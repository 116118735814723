import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
  @Input() companyEmployee: any;
  @Input() employee: any;
  @Input() class: any;
  @Input() task_operation_id: any = 0;
  @Input() host: any;
  @Input() company: any;
  @Input() status_id: any = '-1';
  @Input() imgRoute: any;
  @Input() lines: number = 1;


  constructor() { }

  ngOnInit(): void {
  }

  onPartnerImgError(event){
    event.target.src = this.imgRoute+'/assets/img/partner.png'
  }

}
