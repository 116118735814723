import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { forkJoin, interval } from 'rxjs';
import { last, map, switchMap, tap } from 'rxjs/operators';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { ConnectedAppsService } from 'src/app/shared/services/rest/connected-apps.service';
import * as internal from 'stream';

@Component({
  selector: 'app-connected-app-channels',
  templateUrl: './connected-app-channels.component.html',
  styleUrls: ['./connected-app-channels.component.scss']
})
export class ConnectedAppChannelsComponent extends BaseClass implements OnInit, OnDestroy {
  public form: FormGroup = this.fb.group({
    name: [this.data.name, Validators.required],
  });
  public isFormChange: boolean = false;

  public expChannels: any;
  public type: any = 'facebook';

  constructor(
    private connectedAppsService: ConnectedAppsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ConnectedAppChannelsComponent>,
    private fb: FormBuilder,
    private layoutService: LayoutService
  ) { super() }

  ngOnInit(): void {
    this.getChannelAccountsChannels();

    this.attachSubscriptions(
      this.form.valueChanges.subscribe(() => this.isFormChange = true)
    )
    
    this.attachSubscriptions(
      interval(5000).pipe(
        switchMap(x => {
          return this.connectedAppsService.getChannelAccount(this.data.channel.id)
        })
      ).subscribe(res => {
        if (this.data.channel.channels_updated_at == 0 && res.channels_updated_at != 0) {
          this.getChannelAccountsChannels();
        }

        this.data.channel.channels_updated_at = res.channels_updated_at;
      })
    )

    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        if (this.isFormChange) {
          this.layoutService.openBottomSheet(this.dialogRef);
        } else {
          this.close();
        }
      })
    )

  }

  
  changeTab(val) {
    this.type = val;
    this.expChannels = [];
    this.getChannelAccountsChannels();
  }

  
  getChannelAccountsChannels() {
    this.attachSubscriptions(
      this.connectedAppsService.getChannelAccountsChannelsDyn('1', null, this.data.channel.id, this.data.channel.type == 'facebook' ? this.type : this.data.channel.type, null, '1', !!this.data.fromCompany ? this.data.company_id : null).pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }
  
          return forkJoin(arr.map(x => this.connectedAppsService.getChannelAccountsChannelsDyn(x, null, this.data.channel.id, this.data.channel.type == 'facebook' ? this.type : this.data.channel.type, null, '200', !!this.data.fromCompany ? this.data.company_id : null).pipe(map(u => u.body)))).pipe(
            last(),
            tap(values => {
              let conValues = [].concat(...values)
              this.expChannels = conValues;
            }),
          )
        }),
      ).subscribe(resp => {
        console.log("expChannels", this.expChannels);
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  onUpdate() {
    this.attachSubscriptions(
      this.connectedAppsService.editChannelAccount(this.data.channel.id, {channels_updated_at: 0}).subscribe(resp => {
        this.data.channel.channels_updated_at = resp.channels_updated_at;
      })
    )
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
