import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDateRangePicker } from '@angular/material/datepicker';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { Router } from '@angular/router';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import * as moment from 'moment';
import { fromEvent, ReplaySubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';
import { MY_FORMATS } from 'src/app/components/atTasksDialog/task-profile-add/task-profile-add.component';
import { prioritys } from 'src/app/shared/consts/prioritys';
import { BaseClass } from 'src/app/shared/models/base-class';
import { GlobalDataService } from 'src/app/shared/services/common/global-data.service';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';

@Component({
  selector: 'app-board-filter',
  templateUrl: './board-filter.component.html',
  styleUrls: ['./board-filter.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ]
})

export class BoardFilterComponent extends BaseClass implements OnInit, OnDestroy {
  public now = new Date();
  public today = moment().set({hour:0,minute:0,second:0}).unix();

  public todayStartVal = moment().set({hour:0,minute:0,second:0}).format("X");
  public todayEndVal = moment().set({hour:23,minute:59,second:59}).format("X");
  public tomorrowStartVal = moment().add(1,'days').set({hour:0,minute:0,second:0}).format("X");
  public tomorrowEndVal = moment().add(1,'days').set({hour:23,minute:59,second:59}).format("X");
  public todayStart = moment().set({hour:0,minute:0,second:0});
  public todayEnd = moment().set({hour:23,minute:59,second:59});
  public tomorrowStart = moment().add(1,'days').set({hour:0,minute:0,second:0});
  public tomorrowEnd = moment().add(1,'days').set({hour:23,minute:59,second:59});
  public filterCount: number = 1;
  public groups: any;
  public activeStatus: number[] = [1,2,5];
  public doneStatus: number[] = [3,4,6,97,98,99];

  public filter: FormGroup = this.fb.group({
    status_id: [[1,2,5]],
    doneStatus: [[3,4,6,97,98,99]],
    employee_id: '',
    task_operation_id: '',
    discussion_id: '',
    start_at_from : '',
    start_at_to : '',
  });
  public activeLang: any;
  public statuses: any;
  public taskStatuses: any;
  public employees: any;
  public prioritys: any = prioritys;
  
  public contentUrl = [
    {
      name: marker("Without content url"),
      id: 0
    },
    {
      name: marker("With content url"),
      id: 1
    }
  ]

  public employees$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public execControl: FormControl = new FormControl();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<BoardFilterComponent>,
    private fb: FormBuilder,
    private router: Router,
    private _adapter: DateAdapter<any>,
    private taskService: TaskService,
    private languageService: LanguageService,
  ) { super() }

  ngOnInit(): void {
    this.getOperationsStatus();
    this.getEmployees();
    this.getTaskStatus();
    this.getLangData();

    this.filter.valueChanges.subscribe(res => {
      console.log("valueChanges", res);
      this.filterCount = this.filterCount + 1;
      this.filter.value.count = this.filterCount;
      let filterData = {...res};
    
      if (!!this.filter.value.start_at_from) {
        filterData.start_at_from = moment(this.filter.value.start_at_from._d).format("X");
      }
      if (!!this.filter.value.start_at_to) {
        filterData.start_at_to = moment(this.filter.value.start_at_to._d).format("X");
      }
      // console.log("filter", this.filter);
      // console.log("filterData", filterData);
  
      // return
      filterData.status_id = this.activeStatus
      filterData.doneStatus = this.doneStatus
  
      let params = {
        company_id: this.data.company.id
      };
      
      Object.keys(filterData).forEach(element => {
        if (element == 'status_id' || element == 'doneStatus' && filterData[element].length > 0) {
          params[element] = filterData[element].join(',')
        } else {
          if (!!filterData[element] && element != 'status_id' && element != 'doneStatus') {
            params[element] = filterData[element]
          }
        }
      })
  
      this.router.navigate(['/board'], { queryParams: params});

      this.close();
    })
    this.attachSubscriptions(
      this.execControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchEmoloyees(resp))
    )
  }

  onSearchEmoloyees(resp) {
    if (!this.employees) {
      return;
    }

    if (!resp) {
      this.employees$.next(this.employees.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.employees$.next(
      this.employees.filter(z => z.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  getTaskStatus() {
    this.attachSubscriptions(
      this.taskService.getTaskStatuses(this.data.company.id).subscribe(resp => {
        this.taskStatuses = resp.slice();
        this.taskStatuses.unshift({
          id: 0,
          name: marker('All statuses')
        });
      })
    )
  }

  getOperationsStatus() {
    this.attachSubscriptions(
      this.taskService.getOperationsStatus().subscribe(resp => {
        this.statuses = resp.slice();
        console.log("statuses", this.statuses);
      })
    )
  }

  changeActiveStatus(id, event = null) {
    if (id == 0) {
      this.activeStatus = [1,2,5];
    } else {
      this.activeStatus = [id];
    }

    this.filter.patchValue({
      status_id: this.activeStatus
    })

  }

  changeDoneStatus(id, event = null) {
    console.log("changeDoneStatus", id)
    if (id == 0) {
      this.doneStatus = [3,4,6,97,98,99];
    } else {
      this.doneStatus = [id];
    }

    this.filter.patchValue({
      doneStatus: this.doneStatus
    })
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  getEmployees() {
    this.attachSubscriptions(
      this.taskService.getEmployees(this.data.company.id).subscribe(resp => {
        this.employees = resp;
        this.employees$.next(this.employees.slice());
      })
    )
  }

  changeDay(e: MatButtonToggleChange) {
    if (e.value == "today") {
      this.filter.patchValue({
        start_at_from: this.todayStart,
        start_at_to: this.todayEnd
      })
    } else {
      this.filter.patchValue({
        start_at_from: this.tomorrowStart,
        start_at_to: this.tomorrowEnd
      })
    }
  }
  
  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData().pipe(
        tap(resp => {
          this.activeLang = resp.active;
          this._adapter.setLocale(resp.active);
        })
      ).subscribe(resp => {
        console.log("getOperations", resp)
      })
    )
  }


  ngOnDestroy(): void {
    this.clearSubscriptions();
  }
}
