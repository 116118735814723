<h2 mat-dialog-title class="dialog_title">
    <span>
        {{ "Checklists" | translate }}: {{ getColumnName(data?.work?.operation_id) }}
    </span>
    <button mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h2>
<mat-dialog-content class="mat-typography">
    <div class="container form_wrapper">
        <div class="checks_wrp">
            <app-check-list-items [activeLang]="activeLang" [operationsValues]="data.operationsValues" [task]="data.task" [user]="data.user" [job]="data.work" [company]="data.company"></app-check-list-items>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button (click)="close()">
        {{ "Close" | translate }}
    </button>
</mat-dialog-actions>