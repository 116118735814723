<h2 mat-dialog-title class="dialog_title" (click)="log()">
    {{ "Duplicate settings" | translate }}
</h2>
<mat-dialog-content class="mat-typography">
    <div class="container form_wrapper settings_body">
        <form [formGroup]="form">

            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ 'Duplicated status' | translate }}</mat-label>
                <mat-select disableOptionCentering formControlName="post_status_after">
                    <mat-option *ngFor="let status of profileStatuses" [value]="status.id">
                        {{ status.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <div class="box_wrp">
                <mat-checkbox color="primary" formControlName="post_delete_after">{{"Will be deleted after creating a duplicate" | translate}}</mat-checkbox>
            </div>
            <div class="box_wrp">
                <mat-checkbox color="primary" formControlName="post_backup_after">{{"Backup" | translate}}</mat-checkbox>
            </div>
            <div class="box_wrp">
                <mat-checkbox color="primary" formControlName="post_link_after">{{"Use new link" | translate}}</mat-checkbox>
            </div>

            <mat-form-field *ngIf="!!form.get('post_link_after').value" appearance="standard" class="full_width_form">
                <mat-label>{{ 'New link' | translate }}</mat-label>
                <input (input)="onInputUrl($event.target.value)" formControlName="new_link" matInput placeholder="{{ 'New link' | translate }}">
            </mat-form-field>

        </form>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <div class="start_btns">
        <button class="b_tn" (click)="close()">
            {{ "Close" | translate }}
        </button>

        <button class="b_tn b_tn_blue ml-1" (click)="save()">
            {{ "Duplicate" | translate }}
        </button>
    </div>
</mat-dialog-actions>
