import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { forkJoin } from 'rxjs';
import { last, map, switchMap, tap } from 'rxjs/operators';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { QuestsService } from 'src/app/shared/services/rest/quests.service';
import { StatementService } from 'src/app/shared/services/rest/statement.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';

@Component({
  selector: 'app-add-variable',
  templateUrl: './add-variable.component.html',
  styleUrls: ['./add-variable.component.scss']
})
export class AddVariableComponent extends BaseClass implements OnInit, OnDestroy {
  public form: FormGroup;
  public forms: any;
  public fields: any;
  public isFormChange: boolean = false;
  public isSubmit: boolean = false;
  public isGettingFields: boolean = false;
  public sources:any = [
    {
      value: '$employee.application.',
      name: "Onboarding form"
    },
    {
      value: '$statement.summaries',
      name: "Statement Summaries"
    },
    {
      value: '$statement',
      name: "Statement"
    },
  ]

  public stFields = [
    {
      name: "ID",
      value: "id"
    }
  ]
  
  public statementFields = [
    {
      name: "Paid",
      value: "paid"
    },
    {
      name: "Balance",
      value: "balance"
    },
    {
      name: "Pending Balance",
      value: "balance_pending"
    },
    {
      name: "Pending",
      value: "pending"
    },
    {
      name: "Previous Balance",
      value: "start_balance"
    },
    {
      name: "Earned",
      value: "earned"
    },
    {
      name: "Returned",
      value: "returned"
    },
  ]

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<AddVariableComponent>,
    private layoutService: LayoutService,
    private questsService: QuestsService,
  ) { super() }

  ngOnInit(): void {

    console.log("AddVariableComponent", this.data)

    this.getForms()

    this.form = this.fb.group({
      source: ['', Validators.required],
      form_id: ['', Validators.required],
      field: ['', Validators.required],
    })

    this.attachSubscriptions(
      this.form.get('source').valueChanges.subscribe(res => {
        if (res == "$employee.application.") {
          this.form.addControl('form_id', this.fb.control('', Validators.required))
          this.form.addControl('field', this.fb.control('', Validators.required))
          if (this.form.get('statement_field')) {
            this.form.removeControl('statement_field')
          }
        } else {
          if (this.form.get('form_id')) {
            this.form.removeControl('form_id')
            this.form.removeControl('field')
          }
          this.form.addControl('statement_field', this.fb.control('', Validators.required))
        }
      })
    )

    this.attachSubscriptions(
      this.form.get('form_id').valueChanges.subscribe(res => {
        if (!!res) {
          this.form.patchValue({
            field: ['', Validators.required],
          })
          this.getFields(res);
        }
      })
    )

    this.attachSubscriptions(
      this.form.get('field').valueChanges.subscribe(res => {
        if (res && this.getFieldById(res) && this.getFieldById(res).type == 'date') {
          if (!this.form.get('date_format')) {
            this.form.addControl('date_format', this.fb.control('', Validators.required))
          }
        } else {
          if (!!this.form.get('date_format')) {
            this.form.removeControl('date_format')
          }
        }
      })
    )

    this.attachSubscriptions(
      this.form.valueChanges.subscribe(() => this.isFormChange = true)
    )

    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        if (this.isFormChange) {
          this.layoutService.openBottomSheet(this.dialogRef);
        } else {
          this.close();
        }
      })
    )
  }

  getFieldById(id) {
    return this.fields && this.fields.find(x => x.id == id)
  }

  getStFieldByValue(val) {
    return this.stFields && this.stFields.find(x => x.value == val)
  }

  getStatementFieldByValue(val) {
    return this.statementFields && this.statementFields.find(x => x.value == val)
  }

  getForms() {
    console.log("getForms this.data.company.id", this.data.company.id)
    this.attachSubscriptions(
      this.questsService.getQuestsDyn('1', this.data.company.id, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.questsService.getQuestsDyn(x, this.data.company.id, '200').pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        this.forms = [].concat(...res);
      })
    )
  }

  getFields(aId) {
    this.isGettingFields = true;
    this.attachSubscriptions(
      this.questsService.getFields('1', this.data.company.id, aId, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.questsService.getFields(x, this.data.company.id, aId, '200').pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        this.fields = [].concat(...res);
        this.isGettingFields = false
      })
    )
  }
  
  close() {
    this.dialogRef.close({event: "close", data: false});
  }

  submitForm() {
    let sbmtForm:any = {...this.form.value};
    sbmtForm.name = '';
    if (sbmtForm.source == '$employee.application.') {
      if (this.getFieldById(sbmtForm.field)) {
        sbmtForm.name = this.getFieldById(sbmtForm.field).name;
      }
    } else if (sbmtForm.source == '$statement.summaries') {
      sbmtForm.name = this.getStatementFieldByValue(sbmtForm.statement_field).name;
    } else if (sbmtForm.source == '$statement') {
      sbmtForm.name = this.getStFieldByValue(sbmtForm.statement_field).name;
    }
    this.dialogRef.close({event: "close", data: sbmtForm});
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
