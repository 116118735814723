import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { fromEvent, Observable, of, ReplaySubject, Subscription } from 'rxjs';
import { debounceTime,filter,map,switchMap,take,tap } from 'rxjs/operators';
import { prioritys } from 'src/app/shared/consts/prioritys';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { ScenariosService } from 'src/app/shared/services/rest/scenarios.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { CdkDragEnter, CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { ChatService } from 'src/app/shared/services/rest/chat.service';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
@Component({
  selector: 'app-edit-chat-connections',
  templateUrl: './edit-chat-connections.component.html',
  styleUrls: ['./edit-chat-connections.component.scss']
})

export class EditChatConnectionsComponent extends BaseClass implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('consistPlaceholder') consistPlaceholder: any;
  @ViewChild('partPlaceholder') partPlaceholder: any;

  @ViewChild('consistDragPlaceholder') consistDragPlaceholder: ElementRef;
  @ViewChild('partDragPlaceholder') partDragPlaceholder: ElementRef;

  @ViewChild('relatedPlaceholder') relatedPlaceholder: any;
  @ViewChild('relatedDragPlaceholder') relatedDragPlaceholder: ElementRef;

  @ViewChild('consistPlaceholderTemplate') consistPlaceholderTemplate: any;
  @ViewChild('partPlaceholderTemplate') partPlaceholderTemplate: any;

  @ViewChild('consistDragPlaceholderTemplate') consistDragPlaceholderTemplate: ElementRef;
  @ViewChild('partDragPlaceholderTemplate') partDragPlaceholderTemplate: ElementRef;

  @ViewChild('relatedPlaceholderTemplate') relatedPlaceholderTemplate: any;
  @ViewChild('relatedDragPlaceholderTemplate') relatedDragPlaceholderTemplate: ElementRef;

  @ViewChild('contextMenu') contextMenu: TemplateRef<any>;
  overlayRef: OverlayRef | null;
  public backContextSub: Subscription;
  public origin = window.location.origin;
  
  public target: CdkDropList;
  public targetIndex: number;
  public source: CdkDropList;
  public sourceIndex: number;

  public separatorKeysCodes: number[] = [ENTER, COMMA];

  public form: FormGroup = this.fb.group({
    consist_of_discussion_id: [this.data.chat.consistOfDiscussionPartition.map(x => x.partOfDiscussion)],
    part_of_discussion_id: [this.data.chat.partOfDiscussionPartition.map(x => x.consistOfDiscussion)],
    related_discussion_id: [this.data.chat.discussionRelated.map(x => x.relatedDiscussion)]
  });
  public page: number = 1;
  public pagination: any;
  public isFormChange: boolean = false;
  public submited: boolean = false;
  public chats: any[] = [];
  public isSubmit: boolean = false;
  public isSubmitTmpl: boolean = false;
  public savedData = {
    consist_of_discussion_id: this.data.chat.consistOfDiscussionPartition,
    part_of_discussion_id: this.data.chat.partOfDiscussionPartition,
    related_discussion_id: this.data.chat.discussionRelated
  }

  public searchControl: FormControl = new FormControl();

  public chats$: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<EditChatConnectionsComponent>,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private sm: StorageManagerService,
    private taskService: TaskService,
    private chatService: ChatService,
    public overlay: Overlay,
    public viewContainerRef: ViewContainerRef,
    private companyService: CompanyService,
    private layoutService: LayoutService
  ) { super() }

  ngOnInit(): void {
    this.dialogRef.addPanelClass("create_task_by_manager_modal")

    this.attachSubscriptions(
      this.searchControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchChats(resp))
    )

    this.attachSubscriptions(
      this.form.valueChanges.subscribe(() => this.isFormChange = true)
    )

    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        if (this.isFormChange) {
          this.layoutService.openBottomSheet(this.dialogRef);
        } else {
          this.close();
        }
      })
    )

    this.getChats(this.page);
  
  }


  selected(e, input: HTMLInputElement, key) {
    console.log("selected", e)
    let addArr = this.form.get(key).value.slice();

    if (addArr.filter(x => x.id == e.option.value.id).length == 0) {
      addArr.push(e.option.value)
    }

    this.form.patchValue({
      [key]: addArr
    })

    input.value = ''
    this.searchControl.patchValue("");
  }



  onSearchChats(resp) {
    if (!this.chats) {
      return;
    }

    this.page = 1;
    this.pagination = undefined;
    this.getChats(this.page, resp)
  }

  onScrollChats() {
    if (this.pagination.pageCount >= this.page) {
      console.log("SCROLL !")
      this.chatService.getChatsExpand(this.page, this.data.company.id, {'q_name': !!this.searchControl.value ? this.searchControl.value : ""}, '40').pipe(take(1)).subscribe(resp => {
        this.chats.push(...resp.body.filter(x => x.id != this.data.chat.id))
        this.chats$.next(this.chats.slice())
        console.log(this.page, this.chats)
        this.page++;
      })
    }
  }

  getChats(page, q?) {
    this.attachSubscriptions(
      this.chatService.getChatsExpand(page, this.data.company.id, {'q_name': !!q ? q : ''}, '40').pipe(
        tap(el => {
          this.pagination = {
            'pageCount': el.headers.get('x-pagination-page-count'),
            'perPage': el.headers.get('x-pagination-per-page'),
            'totalCount': el.headers.get('x-pagination-total-count'),
            'currentPage': el.headers.get('x-pagination-current-page'),
          }
        })
      ).subscribe(resp => {
        this.chats = resp.body.filter(x => x.id != this.data.chat.id)
        this.chats$.next(this.chats.slice())
        
        console.log(this.page, this.chats)
        this.page = this.page + 1; 
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false});
  }

  submitForm() {
    this.isSubmit = true;

    let sortData = [];

    this.form.get('consist_of_discussion_id').value.forEach((el, i) => {
      sortData.push(
        {
          "path": '/api/discussion-partition/register/',
          "query": {'company_id': this.data.company.id},
          "method": "POST",
          "body": {
            [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
            consist_of_discussion_id: this.data.chat.id,
            part_of_discussion_id: el.id,
            consist_of_order: i,
            part_of_order: null
          }
        }
      )
    });

    this.form.get('part_of_discussion_id').value.forEach((el, i) => {
      sortData.push(
        {
          "path": '/api/discussion-partition/register/',
          "query": {'company_id': this.data.company.id},
          "method": "POST",
          "body": {
            [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
            part_of_discussion_id: this.data.chat.id,
            consist_of_discussion_id: el.id,
            part_of_order: i,
            consist_of_order: null
          }
        }
      )
    });

    this.form.get('related_discussion_id').value.forEach((el, i) => {
      sortData.push(
        {
          "path": '/api/discussion-related/register/',
          "query": {company_id: this.data.company.id},
          "method": "POST",
          "body": {
            [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
            discussion_id: this.data.chat.id,
            related_discussion_id: el.id,
            order: i
          }
        }
      )
    });

    this.attachSubscriptions(
      this.taskService.multiRequest(sortData).subscribe(resp => {
        this.isSubmit = false
        this.dialogRef.close({event: "Add", data: resp})
      })
    )
  }

  findChat(id) {
    if (this.chats.find(x => x.id == id)) {
      return this.chats.find(x => x.id == id)
    }
  }
  
  onRemoved(value: any, key) {
    if (key == "consist_of_discussion_id") {
      this.chatService.unregisterPartition({
        consist_of_discussion_id: this.data.chat.id,
        part_of_discussion_id: value.id,
      }, this.data.company.id).pipe(
        take(1)
      ).subscribe(resp => {
        this.removedFunc(value, key)
      })
    } else if (key == "part_of_discussion_id") { 
      this.chatService.unregisterPartition({
        part_of_discussion_id: this.data.chat.id,
        consist_of_discussion_id: value.id,
      }, this.data.company.id).pipe(
        take(1)
      ).subscribe(resp => {
        this.removedFunc(value, key)
      })
    } else {
      this.chatService.unregisterRelated({
        discussion_id: this.data.chat.id,
        related_discussion_id: value.id
      }, this.data.company.id).pipe(
        take(1)
      ).subscribe(resp => {
        this.removedFunc(value, key)
      })
    }
  }

  removedFunc(value: any, key) {
    const values = this.form.get(key).value as any[];
    this.removeFirst(values, value);
    this.form.get(key).setValue(values); // To trigger change detection

    console.log(this.form.value)
  }

  check() {
    console.log(this.form.value)
  }

  private removeFirst<T>(array: T[], toRemove: T): void {
    const index = array.indexOf(toRemove);
    if (index !== -1) {
      array.splice(index, 1);
    }
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
  
  ngAfterViewInit() {
    let conPhElement = this.consistPlaceholder.nativeElement;

    conPhElement.style.display = 'none';
    conPhElement.parentNode.removeChild(conPhElement);

    let partPhElement = this.partPlaceholder.nativeElement;

    partPhElement.style.display = 'none';
    partPhElement.parentNode.removeChild(partPhElement);

    let relatedPhElement = this.relatedPlaceholder.nativeElement;

    relatedPhElement.style.display = 'none';
    relatedPhElement.parentNode.removeChild(relatedPhElement);
  }

  dropped(papa, ph, arr) {
    if (!this.target) return;

    const parent: HTMLElement = papa;
    const phElement: HTMLElement = ph;
    const phElementIndex = __indexOf(parent.children, phElement);

    phElement.style.display = 'none';
    parent.removeChild(phElement);
    parent.appendChild(phElement);

    parent.insertBefore(
      this.source.element.nativeElement,
      parent.children[this.sourceIndex]
    );

    console.log(this.sourceIndex, ' => ', phElementIndex);

    if (this.sourceIndex != phElementIndex) {
      moveItemInArray(arr, this.sourceIndex, phElementIndex);
    }

    this.target = null;
    this.targetIndex = undefined;
    this.source = null;
    this.sourceIndex = undefined;
  }

  entered({ item, container }: CdkDragEnter, papa, ph) {
    const phElement: HTMLElement = ph;
    const dropElement: HTMLElement = container.element.nativeElement;
    const prevTarget: CdkDropList = this.target;
    const prevTargetIndex: number = this.targetIndex;
    this.target = container;

    const dropElementIsTheSource: boolean = !dropElement.parentNode;
    const prevAndCurrentTargetAreTheSame: boolean = this.target === prevTarget;
    if (dropElementIsTheSource || prevAndCurrentTargetAreTheSame) {
      return;
    }

    this.targetIndex = __indexOf(dropElement.parentNode.children, dropElement);

    if (!this.source) {
      this.source = item.dropContainer;
      this.sourceIndex = __indexOf(
        dropElement.parentNode.children,
        item.dropContainer.element.nativeElement
      );
      const sourceElement: HTMLElement = this.source.element.nativeElement;

      this.fixPhElementStyling(phElement, sourceElement);

      sourceElement.parentNode.removeChild(sourceElement);
    }

    const index: number = prevTargetIndex ?? this.sourceIndex;
    const insertAfter: boolean = index < this.targetIndex;

    papa.insertBefore(
      phElement,
      insertAfter ? dropElement.nextSibling : dropElement
    );
  }

  dragReleased(ph, dragPh) {
    const phElementPositionWasChanged: boolean = !!this.source;
    if (phElementPositionWasChanged) {
      // console.log("dragReleased dragPh", ph, dragPh)
      dragPh.nativeElement.style.transform = 'none';
      dragPh.nativeElement.parentNode.removeChild(
        dragPh.nativeElement
      );
      ph.appendChild(
        dragPh.nativeElement
      );
    }
  }

  private fixPhElementStyling(
    phElement: HTMLElement,
    sourceElement: HTMLElement
  ) {
    phElement.style.width = sourceElement.clientWidth - 6 + 'px';
    phElement.style.height = sourceElement.clientHeight - 6 + 'px';

    const size = Array.from(sourceElement.classList).find((c) =>
      c.startsWith('content-item-c')
    );

    phElement.style.display = '';
    const oldSize = Array.from(phElement.classList).find((c) =>
      c.startsWith('content-item-c')
    );
    if (oldSize) {
      phElement.classList.remove(oldSize);
    }
    if (size) {
      phElement.classList.add(size);
    }
  }

  openContext({ x, y }: MouseEvent, contextData) {
    this.closeContext();
    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo({ x, y })
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        }
      ]);

    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });

    this.overlayRef.attach(new TemplatePortal(this.contextMenu, this.viewContainerRef, {
      $implicit: contextData
    }));
    
    this.backContextSub = fromEvent<MouseEvent>(document, 'click')
      .pipe(
        filter(event => {
          const clickTarget = event.target as HTMLElement;
          return !!this.overlayRef && !this.overlayRef.overlayElement.contains(clickTarget);
        }),
        take(1)
      ).subscribe(() => this.closeContext())

  }

  closeContext() {
    this.backContextSub && this.backContextSub.unsubscribe();
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
  }

  moveTo(contextData, targetControl) {
    console.log(contextData, targetControl)

    this.onRemoved(contextData.disc, contextData.control)


    setTimeout(() => {
      let toValues = contextData.form.get(targetControl).value as any[];
      toValues.push(contextData.disc)
      contextData.form.get(targetControl).setValue(toValues); // To trigger change detection
    },200)

    console.log(contextData.form.value)
    this.closeContext()
  }

  remove(contextData) {
    this.onRemoved(contextData.disc, contextData.control)
    this.closeContext()
  }

  openChat(contextData) {
    this.closeContext()
    let chat = contextData.disc;
    console.log("chat", chat)
    if (!chat) {
      return
    }
    window.open(`${this.origin}/chat/${chat.acm}`, '_blank')
  }
}

function __indexOf(collection, node) {
  return Array.prototype.indexOf.call(collection, node);
}
