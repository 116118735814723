import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { fromEvent } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { BaseClass } from 'src/app/shared/models/base-class';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { FileService } from 'src/app/shared/services/rest/file.service';
import { RefreshService } from 'src/app/shared/services/rest/refresh.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-select-files-for-check',
  templateUrl: './select-files-for-check.component.html',
  styleUrls: ['./select-files-for-check.component.scss']
})
export class SelectFilesForCheckComponent extends BaseClass implements OnInit, OnDestroy {
  public selectedCheck: any;
  public host: any = environment.host;
  public imgRoute: any;
  public pagination: any;
  public page: number = 1;
  public files: any[] = [];
  public is_mobile: boolean = false;
  public isSubmit: boolean = false;
  public filesCollection: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<SelectFilesForCheckComponent>,
    private taskService: TaskService,
    private fileService: FileService,
    private sm: StorageManagerService
  ) { super() }

  ngOnInit(): void {
    this.data.dialogHasFm = true;
    console.log("SelectFilesForCheckComponent", this.data);
    this.checkIsMobile();
    this.getFiles(this.page);
    
    this.filesCollection = new SelectionModel(
      true,
      []
    )

    if (this.data.check.files && this.data.check.files.length) {
      this.data.check.files.forEach(file => {
        this.filesCollection.select(file)
      })
    }
  }

  understandFileType(val) {
    if (!val) {
      return ""
    }
    
    if (val == 'application/pdf') {
      return 'pdf'
    } else if (val.indexOf("/") != -1) {
      return val.split('/')[0]
    } else {
      return ""
    }
  }

  checkIsMobile() {
    if (window.innerWidth <= 769) {
      this.is_mobile = true;
    } else {
      this.is_mobile = false;
    }
    this.onResize();
  }
  
  onResize() {
    this.attachSubscriptions(
      fromEvent(window, "resize").pipe(
        map(() => window.innerWidth)
      ).subscribe((wWidth) => {
          if (wWidth <= 769) {
            this.is_mobile = true;
          } else {
            this.is_mobile = false;
          }
        }
      )
    )
  }

  getCount() {
    return this.files.filter(x => x.selected).length
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  selectedIds() {
    return [...this.filesCollection.selected.map(x => x.id)]
  }

  selectFile(file) {
    console.log(file)
  }

  onImgError(event){
    event.target.src = this.imgRoute+'/assets/img/image_black_48dp.svg'
  }

  getFiles(page) {
    this.attachSubscriptions(
      this.fileService.getFileData(page, this.data.company.id, {task_id: this.data.task.id, task_operation_id: this.data.work.id, 'location_all': '/ready_files'}, '200').pipe(
        tap(el => {
          this.pagination = {
            'pageCount': el.headers.get('x-pagination-page-count'),
            'perPage': el.headers.get('x-pagination-per-page'),
            'totalCount': el.headers.get('x-pagination-total-count'),
            'currentPage': el.headers.get('x-pagination-current-page'),
          }
        }),
        map(el => el.body.filter(x => !x.operation_reminder_id)),
      ).subscribe(resp => {
        console.log("getFilesQUERYES", resp)
        this.files.push(...resp.slice())
        this.page = this.page + 1;

        console.log(this.pagination, this.files);
        // if (+this.pagination['currentPage'] != +this.pagination['pageCount']) {
        //   this.getFiles(this.page);
        // }
      })
    )
  }

  toggleFile(file) {
    // file.selected = !file.selected
    if (this.filesCollection.selected.filter(x => x.id == file.id).length > 0) {
      this.filesCollection.deselect(this.filesCollection.selected.find(x => x.id == file.id))
    } else {
      this.filesCollection.select(file)
    }
    console.log("toggleFile", file)
    console.log("toggleFile", this.filesCollection.selected)
    console.log("toggleFile", this.data.check)
    console.log("toggleFile", this.data.check.files.filter(u => u.id == file.id).length > 0)
    // if (this.data.check.files.filter(u => u.id == file.id).length > 0) {
    //   this.attachSubscriptions(
    //     this.fileService.editFile(file.id, {operation_reminder_id: this.data.check.id}, this.data.company.id).subscribe(resp => {
    //       this.data.check.files.splice(this.data.check.files.indexOf(file), 1);
    //       this.filesCollection.deselect(file)
    //     })
    //   )
    // }
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  save() {
    this.isSubmit = true;
    let sbmtData = [];
    this.filesCollection.selected.forEach(x => {
      sbmtData.push({
        "path": `/api/file/${x.id}/`,
        "query": {company_id: this.data.company.id},
        "method": "POST",
        "body": Object.assign({
          [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
          operation_reminder_id: this.data.check.id
        }, x.location.indexOf('ready_files') == -1 ? {location: '/ready_files'} : {})
      });
    });

    this.data.check.files.filter(b => this.filesCollection.selected.filter(u => u.id == b.id).length == 0).forEach(x => {
      sbmtData.push({
        "path": `/api/file/${x.id}/`,
        "query": {company_id: this.data.company.id},
        "method": "POST",
        "body": Object.assign({
          [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
        }, {operation_reminder_id: 0})
      });
    });

    this.attachSubscriptions(
      this.taskService.multiRequest(sbmtData).subscribe(resp => {
        console.log(resp)
        this.isSubmit = false;
        this.close()
      },
      error => {
        console.log(error)
      })
    )
  }

  ngOnDestroy() {
    this.clearSubscriptions()
  }

}
