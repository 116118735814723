import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { BaseClass } from "src/app/shared/models/base-class";
import { LayoutService } from "src/app/shared/services/common/layout.service";
import { StorageManagerService } from "src/app/shared/services/common/storage-manager.service";
import { ChatService } from "src/app/shared/services/rest/chat.service";
import { MembersService } from "src/app/shared/services/rest/members.service";
import { TaskService } from "src/app/shared/services/rest/task.service";
import { of } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { marker } from "@biesbjerg/ngx-translate-extract-marker";
import { BackJobToPrevStatusComponent } from "src/app/shared/global_components/back-job-to-prev-status/back-job-to-prev-status.component";
import { getLessStatuses } from "src/app/shared/functions/statusesLessModel";


@Component({
  selector: 'app-edit-chat-ass-status',
  templateUrl: './edit-chat-ass-status.component.html',
  styleUrls: ['./edit-chat-ass-status.component.scss']
})
export class EditChatAssStatusComponent extends BaseClass implements OnInit, OnDestroy {
  public operationStatuses: any;
  public form: FormGroup;
  public isFormChange: boolean = false;

  constructor(
    private taskService: TaskService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<EditChatAssStatusComponent>,
    private fb: FormBuilder,
    private chatService: ChatService,
    private bottomSheet: MatBottomSheet,
    private membersService: MembersService,
    private layoutService: LayoutService
  ) { super() }

  ngOnInit(): void {
    console.log("EditChatAssStatusComponent", this.data)
    this.getOperationsStatus();
    
    let initStatusId: number;

    if (!!this.data.employee.is_partner) {
      if (this.data.employee.partnerCompanyStatus) {
        initStatusId = this.data.employee.partnerCompanyStatus.status_id;
      }
    } else {
      if (this.data.employee.employeeStatus) {
        initStatusId = this.data.employee.employeeStatus.status_id;
      }
    }


    this.form = this.fb.group({
      status_id: [!!initStatusId ? initStatusId : this.data.chat.status_id, Validators.required],
    })

    this.attachSubscriptions(
      this.form.valueChanges.subscribe((val) => {
        this.isFormChange = true        
      })
    )

    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        if (this.isFormChange) {
          this.layoutService.openBottomSheet(this.dialogRef);
        } else {
          this.close();
        }
      })
    )
  }

  getOperationsStatus() {
    this.attachSubscriptions(
      this.taskService.getOperationsStatus().subscribe(resp => {
        console.log("operationStatuses", resp)
        this.operationStatuses = resp.slice();
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  onSubmit() {
    if (!!this.data?.employee.is_partner_employee) {
      if (!!this.data?.employee.employeeStatus.id) {
        this.attachSubscriptions(
          this.membersService.editPartnerEmployeeStatus(this.data?.employee.employeeStatus.id, this.form.value, this.data.company.id).pipe(
            switchMap(val => {
              if ([3,4,6,97,98,99].includes(this.form.value.status_id)) {
                return this.membersService.getTaskPartnerEmployeeStatuses({task_operation_id: this.data.work.id, status_id: getLessStatuses(this.form.value.status_id), partner_company_id: this.data?.employee?.partner_company_id, partner_employee_id: this.data?.employee?.partner_employee_id}, this.data.company.id, '2').pipe(
                  switchMap(res => {
                    console.log("RES3", res)
                    if (res.length == 1 && !res[0].discussion_id) {
                      console.log("RES4", val)
                      let questionAlert =  this.bottomSheet.open(BackJobToPrevStatusComponent, {
                        hasBackdrop: true,
                        backdropClass: 'bottom-sheed-backdrop',
                        data: {
                          msg: marker(`Do you really want to convert job personal status to: '${this.getOperationStatusName(this.form.value.status_id)}'?`)
                        }
                      });
                  
                      return questionAlert.afterDismissed()
                      .pipe(
                        switchMap(data => {
                          if (data && data.message == 'no') {
                            console.log("no");
                            return of(val)
                          } else if (data && data.message == 'yes') {
                            return this.membersService.editPartnerEmployeeStatus(res[0].id, {status_id: this.form.value.status_id}, this.data.company.id).pipe(
                              map(() => val)
                            )
                          } else {
                            return of(val)
                          }
                        })
                      )
                    } else {
                      return of(val)
                    }
                  })
                )
              } else {
                return of(val)
              }
            }) 
          ).subscribe(resp => {
            this.dialogRef.close({event: "update", data: resp})
          },
          error => {
            this.data.showError()
            this.dialogRef.close({event: "update", data: {}})
          })
        )
      } else {
        this.attachSubscriptions(
          this.membersService.addPartnerEmployeeStatus(
            Object.assign(this.form.value, {
              company_id: this.data.company.id,
              task_id: this.data.task.id,
              task_operation_id: this.data.work.id,
              discussion_id: this.data.chat.id,
              partner_company_id: this.data?.employee.partner_company_id,
              partner_employee_id: this.data?.employee.partner_employee_id
            }), this.data.company.id).pipe(
              switchMap(val => {
                if ([3,4,6,97,98,99].includes(this.form.value.status_id)) {
                  return this.membersService.getTaskPartnerEmployeeStatuses({task_operation_id: this.data.work.id, status_id: getLessStatuses(this.form.value.status_id), partner_company_id: this.data?.employee?.partner_company_id, partner_employee_id: this.data?.employee?.partner_employee_id}, this.data.company.id, '2').pipe(
                    switchMap(res => {
                      console.log("RES3", res)
                      if (res.length == 1 && !res[0].discussion_id) {
                        console.log("RES4", val)
                        let questionAlert =  this.bottomSheet.open(BackJobToPrevStatusComponent, {
                          hasBackdrop: true,
                          backdropClass: 'bottom-sheed-backdrop',
                          data: {
                            msg: marker(`Do you really want to convert job personal status to: '${this.getOperationStatusName(this.form.value.status_id)}'?`)
                          }
                        });
                    
                        return questionAlert.afterDismissed()
                        .pipe(
                          switchMap(data => {
                            if (data && data.message == 'no') {
                              console.log("no");
                              return of(val)
                            } else if (data && data.message == 'yes') {
                              return this.membersService.editPartnerEmployeeStatus(res[0].id, {status_id: this.form.value.status_id}, this.data.company.id).pipe(
                                map(() => val)
                              )
                            } else {
                              return of(val)
                            }
                          })
                        )
                      } else {
                        return of(val)
                      }
                    })
                  )
                } else {
                  return of(val)
                }
              }) 
            ).subscribe(resp => {
            this.dialogRef.close({event: "update", data: resp})
          },
          error => {
            this.data.showError()
            this.dialogRef.close({event: "update", data: {}})
          })
        )
      }
    } else {
      if (!!this.data?.employee?.employeeStatus?.id) {
        this.attachSubscriptions(
          this.membersService.editTaskEmployeeStatus(this.data.employee.employeeStatus.id, this.form.value, this.data.company.id)
          .pipe(
            switchMap(val => {
              if ([3,4,6,97,98,99].includes(this.form.value.status_id)) {
                return this.membersService.getTaskEmployeeStatuses({task_operation_id: this.data.work.id, status_id: getLessStatuses(this.form.value.status_id), employee_id: this.data?.employee?.employee_id}, this.data.company.id, '2').pipe(
                  switchMap(res => {
                    console.log("RES3", res)
                    if (res.length == 1 && !res[0].discussion_id) {
                      console.log("RES4", val)
                      let questionAlert =  this.bottomSheet.open(BackJobToPrevStatusComponent, {
                        hasBackdrop: true,
                        backdropClass: 'bottom-sheed-backdrop',
                        data: {
                          msg: marker(`Do you really want to convert job personal status to: '${this.getOperationStatusName(this.form.value.status_id)}'?`)
                        }
                      });
                  
                      return questionAlert.afterDismissed()
                      .pipe(
                        switchMap(data => {
                          if (data && data.message == 'no') {
                            console.log("no");
                            return of(val)
                          } else if (data && data.message == 'yes') {
                            return this.membersService.editTaskEmployeeStatus(res[0].id, {status_id: this.form.value.status_id}, this.data.company.id).pipe(
                              map(() => val)
                            )
                          } else {
                            return of(val)
                          }
                        })
                      )
                    } else {
                      return of(val)
                    }
                  })
                )
              } else {
                return of(val)
              }
            }) 
          ).subscribe(resp => {
            this.dialogRef.close({event: "update", data: resp})
          },
          error => {
            this.data.showError()
            this.dialogRef.close({event: "update", data: {}})
          })
        )
      } else {
        this.attachSubscriptions(
          this.membersService.addTaskEmployeeStatus(Object.assign(this.form.value, {
              company_id: this.data?.employee?.company_id,
              task_id: this.data.task.id,
              task_operation_id: this.data.work.id,
              discussion_id: this.data.chat.id,
              employee_id: this.data?.employee?.employee_id,
            }), this.data.company.id)
            .pipe(
              switchMap(val => {
                if ([3,4,6,97,98,99].includes(this.form.value.status_id)) {
                  return this.membersService.getTaskEmployeeStatuses({task_operation_id: this.data.work.id, status_id: getLessStatuses(this.form.value.status_id), employee_id: this.data?.employee?.employee_id}, this.data.company.id, '2').pipe(
                    switchMap(res => {
                      console.log("RES3", res)
                      if (res.length == 1 && !res[0].discussion_id) {
                        console.log("RES4", val)
                        let questionAlert =  this.bottomSheet.open(BackJobToPrevStatusComponent, {
                          hasBackdrop: true,
                          backdropClass: 'bottom-sheed-backdrop',
                          data: {
                            msg: marker(`Do you really want to convert job personal status to: '${this.getOperationStatusName(this.form.value.status_id)}'?`)
                          }
                        });
                    
                        return questionAlert.afterDismissed()
                        .pipe(
                          switchMap(data => {
                            if (data && data.message == 'no') {
                              console.log("no");
                              return of(val)
                            } else if (data && data.message == 'yes') {
                              return this.membersService.editTaskEmployeeStatus(res[0].id, {status_id: this.form.value.status_id}, this.data.company.id).pipe(
                                map(() => val)
                              )
                            } else {
                              return of(val)
                            }
                          })
                        )
                      } else {
                        return of(val)
                      }
                    })
                  )
                } else {
                  return of(val)
                }
              }) 
            ).subscribe(resp => {
            this.dialogRef.close({event: "update", data: resp})
          },
          error => {
            this.data.showError()
            this.dialogRef.close({event: "update", data: {}})
          })
        )
      }
    }
  }

  getOperationStatusName(id) {
    switch (+id) {
      case 1:
        return "Waiting";
        break;
      case 2:
        return "In process";
        break;
      case 3:
        return "To approve";
        break;
      case 4:
        return "Approved";
        break;
      case 5:
        return "To correct";
        break;
      case 6:
        return "Sources Needed";
        break;
      case 97:
        return "On hold";
        break;
      case 98:
        return "Ready";
        break;
      case 99:
        return "Canceled";
        break;
    }
  }

  onSubmitPartner() {
    if (!!this.data?.employee?.partnerCompanyStatus?.id) {
      this.attachSubscriptions(
        this.membersService.editPartnerStatus(this.data.employee.partnerCompanyStatus.id, this.form.value, this.data.company.id)
        .pipe(
          switchMap(val => {
            if ([3,4,6,97,98,99].includes(this.form.value.status_id)) {
              return this.membersService.getTaskPartnerStatuses({task_operation_id: this.data.work.id, status_id: getLessStatuses(this.form.value.status_id), partner_company_id: this.data?.employee?.partner_company_id}, this.data.company.id, '2').pipe(
                switchMap(res => {
                  console.log("drop RES3", res)
                  if (res.length == 1 && !res[0].discussion_id) {
                    console.log("drop RES4", val)
                    let questionAlert =  this.bottomSheet.open(BackJobToPrevStatusComponent, {
                      hasBackdrop: true,
                      backdropClass: 'bottom-sheed-backdrop',
                      data: {
                        msg: marker(`Do you really want to convert personal partner status in job to: '${this.getOperationStatusName(this.form.value.status_id)}'?`)
                      }
                    });
                
                    return questionAlert.afterDismissed()
                    .pipe(
                      switchMap(data => {
                        if (data && data.message == 'no') {
                          console.log("no");
                          return of(val)
                        } else if (data && data.message == 'yes') {
                          return this.membersService.editPartnerStatus(res[0].id, {status_id: this.form.value.status_id}, this.data.company.id).pipe(
                            map(() => val)
                          )
                        } else {
                          return of(val)
                        }
                      })
                    )
                  } else {
                    return of(val)
                  }
                })
              )
            } else {
              return of(val)
            }
          }) 
        ).subscribe(resp => {
          this.dialogRef.close({event: "update", data: resp})
        },
        error => {
          this.data.showError()
          this.dialogRef.close({event: "update", data: {}})
        })
      )
    } else {
      this.attachSubscriptions(
        this.membersService.addPartnerStatus(Object.assign(this.form.value, {
            company_id: this.data?.employee?.company_id,
            task_id: this.data.task.id,
            task_operation_id: this.data.work.id,
            discussion_id: this.data.chat.id,
            partner_company_id: this.data?.employee?.partner_company_id,
          }), this.data.company.id)
          .pipe(
            switchMap(val => {
              if ([3,4,6,97,98,99].includes(this.form.value.status_id)) {
                return this.membersService.getTaskPartnerStatuses({task_operation_id: this.data.work.id, status_id: getLessStatuses(this.form.value.status_id), partner_company_id: this.data?.employee?.partner_company_id}, this.data.company.id, '2').pipe(
                  switchMap(res => {
                    console.log("drop RES3", res)
                    if (res.length == 1 && !res[0].discussion_id) {
                      console.log("drop RES4", val)
                      let questionAlert =  this.bottomSheet.open(BackJobToPrevStatusComponent, {
                        hasBackdrop: true,
                        backdropClass: 'bottom-sheed-backdrop',
                        data: {
                          msg: marker(`Do you really want to convert personal partner status in job to: '${this.getOperationStatusName(this.form.value.status_id)}'?`)
                        }
                      });
                  
                      return questionAlert.afterDismissed()
                      .pipe(
                        switchMap(data => {
                          if (data && data.message == 'no') {
                            console.log("no");
                            return of(val)
                          } else if (data && data.message == 'yes') {
                            return this.membersService.editPartnerStatus(res[0].id, {status_id: this.form.value.status_id}, this.data.company.id).pipe(
                              map(() => val)
                            )
                          } else {
                            return of(val)
                          }
                        })
                      )
                    } else {
                      return of(val)
                    }
                  })
                )
              } else {
                return of(val)
              }
            }) 
          ).subscribe(resp => {
          this.dialogRef.close({event: "update", data: resp})
        },
        error => {
          this.data.showError()
          this.dialogRef.close({event: "update", data: {}})
        })
      )
    }
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

}
