import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { HttpRestService } from '../common/http-rest.service';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class StatementService {

  constructor(
    private http: HttpRestService,
    private sanitizer: DomSanitizer
  ) { }

  getStatementTemplates(page, company_id, filter:any = false, per_page:string = '200') {
    let filter_params = new HttpParams().set("page", page).set("company_id", company_id).set('per-page', per_page)
    
    if (filter) {
      Object.keys(filter).forEach(element => {
        if (filter[element] != '0' && filter[element] != '') {
          filter_params = filter_params.set(element, filter[element]);
        }
      })
    }

    return this.http.executeGet<any>(`/api/statement-template/`, true, {
      observe: 'response',
      params: filter_params
    })
  }

  addStatementTemplate(company_id, data) {
    return this.http.executePost<any>(`/api/statement-template/`, data, true, {
      params: new HttpParams().set("company_id", company_id)
    })
  }

  editStatementTemplate(company_id, id, data) {
    console.log("editStatementTemplate data1", data)
    return this.http.executePost<any>(`/api/statement-template/${id}/`, data, true, {
      params: new HttpParams().set("company_id", company_id)
    })
  }

  deleteStatementTemplate(company_id, id) {
    return this.http.executeDelete<any>(`/api/statement-template/${id}/`, true, {
      params: new HttpParams().set("company_id", company_id)
    })
  }

  getStatementDocuments(page, company_id, filter:any = false, per_page:string = '200') {
    let filter_params = new HttpParams().set("page", page).set("company_id", company_id).set('per-page', per_page).set("expand","file")
    
    if (filter) {
      Object.keys(filter).forEach(element => {
        if (filter[element] != '0' && filter[element] != '') {
          filter_params = filter_params.set(element, filter[element]);
        }
      })
    }

    return this.http.executeGet<any>(`/api/statement-document/`, true, {
      observe: 'response',
      params: filter_params
    })
  }

  addStatementDocument(company_id, data) {
    return this.http.executePost<any>(`/api/statement-document/`, data, true, {
      params: new HttpParams().set("company_id", company_id)
    })
  }

  editStatementDocument(company_id, id, data) {
    return this.http.executePost<any>(`/api/statement-document/${id}/`, data, true, {
      params: new HttpParams().set("company_id", company_id)
    })
  }

  deleteStatementDocument(company_id, id) {
    return this.http.executeDelete<any>(`/api/statement-document/${id}/`, true, {
      params: new HttpParams().set("company_id", company_id)
    })
  }

  getStatements(page, company_id, filter:any = false, per_page:string = '20') {
    let filter_params = new HttpParams().set("page", page).set("company_id", company_id).set('per-page', per_page).set("expand","salaryCurrency,employee,summaries,summaries.currency")
    
    if (filter) {
      Object.keys(filter).forEach(element => {
        if (filter[element] == 'empl') {
          filter_params = filter_params.set(element, '1654041600');
        } else {
          if (filter[element] != '0' && filter[element] != '') {
            filter_params = filter_params.set(element, filter[element]);
          }
        }
      })
    }

    return this.http.executeGet<any>(`/api/statement/`, true, {
      observe: 'response',
      params: filter_params
    })
  }

  // getStatementSnapshot(page, company_id, statement_id, per_page:string = '200') {
  //   let filter_params = new HttpParams().set("page", page).set("company_id", company_id).set("statement_id", statement_id).set('per-page', per_page).set("expand","task,taskOperation,taskOperation.parameterValuesToTask.parameter,taskOperation.parameterValuesToTask.parameterValue,discussion,currency,status")
  //   return this.http.executeGet<any>(`/api/statement-snapshot/`, true, {
  //     observe: 'response',
  //     params: filter_params
  //   })
  // }

  getStatementPending(page, company_id, employee_id, type, created_at_from, created_at_to, per_page:string = '200') {
    let filter_params = new HttpParams().set("page", page).set("type", type).set("employee_id", employee_id).set("created_at_from", created_at_from).set("company_id", company_id).set("is_excluded", "0").set('per-page', per_page).set("expand","task,taskOperation,taskOperation.parameterValuesToTask.parameter,taskOperation.parameterValuesToTask.parameterValue,discussion,currency,status")

    if (created_at_to) {
      filter_params = filter_params.set("created_at_to", created_at_to)
    }
    return this.http.executeGet<any>(`/api/statement-pending/`, true, {
      observe: 'response',
      params: filter_params
    })
  }

  getFinancesPending(page, company_id, employee_id, created_at_from, per_page:string = '200') {
    console.log("getFinancesPending", page, company_id, employee_id, created_at_from, per_page)
    let filter_params = new HttpParams().set("page", page).set("employee_id", employee_id).set("created_at_from", created_at_from).set("company_id", company_id).set('per-page', per_page).set("expand","task,taskOperation,taskOperation.parameterValuesToTask.parameter,taskOperation.parameterValuesToTask.parameterValue,discussion,currency,status")

    return this.http.executeGet<any>(`/api/statement-pending/`, true, {
      observe: 'response',
      params: filter_params
    })
  }

  getStatementRecord(page, company_id, created_at_from, created_at_to, employee_id, per_page:string = '200',query_type?) {
    let filter_params = new HttpParams().set("page", page).set("company_id", company_id).set("employee_id", employee_id).set("created_at_from", created_at_from).set("created_at_to", created_at_to).set('per-page', per_page).set("expand","task,taskOperation,taskOperation.parameterValuesToTask.parameter,taskOperation.parameterValuesToTask.parameterValue,discussion,currency,status")
    if (query_type) {
      filter_params = filter_params.set("query_type", query_type)
    }
    return this.http.executeGet<any>(`/api/statement-record/`, true, {
      observe: 'response',
      params: filter_params
    })
  }

  getStatementRecordInside(page, company_id, created_at_from, created_at_to, employee_id, per_page:string = '200', task_id?, task_operation_id?, discussion_id?, price_currency_id?) {
    let filter_params = new HttpParams().set("page", page).set("company_id", company_id).set("employee_id", employee_id).set("created_at_from", created_at_from).set("created_at_to", created_at_to).set('per-page', per_page).set("expand","task,taskOperation,taskOperation.parameterValuesToTask.parameter,taskOperation.parameterValuesToTask.parameterValue,discussion,currency,status")
    if (task_id) {
      filter_params = filter_params.set("task_id", task_id)
    }
    if (task_operation_id) {
      filter_params = filter_params.set("task_operation_id", task_operation_id)
    }
    if (discussion_id) {
      filter_params = filter_params.set("discussion_id", discussion_id)
    }
    if (price_currency_id) {
      filter_params = filter_params.set("price_currency_id", price_currency_id)
    }
    return this.http.executeGet<any>(`/api/statement-record/`, true, {
      observe: 'response',
      params: filter_params
    })
  }

  getStatementRecordFinances(page, company_id, filter?, per_page: any = '200') {
    let filter_params = new HttpParams().set("company_id", company_id).set('page', page).set('per-page', per_page).set('order_by', 'created_at_desc').set("expand","task,taskOperation,taskOperation.parameterValuesToTask.parameter,taskOperation.parameterValuesToTask.parameterValue,discussion,currency,status")

    if (filter) {
      Object.keys(filter).forEach(element => {
        if (!!filter[element]) {
          filter_params = filter_params.set(element, filter[element]);
        }
      })
    }
    return this.http.executeGet<any>(`/api/statement-record/`, true, {
      observe: 'response',
      params: filter_params
    })

  }

  getPaymentsFinances(page, company_id, filter?, per_page: any = '200') {
    let _params = new HttpParams().set("company_id", company_id).set("expand","employee,paymentSystem,currency,files").set('page', page).set('per-page', per_page)
    
    if (filter) {
      Object.keys(filter).forEach(element => {
        if (!!filter[element]) {
          _params = _params.set(element, filter[element]);
        }
      })
    }

    return this.http.executeGet<any>('/api/payment/', true, {
      observe: 'response',
      params: _params
    });
  }

  getOpenSummary(company_id, filter?) {
    let filter_params = new HttpParams().set("company_id", company_id)

    if (filter) {
      Object.keys(filter).forEach(element => {
        if (!!filter[element]) {
          filter_params = filter_params.set(element, filter[element]);
        }
      })
    }

    return this.http.executeGet<any>(`/api/statement/open-summary/`, true, {
      params: filter_params
    })
  }
}
