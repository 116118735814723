<h2 mat-dialog-title class="dialog_title">
    <span>
        {{ "Which files To Approve?" | translate }}
    </span>
    <button mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h2>
<mat-dialog-content class="mat-typography">
    <div class="container a_r_blocks_wrp">
        <p class="in_block comment">Executor submitted files blow for your Aproval.<br>To approve job you must approve or reject the files.</p>
        <div class="in_block files_block">
            <div class="title">
                <span>{{ "Approve files one by one" | translate }}</span>
                <div class="d-f-c">
                    <button class="ml-1 mr-1" mat-icon-button [matMenuTriggerFor]="userMenu" style="line-height: 24px; width: 24px; height: 24px;" *ngIf="getCount()">
                        {{ getCount() }}
                        <mat-icon style="line-height: 18px; font-size:18px; width: 18px; height: 18px;">insert_drive_file</mat-icon>
                    </button>
                
                    <mat-menu #userMenu="matMenu" xPosition="before" class="header_menu">
                        <div mat-menu-item (click)="moveSelectedFile()">
                            <mat-icon>
                                <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12.6 8.4H15.4V0H12.6M9.8 0H3.5C2.919 0 2.422 0.35 2.212 0.854L0.098 5.789C0.035 5.95 0 6.118 0 6.3V7.7C0 8.0713 0.1475 8.4274 0.410051 8.68995C0.672602 8.9525 1.0287 9.1 1.4 9.1H5.817L5.152 12.299C5.138 12.369 5.131 12.439 5.131 12.516C5.131 12.81 5.25 13.069 5.439 13.258L6.181 14L10.787 9.387C11.046 9.135 11.2 8.785 11.2 8.4V1.4C11.2 0.623 10.57 0 9.8 0Z" fill="#DB0000"/>
                                </svg>
                            </mat-icon>
                            {{ "Reject" | translate }}  
                        </div>
                        <div mat-menu-item (click)="moveSelectedFile(true)">
                            <mat-icon>
                                <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.4 6.3C15.4 5.523 14.77 4.9 14 4.9H9.576L10.248 1.701C10.262 1.631 10.269 1.554 10.269 1.477C10.269 1.19 10.15 0.924 9.961 0.735L9.219 0L4.613 4.606C4.354 4.865 4.2 5.215 4.2 5.6V12.6C4.2 12.9713 4.3475 13.3274 4.61005 13.5899C4.8726 13.8525 5.2287 14 5.6 14H11.9C12.481 14 12.978 13.65 13.188 13.146L15.302 8.211C15.365 8.05 15.4 7.882 15.4 7.7V6.3ZM0 14H2.8V5.6H0V14Z" fill="#219653"/>
                                </svg>                                            
                            </mat-icon>
                            {{ "Approve" | translate }}  
                        </div>
                    </mat-menu>
                    <mat-checkbox #checkMulti class="multiple" (change)="multipleToggle($event.checked)" [labelPosition]="'before'">
                        {{ "Multiply aproval" | translate }}
                    </mat-checkbox>
                </div>
            </div>
            <div class="files_wrp" [ngClass]="{'loaded': isGettingFiles}" [appDeviceClass]="'custom-scroll custom-scroll-t'">
                <ng-container *ngIf="isGettingFiles; else elseTemplateLoaded">
                    <mat-spinner></mat-spinner>
                </ng-container>
                <ng-template #elseTemplateLoaded>      
                    <ng-container *ngFor="let file of files; let i = index;">
                        <div class="file"
                        [ngClass]="{'has_dmg': !file.is_uploaded, 'selected': !!file.selected, 'approved': file.state == 'approved' || file.state == 'ready_files', 'reject': file.state == 'reject'}">
                            <mat-icon class="recomend" *ngIf="file.is_ready_if_approved">
                                recommend
                            </mat-icon>
                            <div class="file_data">
                                <div class="file_img">
                                    <ng-container [ngSwitch]="understandFileType(file.content_type)">
                                        <ng-container *ngSwitchCase="'video'">
                                            <ng-container *ngIf="file.thumbnail; else elseTemplateThumbnail">
                                                <div class="movie_thumb">
                                                    <img style="max-width: 100%; max-height: 100%;" (error)="onImgError($event)" [src]="host + file.thumbnail + '?company_id=' + company?.id">
                                                    <mat-icon>play_circle</mat-icon>
                                                </div>
                                            </ng-container>
                                            <ng-template #elseTemplateThumbnail>
                                                <mat-icon class="file_img_icon" style="color: #3f51b5">
                                                    movie
                                                </mat-icon>
                                            </ng-template>
                                        </ng-container>
                                        <ng-container *ngSwitchCase="'pdf'">
                                            <div class="pdf">
                                                <svg height="800px" width="800px" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
                                                    viewBox="0 0 303.188 303.188" xml:space="preserve">
                                                    <g>
                                                        <polygon style="fill:#E8E8E8;" points="219.821,0 32.842,0 32.842,303.188 270.346,303.188 270.346,50.525 	"/>
                                                        <path style="fill:#FB3449;" d="M230.013,149.935c-3.643-6.493-16.231-8.533-22.006-9.451c-4.552-0.724-9.199-0.94-13.803-0.936
                                                            c-3.615-0.024-7.177,0.154-10.693,0.354c-1.296,0.087-2.579,0.199-3.861,0.31c-1.314-1.36-2.584-2.765-3.813-4.202
                                                            c-7.82-9.257-14.134-19.755-19.279-30.664c1.366-5.271,2.459-10.772,3.119-16.485c1.205-10.427,1.619-22.31-2.288-32.251
                                                            c-1.349-3.431-4.946-7.608-9.096-5.528c-4.771,2.392-6.113,9.169-6.502,13.973c-0.313,3.883-0.094,7.776,0.558,11.594
                                                            c0.664,3.844,1.733,7.494,2.897,11.139c1.086,3.342,2.283,6.658,3.588,9.943c-0.828,2.586-1.707,5.127-2.63,7.603
                                                            c-2.152,5.643-4.479,11.004-6.717,16.161c-1.18,2.557-2.335,5.06-3.465,7.507c-3.576,7.855-7.458,15.566-11.815,23.02
                                                            c-10.163,3.585-19.283,7.741-26.857,12.625c-4.063,2.625-7.652,5.476-10.641,8.603c-2.822,2.952-5.69,6.783-5.941,11.024
                                                            c-0.141,2.394,0.807,4.717,2.768,6.137c2.697,2.015,6.271,1.881,9.4,1.225c10.25-2.15,18.121-10.961,24.824-18.387
                                                            c4.617-5.115,9.872-11.61,15.369-19.465c0.012-0.018,0.024-0.036,0.037-0.054c9.428-2.923,19.689-5.391,30.579-7.205
                                                            c4.975-0.825,10.082-1.5,15.291-1.974c3.663,3.431,7.621,6.555,11.939,9.164c3.363,2.069,6.94,3.816,10.684,5.119
                                                            c3.786,1.237,7.595,2.247,11.528,2.886c1.986,0.284,4.017,0.413,6.092,0.335c4.631-0.175,11.278-1.951,11.714-7.57
                                                            C231.127,152.765,230.756,151.257,230.013,149.935z M119.144,160.245c-2.169,3.36-4.261,6.382-6.232,9.041
                                                            c-4.827,6.568-10.34,14.369-18.322,17.286c-1.516,0.554-3.512,1.126-5.616,1.002c-1.874-0.11-3.722-0.937-3.637-3.065
                                                            c0.042-1.114,0.587-2.535,1.423-3.931c0.915-1.531,2.048-2.935,3.275-4.226c2.629-2.762,5.953-5.439,9.777-7.918
                                                            c5.865-3.805,12.867-7.23,20.672-10.286C120.035,158.858,119.587,159.564,119.144,160.245z M146.366,75.985
                                                            c-0.602-3.514-0.693-7.077-0.323-10.503c0.184-1.713,0.533-3.385,1.038-4.952c0.428-1.33,1.352-4.576,2.826-4.993
                                                            c2.43-0.688,3.177,4.529,3.452,6.005c1.566,8.396,0.186,17.733-1.693,25.969c-0.299,1.31-0.632,2.599-0.973,3.883
                                                            c-0.582-1.601-1.137-3.207-1.648-4.821C147.945,83.048,146.939,79.482,146.366,75.985z M163.049,142.265
                                                            c-9.13,1.48-17.815,3.419-25.979,5.708c0.983-0.275,5.475-8.788,6.477-10.555c4.721-8.315,8.583-17.042,11.358-26.197
                                                            c4.9,9.691,10.847,18.962,18.153,27.214c0.673,0.749,1.357,1.489,2.053,2.22C171.017,141.096,166.988,141.633,163.049,142.265z
                                                            M224.793,153.959c-0.334,1.805-4.189,2.837-5.988,3.121c-5.316,0.836-10.94,0.167-16.028-1.542
                                                            c-3.491-1.172-6.858-2.768-10.057-4.688c-3.18-1.921-6.155-4.181-8.936-6.673c3.429-0.206,6.9-0.341,10.388-0.275
                                                            c3.488,0.035,7.003,0.211,10.475,0.664c6.511,0.726,13.807,2.961,18.932,7.186C224.588,152.585,224.91,153.321,224.793,153.959z"/>
                                                        <polygon style="fill:#FB3449;" points="227.64,25.263 32.842,25.263 32.842,0 219.821,0 	"/>
                                                        <g>
                                                            <path style="fill:#A4A9AD;" d="M126.841,241.152c0,5.361-1.58,9.501-4.742,12.421c-3.162,2.921-7.652,4.381-13.472,4.381h-3.643
                                                                v15.917H92.022v-47.979h16.606c6.06,0,10.611,1.324,13.652,3.971C125.321,232.51,126.841,236.273,126.841,241.152z
                                                                M104.985,247.387h2.363c1.947,0,3.495-0.546,4.644-1.641c1.149-1.094,1.723-2.604,1.723-4.529c0-3.238-1.794-4.857-5.382-4.857
                                                                h-3.348C104.985,236.36,104.985,247.387,104.985,247.387z"/>
                                                            <path style="fill:#A4A9AD;" d="M175.215,248.864c0,8.007-2.205,14.177-6.613,18.509s-10.606,6.498-18.591,6.498h-15.523v-47.979
                                                                h16.606c7.701,0,13.646,1.969,17.836,5.907C173.119,235.737,175.215,241.426,175.215,248.864z M161.76,249.324
                                                                c0-4.398-0.87-7.657-2.609-9.78c-1.739-2.122-4.381-3.183-7.926-3.183h-3.773v26.877h2.888c3.939,0,6.826-1.143,8.664-3.43
                                                                C160.841,257.523,161.76,254.028,161.76,249.324z"/>
                                                            <path style="fill:#A4A9AD;" d="M196.579,273.871h-12.766v-47.979h28.355v10.403h-15.589v9.156h14.374v10.403h-14.374
                                                                L196.579,273.871L196.579,273.871z"/>
                                                        </g>
                                                        <polygon style="fill:#D1D3D3;" points="219.821,50.525 270.346,50.525 219.821,0 	"/>
                                                    </g>
                                                </svg>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngSwitchCase="'image'">
                                            <img (error)="onImgError($event)" style="max-width: 100%; max-height: 100%;" [src]="host + (file.thumbnail ? file.thumbnail : file.original) + '?company_id=' + company?.id">
                                        </ng-container>
                                        <ng-container *ngSwitchDefault>
                                            <ng-container *ngIf="!!file?.thumbnail; else elseTemplateHasThumb">
                                                <img (error)="onImgError($event)" style="max-width: 100%; max-height: 100%;" [src]="host + file.thumbnail + '?company_id=' + company?.id">
                                            </ng-container>
                                            <ng-template #elseTemplateHasThumb>
                                                <mat-icon class="file_img_icon" style="color: #3f51b5">
                                                    insert_drive_file
                                                </mat-icon>
                                            </ng-template>
                                        </ng-container>
                                    </ng-container>
                                    <div class="file_name text_one_line" #tooltip="matTooltip"
                                    matTooltip="{{file.filename}}"
                                    [matTooltipPosition]="'below'">
                                        {{ file.filename | translate }}
                                    </div>
                                </div>
                                <div class="file_btns">
                                    <ng-container *ngIf="!isMultiple; else elseTemplateisMultiple">
                                        <div class="file_btn reject" (click)="moveFile(file, '/reject', i)">
                                            <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12.6 8.4H15.4V0H12.6M9.8 0H3.5C2.919 0 2.422 0.35 2.212 0.854L0.098 5.789C0.035 5.95 0 6.118 0 6.3V7.7C0 8.0713 0.1475 8.4274 0.410051 8.68995C0.672602 8.9525 1.0287 9.1 1.4 9.1H5.817L5.152 12.299C5.138 12.369 5.131 12.439 5.131 12.516C5.131 12.81 5.25 13.069 5.439 13.258L6.181 14L10.787 9.387C11.046 9.135 11.2 8.785 11.2 8.4V1.4C11.2 0.623 10.57 0 9.8 0Z" fill="#DB0000"/>
                                            </svg>
                                            <ng-container *ngIf="file.state == 'reject'; else elseTemplateRejectedState">
                                                {{ "Rejected" | translate }}
                                            </ng-container>
                                            <ng-template #elseTemplateRejectedState>
                                                {{ "Reject" | translate }}                                    
                                            </ng-template>
                                            
                                        </div>
                                        <div class="file_btn approve" (click)="moveFile(file, file.is_ready_if_approved ? '/ready_files' : '/approved', i)">
                                            <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M15.4 6.3C15.4 5.523 14.77 4.9 14 4.9H9.576L10.248 1.701C10.262 1.631 10.269 1.554 10.269 1.477C10.269 1.19 10.15 0.924 9.961 0.735L9.219 0L4.613 4.606C4.354 4.865 4.2 5.215 4.2 5.6V12.6C4.2 12.9713 4.3475 13.3274 4.61005 13.5899C4.8726 13.8525 5.2287 14 5.6 14H11.9C12.481 14 12.978 13.65 13.188 13.146L15.302 8.211C15.365 8.05 15.4 7.882 15.4 7.7V6.3ZM0 14H2.8V5.6H0V14Z" fill="#219653"/>
                                            </svg>      
                                            <ng-container *ngIf="file.state == 'approved' || file.state == 'ready_files'; else elseTemplateApprovedState">
                                                {{ "Approved" | translate }}
                                            </ng-container>
                                            <ng-template #elseTemplateApprovedState>
                                                {{ "Approve" | translate }}                                    
                                            </ng-template>
                                        </div>
                                    </ng-container>
                                    <ng-template #elseTemplateisMultiple>
                                        <div></div>
                                        <mat-checkbox [checked]="!!file.selected" (change)="file.selected = $event.checked"></mat-checkbox>
                                    </ng-template>
                                </div>
    
                            </div>
                        </div>  
                    </ng-container>
                </ng-template>
            </div>
        </div>
        <div class="in_block all_block">
            <div class="title">
                <span>{{ "Apply to all files in folder To Approve" | translate }}</span>
            </div>
            <div class="file_btns">
                <div class="file_btn reject" (click)="moveAllFile()">
                    <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.6 8.4H15.4V0H12.6M9.8 0H3.5C2.919 0 2.422 0.35 2.212 0.854L0.098 5.789C0.035 5.95 0 6.118 0 6.3V7.7C0 8.0713 0.1475 8.4274 0.410051 8.68995C0.672602 8.9525 1.0287 9.1 1.4 9.1H5.817L5.152 12.299C5.138 12.369 5.131 12.439 5.131 12.516C5.131 12.81 5.25 13.069 5.439 13.258L6.181 14L10.787 9.387C11.046 9.135 11.2 8.785 11.2 8.4V1.4C11.2 0.623 10.57 0 9.8 0Z" fill="#DB0000"/>
                    </svg>
                    {{ "Reject All" | translate }}                                            
                </div>
                <div class="file_btn approve" (click)="moveAllFile(true)">
                    <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.4 6.3C15.4 5.523 14.77 4.9 14 4.9H9.576L10.248 1.701C10.262 1.631 10.269 1.554 10.269 1.477C10.269 1.19 10.15 0.924 9.961 0.735L9.219 0L4.613 4.606C4.354 4.865 4.2 5.215 4.2 5.6V12.6C4.2 12.9713 4.3475 13.3274 4.61005 13.5899C4.8726 13.8525 5.2287 14 5.6 14H11.9C12.481 14 12.978 13.65 13.188 13.146L15.302 8.211C15.365 8.05 15.4 7.882 15.4 7.7V6.3ZM0 14H2.8V5.6H0V14Z" fill="#219653"/>
                    </svg>                                            
                    {{ "Approve All" | translate }}  
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <div class="f_w_p_btns">
        <button mat-button (click)="close()">
            {{ "Close" | translate }}
        </button>
        <button class="ml-1 d-f-btn" (click)="save()" mat-raised-button color="primary" [disabled]="isSubmit">
            <mat-spinner class="spin_16 mr-1" *ngIf="isSubmit"></mat-spinner>  
            {{ "Save" | translate }}
        </button>
    </div>
</mat-dialog-actions>
