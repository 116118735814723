import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReplaySubject, forkJoin, of } from 'rxjs';
import { debounceTime, last, map, switchMap } from 'rxjs/operators';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';

@Component({
  selector: 'app-group-edit',
  templateUrl: './group-edit.component.html',
  styleUrls: ['./group-edit.component.scss']
})
export class TaskGroupEditComponent extends BaseClass implements OnInit, OnDestroy {
  public form: FormGroup;
  public statuses: any;

  public groups$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public groupsMoreControl: FormControl = new FormControl();
  public groups: any;
  
  public isFormChange: boolean = false;
  public customIdValue: number;
  public isGetId: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<TaskGroupEditComponent>,
    private fb: FormBuilder,
    private taskService: TaskService,
    private companyService: CompanyService,
    private layoutService: LayoutService
  ) { super() }

  ngOnInit(): void {

    this.getGroupsCompany();

    this.attachSubscriptions(
      this.groupsMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchGroups(resp))
    )

    this.form = this.fb.group({
      group_id: this.data.task.group_id
    })

    this.attachSubscriptions(
      this.form.get('group_id').valueChanges.subscribe(res => {
        if (!this.form.get('custom_id')) {
          this.form.addControl('custom_id', this.fb.control(this.data.task.custom_id))
          this.form.get('custom_id').updateValueAndValidity({onlySelf:true})
        }
        this.getCustomId(res);
      })
    )

    this.attachSubscriptions(
      this.form.valueChanges.subscribe(() => this.isFormChange = true)
    )

    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        if (this.isFormChange) {
          this.layoutService.openBottomSheet(this.dialogRef);
        } else {
          this.close();
        }
      })
    )
  }

  getGroupsCompany() {
    this.attachSubscriptions(
      this.companyService.getInfiniteGroupsCompany(this.data.company_id, '1', '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.companyService.getInfiniteGroupsCompany(this.data.company_id, x).pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        this.groups = [].concat(...res);
        this.groups$.next(this.groups.slice());
      })
    )
  }

  onSearchGroups(resp) {
    if (!this.groups) {
      return;
    }

    if (!resp) {
      this.groups$.next(this.groups.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.groups$.next(
      this.groups.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  close() {
    this.dialogRef.close({event: "close", data: false});
  }

  pasteIdVal() {
    this.form.patchValue({
      custom_id: this.customIdValue
    })
    this.isGetId = false;
  }

  getCustomId(value) {
    this.attachSubscriptions(
      this.taskService.getCustomId(this.data.company_id, value).subscribe(resp => {
        this.customIdValue = +resp;
        this.isGetId = true;
      })
    )
  }

  submitForm() {
    if (this.data.task && this.data.task.channels && this.data.task.channels.length && this.form.value.group_id != this.data.task.group_id) {
      let deletedChannelsData = [];
      this.data.task.channels.filter(x => x.content_status_id == 1 && !x.content_url).forEach(channel => {
        deletedChannelsData.push({
          "path": `/api/task-channel/${channel.id}/`,
          "query": {},
          "method": "DELETE",
          "body": ''
        });
      });

      this.attachSubscriptions(
        this.taskService.multiRequest(deletedChannelsData).pipe(
          switchMap(() => this.taskService.editTask(this.data.task.id, this.form.value, this.data.company.id))
        ).subscribe(resp => {
          this.dialogRef.close({event: "Add", data: resp})
        })
      )
    } else {
      this.attachSubscriptions(
        this.taskService.editTask(this.data.task.id, this.form.value, this.data.company.id).subscribe(resp => {
          this.dialogRef.close({event: "Add", data: resp})
        })
      )
    }
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

}
