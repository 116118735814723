import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { ReplaySubject, Subject, animationFrameScheduler, forkJoin, interval } from 'rxjs';
import { debounceTime, last, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { FileService } from 'src/app/shared/services/rest/file.service';
import { SnackBarItem } from '../../../snack-bar/snack-bar-item';
import { SelectFileComponent } from '../select-file/select-file.component';
import { VideoEditorTimeFormatPipe } from 'src/app/shared/pipes/videoEditorTimeFormat.pipe';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { TargetParametersComponent } from 'src/app/components/atTasksDialog/target-parameters/target-parameters.component';
import { ScenariosService } from 'src/app/shared/services/rest/scenarios.service';
import { prioritys } from 'src/app/shared/consts/prioritys';

@Component({
  selector: 'app-video-edtior',
  templateUrl: './video-edtior.component.html',
  styleUrls: ['./video-edtior.component.scss']
})
export class VideoEdtiorComponent extends BaseClass implements OnInit, AfterViewInit, OnDestroy {
  public version: number = 1;

  @ViewChild('originalAudio') originalAudio: ElementRef;
  @ViewChild('outContainer') outContainer: ElementRef<HTMLDivElement>;
  @ViewChild('audioPlayer') audioPlayer: ElementRef;
  @ViewChild('originalAudioCanvas') originalAudioCanvas: ElementRef;
  @ViewChild('timeMarker', { static: true }) timeMarkerRef: ElementRef;
  @ViewChild('customTimeline', { static: true }) customTimelineRef: ElementRef;
  @ViewChild('player', { static: true }) videoPlayer!: ElementRef;
  @ViewChild('videoContainer') videoContainerRef!: ElementRef<HTMLDivElement>;
  @ViewChild('timelineThumbnails') timelineThumbnails!: ElementRef<HTMLDivElement>;
  @ViewChild('thumbnailCanvas') thumbnailCanvas!: ElementRef<HTMLCanvasElement>;
  @ViewChild('timelineCanvas', { static: true }) timelineCanvasRef: ElementRef<HTMLCanvasElement>;
  public duration: any = 0;
  public verticalLine: any = {
    val: 0,
    posX: 0,
    left: 0,
    show: false
  }
  public is_create_cards: FormControl = new FormControl(0)

  public cuts: any = [];
  public parameters: any[] = [];
  public jobParameters: any[] = [];
  public mode: any = 'view';
  public currentTime: any = 0;
  public cutEdited: any;
  public playCut: any;
  public audioFile: any;
  public taskTemplates: any;
  public isMediaMenuOpen: boolean = false;
  public submited: boolean = false;
  public isSubmit: boolean = false;
  
  public form: FormGroup;

  private destroy$ = new Subject<void>();
  
  public groups$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public groupsMoreControl: FormControl = new FormControl();
  public groups: any;
  
  public taskStatuses$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public taskStatusesMoreControl: FormControl = new FormControl();
  public taskStatuses: any;

  public operations$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public operationsMoreControl: FormControl = new FormControl();
  public operations: any;

  public employees$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public employeesMoreControl: FormControl = new FormControl();
  public employees: any;

  public statuses: any;

  public jobs:any = [];
  public prioritys = prioritys;
  private context: CanvasRenderingContext2D;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<VideoEdtiorComponent>,
    private fileService: FileService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    public scenariosService: ScenariosService,
    public companyService: CompanyService,
    public taskService: TaskService,
    public layoutService: LayoutService,
    private cdRef: ChangeDetectorRef
  ) { super() }

  ngOnInit(): void {
    console.log("VideoEdtiorComponent", this.data)

    this.form = this.fb.group({
      type: 0,
      group_id: "",
      status_id: 1,
      create_parameter_values_to_task: [[]],
      name: '',
      template_id: ''
    })

    this.form.valueChanges.subscribe(res => {
      console.log(res, this.form.value)
      this.cuts.forEach(cut => {
        this.setFormValueAsHeadForm(cut, false, true)
      })
      
      this.updateJobs()
    })

    this.attachSubscriptions(
      this.groupsMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchGroups(resp))
    )

    this.attachSubscriptions(
      this.taskStatusesMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchTaskStatuses(resp))
    )

    this.attachSubscriptions(
      this.employeesMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchEmoloyees(resp))
    )
    
    this.attachSubscriptions(
      this.operationsMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchOperations(resp))
    )

    this.getTaskTemplates();
    this.getGroups();
    this.getTaskStatus();

    this.getOperations();
    this.getOperationsStatus();
    this.getEmployees();

    this.data.file.is_audio_only = 1
    this.data.file.is_video_only = 1
  }

  onSearchEmoloyees(resp) {
    if (!this.employees) {
      return;
    }

    if (!resp) {
      this.employees$.next(this.employees.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.employees$.next(
      this.employees.filter(z => z.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  onSearchOperations(resp) {
    if (!this.operations) {
      return;
    }

    if (!resp) {
      this.operations$.next(this.operations.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.operations$.next(
      this.operations.filter(z => (z.name.toLowerCase().indexOf(resp) > -1))
    );
  }

  getEmployees() {
    this.attachSubscriptions(
      this.taskService.getEmployees(this.data.company.id).subscribe(resp => {
        console.log('getEmployees', resp)
        this.employees = resp;
        this.employees$.next(this.employees.slice())
      })
    )
  }

  getOperations() {
    this.attachSubscriptions(
      this.taskService.getOperations(this.data.company.id).subscribe(resp => {
        this.operations = resp;
        this.operations$.next(this.operations.slice())
      })
    )
  }

  getOperationsStatus() {
    this.attachSubscriptions(
      this.taskService.getOperationsStatus().subscribe(resp => {
        this.statuses = resp.slice();
      })
    )
  }

  selectTmpl(e, cut?) {
    console.log(e, cut)
    if (cut) {
      let tmpl = cut.taskTemplates.find(x => x.id == e.value)
      cut.taskTemplates.map(x => x.active = false)
      tmpl.active = true;
      cut.form.patchValue({
        type: 1,
        name: tmpl.template_data.name
      })
      cut.is_custom.patchValue(true)
    } else {
      let tmpl = this.taskTemplates.find(x => x.id == e.value)
      this.taskTemplates.map(x => x.active = false)
      tmpl.active = true;
      this.form.patchValue({
        name: tmpl.template_data.name,
        type: 1
      })
    }
  }

  getTaskTemplates() {
    this.attachSubscriptions(
      this.scenariosService.getTaskTemplates('1', {company_id: this.data.company.id}, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.scenariosService.getTaskTemplates(x, {company_id: this.data.company.id}, '200').pipe(map(u => u.body)))).pipe(
            last(),
            tap(values => {
              this.taskTemplates = [].concat(...values)
              this.taskTemplates.unshift({
                id: '',
                name: "Not set"
              })
              console.log("taskTemplates", this.taskTemplates)
            }),
          )
        }),
      ).subscribe(resp => {
        console.log("getTaskTemplates sub", resp);
      })
    )
  }

  selectType(type, cut?) {
    if (cut) {
      cut.form.patchValue({
        status_id: !!type.id ? type.id : type.value
      })
      cut.is_custom.patchValue(true)
    } else {
      this.form.patchValue({
        status_id: !!type.id ? type.id : type.value
      })
    }
  }

  getTaskStatus() {
    this.attachSubscriptions(
      this.taskService.getTaskStatuses(this.data.company_id).subscribe(resp => {
        console.log("getTaskStatuses", resp)
        this.taskStatuses = resp;
        this.taskStatuses$.next(this.taskStatuses.slice());
      })
    )
  }

  onSearchTaskStatuses(resp) {
    if (!this.taskStatuses) {
      return;
    }

    if (!resp) {
      this.taskStatuses$.next(this.taskStatuses.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.taskStatuses$.next(
      this.taskStatuses.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  // getStatuses() {
  //   this.attachSubscriptions(
  //     this.taskService.getTaskStatuses(this.data.company_id).subscribe(resp => {
  //       console.log("getTaskStatuses", resp)
  //       this.taskStatuses = resp;
  //       this.taskStatuses$.next(this.taskStatuses.slice())
  //     })
  //   )
  // }

  getGroups() {
    this.attachSubscriptions(
      this.companyService.getInfiniteGroupsCompany(this.data.company_id, '1', '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.companyService.getInfiniteGroupsCompany(this.data.company_id, x).pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        this.groups = [].concat(...res);
        this.groups$.next(this.groups.slice());
      })
    )
  }

  getGroupById(id) {
    if (!this.groups || this.groups.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.groups.find(el => el.id == id)
  }

  
  getStatusById(id) {
    if (!this.taskStatuses || this.taskStatuses.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.taskStatuses.find(el => el.id == id)
  }

  onSearchGroups(resp) {
    if (!this.groups) {
      return;
    }

    if (!resp) {
      this.groups$.next(this.groups.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.groups$.next(
      this.groups.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  onChangeCutType(cut) {
    cut.is_custom.patchValue(true)

  }

  selectGroup(group, cut?) {
    // if (!this.data.isMulti) {
    //   this.getCustomId(!!group.id ? group.id : group.value);
    // }
    if (cut) {
      cut.form.patchValue({
        group_id: !!group.id ? group.id : group.value
      })
      cut.is_custom.patchValue(true)
    } else {
      this.form.patchValue({
        group_id: !!group.id ? group.id : group.value
      })
    }
  }

  getCircularReplacer() {
    const ancestors:any = [];
    return function (key, value) {
      if (typeof value !== "object" || value === null) {
        return value;
      }
      // `this` is the object that value is contained in,
      // i.e., its direct parent.
      while (ancestors.length > 0 && ancestors.at(-1) !== this) {
        ancestors.pop();
      }
      if (ancestors.includes(value)) {
        return "[Circular]";
      }
      ancestors.push(value);
      return value;
    };
  }

  logCuts() {
    console.log("cuts", this.cuts);
    console.log("parameters", this.parameters);
    console.log("jobParameters", this.jobParameters);
    console.log("form", this.form.value);
    console.log("jobs", this.jobs);
    console.log("file", this.data.file);
  }

  addJob(cut?) {
    let jobData:any = {
      operation_id: 1,
      status_id: 1,
      priority: 0,
      name: '',
      comment: '',
      template_id: '',
      create_task_employees: [],
      parameters: this.jobParameters.slice()
    }

    if (this.employees.length > 0) {
      jobData.newExecutor = {
        employee_id: '',
        is_manager: 1
      }
    }

    if (cut) {
      if (!cut.hasOwnProperty('jobs')) {
        cut.jobs = []
      }
      cut.is_custom.patchValue(true)
      cut.jobs.push(jobData)
    } else {
      this.jobs.push(jobData)
      this.updateJobs()
    }

  }

  updateJobs() {
    this.cuts.filter(cut => !cut.is_custom.value).forEach(cut => {
      // let arr = []
      // this.jobs.forEach(el => {
      //   let x:any = {}
      //   Object.keys(el).forEach(key => {
      //     // if (key == 'parameters') {
      //     //   x[key] = [...el[key]]
      //     // } else {
      //     // }
      //     x[key] = JSON.parse(JSON.stringify(el[key], this.getCircularReplacer()))
      //   })
      //   arr.push(x)
      // });
      // cut.jobs = [...arr];
      cut.jobs = JSON.parse(JSON.stringify(this.jobs, this.getCircularReplacer()))
    })
    
  }

  deleteJob(i, cut?) {
    if (cut) {
      cut.is_custom.patchValue(true)
      cut.jobs.splice(i,1);
    } else {
      this.jobs.splice(i,1);
      this.updateJobs();
    }
  }

  checkIfDisabled(job, execId) {
    if (job.create_task_employees.filter(x => x.employee_id == execId).length > 0) {
      return true
    } else {
      return false
    }
  }

  ngAfterViewInit(): void {
    this.thumbnailCanvas.nativeElement.width = this.videoContainerRef.nativeElement.offsetWidth;
    this.originalAudioCanvas.nativeElement.width = this.videoContainerRef.nativeElement.offsetWidth;
    this.timelineCanvasRef.nativeElement.width = this.videoContainerRef.nativeElement.offsetWidth;

    const videoElement = this.videoPlayer.nativeElement;
    // const canvas = this.thumbnailCanvas.nativeElement;
    // const timelineThumbnails = this.timelineThumbnails.nativeElement;
    this.updateCurrentTime();

    videoElement.addEventListener('loadedmetadata', () => {
      // this.generateThumbnails(videoElement, canvas);
      this.duration = videoElement.duration
      this.context = this.timelineCanvasRef.nativeElement.getContext('2d');
      this.drawTimeline();
    });
  }

  updateCurrentTime() {
    let lastUpdateTime = performance.now();

    interval(100, animationFrameScheduler) // Emit a value every ~16ms (approximately 60 times per second)
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        const videoElement: HTMLVideoElement = this.videoPlayer.nativeElement;
        const now = performance.now();
        const elapsed = now - lastUpdateTime;

        // Update currentTime only if at least 1ms has passed
        if (elapsed >= 1) {
          this.currentTime = videoElement.currentTime;
          if (this.playCut) {
            if (videoElement.currentTime >= this.playCut.to) {
              videoElement.pause();
              videoElement.currentTime = this.playCut.to;
              this.playCut = undefined
            } 
            // else if (videoElement.currentTime < this.playCut.from) {
            //   videoElement.pause();
            //   videoElement.currentTime = this.playCut.from;
            //   this.playCut = undefined
            // }
          }

          lastUpdateTime = now;

          this.cdRef.markForCheck();
        }
      });
  }

  clickToCurrentTime(e) {
    e.stopPropagation();
    e.preventDefault();
    if (this.mode == 'cut') {
      this.addCut()
    }
  }

  changeMode(val) {
    this.mode = val
  }

  addCut() {
    console.log("ADD CUT")

    if (!this.checkIfCanAdd()) {
      return
    }
    

    let x:any = {
      spliter: {
        val: this.videoPlayer.nativeElement.currentTime
      },
      is_audio_only: 1,
      is_video_only: 1
    }

    if (this.cuts.length == 0) {
      let p = {
        spliter: {
          val:0
        },
        is_audio_only: 1,
        is_video_only: 1,
        parameters: JSON.parse(JSON.stringify(this.parameters, this.getCircularReplacer())),
        is_custom: new FormControl(false),
        taskTemplates: JSON.parse(JSON.stringify(this.taskTemplates)),
        name: !!this.data.file.extension && this.data.file.filename.indexOf('.'+this.data.file.extension) != -1 ? this.data.file.filename.replace('.'+this.data.file.extension, '-cut-1') : (this.data.file.filename + '-cut-1' )
      }
      this.setFormValueAsHeadForm(p)
      this.cuts.push(p)
      x.name = !!this.data.file.extension && this.data.file.filename.indexOf('.'+this.data.file.extension) != -1 ? this.data.file.filename.replace('.'+this.data.file.extension, '-cut-2') : (this.data.file.filename + '-cut-2' )
      
      x.is_custom = new FormControl(false)
      x.parameters = JSON.parse(JSON.stringify(this.parameters, this.getCircularReplacer()))
      x.taskTemplates = JSON.parse(JSON.stringify(this.taskTemplates))
      this.setFormValueAsHeadForm(x)
      this.cuts.push(x)
    } else {

      let insideRanges = this.cuts.filter(x => x.from < this.videoPlayer.nativeElement.currentTime && x.to > this.videoPlayer.nativeElement.currentTime)
      let insideRange;
      if (insideRanges.length > 0) {
        insideRange = insideRanges[0]
      }

      if (insideRange && insideRange.name) {
        if (insideRange.name.indexOf('-cut-') == -1) {
          insideRange.name = insideRange.name + '-cut-1'
        }
        x.name = insideRange.name
      }

      x.is_custom = new FormControl(false)
      x.parameters = JSON.parse(JSON.stringify(this.parameters, this.getCircularReplacer()));
      x.taskTemplates = JSON.parse(JSON.stringify(this.taskTemplates))
      this.setFormValueAsHeadForm(x)
      this.cuts.push(x)
    }

    this.updateCuts();
    this.updateJobs();

    console.log("this.cuts", this.cuts)
  }

  setFormValueAsHeadForm(cut:any, initValueChanges:boolean = true, isPatch:boolean = false) {
    console.log("cut", cut)
    if (!!cut.is_custom.value) {
      return
    }
    if (isPatch) {
      cut.form.patchValue({
        name: this.form.value.type == 0 ? ((cut.form && cut.form.value.name) ? cut.form.value.name : '') : this.form.value.name,
        type: this.form.value.type,
        group_id: this.form.value.group_id,
        status_id: this.form.value.status_id,
        create_parameter_values_to_task: this.form.value.create_parameter_values_to_task,
        template_id: this.form.value.template_id
      })
    } else {
      cut.form = this.fb.group({
        name: (cut.form && cut.form.value.name) ? cut.form.value.name : '',
        type: this.form.value.type,
        group_id: this.form.value.group_id,
        status_id: this.form.value.status_id,
        create_parameter_values_to_task: this.form.value.create_parameter_values_to_task,
        template_id: this.form.value.template_id,
      })
    }
    cut.taskTemplates = JSON.parse(JSON.stringify(this.taskTemplates))
    cut.parameters = JSON.parse(JSON.stringify(this.parameters, this.getCircularReplacer()));

    if (initValueChanges) {
      this.attachSubscriptions(
        cut.is_custom.valueChanges.subscribe(res => {
          console.log('is_custom', cut, res)
          // cut.form.patchValue({
          //   template_id: ''
          // })
          if (!res) {
            console.log('is_custom2', cut, res)
            this.setFormValueAsHeadForm(cut, false)
            // let arr = []
            // this.jobs.forEach(el => {
            //   let x:any = {}
            //   Object.keys(el).forEach(key => {
            //     if (key == 'parameters') {
            //       x[key] = [...el[key]]
            //     } else {
            //       x[key] = JSON.parse(JSON.stringify(el[key]))
            //     }
            //   })
            //   arr.push(x)
            // });
            // cut.jobs = [...arr];
            cut.jobs = JSON.parse(JSON.stringify(this.jobs, this.getCircularReplacer()))
          }
        })
      )

      // this.attachSubscriptions(
      //   cut.form.valueChanges.subscribe(res => {
      //     console.log("cut.form.valueChanges" + cut.name, res)
      //   })
      // )
    }

  }
  
  getEmployeeById(id) {
    return this.employees.find(x => x.id == id)
  }

  selectExec(job, cut, employee) {
    job.newExecutor.employee_id = employee.id; 
    this.onChangeCutType(cut)
  }

  addExecutor(job, cut?) {
    if (!job.newExecutor.employee_id) {
      this.layoutService.showSnackBar({name: ''}, marker("Choose an executor"), SnackBarItem)
      return;
    }
    job.create_task_employees.push(job.newExecutor);
    job.newExecutor = {
      employee_id: '',
      is_manager: 1
    }

    if (cut) {
      this.onChangeCutType(cut)
    } else {
      this.updateJobs();
    }
    
  }

  deleteExecutor(job, ind, cut?) {
    job.create_task_employees.splice(ind,1);

    if (cut) {
      cut.is_custom.patchValue(true)
    } else {
      this.updateJobs();
    }
  }

  onRemove(tagID, ind, cut?, job?) {
    if (job) {
      if (cut) {
        cut.is_custom.patchValue(true)
        job.parameters[0].activeValues.splice(ind, 1)
      } else {
        job.parameters[0].activeValues.splice(ind, 1)
        this.updateJobs();
      }
    } else {
      if (cut) {
        cut.is_custom.patchValue(true)
        cut.parameters[0].activeValues.splice(ind, 1)
        cut.form.patchValue({
          create_parameter_values_to_task: cut.parameters[0].activeValues.map(x => x.id)
        })
      } else {
        this.parameters[0].activeValues.splice(ind, 1)
        this.form.patchValue({
          create_parameter_values_to_task: this.parameters[0].activeValues.map(x => x.id)
        })
      }
    }
  }

  openTargetValues(cut?, job?) {
    let initData:any = {
      company: this.data.company,
      auto: true,
      parameters: !!job ? job.parameters : (!!cut ? cut.parameters : this.parameters)
    }

    const dialogRef = this.dialog.open(TargetParametersComponent, {
      backdropClass: ['parameters_modal_backdrop'],
      panelClass: ['without_paddings_modal', 'parameters_modal'],
      data: initData
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result) {
          if (result.event == 'save') {
            if (job) {
              if (cut) {
                job.parameters = result.data
                cut.is_custom.patchValue(true)
              } else {
                job.parameters = result.data
                this.updateJobs()
              }
            } else {
              if (cut) {
                cut.parameters = result.data
                cut.form.patchValue({
                  create_parameter_values_to_task: cut.parameters[0].activeValues.map(x => x.id)
                })
                cut.is_custom.patchValue(true)
              } else {
                this.parameters = result.data
                this.form.patchValue({
                  create_parameter_values_to_task: this.parameters[0].activeValues.map(x => x.id)
                })
              }
            }

          }
        }
      })
    )
  }

  hasValue() {
    if (this.cuts.length == 0) {
      return false
    }

    return !!this.cuts.filter(x => !!x.is_video_only || !!x.is_audio_only).length
  }

  checkboxLabel(cut?): string {
    if (!cut) {
      return `${this.isAllSelected() ? 'Deselect' : 'Select'} all`;
    }
    return `${(!!cut.is_video_only && !!cut.is_audio_only) ? 'Deselect' : 'Select'} ${cut.name}`;
  }

  isAllSelected() {
    const numSelected = this.cuts.filter(x => !!x.is_video_only && !!x.is_audio_only).length;
    const numRows = this.cuts.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
        this.cuts.map(x => {x.is_video_only = 0; x.is_audio_only = 0}):
        this.cuts.map(x => {x.is_video_only = 1; x.is_audio_only = 1});
  }

  
  updateCuts() {
    this.cuts.sort((a, b) => a.spliter.val - b.spliter.val);
    this.cuts.forEach((element, i) => {
      if (i == 0) {
        element.from = 0;
        if (this.cuts.length == 1) {
          element.to = element.spliter.val
        } else {
          element.to = this.cuts[i + 1].spliter.val
        }
      } else if (i == this.cuts.length - 1) {
        element.from = element.spliter.val;
        element.to = this.duration
      } else {
        element.from = this.cuts[i - 1].to;
        element.to = this.cuts[i + 1].spliter.val;
      }
      element.duration = Number(element.to - element.from)
      if (this.cuts.filter(u => u.name.indexOf(element.name.substring(0, +element.name.indexOf("-cut-") + 5)) != -1).length > 1) {
        this.cuts.filter(u => u.name.indexOf(element.name.substring(0, +element.name.indexOf("-cut-") + 5)) != -1).forEach((x,k) => {
          x.name = x.name.substring(0, +x.name.indexOf("-cut-") + 5) + (k+1)
        })
      }
    });

  }

  selectFile() {
    this.isMediaMenuOpen = false;

    const dialogRef = this.dialog.open(SelectFileComponent, {
      data: {
        company: this.data.company,
        task: this.data.task,
        work: this.data.work,
        user: this.data.user,
        audioFile: this.audioFile,
        only: 'audio'
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log("result", result)
        if (!!result && result.event == 'select' && result.data) {
          if (!this.audioFile || this.audioFile.id != result.data.id) {
            this.audioFile = result.data;
            // (document.getElementById('addOriginalAudioCanvas') as any).nativeElement.width = this.videoContainerRef.nativeElement.offsetWidth;
          } else {
            this.audioFile = undefined
          }
        }
      })
    )
  }

  toggleMediaMenu() {
    console.log(this.audioFile)
    this.isMediaMenuOpen = !this.isMediaMenuOpen 
  }

  deleteCut(cut, i) {
    if (this.cuts.length > 2) {
      this.cuts.splice(i,1)
      this.updateCuts()
    } else {
      this.cuts = []
    }
  }

  playAudio() {
    if (this.audioFile) {
      this.audioPlayer.nativeElement.currentTime = this.videoPlayer.nativeElement.currentTime
      this.audioPlayer.nativeElement.play();
    }
  }
  
  pauseAudio() {
    if (this.audioFile) {
      this.audioPlayer.nativeElement.currentTime = this.videoPlayer.nativeElement.currentTime
      this.audioPlayer.nativeElement.pause();
    }
  }



  checkIfCanAdd() {
    return this.videoPlayer.nativeElement.currentTime != this.duration ? (this.cuts.length ? this.cuts.filter(x => x.spliter.val == this.videoPlayer.nativeElement.currentTime).length == 0 : true) : false
  }
  // generateThumbnails(videoElement: HTMLVideoElement, canvas: HTMLCanvasElement) {
  //   const ctx = canvas.getContext('2d');
    
  //   // Configure thumbnail dimensions
  //   const thumbnailWidth = 50; // Adjust as needed
  //   const thumbnailHeight = 40; // Adjust as needed
    
  //   // Calculate number of thumbnails based on video duration and desired interval
  //   const videoDuration = videoElement.duration;
  //   const interval = 1; // Display one thumbnail per second, adjust as needed
  //   const numThumbnails = Math.ceil(videoDuration / interval);
    
  //   // Set canvas dimensions
  //   const canvasWidth = thumbnailWidth * numThumbnails;
  //   const canvasHeight = thumbnailHeight;
  //   canvas.width = canvasWidth;
  //   canvas.height = canvasHeight;
    
  //   // Clear canvas
  //   ctx.clearRect(0, 0, canvasWidth, canvasHeight);
    
  //   // Generate thumbnails
  //   for (let i = 0; i < numThumbnails; i++) {
  //     const thumbnailTime = i * interval;
  //     videoElement.currentTime = thumbnailTime;
      
  //     ctx.drawImage(videoElement, i * thumbnailWidth, 0, thumbnailWidth, thumbnailHeight);
      
  //     // Use the generated thumbnail image (ctx.getImageData()) as needed
  //     // You can also save the thumbnail image to an array or use it in other ways
  //   }
    
  //   // Calculate width of the timeline thumbnails container
  //   const timelineWidth = thumbnailWidth * numThumbnails;
  //   this.timelineThumbnails.nativeElement.style.width = `${timelineWidth}px`;
  // }

  onMouseLeave(e: MouseEvent) {
    // console.log("onMouseLeave",e)
    this.verticalLine.show = false;
  }

  onMouseOverVideo(e: MouseEvent) {
    // console.log("onMouseOverVideo",e)
  }
  onMouseOver(e: MouseEvent) {
    // console.log("onMouseOver",e)
  }

  drawTimeline() {
    const canvas = this.timelineCanvasRef.nativeElement;
    const context = this.context;
    const canvasWidth = canvas.width;
    const canvasHeight = canvas.height;
    const tickCount = 5; // Количество чёрточек
    const tickHeight = 50;
    const tickInterval = canvasWidth / tickCount;
  
    console.log("canvasWidth", canvasWidth) 
    console.log("canvasHeight", canvasHeight) 
    // Очистка канваса
    context.clearRect(0, 0, canvasWidth, canvasHeight);
  
    // Рисование прямоугольника
    context.fillStyle = '#686868';
    context.fillRect(0, canvasHeight - 1, canvasWidth, 1); // Нижняя полоска
  
    // Рисование чёрточек и подписей времени
    context.font = '12px Roboto';
    context.textAlign = 'right';
    context.fillStyle = '686868';
    context.fillText('00:00', 36, 10);
  
    for (let i = 0; i < tickCount; i++) {
      const x = i * tickInterval;
      const time = (i / tickCount) * this.duration;
  
      // Рисование вертикальной полоски
      context.fillRect(x, 0, 1, tickHeight);
  
      // Вывод подписи времени
      const timeText = new VideoEditorTimeFormatPipe().transform(time);
      context.fillText(timeText, x-5, 10);
    }
    
    // Вывод подписи начала и конца времени
    const endTimeText = new VideoEditorTimeFormatPipe().transform(this.duration);
    context.fillText(endTimeText, canvasWidth-6, 10);
    context.fillRect(canvasWidth-1, 0, 1, tickHeight);
  }

  onMouseMove(e: MouseEvent, timelineWidth) {
    // console.log("onMouseMove",e)
    const posX = e.clientX - this.outContainer.nativeElement.offsetLeft;
    const newTime = (posX / timelineWidth) * this.duration;
    this.verticalLine.val = newTime
    this.verticalLine.posX = posX

    if (e.buttons == 1) {
      if (!!this.cutEdited && !!this.cutEdited.is_active) {
        this.editCut(e.clientX, timelineWidth)
      } else {
        this.changeTime(newTime)
      }
    }
  }
  
  onMouseDown(e: MouseEvent) {
    // console.log("onMouseDown",e)
    e.preventDefault();

    if (!this.duration) {
      return
    }

    if (!this.cutEdited || !this.cutEdited.is_active) {
      const timelineWidth = this.customTimelineRef.nativeElement.offsetWidth;
      const clickX = e.clientX - this.outContainer.nativeElement.offsetLeft;
      const newTime = (clickX / timelineWidth) * this.duration;
  
      this.changeTime(newTime)
      const clickTarget = e.target as HTMLElement;
      console.log("clickTarget", clickTarget)   
      
      let check = !!this.customTimelineRef && !clickTarget.classList.contains('value') && !!this.timeMarkerRef && !this.customTimelineRef.nativeElement.contains(clickTarget) && !this.timeMarkerRef.nativeElement.contains(clickTarget)

      console.log("check", check)  
        
      if (this.mode == 'cut' && check) {
        this.addCut()
      }
    } else {
      this.changeTime(this.cutEdited.cut.spliter.val)
    }
  }

  onMouseEnter(e: MouseEvent) {
    // console.log("onMouseEnter",e)

    this.verticalLine.show = true;
  }


  // cut Events
  editCut(x, width) {
    let cut = this.cutEdited.cut;
    console.log("need edit cut", cut)
    let prevCut = this.cuts[this.cutEdited.ind-1]; 
    let posX = +(x - this.outContainer.nativeElement.offsetLeft)
    if (this.cutEdited.is_left) {
      posX = posX + +this.cutEdited.space
    } else {
      posX = posX - +this.cutEdited.space
    }
    console.log("need posX", posX)

    const newTime = (posX / width) * this.duration
    prevCut.to = newTime,
    prevCut.duration = Number(prevCut.to - prevCut.from)
    cut.from = prevCut.to
    cut.spliter.val = newTime;
    cut.duration = Number(cut.to - cut.from)
    console.log("need", cut)

    this.changeTime(newTime)
  }

  getItemById(arr, id) {
    return arr.find(x => x.id == id)
  }

  onMouseDownCut(e: MouseEvent, cut, ind, is_left:boolean = true, width) {
    // if (this.mode == 'cut') {
    //   return
    // }
    e.preventDefault();

    const posX = e.clientX - this.outContainer.nativeElement.offsetLeft
    console.log("posXposXposXposX", cut)
    console.log("posXposXposXposX", (cut.spliter.val / this.duration) * width)
    console.log("posXposXposXposX", width)
    this.cutEdited = {
      selected: true,
      ind: ind,
      cut: cut,
      is_left: is_left,
      space: Math.abs(posX - ((cut.spliter.val / this.duration) * width)),
      is_active: true
    }
    
    console.log("onMouseDownCut", this.cutEdited)
  }

  changeTime(newTime) {
    this.videoPlayer.nativeElement.currentTime = newTime;
  }

  onMouseUp(e: MouseEvent) {
    // console.log("onMouseUp",e)
    if (this.cutEdited) {
      this.cutEdited.is_active = false
    }
  }
  
  pauseVideo() {
    console.log("pauseVideo")
    const video = this.videoPlayer.nativeElement;
    // Остановка видео
    video.pause();
  }

  playVideo() {
    const video = this.videoPlayer.nativeElement;
    if (this.audioFile) {
      this.audioPlayer.nativeElement.currentTime = video.currentTime
    }
    // Воспроизведение видео
    video.play();
  }

  playVideoInterval(cut) {
    console.log("playVideoInterval")
    this.playCut = cut
    const video = this.videoPlayer.nativeElement;

    video.currentTime = cut.from;
    // if (!(video.currentTime >= cut.from && video.currentTime < cut.to)) {
    //   video.currentTime = cut.from;
    // }
    
    if (this.audioFile) {
      this.audioPlayer.nativeElement.currentTime = video.currentTime
    }
  
    // // Установка времени начала интервала

    // // Воспроизведение видео
    video.play();
  }

  onTimeUpdate(time: number) {
    this.currentTime = time;
  }

  close() {
    this.dialogRef.close({event: "close", data: false});
  }

  // output_location: this.data.file.location == "/" ? this.data.file.location + this.data.file.filename.replace('.'+this.data.file.extension, ' cuts') + "/cut files" : this.data.file.location + "/" + this.data.file.filename.replace('.'+this.data.file.extension, ' cuts') + "/cut files",
  save() {
    if (this.is_create_cards.value) {
      if (!this.cuts.length) {
        if (this.form.value.type == 0) {
          if (this.form.value.name == '') {
            this.submited = true;
            this.layoutService.showSnackBar({name: ''}, marker("Enter a name for the card."), SnackBarItem)
            return
          }
        } else {
          if (this.form.value.name == '' || this.taskTemplates.filter(p => p.active).length == 0) {
            this.submited = true;
            this.layoutService.showSnackBar({name: ''}, marker("You didn't enter a card name or didn't choose a template"), SnackBarItem)
            return
          }
        }

      } else {
        this.cuts.filter(x => !!x.is_audio_only || !!x.is_video_only).forEach(x => {
          if (x.form.value.type == 0) {
            if (x.form.value.name == '') {
              this.layoutService.showSnackBar({name: x.name}, marker("Enter a name for the card."), SnackBarItem)
            }
          } else {
            if (x.form.value.name == '' || x.taskTemplates.filter(p => p.active).length == 0) {
              this.layoutService.showSnackBar({name: x.name}, marker("You didn't enter a card name or didn't choose a template"), SnackBarItem)
            }
          }
        })
        
        
        if (this.cuts.filter(x => (!!x.is_audio_only || !!x.is_video_only) && ((x.form.value.type == 0 && x.form.value.name == '') || (x.form.value.type == 1 && (x.form.value.name == '' || x.taskTemplates.filter(p => p.active).length == 0)))).length > 0) {
          console.log("TEST")
          this.submited = true;
          return
        }
        
      }
    }

    console.log("TEST 2")
    this.submited = false;
    this.isSubmit = true;
    if (!this.audioFile) {
      if (this.cuts && this.cuts.length) {
        if (!this.hasValue()) {
          this.layoutService.showSnackBar({name: ''}, marker("In order to mount a file, you need to select at least 1 part (select its audio and / or video)."), SnackBarItem)
          return
        }
        this.attachSubscriptions(
          this.fileService.createVideoProject({
            editor_version: this.version.toString(),
            company_id: this.data.target_company_id,
            task_id: this.data.file.task_id,
            task_operation_id: this.data.file.task_operation_id,
            discussion_id: this.data.file.discussion_id,
            name: this.data.file.filename.replace('.'+this.data.file.extension, ' cuts'),
            output_location: this.data.file.location == "/" ? this.data.file.location + `cut files of ${this.data.file.filename.replace('.'+this.data.file.extension, '')}` : this.data.file.location + `/cut files of ${this.data.file.filename.replace('.'+this.data.file.extension, '')}`,
            is_draft: 1,
            output_width: this.data.file.meta_width,
            output_height: this.data.file.meta_height
          }, this.data.company_id).pipe(
            switchMap(project => {
              return this.fileService.createVideoProjectFile({
                company_id: this.data.target_company_id,
                video_project_id: project.id,
                file_id: this.data.file.id,
                is_input: 1,
              }, this.data.company_id).pipe(
                switchMap(original => {
                  let cutsArr = []
                  this.cuts.forEach(cut => {
                    let x:any = {
                      company_id: this.data.target_company_id,
                      video_project_id: project.id,
                      input_video_project_file_id: original.id,
                      output_filename: !!this.data.file.extension ? cut.name + `.${this.data.file.extension}` : cut.name,
                      part_start_ms: Math.round(cut.from*1000),
                      part_end_ms: Math.round(cut.to*1000),
                      is_part: 1
                    }
                    if (!!cut.is_video_only || !!cut.is_audio_only) {
                      x.is_output = 1;
                    }
                    if (!cut.is_video_only && !!cut.is_audio_only) {
                      x.is_audio_only = 1;
                    }
                    if (!!cut.is_video_only && !cut.is_audio_only) {
                      x.is_video_only = 1;
                    }
                    cutsArr.push(x)
                  })
                  return forkJoin([...cutsArr.map(cutData => this.fileService.createVideoProjectFile(cutData, this.data.company_id))]).pipe(
                    switchMap(parts => {
                      if (this.is_create_cards.value && this.cuts.filter(o => !!o.is_video_only || !!o.is_audio_only).length > 0) {
                        let cardsArr = []
                        this.cuts.filter(o => !!o.is_video_only || !!o.is_audio_only).forEach((cut, index) => {
                          let formData = {...cut.form.value}

                          delete formData.type
                          formData.create_parameter_values_to_task = []
                      
                          cut.parameters.filter(x => x.id != 0 && x.activeValues.length > 0).forEach(param => {
                            param.activeValues.forEach(el => {
                              formData.create_parameter_values_to_task.push({
                                parameter_id : param.id,
                                parameter_value_id : el.id
                              })
                            })
                          })

                          cut.jobs.forEach(job => {
                            delete job.newExecutor;
                            job.create_parameter_values_to_task = []
                            if (job.parameters && job.parameters.length > 0) {
                              job.parameters[0].activeValues.forEach(el => {
                                job.create_parameter_values_to_task.push({
                                  parameter_id : el.parameter_id,
                                  parameter_value_id : el.id
                                })
                              })
                            }

                            delete job.parameters;
                          })

                          formData.create_task_operations = cut.jobs.slice();

                          let u = {
                            video_project_id: project.id,
                            company_id: this.data.target_company_id,
                            template_data: cut.form.value.type == 0 ? formData : Object.assign(cut.taskTemplates.find(k => k.active).template_data, {name: cut.form.value.name}),
                            video_project_files: `${parts.filter(o => !!o.is_output)[index].id}`
                          }

                          cardsArr.push(u)
                          
                        })
                        return forkJoin([...cardsArr.map(cardData => this.fileService.createVideoProjectTaskFile(cardData, this.data.company_id))]).pipe(
                          switchMap(cardsReq => {
                            return this.fileService.editVideoProject(project.id, {
                              editor_version: this.version.toString(),
                              is_draft: 0,
                              is_to_process: 1
                            }, this.data.company_id).pipe(
                              map(editedProject => {
                                return {
                                  project: project,
                                  original: original,
                                  parts: parts,
                                  cardsReq: cardsReq,
                                  editedProject: editedProject
                                }
                              })
                            )
                          })
                        )
                      } else {
                        return this.fileService.editVideoProject(project.id, {
                          editor_version: this.version.toString(),
                          is_draft: 0,
                          is_to_process: 1
                        }, this.data.company_id).pipe(
                          map(editedProject => {
                            return {
                              project: project,
                              original: original,
                              parts: parts,
                              editedProject: editedProject
                            }
                          })
                        )
                      }
                    })
                  )
                })
              )
            })
          ).subscribe(resp => {
            console.log(resp)
            this.isSubmit = false;
            this.close();
          })
        )
      } else {
        this.attachSubscriptions(
          this.fileService.createVideoProject({
            editor_version: this.version.toString(),
            company_id: this.data.target_company_id,
            task_id: this.data.file.task_id,
            task_operation_id: this.data.file.task_operation_id,
            discussion_id: this.data.file.discussion_id,
            name: this.data.file.filename.replace('.'+this.data.file.extension, ' cuts'),
            output_location: this.data.file.location == "/" ? this.data.file.location + `cut files of ${this.data.file.filename.replace('.'+this.data.file.extension, '')}` : this.data.file.location + `/cut files of ${this.data.file.filename.replace('.'+this.data.file.extension, '')}`,
            is_draft: 1,
            output_width: this.data.file.meta_width,
            output_height: this.data.file.meta_height
          }, this.data.company_id).pipe(
            switchMap(project => {
              return this.fileService.createVideoProjectFile({
                company_id: this.data.target_company_id,
                video_project_id: project.id,
                file_id: this.data.file.id,
                is_input: 1
              }, this.data.company_id).pipe(
                switchMap(original => {
                  let outData:any = {
                    company_id: this.data.target_company_id,
                    video_project_id: project.id,
                    input_video_project_file_id: original.id,
                    is_output: 1,
                    output_filename: this.data.file.filename,
                  }
                  if (!this.data.file.is_video_only && !!this.data.file.is_audio_only) {
                    outData.is_audio_only = 1;
                  }
                  if (!!this.data.file.is_video_only && !this.data.file.is_audio_only) {
                    outData.is_video_only = 1;
                  }
                  if (!!outData.is_video_only || !!outData.is_audio_only) {
                    outData.is_part = 1;
                  }
                  return this.fileService.createVideoProjectFile(outData, this.data.company_id).pipe(
                    switchMap(output => {
                      if (this.is_create_cards.value) {
                        let formData = {...this.form.value}

                        delete formData.type
                        formData.create_parameter_values_to_task = []
                    
                        this.parameters.filter(x => x.id != 0 && x.activeValues.length > 0).forEach(param => {
                          param.activeValues.forEach(el => {
                            formData.create_parameter_values_to_task.push({
                              parameter_id : param.id,
                              parameter_value_id : el.id
                            })
                          })
                        })

                        this.jobs.forEach(job => {
                          delete job.newExecutor;
                          job.create_parameter_values_to_task = []
                          if (job.parameters && job.parameters.length > 0) {
                            job.parameters[0].activeValues.forEach(el => {
                              job.create_parameter_values_to_task.push({
                                parameter_id : el.parameter_id,
                                parameter_value_id : el.id
                              })
                            })
                          }

                          delete job.parameters;
                        })

                        formData.create_task_operations = this.jobs.slice();

                        let cardData = {
                          video_project_id: project.id,
                          company_id: this.data.target_company_id,
                          template_data: this.form.value.type == 0 ? formData : Object.assign(this.taskTemplates.find(k => k.active).template_data, {name: this.form.value.name}),
                          video_project_files: `${output.id}`
                        }
                        return this.fileService.createVideoProjectTaskFile(cardData, this.data.company_id).pipe(
                          switchMap(cardsReq => {
                            return this.fileService.editVideoProject(project.id, {
                              editor_version: this.version.toString(),
                              is_draft: 0,
                              is_to_process: 1
                            }, this.data.company_id).pipe(
                              map(editedProject => {
                                return {
                                  project: project,
                                  original: original,
                                  output: output,
                                  cardsReq: cardsReq,
                                  editedProject: editedProject
                                }
                              })
                            )
                          })
                        )
                      } else {
                        return this.fileService.editVideoProject(project.id, {
                          editor_version: this.version.toString(),
                          is_draft: 0,
                          is_to_process: 1
                        }, this.data.company_id).pipe(
                          map(editedProject => {
                            return {
                              project: project,
                              original: original,
                              output: output,
                              editedProject: editedProject
                            }
                          })
                        )
                      }
                    })
                  )
                })
              )
            })
          ).subscribe(resp => {
            console.log(resp)
            this.isSubmit = false;
            this.close();
          })
        )
      }
    } else {
      if (this.cuts && this.cuts.length) {
        // has cuts
        if (!this.hasValue()) {
          this.layoutService.showSnackBar({name: ''}, marker("In order to mount a file, you need to select at least 1 part (select its audio and / or video)."), SnackBarItem)
          return
        }
        this.attachSubscriptions(
          // add project
          this.fileService.createVideoProject({
            editor_version: this.version.toString(),
            company_id: this.data.target_company_id,
            task_id: this.data.file.task_id,
            task_operation_id: this.data.file.task_operation_id,
            discussion_id: this.data.file.discussion_id,
            name: this.data.file.filename.replace('.'+this.data.file.extension, ' cuts'),
            output_location: this.data.file.location == "/" ? this.data.file.location + `cut files of ${this.data.file.filename.replace('.'+this.data.file.extension, '')}` : this.data.file.location + `/cut files of ${this.data.file.filename.replace('.'+this.data.file.extension, '')}`,
            is_draft: 1,
            output_width: this.data.file.meta_width,
            output_height: this.data.file.meta_height
          }, this.data.company_id).pipe(
            // add video
            switchMap(project => {
              return this.fileService.createVideoProjectFile({
                company_id: this.data.target_company_id,
                video_project_id: project.id,
                file_id: this.data.file.id,
                is_input: 1
              }, this.data.company_id).pipe(
                // add new audio
                switchMap(original => {
                  return this.fileService.createVideoProjectFile({
                    company_id: this.data.target_company_id,
                    video_project_id: project.id,
                    file_id: this.audioFile.id,
                    is_input: 1
                  }, this.data.company_id).pipe(
                    map(p => {
                      return {
                        original: original,
                        addAudio: p
                      }
                    }),
                    // create mux - (original + addAudio) file
                    switchMap(vidAndAud => {
                      return this.fileService.createVideoProjectFile({
                        company_id: this.data.target_company_id,
                        video_project_id: project.id,
                        input_video_project_file_id: vidAndAud.original.id + ',' + vidAndAud.addAudio.id,
                        is_mux: 1,
                        is_mux_shortest_length: 1
                      }, this.data.company_id).pipe(
                        // cut mux file
                        switchMap(muxFile => {
                          let cutsArr = []
                          this.cuts.forEach(cut => {
                            let x:any = {
                              company_id: this.data.target_company_id,
                              video_project_id: project.id,
                              input_video_project_file_id: muxFile.id,
                              output_filename: !!this.data.file.extension ? cut.name + `.${this.data.file.extension}` : cut.name,
                              part_start_ms: Math.round(cut.from*1000),
                              part_end_ms: Math.round(cut.to*1000),
                              is_part: 1,
                            }
                            
                            if (!!cut.is_video_only || !!cut.is_audio_only) {
                              x.is_output = 1;
                            }
      
                            cutsArr.push(x)
                          })
                          // create cuts files for output
                          return forkJoin([...cutsArr.map(cutData => this.fileService.createVideoProjectFile(cutData, this.data.company_id))]).pipe(
                            // Start mounting
                            switchMap(parts => {
                              if (this.is_create_cards.value && this.cuts.filter(o => !!o.is_video_only || !!o.is_audio_only).length > 0) {
                                let cardsArr = []
                                this.cuts.filter(o => !!o.is_video_only || !!o.is_audio_only).forEach((cut, index) => {
                                  let formData = {...cut.form.value}

                                  delete formData.type
                                  formData.create_parameter_values_to_task = []
                              
                                  cut.parameters.filter(x => x.id != 0 && x.activeValues.length > 0).forEach(param => {
                                    param.activeValues.forEach(el => {
                                      formData.create_parameter_values_to_task.push({
                                        parameter_id : param.id,
                                        parameter_value_id : el.id
                                      })
                                    })
                                  })

                                  cut.jobs.forEach(job => {
                                    delete job.newExecutor;
                                    job.create_parameter_values_to_task = []
                                    if (job.parameters && job.parameters.length > 0) {
                                      job.parameters[0].activeValues.forEach(el => {
                                        job.create_parameter_values_to_task.push({
                                          parameter_id : el.parameter_id,
                                          parameter_value_id : el.id
                                        })
                                      })
                                    }
        
                                    delete job.parameters;
                                  })
        
                                  formData.create_task_operations = cut.jobs.slice();
                                  
                                  let u = {
                                    video_project_id: project.id,
                                    company_id: this.data.target_company_id,
                                    template_data: cut.form.value.type == 0 ? formData : Object.assign(cut.taskTemplates.find(k => k.active).template_data, {name: cut.form.value.name}),
                                    video_project_files: `${parts.filter(o => !!o.is_output)[index].id}`
                                  }
                                  cardsArr.push(u)
                                })
                                return forkJoin([...cardsArr.map(cardData => this.fileService.createVideoProjectTaskFile(cardData, this.data.company_id))]).pipe(
                                  switchMap(cardsReq => {
                                    return this.fileService.editVideoProject(project.id, {
                                      editor_version: this.version.toString(),
                                      is_draft: 0,
                                      is_to_process: 1
                                    }, this.data.company_id).pipe(
                                      map(editedProject => {
                                        return {
                                          project: project,
                                          original: original,
                                          parts: parts,
                                          cardsReq: cardsReq,
                                          editedProject: editedProject
                                        }
                                      })
                                    )
                                  })
                                )
                              } else {
                                return this.fileService.editVideoProject(project.id, {
                                  editor_version: this.version.toString(),
                                  is_draft: 0,
                                  is_to_process: 1
                                }, this.data.company_id).pipe(
                                  map(editedProject => {
                                    return {
                                      project: project,
                                      original: original,
                                      parts: parts,
                                      editedProject: editedProject
                                    }
                                  })
                                )
                              }
                            })
                          )
                        })
                      )
                    }) 
      
                  )
                })
              )
            })
          ).subscribe(resp => {
            console.log(resp)
            this.isSubmit = false;
            this.close();
          })
        )
      } else {
        // no cuts
        this.attachSubscriptions(
          // add project
          this.fileService.createVideoProject({
            editor_version: this.version.toString(),
            company_id: this.data.target_company_id,
            task_id: this.data.file.task_id,
            task_operation_id: this.data.file.task_operation_id,
            discussion_id: this.data.file.discussion_id,
            name: this.data.file.filename.replace('.'+this.data.file.extension, ' cuts'),
            output_location: this.data.file.location == "/" ? this.data.file.location + `cut files of ${this.data.file.filename.replace('.'+this.data.file.extension, '')}` : this.data.file.location + `/cut files of ${this.data.file.filename.replace('.'+this.data.file.extension, '')}`,
            is_draft: 1,
            output_width: this.data.file.meta_width,
            output_height: this.data.file.meta_height
          }, this.data.company_id).pipe(
            // add video
            switchMap(project => {
              return this.fileService.createVideoProjectFile({
                company_id: this.data.target_company_id,
                video_project_id: project.id,
                file_id: this.data.file.id,
                is_input: 1
              }, this.data.company_id).pipe(
                // add new audio
                switchMap(original => {
                  return this.fileService.createVideoProjectFile({
                    company_id: this.data.target_company_id,
                    video_project_id: project.id,
                    file_id: this.audioFile.id,
                    is_input: 1
                  }, this.data.company_id).pipe(
                    map(p => {
                      return {
                        original: original,
                        addAudio: p
                      }
                    }),
                    // create mux - (original + addAudio) file and output
                    switchMap(vidAndAud => {
                      return this.fileService.createVideoProjectFile({
                        company_id: this.data.target_company_id,
                        video_project_id: project.id,
                        input_video_project_file_id: vidAndAud.original.id + ',' + vidAndAud.addAudio.id,
                        is_mux: 1,
                        is_mux_shortest_length: 1,
                        is_output: 1,
                        output_filename: this.data.file.filename,
                      }, this.data.company_id).pipe(
                        // Start mounting
                        switchMap(muxFile => {
                          if (this.is_create_cards.value) {
                            let formData = {...this.form.value}

                            delete formData.type
                            formData.create_parameter_values_to_task = []
                        
                            this.parameters.filter(x => x.id != 0 && x.activeValues.length > 0).forEach(param => {
                              param.activeValues.forEach(el => {
                                formData.create_parameter_values_to_task.push({
                                  parameter_id : param.id,
                                  parameter_value_id : el.id
                                })
                              })
                            })

                            this.jobs.forEach(job => {
                              delete job.newExecutor;
                              job.create_parameter_values_to_task = []
                              if (job.parameters && job.parameters.length > 0) {
                                job.parameters[0].activeValues.forEach(el => {
                                  job.create_parameter_values_to_task.push({
                                    parameter_id : el.parameter_id,
                                    parameter_value_id : el.id
                                  })
                                })
                              }
  
                              delete job.parameters;
                            })
  
                            formData.create_task_operations = this.jobs.slice();

                            let cardData = {
                              video_project_id: project.id,
                              company_id: this.data.target_company_id,
                              template_data: this.form.value.type == 0 ? formData : Object.assign(this.taskTemplates.find(k => k.active).template_data, {name: this.form.value.name}),
                              video_project_files: `${muxFile.id}`
                            }
                            return this.fileService.createVideoProjectTaskFile(cardData, this.data.company_id).pipe(
                              switchMap(cardsReq => {
                                return this.fileService.editVideoProject(project.id, {
                                  editor_version: this.version.toString(),
                                  is_draft: 0,
                                  is_to_process: 1
                                }, this.data.company_id).pipe(
                                  map(editedProject => {
                                    return {
                                      project: project,
                                      original: original,
                                      muxFile: muxFile,
                                      cardsReq: cardsReq,
                                      editedProject: editedProject
                                    }
                                  })
                                )
                              })
                            )
                          } else {
                            return this.fileService.editVideoProject(project.id, {
                              editor_version: this.version.toString(),
                              is_draft: 0,
                              is_to_process: 1
                            }, this.data.company_id).pipe(
                              map(editedProject => {
                                return {
                                  project: project,
                                  original: original,
                                  muxFile: muxFile,
                                  editedProject: editedProject
                                }
                              })
                            )
                          }
                        })
                      )
                    }) 
      
                  )
                })
              )
            })
          ).subscribe(resp => {
            console.log(resp)
            this.isSubmit = false;
            this.close();
          })
        )
      }
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.clearSubscriptions()
  }
}
