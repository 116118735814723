import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';

@Component({
  selector: 'app-work-operation-id-edit',
  templateUrl: './work-operation-id-edit.component.html',
  styleUrls: ['./work-operation-id-edit.component.scss']
})
export class WorkOperationIdEditComponent extends BaseClass implements OnInit, OnDestroy {
  public operations: any;
  public form: FormGroup;
  public isFormChange: boolean = false;

  constructor(
    private taskService: TaskService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<WorkOperationIdEditComponent>,
    private fb: FormBuilder,
    private layoutService: LayoutService
  ) { super() }

  ngOnInit(): void {
    this.getOperations();
    
    this.form = this.fb.group({
      operation_id: [this.data.work.operation_id, Validators.required],
    })

    this.attachSubscriptions(
      this.form.valueChanges.subscribe(() => this.isFormChange = true)
    )

    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        if (this.isFormChange) {
          this.layoutService.openBottomSheet(this.dialogRef);
        } else {
          this.close();
        }
      })
    )

  }


  getOperations() {
    this.attachSubscriptions(
      this.taskService.getOperations(this.data.company.id).subscribe(resp => {
        this.operations = resp;
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  onSubmit() {
    this.attachSubscriptions(
      this.taskService.editWork(this.data.work.id, this.form.value, this.data.company.id).subscribe(resp => {
        this.dialogRef.close({event: "update", data: resp})
      })
    )
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

}
