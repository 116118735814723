<div class="cases_page">
    <div class="container">
        <div class="filter_out">  
            <div class="filter_values">
                <mat-chip-list class="chips_wrp without_ordered start_page_chips">
            
                    <mat-chip class="" *ngIf="filter.get('channel_group_id').value" (removed)="removeValue('group_id')" >
                        <span class="mr-1" style="font-weight: 500;">{{ "Group" | translate }}</span>
                        {{getGroup(filter.get('channel_group_id').value).name}}
                        <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
                    </mat-chip>

                    <mat-chip class="" *ngIf="filter.get('operation_id').value" (removed)="removeValue('task_operation_operation_id')" >
                        <span class="mr-1" style="font-weight: 500;">{{ "Job type" | translate }}</span>
                        {{getType(filter.get('operation_id').value).name}}
                        <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
                    </mat-chip>
                
                    <mat-icon class="remove_all_chips_btn" (click)="removeValue('all')" *ngIf="filter.get('channel_group_id').value || filter.get('operation_id').value">cancel</mat-icon>
                </mat-chip-list>
    
        
                <div class="stats_wrp">
                    <div class="tasks_counters">
                        <div class="counter_block" *ngIf="pagination && pagination['totalCount']" (click)="log()">
                            <div class="counter_top">{{ "Checklists" | translate }}</div>
                            <div class="counter_bottom">{{pagination['totalCount']}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container centred_page">

    <div class="step">
        <!-- <h3>{{ 'Checklists' | translate }}:</h3>

        <form [formGroup]="filter" class="filter_wrp d-f-c" style="justify-content: space-between;" (ngSubmit)="filterChecklists()">
            <div class="d-f-c">
                <mat-form-field appearance="legacy" class="filter_form">
                    <mat-label>{{ "Group" | translate }}</mat-label>
                    <mat-select [disableOptionCentering]="true" selectDir formControlName="channel_group_id" >
                        <mat-option>
                          <ngx-mat-select-search 
                              placeholderLabel="{{ 'Search' | translate }}" 
                              noEntriesFoundLabel="{{ 'no matching group found' | translate }}"
                              [formControl]="groupsControl"></ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let group of groups$ | async" [value]="group.id">
                            {{ group.name | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            
                <mat-form-field appearance="legacy" class="filter_form ml-1">
                    <mat-label>{{ "Type" | translate }}</mat-label>
                    <mat-select [disableOptionCentering]="true" selectDir formControlName="operation_id">
                        <mat-option *ngFor="let operatuib of operations" [value]="operatuib.id">
                            {{ operatuib.name | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        
            <div class="d-f-c">
                <button mat-button type="button" (click)="filterReset()">{{ "Reset" | translate }}</button>
                <button mat-raised-button class="ml-1" type="submit" color="primary">{{ "Filter" | translate }}</button>
            </div>
        </form> -->
        
        <div #tableContainer class="example-container mat-elevation-z8 employees_table">
            <table mat-table [dataSource]="checklist" class="centred_cols">
            <!-- ["Date", "Order", "Operation type", "Text"] -->
              <ng-container matColumnDef="Date">
                <th mat-header-cell *matHeaderCellDef> {{ "Created at" | translate }} </th>
                <td mat-cell *matCellDef="let element"> 
                    {{ element?.created_at*1000 | date:'mediumDate':'+0000':activeLang | yearPipe }}
                </td>
              </ng-container>

              <ng-container matColumnDef="Order">
                <th mat-header-cell *matHeaderCellDef> {{ "Order" | translate }} </th>
                <td mat-cell *matCellDef="let element"> 
                    {{ element?.order }}
                </td>
              </ng-container>
              
              <ng-container matColumnDef="Operation type">
                <th mat-header-cell *matHeaderCellDef> {{ "Job type" | translate }} </th>
                <td mat-cell *matCellDef="let element"> 
                    {{ element?.operation?.name }}
                </td>
              </ng-container>
              
              <ng-container matColumnDef="Group">
                <th mat-header-cell *matHeaderCellDef> {{ "Group" | translate }} </th>
                <td mat-cell *matCellDef="let element"> 
                    {{ element?.group?.name }}
                </td>
              </ng-container>

              <ng-container matColumnDef="Text">
                <th mat-header-cell *matHeaderCellDef> {{ "Text" | translate }} </th>
                <td mat-cell *matCellDef="let element"> 
                    {{ element?.text }}
                </td>
              </ng-container>

              <ng-container matColumnDef="Tag">
                <th mat-header-cell *matHeaderCellDef> {{ "Tag" | translate }} </th>
                <td mat-cell *matCellDef="let element"> 
                    <div class="d-f-c">
                        <ng-container *ngIf="!element?.exceptParameterValues?.length && !element?.toParameterValues?.length; else elseTemplateHasParameterConnection">      
                            <button mat-stroked-button (click)="connectionWithParameters(element, false)">{{ "Except" | translate }}</button>
                            <button class="ml-1" color="primary" mat-raised-button (click)="connectionWithParameters(element, true)">{{ "Only for" | translate }}</button>
              
                        </ng-container>
                        <ng-template #elseTemplateHasParameterConnection>
                            <ng-container *ngIf="element?.exceptParameterValues?.length; else elseTemplateHasExceptConnection">
                                <button mat-stroked-button (click)="connectionWithParameters(element, false)">{{ "For everyone except" | translate }}</button>
                                <ul>
                                    <li *ngFor="let item of element?.exceptParameterValues">{{ item?.parameterValue?.value }}</li>
                                </ul>
                            </ng-container>
                            <ng-template #elseTemplateHasExceptConnection>
                                <button color="primary" mat-raised-button (click)="connectionWithParameters(element, true)">{{ "Only for" | translate }}</button>
                                <ul>
                                    <li *ngFor="let item of element?.toParameterValues">{{ item?.parameterValue?.value }}</li>
                                </ul>
                            </ng-template>
                            
                        </ng-template>
                    </div>
                    
                </td>
              </ng-container>

              <ng-container matColumnDef="Auto set">
                <th mat-header-cell *matHeaderCellDef> {{ "Auto set" | translate }} </th>
                <td mat-cell *matCellDef="let element"> 
                    <mat-form-field appearance="standard" class="full_width_form">
                        <mat-label>{{ "Tag" | translate }}</mat-label>
                        <mat-select [disableOptionCentering]="true" selectDir [multiple]="false" [(ngModel)]="element.file_parameter_value_id" (selectionChange)="changeAutoCheckTag($event, element)">
                            <mat-select-trigger class="multi_users">
                                <ng-container *ngIf="element.file_parameter_value_id">
                                    <span>{{ getValuesById(element.file_parameter_value_id)?.value }}</span>
                                </ng-container>
                            </mat-select-trigger>
                            <mat-option>
                                <ngx-mat-select-search 
                                placeholderLabel="{{ 'Search' | translate }}" 
                                noEntriesFoundLabel="{{ 'no matching tag found' | translate }}"
                                [formControl]="allValuesControl"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let item of allValues$ | async" [value]="item.id">
                                <div class="d-f-c check_tag_in" style="justify-content: space-between;">
                                    <span class="text_one_line">{{ item.value }}</span>
                                    <span class="text_one_line">{{ item?.parameter?.name }}</span>
                                </div>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </td>
              </ng-container>

              <ng-container matColumnDef="Activity" *ngIf="company?.permissions.includes('owner') || company?.permissions.includes('admin') || company?.permissions.includes('manager')">
                <th mat-header-cell *matHeaderCellDef> {{ "Activity" | translate }} </th>
                <td mat-cell *matCellDef="let element">
                    <mat-icon *ngIf="company?.permissions.includes('owner') || company?.permissions.includes('manager') || company?.permissions.includes('admin')" style="color: green; cursor: pointer;" (click)="editChecklist(element)">edit</mat-icon>
                    <mat-icon *ngIf="company?.permissions.includes('owner') || company?.permissions.includes('admin')" style="color: red; cursor: pointer;" (click)="deleteChecklist(element)">delete</mat-icon>
                </td>
              </ng-container>       
          
              <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
              <tr mat-row *matRowDef="let element; columns: displayedColumns;"></tr>
    
            </table>
        </div>

        <div class="btns_line">
            <a mat-raised-button (click)="goBack($event)">
                {{ "Back" | translate }}
            </a>
            <a class="ml-1" *ngIf="company?.permissions.includes('owner') || company?.permissions.includes('admin')" (click)="addChecklist($event)" mat-raised-button color="primary">
                <mat-icon>add</mat-icon>
                {{ "Add checklist" | translate }}
            </a>
        </div>

    </div>
</div>