<h2 mat-dialog-title class="dialog_title">
    <ng-container *ngIf="data.needStatus; else elseTemplateChangeStatus">
        <span (click)="log()">{{ "Set status" | translate }}:</span>        
    </ng-container>
    <ng-template #elseTemplateChangeStatus>
        <span (click)="log()">{{ "Editing a profile" | translate }}: {{ data.profile.content_name.length > 20 ? (data.profile.content_name | slice:0:20) + " ..." : data.profile.content_name }}</span>
    </ng-template>
    
    <button mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h2>

<mat-dialog-content class="mat-typography"  dirEnter (dirSubmit)="!form.valid ? null : onSubmit()">
    <div class="container form_wrapper">
        <form [formGroup]="form">
            <div class="custom_radio">
                <label>{{ "Choose a status" | translate }}: </label>
                <mat-radio-group formControlName="content_status_id">
                    <ng-container *ngFor="let status of profileStatuses">
                        <ng-container *ngIf="!withoutToUpload() || status.id != 6">
                            <mat-radio-button color="primary" [disabled]="status.is_user == 0" [value]="status.id">{{ status.name+'_profile' | translate }}</mat-radio-button>
                        </ng-container>
                    </ng-container>
                </mat-radio-group>
            </div>
            <div class="error_label" *ngIf="data.profile && data.profile.publishing_error">
                {{data.profile.publishing_error}}
            </div>
        </form>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-raised-button (click)="close()">
        {{ "Back" | translate }}
    </button>
    <button class="ml-1 d-f-btn" (click)="onSubmit()" mat-raised-button cdkFocusInitial color="primary" [disabled]="!form.valid">
        {{ "Save" | translate }}
    </button>
</mat-dialog-actions>