<div class="companyes_page container">
    <h1 style="padding: 0 20px; font-weight: 500;" (click)="log()">{{"Workspace balance"}}: <b style="font-weight: 800;">${{workspaceBalance?.balance || 0}}</b></h1>
    <div class="sevice" (click)="openService()">
        <div class="active_block">
            <div class="line">
                <div class="name big">{{"Livestreams" | translate}}</div>
                <div class="price"></div>
            </div>
            <div class="line">
                <div class="name">{{(!!company?.live_stream_limit ? "Active" : "Not Active") | translate}}</div>
                <div class="count">{{company?.live_stream_limit}} {{"streams" | translate}}</div>
            </div>
        </div>
        <mat-divider class="mtb"></mat-divider>
        <div class="next_block">
            <div class="line">
                <div class="name">{{"Next month" | translate}} {{company?.planned_live_stream_limit}} {{"streams" | translate}}</div>
                <div class="price"></div>
            </div>
        </div>
        <ng-container *ngIf="!!nextPay">
            <div class="next_block pay_block">
                <div class="line">
                    <div class="name">
                        {{"Next payment" | translate}} {{nextPay?.planned_execution_at*1000 | date:'MMM dd, Y':'':activeLang | yearPipe }} {{"after" | translate}} {{nextPay?.planned_execution_at*1000 | date:'hh:00 a':'':activeLang }}
                        <button type="button" class="pay_now" (click)="$event.preventDefault(); $event.stopPropagation(); payNow()">
                            {{"Pay now" | translate}}
                        </button>
                    </div>
                    <div class="price">${{nextPay?.balance_movement}}</div>
                </div>
            </div>
            <div class="next_block error_block" *ngIf="nextPay?.message_error">
                <div class="line">
                    <div class="name small">
                        {{nextPay?.message_error}} 
                    </div>
                    <div class="price small">{{nextPay?.errored_at*1000 | date:'MMM dd. Y, hh:mm a':'':activeLang }}</div>
                </div>
            </div>
        </ng-container>
    </div>
</div>