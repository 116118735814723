<mat-dialog-content class="mat-typography">
    <div class="head_title" (click)="log()">
        <b>{{"Compilations Assistant" | translate}}</b>

        <div class="assist_collections_wrp" *ngIf="taskCollection?.selected?.length">
            <span>{{ "Actions with selected" | translate }}</span>
            <span>{{ taskCollection?.selected?.length }}</span>
            <span>{{ "card(s):" | translate }}</span>
        
            <div class="collections_btns">
              <button type="button" (click)="openTargetValues([0, 0, 0], null)">
                {{ "Add Tags" | translate }}
              </button>
              <button type="button" (click)="createJobs()">
                {{ "Add empty jobs" | translate }}
              </button>
            </div>
        </div>
    </div>
    <div class="elements" [appDeviceClass]="'custom-scroll custom-scroll-t'">
        <div class="filters">
            <div class="filter" *ngFor="let filter of filters; let i = index;">
                <div class="d-f-c">        
                    <mat-form-field appearance="legacy" class="full_width_form white_legacy_select" style="width: 150px;">
                        <ng-container *ngIf="submited && (!filter.group_id || filter.group_id == 'ignore') && haveErrorGroup">
                            <div class="req_field small"></div>
                            <div class="req_icon" #tooltip="matTooltip"
                            matTooltip="{{ 'You have selected a group in at least one other line. Therefore, you must either select a group here or remove the groups from the other lines.' | translate }}"
                            [matTooltipPosition]="'above'">
                                <mat-icon>error</mat-icon>
                            </div>
                        </ng-container>
                        <mat-label>{{"Group" | translate}}</mat-label>
                        <mat-select [disableOptionCentering]="true" selectDir [(ngModel)]="filter.group_id" (selectionChange)="changeReqSelect($event)">
                            <mat-option>
                                <ngx-mat-select-search 
                                placeholderLabel="{{ 'Search' | translate }}" 
                                noEntriesFoundLabel="{{ 'no matching group found' | translate }}"
                                [formControl]="groupMoreControl"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option [value]="'ignore'">
                                {{"Ignore group" | translate}}
                            </mat-option>
                            <mat-option *ngFor="let fil of groups$ | async" [value]="fil.id">
                                {{ fil.name | translate }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="legacy" class="ml-1 full_width_form white_legacy_select" style="width: 150px;">
                        <ng-container *ngIf="submited && !filter?.task_parameter_value_id?.length && haveErrorTags">
                            <div class="req_field small"></div>
                            <div class="req_icon" #tooltip="matTooltip"
                            matTooltip="{{ 'You have selected a tag in at least one other line. Therefore, you must either select a tag here or remove the tags from the other lines.' | translate }}"
                            [matTooltipPosition]="'above'">
                                <mat-icon>error</mat-icon>
                            </div>
                        </ng-container>
                        <mat-label>{{ "Card Tag(s)" | translate }}</mat-label>
                        <mat-select [disableOptionCentering]="true" selectDir [multiple]="true" [(ngModel)]="filter.task_parameter_value_id" (selectionChange)="changeReqSelect($event)">
                            <mat-select-trigger class="multi_users">
                                <ng-container *ngIf="filter.task_parameter_value_id && filter.task_parameter_value_id.length">
                                    <ng-container *ngFor="let id of filter.task_parameter_value_id">
                                        <span>{{ getValuesById(id)?.value }}</span>
                                    </ng-container>
                                </ng-container>
                            </mat-select-trigger>
                            <mat-option>
                                <ngx-mat-select-search 
                                placeholderLabel="{{ 'Search' | translate }}" 
                                noEntriesFoundLabel="{{ 'no matching tag(s) found' | translate }}"
                                [formControl]="allValuesControl"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let item of allValues$ | async" [value]="item.id">
                                <div class="d-f-c" style="justify-content: space-between;">
                                    <span>{{ item.value }}</span>
                                    <span>{{ item?.parameter?.name }}</span>
                                </div>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="legacy" class="ml-1 full_width_form white_legacy_select" style="width: 150px;">
                        <mat-label>{{"Job type" | translate}}</mat-label>
                        <mat-select [disableOptionCentering]="true" selectDir [(ngModel)]="filter.task_operation_operation_id">
                            <mat-option>
                                <ngx-mat-select-search 
                                placeholderLabel="{{ 'Search' | translate }}" 
                                noEntriesFoundLabel="{{ 'no matching job type found' | translate }}"
                                [formControl]="jobTypesControl"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let fil of jobTypes$ | async" [value]="fil.id">
                                {{ fil.name | translate }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="legacy" class="ml-1 full_width_form white_legacy_select" style="width: 150px;">
                        <mat-label>{{"Job status" | translate}}</mat-label>
                        <mat-select [disableOptionCentering]="true" selectDir [(ngModel)]="filter.operation_status_id">
                            <mat-option [value]="'ignore'">
                                {{"Ignore status" | translate}}
                             </mat-option>
                            <mat-option *ngFor="let fil of operationStatuses" [value]="fil.id">
                                {{ fil.name | translate }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    
                    <mat-form-field appearance="legacy" class="ml-1 full_width_form white_legacy_select" style="width: 150px;">
                        <mat-label>{{ "Job Tag" | translate }}</mat-label>
                        <mat-select [disableOptionCentering]="true" selectDir [multiple]="false" [(ngModel)]="filter.task_operation_parameter_value_id">
                            <mat-select-trigger class="multi_users" *ngIf="filter.task_operation_parameter_value_id && !['ignore', 'no'].includes(filter.task_operation_parameter_value_id)">
                                <span>{{ getValuesById(filter.task_operation_parameter_value_id)?.value }}</span>
                            </mat-select-trigger>
                            <mat-option>
                                <ngx-mat-select-search 
                                placeholderLabel="{{ 'Search' | translate }}" 
                                noEntriesFoundLabel="{{ 'no matching tag found' | translate }}"
                                [formControl]="allValuesControl"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option [value]="'ignore'">
                               {{"Ignore tags" | translate}}
                            </mat-option>
                            <mat-option [value]="'no'">
                               {{"No tags" | translate}}
                            </mat-option>
                            <mat-option *ngFor="let item of allValues$ | async" [value]="item.id">
                                <div class="d-f-c" style="justify-content: space-between;">
                                    <span>{{ item.value }}</span>
                                    <span>{{ item?.parameter?.name }}</span>
                                </div>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>


                </div>
    
    
                <div class="d-f-c" style="margin-left: 100px;">
                    <button class="show_on_hover" mat-icon-button (click)="delFilter(i)" *ngIf="filters.length > 1">
                        <mat-icon>delete</mat-icon>
                    </button>
                    <button class="show_on_hover" mat-icon-button (click)="duplicate(filter)">
                        <mat-icon>control_point_duplicate</mat-icon>
                    </button>
                    <ng-container *ngIf="i == filters.length - 1">
                        <button mat-icon-button (click)="addFilter()">
                            <mat-icon>add</mat-icon>
                        </button>
                        <button mat-icon-button [matMenuTriggerFor]="bookmarkMenu" #menuTrigger="matMenuTrigger">
                            <mat-icon>bookmarks</mat-icon>
                        </button>
                        <mat-menu #bookmarkMenu="matMenu" xPosition="before">
                            <button class="scheme_item" *ngFor="let item of bookmarks" (click)="selectPreset(item)" mat-menu-item [ngClass]="{'active': selectedPreset && selectedPreset.id == item.id}">
                                <span>{{ item.name }}</span>
                                <div class="d-f-c show_scheme_hover" *ngIf="is_scheme_editing">
                                    <mat-icon class="small_scheme_icon green" (click)="editPreset($event, menuTrigger, item, task)">edit</mat-icon>
                                    <mat-icon class="small_scheme_icon red" (click)="deletePreset($event, menuTrigger, item)">delete</mat-icon>
                                </div>
                            </button>
                            <button class="scheme_item scheme_item_create" mat-menu-item (click)="createNewPreset($event)">
                                <mat-icon>add</mat-icon>
                                <span>{{ "Create new preset" | translate }}</span>
                                <mat-icon style="margin-left: 50px !important; margin-right: 0 !important;" *ngIf="bookmarks && bookmarks.length" [ngStyle]="{'color': is_scheme_editing ? '#3f51b5' : '#686868'}" (click)="toggleSchemeEditing($event)">settings</mat-icon>
                            </button>
                        </mat-menu>

                        <button mat-raised-button style="color: #fff" class="ml-1" color="primary" (click)="filterCards()" [disabled]="isLoad" #tooltip="matTooltip"
                        matTooltip="{{ '3' | translate }}"
                        [matTooltipPosition]="'above'">
                            {{ "Get Cards" | translate }}
                        </button>

                        <button mat-icon-button *ngIf="tasks?.length == totalTasksCount && !!totalTasksCount" (click)="settingsData()">
                            <mat-icon>settings</mat-icon>
                        </button>
                    </ng-container>
                </div>
            </div>

            <div class="elements_out">
                <div class="elements_wrp sticky_main" [ngClass]="{'use_scheme': !!scheme && !!scheme.tags && !!scheme?.is_one}">
                    <div class="head" *ngIf="tasks && tasks.length" style="padding-left: 8px !important;">
                        <div class="check_wrp mr-5">
                            <mat-checkbox 
                            class="select_all_checkbox" 
                            *ngIf="taskCollection" 
                            #tooltip="matTooltip"
                            matTooltip="{{ checkboxLabel() }}"
                            [matTooltipPosition]="'above'" 
                            (click)="$event.preventDefault; $event.stopPropagation()" 
                            (change)="$event ? masterToggle() : null"
                              [checked]="taskCollection.hasValue() && isAllSelected()"
                              [indeterminate]="taskCollection.hasValue() && !isAllSelected()">
                            </mat-checkbox>
                          </div>
                        {{ "Card" | translate }}
                        <mat-icon (click)="sortCards('updated_desc')" class="sort_arrow" [ngClass]="{'active': sortValue == 'updated_desc'}" style="margin-right: 0 !important;">arrow_drop_down</mat-icon>
                        <mat-icon (click)="sortCards('updated')" class="sort_arrow" [ngClass]="{'active': sortValue == 'updated'}" style="margin-right: 0 !important;">arrow_drop_up</mat-icon>
                    </div>
                    <div class="element text_one_line" (click)="openTask(item)" *ngFor="let item of (allJobs.value || allHideJobs.value ? filteredTasks : tasks)">
                        <div class="check_wrp" [ngClass]="{'show': taskCollection.isSelected(item)}">
                            <mat-checkbox color="primary" class="example-margin" (click)="$event.stopPropagation()" (change)="$event ? toggleTask(item) : null" [checked]='taskCollection.isSelected(item)'></mat-checkbox>
                        </div>
                        <mat-icon class="cp" *ngIf="!!item?.is_warning_show" (click)="removeCard(item)"
                        #tooltip="matTooltip"
                        matTooltip="{{ 'This card no longer matches this filter. Click to remove it.' | translate }}"
                        [matTooltipPosition]="'above'" 
                        style="color: rgb(202, 65, 65); width: 12px; height: 12px; font-size: 12px; line-height: 12px;">warning</mat-icon>
                        <div class="ids">
                            <div>{{item.custom_id}}</div>
                            <div>{{item.internal_id}}</div>
                        </div>
                        <div class="name text_one_line">
                            {{item.name}}
                        </div>
                    </div>
                </div>
                <div class="elements_wrp scheme" *ngIf="scheme && scheme?.ccol3">
                    <div class="head">&nbsp;</div>
                    <div class="element text_one_line tag_td" *ngFor="let item of (allJobs.value || allHideJobs.value ? filteredTasks : tasks)">
                        {{ checkCCol3(item) ? scheme?.refl : ''}}
                    </div>
                </div>
                <div class="elements_wrp scheme tag_scheme views_scheme" *ngIf="scheme && scheme?.ccol2">
                    <div class="head tag_td">
                        {{"Total views"}}
                        <mat-icon (click)="sortCards('views_desc')" class="sort_arrow" [ngClass]="{'active': sortValue == 'views_desc'}" style="margin-right: 0 !important;">arrow_drop_down</mat-icon>
                        <mat-icon (click)="sortCards('views')" class="sort_arrow" [ngClass]="{'active': sortValue == 'views'}" style="margin-right: 0 !important;">arrow_drop_up</mat-icon>
                    </div>
                    <div class="element text_one_line tag_td" *ngFor="let item of (allJobs.value || allHideJobs.value ? filteredTasks : tasks)">
                        {{item?.totalViews | shortNumber}}
                    </div>
                </div>
                <div class="elements_wrp scheme" *ngIf="scheme && scheme?.ccol1">
                    <div class="head tag_td">{{"Tags for status" | translate}}</div>
                    <div class="element text_one_line tag_td" *ngFor="let item of (allJobs.value || allHideJobs.value ? filteredTasks : tasks)">
                        {{ checkCCol1(item)}}
                    </div>
                </div>
                <ng-container *ngIf="scheme && scheme.tags && !!scheme?.is_one">
                    <div class="elements_wrp scheme tag_scheme" *ngFor="let tag of scheme?.tags">
                        <div class="head tag_td">{{ tag.value }}</div>
                        <div class="element text_one_line tag_td" (click)="tdClick($event, getTag(item?.schemeTags, tag.id), item)" (contextmenu)="openTdContext($event, getTag(item?.schemeTags, tag.id), item); $event.preventDefault(); $event.stopPropagation();" [ngClass]="{
                            'first': getTag(item?.schemeTags, tag.id)?.job?.status_id == '1',
                            'second': getTag(item?.schemeTags, tag.id)?.job?.status_id == '2',
                            'third': getTag(item?.schemeTags, tag.id)?.job?.status_id == '3',
                            'fours': getTag(item?.schemeTags, tag.id)?.job?.status_id == '4',
                            'fives': getTag(item?.schemeTags, tag.id)?.job?.status_id == '5',
                            'sixs': getTag(item?.schemeTags, tag.id)?.job?.status_id == '6',
                            'nine': getTag(item?.schemeTags, tag.id)?.job?.status_id == '97',
                            'seven': getTag(item?.schemeTags, tag.id)?.job?.status_id == '98',
                            'eight': getTag(item?.schemeTags, tag.id)?.job?.status_id == '99'}" *ngFor="let item of (allJobs.value || allHideJobs.value ? filteredTasks : tasks)" #tooltip="matTooltip"
                            matTooltip="{{ getTag(item?.schemeTags, tag.id)?.job ? ('Tag: ' + tag.value + ' | cID: ' + item.custom_id + ' | Card name: ' + item.name + ' | Job status: ' + getStatusById(getTag(item?.schemeTags, tag.id)?.job?.status_id)?.name + ' | Job ID: ' + getTag(item?.schemeTags, tag.id)?.job?.id) : ''}}"
                            [matTooltipPosition]="'above'">
                            <div class="new_priority_line" *ngIf="getTag(item?.schemeTags, tag.id)?.job" [ngClass]="{
                                'Urgent': getTag(item?.schemeTags, tag.id)?.job?.priority == '1000',
                                'High': getTag(item?.schemeTags, tag.id)?.job?.priority == '900',
                                'Middle': getTag(item?.schemeTags, tag.id)?.job?.priority == '800',
                                'Low': getTag(item?.schemeTags, tag.id)?.job?.priority == '700',
                                'No': getTag(item?.schemeTags, tag.id)?.job?.priority == '0' || !getTag(item?.schemeTags, tag.id)?.job?.priority
                            }"></div>
                            <ng-container *ngIf="!!scheme?.is_two">
                                {{getTag(item?.schemeTags, tag.id)?.post?.content_views_count | shortNumber}}
                            </ng-container>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <div class="bottom_btns">
        <div class="d-f-c">
            <div>
                <div class="loader_line" *ngIf="!!totalTasksCount">
                    <div class="loader_out">
                        <mat-progress-spinner
                            [ngClass]="{'success': tasks.length == totalTasksCount}"
                            class="example-margin"
                            [color]="'primary'"
                            [mode]="'determinate'"
                            [value]="((tasks.length/totalTasksCount)*100).toFixed(0)">
                        </mat-progress-spinner>
                        <ng-container *ngIf="tasks.length == totalTasksCount; else elseTemplateGetFinishVal">
                            <span class="progress_val">
                                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" stroke="#44bd32" stroke-width="5" stroke-dasharray="100">
                                    <path id="check" d="M 12,22 L 22,31 L 36,13" stroke-dashoffset="0">
                                        <animate attributeName="stroke-dashoffset" from="100" to="0" repeatCount="1" dur="2s"></animate>
                                    </path>
                                </svg>
                            </span>
                        </ng-container>
                        <ng-template #elseTemplateGetFinishVal>
                            <span class="progress_val">{{((tasks.length/totalTasksCount)*100).toFixed(0)}}</span>
                        </ng-template>
                    </div>
                    <div class="loader_title">
                        <ng-container *ngIf="tasks.length == totalTasksCount; else elseTemplateGetFinish">
                            {{'All cards scanned' | translate}}. 
                        </ng-container>
                        <ng-template #elseTemplateGetFinish>
                            {{"Getting cards" | translate}} 
                            <span class="have">{{tasks.length}}</span> <span class="split">/</span> <span class="need">{{totalTasksCount}}</span>
                        </ng-template>
                    </div>
                </div>
                <div class="loader_line" *ngIf="!!totalTasksCount">
                    <div class="loader_out">
                        <mat-progress-spinner
                            [ngClass]="{'success': checkNeededEndCount() == totalTasksCount}"
                            class="example-margin"
                            [color]="'primary'"
                            [mode]="'determinate'"
                            [value]="((checkNeededEndCount()/totalTasksCount)*100).toFixed(0)">
                        </mat-progress-spinner>
                        <ng-container *ngIf="checkNeededEndCount() == totalTasksCount; else elseTemplateGetFinishVal">
                            <span class="progress_val">
                                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" stroke="#44bd32" stroke-width="5" stroke-dasharray="100">
                                    <path id="check" d="M 12,22 L 22,31 L 36,13" stroke-dashoffset="0">
                                        <animate attributeName="stroke-dashoffset" from="100" to="0" repeatCount="1" dur="2s"></animate>
                                    </path>
                                </svg>
                            </span>
                        </ng-container>
                        <ng-template #elseTemplateGetFinishVal>
                            <span class="progress_val">{{((checkNeededEndCount()/totalTasksCount)*100).toFixed(0)}}</span>
                        </ng-template>
                    </div>
                    <div class="loader_title">
                        <ng-container *ngIf="checkNeededEndCount() == totalTasksCount; else elseTemplateGetFinish">
                            {{'All tags checked' | translate}}. 
                        </ng-container>
                        <ng-template #elseTemplateGetFinish>
                            {{"Getting Tags" | translate}} 
                            <span class="have">{{checkNeededEndCount()}}</span> <span class="split">/</span> <span class="need">{{totalTasksCount}}</span>
                        </ng-template>
                    </div>
                </div>
                <div class="loader_line" *ngIf="!!totalTasksCount && scheme">
                    <div class="loader_out">
                        <mat-progress-spinner
                            [ngClass]="{'success': checkIsReady(tasks) == totalTasksCount}"
                            class="example-margin"
                            [color]="'primary'"
                            [mode]="'determinate'"
                            [value]="((checkIsReady(tasks)/totalTasksCount)*100).toFixed(0)">
                        </mat-progress-spinner>
                        <ng-container *ngIf="checkIsReady(tasks) == totalTasksCount; else elseTemplateGetFinishValScheme">
                            <span class="progress_val">
                                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" stroke="#44bd32" stroke-width="5" stroke-dasharray="100">
                                    <path id="check" d="M 12,22 L 22,31 L 36,13" stroke-dashoffset="0">
                                        <animate attributeName="stroke-dashoffset" from="100" to="0" repeatCount="1" dur="2s"></animate>
                                    </path>
                                </svg>
                            </span>
                        </ng-container>
                        <ng-template #elseTemplateGetFinishValScheme>
                            <span class="progress_val">{{((checkIsReady(tasks)/totalTasksCount)*100).toFixed(0)}}</span>
                        </ng-template>
                    </div>
                    <div class="loader_title">
                        <ng-container *ngIf="checkIsReady(tasks) == totalTasksCount; else elseTemplateGetFinishScheme">
                            {{'All cards scheme data scanned' | translate}}. 
                        </ng-container>
                        <ng-template #elseTemplateGetFinishScheme>
                            {{"Getting Scheme data" | translate}} 
                            <span class="have">{{checkIsReady(tasks)}}</span> <span class="split">/</span> <span class="need">{{totalTasksCount}}</span>
                        </ng-template>
                    </div>
                </div>
            </div>
            <div class="result" *ngIf="tasks.length == totalTasksCount && !!totalTasksCount && checkNeededEndCount() == totalTasksCount">
                {{(allJobs.value || allHideJobs.value ? filteredTasks : tasks).length}} {{"cards matched" | translate}}


                <ng-container *ngIf="allJobs.value || allHideJobs.value; else elseTemplateFilteredCards">         
                    <a *ngIf="filteredTasks.length < 250" href="/tasks?company_id={{data.company_id}}&id={{getFilTasksIds()}}&count=1" (click)="goToFilCards($event)">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" style="width: 18px; height: 18px;"><path d="M16 12V16H2V12H16ZM17 10H1C0.45 10 0 10.45 0 11V17C0 17.55 0.45 18 1 18H17C17.55 18 18 17.55 18 17V11C18 10.45 17.55 10 17 10ZM4 15.5C3.18 15.5 2.5 14.83 2.5 14C2.5 13.17 3.18 12.5 4 12.5C4.82 12.5 5.5 13.17 5.5 14C5.5 14.83 4.83 15.5 4 15.5ZM16 2V6H2V2H16ZM17 0H1C0.45 0 0 0.45 0 1V7C0 7.55 0.45 8 1 8H17C17.55 8 18 7.55 18 7V1C18 0.45 17.55 0 17 0ZM4 5.5C3.18 5.5 2.5 4.83 2.5 4C2.5 3.17 3.18 2.5 4 2.5C4.82 2.5 5.5 3.18 5.5 4C5.5 4.82 4.83 5.5 4 5.5Z" fill="#ccc"></path></svg>
                    </a>
                </ng-container>
                <ng-template #elseTemplateFilteredCards>
                    <a *ngIf="tasks.length < 250" href="/tasks?company_id={{data.company_id}}&id={{getTasksIds()}}&count=1" (click)="goToCards($event)">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" style="width: 18px; height: 18px;"><path d="M16 12V16H2V12H16ZM17 10H1C0.45 10 0 10.45 0 11V17C0 17.55 0.45 18 1 18H17C17.55 18 18 17.55 18 17V11C18 10.45 17.55 10 17 10ZM4 15.5C3.18 15.5 2.5 14.83 2.5 14C2.5 13.17 3.18 12.5 4 12.5C4.82 12.5 5.5 13.17 5.5 14C5.5 14.83 4.83 15.5 4 15.5ZM16 2V6H2V2H16ZM17 0H1C0.45 0 0 0.45 0 1V7C0 7.55 0.45 8 1 8H17C17.55 8 18 7.55 18 7V1C18 0.45 17.55 0 17 0ZM4 5.5C3.18 5.5 2.5 4.83 2.5 4C2.5 3.17 3.18 2.5 4 2.5C4.82 2.5 5.5 3.18 5.5 4C5.5 4.82 4.83 5.5 4 5.5Z" fill="#ccc"></path></svg>
                    </a>
                </ng-template>
                
            </div>
        </div>
        <div class="d-f-c">
            <ng-container *ngIf="scheme && scheme.tags && !!scheme?.is_one">
                <!-- <div class="box_wrp d-f-c" style="min-height: 40px; color: #fff;">
                    <mat-checkbox color="primary" [formControl]="allJobs" [disabled]="!allJobsStatus.length">
                        {{ "All jobs in status" | translate }}
                    </mat-checkbox>
                </div> -->
                <mat-form-field appearance="legacy" class="mr-1 full_width_form white_legacy_select" style="width: 150px;">
                    <mat-label>{{"Job statuses" | translate}}</mat-label>
                    <mat-select [multiple]="true" [disableOptionCentering]="true" selectDir [(ngModel)]="allJobsStatus" (selectionChange)="changeJobStatus($event)">
                        <mat-option *ngFor="let fil of operationStatuses" [value]="fil.id">
                            {{ fil.name | translate }}
                        </mat-option>
                    </mat-select>
                    <mat-hint style="color: #ccc; font-family: 'Roboto Condensed', sans-serif; margin-top: -2px; font-size: 75%;">{{"All columns in line must match the status(es)" | translate}}</mat-hint>
                </mat-form-field>

                <div class="d-f-c mr-1">
                    <div class="cp symbol" (click)="toggleSymbol()">
                        <ng-container *ngIf="symbolCtrl.value == '>'; else elseTemplateSymbol">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M6.5,2.27L20,10.14L6.5,18L5.5,16.27L16.03,10.14L5.5,4L6.5,2.27M20,20V22H5V20H20Z" /></svg>
                        </ng-container>
                        <ng-template #elseTemplateSymbol>
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M18.5,2.27L5,10.14L18.5,18L19.5,16.27L8.97,10.14L19.5,4L18.5,2.27M5,20V22H20V20H5Z" /></svg>
                        </ng-template>
                    </div>
                    <input style="color: #fff;" id="viewers" name="viewers" class="ve_input reports_white_input" style="max-width: 40px; text-align: right;" type="number" [formControl]="viewersCtrl" matInput placeholder="{{ 'Viewers' | translate }}">
                    <label style="color: #fff; white-space: nowrap;" class="viewers_label" for="viewers">{{"Total views" | translate}}</label>
                </div>

                <div class="d-f-c mr-1" style="flex-direction: column; height: 36px; justify-content: space-between;">
                    <div class="all_jobs" [ngClass]="{'active': allJobs.value}" (click)="toggleAllJobs()">
                        {{"Filter" | translate}}
                    </div>
                    <div class="all_jobs hide_jobs" [ngClass]="{'active': allHideJobs.value}" (click)="toggleAllHideJobs()">
                        {{"Hide" | translate}}
                    </div>
                </div>
            </ng-container>

            

            <button mat-raised-button (click)="close()">
                {{ "Close" | translate }}
            </button>

            <button class="ml-1" mat-raised-button color="primary" style="color: #fff;" (click)="compilations()" #tooltip="matTooltip"
            matTooltip="{{ '2' | translate }}"
            [matTooltipPosition]="'above'" [disabled]="stage != 'To Compilation wizard'">
                {{ stage | translate }}
            </button>
        </div>
    </div>
</mat-dialog-actions>


<ng-template #tdContext let-item>
    <section class="bckp-menu">
      <div (click)="closeTdContext(); editPriority(item)">
        <span>{{ "Edit priority" | translate }}</span>
      </div>
      <div (click)="closeTdContext(); editJobStatus(item)">
        <span>{{ "Edit Status" | translate }}</span>
      </div>
    </section>
  </ng-template>