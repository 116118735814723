import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { AuthService } from 'src/app/shared/services/rest/auth.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { BaseClass } from 'src/app/shared/models/base-class';
import { TasksHaveSameUrlComponent } from 'src/app/shared/global_components/tasks-have-same-url/tasks-have-same-url.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { SameUrlTasksComponent } from '../same-url-tasks/same-url-tasks.component';
import { concatMap, debounceTime, distinctUntilChanged, filter, last, map, switchMap, tap } from 'rxjs/operators';
import { concat, forkJoin, from, fromEvent, ReplaySubject, Subscription } from 'rxjs';
import { MatSelect } from '@angular/material/select';
import { GlobalDataService } from 'src/app/shared/services/common/global-data.service';
import { environment } from 'src/environments/environment';
import { LoadingService } from 'src/app/shared/services/rest/loading.service';
import { RefreshService } from 'src/app/shared/services/rest/refresh.service';

@Component({
  selector: 'app-add-channel-to-task',
  templateUrl: './add-channel-to-task.component.html',
  styleUrls: ['./add-channel-to-task.component.scss']
})


export class AddChannelToTaskComponent extends BaseClass implements OnInit, OnDestroy {
  @ViewChild('profilesContainer') profilesContainer: MatSelect;
  public profiles: any[] = [];
  public pagination: any;
  public page: number = 1;
  public host: any = environment.host;
  public imgRoute: any = '';
  public user: any = {};
  public user_id: number;
  public form: FormGroup;
  public company_id: any;
  public task_id: any;
  public profileStatuses: any;
  public profile_id: any;
  public isFormChange: boolean = false;
  public activeLang: any;
  public profilesMoreControl: FormControl = new FormControl();
  public profiles$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public newOutlets: any = [];
  public allContentTypes: any;

  public contentPagination: any;
  public contentPage: number = 1;

  public contentTypes: any[] = [];
  public contentTypeMoreControl: FormControl = new FormControl();
  public contentTypes$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public submited: boolean = false;

  public isSubmit: boolean = false;
  public eltsSub: Subscription;
  public cardsPage = 1;
  public cardsPagination: any;
  public cards: any = [];
  public isLoad: boolean = false;
  public progress:number = 0;

  constructor(
    private bottomSheet: MatBottomSheet,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddChannelToTaskComponent>,
    private sm: StorageManagerService,
    private auth: AuthService,
    private companyService: CompanyService,
    private globalDataService: GlobalDataService,
    private fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private taskService: TaskService,
    private layoutService: LayoutService,
    private languageService: LanguageService,
    private refreshService: RefreshService,
    public ls: LoadingService
  ) { super() }

  ngOnInit(): void {

    console.log("AddChannelToTaskComponent", this.data)
    window.scroll(0,0);
    this.company_id = this.data.company_id;
    this.task_id = this.data.task ? this.data.task.id : 0;

    this.form = this.fb.group({
      task_id: this.task_id,
      channel_id: ['', Validators.required],
      content_type_id: '',
      content_status_id: 1
    })

    if (this.data.is_all_results) {
      this.getCards(this.cardsPage);
    }
    
    if (this.data.tasks) {
      this.getAllContentTypes()
    }
    if (this.data.is_edit) {
      if (!!this.data.profile.content_status_id) {
        this.form.removeControl('content_status_id');
        this.form.updateValueAndValidity();
      }
      this.form.patchValue({
        task_id: this.task_id,
        channel_id: this.data.profile.channel_id,
        content_type_id: this.data.profile.content_type_id
      })

      if (this.data.profile.channel) {
        this.contentPage = 1;
        this.contentTypes = [];
        this.contentTypes$.next(this.contentTypes.slice());
        this.getContentTypes(this.data.profile.channel.platform_id, this.contentPage);
      }
    }


    this.attachSubscriptions(
      this.form.get("channel_id").valueChanges.subscribe(resp => {
        this.form.patchValue({
          content_type_id: ''
        })
        console.log('"channel_id" valueChanges', resp)
        if (!!resp && this.profiles.find(x => x.id == resp)) {
          let platform_id = this.profiles.find(x => x.id == resp).platform_id
          this.contentPage = 1;
          this.contentTypes = [];
          this.contentTypes$.next(this.contentTypes.slice());
          this.getContentTypes(platform_id, this.contentPage);
        }
      })
    )

    this.attachSubscriptions(
      this.form.valueChanges.subscribe(() => this.isFormChange = true)
    )

    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        if (this.isFormChange) {
          this.layoutService.openBottomSheet(this.dialogRef);
        } else {
          this.close();
        }
      })
    )

    this.attachSubscriptions(
      this.profilesMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchProfiles(resp))
    )

    this.attachSubscriptions(
      this.contentTypeMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchContentTypes(resp))
    )

    this.getImgRoute();
    this.getCompany();
    this.getCsrf();
    this.getUser();
    this.getProfiles();
    // this.getProfiles(this.company_id, this.page);
    this.getTaskProfiles();
    this.getLangData();

    // this.getProfilesStatus();
  }

  getCards(n) {
    this.isLoad = true;
    let filterData:any = {...this.data.filterData};

    this.eltsSub = this.taskService.getTasksSelectNoExpNew(n, this.data.company.id, filterData).pipe(
      tap(el => {
        this.cardsPagination = {
          'pageCount': el.headers.get('x-pagination-page-count'),
          'perPage': el.headers.get('x-pagination-per-page'),
          'totalCount': el.headers.get('x-pagination-total-count'),
          'currentPage': el.headers.get('x-pagination-current-page')
        }
        console.log("this.cardsPagination TEST", this.cardsPagination)
      }),
      map(el => el.body),
      distinctUntilChanged()
    ).subscribe(resp => {

      console.log("getCards", resp)
      
      if (this.cardsPage == 1) {
        this.cards = resp;
      } else {
        this.cards.push(...resp);
      }

      this.progress = +((this.cards.length / +this.cardsPagination.totalCount)*100).toFixed(1);
      this.cardsPage = this.cardsPage + 1;
      
      
      if (+this.cardsPagination['currentPage'] < +this.cardsPagination['pageCount'] && +this.cardsPagination['pageCount'] > 0) {
        this.getCards(this.cardsPage)
      } else {
        console.log("cards - " + this.cardsPage, this.cards)
        this.isLoad = false;
        this.progress = 100;
      }
    },
    error => {
      this.isLoad = false;
    })
  }

  onAdd() {
    if (!this.form.value.content_type_id) {
      this.submited = true;
      this.layoutService.showSnackBar({name: ''}, marker("You need to fill in all required fields"), SnackBarItem)
      return
    }
    this.submited = false;

    this.newOutlets.push({...this.form.value})
    
    this.contentPage = 1;
    this.contentTypes = [];
    this.contentTypes$.next(this.contentTypes.slice());
    this.form.patchValue({
      channel_id: ['', Validators.required],
      content_type_id: '',
    })
  }

  onRemove(i) {
    this.newOutlets.splice(i, 1)
  }

  onSearchContentTypes(resp) {
    if (!this.contentTypes) {
      return;
    }

    if (!resp) {
      this.contentTypes$.next(this.contentTypes.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.contentTypes$.next(
      this.contentTypes.filter(z => (z.name.toLowerCase().indexOf(resp) > -1))
    );
  }

  selectType(type) {
    this.form.patchValue({
      content_type_id: !!type.id ? type.id : type.value
    })
  }

  getContentById(id) {
    if (!this.allContentTypes || this.allContentTypes.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.allContentTypes.find(el => el.id == id)
  }

  getAllContentTypes() {
    this.attachSubscriptions(
      this.companyService.getContentTypes().pipe(
        tap(el => {
          this.contentPagination = {
            'pageCount': el.headers.get('x-pagination-page-count'),
            'perPage': el.headers.get('x-pagination-per-page'),
            'totalCount': el.headers.get('x-pagination-total-count'),
            'currentPage': el.headers.get('x-pagination-current-page'),
          }
        }),
        map(el => el.body)
      ).subscribe(resp => {
        this.allContentTypes = resp;
      })
    )
  }

  getContentTypes(platform_id, contentPage) {
    this.attachSubscriptions(
      this.companyService.getContentTypes(platform_id, contentPage).pipe(
        tap(el => {
          this.contentPagination = {
            'pageCount': el.headers.get('x-pagination-page-count'),
            'perPage': el.headers.get('x-pagination-per-page'),
            'totalCount': el.headers.get('x-pagination-total-count'),
            'currentPage': el.headers.get('x-pagination-current-page'),
          }
        }),
        map(el => el.body)
      ).subscribe(resp => {
        this.contentTypes.push(...resp);
        this.contentTypes$.next(this.contentTypes.slice());
        this.contentPage = this.contentPage + 1;
        console.log("getContentTypes", this.contentPage, this.contentTypes)

        if (this.contentPagination.pageCount >= this.contentPage) {
          this.getContentTypes(platform_id, this.contentPage);
        }
      })
    )
  }


  // getProfilesStatus() {
  //   this.attachSubscriptions(
  //     this.globalDataService.getContentStatus().subscribe(resp => {
  //       console.log("getContentStatus", resp);
  //       this.profileStatuses = resp.slice();
  //     })
  //   )
  // }

  // ngAfterViewInit(): void {
  //   this.attachSubscriptions(
  //     this.profilesContainer.openedChange.subscribe((e) => {
  //       if (e) {
  //         this.onScroll()
  //       }
  //     })
  //   )
  // }

  

  // onScroll() {
  //   this.attachSubscriptions(
  //     fromEvent(this.profilesContainer.panel.nativeElement, "scroll").pipe(
  //       filter((e:any) => e.target.scrollTop >=  e.target.scrollHeight - e.target.offsetHeight - 400),
  //       map(() => this.profilesContainer.panel.nativeElement.scrollTop),
  //       debounceTime(300),
  //       map(y => Math.ceil((y + this.profilesContainer.panel.nativeElement.innerHeight)/ (400))),
  //       distinctUntilChanged()
  //     ).subscribe(() => {
  //         if (this.page <= this.pagination['pageCount']) {
  //           this.getProfiles(this.company_id, this.page);
  //         }
  //       }
  //     )
  //   )
  // }

  onSearchProfiles(resp) {
    if (!this.profiles) {
      return;
    }

    if (!resp) {
      this.profiles$.next(this.profiles.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }

    // filter the banks
    this.profiles$.next(
      this.profiles.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  getProfile(id) {
    if (!this.profiles) {
      return false;
    }
    return this.profiles.find(el => el.id == id)
  }  

  // getProfiles(company_id, page) {
  //   this.attachSubscriptions(
  //     this.companyService.getProfiles(company_id, page).pipe(
  //       tap(el => {
  //         this.pagination = {
  //           'pageCount': el.headers.get('x-pagination-page-count'),
  //           'perPage': el.headers.get('x-pagination-per-page'),
  //           'totalCount': el.headers.get('x-pagination-total-count'),
  //           'currentPage': el.headers.get('x-pagination-current-page'),
  //         }
  //       }),
  //       map(el => el.body)
  //     ).subscribe(resp => {
  //       console.log("getProfiles", resp)
  //       this.profiles.push(...resp);
  //       this.profiles$.next(this.profiles.slice());
  //       this.page = this.page + 1;
  //     })
  //   )
  // }

  getProfiles() {
    this.attachSubscriptions(
      this.companyService.getAllProfilesDyn('1', this.company_id, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }
          return forkJoin(arr.map(x => this.companyService.getAllProfilesDyn(x, this.company_id, '200').pipe(map(u => u.body)))).pipe(
            last(),
            tap(values => {
              let conValues = [].concat(...values)
              this.profiles = conValues;
              this.profiles$.next(this.profiles.slice());
            }),
          )
        }),
      ).subscribe(resp => {
        console.log("getProfiles resp", resp);
        console.log("getProfiles profiles", this.profiles);
      })
    )
  }

  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData().subscribe(resp => {
        this.activeLang = resp.active;
      })
    )
  }

  getTaskProfiles() {
    this.attachSubscriptions(
      this.taskService.getTaskProfiles(this.task_id, this.company_id).subscribe(resp => console.log("PROFILES", resp))
    )
  }

  getCompany() {
    this.attachSubscriptions(
      this.companyService.getCompany(this.company_id).subscribe(resp => {
        this.companyService.company$.next(resp[0]);
      })
    )
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  getCsrf() {
    this.attachSubscriptions(
      this.auth.$userToken.subscribe(resp => {
        this.user_id = resp.user_id;
        this.sm.localStorageSetItem("csrf_param", resp.csrf_param)
        this.sm.localStorageSetItem("csrf_token", resp.csrf_token)
      })
    )
  }

  getUser() {
    this.attachSubscriptions(
      this.auth.$user.subscribe(resp => {
        this.user = resp;
      })
    )
  }
  
  onSubmit() {
    if (this.data.is_all_results) {
      if (!this.newOutlets.length) {
        this.submited = true;
        this.layoutService.showSnackBar({name: ''}, marker("You need to add outlet and type for multi adding"), SnackBarItem)
        return
      }
      this.isSubmit = true;
      this.submited = false;
      let sbmtData = [];
      let partsSbmtData = [];
  
      this.cards.forEach(task => {
        this.newOutlets.forEach(newOut => {
          sbmtData.push({
            "path": `/api/task-channel/`,
            "query": {company_id: this.company_id},
            "method": "POST",
            "body": Object.assign({
              [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
              task_id: task.id
          }, newOut) 
          })
        })
      });

      for (let i = 0; i < Math.ceil(sbmtData.length/10); i++) {
        partsSbmtData.push(sbmtData.slice(i*10, 10 + i*10))
      }
  
      let count = 0;
      concat(...partsSbmtData.map((param) => this.taskService.multiRequest(param))).pipe(
        tap(el => {
          this.ls.requests$.next({
            value: Math.round((100 / partsSbmtData.length) * (count+1)),
            target: "Adding Outlets"
          })
          if (count == 0) {
            this.isSubmit = false;
            this.dialogRef.close({event: "Add", data: null});
          }
          count++;
        })
      ).subscribe(res => {
        console.log(res);
      })
    } else if (this.data.tasks) {
      if (!this.newOutlets.length) {
        this.submited = true;
        this.layoutService.showSnackBar({name: ''}, marker("You need to add outlet and type for multi adding"), SnackBarItem)
        return
      }
      this.submited = false;

      let lenSelection = this.data.tasks.length;
      let count = 0;
      from(this.data.tasks).pipe(
        concatMap((tID:any) => {
          return forkJoin(this.newOutlets.map(newOut => this.taskService.addProfile(Object.assign(newOut, {task_id: tID}), this.company_id))).pipe(
            tap(addedNewOut => {
              this.refreshService.refreshTask$.next({company_id: this.company_id, task_id: tID})
              this.ls.requests$.next({
                value: Math.round((100 / lenSelection) * (count+1)),
                target: `Adding Outlets to ${lenSelection} cards`,
              })
              count++;
            })
          )
        })
      ).subscribe({
        next: (next) => {
          console.log("next onSubmit", next);
        },
        complete: () => {
          console.log("complete onSubmit");
          this.dialogRef.close({event: "Add"});
        },
        error: (error) => {
          console.log("error onSubmit", error)
        }
      })
    } else {
      if (this.newOutlets.length) {
        this.submited = false;

        let lenSelection = this.newOutlets.length;
        let count = 0;
        from(this.newOutlets).pipe(
          concatMap((newOut:any) => {
            return this.taskService.addProfile(Object.assign(newOut, {task_id: this.task_id}), this.company_id)
          })
        ).subscribe({
          next: (next) => {
            console.log("next onSubmit", next);
            this.refreshService.refreshTask$.next({company_id: this.company_id, task_id: this.task_id})
            this.ls.requests$.next({
              value: Math.round((100 / lenSelection) * (count+1)),
              target: `Adding ${lenSelection} Outlets to card`,
            })
            count++;
          },
          complete: () => {
            console.log("complete onSubmit");
            this.dialogRef.close({event: "Add"});
          },
          error: (error) => {
            console.log("error onSubmit", error)
          }
        })
      } else {
        if (!this.form.value.content_type_id) {
          this.submited = true;
          this.layoutService.showSnackBar({name: ''}, marker("You need to fill in all required fields"), SnackBarItem)
          return
        }
        this.submited = false;
    
        if (this.data.is_edit) {
          this.attachSubscriptions(
            this.taskService.editProfile(this.data.profile.id, this.form.value, this.company_id).subscribe(resp => {
              this.dialogRef.close({event: "edit", data: resp});
            })
          )
        } else {
          this.attachSubscriptions(
            this.taskService.addProfile(this.form.value, this.company_id).subscribe(resp => {
              this.dialogRef.close({event: "Add", data: resp});
            })
          )
        }
      }
    }

  }

  close() {
    this.dialogRef.close({event: "close", data: false});
  }

  ngOnDestroy(): void {
    if (this.eltsSub) {
      this.eltsSub.unsubscribe()
    }
    this.clearSubscriptions()
  }
}
