<div class="cases_page tasks_page">
    <div class="container">
      <div class="filter_out">  
        <div class="filter_values">
          <mat-chip-list class="chips_wrp without_ordered start_page_chips">
  
            <mat-chip (removed)="removeValue('q')" *ngIf="filter.get('q').value">
                {{ "Search" | translate }}: {{filter.get('q').value}}
              <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
            </mat-chip>

            <mat-chip (removed)="removeValue('department_id')" *ngIf="filter?.get('department_id')?.value">
                {{ "Department" | translate }}: {{ getDepById(filter?.get('department_id')?.value)?.name }}
                <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
            </mat-chip>

            <mat-chip (removed)="removeValue('department_position_id')" *ngIf="filter?.get('department_position_id')?.value">
                {{ "Position" | translate }}: {{ getPosById(filter?.get('department_position_id')?.value)?.name }}
                <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
            </mat-chip>
            
            <mat-chip (removed)="removeValue('permission')" *ngIf="filter?.get('permission')?.value">
                {{ "Permission" | translate }}: {{ filter?.get('permission')?.value }}
                <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
            </mat-chip>

            <mat-chip (removed)="removeValue('group_id')" *ngIf="filter?.get('group_id')?.value">
                {{ "Group" | translate }}: {{ getGroupById(filter?.get('group_id')?.value)?.name }}
                <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
            </mat-chip>
  
            <!-- <mat-chip (removed)="removeValue('content_type_id')" *ngIf="filter.get('content_type_id').value">
              <ng-container *ngIf="filter.get('content_type_id').value.toString() == '0'; else elseTemplateZeroContent">
                  {{ "Content Type" | translate }}: {{ "Not set" | translate }}
              </ng-container>
              <ng-template #elseTemplateZeroContent>
                  {{ "Content Type" | translate }}: {{ getContentById(filter.get('content_type_id').value)?.name }}
              </ng-template>
           
              <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
          </mat-chip>
  
          <ng-container *ngIf="filter.get('channel_id').value.length">
            <span class="chip_out_label">{{ "Outlets" | translate }}</span>
            <mat-chip [ngClass]="{'user_chip': id != 0}" *ngFor="let id of filter.get('channel_id').value" (removed)="removeValueFromMultiple('channel_id', id)">
              <ng-container *ngIf="id != 0; else elseTemplateOutletNotSet">
                <app-outlet-line [channel]="getChannelById(id)" [company_id]="company_id"></app-outlet-line>
              </ng-container>
              <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
            </mat-chip>
          </ng-container> -->
           
          <!-- || filter.get('id').value || filter.get('content_type_id').value || filter.get('channel_id').value.length -->
            <mat-icon *ngIf="filter.get('q').value || filter?.get('department_id')?.value || filter?.get('department_position_id')?.value || filter?.get('permission')?.value || filter?.get('group_id')?.value" class="remove_all_chips_btn" (click)="removeValue('all')">cancel</mat-icon>
          </mat-chip-list>
  
          <div class="stats_wrp ml-1">
            <div class="tasks_counters">
              
              <!-- <form [formGroup]="filter" class="preset_form" *ngIf="bookmarks && bookmarks.length > 0">
                <div class="simple_filter_snippet" (click)="presetFilter.open()">
                  <ng-container *ngIf="filter.get('preset_id').value; else elseTemplatePreset">
                    <span class="name">
                      {{ getBookmarkById(filter.get('preset_id').value)?.name }}
                    </span>
                  </ng-container>
                  <ng-template #elseTemplatePreset>
                    <span class="name">
                      {{ "Bookmark" | translate }}
                    </span>
                  </ng-template>
                  
  
                  <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_2474_38987)">
                    <path d="M7.91699 3.33325L2.08366 3.33325L5.00033 7.91658L7.91699 3.33325Z" fill="black"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_2474_38987">
                    <rect width="10" height="10" fill="white" transform="translate(10) rotate(90)"/>
                    </clipPath>
                    </defs>
                  </svg>
                </div>
  
                <mat-form-field appearance="legacy" class="filter_form preset_filter">
                  <mat-label>{{ "Bookmark" | translate }}</mat-label>
                  <mat-select #presetFilter [disableOptionCentering]="true" selectDir formControlName="preset_id" (selectionChange)="bookmarksChange($event)">
                      <mat-option *ngIf="bookmarks.length > 7">
                        <ngx-mat-select-search 
                            placeholderLabel="{{ 'Search' | translate }}" 
                            noEntriesFoundLabel="{{ 'no matching presets found' | translate }}"
                            [formControl]="bookmarksControl"></ngx-mat-select-search>
                      </mat-option>
                      <mat-option *ngFor="let bookmark of bookmarks$ | async" [value]="bookmark.id">
                        {{ bookmark.name | translate }}
                      </mat-option>
                  </mat-select>
                </mat-form-field>
              </form> -->
  
              <!-- <div class="counter_block" *ngIf="groupsPagination && groupsPagination['totalCount']">
                <div class="counter_top">{{ "Groups" | translate }}</div>
                <div class="counter_bottom">{{groupsPagination['totalCount']}}</div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
  
      <div class="lists_wrp">
      </div>   
    </div>
</div>
<div class="container centred_page">

    <div class="step">
        <mat-tab-group mat-align-tabs="center" class="scenarios_tabs" [selectedIndex]="selectedIndex" (selectedIndexChange)="changeTab($event)">
            <mat-tab>
                <ng-template mat-tab-label>
                    {{ 'Company members' | translate }}<ng-container *ngIf="is_deleted == 0">: {{pagination?.totalCount}}</ng-container>
                </ng-template>
 
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    {{ 'Deleted members' | translate }}<ng-container *ngIf="is_deleted == 1">: {{pagination?.totalCount}}</ng-container>
                </ng-template>
            
            </mat-tab>
        </mat-tab-group>
        
        <div #tableContainer class="example-container mat-elevation-z8 employees_table" [appDeviceClass]="'custom-scroll custom-scroll-t'">
            <table mat-table [dataSource]="employees" class="centred_cols">
          
              <ng-container matColumnDef="state">
                <th mat-header-cell *matHeaderCellDef> {{ "State" | translate }} </th>
                <td mat-cell *matCellDef="let element">

                    <div class="state" [ngClass]="{
                        'red': element.status == 'inactive',
                        'green': element.status == 'active',
                        'silver': !['inactive', 'active', 'break'].includes(element.status),
                        'yelow': element.status == 'break'
                    }"></div>
                </td>
              </ng-container>

              <ng-container matColumnDef="timer">
                <th mat-header-cell *matHeaderCellDef> {{ "Duration in work" | translate }} </th>
                <td mat-cell *matCellDef="let element">
                    <div *ngIf="!!element?.workDuration">
                        {{ element?.workDuration | timeFormat }}
                    </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="Date">
                <th mat-header-cell *matHeaderCellDef>
                    <div>{{ "Created" | translate }}</div>
                    <div>{{ "Updated" | translate }}</div>
                </th>
                <td mat-cell *matCellDef="let element">
                    <div style="font-size: 10px; white-space: nowrap;">{{element.created_at|reportsDate:activeLang}}</div>
                    <div style="font-size: 10px; white-space: nowrap;">{{element.updated_at|reportsDate:activeLang}}</div>
                </td>
              </ng-container>

              <ng-container matColumnDef="User">
                <th mat-header-cell *matHeaderCellDef> 
                  <!-- {{ "Virtual user" | translate }}  -->
                    <div>
                        {{ "Name" | translate }}
                    </div>
                    <div>
                        {{ "Description" | translate }}
                    </div>
                    <div>
                        {{ "Department / Postion" | translate }}
                    </div>
                </th>
                <td mat-cell *matCellDef="let element">
                    <div class="user_td">

                        <div class="icon">
                            <ng-container *ngIf="element?.user_id != '0'; else elseAccInviteTemplateIcon">
                              <div [appHoverUserInfo]="{ user: element, company_id: company_id }">
                                <ng-container *ngIf="!!element?.image; else elseTemplateUserHasDataImage">
                                    <img class="user_avatar" [src]="element?.image" alt="">
                                </ng-container>
                                <ng-template #elseTemplateUserHasDataImage>
                                    <mat-icon>account_circle</mat-icon>
                                </ng-template>
                              </div>
                            </ng-container>
                            <ng-template #elseAccInviteTemplateIcon>
                                <ng-container *ngIf="element?.invite == ''; else elseInviteTemplateIcon">
                                    <button class="i_btn like_img" (click)="generateInvite(element?.id, element?.user_id)"
                                    #tooltip="matTooltip"
                                    matTooltip="{{'Generate invitation link' | translate}}"
                                    [matTooltipPosition]="'above'">
                                        <mat-icon>add_link</mat-icon>
                                    </button>
                                </ng-container>
                                <ng-template #elseInviteTemplateIcon>
                                    <button class="i_btn like_img blue" ngxClipboard [cbContent]="origin+'/?code='+element?.invite" (click)="copyInvite(element)" 
                                    #tooltip="matTooltip"
                                    matTooltip="{{'Copy invitation link' | translate}}"
                                    [matTooltipPosition]="'above'">
                                        <mat-icon>content_copy</mat-icon>
                                    </button>
                                </ng-template>
                            </ng-template>
                        </div>
                        <div class="info">
                            <div class="v_user">
                                {{ element?.vuser?.name }} 
                            </div>
                            <ng-container *ngIf="element?.user_id != '0'; else elseAccInviteTemplate">
                                <div class="silver_name">
                                    <span class="text_one_line">{{ element?.name }}</span>
                                    <ng-container *ngIf="element?.mustAccept">                                
                                        <button type="button" class="custom_btn" (click)="Accept(element)">
                                            <mat-icon class="mr-1" >done</mat-icon>
                                            <span>{{ "Accept" | translate }}</span>
                                        </button>
                                    </ng-container>
                                </div>
                            </ng-container>
                            <ng-template #elseAccInviteTemplate>
                                <ng-container *ngIf="element?.invite != ''">
                                    <div class="silver_name">
                                        {{element?.invite}}
                                    </div>
                                </ng-container>
                            </ng-template>
                            <div class="positions" *ngIf="element.employeeToDepartmentPositions && element.employeeToDepartmentPositions.length">
                                <div class="pos" *ngFor="let item of element.employeeToDepartmentPositions">
                                    <span>{{item.department.name}}</span> 
                                    -
                                    <span>{{item.departmentPosition.name}}</span>
                                </div>
                            </div>
                            <div class="user_desc" *ngIf="element?.description">
                                <span class="text_one_line">{{element.description}}</span>
                            </div>
                            <div class="user_desc" *ngIf="element?.description">
                                <span class="text_one_line">{{element.description}}</span>
                            </div>
                            <div class="user_rules" *ngIf="element?.rules && element.rules.length">
                              <ng-container *ngFor="let rule of element?.rules; let last = last;">
                                  <span>{{rule.permission | translate}}</span><ng-container *ngIf="!last">, </ng-container>
                              </ng-container>
                            </div>
                        </div>
                        <ng-container *ngIf="element?.invite != '' && element?.user_id == '0'">
                            <div class="d-f-c">
                                <button class="i_btn ml-1" (click)="generateInvite(element?.id, element?.user_id)" 
                                #tooltip="matTooltip"
                                matTooltip="{{'Refresh invitation link' | translate}}"
                                [matTooltipPosition]="'above'">
                                    <mat-icon>refresh</mat-icon>
                                </button>
                                <button class="i_btn ml-1" (click)="removeInvite(element?.id, element?.user_id)" 
                                #tooltip="matTooltip"
                                matTooltip="{{'Delete invitation link' | translate}}"
                                [matTooltipPosition]="'above'">
                                    <mat-icon>link_off</mat-icon>
                                </button>
                            </div>
                        </ng-container>

                    </div>
                </td>
              </ng-container>
    
              <ng-container matColumnDef="Status">
                <th mat-header-cell *matHeaderCellDef> {{ "Member rules" | translate }} </th>
                <td mat-cell *matCellDef="let element">
                    <ng-container *ngIf="element?.user_id != company?.owner_user_id; else elseTemplateRules">
                        <button mat-icon-button (click)="rules(element)" #tooltip="matTooltip"
                        matTooltip="{{'Roles and permissions.' | translate}} {{'List of items user has access through direct assignment.' | translate}}"
                        [matTooltipPosition]="'above'"><mat-icon>rule</mat-icon></button>
                        <button mat-icon-button #tooltip="matTooltip"
                        matTooltip="{{'Automation of specifying the price for the execution of job by this executor' | translate}}"
                        [matTooltipPosition]="'above'" (click)="autoPrice(element)"><mat-icon>attach_money</mat-icon></button>
                        <button mat-icon-button #tooltip="matTooltip"
                        matTooltip="{{'User position.' | translate}} {{'Permissions within department.' | translate}}"
                        [matTooltipPosition]="'above'" (click)="positions(element)"><mat-icon>engineering</mat-icon></button>
                        <button mat-icon-button #tooltip="matTooltip"
                        matTooltip="{{'Automation of assigning a user to tasks' | translate}}"
                        [matTooltipPosition]="'above'" (click)="posAutos(element)"><mat-icon>settings_accessibility</mat-icon></button>
                        <button mat-icon-button #tooltip="matTooltip"
                        matTooltip="{{'What outlets user can publish to' | translate}}"
                        [matTooltipPosition]="'above'" (click)="openChannelToEmployee(element)"><mat-icon>public</mat-icon></button>
                        <button mat-icon-button #tooltip="matTooltip"
                        matTooltip="{{'What groups can see to filter and assign to cards' | translate}}"
                        [matTooltipPosition]="'above'" (click)="openChannelGroupToEmployee(element)"><mat-icon>group</mat-icon></button>
                        <button mat-icon-button (click)="openGroupToEmployee(element)"><mat-icon>groups</mat-icon></button>
                        <button mat-icon-button #tooltip="matTooltip"
                        matTooltip="{{'Access to full version media files' | translate}}"
                        [matTooltipPosition]="'above'" (click)="openFilesSettingsToEmployee(element)"><mat-icon>photo_size_select_large</mat-icon></button>
                        <button mat-icon-button #tooltip="matTooltip"
                        matTooltip="{{'Working hours / schedule' | translate}}"
                        [matTooltipPosition]="'above'" (click)="userCalendar(element)"><mat-icon>calendar_month</mat-icon></button>
                        <button mat-icon-button #tooltip="matTooltip"
                        matTooltip="{{'Interface limitation settings' | translate}}"
                        [matTooltipPosition]="'above'" (click)="foldersPermissions(element)"><mat-icon>folder_shared</mat-icon></button>
                    </ng-container>
                    <ng-template #elseTemplateRules>
                        {{ "Owner" | translate }}
                        <button mat-icon-button (click)="userCalendar(element)"><mat-icon>calendar_month</mat-icon></button>
                    </ng-template>
                </td>
              </ng-container>
              <ng-container matColumnDef="RSC">
                <th mat-header-cell *matHeaderCellDef> 
                    <div>{{ "Points" | translate }}</div>
                    <div>{{ "Salary" | translate }}</div>
                    <div>{{ "Currency" | translate }}</div>
                </th>
                <td mat-cell *matCellDef="let element"> 
                    <div>{{ !!(element?.rate_target*1) ? (element?.rate_target | reportsNumber) : "&nbsp;" }}</div>
                    <div>{{ !!(element?.salary*1) ? (element?.salary | reportsNumber) : "&nbsp;" }}</div>
                    <div>{{ getCurrency(element?.currency_id)?.short_name }}</div>
                </td>
              </ng-container>    

              <ng-container matColumnDef="Application status">
                <th mat-header-cell *matHeaderCellDef> {{ "Application status" | translate }} </th>
                <td mat-cell *matCellDef="let element"> 
                    <a [href]="host + '/settings?company_id=18&page=questionnaires&id=' + element.id" (click)="goToEmplApp($event, element.id)">{{ getStatusName(element?.application_status_id) }}</a>
                </td>
              </ng-container>
              
              <ng-container matColumnDef="BravoregId">
                <th mat-header-cell *matHeaderCellDef> 
                  {{ "ID" | translate }}
                    <!-- <div>
                        {{ "Bravoreg" | translate }}
                    </div>
                    <div>
                        {{ "id" | translate }} <ng-container *ngIf="!!user.is_root">/ {{ "external id" | translate }}</ng-container>
                    </div> -->
                </th>
                <td mat-cell *matCellDef="let element">
                    <div *ngIf="element?.user">
                        {{ element?.user?.id }} <span *ngIf="!!user.is_root" #tooltip="matTooltip"
                        matTooltip="{{'External bravoreg id' | translate}}"
                        [matTooltipPosition]="'above'">/ {{ element?.user?.external_id }}</span>
                    </div> 
                </td>
              </ng-container> 

              <ng-container matColumnDef="Activity">
                <th mat-header-cell *matHeaderCellDef>
                   <!--  {{ "Activity" | translate }}  -->
                </th>

                <td mat-cell *matCellDef="let element" style="position: relative;"> 
                    <mat-icon class="is_share" *ngIf="exportEmplData && exportEmplData.employee_id == element.id">ios_share</mat-icon>
                    <button class="show_on_hover_tr" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                      <button mat-menu-item (click)="editEmployee(element)">
                        <mat-icon>edit</mat-icon>
                        <span>{{"Edit" | translate}}</span>
                      </button>
                      <ng-container *ngIf="!!element.is_deleted">
                          <p style="margin: 0; padding: 0px 16px; font-size: 10px;">{{"Deleted at" | translate}}:<b class="ml-1">{{ element.deleted_at | reportsDate:activeLang }}</b></p>
                          <button mat-menu-item (click)="undeleteEmployee(element?.id)">
                            <mat-icon>delete_forever</mat-icon>
                            <span>{{"Return from deleted" | translate}}</span>
                          </button>
                          <mat-divider></mat-divider>
                      </ng-container>
                        
                
                        <button mat-menu-item (click)="deleteEmployee(element?.id)" *ngIf="!element.is_deleted">
                          <mat-icon>delete</mat-icon>
                          <span>{{"Delete" | translate}}</span>
                        </button>
                        <button mat-menu-item (click)="exportEmplSettings(element)">
                          <mat-icon>ios_share</mat-icon>
                          <span>{{"Export group settings" | translate}}</span>
                        </button>
                        <button mat-menu-item (click)="exportEmplToJobSettings(element)">
                          <mat-icon>ios_share</mat-icon>
                          <span>{{"Export auto jobs settings" | translate}}</span>
                        </button>
                        <button mat-menu-item *ngIf="exportEmplData && exportEmplData.groupsSettings && exportEmplData.employee_id != element.id" (click)="importEmplSettings(element)">
                          <mat-icon style="transform: rotate(90deg);">exit_to_app</mat-icon>
                          <span>{{"Import group settings" | translate}}</span>
                        </button>
                        <button mat-menu-item *ngIf="exportEmplData && exportEmplData.emplToJobSettings && exportEmplData.employee_id != element.id" (click)="importEmplToJobSettings(element)">
                          <mat-icon style="transform: rotate(90deg);">exit_to_app</mat-icon>
                          <span>{{"Import auto jobs settings" | translate}}</span>
                        </button>
                        <button mat-menu-item *ngIf="element?.user_id != company?.owner_user_id && (element?.invite != '' || element?.user_id != '0')" (click)="removeInvite(element?.id, element?.user_id)">
                            <mat-icon>link_off</mat-icon>
                            <span>{{"Remove Invite" | translate}}</span>
                        </button>
                    </mat-menu>
                      
                </td>
              </ng-container>       
          
              <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
              <tr mat-row *matRowDef="let element; columns: displayedColumns;" [ngClass]="{'deleted_empl': !!element.is_deleted}"></tr>
    
            </table>
        </div>

        <div class="btns_line">
            <a mat-raised-button (click)="goBack()">
                {{ "Back" | translate }}
            </a>
            <a class="ml-1" (click)="addEmployee($event)" mat-raised-button color="primary">
                <mat-icon>add</mat-icon>
                {{ "Add individual memeber" | translate }}
            </a>
        </div>

    </div>
</div>