import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { fromEvent } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseClass } from 'src/app/shared/models/base-class';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { FileService } from 'src/app/shared/services/rest/file.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-select-file',
  templateUrl: './select-file.component.html',
  styleUrls: ['./select-file.component.scss']
})
export class SelectFileComponent extends BaseClass implements OnInit, OnDestroy {
  public selectedCheck: any;
  public host: any = environment.host;
  public imgRoute: any;
  public is_mobile: boolean = false;
  public selectedFile:any = this.data.thumbFile;
  public selectedFiles:any = this.data.selectedFiles;
  public selectedFilesId:any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<SelectFileComponent>,
    private fileService: FileService,
    private sm: StorageManagerService
  ) { super() }

  ngOnInit(): void {
    this.data.dialogHasFm = true;
    if (this.selectedFiles) {
      this.selectedFilesId = this.selectedFiles.map(x => x.id)
    }
    console.log("SelectFileComponent", this.data);
    this.checkIsMobile();
    this.getImgRoute();
  }

  checkIsMobile() {
    if (window.innerWidth <= 769) {
      this.is_mobile = true;
    } else {
      this.is_mobile = false;
    }
    this.onResize();
  }

  selectAll() {
    console.log("selectAll")
    this.fileService.selectAllFiles$.next(true);
  }
  
  onResize() {
    this.attachSubscriptions(
      fromEvent(window, "resize").pipe(
        map(() => window.innerWidth)
      ).subscribe((wWidth) => {
          if (wWidth <= 769) {
            this.is_mobile = true;
          } else {
            this.is_mobile = false;
          }
        }
      )
    )
  }

  selectFile(file) {
    if (this.selectedFiles.filter(x => x.id == file.id).length == 0) {
      this.selectedFiles.push(file)
    } else {
      this.selectedFiles.splice(this.selectedFiles.indexOf(file), 1)
    }
    this.selectedFilesId = this.selectedFiles.map(x => x.id)
    console.log("selectedFilesId", this.selectedFilesId)
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }


  onImgError(event){
    event.target.src = this.imgRoute+'/assets/img/image_black_48dp.svg'
  }


  toggleFile(file) {
    this.selectedFile = file;

    console.log(this.selectedFile)
  }

  close() {
    if (!this.is_mobile) {
      this.dialogRef.close({event: "close", data: false})
    } else {
      this.dialogRef.removePanelClass('animate__slideInUp')
      this.dialogRef.addPanelClass('animate__slideOutDown')
      setTimeout(()=>{this.dialogRef.close({event: 'close', data: false})}, 300);
    }
  }

  save() {
    if (!this.is_mobile) {
      this.dialogRef.close({event: "select", data: this.data.is_multi ? this.selectedFiles : this.selectedFile})
    } else {
      this.dialogRef.removePanelClass('animate__slideInUp')
      this.dialogRef.addPanelClass('animate__slideOutDown')
      setTimeout(()=>{this.dialogRef.close({event: "select", data: this.data.is_multi ? this.selectedFiles : this.selectedFile})}, 300);
    }
  }

  ngOnDestroy() {
    this.clearSubscriptions()
  }

}
