import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { fromEvent, ReplaySubject } from 'rxjs';
import { debounceTime, map, switchMap, tap } from 'rxjs/operators';
import { DeleteAlertComponent } from 'src/app/shared/global_components/delete-alert/delete-alert.component';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { AddEmployeeToBookmarkComponent } from './dialogs/add-employee-to-bookmark/add-employee-to-bookmark.component';
import { EditBookmarkComponent } from './dialogs/edit-bookmark/edit-bookmark.component';

@Component({
  selector: 'app-bookmarks',
  templateUrl: './bookmarks.component.html',
  styleUrls: ['./bookmarks.component.scss']
})
export class BookmarksComponent extends BaseClass implements OnInit, OnDestroy {
  public filter: FormGroup = this.fb.group({
    employee_id: '',
    is_active: '',
    is_shortcut: '',
    section: '',
    type: ''
  })
  throttle = 100;
  scrollUpDistance = 3.5;
  public pagination: any;
  public page: number = 1;

  public company_id: any;
  public bookmarks: any = [];

  public isLoad: boolean = false;
  public is_mobile: boolean = false;
  public activeLang: any;

  @Input() host: any;
  @Input() user: any;
  @Input() company: any;
  @Input() imgRoute: any;

  public employees: any;
  public employees$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public employeesControl: FormControl = new FormControl();

  public sections: any = [{
    name: "Cards", 
    types: ['', 'Filter']
  }, {
    name: "Card", 
    types: ['', 'Clipboard']
  },{
    name: "Jobs", 
    types: ['', 'Filter', 'Layout template']
  }, {
    name: "Chats", 
    types: ['', 'Filter']
  }, {
    name: "Posts", 
    types: ['', 'Filter']
  }, {
    name: "Post Dashboards", 
    types: ['', 'Filter']
  }, {
    name: "Outlet Dashboards", 
    types: ['', 'Filter']
  }, {
    name: "Board by Order", 
    types: ['', 'Filter']
  }, {
    name: "Board by Date", 
    types: ['', 'Filter']
  }, {
    name: "Projects", 
    types: ['', 'Filter', 'Layout template']
  }, {
    name: "Document", 
    types: ['', 'AI']
  }]

  public types: any = ['', 'Filter', 'Layout template', 'AI', 'Clipboard']

  constructor(
    private fb: FormBuilder,
    private companyService: CompanyService,
    private languageService: LanguageService,
    private activatedRoute: ActivatedRoute,
    private bottomSheet: MatBottomSheet,
    private taskService: TaskService,
    private dialog: MatDialog,
    private layoutService: LayoutService,
  ) { super() }

  ngOnInit(): void {
    this.company_id = this.activatedRoute.snapshot.queryParamMap.get('company_id');
    this.getLangData();
    this.getBookmarks();
    this.attachSubscriptions(
      this.employeesControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchEmoloyees(resp))
    )

    this.checkIsMobile();
    this.onRouteChange();
    this.getEmployees();
  }

  getSection(val) {
    return this.sections.find(x => x.name == val)
  }

  checkIsMobile() {
    if (window.innerWidth <= 769) {
      this.is_mobile = true;
    } else {
      this.is_mobile = false;
    }
    this.onResize();
  }

  onResize() {
    this.attachSubscriptions(
      fromEvent(window, "resize").pipe(
        map(() => window.innerWidth)
      ).subscribe((wWidth) => {
          if (wWidth <= 769) {
            this.is_mobile = true;
          } else {
            this.is_mobile = false;
          }
        }
      )
    )
  }

  onRouteChange() {
    this.attachSubscriptions(
      this.activatedRoute.queryParams.pipe(
        tap((val) => {
          if (this.company_id != this.activatedRoute.snapshot.queryParamMap.get('company_id')) {
            this.company_id = this.activatedRoute.snapshot.queryParamMap.get('company_id');
            this.getEmployees();
            this.getBookmarks();
          }
        })
      ).subscribe(res => {
        console.log(res)
      })
    )
  }

  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData().pipe(
        tap(resp => {
          this.activeLang = resp.active;
        })
      ).subscribe(resp => {
        console.log("getLangData", resp)
      })
    )
  }

  editBookmark(bookmark, key) {
    this.attachSubscriptions(
      this.companyService.editBookmark(bookmark.id, {[key]: bookmark[key] == 1 ? 0 : 1}).subscribe(res => {
        bookmark[key] = res[key]
      }, error => {
        this.showError(error)
      })
    )
  }

  deleteBookmark(bookmark, k) {
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: {},
        target: marker("bookmark")
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        this.attachSubscriptions(
          this.companyService.deleteBookmark(bookmark.id).subscribe(resp => {
            this.bookmarks.splice(k, 1)
          }, error => {
            this.showError(error)
          })
        )
      }
    });

  }

  addEmployeeToBookmark(bookmark, k) {
    const dialogRef = this.dialog.open(AddEmployeeToBookmarkComponent, {
      backdropClass: ['backdrop_under_header'],
      panelClass: !this.is_mobile ? ['show_header'] : '',
      hasBackdrop: true,
      data: {
        user: this.user,
        company: this.company,
        host: this.host,
        imgRoute: this.imgRoute,
        activeLang: this.activeLang,
        bookmark: bookmark,
        employees: this.employees,
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result) {
          if (result.event == 'save') {
            this.updateBookmark(bookmark, k)
          }
        }
      })
    )
  }
  
  goToEditBookmark(bookmark, k) {
    const dialogRef = this.dialog.open(EditBookmarkComponent, {
      backdropClass: ['backdrop_under_header'],
      panelClass: !this.is_mobile ? ['show_header'] : '',
      hasBackdrop: true,
      data: {
        user: this.user,
        company: this.company,
        host: this.host,
        imgRoute: this.imgRoute,
        activeLang: this.activeLang,
        bookmark: bookmark
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result) {
          if (result.event == 'edit') {
            this.updateBookmark(bookmark, k)
          }
        }
      })
    )
  }

  updateBookmark(bookmark, ind) {
    this.attachSubscriptions(
      this.companyService.getBookmarksExp(this.company_id, 1, {id: [bookmark.id]}).pipe(
        map(x => x.body[0])
      ).subscribe(res => {
        this.bookmarks.splice(ind, 1, res)
      }, error => {
        this.showError(error)
      })
    )
  }

  emplEditBookmark(bookmark, key) {
    if (!bookmark.sectionToEmployee) {
      this.attachSubscriptions(
        this.companyService.addBookmarkEmpl({
          "company_id": this.company.id,
          "employee_id": this.company.employees[0].id,
          "section_id": bookmark.id,
          "order": 0,
          [key]: 1
        }).subscribe(res => {
          this.updateBookmark(bookmark, this.bookmarks.indexOf(bookmark))
        }, error => {
          this.showError(error)
        })
      )
    } else {
      this.editEmployeeBookmark(bookmark.sectionToEmployee, key)
    }
  }

  showError(error?) {
    this.layoutService.showSnackBar({name: ''}, marker(error ? error + ". You do not have permission to do this." : "You do not have permission to do this"), SnackBarItem)
  }


  deleteEmployeeBookmark(book, item, k) {
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: {},
        target: `bookmark for ${item.employee.name}`
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        this.attachSubscriptions(
          this.companyService.deleteBookmarkEmpl(item.id).subscribe(resp => {
            book.sectionToEmployees.splice(k, 1)
          }, error => {
            this.showError(error)
          })
        )
      }
    });

  }

  editEmployeeBookmark(item, key) {
    this.attachSubscriptions(
      this.companyService.editBookmarkEmpl(item.id, {[key]: item[key] == 1 ? 0 : 1}).subscribe(res => {
        item[key] = res[key];
      }, error => {
        this.showError(error)
      })
    )
  }

  getBookmarks() {
    this.isLoad = true;
    let params:any = {...this.filter.value}
    let getParams:any = {};
    Object.keys(params).forEach(key => {
      if (params[key].toString() != '') {
        getParams[key] = params[key]
      }
    })
    this.attachSubscriptions(
      this.companyService.getBookmarksExp(this.company_id, this.page, getParams).pipe(
        tap(el => {
          this.pagination = {
            'pageCount': el.headers.get('x-pagination-page-count'),
            'perPage': el.headers.get('x-pagination-per-page'),
            'totalCount': el.headers.get('x-pagination-total-count'),
            'currentPage': el.headers.get('x-pagination-current-page'),
          }
        }),
        map(x => x.body)
      ).subscribe(res => {
        if (this.page == 1) {
          this.bookmarks = res
        } else {
          this.bookmarks.push(...res)
        }
        this.isLoad = false;
        console.log("this.bookmarks", this.page, this.bookmarks)
        this.page++;
      })
    )
  }

  onDownBook(e) {
    console.log("SCROLL DOWN", e);
    if (this.pagination['pageCount'] >= this.page) {
      console.log("getNext Bookmarks")
      this.getBookmarks();
    }
  }

  onSearchEmoloyees(resp) {
    if (!this.employees) {
      return;
    }

    if (!resp) {
      this.employees$.next(this.employees.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.employees$.next(
      this.employees.filter(z => z.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  getEmployee(id) {
    if (!this.employees) {
      return false;
    }
    return this.employees.find(el => el.id == id)
  } 

  getEmployees() {
    this.attachSubscriptions(
      this.taskService.getEmployees(this.company_id).subscribe(resp => {
        this.employees = resp;
        this.employees$.next(this.employees.slice());
      })
    )
  }

  changeView(e: MatButtonToggleChange, key) {
    this.filter.patchValue({
      [key]: e.value
    })
    this.filterBookmarks();
  }

  removeValue(val) {
    if (val == "all") {
      this.filter.patchValue({
        employee_id: '',
        is_active: '',
        is_shortcut: '',
        section: '',
        type: ''
      })
    } else {
      this.filter.patchValue({
        [val]: ''
      })
    }

    this.filterBookmarks();
  }

  changeActiveStatus(val) {
    this.filter.patchValue({
      section: val
    })

    this.filterBookmarks();
  }
  
  selectChange(e) {
    console.log(e);
    this.filterBookmarks();
  }

  filterBookmarks() {
    console.log(this.filter.value)

    this.page = 1;
    this.getBookmarks();
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

}
