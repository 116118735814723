<div class="container cases_page">
    <div class="dashboard_wrp">
        <div class="dashboard">
            <div class="dash_item" (mouseenter)="!is_mobile ? onMouseEnter($event, 0) : null" (click)="!hoveredStatus[0].hover ? onMouseEnter($event, 0) : null;" [ngClass]="{'hovered': hoveredStatus[0].hover}">
                <div class="page_title">
                    <div class="page_name">
                        {{ 'Company structure' | translate }}
                    </div>
                    <div class="chat_icon_wrp">
                        <mat-icon>engineering</mat-icon>
                    </div>
                </div>

                <a href="/settings?company_id={{company?.id}}&page=structure" (click)='goToStructure($event, company?.id)' class="link-to-page page_btn hover_viss">
                    {{ "To page" | translate }}
                </a>
            </div>

            <div class="dash_item" (mouseenter)="!is_mobile ? onMouseEnter($event, 1) : null" (click)="!hoveredStatus[1].hover ? onMouseEnter($event, 1) : null;" [ngClass]="{'hovered': hoveredStatus[1].hover}">
                <div class="page_title">
                    <div class="page_name">
                        {{ 'Card Types' | translate }}
                    </div>
                    <div class="chat_icon_wrp">
                        <mat-icon>checklist_rtl</mat-icon>
                    </div>
                </div>

                <a href="/settings?company_id={{company?.id}}&page=task_types" (click)='goToTaskTypes($event, company?.id)' class="link-to-page page_btn hover_viss">
                    {{ "To page" | translate }}
                </a>
            </div>
 
            <div class="dash_item" (mouseenter)="!is_mobile ? onMouseEnter($event, 2) : null;" (click)="!hoveredStatus[2].hover ? onMouseEnter($event, 2) : null;" [ngClass]="{'hovered': hoveredStatus[2].hover}">
                <div class="page_title">
                    <div class="page_name">
                        {{ 'Job types' | translate }}
                    </div>
                    <div class="chat_icon_wrp">
                        <mat-icon>checklist_rtl</mat-icon>
                    </div>
                </div>

                <a class="link-to-page page_btn hover_viss" href="/settings?company_id={{company?.id}}&page=job_types" (click)='goToJobTypes($event, company?.id)'>
                    {{ "To page" | translate }}
                </a>
            </div>

            <div class="dash_item" (mouseenter)="!is_mobile ? onMouseEnter($event, 3) : null" (click)="!hoveredStatus[3].hover ? onMouseEnter($event, 3) : null;" [ngClass]="{'hovered': hoveredStatus[3].hover}">
                <div class="page_title">
                    <div class="page_name">
                        {{ 'Groups' | translate }}
                    </div>
                    <div class="chat_icon_wrp">
                        <mat-icon>groups</mat-icon>
                    </div>
                </div>

                <a href="/settings?company_id={{company?.id}}&page=company" (click)='goToGroups($event, company?.id)' class="link-to-page page_btn hover_viss">
                    {{ "To page" | translate }}
                </a>
            </div>

            <div class="dash_item" (mouseenter)="!is_mobile ? onMouseEnter($event, 4) : null" (click)="!hoveredStatus[4].hover ? onMouseEnter($event, 4) : null;" [ngClass]="{'hovered': hoveredStatus[4].hover}">
                <div class="page_title">
                    <div class="page_name">
                        {{ "Presets" | translate }}
                    </div>
                    <div class="chat_icon_wrp">
                        <mat-icon>bookmarks</mat-icon>
                    </div>
                </div>

                <a class="link-to-page page_btn hover_viss" href="/settings?company_id={{company?.id}}&page=bookmarks" (click)='goToBookmarks($event, company?.id)'>
                    {{ "To page" | translate }}
                </a>
            </div>

            <div class="dash_item" *ngIf="company?.permissions.includes('owner') || company?.permissions.includes('admin') || company?.permissions.includes('manager')" (mouseenter)="!is_mobile ? onMouseEnter($event, 5) : null" (click)="!hoveredStatus[5].hover ? onMouseEnter($event, 5) : null;" [ngClass]="{'hovered': hoveredStatus[5].hover}">
                <div class="page_title">
                    <div class="page_name">
                        {{ "Tags" | translate }}
                    </div>
                    <div class="chat_icon_wrp">
                        <mat-icon>settings_suggest</mat-icon>
                    </div>
                </div>

                <a class="link-to-page page_btn hover_viss" href="/settings?company_id={{company?.id}}&page=parameters" (click)='goToParameters($event, company?.id)'>
                    {{ "To page" | translate }}
                </a>
            </div>


        </div>
    </div>
</div>