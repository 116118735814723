import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fromnowtotime'
})
export class fromNowToTime implements PipeTransform {
  transform(timestamp: number, noSec:boolean = false) {
    let hasHour: boolean = false;
    let value = Math.round(timestamp - (Date.now() / 1000))
    let newValue = "";
    if (value > 3600) {
      newValue = Math.round(value / 3600) + "h"
      value %= 3600
      hasHour = true
    }
    if (value > 60) {
      newValue = newValue + " " + Math.round(value / 60) + "m"
      value %= 60
    }

    if (!noSec || (noSec && !hasHour)) {
      newValue = newValue + " " + value + "s"
    }


    return newValue
  }
}