import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import * as moment from 'moment';
import { concat, forkJoin, fromEvent, of, ReplaySubject, Subscription } from 'rxjs';
import { catchError, concatMap, debounceTime, distinctUntilChanged, filter, flatMap, last, map, switchMap, take, tap, toArray } from 'rxjs/operators';
import { ConnectionsModalComponent } from 'src/app/shared/global_components/connections-modal/connections-modal.component';
import { DeleteAlertComponent } from 'src/app/shared/global_components/delete-alert/delete-alert.component';
import { NotificationsBar } from 'src/app/shared/global_components/notifications-bar/notifications-bar';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { AuthService } from 'src/app/shared/services/rest/auth.service';
import { ChatService } from 'src/app/shared/services/rest/chat.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import SwiperCore, { Pagination,  Mousewheel } from 'swiper/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatSelect } from '@angular/material/select';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { InterfaceComponent } from 'src/app/shared/global_components/file-manager/dialog/interface/interface.component';
import { MatDatepickerInputEvent, MatDateRangePicker } from '@angular/material/datepicker';
import { casesModel } from 'src/app/shared/functions/casesModel';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { LoadingService } from 'src/app/shared/services/rest/loading.service';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { RefreshService } from 'src/app/shared/services/rest/refresh.service';
import { GlobalDataService } from 'src/app/shared/services/common/global-data.service';
import { ApproveRejectFilesComponent } from 'src/app/shared/global_components/approve-reject-files/approve-reject-files.component';
import { MY_FORMATS } from '../../../workspace-settings/finances/statements/statements.component';
import { OpenJobComponent } from '../dialogs/open-job/open-job.component';
import { OpenTaskComponent } from '../dialogs/open-task/open-task.component';
import { ChatsComponent } from '../../../chats/chats.component';
import { OperationChatComponent } from '../../../atTasksDialog/operation-chat/operation-chat.component';
import { MatSelectionListChange } from '@angular/material/list';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { FileService } from 'src/app/shared/services/rest/file.service';
import { UploadService } from 'src/app/shared/services/rest/upload.service';
import { mimeTypes } from 'mime-wrapper';
import { SelectFilesForCheckComponent } from './dialogs/select-files-for-check/select-files-for-check.component';
import { ParametersService } from 'src/app/shared/services/rest/parameters.service';
import { environment } from 'src/environments/environment';
SwiperCore.use([Mousewheel, Pagination]);

@Component({
  selector: 'app-reminders',
  templateUrl: './reminders.component.html',
  styleUrls: ['./reminders.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ]
})
export class RemindersComponent extends BaseClass implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild("mobile_filter_wrp") mobile_filter_wrp: ElementRef;
  @ViewChild("tasksContainer") tasksContainer: ElementRef;
  @ViewChild('checkUploader') checkUploader: ElementRef<HTMLInputElement>;

  public host: any = environment.host;
  public imgRoute: any = '';
  public user: any = {};
  public user_id: number;
  public linkTo: string = '';
  public company: any;
  public me: any;
  public currMonthName: any;
  public company_id: any;
  public activeLang: any;
  public operations: any;
  public operationsValues: any;
  public operationStatuses: any;
  public currencyes: any;
  public partnerGroups: any;
  public page: number = 1;
  public filterCount: number = 1;
  // public pagination: object = {};
  public isScrolledCollectionBtn: boolean = false;
  public collectionIsActive: boolean = false;
  public filter: FormGroup = this.fb.group({
    count: '',
    q: '',
    status_id: '',
    group_id: [[]],
    operation_employee_id: [[]],
    operation_partner_company_id: [[]],
    no_operation_id: '',
    channel_id: [[]],
    no_channel_id: [[]],
    operation_id: '',
    operation_filter: '1',
    operation_is_reminder: '1',
    operation_completed_at_from : '',
    operation_completed_at_to : '',
    order_by: 'updated_desc',
    id: '',
    custom_id: "",
    task_company_id: '',
    discussion_id: "",
    task_operation_id: "",
    q_task_name: "",
    q_task_comment: "",
    q_task_private_comment: "",
    q_task_operation_comment: "",
    q_task_operation_employee_comment: "",
    q_task_operation_private_comment: ""
  });
  public timeZone = new Date().getTimezoneOffset();
  public is_mobile: boolean = false;
  public hasMore: boolean = true;
  public origin = window.location.origin;
  public tasks: any[] = [];
  public appStatusId: number;
  public profileStatuses: any;
  public employees: any;
  public sortValue: string = 'updated_desc';
  public activeStatus: number[] = [0];
  public taskCollection: any;
  public groups: any;
  public selectedCheck: any;
  public selectedJob: any;
  public selectedTask: any;
  public sub: Subscription;
  public subFilter: Subscription;
  public subClientFilter: Subscription;
  public execFilterSub: Subscription;
  public taskDataSub: Subscription;
  public taskStatuses: any;
  public partners: any;
  public isLoad: boolean = false;
  public dateFromChanged: boolean = false;
  public uploadLimit: boolean = false;
  public dateToChanged: boolean = false;
  public tasksSub: Subscription;
  public tasksCountSub: Subscription;
  public curYear = (new Date()).getFullYear()

  public groupsControl: FormControl = new FormControl();
  public groups$: ReplaySubject<any> = new ReplaySubject<any>(1);
  
  public partnersControl: FormControl = new FormControl();
  public partners$: ReplaySubject<any> = new ReplaySubject<any>(1);
  
  public employees$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public execControl: FormControl = new FormControl();
  // public clientControl: FormControl = new FormControl();
  public selectedIndex: FormControl = new FormControl(0);

  
  overlayRefFilter: OverlayRef | null;
  // overlayRefClientFilter: OverlayRef | null;
  overlayExecRefFilter: OverlayRef | null;
  overlayRef: OverlayRef | null;
  @ViewChild('filterMenu') filterMenu: TemplateRef<any>;
  // @ViewChild('filterClientMenu') filterClientMenu: TemplateRef<any>;
  @ViewChild('filterExecMenu') filterExecMenu: TemplateRef<any>;
  @ViewChild('taskMenu') taskMenu: TemplateRef<any>;
  public totalTasksCount: number = 0;
  public totalInfo: object =  {
    'summaryRate': 0,
    'summaryPrice': [
      {
        currency_id: 0,
        summary: 0
      },
      {
        currency_id: 1,
        summary: 0
      },
      {
        currency_id: 2,
        summary: 0
      },
      {
        currency_id: 3,
        summary: 0
      },
      {
        currency_id: 4,
        summary: 0
      }
    ]
  }
  public contentUrl = [
    {
      name: marker("Without content url"),
      id: 0
    },
    {
      name: marker("With content url"),
      id: 1
    }
  ]

  constructor(
    private sm: StorageManagerService,
    private auth: AuthService,
    private router: Router,
    private chatService: ChatService,
    private globalDataService: GlobalDataService,
    public activatedRoute: ActivatedRoute,
    private taskService: TaskService,
    private companyService: CompanyService,
    private languageService: LanguageService,
    private parametersService: ParametersService,
    private layoutService: LayoutService,
    private fileService: FileService,
    private uploadService: UploadService,
    private refreshService: RefreshService,
    private _adapter: DateAdapter<any>,
    private dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private fb: FormBuilder,
    private bottomSheet: MatBottomSheet,
    public viewContainerRef: ViewContainerRef,
    public overlay: Overlay,
    public ls: LoadingService
  ) { super() }

  ngOnInit(): void {
    this.company_id = this.activatedRoute.snapshot.queryParamMap.get('company_id');
    this.checkIsMobile();
    
    this.getLangData(this.company_id);
    this.getImgRoute();
    this.getUser();
    this.getOperationsStatus();
    this.getTaskStatus();
    this.onRouteChange();
    this.refresh();

    this.attachSubscriptions(
      this.groupsControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchGroups(resp))
    )

    this.attachSubscriptions(
      this.execControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchEmoloyees(resp))
    )

    // this.attachSubscriptions(
    //   this.clientControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchEmoloyees(resp))
    // )

    this.attachSubscriptions(
      this.partnersControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchPartners(resp))
    )
  }

  onSearchPartners(resp) {
    if (!this.partners) {
      return;
    }

    if (!resp) {
      this.partners$.next(this.partners.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.partners$.next(
      this.partners.filter(z => z.partnerCompany.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  onSearchEmoloyees(resp) {
    if (!this.employees) {
      return;
    }

    if (!resp) {
      this.employees$.next(this.employees.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.employees$.next(
      this.employees.filter(z => z.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  onSearchGroups(resp) {
    if (!this.groups) {
      return;
    }

    if (!resp) {
      this.groups$.next(this.groups.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }

    // filter the banks
    this.groups$.next(
      this.groups.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  changeTab(val) {
    this.selectedIndex.patchValue(val);
  }

  changeSelectionList(e, key) {
    console.log(e, key)
    console.log(e.option._value)

    this.filter.patchValue({
      [key]: e.option._value
    })
    // this.closeOpenClientFilter();
    this.closeExecFilter();
    this.filterTasks();
  }

  refresh() {
    this.attachSubscriptions(
      this.refreshService.getRefreshTasks().pipe(
        concatMap(res => {
          if ( this.tasks.filter(x => x.id == res.task_id).length > 0 && this.company_id == res.company_id ) {
            return this.taskService.getOneTaskExpand(this.company_id, res.task_id).pipe(
              map(x => x.body[0]),
              switchMap(x => this.neededData(x).pipe(map(() => casesModel([x], this.tasks, 'update')),map(x => x.arr[0]))),
              tap(x => {
                this.tasks.splice(this.tasks.indexOf(this.tasks.find(b => b.id == x.id)),1, x)
              })
            )
          } else {
            return of(false)
          }    
        })
      ).subscribe(resp => {
        console.log("getRefreshTasks", resp)
      })
    )
  }

  openCheck(job, check) {
    check.open = !check.open

    if (check.open) {
      this.attachSubscriptions(
        this.fileService.getFilesForCheck(this.company_id, job.id, check.id, '/ready_files', '200').subscribe(res => {
          check.files = res.body
          console.log(check.files)
        })
      )
    }
  }

  
  understandFileType(val) {
    if (!val) {
      return ""
    }
    
    if (val == 'application/pdf') {
      return 'pdf'
    } else if (val.indexOf("/") != -1) {
      return val.split('/')[0]
    } else {
      return ""
    }
  }

  deleteFile(file, check, ind) {
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        msg: marker("Are you sure you want to unlink the file from this checklist item?")
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        this.attachSubscriptions(
          this.fileService.editFile(file.id, {operation_reminder_id: '0'}, this.company.id).subscribe(resp => {
            check.files.splice(ind, 1)
          })
        )
      }
    });
  }

  onListSelectionChange(val: MatCheckboxChange, check, job) {
    check.disable = true;
    if (!check.status) {
      this.attachSubscriptions(
        this.taskService.createOperationReminderStatus(job.id, check.id, this.company_id).subscribe(resp => {
          check.status = resp;
          check.disable = false;
          console.log(check)
          // if (job.checklist.filter(x => x.status && x.status.is_done == 1).length == job.checklist.length || (job.checklist.length == 0 || !job.checklist)) {
          //   this.taskService.editWork(job.id, {is_reminder: 0}, this.company_id).subscribe(resp => {
          //     console.log(resp)
          //     job.is_reminder = resp.is_reminder
          //   })
          // }
        }, error => {
          delete check.status;
          val.source.toggle()
          this.layoutService.showSnackBar({name: ''}, marker("You do not have permission to do this. Error: " + error), SnackBarItem)
          
        })
      )
    } else {
      // if (job.checklist.filter(x => x.status && x.status.is_done == 1).length == job.checklist.length || (job.checklist.length == 0 || !job.checklist)) {
      //   this.taskService.editWork(job.id, {is_reminder: 1}, this.company_id).subscribe(resp => {
      //     console.log(resp)
      //     job.is_reminder = resp.is_reminder
      //   })
      // }
      this.attachSubscriptions(
        this.taskService.deleteOperationReminderStatus(check.status.id).subscribe(resp => {
          check.status = resp
          check.disable = false;
          console.log(check)
        })
      )
    }

    console.log("afterChange", job.checklist)
  }

  uploadFileBtn(job, check, task) {
    if (!check.hasOwnProperty('files')) {
      check.files = [];
    }
    this.selectedCheck = check
    this.selectedJob = job;
    this.selectedTask = task;
    this.checkUploader.nativeElement.click()
  }


  uploadFile(event) {
    if (this.uploadLimit) {
      this.layoutService.showSnackBar({name: ''}, marker("This company has exceeded the file upload limit."), SnackBarItem)
      return
    }
    if (event.target.files.length > 0) {
      this.selectedCheck.filesCount = (this.selectedCheck.filesCount ? this.selectedCheck.filesCount : 0) + event.target.files.length
      for (let index = 0; index < event.target.files.length; index++) {
        const element = event.target.files[index];
        this.putFile(element, false, "/ready_files");
      }
    } 
  }

  putFile(file, is_folder:boolean = false, initPath:any = false) {
    let path = '/ready_files'

    this.fileService.uploadFile({
      company_id: this.company.id,
      task_id: this.selectedJob.task_id,
      task_operation_id: this.selectedJob.id,
      filesize: file.size,
      filename: file.name,
      operation_reminder_id: this.selectedCheck.id,
      content_type: file.type ? file.type : mimeTypes.getType(file.name),
      location: path,
      is_dir: 0
    }, this.company.id).subscribe(resp => {
      console.log("putFile", this.selectedCheck)
      this.fileService.files$.next({
        place: "file_manager",
        url: window.location.href,
        data: resp,
        files: this.selectedCheck.files,
        location: path,
        task: this.selectedTask,
        work: this.selectedJob,
        activeLang: this.activeLang,
        operationsValues: this.operationsValues,
        target: file,
        operation_reminder: this.selectedCheck,
        task_id: this.selectedJob.task_id,
        work_id: this.selectedJob.id,
        company_id: this.company.id,
        company: this.company,
        user: this.user
      })
      // this.selectedJob = undefined
    })
  }

  selectFilesFromJob(task, job, check) {
    const dialogRef = this.dialog.open(SelectFilesForCheckComponent, {
      data: {
        company: this.company,
        task: task,
        work: job,
        user: this.user,
        check: check,
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().pipe(
        switchMap(() => this.fileService.getFilesForCheck(this.company_id, job.id, check.id, '/ready_files', '200'))
      ).subscribe(result => {
        check.files = result.body
      })
    )
  }

  onFileDropped(e, check, job) {
    console.log("onFileDropped", e, check, job)

    this.getFilesDataTransferItems(e.dataTransfer.items).then((files:any) => {
      if (files.length > 0) {
        for (let index = 0; index < files.length; index++) {
          this.company.filesize = this.company.filesize + files[index].size
        }
      }
    })

    if (this.uploadLimit) {
      this.layoutService.showSnackBar({name: ''}, marker("This company has exceeded the file upload limit."), SnackBarItem)
      return
    }
    if (!e.dataTransfer.files.length) {
      return false;
    }

    this.selectedCheck = check;
    this.selectedJob = job;

    this.getFilesDataTransferItems(e.dataTransfer.items).then((files:any) => {
      if (files.length > 0) {
        for (let index = 0; index < files.length; index++) {
          this.putFile(files[index]);
        }
      }
    });
    
    if (this.company.filesize >= this.company.filesize_limit) {
      this.uploadService.uploadLimit$.next(true)
    }
  }
  getFilesDataTransferItems(dataTransferItems) {
    function traverseFileTreePromise(item, folder) {
      return new Promise(resolve => {
        if (item.isFile) {
          item.file(file => {
            file.filepath = item.fullPath.replace(`/${file.name}`, "");
            folder.push(file);
            resolve(file);
          });
        } else if (item.isDirectory) {
          let dirReader = item.createReader();
          dirReader.readEntries(entries => {
            let entriesPromises = [];
            for (let entr of entries)
              entriesPromises.push(
                traverseFileTreePromise(entr, folder)
              );
            resolve(Promise.all(entriesPromises));
          });
        }
      });
    }
  
    let files = [];
    return new Promise((resolve, reject) => {
      let entriesPromises = [];
      for (let it of dataTransferItems)
        entriesPromises.push(
          traverseFileTreePromise(it.webkitGetAsEntry(), files)
        );
      Promise.all(entriesPromises).then(entries => {
        resolve(files);
      });
    });
  }

  detectBrowserName() { 
    const agent = window.navigator.userAgent.toLowerCase()
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }

  neededData(task) {
    let arr = [
      ...task.operations.map(x => this.taskService.getOperationReminder(this.company_id, {task_operation_id: x.id}).pipe(
        tap(val => {
          let checklist = val.body;
          let jobParameters = task.parameterValuesToTask.filter(x => !!x.task_operation_id && !x.discussion_id && !x.file_id)
          x.checklist = [];
          checklist.forEach(element => {
            if (element.exceptParameterValues.length > 0) {
              if (element.exceptParameterValues.map(b => b.parameter_value_id).every(n => !jobParameters.map(m => m.parameter_value_id).includes(n))) {
                x.checklist.push(element)
              }
            } else if (element.toParameterValues.length > 0) {
              if (element.toParameterValues.map(b => b.parameter_value_id).some(n => jobParameters.map(m => m.parameter_value_id).includes(n))) {
                x.checklist.push(element)
              }
            } else {
              x.checklist.push(element)
            }
          });

          if (x.checklist.length == 0 && x.checklist.filter(b => !!b.status).length == x.checklist.length) {
            x.hasChecklistError = true;
          }
        })
      )),
      this.chatService.getTasksChats(this.company_id, [task.id]).pipe(
        tap(res => {
          task['operations'].map(o_el => {
            o_el['chatsInfo'] = [];
            o_el['chatsInfo'].push(...res.filter(z => z.task_operation_id == o_el.id));
            o_el['unReadChats'] = res.filter(z => z.task_operation_id == o_el.id && z.is_read != '1').length;
            o_el['unClosedChats'] = res.filter(z => z.task_operation_id == o_el.id && z.status_id != '4').length;
          });
          task['chatsInfo'] = [];
          task['chatsInfo'].push(...res.filter(z => z.task_id == task.id));
          task['unReadChats'] = res.filter(z => z.is_read != '1').length;
          task['unClosedChats'] = res.filter(z => z.status_id != '4').length;
        })
      ),
      ...task.operations.filter(x => !x.hasChecklistError).map(x => this.fileService.getReminderFilesForOpenTask(this.company_id, x.id, '6').pipe(
        tap(val => x.files = val.body)
      ))
    ]
    // if (task.company_id == this.company.id) {
    //   arr.push(
    //     this.taskService.getTaskClients(task.id).pipe(
    //       tap(res => {
    //         task.clients = res
    //       }),
    //       catchError(err => {
    //         return err
    //       })
    //     )
    //   )
    // }
    return forkJoin(arr)
  }

  onStartDateChange(e: MatDatepickerInputEvent<Date, Date>) {
    if (e && e.value) {
      this.dateFromChanged = true;
    }
    this.checkRange();
  }

  onEndDateChange(e: MatDatepickerInputEvent<Date, Date>) {
    if (e && e.value) {
      this.dateToChanged = true;
    }
    this.checkRange();
  }

  changeView(e: MatButtonToggleChange) {
    if (e.value == "executor") {
      this.filter.patchValue({
        operation_employee_id: [this.me.id],
        operation_partner_company_id: []
      })
      this.filterTasks()
    } else {
      this.filter.patchValue({
        operation_employee_id: [],
        operation_partner_company_id: []
      })
      this.filterTasks()
    }
  }

  checkRange(): void {
    if (this.dateFromChanged || this.dateToChanged) {
      this.dateFromChanged = false;
      this.dateToChanged = false;
      this.filterTasks()
    }
  }

  copyLink(type) {
    this.layoutService.showSnackBar(marker(type + " link"), marker("Copied"), SnackBarItem)
  }

  checkIsManager(task, company, _user) { 
    return task.managers.filter(x => x.user_id == _user.id).length > 0 || task?.group?.managers.filter(x => x.user_id == _user.id).length > 0 || company?.permissions.includes('admin') || company?.permissions.includes('manager'); 
  }
  
  noOperation(e, id, jobTypeSelect: MatSelect) {
    e.preventDefault();
    e.stopPropagation();
    this.filter.patchValue({
      no_operation_id: id,
    })

    jobTypeSelect.close();
    this.filterTasks()
  }

  removeValue(val) {
    if (val == "all") {
      this.activeStatus = [0];
      this.filter.patchValue({
        count: '',
        q: '',
        status_id: '',
        group_id: [],
        no_operation_id: '',
        channel_id: [],
        no_channel_id: [],
        operation_employee_id: [],
        operation_partner_company_id: [],
        task_company_id : '',
        operation_id: '',
        operation_completed_at_from : '',
        operation_completed_at_to : '',
        order_by: 'updated_desc',
        id: ''
      })
      this.filterReset()
    } else if (val == "operation_employee_id" || val == "operation_partner_company_id") {
      this.filter.patchValue({
        [val]: []
      })
      this.filterTasks()
    } else {
      this.filter.patchValue({
        [val]: ''
      })
      this.filterTasks()
    }
    
  }

  onRouteChange() {
    this.attachSubscriptions(
      this.activatedRoute.queryParams.pipe(
        tap((val) => {
          this.getCurrencyList();
          if (this.taskDataSub) {
            this.taskDataSub.unsubscribe();
          }
          if (this.company_id != this.activatedRoute.snapshot.queryParamMap.get('company_id')) {
            this.getLangData(this.activatedRoute.snapshot.queryParamMap.get('company_id'));
          }
          this.company_id = this.activatedRoute.snapshot.queryParamMap.get('company_id');
          this.sortValue = !!this.activatedRoute.snapshot.queryParamMap.get('order_by') ? this.activatedRoute.snapshot.queryParamMap.get('order_by') : "updated_desc";
          this.filter.patchValue({
            count: this.filterCount,
            id: this.activatedRoute.snapshot.queryParamMap.get('id') ? +this.activatedRoute.snapshot.queryParamMap.get('id') : '',
            q: !!this.activatedRoute.snapshot.queryParamMap.get('q') ? this.activatedRoute.snapshot.queryParamMap.get('q') : "",
            status_id: !!this.activatedRoute.snapshot.queryParamMap.get('status_id') ? +this.activatedRoute.snapshot.queryParamMap.get('status_id') : "",
            no_operation_id: !!this.activatedRoute.snapshot.queryParamMap.get('no_operation_id') ? +this.activatedRoute.snapshot.queryParamMap.get('no_operation_id') : "",
            no_channel_id: !!this.activatedRoute.snapshot.queryParamMap.get('no_channel_id') ? this.activatedRoute.snapshot.queryParamMap.get('no_channel_id').split(',').map(Number) : [],
            channel_id: !!this.activatedRoute.snapshot.queryParamMap.get('channel_id') ? this.activatedRoute.snapshot.queryParamMap.get('channel_id').split(',').map(Number) : [],
            group_id: !!this.activatedRoute.snapshot.queryParamMap.get('group_id') ? this.activatedRoute.snapshot.queryParamMap.get('group_id').split(',').map(Number) : [],
            operation_employee_id: !!this.activatedRoute.snapshot.queryParamMap.get('operation_employee_id') ? [+this.activatedRoute.snapshot.queryParamMap.get('operation_employee_id')] : [],
            operation_partner_company_id: !!this.activatedRoute.snapshot.queryParamMap.get('operation_partner_company_id') ? [+this.activatedRoute.snapshot.queryParamMap.get('operation_partner_company_id')] : [],
            operation_id: !!this.activatedRoute.snapshot.queryParamMap.get('operation_id') ? +this.activatedRoute.snapshot.queryParamMap.get('operation_id') : "",
            operation_completed_at_from: !!this.activatedRoute.snapshot.queryParamMap.get('operation_completed_at_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('operation_completed_at_from')*1000).utc() : "",
            operation_completed_at_to: this.activatedRoute.snapshot.queryParamMap.get('operation_completed_at_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('operation_completed_at_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('operation_completed_at_to')*1000).utc() : ""),
            order_by: !!this.activatedRoute.snapshot.queryParamMap.get('order_by') ? this.activatedRoute.snapshot.queryParamMap.get('order_by') : "updated_desc",
            custom_id: this.activatedRoute.snapshot.queryParamMap.get('custom_id') ? +this.activatedRoute.snapshot.queryParamMap.get('custom_id') : '',
            task_company_id: this.activatedRoute.snapshot.queryParamMap.get('task_company_id') ? +this.activatedRoute.snapshot.queryParamMap.get('task_company_id') : '',
            discussion_id: this.activatedRoute.snapshot.queryParamMap.get('discussion_id') ? +this.activatedRoute.snapshot.queryParamMap.get('discussion_id') : '',
            task_operation_id: this.activatedRoute.snapshot.queryParamMap.get('task_operation_id') ? +this.activatedRoute.snapshot.queryParamMap.get('task_operation_id') : '',
            q_task_name: this.activatedRoute.snapshot.queryParamMap.get('q_task_name') ? this.activatedRoute.snapshot.queryParamMap.get('q_task_name') : '',
            q_task_comment: this.activatedRoute.snapshot.queryParamMap.get('q_task_comment') ? this.activatedRoute.snapshot.queryParamMap.get('q_task_comment') : '',
            q_task_private_comment: this.activatedRoute.snapshot.queryParamMap.get('q_task_private_comment') ? this.activatedRoute.snapshot.queryParamMap.get('q_task_private_comment') : '',
            q_task_operation_comment: this.activatedRoute.snapshot.queryParamMap.get('q_task_operation_comment') ? this.activatedRoute.snapshot.queryParamMap.get('q_task_operation_comment') : '',
            q_task_operation_employee_comment: this.activatedRoute.snapshot.queryParamMap.get('q_task_operation_employee_comment') ? this.activatedRoute.snapshot.queryParamMap.get('q_task_operation_employee_comment') : '',
            q_task_operation_private_comment: this.activatedRoute.snapshot.queryParamMap.get('q_task_operation_private_comment') ? this.activatedRoute.snapshot.queryParamMap.get('q_task_operation_private_comment') : ''
          })
        }),
        switchMap((val) => {
          console.log("switchMap", this.company)
          if (this.company == undefined || this.company.id != this.activatedRoute.snapshot.queryParamMap.get('company_id')) {
            return this.companyService.getCompany(this.activatedRoute.snapshot.queryParamMap.get('company_id')).pipe(
              map(x => x[0]),
              tap(x => {
                this.company = x;
                this.me = this.company.employees[0];
                this.companyService.company$.next(x);
                if (x.employees.find(x => x.user_id == this.user.id)) {
                  this.appStatusId = x.employees.find(x => x.user_id == this.user.id).application_status_id
                }
                if (!!x && !!x.employees && !!x.employees[0]) {
                  this.company['rate_target'] = x.employees[0].rate_target
                }

                // COMMENT BEFORE MERGE
                // const dialogRef = this.dialog.open(CreateTaskComponent, {
                //   disableClose: true,
                //   data: {
                //     company_id: this.company_id,
                //     company: this.company,
                //     imgRoute: this.imgRoute,
                //     host: this.host,
                //   }
                // });
            
              }),
              switchMap(() => this.uploadService.getUploadLimit()
              .pipe(
                tap(res => {
                  if (res || this.company.filesize >= this.company.filesize_limit) {
                    this.uploadLimit = true;
                  }
                })
              )),
              map(() => val)
            )
          } else {
            return of(val)
          }
        }),
      ).subscribe(params => {
        console.log("route STREAM", params);
        this.linkTo = '';
        this.taskCollection = new SelectionModel(
          true,
          []
        )

        // console.log("this.linkTo", this.linkTo)
        // console.log('this.linkTo.split("_")[1]', this.linkTo.split("_")[1])
        // console.log("this.filter.value", this.filter.value)

        this.tasks = [];
        this.page = 1;
        
        this.taskDataSub = this.taskService.getTaskDataStream().pipe(
          concatMap(tasksInPage => {
            return concat(...tasksInPage.map(taskInPage => this.neededData(taskInPage))).pipe(last(),map(x => tasksInPage))
          }),
        ).subscribe(resp => console.log("-----getTaskDataStream-----",resp));
        this.getTasks(this.page);
      })
    )
  }

  goToReminderCases() {
    console.log("goToReminderCases");
  }

  openJob(task_id, task_operation_id) {
    this.attachSubscriptions(
      this.taskService.getOneTaskExpand(this.company_id, task_id).pipe(
        map(x => x.body[0]),
        switchMap(x => this.neededData(x).pipe(map(() => casesModel([x], [], 'update')),map(x => x.arr[0]))),
      ).subscribe(resp => {
        const dialogRef = this.dialog.open(OpenJobComponent, {
          backdropClass: 'backdrop_under_header',
          panelClass: !this.is_mobile ? ['open_task_dialog', 'show_header'] : 'open_task_dialog',
          autoFocus: false,
          data: {
            task_id: task_id,
            task_operation_id: task_operation_id,
            task: resp,
          }
        });
      })
    )
  }

  openTask(task) {
    let taskData:any = {
      task_id: task.id
    }
    if (task.hasOwnProperty("operations")) {
      taskData.task = task
    }
    if (this.tasks.length) {
      taskData.tasks = this.tasks
    }
    const dialogRef = this.dialog.open(OpenTaskComponent, {
      backdropClass: 'backdrop_under_header',
      panelClass: !this.is_mobile ? ['open_task_dialog', 'show_header'] : 'open_task_dialog',
      autoFocus: false,
      data: taskData
    });
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  getUser() {
    this.attachSubscriptions(
      this.auth.$user.subscribe(resp => {
        this.user = resp;
      })
    )
  }

  getRateStats() {
    this.attachSubscriptions(
      this.taskService.getRateStats(this.company_id, moment().utc().clone().startOf('month').format('X'), moment().utc().clone().endOf('month').format('X')).subscribe(resp => {
        this.taskService.userStats$.next(resp);
      })
    )
  }

  getGroupsCompany() {
    this.attachSubscriptions(
      this.companyService.getInfiniteGroupsCompany(this.company_id, '1', '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.companyService.getInfiniteGroupsCompany(this.company_id, x).pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        this.groups = [].concat(...res);
        this.groups$.next(this.groups.slice());
      })
    )
  }
  
  getHeaderStats() {
    let sources = [];
    if (!!this.company && !!this.company.id) {
      sources.push(this.taskService.getHeaderStats(this.company.id, '3', moment().utc().clone().startOf('month').format('X'), moment().utc().clone().endOf('month').format('X')))
      sources.push(this.taskService.getHeaderStats(this.company.id, '4', moment().utc().clone().startOf('month').format('X'), moment().utc().clone().endOf('month').format('X')))
      sources.push(this.taskService.getHeaderStats(this.company.id, '98', moment().utc().clone().startOf('month').format('X'), moment().utc().clone().endOf('month').format('X')))
      this.attachSubscriptions(
        forkJoin(sources).subscribe(result => {
          console.log(result)
          let rate_toAprove = result[0]['headers'].get('x-summary-rate') ? result[0]['headers'].get('x-summary-rate') : 0;
          let rate_Aproved = result[1]['headers'].get('x-summary-rate') ? result[1]['headers'].get('x-summary-rate') : 0;
          this.company.rate_pending = +rate_Aproved + +rate_toAprove;
          this.company.rate_ready = result[2]['headers'].get('x-summary-rate') ? result[2]['headers'].get('x-summary-rate') : 0;
  
          let price_toAprove = JSON.parse(result[0]['headers'].get('x-summary-price')).length ? JSON.parse(result[0]['headers'].get('x-summary-price'))[0].summary : 0;
          let price_Aproved = JSON.parse(result[1]['headers'].get('x-summary-price')).length ? JSON.parse(result[1]['headers'].get('x-summary-price'))[0].summary : 0;
          this.company.price_pending = +price_toAprove + +price_Aproved;
          this.company.price_ready = JSON.parse(result[2]['headers'].get('x-summary-price')).length ? JSON.parse(result[2]['headers'].get('x-summary-price'))[0].summary : 0;
        })
      )
    }
  }

  getEmployees() {
    this.attachSubscriptions(
      this.taskService.getEmployees(this.company_id).subscribe(resp => {
        this.employees = resp;
        this.employees$.next(this.employees.slice());
      })
    )
  }

  getLangData(id) {
    this.attachSubscriptions(
      this.languageService.getLangData().pipe(
        tap(resp => {
          this.activeLang = resp.active;
          this._adapter.setLocale(resp.active);
          this.currMonthName = moment().format('MMMM');
        }),
        switchMap(() => this.taskService.getOperations(id, this.activeLang).pipe(
          tap(x => {
            this.operationsValues = x;
          })
        ))
      ).subscribe(resp => {
        console.log("getOperations", resp)
      })
    )
  }


  addRowData(row_obj) {
    let data = casesModel([row_obj], JSON.parse(JSON.stringify(this.tasks)), "add")
    this.tasks.unshift(...data.arr);
    // this.pagination['totalCount'] = +this.pagination['totalCount'] + 1;
  }

  checkIsMobile() {
    if (window.innerWidth <= 769) {
      this.is_mobile = true;
    } else {
      this.is_mobile = false;
    }
    this.onResize();
  }

  onResize() {
    this.attachSubscriptions(
      fromEvent(window, "resize").pipe(
        map(() => window.innerWidth)
      ).subscribe((wWidth) => {
          if (wWidth <= 769) {
            this.is_mobile = true;
          } else {
            this.is_mobile = false;
          }
        }
      )
    )
  }

  ngAfterViewInit(): void {
    if (this.is_mobile) {
      this.onMobScroll()
    } else {
      this.onScroll();
    }
  }

  searchInMobile(element: ElementRef) {
    this.filter.patchValue({
      q: element['value']
    })
    this.filterTasks();
  }

  openMobFilter() {
    this.layoutService.overflowHidden();
    this.mobile_filter_wrp.nativeElement.classList.add("active");
  }

  closeMobFilter() {
    this.layoutService.overflowAuto();
    this.mobile_filter_wrp.nativeElement.classList.remove("active");
  }

  sort(val) {
    this.filterTasks(null, val)
  }

  checkActions(e, task) {
    if (!this.is_mobile) {
      e.preventDefault();
      e.stopPropagation();
    }
    this.attachSubscriptions(
      this.taskService.getActions(task.id, 1, this.company.id).subscribe(resp => {
        if (resp.body.length) {
          this.dialog.open(NotificationsBar, {
            backdropClass: this.is_mobile ? ['mob_interface_backdrop'] : 'backdrop_under_header',
            panelClass: this.is_mobile ? ['mob_interface_panel', 'animate__animated', 'animate__slideInUp'] : ['open_task_dialog', 'show_header'],
            data: {
              notifications: resp.body,
              employees: !!this.employees ? this.employees : [],
              taskStatuses: this.taskStatuses,
              company: this.company,
              user: this.user,
              task: task,
              header: false,
              tasks: this.tasks,
              initCompanyId: this.company_id,
              empl_status: this.company.permissions.includes('owner') || this.company.permissions.includes('admin') || this.company.permissions.includes('manager'),
              pagination: {
                'pageCount': resp.headers.get('x-pagination-page-count'),
                'perPage': resp.headers.get('x-pagination-per-page'),
                'totalCount': resp.headers.get('x-pagination-total-count'),
                'currentPage': resp.headers.get('x-pagination-current-page'),
              },
            }
          });
        }
      })
    )
  }

  getGroup(id) {
    if (!this.groups || !this.groups.find(el => el.id == id)) {
      return false;
    }
    return this.groups.find(el => el.id == id)
  }  

  getEmployee(id) {
    if (!this.employees) {
      return false;
    }
    return this.employees.find(el => el.id == id)
  }  

  getPartner(id) {
    if (!this.partners) {
      return false;
    }
    return this.partners.find(el => el.partner_company_id == id)
  }

  selectChange(e) {
    // console.log(!e)
    this.closeExecFilter();
    this.filterTasks();
  }

  selectOperation(e, id, jobTypeSelect) {
    e.preventDefault();
    e.stopPropagation();

    this.filter.patchValue({
      operation_id: id,
    })

    jobTypeSelect.close()
    this.closeExecFilter();
    this.filterTasks();

  }

  filterReset() {
    this.filter.patchValue({
      id: '',
      operation_completed_at_from: '',
      operation_completed_at_to: ''
    })
    this.router.navigate(['/reminder-tasks'], { queryParams: {company_id: this.company_id, order_by: "updated_desc"}});
    if (this.is_mobile) {
      this.layoutService.overflowAuto();
      this.mobile_filter_wrp.nativeElement.classList.remove("active");
    }
  }

  resetSearchParams() {
    this.filter.patchValue({
    id: '',
    custom_id: "",
    task_company_id: '',
    discussion_id: "",
    task_operation_id: "",
    q_task_name: "",
    q_task_comment: "",
    q_task_private_comment: "",
    q_task_operation_comment: "",
    q_task_operation_employee_comment: "",
    q_task_operation_private_comment: ""
    })
  }

  getCountSumm(arr, startIndex) {
    let count = 0

    for (startIndex; startIndex < arr.length; startIndex++) {
      const element = arr[startIndex];
      count = count + element.count
    }
    
    return count
  }

  changeActiveStatus(id) {
    this.router.navigate(['/tasks'], { queryParams: {company_id: this.company_id, operation_status_id: id, order_by: "updated_desc", count: 1}});
  }

  // if (id == 0) {
  //   this.activeStatus = [0];
  // } else {
  //   this.activeStatus = [...id];
  // }

  getTasksNoComplete(picker:MatDateRangePicker<any>) {
    picker.close()
    this.filter.patchValue({
      operation_completed_at_from: '',
      operation_completed_at_to: 0
    })
    this.filterTasks();
  }

  filterTasks(op_status_id = null, order_by = null, is_btn = false) {
    this.closeOpenFilter();
    if (this.tasksSub) {
      this.tasksSub.unsubscribe()
    }
    this.filterCount = this.filterCount + 1;
    this.filter.patchValue({
      count: this.filterCount
    })
    
    let filterData = {...this.filter.value};
    if (!!filterData.operation_completed_at_from) {
      filterData.operation_completed_at_from = moment(filterData.operation_completed_at_from._d).format("X");
    }
    if (!!filterData.operation_completed_at_to) {
      filterData.operation_completed_at_to = moment(filterData.operation_completed_at_to._d).format("X");
    }

    if (!!order_by || order_by == "0") {
      filterData.order_by = order_by
    }
    
    let params = {
      company_id: this.company_id
    };

    // if (is_btn) {
    //   this.filter.removeControl('id');
    // }

    Object.keys(filterData).forEach(element => {
      if (["channel_id", "no_channel_id", "group_id"].includes(element) && filterData[element].length > 0) {
        params[element] = filterData[element].join(',')
      } else if (filterData[element] == '0' && filterData[element] == 0 && (element == 'operation_employee_id' || element == 'operation_partner_company_id')) {
        params[element] = filterData[element]
      } else if (filterData[element] != '0' && filterData[element] != '') {
        params[element] = filterData[element]
      }

    });


    // console.log("filterData.operation_completed_at_to", filterData.operation_completed_at_to)
    if (filterData.operation_completed_at_to === 0) {
      params['operation_completed_at_to'] = '0';
    }

    this.router.navigate(['/reminder-tasks'], { queryParams: params });

    if (this.is_mobile) {
      this.layoutService.overflowAuto();
      if (this.mobile_filter_wrp) {
        this.mobile_filter_wrp.nativeElement.classList.remove("active");
      }
    }
  }

  getTaskStatus() {
    this.attachSubscriptions(
      this.taskService.getTaskStatuses(this.company_id).subscribe(resp => {
        this.taskStatuses = resp.slice()
        this.taskStatuses.unshift({
          id: 0,
          name: marker('All statuses')
        });
      })
    )
  }

  getOperationsStatus() {
    this.attachSubscriptions(
      this.taskService.getOperationsStatus().subscribe(resp => {
        this.operationStatuses = resp.slice();
        this.operationStatuses.unshift({
          id: 0,
          name: marker('All statuses')
        });
      })
    )
  }


  getTasksCount() {
    if (this.tasksCountSub) {      
      this.tasksCountSub.unsubscribe()
    }
    this.tasksCountSub = this.taskService.getTasksCount(this.company_id, {...this.filter.value}).pipe(
      tap(el => {
        this.totalInfo['summaryRate'] = +el.headers.get('x-summary-rate');
        let parsePrice = JSON.parse(el.headers.get('x-summary-price'));
        this.totalInfo['summaryPrice'].forEach(price => {
          if (parsePrice.find(x => x.currency_id == price.id)) {
            price.summary = parsePrice.find(x => x.currency_id == price.id).summary;
          } else {
            price.summary = 0
          }
        });

        this.totalTasksCount = +el.headers.get('x-pagination-total-count')

        // console.log("parsePrice", parsePrice);
        console.log("tasksCountSub", el.headers.get('x-pagination-total-count'));
      })
    ).subscribe(resp => {
      console.log(resp)
    })
  }

  getTasks(n) {
    console.log("FILTER", this.filter.value)
    this.isLoad = true;
    this.tasksSub = this.taskService.getTasksExpand(n, this.company_id, {...this.filter.value}, !this.is_mobile ? '50' : '10').pipe(
      tap(arrTasks => {
        console.log("arrTasks", arrTasks)
        if (this.is_mobile) {
          if (arrTasks.length < 10) {
            this.hasMore = false
          } else {
            this.hasMore = true;
          }
        } else {
          if (arrTasks.length < 50) {
            this.hasMore = false
          } else {
            this.hasMore = true;
          }
        }
      }), 
      map(arr => {
        let data = casesModel(arr, this.tasks, "get");
        return {
          arr: data.arr,
          data: data.tasks
        };
      }),
      tap(resp => {
        if (resp.arr.length) {
          this.taskService.taskData$.next(resp.arr)
        }
      }),
      distinctUntilChanged()
    ).subscribe(resp => {
      // if (this.linkTo && this.page == 1) {
      //   if (this.linkTo.indexOf("w_") != -1 || this.linkTo.indexOf("t_") != -1) {
      //     this.openTask(resp.data[0]);
      //   }  
      // }

      this.tasks = resp.data;

      console.log(this.totalInfo);

      console.log("this.tasks "+this.page, this.tasks)
      if (this.page == 1) {
        this.tasksContainer.nativeElement.scrollTop = 0;
        if (this.filter.value.operation_partner_company_id && this.filter.value.operation_partner_company_id.length) {
          this.getGroupsPartnerCompany()
        } else {
          this.getGroupsCompany()
        }
        this.getEmployees();
        if (this.company.permissions.includes('owner') || this.company.permissions.includes('admin') || this.company.permissions.includes('manager')) {
          this.getPartners();
        }
        this.getGroupsCompany();
        this.getRateStats();
      }

      this.page = this.page + 1;
      this.isLoad = false;
    },
    error => {
      this.isLoad = false;
    })
    
  }

  getGroupsPartnerCompany() {
    console.log("getGroupsPartnerCompany", this.filter.value.operation_partner_company_id);
    this.attachSubscriptions(
      this.companyService.getGroupsPartnerCompany(this.company.id, this.filter.value.operation_partner_company_id).subscribe(resp => {
        // this.partnerGroups = resp
        this.groups = resp;
        this.groups$.next(this.groups.slice());
        console.log("getGroupsPartnerCompany", resp);
      })
    )
  }

  openChat(id) {
    this.attachSubscriptions(
      this.chatService.getChatsExpand(1, this.company_id, {id: id}).pipe(
        map(res => res.body[0])
      ).subscribe(chat => {
        const goToChat = this.dialog.open(ChatsComponent, {
          backdropClass: ['backdrop_under_header', "without_pad"],
          panelClass: !this.is_mobile ? ['full_screen_dialog', 'global_chats_dialog', 'show_header'] : ['full_screen_dialog', 'global_chats_dialog'],
          position: !this.is_mobile ? { top: '58px', left: '0', right: '0', bottom: '0' } : {},
          data: {
            company: this.company,
            user: this.user,
            imgRoute: this.imgRoute,
            host: this.host,
            dialogType: 'global_chat',
            activeLang: this.activeLang, 
            chat: chat
          }
        });
      })
    )
  }

  onPartnerImgError(event){
    event.target.src = this.imgRoute+'/assets/img/partner.png'
  }

  closeOpenFilter() {
    this.subFilter && this.subFilter.unsubscribe();
    if (this.overlayRefFilter) {
      this.overlayRefFilter.dispose();
      this.overlayRefFilter = null;
    }
  }

  openFilter(el, val) {
    this.closeOpenFilter();
    let x = el.offsetLeft;
    let y =  el.offsetTop + el.offsetHeight;
    const positionStrategy = this.overlay.position()
    .flexibleConnectedTo({ x, y })
    .withPositions([
      {
        originX: 'start',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'top',
      }
    ]);

    this.overlayRefFilter = this.overlay.create({
      backdropClass: 'transparent_bg',
      hasBackdrop: true,
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });

    this.overlayRefFilter.attach(new TemplatePortal(this.filterMenu, this.viewContainerRef, {
      $implicit: val
    }));
    
    this.subFilter = fromEvent<MouseEvent>(document, 'click')
      .pipe(
        filter(event => {
          const clickTarget = event.target as HTMLElement;
          return !!this.overlayRefFilter && !this.overlayRefFilter.overlayElement.contains(clickTarget);
        }),
        take(1)
      ).subscribe(() => this.closeOpenFilter())

  }

  // closeOpenClientFilter() {
  //   this.subClientFilter && this.subClientFilter.unsubscribe();
  //   if (this.overlayRefClientFilter) {
  //     this.overlayRefClientFilter.dispose();
  //     this.overlayRefClientFilter = null;
  //   }
  // }

  // openClientFilter(el, val) {
  //   this.closeOpenFilter();
  //   let x = el.offsetLeft;
  //   let y =  el.offsetTop + el.offsetHeight;
  //   const positionStrategy = this.overlay.position()
  //   .flexibleConnectedTo({ x, y })
  //   .withPositions([
  //     {
  //       originX: 'start',
  //       originY: 'bottom',
  //       overlayX: 'start',
  //       overlayY: 'top',
  //     }
  //   ]);

  //   this.overlayRefClientFilter = this.overlay.create({
  //     backdropClass: 'transparent_bg',
  //     hasBackdrop: true,
  //     positionStrategy,
  //     scrollStrategy: this.overlay.scrollStrategies.close()
  //   });

  //   this.overlayRefClientFilter.attach(new TemplatePortal(this.filterClientMenu, this.viewContainerRef, {
  //     $implicit: val
  //   }));
    
  //   this.subClientFilter = fromEvent<MouseEvent>(document, 'click')
  //     .pipe(
  //       filter(event => {
  //         const clickTarget = event.target as HTMLElement;
  //         return !!this.overlayRefClientFilter && !this.overlayRefClientFilter.overlayElement.contains(clickTarget);
  //       }),
  //       take(1)
  //     ).subscribe(() => this.closeOpenClientFilter())

  // }

  goToChat(chat) {
    const goToChat = this.dialog.open(OperationChatComponent, {
      backdropClass: ['backdrop_under_header', "without_pad"],
      panelClass: ['full_screen_dialog', 'global_chats_dialog'],
      disableClose: true,
      data: {
        company: this.company,
        task: this.tasks[0],
        tasks: this.tasks,
        dialogType: 'operation_chat',
        operationsValues: this.operationsValues,
        work: this.tasks[0].operations.find(el => el.id == this.linkTo.split("_")[2]),
        user: this.user,
        empl_status: this.company.permissions.includes('owner') || this.company.permissions.includes('admin') || this.company.permissions.includes('manager'),
        chat: chat,
        chats: this.tasks[0].operations.find(el => el.id == this.linkTo.split("_")[2]).chatsInfo
      }
    });

    this.attachSubscriptions(
      goToChat.afterClosed().subscribe(result => {
        if (!!result && result.event == 'close') {
          this.chatService.postChatRead(chat.id, 0, this.company.id).subscribe(resp => {
            console.log(resp);
          })
        }
      })
    )
  }

  getTaskStatusById(id) {
    if (!this.taskStatuses || this.taskStatuses.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.taskStatuses.find(el => el.id == id)
  }
  
  onMobScroll() {
    this.attachSubscriptions(
      fromEvent(window, "scroll").pipe(
        map(() => window.scrollY),
        tap((val) => {
          val > 100 ? this.isScrolledCollectionBtn = true : this.isScrolledCollectionBtn = false;
        }),
        filter(current => current >=  document.body.clientHeight - window.innerHeight - 100),
        debounceTime(300),
        distinctUntilChanged()
      ).subscribe(() => {
        if (this.hasMore) {
          this.getTasks(this.page);
        }
      })
    )
  }

  onScroll() {
    this.attachSubscriptions(
      fromEvent(this.tasksContainer.nativeElement, "scroll").pipe(
        filter((e:any) => e.target.scrollTop >=  e.target.scrollHeight - e.target.offsetHeight - 400),
        debounceTime(300)
      ).subscribe(() => {
        if (this.hasMore) {
          this.getTasks(this.page);
        }
      })
    )
  }

  // @HostListener('window:scroll', ['$event']) // for window scroll events
  // onMobileScroll(e) {
  //   if (this.is_mobile) {
  //     window.pageYOffset > 100 ? this.isScrolledCollectionBtn = true : this.isScrolledCollectionBtn = false;
  //   }
  // }

  getPartners() {
    this.attachSubscriptions(
      this.companyService.getPartners({company_id: this.company_id}).subscribe(resp => {
        this.partners = resp.filter(x => x.partner_company_id != 0);
        console.log("getPartners", this.partners)
        this.partners$.next(this.partners.slice())
      }, error => {
        console.log(error)
      })
    )
  }

  closeExecFilter() {
    this.execFilterSub && this.execFilterSub.unsubscribe();
    if (this.overlayExecRefFilter) {
      this.overlayExecRefFilter.dispose();
      this.overlayExecRefFilter = null;
    }
  }

  openExecFilter(el, val) {
    this.closeExecFilter();
    let x = el.offsetLeft;
    let y =  el.offsetTop + el.offsetHeight;
    const positionStrategy = this.overlay.position()
    .flexibleConnectedTo({ x, y })
    .withPositions([
      {
        originX: 'start',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'top',
      }
    ]);

    this.overlayExecRefFilter = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });

    this.overlayExecRefFilter.attach(new TemplatePortal(this.filterExecMenu, this.viewContainerRef, {
      $implicit: val
    }));
    
    this.execFilterSub = fromEvent<MouseEvent>(document, 'click')
      .pipe(
        filter(event => {
          const clickTarget = event.target as HTMLElement;
          return !!this.overlayExecRefFilter && !this.overlayExecRefFilter.overlayElement.contains(clickTarget);
        }),
        take(1)
      ).subscribe(() => this.closeExecFilter())

  }

  open({ x, y }: MouseEvent, val) {
    this.close();
    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo({ x, y })
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        }
      ]);

    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });

    this.overlayRef.attach(new TemplatePortal(this.taskMenu, this.viewContainerRef, {
      $implicit: val
    }));
    
    this.sub = fromEvent<MouseEvent>(document, 'click')
      .pipe(
        filter(event => {
          const clickTarget = event.target as HTMLElement;
          return !!this.overlayRef && !this.overlayRef.overlayElement.contains(clickTarget);
        }),
        take(1)
      ).subscribe(() => this.close())

  }

  close() {
    this.sub && this.sub.unsubscribe();
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
  }

  checkboxLabel(row?): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.taskCollection.isSelected(row.id) ? 'deselect' : 'select'} task ${row.name}`;
  }

  isAllSelected() {
    const numSelected = this.taskCollection.selected.length;
    const numRows = this.tasks.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
        this.taskCollection.clear() :
        this.tasks.forEach(row => this.taskCollection.select(row.id));
  }

  getCurrencyList() {
    this.attachSubscriptions(
      this.globalDataService.getCurrencies().subscribe(resp => {
        console.log("getCurrencyList", resp)
        let currencyData = resp.slice()
        currencyData.map(x => x.summary = 0)
        this.currencyes = currencyData;
        this.totalInfo['summaryPrice'] = [...currencyData]
        console.log("this.totalInfo", this.totalInfo);
      })
    )
  }

  getOperationStatusName(id) {
    switch (+id) {
      case 1:
        return "Waiting";
        break;
      case 2:
        return "In process";
        break;
      case 3:
        return "To approve";
        break;
      case 4:
        return "Approved";
        break;
      case 5:
        return "To correct";
        break;
      case 6:
        return "Sources Needed";
        break;
      case 97:
        return "On hold";
        break;
      case 98:
        return "Ready";
        break;
      case 99:
        return "Canceled";
        break;
    }
  }

  getColumnName(id) {
    if (!this.operationsValues || this.operationsValues.filter(el => el.id == id).length == 0) {
      return '';
    }
    return this.operationsValues.find(el => el.id == id)?.translation?.name ? this.operationsValues.find(el => el.id == id).translation.name : this.operationsValues.find(el => el.id == id).name
  }

  showFilesInterface(task, work?, loc?) {
    const dialogRef = this.dialog.open(InterfaceComponent, {
      backdropClass: ['backdrop_under_header', 'file_backdrop_under_header'],
      panelClass: !this.is_mobile ? ['file_manager_modal', 'show_header'] : 'file_manager_modal',
      data: {
        company: this.company,
        task: task,
        work: work != undefined ? work : 0,
        tasks: this.tasks,
        user: this.user,
        loc: loc != undefined ? loc : "",
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result && result.event == 'update') {

        }
      })
    )
  }
  
  ngOnDestroy(): void {
    
    this.layoutService.overflowAuto();
    if (this.tasksSub) {
      this.tasksSub.unsubscribe()
    }
    if (this.taskDataSub) {
      this.taskDataSub.unsubscribe();
    }
    this.clearSubscriptions()
  }

}
