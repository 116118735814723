<mat-dialog-content class="lite_job_content_out">
    <div class="lite_job_head">
        <div class="lite_work_name_out">
            <div class="job_type_out" style="flex-shrink: 0;">
                <div class="job_type">
                    <span class="text_one_line type">
                        {{ job.operation && job.operation.name ? job.operation.name : getColumnName(job.operation_id) }}
                    </span>
                    <span *ngIf="getPrimaryTag(job)" class="text_one_line val">{{getPrimaryTag(job)?.parameterValue?.value}}</span>
                </div>
            </div>
            <!-- <div class="job_status ml-5" style="flex-shrink: 0;">
                <div class="inside_job" [ngClass]="{
                    'first': job?.status_id == '1',
                    'second': job?.status_id == '2',
                    'third': job?.status_id == '3',
                    'fours': job?.status_id == '4',
                    'fives': job?.status_id == '5',
                    'sixs': job?.status_id == '6',
                    'nine': job?.status_id == '97',
                    'seven': job?.status_id == '98',
                    'eight': job?.status_id == '99'}">
                    <span>{{ getStatus(job.status_id)?.name | translate }}</span>  
                </div>
                <a *ngIf="job?.content_url" target="_blank" [href]="job?.content_url"><mat-icon style="font-size: 20px; width: 20px; height: 20px;">open_in_new</mat-icon></a>
            </div> -->
            <ng-container *ngIf="isMainEmpl(job) || !!job?.isManager || ifJobClientModerator(job); else elseTemplateMANAGERSmall">
                <div class="job_status" [ngClass]="{'full': job.open}">
                    <div class="out_job" (click)="$event.preventDefault(); $event.stopPropagation()">
                        <div class="inside_job" (click)="openStatus(job)" [ngClass]="{
                            'first': job?.status_id == '1',
                            'second': job?.status_id == '2',
                            'third': job?.status_id == '3',
                            'fours': job?.status_id == '4',
                            'fives': job?.status_id == '5',
                            'sixs': job?.status_id == '6',
                            'nine': job?.status_id == '97',
                            'seven': job?.status_id == '98',
                            'eight': job?.status_id == '99'}">
                            <span class="text_one_line">{{ getStatus(job.status_id)?.name | translate }}</span>  
                        </div>
                        <ng-container *ngIf="!job.newStatusFor; else elseTemplateNewStatusSmall">

                            <div class="cst_menu" *ngIf="job.open">
                                <div class="item" *ngFor="let stat of job.statuses" (click)="chooseStatus(job, stat)" [ngClass]="{
                                    'first': stat.id == '1',
                                    'second': stat.id == '2',
                                    'third': stat.id == '3',
                                    'fours': stat.id == '4',
                                    'fives': stat.id == '5',
                                    'sixs': stat.id == '6',
                                    'nine': stat.id == '97',
                                    'seven': stat.id == '98',
                                    'eight': stat.id == '99'}">
                                    <span>{{ stat.name | translate }}</span>
                                </div>
                            </div>
                        </ng-container>
                        <ng-template #elseTemplateNewStatusSmall>
                            <div class="cst_menu" *ngIf="job.open">
                                <div class="item" [ngClass]="{'initial_selected': empl.initial_selected, 'selected': empl.selected}" *ngFor="let empl of job.empl_status" (click)="selectEmpl(job, empl)">
                                    <ng-container *ngIf="!empl?.is_partner; else elseTemplateIsPartnerStatus">                                                            
                                        <ng-container *ngIf="empl?.employee?.image; else elseTemplateStat">
                                            <img [src]="empl?.employee?.image">
                                        </ng-container>
                                        <ng-template #elseTemplateStat>
                                            <mat-icon>account_circle</mat-icon>
                                        </ng-template>
                                        <span>{{ empl?.employee?.name }}</span>
                                    </ng-container>
                                    <ng-template #elseTemplateIsPartnerStatus>
                                        <ng-container *ngIf="!!empl?.partnerCompany?.avatarFile?.thumbnail && !!host; else elseTemplatePartnerAvatar">
                                            <img class="partner_avatar" (error)="onPartnerImgError($event)" [src]="host + empl?.partnerCompany?.avatarFile?.thumbnail + '?company_id=' + company?.id">
                                        </ng-container>
                                        <ng-template #elseTemplatePartnerAvatar>
                                            <img class="partner_avatar" (error)="onPartnerImgError($event)" src="{{ imgRoute }}assets/img/partner.png">
                                        </ng-template>
                                        <span>{{empl?.partnerCompany?.name}}</span>
                                    </ng-template>
                                </div>
                                <div class="cst_divider"></div>
                                <div class="item" *ngIf="job.mainEmployees.length == 0" (click)="applyJobStats(job)">
                                    <span>{{ "Apply to job" | translate }}</span>
                                </div>
                                <div class="item" (click)="applyStats(job)">
                                    <span>{{ "Apply" | translate }}</span>
                                </div>
                                <div class="item" (click)="openStatus(job)">
                                    <span>{{ "Cancel" | translate }}</span>
                                </div>
                            </div>
                        </ng-template>
                    </div>
                </div>
            </ng-container>
            <ng-template #elseTemplateMANAGERSmall>
                <div class="job_status" [ngClass]="{'full': job.open}">
                    <div class="out_job">
                        <ng-container *ngIf="!!job?.employeeStatus?.status; else elseTemplateHasPersonalSmall">
                            <div class="inside_job" (click)="$event.preventDefault(); $event.stopPropagation(); openEmplStatus(job)" [ngClass]="{
                                'first': job?.employeeStatus?.status_id == '1',
                                'second': job?.employeeStatus?.status_id == '2',
                                'third': job?.employeeStatus?.status_id == '3',
                                'fours': job?.employeeStatus?.status_id == '4',
                                'fives': job?.employeeStatus?.status_id == '5',
                                'sixs': job?.employeeStatus?.status_id == '6',
                                'nine': job?.employeeStatus?.status_id == '97',
                                'seven': job?.employeeStatus?.status_id == '98',
                                'eight': job?.employeeStatus?.status_id == '99'}">
                                <span class="text_one_line">{{ job?.employeeStatus?.status?.name | translate }}</span>  
                            </div>
                        </ng-container>
                        <ng-template #elseTemplateHasPersonalSmall>
                            <div class="inside_job" (click)="$event.preventDefault(); $event.stopPropagation(); openEmplStatus(job)" [ngClass]="{
                                'first': job?.status_id == '1',
                                'second': job?.status_id == '2',
                                'third': job?.status_id == '3',
                                'fours': job?.status_id == '4',
                                'fives': job?.status_id == '5',
                                'sixs': job?.status_id == '6',
                                'nine': job?.status_id == '97',
                                'seven': job?.status_id == '98',
                                'eight': job?.status_id == '99'}">
                                <span class="text_one_line">{{ getStatus(job.status_id)?.name | translate }}</span> 
                            </div>
                        </ng-template>

                        <div class="cst_menu" *ngIf="job.open">
                            <div class="item" *ngFor="let stat of job.statuses" (click)="$event.preventDefault(); $event.stopPropagation(); chooseEmplStatus(job, stat)" [ngClass]="{
                                'first': stat.id == '1',
                                'second': stat.id == '2',
                                'third': stat.id == '3',
                                'fours': stat.id == '4',
                                'fives': stat.id == '5',
                                'sixs': stat.id == '6',
                                'nine': stat.id == '97',
                                'seven': stat.id == '98',
                                'eight': stat.id == '99'}">
                                <span>{{ stat.name | translate}}</span>
                            </div>
                        </div>  
                    </div>
                </div>
            </ng-template>
            <div class="ml-5 lite_work_name text_one_line" #tooltip="matTooltip"
            matTooltip="{{ 'Job title' | translate }}"
            [matTooltipPosition]="'above'">
                {{job?.name}}
            </div>
       

            <div class="lite_work_info_right">
                <span class="job_id" (click)="data?.job.is_clicked = false; data?.openJob(data?.job?.task_id, data?.job, {selectedIndex: selectedIndex.value})" #tooltip="matTooltip"
                matTooltip="{{ 'Job id.\nClick to open advanced mode of chats in job.' | translate }}"
                [matTooltipPosition]="'above'">
                    ID {{job?.id}}
                </span>

                <div class="close" (click)="close()">
                    <svg style="width: 6px; height: 6px" width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6 0.604286L5.39571 0L3 2.39571L0.604286 0L0 0.604286L2.39571 3L0 5.39571L0.604286 6L3 3.60429L5.39571 6L6 5.39571L3.60429 3L6 0.604286Z" fill="#647C64"/>
                    </svg>
                </div>
            </div>
        </div>
    </div>
    <div class="lite_job_content">
        <div class="tabs">
            <div class="tab" [ngClass]="{'active': selectedIndex.value == 0}" (click)="changeTab(0)">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M21.6 0H2.4C1.08 0 0 1.08 0 2.4V24L4.8 19.2H21.6C22.92 19.2 24 18.12 24 16.8V2.4C24 1.08 22.92 0 21.6 0ZM21.6 16.8H3.84L2.4 18.24V2.4H21.6V16.8Z" fill="#686868"/>
                </svg> 
                <span>{{"Chats" | translate}}</span>
            </div>
            <div class="tab" [ngClass]="{'active': selectedIndex.value == 3}" (click)="changeTab(3)">
                <svg width="12" height="24" viewBox="0 0 12 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.3636 5.45455V18C10.3636 19.1573 9.9039 20.2672 9.08556 21.0856C8.26722 21.9039 7.15731 22.3636 6 22.3636C4.84269 22.3636 3.73278 21.9039 2.91444 21.0856C2.0961 20.2672 1.63636 19.1573 1.63636 18V4.36364C1.63636 3.64032 1.9237 2.94663 2.43516 2.43516C2.94663 1.9237 3.64032 1.63636 4.36364 1.63636C5.08695 1.63636 5.78065 1.9237 6.29211 2.43516C6.80357 2.94663 7.09091 3.64032 7.09091 4.36364V15.8182C7.09091 16.1075 6.97597 16.385 6.77139 16.5896C6.5668 16.7942 6.28933 16.9091 6 16.9091C5.71067 16.9091 5.4332 16.7942 5.22861 16.5896C5.02403 16.385 4.90909 16.1075 4.90909 15.8182V5.45455H3.27273V15.8182C3.27273 16.5415 3.56006 17.2352 4.07153 17.7467C4.58299 18.2581 5.27668 18.5455 6 18.5455C6.72332 18.5455 7.41701 18.2581 7.92847 17.7467C8.43994 17.2352 8.72727 16.5415 8.72727 15.8182V4.36364C8.72727 3.20633 8.26753 2.09642 7.44919 1.27808C6.63085 0.459739 5.52095 0 4.36364 0C3.20633 0 2.09642 0.459739 1.27808 1.27808C0.459739 2.09642 0 3.20633 0 4.36364V18C0 19.5913 0.632141 21.1174 1.75736 22.2426C2.88258 23.3679 4.4087 24 6 24C7.5913 24 9.11742 23.3679 10.2426 22.2426C11.3679 21.1174 12 19.5913 12 18V5.45455H10.3636Z" fill="#686868"/>
                </svg>
                <span>{{"Files" | translate}}</span>
            </div>
            <div class="tab" [ngClass]="{'active': selectedIndex.value == 1}" (click)="changeTab(1)">
                <svg width="24" height="17" viewBox="0 0 24 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.4 8.4C17.794 8.4 18.1841 8.3224 18.5481 8.17164C18.912 8.02087 19.2427 7.7999 19.5213 7.52132C19.7999 7.24274 20.0209 6.91203 20.1716 6.54805C20.3224 6.18407 20.4 5.79397 20.4 5.4C20.4 4.60435 20.0839 3.84129 19.5213 3.27868C18.9587 2.71607 18.1957 2.4 17.4 2.4C16.6044 2.4 15.8413 2.71607 15.2787 3.27868C14.7161 3.84129 14.4 4.60435 14.4 5.4C14.4 5.79397 14.4776 6.18407 14.6284 6.54805C14.7791 6.91203 15.0001 7.24274 15.2787 7.52132C15.8413 8.08393 16.6044 8.4 17.4 8.4ZM8.4 7.2C9.35478 7.2 10.2705 6.82072 10.9456 6.14558C11.6207 5.47045 12 4.55478 12 3.6C12 2.64522 11.6207 1.72955 10.9456 1.05442C10.2705 0.379285 9.35478 0 8.4 0C7.44522 0 6.52955 0.379285 5.85442 1.05442C5.17929 1.72955 4.8 2.64522 4.8 3.6C4.8 4.55478 5.17929 5.47045 5.85442 6.14558C6.52955 6.82072 7.44522 7.2 8.4 7.2ZM17.4 10.8C15.204 10.8 10.8 11.904 10.8 14.1V16.8H24V14.1C24 11.904 19.596 10.8 17.4 10.8ZM8.4 9.6C5.604 9.6 0 11.004 0 13.8V16.8H8.4V14.1C8.4 13.08 8.796 11.292 11.244 9.936C10.2 9.72 9.192 9.6 8.4 9.6Z" fill="#686868"/>
                </svg> 
                <span>{{"Asgn" | translate}}</span>
            </div>
            <div class="tab" [ngClass]="{'active': selectedIndex.value == 2}" (click)="changeTab(2)">
                <svg width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M19.2 21.6H2.4V8.4H19.2M15.6 0V2.4H6V0H3.6V2.4H2.4C1.068 2.4 0 3.468 0 4.8V21.6C0 22.2365 0.252856 22.847 0.702944 23.2971C1.15303 23.7471 1.76348 24 2.4 24H19.2C19.8365 24 20.447 23.7471 20.8971 23.2971C21.3471 22.847 21.6 22.2365 21.6 21.6V4.8C21.6 3.468 20.52 2.4 19.2 2.4H18V0M16.8 13.2H10.8V19.2H16.8V13.2Z" fill="#686868"/>
                </svg> 
                <span>{{"Plan" | translate}}</span>
            </div>
            <button class="lite_fm_ic_btn projects" mat-icon-button (click)="openProjects()">
                <mat-icon>movie_filter</mat-icon>
            </button>
            <div class="filter" [ngClass]="{'active': is_filter_active}" (click)="toggleFilter()">
                <mat-icon
                    [matBadge]="checkFmFilterCount()"
                    [matBadgeHidden]="!checkFmFilterCount()"
                    matBadgePosition="above after"
                    matBadgeColor="primary">
                    filter_list
                </mat-icon>
                <!-- <svg style="width: 15px; height: 10px" width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2.5 5.83333H12.5V4.16667H2.5M0 0V1.66667H15V0M5.83333 10H9.16667V8.33333H5.83333V10Z" fill="black"/>
                </svg> -->
            </div>
        </div>
        <div class="tab_content">
    
            <ng-container [ngSwitch]="selectedIndex.value">
                <ng-container *ngSwitchCase="0">
                    <ng-container *ngIf="is_filter_active; else elseTemplateIsFilter0">
                        <form [formGroup]="liteChatFilter" class="lite_filter" [appDeviceClass]="'custom-scroll custom-scroll-t'">
                            <mat-form-field appearance="legacy" class="lite_filter_el lite_filter_field">
                                <mat-label>{{ 'Search' | translate }}</mat-label>
                                <input formControlName="q" matInput placeholder="{{ 'Search' | translate }}">
                            </mat-form-field>
    
                            <mat-form-field appearance="legacy" class="lite_filter_el lite_filter_field">
                                <mat-label>{{ 'Chat title' | translate }}</mat-label>
                                <input formControlName="q_name" matInput placeholder="{{ 'Chat title' | translate }}">
                            </mat-form-field>
    
                            <mat-form-field appearance="legacy" class="lite_filter_el lite_filter_field">
                                <mat-label>{{ 'Chat messages' | translate }}</mat-label>
                                <input formControlName="q_text" matInput placeholder="{{ 'Chat messages' | translate }}">
                            </mat-form-field>
    
                            
                            <mat-form-field appearance="legacy" class="lite_filter_el lite_filter_select">
                                <mat-label>{{ "Chat Tag(s)" | translate }}</mat-label>
                                <mat-select [disableOptionCentering]="true" selectDir [multiple]="true" formControlName="discussion_parameter_value_id">
                                    <mat-select-trigger class="multi_users">
                                        <ng-container *ngIf="liteChatFilter.get('discussion_parameter_value_id').value && liteChatFilter.get('discussion_parameter_value_id').value.length">
                                            <ng-container *ngFor="let id of liteChatFilter.get('discussion_parameter_value_id').value">
                                                <span>{{ getValuesById(id)?.value }}</span>
                                            </ng-container>
                                        </ng-container>
                                    </mat-select-trigger>
                                    <mat-option>
                                        <ngx-mat-select-search 
                                        placeholderLabel="{{ 'Search' | translate }}" 
                                        noEntriesFoundLabel="{{ 'no matching tag(s) found' | translate }}"
                                        [formControl]="allValuesControl"></ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option *ngFor="let item of allValues$ | async" [value]="item.id">
                                        <div class="d-f-c" style="justify-content: space-between;">
                                            <span>{{ item.value }}</span>
                                            <span>{{ item?.parameter?.name }}</span>
                                        </div>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
    
                            <mat-form-field appearance="legacy" class="lite_filter_el lite_filter_select">
                                <mat-label>{{ "Connected with file" | translate }}</mat-label>
                                <mat-select disableOptionCentering #filterChats formControlName="data_file_id">
                                    <mat-option [value]="''" [ngClass]="{'cst_active_select_option': liteChatFilter.value.data_file_id === ''}">
                                        {{ "All" | translate }}
                                    </mat-option>
                                    <mat-option [value]="'isset'" [ngClass]="{'cst_active_select_option': liteChatFilter.value.data_file_id == 'isset'}">
                                        {{ "File chats" | translate }}
                                    </mat-option>
                                    <mat-option [value]="0" [ngClass]="{'cst_active_select_option': liteChatFilter.value.data_file_id === 0}">
                                        {{ "Just chats" | translate }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
    
                            <mat-form-field appearance="legacy" class="lite_filter_el lite_filter_date">
                                <mat-label>{{ 'Chat Added' | translate }}</mat-label>
                                <mat-date-range-input [rangePicker]="rangePickerCA">
                                  <input formControlName="discussion_created_at_from" matStartDate placeholder="{{ 'Date from' | translate }}">
                                  <input formControlName="discussion_created_at_to" matEndDate placeholder="{{ 'Date to' | translate }}">
                                </mat-date-range-input>
                                <mat-datepicker-toggle matSuffix [for]="rangePickerCA"></mat-datepicker-toggle>
                                <mat-date-range-picker #rangePickerCA>
                                  <mat-date-range-picker-actions>
                                    <button mat-button matDateRangePickerCancel>Cancel</button>
                                    <button mat-raised-button color="primary" matDateRangePickerApply>Apply</button>
                                  </mat-date-range-picker-actions>
                                </mat-date-range-picker>
                            </mat-form-field>
                            
                            <mat-form-field appearance="legacy" class="lite_filter_el lite_filter_date">
                                <mat-label>{{ 'Chat Completed' | translate }}</mat-label>
                                <mat-date-range-input [rangePicker]="rangePickerCC">
                                  <input formControlName="discussion_completed_at_from" matStartDate placeholder="{{ 'Date from' | translate }}">
                                  <input formControlName="discussion_completed_at_to" matEndDate placeholder="{{ 'Date to' | translate }}">
                                </mat-date-range-input>
                                <mat-datepicker-toggle matSuffix [for]="rangePickerCC"></mat-datepicker-toggle>
                                <mat-date-range-picker #rangePickerCC>
                                  <mat-date-range-picker-actions>
                                    <button mat-button matDateRangePickerCancel>Cancel</button>
                                    <button mat-raised-button color="primary" matDateRangePickerApply>Apply</button>
                                  </mat-date-range-picker-actions>
                                </mat-date-range-picker>
                            </mat-form-field>
    
                            <mat-form-field appearance="legacy" class="lite_filter_el lite_filter_date">
                                <mat-label>{{ 'Deadline' | translate }}</mat-label>
                                <mat-date-range-input [rangePicker]="rangePickerCD">
                                  <input formControlName="discussion_planned_completed_at_from" matStartDate placeholder="{{ 'Date from' | translate }}">
                                  <input formControlName="discussion_planned_completed_at_to" matEndDate placeholder="{{ 'Date to' | translate }}">
                                </mat-date-range-input>
                                <mat-datepicker-toggle matSuffix [for]="rangePickerCD"></mat-datepicker-toggle>
                                <mat-date-range-picker #rangePickerCD>
                                  <mat-date-range-picker-actions>
                                    <button mat-button matDateRangePickerCancel>Cancel</button>
                                    <button mat-raised-button color="primary" matDateRangePickerApply>Apply</button>
                                  </mat-date-range-picker-actions>
                                </mat-date-range-picker>
                            </mat-form-field>
    
                            <mat-form-field appearance="legacy" class="lite_filter_el lite_filter_date">
                                <mat-label>{{ 'Post Added' | translate }}</mat-label>
                                <mat-date-range-input [rangePicker]="rangePickerPA">
                                  <input formControlName="posted_at_from" matStartDate placeholder="{{ 'Date from' | translate }}">
                                  <input formControlName="posted_at_to" matEndDate placeholder="{{ 'Date to' | translate }}">
                                </mat-date-range-input>
                                <mat-datepicker-toggle matSuffix [for]="rangePickerPA"></mat-datepicker-toggle>
                                <mat-date-range-picker #rangePickerPA>
                                  <mat-date-range-picker-actions>
                                    <button mat-button matDateRangePickerCancel>Cancel</button>
                                    <button mat-raised-button color="primary" matDateRangePickerApply>Apply</button>
                                  </mat-date-range-picker-actions>
                                </mat-date-range-picker>
                            </mat-form-field>
    
                            <div class="btns">
                                <!-- <button mat-raised-button (click)="log()">
                                    {{ "log" | translate }}
                                </button> -->
                                <button mat-raised-button (click)="resetChatFilter()">
                                    {{ "Reset" | translate }}
                                </button>
                                <button class="ml-1" (click)="toggleFilter()" mat-raised-button color="primary">
                                    {{ "Filter" | translate }}
                                </button>
                            </div>
                        </form>
                    </ng-container>
                    <ng-template #elseTemplateIsFilter0>
                        <ng-container *ngIf="!!data?.job.outputChats">
                            <app-lite-chats 
                            [useOut]="true"
                            (CloseContext)="closeContext()" 
                            [liteFilterForm]="liteChatFilter"
                            [liteFilter]="liteChatFilter.value"
                            (OpenJob)="data?.openJob(data?.job?.task_id, data?.job, {selectedIndex: 0})" 
                            [target]="data?.job" 
                            [is_mobile]="data?.is_mobile" 
                            [user]="data?.user" 
                            [activeLang]="data?.activeLang" 
                            [company]="data?.company" 
                            [imgRoute]="data?.imgRoute" 
                            [host]="data?.host"></app-lite-chats>
                        </ng-container>
                    </ng-template>
                </ng-container>
                <ng-container *ngSwitchCase="1">
                    <ng-container *ngIf="is_filter_active; else elseTemplateIsFilter1">
                        <div style="width: 500px; height: 370px;">
                            Asgn filter in developing
                        </div>
                    </ng-container>
                    <ng-template #elseTemplateIsFilter1>
                        <app-lite-executors *ngIf="!!data?.job.outputExecutors"
                        [useOut]="true"
                        (CloseContext)="closeContext()" 
                        (OpenJob)="data?.openJob(data?.job?.task_id, data?.job, {selectedIndex: 3})" 
                        [target]="data?.job" 
                        [is_mobile]="data?.is_mobile" 
                        [user]="data?.user" 
                        [activeLang]="data?.activeLang" 
                        [company]="data?.company" 
                        [imgRoute]="data?.imgRoute" 
                        [host]="data?.host"
                        ></app-lite-executors>
                    </ng-template>
                </ng-container>
                <ng-container *ngSwitchCase="2">
                    <ng-container *ngIf="is_filter_active; else elseTemplateIsFilter2">
                        <form [formGroup]="liteBoardFilter" class="lite_filter" [appDeviceClass]="'custom-scroll custom-scroll-t'">
                            <mat-form-field appearance="legacy" class="lite_filter_el lite_filter_field">
                                <mat-label>{{ 'Search' | translate }}</mat-label>
                                <input formControlName="q" matInput placeholder="{{ 'Search' | translate }}">
                            </mat-form-field>
                        </form>
                    </ng-container>
                    <ng-template #elseTemplateIsFilter2>
                        <ng-container *ngIf="!!data?.job.outputBoard">
                            <app-lite-board 
                            [useOut]="true"
                            (CloseContext)="closeContext()" 
                            [liteFilterForm]="liteBoardFilter"
                            [liteFilter]="liteBoardFilter.value"
                            (OpenJob)="data?.openJob(data?.job?.task_id, data?.job, {selectedIndex: 0})" 
                            [target]="data?.job" 
                            [is_mobile]="data?.is_mobile" 
                            [user]="data?.user" 
                            [activeLang]="data?.activeLang" 
                            [company]="data?.company" 
                            [imgRoute]="data?.imgRoute" 
                            [host]="data?.host"></app-lite-board>
                        </ng-container>
                    </ng-template>
                </ng-container>
                <ng-container *ngSwitchCase="3">
                    <ng-container *ngIf="is_filter_active; else elseTemplateIsFilter3">
                        <form [formGroup]="liteFmFilter" class="lite_filter" [appDeviceClass]="'custom-scroll custom-scroll-t'">
                            <mat-form-field appearance="legacy" class="lite_filter_el lite_filter_field">
                                <mat-label>{{ 'Search' | translate }}</mat-label>
                                <input formControlName="q" matInput placeholder="{{ 'Search' | translate }}">
                            </mat-form-field>
    
                            
                            <mat-form-field appearance="legacy" class="lite_filter_el lite_filter_select">
                                <mat-label>{{ "File Tag(s)" | translate }}</mat-label>
                                <mat-select [disableOptionCentering]="true" selectDir [multiple]="true" formControlName="file_parameter_value_id">
                                    <mat-select-trigger class="multi_users">
                                        <ng-container *ngIf="liteFmFilter.get('file_parameter_value_id').value && liteFmFilter.get('file_parameter_value_id').value.length">
                                            <ng-container *ngFor="let id of liteFmFilter.get('file_parameter_value_id').value">
                                                <span>{{ getValuesById(id)?.value }}</span>
                                            </ng-container>
                                        </ng-container>
                                    </mat-select-trigger>
                                    <mat-option>
                                        <ngx-mat-select-search 
                                        placeholderLabel="{{ 'Search' | translate }}" 
                                        noEntriesFoundLabel="{{ 'no matching tag(s) found' | translate }}"
                                        [formControl]="allValuesControl"></ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option *ngFor="let item of allValues$ | async" [value]="item.id">
                                        <div class="d-f-c" style="justify-content: space-between;">
                                            <span>{{ item.value }}</span>
                                            <span>{{ item?.parameter?.name }}</span>
                                        </div>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
    
                            <div class="lite_filter_el">
                                <mat-button-toggle-group appearance="legacy" formControlName="is_dir" class="lite_filter_toggle_group">
                                    <mat-button-toggle [value]="''">{{"All" | translate}}</mat-button-toggle>
                                    <mat-button-toggle [value]="0">{{"Only Files" | translate}}</mat-button-toggle>
                                    <mat-button-toggle [value]="1">{{"Only Folders" | translate}}</mat-button-toggle>
                                </mat-button-toggle-group>
                            </div>
    
                            <div class="lite_filter_el" style="margin-top: 1.25em; margin-bottom: 1.25rem;">
                                <mat-button-toggle-group appearance="legacy" formControlName="location_type" class="lite_filter_toggle_group">
                                    <mat-button-toggle [value]="'location'">{{"In Folder" | translate}}</mat-button-toggle>
                                    <mat-button-toggle [value]="'location_all'">{{"In All Subfolders" | translate}}</mat-button-toggle>
                                </mat-button-toggle-group>
                            </div>
    
                            <mat-form-field appearance="legacy" class="lite_filter_el lite_filter_select">
                                <mat-label>{{ "Checklist" | translate }}</mat-label>
                                <mat-select [disableOptionCentering]="true" selectDir formControlName="operation_reminder_id">
                                    <mat-option>
                                        <ngx-mat-select-search 
                                            placeholderLabel="{{ 'Search' | translate }}" 
                                            noEntriesFoundLabel="{{ 'no matching checklists found' | translate }}"
                                            [formControl]="checklistsControl"></ngx-mat-select-search>
                                      </mat-option>
                                    <mat-option *ngFor="let fil of checklists$ | async" [value]="fil.id">
                                        {{fil.text | translate}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
    
                            <mat-form-field appearance="legacy" class="lite_filter_el lite_filter_select">
                                <mat-label>{{ "Device" | translate }}</mat-label>
                                <mat-select [disableOptionCentering]="true" selectDir formControlName="meta_device">
                                    <mat-option>
                                        <ngx-mat-select-search 
                                            placeholderLabel="{{ 'Search' | translate }}" 
                                            noEntriesFoundLabel="{{ 'no matching devices found' | translate }}"
                                            [formControl]="devicesMoreControl"></ngx-mat-select-search>
                                      </mat-option>
                                    <mat-option *ngFor="let fil of devices$ | async" [value]="fil">
                                        {{fil | translate}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
    
                            <mat-form-field appearance="legacy" class="lite_filter_el lite_filter_select">
                                <mat-label>{{ "Ready Version" | translate }}</mat-label>
                                <mat-select [disableOptionCentering]="true" selectDir formControlName="is_ready_if_approved">
                                    <mat-option [value]="'0'">
                                        {{"No" | translate}}
                                    </mat-option>
                                    <mat-option [value]="'1'">
                                        {{"Yes" | translate}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
    
                            <mat-form-field appearance="legacy" class="lite_filter_el lite_filter_select">
                                <mat-label>{{ "Part of" | translate }}</mat-label>
                                <mat-select [disableOptionCentering]="true" selectDir formControlName="part_of_count">
                                    <mat-option [value]="'isset'">
                                        {{"Is part of file" | translate}}
                                    </mat-option>
                                    <mat-option [value]="'0'">
                                        {{"Isn`t part of file" | translate}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            
                            <mat-form-field appearance="legacy" class="lite_filter_el lite_filter_select">
                                <mat-label>{{ "Consist of" | translate }}</mat-label>
                                <mat-select [disableOptionCentering]="true" selectDir formControlName="consist_of_count">
                                    <mat-option [value]="'isset'">
                                        {{"Has parts" | translate}}
                                    </mat-option>
                                    <mat-option [value]="'0'">
                                        {{"Hasn't parts" | translate}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
    
                            <mat-form-field appearance="legacy" class="lite_filter_el lite_filter_select">
                                <mat-label>{{ "Related" | translate }}</mat-label>
                                <mat-select [disableOptionCentering]="true" selectDir formControlName="related_count">
                                    <mat-option [value]="'isset'">
                                        {{"Is related" | translate}}
                                    </mat-option>
                                    <mat-option [value]="'0'">
                                        {{"Isn`t related" | translate}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
    
                            <div class="btns">
                                <button mat-raised-button (click)="resetFmFilter()">
                                    {{ "Reset" | translate }}
                                </button>
                                <button class="ml-1" (click)="toggleFilter()" mat-raised-button color="primary">
                                    {{ "Filter" | translate }}
                                </button>
                            </div>
                        </form>
                    </ng-container>
                    <ng-template #elseTemplateIsFilter3>
                        <ng-container *ngIf="!!data?.job.outputFolder">
                            <app-lite-file-manager 
                            [useOut]="true"
                            (CloseContext)="closeContext()"
                            [liteFilterForm]="liteFmFilter"
                            [liteFilter]="liteFmFilter.value"
                            (OpenJob)="data?.openJob(data?.job?.task_id, data?.job, {selectedIndex: 3})" 
                            [target]="data?.job" 
                            [is_mobile]="data?.is_mobile" 
                            [user]="data?.user" 
                            [activeLang]="data?.activeLang" 
                            [company]="data?.company" 
                            [imgRoute]="data?.imgRoute" 
                            [host]="data?.host"></app-lite-file-manager>
                        </ng-container>
                    </ng-template>
                </ng-container>
            </ng-container>
    
            <!-- <div class="tab_footer">
                <div class="active_filters">
    
                </div>
                
                <div class="d-f-c">
                    <button class="lite_fm_ic_btn mr-5" mat-icon-button (click)="openProjects()">
                        <mat-icon>movie_filter</mat-icon>
                    </button>
                    <span class="job_id" (click)="data?.job.is_clicked = false; data?.openJob(data?.job?.task_id, data?.job, {selectedIndex: selectedIndex.value})">
                        JOB ID {{data?.job?.id}}
                    </span>
                </div>
            </div> -->
        </div>
    </div>
</mat-dialog-content>