<mat-dialog-content class="mat-typography">
    <span class="head_title" (click)="log()">
        <b style="margin-right: 3.5px;" *ngIf="(urlsByChannel$ | async)">{{(urlsByChannel$ | async).length}} {{"channels" | translate}}</b> {{urlsCount}} {{"streams" | translate}}
        <ng-container *ngIf="liveCollection.selected && liveCollection.selected.length">
            <div class="head_split">|</div>
            <div class="collections_btns">
                <button type="button" style="background: red;" (click)="deleteProfiles()">
                    {{ "Delete" | translate }}
                </button>
                <button class="ml-1" type="button" style="background: #67214a;" (click)="multiBackup()" *ngIf="checkLengthWithExId() > 0">
                    {{ "Backup" | translate }}
                </button>
                <button class="ml-1 mr-1" type="button" style="background: #424091;" (click)="multiDuplicatePosts()" *ngIf="checkLengthWithExId() > 0">
                    {{ "Duplicate" | translate }}
                </button>
                <!-- <button class="ml-1" type="button" style="background: #424091;" (click)="changeStatuses()">
                {{ "Change statuses" | translate }}
                </button>
                <button class="ml-1" type="button" style="background: #55ACEE;" (click)="multiRetryPublish()">
                {{ "Retry publish" | translate }}
                </button>
                <button class="ml-1" type="button" style="background: #1f9eff;" (click)="multiRetryPublishToFirst()">
                {{ "Retry publish to first available slot" | translate }}
                </button>
                <button class="no_cst_style ml-1" (click)="multiRefreshViewCounts()" style="color: #27ae60;" mat-icon-button #tooltip="matTooltip" matTooltip="{{ 'Update the number of views for selected posts' | translate }}" [matTooltipPosition]="'above'">
                <mat-icon [ngClass]="{'rotateble': isRefreshed}">refresh</mat-icon>
                </button>
                <button class="ml-1" type="button" style="background: green;" (click)="setMultyAutoUpdate()">
                {{ "Set auto update" | translate }}
                </button> -->
            </div>
        </ng-container>
        <ng-container *ngIf="true">
            <div class="head_split">|</div>
            <ng-container *ngIf="data?.company?.permissions?.includes('admin') || data?.company?.permissions?.includes('owner')">
                <div>{{"Total stream viewers" | translate}} {{viewersCount}}</div>
                <div class="head_split">|</div>
            </ng-container>
            <input id="viewers" name="viewers" class="ve_input reports_white_input" style="max-width: 40px; text-align: right;" type="number" [formControl]="viewersCtrl" matInput placeholder="{{ 'Viewers' | translate }}">
            <label class="viewers_label" for="viewers">{{"Streams" | translate}}</label>
            <div class="cp symbol" (click)="toggleSymbol()">
                <ng-container *ngIf="symbolCtrl.value == '>'; else elseTemplateSymbol">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M6.5,2.27L20,10.14L6.5,18L5.5,16.27L16.03,10.14L5.5,4L6.5,2.27M20,20V22H5V20H20Z" /></svg>
                </ng-container>
                <ng-template #elseTemplateSymbol>
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M18.5,2.27L5,10.14L18.5,18L19.5,16.27L8.97,10.14L19.5,4L18.5,2.27M5,20V22H20V20H5Z" /></svg>
                </ng-template>
            </div>
        </ng-container>
        <div class="table_progress_wrp" [ngClass]="{'hidded': progress >= 100}">
            <mat-progress-bar
                class="table_progress"
                [color]="'primary'"
                [mode]="'buffer'"
                [value]="progress"
                [bufferValue]="bufferProgress">
            </mat-progress-bar>
            <span class="percent">{{progress}}%</span>
        </div>
    </span>
    <div class="autos" [appDeviceClass]="'custom-scroll custom-scroll-t'">

        <!-- <div class="d-f-c" style="justify-content: space-between;">
            <mat-form-field appearance="legacy" class="white_legacy_select" *ngIf="parameters" style="color: #fff;">
                <mat-label>{{ "Tag category" | translate }}</mat-label>
                <mat-select [disableOptionCentering]="true" [multiple]="false" selectDir [(ngModel)]="parameter_id" (selectionChange)="onParamChange($event)">
                  <mat-option>
                    <ngx-mat-select-search 
                        placeholderLabel="{{ 'Search' | translate }}" 
                        noEntriesFoundLabel="{{ 'no matching tag found' | translate }}"
                        [formControl]="parametersCtrl"></ngx-mat-select-search>
                  </mat-option>
                  <mat-option *ngFor="let parameter of parameters$ | async" [value]="parameter.id">
                    {{parameter.name}}
                  </mat-option>
                </mat-select>
            </mat-form-field>

            <div class="d-f-c" style="margin-left: 40px;">
                <mat-form-field appearance="legacy" class="white_legacy_select" *ngIf="checklists" style="color: #fff;">
                    <mat-label>{{ "Check List" | translate }}</mat-label>
                    <mat-select [disableOptionCentering]="true" [multiple]="false" selectDir [(ngModel)]="operation_reminder_id" (selectionChange)="onRemChange($event)">
                      <mat-option>
                        <ngx-mat-select-search 
                            placeholderLabel="{{ 'Search' | translate }}" 
                            noEntriesFoundLabel="{{ 'no matching checklists found' | translate }}"
                            [formControl]="checklistsCtrl"></ngx-mat-select-search>
                      </mat-option>
                      <mat-option *ngFor="let check of checklists$ | async" [value]="check.id">
                        {{check.text}}
                      </mat-option>
                    </mat-select>
                </mat-form-field>
    
                <mat-form-field appearance="legacy" class="white_legacy_select ml-1" *ngIf="parameters" style="color: #fff;">
                    <mat-label>{{ "Inherit tags from categories" | translate }}</mat-label>
                    <mat-select [disableOptionCentering]="true" [multiple]="true" selectDir [(ngModel)]="nestedTags">
                      <mat-option>
                        <ngx-mat-select-search 
                            placeholderLabel="{{ 'Search' | translate }}" 
                            noEntriesFoundLabel="{{ 'no matching tag found' | translate }}"
                            [formControl]="parametersCtrl"></ngx-mat-select-search>
                      </mat-option>
                      <mat-option *ngFor="let parameter of parameters$ | async" [value]="parameter.id" [disabled]="parameter.id == parameter_id" (selectionChange)="onNestedChange($event)">
                        {{parameter.name}}
                      </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div> -->

        <ng-container *ngIf="urls">
            <div class="items">      
                <div class="items_line sort_line">
                    <div class="item server_item">
                        <mat-icon class="sort_arrow" (click)="changeSort(sort_by == 'name_down' && ['name_down', 'name_up'].includes(sort_by) ? 'name_up' : 'name_down')" [ngClass]="{'active': ['name_down', 'name_up'].includes(sort_by)}">{{sort_by == 'name_down' || !['name_down', 'name_up'].includes(sort_by) ? 'arrow_drop_down' : 'arrow_drop_up'}}</mat-icon>
                    </div>
                    <div class="item post_item item_information">
                        <div class="item_top" style="align-items: center; width: 100%;">
                            <div class="view_count">
                                <mat-icon class="sort_arrow" (click)="changeSort(sort_by == 'streams_down' && ['streams_down', 'streams_up'].includes(sort_by) ? 'streams_up' : 'streams_down')" [ngClass]="{'active': ['streams_down', 'streams_up'].includes(sort_by)}">{{sort_by == 'streams_down' || !['streams_down', 'streams_up'].includes(sort_by) ? 'arrow_drop_down' : 'arrow_drop_up'}}</mat-icon>
                            </div>
                            <div class="view_count ml-5">
                                <mat-icon class="sort_arrow" (click)="changeSort(sort_by == 'views_down' && ['views_down', 'views_up'].includes(sort_by) ? 'views_up' : 'views_down')" [ngClass]="{'active': ['views_down', 'views_up'].includes(sort_by)}">{{sort_by == 'views_down' || !['views_down', 'views_up'].includes(sort_by) ? 'arrow_drop_down' : 'arrow_drop_up'}}</mat-icon>
                            </div>
                            <div class="view_count ml-5">
                                <mat-icon class="sort_arrow" (click)="changeSort(sort_by == 'concurrent_views_down' && ['concurrent_views_down', 'concurrent_views_up'].includes(sort_by) ? 'concurrent_views_up' : 'concurrent_views_down')" [ngClass]="{'active': ['concurrent_views_down', 'concurrent_views_up'].includes(sort_by)}">{{sort_by == 'concurrent_views_down' || !['concurrent_views_down', 'concurrent_views_up'].includes(sort_by) ? 'arrow_drop_down' : 'arrow_drop_up'}}</mat-icon>
                            </div>
                            <div class="view_count ml-5">
                                <mat-icon class="sort_arrow" (click)="changeSort(sort_by == 'duration_down' && ['duration_down', 'duration_up'].includes(sort_by) ? 'duration_up' : 'duration_down')" [ngClass]="{'active': ['duration_down', 'duration_up'].includes(sort_by)}">{{sort_by == 'duration_down' || !['duration_down', 'duration_up'].includes(sort_by) ? 'arrow_drop_down' : 'arrow_drop_up'}}</mat-icon>
         
                            </div>
                        </div>
                    </div>
                </div>
                <ng-container *ngFor="let channelData of urlsByChannel$ | async; let i = index">
                    <div class="items_line">
                        <div class="item server_item">
                            <div class="item_top" style="align-items: center;">
                                <app-outlet-line [channel]="channelData.channel" [company_id]="data?.company?.id"></app-outlet-line>
                            </div>
                        </div>
                        <div class="item post_item item_information">
                            <div class="item_top" style="align-items: center; width: 100%;">
                                <div class="view_count">
                                    <div class="d-f-c" *ngIf="!!channelData?.urls">
                                        <mat-icon style="color: #C9C9C9; width: 11px; height: 11px; font-size: 11px; line-height: 11px;">live_tv</mat-icon>
                                        <span style="margin-left: 3px;">{{ channelData?.urls?.length }}</span>
                                    </div>
                                </div>
                                <div class="view_count ml-5">
                                    <div class="d-f-c" *ngIf="!!channelData.content_views_count">
                                        <svg style="width: 9px; height: 10px" width="9" height="10" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0 10V3H2V10H0ZM3.5 10V0H5.5V10H3.5ZM7 10V6H9V10H7Z" fill="#C9C9C9"/>
                                        </svg>
                                        <span style="margin-left: 1px;">{{ channelData.content_views_count | shortNumber }}</span>
                                    </div>
                                </div>
                                <div class="view_count ml-5">
                                    <div class="d-f-c" *ngIf="!!channelData.content_concurrent_views_count">
                                        <mat-icon style="width: 10px; height: 10px; font-size: 10px; line-height: 10px; color: #C9C9C9;">person</mat-icon>
                                        <span style="margin-left: 1px;">{{ channelData.content_concurrent_views_count | shortNumber }}</span>
                                    </div>
                                </div>
                                <div class="view_count ml-5">
                                    <div class="d-f-c chat_timestamp" *ngIf="!!channelData?.content_duration">
                                        <mat-icon style="width: 10px; height: 10px; font-size: 10px; line-height: 10px; color: #C9C9C9; margin-right: 1px;">schedule</mat-icon>
                                        <span class="silver">{{(channelData?.content_duration | fullTime:false:true:true).silverTime}}</span>
                                        <span class="black">{{(channelData?.content_duration | fullTime:false:true:true).blackTime}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ng-container *ngIf="true || !!channelData.expanded">
                            <div class="item chart_item">
                                <span>{{channelData.urls.length}}</span>
                                <div class="shart_creator" *ngFor="let el of channelData.urls; let j = index;"></div>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>            
                <div class="loading" *ngIf="isGettingFiles || isRealGettingFiles">
                    <mat-spinner></mat-spinner>
                </div>
            </div>
        </ng-container>
        
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <div class="bottom_btns">
        <div class="d-f-c">

        </div>
        <div class="d-f-c">


            <button mat-raised-button (click)="close()">
                {{ "Close" | translate }}
            </button>
        </div>
    </div>
</mat-dialog-actions>


<ng-template #postContext let-contextData>
    <section class="card-menu">
      <div (click)="closePostContext(); editProfile(contextData.el.task, contextData.el)">
        <mat-icon>edit</mat-icon> 
        <span>{{ "Edit" | translate }}</span>
      </div>
      <!-- <div (click)="closePostContext(); startLive(contextData.el.task, contextData.el)" *ngIf="!!contextData.el.external_content_id && contextData.el.content_type_id == '106' && ![14,6].includes(contextData.el.content_status_id)">
        <mat-icon>cast</mat-icon> 
        <span>{{ "Start Live" | translate }}</span>
      </div> -->
      <div (click)="closePostContext(); stopLive(contextData.el.task, contextData.el)" *ngIf="contextData.el.content_type_id == '106' && [14,6].includes(contextData.el.content_status_id)" #tooltip="matTooltip"
      matTooltip="{{ '4' | translate }}"
      [matTooltipPosition]="'above'">
        <mat-icon>cancel_presentation</mat-icon> 
        <span>{{ "Stop stream" | translate }}</span>
      </div>
      <div (click)="closePostContext(); deleteProfile(contextData.el.task, contextData.el, contextData.j, contextData.channelData)">
        <mat-icon>delete</mat-icon>
        <span>{{ 'Delete' | translate }}</span>
      </div>
    </section>
</ng-template>

<ng-template #postBckpContext let-contextData>
    <section class="bckp-menu">

        <ng-container *ngIf="contextData.type == 'backup'; else elseTemplateBckpOriginal">
            <!--  (contextmenu)="openPostContext($event, el, i, j, server); $event.preventDefault(); $event.stopPropagation();" -->
            <div class="item post_item" [ngClass]="{'cp': !!contextData?.el?.content_url}" (click)="goToPost($event, contextData?.el)">
                <div class="item_top">
                    <div class="item_photo">
                        <img *ngIf="contextData?.el?.content_image" [src]="contextData?.el?.content_image" alt="">
                    </div>
                    <div class="item_name text_3_line">
                        {{contextData?.el?.content_name}}
                    </div>
                </div>
                <div class="item_bottom">
                    <div class="d-f-c">
                        <div class="status">
                            {{ (getProfileStatusById(contextData?.el.content_status_id) ? (getProfileStatusById(contextData?.el.content_status_id)?.name+'_profile') : 'Content status') | translate }}
                        </div>
                        <ng-container *ngIf="contextData?.el?.content_published_at > today && contextData?.el?.content_published_at < todayEnd; else publishedAtTempl">
                            <span class="date" #tooltip="matTooltip"
                              matTooltip="{{ contextData?.el?.content_published_at*1000 | date:'MMM d, y':'':data.activeLang | yearPipe }}"
                              [matTooltipPosition]="'above'">
                              {{ contextData?.el?.content_published_at*1000 | date:'hh:mm a':'':data.activeLang }}
                            </span>
                        </ng-container>
                        <ng-template #publishedAtTempl>
                            <span class="date" *ngIf="contextData?.el?.content_published_at && contextData?.el?.content_published_at > 30000" #tooltip="matTooltip"
                              matTooltip="{{ contextData?.el?.content_published_at*1000 | date:'hh:mm a':'':data.activeLang | yearPipe }}"
                              [matTooltipPosition]="'above'">
                              {{ contextData?.el?.content_published_at*1000 | date:'MM.dd.yy':'': data.activeLang }}
                            </span>
                        </ng-template>
                    </div>
    
                    <div class="d-f-c">
                        <div class="id cp" (click)="$event.preventDefault(); $event.stopPropagation();" ngxClipboard [cbContent]="contextData?.el.id" (cbOnSuccess)="copyLiveData('id')">
                            {{contextData?.el.id}}
                        </div>
                        <div class="view_count cp ml-5" *ngIf="!!contextData?.el.content_views_count" (click)="$event.preventDefault(); $event.stopPropagation(); showStats(contextData?.el)">
                            <div class="d-f-c">
                                <svg style="width: 9px; height: 10px" width="9" height="10" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 10V3H2V10H0ZM3.5 10V0H5.5V10H3.5ZM7 10V6H9V10H7Z" fill="#C9C9C9"/>
                                </svg>
                                <span style="margin-left: 1px;">{{ contextData?.el.content_views_count | shortNumber }}</span>
                            </div>
                        </div>
                        <div class="view_count ml-5" *ngIf="!!contextData?.el.content_concurrent_views_count">
                            <div class="d-f-c">
                                <mat-icon style="width: 10px; height: 10px; font-size: 10px; line-height: 10px; color: #C9C9C9;">person</mat-icon>
                                <span style="margin-left: 1px;">{{ contextData?.el.content_concurrent_views_count | shortNumber }}</span>
                            </div>
                        </div>
                        <div class="view_count ml-5" *ngIf="!!contextData?.el?.content_duration">
                            <div class="d-f-c chat_timestamp">
                                <mat-icon style="width: 10px; height: 10px; font-size: 10px; line-height: 10px; color: #C9C9C9; margin-right: 1px;">schedule</mat-icon>
                                <span class="silver">{{(contextData?.el?.content_duration | fullTime:false:true:true).silverTime}}</span>
                                <span class="black">{{(contextData?.el?.content_duration | fullTime:false:true:true).blackTime}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-f-c" style="justify-content: space-between;">
                    <app-outlet-line [channel]="contextData?.el.channel" [company_id]="data?.company?.id"></app-outlet-line>
                </div>
                <div style="font-size: 8px;" *ngIf="!!data?.user?.is_root">{{contextData?.el?.server_id}}</div>
            </div>
        </ng-container>
        <ng-template #elseTemplateBckpOriginal>      
            <div class="item post_item" *ngFor="let el of contextData.el.backups; let j = index;" [ngClass]="{'cp': !!el.content_url}" (click)="goToPost($event, el)">
                <div class="item_top">
                    <div class="item_photo">
                        <img *ngIf="el?.content_image" [src]="el?.content_image" alt="">
                    </div>
                    <div class="item_name text_3_line">
                        {{el?.content_name}}
                    </div>
                </div>
                <div class="item_bottom">
                    <div class="d-f-c">
                        <div class="status">
                            {{ (getProfileStatusById(el.content_status_id) ? (getProfileStatusById(el.content_status_id)?.name+'_profile') : 'Content status') | translate }}
                        </div>
                        <ng-container *ngIf="el?.content_published_at > today && el?.content_published_at < todayEnd; else publishedAtTempl">
                            <span class="date" #tooltip="matTooltip"
                              matTooltip="{{ el?.content_published_at*1000 | date:'MMM d, y':'':data.activeLang | yearPipe }}"
                              [matTooltipPosition]="'above'">
                              {{ el?.content_published_at*1000 | date:'hh:mm a':'':data.activeLang }}
                            </span>
                        </ng-container>
                        <ng-template #publishedAtTempl>
                            <span class="date" *ngIf="el?.content_published_at && el?.content_published_at > 30000" #tooltip="matTooltip"
                              matTooltip="{{ el?.content_published_at*1000 | date:'hh:mm a':'':data.activeLang | yearPipe }}"
                              [matTooltipPosition]="'above'">
                              {{ el?.content_published_at*1000 | date:'MM.dd.yy':'': data.activeLang }}
                            </span>
                        </ng-template>
                    </div>
    
                    <div class="d-f-c">
                        <div class="id cp" (click)="$event.preventDefault(); $event.stopPropagation();" ngxClipboard [cbContent]="el.id" (cbOnSuccess)="copyLiveData('id')">
                            {{el.id}}
                        </div>
                        <div class="view_count cp ml-5" *ngIf="!!el.content_views_count" (click)="$event.preventDefault(); $event.stopPropagation(); showStats(el)">
                            <div class="d-f-c">
                                <svg style="width: 9px; height: 10px" width="9" height="10" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 10V3H2V10H0ZM3.5 10V0H5.5V10H3.5ZM7 10V6H9V10H7Z" fill="#C9C9C9"/>
                                </svg>
                                <span style="margin-left: 1px;">{{ el.content_views_count | shortNumber }}</span>
                            </div>
                        </div>
                        <div class="view_count ml-5" *ngIf="!!el.content_concurrent_views_count">
                            <div class="d-f-c">
                                <mat-icon style="width: 10px; height: 10px; font-size: 10px; line-height: 10px; color: #C9C9C9;">person</mat-icon>
                                <span style="margin-left: 1px;">{{ el.content_concurrent_views_count | shortNumber }}</span>
                            </div>
                        </div>
                        <div class="view_count ml-5" *ngIf="!!el?.content_duration">
                            <div class="d-f-c chat_timestamp">
                                <mat-icon style="width: 10px; height: 10px; font-size: 10px; line-height: 10px; color: #C9C9C9; margin-right: 1px;">schedule</mat-icon>
                                <span class="silver">{{(el?.content_duration | fullTime:false:true:true).silverTime}}</span>
                                <span class="black">{{(el?.content_duration | fullTime:false:true:true).blackTime}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-f-c" style="justify-content: space-between;">
                    <app-outlet-line [channel]="el.channel" [company_id]="data?.company?.id"></app-outlet-line>
                </div>
                <div style="font-size: 8px;" *ngIf="!!data?.user?.is_root">{{el?.server_id}}</div>
            </div>
        </ng-template>
        
    </section>
</ng-template>