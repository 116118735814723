
<section class="lite_executors" [ngClass]="{'use_out': useOut, 'centred': target?.outputExecutors?.is_load}" [appDeviceClass]="'custom-scroll custom-scroll-t'">
    <ng-container *ngIf="target?.outputExecutors?.is_load; else elseTemplateLoadedDone">
        <mat-spinner class="loader"></mat-spinner>
    </ng-container>
    <ng-template #elseTemplateLoadedDone>
        <ng-container *ngIf="selectedExec; else elseTemplateselectedExec">
            <div class="selected_out">
                <div class="selected_head">
                    <div class="icon_back" (click)="deactiveUser()">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7 12L6.11612 11.1161C5.62796 11.6043 5.62796 12.3957 6.11612 12.8839L7 12ZM16.8839 3.88388C17.372 3.39573 17.372 2.60427 16.8839 2.11612C16.3957 1.62796 15.6043 1.62796 15.1161 2.11612L16.8839 3.88388ZM15.1161 21.8839C15.6043 22.372 16.3957 22.372 16.8839 21.8839C17.372 21.3957 17.372 20.6043 16.8839 20.1161L15.1161 21.8839ZM7.88388 12.8839L16.8839 3.88388L15.1161 2.11612L6.11612 11.1161L7.88388 12.8839ZM6.11612 12.8839L15.1161 21.8839L16.8839 20.1161L7.88388 11.1161L6.11612 12.8839Z" fill="#232323"/>
                        </svg>
                    </div>
                    <ng-container *ngIf="selectedExec?.exec?.partnerCompany; else elseTemplateIsPartner">
                        <app-user [class]="'executor black'" [lines]="1" [status_id]="selectedExec?.exec?.partnerCompanyStatus?.status_id" [companyEmployee]="selectedExec?.exec?.partnerCompany" [imgRoute]="imgRoute" [host]="host" [company]="company"></app-user>
                    </ng-container>
                    <ng-template #elseTemplateIsPartner>
                        <app-user [class]="'executor black'" [lines]="1" [status_id]="selectedExec?.exec?.employeeStatus?.status_id" [employee]="selectedExec?.exec?.employee"></app-user>
                    </ng-template>
                </div>
    
                <div class="selected_body">
                    <ng-container *ngIf="['client user', 'client workspace'].includes(selectedExec?.type); else elseTemplateIsCli">
                        <div class="open_exec_block silver">
                            <div class="title">
                                {{ "Client Permission" | translate }}:
                            </div>
                            <div class="info">
                                <div>{{ "Moderator" | translate }}</div>
                                <mat-checkbox [labelPosition]="'before'" [disabled]="selectedExec.exec.disabled" class="ml-1 mr-1" color="primary" [(ngModel)]="selectedExec.exec.is_principal" (change)="togglePartner($event, selectedExec.exec)">
                                </mat-checkbox>
                            </div>
                        </div>
                    </ng-container>
                    <ng-template #elseTemplateIsCli>
                        <div class="open_exec_block with_bord white">
                            <div class="d-f-c">
                                <div class="date_name">
                                    {{"Added" | translate}}:
                                </div>
                                <div class="create_date">
                                    <ng-container *ngIf="(selectedExec?.exec?.employeeStatus ? selectedExec?.exec?.employeeStatus : selectedExec?.exec?.partnerCompanyStatus)?.created_at; else elseTemplateComplete">
                                        {{ (selectedExec?.exec?.employeeStatus ? selectedExec?.exec?.employeeStatus : selectedExec?.exec?.partnerCompanyStatus)?.created_at*1000 | date:'MMM dd. yyyy':'+0000': activeLang | yearPipe }}
                                    </ng-container>
                                    <ng-template #elseTemplateComplete>
                                        &nbsp;
                                    </ng-template>
                                </div>
                            </div>
                            <div class="d-f-c">
                                <div class="date_name">
                                    {{"Updated" | translate}}:
                                </div>
                                <div class="create_date">
                                    <ng-container *ngIf="(selectedExec?.exec?.employeeStatus ? selectedExec?.exec?.employeeStatus : selectedExec?.exec?.partnerCompanyStatus)?.updated_at; else elseTemplateComplete">
                                        {{ (selectedExec?.exec?.employeeStatus ? selectedExec?.exec?.employeeStatus : selectedExec?.exec?.partnerCompanyStatus)?.updated_at*1000 | date:'MMM dd. yyyy':'+0000': activeLang | yearPipe }}
                                    </ng-container>
                                    <ng-template #elseTemplateComplete>
                                        &nbsp;
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                        <div class="open_exec_block with_bord white">
                            <div class="d-f-c" style="justify-content: space-between;">
                                <div class="cp" (click)="changeType(selectedExec.exec)">
                                    <div class="title">{{"Role" | translate}}:</div>
                                    <div style="height: 20px;">
                                        <ng-container *ngIf="!!selectedExec?.exec?.typeIsChanging; else elseTemplateTypeChanging">
                                            <mat-spinner class="spin_16"></mat-spinner>  
                                        </ng-container>
                                        <ng-template #elseTemplateTypeChanging>
                                            <ng-container *ngIf="selectedExec.exec.is_manager > 0; else elseTemplateIsManager">
                                                {{ "Executor" | translate }}
                                            </ng-container>
                                            <ng-template #elseTemplateIsManager>
                                                {{ "Assistant" | translate }}
                                            </ng-template>
                                        </ng-template>
                                        
                                    </div>
                                </div>
                                <div>
                                    <div class="title">&nbsp;</div>
                                    <div>
                                        <div class="price_val_out cp" (click)="changePriceManager(selectedExec.exec)">
                                            <ng-container *ngIf="!!selectedExec?.exec?.pricePermIsChanging; else elseTemplatePriceChanging">
                                                <mat-spinner class="spin_16"></mat-spinner>  
                                            </ng-container>
                                            <ng-template #elseTemplatePriceChanging>
                                                <div class="price_val_in" [ngClass]="{'green': selectedExec.exec.is_price_manager > 0}">$</div>
                                                <span [ngClass]="{'cant': selectedExec.exec.is_price_manager == 0}">
                                                    {{"See prices" | translate}}                                               
                                                </span>
                                            </ng-template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="open_exec_block silver cp" (click)="changeCompletedAt(selectedExec?.exec)">
                            <div class="title">
                                {{ "When done" | translate }}:
                            </div>
                            <div class="info">
                                <ng-container *ngIf="selectedExec?.exec?.completed_at; else elseTemplateNoComplDate">
                                    {{ selectedExec?.exec?.completed_at*1000 | date:'MMM dd, yy':'': activeLang | yearPipe }}
                                </ng-container>
                                <ng-template #elseTemplateNoComplDate>
                                    &nbsp;
                                </ng-template>
                            </div>
                        </div>
    
                        <div class="open_exec_block silver cp" (click)="changePrice(selectedExec?.exec)">
                            <div class="title">
                                {{ "Charges for assignment" | translate }}:
                            </div>
                            <div class="info">
                                <ng-container *ngIf="+selectedExec?.exec.rate > 0; else elseTemplatePrice">
                                    <div class="exec_price">
                                        <span class="unit">
                                            {{ "pts" }}
                                        </span>
                                        <span class="price_numb">
                                            {{ selectedExec?.exec.rate | int }} {{ ((+selectedExec?.exec?.rate - +(selectedExec?.exec?.rate | int)) | reportsNumber).toString()|slice:1 }}
                                        </span>
                                    </div>
                                </ng-container>
                                <ng-template #elseTemplatePrice>
                                    <ng-container *ngIf="+selectedExec?.exec?.price; else elseTemplateCurr">
                                        <div class="exec_price">
                                            <span class="unit">
                                                <ng-container *ngIf="selectedExec?.exec?.currency?.short_name; else elseTemplateHasCur">
                                                    {{selectedExec?.exec?.currency?.short_name}}
                                                </ng-container>
                                                <ng-template #elseTemplateHasCur>     
                                                    <ng-container [ngSwitch]="selectedExec?.exec?.price_currency_id">
                                                        <ng-container *ngSwitchCase="'1'">&#36;</ng-container>
                                                        <ng-container *ngSwitchCase="'2'">&#8381;</ng-container>
                                                        <ng-container *ngSwitchCase="'3'">&#8364;</ng-container>
                                                        <ng-container *ngSwitchCase="'4'">&#8372;</ng-container>
                                                    </ng-container>
                                                </ng-template>  
                                            </span>
                                            <span class="price_numb">
                                                {{ selectedExec?.exec?.price | int }} {{ ((+selectedExec?.exec?.price - +(selectedExec?.exec?.price | int)) | reportsNumber).toString()|slice:1 }}
                                            </span>
                                        </div>
                                    </ng-container>
                                    <ng-template #elseTemplateCurr>
                                        &nbsp;
                                    </ng-template>
                                </ng-template>
                            </div>
                        </div>
                        <div class="open_exec_block silver">
                            <div class="title">
                                {{ "Status" | translate }}:
                            </div>
                            <div class="info">
                                <!-- (dblclick)="jobEmpl.discussion_id == 0 || !jobEmpl.discussion_id ? dblChangeStatus($event, jobEmpl) : dblChangeChatAssStatus($event, jobEmpl, getChatById(jobEmpl.discussion_id))" -->
                                <div class="job_status">
                                    <div class="inside_job" [ngClass]="{
                                        'first': (selectedExec?.exec?.employeeStatus ? selectedExec?.exec?.employeeStatus : selectedExec?.exec?.partnerCompanyStatus)?.status_id == '1',
                                        'second': (selectedExec?.exec?.employeeStatus ? selectedExec?.exec?.employeeStatus : selectedExec?.exec?.partnerCompanyStatus)?.status_id == '2',
                                        'third': (selectedExec?.exec?.employeeStatus ? selectedExec?.exec?.employeeStatus : selectedExec?.exec?.partnerCompanyStatus)?.status_id == '3',
                                        'fours': (selectedExec?.exec?.employeeStatus ? selectedExec?.exec?.employeeStatus : selectedExec?.exec?.partnerCompanyStatus)?.status_id == '4',
                                        'fives': (selectedExec?.exec?.employeeStatus ? selectedExec?.exec?.employeeStatus : selectedExec?.exec?.partnerCompanyStatus)?.status_id == '5',
                                        'sixs': (selectedExec?.exec?.employeeStatus ? selectedExec?.exec?.employeeStatus : selectedExec?.exec?.partnerCompanyStatus)?.status_id == '6',
                                        'nine': (selectedExec?.exec?.employeeStatus ? selectedExec?.exec?.employeeStatus : selectedExec?.exec?.partnerCompanyStatus)?.status_id == '97',
                                        'seven': (selectedExec?.exec?.employeeStatus ? selectedExec?.exec?.employeeStatus : selectedExec?.exec?.partnerCompanyStatus)?.status_id == '98',
                                        'eight': (selectedExec?.exec?.employeeStatus ? selectedExec?.exec?.employeeStatus : selectedExec?.exec?.partnerCompanyStatus)?.status_id == '99'}">
                                        <span>{{ getStatus((selectedExec?.exec?.employeeStatus ? selectedExec?.exec?.employeeStatus : selectedExec?.exec?.partnerCompanyStatus)?.status_id)?.name }}</span>
                                    </div>
    
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </div>
    
                <div class="selected_chats" *ngIf="selectedExec?.exec?.chats && selectedExec?.exec?.chats.length">
                    <div class="selected_head" style="height: 32px; margin-top: 20px;">{{"Statuses in chats" | translate}}:</div>
                    <div class="selected_body">
                        <div class="chat" *ngFor="let chat of selectedExec?.exec?.chats">
                            <div class="chat_name" (click)="goToChatStatus(chat)">
             
                                {{chat.name}}
                                
                                <!-- <button class="start_at_btn ml-1" *ngIf="!chat?.employeeStatus?.start_at" (click)="$event.stopPropagation(); setStartAt(chat, jobEmpl)" mat-icon-button>
                                    <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.6667 2.00002H10V0.666687H8.66667V2.00002H3.33333V0.666687H2V2.00002H1.33333C0.593333 2.00002 0.00666666 2.60002 0.00666666 3.33335L0 12.6667C0 13.4 0.593333 14 1.33333 14H10.6667C11.4 14 12 13.4 12 12.6667V3.33335C12 2.60002 11.4 2.00002 10.6667 2.00002ZM10.6667 12.6667H1.33333V5.33335H10.6667V12.6667ZM2.66667 6.66669H6V10H2.66667V6.66669Z" fill="#686868"/>
                                    </svg>  
                                </button>
                                <button class="start_at_btn ml-1" *ngIf="!chat?.employeeStatus?.start_at" (click)="setToOrderBoard(chat, jobEmpl)" mat-icon-button>
                                    <mat-icon>dashboard</mat-icon>
                                </button> -->
                            </div>
                            <div class="d-f-c chat_statuses_wrp">
                                <div class="chat_status">
                                    <div class="inside_job" [ngClass]="{
                                        'first': (chat?.employeeStatus ? chat?.employeeStatus : chat?.partnerCompanyStatus)?.status_id == '1',
                                        'second': (chat?.employeeStatus ? chat?.employeeStatus : chat?.partnerCompanyStatus)?.status_id == '2',
                                        'third': (chat?.employeeStatus ? chat?.employeeStatus : chat?.partnerCompanyStatus)?.status_id == '3',
                                        'fours': (chat?.employeeStatus ? chat?.employeeStatus : chat?.partnerCompanyStatus)?.status_id == '4',
                                        'fives': (chat?.employeeStatus ? chat?.employeeStatus : chat?.partnerCompanyStatus)?.status_id == '5',
                                        'sixs': (chat?.employeeStatus ? chat?.employeeStatus : chat?.partnerCompanyStatus)?.status_id == '6',
                                        'nine': (chat?.employeeStatus ? chat?.employeeStatus : chat?.partnerCompanyStatus)?.status_id == '97',
                                        'seven': (chat?.employeeStatus ? chat?.employeeStatus : chat?.partnerCompanyStatus)?.status_id == '98',
                                        'eight': (chat?.employeeStatus ? chat?.employeeStatus : chat?.partnerCompanyStatus)?.status_id == '99'}">
                                        <span>{{ getStatus((chat?.employeeStatus ? chat?.employeeStatus : chat?.partnerCompanyStatus)?.status_id)?.name }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-template #elseTemplateselectedExec>            
            <div class="white_exec">
                <ng-container *ngIf="target.outputExecutors.employees && jobHasUserExec()">
                    <div class="white_title">
                        <div class="exec_title">
                            {{"Executors users"|translate}}
                        </div>

                        <mat-icon (click)="addExecutor()">add_circle</mat-icon>
                    </div>
                    <div class="executors">
                        <ng-container *ngFor="let jobEmpl of target.outputExecutors.employees; let i = index;">
                            <div class="exec" *ngIf="!jobEmpl.is_partner && !jobEmpl.is_partner_employee && (jobEmpl.discussion_id == 0 || !jobEmpl.discussion_id)">
                                <app-user (click)="activeUser(jobEmpl, 'executor user', i)" [class]="'cp executor black'" [lines]="2" [status_id]="jobEmpl?.employeeStatus?.status_id" [employee]="jobEmpl?.employee"></app-user>
        
                                <div class="d-f-c">
                                    <div class="exec_price_out">
                                        <ng-container *ngIf="+jobEmpl.rate > 0; else elseTemplatePrice">
                                            <div class="exec_price">
                                                <span class="unit">
                                                    {{ "pts" }}
                                                </span>
                                                <span class="price_numb">
                                                    {{ jobEmpl.rate | int }} {{ ((+jobEmpl?.rate - +(jobEmpl?.rate | int)) | reportsNumber).toString()|slice:1 }}
                                                </span>
                                            </div>
                                        </ng-container>
                                        <ng-template #elseTemplatePrice>
                                            <ng-container *ngIf="+jobEmpl?.price; else elseTemplateCurr">
                                                <div class="exec_price">
                                                    <span class="unit">
                                                        {{ jobEmpl?.currency?.short_name }}
                                                    </span>
                                                    <span class="price_numb">
                                                        {{ jobEmpl?.price | int }} {{ ((+jobEmpl?.price - +(jobEmpl?.price | int)) | reportsNumber).toString()|slice:1 }}
                                                    </span>
                                                </div>
                                            </ng-container>
                                            <ng-template #elseTemplateCurr>
                                                &nbsp;
                                            </ng-template>
                                        </ng-template>
                                    </div>
                                    <div class="date_out">
                                        <div class="date">
                                            <ng-container *ngIf="jobEmpl?.completed_at; else elseTemplateNoComplDate">
                                                {{ jobEmpl?.completed_at*1000 | date:'MMM dd, yy':'': activeLang | yearPipe }}
                                            </ng-container>
                                            <ng-template #elseTemplateNoComplDate>
                                                <b>{{"When done?" | translate}}</b>
                                            </ng-template>
                                        </div>
                                    </div>
                                    <div class="type_out">
                                        <div class="exec_type">
                                            <span class="cp" (click)="changeType(jobEmpl)">
                                                <ng-container *ngIf="!!jobEmpl?.typeIsChanging; else elseTemplateTypeChang">
                                                    <mat-spinner class="spin_16"></mat-spinner>  
                                                </ng-container>
                                                <ng-template #elseTemplateTypeChang>
                                                    <ng-container *ngIf="jobEmpl.is_manager > 0; else elseTemplateIsManager">
                                                        {{ "Exec" | translate }}
                                                    </ng-container>
                                                    <ng-template #elseTemplateIsManager>
                                                        {{ "Assist" | translate }}
                                                    </ng-template>
                                                </ng-template>
                                            </span>
        
                                            <div class="price_val cp" (click)="changePriceManager(jobEmpl)" [ngClass]="{'green': jobEmpl.is_price_manager > 0}">
                                                <ng-container *ngIf="!!jobEmpl?.pricePermIsChanging; else elseTemplatePriceChang">
                                                    <mat-spinner class="spin_16"></mat-spinner>  
                                                </ng-container>
                                                <ng-template #elseTemplatePriceChang>
                                                    $
                                                </ng-template>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <!-- <mat-icon class="mob_delete_executor" *ngIf="!!jobEmpl.activate" (click)="deleteEmployee(jobEmpl, i)">close</mat-icon> -->
                            </div>
                        </ng-container>
                    </div>
                </ng-container>
                
                <ng-container *ngIf="jobHasChatExec() || jobHasChatPartn()">
                    <div class="white_title">
                        <div class="exec_title">
                            {{"Chat executors"|translate}}
                        </div>
                    </div>
                    <div class="executors">
                        <ng-container *ngFor="let jobEmpl of target.outputExecutors.employees; let i = index;">
                            <div class="exec" *ngIf="!jobEmpl.is_partner && !jobEmpl.is_partner_employee && !(jobEmpl.discussion_id == 0 || !jobEmpl.discussion_id)">
                                <app-user (click)="activeUser(jobEmpl, 'chat user', i)" [class]="'cp executor black'" [lines]="2" [status_id]="jobEmpl?.employeeStatus?.status_id" [employee]="jobEmpl?.employee"></app-user>
        
                                <div class="d-f-c">
                                    <div class="exec_price_out">
                                        <ng-container *ngIf="+jobEmpl.rate > 0; else elseTemplatePrice">
                                            <div class="exec_price">
                                                <span class="unit">
                                                    {{ "pts" }}
                                                </span>
                                                <span class="price_numb">
                                                    {{ jobEmpl.rate | int }} {{ ((+jobEmpl?.rate - +(jobEmpl?.rate | int)) | reportsNumber).toString()|slice:1 }}
                                                </span>
                                            </div>
                                        </ng-container>
                                        <ng-template #elseTemplatePrice>
                                            <ng-container *ngIf="+jobEmpl?.price; else elseTemplateCurr">
                                                <div class="exec_price">
                                                    <span class="unit">
                                                        {{ jobEmpl?.currency?.short_name }}
                                                    </span>
                                                    <span class="price_numb">
                                                        {{ jobEmpl?.price | int }} {{ ((+jobEmpl?.price - +(jobEmpl?.price | int)) | reportsNumber).toString()|slice:1 }}
                                                    </span>
                                                </div>
                                            </ng-container>
                                            <ng-template #elseTemplateCurr>
                                                &nbsp;
                                            </ng-template>
                                        </ng-template>
                                    </div>
                                    <div class="date_out">
                                        <div class="date">
                                            <ng-container *ngIf="jobEmpl?.completed_at; else elseTemplateNoComplDate">
                                                {{ jobEmpl?.completed_at*1000 | date:'MMM dd, yy':'': activeLang | yearPipe }}
                                            </ng-container>
                                            <ng-template #elseTemplateNoComplDate>
                                                <b>{{"When done?" | translate}}</b>
                                            </ng-template>
                                        </div>
                                    </div>
                                    <div class="type_out">
                                        <div class="exec_type">
                                            <span class="cp" (click)="changeType(jobEmpl)">
                                                <ng-container *ngIf="!!jobEmpl?.typeIsChanging; else elseTemplateTypeChang">
                                                    <mat-spinner class="spin_16"></mat-spinner>  
                                                </ng-container>
                                                <ng-template #elseTemplateTypeChang>
                                                    <ng-container *ngIf="jobEmpl.is_manager > 0; else elseTemplateIsManager">
                                                        {{ "Exec" | translate }}
                                                    </ng-container>
                                                    <ng-template #elseTemplateIsManager>
                                                        {{ "Assist" | translate }}
                                                    </ng-template>
                                                </ng-template>
                                            </span>
        
                                            <div class="price_val cp" (click)="changePriceManager(jobEmpl)" [ngClass]="{'green': jobEmpl.is_price_manager > 0}">
                                                <ng-container *ngIf="!!jobEmpl?.pricePermIsChanging; else elseTemplatePriceChang">
                                                    <mat-spinner class="spin_16"></mat-spinner>  
                                                </ng-container>
                                                <ng-template #elseTemplatePriceChang>
                                                    $
                                                </ng-template>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <mat-icon class="mob_delete_executor" *ngIf="!!jobEmpl.activate" (click)="deleteEmployee(jobEmpl, i)">close</mat-icon> -->
                            </div>
                        </ng-container>
                        <ng-container *ngFor="let partner of target.outputExecutors.partnerCompanies; let i = index;">
                            <div class="exec" *ngIf="!(partner.discussion_id == 0 || !partner.discussion_id)">
                                <app-user (click)="activeUser(partner, 'chat workspace', i)" [class]="'cp executor black'" [lines]="2" [status_id]="partner?.partnerCompanyStatus?.status_id" [companyEmployee]="partner?.partnerCompany" [imgRoute]="imgRoute" [host]="host" [company]="company"></app-user>
                            
                                <div class="d-f-c">
                                    <div class="exec_price_out">
                                        <ng-container *ngIf="+partner.rate > 0; else elseTemplatePrice">
                                            <div class="exec_price">
                                                <span class="unit">
                                                    {{ "pts" }}
                                                </span>
                                                <span class="price_numb">
                                                    {{ partner.rate | int }} {{ ((+partner?.rate - +(partner?.rate | int)) | reportsNumber).toString()|slice:1 }}
                                                </span>
                                            </div>
                                        </ng-container>
                                        <ng-template #elseTemplatePrice>
                                            <ng-container *ngIf="+partner?.price; else elseTemplateCurr">
                                                <div class="exec_price">
                                                    <span class="unit">
                                                        {{ partner?.currency?.short_name }}
                                                    </span>
                                                    <span class="price_numb">
                                                        {{ partner?.price | int }} {{ ((+partner?.price - +(partner?.price | int)) | reportsNumber).toString()|slice:1 }}
                                                    </span>
                                                </div>
                                            </ng-container>
                                            <ng-template #elseTemplateCurr>
                                                &nbsp;
                                            </ng-template>
                                        </ng-template>
                                    </div>
                                    <div class="date_out">
                                        <div class="date">
                                            <ng-container *ngIf="partner?.completed_at; else elseTemplateNoComplDate">
                                                {{ partner?.completed_at*1000 | date:'MMM dd, yy':'': activeLang | yearPipe }}
                                            </ng-container>
                                            <ng-template #elseTemplateNoComplDate>
                                                <b>{{"When done?" | translate}}</b>
                                            </ng-template>
                                        </div>
                                    </div>
                                    <div class="type_out">
                                        <div class="exec_type">
                                            <span class="cp" (click)="changeType(partner)">
                                                <ng-container *ngIf="!!partner?.typeIsChanging; else elseTemplateTypeChang">
                                                    <mat-spinner class="spin_16"></mat-spinner>  
                                                </ng-container>
                                                <ng-template #elseTemplateTypeChang>
                                                    <ng-container *ngIf="partner.is_manager > 0; else elseTemplateIsManager">
                                                        {{ "Exec" | translate }}
                                                    </ng-container>
                                                    <ng-template #elseTemplateIsManager>
                                                        {{ "Assist" | translate }}
                                                    </ng-template>
                                                </ng-template>
                                            </span>
        
                                            <div class="price_val cp" (click)="changePriceManager(partner)" [ngClass]="{'green': partner.is_price_manager > 0}">
                                                <ng-container *ngIf="!!partner?.pricePermIsChanging; else elseTemplatePriceChang">
                                                    <mat-spinner class="spin_16"></mat-spinner>  
                                                </ng-container>
                                                <ng-template #elseTemplatePriceChang>
                                                    $
                                                </ng-template>
                                            </div>
                                        </div>
                                    </div>
              
                                </div>
                                <!-- <mat-icon class="mob_delete_executor" *ngIf="!!partner.activate" (click)="deletePartner(partner, i)">close</mat-icon> -->
                            </div>
                        </ng-container>
                    </div>
                </ng-container>
    
                <ng-container *ngIf="!!target.outputExecutors.partnerCompanies.length && jobHasNotChatPartn()">
                    <div class="white_title">
                        <div class="exec_title">
                            {{"Executors workspaces"|translate}}
                        </div>
                        <mat-icon (click)="addExecutor(true)">add_circle</mat-icon>
                    </div>
                    <div class="executors">
                        <ng-container *ngFor="let partner of target.outputExecutors.partnerCompanies; let i = index;">
                            <div class="exec" *ngIf="partner.discussion_id == 0 || !partner.discussion_id">
                                <app-user (click)="activeUser(partner, 'executor workspace', i)" [class]="'cp executor black'" [lines]="2" [status_id]="partner?.partnerCompanyStatus?.status_id" [companyEmployee]="partner?.partnerCompany" [imgRoute]="imgRoute" [host]="host" [company]="company"></app-user>
                            
                                <div class="d-f-c">
                                    <div class="exec_price_out">
                                        <ng-container *ngIf="+partner.rate > 0; else elseTemplatePrice">
                                            <div class="exec_price">
                                                <span class="unit">
                                                    {{ "pts" }}
                                                </span>
                                                <span class="price_numb">
                                                    {{ partner.rate | int }} {{ ((+partner?.rate - +(partner?.rate | int)) | reportsNumber).toString()|slice:1 }}
                                                </span>
                                            </div>
                                        </ng-container>
                                        <ng-template #elseTemplatePrice>
                                            <ng-container *ngIf="+partner?.price; else elseTemplateCurr">
                                                <div class="exec_price">
                                                    <span class="unit">
                                                        {{ partner?.currency?.short_name }}
                                                    </span>
                                                    <span class="price_numb">
                                                        {{ partner?.price | int }} {{ ((+partner?.price - +(partner?.price | int)) | reportsNumber).toString()|slice:1 }}
                                                    </span>
                                                </div>
                                            </ng-container>
                                            <ng-template #elseTemplateCurr>
                                                &nbsp;
                                            </ng-template>
                                        </ng-template>
                                    </div>
                                    <div class="date_out">
                                        <div class="date">
                                            <ng-container *ngIf="partner?.completed_at; else elseTemplateNoComplDate">
                                                {{ partner?.completed_at*1000 | date:'MMM dd, yy':'': activeLang | yearPipe }}
                                            </ng-container>
                                            <ng-template #elseTemplateNoComplDate>
                                                <b>{{"When done?" | translate}}</b>
                                            </ng-template>
                                        </div>
                                    </div>
                                    <div class="type_out">
                                        <div class="exec_type">
                                            <span class="cp" (click)="changeType(partner)">
                                                <ng-container *ngIf="!!partner?.typeIsChanging; else elseTemplateTypeChang">
                                                    <mat-spinner class="spin_16"></mat-spinner>  
                                                </ng-container>
                                                <ng-template #elseTemplateTypeChang>
                                                    <ng-container *ngIf="partner.is_manager > 0; else elseTemplateIsManager">
                                                        {{ "Exec" | translate }}
                                                    </ng-container>
                                                    <ng-template #elseTemplateIsManager>
                                                        {{ "Assist" | translate }}
                                                    </ng-template>
                                                </ng-template>
                                            </span>
        
                                            <div class="price_val cp" (click)="changePriceManager(partner)" [ngClass]="{'green': partner.is_price_manager > 0}">
                                                <ng-container *ngIf="!!partner?.pricePermIsChanging; else elseTemplatePriceChang">
                                                    <mat-spinner class="spin_16"></mat-spinner>  
                                                </ng-container>
                                                <ng-template #elseTemplatePriceChang>
                                                    $
                                                </ng-template>
                                            </div>
                                        </div>
                                    </div>
              
                                </div>
                                <!-- <mat-icon class="mob_delete_executor" *ngIf="!!partner.activate" (click)="deletePartner(partner, i)">close</mat-icon> -->
                            </div>
                        </ng-container>
                    </div>
                </ng-container>
                
            </div>
    
            <div class="white_exec" *ngIf="!!target?.outputExecutors?.clients.length">
                <div class="white_title">
                    <div class="exec_title">
                        {{"Clients users"|translate}}
                    </div>
                    <mat-icon (click)="addClient()">add_circle</mat-icon>
                </div>
    
                <div class="executors">
                    <ng-container *ngFor="let client of target?.outputExecutors?.clients; let i = index;">
                        <div class="exec" *ngIf="!(client.client_partner_company_id && !client.employee)">
                            <app-user (click)="activeUser(client, 'client user', i)" [class]="'cp executor black'" [lines]="2" [employee]="client?.employee"></app-user>
    
                            <div class="d-f-c">
                                <div class="cli_type_out">
                                    <div class="cli_exec_type">
                                        <span>
                                            <ng-container *ngIf="!!client.discussion_id; else elseTemplateChatCli">
                                                {{ 'Chat client' | translate }}
                                            </ng-container>
                                            <ng-template #elseTemplateChatCli>      
                                                <ng-container *ngIf="client.task_operation_id; else elseTemplateWorkCli">
                                                    {{ 'Job client' | translate }}
                                                </ng-container>
                                                <ng-template #elseTemplateWorkCli>
                                                    {{ 'Card client' | translate }}
                                                </ng-template>
                                            </ng-template>
                                        </span>
                                    </div>
                                </div>
                                <div class="checkbox_clients_col">
                                    <mat-checkbox [labelPosition]="'before'" [disabled]="client.disabled" class="ml-1 mr-1" color="primary" [(ngModel)]="client.is_principal" (change)="togglePartner($event, client)">
                                        {{ "Moderator" | translate }}
                                    </mat-checkbox>
                                </div>
                            </div>
                            <!-- <mat-icon class="mob_delete_executor" *ngIf="!!client.activate" (click)="deleteClient(client, i)">close</mat-icon> -->
                        </div>
                    </ng-container>
                </div>
    
                <div class="white_title">
                    <div class="exec_title">
                        {{"Clients workspaces"|translate}}
                    </div>
                    <mat-icon (click)="addPartnerClient()">add_circle</mat-icon>
                </div>
                <div class="executors">
                    <ng-container *ngFor="let client of target?.outputExecutors?.clients; let i = index;">
                        <div class="exec" *ngIf="client.client_partner_company_id && !client.employee">
                            <app-user (click)="activeUser(client, 'client workspace', i)" [class]="'cp executor black'" [lines]="2" [companyEmployee]="client?.partnerCompany" [imgRoute]="imgRoute" [host]="host" [company]="company"></app-user>
                            <div class="d-f-c">
                                <div class="cli_type_out">
                                    <div class="cli_exec_type">
                                        <span>
                                            <ng-container *ngIf="!!client.discussion_id; else elseTemplateChatCli">
                                                {{ 'Chat client' | translate }}
                                            </ng-container>
                                            <ng-template #elseTemplateChatCli>      
                                                <ng-container *ngIf="client.task_operation_id; else elseTemplateWorkCli">
                                                    {{ 'Job client' | translate }}
                                                </ng-container>
                                                <ng-template #elseTemplateWorkCli>
                                                    {{ 'Card client' | translate }}
                                                </ng-template>
                                            </ng-template>
                                        </span>
                                    </div>
                                </div>
    
                                <div class="checkbox_clients_col">
                                    <mat-checkbox [labelPosition]="'before'" [disabled]="client.disabled" class="ml-1 mr-1" color="primary" [(ngModel)]="client.is_principal" (change)="togglePartner($event, client)">
                                        {{ "Moderator" | translate }}
                                    </mat-checkbox>
                                </div>
                            </div>
                            <!-- <mat-icon class="mob_delete_executor" *ngIf="!!client.activate" (click)="deleteClient(client, i)">close</mat-icon> -->
                        </div>
                    </ng-container>
                </div>
                
            </div>
        </ng-template> 
    </ng-template>
</section>

<!-- <ng-container [ngTemplateOutlet]="ChatsTemplate" [ngTemplateOutletContext]="{ target: target, index: chatsIndex.value }"></ng-container>
<ng-template #ChatsTemplate let-target="target" let-index="index">
    <div class="lite_chats_content" [ngClass]="{'centred': target?.outputChats?.is_load && target?.outputChats?.chats?.length == 0}" [appDeviceClass]="'custom-scroll custom-scroll-t'"
    infinite-scroll
    [infiniteScrollDisabled]="target?.outputChats?.is_load"
    [scrollWindow]="false"
    [infiniteScrollUpDistance]="scrollUpDistance"
    [infiniteScrollThrottle]="throttle"
    (scrolled)="onDownChats($event, target)">     
        <ng-container *ngIf="target?.outputChats?.is_load && target?.outputChats?.chats?.length == 0; else elseTemplateLoadedDone">
            <mat-spinner></mat-spinner>
        </ng-container>
        <ng-template #elseTemplateLoadedDone>
            <ng-container *ngFor="let item of target?.outputChats?.chats">
                <div class="chat" [ngClass]="{'todo_chat': [1,2,5].includes(item.status_id), 'done_chat': [3,4,6,97,98,99].includes(item.status_id)}" (click)="goToChat(item, target)">
                    <div class="chat_name text_one_line">{{item.name}}</div>
                    <div class="to_board_btn" (click)="$event.preventDefault(); $event.stopPropagation(); addToBoard(item)">
                        <svg style="width: 12px; height: 14px" width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M10.8 12L6 16.8H9.6V24H12V16.8H15.6L10.8 12ZM19.2 2.4H18V0H15.6V2.4H6V0H3.6V2.4H2.4C1.76348 2.4 1.15303 2.65286 0.702944 3.10294C0.252856 3.55303 0 4.16348 0 4.8V21.6C0 22.2365 0.252856 22.847 0.702944 23.2971C1.15303 23.7471 1.76348 24 2.4 24H7.2V21.6H2.4V8.4H19.2V21.6H14.4V24H19.2C19.8365 24 20.447 23.7471 20.8971 23.2971C21.3471 22.847 21.6 22.2365 21.6 21.6V4.8C21.6 4.16348 21.3471 3.55303 20.8971 3.10294C20.447 2.65286 19.8365 2.4 19.2 2.4Z" fill="#219653"/>
                        </svg>
                    </div>
                </div>
            </ng-container>
        </ng-template>
    </div>
</ng-template> -->