import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { switchMap, tap } from 'rxjs/operators';
import { BaseClass } from 'src/app/shared/models/base-class';
import { AuthService } from 'src/app/shared/services/rest/auth.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { RefreshService } from 'src/app/shared/services/rest/refresh.service';

@Component({
  selector: 'app-invite-dialog',
  templateUrl: './invite-dialog.component.html',
  styleUrls: ['./invite-dialog.component.scss']
})
export class InviteDialogComponent extends BaseClass implements OnInit, OnDestroy {
  public companyes: any;
  public user: any;
  public host: any;
  public inviteData: any;
  public errorMsg: any = "";
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<InviteDialogComponent>,
    private companyService: CompanyService,
    private refreshService: RefreshService,
    private auth: AuthService,
    private router: Router,
  ) { super() }

  ngOnInit(): void {
    console.log("InviteDialogComponent", this.data)
    this.getCompanyes()
    this.getUser();
  }

  getUser() {
    this.attachSubscriptions(
      this.auth.$user.subscribe(resp => {
        console.log("USER", resp)
        this.user = resp;
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false});
  }

  login() {
    window.open(`${this.data.host}/user/auth/?action=lastauth&returnUrl=${window.location.href}`, "_self")
  }

  getCompanyes() {
    this.attachSubscriptions(
      this.companyService.getCompanyes().pipe(
        tap(resp => {
          this.companyes = resp;
        }),
        switchMap(x => this.companyService.getInvite(this.data.invite.replace("?code=", "")))
      ).subscribe(resp => {
        console.log("test", resp);
        if (resp.hasOwnProperty('exception')) {
          this.errorMsg = resp.exception.message
        } else {
          this.inviteData = resp[0];
        }
        // if (!!this.invite) {
        //   let source = [];
        //   source.push(this.companyService.getInvite(this.invite));
        //   source.push(this.clientsService.getInvite(this.invite));
          
        //   forkJoin(source).subscribe((data: any) => {
        //     console.log(data);
        //     if (data[0].length && !resp.find(el => el.id == data[0][0].company_id)) {
        //       this.inviteData = data[0][0];
        //       this.inviteData.is_employee = true;
        //     }
        //     if (data[1].length && !resp.find(el => el.id == data[1][0].company_id)) {
        //       this.inviteData = data[1][0];
        //       this.inviteData.is_client = true;
        //     }
        //   })
        // }

      })
    )
  }

  acceptInvite() {
    this.attachSubscriptions(
      this.companyService.acceptInvite(this.inviteData.id, this.data.invite.replace("?code=", "")).subscribe(resp => {
        this.getCompanyes();
        this.inviteData = false;
        this.router.navigate(['/main']);
        this.companyService.invited$.next(true);
        this.refreshService.newWorkSpace$.next(true)
        this.close();
      })
    )
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
