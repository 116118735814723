<h2 mat-dialog-title class="dialog_title">
    <span>
        {{ "You are adding a new tag, select the parameter to which you want to add a tag" | translate }}:
    </span>
    <button class="ml-1" mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h2>
<mat-dialog-content class="mat-typography">
    <div class="container form_wrapper">
        <mat-selection-list [multiple]="false" (selectionChange)="selectChange($event)">
            <ng-container *ngFor="let param of data.parameters">
                <mat-list-option *ngIf="param.id != 0" [value]="param"> 
                  {{param.name}}
                </mat-list-option>
            </ng-container>
            
        </mat-selection-list>
    </div>
</mat-dialog-content>
