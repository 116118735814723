<div class="alert_wrp">
    <ng-container *ngIf="data.id; else elseTemplateID">
        <div class="alert_target cp" ngxClipboard [cbContent]="data.id">
            {{ data.name | translate }}
        </div>
    </ng-container>
    <ng-template #elseTemplateID>          
        <div class="alert_target">
            {{ data.name | translate }}
        </div>
    </ng-template>


    <ng-container *ngIf="data.dates && data.dates.arr && data.dates.arr.length; else elseTemplateDates">
        <div class="dates">
            <div class="text">{{ "Try this dates (Workspace timezone)" | translate }}:</div>
            <div class="dates_in">
                <span class="date_item" *ngFor="let item of data.dates.arr">{{ item | date:'MMM dd, hh:mm a':'':data.dates.activeLang }}</span>
            </div>
        </div>
    </ng-container>
    <ng-template #elseTemplateDates>
        <ng-container *ngIf="data.can_copy; else elseTemplate">
            <div class="alert_value cp" ngxClipboard [cbContent]="data.copy_tmpl" (cbOnSuccess)="closeSnack()">
                {{ data.message | translate }}
            </div>
        </ng-container>
        <ng-template #elseTemplate>          
            <div class="alert_value" innerHtml="{{ data.message | translate }}"></div>
        </ng-template>
    </ng-template>

    <div *ngIf="data.can_copy" class="close_alert" (click)="closeSnack()">
        <mat-icon>close</mat-icon>
    </div>
</div>
  