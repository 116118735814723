import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { concat, forkJoin, from, of, ReplaySubject, Subscription } from 'rxjs';
import { concatMap, debounceTime, last, map, switchMap, tap } from 'rxjs/operators';
import { DeleteAlertComponent } from 'src/app/shared/global_components/delete-alert/delete-alert.component';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { AuthService } from 'src/app/shared/services/rest/auth.service';
import { ParametersService } from 'src/app/shared/services/rest/parameters.service';
import { RootService } from 'src/app/shared/services/rest/root.service';
import { environment } from 'src/environments/environment';
import { MY_FORMATS } from '../../atTasksDialog/task-profile-add/task-profile-add.component';
import { ChangeSizeLimitComponent } from '../dialogs/change-size-limit/change-size-limit.component';
import { CreateRootTypeComponent } from '../dialogs/create-root-type/create-root-type.component';
import { SameValuesComponent } from '../dialogs/same-values/same-values.component';
import { TranslateVerifiedComponent } from '../dialogs/translate-verified/translate-verified.component';
import { TipsInterfaceComponent } from 'src/app/shared/global_components/tips-interface/tips-interface.component';
import { WorkspaceLimitsComponent } from '../dialogs/workspace-limits/workspace-limits.component';
import * as moment from 'moment';
import { SelectionModel } from '@angular/cdk/collections';
import { LoadingService } from 'src/app/shared/services/rest/loading.service';
import { AddEditRootPlanComponent } from '../dialogs/add-edit-root-plan/add-edit-root-plan.component';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { AddEditTransactionComponent } from '../dialogs/add-edit-transaction/add-edit-transaction.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ]
})
export class DashboardComponent extends BaseClass implements OnInit, OnDestroy {
  throttle = 100;
  scrollUpDistance = 3.5;
  public host: any = environment.host;
  public imgRoute: any = '';
  public isExpanded: boolean = true;
  public activeTab: any;
  public companyes: any;
  public taskTypes: any;
  public jobTypes: any;
  public servers: any;
  public plans: any;
  public transactions: any;

  public usersPagination: any;
  public usersPage: number = 1;

  public companyesPagination: any;
  public companyesPage: number = 1;

  public parametersPagination: any;
  public parametersPage: number = 1;

  public serversPagination: any;
  public serversPage: number = 1;

  public plansPagination: any;
  public plansPage: number = 1;

  public transactionsPagination: any;
  public transactionsPage: number = 1;

  public serversCollection = new SelectionModel(
    true,
    []
  )

  public pagination: any;
  public totalBalance: number = 0;
  public page: number = 1;
  public allValues: any[] = [];

  public isLoadComp: boolean = false;
  public isLoadParameters: boolean = false;
  public isLoadServ: boolean = false;
  public isLoadPlans: boolean = false;
  public isLoadTransactions: boolean = false;
  public isLoad: boolean = false;
  public users: any;
  public locks: any;
  public parameters: any;
  public activeLang: any;
  public _user: any;

  public plansFilter: FormGroup = this.fb.group({
    company_id: '',
    id: '',
    is_active: '',
    is_type_plan: '',
    is_type_live_stream: ''
  })

  public workspaces: any = [];
  public workspacesControl: FormControl = new FormControl();
  public workspaces$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public transactionsFilter: FormGroup = this.fb.group({
    company_id: '',
    id: '',
    type: '',
    state: '',
    is_applied: '',
    is_hide: '',
    done_at_from: '',
    done_at_to: '',
  })

  public serversFilter: FormGroup = this.fb.group({
    role:'',
    is_active: '',
    is_stop: '',
    is_stopped: '',
    is_delete: '',
    is_deleted: '',
    q: ''
  })
  
  public originalControl: FormControl = new FormControl('0');
  public valuesControl: FormControl = new FormControl();
  public groupsTagsControl: FormControl = new FormControl('');
  public tagsControl: FormControl = new FormControl('');
  public paramDataSub: Subscription;
  public uploadMethod: FormControl = new FormControl(!!this.sm.localStorageGetItem("smartUpload") ? '' : 1);
  public menuItems: any[] = [
    {
      icon: 'domain',
      name: 'Companyes',
      tab: 'companyes'
    },
    {
      icon: 'people',
      name: 'Users',
      tab: 'users'
    },
    {
      icon: 'settings_suggest',
      name: 'Tags',
      tab: 'parameters'
    },
    {
      icon: 'task',
      name: 'Card Types',
      tab: 'card_types'
    },
    {
      icon: 'work',
      name: 'Job types',
      tab: 'job_types'
    },
    {
      icon: 'lock',
      name: 'Locks',
      tab: 'locks'
    },
    {
      icon: 'public',
      name: 'Servers',
      tab: 'servers'
    },
    {
      icon: 'switch_access_shortcut',
      name: 'Plans',
      tab: 'plans'
    },
    {
      icon: 'point_of_sale',
      name: 'Transactions',
      tab: 'transactions'
    },
  ]
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private auth: AuthService,
    private languageService: LanguageService,
    private rootService: RootService,
    private companyService: CompanyService,
    private ls: LoadingService,
    private fb: FormBuilder,
    private layoutService: LayoutService,
    private _adapter: DateAdapter<any>,
    private parametersService: ParametersService,
    private dialog: MatDialog,
    private bottomSheet: MatBottomSheet,
    private sm: StorageManagerService
  ) { 
    super()
   }

  ngOnInit(): void {
    this.getUser();
    this.getLangData();
    this.getImgRoute();
    this.getRootWorkspaces();

    this.attachSubscriptions(
      this.workspacesControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchWorkspaces(resp))
    )
    this.attachSubscriptions(
      this.activatedRoute.queryParams.subscribe(resp => {
        this.activeTab = this.activatedRoute.snapshot.queryParamMap.get('tab');
        if (this.paramDataSub) {
          this.paramDataSub.unsubscribe();
        }
        this.serversCollection.clear();
        switch (this.activeTab) {
          case 'companyes':
            this.getRootCompany(this.companyesPage);
            break;
          case 'users':
            this.getRootUsers(this.usersPage)
            break;
          case 'locks':
            this.getLocks()
            break;
          case 'card_types':
            this.getRootTaskTypes();
            break;
          case 'servers':
            console.log("this.activatedRoute.snapshot.queryParamMap.get('is_active')", this.activatedRoute.snapshot.queryParamMap.get('is_active'))
            this.serversFilter.patchValue({
              role: !!this.activatedRoute.snapshot.queryParamMap.get('role') ? this.activatedRoute.snapshot.queryParamMap.get('role') : "",
              is_active: this.activatedRoute.snapshot.queryParamMap.get('is_active') !== '' && this.activatedRoute.snapshot.queryParamMap.get('is_active') !== null ? +this.activatedRoute.snapshot.queryParamMap.get('is_active') : "",
              is_stop: this.activatedRoute.snapshot.queryParamMap.get('is_stop') !== '' && this.activatedRoute.snapshot.queryParamMap.get('is_stop') !== null ? +this.activatedRoute.snapshot.queryParamMap.get('is_stop') : "",
              is_stopped: this.activatedRoute.snapshot.queryParamMap.get('is_stopped') !== '' && this.activatedRoute.snapshot.queryParamMap.get('is_stopped') !== null ? +this.activatedRoute.snapshot.queryParamMap.get('is_stopped') : "",
              is_delete: this.activatedRoute.snapshot.queryParamMap.get('is_delete') !== '' && this.activatedRoute.snapshot.queryParamMap.get('is_delete') !== null ? +this.activatedRoute.snapshot.queryParamMap.get('is_delete') : "",
              is_deleted: this.activatedRoute.snapshot.queryParamMap.get('is_deleted') !== '' && this.activatedRoute.snapshot.queryParamMap.get('is_deleted') !== null ? +this.activatedRoute.snapshot.queryParamMap.get('is_deleted') : "",
              q: !!this.activatedRoute.snapshot.queryParamMap.get('q') ? this.activatedRoute.snapshot.queryParamMap.get('q') : "",
            })
            this.serversPage = 1;
            this.getRootServers(this.serversPage);
            break;
          case 'plans':
            this.plansFilter.patchValue({
              id: !!this.activatedRoute.snapshot.queryParamMap.get('id') ? this.activatedRoute.snapshot.queryParamMap.get('id') : "",
              company_id: !!this.activatedRoute.snapshot.queryParamMap.get('company_id') ? +this.activatedRoute.snapshot.queryParamMap.get('company_id') : "",
              is_active: this.activatedRoute.snapshot.queryParamMap.get('is_active') !== '' && this.activatedRoute.snapshot.queryParamMap.get('is_active') !== null ? +this.activatedRoute.snapshot.queryParamMap.get('is_active') : "",
              is_type_plan: this.activatedRoute.snapshot.queryParamMap.get('is_type_plan') !== '' && this.activatedRoute.snapshot.queryParamMap.get('is_type_plan') !== null ? +this.activatedRoute.snapshot.queryParamMap.get('is_type_plan') : "",
              is_type_live_stream: this.activatedRoute.snapshot.queryParamMap.get('is_type_live_stream') !== '' && this.activatedRoute.snapshot.queryParamMap.get('is_type_live_stream') !== null ? +this.activatedRoute.snapshot.queryParamMap.get('is_type_live_stream') : ""
            })
            this.plansPage = 1;
            this.getRootPlans(this.plansPage);
            break;
          case 'transactions':
            this.totalBalance = 0;
            this.transactionsFilter.patchValue({
              id: !!this.activatedRoute.snapshot.queryParamMap.get('id') ? this.activatedRoute.snapshot.queryParamMap.get('id') : "",
              company_id: !!this.activatedRoute.snapshot.queryParamMap.get('company_id') ? +this.activatedRoute.snapshot.queryParamMap.get('company_id') : "",
              type: !!this.activatedRoute.snapshot.queryParamMap.get('type') ? this.activatedRoute.snapshot.queryParamMap.get('type') : "",
              state: !!this.activatedRoute.snapshot.queryParamMap.get('state') ? this.activatedRoute.snapshot.queryParamMap.get('state') : "",
              is_applied: this.activatedRoute.snapshot.queryParamMap.get('is_applied') !== '' && this.activatedRoute.snapshot.queryParamMap.get('is_applied') !== null ? +this.activatedRoute.snapshot.queryParamMap.get('is_applied') : "",
              is_hide: this.activatedRoute.snapshot.queryParamMap.get('is_hide') !== '' && this.activatedRoute.snapshot.queryParamMap.get('is_hide') !== null ? +this.activatedRoute.snapshot.queryParamMap.get('is_hide') : ""
            })
            this.transactionsPage = 1;
            this.getRootTransactions(this.transactionsPage);
            break;
            case 'job_types':
            this.getRootJobTypes();
            break;
          case 'parameters':
            this.paramDataSub = this.parametersService.getParamsStream().pipe(
              concatMap(params => {
                return concat(...params.map(oneParam => this.neededParametersData(oneParam))).pipe(last(),map(x => params))
              }),
            ).subscribe(resp => console.log("-----getParamsStream-----",resp));
            this.parametersPage = 1;
            this.getParameters(this.parametersPage);
            this.findValues(this.page);
            break;
        
          default:
            break;
        }
        console.log(resp)
      })
    )

    this.attachSubscriptions(
      this.uploadMethod.valueChanges.subscribe(resp => {
        console.log(resp)
      })
    )

    this.attachSubscriptions(
      this.valuesControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => {
        this.page = 1;
        this.findValues(this.page, resp)
      })
    )
  }

  stopAndDeleteServer(server) {
    this.attachSubscriptions(
      this.rootService.editRootServer(server.id, {
        is_stop: 1,
        is_delete: 1
      }).subscribe(resp => {
        this.layoutService.showSnackBar({name: server.server_id}, "Successfully Stopped and Deleted", SnackBarItem)
        this.filterData()
      }, error => {
        this.layoutService.showSnackBar({name: server.server_id}, `Error: ${error}`, SnackBarItem)
      })
    )
  }
  
  onSearchWorkspaces(resp) {
    if (!this.workspaces) {
      return;
    }

    if (!resp) {
      this.workspaces$.next(this.workspaces.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.workspaces$.next(
      this.workspaces.filter(z => z.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  pauseServer(server) {
    this.attachSubscriptions(
      this.rootService.editRootServer(server.id, {
        is_stop: 1,
        stop_expires_at: +moment().format('X') + 604800
      }).subscribe(resp => {
        this.layoutService.showSnackBar({name: server.server_id}, "Successfully Paused for 1 week", SnackBarItem)
        this.filterData()
      }, error => {
        this.layoutService.showSnackBar({name: server.server_id}, `Error: ${error}`, SnackBarItem)
      })
    )
  }

  multiStopAndDeleteServer() {
    let lenSelection = this.serversCollection.selected.filter(item => item.role == 'live_stream_broadcasting' && !!item.is_active && !item.is_stop && !item.is_stopped && !item.is_delete && !item.is_deleted).length;
    let count = 0;

    if (!lenSelection) {
      this.layoutService.showSnackBar({name: 'All of selected Servers no active or paused or no streaming servers.'}, '', SnackBarItem)
      return
    }
    this.ls.requests$.next({
      value: 0,
      target: `Stop and Delete ${lenSelection} server(s)`
    })
    from(this.serversCollection.selected.filter(item => item.role == 'live_stream_broadcasting' && !!item.is_active && !item.is_stop && !item.is_stopped && !item.is_delete && !item.is_deleted)).pipe(
      concatMap((server:any) => {
        return this.rootService.editRootServer(server.id, {
          is_stop: 1,
          is_delete: 1
        })
      })
    ).subscribe({
      next: (next) => {
        console.log("next multiStopAndDeleteServer", next);
        this.ls.requests$.next({
          value: Math.round((100 / lenSelection) * (count+1)),
          target: `Stop and Delete ${lenSelection} server(s)`
        })
        count++;
      },
      complete: () => {
        console.log("complete multiStopAndDeleteServer");
        this.serversCollection.clear();
        this.serversPage = 1;
        this.getRootServers(this.serversPage);
      },
      error: (error) => {
        console.log("error multiStopAndDeleteServer", error)
      }
    })
  }

  logVal(val) {
    console.log("logVal", val)
  }

  // ifServerCheck(server) {
  //   return !!this.serversCollection.selected.filter(x => x.id == server.id).length
  // }

  toggleServer(server): void {
    this.serversCollection.toggle(server);

    console.log("toggleServer", this.serversCollection.selected)
  }

  multiPauseServers() {
    let lenSelection = this.serversCollection.selected.filter(item => item.role == 'live_stream_broadcasting' && !!item.is_active && !item.is_stop && !item.is_stopped && !item.is_delete && !item.is_deleted).length;
    let count = 0;

    if (!lenSelection) {
      this.layoutService.showSnackBar({name: 'All of selected Servers no active or paused or no streaming servers.'}, '', SnackBarItem)
      return
    }
    this.ls.requests$.next({
      value: 0,
      target: `Pause ${lenSelection} server(s)`
    })
    from(this.serversCollection.selected.filter(item => item.role == 'live_stream_broadcasting' && !!item.is_active && !item.is_stop && !item.is_stopped && !item.is_delete && !item.is_deleted)).pipe(
      concatMap((server:any) => {
        return this.rootService.editRootServer(server.id, {
          is_stop: 1,
          stop_expires_at: +moment().format('X') + 604800
        })
      })
    ).subscribe({
      next: (next) => {
        console.log("next multiPauseServers", next);
        this.ls.requests$.next({
          value: Math.round((100 / lenSelection) * (count+1)),
          target: `Pause ${lenSelection} server(s)`
        })
        count++;
      },
      complete: () => {
        console.log("complete multiPauseServers");
        this.serversCollection.clear();
        this.serversPage = 1;
        this.getRootServers(this.serversPage);
      },
      error: (error) => {
        console.log("error multiPauseServers", error)
      }
    })
  }

  checkboxLabel(row?): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.serversCollection.isSelected(row) ? 'deselect' : 'select'} server ${row.name}`;
  }

  isAllSelected() {
    if (!this.servers) {
      return false;
    }
    const numSelected = this.serversCollection.selected.length;
    const numRows = this.servers.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
        this.serversCollection.clear() :
        this.servers.forEach(row => this.serversCollection.select(row));
  }

  deletePlan(item, i) {
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: item,
        target: marker("plan")
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        this.attachSubscriptions(
          this.rootService.deleteRootPlan(item.id).subscribe(resp => {
            this.plans.splice(i, 1)
          })
        )
      }
    });
  }

  deleteTransaction(item, i) {
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: item,
        target: marker("transaction")
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        this.attachSubscriptions(
          this.rootService.deleteRootTransaction(item.id).subscribe(resp => {
            this.transactions.splice(i, 1)
          }, error => {
            this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
          })
        )
      }
    });
  }

  addRootPlan(item?) {
    console.log("addRootPlan", this.plans)

    let planData:any = {
      imgRoute: this.imgRoute,
      host: this.host
    }

    if (this.transactionsFilter.value.company_id) {
      planData.company_id = this.transactionsFilter.value.company_id;
    }
    if (item) {
      planData.plan = item;
    }
    const dialogRef = this.dialog.open(AddEditRootPlanComponent, {
      data: planData
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        this.plansPage = 1;
        this.getRootPlans(this.plansPage);
      })
    )
  }


  getRootWorkspaces() {
    this.attachSubscriptions(
      this.rootService.getRootCompany(1).pipe(
        map(k => k.body)
      ).subscribe(resp => {
        this.workspaces = resp;
        this.workspaces$.next(this.workspaces.slice());

        console.log("this.companyes", this.companyes)
      })
    )
  }

  addRootTransaction(item?) {
    console.log("addRootTransaction", this.transactions)

    let transactionData:any = {
      imgRoute: this.imgRoute,
      host: this.host
    }
    if (item) {
      transactionData.transaction = item;
    }
    if (this.transactionsFilter.value.company_id) {
      transactionData.company_id = this.transactionsFilter.value.company_id;
    }
    const dialogRef = this.dialog.open(AddEditTransactionComponent, {
      data: transactionData
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        this.transactionsPage = 1;
        this.getRootTransactions(this.transactionsPage);
      })
    )
  }

  filterTransactionsData() {
    let params = {
      tab: this.activeTab
    };

    Object.keys(this.transactionsFilter.value).forEach(element => {
      
      if (this.transactionsFilter.value[element] !== '') {
        if (element == 'company_id') {
          if (this.transactionsFilter.value[element] != 0) {
            params[element] = this.transactionsFilter.value[element];
          }
        } else {
          params[element] = this.transactionsFilter.value[element];
        }
      }
    });

    this.router.navigate(['/root'], { queryParams: params});
  }

  filterPlansData() {
    let params = {
      tab: this.activeTab
    };

    Object.keys(this.plansFilter.value).forEach(element => {
      if (this.plansFilter.value[element] !== '') {
        params[element] = this.plansFilter.value[element]
      }
    });

    this.router.navigate(['/root'], { queryParams: params});
  }

  filterData() {
    let params = {
      tab: this.activeTab
    };

    Object.keys(this.serversFilter.value).forEach(element => {
      if (this.serversFilter.value[element] !== '') {
        params[element] = this.serversFilter.value[element]
      }
    });

    this.router.navigate(['/root'], { queryParams: params});
  }

  keys(val) {
    return Object.keys(val)
  }

  filterParameters() {
    if (this.paramDataSub) {
      this.paramDataSub.unsubscribe();
    }
    this.paramDataSub = this.parametersService.getParamsStream().pipe(
      concatMap(params => {
        return concat(...params.map(oneParam => this.neededParametersData(oneParam))).pipe(last(),map(x => params))
      }),
    ).subscribe(resp => console.log("-----getParamsStream-----",resp));
    this.parametersPage = 1;
    this.getParameters(this.parametersPage);
  }

  findValues(page, q:any = '') {
    this.attachSubscriptions(
      this.parametersService.getRootParameterValuesFiltered(q, page).pipe(
        tap(el => {
          this.pagination = {
            'pageCount': el.headers.get('x-pagination-page-count'),
            'perPage': el.headers.get('x-pagination-per-page'),
            'totalCount': el.headers.get('x-pagination-total-count'),
            'currentPage': el.headers.get('x-pagination-current-page'),
          }
        }),
        map(el => el.body)
      ).subscribe(resp => {
        if (page == 1) {
          this.allValues = [...resp]
        } else {
          this.allValues.push(...resp)
        }
        console.log("findValues", this.page, this.allValues)
        this.page = +this.page + 1;
      })
    )
  }

  getNextValues() {
    console.log("getNextValues", this.page)

    if (this.pagination.pageCount >= this.page) {
      console.log("getNextValues 111")
      this.findValues(this.page, this.valuesControl.value ? this.valuesControl.value : '');
    }
  }

  openTips(param, value?) {
    let tipsData:any = {
      parameters: this.parameters,
      param: param,
      root: true
    }
    if (value) {
      tipsData.value = value;
    }
    const dialogRef = this.dialog.open(TipsInterfaceComponent, {
      data: tipsData
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (result && result.event == "add") {
          this.parametersPage = 1;
          this.getParameters(this.parametersPage);
        }
      })
    )
  }

  addType(is_card:boolean = false) {
    const dialogRef = this.dialog.open(CreateRootTypeComponent, {
      disableClose: true,
      data: {
        is_card: is_card
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (is_card) {
          this.getRootTaskTypes()
        } else {
          this.getRootJobTypes()
        }
      })
    )
  }

  unLock(lock) {
    this.attachSubscriptions(
      this.rootService.lockClear({action: lock.action}).subscribe(resp => {
        console.log("unLock", resp)

        this.getLocks();
      })
    )
  }

  getLocks() {
    this.attachSubscriptions(
      this.rootService.getLocks().subscribe(resp => {
        this.locks = resp
      })
    )
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData().pipe(
        tap(resp => {
          this.activeLang = resp.active;
          this._adapter.setLocale(resp.active);
        }),
      ).subscribe(resp => {
        console.log("getLangData", resp)
      })
    )
  }

  onPartnerImgError(event){
    event.target.src = this.imgRoute+'/assets/img/partner.png'
  }

  changeGeo(e, comp) {
    console.log(e, comp)
    comp.isGeo = true;
    this.attachSubscriptions(
      this.rootService.changeLimit(comp.id, {is_geocode: e.checked ? 1 : 0}).subscribe(resp => {
        comp.is_geocode = resp.is_geocode
        console.log(resp);
        comp.isGeo = false
      }, error => {
        comp.isGeo = false
      })
    )
  }

  changeVerified(e, comp) {
    console.log(e, comp)
    comp.isVerifing = true;
    this.attachSubscriptions(
      this.rootService.changeLimit(comp.id, {is_verified: e.checked ? 1 : 0}).subscribe(resp => {
        comp.is_verified = resp.is_verified
        console.log(resp);
        comp.isVerifing = false
      }, error => {
        comp.isVerifing = false
      })
    )
  }

  changeYT(e, comp) {
    console.log(e, comp)
    comp.isYT = true;
    this.attachSubscriptions(
      this.rootService.changeLimit(comp.id, {is_youtube_api_publish : e.checked ? 1 : 0}).subscribe(resp => {
        comp.is_youtube_api_publish = resp.is_youtube_api_publish;
        console.log(resp);
        comp.isYT = false
      }, error => {
        comp.isYT = false
      })
    )
  }

  changeYTLive(e, comp) {
    console.log(e, comp)
    comp.isYTLive = true;
    this.attachSubscriptions(
      this.rootService.changeLimit(comp.id, {is_youtube_api_live_stream : e.checked ? 1 : 0}).subscribe(resp => {
        comp.is_youtube_api_live_stream = resp.is_youtube_api_live_stream;
        console.log(resp);
        comp.isYTLive = false
      }, error => {
        comp.isYTLive = false
      })
    )
  }

  changeYTLiveThumb(e, comp) {
    console.log(e, comp)
    comp.isYTLiveThumb = true;
    this.attachSubscriptions(
      this.rootService.changeLimit(comp.id, {is_youtube_api_live_stream_thumbnail : e.checked ? 1 : 0}).subscribe(resp => {
        comp.is_youtube_api_live_stream_thumbnail = resp.is_youtube_api_live_stream_thumbnail;
        console.log(resp);
        comp.isYTLiveThumb = false
      }, error => {
        comp.isYTLiveThumb = false
      })
    )
  }

  changeValueAssigned(e, value) {
    console.log(e, value)
    this.attachSubscriptions(
      this.parametersService.editRootParameterValue(value.id, {is_not_automatically_assigned: e.checked ? 1 : 0}).subscribe(resp => {
        value.is_not_automatically_assigned = resp.is_not_automatically_assigned
        console.log(resp);
      }, error => {
        this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
      })
    )
  }

  translateVerified(comp) {
    const dialogRef = this.dialog.open(TranslateVerifiedComponent, {
      data: {
        company: comp,
        activeLang: this.activeLang,
        user: this._user,
        imgRoute: this.imgRoute
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
      this.getRootCompany(this.companyesPage);
      })
    )
  }

  createParamAsSystem(param) {
    console.log(param);
    this.attachSubscriptions(
      this.parametersService.addRootParameter({
        original_id: param.id,
        name: param.name
      }).subscribe(resp => {
        console.log(resp)
        this.parametersPage = 1;
        this.getParameters(this.parametersPage);
      }, error => {
        console.log(error)
        this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
      })
    )
  }
  
  createValAsSystem(val) {
    console.log(val);
    this.attachSubscriptions(
      this.parametersService.addRootParameterValue({
        original_id: val.id,
        parameter_id: val.parameter.original_id,
        value: val.value
      }).subscribe(resp => {
        console.log(resp)
        this.parametersPage = 1;
        this.getParameters(this.parametersPage);
      }, error => {
        console.log(error)
        this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
      })
    )
  }


  neededParametersData(param) {
    let arr = []
    let data:any = {};
    if (this.tagsControl.value != '') {
      data.company_id = this.tagsControl.value
    }
    if (this.originalControl.value != '') {
      data.original_id = this.originalControl.value
    }
    arr.push(
      this.parametersService.getRootParameterValues(param.id, data).pipe(
        tap(x => {
          param.values = x
        })
      )
    )

    return forkJoin(arr)
  }

  addRootParameterValue(param) {
    this.attachSubscriptions(
      this.parametersService.addRootParameterValue({parameter_id: param.id, value: "Add new tag value"}).subscribe(resp => {
        resp.company_id = param.company_id;
        param.values.unshift(resp)
        this.focusValue(param, resp)
      }, error => {
        this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
      })
    )
  }

  editRootParameterValue(param, value, e) {
    console.log(param, value, e)
    value.isEddited = false;

    this.attachSubscriptions(
      this.parametersService.getRootParameterValuesFiltered(value.value, 1).pipe(
        map(x => x.body.filter(x => x.id != value.id)),
        switchMap(arr => {
          if (arr.length == 0) {
            return this.parametersService.editRootParameterValue(value.id, {value: e.target.value}).pipe(
              tap(u => {
                value.value = u.value
              }),
              map(() => arr)
            )
          } else {
            return this.parametersService.getRootParameterValue(value.id).pipe(
              tap(u => {
                value.value = u.value
              }),
              map(() => arr)
            )
          }
        })
      ).subscribe(resp => {
        if (resp.length > 0) {
          const sameDialog = this.dialog.open(SameValuesComponent, {
            data: {
              activeLang: this.activeLang,
              user: this._user,
              host: this.host,
              imgRoute: this.imgRoute,
              sameValues: resp,
              val: e.target.value
            }
          });

          sameDialog.afterClosed().subscribe(res => {
            console.log("sameDialog.afterClosed()", res);
            if (res && res.event && res.event == 'save') {
              this.parametersService.editRootParameterValue(value.id, {value: e.target.value}).pipe(
                tap(u => {
                  value.value = u.value
                })
              ).subscribe(result => {
                console.log("editRootParameterValue", result);
              })
            }
          })
        } 
      })

    )
    // this.attachSubscriptions(
    //   this.parametersService.editRootParameterValue(value.id, {value: e.target.value}).pipe(
    //     switchMap(el => {
    //       return this.parametersService.getRootParameterValuesFiltered(el.value, 1).pipe(
    //         map(x => x.body),
    //         tap(u => {
    //           let sameValues = u.filter(x => x.id != el.id);
    //           console.log("getRootParameterValuesFiltered sameValues", sameValues)
    //           this.dialog.open(SameValuesComponent, {
    //             data: {
    //               activeLang: this.activeLang,
    //               user: this._user,
    //               host: this.host,
    //               imgRoute: this.imgRoute,
    //               sameValues: sameValues
    //             }
    //           });
    //         }),
    //         map(() => el)
    //       )
    //     })
    //   ).subscribe(resp => {
    //     console.log("editRootParameterValue", resp)
    //     value = resp;
    //   })
    // )
  }

  editRootParameter(param, e) {
    param.isEddited = false;

    this.attachSubscriptions(
      this.parametersService.editRootParameter(param.id, {name: e.target.value}).subscribe(resp => {
        console.log("editRootParameterValue", resp)
        param.name = resp.name;
        param.updated_at = resp.updated_at
      })
    )
  }

  getParameters(page) {
    let data:any = {page: page};
    if (this.groupsTagsControl.value != '') {
      data.company_id = this.groupsTagsControl.value
    }
    this.isLoadParameters = true;

    this.attachSubscriptions(
      this.parametersService.getRootParameters(data).pipe(
        tap(el => {
          this.parametersPagination = {
            'pageCount': el.headers.get('x-pagination-page-count'),
            'perPage': el.headers.get('x-pagination-per-page'),
            'totalCount': el.headers.get('x-pagination-total-count'),
            'currentPage': el.headers.get('x-pagination-current-page'),
          }
        }),
        map(k => k.body),
        tap(res => {
          this.parametersService.paramsData$.next(res)
        })
      ).subscribe(resp => {
        if (page == 1) {
          this.parameters = resp;
        } else {
          this.parameters.push(...resp)
        }
        console.log("getRootparameters-" + this.parametersPage, this.parameters);
        this.parametersPage++;
        this.isLoadParameters = false;
        console.log("this.parameters", this.parameters)
      })
    )
  }

  changeView(e: MatButtonToggleChange) {
    console.log(e);
    this.filterParameters();
  }

  saveValue(param, value) {
    value.isEddited = false
  }

  getWorkspaceById(id) {
    if (!this.workspaces || this.workspaces.length == 0) {
      return null
    }
    return this.workspaces.find(x => x.id == id)
  }

  focusValue(param, value) {
    value.isEddited = true;

    setTimeout(() => {
      if (document.getElementById(`val_${param.id}_${value.id}`)) {
        document.getElementById(`val_${param.id}_${value.id}`).focus()
      }
    }, 0)
  }

  deleteValue(param, val, i) {
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: val,
        target: marker("parameter value")
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        this.attachSubscriptions(
          this.parametersService.deleteRootParameterValue(val.id).subscribe(resp => {
            param.values.splice(i, 1)
          })
        )
      }
    });

  }

  deleteRootParam(param, i) {
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: param,
        target: marker("root parameter")
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        this.attachSubscriptions(
          this.parametersService.deleteRootParameter(param.id).subscribe(resp => {
            this.parameters.splice(i, 1)
          })
        )
      }
    });

  }

  saveRootParam(param) {
    param.isEddited = false
  }

  focusRootParam(param) {
    param.isEddited = true;

    setTimeout(() => {
      if (document.getElementById(`param_${param.id}`)) {
        document.getElementById(`param_${param.id}`).focus()
      }
    }, 0)
  }

  addRootParameter() {
    this.attachSubscriptions(
      this.parametersService.addRootParameter({name: "New root parameter"})
      .pipe(
        tap(b => { b.values = [] })
      ).subscribe(resp => {
        if (!resp.hasOwnProperty("company_id")) {
          resp.company_id = 0
        }
        this.parameters.push(resp);
        this.focusRootParam(resp);
      })
    )
  }

  getUser() {
    this.attachSubscriptions(
      this.auth.$user.subscribe(resp => {
        this._user = resp;
      })
    )
  }

  openLimits(company) {
    const dialogRef = this.dialog.open(WorkspaceLimitsComponent, {
      disableClose: true,
      data: company
    });
  }

  changeLimit(company) {
    const dialogRef = this.dialog.open(ChangeSizeLimitComponent, {
      disableClose: true,
      data: company
    });
  }

  changeUploadMethod(e) {
    console.log(e)
    this.sm.localStorageSetItem("smartUpload", e.checked ? '' : 1)
  }

  changeRoot(user) {
    this.attachSubscriptions(
      this.rootService.changeRoot(user.id, user.is_root == 1 ? 0 : 1).subscribe(resp => {
        console.log(resp)
        user.is_root = resp.is_root
      })
    )
  }

  onDownCompany(e) {
    console.log("SCROLL DOWN", e);
    if (this.companyesPagination['pageCount'] >= this.companyesPage) {
      console.log("onDownCompany")
      this.getRootCompany(this.companyesPage);
    }
  }

  onDownParameters(e) {
    console.log("SCROLL DOWN", e);
    if (this.parametersPagination['pageCount'] >= this.parametersPage) {
      console.log("onDownCompany")
      this.getParameters(this.parametersPage);
    }
  }
  onDownServers(e) {
    console.log("SCROLL DOWN", e);
    if (this.serversPagination['pageCount'] >= this.serversPage) {
      console.log("onDownCompany")
      this.getRootServers(this.serversPage);
    }
  }
  onDownPlans(e) {
    console.log("SCROLL DOWN", e);
    if (this.plansPagination['pageCount'] >= this.plansPage) {
      console.log("onDownCompany")
      this.getRootPlans(this.plansPage);
    }
  }
  
  onDownTransactions(e) {
    console.log("SCROLL DOWN", e);
    if (this.transactionsPagination['pageCount'] >= this.transactionsPage) {
      console.log("onDownCompany")
      this.getRootTransactions(this.transactionsPage);
    }
  }
  
  getRootServers(page) {
    this.isLoadServ = true;
    this.attachSubscriptions(
      this.rootService.getRootServers(page, this.serversFilter.value).pipe(
        tap(el => {
          this.serversPagination = {
            'pageCount': el.headers.get('x-pagination-page-count'),
            'perPage': el.headers.get('x-pagination-per-page'),
            'totalCount': el.headers.get('x-pagination-total-count'),
            'currentPage': el.headers.get('x-pagination-current-page'),
          }
        }),
        map(res => res.body),
      ).subscribe(resp => {
        resp.forEach(el => {
          if (el.serverLog && el.serverLog.data) {
            el.serverLog.data = JSON.parse(el.serverLog.data)
          }
          if (el.serverLog && el.serverLog.log) {
            el.serverLog.log = el.serverLog.log.replaceAll("\n","<br>")
          }
        })
        
        if (page == 1) {
          this.servers = resp;
        } else {
          this.servers.push(...resp)
        }
        console.log("getRootServers-" + this.serversPage, this.servers);
        this.serversPage++;
        this.isLoadServ = false;
      })
    )
  }

  getRootTransactions(page) {
    this.isLoadTransactions = true;
    let params:any = {}
    Object.keys(this.transactionsFilter.value).forEach(element => {
      
      if (this.transactionsFilter.value[element] !== '') {
        if (element == 'company_id') {
          if (this.transactionsFilter.value[element] != 0) {
            params[element] = this.transactionsFilter.value[element];
          }
        } else if (element == 'type') {
          params[this.transactionsFilter.value[element]] = 1;
        } else if (element == 'state') {
          params[this.transactionsFilter.value[element]] = 1;
        } else {
          params[element] = this.transactionsFilter.value[element];
        }
      }
    });
    this.attachSubscriptions(
      this.rootService.getRootTransactions(page, params).pipe(
        tap(el => {
          this.transactionsPagination = {
            'pageCount': el.headers.get('x-pagination-page-count'),
            'perPage': el.headers.get('x-pagination-per-page'),
            'totalCount': el.headers.get('x-pagination-total-count'),
            'currentPage': el.headers.get('x-pagination-current-page'),
          }
        }),
        map(res => res.body),
      ).subscribe(resp => {
        resp.forEach(el => {
          if (el.serverLog && el.serverLog.data) {
            el.serverLog.data = JSON.parse(el.serverLog.data)
          }
          if (el.serverLog && el.serverLog.log) {
            el.serverLog.log = el.serverLog.log.replaceAll("\n","<br>")
          }
        })
        
        if (page == 1) {
          this.transactions = resp;
        } else {
          this.transactions.push(...resp)
        }
        if (this.transactionsFilter.value.company_id && this.transactions.length && this.transactions.find(k => !!k.is_applied)) {
          this.totalBalance = this.transactions.find(k => !!k.is_applied).balance;
        }
        console.log("getRoottransactions-" + this.transactionsPage, this.transactions);
        this.transactionsPage++;
        this.isLoadTransactions = false;
      })
    )
  }

  getRootPlans(page) {
    this.isLoadPlans = true;
    this.attachSubscriptions(
      this.rootService.getRootPlans(page, this.plansFilter.value).pipe(
        tap(el => {
          this.plansPagination = {
            'pageCount': el.headers.get('x-pagination-page-count'),
            'perPage': el.headers.get('x-pagination-per-page'),
            'totalCount': el.headers.get('x-pagination-total-count'),
            'currentPage': el.headers.get('x-pagination-current-page'),
          }
        }),
        map(res => res.body),
      ).subscribe(resp => {
        resp.forEach(el => {
          if (el.serverLog && el.serverLog.data) {
            el.serverLog.data = JSON.parse(el.serverLog.data)
          }
          if (el.serverLog && el.serverLog.log) {
            el.serverLog.log = el.serverLog.log.replaceAll("\n","<br>")
          }
        })
        
        if (page == 1) {
          this.plans = resp;
        } else {
          this.plans.push(...resp)
        }
        console.log("getRootplans-" + this.plansPage, this.plans);
        this.plansPage++;
        this.isLoadPlans = false;
      })
    )
  }

  getRootCompany(page) {
    this.isLoadComp = true;
    this.attachSubscriptions(
      this.rootService.getRootCompany(page).pipe(
        tap(el => {
          if (page == 1) {
            this.companyesPagination = {
              'pageCount': el.headers.get('x-pagination-page-count'),
              'perPage': el.headers.get('x-pagination-per-page'),
              'totalCount': el.headers.get('x-pagination-total-count'),
              'currentPage': el.headers.get('x-pagination-current-page'),
            }
          }
        }),
        map(res => res.body),
      ).subscribe(resp => {
        
        if (page == 1) {
          this.companyes = resp;
        } else {
          this.companyes.push(...resp)
        }
        console.log("getRootCompany-" + this.companyesPage, this.companyes);
        this.companyesPage++;
        this.isLoadComp = false;
      })
    )
  }

  deleteRootCardType(type, i) {
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: type,
        target: marker("root card type")
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        this.attachSubscriptions(
          this.rootService.deleteRootTaskType(type.id).subscribe(resp => {
            this.taskTypes.splice(i, 1)
          }, error => {
            this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
          })
        )
      }
    });

  }

  deleteRootJobType(type, i) {
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: type,
        target: marker("root job type")
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        this.attachSubscriptions(
          this.rootService.deleteRootJobType(type.id).subscribe(resp => {
            this.jobTypes.splice(i, 1)
          }, error => {
            this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
          })
        )
      }
    });

  }

  toggleHideRootCardType(type) {
    this.attachSubscriptions(
      this.rootService.editRootTaskType(type.id, {is_hidden: type.is_hidden == 0 ? 1 : 0 }).subscribe(resp => {
        console.log(resp)
        type.is_hidden = resp.is_hidden
      }, error => {
        this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
      })
    )
  }

  toggleHideRootJobType(type) {
    this.attachSubscriptions(
      this.rootService.editRootJobType(type.id, {is_hidden: type.is_hidden == 0 ? 1 : 0 }).subscribe(resp => {
        console.log(resp)
        type.is_hidden = resp.is_hidden
      }, error => {
        this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
      })
    )
  }

  publicRootCardType(type) {
    this.attachSubscriptions(
      this.rootService.editRootTaskType(type.id, {company_id: 0 }).subscribe(resp => {
        console.log(resp)
        type.company_id = resp.company_id
      }, error => {
        this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
      })
    )
  }

  publicRootJobType(type) {
    this.attachSubscriptions(
      this.rootService.editRootJobType(type.id, {company_id: 0 }).subscribe(resp => {
        console.log(resp)
        type.company_id = resp.company_id
      }, error => {
        this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
      })
    )
  }

  getRootTaskTypes() {
    this.attachSubscriptions(
      this.rootService.getRootTaskTypes().subscribe(resp => {
        console.log(resp)
        this.taskTypes = resp
      }, error => {
        this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
      })
    )
  }

  getRootJobTypes() {
    this.attachSubscriptions(
      this.rootService.getRootJobTypes().subscribe(resp => {
        console.log(resp)
        this.jobTypes = resp
      }, error => {
        this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
      })
    )
  }

  onDownUsers(e) {
    console.log("SCROLL DOWN", e);
    if (this.usersPagination['pageCount'] >= this.usersPage) {
      console.log("onDownUsers")
      this.getRootUsers(this.usersPage);
    }
  }
  
  getRootUsers(page) {
    this.isLoad = true;
    this.attachSubscriptions(
      this.rootService.getRootUsers(page).pipe(
        tap(el => {
          if (page == 1) {
            this.usersPagination = {
              'pageCount': el.headers.get('x-pagination-page-count'),
              'perPage': el.headers.get('x-pagination-per-page'),
              'totalCount': el.headers.get('x-pagination-total-count'),
              'currentPage': el.headers.get('x-pagination-current-page'),
            }
          }
        }),
        map(res => res.body),
      ).subscribe(resp => {
        
        if (page == 1) {
          this.users = resp;
        } else {
          this.users.push(...resp)
        }
        console.log("getRootUsers-" + this.usersPage, this.users);
        this.usersPage++;
        this.isLoad = false;
      })
    )
  }

  goToMenuItem(item) {
    this.router.navigate(['/root'], { queryParams: {tab: item.tab}});
  }

  ngOnDestroy(): void {
    if (this.paramDataSub) {
      this.paramDataSub.unsubscribe();
    }
    this.clearSubscriptions()
  }

}
