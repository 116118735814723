import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReplaySubject, forkJoin } from 'rxjs';
import { debounceTime, last, map, switchMap, tap } from 'rxjs/operators';
import { BaseClass } from 'src/app/shared/models/base-class';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { MembersService } from 'src/app/shared/services/rest/members.service';
import { ParametersService } from 'src/app/shared/services/rest/parameters.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';

@Component({
  selector: 'app-view-scheme',
  templateUrl: './view-scheme.component.html',
  styleUrls: ['./view-scheme.component.scss']
})

export class ViewSchemeComponent extends BaseClass implements OnInit, OnDestroy {
  public employees: any;
  public employees$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public employeesControl: FormControl = new FormControl();

  public operationStatuses: any;
  public jobTypes: any;
  public jobTypesControl: FormControl = new FormControl();
  public jobTypes$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public isSubmit: boolean = false;

  public platforms: any;
  public parameters: any;
  public paramControl: FormControl = new FormControl();
  public parameters$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public result: any = {
    jobType: '',
    jobStatus: [],
    reflectJobStatus: [],
    platform_id: '',
    tagCategory: '',
    refl: '-',
    is_one: false,
    is_two: false,
    ccol1: false,
    ccol2: false,
    ccol3: false
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private taskService: TaskService,
    private companyService: CompanyService,
    private parametersService: ParametersService,
    public dialogRef: MatDialogRef<ViewSchemeComponent>,
    private fb: FormBuilder,
    private membersService: MembersService
  ) { super() }

  ngOnInit(): void {
    if (this.data.scheme) {
      this.result = JSON.parse(JSON.stringify(this.data.scheme));
    }
    console.log("ViewSchemeComponent", this.data);

    this.attachSubscriptions(
      this.jobTypesControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchJobTypes(resp))
    )

    this.attachSubscriptions(
      this.paramControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchParams(resp))
    )
    this.getParameters();
    this.getPlatforms();

    this.operationStatuses = this.data.operationStatuses;
    this.jobTypes = this.data.jobTypes;
    this.jobTypes$.next(this.jobTypes.slice())

    // this.attachSubscriptions(
    //   this.employeesControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchEmoloyees(resp))
    // )

    // this.getEmployees();
  }

  onSearchParams(resp) {
    if (!this.parameters) {
      return;
    }

    console.log("JOBS", this.parameters)
    if (!resp) {
      this.parameters$.next(this.parameters.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }

    this.parameters$.next(this.parameters.filter(z => z.name && z.name.toLowerCase().indexOf(resp) > -1));
  }
  
  getPlatforms() {
    this.attachSubscriptions(
      this.companyService.getGroupPlatforms().subscribe(resp => {
        this.platforms = resp;
        console.log("this.platforms", this.platforms)
      })
    )
  }

  getPlatformById(id) {
    return this.platforms && this.platforms.find(x => x.id == id)
  }
  getParamById(id) {
    return this.parameters && this.parameters.find(x => x.id == id)
  }
  getStatusById(id) {
    return this.operationStatuses && this.operationStatuses.find(x => x.id == id)
  }

  getParameters() {
    this.attachSubscriptions(
      this.parametersService.getApiParameters(this.data.company.id).pipe(
        tap(res => {
          this.parameters = res;
          this.parameters$.next(this.parameters.filter(x => !!x.id).slice())
        }),
      ).subscribe(resp => {
        console.log("this.parameters", this.parameters)
      })
    )
  }

  getTypeById(id) {
    return this.jobTypes && this.jobTypes.find(x => x.id == id)
  }
    
  onSearchJobTypes(resp) {
    if (!this.jobTypes) {
      return;
    }

    if (!resp) {
      this.jobTypes$.next(this.jobTypes.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }

    // filter the banks
    this.jobTypes$.next(
      this.jobTypes.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  onSearchEmoloyees(resp) {
    if (!this.employees) {
      return;
    }

    if (!resp) {
      this.employees$.next(this.employees.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.employees$.next(
      this.employees.filter(z => z.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  getEmployees() {
    this.attachSubscriptions(
      this.taskService.getEmployeesDyn('1', this.data.company.id, null, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }
  
          return forkJoin(arr.map(x => this.taskService.getEmployeesDyn(x, this.data.company.id, null, '200').pipe(map(u => u.body)))).pipe(
            last(),
            tap(values => {
              let conValues = [].concat(...values)
              this.employees = conValues;
              this.employees$.next(this.employees.slice())
            }),
          )
        }),
      ).subscribe(resp => {
        console.log("getEmployees resp", resp);
        console.log("ViewSchemeComponent getEmployees", this.employees);
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false});
  }
  
  default() {
    this.dialogRef.close({event: "default", data: false});
  }

  submitForm() {
    this.dialogRef.close({event: "ok", data: this.result});
  }


  ngOnDestroy() {
    this.clearSubscriptions()
  }

}
