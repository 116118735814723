<h2 mat-dialog-title class="mobile_interface_title" (click)="log()">
    {{ "Extra info" | translate }}
</h2>
<mat-dialog-content>
    <div class="extras" [appDeviceClass]="'custom-scroll custom-scroll-t'"  >
        <ng-container *ngFor="let item of extras; let i = index">
            <div class="extra">
                <pre>{{item|json}}</pre>
                <div class="extra_in">
                    <div class="info">
                        <span class="mr-1">{{"Server" | translate}}:</span>
                        <b>{{item.server_id}}</b>
                    </div>
                    <div class="info">
                        <span class="mr-1">{{"Created at" | translate}}:</span>
                        <ng-container *ngIf="item.created_at > today && item.created_at < todayEnd; else createdAtTempl">
                            <b #tooltip="matTooltip"
                              matTooltip="{{ item.created_at*1000 | date:'MMM d, y':'':data.activeLang | yearPipe }}"
                              [matTooltipPosition]="'above'">
                              {{ item.created_at*1000 | date:'hh:mm a':'':data.activeLang }}
                            </b>
                        </ng-container>
                        <ng-template #createdAtTempl>
                            <b *ngIf="item.created_at && item.created_at > 30000" #tooltip="matTooltip"
                              matTooltip="{{ item.created_at*1000 | date:'hh:mm a':'':data.activeLang | yearPipe }}"
                              [matTooltipPosition]="'above'">
                              {{ item.created_at*1000 | date:'MM.dd.yy, hh:mm a':'': data.activeLang }}
                            </b>
                        </ng-template>
                    </div>
                    <div class="info">
                        <span class="mr-1">{{"Updated at" | translate}}:</span>
                        <ng-container *ngIf="item.updated_at > today && item.updated_at < todayEnd; else updatedAtTempl">
                            <b #tooltip="matTooltip"
                              matTooltip="{{ item.updated_at*1000 | date:'MMM d, y':'':data.activeLang | yearPipe }}"
                              [matTooltipPosition]="'above'">
                              {{ item.updated_at*1000 | date:'hh:mm a':'':data.activeLang }}
                            </b>
                        </ng-container>
                        <ng-template #updatedAtTempl>
                            <b *ngIf="item.updated_at && item.updated_at > 30000" #tooltip="matTooltip"
                              matTooltip="{{ item.updated_at*1000 | date:'hh:mm a':'':data.activeLang | yearPipe }}"
                              [matTooltipPosition]="'above'">
                              {{ item.updated_at*1000 | date:'MM.dd.yy, hh:mm a':'': data.activeLang }}
                            </b>
                        </ng-template>
                    </div>
                    <div class="info">
                        <span class="mr-1">{{"Downloading" | translate}}:</span>
                        <ng-container *ngIf="item.downloading_started_at > today && item.downloading_started_at < todayEnd; else downloadingAtTempl">
                            <b #tooltip="matTooltip"
                              matTooltip="{{ item.downloading_started_at*1000 | date:'MMM d, y':'':data.activeLang | yearPipe }}"
                              [matTooltipPosition]="'above'">
                              {{ item.downloading_started_at*1000 | date:'hh:mm a':'':data.activeLang }}
                            </b>
                        </ng-container>
                        <ng-template #downloadingAtTempl>
                            <b *ngIf="item.downloading_started_at && item.downloading_started_at > 30000" #tooltip="matTooltip"
                              matTooltip="{{ item.downloading_started_at*1000 | date:'hh:mm a':'':data.activeLang | yearPipe }}"
                              [matTooltipPosition]="'above'">
                              {{ item.downloading_started_at*1000 | date:'MM.dd.yy, hh:mm a':'': data.activeLang }}
                            </b>
                        </ng-template>
                    </div>
                    <div class="info">
                        <span class="mr-1">{{"Broadcasting started" | translate}}:</span>
                        <ng-container *ngIf="item.broadcasting_started_at > today && item.broadcasting_started_at < todayEnd; else broadcastingStartedAtTempl">
                            <b #tooltip="matTooltip"
                              matTooltip="{{ item.broadcasting_started_at*1000 | date:'MMM d, y':'':data.activeLang | yearPipe }}"
                              [matTooltipPosition]="'above'">
                              {{ item.broadcasting_started_at*1000 | date:'hh:mm a':'':data.activeLang }}
                            </b>
                        </ng-container>
                        <ng-template #broadcastingStartedAtTempl>
                            <b *ngIf="item.broadcasting_started_at && item.broadcasting_started_at > 30000" #tooltip="matTooltip"
                              matTooltip="{{ item.broadcasting_started_at*1000 | date:'hh:mm a':'':data.activeLang | yearPipe }}"
                              [matTooltipPosition]="'above'">
                              {{ item.broadcasting_started_at*1000 | date:'MM.dd.yy, hh:mm a':'': data.activeLang }}
                            </b>
                        </ng-template>
                    </div>
                    <div class="info">
                        <span class="mr-1">{{"Broadcasting stopped" | translate}}:</span>
                        <ng-container *ngIf="item.broadcasting_stopped_at > today && item.broadcasting_stopped_at < todayEnd; else broadcastingStoppedAtTempl">
                            <b #tooltip="matTooltip"
                              matTooltip="{{ item.broadcasting_stopped_at*1000 | date:'MMM d, y':'':data.activeLang | yearPipe }}"
                              [matTooltipPosition]="'above'">
                              {{ item.broadcasting_stopped_at*1000 | date:'hh:mm a':'':data.activeLang }}
                            </b>
                        </ng-container>
                        <ng-template #broadcastingStoppedAtTempl>
                            <b *ngIf="item.broadcasting_stopped_at && item.broadcasting_stopped_at > 30000" #tooltip="matTooltip"
                              matTooltip="{{ item.broadcasting_stopped_at*1000 | date:'hh:mm a':'':data.activeLang | yearPipe }}"
                              [matTooltipPosition]="'above'">
                              {{ item.broadcasting_stopped_at*1000 | date:'MM.dd.yy, hh:mm a':'': data.activeLang }}
                            </b>
                        </ng-template>
                    </div>
                    <div class="info" *ngIf="!!item.error_message">
                        <span class="mr-1">{{"Error" | translate}}:</span>
                        <b style="color: red;">{{item.error_message}}</b>
                    </div>

                    <div class="btns d-f-c">

                        <button mat-icon-button (click)="!!item.is_pause ? startBroadcast(item) : pauseBroadcast(item)"
                        *ngIf="!!item.is_active" 
                        #tooltip="matTooltip"
                        matTooltip="{{ (!!item.is_pause ? 'Start broadcast' : 'Pause broadcast') | translate }}"
                        [matTooltipPosition]="'above'">
                            <mat-icon>{{!!item.is_pause ? 'play_circle_outline' : 'pause_circle_outline'}}</mat-icon>
                        </button>

                        <button mat-icon-button (click)="moveBroadcast(item)"
                        *ngIf="!!item.is_active" 
                        #tooltip="matTooltip"
                        matTooltip="{{ 'Move broadcast to another server' | translate }}"
                        [matTooltipPosition]="'above'">
                            <mat-icon>alt_route</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
            <mat-divider style="margin: 5px 0;" *ngIf="i != extras.length - 1"></mat-divider>
        </ng-container>
        
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <div class="d-f-c" style="justify-content: space-between !important;">
        <button mat-button (click)="close()">
            {{ "Close" | translate }}
        </button>
        <button class="ml-1" mat-raised-button color="warn" (click)="refresh()">
            {{ "Refresh" | translate }}
        </button>
        <button class="ml-1" mat-raised-button color="primary" *ngIf="pagination && +this.page <= +pagination['pageCount']" (click)="getPostExtra()">
            {{ "More" | translate }}
        </button>
    </div>
</mat-dialog-actions>