<h2 mat-dialog-title class="dialog_title">
    <span>{{ "Editing a profile" | translate }}: {{ data.profile.content_name.length > 20 ? (data.profile.content_name | slice:0:20) + " ..." : data.profile.content_name }}</span>
    <button mat-button (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h2>

<mat-dialog-content class="mat-typography"  dirEnter (dirSubmit)="!form.valid ? null : onSubmit()">
    <div class="container form_wrapper">
        <form [formGroup]="form">
            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ 'Content Name' | translate }}</mat-label>
                <input formControlName="content_name" matInput placeholder="{{ 'Content Name' | translate }}">
            </mat-form-field>
        </form>
        
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-raised-button (click)="close()">
        {{ "Back" | translate }}
    </button>
    <button class="ml-1 d-f-btn" (click)="onSubmit()" mat-raised-button cdkFocusInitial color="primary" [disabled]="!form.valid">
        {{ "Save" | translate }}
    </button>
</mat-dialog-actions>