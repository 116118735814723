import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseClass } from 'src/app/shared/models/base-class';

@Component({
  selector: 'app-ask-paste-files',
  templateUrl: './ask-paste-files.component.html',
  styleUrls: ['./ask-paste-files.component.scss']
})
export class AskPasteFilesComponent extends BaseClass implements OnInit, OnDestroy {
  public clearAfter: boolean = true;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AskPasteFilesComponent>,
  ) { super() }

  ngOnInit(): void {
    console.log("AskPasteFilesComponent", this.data)
  }

  chose(type) {
    this.dialogRef.close({action: type, clear: this.clearAfter})
  }

  close() {
    this.dialogRef.close()
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
