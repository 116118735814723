import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BaseClass } from '../../models/base-class';
import { StorageManagerService } from '../../services/common/storage-manager.service';
import { CompanyService } from '../../services/rest/company.service';
import * as moment from 'moment';
import { catchError, map, tap } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { LanguageService } from '../../services/common/language.service';
import { MembersService } from '../../services/rest/members.service';
import { TaskService } from '../../services/rest/task.service';
import { MatDialog } from '@angular/material/dialog';
import { NotificationsBar } from '../notifications-bar/notifications-bar';
import { fromEvent, of } from 'rxjs';
import { SnackBarItem } from '../snack-bar/snack-bar-item';
import { LayoutService } from '../../services/common/layout.service';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss']
})
export class UserInfoComponent extends BaseClass implements OnInit, OnDestroy {
  @Input() user: any;
  @Input() company_id: any;
  @Input() task_operation_id: any;
  public host: any = environment.host;
  public job: any;
  public activeLang: any;
  public company: any;
  public operationsValues: any;
  public statuses: any;
  public activeSection: string = '';
  public departments: any = [];
  public imgRoute: any = '';
  public todayScheduleData: any;
  public today: any = moment().utc().startOf("day").format("X");
  
  public is_mobile: boolean = false;

  @Output() mouseEnter = new EventEmitter<void>();
  @Output() mouseLeave = new EventEmitter<void>();
  @Output() onClose = new EventEmitter<void>();
  
  constructor(
    private sm: StorageManagerService,
    private languageService: LanguageService,
    private dialog: MatDialog,
    private taskService: TaskService,
    private companyService: CompanyService,
    private layoutService: LayoutService,
    private membersService: MembersService,
    public activatedRoute: ActivatedRoute,
  ) { 
    super()
  }

  ngOnInit(): void {
    console.log("UserInfoComponent", {user: this.user, company_id: this.company_id, task_operation_id: this.task_operation_id})
    this.getImgRoute()
    this.getLangData()

    if (window.innerWidth <= 769) {
      this.is_mobile = true;
    } else {
      this.is_mobile = false;
    }

    if (!this.company_id) {
      this.company_id = this.activatedRoute.snapshot.queryParamMap.get('company_id') || this.user.company_id;
    }

    if (!!this.company_id) {
      this.getCompany();
    }
    if (!!this.task_operation_id) {
      this.getJobLine();
      this.getOperationsStatus();
      this.getOperations();
    }


    console.log("UserInfoComponent", {user: this.user, company_id: this.company_id, task_operation_id: this.task_operation_id})

    if (!this.user.partnerCompany) {
      this.getTodaySchedule(this.user.id)
      this.getUserPositions(this.user.id)
    }
  }

  getOperationsStatus() {
    this.attachSubscriptions(
      this.taskService.getOperationsStatus().subscribe(resp => {
        this.statuses = resp.slice().reverse();
      })
    )
  }

  getOperations() {
    this.attachSubscriptions(
      this.taskService.getOperations(this.company_id, this.activeLang).subscribe(resp => {
        this.operationsValues = resp;
      })
    )
  }

  getJobLine() {
    this.attachSubscriptions(
      this.taskService.getWorks('1', this.company_id, {id: this.task_operation_id}, '20').pipe(
        map(res => res.body)
      ).subscribe(resp => {
        console.log("getJobLine", resp)
        if (resp.length > 0) {
          let element = resp[0]
          if (!element.hasOwnProperty('partnerEmployees')) {
            element.partnerEmployees = []
          }
          if (!element.hasOwnProperty('employees')) {
            element.employees = []
          }
          if (!element.hasOwnProperty('partnerCompanies')) {
            element.partnerCompanies = []
          }
          element.partnerEmployees.forEach(empl => {
            empl.is_partner_employee = true;
            if (element.partnerEmployeesStatuses.filter(y => y.employee_id == empl.employee_id && y.task_operation_id == empl.task_operation_id).length > 0) {
              if (element.partnerEmployeesStatuses.filter(x => x.discussion_id == empl.discussion_id).length > 0) {
                empl.statusObj = element.partnerEmployeesStatuses.find(x => x.employee_id == empl.employee_id && x.task_operation_id == empl.task_operation_id && x.discussion_id == empl.discussion_id)
              }
              if (element.partnerEmployeesStatuses.filter(x => x.discussion_id == 0).length > 0) {
                empl.statusObj = element.partnerEmployeesStatuses.find(x => x.employee_id == empl.employee_id && x.task_operation_id == empl.task_operation_id && x.discussion_id == 0)
              }
            }
  
            empl.employee = empl.partnerEmployee;
  
            delete empl.partnerEmployee
          })
  
          element.employees.forEach(empl => {
            if (element.employeesStatuses.filter(y => y.employee_id == empl.employee_id && y.task_operation_id == empl.task_operation_id).length > 0) {
              if (element.employeesStatuses.filter(x => x.discussion_id == empl.discussion_id).length > 0) {
                empl.statusObj = element.employeesStatuses.find(x => x.employee_id == empl.employee_id && x.task_operation_id == empl.task_operation_id && x.discussion_id == empl.discussion_id)
              }
              if (element.employeesStatuses.filter(x => x.discussion_id == 0).length > 0) {
                empl.statusObj = element.employeesStatuses.find(x => x.employee_id == empl.employee_id && x.task_operation_id == empl.task_operation_id && x.discussion_id == 0)
              }
            }
          })
  
          element.employees.push(...element.partnerEmployees)
  
          
          element.addEmployee = element.employees.filter(x => x.task_operation_id == element.id && (x.is_manager == 0 || (x.is_manager == 1 && x.company_id != element.company_id)));
          
          element.mainEmployees = element.employees.filter(x => x.task_operation_id == element.id && x.is_manager == 1 && x.company_id == element.company_id);
  
          if (element.partnerCompanies.length) {
            element.partnerCompanies.forEach(p => {
              if (element.partnerCompaniesStatuses.filter(y => y.partner_company_id == p.partner_company_id && y.task_operation_id == p.task_operation_id).length > 0) {
                if (element.partnerCompaniesStatuses.filter(x => x.discussion_id == p.discussion_id).length > 0) {
                  p.statusObj = element.partnerCompaniesStatuses.find(x => x.partner_company_id == p.partner_company_id && x.task_operation_id == p.task_operation_id && x.discussion_id == p.discussion_id)
                }
                if (element.employeesStatuses.filter(x => x.discussion_id == 0).length > 0) {
                  p.statusObj = element.partnerCompaniesStatuses.find(x => x.partner_company_id == p.partner_company_id && x.task_operation_id == p.task_operation_id && x.discussion_id == 0)
                }
              }
              p.is_partner = true;
              element.employees.push(p);
              if (p.is_manager == 1) {
                element.mainEmployees.push(p);
              } else {
                element.addEmployee.push(p)
              }
            
            })
          }

  
          element.uniqueEmployees = [];
  
  
          element.mainEmployees.forEach(u => {
            if (!element.uniqueEmployees.find(x => x.employee_id == u.employee_id)) {
              element.uniqueEmployees.push(u)
            }
          });
  


          this.job = resp[0];
          if (!!this.user.partnerCompany) {
            this.job.userInfo = this.job.employees.find(x => !!x.partner_company_id && x.partner_company_id == this.user.partnerCompany.id)
          } else {
            this.job.userInfo = this.job.employees.find(x => !!x.employee_id && x.employee_id == this.user.id)
          }
        }
        console.log("getJobLine", this.job)

      })
    )
  }

  changeStatusTo(id) {
    if (!!this.job.userInfo.is_partner) {
      if (!!this.job.userInfo.statusObj?.id) {
        this.attachSubscriptions(
          this.membersService.editPartnerStatus(this.job.userInfo.statusObj.id, {status_id: id}, this.company_id).subscribe(resp => {
            this.getJobLine();
          }, error => {
            this.showStatusError(error, this.job.userInfo?.partnerCompany?.name)
          })
        )
      } else {
        this.attachSubscriptions(
          this.membersService.addPartnerStatus({
            status_id: id,
            company_id: this.company_id,
            task_id: this.job.task_id,
            task_operation_id: this.job.id,
            partner_company_id: this.job.userInfo.partner_company_id,
          }, this.company_id).subscribe(resp => {
            this.getJobLine();
          }, error => {
            this.showStatusError(error, this.job.userInfo?.partnerCompany?.name)
          })
        )
      }
    } else if (!!this.job.userInfo.is_partner_employee) {
      if (!!this.job.userInfo.statusObj?.id) {
        this.attachSubscriptions(
          this.membersService.editPartnerEmployeeStatus(this.job.userInfo.statusObj.id, {status_id: id}, this.company_id).subscribe(resp => {
            this.getJobLine();
          }, error => {
            this.showStatusError(error, this.job.userInfo?.employee?.display_name)
          })
        )
      } else {
        this.attachSubscriptions(          
          this.membersService.addPartnerEmployeeStatus({
            status_id: id,
            company_id: this.company_id,
            task_id: this.job.task_id,
            task_operation_id: this.job.id,
            partner_company_id: this.job.userInfo.partner_company_id,
            partner_employee_id: this.job.userInfo.partner_employee_id
          }, this.company_id).subscribe(resp => {
            this.getJobLine();
          }, error => {
            this.showStatusError(error, this.job.userInfo?.employee?.display_name)
          })
        )
      }
    } else {
      if (!!this.job.userInfo.statusObj?.id) {
        this.attachSubscriptions(
          this.membersService.editTaskEmployeeStatus(this.job.userInfo.statusObj.id, {status_id: id}, this.company_id).subscribe(resp => {
            this.getJobLine();
          }, error => {
            this.showStatusError(error, this.job.userInfo?.employee?.display_name)
          })
        )       
      } else {
        this.attachSubscriptions(
          this.membersService.addTaskEmployeeStatus({
            status_id: id,
            company_id: this.company_id,
            task_id: this.job.task_id,
            task_operation_id: this.job.id,
            employee_id: this.job.userInfo.employee_id,
          }, this.company_id).subscribe(resp => {
            this.getJobLine();
          }, error => {
            this.showStatusError(error, this.job.userInfo?.employee?.display_name)
          })
        )
      }
    }
  }

  showStatusError(error, user?) {
    this.layoutService.showSnackBar({name: user}, `You do not have permission to do this. ${error}`, SnackBarItem)
  }
  
  changeUserJobStatus(job) {
    this.job.is_changing_status = !this.job.is_changing_status;
    console.log("changeUserJobStatus", job)
  }

  getOperationStatusName(id) {
    switch (+id) {
      case 1:
        return "Waiting";
        break;
      case 2:
        return "In process";
        break;
      case 3:
        return "To approve";
        break;
      case 4:
        return "Approved";
        break;
      case 5:
        return "To correct";
        break;
      case 6:
        return "Sources Needed";
        break;
      case 97:
        return "On hold";
        break;
      case 98:
        return "Ready";
        break;
      case 99:
        return "Canceled";
        break;
    }
  }

  onResize() {
    this.attachSubscriptions(
      fromEvent(window, "resize").pipe(
        map(() => window.innerWidth)
      ).subscribe((wWidth) => {
          let vh = window.innerHeight * 0.01;
          document.documentElement.style.setProperty('--vh', `${vh}px`);
          if (wWidth <= 769) {
            this.is_mobile = true;
          } else {
            this.is_mobile = false;
          }
        }
      )
    )
  }

  getPrimaryTag(job) {
    if (job.parameterValuesToTask && job.parameterValuesToTask.length) {
      return job.parameterValuesToTask.find(x => !!x.is_primary)
    } else {
      return undefined
    }
  }
  
  
  getColumnName(id) {
    if (!this.operationsValues || this.operationsValues.filter(el => el.id == id).length == 0) {
      return '';
    }
    return this.operationsValues.find(el => el.id == id)?.translation?.name ? this.operationsValues.find(el => el.id == id).translation.name : this.operationsValues.find(el => el.id == id).name
  }

  getCompany() {
    this.attachSubscriptions(
      this.companyService.getCompany(this.company_id).subscribe(resp => {
        this.company = resp[0];
        console.log("getCompany", this.company)
      })
    )
  }

  openActivity(e) {
    e.preventDefault();
    e.stopPropagation();

    this.attachSubscriptions(
      this.taskService.getActions(false, 1, this.company.id, {employee_id: this.user.id}).subscribe(resp => {
        
        if (resp.body.length) {
          this.dialog.open(NotificationsBar, {
            backdropClass: 'backdrop_under_header',
            panelClass: !this.is_mobile ? ['open_task_dialog', 'show_header'] : 'open_task_dialog',
            data: {
              notifications: resp.body,
              employees: [],
              employee_id: this.user.id,
              taskStatuses: undefined,
              company: this.company,
              user: this.user,
              empl_status: this.company.permissions.includes('owner') || this.company.permissions.includes('admin') || this.company.permissions.includes('manager'),
              header: true,
              pagination: {
                'pageCount': resp.headers.get('x-pagination-page-count'),
                'perPage': resp.headers.get('x-pagination-per-page'),
                'totalCount': resp.headers.get('x-pagination-total-count'),
                'currentPage': resp.headers.get('x-pagination-current-page'),
              },
            }
          });
          this.onClose.emit()
        }
      })
    )

    console.log("openActivity")
  }

  activateSection(val) {
    if (this.activeSection == val) {
      this.activeSection = '';
    } else {
      this.activeSection = val;
    }
  }

  getUserPositions(id) {
    this.attachSubscriptions(
      this.membersService.getPostionsToEmployee(this.company_id, {employee_id: id}).subscribe(resp => {
        resp.forEach(el => {
          if (this.departments.filter(x => x.department_id == el.department_id).length == 0) {
            this.departments.push({
              department_id: el.department_id,
              department: el.department,
              positions: [Object.assign({info: el}, el.departmentPosition)]
            })
          } else {
            this.departments.find(x => x.department_id == el.department_id).positions.push(Object.assign({info: el}, el.departmentPosition))
          }
        });
        console.log("getUserPositions", this.departments);
      })
    )
  }

  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData().pipe(
        tap(resp => {
          this.activeLang = resp.active;
        })
      ).subscribe(resp => {
        console.log("this.activeLang", this.activeLang)
      })
    )
  }

  getTodaySchedule(employee_id) {
    this.attachSubscriptions(
      this.companyService.getScheduleItem(this.company_id, 1, '200', {day: this.today, employee_id: employee_id}).pipe(map(x => x.body)).subscribe(resp => {
        if (resp && resp.filter(x => x.from_time != 0 && x.to_time != 0 && x.from_time > x.day && x.to_time > x.day).length) {
          this.todayScheduleData = resp.filter(x => x.from_time != 0 && x.to_time != 0 && x.from_time > x.day && x.to_time > x.day);
        } else {
          this.todayScheduleData = undefined;
        }
        console.log("getTodaySchedule", this.todayScheduleData);
      })
    )
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

}
