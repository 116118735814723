import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReplaySubject, forkJoin } from 'rxjs';
import { debounceTime, last, map, switchMap, tap } from 'rxjs/operators';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { BaseClass } from 'src/app/shared/models/base-class';
import { GlobalDataService } from 'src/app/shared/services/common/global-data.service';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { MembersService } from 'src/app/shared/services/rest/members.service';
import { ParametersService } from 'src/app/shared/services/rest/parameters.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';

@Component({
  selector: 'app-price-to-employee',
  templateUrl: './price-to-employee.component.html',
  styleUrls: ['./price-to-employee.component.scss']
})
export class PriceToEmployeeComponent extends BaseClass implements OnInit, OnDestroy {
  public form: FormGroup;
  public currencyes: any

  public groups: any
  public groupMoreControl: FormControl = new FormControl();
  public groups$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public operations: any;
  public operationMoreControl: FormControl = new FormControl();
  public operations$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public allValues: any;
  public allValuesControl: FormControl = new FormControl();
  public allValues$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public pricesToEmployee: any;
  public isSubmit: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<PriceToEmployeeComponent>,
    public layoutService: LayoutService,
    public parametersService: ParametersService,
    public globalDataService: GlobalDataService,
    public taskService: TaskService,
    public companyService: CompanyService,
    public membersService: MembersService,
  ) { super() }

  ngOnInit(): void {
    if (this.data.is_partner) {
      this.form = this.fb.group({
        company_id: this.data.company.id,
        partner_company_id: this.data.employee.partner_company_id,
        is_task_partner_employee: 0,
        is_task_partner_employee_assistant: 0,
        group_id: 0,
        operation_id: 0,
        parameter_value_id: 0,
        rate: 0,
        price: 0,
        price_currency_id: this.data.employee.currency_id
      })
    } else {
      this.form = this.fb.group({
        company_id: this.data.company.id,
        employee_id: this.data.employee.id,
        is_task_employee: 0,
        is_task_employee_assistant: 0,
        group_id: 0,
        operation_id: 0,
        parameter_value_id: 0,
        rate: 0,
        price: 0,
        price_currency_id: this.data.employee.currency_id
      })
    }

    console.log("PriceToEmployeeComponent", this.data)
    this.getPriceToEmployee(this.data.is_partner ? {partner_company_id: this.data.employee.partner_company_id} : {employee_id: this.data.employee.id});
    this.getOperations();
    this.getCurrencyList();
    this.getGroupsCompany();
    this.getAllApiParameterValues();
    this.attachSubscriptions(
      this.operationMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchOperations(resp))
    )
    this.attachSubscriptions(
      this.groupMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchGroups(resp))
    )
    this.attachSubscriptions(
      this.allValuesControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchValues(resp))
    )
  }

  getCurrency(id) {
    if (!!this.currencyes) {
      return this.currencyes.find(el => el.id == id)
    }
  }

  getOperationsById(id) {
    return this.operations && this.operations.find(x => x.id == id)
  }

  getGroupById(id) {
    if (!this.groups || !this.groups.find(el => el.id == id)) {
      return false;
    }
    return this.groups.find(el => el.id == id)
  }  

  getCurrencyList() {
    this.attachSubscriptions(
      this.globalDataService.getCurrencies().subscribe(resp => {
        console.log("getCurrencyList", resp)
        let currencyData = [...resp]
        this.currencyes = currencyData;
      })
    )
  }

  getAllApiParameterValues() {
    this.attachSubscriptions(
      this.parametersService.getAllValues('1', this.data.company.id, null, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.parametersService.getAllValues(x, this.data.company.id).pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        this.allValues = [].concat(...res);
        this.allValues$.next(this.allValues.slice())
      })
    )
  }

  getValuesById(id) {
    return this.allValues && this.allValues.find(x => x.id == id)
  }

  onSearchValues(resp) {
    if (!this.allValues) {
      return;
    }

    if (!resp) {
      this.allValues$.next(this.allValues.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.allValues$.next(
      this.allValues.filter(z => z.value.toLowerCase().indexOf(resp) > -1 || (z.parameter.name && z.parameter.name.toLowerCase().indexOf(resp) > -1))
    );
  }

  onSearchGroups(resp) {
    if (!this.groups) {
      return;
    }

    if (!resp) {
      this.groups$.next(this.groups.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.groups$.next(
      this.groups.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  getGroupsCompany() {
    this.attachSubscriptions(
      this.companyService.getInfiniteGroupsCompany(this.data.company.id, '1', '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.companyService.getInfiniteGroupsCompany(this.data.company.id, x).pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        this.groups = [].concat(...res);
        this.groups$.next(this.groups.slice());
      })
    )
  }

  getOperations() {
    this.attachSubscriptions(
      this.taskService.getOperations(this.data.company.id).subscribe(resp => {
        this.operations = resp;
        this.operations$.next(this.operations.slice())
      })
    )
  }
  
  onSearchOperations(resp) {
    if (!this.operations) {
      return;
    }

    if (!resp) {
      this.operations$.next(this.operations.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.operations$.next(
      this.operations.filter(z => (z.name.toLowerCase().indexOf(resp) > -1))
    );
  }

  getPriceToEmployee(filter) {
    this.attachSubscriptions(
      this.membersService.getPriceToEmployee(this.data.company.id, filter).subscribe(resp => {
        console.log("getPriceToEmployee", resp)
        this.pricesToEmployee = resp
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false});
  }

  onSubmit() {
    this.isSubmit = true;
    let sbmtData = {...this.form.value}

    this.attachSubscriptions(
      this.membersService.addPriceToEmployee(sbmtData).subscribe(resp => {
        this.isSubmit = false;
        if (this.data.is_partner) {
          this.form.patchValue({
            is_task_partner_employee: 0,
            is_task_partner_employee_assistant: 0,
            group_id: 0,
            operation_id: 0,
            parameter_value_id: 0,
            rate: 0,
            price: 0,
            price_currency_id: this.data.employee.currency_id
          })
        } else {
          this.form.patchValue({
            is_task_employee: 0,
            is_task_employee_assistant: 0,
            group_id: 0,
            operation_id: 0,
            parameter_value_id: 0,
            rate: 0,
            price: 0,
            price_currency_id: this.data.employee.currency_id
          })
        }

        this.getPriceToEmployee(this.data.is_partner ? {partner_company_id: this.data.employee.partner_company_id} : {employee_id: this.data.employee.id});
      }, error => {
        this.isSubmit = false;
        this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
      })
    )
  }

  // editPriceToEm(priceToEm, key) {
  //   if (priceToEm.isEditing) {
  //     return
  //   }
  //   priceToEm.isEditing = true;
  //   this.attachSubscriptions(
  //     this.membersService.editPositionToEmployee(priceToEm.id, {[key]: !!priceToEm[key] ? 0 : 1}).subscribe(resp => {
  //       // priceToEm.is_department_view = resp.is_department_view;
  //       // priceToEm.is_department_position_view = resp.is_department_position_view;
  //       // priceToEm.is_department_manage_prices = resp.is_department_manage_prices;
  //       // priceToEm.is_department_position_manage_prices = resp.is_department_position_manage_prices;
  //       priceToEm.isEditing = false;
  //     }, error => {
  //       this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
  //       priceToEm.isEditing = false;
  //     })
  //   )
  // }

  deletePriceToEmployee(id, i) {
    this.attachSubscriptions(
      this.membersService.deletePriceToEmployee(id).subscribe(resp => {
        this.pricesToEmployee.splice(i, 1)
      })
    )
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
