<h2 mat-dialog-title class="dialog_title">
    <span>
        {{ data?.tasks.length}} {{ "Tasks have the same content link" | translate }}: {{ data.resp.name.length > 29 ? (data.resp.name | slice:0:29) + " ..." : data.resp.name }}
    </span>
    <button mat-button (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h2>
<mat-dialog-content class="mat-typography">
    <div class="container task_container form_wrapper" #tasksContainer>
        <mat-list>
            <mat-list-item *ngFor="let task of tasks">
              <mat-icon mat-list-icon style="transform: rotate(-90deg);">note</mat-icon>
              <div mat-line>{{ task.name }}</div>
              <div mat-line>{{ task.id }} &nbsp;{{task.custom_id}}/{{task.internal_id}}/{{task.id}}</div>
            </mat-list-item>
        </mat-list>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-raised-button (click)="close()">
        {{ "Close" | translate }}
    </button>
</mat-dialog-actions>