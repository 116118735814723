import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseClass } from 'src/app/shared/models/base-class';
import { ParametersService } from 'src/app/shared/services/rest/parameters.service';

@Component({
  selector: 'app-tips-interface',
  templateUrl: './tips-interface.component.html',
  styleUrls: ['./tips-interface.component.scss']
})
export class TipsInterfaceComponent extends BaseClass implements OnInit {
  public tips: any;
  public origin = window.location.origin;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<TipsInterfaceComponent>,
    private parametersService: ParametersService,
  ) { super() }

  ngOnInit(): void {
    console.log("TipsInterfaceComponent", this.data);
    this.getTips();
  }

  getTips() {
    if (this.data.root) {
      let filter:any = {
        company_id: this.data.param.company_id,
        expand: 'task'
      }
  
      if (!!this.data.value) {
        filter.parameter_value_id = this.data.value.id
      } else {
        filter.parameter_id = this.data.param.id
      }
      this.attachSubscriptions(
        this.parametersService.getRootTips(this.data.param.company_id, filter).subscribe(resp => {
          console.log("tips", resp)
          this.tips = resp;
        })
      )
    } else {
      let filter:any = {
        company_id: this.data.company_id,
        expand: 'task'
      }
  
      if (!!this.data.value) {
        filter.parameter_value_id = this.data.value.id
      } else {
        filter.parameter_id = this.data.param.id
      }
      this.attachSubscriptions(
        this.parametersService.getApiTips(this.data.company_id, filter).subscribe(resp => {
          console.log("tips", resp)
          this.tips = resp;
        })  
      )
    }
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

}
