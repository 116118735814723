import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { FileService } from 'src/app/shared/services/rest/file.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { SelectFileComponent } from '../select-file/select-file.component';
import { FormControl } from '@angular/forms';
import { ReplaySubject, concat, empty, forkJoin, of } from 'rxjs';
import { catchError, debounceTime, last, map, switchMap, tap } from 'rxjs/operators';
import { ParametersService } from 'src/app/shared/services/rest/parameters.service';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { SnackBarItem } from '../../../snack-bar/snack-bar-item';
import { LoadingService } from 'src/app/shared/services/rest/loading.service';
import { ReplacementsSettingsComponent } from './dialog/replacements-settings/replacements-settings.component';
import { DeleteAlertComponent } from '../../../delete-alert/delete-alert.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-auto-projects',
  templateUrl: './auto-projects.component.html',
  styleUrls: ['./auto-projects.component.scss']
})
export class AutoProjectsComponent extends BaseClass implements OnInit, OnDestroy {
  public isFormChange: boolean = false;
  public isSubmit: boolean = false;
  public isSubmitAuto: boolean = false;
  public isGettingReplacements: boolean = false;
  public loadAuto: boolean = false;
  public parameter_id: number = 0;
  public operation_reminder_id: number = 0;

  public headCols: any;
  public selectedCols: any;
  public allValues: any;

  public openedLines: any[] = [];
  
  public nestedTags: any[] = [];

  public checklists: any;
  public checklistsCtrl: FormControl = new FormControl();
  public checklists$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public parameters: any;
  public parametersCtrl: FormControl = new FormControl();
  public parameters$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public replSettings:any = {
    is_all_target: true,
    is_location_all: true,
    folder: "/",
  };
  public videoFiles = [];

  public audioFiles = [];

  public outputObj: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private taskService: TaskService,
    public dialogRef: MatDialogRef<AutoProjectsComponent>,
    private fileService: FileService,
    private ls: LoadingService,
    private bottomSheet: MatBottomSheet,
    private parametersService: ParametersService,
    private dialog: MatDialog,
    private layoutService: LayoutService
  ) { super() }

  ngOnInit(): void {
    console.log("AutoProjectsComponent", this.data)

    this.attachSubscriptions(
      this.parametersCtrl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchParams(resp))
    )
    this.attachSubscriptions(
      this.checklistsCtrl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchChecks(resp))
    )

    if (this.data.work) {
      this.getJobChecklists()
    }
    
    if (this.data.files) {
      if (this.data.files.video && this.data.files.video.length) {
        this.data.files.video.forEach(file => {
          if (this.videoFiles.filter(x => x.file_id == file.id).length == 0) {
            this.videoFiles.push({
              file_id: file.id,
              original: file,
              replaceArr: []
            })
          }
        });
      }
      if (this.data.files.audio && this.data.files.audio.length) {
        this.data.files.audio.forEach(file => {
          if (this.audioFiles.filter(x => x.file_id == file.id).length == 0) {
            this.audioFiles.push({
              file_id: file.id,
              original: file,
              replaceArr: []
            })
          }
        });
      }
    }


    if (this.data.project.batch_data) {

      if (this.data.project.batch_data.parameter_id) {
        this.parameter_id = this.data.project.batch_data.parameter_id
      }
      if (this.data.project.batch_data.nestedTags) {
        this.nestedTags = this.data.project.batch_data.nestedTags
      }
      if (this.data.project.batch_data.selectedCols) {
        this.selectedCols = this.data.project.batch_data.selectedCols
      }
      if (this.data.project.batch_data.operation_reminder_id) {
        this.operation_reminder_id = this.data.project.batch_data.operation_reminder_id
      }
      if (this.data.project.batch_data.output_video_project_files && this.data.project.batch_data.output_video_project_files.length) {
        this.openedLines = [];
        this.outputObj = this.data.project.batch_data.output_video_project_files;

        this.outputObj.forEach(el => {
          let arr = [];
          for (let index = 0; index < el.output_filenames.length; index++) {
            arr.push(this.operation_reminder_id)
          }
          el.operation_reminder_id = arr;
        })
      }

      let ivpf;
      if (this.data.project.batch_data.all_input_video_project_files && this.data.project.batch_data.all_input_video_project_files.length) {
        ivpf = this.data.project.batch_data.all_input_video_project_files;
      } else if (this.data.project.batch_data.input_video_project_files && this.data.project.batch_data.input_video_project_files.length) {
        ivpf = this.data.project.batch_data.input_video_project_files;
      }
        
      if (ivpf && ivpf.length) {
        this.loadAuto = true;
        let ids = []
        ivpf.forEach(el => {
          el.files_ids.forEach(id => {       
            if (!ids.includes(id)) {
              ids.push(id)
            }
          });
        })
        this.attachSubscriptions(
          this.fileService.getFilesWithTags(this.data.company.id, ids).subscribe(res => {
            res.forEach(element => {
              element.uniqueParameterValuesToTask = [];
              element.parameterValuesToTask.forEach(x => {
                if (element.uniqueParameterValuesToTask.filter(u => u.parameter_value_id == x.parameter_value_id).length == 0) {
                  element.uniqueParameterValuesToTask.push(x)
                }
              })
            });
            this.loadAuto = false;
            ivpf.forEach(el => {
              el.files_ids.forEach(id => {
                if (this.videoFiles.find(x => x.file_id == el.file_id)) {
                  if (res.find(x => x.id == id)) {
                    if (id == el.file_id) {
                      this.videoFiles.find(x => x.file_id == el.file_id).replaceArr.push(null);
                    } else {
                      this.videoFiles.find(x => x.file_id == el.file_id).replaceArr.push(res.find(x => x.id == id));
                    }
                  }
                }
                if (this.audioFiles.find(x => x.file_id == el.file_id)) {
                  if (res.find(x => x.id == id)) {
                    if (id == el.file_id) {
                      this.audioFiles.find(x => x.file_id == el.file_id).replaceArr.push(null);
                    } else {
                      this.audioFiles.find(x => x.file_id == el.file_id).replaceArr.push(res.find(x => x.id == id));
                    }
                  }
                }
              });
            })
  
            if (this.parameter_id) {
              if (!this.data.project.batch_data.nestedTags) {
                this.nestedTags = [this.parameter_id]
              }
              this.headCols = this.getLineTags(!(this.data.project.batch_data.output_video_project_files && this.data.project.batch_data.output_video_project_files.length));
              if (!(this.data.project.batch_data.output_video_project_files && this.data.project.batch_data.output_video_project_files.length)) {
                this.updateOutput();
              }
              if (!this.selectedCols) {
                this.selectedCols = [...this.headCols];
              }
              console.log("this.headCols", this.headCols)

              if (this.headCols.length > 0) {
                this.videoFiles.forEach(col => {
                  if (col.replaceArr.length < this.headCols.length) {
                    let counters = this.headCols.length - col.replaceArr.length
    
                    for (let index = 0; index < counters; index++) {
                      col.replaceArr.push(null)
                    }
                  }
                })
    
                this.audioFiles.forEach(col => {
                  if (col.replaceArr.length < this.headCols.length) {
                    let counters = this.headCols.length - col.replaceArr.length
    
                    for (let index = 0; index < counters; index++) {
                      col.replaceArr.push(null)
                    }
                  }
                })
              }
            }
            console.log("res");
            
          })
        )
      }
    }
    



    this.getParameters();


    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        if (this.isFormChange) {
          this.layoutService.openBottomSheet(this.dialogRef);
        } else {
          this.close();
        }
      })
    )
  }

  toggleCol(tId) {
    if (!this.selectedCols) {
      this.selectedCols = [tId];
    } else {
      if (this.selectedCols.includes(tId)) {
        this.selectedCols.splice(this.selectedCols.findIndex(u => u == tId), 1)
      } else {
        this.selectedCols.push(tId)
      }
    }

    this.updateOutput();
  }

  getJobChecklists() {
    this.attachSubscriptions(
      this.taskService.getOperationReminder(this.data.company.id, {task_operation_id: this.data.work.id}).pipe(map(x => x.body)).subscribe(resp => {
        this.checklists = resp;
        console.log("this.checklists", this.checklists)
        this.checklists$.next(this.checklists.slice())
      })
    )
  }

  onRemChange(e) {
    console.log("onRemChange", e)
  }

  onNestedChange(e) {
    console.log("onNestedChange", e)
  }

  getValueById(id) {
    if (!this.allValues || this.allValues.length == 0) {
      return null
    }
    return this.allValues.find(x => x.id == id)
  }

  onParamChange(e) {
    this.videoFiles.forEach(el => {
      el.replaceArr = []
    })
    this.audioFiles.forEach(el => {
      el.replaceArr = []
    })
    if (this.parameter_id) {
      this.headCols = this.getLineTags();
      this.selectedCols = [...this.headCols];
      this.updateOutput();
      console.log("onParamChange this.headCols", this.headCols)
      this.nestedTags = [this.parameter_id]
    } else {
      this.headCols = undefined;
      this.selectedCols = undefined;
      this.nestedTags = [];
    }
  }

  getParameters() {
    this.attachSubscriptions(
      this.parametersService.getApiParameters(this.data.company.id).pipe(
        switchMap(res => {
          return this.parametersService.getAllValues('1', this.data.company.id, null, '1').pipe(
            switchMap(el => {
              let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
              let arr = []
              for (let index = 1; index <= pages; index++) {
                arr.push(index)
              }
    
              return forkJoin(arr.map(x => this.parametersService.getAllValues(x, this.data.company.id).pipe(map(u => u.body)))).pipe(
                last(),
                tap(values => {
                  this.allValues = [].concat(...values)
                  console.log("getAllApiParameterValues", this.allValues)
                }),
                map(() => res)
              )
            }),
            map(() => res)
          )
        }),
        tap(res => {
          res.unshift({
            company_id: this.data.company.id,
            created_at: 0,
            id: 0,
            is_new_value: 0,
            name: "Unset",
            original_id: 0,
          })
          this.parameters = res;
          this.parameters.forEach(element => {
            if (element.id == 0) {
              element.values = this.allValues;
            } else {
              element.values = this.allValues.filter(u => u.parameter_id = element.id);
            }
          });
          this.parameters$.next(this.parameters.filter(x => !!x.id).slice())
        }),
      ).subscribe(resp => {
        console.log("this.parameters", this.parameters)
        console.log("this.allValues", this.allValues)
      })
    )
  }

  toggleLine(i) {
    if (this.openedLines.includes(i)) {
      this.openedLines.splice(this.openedLines.indexOf(i), 1)
    } else {
      this.openedLines.push(i)
    }
  }

  getLineTags(is_upd_output:boolean = true) {
    let tagsIds = [];

    if (this.videoFiles && this.videoFiles.length) {
      this.videoFiles.forEach(col => {
        if (col.replaceArr.length > 0) {
          col.replaceArr.forEach((el, i) => {
            if (el && this.parameter_id && el.uniqueParameterValuesToTask && el.uniqueParameterValuesToTask.filter(x => x.parameter_id == this.parameter_id).length == 1) {
              tagsIds[i] = el.uniqueParameterValuesToTask.find(x => x.parameter_id == this.parameter_id).parameterValue.id
            }
          });
        }
      })
    }

    if (this.audioFiles && this.audioFiles.length) {
      this.audioFiles.forEach(col => {
        if (col.replaceArr.length > 0) {
          col.replaceArr.forEach((el, i) => {
            if (el && this.parameter_id && el.uniqueParameterValuesToTask && el.uniqueParameterValuesToTask.filter(x => x.parameter_id == this.parameter_id).length == 1) {
              tagsIds[i] = el.uniqueParameterValuesToTask.find(x => x.parameter_id == this.parameter_id).parameterValue.id
            }
          });
        }
      })
    }


    // if (is_upd_output) {
    //   this.updateOutput();
    // }

    return tagsIds
  }

  onInputFileName(e, i, j) {
    console.log("onInputFileName(e, i, j)", e, i, j)
    // console.log("onInputFileName this.outputObj", this.outputObj)
    this.videoFiles[j].replaceArr[i].changed_name = e.target.value;
  }

  updateOutput() {
    this.openedLines = [];
    this.outputObj = [];
    if (this.data.saveMode == 'concat') {
      let x:any;
      if (this.data.version == 'A') {
        x = {
          file_key: 'resultfile',
          output_filenames: [],
          parameter_values: [],
          operation_reminder_id: []
        }
      } else {
        x = {
          custom_id: 1,
          file_id: this.data.tracks[0].parts[0].id,
          output_filenames: [],
          parameter_values: [],
          operation_reminder_id: []
        }
      }

      if (this.videoFiles.filter(x => x.file_id == this.data.tracks[0].parts[0].id).length) {
        this.videoFiles.filter(x => x.file_id == this.data.tracks[0].parts[0].id).forEach(col => {
          if (col.replaceArr.length > 0) {
            col.replaceArr.forEach((item,i) => {
              if (this.selectedCols.includes(this.headCols[i])) {
                x.operation_reminder_id.push(this.operation_reminder_id);
                if (!item) {
                  item = col.original;
                  item.uniqueParameterValuesToTask = [];
                  if (item.parameterValuesToTask && item.parameterValuesToTask.length) {
                    item.parameterValuesToTask.forEach(x => {
                      if (item.uniqueParameterValuesToTask.filter(u => u.parameter_value_id == x.parameter_value_id).length == 0) {
                        item.uniqueParameterValuesToTask.push(x)
                      }
                    })
                  }
                }
                x.output_filenames.push(item.changed_name || item.filename);


                if (this.data.version == 'A') {
                  let u = item.uniqueParameterValuesToTask.filter(x => this.nestedTags.includes(x.parameter_id)).map(p => {return {parameter_value_id: p.parameter_value_id, is_primary: p.is_primary}});
                  let uniqueArray = Array.from(new Set(u.map(obj => obj.parameter_value_id))).map(id => u.find(obj => obj.parameter_value_id === id));
      
                  console.log(uniqueArray);
                  // let uniqueArray = [...new Set(u)];
                  x.parameter_values.push(uniqueArray);
                } else {
                  let u = item.uniqueParameterValuesToTask.filter(x => this.nestedTags.includes(x.parameter_id)).map(p => {return {id: p.parameter_value_id, is_primary: p.is_primary}});
                  let uniqueArray = Array.from(new Set(u.map(obj => obj.id))).map(id => u.find(obj => obj.id === id));
      
                  console.log(uniqueArray);
                  // let uniqueArray = [...new Set(u)];
                  x.parameter_values.push(uniqueArray);
                }
              }
            })
          }
        })
      }
      
      this.outputObj.push(x)
    } else {
      if (this.data.tracks.find(p => p.type == "video").parts.length) {
        this.data.tracks.find(p => p.type == "video").parts.forEach((part, partInd) => {
          if (this.data.version == 'A') {
            let x = {
              file_key: `resultfile${partInd + 1}`,
              output_filenames: [],
              parameter_values: [],
              operation_reminder_id: []
            }
            if (this.videoFiles.filter(x => x.file_id == part.id).length) {
              this.videoFiles.filter(x => x.file_id == part.id).forEach(col => {
                if (col.replaceArr.length > 0) {
                  col.replaceArr.forEach((item, i) => {
                    if (this.selectedCols.includes(this.headCols[i])) {
                      x.operation_reminder_id.push(this.operation_reminder_id);
                      if (!item) {
                        item = col.original;
                        item.uniqueParameterValuesToTask = [];
                        if (item.parameterValuesToTask && item.parameterValuesToTask.length) {
                          item.parameterValuesToTask.forEach(x => {
                            if (item.uniqueParameterValuesToTask.filter(u => u.parameter_value_id == x.parameter_value_id).length == 0) {
                              item.uniqueParameterValuesToTask.push(x)
                            }
                          })
                        }
                      }
                      x.output_filenames.push(item.changed_name || item.filename);
                      let u = item.uniqueParameterValuesToTask.filter(x => this.nestedTags.includes(x.parameter_id)).map(p => {return {parameter_value_id: p.parameter_value_id, is_primary: p.is_primary}});
                      let uniqueArray = Array.from(new Set(u.map(obj => obj.parameter_value_id))).map(id => u.find(obj => obj.parameter_value_id === id));
                      x.parameter_values.push(uniqueArray);
                      
                      // let vals = []
                      // x.parameter_values.forEach(arrObj => {
                      //   let arr = [];
                      //   arrObj.forEach(obj => {    
                      //     arr.push({
                      //       parameter_value_id: obj.id,
                      //       is_primary: obj.is_primary
                      //     })
                      //   });
                      //   vals.push(arr)
                      // })
                      // x.parameter_values = vals;
                    }
                  })
                }
              })
            }
            this.outputObj.push(x)
          } else {
            if (part.is_combine) {
              if (part.blends.length) {
                part.blends.forEach((blend, blendIndex) => {
         
                  let x = {
                    file_id: part.id,
                    custom_id: partInd + 1 + "0" + blendIndex,
                    output_filenames: [],
                    parameter_values: [],
                    operation_reminder_id: []
                  }
                  this.videoFiles.filter(x => x.file_id == part.id).forEach(col => {
                    col.replaceArr.forEach((item,i) => {
                      if (this.selectedCols.includes(this.headCols[i])) {
                        x.operation_reminder_id.push(this.operation_reminder_id);
                        if (!item) {
                          item = col.original;
                          item.uniqueParameterValuesToTask = [];
                          item.parameterValuesToTask.forEach(x => {
                            if (item.uniqueParameterValuesToTask.filter(u => u.parameter_value_id == x.parameter_value_id).length == 0) {
                              item.uniqueParameterValuesToTask.push(x)
                            }
                          })
                        }
      
                        x.output_filenames.push(item.changed_name || item.filename);
                        let u = item.uniqueParameterValuesToTask.filter(x => this.nestedTags.includes(x.parameter_id)).map(p => {return {id: p.parameter_value_id, is_primary: p.is_primary}});
                        let uniqueArray = Array.from(new Set(u.map(obj => obj.id))).map(id => u.find(obj => obj.id === id));
                        x.parameter_values.push(uniqueArray);
                      }
                    })
                  })
                  this.outputObj.push(x)
                })
              }
            } else {
              let x = {
                file_id: part.id,
                custom_id: partInd + 1,
                output_filenames: [],
                parameter_values: [],
                operation_reminder_id: []
              }
              if (this.videoFiles.filter(x => x.file_id == part.id).length) {
                this.videoFiles.filter(x => x.file_id == part.id).forEach(col => {
                  if (col.replaceArr.length > 0) {
                    col.replaceArr.forEach((item, i) => {
                      if (this.selectedCols.includes(this.headCols[i])) {
                        x.operation_reminder_id.push(this.operation_reminder_id);
                        if (!item) {
                          item = col.original;
                          item.uniqueParameterValuesToTask = [];
                          if (item.parameterValuesToTask && item.parameterValuesToTask.length) {
                            item.parameterValuesToTask.forEach(x => {
                              if (item.uniqueParameterValuesToTask.filter(u => u.parameter_value_id == x.parameter_value_id).length == 0) {
                                item.uniqueParameterValuesToTask.push(x)
                              }
                            })
                          }
                        }
                        x.output_filenames.push(item.changed_name || item.filename);
                        let u = item.uniqueParameterValuesToTask.filter(x => this.nestedTags.includes(x.parameter_id)).map(p => {return {id: p.parameter_value_id, is_primary: p.is_primary}});
                        let uniqueArray = Array.from(new Set(u.map(obj => obj.id))).map(id => u.find(obj => obj.id === id));
                        x.parameter_values.push(uniqueArray);
                      }
                    })
                  }
                })
              }
              this.outputObj.push(x)
            }
          }
        })
      }
    }
  }

  getMaximumColItems() {
    let is_vid_length = true;
    let maxLength = 0;
    let index = 0;

    if (this.videoFiles.length) {
      this.videoFiles.forEach((col,i) => {
        if (col.replaceArr.length > maxLength) {
          maxLength = col.replaceArr.length;
          index = i;
        }
      })
    }

    if (this.audioFiles.length) {
      this.audioFiles.forEach((col,i) => {
        if (col.replaceArr.length > maxLength) {
          is_vid_length = false
          maxLength = col.replaceArr.length;
          index = i;
        }
      })
    }

    let returnedArr;
    if (is_vid_length) {
      returnedArr = this.videoFiles[index]
    } else {
      returnedArr = this.audioFiles[index]
    }

    returnedArr.replaceArr.forEach(element => {
      if (this.parameter_id && element.uniqueParameterValuesToTask && element.uniqueParameterValuesToTask.filter(x => x.parameter_id == this.parameter_id).length == 1) {
        element.tag_id = element.uniqueParameterValuesToTask.filter(x => x.parameter_id == this.parameter_id)[0].parameterValue.id
      }
    });

    return returnedArr;
  }

  onSearchParams(resp) {
    if (!this.parameters) {
      return;
    }

    if (!resp) {
      this.parameters$.next(this.parameters.filter(z => !!z.id).slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.parameters$.next(
      this.parameters.filter(z => !!z.id && (z.name.toLowerCase().indexOf(resp) > -1))
    );
  }

  onSearchChecks(resp) {
    if (!this.checklists) {
      return;
    }

    if (!resp) {
      this.checklists$.next(this.checklists.filter(z => !!z.id).slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.checklists$.next(
      this.checklists.filter(z => !!z.id && (z.text.toLowerCase().indexOf(resp) > -1))
    );
  }

  deleteLine(tId, i) {
    this.videoFiles.forEach(col => {
      col.replaceArr.splice(i, 1)
    })
    if (this.parameter_id) {
      this.headCols = this.getLineTags();
      if (!this.selectedCols) {
        this.selectedCols = [...this.headCols];
      } else {
        if (this.selectedCols.includes(tId)) {
          this.selectedCols.splice(this.selectedCols.findIndex(u => u == tId), 1)
        }
      }
      this.updateOutput();
      console.log("this.headCols", this.headCols)

      if (this.headCols.length != 0) {
        this.videoFiles.forEach(col => {
          if (col.replaceArr.length > this.headCols.length) {
            col.replaceArr = col.replaceArr.slice(0, this.headCols.length)
          }
        })
    
        this.audioFiles.forEach(col => {
          if (col.replaceArr.length > this.headCols.length) {
            col.replaceArr = col.replaceArr.slice(0, this.headCols.length)
          }
        })
      } else {
        this.videoFiles.forEach(el => {
          el.replaceArr = []
        })
        this.audioFiles.forEach(el => {
          el.replaceArr = []
        })
      }
    }
    this.isFormChange = true;
  }

  deleteEl(item, i) {
    item.replaceArr[i] = null;
    let allNull:boolean = true;

    this.videoFiles.forEach(col => {
      if (!!col.replaceArr[i]) {
        allNull = false;
      }
    })

    if (allNull) {
      this.videoFiles.forEach(col => {
        col.replaceArr.splice(i, 1)
      })
      
    }

    if (this.parameter_id) {
      this.headCols = this.getLineTags();
      console.log("this.headCols", this.headCols)
      this.updateOutput();
      if (this.headCols.length != 0) {
        this.videoFiles.forEach(col => {
          if (col.replaceArr.length > this.headCols.length) {
            col.replaceArr = col.replaceArr.slice(0, this.headCols.length)
          }
        })
    
        this.audioFiles.forEach(col => {
          if (col.replaceArr.length > this.headCols.length) {
            col.replaceArr = col.replaceArr.slice(0, this.headCols.length)
          }
        })
      } else {
        this.videoFiles.forEach(el => {
          el.replaceArr = []
        })
        this.audioFiles.forEach(el => {
          el.replaceArr = []
        })
      }
    }

    this.isFormChange = true;

  }

  selectFile(only, item) {
    console.log("selectFile", item, only)
    let x:any = {
      company: this.data.company,
      task: this.data.task,
      work: this.data.work,
      user: this.data.user,
      originalFile: item.original,
      only: only,
      fromVE: true,
      is_multi: true,
      selectedFiles: []
    }

    const dialogRef = this.dialog.open(SelectFileComponent, {
      data: x
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log("result", result)
        if (!!result && result.event == 'select' && result.data && result.data.length) {
         
          result.data.forEach(element => {
            element.uniqueParameterValuesToTask = [];
            element.parameterValuesToTask.forEach(x => {
              if (element.uniqueParameterValuesToTask.filter(u => u.parameter_value_id == x.parameter_value_id).length == 0) {
                element.uniqueParameterValuesToTask.push(x)
              }
            })
          });

          if (this.headCols) {
            
            let oldCols = JSON.parse(JSON.stringify(this.headCols));
            if (result.data.length != result.data.filter(u => !!u && !!u.uniqueParameterValuesToTask && u.uniqueParameterValuesToTask.filter(x => x.parameter_id == this.parameter_id).length == 1).length) {
              this.layoutService.showSnackBar({name: ''}, marker("One or more files were not added because they don't contain the selected category tags or contain more than one tag from same category."), SnackBarItem);
              result.data = result.data.filter(u => !!u && !!u.uniqueParameterValuesToTask && u.uniqueParameterValuesToTask.filter(x => x.parameter_id == this.parameter_id).length == 1);
            }

            if (result.data.length == 0) {
              return
            }

            // let forCycle = result.data.length;

            // let beforeItemRArrLength = item.replaceArr.length;

            // for (let index = 0; index < forCycle; index++) {
            //   if (this.headCols.replaceArr[beforeItemRArrLength + index]) {
            //     let tag_id = this.headCols.replaceArr[beforeItemRArrLength + index].tag_id;
            //     if (tag_id) {
            //       if (result.data.filter(u => u.uniqueParameterValuesToTask.filter(x => x.parameter_value_id == tag_id).length == 1).length == 1) {
            //         item.replaceArr.push(result.data.find(u => u.uniqueParameterValuesToTask.filter(x => x.parameter_value_id == tag_id).length == 1));
            //       } else {
            //         item.replaceArr[beforeItemRArrLength + index] = item.original;
            //       }
            //     } else {
            //       item.replaceArr[beforeItemRArrLength + index] = item.original;
            //     }
            //   }
            // }

            item.replaceArr = item.replaceArr.filter(x => !!x);
            result.data.forEach(el => {  
              if (item.replaceArr.filter(u => u.id == el.id).length == 0) {
                item.replaceArr.push(el)
              }
            });

            let tIds = this.headCols.slice()
            console.log("tIds",tIds);

            let newReplArr = [];
            tIds.forEach((tId,index) => {
              if (item.replaceArr.filter(u => u.uniqueParameterValuesToTask.filter(x => x.parameter_value_id == tId).length == 1).length == 1) {
                newReplArr[index] = item.replaceArr.find(u => u.uniqueParameterValuesToTask.filter(x => x.parameter_value_id == tId).length == 1)
              } else {
                newReplArr[index] = null
              }
            });

            item.replaceArr.forEach(el => {  
              if (newReplArr.filter(u => !!u && u.id == el.id).length == 0) {
                newReplArr.push(el)
              }
            });

            item.replaceArr = newReplArr;

            if (this.parameter_id) {
              this.headCols = this.getLineTags();

              if (this.headCols.length != oldCols.length) {
                this.headCols.forEach(tagID => {
                  if (!oldCols.includes(tagID)) {
                    this.selectedCols.push(tagID)
                  }
                })
              }
              console.log("this.headCols", this.headCols)
              this.updateOutput();
            }

            this.videoFiles.forEach(col => {
              if (col.replaceArr.length < this.headCols.length) {
                let counters = this.headCols.length - col.replaceArr.length

                for (let index = 0; index < counters; index++) {
                  col.replaceArr.push(null)
                }
              }
            })

            this.audioFiles.forEach(col => {
              if (col.replaceArr.length < this.headCols.length) {
                let counters = this.headCols.length - col.replaceArr.length

                for (let index = 0; index < counters; index++) {
                  col.replaceArr.push(null)
                }
              }
            })
            // let arrAfter = [];
            // result.data.forEach(el => {  
            //   if (item.replaceArr.filter(u => u.id == el.id).length == 0) {
            //     if (this.headCols.replaceArr[item.replaceArr.length].tag_id && el.parameterValuesToTask.filter(x => x.parameter_value_id == this.headCols.replaceArr[item.replaceArr.length].tag_id).length == 1) {
            //       item.replaceArr.push(el)
            //     } else {
            //       arrAfter.push(el)
            //     }
            //   }
            // });

            // if (arrAfter.length) {
            //   item.replaceArr.push(...arrAfter)

            //   if (this.parameter_id) {
            //     this.headCols = this.getMaximumColItems();
            //     console.log("this.headCols", this.headCols)
            //   }
            // }
          } else {

            if (this.parameter_id && result.data.length != result.data.filter(u => !!u && !!u.uniqueParameterValuesToTask && u.uniqueParameterValuesToTask.filter(x => x.parameter_id == this.parameter_id).length == 1).length) {
              this.layoutService.showSnackBar({name: ''}, marker("One or more files were not added because they don't contain the selected category tags or contain more than one tag from same category."), SnackBarItem);
              result.data = result.data.filter(u => !!u && !!u.uniqueParameterValuesToTask && u.uniqueParameterValuesToTask.filter(x => x.parameter_id == this.parameter_id).length == 1);
            }

            result.data.forEach(el => {  
              if (item.replaceArr.filter(u => !!u && u.id == el.id).length == 0) {
                item.replaceArr.push(el)
              }
            });

            if (this.parameter_id) {
              this.headCols = this.getLineTags();
              this.selectedCols = [...this.headCols];
              this.updateOutput();
              console.log("this.headCols", this.headCols)
            }
          }

          this.isFormChange = true;
        }
      })
    )
  }

  findMatches(query: string, data) {
    const queryTokens = query.toLowerCase().split(/[\s+\-_]+/); // Разбиваем строку запроса на отдельные токены
    
    console.log("findMatches queryTokens", queryTokens);
    let maxMatches = 0;
    let matchingItems = [];

    for (const item of data) {
        let matches = 0;
        const itemNameTokens = item.filename.toLowerCase().split(/[\s+\-_]+/); // Разбиваем имя элемента на токены

        for (const token of queryTokens) {
            if (itemNameTokens.includes(token)) { // Если токен из запроса есть в имени элемента, увеличиваем счетчик совпадений
                matches++;
            }
        }
        if (matches > maxMatches) {
          maxMatches = matches;
        }
        // if (matches > maxMatches) { // Если текущий элемент имеет больше совпадений, чем предыдущие элементы, обновляем максимальное число совпадений и список совпадающих элементов
        //     maxMatches = matches;
        //     matchingItems = [item];
        // } else if (matches === maxMatches) { // Если текущий элемент имеет такое же количество совпадений, добавляем его в список совпадающих элементов
        // }
        item.matches = matches;
        matchingItems.push(item);
    }

    return {matchingItems, maxMatches};
    // return {matchingItems: matchingItems.map(u => {
    //   return {
    //     filename: u.filename, 
    //     matches: u.matches
    //   }
    // }), maxMatches};
  }

  openReplacementsSettings() {
    const dialogRef = this.dialog.open(ReplacementsSettingsComponent, {
      data: {
        replSettings: this.replSettings
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log("result", result)
        console.log("this.replSettings", this.replSettings)
        if (result) {
          if (result.event == 'find') {
            this.getReplacements()
          } else if (result.event == 'alt_find') {
            this.getAltReplacements()
          }
        }
      })
    )
  }

  getAltReplacements() {
    this.attachSubscriptions(
      (!!this.replSettings.is_all_target ? this.fileService.getFileDataWithTags('1', this.data.company.id, {is_dir: 0, exclude_root_dirs: 'trash', task_id: this.data.project.task_id, task_operation_id: this.data.project.task_operation_id, location_all: '/'}, '1') : this.fileService.getFileDataWithTags('1', this.data.company.id, {is_dir: 0, exclude_root_dirs: 'trash', task_id: this.data.project.task_id, task_operation_id: this.data.project.task_operation_id, [this.replSettings.is_location_all ? 'location_all' : 'location']: this.replSettings.folder}, '1')).pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 100)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }
          return forkJoin(arr.map(x => (!!this.replSettings.is_all_target ? this.fileService.getFileDataWithTags(x, this.data.company.id, {is_dir: 0, exclude_root_dirs: 'trash', task_id: this.data.project.task_id, task_operation_id: this.data.project.task_operation_id, location_all: '/'}, '100') : this.fileService.getFileDataWithTags(x, this.data.company.id, {is_dir: 0, exclude_root_dirs: 'trash', task_id: this.data.project.task_id, task_operation_id: this.data.project.task_operation_id, [this.replSettings.is_location_all ? 'location_all' : 'location']: this.replSettings.folder}, '100')).pipe(map(u => u.body)))).pipe(
            last(),
            map(values => {
              return [].concat(...values);
            })
          )
        }),
      ).subscribe(resp => {
        console.log("getAltReplacements", resp);
        this.videoFiles.forEach(item => {
          let results = JSON.parse(JSON.stringify(resp.filter(p => p.id != item.original.id && p.original_file_id != item.original.id), this.getCircularReplacer()))
          let x = this.findMatches(item.original.filename.substring(0, item.original.filename.lastIndexOf('_')), results)
          console.log("findMatches x", x);
          let replFiles = x.matchingItems.filter(u => u.matches >= x.maxMatches - 1);
 
          if (replFiles && replFiles.length) {
            replFiles.forEach(element => {
              element.uniqueParameterValuesToTask = [];
              if (element.parameterValuesToTask && element.parameterValuesToTask.length) {
                element.parameterValuesToTask.forEach(x => {
                  if (element.uniqueParameterValuesToTask.filter(u => u.parameter_value_id == x.parameter_value_id).length == 0) {
                    element.uniqueParameterValuesToTask.push(x)
                  }
                })
              }
            });
          }

          if (this.headCols) {
            let oldCols = JSON.parse(JSON.stringify(this.headCols));
            if (replFiles.length != replFiles.filter(u => !!u && !!u.uniqueParameterValuesToTask && u.uniqueParameterValuesToTask.filter(x => x.parameter_id == this.parameter_id).length == 1).length) {
              this.layoutService.showSnackBar({name: ''}, marker("One or more files were not added because they don't contain the selected category tags or contain more than one tag from same category."), SnackBarItem);
              replFiles = replFiles.filter(u => !!u && !!u.uniqueParameterValuesToTask && u.uniqueParameterValuesToTask.filter(x => x.parameter_id == this.parameter_id).length == 1);
            }

            if (replFiles.length == 0) {
              return
            }

            item.replaceArr = item.replaceArr.filter(x => !!x);
            if (replFiles && replFiles.length) {
              replFiles.forEach(el => {  
                if (item.replaceArr.filter(u => u.id == el.id).length == 0) {
                  item.replaceArr.push(el)
                }
              });
            }

            let tIds = this.headCols.slice()
            console.log("tIds",tIds);

            let newReplArr = [];
            if (tIds && tIds.length) {
              tIds.forEach((tId,index) => {
                if (item.replaceArr.filter(u => u.uniqueParameterValuesToTask.filter(x => x.parameter_value_id == tId).length == 1).length == 1) {
                  newReplArr[index] = item.replaceArr.find(u => u.uniqueParameterValuesToTask.filter(x => x.parameter_value_id == tId).length == 1)
                } else {
                  newReplArr[index] = null
                }
              });
            }

            if (item.replaceArr && item.replaceArr.length) {   
              item.replaceArr.forEach(el => {  
                if (newReplArr.filter(u => !!u && u.id == el.id).length == 0) {
                  newReplArr.push(el)
                }
              });
            }


            item.replaceArr = newReplArr;

            if (this.parameter_id) {
              this.headCols = this.getLineTags();
              console.log("this.headCols", this.headCols)

              if (this.headCols.length != oldCols.length) {
                this.headCols.forEach(tagID => {
                  if (!oldCols.includes(tagID)) {
                    this.selectedCols.push(tagID)
                  }
                })
              }
              this.updateOutput();
            }

            if (this.videoFiles && this.videoFiles.length) {
              this.videoFiles.forEach(col => {
                if (col.replaceArr.length < this.headCols.length) {
                  let counters = this.headCols.length - col.replaceArr.length
  
                  for (let index = 0; index < counters; index++) {
                    col.replaceArr.push(null)
                  }
                }
              })
            }

            if (this.audioFiles && this.audioFiles.length) {
              this.audioFiles.forEach(col => {
                if (col.replaceArr.length < this.headCols.length) {
                  let counters = this.headCols.length - col.replaceArr.length
  
                  for (let index = 0; index < counters; index++) {
                    col.replaceArr.push(null)
                  }
                }
              })
            }
          } else {
            if (this.parameter_id && replFiles.length != replFiles.filter(u => !!u && !!u.uniqueParameterValuesToTask && u.uniqueParameterValuesToTask.filter(x => x.parameter_id == this.parameter_id).length == 1).length) {
              this.layoutService.showSnackBar({name: ''}, marker("One or more files were not added because they don't contain the selected category tags or contain more than one tag from same category."), SnackBarItem);
              replFiles = replFiles.filter(u => !!u && !!u.uniqueParameterValuesToTask && u.uniqueParameterValuesToTask.filter(x => x.parameter_id == this.parameter_id).length == 1);
            }

            if (replFiles && replFiles.length) {
              replFiles.forEach(el => {  
                if (item.replaceArr.filter(u => !!u && u.id == el.id).length == 0) {
                  item.replaceArr.push(el)
                }
              });
            }

            if (this.parameter_id) {
              this.headCols = this.getLineTags();
              this.selectedCols = [...this.headCols];
              this.updateOutput();
              console.log("this.headCols", this.headCols)
            }
          }

          this.isFormChange = true;
          console.log(item.original.filename, replFiles)
          console.log(item.original.filename, item.replaceArr)
        })
      })
    )
  }

  getCircularReplacer() {
    const ancestors:any = [];
    return function (key, value) {
      if (typeof value !== "object" || value === null) {
        return value;
      }
      // `this` is the object that value is contained in,
      // i.e., its direct parent.
      while (ancestors.length > 0 && ancestors.at(-1) !== this) {
        ancestors.pop();
      }
      if (ancestors.includes(value)) {
        return "[Circular]";
      }
      ancestors.push(value);
      return value;
    };
  }

  getReplacements() {
    this.isGettingReplacements = true;
  
    let count = 1;
    this.attachSubscriptions(
      concat(...this.videoFiles.map(item => (!!this.replSettings.is_all_target ? this.fileService.getFileDataWithTags('1', this.data.company.id, {is_dir: 0, exclude_root_dirs: 'trash', q: item.original.filename.substring(0, item.original.filename.lastIndexOf('_')), task_id: this.data.project.task_id, task_operation_id: this.data.project.task_operation_id, location_all: '/'}) : this.fileService.getFileDataWithTags('1', this.data.company.id, {is_dir: 0, exclude_root_dirs: 'trash', q: item.original.filename.substring(0, item.original.filename.lastIndexOf('_')), task_id: this.data.project.task_id, task_operation_id: this.data.project.task_operation_id, [this.replSettings.is_location_all ? 'location_all' : 'location']: this.replSettings.folder})).pipe(
        map(u => u.body),
        tap(vals => {
          let replFiles = vals.filter(p => p.id != item.original.id && p.original_file_id != item.original.id);
          if (replFiles && replFiles.length) {
            replFiles.forEach(element => {
              element.uniqueParameterValuesToTask = [];
              if (element.parameterValuesToTask && element.parameterValuesToTask.length) {
                element.parameterValuesToTask.forEach(x => {
                  if (element.uniqueParameterValuesToTask.filter(u => u.parameter_value_id == x.parameter_value_id).length == 0) {
                    element.uniqueParameterValuesToTask.push(x)
                  }
                })
              }
            });
          }

          if (this.headCols) {
            
            let oldCols = JSON.parse(JSON.stringify(this.headCols));
            if (replFiles.length != replFiles.filter(u => !!u && !!u.uniqueParameterValuesToTask && u.uniqueParameterValuesToTask.filter(x => x.parameter_id == this.parameter_id).length == 1).length) {
              this.layoutService.showSnackBar({name: ''}, marker("One or more files were not added because they don't contain the selected category tags or contain more than one tag from same category."), SnackBarItem);
              replFiles = replFiles.filter(u => !!u && !!u.uniqueParameterValuesToTask && u.uniqueParameterValuesToTask.filter(x => x.parameter_id == this.parameter_id).length == 1);
            }

            if (replFiles.length == 0) {
              return
            }

            // let forCycle = replFiles.length;

            // let beforeItemRArrLength = item.replaceArr.length;

            // for (let index = 0; index < forCycle; index++) {
            //   if (this.headCols.replaceArr[beforeItemRArrLength + index]) {
            //     let tag_id = this.headCols.replaceArr[beforeItemRArrLength + index].tag_id;
            //     if (tag_id) {
            //       if (replFiles.filter(u => u.uniqueParameterValuesToTask.filter(x => x.parameter_value_id == tag_id).length == 1).length == 1) {
            //         item.replaceArr.push(replFiles.find(u => u.uniqueParameterValuesToTask.filter(x => x.parameter_value_id == tag_id).length == 1));
            //       } else {
            //         item.replaceArr[beforeItemRArrLength + index] = item.original;
            //       }
            //     } else {
            //       item.replaceArr[beforeItemRArrLength + index] = item.original;
            //     }
            //   }
            // }

            item.replaceArr = item.replaceArr.filter(x => !!x);
            if (replFiles && replFiles.length) {
              replFiles.forEach(el => {  
                if (item.replaceArr.filter(u => u.id == el.id).length == 0) {
                  item.replaceArr.push(el)
                }
              });
            }

            let tIds = this.headCols.slice()
            console.log("tIds",tIds);

            let newReplArr = [];
            if (tIds && tIds.length) {
              tIds.forEach((tId,index) => {
                if (item.replaceArr.filter(u => u.uniqueParameterValuesToTask.filter(x => x.parameter_value_id == tId).length == 1).length == 1) {
                  newReplArr[index] = item.replaceArr.find(u => u.uniqueParameterValuesToTask.filter(x => x.parameter_value_id == tId).length == 1)
                } else {
                  newReplArr[index] = null
                }
              });
            }

            if (item.replaceArr && item.replaceArr.length) {   
              item.replaceArr.forEach(el => {  
                if (newReplArr.filter(u => !!u && u.id == el.id).length == 0) {
                  newReplArr.push(el)
                }
              });
            }


            item.replaceArr = newReplArr;

            if (this.parameter_id) {
              this.headCols = this.getLineTags();
              if (this.headCols.length != oldCols.length) {
                this.headCols.forEach(tagID => {
                  if (!oldCols.includes(tagID)) {
                    this.selectedCols.push(tagID)
                  }
                })
              }
              this.updateOutput();
              console.log("this.headCols", this.headCols)
            }

            if (this.videoFiles && this.videoFiles.length) {
              this.videoFiles.forEach(col => {
                if (col.replaceArr.length < this.headCols.length) {
                  let counters = this.headCols.length - col.replaceArr.length
  
                  for (let index = 0; index < counters; index++) {
                    col.replaceArr.push(null)
                  }
                }
              })
            }

            if (this.audioFiles && this.audioFiles.length) {
              this.audioFiles.forEach(col => {
                if (col.replaceArr.length < this.headCols.length) {
                  let counters = this.headCols.length - col.replaceArr.length
  
                  for (let index = 0; index < counters; index++) {
                    col.replaceArr.push(null)
                  }
                }
              })
            }
            // let arrAfter = [];
            // replFiles.forEach(el => {  
            //   if (item.replaceArr.filter(u => u.id == el.id).length == 0) {
            //     if (this.headCols.replaceArr[item.replaceArr.length].tag_id && el.parameterValuesToTask.filter(x => x.parameter_value_id == this.headCols.replaceArr[item.replaceArr.length].tag_id).length == 1) {
            //       item.replaceArr.push(el)
            //     } else {
            //       arrAfter.push(el)
            //     }
            //   }
            // });

            // if (arrAfter.length) {
            //   item.replaceArr.push(...arrAfter)

            //   if (this.parameter_id) {
            //     this.headCols = this.getMaximumColItems();
            //     console.log("this.headCols", this.headCols)
            //   }
            // }
          } else {
            if (this.parameter_id && replFiles.length != replFiles.filter(u => !!u && !!u.uniqueParameterValuesToTask && u.uniqueParameterValuesToTask.filter(x => x.parameter_id == this.parameter_id).length == 1).length) {
              this.layoutService.showSnackBar({name: ''}, marker("One or more files were not added because they don't contain the selected category tags or contain more than one tag from same category."), SnackBarItem);
              replFiles = replFiles.filter(u => !!u && !!u.uniqueParameterValuesToTask && u.uniqueParameterValuesToTask.filter(x => x.parameter_id == this.parameter_id).length == 1);
            }

            if (replFiles && replFiles.length) {
              replFiles.forEach(el => {  
                if (item.replaceArr.filter(u => !!u && u.id == el.id).length == 0) {
                  item.replaceArr.push(el)
                }
              });
            }

            if (this.parameter_id) {
              this.headCols = this.getLineTags();
              this.selectedCols = [...this.headCols];
              this.updateOutput();
              console.log("this.headCols", this.headCols)
            }
          }

          this.isFormChange = true;
          console.log(item.original.filename, replFiles)
          console.log(item.original.filename, item.replaceArr)
        }),
        catchError(error => {
          console.log(error)
          return of(error)
        })
      ))).pipe(
        tap(el => {
          this.ls.requests$.next({
            value: Math.round((100 / this.videoFiles.length) * count),
            target: "Getting replacements" 
          })
          count++;
        })
      ).subscribe(resp => {
        console.log('getReplacements res', resp)
        console.log('getReplacements result', this.videoFiles)
        this.isGettingReplacements = false;
      }, error => {
        this.isGettingReplacements = false;
        this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
      })
    )
  }
  
  log() {
    console.log("videoFiles", this.videoFiles)
    console.log("audioFiles", this.audioFiles)
    console.log("parameter_id", this.parameter_id)
    console.log("outputObj", this.outputObj)
    console.log("openedLines", this.openedLines)
    console.log("this.headCols", this.headCols)
    console.log("this.selectedCols", this.selectedCols)
  }

  close() {
    this.dialogRef.close({event: "close", data: false});
  }

  submitForm() {
    this.isSubmit = true;

    let notAll = []

    this.selectedCols.forEach(sC => {
      let ind = this.headCols.findIndex(x => x == sC);
      this.videoFiles.forEach(x => {
        if (!x.replaceArr[ind] && !notAll.includes(sC)) {
          notAll.push(sC)
        }
      })
    })

    if (notAll.length) {
      let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
        hasBackdrop: true,
        backdropClass: 'bottom-sheed-backdrop',
        data: {
          btn_yes: "Ok",
          msg: `One or more files replacements are missing for Automation`,
          msg_1: `Videos with missing replacements won't exported.`,
          not_show_no: true
        }
      });
  
      deleteAlert.afterDismissed().subscribe( data => {
        if (data && data.message == 'no') {
          console.log("no");
          return
        } else if (data && data.message == 'yes') {
          notAll.forEach(tId => {
            this.selectedCols.splice(this.selectedCols.findIndex(u => u == tId), 1)
          });
          
          this.updateOutput();
          // this.layoutService.showSnackBar({name: 'One or more files replacements are missing for Automation'}, marker("One or more files were not added because they don't contain the selected category tags or contain more than one tag from same category."), SnackBarItem);
          this.submitForm();
          return
        }
      });
    

    }

    let data = {
      batch_data: {
        parameter_id: this.parameter_id,
        headCols: this.headCols,
        selectedCols: this.selectedCols,
        nestedTags: this.nestedTags,
        operation_reminder_id: this.operation_reminder_id,
        output_video_project_files: this.outputObj,
        all_input_video_project_files: [],
        input_video_project_files: []
      }
    }



    this.videoFiles.filter(u => !!u.replaceArr.filter(u => !!u).length).forEach(element => {
      let files_ids = [];
      element.replaceArr.forEach((x,i) => {
        if (this.selectedCols.includes(this.headCols[i])) {
          files_ids.push(!!x ? x.id : element.file_id)
        }
      })
      if (this.data.version == 'A') {
        data.batch_data.input_video_project_files.push({
          file_key: `file_${element.original.original_file_id}`,
          file_id: element.file_id,
          files_ids: files_ids
        })
        data.batch_data.all_input_video_project_files.push({
          file_key: `file_${element.original.original_file_id}`,
          file_id: element.file_id,
          files_ids: element.replaceArr.map(x => !!x ? x.id : element.file_id)
        })
      } else {

        data.batch_data.input_video_project_files.push({
          file_id: element.file_id,
          files_ids: files_ids
        })
        data.batch_data.all_input_video_project_files.push({
          file_id: element.file_id,
          files_ids: element.replaceArr.map(x => !!x ? x.id : element.file_id)
        })
      }
    });

    this.audioFiles.filter(u => !!u.replaceArr.filter(u => !!u).length).forEach(element => {
      let files_ids = [];
      element.replaceArr.forEach((x,i) => {
        if (this.selectedCols.includes(this.headCols[i])) {
          files_ids.push(!!x ? x.id : element.file_id)
        }
      })
      if (this.data.version == 'A') {
        data.batch_data.input_video_project_files.push({
          file_key: `file_${element.original.original_file_id}`,
          file_id: element.file_id,
          files_ids: files_ids
        })
        data.batch_data.all_input_video_project_files.push({
          file_key: `file_${element.original.original_file_id}`,
          file_id: element.file_id,
          files_ids: element.replaceArr.map(x => !!x ? x.id : element.file_id)
        })
      } else {
        data.batch_data.input_video_project_files.push({
          file_id: element.file_id,
          files_ids: files_ids
        })
        data.batch_data.all_input_video_project_files.push({
          file_id: element.file_id,
          files_ids: element.replaceArr.map(x => !!x ? x.id : element.file_id)
        })
      }
    });

    this.attachSubscriptions(
      this.fileService.editVideoProject(this.data.project.id, data, this.data.company.id).subscribe(resp => {
        this.isSubmit = false;
        this.dialogRef.close({event: "save", data: resp})
      })
    )
  }

  deleteAuto() {
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: this.data.project,
        target: marker("auto projects")
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        this.attachSubscriptions(
          this.fileService.editVideoProject(this.data.project.id, {
            batch_data: ''
          }, this.data.company.id).subscribe(resp => {
            this.isSubmitAuto = false;
            this.dialogRef.close({event: "save", data: resp})
          })
        )
      }
    });
  }
  
  exportProjects() {
    this.isSubmitAuto = true;
    let data = {
      is_batch_apply: 1
    }

    this.attachSubscriptions(
      this.fileService.editVideoProject(this.data.project.id, data, this.data.company.id).subscribe(resp => {
        this.isSubmitAuto = false;
        this.dialogRef.close({event: "save", data: resp})
      })
    )
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
