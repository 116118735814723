<mat-dialog-content class="mat-typography">
    <div class="container form_wrapper" [appDeviceClass]="'custom-scroll custom-scroll-t'">
        <div class="title_modal d-f-c">
            <span class="set_width">
                {{ "File rules for" | translate }}:
            </span>
            <ng-container *ngIf="data?.is_partner; else elseTemplateIsPartner">
                <app-user [class]="'user_div ml-1'" [companyEmployee]="data?.employee?.partnerCompany" [imgRoute]="imgRoute" [host]="host" [company]="data?.company"></app-user>
            </ng-container>
            <ng-template #elseTemplateIsPartner>
                <app-user [class]="'user_div ml-1'" [employee]="data?.employee"></app-user>
            </ng-template>
        </div>

        <ng-container *ngIf="!data?.is_partner; else elseTemplatePartnerWorkspace">
            <div class="panel_form">
                <form [formGroup]="form" class="form_with_pads form_with_valids">
                    <div class="form_panel">
                        <mat-button-toggle-group class="form_btn_grp" formControlName="type">
                            <mat-button-toggle [value]="0">{{"This workspace" | translate}}</mat-button-toggle>
                            <mat-button-toggle [value]="1">{{"Partner workspace" | translate}}</mat-button-toggle>
                        </mat-button-toggle-group>
                        <div class="tmpl_val" *ngIf="form.get('type').value == 1">
                            <div (click)="selectWorkspace.open()">
                                <ng-container *ngIf="form.get('partner_company_id').value; else elseTemplateSelected">
                                    <app-user [class]="'user_div'" [companyEmployee]="getPartnerById(form.get('partner_company_id').value)?.partnerCompany" [imgRoute]="imgRoute" [host]="host" [company]="data?.company"></app-user>
                                </ng-container>
                                <ng-template #elseTemplateSelected>
                                    {{"Select Partner" | translate}}
                                </ng-template>
                                <mat-icon>arrow_drop_down</mat-icon>
                            </div>
                            <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                                <mat-select disableOptionCentering #selectWorkspace formControlName="partner_company_id">
                                    <mat-option [value]="''">
                                        <div class="user_div">
                                            <img src="" alt="" style="visibility: hidden;">
                                            <span>{{ 'All' | translate }}</span>
                                        </div>
                                    </mat-option>
                                    <mat-option *ngFor="let partner of partners" [value]="partner.partner_company_id">
                                        <app-user [class]="'user_div'" [companyEmployee]="partner?.partnerCompany" [imgRoute]="imgRoute" [host]="host" [company]="data?.company"></app-user>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </form>
                <div class="form_in">
                    <form [formGroup]="form" class="">   
                        <mat-nav-list *ngIf="emplRules?.length">
                            <mat-list-item *ngFor="let emRule of emplRules; let i = index;" class="rule_out_item">
                                <div class="rule_item">
                                    <div class="d-f-c">
                                        <ng-container *ngIf="checkIsGlobal(emRule); else elseTemplateIsGlob">
                                            <span>(ID: {{emRule.id}}) {{ "For all files" | translate }}</span>
                                        </ng-container>
                                        <ng-template #elseTemplateIsGlob>
                                            <span>(ID: {{emRule.id}}) {{ "For target" | translate }}:</span>
                                            <span *ngIf="emRule.group_id">G id: {{emRule.group_id}}</span>
                                            <span *ngIf="emRule.task_id">T id: {{emRule.task_id}}</span>
                                            <span *ngIf="emRule.task_operation_id">J id: {{emRule.task_operation_id}}</span>
                                            <span *ngIf="emRule.discussion_id">C id: {{emRule.discussion_id}}</span>
                                            <span *ngIf="emRule.file_id">F id: {{emRule.file_id}}</span>
                                        </ng-template>
                                        <div class="d-f-c ml-5" *ngIf="form.get('type').value == 1 && (!form.get('partner_company_id') || !form.get('partner_company_id').value) && !!emRule.partner_company_id">
                                            <span class="mr-5">{{ 'in' | translate}}</span>
                                            <app-user [class]="'user_div'" [companyEmployee]="getPartnerById(emRule.company_id)?.partnerCompany" [imgRoute]="imgRoute" [host]="host" [company]="data?.company"></app-user>
                                        </div>
                                    </div>
                                    <mat-icon style="color: red;" (click)="deleteFileRule(emRule.id, i)">delete</mat-icon>
                                </div>
                            </mat-list-item>
                        </mat-nav-list>
                    </form>
                </div>
            </div>

        </ng-container>
        <ng-template #elseTemplatePartnerWorkspace>
            <form [formGroup]="form" class="form_with_pads">   
                <mat-nav-list *ngIf="emplRules?.length">
                    <mat-list-item *ngFor="let emRule of emplRules; let i = index;" class="rule_out_item">
                        <div class="rule_item">
                            <ng-container *ngIf="checkIsGlobal(emRule); else elseTemplateIsGlob">
                                <span>(ID: {{emRule.id}}) {{ "For all files" | translate }}</span>
                            </ng-container>
                            <ng-template #elseTemplateIsGlob>
                                <span>(ID: {{emRule.id}}) {{ "For target" | translate }}:</span>
                                <span *ngIf="emRule.group_id">G id: {{emRule.group_id}}</span>
                                <span *ngIf="emRule.task_id">T id: {{emRule.task_id}}</span>
                                <span *ngIf="emRule.task_operation_id">J id: {{emRule.task_operation_id}}</span>
                                <span *ngIf="emRule.discussion_id">C id: {{emRule.discussion_id}}</span>
                                <span *ngIf="emRule.file_id">F id: {{emRule.file_id}}</span>
                            </ng-template>
                            <mat-icon style="color: red;" (click)="deleteFileRule(emRule.id, i)">delete</mat-icon>
                        </div>
                    </mat-list-item>
                </mat-nav-list>
            </form>
        </ng-template>
        
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <div class="f_w_p_btns">
        <button mat-raised-button type="button" (click)="close()"> 
            {{ "Close" | translate }}
        </button>
        <button mat-raised-button type="button" class="ml-1" color="primary" (click)="onSubmit()" [disabled]="isSubmit || (form.get('type').value == 1 && !form.get('partner_company_id').value)" #tooltip="matTooltip"
        matTooltip="{{ 'Global access to view and download originals' | translate }}"> 
            {{ "Add Global" | translate }}
        </button>
    </div>
</mat-dialog-actions>