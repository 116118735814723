<h2 mat-dialog-title class="dialog_title">

    <div class="cases_ids_wrp" style="margin-right: 25px"> 
      <div class="case_ids">
        <div class="case_c_id">{{data?.task?.custom_id}} </div>
        <div class="case_id">{{data?.task?.id}}</div>
      </div>
      <span>
        {{ data?.task?.name.length > 60 ? (data?.task?.name | slice:0:56) + " ..." : data?.task?.name }}
      </span>
    </div>

    <button mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h2>
<mat-dialog-content class="mat-typography connections_dialog">
    <div class="container">
        <div class="p-t-b">
            <mat-accordion>
                <mat-expansion-panel *ngIf="data.task.consist_of_count" [expanded]="expandConsist">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{ "Consist of" | translate }}
                    </mat-panel-title>
                    <mat-panel-description>
                      {{ data.task.consist_of_count }} {{ 'Cards' | translate }}
                    </mat-panel-description>
                  </mat-expansion-panel-header>
                  <mat-list-item *ngFor="let note of data.task.partOfTasks">
                    <mat-icon class="mr-1" mat-list-icon>note</mat-icon>
                    
                    <a [href]="origin + '/task/' + note?.acm" (click)="goToTask($event, note)" mat-line class="cases_ids_wrp"> 
                      <div class="case_ids">
                        <div class="case_c_id">{{note.custom_id}} </div>
                        <div class="case_id">{{note.id}}</div>
                      </div>
                      <span>
                        {{ note.name.length > 60 ? (note.name | slice:0:56) + " ..." : note.name }}
                      </span>
                    </a>
                    <!-- <div mat-line> {{ (note.action_updated_at*1000 | date:'mediumDate':'timeZone':activeLang).replace(' г.','').replace("р.","") }} </div> -->
                  </mat-list-item>
                </mat-expansion-panel>
                <mat-expansion-panel *ngIf="data.task.part_of_count" [expanded]="expandPart">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{ "Part of" | translate }}
                    </mat-panel-title>
                    <mat-panel-description>
                        {{ data.task.part_of_count }} {{ 'Cards' | translate }}
                    </mat-panel-description>
                  </mat-expansion-panel-header>
                  <mat-list-item *ngFor="let note of data.task.consistOfTasks">
                    <mat-icon class="mr-1" mat-list-icon>note</mat-icon>
                    <a [href]="origin + '/task/' + note?.acm" (click)="goToTask($event, note)" mat-line class="cases_ids_wrp"> 
                      <div class="case_ids">
                        <div class="case_c_id">{{note.custom_id}} </div>
                        <div class="case_id">{{note.id}}</div>
                      </div>
                      <span>
                        {{ note.name.length > 60 ? (note.name | slice:0:56) + " ..." : note.name }}
                      </span>
                    </a>
                    <!-- <div mat-line> {{ (note.action_updated_at*1000 | date:'mediumDate':'timeZone':activeLang).replace(' г.','').replace("р.","") }} </div> -->
                  </mat-list-item>
                </mat-expansion-panel>
                <mat-expansion-panel *ngIf="data.task.related_count" [expanded]="expandRelated">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{ "Related" | translate }}
                    </mat-panel-title>
                    <mat-panel-description>
                        {{ data.task.related_count }} {{ 'Cards' | translate }}
                    </mat-panel-description>
                  </mat-expansion-panel-header>
                  <mat-list-item *ngFor="let note of data.task.relatedTasks">
                    <mat-icon class="mr-1" mat-list-icon>note</mat-icon>
                    <a [href]="origin + '/task/' + note?.acm" (click)="goToTask($event, note)" mat-line class="cases_ids_wrp"> 
                      <div class="case_ids">
                        <div class="case_c_id">{{note.custom_id}} </div>
                        <div class="case_id">{{note.id}}</div>
                      </div>
                      <span>
                        {{ note.name.length > 60 ? (note.name | slice:0:56) + " ..." : note.name }}
                      </span>
                    </a>
                    <!-- <div mat-line> {{ (note.action_updated_at*1000 | date:'mediumDate':'timeZone':activeLang).replace(' г.','').replace("р.","") }} </div> -->
                  </mat-list-item>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
          
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button (click)="close()">
        {{ "Close" | translate }}
    </button>
</mat-dialog-actions>
