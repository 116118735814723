import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReplaySubject } from 'rxjs';
import { debounceTime, map, tap } from 'rxjs/operators';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { MembersService } from 'src/app/shared/services/rest/members.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';

@Component({
  selector: 'app-group-to-employee',
  templateUrl: './group-to-employee.component.html',
  styleUrls: ['./group-to-employee.component.scss']
})
export class GroupToEmployeeComponent extends BaseClass implements OnInit, OnDestroy {
  public form: FormGroup = this.fb.group({
    is_channel_content: '',
    is_channel_content_upload: ''
  });
  public groupsToEmployee: any;
  public groupsPagination: any;
  public groupsPage: number = 1;

  public groups: any[] = [];
  public groupsMoreControl: FormControl = new FormControl();
  public groups$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public isSubmit: boolean = false;

  public employees: any;
  public employees$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public execControl: FormControl = new FormControl();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<GroupToEmployeeComponent>,
    public layoutService: LayoutService,
    public taskService: TaskService,
    public companyService: CompanyService,
    public membersService: MembersService,
  ) { super() }

  ngOnInit(): void {
    console.log("GroupToEmployeeComponent", this.data)
    
    if (!!this.data.fromEmployees) {
      this.form = this.fb.group({
        company_id: this.data.company.id,
        employee_id: this.data.employee.id,
        group_id: '',
      })
      this.getGroups(this.data.company.id, this.groupsPage);
      this.getGroupToEmployee({employee_id: this.data.employee.id});
    } else {
      this.form = this.fb.group({
        company_id: this.data.company.id,
        employee_id: '',
        group_id: this.data.group.id,
      })
      this.getEmployees();
      this.getGroupToEmployee({group_id: this.data.group.id});
    }
    
    this.attachSubscriptions(
      this.execControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchEmoloyees(resp))
    )

    this.attachSubscriptions(
      this.groupsMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchgroups(resp))
    )
  }

  getEmployees() {
    this.attachSubscriptions(
      this.taskService.getEmployees(this.data.company.id).subscribe(resp => {
        this.employees = resp;
        this.employees$.next(this.employees.slice());
      })
    )
  }

  onSearchEmoloyees(resp) {
    if (!this.employees) {
      return;
    }

    if (!resp) {
      this.employees$.next(this.employees.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.employees$.next(
      this.employees.filter(z => z.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  getEmployee(id) {
    if (!this.employees) {
      return false;
    }
    return this.employees.find(el => el.id == id)
  }  


  getNextGroups() {
    if (this.groupsPagination.pageCount >= this.groupsPage) {
      console.log("getNextgroups")
      this.getGroups(this.data.company.id, this.groupsPage);
    }
  }

  onSearchgroups(resp) {
    if (!this.groups) {
      return;
    }

    if (!resp) {
      this.groups$.next(this.groups.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }

    // filter the banks
    this.groups$.next(
      this.groups.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  getGroups(company_id, page) {
    this.attachSubscriptions(
      this.companyService.getInfiniteGroupsCompany(company_id, page).pipe(
        tap(el => {
          this.groupsPagination = {
            'pageCount': el.headers.get('x-pagination-page-count'),
            'perPage': el.headers.get('x-pagination-per-page'),
            'totalCount': el.headers.get('x-pagination-total-count'),
            'currentPage': el.headers.get('x-pagination-current-page'),
          }
        }),
        map(el => el.body)
      ).subscribe(resp => {
        console.log("getgroups", resp)
        resp.forEach(profile => {
          if (this.groups.filter(x => x.id == profile.id).length == 0) {
            this.groups.push(...resp);
          }
        })
        this.groups$.next(this.groups.slice());
        this.groupsPage = this.groupsPage + 1;
      })
    )
  }


  getGroupToEmployee(filter) {
    this.attachSubscriptions(
      this.membersService.getGroupToEmployee(this.data.company.id, filter).subscribe(resp => {
        console.log("getGroupToEmployee", resp)
        this.groupsToEmployee = resp
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false});
  }

  onSubmit() {
    this.isSubmit = true;
    let sbmtData = {...this.form.value}

    this.attachSubscriptions(
      this.membersService.addGroupToEmployee(sbmtData).subscribe(resp => {
        this.isSubmit = false;

        if (!!this.data.fromEmployees) {
          this.form.patchValue({
            group_id: '',
          })
          this.getGroupToEmployee({employee_id: this.data.employee.id});
        } else {
          this.form.patchValue({
            employee_id: '',
          })
          this.getGroupToEmployee({group_id: this.data.group.id});
        }
      }, error => {
        this.isSubmit = false;
        this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
      })
    )
  }

  deleteGroupToEmployee(id, i) {
    this.attachSubscriptions(
      this.membersService.deleteGroupToEmployee(id).subscribe(resp => {
        this.groupsToEmployee.splice(i, 1)
      })
    )
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
