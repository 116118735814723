<mat-dialog-title class="video_editor_title" [ngClass]="{'sp_b': data?.thumbMode}">
    <div class="left_ve">
        <span (click)="log()" class="version">
            {{(data.project ? "Edit cards and publish project" : "Create cards and publish project" ) | translate}}
        </span>
    </div>

    <div class="right_ve">
        <div class="ve_input_out">
            <input class="ve_input" style="max-width: 250px;" [(ngModel)]="projectName" matInput placeholder="{{ 'Project Name' | translate }}">
        </div>
        <div class="d-f-c">
            <button [disabled]="isSubmit" [ngStyle]="{'submited_btn': isSubmit}" class="head_btn" (click)="save(true)">
                <mat-spinner class="spin_16 mr-1" *ngIf="isSubmit"></mat-spinner> 
                {{"Save project"| translate}}
            </button>

            <!-- <div class="cut_value">
                <div (click)="priority.open()">
                    <ng-container [ngSwitch]="waiting_time_limit.value">
                        <ng-container *ngSwitchCase="1">
                            {{ "Now" | translate }}
                        </ng-container>
                        <ng-container *ngSwitchCase="300">
                            {{ "Immediately" | translate }}
                        </ng-container>
                        <ng-container *ngSwitchCase="1200">
                            {{ "Urgent" | translate }}
                        </ng-container>
                        <ng-container *ngSwitchCase="14400">
                            {{ "Today" | translate }}
                        </ng-container>
                        <ng-container *ngSwitchCase="43200">
                            {{ "Tomorrow" | translate }}
                        </ng-container>
                        <ng-container *ngSwitchCase="86400">
                            {{ "Priority" | translate }}
                        </ng-container>
                        <ng-container *ngSwitchDefault>
                            {{ "Priority" | translate }}
                        </ng-container>
                    </ng-container>
                </div>
                <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                    <mat-select disableOptionCentering #priority [formControl]="waiting_time_limit">
                        <mat-option value="1" *ngIf="!!data?.user?.is_root">
                            {{ "Now" | translate }}
                        </mat-option>
                        <mat-option value="300" *ngIf="!!data?.user?.is_root">
                            {{ "Immediately" | translate }}
                        </mat-option>
                        <mat-option value="1200">
                            {{ "Urgent" | translate }}
                        </mat-option>
                        <mat-option value="14400">
                            {{ "Today" | translate }}
                        </mat-option>
                        <mat-option value="43200">
                            {{ "Tomorrow" | translate }}
                        </mat-option>
                        <mat-option value="86400">
                            {{ "No time frame" | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div> -->

            <button [disabled]="isSubmit" [ngStyle]="{'submited_btn': isSubmit}" class="head_btn head_btn_green" (click)="save()">
                <mat-spinner class="spin_16 mr-1" *ngIf="isSubmit"></mat-spinner> 
                {{"Export"| translate}}
            </button>

            <button mat-icon-button (click)="close()">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>
</mat-dialog-title>
<mat-dialog-content class="video_editor_content">
    <div class="video_edtior_screen" [appDeviceClass]="'custom-scroll'" cdkDropListGroup>
        <form [formGroup]="form" class="cards_form">
            <p>{{"Set for all" | translate}}:</p>

            <mat-form-field appearance="legacy" class="white_legacy_select">
                <mat-label>{{ 'Card template' | translate }}</mat-label>
                <mat-select disableOptionCentering #selectTemplate formControlName="template_id" (selectionChange)="selectTmpl($event)">
                    <mat-option>
                        <ngx-mat-select-search 
                        placeholderLabel="{{ 'Search' | translate }}" 
                        noEntriesFoundLabel="{{ 'no matching template found' | translate }}"
                        [formControl]="taskTemplatesControl"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let tmpl of taskTemplates$ | async" [value]="tmpl.id">
                        {{ tmpl?.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="legacy" class="white_legacy_select ml-1">
                <mat-label>{{ 'Card title' | translate }}</mat-label>
                <input formControlName="card_name" matInput placeholder="{{ 'Card title' | translate }}">
            </mat-form-field>
            <mat-form-field appearance="legacy" class="white_legacy_select ml-1" *ngIf="publications && publications.length">
                <mat-label>{{ 'Post title' | translate }}</mat-label>
                <input formControlName="post_name" matInput placeholder="{{ 'Post title' | translate }}">
            </mat-form-field>
            <!-- , form.get('template_id').value ? getItemById(taskTemplates, form.get('template_id').value).template_data.group_id: form.get('group_id').value -->
            <div class="tmpl_val ml-1" *ngIf="form.get('template_id').value && !(!!publications && !!publications.length)" (click)="$event.stopPropagation(); $event.preventDefault(); openContext($event, {})">
                <div class="dark">
                    <mat-icon>public</mat-icon>
                </div>
            </div>

            <div class="tmpl_val ml-1" *ngIf="!!publications && !!publications.length" (click)="$event.stopPropagation(); $event.preventDefault(); deletePublications()">
                <div class="dark">
                    <mat-icon style="color: red !important;">public_off</mat-icon>
                </div>
            </div>
        </form>
        <div class="card" *ngFor="let item of cards; let i = index;">
            <mat-form-field appearance="legacy" class="white_legacy_select">
                <mat-label>{{ 'Card title' | translate }}</mat-label>
                <input [(ngModel)]="item.card_name" matInput placeholder="{{ 'Card title' | translate }}">
            </mat-form-field>

            <div class="card_in">
                <div class="files" 
                [ngClass]="{'highlight': is_dragging}"
                cdkDropList
                [cdkDropListData]="item.files"
                (cdkDropListDropped)="drop($event)">
                    <div class="card_file" *ngFor="let file of item.files" cdkDrag (cdkDragStart)="startDrag($event)" (cdkDragEnd)="endDrag($event)">
                        <div class="card_file_img"><img (error)="onImgError($event)" style="max-width: 100%; max-height: 100%;" [src]="data?.host + file.thumbnail + '?company_id=' + data?.company?.id"></div>
                        <div class="card_file_info">
                            <div class="d-f-c">
                                <div class="card_file_name text_2_line" [ngClass]="{'has_posts': file.publications && file.publications.length}">{{file?.filename}}</div>
                                <div class="ve_input_out" *ngIf="file.publications && file.publications.length">
                                    <input class="ve_input" [(ngModel)]="file.post_name" matInput placeholder="{{ 'Post title' | translate }}">
                                </div>
                            </div>
                            <div class="file_tags" style="color: #3f51b5;" *ngIf="file.parameterValuesToTask">
                                <ng-container *ngFor="let tag of file.parameterValuesToTask; let last = last">
                                  <span>{{tag?.parameterValue?.value}}</span>
                                  <ng-container *ngIf="!last">, </ng-container>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
    
                <div class="card_info">
                    <div></div>
                    <button mat-icon-button color="warn" style="align-self: center;" (click)="deleteCard(item, i)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>   
</mat-dialog-content>

<ng-template #contextMenu let-contextdata>
	<section class="dark_menu with_search" [ngClass]="{'ai_menu': !!contextdata?.is_ai, with_mistake: !!aiMistake}">
        <ng-container *ngIf="!!contextdata?.is_ai; else elseTemplateIsAiMenu">
            <ng-container *ngIf="aiResults && aiResults.length; else elseTemplateHasResHead">
                <div class="context_head no_focus">
                    <div class="cont_title">
                        <div>{{aiResults.length}} {{"another options" | translate}}</div>
                        <div class="cp" (click)="$event.preventDefault(); $event.stopPropagation(); aiResults = []; aiMistake = undefined;">
                            <mat-icon style="color: #C9C9C9;">arrow_back_ios</mat-icon>
                        </div>
                    </div>
                    <div class="cont_desc">
                        {{"Tap option to apply" | translate}}
                    </div>
                </div>
            </ng-container>
            <ng-template #elseTemplateHasResHead>
                <ng-container *ngIf="!!aiMistake; else elseTemplateHasMistake">
                    <div class="context_head no_focus">
                        <div class="cont_title">
                            <div>{{"Your response was received in the wrong format" | translate}}</div>
                            <div class="cp" (click)="$event.preventDefault(); $event.stopPropagation(); resetRes();">
                                <mat-icon style="color: #C9C9C9;">arrow_back_ios</mat-icon>
                            </div>
                        </div>
                        <div class="cont_desc">
                            {{"Try again by clicking on the 'Get Results'" | translate}}
                        </div>
                    </div>
                </ng-container>
                <ng-template #elseTemplateHasMistake>
                    <div class="context_head no_focus">
                        <div class="cont_title">
                            <div>{{"Title composer" | translate}}</div>
                            <div>
                                <svg style="width: 21px; height: 20px" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13.0817 9.25L12.1817 10.17C11.4617 10.89 11.0117 11.5 11.0117 13H9.01172V12.5C9.01172 11.39 9.46172 10.39 10.1817 9.67L11.4217 8.41C11.7917 8.05 12.0117 7.55 12.0117 7C12.0117 5.89 11.1117 5 10.0117 5C9.48129 5 8.97258 5.21071 8.59751 5.58579C8.22243 5.96086 8.01172 6.46957 8.01172 7H6.01172C6.01172 5.93913 6.43315 4.92172 7.18329 4.17157C7.93344 3.42143 8.95085 3 10.0117 3C11.0726 3 12.09 3.42143 12.8401 4.17157C13.5903 4.92172 14.0117 5.93913 14.0117 7C14.0117 7.88 13.6517 8.67 13.0817 9.25ZM11.0117 17H9.01172V15H11.0117M10.0117 0C8.6985 0 7.39814 0.258658 6.18488 0.761205C4.97163 1.26375 3.86924 2.00035 2.94065 2.92893C1.06529 4.8043 0.0117188 7.34784 0.0117188 10C0.0117188 12.6522 1.06529 15.1957 2.94065 17.0711C3.86924 17.9997 4.97163 18.7362 6.18488 19.2388C7.39814 19.7413 8.6985 20 10.0117 20C12.6639 20 15.2074 18.9464 17.0828 17.0711C18.9582 15.1957 20.0117 12.6522 20.0117 10C20.0117 4.47 15.5117 0 10.0117 0Z" fill="#219653"/>
                                </svg> 
                            </div>
                        </div>
                        <div class="cont_desc">
                            {{"Here you may compose title for all video publications" | translate}}
                        </div>
                    </div>
                </ng-template>
            </ng-template>

    
            <div class="context_body" [ngClass]="{'with_results': aiResults && aiResults.length}">
                <ng-container *ngIf="aiResults && aiResults.length; else elseTemplateHasRes">
                    <div class="res_list">
                        <div class="res text_one_line" [ngClass]="{'active': (!!contextdata.contextData && !!contextdata.contextData.is_cut && !!contextdata.contextData.form && contextdata.contextData.form.get('name').value == item) || ((!contextdata.contextData || !contextdata.contextData.is_cut) && form.get('name').value == item)}" *ngFor="let item of aiResults" (click)="$event.preventDefault(); $event.stopPropagation(); selectTitle(item, contextdata)">
                            {{item}}
                        </div>
                    </div>
                </ng-container>
                <ng-template #elseTemplateHasRes>
                    <div class="body_left">
                        <form [formGroup]="aiForm">
                            <mat-form-field appearance="standard" class="full_width_form">
                                <mat-label>{{ 'On the video' | translate }}</mat-label>
                                <textarea 
                                cdkTextareaAutosize
                                #autosize="cdkTextareaAutosize"
                                cdkAutosizeMinRows="2"
                                cdkAutosizeMaxRows="3" formControlName="on_the_video" matInput></textarea>
                                <mat-hint class="char_counter_hint" *ngIf="aiForm.get('on_the_video').value" [ngClass]="{'red': aiForm.get('on_the_video').value.length > 999999999}" align="end">{{aiForm.get('on_the_video').value.length}}</mat-hint>
                            </mat-form-field>
    
                            <mat-form-field appearance="standard" class="full_width_form">
                                <mat-label>{{ 'Your idea of title' | translate }}</mat-label>
                                <textarea 
                                cdkTextareaAutosize
                                #autosize="cdkTextareaAutosize"
                                cdkAutosizeMinRows="2"
                                cdkAutosizeMaxRows="3" formControlName="your_idea_of_title" matInput></textarea>
                                <mat-hint class="char_counter_hint" *ngIf="aiForm.get('your_idea_of_title').value" [ngClass]="{'red': aiForm.get('your_idea_of_title').value.length > 999999999}" align="end">{{aiForm.get('your_idea_of_title').value.length}}</mat-hint>
                            </mat-form-field>

                            <mat-form-field class="full_width_form" style="margin-top: 10px;">
                                <mat-label>{{"Words to use" | translate}}</mat-label>
                                <!-- has_auto_trigger -->
                                <mat-chip-list class="without_ordered" #chipGrid aria-label="keywords selection" formControlName="keywords">
                                    <mat-chip class="chip_el" *ngFor="let keyword of aiForm.get('keywords').value" (removed)="removeKeyword(keyword)">
                                    {{keyword}}
                                    <button matChipRemove [attr.aria-label]="'remove ' + keyword">
                                        <mat-icon>close</mat-icon>
                                    </button>
                                    </mat-chip>
                                    <input class="auto_input" #keyInput placeholder="Other Keyword..." [formControl]="savedTagsControl" (input)="$event.stopPropagation()"
                                    #triggerKeys="matAutocompleteTrigger"
                                    [matChipInputFor]="chipGrid" [matAutocomplete]="keywordsAuto"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    (matChipInputTokenEnd)="addKeyword($event, keyInput, triggerKeys)"/>
                                    <!-- <div class="auto_trigger" (click)="$event.stopPropagation(); $event.stopImmediatePropagation(); $event.preventDefault(); triggerKeys.panelOpen ? triggerKeys.closePanel() : triggerKeys.openPanel()">
                                        <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M2.1 3C2.3387 3 2.56761 2.90518 2.7364 2.7364C2.90518 2.56761 3 2.3387 3 2.1C3 1.86131 2.90518 1.63239 2.7364 1.4636C2.56761 1.29482 2.3387 1.2 2.1 1.2C1.86131 1.2 1.63239 1.29482 1.4636 1.4636C1.29482 1.63239 1.2 1.86131 1.2 2.1C1.2 2.3387 1.29482 2.56761 1.4636 2.7364C1.63239 2.90518 1.86131 3 2.1 3ZM9.246 4.548C9.462 4.764 9.6 5.064 9.6 5.4C9.6 5.73 9.468 6.03 9.246 6.246L6.246 9.246C6.03 9.462 5.73 9.6 5.4 9.6C5.07 9.6 4.77 9.468 4.548 9.246L0.354 5.052C0.132 4.83 0 4.53 0 4.2V1.2C0 0.534 0.534 0 1.2 0H4.2C4.53 0 4.83 0.132 5.046 0.348L9.246 4.548ZM6.924 1.026L7.524 0.426L11.646 4.548C11.868 4.764 12 5.07 12 5.4C12 5.73 11.868 6.03 11.652 6.246L8.424 9.474L7.824 8.874L11.25 5.4L6.924 1.026Z" fill="#C9C9C9"/>
                                        </svg> 
                                    </div> -->
                                </mat-chip-list>
                                <mat-autocomplete class="list_of_keywords" classList="list_of_keywords" #keywordsAuto="matAutocomplete" (optionSelected)="selectedKeyword($event, keyInput)">
                                    <div class="select_all_auto" *ngIf="checkIfCanSelectAll((savedTags$ | async), aiForm.get('keywords').value)" (click)="$event.stopPropagation(); $event.preventDefault(); selectAllKeywords(keywordsAuto, aiForm.get('keywords').value, aiForm, triggerKeys)">{{"Select All" | translate}}</div>
                                    <ng-container *ngFor="let keyword of savedTags$ | async">
                                        <ng-container *ngIf="!aiForm.get('keywords').value.includes(keyword.value)">
                                            <mat-option (click)="$event.stopPropagation();" [value]="keyword.value">
                                                {{keyword.value}}
                                            </mat-option>
                                        </ng-container>
                                    </ng-container>
                                </mat-autocomplete>
                            </mat-form-field>

                            <mat-form-field class="full_width_form" style="margin-top: 15px;">
                                <mat-label>{{"Remember" | translate}}</mat-label>
                                <!-- has_auto_trigger -->
                                <mat-chip-list class="without_ordered" #remGrid aria-label="remembers selection" formControlName="remembers">
                                    <mat-chip class="chip_el" *ngFor="let remember of aiForm.get('remembers').value" (removed)="removeRemember(remember)">
                                    {{remember}}
                                    <button matChipRemove [attr.aria-label]="'remove ' + remember">
                                        <mat-icon>close</mat-icon>
                                    </button>
                                    </mat-chip>
                                    <input class="auto_input" #remInput placeholder="Other Remember..." [formControl]="remembersControl" (input)="$event.stopPropagation()"
                                    #triggerRem="matAutocompleteTrigger"
                                    [matChipInputFor]="remGrid" [matAutocomplete]="remembersAuto"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    (matChipInputTokenEnd)="addRemember($event, remInput, triggerRem)"/>
                                    <!-- <div class="auto_trigger" (click)="$event.stopPropagation(); $event.stopImmediatePropagation(); $event.preventDefault(); triggerRem.panelOpen ? triggerRem.closePanel() : triggerRem.openPanel()">
                                        <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0 0H2.66667V2.66667H0V0ZM4 0.666667V2H12V0.666667H4ZM0 4H2.66667V6.66667H0V4ZM4 4.66667V6H12V4.66667H4ZM0 8H2.66667V10.6667H0V8ZM4 8.66667V10H12V8.66667H4Z" fill="#C9C9C9"/>
                                        </svg> 
                                    </div> -->
                                </mat-chip-list>
                                <mat-autocomplete class="list_of_keywords" classList="list_of_keywords" #remembersAuto="matAutocomplete" (optionSelected)="selectedRemember($event, remInput)">
                                    <ng-container *ngFor="let remember of remembers$ | async">
                                        <ng-container *ngIf="!aiForm.get('remembers').value.includes(remember.message)">
                                            <mat-option (click)="$event.stopPropagation();" [value]="remember.message">
                                                {{remember.message}}
                                            </mat-option>
                                        </ng-container>
                                    </ng-container>
                                </mat-autocomplete>
                            </mat-form-field>
                                
    
                            <mat-form-field appearance="standard" class="full_width_form">
                                <mat-label>{{ 'Also remember' | translate }}</mat-label>
                                <textarea 
                                cdkTextareaAutosize
                                #autosize="cdkTextareaAutosize"
                                cdkAutosizeMinRows="1"
                                cdkAutosizeMaxRows="2" formControlName="also_remember" matInput></textarea>
                                <mat-hint class="char_counter_hint" *ngIf="aiForm.get('also_remember').value" [ngClass]="{'red': aiForm.get('also_remember').value.length > 999999999}" align="end">{{aiForm.get('also_remember').value.length}}</mat-hint>
                            </mat-form-field>
                        </form>
                    </div>
                    <div class="body_right">
                        <div class="msgs_preview">
                            <div class="msg" *ngIf="aiForm.get('on_the_video').value">
                                <b>On the video</b> {{aiForm.get('on_the_video').value}}
                            </div>
                            <div class="msg" *ngIf="aiForm.get('your_idea_of_title').value">
                                <b>Example of the title</b> {{aiForm.get('your_idea_of_title').value}}
                            </div>
                            <div class="msg" *ngIf="aiForm.get('keywords').value && aiForm.get('keywords').value.length">
                                <b>Use words</b> {{aiForm.get('keywords').value.join(', ')}}
                            </div>
                            <div class="msg" *ngIf="aiForm.get('remembers').value && aiForm.get('remembers').value.length">
                                <b>Remember</b> {{aiForm.get('remembers').value.join(', ')}}
                            </div>
                            <div class="msg" *ngIf="aiForm.get('also_remember').value">
                                <b>Also remember</b> {{aiForm.get('also_remember').value}}
                            </div>
                            <ng-container *ngIf="showSys">
                                <form class="form_sys" [formGroup]="aiSysForm">      
                                    <div class="sys_str" contenteditable="true" spellcheck="false" formControlName="str"></div>
                                </form>
                                <!-- <pre class="request_sys" *ngIf="!!getResultsReqObject() && getResultsReqObject().messages && getResultsReqObject().messages[0]">{{getResultsReqObject().messages[0]}}</pre> -->
                            </ng-container>
                        </div>
                        <div class="context_footer">
                            <div class="sys_btn" (click)="showSys = !showSys">
                                <ng-container *ngIf="showSys; else elseTemplate">
                                    {{"Hide full request" | translate}}
                                </ng-container>
                                <ng-template #elseTemplate>
                                    {{"Full request" | translate}}
                                </ng-template>
                            </div>
                            <button class="cntxt_btn blue" [disabled]="isGettingResults" (click)="getResults(aiForm, contextdata)">
                                <mat-spinner class="spin_16 mr-1" *ngIf="isGettingResults"></mat-spinner> 
                                {{ "Get Results" | translate }}
                            </button>
                        </div>
                    </div>
                    <div class="mistake" *ngIf="!!aiMistake">{{aiMistake}}</div>
                </ng-template>
            </div>
        </ng-container>
        <ng-template #elseTemplateIsAiMenu>            
            <div class="context_head no_focus">
                <div class="cont_title">
                    <div>{{"Mass publish setup" | translate}}</div>
                    <div>
                        <svg style="width: 21px; height: 20px" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.0817 9.25L12.1817 10.17C11.4617 10.89 11.0117 11.5 11.0117 13H9.01172V12.5C9.01172 11.39 9.46172 10.39 10.1817 9.67L11.4217 8.41C11.7917 8.05 12.0117 7.55 12.0117 7C12.0117 5.89 11.1117 5 10.0117 5C9.48129 5 8.97258 5.21071 8.59751 5.58579C8.22243 5.96086 8.01172 6.46957 8.01172 7H6.01172C6.01172 5.93913 6.43315 4.92172 7.18329 4.17157C7.93344 3.42143 8.95085 3 10.0117 3C11.0726 3 12.09 3.42143 12.8401 4.17157C13.5903 4.92172 14.0117 5.93913 14.0117 7C14.0117 7.88 13.6517 8.67 13.0817 9.25ZM11.0117 17H9.01172V15H11.0117M10.0117 0C8.6985 0 7.39814 0.258658 6.18488 0.761205C4.97163 1.26375 3.86924 2.00035 2.94065 2.92893C1.06529 4.8043 0.0117188 7.34784 0.0117188 10C0.0117188 12.6522 1.06529 15.1957 2.94065 17.0711C3.86924 17.9997 4.97163 18.7362 6.18488 19.2388C7.39814 19.7413 8.6985 20 10.0117 20C12.6639 20 15.2074 18.9464 17.0828 17.0711C18.9582 15.1957 20.0117 12.6522 20.0117 10C20.0117 4.47 15.5117 0 10.0117 0Z" fill="#219653"/>
                        </svg> 
                    </div>
                </div>
                <div class="cont_desc">
                    {{"All exported files will be published to corresponded outlet on selected platform at defined time" | translate}}
                </div>
            </div>
    
            <div class="context_body with_results" [appDeviceClass]="'custom-scroll'">
                <ng-container [ngSwitch]="profilesTab">
                    <ng-container *ngSwitchCase="0">
                        <div class="context_body_desc no_focus">
                            {{"In current setup for exporting files cards there are available outlets." | translate}}
                        </div>
                        <div class="grouped_outlets" *ngFor="let item of contextdata.outlets">
                            <div class="group_items">
                                <div class="title">
                                    <app-platform [platform_id]="item.platform.id"></app-platform>
                                    <span>
                                        {{item.platform.name}}
                                    </span>
                                </div>
                                <div class="items" *ngFor="let arrItem of item.arr">
                                    <div class="item" [ngClass]="{'active': !!arrItem.is_selected}" (click)="arrItem.is_selected = !arrItem.is_selected">
                                        <app-outlet-line [channel]="arrItem.channel" [company_id]="data?.company.id"></app-outlet-line> 
                                        <span>{{getTypeById(arrItem?.content_type_id)?.name}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="1">
                        <div class="context_body_desc no_focus">
                            {{"Media plans available for outlets." | translate}}<br>
                            {{"Define how frequently you want to publish files." | translate}}
                        </div>
                        <div class="selected_items">
                            <ng-container *ngFor="let item of contextdata.outlets">
                                <ng-container *ngFor="let arrItem of item.arr">
                                    <ng-container *ngIf="!!arrItem.is_selected">
                                        <div class="selected_item">
                                            <div class="chann">
                                                <app-outlet-line [channel]="arrItem.channel" [company_id]="data?.company.id"></app-outlet-line>
                                            </div>
                                            <div class="chann_settings">
                                                <span class="time">Anytime</span>
                                                <span>skipping</span>
                                                <input class="min_int" type="number" [(ngModel)]="arrItem.publData.content_plan_min_interval">
                                                <span>time slots</span>
                                            </div>
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="2">
                        <div class="context_body_desc no_focus">
                            {{"Almost done." | translate}}<br>
                        </div>
    
                        + Autoassign time slots after file are ready?
                    </ng-container>
                </ng-container>
            </div>
            <div class="context_footer">
                <div>
                    <button class="cntxt_btn" *ngIf="profilesTab != 0" (click)="$event.preventDefault; $event.stopPropagation(); profilesTab = profilesTab - 1">
                        {{ "Back" | translate }}
                    </button>
                </div>
                <div class="d-f-c">
                    <button class="cntxt_btn" (click)="$event.preventDefault; $event.stopPropagation(); closeContext()">
                        {{ "Cancel" | translate }}
                    </button>
                    <button class="cntxt_btn" style="color: #2F80ED;" *ngIf="profilesTab == 0 && checkOutletsCount(contextdata.outlets) > 5" (click)="$event.preventDefault; $event.stopPropagation(); selectAll(contextdata.outlets)">
                        {{ "Select All" | translate }}
                    </button>
                    <button class="cntxt_btn blue" *ngIf="profilesTab < 2" (click)="$event.preventDefault; $event.stopPropagation(); nextTab()">
                        {{ "Next" | translate }}
                    </button>
                    <button class="cntxt_btn blue" *ngIf="profilesTab == 2" (click)="$event.preventDefault; $event.stopPropagation(); finishPubl(contextdata.outlets)">
                        {{ "Finish" | translate }}
                    </button>
                </div>
            </div>
        </ng-template>
        
	</section>
</ng-template>