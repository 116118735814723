import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectionList } from '@angular/material/list';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { ConnectedAppsService } from 'src/app/shared/services/rest/connected-apps.service';
import { FileService } from 'src/app/shared/services/rest/file.service';
import { ParametersService } from 'src/app/shared/services/rest/parameters.service';

@Component({
  selector: 'app-select-checklist',
  templateUrl: './select-checklist.component.html',
  styleUrls: ['./select-checklist.component.scss']
})
export class SelectChecklistComponent extends BaseClass implements OnInit, OnDestroy {
  public form: FormGroup;
  public checklist: any;
  public hasChecklistError: boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<SelectChecklistComponent>,
    private fileService: FileService,
    private parametersService: ParametersService,
    private fb: FormBuilder,
  ) { super() }

  ngOnInit(): void {
    console.log("SelectChecklistComponent", this.data);
    this.form = this.fb.group({
      operation_reminder_id: this.data.file.operation_reminder_id
    })

    this.getTargetParameters()
  }

  getTargetParameters() {
    let tagsFilter:any = {task_id: this.data.file.task_id, task_operation_id: this.data.file.task_operation_id}

    if (this.data.file.company_id != this.data.company.id) {
      tagsFilter.partner_company_id = this.data.file.company_id
    }
    this.attachSubscriptions(
      this.parametersService.getTargetParameters(this.data.company.id, tagsFilter).subscribe(resp => {
        let __checklist = this.data.checklist;
        let jobParameters = resp
        this.checklist = [];
        __checklist.forEach(element => {
          if (element.exceptParameterValues.length > 0) {
            if (element.exceptParameterValues.map(b => b.parameter_value_id).every(n => !jobParameters.map(m => m.parameter_value_id).includes(n))) {
              this.checklist.push(element)
            }
          } else if (element.toParameterValues.length > 0) {
            if (element.toParameterValues.map(b => b.parameter_value_id).some(n => jobParameters.map(m => m.parameter_value_id).includes(n))) {
              this.checklist.push(element)
            }
          } else {
            this.checklist.push(element)
          }
        });
    
        if (this.checklist.length == 0 && this.checklist.filter(b => !!b.status).length == this.checklist.length) {
          this.hasChecklistError = true;
        }

        console.log("getTargetParameters", resp)
        console.log("getTargetParameters", this.checklist)
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false});
  }

  onImgError(event){
    event.target.src = this.data.imgRoute+'/assets/img/image_black_48dp.svg'
  }

  save() {
    let must_delete = false;
    let sbmtData = {...this.form.value}
    if (this.data.file.location.indexOf('ready_files') == -1 && this.data.file.location.indexOf('approved') != -1) {
      sbmtData.location = '/ready_files';
      must_delete = true;
    }
    this.attachSubscriptions(
      this.fileService.editFile(this.data.file.id, sbmtData, this.data.company.id).subscribe(resp => {
        this.data.file.operation_reminder_id = resp.operation_reminder_id;
        this.data.file.operation_reminder_name = resp.operation_reminder_name;
        if (must_delete) {
          this.dialogRef.close({event: "close", data: false, must_delete: must_delete});
        } else {
          this.close()
        }
      })
    )
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
