import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { DeleteAlertComponent } from 'src/app/shared/global_components/delete-alert/delete-alert.component';
import { BaseClass } from 'src/app/shared/models/base-class';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { TaskAddClientComponent } from '../task-add-client/task-add-client.component';
import { environment } from 'src/environments/environment';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { map, tap } from 'rxjs/operators';

@Component({
  selector: 'app-task-clients',
  templateUrl: './task-clients.component.html',
  styleUrls: ['./task-clients.component.scss']
})
export class TaskClientsComponent extends BaseClass implements OnInit, OnDestroy {
  public clients: any;
  throttle = 100;
  scrollUpDistance = 3.5;
  public imgRoute: any;
  public isLoad: boolean = false;
  public host: any = environment.host;
  public page: number = 1;
  public pagination: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private taskService: TaskService,
    public dialogRef: MatDialogRef<TaskClientsComponent>,
    private dialog: MatDialog,
    private sm: StorageManagerService,
    private bottomSheet: MatBottomSheet,
  ) { super() }

  ngOnInit(): void {
    console.log("TaskClientsComponent", this.data)
    this.getImgRoute();
    this.getClients();
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  onDown(e) {
    console.log("SCROLL DOWN", e);
    if (this.pagination['pageCount'] >= this.page) {
      console.log("onDownAutoTags")
      this.getClients();
    }
  }

  addClient() {
    const dialogRef = this.dialog.open(TaskAddClientComponent, {
      data: {
        company: this.data.company,
        company_id: this.data.company_id,
        task: this.data.task,
        user: this.data.user,
        clients: this.clients,
        work: this.data.work
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log(result)
        if (result.event == 'update') {
          this.getClients();
        }
      })
    )
  }

  addPartnerClient() {
    const dialogRef = this.dialog.open(TaskAddClientComponent, {
      data: {
        company: this.data.company,
        company_id: this.data.company_id,
        task: this.data.task,
        user: this.data.user,
        clients: this.clients,
        work: this.data.work,
        is_partner: true,
        imgRoute: this.imgRoute,
        host: this.host
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log(result)
        if (result.event == 'update') {
          this.getClients();
        }
      })
    )
  }

  togglePartner(e, client) {
    client.disabled = true
    this.taskService.editClientInTask(client.id, {is_principal: Number(e.checked)}, this.data.company.id).subscribe(resp => {
      client.is_principal = Number(e.checked);
      client.disabled = false;
    })
  }

  // getClients() {
  //   this.taskService.getTaskClientsDyn(this.page, this.data.task.id, this.data.company.id, '10').subscribe(resp => {
  //     console.log("getClients", resp);
  //     this.clients = resp;
  //   })
  // }

  getClients() {
    this.isLoad = true;
    this.attachSubscriptions(
      this.taskService.getTaskClientsDyn(this.page, this.data.task.id, this.data.company.id, '200').pipe(
        tap(el => {
          this.pagination = {
            'pageCount': el.headers.get('x-pagination-page-count'),
            'perPage': el.headers.get('x-pagination-per-page'),
            'totalCount': el.headers.get('x-pagination-total-count'),
            'currentPage': el.headers.get('x-pagination-current-page'),
          }
        }),
        map(el => el.body)
      ).subscribe(resp => {
        console.log("getProfiles", resp)

        if (this.page == 1) {
          this.clients = resp;
        } else {
          resp.forEach(cli => {
            if (this.clients.filter(x => x.id == cli.id).length == 0) {
              this.clients.push(...resp);
            }
          })
        }
        this.isLoad = false;
        this.page++;
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false});
  }

  deleteClient(client, i) {
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: {},
        target: marker("client")
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        this.attachSubscriptions(
          this.taskService.deleteClient(client.id).subscribe(resp => {
            console.log(resp)
            this.clients.splice(i,1);
          })
        )
      }
    });
  
  }

  ngOnDestroy() {
    this.clearSubscriptions()
  }

}
