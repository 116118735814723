<ng-container *ngIf="!is_mobile; else elseTemplateDeskFil">
    <nav class="reports_nav" [ngClass]="{'deactivate': ['Post Dashboards', 'Outlet Dashboards'].includes(activeSections())}">
        <ul>
            <li class="page">
                <a>
                    <ng-container [ngSwitch]="activeSections()">
                        <ng-container *ngSwitchCase="'Cards'">
                            <div class="a_icon">      
                                <svg style="width: 18px; height: 18px" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16 12V16H2V12H16ZM17 10H1C0.45 10 0 10.45 0 11V17C0 17.55 0.45 18 1 18H17C17.55 18 18 17.55 18 17V11C18 10.45 17.55 10 17 10ZM4 15.5C3.18 15.5 2.5 14.83 2.5 14C2.5 13.17 3.18 12.5 4 12.5C4.82 12.5 5.5 13.17 5.5 14C5.5 14.83 4.83 15.5 4 15.5ZM16 2V6H2V2H16ZM17 0H1C0.45 0 0 0.45 0 1V7C0 7.55 0.45 8 1 8H17C17.55 8 18 7.55 18 7V1C18 0.45 17.55 0 17 0ZM4 5.5C3.18 5.5 2.5 4.83 2.5 4C2.5 3.17 3.18 2.5 4 2.5C4.82 2.5 5.5 3.18 5.5 4C5.5 4.82 4.83 5.5 4 5.5Z" fill="#686868"/>
                                </svg>
                            </div>
                        </ng-container>
                        <ng-container *ngSwitchCase="'Jobs'">
                            <div class="a_icon">      
                                <svg style="width: 18px; height: 15px" width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 14.4H18V10.8H0V14.4ZM1.8 11.7H3.6V13.5H1.8V11.7ZM0 0V3.6H18V0H0ZM3.6 2.7H1.8V0.9H3.6V2.7ZM0 9H18V5.4H0V9ZM1.8 6.3H3.6V8.1H1.8V6.3Z" fill="#686868"/>
                                </svg>
                            </div>
                        </ng-container>
                        <ng-container *ngSwitchCase="'Chats'">
                            <div class="a_icon">      
                                <svg style="width: 18px; height: 18px" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16.2 0H1.8C1.32261 0 0.864773 0.189642 0.527208 0.527208C0.189642 0.864773 0 1.32261 0 1.8V18L3.6 14.4H16.2C16.6774 14.4 17.1352 14.2104 17.4728 13.8728C17.8104 13.5352 18 13.0774 18 12.6V1.8C18 0.801 17.19 0 16.2 0Z" fill="#686868"/>
                                </svg>
                            </div>
                        </ng-container>
                        <ng-container *ngSwitchCase="'Board by Date'">
                            <div class="a_icon">      
                                <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.77778 11.5556H3.55556V13.3333H9.77778M14.2222 16H1.77778V6.22222H14.2222M14.2222 1.77778H13.3333V0H11.5556V1.77778H4.44444V0H2.66667V1.77778H1.77778C0.791111 1.77778 0 2.57778 0 3.55556V16C0 16.4715 0.187301 16.9237 0.520699 17.2571C0.854097 17.5905 1.30628 17.7778 1.77778 17.7778H14.2222C14.6937 17.7778 15.1459 17.5905 15.4793 17.2571C15.8127 16.9237 16 16.4715 16 16V3.55556C16 3.08406 15.8127 2.63188 15.4793 2.29848C15.1459 1.96508 14.6937 1.77778 14.2222 1.77778ZM12.4444 8H3.55556V9.77778H12.4444V8Z" fill="#686868"/>
                                </svg>
                            </div>
                        </ng-container>
                        <ng-container *ngSwitchCase="'Posts'">
                            <div class="a_icon">      
                                <svg style="width: 18px; height: 9px" width="18" height="9" viewBox="0 0 18 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13.5 0H9.9V1.8H13.5C14.985 1.8 16.2 3.015 16.2 4.5C16.2 5.985 14.985 7.2 13.5 7.2H9.9V9H13.5C15.984 9 18 6.984 18 4.5C18 2.016 15.984 0 13.5 0ZM8.1 7.2H4.5C3.015 7.2 1.8 5.985 1.8 4.5C1.8 3.015 3.015 1.8 4.5 1.8H8.1V0H4.5C2.016 0 0 2.016 0 4.5C0 6.984 2.016 9 4.5 9H8.1V7.2ZM5.4 3.6H12.6V5.4H5.4V3.6Z" fill="#686868"/>
                                </svg>
                            </div>
                        </ng-container>
                        <ng-container *ngSwitchCase="'Board by Order'">
                            <div class="a_icon">      
                                <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.8 13.5H18V11.7H10.8V13.5ZM10.8 8.55H18V6.75H10.8V8.55ZM10.8 3.6H18V1.8H10.8V3.6ZM0 7.65C0 4.428 2.628 1.8 5.85 1.8H6.3V0L9 2.7L6.3 5.4V3.6H5.85C3.6 3.6 1.8 5.4 1.8 7.65C1.8 9.9 3.6 11.7 5.85 11.7H9V13.5H5.85C2.628 13.5 0 10.872 0 7.65Z" fill="#686868"/>
                                </svg>
                            </div>
                        </ng-container>
                        <ng-container *ngSwitchCase="'Post Dashboards'">
                            <div class="a_icon">      
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.1111 0V6.66667H20V0M11.1111 20H20V8.88889H11.1111M0 20H8.88889V13.3333H0M0 11.1111H8.88889V0H0V11.1111Z" fill="#686868"/>
                                </svg>
                            </div>
                        </ng-container>
                        <ng-container *ngSwitchCase="'Outlet Dashboards'">
                            <div class="a_icon">      
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.66667 0V16.6667H10V0H6.66667ZM10 2.22222L14.4444 16.6667L17.7778 15.5556L13.3333 1.11111L10 2.22222ZM2.22222 2.22222V16.6667H5.55556V2.22222H2.22222ZM0 17.7778V20H20V17.7778H0Z" fill="#686868"/>
                                </svg>
                            </div>
                        </ng-container>
                        <ng-container *ngSwitchCase="'Settings'">
                            <div class="a_icon">      
                                <svg style="width: 18px; height: 18px" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_3672_54608pa)">
                                    <path d="M14.3546 9.70505C14.3846 9.48005 14.3996 9.24755 14.3996 9.00005C14.3996 8.76005 14.3846 8.52005 14.3471 8.29505L15.8696 7.11005C16.0046 7.00505 16.0421 6.80255 15.9596 6.65255L14.5196 4.16255C14.4296 3.99755 14.2421 3.94505 14.0771 3.99755L12.2846 4.71755C11.9096 4.43255 11.5121 4.19255 11.0696 4.01255L10.7996 2.10755C10.7696 1.92755 10.6196 1.80005 10.4396 1.80005H7.55959C7.37959 1.80005 7.23709 1.92755 7.20709 2.10755L6.93709 4.01255C6.49459 4.19255 6.08959 4.44005 5.72209 4.71755L3.92959 3.99755C3.76459 3.93755 3.57709 3.99755 3.48709 4.16255L2.05459 6.65255C1.96459 6.81005 1.99459 7.00505 2.14459 7.11005L3.66709 8.29505C3.62959 8.52005 3.59959 8.76755 3.59959 9.00005C3.59959 9.23255 3.61459 9.48005 3.65209 9.70505L2.12959 10.89C1.99459 10.995 1.95709 11.1975 2.03959 11.3475L3.47959 13.8375C3.56959 14.0026 3.75709 14.055 3.92209 14.0025L5.71459 13.2825C6.08959 13.5675 6.48709 13.8075 6.92959 13.9875L7.19959 15.8925C7.23709 16.0725 7.37959 16.2 7.55959 16.2H10.4396C10.6196 16.2 10.7696 16.0725 10.7921 15.8925L11.0621 13.9875C11.5046 13.8075 11.9096 13.5675 12.2771 13.2825L14.0696 14.0025C14.2346 14.0625 14.4221 14.0026 14.5121 13.8375L15.9521 11.3475C16.0421 11.1825 16.0046 10.995 15.8621 10.89L14.3546 9.70505ZM8.99959 11.7C7.51459 11.7 6.29959 10.485 6.29959 9.00005C6.29959 7.51505 7.51459 6.30005 8.99959 6.30005C10.4846 6.30005 11.6996 7.51505 11.6996 9.00005C11.6996 10.485 10.4846 11.7 8.99959 11.7Z" fill="#686868"/>
                                    </g>
                                    <defs>
                                    <clipPath id="clip0_3672_54608pa">
                                    <rect width="18" height="18" fill="white"/>
                                    </clipPath>
                                    </defs>
                                </svg>
                            </div>
                        </ng-container>
                    </ng-container>
                    {{activeSections()}}
                </a>
    
                <div class="expanded_block_out" #navPages [ngClass]="{'show': isOpenBoard}">
                    <div class="expanded_block_in card_exp">
                        <div class="title_nav">
                            <span>
                                {{ "Workspace section" | translate }}
                            </span>
                        </div>
                        <div class="exp_pages">
                            <a class="exp_page" [routerLink]="['/tasks']" [queryParams]="getParams()" [class.active]="isLinkActive('/tasks')" (click)="closeNav(navPages); $event.preventDefault(); goToPage('/tasks')">
                                <div class="icon">      
                                    <svg style="width: 18px; height: 18px" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16 12V16H2V12H16ZM17 10H1C0.45 10 0 10.45 0 11V17C0 17.55 0.45 18 1 18H17C17.55 18 18 17.55 18 17V11C18 10.45 17.55 10 17 10ZM4 15.5C3.18 15.5 2.5 14.83 2.5 14C2.5 13.17 3.18 12.5 4 12.5C4.82 12.5 5.5 13.17 5.5 14C5.5 14.83 4.83 15.5 4 15.5ZM16 2V6H2V2H16ZM17 0H1C0.45 0 0 0.45 0 1V7C0 7.55 0.45 8 1 8H17C17.55 8 18 7.55 18 7V1C18 0.45 17.55 0 17 0ZM4 5.5C3.18 5.5 2.5 4.83 2.5 4C2.5 3.17 3.18 2.5 4 2.5C4.82 2.5 5.5 3.18 5.5 4C5.5 4.82 4.83 5.5 4 5.5Z" fill="#686868"/>
                                    </svg>
                                </div>
    
                                <span>
                                    {{ "Cards" | translate }}
                                </span>
                            </a>
                            <a class="exp_page" [routerLink]="['/jobs']" [queryParams]="getParams()" [class.active]="isLinkActive('/jobs')" (click)="closeNav(navPages); $event.preventDefault(); goToPage('/jobs')">
                                <div class="icon">      
                                    <svg style="width: 18px; height: 15px" width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0 14.4H18V10.8H0V14.4ZM1.8 11.7H3.6V13.5H1.8V11.7ZM0 0V3.6H18V0H0ZM3.6 2.7H1.8V0.9H3.6V2.7ZM0 9H18V5.4H0V9ZM1.8 6.3H3.6V8.1H1.8V6.3Z" fill="#686868"/>
                                    </svg>
                                </div>
                                    
                                <span>
                                    {{ "Jobs" | translate }}
                                </span>
                            </a>
                            <a class="exp_page" [routerLink]="['/chats']" [queryParams]="getParams()" [class.active]="isLinkActive('/chats')" (click)="closeNav(navPages); $event.preventDefault(); goToPage('/chats')">
                                <div class="icon">       
                                    <svg style="width: 18px; height: 18px" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16.2 0H1.8C1.32261 0 0.864773 0.189642 0.527208 0.527208C0.189642 0.864773 0 1.32261 0 1.8V18L3.6 14.4H16.2C16.6774 14.4 17.1352 14.2104 17.4728 13.8728C17.8104 13.5352 18 13.0774 18 12.6V1.8C18 0.801 17.19 0 16.2 0Z" fill="#686868"/>
                                    </svg>
                                </div>
    
                                <span>
                                    {{ "Chats" | translate }}
                                </span>
                            </a>
    
                            <div class="exp_divider"></div>
    
                            <a (mouseenter)="onMouseEnter($event)" (mouseleave)="onMouseLeave($event)" *ngIf="company | perm:'see_task_channels'" class="exp_page q_posts_out" [routerLink]="['/urls-start']" [queryParams]="urlParams()" [class.active]="activeSections() == 'Posts Start'" (click)="closeNav(navPages); $event.preventDefault(); goToPage('/urls-start')">
                                <div class="d-f-c" style="position: relative;">
                                    <div class="icon">     
                                        <svg style="width: 18px; min-width: 18px; height: 9px; min-height: 9px; transform: none;" width="18" height="9" viewBox="0 0 18 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.5 0H9.9V1.8H13.5C14.985 1.8 16.2 3.015 16.2 4.5C16.2 5.985 14.985 7.2 13.5 7.2H9.9V9H13.5C15.984 9 18 6.984 18 4.5C18 2.016 15.984 0 13.5 0ZM8.1 7.2H4.5C3.015 7.2 1.8 5.985 1.8 4.5C1.8 3.015 3.015 1.8 4.5 1.8H8.1V0H4.5C2.016 0 0 2.016 0 4.5C0 6.984 2.016 9 4.5 9H8.1V7.2ZM5.4 3.6H12.6V5.4H5.4V3.6Z" fill="#686868"/>
                                        </svg>
                                    </div>
        
                                    <span>
                                        {{ "Posts" | translate }}
                                    </span>
                                    <!-- {{haveActiveFilters()}} -->
                                    <mat-icon class="home_icon" *ngIf="haveActiveFilters()" [class.active]="activeSections() == 'Posts'" (click)="closeNav(navPages); $event.preventDefault(); $event.stopPropagation(); goToPage('/urls')">filter_list</mat-icon>
                                </div>
                                
                                <div style="position: relative;">
                                    <input [ngClass]="{'showed': postsHovered}" (keydown.enter)="closeNav(navPages); $event.preventDefault(); goToPage('/urls-start')" [(ngModel)]="postsQ" class="q_posts" matInput placeholder="{{ 'Search' | translate }}">
                                    <mat-icon class="on_go">search</mat-icon>
                                </div>
                            </a>

                            <a *ngIf="company | perm:'see_task_channels'" [routerLink]="['/urls-dashboard']" [queryParams]="getParams()" class="exp_page" [class.active]="activeSections() == 'Post Dashboards'" (click)="closeNav(navPages); $event.preventDefault(); goToPage('/urls-dashboard')">
                                <div class="icon">     
                                    <svg style="width: 18px; min-width: 18px; height: 18px; min-height: 18px;" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.1111 0V6.66667H20V0M11.1111 20H20V8.88889H11.1111M0 20H8.88889V13.3333H0M0 11.1111H8.88889V0H0V11.1111Z" fill="#686868"/>
                                    </svg>
                                </div>
                                <span>
                                    {{ "Post Dashboards" | translate }}
                                </span>
                            </a>
                            <a *ngIf="company | perm:'see_task_channels'" [routerLink]="['/outlets-dashboard']" [queryParams]="getParams()" class="exp_page" [class.active]="activeSections() == 'Outlet Dashboards'" (click)="closeNav(navPages); $event.preventDefault(); goToPage('/outlets-dashboard')">
                                <div class="icon">     
                                    <svg style="width: 18px; min-width: 18px; height: 18px; min-height: 18px;" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.66667 0V16.6667H10V0H6.66667ZM10 2.22222L14.4444 16.6667L17.7778 15.5556L13.3333 1.11111L10 2.22222ZM2.22222 2.22222V16.6667H5.55556V2.22222H2.22222ZM0 17.7778V20H20V17.7778H0Z" fill="#686868"/>
                                    </svg>
                                </div>
                                <span>
                                    {{ "Outlet Dashboards" | translate }}
                                </span>
                            </a>
    
                            <div class="exp_divider" *ngIf="company | perm:'see_task_channels'"></div>
                          
                            <a style="position: relative;" [routerLink]="['/board-list']" [queryParams]="getParams()" class="exp_page" [class.active]="activeSections() == 'Board by Order'" (click)="closeNav(navPages); $event.preventDefault(); goToPage('/board-list')">
                                <div class="icon">     
                                    <svg style="width: 18px; height: 14px;" width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.8 13.5H18V11.7H10.8V13.5ZM10.8 8.55H18V6.75H10.8V8.55ZM10.8 3.6H18V1.8H10.8V3.6ZM0 7.65C0 4.428 2.628 1.8 5.85 1.8H6.3V0L9 2.7L6.3 5.4V3.6H5.85C3.6 3.6 1.8 5.4 1.8 7.65C1.8 9.9 3.6 11.7 5.85 11.7H9V13.5H5.85C2.628 13.5 0 10.872 0 7.65Z" fill="#686868"/>
                                    </svg>
                                </div>
                                <span style="white-space: nowrap;">
                                    {{ "Board by Order" | translate }}
                                </span>
                                <div class="project_page_link" (click)="$event.preventDefault(); $event.stopPropagation(); goToUserBoard.toggle()">
                                    <mat-icon>account_circle</mat-icon>
                                    <mat-form-field appearance="legacy" class="hide-form-field">
                                        <mat-label>{{"User" | translate}}</mat-label>
                                        <mat-select [sdType]="'custom-panel-class'" (openedChange)="onOpenedChange($event)" #goToUserBoard [disableOptionCentering]="true" selectDir (selectionChange)="goToFilterPage($event, company, '/board-list')">
                                            <mat-option>
                                                <ngx-mat-select-search 
                                                placeholderLabel="{{ 'Search' | translate }}" 
                                                noEntriesFoundLabel="{{ 'no matching employee found' | translate }}"
                                                [formControl]="employeesControl"></ngx-mat-select-search>
                                            </mat-option>
                                            <mat-option [value]="item.id" *ngFor="let item of employees$ | async">
                                                <app-user [class]="'user_div'" [employee]="item" [imgRoute]="imgRoute" [host]="host" [company]="company"></app-user>
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </a>


                            <a [routerLink]="['/board']" [queryParams]="getParams()" class="exp_page" [class.active]="activeSections() == 'Board by Date'" (click)="closeNav(navPages); $event.preventDefault(); goToPage('/board')">
                                <div class="icon">
                                    <svg style="width: 16px; height: 18px;" width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.77778 11.5556H3.55556V13.3333H9.77778M14.2222 16H1.77778V6.22222H14.2222M14.2222 1.77778H13.3333V0H11.5556V1.77778H4.44444V0H2.66667V1.77778H1.77778C0.791111 1.77778 0 2.57778 0 3.55556V16C0 16.4715 0.187301 16.9237 0.520699 17.2571C0.854097 17.5905 1.30628 17.7778 1.77778 17.7778H14.2222C14.6937 17.7778 15.1459 17.5905 15.4793 17.2571C15.8127 16.9237 16 16.4715 16 16V3.55556C16 3.08406 15.8127 2.63188 15.4793 2.29848C15.1459 1.96508 14.6937 1.77778 14.2222 1.77778ZM12.4444 8H3.55556V9.77778H12.4444V8Z" fill="#686868"/>
                                    </svg>
                                </div>
                                <span>
                                    {{ "Board by Date" | translate }}
                                </span>
                            </a>
    
                            <!-- <div class="exp_divider"></div>
    
                            <div class="exp_page" [class.active]="isLinkActive('/files')" (click)="closeNav(navPages); $event.preventDefault(); goToPage('/files')" *ngIf="company | perm:'see_files'">
                                <div class="icon">     
                                    <svg style="width: 9px; height: 18px" width="9" height="18" viewBox="0 0 9 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.77273 4.09091V13.5C7.77273 14.368 7.42792 15.2004 6.81417 15.8142C6.20041 16.4279 5.36798 16.7727 4.5 16.7727C3.63202 16.7727 2.79959 16.4279 2.18583 15.8142C1.57208 15.2004 1.22727 14.368 1.22727 13.5V3.27273C1.22727 2.73024 1.44278 2.20997 1.82637 1.82637C2.20997 1.44278 2.73024 1.22727 3.27273 1.22727C3.81522 1.22727 4.33548 1.44278 4.71908 1.82637C5.10268 2.20997 5.31818 2.73024 5.31818 3.27273V11.8636C5.31818 12.0806 5.23198 12.2887 5.07854 12.4422C4.9251 12.5956 4.717 12.6818 4.5 12.6818C4.283 12.6818 4.0749 12.5956 3.92146 12.4422C3.76802 12.2887 3.68182 12.0806 3.68182 11.8636V4.09091H2.45455V11.8636C2.45455 12.4061 2.67005 12.9264 3.05365 13.31C3.43724 13.6936 3.95751 13.9091 4.5 13.9091C5.04249 13.9091 5.56276 13.6936 5.94635 13.31C6.32995 12.9264 6.54545 12.4061 6.54545 11.8636V3.27273C6.54545 2.40475 6.20065 1.57232 5.58689 0.95856C4.97314 0.344804 4.14071 0 3.27273 0C2.40475 0 1.57231 0.344804 0.958559 0.95856C0.344804 1.57232 0 2.40475 0 3.27273V13.5C0 14.6935 0.474106 15.8381 1.31802 16.682C2.16193 17.5259 3.30653 18 4.5 18C5.69347 18 6.83807 17.5259 7.68198 16.682C8.52589 15.8381 9 14.6935 9 13.5V4.09091H7.77273Z" fill="#686868"/>
                                    </svg>
                                </div>
    
                                <span>
                                    {{ "Files" | translate }}
                                </span>
                            </div> -->
                            <a style="position: relative;" [routerLink]="['/files']" [queryParams]="getParams('/files')" class="exp_page" [class.active]="activeSections() == 'Files'" (click)="closeNav(navPages); $event.preventDefault(); goToPage('/files')">
                                <div class="icon">
                                    <svg style="width: 9px; height: 18px" width="9" height="18" viewBox="0 0 9 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.77273 4.09091V13.5C7.77273 14.368 7.42792 15.2004 6.81417 15.8142C6.20041 16.4279 5.36798 16.7727 4.5 16.7727C3.63202 16.7727 2.79959 16.4279 2.18583 15.8142C1.57208 15.2004 1.22727 14.368 1.22727 13.5V3.27273C1.22727 2.73024 1.44278 2.20997 1.82637 1.82637C2.20997 1.44278 2.73024 1.22727 3.27273 1.22727C3.81522 1.22727 4.33548 1.44278 4.71908 1.82637C5.10268 2.20997 5.31818 2.73024 5.31818 3.27273V11.8636C5.31818 12.0806 5.23198 12.2887 5.07854 12.4422C4.9251 12.5956 4.717 12.6818 4.5 12.6818C4.283 12.6818 4.0749 12.5956 3.92146 12.4422C3.76802 12.2887 3.68182 12.0806 3.68182 11.8636V4.09091H2.45455V11.8636C2.45455 12.4061 2.67005 12.9264 3.05365 13.31C3.43724 13.6936 3.95751 13.9091 4.5 13.9091C5.04249 13.9091 5.56276 13.6936 5.94635 13.31C6.32995 12.9264 6.54545 12.4061 6.54545 11.8636V3.27273C6.54545 2.40475 6.20065 1.57232 5.58689 0.95856C4.97314 0.344804 4.14071 0 3.27273 0C2.40475 0 1.57231 0.344804 0.958559 0.95856C0.344804 1.57232 0 2.40475 0 3.27273V13.5C0 14.6935 0.474106 15.8381 1.31802 16.682C2.16193 17.5259 3.30653 18 4.5 18C5.69347 18 6.83807 17.5259 7.68198 16.682C8.52589 15.8381 9 14.6935 9 13.5V4.09091H7.77273Z" fill="#686868"/>
                                    </svg>
                                </div>
                                <span>
                                    {{ "Files" | translate }}
                                </span>

                                <a [routerLink]="['/projects']" [queryParams]="getParams('/projects')" class="project_page_link" [class.active]="isLinkActive('/projects')" (click)="closeNav(navPages); $event.preventDefault(); $event.stopPropagation(); goToPage('/projects')">
                                    <mat-icon>movie_filter</mat-icon>
                                </a>
                            </a>
    
                            <div class="exp_divider"></div>
                            
                            <a [routerLink]="['/settings']" [queryParams]="getParams('/settings')" class="exp_page" [class.active]="isLinkActive('/settings')" (click)="closeNav(navPages); $event.preventDefault(); goToPage('/settings')">
                                <div class="icon">      
                                    <svg style="width: 18px; height: 18px" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_3672_54608p)">
                                        <path d="M14.3546 9.70505C14.3846 9.48005 14.3996 9.24755 14.3996 9.00005C14.3996 8.76005 14.3846 8.52005 14.3471 8.29505L15.8696 7.11005C16.0046 7.00505 16.0421 6.80255 15.9596 6.65255L14.5196 4.16255C14.4296 3.99755 14.2421 3.94505 14.0771 3.99755L12.2846 4.71755C11.9096 4.43255 11.5121 4.19255 11.0696 4.01255L10.7996 2.10755C10.7696 1.92755 10.6196 1.80005 10.4396 1.80005H7.55959C7.37959 1.80005 7.23709 1.92755 7.20709 2.10755L6.93709 4.01255C6.49459 4.19255 6.08959 4.44005 5.72209 4.71755L3.92959 3.99755C3.76459 3.93755 3.57709 3.99755 3.48709 4.16255L2.05459 6.65255C1.96459 6.81005 1.99459 7.00505 2.14459 7.11005L3.66709 8.29505C3.62959 8.52005 3.59959 8.76755 3.59959 9.00005C3.59959 9.23255 3.61459 9.48005 3.65209 9.70505L2.12959 10.89C1.99459 10.995 1.95709 11.1975 2.03959 11.3475L3.47959 13.8375C3.56959 14.0026 3.75709 14.055 3.92209 14.0025L5.71459 13.2825C6.08959 13.5675 6.48709 13.8075 6.92959 13.9875L7.19959 15.8925C7.23709 16.0725 7.37959 16.2 7.55959 16.2H10.4396C10.6196 16.2 10.7696 16.0725 10.7921 15.8925L11.0621 13.9875C11.5046 13.8075 11.9096 13.5675 12.2771 13.2825L14.0696 14.0025C14.2346 14.0625 14.4221 14.0026 14.5121 13.8375L15.9521 11.3475C16.0421 11.1825 16.0046 10.995 15.8621 10.89L14.3546 9.70505ZM8.99959 11.7C7.51459 11.7 6.29959 10.485 6.29959 9.00005C6.29959 7.51505 7.51459 6.30005 8.99959 6.30005C10.4846 6.30005 11.6996 7.51505 11.6996 9.00005C11.6996 10.485 10.4846 11.7 8.99959 11.7Z" fill="#686868"/>
                                        </g>
                                        <defs>
                                        <clipPath id="clip0_3672_54608p">
                                        <rect width="18" height="18" fill="white"/>
                                        </clipPath>
                                        </defs>
                                    </svg>
                                </div>
    
                                <span>
                                    {{ "Workspace Settings" | translate }}
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
            </li>
            
            <li class="nav_filter" [ngClass]="{'no_active': activeSections() == 'Analytic' || (activeSections() == 'Settings' && ['finances_dashboard', 'finances', 'automation', 'checklists', 'workspace_members', 'individuals', 'schedules', 'partner_workspaces', 'questionnaires', 'total_usage', 'import-data', 'sftp', 'parameters', 'bookmarks', 'task_types', 'job_types', 'structure', 'form', 'users', 'categorization', 'discontinued', 'stats', 'chats', 'company', 'manage-auth', 'profiles'].includes(page))}" (mouseenter)="mouseEnterNavPar($event)">
                <a>{{ "Card" | translate }}</a>
    
                <div class="expanded_block_out" *ngIf="taskStatuses && taskStatuses.length">
                    <div class="expanded_block_in exp_def">
                        <div class="title_nav">
                            <span>
                                {{ "Card type" | translate }}
                            </span>
                        </div>
                        <ng-container [ngTemplateOutlet]="defaultFilter" [ngTemplateOutletContext]="{ array: taskStatuses, asArray: taskStatuses$, search: taskStatusesControl, control: 'task_status_id' }"></ng-container>
                    </div>
                </div>
            </li>
            <li class="nav_filter" [ngClass]="{'no_active': activeSections() == 'Analytic' || (activeSections() == 'Settings' && (['finances_dashboard', 'finances', 'workspace_members', 'individuals', 'schedules', 'partner_workspaces', 'questionnaires', 'total_usage', 'import-data', 'sftp', 'parameters', 'bookmarks', 'task_types', 'job_types', 'structure', 'form', 'users', 'categorization', 'discontinued', 'stats', 'chats', 'company', 'manage-auth', 'profiles'].includes(page) || (page == 'automation' && ['', 'card_templates', 'card_scenario_templates', 'job_scenario_templates', 'automation_of_tag_assigning'].includes(pageTab))))}" (mouseenter)="mouseEnterNavPar($event)">
                <a>{{ "Job" | translate }}</a>
                <div class="expanded_block_out" *ngIf="jobTypes && jobTypes.length">
                    <div class="expanded_block_in exp_def">
                        <div class="title_nav">
                            <span (click)="logJobType()">
                                {{ "Job type" | translate }}
                            </span>
                        </div>
                        <ng-container *ngIf="activeSections() == 'Jobs'; else elseTemplateIsJobsJobs">
                            <section class="">
                                <ng-container *ngIf="jobTypes.length > 7; else elseTemplateJobTypeStatus">
                                    <div class="filter_exp_search">
                                        <mat-form-field appearance="legacy" class="filter_form search">
                                            <input class="search_inp" [formControl]="jobTypesControl" matInput>
                                            <mat-icon matSuffix>search</mat-icon>
                                        </mat-form-field>
                                    </div>
                                    <ng-container *ngIf="!switched">
                                        <form [formGroup]="filter" class="exp_form" [appDeviceClass]="'custom-scroll'">
                                            <mat-selection-list [multiple]="false" formControlName="task_operation_operation_id" (selectionChange)="selectChange($event, false, 'task_operation_operation_id')">
                                                <ng-container *ngFor="let fil of jobTypes$ | async">
                                                    <mat-list-option class="cst_o flex_text job-option-2" [value]="fil.id" [ngClass]="{'active': filter.get('task_operation_operation_id').value.includes(fil.id)}">
                                                        <div class="d-f-c">
                                                            <app-outlet-line [class]="'for_nav'" [channel]="fil" [company_id]="company_id"></app-outlet-line>
                                                            <mat-checkbox (click)="setOperMulty($event, fil.id, 'task_operation_operation_id')" [disableRipple]="true" class="outlet_fil_check" [checked]="filter.get('task_operation_operation_id').value.includes(fil.id)" color="primary"></mat-checkbox>
                                                        </div>
                                                        <span class="not_assigned" (click)="noOperation($event, fil.id)" [ngClass]="{'active': filter.get('no_operation_id').value == fil.id}">
                                                            <span>
                                                                {{ "n/a" | translate }}
                                                            </span>
                                                        </span>
                                                    </mat-list-option>
                                                </ng-container>
                                            </mat-selection-list>
                                        </form>
                                    </ng-container>
                                </ng-container>
                                <ng-template #elseTemplateJobTypeStatus>
                                    <form [formGroup]="filter" class="exp_form" [appDeviceClass]="'custom-scroll'">
                                        <mat-selection-list [multiple]="false" formControlName="task_operation_operation_id" (selectionChange)="selectChange($event, false, 'task_operation_operation_id')">
                                            <ng-container *ngFor="let fil of jobTypes">
                                                <mat-list-option class="cst_o flex_text job-option-2" [value]="fil.id" [ngClass]="{'active': filter.get('task_operation_operation_id').value.includes(fil.id)}">
                                                    <div class="d-f-c">
                                                        <app-outlet-line [class]="'for_nav'" [channel]="fil" [company_id]="company_id"></app-outlet-line>
                                                        <mat-checkbox (click)="setOperMulty($event, fil.id, 'task_operation_operation_id')" [disableRipple]="true" class="outlet_fil_check" [checked]="filter.get('task_operation_operation_id').value.includes(fil.id)" color="primary"></mat-checkbox>
                                                    </div>
                                                    <span class="not_assigned" (click)="noOperation($event, fil.id)" [ngClass]="{'active': filter.get('no_operation_id').value == fil.id}">
                                                        <span>
                                                            {{ "n/a" | translate }}
                                                        </span>
                                                    </span>
                                                </mat-list-option>
                                            </ng-container>
                                        </mat-selection-list>
                                    </form>
                                </ng-template>
                                <div class="search_fil_line" style="padding-bottom: 10px;" *ngIf="showJobsBtn">
                                    <button (click)="searchFilterSubmit()">{{"Search" | translate}}</button>
                                </div>
                            </section>
                        </ng-container>
                        <ng-template #elseTemplateIsJobsJobs>
                            <ng-container [ngTemplateOutlet]="defaultFilter" [ngTemplateOutletContext]="{ array: jobTypes, asArray: jobTypes$, search: jobTypesControl, control: 'task_operation_operation_id' }"></ng-container>
                        </ng-template>
                    </div>
                </div>
            </li>
            <li class="nav_filter" (mouseenter)="mouseEnterNavPar($event)" [ngClass]="{'no_active': activeSections() == 'Analytic' || (activeSections() == 'Settings' && (['finances_dashboard', 'checklists', 'workspace_members', 'individuals', 'schedules', 'partner_workspaces', 'questionnaires', 'total_usage', 'import-data', 'sftp', 'parameters', 'bookmarks', 'task_types', 'job_types', 'structure', 'form', 'users', 'categorization', 'discontinued', 'stats', 'chats', 'company', 'manage-auth'].includes(page) || (page == 'automation' && ['', 'card_templates', 'card_scenario_templates', 'job_scenario_templates', 'automation_of_tag_assigning'].includes(pageTab))))}">
                <a>{{ "User" | translate }}</a>
                <div class="expanded_block_out" *ngIf="employees && employees.length && clients && clients.length">
                    <div class="expanded_block_in exp_subs exp_date">
                        <div class="title_nav">
                            <span>
                                {{ "User" | translate }} <ng-container *ngIf="activeSections() == 'Posts'">(Assigned)</ng-container>
                            </span>
                        </div>
                        <ng-container *ngIf="activeSections() == 'Posts'; else elseTemplateNotPostList">
                            <section class="sub_filter_wrp short_sub_wrp">
                                <div class="subs" [appDeviceClass]="'custom-scroll'">
                                    <div class="sub" *ngFor="let tab of postsModel" [ngClass]="{'active': postsControl.value === tab.id}" (click)="patchControl(postsControl, tab.id)">
                                        <ng-container *ngIf="tab?.icon == 'edit'">
                                            <svg style="width: 8px; height: 8px;" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clip-path="url(#clip0_3980_54429fil)">
                                                <path d="M1 5.7501V7.0001H2.25L5.93667 3.31343L4.68667 2.06343L1 5.7501ZM6.90333 2.34677C7.03333 2.21677 7.03333 2.00677 6.90333 1.87677L6.12333 1.09677C5.99333 0.966768 5.78333 0.966768 5.65333 1.09677L5.04333 1.70677L6.29333 2.95677L6.90333 2.34677Z" fill="#686868"/>
                                                </g>
                                                <defs>
                                                <clipPath id="clip0_3980_54429fil">
                                                <rect width="8" height="8" fill="white"/>
                                                </clipPath>
                                                </defs>
                                            </svg>
                                        </ng-container>
                                        <ng-container *ngIf="tab?.icon == 'plus'">
                                            <svg style="left: 10px; width: 12px; height: 12px;" version="1.1" viewBox="0 0 512 512" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                                <path fill="#686868" d="M381,236H276V131c0-11-9-20-20-20s-20,9-20,20v105H131c-11,0-20,9-20,20s9,20,20,20h105v105c0,11,9,20,20,20  s20-9,20-20V276h105c11,0,20-9,20-20S392,236,381,236z"/>
                                            </svg>
                                        </ng-container>
                                        {{ tab.name | translate }} <ng-container *ngIf="tab?.onlyThis">*</ng-container>
                                    </div>
                                </div>
                                <div class="block" style="padding: 0; min-height: 268px !important; width: calc(100% - 147px) !important;">
                                    <div class="sub_tabs" *ngIf="postsModel[postsControl.value].filters.length > 1">
                                        <div class="sub_tabs_in">
                                            <div class="sub_tab" *ngFor="let subTab of postsModel[postsControl.value].filters" [ngClass]="{'active': postsModel[postsControl.value].activeFilter.value === subTab.id}" (click)="postsModel[postsControl.value].filters.length > 1 ? patchControl(postsModel[postsControl.value].activeFilter, subTab.id) : null;">
                                                {{subTab.name}} <ng-container *ngIf="subTab?.onlyThis">*</ng-container>
                                            </div>
                                        </div>
                                    </div>
                                    <ng-container *ngIf="!switched">
                                        <ng-container *ngIf="postsModel[postsControl.value].filters[postsModel[postsControl.value].activeFilter['value']].data.length > 7; else elseTemplateSubL">
                                            <div class="filter_exp_search">
                                                <mat-form-field appearance="legacy" class="filter_form search">
                                                    <input class="search_inp" [formControl]="postsModel[postsControl.value].filters[postsModel[postsControl.value].activeFilter['value']].control" matInput>
                                                    <mat-icon matSuffix>search</mat-icon>
                                                </mat-form-field>
                                                </div>
                                            <form [formGroup]="filter" class="exp_form short_sub" [appDeviceClass]="'custom-scroll'">
                                                <mat-selection-list [multiple]="false" formControlName="{{postsModel[postsControl.value].filters[postsModel[postsControl.value].activeFilter['value']].key}}" (selectionChange)="selectChange($event)">
                                                    <mat-list-option [value]="0" *ngIf="postsControl.value == 0" [ngClass]="{'active': 0 === filter.get(postsModel[postsControl.value].filters[postsModel[postsControl.value].activeFilter['value']].key).value}">
                                                        <div class="user_div">
                                                            <mat-icon style="visibility: none;">user</mat-icon>
                                                            <span>
                                                                {{ "User not set" | translate }}
                                                            </span>
                                                        </div>
                                                    </mat-list-option>
                                                    <ng-container *ngFor="let fil of postsModel[postsControl.value].filters[postsModel[postsControl.value].activeFilter['value']].asData | async">
                                                        <ng-container *ngIf="postsControl.value == 2 || (postsControl.value == 1 && postsModel[postsControl.value].filters[postsModel[postsControl.value].activeFilter['value']].id == 1); else elseTemplateTypeSub">
                                                            <mat-list-option [value]="fil.partner_company_id" [ngClass]="{'active': fil.partner_company_id == filter.get(postsModel[postsControl.value].filters[postsModel[postsControl.value].activeFilter['value']].key).value}">
                                                                <app-user [class]="'user_div'" [companyEmployee]="fil?.partnerCompany" [imgRoute]="imgRoute" [host]="host" [company]="company"></app-user>
                                                            </mat-list-option>
                                                        </ng-container>
                                                        <ng-template #elseTemplateTypeSub>
                                                            <mat-list-option [value]="fil.id" [ngClass]="{'active': fil.id == filter.get(postsModel[postsControl.value].filters[postsModel[postsControl.value].activeFilter['value']].key).value}">
                                                                <app-user [class]="'user_div'" [employee]="fil" [imgRoute]="imgRoute" [host]="host" [company]="company"></app-user>
                                                            </mat-list-option>
                                                        </ng-template>
                                                    </ng-container>
                                                </mat-selection-list>
                                            </form>
                                        </ng-container>
                                        <ng-template #elseTemplateSubL>
                                            <form [formGroup]="filter" class="exp_form short_sub" [appDeviceClass]="'custom-scroll'">
                                                <mat-selection-list [multiple]="false" formControlName="{{postsModel[postsControl.value].filters[postsModel[postsControl.value].activeFilter['value']].key}}" (selectionChange)="selectChange($event)">
                                                    <mat-list-option [value]="0" *ngIf="postsControl.value == 0" [ngClass]="{'active': 0 === filter.get(postsModel[postsControl.value].filters[postsModel[postsControl.value].activeFilter['value']].key).value}">
                                                        <div class="user_div">
                                                            <mat-icon style="visibility: none;">user</mat-icon>
                                                            <span>
                                                                {{ "User not set" | translate }}
                                                            </span>
                                                        </div>
                                                    </mat-list-option>
                                                    <ng-container *ngFor="let fil of postsModel[postsControl.value].filters[postsModel[postsControl.value].activeFilter['value']].data">
                                                        <ng-container *ngIf="postsControl.value == 2 || (postsControl.value == 1 && postsModel[postsControl.value].filters[postsModel[postsControl.value].activeFilter['value']].id == 1); else elseTemplateTypeSub">
                                                            <mat-list-option [value]="fil.partner_company_id" [ngClass]="{'active': fil.partner_company_id == filter.get(postsModel[postsControl.value].filters[postsModel[postsControl.value].activeFilter['value']].key).value}">
                                                                <app-user [class]="'user_div'" [companyEmployee]="fil?.partnerCompany" [imgRoute]="imgRoute" [host]="host" [company]="company"></app-user>
                                                            </mat-list-option>
                                                        </ng-container>
                                                        <ng-template #elseTemplateTypeSub>
                                                            <mat-list-option [value]="fil.id" [ngClass]="{'active': fil.id == filter.get(postsModel[postsControl.value].filters[postsModel[postsControl.value].activeFilter['value']].key).value}">
                                                                <app-user [class]="'user_div'" [employee]="fil" [imgRoute]="imgRoute" [host]="host" [company]="company"></app-user>
                                                            </mat-list-option>
                                                        </ng-template>
                                                    </ng-container>
                                                    
                                                </mat-selection-list>
                                            </form>
                                        </ng-template>
                                    </ng-container>
                                </div>
                            </section>
                        </ng-container>
                        <ng-template #elseTemplateNotPostList>
                            <section class="sub_filter_wrp short_sub_wrp">
                                <div class="subs" [appDeviceClass]="'custom-scroll'">
                                    <div class="sub" *ngFor="let tab of stakeholdersModel" [ngClass]="{'active': stakeholderControl.value === tab.id, 'no_active': (activeSections() == 'Settings' && ['finances', 'automation'].includes(page) && ['', 'current_activity', 'statements'].includes(pageTab) && [1,2].includes(tab.id)) || (activeSections() == 'Settings' && ['finances'].includes(page) && ['payouts'].includes(pageTab) && [1].includes(tab.id)) || (activeSections() == 'Settings' && ['profiles'].includes(page) && [''].includes(pageTab) && [0,1].includes(tab.id))}" (click)="patchControl(stakeholderControl, tab.id)">
                                        {{ tab.name | translate }}
                                    </div>
                                </div>
                                <div class="block" style="padding: 0; min-height: 268px !important; width: calc(100% - 147px) !important;">
                                    <div class="sub_tabs" *ngIf="stakeholdersModel[stakeholderControl.value].filters.length > 1">
                                        <div class="sub_tabs_in">
                                            <div class="sub_tab" *ngFor="let subTab of stakeholdersModel[stakeholderControl.value].filters" [ngClass]="{'active': stakeholdersModel[stakeholderControl.value].activeFilter.value === subTab.id}" (click)="stakeholdersModel[stakeholderControl.value].filters.length > 1 ? patchControl(stakeholdersModel[stakeholderControl.value].activeFilter, subTab.id) : null;">
                                                {{subTab.name}}
                                            </div>
                                        </div>
                                    </div>
                                    <ng-container *ngIf="!switched">
                                        <ng-container *ngIf="stakeholdersModel[stakeholderControl.value].filters[stakeholdersModel[stakeholderControl.value].activeFilter['value']].data.length > 7; else elseTemplateSubL">
                                            <div class="filter_exp_search">
                                                <mat-form-field appearance="legacy" class="filter_form search">
                                                    <input class="search_inp" [formControl]="stakeholdersModel[stakeholderControl.value].filters[stakeholdersModel[stakeholderControl.value].activeFilter['value']].control" matInput>
                                                    <mat-icon matSuffix>search</mat-icon>
                                                </mat-form-field>
                                                </div>
                                            <form [formGroup]="filter" class="exp_form short_sub" [appDeviceClass]="'custom-scroll'">
                                                <!-- <pre>
                                                    {{stakeholdersModel[stakeholderControl.value].filters[stakeholdersModel[stakeholderControl.value].activeFilter['value']].asData | async | json}}
                                                </pre> -->
                                                <mat-selection-list [multiple]="false" formControlName="{{stakeholdersModel[stakeholderControl.value].filters[stakeholdersModel[stakeholderControl.value].activeFilter['value']].key}}" (selectionChange)="selectChange($event)">
                                                    <mat-list-option [value]="0" *ngIf="stakeholderControl.value == 0" [ngClass]="{'active': 0 === filter.get(stakeholdersModel[stakeholderControl.value].filters[stakeholdersModel[stakeholderControl.value].activeFilter['value']].key).value}">
                                                        <div class="user_div">
                                                            <mat-icon style="visibility: none;">user</mat-icon>
                                                            <span>
                                                                {{ "User not set" | translate }}
                                                            </span>
                                                        </div>
                                                    </mat-list-option>
                                                    <ng-container *ngFor="let fil of stakeholdersModel[stakeholderControl.value].filters[stakeholdersModel[stakeholderControl.value].activeFilter['value']].asData | async">
                                                        <ng-container *ngIf="stakeholderControl.value == 2 || (stakeholderControl.value == 1 && stakeholdersModel[stakeholderControl.value].filters[stakeholdersModel[stakeholderControl.value].activeFilter['value']].id == 1); else elseTemplateTypeSub">
                                                            <mat-list-option [value]="fil.partner_company_id" [ngClass]="{'active': fil.partner_company_id == filter.get(stakeholdersModel[stakeholderControl.value].filters[stakeholdersModel[stakeholderControl.value].activeFilter['value']].key).value}">
                                                                <app-user [class]="'user_div'" [companyEmployee]="fil?.partnerCompany" [imgRoute]="imgRoute" [host]="host" [company]="company"></app-user>
                                                            </mat-list-option>
                                                        </ng-container>
                                                        <ng-template #elseTemplateTypeSub>
                                                            <mat-list-option [value]="fil.id" [ngClass]="{'active': fil.id == filter.get(stakeholdersModel[stakeholderControl.value].filters[stakeholdersModel[stakeholderControl.value].activeFilter['value']].key).value}">
                                                                <app-user [class]="'user_div'" [employee]="fil" [imgRoute]="imgRoute" [host]="host" [company]="company"></app-user>
                                                            </mat-list-option>
                                                        </ng-template>
                                                    </ng-container>
                                                </mat-selection-list>
                                            </form>
                                        </ng-container>
                                        <ng-template #elseTemplateSubL>
                                            <form [formGroup]="filter" class="exp_form short_sub" [appDeviceClass]="'custom-scroll'">
                                                <mat-selection-list [multiple]="false" formControlName="{{stakeholdersModel[stakeholderControl.value].filters[stakeholdersModel[stakeholderControl.value].activeFilter['value']].key}}" (selectionChange)="selectChange($event)">
                                                    <mat-list-option [value]="0" *ngIf="stakeholderControl.value == 0" [ngClass]="{'active': 0 === filter.get(stakeholdersModel[stakeholderControl.value].filters[stakeholdersModel[stakeholderControl.value].activeFilter['value']].key).value}">
                                                        <div class="user_div">
                                                            <mat-icon style="visibility: none;">user</mat-icon>
                                                            <span>
                                                                {{ "User not set" | translate }}
                                                            </span>
                                                        </div>
                                                    </mat-list-option>
                                                    <ng-container *ngFor="let fil of stakeholdersModel[stakeholderControl.value].filters[stakeholdersModel[stakeholderControl.value].activeFilter['value']].data">
                                                        <ng-container *ngIf="stakeholderControl.value == 2 || (stakeholderControl.value == 1 && stakeholdersModel[stakeholderControl.value].filters[stakeholdersModel[stakeholderControl.value].activeFilter['value']].id == 1); else elseTemplateTypeSub">
                                                            <mat-list-option [value]="fil.partner_company_id" [ngClass]="{'active': fil.partner_company_id == filter.get(stakeholdersModel[stakeholderControl.value].filters[stakeholdersModel[stakeholderControl.value].activeFilter['value']].key).value}">
                                                                <app-user [class]="'user_div'" [companyEmployee]="fil?.partnerCompany" [imgRoute]="imgRoute" [host]="host" [company]="company"></app-user>
                                                            </mat-list-option>
                                                        </ng-container>
                                                        <ng-template #elseTemplateTypeSub>
                                                            <mat-list-option [value]="fil.id" [ngClass]="{'active': fil.id == filter.get(stakeholdersModel[stakeholderControl.value].filters[stakeholdersModel[stakeholderControl.value].activeFilter['value']].key).value}">
                                                                <app-user [class]="'user_div'" [employee]="fil" [imgRoute]="imgRoute" [host]="host" [company]="company"></app-user>
                                                            </mat-list-option>
                                                        </ng-template>
                                                    </ng-container>
                                                    
                                                </mat-selection-list>
                                            </form>
                                        </ng-template>
                                    </ng-container>
                                </div>
                            </section>
                            <!-- <ng-container [ngTemplateOutlet]="subFilter" [ngTemplateOutletContext]="{ subControl: stakeholderControl, subs: ['Individuals', 'schedules', 'Clients', 'Workspaces'], arrays: [employees, clients, partners], asArrays: [employees$, clients$, partners$], searchs: [employeesControl, clientsControl, partnersControl], controls: ['operation_employee_id', 'client_employee_id', 'operation_partner_company_id'] }"></ng-container> -->
                        </ng-template>
                    </div>
                </div>
            </li>
            <li class="nav_filter" [ngClass]="{'no_active': activeSections() == 'Analytic' || (activeSections() == 'Settings' && ['finances_dashboard', 'finances', 'automation', 'workspace_members', 'partner_workspaces', 'questionnaires', 'total_usage', 'import-data', 'sftp', 'parameters', 'bookmarks', 'task_types', 'job_types', 'structure', 'form', 'users', 'categorization', 'discontinued', 'stats', 'chats', 'company', 'manage-auth'].includes(page))}" (mouseenter)="mouseEnterNavPar($event)">
                <a>{{ "Group" | translate }}</a>

                <ng-container *ngIf="partners && partners.length; else elseTemplateHasPartners">
                    <div class="expanded_block_out">
                        <div class="expanded_block_in exp_subs exp_channels exp_def">
                            <div class="title_nav outlet_title_nav has_tabs">
                                <span>
                                    {{ "Group" | translate }}
                                </span>
                            </div>
                            <section class="sub_filter_wrp">
                                <div class="subs with_search_btn" [appDeviceClass]="'custom-scroll'">
                                    <div class="sub" [ngClass]="{'active': selectedPartnerForGroup == 0}" (click)="selectPartnerGroups(0)">
                                        <div class="d-f-c">
                                            <span class="text_one_line">
                                                {{ "Own groups" | translate }}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="sub" [ngClass]="{'active': selectedPartnerForGroup == item.partner_company_id}" (click)="selectPartnerGroups(item.partner_company_id)" *ngFor="let item of partners; let i = index;">
                                        <div class="d-f-c user_in" style="width: 100%;">
                                            <app-user [class]="'user_div'" [companyEmployee]="item?.partnerCompany" [imgRoute]="imgRoute" [host]="host" [company]="company"></app-user>
                                        </div>
                                    </div>
                                </div>
                                <div class="block">
                                    <ng-container *ngIf="!switched">
                                        <ng-container *ngIf="selectedPartnerForGroup == 0; else elseTemplateIsOwnGroups">
                                            <div class="filter_exp_search">
                                                <mat-form-field appearance="legacy" class="filter_form search">
                                                    <input class="search_inp" [formControl]="groupsControl" matInput placeholder="{{'Search' | translate}}">
                                                    <mat-icon matSuffix>search</mat-icon>
                                                </mat-form-field>
                                                <mat-progress-bar mode="indeterminate" *ngIf="isLoadGroups" class="mat_proggress_w8"></mat-progress-bar>
                                            </div>
                                            <form [formGroup]="filter" class="exp_form" [appDeviceClass]="'custom-scroll'" 
                                            infinite-scroll
                                            [scrollWindow]="false"
                                            [infiniteScrollDisabled]="isLoadGroups"
                                            [infiniteScrollUpDistance]="scrollUpDistance"
                                            [infiniteScrollThrottle]="throttle"
                                            (scrolled)="onDown($event, 'group_id')">
                                                <mat-selection-list [multiple]="true" formControlName="group_id" (selectionChange)="selectChange($event, true)">
                                                    <mat-list-option [value]="0" [ngClass]="{'active': filter.get('group_id').value.includes(0)}" (click)="setFilValue($event, 'group_id', 0)">
                                                        <div class="user_div">
                                                            <span>
                                                                {{ "Group not set" | translate }}
                                                            </span>
                                                        </div>
                                                    </mat-list-option>
                                                    <ng-container *ngFor="let fil of groups$ | async">
                                                        <mat-list-option class="cst_o flex_text" [value]="fil.id" (click)="setFilValue($event, 'group_id', fil.id)" [ngClass]="{'active': filter.get('group_id').value.includes(fil.id)}">
                                                            <div class="d-f-c">
                                                                <span>
                                                                    {{fil.name}}
                                                                </span>
                                                            </div>
                                                        </mat-list-option>
                                                    </ng-container>
                                            </mat-selection-list>
                                            </form> 
                                        </ng-container>
                                        <ng-template #elseTemplateIsOwnGroups>
                                            <ng-container *ngIf="partnerGroups && partnerGroups.length">
                                                <ng-container *ngIf="partnerGroups.length > 7; else elseTemplateSubLGr">
                                                    <div class="filter_exp_search">
                                                        <mat-form-field appearance="legacy" class="filter_form search">
                                                            <input class="search_inp" [formControl]="partnerGroupsControl" matInput>
                                                            <mat-icon matSuffix>search</mat-icon>
                                                        </mat-form-field>
                                                    </div>
                                                    <form [formGroup]="filter" class="exp_form" [appDeviceClass]="'custom-scroll'">
                                                        <mat-selection-list [multiple]="true" formControlName="group_id" (selectionChange)="selectChange($event, true)">
                                                            <mat-list-option [value]="0" [ngClass]="{'active': filter.get('group_id').value.includes(0)}" (click)="setFilValue($event, 'group_id', 0)">
                                                                <div class="user_div">
                                                                    <span>
                                                                        {{ "Group not set" | translate }}
                                                                    </span>
                                                                </div>
                                                            </mat-list-option>
                                                            <ng-container *ngFor="let fil of partnerGroups$ | async">
                                                                <mat-list-option class="cst_o flex_text" [value]="fil.id" [ngClass]="{'active': filter.get('group_id').value.includes(fil.id)}" (click)="setFilValue($event, 'group_id', fil.id)">
                                                                    <div class="d-f-c">
                                                                        <span>
                                                                            {{fil.name}}
                                                                        </span>
                                                                    </div>
                                                                </mat-list-option>
                                                            </ng-container>
                                                    </mat-selection-list>
                                                    </form>
                                                </ng-container>
                                                <ng-template #elseTemplateSubLGr>
                                                    <form [formGroup]="filter" class="exp_form" [appDeviceClass]="'custom-scroll'">
                                                        <mat-selection-list [multiple]="true" formControlName="group_id" (selectionChange)="selectChange($event, true)">
                                                            <mat-list-option [value]="0" [ngClass]="{'active': filter.get('group_id').value.includes(0)}" (click)="setFilValue($event, 'group_id', fil.id)">
                                                                <div class="user_div">
                                                                    <span>
                                                                        {{ "Group not set" | translate }}
                                                                    </span>
                                                                </div>
                                                            </mat-list-option>
                                                            <ng-container *ngFor="let fil of partnerGroups">
                                                                <mat-list-option class="cst_o flex_text" [value]="fil.id" [ngClass]="{'active': filter.get('group_id').value.includes(fil.id)}" (click)="setFilValue($event, 'group_id', fil.id)">
                                                                    <div class="d-f-c">
                                                                        <span>
                                                                            {{fil.name}}
                                                                        </span>
                                                                    </div>
                                                                </mat-list-option>
                                                            </ng-container>
                                                        </mat-selection-list>
                                                    </form>
                                                </ng-template>
                                            </ng-container>
                                        </ng-template>
                                    </ng-container>
                              
                                    <div class="search_fil_line">
                                        <button (click)="searchFilterSubmit()">{{"Search" | translate}}</button>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </ng-container>
                <ng-template #elseTemplateHasPartners>
                    <div class="expanded_block_out">
                        <div class="expanded_block_in exp_def">
                            <div class="title_nav">
                                <span>
                                    {{ "Group" | translate }}
                                </span>
                            </div>
                            <section class="">
                                <div class="filter_exp_search">
                                    <mat-form-field appearance="legacy" class="filter_form search">
                                        <input class="search_inp" [formControl]="groupsControl" matInput>
                                        <mat-icon matSuffix>search</mat-icon>
                                    </mat-form-field>
                                    <mat-progress-bar mode="indeterminate" *ngIf="isLoadGroups" class="mat_proggress_w8"></mat-progress-bar>
                                </div>
                                <ng-container *ngIf="!switched">
                                    <form [formGroup]="filter" class="exp_form" [appDeviceClass]="'custom-scroll'" 
                                    infinite-scroll
                                    [scrollWindow]="false"
                                    [infiniteScrollDisabled]="isLoadGroups"
                                    [infiniteScrollUpDistance]="scrollUpDistance"
                                    [infiniteScrollThrottle]="throttle"
                                    (scrolled)="onDown($event, 'group_id')">
                                        <mat-selection-list [multiple]="true" formControlName="group_id" (selectionChange)="selectChange($event, true)">
                                            <mat-list-option [value]="0" [ngClass]="{'active': filter.get('group_id').value.includes(0)}">
                                                <div class="user_div">
                                                    <span>
                                                        {{ "Group not set" | translate }}
                                                    </span>
                                                </div>
                                            </mat-list-option>
                                            <ng-container *ngFor="let fil of groups$ | async">
                                                <mat-list-option class="cst_o flex_text" [value]="fil.id" [ngClass]="{'active': filter.get('group_id').value.includes(fil.id)}">
                                                    <div class="d-f-c">
                                                        <span>
                                                            {{fil.name}}
                                                        </span>
                                                    </div>
                                                </mat-list-option>
                                            </ng-container>
                                    </mat-selection-list>
                                    </form> 
                                </ng-container>
                                <div class="search_fil_line" style="padding-bottom: 10px;">
                                    <button (click)="searchFilterSubmit()">{{"Search" | translate}}</button>
                                </div>
                            </section>
                            <!-- <ng-container [ngTemplateOutlet]="defaultFilter" [ngTemplateOutletContext]="{ array: groups, asArray: groups$, search: groupsControl, control: 'group_id' }"></ng-container> -->
                        </div>
                    </div>
                </ng-template>
            </li>
            <li class="nav_filter" [ngClass]="{'no_active': activeSections() == 'Analytic' || (activeSections() == 'Settings' && (['finances_dashboard', 'finances', 'checklists', 'workspace_members', 'individuals', 'schedules', 'partner_workspaces', 'questionnaires', 'total_usage', 'import-data', 'sftp', 'parameters', 'bookmarks', 'task_types', 'job_types', 'structure', 'form', 'users', 'categorization', 'discontinued', 'stats', 'chats', 'company', 'manage-auth'].includes(page) || (page == 'automation' && ['', 'card_templates', 'card_scenario_templates', 'job_scenario_templates', 'automation_of_tag_assigning'].includes(pageTab))))}">
                <a>{{ "Tag" | translate }}</a>
                <div class="expanded_block_out" *ngIf="allValues && allValues.length" [ngClass]="{'show_filter': showFilter}">
                    <div class="expanded_block_in exp_subs">
                        <div class="title_nav">
                            <span>
                                {{ "Tags" | translate }}
                            </span>
                        </div>
                        <section class="sub_filter_wrp">
                            <div class="subs" [appDeviceClass]="'custom-scroll'">
                                <div class="sub" [ngClass]="{'active': tagsControl.value == 0}" (click)="patchControl(tagsControl, 0)">
                                    {{ "Everywhere" | translate }}
                                </div>
                                <div class="sub" [ngClass]="{'active': tagsControl.value == 1, 'no_active': ['profiles'].includes(page)}" (click)="patchControl(tagsControl, 1)">
                                    {{ "Cards" | translate }}
                                </div>
                                <div class="sub" [ngClass]="{'active': tagsControl.value == 2, 'no_active': ['profiles'].includes(page)}" (click)="patchControl(tagsControl, 2)">
                                    {{ "Jobs" | translate }}
                                </div>
                                <div class="sub" [ngClass]="{'active': tagsControl.value == 3, 'no_active': ['profiles'].includes(page)}" (click)="patchControl(tagsControl, 3)">
                                    {{ "Chats" | translate }}
                                </div>
                                <div class="sub" [ngClass]="{'active': tagsControl.value == 4, 'no_active': ['profiles'].includes(page)}" (click)="patchControl(tagsControl, 4)">
                                    {{ "Files" | translate }}
                                </div>
                            </div>
                            <div class="block" style="padding: 0;">
                                <ng-container *ngIf="!switched">
                                    <form [formGroup]="filter" class="exp_param_form custom-scroll filter_in parameter_global_filter scroll_task_filter" [appDeviceClass]="'custom-scroll'" (ngSubmit)="searchFilterSubmit()">
                                        <ng-container *ngFor="let param of parameters">
        
                                            <mat-form-field appearance="standard" class="full_width_form" *ngIf="!!param?.hasValues" [ngStyle]="{'margin-bottom': param.id == 0 ? '20px': ''}">
                                                <mat-label>{{ param.name }}</mat-label>
                                    
                                                <mat-chip-list 
                                                    class="without_ordered"
                                                    #paramsChipList
                                                    formControlName="{{paramFilterProps[tagsControl['value']]}}"
                                                >
                                                    <mat-chip 
                                                        class="param_chip_on_page"
                                                        *ngFor="let val of paramSwitcher(paramFilterProps[tagsControl['value']], param); let k = index;"
                                                        [removable]="true"
                                                        color="primary"
                                                        (removed)="onRemove(val, param, paramFilterProps[tagsControl['value']])">
                                                            {{ getValueById(val.id)?.value }} <span class="parameter_name" *ngIf="param.id == 0 && getParameterById(val.parameter_id)">({{ getParameterById(val.parameter_id).name }})</span> 
                                                        <mat-icon matChipRemove>cancel</mat-icon>
                                                    </mat-chip>
                                    
                                                    <input
                                                    id="param_nav_input_{{param.id}}"
                                                    class="auto_complete_input"
                                                    placeholder="{{ 'Search' | translate }}"
                                                    #paramsInput
                                                    [formControl]="param.search"
                                                    [matAutocomplete]="paramsAuto"
                                                    [matChipInputFor]="paramsChipList"
                                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                                    (matChipInputTokenEnd)="add($event)">
                                                </mat-chip-list>
                                                <mat-autocomplete #autoHead (opened)="onOpened(autoHead)" (closed)="onClosed(autoHead)" class="filter_autocomlete" autoActiveFirstOption #paramsAuto="matAutocomplete" (optionsScroll)="onScrollParams(param)" (optionSelected)="selected($event, param, paramFilterProps[tagsControl['value']])">
                                                    <ng-container *ngFor="let val of param.values">
                                                        <mat-option *ngIf="noOption(param[keySwitcher(paramFilterProps[tagsControl['value']])], val.id) && withSearch(param?.search?.value, val)" [value]="val">
                                                          <div class="d-f-c" style="justify-content: space-between;">
                                                            <span>
                                                              {{ val.value }}
                                                            </span>
                                                            <span class="parameter_name_in_option" *ngIf="param.id == 0 && getParameterById(val.parameter_id)">({{ getParameterById(val.parameter_id).name }})</span> 
                                                          </div>
                                                        </mat-option>
                                                    </ng-container>
                                                </mat-autocomplete>
                                            </mat-form-field>
                                          </ng-container>
                                    </form>
                                </ng-container>
                                
                            </div>
                        </section>
                    </div>
                </div>
            </li>
            <li class="nav_filter" [ngClass]="{'no_active': activeSections() == 'Analytic' || (activeSections() == 'Settings' && ['finances_dashboard', 'finances', 'automation', 'checklists', 'workspace_members', 'individuals', 'schedules', 'partner_workspaces', 'questionnaires', 'total_usage', 'import-data', 'sftp', 'parameters', 'bookmarks', 'task_types', 'job_types', 'structure', 'form', 'users', 'categorization', 'discontinued', 'stats', 'chats', 'company', 'manage-auth', 'profiles'].includes(page))}" (mouseenter)="mouseEnterNavPar($event)">
                <a>{{ "Priority" | translate }}</a>
                <div class="expanded_block_out" *ngIf="prioritys && prioritys.length">
                    <div class="expanded_block_in exp_def">
                        <div class="title_nav">
                            <span>
                                {{ "Priority" | translate }}
                            </span>
                        </div>
    
                        <ng-container [ngTemplateOutlet]="defaultFilter" [ngTemplateOutletContext]="{ array: prioritys, asArray: prioritys$, search: prioritysControl, control: 'priority' }"></ng-container>
                    </div>
                </div>
            </li>
        </ul>
    
        <ul [ngClass]="{'hide_menu': !(company | perm:'see_task_channels')}" (mouseenter)="mouseEnterNavPar($event)">
            <li class="nav_filter" [ngClass]="{'no_active': activeSections() == 'Settings' && ['finances_dashboard', 'finances', 'automation', 'checklists', 'workspace_members', 'individuals', 'schedules', 'partner_workspaces', 'questionnaires', 'total_usage', 'import-data', 'sftp', 'parameters', 'bookmarks', 'task_types', 'job_types', 'structure', 'form', 'users', 'categorization', 'discontinued', 'stats', 'chats', 'manage-auth', 'profiles'].includes(page)}">
                <a>{{ "Outlet" | translate }}</a>

                <app-outlet-filter *ngIf="platforms"
                    [company]="company" 
                    [company_id]="company_id" 
                    [multiple]="false" 
                    [n_a]="true" 
                    [form]="filter" 
                    [profiles]="false" 
                    [outletKey]="outletKey" 
                    [activeSections]="activeSections()" 
                    [platforms]="platforms"
                    [showOutletBtn]="showOutletBtn" 
                    (SelectChange)="selectChange($event)"
                    (SelectOutletTab)="setOutletKey($event)">
                </app-outlet-filter>
                <!-- <div class="expanded_block_out" *ngIf="profiles && profiles.length">
                    <div class="expanded_block_in exp_subs exp_channels exp_def">
                        <div class="title_nav outlet_title_nav has_tabs">
                            <span>
                                {{ "Outlet" | translate }}
                            </span>

                            <div class="out_tabs" *ngIf="activeSections() == 'Chats'">
                                <span [ngClass]="{'active': outletKey == 'channel_id'}" (click)="setOutletKey('channel_id')">{{"Cards"|translate}}</span>
                                <span [ngClass]="{'active': outletKey == 'discussion_channel_id'}" (click)="setOutletKey('discussion_channel_id')">{{"Chats"|translate}}</span>
                            </div>
                        </div>
                        <section class="sub_filter_wrp" [ngClass]="{'has_search_btn': showOutletBtn}">
                            <div class="subs" [appDeviceClass]="'custom-scroll'">
                                <div class="sub" [ngClass]="{'active': channelsSubsControl.value == i}" (click)="patchControl(channelsSubsControl, i)" *ngFor="let item of groupedProfiles; let i = index;">
                                    <ng-container *ngIf="item.platform_id == 0; else elseTemplateAll">
                                        <div class="d-f-c">
                                            <span class="text_one_line">
                                                {{ "All outlets" | translate }}
                                            </span>
                                        </div>
                                    </ng-container>
                                    <ng-template #elseTemplateAll>
                                        <div class="d-f-c">
                                            <app-platform [platform_id]="item.platform_id"></app-platform>
                                            <span class="ml-1 text_one_line">
                                                {{getPlatformById(item.platform_id)?.name}}
                                            </span>
                                        </div>
                                    </ng-template>
                                </div>
                            </div>
                            <div class="block">
                                <ng-container *ngIf="!switched && groupedProfiles && groupedProfiles[channelsSubsControl['value']] && groupedProfiles[channelsSubsControl['value']].channels.length">
                                    <ng-container *ngIf="groupedProfiles[channelsSubsControl['value']].channels.length > 7; else elseTemplateSubL">
                                        <div class="filter_exp_search">
                                            <mat-form-field appearance="legacy" class="filter_form search">
                                                <input class="search_inp" [formControl]="groupedProfiles[channelsSubsControl['value']].channelsControl" matInput>
                                                <mat-icon matSuffix>search</mat-icon>
                                            </mat-form-field>
                                          </div>
                                        <form [formGroup]="filter" class="exp_form" [appDeviceClass]="'custom-scroll'">
                                            <mat-selection-list [multiple]="false" formControlName="{{outletKey}}" (selectionChange)="selectChange($event)">
                                                <mat-list-option [value]="0" [ngClass]="{'active': filter.get(outletKey).value.includes(0)}">
                                                    <div class="user_div">
                                                        <mat-icon style="visibility: none;">user</mat-icon>
                                                        <span>
                                                            {{ "Outlet not set" | translate }}
                                                        </span>
                                                    </div>
                                                </mat-list-option>
                                                <ng-container *ngFor="let fil of groupedProfiles[channelsSubsControl['value']].asChannels | async">
                                                    <mat-list-option class="cst_o flex_text" [value]="fil.id" [ngClass]="{'active': filter.get(outletKey).value.includes(fil.id)}">
                                                        <div class="d-f-c">
                                                            <app-outlet-line [class]="'for_nav'" [channel]="fil" [company_id]="company_id"></app-outlet-line>
                                                            <mat-checkbox (click)="setChannelMulty($event, fil.id, outletKey)" [disableRipple]="true"  class="outlet_fil_check" [checked]="filter.get(outletKey).value.includes(fil.id)" color="primary"></mat-checkbox>
                                                        </div>
                                                        <span class="not_assigned" (click)="noChannel($event, fil.id)">
                                                            {{ "n/a" | translate }}
                                                            <mat-checkbox [disableRipple]="true" class="outlet_fil_check" color="primary" [checked]="filter.get('no_channel_id').value.includes(fil.id)" (click)="noChannelMulty($event, fil.id)"></mat-checkbox>
                                                        </span>
                                                    </mat-list-option>
                                                </ng-container>
                                        </mat-selection-list>
                                        </form>
                                    </ng-container>
                                    <ng-template #elseTemplateSubL>
                                        <form [formGroup]="filter" class="exp_form" [appDeviceClass]="'custom-scroll'">
                                            <mat-selection-list [multiple]="false" formControlName="{{outletKey}}" (selectionChange)="selectChange($event)">
                                                <mat-list-option [value]="0" [ngClass]="{'active': filter.get(outletKey).value.includes(0)}">
                                                    <div class="user_div">
                                                        <mat-icon style="visibility: none;">user</mat-icon>
                                                        <span>
                                                            {{ "Outlet not set" | translate }}
                                                        </span>
                                                    </div>
                                                </mat-list-option>
                                                <ng-container *ngFor="let fil of groupedProfiles[channelsSubsControl['value']].channels">
                                                    <mat-list-option class="cst_o flex_text" [value]="fil.id" [ngClass]="{'active': filter.get(outletKey).value.includes(fil.id)}">
                                                        <div class="d-f-c">
                                                            <app-outlet-line [class]="'for_nav'" [channel]="fil" [company_id]="company_id"></app-outlet-line>
                                                            <mat-checkbox (click)="setChannelMulty($event, fil.id, outletKey)" [disableRipple]="true" class="outlet_fil_check" [checked]="filter.get(outletKey).value.includes(fil.id)" color="primary"></mat-checkbox>
                                                        </div>
                                                        <span class="not_assigned" (click)="noChannel($event, fil.id)">
                                                            {{ "n/a" | translate }}
                                                            <mat-checkbox [disableRipple]="true" class="outlet_fil_check" [checked]="filter.get('no_channel_id').value.includes(fil.id)" (click)="noChannelMulty($event, fil.id)" color="primary"></mat-checkbox>
                                                        </span>
                                                    </mat-list-option>
                                                </ng-container>
                                            </mat-selection-list>
                                        </form>
                                    </ng-template>
                                </ng-container>
                                <div class="search_fil_line" *ngIf="showOutletBtn">
                                    <button (click)="searchFilterSubmit()">{{"Search" | translate}}</button>
                                </div>
                            </div>
                        </section>
                    </div>
                </div> -->
            </li>
            <li class="nav_filter" [ngClass]="{'no_active': activeSections() == 'Settings' && ['finances_dashboard', 'finances', 'automation', 'checklists', 'workspace_members', 'individuals', 'schedules', 'partner_workspaces', 'questionnaires', 'total_usage', 'import-data', 'sftp', 'parameters', 'bookmarks', 'task_types', 'job_types', 'structure', 'form', 'users', 'categorization', 'discontinued', 'stats', 'chats', 'manage-auth', 'profiles'].includes(page)}" (mouseenter)="mouseEnterNavPar($event)">
                <a>{{ "Type" | translate }}</a>
                <div class="expanded_block_out" *ngIf="allContentTypes && allContentTypes.length">
                    <div class="expanded_block_in exp_def">
                        <div class="title_nav">
                            <span>
                                {{ "Content Types" | translate }}
                            </span>
                        </div>
                      
                        <section class="">
                            <ng-container *ngIf="allContentTypes.length > 7; else elseTemplateTaskStatus">
                                <div class="filter_exp_search">
                                    <mat-form-field appearance="legacy" class="filter_form search">
                                        <input class="search_inp" [formControl]="contentTypesControl" matInput>
                                        <mat-icon matSuffix>search</mat-icon>
                                    </mat-form-field>
                                </div>
                                <form [formGroup]="filter" class="exp_form" [appDeviceClass]="'custom-scroll'"     
                                infinite-scroll
                                [scrollWindow]="false"
                                [infiniteScrollUpDistance]="scrollUpDistance"
                                [infiniteScrollThrottle]="throttle"
                                (scrolled)="onDown($event)">
                                    <mat-selection-list [multiple]="false" formControlName="content_type_id" (selectionChange)="selectChange($event)">
                                        <mat-list-option [value]="0" [ngClass]="{'active': 0 === filter.get('content_type_id').value}">
                                            {{ "Content type not set" | translate}}
                                        </mat-list-option>
                                        <ng-container *ngFor="let contentType of contentTypes$ | async">
                                            <div mat-subheader>{{getPlatformById(contentType.channel_platform_id)?.name}}</div>
                                            <mat-list-option *ngFor="let fil of contentType.types" [value]="fil.id" [ngClass]="{'active': fil.id == filter.get('content_type_id').value}">
                                                {{fil.name | translate}}
                                            </mat-list-option>
                                            <mat-divider></mat-divider>
                                        </ng-container>
                                    </mat-selection-list>
                                </form>
                            </ng-container>
                            <ng-template #elseTemplateTaskStatus>
                                <form [formGroup]="filter" class="exp_form" [appDeviceClass]="'custom-scroll'" 
                                infinite-scroll
                                [scrollWindow]="false"
                                [infiniteScrollUpDistance]="scrollUpDistance"
                                [infiniteScrollThrottle]="throttle"
                                (scrolled)="onDown($event, 'content_type_id')">
                                    <mat-selection-list [multiple]="false" formControlName="content_type_id" (selectionChange)="selectChange($event)">
                                        <mat-list-option [value]="0" [ngClass]="{'active': 0 === filter.get('content_type_id').value}">
                                            {{ "Content type not set" | translate}}
                                        </mat-list-option>
                                        <ng-container *ngFor="let fil of allContentTypes">
                                            <mat-list-option [value]="fil.id" [ngClass]="{'active': fil.id == filter.get('content_type_id').value}">
                                                {{fil.name | translate}}
                                            </mat-list-option>
                                        </ng-container>
                                    </mat-selection-list>
                                </form>
                            </ng-template>
                        </section>
        
                    </div>
                </div>
            </li>

            <li class="nav_filter" [ngClass]="{'no_active': activeSections() == 'Analytic' || (activeSections() == 'Settings' && ['finances_dashboard', 'finances', 'automation', 'checklists', 'workspace_members', 'individuals', 'schedules', 'partner_workspaces', 'questionnaires', 'total_usage', 'import-data', 'sftp', 'parameters', 'bookmarks', 'task_types', 'job_types', 'structure', 'form', 'users', 'categorization', 'discontinued', 'stats', 'chats', 'company', 'manage-auth', 'profiles'].includes(page))}" (mouseenter)="mouseEnterNavPar($event)">
                <a>{{ "Status" | translate }}</a>
                
                <ng-container *ngIf="activeSections() == 'Files'; else elseTemplateThisIsFiles">
                    <div class="expanded_block_out" *ngIf="profileStatuses && profileStatuses.length">
                        <div class="expanded_block_in exp_subs">
                            <div class="title_nav">
                                <span>
                                    {{ "Publication Status" | translate }}
                                </span>
                            </div>
                            <section class="sub_filter_wrp">
                                <div class="subs" [appDeviceClass]="'custom-scroll'">
                                    <div class="sub" [ngClass]="{'active': statusControl.value == 0}" (click)="patchControl(statusControl, 0)">
                                        {{ "Cards" | translate }}
                                    </div>
                                    <div class="sub" [ngClass]="{'active': statusControl.value == 1}" (click)="patchControl(statusControl, 1)">
                                        {{ "Files" | translate }}
                                    </div>
                                </div>
                                <div class="block" style="padding: 0;">
                                    <div class="filter_exp_search">
                                        <mat-form-field appearance="legacy" class="filter_form search">
                                            <input class="search_inp" [formControl]="profileStatusesControl" matInput>
                                            <mat-icon matSuffix>search</mat-icon>
                                        </mat-form-field>
                                    </div>
                                    <ng-container *ngIf="!switched">
                                        <form [formGroup]="filter" class="exp_form" [appDeviceClass]="'custom-scroll'">
                                            <mat-selection-list [multiple]="false" formControlName="{{statusControl.value == 0 ? 'content_status_id' : 'task_channel_file_content_status_id'}}" (selectionChange)="selectChange($event, false)">
                                                <mat-list-option *ngIf="statusControl.value == 1" [value]="'no'" [ngClass]="{'active': 'no' === filter.get('task_channel_file_content_status_id').value}">
                                                    <span>
                                                        {{"Not used in posts" | translate}}
                                                    </span>
                                                </mat-list-option>
                                                <mat-list-option *ngFor="let fil of profileStatuses$ | async" [value]="fil.id" [ngClass]="{'active': statusControl.value == 0 ? fil.id === filter.get('content_status_id').value : fil.id == filter.get('task_channel_file_content_status_id').value}">
                                                    <span>
                                                        {{fil.name}}
                                                    </span>
                                                </mat-list-option>
                                            </mat-selection-list>
                                        </form>
                                    </ng-container>
                                </div>
                            </section>
                        </div>
                    </div>
                    
                    
                </ng-container>
                <ng-template #elseTemplateThisIsFiles>                    
                    <div class="expanded_block_out">
                        <div class="expanded_block_in exp_def" [ngClass]="{'exp_def_tabs': activeSections() == 'Cards'}">
                            <div class="title_nav has_tabs">
                                <span>
                                    {{ "Publication Status" | translate }}
                                </span>
    
                                <div class="out_tabs" style="margin-left: 50px;" *ngIf="activeSections() == 'Cards'">
                                    <span [ngClass]="{'active': statusKey == 'content_status_id'}" (click)="setStatusKey('content_status_id')">{{"Personally"|translate}}</span>
                                    <span [ngClass]="{'active': statusKey == 'task_content_status_id'}" (click)="setStatusKey('task_content_status_id')">{{"Applies to all"|translate}}</span>
                                </div>
                            </div>
        
                            <ng-container [ngTemplateOutlet]="defaultFilter" [ngTemplateOutletContext]="{ array: profileStatuses, asArray: profileStatuses$, search: profileStatusesControl, control: statusKey }"></ng-container>
                        </div>
                    </div>
                </ng-template> 
            </li>
            <li class="nav_filter" [ngClass]="{'no_active': activeSections() == 'Analytic' || (activeSections() == 'Settings' && ['finances_dashboard', 'finances', 'automation', 'checklists', 'workspace_members', 'partner_workspaces', 'questionnaires', 'total_usage', 'import-data', 'sftp', 'parameters', 'bookmarks', 'task_types', 'job_types', 'structure', 'form', 'users', 'categorization', 'discontinued', 'stats', 'chats', 'company', 'manage-auth'].includes(page))}">
                <a>
                    <svg width="16" height="4" viewBox="0 0 16 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 2C12 1.46957 12.2107 0.96086 12.5858 0.585787C12.9609 0.210714 13.4696 0 14 0C14.5304 0 15.0391 0.210714 15.4142 0.585787C15.7893 0.96086 16 1.46957 16 2C16 2.53043 15.7893 3.03914 15.4142 3.41421C15.0391 3.78929 14.5304 4 14 4C13.4696 4 12.9609 3.78929 12.5858 3.41421C12.2107 3.03914 12 2.53043 12 2ZM6 2C6 1.46957 6.21071 0.96086 6.58579 0.585787C6.96086 0.210714 7.46957 0 8 0C8.53043 0 9.03914 0.210714 9.41421 0.585787C9.78929 0.96086 10 1.46957 10 2C10 2.53043 9.78929 3.03914 9.41421 3.41421C9.03914 3.78929 8.53043 4 8 4C7.46957 4 6.96086 3.78929 6.58579 3.41421C6.21071 3.03914 6 2.53043 6 2ZM0 2C0 1.46957 0.210714 0.96086 0.585786 0.585787C0.960859 0.210714 1.46957 0 2 0C2.53043 0 3.03914 0.210714 3.41421 0.585787C3.78929 0.96086 4 1.46957 4 2C4 2.53043 3.78929 3.03914 3.41421 3.41421C3.03914 3.78929 2.53043 4 2 4C1.46957 4 0.960859 3.78929 0.585786 3.41421C0.210714 3.03914 0 2.53043 0 2Z" fill="#686868"/>
                    </svg>
                </a>
    
                <div class="expanded_block_out">
                    <div class="expanded_block_in more_exp">
                        <div class="title_nav">
                            <span>
                                {{ "More options" | translate }}
                            </span>
                        </div>
                        <section class="">
                            <div class="exp_form">
                                <mat-list>
                                    <mat-list-item class="sub_exp_item" [ngClass]="{'no_active': activeSections() == 'Settings' && ['individuals', 'schedules', 'profiles'].includes(page)}">
                                        <span class="sub_exp_val">
                                            {{ "URL presented" | translate }}
                                        </span>
                                        <div class="expanded_block_out_sub">
                                            <div class="expanded_block_in_sub exp_def">
                                                <div class="title_nav">
                                                    <span>
                                                        {{ "URL presented" | translate }}
                                                    </span>
                                                </div>
                                                <section class="">   
                                                    <form [formGroup]="filter" class="exp_form" [appDeviceClass]="'custom-scroll'" 
                                                    infinite-scroll
                                                    [scrollWindow]="false"
                                                    [infiniteScrollUpDistance]="scrollUpDistance"
                                                    [infiniteScrollThrottle]="throttle"
                                                    (scrolled)="onDown($event, 'is_content_url')">
                                                        <mat-selection-list [multiple]="false" formControlName="is_content_url" (selectionChange)="selectChange($event)">
                                                            <ng-container *ngFor="let fil of contentUrl">
                                                                <mat-list-option [value]="fil.id" [ngClass]="{'active': fil.id === filter.get('is_content_url').value}">
                                                                    <span class="sub_exp_val">
                                                                        {{fil.name | translate}}
                                                                    </span>
                                                                </mat-list-option>
                                                            </ng-container>
                                                        </mat-selection-list>
                                                    </form>
                                                </section>
                                            </div>
                                        </div>
                                    </mat-list-item>
                                    <mat-list-item class="sub_exp_item" [ngClass]="{'no_active': activeSections() == 'Settings' && ['individuals'].includes(page)}" (mouseenter)="mouseEnterNavPar($event)">
                                        <span class="sub_exp_val">
                                            {{ "Platform" | translate }}
                                        </span>
                                        <div class="expanded_block_out_sub" *ngIf="platforms && platforms.length">
                                            <div class="expanded_block_in_sub exp_def">
                                                <div class="title_nav">
                                                    <span>
                                                        {{ "Platform" | translate }}
                                                    </span>
                                                </div>
                                                <section class="">
                                                    <div class="filter_exp_search">
                                                        <mat-form-field appearance="legacy" class="filter_form search">
                                                            <input class="search_inp" [formControl]="platformsMoreControl" matInput>
                                                            <mat-icon matSuffix>search</mat-icon>
                                                        </mat-form-field>
                                                      </div>
                                                    <form [formGroup]="filter" class="exp_form" [appDeviceClass]="'custom-scroll'"     
                                                    infinite-scroll
                                                    [scrollWindow]="false"
                                                    [infiniteScrollUpDistance]="scrollUpDistance"
                                                    [infiniteScrollThrottle]="throttle"
                                                    (scrolled)="onDown($event, 'channel_platform_id')">
                                                        <mat-selection-list [multiple]="false" formControlName="channel_platform_id" (selectionChange)="selectChange($event)">
                                                            <mat-list-option *ngFor="let fil of platforms$ | async" [value]="fil.id" [ngClass]="{'active': fil.id === filter.get('channel_platform_id').value}">
                                                                <div class="d-f-c">
                                                                    <div class="platform_icon mr-1">
                                                                        <app-platform [platform_id]="fil?.id"></app-platform>
                                                                    </div>
                                                                    <span>
                                                                        {{fil.name}}
                                                                    </span>
                                                                </div>
                                                            </mat-list-option>
                                                        </mat-selection-list>
                                                    </form>
                                                </section>
                                            </div>
                                        </div>
                                    </mat-list-item>
                                    <!-- <mat-list-item class="sub_exp_item" *ngIf="!!filter.get('task_content_status_id') && activeSections() == 'Cards'">
                                        <span class="sub_exp_val">
                                            {{ "Card Posts Status" | translate }}*
                                        </span>
                                        <div class="expanded_block_out_sub">
                                            <div class="expanded_block_in_sub exp_def">
                                                <div class="title_nav">
                                                    <span>
                                                        {{ "Card Posts Status" | translate }}*
                                                    </span>
                                                </div>
                                                <ng-container [ngTemplateOutlet]="defaultFilter" [ngTemplateOutletContext]="{ array: profileStatuses, asArray: profileStatuses$, search: profileStatusesControl, control: 'task_content_status_id' }"></ng-container>
                                            </div>
                                        </div>
                                    </mat-list-item> -->
                                    <mat-list-item class="sub_exp_item" *ngIf="!!filter.get('is_content_views_count_update') && activeSections() == 'Posts'">
                                        <span class="sub_exp_val">
                                            {{ "Autoupdate" | translate }}*
                                        </span>
                                        <div class="expanded_block_out_sub" *ngIf="platforms && platforms.length">
                                            <div class="expanded_block_in_sub exp_def">
                                                <div class="title_nav">
                                                    <span>
                                                        {{ "Autoupdate" | translate }}*
                                                    </span>
                                                </div>
                                                <section class="">
                                                    <form [formGroup]="filter" class="exp_form" [appDeviceClass]="'custom-scroll'">
                                                        <mat-selection-list [multiple]="false" formControlName="is_content_views_count_update" (selectionChange)="selectChange($event)">
                                                            <mat-list-option [value]="0" [ngClass]="{'active': 0 === filter.get('is_content_views_count_update').value}">
                                                                {{ "Without auto-update" | translate }}
                                                            </mat-list-option>
                                                            <mat-list-option [value]="1" [ngClass]="{'active': 1 === filter.get('is_content_views_count_update').value}">
                                                                {{ "With auto-update" | translate }}
                                                            </mat-list-option>
                                                            <mat-list-option [value]="2" [ngClass]="{'active': 2 === filter.get('is_content_views_count_update').value}">
                                                                {{ "Error in update" | translate }}
                                                            </mat-list-option>
                                                        </mat-selection-list>
                                                    </form>
                                                </section>
                                            </div>
                                        </div>
                                    </mat-list-item>
                                    <mat-list-item class="sub_exp_item" *ngIf="!!filter.get('is_bravourl_content_views_count_update') && activeSections() == 'Posts'">
                                        <span class="sub_exp_val">
                                            {{ "Autoupdate (bravo)" | translate }}*
                                        </span>
                                        <div class="expanded_block_out_sub" *ngIf="platforms && platforms.length">
                                            <div class="expanded_block_in_sub exp_def">
                                                <div class="title_nav">
                                                    <span>
                                                        {{ "Autoupdate (bravo)" | translate }}*
                                                    </span>
                                                </div>
                                                <section class="">
                                                    <form [formGroup]="filter" class="exp_form" [appDeviceClass]="'custom-scroll'">
                                                        <mat-selection-list [multiple]="false" formControlName="is_bravourl_content_views_count_update" (selectionChange)="selectChange($event)">
                                                            <mat-list-option [value]="0" [ngClass]="{'active': 0 === filter.get('is_bravourl_content_views_count_update').value}">
                                                                {{ "Without auto-update" | translate }}
                                                            </mat-list-option>
                                                            <mat-list-option [value]="1" [ngClass]="{'active': 1 === filter.get('is_bravourl_content_views_count_update').value}">
                                                                {{ "With auto-update" | translate }}
                                                            </mat-list-option>
                                                            <mat-list-option [value]="2" [ngClass]="{'active': 2 === filter.get('is_bravourl_content_views_count_update').value}">
                                                                {{ "Error in update" | translate }}
                                                            </mat-list-option>
                                                        </mat-selection-list>
                                                    </form>
                                                </section>
                                            </div>
                                        </div>
                                    </mat-list-item>
                                    <mat-list-item class="sub_exp_item" *ngIf="!!filter.get('views_update_interval') && activeSections() == 'Posts'">
                                        <span class="sub_exp_val">
                                            {{ "Views Update Interval" | translate }}*
                                        </span>
                                        <div class="expanded_block_out_sub" *ngIf="viewsUpdateIntervalList && viewsUpdateIntervalList.length">
                                            <div class="expanded_block_in_sub exp_def">
                                                <div class="title_nav">
                                                    <span>
                                                        {{ "Views Update Interval" | translate }}*
                                                    </span>
                                                </div>
                                                <section class="">
                                                    <form [formGroup]="filter" class="exp_form" [appDeviceClass]="'custom-scroll'">
                                                        <mat-selection-list [multiple]="false" formControlName="views_update_interval" (selectionChange)="selectChange($event)">
                                                            <mat-list-option *ngFor="let fil of viewsUpdateIntervalList" [value]="fil.value" [ngClass]="{'active': fil.value === filter.get('views_update_interval').value}">
                                                                {{ fil.name | translate }}
                                                            </mat-list-option>
                                                        </mat-selection-list>
                                                    </form>
                                                </section>
                                            </div>
                                        </div>
                                    </mat-list-item>
                                    <mat-list-item class="sub_exp_item" *ngIf="!!filter.get('scheduled_published_time_by') && activeSections() == 'Posts'">
                                        <span class="sub_exp_val">
                                            {{ "Posting method" | translate }}*
                                        </span>
                                        <div class="expanded_block_out_sub" *ngIf="scheduleTypes && scheduleTypes.length">
                                            <div class="expanded_block_in_sub exp_def">
                                                <div class="title_nav">
                                                    <span>
                                                        {{ "Posting method" | translate }}*
                                                    </span>
                                                </div>
                                                <section class="">
                                                    <form [formGroup]="filter" class="exp_form" [appDeviceClass]="'custom-scroll'">
                                                        <mat-selection-list [multiple]="false" formControlName="scheduled_published_time_by" (selectionChange)="selectChange($event)">
                                                            <mat-list-option *ngFor="let fil of scheduleTypes" [value]="fil" [ngClass]="{'active': fil === filter.get('scheduled_published_time_by').value}">
                                                                {{ fil + '_sch_type' | translate }}
                                                            </mat-list-option>
                                                        </mat-selection-list>
                                                    </form>
                                                </section>
                                            </div>
                                        </div>
                                    </mat-list-item>
                                    <mat-list-item class="sub_exp_item" *ngIf="!!filter.get('external_status') && activeSections() == 'Posts'">
                                        <span class="sub_exp_val">
                                            {{ "Privacy status" | translate }}*
                                        </span>
                                        <div class="expanded_block_out_sub">
                                            <div class="expanded_block_in_sub exp_def">
                                                <div class="title_nav">
                                                    <span>
                                                        {{ "Privacy status" | translate }}*
                                                    </span>
                                                </div>
                                                <section class="">
                                                    <form [formGroup]="filter" class="exp_form" [appDeviceClass]="'custom-scroll'">
                                                        <mat-selection-list [multiple]="false" formControlName="external_status" (selectionChange)="selectChange($event)">
                                                            <mat-list-option color="primary" class="privacy-radio-button" [value]="'private'" [ngClass]="{'active': 'private' === filter.get('external_status').value}">
                                                                <div class="d-f-c">
                                                                    <mat-icon class="mr-1 icon_16">lock</mat-icon>
                                                                    {{"Private" | translate}}
                                                                </div>
                                                            </mat-list-option>
                                                            <mat-list-option color="primary" class="privacy-radio-button" [value]="'public'" [ngClass]="{'active': 'public' === filter.get('external_status').value}">
                                                                <div class="d-f-c">
                                                                    <mat-icon class="mr-1 icon_16">visibility</mat-icon>
                                                                    {{"Public" | translate}}
                                                                </div>
                                                            </mat-list-option>
                                                            <mat-list-option color="primary" class="privacy-radio-button" [value]="'unlisted'" [ngClass]="{'active': 'unlisted' === filter.get('external_status').value}">
                                                                <div class="d-f-c">
                                                                    <mat-icon class="mr-1 icon_16">visibility_off</mat-icon>
                                                                    {{"Unlisted" | translate}}
                                                                </div>
                                                            </mat-list-option>
                                                        </mat-selection-list>
                                                    </form>
                                                </section>
                                            </div>
                                        </div>
                                    </mat-list-item>
                                    <ng-container *ngIf="activeSections() == 'Settings'">
                                        <ng-container *ngIf="['individuals', 'schedules'].includes(activePage())">
                                            <mat-list-item class="sub_exp_item" *ngIf="!!filter.get('permission')">
                                                <span class="sub_exp_val">
                                                    {{ "Permission" | translate }}*
                                                </span>
                                                <div class="expanded_block_out_sub">
                                                    <div class="expanded_block_in_sub exp_def">
                                                        <div class="title_nav">
                                                            <span>
                                                                {{ "Permission" | translate }}*
                                                            </span>
                                                        </div>
                                                        <section class="">   
                                                            <form [formGroup]="filter" class="exp_form" [appDeviceClass]="'custom-scroll'" 
                                                            infinite-scroll
                                                            [scrollWindow]="false"
                                                            [infiniteScrollUpDistance]="scrollUpDistance"
                                                            [infiniteScrollThrottle]="throttle"
                                                            (scrolled)="onDown($event, 'permission')">
                                                                <mat-selection-list [multiple]="false" formControlName="permission" (selectionChange)="selectChange($event)">
                                                                    <ng-container *ngFor="let fil of permissions$ | async">
                                                                        <mat-list-option [value]="fil" [ngClass]="{'active': fil === filter.get('permission').value}">
                                                                            <span class="sub_exp_val">
                                                                                {{fil | translate}}
                                                                            </span>
                                                                        </mat-list-option>
                                                                    </ng-container>
                                                                </mat-selection-list>
                                                            </form>
                                                        </section>
                                                    </div>
                                                </div>
                                            </mat-list-item>
                                            <mat-list-item class="sub_exp_item" *ngIf="!!filter.get('department_id')">
                                                <span class="sub_exp_val">
                                                    {{ "Department" | translate }}*
                                                </span>
                                                <div class="expanded_block_out_sub">
                                                    <div class="expanded_block_in_sub exp_def">
                                                        <div class="title_nav">
                                                            <span>
                                                                {{ "Department" | translate }}*
                                                            </span>
                                                        </div>
                                                        <section class="">   
                                                            <form [formGroup]="filter" class="exp_form" [appDeviceClass]="'custom-scroll'" 
                                                            infinite-scroll
                                                            [scrollWindow]="false"
                                                            [infiniteScrollUpDistance]="scrollUpDistance"
                                                            [infiniteScrollThrottle]="throttle"
                                                            (scrolled)="onDown($event, 'department_id')">
                                                                <mat-selection-list [multiple]="false" formControlName="department_id" (selectionChange)="selectChange($event)">
                                                                    <ng-container *ngFor="let fil of departments$ | async">
                                                                        <mat-list-option [value]="fil.id" [ngClass]="{'active': fil.id === filter.get('department_id').value}">
                                                                            <span class="sub_exp_val">
                                                                                {{fil.name | translate}}
                                                                            </span>
                                                                        </mat-list-option>
                                                                    </ng-container>
                                                                </mat-selection-list>
                                                            </form>
                                                        </section>
                                                    </div>
                                                </div>
                                            </mat-list-item>
                                            <mat-list-item class="sub_exp_item" *ngIf="!!filter.get('department_position_id')">
                                                <span class="sub_exp_val">
                                                    {{ "Position" | translate }}*
                                                </span>
                                                <div class="expanded_block_out_sub">
                                                    <div class="expanded_block_in_sub exp_def">
                                                        <div class="title_nav">
                                                            <span>
                                                                {{ "Position" | translate }}*
                                                            </span>
                                                        </div>
                                                        <section class="">   
                                                            <form [formGroup]="filter" class="exp_form" [appDeviceClass]="'custom-scroll'" 
                                                            infinite-scroll
                                                            [scrollWindow]="false"
                                                            [infiniteScrollUpDistance]="scrollUpDistance"
                                                            [infiniteScrollThrottle]="throttle"
                                                            (scrolled)="onDown($event, 'department_position_id')">
                                                                <mat-selection-list [multiple]="false" formControlName="department_position_id" (selectionChange)="selectChange($event)">
                                                                    <ng-container *ngFor="let fil of pos$ | async">
                                                                        <mat-list-option [value]="fil.id" [ngClass]="{'active': fil.id === filter.get('department_position_id').value}">
                                                                            <span class="sub_exp_val">
                                                                                {{fil.name | translate}}
                                                                            </span>
                                                                        </mat-list-option>
                                                                    </ng-container>
                                                                </mat-selection-list>
                                                            </form>
                                                        </section>
                                                    </div>
                                                </div>
                                            </mat-list-item>
                                        </ng-container>
                                    </ng-container>
                                    <ng-container *ngIf="activeSections() == 'Files'">
                                        <mat-list-item class="sub_exp_item" *ngIf="!!filter.get('operation_reminder_id')">
                                            <span class="sub_exp_val">
                                                {{ "Checklist" | translate }}*
                                            </span>
                                            <div class="expanded_block_out_sub">
                                                <div class="expanded_block_in_sub exp_def">
                                                    <div class="title_nav">
                                                        <span>
                                                            {{ "Checklist" | translate }}*
                                                        </span>
                                                    </div>
                                                    <section class="">   
                                                        <form [formGroup]="filter" class="exp_form" [appDeviceClass]="'custom-scroll'" 
                                                        infinite-scroll
                                                        [scrollWindow]="false"
                                                        [infiniteScrollUpDistance]="scrollUpDistance"
                                                        [infiniteScrollThrottle]="throttle"
                                                        (scrolled)="onDown($event, 'operation_reminder_id')">
                                                            <mat-selection-list [multiple]="false" formControlName="operation_reminder_id" (selectionChange)="selectChange($event)">
                                                                <ng-container *ngFor="let fil of checklists$ | async">
                                                                    <mat-list-option [value]="fil.id" [ngClass]="{'active': fil.id === filter.get('operation_reminder_id').value}">
                                                                        <span class="sub_exp_val">
                                                                            {{fil.text | translate}}
                                                                        </span>
                                                                    </mat-list-option>
                                                                </ng-container>
                                                            </mat-selection-list>
                                                        </form>
                                                    </section>
                                                </div>
                                            </div>
                                        </mat-list-item>
                                        <mat-list-item class="sub_exp_item" *ngIf="!!filter.get('meta_device')">
                                            <span class="sub_exp_val">
                                                {{ "Device" | translate }}*
                                            </span>
                                            <div class="expanded_block_out_sub">
                                                <div class="expanded_block_in_sub exp_def">
                                                    <div class="title_nav">
                                                        <span>
                                                            {{ "Device" | translate }}*
                                                        </span>
                                                    </div>
                                                    <section class="">   
                                                        <form [formGroup]="filter" class="exp_form" [appDeviceClass]="'custom-scroll'" 
                                                        infinite-scroll
                                                        [scrollWindow]="false"
                                                        [infiniteScrollUpDistance]="scrollUpDistance"
                                                        [infiniteScrollThrottle]="throttle"
                                                        (scrolled)="onDown($event, 'meta_device')">
                                                            <mat-selection-list [multiple]="false" formControlName="meta_device" (selectionChange)="selectChange($event)">
                                                                <ng-container *ngFor="let fil of devices$ | async">
                                                                    <mat-list-option [value]="fil" [ngClass]="{'active': fil === filter.get('meta_device').value}">
                                                                        <span class="sub_exp_val">
                                                                            {{fil}}
                                                                        </span>
                                                                    </mat-list-option>
                                                                </ng-container>
                                                            </mat-selection-list>
                                                        </form>
                                                    </section>
                                                </div>
                                            </div>
                                        </mat-list-item>
                                        <mat-list-item class="sub_exp_item" *ngIf="!!filter.get('is_ready_if_approved')">
                                            <span class="sub_exp_val">
                                                {{ "Ready Version" | translate }}*
                                            </span>
                                            <div class="expanded_block_out_sub">
                                                <div class="expanded_block_in_sub exp_def">
                                                    <div class="title_nav">
                                                        <span>
                                                            {{ "Ready Version" | translate }}*
                                                        </span>
                                                    </div>
                                                    <section class="">   
                                                        <form [formGroup]="filter" class="exp_form" [appDeviceClass]="'custom-scroll'" 
                                                        infinite-scroll
                                                        [scrollWindow]="false"
                                                        [infiniteScrollUpDistance]="scrollUpDistance"
                                                        [infiniteScrollThrottle]="throttle"
                                                        (scrolled)="onDown($event, 'is_ready_if_approved')">
                                                            <mat-selection-list [multiple]="false" formControlName="is_ready_if_approved" (selectionChange)="selectChange($event)">
                                                                <mat-list-option [value]="'0'" [ngClass]="{'active': '0' === filter.get('is_ready_if_approved').value}">
                                                                    <span class="sub_exp_val">
                                                                        {{"No" | translate}}
                                                                    </span>
                                                                </mat-list-option>
                                                                <mat-list-option [value]="'1'" [ngClass]="{'active': '1' === filter.get('is_ready_if_approved').value}">
                                                                    <span class="sub_exp_val">
                                                                        {{"Yes" | translate}}
                                                                    </span>
                                                                </mat-list-option>
                                                            </mat-selection-list>
                                                        </form>
                                                    </section>
                                                </div>
                                            </div>
                                        </mat-list-item>
                                        
                                        <mat-list-item class="sub_exp_item" *ngIf="!!filter.get('part_of_count')">
                                            <span class="sub_exp_val">
                                                {{ "Part of" | translate }}*
                                            </span>
                                            <div class="expanded_block_out_sub">
                                                <div class="expanded_block_in_sub exp_def">
                                                    <div class="title_nav">
                                                        <span>
                                                            {{ "Part of" | translate }}*
                                                        </span>
                                                    </div>
                                                    <section class="">   
                                                        <form [formGroup]="filter" class="exp_form" [appDeviceClass]="'custom-scroll'" 
                                                        infinite-scroll
                                                        [scrollWindow]="false"
                                                        [infiniteScrollUpDistance]="scrollUpDistance"
                                                        [infiniteScrollThrottle]="throttle"
                                                        (scrolled)="onDown($event, 'part_of_count')">
                                                            <mat-selection-list [multiple]="false" formControlName="part_of_count" (selectionChange)="selectChange($event)">
                                                                <mat-list-option [value]="'isset'" [ngClass]="{'active': 'isset' === filter.get('part_of_count').value}">
                                                                    <span class="sub_exp_val">
                                                                        {{"Is part of file" | translate}}
                                                                    </span>
                                                                </mat-list-option>
                                                                <mat-list-option [value]="'0'" [ngClass]="{'active': '0' === filter.get('part_of_count').value}">
                                                                    <span class="sub_exp_val">
                                                                        {{"Isn`t part of file" | translate}}
                                                                    </span>
                                                                </mat-list-option>
                                                            </mat-selection-list>
                                                        </form>
                                                    </section>
                                                </div>
                                            </div>
                                        </mat-list-item>
                                        <mat-list-item class="sub_exp_item" *ngIf="!!filter.get('consist_of_count')">
                                            <span class="sub_exp_val">
                                                {{ "Consist of" | translate }}*
                                            </span>
                                            <div class="expanded_block_out_sub">
                                                <div class="expanded_block_in_sub exp_def">
                                                    <div class="title_nav">
                                                        <span>
                                                            {{ "Consist of" | translate }}*
                                                        </span>
                                                    </div>
                                                    <section class="">   
                                                        <form [formGroup]="filter" class="exp_form" [appDeviceClass]="'custom-scroll'" 
                                                        infinite-scroll
                                                        [scrollWindow]="false"
                                                        [infiniteScrollUpDistance]="scrollUpDistance"
                                                        [infiniteScrollThrottle]="throttle"
                                                        (scrolled)="onDown($event, 'consist_of_count')">
                                                            <mat-selection-list [multiple]="false" formControlName="consist_of_count" (selectionChange)="selectChange($event)">
                                                                <mat-list-option [value]="'isset'" [ngClass]="{'active': 'isset' === filter.get('consist_of_count').value}">
                                                                    <span class="sub_exp_val">
                                                                        {{"Has parts" | translate}}
                                                                    </span>
                                                                </mat-list-option>
                                                                <mat-list-option [value]="'0'" [ngClass]="{'active': '0' === filter.get('consist_of_count').value}">
                                                                    <span class="sub_exp_val">
                                                                        {{"Hasn't parts" | translate}}
                                                                    </span>
                                                                </mat-list-option>
                                                            </mat-selection-list>
                                                        </form>
                                                    </section>
                                                </div>
                                            </div>
                                        </mat-list-item>
                                        <mat-list-item class="sub_exp_item" *ngIf="!!filter.get('related_count')">
                                            <span class="sub_exp_val">
                                                {{ "Related" | translate }}*
                                            </span>
                                            <div class="expanded_block_out_sub">
                                                <div class="expanded_block_in_sub exp_def">
                                                    <div class="title_nav">
                                                        <span>
                                                            {{ "Related" | translate }}*
                                                        </span>
                                                    </div>
                                                    <section class="">   
                                                        <form [formGroup]="filter" class="exp_form" [appDeviceClass]="'custom-scroll'" 
                                                        infinite-scroll
                                                        [scrollWindow]="false"
                                                        [infiniteScrollUpDistance]="scrollUpDistance"
                                                        [infiniteScrollThrottle]="throttle"
                                                        (scrolled)="onDown($event, 'related_count')">
                                                            <mat-selection-list [multiple]="false" formControlName="related_count" (selectionChange)="selectChange($event)">
                                                                <mat-list-option [value]="'isset'" [ngClass]="{'active': 'isset' === filter.get('related_count').value}">
                                                                    <span class="sub_exp_val">
                                                                        {{"Is related" | translate}}
                                                                    </span>
                                                                </mat-list-option>
                                                                <mat-list-option [value]="'0'" [ngClass]="{'active': '0' === filter.get('related_count').value}">
                                                                    <span class="sub_exp_val">
                                                                        {{"Isn`t related" | translate}}
                                                                    </span>
                                                                </mat-list-option>
                                                            </mat-selection-list>
                                                        </form>
                                                    </section>
                                                </div>
                                            </div>
                                        </mat-list-item>


                                        <!-- <mat-form-field appearance="legacy" class="filter_form group">
                                            <mat-label>{{ "Checklist" | translate }}</mat-label>
                                            <mat-select [disableOptionCentering]="true" [multiple]="true" selectDir formControlName="operation_reminder_id" (selectionChange)="filterFmFiles()">
                                                <mat-option>
                                                  <ngx-mat-select-search 
                                                      placeholderLabel="{{ 'Search' | translate }}" 
                                                      noEntriesFoundLabel="{{ 'no matching group found' | translate }}"
                                                      [formControl]="checklistsControl"></ngx-mat-select-search>
                                                </mat-option>
                                                <mat-option *ngFor="let checklist of checklists$ | async" [value]="checklist.id">
                                                    {{ checklist.text | translate }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
            
                                        <mat-form-field *ngIf="devices?.length" appearance="legacy" class="filter_form priority">
                                            <mat-label>{{ "Device" | translate }}</mat-label>
                                            <mat-select [disableOptionCentering]="true" selectDir formControlName="meta_device" (selectionChange)="filterFmFiles()">
                                                <mat-option>
                                                    <ngx-mat-select-search 
                                                        placeholderLabel="{{ 'Search' | translate }}" 
                                                        noEntriesFoundLabel="{{ 'no matching device found' | translate }}"
                                                        [formControl]="devicesMoreControl"></ngx-mat-select-search>
                                                </mat-option>
                                                <mat-option *ngFor="let device of devices$ | async" [value]="device">
                                                    {{ device }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
            
                                        <mat-form-field appearance="legacy" class="filter_form priority">
                                            <mat-label>{{ 'Ready if Approved' | translate }}</mat-label>
                                            <mat-select [disableOptionCentering]="true" selectDir formControlName="is_ready_if_approved" (selectionChange)="filterFmFiles()">
                                                <mat-option [value]="'0'">
                                                    {{ 'No' | translate }}
                                                </mat-option>
                                                <mat-option [value]="'1'">
                                                    {{ 'Yes' | translate }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field> -->
                                    </ng-container>
                                    
                                </mat-list>
                            </div>
                        </section>
                    </div>
                </div>
            </li>
        </ul>
    
        <ul>
            <!-- search -->
            <li class="nav_filter" [ngClass]="{'no_active': activeSections() == 'Settings' && (['finances_dashboard', 'finances', 'checklists', 'workspace_members', 'partner_workspaces', 'questionnaires', 'total_usage', 'import-data', 'sftp', 'parameters', 'bookmarks', 'task_types', 'job_types', 'structure', 'form', 'users', 'categorization', 'discontinued', 'stats', 'chats'].includes(page) || (page == 'automation' && ['automation_of_assigning_scenarios', 'automation_of_tag_assigning'].includes(pageTab)))}" (mouseenter)="searchEverywhere.focus()">
                <a>
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.68571 0C8.45888 0 10.1594 0.704386 11.4132 1.9582C12.667 3.21202 13.3714 4.91255 13.3714 6.68571C13.3714 8.34171 12.7646 9.864 11.7669 11.0366L12.0446 11.3143H12.8571L18 16.4571L16.4571 18L11.3143 12.8571V12.0446L11.0366 11.7669C9.864 12.7646 8.34171 13.3714 6.68571 13.3714C4.91255 13.3714 3.21202 12.667 1.9582 11.4132C0.704386 10.1594 0 8.45888 0 6.68571C0 4.91255 0.704386 3.21202 1.9582 1.9582C3.21202 0.704386 4.91255 0 6.68571 0ZM6.68571 2.05714C4.11429 2.05714 2.05714 4.11429 2.05714 6.68571C2.05714 9.25714 4.11429 11.3143 6.68571 11.3143C9.25714 11.3143 11.3143 9.25714 11.3143 6.68571C11.3143 4.11429 9.25714 2.05714 6.68571 2.05714Z" fill="#686868"/>
                    </svg>
                </a>
                <div class="expanded_block_out">
                    <div class="expanded_block_in search_exp">
                        <div class="title_nav">
                            <span (click)="logFils()">
                                {{ "Search" | translate }} {{activeSections()}}
                            </span>
                        </div>
                        <section class="exp_search_params">
                            <form [formGroup]="filter" class="exp_form" (ngSubmit)="searchFilterSubmit()">
                              <mat-form-field class="search-field" appearance="legacy">
                                <mat-label>{{ "Search everywhere" | translate }}</mat-label>
                                <input autocomplete="off" matInput formControlName="q" #searchEverywhere>
                                <mat-icon style="color: #686868" matSuffix>search</mat-icon>
                              </mat-form-field>
                              <ng-container [ngSwitch]="activeSections()">
                                <ng-container *ngSwitchCase="'Cards'">
                                    <div style="padding-top: 10px;"></div>
                                    <mat-form-field class="search-field" appearance="legacy">
                                    <mat-label>{{ "Card Title" | translate }}*</mat-label>
                                    <input autocomplete="off" matInput formControlName="q_task_name">
                                    </mat-form-field>
                                    <mat-form-field class="search-field" appearance="legacy">
                                    <mat-label>{{ "Card description" | translate }}*</mat-label>
                                    <input autocomplete="off" matInput formControlName="q_task_comment">
                                    </mat-form-field>
                                    <mat-form-field class="search-field" appearance="legacy">
                                        <mat-label>{{ "Card private comment" | translate }}*</mat-label>
                                        <input autocomplete="off" matInput formControlName="q_task_private_comment">
                                    </mat-form-field>
                                    <div class="d-f-c search-field-group">
                                        <mat-form-field class="search-field" appearance="legacy">
                                            <mat-label>{{ "Card custom ID" | translate }}*</mat-label>
                                            <input autocomplete="off" matInput formControlName="task_custom_id">
                                        </mat-form-field>
                                        <mat-form-field class="search-field ml-1" appearance="legacy">
                                            <mat-label>{{ "Card internal ID" | translate }}*</mat-label>
                                            <input autocomplete="off" type="number" matInput formControlName="internal_id">
                                        </mat-form-field>
                                        <mat-form-field class="search-field ml-1" appearance="legacy">
                                            <mat-label>{{ "Card system ID" | translate }}*</mat-label>
                                            <input autocomplete="off" matInput formControlName="id">
                                        </mat-form-field>
                                    </div>
                                    <mat-form-field class="search-field" appearance="legacy">
                                    <mat-label>{{ "Card workspace ID" | translate }}*</mat-label>
                                    <input autocomplete="off" type="number" matInput formControlName="task_company_id">
                                    </mat-form-field>
                                    
                                    <div style="padding-top: 10px;"></div>
                                    
                                    <mat-form-field class="search-field" appearance="legacy">
                                    <mat-label>{{ "Job title" | translate }}*</mat-label>
                                    <input autocomplete="off" matInput formControlName="q_task_operation_comment">
                                    </mat-form-field>
                                    <mat-form-field class="search-field" appearance="legacy">
                                    <mat-label>{{ "Job executor commment" | translate }}*</mat-label>
                                    <input autocomplete="off" matInput formControlName="q_task_operation_employee_comment">
                                    </mat-form-field>
                                    <mat-form-field class="search-field" appearance="legacy">
                                    <mat-label>{{ "Job private comment " | translate }}*</mat-label>
                                    <input autocomplete="off" matInput formControlName="q_task_operation_private_comment">
                                    </mat-form-field>
                                    <mat-form-field class="search-field" appearance="legacy">
                                    <mat-label>{{ "Job System ID " | translate }}*</mat-label>
                                    <input autocomplete="off" type="number" matInput formControlName="task_operation_id">
                                    </mat-form-field>
                            
                                    <div style="padding-top: 10px;"></div>
                                    <mat-form-field class="search-field" appearance="legacy">
                                    <mat-label>{{ "Chat system ID" | translate }}*</mat-label>
                                    <input autocomplete="off" type="number" matInput formControlName="discussion_id">
                                    </mat-form-field>
                                    <div style="padding-top: 10px;"></div>
                                    <mat-form-field class="search-field" appearance="legacy">
                                        <mat-label>{{ "Posts filename" | translate }}*</mat-label>
                                        <input autocomplete="off" matInput formControlName="q_task_channel_content_filename">
                                    </mat-form-field>
                                </ng-container>
                                <ng-container *ngSwitchCase="'Chats'">
                                    <mat-form-field class="search-field" appearance="legacy">
                                        <mat-label>{{ "Chat title" | translate }}*</mat-label>
                                        <input matInput formControlName="q_name">
                                    </mat-form-field>
                                    <mat-form-field class="search-field" appearance="legacy">
                                        <mat-label>{{ "Chat messages" | translate }}*</mat-label>
                                        <input matInput formControlName="q_text">
                                    </mat-form-field>
                                    <div class="d-f-c search-field-group">
                                        <mat-form-field class="search-field" appearance="legacy">
                                            <mat-label>{{ "Card custom ID" | translate }}*</mat-label>
                                            <input autocomplete="off" matInput formControlName="task_custom_id">
                                        </mat-form-field>
                                        <div></div>
                                        <div></div>
                                    </div>
                                </ng-container>
                                <ng-container *ngSwitchCase="'Projects'">
                                    <div class="d-f-c search-field-group">
                                        <mat-form-field class="search-field" appearance="legacy">
                                            <mat-label>{{ "Project ID" | translate }}*</mat-label>
                                            <input autocomplete="off" type="number" matInput formControlName="id">
                                        </mat-form-field>
                                        <mat-form-field class="search-field" appearance="legacy">
                                            <mat-label>{{ "Card ID" | translate }}*</mat-label>
                                            <input autocomplete="off" type="number" matInput formControlName="task_id">
                                        </mat-form-field>
                                        <mat-form-field class="search-field ml-1" appearance="legacy">
                                            <mat-label>{{ "Job ID" | translate }}*</mat-label>
                                            <input autocomplete="off" type="number" matInput formControlName="task_operation_id">
                                        </mat-form-field>
                                        <mat-form-field class="search-field ml-1" appearance="legacy">
                                            <mat-label>{{ "Chat ID" | translate }}*</mat-label>
                                            <input autocomplete="off" type="number" matInput formControlName="discussion_id">
                                        </mat-form-field>
                                    </div>
                                </ng-container>
                                <ng-container *ngSwitchCase="'Settings'">
                                    <ng-conatainer [ngSwitch]="activatedRoute.snapshot.queryParamMap.get('page')">
                                        <ng-container *ngSwitchCase="'company'">                       
                                            <div class="d-f-c search-field-group">
                                                <mat-form-field class="search-field" appearance="legacy">
                                                    <mat-label>{{ "Group ID" | translate }}*</mat-label>
                                                    <input autocomplete="off" type="number" matInput formControlName="id">
                                                </mat-form-field>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngSwitchCase="false">
                                        
                                        </ng-container>
                         
                                    </ng-conatainer>
                                </ng-container>
                                <ng-container *ngSwitchCase="'Analytic'">
                                    <ng-conatainer [ngSwitch]="activatedRoute.snapshot.queryParamMap.get('page')">
                                        <ng-container *ngSwitchCase="'progress'">                       
                                            <div class="d-f-c search-field-group">
                                                <mat-form-field class="search-field" appearance="legacy">
                                                    <mat-label>{{ "Group ID" | translate }}*</mat-label>
                                                    <input autocomplete="off" type="text" matInput formControlName="id">
                                                </mat-form-field>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngSwitchCase="false">
                                        
                                        </ng-container>
                         
                                    </ng-conatainer>
                                </ng-container>
                                <ng-container *ngSwitchDefault>
                                    <div class="d-f-c search-field-group">
                                        <mat-form-field class="search-field" appearance="legacy" *ngIf="activeSections() == 'Files'">
                                            <mat-label>{{ "File ID" | translate }}*</mat-label>
                                            <input autocomplete="off" type="number" matInput formControlName="file_id">
                                        </mat-form-field>
                                        <mat-form-field class="search-field" appearance="legacy">
                                            <mat-label>{{ "Card custom ID" | translate }}*</mat-label>
                                            <input autocomplete="off" matInput formControlName="task_custom_id">
                                        </mat-form-field>
                                    </div>
                                    <mat-form-field class="search-field" appearance="legacy" *ngIf="activeSections() == 'Files'">
                                        <mat-label>{{ "Content type" | translate }}*</mat-label>
                                        <input autocomplete="off" matInput formControlName="content_type">
                                    </mat-form-field>
                                </ng-container>
                                
                              </ng-container>
                              
                             
                              <div class="d-f-c" style="padding-top: 10px; justify-content: flex-end;">
                                
                                <!-- <button mat-button type="button" (click)="closeOpenFilter()">{{ "Close" | translate }}</button>
                                <button mat-raised-button color="warn" (click)="resetSearchParams()" type="button" class="ml-1">{{ "Clear" | translate }}</button> -->
                                <button mat-raised-button color="primary" type="submit" class="ml-1">{{ "Search" | translate }}</button>
                              </div>
                            </form>
                        </section>
                    </div>
                </div>
            </li>
    
            <!-- date -->
            <li class="nav_filter" [ngClass]="{'no_active': activeSections() == 'Analytic' || (activeSections() == 'Settings' && ['finances_dashboard', 'automation', 'checklists', 'workspace_members', 'individuals', 'schedules', 'partner_workspaces', 'questionnaires', 'total_usage', 'import-data', 'sftp', 'parameters', 'bookmarks', 'task_types', 'job_types', 'structure', 'form', 'users', 'categorization', 'discontinued', 'stats', 'chats', 'company', 'manage-auth', 'profiles'].includes(page) || (activeSections() == 'Settings' && page == 'finances' && ['payouts', 'current_activity'].includes(pageTab)))}">
                <a>
                    <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.4 16.2H1.8V6.3H14.4M11.7 0V1.8H4.5V0H2.7V1.8H1.8C0.801 1.8 0 2.601 0 3.6V16.2C0 16.6774 0.189642 17.1352 0.527208 17.4728C0.864773 17.8104 1.32261 18 1.8 18H14.4C14.8774 18 15.3352 17.8104 15.6728 17.4728C16.0104 17.1352 16.2 16.6774 16.2 16.2V3.6C16.2 2.601 15.39 1.8 14.4 1.8H13.5V0M12.6 9.9H8.1V14.4H12.6V9.9Z" fill="#686868"/>
                    </svg>
                </a>
    
                <div class="expanded_block_out">
                    <div class="expanded_block_in exp_subs exp_date">
                        <div class="title_nav">
                            <span (click)="logDates()">
                                {{ "Date" | translate }}
                            </span>
                        </div>
                        <section class="sub_filter_wrp">
                            <div class="subs" [appDeviceClass]="'custom-scroll'">
                                <div class="sub" *ngFor="let tab of datesModel" [ngClass]="{'active': datesControl.value === tab.id}" (click)="patchControl(datesControl, tab.id)">
                                    {{ tab.name | translate }} <ng-container *ngIf="tab?.onlyThis">*</ng-container>
                                </div>
                            </div>
                            <div class="block" style="padding: 0;">
                                <div class="sub_tabs">
                                    <div class="sub_tabs_in">
                                        <div class="sub_tab" *ngFor="let subTab of dateByControl(datesControl.value).filters" [ngClass]="{'active': dateByControl(datesControl.value).activeFilter.value === subTab.id}" (click)="dateByControl(datesControl.value).filters.length > 1 ? patchControl(dateByControl(datesControl.value).activeFilter, subTab.id) : null;">
                                            {{subTab.name}} <ng-container *ngIf="subTab?.onlyThis">*</ng-container>
                                        </div>
                                    </div>
                                </div>
                                <ng-container *ngIf="!switched">
                                    <form [formGroup]="filter" class="exp_form" [appDeviceClass]="'custom-scroll'"  (ngSubmit)="searchFilterSubmit()">
                                        <ng-container *ngIf="dateByControl(datesControl.value).id == 6; else elseTemplateNormalRange">
                                            <mat-calendar 
                                            startView="multi-year" 
                                            panelClass="example-month-picker"
                                            [selected]="filter.get(dateByControl(datesControl.value).filters[dateByControl(datesControl.value).activeFilter.value].from).value"
                                            (yearSelected)="chosenYearHandler($event)"
                                            (monthSelected)="chosenMonthHandler($event)"></mat-calendar>
                                        </ng-container>
                                        <ng-template #elseTemplateNormalRange>
                                            <mat-calendar (selectedChange)="rangeChange($event, dateByControl(datesControl.value).filters[dateByControl(datesControl.value).activeFilter.value].key)" [selected]="dateByControl(datesControl.value).filters[dateByControl(datesControl.value).activeFilter.value].range"></mat-calendar>
                                            <div class="calendar_inputs">
                                                <mat-form-field appearance="standard">
                                                    <mat-label>{{ "Date Start" | translate }}</mat-label>
                                                    <input matInput [matDatepicker]="dp_from" formControlName="{{dateByControl(datesControl.value).filters[dateByControl(datesControl.value).activeFilter.value].from}}">
                                                    <!-- <mat-datepicker-toggle matIconSuffix [for]="dp_from"></mat-datepicker-toggle> -->
                                                    <mat-datepicker #dp_from></mat-datepicker>
                                                </mat-form-field>
                                                <span class="splash"></span>
                                                <mat-form-field appearance="standard">
                                                    <mat-label>
                                                        <ng-container *ngIf="!filter.get(dateByControl(datesControl.value).filters[dateByControl(datesControl.value).activeFilter.value].to).value && !!filter.get(dateByControl(datesControl.value).filters[dateByControl(datesControl.value).activeFilter.value].from).value; else elseTemplateSOFAR">
                                                            {{ "So far" | translate }}
                                                        </ng-container>
                                                        <ng-template #elseTemplateSOFAR>
                                                            {{ "Date End" | translate }}
                                                        </ng-template>
                                                    </mat-label>
                                                    <input matInput [matDatepicker]="dp_to" formControlName="{{dateByControl(datesControl.value).filters[dateByControl(datesControl.value).activeFilter.value].to}}">
                                                    <!-- <mat-datepicker-toggle matIconSuffix [for]="dp_to"></mat-datepicker-toggle> -->
                                                    <mat-datepicker #dp_to></mat-datepicker>
                                                </mat-form-field>
                                            </div>
                                        </ng-template>
                                        
                                        <div class="apply_date">
                                            <button type="button" (click)="clearDate(dateByControl(datesControl.value).filters[dateByControl(datesControl.value).activeFilter.value].key)">{{ "Clear" | translate }}</button>
                                            <button class="ml-1 blue" type="submit">{{ "Search" | translate }}</button>
                                        </div>
                                    </form>
                                </ng-container>
                            </div>
                        </section>
                    </div>
                </div>
            </li>
    
            <!-- shortcuts -->
            <li class="nav_filter">
                <a>
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9 0L5.778 5.004L0 6.516L3.78 11.142L3.438 17.1L9 14.931L14.562 17.1L14.22 11.142L18 6.516L12.222 5.004L9 0ZM6.642 7.2C7.2 7.2 7.65 7.65 7.65 8.217C7.65 8.48434 7.5438 8.74073 7.35476 8.92976C7.16573 9.1188 6.90934 9.225 6.642 9.225C6.075 9.225 5.625 8.775 5.625 8.217C5.625 7.65 6.075 7.2 6.642 7.2ZM11.367 7.2C11.925 7.2 12.375 7.65 12.375 8.217C12.375 8.48434 12.2688 8.74073 12.0798 8.92976C11.8907 9.1188 11.6343 9.225 11.367 9.225C10.8 9.225 10.35 8.775 10.35 8.217C10.35 7.65 10.8 7.2 11.367 7.2ZM6.3 11.25H11.7C11.25 12.339 10.179 13.05 9 13.05C7.821 13.05 6.75 12.339 6.3 11.25Z" fill="#686868"/>
                    </svg>
                </a>
                <div class="expanded_block_out" [ngClass]="{'hide_shorts': hideShorts}">
                    <div class="expanded_block_in exp_shorts">
                        <div class="short_title">
                            <div class="d-f-c">
                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9 0L5.778 5.004L0 6.516L3.78 11.142L3.438 17.1L9 14.931L14.562 17.1L14.22 11.142L18 6.516L12.222 5.004L9 0ZM6.642 7.2C7.2 7.2 7.65 7.65 7.65 8.217C7.65 8.48434 7.5438 8.74073 7.35476 8.92976C7.16573 9.1188 6.90934 9.225 6.642 9.225C6.075 9.225 5.625 8.775 5.625 8.217C5.625 7.65 6.075 7.2 6.642 7.2ZM11.367 7.2C11.925 7.2 12.375 7.65 12.375 8.217C12.375 8.48434 12.2688 8.74073 12.0798 8.92976C11.8907 9.1188 11.6343 9.225 11.367 9.225C10.8 9.225 10.35 8.775 10.35 8.217C10.35 7.65 10.8 7.2 11.367 7.2ZM6.3 11.25H11.7C11.25 12.339 10.179 13.05 9 13.05C7.821 13.05 6.75 12.339 6.3 11.25Z" fill="#686868"/>
                                </svg>
                                <span>{{ "Shortcuts" | translate }}</span>
                            </div>
                        </div>

                        <!-- <button (click)="check(barItems)">click</button> -->
    
                        <div class="shorts">
                            <div class="short" *ngIf="groupedBarItems(barItems) && groupedBarItems(barItems).tasks && groupedBarItems(barItems).tasks.length">
                                <div class="short_img">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16 12V16H2V12H16ZM17 10H1C0.45 10 0 10.45 0 11V17C0 17.55 0.45 18 1 18H17C17.55 18 18 17.55 18 17V11C18 10.45 17.55 10 17 10ZM4 15.5C3.18 15.5 2.5 14.83 2.5 14C2.5 13.17 3.18 12.5 4 12.5C4.82 12.5 5.5 13.17 5.5 14C5.5 14.83 4.83 15.5 4 15.5ZM16 2V6H2V2H16ZM17 0H1C0.45 0 0 0.45 0 1V7C0 7.55 0.45 8 1 8H17C17.55 8 18 7.55 18 7V1C18 0.45 17.55 0 17 0ZM4 5.5C3.18 5.5 2.5 4.83 2.5 4C2.5 3.17 3.18 2.5 4 2.5C4.82 2.5 5.5 3.18 5.5 4C5.5 4.82 4.83 5.5 4 5.5Z" fill="#686868"/>
                                    </svg>
                                </div>
                                <div class="short_info">
                                    <div class="info_title">
                                        {{ "Cards" | translate }}
                                    </div>
    
                                    <div class="short_items">
    
                                        <div class="short_item" (click)="goToItem($event, item)" *ngFor="let item of groupedBarItems(barItems).tasks">
                                            <mat-icon (click)="linkToItem($event, item)" class="open_short_in_new">open_in_new</mat-icon>
                                            <span class="text_one_line">
                                                {{item?.switchData?.name || "no name"}}
                                            </span>
                                            <mat-icon (click)="removeItem($event, item)">close</mat-icon>
                                        </div>
                                    </div>
                                </div>
                            </div>
    
                            <div class="short" *ngIf="groupedBarItems(barItems) && groupedBarItems(barItems).works && groupedBarItems(barItems).works.length">
                                <div class="short_img">
                                    <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0 14.4H18V10.8H0V14.4ZM1.8 11.7H3.6V13.5H1.8V11.7ZM0 0V3.6H18V0H0ZM3.6 2.7H1.8V0.9H3.6V2.7ZM0 9H18V5.4H0V9ZM1.8 6.3H3.6V8.1H1.8V6.3Z" fill="#686868"/>
                                    </svg>
                                </div>
                                <div class="short_info">
                                    <div class="info_title">
                                        {{ "Jobs" | translate }}
                                    </div>
    
                                    <div class="short_items">
    
                                        <div class="short_item" (click)="goToItem($event, item)" *ngFor="let item of groupedBarItems(barItems).works">
                                            <mat-icon (click)="linkToItem($event, item)" class="open_short_in_new">open_in_new</mat-icon>
                                            <span class="text_one_line">
                                                {{item?.switchData?.name || "no name"}}
                                            </span>
                                            <mat-icon (click)="removeItem($event,item)">close</mat-icon>
                                        </div>
                                    </div>
                                </div>
                            </div>
    
                            <div class="short" *ngIf="groupedBarItems(barItems) && groupedBarItems(barItems).chats && groupedBarItems(barItems).chats.length">
                                <div class="short_img">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16.2 0H1.8C1.32261 0 0.864773 0.189642 0.527208 0.527208C0.189642 0.864773 0 1.32261 0 1.8V18L3.6 14.4H16.2C16.6774 14.4 17.1352 14.2104 17.4728 13.8728C17.8104 13.5352 18 13.0774 18 12.6V1.8C18 0.801 17.19 0 16.2 0Z" fill="#686868"/>
                                    </svg>
                                </div>
                                <div class="short_info">
                                    <div class="info_title">
                                        {{ "Chats" | translate }}
                                    </div>
    
                                    <div class="short_items">
                                        <div class="short_item" (click)="goToItem($event, item)" *ngFor="let item of groupedBarItems(barItems).chats">
                                            <mat-icon (click)="linkToItem($event, item)" class="open_short_in_new">open_in_new</mat-icon>
                                            <span class="text_one_line">
                                                {{item?.switchData?.name || "no name"}}
                                            </span>
                                            <mat-icon (click)="removeItem($event,item)">close</mat-icon>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="short" *ngIf="groupedBarItems(barItems) && groupedBarItems(barItems).files && groupedBarItems(barItems).files.length">
                                <div class="short_img">
                                    <svg width="9" height="18" viewBox="0 0 9 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.77273 4.09091V13.5C7.77273 14.368 7.42792 15.2004 6.81417 15.8142C6.20041 16.4279 5.36798 16.7727 4.5 16.7727C3.63202 16.7727 2.79959 16.4279 2.18583 15.8142C1.57208 15.2004 1.22727 14.368 1.22727 13.5V3.27273C1.22727 2.73024 1.44278 2.20997 1.82637 1.82637C2.20997 1.44278 2.73024 1.22727 3.27273 1.22727C3.81522 1.22727 4.33548 1.44278 4.71908 1.82637C5.10268 2.20997 5.31818 2.73024 5.31818 3.27273V11.8636C5.31818 12.0806 5.23198 12.2887 5.07854 12.4422C4.9251 12.5956 4.717 12.6818 4.5 12.6818C4.283 12.6818 4.0749 12.5956 3.92146 12.4422C3.76802 12.2887 3.68182 12.0806 3.68182 11.8636V4.09091H2.45455V11.8636C2.45455 12.4061 2.67005 12.9264 3.05365 13.31C3.43724 13.6936 3.95751 13.9091 4.5 13.9091C5.04249 13.9091 5.56276 13.6936 5.94635 13.31C6.32995 12.9264 6.54545 12.4061 6.54545 11.8636V3.27273C6.54545 2.40475 6.20065 1.57232 5.58689 0.95856C4.97314 0.344804 4.14071 0 3.27273 0C2.40475 0 1.57231 0.344804 0.958559 0.95856C0.344804 1.57232 0 2.40475 0 3.27273V13.5C0 14.6935 0.474106 15.8381 1.31802 16.682C2.16193 17.5259 3.30653 18 4.5 18C5.69347 18 6.83807 17.5259 7.68198 16.682C8.52589 15.8381 9 14.6935 9 13.5V4.09091H7.77273Z" fill="#686868"/>
                                    </svg>
                                </div>
                                <div class="short_info">
                                    <div class="info_title">
                                        {{ "Files" | translate }}
                                    </div>
    
                                    <div class="short_items">
                                        <ng-container *ngFor="let item of groupedBarItems(barItems).files">     
                                            <div class="short_item" (click)="goToItem($event, item)" [ngClass]="{'dir': item?.switchData?.is_dir == 1}">
                                                <mat-icon (click)="linkToItem($event, item)" class="open_short_in_new">open_in_new</mat-icon>
                                                <div class="d-f-c" style="width: calc(100% - 30px);">
                                                    <mat-icon style="color: #686868; display: block; margin-right: 5px; margin-left: 0 !important;" *ngIf="item?.switchData?.is_dir == 1">folder</mat-icon>
                                                    <span class="text_one_line">
                                                        {{ (item?.switchData?.is_dir == 1 && item?.switchData?.location == '/' ? (item?.switchData?.filename | translate) : item?.switchData?.filename) }}
                                                    </span>
                                                </div>
                                                <mat-icon (click)="removeItem($event,item)">close</mat-icon>
                                            </div>
                                        </ng-container>
                                        
                                    </div>
                                </div>
                            </div>

                            <div class="short" *ngIf="favoriteBookmarks && favoriteBookmarks.length">
                                <div class="short_img">
                                    <mat-icon style="color: #686868;">bookmarks</mat-icon>
                                </div>
                                <div class="short_info">
                                    <div class="info_title">
                                        {{ "Presets" | translate }}
                                    </div>
    
                                    <div class="short_items">
                                        <div class="short_item" (click)="goToBookmark($event, bookmark)" *ngFor="let bookmark of favoriteBookmarks">
                                            <span class="text_one_line">
                                                {{bookmark.name}}
                                            </span>
                                            <!-- <mat-icon (click)="removeBookmarkItem($event, bookmark)">close</mat-icon> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
    
                            <p style="color: #686868;" *ngIf="!groupedBarItems(barItems) || (!groupedBarItems(barItems).chats.length && !groupedBarItems(barItems).works.length && !groupedBarItems(barItems).tasks.length)">{{"You can add an element to the shortcuts, in the menu 3 dots of the element (card, job, chat)" | translate}}.</p>
                        </div>
                    </div>
                </div>
            </li>

            
            <li class="nav_filter" *ngIf="isMobHasActiveFilter || activeSections() == 'Projects' || activeSections() == 'Jobs'" (click)="$event.stopImmediatePropagation(); $event.stopPropagation(); saveFilterToBookmarks()" #tooltip="matTooltip"
            matTooltip="{{ 'Save filter to Presets' | translate }}">
                <a>
                    <mat-icon style="color: #686868;">bookmark_add</mat-icon>
                </a>
            </li>
        </ul>
    </nav>
</ng-container>
<ng-template #elseTemplateDeskFil>
    <nav class="glob_mob_filter">
        <ul class="glob_filters_wrp" [ngClass]="{'deactivate_mob': ['Post Dashboards', 'Outlet Dashboards'].includes(activeSections())}">
            <li class="fil_item no_p active" (click)="openMobActiveFilters()" *ngIf="true || isMobHasActiveFilter">
                <mat-icon>tune</mat-icon>
            </li>
            <li class="fil_item no_p" (click)="openMobSearch()">
                <mat-icon>search</mat-icon>
            </li>
            <li class="fil_item no_p" (click)="openMobDateFilter()">
                <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.4 16.2H1.8V6.3H14.4M11.7 0V1.8H4.5V0H2.7V1.8H1.8C0.801 1.8 0 2.601 0 3.6V16.2C0 16.6774 0.189642 17.1352 0.527208 17.4728C0.864773 17.8104 1.32261 18 1.8 18H14.4C14.8774 18 15.3352 17.8104 15.6728 17.4728C16.0104 17.1352 16.2 16.6774 16.2 16.2V3.6C16.2 2.601 15.39 1.8 14.4 1.8H13.5V0M12.6 9.9H8.1V14.4H12.6V9.9Z" fill="#686868"/>
                </svg>
            </li>
            <li (click)="openMobShortcuts()" class="fil_item short_mob_fil no_p" *ngIf="!(!groupedBarItems(barItems) || (!groupedBarItems(barItems).chats.length && !groupedBarItems(barItems).works.length && !groupedBarItems(barItems).tasks.length && !groupedBarItems(barItems).files.length && !favoriteBookmarks?.length))">
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9 0L5.778 5.004L0 6.516L3.78 11.142L3.438 17.1L9 14.931L14.562 17.1L14.22 11.142L18 6.516L12.222 5.004L9 0ZM6.642 7.2C7.2 7.2 7.65 7.65 7.65 8.217C7.65 8.48434 7.5438 8.74073 7.35476 8.92976C7.16573 9.1188 6.90934 9.225 6.642 9.225C6.075 9.225 5.625 8.775 5.625 8.217C5.625 7.65 6.075 7.2 6.642 7.2ZM11.367 7.2C11.925 7.2 12.375 7.65 12.375 8.217C12.375 8.48434 12.2688 8.74073 12.0798 8.92976C11.8907 9.1188 11.6343 9.225 11.367 9.225C10.8 9.225 10.35 8.775 10.35 8.217C10.35 7.65 10.8 7.2 11.367 7.2ZM6.3 11.25H11.7C11.25 12.339 10.179 13.05 9 13.05C7.821 13.05 6.75 12.339 6.3 11.25Z" fill="#686868"/>
                </svg>
            </li>
            <li (click)="openMobSort()" *ngIf="['Cards', 'Jobs', 'Chats', 'Posts'].includes(activeSections())" class="fil_item no_p">
                <mat-icon style="color: #686868;">sort</mat-icon>
            </li>
            <li class="fil_item" *ngIf="['Cards', 'Jobs', 'Chats', 'Board by Date', 'Board by Order'].includes(activeSections())" (click)="openStatusFilter()">
                {{ 'Status' | translate }}
            </li>
            <li class="fil_item" (click)="openDefaultFilter('card')">
                {{ 'Card' | translate }}
            </li>
            <li class="fil_item" (click)="openDefaultFilter('job')">
                {{ 'Job' | translate }}
            </li>
            <li class="fil_item" (click)="openMobStakeholder()">
                {{ 'User' | translate }}
            </li>
            <li class="fil_item" (click)="openDefaultFilter('group')">
                {{ 'Group' | translate }}
            </li>
            <li class="fil_item" (click)="openMobTagFilter()">
                {{ 'Tag' | translate }}
            </li>
            <li class="fil_item" (click)="openDefaultFilter('priority')">
                {{ 'Priority' | translate }}
            </li>
            <li *ngIf="company | perm:'see_task_channels'" class="fil_item" (click)="openMobChannelsFilter()">
                {{ 'Outlet' | translate }}
            </li>
            <li *ngIf="company | perm:'see_task_channels'" class="fil_item" (click)="openMobTypeFilter()">
                {{ 'Type' | translate }}
            </li>
            <li *ngIf="company | perm:'see_task_channels'" class="fil_item" (click)="openDefaultFilter('status')">
                {{ 'Status' | translate }}
            </li>
            <!-- <li *ngIf="!!filter.get('task_content_status_id') && activeSections() == 'Cards'" class="fil_item" (click)="openDefaultFilter('task_content_status_id')">
                {{ "Card Posts Status" | translate }}*
            </li> -->
            <li *ngIf="company | perm:'see_task_channels'" class="fil_item" (click)="openDefaultFilter('url_presented')">
                {{ 'Url presented' | translate }}
            </li>
            <li *ngIf="company | perm:'see_task_channels'" class="fil_item" (click)="openMobPlatformFilter()">
                {{ 'Platform' | translate }}
            </li>
            <li *ngIf="!!filter.get('is_content_views_count_update') && activeSections() == 'Posts'" class="fil_item" (click)="openMobAutoUpdateFilter('is_content_views_count_update')">
                {{ "Autoupdate" | translate }}*
            </li>
            <li *ngIf="!!filter.get('is_bravourl_content_views_count_update') && activeSections() == 'Posts'" class="fil_item" (click)="openMobAutoUpdateFilter('is_bravourl_content_views_count_update')">
                {{ "Autoupdate (bravo)" | translate }}*
            </li>
            <li *ngIf="!!filter.get('views_update_interval') && activeSections() == 'Posts'" class="fil_item" (click)="openMobUpdateIntervalFilter()">
                {{ "Views Update Interval" | translate }}*
            </li>
            <li *ngIf="!!filter.get('scheduled_published_time_by') && activeSections() == 'Posts'" class="fil_item" (click)="openMobPostingMethodFilter()">
                {{ "Posting method" | translate }}*
            </li>
            <ng-container *ngIf="activeSections() == 'Files'">
                <li *ngIf="!!filter.get('operation_reminder_id')" class="fil_item" (click)="openDefaultFilter('checklist')">
                    {{ "Checklist" | translate }}*
                </li>
                <li *ngIf="!!filter.get('meta_device')" class="fil_item" (click)="openDefaultFilter('device')">
                    {{ "Device" | translate }}*
                </li>
                <li *ngIf="!!filter.get('is_ready_if_approved')" class="fil_item" (click)="openDefaultFilter('readyVersion')">
                    {{ "Ready Version" | translate }}*
                </li>
                <li *ngIf="!!filter.get('part_of_count')" class="fil_item" (click)="openDefaultFilter('part_of_count')">
                    {{ "Part of" | translate }}*
                </li>
                <li *ngIf="!!filter.get('consist_of_count')" class="fil_item" (click)="openDefaultFilter('consist_of_count')">
                    {{ "Consist of" | translate }}*
                </li>
                <li *ngIf="!!filter.get('related_count')" class="fil_item" (click)="openDefaultFilter('related_count')">
                    {{ "Related" | translate }}*
                </li>
            </ng-container>
            
        </ul>
    </nav>
</ng-template>


  

<ng-template #defaultFilter let-array="array" let-search="search" let-asArray="asArray" let-control="control">
    <section class="">
        <ng-container *ngIf="array.length > 7; else elseTemplateTaskStatus">
            <div class="filter_exp_search">
                <mat-form-field appearance="legacy" class="filter_form search">
                    <input class="search_inp" [formControl]="search" matInput>
                    <mat-icon matSuffix>search</mat-icon>
                </mat-form-field>
            </div>
            <ng-container *ngIf="!switched">
                <form [formGroup]="filter" class="exp_form" [appDeviceClass]="'custom-scroll'"
                [ngClass]="{'no_min_h': control == 'priority'}"
                infinite-scroll
                [scrollWindow]="false"
                [infiniteScrollUpDistance]="scrollUpDistance"
                [infiniteScrollThrottle]="throttle"
                (scrolled)="onDown($event)">
                <!-- <mat-selection-list [multiple]="control == 'group_id'" formControlName="{{control}}" (selectionChange)="selectChange($event, control)"> -->
                    <mat-selection-list [multiple]="control == 'group_id'" formControlName="{{control}}" (selectionChange)="selectChange($event, control == 'group_id', control)">
                        <mat-list-option *ngIf="control == 'group_id'" [value]="0" [ngClass]="{'active': 0 === filter.get(control).value}">
                            <div class="d-f-c">
                                <span>
                                    {{ "Group not set" | translate }}
                                </span>
                            </div>
                        </mat-list-option>
                        <mat-list-option *ngIf="control == 'task_content_status_id'" [value]="0" [ngClass]="{'active': 0 === filter.get(control).value}">
                            <div class="d-f-c">
                                <span>
                                    {{ "Different" | translate }}
                                </span>
                            </div>
                        </mat-list-option>
                        <mat-list-option *ngFor="let fil of asArray | async" [value]="fil.id" [ngClass]="{'active': control == 'priority' ? fil.id === filter.get(control).value : fil.id == filter.get(control).value, 'has_not_assigned': control == 'task_operation_operation_id'}">
                            <div class="d-f-c">
                                <div class="platform_icon mr-1" *ngIf="control == 'channel_id'">
                                    <app-platform [platform_id]="fil?.platform_id"></app-platform>
                                </div>
                                <span>
                                    {{fil.name}}
                                </span>
                            </div>
                            <span class="not_assigned" *ngIf="control == 'task_operation_operation_id'" (click)="noOperation($event, fil.id)" [ngClass]="{'active': filter.get('no_operation_id').value == fil.id}">
                                {{ "n/a" | translate }}
                            </span>
                        </mat-list-option>
                    </mat-selection-list>
                </form>
            </ng-container>
        </ng-container>
        <ng-template #elseTemplateTaskStatus>
            <form [formGroup]="filter" class="exp_form" [appDeviceClass]="'custom-scroll'" 
            [ngClass]="{'no_min_h': control == 'priority'}"
            infinite-scroll
            [scrollWindow]="false"
            [infiniteScrollUpDistance]="scrollUpDistance"
            [infiniteScrollThrottle]="throttle"
            (scrolled)="onDown($event, control)">
                <!-- <mat-selection-list [multiple]="control == 'group_id'" formControlName="{{control}}" (selectionChange)="selectChange($event, control == 'group_id')"> -->
                <mat-selection-list [multiple]="control == 'group_id'" formControlName="{{control}}" (selectionChange)="selectChange($event, control == 'group_id', control)">
                    <mat-list-option *ngIf="control == 'group_id'" [value]="0" [ngClass]="{'active': 0 === filter.get(control).value}">
                        <div class="d-f-c">
                            <span>
                                {{ "Group not set" | translate }}
                            </span>
                        </div>
                    </mat-list-option>
                    <mat-list-option *ngIf="control == 'task_content_status_id'" [value]="0" [ngClass]="{'active': 0 === filter.get(control).value}">
                        <div class="d-f-c">
                            <span>
                                {{ "Different" | translate }}
                            </span>
                        </div>
                    </mat-list-option>
                    <mat-list-option *ngFor="let fil of array" [value]="fil.id" [ngClass]="{'active': control == 'priority' ? fil.id === filter.get(control).value : fil.id == filter.get(control).value, 'has_not_assigned': control == 'task_operation_operation_id'}">
                        <div class="d-f-c">
                            <div class="platform_icon mr-1" *ngIf="control == 'channel_id'">
                                <app-platform [platform_id]="fil?.platform_id"></app-platform>
                            </div>
                            <span>
                                {{fil.name}}
                            </span>
                        </div>
                        <span class="not_assigned" *ngIf="control == 'task_operation_operation_id'" (click)="noOperation($event, fil.id)" [ngClass]="{'active': filter.get('no_operation_id').value == fil.id}">
                            {{ "n/a" | translate }}
                        </span>
                    </mat-list-option>
                </mat-selection-list>
            </form>
        </ng-template>
        <div class="search_fil_line" style="padding-bottom: 10px;" *ngIf="control == 'group_id'">
            <button (click)="searchFilterSubmit()">{{"Search" | translate}}</button>
        </div>
    </section>
</ng-template>

<ng-template #subFilter let-subControl="subControl" let-subs="subs" let-arrays="arrays" let-searchs="searchs" let-asArrays="asArrays" let-controls="controls">
    <section class="sub_filter_wrp">
        <div class="subs" [appDeviceClass]="'custom-scroll'">
            <div class="sub" [ngClass]="{'active': subControl.value == i}" (click)="patchControl(subControl, i)" *ngFor="let item of subs; let i = index;">
                {{item}}
            </div>
        </div>
        <div class="block">
            <ng-container *ngIf="!switched && arrays && arrays[subControl['value']] && arrays[subControl['value']].length">
                <ng-container *ngIf="arrays[subControl['value']].length > 7; else elseTemplateSubL">
                    <div class="filter_exp_search">
                        <mat-form-field appearance="legacy" class="filter_form search">
                            <input class="search_inp" [formControl]="searchs[subControl['value']]" matInput>
                            <mat-icon matSuffix>search</mat-icon>
                        </mat-form-field>
                    </div>
                    <form [formGroup]="filter" class="exp_form" [appDeviceClass]="'custom-scroll'">
                        <mat-selection-list [multiple]="false" formControlName="{{controls[subControl['value']]}}" (selectionChange)="selectChange($event)">
                            <mat-list-option [value]="0" *ngIf="postsControl.value == 0" [ngClass]="{'active': 0 === filter.get(controls[subControl['value']]).value}">
                                <div class="user_div">
                                    <mat-icon style="visibility: none;">user</mat-icon>
                                    <span>
                                        {{ "User not set" | translate }}
                                    </span>
                                </div>
                            </mat-list-option>
                            <ng-container *ngFor="let fil of asArrays[subControl['value']] | async">
                                <ng-container *ngIf="subControl['value'] < 2; else elseTemplateTypeSub">
                                    <mat-list-option [value]="fil.id" [ngClass]="{'active': fil.id == filter.get(controls[subControl['value']]).value}">
                                        <app-user [class]="'user_div'" [employee]="fil" [imgRoute]="imgRoute" [host]="host" [company]="company"></app-user>
                                    </mat-list-option>
                                </ng-container>
                                <ng-template #elseTemplateTypeSub>
                                    <mat-list-option [value]="fil.partner_company_id" [ngClass]="{'active': fil.partner_company_id == filter.get(controls[subControl['value']]).value}">
                                        <app-user [class]="'user_div'" [companyEmployee]="fil?.partnerCompany" [imgRoute]="imgRoute" [host]="host" [company]="company"></app-user>
                                    </mat-list-option>
                                </ng-template>
                            </ng-container>
                    </mat-selection-list>
                    </form>
                </ng-container>
                <ng-template #elseTemplateSubL>
                    <form [formGroup]="filter" class="exp_form" [appDeviceClass]="'custom-scroll'">
                        <mat-selection-list [multiple]="false" formControlName="{{controls[subControl['value']]}}" (selectionChange)="selectChange($event)">
                            <mat-list-option [value]="0" *ngIf="postsControl.value == 0" [ngClass]="{'active': 0 === filter.get(controls[subControl['value']]).value}">
                                <div class="user_div">
                                    <mat-icon style="visibility: none;">user</mat-icon>
                                    <span>
                                        {{ "User not set" | translate }}
                                    </span>
                                </div>
                            </mat-list-option>
                            <ng-container *ngFor="let fil of arrays[subControl['value']]">
                                <ng-container *ngIf="subControl['value'] < 2; else elseTemplateTypeSub">
                                    <mat-list-option [value]="fil.id" [ngClass]="{'active': fil.id == filter.get(controls[subControl['value']]).value}">
                                        <app-user [class]="'user_div'" [employee]="fil" [imgRoute]="imgRoute" [host]="host" [company]="company"></app-user>
                                    </mat-list-option>
                                </ng-container>
                                <ng-template #elseTemplateTypeSub>
                                    <mat-list-option [value]="fil.partner_company_id" [ngClass]="{'active': fil.partner_company_id == filter.get(controls[subControl['value']]).value}">
                                        <app-user [class]="'user_div'" [companyEmployee]="fil?.partnerCompany" [imgRoute]="imgRoute" [host]="host" [company]="company"></app-user>
                                    </mat-list-option>
                                </ng-template>
                            </ng-container>
                        </mat-selection-list>
                    </form>
                </ng-template>
            </ng-container>
            
        </div>
    </section>
</ng-template>
