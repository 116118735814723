<mat-dialog-content class="mat-typography" dirEnter (dirSubmit)="!form.valid || isSubmit ? null : onSubmit()">
    <div class="container form_wrapper">
        <div class="title_modal" (click)="log()">
            {{ "Add executor for" | translate }} {{data.scen ? "Job Scenario" : "Card Template" | translate}}
        </div>
        <form [formGroup]="form" class="form_with_pads form_with_valids">

            <div class="users_select" [ngClass]="{'req_value': !form.get('employee_id').valid}">
                <label>{{ "Choose an executor" | translate }}: </label>
                <mat-chip-list [multiple]="false" selectable formControlName="employee_id">
                    <!-- <mat-chip style="padding: 0 !important;" class="chip_user clear" (click)="toggleChip({id: 0})" [value]="0">
                        <mat-icon style="opacity: .7;">close</mat-icon>
                    </mat-chip> -->
                    <ng-container *ngFor="let employee of employees; let i = index">
                        <mat-chip class="chip_user" (click)="toggleChip(employee)" [value]="employee.id">
                            <app-user [class]="'user_div'" [employee]="employee"></app-user>
                        </mat-chip>
                        <mat-chip *ngIf="i == employees.length - 1 && i > 6" class="chip_user more" (click)="employeeIdContainer.open()">
                            {{ "more" | translate }}
                        </mat-chip>
                    </ng-container>
                </mat-chip-list>
            </div>

            <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                <mat-select #employeeIdContainer formControlName="employee_id" (selectionChange)="toggleChip($event)">
                    <mat-option>
                        <ngx-mat-select-search 
                        placeholderLabel="{{ 'Search' | translate }}" 
                        noEntriesFoundLabel="{{ 'no matching executor found' | translate }}"
                        [formControl]="employeeMoreControl"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let employee of employees$ | async" [value]="employee.id">
                        <app-user [class]="'user_div'" [employee]="employee"></app-user>
                    </mat-option>
                </mat-select>
            </mat-form-field>


            <div class="users_select" *ngIf="partners?.length > 0 && !!form.get('partner_company_id')">
                <label>{{ "Choose a partner" | translate }}: </label>
                <mat-chip-list [multiple]="false" selectable formControlName="partner_company_id">
                    <ng-container *ngFor="let partner of partners; let i = index">
                        <mat-chip class="chip_user" (click)="togglePartnerChip(partner)" [value]="partner?.partner_company_id">
                            <app-user [class]="'user_div'" [companyEmployee]="partner?.partnerCompany" [imgRoute]="imgRoute" [host]="host" [company]="data?.company"></app-user>
                        </mat-chip>
                        <mat-chip *ngIf="i == partners.length - 1 && i > 6" class="chip_user more" (click)="partnerAddAdditionalContainer.open()">
                            {{ "more" | translate }}
                        </mat-chip>
                    </ng-container>
                </mat-chip-list>
            </div>

            <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                <mat-select #partnerAddAdditionalContainer formControlName="partner_company_id" (selectionChange)="togglePartnerChip($event)">
                    <mat-option>
                        <ngx-mat-select-search 
                        placeholderLabel="{{ 'Search' | translate }}" 
                        noEntriesFoundLabel="{{ 'no matching partner found' | translate }}"
                        [formControl]="partnerMoreControl"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let partner of partners$ | async" [value]="partner?.partner_company_id">
                        <app-user [class]="'user_div'" [companyEmployee]="partner?.partnerCompany" [imgRoute]="imgRoute" [host]="host" [company]="data?.company"></app-user>
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </form>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <div class="f_w_p_btns">
        <button mat-raised-button (click)="close()">
            {{ "Back" | translate }}
        </button>
        <button class="ml-1 d-f-btn" (click)="onSubmit()" mat-raised-button cdkFocusInitial color="primary" [disabled]="isSubmit">
            <mat-spinner class="spin_16 mr-1" *ngIf="isSubmit"></mat-spinner>  
            {{ "Add" | translate }}
        </button>
    </div>
</mat-dialog-actions>