import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {
  isMac: boolean;

  constructor() {
    const userAgent = window.navigator.userAgent.toLowerCase();
    this.isMac = userAgent.includes('mac');
  }
}