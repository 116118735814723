import { NgxMatDateAdapter } from '@angular-material-components/datetime-picker';
import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import * as moment from 'moment';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { fromEvent, of, ReplaySubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MY_FORMATS } from 'src/app/components/atTasksDialog/task-profile-add/task-profile-add.component';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';

@Component({
  selector: 'app-add-regular',
  templateUrl: './add-regular.component.html',
  styleUrls: ['./add-regular.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})

export class AddRegularComponent extends BaseClass implements OnInit, OnDestroy {
  public pagination: any;
  public platforms: any;
  public page: number = 1;

  public contentPagination: any;
  public contentPage: number = 1;

  public form: FormGroup;

  public types:any = ['is_daily', 'is_weekly', 'is_monthly']

  public timeZone = - new Date().getTimezoneOffset();
  public opened: boolean = false;
  public isSubmit: boolean = false;
  public submited: boolean = false;
  public isFormChange: boolean = false;

  public channels: any[] = [];
  // public channelMoreControl: FormControl = new FormControl();
  // public channels$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public contentTypes: any[] = [];
  public contentTypeMoreControl: FormControl = new FormControl();
  public contentTypes$: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddRegularComponent>,
    private fb: FormBuilder,
    private sm: StorageManagerService,
    private companyService: CompanyService,
    private taskService: TaskService,
    private layoutService: LayoutService,
    private _ngx_adapter: NgxMatDateAdapter<any>,
  ) { super() }

  ngOnInit(): void {

    console.log("AddRegularComponent", this.data)
    this._ngx_adapter.setLocale(this.data.activeLang);

    if (this.data.dublicate) {
      this.form = this.fb.group({
        company_id: this.data.company_id,
        name: [this.data.dublicate.name, Validators.required],
        channel_id: ['', Validators.required],
        content_type_id: ['', Validators.required],
        type: [0, Validators.required],
        apply_from_time: [moment(this.data.dublicate.apply_from_time*1000), Validators.required],
        apply_to_time: [moment(this.data.dublicate.apply_to_time*1000), Validators.required]
      });

      this.attachSubscriptions(
        this.form.get("channel_id").valueChanges.pipe(
          switchMap(chID => {
            return this.companyService.getProfileById(this.data.company_id, chID)
          })
        ).subscribe(resp => {
          console.log('"channel_id" valueChanges', resp)
          if (!!resp && resp[0] && !!resp[0].platform_id) {
            let platform_id = resp[0].platform_id;
            this.contentPage = 1;
            this.contentTypes = [];
            this.contentTypes$.next(this.contentTypes.slice());
            this.getContentTypes(platform_id, this.contentPage);
          }
        })
      )

      this.attachSubscriptions(
        this.contentTypeMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchContentTypes(resp))
      )

      this.getPlatforms();
      // this.getChannels(this.data.company_id, this.page);

    } else {
      if (!this.data.regular) {
        this.form = this.fb.group({
          company_id: this.data.company_id,
          channel_id: ['', Validators.required],
          content_type_id: ['', Validators.required],
          name: ['', Validators.required],
          type: [0, Validators.required],
          apply_from_time: ['', Validators.required],
          apply_to_time: ['', Validators.required]
        });
  
        this.attachSubscriptions(
          this.form.get("channel_id").valueChanges.pipe(
            switchMap(chID => {
              return this.companyService.getProfileById(this.data.company_id, chID)
            })
          ).subscribe(resp => {
            console.log('"channel_id" valueChanges', resp)
            if (!!resp && resp[0] && !!resp[0].platform_id) {
              let platform_id = resp[0].platform_id
              this.contentPage = 1;
              this.contentTypes = [];
              this.contentTypes$.next(this.contentTypes.slice());
              this.getContentTypes(platform_id, this.contentPage);
            }
          })
        )
  
        this.attachSubscriptions(
          this.contentTypeMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchContentTypes(resp))
        )
  
        this.getPlatforms();
        // this.getChannels(this.data.company_id, this.page);
  
      } else {
        this.form = this.fb.group({
          company_id: this.data.company_id,
          name: [this.data.regular.name, Validators.required],
          apply_from_time: [moment(this.data.regular.apply_from_time*1000), Validators.required],
          apply_to_time: [moment(this.data.regular.apply_to_time*1000), Validators.required]
        });
      }
    }

    

    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        if (this.isFormChange) {
          this.layoutService.openBottomSheet(this.dialogRef);
        } else {
          this.close();
        }
      })
    )

  }

  getPlatforms() {
    this.attachSubscriptions(
      this.companyService.getGroupPlatforms().subscribe(resp => {
        this.platforms = resp;
        console.log("this.platforms", this.platforms)
      })
    )
  }

  setOutletKey(e) {
    console.log('setOutletKey', e)
  }

  getProfileById(id) {
    return this.channels.find(x => x.id == id)
  }

  selectChange(e) {
    console.log('selectChange', e)
    if (!!e) {
      if (!this.channels.find(x => x.id == e.id)) {
        this.channels.push(e);
      }
    }
    this.opened = false;
  }

  onSearchContentTypes(resp) {
    if (!this.contentTypes) {
      return;
    }

    if (!resp) {
      this.contentTypes$.next(this.contentTypes.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.contentTypes$.next(
      this.contentTypes.filter(z => (z.name.toLowerCase().indexOf(resp) > -1))
    );
  }

  // getChannels(company_id, page) {
  //   this.attachSubscriptions(
  //     this.companyService.getProfiles(company_id, page).pipe(
  //       tap(el => {
  //         this.pagination = {
  //           'pageCount': el.headers.get('x-pagination-page-count'),
  //           'perPage': el.headers.get('x-pagination-per-page'),
  //           'totalCount': el.headers.get('x-pagination-total-count'),
  //           'currentPage': el.headers.get('x-pagination-current-page'),
  //         }
  //       }),
  //       map(el => el.body)
  //     ).subscribe(resp => {
  //       this.channels.push(...resp);
  //       this.page = this.page + 1;

  //       console.log("getChannels", this.contentPage, this.contentTypes)
  //       if (this.pagination.pageCount >= this.page) {
  //         this.getChannels(this.data.company.id, this.page);
  //       } else {
  //         if (!!this.data.post && !!this.data.post.content_type_id && this.channels.filter(x => x.id == this.data.post.channel_id).length > 0) {
  //           let platform_id = this.channels.find(x => x.id == this.data.post.channel_id).platform_id
  //           this.getContentTypes(platform_id, this.contentPage);
  //         }
  //       }
  //     })
  //   )
  // }

  selectType(type) {
    this.form.patchValue({
      content_type_id: !!type.id ? type.id : type.value
    })
  }

  getContentTypes(platform_id, contentPage) {
    this.attachSubscriptions(
      this.companyService.getContentTypes(platform_id, contentPage).pipe(
        tap(el => {
          this.contentPagination = {
            'pageCount': el.headers.get('x-pagination-page-count'),
            'perPage': el.headers.get('x-pagination-per-page'),
            'totalCount': el.headers.get('x-pagination-total-count'),
            'currentPage': el.headers.get('x-pagination-current-page'),
          }
        }),
        map(el => el.body)
      ).subscribe(resp => {
        this.contentTypes.push(...resp);
        this.contentTypes$.next(this.contentTypes.slice());
        this.contentPage = this.contentPage + 1;
        console.log("getContentTypes", this.contentPage, this.contentTypes)

        if (this.contentPagination.pageCount >= this.contentPage) {
          this.getContentTypes(platform_id, this.contentPage);
        }
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  showUserDate(date:any = false, timestamp:any = false) {
    let timeAndDate;
    if (date) {
      timeAndDate = moment(date).clone()
    } else {
      timeAndDate = moment(timestamp*1000).clone()
    }

    const utcOffset = moment.tz(timeAndDate, this.data.company.timezone).utcOffset();
    
    let utcTimestamp = moment(timeAndDate).format("X")
    
    let difference = (Number(this.timeZone) - Number(utcOffset))*60
    console.log(difference);
    
    let workSpaceTimeZone;
    if (date) {
      workSpaceTimeZone = Number(utcTimestamp) + Number(difference)
    } else {
      workSpaceTimeZone = Number(utcTimestamp) - Number(difference)
    }
    return workSpaceTimeZone
  }

  onSubmit() {
    if (!this.form.valid) {
      this.submited = true;
      this.layoutService.showSnackBar({name: ''}, marker("You need to fill in all required fields"), SnackBarItem)
      return
    }

    this.submited = false;
    this.isSubmit = true;

    if (!!this.form.value.apply_from_time && !!this.form.value.apply_from_time._d) {
      this.form.value.apply_from_time = moment(this.form.value.apply_from_time._d).utc().format("X");
    } else {
      delete this.form.value.apply_from_time;
    }

    if (!!this.form.value.apply_to_time && !!this.form.value.apply_to_time._d) {
      this.form.value.apply_to_time = moment(this.form.value.apply_to_time._d).utc().format("X");
    } else {
      delete this.form.value.apply_to_time;
    }
    let sbmtData = {...this.form.value}

    
    console.log("sbmtData", sbmtData)
    if (this.data.dublicate) {
      delete sbmtData.type;
      sbmtData.is_daily = this.data.dublicate.is_daily;
      sbmtData.is_monthly = this.data.dublicate.is_monthly;
      sbmtData.is_weekly = this.data.dublicate.is_weekly;
      this.attachSubscriptions(
        this.companyService.addRegular(sbmtData).pipe(
          switchMap(output => {
            if (this.data.dublicate.items && this.data.dublicate.items.length) {
              let postData = [];
                    
              this.data.dublicate.items.forEach(item => {
                let x:any = {
                  "path": `/api/content-schedule-item/`,
                  "query": {company_id: this.data.company.id},
                  "method": "POST",
                  "body": {
                    company_id: output.company_id,
                    content_schedule_id: output.id,
                    name:	item.name,
                    time:	item.time,
                    [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
                  }
                }

                if (item.hasOwnProperty('day_number')) {
                  x.body.day_number = item.day_number
                }
                postData.push(x)
              });
              return this.taskService.multiRequest(postData).pipe(map(() => output))
            } else {
              return of(output)
            }
          })
        ).subscribe(resp => {
          this.dialogRef.close({event: "add", data: resp})
        })
      )
    } else {
      if (!this.data.regular) {
        sbmtData[this.types[sbmtData.type]] = 1
    
        delete sbmtData.type;
  
        this.attachSubscriptions(
          this.companyService.addRegular(sbmtData).subscribe(resp => {
            this.dialogRef.close({event: "add", data: resp})
          })
        )
      } else {
        this.attachSubscriptions(
          this.companyService.editRegular(this.data.regular.id, this.form.value).subscribe(resp => {
            this.dialogRef.close({event: "add", data: resp})
          })
        )
      }
    }
  }

  getProfile(id) {
    if (!this.channels) {
      return false;
    }
    return this.channels.find(el => el.id == id)
  }  

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

}
