import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ReplaySubject, forkJoin } from 'rxjs';
import { debounceTime, last, map, switchMap, tap } from 'rxjs/operators';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { BaseClass } from 'src/app/shared/models/base-class';
import { GlobalDataService } from 'src/app/shared/services/common/global-data.service';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { AuthService } from 'src/app/shared/services/rest/auth.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { UsersService } from 'src/app/shared/services/rest/users.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-employee-edit',
  templateUrl: './employee-edit.component.html',
  styleUrls: ['./employee-edit.component.scss']
})
export class EmployeeEditComponent extends BaseClass implements OnInit, OnDestroy {
  public host: any = environment.host;
  public imgRoute: any = '';
  public user: any = {};
  public user_id: number;
  public vEmployees: any;
  public employees: any;
  public form: FormGroup;
  public partnerForm: FormGroup;
  public emplForm: FormGroup;
  public company_id: any;
  public emp_id: any;
  public emplToEmpl: any;
  public statuses: any;
  public disableSelect: boolean = false;
  public isSubmit: boolean = false;
  public isFormChange: boolean = false;
  public isLoad: boolean = false;
  public currencyList: any;
  public numberRegEx = /\d*\.?\d{1,2}/;
  public saveVal = []
  public savePartnerVal = []

  public employeeMoreControl: FormControl = new FormControl();
  public employees$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public partners: any;
  public partnerMoreControl: FormControl = new FormControl();
  public partners$: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<EmployeeEditComponent>,
    private sm: StorageManagerService,
    private layoutService: LayoutService,
    private auth: AuthService,
    private companyService: CompanyService,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private globalDataService: GlobalDataService,
    private taskService: TaskService,
    private usersService: UsersService
  ) { super() }

  ngOnInit(): void {
    console.log('EmployeeEditComponent', this.data);
    this.getCompany();
    this.getUser();

    if (this.data.employee.currency_id && !this.data.company.permissions.includes('owner')) {
      this.disableSelect = true
    }
    
    this.form = this.fb.group({
      company_id: this.data.company_id,
      vuser_id: [this.data.employee.vuser_id, Validators.required],
      currency_id: [this.data.employee.currency_id],
      description: this.data.employee.description,
      company_name: this.data.employee.company_name,
      partner_name : this.data.employee.partner_name,
      is_company_image: this.data.employee.is_company_image,
      is_partner_image: this.data.employee.is_partner_image,
      permissions: this.data.employee.permissions,
      rate_target: [this.data.employee.rate_target, [Validators.required, Validators.pattern(this.numberRegEx)]],
      salary: [this.data.employee.salary, [Validators.required, Validators.pattern(this.numberRegEx)]],
    })

    this.emplForm = this.fb.group({
      company_id: this.data.company_id,
      employee_id: '',
      view_employee_id: [[]],
    })

    this.partnerForm = this.fb.group({
      company_id: this.data.company_id,
      employee_id: '',
      view_partner_company_id: [[]],
    })

    this.attachSubscriptions(
      this.employeeMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchEmoloyees(resp))
    )

    this.attachSubscriptions(
      this.partnerMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchPartners(resp))
    )

    this.attachSubscriptions(
      this.form.valueChanges.subscribe(() => this.isFormChange = true)
    )

    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        if (this.isFormChange) {
          this.layoutService.openBottomSheet(this.dialogRef);
        } else {
          this.close();
        }
      })
    )

    this.getEmployeeStatuses()
    this.getEmployeeToEmployee();
    this.getEmployees();
    this.getPartners()
    this.getVEmployees();
    this.getCurrencyList();
  }

  getPartners() {
    this.attachSubscriptions(
      this.companyService.getPartners({company_id: this.data.company_id}).subscribe(resp => {
        this.partners = resp;
        this.partners$.next(this.partners.slice());
      })
    )
  }

  onSearchPartners(resp) {
    if (!this.partners) {
      return;
    }

    if (!resp) {
      this.partners$.next(this.partners.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.partners$.next(
      this.partners.filter(z => z.partnerCompany.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  getVEmployees() {
    this.attachSubscriptions(
      this.usersService.getUsers(this.data.company_id).subscribe(resp => {
        console.log(resp);
        this.vEmployees = resp;
      })
    )
  }

  onSearchEmoloyees(resp) {
    if (!this.employees) {
      return;
    }

    if (!resp) {
      this.employees$.next(this.employees.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.employees$.next(
      this.employees.filter(z => z.name.toLowerCase().indexOf(resp) > -1)
    );
  }


  toggleSelectChip(chip) {
    let targetId;
    this.isLoad = true;
    if (chip.value.length > this.saveVal.length) {
      chip.value.forEach(x => {
        if (!this.saveVal.includes(x)) {
          targetId = x
        }
      })
      this.attachSubscriptions(
        this.taskService.addEmployeeToEmployee({
          company_id: this.data.company_id,
          employee_id: this.data.employee.id,
          view_employee_id: targetId
        }).subscribe(res => {
          this.getEmployeeToEmployee();
        })
      )
    } else {
      this.saveVal.forEach(x => {
        if (!chip.value.includes(x)) {
          targetId = x
        }
      })

      let id = this.emplToEmpl.find(x => x.view_employee_id == targetId).id
      this.attachSubscriptions(
        this.taskService.deleteEmployeeToEmployee(id).subscribe(res => {
          this.getEmployeeToEmployee();
        })
      )
    }
  }

  toggleChip = (chip: any) => {
    this.isLoad = true;
    let targetId = chip.id;

    if (!!this.emplForm.value.view_employee_id && this.emplForm.value.view_employee_id.includes(targetId)) {
      let id = this.emplToEmpl.find(x => x.view_employee_id == targetId).id
      this.attachSubscriptions(
        this.taskService.deleteEmployeeToEmployee(id).subscribe(res => {
          this.getEmployeeToEmployee();
        })
      )
    } else {
      this.attachSubscriptions(
        this.taskService.addEmployeeToEmployee({
          company_id: this.data.company_id,
          employee_id: this.data.employee.id,
          view_employee_id: targetId
        }).subscribe(res => {
          this.getEmployeeToEmployee();
        })
      )
    }
  }

  togglePartnerSelectChip(chip) {
    let targetId;
    this.isLoad = true;
    if (chip.value.length > this.savePartnerVal.length) {
      chip.value.forEach(x => {
        if (!this.savePartnerVal.includes(x)) {
          targetId = x
        }
      })
      this.attachSubscriptions(
        this.taskService.addEmployeeToEmployee({
          company_id: this.data.company_id,
          employee_id: this.data.employee.id,
          view_partner_company_id: targetId
        }).subscribe(res => {
          this.getEmployeeToEmployee();
        })
      )
    } else {
      this.savePartnerVal.forEach(x => {
        if (!chip.value.includes(x)) {
          targetId = x
        }
      })

      let id = this.emplToEmpl.find(x => x.view_partner_company_id == targetId).id
      this.attachSubscriptions(
        this.taskService.deleteEmployeeToEmployee(id).subscribe(res => {
          this.getEmployeeToEmployee();
        })
      )
    }
  }

  togglePartnerChip = (chip: any) => {
    this.isLoad = true;
    let targetId = chip.partner_company_id;

    if (!!this.partnerForm.value.view_partner_company_id && this.partnerForm.value.view_partner_company_id.includes(targetId)) {
      let id = this.emplToEmpl.find(x => x.view_partner_company_id == targetId).id
      this.attachSubscriptions(
        this.taskService.deleteEmployeeToEmployee(id).subscribe(res => {
          this.getEmployeeToEmployee();
        })
      )
    } else {
      this.attachSubscriptions(
        this.taskService.addEmployeeToEmployee({
          company_id: this.data.company_id,
          employee_id: this.data.employee.id,
          view_partner_company_id: targetId
        }).subscribe(res => {
          this.getEmployeeToEmployee();
        })
      )
    }
  }

  getEmployeeStatuses() {
    this.attachSubscriptions(
      this.taskService.getEmployeeStatuses().subscribe(resp => {
        console.log("getEmployeeStatuses", resp)
        this.statuses = resp;
      })
    )
  }

  getCompany() {
    this.attachSubscriptions(
      this.companyService.getCompany(this.data.company_id).subscribe(resp => {
        this.companyService.company$.next(resp[0]);
      })
    )
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  getCsrf() {
    this.attachSubscriptions(
      this.auth.$userToken.subscribe(resp => {
        this.user_id = resp.user_id;
        this.sm.localStorageSetItem("csrf_param", resp.csrf_param)
        this.sm.localStorageSetItem("csrf_token", resp.csrf_token)
      })
    )
  }

  getUser() {
    this.attachSubscriptions(
      this.auth.$user.subscribe(resp => {
        this.user = resp;
      })
    )
  }

  getCurrencyList() {
    this.attachSubscriptions(
      this.globalDataService.getCurrencies().subscribe(resp => {
        console.log("getCurrencyList", resp)
        this.currencyList = resp.slice();
      })
    )
    // this.attachSubscriptions(
    //   this.companyService.getCurrencyList().subscribe(resp => {
    //     console.log("getCurrencyList", resp);
    //     this.currencyList = resp
    //   })
    // )
  }

  // getEmployees() {
  //   this.attachSubscriptions(
  //     this.taskService.getEmployees(this.data.company_id).subscribe(resp => {
  //       console.log("getEmployees" ,resp)
  //       this.employees = resp.filter(x => x.id != this.data.employee.id);
  //       this.employees$.next(this.employees.slice())
  //     })
  //   )
  // }

  getEmployees() {
    this.attachSubscriptions(
      this.taskService.getEmployeesDyn('1', this.data.company.id, null, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }
  
          return forkJoin(arr.map(x => this.taskService.getEmployeesDyn(x, this.data.company.id, null, '200').pipe(map(u => u.body)))).pipe(
            last(),
            tap(values => {
              let conValues = [].concat(...values).filter(x => x.id != this.data.employee.id)
              this.employees = conValues;
              this.employees$.next(this.employees.slice())
            }),
          )
        }),
      ).subscribe(resp => {
        console.log("getEmployees resp", resp);
        console.log("getEmployees", this.employees);
      })
    )
  }

  getEmployeeToEmployee() {
    this.attachSubscriptions(
      this.taskService.getEmployeeToEmployee(this.data.company_id, this.data.employee.id).subscribe(resp => {
        console.log("getEmployeeToEmployee" ,resp)
        let ids = resp.slice().filter(u => !!u.view_employee_id).map((x) => x.view_employee_id);
        console.log("ids", ids)
        this.emplForm.patchValue({
          view_employee_id: ids
        })

        let pIds = resp.slice().filter(u => !!u.view_partner_company_id).map((x) => x.view_partner_company_id);
        console.log("pIds", pIds)
        this.partnerForm.patchValue({
          view_partner_company_id: pIds
        })
        this.savePartnerVal = pIds;
        this.saveVal = ids;
        this.emplToEmpl = resp;
        this.isLoad = false
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  onSubmit() {
    this.isSubmit = true;
    
    let sbmtData = {...this.form.value}
    if (String(sbmtData['rate_target']).indexOf(",") != -1) {
      sbmtData['rate_target'] = sbmtData['rate_target'].replace(",", ".");
    }
    if (String(sbmtData['salary']).indexOf(",") != -1) {
      sbmtData['salary'] = sbmtData['salary'].replace(",", ".");
    }
    sbmtData.is_company_image = !!sbmtData.is_company_image ? 1 : 0;
    sbmtData.is_partner_image = !!sbmtData.is_partner_image ? 1 : 0;

    this.attachSubscriptions(
      this.taskService.editVuser(this.data.employee.id, sbmtData).subscribe(resp => {
        this.isSubmit = false;
        this.dialogRef.close({event: "update", data: resp});
      }, error => {
        this.isSubmit = false;
        this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
      })
    )
  }

  ngOnDestroy(): void {
    this.clearSubscriptions();
  }
}
