import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';

@Component({
  selector: 'app-approve-job',
  templateUrl: './approve-job.component.html',
  styleUrls: ['./approve-job.component.scss']
})
export class ApproveJobComponent extends BaseClass implements OnInit, OnDestroy {
  public isSubmit: boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ApproveJobComponent>,
    private fb: FormBuilder,
    private taskService: TaskService,
    public layoutService: LayoutService
  ) { super() }

  ngOnInit(): void {
    console.log("ApproveJobComponent", this.data)
  }

  no() {
    this.dialogRef.close({event: "close", data: false});
  }

  yes() {
    this.isSubmit = true;
    this.attachSubscriptions(
      this.taskService.editWork(this.data.work.id, {status_id: 4}, this.data.company.id).subscribe(resp => {
        this.isSubmit = false;
        this.dialogRef.close({event: "yes", data: resp})
      },
      error => {
        this.isSubmit = false;
      })
    )
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
