import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { mimeTypes } from 'mime-wrapper';
import { ReplaySubject, Subject } from 'rxjs';
import { catchError, switchMap, takeUntil, tap } from 'rxjs/operators';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { BaseClass } from 'src/app/shared/models/base-class';
import { FileSizePipe } from 'src/app/shared/pipes/filesize.pipe';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { AuthService } from 'src/app/shared/services/rest/auth.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { FileService } from 'src/app/shared/services/rest/file.service';
import { UploadService } from 'src/app/shared/services/rest/upload.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-company-edit',
  templateUrl: './company-edit.component.html',
  styleUrls: ['./company-edit.component.scss']
})
export class CompanyEditComponent extends BaseClass implements OnInit, OnDestroy {
  public host: any = environment.host;
  public imgRoute: any = '';
  public user: any = {};
  public user_id: number;
  public company_id: any;
  public company: any;
  public form: FormGroup = this.fb.group({
    name: ['', Validators.required],
    timezone: '',
    timezone_offset: ''
  });
  public uploadLimit: boolean = false;
  public timezones: any;
  public timezoneFilterCtrl: FormControl = new FormControl();
  protected _onDestroy = new Subject<void>();
    /** list of bank groups filtered by search keyword for option groups */
  public filteredTimezonesGroups: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(
    private sm: StorageManagerService,
    private auth: AuthService,
    private companyService: CompanyService,
    private router: Router,
    private fb: FormBuilder,
    private layoutService: LayoutService,
    private uploads: UploadService,
    private fileService: FileService,
  ) { super() }

  ngOnInit(): void {
    window.scroll(0,0);
    this.getCompany();
    this.getImgRoute();
    this.getCsrf();
    this.getUser();
    this.getTimeZones();
    this.form.get("timezone").valueChanges.subscribe(x => {
      this.form.patchValue({
        timezone_offset: x == "no" ? '' : this.getOffsetData(x)
      })
    })

    

    this.timezoneFilterCtrl.valueChanges
    .pipe(takeUntil(this._onDestroy))
    .subscribe(() => {
      this.filterTimezoneGroups();
    });
  }
  

  uploadFile(event, company) {
    if (this.uploadLimit) {
      this.layoutService.showSnackBar({name: ''}, marker("This company has exceeded the file upload limit."), SnackBarItem)
      return
    }
    if (event.target.files.length > 0) {
      const file = (event.target as HTMLInputElement).files[0];
      console.log(file);
      company.fileVal = file;
      this.putFile(company);
    } else {
      company.fileVal = null
    }
  }

  putFile(company) {
    this.attachSubscriptions(
      this.companyService.editCompany(company.id, {
          "upload_avatar_filename": company.fileVal.name,
          "upload_avatar_content_type": company.fileVal.type ?  company.fileVal.type : mimeTypes.getType(company.fileVal.name),
          "upload_avatar_filesize": company.fileVal.size
      }).subscribe(resp => {
        console.log("putFile", resp)
        company.upload_url = resp.upload_avatar_url;
        this.fileService.files$.next({
          place: "edit_company",
          url: window.location.href,
          data: company,
          target: company,
          company_id: company.id,
          company: company,
          user: this.user
        })
      },
      error => {
        let msg = error;
        if (msg.match(/(\d+)/g).length > 0 && +msg.match(/(\d+)/g)[0] > 10000) {
          msg = msg.replace(msg.match(/(\d+)/g)[0], new FileSizePipe().transform(+msg.match(/(\d+)/g)[0]))
          this.layoutService.showSnackBar({name: ''}, msg, SnackBarItem)
        } else {
          this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
        }
      })
    )
  }

  protected filterTimezoneGroups() {
    if (!this.timezones) {
      return;
    }
    // get the search keyword
    let search = this.timezoneFilterCtrl.value;
    const timezonesCopy = this.copyTimezoneGroups(this.timezones);
    if (!search) {
      this.filteredTimezonesGroups.next(timezonesCopy);
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredTimezonesGroups.next(
      timezonesCopy.filter(timezoneGroup => {
        const showBankGroup = timezoneGroup.value.toLowerCase().indexOf(search) > -1;
        if (!showBankGroup) {
          timezoneGroup.names = timezoneGroup.names.filter(name => name.toLowerCase().indexOf(search) > -1);
        }
        return timezoneGroup.names.length > 0;
      })
    );
  }

  protected copyTimezoneGroups(timezoneGroups: any) {
    const timezonesCopy = [];
    timezoneGroups.forEach(bankGroup => {
      timezonesCopy.push({
        value: bankGroup.value,
        names: bankGroup.names.slice()
      });
    });
    return timezonesCopy;
  }

  getTimeZone(val) {
    let o = Math.abs(val);
    return (val >= 0 ? "+" : "-") + ("00" + Math.floor(o / 3600)).slice(-2) + ":00";
  }
  
  getOffsetData(name) {
    if (!this.timezones) {
      return "0"
    }
    return this.timezones.find(x => x.names.filter(y => y == name).length > 0).value
  }

  getTimeZones() {
    this.attachSubscriptions(
      this.companyService.getTimeZones().subscribe(resp => {
        let val = [];
        Object.keys(resp).forEach(key => {
          val.push({
            value: key,
            names: resp[key]
          })
        })
        this.timezones = val;
        this.filteredTimezonesGroups.next(this.copyTimezoneGroups(this.timezones));
        console.log("getTimeZones",this.timezones);
      })
    )
  }
  
  getCompany() {
    this.attachSubscriptions(
      this.companyService.getCompany(this.router.url.split("/")[2]).pipe(
        tap(val => {
          this.companyService.company$.next(val[0]);
          this.company = val[0]
          this.form.patchValue({
            name: val[0].name,
            timezone: val[0].timezone,
            timezone_offset: val[0].timezone_offset,
          })
          console.log(this.form.value);
        }),
        switchMap(x => this.uploads.getUploadLimit().pipe(
          tap(z => {
            if (z || this.company.filesize >= this.company.filesize_limit) {
              this.uploadLimit = true;
            }
          })
        ))
      ).subscribe(resp => {
        console.log(resp);
      })
    )
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  onImgError(event){
    event.target.src = this.imgRoute+'/assets/img/image_black_48dp.svg'
  }

  getCsrf() {
    this.attachSubscriptions(
      this.auth.$userToken.subscribe(resp => {
        this.user_id = resp.user_id;
        this.sm.localStorageSetItem("csrf_param", resp.csrf_param)
        this.sm.localStorageSetItem("csrf_token", resp.csrf_token)
      })
    )
  }

  getUser() {
    this.attachSubscriptions(
      this.auth.$user.subscribe(resp => {
        this.user = resp;
      })
    )
  }
  

  onSubmit() {
    let sbmtData = {...this.form.value}
    if (sbmtData.timezone == 'no') {
      sbmtData.timezone = ''
    } else {
      delete sbmtData.timezone_offset
    }
    
    this.attachSubscriptions(
      this.companyService.editCompany(this.router.url.split("/")[2], sbmtData).subscribe(resp => {
        console.log(resp);
        this.router.navigate(['/main'])
      })
    )
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
    this.clearSubscriptions();
  }

}
