<h2 mat-dialog-title class="dialog_title">
    <span>
        {{ "Plan this post" | translate }}
    </span>
    <button mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h2>
<mat-dialog-content class="mat-typography">
    <div class="container">
        <div class="regular_times_wrp" *ngIf="regularDateTimes && regularDateTimes.length">
            <div class="reg_title">
                {{ "You have a scheduled post for this time" | translate }}:
            </div>

            <div
            #tooltip="matTooltip" 
            matTooltip="{{ (!item.is_con ? (getStatusById(item?.content_status_id)?.name + ' ') : '') + item.name }}" 
            [matTooltipPosition]="'above'" 
            [class]="'regular_time ' + item?.class" 
            [ngClass]="{
                'active': selectedPlan && item?.id == selectedPlan?.id, 
                'has_photo': item.file && item.file.original
            }" 
            *ngFor="let item of regularDateTimes" 
            (click)="acceptRegularTime(item)">
                <div class="photo_img" *ngIf="item.file && item.file.original" [ngStyle]="{'background': 'url(' + host + item.file.original + '?company_id=' + data?.company?.id + ') no-repeat center / cover'}"></div>
                {{item.content_published_at*1000 | date:'E, MMM dd':'':activeLang}},&nbsp;
                <span>
                    {{item.content_published_at*1000 | date:'hh:mm a':'':activeLang}}
                </span>
            </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button (click)="close()">
        {{ "Close" | translate }}
    </button>
    <button class="ml-1" (click)="onSubmit()" mat-raised-button cdkFocusInitial color="primary" [disabled]="!selectedPlan">
        {{ "Save" | translate }}
    </button>
</mat-dialog-actions>
