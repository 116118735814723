import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReplaySubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { AuthService } from 'src/app/shared/services/rest/auth.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dropbox',
  templateUrl: './dropbox.component.html',
  styleUrls: ['./dropbox.component.scss']
})
export class DropboxComponent extends BaseClass implements OnInit, OnDestroy {
  public host: any = environment.host;
  public imgRoute: any = '';
  public user: any = {};
  public user_id: number;
  public form: FormGroup;
  public isSubmit: boolean = false;
  public isFormChange: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DropboxComponent>,
    private layoutService: LayoutService,
    private sm: StorageManagerService,
    private auth: AuthService,
    private companyService: CompanyService,
    private fb: FormBuilder,
  ) { super() }

  ngOnInit(): void {
    console.log("DropboxComponent", this.data)
    this.getCompany();
    this.getImgRoute();
    this.getCsrf();
    this.getUser();

    if (this.data.sftp) {
      this.form = this.fb.group({
        company_id: this.data.company_id,
        name: [this.data.sftp.name, Validators.required],
        host: [this.data.sftp.host, Validators.required],
        username: [this.data.sftp.username, Validators.required],
        port: [this.data.sftp.port, Validators.required],
        reports_directory: [this.data.sftp.reports_directory, Validators.required],
      })
    } else {
      this.form = this.fb.group({
        company_id: this.data.company_id,
        name: ['', Validators.required],
        host: ['', Validators.required],
        username: ['', Validators.required],
        port: ['', Validators.required],
        reports_directory: ['/', Validators.required],
        public_key: '',
        private_key: '',
        is_generate_keys: 1
      })
    }

    this.attachSubscriptions(
      this.form.valueChanges.subscribe(() => this.isFormChange = true)
    )

    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        if (this.isFormChange) {
          this.layoutService.openBottomSheet(this.dialogRef);
        } else {
          this.close();
        }
      })
    )
  }

  getCompany() {
    this.attachSubscriptions(
      this.companyService.getCompany(this.data.company_id).subscribe(resp => {
        this.companyService.company$.next(resp[0]);
      })
    )
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  getCsrf() {
    this.attachSubscriptions(
      this.auth.$userToken.subscribe(resp => {
        this.user_id = resp.user_id;
        this.sm.localStorageSetItem("csrf_param", resp.csrf_param)
        this.sm.localStorageSetItem("csrf_token", resp.csrf_token)
      })
    )
  }

  getUser() {
    this.attachSubscriptions(
      this.auth.$user.subscribe(resp => {
        this.user = resp;
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  onSubmit() {
    this.isSubmit = true;
    let formData = {...this.form.value}

    
    if (this.data.sftp) {
      this.attachSubscriptions(
        this.companyService.editSftp(this.data.sftp.id, formData).subscribe(resp => {
          this.isSubmit = false;
          this.dialogRef.close({event: "update", data: resp});
        }, error => {
          this.isSubmit = false;
          this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
        })
      )
    } else {
      if (formData.is_generate_keys == 1) {
        delete formData.public_key;
        delete formData.private_key;
      } else {
        if (formData.public_key === '' || formData.private_key === '') {
          this.layoutService.showSnackBar({name: ''}, "The private key and public key must not be empty. Please complete these fields.", SnackBarItem)
          this.isSubmit = false;
          return
        }
      }
  
      this.attachSubscriptions(
        this.companyService.addSftp(formData).subscribe(resp => {
          this.isSubmit = false;
          this.dialogRef.close({event: "update", data: resp});
        }, error => {
          this.isSubmit = false;
          this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
        })
      )
    }
  }
  
  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
