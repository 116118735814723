import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReplaySubject } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { RootService } from 'src/app/shared/services/rest/root.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';

@Component({
  selector: 'app-add-edit-transaction',
  templateUrl: './add-edit-transaction.component.html',
  styleUrls: ['./add-edit-transaction.component.scss']
})
export class AddEditTransactionComponent extends BaseClass implements OnInit, OnDestroy {
  public form: FormGroup;
  public isFormChange: boolean = false;
  public companyes: any;
  public compMoreControl: FormControl = new FormControl();
  public companyes$: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private companyService: CompanyService,
    private rootService: RootService,
    public dialogRef: MatDialogRef<AddEditTransactionComponent>,
    public layoutService: LayoutService
  ) { super() }

  ngOnInit(): void {



    if (this.data.transaction) {
      if (this.data.transaction.is_done) {
        this.form = this.fb.group({
          message: this.data.transaction.message,
          message_root: this.data.transaction.message_root,
          type: this.data.transaction.is_expense ? 'is_expense' : (this.data.transaction.is_refill ? 'is_refill' : 'is_adjustment'),
          is_hide: this.data.transaction.is_hide,
        })
      } else {
        this.form = this.fb.group({
          balance_movement: this.data.transaction.balance_movement,
          message: this.data.transaction.message,
          message_root: this.data.transaction.message_root,
          type: this.data.transaction.is_expense ? 'is_expense' : (this.data.transaction.is_refill ? 'is_refill' : 'is_adjustment'),
          state: this.data.transaction.is_new ? 'is_new' : 'is_done',
          is_hide: this.data.transaction.is_hide,
        })
      }
    } else {
      this.form = this.fb.group({
        company_id: [this.data.company_id || 0, Validators.required],
        balance_movement: 0,
        message: '',
        message_root: '',
        type: 'is_refill',
        state: 'is_done',
        is_hide: 0
      })
      
      this.getCompanyes();

      this.attachSubscriptions(
        this.compMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchWorkspaces(resp))
      )
    }

    this.attachSubscriptions(
      this.form.valueChanges.subscribe(() => this.isFormChange = true)
    )

    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        if (this.isFormChange) {
          this.layoutService.openBottomSheet(this.dialogRef);
        } else {
          this.close();
        }
      })
    )
  }

  onSearchWorkspaces(resp) {
    if (!this.companyes) {
      return;
    }

    if (!resp) {
      this.companyes$.next(this.companyes.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.companyes$.next(
      this.companyes.filter(z => z.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  getCompanyes() {
    this.attachSubscriptions(
      this.rootService.getRootCompany(1).pipe(
        map(k => k.body)
      ).subscribe(resp => {
        this.companyes = resp;
        this.companyes$.next(this.companyes.slice());

        console.log("this.companyes", this.companyes)
      })
    )
  }

  toggleChip = (chip: any) => {
    this.form.patchValue({
      company_id: !!chip.id ? chip.id : chip.value
    })
  }

  close() {
    this.dialogRef.close({event: "close", data: false});
  }

  submitForm() {
    let sendData:any = {};
    Object.keys(this.form.value).forEach(element => {
      if (element == 'type' || element == 'state') {
        sendData[this.form.value[element]] = 1;
      } else {
        sendData[element] = this.form.value[element]
      }
    })

    if (this.data.transaction) {
      this.attachSubscriptions(
        this.rootService.editRootTransaction(this.data.transaction.id, sendData).subscribe(resp => {
          this.dialogRef.close({event: "close", data: resp});
        }, error => {
          console.log('error', error)
          this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
        })
      )
    } else {
      this.attachSubscriptions(
        this.rootService.addRootTransaction(sendData).subscribe(resp => {
          this.dialogRef.close({event: "close", data: resp});
        }, error => {
          console.log('error', error)
          this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
        })
      )
    }
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
