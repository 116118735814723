<div mat-dialog-title class="mobile_interface_title">
    <div class="mobile_interface_title_in" style="overflow: hidden;">
        <div class="tab_title" (click)="back()">
            <div class="d-f-c">
                <div class="icon_back">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7 12L6.11612 11.1161C5.62796 11.6043 5.62796 12.3957 6.11612 12.8839L7 12ZM16.8839 3.88388C17.372 3.39573 17.372 2.60427 16.8839 2.11612C16.3957 1.62796 15.6043 1.62796 15.1161 2.11612L16.8839 3.88388ZM15.1161 21.8839C15.6043 22.372 16.3957 22.372 16.8839 21.8839C17.372 21.3957 17.372 20.6043 16.8839 20.1161L15.1161 21.8839ZM7.88388 12.8839L16.8839 3.88388L15.1161 2.11612L6.11612 11.1161L7.88388 12.8839ZM6.11612 12.8839L15.1161 21.8839L16.8839 20.1161L7.88388 11.1161L6.11612 12.8839Z" fill="#232323"/>
                    </svg>
                </div>
                <span>
                    {{ "Edit Job" | translate }}
                </span>
                <span class="small_title with_i">7 / 7 — {{ "TAP ELEMENT TO EDIT" | translate }}</span>
            </div>
        </div>
    </div>
</div>
<mat-dialog-content class="mobile_interface_content">
    <div class="mobile_scrollable_int_big" style="background: #fff;">
        <div class="mob_edit_wrp">
            <div class="edit_line" (click)="goTo('name')">
                <div class="edit_label" [ngClass]="{'no_val': !data.work.name}">
                    {{ "Title" | translate }}
                </div>
                <div class="edit_value text_one_line" *ngIf="!!data.work.name">
                    {{data.work.name}}
                </div>
            </div>
            <div class="edit_line" (click)="goTo('comment')">
                <div class="edit_label" [ngClass]="{'no_val': !data.work.comment}">
                    {{ "Brief description" | translate }}
                </div>
                <div class="edit_value text_one_line" *ngIf="!!data.work.comment">
                    {{data.work.comment}}
                </div>
            </div>
            <div class="edit_line" (click)="goTo('private_comment')">
                <div class="edit_label no_m" [ngClass]="{'no_val': !data.work.private_comment}">
                    {{ "Hidden notes" | translate }}
                </div>
                <div class="edit_value text_one_line" *ngIf="!!data.work.private_comment">
                    {{data.work.private_comment}}
                </div>
                <div class="line_info">{{ "Can see managers and above" | translate }}</div>
            </div>
            <div class="edit_items">
                <div class="edit_item" (click)="goTo('priority')">
                    <div class="item_label">{{ "Priority" | translate }}</div>

                    <div class="job_priority" [ngClass]="{
                        'Urgent': data.work.priority == '1000',
                        'High': data.work.priority == '900',
                        'Middle': data.work.priority == '800',
                        'Low': data.work.priority == '700',
                        'No': data.work.priority == '0'
                    }">
                        <span>
                            {{ getPriority(data.work.priority).name | translate }}
                        </span>
                    </div>

                </div>
                <div class="edit_item" (click)="goTo('type')">
                    <div class="item_label">{{ "Job Type" | translate }}</div>

                    <div class="job_type text_one_line">
                        {{ data?.work?.operation?.name}}
                    </div>
                </div>
            </div>
            <div class="edit_items">
                <div class="edit_item">
                    <div class="item_label" [ngClass]="{'no_val': !data?.work?.automationScenarios?.length}">{{ "Scenario" | translate }}</div>

                    <div class="scenarios text_2_line" *ngIf="data?.work?.automationScenarios.length">
                        <span *ngFor="let item of data?.work?.automationScenarios">
                            {{ item.template.name }}
                        </span>
                    </div>

                </div>
                <div class="edit_item">
                    <div class="item_label" [ngClass]="{'no_val': !summary.length}">{{ "Price" | translate }}</div>

                    <div class="prices" *ngIf="!!summary.length">
                        <ng-container *ngFor="let emplSumm of summary">
                            <div class="val">
                                <span class="price_numb">
                                    {{ emplSumm?.summary | int }}
                                </span>
                                <span class="dot" *ngIf="+emplSumm?.summary > +(emplSumm?.summary | int)">
                                    {{ ((+emplSumm?.summary - +(emplSumm?.summary | int)) | reportsNumber).toString()|slice:1 }}
                                </span>
                                <!-- <span class="dot" *ngIf="+emplSumm?.summary == +(emplSumm?.summary | int)">
                                    {{ '.00' }}
                                </span> -->
                                <span class="unit">
                                    {{emplSumm?.iso_name}}
                                </span>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="edit_items">
                <div class="edit_item" (click)="goTo('completed_at')">
                    <div class="item_label" [ngClass]="{'no_val': !data?.work?.completed_at}">{{ "Date Completed" | translate }}</div>

                    <div class="completed_date" *ngIf="data?.work?.completed_at">
                        <ng-container *ngIf="data?.work?.completed_at > today; else completedAtTemplMobA">
                            {{ (data?.work?.completed_at*1000 | date:'hh:mm a':'':data.activeLang) }}
                        </ng-container>
                        <ng-template #completedAtTemplMobA>
                            {{ (data?.work?.completed_at*1000 | date:'MMM dd, Y':'':data.activeLang | yearPipe).replaceAll('.', '') }}
                        </ng-template>
                    </div>
                </div>
                <div class="edit_item" (click)="goTo('status')"> 
                    <div class="item_label">{{ "Status" | translate }}</div>

                    <div class="inside_job" [ngClass]="{
                        'first': data?.work?.status_id == '1',
                        'second': data?.work?.status_id == '2',
                        'third': data?.work?.status_id == '3',
                        'fours': data?.work?.status_id == '4',
                        'fives': data?.work?.status_id == '5',
                        'sixs': data?.work?.status_id == '6',
                        'nine': data?.work?.status_id == '97',
                        'seven': data?.work?.status_id == '98',
                        'eight': data?.work?.status_id == '99'}">
                        <span>{{ (getStatus(data?.work?.status_id)?.name | translate).length > 15 ? ((getStatus(data?.work?.status_id)?.name | translate)|slice:0:12) + "..." : (getStatus(data?.work?.status_id)?.name | translate) }}</span>  
                    </div>
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>
