<h2 mat-dialog-title class="dialog_title">
    <span (click)="log()">
        {{ data.values ? "Edit Table" : "Table" | translate }}
    </span>
</h2>
<mat-dialog-content class="mat-typography" dirEnter (dirSubmit)="!source ? null : submitForm()">
    <div class="container form_wrapper" style="padding: 20px 0;">
        <mat-form-field appearance="standard" class="full_width_form">
            <mat-label>{{ "Choose a Source" | translate }}</mat-label>
            <mat-select [(ngModel)]="source" required>
                <mat-option *ngFor="let source of sources" [value]="source.value">
                    {{ source.name | translate }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <div class="table_settings_out" *ngIf="source">
            <table class="table_settings">
                <thead>
                    <tr>
                        <td *ngFor="let col of columns; let i = index;"
                        [ngStyle]="{
                            'font-size': col.font_size+'px',
                            'padding-left': col.padding_left+'px',
                            'padding-right': col.padding_right+'px',
                            'padding-top': col.padding_top+'px',
                            'padding-bottom': col.padding_bottom+'px'
                        }">
                            <input type="text" [(ngModel)]="col.head_name" placeholder="{{'Column Title'|translate}}">
                            <div class="btns">
                                <mat-icon *ngIf="columns.length > 1" (click)="deleteColumn(i)" class="btn_head" style="color: red;">delete</mat-icon>
                                <mat-icon (click)="editColumn(col)" class="btn_head" style="color: blue;">edit</mat-icon>
                                <mat-icon (click)="addColumn(i)" class="btn_head" style="color: green;">add_circle</mat-icon>
                            </div>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td *ngFor="let col of columns" 
                        [ngStyle]="{
                            'font-size': col.font_size+'px',
                            'padding-left': col.padding_left+'px',
                            'padding-right': col.padding_right+'px',
                            'padding-top': col.padding_top+'px',
                            'padding-bottom': col.padding_bottom+'px'
                        }">
                            <div class="cut_value">
                                <div (click)="columnVar.open()">
                                    {{ (col.row_var ? col.row_var : "Column Variable") | translate }}
                                </div>
                                <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                                    <mat-select disableOptionCentering #columnVar [(ngModel)]="col.row_var">
                                        <mat-option [value]="item" *ngFor="let item of variables[source].vars" [ngClass]="{'cst_active_select_option': col.row_var == item}">
                                            {{ item | translate }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>    
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button (click)="close()">
        {{ "Cancel" | translate }}
    </button>
    <button class="ml-1 d-f-btn" (click)="submitForm()" mat-raised-button cdkFocusInitial color="primary" [disabled]="!source">
        {{ "Save" | translate }}
    </button>
</mat-dialog-actions>
