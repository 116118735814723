<div class="cases_page tasks_page" *ngIf="!is_mobile">
    <div class="container">
      <div class="filter_out">  
        <div class="filter_values">
          <mat-chip-list class="chips_wrp without_ordered start_page_chips">

            <!-- <mat-chip (removed)="removeValue('q')" *ngIf="filter.get('q').value">
                {{filter.get('q').value}}
                <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
            </mat-chip>
             -->

            <mat-chip (removed)="removeValue('task_operation_operation_id')" *ngIf="filter.get('task_operation_operation_id').value">
              {{ getColumnName(filter.get('task_operation_operation_id').value) }}
              <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
            </mat-chip>

            <mat-chip (removed)="removeValue('id')" *ngIf="filter?.get('id')?.value">
              {{ "Project ID" | translate }}: {{ filter?.get('id')?.value }}
              <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
            </mat-chip>

            <mat-chip (removed)="removeValue('task_id')" *ngIf="filter?.get('task_id')?.value">
              {{ "Card ID" | translate }}: {{ filter?.get('task_id')?.value }}
              <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
            </mat-chip>

            <mat-chip (removed)="removeValue('task_operation_id')" *ngIf="filter?.get('task_operation_id')?.value">
              {{ "Job ID" | translate }}: {{ filter?.get('task_operation_id')?.value }}
              <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
            </mat-chip>

            <mat-chip class="user_chip" (removed)="removeValue('operation_partner_company_id')" *ngIf="filter.get('partner_company_id').value">
              {{ "Partner" | translate }}:
              <app-user [class]="'user_div ml-1'" [companyEmployee]="getPartner(filter.get('partner_company_id').value)?.partnerCompany" [imgRoute]="imgRoute" [host]="host" [company]="company"></app-user>
              <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
            </mat-chip>
            
            <mat-chip (removed)="removeValue('discussion_id')" *ngIf="filter?.get('discussion_id')?.value">
              {{ "Chat ID" | translate }}: {{ filter?.get('discussion_id')?.value }}
              <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
            </mat-chip>

            <ng-container *ngIf="filter.get('group_id').value.length">
              <span class="chip_out_label">{{ "Group" | translate }}</span>
              <mat-chip *ngFor="let id of filter.get('group_id').value" (removed)="removeValueFromMultiple('group_id', id)">
                <ng-container *ngIf="id === 0; else elseTemplateNoGroup">
                  {{ "Goup not set" | translate }}
                </ng-container>
                <ng-template #elseTemplateNoGroup>
                  {{getGroup(id) ? getGroup(id)?.name : getPartnerGroup(id)?.name}}
                </ng-template>
                <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
              </mat-chip>
            </ng-container>
           
            <mat-icon *ngIf="filter.get('id').value || filter.get('partner_company_id').value || filter.get('task_id').value || filter.get('task_operation_id').value || filter.get('discussion_id').value || filter.get('group_id').value.length" class="remove_all_chips_btn" (click)="removeValue('all')">cancel</mat-icon>
          </mat-chip-list>
  
          <div class="stats_wrp ml-1">
            <div class="tasks_counters">
              
              <div class="server_btn mr-1" *ngIf="!!user?.is_root && !!filter.value.is_processing" (click)="openServersTable()">
                <span>
                  {{"Servers Table" | translate}}
                </span>
              </div>

              <div class="server_btn blue mr-1" *ngIf="canSave()" (click)="saveBookmark()">
                <span>
                  {{"Save" | translate}}
                </span>
              </div>

              <form [formGroup]="filter" class="preset_form" *ngIf="bookmarks && bookmarks.length > 0">
                <div class="simple_filter_snippet" (click)="presetFilter.open()">
                  <ng-container *ngIf="filter.get('preset_id').value; else elseTemplatePreset">
                    <span class="name">
                      {{ getBookmarkById(filter.get('preset_id').value)?.name }}
                    </span>
                  </ng-container>
                  <ng-template #elseTemplatePreset>
                    <span class="name">
                      {{ "Preset" | translate }}
                    </span>
                  </ng-template>
                  

                  <svg class="ml-5" style="width: 10px; height: 10px;" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_2474_38987)">
                    <path d="M7.91699 3.33325L2.08366 3.33325L5.00033 7.91658L7.91699 3.33325Z" fill="black"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_2474_38987">
                    <rect width="10" height="10" fill="white" transform="translate(10) rotate(90)"/>
                    </clipPath>
                    </defs>
                  </svg>
                </div>

                <mat-form-field appearance="legacy" class="filter_form preset_filter">
                  <mat-label>{{ "Preset" | translate }}</mat-label>
                  <mat-select #presetFilter [disableOptionCentering]="true" selectDir formControlName="preset_id" (selectionChange)="bookmarksChange($event)">
                      <mat-option *ngIf="bookmarks.length > 7">
                        <ngx-mat-select-search 
                            placeholderLabel="{{ 'Search' | translate }}" 
                            noEntriesFoundLabel="{{ 'no matching presets found' | translate }}"
                            [formControl]="bookmarksControl"></ngx-mat-select-search>
                      </mat-option>
                      <mat-option *ngFor="let bookmark of bookmarks$ | async" [value]="bookmark.id">
                        {{ bookmark.name | translate }}
                      </mat-option>
                  </mat-select>
                </mat-form-field>
              </form>

              <div class="counter_block" *ngIf="projectsPagination && projectsPagination['totalCount']">
                <div class="counter_top">{{ "Projects" | translate }}</div>
                <div class="counter_bottom">{{projectsPagination['totalCount']}}</div>
                <!-- \{{chats.length}} -->
              </div>
              <button type="button" mat-icon-button (click)="refreshProjects()" #tooltip="matTooltip" matTooltip="{{ 'Refresh results on the page' | translate }}" [matTooltipPosition]="'above'">
                <mat-icon>refresh</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="lists_wrp">
        <div class="lists_left">
          <div class="check_wrp">
            <!-- [checked]="showAddOptions.value" -->
            <mat-checkbox class="select_all_checkbox" *ngIf="user?.is_root"
              [formControl]="showAddOptions">
              <span class="check_sm_label">{{"Extra info" | translate }}</span>
            </mat-checkbox>
          </div>
          <!-- <div class="job_status_wrp">
            <ng-container *ngFor="let stat of statusesForFilter">
              <button *ngIf="stat.hasChats" mat-button (click)='changeActiveStatus(stat.id, $event)' [ngClass]="{'active_status': activeStatus.includes(stat.id)}">{{ stat.name | translate }}</button>
            </ng-container>
          </div> -->
          <!-- style="margin-left: 25px;"  -->
          <div class="job_status_wrp" [ngClass]="{'ml-1': user?.is_root,'active': filter.get('is_draft').value == 0 && filter.get('is_to_process').value == 0 && filter.get('is_processing').value == 0 && filter.get('is_reset').value == 0 && filter.get('is_error').value == 0 && filter.get('is_ready').value == 0}">
            <button mat-button (click)='changeActiveStatus("all")' [ngClass]="{'active_status': filter.get('is_draft').value == 0 && filter.get('is_to_process').value == 0 && filter.get('is_processing').value == 0 && filter.get('is_reset').value == 0 && filter.get('is_error').value == 0 && filter.get('is_ready').value == 0}">{{ "All" | translate }}</button>
          </div>
          <div class="job_status_wrp" style="margin-left: 25px;" [ngClass]="{'active': filter.get('is_draft').value != 0 || filter.get('is_to_process').value != 0 || filter.get('is_processing').value != 0 || filter.get('is_reset').value != 0 || filter.get('is_error').value != 0 || filter.get('is_ready').value != 0}">
            <button mat-button (click)='changeActiveStatus("is_draft")' [ngClass]="{'active_status': filter.get('is_draft').value != 0}">{{ "Draft" | translate }}</button>
            <button mat-button (click)='changeActiveStatus("is_to_process")' [ngClass]="{'active_status': filter.get('is_to_process').value != 0}">{{ "To Process" | translate }}</button>
            <button mat-button (click)='changeActiveStatus("is_processing")' [ngClass]="{'active_status': filter.get('is_processing').value != 0}">{{ "Processing" | translate }}</button>
            <button mat-button (click)='changeActiveStatus("is_reset")' [ngClass]="{'active_status': filter.get('is_reset').value != 0}">{{ "Reset" | translate }}</button>
            <button mat-button (click)='changeActiveStatus("is_error")' [ngClass]="{'active_status': filter.get('is_error').value != 0}">{{ "Error" | translate }}</button>
            <button mat-button (click)='changeActiveStatus("is_ready")' [ngClass]="{'active_status': filter.get('is_ready').value != 0}">{{ "Ready" | translate }}</button>
          </div>
        </div>
  
        <div class="d-f-c">
          <!-- <div class="task_collections_wrp" style="background: transparent;" *ngIf="chatCollection?.selected?.length && (company?.permissions?.includes('owner') || company?.permissions.includes('admin') || company?.permissions?.includes('manager'))">
            <div class="d-f-c">
              <span>{{ "Actions with" | translate }}</span>
              <span>{{ chatCollection?.selected?.length }}</span>
              <mat-icon style="transform: translateY(1px); color: #FF0000; margin-left: 5px; font-size: 12px; line-height: 12px; width: 12px; height: 12px;">chat_bubble</mat-icon>
            </div>
        
            <div class="collections_btns">
              <button class="mint_btn ml-1" type="button" (click)="deleteChats()">
                  {{ "Delete chats" | translate }}
              </button>
              <button class="mint_btn ml-1" type="button" (click)="multiEditExecutors()">
                  {{ "Edit executors" | translate }}
              </button>
              <button class="mint_btn ml-1" type="button" (click)="multiEditJobsType()">
                  {{ "Edit jobs type" | translate }}
              </button>
            </div>
          </div> -->

          <button type="button" mat-icon-button (click)="openSettings()" #tooltip="matTooltip" matTooltip="{{ 'Page layout and content settings' | translate }}" [matTooltipPosition]="'above'">
            <mat-icon>settings</mat-icon>
          </button>

          <button type="button" mat-icon-button [matMenuTriggerFor]="sortBtn" class="mobActiveFilter" #tooltip="matTooltip" matTooltip="{{ 'Sort' | translate }}" [matTooltipPosition]="'above'">
            <mat-icon>sort</mat-icon>
          </button>
        </div>

        <mat-menu #sortBtn="matMenu" class="sort_tablet" xPosition='before' overlayPanelClass='sortTAB'>
          <div class="mat-menu-title">{{"Sort By" | translate}}:</div>
          <mat-divider></mat-divider>
          <button mat-menu-item (click)="sort(filter.get('order_by').value == 'id_desc' ? 'id' : 'id_desc')" [ngClass]="{'active_status': filter.get('order_by').value == 'id_desc' || filter.get('order_by').value == 'id'}">
              {{  "ID" | translate }}
              <div class="d-f-c ml-1">
                  <mat-icon (click)="$event.preventDefault(); $event.stopPropagation(); sort('id_desc')" [ngClass]="{'active': filter.get('order_by').value == 'id_desc'}" style="margin-right: 0 !important;">arrow_drop_down</mat-icon>
                  <mat-icon (click)="$event.preventDefault(); $event.stopPropagation(); sort('id')" [ngClass]="{'active': filter.get('order_by').value == 'id'}" style="margin-right: 0 !important;">arrow_drop_up</mat-icon>
              </div>
          </button>
          <button mat-menu-item (click)="sort(filter.get('order_by').value == 'created_at_desc' ? 'created_at' : 'created_at_desc')" [ngClass]="{'active_status': filter.get('order_by').value == 'created_at_desc' || filter.get('order_by').value == 'created_at'}">
            {{ "created_at" | translate }}
            <div class="d-f-c ml-1">
                <mat-icon (click)="$event.preventDefault(); $event.stopPropagation(); sort('created_at_desc')" [ngClass]="{'active': filter.get('order_by').value == 'created_at_desc'}" style="margin-right: 0 !important;">arrow_drop_down</mat-icon>
                <mat-icon (click)="$event.preventDefault(); $event.stopPropagation(); sort('created_at')" [ngClass]="{'active': filter.get('order_by').value == 'created_at'}" style="margin-right: 0 !important;">arrow_drop_up</mat-icon>
            </div>
          </button>
          <button mat-menu-item (click)="sort(filter.get('order_by').value == 'filesize_original_desc' ? 'filesize_original' : 'filesize_original_desc')" [ngClass]="{'active_status': filter.get('order_by').value == 'filesize_original_desc' || filter.get('order_by').value == 'filesize_original'}">
              {{ "filesize_original" | translate }}
              <div class="d-f-c ml-1">
                  <mat-icon (click)="$event.preventDefault(); $event.stopPropagation(); sort('filesize_original_desc')" [ngClass]="{'active': filter.get('order_by').value == 'filesize_original_desc'}" style="margin-right: 0 !important;">arrow_drop_down</mat-icon>
                  <mat-icon (click)="$event.preventDefault(); $event.stopPropagation(); sort('filesize_original')" [ngClass]="{'active': filter.get('order_by').value == 'filesize_original'}" style="margin-right: 0 !important;">arrow_drop_up</mat-icon>
              </div>
          </button>
          <button mat-menu-item (click)="sort(filter.get('order_by').value == 'processing_queue_at_desc' ? 'processing_queue_at' : 'processing_queue_at_desc')" [ngClass]="{'active_status': filter.get('order_by').value == 'processing_queue_at_desc' || filter.get('order_by').value == 'processing_queue_at'}">
              {{ "processing_queue_at" | translate }}
              <div class="d-f-c ml-1">
                  <mat-icon (click)="$event.preventDefault(); $event.stopPropagation(); sort('processing_queue_at_desc')" [ngClass]="{'active': filter.get('order_by').value == 'processing_queue_at_desc'}" style="margin-right: 0 !important;">arrow_drop_down</mat-icon>
                  <mat-icon (click)="$event.preventDefault(); $event.stopPropagation(); sort('processing_queue_at')" [ngClass]="{'active': filter.get('order_by').value == 'processing_queue_at'}" style="margin-right: 0 !important;">arrow_drop_up</mat-icon>
              </div>
          </button>
          <button mat-menu-item (click)="sort(filter.get('order_by').value == 'processing_start_at_desc' ? 'processing_start_at' : 'processing_start_at_desc')" [ngClass]="{'active_status': filter.get('order_by').value == 'processing_start_at_desc' || filter.get('order_by').value == 'processing_start_at'}">
              {{ "processing_start_at" | translate }}
              <div class="d-f-c ml-1">
                  <mat-icon (click)="$event.preventDefault(); $event.stopPropagation(); sort('processing_start_at_desc')" [ngClass]="{'active': filter.get('order_by').value == 'processing_start_at_desc'}" style="margin-right: 0 !important;">arrow_drop_down</mat-icon>
                  <mat-icon (click)="$event.preventDefault(); $event.stopPropagation(); sort('processing_start_at')" [ngClass]="{'active': filter.get('order_by').value == 'processing_start_at'}" style="margin-right: 0 !important;">arrow_drop_up</mat-icon>
              </div>
          </button>
          <button mat-menu-item (click)="sort(filter.get('order_by').value == 'processing_started_at_desc' ? 'processing_started_at' : 'processing_started_at_desc')" [ngClass]="{'active_status': filter.get('order_by').value == 'processing_started_at_desc' || filter.get('order_by').value == 'processing_started_at'}">
              {{ "processing_started_at" | translate }}
              <div class="d-f-c ml-1">
                  <mat-icon (click)="$event.preventDefault(); $event.stopPropagation(); sort('processing_started_at_desc')" [ngClass]="{'active': filter.get('order_by').value == 'processing_started_at_desc'}" style="margin-right: 0 !important;">arrow_drop_down</mat-icon>
                  <mat-icon (click)="$event.preventDefault(); $event.stopPropagation(); sort('processing_started_at')" [ngClass]="{'active': filter.get('order_by').value == 'processing_started_at'}" style="margin-right: 0 !important;">arrow_drop_up</mat-icon>
              </div>
          </button>
          <button mat-menu-item (click)="sort(filter.get('order_by').value == 'processing_downloaded_at_desc' ? 'processing_downloaded_at' : 'processing_downloaded_at_desc')" [ngClass]="{'active_status': filter.get('order_by').value == 'processing_downloaded_at_desc' || filter.get('order_by').value == 'processing_downloaded_at'}">
              {{ "processing_downloaded_at" | translate }}
              <div class="d-f-c ml-1">
                  <mat-icon (click)="$event.preventDefault(); $event.stopPropagation(); sort('processing_downloaded_at_desc')" [ngClass]="{'active': filter.get('order_by').value == 'processing_downloaded_at_desc'}" style="margin-right: 0 !important;">arrow_drop_down</mat-icon>
                  <mat-icon (click)="$event.preventDefault(); $event.stopPropagation(); sort('processing_downloaded_at')" [ngClass]="{'active': filter.get('order_by').value == 'processing_downloaded_at'}" style="margin-right: 0 !important;">arrow_drop_up</mat-icon>
              </div>
          </button>
          <button mat-menu-item (click)="sort(filter.get('order_by').value == 'processing_completed_at_desc' ? 'processing_completed_at' : 'processing_completed_at_desc')" [ngClass]="{'active_status': filter.get('order_by').value == 'processing_completed_at_desc' || filter.get('order_by').value == 'processing_completed_at'}">
              {{ "processing_completed_at" | translate }}
              <div class="d-f-c ml-1">
                  <mat-icon (click)="$event.preventDefault(); $event.stopPropagation(); sort('processing_completed_at_desc')" [ngClass]="{'active': filter.get('order_by').value == 'processing_completed_at_desc'}" style="margin-right: 0 !important;">arrow_drop_down</mat-icon>
                  <mat-icon (click)="$event.preventDefault(); $event.stopPropagation(); sort('processing_completed_at')" [ngClass]="{'active': filter.get('order_by').value == 'processing_completed_at'}" style="margin-right: 0 !important;">arrow_drop_up</mat-icon>
              </div>
          </button>
          <button mat-menu-item (click)="sort(filter.get('order_by').value == 'processing_download_time_desc' ? 'processing_download_time' : 'processing_download_time_desc')" [ngClass]="{'active_status': filter.get('order_by').value == 'processing_download_time_desc' || filter.get('order_by').value == 'processing_download_time'}">
            {{ "processing_download_time" | translate }}
            <div class="d-f-c ml-1">
                <mat-icon (click)="$event.preventDefault(); $event.stopPropagation(); sort('processing_download_time_desc')" [ngClass]="{'active': filter.get('order_by').value == 'processing_download_time_desc'}" style="margin-right: 0 !important;">arrow_drop_down</mat-icon>
                <mat-icon (click)="$event.preventDefault(); $event.stopPropagation(); sort('processing_download_time')" [ngClass]="{'active': filter.get('order_by').value == 'processing_download_time'}" style="margin-right: 0 !important;">arrow_drop_up</mat-icon>
            </div>
          </button>
          <button mat-menu-item (click)="sort(filter.get('order_by').value == 'processing_time_desc' ? 'processing_time' : 'processing_time_desc')" [ngClass]="{'active_status': filter.get('order_by').value == 'processing_time_desc' || filter.get('order_by').value == 'processing_time'}">
            {{ "processing_time" | translate }}
            <div class="d-f-c ml-1">
                <mat-icon (click)="$event.preventDefault(); $event.stopPropagation(); sort('processing_time_desc')" [ngClass]="{'active': filter.get('order_by').value == 'processing_time_desc'}" style="margin-right: 0 !important;">arrow_drop_down</mat-icon>
                <mat-icon (click)="$event.preventDefault(); $event.stopPropagation(); sort('processing_time')" [ngClass]="{'active': filter.get('order_by').value == 'processing_time'}" style="margin-right: 0 !important;">arrow_drop_up</mat-icon>
            </div>
          </button>
        </mat-menu>
      </div>   
    </div>
</div>

<div class="container" style="background: #fff; border-radius: 8px;" *ngIf="company && user && host">
    <div class="projects_out">
        <!-- <app-projects
        [data]="{
          company: company,
          imgRoute: imgRoute,
          activeLang: activeLang,
          user: user,
          target_company_id: company_id,
          host: host,
          is_mobile: is_mobile,
          company_id: company_id
        }"
        [showAddOptions]="showAddOptions.value"
        [globalFilterForm]="filter"
        (Pagination)="setPagination($event)"
        [globalFilter]="filter.value"
        [isGlobal]="true">
        </app-projects> -->
        <div class="view_settings" *ngIf="isSettingsOpen">
          <div class="view_table" [appDeviceClass]="'custom-scroll custom-scroll-t'">
            <div class="items" cdkDropList cdkDropListOrientation="horizontal" (cdkDropListDropped)="drop($event)">
              <div class="view_item" *ngFor="let item of viewArch.columns; let i = index;" cdkDrag>
                <div class="head">
                    <span>{{item.name}}</span>
                    <div class="d-f-c">
                      <mat-icon class="edit_column cp" (click)="addColumn(item, i)">edit</mat-icon>
                      <mat-icon class="del_column cp" (click)="deleteColumn(item, i)">close</mat-icon>
                    </div>
                </div>
                <div class="variables" cdkDropList (cdkDropListDropped)="dropVar($event, item.variables)">
                  <div class="variable" *ngFor="let variable of item.variables; let k = index;" cdkDrag>
                    <span [ngStyle]="{'color': variable.color}">{{variable.name}}</span>
                    <div class="d-f-c">
                      <mat-icon (click)="addVariable(item, variable)">edit</mat-icon>
                      <mat-icon (click)="deleteVariable(item, variable, k)">close</mat-icon>
                    </div>
                  </div>
                  <div class="variable add cp" (click)="addVariable(item)">
                    {{"Add Variable" | translate}}
                  </div>
                </div>
              </div>
              <mat-icon class="add_column cp" style="color: #27ae60;" (click)="addColumn()">add_circle</mat-icon>
              <!-- <mat-icon class="default cp" style="color: #2980b9;" (click)="toDefault()">view_week</mat-icon> -->
            </div>
            <!-- <div class="actions">
              {{"Actions" | translate}}
            </div> -->
          </div>
        </div>

        <div class="vid_projects" [ngClass]="{'setting_opened': isSettingsOpen}">

          <div class="head_wrp">
              <div class="project_values">
                <ng-container *ngFor="let item of viewArch.columns; let i = index;">
                  <ng-container [ngSwitch]="item.name">
                    <ng-container *ngSwitchCase="'ID'">
                      <div class="id">{{"ID" | translate}}</div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'Card & Job ID'">
                      <div class="card_job card_job_head d-f-c">
                        <div>
                            <div>{{"Card" | translate}}</div>
                            <div>{{"Job" | translate}}</div>
                        </div>
                        <div>{{"ID" | translate}}</div>
                      </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'Project Name'">
                      <div class="name" style="display: flex; align-items: center; justify-content: space-between;">
                        <span>
                            {{"Project Name" | translate}} 
                        </span>
                      </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'Template'">
                      <div class="templates_col">{{"Templates" | translate}}</div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'Employees'">
                      <div class="employees_col">{{"Employees" | translate}}</div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'Created At'">
                      <div class="created">{{"Created At" | translate}}</div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'Stage'">
                      <div class="stage">{{"Stage" | translate}}</div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'Units'">
                      <div class="units">{{"Units" | translate}}</div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'Queue time'">
                      <div class="time" #tooltip="matTooltip" matTooltip="{{ 'Queue time' | translate }}" [matTooltipPosition]="'above'"><mat-icon>more_time</mat-icon></div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'Processing start time'">
                      <div class="time" #tooltip="matTooltip" matTooltip="{{ 'Processing start time' | translate }}" [matTooltipPosition]="'above'"><mat-icon>schedule</mat-icon></div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'Processing end time'">
                      <div class="time" #tooltip="matTooltip" matTooltip="{{ 'Processing end time' | translate }}" [matTooltipPosition]="'above'"><mat-icon>access_time_filled</mat-icon></div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'How many seconds did it take'">
                      <div class="time" #tooltip="matTooltip" matTooltip="{{ 'How many seconds did it take' | translate }}" [matTooltipPosition]="'above'"><mat-icon>alarm_on</mat-icon></div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'Preparing'">
                      <div class="time" #tooltip="matTooltip" matTooltip="{{ 'Preparing' | translate }}" [matTooltipPosition]="'above'"><mat-icon>downloading</mat-icon></div>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </div>
              
              <div class="company_btns">
                  <!-- {{"Actions" | translate}} -->
              </div>
          </div>

          <mat-list 
          role="list" 
          class="scrolled_list" 
          [appDeviceClass]="'custom-scroll custom-scroll-t'"           
          infinite-scroll
          [infiniteScrollDisabled]="isLoad"
          [scrollWindow]="false"
          [infiniteScrollUpDistance]="scrollUpDistance"
          [infiniteScrollThrottle]="throttle"
          (scrolled)="onDown($event)">
              <mat-list-item *ngFor="let project of projects; let i = index;" role="listitem" class="company_item proj_list_item">
                  <div class="project_out">
                      <div class="project_in">
                          <div class="project_values">
                            <ng-container *ngFor="let item of viewArch.columns; let i = index;">
                              <ng-container [ngSwitch]="item.name">
                                <ng-container *ngSwitchCase="'ID'">
                                  <div class="id">
                                    <ng-container *ngFor="let variable of item.variables" [ngTemplateOutlet]="variableTemplate" [ngTemplateOutletContext]="{ variable: variable, project: project, col: item }"></ng-container>
                                  </div>
                                </ng-container>
                                <ng-container *ngSwitchCase="'Card & Job ID'">
                                  <div class="card_job">
                                    <ng-container *ngFor="let variable of item.variables" [ngTemplateOutlet]="variableTemplate" [ngTemplateOutletContext]="{ variable: variable, project: project, col: item }"></ng-container>
                                  </div>
                                </ng-container>
                                <ng-container *ngSwitchCase="'Project Name'">
                                  <div class="name">
                                    <ng-container *ngFor="let variable of item.variables" [ngTemplateOutlet]="variableTemplate" [ngTemplateOutletContext]="{ variable: variable, project: project, col: item }"></ng-container>
                                  </div>
                                </ng-container>
                                <ng-container *ngSwitchCase="'Template'">
                                  <div class="templates_col">
                                    <ng-container *ngFor="let variable of item.variables" [ngTemplateOutlet]="variableTemplate" [ngTemplateOutletContext]="{ variable: variable, project: project, col: item }"></ng-container>
                                  </div>
                                </ng-container>
                                <ng-container *ngSwitchCase="'Employees'">
                                  <div class="employees_col">
                                    <ng-container *ngFor="let variable of item.variables" [ngTemplateOutlet]="variableTemplate" [ngTemplateOutletContext]="{ variable: variable, project: project, col: item }"></ng-container>
                                  </div>
                                </ng-container>
                                <ng-container *ngSwitchCase="'Created At'">
                                  <div class="created">
                                    <ng-container *ngFor="let variable of item.variables" [ngTemplateOutlet]="variableTemplate" [ngTemplateOutletContext]="{ variable: variable, project: project, col: item }"></ng-container>
                                  </div>
                                </ng-container>
                                <ng-container *ngSwitchCase="'Stage'">
                                  <div class="stage" style="position: relative;">
                                    <ng-container *ngFor="let variable of item.variables" [ngTemplateOutlet]="variableTemplate" [ngTemplateOutletContext]="{ variable: variable, project: project, col: item }"></ng-container>
                                  </div>
                                </ng-container>
                                <ng-container *ngSwitchCase="'Units'">
                                  <div class="units">
                                    <ng-container *ngFor="let variable of item.variables" [ngTemplateOutlet]="variableTemplate" [ngTemplateOutletContext]="{ variable: variable, project: project, col: item }"></ng-container>
                                  </div>
                                </ng-container>
                                <ng-container *ngSwitchCase="'Queue time'">
                                  <div class="time">
                                    <ng-container *ngFor="let variable of item.variables" [ngTemplateOutlet]="variableTemplate" [ngTemplateOutletContext]="{ variable: variable, project: project, col: item }"></ng-container>
                                  </div>
                                </ng-container>
                                <ng-container *ngSwitchCase="'Processing start time'">
                                  <div class="time">
                                    <ng-container *ngFor="let variable of item.variables" [ngTemplateOutlet]="variableTemplate" [ngTemplateOutletContext]="{ variable: variable, project: project, col: item }"></ng-container>
                                  </div>
                                </ng-container>
                                <ng-container *ngSwitchCase="'Processing end time'">
                                  <div class="time">
                                    <ng-container *ngFor="let variable of item.variables" [ngTemplateOutlet]="variableTemplate" [ngTemplateOutletContext]="{ variable: variable, project: project, col: item }"></ng-container>
                                  </div>
                                </ng-container>
                                <ng-container *ngSwitchCase="'How many seconds did it take'">
                                  <div class="time">
                                    <ng-container *ngFor="let variable of item.variables" [ngTemplateOutlet]="variableTemplate" [ngTemplateOutletContext]="{ variable: variable, project: project, col: item }"></ng-container>
                                  </div>
                                </ng-container>
                                <ng-container *ngSwitchCase="'Preparing'">
                                  <div class="time">
                                    <ng-container *ngFor="let variable of item.variables" [ngTemplateOutlet]="variableTemplate" [ngTemplateOutletContext]="{ variable: variable, project: project, col: item }"></ng-container>
                                  </div>
                                </ng-container>
                              </ng-container>
                            </ng-container>            
                          </div>
                          
                          <div class="company_btns d-f-c">
                              <mat-icon *ngIf="user?.is_root" class="expand_root show_on_hover" (click)="project.showInfo = !project.showInfo; $event.stopPropagation(); $event.preventDefault();">
                                {{!project.showInfo ? 'expand_more' : 'expand_less'}}
                              </mat-icon>
                              <button [ngClass]="{'blue_dots': !!project.history_count}"  style="color: #ccc;" mat-icon-button [matMenuTriggerFor]="projDotsMenu" #menuTrigger="matMenuTrigger" aria-label="Example icon-button with a menu">
                                  <mat-icon>more_vert</mat-icon>
                              </button>
                              <mat-menu #projDotsMenu="matMenu" xPosition="before">
                                  <div class="select_wrp_out d-f-c" mat-menu-item (click)="$event.preventDefault(); $event.stopPropagation(); priority.toggle()">
                                      <div class="select_wrp" style="position: relative; height: 48px;">
                                          <div>
                                              {{ "Priority" | translate }} :
                                              <b>
                                                  <ng-container [ngSwitch]="project.waiting_time_limit">
                                                      <ng-container *ngSwitchCase="1">
                                                          {{ "Now" | translate }}
                                                      </ng-container>
                                                      <ng-container *ngSwitchCase="300">
                                                          {{ "Immediately" | translate }}
                                                      </ng-container>
                                                      <ng-container *ngSwitchCase="1200">
                                                          {{ "Urgent" | translate }}
                                                      </ng-container>
                                                      <ng-container *ngSwitchCase="14400">
                                                          {{ "High" | translate }}
                                                      </ng-container>
                                                      <ng-container *ngSwitchCase="43200">
                                                          {{ "Middle" | translate }}
                                                      </ng-container>
                                                      <ng-container *ngSwitchCase="86400">
                                                          {{ "Low" | translate }}
                                                      </ng-container>
                                                      <ng-container *ngSwitchCase="172800">
                                                          {{ "No priority" | translate }}
                                                      </ng-container>
                                                      <ng-container *ngSwitchDefault>
                                                          {{ "Priority" | translate }}
                                                      </ng-container>
                                                  </ng-container>
                                              </b>
                                              <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                                                  <mat-select disableOptionCentering #priority [(ngModel)]="project.waiting_time_limit" (selectionChange)="editProjPriority(project)">
                                                      <mat-option value="1" *ngIf="!!user?.is_root" [ngClass]="{'cst_active_select_option': project.waiting_time_limit == 1}">
                                                          {{ "Now" | translate }}
                                                      </mat-option>
                                                      <mat-option value="300" *ngIf="!!user?.is_root" [ngClass]="{'cst_active_select_option': project.waiting_time_limit == 300}">
                                                          {{ "Immediately" | translate }} (x3 VPU) 
                                                      </mat-option>
                                                      <mat-option value="1200" [ngClass]="{'cst_active_select_option': project.waiting_time_limit == 1200}">
                                                          {{ "Urgent" | translate }} (x2 VPU)
                                                      </mat-option>
                                                      <mat-option value="14400" [ngClass]="{'cst_active_select_option': project.waiting_time_limit == 14400}">
                                                          {{ "High" | translate }} (x1.2 VPU)
                                                      </mat-option>
                                                      <mat-option value="43200" [ngClass]="{'cst_active_select_option': project.waiting_time_limit == 43200}">
                                                          {{ "Middle" | translate }} (x1.2 VPU)
                                                      </mat-option>
                                                      <mat-option value="86400" [ngClass]="{'cst_active_select_option': project.waiting_time_limit == 86400}">
                                                          {{ "Low" | translate }}
                                                      </mat-option>
                                                      <mat-option value="172800" [ngClass]="{'cst_active_select_option': project.waiting_time_limit == 172800}">
                                                          {{ "No priority" | translate }}
                                                      </mat-option>
                                                  </mat-select>
                                              </mat-form-field>
                                          </div>
                                      </div>
                                      <!-- <div (click)="editProjPriority(project)">
                                          {{"Edit" | translate}}
                                      </div> -->
                                  </div>
                                  <mat-divider></mat-divider>
                                  <ng-container *ngIf="!!project?.is_to_process">
                                      <button (click)="editProjStatus(project, 'is_draft')" mat-menu-item>
                                          <mat-icon class="mr-1">edit_square</mat-icon>
                                          {{ 'Set Draft status' | translate }}
                                      </button>
                                      <mat-divider></mat-divider>
                                  </ng-container>
                                  <button [matMenuTriggerFor]="menuAsNew" *ngIf="!!project.history_count" mat-menu-item>
                                      <mat-icon class="mr-1">library_add</mat-icon>
                                      {{ 'Duplicate' | translate }}
                                  </button>
                                  <button [matMenuTriggerFor]="menu" *ngIf="!!project.history_count" mat-menu-item>
                                      <mat-icon class="mr-1">movie_edit</mat-icon>
                                      {{ 'Open' | translate }}
                                  </button>
                                  <button *ngIf="!(project.is_processing || project.is_to_process || project.is_reset == 1)" mat-menu-item (click)="refreshProject(project, i)">
                                      <mat-icon class="mr-1">refresh</mat-icon>
                                      {{ 'Export' | translate }}
                                  </button>
                                  <button *ngIf="!!user?.is_root" mat-menu-item (click)="deleteProject(project, i)">
                                      <mat-icon class="mr-1">delete</mat-icon>
                                      {{ 'Delete' | translate }}
                                  </button>
                              </mat-menu>

                              <mat-menu #menuAsNew="matMenu" xPosition="before">
                                <button  mat-menu-item (click)="V5VideoEditor(project, i, true)">
                                    <mat-icon class="mr-1">library_add</mat-icon>
                                    {{ 'V5' | translate }}
                                </button>
                                <button  mat-menu-item (click)="videoEditorV6(project, i, true)">
                                  <mat-icon class="mr-1">library_add</mat-icon>
                                  {{ 'V5.1' | translate }}
                                </button>
                                <button mat-menu-item (click)="videoEditorVA(project, i, true)">
                                  <div class="d-f-c" style="justify-content: space-between;">
                                    <mat-icon class="mr-1">library_add</mat-icon>
                                    {{ 'V. A' | translate }}
                                    <div class="other_job" (click)="$event.preventDefault(); $event.stopPropagation(); duplicateToOtherJob(project, i, true, menuTrigger, 'A');" #tooltip="matTooltip" matTooltip="{{ 'Duplicate to other job' | translate }}" [matTooltipPosition]="'above'">
                                      <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg" style="width: 18px; height: 15px;"><path d="M0 14.4H18V10.8H0V14.4ZM1.8 11.7H3.6V13.5H1.8V11.7ZM0 0V3.6H18V0H0ZM3.6 2.7H1.8V0.9H3.6V2.7ZM0 9H18V5.4H0V9ZM1.8 6.3H3.6V8.1H1.8V6.3Z" fill="#686868"></path></svg>
                                    </div>
                                  </div>
                                </button>
                                <button  mat-menu-item (click)="videoEditorAA(project, i, true)">
                                  <div class="d-f-c" style="justify-content: space-between;">
                                    <mat-icon class="mr-1">library_add</mat-icon>
                                    {{ 'V. AA' | translate }}
                                    <div class="other_job" (click)="$event.preventDefault(); $event.stopPropagation(); duplicateToOtherJob(project, i, true, menuTrigger, 'AA');" #tooltip="matTooltip" matTooltip="{{ 'Duplicate to other job' | translate }}" [matTooltipPosition]="'above'">
                                      <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg" style="width: 18px; height: 15px;"><path d="M0 14.4H18V10.8H0V14.4ZM1.8 11.7H3.6V13.5H1.8V11.7ZM0 0V3.6H18V0H0ZM3.6 2.7H1.8V0.9H3.6V2.7ZM0 9H18V5.4H0V9ZM1.8 6.3H3.6V8.1H1.8V6.3Z" fill="#686868"></path></svg>
                                    </div>
                                  </div>
                                </button>
                              </mat-menu>

                              <mat-menu #menu="matMenu" xPosition="before">
                                  <button  mat-menu-item (click)="V5VideoEditor(project, i)">
                                      <mat-icon class="mr-1">movie_edit</mat-icon>
                                      {{ 'V5' | translate }}
                                  </button>
                                  <button  mat-menu-item (click)="videoEditorV6(project, i)">
                                      <mat-icon class="mr-1">movie_edit</mat-icon>
                                      {{ 'V5.1' | translate }}
                                  </button>
                                  <button mat-menu-item (click)="videoEditorVA(project, i)">
                                    <mat-icon class="mr-1">movie_edit</mat-icon>
                                    {{ 'V. A' | translate }}
                                  </button>
                                  <button  mat-menu-item (click)="videoEditorAA(project, i)">
                                      <mat-icon class="mr-1">movie_edit</mat-icon>
                                      {{ 'V. AA' | translate }}
                                  </button>
                              </mat-menu>
                          </div>
                      </div>
                      <div class="project_history" *ngIf="project?.isOpen">
                          <div class="history_item" *ngFor="let item of project?.runHistory">
                              <div class="project_values">
                                  <div class="id">
                                    <div>&nbsp;</div>
                                    <div class="d-f-c">
                                        <div class="output_in region" style="opacity: .8; margin-right: 5px;">
                                            {{item.editor_version}}
                                        </div>
                                    </div>
                                  </div>
                                  <div class="card_job"></div>
                                  <div class="name">
                                    <app-user [class]="'user_div'" [employee]="item.employee" [task_operation_id]="project?.task_operation_id"></app-user>
                                    <div class="output_in" [ngClass]="{'show_on_hover': !showAddOptions.value}" *ngIf="!!user?.is_root">{{item.server_id}}<ng-container *ngIf="item.server_id && item?.region">, </ng-container><span class="region" *ngIf="item?.region">{{item?.region}}</span></div>
                                  </div>
                                  <div class="templates_col"></div>
                                  <div class="employees_col"></div>
                                  <div class="created">
                                    <span #tooltip="matTooltip" matTooltip="{{ item.created_at*1000 | date:'hh:mm, a':'':activeLang }}" [matTooltipPosition]="'above'">{{item.created_at | reportsDate:activeLang}}</span>
                                  </div>
                                  <div class="stage">
                                    <mat-icon *ngIf="!!user?.is_root && item?.processing_error" #tooltip="matTooltip" matTooltip="{{ item?.processing_error }}" [matTooltipPosition]="'above'" style="color: #c0392b;">warning</mat-icon>
                                  </div>
                                  <div class="units">
                                      <span *ngIf="item?.price_unit">{{item?.price_unit}}</span>
                                  </div>
                                  <div class="time">
                                      <span #tooltip="matTooltip" matTooltip="{{ item.processing_queue_at*1000 | date:'hh:mm, a':'':activeLang }}" [matTooltipPosition]="'above'">{{item.processing_queue_at | reportsDate:activeLang}}</span>
                                  </div>
                                  <div class="time">
                                      <span #tooltip="matTooltip" matTooltip="{{ item.processing_started_at*1000 | date:'hh:mm, a':'':activeLang }}" [matTooltipPosition]="'above'">{{item.processing_started_at | reportsDate:activeLang}}</span>
                                  </div>
                                  <div class="time">
                                      <span #tooltip="matTooltip" matTooltip="{{ item.processing_completed_at*1000 | date:'hh:mm, a':'':activeLang }}" [matTooltipPosition]="'above'">{{item.processing_completed_at | reportsDate:activeLang}}</span>
                                  </div>
                                  <div class="time">
                                      <span>{{item.processing_time | timeFormat}}</span>
                                  </div>
                                  <div class="time">
                                      <span>{{item.processing_download_time | timeFormat}}</span>
                                  </div>
                              </div>
                              <div class="company_btns">
  
                              </div>
                          </div>
                      </div>
                      <ng-container *ngIf="project?.showInfo">
                        <ng-container *ngIf="!project.is_error && user?.external_id == 8166 && project?.execute_data">
                          <hr>
                          <p><b>VE v.A execute_data:</b></p>
                          <div class="d-f" *ngIf="project?.execute_data?.inputs">
                            <b>inputs:</b>
                            <pre [innerHTML]="project?.execute_data.inputs | json" style="font-size: 12px; line-height: 1; overflow-y: hidden; overflow-x: auto;"></pre>
                          </div>
                          <div class="d-f">
                            <b>input_text_files:</b>
                            <div *ngIf="project?.execute_data?.input_text_files" style="margin-top: 20px;">
                              <div class="d-f one_fg" *ngFor="let textFile of getArray(project?.execute_data.input_text_files); let q = index;">
                                <b>text_file_{{q}}:</b>
                                <div style="display: flex; flex-direction: column;" style="margin-top: 20px;">
                                  <div class="d-f">
                                    <b>content:</b>
                                    <pre [innerHTML]="textFile.content" style="font-size: 12px; line-height: 1; overflow-y: hidden; overflow-x: auto; color: #155530;"></pre>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="d-f">
                            <b>filtergraphs:</b>
                            <div *ngIf="project?.execute_data?.filtergraphs" style="margin-top: 20px;">
                              <div class="d-f one_fg" *ngFor="let fg of getArray(project?.execute_data.filtergraphs); let q = index;">
                                <b>fg{{q+1}}:</b>
                                <div style="display: flex; flex-direction: column;" style="margin-top: 20px;">
                                  <div class="d-f">
                                    <b>inputs:</b>
                                    <pre [innerHTML]="fg.inputs | json" style="font-size: 12px; line-height: 1; overflow-y: hidden; overflow-x: auto;"></pre>
                                  </div>
                                  <div class="d-f">
                                    <b>filtergraph:</b>
                                    <pre [innerHTML]="fg.filtergraph" style="font-size: 12px; line-height: 1; overflow-y: hidden; overflow-x: auto; color: #155530;"></pre>
                                  </div>
                                  <div class="d-f">
                                    <b>outputs:</b>
                                    <pre [innerHTML]="fg.outputs | json" style="font-size: 12px; line-height: 1; overflow-y: hidden; overflow-x: auto;"></pre>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </ng-container>
                        
                        <ng-container *ngIf="project.is_error && user?.is_root && project?.execute_data">
                          <hr>
                          <p><b>VE v.A execute_data:</b></p>
                          <div class="d-f">
                            <b>inputs:</b>
                            <pre [innerHTML]="project?.execute_data.inputs | json" style="font-size: 12px; line-height: 1; overflow-y: hidden; overflow-x: auto;"></pre>
                          </div>
                          <div class="d-f">
                            <b>input_text_files:</b>
                            <div *ngIf="project?.execute_data?.input_text_files" style="margin-top: 20px;">
                              <div class="d-f one_fg" *ngFor="let textFile of getArray(project?.execute_data.input_text_files); let q = index;">
                                <b>text_file_{{q}}:</b>
                                <div style="display: flex; flex-direction: column;" style="margin-top: 20px;">
                                  <div class="d-f">
                                    <b>content:</b>
                                    <pre [innerHTML]="textFile.content" style="font-size: 12px; line-height: 1; overflow-y: hidden; overflow-x: auto; color: #155530;"></pre>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="d-f">
                            <b>filtergraphs:</b>
                            <div *ngIf="project?.execute_data.filtergraphs" style="margin-top: 20px;">
                              <div class="d-f one_fg" *ngFor="let fg of getArray(project?.execute_data.filtergraphs); let q = index;">
                                <b>fg{{q+1}}:</b>
                                <div style="display: flex; flex-direction: column;" style="margin-top: 20px;">
                                  <div class="d-f">
                                    <b>inputs:</b>
                                    <pre [innerHTML]="fg.inputs | json" style="font-size: 12px; line-height: 1; overflow-y: hidden; overflow-x: auto;"></pre>
                                  </div>
                                  <div class="d-f">
                                    <b>filtergraph:</b>
                                    <pre [innerHTML]="fg.filtergraph" style="font-size: 12px; line-height: 1; overflow-y: hidden; overflow-x: auto; color: #155530;"></pre>
                                  </div>
                                  <div class="d-f">
                                    <b>outputs:</b>
                                    <pre [innerHTML]="fg.outputs | json" style="font-size: 12px; line-height: 1; overflow-y: hidden; overflow-x: auto;"></pre>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <ng-container *ngIf="project?.runHistory && project?.runHistory[0] && project.runHistory[0].execute_log">
                            <hr>
                            <p><b>VE v.A LOG:</b></p>
                            <pre [innerHTML]="project.runHistory[0].execute_log" style="font-size: 12px; line-height: 1; overflow-y: hidden; overflow-x: auto;"></pre>
                            <hr>
                          </ng-container>
                        </ng-container>
                      </ng-container>
                      
                  </div>
              </mat-list-item>
          </mat-list>
      </div>
    </div>
</div>

<ng-template #variableTemplate let-variable="variable" let-project="project" let-col="col">
  <ng-container [ngSwitch]="variable.key">

    <ng-container *ngSwitchCase="'id'">
      <div #tooltip="matTooltip" matTooltip="{{ 'Project ID' | translate }}" [matTooltipPosition]="'above'" [ngStyle]="{'color': variable.color, 'font-size': variable.fontSize+'px'}">{{project.id}}</div>
    </ng-container>

    <ng-container *ngSwitchCase="'editor_version'">
      <div class="d-f-c">
        <div #tooltip="matTooltip" matTooltip="{{ 'Project Version' | translate }}" [matTooltipPosition]="'above'" class="output_in region" style="font-size: 8px; opacity: .8; margin-right: 3.5px;" [ngStyle]="{'color': variable.color, 'font-size': variable.fontSize+'px'}" *ngIf="project.editor_version">
            {{project.editor_version}}
        </div>
        <div class="output_in" [ngClass]="{'show_on_hover': !showAddOptions.value}" *ngIf="!!user?.is_root" style="opacity: .8; font-size: 8px !important;">
            <span #tooltip="matTooltip" matTooltip="{{ 'Project original filesize' | translate }}" [matTooltipPosition]="'above'">{{ project?.filesize_original | filesize:2:true }}</span> <ng-container *ngIf="project.filesize"><span #tooltip="matTooltip" matTooltip="{{ 'Project filesize' | translate }}" [matTooltipPosition]="'above'"> - {{project?.filesize | filesize:2:true }}</span></ng-container>
        </div>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'task_id'">
      <div class="cp" *ngIf="!!project?.task_id" (click)="openTask({id: project?.task_id})">
        <span #tooltip="matTooltip" matTooltip="{{ 'Project Card' | translate }}" [matTooltipPosition]="'above'" [ngStyle]="{'color': variable.color, 'font-size': variable.fontSize+'px'}">
          {{project?.task_id}}
        </span>
        <a (click)="openInNew($event, project, 'task_id')" href="/projects?company_id={{company?.id}}&count=1&task_id={{project?.task_id}}&order_by=id_desc" class="open_in_new"><mat-icon>open_in_new</mat-icon></a>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'task_operation_id'">
      <div class="cp" *ngIf="!!project?.task_operation_id" (click)="openJob(project?.task_id, project?.task_operation_id, project?.output_location)">
        <span #tooltip="matTooltip" matTooltip="{{ 'Project Job' | translate }}" [matTooltipPosition]="'above'" [ngStyle]="{'color': variable.color, 'font-size': variable.fontSize+'px'}">
          {{project?.task_operation_id}}
        </span>
        <a (click)="openInNew($event, project, 'task_operation_id')" href="/projects?company_id={{company?.id}}&count=1&task_operation_id={{project?.task_operation_id}}&order_by=id_desc" class="open_in_new"><mat-icon>open_in_new</mat-icon></a>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="'name'">
      <div class="name_in" (click)="project?.runHistory && project?.runHistory.length ? toggleProject(project) : null" [ngClass]="{'blue': project?.runHistory && project?.runHistory.length}">
        <div #tooltip="matTooltip" matTooltip="{{ 'Project Name' | translate }}" [matTooltipPosition]="'above'" class="name_line text_one_line" [ngStyle]="{'color': variable.color, 'font-size': variable.fontSize+'px'}">{{project.name}}</div>
        <ng-container *ngIf="!!project?.lastEditedV">
          <mat-icon class="proj_info show_on_hover" [ngxTippy]="tippyTemplate">info</mat-icon>
          <div #tippyTemplate class="project_tooltip">
            <div class="d-f-c">
              <div class="">{{ 'Last edited version'| translate}}</div>
              <div class="ml-1" style="font-weight: 500;">{{ project?.lastEditedV }}</div>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="'task_operation_name'">
      <div #tooltip="matTooltip" matTooltip="{{ 'Project Job Name' | translate }}" [matTooltipPosition]="'above'" class="name_in text_one_line" *ngIf="!!project?.taskOperation" [ngStyle]="{'color': variable.color, 'font-size': variable.fontSize+'px'}">{{project?.taskOperation?.name}}</div>
    </ng-container>
    <ng-container *ngSwitchCase="'task_operation_type'">
      <div #tooltip="matTooltip" matTooltip="{{ 'Project Job Type' | translate }}" [matTooltipPosition]="'above'" class="name_in text_one_line" *ngIf="!!project?.taskOperation && !!project?.taskOperation?.operation && !!project?.taskOperation?.operation?.name" [ngStyle]="{'color': variable.color, 'font-size': variable.fontSize+'px'}">{{project?.taskOperation?.operation?.name}}</div>
    </ng-container>
    <ng-container *ngSwitchCase="'task_operation_status'">
      <div #tooltip="matTooltip" matTooltip="{{ 'Project Job Status' | translate }}" [matTooltipPosition]="'above'" class="inside_job" *ngIf="!!project?.taskOperation" [ngClass]="{
        'first': project?.taskOperation?.status_id == '1',
        'second': project?.taskOperation?.status_id == '2',
        'third': project?.taskOperation?.status_id == '3',
        'fours': project?.taskOperation?.status_id == '4',
        'fives': project?.taskOperation?.status_id == '5',
        'sixs': project?.taskOperation?.status_id == '6',
        'nine': project?.taskOperation?.status_id == '97',
        'seven': project?.taskOperation?.status_id == '98',
        'eight': project?.taskOperation?.status_id == '99'}">
        <span class="text_one_line">{{ getStatus(project?.taskOperation?.status_id)?.name | translate }}</span>  
    </div>
    </ng-container>
    <ng-container *ngSwitchCase="'task.group.name'">
      <div #tooltip="matTooltip" matTooltip="{{ 'Project Card Group' | translate }}" [matTooltipPosition]="'above'" class="group_in text_one_line" *ngIf="project?.task?.group?.name" [ngStyle]="{'color': variable.color, 'font-size': variable.fontSize+'px'}">{{project?.task?.group?.name}}</div>
    </ng-container>
    <ng-container *ngSwitchCase="'server_and_region'">
      <div class="output_in" [ngClass]="{'show_on_hover': !showAddOptions.value}" *ngIf="!!user?.is_root" [ngStyle]="{'color': variable.color, 'font-size': variable.fontSize+'px'}"><span #tooltip="matTooltip" matTooltip="{{ 'Project Server' | translate }}" [matTooltipPosition]="'above'">{{project.server_id}}</span><ng-container *ngIf="project.server_id && project?.region">, </ng-container><span class="region" #tooltip="matTooltip" matTooltip="{{ 'Project Region' | translate }}" [matTooltipPosition]="'above'" *ngIf="project?.region">{{project?.region}}</span></div>
    </ng-container>
    <ng-container *ngSwitchCase="'templateIds'">
      <div *ngFor="let tID of project?.templateIds" #tooltip="matTooltip" matTooltip="{{ 'Project Template' | translate }}" [matTooltipPosition]="'above'" class="text_one_line" [ngStyle]="{'color': variable.color, 'font-size': variable.fontSize+'px'}">
          {{getTemplateById(tID)?.name}}
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="'employees'">
      <div class="employees_var" [ngStyle]="{'width': '98px', 'min-width': '98px'}">
        <ng-container *ngIf="project?.employees && project?.employees?.length">
            <div class="more_empls">
                <ng-container *ngFor="let item of project?.employees; let u = index;">
                  <ng-container *ngIf="u <= 3">
                    <ng-container *ngIf="item?.employee?.image; else elseMobWorkTemplate">
                      <img [appHoverUserInfo]="{ user: item?.employee, company_id: company?.id, task_operation_id: project?.task_operation_id }"
                      #tooltip="matTooltip" 
                      [ngClass]="{
                        'first': item?.employeeStatus?.status_id == '1',
                        'second': item?.employeeStatus?.status_id == '2',
                        'third': item?.employeeStatus?.status_id == '3',
                        'fours': item?.employeeStatus?.status_id == '4',
                        'fives': item?.employeeStatus?.status_id == '5',
                        'sixs': item?.employeeStatus?.status_id == '6',
                        'nine': item?.employeeStatus?.status_id == '97',
                        'seven': item?.employeeStatus?.status_id == '98',
                        'eight': item?.employeeStatus?.status_id == '99'}"
                      matTooltip="{{item?.employee?.name}}"
                      [matTooltipPosition]="'above'" 
                      [src]="item?.employee?.image" alt="" [ngStyle]="{'z-index': project?.employees.length - u, 'left': u*17.5 + 'px'}">
                    </ng-container>
                    <ng-template #elseMobWorkTemplate>
                      <ng-container *ngIf="item?.is_partner; else elsePartner">
                        <ng-container *ngIf="!!item?.partnerCompany?.avatarFile?.thumbnail && !!host; else elseTemplatePartnerAvatar">
                          <img [appHoverUserInfo]="{ user: {partnerCompany: item?.partnerCompany}, company_id: company?.id, task_operation_id: project?.task_operation_id }"
                          [ngClass]="{
                            'first': item?.partnerCompanyStatus?.status_id == '1',
                            'second': item?.partnerCompanyStatus?.status_id == '2',
                            'third': item?.partnerCompanyStatus?.status_id == '3',
                            'fours': item?.partnerCompanyStatus?.status_id == '4',
                            'fives': item?.partnerCompanyStatus?.status_id == '5',
                            'sixs': item?.partnerCompanyStatus?.status_id == '6',
                            'nine': item?.partnerCompanyStatus?.status_id == '97',
                            'seven': item?.partnerCompanyStatus?.status_id == '98',
                            'eight': item?.partnerCompanyStatus?.status_id == '99'}"
                          #tooltip="matTooltip" 
                          [ngStyle]="{'z-index': project?.employees.length - u, 'left': u*17.5 + 'px'}"
                          matTooltip="{{item?.partnerCompany?.name}}"
                          [matTooltipPosition]="'above'" class="partner_avatar" (error)="onPartnerImgError($event)" [src]="host + item?.partnerCompany?.avatarFile?.thumbnail + '?company_id=' + company?.id" alt="">
                        </ng-container>
                        <ng-template #elseTemplatePartnerAvatar>
                          <img [appHoverUserInfo]="{ user: {partnerCompany: item?.partnerCompany}, company_id: company?.id, task_operation_id: project?.task_operation_id }"
                          [ngClass]="{
                            'first': item?.partnerCompanyStatus?.status_id == '1',
                            'second': item?.partnerCompanyStatus?.status_id == '2',
                            'third': item?.partnerCompanyStatus?.status_id == '3',
                            'fours': item?.partnerCompanyStatus?.status_id == '4',
                            'fives': item?.partnerCompanyStatus?.status_id == '5',
                            'sixs': item?.partnerCompanyStatus?.status_id == '6',
                            'nine': item?.partnerCompanyStatus?.status_id == '97',
                            'seven': item?.partnerCompanyStatus?.status_id == '98',
                            'eight': item?.partnerCompanyStatus?.status_id == '99'}"
                          #tooltip="matTooltip" 
                          [ngStyle]="{'z-index': project?.employees.length - u, 'left': u*17.5 + 'px'}"
                          matTooltip="{{item?.partnerCompany?.name}}"
                          [matTooltipPosition]="'above'" class="partner_avatar" (error)="onPartnerImgError($event)" src="{{ imgRoute }}assets/img/partner.png" alt="">
                        </ng-template>
                      </ng-container>
                      <ng-template #elsePartner>
                        <mat-icon [appHoverUserInfo]="{ user: item?.employee, company_id: company?.id, task_operation_id: project?.task_operation_id }"
                        #tooltip="matTooltip" 
                        [ngStyle]="{'z-index': project?.employees.length - u, 'left': u*17.5 + 'px'}"
                        [ngClass]="{
                            'first': item?.employeeStatus?.status_id == '1',
                            'second': item?.employeeStatus?.status_id == '2',
                            'third': item?.employeeStatus?.status_id == '3',
                            'fours': item?.employeeStatus?.status_id == '4',
                            'fives': item?.employeeStatus?.status_id == '5',
                            'sixs': item?.employeeStatus?.status_id == '6',
                            'nine': item?.employeeStatus?.status_id == '97',
                            'seven': item?.employeeStatus?.status_id == '98',
                            'eight': item?.employeeStatus?.status_id == '99'}"
                        matTooltip="{{item?.employee?.name}}"
                        [matTooltipPosition]="'above'">account_circle</mat-icon>
                      </ng-template>
                    </ng-template>
                  </ng-container>      
                </ng-container>
            </div>
            <ng-container *ngIf="project?.employees?.length > 4;">
              <div class="plus">
                +{{project?.employees?.length - 4}}
              </div>
            </ng-container>
        </ng-container>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="'createdEmployee'">
      <div class="creator" *ngIf="project?.createdEmployee" [ngClass]="{'show_on_hover': !showAddOptions.value}" #tooltip="matTooltip" 
      matTooltip="{{ 'Project Creator' | translate }}: {{project?.createdEmployee?.name}}"
      [matTooltipPosition]="'above'">
          <app-user [class]="'only_photo'" [employee]="project?.createdEmployee" [task_operation_id]="project?.task_operation_id"></app-user>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="'created_at'">
      <div [ngStyle]="{'color': variable.color, 'font-size': variable.fontSize+'px'}" #tooltip="matTooltip" matTooltip="{{ 'Project Created at' | translate }}: {{ project.created_at*1000 | date:'hh:mm, a':'':activeLang }}" [matTooltipPosition]="'above'">{{project.created_at | reportsDate:activeLang}}</div>
    </ng-container>
    <ng-container *ngSwitchCase="'stage'">
      <div>
        <mat-icon class="cp icon_12" *ngIf="project.task_operation_id" [ngClass]="{'show_on_hover': !project?.is_clicked}" #tooltip="matTooltip" matTooltip="{{project.output_location}}" [matTooltipPosition]="'above'" (click)="$event.preventDefault(); $event.stopPropagation(); openLiteJob(project, 3, project.output_location)">folder</mat-icon>
        <span #tooltip="matTooltip" matTooltip="{{ 'Project Stage' | translate }}" [matTooltipPosition]="'above'" *ngIf="project.is_draft">{{"Draft" | translate}}</span>
        <span *ngIf="project.is_error" #tooltip="matTooltip" matTooltip="{{ 'Project Stage' | translate }}: {{ !!user?.is_root ? project.processing_error : '' }}" [matTooltipPosition]="'above'" style="color: #c0392b;">{{"Error" | translate}}</span>
        <ng-container *ngIf="project.is_processing">
            <span #tooltip="matTooltip" matTooltip="{{ 'Project Stage' | translate }}" [matTooltipPosition]="'above'" *ngIf="project.processing_download_time == 0" style="color: #2980b9;">
                {{"Preparing" | translate}}
            </span>
            <span #tooltip="matTooltip" matTooltip="{{ 'Project Stage' | translate }}" [matTooltipPosition]="'above'" *ngIf="project.processing_download_time != 0" style="color: #3867d6;">
                {{"Processing" | translate}}
            </span>
        </ng-container>
        <span #tooltip="matTooltip" matTooltip="{{ 'Project Stage' | translate }}" [matTooltipPosition]="'above'" *ngIf="project.is_ready" style="color: #27ae60;">{{"Ready" | translate}}</span>
        <span #tooltip="matTooltip" matTooltip="{{ 'Project Stage' | translate }}" [matTooltipPosition]="'above'" *ngIf="project.is_reset == 1" style="color: #ff5252;">{{"Is Reset" | translate}}</span>
        <span #tooltip="matTooltip" matTooltip="{{ 'Project Stage' | translate }}" [matTooltipPosition]="'above'" *ngIf="project.is_to_process" style="color: #f1c40f;">{{"To Process" | translate}}</span>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="'total_price_unit'">
      <div #tooltip="matTooltip" matTooltip="{{ 'Project Price Units' | translate }}" [matTooltipPosition]="'above'" *ngIf="project?.total_price_unit" [ngStyle]="{'color': variable.color, 'font-size': variable.fontSize+'px'}">{{project?.total_price_unit}}</div>
    </ng-container>
    <ng-container *ngSwitchCase="'processing_queue_at'">
      <div [ngStyle]="{'color': variable.color, 'font-size': variable.fontSize+'px'}" #tooltip="matTooltip" matTooltip="{{'Queue time'|translate}}: {{ project.processing_queue_at*1000 | date:'hh:mm, a':'':activeLang }}" [matTooltipPosition]="'above'">{{project.processing_queue_at | reportsDate:activeLang}}</div>
    </ng-container>
    <ng-container *ngSwitchCase="'processing_started_at'">
      <div [ngStyle]="{'color': variable.color, 'font-size': variable.fontSize+'px'}" #tooltip="matTooltip" matTooltip="{{'Processing start time'|translate}}: {{ project.processing_started_at*1000 | date:'hh:mm, a':'':activeLang }}" [matTooltipPosition]="'above'">{{project.processing_started_at | reportsDate:activeLang}}</div>
    </ng-container>
    <ng-container *ngSwitchCase="'processing_completed_at'">
      <div [ngStyle]="{'color': variable.color, 'font-size': variable.fontSize+'px'}" #tooltip="matTooltip" matTooltip="{{'Processing end time'|translate}}: {{ project.processing_completed_at*1000 | date:'hh:mm, a':'':activeLang }}" [matTooltipPosition]="'above'">{{project.processing_completed_at | reportsDate:activeLang}}</div>
    </ng-container>
    <ng-container *ngSwitchCase="'processing_time'">
      <div #tooltip="matTooltip" matTooltip="{{ 'How many seconds did it take' | translate }}" [matTooltipPosition]="'above'" [ngStyle]="{'color': variable.color, 'font-size': variable.fontSize+'px'}">{{project.processing_time | timeFormat}}</div>
    </ng-container>
    <ng-container *ngSwitchCase="'processing_download_time'">
        <div #tooltip="matTooltip" matTooltip="{{ 'Project Preparing' | translate }}" [matTooltipPosition]="'above'" [ngStyle]="{'color': variable.color, 'font-size': variable.fontSize+'px'}">{{project.processing_download_time | timeFormat}}</div>
    </ng-container>
  </ng-container>
</ng-template>