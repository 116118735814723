<h2 mat-dialog-title class="dialog_title">
    <span>
        {{ "Editing quest status" | translate }}: 
    </span>
    <button mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h2>
<mat-dialog-content class="mat-typography">
    <div class="container">
        <form [formGroup]="form">

            <div class="custom_radio">
                <label>{{ "Choose a status" | translate }}: </label>
                <mat-radio-group formControlName="application_status_id">
                  <mat-radio-button color="primary" *ngFor="let status of questStatuses" [value]="status.id">{{ status.name | translate }}</mat-radio-button>
                </mat-radio-group>
            </div>   
        
        </form>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-raised-button (click)="close()">
        {{ "Back" | translate }}
    </button>
    <button class="ml-1" (click)="onSubmit()" mat-raised-button color="primary" [disabled]="!form.valid">
        {{ "Edit" | translate }}
    </button>
</mat-dialog-actions>