import { Directive, ElementRef, HostListener, Input, OnDestroy } from '@angular/core';

interface MenuOptions {
  rowSelector: string;
  submenuSelector: string;
  submenuDirection: 'left' | 'right' | 'above' | 'below';
  tolerance: number;
  enter(): void;
  exit(): void;
  activate(row: HTMLElement): void;
  deactivate(row: HTMLElement): void;
  exitMenu(): boolean;
}

@Directive({
  selector: '[appMenuAim]'
})
export class MenuAimDirective implements OnDestroy {
  @Input() options: MenuOptions;

  private activeRow: HTMLElement | null = null;
  private mouseLocs: { x: number; y: number }[] = [];
  private lastDelayLoc: { x: number; y: number } | null = null;
  private timeoutId: ReturnType<typeof setTimeout> | null = null;

  constructor(private elementRef: ElementRef) {}

  ngOnDestroy(): void {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event: MouseEvent): void {
    this.mouseLocs.push({ x: event.pageX, y: event.pageY });

    if (this.mouseLocs.length > 3) {
      this.mouseLocs.shift();
    }
  }

  @HostListener('mouseleave')
  onMouseLeave(): void {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }

    if (this.options.exitMenu()) {
      if (this.activeRow) {
        this.options.deactivate(this.activeRow);
      }

      this.activeRow = null;
    }
  }

  @HostListener('click', ['$event.target'])
  onClick(target: HTMLElement): void {
    this.activate(target);
  }

  @HostListener('mouseenter', ['$event.target'])
  onMouseEnter(target: HTMLElement): void {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }

    this.options.enter();

    this.possiblyActivate(target);
  }

  @HostListener('mouseleave', ['$event.target'])
  onMouseLeaveRow(target: HTMLElement): void {
    this.options.exit();
  }

  private activate(row: HTMLElement): void {
    if (row === this.activeRow) {
      return;
    }

    if (this.activeRow) {
      this.options.deactivate(this.activeRow);
    }

    this.options.activate(row);
    this.activeRow = row;
  }

  private possiblyActivate(row: HTMLElement): void {
    const delay = this.activationDelay();

    if (delay) {
      this.timeoutId = setTimeout(() => {
        this.possiblyActivate(row);
      }, delay);
    } else {
      this.activate(row);
    }
  }

  private activationDelay(): number {
    if (!this.activeRow || !this.activeRow.matches(this.options.submenuSelector)) {
      return 0;
    }

    const offset = this.elementRef.nativeElement.getBoundingClientRect();
    const { x, y } = this.mouseLocs[this.mouseLocs.length - 1];
    const prevLoc = this.mouseLocs[0];

    if (!prevLoc) {
      return 0;
    }

    if (prevLoc.x < offset.left || prevLoc.x > offset.left + offset.width ||
      prevLoc.y < offset.top || prevLoc.y > offset.top + offset.height) {
      return 0;
    }

    if (this.lastDelayLoc && x === this.lastDelayLoc.x && y === this.lastDelayLoc.y) {
      return 0;
    }

    const decreasingCorner = { x: offset.left + offset.width, y: offset.top - this.options.tolerance };
    const increasingCorner = { x: offset.left + offset.width, y: offset.top + offset.height + this.options.tolerance };

    let decreasingSlope = this.slope({ x, y }, decreasingCorner);
    let increasingSlope = this.slope({ x, y }, increasingCorner);
    let prevDecreasingSlope = this.slope(prevLoc, decreasingCorner);
    let prevIncreasingSlope = this.slope(prevLoc, increasingCorner);

    if (this.options.submenuDirection === 'left') {
      decreasingSlope = this.slope({ x, y }, { x: offset.left, y: offset.top + offset.height + this.options.tolerance });
      increasingSlope = this.slope({ x, y }, { x: offset.left, y: offset.top - this.options.tolerance });
      prevDecreasingSlope = this.slope(prevLoc, { x: offset.left, y: offset.top + offset.height + this.options.tolerance });
      prevIncreasingSlope = this.slope(prevLoc, { x: offset.left, y: offset.top - this.options.tolerance });
    } else if (this.options.submenuDirection === 'above') {
      decreasingSlope = this.slope({ x, y }, { x: offset.left + offset.width + this.options.tolerance, y: offset.top });
      increasingSlope = this.slope({ x, y }, { x: offset.left - this.options.tolerance, y: offset.top });
      prevDecreasingSlope = this.slope(prevLoc, { x: offset.left + offset.width + this.options.tolerance, y: offset.top });
      prevIncreasingSlope = this.slope(prevLoc, { x: offset.left - this.options.tolerance, y: offset.top });
    } else if (this.options.submenuDirection === 'below') {
      decreasingSlope = this.slope({ x, y }, { x: offset.left - this.options.tolerance, y: offset.top + offset.height });
      increasingSlope = this.slope({ x, y }, { x: offset.left + offset.width + this.options.tolerance, y: offset.top + offset.height });
      prevDecreasingSlope = this.slope(prevLoc, { x: offset.left - this.options.tolerance, y: offset.top + offset.height });
      prevIncreasingSlope = this.slope(prevLoc, { x: offset.left + offset.width + this.options.tolerance, y: offset.top + offset.height });
    }

    if (decreasingSlope < prevDecreasingSlope && increasingSlope > prevIncreasingSlope) {
      this.lastDelayLoc = { x, y };
      return 300;
    }

    this.lastDelayLoc = null;
    return 0;
  }

  private slope(a: { x: number; y: number }, b: { x: number; y: number }): number {
    return (b.y - a.y) / (b.x - a.x);
  }
}
