import { NgxMatDateAdapter } from '@angular-material-components/datetime-picker';
import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import * as moment from 'moment';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { fromEvent, ReplaySubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, tap } from 'rxjs/operators';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MY_FORMATS } from '../../atTasksDialog/task-profile-add/task-profile-add.component';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';

@Component({
  selector: 'app-add-and-edit-scheduled-post',
  templateUrl: './add-and-edit-scheduled-post.component.html',
  styleUrls: ['./add-and-edit-scheduled-post.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class AddAndEditScheduledPostComponent extends BaseClass implements OnInit, OnDestroy {
  public pagination: any;
  public imgRoute: any;
  public page: number = 1;

  public contentPagination: any;
  public contentPage: number = 1;

  public form: FormGroup;

  public timeZone = - new Date().getTimezoneOffset();
  public isSubmit: boolean = false;
  public submited: boolean = false;
  public isFormChange: boolean = false;

  public channels: any[] = [];
  public channelMoreControl: FormControl = new FormControl();
  public channels$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public contentTypes: any[] = [];
  public contentTypeMoreControl: FormControl = new FormControl();
  public contentTypes$: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddAndEditScheduledPostComponent>,
    private fb: FormBuilder,
    private companyService: CompanyService,
    private layoutService: LayoutService,
    private sm: StorageManagerService,
    private _ngx_adapter: NgxMatDateAdapter<any>,
  ) { super() }

  ngOnInit(): void {

    console.log("AddAndEditScheduledPostComponent", this.data)
    this.getImgRoute();
    if (!this.data.post) {
      // add
      this.form = this.fb.group({
        company_id: this.data.company.id,
        channel_id: ['', Validators.required],
        content_type_id: ['', Validators.required],
        name: ['', Validators.required],
        publish_at: [this.data.val && this.data.val.date ? moment(this.showUserDate(false, moment(this.data.val.date).format("X")) * 1000) : '', Validators.required]
      })
    } else {
      // edit
      this.form = this.fb.group({
        company_id: this.data.post.company_id,
        channel_id: [this.data.post.channel_id, Validators.required],
        content_type_id: [this.data.post.content_type_id, Validators.required],
        name: [this.data.post.name, Validators.required],
        publish_at: [moment(this.showUserDate(false, this.data.post.publish_at) * 1000), Validators.required]
      })
    }

    
    this._ngx_adapter.setLocale(this.data.activeLang);

    this.attachSubscriptions(
      this.form.get("channel_id").valueChanges.subscribe(resp => {
        console.log('"channel_id" valueChanges', resp)
        if (!!resp) {
          let platform_id = this.channels.find(x => x.id == resp).platform_id
          this.contentPage = 1;
          this.contentTypes = [];
          this.contentTypes$.next(this.contentTypes.slice());
          this.getContentTypes(platform_id, this.contentPage);
        }
      })
    )

    this.getChannels(this.data.company.id, this.page);


    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        if (this.isFormChange) {
          this.layoutService.openBottomSheet(this.dialogRef);
        } else {
          this.close();
        }
      })
    )


    this.attachSubscriptions(
      this.channelMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchChannels(resp))
    )

    this.attachSubscriptions(
      this.contentTypeMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchContentTypes(resp))
    )

  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  onSearchChannels(resp) {
    if (!this.channels) {
      return;
    }

    if (!resp) {
      this.channels$.next(this.channels.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.channels$.next(
      this.channels.filter(z => (z.name.toLowerCase().indexOf(resp) > -1))
    );
  }

  onSearchContentTypes(resp) {
    if (!this.contentTypes) {
      return;
    }

    if (!resp) {
      this.contentTypes$.next(this.contentTypes.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.contentTypes$.next(
      this.contentTypes.filter(z => (z.name.toLowerCase().indexOf(resp) > -1))
    );
  }

  getChannels(company_id, page) {
    this.attachSubscriptions(
      this.companyService.getProfiles(company_id, page).pipe(
        tap(el => {
          this.pagination = {
            'pageCount': el.headers.get('x-pagination-page-count'),
            'perPage': el.headers.get('x-pagination-per-page'),
            'totalCount': el.headers.get('x-pagination-total-count'),
            'currentPage': el.headers.get('x-pagination-current-page'),
          }
        }),
        map(el => el.body)
      ).subscribe(resp => {
        this.channels.push(...resp);
        this.channels$.next(this.channels.slice());
        this.page = this.page + 1;

        console.log("getChannels", this.contentPage, this.contentTypes)
        if (this.pagination.pageCount >= this.page) {
          this.getChannels(this.data.company.id, this.page);
        } else {
          if (!!this.data.post && !!this.data.post.content_type_id && this.channels.filter(x => x.id == this.data.post.channel_id).length > 0) {
            let platform_id = this.channels.find(x => x.id == this.data.post.channel_id).platform_id
            this.getContentTypes(platform_id, this.contentPage);
          }
        }
      })
    )
  }

  selectType(type) {
    this.form.patchValue({
      content_type_id: !!type.id ? type.id : type.value
    })
  }

  getContentTypes(platform_id, contentPage) {
    this.attachSubscriptions(
      this.companyService.getContentTypes(platform_id, contentPage).pipe(
        tap(el => {
          this.contentPagination = {
            'pageCount': el.headers.get('x-pagination-page-count'),
            'perPage': el.headers.get('x-pagination-per-page'),
            'totalCount': el.headers.get('x-pagination-total-count'),
            'currentPage': el.headers.get('x-pagination-current-page'),
          }
        }),
        map(el => el.body)
      ).subscribe(resp => {
        this.contentTypes.push(...resp);
        this.contentTypes$.next(this.contentTypes.slice());
        this.contentPage = this.contentPage + 1;
        console.log("getContentTypes", this.contentPage, this.contentTypes)

        if (this.contentPagination.pageCount >= this.contentPage) {
          this.getContentTypes(platform_id, this.contentPage);
        }
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  showUserDate(date:any = false, timestamp:any = false) {
    let timeAndDate;
    if (date) {
      timeAndDate = moment(date).clone()
    } else {
      timeAndDate = moment(timestamp*1000).clone()
    }

    const utcOffset = moment.tz(timeAndDate, this.data.company.timezone).utcOffset();
    
    let utcTimestamp = moment(timeAndDate).format("X")
    
    let difference = (Number(this.timeZone) - Number(utcOffset))*60
    console.log(difference);
    
    let workSpaceTimeZone;
    if (date) {
      workSpaceTimeZone = Number(utcTimestamp) + Number(difference)
    } else {
      workSpaceTimeZone = Number(utcTimestamp) - Number(difference)
    }
    return workSpaceTimeZone
  }

  onSubmit() {
    if (!this.form.valid) {
      this.submited = true;
      this.layoutService.showSnackBar({name: ''}, marker("You need to fill in all required fields"), SnackBarItem)
      return
    }

    this.submited = false;
    this.isSubmit = true;

    console.log(this.form.value)
    let timeAndDate = this.form.value.publish_at.clone()

    const utcOffset = moment.tz(timeAndDate, this.data.company.timezone).utcOffset();
    console.log(utcOffset);

    
    let utcTimestamp = moment(timeAndDate).format("X")
    console.log(utcTimestamp);
    
    let difference = (Number(this.timeZone) - Number(utcOffset))*60
    console.log("difference", difference);
    
    let workSpaceTimeZone = Number(utcTimestamp) + Number(difference)
    console.log("workSpaceTimeZone", workSpaceTimeZone);

    let sbmtData = {...this.form.value}

    sbmtData.publish_at = workSpaceTimeZone

    if (!this.data.post) {
      this.attachSubscriptions(
        this.companyService.addScheduledPost(sbmtData).subscribe(resp => {
          this.dialogRef.close({event: "add", data: resp})
        })
      )
    } else {
      this.attachSubscriptions(
        this.companyService.editScheduledPost(this.data.post.id, sbmtData).subscribe(resp => {
          this.dialogRef.close({event: "edit", data: resp})
        })
      )
    }
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

}
