<mat-dialog-content class="mat-typography">
    <mat-tab-group [@.disabled]="true" [selectedIndex]="activeIndex" (selectedIndexChange)="changeTab($event)">
        <mat-tab *ngIf="data.selectedChats">
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon mr-1">badge</mat-icon>
                {{ "Assign executor" | translate }}
            </ng-template>
            
            <form [formGroup]="form" class="multi_tab_form form_with_pads form_with_valids">

                <div class="users_select" [ngClass]="{'req_value': !form.get('employee_id').valid}">
                    <label>{{ "Choose an executor" | translate }}: </label>
                    <mat-chip-list [multiple]="true" selectable formControlName="employee_id">
                        <ng-container *ngFor="let employee of employees; let i = index">
                            <mat-chip class="chip_user" (click)="toggleChip(employee.id, 'employee_id')" [value]="employee.id" [selected]="form.value.employee_id && form.value.employee_id.includes(employee.id)">
                                <app-user [class]="'user_div'" [employee]="employee"></app-user>
                            </mat-chip>
                            <mat-chip *ngIf="i == employees.length - 1 && i > 6" class="chip_user more" (click)="employeeAddAdditionalContainer.open()">
                                {{ "more" | translate }}
                            </mat-chip>
                        </ng-container>
                    </mat-chip-list>
                </div>
    
                <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                    <mat-select [multiple]="true" #employeeAddAdditionalContainer formControlName="employee_id">
                        <mat-option>
                            <ngx-mat-select-search 
                            placeholderLabel="{{ 'Search' | translate }}" 
                            noEntriesFoundLabel="{{ 'no matching executor found' | translate }}"
                            [formControl]="employeeMoreControl"></ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let employee of employees$ | async" [value]="employee.id">
                            <app-user [class]="'user_div'" [employee]="employee"></app-user>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
    
                <div class="users_select" *ngIf="partners?.length > 0 && !!form.get('partner_company_id')">
                    <label>{{ "Choose a partner" | translate }}: </label>
                    <mat-chip-list [multiple]="true" selectable formControlName="partner_company_id">
                        <ng-container *ngFor="let partner of partners; let i = index">
                            <mat-chip class="chip_user" (click)="toggleChip(partner.partner_company_id, 'partner_company_id')" [value]="partner?.partner_company_id" [selected]="form.value.partner_company_id && form.value.partner_company_id.includes(partner?.partner_company_id)">
                                <app-user [class]="'user_div'" [companyEmployee]="partner?.partnerCompany" [imgRoute]="imgRoute" [host]="host" [company]="data?.company"></app-user>
                            </mat-chip>
                            <mat-chip *ngIf="i == partners.length - 1 && i > 6" class="chip_user more" (click)="partnerAddAdditionalContainer.open()">
                                {{ "more" | translate }}
                            </mat-chip>
                        </ng-container>
                    </mat-chip-list>
                </div>
    
                <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                    <mat-select [multiple]="true" #partnerAddAdditionalContainer formControlName="partner_company_id">
                        <mat-option>
                            <ngx-mat-select-search 
                            placeholderLabel="{{ 'Search' | translate }}" 
                            noEntriesFoundLabel="{{ 'no matching partner found' | translate }}"
                            [formControl]="partnerMoreControl"></ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let partner of partners$ | async" [value]="partner?.partner_company_id">
                            <app-user [class]="'user_div'" [companyEmployee]="partner?.partnerCompany" [imgRoute]="imgRoute" [host]="host" [company]="data?.company"></app-user>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
    
                <div class="small_chips" *ngIf="operationStatuses?.length > 0">
                    <ng-container *ngIf="!form.get('status_id').valid && submited">
                        <div class="req_field"></div>
                        <div class="req_label">
                            <div class="angle"></div>
                            <span>
                                {{ "You need to choose a value" | translate }}
                            </span>
                        </div>
                    </ng-container>
                    <label>{{ "Status" | translate }}: </label>
                    <mat-chip-list [multiple]="false" selectable formControlName="status_id">
                        <ng-container *ngFor="let status of operationStatuses">
                            <mat-chip class="small_chip" (click)="selectStatus(status)" [value]="status?.id" [ngClass]="{
                                'first': status.id == '1',
                                'second': status.id == '2',
                                'third': status.id == '3',
                                'fours': status.id == '4',
                                'fives': status.id == '5',
                                'sixs': status.id == '6',
                                'nine': status.id == '97',
                                'seven': status.id == '98',
                                'eight': status.id == '99'}">
                                {{ status.name | translate }}
                            </mat-chip>
                        </ng-container>
                    </mat-chip-list>
                </div>
    
                <p style="padding: 20px 0;">
                    {{ "Choose member role" | translate }}:
                    <mat-button-toggle-group appearance="legacy" class="ml-1" formControlName="is_manager" [value]="form.get('is_manager').value">
                        <mat-button-toggle [value]="1">{{ "Executor" | translate }}</mat-button-toggle>
                        <mat-button-toggle [value]="0">{{ "Assistant" | translate }}</mat-button-toggle>
                    </mat-button-toggle-group>
                </p>
            </form>
        </mat-tab>
      
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon mr-1">delete</mat-icon>
                {{ "Delete executors" | translate }}
            </ng-template>
            <form [formGroup]="deleteForm" class="multi_tab_form form_with_pads form_with_valids">

                <div class="box_wrp">
                    <mat-checkbox color="primary" formControlName="assistant">{{ "Including Assistants" | translate }}</mat-checkbox>
                </div>
                <div class="box_wrp">
                    <mat-checkbox color="primary" formControlName="executor">{{ "Including Executors" | translate }}</mat-checkbox>
                </div>
                
                <div class="users_select" *ngIf="employeesAssignedEarlyer && employeesAssignedEarlyer.length" [ngClass]="{'req_value': !deleteForm.get('employee_id').valid}">
                    <label>{{ "Select executors you want to remove" | translate }}:</label>
                    <mat-chip-list class="without_ordered" [multiple]="true" selectable formControlName="employee_id">
                        <ng-container *ngFor="let employee of employeesAssignedEarlyer; let i = index">
                            <mat-chip class="chip_user" (click)="toggleChip(employee.employee_id, 'employee_id', 'deleteForm')" [value]="employee.employee_id" [selected]="deleteForm.value.employee_id && deleteForm.value.employee_id.includes(employee.employee_id)">
                                <app-user [class]="'user_div'" [employee]="employee.employee"></app-user>
                            </mat-chip>
                        </ng-container>
                    </mat-chip-list>
                </div>
                <div class="users_select" *ngIf="partnersAssignedEarlyer && partnersAssignedEarlyer.length" [ngClass]="{'req_value': !deleteForm.get('employee_id').valid}">
                    <label>{{ "Select executors you want to remove" | translate }}:</label>
                    <mat-chip-list class="without_ordered" [multiple]="true" selectable formControlName="partner_company_id">
                        <ng-container *ngFor="let partner of partnersAssignedEarlyer; let i = index">
                            <mat-chip class="chip_user" (click)="toggleChip(partner.partner_company_id, 'partner_company_id', 'deleteForm')" [value]="partner.partner_company_id" [selected]="deleteForm.value.partner_company_id && deleteForm.value.partner_company_id.includes(partner.partner_company_id)">
                                <app-user [class]="'user_div'" [companyEmployee]="partner?.partnerCompany" [imgRoute]="imgRoute" [host]="host" [company]="data?.company"></app-user>
                            </mat-chip>
                        </ng-container>
                    </mat-chip-list>
                </div>
            </form>
        </mat-tab>
    </mat-tab-group>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <div class="f_w_p_btns">
        <button mat-raised-button (click)="close()">
            {{ "Cancel" | translate }}
        </button>
        <ng-container *ngIf="activeIndex.value == 0 && data.selectedChats; else elseTemplateTabIndex">
            <button class="ml-1 d-f-btn" (click)="onAssign()" mat-raised-button cdkFocusInitial color="primary" [disabled]="isSubmit">
                <mat-spinner class="spin_16 mr-1" *ngIf="isSubmit"></mat-spinner>
                {{ "Assign" | translate }}
            </button>
        </ng-container>
        <ng-template #elseTemplateTabIndex>
            <button class="ml-1 d-f-btn" (click)="onDelete()" mat-raised-button cdkFocusInitial color="primary" [disabled]="isSubmit">
                <mat-spinner class="spin_16 mr-1" *ngIf="isSubmit"></mat-spinner>
                {{ "Delete" | translate }}
            </button>
        </ng-template>
    </div>
</mat-dialog-actions>