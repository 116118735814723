import { NgxMatDateAdapter } from '@angular-material-components/datetime-picker';
import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import * as moment from 'moment';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { forkJoin, fromEvent, of, ReplaySubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, last, map, switchMap, tap } from 'rxjs/operators';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MY_FORMATS } from 'src/app/components/atTasksDialog/task-profile-add/task-profile-add.component';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';

@Component({
  selector: 'app-select-group-for-reg',
  templateUrl: './select-group-for-reg.component.html',
  styleUrls: ['./select-group-for-reg.component.scss']
})

export class SelectGroupForRegComponent extends BaseClass implements OnInit, OnDestroy {
  public isSubmit: boolean = false;
  public submited: boolean = false;
  public isFormChange: boolean = false;

  public form: FormGroup = this.fb.group({
    group_id: ''
  })

  public groups: any[] = [];
  public groupsMoreControl: FormControl = new FormControl();
  public groups$: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<SelectGroupForRegComponent>,
    private fb: FormBuilder,
    private sm: StorageManagerService,
    private companyService: CompanyService,
    private taskService: TaskService,
    private layoutService: LayoutService,
    private _ngx_adapter: NgxMatDateAdapter<any>,
  ) { super() }

  ngOnInit(): void {
    console.log("SelectGroupForRegComponent", this.data)
    this.getGroups();

    this.attachSubscriptions(
      this.form.valueChanges.subscribe(() => this.isFormChange = true)
    )

    this.attachSubscriptions(
      this.groupsMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchGroups(resp))
    )

    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        if (this.isFormChange) {
          this.layoutService.openBottomSheet(this.dialogRef);
        } else {
          this.close();
        }
      })
    )

    if (this.data.item) {
      this.form.patchValue({
        group_id: this.data.item.group_id
      })
    } else {
      if (this.data.schedule) {
        this.form.patchValue({
          group_id: this.data.schedule.group_id
        })
      }
    }
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  onSearchGroups(resp) {
    if (!this.groups) {
      return;
    }

    if (!resp) {
      this.groups$.next(this.groups.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }

    // filter the banks
    this.groups$.next(
      this.groups.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  getGroups() {
    this.attachSubscriptions(
      this.companyService.getInfiniteGroupsCompany(this.data.company.id, '1', '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.companyService.getInfiniteGroupsCompany(this.data.company.id, x).pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        this.groups = [].concat(...res);
        this.groups$.next(this.groups.slice());
      })
    )
  }
  
  onSubmit() {
    this.submited = false;
    this.isSubmit = true;

    if (this.data.item) {
      this.attachSubscriptions(
        this.companyService.editRegularItem(this.data.item.id, this.form.value).subscribe(resp => {
          this.data.item.group_id = resp.group_id;
          this.close();
          this.isSubmit = false;
        }, error => {
          this.isSubmit = false;
          this.layoutService.showSnackBar({name: ''}, error, SnackBarItem);
        })
      )
    } else {
      this.attachSubscriptions(
        this.companyService.editRegular(this.data.schedule.id, this.form.value).subscribe(resp => {
          this.data.schedule.group_id = resp.group_id;
          this.close();
          this.isSubmit = false;
        }, error => {
          this.isSubmit = false;
          this.layoutService.showSnackBar({name: ''}, error, SnackBarItem);
        })
      )

    }
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

}
