import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { BaseClass } from 'src/app/shared/models/base-class';

@Component({
  selector: 'app-mob-sort-filter',
  templateUrl: './mob-sort-filter.component.html',
  styleUrls: ['./mob-sort-filter.component.scss']
})

export class MobSortFilterComponent extends BaseClass implements OnInit, OnDestroy {
  public sortValue: any;
  public filter: FormGroup = this.fb.group({
    order_by: []
  });
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<MobSortFilterComponent>,
  ) { super() }

  ngOnInit(): void {
    console.log("MobSortFilterComponent", this.data)

    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        this.close();
      })
    )

    switch (this.activeSections()) {
      case 'Cards':
        this.sortValue = this.activatedRoute.snapshot.queryParamMap.get('order_by') ? [this.activatedRoute.snapshot.queryParamMap.get('order_by')] : ['updated_desc']
        this.filter.patchValue({
          order_by: this.activatedRoute.snapshot.queryParamMap.get('order_by') ? [this.activatedRoute.snapshot.queryParamMap.get('order_by')] : ['updated_desc']
        })
        break;
      case 'Jobs':
        this.sortValue = this.activatedRoute.snapshot.queryParamMap.get('order_by') ? [this.activatedRoute.snapshot.queryParamMap.get('order_by')] : ['updated_desc']
        this.filter.patchValue({
          order_by: this.activatedRoute.snapshot.queryParamMap.get('order_by') ? [this.activatedRoute.snapshot.queryParamMap.get('order_by')] : ['updated_desc']
        })
        break;
      case 'Chats':
        this.sortValue = this.activatedRoute.snapshot.queryParamMap.get('order_by') ? [this.activatedRoute.snapshot.queryParamMap.get('order_by')] : ['updated_desc']
        this.filter.patchValue({
          order_by: this.activatedRoute.snapshot.queryParamMap.get('order_by') ? [this.activatedRoute.snapshot.queryParamMap.get('order_by')] : ['updated_desc']
        })
        break;
      case 'Posts':
        this.sortValue = this.activatedRoute.snapshot.queryParamMap.get('order_by') ? [this.activatedRoute.snapshot.queryParamMap.get('order_by')] : ['content_published_at_desc']
        this.filter.patchValue({
          order_by: this.activatedRoute.snapshot.queryParamMap.get('order_by') ? [this.activatedRoute.snapshot.queryParamMap.get('order_by')] : ['content_published_at_desc']
        })
        break;
    
      default:
        break;
    }
  }

  activeSections() {
    switch (window.location.pathname) {
      case '/dashboard':
        return 'Dashboard'
        break;
      case '/tasks':
        return 'Cards'
        break;
      case '/jobs':
        return 'Jobs'
        break;
      case '/chats':
        return 'Chats'
        break;
      case '/board':
        return 'Board by Date'
        break;
      case '/board-list':
        return 'Board by Order'
        break;
      case '/urls':
        return 'Posts'
        break;
      case '/urls-dashboard':
        return 'Post Dashboards'
        break;
      case '/outlets-dashboard':
        return 'Outlet Dashboards'
        break;
      case '/settings':
        return 'Settings'
        break;
      case '/files':
        return 'Files'
        break;
      default:
        return window.location.pathname.replace('/', '')
        break;
    }
  }

  close() {
    this.dialogRef.removePanelClass('animate__slideInUp');
    this.dialogRef.addPanelClass('animate__slideOutDown');
    setTimeout(()=>{this.dialogRef.close({event: 'close'})}, 300);
  }

  onSubmit() {
    this.dialogRef.removePanelClass('animate__slideInUp');
    this.dialogRef.addPanelClass('animate__slideOutDown');
    setTimeout(()=>{this.dialogRef.close({event: 'apply', data: {order_by: this.filter.value.order_by[0]}})}, 300);
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

}
