import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { SnackBarItem } from '../../../snack-bar/snack-bar-item';
import { PushNotificationsService } from 'src/app/shared/services/rest/push-notifications.service';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-mob-chat-options',
  templateUrl: './mob-chat-options.component.html',
  styleUrls: ['./mob-chat-options.component.scss']
})
export class MobChatOptionsComponent extends BaseClass implements OnInit, OnDestroy {
  
  public origin = window.location.origin;
  public notEmployees: any;
  public selectedNotEmployees: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<MobChatOptionsComponent>,
    private pushNotificationsService: PushNotificationsService,
    private layoutService: LayoutService
  ) { super() }

  ngOnInit(): void {
    console.log("MobChatOptionsComponent", this.data)
    this.getNotificationEmployees();
  }

  close(type?) {
    this.dialogRef.removePanelClass('animate__slideInUp')
    this.dialogRef.addPanelClass('animate__slideOutDown')
    setTimeout(()=>{this.dialogRef.close({data: type})}, 300);
  }

  getNotificationEmployees() {
    this.attachSubscriptions(
      this.pushNotificationsService.getNotificationEmployees(this.data.company.id, this.data.chat.id).pipe(
        tap(x => {
          x.forEach(el => {
            if (el.notificationSubscription == null) {
              el.notificationSubscription = {
                company_id: el.company_id,
                employee_id: el.id,
                discussion_id: this.data.chat.id,
                id: 0,
                is_webpush: 0
              }
            }
          })
        })
      ).subscribe(resp => {
        console.log("getNotificationEmployees", resp);
        this.notEmployees = resp;
        this.selectedNotEmployees = resp.filter(x => x.notificationSubscription && x.notificationSubscription.is_webpush == 1);
      })
    )
  }

  copyLink(type) {
    this.layoutService.showSnackBar({name: marker(type + " link")}, marker("Copied"), SnackBarItem)
  }

  checkIsManager(task, company, _user) {
    return task && company && task.company_id == company.employees[0].company_id && (task?.managers.filter(x => x.user_id == _user.id).length > 0 || task?.group?.managers.filter(x => x.user_id == _user.id).length > 0 || company?.permissions.includes('admin') || company?.permissions.includes('manager')); 
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
