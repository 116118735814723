
<div>
  <markdown [data]="markdownText"></markdown>

  <hr>
  <markdown appHighlight [isMarkdown]="true" [data]="markdownText2"></markdown>

  <div id='chat' [style.top.px]='y'  
  [style.left.px]='x' 
  [style.width.px]='width'
  [style.minHeight.px]='height'
  (mousedown)='onWindowPress($event)'
  (mousemove)='onWindowDrag($event)'>
  <div>
    <span>x: </span> <span>{{x}}</span>
  </div>
  <div>
    <span>y: </span> <span>{{y}}</span>
  </div>
  <div>
    <span>px: </span> <span>{{px}}</span>
  </div>
  <div>
    <span>py: </span> <span>{{py}}</span>
  </div>
  <div>
    <span>width: </span> <span>{{width}}</span>
  </div>
  <div>
    <span>height: </span> <span>{{height}}</span>
  </div>
  <div>
    <span>draggingCorner: </span> <span>{{draggingCorner}}</span>
  </div>
  <div>
    <span>draggingWindow: </span> <span>{{draggingWindow}}</span>
  </div>
<div (mousedown)='onCornerClick($event, topLeftResize)' id='chat-top-left-resize' class='chat-corner-resize'></div>
<div (mousedown)='onCornerClick($event, topRightResize)' id='chat-top-right-resize' class='chat-corner-resize'></div>
<div (mousedown)='onCornerClick($event, bottomLeftResize)' id='chat-bottom-left-resize' class='chat-corner-resize'></div>
<div (mousedown)='onCornerClick($event, bottomRightResize)' id='chat-bottom-right-resize' class='chat-corner-resize'></div>
</div>
  <style>
    @import url('https://fonts.googleapis.com/css2?family=Sankofa+Display&display=swap');
    @import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
    @import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
  </style>
  <div #content contenteditable="true">
    <h1 style="color: red; font-family: Roboto,sans-serif; background: #42cd4b69;">Hello World This is Condensed Title</h1>
    <p style="text-decoration: underline; font-family: 'Sankofa Display', sans-serif;" >This is a test with underline. font-family: "Sankofa Display", sans-serif;</p>
    <p style="font-style: italic; font-size: 20px;">This is italic font style.</p>
    <h3 style="text-shadow: 5px 5px #558ABB;">Text with SHADOW</h3>
    <h3 style="text-shadow: 1px 1px 2px red, 0 0 1em blue, 0 0 0.2em blue; color: red;">Text with Other SHADOW</h3>
    <p style="font-family: 'Arial', serif; -webkit-text-stroke: 1.70926px rgb(0, 0, 0); font-size: 44.4407px; color: rgb(102, 12, 161); font-style: normal; font-weight: normal; top: 188px; left: 40px; width: 422px; height: 623px;">
      Lorem Ipsum - эт 12; 
      - используемый в 2;
      и вэб-дизайне.
      Lorem Ipsum явля...
      стандартной рыбой
    </p>
  </div> 
  <div class="d-f-c">
    <button (click)="convertHtmlToSvg()">Convert HTML to SVG</button>
    <button *ngIf="base64Svg" (click)="downloadSvg()">Download SVG</button>

    <span style="margin: 0 10px;"> | </span>
    
    <button (click)="convertToPNG()">Конвертировать в PNG</button>
  </div>
  <div *ngIf="base64Svg">
    <div>
      display the HTML as an SVG
    </div>
    <div>
      <style>
        @import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
      </style>
      <img [src]="safeSvgUrl" alt="Converted SVG" />
    </div>
  </div>
  <div *ngIf="pngUrl">
    <div>
      display the HTML as an PNG
    </div>
    <div class="pl_out">
      <img class="video_img" src="https://media1.thrillophilia.com/filestore/8sm83n8xgs1l5dtsjpusrxrqxck1_D6BYJJKRQIQAFFGO6UUPOB7FYQ.jpg" alt="">
      <img class="sub_img" [src]="pngUrl" alt="Converted PNG" />
    </div>
  </div>
</div>

<hr style="margin: 25px 0;">
<div class="elem">
    <app-draw></app-draw>
</div>
<div class="elem">
    <app-drawer></app-drawer>
</div>

<div appMenuAim [options]="menuOptions" class="menu">
    <ul class="menu-list">
      <li class="menu-item">Menu Item 1
        <ul class="submenu">
          <li class="submenu-item">Submenu Item 1</li>
          <li class="submenu-item">Submenu Item 2</li>
          <li class="submenu-item">Submenu Item 3</li>
        </ul>
      </li>
      <li class="menu-item">Menu Item 2
        <ul class="submenu">
          <li class="submenu-item">Submenu Item 4</li>
          <li class="submenu-item">Submenu Item 5</li>
          <li class="submenu-item">Submenu Item 6</li>
        </ul>
      </li>
      <li class="menu-item">Menu Item 3
        <ul class="submenu">
          <li class="submenu-item">Submenu Item 7</li>
          <li class="submenu-item">Submenu Item 8</li>
          <li class="submenu-item">Submenu Item 9</li>
        </ul>
      </li>
    </ul>
</div>
  
  
  
<hr>
  
  

<mat-form-field *ngIf="testArray && testArray.length">
	<mat-label>Select an option</mat-label>
	<mat-select [(ngModel)]="selected" [multiple]="true" #cstSelect>
        <div class="custom-panel">
            <mat-option *ngFor="let item of testArray" [value]="item?.id">{{item?.name}}</mat-option>
        </div>
        <div class="select_footer">
			<button mat-raised-button>Ok</button>
			<button mat-button (click)="cstSelect.close()">Close</button>
		</div>
	</mat-select>
</mat-form-field>
<h1 (click)="log()">Drag&Drop with a flex-wrap</h1>
<div class="chat_timestamp">
    <ng-container>
        <span class="silver">{{(testTime | fullTime:false:true:true).silverTime}}</span>
        <span class="black">{{(testTime | fullTime:false:true:true).blackTime}}</span>
    </ng-container>
</div>

<button (click)="add()">Add</button> <button (click)="shuffle()">Shuffle</button><br/>
<ul class="example-list"><li *ngFor="let item of items">{{ item }}</li></ul>

<div class="example-container" cdkDropListGroup #partGroup>
    <div class="cst_drag_placeholder"
    #partPlaceholder
    cdkDropList 
    (cdkDropListEntered)="entered($event, partGroup, partPlaceholder)" 
    (cdkDropListDropped)="dropped(partGroup, partPlaceholder, items)">
    </div>

    <div *ngFor="let item of items" 
    cdkDropList
    (cdkDropListEntered)="entered($event, partGroup, partPlaceholder)"
    (cdkDropListDropped)="dropped(partGroup, partPlaceholder, items)">
        <div cdkDrag (cdkDragReleased)="dragReleased(partPlaceholder, partDragPlaceholder)" class="example-box">
            <div class="sorted_chip_custom_placeholder" #partDragPlaceholder *cdkDragPlaceholder></div>
            {{item}}
        </div>
    </div>
</div> 


<audio id="audio_test" controls [src]="host + audioFile.original + '?company_id=18'" (loadedmetadata)="loadedAudio($event)"></audio>
<svg style="height: 100px; width: 800px" id="waveformSvg" width="800" height="100"></svg>

<hr>

<!-- <audio id="audio_test" controls src="../../../assets/audio/Песня - Була в мене парова машина.mp3" (loadedmetadata)="loadedAudio($event)"></audio> -->
<!-- <canvas id="waveformCanvas" width="800" height="100"></canvas> -->


<audio id="audio_test_0" controls src="../../../assets/audio/Песня - Була в мене парова машина.mp3" (loadedmetadata)="loadedAudioZero($event)"></audio>
<svg style="height: 100px; width: 800px" id="waveformSvg_0" width="800" height="100"></svg>