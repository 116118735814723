import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'reportsDate'
})
export class ReportsDate implements PipeTransform {
    transform(timestamp: string, activeLang: any) {
        if (!timestamp || timestamp == "") {
            return ''
        }

        let lang = activeLang || 'en'

        if (moment(+timestamp*1000).isSameOrAfter(moment().startOf('d')) && moment(+timestamp*1000).isSameOrBefore(moment().endOf('d'))) {
            return moment(+timestamp*1000).locale(lang).format('hh:mm a');
        } else if (moment(+timestamp*1000).isSameOrAfter(moment().startOf('y')) && moment(+timestamp*1000).isSameOrBefore(moment().endOf('y'))) {
            return moment(+timestamp*1000).locale(lang).format('MMM DD');
        } else {
            return moment(+timestamp*1000).locale(lang).format("MMM DD, Y");
        }
    }
}