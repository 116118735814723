import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { AuthService } from 'src/app/shared/services/rest/auth.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { MY_FORMATS } from '../task-profile-add/task-profile-add.component';
import * as moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/en-gb';
import 'moment/locale/uk';
import 'moment/locale/ru';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { BaseClass } from 'src/app/shared/models/base-class';
import { CreateOperationChatComponent } from '../create-operation-chat/create-operation-chat.component';
import { WorkToApproveComponent } from 'src/app/shared/global_components/work-to-approve/work-to-approve.component';
import { OperationChatsComponent } from '../operation-chats/operation-chats.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { prioritys } from 'src/app/shared/consts/prioritys';
import { MembersService } from 'src/app/shared/services/rest/members.service';
import { debounceTime, map, switchMap, tap } from 'rxjs/operators';
import { of, ReplaySubject } from 'rxjs';
import { ScenariosService } from 'src/app/shared/services/rest/scenarios.service';
import { environment } from 'src/environments/environment';
import { NgxMatDateAdapter } from '@angular-material-components/datetime-picker';
@Component({
  selector: 'app-work-edit',
  templateUrl: './work-edit.component.html',
  styleUrls: ['./work-edit.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class WorkEditComponent extends BaseClass implements OnInit, OnDestroy {
  public host: any = environment.host;
  public imgRoute: any = '';
  public user_id: number;
  public isFormChange: boolean = false;
  public form: FormGroup;
  public workEmpl: FormGroup;
  public templateForm: FormGroup;
  public company_id: any;
  public task_id: any;
  public work_id: any;
  public templates: any;
  public automations: any;
  public noChat: boolean = false;
  public showPart: boolean = false;
  public operations: any;
  public partners: any;
  public prioritys: any = prioritys;
  public operationStatuses: any;
  public employees: any;
  public numberRegEx = /\d*\.?\d{1,2}/;
  public isSubmit: boolean = false;
  public disableTemplate: boolean = false;
  public showStatuses: boolean = false;
  public submited: boolean = false;
  public operationMoreControl: FormControl = new FormControl();
  public operations$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public templatesMoreControl: FormControl = new FormControl();
  public templates$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public saveVal: any;

  // public partnerMoreControl: FormControl = new FormControl();
  // public partners$: ReplaySubject<any> = new ReplaySubject<any>(1);
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<WorkEditComponent>,
    private sm: StorageManagerService,
    private auth: AuthService,
    private companyService: CompanyService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private bottomSheet: MatBottomSheet,
    private taskService: TaskService,
    private _adapter: DateAdapter<any>,
    private _ngx_adapter: NgxMatDateAdapter<any>,
    private languageService: LanguageService,
    private layoutService: LayoutService,
    private membersService: MembersService,
    private scenariosService: ScenariosService,
  ) { super() }

  ngOnInit(): void {
    window.scroll(0,0);

    console.log(this.data);

    this.company_id = this.data.company.id;
    this.task_id = this.data.task.id;
    this.work_id = this.data.work.id;

    this.getCompany();
    this.getImgRoute();
    this.getCsrf();

    this.form = this.fb.group({
      operation_id: ['', Validators.required],
      status_id: ['', Validators.required],
      name: '',
      comment: '',
      priority: 0,
      private_comment: '',
      employee_comment: '',
      completed_at: '',
      planned_completed_at: '',
      date: this.fb.group({
        time: '',
        day: ''
      })
    })

    this.attachSubscriptions(
      this.form.get('date').valueChanges.subscribe(resp => {
        console.log(resp)
        let dateForm = {...this.form.get("date").value}
        if (!dateForm.day) {
          dateForm.day = moment(0).hour(0).minute(0)
        }
        let testTimeAndDate = dateForm.day.clone()
        testTimeAndDate.hour(Number(dateForm.time.split(":")[0]))
        testTimeAndDate.minute(Number(dateForm.time.split(":")[1]))
        this.form.patchValue({
          planned_completed_at: testTimeAndDate
        })
        console.log('this.form.value', this.form.value);
        console.log('testTimeAndDate', testTimeAndDate.clone().format("X"));
      })
    )

    this.templateForm = this.fb.group({
      company_id: this.company_id,
      task_operation_id: this.data.work.id,
      template_id: []
    })

    this.workEmpl = this.fb.group({
      rate: [0, [Validators.required, Validators.pattern(this.numberRegEx)]],
      price: [0, [Validators.required, Validators.pattern(this.numberRegEx)]],
    })

    this.attachSubscriptions(
      this.operationMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchOperations(resp))
    )

    // this.attachSubscriptions(
    //   this.partnerMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchPartners(resp))
    // )

    this.getOperations();
    this.getOperationsStatus();
    this.getEmployees();
    this.getPartners();
    if (this.data.work?.employee?.employee_id) {
      this.workEmpl.patchValue({
        rate: this.data.work.employee.rate,
        price: this.data.work.employee.price,
      })
    }

    this.getWork();


    this.getLangData();
    this.getJobScenarios();
    this.getAutomationTemplates();
    this.attachSubscriptions(
      this.templatesMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchTemplates(resp))
    )
  }

  getAutomationTemplates() {
    this.attachSubscriptions(
      this.scenariosService.getAutomationTemplates('1', {company_id: this.data.company.id, is_task_operation: 1}, '200').pipe(
        map(el => el.body)
      ).subscribe(resp => {
        console.log("getAutomationTemplates", resp);
        this.templates = resp;
        this.templates$.next(this.templates.slice());
      })
    )
  }

  toggleChipTemplate = (chip: any) => {
    console.log(chip);
    // console.log(chip.source.selected);
    // console.log(chip.source.value);
    if (!!this.templateForm.value.template_id && this.templateForm.value.template_id.includes(chip.id)) {
      let val = [...this.templateForm.value.template_id]
      val.splice(this.templateForm.value.template_id.indexOf(chip.id), 1)
      this.templateForm.patchValue({
        template_id: val
      })

      this.disableTemplate = true;
      let id = this.automations.find(x => x.template_id == (chip.id)).id
      this.attachSubscriptions(
        this.scenariosService.deleteAutomationScenario(id).subscribe(resp => {
          this.automations.splice(this.automations.indexOf(this.automations.find(x => x.template_id == (chip.id))), 1)
          this.disableTemplate = false;
        })
      )
    } else {
      if (!!this.templateForm.value.template_id) {
        let val = [...this.templateForm.value.template_id]
        val.push(chip.id)
        this.templateForm.patchValue({
          template_id: val
        })
      } else {
        this.templateForm.patchValue({
          template_id: [chip.id]
        })
      }
      this.disableTemplate = true;
      this.attachSubscriptions(
        this.scenariosService.addAutomationScenario({
          company_id: this.company_id,
          task_operation_id: this.data.work.id,
          template_id: chip.id
        }, this.company_id).pipe(
          switchMap(res => {
            return this.scenariosService.tryScenario(res.id).pipe(tap(result => res.tryData = result), map(() => res))
          }),
        ).subscribe(resp => {
          console.log("addAutomationScenario", resp);
          this.automations.push(resp);
          this.disableTemplate = false;
        })
      )
    }
  }

  templateChange(e) {
    console.log(this.saveVal);
    console.log(e.value);
    this.disableTemplate = true;
    if (this.saveVal.length > e.value.length) {
      let templateId;

      this.saveVal.forEach(el => {
        if (!e.value.includes(el)) {
          templateId = el
        }
      });
      this.saveVal = e.value;

      let id = this.automations.find(x => x.template_id == templateId).id
      
      this.attachSubscriptions(
        this.scenariosService.deleteAutomationScenario(id).subscribe(resp => {
          this.automations.splice(this.automations.indexOf(this.automations.find(x => x.template_id == templateId)), 1)
          this.disableTemplate = false;
        })
      )
    } else {
      let templateId;

      e.value.forEach(el => {
        if (!this.saveVal.includes(el)) {
          templateId = el
        }
      });
      this.saveVal = e.value;
      this.attachSubscriptions(
        this.scenariosService.addAutomationScenario({
          company_id: this.company_id,
          task_operation_id: this.data.work.id,
          template_id: templateId
        }, this.company_id).pipe(
          switchMap(res => {
            return this.scenariosService.tryScenario(res.id).pipe(tap(result => res.tryData = result), map(() => res))
          }),
        ).subscribe(resp => {
          console.log("addAutomationScenario", resp);
          this.automations.push(resp);
          this.disableTemplate = false;
        })
      )
    }
  }

  onSearchTemplates(resp) {
    if (!this.templates) {
      return;
    }

    if (!resp) {
      this.templates$.next(this.templates.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.templates$.next(
      this.templates.filter(z => z.name.toLowerCase().indexOf(resp) > -1)
    );
  }
  // onPartnerImgError(event){
  //   event.target.src = this.imgRoute+'/assets/img/partner.png'
  // }

  // onSearchPartners(resp) {
  //   if (!this.partners) {
  //     return;
  //   }

  //   if (!resp) {
  //     this.partners$.next(this.partners.slice());
  //     return;
  //   } else {
  //     resp = resp.toLowerCase();
  //   }
  //   // filter the banks
  //   this.partners$.next(
  //     this.partners.filter(z => z.partnerCompany.name.name.toLowerCase().indexOf(resp) > -1)
  //   );
  // }

  getJobScenarios() {
    this.attachSubscriptions(
      this.scenariosService.getAutomationScenarios(this.data.company.id, {task_operation_id: this.data.work.id, 'per-page': 200}).pipe(
        map(el => el.body)
      ).subscribe(resp => {
        this.automations = resp;
        console.log('getJobScenarios', resp)
        let ids = []
        resp.forEach(element => {
          ids.push(element.template_id)
        });
        this.templateForm.patchValue({
          template_id: ids
        })

        this.saveVal = ids;
        console.log('getJobScenarios', this.templateForm.value)
      })
    )
  }

  getPartners() {
    this.attachSubscriptions(
      this.companyService.getPartners({company_id: this.data.company.id}).subscribe(resp => {
        this.partners = resp;
        // this.partners$.next(this.partners.slice());
      })
    )
  }

  selectStatus(status) {
    console.log(status.id)
    if (status.id == 3) {
      this.form.addControl("completed_at", this.fb.control(''));
      this.form.get("completed_at").updateValueAndValidity();
    } else if (!!this.form.get('completed_at')) {
      this.form.removeControl("completed_at");
      this.form.updateValueAndValidity();
    }
    this.form.patchValue({
      status_id: status.id
    })
  }

  onSearchOperations(resp) {
    if (!this.operations) {
      return;
    }

    if (!resp) {
      this.operations$.next(this.operations.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.operations$.next(
      this.operations.filter(z => (z.name.toLowerCase().indexOf(resp) > -1))
    );
  }


  selectPrioritys = (priority: any) => {
    this.form.patchValue({
      priority: priority.id
    })
  }

  showMoreStatuses() {
    this.showStatuses = true;
  }

  selectType(type) {
    this.form.patchValue({
      operation_id: type.id || type.value
    })
  }

  // togglePartnerChip = (chip: any) => {
  //   this.form.patchValue({
  //     partner_company_id: chip.partner_company_id
  //   })
  // }

  showMorePart() {
    this.showPart = true;
  }

  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData().subscribe(resp => {
        this._ngx_adapter.setLocale(resp.active);
        this._adapter.setLocale(resp.active);
      })
    )
  }
  
  getCompany() {
    this.attachSubscriptions(
      this.companyService.getCompany(this.company_id).subscribe(resp => {
        this.companyService.company$.next(resp[0]);
      })
    )
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  getCsrf() {
    this.attachSubscriptions(
      this.auth.$userToken.subscribe(resp => {
        this.user_id = resp.user_id;
        this.sm.localStorageSetItem("csrf_param", resp.csrf_param)
        this.sm.localStorageSetItem("csrf_token", resp.csrf_token)
      })
    )
  }

 
  getEmployees() {
    this.attachSubscriptions(
      this.taskService.getEmployees(this.company_id).subscribe(resp => {
        this.employees = resp;
      })
    )
  }

  changeStatus(event) {
    if (this.operationStatuses[event.value - 1].is_lock == 1) {
      if (this.data.work.wage_updated_user_id != 0 && this.data.work.wage_updated_user_id != this.data.user.id) {
        let item = {...this.data.work};
        item.name = this.operations.find(el => el.id == this.data.work.operation_id).name
        this.layoutService.showSnackBar(item, marker("The performer changed the rate and price. Check this data!"), SnackBarItem)
      }
    }
  }
  
  getOperations() {
    this.attachSubscriptions(
      this.taskService.getOperations(this.data.company.id).subscribe(resp => {
        this.operations = resp;
        this.operations$.next(this.operations.slice())
      })
    )
  }

  getOperationsStatus() {
    this.attachSubscriptions(
      this.taskService.getOperationsStatus().subscribe(resp => {
        this.operationStatuses = resp.slice();
      })
    )
  }

  numberToTimeFormat(val) {
    if (Number(val) < 10) {
      val = "0" + val
    }
    return val
  }

  getWork() {
    this.attachSubscriptions(
      this.taskService.getWork(this.work_id).subscribe(resp => {

        // partner_company_id: resp.partner_company_id,
        this.form.patchValue({
          operation_id: resp.operation_id,
          status_id: resp.status_id,
          name: resp.name,
          comment: resp.comment,
          priority: !!resp.priority ? resp.priority : 0,
          private_comment: resp.private_comment,
          employee_comment: resp.employee_comment,
          completed_at: resp.completed_at != "0" ? moment(resp.completed_at*1000) : '',
          planned_completed_at: resp.planned_completed_at != "0" ? moment(resp.planned_completed_at*1000) : ''
        })

        this.form.get('date').patchValue({
          day: resp.planned_completed_at ? moment(resp.planned_completed_at * 1000).hour(0).minute(0) : '',
          time: resp.planned_completed_at ? (this.numberToTimeFormat(moment(resp.planned_completed_at * 1000).hours()) + ":" + this.numberToTimeFormat(moment(resp.planned_completed_at * 1000).minutes())) : '',
        })

        this.attachSubscriptions(
          this.form.valueChanges.subscribe(() => this.isFormChange = true)
        )

        this.attachSubscriptions(
          this.form.controls.status_id.valueChanges.subscribe(value => {
            console.log(value);
            if (value == 5 && this.data.company && this.data.company.permissions.includes('create_discussion')) {
              this.noChat = true;
              const createChat = this.dialog.open(CreateOperationChatComponent, {
                disableClose: true,
                data: {
                  is_create_chat_component: true,
                  company: this.data.company,
                  task: this.data.task,
                  work: this.data.work,
                  tasks: this.data.tasks,
                  user: this.data.user,
                  empl_status: this.data.company.permissions.includes('owner') || this.data.company.permissions.includes('admin') || this.data.company.permissions.includes('manager')
                }
              });
    
              this.attachSubscriptions(
                createChat.afterClosed().subscribe(result => {
                  if (!!result && result.event == 'create') {
                    this.noChat = false;
                  } else {
                    this.form.patchValue({
                      status_id: ''
                    })
                  }
                })
              )
            } else {
              this.noChat = false;
            }
          })
        )
    
        this.attachSubscriptions(
          this.dialogRef.backdropClick().subscribe(e => {
            e.preventDefault();
            if (this.isFormChange) {
              this.layoutService.openBottomSheet(this.dialogRef);
            } else {
              this.close();
            }
          })
        )
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  onSubmit() {
    if (!this.form.valid || !this.workEmpl.valid) {
      this.submited = true;
      this.layoutService.showSnackBar({name: ''}, marker("You need to fill in all required fields"), SnackBarItem)
      return
    }

    this.submited = false;
    this.isSubmit = true;
    let data = this.form.value;
    let dataEmpl = this.workEmpl.value;

    if (String(dataEmpl['rate']).indexOf(",") != -1) {
      dataEmpl['rate'] = dataEmpl['rate'].replace(",", ".");
    }
    if (String(dataEmpl['price']).indexOf(",") != -1) {
      dataEmpl['price'] = dataEmpl['price'].replace(",", ".");
    }

    if (dataEmpl.price == null) {
      dataEmpl.price = "0";
    }
    if (dataEmpl.rate == null) {
      dataEmpl.rate = "0";
    }

    if (!!data.completed_at && !!data.completed_at._d) {
      data.completed_at = moment(data.completed_at._d).format("X");
      dataEmpl.completed_at = data.completed_at
    } else {
      delete data.completed_at;
    }

    if (!!data.planned_completed_at && !!data.planned_completed_at._d) {
      data.planned_completed_at = moment(data.planned_completed_at._d).format("X");
    } else {
      delete data.planned_completed_at;
    }
    delete data.date;
    
    dataEmpl.status_id = data.status_id

    console.log("DATA", data)

    if (this.data.work.unClosedChats > 0 && data.status_id == 4) {
      let workToApprove =  this.bottomSheet.open(WorkToApproveComponent, {
        data: {
          work: this.data.work
        }
      });

      workToApprove.afterDismissed().subscribe( result => {
        if (result && result.message == 'show') {
          console.log("show");
          const dialogRef = this.dialog.open(OperationChatsComponent, {
            data: {
              tasks: this.data.tasks,
              company: this.data.company,
              task: this.data.task,
              work: this.data.work,
              user: this.data.user,
              empl_status: this.data.empl_status,
              host: this.host,
              operationsValues: this.data.operationsValues
            }
          });

          this.attachSubscriptions(
            dialogRef.afterClosed().subscribe(result => {
            })
          )
          
        } if (result && result.message == 'approve') {
          this.data.work.unClosedChats = 0;
          this.data.work.unReadChats = 0;
          this.attachSubscriptions(
            this.taskService.editWork(this.work_id, data, this.data.company.id).pipe(
              switchMap((x) => {
                if (!!this.data.work?.employee?.employee_id) {
                  return this.membersService.editMember(this.data.work?.employee.id, dataEmpl, this.data.company.id)
                  // .pipe(
                  //   switchMap(y => {
                  //     if (this.data.work?.employeeStatus) {
                  //       return this.membersService.editTaskEmployeeStatus(this.data.work?.employeeStatus.id, {status_id: this.form.value.status_id})
                  //     } else {
                  //       return this.membersService.addTaskEmployeeStatus(Object.assign({status_id: this.form.value.status_id}, {
                  //         company_id: this.data.company.id,
                  //         task_id: this.data.work.task_id,
                  //         task_operation_id: this.data.work.id,
                  //         employee_id: this.data.work?.employee.employee_id,
                  //       }))
                  //     }
                  //   }),
                  //   map(() => x)
                  // )
                } else {
                  return of(x)
                }
              })
            ).subscribe(resp => {
              this.dialogRef.close({event: "update", data: resp})
            })
          )
        }
      });
      return false;
    }

    console.log("DATA", data)

    this.attachSubscriptions(
      this.taskService.editWork(this.work_id, data, this.data.company.id).pipe(
        switchMap((x) => {
          if (!!this.data.work?.employee?.employee_id) {
            return this.membersService.editMember(this.data.work?.employee.id, dataEmpl, this.data.company.id)
            // .pipe(
            //   switchMap(y => {
            //     if (this.data.work?.employeeStatus) {
            //       return this.membersService.editTaskEmployeeStatus(this.data.work?.employeeStatus.id, {status_id: this.form.value.status_id})
            //     } else {
            //       return this.membersService.addTaskEmployeeStatus(Object.assign({status_id: this.form.value.status_id}, {
            //         company_id: this.data.company.id,
            //         task_id: this.data.work.task_id,
            //         task_operation_id: this.data.work.id,
            //         employee_id: this.data.work?.employee.employee_id,
            //       }))
            //     }
            //   }),
            //   map(() => x)
            // )
          } else {
            return of(x)
          }
        })
      ).subscribe(resp => {
        this.isSubmit = false;
        this.dialogRef.close({event: "update", data: resp})
      })
    )
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
