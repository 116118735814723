import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { BaseClass } from 'src/app/shared/models/base-class';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { UsersService } from 'src/app/shared/services/rest/users.service';

@Component({
  selector: 'app-payment-info',
  templateUrl: './payment-info.component.html',
  styleUrls: ['./payment-info.component.scss']
})
export class PaymentInfoComponent extends BaseClass implements OnInit {

  public isSubmit: boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PaymentInfoComponent>,
    private taskService: TaskService,
    private activatedRoute: ActivatedRoute,
    private usersService: UsersService,
    private fb: FormBuilder,
  ) { super() }

  ngOnInit(): void {
    console.log(this.data);
  }

  getCurrency(id) {
    return this.data.currencyList.filter(x => x.id == id).length > 0 ? this.data.currencyList.find(x => x.id == id) : false 
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }
  
  // submitForm() {
  //   this.isSubmit = true;
  //   this.attachSubscriptions(
  //     this.taskService.createTaskType(this.data.form.value).subscribe(resp => {
  //       console.log(resp);
  //       this.data.taskTypes.push(resp)
  //       this.isSubmit = false;
  //       this.close()
  //     })
  //   )
  // }

  submitForm() {
    this.isSubmit = true;
    if (String(this.data.form.value['rate_target']).indexOf(",") != -1) {
      this.data.form.value['rate_target'] = this.data.form.value['rate_target'].replace(",", ".");
    }

    this.attachSubscriptions(
      this.usersService.createUser(this.data.virtual.value, this.activatedRoute.snapshot.queryParamMap.get('company_id')).pipe(
        switchMap(x => {
          this.data.form.patchValue({
            vuser_id: x.id
          })
          return this.taskService.addVuser(this.data.form.value)
        })
        ).subscribe(resp => {
          this.isSubmit = false;
          this.data.form.reset();
          this.data.form.patchValue({
            company_id: this.data.company.id,
            currency_id: 0
          })
          this.data.virtual.reset();
          this.dialogRef.close({event: "add"})
      })
    
    )
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

}
