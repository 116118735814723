
<div class="reports_dash">
    <form class="reports_dash_header">
        <div class="head_tit">
            {{ "Imports" | translate }}
        </div>
    </form>

    <div class="reports_wrp" [appDeviceClass]="'custom-scroll custom-scroll-t'" #tableContainer>
        <div class="report" *ngFor="let item of imports; let i = index">
            <div class="report_line">
                <div class="column report_col">
                    <span class="big">
                        {{ item?.name }}
                    </span>
                    <button class="show_on_hover" style="color: #A3A3A3;" mat-icon-button [matMenuTriggerFor]="menuBig" aria-label="Example icon-button with a menu">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menuBig="matMenu">
                        <button mat-menu-item (click)="editImport(item)">
                            <mat-icon>edit</mat-icon>
                            <span>{{"Edit"| translate}}</span>
                        </button>
                        <button mat-menu-item (click)="deleteImport(item, i)">
                            <mat-icon>delete</mat-icon>
                            <span>{{"Delete"| translate}}</span>
                        </button>
                    </mat-menu>
                </div>
                <div class="column report_col">
                    <span class="normal cp is_active" (click)="changeImport(item)" [ngClass]="{'green': item.is_active == 1}">
                        {{ item.is_active == 1 ? 'Active' : "Not active" | translate }}
                    </span>
                </div>
                <div class="column report_col">
                    <div class="error_el" *ngIf="item.is_error" #tooltip="matTooltip" matTooltip="{{ !!user?.is_root ? item.error_message : '' }}" [matTooltipPosition]="'above'">
                        <mat-icon style="color: #c0392b;">warning</mat-icon>
                    </div>
                </div>
                <div class="column report_col">
                    <span #tooltip="matTooltip" matTooltip="{{ 'Update period (h)' | translate }}" [matTooltipPosition]="'above'" *ngIf="item.update_every">{{item.update_every/3600}}h</span>
                    <span *ngIf="!item.update_every">{{item.update_every}}</span>
                </div>
                <div class="column report_col">
                    <span *ngIf="item.file_url"#tooltip="matTooltip" matTooltip="{{ 'File URL' | translate }}" [matTooltipPosition]="'above'" style="font-size: 12px;">{{item.file_url}}</span>
                </div>
                <div class="column report_col">
                    <span *ngIf="item.timezone" #tooltip="matTooltip" matTooltip="{{ 'Timezone' | translate }}" [matTooltipPosition]="'above'" style="font-size: 12px;">{{item.timezone}}</span>
                </div>
                <div class="column report_col">
                    <span *ngIf="item.imported_at" #tooltip="matTooltip" matTooltip="{{ 'Imported at' | translate }}" [matTooltipPosition]="'above'" style="font-size: 12px;">{{item.imported_at | reportsDate:activeLang}}</span>
                </div>
                <div class="column report_col expand">
                    <!-- <button mat-icon-button style="color: #686868;" (click)="expandItem(item)">
                        <mat-icon>
                            {{ item.isOpen ? 'expand_less' : 'expand_more' }}
                        </mat-icon>
                    </button> -->
                </div>
            </div>
            <!-- <div class="param_values report_sub_lines" *ngIf="item.isOpen">
                <div class="item_val">
                    <div>host:</div>
                    <div>{{item.host}}</div>
                </div>
  
                <div class="item_val">
                    <div>Username:</div>
                    <div>{{item.username}}</div>
                </div>
                <div class="item_val">
                    <div>Port:</div>
                    <div>{{item.port}}</div>
                </div>
                <div class="item_val">
                    <div>Folder:</div>
                    <div>{{item.reports_directory}}</div>
                </div>
                <div class="item_val red" *ngIf="item.last_error_message">
                    <div>Error:</div>
                    <div>{{item.last_error_message}}</div>
                </div>

                <div class="reports_add_line" style="margin-top: 15px;">
                    <div class="add_line" (click)="addSftpChannel(item)">
                        <mat-icon>add_circle</mat-icon>
                        <span class="ml-1">{{"Add Channel" | translate}}</span>
                    </div>

                    <div class="count_channs ml-1" *ngIf="!!item?.valuesCount">
                        {{item?.valuesCount}} {{"channels"|translate}}
                    </div>
                </div>
                <div class="param_value report_sub_line" *ngFor="let val of item.values; let k = index;">
                    <div class="sub_line">
                        <div class="column report_col">
                            <span class="normal">
                                <app-outlet-line [channel]="val.channel" [company_id]="company_id"></app-outlet-line>
                            </span>
                            <button class="show_on_hover" style="color: #A3A3A3;" mat-icon-button [matMenuTriggerFor]="menuSmall" aria-label="Example icon-button with a menu">
                                <mat-icon>more_vert</mat-icon>
                            </button>
                            <mat-menu #menuSmall="matMenu">
                                <button mat-menu-item (click)="deleteSftpChannel(item, val, k)">
                                    <mat-icon>delete</mat-icon>
                                    <span>{{"Delete"| translate}}</span>
                                </button>
                            </mat-menu>
                        </div>
                        <div class="column report_col">
                    
                        </div>
                        <div class="column report_col">
                  
                        </div>
                        <div class="column report_col expand">
                
                        </div>
                    </div>
                </div>

            </div> -->
        </div>
    </div>
</div>

<div style="padding: 10px 0;">
    <a mat-raised-button (click)="goBack($event)">
        {{ "Back" | translate }}
    </a>

    <button class="add_btn ml-1" mat-raised-button color="primary" (click)="addImport($event)">
        <mat-icon>add</mat-icon>
        {{ "Import" | translate }}
    </button>
</div>