import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { AuthService } from 'src/app/shared/services/rest/auth.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

import * as moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/en-gb';
import 'moment/locale/uk';
import 'moment/locale/ru';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { BaseClass } from 'src/app/shared/models/base-class';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { catchError, map, retry, switchMap, tap } from 'rxjs/operators';
import { of, throwError, forkJoin, fromEvent } from 'rxjs';
import { GlobalDataService } from 'src/app/shared/services/common/global-data.service';
import { NgxMatDateAdapter } from '@angular-material-components/datetime-picker';
import { DeleteAlertComponent } from 'src/app/shared/global_components/delete-alert/delete-alert.component';
import { AddTaskProfileComponent } from '../add-task-profile/add-task-profile.component';
import { OpenTaskComponent } from '../../open-task.component';
import * as shape from 'd3-shape';
import SwiperCore, { Pagination,  Mousewheel } from 'swiper/core';
import { environment } from 'src/environments/environment';

SwiperCore.use([Mousewheel, Pagination]);

export const MY_FORMATS = {
  parse: {
    dateInput: 'LLLLL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
export const MY_NEW_FORMATS = {
  parse: {
    dateInput: 'MM/DD/YYYY',
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'MM/DD/YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
}

@Component({
  selector: 'app-mob-url-analytics',
  templateUrl: './mob-url-analytics.component.html',
  styleUrls: ['./mob-url-analytics.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_NEW_FORMATS},
  ]
})

export class MobUrlAnalyticsComponent extends BaseClass implements OnInit, OnDestroy {
  public view: any[] = [180, 81];
  public viewArea: any[] = [210, 152];
  public filter: FormGroup = this.fb.group({
    created_at_from: '',
    created_at_to: '',
  })

  public totalRevenue:number = 0
  public totalAdRevenue:number = 0
  public totalRedRevenue:number = 0
  
  public lastTimeData:number = 0
  public timeTicks:any = [0]
  public timeYScaleMax:number = 0
  public areaTicks:any = [0]
  public areaYScaleMax:number = 0
  public timeTicksV2:any = [0]
  public timeYScaleMaxV2:number = 0
  public areaTicksV2:any = [0]
  public areaYScaleMaxV2:number = 0
  public areaTicksRev:any = [0]
  public areaYScaleMaxRev:number = 0
  public areaTicksPerc:any = [0]
  public areaYScaleMaxPerc:number = 0
  public areaTicksDur:any = [0]
  public areaYScaleMaxDur:number = 0
  public summaryViews: number = 0
  public summaryV2Views: number = 0
  public summaryAveragePercentageViewed: number = 0
  public summaryAverageViewDuration: number = 0
  public averagePercentageViewed: number = 0
  public averageViewDuration: number = 0
  public upViews: number = 0
  public upV2Views: number = 0
  public upPercViewedViews: number = 0
  public upDurViewedViews: number = 0

  public curve = shape.curveMonotoneX;
  public currDate = moment().minutes(0).seconds(0)

  public today = moment().set({hour:0,minute:0,second:0}).unix();
  public timeCols = [
    {
      id: 0,
      name: 'Last 24 hours',
      value: 24
    },
    {
      id: 1,
      name: 'Last 48 hours',
      value: 48
    },
    {
      id: 2,
      name: 'Last 72 hours',
      value: 72
    }
  ]
  
  public statsFilter = [
    {
      created_at_from: moment().subtract(7, 'd').startOf("day").format("X"),
      created_at_to: moment().endOf("day").format("X")
    },
    {
      created_at_from: moment().subtract(30, 'd').startOf("day").format("X"),
      created_at_to: moment().endOf("day").format("X")
    }
  ]
  public filters = [
    {
      id: 0,
      name: "Last 7 days",
      divider: false,
      needDataFromTimeChart: true,
      val: {
        start_time_from: moment().subtract(7, 'd').startOf("day").format("X"),
        content_id: this.data.profile.external_content_id,
        period: 'day',
        content_type: 'youtube'
      }
    }
  ]
  // options
  public showLabels: boolean = true;
  public animations: boolean = true;
  public xAxis: boolean = true;
  public yAxis: boolean = true;
  public showYAxisLabel: boolean = true;
  public showXAxisLabel: boolean = true;
  public xAxisLabel: string = 'Year';
  public yAxisLabel: string = 'Views';
  // public timeline: boolean = true;

  public colorScheme = {
    domain: ['#5AA454', '#2ea5cb', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5']
  };
  public revScheme = {
    domain: ['#074099']
  };
  public percScheme = {
    domain: ['#079992']
  };
  public durScheme = {
    domain: ['#7aa3e5', '#a8385d', '#aae3f5']
  };
  public colorTimeScheme = {
    domain: ['#2ea5cb']
  };
  public selectedHistoryItem: any;
  public profiles: any[] = [];
  public pagination: any;
  public selectedFile: any;
  public allHistory: any;
  public page: number = 1;
  public count: number = 1;
  public contentCount: number = 1;
  public host: any = environment.host;
  public imgRoute: any = '';
  public user: any = {};
  public user_id: number;

  public lastSeven: number;

  public durViewedFilter: FormControl = new FormControl(0);
  public percViewedFilter: FormControl = new FormControl(0);
  public selectFilter: FormControl = new FormControl(0);
  public selectV2Filter: FormControl = new FormControl(0);
  public selectRevFilter: FormControl = new FormControl(0);
  public selectTimeFilter: FormControl = new FormControl(1);
  public selectTimeV2Filter: FormControl = new FormControl(1);
  public lastMonth: number;

  public form: FormGroup;
  public company_id: any;
  public taskAvatar: any;
  public diffs: any;
  public task: any;
  public activeLang: any;
  public chartData: any = [];
  public chartDataV2: any = [];
  public chartDataRev: any = [];
  public percViewed: any = [];
  public durViewed: any = [];
  public timeChartData: any = [];
  public timeChartDataV2: any = [];
  public task_id: any;
  public selectedIndex: number = 0;
  public timeChartDataMaxVal: number = 0;
  public timeChartDataV2MaxVal: number = 0;
  public timeChartSumm: number = 0;
  public timeV2ChartSumm: number = 0;

  public isRefreshed: boolean = false;
  public is_mobile: boolean = false;
  public publichAt: any;

  public timeZone = - new Date().getTimezoneOffset();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<MobUrlAnalyticsComponent>,
    private sm: StorageManagerService,
    private auth: AuthService,
    private companyService: CompanyService,
    private globalDataService: GlobalDataService,
    private fb: FormBuilder,
    private bottomSheet: MatBottomSheet,
    private dialog: MatDialog,
    private taskService: TaskService,
    private languageService: LanguageService,
    private _ngx_adapter: NgxMatDateAdapter<any>,
    private _adapter: DateAdapter<any>,
    private layoutService: LayoutService
  ) {super()}

  ngOnInit(): void {
    this.dialogRef.addPanelClass("create_task_by_manager_modal")
    console.log("MobUrlAnalyticsComponent", this.data)

    this.publichAt = this.data.profile.content_published_at;
    
    this.diffs = {
      days: Math.trunc(Math.abs(moment.duration(moment(this.publichAt*1000).clone().diff(moment().endOf('day'))).asDays())),
      months: Math.trunc(Math.abs(moment.duration(moment(this.publichAt*1000).clone().diff(moment().endOf('day'))).asMonths())),
      years: Math.trunc(Math.abs(moment.duration(moment(this.publichAt*1000).clone().diff(moment().endOf('day'))).asYears()))
    }
    console.log("Math.trunc(Math.abs(moment.duration(moment(this.publichAt*1000).clone().diff(moment().endOf('day'))).asMonths()))", Math.trunc(Math.abs(moment.duration(moment(this.publichAt*1000).clone().diff(moment().endOf('day'))).asMonths())));
    console.log("Math.trunc(Math.abs(moment.duration(moment(this.publichAt*1000).clone().diff(moment().endOf('day'))).asYears()))", Math.trunc(Math.abs(moment.duration(moment(this.publichAt*1000).clone().diff(moment().endOf('day'))).asYears())));
    this.createFilter()

    this.company_id = this.data.company_id;
    this.task_id = this.data.task.id;

    this.form = this.fb.group({
      is_content_views_count_update : 0,
      is_bravourl_content_views_count_update : 0,
    })

    this.checkIsMobile();

    this.getTaskAvatar();

    this.dialogRef.backdropClick().subscribe(e => {
      e.preventDefault();
      this.close();
    });

    this.getCompany();
    this.getImgRoute();
    this.getCsrf();
    this.getUser();
    this.getProfiles(this.company_id, this.page);
    this.getLangData();
    
    if (this.data.is_edit) {
      this.getCounts();
      this.getContentHistory();
      this.getContentHistoryStats();
      this.getHoursChart();
      this.getDaysChart();
      this.getRevChart();
      this.getPercChart();
      this.getDurChart();
      
      this.form.patchValue({
        is_content_views_count_update: !!this.data.profile.is_content_views_count_update,
        is_bravourl_content_views_count_update: !!this.data.profile.is_bravourl_content_views_count_update,
      })
    }

    this.form.get('is_content_views_count_update').valueChanges.pipe(
      switchMap(val => {
        return this.taskService.editProfile(this.data.profile.id, {is_content_views_count_update: !!val ? 1 : 0}, this.company_id)
      })
    ).subscribe(res => {
      console.log(res)
      this.data.profile.is_content_views_count_update = res.is_content_views_count_update
    })

    this.form.get('is_bravourl_content_views_count_update').valueChanges.pipe(
      switchMap(val => {
        return this.taskService.editProfile(this.data.profile.id, {is_bravourl_content_views_count_update: !!val ? 1 : 0}, this.company_id)
      })
    ).subscribe(res => {
      console.log(res)
      this.data.profile.is_bravourl_content_views_count_update = res.is_bravourl_content_views_count_update
    })
  }

  getCounts() {
    let filterData:any = {
      start_time_from: this.data.profile.content_published_at,
      period: 'day',
      totals: '1',
      order_by: 'start_time',
      'per-page': 1,
      content_id: this.data.profile.external_content_id,
    }

    this.attachSubscriptions(
      this.taskService.getContentAnlytics(this.data.company.id, filterData, "1").pipe(
        tap(x => {
          this.summaryAveragePercentageViewed = x.headers.get('x-summary-average-view-percentage')
          this.summaryAverageViewDuration = x.headers.get('x-summary-average-view-duration')
        }),
      ).subscribe(resp => {
        console.log("getCounts",  resp)
      })
    )
  }

  createFilter() {

    if (this.diffs.days > 7) {
      this.filters.push({
          id: this.filters.length,
          name: "Last 30 days",
          divider: false,
          needDataFromTimeChart: true,
          val: {
            start_time_from: moment().subtract(30, 'd').startOf("day").format("X"),
            content_id: this.data.profile.external_content_id,
            period: 'day',
            content_type: 'youtube'
          }
        }
      )
    } else if (this.diffs.days <= 7) {
      this.filters[this.filters.length - 1].divider = true
    }

    if (this.diffs.days > 30) {
      this.filters.push({
        id: this.filters.length,
        name: "Last 90 days",
        divider: false,
        needDataFromTimeChart: true,
        val: {
          start_time_from: moment().subtract(90, 'd').startOf("day").format("X"),
          content_id: this.data.profile.external_content_id,
          period: 'day',
          content_type: 'youtube'
        }
      })
    } else if (this.diffs.days <= 30 && this.diffs.days > 7) {
      this.filters[this.filters.length - 1].divider = true
    }

    if (this.diffs.days > 90) {
      this.filters.push({
        id: this.filters.length,
        name: "Last 365 days",
        divider: true,
        needDataFromTimeChart: true,
        val: {
          start_time_from: moment().subtract(365, 'd').startOf("day").format("X"),
          content_id: this.data.profile.external_content_id,
          period: 'day',
          content_type: 'youtube'
        }
      })
    } else if (this.diffs.days <= 90 && this.diffs.days > 30) {
      this.filters[this.filters.length - 1].divider = true
    }


    function monthsBetweenDates (startDate, endDate) {
        const now = startDate, dates = [];
          
        while (now.isSameOrBefore(endDate)) {
              dates.push(now.clone());
              now.add(1, 'months');
        }
        return dates;
    }
    
    const fromDate = moment(this.publichAt * 1000).clone().startOf('month');
    const toDate = moment().endOf('month');
    const results = monthsBetweenDates(fromDate, toDate).reverse().slice(0,4);

    results.forEach((m, i) => {
      let x = {
        id: this.filters.length,
        name: m.clone().format('MMMM'),
        date: m.clone(),
        needDataFromTimeChart: false,
        divider: false,
        val: {
          start_time_from: m.startOf("month").format("X"),
          start_time_to: m.endOf("month").format("X"),
          content_id: this.data.profile.external_content_id,
          period: 'day',
          content_type: 'youtube'
        }
      }
      if (i == 0) {
        x.needDataFromTimeChart = true
      }
      
      if (i == results.length - 1) {
        x.divider = true
      }

      this.filters.push(x);
    })


    function yearsBetweenDates (startDate, endDate) {
      const now = startDate, years = [];
        
      while (now.isSameOrBefore(endDate)) {
            years.push(now.clone());
            now.add(1, 'year');
      }

      return years;
    }
    
    const fromDateYear = moment(this.publichAt * 1000).clone().startOf('year');
    const toDateYear = moment().endOf('year');
    const resultsYear = yearsBetweenDates(fromDateYear, toDateYear).reverse().slice(0,4);

    resultsYear.forEach((y, i) => {
      let x = {
        id: this.filters.length,
        name: y.clone().format('YYYY'),
        date: y.clone(),
        needDataFromTimeChart: false,
        divider: false,
        val: {
          start_time_from: y.startOf("year").format("X"),
          start_time_to: y.endOf("year").format("X"),
          content_id: this.data.profile.external_content_id,
          period: 'day',
          content_type: 'youtube'
        }
      }
      if (i == 0) {
        x.needDataFromTimeChart = true
      }
      
      if (i == resultsYear.length - 1) {
        x.divider = true
      }

      this.filters.push(x);
    })

    console.log("as____this.filters", this.filters)
  }

  getTaskAvatar() {
    this.attachSubscriptions(
      this.taskService.getTasksExpand(1, this.company_id, {id: this.data.profile.task_id}, '1').subscribe(resp => {
        console.log("getTaskAvatar", resp)

        if (resp.length) {
          this.task = resp[0]
          this.taskAvatar = resp[0].avatarFile
        }
      })
    )
  }

  getProfiles(company_id, page) {
    this.attachSubscriptions(
      this.companyService.getProfiles(company_id, page).pipe(
        tap(el => {
          this.pagination = {
            'pageCount': el.headers.get('x-pagination-page-count'),
            'perPage': el.headers.get('x-pagination-per-page'),
            'totalCount': el.headers.get('x-pagination-total-count'),
            'currentPage': el.headers.get('x-pagination-current-page'),
          }
        }),
        map(el => el.body)
      ).subscribe(resp => {
        console.log("getProfiles", resp)
        this.profiles.push(...resp);
        this.page = this.page + 1;
        if (this.page <= this.pagination['pageCount'] && this.pagination['pageCount'] >= 2) {
          this.getProfiles(this.company_id, this.page);
        }
      })
    )
  }


  unSelectHistoryItem() {
    this.selectedHistoryItem = undefined;
  }
  
  onSelect(data): void {
    console.log('Item clicked', JSON.parse(JSON.stringify(data)));
    console.log("this.timeChartData", this.timeChartData);
    console.log("this.timeChartDataV2", this.timeChartDataV2);
    if (this.data.company.permissions.includes("owner") || this.data.company.permissions.includes("manager")) {
      this.selectedHistoryItem = JSON.parse(JSON.stringify(data))
    }
  }

  onActivate(data): void {
    console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data): void {
    console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }

  selectChange(e) {
    console.log("selectChange", e)
    // this.getContentHistory();
    this.getContentAnlytics();
  }

  selectV2Change(e) {
    console.log("selectChange", e)
    // this.getContentHistory();
    this.getDaysChart();
  }

  
  selectRevChange(e) {
    console.log("selectChange", e)
    // this.getContentHistory();
    this.getRevChart();
  }

  percViewedChange(e) {
    console.log("selectChange", e)
    // this.getContentHistory();
    this.getPercChart();
  }
  
  durViewedChange(e) {
    console.log("selectChange", e)
    // this.getContentHistory();
    this.getDurChart();
  }

  selectTimeChange(e): void {
    this.getTimeHistory();
  }

  selectTimeV2Change(e): void {
    this.getHoursChart();
  }

  getPercChart() {
    let filterData:any = {
      start_time_from: this.filters[this.percViewedFilter.value].val.start_time_from,
      period: 'day',
      totals: '1',
      order_by: 'start_time',
      content_id: this.data.profile.external_content_id,
    }
    if (!!this.filters[this.percViewedFilter.value].val.hasOwnProperty("start_time_to")) {
      filterData.start_time_to = this.filters[this.percViewedFilter.value].val['start_time_to']
    }
    this.attachSubscriptions(
      this.taskService.getContentAnlytics(this.data.company.id, filterData, "1").pipe(
        switchMap(x => {
          this.averagePercentageViewed = x.headers.get('x-summary-average-view-percentage')
          console.log("observe getPercChart", x)
          // console.log("x.headers.get('x-pagination-page-count')", x.headers.get('x-pagination-page-count')) 
          if (x.headers.get('x-pagination-page-count') > 1) {
            return this.taskService.getContentAnlytics(this.data.company.id, filterData, "2").pipe(
              tap(b => {
                x.body.push(...b.body)
              }),
              map(() => x.body)
            )
          } else {
            return of(x.body)
          }
        }),
        tap(analytics => {
          for (let index = 0; index < analytics.length; index++) {
            let element = analytics[index];
            element.value = Math.round(element.average_view_percentage)
            element.date = moment(element.start_time * 1000).clone()
            if (moment(element.start_time * 1000).clone().isAfter(moment().hours(0).minutes(0).seconds(0)) || moment(element.start_time * 1000).clone().isSame(moment().hours(0).minutes(0).seconds(0))) {
              element.name = moment(element.start_time * 1000).clone().format("MM/DD")
            } else if (moment(element.start_time * 1000).clone().year() == moment().year()) {
              element.name = moment(element.start_time * 1000).clone().format("MM/DD")
            } else {
              element.name = moment(element.start_time * 1000).clone().format("MM/DD/YYYY")
            }
          }
        }),
      ).subscribe(resp => {

        console.log("getPercChart",  resp)
        if (resp.length > 0) {
          this.upPercViewedViews = resp[resp.length - 1].updated_at
        } else {
          this.upPercViewedViews = 0
        }

        this.percViewed = [...[{
          name: this.data.profile.content_name,
          series: resp
        }]]
        this.createAreaTicks(this.percViewed, 3);
        console.log("percViewed", this.percViewed)
      })
    )
  }

  getDurChart() {
    let filterData:any = {
      start_time_from: this.filters[this.durViewedFilter.value].val.start_time_from,
      period: 'day',
      totals: '1',
      order_by: 'start_time',
      content_id: this.data.profile.external_content_id,
    }
    if (!!this.filters[this.durViewedFilter.value].val.hasOwnProperty("start_time_to")) {
      filterData.start_time_to = this.filters[this.durViewedFilter.value].val['start_time_to']
    }
    this.attachSubscriptions(
      this.taskService.getContentAnlytics(this.data.company.id, filterData, "1").pipe(
        switchMap(x => {
          this.averageViewDuration = x.headers.get('x-summary-average-view-duration')
          console.log("observe getPercChart", x)
          // console.log("x.headers.get('x-pagination-page-count')", x.headers.get('x-pagination-page-count')) 
          if (x.headers.get('x-pagination-page-count') > 1) {
            return this.taskService.getContentAnlytics(this.data.company.id, filterData, "2").pipe(
              tap(b => {
                x.body.push(...b.body)
              }),
              map(() => x.body)
            )
          } else {
            return of(x.body)
          }
        }),
        tap(analytics => {
          for (let index = 0; index < analytics.length; index++) {
            let element = analytics[index];
            element.value = element.average_view_duration
            element.date = moment(element.start_time * 1000).clone()
            if (moment(element.start_time * 1000).clone().isAfter(moment().hours(0).minutes(0).seconds(0)) || moment(element.start_time * 1000).clone().isSame(moment().hours(0).minutes(0).seconds(0))) {
              element.name = moment(element.start_time * 1000).clone().format("MM/DD")
            } else if (moment(element.start_time * 1000).clone().year() == moment().year()) {
              element.name = moment(element.start_time * 1000).clone().format("MM/DD")
            } else {
              element.name = moment(element.start_time * 1000).clone().format("MM/DD/YYYY")
            }
          }
        }),
      ).subscribe(resp => {

        console.log("getDurChart",  resp)
        if (resp.length > 0) {
          this.upDurViewedViews = resp[resp.length - 1].updated_at
        } else {
          this.upDurViewedViews = 0
        }

        this.durViewed = [...[{
          name: this.data.profile.content_name,
          series: resp
        }]]
        this.createAreaTicks(this.durViewed, 4);
        console.log("percViewed", this.durViewed)
      })
    )
  }

  
    getRevChart() {
      this.totalRevenue = 0;
      this.totalAdRevenue = 0;
      this.totalRedRevenue = 0;
      let filterData:any = {
        start_time_from: this.filters[this.selectRevFilter.value].val.start_time_from,
        period: 'day',
        totals: '1',
        order_by: 'start_time'
      }
      if (!!this.filters[this.selectRevFilter.value].val.hasOwnProperty("start_time_to")) {
        filterData.start_time_to = this.filters[this.selectRevFilter.value].val['start_time_to']
      }
      this.attachSubscriptions(
        this.taskService.getDaysChart(this.data.company.id, this.data.profile.id, filterData).pipe(
          switchMap(x => {
            this.summaryV2Views = x.headers.get('x-summary-views')
            console.log("observe getDaysChart", x)
            // console.log("x.headers.get('x-pagination-page-count')", x.headers.get('x-pagination-page-count'))
            if (x.headers.get('x-pagination-total-count') == 0 && +this.data.profile.content_views_count > 0) {
              let subFilterData = {...filterData};
              subFilterData.order_by = 'start_time_desc'
              return this.taskService.getDaysChart(this.data.company.id, this.data.profile.id, Object.assign(subFilterData, {page: "1"})).pipe(
                tap(b => {
                  if (b.body && b.body.length > 0) {
                    this.lastTimeData = b.body[0].start_time;
                    this.filters.forEach(el => {
                      if ((+el.val.start_time_from) <= (+this.lastTimeData)) {
                        el['hasData'] = true;
                      }
                    })
                  }
                  console.log("subFilterData", this.filters)
                }),
                map(() => [])
              )
            } else {
              if (x.headers.get('x-pagination-page-count') > 1) {
                return this.taskService.getDaysChart(this.data.company.id, this.data.profile.id, Object.assign(filterData, {page: "2"})).pipe(
                  tap(b => {
                    x.body.push(...b.body)
                  }),
                  map(() => x.body)
                )
              } else {
                return of(x.body)
              }
            }
          }),
          tap(analytics => {
  
            
            for (let index = 0; index < analytics.length; index++) {
              let element = analytics[index];
              this.totalRevenue += +element.estimated_revenue;
              this.totalAdRevenue += +element.estimated_ad_revenue;
              this.totalRedRevenue += +element.estimated_red_partner_revenue;
              element.value = element.estimated_revenue
              element.date = moment(element.start_time * 1000).clone()
              if (moment(element.start_time * 1000).clone().isAfter(moment().hours(0).minutes(0).seconds(0)) || moment(element.start_time * 1000).clone().isSame(moment().hours(0).minutes(0).seconds(0))) {
                element.name = moment(element.start_time * 1000).clone().format("MM/DD")
              } else if (moment(element.start_time * 1000).clone().year() == moment().year()) {
                element.name = moment(element.start_time * 1000).clone().format("MM/DD")
              } else {
                element.name = moment(element.start_time * 1000).clone().format("MM/DD/YYYY")
              }
            }
          }),
        ).subscribe(resp => {
          this.chartDataRev = [...[{
            name: this.data.profile.content_name,
            series: resp
          }]]
          this.createAreaTicks(this.chartDataRev, 4);
          console.log("chartDataRev", this.chartDataRev)
        })
      )
    }

  getDaysChart() {
    let filterData:any = {
      start_time_from: this.filters[this.selectV2Filter.value].val.start_time_from,
      period: 'day',
      totals: '1',
      order_by: 'start_time'
    }
    if (!!this.filters[this.selectV2Filter.value].val.hasOwnProperty("start_time_to")) {
      filterData.start_time_to = this.filters[this.selectV2Filter.value].val['start_time_to']
    }
    this.attachSubscriptions(
      this.taskService.getDaysChart(this.data.company.id, this.data.profile.id, filterData).pipe(
        switchMap(x => {
          this.summaryV2Views = x.headers.get('x-summary-views')
          console.log("observe getDaysChart", x)
          // console.log("x.headers.get('x-pagination-page-count')", x.headers.get('x-pagination-page-count')) 
          if (x.headers.get('x-pagination-total-count') == 0 && +this.data.profile.content_views_count > 0) {
            let subFilterData = {...filterData};
            subFilterData.order_by = 'start_time_desc'
            return this.taskService.getDaysChart(this.data.company.id, this.data.profile.id, Object.assign(subFilterData, {page: "1"})).pipe(
              tap(b => {
                if (b.body && b.body.length > 0) {
                  this.lastTimeData = b.body[0].start_time;
                  this.filters.forEach(el => {
                    if ((+el.val.start_time_from) <= (+this.lastTimeData)) {
                      el['hasData'] = true;
                    }
                  })
                }
                console.log("subFilterData", this.filters)
              }),
              map(() => [])
            )
          } else {
            if (x.headers.get('x-pagination-page-count') > 1) {
              return this.taskService.getDaysChart(this.data.company.id, this.data.profile.id, Object.assign(filterData, {page: "2"})).pipe(
                tap(b => {
                  x.body.push(...b.body)
                }),
                map(() => x.body)
              )
            } else {
              return of(x.body)
            }
          }
        }),
        tap(analytics => {
          for (let index = 0; index < analytics.length; index++) {
            let element = analytics[index];
            element.value = element.views_count
            element.date = moment(element.start_time * 1000).clone()
            if (moment(element.start_time * 1000).clone().isAfter(moment().hours(0).minutes(0).seconds(0)) || moment(element.start_time * 1000).clone().isSame(moment().hours(0).minutes(0).seconds(0))) {
              element.name = moment(element.start_time * 1000).clone().format("MM/DD")
            } else if (moment(element.start_time * 1000).clone().year() == moment().year()) {
              element.name = moment(element.start_time * 1000).clone().format("MM/DD")
            } else {
              element.name = moment(element.start_time * 1000).clone().format("MM/DD/YYYY")
            }
          }
        }),
      ).subscribe(resp => {
        this.chartDataV2 = [...[{
          name: this.data.profile.content_name,
          series: resp
        }]]
        this.createAreaTicks(this.chartDataV2, 2);
        console.log("chartDataV2", this.chartDataV2)
      })
    )
  }

  getHoursChart() {
    this.attachSubscriptions(
      this.taskService.getHoursChart(this.data.company.id, this.data.profile.id, {
        start_time_from: moment().subtract(this.timeCols[this.selectTimeV2Filter.value].value, 'h').format("X"),
        period: 'hour',
        totals: '1',
        order_by: 'start_time'
      }).pipe(
        tap(x => {
          this.timeV2ChartSumm = x.headers.get('x-summary-views')
          for (let index = 0; index < x.body.length; index++) {
            let element = x.body[index];
            element.value = element.views_count
            element.date = moment(element.start_time * 1000).clone()
            if (moment(element.start_time * 1000).clone().isAfter(moment().hours(0).minutes(0).seconds(0)) || moment(element.start_time * 1000).clone().isSame(moment().hours(0).minutes(0).seconds(0))) {
              element.name = moment(element.start_time * 1000).clone().format("hh:mm a") + " \u2013 " + moment(element.end_time * 1000).clone().format("hh:mm a");
            } else if (moment(element.start_time * 1000).clone().year() == moment().year()) {
              element.name = moment(element.start_time * 1000).clone().format("MM/DD, hh:mm a") + " \u2013 " + moment(element.end_time * 1000).clone().format("hh:mm a");
            } else {
              element.name = moment(element.start_time * 1000).clone().format("MM/DD/YYYY, hh:mm a") + " \u2013 " + moment(element.end_time * 1000).clone().format("hh:mm a");
            }
          }
        }),
        map(x => x.body),
        map(chartArr => {
          console.log("NEW MODEL chartArr", chartArr)
          let model = [];

          for (let i:number = 1; i <= this.timeCols[this.selectTimeV2Filter.value].value; i++) {
            let date = this.currDate.clone().subtract(this.timeCols[this.selectTimeV2Filter.value].value-i, 'h')
            if (chartArr.filter(u => date.isSameOrAfter(moment(u.start_time * 1000)) && date.clone().subtract(1, 's').isSameOrBefore(moment(u.end_time * 1000))).length == 0) {
              let name = '';
              if (date.clone().isSameOrAfter(moment().hours(0).minutes(0).seconds(0))) {
                name = date.clone().format("hh:mm a") + " \u2013 " + date.clone().add(1,'h').subtract(1, 's').format("hh:mm a");
              } else if (date.clone().year() == moment().year()) {
                name = date.clone().format("MM/DD, hh:mm a") + " \u2013 " + date.clone().add(1,'h').subtract(1, 's').format("hh:mm a");
              } else {
                name = date.clone().format("MM/DD/YYYY, hh:mm a") + " \u2013 " + date.clone().add(1,'h').subtract(1, 's').format("hh:mm a");
              }
              model.push({
                index: i,
                hour: i - this.timeCols[this.selectTimeV2Filter.value].value,
                date: date,
                name: name,
                value: 0
              })
            } else {
              model.push(chartArr.find(u => date.isSameOrAfter(moment(u.start_time * 1000)) && date.clone().subtract(1, 's').isSameOrBefore(moment(u.end_time * 1000))))
            }
          }
          console.log("NEW MODEL", model)
          return model
        })
      ).subscribe(resp => {
        if (resp.length > 0) {
          this.upV2Views = resp[resp.length - 1].updated_at
        } else {
          this.upV2Views = 0
        }
        this.timeChartDataV2 = [...resp]
        this.timeChartDataV2MaxVal = Math.max(...this.timeChartDataV2.slice().map(x => x.value))
        
        this.createTimeTicks(this.timeChartDataV2, 2)
        console.log("timeChartDataV2MaxVal", this.timeChartDataV2MaxVal)
        console.log("timeChartDataV2", this.timeChartDataV2)
      })
    )
  }

  getTimeHistory() {
    // console.log("getTimeHistory this.allHistory", this.allHistory)
    // console.log("getTimeHistory this.currDate", this.currDate)

    if (!this.allHistory || this.allHistory.length == 0) {
      return 
    }


    this.timeChartSumm = 0;

    let model = [];

    for (let i:number = 1; i <= this.timeCols[this.selectTimeFilter.value].value; i++) {
      model.push({
        index: i,
        hour: i - this.timeCols[this.selectTimeFilter.value].value,
        date: this.currDate.clone().subtract(this.timeCols[this.selectTimeFilter.value].value-i, 'h'),
        name: '',
        // name: this.currDate.clone().subtract(this.timeCols[this.selectTimeFilter.value].value-i, 'h').format("MM/DD/YYYY hh:mm a"),
        value: 0,
        arr: []
      })
    }

    let values = this.allHistory.slice();

    let modArrL = 0;

    model.forEach((item, ind) => {
      values.forEach(element => {
        if (ind == 0 && moment(element.created_at*1000).isBefore(item.date)) {
          item.arr.push(element)
        } else if (moment(element.created_at*1000).isSame(item.date) || (moment(element.created_at*1000).isAfter(item.date) && !!model[ind+1] && moment(element.created_at*1000).isBefore(model[ind+1].date))) {
          item.arr.push(element)
        } else if (moment(element.created_at*1000).isAfter(item.date) && !model[ind+1]) {
          item.arr.push(element)
          // console.log(item, ind, element)
        } 
      });

      item.arr.sort((a,b) => {
        return +a.content_views_count - +b.content_views_count;
      })

      let prevWithArrInd;
      let slicedModel:any = model.slice(0, ind)
      if (slicedModel.filter(x => x.arr.length).length > 0) {
        prevWithArrInd = slicedModel.indexOf(slicedModel.findLast(x => x.arr.length > 0))
        // console.log("LALA", ind, prevWithArrInd, slicedModel);
      }
      if (ind != 0 && item.arr && item.arr.length > 0 && prevWithArrInd != undefined && model[prevWithArrInd]) {
        // console.log(ind, prevWithArrInd);
        item.value = item.arr[item.arr.length - 1].content_views_count - model[prevWithArrInd].arr[model[prevWithArrInd].arr.length - 1].content_views_count
        if (item.value < 0) {
          item.value = 0
        }
      } else {
        item.value = 0
      }

      this.timeChartSumm = +this.timeChartSumm + +item.value
      modArrL = modArrL + item.arr.length
      if (item.date.isAfter(moment().hours(0).minutes(0).seconds(0)) || item.date.isSame(moment().hours(0).minutes(0).seconds(0))) {
        item.name = item.date.format("hh:mm a") + " \u2013 " + item.date.add(1, 'h').format("hh:mm a");
      } else if (item.date.year() == moment().year()) {
        item.name = item.date.format("MM/DD, hh:mm a") + " \u2013 " + item.date.add(1, 'h').format("hh:mm a");
      } else {
        item.name = item.date.format("MM/DD/YYYY, hh:mm a") + " \u2013 " + item.date.add(1, 'h').format("hh:mm a");
      }
    })


    // console.log("if we have only one hour data and this item has more then 1 arr data")
    // if we have only one hour data and this item has more then 1 arr data
    if (model.filter(x => x.arr.length > 1).length == 1 && model.filter(x => x.arr.length == 1).length == 0) {
      console.log("if we have only one hour data and this item has more then 1 arr data")
      let target = model.find(x => x.arr.length > 1)
      console.log("ONLY ONE Hour")
      target.value = target.arr[target.arr.length - 1].content_views_count - target.arr[0].content_views_count
    }

    this.timeChartData = [...model]

    this.createTimeTicks(this.timeChartData, 1)
    // this.timeChartData = [];

    // setTimeout(() => {
    //   this.timeChartData = [...model]
    // })
    
    if (this.contentCount == 1) {
      this.getContentAnlytics();
      this.contentCount++
    }

    this.timeChartDataMaxVal = Math.max(...this.timeChartData.slice().map(x => x.value))
    
    console.log("timeChartDataMaxVal", this.timeChartDataMaxVal)

    console.log("model", model)
    console.log("modArrL", modArrL)
  }

  getContentHistoryStats() {
    console.log("statsFilter", this.statsFilter);
    this.attachSubscriptions(

      forkJoin(this.statsFilter.map((x, i) => this.taskService.getContentHistory(this.data.company.id, this.data.profile.id, x).pipe(
        tap(val => {
          if (i == 0) {
            if (val.length > 1) {
              this.lastSeven = val[0].content_views_count - val[val.length - 1].content_views_count
            }
          } else {
            if (val.length > 1) {
              this.lastMonth = val[0].content_views_count - val[val.length - 1].content_views_count
            }
          }
        })
      ))).subscribe(resp => {
        console.log("getContentHistoryStats", resp)
      })
    )
  }

  checkIsMobile() {
    if (window.innerWidth <= 769) {
      this.is_mobile = true;
    } else {
      this.is_mobile = false;
    }
    this.onResize();
  }

  onResize() {
    this.attachSubscriptions(
      fromEvent(window, "resize").pipe(
        map(() => window.innerWidth)
      ).subscribe((wWidth) => {
          if (wWidth <= 769) {
            this.is_mobile = true;
          } else {
            this.is_mobile = false;
          }
        }
      )
    )
  }

  createTimeTicks(arr, v) {
    let _arr = [];
    let _rArr = [];
    
    _arr = [...arr.map(x => Number(x.value))].sort((a,b) => a - b)

    console.log("_arr", _arr)
    let maxValue = _arr[_arr.length - 1];
    console.log("maxValue", maxValue)

    if (maxValue <= 3) {
      if (maxValue == 3) {
        _rArr.push(...[3,2,1,0])
      } else if (maxValue == 2) {
        _rArr.push(...[2,1,0])
      } else if (maxValue == 1) {
        _rArr.push(...[1,0])
      } else {
        _rArr.push(0)
      }
    } else if (maxValue > 3 && maxValue < 15) {
      let maxTick = roundToUpper2(maxValue, 3)
      _rArr.push(maxTick, (maxTick*2)/3, maxTick/3, 0)
    } else if (maxValue >= 15 && maxValue < 100) {
      let maxTick = roundToUpper2(maxValue, 15)
      _rArr.push(maxTick, (maxTick*2)/3, maxTick/3, 0)
    } else if (maxValue >= 100 && maxValue < 1000) {
      let maxTick = roundToUpper2(maxValue, 150)
      _rArr.push(maxTick, (maxTick*2)/3, maxTick/3, 0)
    } else if (maxValue >= 1000 && maxValue < 100000) {
      let maxTick = roundToUpper2(maxValue, 1500)
      _rArr.push(maxTick, (maxTick*2)/3, maxTick/3, 0)
    } else if (maxValue >= 100000) {
      let maxTick = roundToUpper2(maxValue, 15000)
      _rArr.push(maxTick, (maxTick*2)/3, maxTick/3, 0)
    }

    _rArr = _rArr.reverse()

    // console.log("maxValue.length", String(maxValue).length)
    // console.log("maxValue", maxValue)
    // console.log("neededValueLength", neededValueLength)
    if (v == 1) {
      this.timeTicks = _rArr
      this.timeYScaleMax = _rArr[_rArr.length - 1]
    } else {
      this.timeTicksV2 = _rArr
      this.timeYScaleMaxV2 = _rArr[_rArr.length - 1]
    }
    

    function roundToUpper2(val, roundNum) {
      if (val >= (Math.round(val / roundNum) * roundNum)) {
        return Math.round((val + roundNum) / roundNum) * roundNum;
      } else {
        return Math.round(val / roundNum) * roundNum;
      }
    }
  }

  createAreaTicks(arr, v) {
    // console.log("arr", arr)
    let _arr = [];
    let _rArr = [];
    
    arr.forEach(x => {
      x.series.forEach(v => {
        _arr.push(Number(v.value))
      })
    })

    _arr.sort((a,b) => a - b)

    let maxValue = _arr[_arr.length - 1];

    if (maxValue <= 3) {
      if (maxValue == 3) {
        _rArr.push(...[3,2,1,0])
      } else if (maxValue == 2) {
        _rArr.push(...[2,1,0])
      } else if (maxValue == 1) {
        _rArr.push(...[1,0])
      } else {
        _rArr.push(0)
      }
    } else if (maxValue > 3 && maxValue < 15) {
      let maxTick = roundToUpper2(maxValue, 3)
      _rArr.push(maxTick, (maxTick*2)/3, maxTick/3, 0)
    } else if (maxValue >= 15 && maxValue < 100) {
      let maxTick = roundToUpper2(maxValue, 15)
      _rArr.push(maxTick, (maxTick*2)/3, maxTick/3, 0)
    } else if (maxValue >= 100 && maxValue < 1000) {
      let maxTick = roundToUpper2(maxValue, 150)
      _rArr.push(maxTick, (maxTick*2)/3, maxTick/3, 0)
    } else if (maxValue >= 1000 && maxValue < 100000) {
      let maxTick = roundToUpper2(maxValue, 1500)
      _rArr.push(maxTick, (maxTick*2)/3, maxTick/3, 0)
    } else if (maxValue >= 100000) {
      let maxTick = roundToUpper2(maxValue, 15000)
      _rArr.push(maxTick, (maxTick*2)/3, maxTick/3, 0)
    }

    _rArr = _rArr.reverse()

    // console.log("_arr", _arr)
    // console.log("maxValue.length", String(maxValue).length)
    // console.log("maxValue", maxValue)
    // console.log("maxValue", maxValue)
    // console.log("neededValueLength", neededValueLength)

    if (v == 1) {
      this.areaTicks = _rArr
      this.areaYScaleMax = _rArr[_rArr.length - 1]
    } else if (v == 2) {
      this.areaTicksV2 = _rArr
      this.areaYScaleMaxV2 = _rArr[_rArr.length - 1]
    } else if (v == 3) {
      this.areaTicksPerc = _rArr
      this.areaYScaleMaxPerc = _rArr[_rArr.length - 1]
    } else if (v == 4) {
      this.areaTicksRev = _rArr
      this.areaYScaleMaxRev = _rArr[_rArr.length - 1]
    } else {
      this.areaTicksDur = _rArr
      this.areaYScaleMaxDur = _rArr[_rArr.length - 1]
    }
    
    // console.log("areaYScaleMax", this.areaYScaleMax)

    function roundToUpper2(val, roundNum) {
      if (val >= (Math.round(val / roundNum) * roundNum)) {
        return Math.round((val + roundNum) / roundNum) * roundNum;
      } else {
        return Math.round(val / roundNum) * roundNum;
      }
    }
  }

  consoleData(data) {
    console.log(data)
  }

  getContentAnlytics() {

    if (!this.data.profile.external_content_id) {
      return
    }
    this.attachSubscriptions(
      this.taskService.getContentAnlytics(this.data.company.id, this.filters[this.selectFilter.value].val, "1").pipe(
        switchMap(x => {
          console.log("observe getContentAnlytics", x)
          this.summaryViews = +x.headers.get('x-summary-views');
          // console.log("x.headers.get('x-pagination-page-count')", x.headers.get('x-pagination-page-count')) 
          if (x.headers.get('x-pagination-page-count') > 1) {
            return this.taskService.getContentAnlytics(this.data.company.id, this.filters[this.selectFilter.value].val, "2").pipe(
              tap(b => {
                x.body.push(...b.body)
              }),
              map(() => x.body)
            )
          } else {
            return of(x.body)
          }
        }),
        tap(values => {
          // console.log("x-pagination-page-count", values)
          values.map((val) => {
            // console.log(val)

            if (moment(val.start_time * 1000).isAfter(moment().hours(0).minutes(0).seconds(0)) || moment(val.start_time * 1000).isSame(moment().hours(0).minutes(0).seconds(0))) {
              val.name = moment(val.start_time * 1000).format("MM/DD");
            } else if (moment(val.start_time * 1000).year() == moment().year()) {
              val.name = moment(val.start_time * 1000).format("MM/DD");
            } else {
              val.name = moment(val.start_time * 1000).format("MM/DD/YYYY");
            }
            val.value = val.views_count.toString();
          })
          
          
          let addValues = []
          if (values.length && this.filters[this.selectFilter.value].needDataFromTimeChart) {
            let lastVal = values[values.length - 1]
            this.upViews = lastVal.updated_at
            addValues.push(lastVal)
            console.log("lastVal", lastVal);
            let diffDays = Math.trunc(Math.abs(moment.duration(moment(lastVal.end_time*1000).diff(moment().endOf('day'))).asDays())) + 1;
            console.log("diffDays", diffDays);
  
            if (diffDays > 0 && this.timeChartData && this.timeChartData.filter(x => x.value > 0).length > 0) {
              for (let i = 0; i < diffDays; i++) {
                let lastNewValue = addValues[addValues.length - 1];
                let arr = this.timeChartData.filter(b => b.value && b.date.clone().isBetween(moment(lastNewValue.start_time*1000).add(1, 'd'), moment(lastNewValue.end_time*1000).add(1, 'd')))
                let x:any = {
                  id: 0,
                  index: i,
                  company_id: this.company_id,
                  content_id: lastNewValue.content_id,
                  content_type: lastNewValue.content_type,
                  period: lastNewValue.period,
                  period_id: moment(lastNewValue.period_id).add(1, 'd').format("YYYY-MM-DD"),
                  start_time: moment(lastNewValue.start_time*1000).add(1, 'd').format("X"),
                  end_time: moment(lastNewValue.end_time*1000).add(1, 'd').format("X"),
                  start_time_d: moment(lastNewValue.start_time*1000).add(1, 'd'),
                  end_time_d: moment(lastNewValue.end_time*1000).add(1, 'd'),
                  views_count: 0,
                  created_at: moment(lastNewValue.created_at*1000).add(1, 'd').format("X"),
                  updated_at: moment(lastNewValue.updated_at*1000).add(1, 'd').format("X"),
                  value: arr.length ? arr.map(n => n.value).reduce((a, b) => +a + +b, 0) : 0,
                  arr: arr
                }
                
                if (moment(x.start_time * 1000).isAfter(moment().hours(0).minutes(0).seconds(0)) || moment(x.start_time * 1000).isSame(moment().hours(0).minutes(0).seconds(0))) {
                  x.name = moment(x.start_time * 1000).format("MM/DD");
                } else if (moment(x.start_time * 1000).year() == moment().year()) {
                  x.name = moment(x.start_time * 1000).format("MM/DD");
                } else {
                  x.name = moment(x.start_time * 1000).format("MM/DD/YYYY");
                }
  
                
    
                addValues.push(x)
              }
            }
          }

          if (addValues.length) {

            console.log('+addValues.map(n => n.value).reduce((acc, number) => acc + number, 0)', +addValues.map(n => n.value).reduce((acc, number) => +acc + +number, 0))
            this.summaryViews = +this.summaryViews + +addValues.map(n => n.value).reduce((acc, number) => +acc + +number, 0) - +addValues[0].value

            let todayValues = [];
            todayValues.push(addValues[addValues.length - 2])
            todayValues.push(addValues[addValues.length - 1])
            addValues.splice(addValues.length - 1, 1)

            this.chartData = [...[
              {
                name: "",
                series: values
              }, 
              {
                name: "(h) \u2013 ",
                series: addValues
              },
              {
                name: "Today (h) \u2013 ",
                series: todayValues
              }
            ]]
          } else {
            this.chartData = [...[{
              name: this.data.profile.content_name,
              series: values
            }]]
          }
        })
      ).subscribe(resp => {

        this.createAreaTicks(this.chartData, 1);
        console.log("getContentAnlytics", resp)
        console.log("getContentAnlytics", this.chartData)
      })
    )
  }

  getContentHistory() {
    this.attachSubscriptions(
      this.taskService.getContentHistory(this.data.company.id, this.data.profile.id, {
        created_at_from: moment().subtract(4, 'd').startOf("day").format("X"),
        created_at_to: moment().endOf("day").format("X"),
      }).pipe(
        tap(values => {
          this.allHistory = JSON.parse(JSON.stringify(values));
          if (this.count == 1) {
            this.getTimeHistory()
            this.count++
          }
        })
      ).subscribe(resp => {
        console.log("chartData", resp)
      })
    )
  }

  openTask() {
    let taskData:any = {
      task_id: this.task.id
    }
    if (this.task.hasOwnProperty("operations")) {
      taskData.task = this.task
    }

    const dialogRef = this.dialog.open(OpenTaskComponent, {
      backdropClass: 'backdrop_under_header',
      panelClass: !this.is_mobile ? ['open_task_dialog', 'show_header'] : 'open_task_dialog',
      autoFocus: false,
      data: taskData
    });
  }

  deleteContentHistoryElement(item) {
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: {},
        target: marker("content history item")
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        this.attachSubscriptions(
          this.taskService.deleteContentHistoryElement(item.id).subscribe(resp => {
            this.timeChartData.splice(this.timeChartData.indexOf(this.timeChartData.find(x => x.id == item.id)), 1)
            this.timeChartData = [...this.timeChartData];
            this.selectedHistoryItem = undefined
            this.getContentHistoryStats();
          })
        )
      }
    });
  }


  //  element.timeInFormat = new Date(element.time * 1000).toISOString().slice(11, 16)
  // time: Number(value.timeInFormat.split(":")[0])*3600 + Number(value.timeInFormat.split(":")[1])*60,
  onImgError(event){
    event.target.src = this.imgRoute+'/assets/img/image_black_48dp.svg'
  }

  showUserDate(date:any = false, timestamp:any = false) {
    let timeAndDate;
    if (date) {
      timeAndDate = moment(date).clone()
    } else {
      timeAndDate = moment(timestamp*1000).clone()
    }

    const utcOffset = moment.tz(timeAndDate, this.data.company.timezone).utcOffset();
    
    let utcTimestamp = moment(timeAndDate).format("X")
    
    let difference = (Number(this.timeZone) - Number(utcOffset))*60
    // console.log(difference);
    
    let workSpaceTimeZone;
    if (date) {
      workSpaceTimeZone = Number(utcTimestamp) + Number(difference)
    } else {
      workSpaceTimeZone = Number(utcTimestamp) - Number(difference)
    }
    return workSpaceTimeZone
  }

  toFullEdit() {
    const dialogRef = this.dialog.open(AddTaskProfileComponent, {
      disableClose: true,
      data: this.data
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        
        if (!!result && result.event == 'edit') {
          this.count = 1;
          this.contentCount = 1;
          this.getContentHistory()
          this.getContentHistoryStats();
        }
      })
    )
  }

  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData().subscribe(resp => {
        this._ngx_adapter.setLocale(resp.active);
        this._adapter.setLocale(resp.active);
        this.activeLang = resp.active;
      })
    )
  }

  getCompany() {
    this.attachSubscriptions(
      this.companyService.getCompany(this.company_id).subscribe(resp => {
        this.companyService.company$.next(resp[0]);
      })
    )
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  getCsrf() {
    this.attachSubscriptions(
      this.auth.$userToken.subscribe(resp => {
        this.user_id = resp.user_id;
        this.sm.localStorageSetItem("csrf_param", resp.csrf_param)
        this.sm.localStorageSetItem("csrf_token", resp.csrf_token)
      })
    )
  }

  getUser() {
    this.attachSubscriptions(
      this.auth.$user.subscribe(resp => {
        this.user = resp;
      })
    )
  }

  getJob(id) {
    return (id && !!this.data.task.operations.find(x => x.id == id)) ? this.data.task.operations.find(x => x.id == id) : undefined
  }

  change() {
    console.log("CHANGE")
  }

  refreshViewCounts() {
    this.isRefreshed = true;

    if (this.data.profile && this.data.profile.channel && this.data.profile.channel.platform_id == 1) {
      this.attachSubscriptions(
        this.taskService.getYouTubeViews(this.data.profile.id, {}).pipe(
          switchMap(el => {
            return this.taskService.editProfile(this.data.profile.id, {content_views_count: !!el.content_views_count ? el.content_views_count : '0', is_bravourl_content_views_count_update: !!this.data.profile.is_bravourl_content_views_count_update ? '1' : '0', is_content_views_count_update: !!this.data.profile.is_content_views_count_update ? '1' : '0'}, this.company_id).pipe(
              tap(result => {
                this.data.profile.content_views_count = result.content_views_count.toString();
                this.data.profile.is_content_views_count_update = result.is_content_views_count_update;
                this.data.profile.is_bravourl_content_views_count_update = result.is_bravourl_content_views_count_update;
                this.data.profile.views_updated_at = result.views_updated_at;
                this.data.profile.views_update_at = result.views_update_at;
              })
            )
          }),
          retry(3)
        ).subscribe((resp:any) => {
          console.log("refreshViewCounts", resp)
          this.isRefreshed = false;
          this.count = 1;
          this.contentCount = 1;
          this.getContentHistory()
          this.getContentHistoryStats();
        },
        error => {
          this.isRefreshed = false;
          this.layoutService.showSnackBar({name: ''}, marker(error), SnackBarItem)
        })
      )
    } else if (this.data.profile && this.data.profile.channel && this.data.profile.channel.channel_account_id && this.data.profile.channel.platform_id == 2) {
      this.attachSubscriptions(
        this.taskService.getIGViews(this.data.profile.id, {}).pipe(
          switchMap(el => {
            return this.taskService.editProfile(this.data.profile.id, {content_views_count: !!el.content_views_count ? el.content_views_count : '0', is_bravourl_content_views_count_update: !!this.data.profile.is_bravourl_content_views_count_update ? '1' : '0', is_content_views_count_update: !!this.data.profile.is_content_views_count_update ? '1' : '0'}, this.company_id).pipe(
              tap(result => {
                this.data.profile.content_views_count = result.content_views_count.toString();
                this.data.profile.is_content_views_count_update = result.is_content_views_count_update;
                this.data.profile.is_bravourl_content_views_count_update = result.is_bravourl_content_views_count_update;
                this.data.profile.views_updated_at = result.views_updated_at;
                this.data.profile.views_update_at = result.views_update_at;
              })
            )
          }),
          retry(3)
        ).subscribe((resp:any) => {
          console.log("refreshViewCounts", resp)
          this.data.profile.isRefreshed = false;
        },
        error => {
          this.data.profile.isRefreshed = false;
          this.layoutService.showSnackBar({name: ''}, marker(error), SnackBarItem)
        })
      )
    } else if (this.data.profile && this.data.profile.channel && this.data.profile.channel.platform_id == 3) {
      this.attachSubscriptions(
        this.taskService.getTikTokViews(this.data.profile.id, {}).pipe(
          switchMap(el => {
            return this.taskService.editProfile(this.data.profile.id, {content_views_count: !!el.content_views_count ? el.content_views_count : '0', is_bravourl_content_views_count_update: !!this.data.profile.is_bravourl_content_views_count_update ? '1' : '0', is_content_views_count_update: !!this.data.profile.is_content_views_count_update ? '1' : '0'}, this.company_id).pipe(
              tap(result => {
                this.data.profile.content_views_count = result.content_views_count.toString();
                this.data.profile.is_content_views_count_update = result.is_content_views_count_update;
                this.data.profile.is_bravourl_content_views_count_update = result.is_bravourl_content_views_count_update;
                this.data.profile.views_updated_at = result.views_updated_at;
                this.data.profile.views_update_at = result.views_update_at;
              })
            )
          }),
          retry(3)
        ).subscribe((resp:any) => {
          console.log("refreshViewCounts", resp)
          this.data.profile.isRefreshed = false;
        },
        error => {
          this.data.profile.isRefreshed = false;
          this.layoutService.showSnackBar({name: ''}, marker(error), SnackBarItem)
        })
      )
    } else if (this.data.profile && this.data.profile.channel && this.data.profile.channel.platform_id == 4) {
      this.attachSubscriptions(
        this.taskService.getFacebookViews(this.data.profile.id, {}).pipe(
          switchMap(el => {
            return this.taskService.editProfile(this.data.profile.id, {content_views_count: !!el.content_views_count ? el.content_views_count : '0', is_bravourl_content_views_count_update: !!this.data.profile.is_bravourl_content_views_count_update ? '1' : '0', is_content_views_count_update: !!this.data.profile.is_content_views_count_update ? '1' : '0'}, this.company_id).pipe(
              tap(result => {
                this.data.profile.content_views_count = result.content_views_count.toString();
                this.data.profile.is_content_views_count_update = result.is_content_views_count_update;
                this.data.profile.is_bravourl_content_views_count_update = result.is_bravourl_content_views_count_update;
                this.data.profile.views_updated_at = result.views_updated_at;
                this.data.profile.views_update_at = result.views_update_at;
              })
            )
          }),
          retry(3)
        ).subscribe((resp:any) => {
          console.log("refreshViewCounts", resp)
          this.data.profile.isRefreshed = false;
        },
        error => {
          this.data.profile.isRefreshed = false;
          this.layoutService.showSnackBar({name: ''}, marker(error), SnackBarItem)
        })
      )
    } else {
      this.attachSubscriptions(
        this.taskService.getLinkData(this.data.profile.content_url, this.company_id, (this.data.profile.company_id != this.company_id ? this.data.profile.company_id : null), this.data.profile.channel_id).pipe(
          switchMap(el => {
            return this.taskService.editProfile(this.data.profile.id, {content_views_count: !!el.views_count ? el.views_count : '0', is_bravourl_content_views_count_update: !!this.data.profile.is_bravourl_content_views_count_update ? '1' : '0', is_content_views_count_update: !!this.data.profile.is_content_views_count_update ? '1' : '0'}, this.company_id).pipe(
              tap(result => {
                this.data.profile.content_views_count = result.content_views_count.toString();
                this.data.profile.is_content_views_count_update = result.is_content_views_count_update;
                this.data.profile.is_bravourl_content_views_count_update = result.is_bravourl_content_views_count_update;
                this.data.profile.views_updated_at = result.views_updated_at;
                this.data.profile.views_update_at = result.views_update_at;
              })
            )
          }),
        ).subscribe((resp:any) => {
          console.log("refreshViewCounts", resp)
          this.isRefreshed = false;
          this.count = 1;
          this.contentCount = 1;
          this.getContentHistory()
          this.getContentHistoryStats();
        },
        error => {
          this.isRefreshed = false;
        })
      )
    }

  }

  showError() {
    this.layoutService.showSnackBar({name: ''}, marker("Try again later"), SnackBarItem)
  }

  cancel() {
    this.dialogRef.removePanelClass('animate__slideInUp')
    this.dialogRef.addPanelClass('animate__slideOutDown')
    setTimeout(()=>{this.dialogRef.close({event: "null", data: this.data})}, 300);
    
  }

  getProfileById(id) {
    if (!this.profiles || this.profiles.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.profiles.find(el => el.id == id)
  }

  close() {
    this.dialogRef.removePanelClass('animate__slideInUp')
    this.dialogRef.addPanelClass('animate__slideOutDown')
    setTimeout(()=>{this.dialogRef.close({event: "close", data: this.data})}, 300);
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
