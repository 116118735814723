import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { prioritys } from 'src/app/shared/consts/prioritys';
import { BaseClass } from 'src/app/shared/models/base-class';
import { ChatService } from 'src/app/shared/services/rest/chat.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';

@Component({
  selector: 'app-mob-title',
  templateUrl: './mob-title.component.html',
  styleUrls: ['./mob-title.component.scss']
})
export class MobTitleComponent extends BaseClass implements OnInit, OnDestroy {

  public prioritys = prioritys
  public form: FormGroup = this.fb.group({
    [this.data.type_prop]: ''
  })

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<MobTitleComponent>,
    private fb: FormBuilder,
    private chatService: ChatService,
    private taskService: TaskService,
  ) { super() }

  ngOnInit(): void {
    console.log("MobTitleComponent", this.data)

    this.form = this.fb.group({
      [this.data.type_prop]: !!this.data[this.data.target][this.data.type_prop] ? this.data[this.data.target][this.data.type_prop] : "",
    })
  }

  onSubmit() {
    if (this.data.target == 'chat') {
      this.attachSubscriptions(
        this.chatService.editChat(this.data.chat.id, this.form.value, this.data.company.id).subscribe(resp => {
          console.log(resp)
          this.dialogRef.removePanelClass('animate__slideInUp')
          this.dialogRef.addPanelClass('animate__slideOutDown')
          setTimeout(()=>{this.dialogRef.close({event: 'update', data: resp})}, 300);
        })
      )
    } else if (this.data.target == 'work') {
      this.attachSubscriptions(
        this.taskService.editWork(this.data.work.id, this.form.value, this.data.company.id).subscribe(resp => {
          this.dialogRef.removePanelClass('animate__slideInUp')
          this.dialogRef.addPanelClass('animate__slideOutDown')
          setTimeout(()=>{this.dialogRef.close({event: 'update', data: resp})}, 300);
        })
      )
    } else {
      this.attachSubscriptions(
        this.taskService.editTask(this.data.task.id, this.form.value, this.data.company.id).subscribe(resp => {
          this.dialogRef.removePanelClass('animate__slideInUp')
          this.dialogRef.addPanelClass('animate__slideOutDown')
          setTimeout(()=>{this.dialogRef.close({event: 'update', data: resp})}, 300);
        })
      )
    }

  }

  back() {
    this.dialogRef.removePanelClass('animate__slideInUp')
    this.dialogRef.addPanelClass('animate__slideOutDown')
    setTimeout(()=>{this.dialogRef.close({event: 'back'})}, 300);
  }

  close() {
    this.dialogRef.removePanelClass('animate__slideInUp')
    this.dialogRef.addPanelClass('animate__slideOutDown')
    setTimeout(()=>{this.dialogRef.close()}, 300);
  }


  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
