import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sizepersec'
})
export class SizePerSec implements PipeTransform {
  transform(size: number) {
    if (size >= 1073741824) {
      return (size / 1073741824).toFixed(1) + " GB/s";
    } else if (size >= 10240) {
      return (size / 1048576).toFixed(1) + " MB/s";
    } else if (size >= 1) {
      return (size / 1024).toFixed(1) + " KB/s";
    } else {
      return "0 B";
    }
  }
}