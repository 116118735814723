import { NgxMatDateAdapter } from '@angular-material-components/datetime-picker';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { Component, Inject, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { ReplaySubject, Subscription, forkJoin, fromEvent, of } from 'rxjs';
import { debounceTime, filter, last, map, switchMap, take, tap } from 'rxjs/operators';
import { MY_NEW_FORMATS } from 'src/app/components/workspace-pages/cases/dialogs/open-task/dialogs/url-analytics/url-analytics.component';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { AiService } from 'src/app/shared/services/rest/ai.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { FileService } from 'src/app/shared/services/rest/file.service';
import { ParametersService } from 'src/app/shared/services/rest/parameters.service';
import { ScenariosService } from 'src/app/shared/services/rest/scenarios.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';

@Component({
  selector: 'app-card-project',
  templateUrl: './card-project.component.html',
  styleUrls: ['./card-project.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_NEW_FORMATS},
  ]
})
export class CardProjectComponent extends BaseClass implements OnInit, OnDestroy {
  public cards: any = [];

  public publications: any = [];
  public forAllContentTypes: any;

  
  public groups$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public groupsMoreControl: FormControl = new FormControl();
  public groups: any;

  public projectName: string = this.data.project ? this.data.project.name : `Project based on ${this.data.allFiles.length} files`;

  public isSubmit: boolean = false;
  
  public is_dragging: boolean = false;

  public profilesTab: number = 0

  public taskTemplates: any;
  public taskTemplates$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public taskTemplatesControl: FormControl = new FormControl();

  public profiles: any[] = [];
  public profilesMoreControl: FormControl = new FormControl();
  public profiles$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public appPublicationData: any = {
    channel_id: '',
    content_type_id: '',
    content_status_id: 6,
    content_published_at: '',
    is_content_plan: 1,
    content_plan_start_at: 0,
    content_plan_min_interval: 1,
    content_name: '',
    content_description: '',
    task_channel_files: [{
      original_file_id: 0,
      is_content: 1,
      is_preview: 0,
      is_thumbnail: 0,
      is_original: 0,
      is_to_upload: 1
    }]
  }

  public form: FormGroup = this.fb.group({
    card_name: '',
    post_name: '',
    template_id: ''
  })
  public aiSysForm: FormGroup;
  public aiForm: FormGroup;

  public aiResults: any;
  public aiMistake: any;
  public showSys: boolean = false;
  public aiCount: number = 0;
  
  @ViewChild('contextMenu') contextMenu: TemplateRef<any>;
  overlayRef: OverlayRef | null;
  public backContextSub: Subscription;
  public isGettingResults: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CardProjectComponent>,
    private fileService: FileService,
    private parametersService: ParametersService,
    private dialog: MatDialog,
    private sanitizer: DomSanitizer,
    private fb: FormBuilder,
    public scenariosService: ScenariosService,
    public aiService: AiService,
    public companyService: CompanyService,
    private sm: StorageManagerService,
    public taskService: TaskService,
    public layoutService: LayoutService,
    public overlay: Overlay,
    public viewContainerRef: ViewContainerRef,
    private _ngx_adapter: NgxMatDateAdapter<any>,
    private _adapter: DateAdapter<any>,
  ) { super() }

  ngOnInit(): void {
    console.log("CardProjectComponent", this.data);
    this.dialogRef.addPanelClass('video_editor_dialog');

    this.aiForm = this.fb.group({
      on_the_video: '',
      your_idea_of_title: '',
      remembers: [[]],
      keywords: [[]],
      also_remember: ''
    })

    this.aiSysForm = this.fb.group({
      str: this.getResultsReqObject().messages[0].content || ''
    })

    this.attachSubscriptions(
      this.aiForm.valueChanges.subscribe(res => {
        this.aiSysForm.patchValue({
          str: this.getResultsReqObject().messages[0].content || ""
        })
      })
    )

    if (this.data.project) {
      let custom_data = JSON.parse(this.data.project.custom_data);
      this.publications = custom_data.publications;
      this.cards = custom_data.cards;
    } else {
      this.data.allFiles.forEach((element, index) => {
        element.post_name = 'Post ' + (index + 1);
        element.publications = []
        this.cards.push({
          files: [element],
          card_name: 'Card ' + (index + 1)
        })
      });
    }

    this.attachSubscriptions(
      this.form.get('card_name').valueChanges.subscribe(res => {
        this.cards.forEach(x => {
          x.card_name = res
        })
      })
    )
    this.attachSubscriptions(
      this.form.get('post_name').valueChanges.subscribe(res => {
        this.cards.forEach(x => {
          x.files.forEach(u => {
            u.post_name = res
          })
        })
      })
    )

    this.getTaskTemplates();
    this.getProfiles();
    this.getGroups();
    this.getForAllContentTypes();

    this.attachSubscriptions(
      this.taskTemplatesControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchBy("name", 'taskTemplates', resp))
    )

    this.attachSubscriptions(
      this.groupsMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchBy("name", 'groups', resp))
    )

    this.attachSubscriptions(
      this.profilesMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchBy("name", 'profiles', resp))
    )
  }

  
  isYouTube(number) {
    // Преобразуем число в строку для удобства работы с символами
    const numStr = number.toString();

    // Проверяем, начинается ли строка с символа '3'
    return numStr.charAt(0) === '1';
  }

  isTikTok(number) {
    // Преобразуем число в строку для удобства работы с символами
    const numStr = number.toString();

    // Проверяем, начинается ли строка с символа '3'
    return numStr.charAt(0) === '3';
  }

  getChannelById(id) {
    if (!this.profiles || this.profiles.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.profiles.find(el => el.id == id)
  }

  titleAI(e, data, cut?) {
    this.resetRes()
    if (cut) {
      this.aiCount = 0
      data.isGettingResults = true;
      this.getCutResults(e, this.aiForm, data)
    } else {
      this.openContext(e, data, true, cut)
    }
  }

  getResults(form, contextdata) {
    this.aiCount++

    let x:any = {
      company_id: this.data.company.id,
      partner_company_id: this.data.company.id != this.data.target_company_id ? this.data.target_company_id : 0,
      messages: []
    }

    let msg = "";
    Object.keys(form.value).forEach(key => {
      if (!!form.value[key]) {
        if (['keywords', 'remembers'].includes(key) && form.value[key].length == 0) {
          return
        }

        if (key == 'keywords') {
          msg += "Use keywords "
        }

        if (['keywords', 'remembers'].includes(key)) {
          msg+= `${form.value[key].join(', ')}. `
        } else {
          msg+= `${form.value[key]}. `
        }

      }
    })

    let content = `${msg}Write ${this.cards.length} options in html ul.`
    if (content !== this.aiSysForm.value.str) {
      content = this.aiSysForm.value.str
    }

    x.messages.push({
      role: 'user',
      content: content
    })

    this.isGettingResults = true;
    this.attachSubscriptions(
      this.aiService.sendAIMsg(x).pipe(
        map(u => {
          if (u.indexOf("<ul>") != -1) {
            const parser = new DOMParser();
            const doc = parser.parseFromString(u, 'text/html');
            const ulElement = doc.querySelector('ul');

            if (ulElement) {
              let liElements = ulElement.querySelectorAll('li');
              let liArray = Array.from(liElements).map(x => (String(x.textContent) as any).replaceAll("\n", "").trim())
              return {parsed: !!liArray && liArray.length ? liArray : false, before: u};
            } else {
              return {parsed: false, before: u}
            }
          } else {
            return {parsed: false, before: u}
          }
        })
      ).subscribe(resp => {
        console.log("getResults", resp)
        if (resp.parsed) {
          this.aiCount = 0;
          this.isGettingResults = false;
          this.aiResults = resp.parsed
          console.log("TESTS", !contextdata.contextData || !contextdata.contextData.is_cut)
          console.log("TESTS", this.aiResults)
          if (!contextdata.contextData || !contextdata.contextData.is_cut) {
            this.setTitlesForParts(this.cards)
          }
        } else {
          if (this.aiCount <= 3) {
            this.getResults(form, contextdata)
          } else {
            this.aiMistake = resp.before
            this.aiCount = 0;
            this.isGettingResults = false;
            this.layoutService.showSnackBar({name: ''}, marker("Incorrect data received, please try again"), SnackBarItem)
          }
        }
      }, error => {
        if (this.aiCount <= 3) {
          this.getResults(form, contextdata)
        } else {
          this.aiMistake = error
          this.aiCount = 0;
          this.isGettingResults = false;
          this.layoutService.showSnackBar({name: ''}, marker("Incorrect data received, please try again"), SnackBarItem)
        }
      })
    )
    // console.log("getResults form", form.value);
    // console.log("getResults x", x);
  }

  getCutResults(e, form, contextdata) {
    this.aiCount++
    let x:any = {
      company_id: this.data.company.id,
      partner_company_id: this.data.company.id != this.data.target_company_id ? this.data.target_company_id : 0,
      messages: []
    }
    
    let msg = "";
    Object.keys(form.value).forEach(key => {
      if (!!form.value[key]) {
        if (['keywords', 'remembers'].includes(key) && form.value[key].length == 0) {
          return
        }
        
        if (key == 'keywords') {
          msg += "Use keywords "
        } 

        if (['keywords', 'remembers'].includes(key)) {
          msg+= `${form.value[key].join(', ')}. `
        } else {
          msg+= `${form.value[key]}. `
        }
  
        // x.messages.push({
        //   role: 'user',
        //   content: msg
        // })
      }
    })
    
    x.messages.push({
      role: 'user',
      content: `${msg}Write ${contextdata.optionsCount} options in html ul.`
    })
    // x.messages.push({
    //   role: 'user',
    //   content: "Important: Answer without extra text, only the answer to my specific request. If I ask you to provide 1 option, give it in the form (for example js array: ['option']). If I ask for more options, give them out without iteration and in the form of a single array where each element of the array is an answer variant (for example js array: ['option1', 'option2', ...])"
    // })

    contextdata.isGettingResults = true;
    this.attachSubscriptions(
      this.aiService.sendAIMsg(x).pipe(
        map(u => {
          if (u.indexOf("<ul>") != -1) {
            const parser = new DOMParser();
            const doc = parser.parseFromString(u, 'text/html');
            const ulElement = doc.querySelector('ul');

            if (ulElement) {
              let liElements = ulElement.querySelectorAll('li');
              let liArray = Array.from(liElements).map(x => (String(x.textContent) as any).replaceAll("\n", "").trim())
              return {parsed: !!liArray && liArray.length ? liArray : false, before: u};
            } else {
              return {parsed: false, before: u}
            }
          } else {
            return {parsed: false, before: u}
          }
        })
      ).subscribe(resp => {
        if (resp) {
          this.aiCount = 0;
          contextdata.isGettingResults = false;
          this.aiResults = resp.parsed
          this.openContext(e, contextdata, true, true)
        } else {
          if (this.aiCount <= 3) {
            this.getCutResults(e, this.aiForm, contextdata)
          } else {
            this.aiCount = 0;
            contextdata.isGettingResults = false;
            this.layoutService.showSnackBar({name: ''}, marker("Incorrect data received, please try again"), SnackBarItem)
          }
        }
      }, error => {
        if (this.aiCount <= 3) {
          this.getCutResults(e, this.aiForm, contextdata)
        } else {
          this.aiCount = 0;
          contextdata.isGettingResults = false;
          this.layoutService.showSnackBar({name: ''}, marker("Incorrect data received, please try again"), SnackBarItem)
        }
      })
    )
  }

  setTitlesForParts(cards) {
    this.closeContext();
    cards.forEach((card,i) => {
      if (card.form && this.aiResults && this.aiResults.length && this.aiResults[i]) {
        card.is_input_set_ai = true;
        card.card_name = this.aiResults[i];
      }
    });
  }

  resetRes() {
    this.aiResults = [];
    this.aiMistake = undefined;
    this.showSys = false;
  }

  selectTitle(item, contentData) {
    console.log("item, contentData", item, contentData)
    if (contentData.contextData && contentData.contextData.is_cut) {
      contentData.contextData.is_input_set_ai = true;
      contentData.contextData.card_name = item;
    } else {
      this.data.is_input_set_ai = true;
      this.form.patchValue({
        name: item
      })
    }
    this.closeContext();
  }

  getResultsReqObject() {
    let x:any = {
      company_id: this.data.company.id,
      partner_company_id: this.data.company.id != this.data.target_company_id ? this.data.target_company_id : 0,
      messages: []
    }
    let form = this.aiForm.value;

    
    let msg = "";
    Object.keys(form).forEach(key => {
      if (!!form[key]) {
        if (['keywords', 'remembers'].includes(key) && form[key].length == 0) {
          return
        }
        
        // if (key == "on_the_video") {
        //   msg += "On the video"
        // } else if (key == "your_idea_of_title") {
        //   msg += "Example of the title"
        // } else if (key == "also_remember") {
        //   msg += "Also remember"
        // } else if (key == 'keywords') {
        //   msg += "Use words"
        // } else if (key == 'remembers') {
        //   msg += "Remember"
        // }

        if (key == 'keywords') {
          msg += "Use keywords "
        }

        if (['keywords', 'remembers'].includes(key)) {
          msg+= `${form[key].join(', ')}. `
        } else {
          msg+= `${form[key]}. `
        }
        // x.messages.push({
        //   role: 'user',
        //   content: msg
        // })
      }
    })
    // x.messages.push({
    //   role: 'user',
    //   content: `Important: Write ${parts.length} options for video title. Answer without extra text, only the answer to my specific request. If I asked you for several options, give them as an array. Where each option is a string and an array element. Without extra text and iteration of options in your answer. (for example js array: ["option1", "option2", ...]).`
    // })
    x.messages.push({
      role: 'user',
      content: `${msg}Write ${this.cards.length} options in html ul.`
    })


    return x
  }

  getGroups() {
    this.attachSubscriptions(
      this.companyService.getInfiniteGroupsCompany(this.data.company_id, '1', '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.companyService.getInfiniteGroupsCompany(this.data.company_id, x).pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        this.groups = [].concat(...res);
        this.groups.unshift({
          id: '',
          name: "Not set"
        });
        this.groups$.next(this.groups.slice())
      })
    )
  }

  onSearchBy(by, key, val) {
    if (!this[key]) {
      return;
    }

    if (!val) {
      this[key+"$"].next(this[key].slice());
      return;
    } else {
      val = val.toLowerCase();
    }

    // filter the banks
    this[key+"$"].next(
      this[key].filter(b => b[by].toLowerCase().indexOf(val) > -1)
    );
  }

  getProfiles() {
    this.attachSubscriptions(
      this.companyService.getAllProfilesDyn('1', this.data.company.id, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }
  
          return forkJoin(arr.map(x => this.companyService.getAllProfilesDyn(x, this.data.company.id, '200').pipe(map(u => u.body)))).pipe(
            last(),
            tap(values => {
              let conValues = [].concat(...values)
              this.profiles = conValues;
              this.profiles$.next(this.profiles.slice());
            }),
          )
        }),
      ).subscribe(resp => {
        console.log("getGroupProfiles resp", resp);
        console.log("getGroupProfiles profiles", this.profiles);
      })
    )
  }
  
  getTaskTemplates() {
    this.attachSubscriptions(
      this.scenariosService.getTaskTemplates('1', {company_id: this.data.company_id}, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.scenariosService.getTaskTemplates(x, {company_id: this.data.company_id}, '200').pipe(map(u => u.body)))).pipe(
            last(),
            tap(values => {
              this.taskTemplates = [].concat(...values)
              this.taskTemplates.unshift({
                id: '',
                name: "Not set"
              })
              console.log("taskTemplates", this.taskTemplates)
              this.taskTemplates$.next(this.taskTemplates.slice())
            }),
          )
        }),
      ).subscribe(resp => {
        console.log("getTaskTemplates sub", resp);
      })
    )
  }

  startDrag(e) {
    console.log("startDrag", e);
    this.is_dragging = true;
  }

  endDrag(e) {
    console.log("startDrag", e);
    this.is_dragging = false;
  }

  deleteCard(card, i) {
    this.cards.splice(i, 1);
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
  }
  

  onImgError(event){
    event.target.src = this.data.imgRoute+'/assets/img/image_black_48dp.svg'
  }

  log() {
    console.log('cards', this.cards)
  }

  close() {
    this.dialogRef.close({event: "close", data: false});
  }

  save(is_draft:boolean = false) {
    console.log("save", is_draft)
    this.attachSubscriptions(
      this.fileService.createCardProject({
        company_id: this.data.company_id,
        name: this.projectName,
        is_draft: 1,
        is_to_process: 0,
        custom_data: JSON.stringify({
          cards: this.cards,
          publications: this.publications
        }, this.getCircularReplacer())
      }, this.data.company_id).pipe(
        switchMap(project => {
          console.log("project0", project)
          if (this.cards.length) {
            console.log("project1", project)
            let usedOutlets = []
            let items = [];
            this.cards.forEach(card => {
              console.log("before activeT")
              let activeT = JSON.parse(JSON.stringify(this.getItemById(this.taskTemplates, this.form.get('template_id').value), this.getCircularReplacer()));
              console.log("after activeT", activeT)
              let cardData:any = {
                company_id: this.data.company_id,
                task_create_project_id: project.id,
                task_template_id: this.form.value.template_id,
                template_data: Object.assign(activeT.template_data, {name: card.card_name}),
                files_ids: '',
                task_channels: []
              }
              console.log("cardData", cardData);
              cardData.files_ids = card.files.map(u => u.original_file_id).join(',');
              card.files.forEach(file => {
                console.log("file", file);

                file.publications.forEach(pub => {
                  let cont_name = '';
                  if (file.post_name) {
                    cont_name = file.post_name;
                  } else if (card.card_name) {
                    cont_name = card.card_name;
                  } else {
                    cont_name = file.filename;
                  }
                  pub.content_name = pub.content_type_id && this.isYouTube(pub.content_type_id) && cont_name.length > 100 ? cont_name.slice(0,97)+'...' : cont_name;
                  pub.content_description = cont_name;
                  pub.task_channel_files[0].original_file_id = file.original_file_id;
                  pub.content_plan_min_interval = pub.content_plan_min_interval + usedOutlets.filter(k => k.channel_id == pub.channel_id && k.content_type_id == pub.content_type_id).length;
                          
                  if (pub.content_type_id && this.isTikTok(pub.content_type_id)) {
                    pub.publishing_params = {
                      privacy_level : 'PUBLIC_TO_EVERYONE',
                      disable_duet: true,
                      disable_stitch: true,
                      disable_comment: false,
                      brand_content_toggle: false,
                      brand_organic_toggle: false,
                    }
                  }
                  cardData.task_channels.push(pub);
                  usedOutlets.push({channel_id: pub.channel_id, content_type_id: pub.content_type_id})
                });
              })

              items.push(cardData);
            })

            console.log("ITEMS", items);

            return forkJoin(items.map(x => this.fileService.createCardProjectItem(x, this.data.company_id))).pipe(
              map(projItems => {
                return {
                  projItems: projItems,
                  project: project
                }
              })
            )
          } else {
            console.log("project2", project)
            return of({
              project: project,
              projItems: []
            })
          }
        }),
        switchMap(result => {
          if (!is_draft) {
            return this.fileService.editCardProject(result.project.id, {is_draft: 0, is_to_process: 1}, this.data.company_id).pipe(
              map(editedProject => {
                return {
                  editedProject: editedProject,
                  project: result.project,
                  projItems: result.projItems
                }
              })
            )
          } else {
            return of(result)
          }
        })
      ).subscribe(res => {
        console.log("save", res);
        this.layoutService.showSnackBar({name: 'Project created'}, '', SnackBarItem)
        this.close();
      }, error => {
        this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
      })
    )
  }

  getCircularReplacer() {
    const ancestors:any = [];
    return function (key, value) {
      if (typeof value !== "object" || value === null) {
        return value;
      }
      // `this` is the object that value is contained in,
      // i.e., its direct parent.
      while (ancestors.length > 0 && ancestors.at(-1) !== this) {
        ancestors.pop();
      }
      if (ancestors.includes(value)) {
        return "[Circular]";
      }
      ancestors.push(value);
      return value;
    };
  }

  selectTmpl(e, card?) {
    console.log(e, card)
    if (card) {
      // if (e.value === '') {
      //   card.form.patchValue({
      //     type: 0
      //   })
      // } else {
      //   let tmpl = this.taskTemplates.find(x => x.id == e.value)
      //   card.form.patchValue({
      //     type: 1,
      //     name: tmpl.template_data.name
      //   })
      // }
      // card.is_custom.patchValue(true)
      // card.is_input_set_ai = false;
      // this.saveVideoEditorHistory();
    } else {
      console.log('e.value', e.value);
      if (e.value === '') {
        // this.form.patchValue({
        //   type: 0
        // })
      } else {
        let tmpl = this.taskTemplates.find(x => x.id == e.value)
        this.form.patchValue({
          name: tmpl.template_data.name
        })
      }
    }
  }

  closeContext() {
    console.log("closeContext")
    this.profilesTab = 0;
    // this.regularDateTimes = [];
    // this.appPublicationData = {
    //   channel_id: '',
    //   content_type_id: '',
    //   content_status_id: 6,
    //   content_published_at: '',
    //   content_name: '',
    //   content_description: '',
    //   task_channel_files: [{
    //     original_file_id: 0,
    //     is_content: 1,
    //     is_preview: 0,
    //     is_thumbnail: 0,
    //     is_original: 0,
    //     is_to_upload: 1
    //   }]
    // }
    this.backContextSub && this.backContextSub.unsubscribe();
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
  }

  openContext({ x, y }: MouseEvent, contextData, is_ai?, is_cut?) {
    this.closeContext();
    console.log("{ x, y }: MouseEvent, contextData, group_id", { x, y }, contextData)
    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo({ x, y })
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        }
      ]);

    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });

    contextData.is_cut = is_cut

    let conData:any = {
      contextData: contextData
    }

    if (is_ai) {
      conData.is_ai = is_ai
    } else {
      let outlets = []
      let setupGroups = [];
      let activeT = this.getItemById(this.taskTemplates, this.form.get('template_id').value);
      if (activeT && activeT.template_data.group_id && !setupGroups.includes(activeT.template_data.group_id)) {
        setupGroups.push(activeT.template_data.group_id)
      }
      // let parts = (this.data.tracks.filter(x => x.type == 'video').map(k => k.parts) as any).flat(Infinity)
      // parts.forEach(part => {
      //   if (part.form) {
      //     if (part.form.get('template_id').value) {
      //       let activeT = this.getItemById(this.taskTemplates, part.form.get('template_id').value);
      //       if (activeT && activeT.template_data.group_id && !setupGroups.includes(activeT.template_data.group_id)) {
      //         setupGroups.push(activeT.template_data.group_id)
      //       }
      //     } else if (part.form.value.group_id) {
      //       if (!setupGroups.includes(part.form.value.group_id)) {
      //         setupGroups.push(part.form.value.group_id)
      //       }
      //     }
      //   }
      // })

      console.log("setupGroups", setupGroups)

      if (setupGroups && setupGroups.length) {
        setupGroups.forEach(group_id => {
          console.log("this.getGroupById(group_id)", this.getGroupById(group_id))
          if (this.getGroupById(group_id)) {
            let channelToGroup = this.getGroupById(group_id).channelToGroup;
      
            channelToGroup.forEach((chan:any) => {
              chan.is_selected = false;
              chan.fromGroupId = group_id;
              chan.publData = {
                channel_id: chan.channel_id,
                content_type_id: chan.content_type_id,
                content_status_id: 6,
                content_published_at: 0,
                is_content_plan: 1,
                content_plan_start_at: 0,
                content_plan_min_interval: 0,
                content_name: '',
                content_description: '',
                task_channel_files: [{
                  original_file_id: 0,
                  is_content: 1,
                  is_preview: 0,
                  is_thumbnail: 0,
                  is_original: 0,
                  is_to_upload: 1
                }]
              }
              if (!!chan.content_type_id) {
                if (outlets.filter(x => x.content_type_id == chan.content_type_id).length == 0) {
                  outlets.push({
                    content_type_id: chan.content_type_id,
                    platform: chan.channel.platform,
                    contentType: this.getTypeById(chan.content_type_id),
                    arr: [chan],
                  })
                } else {
                  outlets.find(x => x.content_type_id == chan.content_type_id).arr.push(chan)
                }
              }
            })
  
            conData.group = this.getGroupById(group_id);
          }
        })
        conData.outlets = outlets;
      }
    }
    

    this.overlayRef.attach(new TemplatePortal(this.contextMenu, this.viewContainerRef, {
      $implicit: conData
    }));

    console.log("this.contextMenu", this.contextMenu)
    console.log("conData.outlets", conData.outlets)

    this.backContextSub = fromEvent<MouseEvent>(document, 'click')
      .pipe(
        filter(event => {
          const clickTarget = event.target as HTMLElement;
          return !!this.overlayRef && !this.overlayRef.overlayElement.contains(clickTarget) && !clickTarget.closest('.list_of_keywords'); 
        }),
        take(1)
      ).subscribe(() => this.closeContext())

  }

  nextTab() {
    if (this.profilesTab == 0) {

    }
    this.profilesTab = this.profilesTab + 1;
  }
  
  deletePublications(card?) {
    this.publications = [];
    this.cards.forEach((card, i) => {
      card.files.forEach(file => {
        file.publications = [];
      });
    });
  }

  finishPubl(outlets) {
    console.log("finishPubl", outlets)
    let pubs = [];
    outlets.forEach(outlet => {
      outlet.arr.forEach(item => {
        if (item.is_selected) {
          pubs.push(item)
          // pubs.push(item.publData)
        }
      });
    });
    this.publications = pubs;
    console.log("finishPubl 2", this.publications)


    // parts.forEach(part => {
    //   if (part.form) {
    //     if (part.form.get('template_id').value) {
    //       let activeT = this.getItemById(part.taskTemplates, part.form.get('template_id').value);
    //       if (activeT && activeT.template_data.group_id && !setupGroups.includes(activeT.template_data.group_id)) {
    //         setupGroups.push(activeT.template_data.group_id)
    //       }
    //     } else if (part.form.value.group_id) {
    //       if (!setupGroups.includes(part.form.value.group_id)) {
    //         setupGroups.push(part.form.value.group_id)
    //       }
    //     }
    //   }
    // })

    this.cards.forEach((card, i) => {

      // let cardGroupId;

      // if (card.form) {
      //   if (card.form.get('template_id').value) {
      //     let activeT = this.getItemById(this.taskTemplates, card.form.get('template_id').value);
      //     if (activeT && activeT.template_data.group_id) {
      //       cardGroupId = activeT.template_data.group_id;
      //     }
      //   } else if (card.form.value.group_id) {
      //     cardGroupId = card.form.value.group_id;
      //   }
      // }

      card.files.forEach(file => {
        file.tags_ids = file.parameterValuesToTask.map(x => x.parameter_value_id);
        this.publications.forEach(pub => {
          pub.tags_ids = this.getChannelById(pub.channel_id).parameterValuesToChannel.map(x => x.parameter_value_id)
          if (this.checkForMatches(pub.tags_ids, file.tags_ids)) {
            file.publications.push(JSON.parse(JSON.stringify(pub.publData, this.getCircularReplacer())))
          }
        });     
      });


      // if (cardGroupId) {
      //   let pubsData = JSON.parse(JSON.stringify(this.publications, this.getCircularReplacer()))
      //   pubsData.map(x => x.publData.content_plan_min_interval = x.publData.content_plan_min_interval + 1)
      //   card.publications = pubsData.filter(x => x.fromGroupId == cardGroupId).map(x => x.publData);

      //   console.log("card.publications", card.publications)
      // }



      // if (i == this.cards.length - 1) {
      // }
    });
    this.closeContext();
  }

  checkForMatches(arr1, arr2) {
    // Создаем множества из элементов массивов для быстрого поиска
    let set1 = new Set(arr1);
    let set2 = new Set(arr2);
    
    // Проверяем каждый элемент из первого массива на наличие во втором
    for (let num of set1) {
        if (set2.has(num)) {
            return true; // Если нашли совпадение, возвращаем true
        }
    }
    
    // Если не нашли совпадений, возвращаем false
    return false;
}

  getForAllContentTypes() {
    this.attachSubscriptions(
      this.companyService.getContentTypes().pipe(
        map(el => el.body)
      ).subscribe(resp => {
        this.forAllContentTypes = resp;
      })
    )
  }
  
  checkOutletsCount(arrOut) {
    let count = 0;

    arrOut.forEach(el => {
      count = count + el.arr.length;
    })
    
    return count
  }
  
  selectAll(arrOut) {    
    arrOut.forEach(el => {
      el.arr.forEach(elIn => {
        elIn.is_selected = true;
      })
    })
  }
  
  getGroupById(id) {
    if (!this.groups || this.groups.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.groups.find(el => el.id == id)
  }

  getTypeById(id) {
    if (!this.forAllContentTypes || this.forAllContentTypes.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.forAllContentTypes.find(el => el.id == id)
  }

  getItemById(arr, id) {
    return arr.find(x => x.id == id)
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

}
