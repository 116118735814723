<div class="container">
    <div class="parameters_page">
        <div class="company_title" *ngIf="company">
            {{ company?.name }} - {{ "tags" | translate }}:
        </div>
        <div class="parameters_wrp" [appDeviceClass]="'custom-scroll custom-scroll-t'">
            <div class="parameter" *ngFor="let param of parameters; let i = index;">
                <div class="param_info">
                    <div class="name_wrp">
                        <ng-container *ngIf="param.isEddited; else elseTemplateEddited">
                            <mat-form-field appearance="legacy">
                                <mat-label>{{ "Tag category name" | translate }}</mat-label>
                                <input matInput type="text" class="name_input" (focusout)="saveApiParam(param)" type="text" id="param_{{param.id}}" [(ngModel)]="param.name" (change)="editApiParameter(param, $event)">
                            </mat-form-field>
                            <mat-icon style="color: blue;" (click)="saveApiParam(param)">done</mat-icon>
                        </ng-container>
                        <ng-template #elseTemplateEddited>
                            <div class="name" (dblclick)="focusApiParam(param)">
                                {{param.name}} <span *ngIf="!!param.original_id" style="font-weight: 300; font-size: 10px; line-height: 1; color: green;">({{ "From collection" | translate }})</span>
                            </div>
                            <div class="d-f-c">
                                <button *ngIf="!!param.is_new_value" class="mr-1" mat-stroked-button (click)="getNewValues(param)">{{ "What new" | translate }}</button>
                                <button *ngIf="!!param.is_new_value" class="mr-1" mat-raised-button color="primary" (click)="updateParameter(param)">
                                    <mat-icon class="mr-1">update</mat-icon>
                                    {{ "Update" | translate }}
                                </button>
                                <button *ngIf="param?.tips_count > 0" mat-icon-button style="color: #333;" (click)="openTips(param)">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" style="width: 18px; height: 18px;"><path d="M16 12V16H2V12H16ZM17 10H1C0.45 10 0 10.45 0 11V17C0 17.55 0.45 18 1 18H17C17.55 18 18 17.55 18 17V11C18 10.45 17.55 10 17 10ZM4 15.5C3.18 15.5 2.5 14.83 2.5 14C2.5 13.17 3.18 12.5 4 12.5C4.82 12.5 5.5 13.17 5.5 14C5.5 14.83 4.83 15.5 4 15.5ZM16 2V6H2V2H16ZM17 0H1C0.45 0 0 0.45 0 1V7C0 7.55 0.45 8 1 8H17C17.55 8 18 7.55 18 7V1C18 0.45 17.55 0 17 0ZM4 5.5C3.18 5.5 2.5 4.83 2.5 4C2.5 3.17 3.18 2.5 4 2.5C4.82 2.5 5.5 3.18 5.5 4C5.5 4.82 4.83 5.5 4 5.5Z" fill="#333"></path></svg>
                                </button>
                                <button mat-icon-button style="color: green;" (click)="focusApiParam(param)">
                                    <mat-icon>edit</mat-icon>
                                </button>
                                <button mat-icon-button style="color: red;" (click)="deleteApiParam(param, i)">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </div>
                        </ng-template>
                    </div>
                    <button mat-icon-button color="primary" (click)="toggleParam(param)">
                        <mat-icon>
                            {{ param.isOpen ? 'expand_less' : 'expand_more' }}
                        </mat-icon>
                    </button>
                </div>
                <div class="param_values" *ngIf="param.isOpen"
                cdkDropList
                [cdkDropListData]="param.values"
                (cdkDropListDropped)="drop($event)">
                    <div class="param_value" *ngFor="let val of param.values; let k = index;" cdkDrag [cdkDragData]="val">
                        <div class="value_wrp">
                            <ng-container *ngIf="val.isEddited; else elseTemplateEddited">
                                <mat-form-field appearance="legacy">
                                    <mat-label>{{ "Tag name" | translate }}</mat-label>
                                    <input matInput type="text" class="value_input" (focusout)="saveValue(param, val)" type="text" id="val_{{param.id}}_{{val.id}}" [(ngModel)]="val.value" (change)="editApiParameterValue(param, val, $event)">
                                </mat-form-field>
                                <mat-icon style="color: blue;" (click)="saveValue(param, val)">done</mat-icon>
                            </ng-container>
                            <ng-template #elseTemplateEddited>
                                <div class="d-f-c">
                                    <div class="value" (dblclick)="focusValue(param, val)">
                                        {{val.value}}

                                        <div class="original" *ngIf="val.original">
                                            {{val.original.value}}
                                        </div>
                                    </div>
                                    <div class="value_assigned" *ngIf="val.parameter && (val.parameter.is_file_meta_device == 1 || val.parameter.is_file_meta_orientation == 1)">
                                        <mat-checkbox color="primary" (change)="changeValueAssigned($event, val)" [(ngModel)]="val.is_not_automatically_assigned">{{ "Not Auto-Assigned" | translate }}</mat-checkbox>
                                    </div>
                                </div>
                                <div class="d-f-c">
                                    <button *ngIf="val?.tips_count > 0" mat-icon-button style="color: #333;" (click)="openTips(param, val)">
                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" style="width: 18px; height: 18px;"><path d="M16 12V16H2V12H16ZM17 10H1C0.45 10 0 10.45 0 11V17C0 17.55 0.45 18 1 18H17C17.55 18 18 17.55 18 17V11C18 10.45 17.55 10 17 10ZM4 15.5C3.18 15.5 2.5 14.83 2.5 14C2.5 13.17 3.18 12.5 4 12.5C4.82 12.5 5.5 13.17 5.5 14C5.5 14.83 4.83 15.5 4 15.5ZM16 2V6H2V2H16ZM17 0H1C0.45 0 0 0.45 0 1V7C0 7.55 0.45 8 1 8H17C17.55 8 18 7.55 18 7V1C18 0.45 17.55 0 17 0ZM4 5.5C3.18 5.5 2.5 4.83 2.5 4C2.5 3.17 3.18 2.5 4 2.5C4.82 2.5 5.5 3.18 5.5 4C5.5 4.82 4.83 5.5 4 5.5Z" fill="#333"></path></svg>
                                    </button>
                                    <button mat-icon-button style="color: green;" (click)="focusValue(param, val)">
                                        <mat-icon>edit</mat-icon>
                                    </button>
                                    <button mat-icon-button style="color: red;" (click)="deleteValue(param, val, k)">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                    <button mat-icon-button style="color: #686868;" cdkDragHandle>
                                        <mat-icon>drag_handle</mat-icon>
                                    </button>
                                </div>
                            </ng-template>
                            
                        </div>
                    
                    </div>
                    <div class="d-f-c" style="justify-content: flex-end;">
                        <button class="add_btn" mat-raised-button color="primary" (click)="addApiParameterValue(param)" *ngIf="company?.permissions.includes('owner') || company?.permissions.includes('admin')">
                            <mat-icon>add</mat-icon>
                            {{ "Tag" | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="btns_line">
            <button mat-raised-button type="button" (click)="goBack()">
                {{ "Back" | translate }}
            </button>
            <button class="ml-1" mat-raised-button color="primary" (click)="addApiParameter()" *ngIf="company?.permissions.includes('owner') || company?.permissions.includes('admin')">
                <mat-icon>add</mat-icon>
                {{ "Tag Category" | translate }}
            </button>
            <button class="ml-1" mat-stroked-button color="warn" (click)="addParameterFromCollection()">
                <mat-icon>add</mat-icon>
                {{ "From Tags Collection" | translate }}
            </button>
        </div>
    </div>
</div>