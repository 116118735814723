

  <h2 mat-dialog-title class="dialog_title">
    <span>{{ "Additional employees" | translate }}</span>
    <button mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h2>

<mat-dialog-content class="mat-typography">
    <div class="container">
        <mat-list role="list">
            <mat-list-item role="listitem" *ngFor="let addEmpl of additionalEmpls; let i = index;">
                <div class="add_empl">
                    <div class="add_empl_val">
                        <app-user [class]="'user_div mr-1'" [employee]="addEmpl?.employee"></app-user>
                        {{ getOperationStatusName(addEmpl.status_id) | translate }} 
                        
                        <b class="mr-1 ml-1" style="color:green;">{{ "added to" | translate }}</b>

                        <ng-container *ngIf="addEmpl?.discussion_id; else elseTemplatediscussion_id">
                            <div class="d-f-c">
                                <span class="mr-1">
                                    {{ "Chat" | translate }}: 
                                </span>
                                {{addEmpl?.discussion?.name}}
                            </div>
                        </ng-container>
                        <ng-template #elseTemplatediscussion_id>
                            <div class="d-f-c">
                                <span class="mr-1">
                                    {{ "Work" | translate }}: 
                                </span>
                                
                                <app-user [class]="'user_div mr-1'" [employee]="data?.work?.employee?.employee"></app-user>
                                {{ getOperationStatusName(addEmpl.status_id) | translate }} 
                            </div>
                        </ng-template>
                        
                    </div>
                    <div class="add_empl_btns" style="padding-left: 50px;" *ngIf="data?.company?.permissions.includes('owner') || checkIsManager(data?.task, data?.company, data?.user)">
                        <mat-icon style="color: green;" (click)="goToEditAddEmpl(addEmpl)">edit</mat-icon>
                        <mat-icon style="color: brown;" (click)="deleteAddEmpl(addEmpl, i)">delete</mat-icon>
                    </div>
                </div>
            </mat-list-item>
        </mat-list>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-raised-button (click)="close()">
        {{ "Back" | translate }}
    </button>
    <button class="ml-1 d-f-btn" (click)="addAdditionalEmplToWork()" mat-raised-button color="primary">
        {{ "Add" | translate }}
    </button>
</mat-dialog-actions>