<mat-dialog-title class="dialog_title profile_dialog_title">   
    <div class="d-f-c"> 
        <span class="info">
            {{ "Publish to" | translate }}
        </span>
        <div class="for_space users_select">
            <mat-chip-list [multiple]="false" [selected]="false">
                <mat-chip class="chip_user" style="padding-left: 0 !important;">
                    <div class="user_div">
                        <app-platform [platform_id]="data?.profile?.channel?.platform_id"></app-platform>
                        <span>{{ data.profile?.channel?.name }}</span>
                    </div>
                </mat-chip>
            </mat-chip-list>
            <!-- <span style="color: #1877F2; cursor: pointer;" (click)="profilesContainer.open()">
                {{ "Change" | translate }}
            </span>
    
            <form [formGroup]="profileForm">
                <mat-form-field appearance="standard" class="full_width_form">
                    <mat-label>{{ "Choose a profile" | translate }}</mat-label>
                    <mat-select [disableOptionCentering]="true" selectDir #profilesContainer formControlName="channel_id" required>
                        <mat-option *ngFor="let profile of data.task.uniqueChannels" [value]="profile.id">
                            <div class="platform_wrp">
                                <app-platform [platform_id]="profile?.channel?.platform_id"></app-platform>
                                <span class="ml-1">{{ profile.channel.name }}</span>
                            </div>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </form> -->
        </div>

        <div class="tik_tok ml-1" *ngIf="tikTok">
            <img [src]="tikTok.data.creator_avatar_url" alt="">
            <span>{{tikTok.data.creator_nickname}}</span>
        </div>
    </div>

    <a href="https://www.youtube.com/" *ngIf="data?.profile?.channel?.platform_id == 1" target="_blank" class="yt_log"><img src="{{ data?.imgRoute }}assets/img/developed-with-youtube-lowercase-light.png" alt=""></a>
    
    <button *ngIf="data?.profile?.channel?.platform_id != 1" mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</mat-dialog-title>

<mat-dialog-content class="mat-typography">
    <div class="container form_wrapper">
        <form [formGroup]="form" class="form_with_pads form_with_valids">
  
            <div class="white_block">
                <!-- <div class="white_block_title">
                    {{ "Publication identification" | translate }}
                </div> -->
                <div class="preview_block">
                    <div class="preview">
                        <div class="outlet">
                            <ng-container *ngIf="data?.profile?.channel.avatarFile; else elseTemplateNoPhoto">
                                <div class="channel_avatar">
                                    <img [src]="host + data?.profile?.channel.avatarFile.original + '?company_id=' + data?.company_id" alt="">
                                    <div class="channel_avatar_platform">
                                        <app-platform [platform_id]="data?.profile?.channel?.platform_id"></app-platform>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-template #elseTemplateNoPhoto>      
                                <div class="platform_icon">
                                    <app-platform [platform_id]="data?.profile?.channel?.platform_id"></app-platform>
                                </div>
                            </ng-template>
                            <div class="outlet_lines">
                                <div>{{data?.profile?.channel?.name}}</div>
                                <div>{{getContentById(form.get('content_type_id').value)?.name}} - {{data?.file?.meta_duration | timeFormat}}</div>
                            </div>
                        </div>
                        <video *ngIf="pbType == 'video'" width="100%" class="player" controls #player>
                            <source [src]="host + (data?.file?.preview1080 ? data?.file?.preview1080 : data?.file?.original) + '?company_id=' + data?.company_id" [type]="data?.file?.content_type == 'video/quicktime' ? 'video/mp4' : data?.file?.content_type"/>
                            Your browser doesn't support HTML5 video tag.
                        </video>
                        <img *ngIf="['image', 'ig_image', 'ig_carousel'].includes(pbType)" style="max-width: 100%; max-height: 100%; object-fit: cover; width: 100%; height: 100%;" [src]="host + (data?.file.thumbnail ? data?.file.thumbnail : data?.file?.original) + '?company_id=' + data?.company_id">
                        <div class="desc" *ngIf="data?.profile?.channel?.platform_id != 3" [innerHTML]="form?.get('desc')?.value.replaceAll('\n','<br>') || ('Description preview' | translate)"></div>
                    </div>

                    <ng-container *ngIf="data?.profile?.channel?.platform_id != 3; else elseTemplateIsTikTok">
                        <div class="desc_and_thumb">
                            <ng-container *ngIf="[404, 402, 202, 201, 206].includes(form.get('content_type_id').value); else elseTemplateHasName">
                                <mat-form-field appearance="standard" class="full_width_form">
                                    <mat-label>{{ [404, 202].includes(form.get('content_type_id').value) ? 'Reel description' : 'Description' | translate }}</mat-label>
                                    <textarea 
                                    cdkTextareaAutosize
                                    #autosize="cdkTextareaAutosize"
                                    cdkAutosizeMinRows="3"
                                    cdkAutosizeMaxRows="9" formControlName="desc" matInput placeholder="{{ [404, 202].includes(form.get('content_type_id').value) ? 'Reel description' : 'Description' | translate }}"></textarea>
                                    <mat-hint class="char_counter_hint" *ngIf="form.get('desc').value" [ngClass]="{'red': form.get('desc').value.length > 999999999}" align="end">{{form.get('desc').value.length}}</mat-hint>
                                </mat-form-field>
                            </ng-container>
                            <ng-template #elseTemplateHasName>
                                <mat-form-field appearance="standard" class="full_width_form" >
                                    <mat-label>{{ 'Title' | translate }}</mat-label>
                                    <textarea 
                                    cdkTextareaAutosize
                                    #autosize="cdkTextareaAutosize"
                                    cdkAutosizeMinRows="1"
                                    cdkAutosizeMaxRows="2" formControlName="name" matInput placeholder="{{ 'Title' | translate }}"></textarea>
                                    <mat-hint class="char_counter_hint" *ngIf="form.get('name').value" [ngClass]="{'red': form.get('name').value.length > 999999999}" align="end">{{form.get('name').value.length}}</mat-hint>
                                </mat-form-field>
            
                                <mat-form-field appearance="standard" class="full_width_form">
                                    <mat-label>{{ 'Description' | translate }}</mat-label>
                                    <textarea 
                                    cdkTextareaAutosize
                                    #autosize="cdkTextareaAutosize"
                                    cdkAutosizeMinRows="2"
                                    cdkAutosizeMaxRows="4" formControlName="desc" matInput placeholder="{{ 'Description' | translate }}"></textarea>
                                    <mat-hint class="char_counter_hint" *ngIf="form.get('desc').value" [ngClass]="{'red': form.get('desc').value.length > 999999999}" align="end">{{form.get('desc').value.length}}</mat-hint>
                                </mat-form-field>
                            </ng-template>
    
    
                            <ng-container *ngIf="['image', 'ig_carousel'].includes(pbType); else elseTemplatePB">                                
                                <div class="thumb photos_wrp">
                                    <div class="btn" (click)="selectFile(true)">
                                        {{ "More photo(s)" | translate }}
                                    </div>
        
                                    <div class="more_photos">
                                        <div class="more_photo" *ngFor="let photo of morePhotos; let i = index;">
                                            <img [src]="host + (photo.thumbnail ? photo.thumbnail : photo.original) + '?company_id=' + data?.company?.id" alt="">
                                            <mat-icon class="remove_more_photo" (click)="removeMorePhoto(photo, i)">close</mat-icon>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-template #elseTemplatePB>                                
                                <div class="thumb" *ngIf="pbType != 'ig_image'">
                                    <div class="d-f-c">
                                        <div class="btn" (click)="selectFile()">
                                            {{ "Custom thumbnail" | translate }}
                                        </div>
                                        <div class="btn ml-1" (click)="videoEditorV3()">
                                            {{ "Use video Frame" | translate }}
                                        </div>
                                        <div class="btn ml-1" (click)="selectFile(false, true)">
                                            {{ "Get text" | translate }}
                                        </div>
                                    </div>

                                    <div *ngIf="!!frame_ms" class="thumb_file">
                                        <div class="img_out">
                                            <div class="btn_remove" (click)="removeFrame()">
                                                <mat-icon>close</mat-icon>
                                            </div>
                                            <video width="100%" class="player" [controls]="false" (loadedmetadata)="loadedVideo($event)">
                                                <source [src]="host + (data?.file?.preview1080 ? data?.file?.preview1080 : data?.file?.original) + '?company_id=' + data?.company_id" [type]="data?.file?.content_type == 'video/quicktime' ? 'video/mp4' : data?.file?.content_type"/>
                                                Your browser doesn't support HTML5 video tag.
                                            </video>
                                        </div>
                                    </div>
        
                                    <div *ngIf="thumbFile" class="thumb_file">
                                        <div class="img_out">
                                            <div class="btn_remove" (click)="removeThumb()">
                                                <mat-icon>close</mat-icon>
                                            </div>
                                            <img [src]="host + (thumbFile.thumbnail ? thumbFile.thumbnail : thumbFile.original) + '?company_id=' + data?.company?.id" alt="">
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                            <ng-container *ngIf="data?.profile?.channel?.platform_id == 1">
                                <ng-container formGroupName="publishing_params">
                                    <div class="d-f-c">
                                        <div class="tik_tok_wrp" style="margin-top: 15px;">
                                            <div class="tik_title">
                                                {{"Privacy status" | translate}}
                                            </div>
                                            <mat-button-toggle-group class="tik_tok_btn_group" formControlName="privacy">
                                              <mat-button-toggle [value]="'public'">{{"Public" | translate}}</mat-button-toggle>
                                              <mat-button-toggle [value]="'private'">{{"Private" | translate}}</mat-button-toggle>
                                              <mat-button-toggle [value]="'unlisted'">{{"Unlisted" | translate}}</mat-button-toggle>
                                            </mat-button-toggle-group>
                                        </div>

                                        <div class="box_wrp ml-1">
                                            <mat-checkbox formControlName="made_for_kids" color="primary">{{"Made for kids" | translate}}</mat-checkbox>
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>

                            <ng-container *ngIf="data?.profile?.channel?.platform_id == 4 || [201, 202, 206].includes(form.get('content_type_id').value)">
                                <form class="example-form">
                                    <mat-form-field class="full_width_form">
                                        <mat-label>{{"Location" | translate}}</mat-label>
                                        <input type="text" matInput [formControl]="myControl" [matAutocomplete]="auto">
                                        <mat-icon matSuffix>place</mat-icon>
                                        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="selected($event)">
                                          <mat-option *ngFor="let option of filteredOptions$ | async" [value]="option">
                                            {{option.name}}
                                          </mat-option>
                                        </mat-autocomplete>
                                        <mat-hint>{{"In case no matches enter code of location. Code you can find" | translate}} <a target="_blank" href="https://www.facebook.com/places/">{{"here" | translate}}.</a></mat-hint>
                                    </mat-form-field>
                                </form>
                            </ng-container>
                        </div>
                    </ng-container>
                    <ng-template #elseTemplateIsTikTok>
                        
                        <div *ngIf="form.get('publishing_params')" class="tik_tok_out">

                            <mat-form-field appearance="standard" class="full_width_form">
                                <mat-label>{{ 'Name' | translate }}</mat-label>
                                <textarea 
                                cdkTextareaAutosize
                                #autosize="cdkTextareaAutosize"
                                cdkAutosizeMinRows="1"
                                cdkAutosizeMaxRows="9" formControlName="name" matInput placeholder="{{ 'Name' | translate }}"></textarea>
                                <mat-hint class="char_counter_hint" *ngIf="form.get('name').value" [ngClass]="{'red': form.get('name').value.length > 999999999}" align="end">{{form.get('name').value.length}}</mat-hint>
                            </mat-form-field>

                            <ng-container formGroupName="publishing_params">
                                <div class="tik_tok_wrp" *ngIf="tikTok">
                                    <div class="tik_title">
                                        {{"Who can view this video" | translate}}
                                    </div>
                                    <mat-button-toggle-group class="tik_tok_btn_group" formControlName="privacy_level" aria-label="Font Style">
                                      <mat-button-toggle #tooltip="matTooltip" 
                                      matTooltip="{{ (item + '_desk') | translate }}" 
                                      [matTooltipPosition]="'above'" *ngFor="let item of tikTok.data.privacy_level_options" [value]="item">{{item | translate}}</mat-button-toggle>
                                    </mat-button-toggle-group>
                                </div>
        
                                <div class="allow_users">
                                    <div class="tik_title">
                                        {{"Allow users to" | translate}}
                                    </div>
    
                                    <div class="d-f-c">
                                        <p>
                                            <mat-checkbox color="primary" formControlName="disable_duet">{{ "disable_duet" | translate }}</mat-checkbox>
                                        </p>
                                        <p>
                                            <mat-checkbox color="primary" formControlName="disable_stitch">{{ "disable_stitch" | translate }}</mat-checkbox>
                                        </p>
                                        <p>
                                            <mat-checkbox color="primary" formControlName="disable_comment">{{ "disable_comment" | translate }}</mat-checkbox>
                                        </p>
                                    </div>
                                </div>

                                <div class="tt_title">
                                  <span>{{ "Disclose video content" | translate }}</span>
                                  <mat-slide-toggle [hideIcon]="true" color="primary" (change)="onChangeDisc($event)"></mat-slide-toggle>
                                </div>
                                <ng-container *ngIf="isChecked">
                                    <div class="tt_p">{{ "Turn on to disclose that this video promotes goods or services in exchange for something of value. Your video could promote yourself, a third party, or both." | translate }}</div>
                                    <div class="tt_title">
                                        <span>{{ "Your brand" | translate }}</span>
                                        <mat-checkbox color="primary" formControlName="brand_organic_toggle"></mat-checkbox>
                                    </div>
                                    <div class="tt_p">{{ "You are promoting yourself or your own business. This video will be classified as Brand Organic" | translate }}</div>
                                    <div class="tt_title">
                                        <span>{{ "Branded content" | translate }}</span>
                                        <mat-checkbox color="primary" formControlName="brand_content_toggle"></mat-checkbox>
                                    </div>
                                    <div class="tt_p">{{ "You are promoting another brand or a third party. This video will be classified as Branded Content. By posting, you agree to" | translate }} <a target="_blank" href="https://www.tiktok.com/legal/page/global/bc-policy/en">{{ "TikTok's Branded Content Policy" | translate }}</a></div>
                                    <div class="tt_p">{{ "By posting, you agree to our" | translate }} <a target="_blank" href="https://www.tiktok.com/legal/page/global/music-usage-confirmation/en">{{ "Music Usage Confirmation" | translate }}</a>.</div>
                                </ng-container>
                                

                            </ng-container>
                            
                        </div>
                    </ng-template>


                    
                </div>
    
            </div>  

            <!-- *ngIf="contentTypes?.length > 1" -->
            <div class="white_block" *ngIf="!!contentTypes?.length">
                <div class="white_block_title">
                    {{ "Publication Type" | translate }}
                </div>
                <div class="small_chips">
                    <ng-container *ngIf="!form.get('content_type_id').value && submited">
                        <div class="req_field"></div>
                        <div class="req_label">
                            <div class="angle"></div>
                            <span>
                                {{ "You need to choose a value" | translate }}
                            </span>
                        </div>
                    </ng-container>
                    <!-- <label>{{ "Content type" | translate }}: </label> -->
                    <mat-chip-list class="without_ordered" [multiple]="false" selectable formControlName="content_type_id">
                        <ng-container *ngFor="let type of contentTypes; let i = index">
                            <mat-chip *ngIf="type.id != 203" class="small_chip" (click)="selectType(type)" [value]="type.id">
                                {{ type.name | translate }}
                            </mat-chip>
                        </ng-container>
                    </mat-chip-list>
                </div>
            </div>

            <div class="white_block">
                <div class="white_block_title">
                    {{ "Publishing options" | translate }}
                </div>
    
                <div class="small_chips">
                    <!-- <label>{{ "Group" | translate }}: </label> -->
                    <mat-chip-list [multiple]="false" selectable formControlName="status_id">
                        <ng-container *ngIf="data?.profile?.permission_upload == 1">
                            <mat-chip class="small_chip" [value]="0" (click)="selectStatus(0)">
                                {{ "Publish now" | translate }}
                            </mat-chip>
                            <mat-chip class="small_chip" [value]="1" (click)="selectStatus(1)">
                                {{ "Schedule" | translate }}
                            </mat-chip>
                        </ng-container>
                        <mat-chip class="small_chip" [value]="2" (click)="selectStatus(2)">
                            {{ "Draft" | translate }}
                        </mat-chip>
                    </mat-chip-list>
                </div>

                <div class="schedule" *ngIf="form?.get('status_id').value == 1">
                    <div class="date_and_time_c" formGroupName="date">
                        <ng-container *ngIf="needData && (!form.get('published_at').value || !isValidNotYetDate()) && submited">
                            <div class="req_field"></div>
                            <div class="req_label">
                                <div class="angle"></div>
                                <span>
                                    {{ "You need to fill this field" | translate }}
                                </span>
                            </div>
                        </ng-container>
                        <mat-form-field appearance="standard" class="date_picker_cst">
                            <div class="prefix" matPrefix style="margin-right: 10px;">{{ "Date" | translate }}</div>
                            <input formControlName="day" (dateChange)="onDateChange($event)" matInput [matDatepicker]="pickerDay" placeholder="MM / DD/ YYYY">
                            <mat-datepicker-toggle matSuffix [for]="pickerDay"></mat-datepicker-toggle>
                            <mat-datepicker #pickerDay></mat-datepicker>
                        </mat-form-field>
                        
                        <div class="ngx-timepicker-field-example">
                            <div class="prefix">{{ "Time" | translate }}</div>
                            <ngx-timepicker-field [format]="24" [defaultTime]="form.get('date').get('time').value" (timeChanged)="changedTime($event)" formControlName="time"></ngx-timepicker-field>
                            <div class="local_time">{{ "Workspace Time Zone" | translate }} <span>({{data.company.timezone}})</span></div>
                        </div>
                        <div class="user_timezone" *ngIf="isValidNotYetDate()">
                            <span>{{"Your time" | translate}}</span>
                            <span>{{showUserDate(form.get('published_at').value) * 1000 | date:'MMM dd, hh:mm a':'':activeLang }}</span>
                        </div>
                    </div>
                    
                    <div class="regular_times_wrp">
                        <div class="reg_title_out">
                            <div class="reg_title">
                                {{ "Your scheduled post for this time" | translate }}:
                            </div>
                            <div class="ml-1" style="position: relative;">
                                <span style="color: #1877F2; cursor: pointer;" (click)="regArchContainer.open()">
                                    {{form.get('content_schedule_id').value ? getArchById(form.get('content_schedule_id').value).name : ("All regulars"|translate)}}
                                </span>
                        
                                <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                                    <mat-label>{{ "Choose a Regular" | translate }}</mat-label>
                                    <mat-select [disableOptionCentering]="true" selectDir #regArchContainer formControlName="content_schedule_id" required>
                                        <mat-option [value]="''">
                                            {{"All regulars" | translate}}
                                        </mat-option>
                                        <mat-option *ngFor="let item of regArch" [value]="item.id">
                                            {{item.name}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>

                        <ng-container *ngIf="regularDateTimes && regularDateTimes.length">                          
                            <div class="regular_time" 
                            #tooltip="matTooltip" 
                            matTooltip="{{ (!item.is_con ? (getStatusById(item?.content_status_id)?.name + ' ') : '') + item.name }}" 
                            [matTooltipPosition]="'above'" 
                            [class]="item?.class" 
                            [ngClass]="{
                                'active': item?.content_published_at == showUserDate(form.get('published_at').value), 
                                'has_photo': item.file && item.file.original
                            }" 
                            *ngFor="let item of regularDateTimes" 
                            (click)="acceptRegularTime(item)">
                                <div class="photo_img" *ngIf="item.file && item.file.original" [ngStyle]="{'background': 'url(' + host + item.file.original + '?company_id=' + data?.company?.id + ') no-repeat center / cover'}"></div>
                                {{item.content_published_at*1000 | date:'E, MMM dd':'':activeLang}},&nbsp;
                                <span>
                                    {{item.content_published_at*1000 | date:'hh:mm a':'':activeLang}}
                                </span>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>  
        </form>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <div class="f_w_p_btns">
        <button mat-button (click)="close()">
            {{ "Close" | translate }}
        </button>
        <button class="ml-1 d-f-btn" (click)="submitForm()" mat-raised-button cdkFocusInitial color="primary" [disabled]="!!isSubmit || !!cantPublish">
            <mat-spinner class="spin_12 mr-1" *ngIf="isSubmit"></mat-spinner>  
            {{ "Upload" | translate }}
        </button>
        <div class="small_info_right_btn">
            <p>{{ "Using this feature you are agreeing to be bound by the" | translate }} <a href="https://www.youtube.com/t/terms" target="_blank">{{"YouTube's Terms of Service" | translate}}</a></p>
            <p>{{ "It may take a few minutes for the video to process and be visible on your profile" | translate }}</p>
        </div>
    </div>
</mat-dialog-actions>
