<div class="cases_page">
  <div class="container">
    <div class="filter_out">  
      <!-- <form [formGroup]="filter" class="filter_in" (ngSubmit)="filterStatements(null, null, true)" >
        <div class="filter_form_in">
  
          <div class="d-f-c">
            <mat-form-field appearance="legacy" class="filter_form search">
              <mat-icon matPrefix>search</mat-icon>
              <input formControlName="q" matInput>
              <mat-icon (click)="$event.stopPropagation();" matSuffix>tune</mat-icon>
            </mat-form-field>
            <button type="submit" class="filter_btn search_filter_btn">{{ "Search" | translate }}</button>
          </div>

          <mat-form-field appearance="legacy" class="filter_form executor" *ngIf="!withoutFilter">
            <mat-label>{{ "Executor" | translate }}</mat-label>
            <mat-select [disableOptionCentering]="true" selectDir formControlName="employee_id"  (selectionChange)="selectChange($event)">
              <mat-option>
                <ngx-mat-select-search 
                    placeholderLabel="{{ 'Search' | translate }}" 
                    noEntriesFoundLabel="{{ 'no matching member found' | translate }}"
                    [formControl]="execControl"></ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let employee of employees$ | async" [value]="employee.id">
                <app-user [class]="'user_div'" [employee]="employee"></app-user>
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="legacy" class="example-form-field filter_form date">
              <mat-label>{{ 'Month' | translate }}</mat-label>
              <input [min]="minDate" matInput [matDatepicker]="dp" formControlName="time_start_utc_from">
              <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
              <mat-datepicker #dp
                              startView="multi-year"
                              (yearSelected)="chosenYearHandler($event)"
                              (monthSelected)="chosenMonthHandler($event, dp)"
                              panelClass="example-month-picker">
              </mat-datepicker>
          </mat-form-field>
        </div>
      </form> -->
      <div class="filter_values">
        <mat-chip-list class="chips_wrp without_ordered start_page_chips">
          
          <!-- <mat-chip (removed)="removeValue('q')" *ngIf="activatedRoute.snapshot.queryParamMap.get('q')">
            {{activatedRoute.snapshot.queryParamMap.get('q')}}
            <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
          </mat-chip> -->

      
          <mat-chip class="user_chip" *ngIf="filter.get('employee_id').value" (removed)="removeValue('operation_employee_id')" >
              <span class="mr-1" style="font-weight: 500;">{{ "Executor" | translate }}</span>
              <app-user [class]="'user_div'" [employee]="getEmployee(filter.get('employee_id').value)"></app-user>
              <mat-icon class="remove_chip_btn" matChipRemove *ngIf="!withoutFilter">cancel</mat-icon>
          </mat-chip>

          <mat-chip class="user_chip" *ngIf="filter.get('partner_company_id').value" (removed)="removeValue('operation_partner_company_id')" >
              <span class="mr-1" style="font-weight: 500;">{{ "Partner" | translate }}</span>
              <app-user [class]="'user_div'" [companyEmployee]="getPartner(filter.get('partner_company_id').value)?.partnerCompany" [imgRoute]="imgRoute" [host]="host" [company]="company"></app-user>
              <mat-icon class="remove_chip_btn" matChipRemove *ngIf="!withoutFilter">cancel</mat-icon>
          </mat-chip>
    


          <mat-icon class="remove_all_chips_btn" (click)="removeValue('all')" *ngIf="!withoutFilter && (filter.get('employee_id').value || filter.get('partner_company_id').value)">cancel</mat-icon>
        </mat-chip-list>


        <div class="stats_wrp">

          <div class="tasks_counters">
            <div class="counter_block" *ngIf="pagination && pagination['totalCount']" (click)="log()">
              <div class="counter_top">{{ "Payments" | translate }}</div>
              <div class="counter_bottom">{{pagination['totalCount']}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container centred_page">

    <div class="step">
        <!-- <form *ngIf="payments?.length && !withoutFilter" [formGroup]="filter" class="filter_wrp d-f-c" style="justify-content: space-between;">
          <h3 (click)="log()">{{ 'Payments' | translate }}:</h3>
          <div class="d-f-c">
            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ "Choose an employee" | translate }}</mat-label>
                <mat-select [disableOptionCentering]="true" selectDir formControlName="employee_id">
                  <mat-option>
                    <ngx-mat-select-search 
                        placeholderLabel="{{ 'Search' | translate }}" 
                        noEntriesFoundLabel="{{ 'no matching member found' | translate }}"
                        [formControl]="execControl"></ngx-mat-select-search>
                  </mat-option>
                  <mat-option [value]="">{{ "All" | translate }}</mat-option>
                  <mat-option *ngFor="let employee of employees$ | async" [value]="employee.id">
                    <app-user [class]="'user_div'" [employee]="employee"></app-user>
                  </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="standard" class="full_width_form ml-1">
              <mat-label>{{ "Choose a partner" | translate }}</mat-label>
              <mat-select [disableOptionCentering]="true" selectDir formControlName="partner_company_id">
                  <mat-option>
                      <ngx-mat-select-search 
                      placeholderLabel="{{ 'Search' | translate }}" 
                      noEntriesFoundLabel="{{ 'no matching group found' | translate }}"
                      [formControl]="partnersControl"></ngx-mat-select-search>
                  </mat-option>
                  <mat-option [value]="">{{ "All" | translate }}</mat-option>
                  <mat-option *ngFor="let parnter of partners$ | async" [value]="parnter.partner_company_id">
                      <app-user [class]="'user_div'" [companyEmployee]="parnter.partnerCompany" [imgRoute]="imgRoute" [host]="host" [company]="company"></app-user>
                  </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </form> -->
        <div #tableContainer class="example-container mat-elevation-z8 employees_table">
            <table mat-table [dataSource]="payments" class="centred_cols">

              <ng-container matColumnDef="Date Posted">
                <th mat-header-cell *matHeaderCellDef> {{ "Date Posted" | translate }} </th>
                <td mat-cell *matCellDef="let element"> 
                    {{ element.paid_at*1000 | date:'mediumDate':'+0000':activeLang | yearPipe }}
                </td>
                <td mat-footer-cell *matFooterCellDef> <b>{{ 'Total' | translate }}</b> </td>
              </ng-container>
          
              <ng-container matColumnDef="Real_paid_at">
                <th mat-header-cell *matHeaderCellDef> {{ "Real paid at" | translate }} </th>
                <td mat-cell *matCellDef="let element"> 
                    {{ element.real_paid_at*1000 | date:'mediumDate':'+0000':activeLang | yearPipe }}
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
              </ng-container>

              <ng-container matColumnDef="Payment System">
                <th mat-header-cell *matHeaderCellDef> {{ "Payment System" | translate }} </th>
                <td mat-cell *matCellDef="let element"> 
                    {{ element.paymentSystem.name }}
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
              </ng-container>

              <ng-container matColumnDef="Employee">
                <th mat-header-cell *matHeaderCellDef> {{ "Employee" | translate }} </th>
                <td mat-cell *matCellDef="let element">
                    <div *ngIf="element?.employee; else elseTemplatePartner">
                      <app-user [class]="'user_div'" [employee]="element?.employee"></app-user>
                    </div>
                    <ng-template #elseTemplatePartner>
                      <div>
                        <app-user *ngIf="element?.partnerCompany" [class]="'user_div'" [companyEmployee]="element?.partnerCompany" [imgRoute]="imgRoute" [host]="host" [company]="company"></app-user>
                      </div>
                    </ng-template>
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
              </ng-container>

              <ng-container matColumnDef="Amount">
                <th mat-header-cell *matHeaderCellDef> {{ "Amount" | translate }} </th>
                <td mat-cell *matCellDef="let element"> 
                  {{ getCurrency(element?.currency_id)?.short_name }}{{ roundNumber(element.amount) }}
                </td>
                <td mat-footer-cell *matFooterCellDef>
                  <ng-container *ngFor="let value of currencyes; let i = index;">
                    <span *ngIf="+value.val">
                      <ng-container [ngSwitch]="value.id">
                        <ng-container *ngSwitchCase="'1'">&#36;</ng-container>
                        <ng-container *ngSwitchCase="'2'">&#8381;</ng-container>
                        <ng-container *ngSwitchCase="'3'">&#8364;</ng-container>
                        <ng-container *ngSwitchCase="'4'">&#8372;</ng-container>
                      </ng-container>{{ value.val | reportsNumber }}
                    </span>
                  </ng-container>  
                </td>
              </ng-container>

              <ng-container matColumnDef="Comment">
                <th mat-header-cell *matHeaderCellDef> {{ "Comment" | translate }} </th>
                <td mat-cell *matCellDef="let element"> 
                    {{ element.comment }}
                </td>
                <td mat-footer-cell *matFooterCellDef> </td>
              </ng-container>

              <ng-container matColumnDef="Activity" *ngIf="company?.permissions.includes('owner') || company?.permissions.includes('admin')">
                <th mat-header-cell *matHeaderCellDef> {{ "Activity" | translate }} </th>
                <td mat-cell *matCellDef="let element"> 
                  <div class="d-f-c" style="justify-content: flex-end;">
                    <mat-icon class="cp" *ngIf="!!element?.files?.length" style="color: blue;" (click)="download(element)">attachment</mat-icon>
                    <a class="cp ml-5" (click)="editPayment($event, element)">
                        <mat-icon style="color: green;">edit</mat-icon>
                    </a>
                    <mat-icon class="cp ml-5" style="color: red;" (click)="deletePayment(element)">delete</mat-icon>
                  </div>
                </td>
                <td mat-footer-cell *matFooterCellDef> </td>
              </ng-container>       
          
              <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
              <tr mat-row *matRowDef="let element; columns: displayedColumns;"></tr>
              <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></tr>
            </table>
        </div>

        <div class="btns_line">
            <a mat-raised-button (click)="goBack($event)">
                {{ "Back" | translate }}
            </a>
            <a class="ml-1" *ngIf="company?.permissions.includes('owner') || company?.permissions.includes('admin') || company?.permissions.includes('manager')" (click)="addPayment($event)" mat-raised-button color="primary">
                <mat-icon>add</mat-icon>
                {{ "Add payment" | translate }}
            </a>
        </div>

    </div>
</div>