import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { concat, forkJoin, of, Subscription } from 'rxjs';
import { concatMap, last, map, switchMap, tap } from 'rxjs/operators';
import { BaseClass } from 'src/app/shared/models/base-class';
import { FileService, Folder } from 'src/app/shared/services/rest/file.service';

@Component({
  selector: 'app-mob-fm-navigation',
  templateUrl: './mob-fm-navigation.component.html',
  styleUrls: ['./mob-fm-navigation.component.scss']
})
export class MobFmNavigationComponent extends BaseClass implements OnInit, OnDestroy {
  public folders:any;
  public pagination:any;
  public page:number = 1;
  public isLoad:boolean = false;
  throttle = 300;
  scrollUpDistance = 3.5;
  public rootFolders: any[] = [
    {
      name: "Sources",
      id: 0,
      filename: 'sources',
      location: "/",
      over: false,
      rootDir: true
    },
    {
      name: "To Approve",
      id: 1,
      filename: 'to_approve',
      location: "/",
      over: false,
      rootDir: true
    },
    {
      name: "Ready Files",
      id: 2,
      filename: 'ready_files',
      location: "/",
      over: false,
      rootDir: true
    },
    {
      name: "Approved",
      id: 3,
      filename: 'approved',
      location: "/",
      over: false,
      rootDir: true
    },
    {
      name: "Project",
      id: 4,
      filename: 'project',
      location: "/",
      over: false,
      rootDir: true
    },
    {
      name: "Trash",
      id: 5,
      filename: 'trash',
      location: "/",
      over: false,
      rootDir: true
    }
  ]
  public crumbs: any = this.data.crumbs.slice();
  public foldersExpandSub: Subscription;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<MobFmNavigationComponent>,
    private fileService: FileService,
    ) { super() }


  ngOnInit(): void {
    console.log("MobFmNavigationComponent", this.data)
    if (this.crumbs.length > 1) {
      this.crumbs.splice(this.crumbs.length - 1, 1)
    }
    this.getFolders(this.data.activeFolder || this.data.crumbs[0], null, this.page);
  }

  neededData(item) {
    let locationData = item.location == "/" ? item.location + (!!item.filename ? item.filename : item.name) : item.location + "/" + (!!item.filename ? item.filename : item.name)
    let arr = [
      this.fileService.getFileData("1", this.data.company ? this.data.company.id : this.data.company_id, {task_id: this.data.fileRoute.task_id, is_dir: '1', task_operation_id: this.data.fileRoute.task_operation_id, [!!this.data.fileRoute.task_id ? 'location' : 'location_all']: locationData}, '0').pipe(
        tap(el => item.foldersCount = +el.headers.get('x-pagination-total-count')),
      )
    ];
    return forkJoin(arr)
  }

  onDown(e) {
    console.log(e?.target?.scrollTop)
    if (!this.isLoad) {
      if (this.page <= this.pagination['pageCount']) {
        this.getFolders(this.data.activeFolder || this.data.crumbs[0], null, this.page);
      }
    }
  }
  
  logCr() {
    console.log(this.crumbs)
  }

  getFolders(folder, ins:boolean = false, page) {
    this.isLoad = true;
    let locationData = folder.location

    if (ins && folder.filename != "Root" && folder.name != "Root") {
      locationData = folder.location == "/" ? folder.location + (!!folder.filename ? folder.filename : folder.name) : folder.location + "/" + (!!folder.filename ? folder.filename : folder.name)
    }

    if (this.foldersExpandSub) {
      this.foldersExpandSub.unsubscribe()
    }

    this.foldersExpandSub = this.fileService.getMobFoldersStream().pipe(
      concatMap(topsInPage => {
        return concat(...topsInPage.map(topInPage => this.neededData(topInPage))).pipe(last(),map(x => topsInPage))
      }),
    ).subscribe(resp => console.log("-----getMobFoldersStream-----",resp));

    let params;
    if (this.data.is_global) {
      params = {...this.data.globalFilterFmFiles}
      delete params.operation_status_id;

      if (!this.data.indicator()) {
        params.location = locationData;
  
        delete params.location_all;
      } else {
        params.location_all = locationData
      }
      params.is_dir = 1;
    }

    console.log("getFolders locationData", locationData)
    this.attachSubscriptions(
      this.fileService.getGlobalFileData(page, this.data.company ? this.data.company.id : this.data.company_id, this.data.is_global ? params : {task_id: this.data.fileRoute.task_id, is_dir: '1', task_operation_id: this.data.fileRoute.task_operation_id, [!!this.data.fileRoute.task_id ? 'location' : 'location_all']: locationData}, '200').pipe(
        tap(el => {
          this.pagination = {
            'pageCount': el.headers.get('x-pagination-page-count'),
            'perPage': el.headers.get('x-pagination-per-page'),
            'totalCount': el.headers.get('x-pagination-total-count'),
            'currentPage': el.headers.get('x-pagination-current-page'),
          }
        }),
        map(x => x.body),
        tap(fdrs => {this.fileService.mobFolders$.next(fdrs)})
      ).subscribe(resp => {
        console.log("getFolders", resp, locationData);
        if (locationData == '/') {
          let result: any[] = [];
          result = this.rootFolders;

          resp.forEach(folder => {
            if (result.find(x => x.filename == folder.filename)) {
              result.splice(result.indexOf(result.find(x => x.filename == folder.filename)), 1, folder)
            } else {
              result.push(folder);
            }
          });
          this.folders = result
        } else {
          this.folders = resp;
        }
        this.isLoad = false;
        page++;
      })
    )
  }

  goUp() {
    this.page = 1;
    this.crumbs.splice(this.crumbs.length - 1, 1)
    if (this.crumbs.length == 0) {
      this.folders = [{
        location: '/',
        filename: "Root",
        id: 0,
        foldersCount: 1
      }];
    } else {
      this.getFolders(this.crumbs[this.crumbs.length - 1], true, this.page)
    }
  }

  selectFolder(folder) {
    console.log(folder);
    this.dialogRef.removePanelClass('animate__slideInUp')
    this.dialogRef.addPanelClass('animate__slideOutDown')
    setTimeout(()=>{this.dialogRef.close({event: 'change', data: {
      folder: folder,
      crumbs: this.crumbs
    }})}, 300);
  }

  expand(folder) {
    console.log(folder)
    if (folder.location.split("/").length > 1) {
      if (this.crumbs[this.crumbs.length - 1].name != folder.filename) {
        this.crumbs.push({
          name: folder.filename,
          location: folder.location,
          target: folder
        })
      }
    } else {
      this.crumbs = [{
        name: "Root",
        location: "/"
      }];
      this.crumbs.push({
        name: folder.filename,
        location: folder.location,
        target: folder
      })
    }

    this.getFolders(folder, true, folder.page)
  }

  // getFoldersCount() {
  //   this.fileService.getFileData("1", this.data.company ? this.data.company.id : this.data.company_id, {task_id: this.data.fileRoute.task_id, is_dir: '1', task_operation_id: this.data.fileRoute.task_operation_id, [!!this.data.fileRoute.task_id ? 'location' : 'location_all']: location}, '0').pipe(
  //     tap(el => this.foldersCount = el.headers.get('x-pagination-total-count'))
  //   ).subscribe(resp => {
  //     console.log(this.foldersCount)
  //   })
  // }

  close() {
    this.dialogRef.removePanelClass('animate__slideInUp')
    this.dialogRef.addPanelClass('animate__slideOutDown')
    setTimeout(()=>{this.dialogRef.close()}, 300);
  }

  ngOnDestroy() {
    if (this.foldersExpandSub) {
      this.foldersExpandSub.unsubscribe()
    }
    this.clearSubscriptions()
  }
}
