import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReplaySubject } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import { BaseClass } from 'src/app/shared/models/base-class';
import { TaskService } from 'src/app/shared/services/rest/task.service';

@Component({
  selector: 'app-get-files-settings',
  templateUrl: './get-files-settings.component.html',
  styleUrls: ['./get-files-settings.component.scss']
})
export class GetFilesSettingsComponent extends BaseClass implements OnInit, OnDestroy  {
  public cardJobControl: FormControl = new FormControl('');
  public cardJobs$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public cardJobs: any[] = [];

  public cardJobsPage: number = 1;
  public cardJobsPagination: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<GetFilesSettingsComponent>,
    private taskService: TaskService,
  ) { super() }

  ngOnInit(): void {
    console.log("GetFilesSettingsComponent", this.data)
    this.getCardWorks(this.cardJobsPage);

    this.attachSubscriptions(
      this.cardJobControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onCardJobsSearch(resp))
    )
  }

  onCardJobsSearch(resp) {
    if (!this.cardJobs) {
      return;
    }

    if (!resp) {
      this.cardJobs$.next(this.cardJobs.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.cardJobs$.next(
      this.cardJobs.filter(z => (!!z.operation && z.operation.name.toLowerCase().indexOf(resp) > -1) || z.id.toString().indexOf(resp) > -1 || (z.name && z.name.toLowerCase().indexOf(resp) > -1) || (z.comment && z.comment.toLowerCase().indexOf(resp) > -1))
    );
  }

  getPrimaryTag(job) {
    if (job.parameterValuesToTask && job.parameterValuesToTask.length) {
      return job.parameterValuesToTask.find(x => !!x.is_primary)
    } else {
      return undefined
    }
  }

  getCardJobById(id) {
    return this.cardJobs.find(x => x.id == id);
  }

  getCardWorks(page) {
    this.attachSubscriptions(
      this.taskService.getWorks(page, this.data.company.id, {task_id: this.data.task ? this.data.task.id : this.data.chat.task_id}, '200').pipe(
        tap(el => {
          this.cardJobsPagination = {
            'pageCount': el.headers.get('x-pagination-page-count'),
            'perPage': el.headers.get('x-pagination-per-page'),
            'totalCount': el.headers.get('x-pagination-total-count'),
            'currentPage': el.headers.get('x-pagination-current-page'),
          }
        })
      ).subscribe(resp => {
        this.cardJobs.push(...resp.body.slice())
        this.cardJobs$.next(this.cardJobs.slice())
        this.cardJobsPage = this.cardJobsPage + 1;

        console.log(this.cardJobsPagination, this.cardJobs);
        if (+this.cardJobsPagination['currentPage'] != +this.cardJobsPagination['pageCount']) {
          this.getCardWorks(this.cardJobsPage);
        }
      })
    )
  }

  find() {
    this.dialogRef.close({event: "find"});
  }
  // findAlt() {
  //   this.dialogRef.close({event: "alt_find"});
  // }

  close() {
    this.dialogRef.close({event: "close", data: false});
  }

  ngOnDestroy(): void {
    this.clearSubscriptions();
  }
}
