import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { forkJoin, fromEvent } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { BaseClass } from 'src/app/shared/models/base-class';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { FileService } from 'src/app/shared/services/rest/file.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { environment } from 'src/environments/environment';
import { HttpRestService } from '../../services/common/http-rest.service';
import { RefreshService } from '../../services/rest/refresh.service';

@Component({
  selector: 'app-approve-reject-files',
  templateUrl: './approve-reject-files.component.html',
  styleUrls: ['./approve-reject-files.component.scss']
})
export class ApproveRejectFilesComponent extends BaseClass implements OnInit, OnDestroy {
  @ViewChild('checkUploaderFolder') checkUploaderFolder: ElementRef<HTMLInputElement>;
  @ViewChild('checkUploader') checkUploader: ElementRef<HTMLInputElement>;
  @ViewChild('checkMulti') checkMulti: MatCheckbox;
  public uploadLimit: boolean = false;
  public selectedCheck: any;
  public activeLang: any;
  public host: any = environment.host;
  public imgRoute: any;
  public pagination: any;
  public page: number = 1;
  public files: any[] = [];
  public isGettingFiles: boolean = false;
  public is_mobile: boolean = false;
  public isMultiple: boolean = false;
  public isSubmit: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ApproveRejectFilesComponent>,
    private taskService: TaskService,
    private fileService: FileService,
    private http: HttpRestService,
    private refreshService: RefreshService,
    private sm: StorageManagerService
  ) { super() }

  ngOnInit(): void {
    console.log("ApproveRejectFilesComponent", this.data);
    this.checkIsMobile();
    this.getFiles(this.page);
  }

  understandFileType(val) {
    if (!val) {
      return ""
    }
    
    if (val == 'application/pdf') {
      return 'pdf'
    } else if (val.indexOf("/") != -1) {
      return val.split('/')[0]
    } else {
      return ""
    }
  }

  checkIsMobile() {
    if (window.innerWidth <= 769) {
      this.is_mobile = true;
    } else {
      this.is_mobile = false;
    }
    this.onResize();
  }
  
  onResize() {
    this.attachSubscriptions(
      fromEvent(window, "resize").pipe(
        map(() => window.innerWidth)
      ).subscribe((wWidth) => {
          if (wWidth <= 769) {
            this.is_mobile = true;
          } else {
            this.is_mobile = false;
          }
        }
      )
    )
  }

  moveFile(file, location, i) {
    console.log(file, location)
    let newLocation = location + file.location.replace('/to_approve', '')

    this.attachSubscriptions(
      this.fileService.editFile(file.id, {"location": newLocation}, this.data.company_id || this.data.target.company_id).subscribe(resp => {
        console.log("resp", resp);
        // this.files.splice(i,1);
        file.state = location.replace("/", "")
      })
    )
  }

  getCount() {
    return this.files.filter(x => x.selected).length
  }

  moveSelectedFile(location:boolean = false) {
    let sbmtData = [];
    this.files.filter(y => y.selected).forEach(x => {  
      let loc = !!location ? (x.is_ready_if_approved ? '/ready_files' : '/approved') : '/reject'
      let newLocation = loc + x.location.replace('/to_approve', '')   
      sbmtData.push({
        "path": `/api/file/${x.id}/`,
        "query": {company_id: this.data.company_id || this.data.target.company_id},
        "method": "POST",
        "body": Object.assign({
          [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
        }, {location: newLocation})
      });
    });

    this.attachSubscriptions(
      this.taskService.multiRequest(sbmtData).subscribe(resp => {
        console.log(resp)
        this.files.filter(y => y.selected).forEach(x => {
          // this.files.splice(this.files.indexOf(x), 1)
          let loc = !!location ? (x.is_ready_if_approved ? '/ready_files' : '/approved') : '/reject';
          x.state = loc;
          x.state = x.state.replace("/", "");
          x.selected = false;
        })
        this.isMultiple = false;
        this.checkMulti.checked = false;
        console.log(this.files)
      },
      error => {
        console.log(error)
      })
    )
  }
  
  moveAllFile(location:boolean = false) {
    let sbmtData = [];
    this.files.forEach(x => {      
      let loc = !!location ? (x.is_ready_if_approved ? '/ready_files' : '/approved') : '/reject'
      let newLocation = loc + x.location.replace('/to_approve', '')   
      sbmtData.push({
        "path": `/api/file/${x.id}/`,
        "query": {company_id: this.data.company_id || this.data.target.company_id},
        "method": "POST",
        "body": Object.assign({
          [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
        }, {location: newLocation})
      });
    });

    this.attachSubscriptions(
      this.taskService.multiRequest(sbmtData).subscribe(resp => {
        console.log(resp)
        this.files.map(x => {
          let loc = !!location ? (x.is_ready_if_approved ? '/ready_files' : '/approved') : '/reject';
          x.state = loc;
          x.state = x.state.replace("/", "");
        })
        console.log(this.files)
      },
      error => {
        console.log(error)
      })
    )
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  onImgError(event){
    event.target.src = this.imgRoute+'/assets/img/image_black_48dp.svg'
  }

  // download(file) {
  //   window.open(this.host + file.original + '?company_id=' + this.data.company.id, '_blank');
  // }

  // previewVideo(file, job) {
  //   const dialogRef = this.dialog.open(VideoViewerComponent, {
  //     panelClass: 'video_viewer',
  //     data: {
  //       file: file,
  //       task: this.data.task,
  //       work: job,
  //       operationsValues: this.data.operationsValues,
  //       company: this.data.company,
  //       activeLang: this.data.activeLang,
  //       user: this.data.user
  //     }
  //   });
  // }

  getFiles(page) {
    this.isGettingFiles = true;
    this.attachSubscriptions(
      this.fileService.getFileData(page, this.data.company_id || this.data.target.company_id, {is_dir: 0, task_id: this.data.target.task_id, task_operation_id: this.data.target.id, 'location_all': '/to_approve'}).pipe(
        tap(el => {
          this.pagination = {
            'pageCount': el.headers.get('x-pagination-page-count'),
            'perPage': el.headers.get('x-pagination-per-page'),
            'totalCount': el.headers.get('x-pagination-total-count'),
            'currentPage': el.headers.get('x-pagination-current-page'),
          }
        }),
        map(el => el.body),
      ).subscribe(resp => {
        this.files.push(...resp.slice())
        this.page = this.page + 1;

        console.log(this.pagination, this.files);
        if (+this.pagination['currentPage'] != +this.pagination['pageCount']) {
          this.getFiles(this.page);
        } else {
          this.isGettingFiles = false;
        }
      })
    )
  }

  showFilse() {
    console.log(this.files)
  }

  multipleToggle(val) {
    console.log(val)
    this.isMultiple = val;
    if (!val) {
      this.files.map(x => x.selected = false)
    }
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  save() {
    console.log('save')
    this.isSubmit = true;
      if (this.data.val.request.method == 'POST') {
        this.http.executePost('/api/' + this.data.val.request.url.split("/api/")[1], this.data.val.request.body, true, {
          params: this.data.val.request.params
        }).pipe(map(() => this.data.val)).subscribe(resp => {
          console.log(resp)
          this.isSubmit = false;
          this.close();
          this.refreshService.refreshJob$.next({company_id: resp.err.error.params.task_operation.company_id, job_id: resp.err.error.params.task_operation.id})
          this.refreshService.refreshTask$.next({company_id: resp.err.error.params.task_operation.company_id, task_id: resp.err.error.params.task_operation.task_id})
          this.refreshService.refreshTaskCard$.next({company_id: resp.err.error.params.task_operation.company_id, task_id: resp.err.error.params.task_operation.task_id, task_operation_id: resp.err.error.params.task_operation.id})

        }, error => {
          this.isSubmit = false;
          this.close();
        })
      }
  }

  ngOnDestroy() {
    this.clearSubscriptions()
  }

}
