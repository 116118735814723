<h2 mat-dialog-title class="dialog_title">
    <span (click)="log()">
        {{ "Delete tag" | translate }}:
    </span>
    <button mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h2>
<mat-dialog-content class="mat-typography">
    <div class="container parameters_target_wrp" [appDeviceClass]="'custom-scroll'">
        <mat-form-field appearance="standard" class="full_width_form">
            <mat-label>{{ "Tag" | translate }}</mat-label>
            <mat-select [disableOptionCentering]="true" selectDir [multiple]="false" [(ngModel)]="parameter_value">
                <mat-select-trigger class="multi_users">
                    <ng-container *ngIf="parameter_value">
                        <span>{{ parameter_value.value }}</span>
                    </ng-container>
                </mat-select-trigger>
                <mat-option>
                    <ngx-mat-select-search 
                    placeholderLabel="{{ 'Search' | translate }}" 
                    noEntriesFoundLabel="{{ 'no matching tag found' | translate }}"
                    [formControl]="allValuesControl"></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let item of allValues$ | async" [value]="item">
                    <div class="d-f-c" style="justify-content: space-between;">
                        <span>{{ item.value }}</span>
                        <span>{{ item?.parameter?.name }}</span>
                    </div>
                </mat-option>
            </mat-select>
        </mat-form-field>

        <div class="box_wrp">
            <mat-checkbox color="primary" [FormControl]="is_primary">{{"Delete if tag is primary" | translate}}</mat-checkbox>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <div class="btns">
        <button mat-raised-button (click)="close()">
            {{ "Close" | translate }}
        </button>
        <button class="ml-1 d-f-btn" (click)="save()" mat-raised-button cdkFocusInitial color="primary" [disabled]="!parameter_value || isSubmit">
            <mat-spinner class="spin_16 mr-1" *ngIf="isSubmit"></mat-spinner>  
            {{ "Delete" | translate }}
        </button>
    </div>
</mat-dialog-actions>