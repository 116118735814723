import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseClass } from 'src/app/shared/models/base-class';
import { QuestsService } from 'src/app/shared/services/rest/quests.service';

@Component({
  selector: 'app-edit-field',
  templateUrl: './edit-field.component.html',
  styleUrls: ['./edit-field.component.scss']
})

export class EditFieldComponent extends BaseClass implements OnInit, OnDestroy {
  public types: any[] = [
    {
      id: 0,
      name: 'text',
    },
    {
      id: 1,
      name: 'file',
    },
    {
      id: 2,
      name: 'email',
    },
    {
      id: 3,
      name: 'tel',
    },
    {
      id: 4,
      name: 'date',
    },
    {
      id: 5,
      name: 'time',
    },
    {
      id: 6,
      name: 'url',
    },
    {
      id: 7,
      name: 'file',
    },
    {
      id: 8,
      name: 'number',
    },
  ];
  public form: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<EditFieldComponent>,
    private fb: FormBuilder,
    private questsService: QuestsService
  ) { super() }

  ngOnInit(): void {

    console.log(this.data)

    this.form = this.fb.group({
      type: this.fb.control([{ value: '', disabled: this.data.is_data && this.data.field.field_id != 0 }], Validators.required),
      name: this.fb.control({ value: this.data.field.name, disabled: this.data.is_data && this.data.field.field_id != 0 }, Validators.required),
      order: [this.data.field.order, Validators.required]
    })

    if (this.data.is_data) {
      this.form.addControl('comment', this.fb.control(this.data.field.comment))
      this.form.updateValueAndValidity()
    }

    this.form.get('type').valueChanges.subscribe(resp => {
      if (resp == 'rules') {
        this.form.addControl('rules', this.fb.control(this.data.field.rules))
      } else {
        if (this.form.get('rules')) {
          this.form.removeControl('rules')
        }
      }
      this.form.updateValueAndValidity()
    })

    
    if (this.data.quest) {
      this.form.addControl("application_id",this.fb.control(this.data.quest.id))
    } else {
      this.types.push({
        id: 9,
        name: 'rules',
      })
      this.form.addControl('employee_id', this.fb.control(this.data.employee_id));
    }
    this.form.patchValue({
      type: this.data.field.type
    })
    if (this.data.is_data && this.data.field.field_id != 0) {
      this.form.get('type').disable({
        onlySelf: true
      })
    }
 
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  onSubmit() {
    console.log("submit")
    if (this.data.is_data) {
      this.attachSubscriptions(
        this.questsService.editDataField(this.data.field.id, this.form.value, this.data.company_id).subscribe(resp => {
          this.data.field.name = resp.name;
          this.data.field.order = resp.order;
          this.data.field.type = resp.type;
          this.data.field.comment = resp.comment;
          this.dialogRef.close({event: "edit", data: resp});
        })
      )
    } else {
      this.attachSubscriptions(
        this.questsService.editField(this.data.field.id, this.form.value).subscribe(resp => {
          this.data.field.name = resp.name;
          this.data.field.order = resp.order;
          this.data.field.type = resp.type;
          this.dialogRef.close({event: "edit", data: resp});
        })
      )
    }
  }

  ngOnDestroy() {
    this.clearSubscriptions()
  }
}
