import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { BaseClass } from '../../models/base-class';
import { FileService } from '../../services/rest/file.service';
import { catchError, distinctUntilChanged, filter, last, map, switchMap, take, tap } from 'rxjs/operators';
import { TaskService } from '../../services/rest/task.service';
import { ChatService } from '../../services/rest/chat.service';
import { Subscription, forkJoin, fromEvent } from 'rxjs';
import { casesModel } from '../../functions/casesModel';
import { OpenJobComponent } from 'src/app/components/workspace-pages/cases/dialogs/open-job/open-job.component';
import { MatDialog } from '@angular/material/dialog';
import { SelectionModel } from '@angular/cdk/collections';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { TemplatePortal } from '@angular/cdk/portal';
import { DeleteAlertComponent } from '../delete-alert/delete-alert.component';
import { OpenTaskComponent } from 'src/app/components/workspace-pages/cases/dialogs/open-task/open-task.component';
import { VideoViewerComponent } from '../file-manager/dialog/video-viewer/video-viewer.component';
import { Router } from '@angular/router';
import { StorageManagerService } from '../../services/common/storage-manager.service';
import { VideoEditorV5Component } from '../file-manager/dialog/video-editor-v5/video-editor-v5.component';
import { PublishToOutletComponent } from '../file-manager/dialog/publish-to-outlet/publish-to-outlet.component';
import { CompanyService } from '../../services/rest/company.service';
import { FileSettingsComponent } from '../file-manager/dialog/file-settings/file-settings.component';
import { mimeTypes } from 'mime-wrapper';
import { RenameComponent } from '../file-manager/dialog/rename/rename.component';
import { FormControl } from '@angular/forms';
import { OperationChatComponent } from 'src/app/components/atTasksDialog/operation-chat/operation-chat.component';
import { AddChatToBoardComponent } from 'src/app/components/workspace-pages/chats-page/dialogs/add-chat-to-board/add-chat-to-board.component';
import { CreateOperationChatComponent } from 'src/app/components/atTasksDialog/create-operation-chat/create-operation-chat.component';
import { BoardService } from '../../services/rest/board.service';
import * as moment from 'moment';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MY_FORMATS } from 'src/app/components/atTasksDialog/task-profile-add/task-profile-add.component';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { SnackBarItem } from '../snack-bar/snack-bar-item';
import { LayoutService } from '../../services/common/layout.service';
import { CdkDragDrop, CdkDragEnd, CdkDragStart, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { ChooseStatusComponent } from 'src/app/components/workspace-pages/board-page/dialogs/choose-status/choose-status.component';

@Component({
  selector: 'app-lite-board',
  templateUrl: './lite-board.component.html',
  styleUrls: ['./lite-board.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ]
})
export class LiteBoardComponent extends BaseClass implements OnInit, OnDestroy {
  @ViewChild('fileMenu') fileMenu: TemplateRef<any>;
  @Input() target: any;
  @Input() company: any;
  @Input() is_mobile: any;
  @Input() host: any;
  @Input() user: any;
  @Input() activeLang: any;
  @Input() imgRoute: any;
  @Input() liteFilter: any;
  @Input() liteFilterForm: any;
  @Input() useOut: boolean = false;
  public taskStatuses: any;
  public isDragged: boolean = false;
  public boardLoad: boolean = false;

  public todoDataSub: Subscription;
  public doneDataSub: Subscription;
  public todoSub: Subscription;
  public doneSub: Subscription;

  public boardCollection: any = new SelectionModel(
    true,
    []
  );

  public now = new Date();
  public today = moment().set({hour:0,minute:0,second:0}).unix();

  public todayStartVal = moment().set({hour:0,minute:0,second:0}).format("X");
  public todayEndVal = moment().set({hour:23,minute:59,second:59}).format("X");
  public tomorrowStartVal = moment().add(1,'days').set({hour:0,minute:0,second:0}).format("X");
  public tomorrowEndVal = moment().add(1,'days').set({hour:23,minute:59,second:59}).format("X");
  public todayStart = moment().set({hour:0,minute:0,second:0});
  public todayEnd = moment().set({hour:23,minute:59,second:59});
  public tomorrowStart = moment().add(1,'days').set({hour:0,minute:0,second:0});
  public tomorrowEnd = moment().add(1,'days').set({hour:23,minute:59,second:59});

  public statuses: any;
  public operationsValues: any;
  public origin = window.location.origin;
  throttle = 100;
  scrollUpDistance = 3.5;

  public tabStatuses = {
    0: [0],
    1: [1,2,5],
    2: [3,4,6,97,98,99]
  }

  public overlayRefFileMenu: OverlayRef | null;
  public subFileMenu: Subscription;
  @Output() CloseContext = new EventEmitter<any>();
  @Output() OpenJob = new EventEmitter<any>();
  constructor(
    private dialog: MatDialog,
    private fileService: FileService,    
    public router: Router,
    public sm: StorageManagerService,
    public overlay: Overlay,
    public layoutService: LayoutService,
    public boardService: BoardService,
    private _adapter: DateAdapter<any>,    
    public companyService: CompanyService,
    private bottomSheet: MatBottomSheet,
    public viewContainerRef: ViewContainerRef,
    private taskService: TaskService,
    private chatService: ChatService,
  ) {
    super()
   }

  ngOnInit(): void {
    console.log("ngOnInit LiteBoardComponent liteFilter", this.liteFilter)
    this.target.outputBoard.todoPage = 1;
    this.target.outputBoard.todoPagination = undefined;
    this.target.outputBoard.todo_is_load = true;
    this.target.outputBoard.todo = [];
    this.target.outputBoard.donePage = 1;
    this.target.outputBoard.donePagination = undefined;
    this.target.outputBoard.done_is_load = true;
    this.target.outputBoard.done = [];
    this.target.outputBoard.groupedDone = [];

    
    this._adapter.setLocale(this.activeLang);
    if (this.target.outputBoard.boardsIndex === 0) {
      this.getTargetToDo(this.target);
      this.getTargetDone(this.target);
    } else {

    }
    // this.getTargetBoard(this.target);
    this.getTaskStatus();
    this.getOperations();
    this.getOperationsStatus();
  }

  toggleItem(item): void {
    this.boardCollection.toggle(item);
  }

  getOperationStatusName(id) {
    switch (+id) {
      case 1:
        return "Waiting";
        break;
      case 2:
        return "In process";
        break;
      case 3:
        return "To approve";
        break;
      case 4:
        return "Approved";
        break;
      case 5:
        return "To correct";
        break;
      case 6:
        return "Sources Needed";
        break;
      case 97:
        return "On hold";
        break;
      case 98:
        return "Ready";
        break;
      case 99:
        return "Canceled";
        break;
    }
  }

  getTaskStatus() {
    this.attachSubscriptions(
      this.taskService.getTaskStatuses(this.company.id).subscribe(resp => {
        this.taskStatuses = resp.slice();
        this.taskStatuses.unshift({
          id: 0,
          name: marker('All statuses')
        });
      })
    )
  }
    
  getTaskStatusById(id) {
    if (!this.taskStatuses || this.taskStatuses.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.taskStatuses.find(el => el.id == id)
  }

  onPartnerImgError(event){
    event.target.src = this.imgRoute+'/assets/img/partner.png'
  }

  changeTab(e) {
    this.target.outputBoard.boardsIndex = e;
  }

  startDrag(e:CdkDragStart) {
    this.isDragged = true;
  }

  endDrag(e:CdkDragEnd) {
    this.isDragged = false;
  }
  
  sortBoard(array) {
    console.log("sortBoard", array)
    if (array.length == 1) {
      return
    }

    console.log("out1", this.boardCollection.selected.length)
    console.log("out2", this.boardCollection.selected.filter(x => !!x.cant_move).length)
    if (this.boardCollection.selected.length == this.boardCollection.selected.filter(x => !!x.cant_move).length) {
      this.boardCollection.selected.map(b => b.cant_move = false);
      console.log("inside", this.boardCollection.selected)
      return
    }


    let sbmtData = [];
    this.boardCollection.selected.forEach(boardItem => {
      let prevSibling:any = array[array.indexOf(boardItem) - 1];
      let nextSibling:any = array[array.indexOf(boardItem) + 1];
      boardItem.cant_move = false

      if (boardItem.is_partner) {
        sbmtData.push({
          "path": `/api/task-partner-status/${boardItem.id}/`,
          "query": {company_id: this.company.id},
          "method": "POST",
          "body": Object.assign({
            [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
          }, {priority_order_after_id: !!prevSibling ? prevSibling.id : 0, priority_order_after_type: !!prevSibling ? prevSibling.type : null})
        });
      } else if (boardItem.is_partner_employee) {
        sbmtData.push({
          "path": `/api/task-partner-employee-status/${boardItem.id}/`,
          "query": {company_id: this.company.id},
          "method": "POST",
          "body": Object.assign({
            [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
          }, {priority_order_after_id: !!prevSibling ? prevSibling.id : 0, priority_order_after_type: !!prevSibling ? prevSibling.type : null})
        })
      } else {
        sbmtData.push({
          "path": `/api/task-employee-status/${boardItem.id}/`,
          "query": {company_id: this.company.id},
          "method": "POST",
          "body": Object.assign({
            [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
          }, {priority_order_after_id: !!prevSibling ? prevSibling.id : 0, priority_order_after_type: !!prevSibling ? prevSibling.type : null})
        })
      }
    });

    this.attachSubscriptions(
      this.taskService.multiRequest(sbmtData).subscribe(resp => {
        console.log(resp)
      })
    )
  }

  sortDropBoard(event: CdkDragDrop<string[]>) {
    console.log(event);


    let sbmtData = [];

    let prevSibling:any = event.container.data[event.currentIndex - 1];
    let nextSibling:any = event.container.data[event.currentIndex + 1];

    if (event.item.data.is_partner) {
      sbmtData.push({
        "path": `/api/task-partner-status/${event.item.data.id}/`,
        "query": {company_id: this.company.id},
        "method": "POST",
        "body": Object.assign({
          [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
        }, {priority_order_after_id: !!prevSibling ? prevSibling.id : 0, priority_order_after_type: !!prevSibling ? prevSibling.type : null})
      });
    } else if (event.item.data.is_partner_employee) {
      sbmtData.push({
        "path": `/api/task-partner-employee-status/${event.item.data.id}/`,
        "query": {company_id: this.company.id},
        "method": "POST",
        "body": Object.assign({
          [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
        }, {priority_order_after_id: !!prevSibling ? prevSibling.id : 0, priority_order_after_type: !!prevSibling ? prevSibling.type : null})
      })
    } else {
      sbmtData.push({
        "path": `/api/task-employee-status/${event.item.data.id}/`,
        "query": {company_id: this.company.id},
        "method": "POST",
        "body": Object.assign({
          [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
        }, {priority_order_after_id: !!prevSibling ? prevSibling.id : 0, priority_order_after_type: !!prevSibling ? prevSibling.type : null})
      })
    }

    this.attachSubscriptions(
      this.taskService.multiRequest(sbmtData).subscribe(resp => {
        console.log(resp)
        this.target.outputBoard.groupedDone = []
        this.groupDone()
      },
      error => {
        moveItemInArray(event.container.data, event.currentIndex, event.previousIndex);
        this.target.outputBoard.groupedDone = []
        this.groupDone()
      })
    )
  }

  drop(event: CdkDragDrop<string[]>) {
    console.log(event)
    if (event.previousContainer === event.container) {
      if (event.container.id == 'todo') {   
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        this.sortDropBoard(event)
      }
    } else {

      if (event.container.id == 'todo') {
        transferArrayItem(
          event.previousContainer.data,
          event.container.data,
          event.previousIndex,
          event.currentIndex,
        );
        this.sortDropBoard(event)
      } else {
        transferArrayItem(
          event.previousContainer.data,
          event.container.data,
          event.previousIndex,
          0,
        );
        
        this.target.outputBoard.groupedDone = []
        this.groupDone()
      }
      const dialogRef = this.dialog.open(ChooseStatusComponent, {
        disableClose: true,
        data: {
          company: this.company,
          element: event.item.data,
          dirLeft: event.container.id == 'todo' ? true : false,
          showError: () => { this.showError() }
        }
      });
      this.attachSubscriptions(
        dialogRef.afterClosed().subscribe(result => {
          if (!!result && result.event == "update") {
            if (event.container.id == 'todo') {
              this.target.outputBoard.todo.find(x => x.id == event.item.data.id).status_id = result.data.status_id
            } else {
              this.target.outputBoard.done.find(x => x.id == event.item.data.id).status_id = result.data.status_id
            }
            this.target.outputBoard.groupedDone = []
            this.groupDone()
          } else {
            transferArrayItem(
              event.container.data,
              event.previousContainer.data,
              event.currentIndex,
              event.previousIndex,
            );
            this.target.outputBoard.groupedDone = []
            this.groupDone()
          }
        })
      )
    }
  }

  showError() {
    this.layoutService.showSnackBar({name: ''}, marker("You do not have permission to do this"), SnackBarItem)
  }

  getTargetToDo(target) {
    target.outputBoard.todo_is_load = true;

    let getFilter = JSON.parse(JSON.stringify(this.liteFilter));
    getFilter.status_id = this.tabStatuses[1];

    if (!!this.liteFilter.start_at_from) {
      getFilter.start_at_from = moment(this.liteFilter.start_at_from._d).format("X");
    }
    if (!!this.liteFilter.start_at_to) {
      getFilter.start_at_to = moment(this.liteFilter.start_at_to._d).format("X");
    }
    Object.keys(getFilter).forEach(key => {
      if (key.indexOf("_at_",) != -1 && !["start_at_from", "start_at_to"].includes(key) && !!getFilter[key]) {
        getFilter[key] = moment(this.liteFilter[key]._d).format("X")
      }
    })

    this.todoSub = this.boardService.getBoard(target.outputBoard.todoPage, this.company.id, getFilter, '20', target.outputBoard.targetIds.task_operation_id).pipe(
      tap(el => {
        target.outputBoard.todoPagination = {
          'pageCount': el.headers.get('x-pagination-page-count'),
          'perPage': el.headers.get('x-pagination-per-page'),
          'totalCount': el.headers.get('x-pagination-total-count'),
          'currentPage': el.headers.get('x-pagination-current-page'),
        }
        if (target.outputBoard.todoPagination['currentPage'] == '1') {
          target.outputBoard.todo = [];
        }
      }),
      map(el => el.body),
      tap(elements => elements.map(x => {
        if (x.partner_employee_id) {
          x.is_partner_employee = true
          x.employee = x.partnerEmployee
        } else if (x.partner_company_id) {
          x.is_partner = true;
        }
        x.isTodo = true
      })),
      tap(elements => {
        this.boardService.todoData$.next(elements)
      }),
      distinctUntilChanged()
    ).subscribe(resp => {
      if (target.outputBoard.todoPage == 1) {
        target.outputBoard.todo = resp;
      } else {
        target.outputBoard.todo.push(resp);
      }
      target.outputBoard.todoPage++
      target.outputBoard.todo_is_load = false;
    })
  }

  neededTaskData(task) {
    let arr = [
      this.chatService.getTasksChats(this.company.id, [task.id]).pipe(
        tap(res => {
          task['operations'].map(o_el => {
            o_el['chatsInfo'] = [];
            o_el['chatsInfo'].push(...res.filter(z => z.task_operation_id == o_el.id));
            o_el['unReadChats'] = res.filter(z => z.task_operation_id == o_el.id && z.is_read != '1').length;
            o_el['unClosedChats'] = res.filter(z => z.task_operation_id == o_el.id && z.status_id != '4').length;
          });
          task['chatsInfo'] = [];
          task['chatsInfo'].push(...res.filter(z => z.task_id == task.id));
          task['unReadChats'] = res.filter(z => z.is_read != '1').length;
          task['unClosedChats'] = res.filter(z => z.status_id != '4').length;
        })
      )
    ]
    return forkJoin(arr)
  }

  openJobItem(board) {
    console.log(board)
    console.log(this.boardLoad)
    if (this.boardLoad) {
      return
    }
    this.boardLoad = true;
    this.attachSubscriptions(
      this.taskService.getOneTaskExpand(this.company.id, board.task_id).pipe(
        map(x => x.body[0]),
        switchMap(x => this.neededTaskData(x).pipe(map(() => casesModel([x], [], 'update')),map(x => x.arr[0]))),
      ).subscribe(resp => {
        this.boardLoad = false;
        console.log(resp, board)
        const dialogRef = this.dialog.open(OpenJobComponent, {
          backdropClass: 'backdrop_under_header',
          panelClass: !this.is_mobile ? ['open_task_dialog', 'show_header'] : 'open_task_dialog',
          autoFocus: false,
          data: {
            task_id: board.task_id,
            task_operation_id: board.task_operation_id,
            task: resp,
          }
        });
      })
    )

  }

  openChat(board) {
    if (this.boardLoad) {
      return
    }
    this.boardLoad = true;
    this.attachSubscriptions(
      this.taskService.getOneTaskExpand(this.company.id, board.task_id).pipe(
        map(x => x.body[0]),
        switchMap(x => this.neededTaskData(x).pipe(map(() => casesModel([x], [], 'update')),map(x => x.arr[0]))),
      ).subscribe(resp => {
        console.log(resp);
        this.boardLoad = false;
        const goToChat = this.dialog.open(OperationChatComponent, {
          backdropClass: ['backdrop_under_header', "without_pad"],
          panelClass: !this.is_mobile ? ['full_screen_dialog', 'global_chats_dialog', 'show_header'] : ['full_screen_dialog', 'global_chats_dialog'],
          position: !this.is_mobile ? { top: '58px', left: '0', right: '0', bottom: '0' } : {},
          autoFocus: this.is_mobile ? false : true,
          data: {
            company: this.company,
            task: resp,
            dialogType: 'operation_chat',
            operationsValues: this.operationsValues,
            work: resp.operations.find(el => el.id == board.task_operation_id),
            user: this.user,
            empl_status: this.company.permissions.includes('owner') || this.company?.permissions.includes('admin') || this.company.permissions.includes('manager'),
            chat: board.discussion,
            chats: resp.operations.find(el => el.id == board.task_operation_id).chatsInfo
          }
        });
    
        this.attachSubscriptions(
          goToChat.afterClosed().subscribe(result => {
            if (!!result && result.event == 'close') {
              this.chatService.postChatRead(board.discussion_id, 0, this.company.id).subscribe(resp => {
                console.log(resp);
              })
            }
          })
        )
      })
    )
  }

  getColumnName(id) {
    if (!this.operationsValues || this.operationsValues.filter(el => el.id == id).length == 0) {
      return '';
    }
    return this.operationsValues.find(el => el.id == id)?.translation?.name ? this.operationsValues.find(el => el.id == id).translation.name : this.operationsValues.find(el => el.id == id).name
  }

  getTargetDone(target) {
    target.outputBoard.todo_is_load = true;

    let getFilter = JSON.parse(JSON.stringify(this.liteFilter));
    getFilter.status_id = this.tabStatuses[2];

    if (!!this.liteFilter.start_at_from) {
      getFilter.start_at_from = moment(this.liteFilter.start_at_from._d).format("X");
    }
    if (!!this.liteFilter.start_at_to) {
      getFilter.start_at_to = moment(this.liteFilter.start_at_to._d).format("X");
    }
    Object.keys(getFilter).forEach(key => {
      if (key.indexOf("_at_",) != -1 && !["start_at_from", "start_at_to"].includes(key) && !!getFilter[key]) {
        getFilter[key] = moment(this.liteFilter[key]._d).format("X")
      }
    })

    this.doneSub = this.boardService.getBoard(target.outputBoard.donePage, this.company.id, getFilter, '20', target.outputBoard.targetIds.task_operation_id).pipe(
      tap(el => {
        target.outputBoard.donePagination = {
          'pageCount': el.headers.get('x-pagination-page-count'),
          'perPage': el.headers.get('x-pagination-per-page'),
          'totalCount': el.headers.get('x-pagination-total-count'),
          'currentPage': el.headers.get('x-pagination-current-page'),
        }
        if (target.outputBoard.donePagination['currentPage'] == '1') {
          target.outputBoard.done = [];
          target.outputBoard.groupedDone = [];
        }
      }),
      map(el => el.body),
      tap(elements => elements.map(x => {
        if (x.partner_employee_id) {
          x.is_partner_employee = true
          x.employee = x.partnerEmployee
        } else if (x.partner_company_id) {
          x.is_partner = true;
        }
        x.isTodo = false
      })),
      tap(elements => {
        this.boardService.doneData$.next(elements)
      }),
      distinctUntilChanged()
    ).subscribe(resp => {
      if (target.outputBoard.donePage == 1) {
        target.outputBoard.done = resp;
      } else {
        target.outputBoard.done.push(resp);
      }
      this.groupDone();
      target.outputBoard.donePage++
      target.outputBoard.done_is_load = false;
    })
  }

  groupDone() {
    console.log("groupDone", this.target.outputBoard.done);
    this.target.outputBoard.done.forEach(item => {
      if (this.target.outputBoard.groupedDone.filter(x => moment(item.wpc_updated_at*1000).isSameOrAfter(x.start) && moment(item.wpc_updated_at*1000).isSameOrBefore(x.end)).length == 0) {
        this.target.outputBoard.groupedDone.push({
          start: moment(item.wpc_updated_at*1000).clone().startOf('day'),
          end: moment(item.wpc_updated_at*1000).clone().endOf('day'),
          timestamp: moment(item.wpc_updated_at*1000).clone().startOf('day').format("X"),
          items: [item]
        })
      } else {
        if (this.target.outputBoard.groupedDone.find(x => moment(item.wpc_updated_at*1000).isSameOrAfter(x.start) && moment(item.wpc_updated_at*1000).isSameOrBefore(x.end)).items.filter(x => x.id == item.id).length == 0) {
          this.target.outputBoard.groupedDone.find(x => moment(item.wpc_updated_at*1000).isSameOrAfter(x.start) && moment(item.wpc_updated_at*1000).isSameOrBefore(x.end)).items.push(item)
        }
      }
    })
    console.log("target.outputBoard.groupedDone", this.target.outputBoard.groupedDone);
  }


  getOperationsStatus() {
    this.attachSubscriptions(
      this.taskService.getOperationsStatus().subscribe(resp => {
        this.statuses = resp.slice();
      })
    )
  }

  // createChat() {
  //   this.attachSubscriptions(
  //     this.taskService.getOneTaskExpand(this.company.id, this.target.outputBoard.targetIds.task_id).pipe(
  //       map(x => x.body[0]),
  //       switchMap(x => this.neededData(x, this.company).pipe(map(() => casesModel([x], [], 'update')),map(x => x.arr[0]))),
  //     ).subscribe(resp => {
  //       const dialogRef = this.dialog.open(CreateOperationChatComponent, {
  //         disableClose: true,
  //         data: {
  //           is_create_chat_component: true,
  //           company: this.company,
  //           task: resp,
  //           work: !!this.target.outputBoard.targetIds.task_operation_id && resp.operations.find(u => u.id == this.target.outputBoard.targetIds.task_operation_id) ? resp.operations.find(u => u.id == this.target.outputBoard.targetIds.task_operation_id) : undefined,
  //           user: this.user,
  //           empl_status: this.company.permissions.includes('owner') || this.checkIsManager(resp, this.company, this.user),
  //           operationsValues: this.operationsValues
  //         }
  //       });
    
  //       this.attachSubscriptions(
  //         dialogRef.afterClosed().subscribe(result => {
  //           console.log(result)
  //           if (!!result && result.event == 'create') {
  //             this.target.outputBoard.page = 1;
  //             this.target.outputBoard.pagination = undefined;
  //             this.target.outputBoard.is_load = true;
  //             this.target.outputBoard.chats = [];
  //             this.getTargetBoard(this.target);
  //           }
  //         })
  //       )
  //     })
  //   )

  // }

  // goToChat(chat, job) {
  //   console.log("goToChat", chat, job)
  //   this.attachSubscriptions(
  //     this.taskService.getOneTaskExpand(this.company.id, this.target.outputBoard.targetIds.task_id).pipe(
  //       map(x => x.body[0]),
  //       switchMap(x => this.neededData(x, this.company).pipe(map(() => casesModel([x], [], 'update')),map(x => x.arr[0]))),
  //     ).subscribe(resp => {
  //       const goToChat = this.dialog.open(OperationChatComponent, {
  //         backdropClass: ['backdrop_under_header', "without_pad"],
  //         panelClass: !this.is_mobile ? ['full_screen_dialog', 'global_chats_dialog', 'show_header'] : ['full_screen_dialog', 'global_chats_dialog'],
  //         position: !this.is_mobile ? { top: '58px', left: '0', right: '0', bottom: '0' } : {},
  //         autoFocus: this.is_mobile ? false : true,
  //         data: {
  //           company: this.company,
  //           task: resp,
  //           work: !!this.target.outputBoard.targetIds.task_operation_id && resp.operations.find(u => u.id == this.target.outputBoard.targetIds.task_operation_id) ? resp.operations.find(u => u.id == this.target.outputBoard.targetIds.task_operation_id) : undefined,
  //           dialogType: 'operation_chat',
  //           user: this.user,
  //           empl_status: this.company.permissions.includes('owner') || this.checkIsManager(resp, this.company, this.user),
  //           chat: chat,
  //           chats: this.target.outputBoard.chats,
  //           closeFull: true,
  //           operationsValues: this.operationsValues
  //         }
  //       });
    
  //       this.attachSubscriptions(
  //         goToChat.afterClosed().subscribe(result => {
  //           this.target.outputBoard.page = 1;
  //           this.target.outputBoard.pagination = undefined;
  //           this.target.outputBoard.is_load = true;
  //           this.target.outputBoard.chats = [];
  //           this.getTargetBoard(this.target);
  //         })
  //       )
  //     })
  //   )
  // }

  checkIsManager(task, company, _user) { 
    return task?.managers.filter(x => x.user_id == _user.id).length > 0 || task?.group?.managers.filter(x => x.user_id == _user.id).length > 0 || company?.permissions.includes('admin') || company?.permissions.includes('owner') || company?.permissions.includes('manager'); 
  }

  log() {
    console.log('target', this.target)
  }

  understandFileType(val) {
    if (!val) {
      return ""
    }
    
    if (val == 'application/pdf') {
      return 'pdf'
    } else if (val.indexOf("/") != -1) {
      return val.split('/')[0]
    } else {
      return ""
    }
  }

  getOperations() {
    this.attachSubscriptions(
      this.taskService.getOperations(this.company.id, this.activeLang).subscribe(resp => {
        this.operationsValues = resp;
      })
    )
  }

  neededCardData(task) {
    let arr = [
      this.chatService.getTasksChats(this.company.id, [task.id]).pipe(
        tap(res => {
          task['operations'].map(o_el => {
            o_el['chatsInfo'] = [];
            o_el['chatsInfo'].push(...res.filter(z => z.task_operation_id == o_el.id));
            o_el['unReadChats'] = res.filter(z => z.task_operation_id == o_el.id && z.is_read != '1').length;
            o_el['unClosedChats'] = res.filter(z => z.task_operation_id == o_el.id && z.status_id != '4').length;
          });
          task['chatsInfo'] = [];
          task['chatsInfo'].push(...res.filter(z => z.task_id == task.id));
          task['unReadChats'] = res.filter(z => z.is_read != '1').length;
          task['unClosedChats'] = res.filter(z => z.status_id != '4').length;
        })
      )
    ]
    if (task.company_id == this.company.id) {
      arr.push(
        this.taskService.getTaskClients(task.id, this.company.id).pipe(
          tap(res => {
            task.clients = res
          }),
          catchError(err => {
            return err
          })
        )
      )
    }
    arr.push(...task.operations.filter(z => (z.status_id == 3 && z.is_to_approve_files) || (z.status_id == 98 && z.is_approved_files)).map(x => this.fileService.getFilesForOpenTask(this.company.id, x.id, x.status_id == 3 ? '/to_approve' : '/ready_files').pipe(
      tap(val => {
        x.filesCount = val.headers.get('x-pagination-total-count')
        x.files = val.body
      })
    )))

    return forkJoin(arr)
  }

  openJob(task_id, task_operation_id, loc?) {
    if (this.target.can_open) {
      this.OpenJob.emit(true);
    } else {
      this.attachSubscriptions(
        this.taskService.getOneTaskExpand(this.company.id, task_id).pipe(
          map(x => x.body[0]),
          switchMap(x => this.neededCardData(x).pipe(map(() => casesModel([x], [], 'update')),map(x => x.arr[0]))),
        ).subscribe(resp => {
          const dialogRef = this.dialog.open(OpenJobComponent, {
            backdropClass: 'backdrop_under_header',
            panelClass: !this.is_mobile ? ['open_task_dialog', 'show_header'] : 'open_task_dialog',
            autoFocus: false,
            data: {
              task_id: task_id,
              task_operation_id: task_operation_id,
              task: resp,
              initCompanyId: this.company.id,
              loc: loc
            }
          });
        })
      )
    }
  }

  openTask(task) {
    const dialogRef = this.dialog.open(OpenTaskComponent, {
      backdropClass: 'backdrop_under_header',
      panelClass: !this.is_mobile ? ['open_task_dialog', 'show_header'] : 'open_task_dialog',
      autoFocus: false,
      data: {
        task_id: task.id,
        initCompanyId: this.company.id
      }
    });
  }

  
  neededData(task, company) {
    let arr = [
      this.chatService.getTasksChats(company.id, [task.id]).pipe(
        tap(res => {
          task['operations'].map(o_el => {
            o_el['chatsInfo'] = [];
            o_el['chatsInfo'].push(...res.filter(z => z.task_operation_id == o_el.id));
            o_el['unReadChats'] = res.filter(z => z.task_operation_id == o_el.id && z.is_read != '1').length;
            o_el['unClosedChats'] = res.filter(z => z.task_operation_id == o_el.id && z.status_id != '4').length;
          });
          task['chatsInfo'] = [];
          task['chatsInfo'].push(...res.filter(z => z.task_id == task.id));
          task['unReadChats'] = res.filter(z => z.is_read != '1').length;
          task['unClosedChats'] = res.filter(z => z.status_id != '4').length;
        })
      )
    ]
    if (task.company_id == company.id) {
      arr.push(
        this.taskService.getTaskClients(task.id, company.id).pipe(
          tap(res => {
            task.clients = res
          }),
          catchError(err => {
            return err
          })
        )
      )
    }
    return forkJoin(arr)
  }

  onImgError(event){
    event.target.src = this.imgRoute+'/assets/img/image_black_48dp.svg'
  }

  onDownToDo($event, target) {
    if (target.outputBoard.todoPagination['pageCount'] >= target.outputBoard.todoPage) {
      console.log("onDownToDo")
      this.getTargetToDo(target)
    }
  }
  onDownDone($event, target) {
    if (target.outputBoard.donePagination['pageCount'] >= target.outputBoard.donePage) {
      console.log("onDownDone")
      this.getTargetDone(target)
    }
  }


  // getTargetBoard(target) {
  //   // let params:any = {}
  //   // if (!!this.chatsIndex.value) {
  //   //   params.status_id = this.tabStatuses[this.chatsIndex.value]
  //   // }
  //   let filterData = {...this.liteFilter};
  //   // if (!!this.chatsIndex.value) {
  //   //   filterData.status_id = this.tabStatuses[this.chatsIndex.value]
  //   // }
  //   // Object.keys(filterData).filter(x => x != 'preset_id').forEach(element => {
  //   //   if (element != 'location_type') {
  //   //     if (element.indexOf('_at_') != -1 && filterData[element] !== '') {
  //   //       params[element] = filterData[element].format("X")
  //   //     } else if (['discussion_parameter_value_id'].includes(element) && filterData[element] && filterData[element].length > 0) {
  //   //       params[element] = filterData[element].join(',')
  //   //     } else if (filterData[element] !== '') {
  //   //       params[element] = filterData[element]
  //   //     }
  //   //   }
  //   // });
  //   this.attachSubscriptions(
  //     this.chatService.getChatsExpand(target.outputBoard.page, this.company.id, filterData, '20', target.outputBoard.targetIds.task_id, target.outputBoard.targetIds.task_operation_id).pipe(
  //       tap(el => {
  //         if (target.outputBoard.page == 1) {
  //           target.outputBoard.pagination = {
  //             'pageCount': el.headers.get('x-pagination-page-count'),
  //             'perPage': el.headers.get('x-pagination-per-page'),
  //             'totalCount': el.headers.get('x-pagination-total-count'),
  //             'currentPage': el.headers.get('x-pagination-current-page'),
  //           }
  //         }
  //       }),
  //       map(el => el.body)
  //     ).subscribe(resp => {
  //       if (target.outputBoard.page == 1) {
  //         target.outputBoard.chats = resp;
  //       } else {
  //         target.outputBoard.chats.push(...resp);
  //       }
  //       target.outputBoard.page++;
  //       target.outputBoard.is_load = false;
  //       console.log("openContext target.outputBoard", target.outputBoard)
  //     })
  //   )
  // }

  getCircularReplacer() {
    const ancestors:any = [];
    return function (key, value) {
      if (typeof value !== "object" || value === null) {
        return value;
      }
      // `this` is the object that value is contained in,
      // i.e., its direct parent.
      while (ancestors.length > 0 && ancestors.at(-1) !== this) {
        ancestors.pop();
      }
      if (ancestors.includes(value)) {
        return "[Circular]";
      }
      ancestors.push(value);
      return value;
    };
  }

  ngOnDestroy(): void {
    this.clearSubscriptions();
  }
}
