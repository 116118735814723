import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { catchError, tap } from "rxjs/operators";
import { BaseClass } from "src/app/shared/models/base-class";
import { LayoutService } from "src/app/shared/services/common/layout.service";
import { StorageManagerService } from "src/app/shared/services/common/storage-manager.service";
import { MembersService } from "src/app/shared/services/rest/members.service";
import { TaskService } from "src/app/shared/services/rest/task.service";


@Component({
  selector: 'app-choose-status',
  templateUrl: './choose-status.component.html',
  styleUrls: ['./choose-status.component.scss']
})
export class ChooseStatusComponent extends BaseClass implements OnInit, OnDestroy {
  public operationStatuses: any;
  public form: FormGroup;
  public isFormChange: boolean = false;

  constructor(
    private taskService: TaskService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ChooseStatusComponent>,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private bottomSheet: MatBottomSheet,
    private sm: StorageManagerService,
    private membersService: MembersService,
    private layoutService: LayoutService
  ) { super() }

  ngOnInit(): void {
    console.log("ChooseStatusComponent", this.data)
    this.getOperationsStatus();
    
    this.form = this.fb.group({
      status_id: [this.data.element.status_id, Validators.required],
    })


    this.attachSubscriptions(
      this.form.valueChanges.subscribe((val) => {
        this.isFormChange = true        
      })
    )

    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        if (this.isFormChange) {
          this.layoutService.openBottomSheet(this.dialogRef);
        } else {
          this.close();
        }
      })
    )
  }

  getOperationsStatus() {
    this.attachSubscriptions(
      this.taskService.getOperationsStatus().subscribe(resp => {
        console.log("operationStatuses", resp)
        let statusesData = resp.slice();
        if (this.data.dirLeft) {
          this.operationStatuses = statusesData.filter(x => [1,2,5].includes(x.id) );
        } else {
          this.operationStatuses = statusesData.filter(x => ![1,2,5].includes(x.id) );
        }
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  onSubmit() {
    if (this.data.element.is_partner) {
      this.attachSubscriptions(
        this.membersService.editPartnerStatus(this.data.element.id, this.form.value, this.data.company.id).pipe(
          tap(res => {
            this.data.element.start_at = res.start_at
          })
        ).subscribe(resp => {
          this.dialogRef.close({event: "update", data: resp})
        },
        error => {
          this.data.showError()
          this.dialogRef.close({event: "error", data: {}})
        })
      )
    } else if (this.data.element.is_partner_employee) {
      this.attachSubscriptions(
        this.membersService.editPartnerEmployeeStatus(this.data.element.id, this.form.value, this.data.company.id).pipe(
          tap(res => {
            this.data.element.start_at = res.start_at
          })
        ).subscribe(resp => {
          this.dialogRef.close({event: "update", data: resp})
        },
        error => {
          this.data.showError()
          this.dialogRef.close({event: "error", data: {}})
        })
      )
    } else {
      this.attachSubscriptions(
        this.membersService.editTaskEmployeeStatus(this.data.element.id, this.form.value, this.data.company.id).pipe(
          tap(res => {
            this.data.element.start_at = res.start_at
          })
        ).subscribe(resp => {
          this.dialogRef.close({event: "update", data: resp})
        },
        error => {
          this.data.showError()
          this.dialogRef.close({event: "error", data: {}})
        })
      )
    }
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

}
