<h2 mat-dialog-title class="dialog_title">
    <span>
        {{ "Set privacy status" | translate }} {{this.data.collection ? ('for' | translate) + " " + this.data.collection.length + " " + ('selected posts' | translate) : ''}}:
    </span>
    <button class="ml-1" mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h2>
<mat-dialog-content class="mat-typography" dirEnter (dirSubmit)="!form.valid || !!isSubmit ? null : onSubmit()">
    <div class="container form_wrapper">
        <form [formGroup]="form" style="margin: 20px 0;">
            <mat-radio-group
            formControlName="privacy"
            class="example-radio-group">
                <mat-radio-button color="primary" class="privacy-radio-button" [value]="'private'">
                    <div class="d-f-c">
                        <mat-icon class="mr-5">lock</mat-icon>
                        {{"Private" | translate}}
                    </div>
                </mat-radio-button>
                <mat-radio-button color="primary" class="privacy-radio-button" [value]="'public'">
                    <div class="d-f-c">
                        <mat-icon class="mr-5">visibility</mat-icon>
                        {{"Public" | translate}}
                    </div>
                </mat-radio-button>
                <mat-radio-button color="primary" class="privacy-radio-button" [value]="'unlisted'">
                    <div class="d-f-c">
                        <mat-icon class="mr-5">visibility_off</mat-icon>
                        {{"Unlisted" | translate}}
                    </div>
                </mat-radio-button>
            </mat-radio-group> 
        </form>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end" style="margin-top: 5px;">
    <button mat-raised-button (click)="close()">
        {{ "Back" | translate }}
    </button>
    <button class="ml-1" (click)="onSubmit()" mat-raised-button cdkFocusInitial color="primary" [disabled]="!form.valid || !!isSubmit">
        {{ "Set" | translate }}
    </button>
</mat-dialog-actions>
<!-- 

<mat-dialog-content class="mat-typography">
    <div class="container form_wrapper">
        <mat-tab-group [selectedIndex]="activeIndex" (selectedIndexChange)="changeTab($event)">
            <mat-tab>
                <ng-template mat-tab-label>
                    <mat-icon class="example-tab-icon mr-1">lock</mat-icon>
                    {{ "Sftp privacy" | translate }}
                </ng-template>
                
                <form [formGroup]="form" style="margin: 20px 0;">
                    <mat-radio-group
                    formControlName="privacy"
                    class="example-radio-group">
                        <mat-radio-button color="primary" class="privacy-radio-button" [value]="'private'">
                            <div class="d-f-c">
                                <mat-icon class="mr-5">lock</mat-icon>
                                {{"Private" | translate}}
                            </div>
                        </mat-radio-button>
                        <mat-radio-button color="primary" class="privacy-radio-button" [value]="'public'">
                            <div class="d-f-c">
                                <mat-icon class="mr-5">visibility</mat-icon>
                                {{"Public" | translate}}
                            </div>
                        </mat-radio-button>
                        <mat-radio-button color="primary" class="privacy-radio-button" [value]="'unlisted'">
                            <div class="d-f-c">
                                <mat-icon class="mr-5">visibility_off</mat-icon>
                                {{"Unlisted" | translate}}
                            </div>
                        </mat-radio-button>
                    </mat-radio-group> 
                </form>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    <mat-icon class="example-tab-icon mr-1">lock</mat-icon>
                    {{ "API privacy" | translate }}
                </ng-template>
                
                <form [formGroup]="apiForm" style="margin: 20px 0;">
                    <mat-radio-group
                    formControlName="privacy"
                    class="example-radio-group">
                        <mat-radio-button color="primary" class="privacy-radio-button" [value]="'private'">
                            <div class="d-f-c">
                                <mat-icon class="mr-5">lock</mat-icon>
                                {{"Private" | translate}}
                            </div>
                        </mat-radio-button>
                        <mat-radio-button color="primary" class="privacy-radio-button" [value]="'public'">
                            <div class="d-f-c">
                                <mat-icon class="mr-5">visibility</mat-icon>
                                {{"Public" | translate}}
                            </div>
                        </mat-radio-button>
                        <mat-radio-button color="primary" class="privacy-radio-button" [value]="'unlisted'">
                            <div class="d-f-c">
                                <mat-icon class="mr-5">visibility_off</mat-icon>
                                {{"Unlisted" | translate}}
                            </div>
                        </mat-radio-button>
                    </mat-radio-group> 

                    <div class="box_wrp">
                        <mat-checkbox color="primary" formControlName="made_for_kids">{{ "Made for kids" | translate }}</mat-checkbox>
                    </div>
                </form>
            </mat-tab>
        </mat-tab-group>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <div class="f_w_p_btns">
        <button mat-raised-button (click)="close()">
            {{ "Close" | translate }}
        </button>
        <button class="ml-1 d-f-btn" (click)="sumbitBtn()" mat-raised-button color="primary" [disabled]="isSubmit">
            <mat-spinner class="spin_16 mr-1" *ngIf="isSubmit"></mat-spinner>  
            {{ "Save" | translate }}
        </button>
    </div>
</mat-dialog-actions>
 -->
