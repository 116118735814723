<h2 mat-dialog-title class="dialog_title">
    <span>
        {{ "You have " | translate }}{{data?.task_count}}{{" jobs needs to be finalized with delivery of final materials." | translate }}
    </span>
</h2>
<mat-dialog-content class="mat-typography" dirEnter (dirSubmit)="onSubmit()">
    <div class="container">
        {{ "Please deliver remaining resources according check lists of those jobs as soon as possible." | translate }}
    </div>
</mat-dialog-content>
<mat-dialog-actions align="start">
    <button mat-flat-button (click)="close()">
        {{ "Close" | translate }}
    </button>
    <button class="ml-1" (click)="onSubmit()" mat-raised-button cdkFocusInitial color="primary">
        {{ "Jobs to finalize" | translate }}
    </button>
</mat-dialog-actions>
