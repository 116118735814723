import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChildren } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { concat, forkJoin, of, Subscription } from 'rxjs';
import { concatMap, debounceTime, distinctUntilChanged, finalize, last, map, switchMap, take, tap } from 'rxjs/operators';
import { BaseClass } from 'src/app/shared/models/base-class';
import { ParametersService } from 'src/app/shared/services/rest/parameters.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { FormControl } from '@angular/forms';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { SelectParameterComponent } from './dialogs/select-parameter/select-parameter.component';
import { LoadingService } from 'src/app/shared/services/rest/loading.service';

@Component({
  selector: 'app-target-parameters',
  templateUrl: './target-parameters.component.html',
  styleUrls: ['./target-parameters.component.scss']
})
export class TargetParametersComponent extends BaseClass implements OnInit, OnDestroy {
  public values: any;
  public parameters: any;
  public allValues: any;
  public paramDataSub: Subscription
  public separatorKeysCodes: number[] = [ENTER, COMMA];

  public isSubmit: boolean = false;
  public eltsSub: Subscription;
  public page = 1;
  public pagination: any;
  public cards: any = [];
  public isLoad: boolean = false;
  public progress:number = 0;
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<TargetParametersComponent>,
    private dialog: MatDialog,
    private parametersService: ParametersService,
    private sm: StorageManagerService,
    private ls: LoadingService,
    private taskService: TaskService,
    private layoutService: LayoutService,
  ) { super() }

  ngOnInit(): void {
    console.log("TargetParametersComponent", this.data)
    // this.getAllApiParameterValues()

    if (!this.data.selectedFiles && !this.data.is_all_cards_results && !this.data.is_all_files_results && !this.data.selectedJobs && !this.data.selectedCards && !this.data.auto) {
      this.getValues()
    }

    if (this.data.is_all_cards_results) {
      this.getCards(this.page);
    }

    if (this.data.auto && this.data.forOutput) {
      this.values = this.data.tags;
    } else {
      if (this.data.auto && this.data.parameters.length && this.data.parameters[0].activeValues && this.data.parameters[0].activeValues.length) {
        this.values = this.data.parameters[0].activeValues
      }
    }

    this.paramDataSub = this.parametersService.getParamsTargetStream().pipe(
      concatMap(params => {
        return forkJoin([...params.map(oneParam => this.neededParametersData(oneParam))]).pipe(last(),map(x => params))
      }),
    ).subscribe(resp => console.log("++++++++getParamsTargetStream++++++++", resp));
    this.getParameters()
  }

  getCards(n) {
    this.isLoad = true;
    let filterData:any = {...this.data.filterData};

    this.eltsSub = this.taskService.getTasksSelectNoExpNew(n, this.data.company.id, filterData).pipe(
      tap(el => {
        this.pagination = {
          'pageCount': el.headers.get('x-pagination-page-count'),
          'perPage': el.headers.get('x-pagination-per-page'),
          'totalCount': el.headers.get('x-pagination-total-count'),
          'currentPage': el.headers.get('x-pagination-current-page')
        }
        console.log("this.pagination TEST", this.pagination)
      }),
      map(el => el.body),
      distinctUntilChanged()
    ).subscribe(resp => {

      console.log("getCards", resp)
      
      if (this.page == 1) {
        this.cards = resp;
      } else {
        this.cards.push(...resp);
      }

      this.progress = +((this.cards.length / +this.pagination.totalCount)*100).toFixed(1);
      this.page = this.page + 1;
      
      
      if (+this.pagination['currentPage'] < +this.pagination['pageCount'] && +this.pagination['pageCount'] > 0) {
        this.getCards(this.page)
      } else {
        console.log("cards - " + this.page, this.cards)
        this.isLoad = false;
        this.progress = 100;
      }
    },
    error => {
      this.isLoad = false;
    })
  }

  togglePrimary(val, param, k) {
    console.log(val, param, k);
    if (this.data.auto || this.data.is_all_cards_results || this.data.is_all_files_results) {
      val.is_primary = Number(!val.is_primary);
    } else if (!!this.data.selectedFiles) {

      console.log("togglePrimary", this.data.selectedFiles)
      let postData = [];
                    
      this.data.selectedFiles.forEach(file => {
        let value_id = file.parameterValuesToTask.find(x => x.parameter_value_id == val.parameter_value_id) ? file.parameterValuesToTask.find(x => x.parameter_value_id == val.parameter_value_id).id : null
        if (value_id) {
          postData.push({
            "path": `/api/parameter-value-to-task/${value_id}/`,
            "query": {"company_id": this.data.company.id, "expand": "parameter,parameterValue"},
            "method": "POST",
            "body": {
              [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
              is_primary: !!val.is_primary ? 0 : 1
            }
          })
        }
      });
      if (postData.length) {
        this.attachSubscriptions(
          this.taskService.multiRequest(postData).subscribe(resp => {
            if (resp && resp.length) {
              val.is_primary = resp[0].body.is_primary;
  
              resp.forEach(element => {    
                this.data.selectedFiles.forEach(file => {
                  if (file.id == element.body.file_id) {
                    file.parameterValuesToTask.find(x => x.id == element.body.id).is_primary = element.body.is_primary
                  }
                })
              });
            }
          })
        )
      }
    } else if (!!this.data.selectedJobs) {

      console.log("togglePrimary", this.data.selectedJobs)
      let postData = [];
                    
      this.data.selectedJobs.forEach(job => {
        let value_id = job.parameterValuesToTask.find(x => x.parameter_value_id == val.parameter_value_id) ? job.parameterValuesToTask.find(x => x.parameter_value_id == val.parameter_value_id).id : null
        if (value_id) {
          postData.push({
            "path": `/api/parameter-value-to-task/${value_id}/`,
            "query": {"company_id": this.data.company.id, "expand": "parameter,parameterValue"},
            "method": "POST",
            "body": {
              [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
              is_primary: !!val.is_primary ? 0 : 1
            }
          })
        }
      });
      if (postData.length) {
        this.attachSubscriptions(
          this.taskService.multiRequest(postData).subscribe(resp => {
            if (resp && resp.length) {
              val.is_primary = resp[0].body.is_primary;
  
              resp.forEach(element => {    
                this.data.selectedJobs.forEach(job => {
                  if (job.id == element.body.task_operation_id) {
                    job.parameterValuesToTask.find(x => x.id == element.body.id).is_primary = element.body.is_primary
                  }
                })
              });
            }
          })
        )
      }
    } else if (!!this.data.selectedCards) {

      console.log("togglePrimary", this.data.selectedCards)
      let postData = [];
                    
      this.data.selectedCards.forEach(card => {
        let value_id = card.parameterValuesToTask.find(x => x.parameter_value_id == val.parameter_value_id) ? card.parameterValuesToTask.find(x => x.parameter_value_id == val.parameter_value_id).id : null
        if (value_id) {
          postData.push({
            "path": `/api/parameter-value-to-task/${value_id}/`,
            "query": {"company_id": this.data.company.id, "expand": "parameter,parameterValue"},
            "method": "POST",
            "body": {
              [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
              is_primary: !!val.is_primary ? 0 : 1
            }
          })
        }
      });
      if (postData.length) {
        this.attachSubscriptions(
          this.taskService.multiRequest(postData).subscribe(resp => {
            if (resp && resp.length) {
              val.is_primary = resp[0].body.is_primary;
  
              resp.forEach(element => {    
                this.data.selectedCards.forEach(card => {
                  if (card.id == element.body.task_id) {
                    card.parameterValuesToTask.find(x => x.id == element.body.id).is_primary = element.body.is_primary
                  }
                })
              });
            }
          })
        )
      }
    }  else {
      this.attachSubscriptions(
        this.parametersService.editTargetParameter(val.id, {is_primary: !!val.is_primary ? 0 : 1}, this.data.company.id).subscribe(resp => {
          val.is_primary = resp.is_primary;
        }, error => {
          this.showError(error);
        })
      )
    }
  }

  getParameters() {
    this.attachSubscriptions(
      this.parametersService.getApiParameters(this.data.company.id, this.data.target && this.data.target.partner_company_id ? this.data.target.partner_company_id : null).pipe(
        switchMap(res => {
          return this.parametersService.getAllValues('1', this.data.company.id, this.data.target && this.data.target.partner_company_id ? {partner_company_id: this.data.target.partner_company_id} : null, '1').pipe(
            switchMap(el => {
              let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
              let arr = []
              for (let index = 1; index <= pages; index++) {
                arr.push(index)
              }
    
              return forkJoin(arr.map(x => this.parametersService.getAllValues(x, this.data.company.id, this.data.target && this.data.target.partner_company_id ? {partner_company_id: this.data.target.partner_company_id} : null).pipe(map(u => u.body)))).pipe(
                last(),
                tap(values => {
                  this.allValues = [].concat(...values)
                  console.log("getAllApiParameterValues", this.allValues)
                }),
                map(() => res)
              )
            }),
            map(() => res)
          )
        }),
        tap(res => {
          res.unshift({
            company_id: this.data.company.id,
            created_at: 0,
            id: 0,
            is_new_value: 0,
            name: "All parameters",
            original_id: 0,
          })
          this.parametersService.paramsTargetData$.next(res)
          res.forEach(n => {
            n.search = new FormControl('');
            n.search.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearch(resp, n))
          })
          this.parameters = res
        }),
      ).subscribe(resp => {
        console.log("this.parameters", this.parameters)
      })
    )
  }

  onSearch(searchText, param) {
    console.log(searchText, param)
    param.page = 1;
    this.parametersService.getApiParameterValues(this.data.company.id, param.id, "20", !!param.page ? param.page : "1",  !!param.search.value ? param.search.value : '', null, this.data.target && this.data.target.partner_company_id ? this.data.target.partner_company_id : null).subscribe(resp => {
      param.pagination = {
        'pageCount': resp.headers.get('x-pagination-page-count'),
        'perPage': resp.headers.get('x-pagination-per-page'),
        'totalCount': resp.headers.get('x-pagination-total-count'),
        'currentPage': resp.headers.get('x-pagination-current-page'),
      }
      param.page = 2
      param.values = resp.body;
    })
  }

  noValues(param) {
    if (!!param.search.value && typeof param.search.value == 'string') {
      console.log("param.search.value", param.search.value)
      let searchResult = !!param.search.value ? param.values.filter(x => x.value.toLowerCase() == param.search.value.toLowerCase()) : []
      if (searchResult.length == 0 && !!param.search.value) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  withSearch(searchText: string, val) {
    if (searchText == '' || typeof searchText != 'string' || val.value.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ) {
      return true
    } else {
      return false
    }
  } 

  getParameterById(id) {
    if (!this.parameters || this.parameters.length == 0) {
      return null
    }
    return this.parameters.find(x => x.id == id)
  }

  
  noOption(arr, id) {
    if (this.data.auto) {
      return arr.filter(x => x.id == id).length > 0 ? false : true;
    } else {
      return arr.filter(x => x.parameter_value_id == id).length > 0 ? false : true;
    }
  }

  neededParametersData(param) {
    let arr = []

    arr.push(
      this.parametersService.getApiParameterValues(this.data.company.id, param.id, "20", !!param.page ? param.page : "1", '', null, this.data.target && this.data.target.partner_company_id ? this.data.target.partner_company_id : null).pipe(
        tap(x => {
          param.pagination = {
            'pageCount': x.headers.get('x-pagination-page-count'),
            'perPage': x.headers.get('x-pagination-per-page'),
            'totalCount': x.headers.get('x-pagination-total-count'),
            'currentPage': x.headers.get('x-pagination-current-page'),
          }
          param.page = 2
          param.values = x.body;
          if (x.body.length > 0) {
            param.hasValues = true;
          }

          if (this.values) {
            if (param.id != 0) {
              param.activeValues = this.values.filter(b => b.parameter_id == param.id);
            } else {
              param.activeValues = this.values.slice();
            }
          } else {
            param.activeValues = []
          }
        })
      )
    )

    return forkJoin(arr)
  }

  onScroll(param) {
    console.log(param.page, param.pagination.pageCount)
    if (param.pagination.pageCount >= param.page) {
      console.log("SCROLL !")
      this.parametersService.getApiParameterValues(this.data.company.id, param.id, "20", param.page, !!param.search.value ? param.search.value : '', null, this.data.target && this.data.target.partner_company_id ? this.data.target.partner_company_id : null).pipe(take(1), map((x) => x.body)).subscribe(resp => {
        param.page++;
        param.values.push(...resp)
      })
    }
  }
  
  getValues() {
    if (this.data.target) {
      if (!this.data.target.hasOwnProperty('file_id')) {
        this.data.target.file_id = 0
      }
      this.attachSubscriptions(
        this.parametersService.getTargetParameters(this.data.company.id, this.data.target).subscribe(resp => {
          this.values = resp;
          console.log("getTargetParameters", this.values);
        })
      )
    } else if (this.data.targetContentPlan) {
      this.attachSubscriptions(
        this.parametersService.getTargetContentPlanParameters(this.data.company.id, this.data.targetContentPlan).subscribe(resp => {
          this.values = resp;
          console.log("getTargetParameters", this.values);
        })
      )
    } else {
      this.attachSubscriptions(
        this.parametersService.getTargetChannelParameters(this.data.company.id, {channel_id : this.data.channel.id}).subscribe(resp => {
          this.values = resp;
          console.log("getTargetParameters", this.values)
        })
      )
    }
  }

  showError(error) {
    this.layoutService.showSnackBar({name: error}, marker("You do not have permission to do this"), SnackBarItem)
  }

  onRemove(value, param, i) {
    if (this.data.auto) {
      param.activeValues.splice(i, 1)
      if (param.id != 0) {
        this.parameters[0].activeValues.splice(this.parameters[0].activeValues.indexOf(value) ,1)
      } else {
        let targetParam = this.parameters.find(x => x.id == value.parameter_id);
        targetParam.activeValues.splice(targetParam.activeValues.indexOf(value) ,1)
      }
    } else if (this.data.target) {
      this.attachSubscriptions(
        this.parametersService.deleteTargetParameter(value.id).subscribe(resp => {
          param.activeValues.splice(i, 1)
          if (param.id != 0) {
            this.parameters[0].activeValues.splice(this.parameters[0].activeValues.indexOf(value) ,1)
          } else {
            let targetParam = this.parameters.find(x => x.id == value.parameter_id);
            targetParam.activeValues.splice(targetParam.activeValues.indexOf(value) ,1)
          }
        },
        error => {
          this.showError(error)
        })
      )
    }  else if (this.data.targetContentPlan) {
      this.attachSubscriptions(
        this.parametersService.deleteTargetContnetPlan(value.id).subscribe(resp => {
          param.activeValues.splice(i, 1)
          if (param.id != 0) {
            this.parameters[0].activeValues.splice(this.parameters[0].activeValues.indexOf(value) ,1)
          } else {
            let targetParam = this.parameters.find(x => x.id == value.parameter_id);
            targetParam.activeValues.splice(targetParam.activeValues.indexOf(value) ,1)
          }
        },
        error => {
          this.showError(error)
        })
      )
    } else {
      this.attachSubscriptions(
        this.parametersService.deleteTargetChannelParameter(value.id).subscribe(resp => {
          param.activeValues.splice(i, 1)
          if (param.id != 0) {
            this.parameters[0].activeValues.splice(this.parameters[0].activeValues.indexOf(this.parameters[0].activeValues.find(b => b.id == value.id)) ,1)
          } else {
            let targetParam = this.parameters.find(x => x.id == value.parameter_id);
            targetParam.activeValues.splice(targetParam.activeValues.indexOf(value) ,1)
          }
        },
        error => {
          this.showError(error)
        })
      )
    }
    // }
  }

  log() {
    console.log("log parameters", this.parameters)
  }

  selected(e, param) {
    console.log("selected", e, param)
    if (this.data.is_all_cards_results || this.data.is_all_files_results) {
      if (e.option.value.type == 'add') {
        if (param.id == 0) {
          const dialogRef = this.dialog.open(SelectParameterComponent, {
            data: {
              parameters: this.parameters
            }
          });
          
          this.attachSubscriptions(
            dialogRef.afterClosed().subscribe(result => {
              console.log(result)
              if (!!result) {
                if (result.event == "select") {
                  this.attachSubscriptions(
                    this.parametersService.addApiParameterValue({company_id: this.data.company.id, parameter_id: result.data.id, value: e.option.value.value}).subscribe(
                      resp => {
                        result.data.hasValues = true;
                        result.data.activeValues.push(resp);
                        result.data.search.patchValue("")
                        if (document.getElementById(`param_input_${result.data.id}`)) {
                          (document.getElementById(`param_input_${result.data.id}`) as HTMLInputElement).value = ''
                        }
            
                        if (result.data.id != 0) {
                          this.parameters[0].activeValues.push(resp)
                          this.parameters[0].search.patchValue("")
                          if (document.getElementById(`param_input_${this.parameters[0].id}`)) {
                            (document.getElementById(`param_input_${this.parameters[0].id}`) as HTMLInputElement).value = ''
                          }
                        } else {
                          result.data.activeValues.push(resp)
                          result.data.search.patchValue("")
                          if (document.getElementById(`param_input_${result.data.id}`)) {
                            (document.getElementById(`param_input_${result.data.id}`) as HTMLInputElement).value = ''
                          }
                        }
                      },
                      error => {
                        this.showError(error)
                      }
                    )
                  )
                } else {
                  param.search.patchValue("")
                  if (document.getElementById(`param_input_${param.id}`)) {
                    (document.getElementById(`param_input_${param.id}`) as HTMLInputElement).value = ''
                  }
                }
              }
            })
          )
        } else {
          this.attachSubscriptions(
            this.parametersService.addApiParameterValue({company_id: this.data.company.id, parameter_id: param.id, value: e.option.value.value}).subscribe(
              resp => {
                param.activeValues.push(resp);
                param.search.patchValue("")
                if (document.getElementById(`param_input_${param.id}`)) {
                  (document.getElementById(`param_input_${param.id}`) as HTMLInputElement).value = ''
                }
    
                if (param.id != 0) {
                  this.parameters[0].activeValues.push(resp)
                  this.parameters[0].search.patchValue("")
                  if (document.getElementById(`param_input_${this.parameters[0].id}`)) {
                    (document.getElementById(`param_input_${this.parameters[0].id}`) as HTMLInputElement).value = ''
                  }
                } else {
                  let targetParam = this.parameters.find(x => x.id == e.option.value.parameter_id);
                  targetParam.activeValues.push(resp)
                  targetParam.search.patchValue("")
                  if (document.getElementById(`param_input_${targetParam.id}`)) {
                    (document.getElementById(`param_input_${targetParam.id}`) as HTMLInputElement).value = ''
                  }
                }
            
              },
              error => {
                this.showError(error)
              }
            )
          )
        }
      } else {
        param.activeValues.push(e.option.value);
        param.search.patchValue("")
        if (document.getElementById(`param_input_${param.id}`)) {
          (document.getElementById(`param_input_${param.id}`) as HTMLInputElement).value = ''
        }

        if (param.id != 0) {
          this.parameters[0].activeValues.push(e.option.value)
          this.parameters[0].search.patchValue("")
          if (document.getElementById(`param_input_${this.parameters[0].id}`)) {
            (document.getElementById(`param_input_${this.parameters[0].id}`) as HTMLInputElement).value = ''
          }
        } else {
          let targetParam = this.parameters.find(x => x.id == e.option.value.parameter_id);
          targetParam.activeValues.push(e.option.value)
          targetParam.search.patchValue("")
          if (document.getElementById(`param_input_${targetParam.id}`)) {
            (document.getElementById(`param_input_${targetParam.id}`) as HTMLInputElement).value = ''
          }
        }
      }
      return
    }

    if (!!this.data.auto) {
      if (e.option.value.type == 'add') {
        if (param.id == 0) {
          const dialogRef = this.dialog.open(SelectParameterComponent, {
            data: {
              parameters: this.parameters
            }
          });
          
          this.attachSubscriptions(
            dialogRef.afterClosed().subscribe(result => {
              console.log(result)
              if (!!result) {
                if (result.event == "select") {
                  this.attachSubscriptions(
                    this.parametersService.addApiParameterValue({company_id: this.data.company.id, parameter_id: result.data.id, value: e.option.value.value}).subscribe(
                      resp => {
                        result.data.hasValues = true;
                        result.data.activeValues.push(resp);
                        result.data.search.patchValue("")
                        if (document.getElementById(`param_input_${result.data.id}`)) {
                          (document.getElementById(`param_input_${result.data.id}`) as HTMLInputElement).value = ''
                        }
            
                        if (result.data.id != 0) {
                          this.parameters[0].activeValues.push(resp)
                          this.parameters[0].search.patchValue("")
                          if (document.getElementById(`param_input_${this.parameters[0].id}`)) {
                            (document.getElementById(`param_input_${this.parameters[0].id}`) as HTMLInputElement).value = ''
                          }
                        } else {
                          result.data.activeValues.push(resp)
                          result.data.search.patchValue("")
                          if (document.getElementById(`param_input_${result.data.id}`)) {
                            (document.getElementById(`param_input_${result.data.id}`) as HTMLInputElement).value = ''
                          }
                        }
                      },
                      error => {
                        this.showError(error)
                      }
                    )
                  )
                } else {
                  param.search.patchValue("")
                  if (document.getElementById(`param_input_${param.id}`)) {
                    (document.getElementById(`param_input_${param.id}`) as HTMLInputElement).value = ''
                  }
                }
              }
            })
          )
        } else {
          this.attachSubscriptions(
            this.parametersService.addApiParameterValue({company_id: this.data.company.id, parameter_id: param.id, value: e.option.value.value}).subscribe(
              resp => {
                param.activeValues.push(resp);
                param.search.patchValue("")
                if (document.getElementById(`param_input_${param.id}`)) {
                  (document.getElementById(`param_input_${param.id}`) as HTMLInputElement).value = ''
                }
    
                if (param.id != 0) {
                  this.parameters[0].activeValues.push(resp)
                  this.parameters[0].search.patchValue("")
                  if (document.getElementById(`param_input_${this.parameters[0].id}`)) {
                    (document.getElementById(`param_input_${this.parameters[0].id}`) as HTMLInputElement).value = ''
                  }
                } else {
                  let targetParam = this.parameters.find(x => x.id == e.option.value.parameter_id);
                  targetParam.activeValues.push(resp)
                  targetParam.search.patchValue("")
                  if (document.getElementById(`param_input_${targetParam.id}`)) {
                    (document.getElementById(`param_input_${targetParam.id}`) as HTMLInputElement).value = ''
                  }
                }
            
              },
              error => {
                this.showError(error)
              }
            )
          )
        }
      } else {
        param.activeValues.push(e.option.value);
        param.search.patchValue("")
        if (document.getElementById(`param_input_${param.id}`)) {
          (document.getElementById(`param_input_${param.id}`) as HTMLInputElement).value = ''
        }

        if (param.id != 0) {
          this.parameters[0].activeValues.push(e.option.value)
          this.parameters[0].search.patchValue("")
          if (document.getElementById(`param_input_${this.parameters[0].id}`)) {
            (document.getElementById(`param_input_${this.parameters[0].id}`) as HTMLInputElement).value = ''
          }
        } else {
          let targetParam = this.parameters.find(x => x.id == e.option.value.parameter_id);
          targetParam.activeValues.push(e.option.value)
          targetParam.search.patchValue("")
          if (document.getElementById(`param_input_${targetParam.id}`)) {
            (document.getElementById(`param_input_${targetParam.id}`) as HTMLInputElement).value = ''
          }
        }
      }
    } else if (!!this.data.selectedFiles) {
      
      if (e.option.value.type == 'add') {
        if (param.id == 0) {
          const dialogRef = this.dialog.open(SelectParameterComponent, {
            data: {
              parameters: this.parameters
            }
          });
          
          this.attachSubscriptions(
            dialogRef.afterClosed().subscribe(result => {
              console.log(result)
              if (!!result) {
                if (result.event == "select") {
                  this.attachSubscriptions(
                    this.parametersService.addApiParameterValue({company_id: this.data.company.id, parameter_id: result.data.id, value: e.option.value.value}).pipe(
                      switchMap(x => {
                        let postData = [];
                              
                        this.data.selectedFiles.forEach(file => {  
                          postData.push({
                            "path": `/api/parameter-value-to-task/`,
                            "query": {"expand": "parameter,parameterValue", "company_id": this.data.company.id},
                            "method": "POST",
                            "body": {
                              [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
                              company_id: file.company_id,
                              parameter_id: result.data.id,
                              parameter_value_id: x.id,
                              task_id: file.task_id,
                              task_operation_id: file.task_operation_id,
                              discussion_id: file.discussion_id && file.discussion ? file.discussion_id : 0,
                              file_id: file.id
                            }
                          })
                        });
                        return this.taskService.multiRequest(postData)
                      })
                    ).subscribe(
                      resp => {
                        if (resp.find(x => x.status == 201)) {
                          result.data.hasValues = true;
                          result.data.activeValues.push(resp.find(x => x.status == 201).body);
                          result.data.search.patchValue("")
                          if (document.getElementById(`param_input_${result.data.id}`)) {
                            (document.getElementById(`param_input_${result.data.id}`) as HTMLInputElement).value = ''
                          }
              
                          if (result.data.id != 0) {
                            this.parameters[0].activeValues.push(resp.find(x => x.status == 201).body)
                            this.parameters[0].search.patchValue("")
                            if (document.getElementById(`param_input_${this.parameters[0].id}`)) {
                              (document.getElementById(`param_input_${this.parameters[0].id}`) as HTMLInputElement).value = ''
                            }
                          } else {
                            result.data.activeValues.push(resp.find(x => x.status == 201).body)
                            result.data.search.patchValue("")
                            if (document.getElementById(`param_input_${result.data.id}`)) {
                              (document.getElementById(`param_input_${result.data.id}`) as HTMLInputElement).value = ''
                            }
                          }
                        } else if (resp.find(x => x.status == 422)) {
                          this.layoutService.showSnackBar({name: "All files already have this tag"}, '', SnackBarItem)
                        }
                      },
                      error => {
                        this.showError(error)
                      }
                    )
                  )
                } else {
                  param.search.patchValue("")
                  if (document.getElementById(`param_input_${param.id}`)) {
                    (document.getElementById(`param_input_${param.id}`) as HTMLInputElement).value = ''
                  }
                }
              }
            })
          )
        } else {
          this.attachSubscriptions(
            this.parametersService.addApiParameterValue({company_id: this.data.company.id, parameter_id: param.id, value: e.option.value.value}).pipe(
              switchMap(x => {
                let postData = [];
                      
                this.data.selectedFiles.forEach(file => {  
                  postData.push({
                    "path": `/api/parameter-value-to-task/`,
                    "query": {"expand": "parameter,parameterValue", "company_id": this.data.company.id},
                    "method": "POST",
                    "body": {
                      [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
                      company_id: file.company_id,
                      parameter_id: param.id != 0 ? param.id : x.parameter_id,
                      parameter_value_id: x.id,
                      task_id: file.task_id,
                      task_operation_id: file.task_operation_id,
                      discussion_id: file.discussion_id && file.discussion ? file.discussion_id : 0,
                      file_id: file.id
                    }
                  })
                });
                return this.taskService.multiRequest(postData)
              })
            ).subscribe(
              resp => {
                if (resp.find(x => x.status == 201)) {
                  param.activeValues.push(resp.find(x => x.status == 201).body);
                  param.search.patchValue("")
                  if (document.getElementById(`param_input_${param.id}`)) {
                    (document.getElementById(`param_input_${param.id}`) as HTMLInputElement).value = ''
                  }
      
                  if (param.id != 0) {
                    this.parameters[0].activeValues.push(resp.find(x => x.status == 201).body)
                    this.parameters[0].search.patchValue("")
                    if (document.getElementById(`param_input_${this.parameters[0].id}`)) {
                      (document.getElementById(`param_input_${this.parameters[0].id}`) as HTMLInputElement).value = ''
                    }
                  } else {
                    let targetParam = this.parameters.find(x => x.id == e.option.value.parameter_id);
                    targetParam.activeValues.push(resp.find(x => x.status == 201).body)
                    targetParam.search.patchValue("")
                    if (document.getElementById(`param_input_${targetParam.id}`)) {
                      (document.getElementById(`param_input_${targetParam.id}`) as HTMLInputElement).value = ''
                    }
                  }
                } else if (resp.find(x => x.status == 422)) {
                  this.layoutService.showSnackBar({name: "All files already have this tag"}, '', SnackBarItem)
                }
              },
              error => {
                this.showError(error)
              }
            )
          )
        }
      } else {
        let postData = [];
                    
        this.data.selectedFiles.forEach(file => {  
          postData.push({
            "path": `/api/parameter-value-to-task/`,
            "query": {"company_id": this.data.company.id, "expand": "parameter,parameterValue"},
            "method": "POST",
            "body": {
              [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
              company_id: file.company_id,
              parameter_id: param.id != 0 ? param.id : e.option.value.parameter_id,
              parameter_value_id: e.option.value.id,
              task_id: file.task_id,
              task_operation_id: file.task_operation_id,
              discussion_id: file.discussion_id && file.discussion ? file.discussion_id : 0,
              file_id: file.id
            }
          })
        });
        this.attachSubscriptions(
          this.taskService.multiRequest(postData).subscribe(
            resp => {
              console.log(resp);

              if (resp && resp.length) {
                resp.forEach(element => {
                  if ((element.status == 201 || element.status == 203) && this.data.selectedFiles.find(x => x.id == element.body.file_id)) {
                    if (this.data.selectedFiles.find(x => x.id == element.body.file_id).parameterValuesToTask) {
                      this.data.selectedFiles.find(x => x.id == element.body.file_id).parameterValuesToTask.push(element.body);
                    } else {
                      this.data.selectedFiles.find(x => x.id == element.body.file_id).parameterValuesToTask = [element.body];
                    }
                  }
                });
              }
              
              
              if (resp.find(x => x.status == 201)) {
                param.activeValues.push(resp.find(x => x.status == 201).body);
                param.search.patchValue("")
                if (document.getElementById(`param_input_${param.id}`)) {
                  (document.getElementById(`param_input_${param.id}`) as HTMLInputElement).value = ''
                }
    
                if (param.id != 0) {
                  this.parameters[0].activeValues.push(resp.find(x => x.status == 201).body)
                  this.parameters[0].search.patchValue("")
                  if (document.getElementById(`param_input_${this.parameters[0].id}`)) {
                    (document.getElementById(`param_input_${this.parameters[0].id}`) as HTMLInputElement).value = ''
                  }
                } else {
                  let targetParam = this.parameters.find(x => x.id == e.option.value.parameter_id);
                  targetParam.activeValues.push(resp.find(x => x.status == 201).body)
                  targetParam.search.patchValue("")
                  if (document.getElementById(`param_input_${targetParam.id}`)) {
                    (document.getElementById(`param_input_${targetParam.id}`) as HTMLInputElement).value = ''
                  }
                }
              } else if (resp.find(x => x.status == 422)) {
                this.layoutService.showSnackBar({name: "All files already have this tag"}, '', SnackBarItem)
              }
            },
            error => {
              this.showError(error)
            }
          )
        )
      }
    } else if (!!this.data.selectedJobs) {
      
      if (e.option.value.type == 'add') {
        if (param.id == 0) {
          const dialogRef = this.dialog.open(SelectParameterComponent, {
            data: {
              parameters: this.parameters
            }
          });
          
          this.attachSubscriptions(
            dialogRef.afterClosed().subscribe(result => {
              console.log(result)
              if (!!result) {
                if (result.event == "select") {
                  this.attachSubscriptions(
                    this.parametersService.addApiParameterValue({company_id: this.data.company.id, parameter_id: result.data.id, value: e.option.value.value}).pipe(
                      switchMap(x => {
                        let postData = [];
                              
                        this.data.selectedJobs.forEach(job => {  
                          postData.push({
                            "path": `/api/parameter-value-to-task/`,
                            "query": {"expand": "parameter,parameterValue", "company_id": this.data.company.id},
                            "method": "POST",
                            "body": {
                              [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
                              company_id: job.company_id,
                              parameter_id: result.data.id,
                              parameter_value_id: x.id,
                              task_id: job.task_id,
                              task_operation_id: job.id,
                            }
                          })
                        });
                        return this.taskService.multiRequest(postData)
                      })
                    ).subscribe(
                      resp => {
                        if (resp.find(x => x.status == 201)) {
                          result.data.hasValues = true;
                          result.data.activeValues.push(resp.find(x => x.status == 201).body);
                          result.data.search.patchValue("")
                          if (document.getElementById(`param_input_${result.data.id}`)) {
                            (document.getElementById(`param_input_${result.data.id}`) as HTMLInputElement).value = ''
                          }
              
                          if (result.data.id != 0) {
                            this.parameters[0].activeValues.push(resp.find(x => x.status == 201).body)
                            this.parameters[0].search.patchValue("")
                            if (document.getElementById(`param_input_${this.parameters[0].id}`)) {
                              (document.getElementById(`param_input_${this.parameters[0].id}`) as HTMLInputElement).value = ''
                            }
                          } else {
                            result.data.activeValues.push(resp.find(x => x.status == 201).body)
                            result.data.search.patchValue("")
                            if (document.getElementById(`param_input_${result.data.id}`)) {
                              (document.getElementById(`param_input_${result.data.id}`) as HTMLInputElement).value = ''
                            }
                          }
                        } else if (resp.find(x => x.status == 422)) {
                          this.layoutService.showSnackBar({name: "All files already have this tag"}, '', SnackBarItem)
                        }
                      },
                      error => {
                        this.showError(error)
                      }
                    )
                  )
                } else {
                  param.search.patchValue("")
                  if (document.getElementById(`param_input_${param.id}`)) {
                    (document.getElementById(`param_input_${param.id}`) as HTMLInputElement).value = ''
                  }
                }
              }
            })
          )
        } else {
          this.attachSubscriptions(
            this.parametersService.addApiParameterValue({company_id: this.data.company.id, parameter_id: param.id, value: e.option.value.value}).pipe(
              switchMap(x => {
                let postData = [];
                      
                this.data.selectedJobs.forEach(job => {  
                  postData.push({
                    "path": `/api/parameter-value-to-task/`,
                    "query": {"expand": "parameter,parameterValue", "company_id": this.data.company.id},
                    "method": "POST",
                    "body": {
                      [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
                      company_id: job.company_id,
                      parameter_id: param.id != 0 ? param.id : x.parameter_id,
                      parameter_value_id: x.id,
                      task_id: job.task_id,
                      task_operation_id: job.id,
                    }
                  })
                });
                return this.taskService.multiRequest(postData)
              })
            ).subscribe(
              resp => {
                if (resp.find(x => x.status == 201)) {
                  param.activeValues.push(resp.find(x => x.status == 201).body);
                  param.search.patchValue("")
                  if (document.getElementById(`param_input_${param.id}`)) {
                    (document.getElementById(`param_input_${param.id}`) as HTMLInputElement).value = ''
                  }
      
                  if (param.id != 0) {
                    this.parameters[0].activeValues.push(resp.find(x => x.status == 201).body)
                    this.parameters[0].search.patchValue("")
                    if (document.getElementById(`param_input_${this.parameters[0].id}`)) {
                      (document.getElementById(`param_input_${this.parameters[0].id}`) as HTMLInputElement).value = ''
                    }
                  } else {
                    let targetParam = this.parameters.find(x => x.id == e.option.value.parameter_id);
                    targetParam.activeValues.push(resp.find(x => x.status == 201).body)
                    targetParam.search.patchValue("")
                    if (document.getElementById(`param_input_${targetParam.id}`)) {
                      (document.getElementById(`param_input_${targetParam.id}`) as HTMLInputElement).value = ''
                    }
                  }
                } else if (resp.find(x => x.status == 422)) {
                  this.layoutService.showSnackBar({name: "All files already have this tag"}, '', SnackBarItem)
                }
              },
              error => {
                this.showError(error)
              }
            )
          )
        }
      } else {
        let postData = [];
                    
        this.data.selectedJobs.forEach(job => {  
          postData.push({
            "path": `/api/parameter-value-to-task/`,
            "query": {"company_id": this.data.company.id, "expand": "parameter,parameterValue"},
            "method": "POST",
            "body": {
              [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
              company_id: job.company_id,
              parameter_id: param.id != 0 ? param.id : e.option.value.parameter_id,
              parameter_value_id: e.option.value.id,
              task_id: job.task_id,
              task_operation_id: job.id
            }
          })
        });
        this.attachSubscriptions(
          this.taskService.multiRequest(postData).subscribe(
            resp => {
              console.log(resp);

              if (resp && resp.length) {
                resp.forEach(element => {
                  if ((element.status == 201 || element.status == 203) && this.data.selectedJobs.find(x => x.id == element.body.task_operation_id)) {
                    if (this.data.selectedJobs.find(x => x.id == element.body.task_operation_id).parameterValuesToTask) {
                      this.data.selectedJobs.find(x => x.id == element.body.task_operation_id).parameterValuesToTask.push(element.body);
                    } else {
                      this.data.selectedJobs.find(x => x.id == element.body.task_operation_id).parameterValuesToTask = [element.body];
                    }
                  }
                });
              }
              
              
              if (resp.find(x => x.status == 201)) {
                param.activeValues.push(resp.find(x => x.status == 201).body);
                param.search.patchValue("")
                if (document.getElementById(`param_input_${param.id}`)) {
                  (document.getElementById(`param_input_${param.id}`) as HTMLInputElement).value = ''
                }
    
                if (param.id != 0) {
                  this.parameters[0].activeValues.push(resp.find(x => x.status == 201).body)
                  this.parameters[0].search.patchValue("")
                  if (document.getElementById(`param_input_${this.parameters[0].id}`)) {
                    (document.getElementById(`param_input_${this.parameters[0].id}`) as HTMLInputElement).value = ''
                  }
                } else {
                  let targetParam = this.parameters.find(x => x.id == e.option.value.parameter_id);
                  targetParam.activeValues.push(resp.find(x => x.status == 201).body)
                  targetParam.search.patchValue("")
                  if (document.getElementById(`param_input_${targetParam.id}`)) {
                    (document.getElementById(`param_input_${targetParam.id}`) as HTMLInputElement).value = ''
                  }
                }
              } else if (resp.find(x => x.status == 422)) {
                this.layoutService.showSnackBar({name: "All files already have this tag"}, '', SnackBarItem)
              }
            },
            error => {
              this.showError(error)
            }
          )
        )
      }
    } else if (!!this.data.selectedCards) {
      
      if (e.option.value.type == 'add') {
        if (param.id == 0) {
          const dialogRef = this.dialog.open(SelectParameterComponent, {
            data: {
              parameters: this.parameters
            }
          });
          
          this.attachSubscriptions(
            dialogRef.afterClosed().subscribe(result => {
              console.log(result)
              if (!!result) {
                if (result.event == "select") {
                  this.attachSubscriptions(
                    this.parametersService.addApiParameterValue({company_id: this.data.company.id, parameter_id: result.data.id, value: e.option.value.value}).pipe(
                      switchMap(x => {
                        let postData = [];
                              
                        this.data.selectedCards.forEach(card => {  
                          postData.push({
                            "path": `/api/parameter-value-to-task/`,
                            "query": {"expand": "parameter,parameterValue", "company_id": this.data.company.id},
                            "method": "POST",
                            "body": {
                              [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
                              company_id: card.company_id,
                              parameter_id: result.data.id,
                              parameter_value_id: x.id,
                              task_id: card.id,
                            }
                          })
                        });
                        return this.taskService.multiRequest(postData)
                      })
                    ).subscribe(
                      resp => {
                        if (resp.find(x => x.status == 201)) {
                          result.data.hasValues = true;
                          result.data.activeValues.push(resp.find(x => x.status == 201).body);
                          result.data.search.patchValue("")
                          if (document.getElementById(`param_input_${result.data.id}`)) {
                            (document.getElementById(`param_input_${result.data.id}`) as HTMLInputElement).value = ''
                          }
              
                          if (result.data.id != 0) {
                            this.parameters[0].activeValues.push(resp.find(x => x.status == 201).body)
                            this.parameters[0].search.patchValue("")
                            if (document.getElementById(`param_input_${this.parameters[0].id}`)) {
                              (document.getElementById(`param_input_${this.parameters[0].id}`) as HTMLInputElement).value = ''
                            }
                          } else {
                            result.data.activeValues.push(resp.find(x => x.status == 201).body)
                            result.data.search.patchValue("")
                            if (document.getElementById(`param_input_${result.data.id}`)) {
                              (document.getElementById(`param_input_${result.data.id}`) as HTMLInputElement).value = ''
                            }
                          }
                        } else if (resp.find(x => x.status == 422)) {
                          this.layoutService.showSnackBar({name: "All files already have this tag"}, '', SnackBarItem)
                        }
                      },
                      error => {
                        this.showError(error)
                      }
                    )
                  )
                } else {
                  param.search.patchValue("")
                  if (document.getElementById(`param_input_${param.id}`)) {
                    (document.getElementById(`param_input_${param.id}`) as HTMLInputElement).value = ''
                  }
                }
              }
            })
          )
        } else {
          this.attachSubscriptions(
            this.parametersService.addApiParameterValue({company_id: this.data.company.id, parameter_id: param.id, value: e.option.value.value}).pipe(
              switchMap(x => {
                let postData = [];
                      
                this.data.selectedCards.forEach(card => {  
                  postData.push({
                    "path": `/api/parameter-value-to-task/`,
                    "query": {"expand": "parameter,parameterValue", "company_id": this.data.company.id},
                    "method": "POST",
                    "body": {
                      [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
                      company_id: card.company_id,
                      parameter_id: param.id != 0 ? param.id : x.parameter_id,
                      parameter_value_id: x.id,
                      task_id: card.id
                    }
                  })
                });
                return this.taskService.multiRequest(postData)
              })
            ).subscribe(
              resp => {
                if (resp.find(x => x.status == 201)) {
                  param.activeValues.push(resp.find(x => x.status == 201).body);
                  param.search.patchValue("")
                  if (document.getElementById(`param_input_${param.id}`)) {
                    (document.getElementById(`param_input_${param.id}`) as HTMLInputElement).value = ''
                  }
      
                  if (param.id != 0) {
                    this.parameters[0].activeValues.push(resp.find(x => x.status == 201).body)
                    this.parameters[0].search.patchValue("")
                    if (document.getElementById(`param_input_${this.parameters[0].id}`)) {
                      (document.getElementById(`param_input_${this.parameters[0].id}`) as HTMLInputElement).value = ''
                    }
                  } else {
                    let targetParam = this.parameters.find(x => x.id == e.option.value.parameter_id);
                    targetParam.activeValues.push(resp.find(x => x.status == 201).body)
                    targetParam.search.patchValue("")
                    if (document.getElementById(`param_input_${targetParam.id}`)) {
                      (document.getElementById(`param_input_${targetParam.id}`) as HTMLInputElement).value = ''
                    }
                  }
                } else if (resp.find(x => x.status == 422)) {
                  this.layoutService.showSnackBar({name: "All files already have this tag"}, '', SnackBarItem)
                }
              },
              error => {
                this.showError(error)
              }
            )
          )
        }
      } else {
        let postData = [];
                    
        this.data.selectedCards.forEach(card => {  
          postData.push({
            "path": `/api/parameter-value-to-task/`,
            "query": {"company_id": this.data.company.id, "expand": "parameter,parameterValue"},
            "method": "POST",
            "body": {
              [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
              company_id: card.company_id,
              parameter_id: param.id != 0 ? param.id : e.option.value.parameter_id,
              parameter_value_id: e.option.value.id,
              task_id: card.id,
            }
          })
        });
        this.attachSubscriptions(
          this.taskService.multiRequest(postData).subscribe(
            resp => {
              console.log(resp);

              if (resp && resp.length) {
                resp.forEach(element => {
                  if ((element.status == 201 || element.status == 203) && this.data.selectedCards.find(x => x.id == element.body.task_id)) {
                    if (this.data.selectedCards.find(x => x.id == element.body.task_id).parameterValuesToTask) {
                      this.data.selectedCards.find(x => x.id == element.body.task_id).parameterValuesToTask.push(element.body);
                    } else {
                      this.data.selectedCards.find(x => x.id == element.body.task_id).parameterValuesToTask = [element.body];
                    }
                  }
                });
              }
              
              
              if (resp.find(x => x.status == 201)) {
                param.activeValues.push(resp.find(x => x.status == 201).body);
                param.search.patchValue("")
                if (document.getElementById(`param_input_${param.id}`)) {
                  (document.getElementById(`param_input_${param.id}`) as HTMLInputElement).value = ''
                }
    
                if (param.id != 0) {
                  this.parameters[0].activeValues.push(resp.find(x => x.status == 201).body)
                  this.parameters[0].search.patchValue("")
                  if (document.getElementById(`param_input_${this.parameters[0].id}`)) {
                    (document.getElementById(`param_input_${this.parameters[0].id}`) as HTMLInputElement).value = ''
                  }
                } else {
                  let targetParam = this.parameters.find(x => x.id == e.option.value.parameter_id);
                  targetParam.activeValues.push(resp.find(x => x.status == 201).body)
                  targetParam.search.patchValue("")
                  if (document.getElementById(`param_input_${targetParam.id}`)) {
                    (document.getElementById(`param_input_${targetParam.id}`) as HTMLInputElement).value = ''
                  }
                }
              } else if (resp.find(x => x.status == 422)) {
                this.layoutService.showSnackBar({name: "All files already have this tag"}, '', SnackBarItem)
              }
            },
            error => {
              this.showError(error)
            }
          )
        )
      }
    } else if (this.data.targetContentPlan) {
      if (e.option.value.type == 'add') {
        if (param.id == 0) {
          const dialogRef = this.dialog.open(SelectParameterComponent, {
            data: {
              parameters: this.parameters
            }
          });
          
          this.attachSubscriptions(
            dialogRef.afterClosed().subscribe(result => {
              console.log(result)
              if (!!result) {
                if (result.event == "select") {
                  this.attachSubscriptions(
                    this.parametersService.addApiParameterValue({company_id: this.data.company.id, parameter_id: result.data.id, value: e.option.value.value}).pipe(
                      switchMap(x => {
                        return this.parametersService.addTargetContentPlanParameter(Object.assign({
                          company_id: this.data.company.id,
                          parameter_id: result.data.id,
                          parameter_value_id: x.id,
                        }, this.data.targetContentPlan))
                      })
                    ).subscribe(
                      resp => {
                        result.data.hasValues = true;
                        console.log("add and select", resp)
                        result.data.activeValues.push(resp);
                        result.data.search.patchValue("")
                        if (document.getElementById(`param_input_${param.id}`)) {
                          (document.getElementById(`param_input_${param.id}`) as HTMLInputElement).value = ''
                        }
            
                        if (result.data.id != 0) {
                          this.parameters[0].activeValues.push(resp)
                          this.parameters[0].search.patchValue("")
                          if (document.getElementById(`param_input_${this.parameters[0].id}`)) {
                            (document.getElementById(`param_input_${this.parameters[0].id}`) as HTMLInputElement).value = ''
                          }
                        } else {
                          result.data.activeValues.push(resp)
                          result.data.search.patchValue("")
                          if (document.getElementById(`param_input_${result.data.id}`)) {
                            (document.getElementById(`param_input_${result.data.id}`) as HTMLInputElement).value = ''
                          }
                        }
                      },
                      error => {
                        this.showError(error)
                      }
                    )
                  )
                } else {
                  param.search.patchValue("")
                  if (document.getElementById(`param_input_${param.id}`)) {
                    (document.getElementById(`param_input_${param.id}`) as HTMLInputElement).value = ''
                  }
                }
              }
            })
          )
        } else {
          this.attachSubscriptions(
            this.parametersService.addApiParameterValue({company_id: this.data.company.id, parameter_id: param.id, value: e.option.value.value}).pipe(
              switchMap(x => {
                return this.parametersService.addTargetContentPlanParameter(Object.assign({
                  company_id: this.data.company.id,
                  parameter_id: param.id != 0 ? param.id : x.parameter_id,
                  parameter_value_id: x.id,
                }, this.data.targetContentPlan))
              })
            ).subscribe(
              resp => {
                console.log("add and select", resp)
                param.hasValues = true;
                param.activeValues.push(resp);
                param.search.patchValue("")
                if (document.getElementById(`param_input_${param.id}`)) {
                  (document.getElementById(`param_input_${param.id}`) as HTMLInputElement).value = ''
                }
    
                if (param.id != 0) {
                  this.parameters[0].activeValues.push(resp)
                  this.parameters[0].search.patchValue("")
                  if (document.getElementById(`param_input_${this.parameters[0].id}`)) {
                    (document.getElementById(`param_input_${this.parameters[0].id}`) as HTMLInputElement).value = ''
                  }
                } else {
                  let targetParam = this.parameters.find(x => x.id == e.option.value.parameter_id);
                  targetParam.activeValues.push(resp)
                  targetParam.search.patchValue("")
                  if (document.getElementById(`param_input_${targetParam.id}`)) {
                    (document.getElementById(`param_input_${targetParam.id}`) as HTMLInputElement).value = ''
                  }
                }
              },
              error => {
                this.showError(error)
              }
            )
          )
        }
      } else {
        this.attachSubscriptions(
          this.parametersService.addTargetContentPlanParameter(Object.assign({
            company_id: this.data.company.id,
            parameter_id: param.id != 0 ? param.id : e.option.value.parameter_id,
            parameter_value_id: e.option.value.id,
          }, this.data.targetContentPlan)
          ).subscribe(
            resp => {
              param.activeValues.push(resp);
              param.search.patchValue("")
              if (document.getElementById(`param_input_${param.id}`)) {
                (document.getElementById(`param_input_${param.id}`) as HTMLInputElement).value = ''
              }
              if (param.id != 0) {
                this.parameters[0].activeValues.push(resp)
                this.parameters[0].search.patchValue("")
                if (document.getElementById(`param_input_${this.parameters[0].id}`)) {
                  (document.getElementById(`param_input_${this.parameters[0].id}`) as HTMLInputElement).value = ''
                }
              } else {
                let targetParam = this.parameters.find(x => x.id == e.option.value.parameter_id);
                targetParam.activeValues.push(resp)
                targetParam.search.patchValue("")
                if (document.getElementById(`param_input_${targetParam.id}`)) {
                  (document.getElementById(`param_input_${targetParam.id}`) as HTMLInputElement).value = ''
                }
              }
            },
            error => {
              this.showError(error)
            }
          )
        )
      }
    } else {
      if (this.data.target) {
        if (e.option.value.type == 'add') {

          if (param.id == 0) {
            const dialogRef = this.dialog.open(SelectParameterComponent, {
              data: {
                parameters: this.parameters
              }
            });
            
            this.attachSubscriptions(
              dialogRef.afterClosed().subscribe(result => {
                console.log(result)
                if (!!result) {
                  if (result.event == "select") {
                    this.attachSubscriptions(
                      this.parametersService.addApiParameterValue({company_id: this.data.company.id, parameter_id: result.data.id, value: e.option.value.value}).pipe(
                        switchMap(x => {
                          return this.parametersService.addTargetParameter(Object.assign({
                            company_id: this.data.company.id,
                            parameter_id: result.data.id,
                            parameter_value_id: x.id,
                          }, this.data.target), this.data.company.id)
                        })
                      ).subscribe(
                        resp => {
                          result.data.hasValues = true;
                          console.log("add and select", resp)
                          result.data.activeValues.push(resp);
                          result.data.search.patchValue("")
                          if (document.getElementById(`param_input_${param.id}`)) {
                            (document.getElementById(`param_input_${param.id}`) as HTMLInputElement).value = ''
                          }
              
                          if (result.data.id != 0) {
                            this.parameters[0].activeValues.push(resp)
                            this.parameters[0].search.patchValue("")
                            if (document.getElementById(`param_input_${this.parameters[0].id}`)) {
                              (document.getElementById(`param_input_${this.parameters[0].id}`) as HTMLInputElement).value = ''
                            }
                          } else {
                            result.data.activeValues.push(resp)
                            result.data.search.patchValue("")
                            if (document.getElementById(`param_input_${result.data.id}`)) {
                              (document.getElementById(`param_input_${result.data.id}`) as HTMLInputElement).value = ''
                            }
                          }
                        },
                        error => {
                          this.showError(error)
                        }
                      )
                    )
                  } else {
                    param.search.patchValue("")
                    if (document.getElementById(`param_input_${param.id}`)) {
                      (document.getElementById(`param_input_${param.id}`) as HTMLInputElement).value = ''
                    }
                  }
                }
              })
            )
          } else {
            this.attachSubscriptions(
              this.parametersService.addApiParameterValue({company_id: this.data.company.id, parameter_id: param.id, value: e.option.value.value}).pipe(
                switchMap(x => {
                  return this.parametersService.addTargetParameter(Object.assign({
                    company_id: this.data.company.id,
                    parameter_id: param.id != 0 ? param.id : x.parameter_id,
                    parameter_value_id: x.id,
                  },this.data.target), this.data.company.id)
                })
              ).subscribe(
                resp => {
                  console.log("add and select", resp)
                  param.activeValues.push(resp);
                  param.search.patchValue("")
                  if (document.getElementById(`param_input_${param.id}`)) {
                    (document.getElementById(`param_input_${param.id}`) as HTMLInputElement).value = ''
                  }
      
                  if (param.id != 0) {
                    this.parameters[0].activeValues.push(resp)
                    this.parameters[0].search.patchValue("")
                    if (document.getElementById(`param_input_${this.parameters[0].id}`)) {
                      (document.getElementById(`param_input_${this.parameters[0].id}`) as HTMLInputElement).value = ''
                    }
                  } else {
                    let targetParam = this.parameters.find(x => x.id == e.option.value.parameter_id);
                    targetParam.activeValues.push(resp)
                    targetParam.search.patchValue("")
                    if (document.getElementById(`param_input_${targetParam.id}`)) {
                      (document.getElementById(`param_input_${targetParam.id}`) as HTMLInputElement).value = ''
                    }
                  }
                },
                error => {
                  this.showError(error)
                }
              )
            )
          }
        } else {
          let addData;
          if (this.data.target && this.data.target.partner_company_id) {
            let subData = {...this.data.target}
            delete subData.partner_company_id
            addData = Object.assign({
              company_id: this.data.target.partner_company_id,
              parameter_id: param.id != 0 ? param.id : e.option.value.parameter_id,
              parameter_value_id: e.option.value.id,
            }, subData)
          } else {
            addData = Object.assign({
              company_id: this.data.company.id,
              parameter_id: param.id != 0 ? param.id : e.option.value.parameter_id,
              parameter_value_id: e.option.value.id,
            }, this.data.target)
          }
          this.attachSubscriptions(
            this.parametersService.addTargetParameter(addData, this.data.company.id)
            .subscribe(
              resp => {
                param.activeValues.push(resp);
                param.search.patchValue("")
                if (document.getElementById(`param_input_${param.id}`)) {
                  (document.getElementById(`param_input_${param.id}`) as HTMLInputElement).value = ''
                }
    
                if (param.id != 0) {
                  this.parameters[0].activeValues.push(resp)
                  this.parameters[0].search.patchValue("")
                  if (document.getElementById(`param_input_${this.parameters[0].id}`)) {
                    (document.getElementById(`param_input_${this.parameters[0].id}`) as HTMLInputElement).value = ''
                  }
                } else {
                  let targetParam = this.parameters.find(x => x.id == e.option.value.parameter_id);
                  targetParam.activeValues.push(resp)
                  targetParam.search.patchValue("")
                  if (document.getElementById(`param_input_${targetParam.id}`)) {
                    (document.getElementById(`param_input_${targetParam.id}`) as HTMLInputElement).value = ''
                  }
                }
              },
              error => {
                this.showError(error)
              }
            )
          )
        }
      } else {
        if (e.option.value.type == 'add') {
          if (param.id == 0) {
            const dialogRef = this.dialog.open(SelectParameterComponent, {
              data: {
                parameters: this.parameters
              }
            });
            
            this.attachSubscriptions(
              dialogRef.afterClosed().subscribe(result => {
                console.log(result)
                if (!!result) {
                  if (result.event == "select") {
                    this.attachSubscriptions(
                      this.parametersService.addApiParameterValue({company_id: this.data.company.id, parameter_id: result.data.id, value: e.option.value.value}).pipe(
                        switchMap(x => {
                          return this.parametersService.addTargetChannelParameter({
                            company_id: this.data.company.id,
                            parameter_id: result.data.id,
                            parameter_value_id: x.id,
                            channel_id: this.data.channel.id
                          })
                        })
                      ).subscribe(
                        resp => {
                          result.data.hasValues = true;
                          console.log("add and select", resp)
                          result.data.activeValues.push(resp);
                          result.data.search.patchValue("")
                          if (document.getElementById(`param_input_${param.id}`)) {
                            (document.getElementById(`param_input_${param.id}`) as HTMLInputElement).value = ''
                          }
              
                          if (result.data.id != 0) {
                            this.parameters[0].activeValues.push(resp)
                            this.parameters[0].search.patchValue("")
                            if (document.getElementById(`param_input_${this.parameters[0].id}`)) {
                              (document.getElementById(`param_input_${this.parameters[0].id}`) as HTMLInputElement).value = ''
                            }
                          } else {
                            result.data.activeValues.push(resp)
                            result.data.search.patchValue("")
                            if (document.getElementById(`param_input_${result.data.id}`)) {
                              (document.getElementById(`param_input_${result.data.id}`) as HTMLInputElement).value = ''
                            }
                          }
                        },
                        error => {
                          this.showError(error)
                        }
                      )
                    )
                  } else {
                    param.search.patchValue("")
                    if (document.getElementById(`param_input_${param.id}`)) {
                      (document.getElementById(`param_input_${param.id}`) as HTMLInputElement).value = ''
                    }
                  }
                }
              })
            )
          } else {
            this.attachSubscriptions(
              this.parametersService.addApiParameterValue({company_id: this.data.company.id, parameter_id: param.id, value: e.option.value.value}).pipe(
                switchMap(x => {
                  return this.parametersService.addTargetChannelParameter({
                    company_id: this.data.company.id,
                    parameter_id: param.id != 0 ? param.id : x.parameter_id,
                    parameter_value_id: x.id,
                    channel_id: this.data.channel.id
                  })
                })
              ).subscribe(
                resp => {
                  console.log("add and select", resp)
                  param.hasValues = true;
                  param.activeValues.push(resp);
                  param.search.patchValue("")
                  if (document.getElementById(`param_input_${param.id}`)) {
                    (document.getElementById(`param_input_${param.id}`) as HTMLInputElement).value = ''
                  }
      
                  if (param.id != 0) {
                    this.parameters[0].activeValues.push(resp)
                    this.parameters[0].search.patchValue("")
                    if (document.getElementById(`param_input_${this.parameters[0].id}`)) {
                      (document.getElementById(`param_input_${this.parameters[0].id}`) as HTMLInputElement).value = ''
                    }
                  } else {
                    let targetParam = this.parameters.find(x => x.id == e.option.value.parameter_id);
                    targetParam.activeValues.push(resp)
                    targetParam.search.patchValue("")
                    if (document.getElementById(`param_input_${targetParam.id}`)) {
                      (document.getElementById(`param_input_${targetParam.id}`) as HTMLInputElement).value = ''
                    }
                  }
                },
                error => {
                  this.showError(error)
                }
              )
            )
          }
        } else {
          this.attachSubscriptions(
            this.parametersService.addTargetChannelParameter({
              company_id: this.data.company.id,
              parameter_id: param.id != 0 ? param.id : e.option.value.parameter_id,
              parameter_value_id: e.option.value.id,
              channel_id: this.data.channel.id
            }).subscribe(
              resp => {
                param.activeValues.push(resp);
                param.search.patchValue("")
                if (document.getElementById(`param_input_${param.id}`)) {
                  (document.getElementById(`param_input_${param.id}`) as HTMLInputElement).value = ''
                }
                if (param.id != 0) {
                  this.parameters[0].activeValues.push(resp)
                  this.parameters[0].search.patchValue("")
                  if (document.getElementById(`param_input_${this.parameters[0].id}`)) {
                    (document.getElementById(`param_input_${this.parameters[0].id}`) as HTMLInputElement).value = ''
                  }
                } else {
                  let targetParam = this.parameters.find(x => x.id == e.option.value.parameter_id);
                  targetParam.activeValues.push(resp)
                  targetParam.search.patchValue("")
                  if (document.getElementById(`param_input_${targetParam.id}`)) {
                    (document.getElementById(`param_input_${targetParam.id}`) as HTMLInputElement).value = ''
                  }
                }
              },
              error => {
                this.showError(error)
              }
            )
          )
        }
      }
    }
  }
      
  addOnBlur(e) {
    console.log("addOnBlur", e)
  }

  tokenEnd(e, param) {
    console.log("tokenEnd ADD", e)
    // const value = (e.value || '').trim();

    // // Add our fruit
    // if (value) {
    //   param.activeValues.push({value: value});
    // }

    // // Clear the input value
    // e.chipInput!.clear();

    // param.search.patchValue('');
  }
  
  private removeFirst<T>(array: T[], toRemove: T): void {
    const index = array.indexOf(toRemove);
    if (index !== -1) {
      array.splice(index, 1);
    }
  }
    
  // getAllApiParameterValues() {
  //   this.attachSubscriptions(
  //     this.parametersService.getAllApiParameterValues(this.data.company.id).subscribe(resp => {
  //       this.allValues = resp
  //       console.log("getAllApiParameterValues", this.allValues)
  //     })
  //   )
  // }


  onSubmitFiles() {
    if (!this.parameters.length || !this.parameters[0].activeValues.length) {
      this.layoutService.showSnackBar({name: ''}, marker("You need to select one or more tag(s)"), SnackBarItem)
      return
    }

    this.isSubmit = true;
    this.dialogRef.close({event: "update", data: this.parameters[0].activeValues});
  }

  onSubmit() {
    if (!this.parameters.length || !this.parameters[0].activeValues.length) {
      this.layoutService.showSnackBar({name: ''}, marker("You need to select one or more tag(s)"), SnackBarItem)
      return
    }

    this.isSubmit = true;

    let sbmtData = [];
    let partsSbmtData = [];

    this.cards.forEach(task => {
      this.parameters[0].activeValues.forEach(tag => {
        sbmtData.push({
          "path": `/api/parameter-value-to-task/`,
          "query": {"company_id": this.data.company.id, "expand": "parameter,parameterValue"},
          "method": "POST",
          "body": {
            [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
            company_id: task.company_id,
            parameter_id: tag.parameter_id,
            parameter_value_id: tag.id,
            is_primary: !!tag.is_primary ? 1 : 0,
            task_id: task.id
          }
        })
      });
    });

    for (let i = 0; i < Math.ceil(sbmtData.length/10); i++) {
      partsSbmtData.push(sbmtData.slice(i*10, 10 + i*10))
    }

    let count = 0;
    concat(...partsSbmtData.map((param) => this.taskService.multiRequest(param))).pipe(
      tap(el => {
        this.ls.requests$.next({
          value: Math.round((100 / partsSbmtData.length) * (count+1)),
          target: "Adding Tags"
        })
        if (count == 0) {
          this.isSubmit = false;
          this.dialogRef.close({event: "update", data: null});
        }
        count++;
      })
    ).subscribe(res => {
      console.log(res);
    })

  }
  
  getValueById(id) {
    if (!this.allValues || this.allValues.length == 0) {
      return null
    }
    return this.allValues.find(x => x.id == id)
  }

  save() {
    this.dialogRef.close({event: "save", data: this.parameters})
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  ngOnDestroy(): void {
    if (this.paramDataSub) {
      this.paramDataSub.unsubscribe()
    }
    if (this.eltsSub) {
      this.eltsSub.unsubscribe()
    }
    this.clearSubscriptions()
  }

}
