<mat-dialog-title class="dialog_title">
    <span>
        {{ "Editing a job" | translate }}2
    </span>
</mat-dialog-title>
<mat-dialog-content class="mat-typography" dirEnter (dirSubmit)="!form.valid ? null : onSubmit()">
    <div class="container form_wrapper" *ngIf="form">
        <form [formGroup]="form" class="form_with_pads form_with_valids">
            <div class="white_block">
                <div class="white_block_title">
                    {{ "Job identification" | translate }}
                </div>

                <mat-form-field appearance="standard" class="full_width_form">
                    <mat-label>{{ 'Title' | translate }}</mat-label>
                    <input [readonly]="data.company.id != data.work.company_id" formControlName="name" matInput placeholder="{{ 'Title' | translate }}">
                </mat-form-field>

                <mat-form-field appearance="standard" class="full_width_form" *ngIf="data.company.id == data.work.company_id">
                    <mat-label>{{ 'My notes on this job' | translate }}</mat-label>
                    <textarea formControlName="employee_comment" matInput placeholder="{{ 'My notes on this job' | translate }}"
                    cdkTextareaAutosize
                    #autosize="cdkTextareaAutosize"
                    cdkAutosizeMinRows="1"
                    cdkAutosizeMaxRows="5"></textarea>
                </mat-form-field>
                
                <div class="small_chips" *ngIf="operationStatuses?.length > 0">
                    <ng-container *ngIf="!form.get('status_id').valid && submited">
                        <div class="req_field"></div>
                        <div class="req_label">
                            <div class="angle"></div>
                            <span>
                                {{ "You need to choose a value" | translate }}
                            </span>
                        </div>
                    </ng-container>
                    <label>{{ "Status" | translate }}: </label>
                    <mat-chip-list [multiple]="false" selectable formControlName="status_id">
                        <ng-container *ngFor="let status of operationStatuses;">
                            <mat-chip class="small_chip" (click)="selectStatus(status)" [value]="status?.id" [ngClass]="{
                                'first': status.id == '1',
                                'second': status.id == '2',
                                'third': status.id == '3',
                                'fours': status.id == '4',
                                'fives': status.id == '5',
                                'sixs': status.id == '6',
                                'nine': status.id == '97',
                                'seven': status.id == '98',
                                'eight': status.id == '99'}">
                                {{ status.name | translate }}
                            </mat-chip>
                        </ng-container>
                        
                    </mat-chip-list>
                </div>
            </div>

            <div class="white_block">
                <div class="white_block_title">
                    {{ "Job identification" | translate }}
                </div>
                
                <mat-form-field appearance="standard" class="full_width_form">
                    <mat-label>{{ "Completed at" | translate }}</mat-label>
                    <input matInput [matDatepicker]="dp" formControlName="completed_at">
                    <mat-hint *ngIf="!form.get('completed_at').value && form.get('status_id').value == 3" style="color: red;">{{ "When submitting a work to this status, you must indicate the completion date" | translate }}</mat-hint>
                    <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
                    <mat-datepicker #dp></mat-datepicker>
                </mat-form-field>

                <div class="date_and_time_label">{{ "Deadline" | translate }}</div>
                <div class="date_and_time_c_w" formGroupName="date">
                    <mat-form-field appearance="standard" class="date_picker_cst">
                        <div class="prefix" matPrefix style="margin-right: 10px;">{{ "Date" | translate }}</div>
                        <input formControlName="day" matInput [matDatepicker]="pickerDay" placeholder="MM / DD/ YYYY">
                        <mat-datepicker-toggle matSuffix [for]="pickerDay"></mat-datepicker-toggle>
                        <mat-datepicker #pickerDay></mat-datepicker>
                    </mat-form-field>
                    
                    <div class="ngx-timepicker-field-example">
                        <div class="prefix">{{ "Time" | translate }}</div>
                        <ngx-timepicker-field [format]="24" [defaultTime]="form.get('date').get('time').value" formControlName="time"></ngx-timepicker-field>
                    </div>
                </div>
        
                <mat-form-field *ngIf="form.get('rate')" appearance="standard" class="full_width_form">
                    <mat-label>{{ 'Points I charge for this job' | translate }}</mat-label>
                    <input formControlName="rate" matInput type="number" placeholder="{{ 'Points I charge for this job' | translate }}">
                </mat-form-field>
                
                <mat-form-field appearance="standard" class="full_width_form" *ngIf="form.get('price')">
                    <mat-label>{{ 'Price I charge for this job' | translate }}</mat-label>
                    <input formControlName="price" matInput type="number" placeholder="{{ 'Price I charge for this job' | translate }}">
                    <div class="cur_suffix" matSuffix>{{ getCurrency(data?.company?.employees[0]?.currency_id)?.iso_name }}</div>
                </mat-form-field>    
            </div>

            <div class="white_block" *ngIf="form.get('status_id').value == 3 && !data.empl_status">
                <div class="white_block_title">
                    {{ "Files for approval" | translate }}
                </div>
                <mat-checkbox color='primary' [checked]="is_checked" (change)="enableContentUrl($event.checked)" class="example-margin">{{ "This job consist of file or folder to be reviewed." | translate }}</mat-checkbox>
            
                <mat-tab-group *ngIf="showContentUrl" [selectedIndex]="activeIndex" (selectedIndexChange)="changeTab($event)">
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <mat-icon class="example-tab-icon mr-1">perm_media</mat-icon>
                            {{ 'File manager' | translate }}
                        </ng-template>
                      
                        <app-file-manager
                        [useOut]="true"
                        [initLocation]="'/to_approve'"
                        [initData]="{
                            'task': data?.task,
                            'work': data?.work,
                            'company': data?.company
                        }"></app-file-manager>
                    </mat-tab>
                  
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <mat-icon class="example-tab-icon mr-1">link</mat-icon>
                            {{ 'Content url' | translate }}
                        </ng-template>
                        <mat-form-field appearance="standard" class="full_width_form">
                            <mat-label>{{ 'Content url' | translate }}</mat-label>
                            <input formControlName="content_url" matInput placeholder="{{ 'Content url' | translate }}">
                            <mat-hint *ngIf="!form.get('content_url').value" style="color: red;">{{ "Put the link to file or folder you are submitting for approval" | translate }}</mat-hint>
                        </mat-form-field>
                    </mat-tab>
    
                </mat-tab-group>
            </div>
        </form>
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <div class="f_w_p_btns">
        <button mat-raised-button (click)="close()">
            {{ "Close" | translate }}
        </button>
        <button class="ml-1 d-f-btn" (click)="onSubmit()" cdkFocusInitial mat-raised-button color="primary" [disabled]="isSubmit">
            <mat-spinner class="spin_16 mr-1" *ngIf="isSubmit"></mat-spinner>  
            {{ "Save" | translate }}
        </button>
    </div>
</mat-dialog-actions>