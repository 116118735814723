<div class="container centred_page">

    <div class="step">
        <mat-tab-group mat-align-tabs="center" [selectedIndex]="selectedTabVal" (selectedIndexChange)="changeTab($event)">
            <mat-tab>
                <ng-template mat-tab-label>
                    <mat-icon class="example-tab-icon mr-1">assignment</mat-icon>
                    {{ 'Forms' | translate }}
                </ng-template>
                <div #tableContainer class="example-container example-container-1 mat-elevation-z8 employees_table">
                    <table mat-table [dataSource]="quests" class="centred_cols" multiTemplateDataRows>
                    <!-- ["Date", "Order", "Operation type", "Text"] -->
                      <ng-container matColumnDef="Date">
                        <th mat-header-cell *matHeaderCellDef> {{ "Created at" | translate }} </th>
                        <td mat-cell *matCellDef="let element"> 
                            {{ element.created_at*1000 | date:'mediumDate':'+0000':activeLang | yearPipe }}
                        </td>
                      </ng-container>
        
                      <ng-container matColumnDef="Name">
                        <th mat-header-cell *matHeaderCellDef> {{ "Name" | translate }} </th>
                        <td mat-cell *matCellDef="let element"> 
                            {{ element.name }}
                        </td>
                      </ng-container>
        
                      <ng-container matColumnDef="Activity" *ngIf="company?.permissions.includes('owner') || company?.permissions.includes('admin') || company?.permissions.includes('hr')">
                        <th mat-header-cell *matHeaderCellDef> {{ "Activity" | translate }} </th>
                        <td mat-cell *matCellDef="let element">
                            <mat-icon *ngIf="company?.permissions.includes('owner') || company?.permissions.includes('admin') || company?.permissions.includes('manager') || company?.permissions.includes('hr')" style="color: green; cursor: pointer;" (click)="editQuest(element)">edit</mat-icon>
                            <mat-icon *ngIf="company?.permissions.includes('owner') || company?.permissions.includes('admin')" style="color: red; cursor: pointer;" (click)="deleteQuest(element)">delete</mat-icon>
                        </td>
                      </ng-container>
                        
                      <ng-container matColumnDef="expandedDetail">
                        <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                  
                          <div class="row student-element-detail" [@detailExpand]="element.isExpanded ? 'expanded' : 'collapsed'">
                            <div class="quest_form">
        
                                <div class="form_item" *ngFor="let formItem of element.form">
        
                                    <ng-container [ngSwitch]="formItem.type">
                                        <ng-container *ngSwitchCase="'file'">
                                            <mat-form-field appearance="outline" class="form_item_field full_width_form">
                                                <mat-label>{{formItem.name}}</mat-label>
                                                <ngx-mat-file-input></ngx-mat-file-input>
                                                <mat-icon matSuffix>folder</mat-icon>
                                            </mat-form-field>
                                        </ng-container>
                                        <ng-container *ngSwitchDefault>
                                            <mat-form-field appearance="outline" class="form_item_field full_width_form">
                                                <mat-label>{{formItem.name}}</mat-label>
                                                <input matInput required [type]="formItem.type" placeholder="{{formItem.name}}">
                                            </mat-form-field>
                                        </ng-container>
                                    </ng-container>
                             
                                    <button *ngIf="company?.permissions.includes('owner') || company?.permissions.includes('admin') || company?.permissions.includes('hr')" class="ml-1" mat-mini-fab color="primary" (click)="editField(element, formItem)">
                                        <mat-icon>edit</mat-icon>
                                    </button>
                                    <button *ngIf="company?.permissions.includes('owner') || company?.permissions.includes('admin')" class="ml-1" mat-mini-fab color="primary" (click)="deleteField(element, formItem)">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </div>
                                <div class="form_add" *ngIf="company?.permissions.includes('owner') || company?.permissions.includes('admin') || company?.permissions.includes('hr')">
                                    <div class="d-f-c" (click)="addField(element)">
                                        {{ "Add field" | translate }}
                                        <button class="ml-1" mat-mini-fab color="primary">
                                            <mat-icon>add</mat-icon>
                                        </button>
                                    </div>
                                </div>
        
                            </div>
                          </div>
                        </td>
                      </ng-container>
                  
                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="student-element-row"
                      [class.student-expanded-row]="element.isExpanded"
                      (click)="rowClick(element)"></tr>
          
                    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="student-detail-row"></tr>
                
                    </table>
                </div>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    <mat-icon class="example-tab-icon mr-1">contact_page</mat-icon>
                    {{ 'Users' | translate }}
                </ng-template>
                <form [formGroup]="filter" class="filter_wrp d-f-c" style="justify-content: flex-end;">
                    <div class="d-f-c">
                        <mat-form-field appearance="standard" class="full_width_form">
                            <mat-label>{{ "Choose an employee" | translate }}</mat-label>
                            <mat-select [disableOptionCentering]="true" formControlName="employee_id" required>
                                <mat-select-trigger>
                                    <ng-container *ngIf="!!filter.get('employee_id').value">
                                        <app-user [class]="'user_div'" [employee]="getEmployeeById(filter.get('employee_id').value)"></app-user>
                                    </ng-container>
                                </mat-select-trigger>
                                <mat-option>
                                    <ngx-mat-select-search 
                                    placeholderLabel="{{ 'Search' | translate }}" 
                                    noEntriesFoundLabel="{{ 'no matching executor found' | translate }}"
                                    [formControl]="employeeMoreControl"></ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let employee of employees$ | async" [value]="employee.id">
                                    <app-user [class]="'user_div'" [employee]="employee"></app-user>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </form>

                  <div #emplTableContainer class="example-container mat-elevation-z8 employees_table">
                    <div class="quest_form">
        
                        <div class="form_item" *ngFor="let formItem of emplQuests; let i = index;">

                            <ng-container [ngSwitch]="formItem.type">
                                <ng-container *ngSwitchCase="'file'">
                                    <a *ngIf="formItem.file" target="_blank" [href]="host + formItem?.file?.original + '?company_id=' + company?.id" class="download_link d-f-c" style="margin: 10px 0; width: 100%; padding: 0 11.25px;"><mat-icon>download</mat-icon>{{formItem.file.filename}} <b class="ml-1" *ngIf="formItem?.file?.filesize">{{(formItem.file.filesize / (1024*1024)) | reportsNumber}}MB</b></a>
                                    <mat-form-field appearance="outline" *ngIf="!formItem.file" class="form_item_field full_width_form">
                                        <mat-label>{{formItem.name}}</mat-label>
                                        <ngx-mat-file-input disabled></ngx-mat-file-input>
                                        <mat-hint style="padding-top: 11px;" *ngIf="formItem.comment">{{formItem.comment}}</mat-hint>
                                        <mat-icon matSuffix>folder</mat-icon>
                                    </mat-form-field>
                                </ng-container>
                                <ng-container *ngSwitchCase="'rules'">
                                    <div class="form_item_field full_width_form">
                                        <div [innerHTML]="formItem.rules"></div>
                                        <mat-checkbox disabled [checked]="+formItem.text == 1">{{formItem.name}}</mat-checkbox>
                                        <div *ngIf="formItem.comment">
                                            <mat-hint style="padding-top: 11px;">{{formItem.comment}}</mat-hint>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-container *ngSwitchCase="'date'">
                                    <mat-form-field appearance="outline" class="form_item_field full_width_form">
                                        <mat-label>{{formItem.name}}</mat-label>
                                        <input disabled [value]="formItem.text" matInput [matDatepicker]="pickerDay" placeholder="MM / DD/ YYYY">
                                        <mat-datepicker-toggle matSuffix disabled [for]="pickerDay"></mat-datepicker-toggle>
                                        <mat-datepicker disabled #pickerDay></mat-datepicker>
                                        <mat-hint style="padding-top: 11px;" *ngIf="formItem.comment">{{formItem.comment}}</mat-hint>
                                    </mat-form-field>
                                </ng-container>
                                <ng-container *ngSwitchDefault>
                                    <mat-form-field appearance="outline" class="form_item_field full_width_form">
                                        <mat-label>{{formItem.name}}</mat-label>
                                        <input disabled [value]="formItem.text"  matInput [type]="formItem.type" placeholder="{{formItem.name}}">
                                        <mat-hint style="padding-top: 11px;" *ngIf="formItem.comment">{{formItem.comment}}</mat-hint>
                                    </mat-form-field>
                                </ng-container>
                            </ng-container>
                         
                            <button *ngIf="company?.permissions.includes('owner') || company?.permissions.includes('admin') || company?.permissions.includes('hr')" class="ml-1" mat-mini-fab color="primary" (click)="editDataField(formItem)">
                                <mat-icon>edit</mat-icon>
                            </button>
                            <button *ngIf="company?.permissions.includes('owner') || company?.permissions.includes('admin')" class="ml-1" mat-mini-fab color="primary" (click)="deleteDataField(formItem, i)">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </div>

                    </div>
                      <!-- <table mat-table [dataSource]="emplQuests" class="centred_cols">
                    
                        <ng-container matColumnDef="Date">
                          <th mat-header-cell *matHeaderCellDef> {{ "Created at" | translate }} </th>
                          <td mat-cell *matCellDef="let element"> 
                              {{ element.created_at*1000 | date:'mediumDate':'+0000':activeLang | yearPipe }}
                          </td>
                        </ng-container>
          
                        <ng-container matColumnDef="Name">
                          <th mat-header-cell *matHeaderCellDef> {{ "Name" | translate }} </th>
                          <td mat-cell *matCellDef="let element"> 
                              {{ element.name }}
                          </td>
                        </ng-container>
                          
                    
                      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                      <tr mat-row *matRowDef="let element; columns: displayedColumns;"></tr>
            
                  
                      </table> -->
                  </div>
            </mat-tab>
          </mat-tab-group>
        
       

        <div class="btns_line">
            <a mat-raised-button (click)="goBack($event)">
                {{ "Back" | translate }}
            </a>
            <ng-container *ngIf="selectedTabVal == 0;else templateName">
                <a class="ml-1" *ngIf="company?.permissions.includes('owner') || company?.permissions.includes('admin') || company?.permissions.includes('hr')" (click)="addQuest($event)" mat-raised-button color="primary">
                    <mat-icon>add</mat-icon>
                    {{ "Add form" | translate }}
                </a>
            </ng-container>
            <ng-template #templateName>             
                <a class="ml-1" *ngIf="company?.permissions.includes('owner') || company?.permissions.includes('admin') || company?.permissions.includes('hr')" (click)="addQuestToEmpl()" mat-raised-button color="primary">
                    <mat-icon>add</mat-icon>
                    {{ "Assign form to a member" | translate }}
                </a>
                <a class="ml-1" *ngIf="company?.permissions.includes('owner') || company?.permissions.includes('admin') || company?.permissions.includes('hr')" (click)="addFieldToEmpl()" mat-raised-button color="primary">
                    <mat-icon>text_fields</mat-icon>
                    {{ "Add field to member" | translate }}
                </a>
                <a class="ml-1" *ngIf="company?.permissions.includes('owner') || company?.permissions.includes('admin') || company?.permissions.includes('hr')" (click)="changeQuestStatus()" mat-raised-button color="accent">
                    <mat-icon>change_circle</mat-icon>
                    <ng-container *ngIf="employee?.application_status_id; else elseTemplateHasStat">
                        {{ getStatusName(employee?.application_status_id) | translate }}
                    </ng-container>
                    <ng-template #elseTemplateHasStat>
                        {{ "Set status" | translate }}
                    </ng-template>
                </a>
                <a class="ml-1" *ngIf="employee && emplQuests.length && (company?.permissions.includes('owner') || company?.permissions.includes('admin'))" (click)="deleteForm()" mat-raised-button color="warn">
                    <mat-icon>delete</mat-icon>
                    {{ "Delete form" | translate }}
                </a>
                
            </ng-template>
        </div>

    </div>
</div>