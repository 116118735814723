import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'app-close-from-backdrop',
  templateUrl: './close-from-backdrop.component.html',
  styleUrls: ['./close-from-backdrop.component.scss']
})
export class CloseFromBackdropComponent implements OnInit, AfterViewInit {
  @ViewChild('btnFocus') btnFocus: MatButton

  constructor(
    private bottomSheetRef: MatBottomSheetRef<CloseFromBackdropComponent>
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    setTimeout(() => {this.btnFocus.focus()},0)
  }

  close(): void {
    this.bottomSheetRef.dismiss({
      message: 'close',
    });
  }

  continue() {
    this.bottomSheetRef.dismiss({
      message: 'continue',
    });
  }

}
