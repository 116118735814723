export function transformToWatchUrl(url: string): string {
    // Регулярные выражения для различных форматов ссылок
    const studioLivePattern = /https:\/\/studio\.youtube\.com\/video\/([a-zA-Z0-9_-]+)\/livestreaming/;
    const studioEditPattern = /https:\/\/studio\.youtube\.com\/video\/([a-zA-Z0-9_-]+)\/edit/;
    const liveUrlPattern = /https:\/\/youtube\.com\/live\/([a-zA-Z0-9_-]+)\?feature=share/;
    const shortUrlPattern = /https:\/\/youtu\.be\/([a-zA-Z0-9_-]+)(\?si=[a-zA-Z0-9_-]+)?/;
    const shortsUrlPattern = /https:\/\/(?:www\.)?youtube\.com\/shorts\/([a-zA-Z0-9_-]+)/;
    
    // Проверка соответствия шаблонам
    let match = url.match(studioLivePattern) 
             || url.match(studioEditPattern)
             || url.match(liveUrlPattern) 
             || url.match(shortUrlPattern)
             || url.match(shortsUrlPattern);
    if (match) {
      const videoId = match[1]; // Для shorts берем второй индекс
      return `https://www.youtube.com/watch?v=${videoId}`;
    } else {
      return url; // Если формат ссылки неизвестен, возвращаем исходный URL
    }
}