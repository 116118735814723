import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { Component, Inject, OnChanges, OnDestroy, OnInit, SimpleChanges, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatChipInput, MatChipInputEvent } from '@angular/material/chips';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import * as moment from 'moment';
import { Observable, ReplaySubject, Subscription, concat, forkJoin, fromEvent, of } from 'rxjs';
import { concatMap, debounceTime, filter, map, startWith, switchMap, take, tap } from 'rxjs/operators';
import { TargetParametersComponent } from 'src/app/components/atTasksDialog/target-parameters/target-parameters.component';
import { WorkAddComponent } from 'src/app/components/atTasksDialog/work-add/work-add.component';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { AiService } from 'src/app/shared/services/rest/ai.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { LoadingService } from 'src/app/shared/services/rest/loading.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { PostsNamesComponent } from '../posts-names/posts-names.component';

@Component({
  selector: 'app-create-cards',
  templateUrl: './create-cards.component.html',
  styleUrls: ['./create-cards.component.scss']
})
export class CreateCardsComponent extends BaseClass implements OnInit, OnDestroy {
  public form: FormGroup;
  public isFormChange: boolean = false;
  public aiResults: any;
  public aiMistake: any;
  public showSys: boolean = false;

  public activeTag: number = 0
  public profilesTab: number = 0
  public profiles: any[] = [];
  public profilesMoreControl: FormControl = new FormControl();
  public profiles$: ReplaySubject<any> = new ReplaySubject<any>(1);
  
  public groups$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public groupsMoreControl: FormControl = new FormControl();
  public groups: any;
  
  public taskStatuses$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public taskStatusesMoreControl: FormControl = new FormControl();
  public taskStatuses: any;

  public operations$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public operationsMoreControl: FormControl = new FormControl();
  public operations: any;

  public employees$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public employeesMoreControl: FormControl = new FormControl();
  public employees: any;

  public parameters: any;

  public taskTemplates: any;
  public taskTemplates$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public taskTemplatesControl: FormControl = new FormControl();

  public allContentTypes: any;

  public textForm: FormGroup = this.fb.group({
    before: '',
    after: '',
    for_cards: true,
    for_posts: true
  });
  public aiForm: FormGroup;
  public aiSysForm: FormGroup;

  public playCrCardsCut: any;

  public savedTags: any;
  public savedTags$: Observable<string[]>;
  public savedTagsControl: FormControl = new FormControl();

  public remembers: any;
  public remembers$: Observable<string[]>;
  public remembersControl: FormControl = new FormControl();

  public appPublicationData: any = {
    channel_id: '',
    content_type_id: '',
    content_status_id: 6,
    content_published_at: '',
    is_content_plan: 1,

    content_plan_start_at: 0,
    content_plan_min_interval: 1,
    content_name: this.data.file.filename,
    content_description: this.data.file.filename,
    task_channel_files: [{
      video_project_file_id: 0,
      is_content: 1,
      is_preview: 0,
      is_thumbnail: 0,
      is_original: 0,
      is_to_upload: 1
    }]
  }

  public isGettingTranslations: boolean = false;
  public isGettingResults: boolean = false;
  public isProjectPlaying: boolean = false;
  public regularDateTimes: any = [];
  public aiCount: number = 0;
  @ViewChild('contextMenu') contextMenu: TemplateRef<any>;
  @ViewChild('textContextMenu') textContextMenu: TemplateRef<any>;
  overlayRef: OverlayRef | null;
  overlayTextRef: OverlayRef | null;
  public backTextContextSub: Subscription;
  public backContextSub: Subscription;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CreateCardsComponent>,
    private fb: FormBuilder,
    private taskService: TaskService,
    private aiService: AiService,
    private companyService: CompanyService,
    private ls: LoadingService,
    private dialog: MatDialog,
    public overlay: Overlay,
    public viewContainerRef: ViewContainerRef,
    private layoutService: LayoutService
  ) { super() }

  ngOnInit(): void {
    console.log("CreateCardsComponent", this.data)

    if (!!this.data.vidProject.batch_data) {
      if (!this.data.vidProject.batch_data.selectedCols) {
        this.data.vidProject.batch_data.selectedCols = [...this.data.vidProject.batch_data.headCols]
      }
    }

    if (this.data.version == 5.1) {
      this.appPublicationData.content_plan_content_schedules_ids = [];
      this.appPublicationData.content_plan_content_schedule_items_ids = [];
    }

    this.form = this.fb.group({
      type: 0,
      group_id: "",
      status_id: 1,
      create_parameter_values_to_task: [[]],
      name: '',
      template_id: ''
    })

    this.attachSubscriptions(
      this.data.projectState$.subscribe((isPlaying) => {
        console.log("this.data.projectState$", isPlaying)
        if (!isPlaying) {
          this.playCrCardsCut = false
        }
        this.isProjectPlaying = isPlaying;
      })
    )

    this.aiForm = this.fb.group({
      on_the_video: '',
      your_idea_of_title: '',
      remembers: [[]],
      keywords: [[]],
      also_remember: ''
    })

    this.aiSysForm = this.fb.group({
      str: this.getResultsReqObject().messages[0].content || ''
    })

    this.attachSubscriptions(
      this.aiForm.valueChanges.subscribe(res => {
        this.aiSysForm.patchValue({
          str: this.getResultsReqObject().messages[0].content || ""
        })
      })
    )

    if (!!this.data.form) {
      this.form.patchValue(this.data.form.value)
    }
    this.parameters = this.data.parameters;
    this.taskTemplates = this.data.taskTemplates;
    this.taskTemplates$.next(this.taskTemplates.slice());
    this.savedTags = this.data.savedTags;

    this.profiles = this.data.profiles;
    this.profiles$.next(this.profiles.slice());

    this.operations = this.data.operations;
    this.operations$.next(this.operations.slice());
    
    this.employees = this.data.employees;
    this.employees$.next(this.employees.slice());
    
    this.groups = this.data.groups;
    this.groups$.next(this.groups.slice());
    
    this.taskStatuses = this.data.taskStatuses;
    this.taskStatuses$.next(this.taskStatuses.slice());

    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        // if (this.isFormChange) {
        //   this.layoutService.openBottomSheet(this.dialogRef);
        // } else {
        //   this.close();
        // }
        this.close();
      })
    )

    this.form.valueChanges.subscribe(res => {
      console.log(res, this.form.value)
      this.isFormChange = true;
      this.data.form.patchValue(this.form.value)
      this.data.tracks.filter(x => x.type == 'video').forEach((trackEl, trackInd) => {
        trackEl.parts.forEach((part, ind) => {
          this.setFormValueAsHeadForm(part, false, true)
        });
      })
      
      this.updateJobs()
    })

    this.getRemembers();

    this.remembers$ = this.remembersControl.valueChanges.pipe(
      startWith(null),
      map((search: string | null) => (search ? this._filterRemembers(search) : this.remembers.slice())),
    );

    this.savedTags$ = this.savedTagsControl.valueChanges.pipe(
      startWith(null),
      map((search: string | null) => (search ? this._filter(search) : this.savedTags.slice())),
    );
    
    this.attachSubscriptions(
      this.profilesMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchProfiles(resp))
    )

    this.attachSubscriptions(
      this.groupsMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchGroups(resp))
    )

    this.attachSubscriptions(
      this.taskStatusesMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchTaskStatuses(resp))
    )

    this.attachSubscriptions(
      this.employeesMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchEmoloyees(resp))
    )
    
    this.attachSubscriptions(
      this.operationsMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchOperations(resp))
    )

    this.attachSubscriptions(
      this.taskTemplatesControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchTemplates(resp))
    )



    // this.attachSubscriptions(
    //   this.form.valueChanges.subscribe(() => this.isFormChange = true)
    // )

  }

  openPostsNames() {
    const dialogRef = this.dialog.open(PostsNamesComponent, {
      backdropClass: ['ve_back_auto'],
      panelClass: ['ve_panel_auto'],
      disableClose: true,
      data: {
        vidProject: this.data.vidProject,
        company: this.data.company,
        company_id: this.data.company_id,
        tracks: this.data.tracks,
        allValues: this.data.allValues,
        user: this.data.user,
        host: this.data.host,
        isGettingTranslations: this.isGettingTranslations,
      }
    });


    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (result && result.data) {
          // this.saveVideoEditorHistory("Post Names Settings")
        }
        // console.log("form", this.form.value)
      })
    )
  }

  changeAllTags(e) {
    console.log("changeAllTags", e)
    let parts = (this.data.tracks.filter(x => x.type == 'video').map(k => k.parts) as any).flat(Infinity)
    parts.forEach(part => {
      part.activeTag = e.value;
    })
  }

  getValuesById(id) {
    return this.data.allValues && this.data.allValues.find(x => x.id == id)
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.savedTags.filter(x => String(x.value).toLowerCase().indexOf(filterValue) != -1);
  }

  private _filterRemembers(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.savedTags.filter(x => String(x.message).toLowerCase().indexOf(filterValue) != -1);
  }

  titleAI(e, data, cut?) {
    this.resetRes()
    if (cut) {
      this.aiCount = 0
      data.isGettingResults = true;
      this.getCutResults(e, this.aiForm, data)
    } else {
      this.openContext(e, data, 0, true, cut)
    }
  }

  logCrCards() {
    console.log("playCrCardsCut", this.playCrCardsCut)
    console.log("taskTemplates", this.taskTemplates)
  }

  playVidPart(cut) {
    this.playCrCardsCut = cut; 
    this.data.playVideoInterval(cut)
  }

  openContext({ x, y }: MouseEvent, contextData, general_group_id, is_ai?, is_cut?) {
    this.closeContext();
    console.log("{ x, y }: MouseEvent, contextData, group_id", { x, y }, contextData, general_group_id)
    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo({ x, y })
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        }
      ]);

    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });

    contextData.is_cut = is_cut

    let conData:any = {
      contextData: contextData
    }

    if (is_ai) {
      conData.is_ai = is_ai
    } else {
      let outlets = []
      let setupGroups = []
      let parts = (this.data.tracks.filter(x => x.type == 'video').map(k => k.parts) as any).flat(Infinity)
      parts.forEach(part => {
        if (part.form) {
          if (part.form.get('template_id').value) {
            let activeT = this.getItemById(this.taskTemplates, part.form.get('template_id').value);
            if (activeT && activeT.template_data.group_id && !setupGroups.includes(activeT.template_data.group_id)) {
              setupGroups.push(activeT.template_data.group_id)
            }
          } else if (part.form.value.group_id) {
            if (!setupGroups.includes(part.form.value.group_id)) {
              setupGroups.push(part.form.value.group_id)
            }
          }
        }
      })

      console.log("setupGroups", setupGroups)

      if (setupGroups && setupGroups.length) {
        setupGroups.forEach(group_id => {
          console.log("this.getGroupById(group_id)", this.getGroupById(group_id))
          if (this.getGroupById(group_id)) {
            let channelToGroup = this.getGroupById(group_id).channelToGroup;
      
            channelToGroup.forEach((chan:any) => {
              chan.is_selected = false;
              chan.fromGroupId = group_id;
              chan.publData = {
                channel_id: chan.channel_id,
                content_type_id: chan.content_type_id,
                content_status_id: 6,
                content_published_at: 0,
                is_content_plan: 1,
                content_plan_start_at: 0,
                content_plan_min_interval: 0,
                content_name: this.data.file.filename,
                content_description: this.data.file.filename,
                task_channel_files: [{
                  video_project_file_id: 0,
                  is_content: 1,
                  is_preview: 0,
                  is_thumbnail: 0,
                  is_original: 0,
                  is_to_upload: 1
                }]
              }
              if (this.data.version == 5.1) {
                chan.publData.content_plan_content_schedules_ids = [];
                chan.publData.content_plan_content_schedule_items_ids = [];
              }
              if (!!chan.content_type_id) {
                if (outlets.filter(x => x.content_type_id == chan.content_type_id).length == 0) {
                  outlets.push({
                    content_type_id: chan.content_type_id,
                    platform: chan.channel.platform,
                    contentType: this.getTypeById(chan.content_type_id),
                    arr: [chan],
                  })
                } else {
                  outlets.find(x => x.content_type_id == chan.content_type_id).arr.push(chan)
                }
              }
            })
  
            conData.group = this.getGroupById(group_id);
          }
        })
        conData.outlets = outlets;
      }

      if (this.data.version == 5.1) {
        this.attachSubscriptions(
          forkJoin(conData.outlets.map(outGr => forkJoin(outGr.arr.map(outl => this.companyService.getRegulars('1', this.data.company_id, {channel_id: [outl.id], content_type_id: outGr.content_type_id}).pipe(tap(regs => {outl.regulars = regs.body})))))).subscribe(resp => {
            console.log("after getting regulars", resp)
            console.log("conData.outlets after getting regulars", conData.outlets)
          })
        )
      }

    }
    

    this.overlayRef.attach(new TemplatePortal(this.contextMenu, this.viewContainerRef, {
      $implicit: conData
    }));

    console.log("this.contextMenu", this.contextMenu)
    console.log("conData.outlets", conData.outlets)
    console.log("conData", conData)

    this.backContextSub = fromEvent<MouseEvent>(document, 'click')
      .pipe(
        filter(event => {
          const clickTarget = event.target as HTMLElement;
          return !!this.overlayRef && !this.overlayRef.overlayElement.contains(clickTarget) && !clickTarget.closest('.list_of_keywords') && !clickTarget.closest('.mat-select-panel'); 
        }),
        take(1)
      ).subscribe(() => this.closeContext())

  }

  
  // acceptRegularTimeForAllParts(contextdata, item) {

  //   this.data.publications.push({...this.appPublicationData});
  //   let parts = (this.data.tracks.filter(x => x.type == 'video').map(k => k.parts) as any).flat(Infinity)
  //   parts.forEach((part, i) => {

  //     let pubData = JSON.parse(JSON.stringify(this.appPublicationData, this.getCircularReplacer()))

  //     pubData.content_published_at = this.regularDateTimes[i].content_published_at

  //     if (part.publications) {
  //       part.publications.push(pubData)
  //     } else {
  //       part.publications = [pubData]
  //     }
  //     if (i == parts.length - 1) {
  //       this.closeContext();
  //     }
  //   });
  // }

  checkOutletsCount(arrOut) {
    let count = 0;

    arrOut.forEach(el => {
      count = count + el.arr.length;
    })
    
    return count
  }
  
  selectAll(arrOut) {    
    arrOut.forEach(el => {
      el.arr.forEach(elIn => {
        elIn.is_selected = true;
      })
    })
  }

  getContentTypes(platform_id, contentTypePage) {
    this.attachSubscriptions(
      this.companyService.getContentTypes(platform_id, contentTypePage).pipe(
        map(el => el.body)
      ).subscribe(resp => {
        console.log("getContentTypes", resp)
        this.allContentTypes = resp;
        this.profilesTab = 1;
      })
    )
  }

  selectProfile(item, profile) {
    console.log("selectProfile", item, profile)

    this.appPublicationData.channel_id = profile.id

    this.getContentTypes(profile.platform_id, '1')
  }

  selectPubType(item, type) {
    console.log("selectPubType", item, type)

    this.appPublicationData.content_type_id = type.id
    this.profilesTab = 2;
    this.getFreeRegulars()
  }

  getFreeRegulars() {
    console.log("getFreeRegulars")
    let filterData = {
      channel_id: this.appPublicationData.channel_id,
      content_type_id: this.appPublicationData.content_type_id,
      publish_at_from: ''
    }

    filterData.publish_at_from = moment().format("X");

    console.log("filterData", filterData)

    this.attachSubscriptions(
      this.companyService.getExpandPublishScheduledPosts('1', this.data.company.id, {...filterData}, '200').pipe(
        map(el => el.body),
        switchMap(contents => {
          let urlsData:any = {
            channel_id: this.appPublicationData.channel_id,
            content_type_id: this.appPublicationData.content_type_id,
            content_published_at_from: ''
          };

          urlsData.content_published_at_from = moment().format("X");
          console.log("urlsData", urlsData)
          return this.taskService.getExpandPublishUrls('1', this.data.company.id, {...urlsData}, '200').pipe(
            map(expUrls => {
              return {    
                urls: expUrls.body,
                contents: contents
              }
            })
          )
        })
      ).subscribe((resp:any) => {

        let urls = resp.urls
        urls.forEach(x => {
          x.scheduledObj = {};
          x.name = x.content_name;
        })

        resp.contents.forEach(con => {
          if (urls.length > 0 && urls.filter(x => x.content_published_at == con.publish_at).length > 0) {
            urls.find(x => x.content_published_at == con.publish_at).scheduledObj = con
          } else {
            con.is_con = true;
            con.content_published_at = con.publish_at;
            urls.push(con)
          }
        })

        urls.sort(function(a, b) {
          return (a.content_published_at - b.content_published_at);
        })

        let regularFreeDateTimes = [...urls]

        regularFreeDateTimes.forEach(x => {
          if (x.hasOwnProperty('scheduledObj')) {
            if (moment().isSameOrAfter(moment(x.content_published_at*1000))) {
              x.class = 'green'
            } else {
              x.class = 'blue'
            }
          } else {
            if (moment().isSameOrAfter(moment(x.content_published_at*1000))) {
              x.class = 'silver'
            } else {
              x.class = 'white'
            }
          }
        })

        this.regularDateTimes = regularFreeDateTimes.filter(x => x.class == 'white');
      })
    )
  }

  nextTab() {
    if (this.profilesTab == 0) {

    }
    this.profilesTab = this.profilesTab + 1;
  }
  
  finishPubl(outlets) {
    console.log("finishPubl", outlets)
    let pubs = [];
    outlets.forEach(outlet => {
      outlet.arr.forEach(item => {
        if (item.is_selected) {
          pubs.push(item)
          // pubs.push(item.publData)
        }
      });
    });
    this.data.publications = pubs
    console.log("finishPubl 2", this.data.publications)



    // parts.forEach(part => {
    //   if (part.form) {
    //     if (part.form.get('template_id').value) {
    //       let activeT = this.getItemById(part.taskTemplates, part.form.get('template_id').value);
    //       if (activeT && activeT.template_data.group_id && !setupGroups.includes(activeT.template_data.group_id)) {
    //         setupGroups.push(activeT.template_data.group_id)
    //       }
    //     } else if (part.form.value.group_id) {
    //       if (!setupGroups.includes(part.form.value.group_id)) {
    //         setupGroups.push(part.form.value.group_id)
    //       }
    //     }
    //   }
    // })

    let parts = (this.data.tracks.filter(x => x.type == 'video').map(k => k.parts) as any).flat(Infinity)
    parts.forEach((part, i) => {

      let partGroupId;

      if (part.form) {
        if (part.form.get('template_id').value) {
          let activeT = this.getItemById(this.taskTemplates, part.form.get('template_id').value);
          if (activeT && activeT.template_data.group_id) {
            partGroupId = activeT.template_data.group_id;
          }
        } else if (part.form.value.group_id) {
          partGroupId = part.form.value.group_id;
        }
      }

      if (partGroupId) {
        let pubsData = JSON.parse(JSON.stringify(this.data.publications, this.getCircularReplacer()))
        pubsData.map(x => x.publData.content_plan_min_interval = x.publData.content_plan_min_interval + 1)
        part.publications = pubsData.filter(x => x.fromGroupId == partGroupId).map(x => x.publData);

        console.log("part.publications", part.publications)
      }



      if (i == parts.length - 1) {
        this.closeContext();
      }
    });

  }

  selectTitle(item, contentData) {
    console.log("item, contentData", item, contentData)
    if (contentData.contextData && contentData.contextData.is_cut) {
      contentData.contextData.is_input_set_ai = true;
      contentData.contextData.form.patchValue({
        name: item
      })
    } else {
      this.data.is_input_set_ai = true;
      this.form.patchValue({
        name: item
      })
    }
    this.closeContext();
  }

  closeContext() {
    console.log("closeContext")
    this.profilesTab = 0;
    // this.regularDateTimes = [];
    this.appPublicationData = {
      channel_id: '',
      content_type_id: '',
      content_status_id: 6,
      content_published_at: '',
      content_name: this.data.file.filename,
      content_description: this.data.file.filename,
      task_channel_files: [{
        video_project_file_id: 0,
        is_content: 1,
        is_preview: 0,
        is_thumbnail: 0,
        is_original: 0,
        is_to_upload: 1
      }]
    }
    this.backContextSub && this.backContextSub.unsubscribe();
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
  }

  // extractText(inputString: string, before: string, after: string): string | null {
  //   const regexPattern = `(?<=${before}).*?(?=${after})`;
  //   const regex = new RegExp(regexPattern);
  //   const match = inputString.match(regex);
  //   return match ? match[0] : null;
  // }

  extractText(inputString: string, before: string, after: string): string | null {
    const beforeIndex = inputString.indexOf(before);
    if (beforeIndex === -1) {
      return null; // before не найден в строке
    }
    const afterIndex = inputString.indexOf(after, beforeIndex + before.length);
    if (afterIndex === -1) {
      return null; // after не найден в строке
    }
    return inputString.substring(beforeIndex + before.length, afterIndex);
  }

  getText() {
    if (!this.textForm.value.before) {
      this.layoutService.showSnackBar({name: ''}, "Write after text", SnackBarItem)
      return
    }
    if (!this.textForm.value.after) {
      this.layoutService.showSnackBar({name: ''}, "Write before text", SnackBarItem)
      return
    }

    let parts = (this.data.tracks.filter(x => x.type == 'video').map(k => k.parts) as any).flat(Infinity);

    parts.forEach(part => {
      let partExtractedText = this.extractText(part.filename, this.textForm.value.before, this.textForm.value.after);
      console.log("partExtractedText", partExtractedText)

      if (this.textForm.value.for_cards) {
        if (part.form) {
          part.form.patchValue({
            name: partExtractedText
          })
        }
      }
      if (this.textForm.value.for_posts && part.postNames && part.postNames['0']) {
        part.postNames['0'].name = partExtractedText;
      }
    })
    
  }

  openTextContext({ x, y }: MouseEvent, contextData) {
    this.closeGetText();
    console.log("{ x, y }: MouseEvent, contextData", { x, y }, contextData)
    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo({ x, y })
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        }
      ]);

    this.overlayTextRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });


    let conData:any = {
      contextData: contextData
    }

    this.overlayTextRef.attach(new TemplatePortal(this.textContextMenu, this.viewContainerRef, {
      $implicit: conData
    }));

    console.log("this.textContextMenu", this.textContextMenu)
    console.log("conData.outlets", conData.outlets)
    console.log("conData", conData)

    this.backTextContextSub = fromEvent<MouseEvent>(document, 'click')
      .pipe(
        filter(event => {
          const clickTarget = event.target as HTMLElement;
          return !!this.overlayTextRef && !this.overlayTextRef.overlayElement.contains(clickTarget) && !clickTarget.closest('.list_of_keywords'); 
        }),
        take(1)
      ).subscribe(() => this.closeGetText())

  }

  closeGetText() {
    console.log("closeGetText")
    this.backTextContextSub && this.backTextContextSub.unsubscribe();
    if (this.overlayTextRef) {
      this.overlayTextRef.dispose();
      this.overlayTextRef = null;
    }
  }

  resetRes() {
    this.aiResults = [];
    this.aiMistake = undefined;
    this.showSys = false;
  }

  checkIfGetPostMainNames() {
    let parts = (this.data.tracks.filter(x => x.type == 'video').map(k => k.parts) as any).flat(Infinity);
    let val = true;

    parts.forEach(part => {
      if (!(part.postNames && part.postNames['0'] && !!part.postNames['0'])) {
        val = false
      }
    })

    return val
  }

  getTranslations() {
    if (this.isGettingTranslations) {
      return
    }
    // this.openPostsNames();
    console.log("getTranslations", this.data)
    let parts = (this.data.tracks.filter(x => x.type == 'video').map(k => k.parts) as any).flat(Infinity)

    let tslData = []

    
    parts.forEach((part, partInd) => {
      Object.keys(part.postNames).forEach(tID => {
        if (!!tID && this.getValuesById(tID)) {
          let x:any = {
            partInd: partInd,
            tID: tID,
            data: {
              company_id: this.data.company.id,
              partner_company_id: this.data.company.id != this.data.target_company_id ? this.data.target_company_id : 0,
              messages: [{
                role: 'user',
                content: `Translate '${part.postNames['0'].name}' into ${this.getValuesById(tID).value}. Return only result.`
              }]
            }
          }
          tslData.push(x)
        }
      })
    })

    this.isGettingTranslations = true;
    
    let count = 1;
    this.attachSubscriptions(
      concat(...tslData.map(x => this.aiService.sendAIMsg(x.data).pipe(
        tap(val => {
          let rsl = val.replaceAll('"','').replaceAll("'", "");
          if (rsl.indexOf('-->') != -1) {
            rsl = rsl.split('-->')[1]
          }
          if (rsl.indexOf('->') != -1) {
            rsl = rsl.split('->')[1]
          }
          if (rsl.indexOf('>') != -1 || rsl.indexOf('>') != -1) {
            rsl = rsl.replaceAll(">", "").replaceAll("<", "")
          }
          parts[x.partInd].postNames[x.tID].name = rsl;
        })
      ))).pipe(
        tap(el => {
          this.ls.requests$.next({
            value: Math.round((100 / tslData.length) * count),
            target: "Getting translations" 
          })
          count++;
        })
      ).subscribe(resp => {
        console.log('translate res', resp)
        console.log('translate result', parts)
        this.isGettingTranslations = false;
      }, error => {
        this.isGettingTranslations = false;
        this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
      })
    )

  }

  // Translate "My Wonderful World" into Japan. Return only result.
  getResults(form, contextdata) {
    let parts = (this.data.tracks.filter(x => x.type == 'video').map(k => k.parts) as any).flat(Infinity)
    this.aiCount++

    let x:any = {
      company_id: this.data.company.id,
      partner_company_id: this.data.company.id != this.data.target_company_id ? this.data.target_company_id : 0,
      messages: []
    }

    let msg = "";
    Object.keys(form.value).forEach(key => {
      if (!!form.value[key]) {
        if (['keywords', 'remembers'].includes(key) && form.value[key].length == 0) {
          return
        }

        if (key == 'keywords') {
          msg += "Use keywords "
        }

        if (['keywords', 'remembers'].includes(key)) {
          msg+= `${form.value[key].join(', ')}. `
        } else {
          msg+= `${form.value[key]}. `
        }

      }
    })

    let content = `${msg}Write ${parts.length} options in html ul.`
    if (content !== this.aiSysForm.value.str) {
      content = this.aiSysForm.value.str
    }

    x.messages.push({
      role: 'user',
      content: content
    })

    this.isGettingResults = true;
    this.attachSubscriptions(
      this.aiService.sendAIMsg(x).pipe(
        map(u => {
          if (u.indexOf("<ul>") != -1) {
            const parser = new DOMParser();
            const doc = parser.parseFromString(u, 'text/html');
            const ulElement = doc.querySelector('ul');

            if (ulElement) {
              let liElements = ulElement.querySelectorAll('li');
              let liArray = Array.from(liElements).map(x => (String(x.textContent) as any).replaceAll("\n", "").trim())
              return {parsed: !!liArray && liArray.length ? liArray : false, before: u};
            } else {
              return {parsed: false, before: u}
            }
          } else {
            return {parsed: false, before: u}
          }
        })
      ).subscribe(resp => {
        console.log("getResults", resp)
        if (resp.parsed) {
          this.aiCount = 0;
          this.isGettingResults = false;
          this.aiResults = resp.parsed
          console.log("TESTS", !contextdata.contextData || !contextdata.contextData.is_cut)
          console.log("TESTS", this.aiResults)
          if (!contextdata.contextData || !contextdata.contextData.is_cut) {
            this.setTitlesForParts(parts)
          }
        } else {
          if (this.aiCount <= 3) {
            this.getResults(form, contextdata)
          } else {
            this.aiMistake = resp.before
            this.aiCount = 0;
            this.isGettingResults = false;
            this.layoutService.showSnackBar({name: ''}, marker("Incorrect data received, please try again"), SnackBarItem)
          }
        }
      }, error => {
        if (this.aiCount <= 3) {
          this.getResults(form, contextdata)
        } else {
          this.aiMistake = error
          this.aiCount = 0;
          this.isGettingResults = false;
          this.layoutService.showSnackBar({name: ''}, marker("Incorrect data received, please try again"), SnackBarItem)
        }
      })
    )
    // console.log("getResults form", form.value);
    // console.log("getResults x", x);
  }

  setTitlesForParts(parts) {
    this.closeContext();
    parts.forEach((part,i) => {
      if (part.form && this.aiResults && this.aiResults.length && this.aiResults[i]) {
        part.is_input_set_ai = true;
        part.form.patchValue({
          name: this.aiResults[i]
        })
      }
    });
  }

  openCutTargetValues(cut) {
    let initData:any = {
      company: this.data.company,
      auto: true,
      parameters: this.parameters,
      tags: cut.tags,
      forOutput: true
    }

    const dialogRef = this.dialog.open(TargetParametersComponent, {
      backdropClass: ['parameters_modal_backdrop'],
      panelClass: ['without_paddings_modal', 'parameters_modal'],
      data: initData
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result) {
          if (result.event == 'save') {
            console.log(result.data);
            cut.tags = result.data[0].activeValues;
            // this.parameters = result.data
            // this.form.patchValue({
            //   parameter_value_id: this.parameters[0].activeValues.map(x => x.id)
            // })
          }
        }
      })
    )
  }

  getCutResults(e, form, contextdata) {
    this.aiCount++
    let x:any = {
      company_id: this.data.company.id,
      partner_company_id: this.data.company.id != this.data.target_company_id ? this.data.target_company_id : 0,
      messages: []
    }
    
    let msg = "";
    Object.keys(form.value).forEach(key => {
      if (!!form.value[key]) {
        if (['keywords', 'remembers'].includes(key) && form.value[key].length == 0) {
          return
        }
        
        if (key == 'keywords') {
          msg += "Use keywords "
        } 

        if (['keywords', 'remembers'].includes(key)) {
          msg+= `${form.value[key].join(', ')}. `
        } else {
          msg+= `${form.value[key]}. `
        }
  
        // x.messages.push({
        //   role: 'user',
        //   content: msg
        // })
      }
    })
    
    x.messages.push({
      role: 'user',
      content: `${msg}Write ${contextdata.optionsCount} options in html ul.`
    })
    // x.messages.push({
    //   role: 'user',
    //   content: "Important: Answer without extra text, only the answer to my specific request. If I ask you to provide 1 option, give it in the form (for example js array: ['option']). If I ask for more options, give them out without iteration and in the form of a single array where each element of the array is an answer variant (for example js array: ['option1', 'option2', ...])"
    // })

    contextdata.isGettingResults = true;
    this.attachSubscriptions(
      this.aiService.sendAIMsg(x).pipe(
        map(u => {
          if (u.indexOf("<ul>") != -1) {
            const parser = new DOMParser();
            const doc = parser.parseFromString(u, 'text/html');
            const ulElement = doc.querySelector('ul');

            if (ulElement) {
              let liElements = ulElement.querySelectorAll('li');
              let liArray = Array.from(liElements).map(x => (String(x.textContent) as any).replaceAll("\n", "").trim())
              return {parsed: !!liArray && liArray.length ? liArray : false, before: u};
            } else {
              return {parsed: false, before: u}
            }
          } else {
            return {parsed: false, before: u}
          }
        })
      ).subscribe(resp => {
        if (resp) {
          this.aiCount = 0;
          contextdata.isGettingResults = false;
          this.aiResults = resp.parsed
          this.openContext(e, contextdata, 0, true, true)
        } else {
          if (this.aiCount <= 3) {
            this.getCutResults(e, this.aiForm, contextdata)
          } else {
            this.aiCount = 0;
            contextdata.isGettingResults = false;
            this.layoutService.showSnackBar({name: ''}, marker("Incorrect data received, please try again"), SnackBarItem)
          }
        }
      }, error => {
        if (this.aiCount <= 3) {
          this.getCutResults(e, this.aiForm, contextdata)
        } else {
          this.aiCount = 0;
          contextdata.isGettingResults = false;
          this.layoutService.showSnackBar({name: ''}, marker("Incorrect data received, please try again"), SnackBarItem)
        }
      })
    )
  }

  getResultsReqObject() {
    let parts = (this.data.tracks.filter(x => x.type == 'video').map(k => k.parts) as any).flat(Infinity)
    let x:any = {
      company_id: this.data.company.id,
      partner_company_id: this.data.company.id != this.data.target_company_id ? this.data.target_company_id : 0,
      messages: []
    }
    let form = this.aiForm.value;

    
    let msg = "";
    Object.keys(form).forEach(key => {
      if (!!form[key]) {
        if (['keywords', 'remembers'].includes(key) && form[key].length == 0) {
          return
        }
        
        // if (key == "on_the_video") {
        //   msg += "On the video"
        // } else if (key == "your_idea_of_title") {
        //   msg += "Example of the title"
        // } else if (key == "also_remember") {
        //   msg += "Also remember"
        // } else if (key == 'keywords') {
        //   msg += "Use words"
        // } else if (key == 'remembers') {
        //   msg += "Remember"
        // }

        if (key == 'keywords') {
          msg += "Use keywords "
        }

        if (['keywords', 'remembers'].includes(key)) {
          msg+= `${form[key].join(', ')}. `
        } else {
          msg+= `${form[key]}. `
        }
        // x.messages.push({
        //   role: 'user',
        //   content: msg
        // })
      }
    })
    // x.messages.push({
    //   role: 'user',
    //   content: `Important: Write ${parts.length} options for video title. Answer without extra text, only the answer to my specific request. If I asked you for several options, give them as an array. Where each option is a string and an array element. Without extra text and iteration of options in your answer. (for example js array: ["option1", "option2", ...]).`
    // })
    x.messages.push({
      role: 'user',
      content: `${msg}Write ${parts.length} options in html ul.`
    })


    return x
  }
  
  getRemembers() {
    this.attachSubscriptions(
      this.aiService.getAIMsg({company_id: this.data.company.id, partner_company_id: (this.data.target_company_id && this.data.target_company_id != this.data.company.id) ? this.data.target_company_id : 0 }).subscribe(resp => {
        this.remembers = resp
        this.remembersControl.patchValue('')
      })
    )
  }

  onSearchTemplates(resp) {
    if (!this.taskTemplates) {
      return;
    }

    if (!resp) {
      this.taskTemplates$.next(this.taskTemplates.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.taskTemplates$.next(
      this.taskTemplates.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  checkIfCanSelectAll(allArr, selectedArr) {
    if (!allArr) {
      return false
    }
    return allArr.filter(x => !selectedArr.includes(x.value)).length > 1
  }

  selectAllKeywords(keywordsAuto, selectedArr, form, triggerKeys: MatAutocompleteTrigger) {
    let allArr = keywordsAuto.options._results.map(x => x.value);
    console.log("selectAllKeywords allArr", allArr);
    console.log("selectAllKeywords keywordsAuto", keywordsAuto);
    
    console.log("selectAllKeywords keywordsAuto", keywordsAuto);

    let arr = selectedArr.slice();
  
    arr.push(...allArr);
    form.patchValue({
      keywords: arr
    })

    triggerKeys.closePanel();
  }

  selectedKeyword(event: MatAutocompleteSelectedEvent, keyInput): void {
    let arr = this.aiForm.get('keywords').value.slice();
    arr.push(event.option.viewValue);

    this.aiForm.patchValue({
      keywords: arr
    })
    
    this.savedTagsControl.setValue(null);
  }

  selectedRemember(event: MatAutocompleteSelectedEvent, keyInput): void {
    let arr = this.aiForm.get('remembers').value.slice();
    arr.push(event.option.viewValue);

    this.aiForm.patchValue({
      remembers: arr
    })
    
    this.remembersControl.setValue(null);
  }

  removeKeyword(key: string): void {
    const index = this.aiForm.get('keywords').value.indexOf(key);

    if (index >= 0) {
      this.aiForm.get('keywords').value.splice(index, 1);
    }
  }

  removeRemember(key: string): void {
    const index = this.aiForm.get('remembers').value.indexOf(key);

    if (index >= 0) {
      this.aiForm.get('remembers').value.splice(index, 1);
    }
  }

  addRemember(event: MatChipInputEvent, keyInput:MatChipInput, triggerKeys: MatAutocompleteTrigger): void {
    const value = (event.value || '').trim();
    console.log("keyInput", keyInput)
    console.log("value", value)
    console.log("event", event)

    let arr = this.aiForm.get('remembers').value.slice();

    if (!!value && !arr.includes(value) && (!this.remembers || this.remembers.length == 0 || this.remembers.filter(x => x.message.toLowerCase() == value).length == 0)) {
      this.attachSubscriptions(
        this.aiService.saveAiMsg(({company_id: this.data.company.id, message: value})).subscribe(resp => {
          arr.push(value)
  
          this.aiForm.patchValue({
            remembers: arr
          })
      
          event.input.value = '';
          if (triggerKeys.panelOpen) {
            triggerKeys.closePanel();
          }
          this.getRemembers();
        }, error => {
          this.layoutService.showSnackBar({name: error}, marker("Failed to add"), SnackBarItem)
          if (triggerKeys.panelOpen) {
            triggerKeys.closePanel();
          }
        })
      )
    }
  }

  addKeyword(event: MatChipInputEvent, keyInput:MatChipInput, triggerKeys: MatAutocompleteTrigger): void {
    const value = (event.value || '').trim();

    // Add our fruit
    // if (value) {
    //   this.savedTags.push(value);
    // }

    // Clear the input value
    console.log("keyInput", keyInput)
    console.log("value", value)
    console.log("event", event)

    let arr = this.aiForm.get('keywords').value.slice();

    if (!arr.includes(value) && !!value) {
      arr.push(value)
  
      this.aiForm.patchValue({
        keywords: arr
      })
  
      event.input.value = '';
      this.savedTagsControl.setValue(null);
      if (triggerKeys.panelOpen) {
        triggerKeys.closePanel();
      }
    }

  }

  setFormValueAsHeadForm(cut:any, initValueChanges:boolean = true, isPatch:boolean = false) {
    console.log("cut", cut)
    if (!!cut.is_custom.value) {
      return
    }
    if (isPatch && cut.form) {
      cut.form.patchValue({
        name: this.form.value.type == 0 ? ((cut.form && cut.form.value.name) ? cut.form.value.name : '') : this.form.value.name,
        type: this.form.value.type,
        group_id: this.form.value.group_id,
        status_id: this.form.value.status_id,
        create_parameter_values_to_task: this.form.value.create_parameter_values_to_task,
        template_id: this.form.value.template_id
      })
    } else {
      cut.form = this.fb.group({
        name: (cut.form && cut.form.value.name) ? cut.form.value.name : '',
        type: this.form.value.type,
        group_id: this.form.value.group_id,
        status_id: this.form.value.status_id,
        create_parameter_values_to_task: this.form.value.create_parameter_values_to_task,
        template_id: this.form.value.template_id,
      })
    }
    cut.parameters = JSON.parse(JSON.stringify(this.parameters, this.getCircularReplacer()));

    if (initValueChanges) {
      if (!cut.subscr) {
        cut.subscr = cut.is_custom.valueChanges.subscribe(res => {
          console.log('is_custom', cut, res)
          if (!res) {
            console.log('is_custom2', cut, res)
            this.setFormValueAsHeadForm(cut, false)
            cut.jobs = JSON.parse(JSON.stringify(this.data.jobs, this.getCircularReplacer()))
          }
        })
      }
    }
  }

  onRemoveCut(cut, t) {
    cut.tags.splice(t,1);
  }

  onRemove(tagID, ind, cut?, job?) {
    if (job) {
      if (cut) {
        cut.is_custom.patchValue(true)
        job.parameters[0].activeValues.splice(ind, 1)
      } else {
        job.parameters[0].activeValues.splice(ind, 1)
        this.updateJobs();
      }
      // this.saveVideoEditorHistory();
    } else {
      if (cut) {
        cut.is_custom.patchValue(true)
        cut.parameters[0].activeValues.splice(ind, 1)
        cut.form.patchValue({
          create_parameter_values_to_task: cut.parameters[0].activeValues.map(x => x.id)
        })
        // this.saveVideoEditorHistory();
      } else {
        this.parameters[0].activeValues.splice(ind, 1)
        this.form.patchValue({
          create_parameter_values_to_task: this.parameters[0].activeValues.map(x => x.id)
        })
      }
    }
  }

  hasValue() {
    if (!this.hasParts()) {
      return false
    }

    let parts = (this.data.tracks.filter(x => x.type == 'video').map(k => k.parts) as any).flat(Infinity)

    return !!parts.filter(x => !!x.is_audio_only).length
  }

  onSearchTaskStatuses(resp) {
    if (!this.taskStatuses) {
      return;
    }

    if (!resp) {
      this.taskStatuses$.next(this.taskStatuses.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.taskStatuses$.next(
      this.taskStatuses.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  onSearchEmoloyees(resp) {
    if (!this.employees) {
      return;
    }

    if (!resp) {
      this.employees$.next(this.employees.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.employees$.next(
      this.employees.filter(z => z.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  onSearchOperations(resp) {
    if (!this.operations) {
      return;
    }

    if (!resp) {
      this.operations$.next(this.operations.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.operations$.next(
      this.operations.filter(z => (z.name.toLowerCase().indexOf(resp) > -1))
    );
  }

  getCircularReplacer() {
    const ancestors:any = [];
    return function (key, value) {
      if (typeof value !== "object" || value === null) {
        return value;
      }
      // `this` is the object that value is contained in,
      // i.e., its direct parent.
      while (ancestors.length > 0 && ancestors.at(-1) !== this) {
        ancestors.pop();
      }
      if (ancestors.includes(value)) {
        return "[Circular]";
      }
      ancestors.push(value);
      return value;
    };
  }

  onSearchGroups(resp) {
    if (!this.groups) {
      return;
    }

    if (!resp) {
      this.groups$.next(this.groups.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.groups$.next(
      this.groups.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  onSearchProfiles(resp) {
    if (!this.profiles) {
      return;
    }

    if (!resp) {
      this.profiles$.next(this.profiles.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }

    // filter the banks
    this.profiles$.next(
      this.profiles.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  getChannelById(id) {
    if (!this.profiles || this.profiles.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.profiles.find(el => el.id == id)
  }

  getTypeById(id) {
    if (!this.data.forAllContentTypes || this.data.forAllContentTypes.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.data.forAllContentTypes.find(el => el.id == id)
  }

  hasParts() {
    return !!this.data.tracks.filter(trackEl => trackEl.parts.length > 1) || this.data.projectHasParts
  }

  getOperationById(id) {
    if (!this.operations || this.operations.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.operations.find(el => el.id == id)
  }

  getGroupById(id) {
    if (!this.groups || this.groups.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.groups.find(el => el.id == id)
  }

  getJobStatusById(id) {
    if (!this.data.statuses || this.data.statuses.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.data.statuses.find(el => el.id == id)
  }

  getStatusById(id) {
    if (!this.taskStatuses || this.taskStatuses.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.taskStatuses.find(el => el.id == id)
  }

  getVideoParts() {
    let parts = (this.data.tracks.filter(x => x.type == 'video').map(k => k.parts) as any).flat(Infinity)
    return parts
  }

  checkboxLabel(cut?): string {
    if (!cut) {
      return `${this.isAllSelected() ? 'Deselect' : 'Select'} all`;
    }
    return `${(!!cut.is_audio_only) ? 'Deselect' : 'Select'} ${cut.name}`;
  }

  isAllSelected() {
    let parts = (this.data.tracks.filter(x => x.type == 'video').map(k => k.parts) as any).flat(Infinity)
    const numSelected = parts.filter(x => !!x.is_audio_only).length;
    const numRows = parts.length;
    return numSelected === numRows;
  }

  masterToggle() {
    let parts = (this.data.tracks.filter(x => x.type == 'video').map(k => k.parts) as any).flat(Infinity)
    this.isAllSelected() ?
        parts.map(x => x.is_audio_only = 0):
        parts.map(x => x.is_audio_only = 1);
  }

  hasValueVid() {
    if (!this.hasParts()) {
      return false
    }

    let parts = (this.data.tracks.filter(x => x.type == 'video').map(k => k.parts) as any).flat(Infinity)

    return !!parts.filter(x => !!x.is_video_only).length
  }

  checkboxLabelVid(cut?): string {
    if (!cut) {
      return `${this.isAllSelected() ? 'Deselect' : 'Select'} all`;
    }
    return `${(!!cut.is_video_only) ? 'Deselect' : 'Select'} ${cut.name}`;
  }

  isAllSelectedVid() {
    let parts = (this.data.tracks.filter(x => x.type == 'video').map(k => k.parts) as any).flat(Infinity)
    const numSelected = parts.filter(x => !!x.is_video_only).length;
    const numRows = parts.length;
    return numSelected === numRows;
  }

  masterToggleVid() {
    let parts = (this.data.tracks.filter(x => x.type == 'video').map(k => k.parts) as any).flat(Infinity)
    this.isAllSelectedVid() ?
        parts.map(x => x.is_video_only = 0):
        parts.map(x => x.is_video_only = 1);
  }

  cardTitleInput(cut?) {
    if (cut) {
      cut.is_input_set_ai = false
    } else {
      this.data.is_input_set_ai = false
    }
  }

  toggleControl(control, val) {
    control.patchValue(!!val ? false : true)
  }

  selectType(type, cut?) {
    console.log("selectType", type, cut);
    if (cut) {
      cut.form.patchValue({
        status_id: !!type.id ? type.id : type.value
      })
      cut.is_custom.patchValue(true)
      // this.saveVideoEditorHistory();
    } else {
      this.form.patchValue({
        status_id: !!type.id ? type.id : type.value
      })
    }
  }

  selectGroup(group, cut?) {
    // if (!this.data.isMulti) {
    //   this.getCustomId(!!group.id ? group.id : group.value);
    // }
    if (cut) {
      cut.form.patchValue({
        group_id: !!group.id ? group.id : group.value
      })
      cut.is_custom.patchValue(true)
      // this.saveVideoEditorHistory();
    } else {
      this.form.patchValue({
        group_id: !!group.id ? group.id : group.value
      })
    }
  }

  deletePublications(cut?) {
    if (cut) {
      cut.is_custom.patchValue(true)
      cut.publications = [];
      // this.saveVideoEditorHistory();
    } else {
      this.data.publications = [];
      let parts = (this.data.tracks.filter(x => x.type == 'video').map(k => k.parts) as any).flat(Infinity)
      parts.forEach((part, i) => {
        part.publications = [];
      });
      // this.saveVideoEditorHistory();
    }
  }

  addJob(cut?) {
    const dialogRef = this.dialog.open(WorkAddComponent, {
      disableClose: true,
      data: {
        user: this.data.user,
        company: this.data.company,
        company_id: this.data.company_id,
        is_ve: true,
        task: this.data.task
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        
        console.log("RESULT", result)
        if (!!result && result.event == "Add") {
          let jobData:any = {
            operation_id: result.data.form.operation_id,
            status_id: result.data.form.status_id,
            priority: result.data.form.priority,
            name: result.data.form.name,
            comment: result.data.form.comment,
            create_task_employees: [],
            create_task_partners: [],
            create_automation_scenarios: [],
            create_parameter_values_to_task: [],
            parameters: this.data.jobParameters.slice(),
            result: result
          }

          if (result.data.additional.employee_id) {
            result.data.additional.employee_id.forEach(id => {
              if (id) {
                jobData.create_task_employees.push({
                  employee_id: id,
                  is_manager: 0
                })
              }
            })
          }
          if (result.data.templateForm.template_id) {
            result.data.templateForm.template_id.forEach(id => {
              jobData.create_automation_scenarios.push(id)
            })
          }
          if (result.data.workEmpl.employee_id) {
            jobData.create_task_employees.push({
              employee_id: result.data.workEmpl.employee_id,
              is_manager: 1
            })
          }
          if (result.data.partnerForm.partner_company_id) {
            jobData.create_task_partners.push({
              partner_company_id: result.data.partnerForm.partner_company_id,
              is_manager: 1
            })
          }

          jobData.mobEmployees = []
          let empl = JSON.parse(JSON.stringify(jobData.create_task_employees))
          let pEmpl = JSON.parse(JSON.stringify(jobData.create_task_partners))
          empl.map(x => x.employee = this.getEmployeeById(x.employee_id))
          pEmpl.map(x => {
            x.is_partner = true;
            x.partnerCompany = this.getPartner(x.partner_company_id).partnerCompany;
          })
          jobData.mobEmployees.push(...empl, ...pEmpl)
          
          if (cut) {
            if (!cut.hasOwnProperty('jobs')) {
              cut.jobs = []
            }
            cut.is_custom.patchValue(true)
            cut.jobs.push(jobData)
          } else {
            this.data.jobs.push(jobData)
            this.updateJobs()
          }
        }
      })
    )
  }

  updateJobs() {
    let parts = (this.data.tracks.filter(x => x.type == 'video').map(k => k.parts) as any).flat(Infinity)
    parts.filter(cut => !cut.is_custom.value).forEach(cut => {
      cut.jobs = JSON.parse(JSON.stringify(this.data.jobs, this.getCircularReplacer()))
    })
    console.log("updateJobs", parts);
  }

  getPartner(id) {
    if (!this.data.partners) {
      return false;
    }
    return this.data.partners.find(el => el.partner_company_id == id)
  }  

  getEmployeeById(id) {
    return this.employees.find(x => x.id == id)
  }

  openTargetValues(cut?, job?) {
    let initData:any = {
      company: this.data.company,
      auto: true,
      parameters: !!job ? job.parameters : (!!cut ? cut.parameters : this.parameters)
    }

    const dialogRef = this.dialog.open(TargetParametersComponent, {
      backdropClass: ['parameters_modal_backdrop'],
      panelClass: ['without_paddings_modal', 'parameters_modal'],
      data: initData
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result) {
          if (result.event == 'save') {
            if (job) {
              if (cut) {
                job.parameters = result.data
                cut.is_custom.patchValue(true)
                // this.saveVideoEditorHistory();
              } else {
                job.parameters = result.data
                this.updateJobs()
              }
            } else {
              if (cut) {
                cut.parameters = result.data
                cut.form.patchValue({
                  create_parameter_values_to_task: cut.parameters[0].activeValues.map(x => x.id)
                })
                cut.is_custom.patchValue(true)
                // this.saveVideoEditorHistory();
              } else {
                this.parameters = result.data
                this.form.patchValue({
                  create_parameter_values_to_task: this.parameters[0].activeValues.map(x => x.id)
                })
              }
            }

          }
        }
      })
    )
  }

  getItemById(arr, id) {
    return arr.find(x => x.id == id)
  }

  selectTmpl(e, cut?) {
    console.log(e, cut)
    if (cut) {
      if (e.value === '') {
        cut.form.patchValue({
          type: 0
        })
      } else {
        let tmpl = this.taskTemplates.find(x => x.id == e.value)
        cut.form.patchValue({
          type: 1,
          name: tmpl.template_data.name
        })
      }
      cut.is_custom.patchValue(true)
      cut.is_input_set_ai = false;
      // this.saveVideoEditorHistory();
    } else {
      console.log('e.value', e.value);
      if (e.value === '') {
        this.form.patchValue({
          type: 0
        })
      } else {
        let tmpl = this.taskTemplates.find(x => x.id == e.value)
        this.form.patchValue({
          name: tmpl.template_data.name,
          type: 1
        })
      }
      this.data.is_input_set_ai = false;
    }
  }

  close() {
    this.dialogRef.removePanelClass('animate__slideInLeft')
    this.dialogRef.addPanelClass('animate__slideOutLeft')
    setTimeout(()=>{this.dialogRef.close({event: "close", data: this.form.value, publications: this.data.publications.map(x => x && x.publData ? x.publData : null)})}, 300);
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

}
