import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import * as moment from 'moment';
import { ReplaySubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { BaseClass } from 'src/app/shared/models/base-class';
import { GlobalDataService } from 'src/app/shared/services/common/global-data.service';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { MembersService } from 'src/app/shared/services/rest/members.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-multi-delete-tags',
  templateUrl: './multi-delete-tags.component.html',
  styleUrls: ['./multi-delete-tags.component.scss']
})
export class MultiDeleteTagsComponent extends BaseClass implements OnInit, OnDestroy {
  public deleteForm: FormGroup;
  public isSubmit: boolean = false;
  public submited: boolean = false;
  public currencyList: any;
  public partners: any;
  public imgRoute: any = '';
  public host: any = environment.host;

  public tagsAssignedEarlyer: any;

  public activeIndex = new FormControl(0);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<MultiDeleteTagsComponent>,
    private taskService: TaskService,
    private fb: FormBuilder,
    private layoutService: LayoutService,
  ) { super() }

  ngOnInit(): void {
    this.dialogRef.addPanelClass("adding_executors_dialog")
    console.log("MultiDeleteTagsComponent", this.data);
    
    this.deleteForm = this.fb.group({
      parameter_value_id: [[]],
    })


    this.tagsAssignedEarlyer = this.getUniqueTagsAssignedEarlyer();
    console.log("tagsAssignedEarlyer", this.tagsAssignedEarlyer)

  }

  getUniqueTagsAssignedEarlyer() {
    const uniqueEmployees = [];
    const seenIds = new Set();
  
    if (this.data.selectedChats) {
      this.data.selectedChats.forEach(obj => {
        obj.parameterValuesToTask.forEach(tag => {
          if (!!tag.parameter && tag.parameterValue && !seenIds.has(tag.parameter_value_id)) {
            seenIds.add(tag.parameter_value_id);
            uniqueEmployees.push(tag);
          }
        });
      });
    } else if (this.data.selectedJobs) {
      this.data.selectedJobs.forEach(obj => {
        obj.parameterValuesToTask.forEach(tag => {
          if (!!tag.parameter && tag.parameterValue && !seenIds.has(tag.parameter_value_id)) {
            seenIds.add(tag.parameter_value_id);
            uniqueEmployees.push(tag);
          }
        });
      });
    }
  
    return uniqueEmployees;
  }

  toggleChip = (id: any, key, formName: string = 'form') => {
    if (!!this[formName].value[key] && this[formName].value[key].includes(id)) {
      let val = [...this[formName].value[key]]
      val.splice(this[formName].value[key].indexOf(id), 1)
      this[formName].patchValue({
        [key]: val
      })
    } else {
      if (!!this[formName].value[key]) {
        let val = [...this[formName].value[key]]
        val.push(id)
        this[formName].patchValue({
          [key]: [...val]
        })
      } else {
        this[formName].patchValue({
          [key]: [id]
        })
      }
    }
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  onDelete() {
    console.log(this.deleteForm.value)
    if (!this.deleteForm.value.parameter_value_id.length) {
      this.submited = true;
      this.layoutService.showSnackBar({name: ''}, marker("You need to choose tag(s)"), SnackBarItem)
      return
    }

    this.submited = false;
    this.isSubmit = true;


    let deleteData = [];
    if (this.data.selectedChats) {
      this.data.selectedChats.forEach(chat => {
        if (!!this.deleteForm.value.parameter_value_id && this.deleteForm.value.parameter_value_id.length) {
          if (chat.company_id != this.data.company.id) {
            this.deleteForm.value.parameter_value_id.forEach(id => {
              if (!!chat.parameterValuesToTask && chat.parameterValuesToTask.filter(b => b.parameter_value_id == id).length != 0) {
                let tag = chat.parameterValuesToTask.find(x => x.parameter_value_id == id)
                deleteData.push({
                  "path": `/api/parameter-value-to-task/${tag.id}/`,
                  "query": {company_id: this.data.company.id},
                  "method": "DELETE"
                })
              }
            });
          } else {
            this.deleteForm.value.parameter_value_id.forEach(id => {
              if (!!chat.parameterValuesToTask && chat.parameterValuesToTask.filter(b => b.parameter_value_id == id).length != 0) {
                let tag = chat.parameterValuesToTask.find(x => x.parameter_value_id == id)
                deleteData.push({
                  "path": `/api/parameter-value-to-task/${tag.id}/`,
                  "query": {company_id: this.data.company.id},
                  "method": "DELETE" 
                })
              }
            });
          }
        }
  
      })
    } else if (this.data.selectedJobs) {
      this.data.selectedJobs.forEach(job => {
        if (!!this.deleteForm.value.parameter_value_id && this.deleteForm.value.parameter_value_id.length) {
          this.deleteForm.value.parameter_value_id.forEach(id => {
            if (!!job.parameterValuesToTask && job.parameterValuesToTask.filter(b => b.parameter_value_id == id).length != 0) {
              let tag = job.parameterValuesToTask.find(x => x.parameter_value_id == id)
              deleteData.push({
                "path": `/api/parameter-value-to-task/${tag.id}/`,
                "query": {company_id: this.data.company.id},
                "method": "DELETE" 
              })
            }
          });
        }
      })
    }

    if (deleteData.length) {
      this.attachSubscriptions(
        this.taskService.multiRequest(deleteData).subscribe(resp => {
          console.log("multiRequest", resp)
          this.isSubmit = false;
          this.dialogRef.close({event: "change", data: resp})
        })
      )
    } else {
      this.close();
    }
    
  }

  ngOnDestroy(): void {
    this.clearSubscriptions();    
  }

}
