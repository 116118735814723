<h2 mat-dialog-title class="dialog_title">
    <span>
        {{ "Variable" | translate }}
    </span>
</h2>
<mat-dialog-content class="mat-typography" dirEnter (dirSubmit)="!form.valid ? null : submitForm()">
    <div class="container form_wrapper" style="padding: 20px 0;">
        <form [formGroup]="form">
            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ "Choose a Source" | translate }}</mat-label>
                <mat-select formControlName="source" required>
                    <mat-option *ngFor="let source of sources" [value]="source.value">
                        {{ source.name | translate }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <ng-container *ngIf="form.get('source').value == '$employee.application.'">
                <mat-form-field appearance="standard" class="full_width_form">
                    <mat-label>{{ "Choose a Form" | translate }}</mat-label>
                    <mat-select formControlName="form_id" required>
                        <mat-option *ngFor="let frm of forms" [value]="frm.id">
                            {{ frm.name | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
    
                <mat-form-field appearance="standard" class="full_width_form">
                    <mat-label>{{ "Choose a Field" | translate }}</mat-label>
                    <mat-select formControlName="field" required>
                        <mat-option *ngFor="let field of fields" [value]="field.id">
                            {{ field.name | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-progress-bar *ngIf="isGettingFields" mode="indeterminate"></mat-progress-bar>

                <mat-form-field appearance="standard" class="full_width_form" *ngIf="form.get('date_format')">
                    <mat-label>{{ "Choose a format" | translate }}</mat-label>
                    <mat-select formControlName="date_format" required>
                        <mat-option [value]="'%m/%d/%Y'">
                            MM/DD/YYYY
                        </mat-option>
                        <mat-option [value]="'%m.%d.%Y'">
                            MM.DD.YYYY
                        </mat-option>
                        <mat-option [value]="'%d/%m/%Y'">
                            DD/MM/YYYY 
                        </mat-option>
                        <mat-option [value]="'%d.%m.%Y'">
                            DD.MM.YYYY 
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </ng-container>
            
            <ng-container *ngIf="form.get('source').value == '$statement'">
                <mat-form-field appearance="standard" class="full_width_form">
                    <mat-label>{{ "Choose a field" | translate }}</mat-label>
                    <mat-select formControlName="statement_field" required>
                        <mat-option *ngFor="let item of stFields" [value]="item.value">
                            {{ item.name | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </ng-container>

            <ng-container *ngIf="form.get('source').value == '$statement.summaries'">
                <mat-form-field appearance="standard" class="full_width_form">
                    <mat-label>{{ "Choose a field" | translate }}</mat-label>
                    <mat-select formControlName="statement_field" required>
                        <mat-option *ngFor="let item of statementFields" [value]="item.value">
                            {{ item.name | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </ng-container>
            

        </form>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button (click)="close()">
        {{ "Cancel" | translate }}
    </button>
    <button class="ml-1 d-f-btn" (click)="submitForm()" mat-raised-button cdkFocusInitial color="primary" [disabled]="!form.valid">
        {{ "Save" | translate }}
    </button>
</mat-dialog-actions>
