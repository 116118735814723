import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { BaseClass } from '../../models/base-class';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { TaskService } from '../../services/rest/task.service';
import { LanguageService } from '../../services/common/language.service';
import { switchMap, tap } from 'rxjs/operators';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as moment from 'moment';
import { prioritys } from '../../consts/prioritys';
import { Subscription } from 'rxjs';
import { GlobalDataService } from '../../services/common/global-data.service';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MY_FORMATS } from 'src/app/components/workspace-pages/cases/dialogs/open-task/dialogs/channels-table/channels-table.component';

@Component({
  selector: 'app-active-filters',
  templateUrl: './active-filters.component.html',
  styleUrls: ['./active-filters.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})

export class ActiveFiltersComponent extends BaseClass implements OnInit, OnDestroy {
  @Input() company_id: any;
  @Input() filter: any;
  @Input() filterValue: any;
  @Input() section: string = '';
  @Output() RemoveValue = new EventEmitter<any>();
  @Output() RemoveValueFromMultiple = new EventEmitter<any>();

  public currMonthName: any;
  public activeLang: any;
  public taskStatuses: any;
  public employees: any = [];
  public prioritys = prioritys;

  public allContentTypes: any;
  public clients: any;
  public viewsUpdateIntervalList: any;
  public partners: any;
  public allValues: any;
  public statuses: any;
  public checklists: any;
  public partnerGroups: any;
  public jobTypes: any; 
  public groups: any; 
  public platforms: any;
  public profiles: any;
  public profileStatuses: any; 
  public contentUrl: any = [
    {
      name: marker("Without content url"),
      id: 0
    },
    {
      name: marker("With content url"),
      id: 1
    }
  ]

  public employeesSub: Subscription;

  constructor(
    private languageService: LanguageService,
    private taskService: TaskService,
    private globalDataService: GlobalDataService,
    private _adapter: DateAdapter<any>,
  ) { 
    super() 
  }

  ngOnInit(): void {
    console.log('ActiveFiltersComponent onINIT filter', this.filter);
    this.getLangData();
    this.attachSubscriptions(
      this.globalDataService.getTransferData().subscribe(resp => {
        console.log("ActiveFiltersComponent getTransferData", resp)

        if (resp.allContentTypes) {
          this.allContentTypes = resp.allContentTypes;
        }
        if (resp.employees) {
          this.employees = resp.employees;
        }
        if (resp.clients) {
          this.clients = resp.clients;
        }
        if (resp.viewsUpdateIntervalList) {
          this.viewsUpdateIntervalList = resp.viewsUpdateIntervalList;
        }
        if (resp.partners) {
          this.partners = resp.partners;
        }
        if (resp.allValues) {
          this.allValues = resp.allValues;
        }
        if (resp.allValues) {
          this.allValues = resp.allValues;
        }
        if (resp.statuses) {
          this.statuses = resp.statuses;
        }
        if (resp.checklists) {
          this.checklists = resp.checklists;
        }
        if (resp.taskStatuses) {
          this.taskStatuses = resp.taskStatuses;
        }
        if (resp.jobTypes) {
          this.jobTypes = resp.jobTypes;
        }
        if (resp.partnerGroups) {
          this.partnerGroups = resp.partnerGroups;
        }
        if (resp.groups) {
          this.groups = resp.groups;
        }
        if (resp.prioritys) {
          this.prioritys = resp.prioritys;
        }
        if (resp.platforms) {
          this.platforms = resp.platforms;
        }
        if (resp.profiles) {
          this.profiles = resp.profiles;
        }
        if (resp.profileStatuses) {
          this.profileStatuses = resp.profileStatuses;
        }
        if (resp.contentUrl) {
          this.contentUrl = resp.contentUrl;
        }
      })
    )
  }

  getChecklist(id) {
    if (!this.checklists || !this.checklists.find(el => el.id == id)) {
      return false;
    }
    return this.checklists.find(el => el.id == id)
  }  

  getStatus(id) {
    if (!this.statuses) {
      return false;
    }
    return this.statuses.find(el => el.id == id)
  }  
  
  getViewsUpdateIntervalListByValue(val) {
    if (!this.viewsUpdateIntervalList || this.viewsUpdateIntervalList.length == 0) {
      return null
    }
    return this.viewsUpdateIntervalList.find(x => x.value == val)
  }
  
  // ngOnChanges(changes: SimpleChanges): void {
  //   console.log('ActiveFiltersComponent changes', changes)
  //   // if (changes.filterValue && changes.filterValue.currentValue && changes.filterValue.currentValue.operation_employee_id && changes.filterValue.currentValue.operation_employee_id.length) {
  //   //   this.employeesSub = this
  //   // }
  // }

  getGroup(id) {
    if (!this.groups || !this.groups.find(el => el.id == id)) {
      return false;
    }
    return this.groups.find(el => el.id == id)
  }  

  getPartnerGroup(id) {
    if (!this.partnerGroups || !this.partnerGroups.find(el => el.id == id)) {
      return false;
    }
    return this.partnerGroups.find(el => el.id == id)
  }  

  getOperationStatusName(id) {
    switch (+id) {
      case 1:
        return "Waiting";
        break;
      case 2:
        return "In process";
        break;
      case 3:
        return "To approve";
        break;
      case 4:
        return "Approved";
        break;
      case 5:
        return "To correct";
        break;
      case 6:
        return "Sources Needed";
        break;
      case 97:
        return "On hold";
        break;
      case 98:
        return "Ready";
        break;
      case 99:
        return "Canceled";
        break;
    }
  }

  getFilterOperationStatusName(id) {
    if (typeof id == "object") {

      if (id.sort().toString() == '1,2,5') {
        return 'All to do'
      } else if (id.sort().toString() == '3,4,6,97,98,99') {
        return 'All done'
      } else {
        let text = []
        id.forEach(x => {
          text.push(this.getOperationStatusName(x))
        })
        return text.join(", ")
      }
    } else {
      switch (+id) {
        case 1:
          return "Waiting";
          break;
        case 2:
          return "In process";
          break;
        case 3:
          return "To approve";
          break;
        case 4:
          return "Approved";
          break;
        case 5:
          return "To correct";
          break;
        case 6:
          return "Sources Needed";
          break;
        case 97:
          return "On hold";
          break;
        case 98:
          return "Ready";
          break;
        case 99:
          return "Canceled";
          break;
      }
    }
  }

  getProfileStatus(id) {
    if (!this.profileStatuses) {
      return false;
    }
    return this.profileStatuses.find(el => el.id == id)
  }  

  getPartner(id) {
    if (!this.partners) {
      return false;
    }
    return this.partners.find(el => el.partner_company_id == id)
  }  

  getValueById(id) {
    if (!this.allValues || this.allValues.length == 0) {
      return null
    }
    return this.allValues.find(x => x.id == id)
  }
    
  getProfile(id) {
    if (!this.profiles) {
      return false;
    }
    return this.profiles.find(el => el.id == id)
  } 

  getColumnName(id) {
    if (!this.jobTypes || this.jobTypes.filter(el => el.id == id).length == 0) {
      return '';
    }
    return this.jobTypes.find(el => el.id == id)?.translation?.name ? this.jobTypes.find(el => el.id == id).translation.name : this.jobTypes.find(el => el.id == id).name
  }

  getContentById(id) {
    if (!this.allContentTypes || this.allContentTypes.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.allContentTypes.find(el => el.id == id)
  }

  getPlatformById(id) {
    if (!this.platforms || this.platforms.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.platforms.find(el => el.id == id)
  }

  getEmployee(id) {
    if (!this.employees) {
      return false;
    }
    return this.employees.find(el => el.id == id)
  }  

  getPriority(id) {
    if (!this.prioritys) {
      return false;
    }
    return this.prioritys.find(el => el.id == id)
  } 

  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData().pipe(
        tap(resp => {
          this.activeLang = resp.active;
          this._adapter.setLocale(resp.active);
          this.currMonthName = moment().format('MMMM');
        }),
      ).subscribe(resp => {
        console.log("getLangData", resp)
      })
    )
  }

  log() {
    console.log('ActiveFiltersComponent log filter', this.filter)
    console.log('ActiveFiltersComponent log groups', this.groups)
  }

  getTaskStatusById(id) {
    if (!this.taskStatuses || this.taskStatuses.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.taskStatuses.find(el => el.id == id)
  }

  removeValue(val) {
    this.RemoveValue.emit(val)
  }
  
  removeValueFromMultiple(key, id) {
    this.RemoveValueFromMultiple.emit({key: key, id: id})
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

}
