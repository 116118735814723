import { Directive, ElementRef, EventEmitter, OnDestroy, Output } from "@angular/core";
import { fromEvent, merge, of, Subscription, timer } from "rxjs";
import { filter, map, switchMap, tap } from "rxjs/operators";

@Directive({
  selector: "[longPress]",
})
export class LongPressDirective implements OnDestroy {
  private eventSubscribe: Subscription;
  private touchMoveDetected = false;
  private touchTimeout: any;
  threshold = 500; // Время задержки (лонгтап)

  @Output()
  mouseLongPress = new EventEmitter<{ x: number; y: number; event: MouseEvent | TouchEvent }>();

  constructor(private elementRef: ElementRef) {
    const el = this.elementRef.nativeElement;

    // Отключаем стандартное контекстное меню на мобильных
    fromEvent<MouseEvent>(el, "contextmenu")
      .pipe(filter(() => this.isMobile()))
      .subscribe(event => {
        event.preventDefault();
        event.stopPropagation();
        event.stopImmediatePropagation();
    });

    // Отключение контекстного меню для mat-icon
    fromEvent<MouseEvent>(el.querySelector('mat-icon'), 'contextmenu')
      .pipe(filter(() => this.isMobile()))
      .subscribe(event => {
        event.preventDefault();
        event.stopPropagation();
        event.stopImmediatePropagation();
    });

    /** --- ОБРАБОТЧИК ЛОНГТАПА ДЛЯ TOUCH (МОБИЛЬНЫЕ) --- */
    const touchstart = fromEvent<TouchEvent>(el, "touchstart").pipe(
      tap((event) => {
        this.touchMoveDetected = false;
        this.touchTimeout = setTimeout(() => {
          if (!this.touchMoveDetected) {
            // alert('mouseLongPress emit'); 
            const touch = event.touches[0];
            this.mouseLongPress.emit({ x: touch.clientX, y: touch.clientY, event });
          }
        }, this.threshold);
      })
    );

    const touchMove = fromEvent<TouchEvent>(el, "touchmove").pipe(
      tap(() => {
        clearTimeout(this.touchTimeout); // Отменяем Long Press при скролле
        this.touchMoveDetected = true;
      })
    );

    const touchEnd = fromEvent<TouchEvent>(el, "touchend").pipe(
      tap(() => clearTimeout(this.touchTimeout)) // Очистка таймера при отпускании
    );

    /** --- ОБРАБОТЧИК ЛОНГТАПА ДЛЯ МЫШИ (ПК) --- */
    const mousedown = fromEvent<MouseEvent>(el, "mousedown").pipe(
      filter(event => event.button === 0), // Только левая кнопка
      tap(event => {
        this.touchMoveDetected = false;
        this.touchTimeout = setTimeout(() => {
          if (!this.touchMoveDetected) {
            this.mouseLongPress.emit({ x: event.clientX, y: event.clientY, event });
          }
        }, this.threshold);
      })
    );

    const mouseup = fromEvent<MouseEvent>(el, "mouseup").pipe(
      tap(() => clearTimeout(this.touchTimeout))
    );

    this.eventSubscribe = merge(touchstart, touchMove, touchEnd, mousedown, mouseup)
      .subscribe();
  }

  private isMobile(): boolean {
    return /Android|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i.test(navigator.userAgent);
  }

  ngOnDestroy(): void {
    this.eventSubscribe?.unsubscribe();
  }
}