<mat-dialog-content class="mat-typography">
    <span class="head_title" (click)="log()">
        <b style="margin-right: 3.5px;" *ngIf="cards">{{"Clean" | translate}} {{cards.length}} {{"cards" | translate}}</b>
        <div class="table_progress_wrp" [ngClass]="{'hidded': progress >= 100}">
            <mat-progress-bar
                class="table_progress"
                [color]="'primary'"
                [mode]="'determinate'"
                [value]="progress">
            </mat-progress-bar>
            <span class="percent">{{progress}}%</span>
        </div>
    </span>
    <form [formGroup]="filter" class="autos_fil d-f-c form_with_pads form_with_valids" style="justify-content: space-between;">
        <div class="d-f-c">
            <mat-form-field appearance="legacy" class="white_legacy_select" style="width: 150px;">
                <mat-label>{{"Group" | translate}}</mat-label>
                <mat-select formControlName="group_id">
                    <mat-option>
                        <ngx-mat-select-search 
                        placeholderLabel="{{ 'Search' | translate }}" 
                        noEntriesFoundLabel="{{ 'no matching group found' | translate }}"
                        [formControl]="groupMoreControl"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let group of groups$ | async" [value]="group.id">
                        {{ group.name | translate }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            
            <mat-form-field appearance="legacy" class="white_legacy_select ml-1">
                <mat-label>{{ 'Cards created' | translate }}</mat-label>
                <mat-date-range-input  [rangePicker]="rangePicker">
                  <input formControlName="task_created_at_from" matStartDate placeholder="{{ 'Created from' | translate }}">
                  <input formControlName="task_created_at_to" matEndDate placeholder="{{ 'Created to' | translate }}">
                </mat-date-range-input>
                <mat-datepicker-toggle matPrefix [for]="rangePicker"></mat-datepicker-toggle>
                <mat-date-range-picker #rangePicker>
                  <mat-date-range-picker-actions>
                    <button mat-button matDateRangePickerCancel>Cancel</button>
                    <button mat-raised-button color="primary" matDateRangePickerApply>Apply</button>
                  </mat-date-range-picker-actions>
                </mat-date-range-picker>
            </mat-form-field>
        </div>


        <div class="d-f-c" style="margin-left: 100px;">
            <mat-form-field appearance="legacy" class="white_legacy_select">
                <mat-select [disableOptionCentering]="true" selectDir formControlName="platform_id" placeholder="{{ 'Platform' | translate}}">
                    <mat-select-trigger *ngIf="filter.get('platform_id').value">
                        <div class="d-f-c select_trigger">
                            <div class="mr-1">
                                <app-platform [platform_id]="filter.get('platform_id').value"></app-platform>
                            </div>
                            {{ getPlatformById(filter.get('platform_id').value)?.name }}
                        </div>
                    </mat-select-trigger>
                    <mat-option>
                        <ngx-mat-select-search 
                        placeholderLabel="{{ 'Search' | translate }}" 
                        noEntriesFoundLabel="{{ 'no matching platforms found' | translate }}"
                        [formControl]="platformsMoreControl"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let platform of platforms$ | async" [value]="platform.id">
                        <div class="platform_wrp">
                            <app-platform [platform_id]="platform.id"></app-platform>
                            <span class="ml-1">{{ platform.name }}</span>
                        </div>
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="legacy" class="white_legacy_select" *ngIf="parameters" style="color: #fff;">
                <mat-label>{{ "Tag category" | translate }}</mat-label>
                <mat-select [disableOptionCentering]="true" [multiple]="false" selectDir formControlName="parameter_id">
                  <mat-option>
                    <ngx-mat-select-search 
                        placeholderLabel="{{ 'Search' | translate }}" 
                        noEntriesFoundLabel="{{ 'no matching tag found' | translate }}"
                        [formControl]="parametersCtrl"></ngx-mat-select-search>
                  </mat-option>
                  <mat-option *ngFor="let parameter of parameters$ | async" [value]="parameter.id">
                    {{parameter.name}}
                  </mat-option>
                </mat-select>
            </mat-form-field>

            <button mat-raised-button style="color: #fff;" class="ml-1" color="primary" (click)="filterCards()" [disabled]="isLoad" #tooltip="matTooltip"
            matTooltip="{{ 'Get all cards and posts' | translate }}"
            [matTooltipPosition]="'above'">
                {{ "Get Data" | translate }}
            </button>
        </div>
    </form>
    <div class="autos" [appDeviceClass]="'custom-scroll custom-scroll-t'">
        <ng-container *ngIf="cards">
            <div class="items">      
                <div class="items_line head_line">
                    <div class="item post_item">
                        <div class="item_top">
                            <div class="item_name text_2_line">
                                {{"Card" | translate}}
                            </div>
                        </div>
                    </div>
                    <!-- <div class="item count_item">
                        <div class="waiting">{{"Waiting posts" | translate}}</div>
                    </div>
                    <div class="item count_item">
                        <div class="no_waiting">{{"No Waiting posts" | translate}}</div>
                    </div> -->
                </div>
                <ng-container *ngFor="let el of cards; let i = index">
                    <div class="items_line" [ngClass]="{'selected': eltsCollection.isSelected(el)}" (click)="elClick($event, el)">
                        <div class="item post_item">
                            <div class="item_top">
                                <div class="item_name text_2_line">
                                    {{el?.name}}
                                </div>
                            </div>
                            <div class="item_bottom">
                                <div class="id cp" (click)="$event.preventDefault(); $event.stopPropagation();" ngxClipboard [cbContent]="el.custom_id" (cbOnSuccess)="copyLiveData('Card custom id')">
                                    {{el.custom_id}}
                                </div>
                            </div>
                        </div>
                        <ng-container *ngIf="el.postsByTags">
                            <div class="item tag_item" *ngFor="let item of el.postsByTags">
                                <div class="item_top">
                                    <div class="item_name text_2_line">
                                        {{item?.tag?.parameterValue?.value}}
                                    </div>
                                </div>
                                <div class="item_bottom">
                                    <div class="waiting" (click)="logData(item?.waiting_posts)">{{item?.waiting_posts.length}}</div>
                                    <div class="no_waiting" (click)="logData(item?.no_waiting_posts)">{{item?.no_waiting_posts.length}}</div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>            
            </div>
        </ng-container>
        
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <div class="bottom_btns">
        <div class="d-f-c">
            <mat-checkbox  style="color: #fff;" class="select_all_checkbox" *ngIf="eltsCollection && cards" (click)="$event.preventDefault; $event.stopPropagation()" (change)="$event ? masterToggle() : null"
            [checked]="eltsCollection.hasValue() && isAllSelected()"
            [indeterminate]="eltsCollection.hasValue() && !isAllSelected()"
            [aria-label]="checkboxLabel()">
                <span>{{ checkboxLabel() | translate }}</span>
            </mat-checkbox>
        </div>
        <div class="d-f-c">

            <button mat-raised-button (click)="close()">
                {{ "Close" | translate }}
            </button>

            <button class="ml-1" *ngIf="cards && cards.length" mat-raised-button color="warn" (click)="deleteProfiles()" [disabled]="isDeleting || isLoad">
                {{ "Delete" | translate}} {{ findDeletedPosts() ? findDeletedPosts().length : 0 }} {{ "redundant outlets from" | translate }} {{eltsCollection && eltsCollection.selected.length ? eltsCollection.selected.length : cards.length}} {{ "cards" | translate }}
            </button>
        </div>
    </div>
</mat-dialog-actions>