<mat-dialog-content class="mat-typography">
    <div class="container form_wrapper" [appDeviceClass]="'custom-scroll custom-scroll-t'">
        <div class="title_modal d-f-c">
            <span class="set_width">
                {{ "Position to employee" | translate }}:
            </span>
            <app-user [class]="'user_div ml-1'" [employee]="data?.employee"></app-user>
        </div>

        <!-- (infiniteScroll)="getNextDepartments()" -->
        <form [formGroup]="form" class="form_with_pads" (ngSubmit)="onSubmit()">

            <p>
                <mat-checkbox color="primary" formControlName="is_department_view">{{ "Sees everyone in the department." | translate }}</mat-checkbox>
            </p>

            <p>
                <mat-checkbox color="primary" formControlName="is_department_position_view">{{ "Sees everyone with the same position" | translate }}</mat-checkbox>
            </p>
            <p>
                <mat-checkbox color="primary" formControlName="is_department_manage_prices">{{ "Can change department prices" | translate }}</mat-checkbox>
            </p>
            <p>
                <mat-checkbox color="primary" formControlName="is_department_position_manage_prices">{{ "Can change position prices" | translate }}</mat-checkbox>
            </p>

            <mat-form-field appearance="legacy" class="full_width_form">
                <mat-label>{{ "Department" | translate }}</mat-label>
                <mat-select [disableOptionCentering]="true" selectDir formControlName="department_id">
                    <mat-option>
                    <ngx-mat-select-search 
                        placeholderLabel="{{ 'Search' | translate }}" 
                        noEntriesFoundLabel="{{ 'no matching departments found' | translate }}"
                        [formControl]="departmentsMoreControl"></ngx-mat-select-search>
                    </mat-option>                  
                    <mat-option *ngFor="let dep of departments$ | async" [value]="dep.id">
                        {{ dep.name }}
                    </mat-option>
                </mat-select>           
            </mat-form-field>

            <mat-form-field appearance="legacy" class="full_width_form">
                <mat-label>{{ "Position" | translate }}</mat-label>
                <mat-select [disableOptionCentering]="true" selectDir formControlName="department_position_id">
                    <mat-option>
                    <ngx-mat-select-search 
                        placeholderLabel="{{ 'Search' | translate }}" 
                        noEntriesFoundLabel="{{ 'no matching positions found' | translate }}"
                        [formControl]="positionsMoreControl"></ngx-mat-select-search>
                    </mat-option>                  
                    <mat-option *ngFor="let pos of positions$ | async" [value]="pos.id">
                        {{ pos.name }}
                    </mat-option>
                </mat-select>           
                <button class="suffix_button" [ngStyle]="{'color': (!form.get('department_id').value || !form.get('department_position_id').value || isSubmit) ? '#686868' : '#4E844E'}" [disabled]="!form.get('department_id').value || !form.get('department_position_id').value || isSubmit" type="submit" (click)="$event.stopPropagation()" mat-icon-button matSuffix>
                    <mat-icon>add_circle</mat-icon>
                </button>
            </mat-form-field>
        </form>

        <mat-nav-list *ngIf="positionsToEmployee?.length">
            <mat-list-item *ngFor="let posToEm of positionsToEmployee; let i = index;" class="data_out_item">
                <div class="data_item">
                    <div class="data_item_info">
                        <div class="department">{{posToEm.department.name}}</div>
                        <div class="position">{{posToEm.departmentPosition.name}}</div>
                        <div class="d-f-c">
                            <mat-icon (click)="editPosToEm(posToEm, 'is_department_view')" class="cp data_icon ml-1" [ngClass]="{'active': posToEm?.is_department_view}" #tooltip="matTooltip"
                            matTooltip="{{ posToEm?.is_department_view ? ('Can see everyone in the department.' | translate) : ('Can`t see everyone in the department.' | translate)}}"
                            [matTooltipPosition]="'below'">groups</mat-icon>
                            
                            <mat-icon (click)="editPosToEm(posToEm, 'is_department_position_view')" class="data_icon ml-1 cp" [ngClass]="{'active': posToEm?.is_department_position_view}" #tooltip="matTooltip" 
                            matTooltip="{{ posToEm?.is_department_position_view ? ('Can see everyone with the same position.' | translate) : ('Can`t see everyone with the same position.' | translate)}}"
                            [matTooltipPosition]="'below'">group</mat-icon>
                            
                            <mat-icon (click)="editPosToEm(posToEm, 'is_department_manage_prices')" class="data_icon ml-1 cp" [ngClass]="{'active': posToEm?.is_department_manage_prices}" #tooltip="matTooltip" 
                            matTooltip="{{ posToEm?.is_department_manage_prices ? ('Can change department prices' | translate) : ('Can`t change department prices' | translate)}}"
                            [matTooltipPosition]="'below'">{{!!posToEm?.is_department_manage_prices ? 'credit_score' : 'payment'}}</mat-icon>
                            
                            <mat-icon (click)="editPosToEm(posToEm, 'is_department_position_manage_prices')" class="data_icon ml-1 cp" [ngClass]="{'active': posToEm?.is_department_position_manage_prices}" #tooltip="matTooltip" 
                            matTooltip="{{ posToEm?.is_department_position_manage_prices ? ('Can change position prices' | translate) : ('Can`t change position prices' | translate)}}"
                            [matTooltipPosition]="'below'">{{!!posToEm?.is_department_position_manage_prices ? 'attach_money' : 'money_off'}}</mat-icon>

                        </div>
                    </div>
                    <mat-icon style="color: red;" (click)="deletePositionToEmployee(posToEm.id, i)">delete</mat-icon>
                </div>
            </mat-list-item>
        </mat-nav-list>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <div class="f_w_p_btns">
        <button mat-raised-button (click)="close()">
            {{ "Close" | translate }}
        </button>
    </div>
</mat-dialog-actions>