import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/shared/models/base-class';
import { PushNotificationsService } from 'src/app/shared/services/rest/push-notifications.service';

@Component({
  selector: 'app-test-page',
  templateUrl: './test-page.component.html',
  styleUrls: ['./test-page.component.scss']
})
export class TestPageComponent extends BaseClass implements OnInit, OnDestroy {
  public arr:any;
  constructor(
    private pushNotificationsService: PushNotificationsService,
  ) { super() }

  ngOnInit(): void {
    this.attachSubscriptions(
      this.pushNotificationsService.getSubscriptions().subscribe(resp => {
        this.arr = resp
      })
    )
  }

  triggerToSendNot(item) {
    this.attachSubscriptions(
      this.pushNotificationsService.sendNotification(item.id).subscribe(resp => {
        console.log(resp)
      })
    )
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

}
