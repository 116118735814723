import { element } from "protractor";
import { makeArray } from "./objToArray";

export function casesModel(arr, tasks, event) {
  console.log(arr)
  if (!arr || !arr[0]) {
    return {
      arr: arr,
      tasks: tasks
    }
  }
  
  arr.forEach(el => {
      el.jobEmployees = [];
      el.worksByTypeAndStatus = {};
      if (!!el.meta) {
        if (!!el.meta.task_channels_by_platform) {
          el.meta.task_channels_by_platform_arr = Object.values(el.meta.task_channels_by_platform).filter((x:any) => !!x.platform_id);
          el.meta.posts_waiting_count = 0;
          el.meta.posts_published_count = 0;

          el.meta.task_channels_by_platform_arr.forEach(p => {
            el.meta.posts_waiting_count += p.waiting;
            el.meta.posts_published_count += p.published;
          });
          el.meta.posts_count = el.meta.posts_waiting_count + el.meta.posts_published_count;

          el.meta.task_channels_by_platform_arr.push({
            platform_id: 0,
            waiting: el.meta.posts_waiting_count,
            published: el.meta.posts_published_count
          })
          
        }
      }
      if (!el.operations) {
          el.operations = []
      }
      if (!el.channels) {
          el.channels = []
      }
      el.previewImgs = [];

      el.operations.sort((a,b) => a.operation_id - b.operation_id);
      
      // el.channels = el.channels.filter(x => !!x.channel);
      el.channels.sort((a,b) => a.updated_at - b.updated_at);
      el.is_alert_checklist = el.operations.filter(x => x.is_reminder == 1).length > 0
      // el.consistOfTasks = []
      // el.partOfTasks = []
      // el.relatedTasks = []


      el.groupedParametersForTask = [];
      if (el.parameterValuesToTask) {

        el.parameterValuesToTask.forEach(element => {
          if (el.groupedParametersForTask.filter(x => x.parameterID == element.parameter_id).length == 0) {
            el.groupedParametersForTask.push({
              parameterID: element.parameter_id,
              parameterName: element.parameter.name,
              data: [element]
            })
          } else {
            el.groupedParametersForTask.find(x => x.parameterID == element.parameter_id).data.push(element)
          }
        });

        el.groupedParametersForTask.forEach(element => {
          if (element.data.length > 0) {
            element.groupedData = [
              {
                name: "T",
                uniqueData: unique(element.data.filter(y => y.discussion_id == 0 && y.file_id == 0 && y.task_operation_id == 0))
              },
              {
                name: "J",
                uniqueData: unique(element.data.filter(y => y.discussion_id == 0 && y.file_id == 0 && y.task_operation_id != 0))
              },
              {
                name: "C",
                uniqueData: unique(element.data.filter(y => y.discussion_id != 0 && y.file_id == 0 && y.task_operation_id != 0))
              },
              {
                name: "F",
                uniqueData: unique(element.data.filter(y => y.file_id != 0))
              },
            ]
          }
        })
      }
    
      // if (el.consistOfTaskPartition && el.consistOfTaskPartition.length) {
      //   el.consistOfTaskPartition.forEach(element => {
      //     let mode = {...element};
      //     delete mode.partOfTask;

      //     let u = element.partOfTask;
      //     if (u) {
      //       u.sortInfo = mode;
      //       u.previewImgs = [];
        
      //       // u.channels = u.channels.filter(x => !!x.channel);
      //       if (u.channels) {                
      //         u.channels.forEach(x => {
      //           if (!!x.file && !!x.file.original) {
      //             u.previewImgs.push({
      //                 is_parser_url: false,
      //                 src: x.file.original
      //               })
      //           } else if (!!x.content_image) {
      //             u.previewImgs.push({
      //               is_parser_url: true,
      //               src: x.content_image
      //             })
      //           }
      //         })
      //       } else {
      //         u.channels = []
      //       }
  
      //       el.consistOfTasks.push(u)
      //     }
      //   });
      // }

      // if (el.taskRelated && el.taskRelated.length) {
      //   el.taskRelated.forEach(element => {
      //     let mode = {...element};
      //     delete mode.relatedTask;

      //     let u = element.relatedTask;
      //     if (u) {
      //       u.sortInfo = mode;
      //       u.previewImgs = [];
        
      //       // u.channels = u.channels.filter(x => !!x.channel);
      //       if (u.channels) {
      //         u.channels.forEach(x => {
      //           if (!!x.file && !!x.file.original) {
      //             u.previewImgs.push({
      //                 is_parser_url: false,
      //                 src: x.file.original
      //               })
      //           } else if (!!x.content_image) {
      //             u.previewImgs.push({
      //               is_parser_url: true,
      //               src: x.content_image
      //             })
      //           }
      //         })
      //       } else {
      //         u.channels = []
      //       }
  
      //       el.relatedTasks.push(u)
      //     }
      //   });
      // }
      
      // if (el.partOfTaskPartition && el.partOfTaskPartition.length) {
      //   el.partOfTaskPartition.forEach(element => {
      //     let mode = {...element};
      //     delete mode.consistOfTask;

      //     let u = element.consistOfTask;
      //     if (u) {
      //       u.sortInfo = mode;
  
      //       u.previewImgs = [];
      //       // u.channels = u.channels.filter(x => !!x.channel);
      //       if (u.channels) {
      //         u.channels.forEach(x => {
      //           if (!!x.file && !!x.file.original) {
      //             u.previewImgs.push({
      //                 is_parser_url: false,
      //                 src: x.file.original
      //               })
      //           } else if (!!x.content_image) {
      //             u.previewImgs.push({
      //               is_parser_url: true,
      //               src: x.content_image
      //             })
      //           }
      //         })
      //       } else {
      //         u.channels = []
      //       }
  
      //       el.partOfTasks.push(u)
      //     }
      //   });
      // }

      el.channels.forEach(element => {
        if (!!element.file && !!element.file.original) {
          el.previewImgs.push({
              is_parser_url: false,
              src: element.file.original
            })
        } else if (!!element.content_image) {
          el.previewImgs.push({
            is_parser_url: true,
            src: element.content_image
          })
        }
      })

      el.operations.forEach(element => {
        if (!element.hasOwnProperty('partnerEmployees')) {
          element.partnerEmployees = []
        }
        if (!element.hasOwnProperty('employees')) {
          element.employees = []
        }
        if (!element.hasOwnProperty('partnerCompanies')) {
          element.partnerCompanies = []
        }
        element.partnerEmployees.forEach(empl => {
          empl.is_partner_employee = true;
          if (element.partnerEmployeesStatuses.filter(y => y.employee_id == empl.employee_id && y.task_operation_id == empl.task_operation_id).length > 0) {
            if (element.partnerEmployeesStatuses.filter(x => x.discussion_id == empl.discussion_id).length > 0) {
              empl.employeeStatus = element.partnerEmployeesStatuses.find(x => x.employee_id == empl.employee_id && x.task_operation_id == empl.task_operation_id && x.discussion_id == empl.discussion_id)
            }
            if (element.partnerEmployeesStatuses.filter(x => x.discussion_id == 0).length > 0) {
              empl.employeeStatus = element.partnerEmployeesStatuses.find(x => x.employee_id == empl.employee_id && x.task_operation_id == empl.task_operation_id && x.discussion_id == 0)
            }
          }

          empl.employee = empl.partnerEmployee;

          delete empl.partnerEmployee
        })

        element.employees.forEach(empl => {
          if (element.employeesStatuses.filter(y => y.employee_id == empl.employee_id && y.task_operation_id == empl.task_operation_id).length > 0) {
            if (element.employeesStatuses.filter(x => x.discussion_id == empl.discussion_id).length > 0) {
              empl.employeeStatus = element.employeesStatuses.find(x => x.employee_id == empl.employee_id && x.task_operation_id == empl.task_operation_id && x.discussion_id == empl.discussion_id)
            }
            if (element.employeesStatuses.filter(x => x.discussion_id == 0).length > 0) {
              empl.employeeStatus = element.employeesStatuses.find(x => x.employee_id == empl.employee_id && x.task_operation_id == empl.task_operation_id && x.discussion_id == 0)
            }
          }
        })

        element.employees.push(...element.partnerEmployees)

        
        element.addEmployee = element.employees.filter(x => x.task_operation_id == element.id && (x.is_manager == 0 || (x.is_manager == 1 && x.company_id != element.company_id)));
        
        element.mainEmployees = element.employees.filter(x => x.task_operation_id == element.id && x.is_manager == 1 && x.company_id == element.company_id);

        if (element.partnerCompanies.length) {
          element.partnerCompanies.forEach(p => {
            if (element.partnerCompaniesStatuses.filter(y => y.partner_company_id == p.partner_company_id && y.task_operation_id == p.task_operation_id).length > 0) {
              if (element.partnerCompaniesStatuses.filter(x => x.discussion_id == p.discussion_id).length > 0) {
                p.partnerCompanyStatus = element.partnerCompaniesStatuses.find(x => x.partner_company_id == p.partner_company_id && x.task_operation_id == p.task_operation_id && x.discussion_id == p.discussion_id)
              }
              if (element.employeesStatuses.filter(x => x.discussion_id == 0).length > 0) {
                p.partnerCompanyStatus = element.partnerCompaniesStatuses.find(x => x.partner_company_id == p.partner_company_id && x.task_operation_id == p.task_operation_id && x.discussion_id == 0)
              }
            }
            p.is_partner = true;
            element.employees.push(p);
            if (p.is_manager == 1) {
              element.mainEmployees.push(p);
            } else {
              element.addEmployee.push(p)
            }
          
          })
        }

        if (!el.worksByTypeAndStatus.hasOwnProperty(element.operation_id)) {
          el.worksByTypeAndStatus[element.operation_id] = {
            name: element.operation_id,
            type: element.operation.name,
            statuses: {},
            count: 0,
            ids: [],
            advisers: [],
            employees: []
          }
        }

        element.addEmployee.forEach(u => {
          if (!el.worksByTypeAndStatus[element.operation_id].advisers.find(x => x.employee_id == u.employee_id)) {
            el.worksByTypeAndStatus[element.operation_id].advisers.push(u)
          }
        });

        element.uniqueEmployees = [];


        element.mainEmployees.forEach(u => {
          if (!element.uniqueEmployees.find(x => x.employee_id == u.employee_id)) {
            element.uniqueEmployees.push(u)
          }
          if (!el.worksByTypeAndStatus[element.operation_id].employees.find(x => x.employee_id == u.employee_id)) {
            el.worksByTypeAndStatus[element.operation_id].employees.push(u)
          }
        });

        if (!el.worksByTypeAndStatus[element.operation_id].statuses.hasOwnProperty(element.status_id)) {
          el.worksByTypeAndStatus[element.operation_id].statuses[element.status_id] = {
            name: element.status_id,
            employees: [],
            count: 1
          };
        } else {
          el.worksByTypeAndStatus[element.operation_id].statuses[element.status_id].count ++
        }

        if (element.employees.filter(x => x.is_manager == 1 && x.is_price_manager == 0 && x.task_operation_id == element.id && x.discussion_id == 0).length > 0) {
          element.employee = element.employees.find(x => x.is_manager == 1 && x.is_price_manager == 0 && x.task_operation_id == element.id && x.discussion_id == 0)
          if (!el.worksByTypeAndStatus[element.operation_id].statuses[element.status_id].employees.find(y => y.employee_id == element.employee.employee_id)) {
            el.worksByTypeAndStatus[element.operation_id].statuses[element.status_id].employees.push(element.employee);
            el.worksByTypeAndStatus[element.operation_id].ids.push(element.id)
          }
        }

        el.worksByTypeAndStatus[element.operation_id].count++

        if (element.employee && !el.jobEmployees.find(x => x.id == element.employee.employee_id)) {
          el.jobEmployees.push(element.employee)
        }
      });

      el.worksByTypeAndStatus = makeArray(el.worksByTypeAndStatus);
      el.worksByTypeAndStatus.forEach(x => {
        x.allEmpl = [...x.employees]
        x.advisers.forEach(u => {
          if (!x.allEmpl.find(b => b.employee_id == u.employee_id)) {
            x.allEmpl.push(u)
          }
        })
        x.statuses = makeArray(x.statuses);
      });

      if (!tasks.find(x => x.id == el.id)) {
        tasks.push(el);
      }

  });

    // if (event != "update") {
    //   tasks.push(...arr);
    // }

  return {
    arr: arr,
    tasks: tasks
  }
}


function unique(arr) {
  let uniqueArr = [];
  arr.forEach(b => {
    if (uniqueArr.filter(y => y.parameterValue.value == b.parameterValue.value).length == 0 || uniqueArr.length == 0) {
      uniqueArr.push(b)
    }
  })

  return uniqueArr
}