<h2 mat-dialog-title class="dialog_title">
    <span>
        {{ "You have similar values" | translate }} "{{data.val}}":
    </span>
    <button mat-icon-button style="margin-left: 40px;" (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h2>
<mat-dialog-content class="mat-typography">
    <div class="container same_wrp" [appDeviceClass]="'custom-scroll custom-scroll-t'">
        <mat-list role="list">
            <mat-list-item role="listitem" *ngFor="let item of data.sameValues">
                <div class="same_val">
                    <span>{{item.value}}</span>
                    <span>{{item.parameter.name}}</span>
                </div>
            </mat-list-item>
          </mat-list>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button (click)="close()">
        {{ "Close" | translate }}
    </button>
    <button class="ml-1" mat-raised-button color="primary" (click)="save()">
        {{ "Save" | translate }}
    </button>
</mat-dialog-actions>
