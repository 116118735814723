import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { concat, forkJoin, Subscription } from 'rxjs';
import { distinctUntilChanged, map, tap } from 'rxjs/operators';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { LoadingService } from 'src/app/shared/services/rest/loading.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';

@Component({
  selector: 'app-task-status-edit',
  templateUrl: './task-status-edit.component.html',
  styleUrls: ['./task-status-edit.component.scss']
})
export class TaskStatusEditComponent extends BaseClass implements OnInit, OnDestroy {
  public statuses: any;
  public form: FormGroup;
  public isFormChange: boolean = false;
  
  
  public isSubmit: boolean = false;
  public eltsSub: Subscription;
  public page = 1;
  public pagination: any;
  public cards: any = [];
  public isLoad: boolean = false;
  public progress:number = 0;
  
  constructor(
    private taskService: TaskService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<TaskStatusEditComponent>,
    private fb: FormBuilder,
    private sm: StorageManagerService,
    private ls: LoadingService,
    private layoutService: LayoutService,
    private activatedRoute: ActivatedRoute,
  ) { super() }

  ngOnInit(): void {
    this.getTaskStatus();
    
    this.form = this.fb.group({
      status_id: [this.data.task.status_id, Validators.required],
    })

    if (this.data.is_all_results) {
      this.getCards(this.page);
    }

    this.attachSubscriptions(
      this.form.valueChanges.subscribe(() => this.isFormChange = true)
    )

    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        if (this.isFormChange) {
          this.layoutService.openBottomSheet(this.dialogRef);
        } else {
          this.close();
        }
      })
    )

  }

  getCards(n) {
    this.isLoad = true;
    let filterData:any = {...this.data.filterData};

    this.eltsSub = this.taskService.getTasksSelectNoExpNew(n, this.data.company.id, filterData).pipe(
      tap(el => {
        this.pagination = {
          'pageCount': el.headers.get('x-pagination-page-count'),
          'perPage': el.headers.get('x-pagination-per-page'),
          'totalCount': el.headers.get('x-pagination-total-count'),
          'currentPage': el.headers.get('x-pagination-current-page')
        }
        console.log("this.pagination TEST", this.pagination)
      }),
      map(el => el.body),
      distinctUntilChanged()
    ).subscribe(resp => {

      console.log("getCards", resp)
      
      if (this.page == 1) {
        this.cards = resp;
      } else {
        this.cards.push(...resp);
      }

      this.progress = +((this.cards.length / +this.pagination.totalCount)*100).toFixed(1);
      this.page = this.page + 1;
      
      
      if (+this.pagination['currentPage'] < +this.pagination['pageCount'] && +this.pagination['pageCount'] > 0) {
        this.getCards(this.page)
      } else {
        console.log("cards - " + this.page, this.cards)
        this.isLoad = false;
        this.progress = 100;
      }
    },
    error => {
      this.isLoad = false;
    })
  }


  getTaskStatus() {
    this.attachSubscriptions(
      this.taskService.getTaskStatuses(this.data.company.id).subscribe(resp => {
        this.statuses = resp;
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  onSubmit() {
    if (!!this.data.multi) {
      if (this.data.is_all_results) {
        let sbmtData = [];
        let partsSbmtData = [];
    
        this.cards.forEach(task => {
          sbmtData.push({
            "path": `/api/task/${task.id}/`,
            "query": {company_id: this.data.company_id},
            "method": "POST",
            "body": Object.assign({
              [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
          }, this.form.value) 
          })
        });
    
        for (let i = 0; i < Math.ceil(sbmtData.length/10); i++) {
          partsSbmtData.push(sbmtData.slice(i*10, 10 + i*10))
        }
    
        let count = 0;
        concat(...partsSbmtData.map((param) => this.taskService.multiRequest(param))).pipe(
          tap(el => {
            this.ls.requests$.next({
              value: Math.round((100 / partsSbmtData.length) * (count+1)),
              target: "Editing Cards type"
            })
            if (count == 0) {
              this.isSubmit = false;
              this.dialogRef.close({event: "update", data: null});
            }
            // partsSbmtData[count].forEach(param => {
            //   this.refreshService.refreshTask$.next({company_id: this.data.company_id, task_id: +param.path.replace('/api/task/', '').replace('/','')})
            // });
            count++;
          })
        ).subscribe(res => {
          console.log(res);
        })
      } else {
        forkJoin(this.data.collection.map(x => this.taskService.editTask(x, this.form.value, this.data.company_id))).subscribe(resp => {
          this.dialogRef.close({event: "update", status_id: this.form.value.status_id})
        })
      }
    } else { 
      this.attachSubscriptions(
        this.taskService.editTask(this.data.task.id, this.form.value, this.data.company_id).subscribe(resp => {
          this.dialogRef.close({event: "update", data: resp})
        })
      )
    }
  }

  ngOnDestroy(): void {
    if (this.eltsSub) {
      this.eltsSub.unsubscribe()
    }
    this.clearSubscriptions()
  }

}
