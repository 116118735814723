import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { ConnectedAppsService } from 'src/app/shared/services/rest/connected-apps.service';

@Component({
  selector: 'app-edit-channel-account',
  templateUrl: './edit-channel-account.component.html',
  styleUrls: ['./edit-channel-account.component.scss']
})
export class EditChannelAccountComponent extends BaseClass implements OnInit, OnDestroy {
  public form: FormGroup = this.fb.group({
    name: [this.data.name, Validators.required],
  });
  public isFormChange: boolean = false;

  constructor(
    private connectedAppsService: ConnectedAppsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<EditChannelAccountComponent>,
    private fb: FormBuilder,
    private layoutService: LayoutService
  ) { super() }

  ngOnInit(): void {

    this.attachSubscriptions(
      this.form.valueChanges.subscribe(() => this.isFormChange = true)
    )

    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        if (this.isFormChange) {
          this.layoutService.openBottomSheet(this.dialogRef);
        } else {
          this.close();
        }
      })
    )

  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  onSubmit() {
    this.attachSubscriptions(
      this.connectedAppsService.editChannelAccount(this.data.id, this.form.value).subscribe(resp => {
        this.data.name = resp.name;
        this.close()
      })
    )
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
