<h2 mat-dialog-title class="dialog_title">
    <span>
        {{ 'Folders permissions' | translate }}
    </span>
    <button mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h2>
<mat-dialog-content class="mat-typography" dirEnter (dirSubmit)="submitForm()">
    <div class="container form_wrapper" style="padding: 20px 0;">
        <mat-form-field appearance="standard" class="full_width_form">
            <mat-label>{{ "Card type" | translate }}</mat-label>
            <mat-select [disableOptionCentering]="true" selectDir [multiple]="true" [(ngModel)]="data.source.task_statuses_ids">
                <mat-option *ngFor="let item of data.taskStatuses" [value]="item.id">
                    {{ item.name | translate }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="standard" class="full_width_form">
            <mat-label>{{ "Job type" | translate }}</mat-label>
            <mat-select [disableOptionCentering]="true" selectDir [multiple]="true" [(ngModel)]="data.source.task_operation_operations_ids">
                <mat-option *ngFor="let item of data.operations" [value]="item.id">
                    {{ item.name | translate }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="standard" class="full_width_form">
            <mat-label>{{ "Job Status" | translate }}</mat-label>
            <mat-select [disableOptionCentering]="true" selectDir [multiple]="true" [(ngModel)]="data.source.task_operation_statuses_ids">
                <mat-option *ngFor="let item of data.statuses" [value]="item.id">
                    {{ item.name | translate }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="standard" class="full_width_form">
            <mat-label>{{ "Role in job" | translate }}</mat-label>
            <mat-select [disableOptionCentering]="true" selectDir [(ngModel)]="data.source.is_manager">
                <mat-option [value]="0">
                    {{"Assistant" | translate}}
                </mat-option>
                <mat-option [value]="1">
                    {{"Executor" | translate}}
                </mat-option>
                <mat-option [value]="2">
                    {{"All roles" | translate}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <!-- <mat-form-field appearance="standard" class="full_width_form">
            <mat-label>{{ "Folder permisions" | translate }}</mat-label>
            <mat-select [disableOptionCentering]="true" selectDir [multiple]="true" [(ngModel)]="data.source.directories">
                <mat-select-trigger class="multi_users">
                    <ng-container *ngIf="!!data.source.directories && !!data.source.directories.length">
                        <span *ngFor="let id of data.source.directories">
                            {{getFolderByLoc(id)?.name}}
                            <mat-icon (click)="toogleFolRule($event, id)" class="folder_rule" [ngClass]="{'active': data.source.write_directories.includes(id)}">drive_folder_upload</mat-icon>
                        </span>
                    </ng-container>
                </mat-select-trigger>
                <mat-option *ngFor="let item of data.folders" [value]="item.location">
                    {{ item.name | translate }}
                </mat-option>
            </mat-select>
        </mat-form-field> -->

        <div class="folders_table_wrp">
            <div class="folders_table_head">
                {{ "Restrictions for" | translate }}
            </div>
            <div class="folders_table">
                <div class="folders_table_line">
                    <div>{{ "Can`t write" | translate }}</div>
                    <div>{{ "Can`t read" | translate }}</div>
                    <div></div>
                </div>
                <div class="folders_table_line" *ngFor="let item of data.source.folders; let i = index;">
                    <div><mat-checkbox color="primary" (change)="changeCheck($event, item, 'cant_write')" [disabled]="data.source.folders[0].cant_read" [(ngModel)]="item.cant_write"></mat-checkbox></div>
                    <div><mat-checkbox color="primary" (change)="changeCheck($event, item, 'cant_read')" [disabled]="i != 0 && data.source.folders[0].cant_read" [(ngModel)]="item.cant_read"></mat-checkbox></div>
                    <div>{{item.name}}</div>
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button (click)="close()">
        {{ "Close" | translate }}
    </button>
    <button class="ml-1 d-f-btn" (click)="submitForm()" mat-raised-button cdkFocusInitial color="primary" [disabled]="isSubmit">
        <mat-spinner class="spin_16 mr-1" *ngIf="isSubmit"></mat-spinner>  
        {{ "Save" | translate }}
    </button>
</mat-dialog-actions>
