import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { RootService } from 'src/app/shared/services/rest/root.service';

@Component({
  selector: 'app-change-size-limit',
  templateUrl: './change-size-limit.component.html',
  styleUrls: ['./change-size-limit.component.scss']
})
export class ChangeSizeLimitComponent extends BaseClass implements OnInit, OnDestroy {
  public form: FormGroup;
  public isFormChange: boolean = false;

  constructor(
    private rootService: RootService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ChangeSizeLimitComponent>,
    private fb: FormBuilder,
    private layoutService: LayoutService
  ) { super() }

  ngOnInit(): void {
    this.form = this.fb.group({
      filesize_limit: [this.data.filesize_limit/1073741824, Validators.required],
    })

    this.attachSubscriptions(
      this.form.valueChanges.subscribe(() => this.isFormChange = true)
    )

    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        if (this.isFormChange) {
          this.layoutService.openBottomSheet(this.dialogRef);
        } else {
          this.close();
        }
      })
    )

  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  onSubmit() {
    let val = this.form.value;
    val.filesize_limit = (val.filesize_limit * 1073741824).toFixed(0)
    this.attachSubscriptions(
      this.rootService.changeLimit(this.data.id, val).subscribe(resp => {
        this.data.filesize_limit = resp.filesize_limit;
        this.close()
      })
    )
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
