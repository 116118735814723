<mat-dialog-content class="mat-typography">
    <mat-tab-group [@.disabled]="true" [selectedIndex]="activeIndex">
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon mr-1">delete</mat-icon>
                {{ "Delete Tag(s)" | translate }}
            </ng-template>
            <form [formGroup]="deleteForm" class="multi_tab_form form_with_pads form_with_valids">
                <div class="users_select" *ngIf="tagsAssignedEarlyer && tagsAssignedEarlyer.length" [ngClass]="{'req_value': !deleteForm.get('parameter_value_id').valid}">
                    <label>{{ "Select tag(s) you want to remove" | translate }}:</label>
                    <mat-chip-list class="without_ordered" [multiple]="true" selectable formControlName="parameter_value_id">
                        <ng-container *ngFor="let tag of tagsAssignedEarlyer; let i = index">
                            <mat-chip class="" (click)="toggleChip(tag.parameter_value_id, 'parameter_value_id', 'deleteForm')" [value]="tag.parameter_value_id" [selected]="deleteForm.value.parameter_value_id && deleteForm.value.parameter_value_id.includes(tag.parameter_value_id)">
                                <div class="tag_chip_inside">
                                    <div class="tag_val">{{tag?.parameterValue?.value}}</div>
                                    <div class="tag_name text_one_line">{{tag?.parameter?.name}}</div>
                                </div>
                            </mat-chip>
                        </ng-container>
                    </mat-chip-list>
                </div>
            </form>
        </mat-tab>
    </mat-tab-group>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <div class="f_w_p_btns">
        <button mat-raised-button (click)="close()">
            {{ "Cancel" | translate }}
        </button>
        <button class="ml-1 d-f-btn" (click)="onDelete()" mat-raised-button cdkFocusInitial color="primary" [disabled]="isSubmit">
            <mat-spinner class="spin_16 mr-1" *ngIf="isSubmit"></mat-spinner>
            {{ "Delete" | translate }}
        </button>
    </div>
</mat-dialog-actions>