import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { MatButton } from '@angular/material/button';
import { StorageManagerService } from '../../services/common/storage-manager.service';

@Component({
  selector: 'app-delete-alert',
  templateUrl: './delete-alert.component.html',
  styleUrls: ['./delete-alert.component.scss']
})
export class DeleteAlertComponent implements OnInit, AfterViewInit {
  @ViewChild('btnFocus') btnFocus: MatButton;
  public is_delete_without_alert: boolean = !!this.sm.localStorageGetItem('is_delete_without_alert');

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private sm: StorageManagerService,
    private bottomSheetRef: MatBottomSheetRef<DeleteAlertComponent>
  ) { }

  ngOnInit(): void {

    if (!this.data.btn_yes) {
      if (this.is_delete_without_alert) {
        this.yes();
      }
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {this.btnFocus.focus()},0)
  }

  no(): void {
    this.bottomSheetRef.dismiss({
      message: 'no',
    });
  }

  yes() {
    if (this.is_delete_without_alert) {
      this.sm.localStorageSetItem("is_delete_without_alert", '1')
    }
    this.bottomSheetRef.dismiss({
      message: 'yes',
    });
  }

}
