import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BaseClass } from '../../models/base-class';
import { AuthService } from '../../services/rest/auth.service';
import { debounceTime, map, switchMap, tap } from 'rxjs/operators';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { OperationChatComponent } from 'src/app/components/atTasksDialog/operation-chat/operation-chat.component';
import { ChatService } from '../../services/rest/chat.service';
import { TaskService } from '../../services/rest/task.service';
import { casesModel } from '../../functions/casesModel';
import { ReplaySubject, forkJoin } from 'rxjs';
import * as moment from 'moment';

@Component({
  selector: 'app-notifications-center',
  templateUrl: './notifications-center.component.html',
  styleUrls: ['./notifications-center.component.scss']
})
export class NotificationsCenterComponent extends BaseClass implements OnInit, OnDestroy {
  public notifications: any[] = [];
  public page: number = 1;
  public pagination: any;
  public isLoad: boolean = false;
  public today = moment().set({hour:0,minute:0,second:0}).unix();
  public filter: FormGroup;
  public throttle: number = 100;
  public scrollUpDistance: number = 3.5;
  public companyesControl: FormControl = new FormControl();
  public companyes$: ReplaySubject<any> = new ReplaySubject<any>(1);
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<NotificationsCenterComponent>,
    private authService: AuthService,
    private chatService: ChatService,
    private taskService: TaskService,
    private fb: FormBuilder,
    private dialog: MatDialog,
  ) { super() }

  ngOnInit(): void {
    console.log("NotificationsCenterComponent", this.data)
    this.filter = this.fb.group({
      company_id: this.data.company ? this.data.company.id : ''
    })
    this.getNotifications()
    this.companyes$.next(this.data.companyes.slice())

    this.attachSubscriptions(
      this.companyesControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchCompanyes(resp))
    )

    this.filter.valueChanges.subscribe(res => {
      console.log(res);
      this.page = 1; 
      this.pagination = undefined;
      this.getNotifications()
    })
  }

  onSearchCompanyes(resp) {
    if (!this.data.companyes) {
      return;
    }

    if (!resp) {
      this.companyes$.next(this.data.companyes.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }

    this.companyes$.next(
      this.data.companyes.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  getCompanyById(id) {
    if (!this.data.companyes || this.data.companyes.filter(el => el.id == id).length == 0) {
      return {name: "All Workspaces"};
    }
    return this.data.companyes.find(el => el.id == id)
  }

  getOperationStatusName(id) {
    switch (+id) {
      case 1:
        return "Waiting";
        break;
      case 2:
        return "In process";
        break;
      case 3:
        return "To approve";
        break;
      case 4:
        return "Approved";
        break;
      case 5:
        return "To correct";
        break;
      case 6:
        return "Sources Needed";
        break;
      case 97:
        return "On hold";
        break;
      case 98:
        return "Ready";
        break;
      case 99:
        return "Canceled";
        break;
    }
  }

  getNotifications() {
    this.isLoad = true;
    this.attachSubscriptions(
      this.authService.getNotifications(this.page, this.filter.value).pipe(
        tap(el => {
          if (this.page == 1) {
            this.pagination = {
              'pageCount': el.headers.get('x-pagination-page-count'),
              'perPage': el.headers.get('x-pagination-per-page'),
              'totalCount': el.headers.get('x-pagination-total-count'),
              'currentPage': el.headers.get('x-pagination-current-page'),
            }
          }
        }),
        map(x => x.body)
      ).subscribe(resp => {
        if (this.page == 1) {
          this.notifications = [...resp]
        } else {
          this.notifications.push(...resp)
        }
        console.log("notifications " + this.page, this.notifications)
        this.page++
        this.isLoad = false
      })
    )
  }

  openNot(not) {
    if (not.is_active == 1) {
      this.attachSubscriptions(
        this.authService.editNotification(not.id, {is_active: 0}).subscribe(resp => {
          not.is_active = 0;
        })
      )
    }

    if (not.discussion_post_id && not.discussion_id && this.data.companyes.find(x => x.id == not.company_id)) {
      this.openChat(not.discussion_id, this.data.companyes.find(x => x.id == not.company_id), not.discussion_post_id)
    }
  }
  
  neededTaskData(task, company) {
    let arr = [
      this.chatService.getTasksChats(company.id, [task.id]).pipe(
        tap(res => {
          task['operations'].map(o_el => {
            o_el['chatsInfo'] = [];
            o_el['chatsInfo'].push(...res.filter(z => z.task_operation_id == o_el.id));
            o_el['unReadChats'] = res.filter(z => z.task_operation_id == o_el.id && z.is_read != '1').length;
            o_el['unClosedChats'] = res.filter(z => z.task_operation_id == o_el.id && z.status_id != '4').length;
          });
          task['chatsInfo'] = [];
          task['chatsInfo'].push(...res.filter(z => z.task_id == task.id));
          task['unReadChats'] = res.filter(z => z.is_read != '1').length;
          task['unClosedChats'] = res.filter(z => z.status_id != '4').length;
        })
      )
    ]
    return forkJoin(arr)
  }
  
  
  openChat(chat_id, company, msg_id) {
    this.attachSubscriptions(
      this.chatService.getChatsExpand(1, company.id, {id: chat_id}).pipe(
        map(res => res.body[0]),
        switchMap(chat => {
          return this.taskService.getOneTaskExpand(company.id, chat.task_id).pipe(
            map(x => x.body[0]),
            switchMap(x => this.neededTaskData(x, company).pipe(map(() => casesModel([x], [], 'update')),
            switchMap(x => this.taskService.getOperations(company.id, this.data.activeLang).pipe(tap(u => x.arr[0].operationsValues = u), map(() => x))),
            map((x:any) => { return {task: x.arr[0], 'chat': chat}}))),
          )
        })
      ).subscribe((resp:any) => {
        console.log(resp);

        let data:any = {
          company: company,
          task: resp.task,
          dialogType: 'operation_chat',
          operationsValues: resp.task.operationsValues,
          work: resp.task.operations.find(el => el.id == resp.chat.task_operation_id),
          user: this.data.user,
          empl_status: company.permissions.includes('owner') || company?.permissions.includes('admin') || company.permissions.includes('manager'),
          chat: resp.chat,
          chats: resp.task.operations.find(el => el.id == resp.chat.task_operation_id).chatsInfo
        }
        if (msg_id) {
          data.m_id = msg_id
        }
        const goToChat = this.dialog.open(OperationChatComponent, {
          backdropClass: ['backdrop_under_header', "without_pad"],
          panelClass: ['full_screen_dialog', 'global_chats_dialog', 'show_header'],
          position: { top: '58px', left: '0', right: '0', bottom: '0' },
          autoFocus: true,
          data: data
        });
    
        this.attachSubscriptions(
          goToChat.afterClosed().subscribe(result => {
            if (!!result && result.event == 'close') {
              this.chatService.postChatRead(chat_id, 0, company.id).subscribe(resp => {
                console.log(resp);
              })
            }
          })
        )
      })
    )
  }
  
  onDown(e) {
    console.log("SCROLL DOWN", e);
    if (this.pagination['pageCount'] >= this.page) {
      console.log("getNext")
      this.getNotifications();
    }
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

}
