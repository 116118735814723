<h2 mat-dialog-title class="dialog_title">
    <span>
        <ng-container *ngIf="data.promo; else elseTemplateIsAdd">
            {{ "Edit Promo" | translate }}
        </ng-container>
        <ng-template #elseTemplateIsAdd>
            {{ "Add Promo" | translate }}
        </ng-template>
    </span>
    <button class="ml-1" mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h2>
<mat-dialog-content class="mat-typography">
    <div class="container form_wrapper">
        <form [formGroup]="form">
            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ 'Code' | translate }}</mat-label>
                <input formControlName="code" matInput placeholder="{{ 'Code' | translate }}">
            </mat-form-field>

            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ 'Description' | translate }}</mat-label>
                <textarea formControlName="description" matInput placeholder="{{ 'Description' | translate }}"
                cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="2"
                cdkAutosizeMaxRows="7"></textarea>
            </mat-form-field>

            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ 'Workspace Description' | translate }}</mat-label>
                <textarea formControlName="description_company" matInput placeholder="{{ 'Description' | translate }}"
                cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="2"
                cdkAutosizeMaxRows="7"></textarea>
            </mat-form-field>

            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ 'Root Description' | translate }}</mat-label>
                <textarea formControlName="description_root" matInput placeholder="{{ 'Description' | translate }}"
                cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="2"
                cdkAutosizeMaxRows="7"></textarea>
            </mat-form-field>

            <div class="d-f-c" style="height: 40px;">
                <mat-checkbox color="primary" formControlName="is_initial">{{"Initial" | translate}}</mat-checkbox>
            </div>
        </form>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button (click)="close()">
        {{ "Back" | translate }}
    </button>
    <button class="ml-1" (click)="submitForm()" mat-raised-button color="primary" [disabled]="!form.valid">
        {{ "Save" | translate }}
    </button>
</mat-dialog-actions>
