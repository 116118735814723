import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpRestService } from '../common/http-rest.service';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(
    private http: HttpRestService
  ) { }

  getUsers(company_id?) {
    let paramRequest = new HttpParams().set('per-page', '200')
    if (company_id) {
      paramRequest = paramRequest.set("company_id", company_id)
    }
    return this.http.executeGet<any>('/api/vuser/', true, {
      params: paramRequest
    })
  }

  getUser(id, company_id?) {
    let paramRequest = new HttpParams()
    if (company_id) {
      paramRequest = paramRequest.set("company_id", company_id)
    }
    return this.http.executeGet<any>(`/api/vuser/${id}/`, true, {
      params: paramRequest
    })
  }

  createUser(data, company_id?) {
    let paramRequest = new HttpParams()
    if (company_id) {
      data.company_id = company_id
      paramRequest = paramRequest.set("company_id", company_id)
    }
    return this.http.executePost<any>('/api/vuser/', data, true, {
      params: paramRequest
    })
  }

  editUser(id, data, company_id?) {
    let paramRequest = new HttpParams()
    if (company_id) {
      paramRequest = paramRequest.set("company_id", company_id)
    }
    return this.http.executePost<any>(`/api/vuser/${id}/`, data, true, {
      params: paramRequest
    })
  }

  deleteUser(id, company_id?) {
    let paramRequest = new HttpParams()
    if (company_id) {
      paramRequest = paramRequest.set("company_id", company_id)
    }
    return this.http.executeDelete<any>(`/api/vuser/${id}/`, true, {
      params: paramRequest
    })
  }
}
