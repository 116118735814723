import { moveItemInArray } from "@angular/cdk/drag-drop";

export function filesSort(array) {
    let shadowArray = array.slice();

    let newArray = [];

    let uploadingFiles = shadowArray.filter(x => x.target.upload && ['PENDING', 'IN_PROGRESS'].includes(x.target.upload.state));
    let waitingFiles = shadowArray.filter(x => !x.target.upload || (x.countSameFiles > 0 && !x.data.tryAgain)).sort((a,b) => b.data.created_at - a.data.created_at);
    let errorFiles = shadowArray.filter(x => !!x.target.upload && x.target.upload.state == 'ERROR').sort((a,b) => b.data.created_at - a.data.created_at);
    let endedFiles = shadowArray.filter(x => !!x.target.upload && x.target.upload.state == 'DONE').sort((a,b) => b.data.created_at - a.data.created_at);

    if (uploadingFiles.length > 0) {
        newArray.push(...uploadingFiles)
    }

    if (waitingFiles.length > 0) {
        newArray.push(...waitingFiles)
    }

    if (errorFiles.length > 0) {
        newArray.push(...errorFiles)
    }

    if (endedFiles.length > 0) {
        newArray.push(...endedFiles)
    }


    newArray.forEach((el, ind) => {
        let target = array.find(x => x.data.id == el.data.id)
        let prevIndex = array.indexOf(target)
        moveItemInArray(array, prevIndex, ind);
    })

    // console.log("uploadingFile", uploadingFile)
    // console.log("waitingFiles", waitingFiles)
    // console.log("errorFiles", errorFiles)
    // console.log("endedFiles", endedFiles)

    // console.log(array.length, uploadingFile.length + waitingFiles.length + errorFiles.length + endedFiles.length)



    // return array;
}