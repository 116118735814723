import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { MatStepper } from '@angular/material/stepper';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { forkJoin, of } from 'rxjs';
import { last, map, switchMap, tap } from 'rxjs/operators';
import { DeleteAlertComponent } from 'src/app/shared/global_components/delete-alert/delete-alert.component';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { BaseClass } from 'src/app/shared/models/base-class';
import { GlobalDataService } from 'src/app/shared/services/common/global-data.service';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { UsersService } from 'src/app/shared/services/rest/users.service';
import { environment } from 'src/environments/environment';
import { DialogGroupCreateComponent } from '../dialogs/groups/group-create/group-create.component';
import { DialogGroupEditComponent } from '../dialogs/groups/group-edit/group-edit.component';
import { PaymentInfoComponent } from '../dialogs/member/payment-info/payment-info.component';
import { CreateTaskStatusComponent } from '../dialogs/types/create-task-status/create-task-status.component';
import { CreateWorkStatusComponent } from '../dialogs/types/create-work-status/create-work-status.component';
import { EditTaskStatusComponent } from '../dialogs/types/edit-task-status/edit-task-status.component';
import { EditWorkStatusComponent } from '../dialogs/types/edit-work-status/edit-work-status.component';

@Component({
  selector: 'app-set-up',
  templateUrl: './set-up.component.html',
  styleUrls: ['./set-up.component.scss']
})
export class SetUpComponent extends BaseClass implements OnInit, OnDestroy {
  public companyNameGroup: FormGroup;
  public firstFormGroup: FormGroup;
  public form: FormGroup;
  public virtual: FormGroup;
  public secondFormGroup: FormGroup;
  public thirdFormGroup: FormGroup;
  public selectedIndex: number = 0;
  public tabIndex: number = 0;
  public memberIndex: number = 0;
  public earningsVal: FormControl = this.fb.control(0);
  public company_id: any;
  public company: any;
  public activeLang: any;
  public host: any = environment.host;
  public origin = window.location.origin;
  public currencyList: any;
  public taskTypes: any;
  public types: any;
  public groups: any = [];
  public onlyMe: boolean = true;
  public employees: object[];
  public numberRegEx = /\d*\.?\d{1,2}/;
  public payState:number = 0;
  public newUser: any;

  constructor(
    private fb: FormBuilder,
    private usersService: UsersService,
    private companyService: CompanyService,
    private taskService: TaskService,
    private languageService: LanguageService,
    private bottomSheet: MatBottomSheet,
    private activatedRoute: ActivatedRoute,
    private globalDataService: GlobalDataService,
    private layoutService: LayoutService,
    private dialog: MatDialog,
    private router: Router,
    private sm: StorageManagerService
    ) {
      super()
    }

  ngOnInit(): void {
    this.company_id = this.activatedRoute.snapshot.queryParamMap.get('company_id');
    this.getCompany();
    this.getCurrencyList();
    this.getEmployees();
    this.getLangData();
    this.getTypes();
    this.getTaskTypes();
    this.form = this.fb.group({
      company_id: this.company_id,
      vuser_id: '',
      currency_id: 0,
      permissions: '',
      rate_target: [0, [Validators.pattern(this.numberRegEx)]],
      salary: [0, [Validators.pattern(this.numberRegEx)]],
    })

    this.virtual = this.fb.group({
      lastname: ['', Validators.required],
      firstname: ['', Validators.required],
    })
    this.companyNameGroup = this.fb.group({
      name: ['', Validators.required],
    });
    this.firstFormGroup = this.fb.group({
      firstCtrl: ['', Validators.required],
    });
    this.secondFormGroup = this.fb.group({
      secondCtrl: ['', Validators.required],
    });
    this.thirdFormGroup = this.fb.group({
      thirdCtrl: ['', Validators.required],
    });
  }

  addAnother() {
    this.newUser = undefined;
    this.form.reset();
    this.form.patchValue({
      company_id: this.company_id,
      currency_id: 0
    })
    this.virtual.reset();
    this.payState = 0;
    this.memberIndex = 0;
    this.earningsVal.patchValue(0);
  }

  getGroups() {
    this.attachSubscriptions(
      this.companyService.getInfiniteGroupsCompany(this.company_id, '1', '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.companyService.getInfiniteGroupsCompany(this.company_id, x).pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        this.groups = [].concat(...res);
      })
    )
  }

  selectEarningsCalc(e: MatRadioChange) {
    this.form.patchValue({
      salary: 0
    })
    this.earningsVal.patchValue(e.value)
  }

  createVirtual() {
    if (!this.virtual.valid) {
      this.layoutService.showSnackBar({name: ''}, marker("Fill in all the fields"), SnackBarItem)
      return
    }
    this.attachSubscriptions(
      this.usersService.createUser({name: this.virtual.value.firstname + " " + this.virtual.value.lastname}, this.activatedRoute.snapshot.queryParamMap.get('company_id')).subscribe(resp => {
        this.form.patchValue({
          vuser_id: resp.id
        })
        this.newUser = resp;
        this.memberIndex = 1
      })
    )
  }

  get firstname() { return this.virtual.get('firstname'); }

  get lastname() { return this.virtual.get('lastname'); }
  
  skipNow() {
    this.form.patchValue({
      salary: 0
    })
    this.memberIndex = this.memberIndex + 1;
    this.payState = 0;
    this.createMember();
  }
  
  setEarning() {
    if (this.earningsVal.value == 0) {
      this.layoutService.showSnackBar({name: ''}, marker("Choose a way to calculate earnings"), SnackBarItem)
    } else if (this.earningsVal.value == 1) {
      this.createMember();
    } else if  (this.earningsVal.value == 2) {
      if (this.form.value.salary > 0) {
        this.payState = this.payState + 1;
      } else {
        this.layoutService.showSnackBar({name: ''}, marker("Fill monthly rate field"), SnackBarItem)
      }
    }
  }

  skipNowRate() {
    this.form.patchValue({
      rate_target: 0
    })
    this.memberIndex = this.memberIndex + 1;
    this.payState = 0;
    
    this.createMember()
  }

  setRate() {
    if (this.form.value.rate_target <= 0) {
      this.layoutService.showSnackBar({name: ''}, marker("Fill monthly points rate field"), SnackBarItem)
      return
    }

    this.createMember();
    
  }



  getCompany() {
    this.attachSubscriptions(
      this.companyService.getCompany(this.company_id).subscribe(resp => {
        this.company = resp[0]
        this.companyService.company$.next(resp[0]);
      })
    )
  }

  editTaskType(type) {
    const dialogRef = this.dialog.open(EditTaskStatusComponent, {
      data: {
        company: this.company,
        taskTypes: this.taskTypes,
        type: type,
        activeLang: this.activeLang
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        this.getTaskTypes();
      })
    )
  }

  createTaskType() {
    this.dialog.open(CreateTaskStatusComponent, {
      data: {
        company: this.company,
        taskTypes: this.taskTypes
      }
    });
  }

  deleteTaskType(type, i) {
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: {},
        target: marker("task type")
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        this.attachSubscriptions(
          this.taskService.deleteTaskType(type.id).subscribe(resp => {
            this.taskTypes.splice(i,1);
          })
        )
      }
    });
   
  }

  toggleTaskType(e, type) {
    type.disabled = true
    console.log(this.types)
    if (e.checked) {
      this.selectTaskType(type, e);
    } else {
      this.unSelectTaskType(type, e);
    }
  }

  selectTaskType(type, e) {
    this.attachSubscriptions(
      this.taskService.selectTaskStatus({
        task_status_id: type.id,
        company_id: this.company_id
      }).pipe(
        switchMap(x => {
          return this.taskService.getSelectedTaskStatuses(this.company_id, type.id).pipe(
            tap(z => {
              type.taskStatusToCompany = [...z]
            })
          )
        })
      ).subscribe(
        resp => {
          console.log(resp);
          type.disabled = false;
        },
        error => {
          type.disabled = false;
          e.source.toggle();
          this.layoutService.showSnackBar({}, error, SnackBarItem)
        } 
      )
    )
  }

  unSelectTaskType(type, e) {

    this.attachSubscriptions(
      this.taskService.unSelectTaskStatus(type.taskStatusToCompany[0].id).subscribe(
        resp => {
          console.log(resp);
          type.taskStatusToCompany = [];
          type.disabled = false;
        },
        error => {
          type.disabled = false;
          e.source.toggle();
          this.layoutService.showSnackBar({}, error, SnackBarItem)
        } 
      )
    )
  }

  getTaskTypes() {
    this.attachSubscriptions(
      this.taskService.getTaskTypesToCompany(this.company_id, this.activeLang).subscribe(resp => {
        console.log(resp)
        this.taskTypes = resp
      })
    )
  }

  // 
  editType(type) {
    const dialogRef = this.dialog.open(EditWorkStatusComponent, {
      data: {
        company: this.company,
        types: this.types,
        type: type,
        activeLang: this.activeLang
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        this.getTypes();
      })
    )
  }

  createType() {
    this.dialog.open(CreateWorkStatusComponent, {
      data: {
        company: this.company,
        types: this.types
      }
    });
  }

  deleteType(type, i) {
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: {},
        target: marker("job type")
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        this.attachSubscriptions(
          this.taskService.deleteOperation(type.id).subscribe(resp => {
            this.types.splice(i,1);
          })
        )
      }
    });
   
  }

  toggleType(e, type) {
    type.disabled = true;
    if (e.checked) {
      this.selectType(type, e);
    } else {
      this.unSelectType(type, e);
    }
  }

  selectType(type, e) {
    this.attachSubscriptions(
      this.taskService.selectOperation({
        operation_id: type.id,
        company_id: this.company_id
      }).pipe(
        switchMap(x => {
          return this.taskService.getSelectedOperations(this.company_id, type.id).pipe(
            tap(z => {
              type.operationToCompany = [...z]
            })
          )
        })
      ).subscribe(
        resp => {
          console.log(resp);
          type.disabled = false;
        },
        error => {
          type.disabled = false;
          e.source.toggle();
          this.layoutService.showSnackBar({}, error, SnackBarItem)
        } 
      )
    )
  }

  unSelectType(type, e) {

    this.attachSubscriptions(
      this.taskService.unSelectOperation(type.operationToCompany[0].id).subscribe(
        resp => {
          console.log(resp);
          type.operationToCompany = [];
          type.disabled = false;
        },
        error => {
          type.disabled = false;
          e.source.toggle();
          this.layoutService.showSnackBar({}, error, SnackBarItem)
        } 
      )
    )
  }

  getTypes() {
    this.attachSubscriptions(
      this.taskService.getOperationsToCompany(this.company_id, this.activeLang).subscribe(resp => {
        console.log(resp)
        this.types = resp
      })
    )
  }
  
  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData().subscribe(resp => {
        this.activeLang = resp.active;
      })
    )
  }

  setCurrency() {
    if (!!this.form.value.currency_id) {
      this.payState = this.payState + 1;
    } else {
      this.layoutService.showSnackBar({name: ''}, marker("Select currency"), SnackBarItem)
    }
  }

  typesNext(stepper: MatStepper) {
    if (this.types.filter(x => x.operationToCompany.length > 0).length > 0 && this.taskTypes.filter(x => x.taskStatusToCompany.length > 0).length > 0) {
      stepper.next()
    } else {
      this.layoutService.showSnackBar({name: ''}, marker("Select task and job types"), SnackBarItem)
    }
  }

  memberPaymentInfo() {
    const dialogRef = this.dialog.open(PaymentInfoComponent, {
      data: {
        company: this.company,
        form: this.form,
        virtual: this.virtual,
        activeLang: this.activeLang,
        currencyList: this.currencyList,
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log("PaymentInfoComponent closed", result);
        if (result.event == "add") {
          this.selectedIndex = 1;
        }
        // this.getTaskTypes();
      })
    )
  }

  getEmployees() {
    this.attachSubscriptions(
      this.taskService.getEmployees(this.company_id).pipe(
        tap(res => this.employees = res),
        switchMap(res => {
          if (res.filter(x => !x.invite).length == 0) {
            return of(res)
          } else {
            return forkJoin(res.filter(x => !x.invite).map(t => this.taskService.editEmployee(t.id, {
              company_id: this.company_id,
              reset_invite: 1,
              employee: {
                user_id: t.user_id
              }
            }))).pipe(
              switchMap(() => this.taskService.getEmployees(this.company_id).pipe(
                tap(res => this.employees = res),
              ))
            )
          }
        })
      ).subscribe(resp => {
        console.log("getEmployees" ,resp)
      })
    )
  }


  copyInvite(emp) {
    this.layoutService.showSnackBar({name: emp.invite}, marker("Copied"), SnackBarItem)
  }

  deleteEmployee(id, i) {
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: {},
        target: marker("employee")
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        this.attachSubscriptions(
          this.taskService.deleteEmployee(id).subscribe(resp => {
            console.log(resp)
            this.employees.splice(i,1);
          })
        )
      }
    });
  }

  removeInvite(id, vuser_id) {
    let form = {
      company_id: this.company_id,
      revoke_invite: 1,
      employee: {
        user_id: vuser_id
      }
    }

    this.attachSubscriptions(
      this.taskService.editEmployee(id, form).subscribe(resp => {
        resp.vuser = this.employees.find(el => el['id'] == id)['vuser'];
        let index = this.employees.indexOf(this.employees.find(el => el['id'] == id));
        this.employees.splice(index, 1, resp)
      })
    )
  }

  generateInvite(id, vuser_id) {
    let form = {
      company_id: this.company_id,
      reset_invite: 1,
      employee: {
        user_id: vuser_id
      }
    }
    this.attachSubscriptions(
      this.taskService.editEmployee(id, form).subscribe(resp => {
        resp.vuser = this.employees.find(el => el['id'] == id)['vuser'];
        let index = this.employees.indexOf(this.employees.find(el => el['id'] == id));
        this.employees.splice(index, 1, resp)
      })
    )
  }

  cancelOnlyMe() {
    this.selectedIndex = 0;
    this.onlyMe = true;
  }

  goBack() {
    history.back();
  }

  createMember() {
    this.attachSubscriptions(
      this.taskService.addVuser(Object.assign(this.form.value, {
        reset_invite: 1,
      })).pipe(
        switchMap((x) => this.taskService.getEmployees(this.company_id, {id: x.id})),
        map(res => res[0]),
        tap(res => this.newUser = res)
        ).subscribe(resp => {
          // this.form.reset();
          // this.form.patchValue({
          //   company_id: this.company_id,
          //   currency_id: 0
          // })
          // this.virtual.reset();
          this.payState = 0;
          this.memberIndex = this.memberIndex + 1;
          // this.earningsVal.patchValue(0);
      })
    
    )
  }

  getCurrency(id) {
    return this.currencyList.filter(x => x.id == id).length > 0 ? this.currencyList.find(x => x.id == id) : false 
  }

  getCurrencyList() {
    this.attachSubscriptions(
      this.globalDataService.getCurrencies().subscribe(resp => {
        console.log("getCurrencyList", resp)
        this.currencyList = resp.slice();
      })
    )
    // this.attachSubscriptions(
    //   this.companyService.getCurrencyList().subscribe(resp => {
    //     console.log("getCurrencyList", resp);
    //     this.currencyList = resp
    //   })
    // )
  }

  tabChange($event?: MatTabChangeEvent): void {
    this.memberIndex = $event.index;
  }

  selectionChange($event?: StepperSelectionEvent): void {
    console.log($event.selectedIndex);
    if ($event.selectedIndex == 1) {
      this.getEmployees();
      this.secondFormGroup.patchValue({
        secondCtrl: "1"
      })
    } else if ($event.selectedIndex == 3) {
      this.getGroups()
    }
    this.selectedIndex = $event.selectedIndex;
  }

  deleteGroupCompany(id, g) {
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: {},
        target: marker("group")
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        this.attachSubscriptions(
          this.companyService.deleteGroupCompany(id).subscribe(resp => {
            console.log(resp)
            let group = this.groups.find(el => el == g);
            this.groups.splice(this.groups.indexOf(group), 1);
          })
        )
      }
    });

  }

  createGroup() {
    const dialogRef = this.dialog.open(DialogGroupCreateComponent, {
      data: {
        company: this.company,
        activeLang: this.activeLang,
        groups: this.groups
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log("DialogGroupCreateComponent closed", result);
      })
    )
  }

  editGroup(group) {
    const dialogRef = this.dialog.open(DialogGroupEditComponent, {
      data: {
        company: this.company,
        activeLang: this.activeLang,
        groups: this.groups,
        group: group
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log("DialogGroupEditComponent closed", result);
      })
    )
  }

  finish() {
    this.router.navigate(['/main']);
  }

  ngOnDestroy(): void {
    this.clearSubscriptions();
  }

}
