import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { StorageManagerService } from '../services/common/storage-manager.service';
// import { AuthService } from '../services/auth.service';



@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private sm: StorageManagerService
        ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        const currentUser = this.sm.localStorageGetItem("is_root");

        if (currentUser == '1') {
            return true;
        }

        this.router.navigate(['/main']);

        return false;
    }
}