<h2 mat-dialog-title class="dialog_title">
    <span>
        {{ "Apply Promo" | translate }}
    </span>
    <button class="ml-1" mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h2>
<mat-dialog-content class="mat-typography">
    <div class="container form_wrapper">
        <form [formGroup]="form">
            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ 'Code' | translate }}</mat-label>
                <input formControlName="code" (input)="onInput($event)" matInput placeholder="{{ 'Code' | translate }}">
                <button
                    [disabled]="!form.get('code').value"
                    mat-icon-button
                    matSuffix
                    (click)="findCode()">
                    <mat-icon>search</mat-icon>
                </button>
            </mat-form-field>
        </form>

        <div class="result" *ngIf="result && result != 'Error'">
            <h2>{{"Description" | translate}}</h2>
            <div class="text_promo">{{result?.description}}</div>
            <h2>{{"Workspace Description" | translate}}</h2>
            <div class="text_promo">{{result?.description_company}}</div>
        </div>

        <div class="result" *ngIf="result && result == 'Error'">
            <h2 style="color: rgb(180, 64, 64);">{{"This code was not found" | translate}}</h2>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button (click)="close()">
        {{ "Back" | translate }}
    </button>
    <button class="ml-1" (click)="submitForm()" mat-raised-button color="primary" [disabled]="!result || !result?.code">
        {{ "Apply" | translate }}
    </button>
</mat-dialog-actions>
