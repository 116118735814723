import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseClass } from 'src/app/shared/models/base-class';

@Component({
  selector: 'app-edit-column',
  templateUrl: './edit-column.component.html',
  styleUrls: ['./edit-column.component.scss']
})
export class EditColumnComponent extends BaseClass implements OnInit, OnDestroy {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<EditColumnComponent>,
  ) { super() }

  ngOnInit(): void {
    console.log("EditColumnComponent", this.data)
  }

  close() {
    this.dialogRef.close({event: "close", data: false});
  }

  submitForm() {
    // let sbmtForm:any = {...this.form.value};
    this.dialogRef.close({event: "close", data: this.data.col});
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
