import { Component, OnInit } from '@angular/core';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent implements OnInit {
  public imgRoute: string = '';
  constructor(
    private sm: StorageManagerService
  ) { }

  ngOnInit(): void {
    this.getImgRoute();
  }

  getImgRoute() {
    this.sm.getImgPath().subscribe(el => {
      this.imgRoute = el;
    })
  }
}
