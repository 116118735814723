import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { forkJoin, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { DeleteAlertComponent } from 'src/app/shared/global_components/delete-alert/delete-alert.component';
import { BaseClass } from 'src/app/shared/models/base-class';
import { MembersService } from 'src/app/shared/services/rest/members.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { TaskManagerAddComponent } from '../task-manager-add/task-manager-add.component';

@Component({
  selector: 'app-task-managers',
  templateUrl: './task-managers.component.html',
  styleUrls: ['./task-managers.component.scss']
})
export class TaskManagersComponent extends BaseClass implements OnInit, OnDestroy {
  public taskManagers: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private taskService: TaskService,
    public dialogRef: MatDialogRef<TaskManagersComponent>,
    private dialog: MatDialog,
    private bottomSheet: MatBottomSheet,
    private membersService: MembersService,
  ) { super() }

  ngOnInit(): void {
    console.log("TaskManagersComponent", this.data)
    this.getTaskManagers();
  }

  toggleManager(e, manager) {
    manager.disabled = true
    this.membersService.editMember(manager.id, {is_price_manager: Number(e.checked)}, this.data.company.id).subscribe(resp => {
      manager.is_price_manager = Number(e.checked);
      manager.disabled = false;
    })
  }

  addTaskManager() {
    const dialogRef = this.dialog.open(TaskManagerAddComponent, {
      data: {
        company: this.data.company,
        user: this.data.user,
        taskManagers: this.taskManagers,
        task: this.data.task
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log(result)
        if (result.event == 'update') {
          this.getTaskManagers();
        }
      })
    )
  }

  getTaskManagers() {
    this.attachSubscriptions(
      this.membersService.getMembers({task_id: this.data.task.id, is_manager: 1, task_operation_id: 0}, this.data.company.id).subscribe(resp => {
        console.log("getTaskManagers", resp);
        this.taskManagers = resp;
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false});
  }

  deleteTaskManager(manager, i) {
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: {},
        target: marker("task manager")
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        this.attachSubscriptions(
          this.membersService.deleteMember(manager.id, this.data.company.id).pipe(
            tap(x => {
              console.log(x)
              this.taskManagers.splice(i,1);
            }),
            // switchMap(x => this.taskService.getTaskManagers({employee_id: manager.employee.id})),
            // switchMap(x => {
            //   if (x.length == 0) {
            //     return this.taskService.getEmployeeRules(manager.employee.id).pipe(
            //       switchMap(c => {
            //         return forkJoin(c.filter(z => z.permission == 'employee_list').map(t => this.taskService.deleteEmployeeRule(t.id)))
            //       })
            //     )
            //   } else {
            //     return of(x)
            //   }
            // }),
          
          ).subscribe(resp => {
            console.log("END DELETE", resp)
          })
        )
      }
    });
  
  }

  ngOnDestroy() {
    this.clearSubscriptions()
  }

}
