<div class="bottom-sheet">
    <div class="profiles_confirm mr-1">
        <ng-container *ngIf="!!job; else elseTemplateTargetType">
            <ng-container *ngIf="isUpperStatus; else elseTemplateDirection">
                <div class="d-f-c">
                    <span>
                        {{ "To change a job to status" | translate }}
                    </span>
                    
                    <div class="inside_job ml-1 mr-1" [ngClass]="{
                        'first': targetStatusId == '1',
                        'second': targetStatusId == '2',
                        'third': targetStatusId == '3',
                        'fours': targetStatusId == '4',
                        'fives': targetStatusId == '5',
                        'sixs': targetStatusId == '6',
                        'nine': targetStatusId == '97',
                        'seven': targetStatusId == '98',
                        'eight': targetStatusId == '99'}">
                        <span>{{ getStatusNameById(targetStatusId) | translate }}</span>  
                    </div>
                    <span>
                        {{ "You need to change the personal statuses of all executors." | translate }}
                    </span>
                     
                </div>
            </ng-container>
            <ng-template #elseTemplateDirection>
                <div class="d-f-c">
                    <span>
                        {{ "To change a job to status" | translate }}
                    </span>
                    
                    <div class="inside_job ml-1 mr-1" [ngClass]="{
                        'first': targetStatusId == '1',
                        'second': targetStatusId == '2',
                        'third': targetStatusId == '3',
                        'fours': targetStatusId == '4',
                        'fives': targetStatusId == '5',
                        'sixs': targetStatusId == '6',
                        'nine': targetStatusId == '97',
                        'seven': targetStatusId == '98',
                        'eight': targetStatusId == '99'}">
                        <span>{{ getStatusNameById(targetStatusId) | translate }}</span>  
                    </div>
                    <span>
                        {{ "you need to select 1 or more executors who will receive this status." | translate }}
                    </span>
                     
                </div>
            </ng-template> 
        </ng-container>
        <ng-template #elseTemplateTargetType>
            
            <ng-container *ngIf="isUpperStatus; else elseTemplateDirection">
                <div class="d-f-c">
                    <span>
                        {{ "To change a chat to status" | translate }}
                    </span>
                    
                    <div class="inside_job ml-1 mr-1" [ngClass]="{
                        'first': targetStatusId == '1',
                        'second': targetStatusId == '2',
                        'third': targetStatusId == '3',
                        'fours': targetStatusId == '4',
                        'fives': targetStatusId == '5',
                        'sixs': targetStatusId == '6',
                        'nine': targetStatusId == '97',
                        'seven': targetStatusId == '98',
                        'eight': targetStatusId == '99'}">
                        <span>{{ getStatusNameById(targetStatusId) | translate }}</span>  
                    </div>
                    <span>
                        {{ "You need to change the personal statuses of all executors." | translate }}
                    </span>
                     
                </div>
            </ng-container>
            <ng-template #elseTemplateDirection>
                <div class="d-f-c">
                    <span>
                        {{ "To change a chat to status" | translate }}
                    </span>
                    
                    <div class="inside_job ml-1 mr-1" [ngClass]="{
                        'first': targetStatusId == '1',
                        'second': targetStatusId == '2',
                        'third': targetStatusId == '3',
                        'fours': targetStatusId == '4',
                        'fives': targetStatusId == '5',
                        'sixs': targetStatusId == '6',
                        'nine': targetStatusId == '97',
                        'seven': targetStatusId == '98',
                        'eight': targetStatusId == '99'}">
                        <span>{{ getStatusNameById(targetStatusId) | translate }}</span>  
                    </div>
                    <span>
                        {{ "you need to select 1 or more executors who will receive this status." | translate }}
                    </span>
                     
                </div>
            </ng-template>
        </ng-template>
        
        
        <div style="color: #c0392b;">{{ "If you cancel, the status will not change." | translate }}</div>
        
        <div class="mob_stat_empl_wrp">
            <div class="mob_stat_empl" *ngFor="let empl of employees" [ngClass]="{
                'active': empl.active,
                'first': empl?.employeeStatus?.status_id == '1' || empl?.partnerCompanyStatus?.status_id == '1',
                'second': empl?.employeeStatus?.status_id == '2' || empl?.partnerCompanyStatus?.status_id == '2',
                'third': empl?.employeeStatus?.status_id == '3' || empl?.partnerCompanyStatus?.status_id == '3',
                'fours': empl?.employeeStatus?.status_id == '4' || empl?.partnerCompanyStatus?.status_id == '4',
                'fives': empl?.employeeStatus?.status_id == '5' || empl?.partnerCompanyStatus?.status_id == '5',
                'sixs': empl?.employeeStatus?.status_id == '6' || empl?.partnerCompanyStatus?.status_id == '6',
                'nine': empl?.employeeStatus?.status_id == '97' || empl?.partnerCompanyStatus?.status_id == '97',
                'seven': empl?.employeeStatus?.status_id == '98' || empl?.partnerCompanyStatus?.status_id == '98',
                'eight': empl?.employeeStatus?.status_id == '99' || empl?.partnerCompanyStatus?.status_id == '99'}"
                (click)="isUpperStatus ? null : selectUser(empl)">
                <ng-container *ngIf="!!empl.is_partner; else elseTemplatePartnerAdd">
                    <ng-container *ngIf="!!empl?.partnerCompany?.avatarFile?.thumbnail && !!host; else elseTemplatePartnerAvatar">
                        <img class="partner_avatar" (error)="onPartnerImgError($event)" [src]="host + empl?.partnerCompany?.avatarFile?.thumbnail + '?company_id=' + company?.id" alt="">
                    </ng-container>
                    <ng-template #elseTemplatePartnerAvatar>
                        <img class="partner_avatar" (error)="onPartnerImgError($event)" src="{{ imgRoute }}assets/img/partner.png">
                    </ng-template>
                    <span>{{ empl.partnerCompany.name }}</span>
                </ng-container>
                <ng-template #elseTemplatePartnerAdd>
                    <ng-container *ngIf="empl?.employee?.image; else elseStatEmplempl">
                        <img [src]="empl?.employee?.image" alt="">
                    </ng-container>
                    <ng-template #elseStatEmplempl>
                        <mat-icon>account_circle</mat-icon>
                    </ng-template>
                    <span>
                        {{empl?.employee?.name}}
                    </span>
                </ng-template>
            </div>
        </div>
    </div>
    
    <button mat-raised-button type="button" color="warn" (click)="no()">
        {{ (!!data.btn_no ? data.btn_no : "No") | translate }}</button>
    <button mat-raised-button class="change" #btnFocus cdkFocusInitial type="submit" color="primary" (click)="yes()">{{ (!!data.btn_yes ? data.btn_yes : "Yes") | translate }}</button>

</div>