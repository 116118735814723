import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { AuthService } from 'src/app/shared/services/rest/auth.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import * as moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/en-gb';
import 'moment/locale/uk';
import 'moment/locale/ru';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { bool } from 'aws-sdk/clients/signer';
import { debounceTime, map, switchMap, tap } from 'rxjs/operators';
import { of, ReplaySubject } from 'rxjs';
import { prioritys } from 'src/app/shared/consts/prioritys';
import { MembersService } from 'src/app/shared/services/rest/members.service';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { GlobalDataService } from 'src/app/shared/services/common/global-data.service';
import { ScenariosService } from 'src/app/shared/services/rest/scenarios.service';
import { MY_FORMATS } from '../../atTasksDialog/task-profile-add/task-profile-add.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-mob-add-job',
  templateUrl: './mob-add-job.component.html',
  styleUrls: ['./mob-add-job.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class MobAddJobComponent extends BaseClass implements OnInit, OnDestroy {
  public host: any = environment.host;
  public imgRoute: any = '';
  public user_id: number;
  public form: FormGroup;
  public partnerForm: FormGroup;
  public additional: FormGroup;
  public templateForm: FormGroup;
  public workEmpl: FormGroup;
  public company_id: any;
  public task_id: any;
  public operations: any;
  public partners: any;
  public templates: any;
  public currencyList: any;
  public prioritys: any = prioritys;
  public operationStatuses: any;
  public employees: any;
  public addEmployees: any;
  public numberRegEx = /\d*\.?\d{1,2}/;
  public isFormChange: boolean = false;
  public showStatuses: boolean = false;
  public showPart: boolean = false;
  public isSubmit: boolean = false;
  public submited: boolean = false;

  public executorIndex: FormControl = new FormControl(0);
  public selectedIndex: FormControl = new FormControl(0);
  public aboutIndex: FormControl = new FormControl(0);
  public priceIndex: FormControl = new FormControl(0);
  
  public employeeMoreControl: FormControl = new FormControl();
  public employees$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public addEmployeeMoreControl: FormControl = new FormControl();
  public addEmployees$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public operationMoreControl: FormControl = new FormControl();
  public operations$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public partnerMoreControl: FormControl = new FormControl();
  public partners$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public templatesMoreControl: FormControl = new FormControl();
  public templates$: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<MobAddJobComponent>,
    private sm: StorageManagerService,
    private auth: AuthService,
    private companyService: CompanyService,
    private globalDataService: GlobalDataService,
    private fb: FormBuilder,
    private taskService: TaskService,
    private _adapter: DateAdapter<any>,
    private languageService: LanguageService,
    private layoutService: LayoutService,
    private scenariosService: ScenariosService,
    private membersService: MembersService
  ) { super() }

  ngOnInit(): void {
    window.scroll(0,0);
    console.log("MobAddJobComponent", this.data)

    this.company_id = this.data.company_id || this.data.company.id;
    this.task_id = this.data.task.id;

    this.getCompany();
    this.getPartners();
    this.getImgRoute();
    this.getCsrf();

    this.attachSubscriptions(
      this.employeeMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchEmoloyees(resp))
    )
    this.attachSubscriptions(
      this.addEmployeeMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchAddEmoloyees(resp))
    )
    this.attachSubscriptions(
      this.operationMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchOperations(resp))
    )
    this.attachSubscriptions(
      this.partnerMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchPartners(resp))
    )

    this.workEmpl = this.fb.group({
      company_id: this.company_id,
      task_id: this.task_id,
      task_operation_id: 0,
      rate: [0, [Validators.required, Validators.pattern(this.numberRegEx)]],
      price: [0, [Validators.required, Validators.pattern(this.numberRegEx)]],
      employee_id: 0,
      is_manager: 1,
      status_id: 0,
      completed_at: ''
    })

    this.form = this.fb.group({
      task_id: this.task_id,
      operation_id: ['', Validators.required],
      status_id: ['', Validators.required],
      name: '',
      comment: '',
      priority: 0,
      private_comment: '',
      employee_comment: '',
      completed_at: ''
    })

    this.partnerForm = this.fb.group({
      company_id: this.company_id,
      task_id: this.task_id,
      task_operation_id: 0,
      partner_company_id: 0,
      is_manager: 1,
      rate: 0,
      price: 0,
      completed_at: '',
    })

    this.templateForm = this.fb.group({
      company_id: this.company_id,
      task_operation_id: 0,
      template_id: []
    })
    
    this.additional = this.fb.group({
      company_id: this.company_id,
      task_operation_id: '',
      employee_id: [],
      task_id: this.task_id,
      status_id: '',
      is_manager: 0
    })

    this.attachSubscriptions(
      this.form.valueChanges.subscribe(() => this.isFormChange = true)
    )
    this.attachSubscriptions(
      this.additional.valueChanges.subscribe(() => this.isFormChange = true)
    )
    this.attachSubscriptions(
      this.partnerForm.valueChanges.subscribe(() => this.isFormChange = true)
    )

    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        if (this.isFormChange) {
          this.layoutService.openBottomSheet(this.dialogRef);
        } else {
          this.close();
        }
      })
    )

    this.getOperations();
    this.getOperationsStatus();
    this.getEmployees();
    this.getCurrencyList();
    
    this.getLangData();
    this.getAutomationTemplates();
    this.attachSubscriptions(
      this.templatesMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchTemplates(resp))
    )
  }

  changePriceTab(val) {
    this.priceIndex.patchValue(val);
  }

  changeExecutorTab(val) {
    this.executorIndex.patchValue(val);
  }
  
  changeIndex(val) {
    this.selectedIndex.patchValue(val);
  }
  
  changeAboutTab(val) {
    this.aboutIndex.patchValue(val);
  }

  backPrice() {
    this.priceIndex.patchValue(0)
  }

  backAbout() {
    this.aboutIndex.patchValue(0)
  }

  cancelAbout() {
    
    this.backAbout();
  }

  cancelPrice() {
    
    this.backPrice();
  }

  back() {
    this.selectedIndex.patchValue(this.selectedIndex.value - 1)
  }

  next(val) {
    switch (val) {
      case 0:
        if (!this.form.value.operation_id) {
          this.submited = true;
          return false
        }
        break;
      case 1:
        // if (!this.form.value.name) {
        //   this.submited = true;
        //   return false
        // }
        break;
      case 3:
        if (!this.form.value.status_id) {
          this.submited = true;
          return false
        }
        break;
    
      default:
        break;
    }
    this.submited = false;
    this.selectedIndex.patchValue(this.selectedIndex.value + 1)
  }


  getAutomationTemplates() {
    this.attachSubscriptions(
      this.scenariosService.getAutomationTemplates('1', {company_id: this.data.company.id, is_task_operation: 1}, '200').pipe(
        map(el => el.body)
      ).subscribe(resp => {
        console.log("getAutomationTemplates", resp);
        this.templates = resp;
        this.templates$.next(this.templates.slice());
      })
    )
  }

  onSearchTemplates(resp) {
    if (!this.templates) {
      return;
    }

    if (!resp) {
      this.templates$.next(this.templates.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.templates$.next(
      this.templates.filter(z => z.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  onSearchPartners(resp) {
    if (!this.partners) {
      return;
    }

    if (!resp) {
      this.partners$.next(this.partners.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.partners$.next(
      this.partners.filter(z => z.partnerCompany.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  onSearchAddEmoloyees(resp) {
    if (!this.employees) {
      return;
    }

    if (!resp) {
      this.addEmployees$.next(this.employees.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.addEmployees$.next(
      this.employees.filter(z => z.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  onSearchEmoloyees(resp) {
    if (!this.employees) {
      return;
    }

    if (!resp) {
      this.employees$.next(this.employees.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.employees$.next(
      this.employees.filter(z => z.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  onSearchOperations(resp) {
    if (!this.operations) {
      return;
    }

    if (!resp) {
      this.operations$.next(this.operations.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.operations$.next(
      this.operations.filter(z => (z.name.toLowerCase().indexOf(resp) > -1))
    );
  }

  getCurrency(id) {
    if (!!this.currencyList) {
      return this.currencyList.find(el => el.id == id)
    }
  }

  getCurrencyList() {
    this.attachSubscriptions(
      this.globalDataService.getCurrencies().subscribe(resp => {
        console.log("getCurrencyList", resp)
        this.currencyList = resp.slice();
      })
    )
    // this.attachSubscriptions(
    //   this.companyService.getCurrencyList().subscribe(resp => {
    //     console.log("getCurrencyList", resp);
    //     this.currencyList = resp
    //   })
    // )
  }

  showMoreStatuses() {
    this.showStatuses = true;
  }

  selectType(type) {
    this.form.patchValue({
      operation_id: !!type.id ? type.id : type.value
    })
  }

  selectPrioritys = (priority: any) => {
    this.form.patchValue({
      priority: priority.id
    })
  }

  selectStatus(status) {
    this.form.patchValue({
      status_id: status.id
    })
  }

  getEmployeeById(id) {
    return this.employees.find(x => x.id == id)
  }

  showMorePart() {
    this.showPart = true;
  }

  toggleChip = (chip: any) => {
    this.workEmpl.patchValue({
      employee_id: !!chip.id ? chip.id : chip.value
    })
    this.partnerForm.patchValue({
      partner_company_id: ''
    })
    if (!!this.additional.value.employee_id && this.additional.value.employee_id.includes(!!chip.id ? chip.id : chip.value)) {
      let val = [...this.additional.value.employee_id]
      val.splice(this.additional.value.employee_id.indexOf(!!chip.id ? chip.id : chip.value), 1)
      this.additional.patchValue({
        employee_id: val
      })
    }
  }

  togglePartnerChip = (chip: any) => {
    this.partnerForm.patchValue({
      partner_company_id: !!chip.partner_company_id ? chip.partner_company_id : chip.value
    })

    this.workEmpl.patchValue({
      employee_id: ''
    })
    this.additional.patchValue({
      employee_id: []
    })
  }

  toggleChipTemplate = (chip: any) => {
    if (!!this.templateForm.value.template_id && this.templateForm.value.template_id.includes(chip.id)) {
      let val = [...this.templateForm.value.template_id]
      val.splice(this.templateForm.value.template_id.indexOf(chip.id), 1)
      this.templateForm.patchValue({
        template_id: val
      })
    } else {
      if (!!this.templateForm.value.template_id) {
        let val = [...this.templateForm.value.template_id]
        val.push(chip.id)
        this.templateForm.patchValue({
          template_id: val
        })
      } else {
        this.templateForm.patchValue({
          template_id: [chip.id]
        })
      }
    }
  }

  toggleAddChip = (chip: any) => {
    if (!!this.additional.value.employee_id && this.additional.value.employee_id.includes(!!chip.id ? chip.id : chip.value)) {
      let val = [...this.additional.value.employee_id]
      val.splice(this.additional.value.employee_id.indexOf(!!chip.id ? chip.id : chip.value), 1)
      this.additional.patchValue({
        employee_id: val
      })
    } else {
      if (!!this.additional.value.employee_id) {
        let val = [...this.additional.value.employee_id]
        val.push(!!chip.id ? chip.id : chip.value)
        this.additional.patchValue({
          employee_id: val
        })
      } else {
        this.additional.patchValue({
          employee_id: [!!chip.id ? chip.id : chip.value]
        })
      }
    }

    if ((!!chip.id ? chip.id : chip.value) == this.workEmpl.value.employee_id) {
      this.workEmpl.patchValue({
        employee_id: ''
      })
    }
  }

  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData().subscribe(resp => {
        this._adapter.setLocale(resp.active);
      })
    )
  }
  
  getCompany() {
    this.attachSubscriptions(
      this.companyService.getCompany(this.data.company.id).subscribe(resp => {
        this.companyService.company$.next(resp[0]);
      })
    )
  }

  getPartners() {
    this.attachSubscriptions(
      this.companyService.getPartners({company_id: this.data.company.id}).pipe(
        map(x => x.filter(b => b.partner_company_id))
      ).subscribe(resp => {
        this.partners = resp;
        this.partners$.next(this.partners.slice());
        console.log("PARTNERS", this.partners)
      })
    )
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  
  onPartnerImgError(event){
    event.target.src = this.imgRoute+'/assets/img/partner.png'
  }
  
  getCsrf() {
    this.attachSubscriptions(
      this.auth.$userToken.subscribe(resp => {
        this.user_id = resp.user_id;
        this.sm.localStorageSetItem("csrf_param", resp.csrf_param)
        this.sm.localStorageSetItem("csrf_token", resp.csrf_token)
      })
    )
  }
  
  getEmployees() {
    this.attachSubscriptions(
      this.taskService.getEmployees(this.data.company.id).subscribe(resp => {
        this.employees = resp;
        this.employees$.next(this.employees.slice())
        this.addEmployees = resp;
        this.addEmployees$.next(this.addEmployees.slice())
      })
    )
  }
  
  getOperations() {
    this.attachSubscriptions(
      this.taskService.getOperations(this.data.company.id).subscribe(resp => {
        this.operations = resp;
        this.operations$.next(this.operations.slice())
      })
    )
  }

  getOperationsStatus() {
    this.attachSubscriptions(
      this.taskService.getOperationsStatus().subscribe(resp => {
        this.operationStatuses = resp.slice();
      })
    )
  }

  close() {
    this.dialogRef.removePanelClass('animate__slideInUp')
    this.dialogRef.addPanelClass('animate__slideOutDown')
    setTimeout(()=>{this.dialogRef.close({event: "close", data: false})}, 300);
  }

  onSubmit() {
    if (!this.form.valid || !this.workEmpl.valid) {
      this.submited = true;
      this.layoutService.showSnackBar({name: ''}, marker("You need to fill in all required fields"), SnackBarItem)
      return
    }

    this.submited = false;
    this.isSubmit = true;
    if (String(this.workEmpl.value['rate']).indexOf(",") != -1) {
      this.workEmpl.value['rate'] = this.workEmpl.value['rate'].replace(",", ".");
    }
    if (String(this.workEmpl.value['price']).indexOf(",") != -1) {
      this.workEmpl.value['price'] = this.workEmpl.value['price'].replace(",", ".");
    }
    if (!!this.form.value.completed_at && !!this.form.value.completed_at._d) {
      this.form.value.completed_at = moment(this.form.value.completed_at._d).format("X");
    } else {
      delete this.form.value.completed_at;
    }
    
    // this.scenariosService.addAutomationScenario(this.templateForm.value)
    this.attachSubscriptions(
      this.taskService.addWork(this.data.company.id, this.form.value).pipe(
        switchMap(resp => {
          this.workEmpl.patchValue({
            task_operation_id: resp.id,
            status_id: resp.status_id,
            completed_at: this.form.value.completed_at,
          })

          console.log("this.workEmpl.value", this.workEmpl.value);
          if (this.workEmpl.value.employee_id != 0 && !!this.workEmpl.value.employee_id) {
            return this.membersService.addMember(this.workEmpl.value, this.data.company.id).pipe(map(() => resp))
          } else {
            return of(resp)
          }
        }),
        switchMap(resp => {
          if (!!this.partnerForm.value.partner_company_id) {
            this.partnerForm.patchValue({
              task_operation_id: resp.id,
              rate: this.workEmpl.value.rate,
              price: this.workEmpl.value.price,
              completed_at: this.workEmpl.value.completed_at,
              status_id: resp.status_id
            })
            console.log("this.partnerForm.value", this.partnerForm.value);
            return this.membersService.addTaskPartner(this.partnerForm.value, this.data.company.id).pipe(map(() => resp))
          } else {
            return of(resp)
          }
        }),
        switchMap((resp) => {
          this.additional.patchValue({
            task_operation_id: resp.id,
            status_id: resp.status_id
          })
          console.log("this.additional.value", this.additional.value);
          if (!!this.additional.value.employee_id && this.additional.value.employee_id.length) {
            let postData = [];
            let postInData = {...this.additional.value};
            delete postInData.employee_id
            this.additional.value.employee_id.forEach(element => {
              postData.push({
                "path": `/api/task-employee/`,
                "query": {},
                "method": "POST",
                "body": Object.assign({
                  [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
                  employee_id: element,
              }, postInData) 
              })
            });
            return this.taskService.multiRequest(postData).pipe(map(x => resp))
          } else {
            return of(resp)
          }
        }),
        switchMap((resp) => {
          this.templateForm.patchValue({
            task_operation_id: resp.id,
            status_id: resp.status_id
          })
          console.log("this.templateForm.value", this.templateForm.value);
          if (!!this.templateForm.value.template_id && this.templateForm.value.template_id.length) {
            let postData = [];
            let postInData = {...this.templateForm.value};
            delete postInData.template_id
            this.templateForm.value.template_id.forEach(element => {
              postData.push({
                "path": `/api/automation-scenario/`,
                "query": {company_id: this.company_id},
                "method": "POST",
                "body": Object.assign({
                  [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
                  template_id: element,
              }, postInData) 
              })
            });

            return this.taskService.multiRequest(postData).pipe(
              switchMap(res => {
                let tryData = [];
                res.forEach(x => {
                  tryData.push({
                    "path": `/api/automation-scenario/execute/${x.body.id}/`,
                    "query": {company_id: this.company_id},
                    "method": "POST",
                    "body": {}
                  })
                });
                return this.taskService.multiRequest(tryData)
              }),
              map(x => resp)
            )
          } else {
            return of(resp)
          }
        })
      ).subscribe(resp => {
        this.isSubmit = false;
        this.dialogRef.close({event: "update", data: resp});
      })
    )
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
