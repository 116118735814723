<h2 mat-dialog-title class="dialog_title">
    <span>
        {{ "Prepare for approval for" | translate }}
    </span>

    <div class="to_approve_users">
        
        <div class="d-f-c">
            <div class="to_approve_user">
                <ng-container *ngIf="!selectedEmpl?.is_partner; else elseTemplateIsPartnerStatus">                                                            
                    <ng-container *ngIf="selectedEmpl?.employee?.image; else elseTemplateUserHasImg">
                        <img [src]="selectedEmpl?.employee?.image" alt="">
                    </ng-container>
                    <ng-template #elseTemplateUserHasImg>
                        <mat-icon>account_circle</mat-icon>
                    </ng-template>
                    <span>
                        {{ selectedEmpl?.employee?.name }}
                    </span>
                </ng-container>
                <ng-template #elseTemplateIsPartnerStatus>
                    <ng-container *ngIf="!!selectedEmpl?.partnerCompany?.avatarFile?.thumbnail && !!data.host; else elseTemplatePartnerAvatar">
                        <img (error)="onPartnerImgError($event)" [src]="data.host + selectedEmpl?.partnerCompany?.avatarFile?.thumbnail + '?company_id=' + data?.company?.id">
                    </ng-container>
                    <ng-template #elseTemplatePartnerAvatar>
                        <img (error)="onPartnerImgError($event)" src="{{ imgRoute }}assets/img/partner.png">
                    </ng-template>
                    <span>{{selectedEmpl?.partnerCompany?.name}}</span>
                </ng-template>

            </div>
        </div>

    </div>
</h2>
<mat-dialog-content class="mat-typography" >
    <div class="container">
        <mat-horizontal-stepper labelPosition="bottom" [selectedIndex]="selectedIndex" linear #stepper (selectionChange)="selectionChange($event)">
            <mat-step>
                <ng-template matStepLabel>{{ 'What?' | translate }}</ng-template>
                <div class="step_inside" [ngClass]="{'no_active': tabIndex == 3}">
                    <mat-tab-group [selectedIndex]="tabIndex" (selectedIndexChange)="changeTab($event)">
                        <mat-tab>
                          <ng-template mat-tab-label>
                            {{ "Files" | translate }}
                          </ng-template>

                            <app-file-manager
                                [useOut]="true"
                                [initLocation]="'/to_approve'"
                                [initData]="{
                                    'task': data?.task,
                                    'work': data?.job,
                                    'company': data?.company
                                }"
                            ></app-file-manager>
                        </mat-tab>
                      
                        <mat-tab>
                            <ng-template mat-tab-label>
                                {{ "Link to resources" | translate }}
                            </ng-template>
                            <form [formGroup]="form">
                                <mat-form-field appearance="standard" class="full_width_form">
                                    <mat-label>{{ 'Content url' | translate }}</mat-label>
                                    <input formControlName="content_url" matInput placeholder="{{ 'Content url' | translate }}">
                                    <mat-hint *ngIf="!form.get('content_url').value" style="color: red;">{{ "Put the link to file or folder you are submitting for approval" | translate }}</mat-hint>
                                </mat-form-field>
                            </form>
                        </mat-tab>
                      
                        <mat-tab>
                          <ng-template mat-tab-label>
                            {{ "Nothing needed" | translate }}
                          </ng-template>
                        </mat-tab>

                        <mat-tab disabled>
                          <ng-template mat-tab-label>
                            
                          </ng-template>
                          <div class="hidden_text">
                              {{ "Select from above what do you want to provide for aproval, please." | translate}}
                          </div>
                        </mat-tab>
                    </mat-tab-group>
                </div>
            </mat-step>
            <mat-step [stepControl]="completedForm">
                <ng-template matStepLabel>{{ 'When?' | translate }}</ng-template>
                <div class="step_inside center_step">
                    <div class="step_title">
                        {{ 'When did you finish this assignement?' | translate }}
                    </div>
                    <form [formGroup]="completedForm">
                        <mat-form-field class="full_width_form">
                            <input matInput [ngxMatDatetimePicker]="picker" placeholder="Choose a date" formControlName="completed_at">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <ngx-mat-datetime-picker #picker [showSpinners]="true" [touchUi]="false" [color]="'primary'">
            
                              <ng-template>
                                <span>OK</span>
                              </ng-template>
                            </ngx-mat-datetime-picker>
                        </mat-form-field>
                    </form>
                </div>
            </mat-step>
            <mat-step *ngIf="hasCostsStep">
                <ng-template matStepLabel>{{ 'Costs' | translate }}</ng-template>
                <div class="step_inside center_step">
                    <div class="step_title">
                        {{ 'How much do you charge for this assignment?' | translate }}
                    </div>
                    <form [formGroup]="costsForm">
                        <mat-form-field *ngIf="costsForm.get('rate')" appearance="standard" class="full_width_form">
                            <mat-label>{{ 'Points I charge for this job' | translate }}</mat-label>
                            <input formControlName="rate" matInput type="number" placeholder="{{ 'Points I charge for this job' | translate }}">
                        </mat-form-field>
                        
                        <mat-form-field appearance="standard" class="full_width_form" *ngIf="costsForm.get('price')">
                            <mat-label>{{ 'Price I charge for this job' | translate }}</mat-label>
                            <input formControlName="price" matInput type="number" placeholder="{{ 'Price I charge for this job' | translate }}">
                            <div class="cur_suffix" matSuffix>{{ getCurrency(selectedEmpl?.price_currency_id)?.iso_name }}</div>
                        </mat-form-field>
                    </form>
                </div>
            </mat-step>
        </mat-horizontal-stepper>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <div class="to_approve_actions">
        <div class="to_approve_btns">

            <ng-container [ngSwitch]="selectedIndex">
                <ng-container *ngSwitchCase="'0'">
                    <button mat-raised-button (click)="close()">
                        {{ "Close" | translate }}
                    </button>
                    <ng-container [ngSwitch]="tabIndex">
                        <ng-container *ngSwitchCase="'0'">
                            <button class="ml-1" mat-raised-button (click)="saveFiles()" color="primary">
                                {{ "Next" | translate }}
                            </button>
                        </ng-container>
                        <ng-container *ngSwitchCase="'1'">
                            <button class="ml-1" mat-raised-button (click)="saveUrl()" color="primary">
                                {{ "Next" | translate }}
                            </button>
                        </ng-container>
                    </ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'1'">
                    <button mat-raised-button (click)="goBack()">
                        {{ "Back" | translate }}
                    </button>
                    <button class="ml-1 d-f-btn" mat-raised-button (click)="saveCompletedAt()" color="primary" [disabled]="isSaveCompletedAt">
                        <mat-spinner class="spin_16 mr-1" *ngIf="isSaveCompletedAt"></mat-spinner>  
                        {{ "Next" | translate }}
                    </button>
                </ng-container>
                <ng-container *ngSwitchCase="'2'">
                    <button mat-raised-button (click)="goBack()">
                        {{ "Back" | translate }}
                    </button>
                    <button class="ml-1 d-f-btn" mat-raised-button (click)="saveCosts()" color="primary" [disabled]="isSaveCosts">
                        <mat-spinner class="spin_16 mr-1" *ngIf="isSaveCosts"></mat-spinner>  
                        {{ "Finish" | translate }}
                    </button>
                </ng-container>
                <ng-container *ngSwitchDefault>
                    <button mat-raised-button (click)="close()">
                        {{ "Close" | translate }}
                    </button>
                </ng-container>
            </ng-container>
        </div>

        <div class="to_approve_users with_bg" *ngIf="selectedEmpls.length > 0">
            <span>{{ "Next forms are for:" | translate }}</span>

            <div class="d-f-c">
                <div class="to_approve_user" *ngFor="let empl of selectedEmpls">

                    <ng-container *ngIf="!empl?.is_partner; else elseTemplateIsPartnerStatus">                                                            
                        <ng-container *ngIf="empl?.employee?.image; else elseTemplateUserHasImg">
                            <img [src]="empl?.employee?.image" alt="">
                        </ng-container>
                        <ng-template #elseTemplateUserHasImg>
                            <mat-icon>account_circle</mat-icon>
                        </ng-template>
                        <span>
                            {{ empl?.employee?.name }}
                        </span>
                    </ng-container>
                    <ng-template #elseTemplateIsPartnerStatus>
                        <ng-container *ngIf="!!empl?.partnerCompany?.avatarFile?.thumbnail && !!data.host; else elseTemplatePartnerAvatar">
                            <img (error)="onPartnerImgError($event)" [src]="data.host + empl?.partnerCompany?.avatarFile?.thumbnail + '?company_id=' + data?.company?.id">
                        </ng-container>
                        <ng-template #elseTemplatePartnerAvatar>
                            <img (error)="onPartnerImgError($event)" src="{{ imgRoute }}assets/img/partner.png">
                        </ng-template>
                        <span>{{empl?.partnerCompany?.name}}</span>
                    </ng-template>
                </div>
            </div>

        </div>
    </div>
</mat-dialog-actions>
