import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { forkJoin } from 'rxjs';
import { last, map, switchMap, tap } from 'rxjs/operators';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { QuestsService } from 'src/app/shared/services/rest/quests.service';
import { StatementService } from 'src/app/shared/services/rest/statement.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';

@Component({
  selector: 'app-add-date-variable',
  templateUrl: './add-date-variable.component.html',
  styleUrls: ['./add-date-variable.component.scss']
})
export class AddDateVariableComponent extends BaseClass implements OnInit, OnDestroy {
  public form: FormGroup;
  // public forms: any;
  // public fields: any;
  public statementVariables = ['created_at', 'time_start', 'time_end']
  public statementVariablesTranslate = {'created_at': 'Creation Date', 'time_start': 'Start Date', 'time_end': 'End Date'}
  public isFormChange: boolean = false;
  public isSubmit: boolean = false;
  public isGettingFields: boolean = false;
  public sources:any = [
    {
      value: '$statement',
      name: "Statement"
    }
  ]

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<AddDateVariableComponent>,
    private layoutService: LayoutService,
    private questsService: QuestsService,
  ) { super() }

  ngOnInit(): void {

    console.log("AddDateVariableComponent", this.data)

    // this.getForms()

    this.form = this.fb.group({
      source: ['', Validators.required],
      var: ['', Validators.required],
      date_format: ['', Validators.required],
    })

    this.attachSubscriptions(
      this.form.get('source').valueChanges.subscribe(res => {
        // if (res == "{$employee.application.") {
        //   this.form.addControl('form_id', this.fb.control('', Validators.required))
        //   this.form.addControl('field', this.fb.control('', Validators.required))
        //   if (this.form.get('statement_field')) {
        //     this.form.removeControl('statement_field')
        //   }
        // } else {
        //   if (this.form.get('form_id')) {
        //     this.form.removeControl('form_id')
        //     this.form.removeControl('field')
        //   }
        //   this.form.addControl('statement_field', this.fb.control('', Validators.required))
        // }
      })
    )

    this.attachSubscriptions(
      this.form.valueChanges.subscribe(() => this.isFormChange = true)
    )

    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        if (this.isFormChange) {
          this.layoutService.openBottomSheet(this.dialogRef);
        } else {
          this.close();
        }
      })
    )
  }

  // getFieldById(id) {
  //   return this.fields && this.fields.find(x => x.id == id)
  // }

  // getForms() {
  //   console.log("getForms this.data.company.id", this.data.company.id)
  //   this.attachSubscriptions(
  //     this.questsService.getQuestsDyn('1', this.data.company.id, '1').pipe(
  //       switchMap(el => {
  //         let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
  //         let arr = []
  //         for (let index = 1; index <= pages; index++) {
  //           arr.push(index)
  //         }

  //         return forkJoin(arr.map(x => this.questsService.getQuestsDyn(x, this.data.company.id, '200').pipe(map(u => u.body)))).pipe(
  //           last(),
  //         )
  //       }),
  //     ).subscribe(res => {
  //       this.forms = [].concat(...res);
  //     })
  //   )
  // }

  // getFields(aId) {
  //   this.isGettingFields = true;
  //   this.attachSubscriptions(
  //     this.questsService.getFields('1', this.data.company.id, aId, '1').pipe(
  //       switchMap(el => {
  //         let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
  //         let arr = []
  //         for (let index = 1; index <= pages; index++) {
  //           arr.push(index)
  //         }

  //         return forkJoin(arr.map(x => this.questsService.getFields(x, this.data.company.id, aId, '200').pipe(map(u => u.body)))).pipe(
  //           last(),
  //         )
  //       }),
  //     ).subscribe(res => {
  //       this.fields = [].concat(...res);
  //       this.isGettingFields = false
  //     })
  //   )
  // }
  
  close() {
    this.dialogRef.close({event: "close", data: false});
  }

  submitForm() {
    let sbmtForm:any = {...this.form.value};
    sbmtForm.name = '';
    if (sbmtForm.var && this.statementVariablesTranslate[sbmtForm.var]) {
      sbmtForm.name = this.statementVariablesTranslate[sbmtForm.var]
    }
    this.dialogRef.close({event: "close", data: sbmtForm});
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
