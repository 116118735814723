import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BaseClass } from '../../models/base-class';
import { MembersService } from '../../services/rest/members.service';
import { MatDialog } from '@angular/material/dialog';
import { LayoutService } from '../../services/common/layout.service';
import { SnackBarItem } from '../snack-bar/snack-bar-item';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { TaskService } from '../../services/rest/task.service';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { EditCompletedAtComponent } from 'src/app/components/workspace-pages/cases/dialogs/executors/dialogs/edit-completed-at/edit-completed-at.component';
import { EditPriceComponent } from 'src/app/components/workspace-pages/cases/dialogs/executors/dialogs/edit-price/edit-price.component';
import { OperationChatComponent } from 'src/app/components/atTasksDialog/operation-chat/operation-chat.component';
import { ChatService } from '../../services/rest/chat.service';
import { casesModel } from '../../functions/casesModel';
import { SetEmplComponent } from 'src/app/components/workspace-pages/cases/dialogs/members/set-empl/set-empl.component';
import { TaskAddClientComponent } from 'src/app/components/clients/task-add-client/task-add-client.component';

@Component({
  selector: 'app-lite-executors',
  templateUrl: './lite-executors.component.html',
  styleUrls: ['./lite-executors.component.scss']
})
export class LiteExecutorsComponent extends BaseClass implements OnInit, OnDestroy {
  @Input() target: any;
  @Input() company: any;
  @Input() is_mobile: any;
  @Input() host: any;
  @Input() user: any;
  @Input() activeLang: any;
  @Input() imgRoute: any;
  @Input() useOut: boolean = false;
  public statuses: any;
  public chatsIndex: FormControl = new FormControl(0);
  public operationsValues: any;
  public origin = window.location.origin;
  throttle = 100;
  scrollUpDistance = 3.5;
  public selectedExec: any;
  constructor(
    private dialog: MatDialog,
    private chatService: ChatService,
    private taskService: TaskService,
    private membersService: MembersService,
    private layoutService: LayoutService,
  ) {
    super()
   }

  ngOnInit(): void {
    this.getMembers();
    this.getOperations();
    this.getOperationsStatus();
  }

  addExecutor(add_partner?) {
    const dialogRef = this.dialog.open(SetEmplComponent, {
      data: {
        company: this.company,
        user: this.user,
        task: this.target.task,
        work: this.target,
        is_manager: undefined,
        add_partner: add_partner,
        executors: true
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result && result.event == "update") {
          this.getMembers()
        }
      })
    )
  }

  addClient() {
    const dialogRef = this.dialog.open(TaskAddClientComponent, {
      data: {
        company: this.company,
        company_id: this.company.id,
        task: this.target.task,
        work: this.target,
        chat: undefined,
        user: this.user,
        clients: this.target.outputExecutors.clients
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log(result)
        if (result.event == 'update') {
          this.getMembers()
        }
      })
    )
  }

  addPartnerClient() {
    const dialogRef = this.dialog.open(TaskAddClientComponent, {
      data: {
        company: this.company,
        company_id: this.company.id,
        task: this.target.task,
        work: this.target,
        chat: undefined,
        user: this.user,
        clients: this.target.outputExecutors.clients,
        is_partner: true,
        imgRoute: this.imgRoute,
        host: this.host
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log(result)
        if (result.event == 'update') {
          this.getMembers()
        }
      })
    )
  }


  getMembers() {
    this.target.outputExecutors = {
      targetIds: this.target.targetIds,
      employees: [],
      partnerCompanies: [],
      clients: [],
      is_load: true,
    }
    let filter = {task_id: this.target.outputExecutors.targetIds.task_id, task_operation_id: this.target.outputExecutors.targetIds.task_operation_id}

    // if (this.data.chat) {
    //   filter['discussion_id'] = this.data.chat.id
    // }

    let arr = [
      this.membersService.getMembers(this.company.id != this.target.company_id ? Object.assign({partner_company_id: this.target.company_id}, filter) : filter, this.company.id).pipe(
        tap(res => {
          console.log("getMembers", res)
          res.forEach(el => {
            if (this.target.outputExecutors.employees.filter(u => u.employee_id == el.employee_id).length == 0) {
              this.target.outputExecutors.employees.push(el);
            }
          })
        })
      ),
      this.membersService.getTaskPartners(this.company.id != this.target.company_id ? Object.assign({partner_company_id: this.target.company_id}, filter) : filter, this.company.id).pipe(
        tap(res => {
          console.log("getTaskPartners", res)
          res.forEach(el => {
            el.is_partner = true;
            if (this.target.outputExecutors.partnerCompanies.filter(u => u.partner_company_id == el.partner_company_id).length == 0) {
              this.target.outputExecutors.partnerCompanies.push(el);
            }
          })
        })
      )
    ]
    arr.push(
      this.taskService.getTaskClientsDyn("1",this.target.outputExecutors.targetIds.task_id, this.company.id, "200", {task_operation_id: `0,${this.target.outputExecutors.targetIds.task_operation_id}`}).pipe(
        map(x => x.body),
        tap(resp => {
          console.log("getTaskClients", resp)
          if (this.target.outputExecutors.targetIds.discussion_id) {
            this.target.outputExecutors.clients = resp.filter(x => !x.discussion_id || x.discussion_id == this.target.outputExecutors.targetIds.discussion_id);
          } else {
            this.target.outputExecutors.clients = resp;
          }
          console.log("getClients this.clients", this.target.outputExecutors.clients);
          
        })
      )
    )
    if (this.company.id != this.target.company_id) {
      arr.push(
        this.membersService.getTaskPartnerEmployees(filter, this.company.id, this.target.company_id).pipe(
          tap(res => {
            console.log('getTaskPartnerEmployees', res)
            console.log('this.target.outputExecutors.employees', this.target.outputExecutors.employees)
            res.forEach(el => {
              el.is_partner_employee = true;
              // if ((el.discussion_id == 0 || !el.discussion_id) && el.partnerEmployeeStatus && this.target.outputExecutors.employees.find(x => x.id == el.id)) {
              //   this.target.outputExecutors.employees.find(x => x.id == el.id).employeeStatus = el.partnerEmployeeStatus
              // }
              if (this.target.outputExecutors.employees.filter(u => u.partner_company_id == el.partner_company_id).length == 0) {
                this.target.outputExecutors.employees.push(el);
              }
            })
          })
        )
      )
    }
    this.attachSubscriptions(
      forkJoin(arr).subscribe(resp => {
        console.log("getMembers", resp)
        this.target.outputExecutors.is_load = false;
        console.log("getMembers", this.target.outputExecutors)
      })
    )
  }

  getOperations() {
    this.attachSubscriptions(
      this.taskService.getOperations(this.company.id, this.activeLang).subscribe(resp => {
        this.operationsValues = resp;
      })
    )
  }

  neededData(task, company) {
    let arr = [
      this.chatService.getTasksChats(company.id, [task.id]).pipe(
        tap(res => {
          task['operations'].map(o_el => {
            o_el['chatsInfo'] = [];
            o_el['chatsInfo'].push(...res.filter(z => z.task_operation_id == o_el.id));
            o_el['unReadChats'] = res.filter(z => z.task_operation_id == o_el.id && z.is_read != '1').length;
            o_el['unClosedChats'] = res.filter(z => z.task_operation_id == o_el.id && z.status_id != '4').length;
          });
          task['chatsInfo'] = [];
          task['chatsInfo'].push(...res.filter(z => z.task_id == task.id));
          task['unReadChats'] = res.filter(z => z.is_read != '1').length;
          task['unClosedChats'] = res.filter(z => z.status_id != '4').length;
        })
      )
    ]
    if (task.company_id == company.id) {
      arr.push(
        this.taskService.getTaskClients(task.id, company.id).pipe(
          tap(res => {
            task.clients = res
          }),
          catchError(err => {
            return err
          })
        )
      )
    }
    return forkJoin(arr)
  }

  checkIsManager(task, company, _user) { 
    return task?.managers.filter(x => x.user_id == _user.id).length > 0 || task?.group?.managers.filter(x => x.user_id == _user.id).length > 0 || company?.permissions.includes('admin') || company?.permissions.includes('owner') || company?.permissions.includes('manager'); 
  }
  
  goToChatStatus(chat) {
    console.log("goToChat", chat)
    this.attachSubscriptions(
      this.taskService.getOneTaskExpand(this.company.id, this.target.outputExecutors.targetIds.task_id).pipe(
        map(x => x.body[0]),
        switchMap(x => this.neededData(x, this.company).pipe(map(() => casesModel([x], [], 'update')),map(x => x.arr[0]))),
      ).subscribe(resp => {
        const goToChat = this.dialog.open(OperationChatComponent, {
          backdropClass: ['backdrop_under_header', "without_pad"],
          panelClass: !this.is_mobile ? ['full_screen_dialog', 'global_chats_dialog', 'show_header'] : ['full_screen_dialog', 'global_chats_dialog'],
          position: !this.is_mobile ? { top: '58px', left: '0', right: '0', bottom: '0' } : {},
          autoFocus: this.is_mobile ? false : true,
          data: {
            company: this.company,
            task: resp,
            work: !!this.target.outputExecutors.targetIds.task_operation_id && resp.operations.find(u => u.id == this.target.outputExecutors.targetIds.task_operation_id) ? resp.operations.find(u => u.id == this.target.outputExecutors.targetIds.task_operation_id) : undefined,
            dialogType: 'operation_chat',
            user: this.user,
            empl_status: this.company.permissions.includes('owner') || this.checkIsManager(resp, this.company, this.user),
            chat: chat,
            chats: this.selectedExec.exec.chats,
            closeFull: true,
            operationsValues: this.operationsValues
          }
        });
    
        this.attachSubscriptions(
          goToChat.afterClosed().subscribe(result => {

          })
        )
      })
    )
  }

  // goToChatStatus(chat) {
  //   const goToChat = this.dialog.open(OperationChatComponent, {
  //     backdropClass: ['backdrop_under_header', "without_pad"],
  //     panelClass: ['full_screen_dialog', 'global_chats_dialog'],
  //     disableClose: true,
  //     data: {
  //       company: this.company,
  //       task: this.target.task,
  //       tasks: [],
  //       operationsValues: this.operationsValues,
  //       work: this.target,
  //       dialogType: 'operation_chat',
  //       user: this.user,
  //       chat: chat,
  //     }
  //   });

  //   this.attachSubscriptions(
  //     goToChat.afterClosed().subscribe(result => {
  //       if (!!result && result.event == 'close') {
  //         this.chatService.postChatRead(chat.id, 0, this.company.id).subscribe(resp => {
  //           console.log(resp);
  //         })
  //       }
  //     })
  //   )
  // }

  getStatus(id) {
    if (!this.statuses || this.statuses.filter(el => el.id == id).length == 0) {
      return '';
    }
    return this.statuses.find(el => el.id == id)
  }

  getOperationsStatus() {
    this.attachSubscriptions(
      this.taskService.getOperationsStatus().subscribe(resp => {
        this.statuses = resp.slice();
      })
    )
  }

  deactiveUser() {
    this.selectedExec = undefined;
  }

  changeCompletedAt(empl) {   
    const dialogRef = this.dialog.open(EditCompletedAtComponent, {
      data: {
        company: this.company,
        user: this.user,
        employee: empl,
        showError: () => { this.showError() }
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (result && result.event == "update") {
          if (this.selectedExec && result.data) {
            this.selectedExec.exec.completed_at = result.data.completed_at
          }
        }
      })
    )
  }
  
  changePrice(empl) {
    
    const dialogRef = this.dialog.open(EditPriceComponent, {
      data: {
        company: this.company,
        user: this.user,
        employee: empl,
        showError: () => { this.showError() }
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (result && result.event == "update") {
          if (this.selectedExec && result.data) {
            this.selectedExec.exec.price = result.data.price
            this.selectedExec.exec.price_currency_id = result.data.price_currency_id
            this.selectedExec.exec.rate = result.data.rate
          }
        }
      })
    )
  }

  changePriceManager(jobEmpl) {
    jobEmpl.pricePermIsChanging = true;
    if (this.selectedExec) {
      this.selectedExec.exec.pricePermIsChanging = true
    }
    this.attachSubscriptions(
      (!!jobEmpl.is_partner ? this.membersService.editTaskPartner(jobEmpl.id, {is_price_manager: !!jobEmpl.is_price_manager ? 0 : 1}, this.company.id) : (!!jobEmpl.is_partner_employee ? this.membersService.editTaskPartnerEmployee(jobEmpl.id, {is_price_manager: !!jobEmpl.is_price_manager ? 0 : 1}, this.company.id) : this.membersService.editMember(jobEmpl.id, {is_price_manager: !!jobEmpl.is_price_manager ? 0 : 1}, this.company.id))).subscribe(resp => {
        jobEmpl.is_price_manager = !!jobEmpl.is_price_manager ? 0 : 1;
        jobEmpl.disabled = false;
        jobEmpl.pricePermIsChanging = false;
        if (this.selectedExec) {
          this.selectedExec.exec.pricePermIsChanging = false
        }
      },
      error => {
        this.showError();
        jobEmpl.pricePermIsChanging = false;
        if (this.selectedExec) {
          this.selectedExec.exec.pricePermIsChanging = false
        }
      })
    )
  }
  
  activeUser(exec, type, i) {
    this.selectedExec = {
      exec: exec,
      type: type,
      ind: i
    }

    if (type.indexOf("client") == -1) {
      this.toggleChats(exec);
    }
    // this.ActUser.next(this.selectedExec);
  }

  toggleChats(empl) {
    console.log(empl)
    if (!!empl.opened) {
      empl.opened = false
    } else {
      empl.opened = true;
      let filter;
      if (empl.is_partner || empl.is_partner_employee) {
        filter = {partner_company_id: empl.partner_company_id, 'per-page': "200"}
      } else {
        filter = {employee_id: empl.employee_id, 'per-page': "200"}
      }

      filter.task_operation_id = this.target.outputExecutors.targetIds.task_operation_id;
      console.log("filter", filter)
      this.attachSubscriptions(
        this.chatService.getChats(this.company.id, this.target.outputExecutors.targetIds.task_id, filter).pipe(tap(element => {
          element.map(x => {
            if (empl.is_partner) {
              x.partnerCompanyStatus = undefined
              if (!x.partnerCompanyStatus && x.partnerCompaniesStatuses.filter(y => y.partner_company_id == empl.partner_company_id && y.task_operation_id == empl.task_operation_id).length > 0) {
                x.partnerCompanyStatus = x.partnerCompaniesStatuses.find(y => y.partner_company_id == empl.partner_company_id && y.task_operation_id == empl.task_operation_id)
              }
            } else if (empl.is_partner_employee) {
              console.log("set DISC STATUS", x.partnerEmployeesStatuses);
              console.log("set DISC STATUS", x.partnerEmployeesStatuses.filter(y => y.partner_employee_id == empl.partner_employee_id && y.task_operation_id == empl.task_operation_id));

              x.employeeStatus = undefined
              if (!x.employeeStatus && x.partnerEmployeesStatuses.filter(y => y.partner_employee_id == empl.partner_employee_id && y.task_operation_id == empl.task_operation_id).length > 0) {
                x.employeeStatus = x.partnerEmployeesStatuses.find(y => y.partner_employee_id == empl.partner_employee_id && y.task_operation_id == empl.task_operation_id)
              }
            } else {
              x.employeeStatus = undefined
              if (!x.employeeStatus && x.employeesStatuses.filter(y => y.employee_id == empl.employee_id && y.task_operation_id == empl.task_operation_id).length > 0) {
                x.employeeStatus = x.employeesStatuses.find(y => y.employee_id == empl.employee_id && y.task_operation_id == empl.task_operation_id)
              }
            }
          })
        })).subscribe(resp => {
          console.log("getChats", resp)
          empl.chats = resp;
          if (this.selectedExec) {
            this.selectedExec.exec.chats = resp;
          }
          console.log("getChats EMPLOYEE", empl)
        })
      )
    }
  }

  togglePartner(e, client) {
    client.disabled = true
    this.taskService.editClientInTask(client.id, {is_principal: Number(e.checked)}, this.company.id).subscribe(resp => {
      client.is_principal = Number(e.checked);
      client.disabled = false;
    })
  }

  changeType(exec) {
    exec.typeIsChanging = true;
    if (this.selectedExec) {
      this.selectedExec.exec.typeIsChanging = true
    }
    this.attachSubscriptions(
      (!!exec.is_partner ? this.membersService.editTaskPartner(exec.id, {is_manager: !!exec.is_manager ? 0 : 1}, this.company.id) : (!!exec.is_partner_employee ? this.membersService.editTaskPartnerEmployee(exec.id, {is_manager: !!exec.is_manager ? 0 : 1}, this.company.id) : this.membersService.editMember(exec.id, {is_manager: !!exec.is_manager ? 0 : 1}, this.company.id))).subscribe(resp => {
        // this.workAfterClosed();
        if (this.selectedExec) {
          this.selectedExec.exec.is_manager = resp.is_manager
        }
        exec.is_manager = resp.is_manager
        exec.typeIsChanging = false;
        if (this.selectedExec) {
          this.selectedExec.exec.typeIsChanging = false
        }
      },
      error => {
        this.layoutService.showSnackBar({name: ''}, error, SnackBarItem);
        exec.typeIsChanging = false;
        if (this.selectedExec) {
          this.selectedExec.exec.typeIsChanging = false
        }
      })
    )
  }

  showError() {
    this.layoutService.showSnackBar({name: ''}, marker("You do not have permission to do this"), SnackBarItem)
  }

  jobHasUserExec() {
    return this.target.outputExecutors.employees.filter(jobEmpl => !jobEmpl.is_partner && !jobEmpl.is_partner_employee && (jobEmpl.discussion_id == 0 || !jobEmpl.discussion_id)).length
  }
  
  jobHasChatExec() {
    return this.target.outputExecutors.employees.filter(jobEmpl => !jobEmpl.is_partner && !jobEmpl.is_partner_employee && !(jobEmpl.discussion_id == 0 || !jobEmpl.discussion_id)).length
  }

  jobHasChatPartn() {
    return this.target.outputExecutors.partnerCompanies.filter(jobEmpl => !(jobEmpl.discussion_id == 0 || !jobEmpl.discussion_id)).length
  }

  jobHasNotChatPartn() {
    return this.target.outputExecutors.partnerCompanies.filter(jobEmpl => (jobEmpl.discussion_id == 0 || !jobEmpl.discussion_id)).length
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
