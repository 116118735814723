<mat-drawer-container class="root_page" autosize>
    <mat-drawer #drawer mode="side" disableClose="true" opened="true">
        <mat-nav-list class="menu_list">
            <mat-list-item (click)="goToMenuItem(menuItem)" [ngClass]="{'active_menu': menuItem.tab == activePage}" *ngFor="let menuItem of menuItems">
                <mat-icon mat-list-icon>{{menuItem.icon}}</mat-icon>
                <h4 mat-line *ngIf="isExpanded">{{ menuItem.name | translate }}</h4>
            </mat-list-item>
        </mat-nav-list>
        <div class="menu_btn">
            <button mat-mini-fab (click)="isExpanded = !isExpanded" color="warn" style="margin: 10px;">
            <mat-icon aria-label="Menu">
                <ng-container *ngIf="isExpanded; else elseTemplatemenu_open">
                    menu_open
                </ng-container>
                <ng-template #elseTemplatemenu_open>
                    menu
                </ng-template>
            </mat-icon>
            </button>
        </div>
    </mat-drawer>
  
    <mat-drawer-content>
        <div class="container">
            <div class="root_content" [ngSwitch]="activePage">
                <div class="parameters_page" *ngSwitchCase="'progress'">
                    <div class="cases_page tasks_page">
                        <div class="filter_out">  
                        <div class="filter_values">
                            <mat-chip-list class="chips_wrp without_ordered start_page_chips">
                    
                                <mat-chip (removed)="removeValue('q')" *ngIf="groupsFilter.get('q').value">
                                    {{groupsFilter.get('q').value}}
                                    <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
                                </mat-chip>
                                
                                <mat-chip (removed)="removeValue('id')" *ngIf="groupsFilter?.get('id')?.value">
                                    {{ "Group ID" | translate }}: {{ groupsFilter?.get('id')?.value }}
                                    <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
                                </mat-chip>
                        
                                <mat-chip (removed)="removeValue('content_type_id')" *ngIf="groupsFilter.get('content_type_id').value">
                                    <ng-container *ngIf="groupsFilter.get('content_type_id').value.toString() == '0'; else elseTemplateZeroContent">
                                        {{ "Content Type" | translate }}: {{ "Not set" | translate }}
                                    </ng-container>
                                    <ng-template #elseTemplateZeroContent>
                                        {{ "Content Type" | translate }}: {{ getContentById(groupsFilter.get('content_type_id').value)?.name }}
                                    </ng-template>
                                
                                    <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
                                </mat-chip>
                        
                                <ng-container *ngIf="groupsFilter.get('channel_id').value.length">
                                    <span class="chip_out_label">{{ "Outlets" | translate }}</span>
                                    <mat-chip [ngClass]="{'user_chip': id != 0}" *ngFor="let id of groupsFilter.get('channel_id').value" (removed)="removeValueFromMultiple('channel_id', id)">
                                        <ng-container *ngIf="id != 0; else elseTemplateOutletNotSet">
                                            <app-outlet-line [channel]="getChannelById(id)" [company_id]="company_id"></app-outlet-line>
                                        </ng-container>
                                        <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
                                    </mat-chip>
                                </ng-container>
                                
                                <mat-icon *ngIf="groupsFilter.get('q').value || groupsFilter.get('id').value || groupsFilter.get('content_type_id').value || groupsFilter.get('channel_id').value.length" class="remove_all_chips_btn" (click)="removeValue('all')">cancel</mat-icon>
                            </mat-chip-list>
                    
                            <div class="stats_wrp ml-1">
                            <div class="tasks_counters">
                                
                                <!-- <form [formGroup]="filter" class="preset_form" *ngIf="bookmarks && bookmarks.length > 0">
                                <div class="simple_filter_snippet" (click)="presetFilter.open()">
                                    <ng-container *ngIf="filter.get('preset_id').value; else elseTemplatePreset">
                                    <span class="name">
                                        {{ getBookmarkById(filter.get('preset_id').value)?.name }}
                                    </span>
                                    </ng-container>
                                    <ng-template #elseTemplatePreset>
                                    <span class="name">
                                        {{ "Bookmark" | translate }}
                                    </span>
                                    </ng-template>
                                    
                    
                                    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_2474_38987)">
                                    <path d="M7.91699 3.33325L2.08366 3.33325L5.00033 7.91658L7.91699 3.33325Z" fill="black"/>
                                    </g>
                                    <defs>
                                    <clipPath id="clip0_2474_38987">
                                    <rect width="10" height="10" fill="white" transform="translate(10) rotate(90)"/>
                                    </clipPath>
                                    </defs>
                                    </svg>
                                </div>
                    
                                <mat-form-field appearance="legacy" class="filter_form preset_filter">
                                    <mat-label>{{ "Bookmark" | translate }}</mat-label>
                                    <mat-select #presetFilter [disableOptionCentering]="true" selectDir formControlName="preset_id" (selectionChange)="bookmarksChange($event)">
                                        <mat-option *ngIf="bookmarks.length > 7">
                                        <ngx-mat-select-search 
                                            placeholderLabel="{{ 'Search' | translate }}" 
                                            noEntriesFoundLabel="{{ 'no matching presets found' | translate }}"
                                            [formControl]="bookmarksControl"></ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option *ngFor="let bookmark of bookmarks$ | async" [value]="bookmark.id">
                                        {{ bookmark.name | translate }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                </form> -->

                                <div [matMenuTriggerFor]="filterBtn" class="filter_analytic">
                                    <span class="text_one_line">
                                        <ng-container *ngIf="groupsFilter.get('max_farthest_content_published_at_to').value === 0; else elseTemplateOne">
                                            {{ "All channels without plans" | translate }}
                                        </ng-container>
                                        <ng-template #elseTemplateOne>
                                            <ng-container *ngIf="groupsFilter.get('min_farthest_content_published_at_from').value === 1; else elseTemplateTwo">
                                                {{ "All channels with plans" | translate }}
                                            </ng-container>
                                            <ng-template #elseTemplateTwo>
                                                <ng-container *ngIf="groupsFilter.get('min_farthest_content_published_at_from').value === 0; else elseTemplateThree">
                                                    {{ "At least one channel without plans" | translate }}
                                                </ng-container>
                                                <ng-template #elseTemplateThree>
                                                    {{ "All" | translate }}
                                                </ng-template>
                                            </ng-template>
                                        </ng-template>
                                    </span>
                                    <mat-icon>arrow_drop_down</mat-icon>
                                </div>
                                <mat-menu #filterBtn="matMenu" class="sort_tablet new_sort_int" xPosition='before' overlayPanelClass='sortTAB'>
                                    <button mat-menu-item (click)="filterByPlans('min_farthest_content_published_at_from', '')" [ngClass]="{'active_status': groupsFilter.get('min_farthest_content_published_at_from').value === '' && groupsFilter.get('max_farthest_content_published_at_to').value === ''}">
                                        {{ "All" | translate }}
                                    </button>
                                    <button mat-menu-item (click)="filterByPlans('min_farthest_content_published_at_from', 0)" [ngClass]="{'active_status': groupsFilter.get('min_farthest_content_published_at_from').value === 0}">
                                        {{ "At least one channel without plans" | translate }}
                                    </button>
                                    <button mat-menu-item (click)="filterByPlans('max_farthest_content_published_at_to', 0)" [ngClass]="{'active_status': groupsFilter.get('max_farthest_content_published_at_to').value === 0}">
                                        {{ "All channels without plans" | translate }}
                                    </button>
                                    <button mat-menu-item (click)="filterByPlans('min_farthest_content_published_at_from', 1)" [ngClass]="{'active_status': groupsFilter.get('min_farthest_content_published_at_from').value === 1}">
                                        {{ "All channels with plans" | translate }}
                                    </button>
                                </mat-menu>

                                <!-- <mat-button-toggle-group class="view_group" (change)="changeView($event)" [value]="groupsFilter.get('min_farthest_content_published_at_from').value">
                                    <mat-button-toggle [value]="''" #tooltip="matTooltip" matTooltip="{{ 'tool1' | translate }}" [matTooltipPosition]="'above'">{{ "All" | translate }}</mat-button-toggle>
                                    <mat-button-toggle [value]="0" #tooltip="matTooltip" matTooltip="{{ 'tool2' | translate }}" [matTooltipPosition]="'above'">{{ "Any n/a" | translate }}</mat-button-toggle>
                                    <mat-button-toggle [value]="10" #tooltip="matTooltip" matTooltip="{{ 'tool3' | translate }}" [matTooltipPosition]="'above'">{{ "All n/a" | translate }}</mat-button-toggle>
                                    <mat-button-toggle [value]="1" #tooltip="matTooltip" matTooltip="{{ 'tool4' | translate }}" [matTooltipPosition]="'above'">{{ "All must" | translate }}</mat-button-toggle>
                                </mat-button-toggle-group> -->

                                <button type="button" mat-icon-button [matMenuTriggerFor]="sortBtn" class="ml-1 mobActiveFilter" #tooltip="matTooltip" matTooltip="{{ 'Sort' | translate }}" [matTooltipPosition]="'above'">
                                    <mat-icon>sort</mat-icon>
                                </button>

                                <div class="counter_block ml-1" *ngIf="groupsPagination && groupsPagination['totalCount']">
                                    <div class="counter_top">{{ "Groups" | translate }}</div>
                                    <div class="counter_bottom">{{groupsPagination['totalCount']}}</div>
                                </div>

                          
                            
                                <mat-menu #sortBtn="matMenu" class="sort_tablet new_sort_int" xPosition='before' overlayPanelClass='sortTAB'>
                                    <div class="mat-menu-title">{{"Sort By" | translate}}:</div>
                                    <mat-divider></mat-divider>
                                    <button mat-menu-item (click)="sort(groupsFilter.get('order_by').value == 'id_desc' ? 'id' : 'id_desc')" [ngClass]="{'active_status': groupsFilter.get('order_by').value == 'id_desc' || groupsFilter.get('order_by').value == 'id'}">
                                      {{  "ID" | translate }}
                                      <div class="d-f-c ml-1">
                                        <mat-icon (click)="$event.preventDefault(); $event.stopPropagation(); sort('id_desc')" [ngClass]="{'active': groupsFilter.get('order_by').value == 'id_desc'}" style="margin-right: 0 !important;">arrow_drop_down</mat-icon>
                                        <mat-icon (click)="$event.preventDefault(); $event.stopPropagation(); sort('id')" [ngClass]="{'active': groupsFilter.get('order_by').value == 'id'}" style="margin-right: 0 !important;">arrow_drop_up</mat-icon>
                                      </div>
                                    </button>
                                    <button mat-menu-item (click)="sort(groupsFilter.get('order_by').value == 'min_farthest_content_published_at_desc' ? 'min_farthest_content_published_at' : 'min_farthest_content_published_at_desc')" [ngClass]="{'active_status': groupsFilter.get('order_by').value == 'min_farthest_content_published_at_desc' || groupsFilter.get('order_by').value == 'min_farthest_content_published_at'}">
                                      {{  "The earliest plan" | translate }}
                                      <div class="d-f-c ml-1">
                                        <mat-icon (click)="$event.preventDefault(); $event.stopPropagation(); sort('min_farthest_content_published_at_desc')" [ngClass]="{'active': groupsFilter.get('order_by').value == 'min_farthest_content_published_at_desc'}" style="margin-right: 0 !important;">arrow_drop_down</mat-icon>
                                        <mat-icon (click)="$event.preventDefault(); $event.stopPropagation(); sort('min_farthest_content_published_at')" [ngClass]="{'active': groupsFilter.get('order_by').value == 'min_farthest_content_published_at'}" style="margin-right: 0 !important;">arrow_drop_up</mat-icon>
                                      </div>
                                    </button>
                                    <button mat-menu-item (click)="sort(groupsFilter.get('order_by').value == 'max_farthest_content_published_at_desc' ? 'max_farthest_content_published_at' : 'max_farthest_content_published_at_desc')" [ngClass]="{'active_status': groupsFilter.get('order_by').value == 'max_farthest_content_published_at_desc' || groupsFilter.get('order_by').value == 'max_farthest_content_published_at'}">
                                      {{  "The farthest plan" | translate }}
                                      <div class="d-f-c ml-1">
                                        <mat-icon (click)="$event.preventDefault(); $event.stopPropagation(); sort('max_farthest_content_published_at_desc')" [ngClass]="{'active': groupsFilter.get('order_by').value == 'max_farthest_content_published_at_desc'}" style="margin-right: 0 !important;">arrow_drop_down</mat-icon>
                                        <mat-icon (click)="$event.preventDefault(); $event.stopPropagation(); sort('max_farthest_content_published_at')" [ngClass]="{'active': groupsFilter.get('order_by').value == 'max_farthest_content_published_at'}" style="margin-right: 0 !important;">arrow_drop_up</mat-icon>
                                      </div>
                                    </button>
                                </mat-menu>
                            </div>
                            </div>
                        </div>
                        </div>
                    
                        <div class="lists_wrp">
                        </div>  
                    </div>
                    <div class="reports_dash">
                        <div class="reports_dash_header">
                            <div class="column report_col">
                                <div class="title">
                                    {{"Group" | translate}}
                                </div>
                            </div>
                            
                            <div class="column report_col">
                                <div class="title">
                                    {{"The earliest plan" | translate}}
                                </div>
                            </div>

                            <div class="column report_col">
                                <div class="title">
                                    {{"The farthest plan" | translate}}
                                </div>
                            </div>

                            <!-- <div class="column report_col expand" style="align-items: flex-end !important;">
                                <div class="title">
                                    {{"Expand" | translate}}
                                </div>
                            </div> -->
                        </div>

                        <div class="reports_wrp" [appDeviceClass]="'custom-scroll custom-scroll-t'" infinite-scroll
                        [infiniteScrollDisabled]="isLoadGroups"
                        [scrollWindow]="false"
                        [infiniteScrollUpDistance]="scrollUpDistance"
                        [infiniteScrollThrottle]="throttle"
                        (scrolled)="onDownGroups($event)">
                            <div class="report" *ngFor="let item of groups">
                                <div class="report_line cp" (click)="toggleGroup(item)">
                                    <div class="column report_col">
                                        <span class="big">
                                            {{ item?.name }}
                                        </span>
                                    </div>
                                    <div class="column report_col">
                                        <span class="normal" *ngIf="!!item?.min_farthest_content_published_at">
                                            {{ item?.min_farthest_content_published_at*1000 | date:'MMMM dd, Y':'':activeLang | yearPipe }}
                                        </span>
                                    </div>
                                    <div class="column report_col">
                                        <span class="normal" *ngIf="!!item?.max_farthest_content_published_at">
                                            {{ item?.max_farthest_content_published_at*1000 | date:'MMMM dd, Y':'':activeLang | yearPipe }}
                                        </span>
                                    </div>
                                    <!-- <div class="column report_col expand">
                                        <button mat-icon-button style="color: #686868;" (click)="toggleGroup(item)">
                                            <mat-icon>
                                                {{ item.isOpen ? 'expand_less' : 'expand_more' }}
                                            </mat-icon>
                                        </button>
                                    </div> -->
                                </div>
                                <div class="param_values report_sub_lines" *ngIf="item.isOpen">
                                    <!-- <div class="report_line report_line_head">
                                        <div class="column report_col">
                                            <span class="title">
                                                {{ "Channel" | translate }}
                                            </span>
                                        </div>
                                        <div class="column report_col">
                                            <span class="title">
                                                {{ "Content type" | translate }}
                                            </span>
                                        </div>
                                        <div class="column report_col">
                                            <span class="title">
                                                {{"The farthest prepared post" | translate}}
                                            </span>
                                        </div>
                                    </div> -->
                                    <div class="report_line" *ngFor="let chToGroup of item.channelsToGroup">
                                        <div class="column report_col">
                                            <app-outlet-line [channel]="chToGroup?.channel" [company_id]="company.id"></app-outlet-line>
                                            <span class="small ml-1">
                                                <ng-container *ngIf="!!chToGroup.content_type_id; else elseTemplateHasType">
                                                    {{ chToGroup.contentType.name | translate }}
                                                </ng-container>
                                                <ng-template #elseTemplateHasType>
                                                    {{ "All types" | translate }}
                                                </ng-template>
                                            </span>
                                        </div>
                                        <div class="column report_col">
                                        </div>
                                        <div class="column report_col">
                                            <span class="normal" *ngIf="chToGroup?.farthest_content_published_at" #tooltip="matTooltip"
                                            matTooltip="{{('Updated at' | translate) + ' ' + ((chToGroup?.farthest_time_updated_at > today && chToGroup?.farthest_time_updated_at < todayEnd) ? (chToGroup?.farthest_time_updated_at*1000 | date:'hh:mm a':'':activeLang) : (chToGroup?.farthest_time_updated_at*1000 | date:'MM.dd.yy, hh:mm a':'': activeLang)) }}"
                                            [matTooltipPosition]="'above'">
                                                {{ chToGroup?.farthest_content_published_at*1000 | date:'MMMM dd, Y':'':activeLang | yearPipe }}
                                            </span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <button class="add_btn" mat-raised-button color="primary" (click)="goBack()">
                            {{ "Go back" | translate }}
                        </button>
                    </div>
                </div>
                <div *ngSwitchDefault>
                
                </div>
            </div>
        </div>
    </mat-drawer-content>
  </mat-drawer-container>
  

<ng-template #elseTemplatePhoto>
    <mat-icon>account_circle</mat-icon>
</ng-template>