import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';

@Component({
  selector: 'app-edit-work-status',
  templateUrl: './edit-work-status.component.html',
  styleUrls: ['./edit-work-status.component.scss']
})
export class EditWorkStatusComponent extends BaseClass implements OnInit {
  public translations: any;
  public form: FormGroup;
  public transForm: FormGroup;
  public activeLang: any;
  public languages: any;
  public isSubmit: boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<EditWorkStatusComponent>,
    private taskService: TaskService,
    private languageService: LanguageService,
    private fb: FormBuilder,
  ) { super() }

  ngOnInit(): void {
    this.form = this.fb.group({
      name: [this.data.type.name, Validators.required]
    })
    
    
    this.transForm = this.fb.group({
      name: ['', Validators.required],
      operation_id: this.data.type.id,
      language: ''
    })
    

    this.attachSubscriptions(
      this.transForm.get("language").valueChanges.subscribe(resp => {
        this.getTranslations()
      })
    )
    this.getLangData();
  }

  getTranslations() {
    this.attachSubscriptions(
      this.taskService.getOperationTranslation(this.data.type.id).subscribe(resp => {
        console.log("getOperationTranslation", resp)
        this.translations = resp;
        if (resp.find(x => x.language == this.transForm.value.language)) {
          this.transForm.patchValue({
            name: resp.find(x => x.language == this.transForm.value.language).name
          })
        } else {
          this.transForm.patchValue({
            name: ''
          })
        }
      })
    )
  }

  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData().pipe(
        tap(resp => {
          this.activeLang = resp.active;
          this.languages = resp.languages;
          this.transForm.patchValue({
            language: this.activeLang
          })
        })
      ).subscribe(resp => {
        console.log("getOperationTranslation", resp)
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }
  
  submitForm() {
    this.isSubmit = true;
    this.attachSubscriptions(
      this.taskService.editOperation(this.data.type.id, this.form.value).pipe(
        switchMap(x => {
          if (this.translations.filter(i => i.language == this.transForm.value.language).length == 0) {
            if (this.transForm.value.name) {
              return this.taskService.createOperationTranslation(this.transForm.value).pipe(
                tap(val => {
                  this.translations.push(val)
                })
              )
            } else {
              return of(null)
            }
          } else {
            return this.taskService.updateOperationTranslation(this.translations.find(i => i.language == this.transForm.value.language).id, {name: this.transForm.value.name}).pipe(
              tap(val => {
                this.translations.find(x => x.language == val.language).name = this.form.value.name
              })
            )
          }
        }),
      ).subscribe(resp => {
        console.log(resp);
        this.isSubmit = false;
        this.close()
      })
    )
  }


  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

}
