<h2 mat-dialog-title class="dialog_title">
    <span>
        {{ "Editing task type" | translate }}
    </span>
    <button mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h2>
<mat-dialog-content class="mat-typography" dirEnter (dirSubmit)="!form.valid || isSubmit ? null : submitForm()">
    <div class="container">
        <form [formGroup]="form">
            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ 'Type name' | translate }}</mat-label>
                <input cdkFocusInitial formControlName="name" matInput type="text" placeholder="{{ 'Type name' | translate }}">
            </mat-form-field>
        </form>

        <h2>{{ 'Translate type' | translate }}:</h2>
        <form [formGroup]="transForm">
            <div class="d-f-c">
                <mat-form-field appearance="standard" class="full_width_form">
                    <mat-label>{{ 'Name' | translate }}</mat-label>
                    <input formControlName="name" matInput placeholder="{{ 'Name' | translate }}">
                </mat-form-field>
                <mat-form-field class="language_block" appearance="standard">
                    <mat-label>{{ "Language" | translate }}</mat-label>
                    <mat-select formControlName="language">
                      <mat-option *ngFor="let lang of languages" [value]="lang">{{lang}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </form>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button (click)="close()">
        {{ "Close" | translate }}
    </button>
    <button class="ml-1 d-f-btn" (click)="submitForm()" mat-raised-button cdkFocusInitial color="primary" [disabled]="!form.valid || isSubmit">
        <mat-spinner class="spin_16 mr-1" *ngIf="isSubmit"></mat-spinner>  
        {{ "Edit" | translate }}
    </button>
</mat-dialog-actions>
