import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, Inject, OnDestroy, OnInit, Renderer2, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { BehaviorSubject, Observable, ReplaySubject, Subject, Subscription, forkJoin, from, fromEvent, interval, of } from 'rxjs';
import { concatMap, debounceTime, filter, last, map, startWith, switchMap, take, takeUntil, takeWhile, tap } from 'rxjs/operators';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { FileService } from 'src/app/shared/services/rest/file.service';
import { SnackBarItem } from '../../../snack-bar/snack-bar-item';
import { SelectFileComponent } from '../select-file/select-file.component';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { TargetParametersComponent } from 'src/app/components/atTasksDialog/target-parameters/target-parameters.component';
import { ScenariosService } from 'src/app/shared/services/rest/scenarios.service';
import { prioritys } from 'src/app/shared/consts/prioritys';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { ParametersService } from 'src/app/shared/services/rest/parameters.service';
import { WorkAddComponent } from 'src/app/components/atTasksDialog/work-add/work-add.component';
import { environment } from 'src/environments/environment';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import * as moment from 'moment';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MY_NEW_FORMATS } from 'src/app/components/workspace-pages/cases/dialogs/open-task/dialogs/url-analytics/url-analytics.component';
import { NgxMatDateAdapter } from '@angular-material-components/datetime-picker';
import 'resize-observer-polyfill';
import { AiService } from 'src/app/shared/services/rest/ai.service';
import {COMMA, ENTER, R} from '@angular/cdk/keycodes';
import { MatChipInput, MatChipInputEvent } from '@angular/material/chips';
import { MatAutocomplete, MatAutocompleteOrigin, MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { DomSanitizer } from '@angular/platform-browser';
import { el } from 'date-fns/locale';
import { CreateCardsComponent } from '../main-video-editor/dialog/create-cards/create-cards.component';
import { SelectionModel } from '@angular/cdk/collections';
import { AutoProjectsComponent } from '../auto-projects/auto-projects.component';
import { ScrnSettingsComponent } from '../scrn-settings/scrn-settings.component';
import { pid } from 'process';
import { SaveStyleComponent } from '../main-video-editor/dialog/save-style/save-style.component';
import { FileSettingsComponent } from '../file-settings/file-settings.component';
import { app_version } from 'src/app/shared/consts/app_version';
@Component({
  selector: 'app-video-ffmpeg-editor',
  templateUrl: './video-ffmpeg-editor.component.html',
  styleUrls: ['./video-ffmpeg-editor.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_NEW_FORMATS},
  ]
})
export class VideoFfmpegEditorComponent extends BaseClass implements OnInit, AfterViewInit, OnDestroy {
  public version: any = 'A';
  public r_version: any;
  public current_version: any = app_version;

  @ViewChild('expSett') expSett: ElementRef;
  @ViewChild('holst') holst: ElementRef;
  @ViewChild('prevPanelOut') prevPanelOut: ElementRef;
  @ViewChild('prevPaneIn') prevPaneIn: ElementRef;
  @ViewChild('originalAudio') originalAudio: ElementRef;
  @ViewChild('outContainer') outContainer: ElementRef<HTMLDivElement>;
  @ViewChild('originalAudioCanvas') originalAudioCanvas: ElementRef;
  @ViewChild('customTimeline', { static: true }) customTimelineRef: ElementRef;
  @ViewChild('videoContainer') videoContainerRef!: ElementRef<HTMLDivElement>;
  @ViewChild('timelineThumbnails') timelineThumbnails!: ElementRef<HTMLDivElement>;
  @ViewChild('timelineCanvas', { static: true }) timelineCanvasRef: ElementRef<HTMLCanvasElement>;
  @ViewChild('fileMenu') fileMenu: TemplateRef<any>;
  @ViewChild('wordEdit') wordEdit: TemplateRef<any>;
  @ViewChild('exportSettingsMenu') exportSettingsMenu: TemplateRef<any>;
  public duration: any = 0;
  public verticalLine: any = {
    val: 0,
    posX: 0,
    left: 0,
    show: false
  }
  public coverImageUrl: any;
  public create_cards_count: number = 0;
  public frame_ms: number = 0;
  public movedFragment: any;
  public movedSub: any;
  public screenSettings: any[] = [
    {
      name: 'Original quality and size',
      is_active: false,
    }
  ]

  public sub: Subscription;
  public subWordEdit: Subscription;
  public subExport: Subscription;
  public projectForm: FormGroup;
  public rateForm: FormGroup;
  public is_create_cards: FormControl = new FormControl(0)
  public is_create_vid_relations: FormControl = new FormControl(0)
  public is_create_aud_relations: FormControl = new FormControl(0)
  public saveMode: FormControl = new FormControl('concat');
  public is_save_audio: FormControl = new FormControl(false);

  public spacerActive: number = 0;
  public projectSpeed: number = 1;
  public imgRoute: any = '';
  public host: any = environment.host;

  public origin = window.location.origin;
  public forAllContentTypes: any;
  public allContentTypes: any;
  public publications: any = [];

  overlayRefExport: OverlayRef | null;
  overlayRefEditWord: OverlayRef | null;
  overlayRefRight: OverlayRef | null;
  public form: FormGroup;
  public inheritTags: boolean = true;
  public rightActive: boolean = false;
  public leftActive: boolean = false;
  public is_input_set_ai: boolean = false;
  public isCreateThumb: boolean = false;
  public plusDisabled: boolean = false;
  public minusDisabled: boolean = false;
  public cuts: any = [];
  public parameters: any[] = [];
  public jobParameters: any[] = [];
  public mode: any = 'view';
  public currentTime: any = 0;
  public copiedFragment: any;
  public dragItem: any;
  public allValues: any;
  public cutEdited: any;
  public playCut: any;
  public interval: any;
  public project: any;
  public taskTemplates: any;

  public holstRatio: number = 0;
  
  public saveSub: Subscription;
  public vidProject: any;
  public activeHistory: any;
  public history: any;
  public historyPage: number = 1;
  public historyPagination: any;
  public is_saving: boolean = false;


  public activeItem: any;
  public is_saved_styles: boolean = false;
  public isMediaMenuOpen: boolean = false;
  public projectHasParts: boolean = false;
  public submited: boolean = false;
  public isSubmit: boolean = false;
  public screenIsSubmit: boolean = false;
  public isProjectPlaying: boolean = false;

  private destroy$ = new Subject<void>();
  public waiting_time_limit = new FormControl(86400);
  public savedTags: any;

  public profilesTab: number = 0
  public profiles: any[] = [];
  public profilesMoreControl: FormControl = new FormControl();
  public profiles$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public groups$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public groupsMoreControl: FormControl = new FormControl();
  public groups: any;
  
  public taskStatuses$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public taskStatusesMoreControl: FormControl = new FormControl();
  public taskStatuses: any;
  
  public styles: any;

  public operations$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public operationsMoreControl: FormControl = new FormControl();
  public operations: any;

  public employees$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public employeesMoreControl: FormControl = new FormControl();
  public employees: any;
  
  public partners: any;
  public getHistoryCount: number = 1;

  public zoom: FormControl = new FormControl(1);

  public selectedFragments = new SelectionModel(
    true,
    []
  );
  
  public resData: any = {
    width: 0,
    height: 0
  }
  public liner: any = {
    width: 0,
    times: []
  }
  public tracks: any[] = [];

  public files: any = {
    video: [],
    audio: [],
  };

  public activeStyle: any;
  public statuses: any;

  // Создаем Subject для управления состоянием проекта (play/pause)
  public projectState$ = new BehaviorSubject<boolean>(false);

  public currentTime$ = interval(100).pipe(
    takeWhile(() => this.projectState$.getValue()), // Продолжаем генерацию времени, пока проект находится в состоянии воспроизведения
  );

  
  // Переменные для управления временем проекта
  public wWidth:number = 0;
  public currentTimeProject:number = 0;
  public currentCutTimeProject:number = 0;
  public previousTime = Date.now();
  public jobs:any = [];
  public prioritys = prioritys;
  private context: CanvasRenderingContext2D;
  
  separatorKeysCodes: number[] = [ENTER, COMMA];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<VideoFfmpegEditorComponent>,
    private fileService: FileService,
    private parametersService: ParametersService,
    private dialog: MatDialog,
    private sanitizer: DomSanitizer,
    private fb: FormBuilder,
    public scenariosService: ScenariosService,
    public companyService: CompanyService,
    private sm: StorageManagerService,
    public taskService: TaskService,
    public aiService: AiService,
    public layoutService: LayoutService,
    public overlay: Overlay,
    public viewContainerRef: ViewContainerRef,
    private cdRef: ChangeDetectorRef,
    private renderer: Renderer2,
    private _ngx_adapter: NgxMatDateAdapter<any>,
    private _adapter: DateAdapter<any>,
  ) { super() }

  ngOnInit(): void {
    console.log("VideoFfmpegEditorComponent", this.data)

    this.dialogRef.addPanelClass('video_editor_dialog')
    this.layoutService.overflowHidden();
    this.rateForm = this.fb.group({
      output_frame_rate: 25,
      output_audio_bit_rate: 192000,
      output_bit_rate: 0
    })

    this.getAllApiParameterValues();

    this.attachSubscriptions(
      this.rateForm.valueChanges.subscribe(res => {
        this.saveVideoEditorHistory("Change Rate")
      })
    )

    this.projectForm = this.fb.group({
      width: this.data.file ? this.data.file.meta_width : 0,
      height: this.data.file ? this.data.file.meta_height : 0
    })

    this.form = this.fb.group({
      type: 0,
      group_id: "",
      status_id: 1,
      create_parameter_values_to_task: [[]],
      name: '',
      template_id: ''
    })

    this.attachSubscriptions(
      this.fileService.getProjectFiles().pipe(
        concatMap((noBlob:any) => {
          console.log("noBlob", noBlob)
          if (!!noBlob.blob) {
            return of(null)
          } else {
            return this.fileService.getSrc(`${this.data.host}${noBlob.preview1080 ? noBlob.preview1080 : noBlob.original}?company_id=${this.data.company_id}`).pipe(
              switchMap((el:any) => {
                return this.fileService.downloadFileFromUrl(el.download_url, noBlob).pipe(
                  tap(x => {
                    if (x.content && x.state == "DONE") {
                      this.layoutService.showSnackBar({name: noBlob.filename}, marker("is downloaded"), SnackBarItem);
                      let newSrc = URL.createObjectURL(x.content);
                      let file;
                      if (this.files.audio.find(x => x.id == noBlob.id)) {
                        file = this.files.audio.find(x => x.id == noBlob.id)
                      }
                      if (this.files.video.find(x => x.id == noBlob.id)) {
                        file = this.files.video.find(x => x.id == noBlob.id)
                      }
                      if (file) {
                        file.blobSrc = this.sanitizer.bypassSecurityTrustResourceUrl(newSrc);
                        file.blob = x.content;
                      }
                      console.log("END FILE DOWN " + file.filename, file);
                      let parts = (this.tracks.map(k => k.parts) as any).flat(Infinity)
                      parts.forEach(part => {
                        if (part.is_combine) {
                          part.blends.forEach(el => {
                            if (el.id == noBlob.id) {
                              el.blobSrc = this.sanitizer.bypassSecurityTrustResourceUrl(newSrc);
                            }
                          })
                        } else {
                          if (part.id == noBlob.id) {
                            part.blobSrc = this.sanitizer.bypassSecurityTrustResourceUrl(newSrc);
                          }
                        }
                      })
                    }
                  })
                )
              })
            )
          }
        })
      ).subscribe({
        next: (next) => {
          // console.log("next getProjectFiles", next);
        },
        complete: () => {
          console.log("complete getProjectFiles");
        },
        error: (error) => {
          console.log("error getProjectFiles", error)
        }
      })
    )

    this.attachSubscriptions(
      this.is_create_cards.valueChanges.subscribe(val => {
        if (this.create_cards_count == 0 && !!val && !this.data.project) {
          this.openCreateCards()
        }
        this.create_cards_count++
        // if (val && this.mode == 'cut') {
        //   this.mode = 'view'
        // }
      })
    )

    if (!!this.data.project) {
      this.vidProject = this.data.project;
      this.getVideoEditorHistory();
    } else {
      this.createProject();
    }

    
    this.getStyles();

    this._ngx_adapter.setLocale(this.data.activeLang);
    this._adapter.setLocale(this.data.activeLang);
    this.getProfiles()
    this.getForAllContentTypes() 
    this.getImgRoute();

    this.attachSubscriptions(
      this.projectForm.valueChanges.pipe(debounceTime(300)).subscribe(resp => {
        const targetElement = this.prevPanelOut.nativeElement;
        let width = targetElement.clientWidth;
        let height = targetElement.clientHeight;

        console.log("this.projectForm.valueChanges", resp)
        
        this.project = {
          mWidth: resp.width,
          mHeight: resp.height,
          ratio: resp.width / resp.height,
          // height: 380,
          // percent: 380 / resp.height,
        }
        
        if (this.project.ratio <= 1) {
          this.project.height = height;
          this.project.width = this.project.height * this.project.ratio;

          if (this.project.width > width) {
            this.project.width = width;
            this.project.height = this.project.width / this.project.ratio;
          }
        } else {
          this.project.width = width;
          this.project.height = this.project.width / this.project.ratio;

          if (this.project.height > height) {
            this.project.height = height;
            this.project.width = this.project.height * this.project.ratio;
          }
        }

        this.project.percentY = this.project.height / this.project.mHeight;
        this.project.percentX = this.project.width / this.project.mWidth;
        let projectPos = JSON.parse(JSON.stringify(this.holst.nativeElement.getBoundingClientRect()));
        this.project.rect = Object.assign(projectPos, {x: projectPos.x - 200, left: projectPos.left - 200, right: projectPos.right - 200});

      })
    )

    this.attachSubscriptions(
      this.profilesMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchProfiles(resp))
    )



    this.attachSubscriptions(
      this.zoom.valueChanges.subscribe(resp => {
        this.getLines()
      })
    )
    this.getPartners();
    this.attachSubscriptions(
      this.groupsMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchGroups(resp))
    )

    this.attachSubscriptions(
      this.taskStatusesMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchTaskStatuses(resp))
    )

    this.attachSubscriptions(
      this.employeesMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchEmoloyees(resp))
    )
    
    this.attachSubscriptions(
      this.operationsMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchOperations(resp))
    )

    this.attachSubscriptions(
      this.saveMode.valueChanges.subscribe((resp) => {
        console.log("saveMode", resp);
        this.saveVideoEditorHistory("saveMode value change")
      })
    )

    this.getTaskTemplates();
    this.getGroups();
    this.getTaskStatus();

    this.getOperations();
    this.getOperationsStatus();
    this.getEmployees();

    if (this.data.file) {
      if (this.data.file.task) {
        delete this.data.file.task;
      }
      if (this.data.file.createdEmployee) {
        delete this.data.file.createdEmployee;
      }
      this.data.file.is_audio_only = 1;
      this.data.file.is_video_only = 1;
      this.data.file.crop_on = false;
      this.data.file.is_open = false;
      this.data.file.trackElIndex = 0;
      this.data.file.part_speed_rate = 1;
      this.data.file.volume = 1;
      this.data.file.optionsCount = 10;
      this.data.file.myId = this.generateRandomId();
      this.data.file.pId = this.generateRandomId();
      this.data.file.is_custom = new FormControl(false);
      
      this.holstRatio = this.data.file.meta_width / this.data.file.meta_height

      if (this.data.file && this.data.file.hasOwnProperty('meta_has_audio') && this.data.file.meta_has_audio == 0) {
        this.data.file.is_detached = true;
        this.data.file.is_audio_only = 0;
      }
  
      this.data.file.rd = {
        left: 0,
        top: 0,
        deg: 0,
        mWidth: this.data.file.meta_width,
        mHeight: this.data.file.meta_height,
        ratio: this.data.file.meta_width / this.data.file.meta_height,
        // height: 380,
        // percent: 380 / this.data.file.meta_height,
        params: {}
      }

      this.screenSettings.push({
        name: "Size",
        is_active: false,
        mWidth: this.data.file.meta_width,
        mHeight: this.data.file.meta_height,
        filesize: 0
      })
  
  
      this.files.video.push({...this.data.file});
      this.tracks.push({
        type: 'video',
        parts: [{...this.data.file}]
      })
  
      let part = this.tracks[0].parts[0]
      part.form = this.fb.group({
        name: (part.form && part.form.value.name) ? part.form.value.name : '',
        type: 0,
        group_id: "",
        status_id: 1,
        create_parameter_values_to_task: [[]],
        template_id: ''
      })
      
      part.parameters = [];
      part.subscr = part.is_custom.valueChanges.subscribe(res => {
        console.log('is_custom', part, res)
        if (!res) {
          console.log('is_custom2', part, res)
          this.setFormValueAsHeadForm(part, false)
          part.jobs = JSON.parse(JSON.stringify(this.jobs, this.getCircularReplacer()))
        }
      })
      this.fileService.projectFiles$.next(this.files.video[0])
      this.getTags();
    }
  }

  downloadFile(file) {
    this.attachSubscriptions(
      this.fileService.getSrc(`${this.data.host}${file.preview1080 ? file.preview1080 : file.original}?company_id=${this.data.company_id}`).pipe(
        switchMap((el:any) => {
          return this.fileService.downloadFileFromUrl(el.download_url, file)
        })
      ).subscribe((resp:any) => {
        console.log("downloadFile", resp);
        if (resp.content && resp.state == "DONE") {
          this.layoutService.showSnackBar({name: ''}, marker("Video is downloaded"), SnackBarItem);
          let newSrc = URL.createObjectURL(resp.content);
          file.blobSrc = this.sanitizer.bypassSecurityTrustResourceUrl(newSrc);
          file.blob = resp.content;
          // console.log((document.getElementById(`video_${this.tracks[0].parts[0].pId}`) as HTMLVideoElement).querySelectorAll('source')[0]);
          let parts = (this.tracks.filter(x => x.type == 'video').map(k => k.parts) as any).flat(Infinity)
          parts.forEach(part => {
            if (part.id == file.id) {
              part.blobSrc = this.sanitizer.bypassSecurityTrustResourceUrl(newSrc);
            }
          })
        }
      })
    )
  }

  getTarget() {
    console.log("getTarget", this.currentTimeProject)

    let parts = (this.tracks.filter(x => x.type == 'video').map(k => k.parts) as any).flat(Infinity)
    let partIndex:number = 0;

    parts.forEach((part, k) => {
      part.crop_on = false;
      if (this.currentTimeProject >= part.prevAllDuration && this.currentTimeProject <= part.prevAllDuration + part.duration) {
        partIndex = k;
      }
    })

    // let part = parts[partIndex];

    let part;
    if (parts[partIndex].is_combine) {
      let blendIndex:number = 0
      parts[partIndex].blends.forEach((blend, k) => {
        if (this.currentTimeProject >= blend.prevAllDuration && this.currentTimeProject <= blend.prevAllDuration + blend.duration) {
          blendIndex = k;
        }
      })
      part =  parts[partIndex].blends[blendIndex]
    } else {
      part = parts[partIndex]
    }

    console.log("getTarget part", part)
    return {
      part: part,
      ms:  Math.round(Number(((this.currentTimeProject - part.prevAllDuration)*part.part_speed_rate + part.from).toFixed(6))*1000),
      partIndex: partIndex
    }
  }

  openScrnSettings(is_create:boolean = false) {
    let x:any = {
      screenSettings: this.screenSettings,
      file: this.data.file,
      project: this.vidProject,
      is_create: is_create,
      company: this.data.company,
      company_id: this.data.company_id,
      target_company_id: this.data.target_company_id
    }

    if (is_create) {
      x.target = this.getTarget()
    }


    const dialogRef = this.dialog.open(ScrnSettingsComponent, {
      backdropClass: ['ve_back_auto'],
      panelClass: ['ve_panel_auto'],
      disableClose: true,
      data: x
    });


    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (result && result.data) {
          this.screenSettings = JSON.parse(JSON.stringify(result.data));

          if (result.event == 'create') {
            this.layoutService.showSnackBar({name: ''}, marker(`Rendering of ${this.screenSettings.filter(x => x.is_active).length} screenshot(s) started`), SnackBarItem)
          }
          this.saveVideoEditorHistory("Scrennshots Settings")
        }
        // console.log("form", this.form.value)
      })
    )
  }

  openAutomationProjects() {
    const dialogRef = this.dialog.open(AutoProjectsComponent, {
      backdropClass: ['ve_back_auto'],
      panelClass: ['ve_panel_auto'],
      disableClose: true,
      autoFocus: false,
      data: {
        project: this.vidProject,
        company: this.data.company,
        company_id: this.data.company_id,
        files: this.files,
        saveMode: this.saveMode.value,
        tracks: this.tracks,
        task: this.data.task,
        work: this.data.work,
        subProjectForm: this.projectForm,
        subProject: this.project,
        user: this.data.user,
        host: this.host,
        version: this.version
      }
    });


    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (result && result.data) {
          this.vidProject.batch_data = result.data.batch_data;
          this.vidProject.is_batch_apply = result.data.is_batch_apply;

          if (this.vidProject.batch_data.headCols && this.vidProject.batch_data.headCols.length && this.vidProject.batch_data.selectedCols && this.vidProject.batch_data.selectedCols.length) {
            this.tracks.find(p => p.type == "video").parts.forEach((part, partInd) => {
              part.activeTag = 0;

              if (!part.postNames || Object.keys(part.postNames).length == 0) {
                part.postNames = {
                  0: {
                    name: ''
                  }
                }
                this.vidProject.batch_data.headCols.filter(x => this.vidProject.batch_data.selectedCols.includes(x)).forEach(headCol => {
                  part.postNames[headCol] = {
                    name: ''
                  }
                });
              } else {
                if (!part.postNames.hasOwnProperty('0')) {
                  part.postNames['0'] = {
                    name: ''
                  }
                }

                this.vidProject.batch_data.headCols.filter(x => this.vidProject.batch_data.selectedCols.includes(x)).forEach(headCol => {
                  if (!part.postNames.hasOwnProperty(headCol)) {
                    part.postNames[headCol] = {
                      name: ''
                    }
                  }
                });
                
                Object.keys(part.postNames).forEach(key => {
                  if (!this.vidProject.batch_data.headCols.filter(x => this.vidProject.batch_data.selectedCols.includes(x)).includes(key)) {
                    delete part.postNames[key]
                  }
                }) 
              }
            })
            console.log("TEST PARTS", this.tracks.find(p => p.type == "video").parts)
          }
          this.saveVideoEditorHistory("Automation Settings")
        }
        // console.log("form", this.form.value)
      })
    )
  }

  
  isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
  }

  openCreateCards() {
    const dialogRef = this.dialog.open(CreateCardsComponent, {
      backdropClass: ['ve_interface_backdrop'],
      panelClass: ['ve_create_cards', 'animate__animated', 'animate__slideInLeft'],
      disableClose: true,
      data: {
        vidProject: this.vidProject,
        version: this.version,
        taskTemplates: this.taskTemplates,
        allValues: this.allValues,
        tracks: this.tracks,
        form: this.form,
        parameters: this.parameters,
        groups: this.groups,
        jobs: this.jobs,
        projectHasParts: this.projectHasParts,
        saveMode: this.saveMode,
        is_input_set_ai: this.is_input_set_ai,
        duration: this.duration,
        statuses: this.statuses,
        employees: this.employees,
        savedTags: this.savedTags,
        publications: this.publications,
        jobParameters: this.jobParameters,
        taskStatuses: this.taskStatuses,
        partners: this.partners,
        operations: this.operations,
        profiles: this.profiles,
        forAllContentTypes: this.forAllContentTypes,
        file: this.data.file,
        user: this.data.user,
        company: this.data.company,
        company_id: this.data.company_id,
        host: this.host,
        task: this.data.task,
        playCut: this.playCut,
        projectState$: this.projectState$,
        playVideoInterval: (x) => this.playVideoInterval(x)
      }
    });


    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (result && result.data) {
          this.form.patchValue(result.data)
        }
        if (result && result.publications) {
          this.publications = JSON.parse(JSON.stringify(result.publications, this.getCircularReplacer()))
        }
        console.log("this.publications", this.publications)
        console.log("this.publications", result)
        console.log("form", this.form.value)
        this.saveVideoEditorHistory("Create Cards Settings")
      })
    )
  }

  patchRate(val) {
    this.rateForm.patchValue({
      output_frame_rate: val
    })
  }

  patchARate(val) {
    this.rateForm.patchValue({
      output_audio_bit_rate: val
    })
  }

  onInputRate(e) {
    console.log('onInputRate', e)
    if (!Number(e.target.value)) {
      this.rateForm.patchValue({
        output_bit_rate: 0
      })
    }
    if (e.target.value < 0) {
      this.rateForm.patchValue({
        output_bit_rate: 0
      })
    }
    if (e.target.value > 30) {
      this.rateForm.patchValue({
        output_bit_rate: 30
      })
    }
    if (e.target.value.indexOf('.') != -1) {
      this.rateForm.patchValue({
        output_bit_rate: e.target.value.split('.')[0]
      })
    }
    if (e.target.value.indexOf(',') != -1) {
      this.rateForm.patchValue({
        output_bit_rate: e.target.value.split(',')[0]
      })
    }
    if (!!e.target.value && e.target.value[0] == 0) {
      this.rateForm.patchValue({
        output_bit_rate: 0
      })
    }
  }

  onInputHolst(key) {
    console.log('onInputHolst')
    if (this.holstRatio == 0) {
      return
    }

    if (key == 'height') {
      this.projectForm.patchValue({
        width: this.projectForm.value.height * this.holstRatio
      })
    } else {
      this.projectForm.patchValue({
        height: this.projectForm.value.width / this.holstRatio
      })
    }

  }

  toggleRatio() {
    if (this.holstRatio == 0) {
      this.holstRatio = this.projectForm.value.width / this.projectForm.value.height
    } else {
      this.holstRatio = 0
    }
  }

  respawnProject() {
    let raw = JSON.parse(this.activeHistory.data);
    console.log("respawnProject", raw)


    if (raw.screenSettings) {
      this.screenSettings = raw.screenSettings
    }

    if (raw.app_version) {
      this.r_version = raw.app_version
    } else {
      this.r_version = ''
    }

    raw.files.video.forEach(element => {
      delete element.blob
      delete element.blobSrc
    });
    raw.files.audio.forEach(element => {
      delete element.blob
      delete element.blobSrc
    });
    this.files.video = [...raw.files.video];
    this.files.audio = [...raw.files.audio];
    this.project = raw.project;
    this.savedTags = raw.savedTags;

    this.publications = raw.publications;
    this.jobs = raw.jobs;
    if (raw.hasOwnProperty('inheritTags')) {
      this.inheritTags = raw.inheritTags;
    }

    this.saveMode.patchValue(raw.saveMode, {emitEvent: false});
    this.is_save_audio.patchValue(raw.is_save_audio, {emitEvent: false});
    if (this.data.company && this.data.company.permissions.includes('create_task')) {
      this.is_create_cards.patchValue(raw.is_create_cards);
    } else {
      this.is_create_cards.patchValue(0);
    }
    
    this.form.patchValue(raw.form, {emitEvent: false})
    this.projectForm.patchValue(raw.projectForm, {emitEvent: false})
    if (raw.rateForm) {
      this.rateForm.patchValue(raw.rateForm, {emitEvent: false})
    }

    raw.tracks.forEach(element => {
      if (element.type == 'video') {
        if (element.parts) {
          element.parts.forEach(part => {            
            if (part.fileOriginalData) {
              if (part.fileOriginalData.rev_identification_job_data) {
                delete part.fileOriginalData.rev_identification_job_data
              }
              if (part.fileOriginalData.rev_identification_job_id) {
                delete part.fileOriginalData.rev_identification_job_id
              }
              if (part.fileOriginalData.rev_transcription_job_id) {
                delete part.fileOriginalData.rev_transcription_job_id
              }
              if (part.fileOriginalData.transcription_json) {
                delete part.fileOriginalData.transcription_json
              }
              if (part.fileOriginalData.transcription_plain) {
                delete part.fileOriginalData.transcription_plain
              }
              if (part.fileOriginalData.transcription_srt) {
                delete part.fileOriginalData.transcription_srt
              }
              if (part.fileOriginalData.transcription_vtt) {
                delete part.fileOriginalData.transcription_vtt
              }
            }
          });
        }
      }
    });

    this.tracks = [...raw.tracks];
    this.tracks.filter(x => x.type == 'video').forEach((track, trackElIndex) => {
      track.parts.forEach((part, partIndex) => {
        part.is_custom = new FormControl(raw.tracks[trackElIndex].parts[partIndex].is_custom.value)
        part.form = this.fb.group(raw.tracks[trackElIndex].parts[partIndex].form.value, {emitEvent: false})

        part.subscr = part.is_custom.valueChanges.subscribe(res => {
          console.log('is_custom', part, res)
          if (!res) {
            console.log('is_custom2', part, res)
            this.setFormValueAsHeadForm(part, false)
            part.jobs = JSON.parse(JSON.stringify(this.jobs, this.getCircularReplacer()))
          }
        })
      });
    })

    this.data.file = raw.file;
    if (this.holstRatio == 0) {
      this.holstRatio = this.projectForm.value.width / this.projectForm.value.height
    }
    
    this.data.file.is_custom = new FormControl(raw.file.is_custom.value);
    this.files.video.forEach(element => {
      this.fileService.projectFiles$.next(element)
    });
    this.files.audio.forEach(element => {
      this.fileService.projectFiles$.next(element)
    });
    this.getTags();
    this.refreshTracks();
    console.log("respawnProject active", this.activeHistory);
    console.log("respawnProject history", this.history);

    console.log("respawnProject Test 1", this.getHistoryCount);
    console.log("respawnProject Test 2", this.data.openAsNew);
    if (this.getHistoryCount == 1 && this.data.openAsNew) {
      this.createProject();
    }
  }

  historyPrev() {
    let activeIndex = this.history.indexOf(this.activeHistory);
    this.activeHistory = this.history[activeIndex + 1];
    this.respawnProject()
  }

  historyNext() {
    let activeIndex = this.history.indexOf(this.activeHistory);
    this.activeHistory = this.history[activeIndex - 1];
    this.respawnProject()
  }

  hasPrevHistory() {
    return !!this.history && this.history.length && !!this.activeHistory && this.history.indexOf(this.activeHistory) != this.history.length - 1
  }

  hasNextHistory() {
    return !!this.history && this.history.length && !!this.activeHistory && this.history.indexOf(this.activeHistory) != 0
  }

  saveVideoEditorHistory(title?) {
    console.log("#### TITLE SAVE ####", title)
    let savedTracks = JSON.parse(JSON.stringify(this.tracks, this.getCircularReplacer()));
    savedTracks.forEach((trackEl, trackInd) => {
      trackEl.parts.forEach((part, partInd) => {
        delete part.blobSrc
        delete part.subscr

        if (part.fileOriginalData) {
          if (part.fileOriginalData.rev_identification_job_data) {
            delete part.fileOriginalData.rev_identification_job_data
          }
          if (part.fileOriginalData.rev_identification_job_id) {
            delete part.fileOriginalData.rev_identification_job_id
          }
          if (part.fileOriginalData.rev_transcription_job_id) {
            delete part.fileOriginalData.rev_transcription_job_id
          }
          if (part.fileOriginalData.transcription_json) {
            delete part.fileOriginalData.transcription_json
          }
          if (part.fileOriginalData.transcription_plain) {
            delete part.fileOriginalData.transcription_plain
          }
          if (part.fileOriginalData.transcription_srt) {
            delete part.fileOriginalData.transcription_srt
          }
          if (part.fileOriginalData.transcription_vtt) {
            delete part.fileOriginalData.transcription_vtt
          }
        }

        if (part.frames) {
          let savedFrames = []
          part.frames.filter(k => !!k.uploadedFile).forEach(frame => {
            let savedFrame = {
              blobSrc: this.host + frame.uploadedFile.original + 'company_id=' + this.data.company.id,
              time: frame.time,
              uploadedFile: {
                id: frame.uploadedFile.id,
                original: frame.uploadedFile.original,
                filename: frame.uploadedFile.filename
              }
            }
            savedFrames.push(savedFrame)
          });
          part.frames = savedFrames;
        }
      });
    })
    
    let savedFiles = JSON.parse(JSON.stringify(this.files, this.getCircularReplacer()));
    savedFiles.video.forEach(element => {
      delete element.blob
      delete element.blobSrc
    });
    savedFiles.audio.forEach(element => {
      delete element.blob
      delete element.blobSrc
    });

    let raw = {
      title: title,
      saveMode: this.saveMode.value,
      is_save_audio: this.is_save_audio.value,
      is_create_cards: this.is_create_cards.value,
      project: this.project,
      savedTags: this.savedTags,
      form: this.form.value,
      projectForm: this.projectForm.value,
      rateForm: this.rateForm.value,
      tracks: savedTracks,
      files: savedFiles,
      inheritTags: this.inheritTags,
      file: this.data.file,
      jobs: this.jobs,
      screenSettings: this.screenSettings,
      publications: this.publications,
      app_version: app_version
    }

    console.log("RAW", raw);

    if (this.vidProject && (!this.activeHistory || this.activeHistory.data != JSON.stringify(raw, this.getCircularReplacer()))) {
      if (this.saveSub) {
        this.saveSub.unsubscribe()
      }

      this.is_saving = true;
      console.log("RAW", raw)
      console.log("RAW string", JSON.stringify(raw, this.getCircularReplacer()))
      this.saveSub = this.fileService.saveVideoEditorHistory({
        company_id: this.data.target_company_id,
        editor_version: this.version.toString(),
        video_project_id: this.vidProject.id,
        parent_id: !!this.activeHistory ? this.activeHistory.id : 0,
        data: JSON.stringify(raw, this.getCircularReplacer()),
        is_active: 1
      }, this.data.company_id).subscribe(resp => {
        console.log("saveVideoEditorHistory", resp)
        this.activeHistory = resp;
        this.historyPage = 1;
        this.is_saving = false;
        this.getVideoEditorHistory()
      }, error => {
        this.is_saving = false;
      })
    }
  }

  getVideoEditorHistory() {
    this.attachSubscriptions(
      this.fileService.getVideoEditorHistory(this.historyPage, this.data.company.id, {
        company_id: this.data.target_company_id,
        video_project_id: this.vidProject.id,
        is_active: 1
      }, '20').pipe(
        tap(el => {
          this.historyPagination = {
            'pageCount': +el.headers.get('x-pagination-page-count'),
            'perPage': el.headers.get('x-pagination-per-page'),
            'totalCount': +el.headers.get('x-pagination-total-count'),
            'currentPage': +el.headers.get('x-pagination-current-page'),
          }
        }),
        map(el => el.body)
      ).subscribe(resp => {
        console.log("getVideoEditorHistory" + this.historyPage, resp)
        this.history = resp;
        this.activeHistory = this.history[0]
        this.historyPage++;

        if (this.getHistoryCount == 1 && !!this.data.project) {
          this.respawnProject()
        }
        this.getHistoryCount++;
      })
    )
  }

  isYouTube(number) {
    // Преобразуем число в строку для удобства работы с символами
    const numStr = number.toString();

    // Проверяем, начинается ли строка с символа '3'
    return numStr.charAt(0) === '1';
  }

  isTikTok(number) {
    // Преобразуем число в строку для удобства работы с символами
    const numStr = number.toString();

    // Проверяем, начинается ли строка с символа '3'
    return numStr.charAt(0) === '3';
  }

  createProject() {
    this.attachSubscriptions(
      this.fileService.createVideoProject({
        batch_data: !!this.data.project && !!this.data.project.batch_data ? this.data.project.batch_data : '',
        editor_version: this.version.toString(),
        company_id: this.data.target_company_id,
        task_id: this.data.file.task_id,
        task_operation_id: this.data.otherJob ? this.data.work.id : this.data.file.task_operation_id,
        discussion_id: this.data.file.discussion_id,
        name: this.data.file.filename.replace('.'+this.data.file.extension, ' cuts'),
        output_location: this.data.file.location == "/" ? this.data.file.location + `cut files of ${this.data.file.filename.replace('.'+this.data.file.extension, '')}` : this.data.file.location + `/cut files of ${this.data.file.filename.replace('.'+this.data.file.extension, '')}`,
        is_draft: 1,
        output_width: this.projectForm.value.width,
        output_height: this.projectForm.value.height,
        output_frame_rate: this.rateForm.value.output_frame_rate,
        output_audio_bit_rate: this.rateForm.value.output_audio_bit_rate,
        output_bit_rate: this.rateForm.value.output_bit_rate * 1000000,
        waiting_time_limit: this.waiting_time_limit.value
      }, this.data.company_id).subscribe(resp => {
        console.log("createProject", resp)
        this.vidProject = resp;
        this.historyPage = 1;
        this.getVideoEditorHistory();
      })
    )
  }


  cardTitleInput(cut?) {
    if (cut) {
      cut.is_input_set_ai = false
    } else {
      this.is_input_set_ai = false
    }
  }

  toggleControl(control, val) {
    control.patchValue(!!val ? false : true)
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  onSearchProfiles(resp) {
    if (!this.profiles) {
      return;
    }

    if (!resp) {
      this.profiles$.next(this.profiles.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }

    // filter the banks
    this.profiles$.next(
      this.profiles.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  getProfiles() {
    this.attachSubscriptions(
      this.companyService.getAllProfilesDyn('1', this.data.company.id, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }
  
          return forkJoin(arr.map(x => this.companyService.getAllProfilesDyn(x, this.data.company.id, '200').pipe(map(u => u.body)))).pipe(
            last(),
            tap(values => {
              let conValues = [].concat(...values)
              this.profiles = conValues;
              this.profiles$.next(this.profiles.slice());
            }),
          )
        }),
      ).subscribe(resp => {
        console.log("getGroupProfiles resp", resp);
        console.log("getGroupProfiles profiles", this.profiles);
      })
    )
  }

  getChannelById(id) {
    if (!this.profiles || this.profiles.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.profiles.find(el => el.id == id)
  }

  getTypeById(id) {
    if (!this.forAllContentTypes || this.forAllContentTypes.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.forAllContentTypes.find(el => el.id == id)
  }

  toggleMode(val) {
    let videosCount = this.getVideoParts().length;
    if (videosCount < 2) {
      this.layoutService.showSnackBar({name: ''}, marker("Parts mode is not possible there must be more than 1 video fragment"), SnackBarItem)
      return
    }
    this.saveMode.patchValue(val == 'parts' ? 'concat' : 'parts')
    if (this.saveMode.value == 'parts') {
      this.inheritTags = true;
    }
  }

  detachAudio(part?) {
    if (part) {
      this.activeItem = part;
    }
    if (!!this.activeItem.is_detached) {
      return
    }

    console.log("activeItem", this.activeItem)
    this.activeItem.is_video_only = 0;
    this.activeItem.is_audio_only = 1;
    // reverse
    this.changeVidOnly()
    this.changeAudOnly()

    this.activeItem.is_detached = true;

    if (this.activeItem.is_combine) {
      this.activeItem.blends.forEach(blend => {
        if (!blend.is_detached) {
          blend.is_detached = true;
          let activeCopy = JSON.parse(JSON.stringify(blend, this.getCircularReplacer()))
          activeCopy.is_detach = true;
          activeCopy.pId = this.generateRandomId();
      
          if (this.tracks.length > 1) {
            let parts = this.tracks[1].parts;
      
            let range = {
              from: activeCopy.prevAllDuration,
              to: activeCopy.prevAllDuration + activeCopy.duration
            }
      
            let canAdd: boolean = true;
            parts.forEach(el => {
      
              let rFrom = range.from;
              let rTo = range.to;
              let left = el.prevAllDuration;
              let right = el.prevAllDuration + el.duration;
              console.log("rFrom", rFrom)
              console.log("rTo", rTo)
              console.log("left", left)
              console.log("right", right)
      
              // if (!((el.prevAllDuration >= range.from && el.prevAllDuration < range.to) || (((el.prevAllDuration + el.duration) >= range.from) && ((el.prevAllDuration + el.duration) < range.to)))) {
              //   canAdd = false
              // }
              if ((left <= rFrom && right > rFrom) || (left > rFrom && left < rTo) || (right > rFrom && right < rTo)) {
                canAdd = false;
              }
            })
            if (canAdd) {
              parts.push({...activeCopy})
              parts.sort(function(a, b) {
                return (a.prevAllDuration - b.prevAllDuration);
              })
            } else {
              this.tracks.splice(1, 0 , {
                type: 'audio',
                parts: [{...activeCopy}]
              })
            }
          } else {
            this.tracks.push({
              type: 'audio',
              parts: [{...activeCopy}]
            })
          }
        }
      })
    } else {
      let activeCopy = JSON.parse(JSON.stringify(this.activeItem, this.getCircularReplacer()))
      activeCopy.is_detach = true;
      activeCopy.pId = this.generateRandomId();
  
      if (this.tracks.length > 1) {
        let parts = this.tracks[1].parts;
  
        let range = {
          from: activeCopy.prevAllDuration,
          to: activeCopy.prevAllDuration + activeCopy.duration
        }
  
        let canAdd: boolean = true;
        parts.forEach(el => {
  
          let rFrom = range.from;
          let rTo = range.to;
          let left = el.prevAllDuration;
          let right = el.prevAllDuration + el.duration;
          console.log("rFrom", rFrom)
          console.log("rTo", rTo)
          console.log("left", left)
          console.log("right", right)
  
          // if (!((el.prevAllDuration >= range.from && el.prevAllDuration < range.to) || (((el.prevAllDuration + el.duration) >= range.from) && ((el.prevAllDuration + el.duration) < range.to)))) {
          //   canAdd = false
          // }
          if ((left <= rFrom && right > rFrom) || (left > rFrom && left < rTo) || (right > rFrom && right < rTo)) {
            canAdd = false;
          }
        })
        if (canAdd) {
          parts.push({...activeCopy})
          parts.sort(function(a, b) {
            return (a.prevAllDuration - b.prevAllDuration);
          })
        } else {
          this.tracks.splice(1, 0 , {
            type: 'audio',
            parts: [{...activeCopy}]
          })
        }
      } else {
        this.tracks.push({
          type: 'audio',
          parts: [{...activeCopy}]
        })
      }
    }

    this.saveVideoEditorHistory("Detach Audio");
  }



  openCutTargetValues(cut) {
    let initData:any = {
      company: this.data.company,
      auto: true,
      parameters: this.parameters,
      tags: cut.tags,
      forOutput: true
    }

    const dialogRef = this.dialog.open(TargetParametersComponent, {
      backdropClass: ['parameters_modal_backdrop'],
      panelClass: ['without_paddings_modal', 'parameters_modal'],
      data: initData
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result) {
          if (result.event == 'save') {
            console.log(result.data);
            cut.tags = result.data[0].activeValues;
            // this.parameters = result.data
            // this.form.patchValue({
            //   parameter_value_id: this.parameters[0].activeValues.map(x => x.id)
            // })
          }
        }
      })
    )
  }
  // clickToCurrentTime(e) {
  //   e.stopPropagation();
  //   e.preventDefault();
  //   if (this.mode == 'cut') {
  //     this.addCut(this.currentTimeProject)
  //   }
  // }

  getAllApiParameterValues() {
    this.attachSubscriptions(
      this.parametersService.getAllValues('1', this.data.company.id, null, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.parametersService.getAllValues(x, this.data.company.id).pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        this.allValues = [].concat(...res);
        console.log("getAllApiParameterValues", this.allValues)
      })
    )
  }

  toNewOrient(is_hor) {
    let saveForm = {...this.projectForm.value}
    if (is_hor) {
      this.projectForm.patchValue({
        height: Math.max(saveForm.width, saveForm.height),
        width: Math.min(saveForm.width, saveForm.height)
      })
    } else {
      this.projectForm.patchValue({
        height: Math.min(saveForm.width, saveForm.height),
        width: Math.max(saveForm.width, saveForm.height)
      })
    }
  }

  zoomPlus() {
    // let prevScroll = this.outContainer.nativeElement.children[0].scrollLeft;
    if (this.plusDisabled) {
      return
    }
    this.plusDisabled = true;
    let prevMarker = document.getElementById('current-marker').offsetLeft;
    this.zoom.patchValue(this.zoom.value + 0.5);
    let currMarker;
    setTimeout(() => {
      currMarker = document.getElementById('current-marker').offsetLeft;
      console.log('currMarker', currMarker);
      let deltaMarker = currMarker - prevMarker;
      this.outContainer.nativeElement.children[0].scrollLeft = Math.max(this.outContainer.nativeElement.children[0].scrollLeft + deltaMarker, 0);
      this.plusDisabled = false;
    },0)
  }

  zoomDefault() {
    this.zoom.patchValue(1)
    // let currMarker = document.getElementById('current-marker').offsetLeft;
    // console.log(currMarker)
  }

  zoomMinus() {
    if (this.zoom.value - 0.5 > 0) {
      if (this.minusDisabled) {
        return
      }
      this.minusDisabled = true;
      let prevScroll = this.outContainer.nativeElement.children[0].scrollLeft;
      let prevMarker = document.getElementById('current-marker').offsetLeft;
      // let containerPrev = this.outContainer.nativeElement.children[0].scrollWidth;
      // console.log('containerPrev 1', containerPrev);
      this.zoom.patchValue(this.zoom.value - 0.5)  
      let currMarker;
      let containerCurr;
      setTimeout(() => {
        currMarker = document.getElementById('current-marker').offsetLeft;
        containerCurr = this.outContainer.nativeElement.children[0].scrollWidth;
        console.log('containerCurr 2', containerCurr);
        // console.log('prevScroll', prevScroll);
        console.log('currMarker', currMarker);
        console.log('prevMarker', prevMarker);
        let deltaMarker = prevMarker - currMarker;
        console.log('deltaMarker', deltaMarker);
        this.outContainer.nativeElement.children[0].scrollLeft = Math.max(prevScroll - deltaMarker, 0);
        this.minusDisabled = false;
      },0)
    }
  }  
  
  formatLabel(value: number): string {
    return value + 'x';
  }

  generateRandomId(): string {
    const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    const length = 10;
    let randomId = '';
  
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      randomId += characters.charAt(randomIndex);
    }
  
    return randomId;
  }

  private handleResize(width: number, height: number) {
      if (!this.project) {
        return
      }
      this.resData = {width, height}
      let savedProj = JSON.parse(JSON.stringify(this.project));

      if (this.project.ratio <= 1) {
        this.project.height = height;
        this.project.width = this.project.height * this.project.ratio;

        if (this.project.width > width) {
          this.project.width = width;
          this.project.height = this.project.width / this.project.ratio;
        }
      } else {
        this.project.width = width;
        this.project.height = this.project.width / this.project.ratio;

        if (this.project.height > height) {
          this.project.height = height;
          this.project.width = this.project.height * this.project.ratio;
        }
      }



      this.project.percentY = this.project.height / this.project.mHeight;
      this.project.percentX = this.project.width / this.project.mWidth;
  
      console.log("mmTest savedProj", savedProj)
      console.log("mmTest this.project", this.project)

      let savePX = this.project.width / savedProj.width;
      let savePY = this.project.height / savedProj.height;
      console.log('mmTest savePX', savePX)
      console.log('mmTest savePY', savePY)

      let parts = (this.tracks.filter(x => x.type == 'video').map(k => k.parts) as any).flat(Infinity)

      parts.forEach(part => {
        if (!part.rd || !part.rd.height) {
          if (part.rd.ratio <= 1) {
            part.rd.height = height;
            part.rd.width = part.rd.height * part.rd.ratio;

            if (part.rd.width > width) {
              part.rd.width = width;
              part.rd.height = part.rd.width / part.rd.ratio;
            }
          } else {
            part.rd.width = width;
            part.rd.height = part.rd.width / part.rd.ratio;

            if (part.rd.height > height) {
              part.rd.height = height;
              part.rd.width = part.rd.height * part.rd.ratio;
            }
          }
          part.rd.percentY = part.rd.height / part.rd.mHeight;
          part.rd.percentX = part.rd.width / part.rd.mWidth;
        } else {
          if (!!savePX && savePX != 1) {
            part.rd.width = part.rd.width * savePX;
            part.rd.left = part.rd.left * savePX;
          }
          if (!!savePY && savePY != 1) {
            part.rd.height = part.rd.height * savePY;
            part.rd.top = part.rd.top * savePX;
          }
          part.rd.percentY = part.rd.height / part.rd.mHeight;
          part.rd.percentX = part.rd.width / part.rd.mWidth;
        }

        // this.calculateSizes(part, this.project);
      });
      
      console.log("parts", parts)
      // Вызовите здесь ваше событие или выполните нужные действия при изменении размеров элемента.
      console.log('Размеры элемента prevPanelOut изменены', width, height);
  }

  private observeSizeChanges() {
    const targetElement = this.prevPanelOut.nativeElement;
    
    const resObs = new (window as any).ResizeObserver((entries: ResizeObserverEntry[]) => {
      for (const entry of entries) {
        let width = entry.target.clientWidth;
        let height = entry.target.clientHeight;
        
        this.handleResize(width, height);
      }
    });

    resObs.observe(targetElement);
  }

  ngAfterViewInit(): void {
    this.observeSizeChanges();
    this.projectState$.subscribe((isPlaying) => {
      this.isProjectPlaying = isPlaying;
    });

    let projectPos = JSON.parse(JSON.stringify(this.holst.nativeElement.getBoundingClientRect()));

    if (!this.data.project) {
      this.project = {
        mWidth: this.data.file.meta_width,
        mHeight: this.data.file.meta_height,
        ratio: this.data.file.meta_width / this.data.file.meta_height,
        rect: Object.assign(projectPos, {x: projectPos.x - 200, left: projectPos.left - 200, right: projectPos.right - 200})
        // height: 380,
        // percent: 380 / this.data.file.meta_height,
      }
    }

    if (this.data.file) {
      if (this.data.addVideos && this.data.addVideos.length > 0) {
        this.addFileToTimeline('video', this.data.addVideos)
      }
      if (this.data.addAudios && this.data.addAudios.length > 0) {
        this.addFileToTimeline('audio', this.data.addAudios)
      }
    }

    this.cdRef.detectChanges();
  }

  hasParts() {
    return !!this.tracks.filter(trackEl => trackEl.parts.length > 1) || this.projectHasParts
  }

  hasCropOn() {
    let parts = (this.tracks.filter(x => x.type == 'video').map(k => k.parts) as any).flat(Infinity)
    return !!parts.filter(x => !!x.crop_on).length
  }

  changePartVolume(e, part) {
    console.log("changePartVolume", part);
    if (part.trackElIndex == 0) {
      if (part.is_combine) {
        part.blends.forEach(blend => {
          blend.volume = e;
          (document.getElementById(`video_${blend.pId}`) as HTMLVideoElement).volume = e;
        });
      } else {
        (document.getElementById(`video_${part.pId}`) as HTMLVideoElement).volume = e;
      }
    } else {
      (document.getElementById(`audio_${part.pId}`) as HTMLAudioElement).volume = e;
    }
  }

  playProject() {
    console.log("play");
    if (this.currentTimeProject >= this.duration) {
      this.currentTimeProject = 0;
    }

    let parts = (this.tracks.filter(x => x.type == 'video').map(k => k.parts) as any).flat(Infinity)
    let partIndex:number = 0;

    parts.forEach((part, k) => {
      part.crop_on = false;
      if (this.currentTimeProject >= part.prevAllDuration && this.currentTimeProject <= part.prevAllDuration + part.duration) {
        partIndex = k;
      }
    })

    // let part = parts[partIndex];

    let part;
    if (parts[partIndex].is_combine) {
      let blendIndex:number = 0
      parts[partIndex].blends.forEach((blend, k) => {
        if (this.currentTimeProject >= blend.prevAllDuration && this.currentTimeProject <= blend.prevAllDuration + blend.duration) {
          blendIndex = k;
        }
      })
      part =  parts[partIndex].blends[blendIndex]
    } else {
      part = parts[partIndex]
    }

    (document.getElementById(`video_${part.pId}`) as HTMLVideoElement).currentTime = Number(((this.currentTimeProject - part.prevAllDuration)*part.part_speed_rate + part.from).toFixed(6));

    let audParts = this.tracks.find(x => x.type == 'audio') ? (this.tracks.filter(x => x.type == 'audio').map(u => u.parts) as any).flat() : [];
    if (audParts.length) {
      audParts.forEach((audPart: any, k) => {
        if (this.currentTimeProject >= audPart.prevAllDuration && this.currentTimeProject <= audPart.prevAllDuration + audPart.duration) {
          (document.getElementById(`audio_${audPart.pId}`) as HTMLAudioElement).currentTime = Number(((this.currentTimeProject - audPart.prevAllDuration)*audPart.part_speed_rate + audPart.from).toFixed(6));
          if (!!part.is_detached || !part.is_audio_only) {
            (document.getElementById(`video_${part.pId}`) as HTMLVideoElement).muted = true;
          } else {
            (document.getElementById(`video_${part.pId}`) as HTMLVideoElement).muted = false;
          }
          console.log("audio_", (document.getElementById(`audio_${audPart.pId}`) as HTMLAudioElement));
          (document.getElementById(`audio_${audPart.pId}`) as HTMLAudioElement).volume = audPart.volume;
          this.changeRate((document.getElementById(`audio_${audPart.pId}`) as HTMLAudioElement), this.projectSpeed != 1 ? this.projectSpeed : audPart.part_speed_rate);
          (document.getElementById(`audio_${audPart.pId}`) as HTMLAudioElement).play();
        }
      });
    }
    if (!!part.is_detached || !part.is_audio_only) {
      (document.getElementById(`video_${part.pId}`) as HTMLVideoElement).muted = true;
    } else {
      (document.getElementById(`video_${part.pId}`) as HTMLVideoElement).muted = false;
    }
    this.changeRate((document.getElementById(`video_${part.pId}`) as HTMLVideoElement), this.projectSpeed != 1 ? this.projectSpeed : part.part_speed_rate);
    (document.getElementById(`video_${part.pId}`) as HTMLVideoElement).play();
    this.projectState$.next(true);
    this.updateProgress();
  }

  updateProgress() {
    console.log("updateProgress");
    let lastUpdateTime = performance.now();

    let videos:any = document.querySelectorAll('.player');
    let audios:any = document.querySelectorAll('.audioPlayer');
    let parts = this.tracks.find(x => x.type == 'video').parts;
    // let audioParts = this.tracks.find(x => x.type == 'audio') ? this.tracks.find(x => x.type == 'audio').parts : [];
    let audParts = this.tracks.find(x => x.type == 'audio') ? (this.tracks.filter(x => x.type == 'audio').map(u => u.parts) as any).flat() : [];

  
    const update = () => {
      if (this.isProjectPlaying) {
        let partIndex:number = 0;
        let partAudIndex:number = 0;
        const now = performance.now();
        const elapsed = (now - lastUpdateTime) * this.projectSpeed;
  
        // Update currentTimeProject based on elapsed time
        this.currentTimeProject += elapsed / 1000;
        lastUpdateTime = now;

        if (this.playCut) {
          if (this.currentTimeProject >= this.duration) {
            this.playCut = undefined;
            this.pauseProject();
          } else {
            if (audParts.length) {
              audParts.forEach((audPart: any, k) => {
                if (this.currentTimeProject >= audPart.prevAllDuration && this.currentTimeProject <= audPart.prevAllDuration + audPart.duration) {
                  // (document.getElementById(`audio_${audPart.pId}`) as HTMLAudioElement).currentTime = Number(((this.currentTimeProject - audPart.prevAllDuration)*audPart.part_speed_rate + audPart.from).toFixed(6));
                  // (document.getElementById(`video_${part.pId}`) as HTMLVideoElement).muted = true;
                  
                  if ((document.getElementById(`audio_${audPart.pId}`) as HTMLAudioElement).paused || (Number(audPart.from.toFixed(6)) - Number((document.getElementById(`audio_${audPart.pId}`) as HTMLAudioElement).currentTime.toFixed(6)) > 0.25)) {
                    (document.getElementById(`audio_${audPart.pId}`) as HTMLAudioElement).currentTime = Number(audPart.from.toFixed(6));
                    (document.getElementById(`audio_${audPart.pId}`) as HTMLAudioElement).volume = audPart.volume;
                    this.changeRate((document.getElementById(`audio_${audPart.pId}`) as HTMLAudioElement), this.projectSpeed != 1 ? this.projectSpeed : audPart.part_speed_rate);
                    (document.getElementById(`audio_${audPart.pId}`) as HTMLAudioElement).play();
                    
                    let audIndex = this.tracks[audPart.trackElIndex].parts.indexOf(audPart)
                    if (audIndex != 0) {
                      (document.getElementById(`audio_${this.tracks[audPart.trackElIndex].parts[audIndex - 1].pId}`) as HTMLAudioElement).pause();
                    }
                  }
                } else {
                  if (!(document.getElementById(`audio_${audPart.pId}`) as HTMLAudioElement).paused) {
                    (document.getElementById(`audio_${audPart.pId}`) as HTMLAudioElement).pause();
                  }
                }
              });
            }

            if (this.playCut.is_combine) {
              let blendIndex:number = 0
              this.playCut.blends.forEach((blend, k) => {
                if (this.currentTimeProject >= blend.prevAllDuration && this.currentTimeProject <= blend.prevAllDuration + blend.duration) {
                  blendIndex = k;
                }
              })
              let part =  this.playCut.blends[blendIndex]
            

              if ((document.getElementById(`video_${part.pId}`) as HTMLVideoElement).playbackRate != part.part_speed_rate) {
                this.changeRate((document.getElementById(`video_${part.pId}`) as HTMLVideoElement), this.projectSpeed != 1 ? this.projectSpeed : part.part_speed_rate);
              }
              if ((document.getElementById(`video_${part.pId}`) as HTMLVideoElement).paused || (Number((part.from).toFixed(6)) - Number((document.getElementById(`video_${part.pId}`) as HTMLVideoElement).currentTime.toFixed(6)) > 0.25)) {
  
                (document.getElementById(`video_${part.pId}`) as HTMLVideoElement).currentTime = Number((part.from).toFixed(6));
                if ((document.getElementById(`video_${part.pId}`) as HTMLVideoElement).paused) {
                  console.log("audios", [...audios])
                  if (!!part.is_detached || !part.is_audio_only) {
                    (document.getElementById(`video_${part.pId}`) as HTMLVideoElement).muted = true;
                  } else {
                    (document.getElementById(`video_${part.pId}`) as HTMLVideoElement).muted = false;
                  }
                  this.changeRate((document.getElementById(`video_${part.pId}`) as HTMLVideoElement), this.projectSpeed != 1 ? this.projectSpeed : part.part_speed_rate);
                  (document.getElementById(`video_${part.pId}`) as HTMLVideoElement).play();
                  if (blendIndex != 0 && (document.getElementById(`video_${this.playCut.blends[blendIndex - 1].pId}`) as HTMLVideoElement)) {
                    (document.getElementById(`video_${this.playCut.blends[blendIndex - 1].pId}`) as HTMLVideoElement).pause();
                  }
                  if (partIndex != 0) {
                    if (parts[partIndex - 1].is_combine) {
                      (document.getElementById(`video_${parts[partIndex - 1].blends[parts[partIndex - 1].blends.length - 1].pId}`) as HTMLVideoElement).pause();
                    } else {
                      (document.getElementById(`video_${parts[partIndex - 1].pId}`) as HTMLVideoElement).pause();
                    }
                  }
                }
              }

              if (this.playCut.prevAllDuration + this.playCut.duration <= this.currentTimeProject) {
                // (document.getElementById(`video_${this.playCut.blends[this.playCut.blends.length - 1].pId}`) as HTMLVideoElement).pause();
                videos.forEach((vid: HTMLVideoElement, k) => {
                  vid.pause();
                });
                audios.forEach((aud: HTMLAudioElement, k) => {
                  aud.pause();
                });
                this.currentTimeProject = this.playCut.prevAllDuration + this.playCut.duration
                this.playCut = undefined;
                this.projectState$.next(false);
              } else {
                requestAnimationFrame(update);
              }
            } else {
              if ((document.getElementById(`video_${this.playCut.pId}`) as HTMLVideoElement).currentTime >=this.playCut.to) {
                (document.getElementById(`video_${this.playCut.pId}`) as HTMLVideoElement).pause();
                audios.forEach((aud: HTMLAudioElement, k) => {
                  aud.pause();
                });
                this.currentTimeProject = this.playCut.prevAllDuration + this.playCut.duration
                this.playCut = undefined;
                this.projectState$.next(false);
              } else {
                requestAnimationFrame(update);
              }
            } 
          }
        } else {
          if (this.currentTimeProject >= this.duration) {
            this.pauseProject();
          } else {

            parts.forEach((part: any, k) => {
              if (this.currentTimeProject >= part.prevAllDuration && this.currentTimeProject <= part.prevAllDuration + part.duration) {
                partIndex = k;
              }
            });
        
            // let part = parts[partIndex];
            let part;
            let blendIndex:number = 0;
            if (parts[partIndex].is_combine) {
              parts[partIndex].blends.forEach((blend, k) => {
                if (this.currentTimeProject >= blend.prevAllDuration && this.currentTimeProject <= blend.prevAllDuration + blend.duration) {
                  blendIndex = k;
                }
              })
              part = parts[partIndex].blends[blendIndex]
            } else {
              part = parts[partIndex]
            }

            if (audParts.length) {
              audParts.forEach((audPart: any, k) => {
                if (this.currentTimeProject >= audPart.prevAllDuration && this.currentTimeProject <= audPart.prevAllDuration + audPart.duration) {
                  // (document.getElementById(`audio_${audPart.pId}`) as HTMLAudioElement).currentTime = Number(((this.currentTimeProject - audPart.prevAllDuration)*audPart.part_speed_rate + audPart.from).toFixed(6));
                  // (document.getElementById(`video_${part.pId}`) as HTMLVideoElement).muted = true;
                  
                  if ((document.getElementById(`audio_${audPart.pId}`) as HTMLAudioElement).paused || (Number(audPart.from.toFixed(6)) - Number((document.getElementById(`audio_${audPart.pId}`) as HTMLAudioElement).currentTime.toFixed(6)) > 0.25)) {
                    (document.getElementById(`audio_${audPart.pId}`) as HTMLAudioElement).currentTime = Number(audPart.from.toFixed(6));
                    (document.getElementById(`audio_${audPart.pId}`) as HTMLAudioElement).volume = audPart.volume;
                    this.changeRate((document.getElementById(`audio_${audPart.pId}`) as HTMLAudioElement), this.projectSpeed != 1 ? this.projectSpeed : audPart.part_speed_rate);
                    (document.getElementById(`audio_${audPart.pId}`) as HTMLAudioElement).play();
                    
                    let audIndex = this.tracks[audPart.trackElIndex].parts.indexOf(audPart)
                    if (audIndex != 0 && !!this.tracks[audPart.trackElIndex].parts[audIndex - 1]) {
                      (document.getElementById(`audio_${this.tracks[audPart.trackElIndex].parts[audIndex - 1].pId}`) as HTMLAudioElement).pause();
                    }
                  }
                } else {
                  if (!(document.getElementById(`audio_${audPart.pId}`) as HTMLAudioElement).paused) {
                    (document.getElementById(`audio_${audPart.pId}`) as HTMLAudioElement).pause();
                  }
                }
              });
            }

  
            if ((document.getElementById(`video_${part.pId}`) as HTMLVideoElement).playbackRate != part.part_speed_rate) {
              this.changeRate((document.getElementById(`video_${part.pId}`) as HTMLVideoElement), this.projectSpeed != 1 ? this.projectSpeed : part.part_speed_rate);
            }
            if ((document.getElementById(`video_${part.pId}`) as HTMLVideoElement).paused || (Number((part.from).toFixed(6)) - Number((document.getElementById(`video_${part.pId}`) as HTMLVideoElement).currentTime.toFixed(6)) > 0.25)) {

              (document.getElementById(`video_${part.pId}`) as HTMLVideoElement).currentTime = Number((part.from).toFixed(6));
              if ((document.getElementById(`video_${part.pId}`) as HTMLVideoElement).paused) {
                console.log("audios", [...audios])
                if (!!part.is_detached || !part.is_audio_only) {
                  (document.getElementById(`video_${part.pId}`) as HTMLVideoElement).muted = true;
                } else {
                  (document.getElementById(`video_${part.pId}`) as HTMLVideoElement).muted = false;
                }
                this.changeRate((document.getElementById(`video_${part.pId}`) as HTMLVideoElement), this.projectSpeed != 1 ? this.projectSpeed : part.part_speed_rate);
                (document.getElementById(`video_${part.pId}`) as HTMLVideoElement).play();
                if (!!part.is_blend && blendIndex != 0 && (document.getElementById(`video_${parts[partIndex].blends[blendIndex - 1].pId}`) as HTMLVideoElement)) {
                  (document.getElementById(`video_${parts[partIndex].blends[blendIndex - 1].pId}`) as HTMLVideoElement).pause();
                }
                if (partIndex != 0) {
                  if (parts[partIndex - 1].is_combine) {
                    (document.getElementById(`video_${parts[partIndex - 1].blends[parts[partIndex - 1].blends.length - 1].pId}`) as HTMLVideoElement).pause();
                  } else {
                    (document.getElementById(`video_${parts[partIndex - 1].pId}`) as HTMLVideoElement).pause();
                  }
                }
              }
            }
            // Call the update function again in 16 ms
            requestAnimationFrame(update);
          }
        }

      }
    };
  
    // Start updating the progress
    requestAnimationFrame(update);
  }

  // Функция для паузы проекта
  pauseProject() {
    console.log("pause")
    this.playCut = undefined;
    this.projectState$.next(false);
    let videos = document.querySelectorAll('.player')
    let audios:any = document.querySelectorAll('.audioPlayer');
    videos.forEach((vid: HTMLVideoElement) => {
      vid.pause();
    })
    audios.forEach((aud: HTMLAudioElement) => {
      aud.pause();
    })

    // (document.getElementById('video_cstVideo') as HTMLVideoElement).pause()
  }

  loadedVideo(e, track, part, ind, is_audio?) {
    if (part.duration) {
      return
    }
    console.log("loadedVideo", e, track, part, ind);
    part.saveDuration = +e.target.duration;
    part.duration = +e.target.duration;
    // this.duration += +e.target.duration;
    let durs = []
    this.tracks.forEach(trackEl => {
      durs.push(trackEl.parts.map(n => n.duration).reduce((acc, number) => +acc + +number, 0))
    })
    this.duration = Math.max(...durs)
    part.prevAllDuration = (ind == 0 ? 0 : track.parts.slice(0, ind).map(n => n.duration).reduce((acc, number) => +acc + +number, 0));
    part.from = 0;
    part.to = part.duration;
    
    let parts = (this.tracks.map(k => k.parts) as any).flat(Infinity)
    if (parts.length == 1 && (!this.history || this.history.length == 0)) {
      this.saveVideoEditorHistory("Loaded Video")
    }
    this.getLines();
  }

  getLines() {
    this.wWidth = (window.innerWidth*this.zoom.value) - 40;
    // if (this.zoom.value < 1) {}
    const tickCount = 10 * this.zoom.value;
    const tickInterval = this.wWidth / tickCount;
    this.liner.width = tickInterval
    this.liner.times = []
    for (let i = 0; i < tickCount; i++) {
      const time = (i / tickCount) * this.duration;
      this.liner.times.push(time)
    }

    console.log("this.liner", this.liner)
  }

  onSearchEmoloyees(resp) {
    if (!this.employees) {
      return;
    }

    if (!resp) {
      this.employees$.next(this.employees.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.employees$.next(
      this.employees.filter(z => z.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  onSearchOperations(resp) {
    if (!this.operations) {
      return;
    }

    if (!resp) {
      this.operations$.next(this.operations.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.operations$.next(
      this.operations.filter(z => (z.name.toLowerCase().indexOf(resp) > -1))
    );
  }

  getEmployees() {
    this.attachSubscriptions(
      this.taskService.getEmployees(this.data.company.id).subscribe(resp => {
        console.log('getEmployees', resp)
        this.employees = resp;
        this.employees$.next(this.employees.slice())
      })
    )
  }

  getOperations() {
    this.attachSubscriptions(
      this.taskService.getOperations(this.data.company.id).subscribe(resp => {
        this.operations = resp;
        this.operations$.next(this.operations.slice())
      })
    )
  }

  getOperationsStatus() {
    this.attachSubscriptions(
      this.taskService.getOperationsStatus().subscribe(resp => {
        this.statuses = resp.slice();
      })
    )
  }
  


  getTaskTemplates() {
    this.attachSubscriptions(
      this.scenariosService.getTaskTemplates('1', {company_id: this.data.company.id}, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.scenariosService.getTaskTemplates(x, {company_id: this.data.company.id}, '200').pipe(map(u => u.body)))).pipe(
            last(),
            tap(values => {
              this.taskTemplates = [].concat(...values)
              this.taskTemplates.unshift({
                id: '',
                name: "Not set"
              })
              console.log("taskTemplates", this.taskTemplates)
            }),
          )
        }),
      ).subscribe(resp => {
        console.log("getTaskTemplates sub", resp);
      })
    )
  }

  getTaskStatus() {
    this.attachSubscriptions(
      this.taskService.getTaskStatuses(this.data.company_id).subscribe(resp => {
        console.log("getTaskStatuses", resp)
        this.taskStatuses = resp;
        this.taskStatuses$.next(this.taskStatuses.slice());
      })
    )
  }

  onSearchTaskStatuses(resp) {
    if (!this.taskStatuses) {
      return;
    }

    if (!resp) {
      this.taskStatuses$.next(this.taskStatuses.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.taskStatuses$.next(
      this.taskStatuses.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  // getStatuses() {
  //   this.attachSubscriptions(
  //     this.taskService.getTaskStatuses(this.data.company_id).subscribe(resp => {
  //       console.log("getTaskStatuses", resp)
  //       this.taskStatuses = resp;
  //       this.taskStatuses$.next(this.taskStatuses.slice())
  //     })
  //   )
  // }

  getGroups() {
    this.attachSubscriptions(
      this.companyService.getInfiniteGroupsCompany(this.data.company_id, '1', '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.companyService.getInfiniteGroupsCompany(this.data.company_id, x).pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        this.groups = [].concat(...res);
        this.groups.unshift({
          id: '',
          name: "Not set"
        });
        this.groups$.next(this.groups.slice())
        console.log("this.groups", this.groups)
      })
    )
  }

  getOperationById(id) {
    if (!this.operations || this.operations.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.operations.find(el => el.id == id)
  }

  getGroupById(id) {
    if (!this.groups || this.groups.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.groups.find(el => el.id == id)
  }

  getJobStatusById(id) {
    if (!this.statuses || this.statuses.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.statuses.find(el => el.id == id)
  }

  getStatusById(id) {
    if (!this.taskStatuses || this.taskStatuses.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.taskStatuses.find(el => el.id == id)
  }

  onSearchGroups(resp) {
    if (!this.groups) {
      return;
    }

    if (!resp) {
      this.groups$.next(this.groups.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.groups$.next(
      this.groups.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  onChangeCutType(cut) {
    cut.is_custom.patchValue(true)
  }

  getCircularReplacer() {
    const ancestors:any = [];
    return function (key, value) {
      if (typeof value !== "object" || value === null) {
        return value;
      }
      // `this` is the object that value is contained in,
      // i.e., its direct parent.
      while (ancestors.length > 0 && ancestors.at(-1) !== this) {
        ancestors.pop();
      }
      if (ancestors.includes(value)) {
        return "[Circular]";
      }
      ancestors.push(value);
      return value;
    };
  }

  logCuts() {
    console.log("tracks", this.tracks);
    let target = this.getTarget();
    console.log("selectedFragments", this.selectedFragments.selected)
    console.log("target", target)
    console.log("project", this.project);
    console.log("vidProject", this.vidProject);
    console.log("files", this.files);
    console.log("publications", this.publications);

    this.tracks.forEach((trackEl, trackInd) => {
      console.log("trackEl - " + trackInd, trackEl)
      trackEl.parts.forEach((part, partInd) => {
        console.log("part - " + partInd, part)
        console.log("part obj", {
          from: part.from,
          to: part.to,
          prevAllDuration: part.prevAllDuration,
          duration: part.duration,
        });
        console.log("part rd", part.rd);
      });
    })
    // console.log("cuts", this.cuts);
    // console.log("parameters", this.parameters);
    // console.log("jobParameters", this.jobParameters);
    console.log("form", this.form.value);
    console.log("jobs", this.jobs);
    // console.log("file", this.data.file);
  }

  editJob(job, cut?) {
    const dialogRef = this.dialog.open(WorkAddComponent, {
      disableClose: true,
      data: {
        user: this.data.user,
        company: this.data.company,
        company_id: this.data.company_id,
        is_ve: true,
        is_edit_ve: true,
        task: this.data.task,
        job: job
      }
    });
  
    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        
        console.log("RESULT", result)
        if (!!result && result.event == "Add") {
          let jobData:any = {
            operation_id: result.data.form.operation_id,
            status_id: result.data.form.status_id,
            priority: result.data.form.priority,
            name: result.data.form.name,
            comment: result.data.form.comment,
            create_task_employees: [],
            create_task_partners: [],
            create_automation_scenarios: [],
            create_parameter_values_to_task: [],
            parameters: this.jobParameters.slice(),
            result: result
          }
  
          if (result.data.additional.employee_id) {
            result.data.additional.employee_id.forEach(id => {
              if (id) {
                jobData.create_task_employees.push({
                  employee_id: id,
                  is_manager: 0
                })
              }
            })
          }
          if (result.data.templateForm.template_id) {
            result.data.templateForm.template_id.forEach(id => {
              jobData.create_automation_scenarios.push(id)
            })
          }
          if (result.data.workEmpl.employee_id) {
            jobData.create_task_employees.push({
              employee_id: result.data.workEmpl.employee_id,
              is_manager: 1
            })
          }
          if (result.data.partnerForm.partner_company_id) {
            jobData.create_task_partners.push({
              partner_company_id: result.data.partnerForm.partner_company_id,
              is_manager: 1
            })
          }

          jobData.mobEmployees = []
          let empl = JSON.parse(JSON.stringify(jobData.create_task_employees))
          let pEmpl = JSON.parse(JSON.stringify(jobData.create_task_partners))
          empl.map(x => x.employee = this.getEmployeeById(x.employee_id))
          pEmpl.map(x => {
            x.is_partner = true;
            x.partnerCompany = this.getPartner(x.partner_company_id).partnerCompany;
          })
          jobData.mobEmployees.push(...empl, ...pEmpl)
          
          if (cut) {
            cut.is_custom.patchValue(true)
            cut.jobs.splice(cut.jobs.indexOf(job), 1, jobData)
            // this.saveVideoEditorHistory();
          } else {
            this.jobs.splice(this.jobs.indexOf(job), 1, jobData)
            this.updateJobs()
          }
        }
      })
    )
  }

  onPartnerImgError(event){
    event.target.src = this.imgRoute+'/assets/img/partner.png'
  }

  addJob(cut?) {
    const dialogRef = this.dialog.open(WorkAddComponent, {
      disableClose: true,
      data: {
        user: this.data.user,
        company: this.data.company,
        company_id: this.data.company_id,
        is_ve: true,
        task: this.data.task
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        
        console.log("RESULT", result)
        if (!!result && result.event == "Add") {
          let jobData:any = {
            operation_id: result.data.form.operation_id,
            status_id: result.data.form.status_id,
            priority: result.data.form.priority,
            name: result.data.form.name,
            comment: result.data.form.comment,
            create_task_employees: [],
            create_task_partners: [],
            create_automation_scenarios: [],
            create_parameter_values_to_task: [],
            parameters: this.jobParameters.slice(),
            result: result
          }

          if (result.data.additional.employee_id) {
            result.data.additional.employee_id.forEach(id => {
              if (id) {
                jobData.create_task_employees.push({
                  employee_id: id,
                  is_manager: 0
                })
              }
            })
          }
          if (result.data.templateForm.template_id) {
            result.data.templateForm.template_id.forEach(id => {
              jobData.create_automation_scenarios.push(id)
            })
          }
          if (result.data.workEmpl.employee_id) {
            jobData.create_task_employees.push({
              employee_id: result.data.workEmpl.employee_id,
              is_manager: 1
            })
          }
          if (result.data.partnerForm.partner_company_id) {
            jobData.create_task_partners.push({
              partner_company_id: result.data.partnerForm.partner_company_id,
              is_manager: 1
            })
          }

          jobData.mobEmployees = []
          let empl = JSON.parse(JSON.stringify(jobData.create_task_employees))
          let pEmpl = JSON.parse(JSON.stringify(jobData.create_task_partners))
          empl.map(x => x.employee = this.getEmployeeById(x.employee_id))
          pEmpl.map(x => {
            x.is_partner = true;
            x.partnerCompany = this.getPartner(x.partner_company_id).partnerCompany;
          })
          jobData.mobEmployees.push(...empl, ...pEmpl)
          
          if (cut) {
            if (!cut.hasOwnProperty('jobs')) {
              cut.jobs = []
            }
            cut.is_custom.patchValue(true)
            cut.jobs.push(jobData)
          } else {
            this.jobs.push(jobData)
            this.updateJobs()
          }
        }
      })
    )
  }

  addJob2(cut?) {
    let jobData:any = {
      operation_id: 1,
      status_id: 1,
      priority: 0,
      name: '',
      comment: '',
      template_id: '',
      create_task_employees: [],
      parameters: this.jobParameters.slice()
    }

    if (this.employees.length > 0) {
      jobData.newExecutor = {
        employee_id: '',
        is_manager: 1
      }
    }

    if (cut) {
      if (!cut.hasOwnProperty('jobs')) {
        cut.jobs = []
      }
      cut.is_custom.patchValue(true)
      cut.jobs.push(jobData)
    } else {
      this.jobs.push(jobData)
      this.updateJobs()
    }

  }

  updateJobs() {
    let parts = (this.tracks.filter(x => x.type == 'video').map(k => k.parts) as any).flat(Infinity)
    parts.filter(cut => !cut.is_custom.value).forEach(cut => {
      // let arr = []
      // this.jobs.forEach(el => {
      //   let x:any = {}
      //   Object.keys(el).forEach(key => {
      //     // if (key == 'parameters') {
      //     //   x[key] = [...el[key]]
      //     // } else {
      //     // }
      //     x[key] = JSON.parse(JSON.stringify(el[key], this.getCircularReplacer()))
      //   })
      //   arr.push(x)
      // });
      // cut.jobs = [...arr];
      cut.jobs = JSON.parse(JSON.stringify(this.jobs, this.getCircularReplacer()))
    })
    // this.saveVideoEditorHistory();
    console.log("updateJobs", parts);
    
  }

  deleteJob(i, cut?) {
    if (cut) {
      cut.is_custom.patchValue(true)
      cut.jobs.splice(i,1);
    } else {
      this.jobs.splice(i,1);
      this.updateJobs();
    }
  }

  checkIfDisabled(job, execId) {
    if (job.create_task_employees.filter(x => x.employee_id == execId).length > 0) {
      return true
    } else {
      return false
    }
  }

  changeMode(val) {
    if (val == 'view' && this.currentTime != this.currentCutTimeProject && !!this.currentCutTimeProject) {
      this.changeTime(this.currentTimeProject)
    }

    if (val == 'cut') {
      if (this.activeItem) {
        this.activeItem.is_open = false;
      }
      this.activeItem = undefined;
    } else {
      this.currentCutTimeProject = 0
    }

    this.mode = val
  }

  addCut(val, trackEl, trackElIndex, part, partIndex) {
    console.log("ADD CUT")

    if (!this.checkIfCanAdd()) {
      return
    }

    
    if (part.is_combine) {

      let savedVal = +JSON.parse(JSON.stringify(val))
      let newVal;
  
      this.projectHasParts = true;
  
      newVal = (savedVal - +part.prevAllDuration)*part.part_speed_rate + +part.from

      let blendIndex:number = 0;
      part.blends.forEach((blend, k) => {
        if (val >= blend.prevAllDuration && val <= blend.prevAllDuration + blend.duration) {
          blendIndex = k
        }
      })
      
      let blend = part.blends[blendIndex]

      // part + combine
      if (blendIndex == 0 || blendIndex == part.blends.length - 1) {
        if (blendIndex == 0) {
          let newPart = JSON.parse(JSON.stringify(blend, this.getCircularReplacer()))

          let fileStorage;
          if (this.files.video.find(x => x.id == newPart.id)) {
            fileStorage = this.files.video.find(x => x.id == newPart.id)
          }
          if (this.files.audio.find(x => x.id == newPart.id)) {
            fileStorage = this.files.audio.find(x => x.id == newPart.id)
          }
          console.log("fileStorage", fileStorage)
          if (!!fileStorage && !!fileStorage.blob) {
            let newSrc = URL.createObjectURL(fileStorage.blob);
            newPart.blobSrc = this.sanitizer.bypassSecurityTrustResourceUrl(newSrc);
          } else {
            this.fileService.projectFiles$.next(fileStorage)
          }
          
          newPart.is_audio_only = blend.is_audio_only;
          newPart.is_video_only = blend.is_video_only;
          newPart.crop_on = false;
          newPart.parameters = JSON.parse(JSON.stringify(this.parameters, this.getCircularReplacer()));
          newPart.is_custom = new FormControl(blend.is_custom.value);
          newPart.to = newVal;
          newPart.pId = this.generateRandomId();
          this.setFormValueAsHeadForm(newPart)

          part.from = newVal;
          newPart.duration = (newPart.to - newPart.from)* (1/part.part_speed_rate);
          part.duration = part.duration - newPart.duration

          part.prevAllDuration = val;
          blend.from = newVal;
          blend.duration = (blend.to - blend.from)* (1/part.part_speed_rate);

          trackEl.parts.splice(partIndex, 0, newPart);
          this.refreshTracks()
        } else {
          
          let newBlendVal = (savedVal - +blend.prevAllDuration)*blend.part_speed_rate + +blend.from;
          let newPart = JSON.parse(JSON.stringify(blend, this.getCircularReplacer()));

          let fileStorage;
          if (this.files.video.find(x => x.id == newPart.id)) {
            fileStorage = this.files.video.find(x => x.id == newPart.id)
          }
          if (this.files.audio.find(x => x.id == newPart.id)) {
            fileStorage = this.files.audio.find(x => x.id == newPart.id)
          }
          console.log("fileStorage", fileStorage)
          if (!!fileStorage && !!fileStorage.blob) {
            let newSrc = URL.createObjectURL(fileStorage.blob);
            newPart.blobSrc = this.sanitizer.bypassSecurityTrustResourceUrl(newSrc);
          } else {
            this.fileService.projectFiles$.next(fileStorage)
          }
          
          newPart.is_audio_only = blend.is_audio_only;
          newPart.is_video_only = blend.is_video_only;
          newPart.crop_on = false;
          newPart.parameters = JSON.parse(JSON.stringify(this.parameters, this.getCircularReplacer()));
          newPart.is_custom = new FormControl(blend.is_custom.value);
          newPart.from = newBlendVal;
          newPart.pId = this.generateRandomId();
          this.setFormValueAsHeadForm(newPart)

          part.to = newBlendVal;
          newPart.duration = (newPart.to - newPart.from)* (1/part.part_speed_rate);
          part.duration = part.duration - newPart.duration

          newPart.prevAllDuration = val;
          blend.to = newBlendVal;
          blend.duration = (blend.to - blend.from)* (1/part.part_speed_rate);

          // trackEl.parts.splice(partIndex, 0, newPart);
          trackEl.parts.splice(partIndex + 1, 0, newPart);
          this.refreshTracks()
        }
      // combine + combine
      } else {
        // trackEl.parts.splice(partIndex + 1, 0, newPart);
        let combine = JSON.parse(JSON.stringify(part, this.getCircularReplacer()));
        combine.pId = this.generateRandomId();
        let newBlendVal = (savedVal - +blend.prevAllDuration)*blend.part_speed_rate + +blend.from;
        let newPart = JSON.parse(JSON.stringify(blend, this.getCircularReplacer()));
        
        newPart.is_audio_only = part.is_audio_only;
        newPart.is_video_only = part.is_video_only;
        newPart.crop_on = false;
        newPart.parameters = JSON.parse(JSON.stringify(this.parameters, this.getCircularReplacer()));
        newPart.is_custom = new FormControl(blend.is_custom.value);
        newPart.from = newBlendVal;
        newPart.pId = this.generateRandomId();
        this.setFormValueAsHeadForm(newPart)

        part.to = newBlendVal;
        newPart.duration = (newPart.to - newPart.from)* (1/part.part_speed_rate);

        newPart.prevAllDuration = val;
        blend.to = newBlendVal;
        blend.duration = (blend.to - blend.from)* (1/part.part_speed_rate);

        part.blends = part.blends.slice(0, blendIndex + 1)
        part.duration = part.blends.map(n => n.duration || 0).reduce((acc, number) => +acc + +number, 0);
        part.filename = 'Combine ' + part.blends.length + ' fragments';

        combine.blends = combine.blends.slice(blendIndex + 1)
        combine.blends.unshift(newPart);
        combine.blends.forEach(comBlend => {
          let comBlendStorage;
          if (this.files.video.find(x => x.id == comBlend.id)) {
            comBlendStorage = this.files.video.find(x => x.id == comBlend.id)
          }
          if (this.files.audio.find(x => x.id == comBlend.id)) {
            comBlendStorage = this.files.audio.find(x => x.id == comBlend.id)
          }
          console.log("comBlendStorage", comBlendStorage)
          if (!!comBlendStorage && !!comBlendStorage.blob) {
            let newSrc = URL.createObjectURL(comBlendStorage.blob);
            comBlend.blobSrc = this.sanitizer.bypassSecurityTrustResourceUrl(newSrc);
          } else {
            this.fileService.projectFiles$.next(comBlendStorage)
          }
        });
        combine.from = newPart.from;
        combine.duration = combine.blends.map(n => n.duration || 0).reduce((acc, number) => +acc + +number, 0);
        combine.filename = 'Combine ' + combine.blends.length + ' fragments';
        // trackEl.parts.splice(partIndex, 0, newPart);
        trackEl.parts.splice(partIndex + 1, 0, combine);
        this.refreshTracks()
      }

    } else {
      let newVal = +JSON.parse(JSON.stringify(val))
  
      this.projectHasParts = true;
  
      newVal = (newVal - +part.prevAllDuration)*part.part_speed_rate + +part.from
  
      console.log("addCut newVal", newVal)
      console.log("addCut val", val)
  
      let newPart = JSON.parse(JSON.stringify(part, this.getCircularReplacer()))
      if (newPart.trackElIndex == 0) {
        let fileStorage;
        if (this.files.video.find(x => x.id == newPart.id)) {
          fileStorage = this.files.video.find(x => x.id == newPart.id)
        }
        if (this.files.audio.find(x => x.id == newPart.id)) {
          fileStorage = this.files.audio.find(x => x.id == newPart.id)
        }
        console.log("fileStorage", fileStorage)
        if (!!fileStorage && !!fileStorage.blob) {
          let newSrc = URL.createObjectURL(fileStorage.blob);
          newPart.blobSrc = this.sanitizer.bypassSecurityTrustResourceUrl(newSrc);
        } else {
          this.fileService.projectFiles$.next(fileStorage)
        }
      }
      newPart.is_audio_only = part.is_audio_only;
      newPart.is_video_only = part.is_video_only;
      newPart.crop_on = false;
      newPart.parameters = JSON.parse(JSON.stringify(this.parameters, this.getCircularReplacer()));
      newPart.is_custom = new FormControl(false);
  
      newPart.from = newVal;
      newPart.to = part.to || part.duration;
      newPart.pId = this.generateRandomId();
      this.setFormValueAsHeadForm(newPart);
      console.log("newPart to 1", newPart.to);

      
  
      // part.from = part.from || 0;
      part.to = newVal;
      part.duration = (part.to - part.from)* (1/part.part_speed_rate);
  
      console.log("newPart to 2", newPart.to)
  
      // let allPartsDuration = trackEl.parts.map(n => n.duration || 0).reduce((acc, number) => +acc + +number, 0);
      
      newPart.duration = (newPart.to - newPart.from)* (1/part.part_speed_rate);
  
      newPart.prevAllDuration = part.prevAllDuration + part.duration
  
      newPart.saveFTD = {
        from: 0,
        to: newPart.saveDuration,
        duration: newPart.saveDuration,
        part_speed_rate: newPart.part_speed_rate,
        prevAllDuration: newPart.prevAllDuration
      }
  
      part.saveFTD = {
        from: 0,
        to: part.saveDuration,
        duration: part.saveDuration,
        part_speed_rate: part.part_speed_rate,
        prevAllDuration: part.prevAllDuration
      }
  
      trackEl.parts.splice(partIndex + 1, 0, newPart);
      this.setFormValueAsHeadForm(part)
  
      console.log("partIndex", partIndex);
      console.log("part", part);
      console.log("part obj", {
        from: part.from,
        to: part.to,
        prevAllDuration: part.prevAllDuration,
        duration: part.duration,
      });
  
      console.log("newPart obj", {
        from: newPart.from,
        to: newPart.to,
        prevAllDuration: newPart.prevAllDuration,
        duration: newPart.duration,
      })
  
    }
    this.saveVideoEditorHistory("Add Cut");
  }

  setFormValueAsHeadForm(cut:any, initValueChanges:boolean = true, isPatch:boolean = false) {
    console.log("cut", cut)
    if (!cut.is_custom || !!cut.is_custom.value) {
      return
    }
    if (isPatch && cut.form) {
      cut.form.patchValue({
        name: this.form.value.type == 0 ? ((cut.form && cut.form.value.name) ? cut.form.value.name : '') : this.form.value.name,
        type: this.form.value.type,
        group_id: this.form.value.group_id,
        status_id: this.form.value.status_id,
        create_parameter_values_to_task: this.form.value.create_parameter_values_to_task,
        template_id: this.form.value.template_id
      })
    } else {
      cut.form = this.fb.group({
        name: (cut.form && cut.form.value.name) ? cut.form.value.name : '',
        type: this.form.value.type,
        group_id: this.form.value.group_id,
        status_id: this.form.value.status_id,
        create_parameter_values_to_task: this.form.value.create_parameter_values_to_task,
        template_id: this.form.value.template_id,
      })
    }
    cut.parameters = JSON.parse(JSON.stringify(this.parameters, this.getCircularReplacer()));

    if (initValueChanges) {
      if (!cut.subscr) {
        cut.subscr = cut.is_custom.valueChanges.subscribe(res => {
          console.log('is_custom', cut, res)
          if (!res) {
            console.log('is_custom2', cut, res)
            this.setFormValueAsHeadForm(cut, false)
            cut.jobs = JSON.parse(JSON.stringify(this.jobs, this.getCircularReplacer()))
          }
        })
      }
    }
  }

  getPartners() {
    this.attachSubscriptions(
      this.companyService.getPartners({company_id: this.data.company.id}).subscribe(resp => {
        this.partners = resp.filter(x => x.partner_company_id != 0);
        console.log("getPartners", this.partners)
      })
    )
  }

  getPartner(id) {
    if (!this.partners) {
      return false;
    }
    return this.partners.find(el => el.partner_company_id == id)
  }  

  getEmployeeById(id) {
    return this.employees.find(x => x.id == id)
  }

  selectExec(job, cut, employee) {
    job.newExecutor.employee_id = employee.id; 
    this.onChangeCutType(cut)
  }

  addExecutor(job, cut?) {
    if (!job.newExecutor.employee_id) {
      this.layoutService.showSnackBar({name: ''}, marker("Choose an executor"), SnackBarItem)
      return;
    }
    job.create_task_employees.push(job.newExecutor);
    job.newExecutor = {
      employee_id: '',
      is_manager: 1
    }

    if (cut) {
      this.onChangeCutType(cut)
    } else {
      this.updateJobs();
    }
    
  }

  deleteExecutor(job, ind, cut?) {
    job.create_task_employees.splice(ind,1);

    if (cut) {
      cut.is_custom.patchValue(true)
    } else {
      this.updateJobs();
    }
  }

  onRemoveCut(cut, t) {
    cut.tags.splice(t,1);
  }

  onRemove(tagID, ind, cut?, job?) {
    if (job) {
      if (cut) {
        cut.is_custom.patchValue(true)
        job.parameters[0].activeValues.splice(ind, 1)
      } else {
        job.parameters[0].activeValues.splice(ind, 1)
        this.updateJobs();
      }
      // this.saveVideoEditorHistory();
    } else {
      if (cut) {
        cut.is_custom.patchValue(true)
        cut.parameters[0].activeValues.splice(ind, 1)
        cut.form.patchValue({
          create_parameter_values_to_task: cut.parameters[0].activeValues.map(x => x.id)
        })
        // this.saveVideoEditorHistory();
      } else {
        this.parameters[0].activeValues.splice(ind, 1)
        this.form.patchValue({
          create_parameter_values_to_task: this.parameters[0].activeValues.map(x => x.id)
        })
      }
    }
  }

  hasValue() {
    if (!this.hasParts()) {
      return false
    }

    let parts = (this.tracks.filter(x => x.type == 'video').map(k => k.parts) as any).flat(Infinity)

    return !!parts.filter(x => !!x.is_audio_only).length
  }

  checkboxLabel(cut?): string {
    if (!cut) {
      return `${this.isAllSelected() ? 'Deselect' : 'Select'} all`;
    }
    return `${(!!cut.is_audio_only) ? 'Deselect' : 'Select'} ${cut.name}`;
  }

  isAllSelected() {
    let parts = (this.tracks.filter(x => x.type == 'video').map(k => k.parts) as any).flat(Infinity)
    const numSelected = parts.filter(x => !!x.is_audio_only).length;
    const numRows = parts.length;
    return numSelected === numRows;
  }

  masterToggle() {
    let parts = (this.tracks.filter(x => x.type == 'video').map(k => k.parts) as any).flat(Infinity)
    this.isAllSelected() ?
        parts.map(x => x.is_audio_only = 0):
        parts.map(x => x.is_audio_only = 1);
  }

  hasValueVid() {
    if (!this.hasParts()) {
      return false
    }

    let parts = (this.tracks.filter(x => x.type == 'video').map(k => k.parts) as any).flat(Infinity)

    return !!parts.filter(x => !!x.is_video_only).length
  }

  checkboxLabelVid(cut?): string {
    if (!cut) {
      return `${this.isAllSelected() ? 'Deselect' : 'Select'} all`;
    }
    return `${(!!cut.is_video_only) ? 'Deselect' : 'Select'} ${cut.name}`;
  }

  isAllSelectedVid() {
    let parts = (this.tracks.filter(x => x.type == 'video').map(k => k.parts) as any).flat(Infinity)
    const numSelected = parts.filter(x => !!x.is_video_only).length;
    const numRows = parts.length;
    return numSelected === numRows;
  }

  masterToggleVid() {
    let parts = (this.tracks.filter(x => x.type == 'video').map(k => k.parts) as any).flat(Infinity)
    this.isAllSelectedVid() ?
        parts.map(x => x.is_video_only = 0):
        parts.map(x => x.is_video_only = 1);
  }

  
  // updatePartsNames() {
  //   let parts = (this.tracks.filter(x => x.type == 'video').map(k => k.parts) as any).flat(Infinity)
  //   parts.forEach((element, i) => {
  //     if (parts.filter(u => u.filename.indexOf(element.filename) != -1).length > 1) {
  //       parts.filter(u => u.filename.indexOf(element.filename) != -1).forEach((x,k) => {
  //         x.filename = x.filename.substring(0, +x.filename.indexOf("-cut-") + 5) + (k+1)
  //       })
  //     }
  //   });
  // }

  canCombine() {
    let parts = (this.tracks.filter(x => x.type == 'video').map(k => k.parts) as any).flat(Infinity);
    let indexes = [];
    parts.forEach((part, partIndex) => {
      if (this.selectedFragments.isSelected(part)) {
        indexes.push(partIndex)
      }
    })
    indexes.sort();
    let maxMinusRes = 0;

    indexes.forEach((ind,i) => {
      if (i != 0) {
        maxMinusRes = Math.max(ind - indexes[i-1], maxMinusRes)
      }
    }) 

    return this.selectedFragments.selected.length > 1 && this.selectedFragments.selected.filter(x => x.trackElIndex != 0).length == 0 && maxMinusRes == 1
  }

  changeVidOnly() {
    if (!!this.activeItem.is_video_only) {
      this.activeItem.is_video_only = 0;
    } else {
      this.activeItem.is_video_only = 1;
    }
    if (this.activeItem.is_combine) {
      this.activeItem.blends.forEach(blend => {
        blend.is_video_only = this.activeItem.is_video_only
      });
    }
  }

  saveStyleAsBook(e, actIt) {
    e.preventDefault();

    const dialogRef = this.dialog.open(SaveStyleComponent, {
      hasBackdrop: true,
      data: {
        company: this.data.company,
        company_id: this.data.company_id,
        activeItem: actIt,
        scheme: this.activeStyle
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        
        if (!!result && (result.event == 'save' || result.event == 'edit')) {
          this.getStyles();
          this.activeItem = actIt;
          this.activeStyle = undefined;
        }
      })
    )
  }

  getStyles() {
    this.attachSubscriptions(
      this.companyService.getBookmarks(this.data.company_id, {employee_id: this.data.company.employees[0].id, section: "Video editor", type: "Captions style"}).subscribe(res => {
        this.styles = res;

        this.styles.unshift({
          id: -1,
          name: "Karaoke one word",
          settings: {
            style: {
              activeColor: "rgba(255, 255, 0, 1)",
              bold: 1,
              fontSize: 72,
              futureColor: "rgba(128, 128, 128, 0)",
              is_karaoke: true,
              italic: 0,
              outline: 0,
              outlineColor: "rgba(0,0,0,1)",
              pastColor: "rgba(255, 255, 255, 0)",
              strike: 0,
              underline: 0
            }
          }
        })
        this.styles.unshift({
          id: -1,
          name: "Karaoke with active word",
          settings: {
            style: {
              activeColor: "rgba(255, 255, 0, 1)",
              bold: 1,
              fontSize: 72,
              futureColor: "rgba(128, 128, 128, 0.72)",
              is_karaoke: true,
              italic: 0,
              outline: 2,
              outlineColor: "rgba(0,0,0,1)",
              pastColor: "rgba(255, 255, 255, 1)",
              strike: 0,
              underline: 0
            }
          }
        })
        this.styles.unshift({
          id: -1,
          name: "Karaoke Default",
          settings: {
            style: {
              activeColor: "rgba(255, 255, 255, 1)",
              bold: 1,
              fontSize: 72,
              futureColor: "rgba(128, 128, 128, 0.72)",
              is_karaoke: true,
              italic: 0,
              outline: 2,
              outlineColor: "rgba(0,0,0,1)",
              pastColor: "rgba(255, 255, 255, 1)",
              strike: 0,
              underline: 0
            }
          }
        })
        this.styles.unshift({
          id: -1,
          name: "Default",
          settings: {
            style: {
              activeColor: "rgba(255, 255, 255, 1)",
              bold: 0,
              fontSize: 72,
              futureColor: "rgba(128, 128, 128, 0.72)",
              is_karaoke: false,
              italic: 0,
              outline: 0,
              outlineColor: "rgba(0,0,0,1)",
              pastColor: "rgba(255, 255, 255, 1)",
              strike: 0,
              underline: 0
            }
          }
        })
        console.log("getStyles", this.styles);
      })
    )
  }

  setStyle(e, style, is_edit?) {
    console.log("setStyle", style)
    e.preventDefault();
    e.stopPropagation();
    this.activeItem.subSet.fontSize = style.settings.style.fontSize;
    this.activeItem.subSet.outline = style.settings.style.outline;
    this.activeItem.subSet.activeColor = style.settings.style.activeColor;
    this.activeItem.subSet.futureColor = style.settings.style.futureColor;
    this.activeItem.subSet.pastColor = style.settings.style.pastColor;
    this.activeItem.subSet.outlineColor = style.settings.style.outlineColor;
    this.activeItem.subSet.bold = style.settings.style.bold;
    this.activeItem.subSet.italic = style.settings.style.italic;
    this.activeItem.subSet.underline = style.settings.style.underline;
    this.activeItem.subSet.strike = style.settings.style.strike;
    this.activeItem.subSet.is_karaoke = style.settings.style.is_karaoke;

    if (!!is_edit) {
      this.activeStyle = style
    }

    this.is_saved_styles = !this.is_saved_styles;
  }

  savedStyles(e) {
    console.log("savedStyles", e);
    e.preventDefault();
    e.stopPropagation();
    this.is_saved_styles = !this.is_saved_styles;
  }

  changeBlurBg() {
    if (!!this.activeItem.has_blur_bg) {
      this.activeItem.has_blur_bg = 0;
    } else {
      this.activeItem.has_blur_bg = 1;
    }
    if (this.activeItem.is_combine) {
      this.activeItem.blends.forEach(blend => {
        blend.has_blur_bg = this.activeItem.has_blur_bg
      });
    }
    
    this.saveVideoEditorHistory("Blured Background");
  }

  logNewText(val) {
    console.log("logNewText", val)
  }

  // onChangeNewWordTime(e, newObj, word, nwInd) {

  // }

  // onChangeNewWord(e, newObj, word, nwInd) {
  //   if (e.target.value == '') {

  //   } else {

  //   }
  // }

  onAddNewText(e, newObj) {
    console.log("onAddNewText", newObj)
    if (!newObj.from && newObj.from.toString() != '0') {
      this.layoutService.showSnackBar({name: ''}, marker('Add subtitle start time'), SnackBarItem);
      return;
    }
    if (!newObj.to) {
      this.layoutService.showSnackBar({name: ''}, marker('Add subtitle end time'), SnackBarItem);
      return;
    }
    if (newObj.text == '') {
      newObj.words = [];
      this.layoutService.showSnackBar({name: ''}, marker('Cannot add empty subtitle'), SnackBarItem)
      return 
    } else if (newObj.text.indexOf(' ') != -1) {
      let newWords = newObj.text.split(' ');
      console.log(newWords);
      let duration = newObj.to - newObj.from;
      let newWordsArray = [];
      let partDur = duration/newWords.length.toFixed(3);

      newWords.forEach((newWord, ind) => {
        let from;
        let to;

        from = +newObj.from + +(partDur*ind).toFixed(3)

        if (ind == newWords.length - 1) {
          to = +newObj.to
        } else {
          to = +newObj.from + +(partDur*(ind+1)).toFixed(3)
        }

        newWordsArray.push({
          confidence: 0.99,
          from: from,
          ts: from,
          to: to,
          end_ts: to,
          is_edited: false,
          type: "text",
          value: newWord
        })
      });


      newObj.words = newWordsArray;
    } else {
      let newWords = [e.target.value];
      console.log(newWords);
      let newWordsArray = [];

      newWords.forEach((newWord, ind) => {
        let from = newObj.from;
        let to = newObj.to;
        newWordsArray.push({
          confidence: 0.99,
          from: from,
          ts: from,
          to: to,
          end_ts: to,
          is_edited: false,
          type: "text",
          value: newWord
        })
      });


      newObj.words = newWordsArray;
    }
    newObj.text = newObj.words.map(x => x.value).join(' ');
    
    this.addNewText({...newObj});

    this.closeTextAdding(e);
    this.clearNewText();
  }

  addNewText(obj) {
    if (this.activeItem.subs && this.activeItem.subs.length) {
      let findedText = this.activeItem.subs.filter(k => k.to < obj.from)[this.activeItem.subs.filter(k => k.to < obj.from).length - 1].text;
      this.activeItem.subs.splice(this.activeItem.subs.findIndex(p => p.text == findedText) + 1, 0, obj);
    } else {
      if (this.activeItem.subs) {
        this.activeItem.subs.push(obj)
      } else {
        this.activeItem.subs = [obj]
      }
    }
  }

  clearNewText() {
    this.activeItem.subSet.newText = {
      text: '',
      from: 0,
      to: 0,
      words: []
    }
  }

  openTextAdding(e) {
    e.preventDefault();
    e.stopPropagation();
    this.activeItem.subSet.is_add_text = true;
  }

  closeTextAdding(e) {
    e.preventDefault();
    e.stopPropagation();
    this.activeItem.subSet.is_add_text = false;
  }

  resetVidFilters(item) {
    item.video_filters = {
      brightness: '1',
      contrast: '1',
      saturation: '100',
      hue: '0',
      grayscale: '0', 
    }
  }

  changeVideoFilters() {
    this.activeItem.video_filters_on = !this.activeItem.video_filters_on;
    
    if (true || !this.activeItem.video_filters) {
      this.activeItem.video_filters = {
        brightness: '1',
        contrast: '1',
        saturation: '100',
        hue: '0',
        grayscale: '0', 
      }
    }
  }

  cssContrast(item) {
    return ((item.video_filters.contrast - -1000) * (200 - 0)) / (1000 - -1000) + 0;
  }

  changeSubtitles() {

    let originalFile = this.files.video.find(k => k.id == this.activeItem.id)

    if (this.activeItem && originalFile && originalFile.fileOriginalData && originalFile.fileOriginalData.transcription_srt) {
      console.log("originalFile.fileOriginalData", originalFile.fileOriginalData)
      // console.log("originalFile.fileOriginalData transcription_json", originalFile.fileOriginalData.transcription_json)
      // console.log("JSON originalFile.fileOriginalData", (Object.values(JSON.parse(originalFile.fileOriginalData.transcription_json).monologues).map((x:any) => x.elements) as any).flat(Infinity))
      // this.activeItem.words = (Object.values(JSON.parse(originalFile.fileOriginalData.transcription_json).monologues).map((x:any) => x.elements) as any).flat(Infinity)
      this.activeItem.subs = this.convertSrtToAssArray(originalFile.fileOriginalData.transcription_srt)

      let allWords = (Object.values(JSON.parse(originalFile.fileOriginalData.transcription_json).monologues).map((x:any) => x.elements) as any).flat(Infinity).filter(k => k.type == 'text' || !!k.value.trim());
      console.log("allWords", allWords)
      allWords.forEach((word,i) => {
        if (word.type == 'punct') {
          if (allWords[i-1]) {
            allWords[i-1].value += word.value
          }
        }
      });
      allWords = allWords.filter(k => k.type == 'text');

      allWords.forEach((word, wordInd) => {
        word.to = word.end_ts;
        if (wordInd == 0) {
          word.from = word.ts
        } else {
          word.from = allWords[wordInd-1].end_ts
        }
      });

      this.activeItem.subSet = {
        fontSize: 52,
        outline: 2,
        activeColor: "rgba(255, 255, 0, 1)",
        futureColor: "rgba(128, 128, 128, 1)",
        pastColor: "rgba(255, 255, 255, 1)",
        outlineColor: "rgba(0, 0, 0, 1)",
        bold: 1,
        italic: 0,
        underline: 0,
        strike: 0,
        alignment: 5,
        is_karaoke: false,
        is_edited_text: false,
        is_add_text: false,
        newText: {
          text: '',
          from: 0,
          to: 0,
          words: []
        },
        pos: {
          is_edited: false,
          x: Math.ceil(this.activeItem.rd.width*0.05),
          y: Math.ceil(this.activeItem.rd.height*0.8),
          px: 0,
          py: 0,
          width: Math.ceil(this.activeItem.rd.width*0.9),
          height: 145,
          draggingCorner: false,
          draggingWindow: false
        }
      }

      this.activeItem.subs.forEach(sub => {
        sub.words = allWords.filter(k => k.ts >= sub.from && k.end_ts <= sub.to);
      });
      console.log("this.activeItem", this.activeItem)
      // return
      if (!!this.activeItem.subs_on) {
        this.activeItem.subs_on = 0;
      } else {
        this.activeItem.subs_on = 1;
      }
      if (this.activeItem.is_combine) {
        this.activeItem.blends.forEach(blend => {
          blend.subs_on = this.activeItem.subs_on
        });
      }
      
      this.saveVideoEditorHistory("Subtitles Change");
    } else {
      this.layoutService.showSnackBar({name: ''}, marker('The video has no subtitles loaded.'), SnackBarItem)
    }
  }

  toggleSubFormat(obj, key) {
    obj[key] = obj[key] == 1 ? 0 : 1;

    if (key == 'underline' && !!obj[key]) {
      obj.strike = 0;
    } else if (key == 'strike' && !!obj[key]) {
      obj.underline = 0;
    }
  }

  // Метод для конвертации SRT в массив объектов
  convertSrtToAssArray(srtContent: string): Array<{from: number, to: number, text: string}> {
    const srtLines = srtContent.split('\n\n'); // Разделяем субтитры по двум пустым строкам (блоки)

    const subtitlesArray: Array<{from: number, to: number, text: string}> = [];

    const timePattern = /(\d{2}):(\d{2}):(\d{2}),(\d{3}) --> (\d{2}):(\d{2}):(\d{2}),(\d{3})/;

    srtLines.forEach((srtBlock) => {
      const lines = srtBlock.split('\n');
      
      if (lines.length >= 3) {
        const timeLine = lines[1];
        const text = (lines.slice(2).join(' ') as any).replaceAll('\r', ''); // Соединяем все строки текста

        const match = timePattern.exec(timeLine);
        if (match) {
          const from = this.convertSrtTimeToS(match[1], match[2], match[3], match[4]);
          const to = this.convertSrtTimeToS(match[5], match[6], match[7], match[8]);

          subtitlesArray.push({ from, to, text });
        }
      }
    });

    return subtitlesArray;
  }

  // Метод для конвертации времени из формата SRT в миллисекунды
  convertSrtTimeToS(hours: string, minutes: string, seconds: string, milliseconds: string): number {
    const h = parseInt(hours, 10) * 60 * 60 * 1000;
    const m = parseInt(minutes, 10) * 60 * 1000;
    const s = parseInt(seconds, 10) * 1000;
    const ms = parseInt(milliseconds, 10);
    return +((h + m + s + ms)/1000).toFixed(6); // В данном примере возвращаем миллисекунды в виде строки
  }

  changeAudOnly() {
    if (!!this.activeItem.is_audio_only) {
      this.activeItem.is_audio_only = 0;
    } else {
      this.activeItem.is_audio_only = 1;
    }
    if (this.activeItem.is_combine) {
      this.activeItem.blends.forEach(blend => {
        blend.is_audio_only = this.activeItem.is_audio_only
      });
    }
  }

  unCombineVideos(combine) {
    let parts = this.tracks[0].parts;
    combine.blends.map(x => x.is_blend = false)
    parts.splice(parts.indexOf(combine), 1, ...combine.blends)
  }

  combineVideos() {
    let combine;
    let parts = this.tracks[0].parts;
    let indexes = [];
    this.selectedFragments.selected.sort(function(a, b) {
      return (a.prevAllDuration - b.prevAllDuration);
    })
    
    parts.forEach((part, partIndex) => {
      if (this.selectedFragments.isSelected(part)) {
        if (!combine) {
          combine = JSON.parse(JSON.stringify(part, this.getCircularReplacer()))
          combine.blends = [...this.selectedFragments.selected]
          combine.blends.map(x => x.is_blend = true)
          combine.pId = this.generateRandomId();
          combine.is_combine = true;
          combine.filename = 'Combine ' + this.selectedFragments.selected.length + ' fragments';
          delete combine.blobSrc;
          combine.duration = this.selectedFragments.selected.map(n => n.duration).reduce((acc, number) => +acc + +number, 0);
          combine.saveDuration = this.selectedFragments.selected.map(n => n.saveDuration).reduce((acc, number) => +acc + +number, 0);
          this.setFormValueAsHeadForm(combine)
        }
        indexes.push(partIndex)
      }
    })

    combine.is_video_only = (combine.blends.filter(x => !!x.is_video_only).length > combine.blends.length/2) ? 1 : 0;
    combine.is_audio_only = (combine.blends.filter(x => !!x.is_audio_only).length > combine.blends.length/2) ? 1 : 0;
    combine.is_detached = (combine.blends.filter(x => !!x.is_detached).length == combine.blends.length) ? 1 : 0;

    combine.to = this.selectedFragments.selected[this.selectedFragments.selected.length - 1].to
    if (combine.saveFTD) {
      combine.saveFTD.to = this.selectedFragments.selected[this.selectedFragments.selected.length - 1].saveFTD.to
    } else {
      combine.saveFTD = {
        from: 0,
        to: combine.saveDuration,
        duration: combine.saveDuration,
        part_speed_rate: combine.part_speed_rate,
        prevAllDuration: combine.prevAllDuration
      }
    }

    combine.savedBlends = JSON.parse(JSON.stringify(combine.blends, this.getCircularReplacer()));
    indexes.sort();
    parts.splice(indexes[0], indexes.length, combine)
    console.log(parts);
    console.log("combineVideos logic here")
    this.selectedFragments.clear()
    // все фрагменты удаляю
    // Вместо них добавляю combine fragment
    // длина которого summCombDuration
    // сейв начало = 0
    // сейв конец summCombDuration + разница last dur & last save dur
    
    // в таймлайне это 1 фрагмент
    // combine будет брать форму 1-го 
    
    // в видео ряде разбиваю part на combined[combPart]

    // при ресайзе буду менять начало первого и конец последнего (при этом если будет происходить выход за файл он будет удаляться -- но так что бы был возврат ещё)

    // резать тоже можно (таким образом из combine получиться : 2 combine || 1 part + 1 combine || 1 combine + 1 part)
  }

  activateItem(e, trackEl, trackElIndex, part, partIndex) {
    if (this.data.thumbMode) {
      return
    }

    if (trackElIndex == 0 && (e.ctrlKey || e.metaKey)) {
      this.selectedFragments.toggle(part);
      return
    }

    if (this.mode == 'view') {
      const timelineWidth = this.customTimelineRef.nativeElement.offsetWidth;
      const clickX = e.clientX + this.outContainer.nativeElement.children[0].scrollLeft - 20;

      console.log("vev3 clickX", clickX)
      const newTime = (clickX / timelineWidth) * this.duration;

      if (!part.hasOwnProperty('trackElIndex') || part.trackElIndex != trackElIndex) {
        part.trackElIndex = trackElIndex
      }
      this.activeItem = part
      this.changeTime(newTime)
    } else {
      e.stopPropagation()
      const timelineWidth = this.customTimelineRef.nativeElement.offsetWidth;
      const clickX = e.clientX + this.outContainer.nativeElement.children[0].scrollLeft - 20;

      console.log("vev3 clickX", clickX)
      console.log("vev3 timelineWidth", timelineWidth)
      console.log("vev3 this.duration", this.duration)
      const newTime = (clickX / timelineWidth) * this.duration;
      console.log("vev3 newTime", newTime)
      this.addCut(newTime, trackEl, trackElIndex, part, partIndex)
    }
  }

  selectFile(only) {
    this.isMediaMenuOpen = false;
    let x:any = {
      company: this.data.company,
      task: this.data.task,
      work: this.data.work,
      user: this.data.user,
      only: only,
      is_multi: true,
      fromVE: true,
      selectedFiles: []
    }

    const dialogRef = this.dialog.open(SelectFileComponent, {
      data: x
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log("result", result)
        if (!!result && result.event == 'select' && result.data && result.data.length) {
          // ADDING AUDIOS
          if (only == 'audio') {
            let self = this
            async function loop() {
              for (let i = 0; i < result.data.length; i++) {
                await new Promise((res) => setTimeout(res, i == 0 ? 0 : 1000)); // пауза перед очередным
                let audioFile = result.data[i]
                if (audioFile.task) {
                  delete audioFile.task;
                }
                if (audioFile.taskChannelFiles) {
                  delete audioFile.taskChannelFiles;
                }
                if (audioFile.createdEmployee) {
                  delete audioFile.createdEmployee;
                }
                if (audioFile.meta) {
                  delete audioFile.meta;
                }
                if (audioFile.taskOperation) {
                  delete audioFile.taskOperation;
                }
                audioFile.pId = self.generateRandomId();
                audioFile.myId = self.generateRandomId()
                audioFile.part_speed_rate = 1;
                audioFile.trackElIndex = 1;
                audioFile.is_open = false;
                audioFile.volume = 1;
                self.files.audio.push({...audioFile})
                self.tracks.push({
                  type: 'audio',
                  parts: [{...audioFile}]
                })
                self.fileService.projectFiles$.next(self.files.audio[self.files.audio.length - 1])
                self.getTags();
                self.saveVideoEditorHistory("Add Audio");
              }
            }
            loop();
            // ADDING VIDEOS
          } else {
            let self = this;
            result.data = result.data.filter(u => !!u.preview1080);
            async function loop() {
              for (let i = 0; i < result.data.length; i++) {
                await new Promise((res) => setTimeout(res, i == 0 ? 0 : 1000)); // пауза перед очередным
                let addVideo = result.data[i];
                if (addVideo.task) {
                  delete addVideo.task;
                }
                if (addVideo.taskChannelFiles) {
                  delete addVideo.taskChannelFiles;
                }
                if (addVideo.createdEmployee) {
                  delete addVideo.createdEmployee;
                }
                if (addVideo.meta) {
                  delete addVideo.meta;
                }
                if (addVideo.taskOperation) {
                  delete addVideo.taskOperation;
                }
                addVideo.is_audio_only = 1;
                addVideo.is_video_only = 1;
                addVideo.crop_on = false;
                addVideo.optionsCount = 10;
                addVideo.volume = 1;
                addVideo.is_open = false;
                addVideo.part_speed_rate = 1;
                if (addVideo && addVideo.hasOwnProperty('meta_has_audio') && addVideo.meta_has_audio == 0) {
                  addVideo.is_detached = true;
                  addVideo.is_audio_only = 0;
                }

                addVideo.myId = self.generateRandomId();
                addVideo.pId = self.generateRandomId();
                addVideo.parameters = JSON.parse(JSON.stringify(self.parameters, self.getCircularReplacer()));
                addVideo.is_custom = new FormControl(false);
                addVideo.rd = {
                  deg: 0,
                  mWidth: addVideo.meta_width,
                  mHeight: addVideo.meta_height,
                  ratio: addVideo.meta_width / addVideo.meta_height,
                  params: {}
                }
                addVideo.trackElIndex = 0;
                if (addVideo.rd.ratio <= 1) {
                  addVideo.rd.height = self.project.height;
                  addVideo.rd.width = addVideo.rd.height * addVideo.rd.ratio;
                } else {
                  addVideo.rd.width = self.project.width;
                  addVideo.rd.height = addVideo.rd.width / addVideo.rd.ratio;
                }
                addVideo.rd.percentY = addVideo.rd.height / addVideo.rd.mHeight;
                addVideo.rd.percentX = addVideo.rd.width / addVideo.rd.mWidth;
                addVideo.rd.left = (self.project.width - addVideo.rd.width) / 2
                addVideo.rd.top = (self.project.height - addVideo.rd.height) / 2
                self.calculateSizes(addVideo, self.project)
    
                self.setFormValueAsHeadForm(addVideo)
                self.tracks[0].parts.push({...addVideo})
                self.files.video.push({...addVideo})
                self.fileService.projectFiles$.next(self.files.video[self.files.video.length - 1])
                console.log("addVideo", addVideo)
                self.getTags();
                self.saveVideoEditorHistory("Add Video");
              }
            }
            loop();
          }
        }
      })
    )
  }

  addFileToTimeline(only, arr) {
    if (only == 'audio') {
      let self = this
      async function loop() {
        for (let i = 0; i < arr.length; i++) {
          await new Promise((res) => setTimeout(res, 1000)); // пауза перед очередным
          let audioFile = arr[i]
          audioFile.pId = self.generateRandomId();
          audioFile.myId = self.generateRandomId()
          audioFile.part_speed_rate = 1;
          audioFile.trackElIndex = 1;
          audioFile.is_open = false;
          audioFile.volume = 1;
          self.files.audio.push({...audioFile})
          self.tracks.push({
            type: 'audio',
            parts: [{...audioFile}]
          })
          self.fileService.projectFiles$.next(self.files.audio[self.files.audio.length - 1])
          self.getTags();
          self.saveVideoEditorHistory("Add Audio");
        }
      }
      loop();
      // ADDING VIDEOS
    } else {
      let self = this
      async function loop() {
        for (let i = 0; i < arr.length; i++) {
          await new Promise((res) => setTimeout(res, 1000)); // пауза перед очередным
          let addVideo = arr[i];
          addVideo.is_audio_only = 1;
          addVideo.is_video_only = 1;
          addVideo.crop_on = false;
          addVideo.optionsCount = 10;
          addVideo.volume = 1;
          addVideo.is_open = false;
          addVideo.part_speed_rate = 1;
          if (addVideo && addVideo.hasOwnProperty('meta_has_audio') && addVideo.meta_has_audio == 0) {
            addVideo.is_detached = true;
            addVideo.is_audio_only = 0;
          }

          addVideo.myId = self.generateRandomId();
          addVideo.pId = self.generateRandomId();
          addVideo.parameters = JSON.parse(JSON.stringify(self.parameters, self.getCircularReplacer()));
          addVideo.is_custom = new FormControl(false);
          addVideo.rd = {
            deg: 0,
            mWidth: addVideo.meta_width,
            mHeight: addVideo.meta_height,
            ratio: addVideo.meta_width / addVideo.meta_height,
            params: {}
          }
          addVideo.trackElIndex = 0;
          if (addVideo.rd.ratio <= 1) {
            addVideo.rd.height = self.project.height;
            addVideo.rd.width = addVideo.rd.height * addVideo.rd.ratio;
          } else {
            addVideo.rd.width = self.project.width;
            addVideo.rd.height = addVideo.rd.width / addVideo.rd.ratio;
          }
          addVideo.rd.percentY = addVideo.rd.height / addVideo.rd.mHeight;
          addVideo.rd.percentX = addVideo.rd.width / addVideo.rd.mWidth;
          addVideo.rd.left = (self.project.width - addVideo.rd.width) / 2
          addVideo.rd.top = (self.project.height - addVideo.rd.height) / 2
          self.calculateSizes(addVideo, self.project)

          addVideo.form = self.fb.group({
            name: (addVideo.form && addVideo.form.value.name) ? addVideo.form.value.name : '',
            type: 0,
            group_id: "",
            status_id: 1,
            create_parameter_values_to_task: [[]],
            template_id: ''
          })
          
          addVideo.parameters = [];
          addVideo.subscr = addVideo.is_custom.valueChanges.subscribe(res => {
            console.log('is_custom', addVideo, res)
            if (!res) {
              console.log('is_custom2', addVideo, res)
              self.setFormValueAsHeadForm(addVideo, false)
              addVideo.jobs = JSON.parse(JSON.stringify(self.jobs, self.getCircularReplacer()))
            }
          })
          self.tracks[0].parts.push({...addVideo})
          self.files.video.push({...addVideo})
          self.fileService.projectFiles$.next(self.files.video[self.files.video.length - 1])
          console.log("addVideo", addVideo)
          self.refreshTracks(true, true, self);
          self.getTags();
          self.saveVideoEditorHistory("Add Video");
        }
      }
      loop();
    }
  }

  toggleMediaMenu() {
    this.isMediaMenuOpen = !this.isMediaMenuOpen 
  }

  changeRate(el, val) {
    el.playbackRate = val
  }

  changeProjectSpeed(val) {
    this.projectSpeed = val;
    let videos = document.querySelectorAll('.player')
    let audios:any = document.querySelectorAll('.audioPlayer');
    videos.forEach((vid: HTMLVideoElement) => {
      vid.playbackRate = this.projectSpeed;
    })
    audios.forEach((aud: HTMLAudioElement) => {
      aud.playbackRate = this.projectSpeed;
    })

  }

  changeItemRate(item, val, check_multy:boolean = true) {
    console.log('changeItemRate', item)
    console.log('changeItemRate check_multy', check_multy)
    if (check_multy) {
      console.log('selectedFragments', this.selectedFragments.selected)
      if (this.selectedFragments.selected && this.selectedFragments.selected.length > 0) {
        this.selectedFragments.selected.forEach(x => {
          if (x.pId != item.pId) {
            this.changeItemRate(x, val, false); 
          }
        })
      }
    }
    if (val != 1) {
      this.projectSpeed = 1;
      let videos = document.querySelectorAll('.player')
      let audios:any = document.querySelectorAll('.audioPlayer');
      videos.forEach((vid: HTMLVideoElement) => {
        vid.playbackRate = this.projectSpeed;
      })
      audios.forEach((aud: HTMLAudioElement) => {
        aud.playbackRate = this.projectSpeed;
      })
    }
    if (item && item.is_combine) {
      console.log("changeItemRate", val)
      item.part_speed_rate = val;

      item.blends.forEach(blend => {
        let newDuration = (+blend.to - +blend.from) * (1/val);
        blend.duration = newDuration;
        blend.part_speed_rate = val;
        this.changeRate((document.getElementById(`video_${blend.pId}`) as HTMLVideoElement), blend.part_speed_rate);
      });

      item.duration = item.blends.map(n => n.duration || 0).reduce((acc, number) => +acc + +number, 0);
  
      console.log("changeItemRate curTime", this.currentTimeProject)
      let newTime = (+this.currentTimeProject - item.prevAllDuration)*item.part_speed_rate*(1/val);
      console.log("changeItemRate newTime", newTime)

      // if (item.saveFTD) {
      //   item.saveFTD.duration = (+item.saveFTD.to - +item.saveFTD.from) * (1/val)
      // }
  
      console.log("newTime", newTime);
      if (check_multy) {
        this.changeTime(newTime);
      }
      this.refreshTracks()
    } else {

      console.log("changeItemRate", val)
      let newDuration = (+item.to - +item.from) * (1/val);
  
      console.log("changeItemRate curTime", this.currentTimeProject)
      let newTime = (+this.currentTimeProject - item.prevAllDuration)*item.part_speed_rate*(1/val);
      console.log("changeItemRate newTime", newTime)
      
      item.part_speed_rate = val;
      item.duration = newDuration;
      this.changeRate((document.getElementById(`video_${item.pId}`) as HTMLVideoElement), item.part_speed_rate);
      if (item.saveFTD) {
        item.saveFTD.duration = (+item.saveFTD.to - +item.saveFTD.from) * (1/val)
      }
  
      console.log("newTime", newTime);
      if (check_multy) {
        this.changeTime(newTime);
      }
      this.refreshTracks()
    }
  } 

  deleteCut(track, part) {

    console.log("track", track)
    console.log("part", part)

    track.parts.splice(track.parts.indexOf(part), 1)

    if (this.tracks.filter(x => x.type == 'audio').length == 0) {
      let videos = document.querySelectorAll('.player')
      videos.forEach((vid: HTMLVideoElement, k) => {
        vid.muted = false;
      });
    }
    
    this.projectHasParts = true;
    this.refreshTracks();
  }

  playAudio() {
    // if (this.audioFile) {
    //   this.audioPlayer.nativeElement.currentTime = this.videoPlayer.nativeElement.currentTime
    //   this.audioPlayer.nativeElement.play();
    // }
  }
  
  pauseAudio() {
    // if (this.audioFile) {
    //   this.audioPlayer.nativeElement.currentTime = this.videoPlayer.nativeElement.currentTime
    //   this.audioPlayer.nativeElement.pause();
    // }
  }

  handleDragStart(e, trackEl, part, trackElIndex, partIndex) {
    console.log('handleDragStart', e, trackEl, part)
    // e.preventDefault();
    // e.stopPropagation();
    // e.stopImmediatePropagation();
    console.log("drag start")
    part.dragging = true;
    this.dragItem = {trackEl, part, trackElIndex, partIndex}
    // e.dataTransfer.dropEffect = 'move';
    e.dataTransfer.setData("text/plain", JSON.stringify({part, trackEl, trackElIndex, partIndex}, this.getCircularReplacer()));
  }

  checkDragging() {
    let parts = (this.tracks.map(k => k.parts) as any).flat(Infinity)

    return parts.find(x => !!x.dragging)
  }

  checkCustomSpeed() {
    let parts = (this.tracks.map(k => k.parts) as any).flat(Infinity)

    return parts.filter(x => x.part_speed_rate != 1).length
  }

  handleDragEnd(e, item) {
    console.log('handleDragEnd', e)
    item.dragging = false;
    this.dragItem = undefined;
    document.querySelectorAll('.hor_space').forEach(space => {
      space.classList.remove('active');
    })
  }

  onMouseMoveTrack(e) {
    console.log("onMouseMoveTrack", e)
    if (!this.dragItem) {
      return
    }
    let posX = e.clientX + this.outContainer.nativeElement.children[0].scrollLeft - 20;
    const targetElement = e.target as HTMLElement;
    console.log("onMouseMoveTrack", targetElement)

    if (!!targetElement.closest('.track_element') && !targetElement.closest('.track_element').classList.contains('dragging')) {
      console.log("onMouseMoveTrack IN", targetElement)
      let closeEl = targetElement.closest('.track_element');
      let is_left = posX - closeEl.getBoundingClientRect().x <= (closeEl.getBoundingClientRect().width / 2);

      if (is_left) {
        if (!closeEl.previousElementSibling.classList.contains('active')) {
          closeEl.previousElementSibling.classList.add('active')
          document.querySelectorAll('.hor_space').forEach(space => {
            space.classList.remove('active');
          })
        }
      } else {
        if (!closeEl.nextElementSibling.classList.contains('active')) {
          closeEl.nextElementSibling.classList.add('active')
          document.querySelectorAll('.hor_space').forEach(space => {
            space.classList.remove('active');
          })
        }
      }
    }
  }

  onMouseLeaveTrack(e) {
    if (!this.dragItem) {
      return
    }
    document.querySelectorAll('.hor_space').forEach(space => {
      space.classList.remove('active');
    })
  }

  handleDragEnterSpacer(e, is_down) {
    if (is_down) {
      this.spacerActive = 2
    } else {
      this.spacerActive = 1
    }
  }

  handleDragLeaveSpacer(e, is_down) {
    this.spacerActive = 0
  }

  handleDragOverSpacer(e) {
    this.spacerActive = 0
    if (e.preventDefault) {
      e.preventDefault(); // allows us to drop
    } 
  }

  handleDropSpacer(e, is_down) {
    this.spacerActive = 0
    let prevPlace = JSON.parse(e.dataTransfer.getData("text/plain"));
    if (prevPlace.trackEl.type == 'audio' && prevPlace.trackEl.parts.length > 1) {
      this.tracks[prevPlace.trackElIndex].parts.splice(prevPlace.partIndex, 1)
      if (this.tracks[prevPlace.trackElIndex].parts.length == 0) {
        this.tracks.splice(prevPlace.trackElIndex, 1)
      }
      prevPlace.part.dragging = false;
      this.tracks.push({
        type: 'audio',
        parts: [{...prevPlace.part}]
      })
      this.refreshTracks();
    }
    
    console.log("handleDropSpacer prevPlace", prevPlace)
    // item.dragging = false;
    this.dragItem = undefined;
  }

  trackOpen(track) {
    track.is_open = !track.is_open
  }

  handleDrop(e, track) {
    console.log('handleDrop', e)
    // console.log('handleDrop document.querySelector', Array.from(document.querySelectorAll('.hor_space')).find(x => x.classList.contains('active')))
    // console.log("handleDrop getData", JSON.parse(e.dataTransfer.getData("text/plain")))
    let spacer = Array.from(document.querySelectorAll('.hor_space')).find(x => x.classList.contains('active')) as HTMLElement;
    if (spacer) {
      let prevPlace = JSON.parse(e.dataTransfer.getData("text/plain"));
      let newPlace:any = {
        isLeft: !!+spacer.getAttribute('data-is-left'),
        trackElIndex: +spacer.getAttribute('data-track-el-index'),
        partIndex: +spacer.getAttribute('data-part-index')
      }
      newPlace.trackEl = this.tracks[newPlace.trackElIndex]
      console.log("prevPlace", prevPlace);
      console.log("newPlace", newPlace);

      if (newPlace.trackElIndex == prevPlace.trackElIndex) {
        
        let newIndex;
        if (newPlace.partIndex == 0) {
          newIndex = !newPlace.isLeft ? newPlace.partIndex + 1 : newPlace.partIndex;
        } else if (newPlace.partIndex == newPlace.trackEl.parts.length - 1) {
          newIndex = newPlace.isLeft ? newPlace.partIndex - 1 : newPlace.partIndex;
        } else {
          if (prevPlace.partIndex < newPlace.partIndex) {
            newIndex = newPlace.isLeft ? newPlace.partIndex - 1 : newPlace.partIndex;
          } else {
            newIndex = !newPlace.isLeft ? newPlace.partIndex + 1 : newPlace.partIndex;
          }
        }
        console.log("newIndex", newIndex)
        if (newIndex != prevPlace.partIndex) {
          moveItemInArray(newPlace.trackEl.parts, prevPlace.partIndex, newIndex)
          this.refreshTracks();
        }
      } else {
        if (newPlace.trackEl.type == 'audio' && prevPlace.trackEl.type == 'audio') {
          this.tracks[prevPlace.trackElIndex].parts.splice(prevPlace.partIndex, 1)
          prevPlace.part.dragging = false;

          let newIndex;
          if (newPlace.partIndex == 0) {
            newIndex = !newPlace.isLeft ? newPlace.partIndex + 1 : newPlace.partIndex;
          } else if (newPlace.partIndex == newPlace.trackEl.parts.length - 1) {
            newIndex = newPlace.isLeft ? newPlace.partIndex - 1 : newPlace.partIndex;
          } else {
            if (prevPlace.partIndex < newPlace.partIndex) {
              newIndex = newPlace.isLeft ? newPlace.partIndex - 1 : newPlace.partIndex;
            } else {
              newIndex = !newPlace.isLeft ? newPlace.partIndex + 1 : newPlace.partIndex;
            }
          }
          this.tracks[newPlace.trackElIndex].parts.splice(newIndex, 0, prevPlace.part)
          if (this.tracks[prevPlace.trackElIndex].parts.length == 0) {
            this.tracks.splice(prevPlace.trackElIndex, 1)
          }
          console.log("newIndex", newIndex)
          this.refreshTracks();
        }
      }
    }
    this.dragItem = undefined;
  }

  handleDragLeave(e) {
    console.log('handleDragLeave', e)
    document.querySelectorAll('.hor_space').forEach(space => {
      space.classList.remove('active');
    })
  }
  // handleDragOver(e) {
    //   console.log('handleDragOver', e)
    // }
    
  handleDragOver(e) {
    // console.log('handleDragOver', e)
    if (e.preventDefault) {
      e.preventDefault(); // allows us to drop
    } 

    // let posX = e.clientX + this.outContainer.nativeElement.children[0].scrollLeft - 20;
    const targetElement = e.target as HTMLElement;
    if (!!targetElement.closest('.track_element') && !targetElement.closest('.track_element').classList.contains('dragging')) {
      let closeEl = targetElement.closest('.track_element');
      let is_left = e.x - closeEl.getBoundingClientRect().x <= (closeEl.getBoundingClientRect().width / 2);
      // console.log(is_left, closeEl)

      if (is_left) {
        if (!closeEl.previousElementSibling.classList.contains('active')) {
          document.querySelectorAll('.hor_space').forEach(space => {
            space.classList.remove('active');
          })
          closeEl.previousElementSibling.classList.add('active')
        }
      } else {
        if (!closeEl.nextElementSibling.classList.contains('active')) {
          document.querySelectorAll('.hor_space').forEach(space => {
            space.classList.remove('active');
          })
          closeEl.nextElementSibling.classList.add('active')
        }
      }
    }
  }


  checkIfCanAdd() {
    return true
    // return this.videoPlayer.nativeElement.currentTime != this.duration ? (this.cuts.length ? this.cuts.filter(x => x.spliter.val == this.videoPlayer.nativeElement.currentTime).length == 0 : true) : false
  }
  // generateThumbnails(videoElement: HTMLVideoElement, canvas: HTMLCanvasElement) {
  //   const ctx = canvas.getContext('2d');
    
  //   // Configure thumbnail dimensions
  //   const thumbnailWidth = 50; // Adjust as needed
  //   const thumbnailHeight = 40; // Adjust as needed
    
  //   // Calculate number of thumbnails based on video duration and desired interval
  //   const videoDuration = videoElement.duration;
  //   const interval = 1; // Display one thumbnail per second, adjust as needed
  //   const numThumbnails = Math.ceil(videoDuration / interval);
    
  //   // Set canvas dimensions
  //   const canvasWidth = thumbnailWidth * numThumbnails;
  //   const canvasHeight = thumbnailHeight;
  //   canvas.width = canvasWidth;
  //   canvas.height = canvasHeight;
    
  //   // Clear canvas
  //   ctx.clearRect(0, 0, canvasWidth, canvasHeight);
    
  //   // Generate thumbnails
  //   for (let i = 0; i < numThumbnails; i++) {
  //     const thumbnailTime = i * interval;
  //     videoElement.currentTime = thumbnailTime;
      
  //     ctx.drawImage(videoElement, i * thumbnailWidth, 0, thumbnailWidth, thumbnailHeight);
      
  //     // Use the generated thumbnail image (ctx.getImageData()) as needed
  //     // You can also save the thumbnail image to an array or use it in other ways
  //   }
    
  //   // Calculate width of the timeline thumbnails container
  //   const timelineWidth = thumbnailWidth * numThumbnails;
  //   this.timelineThumbnails.nativeElement.style.width = `${timelineWidth}px`;
  // }

  onMouseLeave(e: MouseEvent) {
    // console.log("onMouseLeave",e)
    if (!this.isProjectPlaying && !this.hasCropOn()) {
      this.showTime(this.currentTimeProject);
    }
    this.verticalLine.show = false;
  }

  onMouseOverVideo(e: MouseEvent) {
    // console.log("onMouseOverVideo",e)
  }

  startFunction(e: MouseEvent, is_left) {
    console.log("startFunction",e)
    this.interval = setInterval(() => {
      console.log("interval", !!this.cutEdited)
      if (!this.duration || !!document.body.classList.contains('rd') || !this.cutEdited) {
        return
      }
  
  
      let part = this.cutEdited.part;
      if (!!part.is_combine) {
        return
      }

      let parts = this.tracks[this.cutEdited.trackElIndex].parts
      let partIndex = parts.indexOf(part);

      if (is_left) {
        if (partIndex == 0 && this.cutEdited.trackElIndex == 0) {
          this.leftActive = true;
          if (part.from - 0.1 > part.saveFTD.from) {
            part.from = part.from - 0.1
          } else {
            part.from = part.saveFTD.from
          }
          part.duration = (part.to - part.from)* (1/part.part_speed_rate);
          part.prevAllDuration = part.saveFTD.prevAllDuration + (part.saveFTD.duration - part.duration)
          this.refreshTracks();
          this.changeTime(0)
        }
      } else {
        if (partIndex == parts.length - 1) {
          this.rightActive = true;
          if (part.to + 0.1 < part.saveFTD.to) {
            part.to = part.to + 0.1
          } else {
            part.to = part.saveFTD.to
          }
          part.duration = (part.to - part.from)* (1/part.part_speed_rate);
          this.refreshTracks();
          this.changeTime(part.prevAllDuration + part.duration - 0.005)
        }
      }

      // this.editCut(posX, newTime, this.wWidth)
    }, 100); // Здесь задержка между выполнениями (1 секунда)
  }

  stopFunction() {

    // Останавливаем выполнение функции при уводе мыши с блока
    clearInterval(this.interval);
    this.rightActive = false;
    this.leftActive = false;
    // this.cutEdited = undefined;
  }

  onMouseDownPart(e, part, trackElIndex, partIndex) {
    let posX = e.clientX + this.outContainer.nativeElement.children[0].scrollLeft - 20;
    if (posX < 0) {
      posX = 0
    }
    // console.log(" onMouseMove posX", posX)
    const newTime = (posX / this.wWidth) * this.duration;

    this.movedFragment = {
      part: part,
      delta: (newTime - part.prevAllDuration)*part.part_speed_rate,
      partIndex: partIndex,
      trackElIndex: trackElIndex
    }
    if (trackElIndex == 0) {
      this.connectAudiosWithVideo()
    }
  }

  onMouseDownPartSub(e, part, trackElIndex, partIndex, sub, subIndex) {
    let posX = e.clientX + this.outContainer.nativeElement.children[0].scrollLeft - 20;
    if (posX < 0) {
      posX = 0
    }
    // console.log(" onMouseMove posX", posX)
    const newTime = (posX / this.wWidth) * this.duration;
    sub.duration = +(sub.to - sub.from).toFixed(3);
    if (!sub.hasOwnProperty('saveFrom')) {
      sub.saveFrom = sub.from;
    }

    sub.words.forEach(word => {
      if (!word.save) {
        word.save = {
          end_ts: word.end_ts,
          from: word.from,
          to: word.to,
          ts: word.ts,
        }
      }
    });

    this.movedSub = {
      part: part,
      delta: ((newTime - part.prevAllDuration)*part.part_speed_rate - (sub.from - part.from)),
      partIndex: partIndex,
      trackElIndex: trackElIndex,
      sub: sub,
      subIndex: subIndex
    }
    // if (trackElIndex == 0) {
    //   this.connectAudiosWithVideo()
    // }
  }

  onMouseMove(e: MouseEvent, timelineWidth) {
    // console.log("onMouseMove", e.buttons)
    if (!this.duration || !!document.body.classList.contains('rd') || !!this.dragItem) {
      return
    }

    let posX = e.clientX + this.outContainer.nativeElement.children[0].scrollLeft - 20;
    if (posX < 0) {
      posX = 0
    }
    // console.log(" onMouseMove posX", posX)
    const newTime = (posX / timelineWidth) * this.duration;
    // console.log(" onMouseMove newTime", newTime)
    this.verticalLine.val = newTime
    this.verticalLine.posX = posX

    if (e.buttons == 1) {
      if (this.movedFragment) {
        console.log("onMouseMove movedFragment", this.movedFragment)
        this.movePart(e, posX, newTime, timelineWidth)
      } else if (this.movedSub) {
        this.onMoveSub(e, posX, newTime, timelineWidth)
        console.log("onMouseMove movedSub")
      } else if (!!this.cutEdited) {
        this.editCut(posX, newTime, timelineWidth)
      } else {
        this.changeTime(newTime)
      }
    } else {
      if (!this.isProjectPlaying && !this.hasCropOn()) {
        this.showTime(newTime)
      }
    }
  }

  getItemById(arr, id) {
    return arr.find(x => x.id == id)
  }
  
  onMouseDown(e: MouseEvent) {
    console.log("onMouseDown",e)
    // e.preventDefault();
    if (!this.duration || !!document.body.classList.contains('rd')) {
      return
    }
    const timelineWidth = this.customTimelineRef.nativeElement.offsetWidth;
    const clickX = e.clientX + this.outContainer.nativeElement.children[0].scrollLeft - 20;
    const newTime = (clickX / timelineWidth) * this.duration;
    
    console.log("onMouseDown newTime", newTime, this.outContainer.nativeElement.children[0].scrollLeft)

    // this.changeTime(newTime)

    console.log("onMouseDown",e)
    this.changeTime(newTime)

    return
  }

  onMouseEnter(e: MouseEvent) {
    // console.log("onMouseEnter",e)

    this.verticalLine.show = true;
  }

  
  onMouseDownCut(e: MouseEvent, part, is_left:boolean, trackElIndex, partIndex) {
    // e.preventDefault();
    // if (this.mode == 'cut') {
    //   return
    // }
    const timelineWidth = this.customTimelineRef.nativeElement.offsetWidth;
    const clickX = e.clientX + this.outContainer.nativeElement.children[0].scrollLeft - 20;
    const newTime = (clickX / timelineWidth) * this.duration;
    
    const posX = e.clientX + this.outContainer.nativeElement.children[0].scrollLeft - 20
    console.log("onMouseDownCut timelineWidth", timelineWidth)
    console.log("onMouseDownCut this.duration", this.duration)
    console.log("onMouseDownCut posX", posX)
    console.log("onMouseDownCut newTime", newTime)
    console.log("onMouseDownCut part", part)
    console.log("onMouseDownCut is_left", is_left)
    if (!part.saveFTD) {
      part.saveFTD = {
        from: 0,
        to: part.saveDuration,
        duration: part.saveDuration,
        part_speed_rate: part.part_speed_rate,
        prevAllDuration: part.prevAllDuration
      }
    }
    this.cutEdited = {
      part: part,
      is_left: is_left,
      startTime: newTime,
      savedDur: JSON.parse(JSON.stringify(part.duration)),
      savedFrom: JSON.parse(JSON.stringify(part.from)),
      savedTo: JSON.parse(JSON.stringify(part.to)),
      savedPrevs: this.tracks[trackElIndex].parts.map(x => x.prevAllDuration).slice(),
      partIndex: partIndex,
      trackElIndex: trackElIndex,
      is_combine: !!part.is_combine
    }

    if (trackElIndex == 0) {
      this.connectAudiosWithVideo()
    }
    
    console.log("onMouseDownCut", this.cutEdited)
  }

  onMouseUp(e: MouseEvent) {
    console.log("handle onMouseUp",e)
    if (this.mode != 'cut') {
      if (this.movedFragment) {
        this.refreshTracks(true, this.movedFragment.trackElIndex == 0);
        this.movedFragment = undefined;
        this.saveVideoEditorHistory("Moved Fragment");
      }
      if (this.movedSub) {
        // this.refreshTracks(true, this.movedFragment.trackElIndex == 0);
        this.movedSub = undefined;
        this.saveVideoEditorHistory("Change Sub");
      }
      if (this.cutEdited) {
        this.refreshTracks(true, this.cutEdited.trackElIndex == 0);
        this.cutEdited = undefined;
        this.saveVideoEditorHistory("Edit Fragment");
      }
    }
  }

  onMouseOver(e: MouseEvent) {
    // console.log("onMouseUp",e)
    // if (this.cutEdited) {
    //   this.cutEdited = undefined;
    //   // this.refreshTracks();
    // }
  }

  onMoveSub(e, x, newTime, width) {
    console.log("onMoveSub", e);
    console.log("move", this.movedSub)
    let part = this.movedSub.part;
    let sub = this.movedSub.sub;
    // let parts = this.tracks[this.movedSub.trackElIndex].parts;
    // let subs = part.subs;


    // console.log("move newTime", newTime)
    let mousetime = (newTime - part.prevAllDuration)*part.part_speed_rate
    let start = mousetime - this.movedSub.delta + part.from;

    // console.log("move mousetime", mousetime)
    // console.log("move start", start)
    // if (+start >= +part.prevAllDuration && +start + +sub.duration <= +part.prevAllDuration + +part.duration) {
      sub.from = +start.toFixed(3);
      sub.to = +sub.from + +sub.duration;
      let changeValue = sub.saveFrom - sub.from;
      // console.log("move changeValue", changeValue)
      sub.words.forEach(word => {
        word.end_ts = +(+word.save.end_ts - +changeValue).toFixed(3)
        word.from = +(+word.save.from - +changeValue).toFixed(3)
        word.to = +(+word.save.to - +changeValue).toFixed(3)
        word.ts = +(+word.save.ts - +changeValue).toFixed(3)
      });
      console.log("move sub", sub)
    // }

    // console.log("move delta", this.movedSub.delta)

    // return;
 
  }

  movePart(e, x, newTime, width) {
    console.log("movePart", e);
    let part = this.movedFragment.part;
    let parts = this.tracks[this.movedFragment.trackElIndex].parts;
    let partLeft = this.movedFragment.part.prevAllDuration;
    let newDelta = (newTime - part.prevAllDuration)*part.part_speed_rate - this.movedFragment.delta;

    console.log("move partLeft", partLeft)
    console.log("move delta", this.movedFragment.delta)
    console.log("move newDelta", newDelta)

    let prevPart;
    let prevPartBorder;
    let nextPart;
    let nextPartBorder;

    if (parts[this.movedFragment.partIndex - 1]) {
      prevPart = parts[this.movedFragment.partIndex - 1];
      prevPartBorder = prevPart.prevAllDuration + prevPart.duration;
    }

    if (parts[this.movedFragment.partIndex + 1]) {
      nextPart = parts[this.movedFragment.partIndex + 1];
      nextPartBorder = nextPart.prevAllDuration;
    }

    
    
    
    let newPrevAllDuration = part.prevAllDuration + newDelta;
    console.log("move newPrevAllDuration", newPrevAllDuration)
    console.log("move prevPartBorder", prevPartBorder)
    console.log("move nextPartBorder", nextPartBorder)
    if (!!prevPartBorder && newPrevAllDuration <= prevPartBorder) {
      if (prevPartBorder - newPrevAllDuration > prevPart.duration/2) {
        part.prevAllDuration = prevPart.prevAllDuration;
        prevPart.prevAllDuration = part.prevAllDuration + part.duration;
        parts.sort(function(a, b) {
          return (a.prevAllDuration - b.prevAllDuration);
        })
        this.movedFragment = {
          part: part,
          delta: (newTime - part.prevAllDuration)*part.part_speed_rate,
          partIndex: this.movedFragment.partIndex - 1,
          trackElIndex: prevPart.trackElIndex
        }
      } else {
        part.prevAllDuration = prevPartBorder;
      }
    } else if (!!nextPartBorder && newPrevAllDuration + part.duration >= nextPartBorder) {
      if (newPrevAllDuration + part.duration - nextPartBorder > nextPart.duration/2) {
        nextPart.prevAllDuration = part.prevAllDuration;
        part.prevAllDuration = nextPart.prevAllDuration + nextPart.duration;
        parts.sort(function(a, b) {
          return (a.prevAllDuration - b.prevAllDuration);
        })
        this.movedFragment = {
          part: part,
          delta: (newTime - part.prevAllDuration)*part.part_speed_rate,
          partIndex: this.movedFragment.partIndex + 1,
          trackElIndex: nextPart.trackElIndex
        }
      } else {
        // part.prevAllDuration = nextPartBorder - part.duration;
      }
    } else if (newPrevAllDuration <= 0) {
      part.prevAllDuration = 0;
    }  else {
      part.prevAllDuration = newPrevAllDuration;
    }
    console.log("move part.prevAllDuration", part.prevAllDuration)
    // this.refreshTracks()
  }

  addSpace(arr, index, delta, editedFragment) {
    console.log("addSpace", arr, index, delta, editedFragment)
    arr.slice(index).forEach((element, ind) => {
      element.prevAllDuration = editedFragment.savedPrevs.slice(index)[ind] + delta
    });
  }
  // cut Events
  editCut(x, newTime, width) {
    // console.log("onMouseDownCut editCut x", x);
    console.log("onMouseDownCut editCut newTime", newTime);
    // console.log("onMouseDownCut this.tracks[this.cutEdited.trackElIndex]", this.tracks[this.cutEdited.trackElIndex]);

    let parts = this.tracks[this.cutEdited.trackElIndex].parts
    let part = this.cutEdited.part;
    let partIndex = parts.indexOf(part);
    let newDelta = +(newTime - this.cutEdited.startTime)*part.part_speed_rate;
    // console.log("parts", parts)
    // console.log("partIndex", partIndex)
    let is_left = this.cutEdited.is_left;

    console.log("is_left", is_left)

    if (!!part.is_combine) {
      let savedFirstBlend = part.savedBlends[0];
      let savedLastBlend = part.savedBlends[part.savedBlends.length - 1];
      if (is_left) {
        let leftBlend = part.blends[0];
        let savedLeftBlend = part.savedBlends.find(x => x.pId == leftBlend.pId)
        if (leftBlend.pId == savedFirstBlend.pId) {
          if (Number(((newTime - leftBlend.prevAllDuration)*leftBlend.part_speed_rate + leftBlend.from).toFixed(6)) <= savedLeftBlend.saveFTD.from) {
            if (partIndex != 0) {
              leftBlend.from = savedLeftBlend.saveFTD.from
            }
          } else {
            if (Number(((newTime - leftBlend.prevAllDuration)*leftBlend.part_speed_rate + leftBlend.from).toFixed(6)) >= leftBlend.to) {
              part.blends.splice(0,1)
              leftBlend = part.blends[0];
            } else {
              leftBlend.from = Number(((newTime - leftBlend.prevAllDuration)*leftBlend.part_speed_rate + leftBlend.from).toFixed(6))
            }
          }
          
        } else {
          if (Number(((newTime - leftBlend.prevAllDuration)*leftBlend.part_speed_rate + leftBlend.from).toFixed(6)) <= savedLeftBlend.from) {
            leftBlend.from = savedLeftBlend.from;
            leftBlend.duration = savedLeftBlend.duration;
            let cstInd = part.savedBlends.indexOf(part.savedBlends.find(x => x.pId == leftBlend.pId));
            part.blends.unshift(JSON.parse(JSON.stringify(part.savedBlends[cstInd - 1], this.getCircularReplacer())));
            leftBlend = part.blends[0];
            this.setSrc(leftBlend);
            leftBlend.from = leftBlend.to;
            leftBlend.duration = 0;
            leftBlend.prevAllDuration = part.blends[1].prevAllDuration;
          } else {
            if (part.savedBlends.indexOf(part.savedBlends.find(x => x.pId == leftBlend.pId)) == part.savedBlends.length - 1) {
              if (Number(((newTime - leftBlend.prevAllDuration)*leftBlend.part_speed_rate + leftBlend.from).toFixed(6)) >= leftBlend.to - ((40 / this.wWidth)*this.duration)) {
                leftBlend.from = Number((leftBlend.to - ((40 / this.wWidth)*this.duration)).toFixed(6))
              } else {
                leftBlend.from = Number(((newTime - leftBlend.prevAllDuration)*leftBlend.part_speed_rate + leftBlend.from).toFixed(6))
              }
            } else {
              if (Number(((newTime - leftBlend.prevAllDuration)*leftBlend.part_speed_rate + leftBlend.from).toFixed(6)) >= leftBlend.to) {
                part.blends.splice(0,1)
                leftBlend = part.blends[0];
              } else {
                leftBlend.from = Number(((newTime - leftBlend.prevAllDuration)*leftBlend.part_speed_rate + leftBlend.from).toFixed(6))
              }
            }
          }
        }

        leftBlend.prevAllDuration = leftBlend.prevAllDuration + (leftBlend.duration - ((leftBlend.to - leftBlend.from)*(1/leftBlend.part_speed_rate)))
        leftBlend.duration = (leftBlend.to - leftBlend.from)* (1/leftBlend.part_speed_rate);
        part.duration = part.blends.map(n => n.duration || 0).reduce((acc, number) => +acc + +number, 0);

        part.from = leftBlend.from;
        part.prevAllDuration = leftBlend.prevAllDuration;
        if (partIndex != 0) {
          this.refreshTracks()
        } 
        this.changeTime(leftBlend.prevAllDuration + 0.05)
      } else {

        let rightBlend = part.blends[part.blends.length - 1];
        let savedRightBlend = part.savedBlends.find(x => x.pId == rightBlend.pId);


        if (rightBlend.pId == savedLastBlend.pId) {
          if (Number(((newTime - rightBlend.prevAllDuration)*rightBlend.part_speed_rate + rightBlend.from).toFixed(6)) >= savedRightBlend.saveFTD.to) {
            rightBlend.to = savedRightBlend.saveFTD.to;
          } else {
            if (Number(((newTime - rightBlend.prevAllDuration)*rightBlend.part_speed_rate + rightBlend.from).toFixed(6)) <= rightBlend.from) {
              part.blends.splice(part.blends.length - 1, 1)
              rightBlend = part.blends[part.blends.length - 1];
              console.log("ahahah")
            } else {
              rightBlend.to = Number(((newTime - rightBlend.prevAllDuration)*rightBlend.part_speed_rate + rightBlend.from).toFixed(6))
            }
          }
        } else {
          if (Number(((newTime - rightBlend.prevAllDuration)*rightBlend.part_speed_rate + rightBlend.from).toFixed(6)) >= savedRightBlend.to) {
            rightBlend.to = savedRightBlend.to;
            rightBlend.duration = savedRightBlend.duration;
            let cstInd = part.savedBlends.indexOf(part.savedBlends.find(x => x.pId == rightBlend.pId));
            part.blends.push(JSON.parse(JSON.stringify(part.savedBlends[cstInd + 1], this.getCircularReplacer())));
            rightBlend = part.blends[part.blends.length - 1];
            this.setSrc(rightBlend);
            rightBlend.to = rightBlend.from;
            rightBlend.duration = 0;
            rightBlend.prevAllDuration = part.blends[part.blends.length - 2].prevAllDuration;
          } else {
            if (part.savedBlends.indexOf(part.savedBlends.find(x => x.pId == rightBlend.pId)) == 0) {
              if (Number(((newTime - rightBlend.prevAllDuration)*rightBlend.part_speed_rate + rightBlend.from).toFixed(6)) <= rightBlend.from + ((40 / this.wWidth)*this.duration)) {
                rightBlend.to = Number((rightBlend.from + ((40 / this.wWidth)*this.duration)).toFixed(6))
              } else {
                rightBlend.to = Number(((newTime - rightBlend.prevAllDuration)*rightBlend.part_speed_rate + rightBlend.from).toFixed(6))
              }
            } else {
              if (Number(((newTime - rightBlend.prevAllDuration)*rightBlend.part_speed_rate + rightBlend.from).toFixed(6)) <= rightBlend.from) {
                part.blends.splice(part.blends.length - 1, 1)
                rightBlend = part.blends[part.blends.length - 1];
              } else {
                rightBlend.to = Number(((newTime - rightBlend.prevAllDuration)*rightBlend.part_speed_rate + rightBlend.from).toFixed(6))
              }
            }
          }
        }


        rightBlend.duration = (rightBlend.to - rightBlend.from)* (1/rightBlend.part_speed_rate);
        part.duration = part.blends.map(n => n.duration || 0).reduce((acc, number) => +acc + +number, 0);

        part.to = rightBlend.to;
        if (partIndex != 0) {
          this.refreshTracks()
        } 
        
        this.changeTime(rightBlend.prevAllDuration + rightBlend.duration - 0.005)
      }
    } else {

      if (is_left) {
        if (part.trackElIndex > 0) {
          if (newDelta > 0) {
            console.log("DIR left to right", newDelta, part.from)
            if (Number((this.cutEdited.savedFrom + newDelta).toFixed(6)) >= part.to - ((40 / this.wWidth)*this.duration)) {
              console.log("test #2-1")
              part.from = Number((part.to - ((40 / this.wWidth)*this.duration)).toFixed(6))
            } else {
              console.log("test #2-2")
              part.from = Number((this.cutEdited.savedFrom + newDelta).toFixed(6))
            }
            part.prevAllDuration = part.prevAllDuration + (part.duration - ((part.to - part.from)*(1/part.part_speed_rate)))
            part.duration = (part.to - part.from)* (1/part.part_speed_rate);
  
          } else {
            console.log("DIR left to left", newDelta, part.from)
            if (Number((this.cutEdited.savedFrom + newDelta).toFixed(6)) <= part.saveFTD.from) {
              part.from = part.saveFTD.from
            } else {
              part.from = Number((this.cutEdited.savedFrom + newDelta).toFixed(6))
            }
            part.prevAllDuration = part.prevAllDuration + (part.duration - ((part.to - part.from)*(1/part.part_speed_rate)))
            part.duration = (part.to - part.from)* (1/part.part_speed_rate);
  
            if (!!parts[partIndex - 1] && parts[partIndex - 1].prevAllDuration + parts[partIndex - 1].duration > part.prevAllDuration) {
              let prevPart = parts[partIndex - 1];
     
              if (this.tracks[prevPart.trackElIndex + 1]) {
                let newTrackParts = this.tracks[prevPart.trackElIndex + 1].parts;
          
                let range = {
                  from: prevPart.prevAllDuration,
                  to: prevPart.prevAllDuration + prevPart.duration
                }
          
                let canAdd: boolean = true;
                newTrackParts.forEach(el => {
          
                  let rFrom = range.from;
                  let rTo = range.to;
                  let left = el.prevAllDuration;
                  let right = el.prevAllDuration + el.duration;
                  console.log("rFrom", rFrom)
                  console.log("rTo", rTo)
                  console.log("left", left)
                  console.log("right", right)
                  if ((left <= rFrom && right > rFrom) || (left > rFrom && left < rTo) || (right > rFrom && right < rTo)) {
                    canAdd = false;
                  }
                })
                if (canAdd) {
                  parts.splice(partIndex - 1, 1)
                  newTrackParts.push({...prevPart})
                  newTrackParts.sort(function(a, b) {
                    return (a.prevAllDuration - b.prevAllDuration);
                  })
                } else {
                  parts.splice(partIndex - 1, 1)
                  this.tracks.splice(prevPart.trackElIndex + 1, 0 , {
                    type: 'audio',
                    parts: [{...prevPart}]
                  })
                }
              } else {
                parts.splice(partIndex - 1, 1)
                this.tracks.push({
                  type: 'audio',
                  parts: [{...prevPart}]
                })
              }
              this.refreshTracks()
            }
          }
        } else {
          if (Number(((newTime - part.prevAllDuration)*part.part_speed_rate + part.from).toFixed(6)) <= part.saveFTD.from) {
            console.log("test #1")
            if (partIndex != 0) {
              console.log("test #1-1")
              part.from = part.saveFTD.from
            }
          } else {
            console.log("test #2", Number(((newTime - part.prevAllDuration)*part.part_speed_rate + part.from).toFixed(6)))
            if (Number(((newTime - part.prevAllDuration)*part.part_speed_rate + part.from).toFixed(6)) >= part.to - ((40 / this.wWidth)*this.duration)) {
              console.log("test #2-1")
              part.from = Number((part.to - ((40 / this.wWidth)*this.duration)).toFixed(6))
            } else {
              console.log("test #2-2")
              part.from = Number(((newTime - part.prevAllDuration)*part.part_speed_rate + part.from).toFixed(6))
            }
            console.log("test #2 part.from", part.from);
          }
          part.prevAllDuration = part.prevAllDuration + (part.duration - ((part.to - part.from)*(1/part.part_speed_rate)))
          part.duration = (part.to - part.from)* (1/part.part_speed_rate);
          if (partIndex != 0) {
            this.refreshTracks()
          } 
        }
        this.changeTime(part.prevAllDuration)
      } else {
        if (part.trackElIndex > 0) {
          if (newDelta > 0) {
            console.log("DIR right to right", newDelta, part.to)
            if (Number(((newTime - part.prevAllDuration)*part.part_speed_rate + part.from).toFixed(6)) >= part.saveFTD.to) {
              part.to = part.saveFTD.to
            } else {
              part.to = Number(((newTime - part.prevAllDuration)*part.part_speed_rate + part.from).toFixed(6))
            }
            part.duration = (part.to - part.from)* (1/part.part_speed_rate);
            if (!!parts[partIndex + 1] && (parts[partIndex + 1].prevAllDuration < part.prevAllDuration + part.duration)) {
              let nextPart = parts[partIndex + 1];
     
              if (this.tracks[nextPart.trackElIndex + 1]) {
                let newTrackParts = this.tracks[nextPart.trackElIndex + 1].parts;
          
                let range = {
                  from: nextPart.prevAllDuration,
                  to: nextPart.prevAllDuration + nextPart.duration
                }
          
                let canAdd: boolean = true;
                newTrackParts.forEach(el => {
          
                  let rFrom = range.from;
                  let rTo = range.to;
                  let left = el.prevAllDuration;
                  let right = el.prevAllDuration + el.duration;
                  console.log("rFrom", rFrom)
                  console.log("rTo", rTo)
                  console.log("left", left)
                  console.log("right", right)
                  if ((left <= rFrom && right > rFrom) || (left > rFrom && left < rTo) || (right > rFrom && right < rTo)) {
                    canAdd = false;
                  }
                })
                if (canAdd) {
                  parts.splice(partIndex + 1, 1)
                  newTrackParts.push({...nextPart})
                  newTrackParts.sort(function(a, b) {
                    return (a.prevAllDuration - b.prevAllDuration);
                  })
                } else {
                  parts.splice(partIndex + 1, 1)
                  this.tracks.splice(nextPart.trackElIndex + 1, 0 , {
                    type: 'audio',
                    parts: [{...nextPart}]
                  })
                }
              } else {
                parts.splice(partIndex + 1, 1)
                this.tracks.push({
                  type: 'audio',
                  parts: [{...nextPart}]
                })
              }
              this.refreshTracks()
            }
          } else {
            console.log("DIR right to left", newDelta, part.to)
            if (Number(((newTime - part.prevAllDuration)*part.part_speed_rate + part.from).toFixed(6)) <= part.from + ((40 / this.wWidth)*this.duration)) {
              part.to = Number((part.from + ((40 / this.wWidth)*this.duration)).toFixed(6))
            } else {
              part.to = Number(((newTime - part.prevAllDuration)*part.part_speed_rate + part.from).toFixed(6))
            }
            part.duration = (part.to - part.from)* (1/part.part_speed_rate);
          }
        } else {
          if (Number(((newTime - part.prevAllDuration)*part.part_speed_rate + part.from).toFixed(6)) >= part.saveFTD.to) {
            part.to = part.saveFTD.to
          } else {
            if (Number(((newTime - part.prevAllDuration)*part.part_speed_rate + part.from).toFixed(6)) <= part.from + ((40 / this.wWidth)*this.duration)) {
              part.to = Number((part.from + ((40 / this.wWidth)*this.duration)).toFixed(6))
            } else {
              part.to = Number(((newTime - part.prevAllDuration)*part.part_speed_rate + part.from).toFixed(6))
            }
          }
          part.duration = (part.to - part.from)* (1/part.part_speed_rate);
          if (partIndex != parts.length - 1) {
            this.refreshTracks()
          }
        }
        this.changeTime(part.prevAllDuration + part.duration - 0.005)
      }
    }

    console.log("onMouseDownCut editCut part", part)
  }

  setSrc(fragment) {
    let fileStorage;
    if (this.files.video.find(x => x.id == fragment.id)) {
      fileStorage = this.files.video.find(x => x.id == fragment.id)
    }
    if (this.files.audio.find(x => x.id == fragment.id)) {
      fileStorage = this.files.audio.find(x => x.id == fragment.id)
    }
    console.log("fileStorage", fileStorage)
    if (!!fileStorage && !!fileStorage.blob) {
      let newSrc = URL.createObjectURL(fileStorage.blob);
      fragment.blobSrc = this.sanitizer.bypassSecurityTrustResourceUrl(newSrc);
    } else if (fileStorage) {
      this.fileService.projectFiles$.next(fileStorage)
    }
  }


  // changeCutTime(newTime) {
  //   console.log('changeTime', newTime)
  //   this.currentCutTimeProject = newTime;

  //   let parts = (this.tracks.filter(x => x.type == 'video').map(k => k.parts) as any).flat(Infinity)

  //   let partIndex:number = 0;
  //   parts.forEach((part: any, k) => {
  //     (document.getElementById(`video_${part.pId}`) as HTMLVideoElement).pause();
  //     if (this.currentTimeProject >= part.prevAllDuration && this.currentTimeProject <= part.prevAllDuration + part.duration) {
  //       partIndex = k;
  //     }
  //   });

  //   let part = parts[partIndex];
    
  //   this.playCut = undefined;
  //   // this.projectState$.next(false);
  //   (document.getElementById(`video_${part.pId}`) as HTMLVideoElement).currentTime = Number(((this.currentCutTimeProject - part.prevAllDuration)*part.part_speed_rate + part.from).toFixed(6));
    
  //   if (this.isProjectPlaying) {
  //     (document.getElementById(`video_${part.pId}`) as HTMLVideoElement).play()
  //   }
  //   // this.videoPlayer.nativeElement.currentTime = newTime;
  // }

  goToTime(is_forvard) {
    let newTime = this.currentTimeProject;
    if (is_forvard) {
      newTime = newTime + 5
    } else {
      newTime = newTime - 5
    }
    this.changeTime(newTime)
  }

  showTime(newTime) {
    if (!newTime && newTime != 0) {
      return
    }

    // console.log("showTime", newTime)

    const changeVidTime = () => {
      // console.log("changeVidTime")
      let parts = this.tracks[0].parts;
      let part = parts.find(x => newTime >= x.prevAllDuration && newTime <= x.prevAllDuration + x.duration)
  
      
      if (part) {
        if (part.is_combine) {
          let blend = part.blends.find(x => newTime >= x.prevAllDuration && newTime <= x.prevAllDuration + x.duration);
          let newCurrentTime = Number(((newTime - blend.prevAllDuration)*blend.part_speed_rate + blend.from).toFixed(6));
          (document.getElementById(`video_${blend.pId}`) as HTMLVideoElement).currentTime = newCurrentTime;
        } else {
          let newCurrentTime = Number(((newTime - part.prevAllDuration)*part.part_speed_rate + part.from).toFixed(6));
          (document.getElementById(`video_${part.pId}`) as HTMLVideoElement).currentTime = newCurrentTime;
        }
      }

    }

    requestAnimationFrame(changeVidTime)
  }

  changeTime(newTime) {
    if (!newTime && newTime != 0) {
      return
    }
    console.log('changeTime', newTime)
    this.currentTimeProject = newTime;
    // document.querySelectorAll('video').forEach((element: HTMLVideoElement) => {
    //   element.currentTime = this.currentTimeProject
    // });
    // let audios:any = document.querySelectorAll('.audioPlayer');
    // audios.forEach((aud: HTMLAudioElement, k) => {
    //   aud.pause();
    // });
    let audParts = this.tracks.find(x => x.type == 'audio') ? (this.tracks.filter(x => x.type == 'audio').map(u => u.parts) as any).flat() : [];
    let parts = (this.tracks.filter(x => x.type == 'video').map(k => k.parts) as any).flat(Infinity)

    let partIndex:number = 0;
    parts.forEach((part: any, k) => {
      part.crop_on = false;

      if (part.is_combine) {
        part.blends.forEach(blend => {
          blend.crop_on = part.crop_on
        });
      }
      let vidPart = (document.getElementById(`video_${part.pId}`) as HTMLVideoElement)
      // (document.getElementById(`video_${part.pId}`) as HTMLVideoElement).pause();
      if (this.currentTimeProject >= part.prevAllDuration && this.currentTimeProject <= part.prevAllDuration + part.duration) {
        partIndex = k;

        if (part.is_combine) {
          part.blends.forEach(blend => {
            (document.getElementById(`video_${blend.pId}`) as HTMLVideoElement).pause()
          });
          part.blends.forEach((blend, k) => {
            if (this.isProjectPlaying && this.currentTimeProject >= blend.prevAllDuration && this.currentTimeProject <= blend.prevAllDuration + blend.duration) {
              (document.getElementById(`video_${blend.pId}`) as HTMLVideoElement).play()
            }
          })
        } else {
          if (this.isProjectPlaying && !!vidPart && vidPart.paused) {
            vidPart.play()
          }
        }
      } else {
        if (part.is_combine) {
          part.blends.forEach(blend => {
            (document.getElementById(`video_${blend.pId}`) as HTMLVideoElement).pause()
          });
        } else {
          if (!!vidPart && !vidPart.paused) {
            vidPart.pause();
          }
        }
      }
    });

    // let part = parts[partIndex];

    let part;
    if (parts[partIndex].is_combine) {
      let blendIndex:number = 0
      parts[partIndex].blends.forEach((blend, k) => {
        if (this.currentTimeProject >= blend.prevAllDuration && this.currentTimeProject <= blend.prevAllDuration + blend.duration) {
          blendIndex = k;
        }
      })
      part = parts[partIndex].blends[blendIndex];
    } else {
      part = parts[partIndex];
    }

    console.log("part", part);
    console.log("part obj CHANGE TIME", {
      from: part.from,
      to: part.to,
      prevAllDuration: part.prevAllDuration,
      duration: part.duration,
    });
    
    // console.log("(document.getElementById(`video_${part.pId}`) as HTMLVideoElement)", (document.getElementById(`video_${part.pId}`) as HTMLVideoElement));
    (document.getElementById(`video_${part.pId}`) as HTMLVideoElement).currentTime = Number(((this.currentTimeProject - part.prevAllDuration)*part.part_speed_rate + part.from).toFixed(6));
    if (audParts.length) {
      audParts.forEach((audPart: any, k) => {
        if (this.currentTimeProject >= audPart.prevAllDuration && this.currentTimeProject <= audPart.prevAllDuration + audPart.duration) {
          (document.getElementById(`audio_${audPart.pId}`) as HTMLAudioElement).currentTime = Number(((this.currentTimeProject - audPart.prevAllDuration)*audPart.part_speed_rate + audPart.from).toFixed(6));
          if (!!part.is_detached || !part.is_audio_only) {
            (document.getElementById(`video_${part.pId}`) as HTMLVideoElement).muted = true;
          } else {
            (document.getElementById(`video_${part.pId}`) as HTMLVideoElement).muted = false;
          }
          (document.getElementById(`audio_${audPart.pId}`) as HTMLAudioElement).volume = audPart.volume;
          this.changeRate((document.getElementById(`audio_${audPart.pId}`) as HTMLAudioElement), this.projectSpeed != 1 ? this.projectSpeed : audPart.part_speed_rate);
          if (this.isProjectPlaying && (document.getElementById(`audio_${audPart.pId}`) as HTMLAudioElement).paused) {
            (document.getElementById(`audio_${audPart.pId}`) as HTMLAudioElement).play();
          }
        } else {
          if (this.isProjectPlaying && !(document.getElementById(`audio_${audPart.pId}`) as HTMLAudioElement).paused) {
            (document.getElementById(`audio_${audPart.pId}`) as HTMLAudioElement).pause();
          }
        }
      });

    } else {
      if (this.isProjectPlaying) {
        if (!!part.is_detached || !part.is_audio_only) {
          (document.getElementById(`video_${part.pId}`) as HTMLVideoElement).muted = true;
        } else {
          (document.getElementById(`video_${part.pId}`) as HTMLVideoElement).muted = false;
        }
        if ((document.getElementById(`video_${part.pId}`) as HTMLVideoElement).paused) {
          (document.getElementById(`video_${part.pId}`) as HTMLVideoElement).play()
        }
      }
    }

    
    // if (audioParts.length) {

    //   audioParts.forEach((part, k) => {
    //     if (this.currentTimeProject >= part.prevAllDuration && this.currentTimeProject <= part.prevAllDuration + part.duration) {
    //       partAudIndex = k;
    //     }
    //   });
  
    //   let audPart = audioParts[partAudIndex];
    //   // console.log("audPart", audPart);

    //   if ((document.getElementById(`audio_${audPart.pId}`) as HTMLAudioElement).paused || (Number(audPart.from.toFixed(6)) - Number((document.getElementById(`audio_${audPart.pId}`) as HTMLAudioElement).currentTime.toFixed(6)) > 0.25)) {
    //     (document.getElementById(`audio_${audPart.pId}`) as HTMLAudioElement).currentTime = Number(audPart.from.toFixed(6));

    //     if (this.isProjectPlaying) {
    //       if ((document.getElementById(`audio_${audPart.pId}`) as HTMLAudioElement).paused) {
    //         (document.getElementById(`audio_${audPart.pId}`) as HTMLAudioElement).play();
    //         (document.getElementById(`video_${part.pId}`) as HTMLVideoElement).play()
    //       }
    //     }
    //   }
    // }

    this.playCut = undefined;
    
    // this.videoPlayer.nativeElement.currentTime = newTime;
  }
  
  pauseVideo() {
    console.log("pauseVideo")
    // const video = this.videoPlayer.nativeElement;
    // Остановка видео
    // video.pause();
  }

  playVideo() {
    console.log("playVideo")
    // const video = this.videoPlayer.nativeElement;
    // if (this.audioFile) {
    //   this.audioPlayer.nativeElement.currentTime = video.currentTime
    // }
    // // Воспроизведение видео
    // video.play();
  }

  playVideoInterval(part) {
    if (part.is_combine) {
      let pausedLength: number = 0;
      part.blends.forEach(element => {
        if ((document.getElementById(`video_${element.pId}`) as HTMLVideoElement).paused) {
          pausedLength++
        }
      });
      if (part.blends.length == pausedLength) {
        if (!!this.isProjectPlaying) {
          this.pauseProject();
        }
        this.playCut = part;
        this.currentTimeProject = this.playCut.prevAllDuration;

        (document.getElementById(`video_${part.blends[0].pId}`) as HTMLVideoElement).currentTime = Number(part.blends[0].from.toFixed(6));
        this.changeRate((document.getElementById(`video_${part.blends[0].pId}`) as HTMLVideoElement), this.projectSpeed != 1 ? this.projectSpeed : part.blends[0].part_speed_rate);
  
        let audParts = this.tracks.find(x => x.type == 'audio') ? (this.tracks.filter(x => x.type == 'audio').map(u => u.parts) as any).flat() : [];
        if (audParts.length) {
          audParts.forEach((audPart: any, k) => {
            if (this.currentTimeProject >= audPart.prevAllDuration && this.currentTimeProject <= audPart.prevAllDuration + audPart.duration) {
              (document.getElementById(`audio_${audPart.pId}`) as HTMLAudioElement).currentTime = Number(((this.currentTimeProject - audPart.prevAllDuration)*audPart.part_speed_rate + audPart.from).toFixed(6));
              if (!!this.playCut.is_detached || !this.playCut.is_audio_only) {
                (document.getElementById(`video_${part.blends[0].pId}`) as HTMLVideoElement).muted = true;
              } else {
                (document.getElementById(`video_${part.blends[0].pId}`) as HTMLVideoElement).muted = false;
              }
              (document.getElementById(`audio_${audPart.pId}`) as HTMLAudioElement).volume = audPart.volume;
              this.changeRate((document.getElementById(`audio_${audPart.pId}`) as HTMLAudioElement), this.projectSpeed != 1 ? this.projectSpeed : audPart.part_speed_rate);
              (document.getElementById(`audio_${audPart.pId}`) as HTMLAudioElement).play();
            }
          });
        }
        (document.getElementById(`video_${part.blends[0].pId}`) as HTMLVideoElement).play();
        this.projectState$.next(true);
        this.updateProgress();
      } else {
        this.pauseProject();
      }
    } else if ((document.getElementById(`video_${part.pId}`) as HTMLVideoElement).paused) {
      if (!!this.isProjectPlaying) {
        this.pauseProject();
      }
      this.playCut = part;
      this.currentTimeProject = this.playCut.prevAllDuration;
      (document.getElementById(`video_${part.pId}`) as HTMLVideoElement).currentTime = Number(part.from.toFixed(6));
      this.changeRate((document.getElementById(`video_${part.pId}`) as HTMLVideoElement), this.projectSpeed != 1 ? this.projectSpeed : part.part_speed_rate);

      let audParts = this.tracks.find(x => x.type == 'audio') ? (this.tracks.filter(x => x.type == 'audio').map(u => u.parts) as any).flat() : [];
      if (audParts.length) {
        audParts.forEach((audPart: any, k) => {
          if (this.currentTimeProject >= audPart.prevAllDuration && this.currentTimeProject <= audPart.prevAllDuration + audPart.duration) {
            (document.getElementById(`audio_${audPart.pId}`) as HTMLAudioElement).currentTime = Number(((this.currentTimeProject - audPart.prevAllDuration)*audPart.part_speed_rate + audPart.from).toFixed(6));
            if (!!this.playCut.is_detached || !this.playCut.is_audio_only) {
              (document.getElementById(`video_${this.playCut.pId}`) as HTMLVideoElement).muted = true;
            } else {
              (document.getElementById(`video_${this.playCut.pId}`) as HTMLVideoElement).muted = false;
            }
            (document.getElementById(`audio_${audPart.pId}`) as HTMLAudioElement).volume = audPart.volume;
            this.changeRate((document.getElementById(`audio_${audPart.pId}`) as HTMLAudioElement), this.projectSpeed != 1 ? this.projectSpeed : audPart.part_speed_rate);
            (document.getElementById(`audio_${audPart.pId}`) as HTMLAudioElement).play();
          }
        });
  
      }

      (document.getElementById(`video_${part.pId}`) as HTMLVideoElement).play();
      this.projectState$.next(true);
      this.updateProgress();
    } else {
      this.pauseProject();
    }
  }

  onTimeUpdate(time: number) {
    this.currentTime = time;
  }

  close() {
    this.dialogRef.close({event: "close", data: false});
  }

  generateVideoFrame() {
    this.isCreateThumb = true;
    let part = (this.tracks.filter(x => x.type == 'video').map(k => k.parts) as any).flat(Infinity)[0];
    let videoElement = (document.getElementById(`video_${part.pId}`) as HTMLVideoElement);
    this.frame_ms = videoElement.currentTime;
    console.log("frame_ms", this.frame_ms)
    this.isCreateThumb = false;
    this.dialogRef.close({event: "frame", data: this.frame_ms});
  }

  // let con_arr = [concatVid.id, mixedAudio.id];
  // con_arr.push(...partToId.map(q => q.id))
  // con_arr.push(...originals.map(q => q.id))
  // con_arr.push(...audOriginals.map(q => q.id))
  // resAudioParts.forEach((element, resInd) => {
  //   element.resParts.forEach((resPart, index) => {
  //     con_arr.push(resPart.id);
  //   });
  // })
  // muxData.consist_of_video_project_file_id = con_arr.join(',')
  // consist_of_files_keys
  concatSaveWithAudio(is_draft:boolean = false) {
    let parts = (this.tracks.filter(x => x.type == 'video').map(k => k.parts) as any).flat(Infinity);
    let audParts = (this.tracks.filter(x => x.type == 'audio').map(k => k.parts) as any).flat(Infinity);
    this.isSubmit = true;
    let executeData:any = {
      inputs: {},
      filtergraphs: {},
    }

    let con_arr: any = []

    parts.forEach((part, i) => {
      if (!(Object.values(executeData.inputs) as any).filter(x => x.original_file_id == part.original_file_id).length) {
        executeData.inputs[`file_${part.original_file_id}`] = {
          original_file_id: part.original_file_id
        }
      }

      if (!con_arr.includes(`file_${part.original_file_id}`)) {
        con_arr.push(`file_${part.original_file_id}`)
      }
      if (!con_arr.includes(`ofile${i + 1}`)) {
        con_arr.push(`ofile${i + 1}`)
      }

      if (part.is_combine) {  
        let outsFg = {
          [`ovfile${i + 1}`]: {
            "options": [],
            "is_concat": 1,
            "consist_of_files_keys": `${Array.from(new Set(part.blends.map(blend => `file_${blend.original_file_id}`))).join(',')}`
          },
        }

        // [0:v][0:a][1:v][1:a]concat=n=2:v=1:a=1[outv][outa]
        let fg = `
`;
        let fgFinaly = ``
        let fgInputs:any = {}
        part.blends.forEach((blend, blendIndex) => { 
          if (!(Object.values(executeData.inputs) as any).filter(x => x.original_file_id == blend.original_file_id).length) {
            executeData.inputs[`file_${blend.original_file_id}`] = {
              original_file_id: blend.original_file_id
            }
          }

          if (!(Object.values(fgInputs) as any).filter(x => x.input == `file_${blend.original_file_id}`).length) {
            fgInputs[`[${(Object.values(fgInputs) as any).length}]`] = {
              options: [],
              input: `file_${blend.original_file_id}`
            }
          }

          if (!con_arr.includes(`file_${blend.original_file_id}`)) {
            con_arr.push(`file_${blend.original_file_id}`)
          }

          // if v file has video
          if (!!blend.is_video_only) {
            if (blendIndex == 0) {
              fg += `[${(Object.values(fgInputs) as any).findIndex(k => k.input == `file_${blend.original_file_id}`)}:v]`
            } else {
              fg += `;

[${(Object.values(fgInputs) as any).findIndex(k => k.input == `file_${blend.original_file_id}`)}:v]`
            }
            
    // if v file cuted
            if (blend.from != 0 || blend.to != blend.saveDuration) {
              fg += `
trim=${Math.round(blend.from*1000)}ms:end=${Math.round(blend.to*1000)}ms,`
            }
    
            fg+= `
setpts=PTS-STARTPTS,
format=yuv420p,`
    
            if (blend.is_changed) {
              if (blend.rd.params.part_rotate_deg != 0) {
                        fg += `
rotate='angle=${blend.rd.params.part_rotate_deg}*PI/180:out_w=max(in_w,in_h)*2:out_h=max(in_w,in_h)*2:fillcolor=black',`
              }
              fg += `
crop='out_w=${blend.rd.params.part_crop_width}:out_h=${blend.rd.params.part_crop_height}:x=${blend.rd.params.part_crop_x}:y=${blend.rd.params.part_crop_y}',
scale='width=${blend.rd.params.part_scale_width}:height=${blend.rd.params.part_scale_height}:force_original_aspect_ratio=decrease:force_divisible_by=2',
pad=width=${this.projectForm.value.width}:height=${this.projectForm.value.height}:x=${blend.rd.params.part_pad_x}:y=${blend.rd.params.part_pad_y},
setsar=sar=1`
            } else {
              fg += `
scale=width=${this.projectForm.value.width}:height=${this.projectForm.value.height}:force_original_aspect_ratio=decrease:force_divisible_by=2,
pad=width=${this.projectForm.value.width}:height=${this.projectForm.value.height}:x=${blend.rd.params.part_pad_x}:y=${blend.rd.params.part_pad_y},
setsar=sar=1`
            }
    
    
    // if v file rate changed
            if (blend.part_speed_rate != 1) {
              fg += `,
setpts=${1/blend.part_speed_rate}*PTS`
            }
    
            fg += `
[outv${i}b${blendIndex}]`
    
            // outsFg[`ofile${i + 1}`].options.push({
            //   "-map": `[outv${i}b${blendIndex}]`
            // })
          }  else {
            fg += `color=size=${this.projectForm.value.width}x${this.projectForm.value.height}:rate=25:d=${blend.duration/(1/blend.part_speed_rate)} 
[outv${i}b${blendIndex}]`
          }
          
          // if v file has audio
          if (!!blend.is_audio_only) {
            if (!!blend.is_video_only) {
              fg += `;`
            }
    
            fg += `
    
[${(Object.values(fgInputs) as any).findIndex(k => k.input == `file_${blend.original_file_id}`)}:a]`;
    
    // if v file cuted
            if (blend.from != 0 || blend.to != blend.saveDuration) {
              fg += `
atrim=start=${Math.round(blend.from*1000)}ms:end=${Math.round(blend.to*1000)}ms,
asetpts=PTS-STARTPTS,`
            }
    
    // if v file rate changed
            if (blend.part_speed_rate != 1) {
              fg += `
asetpts=${1/blend.part_speed_rate}*PTS,
${this.generateAtempoFilters(blend.part_speed_rate)},`
            }
    
            fg += `
aformat=channel_layouts=stereo`;
    
    // if v file volume changed
            if (blend.volume != 1) {
              fg += `,
volume=${blend.volume}`
            }
    
            fg += `
[outa${i}b${blendIndex}]`
    
            // outsFg[`ofile${i + 1}`].options.push({
            //   "-map": `[outa${i}b${blendIndex}]`
            // })
          } else {
            fg += `
anullsrc=channel_layout=stereo:sample_rate=44100:d=${0.1}
[outa${i}b${blendIndex}]`;
          }
          fgFinaly += `[outv${i}b${blendIndex}][outa${i}b${blendIndex}]`
        }) 

        fgFinaly += `concat=n=${part.blends.length}:v=1:a=1[outv${i}][outa${i}]`

        outsFg[`ovfile${i + 1}`].options.push({
          "-map": `[outv${i}]`
        })
        outsFg[`ovfile${i + 1}`].options.push({
          "-map": `[outa${i}]`
        })
        fg += `;

${fgFinaly}`;

        console.log('fgInputs', fgInputs)
        executeData.filtergraphs[`fg${i + 1}`] = {
          inputs: fgInputs,
          filtergraph: fg,
          outputs: outsFg
        } 
      } else {

        let fg = `
`;

        let outsFg = {
          [`ovfile${i + 1}`]: {
            "options": [],
            "is_part": 1,
            "part_of_files_keys": `file_${part.original_file_id}`
          },
        }
        // if v file has video
        if (!!part.is_video_only) {
          fg += `[0:v]`
          
  // if v file cuted
          if (part.from != 0 || part.to != part.saveDuration) {
            fg += `
trim=${Math.round(part.from*1000)}ms:end=${Math.round(part.to*1000)}ms,`
          }

          fg+= `
setpts=PTS-STARTPTS,
format=yuv420p,`

          if (part.is_changed) {
            if (part.rd.params.part_rotate_deg != 0) {
                      fg += `
rotate='angle=${part.rd.params.part_rotate_deg}*PI/180:out_w=max(in_w,in_h)*2:out_h=max(in_w,in_h)*2:fillcolor=black',`
            }
            fg += `
crop='out_w=${part.rd.params.part_crop_width}:out_h=${part.rd.params.part_crop_height}:x=${part.rd.params.part_crop_x}:y=${part.rd.params.part_crop_y}',
scale='width=${part.rd.params.part_scale_width}:height=${part.rd.params.part_scale_height}:force_original_aspect_ratio=decrease:force_divisible_by=2',
pad=width=${this.projectForm.value.width}:height=${this.projectForm.value.height}:x=${part.rd.params.part_pad_x}:y=${part.rd.params.part_pad_y},
setsar=sar=1`
          } else {
            fg += `
scale=width=${this.projectForm.value.width}:height=${this.projectForm.value.height}:force_original_aspect_ratio=decrease:force_divisible_by=2,
pad=width=${this.projectForm.value.width}:height=${this.projectForm.value.height}:x=${part.rd.params.part_pad_x}:y=${part.rd.params.part_pad_y},
setsar=sar=1`
          }


  // if v file rate changed
          if (part.part_speed_rate != 1) {
            fg += `,
setpts=${1/part.part_speed_rate}*PTS`
          }

          fg += `
[outv${i}]`
        } else {
          fg += `color=size=${this.projectForm.value.width}x${this.projectForm.value.height}:rate=25:d=${part.duration/(1/part.part_speed_rate)} 
[outv${i}]`
        }
        outsFg[`ovfile${i + 1}`].options.push({
          "-map": `[outv${i}]`
        })
        
        // if v file has audio
        if (!!part.is_audio_only) {

          fg += `;

[0:a]`;

  // if v file cuted
          if (part.from != 0 || part.to != part.saveDuration) {
            fg += `
atrim=start=${Math.round(part.from*1000)}ms:end=${Math.round(part.to*1000)}ms,
asetpts=PTS-STARTPTS,`
          }

  // if v file rate changed
          if (part.part_speed_rate != 1) {
            fg += `
asetpts=${1/part.part_speed_rate}*PTS,
${this.generateAtempoFilters(part.part_speed_rate)},`
          }

          fg += `
aformat=channel_layouts=stereo`;

  // if v file volume changed
          if (part.volume != 1) {
            fg += `,
volume=${part.volume}`
          }

          fg += `
[outa${i}]`

        } else {
          fg += `;

anullsrc=channel_layout=stereo:sample_rate=44100:d=${0.1}
[outa${i}]`;
        }

        outsFg[`ovfile${i + 1}`].options.push({
          "-map": `[outa${i}]`
        })
        executeData.filtergraphs[`fg${i + 1}`] = {
          inputs: {
            '[0]': {
              options: [],
              input: `file_${part.original_file_id}`
            }
          },
          filtergraph: fg,
          outputs: outsFg
        }
      }
    });

    // generate concat FG
    // [0:v][0:a][1:v][1:a]concat=n=2:v=1:a=1[outv][outa]
    // n - count of concated fragments
    // v - result video streams count
    // a - results audio streams count
    let vidOutputFg = {
      inputs: {},
      filtergraph: ``,
      outputs: {
        [`concatv`]: {
          "options": [
            {
              "-map": `[outv]`
            },
            {
              "-map": `[outa]`
            }
          ],
        }
      }
    }

    let ind:number = 0;
    Object.values(executeData.filtergraphs).forEach((fg:any, i) => {
      if (fg.outputs) {
        Object.keys(fg.outputs).forEach(outKey => {
          vidOutputFg.inputs[`[${ind}]`] = {
            options: [],
            input: `${outKey}`
          }

          let concatFG = ``;
          
          vidOutputFg.filtergraph += `[${ind}:v][${ind}:a]`
          ind++;
        })
      }
    })
    vidOutputFg.filtergraph += `concat=n=${ind}:v=1:a=1[outv][outa]`;
    // vidOutputFg.filtergraph += `[0:v][0:a][1:v]concat=n=2:v=1:a=1[outv][outa]`

    // set concat FG
    executeData.filtergraphs[`fg${Object.values(executeData.filtergraphs).length + 1}`] = vidOutputFg;


    // more then one audio
    if (audParts.length > 1) {
      
      // fg generation for all audio framents
      // trim=${x}ms:end=${y}ms
      // x - from
      // y - to
      
      audParts.forEach((aPart, i) => {
        if (!(Object.values(executeData.inputs) as any).filter(x => x.original_file_id == aPart.original_file_id).length) {
          executeData.inputs[`file_${aPart.original_file_id}`] = {
            original_file_id: aPart.original_file_id
          }
        }

        if (!con_arr.includes(`file_${aPart.original_file_id}`)) {
          con_arr.push(`file_${aPart.original_file_id}`)
        }

        let fg = `
[0:a]`;
        if (aPart.from != 0 || aPart.to != aPart.saveDuration) {
          fg += `
atrim=start=${Math.round(aPart.from*1000)}ms:end=${Math.round(aPart.to*1000)}ms,`
        }

        fg += `
asetpts=PTS-STARTPTS,`;

        if (aPart.prevAllDuration != 0) {
          fg += `
adelay=${Number((aPart.prevAllDuration*1000).toFixed(0))}|${Number((aPart.prevAllDuration*1000).toFixed(0))},`
        }

        fg += `
aformat=channel_layouts=stereo`;

        if (aPart.part_speed_rate != 1 || aPart.volume != 1) {
          fg += `,`
        }

        if (aPart.part_speed_rate != 1) {
          fg += `
asetpts=${1/aPart.part_speed_rate}*PTS,
${this.generateAtempoFilters(aPart.part_speed_rate)},`
        }

        if (aPart.volume != 1) {
          fg += `
volume=${aPart.volume}`
        }
        fg += `
[outa${i}]`

        executeData.filtergraphs[`fg${Object.values(executeData.filtergraphs).length + 1}`] = {
          inputs: {
            '[0]': {
              options: [],
              input: `file_${aPart.original_file_id}`
            }
          },
          filtergraph: fg,
          outputs: {
            [`oafile${i + 1}`]: {
              "options": [
                {
                  "-map": `[outa${i}]`
                }
              ],
              "is_part": 1,
              "part_of_files_keys": `file_${aPart.original_file_id}`
            },
          }
        }
        
      });
  
  
      // generate mix FG
      // amix=inputs=${k}:duration=longest:dropout_transition=3
      // k - count of mixed audio fragments
      // duration=longest - устанавливает длительность смешанного трека на основе самого длинного трека.
      // dropout_transition=3 — задает время на переход от одного трека к другому, чтобы избежать резких обрывов.
      let outputAudioFg = {
        inputs: {},
        filtergraph: ``,
        outputs: {
          [`mixa`]: {
            "options": [
              {
                "-map": `[outmixa]`
              }
            ],
            "is_concat": 1,
            "consist_of_files_keys": `${Array.from(new Set(audParts.map(audioPart => `file_${audioPart.original_file_id}`))).join(',')}`
          }
        }
      }
  
      let k:number = 0;
      Object.values(executeData.filtergraphs).forEach((fg:any, i) => {
        if (fg.outputs && i > parts.length) {
          Object.keys(fg.outputs).forEach((outKey) => {
            outputAudioFg.inputs[`[${k}]`] = {
              options: [],
              input: `${outKey}`
            }
            outputAudioFg.filtergraph += `[${k}:a]`
            k++;
          })
        }
      })
      outputAudioFg.filtergraph += `amix=inputs=${k}:duration=longest:dropout_transition=3[outmixa]`
  
      // set audio mix FG
      executeData.filtergraphs[`fg${Object.values(executeData.filtergraphs).length + 1}`] = outputAudioFg;
    } else {
      // only one audio

      if (!(Object.values(executeData.inputs) as any).filter(x => x.original_file_id == audParts[0].original_file_id).length) {
        executeData.inputs[`file_${audParts[0].original_file_id}`] = {
          original_file_id: audParts[0].original_file_id
        }
      }
      if (!con_arr.includes(`file_${audParts[0].original_file_id}`)) {
        con_arr.push(`file_${audParts[0].original_file_id}`)
      }
      let fg = `
      [0:a]`;
              if (audParts[0].from != 0 || audParts[0].to != audParts[0].saveDuration) {
                fg += `
      atrim=start=${Math.round(audParts[0].from*1000)}ms:end=${Math.round(audParts[0].to*1000)}ms,`
              }
      
              fg += `
      asetpts=PTS-STARTPTS,`;
      
              if (audParts[0].prevAllDuration != 0) {
                fg += `
      adelay=${Number((audParts[0].prevAllDuration*1000).toFixed(0))}|${Number((audParts[0].prevAllDuration*1000).toFixed(0))},`
              }
      
              fg += `
      aformat=channel_layouts=stereo`;
      
              if (audParts[0].part_speed_rate != 1 || audParts[0].volume != 1) {
                fg += `,`
              }
      
              if (audParts[0].part_speed_rate != 1) {
                fg += `
      asetpts=${1/audParts[0].part_speed_rate}*PTS,
      ${this.generateAtempoFilters(audParts[0].part_speed_rate)},`
              }
      
              if (audParts[0].volume != 1) {
                fg += `
      volume=${audParts[0].volume}`
              }
              fg += `
      [outonea]`

      let outputAudioFg = {
        inputs: {
          '[0]': {
            options: [],
            input: `file_${audParts[0].original_file_id}`
          }
        },
        filtergraph: fg,
        outputs: {
          [`mixa`]: {
            "options": [
              {
                "-map": `[outonea]`
              }
            ],
            "is_part": 1,
            "part_of_files_keys": `file_${audParts[0].original_file_id}`
          }
        }
      }
      executeData.filtergraphs[`fg${Object.values(executeData.filtergraphs).length + 1}`] = outputAudioFg;
    }

    let resFileFG = `
[0:a][1:a]amix=inputs=2:duration=longest:dropout_transition=3[resulta];`
if (this.is_save_audio.value) {
  resFileFG += `

  
[resulta] asplit=2 [resulta0][resulta1];

[0:v]copy[resultv]`
} else {
  resFileFG += `
[0:v]copy[resultv]`
}
    let resultFileFG:any = {
      inputs: {
        "[0]": {
          options: [],
          input: `concatv`
        },
        "[1]": {
          options: [],
          input: `mixa`
        }
      },
      filtergraph: resFileFG,
      outputs: {
        [`resultfile`]: {
          "options": [
            {
              "-map": `[resultv]`
            },
            {
              "-map": `[${this.is_save_audio.value ? 'resulta0' : 'resulta'}]`
            }
          ],
          "output_location": "/exported-files",
          "output_filename": (this.data.file.extension ? this.data.file.filename.replace('.'+this.data.file.extension, '') + `.${this.data.file.extension}`: this.data.file.filename),
          "output_content_type": this.data.file.content_type,
          "operation_reminder_id": this.vidProject.batch_data && this.vidProject.batch_data.operation_reminder_id ? this.vidProject.batch_data.operation_reminder_id : 0,
          "is_concat": 1,
          "consist_of_files_keys": con_arr.join(',')
        }
      }
    }

    if (this.is_save_audio.value) {
      resultFileFG.outputs[`resultfilea`] = {
        "options": [
          {
            "-map": `[resulta1]`
          }
        ],
        "output_location": "/exported-files",
        "operation_reminder_id": this.vidProject.batch_data && this.vidProject.batch_data.operation_reminder_id ? this.vidProject.batch_data.operation_reminder_id : 0,
        "output_filename": "audio-" + this.data.file.filename.split('.')[0] + '.m4a',
        "output_content_type": "audio/mp4",
        "is_part": 1,
        "part_of_files_keys": `resultfile`
      }
    }

    if (this.inheritTags && this.vidProject.batch_data && this.vidProject.batch_data.nestedTags) {
      let tagsData = [];
      let vidAndAudParts = (this.tracks.map(k => k.parts) as any).flat(Infinity);
      vidAndAudParts.forEach(part => {
        part.parameterValuesToTask.forEach(tag => {
          let pvi = !!tag.parameter_value_id ? tag.parameter_value_id : tag.id;
          if (this.vidProject.batch_data.nestedTags.includes(tag.parameter_id) && tagsData.filter(q => q.parameter_value_id == pvi).length == 0) {
            tagsData.push({
              parameter_value_id: pvi,
              is_primary: tag.is_primary
            })
          }
        });
      });

      resultFileFG.outputs[`resultfile`].parameter_values = tagsData
    }

    executeData.filtergraphs[`fg${Object.values(executeData.filtergraphs).length + 1}`] = resultFileFG;

    // con + a
    let editProjData:any = {
      name: this.vidProject.name,
      editor_version: this.version.toString(),
      output_location: this.data.file.location == "/" ? this.data.file.location + `cut files of ${this.data.file.filename.replace('.'+this.data.file.extension, '')}` : this.data.file.location + `/cut files of ${this.data.file.filename.replace('.'+this.data.file.extension, '')}`,
      output_width: this.projectForm.value.width,
      output_height: this.projectForm.value.height,
      output_frame_rate: this.rateForm.value.output_frame_rate,
      output_audio_bit_rate: this.rateForm.value.output_audio_bit_rate,
      output_bit_rate: this.rateForm.value.output_bit_rate * 1000000,
      waiting_time_limit: this.waiting_time_limit.value,
      execute_data: executeData
    }
    console.log("executeData", executeData)

    if (this.vidProject.batch_data && this.vidProject.batch_data.parameter_id && this.vidProject.batch_data.headCols && !!this.vidProject.batch_data.headCols.length) {
      let batchCards = {
        custom_id: 1,
        video_project_files_custom_ids: `resultfile`,
        task_channels: []
      }

      this.vidProject.batch_data.headCols.forEach(headCol => {
        let check = true;
        if (!!this.vidProject.batch_data.selectedCols) {
          if (!this.vidProject.batch_data.selectedCols.includes(headCol)) {
            check = false
          }
        }
        if (check) {
          if (this.publications && this.publications.length) {
            let publArr = []
            JSON.parse(JSON.stringify(this.publications, this.getCircularReplacer())).forEach(pub => {
              if (this.getChannelById(pub.channel_id).parameterValuesToChannel.filter(k => k.parameter_value_id == headCol).length) {
                let cont_name = ''
                if (parts[0].postNames && parts[0].postNames[headCol] && parts[0].postNames[headCol].name) {
                  cont_name = parts[0].postNames[headCol].name
                } else {
                  cont_name = this.form.value.name
                }
                pub.content_name = pub.content_type_id && this.isYouTube(pub.content_type_id) && cont_name.length > 100 ? cont_name.slice(0,97)+'...' : cont_name;
                pub.content_description = cont_name;
                delete pub.task_channel_files[0].video_project_file_id;
                // pub.task_channel_files[0].video_project_file_custom_id = 1;
                pub.task_channel_files[0].video_project_file_id = `resultfile`;

                if (pub.content_type_id && this.isTikTok(pub.content_type_id)) {
                  pub.publishing_params = {
                    privacy_level : 'PUBLIC_TO_EVERYONE',
                    disable_duet: true,
                    disable_stitch: true,
                    disable_comment: false,
                    brand_content_toggle: false,
                    brand_organic_toggle: false,
                  }
                }
                if (pub.content_type_id && this.isYouTube(pub.content_type_id)) {
                  pub.publishing_params = {
                    privacy : 'private',
                    made_for_kids: !!parts[0].made_for_kids ? 1 : 0
                  }
                }

                publArr.push(pub)
              }
            })

            batchCards.task_channels.push(publArr);
          }
        }
      });
      this.vidProject.batch_data.video_project_task_create = [batchCards];

      editProjData.batch_data = this.vidProject.batch_data;
    }
    // CHECKER)
    // this.isSubmit = false;
    // return 


    
    // edit project
    this.attachSubscriptions(
      this.fileService.resetVideoProject(this.vidProject.id, {}, this.data.company_id).pipe(
        switchMap(resetProject => {
          return this.fileService.editVideoProject(this.vidProject.id, editProjData, this.data.company_id).pipe(
            switchMap(project => {
              if (this.is_create_cards.value) {
                let formData = JSON.parse(JSON.stringify(this.form.value, this.getCircularReplacer()))
    
                delete formData.type
                formData.create_parameter_values_to_task = []
            
                this.parameters.filter(x => x.id != 0 && x.activeValues.length > 0).forEach(param => {
                  param.activeValues.forEach(el => {
                    formData.create_parameter_values_to_task.push({
                      parameter_id : param.id,
                      parameter_value_id : el.id
                    })
                  })
                })
    
                this.jobs.forEach(job => {
                  delete job.newExecutor;
                  job.create_parameter_values_to_task = []
                  if (job.parameters && job.parameters.length > 0) {
                    job.parameters[0].activeValues.forEach(el => {
                      job.create_parameter_values_to_task.push({
                        parameter_id : el.parameter_id,
                        parameter_value_id : el.id
                      })
                    })
                  }
    
                  delete job.mobEmployees;
                  delete job.parameters;
                })
    
                formData.create_task_operations = this.jobs.slice();
                let template;
                let template_id;
                if (this.form.value.type != 0) {
                  if (this.form.value.template_id) {
                    template_id = this.form.value.template_id
                  }
                  template = JSON.parse(JSON.stringify(this.getItemById(this.taskTemplates, this.form.get('template_id').value).template_data, this.getCircularReplacer()))
                }
    
                let cardData:any = {
                  video_project_id: project.id,
                  company_id: this.data.target_company_id,
                  template_data: this.form.value.type == 0 ? formData : Object.assign(template, {name: this.form.value.name}),
                  video_project_files: `resultfile`,
                  custom_id: 1
                }
                if (template_id) {
                  cardData.task_template_id = template_id;
                }
    
                if (this.vidProject.batch_data && this.vidProject.batch_data.parameter_id) {
                  let tag_id;
                  if (parts[0] && parts[0].parameterValuesToTask.find(q => this.vidProject.batch_data.parameter_id == q.parameter_id)) {
                    tag_id = parts[0].parameterValuesToTask.find(q => this.vidProject.batch_data.parameter_id == q.parameter_id).parameter_value_id;
                  }
                  let allHasOne:boolean = true;
    
                  if (tag_id) {
                    parts.forEach(q => {
                      if (q.parameterValuesToTask && q.parameterValuesToTask.length && q.parameterValuesToTask.filter(w => w.parameter_value_id == tag_id).length == 0) {
                        allHasOne = false;
                      }
                    });
    
                    if (allHasOne) {
                      if (this.publications && this.publications.length) {
                        let publArr = []
                        this.publications.forEach(pub => {
                          if (this.getChannelById(pub.channel_id).parameterValuesToChannel.filter(k => k.parameter_value_id == tag_id).length) {
                            
                            let cont_name = ''
                            if (parts[0].postNames && parts[0].postNames[0] && parts[0].postNames[0].name) {
                              cont_name = parts[0].postNames[0].name
                            } else {
                              cont_name = this.form.value.name
                            }
                            pub.content_name = pub.content_type_id && this.isYouTube(pub.content_type_id) && cont_name.length > 100 ? cont_name.slice(0,97)+'...' : cont_name;
                            pub.content_description = cont_name;
    
                            // pub.content_name = pub.content_type_id && this.isYouTube(pub.content_type_id) && this.form.value.name.length > 100 ? this.form.value.name.slice(0,97)+'...' : this.form.value.name;
                            // pub.content_description = this.form.value.name;
                            pub.task_channel_files[0].video_project_file_id = +cardData.video_project_files
    
                            if (pub.content_type_id && this.isTikTok(pub.content_type_id)) {
                              pub.publishing_params = {
                                privacy_level : 'PUBLIC_TO_EVERYONE',
                                disable_duet: true,
                                disable_stitch: true,
                                disable_comment: false,
                                brand_content_toggle: false,
                                brand_organic_toggle: false,
                              }
                            }
                            if (pub.content_type_id && this.isYouTube(pub.content_type_id)) {
                              pub.publishing_params = {
                                privacy : 'private',
                                made_for_kids: !!parts[0].made_for_kids ? 1 : 0
                              }
                            }
    
                            publArr.push(pub)
                          }
                        })
    
                        cardData.task_channels = publArr;
                      }
                    } else {
                      if (this.publications && this.publications.length) {
                        this.publications.forEach(pub => {
                          let cont_name = ''
                          if (parts[0].postNames && parts[0].postNames[0] && parts[0].postNames[0].name) {
                            cont_name = parts[0].postNames[0].name
                          } else {
                            cont_name = this.form.value.name
                          }
                          pub.content_name = pub.content_type_id && this.isYouTube(pub.content_type_id) && cont_name.length > 100 ? cont_name.slice(0,97)+'...' : cont_name;
                          pub.content_description = cont_name;
    
                          // pub.content_name = pub.content_type_id && this.isYouTube(pub.content_type_id) && this.form.value.name.length > 100 ? this.form.value.name.slice(0,97)+'...' : this.form.value.name;
                          // pub.content_description = this.form.value.name;
                          pub.task_channel_files[0].video_project_file_id = +cardData.video_project_files
    
                          if (pub.content_type_id && this.isTikTok(pub.content_type_id)) {
                            pub.publishing_params = {
                              privacy_level : 'PUBLIC_TO_EVERYONE',
                              disable_duet: true,
                              disable_stitch: true,
                              disable_comment: false,
                              brand_content_toggle: false,
                              brand_organic_toggle: false,
                            }
                          }
                          if (pub.content_type_id && this.isYouTube(pub.content_type_id)) {
                            pub.publishing_params = {
                              privacy : 'private',
                              made_for_kids: !!parts[0].made_for_kids ? 1 : 0
                            }
                          }
                        })
    
                        cardData.task_channels = this.publications;
                      }
                    }
                  } else {
                    if (this.publications && this.publications.length) {
                      this.publications.forEach(pub => {
                        let cont_name = ''
                        if (parts[0].postNames && parts[0].postNames[0] && parts[0].postNames[0].name) {
                          cont_name = parts[0].postNames[0].name
                        } else {
                          cont_name = this.form.value.name
                        }
                        pub.content_name = pub.content_type_id && this.isYouTube(pub.content_type_id) && cont_name.length > 100 ? cont_name.slice(0,97)+'...' : cont_name;
                        pub.content_description = cont_name;
    
                        // pub.content_name = pub.content_type_id && this.isYouTube(pub.content_type_id) && this.form.value.name.length > 100 ? this.form.value.name.slice(0,97)+'...' : this.form.value.name;
                        // pub.content_description = this.form.value.name;
                        pub.task_channel_files[0].video_project_file_id = cardData.video_project_files
    
                        if (pub.content_type_id && this.isTikTok(pub.content_type_id)) {
                          pub.publishing_params = {
                            privacy_level : 'PUBLIC_TO_EVERYONE',
                            disable_duet: true,
                            disable_stitch: true,
                            disable_comment: false,
                            brand_content_toggle: false,
                            brand_organic_toggle: false,
                          }
                        }
                        if (pub.content_type_id && this.isYouTube(pub.content_type_id)) {
                          pub.publishing_params = {
                            privacy : 'private',
                            made_for_kids: !!parts[0].made_for_kids ? 1 : 0
                          }
                        }
                      })
    
                      cardData.task_channels = this.publications;
                    }
                  }
                } else {
                  if (this.publications && this.publications.length) {
                    this.publications.forEach(pub => {
                      let cont_name = ''
                      if (parts[0].postNames && parts[0].postNames[0] && parts[0].postNames[0].name) {
                        cont_name = parts[0].postNames[0].name
                      } else {
                        cont_name = this.form.value.name
                      }
                      pub.content_name = pub.content_type_id && this.isYouTube(pub.content_type_id) && cont_name.length > 100 ? cont_name.slice(0,97)+'...' : cont_name;
                      pub.content_description = cont_name;
    
                      // pub.content_name = pub.content_type_id && this.isYouTube(pub.content_type_id) && this.form.value.name.length > 100 ? this.form.value.name.slice(0,97)+'...' : this.form.value.name;
                      // pub.content_description = this.form.value.name;
                      pub.task_channel_files[0].video_project_file_id = cardData.video_project_files
    
                      if (pub.content_type_id && this.isTikTok(pub.content_type_id)) {
                        pub.publishing_params = {
                          privacy_level : 'PUBLIC_TO_EVERYONE',
                          disable_duet: true,
                          disable_stitch: true,
                          disable_comment: false,
                          brand_content_toggle: false,
                          brand_organic_toggle: false,
                        }
                      }
                      if (pub.content_type_id && this.isYouTube(pub.content_type_id)) {
                        pub.publishing_params = {
                          privacy : 'private',
                          made_for_kids: !!parts[0].made_for_kids ? 1 : 0
                        }
                      }
                    })
    
                    cardData.task_channels = this.publications;
                  }
                }
    
                return this.fileService.createVideoProjectTaskFile(cardData, this.data.company_id).pipe(
                  switchMap(cardsReq => {
                    return this.fileService.editVideoProject(project.id, {
                      is_draft: !!is_draft ? 1 : 0,
                      is_to_process: !!is_draft ? 0 : 1,
                      is_batch_apply: !!project.batch_data && !is_draft ? 1 : 0 
                    }, this.data.company_id)
                  })
                )
              } else {
                return this.fileService.editVideoProject(project.id, {
                  is_draft: !!is_draft ? 1 : 0,
                  is_to_process: !!is_draft ? 0 : 1,
                  is_batch_apply: !!project.batch_data && !is_draft ? 1 : 0 
                }, this.data.company_id)
              }
            }),
            switchMap(res => {
              console.log("END switchMap", !!this.is_create_vid_relations.value || !!this.is_create_aud_relations.value)
              if (!!this.is_create_vid_relations.value || !!this.is_create_aud_relations.value) {
                let consistsIds = [];
    
                this.data.task.consistOfTasks.forEach(element => {
                  consistsIds.push(element.id)
                });
    
                let x = {
                  consist_of_task_id: [...consistsIds]
                }
                
                let addTasks = [];
    
                if (!!this.is_create_vid_relations.value) {
                  this.files.video.forEach((pFile) => {
                    if (addTasks.filter(x => x.task_id == pFile.task.id).length == 0 && pFile.task.id != this.data.task.id) {
                      addTasks.push(pFile)
                      x.consist_of_task_id.push(pFile.task_id)
                    }
                  });
                }
                if (!!this.is_create_aud_relations.value) {
                  this.files.audio.forEach((pFile) => {
                    if (addTasks.filter(x => x.task_id == pFile.task.id).length == 0 && pFile.task.id != this.data.task.id) {
                      addTasks.push(pFile)
                      x.consist_of_task_id.push(pFile.task_id)
                    }
                  });
                }
    
                // this.tracks.filter(t => (!!this.is_create_vid_relations.value && !!this.is_create_aud_relations.value) ? true : (!!this.is_create_vid_relations.value ? t.type == 'video' : t.type == 'audio')).forEach(trackEl => {
                //   trackEl.files.forEach((trackElFile) => {
                //     if (addTasks.filter(x => x.task_id != this.data.task.id).length == 0) {
                //       addTasks.push(trackElFile.file)
                //       x.consist_of_task_id.push(trackElFile.file.task_id)
                //     }
                //   });
                // })
    
                console.log("this.data.task.consist_of_count != x.consist_of_task_id.length", this.data.task.consist_of_count != x.consist_of_task_id.length)
                if (this.data.task.consist_of_count != x.consist_of_task_id.length) {
                  return this.taskService.editTask(this.data.task.id, x, this.data.target_company_id).pipe(
                    switchMap(task => {
                      let sortData = [];
      
                      x.consist_of_task_id.forEach((el, i) => {
                        sortData.push(
                          {
                            "path": '/api/task-partition/register/',
                            "query": {'company_id': this.data.target_company_id},
                            "method": "POST",
                            "body": {
                              [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
                              company_id: this.data.target_company_id,
                              consist_of_task_id: this.data.task.id,
                              part_of_task_id: el,
                              consist_of_order: i,
                              part_of_order: null
                            }
                          }
                        )
                      });
    
                      return this.taskService.multiRequest(sortData).pipe(map(() => res))
                    })
                  )
                } else {
                  return of(res)
                }
              } else {
                return of(res)
              }
            }),
            switchMap(res => {
              let savedTracks = JSON.parse(JSON.stringify(this.tracks, this.getCircularReplacer()));
              savedTracks.forEach((trackEl, trackInd) => {
                trackEl.parts.forEach((part, partInd) => {
                  delete part.blobSrc
                  delete part.subscr
                });
              })
              let savedFiles = JSON.parse(JSON.stringify(this.files, this.getCircularReplacer()));
              savedFiles.video.forEach(element => {
                delete element.blob
                delete element.blobSrc
              });
              savedFiles.audio.forEach(element => {
                delete element.blob
                delete element.blobSrc
              });
    
              let raw = {
                saveMode: this.saveMode.value,
                is_save_audio: this.is_save_audio.value,
                is_create_cards: this.is_create_cards.value,
                project: this.project,
                savedTags: this.savedTags,
                form: this.form.value,
                rateForm: this.rateForm.value,
                projectForm: this.projectForm.value,
                tracks: savedTracks,
                files: savedFiles,
                inheritTags: this.inheritTags,
                file: this.data.file,
                jobs: this.jobs,
                publications: this.publications
              }
    
              return ((!this.activeHistory || this.activeHistory.data != JSON.stringify(raw, this.getCircularReplacer())) ? this.fileService.saveVideoEditorHistory({
                company_id: this.data.target_company_id,
                video_project_id: this.vidProject.id,
                editor_version: this.version.toString(),
                parent_id: !!this.activeHistory ? this.activeHistory.id : 0,
                data: JSON.stringify(raw, this.getCircularReplacer()),
                is_active: 1
              }, this.data.company_id) : of(this.activeHistory))
            })
          )
        })).subscribe(resp => {
        console.log("save", resp)
        this.isSubmit = false;
        this.close();
      }, error => {
        this.layoutService.showSnackBar({name: ''}, marker(error), SnackBarItem)
        console.log('save error', error)
        this.isSubmit = false;
      })
    )
  }

  // let con_arr = [];
  // con_arr.push(...originals.map(q => q.id))
  // con_arr.push(...partToId.map(q => q.id))
  // concatData.consist_of_video_project_file_id = [...con_arr].join(',');;
  // concatData.input_video_project_file_id = partToId.map(q => q.id).join(',');
  // consist_of_files_keys
  concatSave(is_draft:boolean = false) {
    let parts = (this.tracks.filter(x => x.type == 'video').map(k => k.parts) as any).flat(Infinity);
    this.isSubmit = true;
    let executeData:any = {
      inputs: {},
      filtergraphs: {},
    }

    let con_arr = [];

    parts.forEach((part, i) => {
      if (!(Object.values(executeData.inputs) as any).filter(x => x.original_file_id == part.original_file_id).length) {
        executeData.inputs[`file_${part.original_file_id}`] = {
          original_file_id: part.original_file_id
        }
      }

      if (!con_arr.includes(`file_${part.original_file_id}`)) {
        con_arr.push(`file_${part.original_file_id}`)
      }
      if (!con_arr.includes(`ofile${i + 1}`)) {
        con_arr.push(`ofile${i + 1}`)
      }

      if (part.is_combine) {  
        let outsFg = {
          [`ofile${i + 1}`]: {
            "options": [],
            "is_concat": 1,
            "consist_of_files_keys": `${Array.from(new Set(part.blends.map(blend => `file_${blend.original_file_id}`))).join(',')}`
          },
        }

        // [0:v][0:a][1:v][1:a]concat=n=2:v=1:a=1[outv][outa]
        let fg = `
`;
        let fgFinaly = ``
        let fgInputs:any = {}
        part.blends.forEach((blend, blendIndex) => { 
          if (!(Object.values(executeData.inputs) as any).filter(x => x.original_file_id == blend.original_file_id).length) {
            executeData.inputs[`file_${blend.original_file_id}`] = {
              original_file_id: blend.original_file_id
            }
          }

          if (!(Object.values(fgInputs) as any).filter(x => x.input == `file_${blend.original_file_id}`).length) {
            fgInputs[`[${(Object.values(fgInputs) as any).length}]`] = {
              options: [],
              input: `file_${blend.original_file_id}`
            }
          }

          if (!con_arr.includes(`file_${blend.original_file_id}`)) {
            con_arr.push(`file_${blend.original_file_id}`)
          }


          // if v file has video
          if (!!blend.is_video_only) {
            if (blendIndex == 0) {
              fg += `[${(Object.values(fgInputs) as any).findIndex(k => k.input == `file_${blend.original_file_id}`)}:v]`
            } else {
              fg += `;

[${(Object.values(fgInputs) as any).findIndex(k => k.input == `file_${blend.original_file_id}`)}:v]`
            }
            
    // if v file cuted
            if (blend.from != 0 || blend.to != blend.saveDuration) {
              fg += `
trim=${Math.round(blend.from*1000)}ms:end=${Math.round(blend.to*1000)}ms,`
            }
    
            fg+= `
setpts=PTS-STARTPTS,
format=yuv420p,`
    
            if (blend.is_changed) {
              if (blend.rd.params.part_rotate_deg != 0) {
                        fg += `
rotate='angle=${blend.rd.params.part_rotate_deg}*PI/180:out_w=max(in_w,in_h)*2:out_h=max(in_w,in_h)*2:fillcolor=black',`
              }
              fg += `
crop='out_w=${blend.rd.params.part_crop_width}:out_h=${blend.rd.params.part_crop_height}:x=${blend.rd.params.part_crop_x}:y=${blend.rd.params.part_crop_y}',
scale='width=${blend.rd.params.part_scale_width}:height=${blend.rd.params.part_scale_height}:force_original_aspect_ratio=decrease:force_divisible_by=2',
pad=width=${this.projectForm.value.width}:height=${this.projectForm.value.height}:x=${blend.rd.params.part_pad_x}:y=${blend.rd.params.part_pad_y},
setsar=sar=1`
            } else {
              fg += `
scale=width=${this.projectForm.value.width}:height=${this.projectForm.value.height}:force_original_aspect_ratio=decrease:force_divisible_by=2,
pad=width=${this.projectForm.value.width}:height=${this.projectForm.value.height}:x=${blend.rd.params.part_pad_x}:y=${blend.rd.params.part_pad_y},
setsar=sar=1`
            }
    
    
    // if v file rate changed
            if (blend.part_speed_rate != 1) {
              fg += `,
setpts=${1/blend.part_speed_rate}*PTS`
            }
    
            fg += `
[outv${i}b${blendIndex}]`
    
            // outsFg[`ofile${i + 1}`].options.push({
            //   "-map": `[outv${i}b${blendIndex}]`
            // })
          } else {
            fg += `color=size=${this.projectForm.value.width}x${this.projectForm.value.height}:rate=25:d=${blend.duration/(1/blend.part_speed_rate)} 
[outv${i}b${blendIndex}]`
          }
          
          // if v file has audio
          if (!!blend.is_audio_only) {
            if (!!blend.is_video_only) {
              fg += `;`
            }
    
            fg += `
    
[${(Object.values(fgInputs) as any).findIndex(k => k.input == `file_${blend.original_file_id}`)}:a]`;
    
    // if v file cuted
            if (blend.from != 0 || blend.to != blend.saveDuration) {
              fg += `
atrim=start=${Math.round(blend.from*1000)}ms:end=${Math.round(blend.to*1000)}ms,
asetpts=PTS-STARTPTS,`
            }
    
    // if v file rate changed
            if (blend.part_speed_rate != 1) {
              fg += `
asetpts=${1/blend.part_speed_rate}*PTS,
${this.generateAtempoFilters(blend.part_speed_rate)},`
            }
    
            fg += `
aformat=channel_layouts=stereo`;
    
    // if v file volume changed
            if (blend.volume != 1) {
              fg += `,
volume=${blend.volume}`
            }
    
            fg += `
[outa${i}b${blendIndex}]`
    
            // outsFg[`ofile${i + 1}`].options.push({
            //   "-map": `[outa${i}b${blendIndex}]`
            // })
          } else {
            fg += `
anullsrc=channel_layout=stereo:sample_rate=44100:d=${0.1}
[outa${i}b${blendIndex}]`;
          }
          fgFinaly += `[outv${i}b${blendIndex}][outa${i}b${blendIndex}]`
        }) 

        fgFinaly += `concat=n=${part.blends.length}:v=1:a=1[outv${i}][outa${i}]`

        outsFg[`ofile${i + 1}`].options.push({
          "-map": `[outv${i}]`
        })
        outsFg[`ofile${i + 1}`].options.push({
          "-map": `[outa${i}]`
        })
        fg += `;

${fgFinaly}`;

        console.log('fgInputs', fgInputs)
        executeData.filtergraphs[`fg${i + 1}`] = {
          inputs: fgInputs,
          filtergraph: fg,
          outputs: outsFg
        } 
      } else {

        let fg = `
`;

        let outsFg = {
          [`ofile${i + 1}`]: {
            "options": [],
          },
        }
        // if v file has video
        if (!!part.is_video_only) {
          fg += `[0:v]`
          
  // if v file cuted
          if (part.from != 0 || part.to != part.saveDuration) {
            fg += `
trim=${Math.round(part.from*1000)}ms:end=${Math.round(part.to*1000)}ms,`
          }

          fg+= `
setpts=PTS-STARTPTS,
format=yuv420p,`

          if (part.is_changed) {
            if (part.rd.params.part_rotate_deg != 0) {
                      fg += `
rotate='angle=${part.rd.params.part_rotate_deg}*PI/180:out_w=max(in_w,in_h)*2:out_h=max(in_w,in_h)*2:fillcolor=black',`
            }
            fg += `
crop='out_w=${part.rd.params.part_crop_width}:out_h=${part.rd.params.part_crop_height}:x=${part.rd.params.part_crop_x}:y=${part.rd.params.part_crop_y}',
scale='width=${part.rd.params.part_scale_width}:height=${part.rd.params.part_scale_height}:force_original_aspect_ratio=decrease:force_divisible_by=2',
pad=width=${this.projectForm.value.width}:height=${this.projectForm.value.height}:x=${part.rd.params.part_pad_x}:y=${part.rd.params.part_pad_y},
setsar=sar=1`
          } else {
            fg += `
scale=width=${this.projectForm.value.width}:height=${this.projectForm.value.height}:force_original_aspect_ratio=decrease:force_divisible_by=2,
pad=width=${this.projectForm.value.width}:height=${this.projectForm.value.height}:x=${part.rd.params.part_pad_x}:y=${part.rd.params.part_pad_y},
setsar=sar=1`
          }


  // if v file rate changed
          if (part.part_speed_rate != 1) {
            fg += `,
setpts=${1/part.part_speed_rate}*PTS`
          }

          fg += `
[outv${i}]`
        } else {
          fg += `color=size=${this.projectForm.value.width}x${this.projectForm.value.height}:rate=25:d=${part.duration/(1/part.part_speed_rate)} 
[outv${i}]`
        }
        outsFg[`ofile${i + 1}`].options.push({
          "-map": `[outv${i}]`
        })
        
        // if v file has audio
        if (!!part.is_audio_only) {

          fg += `;

[0:a]`;

  // if v file cuted
          if (part.from != 0 || part.to != part.saveDuration) {
            fg += `
atrim=start=${Math.round(part.from*1000)}ms:end=${Math.round(part.to*1000)}ms,
asetpts=PTS-STARTPTS,`
          }

  // if v file rate changed
          if (part.part_speed_rate != 1) {
            fg += `
asetpts=${1/part.part_speed_rate}*PTS,
${this.generateAtempoFilters(part.part_speed_rate)},`
          }

          fg += `
aformat=channel_layouts=stereo`;

  // if v file volume changed
          if (part.volume != 1) {
            fg += `,
volume=${part.volume}`
          }

          fg += `
[outa${i}]`

        } else {
          fg += `;

anullsrc=channel_layout=stereo:sample_rate=44100:d=${0.1}
[outa${i}]`;

        }
        outsFg[`ofile${i + 1}`].options.push({
          "-map": `[outa${i}]`
        })
        
        executeData.filtergraphs[`fg${i + 1}`] = {
          inputs: {
            '[0]': {
              options: [],
              input: `file_${part.original_file_id}`
            }
          },
          filtergraph: fg,
          outputs: outsFg
        }
      }
    });

    // generate concat FG
    // [0:v][0:a][1:v][1:a]concat=n=2:v=1:a=1[outv][outa]
    // n - count of concated fragments
    // v - result video streams count
    // a - results audio streams count
    let outputFg:any = {
      inputs: {},
      filtergraph: ``,
      outputs: {
        [`resultfile`]: {
          "options": [
            {
              "-map": `[outv]`
            },
            {
              "-map": `[${this.is_save_audio.value ? 'outa0' : 'outa'}]`
            }
          ],
          "output_location": "/exported-files",
          "output_filename": this.data.file.extension ? this.data.file.filename.replace('.'+this.data.file.extension, '') + `.${this.data.file.extension}`: this.data.file.filename,
          "output_content_type": this.data.file.content_type,
          "operation_reminder_id": this.vidProject.batch_data && this.vidProject.batch_data.operation_reminder_id ? this.vidProject.batch_data.operation_reminder_id : 0,
          "is_concat": 1,
          "consist_of_files_keys": con_arr.join(',')
        }
      }
    }

    if (this.is_save_audio.value) {
      outputFg.outputs[`resultfilea`] = {
        "options": [
          {
            "-map": `[outa1]`
          }
        ],
        "output_location": "/exported-files",
        "is_part": 1,
        "part_of_files_keys": `resultfile`,
        "output_filename": "audio-" + this.data.file.filename.split('.')[0] + '.m4a',
        "output_content_type": "audio/mp4"
      }
    }

    if (this.inheritTags && this.vidProject.batch_data && this.vidProject.batch_data.nestedTags) {
      let tagsData = [];
      let vidAndAudParts = (this.tracks.map(k => k.parts) as any).flat(Infinity);
      vidAndAudParts.forEach(part => {
        part.parameterValuesToTask.forEach(tag => {
          let pvi = !!tag.parameter_value_id ? tag.parameter_value_id : tag.id;
          if (this.vidProject.batch_data.nestedTags.includes(tag.parameter_id) && tagsData.filter(q => q.parameter_value_id == pvi).length == 0) {
            tagsData.push({
              parameter_value_id: pvi,
              is_primary: tag.is_primary
            })
          }
        });
      });

      outputFg.outputs[`resultfile`].parameter_values = tagsData
    }

    let k:number = 0;
    Object.values(executeData.filtergraphs).forEach((fg:any, i) => {
      if (fg.outputs) {
        Object.keys(fg.outputs).forEach(outKey => {
          outputFg.inputs[`[${k}]`] = {
            options: [],
            input: `${outKey}`
          }
          outputFg.filtergraph += `[${k}:v][${k}:a]`
          k++;
        })
      }
    })

    let resFileFG = `concat=n=${k}:v=1:a=1[outv][outa]`
    if (this.is_save_audio.value) {
      resFileFG += `;

[outa] asplit=2 [outa0][outa1]`
    }

    outputFg.filtergraph += resFileFG


    // set concat FG
    executeData.filtergraphs[`fg${Object.values(executeData.filtergraphs).length + 1}`] = outputFg;
    // con
    let editProjData:any = {
      name: this.vidProject.name,
      editor_version: this.version.toString(),
      output_location: this.data.file.location == "/" ? this.data.file.location + `cut files of ${this.data.file.filename.replace('.'+this.data.file.extension, '')}` : this.data.file.location + `/cut files of ${this.data.file.filename.replace('.'+this.data.file.extension, '')}`,
      output_width: this.projectForm.value.width,
      output_height: this.projectForm.value.height,
      output_frame_rate: this.rateForm.value.output_frame_rate,
      output_audio_bit_rate: this.rateForm.value.output_audio_bit_rate,
      output_bit_rate: this.rateForm.value.output_bit_rate * 1000000,
      waiting_time_limit: this.waiting_time_limit.value,
      execute_data: executeData
    }
    console.log("executeData", executeData)

    // CHECKER)
    // this.isSubmit = false;
    // return 

    if (this.vidProject.batch_data && this.vidProject.batch_data.parameter_id && this.vidProject.batch_data.headCols && !!this.vidProject.batch_data.headCols.length) {
      let batchCards = {
        custom_id: 1,
        video_project_files_custom_ids: `resultfile`,
        task_channels: []
      }

      this.vidProject.batch_data.headCols.forEach(headCol => {
        let check = true;
        if (!!this.vidProject.batch_data.selectedCols) {
          if (!this.vidProject.batch_data.selectedCols.includes(headCol)) {
            check = false
          }
        }
        if (check) {
          if (this.publications && this.publications.length) {
            let publArr = []
            JSON.parse(JSON.stringify(this.publications, this.getCircularReplacer())).forEach(pub => {
              if (this.getChannelById(pub.channel_id).parameterValuesToChannel.filter(k => k.parameter_value_id == headCol).length) {
                let cont_name = ''
                if (parts[0].postNames && parts[0].postNames[headCol] && parts[0].postNames[headCol].name) {
                  cont_name = parts[0].postNames[headCol].name
                } else {
                  cont_name = this.form.value.name
                }
                pub.content_name = pub.content_type_id && this.isYouTube(pub.content_type_id) && cont_name.length > 100 ? cont_name.slice(0,97)+'...' : cont_name;
                pub.content_description = cont_name;
                delete pub.task_channel_files[0].video_project_file_id;
                // pub.task_channel_files[0].video_project_file_custom_id = 1;
                pub.task_channel_files[0].video_project_file_id = `resultfile`;

                if (pub.content_type_id && this.isTikTok(pub.content_type_id)) {
                  pub.publishing_params = {
                    privacy_level : 'PUBLIC_TO_EVERYONE',
                    disable_duet: true,
                    disable_stitch: true,
                    disable_comment: false,
                    brand_content_toggle: false,
                    brand_organic_toggle: false,
                  }
                }

                                          
                if (pub.content_type_id && this.isYouTube(pub.content_type_id)) {
                  pub.publishing_params = {
                    privacy : 'private',
                    made_for_kids: !!parts[0].made_for_kids ? 1 : 0
                  }
                }

                publArr.push(pub)
              }
            })

            batchCards.task_channels.push(publArr);
          }
        }
      });
      this.vidProject.batch_data.video_project_task_create = [batchCards];

      editProjData.batch_data = this.vidProject.batch_data;
    }

    // edit project
    this.attachSubscriptions(
      this.fileService.resetVideoProject(this.vidProject.id, {}, this.data.company_id).pipe(
        switchMap(resetProject => {
          return this.fileService.editVideoProject(this.vidProject.id, editProjData, this.data.company_id).pipe(
            switchMap(project => {
              if (this.is_create_cards.value) {
                let formData = JSON.parse(JSON.stringify(this.form.value, this.getCircularReplacer()))
    
                delete formData.type
                formData.create_parameter_values_to_task = []
            
                this.parameters.filter(x => x.id != 0 && x.activeValues.length > 0).forEach(param => {
                  param.activeValues.forEach(el => {
                    formData.create_parameter_values_to_task.push({
                      parameter_id : param.id,
                      parameter_value_id : el.id
                    })
                  })
                })
    
                this.jobs.forEach(job => {
                  delete job.newExecutor;
                  job.create_parameter_values_to_task = []
                  if (job.parameters && job.parameters.length > 0) {
                    job.parameters[0].activeValues.forEach(el => {
                      job.create_parameter_values_to_task.push({
                        parameter_id : el.parameter_id,
                        parameter_value_id : el.id
                      })
                    })
                  }
    
                  delete job.mobEmployees;
                  delete job.parameters;
                })
    
                formData.create_task_operations = this.jobs.slice();
                let template;
                let template_id;
                if (this.form.value.type != 0) {
                  if (this.form.value.template_id) {
                    template_id = this.form.value.template_id
                  }
                  template = JSON.parse(JSON.stringify(this.getItemById(this.taskTemplates, this.form.get('template_id').value).template_data, this.getCircularReplacer()))
                }
    
                let cardData:any = {
                  video_project_id: project.id,
                  company_id: this.data.target_company_id,
                  template_data: this.form.value.type == 0 ? formData : Object.assign(template, {name: this.form.value.name}),
                  video_project_files: `resultfile`,
                  custom_id: 1
                }
                if (template_id) {
                  cardData.task_template_id = template_id;
                }
    
                if (this.vidProject.batch_data && this.vidProject.batch_data.parameter_id) {
                  let tag_id;
                  if (parts[0] && parts[0].parameterValuesToTask.find(q => this.vidProject.batch_data.parameter_id == q.parameter_id)) {
                    tag_id = parts[0].parameterValuesToTask.find(q => this.vidProject.batch_data.parameter_id == q.parameter_id).parameter_value_id;
                  }
                  let allHasOne:boolean = true;
    
                  if (tag_id) {
                    parts.forEach(q => {
                      if (q.parameterValuesToTask && q.parameterValuesToTask.length && q.parameterValuesToTask.filter(w => w.parameter_value_id == tag_id).length == 0) {
                        allHasOne = false;
                      }
                    });
    
                    if (allHasOne) {
                      if (this.publications && this.publications.length) {
                        let publArr = []
                        this.publications.forEach(pub => {
                          if (this.getChannelById(pub.channel_id).parameterValuesToChannel.filter(k => k.parameter_value_id == tag_id).length) {
                            
                            let cont_name = ''
                            if (parts[0].postNames && parts[0].postNames[0] && parts[0].postNames[0].name) {
                              cont_name = parts[0].postNames[0].name
                            } else {
                              cont_name = this.form.value.name
                            }
                            pub.content_name = pub.content_type_id && this.isYouTube(pub.content_type_id) && cont_name.length > 100 ? cont_name.slice(0,97)+'...' : cont_name;
                            pub.content_description = cont_name;
    
                            // pub.content_name = pub.content_type_id && this.isYouTube(pub.content_type_id) && this.form.value.name.length > 100 ? this.form.value.name.slice(0,97)+'...' : this.form.value.name;
                            // pub.content_description = this.form.value.name;
                            pub.task_channel_files[0].video_project_file_id = cardData.video_project_files
    
                            if (pub.content_type_id && this.isTikTok(pub.content_type_id)) {
                              pub.publishing_params = {
                                privacy_level : 'PUBLIC_TO_EVERYONE',
                                disable_duet: true,
                                disable_stitch: true,
                                disable_comment: false,
                                brand_content_toggle: false,
                                brand_organic_toggle: false,
                              }
                            }
                            if (pub.content_type_id && this.isYouTube(pub.content_type_id)) {
                              pub.publishing_params = {
                                privacy : 'private',
                                made_for_kids: !!parts[0].made_for_kids ? 1 : 0
                              }
                            }
    
                            publArr.push(pub)
                          }
                        })
    
                        cardData.task_channels = publArr;
                      }
                    } else {
                      if (this.publications && this.publications.length) {
                        this.publications.forEach(pub => {
                          let cont_name = ''
                          if (parts[0].postNames && parts[0].postNames[0] && parts[0].postNames[0].name) {
                            cont_name = parts[0].postNames[0].name
                          } else {
                            cont_name = this.form.value.name
                          }
                          pub.content_name = pub.content_type_id && this.isYouTube(pub.content_type_id) && cont_name.length > 100 ? cont_name.slice(0,97)+'...' : cont_name;
                          pub.content_description = cont_name;
    
                          // pub.content_name = pub.content_type_id && this.isYouTube(pub.content_type_id) && this.form.value.name.length > 100 ? this.form.value.name.slice(0,97)+'...' : this.form.value.name;
                          // pub.content_description = this.form.value.name;
                          pub.task_channel_files[0].video_project_file_id = cardData.video_project_files
    
                          if (pub.content_type_id && this.isTikTok(pub.content_type_id)) {
                            pub.publishing_params = {
                              privacy_level : 'PUBLIC_TO_EVERYONE',
                              disable_duet: true,
                              disable_stitch: true,
                              disable_comment: false,
                              brand_content_toggle: false,
                              brand_organic_toggle: false,
                            }
                          }
                          if (pub.content_type_id && this.isYouTube(pub.content_type_id)) {
                            pub.publishing_params = {
                              privacy : 'private',
                              made_for_kids: !!parts[0].made_for_kids ? 1 : 0
                            }
                          }
                        })
    
                        cardData.task_channels = this.publications;
                      }
                    }
                  } else {
                    if (this.publications && this.publications.length) {
                      this.publications.forEach(pub => {
                        let cont_name = ''
                        if (parts[0].postNames && parts[0].postNames[0] && parts[0].postNames[0].name) {
                          cont_name = parts[0].postNames[0].name
                        } else {
                          cont_name = this.form.value.name
                        }
                        pub.content_name = pub.content_type_id && this.isYouTube(pub.content_type_id) && cont_name.length > 100 ? cont_name.slice(0,97)+'...' : cont_name;
                        pub.content_description = cont_name;
    
                        // pub.content_name = pub.content_type_id && this.isYouTube(pub.content_type_id) && this.form.value.name.length > 100 ? this.form.value.name.slice(0,97)+'...' : this.form.value.name;
                        // pub.content_description = this.form.value.name;
                        pub.task_channel_files[0].video_project_file_id = cardData.video_project_files
    
                        if (pub.content_type_id && this.isTikTok(pub.content_type_id)) {
                          pub.publishing_params = {
                            privacy_level : 'PUBLIC_TO_EVERYONE',
                            disable_duet: true,
                            disable_stitch: true,
                            disable_comment: false,
                            brand_content_toggle: false,
                            brand_organic_toggle: false,
                          }
                        }
                        if (pub.content_type_id && this.isYouTube(pub.content_type_id)) {
                          pub.publishing_params = {
                            privacy : 'private',
                            made_for_kids: !!parts[0].made_for_kids ? 1 : 0
                          }
                        }
                      })
    
                      cardData.task_channels = this.publications;
                    }
                  }
                } else {
                  if (this.publications && this.publications.length) {
                    this.publications.forEach(pub => {
                      let cont_name = ''
                      if (parts[0].postNames && parts[0].postNames[0] && parts[0].postNames[0].name) {
                        cont_name = parts[0].postNames[0].name
                      } else {
                        cont_name = this.form.value.name
                      }
                      pub.content_name = pub.content_type_id && this.isYouTube(pub.content_type_id) && cont_name.length > 100 ? cont_name.slice(0,97)+'...' : cont_name;
                      pub.content_description = cont_name;
    
                      // pub.content_name = pub.content_type_id && this.isYouTube(pub.content_type_id) && this.form.value.name.length > 100 ? this.form.value.name.slice(0,97)+'...' : this.form.value.name;
                      // pub.content_description = this.form.value.name;
                      pub.task_channel_files[0].video_project_file_id = cardData.video_project_files
    
                      if (pub.content_type_id && this.isTikTok(pub.content_type_id)) {
                        pub.publishing_params = {
                          privacy_level : 'PUBLIC_TO_EVERYONE',
                          disable_duet: true,
                          disable_stitch: true,
                          disable_comment: false,
                          brand_content_toggle: false,
                          brand_organic_toggle: false,
                        }
                      }
                      if (pub.content_type_id && this.isYouTube(pub.content_type_id)) {
                        pub.publishing_params = {
                          privacy : 'private',
                          made_for_kids: !!parts[0].made_for_kids ? 1 : 0
                        }
                      }
                    })
    
                    cardData.task_channels = this.publications;
                  }
                }
    
                return this.fileService.createVideoProjectTaskFile(cardData, this.data.company_id).pipe(
                  switchMap(cardsReq => {
                    return this.fileService.editVideoProject(project.id, {
                      is_draft: !!is_draft ? 1 : 0,
                      is_to_process: !!is_draft ? 0 : 1,
                      is_batch_apply: !!project.batch_data && !is_draft ? 1 : 0 
                    }, this.data.company_id)
                  })
                )
              } else {
                return this.fileService.editVideoProject(project.id, {
                  is_draft: !!is_draft ? 1 : 0,
                  is_to_process: !!is_draft ? 0 : 1,
                  is_batch_apply: !!project.batch_data && !is_draft ? 1 : 0 
                }, this.data.company_id)
              }
            }),
            switchMap(res => {
              console.log("END switchMap", !!this.is_create_vid_relations.value || !!this.is_create_aud_relations.value)
              if (!!this.is_create_vid_relations.value || !!this.is_create_aud_relations.value) {
                let consistsIds = [];
    
                this.data.task.consistOfTasks.forEach(element => {
                  consistsIds.push(element.id)
                });
    
                let x = {
                  consist_of_task_id: [...consistsIds]
                }
                
                let addTasks = [];
    
                if (!!this.is_create_vid_relations.value) {
                  this.files.video.forEach((pFile) => {
                    if (addTasks.filter(x => x.task_id == pFile.task.id).length == 0 && pFile.task.id != this.data.task.id) {
                      addTasks.push(pFile)
                      x.consist_of_task_id.push(pFile.task_id)
                    }
                  });
                }
                if (!!this.is_create_aud_relations.value) {
                  this.files.audio.forEach((pFile) => {
                    if (addTasks.filter(x => x.task_id == pFile.task.id).length == 0 && pFile.task.id != this.data.task.id) {
                      addTasks.push(pFile)
                      x.consist_of_task_id.push(pFile.task_id)
                    }
                  });
                }
    
                // this.tracks.filter(t => (!!this.is_create_vid_relations.value && !!this.is_create_aud_relations.value) ? true : (!!this.is_create_vid_relations.value ? t.type == 'video' : t.type == 'audio')).forEach(trackEl => {
                //   trackEl.files.forEach((trackElFile) => {
                //     if (addTasks.filter(x => x.task_id != this.data.task.id).length == 0) {
                //       addTasks.push(trackElFile.file)
                //       x.consist_of_task_id.push(trackElFile.file.task_id)
                //     }
                //   });
                // })
    
                console.log("this.data.task.consist_of_count != x.consist_of_task_id.length", this.data.task.consist_of_count != x.consist_of_task_id.length)
                if (this.data.task.consist_of_count != x.consist_of_task_id.length) {
                  return this.taskService.editTask(this.data.task.id, x, this.data.target_company_id).pipe(
                    switchMap(task => {
                      let sortData = [];
      
                      x.consist_of_task_id.forEach((el, i) => {
                        sortData.push(
                          {
                            "path": '/api/task-partition/register/',
                            "query": {'company_id': this.data.target_company_id},
                            "method": "POST",
                            "body": {
                              [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
                              company_id: this.data.target_company_id,
                              consist_of_task_id: this.data.task.id,
                              part_of_task_id: el,
                              consist_of_order: i,
                              part_of_order: null
                            }
                          }
                        )
                      });
    
                      return this.taskService.multiRequest(sortData).pipe(map(() => res))
                    })
                  )
                } else {
                  return of(res)
                }
              } else {
                return of(res)
              }
            }),
            switchMap(res => {
              let savedTracks = JSON.parse(JSON.stringify(this.tracks, this.getCircularReplacer()));
              savedTracks.forEach((trackEl, trackInd) => {
                trackEl.parts.forEach((part, partInd) => {
                  delete part.blobSrc
                  delete part.subscr
                });
              })
              let savedFiles = JSON.parse(JSON.stringify(this.files, this.getCircularReplacer()));
              savedFiles.video.forEach(element => {
                delete element.blob
                delete element.blobSrc
              });
              savedFiles.audio.forEach(element => {
                delete element.blob
                delete element.blobSrc
              });
    
              let raw = {
                saveMode: this.saveMode.value,
                is_save_audio: this.is_save_audio.value,
                is_create_cards: this.is_create_cards.value,
                project: this.project,
                savedTags: this.savedTags,
                form: this.form.value,
                rateForm: this.rateForm.value,
                projectForm: this.projectForm.value,
                tracks: savedTracks,
                files: savedFiles,
                inheritTags: this.inheritTags,
                file: this.data.file,
                jobs: this.jobs,
                publications: this.publications
              }
    
              return ((!this.activeHistory || this.activeHistory.data != JSON.stringify(raw, this.getCircularReplacer())) ? this.fileService.saveVideoEditorHistory({
                company_id: this.data.target_company_id,
                video_project_id: this.vidProject.id,
                editor_version: this.version.toString(),
                parent_id: !!this.activeHistory ? this.activeHistory.id : 0,
                data: JSON.stringify(raw, this.getCircularReplacer()),
                is_active: 1
              }, this.data.company_id) : of(this.activeHistory))
            })
          )
        })
      ).subscribe(resp => {
        console.log("save", resp)
        this.isSubmit = false;
        this.close();
      }, error => {
        this.layoutService.showSnackBar({name: ''}, marker(error), SnackBarItem)
        console.log('save error', error)
        this.isSubmit = false;
      })
    )
  }

  partsSaveWithAudio(is_draft:boolean = false) {
    let parts = (this.tracks.filter(x => x.type == 'video').map(k => k.parts) as any).flat(Infinity);
    let audParts = (this.tracks.filter(x => x.type == 'audio').map(k => k.parts) as any).flat(Infinity);
    this.isSubmit = true;
    let executeData:any = {
      inputs: {},
      input_text_files: {},
      filtergraphs: {},
    }

    parts.forEach((part, i) => {
      if (!(Object.values(executeData.inputs) as any).filter(x => x.original_file_id == part.original_file_id).length) {
        executeData.inputs[`file_${part.original_file_id}`] = {
          original_file_id: part.original_file_id
        }
      }

      if (part.subs_on) {
        let content = `
[Script Info]
Title: Reports subtitles
ScriptType: v4.00+
PlayResX: ${this.projectForm.value.width}
PlayResY: ${this.projectForm.value.height}
WrapStyle: 0
ScaledBorderAndShadow: yes
Collisions: Normal
Video Aspect Ratio: 0
Video Zoom: 6
Video Position: 0

[V4+ Styles]
Format: Name, Fontname, Fontsize, PrimaryColour, SecondaryColour, OutlineColour, BackColour, Bold, Italic, Underline, StrikeOut, ScaleX, ScaleY, Spacing, Angle, BorderStyle, Outline, Shadow, Alignment, MarginL, MarginR, MarginV, Encoding
Style: Default,Roboto,${!!part.subSet && !!part.subSet.fontSize ? part.subSet.fontSize : "28"},${!!part.subSet && !!part.subSet.pastColor ? this.rgbaToReverseHex(part.subSet.pastColor) : "&H00FFFFFF"},${!!part.subSet && !!part.subSet.futureColor ? this.rgbaToReverseHex(part.subSet.futureColor) : "&H00808080"},${!!part.subSet && !!part.subSet.outlineColor ? this.rgbaToReverseHex(part.subSet.outlineColor) : "&H00000000"},&H00000000,${part.subSet.bold || "1"},${part.subSet.italic || "0"},${part.subSet.undeline || "0"},${part.subSet.strike || "0"},100,100,0,0,1,${part.subSet.outline || "2"},0,5,${Math.ceil((+part.rd.left + +part.subSet.pos.x)/this.project.percentX)},${Math.ceil((+part.rd.top + +part.subSet.pos.y)/this.project.percentY) > this.projectForm.value.height / 2 ? Math.ceil(this.projectForm.value.height - (+part.rd.top + +part.subSet.pos.y + part.subSet.pos.height)/this.project.percentY) > 0 ? Math.ceil(this.projectForm.value.height - (+part.rd.top + +part.subSet.pos.y + part.subSet.pos.height)/this.project.percentY) : '0' : Math.ceil((+part.rd.top + +part.subSet.pos.y)/this.project.percentY)},0,1

[Events]
`
        part.subs.filter(k => (k.from >= part.from || k.to >= part.from) && (k.to <= part.to)).forEach(subtitle => {
  
          if (subtitle.words && part.subSet && !!part.subSet.is_karaoke) {
            subtitle.words.forEach((word:any, wordInd:number) => {
              // let from;
              // let to = word.end_ts;
              // if (wordInd == 0) {
              //   from = word.ts
              // } else {
              //   from = subtitle.words[wordInd-1].end_ts
              // }
              const startTime = this.convertToAssTime(+this.useSpeedRate(word.from - part.from, part));
              const endTime = this.convertToAssTime(+this.useSpeedRate(word.to - part.from, part));
              let x = Math.ceil((+part.rd.left + +part.subSet.pos.x + (part.subSet.pos.width/2))/this.project.percentX);
              let y = Math.ceil((+part.rd.top + +part.subSet.pos.y + (part.subSet.pos.height/2))/this.project.percentY);
              content += `Dialogue: 0,${startTime},${endTime},Default,,0,0,0,,{\\pos(${x}, ${y})}`;

              let text = ``;
              subtitle.words.forEach((wordIN, wordINInd) => {
                if (wordIN.value) {
                  // let fromIN;
                  // let toIN = wordIN.end_ts;
                  // if (wordINInd == 0) {
                  //   fromIN = wordIN.ts
                  // } else {
                  //   fromIN = subtitle.words[wordINInd-1].end_ts
                  // }

                  if (wordINInd > 0) {
                    text += " ";
                  }
                  text += wordINInd >= wordInd ? `{\\kf${Math.ceil((wordIN.to-wordIN.from)*100)}\\c${!!part.subSet && !!part.subSet.activeColor ? this.rgbaToReverseHex(part.subSet.activeColor) : "&H0000FFFF"};}` : "";
                  text += wordIN.value;
                }
              });
              content += `${text}\n`
            });

            content += `\n\n`
          } else {
            const startTime = this.convertToAssTime(+this.useSpeedRate(subtitle.from - part.from, part));
            const endTime = this.convertToAssTime(+this.useSpeedRate(subtitle.to - part.from, part));
            let x = Math.ceil((+part.rd.left + +part.subSet.pos.x + (part.subSet.pos.width/2))/this.project.percentX);
            let y = Math.ceil((+part.rd.top + +part.subSet.pos.y + (part.subSet.pos.height/2))/this.project.percentY);
            content += `Dialogue: 0,${startTime},${endTime},Default,,0,0,0,,{\\pos(${x}, ${y})}${subtitle.text}\n`;
          }
        });

        executeData.input_text_files[`text_file_${i}`] = {
          content: content
        }
        console.log("content TEST", content);
      }

      if (!part.is_video_only && !part.is_audio_only) {
        return
      }
      if (part.is_combine) {  
        let outsFg:any = {
          [`ovfile${i + 1}`]: {
            "options": [],
            "is_concat": 1,
            "consist_of_files_keys": `${Array.from(new Set(part.blends.map(blend => `file_${blend.original_file_id}`))).join(',')}`
          },
        }

        // [0:v][0:a][1:v][1:a]concat=n=2:v=1:a=1[outv][outa]
        let fg = `
`;
        let fgFinaly = ``
        let fgInputs:any = {};
        // let keys = [];
        part.blends.forEach((blend, blendIndex) => { 
          if (!(Object.values(executeData.inputs) as any).filter(x => x.original_file_id == blend.original_file_id).length) {
            executeData.inputs[`file_${blend.original_file_id}`] = {
              original_file_id: blend.original_file_id
            }
          }

          if (!(Object.values(fgInputs) as any).filter(x => x.input == `file_${blend.original_file_id}`).length) {
            fgInputs[`[${(Object.values(fgInputs) as any).length}]`] = {
              options: [],
              input: `file_${blend.original_file_id}`
            }
            // keys.push(`file_${blend.original_file_id}`)
          }


          // if v file has video
          if (!!blend.is_video_only) {
            if (blendIndex == 0) {
              fg += `[${(Object.values(fgInputs) as any).findIndex(k => k.input == `file_${blend.original_file_id}`)}:v]`
            } else {
              fg += `;

[${(Object.values(fgInputs) as any).findIndex(k => k.input == `file_${blend.original_file_id}`)}:v]`
            }
            
    // if v file cuted
            if (blend.from != 0 || blend.to != blend.saveDuration) {
              fg += `
trim=${Math.round(blend.from*1000)}ms:end=${Math.round(blend.to*1000)}ms,`
            }
    
            fg+= `
setpts=PTS-STARTPTS,
format=yuv420p,`
    
            if (!!blend.video_filters_on && !!blend.video_filters) {
              let vidFilters = `
eq=`

              if (blend.video_filters.contrast != 1) {
                vidFilters += `contrast=${(blend.video_filters.contrast/1000 + 1).toFixed(3)}:`
              }
              if (blend.video_filters.saturation != 1) {
                vidFilters += `saturation=${(blend.video_filters.saturation/100).toFixed(2)}:`
              }
              if (vidFilters.endsWith(":")) {
                // Заменяем ':' на ','
                vidFilters = vidFilters.replace(/:$/, ",");
                fg+= vidFilters;
              }

              if (blend.video_filters.brightness != 1) {
                fg += `
lutrgb=r=clipval*${blend.video_filters.brightness}:g=clipval*${blend.video_filters.brightness}:b=clipval*${blend.video_filters.brightness},`;
              }
            }

            if (blend.is_changed) {
              if (blend.rd.params.part_rotate_deg != 0) {
                        fg += `
rotate='angle=${blend.rd.params.part_rotate_deg}*PI/180:out_w=max(in_w,in_h)*2:out_h=max(in_w,in_h)*2:fillcolor=black',`
              }
              fg += `
crop='out_w=${blend.rd.params.part_crop_width}:out_h=${blend.rd.params.part_crop_height}:x=${blend.rd.params.part_crop_x}:y=${blend.rd.params.part_crop_y}',
scale='width=${blend.rd.params.part_scale_width}:height=${blend.rd.params.part_scale_height}:force_original_aspect_ratio=decrease:force_divisible_by=2',
pad=width=${this.projectForm.value.width}:height=${this.projectForm.value.height}:x=${blend.rd.params.part_pad_x}:y=${blend.rd.params.part_pad_y},
setsar=sar=1`
            } else {
              fg += `
scale=width=${this.projectForm.value.width}:height=${this.projectForm.value.height}:force_original_aspect_ratio=decrease:force_divisible_by=2,
pad=width=${this.projectForm.value.width}:height=${this.projectForm.value.height}:x=${blend.rd.params.part_pad_x}:y=${blend.rd.params.part_pad_y},
setsar=sar=1`
            }
    
    
    // if v file rate changed
            if (blend.part_speed_rate != 1) {
              fg += `,
setpts=${1/blend.part_speed_rate}*PTS`
            }
    
            fg += `
[outv${i}b${blendIndex}]`
    
            // outsFg[`ofile${i + 1}`].options.push({
            //   "-map": `[outv${i}b${blendIndex}]`
            // })
          } else {
            fg += `color=size=${this.projectForm.value.width}x${this.projectForm.value.height}:rate=25:d=${blend.duration/(1/blend.part_speed_rate)} 
[outv${i}b${blendIndex}]`
          }
          
          // if v file has audio
          if (!!blend.is_audio_only) {    
            fg += `;
    
[${(Object.values(fgInputs) as any).findIndex(k => k.input == `file_${blend.original_file_id}`)}:a]`;
    
    // if v file cuted
            if (blend.from != 0 || blend.to != blend.saveDuration) {
              fg += `
atrim=start=${Math.round(blend.from*1000)}ms:end=${Math.round(blend.to*1000)}ms,
asetpts=PTS-STARTPTS,`
            }
    
    // if v file rate changed
            if (blend.part_speed_rate != 1) {
              fg += `
asetpts=${1/blend.part_speed_rate}*PTS,
${this.generateAtempoFilters(blend.part_speed_rate)},`
            }
    
            fg += `
aformat=channel_layouts=stereo`;
    
    // if v file volume changed
            if (blend.volume != 1) {
              fg += `,
volume=${blend.volume}`
            }
    
            fg += `
[outa${i}b${blendIndex}]`
    
            // outsFg[`ofile${i + 1}`].options.push({
            //   "-map": `[outa${i}b${blendIndex}]`
            // })
          } else {
            fg += `;

anullsrc=channel_layout=stereo:sample_rate=44100:d=${0.1}
[outa${i}b${blendIndex}]`;
          }
          fgFinaly += `[outv${i}b${blendIndex}][outa${i}b${blendIndex}]`
        }) 

        fgFinaly += `concat=n=${part.blends.length}:v=1:a=1[outv${i}][outa${i}]`

        // outsFg[`ovfile${i + 1}`].part_of_files_keys = keys.join(',');
        outsFg[`ovfile${i + 1}`].options.push({
          "-map": `[outv${i}]`
        })
        outsFg[`ovfile${i + 1}`].options.push({
          "-map": `[outa${i}]`
        })
        fg += `;

${fgFinaly}`;

        console.log('fgInputs', fgInputs)
        executeData.filtergraphs[`fg${i + 1}`] = {
          inputs: fgInputs,
          filtergraph: fg,
          outputs: outsFg
        } 
      } else {

        let fg = `
`;

        let outsFg = {
          [`ovfile${i + 1}`]: {
            "options": [],
            "is_part": 1,
            "part_of_files_keys": `file_${part.original_file_id}`
          },
        }

        // if v file has_blur_bg
        if (!!part.has_blur_bg) {
          // if v file has video
          if (!!part.is_video_only) {
            fg += `[0:v]`
            
    // if v file cuted
            if (part.from != 0 || part.to != part.saveDuration) {
              fg += `
trim=${Math.round(part.from*1000)}ms:end=${Math.round(part.to*1000)}ms,`
            }
  
            fg+= `
setpts=PTS-STARTPTS,
format=yuv420p,`
  
            if (!!part.video_filters_on && !!part.video_filters) {
              let vidFilters = `
eq=`

              if (part.video_filters.contrast != 1) {
                vidFilters += `contrast=${(part.video_filters.contrast/1000 + 1).toFixed(3)}:`
              }
              if (part.video_filters.saturation != 1) {
                vidFilters += `saturation=${(part.video_filters.saturation/100).toFixed(2)}:`
              }
              if (vidFilters.endsWith(":")) {
                // Заменяем ':' на ','
                vidFilters = vidFilters.replace(/:$/, ",");
                fg+= vidFilters;
              }

              if (part.video_filters.brightness != 1) {
                fg += `
lutrgb=r=clipval*${part.video_filters.brightness}:g=clipval*${part.video_filters.brightness}:b=clipval*${part.video_filters.brightness},`;
              }
            }

            if (part.is_changed) {
              if (part.rd.params.part_rotate_deg != 0) {
                        fg += `
rotate='angle=${part.rd.params.part_rotate_deg}*PI/180:out_w=max(in_w,in_h)*2:out_h=max(in_w,in_h)*2:fillcolor=black',`
              }
              fg += `
crop='out_w=${part.rd.params.part_crop_width}:out_h=${part.rd.params.part_crop_height}:x=${part.rd.params.part_crop_x}:y=${part.rd.params.part_crop_y}',
scale='width=${part.rd.params.part_scale_width}:height=${part.rd.params.part_scale_height}:force_original_aspect_ratio=decrease:force_divisible_by=2',
setsar=sar=1`
            } else {
              fg += `
scale=width=${this.projectForm.value.width}:height=${this.projectForm.value.height}:force_original_aspect_ratio=decrease:force_divisible_by=2,
setsar=sar=1`
            }
  
  
    // if v file rate changed
            if (part.part_speed_rate != 1) {
              fg += `,
setpts=${1/part.part_speed_rate}*PTS`
            }
  
            fg += `
[tempv${i}]`
  

          } else {
            fg += `color=size=${this.projectForm.value.width}x${this.projectForm.value.height}:rate=25:d=${part.duration/(1/part.part_speed_rate)} 
[tempv${i}`
          }

          fg += `;

[0:v]`
          if (part.from != 0 || part.to != part.saveDuration) {
            fg += `
trim=${Math.round(part.from*1000)}ms:end=${Math.round(part.to*1000)}ms,`
          }

          // crop=${this.projectForm.value.width}:${this.projectForm.value.height}:${(this.projectForm.value.width > this.projectForm.value.height) ? 0 : '(in_w-' + this.projectForm.value.width + ')/2'}:${(this.projectForm.value.width > this.projectForm.value.height) ? '(in_h-' + this.projectForm.value.height + ')/2' : 0},
          fg+= `
setpts=PTS-STARTPTS,
format=yuv420p,
scale='width=${(this.projectForm.value.width > this.projectForm.value.height) ? this.projectForm.value.width : -2}:height=${(this.projectForm.value.width > this.projectForm.value.height) ? -2 : this.projectForm.value.height}:force_original_aspect_ratio=decrease:force_divisible_by=2',
crop=${this.projectForm.value.width}:${this.projectForm.value.height}:(in_w-${this.projectForm.value.width})/2:(in_h-${this.projectForm.value.height})/2,
boxblur=luma_radius=30:luma_power=6,
setsar=sar=1`

          if (part.part_speed_rate != 1) {
            fg += `,
setpts=${1/part.part_speed_rate}*PTS`
          }

          fg+= `
[blurred_bg${i}];

[blurred_bg${i}][tempv${i}]overlay=shortest=1:x=${part.rd.params.part_pad_x}:y=${part.rd.params.part_pad_y}`

          if (part.subs_on) {
            fg += `
[stagev${i}]`
            
            fg+= `;
            
[stagev${i}]
ass=[text_file_${i}]
[outv${i}]`
          } else {
            fg += `
[outv${i}]`
          }
          
          outsFg[`ovfile${i + 1}`].options.push({
            "-map": `[outv${i}]`
          })
          // if v file has audio
          if (!!part.is_audio_only) {
            fg += `;
  
[0:a]`;
  
    // if v file cuted
            if (part.from != 0 || part.to != part.saveDuration) {
              fg += `
atrim=start=${Math.round(part.from*1000)}ms:end=${Math.round(part.to*1000)}ms,
asetpts=PTS-STARTPTS,`
            }
  
    // if v file rate changed
            if (part.part_speed_rate != 1) {
              fg += `
asetpts=${1/part.part_speed_rate}*PTS,
${this.generateAtempoFilters(part.part_speed_rate)},`
            }
  
            fg += `
aformat=channel_layouts=stereo`;
  
    // if v file volume changed
            if (part.volume != 1) {
              fg += `,
volume=${part.volume}`
            }
  
            fg += `
[outa${i}]`
  
  
          } else {
            fg += `;
  
  anullsrc=channel_layout=stereo:sample_rate=44100:d=${0.1}
  [outa${i}]`;

          }
          outsFg[`ovfile${i + 1}`].options.push({
            "-map": `[outa${i}]`
          })
        } else {
          
          // if v file has video
          if (!!part.is_video_only) {
            fg += `[0:v]`
            
    // if v file cuted
            if (part.from != 0 || part.to != part.saveDuration) {
              fg += `
trim=${Math.round(part.from*1000)}ms:end=${Math.round(part.to*1000)}ms,`
            }
  
            fg+= `
setpts=PTS-STARTPTS,
format=yuv420p,`
  
            if (!!part.video_filters_on && !!part.video_filters) {
              let vidFilters = `
eq=`

              if (part.video_filters.contrast != 1) {
                vidFilters += `contrast=${(part.video_filters.contrast/1000 + 1).toFixed(3)}:`
              }
              if (part.video_filters.saturation != 1) {
                vidFilters += `saturation=${(part.video_filters.saturation/100).toFixed(2)}:`
              }
              if (vidFilters.endsWith(":")) {
                // Заменяем ':' на ','
                vidFilters = vidFilters.replace(/:$/, ",");
                fg+= vidFilters;
              }

              if (part.video_filters.brightness != 1) {
                fg += `
lutrgb=r=clipval*${part.video_filters.brightness}:g=clipval*${part.video_filters.brightness}:b=clipval*${part.video_filters.brightness},`;
              }
            }
            if (part.is_changed) {
              if (part.rd.params.part_rotate_deg != 0) {
                        fg += `
rotate='angle=${part.rd.params.part_rotate_deg}*PI/180:out_w=max(in_w,in_h)*2:out_h=max(in_w,in_h)*2:fillcolor=black',`
              }
              fg += `
crop='out_w=${part.rd.params.part_crop_width}:out_h=${part.rd.params.part_crop_height}:x=${part.rd.params.part_crop_x}:y=${part.rd.params.part_crop_y}',
scale='width=${part.rd.params.part_scale_width}:height=${part.rd.params.part_scale_height}:force_original_aspect_ratio=decrease:force_divisible_by=2',
pad=width=${this.projectForm.value.width}:height=${this.projectForm.value.height}:x=${part.rd.params.part_pad_x}:y=${part.rd.params.part_pad_y},
setsar=sar=1`
            } else {
              fg += `
scale=width=${this.projectForm.value.width}:height=${this.projectForm.value.height}:force_original_aspect_ratio=decrease:force_divisible_by=2,
pad=width=${this.projectForm.value.width}:height=${this.projectForm.value.height}:x=${part.rd.params.part_pad_x}:y=${part.rd.params.part_pad_y},
setsar=sar=1`
            }
  
  
    // if v file rate changed
            if (part.part_speed_rate != 1) {
              fg += `,
setpts=${1/part.part_speed_rate}*PTS`
            }
  
            if (part.subs_on) {
              fg += `
[tempv${i}]`
              
              fg+= `;
              
[tempv${i}]
ass=[text_file_${i}]
[outv${i}]`
            } else {
              fg += `
[outv${i}]`
            }
  
          } else {
              fg += `color=size=${this.projectForm.value.width}x${this.projectForm.value.height}:rate=25:d=${part.duration/(1/part.part_speed_rate)} 
[outv${i}]`
          }
            outsFg[`ovfile${i + 1}`].options.push({
              "-map": `[outv${i}]`
            })
          // if v file has audio
          if (!!part.is_audio_only) {
  
            fg += `;
  
  [0:a]`;
  
    // if v file cuted
            if (part.from != 0 || part.to != part.saveDuration) {
              fg += `
  atrim=start=${Math.round(part.from*1000)}ms:end=${Math.round(part.to*1000)}ms,
  asetpts=PTS-STARTPTS,`
            }
  
    // if v file rate changed
            if (part.part_speed_rate != 1) {
              fg += `
  asetpts=${1/part.part_speed_rate}*PTS,
  ${this.generateAtempoFilters(part.part_speed_rate)},`
            }
  
            fg += `
  aformat=channel_layouts=stereo`;
  
    // if v file volume changed
            if (part.volume != 1) {
              fg += `,
  volume=${part.volume}`
            }
  
            fg += `
  [outa${i}]`
  
  
          } else {
            fg += `;
  
  anullsrc=channel_layout=stereo:sample_rate=44100:d=${0.1}
  [outa${i}]`;
          }
          outsFg[`ovfile${i + 1}`].options.push({
            "-map": `[outa${i}]`
          })
        }
        
        executeData.filtergraphs[`fg${i + 1}`] = {
          inputs: {
            '[0]': {
              options: [],
              input: `file_${part.original_file_id}`
            }
          },
          filtergraph: fg,
          outputs: outsFg
        }
      }
    });
  
    // // generate concat FG
    // // [0:v][0:a][1:v][1:a]concat=n=2:v=1:a=1[outv][outa]
    // // n - count of concated fragments
    // // v - result video streams count
    // // a - results audio streams count

    // more then one audio
    if (audParts.length > 1) {
      
      // fg generation for all audio framents
      // trim=${x}ms:end=${y}ms
      // x - from
      // y - to
      
      audParts.forEach((aPart, i) => {
        if (!(Object.values(executeData.inputs) as any).filter(x => x.original_file_id == aPart.original_file_id).length) {
          executeData.inputs[`file_${aPart.original_file_id}`] = {
            original_file_id: aPart.original_file_id
          }
        }

        let fg = `
[0:a]`;
        if (aPart.from != 0 || aPart.to != aPart.saveDuration) {
          fg += `
atrim=start=${Math.round(aPart.from*1000)}ms:end=${Math.round(aPart.to*1000)}ms,`
        }

        fg += `
asetpts=PTS-STARTPTS,`;

        if (aPart.prevAllDuration != 0) {
          fg += `
adelay=${Number((aPart.prevAllDuration*1000).toFixed(0))}|${Number((aPart.prevAllDuration*1000).toFixed(0))},`
        }

        fg += `
aformat=channel_layouts=stereo`;

        if (aPart.part_speed_rate != 1 || aPart.volume != 1) {
          fg += `,`
        }

        if (aPart.part_speed_rate != 1) {
          fg += `
asetpts=${1/aPart.part_speed_rate}*PTS,
${this.generateAtempoFilters(aPart.part_speed_rate)},`
        }

        if (aPart.volume != 1) {
          fg += `
volume=${aPart.volume}`
        }
        fg += `
[outa${i}]`

        executeData.filtergraphs[`fg${Object.values(executeData.filtergraphs).length + 1}`] = {
          inputs: {
            '[0]': {
              options: [],
              input: `file_${aPart.original_file_id}`
            }
          },
          filtergraph: fg,
          outputs: {
            [`oafile${i + 1}`]: {
              "options": [
                {
                  "-map": `[outa${i}]`
                }
              ],
              "is_part": 1,
              "part_of_files_keys": `file_${aPart.original_file_id}`
            },
          }
        }
        
      });
  
  
      // generate mix FG
      // amix=inputs=${k}:duration=longest:dropout_transition=3
      // k - count of mixed audio fragments
      // duration=longest - устанавливает длительность смешанного трека на основе самого длинного трека.
      // dropout_transition=3 — задает время на переход от одного трека к другому, чтобы избежать резких обрывов.
      let outputAudioFg = {
        inputs: {},
        filtergraph: ``,
        outputs: {
          [`mixa`]: {
            "options": [
              {
                "-map": `[outmixa]`
              }
            ],
            "is_concat": 1,
            "consist_of_files_keys": `${Array.from(new Set(audParts.map(audioPart => `file_${audioPart.original_file_id}`))).join(',')}`
          }
        }
      }
  
      let k:number = 0;
      Object.values(executeData.filtergraphs).forEach((fg:any, i) => {
        if (fg.outputs && i > parts.length - 1) {
          Object.keys(fg.outputs).forEach((outKey) => {
            outputAudioFg.inputs[`[${k}]`] = {
              options: [],
              input: `${outKey}`
            }
            outputAudioFg.filtergraph += `[${k}:a]`
            k++;
          })
        }
      })
      outputAudioFg.filtergraph += `amix=inputs=${k}:duration=longest:dropout_transition=3[outmixa]`
  
      // set audio mix FG
      executeData.filtergraphs[`fg${Object.values(executeData.filtergraphs).length + 1}`] = outputAudioFg;
    } else {
      // only one audio

      if (!(Object.values(executeData.inputs) as any).filter(x => x.original_file_id == audParts[0].original_file_id).length) {
        executeData.inputs[`file_${audParts[0].original_file_id}`] = {
          original_file_id: audParts[0].original_file_id
        }
      }

      let fg = `
      [0:a]`;
              if (audParts[0].from != 0 || audParts[0].to != audParts[0].saveDuration) {
                fg += `
      atrim=start=${Math.round(audParts[0].from*1000)}ms:end=${Math.round(audParts[0].to*1000)}ms,`
              }
      
              fg += `
      asetpts=PTS-STARTPTS,`;
      
              if (audParts[0].prevAllDuration != 0) {
                fg += `
      adelay=${Number((audParts[0].prevAllDuration*1000).toFixed(0))}|${Number((audParts[0].prevAllDuration*1000).toFixed(0))},`
              }
      
              fg += `
      aformat=channel_layouts=stereo`;
      
              if (audParts[0].part_speed_rate != 1 || audParts[0].volume != 1) {
                fg += `,`
              }
      
              if (audParts[0].part_speed_rate != 1) {
                fg += `
      asetpts=${1/audParts[0].part_speed_rate}*PTS,
      ${this.generateAtempoFilters(audParts[0].part_speed_rate)},`
              }
      
              if (audParts[0].volume != 1) {
                fg += `
      volume=${audParts[0].volume}`
              }
              fg += `
      [outonea]`

      let outputAudioFg = {
        inputs: {
          '[0]': {
            options: [],
            input: `file_${audParts[0].original_file_id}`
          }
        },
        filtergraph: fg,
        outputs: {
          [`mixa`]: {
            "options": [
              {
                "-map": `[outonea]`
              }
            ],
            "is_part": 1,
            "part_of_files_keys": `file_${audParts[0].original_file_id}`
          }
        }
      }
      executeData.filtergraphs[`fg${Object.values(executeData.filtergraphs).length + 1}`] = outputAudioFg;
    }

    parts.forEach((part, pInd) => {
      if (!part.is_video_only && !part.is_audio_only) {
        return
      }
      let resultFileFG:any = {
        inputs: {
          "[0]": {
            options: [],
            input: `ovfile${pInd + 1}`
          },
          "[1]": {
            options: [],
            input: `mixa`
          }
        },
        filtergraph: `
[1:a]
atrim=start=${Math.round(part.prevAllDuration*1000)}ms:end=${Math.round((part.prevAllDuration + part.duration)*1000)}ms,
asetpts=PTS-STARTPTS
[trimeda${pInd + 1}];

[0:a][trimeda${pInd + 1}]amix=inputs=2:duration=longest:dropout_transition=3[resulta${pInd + 1}]; 

[0:v]copy[resultv${pInd + 1}]`,
        outputs: {
          [`resultfile${pInd + 1}`]: {
            "options": [
              {
                "-map": `[resultv${pInd + 1}]`
              },
              {
                "-map": `[resulta${pInd + 1}]`
              }
            ],
            "output_location": "/exported-files",
            "output_filename": (part.extension ? part.filename.replace('.'+part.extension, '') + `.${part.extension}` : part.filename),
            "output_content_type": part.content_type,
            "operation_reminder_id": this.vidProject.batch_data && this.vidProject.batch_data.operation_reminder_id ? this.vidProject.batch_data.operation_reminder_id : 0
          }
        }

      }
      if (part.is_combine) {
        let tags = [];
        part.blends.forEach(blend => {     
          if (blend.tags) {
            blend.tags.forEach(tag => {
              let pvi = !!tag.parameter_value_id ? tag.parameter_value_id : tag.id;
              if (!tags.filter(k => k.parameter_value_id == pvi).length) {
                tags.push({
                  is_primary: tag.is_primary,
                  parameter_value_id: pvi
                })
              }
            });
          }     
        });
        if (part.tags) {
          part.tags.forEach(tag => {
            let pvi = !!tag.parameter_value_id ? tag.parameter_value_id : tag.id;
            if (!tags.filter(k => k.parameter_value_id == pvi).length) {
              tags.push({
                is_primary: tag.is_primary,
                parameter_value_id: pvi
              })
            }
          });
        }
        if (parts.length > 1 || !!this.inheritTags) {
          resultFileFG.outputs[`resultfile${pInd + 1}`].parameter_values = tags;
        }
        // resultFileFG.outputs[`resultfile${pInd + 1}`].parameter_values = tags;
        resultFileFG.outputs[`resultfile${pInd + 1}`].is_concat = 1;
        resultFileFG.outputs[`resultfile${pInd + 1}`].part_of_files_keys = `ovfile${pInd + 1},${Array.from(new Set(part.blends.map(blend => `file_${blend.original_file_id}`))).join(',')},mixa,${Array.from(new Set(audParts.map(audioPart => `file_${audioPart.original_file_id}`))).join(',')}`;
        resultFileFG.outputs[`resultfile${pInd + 1}`].consist_of_files_keys = `${Array.from(new Set(part.blends.map(blend => `file_${blend.original_file_id}`))).join(',')}`;
      } else {
        let tags = [];
        if (part.tags) {
          part.tags.forEach(tag => {
            let pvi = !!tag.parameter_value_id ? tag.parameter_value_id : tag.id;
            if (!tags.filter(k => k.parameter_value_id == pvi).length) {
              tags.push({
                is_primary: tag.is_primary,
                parameter_value_id: pvi
              })
            }
          });
        }
        if (parts.length > 1 || !!this.inheritTags) {
          resultFileFG.outputs[`resultfile${pInd + 1}`].parameter_values = tags;
        }
        // resultFileFG.outputs[`resultfile${pInd + 1}`].parameter_values = tags;
        resultFileFG.outputs[`resultfile${pInd + 1}`].part_of_files_keys = `ovfile${pInd + 1},file_${part.original_file_id},mixa,${Array.from(new Set(audParts.map(audioPart => `file_${audioPart.original_file_id}`))).join(',')}`;
        resultFileFG.outputs[`resultfile${pInd + 1}`].is_part = 1;
      }
      executeData.filtergraphs[`fg${Object.values(executeData.filtergraphs).length + 1}`] = resultFileFG;
    });

    let editProjData:any = {
      name: this.vidProject.name,
      editor_version: this.version.toString(),
      output_location: this.data.file.location == "/" ? this.data.file.location + `cut files of ${this.data.file.filename.replace('.'+this.data.file.extension, '')}` : this.data.file.location + `/cut files of ${this.data.file.filename.replace('.'+this.data.file.extension, '')}`,
      output_width: this.projectForm.value.width,
      output_height: this.projectForm.value.height,
      output_frame_rate: this.rateForm.value.output_frame_rate,
      output_audio_bit_rate: this.rateForm.value.output_audio_bit_rate,
      output_bit_rate: this.rateForm.value.output_bit_rate * 1000000,
      waiting_time_limit: this.waiting_time_limit.value,
      execute_data: executeData,
    }
    console.log("executeData", executeData)

    if (this.vidProject.batch_data && this.vidProject.batch_data.parameter_id && this.vidProject.batch_data.headCols && !!this.vidProject.batch_data.headCols.length) {
      this.vidProject.batch_data.video_project_task_create = []
      parts.forEach((part, partInd) => {   
        if (!part.is_video_only && !part.is_audio_only) {
          return
        }    
        let batchCards = {
          custom_id: partInd + 1,
          video_project_files_custom_ids: `resultfile${partInd + 1}`,
          task_channels: []
        }

        this.vidProject.batch_data.headCols.forEach(headCol => {
          let check = true;
          if (!!this.vidProject.batch_data.selectedCols) {
            if (!this.vidProject.batch_data.selectedCols.includes(headCol)) {
              check = false
            }
          }
          if (check) {
            if (part.publications && part.publications.length) {
              let publArr = [];
              JSON.parse(JSON.stringify(part.publications, this.getCircularReplacer())).forEach(pub => {
                if (this.getChannelById(pub.channel_id).parameterValuesToChannel.filter(k => k.parameter_value_id == headCol).length) {
                  let cont_name = ''
                  if (part.postNames && part.postNames[headCol] && part.postNames[headCol].name) {
                    cont_name = part.postNames[headCol].name
                  } else {
                    cont_name = part.form.value.name
                  }
                  pub.content_name = pub.content_type_id && this.isYouTube(pub.content_type_id) && cont_name.length > 100 ? cont_name.slice(0,97)+'...' : cont_name;
                  pub.content_description = cont_name;

                  // pub.content_name = pub.content_type_id && this.isYouTube(pub.content_type_id) && part.form.value.name.length > 100 ? part.form.value.name.slice(0,97)+'...' : part.form.value.name;
                  // pub.content_description = part.form.value.name;
                  delete pub.task_channel_files[0].video_project_file_id;
                  // pub.task_channel_files[0].video_project_file_custom_id = partInd + 1;
                  pub.task_channel_files[0].video_project_file_id = `resultfile${partInd + 1}`;

                  if (pub.content_type_id && this.isTikTok(pub.content_type_id)) {
                    pub.publishing_params = {
                      privacy_level : 'PUBLIC_TO_EVERYONE',
                      disable_duet: true,
                      disable_stitch: true,
                      disable_comment: false,
                      brand_content_toggle: false,
                      brand_organic_toggle: false,
                    }
                  }

                  if (pub.content_type_id && this.isYouTube(pub.content_type_id)) {
                    pub.publishing_params = {
                      privacy : 'private',
                      made_for_kids: !!part.made_for_kids ? 1 : 0
                    }
                  }

                  publArr.push(pub)
                }
              })

              batchCards.task_channels.push(publArr);
            }
          }
        });

        this.vidProject.batch_data.video_project_task_create.push(batchCards);
      });

      editProjData.batch_data = this.vidProject.batch_data;
    }
    // CHECKER)
    // this.isSubmit = false;
    // return 


    // edit project
    this.attachSubscriptions(
      this.fileService.resetVideoProject(this.vidProject.id, {}, this.data.company_id).pipe(
        switchMap(resetProject => {
          return this.fileService.editVideoProject(this.vidProject.id, editProjData, this.data.company_id).pipe(
            switchMap(project => {
              if (this.is_create_cards.value && parts.filter(o => !!o.is_video_only || !!o.is_audio_only).length > 0) {
                let cardsArr = []
                parts.forEach((cut, index) => {
                  if (!cut.is_video_only && !cut.is_audio_only) {
                    return
                  }
                  if (!!cut.is_video_only || !!cut.is_audio_only) {
                    let formData = JSON.parse(JSON.stringify(cut.form.value, this.getCircularReplacer()))
    
                    delete formData.type
                    formData.create_parameter_values_to_task = []
                
                    cut.parameters.filter(x => x.id != 0 && x.activeValues.length > 0).forEach(param => {
                      param.activeValues.forEach(el => {
                        formData.create_parameter_values_to_task.push({
                          parameter_id : param.id,
                          parameter_value_id : el.id
                        })
                      })
                    })
    
                    if (cut.jobs && cut.jobs.length) {
                      cut.jobs.forEach(job => {
                        delete job.newExecutor;
                        job.create_parameter_values_to_task = []
                        if (job.parameters && job.parameters.length > 0) {
                          job.parameters[0].activeValues.forEach(el => {
                            job.create_parameter_values_to_task.push({
                              parameter_id : el.parameter_id,
                              parameter_value_id : el.id
                            })
                          })
                        }
    
                        delete job.mobEmployees;
                        delete job.parameters;
                      })
    
                      formData.create_task_operations = cut.jobs.slice();
                    }
    
                    let template;
                    let template_id;
                    if (cut.form.value.type != 0) {
                      if (this.form.value.template_id) {
                        template_id = this.form.value.template_id
                      }
                      template = JSON.parse(JSON.stringify(this.getItemById(this.taskTemplates, cut.form.get('template_id').value).template_data, this.getCircularReplacer()))
                    }
                    let u:any = {
                      video_project_id: project.id,
                      company_id: this.data.target_company_id,
                      template_data: cut.form.value.type == 0 ? formData : Object.assign(template, {name: cut.form.value.name}),
                      video_project_files: `resultfile${index + 1}`,
                      custom_id: +index + 1
                    }
    
                    if (template_id) {
                      u.task_template_id = template_id;
                    }
    
                    if (this.vidProject.batch_data && this.vidProject.batch_data.parameter_id) {
                      let tag_id;
                      if (parts[0] && parts[0].parameterValuesToTask.find(q => this.vidProject.batch_data.parameter_id == q.parameter_id)) {
                        tag_id = parts[0].parameterValuesToTask.find(q => this.vidProject.batch_data.parameter_id == q.parameter_id).parameter_value_id;
                      }
                      let allHasOne:boolean = true;
    
                      if (tag_id) {
                        parts.forEach(q => {
                          if (!q.is_video_only && !q.is_audio_only) {
                            return
                          }
                          if (q.parameterValuesToTask && q.parameterValuesToTask.length && q.parameterValuesToTask.filter(w => w.parameter_value_id == tag_id).length == 0) {
                            allHasOne = false;
                          }
                        });
    
                        if (allHasOne) {
                          if (cut.publications && cut.publications.length) {
                            let publArr = []
                            cut.publications.forEach(pub => {
                              if (this.getChannelById(pub.channel_id).parameterValuesToChannel.filter(k => k.parameter_value_id == tag_id).length) {
                                let cont_name = ''
                                if (cut.postNames && cut.postNames[0] && cut.postNames[0].name) {
                                  cont_name = cut.postNames[0].name
                                } else {
                                  cont_name = cut.form.value.name
                                }
                                pub.content_name = pub.content_type_id && this.isYouTube(pub.content_type_id) && cont_name.length > 100 ? cont_name.slice(0,97)+'...' : cont_name;
                                pub.content_description = cont_name;
    
                                // pub.content_name = pub.content_type_id && this.isYouTube(pub.content_type_id) && cut.form.value.name.length > 100 ? cut.form.value.name.slice(0,97)+'...' : cut.form.value.name;
                                // pub.content_description = cut.form.value.name;
                                pub.task_channel_files[0].video_project_file_id = u.video_project_files
    
                                if (pub.content_type_id && this.isTikTok(pub.content_type_id)) {
                                  pub.publishing_params = {
                                    privacy_level : 'PUBLIC_TO_EVERYONE',
                                    disable_duet: true,
                                    disable_stitch: true,
                                    disable_comment: false,
                                    brand_content_toggle: false,
                                    brand_organic_toggle: false,
                                  }
                                }
                                if (pub.content_type_id && this.isYouTube(pub.content_type_id)) {
                                  pub.publishing_params = {
                                    privacy : 'private',
                                    made_for_kids: !!cut.made_for_kids ? 1 : 0
                                  }
                                }
    
                                publArr.push(pub)
                              }
                            })
    
                            u.task_channels = publArr;
                          }
                        } else {
                          if (cut.publications && cut.publications.length) {
                            cut.publications.forEach(pub => {
                              let cont_name = ''
                              if (cut.postNames && cut.postNames[0] && cut.postNames[0].name) {
                                cont_name = cut.postNames[0].name
                              } else {
                                cont_name = cut.form.value.name
                              }
                              pub.content_name = pub.content_type_id && this.isYouTube(pub.content_type_id) && cont_name.length > 100 ? cont_name.slice(0,97)+'...' : cont_name;
                              pub.content_description = cont_name;
    
                              // pub.content_name = pub.content_type_id && this.isYouTube(pub.content_type_id) && cut.form.value.name.length > 100 ? cut.form.value.name.slice(0,97)+'...' : cut.form.value.name;
                              // pub.content_description = cut.form.value.name;
                              pub.task_channel_files[0].video_project_file_id = u.video_project_files
    
                              if (pub.content_type_id && this.isTikTok(pub.content_type_id)) {
                                pub.publishing_params = {
                                  privacy_level : 'PUBLIC_TO_EVERYONE',
                                  disable_duet: true,
                                  disable_stitch: true,
                                  disable_comment: false,
                                  brand_content_toggle: false,
                                  brand_organic_toggle: false,
                                }
                              }
                              if (pub.content_type_id && this.isYouTube(pub.content_type_id)) {
                                pub.publishing_params = {
                                  privacy : 'private',
                                  made_for_kids: !!cut.made_for_kids ? 1 : 0
                                }
                              }
                            })
    
                            u.task_channels = cut.publications;
                          }
                        }
                      } else {
                        if (cut.publications && cut.publications.length) {
                          cut.publications.forEach(pub => {
                            let cont_name = ''
                            if (cut.postNames && cut.postNames[0] && cut.postNames[0].name) {
                              cont_name = cut.postNames[0].name
                            } else {
                              cont_name = cut.form.value.name
                            }
                            pub.content_name = pub.content_type_id && this.isYouTube(pub.content_type_id) && cont_name.length > 100 ? cont_name.slice(0,97)+'...' : cont_name;
                            pub.content_description = cont_name;
                            // pub.content_name = pub.content_type_id && this.isYouTube(pub.content_type_id) && cut.form.value.name.length > 100 ? cut.form.value.name.slice(0,97)+'...' : cut.form.value.name;
                            // pub.content_description = cut.form.value.name;
                            pub.task_channel_files[0].video_project_file_id = u.video_project_files
    
                            if (pub.content_type_id && this.isTikTok(pub.content_type_id)) {
                              pub.publishing_params = {
                                privacy_level : 'PUBLIC_TO_EVERYONE',
                                disable_duet: true,
                                disable_stitch: true,
                                disable_comment: false,
                                brand_content_toggle: false,
                                brand_organic_toggle: false,
                              }
                            }
                            if (pub.content_type_id && this.isYouTube(pub.content_type_id)) {
                              pub.publishing_params = {
                                privacy : 'private',
                                made_for_kids: !!cut.made_for_kids ? 1 : 0
                              }
                            }
                          })
    
                          u.task_channels = cut.publications;
                        }
                      }
                    } else {
                      if (cut.publications && cut.publications.length) {
                        cut.publications.forEach(pub => {
                          let cont_name = ''
                          if (cut.postNames && cut.postNames[0] && cut.postNames[0].name) {
                            cont_name = cut.postNames[0].name
                          } else {
                            cont_name = cut.form.value.name
                          }
                          pub.content_name = pub.content_type_id && this.isYouTube(pub.content_type_id) && cont_name.length > 100 ? cont_name.slice(0,97)+'...' : cont_name;
                          pub.content_description = cont_name;
    
                          // pub.content_name = pub.content_type_id && this.isYouTube(pub.content_type_id) && cut.form.value.name.length > 100 ? cut.form.value.name.slice(0,97)+'...' : cut.form.value.name;
                          // pub.content_description = cut.form.value.name;
                          pub.task_channel_files[0].video_project_file_id = u.video_project_files
    
                          if (pub.content_type_id && this.isTikTok(pub.content_type_id)) {
                            pub.publishing_params = {
                              privacy_level : 'PUBLIC_TO_EVERYONE',
                              disable_duet: true,
                              disable_stitch: true,
                              disable_comment: false,
                              brand_content_toggle: false,
                              brand_organic_toggle: false,
                            }
                          }
                          if (pub.content_type_id && this.isYouTube(pub.content_type_id)) {
                            pub.publishing_params = {
                              privacy : 'private',
                              made_for_kids: !!cut.made_for_kids ? 1 : 0
                            }
                          }
                        })
    
                        u.task_channels = cut.publications;
                      }
                    }
                    cardsArr.push(u)
                  } 
                })
                console.log("cardsArr", cardsArr)
                return forkJoin([...cardsArr.map(cardData => this.fileService.createVideoProjectTaskFile(cardData, this.data.company_id))]).pipe(
                  switchMap(cardsReq => {
                    return this.fileService.editVideoProject(project.id, {
                      is_draft: !!is_draft ? 1 : 0,
                      is_to_process: !!is_draft ? 0 : 1,
                      is_batch_apply: !!project.batch_data && !is_draft ? 1 : 0 
                    }, this.data.company_id)
                  })
                )
              } else {
                return this.fileService.editVideoProject(project.id, {
                  is_draft: !!is_draft ? 1 : 0,
                  is_to_process: !!is_draft ? 0 : 1,
                  is_batch_apply: !!project.batch_data && !is_draft ? 1 : 0 
                }, this.data.company_id)
              }
            }),
            switchMap(res => {
              console.log("END switchMap", !!this.is_create_vid_relations.value || !!this.is_create_aud_relations.value)
              if (!!this.is_create_vid_relations.value || !!this.is_create_aud_relations.value) {
                let consistsIds = [];
    
                this.data.task.consistOfTasks.forEach(element => {
                  consistsIds.push(element.id)
                });
    
                let x = {
                  consist_of_task_id: [...consistsIds]
                }
                
                let addTasks = [];
    
                if (!!this.is_create_vid_relations.value) {
                  this.files.video.forEach((pFile) => {
                    if (addTasks.filter(x => x.task_id == pFile.task.id).length == 0 && pFile.task.id != this.data.task.id) {
                      addTasks.push(pFile)
                      x.consist_of_task_id.push(pFile.task_id)
                    }
                  });
                }
                if (!!this.is_create_aud_relations.value) {
                  this.files.audio.forEach((pFile) => {
                    if (addTasks.filter(x => x.task_id == pFile.task.id).length == 0 && pFile.task.id != this.data.task.id) {
                      addTasks.push(pFile)
                      x.consist_of_task_id.push(pFile.task_id)
                    }
                  });
                }
    
                // this.tracks.filter(t => (!!this.is_create_vid_relations.value && !!this.is_create_aud_relations.value) ? true : (!!this.is_create_vid_relations.value ? t.type == 'video' : t.type == 'audio')).forEach(trackEl => {
                //   trackEl.files.forEach((trackElFile) => {
                //     if (addTasks.filter(x => x.task_id != this.data.task.id).length == 0) {
                //       addTasks.push(trackElFile.file)
                //       x.consist_of_task_id.push(trackElFile.file.task_id)
                //     }
                //   });
                // })
    
                console.log("this.data.task.consist_of_count != x.consist_of_task_id.length", this.data.task.consist_of_count != x.consist_of_task_id.length)
                if (this.data.task.consist_of_count != x.consist_of_task_id.length) {
                  return this.taskService.editTask(this.data.task.id, x, this.data.target_company_id).pipe(
                    switchMap(task => {
                      let sortData = [];
      
                      x.consist_of_task_id.forEach((el, i) => {
                        sortData.push(
                          {
                            "path": '/api/task-partition/register/',
                            "query": {'company_id': this.data.target_company_id},
                            "method": "POST",
                            "body": {
                              [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
                              company_id: this.data.target_company_id,
                              consist_of_task_id: this.data.task.id,
                              part_of_task_id: el,
                              consist_of_order: i,
                              part_of_order: null
                            }
                          }
                        )
                      });
    
                      return this.taskService.multiRequest(sortData).pipe(map(() => res))
                    })
                  )
                } else {
                  return of(res)
                }
              } else {
                return of(res)
              }
            })
            ,
            switchMap(res => {
              let savedTracks = JSON.parse(JSON.stringify(this.tracks, this.getCircularReplacer()));
              savedTracks.forEach((trackEl, trackInd) => {
                trackEl.parts.forEach((part, partInd) => {
                  delete part.blobSrc
                  delete part.subscr
                });
              })
              let savedFiles = JSON.parse(JSON.stringify(this.files, this.getCircularReplacer()));
              savedFiles.video.forEach(element => {
                delete element.blob
                delete element.blobSrc
              });
              savedFiles.audio.forEach(element => {
                delete element.blob
                delete element.blobSrc
              });
    
              let raw = {
                saveMode: this.saveMode.value,
                is_save_audio: this.is_save_audio.value,
                is_create_cards: this.is_create_cards.value,
                project: this.project,
                savedTags: this.savedTags,
                form: this.form.value,
                rateForm: this.rateForm.value,
                projectForm: this.projectForm.value,
                tracks: savedTracks,
                files: savedFiles,
                inheritTags: this.inheritTags,
                file: this.data.file,
                jobs: this.jobs,
                publications: this.publications
              }
    
              return ((!this.activeHistory || this.activeHistory.data != JSON.stringify(raw, this.getCircularReplacer())) ? this.fileService.saveVideoEditorHistory({
                company_id: this.data.target_company_id,
                video_project_id: this.vidProject.id,
                editor_version: this.version.toString(),
                parent_id: !!this.activeHistory ? this.activeHistory.id : 0,
                data: JSON.stringify(raw, this.getCircularReplacer()),
                is_active: 1
              }, this.data.company_id) : of(this.activeHistory))
            })
          )
        })
      ).subscribe(resp => {
        console.log("save", resp)
        this.isSubmit = false;
        this.close();
      }, error => {
        this.layoutService.showSnackBar({name: ''}, marker(error), SnackBarItem)
        console.log('save error', error)
        this.isSubmit = false;
      })
    )
  }

  convertToAssTime(timeInSeconds: number): string {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    const centiseconds = Math.floor((timeInSeconds % 1) * 100); // дробная часть как сотые секунды

    return `${this.pad(hours)}:${this.pad(minutes)}:${this.pad(seconds)}.${this.pad(centiseconds, 2)}`;
  }

  // Метод для добавления ведущих нулей
  pad(num: number, size: number = 2): string {
    let s = num.toString();
    while (s.length < size) s = '0' + s;
    return s;
  }

//   Dialogue: 0,0:00:01.00,0:00:04.00,Default,,0,0,0,,This is the first line.
// Dialogue: 0,0:00:05.00,0:00:08.00,Default,,0,0,0,,Here comes the second line.`

  partsSave(is_draft:boolean = false) {
    let parts = (this.tracks.filter(x => x.type == 'video').map(k => k.parts) as any).flat(Infinity);
    this.isSubmit = true;
    let executeData:any = {
      inputs: {},
      input_text_files: {},
      filtergraphs: {},
    }

    parts.forEach((part, i) => {
      if (!(Object.values(executeData.inputs) as any).filter(x => x.original_file_id == part.original_file_id).length) {
        executeData.inputs[`file_${part.original_file_id}`] = {
          original_file_id: part.original_file_id
        }
      }

      if (part.subs_on) {
        let content = `
[Script Info]
Title: Reports subtitles
ScriptType: v4.00+
PlayResX: ${this.projectForm.value.width}
PlayResY: ${this.projectForm.value.height}
WrapStyle: 0
ScaledBorderAndShadow: yes
Collisions: Normal
Video Aspect Ratio: 0
Video Zoom: 6
Video Position: 0

[V4+ Styles]
Format: Name, Fontname, Fontsize, PrimaryColour, SecondaryColour, OutlineColour, BackColour, Bold, Italic, Underline, StrikeOut, ScaleX, ScaleY, Spacing, Angle, BorderStyle, Outline, Shadow, Alignment, MarginL, MarginR, MarginV, Encoding
Style: Default,Roboto,${!!part.subSet && !!part.subSet.fontSize ? part.subSet.fontSize : "28"},${!!part.subSet && !!part.subSet.pastColor ? this.rgbaToReverseHex(part.subSet.pastColor) : "&H00FFFFFF"},${!!part.subSet && !!part.subSet.futureColor ? this.rgbaToReverseHex(part.subSet.futureColor) : "&H00808080"},${!!part.subSet && !!part.subSet.outlineColor ? this.rgbaToReverseHex(part.subSet.outlineColor) : "&H00000000"},&H00000000,${part.subSet.bold || "1"},${part.subSet.italic || "0"},${part.subSet.undeline || "0"},${part.subSet.strike || "0"},100,100,0,0,1,${part.subSet.outline || "2"},0,5,${Math.ceil((+part.rd.left + +part.subSet.pos.x)/this.project.percentX)},${Math.ceil((+part.rd.top + +part.subSet.pos.y)/this.project.percentY) > this.projectForm.value.height / 2 ? Math.ceil(this.projectForm.value.height - (+part.rd.top + +part.subSet.pos.y + part.subSet.pos.height)/this.project.percentY) > 0 ? Math.ceil(this.projectForm.value.height - (+part.rd.top + +part.subSet.pos.y + part.subSet.pos.height)/this.project.percentY) : '0' : Math.ceil((+part.rd.top + +part.subSet.pos.y)/this.project.percentY)},0,1

[Events]
`
        part.subs.filter(k => (k.from >= part.from || k.to >= part.from) && (k.to <= part.to)).forEach(subtitle => {
  
          if (subtitle.words && part.subSet && !!part.subSet.is_karaoke) {
            subtitle.words.forEach((word:any, wordInd:number) => {
              // let from;
              // let to = word.end_ts;
              // if (wordInd == 0) {
              //   from = word.ts
              // } else {
              //   from = subtitle.words[wordInd-1].end_ts
              // }
              const startTime = this.convertToAssTime(+this.useSpeedRate(word.from - part.from, part));
              const endTime = this.convertToAssTime(+this.useSpeedRate(word.to - part.from, part));
              let x = Math.ceil((+part.rd.left + +part.subSet.pos.x + (part.subSet.pos.width/2))/this.project.percentX);
              let y = Math.ceil((+part.rd.top + +part.subSet.pos.y + (part.subSet.pos.height/2))/this.project.percentY);
              content += `Dialogue: 0,${startTime},${endTime},Default,,0,0,0,,{\\pos(${x}, ${y})}`;

              let text = ``;
              subtitle.words.forEach((wordIN, wordINInd) => {
                if (wordIN.value) {
                  // let fromIN;
                  // let toIN = wordIN.end_ts;
                  // if (wordINInd == 0) {
                  //   fromIN = wordIN.ts
                  // } else {
                  //   fromIN = subtitle.words[wordINInd-1].end_ts
                  // }

                  if (wordINInd > 0) {
                    text += " ";
                  }
                  text += wordINInd >= wordInd ? `{\\kf${Math.ceil((wordIN.to-wordIN.from)*100)}\\c${!!part.subSet && !!part.subSet.activeColor ? this.rgbaToReverseHex(part.subSet.activeColor) : "&H0000FFFF"};}` : "";
                  text += wordIN.value;
                }
              });
              content += `${text}\n`
            });

            content += `\n\n`
          } else {
            const startTime = this.convertToAssTime(+this.useSpeedRate(subtitle.from - part.from, part));
            const endTime = this.convertToAssTime(+this.useSpeedRate(subtitle.to - part.from, part));
            let x = Math.ceil((+part.rd.left + +part.subSet.pos.x + (part.subSet.pos.width/2))/this.project.percentX);
            let y = Math.ceil((+part.rd.top + +part.subSet.pos.y + (part.subSet.pos.height/2))/this.project.percentY);
            content += `Dialogue: 0,${startTime},${endTime},Default,,0,0,0,,{\\pos(${x}, ${y})}${subtitle.text}\n`;
          }
        });

        executeData.input_text_files[`text_file_${i}`] = {
          content: content
        }
        console.log("content TEST", content);
      }


//         let fg = `
// [0:v]
// trim=9137ms:end=18685ms,
// setpts=PTS-STARTPTS,
// format=yuv420p,
// crop='out_w=558:out_h=720:x=379:y=0',
// scale='width=720:height=929:force_original_aspect_ratio=decrease:force_divisible_by=2',
// setsar=sar=1
// [tempv0];

// [0:v]
// trim=9137ms:end=18685ms,
// setpts=PTS-STARTPTS,
// format=yuv420p,
// scale='width=-2:height=1280:force_original_aspect_ratio=decrease:force_divisible_by=2',
// crop=720:1280:(in_w-720)/2:0,
// boxblur=luma_radius=50:luma_power=10,
// setsar=sar=1
// [blurred_bg0];

// [blurred_bg0][tempv0]overlay=shortest=1:x=0:y=180
// [outv0];

// [0:a]
// atrim=start=9137ms:end=18685ms,
// asetpts=PTS-STARTPTS,
// aformat=channel_layouts=stereo
// [outa0]
// `
      if (!part.is_video_only && !part.is_audio_only) {
        return
      }
      if (part.is_combine) {  
        let outsFg = {
          [`resultfile${i + 1}`]: {
            "options": [],
            "output_location": "/exported-files",
            "output_filename": (part.extension ? part.filename.replace('.'+part.extension, '') + `.${part.extension}` : part.filename),
            "output_content_type": part.content_type,
            "operation_reminder_id": this.vidProject.batch_data && this.vidProject.batch_data.operation_reminder_id ? this.vidProject.batch_data.operation_reminder_id : 0,
            "is_concat": 1,
            "consist_of_files_keys": `${Array.from(new Set(part.blends.map(blend => `file_${blend.original_file_id}`))).join(',')}`,
            'parameter_values': []
          },
        }

        // [0:v][0:a][1:v][1:a]concat=n=2:v=1:a=1[outv][outa]
        let fg = `
`;
        let fgFinaly = ``
        let fgInputs:any = {}
        if (part.tags && (parts.length > 1 || !!this.inheritTags)) {
          part.tags.forEach(tag => {
            let pvi = !!tag.parameter_value_id ? tag.parameter_value_id : tag.id;
            if (!outsFg[`resultfile${i + 1}`].parameter_values.filter(k => k.parameter_value_id == pvi).length) {
              outsFg[`resultfile${i + 1}`].parameter_values.push({
                is_primary: tag.is_primary,
                parameter_value_id: pvi
              })
            }
          });
        }

        part.blends.forEach((blend, blendIndex) => { 

          if (blend.tags && (parts.length > 1 || !!this.inheritTags)) {
            blend.tags.forEach(tag => {
              let pvi = !!tag.parameter_value_id ? tag.parameter_value_id : tag.id;
              if (!outsFg[`resultfile${i + 1}`].parameter_values.filter(k => k.parameter_value_id == pvi).length) {
                outsFg[`resultfile${i + 1}`].parameter_values.push({
                  is_primary: tag.is_primary,
                  parameter_value_id: pvi
                })
              }
            });
          }
          if (!(Object.values(executeData.inputs) as any).filter(x => x.original_file_id == blend.original_file_id).length) {
            executeData.inputs[`file_${blend.original_file_id}`] = {
              original_file_id: blend.original_file_id
            }
          }

          if (!(Object.values(fgInputs) as any).filter(x => x.input == `file_${blend.original_file_id}`).length) {
            fgInputs[`[${(Object.values(fgInputs) as any).length}]`] = {
              options: [],
              input: `file_${blend.original_file_id}`
            }
          }

          if (!!blend.has_blur_bg) {           
            // if v file has video
            if (!!blend.is_video_only) {
              if (blendIndex == 0) {
                fg += `[${(Object.values(fgInputs) as any).findIndex(k => k.input == `file_${blend.original_file_id}`)}:v]`
              } else {
                fg += `;
  
[${(Object.values(fgInputs) as any).findIndex(k => k.input == `file_${blend.original_file_id}`)}:v]`
              }
              
      // if v file cuted
              if (blend.from != 0 || blend.to != blend.saveDuration) {
                fg += `
trim=${Math.round(blend.from*1000)}ms:end=${Math.round(blend.to*1000)}ms,`
              }
      
              fg+= `
setpts=PTS-STARTPTS,
format=yuv420p,`
      
              if (!!blend.video_filters_on && !!blend.video_filters) {
                let vidFilters = `
eq=`

                if (blend.video_filters.contrast != 1) {
                  vidFilters += `contrast=${(blend.video_filters.contrast/1000 + 1).toFixed(3)}:`
                }
                if (blend.video_filters.saturation != 1) {
                  vidFilters += `saturation=${(blend.video_filters.saturation/100).toFixed(2)}:`
                }
                if (vidFilters.endsWith(":")) {
                  // Заменяем ':' на ','
                  vidFilters = vidFilters.replace(/:$/, ",");
                  fg+= vidFilters;
                }

                if (blend.video_filters.brightness != 1) {
                  fg += `
lutrgb=r=clipval*${blend.video_filters.brightness}:g=clipval*${blend.video_filters.brightness}:b=clipval*${blend.video_filters.brightness},`;
                }
              }

              if (blend.is_changed) {
                if (blend.rd.params.part_rotate_deg != 0) {
                          fg += `
rotate='angle=${blend.rd.params.part_rotate_deg}*PI/180:out_w=max(in_w,in_h)*2:out_h=max(in_w,in_h)*2:fillcolor=black',`
                }
                fg += `
crop='out_w=${blend.rd.params.part_crop_width}:out_h=${blend.rd.params.part_crop_height}:x=${blend.rd.params.part_crop_x}:y=${blend.rd.params.part_crop_y}',
scale='width=${blend.rd.params.part_scale_width}:height=${blend.rd.params.part_scale_height}:force_original_aspect_ratio=decrease:force_divisible_by=2',
setsar=sar=1`
              } else {
                fg += `
scale=width=${this.projectForm.value.width}:height=${this.projectForm.value.height}:force_original_aspect_ratio=decrease:force_divisible_by=2,
setsar=sar=1`
              }
      
      
      // if v file rate changed
              if (blend.part_speed_rate != 1) {
                fg += `,
setpts=${1/blend.part_speed_rate}*PTS`
              }
      
              fg += `
[tempv${i}b${blendIndex}]`
      
              // outsFg[`resultfile${i + 1}`].options.push({
              //   "-map": `[outv${i}b${blendIndex}]`
              // })
            } else {
              fg += `color=size=${this.projectForm.value.width}x${this.projectForm.value.height}:rate=25:d=${blend.duration/(1/blend.part_speed_rate)} 
[tempv${i}b${blendIndex}]`
            }
            
            fg += `;
  
[${(Object.values(fgInputs) as any).findIndex(k => k.input == `file_${blend.original_file_id}`)}:v]`
            if (blend.from != 0 || blend.to != blend.saveDuration) {
              fg += `
trim=${Math.round(blend.from*1000)}ms:end=${Math.round(blend.to*1000)}ms,`
            }
  
            // crop=${this.projectForm.value.width}:${this.projectForm.value.height}:${(this.projectForm.value.width > this.projectForm.value.height) ? 0 : '(in_w-' + this.projectForm.value.width + ')/2'}:${(this.projectForm.value.width > this.projectForm.value.height) ? '(in_h-' + this.projectForm.value.height + ')/2' : 0},
            fg+= `
setpts=PTS-STARTPTS,
format=yuv420p,
scale='width=${(this.projectForm.value.width > this.projectForm.value.height) ? this.projectForm.value.width : -2}:height=${(this.projectForm.value.width > this.projectForm.value.height) ? -2 : this.projectForm.value.height}:force_original_aspect_ratio=decrease:force_divisible_by=2',
crop=${this.projectForm.value.width}:${this.projectForm.value.height}:(in_w-${this.projectForm.value.width})/2:(in_h-${this.projectForm.value.height})/2,
boxblur=luma_radius=30:luma_power=6,
setsar=sar=1`

          if (blend.part_speed_rate != 1) {
            fg += `,
setpts=${1/blend.part_speed_rate}*PTS`
          }

          fg+= `
[blurred_bg${i}];
  
[blurred_bg${i}b${blendIndex}][tempv${i}b${blendIndex}]overlay=shortest=1:x=${blend.rd.params.part_pad_x}:y=${blend.rd.params.part_pad_y}
[outv${i}b${blendIndex}]`
            // if v file has audio
            if (!!blend.is_audio_only) {      
              fg += `;
      
[${(Object.values(fgInputs) as any).findIndex(k => k.input == `file_${blend.original_file_id}`)}:a]`;
      
      // if v file cuted
              if (blend.from != 0 || blend.to != blend.saveDuration) {
                fg += `
atrim=start=${Math.round(blend.from*1000)}ms:end=${Math.round(blend.to*1000)}ms,
asetpts=PTS-STARTPTS,`
              }
      
      // if v file rate changed
              if (blend.part_speed_rate != 1) {
                fg += `
asetpts=${1/blend.part_speed_rate}*PTS,
${this.generateAtempoFilters(blend.part_speed_rate)},`
              }
      
              fg += `
aformat=channel_layouts=stereo`;
      
      // if v file volume changed
              if (blend.volume != 1) {
                fg += `,
volume=${blend.volume}`
              }
      
              fg += `
[outa${i}b${blendIndex}]`
      
              // outsFg[`resultfile${i + 1}`].options.push({
              //   "-map": `[outa${i}b${blendIndex}]`
              // })
            } else {
              fg += `;

anullsrc=channel_layout=stereo:sample_rate=44100:d=${0.1}
[outa${i}b${blendIndex}]`;
            }
            fgFinaly += `[outv${i}b${blendIndex}][outa${i}b${blendIndex}]`
          } else {
            // if v file has video
            if (!!blend.is_video_only) {
              if (blendIndex == 0) {
                fg += `[${(Object.values(fgInputs) as any).findIndex(k => k.input == `file_${blend.original_file_id}`)}:v]`
              } else {
                fg += `;
  
[${(Object.values(fgInputs) as any).findIndex(k => k.input == `file_${blend.original_file_id}`)}:v]`
              }
              
      // if v file cuted
              if (blend.from != 0 || blend.to != blend.saveDuration) {
                fg += `
trim=${Math.round(blend.from*1000)}ms:end=${Math.round(blend.to*1000)}ms,`
              }
      
              fg+= `
setpts=PTS-STARTPTS,
format=yuv420p,`
      
              if (!!blend.video_filters_on && !!blend.video_filters) {
                let vidFilters = `
eq=`

                if (blend.video_filters.contrast != 1) {
                  vidFilters += `contrast=${(blend.video_filters.contrast/1000 + 1).toFixed(3)}:`
                }
                if (blend.video_filters.saturation != 1) {
                  vidFilters += `saturation=${(blend.video_filters.saturation/100).toFixed(2)}:`
                }
                if (vidFilters.endsWith(":")) {
                  // Заменяем ':' на ','
                  vidFilters = vidFilters.replace(/:$/, ",");
                  fg+= vidFilters;
                }

                if (blend.video_filters.brightness != 1) {
                  fg += `
lutrgb=r=clipval*${blend.video_filters.brightness}:g=clipval*${blend.video_filters.brightness}:b=clipval*${blend.video_filters.brightness},`;
                }
              }
              
              if (blend.is_changed) {
                if (blend.rd.params.part_rotate_deg != 0) {
                          fg += `
rotate='angle=${blend.rd.params.part_rotate_deg}*PI/180:out_w=max(in_w,in_h)*2:out_h=max(in_w,in_h)*2:fillcolor=black',`
                }
                fg += `
crop='out_w=${blend.rd.params.part_crop_width}:out_h=${blend.rd.params.part_crop_height}:x=${blend.rd.params.part_crop_x}:y=${blend.rd.params.part_crop_y}',
scale='width=${blend.rd.params.part_scale_width}:height=${blend.rd.params.part_scale_height}:force_original_aspect_ratio=decrease:force_divisible_by=2',
pad=width=${this.projectForm.value.width}:height=${this.projectForm.value.height}:x=${blend.rd.params.part_pad_x}:y=${blend.rd.params.part_pad_y},
setsar=sar=1`
              } else {
                fg += `
scale=width=${this.projectForm.value.width}:height=${this.projectForm.value.height}:force_original_aspect_ratio=decrease:force_divisible_by=2,
pad=width=${this.projectForm.value.width}:height=${this.projectForm.value.height}:x=${blend.rd.params.part_pad_x}:y=${blend.rd.params.part_pad_y},
setsar=sar=1`
              }
      
      
      // if v file rate changed
              if (blend.part_speed_rate != 1) {
                fg += `,
setpts=${1/blend.part_speed_rate}*PTS`
              }
      
              fg += `
[outv${i}b${blendIndex}]`
      
              // outsFg[`resultfile${i + 1}`].options.push({
              //   "-map": `[outv${i}b${blendIndex}]`
              // })
            } else {
              fg += `color=size=${this.projectForm.value.width}x${this.projectForm.value.height}:rate=25:d=${blend.duration/(1/blend.part_speed_rate)} 
[outv${i}b${blendIndex}]`
            }
            
            // if v file has audio
            if (!!blend.is_audio_only) {
      
              fg += `;
      
[${(Object.values(fgInputs) as any).findIndex(k => k.input == `file_${blend.original_file_id}`)}:a]`;
      
      // if v file cuted
              if (blend.from != 0 || blend.to != blend.saveDuration) {
                fg += `
atrim=start=${Math.round(blend.from*1000)}ms:end=${Math.round(blend.to*1000)}ms,
asetpts=PTS-STARTPTS,`
              }
      
      // if v file rate changed
              if (blend.part_speed_rate != 1) {
                fg += `
asetpts=${1/blend.part_speed_rate}*PTS,
${this.generateAtempoFilters(blend.part_speed_rate)},`
              }
      
              fg += `
aformat=channel_layouts=stereo`;
      
      // if v file volume changed
              if (blend.volume != 1) {
                fg += `,
volume=${blend.volume}`
              }
      
              fg += `
[outa${i}b${blendIndex}]`
      
              // outsFg[`resultfile${i + 1}`].options.push({
              //   "-map": `[outa${i}b${blendIndex}]`
              // })
            } else {
              fg += `;

anullsrc=channel_layout=stereo:sample_rate=44100:d=${0.1}
[outa${i}b${blendIndex}]`;
            }
            fgFinaly += `[outv${i}b${blendIndex}][outa${i}b${blendIndex}]`
          }

        }) 

        fgFinaly += `concat=n=${part.blends.length}:v=1:a=1[outv${i}][outa${i}]`

        outsFg[`resultfile${i + 1}`].options.push({
          "-map": `[outv${i}]`
        })
        outsFg[`resultfile${i + 1}`].options.push({
          "-map": `[outa${i}]`
        })
        fg += `;

${fgFinaly}`;

        console.log('fgInputs', fgInputs)
        executeData.filtergraphs[`fg${i + 1}`] = {
          inputs: fgInputs,
          filtergraph: fg,
          outputs: outsFg
        } 
      } else {

        let fg = `
`;

        let outsFg = {
          [`resultfile${i + 1}`]: {
            "options": [],
            "output_location": "/exported-files",
            "output_filename": part.extension ? part.filename.replace('.'+part.extension, '') + `.${part.extension}` : part.filename,
            "output_content_type": part.content_type,
            "operation_reminder_id": this.vidProject.batch_data && this.vidProject.batch_data.operation_reminder_id ? this.vidProject.batch_data.operation_reminder_id : 0,
            "is_part": 1,
            "part_of_files_keys": `file_${part.original_file_id}`,
            "parameter_values": []
          },
        }

        if (part.tags && (parts.length > 1 || !!this.inheritTags)) {
          part.tags.forEach(tag => {
            let pvi = !!tag.parameter_value_id ? tag.parameter_value_id : tag.id;
            if (!outsFg[`resultfile${i + 1}`].parameter_values.filter(k => k.parameter_value_id == pvi).length) {
              outsFg[`resultfile${i + 1}`].parameter_values.push({
                is_primary: tag.is_primary,
                parameter_value_id: pvi
              })
            }
          });
        }

        // if v file has_blur_bg
        if (!!part.has_blur_bg) {
          // if v file has video
          if (!!part.is_video_only) {
            fg += `[0:v]`
            
    // if v file cuted
            if (part.from != 0 || part.to != part.saveDuration) {
              fg += `
trim=${Math.round(part.from*1000)}ms:end=${Math.round(part.to*1000)}ms,`
            }
  
            fg+= `
setpts=PTS-STARTPTS,
format=yuv420p,`

            if (!!part.video_filters_on && !!part.video_filters) {
              let vidFilters = `
eq=`

              if (part.video_filters.contrast != 1) {
                vidFilters += `contrast=${(part.video_filters.contrast/1000 + 1).toFixed(3)}:`
              }
              if (part.video_filters.saturation != 1) {
                vidFilters += `saturation=${(part.video_filters.saturation/100).toFixed(2)}:`
              }
              if (vidFilters.endsWith(":")) {
                // Заменяем ':' на ','
                vidFilters = vidFilters.replace(/:$/, ",");
                fg+= vidFilters;
              }

              if (part.video_filters.brightness != 1) {
                fg += `
lutrgb=r=clipval*${part.video_filters.brightness}:g=clipval*${part.video_filters.brightness}:b=clipval*${part.video_filters.brightness},`;
              }
            }
  
            if (part.is_changed) {
              if (part.rd.params.part_rotate_deg != 0) {
                        fg += `
rotate='angle=${part.rd.params.part_rotate_deg}*PI/180:out_w=max(in_w,in_h)*2:out_h=max(in_w,in_h)*2:fillcolor=black',`
              }
              fg += `
crop='out_w=${part.rd.params.part_crop_width}:out_h=${part.rd.params.part_crop_height}:x=${part.rd.params.part_crop_x}:y=${part.rd.params.part_crop_y}',
scale='width=${part.rd.params.part_scale_width}:height=${part.rd.params.part_scale_height}:force_original_aspect_ratio=decrease:force_divisible_by=2',
setsar=sar=1`
            } else {
              fg += `
scale=width=${this.projectForm.value.width}:height=${this.projectForm.value.height}:force_original_aspect_ratio=decrease:force_divisible_by=2,
setsar=sar=1`
            }
  
  
    // if v file rate changed
            if (part.part_speed_rate != 1) {
              fg += `,
setpts=${1/part.part_speed_rate}*PTS`
            }
  
            fg += `
[tempv${i}]`
  

          } else {
            fg += `color=size=${this.projectForm.value.width}x${this.projectForm.value.height}:rate=25:d=${part.duration/(1/part.part_speed_rate)} 
[tempv${i}`
          }

          fg += `;

[0:v]`
          if (part.from != 0 || part.to != part.saveDuration) {
            fg += `
trim=${Math.round(part.from*1000)}ms:end=${Math.round(part.to*1000)}ms,`
          }

          // crop=${this.projectForm.value.width}:${this.projectForm.value.height}:${(this.projectForm.value.width > this.projectForm.value.height) ? 0 : '(in_w-' + this.projectForm.value.width + ')/2'}:${(this.projectForm.value.width > this.projectForm.value.height) ? '(in_h-' + this.projectForm.value.height + ')/2' : 0},
          fg+= `
setpts=PTS-STARTPTS,
format=yuv420p,
scale='width=${(this.projectForm.value.width > this.projectForm.value.height) ? this.projectForm.value.width : -2}:height=${(this.projectForm.value.width > this.projectForm.value.height) ? -2 : this.projectForm.value.height}:force_original_aspect_ratio=decrease:force_divisible_by=2',
crop=${this.projectForm.value.width}:${this.projectForm.value.height}:(in_w-${this.projectForm.value.width})/2:(in_h-${this.projectForm.value.height})/2,
boxblur=luma_radius=30:luma_power=6,
setsar=sar=1`

          if (part.part_speed_rate != 1) {
            fg += `,
setpts=${1/part.part_speed_rate}*PTS`
          }

          fg+= `
[blurred_bg${i}];

[blurred_bg${i}][tempv${i}]overlay=shortest=1:x=${part.rd.params.part_pad_x}:y=${part.rd.params.part_pad_y}`

          if (part.subs_on) {
            fg += `
[stagev${i}]`
            
            fg+= `;
            
[stagev${i}]
ass=[text_file_${i}]
[outv${i}]`
          } else {
            fg += `
[outv${i}]`
          }
          
          outsFg[`resultfile${i + 1}`].options.push({
            "-map": `[outv${i}]`
          })
          // if v file has audio
          if (!!part.is_audio_only) {
            fg += `;
  
[0:a]`;
  
    // if v file cuted
            if (part.from != 0 || part.to != part.saveDuration) {
              fg += `
atrim=start=${Math.round(part.from*1000)}ms:end=${Math.round(part.to*1000)}ms,
asetpts=PTS-STARTPTS,`
            }
  
    // if v file rate changed
            if (part.part_speed_rate != 1) {
              fg += `
asetpts=${1/part.part_speed_rate}*PTS,
${this.generateAtempoFilters(part.part_speed_rate)},`
            }
  
            fg += `
aformat=channel_layouts=stereo`;
  
    // if v file volume changed
            if (part.volume != 1) {
              fg += `,
volume=${part.volume}`
            }
  
            fg += `
[outa${i}]`
  
  
          } else {
            fg += `;
  
  anullsrc=channel_layout=stereo:sample_rate=44100:d=${0.1}
  [outa${i}]`;

          }
          outsFg[`resultfile${i + 1}`].options.push({
            "-map": `[outa${i}]`
          })
        } else {
          // if v file has video
          if (!!part.is_video_only) {
            fg += `[0:v]`
            
    // if v file cuted
            if (part.from != 0 || part.to != part.saveDuration) {
              fg += `
trim=${Math.round(part.from*1000)}ms:end=${Math.round(part.to*1000)}ms,`
            }
  
            fg+= `
setpts=PTS-STARTPTS,
format=yuv420p,`
  

            if (!!part.video_filters_on && !!part.video_filters) {
              let vidFilters = `
eq=`
   
              if (part.video_filters.contrast != 1) {
                vidFilters += `contrast=${(part.video_filters.contrast/1000 + 1).toFixed(3)}:`
              }
              if (part.video_filters.saturation != 1) {
                vidFilters += `saturation=${(part.video_filters.saturation/100).toFixed(2)}:`
              }
              if (vidFilters.endsWith(":")) {
                // Заменяем ':' на ','
                vidFilters = vidFilters.replace(/:$/, ",");
                fg+= vidFilters;
              }

              if (part.video_filters.brightness != 1) {
                fg += `
lutrgb=r=clipval*${part.video_filters.brightness}:g=clipval*${part.video_filters.brightness}:b=clipval*${part.video_filters.brightness},`;
              }
            }

            if (part.is_changed) {
              if (part.rd.params.part_rotate_deg != 0) {
                        fg += `
rotate='angle=${part.rd.params.part_rotate_deg}*PI/180:out_w=max(in_w,in_h)*2:out_h=max(in_w,in_h)*2:fillcolor=black',`
              }
              fg += `
crop='out_w=${part.rd.params.part_crop_width}:out_h=${part.rd.params.part_crop_height}:x=${part.rd.params.part_crop_x}:y=${part.rd.params.part_crop_y}',
scale='width=${part.rd.params.part_scale_width}:height=${part.rd.params.part_scale_height}:force_original_aspect_ratio=decrease:force_divisible_by=2',
pad=width=${this.projectForm.value.width}:height=${this.projectForm.value.height}:x=${part.rd.params.part_pad_x}:y=${part.rd.params.part_pad_y},
setsar=sar=1`
            } else {
              fg += `
scale=width=${this.projectForm.value.width}:height=${this.projectForm.value.height}:force_original_aspect_ratio=decrease:force_divisible_by=2,
pad=width=${this.projectForm.value.width}:height=${this.projectForm.value.height}:x=${part.rd.params.part_pad_x}:y=${part.rd.params.part_pad_y},
setsar=sar=1`
            }
  
  
    // if v file rate changed
            if (part.part_speed_rate != 1) {
              fg += `,
setpts=${1/part.part_speed_rate}*PTS`
            }
  
            if (part.subs_on) {
              fg += `
[tempv${i}]`
              
              fg+= `;
              
[tempv${i}]
ass=[text_file_${i}]
[outv${i}]`
            } else {
              fg += `
[outv${i}]`
            }
          } else {
            fg += `color=size=${this.projectForm.value.width}x${this.projectForm.value.height}:rate=25:d=${part.duration/(1/part.part_speed_rate)} 
[outv${i}]`
        }
        outsFg[`resultfile${i + 1}`].options.push({
          "-map": `[outv${i}]`
        })
          
          // if v file has audio
          if (!!part.is_audio_only) {
            if (!!part.is_video_only) {
              fg += `;`
            }
  
            fg += `
  
[0:a]`;
  
    // if v file cuted
            if (part.from != 0 || part.to != part.saveDuration) {
              fg += `
atrim=start=${Math.round(part.from*1000)}ms:end=${Math.round(part.to*1000)}ms,
asetpts=PTS-STARTPTS,`
            }
  
    // if v file rate changed
            if (part.part_speed_rate != 1) {
              fg += `
asetpts=${1/part.part_speed_rate}*PTS,
${this.generateAtempoFilters(part.part_speed_rate)},`
            }
  
            fg += `
aformat=channel_layouts=stereo`;
  
    // if v file volume changed
            if (part.volume != 1) {
              fg += `,
volume=${part.volume}`
            }
  
            fg += `
[outa${i}]`
  
          } else {
            fg += `;
  
  anullsrc=channel_layout=stereo:sample_rate=44100:d=${0.1}
  [outa${i}]`;

          }
          outsFg[`resultfile${i + 1}`].options.push({
            "-map": `[outa${i}]`
          })
        }
        
        executeData.filtergraphs[`fg${i + 1}`] = {
          inputs: {
            '[0]': {
              options: [],
              input: `file_${part.original_file_id}`
            }
          },
          filtergraph: fg,
          outputs: outsFg
        }
      }


    });

    let editProjData:any = {
      name: this.vidProject.name,
      editor_version: this.version.toString(),
      output_location: this.data.file.location == "/" ? this.data.file.location + `cut files of ${this.data.file.filename.replace('.'+this.data.file.extension, '')}` : this.data.file.location + `/cut files of ${this.data.file.filename.replace('.'+this.data.file.extension, '')}`,
      output_width: this.projectForm.value.width,
      output_height: this.projectForm.value.height,
      output_frame_rate: this.rateForm.value.output_frame_rate,
      output_audio_bit_rate: this.rateForm.value.output_audio_bit_rate,
      output_bit_rate: this.rateForm.value.output_bit_rate * 1000000,
      waiting_time_limit: this.waiting_time_limit.value,
      execute_data: executeData
    }
    console.log("executeData", executeData)

    if (this.vidProject.batch_data && this.vidProject.batch_data.parameter_id && this.vidProject.batch_data.headCols && !!this.vidProject.batch_data.headCols.length) {
      this.vidProject.batch_data.video_project_task_create = []
      parts.forEach((part, partInd) => {       
        if (!part.is_video_only && !part.is_audio_only) {
          return
        }
        let batchCards = {
          custom_id: partInd + 1,
          video_project_files_custom_ids: `resultfile${partInd + 1}`,
          task_channels: []
        }

        this.vidProject.batch_data.headCols.forEach(headCol => {
          let check = true;
          if (!!this.vidProject.batch_data.selectedCols) {
            if (!this.vidProject.batch_data.selectedCols.includes(headCol)) {
              check = false
            }
          }
          if (check) {
            if (part.publications && part.publications.length) {
              let publArr = [];
              JSON.parse(JSON.stringify(part.publications, this.getCircularReplacer())).forEach(pub => {
                if (this.getChannelById(pub.channel_id).parameterValuesToChannel.filter(k => k.parameter_value_id == headCol).length) {
                  let cont_name = ''
                  if (part.postNames && part.postNames[headCol] && part.postNames[headCol].name) {
                    cont_name = part.postNames[headCol].name
                  } else {
                    cont_name = part.form.value.name
                  }
                  pub.content_name = pub.content_type_id && this.isYouTube(pub.content_type_id) && cont_name.length > 100 ? cont_name.slice(0,97)+'...' : cont_name;
                  pub.content_description = cont_name;

                  // pub.content_name = pub.content_type_id && this.isYouTube(pub.content_type_id) && part.form.value.name.length > 100 ? part.form.value.name.slice(0,97)+'...' : part.form.value.name;
                  // pub.content_description = part.form.value.name;
                  delete pub.task_channel_files[0].video_project_file_id;
                  // pub.task_channel_files[0].video_project_file_custom_id = partInd + 1;
                  pub.task_channel_files[0].video_project_file_id = `resultfile${partInd + 1}`;

                  if (pub.content_type_id && this.isTikTok(pub.content_type_id)) {
                    pub.publishing_params = {
                      privacy_level : 'PUBLIC_TO_EVERYONE',
                      disable_duet: true,
                      disable_stitch: true,
                      disable_comment: false,
                      brand_content_toggle: false,
                      brand_organic_toggle: false,
                    }
                  }
                  if (pub.content_type_id && this.isYouTube(pub.content_type_id)) {
                    pub.publishing_params = {
                      privacy : 'private',
                      made_for_kids: !!part.made_for_kids ? 1 : 0
                    }
                  }

                  publArr.push(pub)
                }
              })

              batchCards.task_channels.push(publArr);
            }
          }
        });

        this.vidProject.batch_data.video_project_task_create.push(batchCards);
      });

      editProjData.batch_data = this.vidProject.batch_data;
    }
    // return 
    this.attachSubscriptions(
      this.fileService.resetVideoProject(this.vidProject.id, {}, this.data.company_id).pipe(
        switchMap(resetProject => {
          return this.fileService.editVideoProject(this.vidProject.id, editProjData, this.data.company_id).pipe(
            switchMap(project => {
              if (this.is_create_cards.value && parts.filter(o => !!o.is_video_only || !!o.is_audio_only).length > 0) {
                let cardsArr = []
                parts.forEach((cut, index) => {
                  if (!cut.is_video_only && !cut.is_audio_only) {
                    return
                  }
                  let formData = JSON.parse(JSON.stringify(cut.form.value, this.getCircularReplacer()))
    
                  delete formData.type
                  formData.create_parameter_values_to_task = []
              
                  cut.parameters.filter(x => x.id != 0 && x.activeValues.length > 0).forEach(param => {
                    param.activeValues.forEach(el => {
                      formData.create_parameter_values_to_task.push({
                        parameter_id : param.id,
                        parameter_value_id : el.id
                      })
                    })
                  })
    
                  if (cut.jobs && cut.jobs.length) {
                    cut.jobs.forEach(job => {
                      delete job.newExecutor;
                      job.create_parameter_values_to_task = []
                      if (job.parameters && job.parameters.length > 0) {
                        job.parameters[0].activeValues.forEach(el => {
                          job.create_parameter_values_to_task.push({
                            parameter_id : el.parameter_id,
                            parameter_value_id : el.id
                          })
                        })
                      }
    
                      delete job.mobEmployees;
                      delete job.parameters;
                    })
    
                    formData.create_task_operations = cut.jobs.slice();
                  }
    
                  let template;
                  let template_id;
                  if (cut.form.value.type != 0) {
                    if (this.form.value.template_id) {
                      template_id = this.form.value.template_id
                    }
                    template = JSON.parse(JSON.stringify(this.getItemById(this.taskTemplates, cut.form.get('template_id').value).template_data, this.getCircularReplacer()))
                  }
    
                  let u:any = {
                    video_project_id: project.id,
                    company_id: this.data.target_company_id,
                    template_data: cut.form.value.type == 0 ? formData : Object.assign(template, {name: cut.form.value.name}),
                    video_project_files: `resultfile${index + 1}`,
                    custom_id: +index + 1
                  }
    
                  if (template_id) {
                    u.task_template_id = template_id;
                  }
    
                  if (this.vidProject.batch_data && this.vidProject.batch_data.parameter_id) {
                    let tag_id;
                    if (parts[0] && parts[0].parameterValuesToTask.find(q => this.vidProject.batch_data.parameter_id == q.parameter_id)) {
                      tag_id = parts[0].parameterValuesToTask.find(q => this.vidProject.batch_data.parameter_id == q.parameter_id).parameter_value_id;
                    }
                    let allHasOne:boolean = true;
    
                    if (tag_id) {
                      parts.forEach(q => {
                        if (!q.is_video_only && !q.is_audio_only) {
                          return
                        }
                        if (q.parameterValuesToTask && q.parameterValuesToTask.length && q.parameterValuesToTask.filter(w => w.parameter_value_id == tag_id).length == 0) {
                          allHasOne = false;
                        }
                      });
    
                      if (allHasOne) {
                        if (cut.publications && cut.publications.length) {
                          let publArr = []
                          cut.publications.forEach(pub => {
                            if (this.getChannelById(pub.channel_id).parameterValuesToChannel.filter(k => k.parameter_value_id == tag_id).length) {
                              let cont_name = ''
                              if (cut.postNames && cut.postNames[0] && cut.postNames[0].name) {
                                cont_name = cut.postNames[0].name
                              } else {
                                cont_name = cut.form.value.name
                              }
                              pub.content_name = pub.content_type_id && this.isYouTube(pub.content_type_id) && cont_name.length > 100 ? cont_name.slice(0,97)+'...' : cont_name;
                              pub.content_description = cont_name;
    
                              // pub.content_name = pub.content_type_id && this.isYouTube(pub.content_type_id) && cut.form.value.name.length > 100 ? cut.form.value.name.slice(0,97)+'...' : cut.form.value.name;
                              // pub.content_description = cut.form.value.name;
                              pub.task_channel_files[0].video_project_file_id = u.video_project_files
    
                              if (pub.content_type_id && this.isTikTok(pub.content_type_id)) {
                                pub.publishing_params = {
                                  privacy_level : 'PUBLIC_TO_EVERYONE',
                                  disable_duet: true,
                                  disable_stitch: true,
                                  disable_comment: false,
                                  brand_content_toggle: false,
                                  brand_organic_toggle: false,
                                }
                              }
                              if (pub.content_type_id && this.isYouTube(pub.content_type_id)) {
                                pub.publishing_params = {
                                  privacy : 'private',
                                  made_for_kids: !!cut.made_for_kids ? 1 : 0
                                }
                              }
    
                              publArr.push(pub)
                            }
                          })
    
                          u.task_channels = publArr;
                        }
                      } else {
                        if (cut.publications && cut.publications.length) {
                          cut.publications.forEach(pub => {
                            let cont_name = ''
                            if (cut.postNames && cut.postNames[0] && cut.postNames[0].name) {
                              cont_name = cut.postNames[0].name
                            } else {
                              cont_name = cut.form.value.name
                            }
                            pub.content_name = pub.content_type_id && this.isYouTube(pub.content_type_id) && cont_name.length > 100 ? cont_name.slice(0,97)+'...' : cont_name;
                            pub.content_description = cont_name;
    
                            // pub.content_name = pub.content_type_id && this.isYouTube(pub.content_type_id) && cut.form.value.name.length > 100 ? cut.form.value.name.slice(0,97)+'...' : cut.form.value.name;
                            // pub.content_description = cut.form.value.name;
                            pub.task_channel_files[0].video_project_file_id = u.video_project_files
    
                            if (pub.content_type_id && this.isTikTok(pub.content_type_id)) {
                              pub.publishing_params = {
                                privacy_level : 'PUBLIC_TO_EVERYONE',
                                disable_duet: true,
                                disable_stitch: true,
                                disable_comment: false,
                                brand_content_toggle: false,
                                brand_organic_toggle: false,
                              }
                            }
                            if (pub.content_type_id && this.isYouTube(pub.content_type_id)) {
                              pub.publishing_params = {
                                privacy : 'private',
                                made_for_kids: !!cut.made_for_kids ? 1 : 0
                              }
                            }
                          })
    
                          u.task_channels = cut.publications;
                        }
                      }
                    } else {
                      if (cut.publications && cut.publications.length) {
                        cut.publications.forEach(pub => {
                          let cont_name = ''
                          if (cut.postNames && cut.postNames[0] && cut.postNames[0].name) {
                            cont_name = cut.postNames[0].name
                          } else {
                            cont_name = cut.form.value.name
                          }
                          pub.content_name = pub.content_type_id && this.isYouTube(pub.content_type_id) && cont_name.length > 100 ? cont_name.slice(0,97)+'...' : cont_name;
                          pub.content_description = cont_name;
    
                          // pub.content_name = pub.content_type_id && this.isYouTube(pub.content_type_id) && cut.form.value.name.length > 100 ? cut.form.value.name.slice(0,97)+'...' : cut.form.value.name;
                          // pub.content_description = cut.form.value.name;
                          pub.task_channel_files[0].video_project_file_id = u.video_project_files
    
                          if (pub.content_type_id && this.isTikTok(pub.content_type_id)) {
                            pub.publishing_params = {
                              privacy_level : 'PUBLIC_TO_EVERYONE',
                              disable_duet: true,
                              disable_stitch: true,
                              disable_comment: false,
                              brand_content_toggle: false,
                              brand_organic_toggle: false,
                            }
                          }
                          if (pub.content_type_id && this.isYouTube(pub.content_type_id)) {
                            pub.publishing_params = {
                              privacy : 'private',
                              made_for_kids: !!cut.made_for_kids ? 1 : 0
                            }
                          }
                        })
    
                        u.task_channels = cut.publications;
                      }
                    }
                  } else {
                    if (cut.publications && cut.publications.length) {
                      cut.publications.forEach(pub => {
                        let cont_name = ''
                        if (cut.postNames && cut.postNames[0] && cut.postNames[0].name) {
                          cont_name = cut.postNames[0].name
                        } else {
                          cont_name = cut.form.value.name
                        }
                        pub.content_name = pub.content_type_id && this.isYouTube(pub.content_type_id) && cont_name.length > 100 ? cont_name.slice(0,97)+'...' : cont_name;
                        pub.content_description = cont_name;
    
                        // pub.content_name = pub.content_type_id && this.isYouTube(pub.content_type_id) && cut.form.value.name.length > 100 ? cut.form.value.name.slice(0,97)+'...' : cut.form.value.name;
                        // pub.content_description = cut.form.value.name;
                        pub.task_channel_files[0].video_project_file_id = u.video_project_files
    
                        if (pub.content_type_id && this.isTikTok(pub.content_type_id)) {
                          pub.publishing_params = {
                            privacy_level : 'PUBLIC_TO_EVERYONE',
                            disable_duet: true,
                            disable_stitch: true,
                            disable_comment: false,
                            brand_content_toggle: false,
                            brand_organic_toggle: false,
                          }
                        }
                        if (pub.content_type_id && this.isYouTube(pub.content_type_id)) {
                          pub.publishing_params = {
                            privacy : 'private',
                            made_for_kids: !!cut.made_for_kids ? 1 : 0
                          }
                        }
                      })
    
                      u.task_channels = cut.publications;
                    }
                  }
    
                  cardsArr.push(u)
                  
                })
                console.log("cardsArr", cardsArr)
                return forkJoin([...cardsArr.map(cardData => this.fileService.createVideoProjectTaskFile(cardData, this.data.company_id))]).pipe(
                  switchMap(cardsReq => {
                    return this.fileService.editVideoProject(project.id, {
                      is_draft: !!is_draft ? 1 : 0,
                      is_to_process: !!is_draft ? 0 : 1,
                      is_batch_apply: !!project.batch_data && !is_draft ? 1 : 0 
                    }, this.data.company_id)
                  })
                )
              } else {
                return this.fileService.editVideoProject(project.id, {
                  is_draft: !!is_draft ? 1 : 0,
                  is_to_process: !!is_draft ? 0 : 1,
                  is_batch_apply: !!project.batch_data && !is_draft ? 1 : 0 
                }, this.data.company_id)
              }
            }),
            switchMap(res => {
              console.log("END switchMap", !!this.is_create_vid_relations.value || !!this.is_create_aud_relations.value)
              if (!!this.is_create_vid_relations.value || !!this.is_create_aud_relations.value) {
                let consistsIds = [];
    
                this.data.task.consistOfTasks.forEach(element => {
                  consistsIds.push(element.id)
                });
    
                let x = {
                  consist_of_task_id: [...consistsIds]
                }
                
                let addTasks = [];
    
                if (!!this.is_create_vid_relations.value) {
                  this.files.video.forEach((pFile) => {
                    if (addTasks.filter(x => x.task_id == pFile.task.id).length == 0 && pFile.task.id != this.data.task.id) {
                      addTasks.push(pFile)
                      x.consist_of_task_id.push(pFile.task_id)
                    }
                  });
                }
                if (!!this.is_create_aud_relations.value) {
                  this.files.audio.forEach((pFile) => {
                    if (addTasks.filter(x => x.task_id == pFile.task.id).length == 0 && pFile.task.id != this.data.task.id) {
                      addTasks.push(pFile)
                      x.consist_of_task_id.push(pFile.task_id)
                    }
                  });
                }
    
                // this.tracks.filter(t => (!!this.is_create_vid_relations.value && !!this.is_create_aud_relations.value) ? true : (!!this.is_create_vid_relations.value ? t.type == 'video' : t.type == 'audio')).forEach(trackEl => {
                //   trackEl.files.forEach((trackElFile) => {
                //     if (addTasks.filter(x => x.task_id != this.data.task.id).length == 0) {
                //       addTasks.push(trackElFile.file)
                //       x.consist_of_task_id.push(trackElFile.file.task_id)
                //     }
                //   });
                // })
    
                console.log("this.data.task.consist_of_count != x.consist_of_task_id.length", this.data.task.consist_of_count != x.consist_of_task_id.length)
                if (this.data.task.consist_of_count != x.consist_of_task_id.length) {
                  return this.taskService.editTask(this.data.task.id, x, this.data.target_company_id).pipe(
                    switchMap(task => {
                      let sortData = [];
      
                      x.consist_of_task_id.forEach((el, i) => {
                        sortData.push(
                          {
                            "path": '/api/task-partition/register/',
                            "query": {'company_id': this.data.target_company_id},
                            "method": "POST",
                            "body": {
                              [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
                              company_id: this.data.target_company_id,
                              consist_of_task_id: this.data.task.id,
                              part_of_task_id: el,
                              consist_of_order: i,
                              part_of_order: null
                            }
                          }
                        )
                      });
    
                      return this.taskService.multiRequest(sortData).pipe(map(() => res))
                    })
                  )
                } else {
                  return of(res)
                }
              } else {
                return of(res)
              }
            }),
            switchMap(res => {
              let savedTracks = JSON.parse(JSON.stringify(this.tracks, this.getCircularReplacer()));
              savedTracks.forEach((trackEl, trackInd) => {
                trackEl.parts.forEach((part, partInd) => {
                  delete part.blobSrc
                  delete part.subscr
                });
              })
              let savedFiles = JSON.parse(JSON.stringify(this.files, this.getCircularReplacer()));
              savedFiles.video.forEach(element => {
                delete element.blob
                delete element.blobSrc
              });
              savedFiles.audio.forEach(element => {
                delete element.blob
                delete element.blobSrc
              });
    
              let raw = {
                saveMode: this.saveMode.value,
                is_save_audio: this.is_save_audio.value,
                is_create_cards: this.is_create_cards.value,
                project: this.project,
                savedTags: this.savedTags,
                form: this.form.value,
                rateForm: this.rateForm.value,
                projectForm: this.projectForm.value,
                tracks: savedTracks,
                files: savedFiles,
                inheritTags: this.inheritTags,
                file: this.data.file,
                jobs: this.jobs,
                publications: this.publications
              }
    
              return ((!this.activeHistory || this.activeHistory.data != JSON.stringify(raw, this.getCircularReplacer())) ? this.fileService.saveVideoEditorHistory({
                company_id: this.data.target_company_id,
                video_project_id: this.vidProject.id,
                editor_version: this.version.toString(),
                parent_id: !!this.activeHistory ? this.activeHistory.id : 0,
                data: JSON.stringify(raw, this.getCircularReplacer()),
                is_active: 1
              }, this.data.company_id) : of(this.activeHistory))
            })
          )
        })
      ).subscribe(resp => {
        console.log("save", resp)
        this.isSubmit = false;
        this.close();
      }, error => {
        this.layoutService.showSnackBar({name: ''}, marker(error), SnackBarItem)
        console.log('save error', error)
        this.isSubmit = false;
      })
    )
  }

  generateAtempoFilters(speed_rate) {
    let atempoFilters = [];
    
    if (speed_rate < 0.5) {
        let factor = Math.sqrt(speed_rate);
        while (factor < 0.5) {
            factor = Math.sqrt(factor);
        }
        atempoFilters.push(`atempo=${factor}`);
        atempoFilters.push(`atempo=${speed_rate / factor}`);
    } else if (speed_rate > 2.0) {
        let factor = Math.sqrt(speed_rate);
        while (factor > 2.0) {
            factor = Math.sqrt(factor);
        }
        atempoFilters.push(`atempo=${factor}`);
        atempoFilters.push(`atempo=${speed_rate / factor}`);
    } else {
        atempoFilters.push(`atempo=${speed_rate}`);
    }
    
    return atempoFilters.join(',');
  }

  // output_location: this.data.file.location == "/" ? this.data.file.location + this.data.file.filename.replace('.'+this.data.file.extension, ' cuts') + "/cut files" : this.data.file.location + "/" + this.data.file.filename.replace('.'+this.data.file.extension, ' cuts') + "/cut files",
  save(is_draft:boolean = false) {
    let parts = (this.tracks.filter(x => x.type == 'video').map(k => k.parts) as any).flat(Infinity);
    let audParts = (this.tracks.filter(x => x.type == 'audio').map(k => k.parts) as any).flat(Infinity);
    
    if (parts.length > 1 && this.saveMode.value == 'concat') {
      if (this.is_create_cards.value) {
        if (this.form.value.type == 0) {
          if (this.form.value.name == '') {
            this.submited = true;
            this.layoutService.showSnackBar({name: ''}, marker("Enter a name for the card."), SnackBarItem)
            return
          }
        } else {
          if (this.form.value.name == '' || !this.form.value.template_id) {
            this.submited = true;
            this.layoutService.showSnackBar({name: ''}, marker("You didn't enter a card name or didn't choose a template"), SnackBarItem)
            return
          }
        }
      }

      // оригиналы аудио и видео из которых состоит 
      // consists_of_files_keys

      // addParameterValueToVideoProjectFile
      // parameter_values если: if (res.project.batch_data && res.project.batch_data.nestedTags) {
      if (audParts.length) { 
        this.concatSaveWithAudio(is_draft)
      } else {
        this.concatSave(is_draft)
      }
    } else {
      if (this.is_create_cards.value) {
        parts.filter(x => !!x.is_audio_only || !!x.is_video_only).forEach(x => {
          if (x.form.value.type == 0) {
            if (x.form.value.name == '') {
              this.layoutService.showSnackBar({name: x.name}, marker("Enter a name for the card."), SnackBarItem)
            }
          } else {
            if (x.form.value.name == '' || !x.form.get('template_id').value) {
              this.layoutService.showSnackBar({name: x.name}, marker("You didn't enter a card name or didn't choose a template"), SnackBarItem)
            }
          }
        })
        
        if (parts.filter(x => (!!x.is_audio_only || !!x.is_video_only) && ((x.form.value.type == 0 && x.form.value.name == '') || (x.form.value.type == 1 && (x.form.value.name == '' || !this.getItemById(this.taskTemplates, x.form.get('template_id').value))))).length > 0) {
          console.log("TEST")
          this.submited = true;
          return
        }
        
      }

      // let arr = [el.vPart.id, el.vPart.input_video_project_file_id, concatAudioParts[partInd].id, mixedAudio.id];
      // part_of_files_keys arr.join(',')
       // parameter_values всегда
      if (audParts.length) { 
        this.partsSaveWithAudio(is_draft)
      } else {
        this.partsSave(is_draft)
      }
    }
  }

  checkDefault(part) {
    if (Object.keys(part.saveRD.params).some(key => part.saveRD.params[key] != part.rd.params[key])) {
      part.is_changed = true;
    } else {
      part.is_changed = false;
    }
  }

  calculateSizes(part, project) {
    part.rd.params.part_rotate_deg = part.rd.deg%360;

    part.rd.params.part_crop_x = part.rd.left < 0 ? Math.round(Math.abs(part.rd.left)/part.rd.percentX) : 0;
    part.rd.params.part_crop_y = part.rd.top < 0 ? Math.round(Math.abs(part.rd.top)/part.rd.percentY) : 0;
    part.rd.params.part_pad_x = part.rd.left > 0 ? Math.round(part.rd.left/project.percentX) : 0;
    part.rd.params.part_pad_y = part.rd.top > 0 ? Math.round(part.rd.top/project.percentY) : 0;

    if (part.rd.top < 0) {
      if (part.rd.height - Math.abs(part.rd.top) >= project.height) {
        part.rd.params.part_crop_height = Math.round(project.height / part.rd.percentY);
        part.rd.params.part_scale_height = (project.height / project.percentY);
      } else {
        part.rd.params.part_crop_height = Math.round((part.rd.height - Math.abs(part.rd.top)) / part.rd.percentY);
        part.rd.params.part_scale_height = Math.round((part.rd.height - Math.abs(part.rd.top)) / project.percentY);
      }
    } else if (part.rd.top == 0) {
      if (part.rd.height >= project.height) {
        part.rd.params.part_crop_height = Math.round(project.height / part.rd.percentY);
        part.rd.params.part_scale_height = Math.round(project.height / project.percentY);
      } else {
        part.rd.params.part_crop_height = Math.round((part.rd.height) / part.rd.percentY);
        part.rd.params.part_scale_height = Math.round((part.rd.height) / project.percentY);
      }
    } else {
      if (part.rd.height + part.rd.top <= project.height) {
        part.rd.params.part_crop_height = Math.round(part.rd.height / part.rd.percentY);
        part.rd.params.part_scale_height = Math.round(part.rd.height / project.percentY);
      } else {
        part.rd.params.part_crop_height = Math.round((part.rd.height - part.rd.top) / part.rd.percentY);
        part.rd.params.part_scale_height = Math.round((part.rd.height - part.rd.top) / project.percentY);
      }
    }

    if (part.rd.left < 0) {
      if (part.rd.width - Math.abs(part.rd.left) >= project.width) {
        part.rd.params.part_crop_width = Math.round(project.width / part.rd.percentX);
        part.rd.params.part_scale_width = Math.round(project.width / project.percentX);
      } else {
        part.rd.params.part_crop_width = Math.round((part.rd.width - Math.abs(part.rd.left)) / part.rd.percentX);
        part.rd.params.part_scale_width = Math.round((part.rd.width - Math.abs(part.rd.left)) / project.percentX);
      }
    } else if (part.rd.left == 0) {
      if (part.rd.width >= project.width) {
        part.rd.params.part_crop_width = Math.round(project.width / part.rd.percentX);
        part.rd.params.part_scale_width = Math.round(project.width / project.percentX);
      } else {
        part.rd.params.part_crop_width = Math.round((part.rd.width) / part.rd.percentX);
        part.rd.params.part_scale_width = Math.round((part.rd.width) / project.percentX);
      }
    } else {
      if (part.rd.width + part.rd.left <= project.width) {
        part.rd.params.part_crop_width = Math.round(part.rd.width / part.rd.percentX);
        part.rd.params.part_scale_width = Math.round(part.rd.width / project.percentX);
      } else {
        part.rd.params.part_crop_width = Math.round((part.rd.width - part.rd.left) / part.rd.percentX);
        part.rd.params.part_scale_width = Math.round((part.rd.width - part.rd.left) / project.percentX);
      }
    }

    if (part.saveRD && this) {
      this.checkDefault(part)
    }
    console.log("PARAMS ### --- ", part.rd.params)
  }

  testLogic() {
    this.tracks[0].parts[1].from = 0;
    this.tracks[0].parts[1].to = -4;
    this.tracks[0].parts[1].duration = -4;
    this.refreshTracks()
  }

  useSpeedRate(val, element) {
    return (val*(1/element.part_speed_rate)).toFixed(6)
  }

  refreshTracks(checkDur:boolean = true, is_reconect:boolean = true, __this?) {
    // let _this;
    // if (__this) {
    //   _this = __this
    // } else {
    //   _this = this
    // }
    console.log('refreshTracks');
    if (checkDur) {
      let durs = []
      this.tracks.forEach(trackEl => {
        durs.push(trackEl.parts.map(n => n.duration).reduce((acc, number) => +acc + +number, 0) + trackEl.summarySpace)
      })
      this.duration = Math.max(...durs)
      this.getLines();
    }

    this.tracks.filter(x => x.type == 'video').forEach((trackEl, trackElIndex) => {
      trackEl.summarySpace = 0;
      trackEl.parts.forEach((part, partInd) => {
        part.trackElIndex = trackElIndex;
        if (part.duration < 0) {
          part.from = 0;
          part.to = ((40 / this.wWidth)*this.duration).toFixed(6)
          part.duration = (part.to - part.from)* (1/part.part_speed_rate);
        }
        part.cst_id = partInd + 1;
        part.prevAllDuration = (partInd == 0 ? 0 : trackEl.parts.slice(0, partInd).map(n => n.duration).reduce((acc, number) => +acc + +number, 0));
        
        if (part.is_combine) {
          part.blends.forEach((blend, blendInd) => {

            if (blend.duration < 0) {
              blend.from = 0;
              blend.to = ((40 / this.wWidth)*this.duration).toFixed(6)
              blend.duration = (blend.to - blend.from)* (1/blend.part_speed_rate);
            }

            if (blendInd == 0) {
              blend.prevAllDuration = part.prevAllDuration
            } else {
              blend.prevAllDuration = part.blends[blendInd - 1].prevAllDuration + part.blends[blendInd - 1].duration
            }
          })
        }
      });
    })

    this.tracks.filter(x => x.type == 'audio').forEach((trackEl, trackElIndex) => {
      trackEl.summarySpace = 0;
      trackEl.parts.forEach((part, partInd) => {
        part.trackElIndex = trackElIndex + 1;
        part.cst_id = partInd + 1 + (this.tracks.filter(x => x.type == 'video').map(u => u.parts) as any).flat().length;
        if (partInd == 0) {
          if (part.prevAllDuration > 0) {
            trackEl.summarySpace += part.prevAllDuration
          }
        } else {
          if (part.prevAllDuration != (trackEl.parts[partInd - 1].duration + trackEl.parts[partInd - 1].prevAllDuration)){
            trackEl.summarySpace += (part.prevAllDuration - (trackEl.parts[partInd - 1].duration + trackEl.parts[partInd - 1].prevAllDuration))
          }
        }
        // part.prevAllDuration = (partInd == 0 ? 0 : trackEl.parts.slice(0, partInd).map(n => n.duration).reduce((acc, number) => +acc + +number, 0));
      });
    })


    if (is_reconect) {
      this.reconnectAudios();
    }

  }

  @HostListener('window:mouseup', ['$event'])
  onWindowMouseUp(event: MouseEvent) {
    this.cutEdited = undefined;
    this.movedFragment = undefined;
    this.movedSub = undefined;
  }

  copy(part?) {
    if (part) {
      this.activeItem = part;
    }
    this.layoutService.showSnackBar({name: this.activeItem.filename}, marker("fragment copied"), SnackBarItem)
    this.copiedFragment = {...this.activeItem}
  }

  cut(part?) {
    if (part) {
      this.activeItem = part;
    }
    this.layoutService.showSnackBar({name: this.activeItem.filename}, marker("fragment cuted"), SnackBarItem)
    this.copiedFragment = {...this.activeItem}
    this.deleteActiveItem()
  }

  createScreen() {
    if (!!this.screenIsSubmit) {
      this.layoutService.showSnackBar({name: ''}, marker("Generation of screenshot(s) is currently running"), SnackBarItem)
      return
    }
    if (this.screenSettings.filter(x => x.is_active).length == 0) {
      this.layoutService.showSnackBar({name: ''}, marker("No active screenshot settings"), SnackBarItem)
      this.openScrnSettings(true)
    } else {
      this.screenIsSubmit = true;

      let target = this.getTarget();

      this.attachSubscriptions(
        this.fileService.createVideoProject({
          editor_version: this.version.toString(),
          company_id: this.data.target_company_id,
          task_id: this.vidProject.task_id,
          task_operation_id: this.vidProject.task_operation_id,
          discussion_id: this.vidProject.discussion_id,
          name: target.part.filename.replace('.'+target.part.extension, ' Screenshots'),
          output_location: this.data.file.location == "/" ? this.data.file.location + `Screenshots of ${target.part.filename.replace('.'+target.part.extension, '')}` : this.data.file.location + `/Screenshots of ${target.part.filename.replace('.'+target.part.extension, '')}`,
          is_draft: 1,
          output_width: this.vidProject.output_width,
          output_height: this.vidProject.output_height,
          output_frame_rate: this.vidProject.output_frame_rate,
          output_audio_bit_rate: this.vidProject.output_audio_bit_rate,
          output_bit_rate: this.vidProject.output_bit_rate,
          waiting_time_limit: this.vidProject.waiting_time_limit
        }, this.data.company_id).pipe(
          switchMap(project => {
            return this.fileService.createVideoProjectFile({
              company_id: target.part.company_id,
              video_project_id: project.id,
              file_id: target.part.id,
              is_input: 1
            }, this.data.company_id).pipe(
              switchMap(original => {

                let arr = [];

                this.screenSettings.filter(x => x.is_active).forEach(element => {
                  let x:any = {
                    company_id: target.part.company_id,
                    video_project_id: project.id,
                    input_video_project_file_id: original.id,
                    operation_reminder_id: project.batch_data && project.batch_data.operation_reminder_id ? project.batch_data.operation_reminder_id : 0,
                    is_output: 1,
                    is_frame: 1,
                    frame_time_ms: target.ms,
                  }
                  if (element.name == "Original quality and size") {
                    x.output_filename = 'Screenshot ' + target.part.filename.replace('.'+target.part.extension, '');
                    x.is_frame_lossless = 1;
                    arr.push(x)
                  } else {
                    x.frame_resize_side = Math.max(element.mWidth, element.mHeight);
                    if (element.filesize > 0) {
                      x.frame_filesize = Math.round(element.filesize*1000000)
                    }
                    x.output_filename = 'Screenshot w-' + element.mWidth + ' h-' + element.mHeight + " " + (element.filesize > 0 ? element.filesize + " MB ": ' ') + target.part.filename.replace('.'+target.part.extension, '');
                    arr.push(x)
                  }
                });

                return forkJoin(arr.map(pData => this.fileService.createVideoProjectFile(pData, this.data.company_id))).pipe(
                  switchMap(outputs => {
                    return this.fileService.editVideoProject(project.id, {
                      is_draft: 0,
                      is_to_process: 1,
                    }, this.data.company_id).pipe(
                      map(editedProject => {
                        return {
                          project: project,
                          original: original,
                          outputs: outputs,
                          editedProject: editedProject
                        }
                      })
                    )
                  })
                )
              })
            )
          })
        ).subscribe(resp => {
          console.log("createProject", resp)
          this.layoutService.showSnackBar({name: ''}, marker("Generation of screenshot(s) is completed"), SnackBarItem)
          this.screenIsSubmit = false;
        })
      )
    }
  }


  // onMouseDown(e: MouseEvent) {
  //   console.log("onMouseDown",e)
  //   // e.preventDefault();
  //   if (!this.duration || !!document.body.classList.contains('rd')) {
  //     return
  //   }
  //   const timelineWidth = this.customTimelineRef.nativeElement.offsetWidth;
  //   const clickX = e.clientX + this.outContainer.nativeElement.children[0].scrollLeft - 20;
  //   const newTime = (clickX / timelineWidth) * this.duration;
    
  //   console.log("onMouseDown newTime", newTime, this.outContainer.nativeElement.children[0].scrollLeft)

  //   // this.changeTime(newTime)

  //   console.log("onMouseDown",e)
    // this.changeTime(newTime)

  //   return
  // }

  @HostListener('window:keyup', ['$event'])
  onKeyUp(event: KeyboardEvent) {
    console.log('Вы отпустили', event);
    console.log('Вы отпустили key', event.key)
    const key = event.key.toLowerCase();
    // c - 67
    // v - 86
    // x - 88

    if (this.mode == 'cut') {
      if (event.keyCode == 13 || key == 'Enter') {
        const targetElement = event.target as HTMLElement;
        if (
          targetElement.nodeName == 'INPUT' ||
          targetElement.nodeName == 'TEXTAREA' ||
          (targetElement.nodeName == 'DIV' && targetElement.isContentEditable)
        ) {
          // Если целью является поле ввода текста или contenteditable DIV, ничего не делаем
          return;
        }
        event.preventDefault();
        let target = this.getTarget();
        this.addCut(this.currentTimeProject, this.tracks[0], 0, target.part, target.partIndex);
        return
      }
    }
    if (event.shiftKey) {
      if (event.keyCode == 69 || key == 'E') {
        console.log("(event.target as HTMLInputElement)", (event.target as HTMLInputElement))
        const targetElement = event.target as HTMLElement;
        if (
          targetElement.nodeName == 'INPUT' ||
          targetElement.nodeName == 'TEXTAREA' ||
          (targetElement.nodeName == 'DIV' && targetElement.isContentEditable)
        ) {
          // Если целью является поле ввода текста или contenteditable DIV, ничего не делаем
          return;
        }
        event.preventDefault();
        this.createScreen();
        return
      }
    }

    if (event.ctrlKey || event.metaKey || ['meta', 'metaleft', 'metaright'].includes(key)) {
      console.log("(event.target as HTMLInputElement)", (event.target as HTMLInputElement))
      const targetElement = event.target as HTMLElement;
      if (
        targetElement.nodeName == 'INPUT' ||
        targetElement.nodeName == 'TEXTAREA' ||
        (targetElement.nodeName == 'DIV' && targetElement.isContentEditable)
      ) {
        // Если целью является поле ввода текста или contenteditable DIV, ничего не делаем
        return;
      }
      if (event.keyCode == 67 || key == 'c') {
        if (!!this.activeItem) {
          this.layoutService.showSnackBar({name: this.activeItem.filename}, marker("fragment copied"), SnackBarItem)
          this.connectAudiosWithVideo();
          this.copiedFragment = {...this.activeItem}
          event.preventDefault();
          event.stopPropagation();
        }
      } else if (event.keyCode == 88 || key == 'x') {
        if (!!this.activeItem) {
          this.layoutService.showSnackBar({name: this.activeItem.filename}, marker("fragment cuted"), SnackBarItem)
          this.connectAudiosWithVideo();
          this.copiedFragment = {...this.activeItem}
          this.deleteActiveItem(false, true)
          event.preventDefault();
          event.stopPropagation();
        }
      } else if (event.keyCode == 86 || key == 'v') {
        if (!!this.copiedFragment) {

          let copyPart = JSON.parse(JSON.stringify(this.copiedFragment, this.getCircularReplacer()))
          let track = this.tracks[copyPart.trackElIndex]
          let parts = track.parts;
          let partIndex:number = 0

          if (copyPart.trackElIndex == 0) {
            let fileStorage;
            if (this.files.video.find(x => x.id == copyPart.id)) {
              fileStorage = this.files.video.find(x => x.id == copyPart.id)
            }
            if (this.files.audio.find(x => x.id == copyPart.id)) {
              fileStorage = this.files.audio.find(x => x.id == copyPart.id)
            }
            console.log("fileStorage", fileStorage)
            if (!!fileStorage && !!fileStorage.blob) {
              let newSrc = URL.createObjectURL(fileStorage.blob);
              copyPart.blobSrc = this.sanitizer.bypassSecurityTrustResourceUrl(newSrc);
            } else if (fileStorage) {
              this.fileService.projectFiles$.next(fileStorage)
            }


            if (copyPart.is_combine) {
              copyPart.blends.forEach(blend => {
                blend.pId = this.generateRandomId();

                let blendStorage;
                if (this.files.video.find(x => x.id == blend.id)) {
                  blendStorage = this.files.video.find(x => x.id == blend.id)
                }
                if (this.files.audio.find(x => x.id == blend.id)) {
                  blendStorage = this.files.audio.find(x => x.id == blend.id)
                }
                if (!!blendStorage && !!blendStorage.blob) {
                  let blendNewSrc = URL.createObjectURL(blendStorage.blob);
                  blend.blobSrc = this.sanitizer.bypassSecurityTrustResourceUrl(blendNewSrc);
                } else if (blendStorage) {
                  this.fileService.projectFiles$.next(blendStorage)
                }
              })
            } 
          }
          
          parts.forEach((part: any, k) => {
            if (this.currentTimeProject >= part.prevAllDuration && this.currentTimeProject <= part.prevAllDuration + part.duration) {
              partIndex = k;
            }
          });

          let part = parts[partIndex];
          let closeEl = document.getElementById('track_element_'+ part.pId);
          let currMarker = document.getElementById('current-marker');
          
          let is_left = currMarker.getBoundingClientRect().x - closeEl.getBoundingClientRect().x <= (closeEl.getBoundingClientRect().width / 2);

          copyPart.pId = this.generateRandomId();

          // if (is_left) {
          //   parts.splice(parts.indexOf(part), 0 , copyPart)
          // } else {
          //   parts.splice(parts.indexOf(part) + 1, 0 , copyPart)
          // }

          if (is_left) {
            console.log("is_left", parts.indexOf(part))
            if (copyPart.trackElIndex > 0) {
              copyPart.prevAllDuration = parts.indexOf(part) - 1 >= 0 ? (parts[parts.indexOf(part) - 1].prevAllDuration + parts[parts.indexOf(part) - 1].duration) : 0;
              parts.splice(parts.indexOf(part), 0 , copyPart)
              console.log("is_left", parts)
              console.log("is_left", parts.slice(parts.indexOf(part)));
              parts.slice(parts.indexOf(part)).forEach(p => {
                p.prevAllDuration += copyPart.duration
              });
            } else {
              parts.splice(parts.indexOf(part), 0 , copyPart)
            }
          } else {
            console.log("not is_left", parts.indexOf(part) + 1)
            if (copyPart.trackElIndex > 0) {
              copyPart.prevAllDuration = parts[parts.indexOf(part)].prevAllDuration + parts[parts.indexOf(part)].duration
              parts.splice(parts.indexOf(part) + 1, 0 , copyPart)
              parts.slice(parts.indexOf(part) + 2).forEach(p => {
                p.prevAllDuration += copyPart.duration
              });
            } else {
              parts.splice(parts.indexOf(part) + 1, 0 , copyPart)
            }
          }

          if (!!copyPart.hasOwnProperty('trackElIndex') && copyPart.trackElIndex == 0 && !!copyPart.hasOwnProperty('connectedAudios')) {
            if (copyPart.connectedAudios.length > 0) {
              copyPart.connectedAudios.forEach(el => {
                el.pId = this.generateRandomId();
                this.tracks[el.trackElIndex].parts.push(el);
              })
            }
          }
          
          this.refreshTracks();
          this.saveVideoEditorHistory("Paste Fragment");
          this.layoutService.showSnackBar({name: copyPart.filename}, marker("fragment pasted"), SnackBarItem)
          event.preventDefault();
          event.stopPropagation();
        }
      }
    }
  }

  onScrollDown() {
    console.log("onScrollDown")
    this.zoomMinus();
  }

  onScrollTop() {
    console.log("onScrollTop")
    this.zoomPlus();
  }

  getTags(is_refresh:boolean = false) {
    console.log("GET TAGS #@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@1")
    let files = (Object.values(this.files) as any).flat()
    console.log("GET TAGS #@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@2", files)

    let tagsArr = [];
    let parts = (this.tracks.map(k => k.parts) as any).flat(Infinity);

    files.forEach(file => {
      if (!!is_refresh || !file.tags || !file.hasOwnProperty('parameterValuesToTask') || parts.filter(u => !u.hasOwnProperty('parameterValuesToTask')).length > 0) {
        let tagsFilter:any = {task_id: file.task_id, task_operation_id: file.task_operation_id ? file.task_operation_id : 0, discussion_id: 0, file_id: file.id}
    
        if (file.company_id != this.data.company.id) {
          tagsFilter.partner_company_id = file.company_id
        }
        tagsArr.push(tagsFilter)
      }
    })


    console.log("GET TAGS #@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@3 tagsArr", tagsArr)
    this.attachSubscriptions(
      forkJoin(tagsArr.map(t => this.parametersService.getTargetParameters(this.data.company.id, t).pipe(
        map(tags => {
          let newTags = [];
          tags.forEach(element => {
            newTags.push(Object.assign({parameter: element.parameter, is_primary: element.is_primary}, element.parameterValue))
          });
          newTags.forEach(k => {
            if (!k.hasOwnProperty('parameter_value_id')) {
              k.parameter_value_id = k.id;
            }
          })
          return newTags
        }),
        tap(tags => {
          console.log('getTags0', tags);
          if (this.files.video.filter(x => x.id == t.file_id).length) {
            this.files.video.find(x => x.id == t.file_id).tags = tags;
            if (!!is_refresh || !this.files.video.find(x => x.id == t.file_id).hasOwnProperty('parameterValuesToTask')) {
              this.files.video.find(x => x.id == t.file_id).parameterValuesToTask = tags
            }
          } else if (this.files.audio.filter(x => x.id == t.file_id).length) {
            this.files.audio.find(x => x.id == t.file_id).tags = tags
            if (!!is_refresh || !this.files.audio.find(x => x.id == t.file_id).hasOwnProperty('parameterValuesToTask')) {
              this.files.audio.find(x => x.id == t.file_id).parameterValuesToTask = tags
            }
          }
          parts.forEach(part => {
            if (part.id == t.file_id && (!!is_refresh || !part.tags || !part.hasOwnProperty('parameterValuesToTask'))) {
              part.tags = tags
              if (!!is_refresh || !part.hasOwnProperty('parameterValuesToTask')) {
                part.parameterValuesToTask = tags
              }
            }
          });
        })
      ))).subscribe(resp => {
        console.log('getTags', this.files);
        
        this.savedTags = [];

        if (this.files.audio && this.files.audio.length) {
          this.files.audio.forEach(file => {
            if (file.tags && file.tags.length) {
              file.tags.forEach(tag => {
                if (this.savedTags.filter(x => x.id == tag.id).length == 0) {
                  this.savedTags.push(tag)
                }
              });
            }
          });
        }
        if (this.files.video && this.files.video.length) {
          this.files.video.forEach(file => {
            if (file.tags && file.tags.length) {
              file.tags.forEach(tag => {
                if (this.savedTags.filter(x => x.id == tag.id).length == 0) {
                  this.savedTags.push(tag)
                }
              });
            }
          });
        }
        console.log('savedTags', this.savedTags);
      })
    )
  }


  @HostListener('window:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    // console.log('Вы нажали', event);
    // console.log('Вы нажали key', event.key);


    if (event.key === 'ArrowRight') {
      const targetElement = event.target as HTMLElement;
      if (
        targetElement.nodeName == 'INPUT' ||
        targetElement.nodeName == 'TEXTAREA' ||
        (targetElement.nodeName == 'DIV' && targetElement.isContentEditable)
      ) {
        // Если целью является поле ввода текста или contenteditable DIV, ничего не делаем
        return;
      }
      event.preventDefault();
      event.stopPropagation();

      let targetObj = this.getTarget();
      console.log("targetObj right", targetObj)

      let frameRate = 30;
      if (targetObj.part && !!targetObj.part.fileOriginalData && !!targetObj.part.fileOriginalData.meta) {
        let parsedMeta;
        // Пытаемся разобрать метаданные
        try {
          parsedMeta = targetObj.part.fileOriginalData.meta;
        } catch (error) {
          console.error("Error parsing metadata:", error);
          // return;
        }
      
        // Проверяем наличие частоты кадров в метаданных
        if (!!parsedMeta && !!parsedMeta.frame_rate && Number(parsedMeta.frame_rate) > 0) {
          frameRate = Number(parsedMeta.frame_rate);
        }
      }

      console.log("frameRate right", frameRate)
      // console.log(this.videoplayer.currentTime)
      // console.log(this.videoplayer.currentTime - (1 / frameRate))
      this.changeTime(+Math.min(this.duration, this.currentTimeProject + (1 / frameRate)).toFixed(3));
    }

    if (event.key === 'ArrowLeft') {
      const targetElement = event.target as HTMLElement;
      if (
        targetElement.nodeName == 'INPUT' ||
        targetElement.nodeName == 'TEXTAREA' ||
        (targetElement.nodeName == 'DIV' && targetElement.isContentEditable)
      ) {
        // Если целью является поле ввода текста или contenteditable DIV, ничего не делаем
        return;
      }
      event.preventDefault();
      event.stopPropagation();
      let targetObj = this.getTarget();
      console.log("targetObj left", targetObj)

      let frameRate = 30;
      if (targetObj.part && !!targetObj.part.fileOriginalData && !!targetObj.part.fileOriginalData.meta) {
        let parsedMeta;
        // Пытаемся разобрать метаданные
        try {
          parsedMeta = targetObj.part.fileOriginalData.meta;
        } catch (error) {
          console.error("Error parsing metadata:", error);
          // return;
        }
      
        // Проверяем наличие частоты кадров в метаданных
        if (!!parsedMeta && !!parsedMeta.frame_rate && Number(parsedMeta.frame_rate) > 0) {
          frameRate = Number(parsedMeta.frame_rate);
        }
      }
      console.log("frameRate left", frameRate)
      // console.log((1 / frameRate))
      // console.log(this.videoplayer.currentTime)
      // console.log(this.videoplayer.currentTime - (1 / frameRate))
      this.changeTime(+Math.max(0, this.currentTimeProject - (1 / frameRate)).toFixed(3));
    }

    if (event.key === 'Delete' || event.key === 'Backspace') {
      const targetElement = event.target as HTMLElement;
      if (targetElement && (targetElement.closest('.rd_info') || targetElement.closest('.exp_sett_context') || targetElement.closest('.word_edit_context') || targetElement.closest('.new_text_out') || targetElement.closest('.word_edit_menu') || targetElement.closest('.edit_word') || targetElement.closest('.subs_info') || targetElement.closest('.video_filters'))) {
        return
      }
      // Ваш код для обработки нажатия клавиш "Delete" или "Backspace" здесь
      console.log('Вы сотворили УСПЕХ', this.activeItem);
      this.deleteActiveItem(false, true)
    }


    if (!(event.target as HTMLElement).closest('input')) {
      if (event.code === 'KeyA') {
        // Код, который будет выполнен при нажатии клавиши "A"
        this.changeMode('view')
        console.log('Нажата клавиша "A"');
      } else if (event.code === 'KeyB') {
        // Код, который будет выполнен при нажатии клавиши "B"
        console.log('Нажата клавиша "B"');
        this.changeMode('cut')
      }
    }
  }
  
  closeExportSettings() {
    this.subExport && this.subExport.unsubscribe();
    if (this.overlayRefExport) {
      this.overlayRefExport.dispose();
      this.overlayRefExport = null;
    }
  }

  toggleInheritTags() {
    this.inheritTags = !this.inheritTags
  }

  exportSettings(el) {
    if (this.overlayRefExport) {
      this.closeExportSettings();
      return
    } else {
      this.closeExportSettings();
    }
    console.log("exportSettings", el);
    console.log("exportSettings nativeElement", el._elementRef.nativeElement);
    let x = el._elementRef.nativeElement.offsetLeft + el._elementRef.nativeElement.offsetWidth;
    let y =  el._elementRef.nativeElement.offsetTop + el._elementRef.nativeElement.offsetHeight + 4;
    console.log("exportSettings x", x);
    console.log("exportSettings y", y);
    const positionStrategy = this.overlay.position()
    .flexibleConnectedTo({ x, y })
    .withPositions([
      {
        originX: 'end',
        originY: 'top',
        overlayX: 'end',
        overlayY: 'top',
      }
    ]);

    this.overlayRefExport = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });

    this.overlayRefExport.attach(new TemplatePortal(this.exportSettingsMenu, this.viewContainerRef, {
      $implicit: {}
    }));
    
    this.subExport = fromEvent<MouseEvent>(document, 'click').pipe(
      filter(event => {
        const clickTarget = event.target as HTMLElement;
        return !!this.overlayRefExport && !this.overlayRefExport.overlayElement.contains(clickTarget);
      }),
      take(1)
    ).subscribe(() => this.closeExportSettings())
  }
  
  closeWordEdit() {
    this.subWordEdit && this.subWordEdit.unsubscribe();
    if (this.overlayRefEditWord) {
      this.overlayRefEditWord.dispose();
      this.overlayRefEditWord = null;
    }
  }

  onChangeWord(e, wordObj) {
    console.log("onChangeWord", e)
    console.log("onChangeWord", wordObj)

    if (!e) {
      return;
    }
    
    if (e.target.value == '') {
      this.onDeleteWord(e, wordObj);
    } else if (e.target.value.indexOf(' ') != -1) {
      let newWords = e.target.value.split(' ');
      console.log(newWords);
      let duration = wordObj.word.to - wordObj.word.from;
      let newWordsArray = [];
      let partDur = duration/newWords.length.toFixed(3);

      newWords.forEach((newWord, ind) => {
        let from;
        let to;

        from = +wordObj.word.from + +(partDur*ind).toFixed(3)

        if (ind == newWords.length - 1) {
          to = wordObj.word.to
        } else {
          to = +wordObj.word.from + +(partDur*(ind+1)).toFixed(3)
        }

        newWordsArray.push({
          confidence: 0.99,
          from: from,
          ts: from,
          to: to,
          end_ts: to,
          is_edited: false,
          type: "text",
          value: newWord
        })
      });


      wordObj.sub.words.splice(wordObj.wordInd, 1, ...newWordsArray);
    } 
    wordObj.sub.text = wordObj.sub.words.map(x => x.value).join(' ');
    this.closeWordEdit();
    this.saveVideoEditorHistory("Change subtitles");
  }

  onDeleteWord(e, wordObj) {
    e.preventDefault();
    e.stopPropagation();
    console.log("onDeleteWord", wordObj)
    let duration = wordObj.word.to - wordObj.word.from
    if (wordObj.wordInd == wordObj.sub.words.length - 1) {
      wordObj.sub.words.splice(wordObj.wordInd, 1);
      wordObj.sub.words[wordObj.sub.words.length-1].to += duration 
    } else if (wordObj.wordInd == 0) {
      wordObj.sub.words.splice(wordObj.wordInd, 1);
      wordObj.sub.words[0].from -= duration 
    } else {
      wordObj.sub.words[wordObj.wordInd - 1].to += duration/2;;
      wordObj.sub.words[wordObj.wordInd + 1].from -= duration/2;
      wordObj.sub.words.splice(wordObj.wordInd, 1);
    }
    wordObj.sub.text = wordObj.sub.words.map(x => x.value).join(' ');
    this.closeWordEdit();
    this.saveVideoEditorHistory("Change subtitles");
  }

  toggleWordEdit(e, wordObj) {
    e.preventDefault();
    e.stopPropagation();
    wordObj.word.is_edited = !wordObj.word.is_edited
  }

  openWordEdit({ x, y }: MouseEvent, part, sub, word, wordInd) {
    this.closeWordEdit();
    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo({ x, y })
      .withPositions([
        {
          originX: 'start',
          originY: 'top',
          overlayX: 'start',
          overlayY: 'top',
        }
      ]);

    this.overlayRefEditWord = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });

    this.overlayRefEditWord.attach(new TemplatePortal(this.wordEdit, this.viewContainerRef, {
      $implicit: {
        part: part,
        sub: sub,
        word: word,
        wordInd: wordInd
      }
    }));
    
    this.subWordEdit = fromEvent<MouseEvent>(document, 'click').pipe(
      filter(event => {
        const clickTarget = event.target as HTMLElement;
        return !!this.overlayRefEditWord && !this.overlayRefEditWord.overlayElement.contains(clickTarget);
      }),
      take(1)
    ).subscribe(() => this.closeWordEdit())
  }

  closeRightClick() {
    this.sub && this.sub.unsubscribe();
    if (this.overlayRefRight) {
      this.overlayRefRight.dispose();
      this.overlayRefRight = null;
    }
  }

  settings(file?) {
    this.closeRightClick();
    if (file) {
      const dialogRef = this.dialog.open(FileSettingsComponent, {
        data: {
          company_id: this.data.company_id,
          target_company_id: this.data.target_company_id,
          company: this.data.company,
          file: file,
          user: this.data.user,
          imgRoute: this.imgRoute,
          activeLang: this.data.activeLang,
          host: this.host,
          location: this.form.value.location,
          task: this.data.task,
          work: this.data.work,
          operationsValues: null
        }
      });
    }
  }


  open({ x, y }: MouseEvent, file) {
    this.closeRightClick();
    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo({ x, y })
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        }
      ]);

    this.overlayRefRight = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });

    this.overlayRefRight.attach(new TemplatePortal(this.fileMenu, this.viewContainerRef, {
      $implicit: file
    }));
    
    this.sub = fromEvent<MouseEvent>(document, 'click').pipe(
      filter(event => {
        const clickTarget = event.target as HTMLElement;
        return !!this.overlayRefRight && !this.overlayRefRight.overlayElement.contains(clickTarget);
      }),
      take(1)
    ).subscribe(() => this.closeRightClick())
  }

  getConnectedAudiosForVideo(part) {
    let connectedAudios = [];
    let audioParts = (this.tracks.filter(x => x.type == 'audio').map(u => u.parts) as any).flat();
    if (audioParts.length > 0) {
      audioParts.forEach((audPart, audPartIndex) => {
        if (audPart.prevAllDuration >= part.prevAllDuration && audPart.prevAllDuration < part.prevAllDuration + part.duration) {
          audPart.videoTime = part.from + (audPart.prevAllDuration - part.prevAllDuration);
          connectedAudios.push(audPart)
        }
      })
    }
    return connectedAudios;
  }

  checkAudioOverlays() {
    this.tracks.filter(x => x.type == 'audio').forEach((trackEl, trackElIndex) => {
      trackEl.parts.forEach((part, partIndex) => {
        if (partIndex != 0) {
          if (part.prevAllDuration < (trackEl.parts[partIndex - 1].prevAllDuration + trackEl.parts[partIndex - 1].duration)) {
            trackEl.parts.splice(partIndex, 1)
            // needNewPositions.push(part)
            let range = {
              from: part.prevAllDuration,
              to: part.prevAllDuration + part.duration
            }
            let rFrom = range.from;
            let rTo = range.to;

            let willAdd:boolean = false;
            this.tracks.filter(x => x.type == 'audio').forEach((trackElIn, trackElIndexIn) => {
              if (willAdd) {
                return
              }
              
              let canAdd: boolean = true;
              trackElIn.parts.forEach((partIn, partIndexIn) => {
                let left = partIn.prevAllDuration;
                let right = partIn.prevAllDuration + partIn.duration;
                if ((left <= rFrom && right > rFrom) || (left > rFrom && left < rTo) || (right > rFrom && right < rTo)) {
                  canAdd = false;
                }
              })
              if (canAdd) {
                trackElIn.parts.push({...part})
                trackElIn.parts.sort(function(a, b) {
                  return (a.prevAllDuration - b.prevAllDuration);
                })
                willAdd = true;
              } else {
                if (trackElIndexIn == this.tracks.filter(x => x.type == 'audio').length - 1) {
                  this.tracks.push({
                    type: 'audio',
                    parts: [{...part}]
                  })
                  willAdd = true;
                }
              }
            })
          }
        }
      })
    })
    this.refreshTracks(true, false);
  }

  reconnectAudios() {
    let videoParts = (this.tracks.filter(x => x.type == 'video').map(u => u.parts) as any).flat();
    videoParts.forEach((vPart, vPartIndex) => {
      if (vPart.connectedAudios) {
        vPart.connectedAudios.forEach(el => {
          if (vPart.prevAllDuration + (el.videoTime - vPart.from) >= vPart.prevAllDuration) {
            el.prevAllDuration = vPart.prevAllDuration + (el.videoTime - vPart.from);
          } else {
            el.prevAllDuration = vPart.prevAllDuration
          }
        })
      }
    })
    this.checkAudioOverlays();
  }

  connectAudiosWithVideo() {
    let videoParts = (this.tracks.filter(x => x.type == 'video').map(u => u.parts) as any).flat();
    videoParts.forEach((vPart, vPartIndex) => {
      vPart.connectedAudios = this.getConnectedAudiosForVideo(vPart);
    })
  }

  deleteActiveItem(part?, is_delete?) {
    if (part) {
      this.activeItem = part;
    }
    if (!!this.activeItem && this.tracks.filter(track => track.parts.filter(part => part.pId == this.activeItem.pId).length)) {
      let track = this.tracks.find(track => track.parts.filter(part => part.pId == this.activeItem.pId).length)
      let part = track.parts.find(x => x.pId == this.activeItem.pId);

      if (part.trackElIndex == 0) {
        this.connectAudiosWithVideo();

        if (part.connectedAudios && part.connectedAudios.length > 0) {
          part.connectedAudios.forEach(el => {
            this.deleteActiveItem(el, is_delete)
          })
        }
      }

      console.log("track", track)
      console.log("part", part)

      track.parts.splice(track.parts.indexOf(part), 1)

      if (track.parts.length == 0 && track.type != 'video') {
        this.tracks.splice(this.tracks.indexOf(track), 1)
      }

      if (!!is_delete) {
        let allParts = (this.tracks.map(k => k.parts) as any).flat(Infinity)
  
        console.log("allParts", allParts)
        console.log("!allParts.filter(x => x.id == this.activeItem.id).length", !allParts.filter(x => x.id == this.activeItem.id).length)
        if (!allParts.filter(x => x.id == this.activeItem.id).length) {
          console.log("!!this.files.video.filter(p => p.id == this.activeItem.id).length", !!this.files.video.filter(p => p.id == this.activeItem.id).length)
          if (!!this.files.video.filter(p => p.id == this.activeItem.id).length) {
            this.files.video.splice(this.files.video.findIndex(k => k.id == this.activeItem.id), 1)
          }
          console.log("!!this.files.audio.filter(p => p.id == this.activeItem.id).length", !!this.files.audio.filter(p => p.id == this.activeItem.id).length)
          if (!!this.files.audio.filter(p => p.id == this.activeItem.id).length) {
            this.files.audio.splice(this.files.audio.findIndex(k => k.id == this.activeItem.id), 1)
          }
        }
      }
      // if (counts > 1) {
      //   trackEl.parts.splice(trackEl.parts.indexOf(part), 1)
      // } else {
      //   track.files.splice(track.files.indexOf(trackEl), 1)
      //   if (track.type == 'audio' && track.files.length == 0) {
      //     this.tracks.splice(this.tracks.indexOf(track), 1)
      //   }
      // }
  
      if (this.tracks.filter(x => x.type == 'audio').length == 0) {
        let videos = document.querySelectorAll('.player')
        videos.forEach((vid: HTMLVideoElement, k) => {
          vid.muted = false;
        });
      }
      
      this.projectHasParts = true;
      this.refreshTracks();
      if (this.activeItem) {
        this.activeItem.is_open = false;
      }
      this.activeItem = undefined
      this.saveVideoEditorHistory("Delete Fragment");
    }
  }

  changePartDeg(e, item) {
    this.calculateSizes(item, this.project)
  }

  getVideoParts() {
    let parts = (this.tracks.filter(x => x.type == 'video').map(k => k.parts) as any).flat(Infinity)
    return parts
  }

  changeCropOn(item) {
    item.crop_on = !item.crop_on

    if (item.is_combine) {
      item.blends.forEach(blend => {
        blend.crop_on = item.crop_on
      });
    }
  }

  rgbaToReverseHex(rgba) {
    // Извлекаем значения R, G, B, A из строки формата "rgba(...)"
    const rgbaValues = rgba.match(/\d+(\.\d+)?/g).map(Number);
  
    // Получаем R, G, B и A, если альфа отсутствует, считаем её как 1 (полная непрозрачность)
    const [r, g, b, a] = rgbaValues.length === 4 ? rgbaValues : [...rgbaValues, 1];
  
    // Преобразуем значения R, G, B в шестнадцатеричный формат
    const red = r.toString(16).padStart(2, '0');
    const green = g.toString(16).padStart(2, '0');
    const blue = b.toString(16).padStart(2, '0');
  
    // Инвертируем значение альфа-канала для формата ASS
    const invertedAlpha = Math.round((1 - a) * 255).toString(16).padStart(2, '0');
  
    // Возвращаем итоговый HEX-код с переворотом RGBA на ABGR
    return `&H${invertedAlpha}${blue}${green}${red}`;
  }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    const targetElement = event.target as HTMLElement;
    console.log("targetElement", targetElement)
    console.log("targetElement.closest('.word_edit_context')", targetElement.closest('.word_edit_context'))
    if (targetElement && (targetElement.closest('.rd_info') || targetElement.closest('.exp_sett_context') || targetElement.closest('.word_edit_context') || targetElement.closest('.new_text_out') || targetElement.closest('.word_edit_menu') || targetElement.closest('.edit_word') || targetElement.closest('.subs_nav') || targetElement.closest('.subs_text') || targetElement.closest('.subs_info') || targetElement.closest('.video_filters') || targetElement.closest('.trackpad_out') || targetElement.closest('.file-menu') || targetElement.closest('.active_item_info') || targetElement.closest('.rd') || targetElement.closest('.holst') || targetElement.closest('.ratio_value_menu_class'))) {
      // Код, который будет выполнен при клике на элемент с классом `.test`
      console.log('Вы кликнули на элемент с классом .trackpad_out или его потомке');
    } else {
      // Код, который будет выполнен при клике вне элемента с классом `.test`
      if (this.activeItem) {
        this.activeItem.is_open = false;
        if (this.activeItem.crop_on) {
          this.saveVideoEditorHistory("Video Position");
        }
        this.activeItem = undefined;
      }
      let parts = (this.tracks.filter(x => x.type == 'video').map(k => k.parts) as any).flat(Infinity)
      parts.forEach(part => {
        part.crop_on = false;
        if (part.is_combine) {
          part.blends.forEach(blend => {
            blend.crop_on = part.crop_on
          });
        }
      })

    }
  }

  ngOnDestroy(): void {
    if (this.saveSub) {
      this.saveSub.unsubscribe()
    }
    this.layoutService.overflowAuto();
    this.destroy$.next();
    this.destroy$.complete();
    this.clearSubscriptions()
  }



  getContentTypes(platform_id, contentTypePage) {
    this.attachSubscriptions(
      this.companyService.getContentTypes(platform_id, contentTypePage).pipe(
        map(el => el.body)
      ).subscribe(resp => {
        console.log("getContentTypes", resp)
        this.allContentTypes = resp;
        this.profilesTab = 1;
      })
    )
  }

  getForAllContentTypes() {
    this.attachSubscriptions(
      this.companyService.getContentTypes().pipe(
        map(el => el.body)
      ).subscribe(resp => {
        this.forAllContentTypes = resp;
      })
    )
  }

  // getFreeRegulars() {
  //   console.log("getFreeRegulars")
  //   let filterData = {
  //     channel_id: this.appPublicationData.channel_id,
  //     content_type_id: this.appPublicationData.content_type_id,
  //     publish_at_from: ''
  //   }

  //   filterData.publish_at_from = moment().format("X");

  //   console.log("filterData", filterData)

  //   this.attachSubscriptions(
  //     this.companyService.getExpandPublishScheduledPosts('1', this.data.company.id, {...filterData}, '200').pipe(
  //       map(el => el.body),
  //       switchMap(contents => {
  //         let urlsData:any = {
  //           channel_id: this.appPublicationData.channel_id,
  //           content_type_id: this.appPublicationData.content_type_id,
  //           content_published_at_from: ''
  //         };

  //         urlsData.content_published_at_from = moment().format("X");
  //         console.log("urlsData", urlsData)
  //         return this.taskService.getExpandPublishUrls('1', this.data.company.id, {...urlsData}, '200').pipe(
  //           map(expUrls => {
  //             return {    
  //               urls: expUrls.body,
  //               contents: contents
  //             }
  //           })
  //         )
  //       })
  //     ).subscribe((resp:any) => {

  //       let urls = resp.urls
  //       urls.forEach(x => {
  //         x.scheduledObj = {};
  //         x.name = x.content_name;
  //       })

  //       resp.contents.forEach(con => {
  //         if (urls.length > 0 && urls.filter(x => x.content_published_at == con.publish_at).length > 0) {
  //           urls.find(x => x.content_published_at == con.publish_at).scheduledObj = con
  //         } else {
  //           con.is_con = true;
  //           con.content_published_at = con.publish_at;
  //           urls.push(con)
  //         }
  //       })

  //       urls.sort(function(a, b) {
  //         return (a.content_published_at - b.content_published_at);
  //       })

  //       let regularFreeDateTimes = [...urls]

  //       regularFreeDateTimes.forEach(x => {
  //         if (x.hasOwnProperty('scheduledObj')) {
  //           if (moment().isSameOrAfter(moment(x.content_published_at*1000))) {
  //             x.class = 'green'
  //           } else {
  //             x.class = 'blue'
  //           }
  //         } else {
  //           if (moment().isSameOrAfter(moment(x.content_published_at*1000))) {
  //             x.class = 'silver'
  //           } else {
  //             x.class = 'white'
  //           }
  //         }
  //       })

  //       this.regularDateTimes = regularFreeDateTimes.filter(x => x.class == 'white');
  //     })
  //   )
  // }

  getPlatformTypes(id) {
    if (!this.forAllContentTypes || this.forAllContentTypes.filter(el => el.channel_platform_id == id).length == 0) {
      return false;
    }
    return this.forAllContentTypes.filter(el => el.channel_platform_id == id)
  }


  changeOptions(e, cut) {
    console.log("changeOptions", e);
    cut.optionsCount = e.target.value
  }

}
