import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { BaseClass } from 'src/app/shared/models/base-class';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { RefreshService } from 'src/app/shared/services/rest/refresh.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';

@Component({
  selector: 'app-set',
  templateUrl: './set.component.html',
  styleUrls: ['./set.component.scss']
})
export class SetComponent extends BaseClass implements OnInit, OnDestroy {
  public form: FormGroup;
  public isSubmit: boolean = false;
  constructor(
    private fb: FormBuilder,
    private companyService: CompanyService,
    private router: Router,
    private refreshService: RefreshService,
  ) { 
    super()
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      name: ['', Validators.required]
    })
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

  goBack() {
    history.back();
  }

  onSubmit(is_live:boolean = false) {
    this.isSubmit = true;
    this.attachSubscriptions(
      this.companyService.createCompany(this.form.value).subscribe(resp => {
        this.isSubmit = false;
        if (is_live) {
          this.router.navigate(['/set-up-auto'], { queryParams: {company_id: resp.id} });
        } else {
          this.router.navigate(['/set-up'], { queryParams: {company_id: resp.id} });
        }
        this.refreshService.newWorkSpace$.next(true)
      })
    )
  }

}
