import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseClass } from 'src/app/shared/models/base-class';
import { MembersService } from 'src/app/shared/services/rest/members.service';

@Component({
  selector: 'app-edit-type',
  templateUrl: './edit-type.component.html',
  styleUrls: ['./edit-type.component.scss']
})
export class EditTypeComponent extends BaseClass implements OnInit, OnDestroy {
  public form: FormGroup
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<EditTypeComponent>,
    private fb: FormBuilder,
    private membersService: MembersService,
  ) {
    super()
   }

  ngOnInit(): void {
    console.log(this.data);
    this.form = this.fb.group({
      is_manager: [this.data.employee.is_manager == 1 ? 1 : 0, Validators.required],
    })
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  onSubmit() {
    this.attachSubscriptions(
      (!!this.data.employee.is_partner ? this.membersService.editTaskPartner(this.data.employee.id, this.form.value, this.data.company.id) : (!!this.data.employee.is_partner_employee ? this.membersService.editTaskPartnerEmployee(this.data.employee.id, this.form.value, this.data.company.id) : this.membersService.editMember(this.data.employee.id, this.form.value, this.data.company.id))).subscribe(resp => {
        this.dialogRef.close({event: "update", data: resp})
      },
      error => {
        this.data.showError()
        this.dialogRef.close({event: "update", data: {}})
      })
    )
  }

  ngOnDestroy(): void {
    this.clearSubscriptions();
  }


}
