<h2 mat-dialog-title class="dialog_title">
    <div class="d-f-c title_outlet_line">
        {{ "Link channel" | translate }}  {{ "with existing outlet" | translate }}:
        <!-- <app-outlet-line [channel]="data.profile" [company_id]="data.company_id"></app-outlet-line> -->
    </div>
    <button mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h2>
<mat-dialog-content class="mat-typography" dirEnter (dirSubmit)="!form.valid ? null : onSubmit()">
    <div class="container">
        <form [formGroup]="form">
            <mat-form-field appearance="standard" class="full_width_form h_cst_t">
                <mat-label>{{ "Select outlet" | translate }}</mat-label>
                <mat-select [disableOptionCentering]="true" selectDir #profilesContainer formControlName="channel_id" required>
                    <mat-select-trigger>
                        <app-outlet-line [channel]="getProfile(form.controls['channel_id'].value)" [company_id]="data.company_id"></app-outlet-line>
                    </mat-select-trigger>
                    <mat-option>
                        <ngx-mat-select-search 
                        placeholderLabel="{{ 'Search' | translate }}" 
                        noEntriesFoundLabel="{{ 'no matching outlet found' | translate }}"
                        [formControl]="profilesMoreControl"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let profile of profiles$ | async" [value]="profile.id" class="cst_o">
                        <div class="d-f-c" style="justify-content: space-between;">
                            <app-outlet-line [channel]="profile" [company_id]="data.company_id"></app-outlet-line>
                            <svg [ngClass]="{'not_active_sync': !profile?.channelAccount || (profile?.channelAccount && !profile?.channelAccount?.is_active)}" *ngIf="!!profile?.channel_account_id" style="width: 14px; height: 10px;" width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.25 2.5L8.75 5H10.625C10.625 5.99456 10.2299 6.94839 9.52665 7.65165C8.82339 8.35491 7.86956 8.75 6.875 8.75C6.25 8.75 5.64375 8.59375 5.125 8.3125L4.2125 9.225C4.98125 9.7125 5.89375 10 6.875 10C8.20108 10 9.47285 9.47322 10.4105 8.53553C11.3482 7.59785 11.875 6.32608 11.875 5H13.75L11.25 2.5ZM3.125 5C3.125 4.00544 3.52009 3.05161 4.22335 2.34835C4.92661 1.64509 5.88044 1.25 6.875 1.25C7.5 1.25 8.10625 1.40625 8.625 1.6875L9.5375 0.775C8.76875 0.2875 7.85625 0 6.875 0C5.54892 0 4.27715 0.526784 3.33947 1.46447C2.40178 2.40215 1.875 3.67392 1.875 5H0L2.5 7.5L5 5" fill="#1877F2"></path></svg>
                        </div>
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </form>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button (click)="close()">
        {{ "Close" | translate }}
    </button>
    <button class="ml-1" (click)="onSubmit()" mat-raised-button cdkFocusInitial color="primary" [disabled]="!form.valid">
        {{ "Save" | translate }}
    </button>
</mat-dialog-actions>