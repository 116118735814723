<h2 mat-dialog-title class="dialog_title">
    <div class="folder_head">
        <div class="folder_info">
            <mat-icon style="margin-right: 5px;">folder</mat-icon>
            <span>
                {{ data?.folder?.filename | translate }}
            </span>
            <span>{{ fileLocationString | translate }}</span>
        </div>
        <span class="folder_size">
            {{ data?.folder?.filesize | filesize }}
        </span>
    </div>
    <button mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h2>
<mat-dialog-content class="mat-typography">
    <div class="container">
        <ng-container *ngIf="!data.folder.archives || data.folder.archives.length == 0; else elseTemplateNoArchives">
            <p class="no_arch">The folder has no archives yet, generate an archive for the folder and it will be displayed in this window.</p>
        </ng-container>
        <ng-template #elseTemplateNoArchives>      
            <div class="archives" [appDeviceClass]="'custom-scroll custom-scroll-t'">
                <ng-container *ngFor="let item of data.folder.archives; let i = index;">
                    <div class="arch">
                        <div class="arch_line" (click)="openArch(item)">
                            <span>{{"Archive" | translate}} (ID: {{item.id}})</span>

                            <div class="d-f-c">
                                <div class="arch_info">
                                    <span class="arch_state" [ngClass]="{'created': !!item.is_created}">{{ (!!item.is_created ? 'Created' : 'In Process') | translate }}</span>

                                    <span class="create_date">
                                        <mat-icon>more_time</mat-icon>
                                        {{item.created_at | reportsDate:data.activeLang}}
                                    </span>
                                    <span class="delete_date" style="margin-left: 10px !important;">
                                        {{"Will be deleted on" | translate}}: 
                                        {{item.delete_at | reportsDate:data.activeLang}}
                                    </span>
                                    <mat-icon class="cp ml-1" (click)="$event.preventDefault(); $event.stopPropagation(); deleteArchive(item, i)" style="color: #c0392b;">delete</mat-icon>
                                </div>
    
                                <button mat-icon-button class="mib-24">
                                    <ng-container *ngIf="!!item.open; else elseTemplateIsOpenArch">
                                      <mat-icon>expand_less</mat-icon>
                                    </ng-container>
                                    <ng-template #elseTemplateIsOpenArch>
                                      <mat-icon>expand_more</mat-icon>
                                    </ng-template>
                                </button>
                            </div>
                        </div>
                        
                        <div class="arch_body" *ngIf="!!item?.open">
                            <ng-container *ngFor="let part of item.download_url">
                                <div class="part">
                                    <div class="part_name">
                                        {{ part?.name }}
                                    </div>
                                    <a [ngClass]="{'active': !!part?.is_clicked}" [href]="part?.url" download="download" (click)="part.is_clicked = true">
                                        <mat-icon>download</mat-icon>
                                    </a>
                                </div>
                            </ng-container>
             
                            <div class="download_all" *ngIf="!!item.download_url && item.download_url.length > 0">
                                <button class="mr-1" mat-raised-button color="accent" ngxClipboard [cbContent]="generateCombinedUrls(item.download_url)" (cbOnSuccess)="copyUrls(item.download_url.length)">{{ "Copy Download Urls" | translate }}</button>
                                <button mat-raised-button color="warn" (click)="downloadArchive(item)">{{ "Download All" | translate }}</button>
                            </div>
                        </div>

                    </div>
                </ng-container>
            </div>      
        </ng-template>
        
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <div class="d-f-c" style="padding-top: 10px;">
        <button mat-raised-button (click)="close()">
            {{ "Close" | translate }}
        </button>
        <button class="ml-1" color="primary" mat-raised-button (click)="generateNewArchive()">
            {{ "Generate New Archive" | translate }}
        </button>
    </div>
</mat-dialog-actions>
