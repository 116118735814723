<div class="companyes_page">
    <div class="cases_page" style="margin-bottom: 4px;">
        <div class="container">
          <div class="filter_out">  
            <div class="filter_values">
                <div class="d-f-c">

                </div>
              <!-- <mat-chip-list class="chips_wrp without_ordered">
    
    
                <mat-chip (removed)="removeValue('start_time_from')" *ngIf="filter.get('start_time_from').value">
                    {{ filter?.get('start_time_from').value | date:'MMM, y':"+0000":activeLang | yearPipe }}
                    <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
                </mat-chip>
    
                <mat-icon class="remove_all_chips_btn" (click)="removeValue('all')" *ngIf="!withoutFilter && (filter.get('start_time_from').value || filter.get('start_time_to').value)">cancel</mat-icon>
              </mat-chip-list> -->
      
              <div class="stats_wrp">
                <div class="tasks_counters">
                    <div class="filter_btn green mr-1" (click)="applyPromo()">
                        {{"Apply Promo" | translate}}
                    </div>

                    <div class="filter_btn mr-5" (click)="changeFilter(2)" [ngClass]="{'active': is_em == 2}">
                        {{"My rewards" | translate}}
                    </div>
                    <div class="filter_btn mr-5" (click)="changeFilter(1)" [ngClass]="{'active': is_em == 1}">
                        {{"Referral codes" | translate}}
                    </div>
                    <div class="filter_btn mr-1" (click)="changeFilter(0)" [ngClass]="{'active': is_em == 0}">
                        {{"Referral Activity" | translate}}
                    </div>

                    <div class="counter_block" *ngIf="promosPagination && promosPagination['totalCount']">
                        <div class="counter_top">{{ "Promos" | translate }}</div>
                        <div class="counter_bottom">{{promosPagination['totalCount']}}</div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
    <div        
    class="root_users root_plans"  
    [appDeviceClass]="'custom-scroll custom-scroll-t'"      
    infinite-scroll
    [infiniteScrollDisabled]="isLoadPromos"
    [scrollWindow]="false"
    [infiniteScrollUpDistance]="scrollUpDistance"
    [infiniteScrollThrottle]="throttle"
    (scrolled)="onDownPromos($event)">
        <div class="server_item_out server_item_out_head">
            <div class="server_item trans_item plan_item">
                <div class="d-f-c">
                    <div class="id">
                        {{ "ID" | translate }}
                    </div>
                    <div class="ex_id">
                        {{ "Created At" | translate }}
                    </div>
                    <div class="ex_id">
                        {{ "Workspace ID" | translate }}
                    </div>
                    <div class="is_col code">
                        {{ "Code" | translate }}
                    </div>         
                    <div class="is_col desc">
                        {{ "Description" | translate }}
                    </div>   
                    <div class="is_col desc">
                        {{ "Workspace Description" | translate }}
                    </div>
                    <div class="is_col">
                        {{ "Initial" | translate }}
                    </div>
                </div>
                <div style="display: flex; align-items: center; justify-content: space-between !important; width: 50px;">
                    <div style="padding-right: 10px;">
                        {{ "Action" | translate }}
                    </div>
                </div>
            </div>
        </div>
        <ng-container *ngIf="promos?.length">
            <div class="server_item_out" *ngFor="let item of promos; let i = index;">
                <div class="server_item plan_item trans_item">
                    <div class="d-f-c">
                        <div class="id">
                            {{ item?.id }}
                        </div>
                        <div class="ex_id">
                            <ng-container *ngIf="item?.created_at > today && item?.created_at < todayEnd; else createdAtTempl">
                                <span #tooltip="matTooltip"
                                matTooltip="{{ item?.created_at*1000 | date:'MMM d, y':'':activeLang | yearPipe }}"
                                [matTooltipPosition]="'above'">
                                {{ item?.created_at*1000 | date:'hh:mm a':'':activeLang }}
                                </span>
                            </ng-container>
                            <ng-template #createdAtTempl>
                                <span *ngIf="item?.created_at && item?.created_at > 30000" #tooltip="matTooltip"
                                matTooltip="{{ item?.created_at*1000 | date:'hh:mm a':'':activeLang | yearPipe }}"
                                [matTooltipPosition]="'above'">
                                {{ item?.created_at*1000 | date:'MM.dd.yy':'': activeLang }}
                                </span>
                            </ng-template>
                        </div>
                        <div class="ex_id">
                            {{item?.company_id}}
                        </div>
                        <div class="is_col code cp" ngxClipboard [cbContent]="item.code" (cbOnSuccess)="copyData('Code')">
                            <b>{{item?.code}}</b> 
                        </div>   
                        <div class="is_col desc">
                            <div class="text_3_line">
                                {{item?.description}}
                            </div>
                        </div>   
                        <div class="is_col desc">
                            <div class="text_3_line">
                                {{item?.description_company}}
                            </div>
                        </div>   
                        <div class="is_col">
                            <ng-container *ngIf="item.is_initial == 1; else elseTemplateHideTransaction">
                                <mat-icon>add</mat-icon>
                            </ng-container>
                            <ng-template #elseTemplateHideTransaction>
                                <mat-icon>remove</mat-icon>
                            </ng-template>
                        </div>           
                    </div>
                    <div style="display: flex; align-items: center; justify-content: flex-end !important;">
                        <button mat-icon-button style="color: #686868;" (click)="item.isOpen = !item.isOpen">
                            <mat-icon>
                                {{ item?.isOpen ? 'expand_less' : 'expand_more' }}
                            </mat-icon>
                        </button> 
                        <!-- <button mat-icon-button style="color: #686868;" [matMenuTriggerFor]="menuPlans">
                            <mat-icon>more_vert</mat-icon>
                        </button> 
                        <mat-menu #menuPlans="matMenu">
                            <button mat-menu-item (click)="addRootTransaction(item)">
                                <mat-icon>edit</mat-icon>
                                <span>{{"Edit"| translate}}</span>
                            </button>
                            <ng-container *ngIf="!item.is_applied">
                                <mat-divider></mat-divider>
                                <button mat-menu-item (click)="deleteTransaction(item, i)">
                                    <mat-icon>delete_forever</mat-icon>
                                    <span>{{"Delete"| translate}}</span>
                                </button>
                            </ng-container>
                        </mat-menu> -->
                        <!-- <div class="check_wrp">
                            <mat-checkbox color="primary" class="example-margin" (click)="$event.stopPropagation()" (change)="$event ? toggleServer(item) : null" [checked]='serversCollection.isSelected(item)'></mat-checkbox>
                        </div> -->
                    </div>
                </div>
                <div class="server_item_log" *ngIf="item.isOpen" [appDeviceClass]="'custom-scroll custom-scroll-t'">
                    <h2>{{"Description" | translate}}</h2>
                    <div class="text_promo">{{item?.description}}</div>
                    <h2>{{"Workspace Description" | translate}}</h2>
                    <div class="text_promo">{{item?.description_company}}</div>
                    <ng-container *ngIf="user?.is_root">
                        <h2>{{"Root Description" | translate}}</h2>
                        <div class="text_promo">{{item?.description_root}}</div>
                        <hr>
                        <pre>
                            {{item|json}}
                        </pre>
                    </ng-container>
                    
                </div>
            </div>
        </ng-container>
    </div>
    <!-- <div style="padding: 15px 0;">
        <button class="add_btn" mat-raised-button color="primary" (click)="addRootTransaction()">
            <mat-icon>add</mat-icon>
            {{ "Transaction" | translate }}
        </button>
    </div> -->
</div>