<h2 mat-dialog-title class="dialog_title">
    {{ 'AI answer' | translate }}
</h2>
<mat-dialog-content class="mat-typography">
    <div class="container ai_chat">
        <div class="request">
            {{data.request}}
        </div>
        <code class="answer" [innerHTML]="data.answer"></code>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <div class="start_btns">
        <button mat-raised-button (click)="close()">
            {{ "Close" | translate }}
        </button>
        <button class="ml-1" mat-raised-button color="primary" (click)="accept()">
            {{ "Apply" | translate }}
        </button>
        <button class="ml-1" mat-raised-button color="warn" (click)="new()">
            {{ "Create new" | translate }}
        </button>
    </div>
</mat-dialog-actions>
