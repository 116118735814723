<h2 mat-dialog-title class="dialog_title">
    <span>
        {{ "What new in parameter" | translate }}: <b>{{data.param.name}}</b>
    </span>
    <button mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h2>
<mat-dialog-content class="mat-typography">
    <div class="container">
        <mat-list role="list" class="value_list" [appDeviceClass]="'custom-scroll custom-scroll-t'">
            <mat-list-item role="listitem" *ngFor="let val of values">{{val.value}}</mat-list-item>
        </mat-list>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <div class="btns_layer">
        <button mat-raised-button (click)="close()">
            {{ "Close" | translate }}
        </button>
        <button class="ml-1" (click)="submitForm()" mat-raised-button cdkFocusInitial color="primary" [disabled]="isSubmit">
            <mat-icon class="mr-1">update</mat-icon>
            {{ "Update" | translate }}
        </button>
    </div>
</mat-dialog-actions>
