import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { forkJoin, fromEvent, of } from 'rxjs';
import { catchError, last, map, switchMap, tap } from 'rxjs/operators';
import { OpenJobComponent } from 'src/app/components/workspace-pages/cases/dialogs/open-job/open-job.component';
import { OpenTaskComponent } from 'src/app/components/workspace-pages/cases/dialogs/open-task/open-task.component';
import { ChatsComponent } from 'src/app/components/chats/chats.component';
import { casesModel } from 'src/app/shared/functions/casesModel';
import { BaseClass } from 'src/app/shared/models/base-class';
import { GlobalDataService } from 'src/app/shared/services/common/global-data.service';
import { ChatService } from 'src/app/shared/services/rest/chat.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { StatementService } from 'src/app/shared/services/rest/statement.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { StatementDocumentsComponent } from '../statement-documents/statement-documents.component';
import { MembersService } from 'src/app/shared/services/rest/members.service';

@Component({
  selector: 'app-statement',
  templateUrl: './statement.component.html',
  styleUrls: ['./statement.component.scss']
})
export class StatementComponent extends BaseClass implements OnInit, OnDestroy {
  public payments: any;
  public departments: any = [];
  public currencyes: any;
  public earnings: any;
  public allTransactions: any;
  public operations: any;
  public returnedEarnings: any;
  public pendingEarnings: any;
  public recordEarnings: any;
  public changeEarnings: any;
  public groups: any;
  
  public is_mobile: boolean = false;
  
  public totalPayouts: any[] = []

  public totalEarnings: any[] = [{
    amount: 0,
    currency_id: 'rate',
    currency: {
      short_name: "pts"
    }
  }]
  public totalAllTransactions: any[] = [{
    amount: 0,
    currency_id: 'rate',
    currency: {
      short_name: "pts"
    }
  }]
  public totalReturnedEarnings: any[] = [{
    amount: 0,
    currency_id: 'rate',
    currency: {
      short_name: "pts"
    }
  }]
  public totalRecordEarnings: any[] = [{
    amount: 0,
    currency_id: 'rate',
    currency: {
      short_name: "pts"
    }
  }]
  public totalChangeEarnings: any[] = [{
    amount: 0,
    currency_id: 'rate',
    currency: {
      short_name: "pts"
    }
  }]
  public totalPendingEarnings: any[] = [{
    amount: 0,
    currency_id: 'rate',
    currency: {
      short_name: "pts"
    }
  }]
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<StatementComponent>,
    private dialog: MatDialog,
    private companyService: CompanyService,
    private taskService: TaskService,
    private membersService: MembersService,
    private chatService: ChatService,
    private globalDataService: GlobalDataService,
    private statementService: StatementService,
  ) { super() }

  ngOnInit(): void {
    console.log("StatementComponent", this.data)
    this.getCurrencyList();
    this.getPayments();
    this.getStatementRecord();
    this.getStatementRecordGroup();
    this.getOperations();
    this.getUserPositions(this.data.statement.employee_id);
    this.getGroupsCompany();
    this.checkIsMobile();
  }

  getCurrencyList() {
    this.attachSubscriptions(
      this.globalDataService.getCurrencies().subscribe(resp => {
        console.log("getCurrencyList", resp)
        this.currencyes = resp.slice();
        this.getStatementPending();
      })
    )
  }

  getUserPositions(id) {
    this.attachSubscriptions(
      this.membersService.getPostionsToEmployee(this.data.company.id, {employee_id: id}).subscribe(resp => {
        resp.forEach(el => {
          if (this.departments.filter(x => x.department_id == el.department_id).length == 0) {
            this.departments.push({
              department_id: el.department_id,
              department: el.department,
              positions: [Object.assign({info: el}, el.departmentPosition)]
            })
          } else {
            this.departments.find(x => x.department_id == el.department_id).positions.push(Object.assign({info: el}, el.departmentPosition))
          }
        });
        console.log("getUserPositions", this.departments);
      })
    )
  }

  getGroupById(id) {
    if (!this.groups || this.groups.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.groups.find(el => el.id == id)
  }

  getGroupsCompany() {
    this.attachSubscriptions(
      this.companyService.getInfiniteGroupsCompany(this.data.company.id, '1', '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }
          if (pages == 0) {
            return of([])
          }
          return forkJoin(arr.map(x => this.companyService.getInfiniteGroupsCompany(this.data.company.id, x).pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        this.groups = [].concat(...res);
      })
    )
  }

  getStatementPending() {
    this.attachSubscriptions(
      this.statementService.getStatementPending("1", this.data.company.id, this.data.statement.employee_id, 'record', this.data.statement.time_start, this.data.statement.time_end, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }
          if (pages == 0) {
            return of([])
          }
          return forkJoin(arr.map(x => this.statementService.getStatementPending(x, this.data.company.id, this.data.statement.employee_id, 'record', this.data.statement.time_start, this.data.statement.time_end, '200').pipe(map(u => u.body)))).pipe(
            last(),
            map(values => {
              return [].concat(...values)
            })
          )
        })
      ).subscribe(resp => {
        console.log("getStatementPending", resp)
        this.pendingEarnings = resp;
        this.pendingEarnings.forEach(p => {
          if (+p.rate != 0) {
            this.totalPendingEarnings[0].amount += +p.rate
          }
          if (this.totalPendingEarnings.filter(x => x.currency_id == p.price_currency_id).length == 0) {
            this.totalPendingEarnings.push({
              amount: +p.price,
              currency_id: p.price_currency_id,
              currency: this.currencyes.find(x => x.id == p.price_currency_id)
            })
          } else {
            this.totalPendingEarnings.find(x => x.currency_id == p.price_currency_id).amount += +p.price
          }
        })
        console.log("this.pendingEarnings",this.pendingEarnings);
      })
    )
  }

  // getStatementPending() {
  //   this.attachSubscriptions(
  //     this.statementService.getStatementPending("1", this.data.company.id, this.data.statement.employee_id, 'record', this.data.statement.time_start, this.data.statement.time_end).pipe( map(x => x.body) ).subscribe(resp => {
  //       console.log("getStatementPending", resp)
  //       this.pendingEarnings = resp;
  //       this.pendingEarnings.forEach(p => {
  //         if (+p.rate != 0) {
  //           this.totalPendingEarnings[0].amount += +p.rate
  //         }
  //         if (this.totalPendingEarnings.filter(x => x.currency_id == p.price_currency_id).length == 0) {
  //           this.totalPendingEarnings.push({
  //             amount: +p.price,
  //             currency_id: p.price_currency_id,
  //             currency: this.currencyes.find(x => x.id == p.price_currency_id)
  //           })
  //         } else {
  //           this.totalPendingEarnings.find(x => x.currency_id == p.price_currency_id).amount += +p.price
  //         }
  //       })
  //       console.log("this.pendingEarnings",this.pendingEarnings);
  //     })
  //   )
  // }

  neededData(task) {
    let arr = [
      this.chatService.getTasksChats(this.data.company.id, [task.id]).pipe(
        tap(res => {
          task['operations'].map(o_el => {
            o_el['chatsInfo'] = [];
            o_el['chatsInfo'].push(...res.filter(z => z.task_operation_id == o_el.id));
            o_el['unReadChats'] = res.filter(z => z.task_operation_id == o_el.id && z.is_read != '1').length;
            o_el['unClosedChats'] = res.filter(z => z.task_operation_id == o_el.id && z.status_id != '4').length;
          });
          task['chatsInfo'] = [];
          task['chatsInfo'].push(...res.filter(z => z.task_id == task.id));
          task['unReadChats'] = res.filter(z => z.is_read != '1').length;
          task['unClosedChats'] = res.filter(z => z.status_id != '4').length;
        })
      )
    ]
    if (task.company_id == this.data.company.id) {
      arr.push(
        this.taskService.getTaskClients(task.id, this.data.company.id).pipe(
          tap(res => {
            task.clients = res
          }),
          catchError(err => {
            return err
          })
        )
      )
    }
    return forkJoin(arr)
  }

  checkIsMobile() {
    if (window.innerWidth <= 769) {
      this.is_mobile = true;
    } else {
      this.is_mobile = false;
    }
    this.onResize();
  }
  
  onResize() {
    this.attachSubscriptions(
      fromEvent(window, "resize").pipe(
        map(() => window.innerWidth)
      ).subscribe((wWidth) => {
          if (wWidth <= 769) {
            this.is_mobile = true;
          } else {
            this.is_mobile = false;
          }
        }
      )
    )
  }

  openTask(task) {
    const dialogRef = this.dialog.open(OpenTaskComponent, {
      backdropClass: 'backdrop_under_header',
      panelClass: !this.is_mobile ? ['open_task_dialog', 'show_header'] : 'open_task_dialog',
      autoFocus: false,
      data: {
        task_id: task.id
      }
    });
  }

  openDocs() {
    const dialogRef = this.dialog.open(StatementDocumentsComponent, {
      backdropClass: 'backdrop_under_header',
      panelClass: !this.is_mobile ? ['open_task_dialog', 'show_header'] : 'open_task_dialog',
      autoFocus: false,
      data: {
        statement: this.data.statement,
        company: this.data.company
      }
    });
  }

  openChat(id) {
    this.attachSubscriptions(
      this.chatService.getChatsExpand(1, this.data.company.id, {id: id}).pipe(
        map(res => res.body[0])
      ).subscribe(chat => {
        const goToChat = this.dialog.open(ChatsComponent, {
          backdropClass: ['backdrop_under_header', "without_pad"],
          disableClose: true,
          panelClass: ['full_screen_dialog', 'global_chats_dialog'],
          data: {
            company: this.data.company,
            user: this.data.user,
            imgRoute: this.data.imgRoute,
            dialogType: 'global_chat',
            host: this.data.host,
            activeLang: this.data.activeLang, 
            chat: chat
          }
        });
      })
    )
  }

  openJob(task_id, task_operation_id) {
    this.attachSubscriptions(
      this.taskService.getOneTaskExpand(this.data.company.id, task_id).pipe(
        map(x => x.body[0]),
        switchMap(x => this.neededData(x).pipe(map(() => casesModel([x], [], 'update')),map(x => x.arr[0]))),
      ).subscribe(resp => {
        const dialogRef = this.dialog.open(OpenJobComponent, {
          disableClose: true,
          hasBackdrop: !this.data.is_mobile ? false : true,
          panelClass: !this.data.is_mobile ? ['open_task_dialog', 'show_header'] : 'open_task_dialog',
          position: !this.data.is_mobile ? { top: '58px', bottom: '58px' } : {},
          autoFocus: false,
          data: {
            task_id: task_id,
            task_operation_id: task_operation_id,
            task: resp,
          }
        });
      })
    )
  }

  getStatementRecordInside(earning) {
    this.attachSubscriptions(
      this.statementService.getStatementRecordInside("1", this.data.company.id, this.data.statement.time_start, this.data.statement.time_end, this.data.statement.employee_id, "200", earning.task.id, earning.task_operation_id, earning.discussion_id).pipe( map(x => x.body) ).subscribe(resp => {
        console.log("getStatementRecordInside", resp)
        earning.groupIn = resp
        earning.is_open = true
      })
    )
  }

  toggleGroup(earning) {
    if (earning.is_open) {
      earning.is_open = false
    } else {
      this.getStatementRecordInside(earning)
    }
  }

  getStatementRecordGroup() {
    this.attachSubscriptions(
      this.statementService.getStatementRecord("1", this.data.company.id, this.data.statement.time_start, this.data.statement.time_end, this.data.statement.employee_id, '1', 'group_earnings').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }
          if (pages == 0) {
            return of([])
          }
          return forkJoin(arr.map(x => this.statementService.getStatementRecord(x, this.data.company.id, this.data.statement.time_start, this.data.statement.time_end, this.data.statement.employee_id, '200', 'group_earnings').pipe(map(u => u.body)))).pipe(
            last(),
            map(vals => {
              return [].concat(...vals)
            })
          )
        }),
        switchMap(values => forkJoin(values.map(val => {
          return val.count >= 1 ? this.statementService.getStatementRecordInside("1", this.data.company.id, this.data.statement.time_start, this.data.statement.time_end, this.data.statement.employee_id, "200", val.task.id, val.task_operation_id, val.discussion_id).pipe( map(x => x.body), tap(b => val.groupIn = b) ) : of(val)
        })).pipe(map(() => values)))
      ).subscribe(resp => {
        console.log("getStatementRecordGroup", resp)
        this.earnings = resp

        this.earnings.forEach(p => {
          if (+p.rate != 0) {
            this.totalEarnings[0].amount += +p.rate
          }
          if (this.totalEarnings.filter(x => x.currency_id == p.price_currency_id).length == 0) {
            this.totalEarnings.push({
              amount: +p.price,
              currency_id: p.price_currency_id,
              currency: p.currency
            })
          } else {
            this.totalEarnings.find(x => x.currency_id == p.price_currency_id).amount += +p.price
          }
        })
        console.log("this.earnings",this.earnings);
      })
    )
  }

  // getStatementRecordGroup() {
  //   this.attachSubscriptions(
  //     this.statementService.getStatementRecord("1", this.data.company.id, this.data.statement.time_start, this.data.statement.time_end, this.data.statement.employee_id,'200', 'group_earnings').pipe( 
  //       map(x => x.body),
  //       switchMap(values => forkJoin(values.map(val => {
  //         return val.count >= 1 ? this.statementService.getStatementRecordInside("1", this.data.company.id, this.data.statement.time_start, this.data.statement.time_end, this.data.statement.employee_id, "200", val.task.id, val.task_operation_id, val.discussion_id).pipe( map(x => x.body), tap(b => val.groupIn = b) ) : of(val)
  //       })).pipe(map(() => values)))
  //     ).subscribe(resp => {
  //       console.log("getStatementRecordGroup", resp)
  //       this.earnings = resp

  //       this.earnings.forEach(p => {
  //         if (+p.rate != 0) {
  //           this.totalEarnings[0].amount += +p.rate
  //         }
  //         if (this.totalEarnings.filter(x => x.currency_id == p.price_currency_id).length == 0) {
  //           this.totalEarnings.push({
  //             amount: +p.price,
  //             currency_id: p.price_currency_id,
  //             currency: p.currency
  //           })
  //         } else {
  //           this.totalEarnings.find(x => x.currency_id == p.price_currency_id).amount += +p.price
  //         }
  //       })
  //       console.log("this.earnings",this.earnings);
  //     })
  //   )
  // }

  getPrimaryTag(item) {
    if (!!item && !!item.parameterValuesToTask && !!item.parameterValuesToTask.length) {
      return item.parameterValuesToTask.find(x => !!x.is_primary)
    } else {
      return undefined
    }
  }
  
  getStatementRecord() {
    this.attachSubscriptions(
      this.statementService.getStatementRecord("1", this.data.company.id, this.data.statement.time_start, this.data.statement.time_end, this.data.statement.employee_id, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }
          return forkJoin(arr.map(x => this.statementService.getStatementRecord(x, this.data.company.id, this.data.statement.time_start, this.data.statement.time_end, this.data.statement.employee_id, '200').pipe(map(u => u.body)))).pipe(
            last(),
            tap(values => {
              let resp = [].concat(...values)
              // 
              this.allTransactions = resp;

              this.allTransactions.forEach(p => {
                if (+p.rate != 0) {
                  this.totalAllTransactions[0].amount += +p.rate
                }
                if (this.totalAllTransactions.filter(x => x.currency_id == p.price_currency_id).length == 0) {
                  this.totalAllTransactions.push({
                    amount: +p.price,
                    currency_id: p.price_currency_id,
                    currency: p.currency
                  })
                } else {
                  this.totalAllTransactions.find(x => x.currency_id == p.price_currency_id).amount += +p.price
                }
              })
              console.log("this.totalAllTransactions",this.totalAllTransactions);
              console.log("this.allTransactions",this.allTransactions);
              // 


              // 

              this.returnedEarnings = resp.filter(x => x.type == 'return');

              this.returnedEarnings.forEach(p => {
                if (+p.rate != 0) {
                  this.totalReturnedEarnings[0].amount += +p.rate
                }
                if (this.totalReturnedEarnings.filter(x => x.currency_id == p.price_currency_id).length == 0) {
                  this.totalReturnedEarnings.push({
                    amount: +p.price,
                    currency_id: p.price_currency_id,
                    currency: p.currency
                  })
                } else {
                  this.totalReturnedEarnings.find(x => x.currency_id == p.price_currency_id).amount += +p.price
                }
              })
              console.log("this.returnedEarnings",this.returnedEarnings);

              // 

              this.recordEarnings = resp.filter(x => x.type == 'record');

              this.recordEarnings.forEach(p => {
                if (+p.rate != 0) {
                  this.totalRecordEarnings[0].amount += +p.rate
                }
                if (this.totalRecordEarnings.filter(x => x.currency_id == p.price_currency_id).length == 0) {
                  this.totalRecordEarnings.push({
                    amount: +p.price,
                    currency_id: p.price_currency_id,
                    currency: p.currency
                  })
                } else {
                  this.totalRecordEarnings.find(x => x.currency_id == p.price_currency_id).amount += +p.price
                }
              })
              console.log("this.recordEarnings",this.recordEarnings);

              // 

              this.changeEarnings = resp.filter(x => x.type == 'change');

              this.changeEarnings.forEach(p => {
                if (+p.rate != 0) {
                  this.totalChangeEarnings[0].amount += +p.rate
                }
                if (this.totalChangeEarnings.filter(x => x.currency_id == p.price_currency_id).length == 0) {
                  this.totalChangeEarnings.push({
                    amount: +p.price,
                    currency_id: p.price_currency_id,
                    currency: p.currency
                  })
                } else {
                  this.totalChangeEarnings.find(x => x.currency_id == p.price_currency_id).amount += +p.price
                }
              })
              console.log("this.changeEarnings",this.changeEarnings);

              // this.returnedEarnings = resp.filter(x => ![3,4,6,97,98,99].includes(x.status_id))
              // console.log("this.returnedEarnings",this.returnedEarnings);
            }),
          )
        }),
      ).subscribe(resp => {
        console.log("getStatementRecord resp", resp);
        console.log("getStatementRecord this.allTransactions", this.allTransactions);
      })
    )
  }

//   getStatementRecord() {
//     this.attachSubscriptions(
//       this.statementService.getStatementRecord("1", this.data.company.id, this.data.statement.time_start, this.data.statement.time_end, this.data.statement.employee_id).pipe( map(x => x.body) ).subscribe(resp => {
//         console.log("getStatementRecord",resp);
// // 
//         this.allTransactions = resp;

//         this.allTransactions.forEach(p => {
//           if (+p.rate != 0) {
//             this.totalAllTransactions[0].amount += +p.rate
//           }
//           if (this.totalAllTransactions.filter(x => x.currency_id == p.price_currency_id).length == 0) {
//             this.totalAllTransactions.push({
//               amount: +p.price,
//               currency_id: p.price_currency_id,
//               currency: p.currency
//             })
//           } else {
//             this.totalAllTransactions.find(x => x.currency_id == p.price_currency_id).amount += +p.price
//           }
//         })
//         console.log("this.totalAllTransactions",this.totalAllTransactions);
//         console.log("this.allTransactions",this.allTransactions);
// // 


// // 

//         this.returnedEarnings = resp.filter(x => x.type == 'return');

//         this.returnedEarnings.forEach(p => {
//           if (+p.rate != 0) {
//             this.totalReturnedEarnings[0].amount += +p.rate
//           }
//           if (this.totalReturnedEarnings.filter(x => x.currency_id == p.price_currency_id).length == 0) {
//             this.totalReturnedEarnings.push({
//               amount: +p.price,
//               currency_id: p.price_currency_id,
//               currency: p.currency
//             })
//           } else {
//             this.totalReturnedEarnings.find(x => x.currency_id == p.price_currency_id).amount += +p.price
//           }
//         })
//         console.log("this.returnedEarnings",this.returnedEarnings);

// // 

//         this.recordEarnings = resp.filter(x => x.type == 'record');

//         this.recordEarnings.forEach(p => {
//           if (+p.rate != 0) {
//             this.totalRecordEarnings[0].amount += +p.rate
//           }
//           if (this.totalRecordEarnings.filter(x => x.currency_id == p.price_currency_id).length == 0) {
//             this.totalRecordEarnings.push({
//               amount: +p.price,
//               currency_id: p.price_currency_id,
//               currency: p.currency
//             })
//           } else {
//             this.totalRecordEarnings.find(x => x.currency_id == p.price_currency_id).amount += +p.price
//           }
//         })
//         console.log("this.recordEarnings",this.recordEarnings);

// // 

//         this.changeEarnings = resp.filter(x => x.type == 'change');

//         this.changeEarnings.forEach(p => {
//           if (+p.rate != 0) {
//             this.totalChangeEarnings[0].amount += +p.rate
//           }
//           if (this.totalChangeEarnings.filter(x => x.currency_id == p.price_currency_id).length == 0) {
//             this.totalChangeEarnings.push({
//               amount: +p.price,
//               currency_id: p.price_currency_id,
//               currency: p.currency
//             })
//           } else {
//             this.totalChangeEarnings.find(x => x.currency_id == p.price_currency_id).amount += +p.price
//           }
//         })
//         console.log("this.changeEarnings",this.changeEarnings);

//         // this.returnedEarnings = resp.filter(x => ![3,4,6,97,98,99].includes(x.status_id))
//         // console.log("this.returnedEarnings",this.returnedEarnings);
//       })
//     )
//   }

  checkIfHasSumm(arr, key) {
    if (key == "rate") {
      return !!arr ? arr.filter(x => x.amount != 0 && x.currency_id == 'rate').length > 0 : false;
    } else {
      return !!arr ? arr.filter(x => x[key] != 0).length > 0 : false;
    }
  }

  getOperations() {
    this.attachSubscriptions(
      this.taskService.getOperations(this.data.company.id, this.data.activeLang).subscribe(resp => {
        this.operations = resp
      })
    )
  }

  getOperationName(id) {
    if (!this.operations || this.operations.filter(el => el.id == id).length == 0) {
      return '';
    }
    return this.operations.find(el => el.id == id) && this.operations.find(el => el.id == id).translation ? this.operations.find(el => el.id == id).translation.name : this.operations.find(el => el.id == id).name
  }

  getPayments() {
    this.attachSubscriptions(
      this.companyService.getPayments("1", this.data.company.id, this.data.statement.employee_id, 0, '1', this.data.statement.time_start, this.data.statement.time_end).pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }
          if (pages == 0) {
            return of([])
          }
          return forkJoin(arr.map(x => this.companyService.getPayments(x, this.data.company.id, this.data.statement.employee_id, 0, '200', this.data.statement.time_start, this.data.statement.time_end).pipe(map(u => u.body)))).pipe(
            last(),
            map(values => {
              return [].concat(...values)
            })
          )
        }),
        tap(res => {
          res.forEach(p => {
            if (this.totalPayouts.filter(x => x.currency_id == p.currency_id).length == 0) {
              this.totalPayouts.push({
                amount: +p.amount,
                currency_id: p.currency_id,
                currency: p.currency
              })
            } else {
              this.totalPayouts.find(x => x.currency_id == p.currency_id).amount += +p.amount
            }
          })
        })
      ).subscribe(resp => {
        this.payments = resp;
        console.log("payments", this.payments);
        console.log("this.totalPayouts", this.totalPayouts);
      })
    )
  }
  // getPayments() {
  //   this.attachSubscriptions(
  //     this.companyService.getPayments("1", this.data.company.id, this.data.statement.employee_id, 0, '200', this.data.statement.time_start, this.data.statement.time_end).pipe(
  //       map(x => x.body),
  //       tap(res => {
  //         res.forEach(p => {
  //           if (this.totalPayouts.filter(x => x.currency_id == p.currency_id).length == 0) {
  //             this.totalPayouts.push({
  //               amount: +p.amount,
  //               currency_id: p.currency_id,
  //               currency: p.currency
  //             })
  //           } else {
  //             this.totalPayouts.find(x => x.currency_id == p.currency_id).amount += +p.amount
  //           }
  //         })
  //       })
  //     ).subscribe(resp => {
  //       this.payments = resp
  //       console.log("payments", this.payments)
  //       console.log("this.totalPayouts", this.totalPayouts)
  //     })
  //   )
  // }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

}
