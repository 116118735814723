<mat-dialog-content class="mat-typography">
    <div class="container tag-task">
        <button class="close_dialog_btn" mat-icon-button (click)="close()">
            <mat-icon>close</mat-icon>
        </button>
        <mat-tab-group [selectedIndex]="activeIndex" (selectedIndexChange)="changeTab($event)">
            <mat-tab>
                <ng-template mat-tab-label>
                    <mat-icon class="example-tab-icon">work</mat-icon>
                    {{ "Workspace tags" | translate }}
                </ng-template>
                <div class="reports_dash">
                    <div class="reports_dash_header">
                        <div class="column report_col apply_col">
                            <div class="title">
                                {{"Apply" | translate}}
                            </div>
                        </div>
                        <div class="column report_col">
                            <div class="title">
                                {{"Tag" | translate}}
                            </div>
                        </div>
                        <div class="column report_col expand" style="align-items: flex-end !important;">
                            <div class="title">
                                {{"Expand" | translate}}
                            </div>
                        </div>
                    </div>

                    <div class="reports_wrp" [appDeviceClass]="'custom-scroll custom-scroll-t'" >
                        <div class="report" *ngFor="let item of wParameters">
                            <div class="report_line">
                                <div class="column report_col apply_col">
                                    <mat-checkbox class="example-margin" (change)="changeWParam($event, item)" [checked]="!!item?.tips?.length" [disabled]="!!item.isEddited"></mat-checkbox>
                                </div>
                                <div class="column report_col">
                                    <span class="big">
                                        {{ item?.name }}
                                    </span>
                                </div>
                                <div class="column report_col expand">
                                    <button mat-icon-button style="color: #686868;" (click)="item.isOpen = !item.isOpen">
                                        <mat-icon>
                                            {{ item.isOpen ? 'expand_less' : 'expand_more' }}
                                        </mat-icon>
                                    </button>
                                </div>
                            </div>
                            <div class="param_values report_sub_lines" *ngIf="item.isOpen">
                                <div class="param_value report_sub_line" *ngFor="let val of item.values; let k = index;">
                                    <div class="column report_col apply_col">
                                        <mat-checkbox class="example-margin" (change)="changeWVal($event, item, val)" [checked]="!!val?.tips?.length" [disabled]="!!val.isEddited"></mat-checkbox>
                                    </div>
                                    <div class="column report_col">
                                        <span class="normal">
                                            {{val.value}}
                                        </span>
                                    </div>

                                    <div class="column report_col expand">
                                    </div>
                                </div>
        
                            </div>
                        </div>
                    </div>
                </div>
            </mat-tab>
          
            <mat-tab *ngIf="data.user.is_root == 1">
              <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">security</mat-icon>
                {{ "System Tags" | translate }}
              </ng-template>
     
                <div class="reports_dash">
                    <div class="reports_dash_header">
                        <div class="column report_col apply_col">
                            <div class="title">
                                {{"Apply" | translate}}
                            </div>
                        </div>

                        <div class="column report_col">
                            <div class="title">
                                {{"Tag" | translate}}
                            </div>
                        </div>
    
                        <div class="column report_col expand" style="align-items: flex-end !important;">
                            <div class="title">
                                {{"Expand" | translate}}
                            </div>
                        </div>
                    </div>

                    <div class="reports_wrp" [appDeviceClass]="'custom-scroll custom-scroll-t'" >
                        <div class="report" *ngFor="let item of rParameters">
                            <div class="report_line">
                                <div class="column report_col apply_col">
                                    <mat-checkbox class="example-margin" (change)="changeRParam($event, item)" [checked]="!!item?.tips?.length" [disabled]="!!item.isEddited"></mat-checkbox>
                                </div>
                                <div class="column report_col">
                                    <span class="big">
                                        {{ item?.name }}
                                    </span>
                                </div>
                                <div class="column report_col expand">
                                    <button mat-icon-button style="color: #686868;" (click)="item.isOpen = !item.isOpen">
                                        <mat-icon>
                                            {{ item.isOpen ? 'expand_less' : 'expand_more' }}
                                        </mat-icon>
                                    </button>
                                </div>
                            </div>
                            <div class="param_values report_sub_lines" *ngIf="item.isOpen">
                                <div class="param_value report_sub_line" *ngFor="let val of item.values; let k = index;">
                                    <div class="column report_col apply_col">
                                        <mat-checkbox class="example-margin" (change)="changeRVal($event, item, val)" [checked]="!!val?.tips?.length" [disabled]="!!val.isEddited"></mat-checkbox>
                                    </div>
                                    <div class="column report_col">
                                        <span class="normal">
                                            {{val.value}}
                                        </span>
                                    </div>
                            
                                    <div class="column report_col expand">
                                    </div>
                                </div>
        
                            </div>
                        </div>
                    </div>
                </div>
            </mat-tab>
          </mat-tab-group>
          
    </div>
</mat-dialog-content>