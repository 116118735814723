<h2 mat-dialog-title class="dialog_title">
    <span>
        {{ "Column settings" | translate }}
    </span>
</h2>
<mat-dialog-content class="mat-typography" dirEnter (dirSubmit)="submitForm()">
    <div class="container form_wrapper" style="padding: 20px 0;">
        <mat-form-field appearance="standard" class="full_width_form">
            <mat-label>{{ "Font size" | translate }}</mat-label>
            <input [(ngModel)]="data.col.font_size" matInput placeholder="{{ 'Font size' | translate }}">
        </mat-form-field>
        <mat-form-field appearance="standard" class="full_width_form">
            <mat-label>{{ "Padding left" | translate }}</mat-label>
            <input [(ngModel)]="data.col.padding_left" matInput placeholder="{{ 'Padding left' | translate }}">
        </mat-form-field>
        <mat-form-field appearance="standard" class="full_width_form">
            <mat-label>{{ "Padding right" | translate }}</mat-label>
            <input [(ngModel)]="data.col.padding_right" matInput placeholder="{{ 'Padding right' | translate }}">
        </mat-form-field>
        <mat-form-field appearance="standard" class="full_width_form">
            <mat-label>{{ "Padding top" | translate }}</mat-label>
            <input [(ngModel)]="data.col.padding_top" matInput placeholder="{{ 'Padding top' | translate }}">
        </mat-form-field>
        <mat-form-field appearance="standard" class="full_width_form">
            <mat-label>{{ "Padding bottom" | translate }}</mat-label>
            <input [(ngModel)]="data.col.padding_bottom" matInput placeholder="{{ 'Padding bottom' | translate }}">
        </mat-form-field>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button (click)="close()">
        {{ "Cancel" | translate }}
    </button>
    <button class="ml-1 d-f-btn" (click)="submitForm()" mat-raised-button cdkFocusInitial color="primary">
        {{ "Save" | translate }}
    </button>
</mat-dialog-actions>
