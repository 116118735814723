<div class="container">
</div>
<div class="reports_dash">
    <form class="reports_dash_header">
        <div class="column report_col check">
            <mat-checkbox class="select_all_checkbox" *ngIf="itemsCollection" (click)="$event.preventDefault; $event.stopPropagation()" (change)="$event ? masterToggle() : null"
            [checked]="itemsCollection.hasValue() && isAllSelected()"
            [indeterminate]="itemsCollection.hasValue() && !isAllSelected()"
            [aria-label]="checkboxLabel()">
            </mat-checkbox>
        </div>
        <div class="column report_col">
            <div class="title">
                {{"Type" | translate}}
            </div>
        </div>
        <div class="column report_col name_col">
            <div class="title">
                {{"Name" | translate}}
            </div>
        </div>
        <div class="column report_col">
            <div class="title">
                {{"Priority" | translate}}
            </div>
        </div>
        <div class="column report_col">
            <div class="title">
                {{"Status" | translate}}
            </div>
        </div>
        <div class="column report_col users_col">
            <div class="title">
                {{"Executors" | translate}}
            </div>
        </div>
        <div class="column report_col check">
            <div class="title"
            #tooltip="matTooltip"
            matTooltip="{{'Add executor to Board by order' | translate}}"
            [matTooltipPosition]="'above'" >
                {{"BbO" | translate}}
            </div>
        </div>
        <div class="column report_col users_col">
            <div class="title">
                {{"Partners" | translate}}
            </div>
        </div>
        <div class="column report_col users_col">
            <div class="title">
                {{"Clients" | translate}}
            </div>
        </div>
        <div class="column report_col users_col">
            <div class="title">
                {{"Client Partners" | translate}}
            </div>
        </div>
        <div class="column report_col users_col">
            <div class="title">
                {{"Scenarios" | translate}}
            </div>
        </div>
        <!-- <div class="column report_col users_col">
            <div class="title">
                {{"Jobs tab" | translate}}
            </div>
        </div> -->
        <div class="column report_col users_col">
            <div class="title">
                {{"Tags" | translate}}
            </div>
        </div>
        <div class="column report_col deadline">
            <div class="title">
                {{"Deadline" | translate}}
            </div>
        </div>
    </form>

    <div class="reports_wrp" [appDeviceClass]="'custom-scroll custom-scroll-t'">
        <div class="report selected_report" *ngIf="itemsCollection.hasValue()">
            <div class="report_line">
                <div class="column report_col check">
                    <div class="title">
                        {{itemsCollection?.selected?.length}} {{"Jobs" | translate}}
                    </div>
                </div>
                <div class="column report_col">
                    <span class="val cp" (click)="typeAllContainer.open()">
                        {{selectedJobs.operation_id ? getJobTypeById(selectedJobs.operation_id)?.name : ("Not set" | translate)}}
                    </span>
                    <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                        <mat-select [disableOptionCentering]="true" selectDir #typeAllContainer [(ngModel)]="selectedJobs.operation_id" (selectionChange)="selectedJobsChange($event, 'operation_id')">
                            <mat-option>
                                <ngx-mat-select-search 
                                placeholderLabel="{{ 'Search' | translate }}" 
                                noEntriesFoundLabel="{{ 'no matching job type found' | translate }}"
                                [formControl]="operationMoreControl"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let operation of operations$ | async" [value]="operation.id">
                                {{ operation.name | translate }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="column report_col name_col">
                    <input class="name_input" type="text" [(ngModel)]="selectedJobs.name" (input)="selectedJobsChange($event, 'name')">
                </div>
                <div class="column report_col">
                    <span class="val cp" (click)="priorityAllContainer.open()">
                        {{selectedJobs.priority ? getPriorityById(selectedJobs.priority)?.name : ("Not set" | translate)}}
                    </span>
                    <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                        <mat-select [disableOptionCentering]="true" selectDir #priorityAllContainer [(ngModel)]="selectedJobs.priority" (selectionChange)="selectedJobsChange($event, 'priority')">
                            <mat-option *ngFor="let priority of prioritys" [value]="priority.id">
                                {{ priority.name | translate }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="column report_col">
                    <span class="val cp" (click)="statusAllContainer.open()">
                        {{selectedJobs.status_id ? getStatusById(selectedJobs.status_id)?.name : ("Not set" | translate)}}
                    </span>
                    <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                        <mat-select [disableOptionCentering]="true" selectDir #statusAllContainer [(ngModel)]="selectedJobs.status_id" (selectionChange)="selectedJobsChange($event, 'status_id')">
                            <mat-option *ngFor="let status of operationStatuses" [value]="status.id">
                                {{ status.name | translate }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <!-- multi empl -->
                <div class="column report_col users_col">
                    <div class="multi_users_cr_jobs" (click)="employeeAlllContainer.open()">
                        <ng-container *ngIf="selectedJobs.employees && selectedJobs.employees.length">
                            <ng-container *ngFor="let id of selectedJobs.employees">
                                <div class="d-f-c">
                                    <app-user [class]="'user_div'" [employee]="getEmployeeById(id)"></app-user>
                                </div>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="!(selectedJobs.employees && selectedJobs.employees.length)">
                            <span class="val cp">{{"Not set" | translate}}</span>
                        </ng-container>
                    </div>
                    <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                        <mat-select [disableOptionCentering]="true" selectDir #employeeAlllContainer [(ngModel)]="selectedJobs.employees" [multiple]="true" (selectionChange)="selectedJobsChange($event, 'employees')">
                            <mat-option>
                                <ngx-mat-select-search 
                                placeholderLabel="{{ 'Search' | translate }}" 
                                noEntriesFoundLabel="{{ 'no matching executor found' | translate }}"
                                [formControl]="employeeMoreControl"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let employee of employees$ | async" [value]="employee.id">
                                <app-user [class]="'user_div'" [employee]="employee"></app-user>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="column report_col check">
                    <mat-checkbox color="primary" class="example-margin" (click)="$event.stopPropagation()" (change)="toggleBBO($event, selectedJobs, true)" [(ngModel)]="selectedJobs.bbo" [checked]='selectedJobs.bbo'></mat-checkbox>
                </div>
                <!-- multi partners -->
                <div class="column report_col users_col">
                    <div class="multi_users_cr_jobs" (click)="partnerAllContainer.open()">
                        <ng-container *ngIf="selectedJobs.partners && selectedJobs.partners.length">
                            <ng-container *ngFor="let id of selectedJobs.partners">
                                <div class="d-f-c">
                                    <app-user [class]="'user_div'" [companyEmployee]="getPartnerById(id)?.partnerCompany" [imgRoute]="imgRoute" [host]="host" [company]="data?.company"></app-user>
                                </div>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="!(selectedJobs.partners && selectedJobs.partners.length)">
                            <span class="val cp">{{"Not set" | translate}}</span>
                        </ng-container>
                    </div>
                    <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                        <mat-select [disableOptionCentering]="true" selectDir #partnerAllContainer [(ngModel)]="selectedJobs.partners" [multiple]="true" (selectionChange)="selectedJobsChange($event, 'partners')">
                            <mat-option>
                                <ngx-mat-select-search 
                                placeholderLabel="{{ 'Search' | translate }}" 
                                noEntriesFoundLabel="{{ 'no matching partner found' | translate }}"
                                [formControl]="partnerMoreControl"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let partner of partners$ | async" [value]="partner.partner_company_id">
                                <app-user [class]="'user_div'" [companyEmployee]="partner.partnerCompany" [imgRoute]="imgRoute" [host]="host" [company]="data?.company"></app-user>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <!-- multi cli -->
                <div class="column report_col users_col">
                    <div class="multi_users_cr_jobs" (click)="clientAllContainer.open()">
                        <ng-container *ngIf="selectedJobs.clients && selectedJobs.clients.length">
                            <ng-container *ngFor="let cli of selectedJobs.clients">
                                <div class="d-f-c">
                                    <app-user [class]="'user_div'" [employee]="getEmployeeById(cli.client_employee_id)"></app-user>

                                    <mat-icon style="margin-left: 3.5px; width: 10px; height: 10px; line-height: 10px; font-size: 10px;" 
                                    #tooltip="matTooltip"
                                    matTooltip="{{'Permission to approve granted' | translate}}"
                                    [matTooltipPosition]="'above'" 
                                    (click)="$event.preventDefault(); $event.stopPropagation(); cli.is_principal = 0;" 
                                    *ngIf="!!cli?.is_principal">thumb_up</mat-icon>

                                    <mat-icon 
                                    style="margin-left: 3.5px; width: 10px; height: 10px; line-height: 10px; font-size: 10px;" 
                                    #tooltip="matTooltip"
                                    matTooltip="{{'Permission to approve not granted' | translate}}"
                                    [matTooltipPosition]="'above'" 
                                    (click)="$event.preventDefault(); $event.stopPropagation(); cli.is_principal = 1;" 
                                    *ngIf="!cli?.is_principal">thumb_up_off_alt</mat-icon>
                                </div>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="!(selectedJobs.clients && selectedJobs.clients.length)">
                            <span class="val cp">{{"Not set" | translate}}</span>
                        </ng-container>
                    </div>
                    <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                        <mat-select [disableOptionCentering]="true" selectDir #clientAllContainer [(ngModel)]="selectedJobs.clients" [multiple]="true" (selectionChange)="selectedJobsChange($event, 'clients')">
                            <mat-option>
                                <ngx-mat-select-search 
                                placeholderLabel="{{ 'Search' | translate }}" 
                                noEntriesFoundLabel="{{ 'no matching executor found' | translate }}"
                                [formControl]="employeeMoreControl"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let employee of employees$ | async" [value]="{client_employee_id: employee.id, is_principal: 1}">
                                <app-user [class]="'user_div'" [employee]="employee"></app-user>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <!-- multi cli partners -->
                <div class="column report_col users_col">
                    <div class="multi_users_cr_jobs" (click)="cliPartnerAllContainer.open()">
                        <ng-container *ngIf="selectedJobs.partnerClients && selectedJobs.partnerClients.length">
                            <ng-container *ngFor="let cli of selectedJobs.partnerClients">
                                <div class="d-f-c">
                                    <app-user [class]="'user_div'" [companyEmployee]="getPartnerById(cli.client_partner_company_id)?.partnerCompany" [imgRoute]="imgRoute" [host]="host" [company]="data?.company"></app-user>
                                    <mat-icon style="margin-left: 3.5px; width: 10px; height: 10px; line-height: 10px; font-size: 10px;" 
                                    #tooltip="matTooltip"
                                    matTooltip="{{'Permission to approve granted' | translate}}"
                                    [matTooltipPosition]="'above'" 
                                    (click)="$event.preventDefault(); $event.stopPropagation(); cli.is_principal = 0;" 
                                    *ngIf="!!cli?.is_principal">thumb_up</mat-icon>

                                    <mat-icon 
                                    style="margin-left: 3.5px; width: 10px; height: 10px; line-height: 10px; font-size: 10px;" 
                                    #tooltip="matTooltip"
                                    matTooltip="{{'Permission to approve not granted' | translate}}"
                                    [matTooltipPosition]="'above'" 
                                    (click)="$event.preventDefault(); $event.stopPropagation(); cli.is_principal = 1;" 
                                    *ngIf="!cli?.is_principal">thumb_up_off_alt</mat-icon>
                                </div>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="!(selectedJobs.partnerClients && selectedJobs.partnerClients.length)">
                            <span class="val cp">{{"Not set" | translate}}</span>
                        </ng-container>
                    </div>
                    <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                        <mat-select [disableOptionCentering]="true" selectDir #cliPartnerAllContainer [(ngModel)]="selectedJobs.partnerClients" [multiple]="true" (selectionChange)="selectedJobsChange($event, 'partnerClients')">
                            <mat-option>
                                <ngx-mat-select-search 
                                placeholderLabel="{{ 'Search' | translate }}" 
                                noEntriesFoundLabel="{{ 'no matching partner found' | translate }}"
                                [formControl]="partnerMoreControl"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let partner of partners$ | async" [value]="{client_partner_company_id: partner.partner_company_id, is_principal: 1}">
                                <app-user [class]="'user_div'" [companyEmployee]="partner.partnerCompany" [imgRoute]="imgRoute" [host]="host" [company]="data?.company"></app-user>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <!-- multi scen -->
                <div class="column report_col users_col">
                    <div class="multi_users_cr_jobs" (click)="templatesAllContainer.open()">
                        <ng-container *ngIf="selectedJobs.templates && selectedJobs.templates.length">
                            <ng-container *ngFor="let id of selectedJobs.templates">
                                <div class="d-f-c has_pad">
                                    <div class="text_one_line">{{ getTemplateById(id)?.name }}</div>
                                </div>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="!(selectedJobs.templates && selectedJobs.templates.length)">
                            <span class="val cp">{{"Not set" | translate}}</span>
                        </ng-container>
                    </div>
                    <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                        <mat-select [multiple]="true" [disableOptionCentering]="true" selectDir #templatesAllContainer [(ngModel)]="selectedJobs.templates" (selectionChange)="selectedJobsChange($event, 'templates')">
                            <mat-option>
                                <ngx-mat-select-search 
                                placeholderLabel="{{ 'Search' | translate }}" 
                                noEntriesFoundLabel="{{ 'no matching template found' | translate }}"
                                [formControl]="templatesMoreControl"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let template of templates$ | async" [value]="template.id">
                                {{template.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="column report_col users_col">
                    <div class="multi_users_cr_jobs" (click)="tagsAllContainer.open()">
                        <ng-container *ngIf="selectedJobs.tags && selectedJobs.tags.length">
                            <ng-container *ngFor="let tag of selectedJobs.tags">
                                <div class="d-f-c has_pad">
                                    <div class="text_one_line">{{ getValuesById(tag.parameter_value_id)?.value }}</div>
                                    <mat-icon style="margin-left: 3.5px; width: 10px; height: 10px; line-height: 10px; font-size: 10px;" 
                                    #tooltip="matTooltip"
                                    matTooltip="{{'Primary granted' | translate}}"
                                    [matTooltipPosition]="'above'" 
                                    (click)="$event.preventDefault(); $event.stopPropagation(); tag.is_primary = 0;" 
                                    *ngIf="!!tag?.is_primary">thumb_up</mat-icon>

                                    <mat-icon 
                                    style="margin-left: 3.5px; width: 10px; height: 10px; line-height: 10px; font-size: 10px;" 
                                    #tooltip="matTooltip"
                                    matTooltip="{{'Primary not granted' | translate}}"
                                    [matTooltipPosition]="'above'" 
                                    (click)="$event.preventDefault(); $event.stopPropagation(); tag.is_primary = 1;" 
                                    *ngIf="!tag?.is_primary">thumb_up_off_alt</mat-icon>
                                </div>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="!(selectedJobs.tags && selectedJobs.tags.length)">
                            <span class="val cp">{{"Not set" | translate}}</span>
                        </ng-container>
                    </div>
                    <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                        <!-- <mat-label>{{ "Tag(s)" | translate }}</mat-label> -->
                        <mat-select [disableOptionCentering]="true" selectDir #tagsAllContainer [multiple]="true" [(ngModel)]="selectedJobs.tags" (selectionChange)="selectedJobsChange($event, 'tags')">
                            <mat-option>
                                <ngx-mat-select-search 
                                placeholderLabel="{{ 'Search' | translate }}" 
                                noEntriesFoundLabel="{{ 'no matching tag found' | translate }}"
                                [formControl]="allValuesControl"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let tag of allValues$ | async" [value]="{parameter_value_id: tag.id, parameter_id: tag.parameter_id, is_primary: 1}">
                                <div class="d-f-c" style="justify-content: space-between;">
                                    <span>{{ tag.value }}</span>
                                    <span>{{ tag?.parameter?.name }}</span>
                                </div>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="column report_col deadline">
                    <div class="date_and_time_c_w custom">
                        <mat-form-field appearance="standard" class="date_picker_cst">
                            <div class="prefix" matPrefix style="margin-right: 10px;">{{ "Date" | translate }}</div>
                            <input [(ngModel)]="selectedJobs.date.day" (dateChange)="selectedJobsChange($event, 'date')" matInput [matDatepicker]="pickerDay" placeholder="MM / DD/ YYYY">
                            <mat-datepicker-toggle matSuffix [for]="pickerDay"></mat-datepicker-toggle>
                            <mat-datepicker #pickerDay></mat-datepicker>
                        </mat-form-field>
                        
                        <div class="ngx-timepicker-field-example">
                            <div class="prefix">{{ "Time" | translate }}</div>
                            <ngx-timepicker-field [format]="24" [defaultTime]="selectedJobs.date.time" [(ngModel)]="selectedJobs.date.time" (timeChanged)="selectedJobsChange($event, 'date')"></ngx-timepicker-field>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="report" *ngFor="let item of jobs; let i = index;">
            <div class="report_line">
                <div class="column report_col check">
                    <mat-checkbox color="primary" class="example-margin" (click)="$event.stopPropagation()" (change)="$event ? toggleJob(item) : null" [checked]='itemsCollection.isSelected(item)'></mat-checkbox>
                </div>
                <div class="column report_col">
                    <span class="val cp" (click)="operationIdContainer.open()">
                        {{item.operation_id ? getJobTypeById(item.operation_id)?.name : ("Not set" | translate)}}
                    </span>
                    <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                        <mat-select [disableOptionCentering]="true" selectDir #operationIdContainer [(ngModel)]="item.operation_id">
                            <mat-option>
                                <ngx-mat-select-search 
                                placeholderLabel="{{ 'Search' | translate }}" 
                                noEntriesFoundLabel="{{ 'no matching job type found' | translate }}"
                                [formControl]="operationMoreControl"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let operation of operations$ | async" [value]="operation.id">
                                {{ operation.name | translate }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="column report_col name_col">
                    <input class="name_input" type="text" [(ngModel)]="item.name" placeholder="{{'Job name' | translate}}">
                </div>
                <div class="column report_col">
                    <span class="val cp" (click)="priorityContainer.open()">
                        {{item.priority ? getPriorityById(item.priority)?.name : ("Not set" | translate)}}
                    </span>
                    <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                        <mat-select [disableOptionCentering]="true" selectDir #priorityContainer [(ngModel)]="item.priority">
                            <mat-option *ngFor="let priority of prioritys" [value]="priority.id">
                                {{ priority.name | translate }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="column report_col">
                    <span class="val cp" (click)="statusContainer.open()">
                        {{item.status_id ? getStatusById(item.status_id)?.name : ("Not set" | translate)}}
                    </span>
                    <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                        <mat-select [disableOptionCentering]="true" selectDir #statusContainer [(ngModel)]="item.status_id">
                            <mat-option *ngFor="let status of operationStatuses" [value]="status.id">
                                {{ status.name | translate }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <!-- solo empls -->
                <div class="column report_col users_col">
                    <div class="multi_users_cr_jobs" (click)="employeeIdContainer.open()">
                        <ng-container *ngIf="item.employees && item.employees.length">
                            <ng-container *ngFor="let id of item.employees">
                                <div class="d-f-c">
                                    <app-user [class]="'user_div'" [employee]="getEmployeeById(id)"></app-user>
                                </div>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="!(item.employees && item.employees.length)">
                            <span class="val cp">{{"Not set" | translate}}</span>
                        </ng-container>
                    </div>
                    <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                        <mat-select [disableOptionCentering]="true" selectDir #employeeIdContainer [(ngModel)]="item.employees" [multiple]="true">
                            <mat-option>
                                <ngx-mat-select-search 
                                placeholderLabel="{{ 'Search' | translate }}" 
                                noEntriesFoundLabel="{{ 'no matching executor found' | translate }}"
                                [formControl]="employeeMoreControl"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let employee of employees$ | async" [value]="employee.id">
                                <app-user [class]="'user_div'" [employee]="employee"></app-user>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="column report_col check">
                    <mat-checkbox color="primary" class="example-margin" (click)="$event.stopPropagation()" (change)="toggleBBO($event, item)" [(ngModel)]="item.bbo" [checked]='item.bbo'></mat-checkbox>
                </div>
                <!-- solo partners -->
                <div class="column report_col users_col">
                    <div class="multi_users_cr_jobs" (click)="partnerIdContainer.open()">
                        <ng-container *ngIf="item.partners && item.partners.length">
                            <ng-container *ngFor="let id of item.partners">
                                <div class="d-f-c">
                                    <app-user [class]="'user_div'" [companyEmployee]="getPartnerById(id)?.partnerCompany" [imgRoute]="imgRoute" [host]="host" [company]="data?.company"></app-user>
                                </div>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="!(item.partners && item.partners.length)">
                            <span class="val cp">{{"Not set" | translate}}</span>
                        </ng-container>
                    </div>
                    <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                        <mat-select [disableOptionCentering]="true" selectDir #partnerIdContainer [(ngModel)]="item.partners" [multiple]="true">
                            <mat-option>
                                <ngx-mat-select-search 
                                placeholderLabel="{{ 'Search' | translate }}" 
                                noEntriesFoundLabel="{{ 'no matching partner found' | translate }}"
                                [formControl]="partnerMoreControl"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let partner of partners$ | async" [value]="partner.partner_company_id">
                                <app-user [class]="'user_div'" [companyEmployee]="partner.partnerCompany" [imgRoute]="imgRoute" [host]="host" [company]="data?.company"></app-user>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <!-- solo clients -->
                <div class="column report_col users_col">
                    <div class="multi_users_cr_jobs" (click)="clientIdContainer.open()">
                        <ng-container *ngIf="item.clients && item.clients.length">
                            <ng-container *ngFor="let cli of item.clients">
                                <div class="d-f-c">
                                    <app-user [class]="'user_div'" [employee]="getEmployeeById(cli.client_employee_id)"></app-user>

                                    <mat-icon style="margin-left: 3.5px; width: 10px; height: 10px; line-height: 10px; font-size: 10px;" 
                                    #tooltip="matTooltip"
                                    matTooltip="{{'Permission to approve granted' | translate}}"
                                    [matTooltipPosition]="'above'" 
                                    (click)="$event.preventDefault(); $event.stopPropagation(); cli.is_principal = 0;" 
                                    *ngIf="!!cli?.is_principal">thumb_up</mat-icon>

                                    <mat-icon 
                                    style="margin-left: 3.5px; width: 10px; height: 10px; line-height: 10px; font-size: 10px;" 
                                    #tooltip="matTooltip"
                                    matTooltip="{{'Permission to approve not granted' | translate}}"
                                    [matTooltipPosition]="'above'" 
                                    (click)="$event.preventDefault(); $event.stopPropagation(); cli.is_principal = 1;" 
                                    *ngIf="!cli?.is_principal">thumb_up_off_alt</mat-icon>
                                </div>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="!(item.clients && item.clients.length)">
                            <span class="val cp">{{"Not set" | translate}}</span>
                        </ng-container>
                    </div>
                    <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                        <mat-select [disableOptionCentering]="true" selectDir #clientIdContainer [(ngModel)]="item.clients" [multiple]="true">
                            <mat-option>
                                <ngx-mat-select-search 
                                placeholderLabel="{{ 'Search' | translate }}" 
                                noEntriesFoundLabel="{{ 'no matching executor found' | translate }}"
                                [formControl]="employeeMoreControl"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let employee of employees$ | async" [value]="{client_employee_id: employee.id, is_principal: 1}">
                                <app-user [class]="'user_div'" [employee]="employee"></app-user>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <!-- solo cli partners -->
                <div class="column report_col users_col">
                    <div class="multi_users_cr_jobs" (click)="cliPartnerContainer.open()">
                        <ng-container *ngIf="item.partnerClients && item.partnerClients.length">
                            <ng-container *ngFor="let cli of item.partnerClients">
                                <div class="d-f-c">
                                    <app-user [class]="'user_div'" [companyEmployee]="getPartnerById(cli.client_partner_company_id)?.partnerCompany" [imgRoute]="imgRoute" [host]="host" [company]="data?.company"></app-user>
                                    <mat-icon style="margin-left: 3.5px; width: 10px; height: 10px; line-height: 10px; font-size: 10px;" 
                                    #tooltip="matTooltip"
                                    matTooltip="{{'Permission to approve granted' | translate}}"
                                    [matTooltipPosition]="'above'" 
                                    (click)="$event.preventDefault(); $event.stopPropagation(); cli.is_principal = 0;" 
                                    *ngIf="!!cli?.is_principal">thumb_up</mat-icon>

                                    <mat-icon 
                                    style="margin-left: 3.5px; width: 10px; height: 10px; line-height: 10px; font-size: 10px;" 
                                    #tooltip="matTooltip"
                                    matTooltip="{{'Permission to approve not granted' | translate}}"
                                    [matTooltipPosition]="'above'" 
                                    (click)="$event.preventDefault(); $event.stopPropagation(); cli.is_principal = 1;" 
                                    *ngIf="!cli?.is_principal">thumb_up_off_alt</mat-icon>
                                </div>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="!(item.partnerClients && item.partnerClients.length)">
                            <span class="val cp">{{"Not set" | translate}}</span>
                        </ng-container>
                    </div>
                    <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                        <mat-select [disableOptionCentering]="true" selectDir #cliPartnerContainer [(ngModel)]="item.partnerClients" [multiple]="true">
                            <mat-option>
                                <ngx-mat-select-search 
                                placeholderLabel="{{ 'Search' | translate }}" 
                                noEntriesFoundLabel="{{ 'no matching partner found' | translate }}"
                                [formControl]="partnerMoreControl"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let partner of partners$ | async" [value]="{client_partner_company_id: partner.partner_company_id, is_principal: 1}">
                                <app-user [class]="'user_div'" [companyEmployee]="partner.partnerCompany" [imgRoute]="imgRoute" [host]="host" [company]="data?.company"></app-user>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <!-- single scen -->
                <div class="column report_col users_col">
                    <div class="multi_users_cr_jobs" (click)="templatesIdContainer.open()">
                        <ng-container *ngIf="item.templates && item.templates.length">
                            <ng-container *ngFor="let id of item.templates">
                                <div class="d-f-c has_pad">
                                    <div class="text_one_line">{{ getTemplateById(id)?.name }}</div>
                                </div>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="!(item.templates && item.templates.length)">
                            <span class="val cp">{{"Not set" | translate}}</span>
                        </ng-container>
                    </div>
                    <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                        <mat-select [multiple]="true" #templatesIdContainer [(ngModel)]="item.templates">
                            <mat-option>
                                <ngx-mat-select-search 
                                placeholderLabel="{{ 'Search' | translate }}" 
                                noEntriesFoundLabel="{{ 'no matching template found' | translate }}"
                                [formControl]="templatesMoreControl"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let template of templates$ | async" [value]="template.id">
                                {{template.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="column report_col users_col">
                    <div class="multi_users_cr_jobs" (click)="tagsContainer.open()">
                        <ng-container *ngIf="item.tags && item.tags.length">
                            <ng-container *ngFor="let tag of item.tags">
                                <div class="d-f-c has_pad">
                                    <div class="text_one_line">{{ getValuesById(tag.parameter_value_id)?.value }}</div>
                                    <mat-icon style="margin-left: 3.5px; width: 10px; height: 10px; line-height: 10px; font-size: 10px;" 
                                    #tooltip="matTooltip"
                                    matTooltip="{{'Primary granted' | translate}}"
                                    [matTooltipPosition]="'above'" 
                                    (click)="$event.preventDefault(); $event.stopPropagation(); tag.is_primary = 0;" 
                                    *ngIf="!!tag?.is_primary">thumb_up</mat-icon>

                                    <mat-icon 
                                    style="margin-left: 3.5px; width: 10px; height: 10px; line-height: 10px; font-size: 10px;" 
                                    #tooltip="matTooltip"
                                    matTooltip="{{'Primary not granted' | translate}}"
                                    [matTooltipPosition]="'above'" 
                                    (click)="$event.preventDefault(); $event.stopPropagation(); tag.is_primary = 1;" 
                                    *ngIf="!tag?.is_primary">thumb_up_off_alt</mat-icon>
                                </div>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="!(item.tags && item.tags.length)">
                            <span class="val cp">{{"Not set" | translate}}</span>
                        </ng-container>
                    </div>
                    <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                        <!-- <mat-label>{{ "Tag(s)" | translate }}</mat-label> -->
                        <mat-select [disableOptionCentering]="true" selectDir #tagsContainer [multiple]="true" [(ngModel)]="item.tags">
                            <mat-option>
                                <ngx-mat-select-search 
                                placeholderLabel="{{ 'Search' | translate }}" 
                                noEntriesFoundLabel="{{ 'no matching tag found' | translate }}"
                                [formControl]="allValuesControl"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let tag of allValues$ | async" [value]="{parameter_value_id: tag.id, parameter_id: tag.parameter_id, is_primary: 1}">
                                <div class="d-f-c" style="justify-content: space-between;">
                                    <span>{{ tag.value }}</span>
                                    <span>{{ tag?.parameter?.name }}</span>
                                </div>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="column report_col deadline">
                    <div class="show_on_hover delete_job" (click)="deleteJob(i)">
                        <mat-icon>cancel</mat-icon>
                    </div>
                    <div class="date_and_time_c_w custom">
                        <mat-form-field appearance="standard" class="date_picker_cst">
                            <div class="prefix" matPrefix style="margin-right: 10px;">{{ "Date" | translate }}</div>
                            <input [(ngModel)]="item.date.day" matInput [matDatepicker]="pickerDay" placeholder="MM / DD/ YYYY">
                            <mat-datepicker-toggle matSuffix [for]="pickerDay"></mat-datepicker-toggle>
                            <mat-datepicker #pickerDay></mat-datepicker>
                        </mat-form-field>
                        
                        <div class="ngx-timepicker-field-example">
                            <div class="prefix">{{ "Time" | translate }}</div>
                            <ngx-timepicker-field [format]="24" [defaultTime]="item.date.time" [(ngModel)]="item.date.time"></ngx-timepicker-field>
                        </div>
                    </div>
                </div>
            </div>
            <div class="param_values report_sub_lines" *ngIf="item.isOpen">
                <pre>
                    {{item | json}}
                </pre>
            </div>
        </div>
    </div>
</div>
<div class="btns_line">
    <button mat-button (click)="close()">
        {{ "Close" | translate }}
    </button>
    <button *ngIf="!data.jobs" class="ml-1" mat-raised-button color="warn" (click)="addJob()">
        {{ "Add job" | translate }}
    </button>
    <button class="ml-1" mat-raised-button color="primary" (click)="onSubmit()">
        {{ "Create" | translate }}
    </button>
</div>