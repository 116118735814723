import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { LanguageService } from '../../services/common/language.service';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { NgxMatDateAdapter } from '@angular-material-components/datetime-picker';
import { MY_FORMATS, MY_NEW_FORMATS } from 'src/app/components/workspace-pages/cases/dialogs/open-task/dialogs/add-task-profile/add-task-profile.component';
import { CompanyService } from '../../services/rest/company.service';
import { ReplaySubject, Subject, forkJoin } from 'rxjs';
import { last, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView, collapseAnimation } from 'angular-calendar';
import { endOfDay, endOfMonth, endOfWeek, isSameDay, isSameMonth, startOfDay, startOfMonth, startOfWeek } from 'date-fns';
import { StorageManagerService } from '../../services/common/storage-manager.service';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import * as moment from 'moment';
import { CalendarDatePipe } from 'angular-calendar/modules/common/calendar-date.pipe';
import { EditDayComponent } from './dialogs/edit-day/edit-day.component';
import { UserScheduleComponent } from '../user-schedule/user-schedule.component';

@Component({
  selector: 'app-user-calendar',
  templateUrl: './user-calendar.component.html',
  styleUrls: ['./user-calendar.component.scss'],
  animations: [collapseAnimation],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: false } },
  ]
})
export class UserCalendarComponent extends BaseClass implements OnInit, OnDestroy {
  public activeLang: any;
  public schedules: any[] = [];
  public employee: any;
  CalendarView: any = {
    Month: "month",
    Week: "week",
    Day: "day"
  }
  public view: FormControl = new FormControl(!!this.sm.localStorageGetItem('user_calendar_view') ? this.sm.localStorageGetItem('user_calendar_view') : this.CalendarView.Month);
  refreshCalendar = new Subject<void>();
  activeDayIsOpen: boolean = false;
  public filter: FormGroup = this.fb.group({
    employee_id: '',
    from_day: '',
    to_day: ''
  });
  viewDate: Date = new Date();
  public colors: Record<string, any> = {
    1: {
      type: "Waiting",
      primary: '#2F80ED',
      secondary: '#BCD7FD',
    },
    2: {
      type: "Draft",
      primary: '#DB0000',
      secondary: '#FFAEAE',
    },
    3: {
      type: "Scheduled",
      primary: '#BEC11B',
      secondary: '#FEFFB9',
    },
    4: {
      type: "Published",
      primary: '#219653',
      secondary: '#98F1BD',
    },
    5: {
      type: "Canceled",
      primary: '#686868',
      secondary: '#E0E0E0',
    },
    6: {
      type: "content",
      primary: '#DD5E89',
      secondary: '#F7BB97',
    }
  };

  actions: CalendarEventAction[] = [
    {
      label: '<i class="fas fa-fw fa-pencil-alt"></i>',
      a11yLabel: 'Edit',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent(null, 'Edited', event);
      },
    },
    {
      label: '<i class="fas fa-fw fa-trash-alt"></i>',
      a11yLabel: 'Delete',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.schedules = this.schedules.filter((iEvent) => iEvent !== event);
        this.handleEvent(null, 'Deleted', event);
      },
    },
  ];

  constructor(
    private taskService: TaskService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<UserCalendarComponent>,
    private fb: FormBuilder,
    private languageService: LanguageService,
    private layoutService: LayoutService,
    private companyService: CompanyService,
    private dialog: MatDialog,
    private sm: StorageManagerService,
    private _ngx_adapter: NgxMatDateAdapter<any>,
    private _adapter: DateAdapter<any>,
  ) { super() }

  ngOnInit(): void {
    console.log('UserCalendarComponent', this.data)
    console.log('moment', moment().startOf('day'))
    console.log('moment.utc()', moment().utc().startOf('day'))

    if (this.data.employee) {
      this.employee = this.data.employee;
    } else {
      this.employee = this.data.company.employees[0];
    }

    this.getLangData();

    this.filter.patchValue({
      employee_id: this.employee.id,
    })
    
    this.onRouteChange();

    const getStart: any = {
      month: startOfMonth,
      week: startOfWeek,
      day: startOfDay,
    }[this.view.value];

    const getEnd: any = {
      month: endOfMonth,
      week: endOfWeek,
      day: endOfDay,
    }[this.view.value];

    if (this.view.value == "week") {
      this.filter.patchValue({
        from_day : moment(moment(startOfWeek(this.viewDate, {weekStartsOn: 1})).unix() * 1000),
        to_day : moment(moment(endOfWeek(this.viewDate, {weekStartsOn: 1})).unix() * 1000),
      })
    } else {
      this.filter.patchValue({
        from_day : moment(moment(getStart(this.viewDate)).unix() * 1000),
        to_day : moment(moment(getEnd(this.viewDate)).unix() * 1000),
      })
    }

    this.attachSubscriptions(
      this.view.valueChanges.subscribe((resp) => {
        console.log("url_view", resp);
        this.sm.localStorageSetItem("user_calendar_view", resp);
        const getStart: any = {
          month: startOfMonth,
          week: startOfWeek,
          day: startOfDay,
        }[this.view.value];
    
        const getEnd: any = {
          month: endOfMonth,
          week: endOfWeek,
          day: endOfDay,
        }[this.view.value];

        if (this.view.value == "week") {
          this.filter.patchValue({
            from_day : moment(moment(startOfWeek(this.viewDate, {weekStartsOn: 1})).unix() * 1000),
            to_day : moment(moment(endOfWeek(this.viewDate, {weekStartsOn: 1})).unix() * 1000),
          })
        } else {
          this.filter.patchValue({
            from_day : moment(moment(getStart(this.viewDate)).unix() * 1000),
            to_day : moment(moment(getEnd(this.viewDate)).unix() * 1000),
          })
        }
      })
    )
  }

  scheduleSettings() {
    const dialogRef = this.dialog.open(UserScheduleComponent, {
      backdropClass: ['back_user_schedule'],
      panelClass: ['panel_user_schedule'],
      data: {
        company_id: this.data.company.id,
        company: this.data.company,
        imgRoute: this.data.imgRoute,
        host: this.data.host,
        employee: this.employee
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log("getONE BEFORE", result);
        this.schedules = [];
        this.getScheduleItem();
      })
    )
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
    const getStart: any = {
      month: startOfMonth,
      week: startOfWeek,
      day: startOfDay,
    }[this.view.value];

    const getEnd: any = {
      month: endOfMonth,
      week: endOfWeek,
      day: endOfDay,
    }[this.view.value];
    console.log("change viewDate", getStart(this.viewDate), getEnd(this.viewDate));
    
    if (this.view.value == "week") {
      this.filter.patchValue({
        from_day : moment(moment(startOfWeek(this.viewDate, {weekStartsOn: 1})).unix() * 1000),
        to_day : moment(moment(endOfWeek(this.viewDate, {weekStartsOn: 1})).unix() * 1000),
      })
    } else {
      this.filter.patchValue({
        from_day : moment(moment(getStart(this.viewDate)).unix() * 1000),
        to_day : moment(moment(getEnd(this.viewDate)).unix() * 1000),
      })
    }
      
    // moment
    console.log("change viewDate moment", moment(moment(getStart(this.viewDate)).unix() * 1000), moment(moment(getEnd(this.viewDate)).unix() * 1000));

    this.schedules = [];
    console.log(this.filter.value)
  }

  onRouteChange() {
    this.attachSubscriptions(
      this.filter.valueChanges.pipe(
        tap((val) => {
          // if (this.urlsSub) {
          //   this.urlsSub.unsubscribe()
          // }
        })
      ).subscribe(params => {
        console.log("route STREAM", params);
        this.schedules = [];
        this.getScheduleItem();
      })
    )
  }

  setView(view: MatButtonToggleChange) {
    this.view.patchValue(view.value);
  }

  dayClicked({ date, events }: { date: Date; events: any[] }): void {
    console.log(events);
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  editDay(event, day) {
    event.preventDefault();
    event.stopPropagation();
    const dialogRef = this.dialog.open(EditDayComponent, {
      backdropClass: ['back_user_schedule'],
      panelClass: ['panel_user_schedule'],
      data: {
        day: day,
        employee: this.employee,
        company_id: this.data.company.id,
        company: this.data.company,
        imgRoute: this.data.imgRoute,
        host: this.data.host
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log("getONE BEFORE", result);
        this.getScheduleItem()
      })
    )
  }

  handleEvent(e, action: string, event: any): void {
    // this.modalData = { event, action };
    console.log("event", event);

    console.log('e', e)
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    this.schedules = this.schedules.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
    this.handleEvent(null, 'Dropped or resized', event);
  }

  // save(item) {
  //   console.log("save", item);
  //   console.log("save from", this.timeToTimestamp(item.working.from));
  //   console.log("save to", this.timeToTimestamp(item.working.to));
    
  //   if (item.working.from == 0 && item.working.to == 0) {
  //     return;
  //   }

  //   if (!!item.is_load) {
  //     return;
  //   }

  //   // item.is_load = true;

  //   if (!!item.for_all) {
  //     let multiDeleteData = [];
  //     let multiAddData = [];

  //     this.days.forEach(d => {
  //       if (!d.is_off) {
  //         d.data.forEach(x => {
  //           multiDeleteData.push({
  //             "path": `/api/employee-schedule/${x.id}/`,
  //             "query": {company_id: this.data.company.id},
  //             "method": "DELETE",
  //             "body": {}
  //           })
  //         });

  //         multiAddData.push({
  //           "path": `/api/employee-schedule/`,
  //           "query": {company_id: this.data.company.id},
  //           "method": "POST",
  //           "body": {
  //             [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
  //             company_id: this.data.company.id,
  //             employee_id: this.employee.id,
  //             day: d.day,
  //             from_day: this.timeToTimestamp(item.working.from),
  //             to_day: this.timeToTimestamp(item.working.to)
  //           }
  //         })
  //       }
  //     })

  //     this.attachSubscriptions(
  //       this.taskService.multiRequest(multiDeleteData).pipe(
  //         switchMap(res => {
  //           return this.taskService.multiRequest(multiAddData)
  //         })
  //       ).subscribe(resp => {
  //         console.log(resp)
  //         this.getSchedule({employee_id: this.employee.id});
  //       })
  //     )

  //   } else {
  //     if (!item.data || !item.data.length) {

  //       let multiAddData = [];
  //       if (item.breaks.filter(x => x.from != 0 && x.to != 0 && this.timeToTimestamp(x.from) > this.timeToTimestamp(item.working.from) && this.timeToTimestamp(x.to) < this.timeToTimestamp(item.working.to)).length > 0) {
  //         let work = {
  //           from: this.timeToTimestamp(item.working.from),
  //           to: this.timeToTimestamp(item.working.to)
  //         };
  //         let pairs = [];
  //         let cstPairs = [...item.breaks.filter(x => x.from != 0 && x.to != 0 && this.timeToTimestamp(x.from) > this.timeToTimestamp(item.working.from) && this.timeToTimestamp(x.to) < this.timeToTimestamp(item.working.to))]
  //         cstPairs.forEach(x => pairs.push(JSON.parse(JSON.stringify(x))));
  //         pairs.forEach(x => {
  //           x.from = this.timeToTimestamp(x.from);
  //           x.to = this.timeToTimestamp(x.to);
  //         });

  //         pairs.sort((a,b) => {
  //           return a.from - b.from;
  //         })

  //         console.log("work", work)

  //         let workingIntevals = [];
  //         pairs.forEach((p,ind) => {
  //           console.log(ind, p)
  //           if (ind != 0) {
  //             if (p.from > pairs[ind-1].to) {
  //               return
  //             } else {
  //               pairs[ind-1].to = p.to;
  //               pairs.splice(ind, 1)
  //             }
  //           }            
  //         });
          
  //         console.log("pairs", pairs)

  //         pairs.forEach((p,ind) => {
  //           if (ind == 0) {
  //             workingIntevals.push({
  //               from: work.from,
  //               to: p.from
  //             })
  //           } else {
  //             workingIntevals.push({
  //               from: pairs[ind-1].to,
  //               to: p.from
  //             })
  //           }

  //           if (ind == pairs.length - 1) {
  //             workingIntevals.push({
  //               from: p.to,
  //               to: work.to
  //             })
  //           }
  //         })

  //         workingIntevals.forEach(wi => {
  //           multiAddData.push({
  //             "path": `/api/employee-schedule/`,
  //             "query": {company_id: this.data.company.id},
  //             "method": "POST",
  //             "body": {
  //               [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
  //               company_id: this.data.company.id,
  //               employee_id: this.employee.id,
  //               day: item.day,
  //               from_day: wi.from,
  //               to_day: wi.to
  //             }
  //           })
  //         })

  //       } else {
  //         multiAddData.push({
  //           "path": `/api/employee-schedule/`,
  //           "query": {company_id: this.data.company.id},
  //           "method": "POST",
  //           "body": {
  //             [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
  //             company_id: this.data.company.id,
  //             employee_id: this.employee.id,
  //             day: item.day,
  //             from_day: this.timeToTimestamp(item.working.from),
  //             to_day: this.timeToTimestamp(item.working.to)
  //           }
  //         })
  //       }

  //       this.attachSubscriptions(
  //         this.taskService.multiRequest(multiAddData).subscribe(resp => {
  //           console.log(resp)
  //           this.getSchedule({employee_id: this.employee.id});
  //         })
  //       )
  //     } else {
  //       let multiDeleteData = [];
  //       let multiAddData = [];
  
  //       item.data.forEach(item => {
  //         multiDeleteData.push({
  //           "path": `/api/employee-schedule/${item.id}/`,
  //           "query": {company_id: this.data.company.id},
  //           "method": "DELETE",
  //           "body": {}
  //         })
  //       });

  //       if (item.breaks.filter(x => x.from != 0 && x.to != 0 && this.timeToTimestamp(x.from) > this.timeToTimestamp(item.working.from) && this.timeToTimestamp(x.to) < this.timeToTimestamp(item.working.to)).length > 0) {
  //         let work = {
  //           from: this.timeToTimestamp(item.working.from),
  //           to: this.timeToTimestamp(item.working.to)
  //         };
  //         let pairs = [];
  //         let cstPairs = [...item.breaks.filter(x => x.from != 0 && x.to != 0 && this.timeToTimestamp(x.from) > this.timeToTimestamp(item.working.from) && this.timeToTimestamp(x.to) < this.timeToTimestamp(item.working.to))]
  //         cstPairs.forEach(x => pairs.push(JSON.parse(JSON.stringify(x))));
  //         pairs.forEach(x => {
  //           x.from = this.timeToTimestamp(x.from);
  //           x.to = this.timeToTimestamp(x.to);
  //         });

  //         pairs.sort((a,b) => {
  //           return a.from - b.from;
  //         })

  //         console.log("work", work)

  //         let workingIntevals = [];
  //         pairs.forEach((p,ind) => {
  //           console.log(ind, p)
  //           if (ind != 0) {
  //             if (p.from > pairs[ind-1].to) {
  //               return
  //             } else {
  //               pairs[ind-1].to = p.to;
  //               pairs.splice(ind, 1)
  //             }
  //           }            
  //         });
          
  //         console.log("pairs", pairs)

  //         pairs.forEach((p,ind) => {
  //           if (ind == 0) {
  //             workingIntevals.push({
  //               from: work.from,
  //               to: p.from
  //             })
  //           } else {
  //             workingIntevals.push({
  //               from: pairs[ind-1].to,
  //               to: p.from
  //             })
  //           }

  //           if (ind == pairs.length - 1) {
  //             workingIntevals.push({
  //               from: p.to,
  //               to: work.to
  //             })
  //           }
  //         })

  //         workingIntevals.forEach(wi => {
  //           multiAddData.push({
  //             "path": `/api/employee-schedule/`,
  //             "query": {company_id: this.data.company.id},
  //             "method": "POST",
  //             "body": {
  //               [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
  //               company_id: this.data.company.id,
  //               employee_id: this.employee.id,
  //               day: item.day,
  //               from_day: wi.from,
  //               to_day: wi.to
  //             }
  //           })
  //         })

  //       } else {
  //         multiAddData.push({
  //           "path": `/api/employee-schedule/`,
  //           "query": {company_id: this.data.company.id},
  //           "method": "POST",
  //           "body": {
  //             [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
  //             company_id: this.data.company.id,
  //             employee_id: this.employee.id,
  //             day: item.day,
  //             from_day: this.timeToTimestamp(item.working.from),
  //             to_day: this.timeToTimestamp(item.working.to)
  //           }
  //         })
  //       }

  
  //       this.attachSubscriptions(
  //         this.taskService.multiRequest(multiDeleteData).pipe(
  //           switchMap(res => {
  //             return this.taskService.multiRequest(multiAddData)
  //           })
  //         ).subscribe(resp => {
  //           console.log(resp)
  //           this.getSchedule({employee_id: this.employee.id});
  //         })
  //       )
  //     }
  //   }
  // }

  // dayOff(e, item) {
  //   console.log(e);
  //   console.log(item);
  //   if (item.is_load == true) {
  //     return;
  //   }

  //   item.is_load = true;
  //   if (e.checked) {
  //     if (!item.data || !item.data.length) {
  //       this.attachSubscriptions(
  //         this.companyService.addSchedule({
  //           company_id: this.data.company.id,
  //           employee_id: this.employee.id,
  //           day: item.day,
  //           from_day: 0,
  //           to_day: 0
  //         }).subscribe(resp => {
  //           console.log("addSchedule", resp);
  //           this.getSchedule({employee_id: this.employee.id});
  //         })
  //       )
  //     } else {
  //       let multiDeleteData = [];
  
  //       item.data.forEach(item => {
  //         multiDeleteData.push({
  //           "path": `/api/employee-schedule/${item.id}/`,
  //           "query": {company_id: this.data.company.id},
  //           "method": "DELETE",
  //           "body": {}
  //         })
  //       });
  
  //       this.attachSubscriptions(
  //         this.taskService.multiRequest(multiDeleteData).pipe(
  //           switchMap(res => {
  //             return this.companyService.addSchedule({
  //               company_id: this.data.company.id,
  //               employee_id: this.employee.id,
  //               day: item.day,
  //               from_day: 0,
  //               to_day: 0
  //             })
  //           })
  //         ).subscribe(resp => {
  //           console.log(resp)
  //           this.getSchedule({employee_id: this.employee.id});
  //         })
  //       )
  //     }
  //   } else {
  //     if (!!item.data && !!item.data.length) {
  //       let multiDeleteData = [];
  
  //       item.data.forEach(item => {
  //         multiDeleteData.push({
  //           "path": `/api/employee-schedule/${item.id}/`,
  //           "query": {company_id: this.data.company.id},
  //           "method": "DELETE",
  //           "body": {}
  //         })
  //       });
  
  //       this.attachSubscriptions(
  //         this.taskService.multiRequest(multiDeleteData).subscribe(resp => {
  //           console.log(resp)
  //           this.getSchedule({employee_id: this.employee.id});
  //         })
  //       )
  //     }
  //   }

  // }

  // addBreak(item) {
  //   console.log("addBreak", item)
  //   item.breaks.push({from: 0, to: 0})
  // }

  // log() {
  // }

  timeToTimestamp(value) {
    // Разбиваем строку на часы, минуты и AM/PM
    const parts = value.split(' ');
    const time = parts[0];
    const period = parts[1];
  
    // Разбиваем время на часы и минуты
    const timeParts = time.split(':');
    let hours = parseInt(timeParts[0]);
    let minutes = parseInt(timeParts[1]);
  
    // Если период - PM и часы не равны 12, добавляем 12 часов
    if (period === 'PM' && hours !== 12) {
      hours += 12;
    }
  
    // Если период - AM и часы равны 12, устанавливаем их в 0
    if (period === 'AM' && hours === 12) {
      hours = 0;
    }
  
    // Вычисляем общее количество секунд
    const totalSeconds = (hours * 60 + minutes) * 60;
  
    return totalSeconds;
  }

  timestampToTime(value) {
    let newValue = "";
    let period = value >= 43200 ? "PM" : "AM";
  
    if (value > 43200) {
      value -= 43200;
    }
  
    if (Math.floor(value / 3600) < 10) {
      newValue = "0" + Math.floor(value / 3600) + ":";
    } else {
      newValue = Math.floor(value / 3600) + ":";
    }
    value %= 3600;
  
    if (value >= 60) {
      if (Math.floor(value / 60) < 10) {
        newValue = newValue + "0" + Math.floor(value / 60);
      } else {
        newValue = newValue + Math.floor(value / 60);
      }
      value %= 60;
    } else {
      newValue += '00';
    }
  
    return newValue + " " + period;
  }
  
  getScheduleItem() {
    this.attachSubscriptions(
      this.companyService.getScheduleItem(this.data.company.id, '1', '1', this.filter.value).pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.companyService.getScheduleItem(this.data.company.id, x, '200', this.filter.value).pipe(map(u => u.body)))).pipe(
            last(),
            tap((arrValues:any) => {
              let values = arrValues.flat()
              console.log("getScheduleItem arrValues", arrValues)
              console.log("getScheduleItem values", values)
              values.map(val => {
                val.start = new Date((val.from_time) * 1000);
                val.end = new Date((val.to_time) * 1000);
                val.title = val.name || marker("Without name");
                val.color = this.colors[6];
                val.actions = this.actions;
                // val.allDay = true;
                val.cssClass = 'publish_url';
            
                val.meta = {
                  type:'content',
                  filterBy: 'employee'
                }
              });
              this.schedules = [].concat(...values)
              this.refreshCalendar.next()
            }),
          )
        }),
      ).subscribe(resp => {
        console.log("getScheduleItem", this.schedules)
      })
    )
  }

  itemAll(e, item) {
    item.for_all = e.checked;
  }

  // generateModel() {
  //   if (!this.schedules || this.schedules.length == 0) {
  //     return
  //   }

  //   this.days.forEach(d => {
  //     d.working.from = 0;
  //     d.working.to = 0;
  //     d.for_all = false;
  //     d.data = this.schedules.filter(x => x.day == d.day);
  //     if (d.data && d.data.length) {
  //       if (d.data.length == 1 && d.data[0].from_day == 0 && d.data[0].to_day == 0) {
  //         d.breaks = [{from:0, to:0}];
  //         d.is_off = true;
  //       } else {
  //         d.is_off = false;
  //         d.data.sort((a,b) => {
  //           return a.from_day - b.from_day;
  //         })
  //         if (d.data.length == 1) {
  //           d.breaks = [{from:0, to:0}];
  //         } else {
  //           d.breaks = [];
  //         }
  //         d.data.forEach((el, ind) => {
  //           console.log("day-"+ d.day +" el-" + ind, el.to_day)
  //           if (d.working.from === 0 || this.timeToTimestamp(d.working.from) > el.from_day) {
  //             d.working.from = this.timestampToTime(el.from_day);
  //           }
  //           if (d.working.to === 0 || this.timeToTimestamp(d.working.to) < el.to_day) {
  //             console.log("day-"+ d.day +" check-" + ind, el.to_day)
  //             d.working.to = this.timestampToTime(el.to_day);
  //           }

  //           if (ind != 0) {
  //             d.breaks.push({from: this.timestampToTime(d.data[ind-1].to_day), to: this.timestampToTime(el.from_day)})
  //           }
  //         });
  //       }
  //     }
  //     d.is_load = false;
  //   })
  // }

  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData().subscribe(resp => {
        this._ngx_adapter.setLocale(resp.active);
        this._adapter.setLocale(resp.active);
        this.activeLang = resp.active;
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
