
import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { marker } from "@biesbjerg/ngx-translate-extract-marker";
import { forkJoin, of } from "rxjs";
import { map, switchMap, tap } from "rxjs/operators";
import { getLessStatuses } from "src/app/shared/functions/statusesLessModel";
import { BaseClass } from "src/app/shared/models/base-class";
import { LayoutService } from "src/app/shared/services/common/layout.service";
import { StorageManagerService } from "src/app/shared/services/common/storage-manager.service";
import { MembersService } from "src/app/shared/services/rest/members.service";
import { TaskService } from "src/app/shared/services/rest/task.service";
import { BackJobToPrevStatusComponent } from "../../../back-job-to-prev-status/back-job-to-prev-status.component";
import { SnackBarItem } from "../../../snack-bar/snack-bar-item";


@Component({
  selector: 'app-chat-statuses',
  templateUrl: './chat-statuses.component.html',
  styleUrls: ['./chat-statuses.component.scss']
})
export class ChatStatusesComponent extends BaseClass implements OnInit, OnDestroy {
  public operationStatuses: any;
  public target: any;
  public isFormChange: boolean = false;
  public showEm: boolean = false;

  constructor(
    private taskService: TaskService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ChatStatusesComponent>,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private bottomSheet: MatBottomSheet,
    private sm: StorageManagerService,
    private membersService: MembersService,
    private layoutService: LayoutService
  ) { super() }

  ngOnInit(): void {
    console.log("ChatStatusesComponent", this.data)
    this.getMembers()
  }

  getStatus(id) {
    if (!this.data.statuses) {
      return false;
    }
    return this.data.statuses.find(el => el.id == id)
  }  

  showError(error) {
    this.layoutService.showSnackBar({name: 'You do not have permission to do this.'}, `${error}`, SnackBarItem)
  }

  changeStatus(e, chatEm) {
    if (chatEm.is_partner) {
      if (!!chatEm.partnerCompanyStatus.id) {
        this.attachSubscriptions(
          this.membersService.editPartnerStatus(chatEm.partnerCompanyStatus.id, {status_id: e.value}, this.data.company.id).subscribe(resp => {
            this.getMembers()
          },
          error => {
            this.showError(error)
          })
        )
      } else {
        this.attachSubscriptions(
          this.membersService.addPartnerStatus(Object.assign({status_id: e.value}, {
              company_id: this.data.company.id,
              task_id: this.target.outputExecutors.targetIds.task_id,
              task_operation_id: this.target.outputExecutors.targetIds.task_operation_id,
              discussion_id: this.target.outputExecutors.targetIds.discussion_id,
              partner_company_id: chatEm.partner_company_id,
            }), this.data.company.id).subscribe(resp => {
              this.getMembers()
          },
          error => {
            this.showError(error)
          })
        )
      }
    } else if (chatEm.is_partner_employee) {
      if (!!chatEm.employeeStatus.id) {
        this.attachSubscriptions(
          this.membersService.editPartnerEmployeeStatus(chatEm.employeeStatus.id, {status_id: e.value}, this.data.company.id).subscribe(resp => {
            this.getMembers()
          },
          error => {
            this.showError(error)
          })
        )
      } else {
        this.attachSubscriptions(
          this.membersService.addPartnerEmployeeStatus(
            Object.assign({status_id: e.value}, {
              company_id: this.data.company.id,
              task_id: this.target.outputExecutors.targetIds.task_id,
              task_operation_id: this.target.outputExecutors.targetIds.task_operation_id,
              discussion_id: this.target.outputExecutors.targetIds.discussion_id,
              partner_company_id: chatEm.partner_company_id,
              partner_employee_id: chatEm.partner_employee_id
            }), this.data.company.id).subscribe(resp => {
              this.getMembers()
          },
          error => {
            this.showError(error)
          })
        )
      }
    } else {
      if (!!chatEm.employeeStatus.id) {
        this.attachSubscriptions(
          this.membersService.editTaskEmployeeStatus(chatEm.employeeStatus.id, {status_id: e.value}, this.data.company.id)
          .pipe(
            switchMap(val => {
              if ([3,4,6,97,98,99].includes(e.value)) {
                return this.membersService.getTaskEmployeeStatuses({task_operation_id: this.target.outputExecutors.targetIds.task_operation_id, status_id: getLessStatuses(e.value), employee_id: chatEm?.employee_id}, this.data.company.id, '2').pipe(
                  switchMap(res => {
                    console.log("RES3", res)
                    if (res.length == 1 && !res[0].discussion_id) {
                      console.log("RES4", val)
                      let questionAlert =  this.bottomSheet.open(BackJobToPrevStatusComponent, {
                        hasBackdrop: true,
                        backdropClass: 'bottom-sheed-backdrop',
                        data: {
                          msg: marker(`Do you really want to convert job personal status to: '${this.getOperationStatusName(e.value)}'?`)
                        }
                      });
                  
                      return questionAlert.afterDismissed()
                      .pipe(
                        switchMap(data => {
                          if (data && data.message == 'no') {
                            console.log("no");
                            return of(val)
                          } else if (data && data.message == 'yes') {
                            return this.membersService.editTaskEmployeeStatus(res[0].id, {status_id: e.value}, this.data.company.id).pipe(
                              map(() => val)
                            )
                          } else {
                            return of(val)
                          }
                        })
                      )
                    } else {
                      return of(val)
                    }
                  })
                )
              } else {
                return of(val)
              }
            }) 
          ).subscribe(resp => {
            this.getMembers()
          },
          error => {
            this.showError(error)
          })
        )
      } else {
        this.attachSubscriptions(
          this.membersService.addTaskEmployeeStatus(Object.assign({status_id: e.value}, {
              company_id: chatEm?.company_id,
              task_id: this.target.outputExecutors.targetIds.task_id,
              task_operation_id: this.target.outputExecutors.targetIds.task_operation_id,
              discussion_id: this.target.outputExecutors.targetIds.discussion_id,
              employee_id: chatEm?.employee_id,
            }), this.data.company.id)
            .pipe(
              switchMap(val => {
                if ([3,4,6,97,98,99].includes(e.value)) {
                  return this.membersService.getTaskEmployeeStatuses({task_operation_id: this.target.outputExecutors.targetIds.task_operation_id, status_id: getLessStatuses(e.value), employee_id: chatEm?.employee_id}, this.data.company.id, '2').pipe(
                    switchMap(res => {
                      console.log("RES3", res)
                      if (res.length == 1 && !res[0].discussion_id) {
                        console.log("RES4", val)
                        let questionAlert =  this.bottomSheet.open(BackJobToPrevStatusComponent, {
                          hasBackdrop: true,
                          backdropClass: 'bottom-sheed-backdrop',
                          data: {
                            msg: marker(`Do you really want to convert job personal status to: '${this.getOperationStatusName(e.value)}'?`)
                          }
                        });
                    
                        return questionAlert.afterDismissed()
                        .pipe(
                          switchMap(data => {
                            if (data && data.message == 'no') {
                              console.log("no");
                              return of(val)
                            } else if (data && data.message == 'yes') {
                              return this.membersService.editTaskEmployeeStatus(res[0].id, {status_id: e.value}, this.data.company.id).pipe(
                                map(() => val)
                              )
                            } else {
                              return of(val)
                            }
                          })
                        )
                      } else {
                        return of(val)
                      }
                    })
                  )
                } else {
                  return of(val)
                }
              }) 
            ).subscribe(resp => {
              this.getMembers();
          },
          error => {
            this.showError(error);
          })
        )
      }
    }
  }

  
  getOperationStatusName(id) {
    switch (+id) {
      case 1:
        return "Waiting";
        break;
      case 2:
        return "In process";
        break;
      case 3:
        return "To approve";
        break;
      case 4:
        return "Approved";
        break;
      case 5:
        return "To correct";
        break;
      case 6:
        return "Sources Needed";
        break;
      case 97:
        return "On hold";
        break;
      case 98:
        return "Ready";
        break;
      case 99:
        return "Canceled";
        break;
    }
  }

  getMembers() {
    this.target = {
      outputExecutors: {
        targetIds: {
          task_id: this.data.chat.task_id,
          task_operation_id: this.data.chat.task_operation_id,
          discussion_id: this.data.chat.id,
        },
        employees: [],
        partnerCompanies: [],
        clients: [],
        is_load: true,
      }
    }
    // let filter = {task_id: this.target.outputExecutors.targetIds.task_id, task_operation_id: this.target.outputExecutors.targetIds.task_operation_id}
    let filter = this.target.outputExecutors.targetIds;
    // if (this.data.chat) {
    //   filter['discussion_id'] = this.data.chat.id
    // }

    let arr = [
      this.membersService.getMembers(this.data.company.id != this.data.chat.company_id ? Object.assign({partner_company_id: this.data.chat.company_id}, filter) : filter, this.data.company.id).pipe(
        tap(res => {
          console.log("getMembers", res)
          res.forEach(el => {
            if (this.target.outputExecutors.employees.filter(u => u.employee_id == el.employee_id).length == 0) {
              this.target.outputExecutors.employees.push(el);
            }
          })
        })
      ),
      this.membersService.getTaskPartners(this.data.company.id != this.data.chat.company_id ? Object.assign({partner_company_id: this.data.chat.company_id}, filter) : filter, this.data.company.id).pipe(
        tap(res => {
          console.log("getTaskPartners", res)
          res.forEach(el => {
            el.is_partner = true;
            if (this.target.outputExecutors.partnerCompanies.filter(u => u.partner_company_id == el.partner_company_id).length == 0) {
              this.target.outputExecutors.partnerCompanies.push(el);
            }
          })
        })
      )
    ]
  
    if (this.data.company.id != this.data.chat.company_id) {
      arr.push(
        this.membersService.getTaskPartnerEmployees(filter, this.data.company.id, this.data.chat.company_id).pipe(
          tap(res => {
            console.log('getTaskPartnerEmployees', res)
            console.log('this.target.outputExecutors.employees', this.target.outputExecutors.employees)
            res.forEach(el => {
              el.is_partner_employee = true;
              // if ((el.discussion_id == 0 || !el.discussion_id) && el.partnerEmployeeStatus && this.target.outputExecutors.employees.find(x => x.id == el.id)) {
              //   this.target.outputExecutors.employees.find(x => x.id == el.id).employeeStatus = el.partnerEmployeeStatus
              // }
              if (this.target.outputExecutors.employees.filter(u => u.partner_company_id == el.partner_company_id).length == 0) {
                this.target.outputExecutors.employees.push(el);
              }
            })
          })
        )
      )
    }
    this.attachSubscriptions(
      forkJoin(arr).subscribe(resp => {
        console.log("getMembers", resp)
        this.target.outputExecutors.is_load = false;
        console.log("getMembers", this.target.outputExecutors)
      })
    )
  }

  jobHasUserExec() {
    return this.target.outputExecutors.employees.filter(jobEmpl => !jobEmpl.is_partner && !jobEmpl.is_partner_employee && (jobEmpl.discussion_id == 0 || !jobEmpl.discussion_id)).length
  }
  
  jobHasChatExec() {
    return this.target.outputExecutors.employees.filter(jobEmpl => !jobEmpl.is_partner && !jobEmpl.is_partner_employee && !(jobEmpl.discussion_id == 0 || !jobEmpl.discussion_id)).length
  }

  jobHasChatPartn() {
    return this.target.outputExecutors.partnerCompanies.filter(jobEmpl => !(jobEmpl.discussion_id == 0 || !jobEmpl.discussion_id)).length
  }

  jobHasNotChatPartn() {
    return this.target.outputExecutors.partnerCompanies.filter(jobEmpl => (jobEmpl.discussion_id == 0 || !jobEmpl.discussion_id)).length
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

}
