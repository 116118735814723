import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { debounceTime, map, switchMap } from 'rxjs/operators';
import { BaseClass } from 'src/app/shared/models/base-class';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { RefreshService } from 'src/app/shared/services/rest/refresh.service';
import { RootService } from 'src/app/shared/services/rest/root.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';

@Component({
  selector: 'app-save-filter-to-bookmark',
  templateUrl: './save-filter-to-bookmark.component.html',
  styleUrls: ['./save-filter-to-bookmark.component.scss']
})

export class SaveFilterToBookmarkComponent extends BaseClass implements OnInit, OnDestroy {
  public activeParams: any;
  public sortValue: any;
  public isSubmit: boolean = false;
  public filters: boolean = false;
  public submited: boolean = false;
  public activeIndex: number = 0;
  public switched: boolean = false;

  public is_active: FormControl = new FormControl(false);
  public is_shortcut: FormControl = new FormControl(false);
  public employeeMoreControl: FormControl = new FormControl();
  public employees$: ReplaySubject<any> = new ReplaySubject<any>(1);

  // employee_id: [[this.data.company.employees[0].id]],
  public form: FormGroup = this.fb.group({
    company_id: this.data.company.id,
    employee_id: [[this.data.company.employees[0].id]],
    section: this.activeSections() == 'Posts' ? 'Post List' : this.activeSections(),
    type: !!this.data.type ? this.data.type : '',
    settings: ['', Validators.required],
    order: 0,
    name: ['', Validators.required]
  })
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private activatedRoute: ActivatedRoute,
    private companyService: CompanyService,
    private refreshService: RefreshService,
    private taskService: TaskService,
    private rootService: RootService,
    private sm: StorageManagerService,
    public dialogRef: MatDialogRef<SaveFilterToBookmarkComponent>,
    private fb: FormBuilder,
  ) { super() }

  ngOnInit(): void {
    this.dialogRef.addPanelClass("create_task_by_manager_modal")
    console.log("SaveFilterToBookmarkComponent", this.data)

    if (this.data.employees) {
      this.data.employees.map(x => {
        x.is_active = 1;
        x.is_shortcut = 0;
      })
      this.employees$.next(this.data.employees.slice())
    }

    this.attachSubscriptions(
      this.employeeMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchEmoloyees(resp))
    )
    
    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        this.close();
      })
    )

    this.activeParams = JSON.parse(JSON.stringify(this.data.params))
    delete this.activeParams.company_id;
    delete this.activeParams.count;

    switch (this.activeSections()) {
      case 'Cards':
        this.sortValue = this.activatedRoute.snapshot.queryParamMap.get('order_by') ? this.activatedRoute.snapshot.queryParamMap.get('order_by') : 'updated_desc'
        break;
      case 'Jobs':
        this.sortValue = this.activatedRoute.snapshot.queryParamMap.get('order_by') ? this.activatedRoute.snapshot.queryParamMap.get('order_by') : 'updated_desc'
        if (!!this.sm.localStorageGetItem('jobsView') && !!this.sm.localStorageGetItem('card_jobs') && +this.sm.localStorageGetItem('card_jobs') == 2) {
          this.activeParams.viewArch = JSON.parse(this.sm.localStorageGetItem('jobsView'));
        }
        break;
      case 'Chats':
        this.sortValue = this.activatedRoute.snapshot.queryParamMap.get('order_by') ? this.activatedRoute.snapshot.queryParamMap.get('order_by') : 'updated_desc'
        break;
      case 'Posts':
        this.sortValue = this.activatedRoute.snapshot.queryParamMap.get('order_by') ? this.activatedRoute.snapshot.queryParamMap.get('order_by') : 'content_published_at_desc'
        break;
      case 'Projects':
        this.sortValue = this.activatedRoute.snapshot.queryParamMap.get('order_by') ? this.activatedRoute.snapshot.queryParamMap.get('order_by') : 'id_desc'
        if (!!this.sm.localStorageGetItem('projectsView')) {
          this.activeParams.viewArch = JSON.parse(this.sm.localStorageGetItem('projectsView'));
        }
        break;
    
      default:
        break;
    }
    if (!!this.sortValue && !['updated_desc', 'content_published_at_desc'].includes(this.sortValue)) {
      this.activeParams.order_by = this.sortValue
    }
   

    if (['Board by Date', 'Board by Order'].includes(this.activeSections())) {
      if (!this.activeParams.status_id || this.activeParams.status_id.split(',').sort().toString() == [1,2,5].toString()) {
        delete this.activeParams.status_id;
      }
      if (!this.activeParams.doneStatus || this.activeParams.doneStatus.split(',').sort().toString() == [3,4,6,97,98,99].toString()) {
        delete this.activeParams.doneStatus;
      }
    } else if (['Jobs', 'Chats'].includes(this.activeSections())) {
      if (!this.activeParams.status_id) {
        delete this.activeParams.status_id;
      }
    } else {
      delete this.activeParams.status_id;
      delete this.activeParams.doneStatus;
    }

    console.log("this.activeParams", this.activeParams)
  }

  log() {
    console.log("form.value", this.form.value)
  }

  editEmployeeBookmark(employee, key) {
    employee[key] = employee[key] == 1 ? 0 : 1
  }
  
  toggleChip = (chip: any) => {
    this.switched = true;
    if (!!this.form.value.employee_id && this.form.value.employee_id.includes(!!chip.id ? chip.id : chip.value)) {
      let val = [...this.form.value.employee_id]
      val.splice(this.form.value.employee_id.indexOf(!!chip.id ? chip.id : chip.value), 1)
      this.form.patchValue({
        employee_id: val
      })
    } else {
      if (!!this.form.value.employee_id) {
        let val = [...this.form.value.employee_id]
        val.push(!!chip.id ? chip.id : chip.value)
        this.form.patchValue({
          employee_id: val
        })
      } else {
        this.form.patchValue({
          employee_id: [!!chip.id ? chip.id : chip.value]
        })
      }
    }

    this.switched = false
  }

  // toggleChip(chip: any) {
  //   console.log(chip);
  //   console.log(this.form.value.employee_id);
  //   let arr = [];
  //   if (this.form.value.employee_id == 0) {
  //     arr.push(chip.id)
  //   } else if (this.form.value.employee_id && this.form.value.employee_id.length > 0) {
  //     let savedEmplsIds = [...this.form.value.employee_id]
  //     if (savedEmplsIds.includes(chip.id)) {
  //       arr = [...savedEmplsIds].splice(savedEmplsIds.indexOf(chip.id), 1)
  //     } else {
  //       arr.push(chip.id)
  //     }
  //   }
  //   this.form.patchValue({
  //     employee_id: arr
  //   })
  //   console.log(this.form.value.employee_id);
    
  //   // this.form.patchValue({
  //   //   employee_id: !!chip.id ? chip.id : chip.value
  //   // })

  //   // if (!!this.additional.value.employee_id && this.additional.value.employee_id.includes(!!chip.id ? chip.id : chip.value)) {
  //   //   let val = [...this.additional.value.employee_id]
  //   //   val.splice(this.additional.value.employee_id.indexOf(!!chip.id ? chip.id : chip.value), 1)
  //   //   this.additional.patchValue({
  //   //     employee_id: val
  //   //   })
  //   // }
  // }

  onSearchEmoloyees(resp) {
    if (!this.data.employees) {
      return;
    }

    if (!resp) {
      this.employees$.next(this.data.employees.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.employees$.next(
      this.data.employees.filter(z => z.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  getOrder(val) {
    let sorts = {
      "updated_desc": {
        "name": "Updated",
        "arrow": true
      },
      "updated": {
        "name": "Updated",
        "arrow": false
      },
      "priority_desc": {
        "name": "Priority",
        "arrow": true
      },
      "priority": {
        "name": "Priority",
        "arrow": false
      },
      "custom_id_desc": {
        "name": "Custom id",
        "arrow": true
      },
      "custom_id": {
        "name": "Custom id",
        "arrow": false
      },
      "internal_id_desc": {
        "name": "Internal id",
        "arrow": true
      },
      "internal_id": {
        "name": "Internal id",
        "arrow": false
      },
      "id_desc": {
        "name": "Id",
        "arrow": true
      },
      "id": {
        "name": "Id",
        "arrow": false
      },
      "filesize_desc": {
        "name": "Filesize",
        "arrow": true
      },
      "filesize": {
        "name": "Filesize",
        "arrow": false
      },
      "content_published_at_desc": {
        "name": "Content published at",
        "arrow": true
      },
      "content_published_at": {
        "name": "Content published at",
        "arrow": false
      },
      "content_views_count_desc": {
        "name": "Content views count",
        "arrow": true
      },
      "content_views_count": {
        "name": "Content views count",
        "arrow": false
      }
    }    

    return sorts[val]  
  }

  getValueById(id) {
    if (!this.data.allValues || this.data.allValues.length == 0) {
      return null
    }
    return this.data.allValues.find(x => x.id == id)
  }

  getKeys() {
    return Object.keys(this.activeParams)
  }

  changeTab(val) {
    console.log(val);
    if (val == 1) {
      this.form.patchValue({
        employee_id: [this.data.company.employees[0].id],
      })
    } else if (val == 0) {
      this.form.patchValue({
        employee_id: [this.data.company.employees[0].id],
      })
    }
    this.activeIndex = val;
  }

  getValues(value) {
    if (value.hasOwnProperty('columns')) {
      return value.columns
    }
    let val; 
    if (typeof value == 'number') {
      val = value.toString()
    } else {
      val = value
    }

    return val.split(',')
  }

  activeSections() {
    switch (window.location.pathname) {
      case '/dashboard':
        return 'Dashboard'
        break;
      case '/tasks':
        return 'Cards'
        break;
      case '/jobs':
        return 'Jobs'
        break;
      case '/chats':
        return 'Chats'
        break;
      case '/board':
        return 'Board by Date'
        break;
      case "/projects":
        return 'Projects';
        break;
      case '/board-list':
        return 'Board by Order'
        break;
      case '/urls':
        return 'Posts'
        break;
      case '/urls-start':
        return 'Posts'
        break;
      case '/urls-dashboard':
        return 'Post Dashboards'
        break;
      case '/outlets-dashboard':
        return 'Outlet Dashboards'
        break;
      case '/regular-publications':
        return 'Regular Publications'
        break;
      case '/settings':
        return 'Settings'
        break;
      case '/files':
        return 'Files'
        break;
      default:
        return window.location.pathname.replace('/', '')
        break;
    }
  }

  removeValue(key, val?) {

    if (!!val) {
      let arr = [...this.activeParams[key]];
      if (arr.includes(+val)) {
        arr.splice(arr.indexOf(+val), 1)
      }
      this.activeParams[key] = arr
    } else {
      if (key == "all") {
        this.activeParams = []
      } else {
        delete this.activeParams[key]
      }
    }

    // this.onSubmit(key == 'order_by');
  }

  openFilters() {
    this.filters = !this.filters
  }

  clearAll() {

    this.activeParams = []
    // this.onSubmit(true)
  }

  getViewsUpdateIntervalListByValue(val) {
    if (!this.data.viewsUpdateIntervalList || this.data.viewsUpdateIntervalList.length == 0) {
      return null
    }
    return this.data.viewsUpdateIntervalList.find(x => x.value == val)
  }

  getStatusById(id) {
    if (!this.data.statuses || this.data.statuses.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.data.statuses.find(el => el.id == id)
  }

  getTaskStatusById(id) {
    if (!this.data.taskStatuses || this.data.taskStatuses.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.data.taskStatuses.find(el => el.id == id)
  }

  getJobTypeById(id) {
    if (!this.data.jobTypes || this.data.jobTypes.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.data.jobTypes.find(el => el.id == id)
  }

  getPriorityById(id) {
    if (!this.data.prioritys || this.data.prioritys.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.data.prioritys.find(el => el.id == id)
  }

  getGroupById(id) {
    if (!this.data.groups || this.data.groups.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.data.groups.find(el => el.id == id)
  }

  getProfileByID(id) {
    if (!this.data.profiles || this.data.profiles.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.data.profiles.find(el => el.id == id)
  }

  getProfileStatusByID(id) {
    if (!this.data.profileStatuses || this.data.profileStatuses.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.data.profileStatuses.find(el => el.id == id)
  }

  getContentUrlByID(id) {
    if (!this.data.contentUrl || this.data.contentUrl.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.data.contentUrl.find(el => el.id == id)
  }

  getEmployeeById(id) {
    if (!this.data.employees || this.data.employees.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.data.employees.find(el => el.id == id)
  }  

  getContentById(id) {
    if (!this.data.allContentTypes || this.data.allContentTypes.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.data.allContentTypes.find(el => el.id == id)
  }

  
  getPartnerById(id) {
    if (!this.data.partners || this.data.partners.filter(el => el.partner_company_id == id).length == 0) {
      return false;
    }
    return this.data.partners.find(el => el.partner_company_id == id)
  }  

  getPlatformById(id) {
    if (!this.data.platforms || this.data.platforms.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.data.platforms.find(el => el.id == id)
  }

  close() {
    this.dialogRef.close({event: 'close'});
  }

  save() {
    this.form.patchValue({
      settings: this.activeParams
    })
    if (!this.form.valid) {
      this.submited = true;
      console.log(this.form);
      return
    }
    this.submited = false;
    this.isSubmit = true;
    
    let addData = {...this.form.value}

    if (this.activeIndex == 1) {
      let postInData = {...this.form.value};
      delete postInData.employee_id;
      console.log("this.form.value.employee_id", this.form.value.employee_id)

      this.attachSubscriptions(
        this.companyService.addBookmark(Object.assign({
          employee_id: 0,
      }, postInData)).pipe(
        switchMap(book => {
          let postData = [];
          addData.employee_id.forEach(id => {
            postData.push({
              "path": `/api/section-to-employee/`,
              "query": {},
              "method": "POST",
              "body": Object.assign({
                [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
                employee_id: id,
              }, {
                company_id: this.data.company.id,
                section_id: book.id,
                order: 0,
                is_active: !!this.getEmployeeById(id).is_active ? 1 : 0,
                is_shortcut: !!this.getEmployeeById(id).is_shortcut ? 1 : 0,
              }) 
            })
          });
          return this.taskService.multiRequest(postData)
        })
      ).subscribe(res => {
          console.log("addBookmark", res)
          this.isSubmit = false
          if (this.activeSections() == 'Projects') {
            this.sm.localStorageSetItem('projects_preset_id', res.id);
          }
          this.refreshService.refreshBookmarks$.next(this.activeSections())
          this.dialogRef.close({event: 'save'});
        })
      )
    } else if (this.activeIndex == 0) {
      addData.employee_id = addData.employee_id[0]
      this.attachSubscriptions(
        this.companyService.addBookmark(addData).pipe(
          switchMap(res => {
            return this.companyService.getBookmarksExp(this.data.company.id, 1, {id: [res.id]}).pipe(
              map(x => x.body[0])
            )
          }),
          switchMap(book => {
            return this.companyService.editBookmarkEmpl(book.sectionToEmployee.id, {
              is_active: !!this.is_active.value ? 1 : 0,
              is_shortcut: !!this.is_shortcut.value ? 1 : 0
            })
          })
        ).subscribe(res => {
          console.log("addBookmark", res)
          this.isSubmit = false
          if (this.activeSections() == 'Projects') {
            this.sm.localStorageSetItem('projects_preset_id', res.id);
          }
          this.refreshService.refreshBookmarks$.next(this.activeSections())
          this.dialogRef.close({event: 'save'});
        }, error => {
          console.log(error)
          this.isSubmit = false
          this.refreshService.refreshBookmarks$.next(this.activeSections())
          this.dialogRef.close({event: 'error'});
        })
      )
    } else if (this.activeIndex == 3) {
      addData.company_id = 0
      addData.employee_id = 0
      this.attachSubscriptions(
        this.rootService.addBookmark(Object.assign(addData, {is_shared: 1})).pipe(
          switchMap(book => {
            return this.rootService.editBookmark(book.id, {is_shared: 1})
          })
        ).subscribe(res => {
          console.log("addBookmark", res)
          this.isSubmit = false
          if (this.activeSections() == 'Projects') {
            this.sm.localStorageSetItem('projects_preset_id', res.id);
          }
          this.refreshService.refreshBookmarks$.next(this.activeSections())
          this.dialogRef.close({event: 'save'});
        }, error => {
          console.log(error)
          this.isSubmit = false
          this.refreshService.refreshBookmarks$.next(this.activeSections())
          this.dialogRef.close({event: 'error', error: error});
        })
      )
    } else {
      addData.employee_id = 0
      this.attachSubscriptions(
        this.companyService.addBookmark(Object.assign(addData, {is_shared: 1})).pipe(
          switchMap(book => {
            return this.companyService.editBookmark(book.id, {is_shared: 1})
          })
        ).subscribe(res => {
          console.log("addBookmark", res)
          this.isSubmit = false
          if (this.activeSections() == 'Projects') {
            this.sm.localStorageSetItem('projects_preset_id', res.id);
          }
          this.refreshService.refreshBookmarks$.next(this.activeSections())
          this.dialogRef.close({event: 'save'});
        }, error => {
          console.log(error)
          this.isSubmit = false
          this.refreshService.refreshBookmarks$.next(this.activeSections())
          this.dialogRef.close({event: 'error', error: error});
        })
      )
    }

  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

}
