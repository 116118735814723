import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDateRangePicker } from '@angular/material/datepicker';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { Router } from '@angular/router';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import * as moment from 'moment';
import { forkJoin, fromEvent, ReplaySubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, last, map, switchMap, tap } from 'rxjs/operators';
import { MY_FORMATS } from 'src/app/components/atTasksDialog/task-profile-add/task-profile-add.component';
import { prioritys } from 'src/app/shared/consts/prioritys';
import { BaseClass } from 'src/app/shared/models/base-class';
import { GlobalDataService } from 'src/app/shared/services/common/global-data.service';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';

@Component({
  selector: 'app-task-filter',
  templateUrl: './task-filter.component.html',
  styleUrls: ['./task-filter.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ]
})

export class TaskFilterComponent extends BaseClass implements OnInit, OnDestroy {
  @ViewChild('profilesContainer') profilesContainer: MatSelect;
  public filter: FormGroup = this.fb.group({
    count: '',
    status_id: '',
    group_id: '',
    operation_status_id: [0],
    operation_employee_id: '',
    operation_client_id : '',
    channel_id: '',
    priority: '',
    operation_id: '',
    operation_completed_at_from : '',
    operation_completed_at_to : '',
    is_content_url: '',
    order_by: 'updated_desc',
    content_status_id: '',
    id: ''
  });
  public filterCount: number = 1;
  public groups: any;
  public activeStatus: number[] = [0];
  public activeLang: any;
  public taskStatuses: any;
  public employees: any;
  public prioritys: any = prioritys;
  public profileStatuses: any;
  public sortValue: string = 'updated_desc';
  public operationsValues: any;
  
  public profiles: any[] = [];
  public profilesPagination: any;
  public profilesPage: number = 1;
  
  public profilesMoreControl: FormControl = new FormControl();
  public profiles$: ReplaySubject<any> = new ReplaySubject<any>(1);
  
  public groupsControl: FormControl = new FormControl();
  public groups$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public employees$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public execControl: FormControl = new FormControl();
  public clientControl: FormControl = new FormControl();
  
  public contentUrl = [
    {
      name: marker("Without content url"),
      id: 0
    },
    {
      name: marker("With content url"),
      id: 1
    }
  ]

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<TaskFilterComponent>,
    private fb: FormBuilder,
    private router: Router,
    private _adapter: DateAdapter<any>,
    private taskService: TaskService,
    private languageService: LanguageService,
    private companyService: CompanyService,
    private globalDataService: GlobalDataService,
  ) { super() }

  ngOnInit(): void {

    this.getProfilesStatus();
    this.getTaskStatus();
    this.getGroupsCompany();
    this.getEmployees();
    this.getLangData();
    this.getProfiles(this.data.company.id, this.profilesPage);

    this.filter.valueChanges.subscribe(res => {
      console.log("valueChanges", res);
      let filterData = {...res};
      console.log("filterData", filterData)
      if (!!filterData.operation_completed_at_from) {
        filterData.operation_completed_at_from = moment(filterData.operation_completed_at_from._d).format("X");
      }
      if (!!filterData.operation_completed_at_to) {
        filterData.operation_completed_at_to = moment(filterData.operation_completed_at_to._d).format("X");
      } 

      if (filterData.operation_status_id == 0) {
        delete filterData.operation_status_id;
      }
      
  
      let params = {
        company_id: this.data.company.id
      };
  
      Object.keys(filterData).forEach(element => {
        if (element == 'is_content_url' && filterData[element] == '0') {
          params[element] = filterData[element]
        } else if (element == 'operation_status_id' && !(filterData[element].length == 1 && filterData[element].includes(0))) {
          console.log("operation_status_id", filterData[element]);
          params[element] = filterData[element].join(',')
        } else if (filterData[element] == '0' && filterData[element] == 0 && element == 'operation_employee_id') {
          params[element] = filterData[element]
        } else if (filterData[element] != '0' && filterData[element] != '') {
          params[element] = filterData[element]
        }
      });
  
  
      if (filterData.operation_completed_at_to === 0) {
        params['operation_completed_at_to'] = '0';
      }
  
      this.router.navigate(['/tasks'], { queryParams: params});

      this.close();
  
      // if (this.is_mobile) {
      //   this.layoutService.overflowAuto();
      //   if (this.mobile_filter_wrp) {
      //     this.mobile_filter_wrp.nativeElement.classList.remove("active");
      //   }
      // }
    })

    this.attachSubscriptions(
      this.profilesMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchProfiles(resp))
    )
    this.attachSubscriptions(
      this.groupsControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchGroups(resp))
    )
    this.attachSubscriptions(
      this.execControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchEmoloyees(resp))
    )
    this.attachSubscriptions(
      this.clientControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchEmoloyees(resp))
    )
  }

  onSearchEmoloyees(resp) {
    if (!this.employees) {
      return;
    }

    if (!resp) {
      this.employees$.next(this.employees.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.employees$.next(
      this.employees.filter(z => z.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  onSearchGroups(resp) {
    if (!this.groups) {
      return;
    }

    if (!resp) {
      this.groups$.next(this.groups.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }

    // filter the banks
    this.groups$.next(
      this.groups.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  onSearchProfiles(resp) {
    if (!this.profiles) {
      return;
    }

    if (!resp) {
      this.profiles$.next(this.profiles.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }

    // filter the banks
    this.profiles$.next(
      this.profiles.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  onScrollProfiles() {
    this.attachSubscriptions(
      fromEvent(this.profilesContainer.panel.nativeElement, "scroll").pipe(
        filter((e:any) => e.target.scrollTop >=  e.target.scrollHeight - e.target.offsetHeight - 400),
        map(() => this.profilesContainer.panel.nativeElement.scrollTop),
        debounceTime(200),
        distinctUntilChanged()
      ).subscribe(() => {
          if (this.profilesPage <= this.profilesPagination['pageCount']) {
            this.getProfiles(this.data.company.id, this.profilesPage);
          }
        }
      )
    )
  }

  getProfiles(company_id, page) {
    this.attachSubscriptions(
      this.companyService.getProfiles(company_id, page).pipe(
        tap(el => {
          this.profilesPagination = {
            'pageCount': el.headers.get('x-pagination-page-count'),
            'perPage': el.headers.get('x-pagination-per-page'),
            'totalCount': el.headers.get('x-pagination-total-count'),
            'currentPage': el.headers.get('x-pagination-current-page'),
          }
        }),
        map(el => el.body)
      ).subscribe(resp => {
        console.log("getProfiles", resp)
        resp.forEach(profile => {
          if (this.profiles.filter(x => x.id == profile.id).length == 0) {
            this.profiles.push(...resp);
          }
        })
        this.profiles$.next(this.profiles.slice());
        this.profilesPage = this.profilesPage + 1;
      })
    )
  }

  getProfilesStatus() {
    this.attachSubscriptions(
      this.globalDataService.getContentStatus().subscribe(resp => {
        console.log("getProfilesStatus", resp)
        this.profileStatuses = resp.slice();
      })
    )
  }

  getTasksNoComplete(picker:MatDateRangePicker<any>) {
    picker.close()
    this.filter.patchValue({
      operation_completed_at_from: '',
      operation_completed_at_to: 0
    })
  }
  
  getTaskStatus() {
    this.attachSubscriptions(
      this.taskService.getTaskStatuses(this.data.company.id).subscribe(resp => {
        this.taskStatuses = resp.slice()
        this.taskStatuses.unshift({
          id: 0,
          name: marker('All statuses')
        });
      })
    )
  }

  changeActiveStatus(id, event = null) {
    if (!!event && (event.ctrlKey || event.metaKey)) {
      if (id == 0) {
        this.activeStatus = [0];
      } else if (this.activeStatus.includes(id)) {
        this.activeStatus.splice(this.activeStatus.indexOf(id), 1);
      } else if (this.activeStatus.includes(0) && id != 0) {
        this.activeStatus.splice(this.activeStatus.indexOf(0), 1)
        this.activeStatus.push(id);
      } else {
        this.activeStatus.push(id);
      }
      if (this.activeStatus.sort().toString() == [1,2,3,4,5,6,97,98,99].toString()) {
        this.activeStatus = [0];
      }
    } else {
      if (id == 0) {
        this.activeStatus = [0];
      } else {
        this.activeStatus = [id];
      }
    }

    this.filter.patchValue({
      operation_status_id: this.activeStatus
    })
  }
  
  sort(val) {
    this.filter.patchValue({
      order_by: val
    })
  }

  getGroupsCompany() {
    this.attachSubscriptions(
      this.companyService.getInfiniteGroupsCompany(this.data.company.id, '1', '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.companyService.getInfiniteGroupsCompany(this.data.company.id, x).pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        this.groups = [].concat(...res);
        this.groups$.next(this.groups.slice());
      })
    )
  }
    
    getEmployees() {
      this.attachSubscriptions(
        this.taskService.getEmployees(this.data.company.id).subscribe(resp => {
          this.employees = resp;
          this.employees$.next(this.employees.slice());
        })
    )
  }

  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData().pipe(
        tap(resp => {
          this.activeLang = resp.active;
          this._adapter.setLocale(resp.active);
        }),
        switchMap(() => this.taskService.getOperations(this.data.company.id, this.activeLang).pipe(
          tap(x => {
            this.operationsValues = x;
          })
        ))
      ).subscribe(resp => {
        console.log("getOperations", resp)
      })
    )
  }


  ngOnDestroy(): void {
    this.clearSubscriptions();
  }
}
