import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { mimeTypes } from 'mime-wrapper';
import { FileService } from 'src/app/shared/services/rest/file.service';
import { catchError, concatMap, debounceTime, distinctUntilChanged, filter, finalize, last, map, mergeMap, tap } from 'rxjs/operators';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { SnackBarItem } from '../../../snack-bar/snack-bar-item';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { from, fromEvent, of, ReplaySubject } from 'rxjs';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ParametersService } from 'src/app/shared/services/rest/parameters.service';
import * as moment from 'moment';
import { LoadingService } from 'src/app/shared/services/rest/loading.service';
import { canTranscribe } from 'src/app/shared/consts/transcribe.extensions';

@Component({
  selector: 'app-speech-to-text',
  templateUrl: './speech-to-text.component.html',
  styleUrls: ['./speech-to-text.component.scss'],
    animations: [
      trigger('detailExpand', [
        state('collapsed', style({height: '0px', minHeight: '0'})),
        state('expanded', style({height: '*'})),
        transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      ]),
    ],
})
export class SpeechToTextComponent extends BaseClass implements OnInit, OnDestroy {
  @ViewChild("tableContainer") tableContainer: ElementRef;
  public reqs: any[] = [];
  public mimeTypes = mimeTypes;
  public form: FormGroup;
  public canTranscribe: Function = canTranscribe;
  public isRefreshed: boolean = false;
  public isSubmit: boolean = false;
  public isGettingTag: boolean = false;
  public activeLang: any;
  public timeZone = new Date().getTimezoneOffset()*60;
  public page: number = 1;
  public pagination: any;
  public displayedColumns: string[] = ["Date", "Status", "Language", "Costs"];

  public today = moment().set({hour:0,minute:0,second:0}).unix();
  public todayEnd = moment().endOf('day').unix();
  
  public saveTags: any = [];
  
  public langs: any;
  public langs$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public langsControl: FormControl = new FormControl();
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<SpeechToTextComponent>,
    private languageService: LanguageService,
    private parametersService: ParametersService,
    private ls: LoadingService,
    private fileService: FileService,
    private layoutService: LayoutService,
    private fb: FormBuilder,
    private dialog: MatDialog,
  ) { super() }

  ngOnInit(): void {
    console.log("SpeechToTextComponent", this.data)
    if (this.timeZone > 0) {
      this.timeZone = -this.timeZone
    } else {
      this.timeZone = Math.abs(this.timeZone)
    }
    this.form = this.fb.group({
      company_id: this.data.file.company_id,
      original_file_id: this.data.file.original_file_id || this.data.file.id,
      language: '',
      detect: false
    })


    if (this.data.files && this.data.files.length) {

      let count = 0;
      let lenSelection = this.data.files.length;
  
      this.isSubmit = true;
      this.ls.requests$.next({
        value: 0,
        target: `Get language for ${lenSelection} files`
      })

      from(this.data.files).pipe(
        concatMap((file:any) => {
          file.stt = {
            company_id: file.company_id,
            original_file_id: file.original_file_id || file.id,
            language: '',
            detect: false,
            is_getting: true
          }
          let fileTag = file.parameterValuesToTask.find(k => !!k.parameter.is_language);

          if (this.saveTags && this.saveTags.length && this.saveTags.filter(p => p.id == fileTag.id).length) {
            file['stt'].language = this.saveTags.filter(p => p.id == fileTag.id)[0].iso_value;
            return of(null);
          } else {
            if (!!fileTag && !!fileTag.parameterValue) { 
              return this.parametersService.getAllTags('1', {id: fileTag.parameterValue.original_id}, '200').pipe(
                map(k => k.body),
                tap(fileLangs => {
                  if (fileLangs && fileLangs.length) {
                    file['stt'].language = fileLangs[0].iso_value;
                  }
                  this.saveTags.push({
                    id: fileTag.id,
                    language: fileLangs[0].iso_value
                  })
                  file.stt.is_getting = false
                }),
                catchError(() => {
                  file.stt.is_getting = false
                  return of(null);
                }),
              )
            } else {
              file.stt.is_getting = false
              return of(null);
            }
          }

        })
      ).subscribe({
        next: (next) => {
          console.log("next onSubmit", next);
          this.ls.requests$.next({
            value: Math.round((100 / lenSelection) * (count+1)),
            target: `Get language for ${lenSelection} files`
          })
          count++;
        },
        complete: () => {
          console.log("complete onSubmit");
          this.isSubmit = false;
          this.layoutService.showSnackBar({name: 'Getting language'}, marker("successfully!"), SnackBarItem)
        },
        error: (error) => {
          console.log("error onSubmit", error)
        }
      })
    } else {
      if (this.data.file.parameterValuesToTask) {
        
        let langTag = this.data.file.parameterValuesToTask.find(k => !!k.parameter.is_language);

        if (langTag) {
          this.isGettingTag = true;
          this.attachSubscriptions(
            this.parametersService.getAllTags('1', {id: langTag.parameterValue.original_id}, '200').pipe(
              map(k => k.body)
            ).subscribe(resp => {
              console.log("getAllTags", resp)
              if (resp && resp.length) {
                this.form.patchValue({
                  language: resp[0].iso_value
                })
              }
              this.isGettingTag = false;
            }, error => {
              this.isGettingTag = false;
            })
          )
        }

        // let allLangs = this.data.file.parameterValuesToTask.filter(k => k.parameter.is_language);
        // let primaryLangs = this.data.file.parameterValuesToTask.filter(k => k.parameter.is_language && !!k.is_primary);
        // if (primaryLangs.length) {
        //   this.form.patchValue({
        //     language: primaryLangs[0].parameterValue.value
        //   })
        // } else if (allLangs.length) {
        //   this.form.patchValue({
        //     language: allLangs[0].parameterValue.value
        //   })
        // }
      }
      this.getSpeechToText(this.page);
    }
    this.getLangData();    

    this.getLangs();

    this.attachSubscriptions(
      this.langsControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchLangs(resp))
    )
  }

  getLangById(id) {
    if (!this.langs || this.langs.length == 0) {
      return null
    }
    return this.langs.find(x => x.id == id)
  }

  getLangByVal(iso_value) {
    return this.langs && this.langs.find(x => x.iso_value == iso_value)
  }

  onSearchLangs(resp) {
    if (!this.langs) {
      return;
    }

    if (!resp) {
      this.langs$.next(this.langs.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }

    this.langs$.next(
      this.langs.filter(b => b.value.toLowerCase().indexOf(resp) > -1)
    );
  }

  getLangs() {
    // this.data.company.id
    this.attachSubscriptions(
      this.parametersService.getAllValues(1, 0, {is_language: 1}, '200').pipe(
        map(res => res.body),
        tap(res => {
          this.langs = res;
          this.langs$.next(this.langs.filter(x => !!x.id).slice())
        }),
      ).subscribe(resp => {
        console.log("this.langs", this.langs)
      })
    )
  }

  log() {
    console.log("this.reqs - " + this.page, this.reqs)
    console.log("this.form.value", this.form.value)
  }

  rowClick(element) {
    console.log(element);
    element.isExpanded = !element.isExpanded
  }

  refresh() {
    this.page = 1;
    this.getSpeechToText(this.page);
  }

  getSpeechToText(n) {
    this.isRefreshed = true;
    this.attachSubscriptions(
      this.fileService.getSpeechToText(n, this.data.company.id, {original_file_id: this.data.file.original_file_id || this.data.file.id}).pipe(
        tap(el => {
          this.pagination = {
            'pageCount': el.headers.get('x-pagination-page-count'),
            'perPage': el.headers.get('x-pagination-per-page'),
            'totalCount': el.headers.get('x-pagination-total-count'),
            'currentPage': el.headers.get('x-pagination-current-page'),
          }
        }),
        map(el => el.body)
      ).subscribe(resp => {
        if (this.page = 1) {
          this.reqs = resp
        } else {
          this.reqs.push(...resp)
        }
        this.page++;
        this.isRefreshed = false;
        console.log("this.reqs - " + this.page, this.reqs)
      })
    )
  }

  ngAfterViewInit() {
    if (this.tableContainer) {
      this.tableContainer.nativeElement.scrollTop = 0;
      this.onScroll();
    }
  }
  
  onScroll() {
    this.attachSubscriptions(
      fromEvent(this.tableContainer.nativeElement, "scroll").pipe(
        filter((e:any) => e.target.scrollTop >=  e.target.scrollHeight - e.target.offsetHeight - 400),
        debounceTime(300),
        distinctUntilChanged()
      ).subscribe(() => {
          if (this.page <= this.pagination['pageCount']) {
            this.getSpeechToText(this.page);
          }
        }
      )
    )
  }

  copySpeech() {
    this.layoutService.showSnackBar({name: 'Speech'}, marker("Copied"), SnackBarItem)
  }

  transcribeFile() {
    let answers = []
    if (this.data.files) {
      let sttArr = [];
      this.data.files.filter(k => canTranscribe(k.filename)).forEach(file => {
        let formData = {...file.stt}
        if (!!formData.detect) {
          delete formData.language;
        }
        delete formData.is_getting;
        delete formData.detect;

        sttArr.push(formData)
      });

      let count = 0;
      let lenSelection = sttArr.length;

      if (!lenSelection) {
        this.layoutService.showSnackBar({name: 'Among the selected files there are none that can be sent to the speech to text'}, '', SnackBarItem)
        return
      }
      this.isSubmit = true;
      this.ls.requests$.next({
        value: 0,
        target: `Speech to text ${lenSelection} files`
      })


      from(sttArr).pipe(
        mergeMap(x => this.fileService.createSpeechToText(this.data.company.id, x).pipe(
          tap(k => {
            this.ls.requests$.next({
              value: Math.round((100 / lenSelection) * (count+1)),
              target: `Speech to text ${lenSelection} files`
            })
            count++;
            // if (!!this.dialogRef) {
            //   this.close();
            // }
            answers.push(k)
          }),
          catchError(() => of([])),
        ), 4), // 4 запроса параллельно
        finalize(() => {
          this.layoutService.showSnackBar({name: 'Speech to text started'}, marker("successfully!"), SnackBarItem)
          // Этот блок выполнится только после обработки всех элементов
          console.log('Все запросы завершены');
          this.isSubmit = false;
          if (this.data.fromVe) {
            this.dialogRef.close({event: "create", data: answers});
          } else {
            this.close();
          }
        }),
        last()
      ).subscribe(resp => {
      })
    } else {
      let formData = {...this.form.value}
      if (!!formData.detect) {
        delete formData.language
      }
      delete formData.detect
      this.attachSubscriptions(
        this.fileService.createSpeechToText(this.data.company.id, formData).subscribe(resp => {
          answers.push(resp)
          // this.data.file.is_rev_transcribe = resp.is_rev_transcribe
          this.layoutService.showSnackBar({name: ''}, marker('File in the process of receiving text'), SnackBarItem);
          this.page = 1;
          this.getSpeechToText(this.page);

          if (this.data.fromVe) {
            this.dialogRef.close({event: "create", data: answers});
          }
        },
        error => {
          this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
        })
      )
    }
  }

  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData().subscribe(resp => {
        this.activeLang = resp.active;
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false});
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
