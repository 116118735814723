<ng-container *ngIf="!is_mobile; else elseTemplateDesktop">
    <mat-dialog-content class="mat-typography">
        <div class="container a_r_blocks_wrp">
            <p class="in_block comment">{{ (!!data?.only ? ("Tap the file to select new " + data?.only) : "Tap the file to select thumbnail") | translate}}.</p>
            <div class="in_block files_block">
                <ng-container *ngIf="data.is_multi; else elseTemplateMulti">
                    <app-file-manager
                    [useOut]="true"
                    [selectedFileIds]="selectedFilesId"
                    [selectedMode]="true"
                    [disableId]="selectedFile ? selectedFile.id : 0"
                    [multiSelectedMode]="true"
                    (onSelectFile)="selectFile($event)"
                    [only]="data?.only"
                    [originalFile]="data?.originalFile"
                    [initLocation]="'/'"
                    [initData]="{
                        'fromVE': data?.fromVE,
                        'task': data?.task,
                        'work': data?.work,
                        'company': data?.company
                    }"></app-file-manager>
                </ng-container>
                <ng-template #elseTemplateMulti>
                    <app-file-manager
                    [useOut]="true"
                    [selectedFileIds]="selectedFile ? [selectedFile.id] : []"
                    [selectedMode]="true"
                    (onSelectFile)="toggleFile($event)"
                    [only]="data?.only"
                    [initLocation]="'/'"
                    [initData]="{
                        'fromVE': data?.fromVE,
                        'task': data?.task,
                        'work': data?.work,
                        'company': data?.company
                    }"></app-file-manager>
                </ng-template>
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <div class="f_w_p_btns">
            <button mat-raised-button class="mr-1" *ngIf="data.is_multi" (click)="selectAll()">
                {{ "Select all" | translate }}
            </button>
            <button mat-button (click)="close()">
                {{ "Close" | translate }}
            </button>
            <button class="ml-1 d-f-btn" (click)="save()" mat-raised-button color="primary">
                {{ "Save" | translate }}
            </button>
        </div>
    </mat-dialog-actions>
</ng-container>
<ng-template #elseTemplateDesktop>
    <div mat-dialog-title class="mobile_interface_title">
        <div class="mobile_interface_title_in">
            <div class="tab_title" (click)="close()">
                <div class="d-f-c">
                    <div class="icon_back">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7 12L6.11612 11.1161C5.62796 11.6043 5.62796 12.3957 6.11612 12.8839L7 12ZM16.8839 3.88388C17.372 3.39573 17.372 2.60427 16.8839 2.11612C16.3957 1.62796 15.6043 1.62796 15.1161 2.11612L16.8839 3.88388ZM15.1161 21.8839C15.6043 22.372 16.3957 22.372 16.8839 21.8839C17.372 21.3957 17.372 20.6043 16.8839 20.1161L15.1161 21.8839ZM7.88388 12.8839L16.8839 3.88388L15.1161 2.11612L6.11612 11.1161L7.88388 12.8839ZM6.11612 12.8839L15.1161 21.8839L16.8839 20.1161L7.88388 11.1161L6.11612 12.8839Z" fill="#232323"/>
                        </svg>
                    </div>
                    <span>
                        {{ "Tap the file to select thumbnail" | translate }}
                    </span>
                </div>
            </div>
        </div>
    </div>
    
    <mat-dialog-content class="mobile_interface_content">
        <div class="mobile_scrollable_int_big mob_files_block">        
            <app-mob-file-manager
            [useOut]="true"
            [selectedFileIds]="selectedFile ? [selectedFile.id] : []"
            [selectedMode]="true"
            (onSelectFile)="toggleFile($event)"
            [initLocation]="'/'"
            [initData]="{
                'task': data?.task,
                'work': data?.work,
                'company': data?.company
            }">
            </app-mob-file-manager>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions>
        <div class="mobile_interface_buttons" style="justify-content: space-between !important;">
            <button mat-button (click)="close()">
                {{ "Close" | translate }}
            </button>
            <button class="ml-1 d-f-btn" (click)="save()" mat-raised-button color="primary">
                {{ "Save" | translate }}
            </button>
        </div>
    </mat-dialog-actions>
</ng-template>