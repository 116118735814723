<h2 mat-dialog-title class="dialog_title">
    <span>
        {{ "Set working schedule" | translate }}
    </span>
    <div class="ml-1" *ngIf="employee.timezone" style="font-size: 12px; font-weight: 300;" (click)="employee.timezone = ''">
        {{employee.timezone}}
    </div>
    <button style="margin-left: 30px;" mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h2>
<mat-dialog-content class="mat-typography">
    <div class="container">
        <form [formGroup]="form" class="block" *ngIf="!(!!employee.timezone_offset && !!employee.timezone)">
            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ "Time zone" | translate }}</mat-label>
                <mat-select disableOptionCentering formControlName="timezone">
                    <mat-option>
                        <ngx-mat-select-search [formControl]="timezoneFilterCtrl" noEntriesFoundLabel="'no matching time zone found'" placeholderLabel="{{ 'Find time zone' | translate }}..." ></ngx-mat-select-search>
                    </mat-option>
                    <mat-option value="no">-- {{ "My time zone is not" | translate }} --</mat-option>
                    <mat-optgroup *ngFor="let zone of filteredTimezonesGroups | async" [label]="getTimeZone(zone.value)"
                    [disabled]="zone.disabled">
                        <mat-option *ngFor="let name of zone.names" [value]="name">
                            {{name}}
                        </mat-option>
                    </mat-optgroup>
                </mat-select>
            </mat-form-field>
        
            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ 'Timezone offset' | translate }}</mat-label>
                <input [readonly]="form.get('timezone').value != 'no'" formControlName="timezone_offset" matInput placeholder="{{ 'Timezone offset' | translate }}">
            </mat-form-field>

            <div class="bottom_line" style="padding: 0 !important;">
                <button style="color: #686868; background: #fff;" (click)="close()">
                    {{ "Close" | translate }}
                </button>

                <button class="ml-1" (click)="saveEmployee()">
                    {{ "Save" | translate }}
                </button>
            </div>
        </form>
        <div class="block no_p_right" *ngIf="!!(!!employee.timezone_offset && !!employee.timezone)">
            <div class="days">
                <ng-container *ngFor="let item of days">
                    <div class="day" [ngClass]="{'active': !!item.is_open}">
                        <div class="day_top">
                            <div class="name text_one_line">{{item.name}}</div>
                            <ng-container *ngIf="!!item.data && !!item.data.length && !item.is_off; else elseTemplateNoDataPen">
                                <div class="intervals">
                                    <button class="set_btn" mat-icon-button (click)="toggleDay(item)"><mat-icon>{{!item.is_open ? 'edit' : 'close'}}</mat-icon></button>
                                    <ng-container *ngIf="!item.is_open">
                                        <div class="interval" *ngFor="let interval of item.data">
                                            <div class="from">{{timestampToTime(interval.from_time)}}</div>
                                            <div class="ft_spacer">-</div>
                                            <div class="to">{{timestampToTime(interval.to_time)}}</div>
                                            <div class="duration">({{(interval.to_time - interval.from_time) | timeFormat:true}})</div>
                                        </div>
                                    </ng-container>
                                </div>
                            </ng-container>
                            <ng-template #elseTemplateNoDataPen>
                                <div class="hovered">
                                    <div class="set" *ngIf="!item.is_open" (click)="toggleDay(item)">{{"Set working hours" | translate}}</div>
                                </div>
                                <div class="no_hovered">
                                    <div class="no_hours">No working hours yet</div>
                                </div>
                            </ng-template>

                            <span class="day_off">
                                {{ "Day off" | translate }}
                                <mat-checkbox [disableRipple]="true" class="outlet_fil_check" color="primary" [disabled]="!!item.is_load" [checked]="item.is_off" (change)="dayOff($event, item)"></mat-checkbox>
                            </span>
                        </div>
                        <div class="day_settings" *ngIf="!!item.is_open">

                            <div class="lines">
                                <div class="line_title">
                                    {{"Working day"|translate}}
                                </div>
                                <!-- [(ngModel)]="val.timeInFormat" [defaultTime]="'00:00'" [controlOnly]="true" -->
                                <div class="line">
                                    <div class="white_el">
                                        <div class="white_label">
                                            {{ "Starts at" |translate }}
                                        </div>
                                        <div class="ngx-timepicker-field-example ml-1">
                                            <ngx-timepicker-field [(ngModel)]="item.working.from" [controlOnly]="true"></ngx-timepicker-field>
                                        </div>
                                    </div>
                                    <span class="range_spacer">-</span>
                                    <div class="white_el">
                                        <div class="white_label">
                                            {{ "Ends at" |translate }}
                                        </div>
                                        <div class="ngx-timepicker-field-example ml-1">
                                            <ngx-timepicker-field [(ngModel)]="item.working.to" [controlOnly]="true"></ngx-timepicker-field>
                                        </div>
                                    </div>
                                </div>

                                <div class="line_title">
                                    {{"Break(s)"|translate}}
                                </div>
                                <div class="line break_line" *ngFor="let break of item.breaks; let i = index;">
                                    <div class="white_el">
                                        <div class="white_label">
                                            {{ "Starts at" |translate }}
                                        </div>
                                        <div class="ngx-timepicker-field-example ml-1">
                                            <ngx-timepicker-field [(ngModel)]="break.from" [controlOnly]="true"></ngx-timepicker-field>
                                        </div>
                                    </div>
                                    <span class="range_spacer">-</span>
                                    <div class="white_el">
                                        <div class="white_label">
                                            {{ "Ends at" |translate }}
                                        </div>
                                        <div class="ngx-timepicker-field-example ml-1">
                                            <ngx-timepicker-field [(ngModel)]="break.to" [controlOnly]="true"></ngx-timepicker-field>
                                        </div>
                                    </div>
                                    <mat-icon class="delete_break" (click)="deleteBreak(item, i)">delete</mat-icon>
                                </div>
                                <div class="add_another" (click)="log(); addBreak(item)">+ {{'Add another break' | translate}}</div>
                            </div>

                            <div class="save_l">
                                <mat-checkbox [disableRipple]="true" class="outlet_fil_check" color="primary" [checked]="item.for_all" (change)="itemAll($event, item)">{{"Set for all working days" | translate}}</mat-checkbox>
                                <button class="w8_btn" (click)="save(item)" [disabled]="!!item.is_load" *ngIf="item.working.from != 0 && item.working.to != 0">
                                    <mat-spinner class="spin_16 mr-1" *ngIf="!!item.is_load"></mat-spinner>  
                                    {{ "Save" | translate }}
                                </button>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end" *ngIf="!!(!!employee.timezone_offset && !!employee.timezone)">
    <div class="bottom_line">
        <button (click)="close()">
            {{ "Close" | translate }}
        </button>
    </div>
</mat-dialog-actions>
