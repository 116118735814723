import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { concat } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { LoadingService } from 'src/app/shared/services/rest/loading.service';
import { RefreshService } from 'src/app/shared/services/rest/refresh.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';

@Component({
  selector: 'app-set-privacy-status',
  templateUrl: './set-privacy-status.component.html',
  styleUrls: ['./set-privacy-status.component.scss']
})
export class SetPrivacyStatusComponent extends BaseClass implements OnInit, OnDestroy {
  public form: FormGroup;
  public isSubmit: boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<SetPrivacyStatusComponent>,
    private fb: FormBuilder,
    private sm: StorageManagerService,
    private refreshService: RefreshService,
    private ls: LoadingService,
    private taskService: TaskService,
    private layoutService: LayoutService
  ) { super() }

  ngOnInit(): void {
    console.log("SetPrivacyStatusComponent", this.data)
    this.form = this.fb.group({
      privacy: [!!this.data.profile && !!this.data.profile.external_status ? this.data.profile.external_status : '', Validators.required]
    })
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  onSubmit() {
    if (this.data.collection) {
        this.isSubmit = true;
      
        let sbmtData = [];
        let partsSbmtData = [];

        this.data.collection.forEach(item => {
          sbmtData.push({
            "path": `/api/youtube/set-privacy-status/`,
            "query": {company_id: this.data.company.id},
            "method": "POST",
            "body": Object.assign({
              [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token')
          }, {task_channel_id: item.id, privacy_status: this.form.value.privacy}) 
          })
        });
      
        for (let i = 0; i < Math.ceil(sbmtData.length/5); i++) {
          partsSbmtData.push(sbmtData.slice(i*5, 5 + i*5))
        }
      
        let count = 1;
        concat(...partsSbmtData.map((param) => this.taskService.multiRequest(param))).subscribe({
          next: (next) => {
            console.log("next onSubmit", next);
            this.ls.requests$.next({
              value: Math.round((100 / partsSbmtData.length) * count),
              target: "Set privacy status" 
            })
            count++;
          },
          complete: () => {
            console.log("complete onSubmit");
            this.dialogRef.close({event: "update", data: this.data.collection.slice()})
          },
          error: (error) => {
            console.log("error onSubmit", error)
          }
        })
      
    } else {
      this.isSubmit = true;
      this.attachSubscriptions(
        this.taskService.setPrivacyStatusForYT({task_channel_id: this.data.profile.id, privacy_status: this.form.value.privacy}).subscribe(resp => {
          this.dialogRef.close({event: "update", data: this.data.collection.slice()})
          this.isSubmit = false;
        }, error => {
          this.layoutService.showSnackBar({name: ""}, error, SnackBarItem)
          this.isSubmit = false;
        })
      )
    }
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

}
