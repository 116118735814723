import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseClass } from 'src/app/shared/models/base-class';

@Component({
  selector: 'app-select-outlet',
  templateUrl: './select-outlet.component.html',
  styleUrls: ['./select-outlet.component.scss']
})

export class SelectOutletComponent extends BaseClass implements OnInit, OnDestroy {
  public filter: FormGroup;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<SelectOutletComponent>,
    public fb: FormBuilder,
  ) { super() }

  ngOnInit(): void {
    console.log("SelectOutletComponent", this.data)
    this.filter = this.fb.group({
      outlet: ''
    })

    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        this.close();
      })
    )
  }

  close() {
    this.dialogRef.removePanelClass('animate__slideInUp');
    this.dialogRef.addPanelClass('animate__slideOutDown');
    setTimeout(()=>{this.dialogRef.close({event: 'close'})}, 300);
  }

  onSubmit() {
    if (!this.filter.value.outlet || this.filter.value.outlet.length == 0) {
      return
    }
    this.dialogRef.removePanelClass('animate__slideInUp');
    this.dialogRef.addPanelClass('animate__slideOutDown');
    setTimeout(()=>{this.dialogRef.close({event: 'select', data: this.filter.value.outlet[0]})}, 300);
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

}
