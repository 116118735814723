import { Component, OnInit, Input, OnDestroy, SimpleChanges, OnChanges, Output, EventEmitter, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { BaseClass } from 'src/app/shared/models/base-class';
import { StreamState } from 'src/app/shared/models/stream-state';
import { AudioService } from 'src/app/shared/services/common/media/audio.service';

@Component({
  selector: 'app-audio-player',
  templateUrl: './audio-player.component.html',
  styleUrls: ['./audio-player.component.scss'],
  providers: [AudioService],
})
export class AudioPlayerComponent extends BaseClass implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @ViewChild('audio') audio: ElementRef;
  public audioUrl: string;
  @Input() file: any;
  @Input() company_id: any;
  @Input() fileOriginal: any;
  @Input() fileIsUpload: any;
  @Input() activeIndex: any;
  @Input() canTranscribe: boolean = false;
  @Input() forFM: boolean = false;
  @Input() isMOB: boolean = false;
  @Input() host: any;
  @Output() Download = new EventEmitter<any>();
  @Output() Copy = new EventEmitter<any>();
  @Output() TranscribeFile = new EventEmitter<any>();
  @Output() Delete = new EventEmitter<any>();
  @Output() DeleteAndCancel = new EventEmitter<any>();
  state: StreamState;

  public streamSub: Subscription;

  constructor(private audioService: AudioService) {
    super();
    this.audioService.getState().subscribe((state) => {
      this.state = state;
    });
    this.audioService.getThresholdListener().subscribe(() => {
      console.log('activate next button');
    });
  }

  ngOnInit(): void {
    this.audioUrl = this.host + this.file.original + '?company_id=' + this.company_id
    console.log("this.audioUrl",this.audioUrl);
    console.log("this.file", this.file);
    this.playStream(this.audioUrl);
    this.pause();
  }

  ngAfterViewInit(): void {
    if (this.audio) {
      this.audio.nativeElement.muted = true;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log("changes", changes)
    console.log("this.file", this.file);
    if (changes.activeIndex && changes.activeIndex.currentValue && this.file.index && this.file.index != changes.activeIndex.currentValue) {
      this.pause();
    }

    if (this.audioUrl != this.host + this.file.original + '?company_id=' + this.company_id) {
      this.audioUrl = this.host + this.file.original + '?company_id=' + this.company_id;
      this.playStream(this.audioUrl);
      this.pause();
    }
    console.log("this.audioUrl", this.audioUrl)
  }

  playStream(url) {
    if (this.streamSub) {
      this.streamSub.unsubscribe()
    }
    this.streamSub = this.audioService.playStream(url).subscribe((events) => {
      // console.log(events);
    })
  }

  pause() {
    this.audioService.pause();
    if (this.audio) {
      this.audio.nativeElement.pause();
    }

    console.log(this.audio)
  }
  play() {
    this.audioService.play();
    if (this.audio) {
      this.audio.nativeElement.play();
    }
    console.log(this.audio)
  }
  stop() {
    this.audioService.stop();
  }
  mute() {
    this.audioService.mute();
  }
  unmute() {
    this.audioService.unmute();
  }

  changeSpeed(val) {
    this.audioService.changeSpeed(val);
  }

  menu() {

  }

  download() {
    this.Download.emit(true)
  }

  transcribeFile() {
    this.TranscribeFile.emit(true)
  }

  copy() {
    this.Copy.emit(true)
  }

  delete() {
    this.Delete.emit(true)
  }

  deleteAndCancel() {
    this.DeleteAndCancel.emit(true)
  }

  onSliderChangeEnd(change) {
    this.audioService.seekTo(change.value);
  }

  ngOnDestroy() {
    if (this.streamSub) {
      this.streamSub.unsubscribe()
    }
    this.clearSubscriptions()
  }
}
