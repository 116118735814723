<mat-dialog-title class="dialog_title">
    <span>
        {{ "New Card" | translate }}
    </span>
    <div class="for_space users_select">
        <span>{{ "Card belongs to workspace:" | translate }}</span>
        <mat-chip-list [multiple]="false" [selected]="false">
            <mat-chip class="chip_user" style="padding-left: 0 !important;" [value]="data.partner.id">
                <app-user [class]="'user_div'" [companyEmployee]="data?.partner" [imgRoute]="data?.imgRoute" [host]="data?.host" [company]="data?.company"></app-user>
            </mat-chip>
        </mat-chip-list>
        <span (click)="change()">
            {{ "Change" | translate }}
        </span>
    </div>
</mat-dialog-title>
<mat-dialog-content class="mat-typography">
    <div class="container form_wrapper empl_cr_task">
        <mat-horizontal-stepper labelPosition="bottom" [selectedIndex]="selectedIndex" style="background-color: transparent;" linear #stepper (selectionChange)="selectionChange($event)">
            <mat-step>
                <ng-template matStepLabel>{{ 'Add Card' | translate }}</ng-template>
                
                <form [formGroup]="form" class="form_with_valids form_with_pads">

                    <div class="white_block">
                        <div class="white_block_title">
                            {{ "Card details" | translate }}
                        </div>
                    
                        <mat-form-field appearance="standard" class="full_width_form">
                            <ng-container *ngIf="!form.get('name').valid && submited">
                                <div class="req_field"></div>
                                <div class="req_label">
                                    <div class="angle"></div>
                                    <span>
                                        {{ "You need to write here" | translate }}
                                    </span>
                                </div>
                            </ng-container>
                            <mat-label>{{ 'Card title' | translate }}</mat-label>
                            <input formControlName="name" matInput placeholder="{{ 'Name' | translate }}">
                        </mat-form-field>
                    </div>

                    <div class="white_block">
                        <div class="white_block_title">
                            {{ "Card identification" | translate }}
                        </div>
                    
                        <div class="small_chips" *ngIf="groups?.length > 0">
                            <label>{{ "Group" | translate }}: </label>
                            <mat-chip-list [multiple]="false" selectable formControlName="group_id">
                                <ng-container *ngFor="let group of groups; let i = index">
                                    <mat-chip class="small_chip" (click)="selectGroup(group)" [value]="group?.id">
                                        {{ group.name | translate }}
                                    </mat-chip>
                                    <mat-chip *ngIf="i == groups?.length - 1 && i > 6" class="small_chip more" (click)="groupIdContainer.open()">
                                        {{ "More..." | translate }}
                                    </mat-chip>
                                </ng-container>
                                
                            </mat-chip-list>
                        </div>
            
                        <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                            <mat-select #groupIdContainer formControlName="group_id" (selectionChange)="selectGroup($event)">
                                <mat-option>
                                    <ngx-mat-select-search 
                                    placeholderLabel="{{ 'Search' | translate }}" 
                                    noEntriesFoundLabel="{{ 'no matching group found' | translate }}"
                                    [formControl]="groupMoreControl"></ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let group of groups$ | async" [value]="group.id">
                                    {{ group.name | translate }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        
                        <div class="small_chips" *ngIf="statuses?.length > 0">
                            <ng-container *ngIf="!form.get('status_id').valid && submited">
                                <div class="req_field"></div>
                                <div class="req_label">
                                    <div class="angle"></div>
                                    <span>
                                        {{ "You need to choose a value" | translate }}
                                    </span>
                                </div>
                            </ng-container>
                            <label>{{ "Card type" | translate }}: </label>
                            <mat-chip-list [multiple]="false" selectable formControlName="status_id">
                                <ng-container *ngFor="let status of statuses; let i = index">
                                    <mat-chip class="small_chip" (click)="selectType(status)" [value]="status?.id">
                                        {{ status.name | translate }}
                                    </mat-chip>
                                    <mat-chip *ngIf="i == statuses?.length - 1 && i > 6" class="small_chip more" (click)="typesContainer.open()">
                                        {{ "More..." | translate }}
                                    </mat-chip>
                                </ng-container>
                                
                            </mat-chip-list>
                            <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                                <mat-select #typesContainer formControlName="status_id" (selectionChange)="selectType($event)">
                                    <mat-option>
                                        <ngx-mat-select-search 
                                        placeholderLabel="{{ 'Search' | translate }}" 
                                        noEntriesFoundLabel="{{ 'no matching type found' | translate }}"
                                        [formControl]="statusesMoreControl"></ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option *ngFor="let status of statuses$ | async" [value]="status.id">
                                        {{ status.name | translate }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="white_block white_expanded">
                        <div class="d-f-c" (click)="openRelations()">
                            <div class="white_block_title">
                                {{ "Assign relations to other card(s)" | translate }}
                            </div>
        
                            <button mat-icon-button>
                                <mat-icon *ngIf="relations">expand_less</mat-icon>
                                <mat-icon *ngIf="!relations">expand_more</mat-icon>
                            </button>
                        </div>
                        <div class="white_inside" [ngClass]="{'open': relations}">
                            <div class="search_type">
                                <mat-button-toggle-group appearance="legacy" [formControl]="typeOfSearchControl">
                                    <mat-button-toggle value="Everywhere">Everywhere</mat-button-toggle>
                                    <mat-button-toggle value="Custom ID">Custom ID</mat-button-toggle>
                                    <mat-button-toggle value="Internal ID">Internal ID</mat-button-toggle>
                                    <mat-button-toggle value="Global ID">Global ID</mat-button-toggle>
                                    <mat-button-toggle value="Task Name">Task Name</mat-button-toggle>
                                </mat-button-toggle-group>
        
                                <mat-form-field appearance="legacy" class="ml-1" *ngIf="['Everywhere', 'Custom ID', 'Task Name'].includes(typeOfSearchControl.value)">
                                    <mat-label>{{ "Group" | translate }}</mat-label>
                                    <mat-select #groupIdContainer [formControl]="groupOfSearchControl">
                                        <mat-option>
                                            <ngx-mat-select-search 
                                            placeholderLabel="{{ 'Search' | translate }}" 
                                            noEntriesFoundLabel="{{ 'no matching group found' | translate }}"
                                            [formControl]="groupMoreControl"></ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option [value]="''">
                                            {{ 'All' | translate }}
                                        </mat-option>
                                        <mat-option *ngFor="let group of groups$ | async" [value]="group.id">
                                            {{ group.name | translate }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
        
                            <ng-container formGroupName="consist_of_task_id">
                                <mat-form-field appearance="standard" class="full_width_form">
                                    <mat-label>{{ "Consist of" | translate }}</mat-label>
        
                                    <div class="chips-boundary consist_boundary">
                                        <mat-chip-list class="sorted_chips without_ordered" #consistOfChipList formControlName="add">
                                            <div class="chips_sorter_container" cdkDropListGroup #consistGroup>
                                              
                                                <div class="chips_drag_placeholder" cdkDropList (cdkDropListEntered)="entered($event, consistGroup, consistPlaceholder)" (cdkDropListDropped)="dropped(consistGroup, consistPlaceholder, form.get('consist_of_task_id').get('add').value)" #consistPlaceholder></div>
        
                                                <div *ngFor="let id of form.get('consist_of_task_id').get('add').value"  
                                                cdkDropList
                                                class="sorted_chips_drop_list"
                                                (cdkDropListEntered)="entered($event, consistGroup, consistPlaceholder)"
                                                (cdkDropListDropped)="dropped(consistGroup, consistPlaceholder, form.get('consist_of_task_id').get('add').value)">
                                                    <div cdkDrag cdkDragBoundary=".consist_boundary" class="sorted_chip" (cdkDragReleased)="dragReleased(consistPlaceholder, consistDragPlaceholder)" (contextmenu)="openContext($event, {'id': id, 'form': form, 'control': 'consist_of_task_id'}); $event.preventDefault(); $event.stopPropagation();">
                                                        <div class="sorted_chip_custom_placeholder" #consistDragPlaceholder *cdkDragPlaceholder></div>
                                                        <mat-chip [removable]="true" color="primary" (removed)="onRemoved(id, 'consist_of_task_id')">
                                                            <ng-container *ngIf="findTask(id)">
                                                                {{ findTask(id).name.length > 25 ? (findTask(id).name | slice:0:25) + " ..." : findTask(id).name }} ({{findTask(id)?.custom_id}})
                                                            </ng-container>
                                                            <mat-icon matChipRemove>cancel</mat-icon>
                                                        </mat-chip>
                                                    </div>
        
                                                </div>
        
                                                <input class="auto_complete_input"
                                                placeholder="{{ 'Search' | translate }}"
                                                #consistOfInput
                                                [formControl]="consistOfControl"
                                                [matAutocomplete]="consistOfAuto"
                                                [matChipInputFor]="consistOfChipList"
                                                [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
                                                
                                            </div>
                                        </mat-chip-list>
                                    </div>
                                    <mat-autocomplete (optionsScroll)="onScroll('consistOfControl')" autoActiveFirstOption #consistOfAuto="matAutocomplete" (optionSelected)="selected($event, consistOfInput, 'consist_of_task_id')">
                                        <ng-container *ngFor="let task of tasks$ | async">
                                            <mat-option *ngIf="!form.get('consist_of_task_id').get('add').value.includes(task.id) && (['Everywhere', 'Custom ID', 'Task Name'].includes(typeOfSearchControl.value) ? (groupOfSearchControl.value == task.group_id || groupOfSearchControl.value == '') : true)" [value]="task.id">
                                                <div class="task_ids_wrp_out">
                                                    <div class="task_ids_wrp"> 
                                                        <span class="text_one_line">
                                                            {{ task.name }}
                                                        </span>
                                                        <div class="task_ids">
                                                            {{task.custom_id}} 
                                                            <span>
                                                                <div>{{task.internal_id}}</div>
                                                                <div>{{task.id}}</div>
                                                            </span>
                                                        </div>
                                                    </div>
        
                                                    <div class="group text_one_line">
                                                        {{task?.group?.name}}
                                                    </div>
                                                </div>
                                            </mat-option>
                                        </ng-container>
                                        
                                    </mat-autocomplete>
                                </mat-form-field>
                            </ng-container>
                            
                            <ng-container formGroupName="part_of_task_id">
                                <mat-form-field appearance="standard" class="full_width_form">
                                    <mat-label>{{ "Part of" | translate }}</mat-label>
        
                                    <div class="chips-boundary part_boundary">
                                        <mat-chip-list class="sorted_chips without_ordered" #partOfChipList formControlName="add">
                                            <div class="chips_sorter_container" cdkDropListGroup #partGroup>
                                              
                                                <div class="chips_drag_placeholder" cdkDropList (cdkDropListEntered)="entered($event, partGroup, partPlaceholder)" (cdkDropListDropped)="dropped(partGroup, partPlaceholder, form.get('part_of_task_id').get('add').value)" #partPlaceholder></div>
        
                                                <div *ngFor="let id of form.get('part_of_task_id').get('add').value"  
                                                cdkDropList
                                                class="sorted_chips_drop_list"
                                                (cdkDropListEntered)="entered($event, partGroup, partPlaceholder)"
                                                (cdkDropListDropped)="dropped(partGroup, partPlaceholder, form.get('part_of_task_id').get('add').value)">
                                                    <div cdkDrag cdkDragBoundary=".part_boundary" class="sorted_chip" (cdkDragReleased)="dragReleased(partPlaceholder, partDragPlaceholder)" (contextmenu)="openContext($event, {'id': id, 'form': form, 'control': 'part_of_task_id'}); $event.preventDefault(); $event.stopPropagation();">
                                                    <div class="sorted_chip_custom_placeholder" #partDragPlaceholder *cdkDragPlaceholder></div>
                                                    <mat-chip [removable]="true" color="primary" (removed)="onRemoved(id, 'part_of_task_id')">
                                                        <ng-container *ngIf="findTask(id)">
                                                            {{ findTask(id).name.length > 25 ? (findTask(id).name | slice:0:25) + " ..." : findTask(id).name }} ({{findTask(id)?.custom_id}})
                                                        </ng-container>
                                                        <mat-icon matChipRemove>cancel</mat-icon>
                                                    </mat-chip>
                                                    </div>
        
                                                </div>
        
                                                <input class="auto_complete_input"
                                                placeholder="{{ 'Search' | translate }}"
                                                #partOfInput
                                                [formControl]="partOfControl"
                                                [matAutocomplete]="partOfAuto"
                                                [matChipInputFor]="partOfChipList"
                                                [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
                                                
                                            </div>
                                        </mat-chip-list>
                                    </div>
        
                                    <mat-autocomplete (optionsScroll)="onScroll('partOfControl')" autoActiveFirstOption #partOfAuto="matAutocomplete" (optionSelected)="selected($event, partOfInput, 'part_of_task_id')">
                                        <ng-container *ngFor="let task of tasks$ | async">
                                            <mat-option *ngIf="!form.get('part_of_task_id').get('add').value.includes(task.id) && (['Everywhere', 'Custom ID', 'Task Name'].includes(typeOfSearchControl.value) ? (groupOfSearchControl.value == task.group_id || groupOfSearchControl.value == '') : true)" [value]="task.id">
                                                <div class="task_ids_wrp_out">
                                                    <div class="task_ids_wrp"> 
                                                        <span class="text_one_line">
                                                            {{ task.name }}
                                                        </span>
                                                        <div class="task_ids">
                                                            {{task.custom_id}} 
                                                            <span>
                                                                <div>{{task.internal_id}}</div>
                                                                <div>{{task.id}}</div>
                                                            </span>
                                                        </div>
                                                    </div>
        
                                                    <div class="group text_one_line">
                                                        {{task?.group?.name}}
                                                    </div>
                                                </div>
                                            </mat-option>
                                        </ng-container>
                                        
                                    </mat-autocomplete>
                                </mat-form-field>
                            </ng-container>
        
                            <ng-container formGroupName="related_task_id">
                                <mat-form-field appearance="standard" class="full_width_form">
                                    <mat-label>{{ "Related" | translate }}</mat-label>
        
                                <div class="chips-boundary related_boundary">
                                    <mat-chip-list class="sorted_chips without_ordered" #relatedOfChipList formControlName="add">
                                        <div class="chips_sorter_container" cdkDropListGroup #relatedGroup>
                                          
                                            <div class="chips_drag_placeholder" cdkDropList (cdkDropListEntered)="entered($event, relatedGroup, relatedPlaceholder)" (cdkDropListDropped)="dropped(relatedGroup, relatedPlaceholder, form.get('related_task_id').get('add').value)" #relatedPlaceholder></div>
        
                                            <div *ngFor="let id of form.get('related_task_id').get('add').value"  
                                            cdkDropList
                                            class="sorted_chips_drop_list"
                                            (cdkDropListEntered)="entered($event, relatedGroup, relatedPlaceholder)"
                                            (cdkDropListDropped)="dropped(relatedGroup, relatedPlaceholder, form.get('related_task_id').get('add').value)">
                                                <div cdkDrag cdkDragBoundary=".related_boundary" class="sorted_chip" (cdkDragReleased)="dragReleased(relatedPlaceholder, relatedDragPlaceholder)" (contextmenu)="openContext($event, {'id': id, 'form': form, 'control': 'related_task_id'}); $event.preventDefault(); $event.stopPropagation();">
                                                    <div class="sorted_chip_custom_placeholder" #relatedDragPlaceholder *cdkDragPlaceholder></div>
                                                    <mat-chip [removable]="true" color="primary" (removed)="onRemoved(id, 'related_task_id')">
                                                        <ng-container *ngIf="findTask(id)">
                                                            {{ findTask(id).name.length > 25 ? (findTask(id).name | slice:0:25) + " ..." : findTask(id).name }} ({{findTask(id)?.custom_id}})
                                                        </ng-container>
                                                        <mat-icon matChipRemove>cancel</mat-icon>
                                                    </mat-chip>
                                                </div>
        
                                            </div>
        
                                            <input class="auto_complete_input"
                                            placeholder="{{ 'Search' | translate }}"
                                            #relatedOfInput
                                            [formControl]="relativeControl"
                                            [matAutocomplete]="relatedOfAuto"
                                            [matChipInputFor]="relatedOfChipList"
                                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
                                            
                                        </div>
                                    </mat-chip-list>
                                </div>
        
                                    <mat-autocomplete (optionsScroll)="onScroll('relativeControl')" autoActiveFirstOption #relatedOfAuto="matAutocomplete" (optionSelected)="selected($event, relatedOfInput, 'related_task_id')">
                                        <ng-container *ngFor="let task of tasks$ | async">
                                            <mat-option *ngIf="!form.get('related_task_id').get('add').value.includes(task.id) && (['Everywhere', 'Custom ID', 'Task Name'].includes(typeOfSearchControl.value) ? (groupOfSearchControl.value == task.group_id || groupOfSearchControl.value == '') : true)" [value]="task.id">
                                                <div class="task_ids_wrp_out">
                                                    <div class="task_ids_wrp"> 
                                                        <span class="text_one_line">
                                                            {{ task.name }}
                                                        </span>
                                                        <div class="task_ids">
                                                            {{task.custom_id}} 
                                                            <span>
                                                                <div>{{task.internal_id}}</div>
                                                                <div>{{task.id}}</div>
                                                            </span>
                                                        </div>
                                                    </div>
        
                                                    <div class="group text_one_line">
                                                        {{task?.group?.name}}
                                                    </div>
                                                </div>
                                            </mat-option>
                                        </ng-container>
                                        
                                    </mat-autocomplete>
                                </mat-form-field>
                            </ng-container>
                        </div>
                    </div>
                </form>
            </mat-step>
            <mat-step>
                <ng-template matStepLabel>{{ 'Add work' | translate }}</ng-template>
                <form [formGroup]="form" class="form_with_valids form_with_pads" style="margin-top: 22px;">
        
                    <ng-container formGroupName="task_operation">
                        <div class="white_block">
                            <div class="white_block_title">
                                {{ "Job identification" | translate }}
                            </div>

                            <mat-form-field appearance="standard" class="full_width_form">
                                <mat-label>{{ 'My notes on this job' | translate }}</mat-label>
                                <textarea formControlName="employee_comment" matInput placeholder="{{ 'My notes on this job' | translate }}"
                                cdkTextareaAutosize
                                #autosize="cdkTextareaAutosize"
                                cdkAutosizeMinRows="1"
                                cdkAutosizeMaxRows="5"></textarea>
                            </mat-form-field>

                            <div class="small_chips" *ngIf="operations?.length > 0">
                                <ng-container *ngIf="!form.get('task_operation').get('operation_id').valid && submitedWork">
                                    <div class="req_field"></div>
                                    <div class="req_label">
                                        <div class="angle"></div>
                                        <span>
                                            {{ "You need to choose a value" | translate }}
                                        </span>
                                    </div>
                                </ng-container>
                                <label>{{ "Job type" | translate }}: </label>
                                <mat-chip-list [multiple]="false" selectable formControlName="operation_id">
                                    <ng-container *ngFor="let operation of operations; let i = index">
                                        <mat-chip class="small_chip" (click)="selectWorkType(operation)" [value]="operation?.id">
                                            {{ operation.name | translate }}
                                        </mat-chip>
                                        <mat-chip *ngIf="i == operations?.length - 1 && i > 6" class="small_chip more" (click)="operationIdContainer.open()">
                                            {{ "More..." | translate }}
                                        </mat-chip>
                                    </ng-container>
                                    
                                </mat-chip-list>
                            </div>
                
                            <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                                <mat-select #operationIdContainer formControlName="operation_id" (selectionChange)="selectWorkType($event)">
                                    <mat-option>
                                        <ngx-mat-select-search 
                                        placeholderLabel="{{ 'Search' | translate }}" 
                                        noEntriesFoundLabel="{{ 'no matching job type found' | translate }}"
                                        [formControl]="operationMoreControl"></ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option *ngFor="let operation of operations$ | async" [value]="operation.id">
                                        {{ operation.name | translate }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                
                            <div class="small_chips" *ngIf="operationStatuses?.length > 0">
                                <ng-container *ngIf="!form.get('task_operation').get('status_id').valid && submitedWork">
                                    <div class="req_field"></div>
                                    <div class="req_label">
                                        <div class="angle"></div>
                                        <span>
                                            {{ "You need to choose a value" | translate }}
                                        </span>
                                    </div>
                                </ng-container>
                                <label>{{ "Status" | translate }}: </label>
                                <mat-chip-list [multiple]="false" selectable formControlName="status_id">
                                    <ng-container *ngFor="let status of operationStatuses; let i = index">
                                        <mat-chip class="small_chip" (click)="selectStatus(status)" [value]="status?.id" [ngClass]="{
                                            'first': status.id == '1',
                                            'second': status.id == '2',
                                            'third': status.id == '3',
                                            'fours': status.id == '4',
                                            'fives': status.id == '5',
                                            'sixs': status.id == '6',
                                            'nine': status.id == '97',
                                            'seven': status.id == '98',
                                            'eight': status.id == '99'}">
                                            {{ status.name | translate }}
                                        </mat-chip>
                                        <mat-chip *ngIf="i == operationStatuses?.length - 1 && i > 6" class="small_chip more" (click)="showMoreStatuses()">
                                            {{ "More..." | translate }}
                                        </mat-chip>
                                    </ng-container>
                                    
                                </mat-chip-list>
                            </div>
                        </div>

                        <div class="white_block">
                            <div class="white_block_title">
                                {{ "Job details" | translate }}
                            </div>
                        
                            <mat-form-field appearance="standard" class="full_width_form" *ngIf="!!form.get('task_operation').get('completed_at')">
                                <mat-label>{{ "Date I finished working on this job" | translate }}</mat-label>
                                <input matInput [matDatepicker]="dp" formControlName="completed_at">
                                <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
                                <mat-datepicker #dp></mat-datepicker>
                            </mat-form-field>
                    
                            <mat-form-field *ngIf="form.get('task_operation').get('rate')" appearance="standard" class="full_width_form">
                                <mat-label>{{ 'Points I charge for this job' | translate }}</mat-label>
                                <input formControlName="rate" matInput type="number" placeholder="{{ 'Points I charge for this job' | translate }}">
                                <div class="cur_suffix" matSuffix>PTS</div>
                            </mat-form-field>
                    
                            <mat-form-field appearance="standard" class="full_width_form" *ngIf="form.get('task_operation').get('price')">
                                <mat-label>{{ 'Price I charge for this job' | translate }}</mat-label>
                                <input formControlName="price" matInput type="number" placeholder="{{ 'Price I charge for this job' | translate }}">
                                <div class="cur_suffix" matSuffix>{{ getCurrency(data?.company?.employees[0]?.currency_id)?.iso_name }}</div>
                            </mat-form-field>
                        </div>


                    </ng-container>

                </form>
            </mat-step>
        </mat-horizontal-stepper>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <ng-container *ngIf="selectedIndex == 0; else elseTemplateIndex">
        <div class="f_w_p_btns">
            <button mat-button (click)="close()">{{ "Close" | translate }}</button>
            <button mat-raised-button type="button" color="primary" class="ml-1" (click)="goToAddWork()">{{ "Next" | translate }}</button>
        </div>
    </ng-container>
    <ng-template #elseTemplateIndex>    
        <div class="f_w_p_btns">
            <button mat-raised-button (click)="selectedIndex = 0">
                {{ "Back" | translate }}
            </button>
            <button class="ml-1 d-f-btn" (click)="submitForm()" mat-raised-button cdkFocusInitial color="primary" [disabled]="isSubmit">
                <mat-spinner class="spin_16 mr-1" *ngIf="isSubmit"></mat-spinner>  
                {{ "Save" | translate }}
            </button>
        </div> 
    </ng-template>
</mat-dialog-actions>

<ng-template #contextMenu let-contextdata>
	<section class="context-menu">
        <div *ngIf="contextdata.control != 'consist_of_task_id'" (click)="moveTo(contextdata, 'consist_of_task_id')">
            <mat-icon class="mr-1">swipe_up_alt</mat-icon>
            {{ 'Move to Consist Of' | translate }}
        </div>
        <div *ngIf="contextdata.control != 'part_of_task_id'" (click)="moveTo(contextdata, 'part_of_task_id')">
            <mat-icon class="mr-1" *ngIf="contextdata.control == 'related_task_id'">swipe_up_alt</mat-icon>
            <mat-icon class="mr-1" *ngIf="contextdata.control == 'consist_of_task_id'">swipe_down_alt</mat-icon>
            {{ 'Move to Part Of' | translate }}
        </div>
        <div *ngIf="contextdata.control != 'related_task_id'" (click)="moveTo(contextdata, 'related_task_id')">
            <mat-icon class="mr-1">swipe_down_alt</mat-icon>
            {{ 'Move to Related' | translate }}
        </div>
        <div class="menu_divider"></div>
        <div (click)="openCard(contextdata)">
            <mat-icon class="mr-1">open_in_new</mat-icon>
            {{ 'Open Card' | translate }}
        </div>
        <div class="menu_divider"></div>
        <div (click)="remove(contextdata)">
            <mat-icon class="mr-1">delete</mat-icon>
            {{ 'Remove' | translate }}
        </div>
	</section>
</ng-template>