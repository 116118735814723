<h2 mat-dialog-title class="dialog_title">
    <span>
        {{ "Edditing a work" | translate }}: {{data?.task?.name}}<b>({{data?.task?.custom_id}})</b>
    </span>
    <button mat-button (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h2>
<mat-dialog-content class="mat-typography" dirEnter (dirSubmit)="!form.valid || noChat ? null : onSubmit()">
    <div class="container form_wrapper">
        <form [formGroup]="form">

            <div class="custom_radio">
                <label>{{ "Choose a status" | translate }}: </label>
                <mat-radio-group required formControlName="status_id">
                  <mat-radio-button color="primary" *ngFor="let status of operationStatuses" [value]="status.id" 
                  [ngClass]="{
                    'first': status.id == '1',
                    'second': status.id == '2',
                    'third': status.id == '3',
                    'fours': status.id == '4',
                    'fives': status.id == '5',
                    'sixs': status.id == '6',
                    'nine': status.id == '97',
                    'seven': status.id == '98',
                    'eight': status.id == '99'}">{{ status.name | translate }}</mat-radio-button>
                </mat-radio-group>
            </div>   
            <ng-container *ngIf="isChangeRate">
                <mat-form-field appearance="standard" class="full_width_form">
                    <mat-label>{{ 'Points' | translate }}</mat-label>
                    <input cdkFocusInitial formControlName="rate" matInput type="number" placeholder="{{ 'Points' | translate }}">
                </mat-form-field>
        
                <mat-form-field appearance="standard" class="full_width_form">
                    <mat-label>{{ 'Price' | translate }}</mat-label>
                    <input formControlName="price" matInput type="number" placeholder="{{ 'Price' | translate }}">
                </mat-form-field>
            </ng-container>
            
            <div class="content_link_info" *ngIf="data.work.content_url">
                {{ "The employee marked the work with the status 'To Approve' and attached a link to the content: " | translate }}
                <div>
                    <a target="_blank" href="{{data.work.content_url}}">{{data.work.content_url}}</a>
                </div>
            </div>
        </form>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end" style="margin-top: 5px;">
    <button mat-raised-button (click)="close()">
        {{ "Back" | translate }}
    </button>
    <button class="ml-1" (click)="onSubmit()" mat-raised-button cdkFocusInitial color="primary" [disabled]="!form.valid || noChat">
        {{ "Save" | translate }}
    </button>
</mat-dialog-actions>
