<div mat-dialog-title class="mobile_interface_title">
    <div class="mobile_interface_title_in" style="overflow: hidden;">
        <div class="tab_title" (click)="close()">
            <div class="d-f-c">
                <div class="icon_back">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7 12L6.11612 11.1161C5.62796 11.6043 5.62796 12.3957 6.11612 12.8839L7 12ZM16.8839 3.88388C17.372 3.39573 17.372 2.60427 16.8839 2.11612C16.3957 1.62796 15.6043 1.62796 15.1161 2.11612L16.8839 3.88388ZM15.1161 21.8839C15.6043 22.372 16.3957 22.372 16.8839 21.8839C17.372 21.3957 17.372 20.6043 16.8839 20.1161L15.1161 21.8839ZM7.88388 12.8839L16.8839 3.88388L15.1161 2.11612L6.11612 11.1161L7.88388 12.8839ZM6.11612 12.8839L15.1161 21.8839L16.8839 20.1161L7.88388 11.1161L6.11612 12.8839Z" fill="#232323"/>
                    </svg>
                </div>
                <span>
                    {{ "Assignments" | translate }}
                </span>
                <span class="small_title with_i" style="text-transform: uppercase;">{{ "Interface under development" | translate }}</span>
            </div>
        </div>
    </div>
</div>
<mat-dialog-content class="mobile_interface_content">
    <div class="mobile_scrollable_int_big">
        <app-executors class="" [appDeviceClass]="'custom-scroll custom-scroll-t'" [addItem]="addExecutorSub" [initData]="{
            company: data.company,
            user: data.user,
            task: data.task,
            activeLang: data.activeLang,
            job: data.work,
            is_mob: true,
            statuses: data.statuses,
            host: data.host,
            empl_status: data.empl_status,
            operationsValues: data.operationsValues
        }"
        (ActUser)="actUser($event)"
        (Update)="update()"></app-executors>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <div class="mobile_interface_buttons" style="justify-content: space-between !important;">
        <ng-container *ngIf="selectedExecOut; else elseTemplateIsSelected">
            <button class="mobile_interface_btn" (click)="back()">
                {{ "Back" | translate }}
            </button>

            <button type="button" class="mobile_interface_btn mobile_interface_btn_white" (click)="delete()">
                {{"Delete" | translate}}
            </button>
        </ng-container>
        <ng-template #elseTemplateIsSelected>
            <button class="mobile_interface_btn" (click)="close()">
                {{ "Close" | translate }}
            </button>
            
    
            <div class="add_assign_btn_wrp">
                <button type="button">
                    <svg style="width: 18px; height: 18px;" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.5 9.9H9.9V13.5H8.1V9.9H4.5V8.1H8.1V4.5H9.9V8.1H13.5M9 0C7.8181 0 6.64778 0.232792 5.55585 0.685084C4.46392 1.13738 3.47177 1.80031 2.63604 2.63604C0.948211 4.32387 0 6.61305 0 9C0 11.3869 0.948211 13.6761 2.63604 15.364C3.47177 16.1997 4.46392 16.8626 5.55585 17.3149C6.64778 17.7672 7.8181 18 9 18C11.3869 18 13.6761 17.0518 15.364 15.364C17.0518 13.6761 18 11.3869 18 9C18 7.8181 17.7672 6.64778 17.3149 5.55585C16.8626 4.46392 16.1997 3.47177 15.364 2.63604C14.5282 1.80031 13.5361 1.13738 12.4442 0.685084C11.3522 0.232792 10.1819 0 9 0Z" fill="#A3A3A3"/>
                    </svg>
                </button>
                <button type="button" (click)="addExec('executor')">
                    {{"Executor" | translate}}
                </button>
                <button type="button" (click)="addExec('assist')">
                    {{"Assitant" | translate}}
                </button>
                <button type="button" [matMenuTriggerFor]="menu">
                    {{"Client" | translate}}
                </button>
                <mat-menu #menu="matMenu" yPosition="above" xPosition="before">
                    <button mat-menu-item (click)="addExec('client')">{{"Individual" | translate}}</button >
                    <button mat-menu-item (click)="addExec('partner_client')">{{"Workspace" | translate}}</button>
                </mat-menu>
    
            </div>
        </ng-template>
        
        <!-- <button class="mobile_interface_btn mobile_interface_btn_blue" (click)="onSubmit()">
            {{ "Save" | translate }}
        </button> -->
    </div>
</mat-dialog-actions>