<h2 mat-dialog-title class="dialog_title">
    <span (click)="log()">
        {{ (data.only ? "Select Card" : "Select Card for Plan") | translate }}:
    </span>
    <button class="ml-1" mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h2>
<mat-dialog-content class="mat-typography" dirEnter (dirSubmit)="!form.valid ? null : onSubmit()">
    <div class="container form_wrapper">
        <form [formGroup]="form">
            <div class="search_type">
                <mat-button-toggle-group appearance="legacy" [formControl]="typeOfSearchControl">
                    <mat-button-toggle value="Everywhere">Everywhere</mat-button-toggle>
                    <mat-button-toggle value="Custom ID">Custom ID</mat-button-toggle>
                    <mat-button-toggle value="Internal ID">Internal ID</mat-button-toggle>
                    <mat-button-toggle value="Global ID">Global ID</mat-button-toggle>
                    <mat-button-toggle value="Task Name">Card Name</mat-button-toggle>
                </mat-button-toggle-group>

                <mat-form-field appearance="legacy" class="ml-1" *ngIf="['Everywhere', 'Custom ID', 'Task Name'].includes(typeOfSearchControl.value)">
                    <mat-label>{{ "Group" | translate }}</mat-label>
                    <mat-select #groupIdContainer [formControl]="groupOfSearchControl">
                        <mat-option>
                            <ngx-mat-select-search 
                            placeholderLabel="{{ 'Search' | translate }}" 
                            noEntriesFoundLabel="{{ 'no matching group found' | translate }}"
                            [formControl]="groupMoreControl"></ngx-mat-select-search>
                        </mat-option>
                        <mat-option [value]="''">
                            {{ 'All' | translate }}
                        </mat-option>
                        <mat-option *ngFor="let group of groups$ | async" [value]="group.id">
                            {{ group.name | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            
            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ "Select card" | translate }}</mat-label>
                <!-- (selectionChange)="selectValue($event)" -->
                <mat-select [disableOptionCentering]="true" selectDir formControlName="task_id" msInfiniteScroll (infiniteScroll)="onScroll($event, 'taskControl')">
                    <mat-select-trigger>
                        <div *ngIf="selectedTask" class="text_one_line">
                            {{selectedTask.name}}
                        </div>
                    </mat-select-trigger>
                    <mat-option>
                        <ngx-mat-select-search 
                        placeholderLabel="{{ 'Search' | translate }}" 
                        noEntriesFoundLabel="{{ 'no matching card found' | translate }}"
                        [formControl]="taskControl"></ngx-mat-select-search>
                    </mat-option>
    
                    <mat-option *ngFor="let task of tasks" [value]="task.id" (click)="selectCard(task)">
                        <div class="task_item">
                            <div class="task_ids_wrp"> 
                                {{ task.name }}
                                <div class="task_ids">
                                    {{task.custom_id}} 
                                    <span>
                                        <div>{{task.internal_id}}</div>
                                        <div>{{task.id}}</div>
                                    </span>
                                </div>
                            </div>
                            <div class="plans" *ngIf="!data.only && task.contentPlans && task.contentPlans.length">
                                <div class="plan d-f-c" *ngFor="let item of task.contentPlans">
                                    <app-outlet-line [class]="'mini'" [channel]="item.channel" [company_id]="data.company.id"></app-outlet-line>
                                    <span class="type" *ngIf="!!item?.contentType?.name">{{item?.contentType?.name}}</span>
                                    <span class="date">{{(!!item.content_published_at ? item.content_published_at : item.publish_at) * 1000 | date:'MMM d, y, hh:mm a':'':data.activeLang}}</span>
                                </div>
                            </div>
                        </div>
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <ng-container *ngIf="!!form.get('task_channel_id') && !!selectedTask">
                <mat-form-field appearance="standard" class="full_width_form h_cst_t">
                    <mat-label>{{ "Select channel" | translate }}</mat-label>
                    <!-- (selectionChange)="selectValue($event)" -->
                    <mat-select [disableOptionCentering]="true" selectDir formControlName="task_channel_id">
                        <mat-select-trigger>
                            <div *ngIf="selectedChannel" class="d-f-c">
                                <app-outlet-line [channel]="selectedChannel.channel" [company_id]="data.company.id"></app-outlet-line>
                                <span class="text_one_line">
                                    {{selectedChannel?.name}}
                                </span>
                            </div>
                        </mat-select-trigger>
        
                        <mat-option *ngFor="let channel of filteredChannels(selectedTask.channels)" [value]="channel.id" (click)="selectChannel(channel)" class="cst_o">
                            <div class="d-f-c">
                                <app-outlet-line [channel]="channel.channel" [company_id]="data.company.id"></app-outlet-line>
                                <span>
                                    {{channel?.name}}
                                </span>
                            </div>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </ng-container>
            
        </form>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button (click)="close()">
        {{ "Back" | translate }}
    </button>
    <button class="ml-1" (click)="onSubmit()" mat-raised-button cdkFocusInitial color="primary" [disabled]="!form.valid">
        {{ "Save" | translate }}
    </button>
</mat-dialog-actions>
