import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReplaySubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { AuthService } from 'src/app/shared/services/rest/auth.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-profile-create',
  templateUrl: './profile-create.component.html',
  styleUrls: ['./profile-create.component.scss']
})
export class ProfileCreateComponent extends BaseClass implements OnInit, OnDestroy {
  public host: any = environment.host;
  public imgRoute: any = '';
  public user: any = {};
  public user_id: number;
  public platforms: any;
  public form: FormGroup;
  public isSubmit: boolean = false;
  public isFormChange: boolean = false;

  public profilesMoreControl: FormControl = new FormControl();
  public platforms$: ReplaySubject<any> = new ReplaySubject<any>(1);
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ProfileCreateComponent>,
    private layoutService: LayoutService,
    private sm: StorageManagerService,
    private auth: AuthService,
    private companyService: CompanyService,
    private fb: FormBuilder,
  ) { super() }

  ngOnInit(): void {
    console.log("ProfileCreateComponent", this.data)
    this.getCompany();
    this.getImgRoute();
    this.getCsrf();
    this.getUser();

    this.attachSubscriptions(
      this.profilesMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchProfiles(resp))
    )

    this.form = this.fb.group({
      company_id: this.data.company_id,
      group_id: this.data.group ? this.data.group.id : '',
      platform_id: ['', Validators.required],
      name: ['', Validators.required],
      user_email: ['', Validators.email],
      url: ['', Validators.required],
      ai_description: ''
    })

    this.attachSubscriptions(
      this.form.get('platform_id').valueChanges.subscribe(resp => {
        if (resp == 1 && !this.form.get('publishing_params')) {
          this.form.addControl('publishing_params', this.fb.group({
            made_for_kids: false
          }))
        } else {
          this.form.removeControl('publishing_params')
        }

        console.log("form valueChanges", this.form);
      })
    )

    if (this.data.platform_id) {
      this.form.patchValue({
        platform_id: this.data.platform_id
      })
    }

    this.attachSubscriptions(
      this.form.valueChanges.subscribe(() => this.isFormChange = true)
    )

    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        if (this.isFormChange) {
          this.layoutService.openBottomSheet(this.dialogRef);
        } else {
          this.close();
        }
      })
    )

    if (!!this.data.account) {
      this.form.patchValue({
        name: this.data.account.name,
        url: this.data.account.computedUrl
      })
    }

    this.getPlatforms();

  }

  getCompany() {
    this.attachSubscriptions(
      this.companyService.getCompany(this.data.company_id).subscribe(resp => {
        this.companyService.company$.next(resp[0]);
      })
    )
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  getCsrf() {
    this.attachSubscriptions(
      this.auth.$userToken.subscribe(resp => {
        this.user_id = resp.user_id;
        this.sm.localStorageSetItem("csrf_param", resp.csrf_param)
        this.sm.localStorageSetItem("csrf_token", resp.csrf_token)
      })
    )
  }

  getUser() {
    this.attachSubscriptions(
      this.auth.$user.subscribe(resp => {
        this.user = resp;
      })
    )
  }

  getPlatforms() {
    this.attachSubscriptions(
      this.companyService.getGroupPlatforms().subscribe(resp => {
        console.log("getPlatforms", resp)
        this.platforms = resp;
        this.platforms$.next(this.platforms.slice());
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  onSearchProfiles(resp) {
    if (!this.platforms) {
      return;
    }

    if (!resp) {
      this.platforms$.next(this.platforms.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }

    // filter the banks
    this.platforms$.next(
      this.platforms.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  onSubmit() {
    this.isSubmit = true;
    this.attachSubscriptions(
      this.companyService.createCompanyGroupProfile(this.form.value).subscribe(resp => {
        this.isSubmit = false;
        this.dialogRef.close({event: "update", data: resp});
      }, error => {
        this.isSubmit = false;
        this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
      })
    )
  }
  
  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
