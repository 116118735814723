import { animate, state, style, transition, trigger } from "@angular/animations";
import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from "@angular/material-moment-adapter";
import { MatButtonToggleChange } from "@angular/material/button-toggle";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatTable } from '@angular/material/table';
import { Router } from '@angular/router';
import { marker } from "@biesbjerg/ngx-translate-extract-marker";
import * as moment from 'moment';
import { Moment } from 'moment';
import { platform } from 'process';
import { forkJoin, fromEvent, of, ReplaySubject, Subscription } from 'rxjs';
import { catchError, debounceTime, finalize, last, map, switchMap, tap } from 'rxjs/operators';
import { MY_FORMATS } from "src/app/components/atTasksDialog/task-profile-add/task-profile-add.component";
import { ChatsComponent } from "src/app/components/chats/chats.component";
import { OpenJobComponent } from "src/app/components/workspace-pages/cases/dialogs/open-job/open-job.component";
import { OpenTaskComponent } from "src/app/components/workspace-pages/cases/dialogs/open-task/open-task.component";
import { environment } from 'src/environments/environment';
import { casesModel } from "../../functions/casesModel";
import { makeRandomString } from '../../functions/randomString';
import { BaseClass } from '../../models/base-class';
import { GlobalDataService } from '../../services/common/global-data.service';
import { LanguageService } from '../../services/common/language.service';
import { StorageManagerService } from '../../services/common/storage-manager.service';
import { ActivityService } from '../../services/rest/activity.service';
import { ChatService } from '../../services/rest/chat.service';
import { CompanyService } from '../../services/rest/company.service';
import { FileService } from '../../services/rest/file.service';
import { TaskService } from '../../services/rest/task.service';

@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})

export class ActivityComponent extends BaseClass implements OnInit, OnDestroy {
  public employeesMoreControl: FormControl = new FormControl();
  public employees$: ReplaySubject<any> = new ReplaySubject<any>(1);

  throttle = 100;
  scrollUpDistance = 3.5;
  public tab = 0;
  public filter: FormGroup = this.fb.group({
    employee_id: 0,
    partner_company_id: 0
  });

  public activeFilterId: FormControl = new FormControl(0);
  public filters = [
    {
      id: 0,
      name: "Today so far",
      divider: false,
      from: moment().startOf('day'),
      to: 0
    },
    {
      id: 1,
      name: "Yesterday",
      divider: false,
      from: moment().subtract(1, 'd').startOf('day'),
      to: moment().subtract(1, 'd').endOf('day')
    },
    {
      id: 2,
      name: "Day before yesterday",
      divider: true,
      from: moment().subtract(2, 'd').startOf('day'),
      to: moment().subtract(2, 'd').endOf('day')
    },
    {
      id: 3,
      name: "This week so far",
      divider: false,
      from: moment().startOf('week'),
      to: 0
    },
    {
      id: 4,
      name: "Previos week",
      divider: false,
      from: moment().subtract(1, 'week').startOf('week'),
      to: moment().subtract(1, 'week').endOf('week')
    },
    {
      id: 5,
      name: "This month so far",
      divider: false,
      from: moment().startOf('month'),
      to: 0
    },
    {
      id: 6,
      name: "Previos month",
      divider: true,
      from: moment().subtract(1, 'month').startOf('month'),
      to: moment().subtract(1, 'month').endOf('month')
    },
    {
      id: 7,
      name: "This year so far",
      divider: false,
      from: moment().startOf('year'),
      to: 0
    },
    {
      id: 8,
      name: "Previos year",
      divider: false,
      from: moment().subtract(1, 'year').startOf('year'),
      to:  moment().subtract(1, 'year').endOf('year')
    }
  ]

  public groupedByPlatform: any;
  public groupedByStatus: any;
  public editGroupedByStatus: any;

  public isLoad: boolean = false;
  public totals: any = this.data.totals;
  public activity: any = this.data.activity;
  public pagination: any = this.data.pagination;
  public page: any = 2;


  // NOTIFICATIONS TAB
  @ViewChild("not_container") not_container: ElementRef;
  @ViewChild(MatTable) table: MatTable<any>;
  public notEmployees$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public notFilter: FormGroup = this.fb.group({
    group_id: '',
    created_employee_id: '',
    action: '',
    original_action_id: '',
    created_at_from: '',
    created_at_to: '',
    task_operation_operation_id: '',
    task_operation_status_id: ''
  });
  public is_filter_active: boolean = false;
  public notPagination: any;
  public notifications: any;
  public operationStatuses: any;

  public execControl: FormControl = new FormControl();
  public groupsControl: FormControl = new FormControl();
  public groups$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public notPage: any = 1;
  public activeLang: any;
  public actions: any;
  public imgRoute: any = '';
  public host: any = environment.host;
  public timeZone: any;
  public allContentTypes: any;
  public employees: any;
  public pageByScroll$: Subscription;
  public contentPage: number = 1;
  public contentPagination: any;
  public groups: any;
  public is_mobile: boolean;
  public taskStatuses: any;
  public profileStatuses: any;
  public profiles: any[] = [];
  public dragPosition = {x: 0, y: 0};
  public displayedColumns: string[] = ["Date", "User", "Info", "Job"];
  public todaysDate = new Date().setHours(0,0,0,0);
  public randomString = makeRandomString();
  public operations: any;
  public hasSound: boolean = this.sm.localStorageGetItem("hasSound") == "0" ? false : true
  constructor(
    private taskService: TaskService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ActivityComponent>,
    private router: Router,
    private globalDataService: GlobalDataService,
    private companyService: CompanyService,
    private sm: StorageManagerService,
    private activityService: ActivityService,
    private dialog: MatDialog,
    private languageService: LanguageService,
    private chatService: ChatService,
    private fileService: FileService,
    private _adapter: DateAdapter<any>,
    private fb: FormBuilder
  ) { super() }

  ngOnInit(): void {
    console.log("ActivityComponent", this.data)
    this.getPlatforms()
    this.employees$.next(this.data.employees.slice())

    this.attachSubscriptions(
      this.employeesMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchEmoloyees(resp))
    )
      
    // NOTIFICATIONS TAB
    this.notEmployees$.next(this.data.employees.slice())
    this.attachSubscriptions(
      this.groupsControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchGroups(resp))
    )

    this.attachSubscriptions(
      this.execControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchNotEmoloyees(resp))
    )
    this.getOperationsStatus();

    this.checkIsMobile();
    this.getAllActions();
    this.getImgRoute();

    this.attachSubscriptions(
      this.languageService.getLangData().subscribe(resp => {
        this.activeLang = resp.active;
        this._adapter.setLocale(resp.active);
        this.getOperations();
      })
    )

    this.getGroupsCompany();
    this.getProfiles();
    this.getAllContentTypes();
    this.getTaskStatus();
    this.getProfilesStatus();
    this.getActions();
    this.timeZone = new Date().getTimezoneOffset();
  }

  onDown(e) {
    console.log("SCROLL DOWN", e);
    if (this.pagination['pageCount'] >= this.page) {
      console.log("getNextProfiles")
      this.getActivity(this.page);
    }
  }

  selectChange(e) {
    this.filterActivity();
  }

  log() {
    console.log("this.notifications", this.notifications)
    console.log("this.allContentTypes", this.allContentTypes)
  }

  filterActivity() {
    this.page = 1;
    this.pagination = undefined;
    this.totals = undefined;
    console.log("filterActivity")

    if (this.groupedByPlatform && this.groupedByPlatform.length) {
      this.groupedByPlatform.forEach(element => {
        element.is_added_open = false;
        element.is_added_dr_post_open = false;
        element.is_added_sc_post_open = false;
        element.is_added_pu_post_open = false;

        element.channels.forEach(el => {
          // el.employeeData = {}
        });
      });

      console.log("this.groupedByPlatform 2", this.groupedByPlatform)
    }
    this.is_filter_active = false;
    this.getActivity(this.page);
  }

  getPlatforms() {
    this.attachSubscriptions(
      this.companyService.getGroupPlatforms().pipe(
        switchMap(platforms => {
          return forkJoin(platforms.map(x => this.companyService.getFilteredProfiles(this.data.company.id, '1', {platform_id: x.id}).pipe(tap(u => x.channels = u.body)))).pipe(map(() => platforms))
        }),
        switchMap(platforms => {
          return this.globalDataService.getContentStatus().pipe(
            tap(x => {
              this.groupedByStatus = x.slice()
              this.editGroupedByStatus = x.slice()
            }),
            map(() => platforms)
          )
        })
      ).subscribe(resp => {
        this.groupedByPlatform = resp
        this.groupedByStatus.forEach(el => {
          el.groupedByPlatform = JSON.parse(JSON.stringify(this.groupedByPlatform))
        })
        this.editGroupedByStatus.forEach(el => {
          el.groupedByPlatform = JSON.parse(JSON.stringify(this.groupedByPlatform))
        })
        this.activity.forEach(el => {
          el.groupedByPlatform = JSON.parse(JSON.stringify(this.groupedByPlatform))
          el.groupedByStatus = JSON.parse(JSON.stringify(this.groupedByStatus))
          el.editGroupedByStatus = JSON.parse(JSON.stringify(this.editGroupedByStatus))
        })
        console.log("this.groupedByPlatform", this.groupedByPlatform)
      })
    )
  }

  openOutletsAdded(item) {
    item.is_post_outlets = !item.is_post_outlets

    if (!!item.is_post_outlets) {
      let filterData:any = {
        analytics: moment().subtract(7, 'd').isSameOrAfter(this.filters[this.activeFilterId.value].from.clone()) ? 'by_day' : 'by_hour',
        start_time_from: this.filters[this.activeFilterId.value].from.clone().format("X"),
        query_type: 'subtotals',
        page: '1'
      }
      if (item.partner_company_id) {
        filterData.partner_company_id = item.partner_company_id
      } else {
        filterData.employee_id = item.employee_id
      }
      this.attachSubscriptions(
        this.activityService.getActivity(this.data.company.id, Object.assign({...filterData}, {
          platform_id: "group",
        })).pipe(map(x => x.body)).subscribe(resp => {
          resp.forEach(element => {
            if (item.groupedByPlatform.find(x => x.id == element.platform_id)) {
              item.groupedByPlatform.find(x => x.id == element.platform_id).openOutletsAddedData = element
            }
          });
          console.log("openOutletsAdded", resp)
          console.log("openOutletsAdded ITEM", item)
        })
      )
    }
  }


  getItemPlatforms(item, key, objName) {
    item[key] = !item[key]

    if (!!item[key]) {
      let filterData:any = {
        analytics: moment().subtract(7, 'd').isSameOrAfter(this.filters[this.activeFilterId.value].from.clone()) ? 'by_day' : 'by_hour',
        start_time_from: this.filters[this.activeFilterId.value].from.clone().format("X"),
        query_type: 'subtotals',
        page: '1'
      }
      if (item.partner_company_id) {
        filterData.partner_company_id = item.partner_company_id
      } else {
        filterData.employee_id = item.employee_id
      }
      this.attachSubscriptions(
        this.activityService.getActivity(this.data.company.id, Object.assign({...filterData}, {
          platform_id: "group",
        })).pipe(map(x => x.body)).subscribe(resp => {
          resp.forEach(element => {
            if (item.groupedByPlatform.find(x => x.id == element.platform_id)) {
              item.groupedByPlatform.find(x => x.id == element.platform_id)[objName] = element
            }
          });
          console.log("openOutletsAdded", resp)
          console.log("openOutletsAdded ITEM", item)
        })
      )
    }
  }

  getItemPlatformChannels(item, platform, key, objName) {
    platform[key] = !platform[key]

    if (!!platform[key]) {
      let filterData:any = {
        analytics: moment().subtract(7, 'd').isSameOrAfter(this.filters[this.activeFilterId.value].from.clone()) ? 'by_day' : 'by_hour',
        start_time_from: this.filters[this.activeFilterId.value].from.clone().format("X"),
        query_type: 'subtotals',
        page: '1'
      }
      if (item.partner_company_id) {
        filterData.partner_company_id = item.partner_company_id
      } else {
        filterData.employee_id = item.employee_id
      }
      this.attachSubscriptions(
        this.activityService.getActivity(this.data.company.id, Object.assign({...filterData}, {
          platform_id: platform.id,
          channel_id: "group",
        })).pipe(map(x => x.body)).subscribe(resp => {
          resp.forEach(element => {
            if (platform.channels.find(x => x.id == element.channel_id)) {
              platform.channels.find(x => x.id == element.channel_id)[objName] = element
            }
          });
          console.log("openPlatformAdded", resp)
          console.log("openPlatformAdded ITEM", item)
        })
      )
    }
  }

  openPlatformAdded(item, platform) {
    platform.is_added_open = !platform.is_added_open

    if (!!platform.is_added_open) {
      let filterData:any = {
        analytics: moment().subtract(7, 'd').isSameOrAfter(this.filters[this.activeFilterId.value].from.clone()) ? 'by_day' : 'by_hour',
        start_time_from: this.filters[this.activeFilterId.value].from.clone().format("X"),
        query_type: 'subtotals',
        page: '1'
      }
      if (item.partner_company_id) {
        filterData.partner_company_id = item.partner_company_id
      } else {
        filterData.employee_id = item.employee_id
      }
      this.attachSubscriptions(
        this.activityService.getActivity(this.data.company.id, Object.assign({...filterData}, {
          platform_id: platform.id,
          channel_id: "group",
        })).pipe(map(x => x.body)).subscribe(resp => {
          resp.forEach(element => {
            if (platform.channels.find(x => x.id == element.channel_id)) {
              platform.channels.find(x => x.id == element.channel_id).openPlatformAddedData = element
            }
          });
          console.log("openPlatformAdded", resp)
          console.log("openPlatformAdded ITEM", item)
        })
      )
    }
  }

  openPostsEdited(item) {
    console.log(item, this.actions)
    item.is_post_edited = !item.is_post_edited

    if (!!item.is_post_edited) {
      let filterData:any = {
        analytics: moment().subtract(7, 'd').isSameOrAfter(this.filters[this.activeFilterId.value].from.clone()) ? 'by_day' : 'by_hour',
        start_time_from: this.filters[this.activeFilterId.value].from.clone().format("X"),
        query_type: 'subtotals',
        page: '1'
      }
      if (item.partner_company_id) {
        filterData.partner_company_id = item.partner_company_id
      } else {
        filterData.employee_id = item.employee_id
      }
      this.attachSubscriptions(
        this.activityService.getActivity(this.data.company.id, Object.assign({...filterData}, {
          content_status_id: "group",
        })).pipe(map(x => x.body)).subscribe(resp => {
          resp.forEach(element => {
            if (!!item.editGroupedByStatus.find(x => x.id == element.content_status_id)) {
              item.editGroupedByStatus.find(x => x.id == element.content_status_id).openPostsEditedData = element
            }
          });
          console.log("openPostsEdited", resp)
          console.log("openPostsEdited ITEM", item)
        })
      )
    }
  }

  openPostsAdded(item) {
    item.is_post_added = !item.is_post_added

    if (!!item.is_post_added) {
      let filterData:any = {
        analytics: moment().subtract(7, 'd').isSameOrAfter(this.filters[this.activeFilterId.value].from.clone()) ? 'by_day' : 'by_hour',
        start_time_from: this.filters[this.activeFilterId.value].from.clone().format("X"),
        query_type: 'subtotals',
        page: '1'
      }
      if (item.partner_company_id) {
        filterData.partner_company_id = item.partner_company_id
      } else {
        filterData.employee_id = item.employee_id
      }
      this.attachSubscriptions(
        this.activityService.getActivity(this.data.company.id, Object.assign({...filterData}, {
          content_status_id: "group",
        })).pipe(map(x => x.body)).subscribe(resp => {
          resp.forEach(element => {
            if (!!item.groupedByStatus.find(x => x.id == element.content_status_id)) {
              item.groupedByStatus.find(x => x.id == element.content_status_id).openPostsAddedData = element
            }
          });
          console.log("openPostsAdded", resp)
          console.log("openPostsAdded ITEM", item)
        })
      )
    }
  }

  openStatPostsEdited(item, stat) {
    stat.is_open = !stat.is_open

    if (!!stat.is_open) {
      let filterData:any = {
        analytics: moment().subtract(7, 'd').isSameOrAfter(this.filters[this.activeFilterId.value].from.clone()) ? 'by_day' : 'by_hour',
        start_time_from: this.filters[this.activeFilterId.value].from.clone().format("X"),
        query_type: 'subtotals',
        page: '1',
        content_status_id: stat.id
      }
      if (item.partner_company_id) {
        filterData.partner_company_id = item.partner_company_id
      } else {
        filterData.employee_id = item.employee_id
      }
      this.attachSubscriptions(
        this.activityService.getActivity(this.data.company.id, Object.assign({...filterData}, {
          platform_id: "group",
        })).pipe(map(x => x.body)).subscribe(resp => {
          resp.forEach(element => {
            if (stat.groupedByPlatform.find(x => x.id == element.platform_id)) {
              stat.groupedByPlatform.find(x => x.id == element.platform_id).openStatPostsEditedData = element
            }
          });
          console.log("openStatPostsEdited", resp)
          console.log("openStatPostsEdited ITEM", item)
        })
      )
    }
  }
  openStatPostsAdded(item, stat) {
    stat.is_open = !stat.is_open

    if (!!stat.is_open) {
      let filterData:any = {
        analytics: moment().subtract(7, 'd').isSameOrAfter(this.filters[this.activeFilterId.value].from.clone()) ? 'by_day' : 'by_hour',
        start_time_from: this.filters[this.activeFilterId.value].from.clone().format("X"),
        query_type: 'subtotals',
        page: '1',
        content_status_id: stat.id
      }
      if (item.partner_company_id) {
        filterData.partner_company_id = item.partner_company_id
      } else {
        filterData.employee_id = item.employee_id
      }
      this.attachSubscriptions(
        this.activityService.getActivity(this.data.company.id, Object.assign({...filterData}, {
          platform_id: "group",
        })).pipe(map(x => x.body)).subscribe(resp => {
          resp.forEach(element => {
            if (stat.groupedByPlatform.find(x => x.id == element.platform_id)) {
              stat.groupedByPlatform.find(x => x.id == element.platform_id).openStatPostsAddedData = element
            }
          });
          console.log("openStatPostsAdded", resp)
          console.log("openStatPostsAdded ITEM", item)
        })
      )
    }
  }

  openPlatformPostsEdited(item, stat, platform) {
    platform.is_open = !platform.is_open

    if (!!platform.is_open) {
      let filterData:any = {
        analytics: moment().subtract(7, 'd').isSameOrAfter(this.filters[this.activeFilterId.value].from.clone()) ? 'by_day' : 'by_hour',
        start_time_from: this.filters[this.activeFilterId.value].from.clone().format("X"),
        query_type: 'subtotals',
        content_status_id: stat.id,
        platform_id: platform.id,
        page: '1'
      }
      if (item.partner_company_id) {
        filterData.partner_company_id = item.partner_company_id
      } else {
        filterData.employee_id = item.employee_id
      }
      this.attachSubscriptions(
        this.activityService.getActivity(this.data.company.id, Object.assign({...filterData}, {
          channel_id: "group",
        })).pipe(map(x => x.body)).subscribe(resp => {
          resp.forEach(element => {
            if (platform.channels.find(x => x.id == element.channel_id)) {
              platform.channels.find(x => x.id == element.channel_id).openPlatformPostsEditedData = element
            }
          });
          console.log("openPlatformPostsEdited", resp)
          console.log("openPlatformPostsEdited ITEM", item)
        })
      )
    }
  }
  openPlatformPostsAdded(item, stat, platform) {
    platform.is_open = !platform.is_open

    if (!!platform.is_open) {
      let filterData:any = {
        analytics: moment().subtract(7, 'd').isSameOrAfter(this.filters[this.activeFilterId.value].from.clone()) ? 'by_day' : 'by_hour',
        start_time_from: this.filters[this.activeFilterId.value].from.clone().format("X"),
        query_type: 'subtotals',
        content_status_id: stat.id,
        platform_id: platform.id,
        page: '1'
      }
      if (item.partner_company_id) {
        filterData.partner_company_id = item.partner_company_id
      } else {
        filterData.employee_id = item.employee_id
      }
      this.attachSubscriptions(
        this.activityService.getActivity(this.data.company.id, Object.assign({...filterData}, {
          channel_id: "group",
        })).pipe(map(x => x.body)).subscribe(resp => {
          resp.forEach(element => {
            if (platform.channels.find(x => x.id == element.channel_id)) {
              platform.channels.find(x => x.id == element.channel_id).openPlatformPostsAddedData = element
            }
          });
          console.log("openPlatformPostsAdded", resp)
          console.log("openPlatformPostsAdded ITEM", item)
        })
      )
    }
  }


  getActivity(n) {

    this.isLoad = true;
    let filterData:any = {
      analytics: moment().subtract(7, 'd').isSameOrAfter(this.filters[this.activeFilterId.value].from.clone()) ? 'by_day' : 'by_hour',
      start_time_from: this.filters[this.activeFilterId.value].from.clone().format("X"),
      query_type: 'subtotals',
      page: n
    }
    if (this.filters[this.activeFilterId.value].to) {
      filterData.start_time_to = (this.filters[this.activeFilterId.value].to as Moment).clone().format("X")
    }

    if (!!this.filter.value.employee_id) {
      filterData.employee_id = this.filter.value.employee_id
    } else if (!!this.filter.value.partner_company_id) {
      filterData.partner_company_id = this.filter.value.partner_company_id
    } else {
      filterData.employee_id = "group";
      filterData.partner_company_id = "group";
    }
    this.attachSubscriptions(
      this.activityService.getActivity(this.data.company.id, filterData).pipe(
        tap(el => {
          this.pagination = {
            'pageCount': el.headers.get('x-pagination-page-count'),
            'perPage': el.headers.get('x-pagination-per-page'),
            'totalCount': el.headers.get('x-pagination-total-count'),
            'currentPage': el.headers.get('x-pagination-current-page'),
          }
        }),
        map(el => el.body),
        switchMap(arr => {
          if (this.page == 1) {
            let subFilterData = {...filterData}
            subFilterData.query_type = 'totals'
            return this.activityService.getActivity(this.data.company.id, subFilterData).pipe(
              tap(val => {
                this.totals = val.body
                console.log("TOTALS", this.totals)
              }),
              map(() => arr)
            )
          } else {
            return of(arr)
          }
        })
      ).subscribe(resp => {
        if (this.groupedByPlatform && this.groupedByPlatform.length) {
          resp.forEach(el => {
            el.groupedByPlatform = JSON.parse(JSON.stringify(this.groupedByPlatform))
            el.groupedByStatus = JSON.parse(JSON.stringify(this.groupedByStatus))
            el.editGroupedByStatus = JSON.parse(JSON.stringify(this.editGroupedByStatus))
          })
        }
        if (this.page == 1) {
          this.activity = [...resp]
        } else {
          this.activity.push(...resp)
        }

        console.log("this.activity - " + this.page, this.activity)

        this.page = this.page + 1;
        this.isLoad = false;
      })
    )
  }

  onSearchEmoloyees(resp) {
    if (!this.data.employees) {
      return;
    }

    if (!resp) {
      this.employees$.next(this.data.employees.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }

    this.employees$.next(
      this.data.employees.filter(z => z.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  getEmployee(id) {
    if (!this.data.employees) {
      return false;
    }
    return this.data.employees.find(el => el.id == id)
  } 

  close() {
    this.dialogRef.close()
  }

  mobClose() {
    this.dialogRef.removePanelClass('animate__slideInUp');
    this.dialogRef.addPanelClass('animate__slideOutDown');
    setTimeout(()=>{this.dialogRef.close()}, 300);
  }

  changeTab(val) {
    this.tab = val;
    this.is_filter_active = false;
  }



  // NOTIFICATIONS TAB

  
  expandAction($event, element) {
    $event.stopPropagation()
    element.isExpand = !element.isExpand;

    if (!!element.isExpand) {
      this.attachSubscriptions(
        this.taskService.getActions(!!this.data.task ? this.data.task.id : false, "1", this.data.company.id, {original_action_id: element.id}, !!this.data.work ? this.data.work.id : false).pipe(map(res => res.body)).subscribe(resp => {
          element.subActions = resp;
          console.log(resp)
          // this.table.renderRows();
        })
      )
    }

  }

  profileData(id) {
    if (!this.profiles) {
      return false;
    }
    return this.profiles.find(el => el.id == id) ? this.profiles.find(el => el.id == id) : false
  }

  profileStatusesData(id) {
    if (!this.profileStatuses && this.profileStatuses.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.profileStatuses.find(el => el.id == id) ? this.profileStatuses.find(el => el.id == id) : false
  }

  getProfilesStatus() {
    this.attachSubscriptions(
      this.globalDataService.getContentStatus().subscribe(resp => {
        this.profileStatuses = resp.slice();
      })
    )
  }

  getProfile(id) {
    if (!this.profiles) {
      return false;
    }
    return this.profiles.find(el => el.id == id)
  }
  
  getProfiles() {
    this.attachSubscriptions(
      this.companyService.getAllProfilesDyn('1', this.data.company.id, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }
          return forkJoin(arr.map(x => this.companyService.getAllProfilesDyn(x, this.data.company.id, '200').pipe(map(u => u.body)))).pipe(
            last(),
            tap(values => {
              let conValues = [].concat(...values)
              this.profiles = conValues;
            }),
          )
        }),
      ).subscribe(resp => {
        console.log("getProfiles resp", resp);
        console.log("getProfiles profiles", this.profiles);
      })
    )
  }

  getDay(val) {
    return new Date(val).setHours(0,0,0,0);
  }

  toggleVolume() {
    this.hasSound = !this.hasSound;
    if (this.hasSound) {
      this.sm.localStorageSetItem("hasSound", "1")
    } else {
      this.sm.localStorageSetItem("hasSound", "0")
    }
  }

  neededData(task) {
    let arr = [
      this.chatService.getTasksChats(this.data.company.id, [task.id]).pipe(
        tap(res => {
          task['operations'].map(o_el => {
            o_el['chatsInfo'] = [];
            o_el['chatsInfo'].push(...res.filter(z => z.task_operation_id == o_el.id));
            o_el['unReadChats'] = res.filter(z => z.task_operation_id == o_el.id && z.is_read != '1').length;
            o_el['unClosedChats'] = res.filter(z => z.task_operation_id == o_el.id && z.status_id != '4').length;
          });
          task['chatsInfo'] = [];
          task['chatsInfo'].push(...res.filter(z => z.task_id == task.id));
          task['unReadChats'] = res.filter(z => z.is_read != '1').length;
          task['unClosedChats'] = res.filter(z => z.status_id != '4').length;
        })
      )
    ]
    if (task.company_id == this.data.company.id) {
      arr.push(
        this.taskService.getTaskClients(task.id, this.data.company.id).pipe(
          tap(res => {
            task.clients = res
          }),
          catchError(err => {
            return err
          })
        )
      )
    }
    arr.push(...task.operations.filter(z => (z.status_id == 3 && z.is_to_approve_files) || (z.status_id == 98 && z.is_approved_files)).map(x => this.fileService.getFilesForOpenTask(this.data.company.id, x.id, x.status_id == 3 ? '/to_approve' : '/ready_files').pipe(
      tap(val => {
        x.filesCount = val.headers.get('x-pagination-total-count')
        x.files = val.body
      })
    )))

    return forkJoin(arr)
  }

  toFilter() {
    console.log("toFilter")
    this.is_filter_active = !this.is_filter_active;
  }

  openJob(task_id, task_operation_id) {
    this.attachSubscriptions(
      this.taskService.getOneTaskExpand(this.data.company.id, task_id).pipe(
        map(x => x.body[0]),
        switchMap(x => this.neededData(x).pipe(map(() => casesModel([x], [], 'update')),map(x => x.arr[0]))),
      ).subscribe(resp => {
        const dialogRef = this.dialog.open(OpenJobComponent, {
          backdropClass: 'backdrop_under_header',
          panelClass: !this.is_mobile ? ['open_task_dialog', 'show_header'] : 'open_task_dialog',
          autoFocus: false,
          data: {
            task_id: task_id,
            task_operation_id: task_operation_id,
            task: resp,
            initCompanyId: this.data.company.id
          }
        });
      })
    )
  }

  openTask(task) {
    
    const dialogRef = this.dialog.open(OpenTaskComponent, {
      backdropClass: 'backdrop_under_header',
      panelClass: !this.is_mobile ? ['open_task_dialog', 'show_header'] : 'open_task_dialog',
      autoFocus: false,
      data: {
        task_id: task.id,
        initCompanyId: this.data.company.id
      }
    });
  }
  
  getContentById(id) {
    if (!this.allContentTypes || this.allContentTypes.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.allContentTypes.find(el => el.id == id)
  }

  openChat(not) {
    this.attachSubscriptions(
      this.chatService.getChatsExpand(1, this.data.company.id, {id: not.discussion.id}).pipe(
        map(res => res.body[0])
      ).subscribe(chat => {
        const goToChat = this.dialog.open(ChatsComponent, {
          backdropClass: ['backdrop_under_header', "without_pad"],
          disableClose: true,
          panelClass: ['full_screen_dialog', 'global_chats_dialog'],
          data: {
            company: this.data.company,
            user: this.data.user,
            imgRoute: this.data.imgRoute,
            dialogType: 'global_chat',
            host: this.host,
            activeLang: this.activeLang, 
            chat: chat
          }
        });
      })
    )
  }
  
  getOperations() {
    this.attachSubscriptions(
      this.taskService.getOperations(this.data.company.id, this.activeLang).subscribe(resp => {
        console.log(resp)
        this.operations = resp;
      })
    )
  }

  getGroupsCompany() {
    this.attachSubscriptions(
      this.companyService.getInfiniteGroupsCompany(this.data.company.id, '1', '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.companyService.getInfiniteGroupsCompany(this.data.company.id, x).pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        this.groups = [].concat(...res);
        this.groups$.next(this.groups.slice());
      })
    )
  }

  getAllContentTypes() {
    this.attachSubscriptions(
      this.companyService.getContentTypes().pipe(
        tap(el => {
          this.contentPagination = {
            'pageCount': el.headers.get('x-pagination-page-count'),
            'perPage': el.headers.get('x-pagination-per-page'),
            'totalCount': el.headers.get('x-pagination-total-count'),
            'currentPage': el.headers.get('x-pagination-current-page'),
          }
        }),
        map(el => el.body)
      ).subscribe(resp => {
        this.allContentTypes = resp;
      })
    )
  }

  onSearchGroups(resp) {
    if (!this.groups) {
      return;
    }

    if (!resp) {
      this.groups$.next(this.groups.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }

    this.groups$.next(
      this.groups.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  getAllActions() {
    this.attachSubscriptions(
      this.taskService.getAllActions().subscribe(resp => {
        this.actions = resp;
      })
    )
  }

  getTaskStatus() {
    this.attachSubscriptions(
      this.taskService.getTaskStatuses(this.data.company.id).subscribe(resp => {
        resp.unshift({
          id: 0,
          name: marker('All statuses')
        })
        this.taskStatuses = resp;
      })
    )
  }

  getTaskStatusById(id) {
    if (!this.taskStatuses || this.taskStatuses.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.taskStatuses.find(el => el.id == id)
  }

  checkIsMobile() {
    if (window.innerWidth <= 769) {
      this.is_mobile = true;
    } else {
      this.is_mobile = false;
    }
    this.onResize();
  }

  onResize() {
    this.attachSubscriptions(
      fromEvent(window, "resize").pipe(
        map(() => window.innerWidth)
      ).subscribe((wWidth) => {
          if (wWidth <= 769) {
            this.is_mobile = true;
          } else {
            this.is_mobile = false;
          }
        }
      )
    )
  }

  getOperationsStatus() {
    this.attachSubscriptions(
      this.taskService.getOperationsStatus().subscribe(resp => {
        this.operationStatuses = resp.slice();
      })
    )
  }

  onSearchNotEmoloyees(resp) {
    if (!this.data.employees) {
      return;
    }

    if (!resp) {
      this.notEmployees$.next(this.data.employees.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }

    this.notEmployees$.next(
      this.data.employees.filter(z => z.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  getActions() {
    this.notPage = 1;
    this.taskService.getActions(false, 1, this.data.company.id).pipe(
      tap(resp => {
        this.notPagination = {
          'pageCount': resp.headers.get('x-pagination-page-count'),
          'perPage': resp.headers.get('x-pagination-per-page'),
          'totalCount': resp.headers.get('x-pagination-total-count'),
          'currentPage': resp.headers.get('x-pagination-current-page'),
        }
      })
    ).subscribe(resp => {
      this.notifications = resp.body;
      this.notifications.forEach(element => {
        if (element.data && element.data[0] && element.data[0].text) {
          element.parsedData = JSON.parse(element.data[0].text)
        }
      });
      console.log("this.notifications", this.notifications)
      if (this.table) {
        this.table.renderRows();
      }
    })
  }

  filterReset() {
    this.notFilter.patchValue({
      group_id: '',
      employee_id: '',
      action: '',
      original_action_id: '',
      created_at_from: '',
      created_at_to: ''
    })
    this.displayedColumns.splice(this.displayedColumns.indexOf("Expand"), 1)
    this.getActions();
  }

  getMore() {
    this.notPage = this.notPage + 1;
    if (this.notPage <= this.notPagination['pageCount']) {
      let data = this.notFilter.value;
  
      Object.keys(data).forEach(element => {
        if (!data[element]) {
          delete data[element]
        }
      })

      this.attachSubscriptions(
        this.taskService.getActions(false, this.notPage, this.data.company.id, data, false).subscribe(resp => {
          resp.body.forEach(element => {
            if (element.data && element.data[0] && element.data[0].text) {
              element.parsedData = JSON.parse(element.data[0].text)
            }
          });
          this.notifications.push(...resp.body);
          this.notPagination = {
            'pageCount': resp.headers.get('x-pagination-page-count'),
            'perPage': resp.headers.get('x-pagination-per-page'),
            'totalCount': resp.headers.get('x-pagination-total-count'),
            'currentPage': resp.headers.get('x-pagination-current-page'),
          };
          console.log('this.notifications', this.notifications)
          if (this.table) {
            this.table.renderRows();
          }
          this.onReadActions()
        })
      )
    }
  }

  filterNots() {
    this.notPage = 1;
    this.notifications = [];
    let data = this.notFilter.value;
    console.log("filterNots", this.notFilter.value)

    if (!!data.created_at_from) {
      data.created_at_from = moment(data.created_at_from._d).format("X");
    }
    if (!!data.created_at_to) {
      data.created_at_to = moment(data.created_at_to._d).format("X");
    }

    Object.keys(data).forEach(element => {
      if (data[element] === "") {
        delete data[element]
      }
    })

    this.is_filter_active = false;
    this.taskService.getActions(!!this.data.task ? this.data.task.id : false, this.notPage, this.data.company.id, data, !!this.data.work ? this.data.work.id : false).subscribe(resp => {
      this.notifications.push(...resp.body);
      this.notPagination = {
        'pageCount': resp.headers.get('x-pagination-page-count'),
        'perPage': resp.headers.get('x-pagination-per-page'),
        'totalCount': resp.headers.get('x-pagination-total-count'),
        'currentPage': resp.headers.get('x-pagination-current-page'),
      };
      if (this.table) {
        this.table.renderRows();
      }
    })
  }

  getColumnName(id) {
    if (!this.operations || !this.operations.find(el => el.id == id)) {
      return '';
    }
    return this.operations.find(el => el.id == id) && this.operations.find(el => el.id == id).translation ? this.operations.find(el => el.id == id).translation.name : this.operations.find(el => el.id == id).name
  }

  closeBack(e) {
    if (e.target.className == "alert_backdrop") {
      if (this.is_mobile) {
        this.dialogRef.removePanelClass('animate__slideInUp');
        this.dialogRef.addPanelClass('animate__slideOutDown');
        setTimeout(()=>{this.dialogRef.close({event: "close", data: false})}, 300);
      } else {
        this.dialogRef.close({event: "close", data: false})
      }
    }
  }

  getChatStatus(id) {
    switch (+id) {
      case 1:
        return "Waiting";
        break;
      case 2:
        return "In process";
        break;
      case 3:
        return "To approve";
        break;
      case 4:
        return "Approved";
        break;
      case 5:
        return "To correct";
        break;
      case 6:
        return "Sources needed";
        break;  
      case 97:
        return "On hold";
        break;
      case 98:
        return "Ready";
        break;
      case 99:
        return "Canceled";
        break;
    }
  }

  getOperationStatusName(id) {
    switch (+id) {
      case 1:
        return "Waiting";
        break;
      case 2:
        return "In process";
        break;
      case 3:
        return "To approve";
        break;
      case 4:
        return "Approved";
        break;
      case 5:
        return "To correct";
        break;
      case 6:
        return "Sources Needed";
        break;
      case 97:
        return "On hold";
        break;
      case 98:
        return "Ready";
        break;
      case 99:
        return "Canceled";
        break;
    }
  }

  changeView(e: MatButtonToggleChange) {
    if (e.value == "member") {
      this.notFilter.patchValue({
        original_action_id: 0,
      })
      this.displayedColumns.unshift("Expand")
      this.filterNots()
    } else {
      this.notFilter.patchValue({
        original_action_id: '',
      })
      this.displayedColumns.splice(this.displayedColumns.indexOf("Expand"), 1)
      this.filterNots()
    }
  }
  onReadActions() {
    this.attachSubscriptions(
      this.taskService.readActions({
        company_id: this.data.company.id.toString(),
        read_at: moment().format("X")
      }).pipe(
        finalize(() => this.taskService.unreadNots$.next("change"))
      ).subscribe(resp => {
        console.log(resp)
      })
    )
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
