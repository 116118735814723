<div mat-dialog-title class="mobile_interface_title">
    <div class="mobile_interface_title_in" style="overflow: hidden;">
        <div class="tab_title" (click)="close()">
            <div class="d-f-c">
                <div class="icon_back">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7 12L6.11612 11.1161C5.62796 11.6043 5.62796 12.3957 6.11612 12.8839L7 12ZM16.8839 3.88388C17.372 3.39573 17.372 2.60427 16.8839 2.11612C16.3957 1.62796 15.6043 1.62796 15.1161 2.11612L16.8839 3.88388ZM15.1161 21.8839C15.6043 22.372 16.3957 22.372 16.8839 21.8839C17.372 21.3957 17.372 20.6043 16.8839 20.1161L15.1161 21.8839ZM7.88388 12.8839L16.8839 3.88388L15.1161 2.11612L6.11612 11.1161L7.88388 12.8839ZM6.11612 12.8839L15.1161 21.8839L16.8839 20.1161L7.88388 11.1161L6.11612 12.8839Z" fill="#232323"/>
                    </svg>
                </div>
                <span>
                    {{ "Edit Task" | translate }}
                </span>
                <span class="small_title with_i">7 / 7 — {{ "TAP ELEMENT TO EDIT" | translate }}</span>
            </div>
        </div>
    </div>
</div>
<mat-dialog-content class="mobile_interface_content">
    <div class="mobile_scrollable_int_big" style="background: #fff;">
        <div class="mob_edit_wrp">
            <div class="edit_line" (click)="goTo('name')">
                <div class="edit_label" [ngClass]="{'no_val': !data.task.name}">
                    {{ "Title" | translate }}
                </div>
                <div class="edit_value text_one_line" *ngIf="!!data.task.name">
                    {{data.task.name}}
                </div>
            </div>
            <div class="edit_line" (click)="goTo('comment')">
                <div class="edit_label" [ngClass]="{'no_val': !data.task.comment}">
                    {{ "Brief description" | translate }}
                </div>
                <div class="edit_value text_one_line" *ngIf="!!data.task.comment">
                    {{data.task.comment}}
                </div>
            </div>
            <div class="edit_line" (click)="goTo('private_comment')">
                <div class="edit_label no_m" [ngClass]="{'no_val': !data.task.private_comment}">
                    {{ "Hidden notes" | translate }}
                </div>
                <div class="edit_value text_one_line" *ngIf="!!data.task.private_comment">
                    {{data.task.private_comment}}
                </div>
                <div class="line_info">{{ "Can see managers and above" | translate }}</div>
            </div>
            <div class="edit_items">
                <div class="edit_item" (click)="goTo('priority')">
                    <div class="item_label">{{ "Priority" | translate }}</div>

                    <div class="job_priority" [ngClass]="{
                        'Urgent': data.task.priority == '1000',
                        'High': data.task.priority == '900',
                        'Middle': data.task.priority == '800',
                        'Low': data.task.priority == '700',
                        'No': data.task.priority == '0'
                    }">
                        <span>
                            {{ getPriority(data.task.priority).name | translate }}
                        </span>
                    </div>

                </div>
                <div class="edit_item">
                    <div class="item_label">{{ "Task Type" | translate }}</div>

                    <div class="job_type text_one_line">
                        <span class="text_one_line">
                            {{ getTaskStatusById(data?.task?.status_id)?.name }}
                        </span>
                    </div>
                </div>
            </div>
            <div class="edit_items">

                <div class="edit_item" (click)="goTo('group')">
                    <div class="item_label" [ngClass]="{'no_val': !data?.task?.group_id}">{{ "Group" | translate }}</div>

                    <div class="job_type">
                        <span class="text_one_line">
                            {{data?.task?.group?.name}}
                        </span>
                    </div>
                </div>

                <div class="edit_item" style="justify-content: flex-end !important; padding-top: 0; padding-bottom: 6px; min-height: 61px;">
                    <mat-form-field appearance="legacy" class="custom_id_input" [ngClass]="{'no_val': !data.task.custom_id}">
                        <mat-label>{{ "Custom ID" | translate }}</mat-label>
                        <input matInput type="number" (change)="editCustomId()" [(ngModel)]="data.task.custom_id">
                    </mat-form-field>
                </div>
            </div>
            <div class="edit_items">
                <div class="edit_item">
                    <div class="item_label no_val">{{ "Task Relations" | translate }}</div>
                </div>
                <div class="edit_item"> 
                    <div class="item_label no_val" style="white-space: nowrap;">{{ "Thumbnail settings" | translate }}</div>
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>
