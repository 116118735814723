import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { AfterContentChecked, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, SimpleChanges, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import * as moment from 'moment';
import { concat, forkJoin, from, fromEvent, interval, of, ReplaySubject, Subscription, throwError, timer } from 'rxjs';
import { catchError, concatMap, debounce, debounceTime, distinctUntilChanged, filter, finalize, last, map, switchMap, take, tap } from 'rxjs/operators';
import { EditOperationChatComponent } from 'src/app/components/atTasksDialog/edit-operation-chat/edit-operation-chat.component';
import { MobChatStatusEditComponent } from 'src/app/components/atTasksDialog/mob-chat-status-edit/mob-chat-status-edit.component';
import { ExecutorsComponent } from 'src/app/components/workspace-pages/cases/dialogs/executors/executors.component';
import { casesModel } from '../../functions/casesModel';
import { makeArray } from '../../functions/objToArray';
import { BaseClass } from '../../models/base-class';
import { LayoutService } from '../../services/common/layout.service';
import { StorageManagerService } from '../../services/common/storage-manager.service';
import { ChatService } from '../../services/rest/chat.service';
import { FileService } from '../../services/rest/file.service';
import { MembersService } from '../../services/rest/members.service';
import { TaskService } from '../../services/rest/task.service';
import { UploadService } from '../../services/rest/upload.service';
import { DeleteAlertComponent } from '../delete-alert/delete-alert.component';
import { InterfaceComponent } from '../file-manager/dialog/interface/interface.component';
import { VideoViewerComponent } from '../file-manager/dialog/video-viewer/video-viewer.component';
import { FilesUploadAskComponent } from '../files-upload-ask/files-upload-ask.component';
import { NotificationsBar } from '../notifications-bar/notifications-bar';
import { SnackBarItem } from '../snack-bar/snack-bar-item';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { AudioRecordingService } from '../../services/common/media/audio-recording.service';
import { AdditionalEmployeesComponent } from 'src/app/components/additionalEmpl/additional-employees/additional-employees.component';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { MobPersonalStatusesComponent } from './dialogs/mob-personal-statuses/mob-personal-statuses.component';
import { OpenTaskComponent } from 'src/app/components/workspace-pages/cases/dialogs/open-task/open-task.component';
import { RefreshService } from '../../services/rest/refresh.service';
import { AddChatToBoardComponent } from 'src/app/components/workspace-pages/chats-page/dialogs/add-chat-to-board/add-chat-to-board.component';
import { mimeTypes } from "mime-wrapper";
import { CompanyService } from '../../services/rest/company.service';
import { OpenJobComponent } from 'src/app/components/workspace-pages/cases/dialogs/open-job/open-job.component';
import { ToApproveStepperComponent } from 'src/app/components/workspace-pages/cases/dialogs/to-approve-stepper/to-approve-stepper.component';
import { MoveToOtherJobComponent } from './dialogs/move-to-other-job/move-to-other-job.component';
import { MinimizeService } from '../../services/rest/minimize.service';
import { BackJobToPrevStatusComponent } from '../back-job-to-prev-status/back-job-to-prev-status.component';
import { getLessStatuses } from '../../functions/statusesLessModel';
import { TargetParametersComponent } from 'src/app/components/atTasksDialog/target-parameters/target-parameters.component';
import { TaskBarService } from '../../services/rest/task-bar.service';
import { ChatConnectionsComponent } from 'src/app/components/workspace-pages/chats-page/dialogs/chat-connections/chat-connections.component';
import { OperationChatComponent } from 'src/app/components/atTasksDialog/operation-chat/operation-chat.component';
import { ClipboardService } from 'ngx-clipboard';
import { Clipboard } from '@angular/cdk/clipboard';
import { MobFmViewComponent } from '../mob-file-manager/dialogs/mob-fm-view/mob-fm-view.component';
import { MobChangePersonalStatusComponent } from 'src/app/components/workspace-pages/cases/dialogs/open-task/dialogs/mob-change-personal-status/mob-change-personal-status.component';
import { MobChatOptionsComponent } from './dialogs/mob-chat-options/mob-chat-options.component';
import { MobPriorityComponent } from './dialogs/mob-priority/mob-priority.component';
import { MobTitleComponent } from './dialogs/mob-title/mob-title.component';
import { MobQuestionComponent } from '../mob-question/mob-question.component';
import { environment } from 'src/environments/environment';
import { PushSettingsComponent } from '../push-settings/push-settings.component';
import { PushNotificationsService } from '../../services/rest/push-notifications.service';
import { AskPasteFilesComponent } from '../ask-paste-files/ask-paste-files.component';
import { canTranscribe, transcribe } from '../../consts/transcribe.extensions';
import { FileSpeachToTextComponent } from '../file-manager/dialog/file-speach-to-text/file-speach-to-text.component';
import { SameFilesComponent } from '../file-interface/dialogs/same-files/same-files.component';
import { NotesComponent } from '../notes/notes.component';
import { PublishToOutletComponent } from '../file-manager/dialog/publish-to-outlet/publish-to-outlet.component';
import { MobPushSettingsComponent } from './dialogs/mob-push-settings/mob-push-settings.component';
import { SelectionModel } from '@angular/cdk/collections';
import { CardClipboardComponent } from 'src/app/components/workspace-pages/cases/dialogs/open-task/dialogs/card-clipboard/card-clipboard.component';
import { MatMenuTrigger } from '@angular/material/menu';
import * as hljs from 'highlight.js';
@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class ChatComponent extends BaseClass implements OnInit, AfterViewInit, OnDestroy {
  overlayRef: OverlayRef | null;
  overlayFileRef: OverlayRef | null;
  public linkTo: any;
  public linkToData: any = {
    chat_id: "",
    msg_id: "",
    is_used: false
  }
  public origin = window.location.origin;
  overlayContextRef: OverlayRef | null;
  @ViewChild('taskMenu') taskMenu: TemplateRef<any>;
  @ViewChild('contextMenu') contextMenu: TemplateRef<any>;
  @ViewChild("postsContainer") postsContainer: ElementRef;
  @ViewChild("pinnedWrp") pinnedWrp: ElementRef;
  @ViewChild("chatFooter") chatFooter: ElementRef;
  @ViewChild('fileMenu') fileMenu: TemplateRef<any>;
  @ViewChild('chatHeader') chatHeader: ElementRef;
  @ViewChild("chatComponent") chatComponent: ElementRef;
  // @ViewChild("micro") micro: ElementRef;
  @ViewChild("msg") msg: ElementRef<any>;
  @ViewChild("msg_edit") msg_edit: ElementRef<any>;
  @ViewChild("mob_msg") mob_msg: ElementRef<any>;
  @ViewChild("mob_msg_edit") mob_msg_edit: ElementRef<any>;
  @Input() chat_id: number;
  @Input() linkToPost: any;
  @Input() chats: any;
  @Input() activeLang: any;
  @Input() company: any;
  @Input() task: any;
  @Input() tasks: any;
  @Input() work: any;
  @Input() user: any;
  @Input() operationsValues: any;
  @Input() timeZone: any;
  @Input() is_mobile: boolean;
  @Input() is_small: boolean = false;
  @Input() mustChangeStatus: any = false;
  @Input() emplSelected: any = false;
  @Input() is_single: boolean = false;
  @Input() isLoad: boolean;
  @Input() dialogComp: MatDialogRef<any>[]
  @Input() statuses: any;
  @Input() chatMore: number;
  @Input() selectMenuCounter: number;
  @Output() HideMenu = new EventEmitter<any>();
  @Output() ToNextChat = new EventEmitter<any>();
  @Output() Close = new EventEmitter<any>();
  
  public copiedFiles: any;
  
  throttle = 300;
  scrollUpDistance = 0;

  // media vals
  public isPlaying = false;
  public displayControls = true;
  public isAudioRecording = false;
  public audioRecordedTime;
  public audioBlobUrl;
  public audioBlob;
  public audioName;
  public audioStream;
  public audioConf = { audio: true}
  // end
  public now = new Date();
  public today = moment().startOf('d').format('X');
  public todayDate = moment().set({hour:0,minute:0,second:0}).unix();
  public is_typing: any;
  public scrollPostIdTarget: number = 0;
  public startPostId: number = 0;
  public endPostId: number = 0;
  public checksCount: number = 0;
  public newMsgCounter: number = 0;
  public postsSrollCount: number = 0;
  public chatUsers: any;
  public bookmarks: any;
  public imgRoute: any;
  public uploadLimit: boolean = false;
  public dropOver: boolean = false;
  public isVisible: boolean = true;

  public skipChat: boolean = false;

  
  public actions: any = [
    {
      id: 0,
      name: 'No action',
    },
    {
      id: 1,
      name: 'Close chat',
    },
    {
      id: 2,
      name: 'Next chat',
    }
  ]
  public dont_show_ids = [];
  public action: any = +this.sm.localStorageGetItem('chat_browser_action');
  public actionStatuses: any = !!this.sm.localStorageGetItem('chat_browser_action_statuses') ? JSON.parse(this.sm.localStorageGetItem('chat_browser_action_statuses')) : [];

  public chatScrollStrategys: any = [
    {
      id: 0,
      type: 'start',
      order_by: 'id',
      target_id: 0
    },
    {
      id: 1,
      type: 'target',
      order_by: 'id_desc',
      target_id: 0
    },
    {
      id: 2,
      type: 'end',
      order_by: 'id_desc',
      target_id: 0
    }
  ]
  public chatScrollStrategy: FormControl = new FormControl(this.chatScrollStrategys[2])
  public _window: any = window;
  public chat: any;
  public postCollection: any = new SelectionModel(
    true,
    []
  )
  public selectedNotEmployees: any;
  public notEmployees: any;
  public employees: any;
  public checksPagination: any;
  public pinned: any[] = [];
  public currentDate: any = +moment().format("X");
  public host: any = environment.host;
  public form: FormGroup;
  public edited: FormGroup;
  public statusForm: FormGroup;
  public personalStatusForm: FormGroup;
  public statusSub: Subscription;
  public chatEmlStats: any;
  public groups: any;
  public iAmEmployee: any;
  public searchStringControl: FormControl = this.fb.control('');
  public menuOpened: boolean = true;

  public is_checks: boolean = false;
  public is_reminder_group: boolean = false;
  public is_reminder_done: number = undefined;

  public post_edited: boolean = false;
  public firstLoad: boolean = false;
  public isFilesShow: boolean = false;
  public is_scheme_editing: boolean = false;
  public isChangedPersonalStatus: boolean = false;
  public isSendRecord: boolean = false;
  public choised: boolean = false;
  public post_edited_id: number;
  public page: number = 1;
  public A_WEEK_OLD: any = moment().subtract(7, 'days').startOf('day');
  public pagination: any;
  public chatPing = interval(5000).pipe(
    filter(x => this.posts.length > 0 && !!this.isVisible)
  );
  private subscription: Subscription;
  private postSub: Subscription;
  private chatRead: Subscription;
  private postsSub: Subscription;
  private checksSub: Subscription;
  private filesSub: Subscription;
  private updateTaskSub: Subscription;
  private updateDiscussionSub: Subscription;
  private updateChatSub: Subscription;
  private pinnedSub: Subscription;
  private taskSub: Subscription;
  public posts: any[] = [];
  public checks: any[] = [];
  public pasteCatcher: ElementRef<HTMLDivElement>;
  public selectedText: any;
  public reply: any = {
    id: 0,
    post: {}
  };
  public showArrowDown: boolean = true;
  public showArrowUp: boolean = true;
  public is_sms_status: boolean = false;
  public isSended: boolean = false;
  public filesData: any = [
    {
      name: "Chat files",
      count: 0,
      files: []
    },
    {
      name: "Job files",
      count: 0,
      files: []
    },
    {
      name: "Task files",
      count: 0,
      files: []
    }
  ]
  public sub: Subscription;
  public fileSub: Subscription;
  public contextSub: Subscription;
  public searchSub: Subscription;
  public searhcResults: any;
  public prevTitle: any;
  public pinnedRange: any = [0, 1];  
  public transcribe: any = transcribe;
  public canTranscribe: Function = canTranscribe;
  public copiedMsgs: any;
  // public chat_id: number;

  constructor(
    private fb: FormBuilder,
    private chatService: ChatService,
    private taskService: TaskService,
    private layoutService: LayoutService,
    public taskBarService: TaskBarService,
    private dialog: MatDialog,
    private router: Router,
    private bottomSheet: MatBottomSheet,
    private uploadService: UploadService,
    private minimizeService: MinimizeService,
    private dialogRef: MatDialog,
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
    private membersService: MembersService,
    private companyService: CompanyService,
    private fileService: FileService,
    private refreshService: RefreshService,
    private sm: StorageManagerService,
    private clipboard: Clipboard,
    public viewContainerRef: ViewContainerRef,
    private _snackBar: MatSnackBar,
    private pushNotificationsService: PushNotificationsService,
    public overlay: Overlay,
    private audioRecordingService: AudioRecordingService,
    private ref: ChangeDetectorRef,
    private _clipboardService: ClipboardService,
    private sanitizer: DomSanitizer
  ) {
    super();

    this.audioRecordingService.recordingFailed().subscribe(() => {
      this.isAudioRecording = false;
      this.ref.detectChanges();
    });

    this.audioRecordingService.getRecordedTime().subscribe((time) => {
      this.audioRecordedTime = time;
      this.ref.detectChanges();
    });

    this.audioRecordingService.getRecordedBlob().subscribe((data) => {
      this.audioBlob = data.blob;
      this.audioName = data.title;
      this.audioBlobUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(data.blob));
      this.ref.detectChanges();
    });

   }

  ngOnInit(): void {
    this.copiedMsgs = JSON.parse(this.sm.localStorageGetItem('copiedMsgs'));
    this.attachSubscriptions(
      interval(1000).subscribe(x => {
        this.currentDate = this.currentDate + 1
      })
    )

    console.log("this.prevTitle", this.prevTitle);

    this.chatScrollStrategy.valueChanges.subscribe(resp => {
      console.log("chatScrollStrategy", resp)
    })

    // dialogType: 'global_chat' || 'operation_chat',
    if (this.dialog.openDialogs.length > 0 && this.dialog.openDialogs.filter(x => !!x.componentInstance.data.dialogType && ['global_chat', 'operation_chat'].includes(x.componentInstance.data.dialogType)).length > 1) {
      let _dialogs = this.dialog.openDialogs.filter(x => !!x.componentInstance.data.dialogType && ['global_chat', 'operation_chat'].includes(x.componentInstance.data.dialogType))
      
      this.dialog.openDialogs.slice(this.dialog.openDialogs.indexOf(_dialogs[0]), this.dialog.openDialogs.indexOf(_dialogs[1])).forEach(d => {
        d.close()
      })
    }
    console.log("this.dialog.openDialogs", this.dialog.openDialogs)
    // console.log("AFTER ++++++++++++++++++++++++++ this.dialog.openDialogs", this.dialog.openDialogs)
    // let lastChatDialogIndex = this.dialog.openDialogs.indexOf((this.dialog.openDialogs as any).findLast(x => !!x.componentInstance.data.dialogType && ['global_chat', 'operation_chat'].includes(x.componentInstance.data.dialogType)))
    // this.dialog.openDialogs.forEach((_dialog, ind) => {
    //   if (ind != lastChatDialogIndex && !!_dialog.componentInstance.data.dialogType && ['global_chat', 'operation_chat'].includes(_dialog.componentInstance.data.dialogType)) {
    //     _dialog.close()
    //   }
    // })

    //componentInstance.data
    console.log("chat_id", this.chat_id)

    console.log("this.linkToPost", this.linkToPost)
    if (!!this.linkToPost && !!this.linkToPost.chat_id && !!this.linkToPost.msg_id && !this.linkToPost.is_used) {
      this.linkToData = this.linkToPost
    }

    this.attachSubscriptions(
      this.uploadService.getUploadLimit().subscribe(resp => {
        if (resp || this.company.filesize >= this.company.filesize_limit) {
          this.uploadLimit = true;
        }
      })
    )
    this.getGroupsCompany();
    this.getImgRoute();
    this.getOperationsStatus();

    this.attachSubscriptions(
      this.searchStringControl.valueChanges.pipe(
        debounceTime(300)
      ).subscribe((resp) => {
        console.log(resp)
        if (!resp) {
          this.searhcResults = []
        } else {
          this.searchChat(resp)
        }
      })
    )

    this.attachSubscriptions(
      this.refreshService.getChatPost().subscribe(resp => {
        console.log("resp", resp)
        if (this.company.id && resp.company_id == this.company.id && this.chat_id == resp.chat_id && this.posts.find(x => x.id == resp.post_id)) {
          console.log("resp2", resp)
          this.attachSubscriptions(
            this.chatService.getTargetPost(resp.chat_id, resp.post_id, this.company.id).pipe(
              map(u => u[0]),
              tap(element => {
                  element.discussionPostFiles.forEach(el => {
                    if (el.file) {
                      console.log("discussionPostFiles EL", el)
                      if (el.file.meta_width && el.file.meta_height) {
                        if (el.file.meta_width <= el.file.meta_height) {
                          let perc = 320/(+el.file.meta_height)
                          el.file.perc = perc;
                          el.file.prediction_w_size = Math.round((+el.file.meta_width)*perc);
                          el.file.prediction_h_size = 320;
                        } else {
                          let perc = 320/el.file.meta_width
                          el.file.perc = perc;
                          el.file.prediction_w_size = 320;
                          el.file.prediction_h_size = Math.round((+el.file.meta_height)*perc);
                        }
                      }
                    } else {
                      element.discussionPostFiles.splice(element.discussionPostFiles.indexOf(el), 1)
                    }
                  });

                  if (element.replyToPost && element.replyToPost.discussionPostFiles && element.replyToPost.discussionPostFiles.length > 0) {
                    element.replyToPost.discussionPostFiles.forEach(el => {
                      if (el.file) {
                        if (el.file.meta_width && el.file.meta_height) {
                          if (el.file.meta_width <= el.file.meta_height) {
                            let perc = 320/(+el.file.meta_height)
                            el.file.perc = perc;
                            el.file.prediction_w_size = Math.round((+el.file.meta_width)*perc);
                            el.file.prediction_h_size = 320;
                          } else {
                            let perc = 320/el.file.meta_width
                            el.file.perc = perc;
                            el.file.prediction_w_size = 320;
                            el.file.prediction_h_size = Math.round((+el.file.meta_height)*perc);
                          }
                        }
                      } else {
                        element.replyToPost.discussionPostFiles.splice(element.replyToPost.discussionPostFiles.indexOf(el), 1)
                      }
                    });
                  }

                this.posts.splice(this.posts.indexOf(this.posts.find(x => x.id == resp.post_id)), 1, element)
                this.tryHigh('1');
              }),
            ).subscribe(resp => {
              console.log(resp)
            })
          )
        }
      })
    )

    this.attachSubscriptions(
      this.refreshService.getTaskCard().subscribe(resp => {
        if (this.task.id && this.company.id && resp.company_id == this.company.id && this.task.id == resp.task_id && this.work.id == resp.task_operation_id) {
          this.updateDiscussion(this.chat_id)
          this.refreshService.refreshChat$.next({company_id: this.company.id, chat_id: this.chat_id})
          this.initRefreshBoard()
        }
      })
    )
    console.log("this.statuses", this.statuses)

    this.getBookmarks();
  }

  copyToClipboard(val) {
    // this.clipboard.writeText(window.getSelection().toString());
    console.log('window.getSelection().toString()', val)
    this._clipboardService.copy(val)
    this.layoutService.showSnackBar({name: marker(val)}, marker("Copied"), SnackBarItem)
  }

  transcribeFile(file) {
    const dialogRef = this.dialog.open(FileSpeachToTextComponent, {
      backdropClass: ['speech_file_backdrop'],
      panelClass: ['speech_file_pane'],
      data: {
        company: this.company,
        file: file,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result)
    })
  }
  
  pasteFile(event: ClipboardEvent) {
    // WORK FOR CHROME
    const clipboard: any = navigator.clipboard;
    if (clipboard && clipboard.read) {
      clipboard.read().then((clipboardItems) => {

        let files = []
        for (let ind = 0; ind < clipboardItems.length; ind++) {
          const clipboardItem = clipboardItems[ind];
          for (let index = 0; index < clipboardItem.types.length; index++) {
            const type = clipboardItem.types[index];
            clipboardItem.getType(type).then((blob) => {
              console.log("type, blob", type, blob)
              blob.name = `File copied at ${moment().format("MMM DD Y, hh:mm a")}.${mimeTypes.getExtension(type)}`;
              if (type.indexOf('text') == -1) {
                files.push(blob)
              }
              if (index == clipboardItem.types.length - 1 && clipboardItems.length - 1 == ind) {
                if (files.length) {
                  const askDialogRef = this.dialog.open(AskPasteFilesComponent, {
                    data: {
                      company: this.company
                    }
                  });
          
                  askDialogRef.afterClosed().subscribe(result => {
                    if (!!result) {
                      if (result.action == "reports") {
                        this.paste(this.chat, result.clear)
                      } else {
                        const dialogRef = this.dialog.open(FilesUploadAskComponent, {
                          data: {
                            company: this.company,
                            streamData: this.chat,
                            user: this.user,
                            files: files,
                            text: this.post_edited ? this.edited.value.text : this.form.value.text
                          }
                        });
                
                        dialogRef.afterClosed().subscribe(result => {
                          if (!!result && result.event == "send") {
                            console.log("afterClosed", result.data)
                            this.form.patchValue({
                              text: ''
                            })
                            // this.closeReply()
                            this.putFile(result.data).pipe(
                              tap(el => console.log("CONCAT TAP", el))
                            ).subscribe(res => {
                              console.log(res);
                            })
                          }
                        })
                      }
                    }
                  })
                } else {
                  this.paste(this.chat)
                }
              }
            });
          }
        }

        if (!clipboardItems || !clipboardItems.length) {
          this.paste(this.chat)
        }

        console.log("this.copiedFiles", this.copiedFiles)
      }).catch(error => {
        console.log("ERROR")
        this.paste(this.chat)
      });
    } else {
      this.paste(this.chat)
    }
  }

  closeFullscreen() {
    if (!this.chat.discussionPostDraft) {
      this.chat.discussionPostDraft = {}
    }
    this.chat.discussionPostDraft.text = !!this.edited.value.text ? this.edited.value.text : this.form.value.text;
    this.chat.discussionPostDraft.reply_to_post_id = this.reply.id ? this.reply.id : 0;
    this.chat.discussionPostDraft.update_post_id = this.post_edited_id ? this.post_edited_id : 0
    this.chatService.postChatRead(this.chat_id, (!!this.form.value.text || !!this.edited.value.text) ? 1 : 0, this.company.id, !!this.edited.value.text ? this.edited.value.text : this.form.value.text, this.reply.id ? this.reply.id : 0, this.post_edited_id ? this.post_edited_id : 0).subscribe(resp => {
      console.log("POST AFTER CLOSED", resp)
    })
    this.taskBarService.addBarItem({company_id: this.company.id, task_id: this.chat.task_id, task_operation_id: this.chat.task_operation_id, discussion_id: this.chat.id}).subscribe(res => {
      this.minimizeService.minimizes$.next({
        type: 'chat',
        data: this.chat,
        company: this.company,
        user: this.user,
        work: this.work,
        activeLang: this.activeLang,
        isOpen: true,
        discussion_id: this.chat.id,
        company_id: this.company.id,
        task_operation_id: this.work.id,
        task_id: this.task.id,
        id: res.id
      })
    }, error => {
      this.minimizeService.minimizes$.next({
        type: 'chat',
        data: this.chat,
        company: this.company,
        user: this.user,
        work: this.work,
        activeLang: this.activeLang,
        company_id: this.company.id,
        isOpen: true,
        discussion_id: this.chat.id,
        task_operation_id: this.work.id,
        task_id: this.task.id
      })
    })

    this.close()
  }

  pinnedUp() {
    if (this.pinnedRange[1] < this.pinned.length - 1) {
      this.pinnedRange = this.pinnedRange.map(x => +x + 1)
      console.log("pinnedUp", this.pinnedRange)
      console.log("pinnedUp", this.pinned)
    }
  }
  
  pinnedDown() {
    if (this.pinnedRange[0] > 0) {
      this.pinnedRange = this.pinnedRange.map(x => +x - 1)
      console.log("pinnedDown", this.pinnedRange)
    }
  }
  
  minimize() {
    if (!this.chat.discussionPostDraft) {
      this.chat.discussionPostDraft = {}
    }
    this.chat.discussionPostDraft.text = !!this.edited.value.text ? this.edited.value.text : this.form.value.text;
    this.chat.discussionPostDraft.reply_to_post_id = this.reply.id ? this.reply.id : 0;
    this.chat.discussionPostDraft.update_post_id = this.post_edited_id ? this.post_edited_id : 0
    this.chatService.postChatRead(this.chat_id, (!!this.form.value.text || !!this.edited.value.text) ? 1 : 0, this.company.id, !!this.edited.value.text ? this.edited.value.text : this.form.value.text, this.reply.id ? this.reply.id : 0, this.post_edited_id ? this.post_edited_id : 0).subscribe(resp => {
      console.log("POST AFTER CLOSED", resp)
    })
    this.taskBarService.addBarItem({company_id: this.company.id, task_id: this.chat.task_id, task_operation_id: this.chat.task_operation_id, discussion_id: this.chat.id}).subscribe(res => {
      this.minimizeService.minimizes$.next({
        type: 'chat',
        data: this.chat,
        company: this.company,
        user: this.user,
        work: this.work,
        company_id: this.company.id,
        activeLang: this.activeLang,
        isOpen: false,
        discussion_id: this.chat.id,
        task_operation_id: this.work.id,
        task_id: this.task.id,
        id: res.id
      })
    }, error => {
      this.minimizeService.minimizes$.next({
        type: 'chat',
        data: this.chat,
        company: this.company,
        user: this.user,
        work: this.work,
        activeLang: this.activeLang,
        company_id: this.company.id,
        isOpen: false,
        discussion_id: this.chat.id,
        task_operation_id: this.work.id,
        task_id: this.task.id
      })
    })
    this.close()
  }

  clearSearch() {
    this.searchStringControl.patchValue('')
  }

  goToChanelLink(task, chanel) {
    if (!!chanel.content_url) {
      window.open(chanel.content_url, '_blank');
    }
  }

  searchChat(val) {
    if (this.searchSub) {
      this.searchSub.unsubscribe()
    }
    this.searchSub = this.chatService.searchPosts(this.chat.id, 1, val, this.company.id).pipe(
      map(el => el.body),
    ).subscribe(res => {
        console.log(res);
        this.searhcResults = res;
    })
  }

  toggleChatSearch() {
    this.chat.searchActive = !this.chat.searchActive;
    if (this.chat.searchActive && document.getElementById(this.chat?.id + '_search')) {
      document.getElementById(this.chat.id + '_search').focus()
    }
  }

  activeChatChecklist(is_reminder_group:boolean, is_reminder_done:number) {
    this.is_checks = true;
    this.is_reminder_group = is_reminder_group;
    this.is_reminder_done = is_reminder_done;
    this.checks = []
    this.getChecks(this.chat_id)

  }

  deActiveChatChecklist() {
    this.is_checks = false;
    this.is_reminder_group = false;
    this.is_reminder_done = undefined;

    try {
      if (this.posts.length) {
        document.getElementById(`post_${this.posts[this.posts.length - 1].id}`).scrollIntoView({
          behavior: "auto",
          block: "end"
        });
        this.showArrowDown = false;
        this.newMsgCounter = 0;
      }
    } catch(err) {
    }  
  }

  setAsChecklist(post) {
    if (this.postCollection.isSelected(post) && this.postCollection.selected.length > 1) {
      console.log("setAsChecklist post", post)
      this.attachSubscriptions(
        forkJoin([...this.postCollection.selected.map(x => this.chatService.addChatCheck({
          company_id: x.company_id,
          discussion_id: x.discussion_id,
          discussion_post_id: x.id,
          is_set: 1,
        }, this.company.id).pipe(
          tap(res => {
            x.discussionPostReminder = res;
            this.checksCount++;
          }),
          catchError(err => {
            return of(err)
          })
        ))]).subscribe(resp => {
          this.postCollection.clear()
        })
      )
    } else {
      this.attachSubscriptions(
        this.chatService.addChatCheck({
          company_id: post.company_id,
          discussion_id: post.discussion_id,
          discussion_post_id: post.id,
          is_set: 1,
        }, this.company.id).subscribe(resp => {
          console.log("setAsChecklist", resp)
          post.discussionPostReminder = resp;
          this.checksCount++;
        })
      )
    }
  }

  setChecklistData(post, is_done) {
    if (this.postCollection.isSelected(post) && this.postCollection.selected.length > 1) {
      console.log("setAsChecklist post", post)
      this.attachSubscriptions(
        forkJoin([...this.postCollection.selected.filter(u => !!u.discussionPostReminder).map(x => this.chatService.editChatCheck(x.discussionPostReminder.id, {
          is_done: is_done,
        }, this.company.id).pipe(
          tap(res => {
            x.discussionPostReminder.is_done = res.is_done;
          }),
          catchError(err => {
            return of(err)
          })
        ))]).subscribe(resp => {
          this.postCollection.clear()
        })
      )
    } else {
      this.attachSubscriptions(
        this.chatService.editChatCheck(post.discussionPostReminder.id, {
          is_done: is_done,
        }, this.company.id).subscribe(resp => {
          console.log("setAsChecklist", resp)
          post.discussionPostReminder.is_done = resp.is_done;
        })
      )
    }
  }

  removeChecklistItem(post) {
    if (this.postCollection.isSelected(post) && this.postCollection.selected.length > 1) {
      console.log("setAsChecklist post", post)
      this.attachSubscriptions(
        forkJoin([...this.postCollection.selected.selected.filter(u => !!u.discussionPostReminder).map(x => this.chatService.deleteChatCheck(x.discussionPostReminder.id, this.company.id).pipe(
          tap(res => {
            if (this.checks.filter(q => q.id == x.id).length) {
              this.checks.splice(this.checks.findIndex(q => q.id == x.id), 1)
              this.checksCount--;
            }
          }),
          catchError(err => {
            return of(err)
          })
        ))]).subscribe(resp => {
          this.postCollection.clear()
        })
      )
    } else {
      this.attachSubscriptions(
        this.chatService.deleteChatCheck(post.discussionPostReminder.id, this.company.id).subscribe(resp => {
          console.log("setAsChecklist", resp)
          if (this.checks.filter(x => x.id == post.id).length) {
            this.checks.splice(this.checks.findIndex(x => x.id == post.id), 1)
            this.checksCount--;
          }
        })
      )
    }
  }

  getChecks(chat_id, id_from?, id_to?) {
    if (this.postsContainer) {
      this.postsContainer.nativeElement.classList.add("disable_scroll")
    }
    this.isLoad = true;
    this.checksSub = this.chatService.getSmartPosts(chat_id, this.company.id, this.chatScrollStrategy.value.id != 1 ? this.chatScrollStrategy.value.order_by : (!!id_from ? 'id' : 'id_desc'), !this.is_mobile ? 45 : 30, id_from, id_to, true, this.is_reminder_done).pipe(
      tap(el => {
        if ((!id_to && !id_from) && this.chatScrollStrategy.value.id == 2) {
          this.checksPagination = {
            'pageCount': el.headers.get('x-pagination-page-count'),
            'perPage': el.headers.get('x-pagination-per-page'),
            'totalCount': el.headers.get('x-pagination-total-count'),
            'currentPage': el.headers.get('x-pagination-current-page'),
          }
        }
      }),
      map(el => this.chatScrollStrategy.value.id == 2 ? el.body.reverse() : (this.chatScrollStrategy.value.id == 0 ? el.body : (!!id_from ? el.body : el.body.reverse()))),
      tap(postsArr => this.chatService.postsData$.next(postsArr)),
      tap((postsArr:any) => {
        postsArr.forEach(element => {
            if (!this.posts.find(x => x.id == element.id)) {
              element.discussionPostFiles.forEach(el => {
                if (el.file) {
                  console.log("discussionPostFiles EL", el)
                  if (el.file.meta_width && el.file.meta_height) {
                    if (el.file.meta_width <= el.file.meta_height) {
                      let perc = 320/(+el.file.meta_height)
                      el.file.perc = perc;
                      el.file.prediction_w_size = Math.round((+el.file.meta_width)*perc);
                      el.file.prediction_h_size = 320;
                    } else {
                      let perc = 320/el.file.meta_width
                      el.file.perc = perc;
                      el.file.prediction_w_size = 320;
                      el.file.prediction_h_size = Math.round((+el.file.meta_height)*perc);
                    }
                  }
                } else {
                  element.discussionPostFiles.splice(element.discussionPostFiles.indexOf(el), 1)
                }
              });
              if (element.replyToPost && element.replyToPost.discussionPostFiles && element.replyToPost.discussionPostFiles.length > 0) {
                element.replyToPost.discussionPostFiles.forEach(el => {
                  if (el.file) {
                    if (el.file.meta_width && el.file.meta_height) {
                      if (el.file.meta_width <= el.file.meta_height) {
                        let perc = 320/(+el.file.meta_height)
                        el.file.perc = perc;
                        el.file.prediction_w_size = Math.round((+el.file.meta_width)*perc);
                        el.file.prediction_h_size = 320;
                      } else {
                        let perc = 320/el.file.meta_width
                        el.file.perc = perc;
                        el.file.prediction_w_size = 320;
                        el.file.prediction_h_size = Math.round((+el.file.meta_height)*perc);
                      }
                    }
                  } else {
                    element.replyToPost.discussionPostFiles.splice(element.replyToPost.discussionPostFiles.indexOf(el), 1)
                  }
                });
              }
            }
        });
      }),
      tap(postsArr => {
        if (this.is_reminder_group) {
          let done = [];
          let not_done = [];
          postsArr.forEach(post => {
            if (!!post.discussionPostReminder.is_done) {
              done.push(post)
            } else {
              not_done.push(post)
            }
          });
          this.checks = [...not_done,...done];
        } else {
          if (id_to || (!id_to && !id_from)) {
            this.checks = [...postsArr,...this.checks];
            // this.posts.unshift(...postsArr)
          } else {
            this.checks = [...this.checks, ...postsArr]
            // this.posts.push(...postsArr)
          }
        }
      }),
    ).subscribe({
      next: (next) => {
        // console.log(next)
      },
      complete: () => {
        console.log("complete GET CHECKS", this.checks)
        if (this.chatScrollStrategy.value.id == 2) {
          if (!id_to && !id_from) {
            if (this.chat.lastPost) {
              this.chat.lastPost.maxId = Math.max(this.posts[this.posts.length - 1].id, this.chat.lastPost.id, this.chat.lastPost.maxId || 0)
            }
            if (this.postsContainer) {
              this.postsContainer.nativeElement.classList.add("disable_scroll")
            }
            setTimeout(() => {
              console.log("SCROLL 222")
              if (document.getElementById(`post_${this.posts[this.posts.length - 1].id}`)) {
                console.log("SCROLL 333")
                document.getElementById(`post_${this.posts[this.posts.length - 1].id}`).scrollIntoView({
                  behavior: "auto",
                  block: "start"
                });
                if (this.postsContainer) {
                  this.postsContainer.nativeElement.classList.remove("disable_scroll")
                }
              }
            }, 0)
  
          }

          if (this.is_mobile) {
            console.log("after", document.getElementById(`post_${this.scrollPostIdTarget}`));
            if (this.postsContainer) {
              this.postsContainer.nativeElement.classList.add("disable_scroll")
            }
            setTimeout(() => {
              document.getElementById(`post_${this.scrollPostIdTarget}`).scrollIntoView({
                behavior: "auto",
                block: "start"
              });
              if (this.postsContainer) {
                this.postsContainer.nativeElement.classList.remove("disable_scroll")
              }
            }, 0)
          }
        }

        if (this.is_mobile &&this.chatScrollStrategy.value.id == 1) {
          if (!!id_to) {
            console.log("after", document.getElementById(`post_${this.scrollPostIdTarget}`));
            if (this.postsContainer) {
              this.postsContainer.nativeElement.classList.add("disable_scroll")
            }
            setTimeout(() => {
              document.getElementById(`post_${this.scrollPostIdTarget}`).scrollIntoView({
                behavior: "auto",
                block: "start"
              });
              if (this.postsContainer) {
                this.postsContainer.nativeElement.classList.remove("disable_scroll")
              }
            }, 0)
          }
        }
      
        this.isLoad = false;
        if (this.postsContainer) {
          this.postsContainer.nativeElement.classList.remove("disable_scroll")
        }
      },
      error: (error) => {
        console.log(error)
      }
    })
  }


  openTask(task) {
    let taskData:any = {
      task_id: task.id,
      company: this.company,
      initCompanyId: this.company.id,

    }
    if (task.hasOwnProperty("operations")) {
      taskData.task = task
    }

    // backdropClass: 'backdrop_under_header',
    // panelClass: !this.is_mobile ? ['open_task_dialog', 'show_header'] : 'open_task_dialog',
    const dialogRef = this.dialog.open(OpenTaskComponent, {
      backdropClass: !this.is_mobile ? 'backdrop_under_header': ['backdrop_under_header'],
      panelClass: !this.is_mobile ? ['open_task_dialog', 'show_header'] : ['open_task_dialog', 'animate__animated', 'animate__slideInUp'],
      autoFocus: false,
      data: taskData
    });
  }

  getOperationsStatus() {
    this.attachSubscriptions(
      this.taskService.getOperationsStatus().subscribe(resp => {
        this.statuses = resp.slice();
        console.log("statuses", this.statuses);
      })
    )
  }

  checkIsAdditional() {
    if (!this.work) {
      return false
    }

    if (this.work.employees.filter(x => x.employee.user_id == this.user.id && x.discussion_id == this.chat_id && x.is_manager == 0).length > 0) {
      return true;
    } else {
      return false
    }
  }

  compareDates(val) {
    return moment(val*1000).isSameOrAfter(moment().startOf('d'))
  }
  
  // audio recording
  startAudioRecording() {
    if (!this.isAudioRecording) {
      this.isAudioRecording = true;
      this.audioRecordingService.startRecording();
    }
  }

  abortAudioRecording() {
    if (this.isAudioRecording) {
      this.isAudioRecording = false;
      this.audioRecordingService.abortRecording();
    }
  }

  stopAudioRecording() {
    if (this.isAudioRecording) {
      this.audioRecordingService.stopRecording();
      this.isAudioRecording = false;
    }
  }

  clearAudioRecordedData() {
    this.audioBlobUrl = null;
  }

  // end audio recording

  getBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase()
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
}

  sendRecord() {
    this.isSendRecord = true;
    this.audioBlob.name = `Audio-msg-${this.user.display_name}-${moment().format("YYYY-MM-DD-HH-mm-ss")}.${true || (this.getBrowserName() == 'chrome') ? 'wav' : 'ogg'}`
    
    this.putFile([this.audioBlob]).subscribe(resp => {
      console.log("convertWavToMp3 sub", resp)
      this.isSendRecord = false;
    })
  }

  // convertWavToMp3(wavData: ArrayBuffer): Blob {
  //   const wav = new lamejstmp.WavHeader(wavData);
  //   console.log("wav", wav)
  //   const samples = new Int16Array(wavData, wav.dataOffset, wav.dataLen / 2);
  //   console.log("samples", samples)
  //   const mp3encoder = new lamejstmp.Mp3Encoder(wav.channels, wav.sampleRate, 128);
  //   const mp3Data = mp3encoder.encodeBuffer(samples);
  //   const mp3buf = mp3encoder.flush();
  //   const blob = new Blob([new Uint8Array(mp3Data), new Uint8Array(mp3buf)], {
  //     type: 'audio/mp3',
  //   });

  //   console.log("convertWavToMp3", blob);
  //   return blob;
  // }

  editChat(chat) {
    const editChat = this.dialog.open(EditOperationChatComponent, {
      disableClose: true,
      data: {
        tasks: this.tasks,
        company: this.company,
        task: this.task,
        is_create_chat_component: true,
        work: this.work,
        user: this.user,
        empl_status: this.company.permissions.includes('owner') || this.checkIsManager(this.task, this.company, this.user),
        chat: chat,
      }
    });

    this.attachSubscriptions(
      editChat.afterClosed().subscribe(result => {
        this.refreshService.refreshChat$.next({company_id: this.company.id, chat_id: this.chat_id})
        this.initRefreshBoard()
        this.updateDiscussion(this.chat_id)
      })
    )
  }

  editName(chat) {
    const editChat = this.dialog.open(EditOperationChatComponent, {
      disableClose: true,
      data: {
        onlyName: true,
        tasks: this.tasks,
        is_create_chat_component: true,
        company: this.company,
        task: this.task,
        work: this.work,
        user: this.user,
        empl_status: this.company.permissions.includes('owner') || this.checkIsManager(this.task, this.company, this.user),
        chat: chat
      }
    });

    this.attachSubscriptions(
      editChat.afterClosed().subscribe(result => {
        this.refreshService.refreshChat$.next({company_id: this.company.id, chat_id: this.chat_id})
        this.initRefreshBoard()
      })
    )
  }

  editPriority(chat) {
    const editChat = this.dialog.open(EditOperationChatComponent, {
      disableClose: true,
      data: {
        onlyPriority: true,
        tasks: this.tasks,
        is_create_chat_component: true,
        company: this.company,
        task: this.task,
        work: this.work,
        user: this.user,
        empl_status: this.company.permissions.includes('owner') || this.checkIsManager(this.task, this.company, this.user),
        chat: chat
      }
    });

    this.attachSubscriptions(
      editChat.afterClosed().subscribe(result => {
        this.refreshService.refreshChat$.next({company_id: this.company.id, chat_id: this.chat_id})
        this.initRefreshBoard()
      })
    )
  }

  mobEditPriority(chat) {
    const dialogRef = this.dialog.open(MobPriorityComponent, {
      backdropClass: ['mob_interface_backdrop'],
      panelClass: ['mob_interface_panel', 'animate__animated', 'animate__slideInUp'],
      data: {
        company: this.company,
        task: this.task,
        work: this.work,
        user: this.user,
        chat: chat,
        target: 'chat'
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          if (result.event == "back") {
            this.mobOptions();
          } else {
            this.updateDiscussion(this.chat_id)
            this.refreshService.refreshChat$.next({company_id: this.company.id, chat_id: this.chat_id})
            this.initRefreshBoard()
          }
        } else {
          this.updateDiscussion(this.chat_id)
          this.refreshService.refreshChat$.next({company_id: this.company.id, chat_id: this.chat_id})
          this.initRefreshBoard()
        }
      })
    )
  }

  mobEditTitle(chat) {
    const dialogRef = this.dialog.open(MobTitleComponent, {
      backdropClass: ['mob_interface_backdrop'],
      panelClass: ['mob_interface_panel', 'animate__animated', 'animate__slideInUp'],
      data: {
        company: this.company,
        task: this.task,
        work: this.work,
        user: this.user,
        chat: chat,
        type: 'Title',
        type_prop: 'name',
        title: 'Edit chat',
        target: 'chat'
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          if (result.event == "back") {
            this.mobOptions();
          } else {
            this.updateDiscussion(this.chat_id)
            this.refreshService.refreshChat$.next({company_id: this.company.id, chat_id: this.chat_id})
            this.initRefreshBoard()
          }
        } else {
          this.updateDiscussion(this.chat_id)
          this.refreshService.refreshChat$.next({company_id: this.company.id, chat_id: this.chat_id})
          this.initRefreshBoard()
        }
      })
    )
  }

  clearFiles() {
    this.isFilesShow = false;
    this.filesData.map(x => {
      x.count = 0;
      x.files = [];
    })
  }

  ngAfterViewInit(): void {

    console.log("chatHeader", this.chatHeader)
    console.log("chatHeader.n", this.chatHeader.nativeElement)
    this.onScroll();

    setTimeout(() => {
      if (this.msg && this.msg.nativeElement.innerText == '') {
        console.log("this.msg", this.msg)
        this.msg.nativeElement.focus()
      }
    }, 300)
  }

  showFilesInterface(task, work?, loc?) {
    const dialogRef = this.dialog.open(InterfaceComponent, {
      backdropClass: ['backdrop_under_header', 'file_backdrop_under_header'],
      panelClass: !this.is_mobile ? ['file_manager_modal', 'show_header'] : 'file_manager_modal',
      data: {
        company: this.company,
        task: task,
        work: work != undefined ? work : 0,
        tasks: this.tasks,
        user: this.user,
        loc: loc != undefined ? loc : ""
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result && result.event == 'update') {

        }
      })
    )
  }

  updateTask() {
    this.updateTaskSub = this.taskService.getOneTaskExpand(this.company.id, this.task.id).pipe(
      map(x => x.body[0]),
      switchMap(val => this.neededData(val).pipe(map(() => casesModel([val], !!this.tasks ? this.tasks: [], 'update')),map(val => val.arr[0])))
    ).subscribe(resp => {
      this.task = resp;
      this.getJob(this.chat.task_operation_id)
      // Object.keys(resp).forEach(key => {
      //   this.task[key] = resp[key]
      // })
      console.log("this.task updated", this.task);
    })
  
  }

  neededPostData(post) {
    let arr = [];

    arr.push(
      this.pushNotificationsService.getPostNotificationEmployees(post.company_id, post.id).pipe(
        tap(res => {
          post.notEmployees = res
          post.selectedNotEmployees = res.filter(x => x.notificationSubscription && x.notificationSubscription.is_webpush == 1);
        })
      )
    )

    return forkJoin(arr)
  }

  ifChatClientModerator(task, job) {
    // console.log("ifChatClientModerator", task, job, this.company);
    if (!job || !task || !task.clients || task.clients.length == 0 || !this.company) {
      return false;
    }
    // console.log("ifJobClient", task.clients.filter(x => (x.client_employee_id == this.company.employees[0].id || x.client_partner_company_id == this.company_id) && x.is_principal).length)
    if (task.clients.filter(x => (x.client_employee_id == this.company.employees[0].id || x.client_partner_company_id == this.company.id) && !!x.is_principal && ((!x.discussion_id || x.discussion_id == this.chat_id) || (!x.task_operation_id || x.task_operation_id == job.id))).length > 0) {
      return true;
    } else {
      return false;
    }
  }

  ifChatClient(task, job) {
    if (!job || !task || !task.clients || task.clients.length == 0 || !this.company) {
      return false;
    }
    // console.log("ifJobClient", task.clients.filter(x => (x.client_employee_id == this.company.employees[0].id || x.client_partner_company_id == this.company_id) && x.is_principal).length)
    if (task.clients.filter(x => (x.client_employee_id == this.company.employees[0].id || x.client_partner_company_id == this.company.id) && ((!x.discussion_id || x.discussion_id == this.chat_id) || (!x.task_operation_id || x.task_operation_id == job.id))).length > 0) {
      return true;
    } else {
      return false;
    }
  }

  neededData(task) {
    let arr = [];
    arr.push(this.taskService.getTaskClients(task.id, this.company.id).pipe(
      tap(res => {
        task.clients = res
      }),
      catchError(err => {
        return of(err)
      })
    ))
    arr.push(this.chatService.getTasksChats(this.company.id, [task.id]).pipe(
      tap(res => {
        task['operations'].map(o_el => {
          o_el['chatsInfo'] = [];
          o_el['chatsInfo'].push(...res.filter(z => z.task_operation_id == o_el.id));
          o_el['unReadChats'] = res.filter(z => z.task_operation_id == o_el.id && z.is_read != '1').length;
          o_el['unClosedChats'] = res.filter(z => z.task_operation_id == o_el.id && z.status_id != '4').length;
        });
        task['chatsInfo'] = [];
        task['chatsInfo'].push(...res.filter(z => z.task_id == task.id));
        task['unReadChats'] = res.filter(z => z.is_read != '1').length;
        task['unClosedChats'] = res.filter(z => z.status_id != '4').length;
      })
    ))
    return forkJoin(arr).pipe(map(() => task))
  }

  // file dropped
  
  handleDragOver(e) {
    console.log("Over")
    e.preventDefault();
    e.stopPropagation();
    this.dropOver = true
  }
  
  openContext(e: MouseEvent, val) {
    this.closeContextTemplate();
    if (val.post && !val.post.text && !val.post.file && !val.post.fileVal && (!val.post.discussionPostFiles || !val.post.discussionPostFiles?.length)) {
      return
    }
    let x = e.x;
    let y = e.y;
    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo({ x, y })
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        }
      ]);

    this.overlayContextRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });

    if (val.post && val.post.discussionPostFiles && !!e.target && !!(e.target as HTMLElement).closest('.cst_file_class') && !!(e.target as HTMLElement).closest('.cst_file_class').getAttribute('file_id')) {
      val.file = val.post.discussionPostFiles.find(x => x.file_id == (e.target as HTMLElement).closest('.cst_file_class').getAttribute('file_id')).file;
    }

    this.overlayContextRef.attach(new TemplatePortal(this.contextMenu, this.viewContainerRef, {
      $implicit: val
    }));
    
    this.contextSub = fromEvent<MouseEvent>(document, 'click')
      .pipe(
        filter(event => {
          const clickTarget = event.target as HTMLElement;
          return !!this.overlayContextRef && !this.overlayContextRef.overlayElement.contains(clickTarget);
        }),
        take(1)
      ).subscribe(() => this.closeContextTemplate())

  }

  closeContextTemplate() {
    this.contextSub && this.contextSub.unsubscribe();
    if (this.overlayContextRef) {
      this.overlayContextRef.dispose();
      this.overlayContextRef = null;
    }
  }
  
  showViewer() {
    console.log("SHOW VIEWER !!!")
    if (this.dialogComp && !!this.dialogComp.length) {
      this.dialogComp[0].disableClose = true;
    }
    
  }

  closeFileContext() {
    this.fileSub && this.fileSub.unsubscribe();
    if (this.overlayFileRef) {
      this.overlayFileRef.dispose();
      this.overlayFileRef = null;
    }
  }

  openFileContext({ x, y }: MouseEvent, file) {
    this.closeFileContext();
    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo({ x, y })
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        }
      ]);

    if (document.body.offsetWidth - x > 550) {
      file.open_to_left = false
    } else {
      file.open_to_left = true
    }

    this.overlayFileRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });

    this.overlayFileRef.attach(new TemplatePortal(this.fileMenu, this.viewContainerRef, {
      $implicit: file
    }));
    
    this.fileSub = fromEvent<MouseEvent>(document, 'click')
      .pipe(
        filter(event => {
          const clickTarget = event.target as HTMLElement;
          return !!this.overlayFileRef && !this.overlayFileRef.overlayElement.contains(clickTarget);
        }),
        take(1)
      ).subscribe(() => this.closeFileContext())

  }

  hideViewer() {
    console.log("hidden VIEWER !!!")
    if (this.dialogComp && !!this.dialogComp.length) {
      this.dialogComp[0].disableClose = false;
    }
  }

  open({ x, y }: MouseEvent, val) {
    this.closeTemplate();
    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo({ x, y })
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        }
      ]);

    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });

    this.overlayRef.attach(new TemplatePortal(this.taskMenu, this.viewContainerRef, {
      $implicit: val
    }));
    
    this.sub = fromEvent<MouseEvent>(document, 'click')
      .pipe(
        filter(event => {
          const clickTarget = event.target as HTMLElement;
          return !!this.overlayRef && !this.overlayRef.overlayElement.contains(clickTarget);
        }),
        take(1)
      ).subscribe(() => this.closeTemplate())

  }

  closeTemplate() {
    this.sub && this.sub.unsubscribe();
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
  }

  handleDragLeave(e) {
    e.preventDefault();
    e.stopPropagation();
    console.log("Leave")
    this.dropOver = false;
  }

  onFileDropped(e) {
    this.dropOver = false;
    if (this.uploadLimit) {
      this.layoutService.showSnackBar({name: ''}, marker("This company has exceeded the file upload limit."), SnackBarItem)
      return
    }
    if (!e.dataTransfer.files.length) {
      return false;
    }

    this.getFilesDataTransferItems(e.dataTransfer.items).then((files:any) => {
      if (files.length > 0) {
        const dialogRef = this.dialog.open(FilesUploadAskComponent, {
          data: {
            company: this.company,
            streamData: this.chat,
            user: this.user,
            files: files,
            text: this.post_edited ? this.edited.value.text : this.form.value.text
          }
        });

        dialogRef.afterClosed().subscribe(result => {
          if (!!result && result.event == "send") {
            console.log("afterClosed", result.data)
            this.form.patchValue({
              text: ''
            })
            // this.closeReply()
            this.putFile(result.data).pipe(
              tap(el => console.log("CONCAT TAP", el))
            ).subscribe(res => {
              console.log(res);
            })
          }
        })
      }
    });
    
    if (this.company.filesize >= this.company.filesize_limit) {
      this.uploadService.uploadLimit$.next(true)
    }
  }

  getFilesDataTransferItems(dataTransferItems) {
    function traverseFileTreePromise(item, folder) {
      return new Promise(resolve => {
        if (item.isFile) {
          item.file(file => {
            file.filepath = item.fullPath.replace(`/${file.name}`, "");
            folder.push(file);
            resolve(file);
          });
        } else if (item.isDirectory) {
          let dirReader = item.createReader();
          dirReader.readEntries(entries => {
            let entriesPromises = [];
            for (let entr of entries)
              entriesPromises.push(
                traverseFileTreePromise(entr, folder)
              );
            resolve(Promise.all(entriesPromises));
          });
        }
      });
    }
  
    let files = [];
    return new Promise((resolve, reject) => {
      let entriesPromises = [];
      for (let it of dataTransferItems)
        entriesPromises.push(
          traverseFileTreePromise(it.webkitGetAsEntry(), files)
        );
      Promise.all(entriesPromises).then(entries => {
        resolve(files);
      });
    });
  }

  detectBrowserName() { 
    const agent = window.navigator.userAgent.toLowerCase()
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }

  understandFileType(val) {
    if (!val) {
      return ""
    }
    
    if (val == 'application/pdf') {
      return 'pdf'
    } else if (val.indexOf("/") != -1) {
      return val.split('/')[0]
    } else {
      return ""
    }
  }

  checkWorkActions(e, task, work) {
    if (!this.is_mobile) {
      e.preventDefault();
      e.stopPropagation();
    }
    this.attachSubscriptions(
      this.taskService.getActions(null, 1, this.task.company_id, null, work.id).subscribe(resp => {
        
        if (resp.body.length) {
          this.dialog.open(NotificationsBar, {
            backdropClass: this.is_mobile ? ['mob_interface_backdrop'] : 'backdrop_under_header',
            panelClass: this.is_mobile ? ['mob_interface_panel', 'animate__animated', 'animate__slideInUp'] : ['open_task_dialog', 'show_header'],
            data: {
              notifications: resp.body,
              company: this.company,
              user: this.user,
              task: task,
              work: work,
              header: false,
              tasks: this.tasks,
              initCompanyId: this.company.id,
              empl_status: this.company.permissions.includes('owner') || this.checkIsManager(this.task, this.company, this.user),
              pagination: {
                'pageCount': resp.headers.get('x-pagination-page-count'),
                'perPage': resp.headers.get('x-pagination-per-page'),
                'totalCount': resp.headers.get('x-pagination-total-count'),
                'currentPage': resp.headers.get('x-pagination-current-page'),
              },
            }
          });
        }
      })
    )
  }

  deleteChat() {
    if (!this.is_mobile) {
      let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
        hasBackdrop: true,
        backdropClass: 'bottom-sheed-backdrop',
        data: {
          targetVal: this.chat,
          target: marker("chat")
        }
      });
  
      deleteAlert.afterDismissed().subscribe( data => {
        if (data && data.message == 'no') {
          console.log("no");
          return
        } else if (data && data.message == 'yes') {
          this.attachSubscriptions(
            this.chatService.deleteChat(this.chat.id, this.company.id).subscribe(resp => {
              this.taskBarService.removeItems$.next({
                task_id: this.chat.task_id,
                task_operation_id: this.chat.task_operation_id,
                discussion_id: this.chat.id,
                company_id: this.chat.company_id
              })
              this.chatService.deletedChats$.next(this.chat.id)
              this.close()
            })
          )
        }
      });
    } else {
      const dialogRef = this.dialog.open(MobQuestionComponent, {
        backdropClass: ['mob_interface_backdrop'],
        panelClass: ['mob_interface_panel', 'animate__animated', 'animate__slideInUp'],
        disableClose: true,
        data: {
          dialog_title: "Delete chat",
          title: "Do you really want to delete the chat?",
          msg1: "All files, messages will be deleted if you proceed.",
          msg2: "This can`t be undone.",
          btn_yes: "Yes. Delete chat.",
          btn_no: "No. I want te keep this chat.",
        }
      });
  
      this.attachSubscriptions(
        dialogRef.afterClosed().subscribe(result => {
          
          if (!!result) {
            if (result.action == 'yes') {
              this.attachSubscriptions(
                this.chatService.deleteChat(this.chat.id, this.company.id).subscribe(resp => {
                  this.taskBarService.removeItems$.next({
                    task_id: this.chat.task_id,
                    task_operation_id: this.chat.task_operation_id,
                    discussion_id: this.chat.id,
                    company_id: this.chat.company_id
                  })
                  this.chatService.deletedChats$.next(this.chat.id)
                  this.close()
                })
              )
            } else {
              this.mobOptions()
            }
          }
        })
      )
    }
  }

  moveChat() {
    const dialogRef = this.dialog.open(MoveToOtherJobComponent, {
      disableClose: true,
      data: {
        user: this.user,
        company: this.company,
        task: this.task,
        work: this.work,
        chat: this.chat,
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        this.updateDiscussion(this.chat_id)
        this.refreshService.refreshChat$.next({company_id: this.company.id, chat_id: this.chat_id})
        this.initRefreshBoard()
      })
    )
  }

  additionalEmployees() {
    const dialogRef = this.dialog.open(AdditionalEmployeesComponent, {
      disableClose: true,
      data: {
        user: this.user,
        company: this.company,
        task: this.task,
        work: this.work,
        discussion: this.chat,
        initialAdd: true,
        empl_status: this.company?.permissions.includes('owner') || this.checkIsManager(this.task, this.company, this.user)
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        this.updateDiscussion(this.chat_id)
        this.refreshService.refreshChat$.next({company_id: this.company.id, chat_id: this.chat_id})
        this.initRefreshBoard()
      })
    )
  }

  initRefreshBoard() {
    this.refreshService.refreshBoard$.next('refresh')
  }

  deleteFile(post, file, cancel?) {
    if (post.discussionPostFiles && post.discussionPostFiles.length == 1 && (!post.text || post.text == '' || post.text == null)) {
      console.log("1 and without text");
      this.deletePost(this.chat, post, cancel ? file : false);
    } else {
      console.log("more then 1 or 1 and has text")
      console.log(post, file)
      let discussionPostFileID = post.discussionPostFiles.find(x => x.file_id == file.id).id
      let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
        hasBackdrop: true,
        backdropClass: 'bottom-sheed-backdrop',
        data: {
          targetVal: file,
          target: marker("post file")
        }
      });
  
      deleteAlert.afterDismissed().subscribe( data => {
        if (data && data.message == 'no') {
          console.log("no");
          return
        } else if (data && data.message == 'yes') {
          console.log("yes")
          this.attachSubscriptions(
            this.chatService.deletePostFile(discussionPostFileID, this.company.id).subscribe(res => {
              if (cancel) {
                this.cancelUpload(file)
              }
              post.discussionPostFiles.splice(post.discussionPostFiles.indexOf(post.discussionPostFiles.find(x => x.file_id == file.id)), 1)
            })
          )
        }
      });

    }
  }

  showError(error) {
    this.layoutService.showSnackBar({name: ''}, `You do not have permission to do this. ${error}`, SnackBarItem)
  }

  drop(event: CdkDragDrop<string[]>) {
    // this.companyService.closeAllUserHover$.next(true);
    // this.companyService.closeAllOpened();
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
      let chatEm = this.statuses.find(x => x.id == event.container.id).empl.find(y => y.id == event.item.data.id);
      if (chatEm.is_partner) {
        if (!!chatEm.partnerCompanyStatus) {
          console.log("PARTNER", chatEm)
          this.attachSubscriptions(
            this.membersService.editPartnerStatus(chatEm.partnerCompanyStatus.id, {status_id: event.container.id}, this.company.id)
            .pipe(
              switchMap(val => {
                console.log("drop RES1", val)
                if ((this.iAmEmployee?.is_manager == 1 || this.checkIsManager(this.task, this.company, this.user)) && [3,4,6,97,98,99].includes(+event.container.id)) {
                  console.log("drop RES2", val)
                  return this.membersService.getTaskPartnerStatuses({task_operation_id: this.work.id, status_id: getLessStatuses(event.container.id), partner_company_id: chatEm.partner_company_id}, this.company.id, '2').pipe(
                    switchMap(res => {
                      console.log("drop RES3", res)
                      if (res.length == 1 && !res[0].discussion_id) {
                        console.log("drop RES4", val)
                        let questionAlert =  this.bottomSheet.open(BackJobToPrevStatusComponent, {
                          hasBackdrop: true,
                          backdropClass: 'bottom-sheed-backdrop',
                          data: {
                            msg: marker(`Do you really want to convert personal partner status in job to: '${this.getOperationStatusName(+event.container.id)}'?`)
                          }
                        });
                    
                        return questionAlert.afterDismissed()
                        .pipe(
                          switchMap(data => {
                            if (data && data.message == 'no') {
                              console.log("no");
                              return of(val)
                            } else if (data && data.message == 'yes') {
                              return this.membersService.editPartnerStatus(res[0].id, {status_id: event.container.id}, this.company.id).pipe(
                                map(() => val)
                              )
                            } else {
                              return of(val)
                            }
                          })
                        )
                      } else {
                        return of(val)
                      }
                    })
                  )
                } else {
                  return of(val)
                }
              })
            ).subscribe(resp => {
              // if (event.item.data.is_manager == 0 && this.chat.partnerCompanyStatuses.find(z => z.partner_company_id == event.item.data.partner_company_id)) {
              //   this.chat.partnerCompanyStatuses.find(z => z.partner_company_id == event.item.data.partner_company_id).status_id = resp.status_id
              //   this.chat.partnerCompanyStatuses.find(z => z.partner_company_id == event.item.data.partner_company_id).status = this.statuses.find(b => b.id == resp.status_id)
              // }
              this.updateDiscussion(this.chat_id)
              this.refreshService.refreshChat$.next({company_id: this.company.id, chat_id: this.chat_id});
              this.initRefreshBoard()
            },
            error => {
              this.showError(error)
              transferArrayItem(
                event.container.data,
                event.previousContainer.data,
                event.currentIndex,
                event.previousIndex,
              );
            })
          )
        } else {
          this.attachSubscriptions(
            this.membersService.addPartnerStatus(Object.assign({status_id: event.container.id}, {
              company_id: this.company.id,
              task_id: this.task.id,
              task_operation_id: this.work.id,
              discussion_id: this.chat_id,
              partner_company_id: event.item.data.partner_company_id
            }), this.company.id)
            .pipe(
              switchMap(val => {
                console.log("drop RES1", val)
                if ((this.iAmEmployee?.is_manager == 1 || this.checkIsManager(this.task, this.company, this.user)) && [3,4,6,97,98,99].includes(+event.container.id)) {
                  console.log("drop RES2", val)
                  return this.membersService.getTaskPartnerStatuses({task_operation_id: this.work.id, status_id: getLessStatuses(event.container.id), partner_company_id: chatEm.partner_company_id}, this.company.id, '2').pipe(
                    switchMap(res => {
                      console.log("drop RES3", res)
                      if (res.length == 1 && !res[0].discussion_id) {
                        console.log("drop RES4", val)
                        let questionAlert =  this.bottomSheet.open(BackJobToPrevStatusComponent, {
                          hasBackdrop: true,
                          backdropClass: 'bottom-sheed-backdrop',
                          data: {
                            msg: marker(`Do you really want to convert personal partner status in job to: '${this.getOperationStatusName(+event.container.id)}'?`)
                          }
                        });
                    
                        return questionAlert.afterDismissed()
                        .pipe(
                          switchMap(data => {
                            if (data && data.message == 'no') {
                              console.log("no");
                              return of(val)
                            } else if (data && data.message == 'yes') {
                              return this.membersService.editPartnerStatus(res[0].id, {status_id: event.container.id}, this.company.id).pipe(
                                map(() => val)
                              )
                            } else {
                              return of(val)
                            }
                          })
                        )
                      } else {
                        return of(val)
                      }
                    })
                  )
                } else {
                  return of(val)
                }
              })
            ).subscribe(resp => {
              if (event.item.data.is_manager == 0 && this.chat.partnerCompanyStatuses.find(z => z.partner_company_id == event.item.data.partner_company_id)) {
                this.chat.partnerCompanyStatuses.find(z => z.partner_company_id == event.item.data.partner_company_id).status_id = resp.status_id
                this.chat.partnerCompanyStatuses.find(z => z.partner_company_id == event.item.data.partner_company_id).status = this.statuses.find(b => b.id == resp.status_id)
              }
              this.updateDiscussion(this.chat_id)
              this.refreshService.refreshChat$.next({company_id: this.company.id, chat_id: this.chat_id});
              this.initRefreshBoard()
              
            },
            error => {
              this.showError(error)
              transferArrayItem(
                event.container.data,
                event.previousContainer.data,
                event.currentIndex,
                event.previousIndex,
              );
            })
          )
        }
      } else if (chatEm.is_partner_employee) {
        if (!!chatEm.employeeStatus?.id) {
          this.attachSubscriptions(
            this.membersService.editPartnerEmployeeStatus(chatEm.employeeStatus.id, {status_id: event.container.id}, this.company.id)
            .pipe(
              switchMap(val => {
                console.log("drop RES1", val)
                if ((this.iAmEmployee?.is_manager == 1 || this.checkIsManager(this.task, this.company, this.user)) && [3,4,6,97,98,99].includes(+event.container.id)) {
                  console.log("drop RES2", val)
                  return this.membersService.getTaskPartnerEmployeeStatuses({task_operation_id: this.work.id, status_id: getLessStatuses(event.container.id), partner_employee_id: chatEm.partner_employee_id, partner_company_id: this.chat.company_id}, this.company.id, '2').pipe(
                    switchMap(res => {
                      console.log("drop RES3", res)
                      if (res.length == 1 && !res[0].discussion_id) {
                        console.log("drop RES4", val)
                        let questionAlert =  this.bottomSheet.open(BackJobToPrevStatusComponent, {
                          hasBackdrop: true,
                          backdropClass: 'bottom-sheed-backdrop',
                          data: {
                            msg: marker(`Do you really want to convert personal partner status in job to: '${this.getOperationStatusName(+event.container.id)}'?`)
                          }
                        });
                    
                        return questionAlert.afterDismissed()
                        .pipe(
                          switchMap(data => {
                            if (data && data.message == 'no') {
                              console.log("no");
                              return of(val)
                            } else if (data && data.message == 'yes') {
                              return this.membersService.editPartnerEmployeeStatus(res[0].id, {status_id: event.container.id}, this.company.id).pipe(
                                map(() => val)
                              )
                            } else {
                              return of(val)
                            }
                          })
                        )
                      } else {
                        return of(val)
                      }
                    })
                  )
                } else {
                  return of(val)
                }
              })
            ).subscribe(resp => {
              console.log("DQWEQWEQW#!@EWQEQDSA", resp)
              if (event.item.data.is_manager == 0 && this.chat.employeesStatuses.find(z => z.employee_id == event.item.data.employee_id)) {
                this.chat.employeesStatuses.find(z => z.employee_id == event.item.data.employee_id).status_id = resp.status_id
                this.chat.employeesStatuses.find(z => z.employee_id == event.item.data.employee_id).status = this.statuses.find(b => b.id == resp.status_id)
              }
              this.updateDiscussion(this.chat_id)
              this.refreshService.refreshChat$.next({company_id: this.company.id, chat_id: this.chat_id});
              this.initRefreshBoard()
            },
            error => {
              this.showError(error)
              transferArrayItem(
                event.container.data,
                event.previousContainer.data,
                event.currentIndex,
                event.previousIndex,
              );
            })
          )
        } else {
          this.attachSubscriptions(
            this.membersService.addPartnerEmployeeStatus({
              status_id: event.container.id,
              company_id: this.company.id,
              task_id: this.task.id,
              task_operation_id: this.work.id,
              discussion_id: this.chat.id,
              partner_company_id: chatEm.partner_company_id,
              partner_employee_id: chatEm.partner_employee_id
            }, this.company.id)
            .pipe(
              switchMap(val => {
                console.log("drop RES1", val)
                if ((this.iAmEmployee?.is_manager == 1 || this.checkIsManager(this.task, this.company, this.user)) && [3,4,6,97,98,99].includes(+event.container.id)) {
                  console.log("drop RES2", val)
                  return this.membersService.getTaskPartnerEmployeeStatuses({task_operation_id: this.work.id, status_id: getLessStatuses(event.container.id), partner_employee_id: chatEm.partner_employee_id, partner_company_id: this.chat.company_id}, this.company.id, '2').pipe(
                    switchMap(res => {
                      console.log("drop RES3", res)
                      if (res.length == 1 && !res[0].discussion_id) {
                        console.log("drop RES4", val)
                        let questionAlert =  this.bottomSheet.open(BackJobToPrevStatusComponent, {
                          hasBackdrop: true,
                          backdropClass: 'bottom-sheed-backdrop',
                          data: {
                            msg: marker(`Do you really want to convert personal partner status in job to: '${this.getOperationStatusName(+event.container.id)}'?`)
                          }
                        });
                    
                        return questionAlert.afterDismissed()
                        .pipe(
                          switchMap(data => {
                            if (data && data.message == 'no') {
                              console.log("no");
                              return of(val)
                            } else if (data && data.message == 'yes') {
                              return this.membersService.editPartnerEmployeeStatus(res[0].id, {status_id: event.container.id}, this.company.id).pipe(
                                map(() => val)
                              )
                            } else {
                              return of(val)
                            }
                          })
                        )
                      } else {
                        return of(val)
                      }
                    })
                  )
                } else {
                  return of(val)
                }
              })
            ).subscribe(resp => {
              console.log("DQWEQWEQW#!@EWQEQDSA", resp)
              // if (event.item.data.is_manager == 0 && this.chat.employeesStatuses.find(z => z.employee_id == event.item.data.employee_id)) {
              //   this.chat.employeesStatuses.find(z => z.employee_id == event.item.data.employee_id).status_id = resp.status_id
              //   this.chat.employeesStatuses.find(z => z.employee_id == event.item.data.employee_id).status = this.statuses.find(b => b.id == resp.status_id)
              // }
              this.updateDiscussion(this.chat_id)
              this.refreshService.refreshChat$.next({company_id: this.company.id, chat_id: this.chat_id});
              this.initRefreshBoard()
            },
            error => {
              this.showError(error)
              transferArrayItem(
                event.container.data,
                event.previousContainer.data,
                event.currentIndex,
                event.previousIndex,
              );
            })
          )
        }
      } else {
        if (!!chatEm.employeeStatus) {
          this.attachSubscriptions(
            this.membersService.editTaskEmployeeStatus(chatEm.employeeStatus.id, {status_id: event.container.id}, this.company.id)
            .pipe(
              switchMap(val => {
                console.log("drop RES1", val)
                if ((this.iAmEmployee?.is_manager == 1 || this.checkIsManager(this.task, this.company, this.user)) && [3,4,6,97,98,99].includes(+event.container.id)) {
                  console.log("drop RES2", val)
                  return this.membersService.getTaskEmployeeStatuses({task_operation_id: this.work.id, status_id: getLessStatuses(event.container.id), employee_id: chatEm.employee_id}, this.company.id, '2').pipe(
                    switchMap(res => {
                      console.log("drop RES3", res)
                      if (res.length == 1 && !res[0].discussion_id) {
                        console.log("drop RES4", val)
                        let questionAlert =  this.bottomSheet.open(BackJobToPrevStatusComponent, {
                          hasBackdrop: true,
                          backdropClass: 'bottom-sheed-backdrop',
                          data: {
                            msg: marker(`Do you really want to convert job personal status to: '${this.getOperationStatusName(+event.container.id)}'?`)
                          }
                        });
                    
                        return questionAlert.afterDismissed()
                        .pipe(
                          switchMap(data => {
                            if (data && data.message == 'no') {
                              console.log("no");
                              return of(val)
                            } else if (data && data.message == 'yes') {
                              return this.membersService.editTaskEmployeeStatus(res[0].id, {status_id: event.container.id}, this.company.id).pipe(
                                map(() => val)
                              )
                            } else {
                              return of(val)
                            }
                          })
                        )
                      } else {
                        return of(val)
                      }
                    })
                  )
                } else {
                  return of(val)
                }
              })
            ).subscribe(resp => {
              console.log("DQWEQWEQW#!@EWQEQDSA", resp)
              if (event.item.data.is_manager == 0 && this.chat.employeesStatuses.find(z => z.employee_id == event.item.data.employee_id)) {
                this.chat.employeesStatuses.find(z => z.employee_id == event.item.data.employee_id).status_id = resp.status_id
                this.chat.employeesStatuses.find(z => z.employee_id == event.item.data.employee_id).status = this.statuses.find(b => b.id == resp.status_id)
              }
              this.updateDiscussion(this.chat_id)
              this.refreshService.refreshChat$.next({company_id: this.company.id, chat_id: this.chat_id});
              this.initRefreshBoard()
            },
            error => {
              this.showError(error)
              transferArrayItem(
                event.container.data,
                event.previousContainer.data,
                event.currentIndex,
                event.previousIndex,
              );
            })
          )
        } else {
          this.attachSubscriptions(
            this.membersService.addTaskEmployeeStatus(Object.assign({status_id: event.container.id}, {
              company_id: this.company.id,
              task_id: this.task.id,
              task_operation_id: this.work.id,
              discussion_id: this.chat_id,
              employee_id: event.item.data.employee_id
            }), this.company.id)
            .pipe(
              switchMap(val => {
                console.log("drop RES1", val)
                if ((this.iAmEmployee?.is_manager == 1 || this.checkIsManager(this.task, this.company, this.user)) && [3,4,6,97,98,99].includes(+event.container.id)) {
                  console.log("drop RES2", val)
                  return this.membersService.getTaskEmployeeStatuses({task_operation_id: this.work.id, status_id: getLessStatuses(event.container.id), employee_id: chatEm.employee_id}, this.company.id, '2').pipe(
                    switchMap(res => {
                      console.log("drop RES3", res)
                      if (res.length == 1 && !res[0].discussion_id) {
                        console.log("drop RES4", val)
                        let questionAlert =  this.bottomSheet.open(BackJobToPrevStatusComponent, {
                          hasBackdrop: true,
                          backdropClass: 'bottom-sheed-backdrop',
                          data: {
                            msg: marker(`Do you really want to convert job personal status to: '${this.getOperationStatusName(+event.container.id)}'?`)
                          }
                        });
                    
                        return questionAlert.afterDismissed()
                        .pipe(
                          switchMap(data => {
                            if (data && data.message == 'no') {
                              console.log("no");
                              return of(val)
                            } else if (data && data.message == 'yes') {
                              return this.membersService.editTaskEmployeeStatus(res[0].id, {status_id: event.container.id}, this.company.id).pipe(
                                map(() => val)
                              )
                            } else {
                              return of(val)
                            }
                          })
                        )
                      } else {
                        return of(val)
                      }
                    })
                  )
                } else {
                  return of(val)
                }
              })
            ).subscribe(resp => {
              if (event.item.data.is_manager == 0 && this.chat.employeesStatuses.find(z => z.employee_id == event.item.data.employee_id)) {
                this.chat.employeesStatuses.find(z => z.employee_id == event.item.data.employee_id).status_id = resp.status_id
                this.chat.employeesStatuses.find(z => z.employee_id == event.item.data.employee_id).status = this.statuses.find(b => b.id == resp.status_id)
              }
              this.updateDiscussion(this.chat_id)
              this.refreshService.refreshChat$.next({company_id: this.company.id, chat_id: this.chat_id});
              this.initRefreshBoard()
            },
            error => {
              this.showError(error)
              transferArrayItem(
                event.container.data,
                event.previousContainer.data,
                event.currentIndex,
                event.previousIndex,
              );
            })
          )
        }
      }
    }
  }

  emplChangeStatus(status) {
    console.log("emplChangeStatus", status)
    if ([4,98,99].includes(status.id)) {
      return
    }

    let relativeEmpls = this.chatEmlStats.filter(t => status.empl.filter(b => b.id == t.id).length == 0)
    console.log("this.chatEmlStats", this.chatEmlStats)
    console.log("relativeEmpls", relativeEmpls)
    if (relativeEmpls.length > 1) {
      const dialogRef = this.dialog.open(MobPersonalStatusesComponent, {
        panelClass: ['chats_modal', 'desk_statuses'],
        data: {
          company: this.company,
          task: this.task,
          work: this.work,
          chat: this.chat,
          host: this.host,
          imgRoute: this.imgRoute,
          user: this.user,
          statuses: this.statuses,
          selectedStatus: status,
          chatEmlStats: this.chatEmlStats,
          iAmEmployee: this.iAmEmployee,
          updateDiscussion: () => {
            this.updateDiscussion(this.chat_id)
            this.refreshService.refreshChat$.next({company_id: this.company.id, chat_id: this.chat_id})
            this.initRefreshBoard()
          }
        }
      });
  
      this.attachSubscriptions(
        dialogRef.afterClosed().subscribe(result => {
          this.updateDiscussion(this.chat_id)
          this.refreshService.refreshChat$.next({company_id: this.company.id, chat_id: this.chat_id})
          this.initRefreshBoard()
        })
      )
    } else {
      this.addEmlChangeStatus(status)
    }
    // [4,98,99]
  }

  managerChangeStatus(status) {
    if (status.id == this.chat.status_id) {
      return
    }
    console.log("managerChangeStatus", status)
    console.log("managerChangeStatus", status.id)
    let relativeEmpls = this.chatEmlStats.filter(t => status.empl.filter(b => b.id == t.id).length == 0)

    if (relativeEmpls.length == 1) {
      let statEmpl = relativeEmpls[0]
      if (statEmpl.is_partner) {
        if (!!statEmpl.partnerCompanyStatus) {
          this.attachSubscriptions(
            this.membersService.editPartnerStatus(statEmpl.partnerCompanyStatus.id, {status_id: status.id}, this.company.id)
            .pipe(
              switchMap(val => {
                if ([3,4,6,97,98,99].includes(status.id)) {
                  return this.membersService.getTaskPartnerStatuses({task_operation_id: this.work.id, status_id: getLessStatuses(status.id), partner_company_id: statEmpl.partner_company_id}, this.company.id, '2').pipe(
                    switchMap(res => {
                      console.log("drop RES3", res)
                      if (res.length == 1 && !res[0].discussion_id) {
                        console.log("drop RES4", val)
                        let questionAlert =  this.bottomSheet.open(BackJobToPrevStatusComponent, {
                          hasBackdrop: true,
                          backdropClass: 'bottom-sheed-backdrop',
                          data: {
                            msg: marker(`Do you really want to convert personal partner status in job to: '${this.getOperationStatusName(status.id)}'?`)
                          }
                        });
                    
                        return questionAlert.afterDismissed()
                        .pipe(
                          switchMap(data => {
                            if (data && data.message == 'no') {
                              console.log("no");
                              return of(val)
                            } else if (data && data.message == 'yes') {
                              return this.membersService.editPartnerStatus(res[0].id, {status_id: status.id}, this.company.id).pipe(
                                map(() => val)
                              )
                            } else {
                              return of(val)
                            }
                          })
                        )
                      } else {
                        return of(val)
                      }
                    })
                  )
                } else {
                return of(val)
                }
              })
            ).subscribe(res => {
              this.updateDiscussion(this.chat_id)
              this.refreshService.refreshChat$.next({company_id: this.company.id, chat_id: this.chat_id})
              this.initRefreshBoard()
            })
          )
        } else {
          this.attachSubscriptions(
            this.membersService.addPartnerStatus({
              status_id: status.id,
              company_id: this.company.id,
              task_id: this.task.id,
              task_operation_id: this.work.id,
              discussion_id: this.chat.id,
              partner_company_id: statEmpl.partner_company_id
            }, this.company.id)
            .pipe(
              switchMap(val => {
                if ([3,4,6,97,98,99].includes(status.id)) {
                  return this.membersService.getTaskPartnerStatuses({task_operation_id: this.work.id, status_id: getLessStatuses(status.id), partner_company_id: statEmpl.partner_company_id}, this.company.id, '2').pipe(
                    switchMap(res => {
                      console.log("drop RES3", res)
                      if (res.length == 1 && !res[0].discussion_id) {
                        console.log("drop RES4", val)
                        let questionAlert =  this.bottomSheet.open(BackJobToPrevStatusComponent, {
                          hasBackdrop: true,
                          backdropClass: 'bottom-sheed-backdrop',
                          data: {
                            msg: marker(`Do you really want to convert personal partner status in job to: '${this.getOperationStatusName(status.id)}'?`)
                          }
                        });
                    
                        return questionAlert.afterDismissed()
                        .pipe(
                          switchMap(data => {
                            if (data && data.message == 'no') {
                              console.log("no");
                              return of(val)
                            } else if (data && data.message == 'yes') {
                              return this.membersService.editPartnerStatus(res[0].id, {status_id: status.id}, this.company.id).pipe(
                                map(() => val)
                              )
                            } else {
                              return of(val)
                            }
                          })
                        )
                      } else {
                        return of(val)
                      }
                    })
                  )
                } else {
                return of(val)
                }
              })
            ).subscribe(resp => {
              this.updateDiscussion(this.chat_id)
              this.refreshService.refreshChat$.next({company_id: this.company.id, chat_id: this.chat_id})
              this.initRefreshBoard()
            })
          )
        }
      } else if (statEmpl.is_partner_employee) {
        if (!!statEmpl.employeeStatus?.id) {
          this.attachSubscriptions(
            this.membersService.editPartnerEmployeeStatus(statEmpl.employeeStatus.id, {status_id: status.id}, this.company.id)
            .pipe(
              switchMap(val => {
                console.log("drop RES1", val)
                if ((this.iAmEmployee?.is_manager == 1 || this.checkIsManager(this.task, this.company, this.user)) && [3,4,6,97,98,99].includes(status.id)) {
                  console.log("drop RES2", val)
                  return this.membersService.getTaskPartnerEmployeeStatuses({task_operation_id: this.work.id, status_id: getLessStatuses(status.id), partner_employee_id: statEmpl.partner_employee_id, partner_company_id: this.chat.company_id}, this.company.id, '2').pipe(
                    switchMap(res => {
                      console.log("drop RES3", res)
                      if (res.length == 1 && !res[0].discussion_id) {
                        console.log("drop RES4", val)
                        let questionAlert =  this.bottomSheet.open(BackJobToPrevStatusComponent, {
                          hasBackdrop: true,
                          backdropClass: 'bottom-sheed-backdrop',
                          data: {
                            msg: marker(`Do you really want to convert personal partner employee status in job to: '${this.getOperationStatusName(status.id)}'?`)
                          }
                        });
                    
                        return questionAlert.afterDismissed()
                        .pipe(
                          switchMap(data => {
                            if (data && data.message == 'no') {
                              console.log("no");
                              return of(val)
                            } else if (data && data.message == 'yes') {
                              return this.membersService.editPartnerEmployeeStatus(res[0].id, {status_id: status.id}, this.company.id).pipe(
                                map(() => val)
                              )
                            } else {
                              return of(val)
                            }
                          })
                        )
                      } else {
                        return of(val)
                      }
                    })
                  )
                } else {
                  return of(val)
                }
              })
            ).subscribe(res => {
              this.updateDiscussion(this.chat_id)
              this.refreshService.refreshChat$.next({company_id: this.company.id, chat_id: this.chat_id})
              this.initRefreshBoard()
            })
          )
        } else {
          this.attachSubscriptions(
            this.membersService.addPartnerEmployeeStatus({
              status_id: status.id,
              company_id: this.company.id,
              task_id: this.task.id,
              task_operation_id: this.work.id,
              discussion_id: this.chat.id,
              partner_company_id: statEmpl.partner_company_id,
              partner_employee_id: statEmpl.partner_employee_id
            }, this.company.id)
            .pipe(
              switchMap(val => {
                console.log("drop RES1", val)
                if ((this.iAmEmployee?.is_manager == 1 || this.checkIsManager(this.task, this.company, this.user)) && [3,4,6,97,98,99].includes(status.id)) {
                  console.log("drop RES2", val)
                  return this.membersService.getTaskPartnerEmployeeStatuses({task_operation_id: this.work.id, status_id: getLessStatuses(status.id), partner_employee_id: statEmpl.partner_employee_id, partner_company_id: this.chat.company_id}, this.company.id, '2').pipe(
                    switchMap(res => {
                      console.log("drop RES3", res)
                      if (res.length == 1 && !res[0].discussion_id) {
                        console.log("drop RES4", val)
                        let questionAlert =  this.bottomSheet.open(BackJobToPrevStatusComponent, {
                          hasBackdrop: true,
                          backdropClass: 'bottom-sheed-backdrop',
                          data: {
                            msg: marker(`Do you really want to convert personal partner employee status in job to: '${this.getOperationStatusName(status.id)}'?`)
                          }
                        });
                    
                        return questionAlert.afterDismissed()
                        .pipe(
                          switchMap(data => {
                            if (data && data.message == 'no') {
                              console.log("no");
                              return of(val)
                            } else if (data && data.message == 'yes') {
                              return this.membersService.editPartnerEmployeeStatus(res[0].id, {status_id: status.id}, this.company.id).pipe(
                                map(() => val)
                              )
                            } else {
                              return of(val)
                            }
                          })
                        )
                      } else {
                        return of(val)
                      }
                    })
                  )
                } else {
                  return of(val)
                }
              })
            ).subscribe(res => {
              this.updateDiscussion(this.chat_id)
              this.refreshService.refreshChat$.next({company_id: this.company.id, chat_id: this.chat_id})
              this.initRefreshBoard()
            })
          )
        }
      } else {
        if (!!statEmpl.employeeStatus) {
          this.attachSubscriptions(
            this.membersService.editTaskEmployeeStatus(statEmpl.employeeStatus.id, {status_id: status.id}, this.company.id)
            .pipe(
              switchMap(val => {
                if ([3,4,6,97,98,99].includes(status.id)) {
                  return this.membersService.getTaskEmployeeStatuses({task_operation_id: this.work.id, status_id: getLessStatuses(status.id), employee_id: statEmpl.employee_id}, this.company.id, '2').pipe(
                    switchMap(res => {
                      console.log("manager Change RES3", res)
                      if (res.length == 1 && !res[0].discussion_id) {
                        console.log("manager Change RES4", val)
                        let questionAlert =  this.bottomSheet.open(BackJobToPrevStatusComponent, {
                          hasBackdrop: true,
                          backdropClass: 'bottom-sheed-backdrop',
                          data: {
                            msg: marker(`Do you really want to convert job personal status to: '${this.getOperationStatusName(status.id)}'?`)
                          }
                        });
                    
                        return questionAlert.afterDismissed()
                        .pipe(
                          switchMap(data => {
                            if (data && data.message == 'no') {
                              console.log("no");
                              return of(val)
                            } else if (data && data.message == 'yes') {
                              return this.membersService.editTaskEmployeeStatus(res[0].id, {status_id: status.id}, this.company.id).pipe(
                                map(() => val)
                              )
                            } else {
                              return of(val)
                            }
                          })
                        )
                      } else {
                        return of(val)
                      }
                    })
                  )
                } else {
                return of(val)
                }
              })
            ).subscribe(res => {
              this.updateDiscussion(this.chat_id)
              this.refreshService.refreshChat$.next({company_id: this.company.id, chat_id: this.chat_id})
              this.initRefreshBoard()
            })
          )
        } else {
          this.attachSubscriptions(
            this.membersService.addTaskEmployeeStatus({
              status_id: status.id,
              company_id: this.company.id,
              task_id: this.task.id,
              task_operation_id: this.work.id,
              discussion_id: this.chat.id,
              employee_id: statEmpl.employee_id
            }, this.company.id)
            .pipe(
              switchMap(val => {
                if ([3,4,6,97,98,99].includes(status.id)) {
                  return this.membersService.getTaskEmployeeStatuses({task_operation_id: this.work.id, status_id: getLessStatuses(status.id), employee_id: statEmpl.employee_id}, this.company.id, '2').pipe(
                    switchMap(res => {
                      console.log("manager Change RES3", res)
                      if (res.length == 1 && !res[0].discussion_id) {
                        console.log("manager Change RES4", val)
                        let questionAlert =  this.bottomSheet.open(BackJobToPrevStatusComponent, {
                          hasBackdrop: true,
                          backdropClass: 'bottom-sheed-backdrop',
                          data: {
                            msg: marker(`Do you really want to convert job personal status to: '${this.getOperationStatusName(status.id)}'?`)
                          }
                        });
                    
                        return questionAlert.afterDismissed()
                        .pipe(
                          switchMap(data => {
                            if (data && data.message == 'no') {
                              console.log("no");
                              return of(val)
                            } else if (data && data.message == 'yes') {
                              return this.membersService.editTaskEmployeeStatus(res[0].id, {status_id: status.id}, this.company.id).pipe(
                                map(() => val)
                              )
                            } else {
                              return of(val)
                            }
                          })
                        )
                      } else {
                        return of(val)
                      }
                    })
                  )
                } else {
                return of(val)
                }
              })
            ).subscribe(resp => {
              this.updateDiscussion(this.chat_id)
              this.refreshService.refreshChat$.next({company_id: this.company.id, chat_id: this.chat_id})
              this.initRefreshBoard()
            })
          )
        }
      }
    } else {
      const dialogRef = this.dialog.open(MobPersonalStatusesComponent, {
        panelClass: ['chats_modal', 'desk_statuses'],
        data: {
          company: this.company,
          task: this.task,
          work: this.work,
          chat: this.chat,
          host: this.host,
          imgRoute: this.imgRoute,
          user: this.user,
          statuses: this.statuses,
          selectedStatus: status,
          chatEmlStats: this.chatEmlStats,
          iAmEmployee: this.iAmEmployee,
          updateDiscussion: () => {
            this.updateDiscussion(this.chat_id)
            this.refreshService.refreshChat$.next({company_id: this.company.id, chat_id: this.chat_id})
            this.initRefreshBoard()
          }
        }
      });
  
  
      this.attachSubscriptions(
        dialogRef.afterClosed().subscribe(result => {
          this.updateDiscussion(this.chat_id)
          this.refreshService.refreshChat$.next({company_id: this.company.id, chat_id: this.chat_id})
          this.initRefreshBoard()
        })
      )
    }

  }
  
  addEmlChangeStatus(status) {
    console.log("addEmlChangeStatus", status)
    if (status.is_lock == 1) {
      return
    }
    this.personalStatusForm.patchValue({
      status_id: status.id
    })
  }
  
  allDone(job) {
    return job.automationScenarios.length == job.automationScenarios.filter(x => x.is_active == 0).length
  }
  
  someDone(job) {
    return job.automationScenarios.filter(x => x.is_active == 0).length != 0
  }

  getJob(id) {
    if (!this.task || this.task.operations.filter(x => x.id == id).length == 0) {
      return
    }

    this.work = this.task.operations.find(x => x.id == id);
    console.log("this.work", {...this.work});

    // this.work.addEmployee = []
    // this.work.employees.forEach(element => {
    //   if (!this.work.addEmployee.includes(element) && element.discussion_id == this.chat_id) {
    //     this.work.addEmployee.push(element)
    //   }
    // });
    // console.log("CHECK", this.work.employees.filter(x => x.discussion_id == this.chat_id || x.discussion_id == 0 || !x.discussion_id))

    this.work.employees = this.work.employees.filter(x => x.discussion_id == this.chat_id || x.discussion_id == 0 || !x.discussion_id)
    this.work.mainEmployees = this.work.mainEmployees.filter(x => x.discussion_id == this.chat_id || x.discussion_id == 0 || !x.discussion_id)
    this.work.addEmployee = this.work.addEmployee.filter(x => x.discussion_id == this.chat_id || x.discussion_id == 0 || !x.discussion_id)
    this.work.addEmployee.forEach(x => {
      if (!x.employeeStatus && this.chat.employeesStatuses.find(z => z.employee_id == x.employee_id)) {
        x.employeeStatus = this.chat.employeesStatuses.find(z => z.employee_id == x.employee_id)
      }
    })
    this.work.allChatEmpl = [...this.work.mainEmployees, ...this.work.addEmployee].sort((a,b) => b.is_manager - a.is_manager);
    this.chatEmlStats = [];
    this.chatEmlStats = [...this.work.mainEmployees.slice(), ...this.work.addEmployee.slice()];
    this.chatEmlStats.forEach(x => {
      if (x.hasOwnProperty('employeeStatus')) {
        x.employeeStatus = null;
      } else if (x.hasOwnProperty('partnerCompanyStatus')) {
        x.partnerCompanyStatus = null;
      }
    });

    this.chatEmlStats.forEach(x => {
      if (x.is_partner_employee && this.chat.partnerEmployeesStatuses.find(b => b.partner_employee_id == x.partner_employee_id)) {
        x.employeeStatus = this.chat.partnerEmployeesStatuses.find(b => b.partner_employee_id == x.partner_employee_id)
      }
      if (x.is_partner && this.chat.partnerCompaniesStatuses.find(b => b.partner_company_id == x.partner_company_id)) {
        x.partnerCompanyStatus = this.chat.partnerCompaniesStatuses.find(b => b.partner_company_id == x.partner_company_id)
      }
    });

    this.attachSubscriptions(
      this.membersService.getMembers({task_id: this.task.id, task_operation_id: this.work.id, discussion_id: this.chat_id}, this.company.id).subscribe(resp => {
        console.log("chatEmlStats", resp)
        this.chatEmlStats.forEach(x => {
          if (!x.is_partner && !x.is_partner_employee && resp.find(y => x.employee_id == y.employee_id)) {
            x.employeeStatus = resp.find(y => x.employee_id == y.employee_id).employeeStatus
          }
        })

        this.statuses.map(stat => {
          stat.empl = [];
          stat.empl.push(...this.chatEmlStats.filter(x => (x.employeeStatus && x.employeeStatus.status_id == stat.id) || (x.partnerCompanyStatus && x.partnerCompanyStatus.status_id == stat.id)))
        })
        this.dont_show_ids = []
        if (!(this.checkIsManager(this.task, this.company, this.user) || this.ifChatClientModerator(this.task, this.work))) {
          this.statuses.filter(x => x.is_lock == 1 && x.empl.length == 0).forEach(stat => {
            this.dont_show_ids.push(stat.id)
          })

          if (this.statuses.filter(x => x.is_lock == 0 && x.empl.length == 0).length === this.statuses.filter(x => x.is_lock == 0).length) {
            this.statuses.filter(x => x.is_lock == 0).forEach(stat => {
              this.dont_show_ids.push(stat.id)
            })
          }
        }
        // this.companyService.closeAllUserHover$.next(true);
        // this.companyService.closeAllOpened();
        
        console.log("this.statuses", this.statuses)
      })
    )
    
    if (!!this.task.clients) {
      this.work.clients = this.task.clients.filter(x => (!x.task_operation_id || x.task_operation_id == this.work.id) && (!x.discussion_id || x.discussion_id == this.chat_id));
    }
    this.work.managersArr = []
    this.task.managers.forEach(element => {
      if (!this.work.managersArr.includes(element)) {
        this.work.managersArr.push(element)
      }
    });
    this.task?.group?.managers.forEach(element => {
      if (!this.work.managersArr.includes(element)) {
        this.work.managersArr.push(element)
      }
    });

    
    if (this.company.employees.length > 0 && this.work.employees.filter(x => x.employee_id == this.company.employees[0].id).length > 0) {
      this.iAmEmployee = this.work.employees.find(x => (x.employee_id == this.company.employees[0].id) || (x.partner_company_id == this.company.id))
      this.personalStatusForm.patchValue({
        status_id: !!this.chat.employeeStatus ? this.chat.employeeStatus.status_id : this.chat.status_id
      })
    } else if (this.company.employees.length > 0 && this.work.employees.filter(x => x.partner_company_id == this.company.id).length > 0) {
      this.iAmEmployee = this.work.employees.find(x => x.partner_company_id == this.company.id)
    } else {
      this.iAmEmployee = undefined;
    }

    if (this.statusSub) {
      this.statusSub.unsubscribe();
    }

    this.statusSub = this.personalStatusForm.valueChanges.subscribe(resp => {
      console.log(resp);
      if (this.checkIsManager(this.task, this.company, this.user)) {
        return
      }
      console.log('resp1');
      if (this.is_sms_status) {
        this.is_sms_status = false;
      } else {
        console.log('resp2');
        console.log('resp2', resp, this.chat.employeeStatus.status_id, !!this.chat.employeeStatus);
        
        if (resp.status_id != this.chat?.employeeStatus?.status_id) {
          if (!!this.chat.employeeStatus) {
            console.log('resp3');
            this.attachSubscriptions(
              this.membersService.editTaskEmployeeStatus(this.chat.employeeStatus.id, this.personalStatusForm.value, this.company.id).pipe(
                switchMap(val => {
                  console.log('resp4');
                  if (this.iAmEmployee?.is_manager == 1 && [3,4,6,97,98,99].includes(this.personalStatusForm.value.status_id)) {
                    console.log('resp5');
                    return this.membersService.getTaskEmployeeStatuses({task_operation_id: this.work.id, status_id: getLessStatuses(this.personalStatusForm.value.status_id), employee_id: this.iAmEmployee?.employee_id}, this.company.id, '2').pipe(
                      switchMap(res => {
                        console.log("manager Change RES3", res)
                        if (res.length == 1 && !res[0].discussion_id) {
                          console.log("manager Change RES4", val)
                          let questionAlert =  this.bottomSheet.open(BackJobToPrevStatusComponent, {
                            hasBackdrop: true,
                            backdropClass: 'bottom-sheed-backdrop',
                            data: {
                              msg: marker(`Do you really want to convert job personal status to: '${this.getOperationStatusName(this.personalStatusForm.value.status_id)}'?`)
                            }
                          });
                      
                          return questionAlert.afterDismissed()
                          .pipe(
                            switchMap(data => {
                              if (data && data.message == 'no') {
                                console.log("no");
                                return of(val)
                              } else if (data && data.message == 'yes') {
                                return this.membersService.editTaskEmployeeStatus(res[0].id, {status_id: this.personalStatusForm.value.status_id}, this.company.id).pipe(
                                  map(() => val)
                                )
                              } else {
                                return of(val)
                              }
                            })
                          )
                        } else {
                          return of(val)
                        }
                      })
                    )
                  } else {
                  return of(val)
                  }
                })
              ).subscribe(resp => {
                this.isChangedPersonalStatus = true;
                this.chat.employeeStatus.status = this.statuses.find(x => x.id == resp.status_id)
                this.chat.employeeStatus.status_id = resp.status_id;
                this.updateDiscussion(this.chat_id)
                this.refreshService.refreshChat$.next({company_id: this.company.id, chat_id: this.chat_id})
                this.initRefreshBoard()
                console.log("UPDATE", this.chat.employeeStatus)
              })
            )
          } else {
            this.attachSubscriptions(
              this.membersService.addTaskEmployeeStatus(Object.assign(this.personalStatusForm.value, {
                company_id: this.company.id,
                task_id: this.task.id,
                task_operation_id: this.work.id,
                discussion_id: this.chat.id,
                employee_id: this.iAmEmployee?.employee_id
              }), this.company.id).pipe(
                switchMap(val => {
                  if (this.iAmEmployee?.is_manager == 1 && [3,4,6,97,98,99].includes(this.personalStatusForm.value.status_id)) {
                    return this.membersService.getTaskEmployeeStatuses({task_operation_id: this.work.id, status_id: getLessStatuses(this.personalStatusForm.value.status_id), employee_id: this.iAmEmployee?.employee_id}, this.company.id, '2').pipe(
                      switchMap(res => {
                        console.log("manager Change RES3", res)
                        if (res.length == 1 && !res[0].discussion_id) {
                          console.log("manager Change RES4", val)
                          let questionAlert =  this.bottomSheet.open(BackJobToPrevStatusComponent, {
                            hasBackdrop: true,
                            backdropClass: 'bottom-sheed-backdrop',
                            data: {
                              msg: marker(`Do you really want to convert job personal status to: '${this.getOperationStatusName(this.personalStatusForm.value.status_id)}'?`)
                            }
                          });
                      
                          return questionAlert.afterDismissed()
                          .pipe(
                            switchMap(data => {
                              if (data && data.message == 'no') {
                                console.log("no");
                                return of(val)
                              } else if (data && data.message == 'yes') {
                                return this.membersService.editTaskEmployeeStatus(res[0].id, {status_id: this.personalStatusForm.value.status_id}, this.company.id).pipe(
                                  map(() => val)
                                )
                              } else {
                                return of(val)
                              }
                            })
                          )
                        } else {
                          return of(val)
                        }
                      })
                    )
                  } else {
                  return of(val)
                  }
                })
              ).subscribe(resp => {
                this.isChangedPersonalStatus = true;
                if (!this.chat.employeeStatus) {
                  this.chat.employeeStatus = {}
                }
                this.chat.employeeStatus.status = this.statuses.find(x => x.id == resp.status_id)
                this.chat.employeeStatus.status_id = resp.status_id;
                this.chat.employeeStatus.id = resp.id;
                this.updateDiscussion(this.chat_id)
                this.refreshService.refreshChat$.next({company_id: this.company.id, chat_id: this.chat_id})
                this.initRefreshBoard()
                console.log("ADD", this.chat.employeeStatus)
              })
            )
          }

        }
      }
    })
    // console.log("iAmEmployee", this.iAmEmployee);
    console.log("JOB", this.work);
  
    // this.getAdditionalEmpl()
  }



  
  // getAdditionalEmpl() {
  //   this.attachSubscriptions(
  //     this.taskService.getAdditionalEmpl(this.work.id).pipe(
  //       tap(val => {
  //         this.work.addEmployee = val
  //       })
  //     ).subscribe(resp => {
  //       console.log("this.work", this.work)
  //     })
  //   )
  // }

   
  copyScheme(item) {
    this.layoutService.showSnackBar({name: item.name + ' scheme: ' + this.getSchemeContent(item)}, marker("Copied"), SnackBarItem)
  }

  getBookmarks() {
    this.attachSubscriptions(
      this.companyService.getBookmarks(this.company.id, {employee_id: this.company.employees[0].id, section: "Card", type: "Clipboard"}).subscribe(res => {
        this.bookmarks = res;
        console.log("open card bookmarks", this.bookmarks);
      })
    )
  }

  createNewOpenCardClipboard(e, task) {
    e.preventDefault();

    console.log("createNewOpenCardClipboard", task)

    const dialogRef = this.dialog.open(CardClipboardComponent, {
      backdropClass: ['backdrop_under_header'],
      panelClass: !this.is_mobile ? ['show_header'] : '',
      hasBackdrop: true,
      data: {
        company: this.company,
        company_id: this.company.id,
        task: task
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        
        if (!!result && result.event == 'save') {
          this.getBookmarks();
        }
      })
    )
  }
  
  getSchemeContent(item) {
    if (item.settings && item.settings.devScheme) {
      let string = ``;

      item.settings.devScheme.forEach((el,i) => {
        if (el.name == 'Separator') {
          if (!el.not_use) {
            string += `${el.value}`
          }
        } else if (el.name == 'Card') {
          if (el.value == 'group') {
            if (this.task && this.task.group && this.task.group.name) {
              string += `${this.task.group.name}`
            } else {
              if (item.settings.devScheme[i+i]) {
                item.settings.devScheme[i+i].not_use = true;
              }
            }
          } else if (el.value == 'link') {
            if (this.task.acm) {
              string += `${this.origin}/task/${this.task.acm}`
            } else {
              if (item.settings.devScheme[i+i]) {
                item.settings.devScheme[i+i].not_use = true;
              }
            }
          } else if (el.value == 'firstConsistOfName') {
            if (this.task.consistOfTasks && this.task.consistOfTasks.length) {
              string += `${this.task.consistOfTasks[0].name}`
            } else {
              if (item.settings.devScheme[i+i]) {
                item.settings.devScheme[i+i].not_use = true;
              }
            }
          } else if (el.value == 'custom_id') {
            if (this.task && this.task.custom_id) {
              string += `${this.task.custom_id}`
            } else {
              if (item.settings.devScheme[i+i]) {
                item.settings.devScheme[i+i].not_use = true;
              }
            }
          } else {
            if (this.task) {
              string += `${this.task[el.value]}`
            }
          }
        }
      });
      
      return string
    }
  }
  
  
  toggleSchemeEditing(e) {
    e.preventDefault();
    e.stopPropagation();
    this.is_scheme_editing = !this.is_scheme_editing;
  }

  editScheme(e, trigger:MatMenuTrigger, scheme, task) {
    e.preventDefault();
    e.stopPropagation();

    console.log("trigger", trigger)
    
    trigger.closeMenu();

    console.log("createNewOpenCardClipboard", task)

    const dialogRef = this.dialog.open(CardClipboardComponent, {
      backdropClass: ['backdrop_under_header'],
      panelClass: !this.is_mobile ? ['show_header'] : '',
      hasBackdrop: true,
      data: {
        company: this.company,
        company_id: this.company.id,
        task: task,
        scheme: scheme
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        
        if (!!result && result.event == 'edit') {
          this.getBookmarks();
        }
      })
    )
  }

  deleteScheme(e, trigger:MatMenuTrigger, scheme) {
    e.preventDefault();
    e.stopPropagation();

    trigger.closeMenu();
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: scheme,
        target: marker("Card Clipboard scheme")
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        this.attachSubscriptions(
          this.companyService.deleteBookmark(scheme.id).subscribe(res => {
            this.bookmarks.splice(this.bookmarks.findIndex(p => p.id == scheme.id), 1);
            console.log("deleteScheme", this.bookmarks);
          }, error => {
            this.layoutService.showSnackBar({name: ''}, error, SnackBarItem);
          })
        )
      }
    });
  }

  
  // work: job,
  // isTaskCard: true
  viewVideo(file, job?, hasData?) {
    console.log("viewVideo file, job", file, job)
    if (!this.is_mobile) {
      const dialogRef = this.dialog.open(VideoViewerComponent, {
        panelClass: 'video_viewer',
        autoFocus: false,
        data: {
          file: file,
          chat_id: this.chat_id,
          task: this.task,
          work: file.task_operation_id ? job : undefined,
          tasks: this.tasks,
          operationsValues: this.operationsValues,
          company: this.company,
          activeLang: this.activeLang,
          user: this.user,
          initCompanyId: this.company.id,
          chat: this.chat
        }
      });
    } else {
      const dialogRef = this.dialog.open(MobFmViewComponent, {
        backdropClass: ['mob_video_viewer_backdrop'],
        panelClass: 'mob_video_viewer',
        autoFocus: false,
        data: {
          file: file,
          task: this.task,
          chat_id: this.chat_id,
          work: file.task_operation_id ? job : undefined,
          tasks: this.tasks,
          initCompanyId: this.company.id,
          operationsValues: this.operationsValues,
          company: this.company,
          activeLang: this.activeLang,
          user: this.user,
          chat: this.chat
        }
      });
    }
  }

  openTargetValues(info, element) {
    let initData:any = {
      company: this.company,
      chat: element
    }

    initData.target = {
      task_id: info[0],
      task_operation_id: info[1],
      discussion_id: info[2]
    }
    if (element.company_id != this.company.id) {
      initData.target.partner_company_id = element.company_id
    }
    const dialogRef = this.dialog.open(TargetParametersComponent, {
      backdropClass: ['parameters_modal_backdrop'],
      panelClass: ['without_paddings_modal', 'parameters_modal'],
      data: initData
    });

    // this.attachSubscriptions(
    //   dialogRef.afterClosed().subscribe(result => {
    //     this.refreshService.refreshTask$.next({company_id: this.company_id, task_id: element.id})
    //   })
    // )
  }
  
  getOperationStatusName(id) {
    switch (+id) {
      case 1:
        return "Waiting";
        break;
      case 2:
        return "In process";
        break;
      case 3:
        return "To approve";
        break;
      case 4:
        return "Approved";
        break;
      case 5:
        return "To correct";
        break;
      case 6:
        return "Sources Needed";
        break;
      case 97:
        return "On hold";
        break;
      case 98:
        return "Ready";
        break;
      case 99:
        return "Canceled";
        break;
    }
  }

  
  goToLink(val) {
    window.open(val, '_blank').focus();
  }

  getFiles() {
    this.isFilesShow = true;
    this.filesSub = forkJoin([
      this.fileService.getFilesForChat(this.company.id, {discussion_id: this.chat.id}, '/').pipe(
        tap((val) => {
          this.filesData[0].count = val.headers.get('x-pagination-total-count')
          this.filesData[0].files = val.body
        })
      ),
      this.fileService.getFilesForChat(this.company.id, {task_operation_id: this.chat.task_operation_id}, '/').pipe(
        tap((val) => {
          this.filesData[1].count = val.headers.get('x-pagination-total-count')
          this.filesData[1].files = val.body
        })
      ),
      this.fileService.getFilesForChat(this.company.id, {task_id: this.chat.task_id, task_operation_id: 0}, '/').pipe(
        tap((val) => {
          this.filesData[2].count = val.headers.get('x-pagination-total-count')
          this.filesData[2].files = val.body
        })
      )

    ]).subscribe(resp => {
      console.log("getFiles",this.filesData);
    })
  
  }

  getTask() {
    this.taskSub = this.taskService.getOneTaskExpand(this.company.id, this.chat.task.id).pipe(
      map(x => x.body),
      map(arr => {
        let data = casesModel(arr, [], "get");
        return {
          arr: data.arr,
          data: data.tasks ? data.tasks : []
        };
      }),
      tap(val => this.task = val.arr[0]),
      map(u => u.arr[0]),
      switchMap(x => this.neededData(x)),
      switchMap((resp:any) => {
        if (this.company.permissions.includes('owner') || this.company.permissions.includes('admin') || this.company.permissions.includes('manager') || this.company.permissions.includes('see_task_channels')) {
          let expPermFilter:any = {task_id: this.task.id}
          if (this.company.id != this.task.company_id) {
            expPermFilter.partner_company_id = this.task.company_id
          }
          return this.taskService.getExpandPermUrls('1', this.company.id, expPermFilter, '100').pipe(
            tap(rslt => {
              resp.uniqueChannels = [];
              rslt.sort((a, b) => {
                if (a.content_status_id === 13 && b.content_status_id !== 13) {
                  return -1;
                } else if (a.content_status_id === 1 && b.content_status_id !== 13 && b.content_status_id !== 1) {
                  return -1;
                } else if (b.content_status_id === 13 || b.content_status_id === 1) {
                  return 1;
                } else {
                  return 0;
                }
              });
              rslt.filter(p => !!p.permission_prepare_upload).forEach(channel => {
                if (channel.channel && [1,2,3,4].includes(channel.channel.platform_id) && !!channel.channel.channel_account_id) {
                  if (resp.uniqueChannels.filter(ch => ch.channel_id == channel.channel_id).length == 0) {
                    resp.uniqueChannels.push(channel)
                  } else {
                    if (!([1, 13].includes(resp.uniqueChannels.find(ch => ch.channel_id == channel.channel_id).content_status_id) && !resp.uniqueChannels.find(ch => ch.channel_id == channel.channel_id).content_url)) {
                      resp.uniqueChannels.splice(resp.uniqueChannels.indexOf(resp.uniqueChannels.find(ch => ch.channel_id == channel.channel_id)), 1, channel)
                    }
                  }
                }
              });
            }),
            map(() => resp)
          )
        } else {
          return of(resp)
        }
      }),
    ).subscribe(resp => {
      console.log("GetTask", resp)
      this.task = resp;
      this.getJob(this.chat.task_operation_id)
    })
  }

  outletClick(outlet, file) {
    console.log("outletClick", outlet, file);
    this.closeFileContext();
    this.closeContextTemplate();

    // if (outlet.channel.platform_id != 4) {
    //   this.attachSubscriptions(
    //     this.connectedAppsService.addChannelContent({
    //       company_id: this.company_id,
    //       task_id: this.task.id,
    //       channel_id: outlet.channel.id,
    //       content_file_id: file.id,
    //     }).subscribe(res => {
    //       console.log(res)
    //     })
    //   )
    //   return
    // }


    if (outlet.content_status_id == 1 && !outlet.content_url) {
      console.log('create in selected')
      let _task = this.task || file.task;
      let _work = this.work;
      const dialogRef = this.dialog.open(PublishToOutletComponent, {
        disableClose: true,
        data: {
          host: this.host,
          imgRoute: this.imgRoute,
          activeLang: this.activeLang,
          user: this.user,
          company: this.company,
          company_id: this.company.id,
          target_company_id: this.task.company_id,
          profile: outlet,
          file: file,
          task: _task,
          work: _work,
        }
      });
  
      this.attachSubscriptions(
        dialogRef.afterClosed().subscribe(result => {
          console.log(result)
        })
      )
      
    } else {
      console.log('create in new outlet same as selected')
      this.attachSubscriptions(
        this.taskService.addProfile({
          task_id: this.task.id,
          channel_id: outlet.channel.id,
          content_type_id: outlet.content_type_id,
          content_status_id: 1
        }, this.company.id).pipe(
          switchMap(res => {
            return this.taskService.getTaskForManager(this.company ? this.company.id : this.company.id, this.task.id).pipe(
              map(el => {
                if (el[0]) {
                  if (this.work.id != "0") {
                    el[0].operations.find(x => x.id == this.work.id).is_active = true;
                    el[0].is_open = true;
                  } else {
                    el[0].is_open = false;
                  }
                  return casesModel(el, [], 'update').arr[0]
                } else {
                  return false
                }
              }),
              switchMap(resp => {
                let expPermFilter:any = {task_id: this.task.id}
                if (this.company.id != this.task.company_id) {
                  expPermFilter.partner_company_id = this.task.company_id
                }
                return this.taskService.getExpandPermUrls('1', this.company.id, expPermFilter, '100').pipe(
                  tap(rslt => {
                    resp.uniqueChannels = [];
                    rslt.sort((a, b) => {
                      if (a.content_status_id === 13 && b.content_status_id !== 13) {
                        return -1;
                      } else if (a.content_status_id === 1 && b.content_status_id !== 13 && b.content_status_id !== 1) {
                        return -1;
                      } else if (b.content_status_id === 13 || b.content_status_id === 1) {
                        return 1;
                      } else {
                        return 0;
                      }
                    });
                    rslt.filter(p => !!p.permission_prepare_upload).forEach(channel => {
                      if (channel.channel && [1,2,3,4].includes(channel.channel.platform_id) && !!channel.channel.channel_account_id) {
                        if (resp.uniqueChannels.filter(ch => ch.channel_id == channel.channel_id).length == 0) {
                          resp.uniqueChannels.push(channel)
                        } else {
                          if (!([1, 13].includes(resp.uniqueChannels.find(ch => ch.channel_id == channel.channel_id).content_status_id) && !resp.uniqueChannels.find(ch => ch.channel_id == channel.channel_id).content_url)) {
                            resp.uniqueChannels.splice(resp.uniqueChannels.indexOf(resp.uniqueChannels.find(ch => ch.channel_id == channel.channel_id)), 1, channel)
                          }
                        }
                      }
                    });
                  }),
                  map(() => resp)
                )
              }),
              tap(resp => {
                this.task = resp;
              }),
              map((resp) => resp.channels.find(x => x.id == res.id))
            )
          })
        ).subscribe(resOutlet => {
  
          let _task = this.task || file.task;
          let _work = this.work;
          const dialogRefDialog = this.dialog.open(PublishToOutletComponent, {
            disableClose: true,
            data: {
              host: this.host,
              imgRoute: this.imgRoute,
              activeLang: this.activeLang,
              user: this.user,
              company: this.company,
              company_id: this.company.id,
              target_company_id: this.task.company_id,
              profile: resOutlet,
              file: file,
              task: _task,
              work: _work,
            }
          });
      
          // this.attachSubscriptions(
          //   dialogRefDialog.afterClosed().subscribe(result => {
          //     console.log(result)
          //   })
          // )
        })
      )

    }

  }

  choiseNo() {
    this.choised = true;
  }

  choiseYes() {
    this.choised = true;
    this.attachSubscriptions(
      this.chatService.editChat(this.chat.id, this.personalStatusForm.value, this.company.id).subscribe(resp => {
        this.chat.status_id = resp.status_id;
      })
    )
  }

  postPushSettigns(post) {
    const pushSett = this.dialog.open(PushSettingsComponent, {
      panelClass: 'push_settigns_modal',
      data: {
        company: this.company,
        user: this.user,
        imgRoute: this.imgRoute,
        host: this.host,
        activeLang: this.activeLang, 
        chat: this.chat,
        employees: this.chatEmlStats,
        task: this.task,
        job: this.work,
        statuses: this.statuses,
        empl_status: this.company.permissions.includes('owner') || this.checkIsManager(this.task, this.company, this.user),
        operationsValues: this.operationsValues,
        post: post
      }
    });

    this.attachSubscriptions(
      pushSett.afterClosed().pipe(
        switchMap(close => {
          return this.pushNotificationsService.getPostNotificationEmployees(post.company_id, post.id).pipe(
            tap(res => {
              post.notEmployees = res
              post.selectedNotEmployees = res.filter(x => x.notificationSubscription && x.notificationSubscription.is_webpush == 1 && x.notificationSubscription.discussion_post_id);
            })
          )
        })
      ).subscribe(result => {
        console.log("closed postPushSettigns", result)
      })
    )
  }

  mobPushSettigns() {
    const pushSett = this.dialog.open(MobPushSettingsComponent, {
      backdropClass: ['mob_interface_backdrop'],
      panelClass: ['mob_interface_panel', 'animate__animated', 'animate__slideInUp'],
      data: {
        company: this.company,
        user: this.user,
        imgRoute: this.imgRoute,
        host: this.host,
        activeLang: this.activeLang, 
        chat: this.chat,
        employees: this.chatEmlStats,
        task: this.task,
        job: this.work,
        statuses: this.statuses,
        empl_status: this.company.permissions.includes('owner') || this.checkIsManager(this.task, this.company, this.user),
        operationsValues: this.operationsValues,
        type: 'notifications',
        type_prop: 'notifications',
        title: 'Notifications settings',
        target: 'Subscribe for notifications for new messages in this chat'
      }
    });

    this.attachSubscriptions(
      pushSett.afterClosed().subscribe(result => {
      })
    )
  }

  pushSettigns() {
    const pushSett = this.dialog.open(PushSettingsComponent, {
      panelClass: 'push_settigns_modal',
      data: {
        company: this.company,
        user: this.user,
        imgRoute: this.imgRoute,
        host: this.host,
        activeLang: this.activeLang, 
        chat: this.chat,
        employees: this.chatEmlStats,
        task: this.task,
        job: this.work,
        statuses: this.statuses,
        empl_status: this.company.permissions.includes('owner') || this.checkIsManager(this.task, this.company, this.user),
        operationsValues: this.operationsValues,
      }
    });

    this.attachSubscriptions(
      pushSett.afterClosed().subscribe(result => {
        this.getNotificationEmployees();
      })
    )
  }

  clickPost(e, post) {
    if (e.ctrlKey || e.metaKey) {

      if (!e.target.closest('.post-link')) {
        console.log("(ctrl || command) + click", e.sourceEvent);
        e.preventDefault();
        e.stopPropagation();
        e.stopImmediatePropagation();
        this.postCollection.toggle(post)
        return;
      }
    }
  }

  addToBoard() {
    const goToChat = this.dialog.open(AddChatToBoardComponent, {
      panelClass: 'add_to_board_modal',
      data: {
        company: this.company,
        user: this.user,
        imgRoute: this.imgRoute,
        host: this.host,
        activeLang: this.activeLang, 
        chat: this.chat,
        employees: this.chatEmlStats,
        task: this.task,
        job: this.work,
        statuses: this.statuses,
        empl_status: this.company.permissions.includes('owner') || this.checkIsManager(this.task, this.company, this.user),
        operationsValues: this.operationsValues,
      }
    });

    this.attachSubscriptions(
      goToChat.afterClosed().subscribe(result => {
        this.updateDiscussion(this.chat_id)
        this.refreshService.refreshChat$.next({company_id: this.company.id, chat_id: this.chat_id})
        this.initRefreshBoard()
      })
    )
  }

  openExecutors() {
    const dialogRef = this.dialog.open(ExecutorsComponent, {
      panelClass: 'open_task_dialog',
      data: {
        company: this.company,
        user: this.user,
        task: this.task,
        activeLang: this.activeLang,
        job: this.work,
        host: this.host,
        statuses: this.statuses,
        empl_status: this.company.permissions.includes('owner') || this.checkIsManager(this.task, this.company, this.user),
        operationsValues: this.operationsValues,
        chat: this.chat
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        this.updateDiscussion(this.chat_id)
        this.refreshService.refreshChat$.next({company_id: this.company.id, chat_id: this.chat_id})
        this.initRefreshBoard()
      })
    )
  }

  openConnectionChats() {
    const goToChatCon = this.dialog.open(ChatConnectionsComponent, {
      backdropClass: 'backdrop_under_header',
      panelClass: !this.is_mobile ? ['open_task_dialog', 'show_header'] : 'open_task_dialog',
      data: {
        company: this.company,
        user: this.user,
        empl_status: this.company.permissions.includes('owner') || this.company.permissions.includes('admin') || this.checkIsManager(this.task, this.company, this.user),
        chat: this.chat,
        chats: this.chats,
        is_mobile: this.is_mobile,
        imgRoute: this.imgRoute,
        operationsValues: this.operationsValues,
        host: this.host,
        activeLang: this.activeLang,
      }
    });

    this.attachSubscriptions(
      goToChatCon.afterClosed().subscribe(result => {
        this.updateDiscussion(this.chat_id)
        this.refreshService.refreshChat$.next({company_id: this.company.id, chat_id: this.chat_id})
        this.initRefreshBoard()
      })
    )
  }

  // public action: any = +this.sm.localStorageGetItem('chat_browser_action');
  // public actionStatuses: any = !!this.sm.localStorageGetItem('chat_browser_action_statuses') ? JSON.parse(this.sm.localStorageGetItem('chat_browser_action_statuses')) : [];

  changeAction(e) {
    console.log("changeAction", e);
    console.log("changeAction", this.action);
    this.sm.localStorageSetItem('chat_browser_action', this.action);
  }

  changeStatAction(e) {
    console.log("changeStatAction", e);
    console.log("changeStatAction", this.actionStatuses);
    this.sm.localStorageSetItem('chat_browser_action_statuses', JSON.stringify(this.actionStatuses));
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log("ngOnChanges", changes);
    if (changes.selectMenuCounter) {
      setTimeout(() => { this.oldScrollToBottom() }, 100)
    }
    if (changes.chat_id && changes.chat_id.currentValue != changes.chat_id.previousValue) {

      if (changes.chat_id.previousValue) {
        if (!!changes.chats && !!changes.chats.previousValue && !!changes.chats.currentValue) {
          if (changes.chats.previousValue.find(el => el.id == changes.chat_id.previousValue) && changes.chats.previousValue.find(el => el.id == changes.chat_id.previousValue).discussionPostDraft) {
            changes.chats.previousValue.find(el => el.id == changes.chat_id.previousValue).discussionPostDraft.text = !!this.edited.value.text ? this.edited.value.text : this.form.value.text;
            changes.chats.previousValue.find(el => el.id == changes.chat_id.previousValue).discussionPostDraft.reply_to_post_id = this.reply.id ? this.reply.id : 0;
            changes.chats.previousValue.find(el => el.id == changes.chat_id.previousValue).discussionPostDraft.update_post_id = this.post_edited_id ? this.post_edited_id : 0
            this.chatService.postChatRead(changes.chat_id.previousValue, (!!this.form.value.text || !!this.edited.value.text) ? 1 : 0, this.company.id, !!this.edited.value.text ? this.edited.value.text : this.form.value.text, this.reply.id ? this.reply.id : 0, this.post_edited_id ? this.post_edited_id : 0).subscribe(resp => {
              console.log("POST AFTER Changed", resp, changes.chats.previousValue.find(el => el.id == changes.chat_id.previousValue).discussionPostDraft)
            })
          }
        } else {
          if (this.chats.find(el => el.id == changes.chat_id.previousValue) && this.chats.find(el => el.id == changes.chat_id.previousValue).discussionPostDraft) {
            this.chats.find(el => el.id == changes.chat_id.previousValue).discussionPostDraft.text = !!this.edited.value.text ? this.edited.value.text : this.form.value.text;
            this.chats.find(el => el.id == changes.chat_id.previousValue).discussionPostDraft.reply_to_post_id = this.reply.id ? this.reply.id : 0;
            this.chats.find(el => el.id == changes.chat_id.previousValue).discussionPostDraft.update_post_id = this.post_edited_id ? this.post_edited_id : 0
            this.chatService.postChatRead(changes.chat_id.previousValue, (!!this.form.value.text || !!this.edited.value.text) ? 1 : 0, this.company.id, !!this.edited.value.text ? this.edited.value.text : this.form.value.text, this.reply.id ? this.reply.id : 0, this.post_edited_id ? this.post_edited_id : 0).subscribe(resp => {
              console.log("POST AFTER Changed", resp, this.chats.find(el => el.id == changes.chat_id.previousValue).discussionPostDraft)
            })
          }
        }

      }
      this.isChangedPersonalStatus = false;
      this.choised = false;
      this.clearFiles();
      this.closeEdit();
      this.closeReply();

      this.chatScrollStrategys = [
        {
          id: 0,
          type: 'start',
          order_by: 'id',
          target_id: 0
        },
        {
          id: 1,
          type: 'target',
          order_by: 'id_desc',
          target_id: 0
        },
        {
          id: 2,
          type: 'end',
          order_by: 'id_desc',
          target_id: 0
        }
      ]

      this.startPostId = 0
      this.endPostId = 0

      this.chatScrollStrategy.patchValue(this.chatScrollStrategys[2])
      
      // unsubs
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
      if (this.filesSub) {
        this.filesSub.unsubscribe();
      }
      if (this.postsSub) {
        this.postsSub.unsubscribe();
      }
      if (this.checksSub) {
        this.checksSub.unsubscribe();
      }
      if (this.updateTaskSub) {
        this.updateTaskSub.unsubscribe();
      }
      if (this.taskSub) {
        this.taskSub.unsubscribe();
      }
      if (this.updateDiscussionSub) {
        this.updateDiscussionSub.unsubscribe();
      }
      if (this.updateChatSub) {
        this.updateChatSub.unsubscribe();
      }
      if (this.pinnedSub) {
        this.pinnedSub.unsubscribe();
      }
      if (this.postSub) {
        this.postSub.unsubscribe();
      }
      this.dont_show_ids = []
      this.chat = this.chats.find(el => el.id == changes.chat_id.currentValue);
      if (!this.chat) {
        return
      }
      console.log("changes test chat", this.chat)
      console.log("changes test chats", this.chats)
      this.skipChat = false;
      if (this.chat && this.chat.data && this.chat.data.file_id) {
        this.attachSubscriptions(
          this.fileService.getTargetFile(this.chat.data.file_id, this.company.id).pipe(
            tap(x => {
              this.chat.data.file = x;
            })
          ).subscribe(resp => {
            console.log("THIS.CHAT", this.chat)
          })
        )
      }
      if (this.chat && this.chat.lastPost && this.chat.lastPost.id) {
        this.endPostId = this.chat.lastPost.maxId || this.chat.lastPost.id
      }
      console.log("this.linkToPost", this.linkToPost)
      if (!!this.linkToPost && !!this.linkToPost.chat_id && !!this.linkToPost.msg_id && !this.linkToPost.is_used) {
        this.linkToData = this.linkToPost
      }
      if (this.chat && this.chat.name) {
        if (!this.prevTitle) {
          this.prevTitle = this.titleService.getTitle();
        }
        this.titleService.setTitle(this.chat.name)
      }
      if (this.sm.localStorageGetItem("copiedFiles")) {
        this.copiedFiles = JSON.parse(this.sm.localStorageGetItem("copiedFiles"))
      }
      console.log("this.copiedFiles", this.copiedFiles);
      console.log("this.chat", this.chat);
      if (this.searchSub) {
        this.searchSub.unsubscribe()
      }
      this.clearSearch()
      if (this.chat) {
        this.chatService.isInChat$.next(this.chat.id)
      }
      this.task = undefined;
      this.work = undefined;
      this.getTask();
      this.getNotificationEmployees();
      this.getPinnedPosts(this.chat_id);
      // this.getEmployees();

      this.form = this.fb.group({
        discussion_id: this.chat_id,
        text: ''
      })
      
      this.edited = this.fb.group({
        text: ''
      })
      
      this.statusForm = this.fb.group({
        status_id: [this.chat.status_id, Validators.required]
      })

      this.personalStatusForm = this.fb.group({
        status_id: ['', Validators.required]
      })


      this.subscription = this.chatPing.subscribe(res => {
        // this.getPinnedPosts(this.chat_id);
        this.updateChat(this.chat_id);
        this.postChatRead();
      })

      this.menuOpened = true;
      this.chatUsers = undefined;
      this.posts = [];
      this.page = 1;
      this.is_typing = undefined;
      this.firstLoad = false;


      console.log("this.linkToData", this.linkToData)
      
      if (!!this.linkToData.msg_id && !this.linkToData.is_used && this.linkToData.chat_id == this.chat_id) {
        console.log("LALA 1")
        this.linkToData.is_used = true;
        this.postsSrollCount = 0;
        this.goToPostReply({id: this.linkToData.msg_id})
      } else if (!(this.chat && !!this.chat.discussionPostDraft && !!this.chat.discussionPostDraft.text && !!this.chat.discussionPostDraft.reply_to_post_id)) {
        console.log("LALA 2")
        this.getPosts(this.chat_id);
      }
      this.findChecks(this.chat_id)
      // this.postChatRead();

      if (!!this.chat && !!this.chat.discussionPostDraft && !!this.chat.discussionPostDraft.text) {
        if (this.chat.discussionPostDraft.update_post_id) {
          this.post_edited = true;
          this.post_edited_id = this.chat.discussionPostDraft.update_post_id;
      
          this.edited.patchValue({
            text: this.chat.discussionPostDraft.text
          })
        } else {
          this.form.patchValue({
            text: this.chat.discussionPostDraft.text
          })

          if (this.chat.discussionPostDraft.reply_to_post_id) {
            console.log("LALA 3")
            this.postsSrollCount = 0
            this.goToPostReply({id: this.chat.discussionPostDraft.reply_to_post_id}, true)
          }
        }

      }

      this.statusForm.valueChanges.subscribe(resp => {
        console.log(resp);
        if (this.is_sms_status) {
          this.is_sms_status = false;
        } else {
          this.attachSubscriptions(
            this.chatService.editChat(this.chat.id, this.statusForm.value, this.company.id).subscribe(resp => {
              if (this.chat) {
                this.chat.status_id = resp.status_id;
              }
            })
          )
        }
      })

      this.postSub = this.chatService.getPostsDataStream().pipe(
        concatMap(postsInPage => {
          return concat(...postsInPage.map(postInPage => this.neededPostData(postInPage))).pipe(last(),map(x => postsInPage))
        }),
      ).subscribe(resp => console.log("-----getPostsDataStream-----",resp));

      setTimeout(() => {
        if (this.msg && this.msg.nativeElement.innerText == '') {
          console.log("this.msg", this.msg)
          this.msg.nativeElement.focus()
        }
      }, 300)
    }
  }

  findChecks(chat_id) {
    this.attachSubscriptions(
      this.chatService.getSmartPosts(chat_id, this.company.id, 'id', 0, undefined, undefined, true, this.is_reminder_done).pipe(
        tap(el => {
          this.checksCount = +el.headers.get('x-pagination-total-count')
        })
      ).subscribe(resp => {
        console.log("findChecks", this.checksCount)
      })
    )
  }

  neededTaskData(task) {
    let arr = [
      this.chatService.getTasksChats(this.company.id, [task.id]).pipe(
        tap(res => {
          task['operations'].map(o_el => {
            o_el['chatsInfo'] = [];
            o_el['chatsInfo'].push(...res.filter(z => z.task_operation_id == o_el.id));
            o_el['unReadChats'] = res.filter(z => z.task_operation_id == o_el.id && z.is_read != '1').length;
            o_el['unClosedChats'] = res.filter(z => z.task_operation_id == o_el.id && z.status_id != '4').length;
          });
          task['chatsInfo'] = [];
          task['chatsInfo'].push(...res.filter(z => z.task_id == task.id));
          task['unReadChats'] = res.filter(z => z.is_read != '1').length;
          task['unClosedChats'] = res.filter(z => z.status_id != '4').length;
        })
      )
    ]
    return forkJoin(arr)
  }

  openChat(chat) {
    // console.log("this.dialog.openDialogs", this.dialog.openDialogs.filter(x => !!x.componentInstance.data.withConnectedChats).length);

    this.attachSubscriptions(
      this.taskService.getOneTaskExpand(this.company.id, chat.task_id).pipe(
        map(x => x.body[0]),
        switchMap(x => this.neededTaskData(x).pipe(map(() => casesModel([x], [], 'update')),map(x => x.arr[0]))),
      ).subscribe(resp => {
        console.log(resp);
        if (this.dialog.openDialogs.filter(x => !!x.componentInstance.data.withConnectedChats).length > 0) {
          console.log("this.dialog.openDialogs", this.dialog.openDialogs.filter(x => !!x.componentInstance.data.withConnectedChats));
          this.dialog.openDialogs.filter(x => !!x.componentInstance.data.withConnectedChats)[0].close()
        }
        const goToChat = this.dialog.open(OperationChatComponent, {
          backdropClass: ['backdrop_under_header', "without_pad"],
          panelClass: !this.is_mobile ? ['full_screen_dialog', 'global_chats_dialog', 'show_header'] : ['full_screen_dialog', 'global_chats_dialog'],
          position: !this.is_mobile ? { top: '58px', left: '0', right: '0', bottom: '0' } : {},
          data: {
            company: this.company,
            task: resp,
            dialogType: 'operation_chat',
            operationsValues: this.operationsValues,
            work: resp.operations.find(el => el.id == chat.task_operation_id),
            user: this.user,
            withConnectedChats: true,
            empl_status: this.company.permissions.includes('owner') || this.company.permissions.includes('admin') || this.checkIsManager(this.task, this.company, this.user),
            chat: chat,
            chats: resp.operations.find(el => el.id == chat.task_operation_id).chatsInfo
          }
        });
    
        this.attachSubscriptions(
          goToChat.afterClosed().subscribe(result => {
            if (!!result && result.event == 'close') {
              this.chatService.postChatRead(chat.id, 0, this.company.id).subscribe(resp => {
                console.log(resp);
              })
            }
          })
        )
      })
    )
  }

  getChatRead() {
    // if (!this.chat.discussionPostDraft) {
    //   this.chat.discussionPostDraft = {}
    // }
    // this.chat.discussionPostDraft.text = !!this.edited.value.text ? this.edited.value.text : this.form.value.text;
    // this.chat.discussionPostDraft.reply_to_post_id = this.reply.id ? this.reply.id : 0;
    // this.chat.discussionPostDraft.update_post_id = this.post_edited_id ? this.post_edited_id : 0;
    this.attachSubscriptions(
      this.chatService.getChatRead(this.chat_id, this.company.id).subscribe(resp => {
        console.log('getChatRead', resp)
        if (this.chats.find(x => x.id == this.chat_id)) {
          this.chats.find(x => x.id == this.chat_id).is_read = '1';
        }
        if (resp.filter(el => el.user_id != this.user.id).length) {
          this.chatUsers = resp.filter(el =>  !!el.employee && el.employee.user_id != this.user.id);
        }

        this.is_typing = resp.filter(el =>  !!el.employee && el.employee.user_id != this.user.id && el.is_typing == 1)     
      })
    )
  }

  postChatRead() {
    if (this.chatRead) {
      this.chatRead.unsubscribe();
    }
    
    this.chatRead = this.chatService.postChatRead(this.chat_id, (!!this.form.value.text || !!this.edited.value.text) ? 1 : 0, this.company.id, !!this.edited.value.text ? this.edited.value.text : this.form.value.text, this.reply.id ? this.reply.id : 0, this.post_edited_id ? this.post_edited_id : 0).subscribe(resp => {
      if (!this.chat.discussionPostDraft) {
        this.chat.discussionPostDraft = {}
      }
      this.chat.discussionPostDraft.text = !!this.edited.value.text ? this.edited.value.text : this.form.value.text;
      this.chat.discussionPostDraft.reply_to_post_id = this.reply.id ? this.reply.id : 0;
      this.chat.discussionPostDraft.update_post_id = this.post_edited_id ? this.post_edited_id : 0;
      if (this.chats.find(x => x.id == this.chat_id)) {
        this.chats.find(x => x.id == this.chat_id).is_read = '1';
      }
      // console.log('postChatRead', resp)
      
      if (resp.filter(el => el.user_id != this.user.id).length) {
        this.chatUsers = resp.filter(el =>  !!el.employee && el.employee.user_id != this.user.id);
      }
      this.is_typing = resp.filter(el =>  !!el.employee && el.employee.user_id != this.user.id && el.is_typing == 1)     
    })
  }

  tapPost(post) {
    this.posts.map(x => {
      if (x.id != post.id) {
        x.is_tapped = false
      }
    })
    post.is_tapped = !post.is_tapped;
  }

  updateDiscussion(disc_id) {
    console.log("TATATATTA updateDiscussion")
    if (this.updateDiscussionSub) {
      this.updateDiscussionSub.unsubscribe();
    }
    this.updateDiscussionSub = this.chatService.getChatsExpand('1', this.company.id, {id: [disc_id]}).pipe(
      map(res => res.body[0])
    ).subscribe(resp => {
      this.stopDragged();
      this.chat = resp;
      console.log("TATATATTA resp chat", resp, this.chat)
      if (!!resp.discussionPostDraft && !!resp.discussionPostDraft.text) {
        if (this.chat.discussionPostDraft.update_post_id) {
          this.post_edited = true;
          this.post_edited_id = this.chat.discussionPostDraft.update_post_id;
      
          this.edited.patchValue({
            text: this.chat.discussionPostDraft.text
          })
        } else {
          this.form.patchValue({
            text: resp.discussionPostDraft.text
          })
          if (resp.discussionPostDraft.reply_to_post_id) {
            this.postsSrollCount = 0
            this.goToPostReply({id: resp.discussionPostDraft.reply_to_post_id}, true)
          }
        }
      }
      if (!this.skipChat && !this.is_small && !this.is_mobile) {
        if (!!this.action && this.actionStatuses.length && this.actionStatuses.includes(this.chat.status_id)) {
          if (this.action == 1) {
            this.close()
          }
          if (this.action == 2) {
            console.log(this.chats);
            this.goToNextChat()
          }
        }
      }
      this.updateTask();
    })
  }

  goToNextChat() {
    this.ToNextChat.emit()
  }

  openJob(task_id, task_operation_id) {
    this.attachSubscriptions(
      this.taskService.getOneTaskExpand(this.company.id, task_id).pipe(
        map(x => x.body[0]),
        switchMap(x => this.neededData(x).pipe(map(() => casesModel([x], [], 'update')),map(x => x.arr[0]))),
      ).subscribe(resp => {
        const dialogRef = this.dialog.open(OpenJobComponent, {
          backdropClass: !this.is_mobile ? 'backdrop_under_header': ['backdrop_under_header', 'mob_card_backdrop'],
          panelClass: !this.is_mobile ? ['open_task_dialog', 'show_header'] : ['open_task_dialog', 'animate__animated', 'animate__slideInUp'],
          autoFocus: false,
          data: {
            task_id: task_id,
            task_operation_id: task_operation_id,
            task: resp,
            company: this.company,
            initCompanyId: this.company.id,
          }
        });
      })
    )

  }

  onPartnerImgError(event){
    event.target.src = this.imgRoute+'/assets/img/partner.png'
  }

  logPosts() {
    console.log("logPosts", this.chat)
    console.log("logPosts", this.posts)
    console.log("logPosts this.startPostId", this.startPostId)
    console.log("logPosts this.endPostId", this.endPostId)
    
    this.tryHigh('logPosts')
  }
  
  updateChat(chat_id) {
    // console.log("updateChat chat_id - ", chat_id)
    this.updateChatSub = this.chatService.getPosts(chat_id, 1, this.company.id, "updated_at_desc", !this.is_mobile ? 45 : 30).pipe(
      map(el => el.body.reverse())
    ).subscribe(resp => {

      // console.log("newPosts this.chat.lastPost.maxId", this.chat.lastPost.maxId)
      // console.log("newPosts this.chat.lastPost.id", this.chat.lastPost.id)
      // console.log("newPosts this.posts[this.posts.length - 1].id", this.posts[this.posts.length - 1].id)
      // console.log(" newPosts Math.max(this.chat.lastPost.maxId, this.chat.lastPost.id, this.posts[this.posts.length - 1].id)", Math.max(this.chat.lastPost.maxId, this.chat.lastPost.id, this.posts[this.posts.length - 1].id), resp)
      let newPosts = resp.filter(x => Math.max((this.chat.lastPost ? this.chat.lastPost.maxId : 0) || 0, (this.chat.lastPost ? this.chat.lastPost.id : 0), this.posts[this.posts.length - 1].id) < x.id)
      let maxId = -Infinity;
      for (let i = 0; i < newPosts.length; i++) {
        if (newPosts[i].id > maxId) {
          maxId = newPosts[i].id;
        }
      }
      if (this.chat.lastPost && maxId > (this.chat.lastPost.maxId || this.chat.lastPost.id)) {
        this.chat.lastPost.maxId = Math.max(this.posts[this.posts.length - 1].id, this.chat.lastPost.id, this.chat.lastPost.maxId || 0, maxId)
      }
      console.log("newPosts", newPosts)

      if (newPosts.length) {
        console.log(this.postsContainer.nativeElement.scrollHeight > this.postsContainer.nativeElement.clientHeight);
        this.newMsgCounter += newPosts.length;
        if (this.postsContainer.nativeElement.scrollHeight > this.postsContainer.nativeElement.clientHeight) {
          this.showArrowDown = true;
        }
        console.log("ping info", this.newMsgCounter, this.showArrowDown)
      }

      if (this.chatScrollStrategy.value.id == 2 || (this.chat && this.chat.lastPost && this.posts.filter(x => x.id == (this.chat.lastPost.maxId || this.chat.lastPost.id)).length > 0)) {
        if (this.posts.length) {
  
          // need understand file types
          // this.posts.filter(x => !!x.discussionPostFiles && x.discussionPostFiles.length && x.discussionPostFiles.filter(b => !b.file.thumbnail).length != 0).forEach(el => {
          //   el.discussionPostFiles.filter(u => !u.file.thumbnail).forEach(t => {
          //     if (resp.find(p => p.id == el.id) && !!resp.find(p => p.id == el.id).discussionPostFiles && !!resp.find(p => p.id == el.id).discussionPostFiles.find(p => p.file.id == t.file.id)) {
          //       if (t.file.is_uploaded == 0) {
          //         t.file.thumbnail = undefined;
          //         t.file.original = undefined;
          //         t.file.makedUndefined = 1;
          //       }
          //       setTimeout(() => {
          //         if (t.file.makedUndefined && t.file.makedUndefined == 2) {
          //           t.file.is_uploaded = 1;
          //         }
          //         t.file.makedUndefined++
          //         t.file.thumbnail = resp.find(p => p.id == el.id).discussionPostFiles.find(p => p.file.id == t.file.id).file.thumbnail;
          //         t.file.original = resp.find(p => p.id == el.id).discussionPostFiles.find(p => p.file.id == t.file.id).file.original;
          //         t.file.preview1080 = resp.find(p => p.id == el.id).discussionPostFiles.find(p => p.file.id == t.file.id).file.preview1080
          //       }, 10)
          //     }
          //   })
          // })
  
          if (this.posts.filter(x => !!x.discussionPostFiles && x.discussionPostFiles.length && x.discussionPostFiles.filter(b => b.file && b.file.is_uploaded == 0).length != 0).length != 0) {
            console.log("TRY TO UPDATE FILES");
            this.posts.filter(x => !!x.discussionPostFiles && x.discussionPostFiles.length && x.discussionPostFiles.filter(b => b.file && b.file.is_uploaded == 0).length != 0).forEach(el => {
              el.discussionPostFiles.filter(u => !!u.file && !!u.file.id && u.file.is_uploaded == 0).forEach(t => {
                // user_upload_progress
                // user_upload_speed_mbit
                if (t.file && resp.find(p => p.id == el.id) && !!resp.find(p => p.id == el.id).discussionPostFiles && !!resp.find(p => p.id == el.id).discussionPostFiles.find(p => !!p.file && !!p.file.id && p.file.id == t.file.id)) {
                  if (t.file.created_user_id != this.user.id) {
                    t.file.user_upload_progress = resp.find(p => p.id == el.id).discussionPostFiles.find(p => !!p.file && p.file.id == t.file.id).file.user_upload_progress;
                    t.file.user_upload_speed_mbit = resp.find(p => p.id == el.id).discussionPostFiles.find(p => !!p.file && p.file.id == t.file.id).file.user_upload_speed_mbit;
                  }
                  if (!!resp.find(p => p.id == el.id).discussionPostFiles.find(p => !!p.file && !!p.file.id && !!t.file && p.file.id == t.file.id && p.file.is_uploaded == 1)) {
                    t.file.thumbnail = undefined;
                    t.file.original = undefined;
                    
                    setTimeout(() => {
                      t.file.is_uploaded = resp.find(p => p.id == el.id).discussionPostFiles.find(p => !!p.file && p.file.id == t.file.id).file.is_uploaded;
                      t.file.thumbnail = resp.find(p => p.id == el.id).discussionPostFiles.find(p => !!p.file && p.file.id == t.file.id).file.thumbnail;
                      t.file.original = resp.find(p => p.id == el.id).discussionPostFiles.find(p => !!p.file && p.file.id == t.file.id).file.original;
                      t.file.preview1080 = resp.find(p => p.id == el.id).discussionPostFiles.find(p => !!p.file && p.file.id == t.file.id).file.preview1080;
                    }, 10)
                  }
                  // console.log("TRY TO UPDATE FILES", t.file);
                }
              })
            })
          }
        }
  
        console.log("this.posts", this.posts)
        console.log("newPosts", newPosts)
  
        if (newPosts.length > 0) {
          newPosts.forEach((post, index) => {
            post.discussionPostFiles.forEach(el => {
              if (el.file) {
                if (el.file.meta_width && el.file.meta_height) {
                  if (el.file.meta_width <= el.file.meta_height) {
                    let perc = 320/(+el.file.meta_height)
                    el.file.perc = perc;
                    el.file.prediction_w_size = Math.round((+el.file.meta_width)*perc);
                    el.file.prediction_h_size = 320;
                  } else {
                    let perc = 320/el.file.meta_width
                    el.file.perc = perc;
                    el.file.prediction_w_size = 320;
                    el.file.prediction_h_size = Math.round((+el.file.meta_height)*perc);
                  }
                }
              }
            });
            
            this.chatService.postsData$.next([post]);
            if (this.endPostId < post.id) {
              this.endPostId = post.id
            }
            this.posts.push(post);
            if (!!this.is_typing && !!this.is_typing.length) {
              this.is_typing.forEach(x => {
                if (x.user.id == post.user.id) {
                  this.is_typing.splice(this.is_typing.indexOf(x), 1)
                }
              });
            }
          });
  
  
        }
  
        if (newPosts.filter(x => x.action != null && x.action.user_id != this.user.id).length > 0) {
          console.log("TATATATAS")
          this.updateDiscussion(this.chat_id)
          this.refreshService.refreshChat$.next({company_id: this.company.id, chat_id: this.chat_id})
          this.initRefreshBoard()
          setTimeout(() => {
            this.oldScrollToBottom()
          }, 300)
        }
      }

      if (this.posts) {
        let editedPosts = resp.filter(x => this.posts.find(y => y.id == x.id) && (x.updated_at > this.posts.find(y => y.id == x.id).updated_at))
        console.log("editedPosts", editedPosts)
        if (editedPosts.length > 0) {
          this.posts.forEach(x => {
            if (editedPosts.find(b => b.id == x.id)) {
              x.text = editedPosts.find(b => b.id == x.id).text
              x.updated_at = editedPosts.find(b => b.id == x.id).updated_at
            }
            if (x.replyToPost && editedPosts.find(b => b.id == x.replyToPost.id)) {
              x.replyToPost.text = editedPosts.find(b => b.id == x.replyToPost.id).text
              x.replyToPost.updated_at = editedPosts.find(b => b.id == x.replyToPost.id).updated_at
            }
          })
        }
      }

    })
    
  }

  getPercentProgress(part, full) {
    return Math.round( (part/full)*100 )
  }

  getTimeEnd(file) {
    return Math.ceil((file?.filesize - file.user_upload_progress) / file?.user_upload_speed_mbit)
  }

  pinPost(chat, post) {
    if (this.postCollection.isSelected(post) && this.postCollection.selected.length > 1) {
      console.log("setAsChecklist post", post)
      this.attachSubscriptions(
        forkJoin([...this.postCollection.selected.map(x => this.chatService.editPost(x.id, {is_pinned: Math.abs(post.is_pinned - 1)}, this.company.id).pipe(
          tap(res => {
            x.is_pinned = res.is_pinned;
          }),
          catchError(err => {
            return of(err)
          })
        ))]).subscribe(resp => {
          this.postCollection.clear()
          this.getPinnedPosts(chat.id);
        })
      )
    } else {    
      this.attachSubscriptions(
        this.chatService.editPost(post.id, {is_pinned: Math.abs(post.is_pinned - 1)}, this.company.id).pipe(
          tap(val => {
            post.is_pinned = val.is_pinned
          })
        ).subscribe(resp => {
          this.getPinnedPosts(chat.id);
        })
      )
    }
  }

  getChatStatus(id) {
    switch (+id) {
      case 1:
        return "Waiting";
        break;
      case 2:
        return "In process";
        break;
      case 3:
        return "To approve";
        break;
      case 4:
        return "Approved";
        break;
      case 5:
        return "To correct";
        break;
      case 6:
        return "Sources needed";
        break;
      case 97:
        return "On hold";
        break;
      case 98:
        return "Ready";
        break;
      case 99:
        return "Canceled";
        break;
    }
  }
  
  getTimeNow() {
    return +moment().format("X")
  }

  copyLink(type) {
    this.layoutService.showSnackBar({name: marker(type + " link")}, marker("Copied"), SnackBarItem)
  }

  checkScrollTop() {
    console.log("this.postsContainer.nativeElement", this.postsContainer.nativeElement.scrollTop)
  }

  isRaw(file: any): boolean {
    // Проверяем, является ли тип контента "image/DNG", "image/RAW" или "image/TIFF"
    if (!file.thumbnail && (file.contentType === 'image/DNG' || file.contentType === 'image/RAW' || file.contentType === 'image/TIFF')) {
      return true;
    }
  
    // Проверяем, является ли тип контента "video/RAW" или "video/PRORES"
    if (!file.preview1080 && (file.contentType === 'video/RAW' || file.contentType === 'video/PRORES')) {
      return true;
    }
  
    // Проверяем, является ли тип контента "audio/RAW", "audio/WAV" или "audio/AIFF"
    if (!file.preview1080 && (file.contentType === 'audio/RAW' || file.contentType === 'audio/WAV' || file.contentType === 'audio/AIFF')) {
      return true;
    }
  
    // Если тип контента не подходит ни под одно из условий выше, считаем его не raw
    return false;
  }

  onUp(e) {
    if (this.startPostId != this.posts[0].id) {
      console.log('SCROLL UP ' + (+this.posts[0].id - 1))
      this.scrollPostIdTarget = this.posts[0].id;
      // this.layoutService.showSnackBar('', marker("SCROLL UP!" + this.scrollPostIdTarget + " | "), SnackBarItem)
      console.log("SCROLL", this.posts);
      this.startPostId = this.posts[0].id;
      this.getPosts(this.chat_id, null, +this.posts[0].id - 1);
    }
  }
  onScrollDown(e) {
    if (this.endPostId != this.posts[this.posts.length - 1].id) {
      console.log('SCROLL DOWN ' + (+this.posts[this.posts.length - 1].id + 1))
      // this.layoutService.showSnackBar('', marker("SCROLL DOWN!" + this.scrollPostIdTarget + " | "), SnackBarItem)
      console.log("SCROLL", this.posts);
      this.endPostId = this.posts[this.posts.length - 1].id;
      this.getPosts(this.chat_id, +this.posts[this.posts.length - 1].id + 1);
    }
  }

  onScroll() {
    // return
    let prevScrollTop = 0;
    let isScrollToTop: boolean = false;
    this.attachSubscriptions(
      fromEvent(this.postsContainer.nativeElement, "scroll").pipe(
        tap((e:any) => {
          let scrollTopMax = e.target.scrollHeight - e.target.offsetHeight
          if (scrollTopMax - e.target.scrollTop <= 30 && scrollTopMax - e.target.scrollTop >= 0) {
            this.showArrowDown = false;
            this.newMsgCounter = 0;
          } else if (scrollTopMax - e.target.scrollTop > 30) {
            this.showArrowDown = true;
          }
          if (e.target.scrollTop < prevScrollTop) {
            isScrollToTop = true;
          } else {
            isScrollToTop = false;
          }
          prevScrollTop = e.target.scrollTop
        }),
        filter((e:any) => !this.is_mobile && (isScrollToTop ? this.acceptScrollTop(e) : this.acceptScrollBottom(e))),
        debounceTime(300),
        distinctUntilChanged()
      ).subscribe(() => {

        if (this.posts.length) {
          if (isScrollToTop) {
            if (this.startPostId != this.posts[0].id) {
              console.log('SCROLL UP ' + (+this.posts[0].id - 1))
              console.log("SCROLL", this.posts);
              this.startPostId = this.posts[0].id;
              this.getPosts(this.chat_id, null, +this.posts[0].id - 1);
            }
          } else {
            if (this.endPostId != this.posts[this.posts.length - 1].id) {
              console.log('SCROLL DOWN ' + (+this.posts[this.posts.length - 1].id + 1))
              console.log("SCROLL", this.posts);
              this.endPostId = this.posts[this.posts.length - 1].id;
              this.getPosts(this.chat_id, +this.posts[this.posts.length - 1].id + 1);
            }
          }
        }
      })
    )
  }

  acceptScrollBottom(e) {
    let scrollTopMax = e.target.scrollHeight - e.target.offsetHeight
    if (this.is_mobile) {
      return this.posts.length && !this.isLoad && e.target.scrollTop >= scrollTopMax * 0.8
    } else {
      return this.posts.length && !this.isLoad && e.target.scrollTop >= scrollTopMax * 0.8
    }
  }
  
  acceptScrollTop(e) {
    if (this.is_mobile) {
      // return this.posts.length && this.posts.length >= 30 && !this.isLoad && e.target.scrollTop <= 500
      return this.posts.length && this.posts.length >= 30 && this.scrollPostIdTarget != this.posts[0].id && !this.isLoad && (document.getElementById(`post_${this.posts[0].id}`).getBoundingClientRect().top + document.getElementById(`post_${this.posts[0].id}`).clientHeight <= (e.target as Element).scrollHeight && document.getElementById(`post_${this.posts[0].id}`).getBoundingClientRect().top >= 0)
    } else {
      
      // console.log("SCROLL this.posts.length", this.posts.length)
      // console.log("SCROLL this.posts.length >= 45", this.posts.length >= 45)
      // console.log("SCROLL !this.isLoad", !this.isLoad)
      // console.log("SCROLL e.target.scrollTop", e.target.scrollTop)
      // console.log("SCROLL e.target.scrollHeight", e.target.scrollHeight)
      // console.log("SCROLL e.target.scrollTop <= e.target.scrollHeight / 2", e.target.scrollTop <= e.target.scrollHeight / 2)
      // // return this.posts.length && this.posts.length >= 45 && !this.isLoad && e.target.scrollTop <= scrollTopMax / 3
      return this.posts.length && this.posts.length >= 45 && !this.isLoad && e.target.scrollTop <= e.target.scrollHeight / 2
      // return !this.isLoad && e.target.scrollTop <= e.target.scrollHeight / 2
    }
  }

  workAfterClosed(resp = {body:[{}]}, task, work = null) {
    let data = casesModel([resp.body[0]], this.tasks, 'update')

    Object.keys(task).forEach(key => {
      task[key] = data.arr[0][key]
    })
    if (work) {
      Object.keys(work).forEach(key => {
        if (data['arr'][0].operations && data['arr'][0].operations.find(y => y.id == work.id)) {
          work[key] = data['arr'][0].operations.find(y => y.id == work.id)[key]
        }
      })
    }

    this.getChatsForTask(task, data.arr[0]);
  }

  getChatsForTask(task, spliceVal?) {
    this.attachSubscriptions(
      this.chatService.getTasksChats(this.company.id, [task.id]).subscribe(res => {
          task['operations'].forEach(o_el => {
            o_el['chatsInfo'] = [];
            o_el['chatsInfo'].push(...res.filter(z => z.task_operation_id == o_el.id));
            o_el['unReadChats'] = res.filter(z => z.task_operation_id == o_el.id && z.is_read != '1').length;
            o_el['unClosedChats'] = res.filter(z => z.task_operation_id == o_el.id && z.status_id != '4').length;
          });
          task['chatsInfo'] = [];
          task['chatsInfo'].push(...res);
          task.discussion_count = res.length;
          task.read_discussion_count = res.filter(z => z.is_read == '1').length;

          if (spliceVal) {
            this.tasks.splice(this.tasks.indexOf(task), 1, spliceVal);
          }
      })
    )
  }

  scrollToTop(type?) {
    if (this.chatScrollStrategy.value.id != 0) {
      this.chatScrollStrategy.patchValue(this.chatScrollStrategys[0])
      this.posts = [];
      this.getPosts(this.chat_id)
    } else {
      try {
        if (this.posts.length) {
          document.getElementById(`post_${this.posts[0].id}`).scrollIntoView({
            behavior: type ? type : "auto",
            block: "end"
          });
        }
      } catch(err) {

      }                 
    }
  }

  scrollToBottom(type?): void {
    if (this.chatScrollStrategy.value.id != 2) {
      this.chatScrollStrategy.patchValue(this.chatScrollStrategys[2])
      this.posts = [];
      this.getPosts(this.chat_id)
    } else {
      try {
        if (this.posts.length) {
          document.getElementById(`post_${this.posts[this.posts.length - 1].id}`).scrollIntoView({
            behavior: type ? type : "auto",
            block: "end"
          });
          this.showArrowDown = false;
          this.newMsgCounter = 0;
        }
      } catch(err) {
      }                 
    }
  }

  getPinnedPosts(chat_id) {
    this.pinnedSub = this.chatService.getPinnedPosts(chat_id, this.company.id).subscribe(res => {
      res.sort((a,b) => b.updated_at - a.updated_at)
      this.pinned = res;
      // console.log("PINNED", this.pinned)
    })
  
  }

  

  getPosts(chat_id, id_from?, id_to?) {
    if (this.postsContainer) {
      this.postsContainer.nativeElement.classList.add("disable_scroll")
    }
    this.isLoad = true;
    this.postsSub = this.chatService.getSmartPosts(chat_id, this.company.id, this.chatScrollStrategy.value.id != 1 ? this.chatScrollStrategy.value.order_by : (!!id_from ? 'id' : 'id_desc'), !this.is_mobile ? 45 : 30, id_from, id_to).pipe(
      tap(el => {
        if ((!id_to && !id_from) && this.chatScrollStrategy.value.id == 2) {
          this.pagination = {
            'pageCount': el.headers.get('x-pagination-page-count'),
            'perPage': el.headers.get('x-pagination-per-page'),
            'totalCount': el.headers.get('x-pagination-total-count'),
            'currentPage': el.headers.get('x-pagination-current-page'),
          }
        }
      }),
      map(el => this.chatScrollStrategy.value.id == 2 ? el.body.reverse() : (this.chatScrollStrategy.value.id == 0 ? el.body : (!!id_from ? el.body : el.body.reverse()))),
      tap(postsArr => this.chatService.postsData$.next(postsArr)),
      tap((postsArr:any) => {
        postsArr.forEach(element => {
            if (!this.posts.find(x => x.id == element.id)) {
              element.discussionPostFiles.forEach(el => {
                if (el.file) {
                  console.log("discussionPostFiles EL", el)
                  if (el.file.meta_width && el.file.meta_height) {
                    if (el.file.meta_width <= el.file.meta_height) {
                      let perc = 320/(+el.file.meta_height)
                      el.file.perc = perc;
                      el.file.prediction_w_size = Math.round((+el.file.meta_width)*perc);
                      el.file.prediction_h_size = 320;
                    } else {
                      let perc = 320/el.file.meta_width
                      el.file.perc = perc;
                      el.file.prediction_w_size = 320;
                      el.file.prediction_h_size = Math.round((+el.file.meta_height)*perc);
                    }
                  }
                } else {
                  element.discussionPostFiles.splice(element.discussionPostFiles.indexOf(el), 1)
                }
              });
              if (element.replyToPost && element.replyToPost.discussionPostFiles && element.replyToPost.discussionPostFiles.length > 0) {
                element.replyToPost.discussionPostFiles.forEach(el => {
                  if (el.file) {
                    if (el.file.meta_width && el.file.meta_height) {
                      if (el.file.meta_width <= el.file.meta_height) {
                        let perc = 320/(+el.file.meta_height)
                        el.file.perc = perc;
                        el.file.prediction_w_size = Math.round((+el.file.meta_width)*perc);
                        el.file.prediction_h_size = 320;
                      } else {
                        let perc = 320/el.file.meta_width
                        el.file.perc = perc;
                        el.file.prediction_w_size = 320;
                        el.file.prediction_h_size = Math.round((+el.file.meta_height)*perc);
                      }
                    }
                  } else {
                    element.replyToPost.discussionPostFiles.splice(element.replyToPost.discussionPostFiles.indexOf(el), 1)
                  }
                });
              }
            }
        });
      }),
      tap(postsArr => {
        if (id_to || (!id_to && !id_from)) {
          this.posts = [...postsArr,...this.posts];
          // this.posts.unshift(...postsArr)
        } else {
          this.posts = [...this.posts, ...postsArr]
          // this.posts.push(...postsArr)
        }
        this.tryHigh('8')
      }),
    ).subscribe({
      next: (next) => {
        // console.log(next)
      },
      complete: () => {
        console.log("complete", this.page, this.posts)
        if (this.chatScrollStrategy.value.id == 2) {
          if (!id_to && !id_from) {
            if (this.chat.lastPost) {
              this.chat.lastPost.maxId = Math.max(this.posts[this.posts.length - 1].id, this.chat.lastPost.id, this.chat.lastPost.maxId || 0)
            }
            if (this.postsContainer) {
              this.postsContainer.nativeElement.classList.add("disable_scroll")
            }
            setTimeout(() => {
              console.log("SCROLL 222")
              if (document.getElementById(`post_${this.posts[this.posts.length - 1].id}`)) {
                console.log("SCROLL 333")
                document.getElementById(`post_${this.posts[this.posts.length - 1].id}`).scrollIntoView({
                  behavior: "auto",
                  block: "start"
                });
                if (this.postsContainer) {
                  this.postsContainer.nativeElement.classList.remove("disable_scroll")
                }
              }
            }, 0)
  
          }

          if (this.is_mobile) {
            console.log("after", document.getElementById(`post_${this.scrollPostIdTarget}`));
            if (this.postsContainer) {
              this.postsContainer.nativeElement.classList.add("disable_scroll")
            }
            setTimeout(() => {
              document.getElementById(`post_${this.scrollPostIdTarget}`).scrollIntoView({
                behavior: "auto",
                block: "start"
              });
              if (this.postsContainer) {
                this.postsContainer.nativeElement.classList.remove("disable_scroll")
              }
            }, 0)
          }
        }

        if (this.is_mobile &&this.chatScrollStrategy.value.id == 1) {
          if (!!id_to) {
            console.log("after", document.getElementById(`post_${this.scrollPostIdTarget}`));
            if (this.postsContainer) {
              this.postsContainer.nativeElement.classList.add("disable_scroll")
            }
            setTimeout(() => {
              document.getElementById(`post_${this.scrollPostIdTarget}`).scrollIntoView({
                behavior: "auto",
                block: "start"
              });
              if (this.postsContainer) {
                this.postsContainer.nativeElement.classList.remove("disable_scroll")
              }
            }, 0)
          }
        }
      
        this.isLoad = false;
        if (this.postsContainer) {
          this.postsContainer.nativeElement.classList.remove("disable_scroll")
        }
      },
      error: (error) => {
        console.log(error)
      }
    })
  }

  // getPosts(chat_id, page) {
  //   if (this.postsContainer) {
  //     this.postsContainer.nativeElement.classList.add("disable_scroll")
  //   }
  //   this.isLoad = true;
  //   this.postsSub = this.chatService.getPosts(chat_id, page, this.company.id, undefined, !this.is_mobile ? 45 : 30).pipe(
  //     tap(el => {
  //       this.pagination = {
  //         'pageCount': el.headers.get('x-pagination-page-count'),
  //         'perPage': el.headers.get('x-pagination-per-page'),
  //         'totalCount': el.headers.get('x-pagination-total-count'),
  //         'currentPage': el.headers.get('x-pagination-current-page'),
  //       }
  //     }),
  //     tap(el => this.chatService.postsData$.next(el.body)),
  //     map(el => el.body.reverse()),
  //     switchMap(res => {
  //       // this.setMomentJs(res);
  //       if (page == 1) {
  //         return from(res).pipe(
  //           tap((element:any) => {
  //             // if (moment(element['updated_at']*1000).isAfter(this.A_WEEK_OLD)) {
  //             //   element["moment_date"] = moment(element['updated_at']*1000).startOf('minute').fromNow();
  //             // } else {
  //             //   element["moment_date"] = moment(element['updated_at']*1000).format('ll');
  //             // }
  //             if (!this.posts.find(x => x.id == element.id)) {
  //               element.discussionPostFiles.forEach(el => {
  //                 if (el.file.meta_width && el.file.meta_height) {
  //                   if (el.file.meta_width <= el.file.meta_height) {
  //                     let perc = 320/(+el.file.meta_height)
  //                     el.file.perc = perc;
  //                     el.file.prediction_w_size = Math.round((+el.file.meta_width)*perc);
  //                     el.file.prediction_h_size = 320;
  //                   } else {
  //                     let perc = 320/el.file.meta_width
  //                     el.file.perc = perc;
  //                     el.file.prediction_w_size = 320;
  //                     el.file.prediction_h_size = Math.round((+el.file.meta_height)*perc);
  //                   }
  //                 }
  //               });
  //               if (element.replyToPost && element.replyToPost.discussionPostFiles && element.replyToPost.discussionPostFiles.length > 0) {
  //                 element.replyToPost.discussionPostFiles.forEach(el => {
  //                   if (el.file.meta_width && el.file.meta_height) {
  //                     if (el.file.meta_width <= el.file.meta_height) {
  //                       let perc = 320/(+el.file.meta_height)
  //                       el.file.perc = perc;
  //                       el.file.prediction_w_size = Math.round((+el.file.meta_width)*perc);
  //                       el.file.prediction_h_size = 320;
  //                     } else {
  //                       let perc = 320/el.file.meta_width
  //                       el.file.perc = perc;
  //                       el.file.prediction_w_size = 320;
  //                       el.file.prediction_h_size = Math.round((+el.file.meta_height)*perc);
  //                     }
  //                   }
  //                 });
  //               }
  //               this.posts.push(element)
  //             }
  //           })
  //         )
  //       } else {
  //         return from(res.reverse()).pipe(
  //           tap((element:any) => {
  //             // if (moment(element['updated_at']*1000).isAfter(this.A_WEEK_OLD)) {
  //             //   element["moment_date"] = moment(element['updated_at']*1000).startOf('minute').fromNow();
  //             // } else {
  //             //   element["moment_date"] = moment(element['updated_at']*1000).format('ll');
  //             // }
  //             if (!this.posts.find(x => x.id == element.id)) {
  //               element.discussionPostFiles.forEach(el => {
  //                 if (el.file.meta_width && el.file.meta_height) {
  //                   if (el.file.meta_width <= el.file.meta_height) {
  //                     let perc = 320/(+el.file.meta_height)
  //                     el.file.perc = perc;
  //                     el.file.prediction_w_size = Math.round((+el.file.meta_width)*perc);
  //                     el.file.prediction_h_size = 320;
  //                   } else {
  //                     let perc = 320/el.file.meta_width
  //                     el.file.perc = perc;
  //                     el.file.prediction_w_size = 320;
  //                     el.file.prediction_h_size = Math.round((+el.file.meta_height)*perc);
  //                   }
  //                 }
  //               });
  //               if (element.replyToPost && element.replyToPost.discussionPostFiles && element.replyToPost.discussionPostFiles.length > 0) {
  //                 element.replyToPost.discussionPostFiles.forEach(el => {
  //                   if (el.file.meta_width && el.file.meta_height) {
  //                     if (el.file.meta_width <= el.file.meta_height) {
  //                       let perc = 320/(+el.file.meta_height)
  //                       el.file.perc = perc;
  //                       el.file.prediction_w_size = Math.round((+el.file.meta_width)*perc);
  //                       el.file.prediction_h_size = 320;
  //                     } else {
  //                       let perc = 320/el.file.meta_width
  //                       el.file.perc = perc;
  //                       el.file.prediction_w_size = 320;
  //                       el.file.prediction_h_size = Math.round((+el.file.meta_height)*perc);
  //                     }
  //                   }
  //                 });
  //               }
  //               this.posts.unshift(element)
  //             }
  //           })
  //         )
  //       }
  //     }),
  //     finalize(() => {
  //       if (this.postsContainer) {
  //         this.postsContainer.nativeElement.classList.remove("disable_scroll")
  //       }
  //       this.isLoad = false;
  //     })
  //   ).subscribe({
  //     next: (next) => {
  //       // console.log(next)
  //     },
  //     complete: () => {
  //       if (this.page == 1) {
  //         this.page = this.page + 1;
  //         setTimeout(() => { 
  //           this.scrollToBottom() 
  //           if (!!this.linkToData.msg_id && !this.linkToData.is_used && this.linkToData.chat_id == this.chat_id) {
  //             this.linkToData.is_used = true;
  //             this.postsSrollCount = 0
  //             this.goToPostReply({id: this.linkToData.msg_id})
  //           }
  //         }, 100)
  //       } else {
  //         this.page = this.page + 1;
  //         if (this.is_mobile) {
  //           console.log("after", document.getElementById(`post_${this.scrollPostIdTarget}`));
  //           if (this.postsContainer) {
  //             this.postsContainer.nativeElement.classList.add("disable_scroll")
  //           }
  //           setTimeout(() => {
  //             document.getElementById(`post_${this.scrollPostIdTarget}`).scrollIntoView({
  //               behavior: "auto",
  //               block: "start"
  //             });
  //             if (this.postsContainer) {
  //               this.postsContainer.nativeElement.classList.remove("disable_scroll")
  //             }
  //           }, 0)
  //         }
  //       }
        
        
  //       console.log("complete", this.posts)
  //     },
  //     error: (error) => {
  //       console.log(error)
  //     }
  //   })
  // }

  sortPostFiles(files) {
    let x = {
      has_preview: [],
      without_preview: []
    }

    files.forEach(item => {
      let file = item.file;
      if (file) {
        if (this.understandFileType(file.content_type) == 'image') {
          x.has_preview.push(item);
        } else if (this.understandFileType(file.content_type) == 'video' && !!file.thumbnail) {
          x.has_preview.push(item);
        } else {
          x.without_preview.push(item);
        }
      }
    });

    return x
  }

  setMomentJs(resp) {
    resp.forEach(item => {
      if (moment(item['updated_at']*1000).isAfter(this.A_WEEK_OLD)) {
        item["moment_date"] = moment(item['updated_at']*1000).startOf('minute').fromNow();
      } else {
        item["moment_date"] = moment(item['updated_at']*1000).format('ll');
      }
    });
  }

  replyPost(chat, post) {
    this.reply.id = post.id;
    this.reply.post = post;
    
    setTimeout(() => {
      if (this.msg) {
        this.msg.nativeElement.focus()
      } else if (this.msg_edit) {
        this.msg_edit.nativeElement.focus()
      } else if (this.mob_msg) {
        this.mob_msg.nativeElement.focus()
      } else if (this.mob_msg_edit) {
        this.mob_msg_edit.nativeElement.focus()
      }
    }, 0)
  }

  closeReply() {
    this.reply = {
      id: 0,
      post: {}
    }
  }

  insertCodeTemplate() {
    const codeTemplate = `
      <code>... write or paste your code here</code>
    `;
    
    this.insertHTMLAtEnd(codeTemplate);
    // this.insertHTMLAtCursor(codeTemplate);
  }

  insertHTMLAtEnd(html: string) {
    const element = (this.msg || this.msg_edit || this.mob_msg || this.mob_msg_edit).nativeElement;
    
    // Создаем новый элемент, чтобы добавить HTML-контент
    const pre = document.createElement('pre');
    pre.innerHTML = html;
    
    // Добавляем новый элемент в конец содержимого
    element.appendChild(pre);
    
    // Прокручиваем элемент, чтобы показать добавленный контент
    element.scrollTop = element.scrollHeight;

    // this.updateFields();
    this.selectTextInside(pre.querySelector('code') as HTMLElement);
  }

  isEmpty(element) {
    console.log("isEmpty", element);
    return false
  }

  // Метод для выделения текста внутри элемента
  selectTextInside(element: HTMLElement) {
    const range = document.createRange();
    const selection = window.getSelection();

    console.log('selectTextInside element', element)
    console.log('selectTextInside selection', selection)
    if (selection && element) {
      range.selectNodeContents(element);
      console.log('selectTextInside range', range)
      // Перемещаем начало и конец диапазона на текстовое содержимое
      const textNode = Array.from(element.childNodes).find(node => node.nodeType === Node.TEXT_NODE);
      console.log('selectTextInside textNode', textNode)
      if (textNode) {
        range.setStart(textNode, 0);
        range.setEnd(textNode, textNode.textContent?.length || 0);
        selection.removeAllRanges();
        selection.addRange(range);
      }
    }
  }

  insertHTMLAtCursor(html: string) {
    const range = window.getSelection()?.getRangeAt(0);
    if (range) {
      range.deleteContents();
      const fragment = range.createContextualFragment(html);
      range.insertNode(fragment);
      // this.msg.nativeElement.focus();  // Фокус на элемент после вставки

      setTimeout(() => {
        if (this.msg) {
          this.msg.nativeElement.focus()
        } else if (this.msg_edit) {
          this.msg_edit.nativeElement.focus()
        } else if (this.mob_msg) {
          this.mob_msg.nativeElement.focus()
        } else if (this.mob_msg_edit) {
          this.mob_msg_edit.nativeElement.focus()
        }
      }, 0)
    }
  }

  editPost(chat, post) {
    console.log(post)
    this.clearAudioRecordedData();
    this.abortAudioRecording();
    this.post_edited = true;
    this.post_edited_id = post.id;

    this.edited.patchValue({
      text: post.text
    })
  }

  goToPostReply(post, noScroll?, toPost?) {
    if (document.getElementById(`post_${!!post?.replyToPost && !toPost ? post.replyToPost.id : post.id}`)) {
      if (!!noScroll) {
        this.replyPost(this.chat, this.posts.find(x => x.id == (!!post?.replyToPost && !toPost ? post.replyToPost.id : post.id)))
        console.log("this.posts.find(x => x.id == (!!post?.replyToPost && !toPost ? post.replyToPost.id : post.id))", this.posts.find(x => x.id == (!!post?.replyToPost && !toPost ? post.replyToPost.id : post.id)))
        console.log(this.reply);
      } else {
        setTimeout(() => {
          if (document.getElementById(`post_${!!post?.replyToPost && !toPost ? post.replyToPost.id : post.id}`)) {
            document.getElementById(`post_${!!post?.replyToPost && !toPost ? post.replyToPost.id : post.id}`).scrollIntoView({
              behavior: "smooth",
              block: "start"
            });
          }
        }, 500)
        if (this.posts.find(x => x.id == (!!post?.replyToPost && !toPost ? post.replyToPost.id : post.id))) {
          this.posts.find(x => x.id == (!!post?.replyToPost && !toPost ? post.replyToPost.id : post.id)).is_reply = true;
          setTimeout(() => {
            this.posts.find(x => x.id == (!!post?.replyToPost && !toPost ? post.replyToPost.id : post.id)).is_reply = false;
            if (this.postsContainer) {
              this.postsContainer.nativeElement.classList.remove("disable_scroll")
            }
          }, 4000)
        }
        this.isLoad = false;
      }
    } else {
      this.getPostsToReply(this.chat_id, post, noScroll, toPost);
    }
  }

  getPostsToReply(chat_id, scrollTo:any = false, noScroll?, toPost?) {
    console.log("getPostsToReplygetPostsToReplygetPostsToReplygetPostsToReplygetPostsToReplygetPostsToReply");
    this.posts = [];
    this.chatScrollStrategy.patchValue(this.chatScrollStrategys[1])

    let arr = [
      this.chatService.getSmartPosts(chat_id, this.company.id, 'id_desc', !this.is_mobile ? 23 : 15, null, !!scrollTo?.replyToPost && !toPost ? scrollTo.replyToPost.id : scrollTo.id).pipe(
        map(el => el.body.reverse()),
        tap(postsArr => this.chatService.postsData$.next(postsArr)),
        tap((postsArr:any) => {
          postsArr.forEach(element => {
              if (!this.posts.find(x => x.id == element.id)) {
                element.discussionPostFiles.forEach(el => {
                  if (el.file) {
                    if (el.file.meta_width && el.file.meta_height) {
                      if (el.file.meta_width <= el.file.meta_height) {
                        let perc = 320/(+el.file.meta_height)
                        el.file.perc = perc;
                        el.file.prediction_w_size = Math.round((+el.file.meta_width)*perc);
                        el.file.prediction_h_size = 320;
                      } else {
                        let perc = 320/el.file.meta_width
                        el.file.perc = perc;
                        el.file.prediction_w_size = 320;
                        el.file.prediction_h_size = Math.round((+el.file.meta_height)*perc);
                      }
                    }
                  } else {
                    element.discussionPostFiles.splice(element.discussionPostFiles.indexOf(el), 1)
                  }
                });
                if (element.replyToPost && element.replyToPost.discussionPostFiles && element.replyToPost.discussionPostFiles.length > 0) {
                  element.replyToPost.discussionPostFiles.forEach(el => {
                    if (el.file) {
                      if (el.file.meta_width && el.file.meta_height) {
                        if (el.file.meta_width <= el.file.meta_height) {
                          let perc = 320/(+el.file.meta_height)
                          el.file.perc = perc;
                          el.file.prediction_w_size = Math.round((+el.file.meta_width)*perc);
                          el.file.prediction_h_size = 320;
                        } else {
                          let perc = 320/el.file.meta_width
                          el.file.perc = perc;
                          el.file.prediction_w_size = 320;
                          el.file.prediction_h_size = Math.round((+el.file.meta_height)*perc);
                        }
                      }
                    } else {
                      element.discussionPostFiles.splice(element.discussionPostFiles.indexOf(el), 1)
                    }
                  });
                }
              }
          });
        }),
        tap(postsArr => {
          // this.posts = [...postsArr,...this.posts]
          this.posts.unshift(...postsArr)
          this.tryHigh('2')
        }),
      ),
      this.chatService.getSmartPosts(chat_id, this.company.id, 'id', !this.is_mobile ? 22 : 15, !!scrollTo?.replyToPost && !toPost ? (+scrollTo.replyToPost.id + 1) : (+scrollTo.id + 1)).pipe(
        map(el => el.body),
        tap(postsArr => this.chatService.postsData$.next(postsArr)),
        tap((postsArr:any) => {
          postsArr.forEach(element => {
              if (!this.posts.find(x => x.id == element.id)) {
                element.discussionPostFiles.forEach(el => {
                  if (el.file) {
                    if (el.file.meta_width && el.file.meta_height) {
                      if (el.file.meta_width <= el.file.meta_height) {
                        let perc = 320/(+el.file.meta_height)
                        el.file.perc = perc;
                        el.file.prediction_w_size = Math.round((+el.file.meta_width)*perc);
                        el.file.prediction_h_size = 320;
                      } else {
                        let perc = 320/el.file.meta_width
                        el.file.perc = perc;
                        el.file.prediction_w_size = 320;
                        el.file.prediction_h_size = Math.round((+el.file.meta_height)*perc);
                      }
                    }
                  } else {
                    element.discussionPostFiles.splice(element.discussionPostFiles.indexOf(el), 1)
                  }
                });
                if (element.replyToPost && element.replyToPost.discussionPostFiles && element.replyToPost.discussionPostFiles.length > 0) {
                  element.replyToPost.discussionPostFiles.forEach(el => {
                    if (el.file) {  
                      if (el.file.meta_width && el.file.meta_height) {
                        if (el.file.meta_width <= el.file.meta_height) {
                          let perc = 320/(+el.file.meta_height)
                          el.file.perc = perc;
                          el.file.prediction_w_size = Math.round((+el.file.meta_width)*perc);
                          el.file.prediction_h_size = 320;
                        } else {
                          let perc = 320/el.file.meta_width
                          el.file.perc = perc;
                          el.file.prediction_w_size = 320;
                          el.file.prediction_h_size = Math.round((+el.file.meta_height)*perc);
                        }
                      }
                    } else {
                      element.discussionPostFiles.splice(element.discussionPostFiles.indexOf(el), 1)
                    }
                  });
                }
              }
          });
        }),
        tap(postsArr => {
          // this.posts = [...this.posts,...postsArr]
          this.posts.push(...postsArr)
          this.tryHigh('3')
        }),
      )
    ]
    this.attachSubscriptions(
      forkJoin(arr).pipe(last()).subscribe({
        next: (next) => {
          // console.log(next)
        },
        complete: () => {
          
            setTimeout(() => {
              if (document.getElementById(`post_${!!scrollTo?.replyToPost && !toPost ? scrollTo.replyToPost.id : scrollTo.id}`)) {
                document.getElementById(`post_${!!scrollTo?.replyToPost && !toPost ? scrollTo.replyToPost.id : scrollTo.id}`).scrollIntoView({
                  behavior: "smooth",
                  block: !!noScroll ? "center" : "start"
                });
                if (!!noScroll) {
                  this.replyPost(this.chat, this.posts.find(x => x.id == (!!scrollTo?.replyToPost && !toPost ? scrollTo.replyToPost.id : scrollTo.id)))
                }
              }
            }, 500)

            if (this.posts.find(x => x.id == (!!scrollTo?.replyToPost && !toPost ? scrollTo.replyToPost.id : scrollTo.id))) {
              this.posts.find(x => x.id == (!!scrollTo?.replyToPost && !toPost ? scrollTo.replyToPost.id : scrollTo.id)).is_reply = true;
              setTimeout(() => {
                this.posts.find(x => x.id == (!!scrollTo?.replyToPost && !toPost ? scrollTo.replyToPost.id : scrollTo.id)).is_reply = false;
                if (this.postsContainer) {
                  this.postsContainer.nativeElement.classList.remove("disable_scroll")
                }
              }, 4000)
            }
        },
        error: (error) => {
          console.log(error)
        }
      })
    )
  }

  goTolink(e) {
    console.log(e);
    console.log(this.selectedText);
    if (this.selectedText && this.selectedText.state && this.selectedText.state.link) {
      window.open(this.selectedText.state.link, '_blank')
    }
  }

  // getPostsToReply(chat_id, scrollTo:any = false, noScroll?, changeCount?) {
  //   console.log("getPostsToReplygetPostsToReplygetPostsToReplygetPostsToReplygetPostsToReplygetPostsToReply");
  //   if (this.page <= this.pagination['pageCount']) {
  //     this.isLoad = true;
  //     this.attachSubscriptions(
  //       this.chatService.getPosts(chat_id, this.page, this.company.id, undefined, !this.is_mobile ? 45 : 30).pipe(
  //         tap(el => this.chatService.postsData$.next(el.body)),
  //         map(el => el.body.reverse()),
  //         switchMap(res => {
  //             return from(res.reverse()).pipe(
  //               tap((element:any) => {
  //                 // if (moment(element['updated_at']*1000).isAfter(this.A_WEEK_OLD)) {
  //                 //   element["moment_date"] = moment(element['updated_at']*1000).startOf('minute').fromNow();
  //                 // } else {
  //                 //   element["moment_date"] = moment(element['updated_at']*1000).format('ll');
  //                 // }
  //                 if (!this.posts.find(x => x.id == element.id)) {
  //                   element.discussionPostFiles.forEach(el => {
  //                     if (el.file.meta_width && el.file.meta_height) {
  //                       if (el.file.meta_width <= el.file.meta_height) {
  //                         let perc = 320/(+el.file.meta_height)
  //                         el.file.perc = perc;
  //                         el.file.prediction_w_size = Math.round((+el.file.meta_width)*perc);
  //                         el.file.prediction_h_size = 320;
  //                       } else {
  //                         let perc = 320/el.file.meta_width
  //                         el.file.perc = perc;
  //                         el.file.prediction_w_size = 320;
  //                         el.file.prediction_h_size = Math.round((+el.file.meta_height)*perc);
  //                       }
  //                     }
  //                   });
  //                   if (element.replyToPost && element.replyToPost.discussionPostFiles && element.replyToPost.discussionPostFiles.length > 0) {
  //                     element.replyToPost.discussionPostFiles.forEach(el => {
  //                       if (el.file.meta_width && el.file.meta_height) {
  //                         if (el.file.meta_width <= el.file.meta_height) {
  //                           let perc = 320/(+el.file.meta_height)
  //                           el.file.perc = perc;
  //                           el.file.prediction_w_size = Math.round((+el.file.meta_width)*perc);
  //                           el.file.prediction_h_size = 320;
  //                         } else {
  //                           let perc = 320/el.file.meta_width
  //                           el.file.perc = perc;
  //                           el.file.prediction_w_size = 320;
  //                           el.file.prediction_h_size = Math.round((+el.file.meta_height)*perc);
  //                         }
  //                       }
  //                     });
  //                   }
  //                   this.posts.unshift(element)
  //                 }
  //               })
  //             )
  //         }),
  //       ).subscribe({
  //         next: (next) => {
  //           // console.log(next)
  //         },
  //         complete: () => {
  //           if (changeCount) {
  //             this.postsSrollCount = 1;
  //           }
  //           this.page = this.page + 1;
  //           this.goToPostReply(scrollTo, noScroll);
  //           console.log("getPostsToReply", this.posts)
  //         },
  //         error: (error) => {
  //           console.log(error)
  //         }
  //       })
  //     )
  //   } else {
  //     if (changeCount) {
  //       this.postsSrollCount = 1;
  //       this.goToPostReply(scrollTo, noScroll);
  //     }
  //   }
  // }
  
  getNotificationEmployees() {
    this.attachSubscriptions(
      this.pushNotificationsService.getNotificationEmployees(this.company.id, this.chat_id).pipe(
        tap(x => {
          x.forEach(el => {
            if (el.notificationSubscription == null) {
              el.notificationSubscription = {
                company_id: el.company_id,
                employee_id: el.id,
                discussion_id: this.chat_id,
                id: 0,
                is_webpush: 0
              }
            }
          })
        })
      ).subscribe(resp => {
        console.log("getNotificationEmployees", resp);
        this.notEmployees = resp;
        this.selectedNotEmployees = resp.filter(x => x.notificationSubscription && x.notificationSubscription.is_webpush == 1);
        console.log("this.employees", this.employees)
      })
    )
  }

  deletePin(post, i) {
    this.attachSubscriptions(
      this.chatService.editPost(post.id, {is_pinned: Math.abs(post.is_pinned - 1)}, this.company.id).pipe(
        tap(val => {
          post.is_pinned = val.is_pinned
        })
      ).subscribe(resp => {
        this.pinned.splice(i,1)
      })
    )
  }

  customLink(val) {
    // if (this.is_mobile) {
    //   val = val.replaceAll(" href=", ' target="_blank" href=');
    //   val = val.replaceAll('>https://www.', '>').replaceAll('>http://www.', '>');
    //   val = val.replaceAll('>https://', '>').replaceAll('>http://', '>');
    // }

    return val
  }

  toShortCut(e, item, type) {
    if (type == 'chat') {
      this.attachSubscriptions(
        this.taskBarService.addBarItem({company_id: this.company.id, task_id: item.task_id, task_operation_id: item.task_operation_id, discussion_id: item.id}).subscribe(res => {
          this.minimizeService.minimizes$.next(res)
        }, error => {
          console.log("error toShortCut")
        })
      )
    } else if (type == 'job') {
      this.attachSubscriptions(
        this.taskBarService.addBarItem({company_id: this.company.id, task_id: item.task_id, task_operation_id: item.id, discussion_id: 0}).subscribe(res => {
          this.minimizeService.minimizes$.next(res)
        }, error => {
          console.log("error toShortCut")
        })
      )
    } else {
      this.attachSubscriptions(
        this.taskBarService.addBarItem({company_id: this.company.id, task_id: item.id, task_operation_id: 0, discussion_id: 0}).subscribe(res => {
          this.minimizeService.minimizes$.next(res)
        }, error => {
          console.log("error toShortCut")
        })
      )
    }
  }

  // splitContent(el: ElementRef, body?) {
  //   let childNodes;
  //   if (el) {
  //     childNodes = el.nativeElement.childNodes;
  //   } else if (body) {
  //     childNodes = body.childNodes;
  //   }
  
  //   const messages = [];
  //   const maxLen = 4500;
  //   let oneMsg = '';
  //   const sentenceEndRegex = /(\s*[.?!])/g;
  
  //   for (let i = 0; i < childNodes.length; i++) {
  //     const node = childNodes[i];
  //     if (node.nodeType === Node.TEXT_NODE) {
  //       let textChunks = node.textContent.split(sentenceEndRegex).filter(Boolean);
  
  //       for (let j = 0; j < textChunks.length; j++) {
  //         const textChunk = textChunks[j];
  //         if ((oneMsg + textChunk).length <= maxLen) {
  //           oneMsg += textChunk;
  //         } else {
  //           messages.push(oneMsg);
  //           oneMsg = textChunk.trim();
  //         }
  //       }
  //     } else if (node.nodeType === Node.ELEMENT_NODE) {
  //       const htmlChunk = node.outerHTML;
  //       if ((oneMsg + htmlChunk).length <= maxLen) {
  //         oneMsg += htmlChunk;
  //       } else {
  //         messages.push(oneMsg);
  //         oneMsg = htmlChunk.trim();
  //       }
  //     }
  
  //     if (i === childNodes.length - 1 && oneMsg.length > 0) {
  //       messages.push(oneMsg);
  //     }
  //   }
  
  //   return messages;
  // }
  
  splitContent(el: ElementRef, body?) {
    let childNodes;
    if (el) {
      childNodes = el.nativeElement.childNodes;
    } else if (body) {
      childNodes = body.childNodes;
    }
    const messages = [];
    const maxLen = 4500;
    let oneMsg = '';
    const sentenceEndRegex = /(\s*[.?!])/g;

    for (let i = 0; i < childNodes.length; i++) {
      const node = childNodes[i];
      if (node.nodeType === Node.TEXT_NODE) { // проверяем, является ли узел текстовым
        const textChunks = node.textContent.split(sentenceEndRegex).filter(Boolean);
        if (textChunks.length > 1) { // если есть точки
          for (let j = 0; j < textChunks.length; j++) {
            const textChunk = textChunks[j];
            if ((oneMsg + textChunk).length < maxLen) {
              oneMsg += textChunk;
              if (j === textChunks.length - 1 && i === childNodes.length - 1) {
                messages.push(oneMsg);
                oneMsg = '';
              }
            } else {
              messages.push(oneMsg);
              oneMsg = `${textChunk.trimLeft()}.`;
            }
          }
        } else { // если нет точек
          const text = textChunks[0];
          let start = 0;
          while (start < text.length) {
            const end = Math.min(start + maxLen, text.length);
            const chunk = text.substring(start, end);
            if ((oneMsg + chunk).length < maxLen + 1) {
              oneMsg += chunk;
              if (end === text.length && i === childNodes.length - 1) {
                messages.push(oneMsg);
                oneMsg = '';
              }
            } else {
              messages.push(oneMsg);
              oneMsg = chunk.trimLeft();
            }
            start = end;
          }
        }
      } else if (node.nodeType === Node.ELEMENT_NODE) { // проверяем, является ли узел HTML-элементом
        if ((oneMsg + node.outerHTML).length < maxLen) {
          oneMsg += node.outerHTML;
          if (i === childNodes.length - 1) {
            messages.push(oneMsg);
            oneMsg = '';
          }
        } else {
          messages.push(oneMsg);
          oneMsg = node.outerHTML.trimLeft();
        }
      } else if (node.nodeType === Node.COMMENT_NODE) { // проверяем, является ли узел комментарием
        continue; // пропускаем комментарии
      }
    }

    return messages;
}
  
  send(e?) {
    if (!!e) {
      if (e && !e.shiftKey) {
        e.stopPropagation();
        e.preventDefault();
        console.log("a")
      } else if (e && e.shiftKey) {
        return
      }
    }
    if (this.isSended) {
      return
    }
    if (this.form.value.text == "") {
      this.layoutService.showSnackBar('', marker("You cannot send empty message!"), SnackBarItem)
      return
    }
    this.isSended = true;
    let data = {...this.form.value};

    
    if (this.reply.id) {
      data.reply_to_post_id = this.reply.id
    }

    // data.text = this.customLink(data.text)

    if (this.updateDiscussionSub) {
      this.updateDiscussionSub.unsubscribe();
    }
    if (this.chatRead) {
      this.chatRead.unsubscribe();
    }

    let massages = this.splitContent(this.msg || this.mob_msg).filter(x => x.length > 0)

    console.log("massages", massages)

    data.text = massages[0]
    this.form.patchValue({
      text: ''
    })
    this.attachSubscriptions(
      this.chatService.sendPost(data, this.company.id).pipe(
        switchMap(value => {
          // return of(value)
          if (!!this.mustChangeStatus) {
            return this.chatService.editChat(this.chat_id, {status_id: this.mustChangeStatus.id}, this.company.id).pipe(
              switchMap(() => {
                return forkJoin(this.emplSelected.filter(x => x.partnerCompanyStatus || x.employeeStatus).map(val => (val.is_partner ? this.membersService.editPartnerStatus(val.partnerCompanyStatus.id, {status_id: this.mustChangeStatus.id}, this.company.id) : (val.is_partner_employee ? this.membersService.editPartnerEmployeeStatus(val.employeeStatus.id, {status_id: this.mustChangeStatus.id}, this.company.id) : this.membersService.editTaskEmployeeStatus(val.employeeStatus.id, {status_id: this.mustChangeStatus.id}, this.company.id))))).pipe(last(), map(() => value))
              }),
              switchMap(val => {
                if ((this.iAmEmployee?.is_manager == 1 || this.checkIsManager(this.task, this.company, this.user)) && [3,4,98,99].includes(this.mustChangeStatus.id)) {
                  return this.chatService.detectChats(this.company.id, this.work.id, [1,2,5,6]).pipe(
                    map(x => x.headers.get('x-pagination-total-count')),
                    switchMap(res => {
                      if (res == 0) {
                        let questionAlert =  this.bottomSheet.open(BackJobToPrevStatusComponent, {
                          hasBackdrop: true,
                          backdropClass: 'bottom-sheed-backdrop',
                          data: {
                            msg: marker(`Do you really want to convert job to status: '${this.getOperationStatusName(this.mustChangeStatus.id)}'?`)
                          }
                        });
                    
                        return questionAlert.afterDismissed()
                        .pipe(
                          switchMap(data => {
                            if (data && data.message == 'no') {
                              console.log("no");
                              return of(val)
                            } else if (data && data.message == 'yes') {
                              return this.taskService.editWork(this.work.id, {status_id: this.mustChangeStatus.id}, this.company.id).pipe(
                                map(() => val)
                              )
                            } else {
                              return of(val)
                            }
                          })
                        )
                      } else {
                        return of(val)
                      }
                    })
                  )
                } else {
                return of(val)
                }
              })     
            )
          } else {
            return of(value)
          }
        }),
        switchMap(msgBody => {
          return this.chatService.postChatRead(this.chat_id, 0, this.company.id, '', 0, 0).pipe(
            tap(x => {
              if (!this.chat.discussionPostDraft) {
                this.chat.discussionPostDraft = {}
              }
              this.chat.discussionPostDraft.text = '';
              this.chat.discussionPostDraft.reply_to_post_id = 0;
              this.chat.discussionPostDraft.update_post_id = 0;
              this.closeEdit();
              this.getTargetPost(this.chat_id, msgBody.id);
              this.closeReply()
      
              this.isSended = false;
            }),
            map(() => msgBody)
          )
        }),
        switchMap(msgBody => {
          console.log("massages in switchMap", massages)
          if (massages.length > 1) {
            let newMassages = [...massages]
            newMassages = newMassages.slice(1)
            console.log("newMassages", newMassages)
            return concat(...newMassages.map(text => this.chatService.sendPost({discussion_id: data.discussion_id, text: text}, this.company.id).pipe(
              switchMap(subMsgBody => {
                return this.chatService.postChatRead(this.chat_id, 0, this.company.id, '', 0, 0).pipe(
                  tap(x => {
                    if (!this.chat.discussionPostDraft) {
                      this.chat.discussionPostDraft = {}
                    }
                    this.chat.discussionPostDraft.text = '';
                    this.chat.discussionPostDraft.reply_to_post_id = 0;
                    this.chat.discussionPostDraft.update_post_id = 0;
                    this.closeEdit();
                    this.getTargetPost(this.chat_id, subMsgBody.id);
                    this.closeReply()
            
                    this.isSended = false;
                  }),
                  map(() => msgBody)
                )
              })
            )))
          } else {
            return of(msgBody)
          }
        }),
        finalize(() => {
          this.updateDiscussion(this.chat_id)
          this.refreshService.refreshChat$.next({company_id: this.company.id, chat_id: this.chat_id})
          this.initRefreshBoard()
        })
      ).subscribe(resp => {
        console.log(resp);
        this.isSended = false;
      }, error => {
        this.isSended = false;
      })
    )
  }

  edit(e?) {
    if (e && !e.shiftKey) {
      e.stopPropagation();
      e.preventDefault();
      console.log("a")
    } else if (e && e.shiftKey) {
      return
    }
    if (this.isSended) {
      return
    }

    if (this.chatRead) {
      this.chatRead.unsubscribe();
    }

    if (this.edited.value.text != '') {
      this.isSended = true;
      let data = {...this.edited.value}

      data.text = this.customLink(data.text)

      this.attachSubscriptions(
        this.chatService.editPost(this.post_edited_id, data, this.company.id).subscribe(resp => {
          if (this.posts.find(el => el.id == this.post_edited_id)) {
            this.posts.find(el => el.id == this.post_edited_id).text = resp.text;
            this.posts.find(el => el.id == this.post_edited_id).updated_at = resp.updated_at;
          }
          this.posts.forEach(x => {
            if (x.replyToPost && resp.id == x.replyToPost.id) {
              x.replyToPost.text = resp.text
              x.replyToPost.updated_at = resp.updated_at
            }
          })
          this.post_edited = false;
          this.post_edited_id = 0;
          this.edited.patchValue({
            text: ''
          })
          this.isSended = false;
        })
      )
    } else {
      this.layoutService.showSnackBar('', marker("You cannot make the message empty!"), SnackBarItem)
    }

  }

  getTargetPost(chat_id, post_id) {
    this.attachSubscriptions(
      this.chatService.getTargetPost(chat_id, post_id, this.company.id).subscribe(resp => {
        console.log("getTargetPost", resp)
        if (resp.length && !this.posts.find(y => y.id == resp[0].id)) {
          // this.setMomentJs(resp);
          if (this.chatScrollStrategy.value.id == 2 || (this.posts.length && this.chat && this.chat.lastPost && this.posts.find(x => x.id == (this.chat.lastPost.maxId || this.chat.lastPost.id)))) {
            this.posts.push(...resp);
            this.tryHigh('4')
            this.chatService.postsData$.next([resp]);
            setTimeout(() => { this.oldScrollToBottom() }, 300)
          } else {
            this.chat.lastPost.maxId = Math.max(this.posts[this.posts.length - 1].id, this.chat.lastPost.id, this.chat.lastPost.maxId || 0, resp.id)
            this.newMsgCounter++;
          }
        }
      })
    )
  }

  oldScrollToBottom(type?): void {
    try {
      if (this.posts.length && this.chat && this.chat.lastPost && this.posts.find(x => x.id == (this.chat.lastPost.maxId || this.chat.lastPost.id))) {
        document.getElementById(`post_${this.posts[this.posts.length - 1].id}`).scrollIntoView({
          behavior: type ? type : "auto",
          block: "end"
        });
        this.showArrowDown = false;
        this.newMsgCounter = 0;
      }
    } catch(err) {
    }                 
  }

  // setTimeout(this.clearMobInput, 1000)
  mobileInput(e) {
    this.menuOpened = false;
    this.mouseUp()
  }

  // onPasteDeskInput(e) {
  //   console.log("onPasteDeskInput", e);
  //   // e.preventDefault()
  //   this.mouseUp()
  // }

  onPasteDeskInput(e) {
    console.log("onPasteDeskInput OUT", e, e.target.nodeName, !!e.target.closest('code'))
    
    if (e.target && (e.target.nodeName == 'CODE' || !!e.target.closest('code'))) {
      e.preventDefault();
      console.log("onPasteDeskInput IN CODE", e.target.nodeName, !!e.target.closest('code'));
    
      const htmlData = e.clipboardData.getData('text/html');
      const plainTextData = e.clipboardData.getData('text/plain');
      const selection = window.getSelection();
      const range = selection?.getRangeAt(0);
  
      console.log("htmlData", htmlData);
      console.log("plainTextData", plainTextData);
  
      // Обработка текста для вставки
      let text = htmlData || plainTextData;
  
      if (htmlData) {
        text = htmlData
          .replace(/<\/div>/g, "\n") // Замена закрывающих блоков <div> на перенос строки
          .replace(/<\/p>/g, "\n")   // Замена закрывающих блоков <p> на перенос строки
          .replace(/<\/li>/g, "\n")  // Замена закрывающих блоков <li> на перенос строки
          .replace(/<br\s*\/?>/g, "\n") // Замена <br> на перенос строки
          .replace(/<\/?[^>]+(>|$)/g, "") // Удаление HTML-тегов
          .replace(/\r\n|\n|\r/g, "<br>") // Замена переносов строк на <br>
          .replace(/\t/g, "&nbsp;&nbsp;&nbsp;&nbsp;"); // Замена табуляций на пробелы
      } else {
        text = plainTextData
          .replace(/\r\n|\n|\r/g, "<br>") // Замена переносов строк на <br>
          .replace(/\t/g, "&nbsp;&nbsp;&nbsp;&nbsp;"); // Замена табуляций на пробелы
      }
  
      console.log("text", text);
      console.log("range", range);
  
      // Вставляем текст как HTML
      const fragment = document.createRange().createContextualFragment(text);
      console.log("fragment", fragment);
      range.deleteContents(); // Удаляем текущий выделенный текст
      range.insertNode(fragment); // Вставляем новый текст
  
      this.tryHigh('onPasteDeskInput');
      this.updateFields();
      // e.preventDefault();
      // console.log("onPasteDeskInput IN CODE", e.target.nodeName, !!e.target.closest('code'))
      // const htmlData = e.clipboardData.getData('text/html');
      // const plainTextData = e.clipboardData.getData('text/plain');
      // const selection = window.getSelection();
      // const range = selection?.getRangeAt(0);

      // console.log("htmlData", htmlData)
      // console.log("plainTextData", plainTextData)
      // // Если есть HTML-код
      // const text = htmlData ? htmlData.replaceAll("\n", "<br>").replace(/<\/?[^>]+(>|$)/g, "") : plainTextData.replaceAll("\n", "<br>");
      
      // console.log("text", text)
      // console.log("range", range)
      
      // // Вставляем текст как HTML
      // const fragment = document.createRange().createContextualFragment(text);
      // console.log("fragment", fragment)
      // range.deleteContents(); // Удаляем текущий выделенный текст
      // range.insertNode(fragment); // Вставляем новый текст

      // this.tryHigh('onPasteDeskInput');
      // this.updateFields();
    } else {
      if (e && e.clipboardData && ((!!e.clipboardData.getData('text/html') && e.clipboardData.getData('text/html').indexOf('on_reports_copy') == -1) || e.clipboardData.getData('text/html') == '')) {
        console.log("onPasteDeskInput IN")
        e.preventDefault();
        const text = e.clipboardData.getData('text/plain').replaceAll("\n","<br>");
        document.execCommand('insertHTML', false, text);
      }
      this.mouseUp()
    }
  }

  // onPasteDeskInput(e) {
  //   console.log("onPasteDeskInput OUT", e)
  //   if (e && e.clipboardData && ((!!e.clipboardData.getData('text/html') && e.clipboardData.getData('text/html').indexOf('on_reports_copy') == -1) || e.clipboardData.getData('text/html') == '')) {
  //     console.log("onPasteDeskInput IN")
  //     e.preventDefault();
  //     const text = e.clipboardData.getData('text/plain').replaceAll("\n","<br>");
  //     document.execCommand('insertHTML', false, text);
  //   }
  //   this.mouseUp()
  // }

  // onPasteDeskInput(e) {
  //   console.log("onPasteDeskInput OUT", e);
  
  //   // Проверяем, если данные есть в буфере обмена
  //   if (e && e.clipboardData) {
  //     const htmlData = e.clipboardData.getData('text/html');
  //     const plainTextData = e.clipboardData.getData('text/plain');
  
  //     // Условие для обработки вставки HTML данных, исключая данные с 'on_reports_copy'
  //     if ((htmlData && htmlData.indexOf('on_reports_copy') === -1) || htmlData === '') {
  //       e.preventDefault();
  
  //       const selection = window.getSelection();
  //       const range = selection?.getRangeAt(0);
  
  //       if (range) {
  //         const node = range.startContainer.nodeType === Node.ELEMENT_NODE
  //           ? range.startContainer as HTMLElement
  //           : range.startContainer.parentNode as HTMLElement;
  
  //         // Проверяем, если вставка происходит внутри элемента <code>
  //         if (node.closest('code')) {
  //           // Если есть HTML-код
  //           const text = htmlData ? htmlData.replace(/<\/?[^>]+(>|$)/g, "") : plainTextData.replaceAll("\n", "<br>");
            
  //           // Вставляем текст как HTML
  //           const fragment = document.createRange().createContextualFragment(text);
  //           range.deleteContents(); // Удаляем текущий выделенный текст
  //           range.insertNode(fragment); // Вставляем новый текст
  //         } else {
  //           // Если вставка не внутри <code>, вставляем текст
  //           const text = plainTextData.replaceAll("\n", "<br>");
  //           document.execCommand('insertHTML', false, text);
  //         }
  //       }
  //     }
  //   }

  //   // this.tryHigh('onPasteDeskInput');
  //   // this.updateFields();
  
  //   this.mouseUp();
  // }
  

  // onPasteDeskInput(e) {
  //   console.log("onPasteDeskInput OUT", e.clipboardData.getData('text/html'))
  //   console.log("onPasteDeskInput OUT", e.clipboardData.getData('text/x-code'))
  //   console.log("onPasteDeskInput OUT", e.clipboardData.types);
  //   if (e && e.clipboardData && ((!!e.clipboardData.getData('text/html') && e.clipboardData.getData('text/html').indexOf('on_reports_copy') == -1) || e.clipboardData.getData('text/html') == '' )) {
  //     console.log("onPasteDeskInput IN")
  //     e.preventDefault();
  //     const text = e.clipboardData.getData('text/plain').replaceAll("\n","<br>");
  //     document.execCommand('insertHTML', false, (!!e.clipboardData.types && e.clipboardData.types > 2) ? `<code>${text}</code>` : text);
  //   }
  //   this.mouseUp()
  // }

  // onPasteDeskInput(e) {
  //   if (e && e.clipboardData && ((!!e.clipboardData.getData('text/html') && e.clipboardData.getData('text/html').indexOf('on_reports_copy') == -1) || e.clipboardData.getData('text/html') == '')) {
  //     e.preventDefault();
  //     const text = e.clipboardData.getData('text/plain').replaceAll("\n","<br>");
  //     document.execCommand('insertHTML', false, text);
  //   }
  //   this.mouseUp()
  // }

  deskInput(e) {
    if (e.dataTransfer && e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      console.log('prevent');
      e.preventDefault();
      e.stopPropagation();
    }
    this.mouseUp()
  }

  closeEdit() {
    this.post_edited = false;
    this.post_edited_id = 0;
  }

  startDragged() {
    document.body.classList.add("dragged");
  }
  
  stopDragged() {
    document.body.classList.remove("dragged");
  }

  mobOptions() {
    const dialogRef = this.dialog.open(MobChatOptionsComponent, {
      backdropClass: ['mob_interface_backdrop'],
      panelClass: ['mob_interface_panel', 'animate__animated', 'animate__slideInUp'],
      data: {
        company: this.company,
        chat: this.chat,
        task: this.task,
        user: this.user,
        host: this.host
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log("afterClosed", result)
        if (!!result && !!result.data) {
          switch (result.data) {
            case 'copy link':
              console.log("copy link");
              // this._clipboardService.copy(this.host + '/chat/' + this.chat.acm)
              // this.copyLink("Chat")
              break;
            case 'delete':
              this.deleteChat()
              break;
            case 'notes':
              this.openNotes(this.chat)
              break;
            case 'move':
              this.moveChat()
              break;
            case 'priority':
              this.mobEditPriority(this.chat)
              break;
            case 'search':
              console.log("search");
              break;
            case 'search':
              console.log("info");
              break;
            case 'edit title':
              this.mobEditTitle(this.chat)
              break;
            case 'assignments':
              this.additionalEmployees()
              break;
            case 'conns':
              this.openConnectionChats()
              break;
            case 'board':
              this.addToBoard()
              break;
            case 'parameters':
              this.openTargetValues([this.chat.task_id, this.chat.task_operation_id, this.chat.id], this.chat)
              break;
            case 'push':
              this.mobPushSettigns()
              break;
          
            default:
              console.log("Default")
              break;
          }
        }
      })
    )
  }
  
  changeMobJobStatus() {
    const dialogRef = this.dialog.open(MobChangePersonalStatusComponent, {
      backdropClass: ['mob_interface_backdrop'],
      panelClass: ['mob_interface_panel', 'animate__animated', 'animate__slideInUp'],
      data: {
        company: this.company,
        task: this.task,
        work: this.work,
        chat: this.chat,
        chatEmlStats: this.chatEmlStats,
        host: this.host,
        imgRoute: this.imgRoute,
        user: this.user,
        statuses: this.statuses.filter(x => x.id != 98),
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log("afterClosed", result)
        if (!!result && result.event == 'update') {
          this.updateDiscussion(this.chat_id)
          this.updateChat(this.chat_id)
          this.initRefreshBoard()
        }
      })
    )
  }

  changeStatuses() {
    const dialogRef = this.dialog.open(MobPersonalStatusesComponent, {
      panelClass: ['chats_modal'],
      data: {
        company: this.company,
        task: this.task,
        work: this.work,
        chat: this.chat,
        imgRoute: this.imgRoute,
        host: this.host,
        user: this.user,
        statuses: this.statuses,
        chatEmlStats: this.chatEmlStats,
        iAmEmployee: this.iAmEmployee,
        updateDiscussion: () => {this.updateDiscussion(this.chat_id)}
      }
    });


    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        this.updateChat(this.chat_id)
        this.initRefreshBoard()
      })
    )

  }

  openStatuses() {
    const dialogRef = this.dialog.open(MobChatStatusEditComponent, {
      data: {
        company: this.company,
        streamData: this.chat,
        user: this.user,
        statuses: this.statuses
      }
    });

    if (this.is_small) {
      this.attachSubscriptions(
        dialogRef.afterClosed().subscribe(result => {
          if (!!result && result.event == 'Edit') {
            this.chat.status_id = result.data.status_id
            // this.taskService.getOneTaskExpand(this.company.id, this.task.id).subscribe(resp => {
            //   console.log(this.task, this.work, resp)
            //   // this.workAfterClosed(resp, this.task, this.work);
            // })
          }
        })
      )
    }
  }

  hideMenu() {
    this.HideMenu.emit()
  }

  openChatMenu() {
    this.menuOpened = true;

    // this.msg.nativeElement
    
    // this.msg.nativeElement.focus();
  }

  focusFunction() {
    this.menuOpened = false;
  }

  unFocusFunction() {
    this.menuOpened = true;
  }

  toggleMobMenu() {
    this.menuOpened = !this.menuOpened
  }

  copyPost(val) {
    this.layoutService.showSnackBar({name: val}, marker("Copied"), SnackBarItem)
  }

  onImgError(event){
    event.target.src = this.imgRoute+'/assets/img/image_black_48dp.svg'
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  deletePost(chat, post, cancel?) {
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: post,
        target: marker("post")
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        if (this.postCollection.isSelected(post) && this.postCollection.selected.length > 1) {
          console.log("setAsChecklist post", post)
          this.attachSubscriptions(
            forkJoin([...this.postCollection.selected.map(x => this.chatService.deletePost(x.id, this.company.id).pipe(
              tap(res => {
                if (cancel) {
                  this.cancelUpload(cancel)
                }
                if (this.posts.find(p => x.id == p.id)) {
                  this.posts.splice(this.posts.indexOf(x), 1);
                }
              }),
              catchError(err => {
                return of(err)
              })
            ))]).subscribe(resp => {
              this.postCollection.clear()
            })
          )
        } else {  
          this.attachSubscriptions(
            this.chatService.deletePost(post.id, this.company.id).subscribe(resp => {
              if (cancel) {
                this.cancelUpload(cancel)
              }
              this.posts.splice(this.posts.indexOf(post), 1);
            })
          )
        }
      }
    });
  }

  markAsDeletePost(chat, post) {
    if (this.postCollection.isSelected(post) && this.postCollection.selected.length > 1) {
      console.log("setAsChecklist post", post)
      this.attachSubscriptions(
        forkJoin([...this.postCollection.selected.map(x => this.chatService.editPost(x.id, {is_deleted: !!post.is_deleted ? 0 : 1}, this.company.id).pipe(
          tap(res => {
            x.is_deleted = res.is_deleted;
          }),
          catchError(err => {
            return of(err)
          })
        ))]).subscribe(resp => {
          this.postCollection.clear()
        })
      )
    } else {
      this.attachSubscriptions(
        this.chatService.editPost(post.id, {is_deleted: !!post.is_deleted ? 0 : 1}, this.company.id).subscribe(resp => {
          post.is_deleted = resp.is_deleted
          this.layoutService.showSnackBar({name: ''}, !!resp.is_deleted ? marker("Message marked as deleted") : marker("Removed the mark of a deleted message"), SnackBarItem)
        }, error => {
          this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
        })
      )
    }
  }

  markAsHiddenPost(chat, post) {
    if (this.postCollection.isSelected(post) && this.postCollection.selected.length > 1) {
      console.log("setAsChecklist post", post)
      this.attachSubscriptions(
        forkJoin([...this.postCollection.selected.map(x => this.chatService.editPost(x.id, {is_hidden: !!post.is_hidden ? 0 : 1}, this.company.id).pipe(
          tap(res => {
            x.is_hidden = res.is_hidden;
          }),
          catchError(err => {
            return of(err)
          })
        ))]).subscribe(resp => {
          this.postCollection.clear()
        })
      )
    } else {
      this.attachSubscriptions(
        this.chatService.editPost(post.id, {is_hidden: !!post.is_hidden ? 0 : 1}, this.company.id).subscribe(resp => {
          post.is_hidden = resp.is_hidden
          this.layoutService.showSnackBar({name: ''}, !!resp.is_hidden ? marker("Message marked as hidden") : marker("Removed the mark of a hidden message"), SnackBarItem)
        }, error => {
          this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
        })
      )
    }
  }

  showHidden(e, post) {
    e.preventDefault(); 
    e.stopPropagation();
    post.hidden_shown = true;
  }

  markAsOutdatedPost(chat, post) {
    if (this.postCollection.isSelected(post) && this.postCollection.selected.length > 1) {
      console.log("setAsChecklist post", post)
      this.attachSubscriptions(
        forkJoin([...this.postCollection.selected.map(x => this.chatService.editPost(x.id, {is_outdated: !!post.is_outdated ? 0 : 1}, this.company.id).pipe(
          tap(res => {
            x.is_outdated = res.is_outdated;
          }),
          catchError(err => {
            return of(err)
          })
        ))]).subscribe(resp => {
          this.postCollection.clear()
        })
      )
    } else {
      this.attachSubscriptions(
        this.chatService.editPost(post.id, {is_outdated: !!post.is_outdated ? 0 : 1}, this.company.id).subscribe(resp => {
          post.is_outdated = resp.is_outdated
          this.layoutService.showSnackBar({name: ''}, !!resp.is_outdated ? marker("Message marked as outdated") : marker("Removed the mark of a outdated message"), SnackBarItem)
        }, error => {
          this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
        })
      )
    }
  }

  getColumnName(id) {
    if (!this.operationsValues || this.operationsValues.filter(el => el.id == id).length == 0) {
      return '';
    }
    return this.operationsValues.find(el => el.id == id) && this.operationsValues.find(el => el.id == id).translation ? this.operationsValues.find(el => el.id == id).translation.name : this.operationsValues.find(el => el.id == id).name
  }

  checkIsManager(task, company, _user) {
    return task && company && task.company_id == company.employees[0].company_id && (task?.managers.filter(x => x.user_id == _user.id).length > 0 || task?.group?.managers.filter(x => x.user_id == _user.id).length > 0 || company?.permissions.includes('owner') || company?.permissions.includes('admin') || company?.permissions.includes('manager')); 
  }

  checkActions(e, task) {
    if (!this.is_mobile) {
      e.preventDefault();
      e.stopPropagation();
    }
    console.log(task.id, 1, this.task.company_id)
    this.attachSubscriptions(
      this.taskService.getActions(task.id, 1, this.task.company_id).subscribe(resp => {
        

        if (resp.body.length) {
          this.dialog.open(NotificationsBar, {
            backdropClass: this.is_mobile ? ['mob_interface_backdrop'] : 'backdrop_under_header',
            panelClass: this.is_mobile ? ['mob_interface_panel', 'animate__animated', 'animate__slideInUp'] : ['open_task_dialog', 'show_header'],
            data: {
              notifications: resp.body,
              company: this.company,
              user: this.user,
              task: task,
              header: false,
              tasks: this.tasks,
              initCompanyId: this.company.id,
              empl_status: this.company.permissions.includes('owner') || this.checkIsManager(this.task, this.company, this.user),
              pagination: {
                'pageCount': resp.headers.get('x-pagination-page-count'),
                'perPage': resp.headers.get('x-pagination-per-page'),
                'totalCount': resp.headers.get('x-pagination-total-count'),
                'currentPage': resp.headers.get('x-pagination-current-page'),
              },
              preClose: () => {this._snackBar.dismiss()}
            }
          });
        }
      })
    )
  }

  close() {
    this.Close.emit()
  }

  

  putFile(files) {
    console.log("IN PUT", files)

    // this.post_edited
    if (this.post_edited) {
      let postData:any = {
        text: this.edited.value.text,
        upload_files: []
      }
  
      if (this.reply.id) {
        postData.reply_to_post_id = this.reply.id
      }
  
      for (let index = 0; index < files.length; index++) {
        const file = files[index];
        
        if (!file.withoutFile) {
          postData.upload_files.push({
            custom_upload_id: +index + 1,
            upload_filename: file.name,
            upload_content_type: file.type ? file.type : mimeTypes.getType(file.name),
            upload_filesize: file.size
          })
        }
        
        if (file.fileWithText) {
          postData.text = file.text
        }
      }
  
      
      console.log(postData);
      return this.chatService.editPost(this.post_edited_id, postData, this.company.id).pipe(
        switchMap(res => {
          return this.chatService.getTargetPost(this.chat.id, res.id, this.company.id).pipe(
            tap(n => {
              console.log("getTargetPost pre FILE", n);
              n[0].upload_files = res.upload_files
              n[0].discussionPostFiles.forEach((el,i) => {
                console.log("getTargetPost FILE", files[i]);
                if (files[i] && files[i].width && files[i].height) {
                  el.file.meta_width = files[i].width;
                  el.file.meta_height = files[i].height;
                  if (el.file.meta_width <= el.file.meta_height) {
                    let perc = 320/(+el.file.meta_height)
                    el.file.perc = perc;
                    el.file.prediction_w_size = Math.round((+el.file.meta_width)*perc);
                    el.file.prediction_h_size = 320;
                  } else {
                    let perc = 320/el.file.meta_width
                    el.file.perc = perc;
                    el.file.prediction_w_size = 320;
                    el.file.prediction_h_size = Math.round((+el.file.meta_height)*perc);
                  }
                }
              })
              this.posts.splice(this.posts.indexOf(this.posts.find(x => x.id == this.post_edited_id)), 1, n[0])
              this.tryHigh('5');
            }),
            map((n) => n[0])
          )
        }),
        tap(rslt => {
          this.closeEdit();
          if (rslt.upload_files) {
            for (let index = 0; index < rslt.upload_files.length; index++) {
              let deltaIndex = rslt.discussionPostFiles && rslt.discussionPostFiles.length && rslt.upload_files && rslt.upload_files.length ? rslt.discussionPostFiles.length - rslt.upload_files.length : 0
              const uploadFile = rslt.upload_files[index];
              rslt.discussionPostFiles[deltaIndex + index].file.upload_url = uploadFile.upload_url;
              rslt.discussionPostFiles[deltaIndex + index].file.size = rslt.discussionPostFiles[deltaIndex + index].file.filesize;
              rslt.discussionPostFiles[deltaIndex + index].file.name = rslt.discussionPostFiles[deltaIndex + index].file.filename;
              let file = files[index]
              if (!file.withoutFile) {
                let fileData = {...rslt}
                fileData.upload_url = uploadFile.upload_url
                fileData.fileVal = file;
                fileData.discussion_post_id = rslt.id;
                let fileTarget = rslt.discussionPostFiles[deltaIndex + index].file;
                fileData.id = fileTarget.id;
                fileTarget.fileVal = file;
  
                console.log("fileData", fileData)
                console.log("file", file)
                console.log("uploadFile", uploadFile)
          
                this.fileService.files$.next({
                  index: deltaIndex + index,
                  place: "chat",
                  url: window.location.href,
                  data: fileData,
                  target: fileTarget,
                  post: rslt,
                  is_chat: true,
                  task: this.task,
                  work: this.work,
                  activeLang: this.activeLang,
                  operationsValues: this.operationsValues,
                  posts: this.posts,
                  chat: this.chat,
                  company: this.company,
                  company_id: this.company.id,
                  user: this.user
                })
              }
            }
          }
        }),
        tap(rslt => {
          this.clearAudioRecordedData();
          this.abortAudioRecording();
        })
      )
    } else {
      let postData:any = {
        discussion_id: this.chat_id,
        upload_files: []
      }
  
      if (this.reply.id) {
        postData.reply_to_post_id = this.reply.id
      }

      let massages = []
  
      for (let index = 0; index < files.length; index++) {
        const file = files[index];
        
        if (!file.withoutFile) {
          let fileData:any = {
            custom_upload_id: +index + 1,
            upload_filename: file.name,
            upload_content_type: file.type ? file.type : mimeTypes.getType(file.name),
            upload_filesize: file.size
          }
          if (file.name.indexOf("Audio-msg-") != -1) {
            fileData.is_rev_transcribe = 1
          }
          postData.upload_files.push(fileData)
        }
        
        if (file.fileWithText) {
          const parser = new DOMParser();
          const doc = parser.parseFromString(file.text, 'text/html');
          const body = doc.body;
          massages = this.splitContent(null, body).filter(x => x.length > 0)
          postData.text = massages[0]
        }
      }
  

      
      console.log("postData", postData);
      return this.chatService.sendPost(postData, this.company.id).pipe(
        switchMap(res => {
          return this.chatService.getTargetPost(this.chat.id, res.id, this.company.id).pipe(
            tap(n => {
              n[0].discussionPostFiles.forEach((el,i) => {
                console.log("getTargetPost FILE", files[i], n[0]);
                if (files[i] && files[i].width && files[i].height) {
                  el.file.meta_width = files[i].width;
                  el.file.meta_height = files[i].height;
                  if (el.file.meta_width <= el.file.meta_height) {
                    let perc = 320/(+el.file.meta_height)
                    el.file.perc = perc;
                    el.file.prediction_w_size = Math.round((+el.file.meta_width)*perc);
                    el.file.prediction_h_size = 320;
                  } else {
                    let perc = 320/el.file.meta_width
                    el.file.perc = perc;
                    el.file.prediction_w_size = 320;
                    el.file.prediction_h_size = Math.round((+el.file.meta_height)*perc);
                  }
                }
              })
              
              console.log("TESTtttttt")
              this.chatService.postsData$.next([n[0]]);
              
              if (this.chatScrollStrategy.value.id == 2 || (this.posts.length && this.chat && this.chat.lastPost && this.posts.find(x => x.id == (this.chat.lastPost.maxId || this.chat.lastPost.id)))) {
                this.posts.push(n[0])
                this.tryHigh('6')
                if (this.pagination && this.pagination.totalCount) {
                  this.pagination.totalCount = +this.pagination.totalCount + 1
                }
                setTimeout(() => { this.oldScrollToBottom() }, 100)
                if (this.chat.lastPost) {
                  this.chat.lastPost.maxId = Math.max(this.posts[this.posts.length - 1].id, this.chat.lastPost.id, this.chat.lastPost.maxId || 0, n[0].id)
                } else {
                  this.chat.lastPost = n[0];
                  this.chat.lastPost.maxId = n[0].id;
                }
              } else {
                this.newMsgCounter++;
              }
              n[0].upload_files = res.upload_files
            }),
            map((n) => n[0])
          )
        }),
        tap(rslt => {
          console.log("tap rslt", rslt)
          this.closeReply();
          if (rslt.upload_files) {
            for (let index = 0; index < rslt.upload_files.length; index++) {
              const uploadFile = rslt.upload_files[index];
              rslt.discussionPostFiles[index].file.upload_url = uploadFile.upload_url;
              rslt.discussionPostFiles[index].file.size = rslt.discussionPostFiles[index].file.filesize;
              rslt.discussionPostFiles[index].file.name = rslt.discussionPostFiles[index].file.filename;
              let file = files[index]
              if (!file.withoutFile) {
                let fileData = {...rslt}
                fileData.upload_url = uploadFile.upload_url
                fileData.fileVal = file;
                fileData.discussion_post_id = rslt.id;
                let fileTarget = rslt.discussionPostFiles[index].file;
                fileData.id = fileTarget.id;
                fileTarget.fileVal = file;
  
                console.log("fileData", fileData)
                console.log("file", file)
                console.log("uploadFile", uploadFile)
          
                this.fileService.files$.next({
                  index: index,
                  place: "chat",
                  url: window.location.href,
                  data: fileData,
                  target: fileTarget,
                  post: rslt,
                  is_chat: true,
                  task: this.task,
                  work: this.work,
                  activeLang: this.activeLang,
                  operationsValues: this.operationsValues,
                  posts: this.posts,
                  chat: this.chat,
                  company: this.company,
                  company_id: this.company.id,
                  user: this.user
                })
              }
            }
          }
        }),
        tap(rslt => {
          this.clearAudioRecordedData();
          this.abortAudioRecording();
        }),
        switchMap(rslt => {
          console.log("massages in switchMap", massages)
          if (massages.length > 1) {
            let newMassages = [...massages]
            newMassages = newMassages.slice(1)
            console.log("newMassages", newMassages)
            return concat(...newMassages.map(text => this.chatService.sendPost({discussion_id: postData.discussion_id, text: text}, this.company.id).pipe(
              switchMap(subMsgBody => {
                return this.chatService.postChatRead(this.chat_id, 0, this.company.id, '', 0, 0).pipe(
                  tap(x => {
                    if (!this.chat.discussionPostDraft) {
                      this.chat.discussionPostDraft = {}
                    }
                    this.chat.discussionPostDraft.text = '';
                    this.chat.discussionPostDraft.reply_to_post_id = 0;
                    this.chat.discussionPostDraft.update_post_id = 0;
                    this.closeEdit();
                    this.getTargetPost(this.chat_id, subMsgBody.id);
                    this.closeReply()
            
                    this.isSended = false;
                  }),
                  map(() => rslt)
                )
              })
            )))
          } else {
            return of(rslt)
          }
        }),
        switchMap(rslt => {
          setTimeout(() => { this.oldScrollToBottom() }, 100)
          if (!!this.mustChangeStatus) {
            return this.chatService.editChat(this.chat_id, {status_id: this.mustChangeStatus.id}, this.company.id).pipe(
              switchMap(() => {
                return forkJoin(this.emplSelected.filter(x => x.partnerCompanyStatus || x.employeeStatus).map(val => (val.is_partner ? this.membersService.editPartnerStatus(val.partnerCompanyStatus.id, {status_id: this.mustChangeStatus.id}, this.company.id) : (val.is_partner_employee ? this.membersService.editPartnerEmployeeStatus(val.employeeStatus.id, {status_id: this.mustChangeStatus.id}, this.company.id) : this.membersService.editTaskEmployeeStatus(val.employeeStatus.id, {status_id: this.mustChangeStatus.id}, this.company.id))))).pipe(last())
              }),
              switchMap(val => {
                if ((this.iAmEmployee?.is_manager == 1 || this.checkIsManager(this.task, this.company, this.user)) && [3,4,98,99].includes(this.mustChangeStatus.id)) {
                  return this.chatService.detectChats(this.company.id, this.work.id, [1,2,5,6]).pipe(
                    map(x => x.headers.get('x-pagination-total-count')),
                    switchMap(rslt => {
                      if (rslt == 0) {
                        let questionAlert =  this.bottomSheet.open(BackJobToPrevStatusComponent, {
                          hasBackdrop: true,
                          backdropClass: 'bottom-sheed-backdrop',
                          data: {
                            msg: marker(`Do you really want to convert job to status: '${this.getOperationStatusName(this.mustChangeStatus.id)}'?`)
                          }
                        });
                    
                        return questionAlert.afterDismissed()
                        .pipe(
                          switchMap(data => {
                            if (data && data.message == 'no') {
                              console.log("no");
                              return of(val)
                            } else if (data && data.message == 'yes') {
                              return this.taskService.editWork(this.work.id, {status_id: this.mustChangeStatus.id}, this.company.id).pipe(
                                map(() => val)
                              )
                            } else {
                              return of(val)
                            }
                          })
                        )
                      } else {
                        return of(val)
                      }
                    })
                  )
                } else {
                  return of(val)
                }
              })          
            ).pipe(
              switchMap(resp => {
                return this.chatService.postChatRead(this.chat_id, 0, this.company.id, '', 0, 0).pipe(
                  tap(x => {
                    if (!this.chat.discussionPostDraft) {
                      this.chat.discussionPostDraft = {}
                    }
                    this.chat.discussionPostDraft.text = '';
                    this.chat.discussionPostDraft.reply_to_post_id = 0;
                    this.chat.discussionPostDraft.update_post_id = 0;
                    this.closeEdit();
                    console.log("mustChangeStatus", x)
                    this.updateDiscussion(this.chat_id)
                    this.refreshService.refreshChat$.next({company_id: this.company.id, chat_id: this.chat_id})
                    this.initRefreshBoard()
                  })
                )
              })
            )
          } else {
            return this.chatService.postChatRead(this.chat_id, 0, this.company.id, '', 0, 0).pipe(
              tap(x => {
                if (!this.chat.discussionPostDraft) {
                  this.chat.discussionPostDraft = {}
                }
                this.chat.discussionPostDraft.text = '';
                this.chat.discussionPostDraft.reply_to_post_id = 0;
                this.chat.discussionPostDraft.update_post_id = 0;
                this.closeEdit();
                this.updateDiscussion(this.chat_id)
                this.refreshService.refreshChat$.next({company_id: this.company.id, chat_id: this.chat_id})
                this.initRefreshBoard()
              })
            )
          }
        })
      )
    }
    
  }

  openSameFiles(file, post) {
    console.log("openSameFiles", file, post)
    this.uploadService.sameFilesFile$.next(file)
  }

  tryAgain(file) {
    console.log("tryAgain", file)
    this.uploadService.tryAgainFile$.next(file)
  }

  download(e, file) {
    if (e) {
      e.stopPropagation();
    }
    // window.open(this.host + file.original, '_blank');

    // new method
    var a = document.createElement('a');
    a.setAttribute('href', this.host + file.original + '?company_id=' + this.company.id + `&filename=${file.filename}`);
    a.setAttribute('download','download');
    // a.target = "_blank"
    a.click();
    // a.remove();
  }

  getGroupsCompany() {
    this.attachSubscriptions(
      this.companyService.getInfiniteGroupsCompany(this.company.id, '1', '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.companyService.getInfiniteGroupsCompany(this.company.id, x).pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        this.groups = [].concat(...res);
      })
    )
  }

  getGroup(id) {
    if (!this.groups || !this.groups.find(el => el.id == id)) {
      return false;
    }
    return this.groups.find(el => el.id == id)
  }  

  uploadFile(event) {
    if (this.uploadLimit) {
      this.layoutService.showSnackBar({name: ''}, marker("This company has exceeded the file upload limit."), SnackBarItem)
      return
    }
    if (event.target.files.length > 0) {
      let files =  makeArray(event.target.files)
      if (files.length) {
        const dialogRef = this.dialog.open(FilesUploadAskComponent, {
          data: {
            company: this.company,
            streamData: this.chat,
            user: this.user,
            files: files,
            text: this.post_edited ? this.edited.value.text : this.form.value.text
          }
        });

        dialogRef.afterClosed().subscribe(result => {
          if (!!result && result.event == "send") {
            console.log("afterClosed", result.data)
            this.form.patchValue({
              text: ''
            })

            // this.closeReply()
            this.putFile(result.data).pipe(
              tap(el => console.log("CONCAT TAP", el))
            ).subscribe(res => {
              console.log(res);
            })
          }
        })
      }
    } 
  }

  copyMsg(post) {
    if (this.postCollection.isSelected(post) && this.postCollection.selected.length > 1) {
      console.log("setAsChecklist post", post)
      let arr = []
      this.postCollection.selected.forEach(el => {
        arr.push({post_id: el.id, company_id: this.company.id})
      });
      this.sm.localStorageSetItem('copiedMsgs', arr)
      this.layoutService.showSnackBar({name: "Massages"}, marker("Copied"), SnackBarItem)
    } else { 
      this.sm.localStorageSetItem('copiedMsgs', [{post_id: post.id, company_id: this.company.id}])
      this.layoutService.showSnackBar({name: "Massage"}, marker("Copied"), SnackBarItem)
    }
  }

  copyMsgContent(post) {
    // console.log(document.getElementById(`post_msg_${post.id}`))

    // let innerHTML = document.getElementById(`post_msg_${post.id}`).innerHTML
    // console.log(innerHTML)
    // this._clipboardService.copyFromContent(innerHTML)
    const from = document.getElementById(`post_msg_${post.id}`);
    const range = document.createRange();
    window.getSelection().removeAllRanges();
    range.selectNode(from);
    window.getSelection().addRange(range);
    document.execCommand('copy');
    // console.log(document.execCommand())
    window.getSelection().removeAllRanges();
    this.layoutService.showSnackBar({name: "Massage text"}, marker("Copied"), SnackBarItem)
  }

  mouseUp(e?) {
    let selection = document.getSelection();
    let range = selection.getRangeAt(0);
    
    let clientRects = range.getClientRects();

    let posArr = [];
    Object.keys(clientRects).forEach(key => {
      posArr.push({x: clientRects[key].x + clientRects[key].width/2 , y: clientRects[key].y - clientRects[key].height/2})
    });

    let pos = {
      x: Math.round(posArr.map(n => n.x).reduce((a, b) => +a + +b, 0) / posArr.length),
      y: Math.round(posArr.map(n => n.y).reduce((a, b) => +a + +b, 0) / posArr.length),
    }


    this.selectedText = {
      is_selected: selection.toString().trim().length > 0,
      position: pos,
      state: {
        bold: document.queryCommandState('bold'),
        italic: document.queryCommandState('italic'),
        underline: document.queryCommandState('underline'),
        strikeThrough: document.queryCommandState('strikeThrough'),
        insertOrderedList: document.queryCommandState('insertOrderedList'),
        insertUnorderedList: document.queryCommandState('insertUnorderedList'),
        createLink: this.isLink(),
        link: this.isLink() ? this.getLink() : "",
        linkNode: this.isLink() ? this.getLinkNode() : null,
        code: this.isCode()
      }
    }
  }

  isCode() {
    const selection = window.getSelection();
    if (selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        let commonAncestor = range.commonAncestorContainer;

        if (commonAncestor.nodeType === Node.TEXT_NODE) {
            commonAncestor = commonAncestor.parentNode as Element;
        }

        return (commonAncestor as Element).closest('code') !== null ? (commonAncestor as Element).closest('code') : null;
    }
    return null;
  }

  formatText(key) {
    if (key == 'createLink') {

      const from = this.selectedText.state.linkNode;
      console.log(this.selectedText);
      console.log("!!from", from);
      if (!!from) {
        let sel = window.getSelection();
        const range = document.createRange();
        var linkURL = prompt('Enter a URL:', this.selectedText.state.link ? this.selectedText.state.link : '');
        // document.getSelection().removeAllRanges();
        from.childNodes.forEach(element => {
          console.log("childNodes", element)
          // range.selectNode(element);
        });
        sel.removeAllRanges();
        range.selectNodeContents(from)
        
        sel.addRange(range);
        var sText = sel.toString();
        
        console.log('sText', sText);
        console.log('linkURL', linkURL);
        if (linkURL !== null && linkURL != '') {
          document.execCommand('insertHTML', false, '<a href="' + linkURL + '" target="_blank">' + sText + '</a>');
        } else {
          // let sel2 = window.getSelection();
          // let range2 = document.createRange();
          // sel2.removeAllRanges();
          // range2.selectNodeContents(from)
          // sel2.addRange(range2);
          // // sel2.removeAllRanges();
          document.execCommand('unlink', false, null);
          sel.removeAllRanges();
        }
        console.log("!!from", from);
      } else {
        var linkURL = prompt('Enter a URL:', '');
        var sText = document.getSelection().toString();
        if (linkURL !== null && linkURL != '') {
          document.execCommand('insertHTML', false, '<a href="' + linkURL + '" target="_blank">' + sText + '</a>');
        }
      }
      this.mouseUp()
    } else if (key === 'code') {
      const from = this.selectedText.state.linkNode;
      console.log("!!from", from);
      if (!!from) {
        this.unwrapSelectedTextFromCodeTag(from);
      } else {
          this.wrapSelectedTextWithCodeTag();
      }
      this.mouseUp()
    } else {
      document.execCommand(key, false, null);
      this.mouseUp()
    }
  }

  unwrapSelectedTextFromCodeTag(codeNode) {
    const selection = window.getSelection();
    if (selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        const codeElement = codeNode.closest('code');
        
        if (codeElement) {
            while (codeElement.firstChild) {
                codeElement.parentNode.insertBefore(codeElement.firstChild, codeElement);
            }
            codeElement.parentNode.removeChild(codeElement);
        }
    }
  }

  wrapSelectedTextWithCodeTag() {
    const selection = window.getSelection();
    if (selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        const codeElement = document.createElement('code');
        range.surroundContents(codeElement);
    }
  }

  cancelUpload(file) {
    this.uploadService.cancelFile$.next({
      id: file.id,
      is_paused: true
    })
  }

  getLink() {
    const selection = document.getSelection()
    const startA = selection.anchorNode.parentElement.getAttribute('href')
    const endA = selection.focusNode.parentElement.getAttribute('href')
    
    const altStartA = selection.anchorNode
    const altEndA = selection.anchorNode

    if (altStartA.nodeName === 'A' || altEndA.nodeName === 'A') {
      return altStartA['href'] || altEndA['href']
    }
    return startA || endA
  }

  getLinkNode() {
    const selection = document.getSelection()
    const startA = selection.anchorNode.parentElement
    const endA = selection.focusNode.parentElement
    const altStartA = selection.anchorNode
    const altEndA = selection.anchorNode

    if (altStartA.nodeName === 'A' || altEndA.nodeName === 'A') {
      return altStartA || altEndA
    }
    return startA || endA
  }

  isLink () {
    const selection = document.getSelection()
    const startA = selection.anchorNode.parentElement.tagName === 'A'
    const endA = selection.focusNode.parentElement.tagName === 'A'
    const altStartA = selection.anchorNode.nodeName === 'A'
    const altEndA = selection.anchorNode.nodeName === 'A'

    // console.log("isLink selection", selection);
    // console.log("isLink startA", selection.anchorNode.parentElement);
    // console.log("isLink endA", selection.focusNode.parentElement);
    return startA || endA || altStartA || altEndA
  }

  pasteHandler(e) {
    console.log("Chat component", e)
    // if (this.uploadLimit) {
    //   this.layoutService.showSnackBar({name: ''}, marker("This company has exceeded the file upload limit."), SnackBarItem)
    //   return
    // }
 
    if (["INPUT", "TEXTAREA"].includes(e.target.tagName) || !this.dialog.openDialogs[this.dialog.openDialogs.length - 1].componentInstance.data.dialogHasChat) {
      return
    }

    let items = e.clipboardData.items;
    let files = []
    if (items) {
      for (var i = 0; i < items.length; i++) {
        var blob = items[i].getAsFile();
        if (!!blob) {
          files.push(blob);
        }
      }
    }

    if (files.length) {
      e.preventDefault()
      const dialogRef = this.dialog.open(FilesUploadAskComponent, {
        data: {
          company: this.company,
          streamData: this.chat,
          user: this.user,
          files: files,
          text: this.post_edited ? this.edited.value.text : this.form.value.text
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (!!result && result.event == "send") {
          console.log("afterClosed", result.data)
          this.form.patchValue({
            text: ''
          })
          // this.closeReply()
          this.putFile(result.data).pipe(
            tap(el => console.log("CONCAT TAP", el))
          ).subscribe(res => {
            console.log(res);
          })
        }
      })
    } 
  }

  onCopy(event) {
    const selection = document.getSelection();
    console.log("onCopy", selection, event)
    if (selection) {
      const range = selection.getRangeAt(0);
      const clonedContent = range.cloneContents();
  
      const wrapperDiv = document.createElement('div');
      const span = document.createElement('span');
      span.classList.add('on_reports_copy')
      wrapperDiv.appendChild(clonedContent);
      wrapperDiv.appendChild(span);
  
      event.clipboardData?.setData('text/html', wrapperDiv.innerHTML);
      event.clipboardData?.setData('text/plain', selection?.toString().replace(/<br>|<BR>|<\/br>|<\/BR>/gi, '\n'));
      
      event.preventDefault();
    }
  }

  updateFields() {
    if (this.post_edited) {
      if (this.msg_edit) {
        this.edited.patchValue({
          text: this.msg_edit.nativeElement.innerHTML
        })
      } else if (this.mob_msg_edit) {
        this.edited.patchValue({
          text: this.mob_msg_edit.nativeElement.innerHTML
        })
      }
    } else {
      if (this.msg) {
        console.log("this.msg", this.msg)
        this.form.patchValue({
          text: this.msg.nativeElement.innerHTML
        })
      } else if (this.mob_msg) {
        this.form.patchValue({
          text: this.mob_msg.nativeElement.innerHTML
        })
      } 
    }
    console.log("this.form.value.text", this.form)
    console.log("this.form.value.text", this.form.value.text)
  }

  copyFile(file) {
    let copyData = [{
      company_id: file.company_id,
      id: file.id
    }];

    this.layoutService.showSnackBar({name: file.filename}, marker("copied!"), SnackBarItem)
    this.sm.localStorageSetItem('copiedFiles', copyData);
    this.copiedFiles = copyData
  }

  copy(post) {
    console.log(post)
    let copyData = []
    if (post.discussionPostFiles && post.discussionPostFiles.length) {
      post.discussionPostFiles.forEach(item => {
        copyData.push({
          company_id: item.company_id,
          id: item.file.id
        });
      })
    } else {
      copyData.push({
        company_id: post.company_id,
        id: post.file_id
      });
    }

    
    this.sm.localStorageSetItem('copiedFiles', copyData);
    this.copiedFiles = copyData
  }

  pasteMsg() {
    this.closeContextTemplate();
    this.copiedMsgs = JSON.parse(this.sm.localStorageGetItem("copiedMsgs"));

    if (!this.copiedMsgs) {
      this.layoutService.showSnackBar({name: ''}, marker("No copied msgs"), SnackBarItem)
      return
    }
    if (this.copiedMsgs.length == 1) {
      let pasteData:any = {
        discussion_id: this.chat_id,
        id: this.copiedMsgs[0].post_id
      }
  
      this.attachSubscriptions(
        this.chatService.copyPost(pasteData, this.company.id).subscribe(resp => {
          console.log(resp);
          this.layoutService.showSnackBar({name: ''}, marker("Msg copied successfully!"), SnackBarItem)
        })
      )
    } else if (this.copiedMsgs.length > 1) {
      this.attachSubscriptions(
        forkJoin(...this.copiedMsgs.map(k => this.chatService.copyPost({
          discussion_id: this.chat_id,
          id: k.post_id
        }, this.company.id))).subscribe(resp => {
          console.log(resp);
          this.layoutService.showSnackBar({name: ''}, marker("Msgs copied successfully!"), SnackBarItem)
        })
      )
    } else {
      this.layoutService.showSnackBar({name: ''}, marker("No copied msgs"), SnackBarItem)
    }

  }

  tryHigh(val) {
    setTimeout(() => {
      console.log("tryHigh val", val)
      hljs.highlightAll();
    }, 150)
  }

  paste(chat, clear:boolean = false) {
    this.closeContextTemplate();

    this.copiedFiles = JSON.parse(this.sm.localStorageGetItem("copiedFiles"));

    let pasteData:any = {
      discussion_id: this.chat_id,
      text: '',
      upload_files: []
    }

    this.copiedFiles.forEach((file, index) => {
      pasteData.upload_files.push({
        custom_upload_id: +index + 1,
        copy_file_id: file.id,
      })
    });

    this.attachSubscriptions(
      this.chatService.sendPost(pasteData, this.company.id).pipe(
        switchMap(res => {
          return this.chatService.getTargetPost(this.chat.id, res.id, this.company.id).pipe(
            tap(n => {
              if (this.chatScrollStrategy.value.id == 2 || (this.posts.length && this.chat && this.chat.lastPost && this.posts.find(x => x.id == (this.chat.lastPost.maxId || this.chat.lastPost.id)))) {
                this.chatService.postsData$.next([n[0]]);
                if (this.pagination && this.pagination.totalCount) {
                  this.pagination.totalCount = +this.pagination.totalCount + 1
                }
                this.posts.push(n[0])
                this.tryHigh('7')
                setTimeout(() => { this.oldScrollToBottom() }, 100)
              } else {
                this.newMsgCounter++;
                this.chat.lastPost.maxId = Math.max(this.posts[this.posts.length - 1].id, this.chat.lastPost.id, this.chat.lastPost.maxId || 0, n[0].id)
              }
              // n[0].upload_files = res.upload_files
            }),
            // map((n) => n[0])
          )
        }),
        catchError(err => {
          this.layoutService.showSnackBar({name: ''}, marker(err), SnackBarItem)
          return of(err)
        }),
      ).subscribe(resp => {
        this.closeReply();
        if (!!clear) {
          localStorage.removeItem('copiedFiles');
          this.copiedFiles = JSON.parse(this.sm.localStorageGetItem("copiedFiles"));
        }
        this.layoutService.showSnackBar({name: ''}, marker("File(s) copied successfully!"), SnackBarItem)
      })
    )
    console.log("this.copiedFiles", this.copiedFiles)
  }

  openNotes(item) {
    const dialogRef = this.dialog.open(NotesComponent, {
      data: {
        company: this.company,
        company_id: this.company.id,
        user: this.user,
        type: 'chat',
        item: item,
        is_mobile: this.is_mobile
      }
    });
    
    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log(result)
      })
    )
  }

  previewVideo(e, file, job?) {
    if (e.ctrlKey || e.metaKey) {
      return
    }

    if (!file) {
      return false;
    }
    if (!this.is_mobile) {
      const dialogRef = this.dialog.open(VideoViewerComponent, {
        panelClass: 'video_viewer',
        autoFocus: false,
        data: {
          file: file,
          chat_id: this.chat_id,
          task: this.task,
          work: file.task_operation_id ? job : undefined,
          tasks: this.tasks,
          operationsValues: this.operationsValues,
          company: this.company,
          activeLang: this.activeLang,
          user: this.user,
          initCompanyId: this.company.id,
        }
      });
    } else {
      const dialogRef = this.dialog.open(MobFmViewComponent, {
        backdropClass: ['mob_video_viewer_backdrop'],
        panelClass: 'mob_video_viewer',
        autoFocus: false,
        data: {
          file: file,
          task: this.task,
          chat_id: this.chat_id,
          work: file.task_operation_id ? job : undefined,
          tasks: this.tasks,
          initCompanyId: this.company.id,
          operationsValues: this.operationsValues,
          company: this.company,
          activeLang: this.activeLang,
          user: this.user,
        }
      });
    }
  }


  @HostListener('window:paste',['$event'])
  onKeyPress($event) {
    console.log(document.getElementsByTagName('app-mini-chat'))
    if (!this.dialog.openDialogs[this.dialog.openDialogs.length - 1].componentInstance.data.is_create_chat_component && document.getElementsByTagName('app-mini-chat').length == 0 && !this.dialog.openDialogs[this.dialog.openDialogs.length - 1].componentInstance.data.hasOwnProperty('streamData')) {
      this.pasteHandler($event)
    }
  }
  
  @HostListener('window:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (event.altKey && (event.ctrlKey || event.metaKey) && (event.code === 'KeyV' || event.keyCode === 86)) {
      // // Действия при нажатии Alt + Ctrl + V
      // if (navigator.userAgent.indexOf('Mac') !== -1) {
      //   // Действия на Mac
      //   console.log('Вы нажали Opt + Cmd + V');
      // } else {
      //   // Действия на Windows
      //   console.log('Вы нажали Alt + Ctrl + V');
      // }
      if (!this.dialog.openDialogs[this.dialog.openDialogs.length - 1].componentInstance.data.is_create_chat_component && document.getElementsByTagName('app-mini-chat').length == 0 && !this.dialog.openDialogs[this.dialog.openDialogs.length - 1].componentInstance.data.hasOwnProperty('streamData')) {
        this.paste(this.chat)
      }
    }
  }

  @HostListener("window:visibilitychange", ["$event"])
  onVisibilityChange($event) {
    this.isVisible = $event.target.visibilityState === 'visible'
    // console.log("&&&&", isVisible)
    if (this.isVisible) {
      if (this.sm.localStorageGetItem("copiedFiles")) {
        this.copiedFiles = JSON.parse(this.sm.localStorageGetItem("copiedFiles"))
      } else {
        this.copiedFiles = undefined;
      }
      
        // tab is visible
    } else {
        // tab is not-visible
    }
  }

  ngOnDestroy() {
    if (this.chatRead) {
      this.chatRead.unsubscribe();
    }
    this.companyService.closeAllUserHover$.next(true);
    this.companyService.closeAllOpened();
    
    if (!this.chat.discussionPostDraft) {
      this.chat.discussionPostDraft = {}
    }
    this.chat.discussionPostDraft.text = !!this.edited.value.text ? this.edited.value.text : this.form.value.text;
    this.chat.discussionPostDraft.reply_to_post_id = this.reply.id ? this.reply.id : 0;
    this.chat.discussionPostDraft.update_post_id = this.post_edited_id ? this.post_edited_id : 0;
    this.chatService.postChatRead(this.chat_id, (!!this.form.value.text || !!this.edited.value.text) ? 1 : 0, this.company.id, !!this.edited.value.text ? this.edited.value.text : this.form.value.text, this.reply.id ? this.reply.id : 0, this.post_edited_id ? this.post_edited_id : 0).subscribe(resp => {
      console.log("POST AFTER CLOSED", resp, this.chat, this.chat_id, (!!this.form.value.text || !!this.edited.value.text) ? 1 : 0, this.company.id, !!this.edited.value.text ? this.edited.value.text : this.form.value.text, this.reply.id ? this.reply.id : 0, this.post_edited_id ? this.post_edited_id : 0)
    })
    this.chatService.isInChat$.next(0)
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.postSub) {
      this.postSub.unsubscribe();
    }
    if (this.statusSub) {
      this.statusSub.unsubscribe();
    }
    if (this.filesSub) {
      this.filesSub.unsubscribe();
    }
    if (this.postsSub) {
      this.postsSub.unsubscribe();
    }
    if (this.checksSub) {
      this.checksSub.unsubscribe();
    }
    if (this.updateTaskSub) {
      this.updateTaskSub.unsubscribe();
    }
    if (this.taskSub) {
      this.taskSub.unsubscribe();
    }
    if (this.updateDiscussionSub) {
      this.updateDiscussionSub.unsubscribe();
    }
    if (this.updateChatSub) {
      this.updateChatSub.unsubscribe();
    }
    if (this.pinnedSub) {
      this.pinnedSub.unsubscribe();
    }
    if (this.searchSub) {
      this.searchSub.unsubscribe()
    }
    this.titleService.setTitle(this.prevTitle || 'Reports')
    this.abortAudioRecording();
    // this.chatService.unreadChats$.next("change");
    this.clearSubscriptions()
  }

}
