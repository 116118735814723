import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { fromEvent } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { AuthService } from 'src/app/shared/services/rest/auth.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { ServiceComponent } from './service/service.component';

@Component({
  selector: 'app-my-services',
  templateUrl: './my-services.component.html',
  styleUrls: ['./my-services.component.scss']
})
export class MyServicesComponent extends BaseClass implements OnInit, OnDestroy {
  public imgRoute: any;
  public activeLang: any;
  public user: any;
  public workspaceBalance: any;
  public user_id: any;
  public company: any;
  public nextPay: any;
  public is_mobile: boolean = false;
  public isLoad: boolean = false;
  public company_id: number = +this.activatedRoute.snapshot.queryParamMap.get('company_id');

  throttle = 100;
  scrollUpDistance = 3.5;
  
  constructor(
    private companyService: CompanyService,
    private languageService: LanguageService,
    private auth: AuthService,
    private dialog: MatDialog,
    private sm: StorageManagerService,
    private activatedRoute: ActivatedRoute,
  ) { 
    super()
   }

  ngOnInit(): void {
    this.getBalance();
    this.checkIsMobile();
    this.getCompany();
    this.getImgRoute();
    this.getLangData();
    this.getCsrf();
    this.getUser();
    this.getNextPay();
  }

  log() {
    console.log("this.workspaceBalance", this.workspaceBalance)
    console.log("this.company", this.company)
    console.log("this.nextPay", this.nextPay)
  }

  getBalance() {
    // api-dev.reports.re/api/balance-transaction/?company_id=15&is_applied=1&per-page=1
    this.attachSubscriptions(
      this.companyService.getWorkspaceTransactions(1, {
        company_id: this.company_id,
        is_applied: 1,
        'per-page': '1'
      }).pipe(
        map(res => res.body),
      ).subscribe(resp => {
        console.log("getBalance", resp)
        if (resp && resp.length) {
          this.workspaceBalance = resp[0]
        } else {
          this.workspaceBalance = undefined
        }
      })
    )
  }

  payNow() {
    this.attachSubscriptions(
      this.companyService.editWorkspaceTransaction(this.nextPay.id, {is_done: 1}).subscribe(resp => {
        console.log(resp);
        this.nextPay = undefined;
        this.getCompany();
        this.getNextPay();
        this.getBalance();
      })
    )
  }

  getNextPay() {
    // api-dev.reports.re/api/balance-transaction/?company_id=15&is_planned=1&is_type_live_stream=1
    this.attachSubscriptions(
      this.companyService.getWorkspaceTransactions(1, {
        company_id: this.company_id,
        is_planned: 1,
        is_type_live_stream: 1
      }).pipe(
        map(res => res.body),
      ).subscribe(resp => {
        console.log("getNextPay", resp)
        if (resp && resp.length) {
          this.nextPay = resp[0]
        } else {
          this.nextPay = undefined
        }
      })
    )
  }

  openService() {
    const dialogRef = this.dialog.open(ServiceComponent, {
      autoFocus: false,
      data: {
        user: this.user,
        imgRoute: this.imgRoute,
        is_mobile: this.is_mobile,
        company: this.company,
        nextPay: this.nextPay
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        this.getCompany();
        this.getNextPay();
        this.getBalance();
      })
    )
  }

  checkIsMobile() {
    if (window.innerWidth <= 769) {
      this.is_mobile = true;
    } else {
      this.is_mobile = false;
    }
    this.onResize();
  }

  onResize() {
    this.attachSubscriptions(
      fromEvent(window, "resize").pipe(
        map(() => window.innerWidth)
      ).subscribe((wWidth) => {
          if (wWidth <= 769) {
            this.is_mobile = true;
          } else {
            this.is_mobile = false;
          }
        }
      )
    )
  }
  
  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData().subscribe(resp => {
          console.log("getOperations", resp)
          this.activeLang = resp.active;
      })
    )
  }

  getCompany() {
    this.attachSubscriptions(
      this.companyService.getCompany(this.company_id).subscribe(resp => {
        this.companyService.company$.next(resp[0]);
        this.company = resp[0];
      })
    )
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  getCsrf() {
    this.attachSubscriptions(
      this.auth.$userToken.subscribe(resp => {
        this.user_id = resp.user_id;
        this.sm.localStorageSetItem("csrf_param", resp.csrf_param)
        this.sm.localStorageSetItem("csrf_token", resp.csrf_token)
      })
    )
  }

  getUser() {
    this.attachSubscriptions(
      this.auth.$user.subscribe(resp => {
        this.user = resp;
      })
    )
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

}
