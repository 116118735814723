import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { forkJoin, of } from 'rxjs';
import { catchError, last, map, switchMap, tap } from 'rxjs/operators';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { FileService } from 'src/app/shared/services/rest/file.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { environment } from 'src/environments/environment';
import { SnackBarItem } from '../../../snack-bar/snack-bar-item';
import { OpenTaskComponent } from 'src/app/components/workspace-pages/cases/dialogs/open-task/open-task.component';
import { ChatService } from 'src/app/shared/services/rest/chat.service';
import { RefreshService } from 'src/app/shared/services/rest/refresh.service';

@Component({
  selector: 'app-same-files',
  templateUrl: './same-files.component.html',
  styleUrls: ['./same-files.component.scss']
})
export class SameFilesComponent extends BaseClass implements OnInit, OnDestroy {
  public host: any = environment.host;
  public imgRoute: any = ''
  public operations: any;
  public groups: any;
  public origin = window.location.origin;
  public activeLang: any;

  public folders = [
    {
      name: "Sources",
      id: 0,
      location: "/sources",
      over: false,
      rootDir: true
    },
    {
      name: "To Approve",
      id: 1,
      location: "/to_approve",
      over: false,
      rootDir: true
    },
    {
      name: "Ready Files",
      id: 2,
      location: "/ready_files",
      over: false,
      rootDir: true
    },
    {
      name: "Approved",
      id: 3,
      location: "/approved",
      over: false,
      rootDir: true
    },
    {
      name: "Project",
      id: 4,
      location: "/project",
      over: false,
      rootDir: true
    },
    {
      name: "Trash",
      id: 5,
      location: "/trash",
      over: false,
      rootDir: true
    }
  ]

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<SameFilesComponent>,
    private sm: StorageManagerService,
    private taskService: TaskService,
    private dialog: MatDialog,
    private chatService: ChatService,
    private companyService: CompanyService,
    private refreshService: RefreshService,
    private layoutService: LayoutService,
    private fileService: FileService,
    private languageService: LanguageService
  ) { super() }

  ngOnInit(): void {
    console.log("SameFilesComponent", this.data)
    this.data.file.sameFiles.forEach(element => {
      element.fileLocationString = element.location
      console.log(element.fileLocationString)
      if (!!element.fileLocationString) {
        if (element.location == '/') {
          element.fileLocationString = "Root"
        } else {
          this.folders.forEach(el => {
            element.fileLocationString = (element.fileLocationString as any).replaceAll(el.location, el.name)
            console.log(element.fileLocationString);
          })  
          element.fileLocationString = (element.fileLocationString as any).replaceAll('/', " / ")
        }
      }
    });
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => this.imgRoute = el)
    )
    this.attachSubscriptions(
      this.languageService.getLangData().subscribe(resp => {
        this.activeLang = resp.active;
        // this._adapter.setLocale(resp.active);
        this.getOperations();
      })
    )
    this.getGroups()
  }

  getOperations() {
    this.attachSubscriptions(
      this.taskService.getOperations(this.data.file.company_id, this.activeLang).subscribe(resp => {
        console.log("getOperations", resp)
        this.operations = resp;
      })
    )
  }

  getPathForLink(val) {
    return val.replaceAll('//', '%2F').replaceAll('/', '%2F').replaceAll(' ', '%20')
  }

  copyLink(type) {
    this.layoutService.showSnackBar({name: marker(type + " link")}, marker("Copied"), SnackBarItem)
  }

  goToHref(e, sFile) {
    e.preventDefault();
    e.stopPropagation();
    window.open(this.origin + '/file/' + sFile?.acm + '?path=' + this.getPathForLink(sFile?.location), '_blank')
  }

  selectFile(same) {
    console.log(same)
    console.log(this.data);
    if (this.data.file.place == 'chat') {
      if (this.data.file.post && !!this.data.file.post.text && this.data.file.post.discussionPostFiles.find(u => u.file_id == this.data.file.target.id)) {
        this.attachSubscriptions(
          this.chatService.editPost(this.data.file.post.id, {
            upload_files: [
              {
                copy_file_id : same.id,
                custom_upload_id : this.data.file.post.discussionPostFiles.find(u => u.file_id == this.data.file.target.id).file.custom_upload_id
              },
            ]
          }, this.data.company_id).pipe(
            switchMap(q => {
              return this.chatService.deletePostFile(this.data.file.post.discussionPostFiles.find(u => u.file_id == this.data.file.target.id).id, this.data.company_id)
            }),
            tap(r => {
              this.refreshService.refreshChatPost$.next({company_id: this.data.company_id, chat_id: this.data.file.chat.id, post_id: this.data.file.post.id})
            })
          ).subscribe(resp => {
            this.dialogRef.close({event: "chat_close"})
          })
        )
      } else {
        this.attachSubscriptions(
          this.fileService.copyFile(Object.assign({
            location: '/',
            task_id: this.data.file.chat.task_id,
            task_operation_id: this.data.file.chat.task_operation_id,
            discussion_id: this.data.file.chat.id,
          }, {id: same.id, company_id: this.data.file.company.id, text: this.data.file.target.text}), this.data.company_id).pipe(
            catchError(err => {
              this.layoutService.showSnackBar({name: ''}, marker(err), SnackBarItem)
              return of(err)
            }),
          ).subscribe(resp => {
            console.log(resp);
            this.delete();
            // this.data.file.target.file = same;
            // if (same.user) {
            //   this.data.file.target.user = same.user;
            // }
            // this.dialogRef.close({event: "paste"})
          })
        )
      }
    } else if (this.data.file.place == 'file_manager') {
      this.attachSubscriptions(
        this.fileService.copyFile(Object.assign({
          location: this.data.file.folder && this.data.file.folder.is_folder ? this.data.file.folder.path.replaceAll("//", '/') : this.data.file.location,
          task_id: this.data.file.task_id,
          task_operation_id: this.data.file.work_id || this.data.file.task_operation_id,
          discussion_id: 0,
        }, {id: same.id, company_id: this.data.file.company.id}), this.data.company_id).pipe(
          catchError(err => {
            this.layoutService.showSnackBar({name: ''}, marker(err), SnackBarItem)
            return of(err)
          }),
        ).subscribe(resp => {
          // console.log(resp);
          // this.delete();
          // this.data.file.target.file = same;
          if (same.user) {
            resp.user = same.user;
          }
          this.dialogRef.close({event: "paste", newFile: resp})
        })
      )
    }
  }

  openTask(task) {
    let taskData:any = {
      task_id: task.id
    }
    if (task.hasOwnProperty("operations")) {
      taskData.task = task
    }

    const dialogRef = this.dialog.open(OpenTaskComponent, {
      backdropClass: 'backdrop_under_header',
      panelClass: !this.data.is_mobile ? ['open_task_dialog', 'show_header'] : 'open_task_dialog',
      autoFocus: false,
      data: taskData
    });
  }

  onImgError(event){
    event.target.src = this.imgRoute+'/assets/img/image_black_48dp.svg'
  }

  understandFileType(val) {
    if (!val) {
      return ""
    }
    
    if (val == 'application/pdf') {
      return 'pdf'
    } else if (val.indexOf("/") != -1) {
      return val.split('/')[0]
    } else {
      return ""
    }
  }

  getGroups() {
    this.attachSubscriptions(
      this.companyService.getInfiniteGroupsCompany(this.data.file.company_id, '1', '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.companyService.getInfiniteGroupsCompany(this.data.file.company_id, x).pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        this.groups = [].concat(...res);
      })
    )
  }

  getGroup(id) {
    if (!this.groups || !this.groups.find(el => el.id == id)) {
      return false;
    }
    return this.groups.find(el => el.id == id)
  }  
  
  getColumnName(id) {
    if (!this.operations || !this.operations.find(el => el.id == id)) {
      return '';
    }
    return this.operations.find(el => el.id == id) && this.operations.find(el => el.id == id).translation ? this.operations.find(el => el.id == id).translation.name : this.operations.find(el => el.id == id).name
  }

  close() {
    this.dialogRef.close({event: "close"})
  }

  upload() {
    this.dialogRef.close({event: "upload"})
  }

  delete() {
    this.dialogRef.close({event: "delete"})
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
