import {
    Directive,
    Output,
    EventEmitter,
    HostListener
  } from '@angular/core';
  
  @Directive({
    selector: '[dirEnter]'
  })
  export class EnterSubmitDirective {
    @Output() dirSubmit = new EventEmitter();

    @HostListener('window:keyup.Enter', ['$event'])
    onEnter(event: KeyboardEvent) {
        this.dirSubmit.emit();
    }
  }
  