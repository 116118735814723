import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { Moment } from 'moment';
import { forkJoin, fromEvent, of, ReplaySubject, Subscription } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, last, map, switchMap, tap } from 'rxjs/operators';
import { casesModel } from 'src/app/shared/functions/casesModel';
import { makeArray } from 'src/app/shared/functions/objToArray';
import { BaseClass } from 'src/app/shared/models/base-class';
import { GlobalDataService } from 'src/app/shared/services/common/global-data.service';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { AuthService } from 'src/app/shared/services/rest/auth.service';
import { ChatService } from 'src/app/shared/services/rest/chat.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { StatementService } from 'src/app/shared/services/rest/statement.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { OpenJobComponent } from '../../workspace-pages/cases/dialogs/open-job/open-job.component';
import { ChatsComponent } from '../../chats/chats.component';
import { StatementComponent } from './statements/dialogs/statement/statement.component';
import { environment } from 'src/environments/environment';
import { OpenTaskComponent } from '../../workspace-pages/cases/dialogs/open-task/open-task.component';

export const MY_FORMATS = {
  parse: {
    dateInput: 'MMM YYYY',
  },
  display: {
    dateInput: 'MMM YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};



@Component({
  selector: 'app-finances',
  templateUrl: './finances.component.html',
  styleUrls: ['./finances.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
})

export class FinancesComponent extends BaseClass implements OnInit, OnDestroy, AfterViewInit {
  public host: any = environment.host;
  public imgRoute: any = '';
  public user: any = {};
  public user_id: number;
  public currMonthName: any;  
  public company_id: any;
  public activeLang: any;
  public currencyes: any;
  public groups: any;
  public operations: any;
  public filterCount: number = 1;
  public page: number = 1;
  public pagination: object = {};
  public recordPage: number = 1;
  public recordPagination: object = {};
  public payoutPage: number = 1;
  public payoutPagination: object = {};
  public statementsPagination: object = {};
  public timeZone = new Date().getTimezoneOffset();
  public is_mobile: boolean = false;
  public statements: any[] = [];
  public statementsPage: number = 1;
  public employees: any;
  public sortValue: string = 'updated_desc';
  public isLoad: boolean = false;
  public statementsSub: Subscription;
  public me: any;
  public curYear = (new Date()).getFullYear();
  public statementsDataSub: Subscription;
  public company: any;
  public operationsValues: any;
  public statuses: any;
  public employees$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public execControl: FormControl = new FormControl();

  public allTransactions: any;
  public totalAllTransactions: any[] = [{
    amount: 0,
    currency_id: 'rate',
    currency: {
      short_name: "pts"
    }
  }]
  public pendingEarnings: any;
  public totalPendingEarnings: any[] = [{
    amount: 0,
    currency_id: 'rate',
    currency: {
      short_name: "pts"
    }
  }]

  public openSummary:any;
  

  public today = moment().set({hour:0,minute:0,second:0}).unix();
  public filter: FormGroup = this.fb.group({
    count: 1,
    tab: '',
    employee_id: ''
  });

  constructor(
    private sm: StorageManagerService,
    private auth: AuthService,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private statementService: StatementService,
    private taskService: TaskService,
    private companyService: CompanyService,
    private languageService: LanguageService,
    private globalDataService: GlobalDataService,
    private chatService: ChatService,
    private dialog: MatDialog,
    private router: Router,
    private _adapter: DateAdapter<any>,
  ) { super() }

  ngOnInit(): void {
    this.company_id = this.activatedRoute.snapshot.queryParamMap.get('company_id');
    if (!this.activatedRoute.snapshot.queryParamMap.get('tab')) {
      this.changeTab('current_activity')
    }
    this.getEmployees();
    this.checkIsMobile()
    this.getLangData(this.company_id);
    this.getImgRoute();
    this.getGroupsCompany();
    this.getUser();
    this.onTabChange();
    this.attachSubscriptions(
      this.execControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchEmoloyees(resp))
    )
  }

  openTask(task) {
    let taskData:any = {
      task_id: task.id,
      initCompanyId: this.company.id
    }
    if (task.hasOwnProperty("operations")) {
      taskData.task = task
    }
    const dialogRef = this.dialog.open(OpenTaskComponent, {
      backdropClass: 'backdrop_under_header',
      panelClass: !this.is_mobile ? ['open_task_dialog', 'show_header'] : 'open_task_dialog',
      autoFocus: false,
      data: taskData
    });
  }

  getGroupById(id) {
    if (!this.groups || this.groups.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.groups.find(el => el.id == id)
  }

  log() {
    console.log("allTransactions", this.allTransactions)
    console.log("statements", this.statements)
  }

  neededData(task) {
    let arr = [
      this.chatService.getTasksChats(this.company.id, [task.id]).pipe(
        tap(res => {
          task['operations'].map(o_el => {
            o_el['chatsInfo'] = [];
            o_el['chatsInfo'].push(...res.filter(z => z.task_operation_id == o_el.id));
            o_el['unReadChats'] = res.filter(z => z.task_operation_id == o_el.id && z.is_read != '1').length;
            o_el['unClosedChats'] = res.filter(z => z.task_operation_id == o_el.id && z.status_id != '4').length;
          });
          task['chatsInfo'] = [];
          task['chatsInfo'].push(...res.filter(z => z.task_id == task.id));
          task['unReadChats'] = res.filter(z => z.is_read != '1').length;
          task['unClosedChats'] = res.filter(z => z.status_id != '4').length;
        })
      )
    ]
    if (task.company_id == this.company.id) {
      arr.push(
        this.taskService.getTaskClients(task.id, this.company.id).pipe(
          tap(res => {
            task.clients = res
          }),
          catchError(err => {
            return err
          })
        )
      )
    }
    return forkJoin(arr)
  }


  onSearchEmoloyees(resp) {
    if (!this.employees) {
      return;
    }

    if (!resp) {
      this.employees$.next(this.employees.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.employees$.next(
      this.employees.filter(z => z.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  getOpenSummary() {
    this.attachSubscriptions(
      this.statementService.getOpenSummary(this.company_id, {employee_id: this.filter.value.employee_id}).subscribe(resp => {
        console.log("getOpenSummary", resp)
        resp.summaries = makeArray(resp.summaries);
        this.openSummary = resp;

        this.getStatementPending();
        this.getAllTransactionsFinances(this.openSummary.last_statement)
      })
    )
  }

  getCurrencyList() {
    this.attachSubscriptions(
      this.globalDataService.getCurrencies().subscribe(resp => {
        console.log("getCurrencyList", resp)
        this.currencyes = resp.slice();
      })
    )
  }

  mod(v) {
    return Math.abs(v)
  }
  
  getCurrency(id) {
    if (!!this.currencyes && this.currencyes.find(el => el.id == id)) {
      return this.currencyes.find(el => el.id == id)
    } else {
      return ''
    }
  }

  onTabChange() {
    this.attachSubscriptions(
      this.activatedRoute.queryParams.pipe(
        switchMap((val) => {
          return this.companyService.getCompany(this.activatedRoute.snapshot.queryParamMap.get('company_id')).pipe(
            map(x => x[0]),
            tap(x => {
              this.company = x;
              console.log(this.company);
              this.me = this.company.employees[0];
              this.companyService.company$.next(x);
              // if (!this.company.permissions.includes('owner')) {
              // }
            }),
            map(x => val)
          )
        }),
        tap(val => {
          if (!this.filter.value.employee_id || !this.activatedRoute.snapshot.queryParamMap.get('operation_employee_id')) {
            this.filter.patchValue({
              employee_id: this.me.id,
              tab: !!this.activatedRoute.snapshot.queryParamMap.get('tab') ? this.activatedRoute.snapshot.queryParamMap.get('tab') : ''
            })
          } else {
            this.filter.patchValue({
              employee_id: +this.activatedRoute.snapshot.queryParamMap.get('operation_employee_id'),
              tab: !!this.activatedRoute.snapshot.queryParamMap.get('tab') ? this.activatedRoute.snapshot.queryParamMap.get('tab') : ''
            })
          }
        })
      ).subscribe(params => {
        console.log("Tab CHANGE STREAM", params, this.filter.value, this.activatedRoute.snapshot.queryParamMap.get('operation_employee_id'), this.me);
        this.getCurrencyList();
        this.totalAllTransactions = [{
          amount: 0,
          currency_id: 'rate',
          currency: {
            short_name: "pts"
          }
        }]
        this.totalPendingEarnings = [{
          amount: 0,
          currency_id: 'rate',
          currency: {
            short_name: "pts"
          }
        }]

        
        switch (params.tab) {
          case 'current_activity':
            // if (this.company.permissions.includes('owner')) {
            //   this.changeTab("statements");
            // } else {
            // }
            this.statementsPage = 1;
            this.getOpenSummary();
            if (this.statementsSub) {
              this.statementsSub.unsubscribe()
            }
            this.getStatements(this.statementsPage);
            
            break;
          case 'statements':
            
            break;
          case 'payouts':
            
            break;
        }

        // this.page = 1;
      
        // this.getStatements(this.page);
      })
    )
  }

  selectionChange(e) {
    console.log(e);
    this.filter.patchValue({
      employee_id: e.value
    })
    this.filterStatements()
  }

  // getAllTransactionsFinances(currentStatement) {
  //   let arr = [
  //     // this.company.permissions.includes('owner') ? '' :
  //     this.statementService.getStatementRecordFinances(this.recordPage, this.company_id, {employee_id: this.filter.value.employee_id, created_at_from: currentStatement.time_end+1}).pipe(
  //       tap(el => {
  //         this.recordPagination = {
  //           'pageCount': el.headers.get('x-pagination-page-count'),
  //           'perPage': el.headers.get('x-pagination-per-page'),
  //           'totalCount': el.headers.get('x-pagination-total-count'),
  //           'currentPage': el.headers.get('x-pagination-current-page'),
  //         }
  //       }),
  //       map(el => el.body),
  //     ),
  //     // this.company.permissions.includes('owner') ? '' :
  //     this.statementService.getPaymentsFinances(this.payoutPage, this.company_id, {employee_id: this.filter.value.employee_id, paid_at_from: currentStatement.time_end+1}).pipe(
  //       tap(el => {
  //         this.payoutPagination = {
  //           'pageCount': el.headers.get('x-pagination-page-count'),
  //           'perPage': el.headers.get('x-pagination-per-page'),
  //           'totalCount': el.headers.get('x-pagination-total-count'),
  //           'currentPage': el.headers.get('x-pagination-current-page'),
  //         }
  //       }),
  //       map(el => el.body),
  //       tap(arr => {
  //         arr.forEach(x => {
  //           x.type = "Payout"
  //         })
  //       })
  //     ),

  //   ];

  //   this.attachSubscriptions(
  //     forkJoin(arr).subscribe(res => {
  //       console.log("getAllTransactionsFinances", res);
        
  //       let resp = [...res[0].slice(), ... res[1].slice()];

  //       resp.sort((a,b) => {
  //         return +b.created_at - +a.created_at;
  //       })

  //       this.allTransactions = resp;

  //       this.allTransactions.forEach(p => {
  //         if (!!p.task) {
  //           if (+p.rate != 0) {
  //             this.totalAllTransactions[0].amount += +p.rate
  //           }
  //           if (this.totalAllTransactions.filter(x => x.currency_id == p.price_currency_id).length == 0) {
  //             this.totalAllTransactions.push({
  //               amount: +p.price,
  //               currency_id: p.price_currency_id,
  //               currency: p.currency
  //             })
  //           } else {
  //             this.totalAllTransactions.find(x => x.currency_id == p.price_currency_id).amount += +p.price
  //           }
  //         }
  //       })
  //       console.log("this.totalAllTransactions",this.totalAllTransactions);
  //       console.log("this.allTransactions",this.allTransactions);
  //     })
  //   )
  // }

  
  getAllTransactionsFinances(currentStatement) {
    this.attachSubscriptions(
      forkJoin([
        this.statementService.getStatementRecordFinances('1', this.company_id, {employee_id: this.filter.value.employee_id, created_at_from: currentStatement.time_end+1}, '1').pipe(
          switchMap(el => {
            let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
            let arr = []
            for (let index = 1; index <= pages; index++) {
              arr.push(index)
            }
            console.log("Pages 1", pages)
            if (pages == 0) {
              return of([])
            } else {
              return forkJoin(arr.map(x => this.statementService.getStatementRecordFinances(x, this.company_id, {employee_id: this.filter.value.employee_id, created_at_from: currentStatement.time_end+1}, '200').pipe(map(u => u.body)))).pipe(
                last(),
                map(values => {
                  return [].concat(...values)
                })
              )
            }
          })
        ),
        this.statementService.getPaymentsFinances('1', this.company_id, {employee_id: this.filter.value.employee_id, paid_at_from: currentStatement.time_end+1}, '1').pipe(
          switchMap(el => {
            let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
            let arr = []
            for (let index = 1; index <= pages; index++) {
              arr.push(index)
            }
            console.log("Pages 2", pages)
            if (pages == 0) {
              return of([])
            } else {              
              return forkJoin(arr.map(x => this.statementService.getPaymentsFinances(x, this.company_id, {employee_id: this.filter.value.employee_id, paid_at_from: currentStatement.time_end+1}, '200')
              .pipe(
                map(u => u.body),
                tap(arr => {
                  arr.forEach(x => {
                    x.type = "Payout"
                  })
                })
              )))
              .pipe(
                last(),
                map(values => {
                  return [].concat(...values)
                })
              )
            }
          })
        )
      ])
      .subscribe(res => {
        console.log("getAllTransactionsFinances", res);
        
        let resp = [...res[0].slice(), ... res[1].slice()];

        resp.sort((a,b) => {
          return +b.created_at - +a.created_at;
        })

        this.allTransactions = resp;

        this.allTransactions.forEach(p => {
          if (!!p.task) {
            if (+p.rate != 0) {
              this.totalAllTransactions[0].amount += +p.rate
            }
            if (this.totalAllTransactions.filter(x => x.currency_id == p.price_currency_id).length == 0) {
              this.totalAllTransactions.push({
                amount: +p.price,
                currency_id: p.price_currency_id,
                currency: p.currency
              })
            } else {
              this.totalAllTransactions.find(x => x.currency_id == p.price_currency_id).amount += +p.price
            }
          }
        })
        console.log("this.totalAllTransactions",this.totalAllTransactions);
        console.log("this.allTransactions",this.allTransactions);
      })
    )
  }

  getTargetAllTransactionsFinances(statement, ind) {
    this.attachSubscriptions(
      forkJoin([
        this.statementService.getStatementRecordFinances('1', this.company_id, {employee_id: this.filter.value.employee_id, created_at_from: statement.time_start, created_at_to: statement.time_end}, '1').pipe(
          switchMap(el => {
            let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
            let arr = []
            for (let index = 1; index <= pages; index++) {
              arr.push(index)
            }
            console.log("Pages 1", pages)
            if (pages == 0) {
              return of([])
            } else {
              return forkJoin(arr.map(x => this.statementService.getStatementRecordFinances(x, this.company_id, {employee_id: this.filter.value.employee_id, created_at_from: statement.time_start, created_at_to: statement.time_end}, '200').pipe(map(u => u.body)))).pipe(
                last(),
                map(values => {
                  return [].concat(...values)
                })
              )
            }
          })
        ),
        this.statementService.getPaymentsFinances('1', this.company_id, {employee_id: this.filter.value.employee_id, paid_at_from: statement.time_start, paid_at_to: statement.time_end}, '1').pipe(
          switchMap(el => {
            let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
            let arr = []
            for (let index = 1; index <= pages; index++) {
              arr.push(index)
            }
            console.log("Pages 2", pages)
            if (pages == 0) {
              return of([])
            } else {              
              return forkJoin(arr.map(x => this.statementService.getPaymentsFinances(x, this.company_id, {employee_id: this.filter.value.employee_id, paid_at_from: statement.time_start, paid_at_to: statement.time_end}, '200')
              .pipe(
                map(u => u.body),
                tap(arr => {
                  arr.forEach(x => {
                    x.type = "Payout"
                  })
                })
              )))
              .pipe(
                last(),
                map(values => {
                  return [].concat(...values)
                })
              )
            }
          })
        )
      ])
      .subscribe(res => {
        console.log("getTargetAllTransactionsFinances2", res);
        
        statement.status = 'show'
        if (ind < this.statements.length - 1) {
          this.statements[ind + 1].status = 'suggest'
        }
        let resp = [...res[0].slice(), ... res[1].slice()];

        resp.sort((a,b) => {
          return +b.created_at - +a.created_at;
        })

        statement.earnings = resp;

        statement.earnings.forEach(p => {
          if (!!p.task) {
            if (+p.rate != 0) {
              statement.totalAllTransactions[0].amount += +p.rate
            }
            if (statement.totalAllTransactions.filter(x => x.currency_id == p.price_currency_id).length == 0) {
              statement.totalAllTransactions.push({
                amount: +p.price,
                currency_id: p.price_currency_id,
                currency: p.currency
              })
            } else {
              statement.totalAllTransactions.find(x => x.currency_id == p.price_currency_id).amount += +p.price
            }
          }
        })
        console.log("statement.totalAllTransactions",statement.totalAllTransactions);
        console.log("statement.earnings",statement.earnings);
      })
    )
  }

  // getTargetAllTransactionsFinances(statement, ind) {
  //   let arr = [
  //     // this.company.permissions.includes('owner') ? '' :
  //     this.statementService.getStatementRecordFinances(this.recordPage, this.company_id, {employee_id: this.filter.value.employee_id, created_at_from: statement.time_start, created_at_to: statement.time_end}).pipe(
  //       tap(el => {
  //         this.recordPagination = {
  //           'pageCount': el.headers.get('x-pagination-page-count'),
  //           'perPage': el.headers.get('x-pagination-per-page'),
  //           'totalCount': el.headers.get('x-pagination-total-count'),
  //           'currentPage': el.headers.get('x-pagination-current-page'),
  //         }
  //       }),
  //       map(el => el.body),
  //     ),
  //     // this.company.permissions.includes('owner') ? '' :
  //     this.statementService.getPaymentsFinances(this.payoutPage, this.company_id, {employee_id: this.filter.value.employee_id, paid_at_from: statement.time_start, paid_at_to: statement.time_end}).pipe(
  //       tap(el => {
  //         this.payoutPagination = {
  //           'pageCount': el.headers.get('x-pagination-page-count'),
  //           'perPage': el.headers.get('x-pagination-per-page'),
  //           'totalCount': el.headers.get('x-pagination-total-count'),
  //           'currentPage': el.headers.get('x-pagination-current-page'),
  //         }
  //       }),
  //       map(el => el.body),
  //       tap(arr => {
  //         arr.forEach(x => {
  //           x.type = "Payout"
  //         })
  //       })
  //     ),

  //   ];

  //   this.attachSubscriptions(
  //     forkJoin(arr).subscribe(res => {
  //       console.log("getAllTransactionsFinances", res);
        
  //       statement.status = 'show'
  //       if (ind < this.statements.length - 1) {
  //         this.statements[ind + 1].status = 'suggest'
  //       }
  //       let resp = [...res[0].slice(), ... res[1].slice()];

  //       resp.sort((a,b) => {
  //         return +b.created_at - +a.created_at;
  //       })

  //       statement.earnings = resp;

  //       statement.earnings.forEach(p => {
  //         if (!!p.task) {
  //           if (+p.rate != 0) {
  //             statement.totalAllTransactions[0].amount += +p.rate
  //           }
  //           if (statement.totalAllTransactions.filter(x => x.currency_id == p.price_currency_id).length == 0) {
  //             statement.totalAllTransactions.push({
  //               amount: +p.price,
  //               currency_id: p.price_currency_id,
  //               currency: p.currency
  //             })
  //           } else {
  //             statement.totalAllTransactions.find(x => x.currency_id == p.price_currency_id).amount += +p.price
  //           }
  //         }
  //       })
  //       console.log("statement.totalAllTransactions",statement.totalAllTransactions);
  //       console.log("statement.earnings",statement.earnings);
  //     })
  //   )
  // }

  getStatementPending() {
    console.log("1", this.company_id, this.filter.value.employee_id, this.openSummary.last_statement.time_end+1, '1');
    let count;
    this.attachSubscriptions(
      this.statementService.getFinancesPending("1", this.company_id, this.filter.value.employee_id, this.openSummary.last_statement.time_end+1, '1')
      .pipe(
        tap(el => {
          console.log(el);
          count = Math.ceil(el.headers.get('x-pagination-total-count')/200)
        }),
        switchMap(() => {
          let arr = [];
          for (let index = 1; index <= count; index++) {
            arr.push(this.statementService.getFinancesPending(index, this.company_id, this.filter.value.employee_id, this.openSummary.last_statement.time_end+1, '200').pipe(map(b => b.body)))
          }
          return forkJoin(arr)
        }),
        map((x:any) => {
          return x.flat().sort((a,b) => {
            return +b.created_at - +a.created_at;
          });
        })
      ).subscribe((resp: any) => {
        console.log("getStatementPending", resp)
        let pendingData = [];
        for (let index = 0; index < resp.length; index++) {
          const el = resp[index];

          if (pendingData.filter(x => x.employee_id == el.employee_id && x.discussion_id == el.discussion_id && x.task_id == el.task_id && x.task_operation_id == el.task_operation_id).length == 0) {
            pendingData.push(el)
          } else {
            if (el.id > pendingData.find(x => x.employee_id == el.employee_id && x.discussion_id == el.discussion_id && x.task_id == el.task_id && x.task_operation_id == el.task_operation_id).id) {
              pendingData.splice(pendingData.indexOf(pendingData.find(x => x.employee_id == el.employee_id && x.discussion_id == el.discussion_id && x.task_id == el.task_id && x.task_operation_id == el.task_operation_id)), 1, el)
            }
          }
          
        }

        this.pendingEarnings = pendingData.filter(x => x.type != 'return');
        this.pendingEarnings.forEach(p => {
          if (+p.rate != 0) {
            this.totalPendingEarnings[0].amount += +p.rate
          }
          if (this.totalPendingEarnings.filter(x => x.currency_id == p.price_currency_id).length == 0) {
            this.totalPendingEarnings.push({
              amount: +p.price,
              currency_id: p.price_currency_id,
              currency: this.currencyes.find(x => x.id == p.price_currency_id)
            })
          } else {
            this.totalPendingEarnings.find(x => x.currency_id == p.price_currency_id).amount += +p.price
          }
        })
        console.log("this.pendingEarnings",this.pendingEarnings);
      })
    )
  }

  openChat(id) {
    this.attachSubscriptions(
      this.chatService.getChatsExpand(1, this.company.id, {id: id}).pipe(
        map(res => res.body[0])
      ).subscribe(chat => {
        const goToChat = this.dialog.open(ChatsComponent, {
          backdropClass: ['backdrop_under_header', "without_pad"],
          disableClose: true,
          hasBackdrop: !this.is_mobile ? false : true,
          panelClass: !this.is_mobile ? ['open_task_dialog', 'show_header'] : 'open_task_dialog',
          position: !this.is_mobile ? { top: '58px', bottom: '58px' } : {},
          data: {
            company: this.company,
            user: this.user,
            imgRoute: this.imgRoute,
            host: this.host,
            dialogType: 'global_chat',
            activeLang: this.activeLang, 
            chat: chat
          }
        });
      })
    )
  }

  openJob(task_id, task_operation_id) {
    this.attachSubscriptions(
      this.taskService.getOneTaskExpand(this.company.id, task_id).pipe(
        map(x => x.body[0]),
        switchMap(x => this.neededData(x).pipe(map(() => casesModel([x], [], 'update')),map(x => x.arr[0]))),
      ).subscribe(resp => {
        const dialogRef = this.dialog.open(OpenJobComponent, {
          disableClose: true,
          hasBackdrop: !this.is_mobile ? false : true,
          panelClass: !this.is_mobile ? ['open_task_dialog', 'show_header'] : 'open_task_dialog',
          position: !this.is_mobile ? { top: '58px', bottom: '58px' } : {},
          autoFocus: false,
          data: {
            task_id: task_id,
            task_operation_id: task_operation_id,
            task: resp,
          }
        });
      })
    )
  }

  checkIfHasSumm(arr, key) {
    if (key == "rate") {
      return !!arr ? arr.filter(x => x.amount != 0 && x.currency_id == 'rate').length > 0 : false;
    } else {
      return !!arr ? arr.filter(x => x[key] != 0).length > 0 : false;
    }
  }


  getStatements(page) {
    console.log("FILTER", this.filter.value)
    this.isLoad = true;
    this.statementsSub = this.statementService.getStatements(page, this.company_id, {employee_id: this.filter.value.employee_id}, '200').pipe(
      tap(el => {
        this.statementsPagination = {
          'pageCount': el.headers.get('x-pagination-page-count'),
          'perPage': el.headers.get('x-pagination-per-page'),
          'totalCount': el.headers.get('x-pagination-total-count'),
          'currentPage': el.headers.get('x-pagination-current-page'),
        }
        if (this.statementsPagination['currentPage'] == '1') {
          this.statements = [];
        }
      }),
      map(el => el.body),
      tap(arr => {
        arr.forEach(element => {
          element.status = 'hide';
          element.totalAllTransactions = [{
            amount: 0,
            currency_id: 'rate',
            currency: {
              short_name: "pts"
            }
          }]
        });
        arr[0].status = 'suggest'
      }),
      distinctUntilChanged()
    ).subscribe(resp => {
      this.statements.push(...resp);
      console.log("this.statements page:" + this.statementsPage, this.statements)

      this.statementsPage = this.statementsPage + 1;
      this.isLoad = false;

      if (+this.statementsPagination['pageCount'] != 1 && this.statementsPage <= this.statementsPagination['pageCount']) {
        this.getStatements(this.statementsPage);
      }


    },
    error => {
      this.isLoad = false;
    })
    
  }

  getEmployee(id) {
    if (!this.employees) {
      return false;
    }
    return this.employees.find(el => el.id == id)
  }  

  // getEmployees() {
  //   this.attachSubscriptions(
  //     this.taskService.getEmployees(this.company_id).subscribe(resp => {
  //       this.employees = resp;
  //       this.employees$.next(this.employees.slice());
  //     })
  //   )
  // }

  getEmployees() {
    this.attachSubscriptions(
      this.taskService.getEmployeesDyn('1', this.company_id, null, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }
  
          return forkJoin(arr.map(x => this.taskService.getEmployeesDyn(x, this.company_id, null, '200').pipe(map(u => u.body)))).pipe(
            last(),
            tap(values => {
              let conValues = [].concat(...values)
              this.employees = conValues;
              this.employees$.next(this.employees.slice())
            }),
          )
        }),
      ).subscribe(resp => {
        console.log("getEmployees resp", resp);
        console.log("getEmployees", this.employees);
      })
    )
  }

  ngAfterViewInit(): void {
    // this.onScroll();
  }

  // onScroll() {
  //   this.attachSubscriptions(
  //     fromEvent(this.statementsContainer.nativeElement, "scroll").pipe(
  //       filter((e:any) => e.target.scrollTop >=  e.target.scrollHeight - e.target.offsetHeight - 400),
  //       debounceTime(300)
  //     ).subscribe(() => {
  //       if (this.page > 3 && this.page <= this.pagination['pageCount']) {
  //         this.getStatements(this.page);
  //       }
  //     })
  //   )
  // }


  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    var endDate = normalizedMonth.clone().endOf('month');
    this.filter.patchValue({
      time_start_utc_from: normalizedMonth,
      time_start_utc_to: endDate
    })
    console.log(this.filter.value)
    this.filterStatements();
    datepicker.close();
  }

  selectChange(e) {
    this.filterStatements();
  }

  removeValue(val, id = null) {
    if (val == "all") {
      this.filterReset()
      return
    } else {
      if (val == 'time_start_utc_from') {
        this.filter.patchValue({
          time_start_utc_from: '',
          time_start_utc_to: ''
        })
      } else {
        this.filter.patchValue({
          [val]: ''
        })
      }

      this.filterStatements()
    }
    
  }

  filterReset() {
    this.filter.patchValue({
      count: '',
      q: '',
      employee_id: "",
      time_start_utc_from : '',
      time_start_utc_to : '',
      order_by: 'time_start_utc_desc',
    })
    this.router.navigate(['/settings'], { queryParams: {page: 'finances', company_id: this.company_id}});
  }

  changeTab(tab) {
    this.filter.patchValue({
      tab: tab
    })

    this.filterFinances()
  }

  filterFinances() {
    this.filterCount = this.filterCount + 1;
    this.filter.value.count = this.filterCount;

    let filterData = this.filter.value;
    let params = {
      company_id: this.company_id,
      page: 'finances'
    };

    Object.keys(filterData).forEach(element => {
      if (filterData[element] != '0' && filterData[element] != '') {
        params[element] = filterData[element]
      }
    });

    this.router.navigate(['/settings'], { queryParams: params});

  }

  filterStatements(order_by = null, is_btn = false, counting: boolean = true) {
    if (this.statementsSub) {
      this.statementsSub.unsubscribe()
    }

    if (counting) {
      this.filterCount = this.filterCount + 1;
      this.filter.value.count = this.filterCount;
    }
    
    let filterData = this.filter.value;

    if (!!filterData.time_start_utc_from) {
      filterData.time_start_utc_from = filterData.time_start_utc_from.unix();
    }
    if (!!filterData.time_start_utc_to) {
      filterData.time_start_utc_to = filterData.time_start_utc_to.unix();
    }


    if (!!order_by || order_by == "0") {
      filterData.order_by = order_by
    }

 
    let params = {
      company_id: this.company_id,
      page: 'finances'
    };

    Object.keys(filterData).forEach(element => {
      if (filterData[element] != '0' && filterData[element] != '') {
        params[element] = filterData[element]
      }
    });

    this.router.navigate(['/settings'], { queryParams: params});

    // if (this.is_mobile) {
    //   this.layoutService.overflowAuto();
    //   if (this.mobile_filter_wrp) {
    //     this.mobile_filter_wrp.nativeElement.classList.remove("active");
    //   }
    // }
  }

  // openStatement(statement) {
  //   const dialogRef = this.dialog.open(StatementComponent, {
  //     panelClass: 'statement_dialog',
  //     data: {
  //       statement: statement,
  //       company: this.company,
  //       user: this.user,
  //       imgRoute: this.imgRoute,
  //       host: this.host,
  //       activeLang: this.activeLang,
  //       is_mobile: this.is_mobile
  //     }
  //   });
  // }

  getOperations() {
    this.attachSubscriptions(
      this.taskService.getOperations(this.company_id, this.activeLang).subscribe(resp => {
        this.operations = resp
      })
    )
  }
  
  getGroupsCompany() {
    this.attachSubscriptions(
      this.companyService.getInfiniteGroupsCompany(this.company_id, '1', '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.companyService.getInfiniteGroupsCompany(this.company_id, x).pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        this.groups = [].concat(...res);
      })
    )
  }


  getOperationName(id) {
    if (!this.operations || this.operations.filter(el => el.id == id).length == 0) {
      return '';
    }
    return this.operations.find(el => el.id == id) && this.operations.find(el => el.id == id).translation ? this.operations.find(el => el.id == id).translation.name : this.operations.find(el => el.id == id).name
  }

  getLangData(id) {
    this.attachSubscriptions(
      this.languageService.getLangData().pipe(
        tap(resp => {
          this.activeLang = resp.active;
          this._adapter.setLocale(resp.active);
          this.currMonthName = moment().format('MMMM');
        })
      ).subscribe(resp => {
        console.log("getOperations", resp)
        this.getOperations();
      })
    )
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  getUser() {
    this.attachSubscriptions(
      this.auth.$user.subscribe(resp => {
        this.user = resp;
      })
    )
  }

  checkIsMobile() {
    if (window.innerWidth <= 769) {
      this.is_mobile = true;
    } else {
      this.is_mobile = false;
    }
    this.onResize();
  }

  onResize() {
    this.attachSubscriptions(
      fromEvent(window, "resize").pipe(
        map(() => window.innerWidth)
      ).subscribe((wWidth) => {
          if (wWidth <= 769) {
            this.is_mobile = true;
          } else {
            this.is_mobile = false;
          }
        }
      )
    )
  }
  
  ngOnDestroy(): void {
    if (this.statementsSub) {
      this.statementsSub.unsubscribe();
    }
    this.clearSubscriptions();
  }

}
