import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { Moment } from 'moment';
import { forkJoin, fromEvent, of, ReplaySubject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, last, map, switchMap, tap } from 'rxjs/operators';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { AuthService } from 'src/app/shared/services/rest/auth.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { StatementService } from 'src/app/shared/services/rest/statement.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { environment } from 'src/environments/environment';

export const MY_FORMATS = {
  parse: {
    dateInput: 'MMM YYYY',
  },
  display: {
    dateInput: 'MMM YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-dubbing-usage',
  templateUrl: './dubbing-usage.component.html',
  styleUrls: ['./dubbing-usage.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
})

export class DubbingUsageComponent extends BaseClass implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild("usagesContainer") usagesContainer: ElementRef;
  public host: any = environment.host;
  public imgRoute: any = '';
  public user: any = {};
  public user_id: number;
  public currMonthName: any;  
  public updateDate: any;  
  public company_id: any;
  public activeLang: any;
  public currencyes: any;
  public page: number = 1;
  public filterCount: number = 1;
  public pagination: object = {};
  public timeZone = new Date().getTimezoneOffset();
  public is_mobile: boolean = false;
  public usages: any[] = [];
  public employees: any;
  public sortValue: string = 'start_time_desc';
  public isLoad: boolean = false;
  public usageSub: Subscription;
  public me: any;
  public curYear = (new Date()).getFullYear();
  public statementsDataSub: Subscription;
  public company: any;
  public operationsValues: any;
  public statuses: any;
  public minDate: any;
  public initCount: number = 1;
  public showAddOptions: FormControl = new FormControl(false);

  public employees$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public execControl: FormControl = new FormControl();
  
  public today = moment().set({hour:0,minute:0,second:0}).unix();
  public filter: FormGroup = this.fb.group({
    count: '',
    // q: '',
    // employee_id: "",
    start_time_from: moment().utc().startOf('month'),
    start_time_to: moment().utc().endOf('month'),
    order_by: 'start_time_desc',
  });

  @Input() withoutFilter:boolean = false;
  @Input() useOut:boolean = false;

  constructor(
    private sm: StorageManagerService,
    private auth: AuthService,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private statementService: StatementService,
    private taskService: TaskService,
    private companyService: CompanyService,
    private languageService: LanguageService,
    private dialog: MatDialog,
    private router: Router,
    private _adapter: DateAdapter<any>,
  ) { super() }

  ngOnInit(): void {
    this.company_id = this.activatedRoute.snapshot.queryParamMap.get('company_id');
    if (!!this.sm.localStorageGetItem('showAddOptions')) {
      this.showAddOptions.patchValue(true)
    }

    this.attachSubscriptions(
      this.showAddOptions.valueChanges.subscribe(res => {
        if (!!res) {
          this.sm.localStorageSetItem('showAddOptions', true)
        } else {
          localStorage.removeItem('showAddOptions')
        }
      })
    )
    this.checkIsMobile()
    this.getLangData(this.company_id);
    this.getImgRoute();
    this.getUser();
    this.onRouteChange();

    this.filter.patchValue({
      count: this.filterCount
    })
    this.attachSubscriptions(
      this.execControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchEmoloyees(resp))
    )
  }

  onSearchEmoloyees(resp) {
    if (!this.employees) {
      return;
    }

    if (!resp) {
      this.employees$.next(this.employees.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.employees$.next(
      this.employees.filter(z => z.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  onRouteChange() {
    this.attachSubscriptions(
      this.filter.valueChanges.pipe(
        tap((val) => {
          if (this.statementsDataSub) {
            this.statementsDataSub.unsubscribe();
          }
          this.company_id = this.activatedRoute.snapshot.queryParamMap.get('company_id');
          this.sortValue = !!this.activatedRoute.snapshot.queryParamMap.get('order_by') ? this.activatedRoute.snapshot.queryParamMap.get('order_by') : "start_time_desc";
          // this.filter.patchValue({
          //   count: this.filterCount,
          //   employee_id: !!this.activatedRoute.snapshot.queryParamMap.get('employee_id') ? this.activatedRoute.snapshot.queryParamMap.get('employee_id') : "",
          //   q: !!this.activatedRoute.snapshot.queryParamMap.get('q') ? this.activatedRoute.snapshot.queryParamMap.get('q') : "",
          //   start_time_from: !!this.activatedRoute.snapshot.queryParamMap.get('start_time_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('start_time_from')*1000).utc() : "",
          //   start_time_to: this.activatedRoute.snapshot.queryParamMap.get('start_time_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('start_time_to')*1000).utc() : "",
          //   order_by: !!this.activatedRoute.snapshot.queryParamMap.get('order_by') ? this.activatedRoute.snapshot.queryParamMap.get('order_by') : "time_start_utc_desc",
          // })
        }),
        switchMap((val) => {
          if (this.company == undefined || this.company.id != this.activatedRoute.snapshot.queryParamMap.get('company_id')) {
            return this.companyService.getCompany(this.activatedRoute.snapshot.queryParamMap.get('company_id')).pipe(
              map(x => x[0]),
              tap(x => {
                this.company = x;
                this.me = this.company.employees[0];
                this.companyService.company$.next(x);
                if (!this.company.permissions.includes('owner') && !this.company.permissions.includes('admin')) {
                  this.minDate = moment(1654041600000)
                }
              }),
              map(x => val)
            )
          } else {
            return of(val)
          }
        }),
        debounceTime(300)
      ).subscribe(params => {
        console.log("route STREAM", params);

        this.usages = [];
        this.page = 1;
      
        this.getCompanyUsage(this.page);
      })
    )
  }

  log() {
    console.log("usages", this.usages)
  }

  getCompanyUsage(page) {
    console.log("FILTER", this.filter.value)
    this.isLoad = true;
    let filterData = {...this.filter.value}

    if (!!filterData.start_time_from) {
      filterData.start_time_from = filterData.start_time_from.unix();
    } else {
      if (!this.company.permissions.includes('owner') && !this.company.permissions.includes('admin')) {
        filterData.start_time_from = "empl"
      }
    }

    if (!!filterData.start_time_to) {
      filterData.start_time_to = filterData.start_time_to.unix();
    }

    this.usageSub = this.companyService.getCompanyUsage(page, this.company_id, filterData, '200').pipe(
      tap(el => {
        this.pagination = {
          'pageCount': el.headers.get('x-pagination-page-count'),
          'perPage': el.headers.get('x-pagination-per-page'),
          'totalCount': el.headers.get('x-pagination-total-count'),
          'currentPage': el.headers.get('x-pagination-current-page'),
        }
        if (this.pagination['currentPage'] == '1') {
          this.usages = [];
        }
      }),
      map(el => el.body),
      // tap(x => {
      //   x.forEach(val => {
      //     val.time_start = moment(val.time_start*1000).tz(this.company.timezone).unix()
      //     val.time_end = moment(val.time_end*1000).tz(this.company.timezone).unix()
      //   })
      // }),
      distinctUntilChanged()
    ).subscribe(resp => {
      this.usages.push(...resp);

      if (this.initCount == 1 && this.usages[0]) {
        this.updateDate = this.usages[0].updated_at;
      }

      console.log("this.usages "+this.page, this.usages)

      this.page = this.page + 1;
      this.isLoad = false;
      if (+this.pagination['currentPage'] == 1) {
        this.usagesContainer.nativeElement.scrollTop = 0;
      }
      this.initCount++

      if (this.page <= 3 && +this.pagination['pageCount'] != 1 && this.page <= this.pagination['pageCount']) {
        this.getCompanyUsage(this.page);
      }

    },
    error => {
      this.isLoad = false;
    })
    
  }

  ngAfterViewInit(): void {
    this.onScroll();
  }

  onScroll() {
    this.attachSubscriptions(
      fromEvent(this.usagesContainer.nativeElement, "scroll").pipe(
        filter((e:any) => e.target.scrollTop >=  e.target.scrollHeight - e.target.offsetHeight - 400),
        debounceTime(300)
      ).subscribe(() => {
        if (this.page > 3 && this.page <= this.pagination['pageCount']) {
          this.getCompanyUsage(this.page);
        }
      })
    )
  }

  chosenYearHandler(normalizedYear: Moment) {
    // const ctrlValue = this.filter.value;
    // ctrlValue.year(normalizedYear.year());
    // this.filter.setValue(ctrlValue);
  }
  

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    var endDate = normalizedMonth.clone().endOf('month');
    this.filter.patchValue({
      start_time_from: normalizedMonth,
      start_time_to: endDate
    })
    console.log(this.filter.value)
    this.filterStatements();
    datepicker.close();
  }

  selectChange(e) {
    this.filterStatements();
  }

  removeValue(val, id = null) {
    if (val == "all") {
      this.filterReset()
      return
    } else {
      if (val == 'start_time_from') {
        this.filter.patchValue({
          start_time_from: '',
          start_time_to: ''
        })
      } else {
        this.filter.patchValue({
          [val]: ''
        })
      }

      this.filterStatements()
    }
    
  }

  filterReset() {
    this.filter.patchValue({
      count: '',
      q: '',
      employee_id: "",
      start_time_from : '',
      start_time_to : '',
      order_by: 'time_start_utc_desc',
    })
    // this.router.navigate(['/statements'], { queryParams: {company_id: this.company_id, order_by: "time_start_utc_desc"}});
  }

  filterStatements(order_by = null, is_btn = false, counting: boolean = true) {
    // if (this.statementsSub) {
    //   this.statementsSub.unsubscribe()
    // }

    // if (counting) {
    //   this.filterCount = this.filterCount + 1;
    //   this.filter.value.count = this.filterCount;
    // }
    
    // let this.filter.value = this.filter.value;

    // if (!!this.filter.value.start_time_from) {
    //   this.filter.value.start_time_from = this.filter.value.start_time_from.unix();
    // }
    // if (!!this.filter.value.start_time_to) {
    //   this.filter.value.start_time_to = this.filter.value.start_time_to.unix();
    // }


    // if (!!order_by || order_by == "0") {
    //   filterData.order_by = order_by
    // }

 
    // let params = {
    //   company_id: this.company_id
    // };

    // Object.keys(filterData).forEach(element => {
    //   if (filterData[element] != '0' && filterData[element] != '') {
    //     params[element] = filterData[element]
    //   }
    // });

    // this.router.navigate(['/statements'], { queryParams: params});

    // if (this.is_mobile) {
    //   this.layoutService.overflowAuto();
    //   if (this.mobile_filter_wrp) {
    //     this.mobile_filter_wrp.nativeElement.classList.remove("active");
    //   }
    // }
  }

  // openStatement(statement) {
  //   const dialogRef = this.dialog.open(StatementComponent, {
  //     panelClass: 'statement_dialog',
  //     data: {
  //       statement: statement,
  //       company: this.company,
  //       user: this.user,
  //       imgRoute: this.imgRoute,
  //       host: this.host,
  //       activeLang: this.activeLang,
  //       is_mobile: this.is_mobile
  //     }
  //   });
  // }

  getLangData(id) {
    this.attachSubscriptions(
      this.languageService.getLangData().pipe(
        tap(resp => {
          this.activeLang = resp.active;
          this._adapter.setLocale(resp.active);
          this.currMonthName = moment().format('MMMM');
        })
      ).subscribe(resp => {
        console.log("getOperations", resp)
      })
    )
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  getUser() {
    this.attachSubscriptions(
      this.auth.$user.subscribe(resp => {
        this.user = resp;
      })
    )
  }

  checkIsMobile() {
    if (window.innerWidth <= 769) {
      this.is_mobile = true;
    } else {
      this.is_mobile = false;
    }
    this.onResize();
  }

  onResize() {
    this.attachSubscriptions(
      fromEvent(window, "resize").pipe(
        map(() => window.innerWidth)
      ).subscribe((wWidth) => {
          if (wWidth <= 769) {
            this.is_mobile = true;
          } else {
            this.is_mobile = false;
          }
        }
      )
    )
  }
  
  ngOnDestroy(): void {
    if (this.usageSub) {
      this.usageSub.unsubscribe();
    }
    
    this.clearSubscriptions();
  }

}
