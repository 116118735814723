<h2 mat-dialog-title class="dialog_title">
    <span>
        {{ "Edditing selected urls status" | translate }}:
    </span>
    <button class="ml-1" mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h2>
<mat-dialog-content class="mat-typography" dirEnter (dirSubmit)="!form.valid ? null : onSubmit()">
    <div class="container form_wrapper">
        <form [formGroup]="form">

            <div class="custom_radio">
                <label>{{ "Choose a status" | translate }}: </label>
                <mat-radio-group required formControlName="content_status_id">
                  <mat-radio-button color="primary" *ngFor="let status of data.profileStatuses" [value]="status.id" 
                  [ngClass]="{
                    'first': status.id == '1',
                    'second': status.id == '2',
                    'third': status.id == '3',
                    'fours': status.id == '4',
                    'fives': status.id == '5',
                    'sixs': status.id == '6',
                    'nine': status.id == '97',
                    'seven': status.id == '98',
                    'eight': status.id == '99'}" [disabled]="status.is_user == 0">{{ status.name+'_profile' | translate }}</mat-radio-button>
                </mat-radio-group>
            </div>   
        </form>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end" style="margin-top: 5px;">
    <button mat-raised-button (click)="close()">
        {{ "Back" | translate }}
    </button>
    <button class="ml-1" (click)="onSubmit()" mat-raised-button cdkFocusInitial color="primary" [disabled]="!form.valid">
        {{ "Save" | translate }}
    </button>
</mat-dialog-actions>
