export const sections = [{
    name: "Cards", 
    types: ['', 'Filter']
  }, {
    name: "Card", 
    types: ['', 'Clipboard']
  },{
    name: "Jobs", 
    types: ['', 'Filter', 'Layout template']
  }, {
    name: "Chats", 
    types: ['', 'Filter']
  }, {
    name: "Posts", 
    types: ['', 'Filter']
  }, {
    name: "Post Dashboards", 
    types: ['', 'Filter']
  }, {
    name: "Outlet Dashboards", 
    types: ['', 'Filter']
  }, {
    name: "Board by Order", 
    types: ['', 'Filter']
  }, {
    name: "Board by Date", 
    types: ['', 'Filter']
  }, {
    name: "Projects", 
    types: ['', 'Filter', 'Layout template']
  }, {
    name: "Document", 
    types: ['', 'AI']
  }]

export const types = ['', 'Filter', 'Layout template', 'AI', 'Clipboard']