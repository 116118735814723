import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { DeleteAlertComponent } from 'src/app/shared/global_components/delete-alert/delete-alert.component';
import { BaseClass } from 'src/app/shared/models/base-class';
import { ScenariosService } from 'src/app/shared/services/rest/scenarios.service';
import { AddGroupToScenarioComponent } from '../add-group-to-scenario/add-group-to-scenario.component';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { last, map, switchMap } from 'rxjs/operators';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-scenario-to-group',
  templateUrl: './scenario-to-group.component.html',
  styleUrls: ['./scenario-to-group.component.scss']
})

export class ScenarioToGroupComponent extends BaseClass implements OnInit, OnDestroy {
  public groups;
  public groupsToScenarioList;
  public is_change: boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ScenarioToGroupComponent>,
    private dialog: MatDialog,
    private bottomSheet: MatBottomSheet,
    private companyService: CompanyService,
    private scenariosService: ScenariosService,
  ) { super() }

  ngOnInit(): void {
    console.log("ScenarioToGroupComponent", this.data);
    this.getGroupsCompany()

  }
  getGroupsCompany() {
    this.attachSubscriptions(
      this.companyService.getInfiniteGroupsCompany(this.data.company.id, '1', '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.companyService.getInfiniteGroupsCompany(this.data.company.id, x).pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        this.groups = [].concat(...res);
        this.getGroupsToScenario();
      })
    )
  }


  getGroupsToScenario() {
    let filter:any = {automation_template_id: this.data.tmpl.id};
    // if (this.data.discussion) {
    //   filter.discussion_id = this.data.discussion.id
    // }
    
    this.attachSubscriptions(
      this.scenariosService.getGroupsToScenario(filter, this.data.company.id).subscribe(resp => {
        console.log("getGroupsToScenario", resp);
        this.groupsToScenarioList = resp;
        if (resp.length == 0) {
          this.addGroup()
        }
      })
    )
  }

  getGroupById(id) {
    return this.groups.find(x => x.id == id)
  }
  
  addGroup() {
    const dialogRef = this.dialog.open(AddGroupToScenarioComponent, {
      disableClose: true,
      data: {
        user: this.data.user,
        company: this.data.company,
        groups: this.groups,
        tmpl: this.data.tmpl
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result && result.event == "add") {
          this.is_change = true
          this.getGroupsToScenario();
        }
      })
    )
  }

  deleteGroup(id, i) {
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: {},
        target: marker("group to scenario")
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        this.attachSubscriptions(
          this.scenariosService.deleteGroupsToScenario(id).subscribe(resp => {
            this.is_change = true;
            this.groupsToScenarioList.splice(i,1);
          })
        )
      }
    });
  }

  close() {
    this.dialogRef.close({event: this.is_change ? "change" : "close", data: false})
  }

  ngOnDestroy(): void {
    this.clearSubscriptions();
  }
}
