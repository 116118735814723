import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseClass } from 'src/app/shared/models/base-class';
import { QuestsService } from 'src/app/shared/services/rest/quests.service';

@Component({
  selector: 'app-add-field',
  templateUrl: './add-field.component.html',
  styleUrls: ['./add-field.component.scss']
})
export class AddFieldComponent extends BaseClass implements OnInit, OnDestroy {
  public types: any[] = [
    {
      id: 0,
      name: 'text',
    },
    {
      id: 1,
      name: 'file',
    },
    {
      id: 2,
      name: 'email',
    },
    {
      id: 3,
      name: 'tel',
    },
    {
      id: 4,
      name: 'date',
    },
    {
      id: 5,
      name: 'time',
    },
    {
      id: 6,
      name: 'url',
    },
    {
      id: 7,
      name: 'file',
    },
    {
      id: 8,
      name: 'number',
    }
  ];
  public form: FormGroup;
  public isSubmit: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddFieldComponent>,
    private fb: FormBuilder,
    private questsService: QuestsService
  ) { super() }

  ngOnInit(): void {

    console.log(this.data)

    this.form = this.fb.group({
      company_id: this.data.company_id,
      type: ['', Validators.required],
      name: ['', Validators.required],
      order: [0, Validators.required]
    })

    this.form.get('type').valueChanges.subscribe(resp => {
      if (resp == 'rules') {
        this.form.addControl('rules', this.fb.control(''))
      } else {
        if (this.form.get('rules')) {
          this.form.removeControl('rules')
        }
      }
      this.form.updateValueAndValidity()
    })

    if (this.data.quest) {
      this.form.addControl("application_id",this.fb.control(this.data.quest.id))
    } else {
      this.types.push({
        id: 9,
        name: 'rules',
      })
      this.form.addControl('employee_id', this.fb.control(this.data.employee_id));
    }
    this.form.updateValueAndValidity()
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  onSubmit() {
    this.isSubmit = true;
    if (this.data.quest) {
      this.attachSubscriptions(
        this.questsService.addField(this.form.value).subscribe(resp => {
          this.isSubmit = false
          this.data.quest.form.push(resp);
          this.dialogRef.close({event: "add", data: resp});
        })
      )
    } else {
      this.attachSubscriptions(
        this.questsService.addFieldToEmpl(this.form.value).subscribe(resp => {
          this.isSubmit = false
          this.dialogRef.close({event: "add", data: resp});
        })
      )
    }
  }

  ngOnDestroy() {
    this.clearSubscriptions()
  }
}
