import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { prioritys } from 'src/app/shared/consts/prioritys';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';

@Component({
  selector: 'app-mob-task-edit',
  templateUrl: './mob-task-edit.component.html',
  styleUrls: ['./mob-task-edit.component.scss']
})

export class MobTaskEditComponent extends BaseClass implements OnInit, OnDestroy {
  public prioritys = prioritys;
  public taskStatuses: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<MobTaskEditComponent>,
    private layoutService: LayoutService,
    private taskService: TaskService,
  ) { super() }

  ngOnInit(): void {
    console.log("MobTaskEditComponent", this.data)
    this.getTaskStatus();
  }

  close(type?) {
    this.dialogRef.removePanelClass('animate__slideInUp')
    this.dialogRef.addPanelClass('animate__slideOutDown')
    setTimeout(()=>{this.dialogRef.close({event: 'update', data: type})}, 300);
  }

  goTo(type?) {
    this.dialogRef.removePanelClass('animate__slideInUp')
    this.dialogRef.addPanelClass('animate__slideOutDown')
    setTimeout(()=>{this.dialogRef.close({event: type})}, 300);
  }

  getTaskStatus() {
    this.attachSubscriptions(
      this.taskService.getTaskStatuses(this.data.company_id).subscribe(resp => {
        this.taskStatuses = resp.slice()
      })
    )
  }
  
  getPriority(val) {
    return this.prioritys.find(x => x.id == val)
  }

  copyLink(type) {
    this.layoutService.showSnackBar({name: marker(type + " link")}, marker("Copied"), SnackBarItem)
  }

  getTaskStatusById(id) {
    if (!this.taskStatuses || this.taskStatuses.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.taskStatuses.find(el => el.id == id)
  }

  checkIsManager(task, company, _user) {
    return task && company && task.company_id == company.employees[0].company_id && (task?.managers.filter(x => x.user_id == _user.id).length > 0 || task?.group?.managers.filter(x => x.user_id == _user.id).length > 0 || company?.permissions.includes('admin') || company?.permissions.includes('manager')); 
  }

  editCustomId () {
    this.attachSubscriptions(
      this.taskService.editTask(this.data.task.id, {custom_id: !!this.data.task.custom_id ? this.data.task.custom_id : 0}, this.data.company_id).subscribe(resp => {
        // this.dialogRef.close({event: "update", data: resp})
        this.data.task.custom_id = resp.custom_id
        console.log(resp)
      })
    )
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
