<div mat-dialog-title class="mobile_interface_title">
    <div class="mobile_interface_title_in" style="overflow: hidden;">
        <div class="tab_title" (click)="close()">
            <div class="d-f-c">
                <div class="icon_back">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7 12L6.11612 11.1161C5.62796 11.6043 5.62796 12.3957 6.11612 12.8839L7 12ZM16.8839 3.88388C17.372 3.39573 17.372 2.60427 16.8839 2.11612C16.3957 1.62796 15.6043 1.62796 15.1161 2.11612L16.8839 3.88388ZM15.1161 21.8839C15.6043 22.372 16.3957 22.372 16.8839 21.8839C17.372 21.3957 17.372 20.6043 16.8839 20.1161L15.1161 21.8839ZM7.88388 12.8839L16.8839 3.88388L15.1161 2.11612L6.11612 11.1161L7.88388 12.8839ZM6.11612 12.8839L15.1161 21.8839L16.8839 20.1161L7.88388 11.1161L6.11612 12.8839Z" fill="#232323"/>
                    </svg>
                </div>
                <span>
                    {{ "Order by" | translate }}
                </span>
            </div>
        </div>
    </div>
</div>
<mat-dialog-content class="mobile_interface_content">
    <div class="mobile_scrollable_int_big" style="background: #fff;">
        <ng-container [ngSwitch]="activeSections()">
            <form [formGroup]="filter" class="exp_form" [appDeviceClass]="'custom-scroll'">
                <ng-container *ngSwitchCase="'Cards'">
                    <mat-selection-list [multiple]="false" formControlName="order_by">
                        <mat-list-option [value]="'updated_desc'" [ngClass]="{'active': sortValue == 'updated_desc'}">
                            <div class="d-f-c" style="justify-content: space-between;">
                                {{  "Sort by updated" | translate }}
                                <mat-icon style="margin-right: 0 !important;">arrow_drop_down</mat-icon>
                            </div>
                        </mat-list-option>
                        <mat-list-option [value]="'updated'" [ngClass]="{'active': sortValue == 'updated'}">
                            <div class="d-f-c" style="justify-content: space-between;">
                                {{ "Sort by updated" | translate }}
                                <mat-icon style="margin-right: 0 !important;">arrow_drop_up</mat-icon>
                            </div>
                        </mat-list-option>
                        <mat-list-option [value]="'priority_desc'" [ngClass]="{'active': sortValue == 'priority_desc'}">
                            <div class="d-f-c" style="justify-content: space-between;">
                                {{  "Sort by priority" | translate }}
                                <mat-icon style="margin-right: 0 !important;">arrow_drop_down</mat-icon>
                            </div>
                        </mat-list-option>
                        <mat-list-option [value]="'priority'" [ngClass]="{'active': sortValue == 'priority'}">
                            <div class="d-f-c" style="justify-content: space-between;">
                                {{ "Sort by priority" | translate }}
                                <mat-icon style="margin-right: 0 !important;">arrow_drop_up</mat-icon>
                            </div>
                        </mat-list-option>
                        <mat-list-option [value]="'custom_id_desc'" [ngClass]="{'active': sortValue == 'custom_id_desc'}">
                            <div class="d-f-c" style="justify-content: space-between;">
                                {{ "Sort by custom id" | translate }}
                                <mat-icon style="margin-right: 0 !important;">arrow_drop_down</mat-icon>
                            </div>
                        </mat-list-option>
                        <mat-list-option [value]="'custom_id'" [ngClass]="{'active': sortValue == 'custom_id'}">
                            <div class="d-f-c" style="justify-content: space-between;">
                                {{ "Sort by custom id" | translate }}
                                <mat-icon style="margin-right: 0 !important;">arrow_drop_up</mat-icon>
                            </div>
                        </mat-list-option>
                        <mat-list-option [value]="'internal_id_desc'" [ngClass]="{'active': sortValue == 'internal_id_desc'}">
                            <div class="d-f-c" style="justify-content: space-between;">
                                {{ "Sort by intenal id" | translate }}
                                <mat-icon style="margin-right: 0 !important;">arrow_drop_down</mat-icon>
                            </div>
                        </mat-list-option>
                        <mat-list-option [value]="'internal_id'" [ngClass]="{'active': sortValue == 'internal_id'}">
                            <div class="d-f-c" style="justify-content: space-between;">
                                {{ "Sort by intenal id" | translate }}
                                <mat-icon style="margin-right: 0 !important;">arrow_drop_up</mat-icon>
                            </div>
                        </mat-list-option>
                        <mat-list-option [value]="'id_desc'" [ngClass]="{'active': sortValue == 'id_desc'}">
                            <div class="d-f-c" style="justify-content: space-between;">
                                {{ "Sort by id" | translate }}
                                <mat-icon style="margin-right: 0 !important;">arrow_drop_down</mat-icon>
                            </div>
                        </mat-list-option>
                        <mat-list-option [value]="'id'" [ngClass]="{'active': sortValue == 'id'}">
                            <div class="d-f-c" style="justify-content: space-between;">
                                {{ "Sort by id" | translate }}
                                <mat-icon style="margin-right: 0 !important;">arrow_drop_up</mat-icon>
                            </div>
                        </mat-list-option>
                        <mat-list-option [value]="'filesize_desc'" [ngClass]="{'active': sortValue == 'filesize_desc'}">
                            <div class="d-f-c" style="justify-content: space-between;">
                                {{ "Sort by filesize" | translate }}
                                <mat-icon style="margin-right: 0 !important;">arrow_drop_down</mat-icon>
                            </div>
                        </mat-list-option>
                        <mat-list-option [value]="'filesize'" [ngClass]="{'active': sortValue == 'filesize'}">
                            <div class="d-f-c" style="justify-content: space-between;">
                                {{ "Sort by filesize" | translate }}
                                <mat-icon style="margin-right: 0 !important;">arrow_drop_up</mat-icon>
                            </div>
                        </mat-list-option>
                    </mat-selection-list>
                </ng-container>
                <ng-container *ngSwitchCase="'Jobs'">
                    <mat-selection-list [multiple]="false" formControlName="order_by">
                        <mat-list-option [value]="'updated_desc'" [ngClass]="{'active': sortValue == 'updated_desc'}">
                            <div class="d-f-c" style="justify-content: space-between;">
                                {{  "Sort by updated" | translate }}
                                <mat-icon style="margin-right: 0 !important;">arrow_drop_down</mat-icon>
                            </div>
                        </mat-list-option>
                        <mat-list-option [value]="'updated'" [ngClass]="{'active': sortValue == 'updated'}">
                            <div class="d-f-c" style="justify-content: space-between;">
                                {{ "Sort by updated" | translate }}
                                <mat-icon style="margin-right: 0 !important;">arrow_drop_up</mat-icon>
                            </div>
                        </mat-list-option>
                        <mat-list-option [value]="'priority_desc'" [ngClass]="{'active': sortValue == 'priority_desc'}">
                            <div class="d-f-c" style="justify-content: space-between;">
                                {{  "Sort by priority" | translate }}
                                <mat-icon style="margin-right: 0 !important;">arrow_drop_down</mat-icon>
                            </div>
                        </mat-list-option>
                        <mat-list-option [value]="'priority'" [ngClass]="{'active': sortValue == 'priority'}">
                            <div class="d-f-c" style="justify-content: space-between;">
                                {{ "Sort by priority" | translate }}
                                <mat-icon style="margin-right: 0 !important;">arrow_drop_up</mat-icon>
                            </div>
                        </mat-list-option>
                        <mat-list-option [value]="'id_desc'" [ngClass]="{'active': sortValue == 'id_desc'}">
                            <div class="d-f-c" style="justify-content: space-between;">
                                {{ "Sort by id" | translate }}
                                <mat-icon style="margin-right: 0 !important;">arrow_drop_down</mat-icon>
                            </div>
                        </mat-list-option>
                        <mat-list-option [value]="'id'" [ngClass]="{'active': sortValue == 'id'}">
                            <div class="d-f-c" style="justify-content: space-between;">
                                {{ "Sort by id" | translate }}
                                <mat-icon style="margin-right: 0 !important;">arrow_drop_up</mat-icon>
                            </div>
                        </mat-list-option>
                        <mat-list-option [value]="'filesize_desc'" [ngClass]="{'active': sortValue == 'filesize_desc'}">
                            <div class="d-f-c" style="justify-content: space-between;">
                                {{ "Sort by filesize" | translate }}
                                <mat-icon style="margin-right: 0 !important;">arrow_drop_down</mat-icon>
                            </div>
                        </mat-list-option>
                        <mat-list-option [value]="'filesize'" [ngClass]="{'active': sortValue == 'filesize'}">
                            <div class="d-f-c" style="justify-content: space-between;">
                                {{ "Sort by filesize" | translate }}
                                <mat-icon style="margin-right: 0 !important;">arrow_drop_up</mat-icon>
                            </div>
                        </mat-list-option>
                        <mat-list-option [value]="'planned_completed_desc'" [ngClass]="{'active': sortValue == 'planned_completed_desc'}">
                            <div class="d-f-c" style="justify-content: space-between;">
                                {{ "Sort by Deadline" | translate }}
                                <mat-icon style="margin-right: 0 !important;">arrow_drop_down</mat-icon>
                            </div>
                        </mat-list-option>
                        <mat-list-option [value]="'planned_completed'" [ngClass]="{'active': sortValue == 'planned_completed'}">
                            <div class="d-f-c" style="justify-content: space-between;">
                                {{ "Sort by Deadline" | translate }}
                                <mat-icon style="margin-right: 0 !important;">arrow_drop_up</mat-icon>
                            </div>
                        </mat-list-option>
                        <mat-list-option [value]="'completed_desc'" [ngClass]="{'active': sortValue == 'completed_desc'}">
                            <div class="d-f-c" style="justify-content: space-between;">
                                {{ "Sort by Completed" | translate }}
                                <mat-icon style="margin-right: 0 !important;">arrow_drop_down</mat-icon>
                            </div>
                        </mat-list-option>
                        <mat-list-option [value]="'completed'" [ngClass]="{'active': sortValue == 'completed'}">
                            <div class="d-f-c" style="justify-content: space-between;">
                                {{ "Sort by Completed" | translate }}
                                <mat-icon style="margin-right: 0 !important;">arrow_drop_up</mat-icon>
                            </div>
                        </mat-list-option>
                    </mat-selection-list>
                </ng-container>
                <ng-container *ngSwitchCase="'Chats'">
                    <mat-selection-list [multiple]="false" formControlName="order_by">
                        <mat-list-option [value]="'updated_desc'" [ngClass]="{'active': sortValue == 'updated_desc'}">
                            <div class="d-f-c" style="justify-content: space-between;">
                                {{  "Sort by updated" | translate }}
                                <mat-icon style="margin-right: 0 !important;">arrow_drop_down</mat-icon>
                            </div>
                        </mat-list-option>
                        <mat-list-option [value]="'updated'" [ngClass]="{'active': sortValue == 'updated'}">
                            <div class="d-f-c" style="justify-content: space-between;">
                                {{ "Sort by updated" | translate }}
                                <mat-icon style="margin-right: 0 !important;">arrow_drop_up</mat-icon>
                            </div>
                        </mat-list-option>
                        <mat-list-option [value]="'priority_desc'" [ngClass]="{'active': sortValue == 'priority_desc'}">
                            <div class="d-f-c" style="justify-content: space-between;">
                                {{  "Sort by priority" | translate }}
                                <mat-icon style="margin-right: 0 !important;">arrow_drop_down</mat-icon>
                            </div>
                        </mat-list-option>
                        <mat-list-option [value]="'priority'" [ngClass]="{'active': sortValue == 'priority'}">
                            <div class="d-f-c" style="justify-content: space-between;">
                                {{ "Sort by priority" | translate }}
                                <mat-icon style="margin-right: 0 !important;">arrow_drop_up</mat-icon>
                            </div>
                        </mat-list-option>
                        <mat-list-option [value]="'id_desc'" [ngClass]="{'active': sortValue == 'id_desc'}">
                            <div class="d-f-c" style="justify-content: space-between;">
                                {{ "Sort by id" | translate }}
                                <mat-icon style="margin-right: 0 !important;">arrow_drop_down</mat-icon>
                            </div>
                        </mat-list-option>
                        <mat-list-option [value]="'id'" [ngClass]="{'active': sortValue == 'id'}">
                            <div class="d-f-c" style="justify-content: space-between;">
                                {{ "Sort by id" | translate }}
                                <mat-icon style="margin-right: 0 !important;">arrow_drop_up</mat-icon>
                            </div>
                        </mat-list-option>
                        <mat-list-option [value]="'planned_completed_desc'" [ngClass]="{'active': sortValue == 'planned_completed_desc'}">
                            <div class="d-f-c" style="justify-content: space-between;">
                                {{ "Sort by Deadline" | translate }}
                                <mat-icon style="margin-right: 0 !important;">arrow_drop_down</mat-icon>
                            </div>
                        </mat-list-option>
                        <mat-list-option [value]="'planned_completed'" [ngClass]="{'active': sortValue == 'planned_completed'}">
                            <div class="d-f-c" style="justify-content: space-between;">
                                {{ "Sort by Deadline" | translate }}
                                <mat-icon style="margin-right: 0 !important;">arrow_drop_up</mat-icon>
                            </div>
                        </mat-list-option>
                        <mat-list-option [value]="'completed_desc'" [ngClass]="{'active': sortValue == 'completed_desc'}">
                            <div class="d-f-c" style="justify-content: space-between;">
                                {{ "Sort by Completed" | translate }}
                                <mat-icon style="margin-right: 0 !important;">arrow_drop_down</mat-icon>
                            </div>
                        </mat-list-option>
                        <mat-list-option [value]="'completed'" [ngClass]="{'active': sortValue == 'completed'}">
                            <div class="d-f-c" style="justify-content: space-between;">
                                {{ "Sort by Completed" | translate }}
                                <mat-icon style="margin-right: 0 !important;">arrow_drop_up</mat-icon>
                            </div>
                        </mat-list-option>
                    </mat-selection-list>
                </ng-container>
                <ng-container *ngSwitchCase="'Posts'">
                    <mat-selection-list [multiple]="false" formControlName="order_by">
                        <mat-list-option [value]="'id_desc'" [ngClass]="{'active': sortValue == 'id_desc'}">
                            <div class="d-f-c" style="justify-content: space-between;">
                                {{ "Sort by id" | translate }}
                                <mat-icon style="margin-right: 0 !important;">arrow_drop_down</mat-icon>
                            </div>
                        </mat-list-option>
                        <mat-list-option [value]="'id'" [ngClass]="{'active': sortValue == 'id'}">
                            <div class="d-f-c" style="justify-content: space-between;">
                                {{ "Sort by id" | translate }}
                                <mat-icon style="margin-right: 0 !important;">arrow_drop_up</mat-icon>
                            </div>
                        </mat-list-option>
                        <mat-list-option [value]="'content_published_at_desc'" [ngClass]="{'active': sortValue == 'content_published_at_desc'}">
                          <div class="d-f-c" style="justify-content: space-between;">
                              {{  "Sort by content published at" | translate }}
                              <mat-icon style="margin-right: 0 !important;">arrow_drop_down</mat-icon>
                          </div>
                        </mat-list-option>
                        <mat-list-option [value]="'content_published_at'" [ngClass]="{'active': sortValue == 'content_published_at'}">
                          <div class="d-f-c" style="justify-content: space-between;">
                              {{ "Sort by content published at" | translate }}
                              <mat-icon style="margin-right: 0 !important;">arrow_drop_up</mat-icon>
                          </div>
                        </mat-list-option>
                        <mat-list-option [value]="'content_views_count_desc'" [ngClass]="{'active': sortValue == 'content_views_count_desc'}">
                          <div class="d-f-c" style="justify-content: space-between;">
                              {{ "Sort by content views count" | translate }}
                              <mat-icon style="margin-right: 0 !important;">arrow_drop_down</mat-icon>
                          </div>
                        </mat-list-option>
                        <mat-list-option [value]="'content_views_count'" [ngClass]="{'active': sortValue == 'content_views_count'}">
                          <div class="d-f-c" style="justify-content: space-between;">
                              {{ "Sort by content views count" | translate }}
                              <mat-icon style="margin-right: 0 !important;">arrow_drop_up</mat-icon>
                          </div>
                        </mat-list-option>
                    </mat-selection-list>
                </ng-container>
            </form>
        </ng-container>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <div class="mobile_interface_buttons small_mob_btns" style="justify-content: space-between !important;">
        <button class="mobile_interface_btn" (click)="close()">
            {{ "Cancel" | translate }}
        </button>
        
        <button class="mobile_interface_btn mobile_interface_btn_blue" (click)="onSubmit()">
            {{ "Apply" | translate }}
        </button>
    </div>
</mat-dialog-actions>