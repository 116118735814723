import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTable } from '@angular/material/table';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { forkJoin, fromEvent } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { DeleteAlertComponent } from 'src/app/shared/global_components/delete-alert/delete-alert.component';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { ChatService } from 'src/app/shared/services/rest/chat.service';
import { TaskBarService } from 'src/app/shared/services/rest/task-bar.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { environment } from 'src/environments/environment';
import { AddAdditionalEmployeesComponent } from '../../additionalEmpl/add-additional-employees/add-additional-employees.component';
import { AdditionalEmployeesComponent } from '../../additionalEmpl/additional-employees/additional-employees.component';
import { CreateOperationChatComponent } from '../create-operation-chat/create-operation-chat.component';
import { EditOperationChatComponent } from '../edit-operation-chat/edit-operation-chat.component';
import { OperationChatComponent } from '../operation-chat/operation-chat.component';

@Component({
  selector: 'app-operation-chats',
  templateUrl: './operation-chats.component.html',
  styleUrls: ['./operation-chats.component.scss']
})
export class OperationChatsComponent extends BaseClass implements OnInit, OnDestroy {
  @ViewChild(MatTable) table: MatTable<any>;
  public displayedColumns: string[] = ['Date', 'Creator', 'Name', 'Preview', 'Preview Date', 'Status', 'Actions'];
  public chats: any;
  public activeLang: any;
  public filter: FormGroup;
  public host: any = environment.host;
  public sortValue: string = "updated_desc";
  public activeStatus: number[] = [0];
  public timeZone: any;
  public isInit: boolean = false;
  public is_mobile: boolean = false;
  public big_monitor: boolean = false;
  
  public origin = window.location.origin;
  public statuses: any = [
    {
      name: "All",
      id: 0,
      hasChats: true
    },
    {
      name: "Waiting",
      id: 1,
      hasChats: false
    },
    {
      name: "In process",
      id: 2,
      hasChats: false
    },
    {
      name: "To approve",
      id: 3,
      hasChats: false
    },
    {
      name: "Approved",
      id: 4,
      hasChats: false
    },
    {
      name: "To correct",
      id: 5,
      hasChats: false
    },
    {
      name: "Sources needed",
      id: 6,
      hasChats: false
    },
    {
      name: "Ready",
      id: 98,
      hasChats: false
    },
    {
      name: "Canceled",
      id: 99,
      hasChats: false
    },
  ];
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<OperationChatsComponent>,
    public chatService: ChatService,
    private layoutService: LayoutService,
    public taskService: TaskService,
    public taskBarService: TaskBarService,
    private sm: StorageManagerService,
    private dialog: MatDialog,
    public languageService: LanguageService,
    private bottomSheet: MatBottomSheet,
    private fb: FormBuilder
  ) { super() }

  ngOnInit(): void {
    this.timeZone = new Date().getTimezoneOffset();
    console.log(this.data);
    if (window.innerWidth <= 769) {
      this.is_mobile = true;
    } else {
      this.is_mobile = false;
      if (window.innerHeight >= 1400) {
        this.big_monitor = true;
      }
    }

    this.filter = this.fb.group({
      status_id: [[0], Validators.required],
      order_by: this.sortValue
    })

    this.isInit = true;
    this.getChats();
    this.getChatsCount();
    this.getLangData();
    this.onResize();

  }

  sort(val) {
    this.sortValue = val;
    this.filter.patchValue({
      order_by: this.sortValue
    })
    this.getChats()
  }

  getChatsCount() {
    this.statuses.filter(y => y.id).map(t => t.hasChats = false);
    this.attachSubscriptions(
      forkJoin(this.statuses.filter(y => y.id).map(x => this.chatService.getChatsCount(this.data.company.id, this.data.work.id, x.id).pipe(
        tap(z => {
          console.log(x);
          if (z.headers.get('x-pagination-total-count') > 0) {
            x.hasChats = true;
          }
        })
      ))).subscribe(resp => {
        console.log(resp)
      })
    )
  }

  changeActiveStatus(id, event = null) {
    if (!!event && (event.ctrlKey || event.metaKey)) {
      if (id == 0) {
        this.activeStatus = [0];
      } else if (this.activeStatus.includes(id)) {
        this.activeStatus.splice(this.activeStatus.indexOf(id), 1);
      } else if (this.activeStatus.includes(0) && id != 0) {
        this.activeStatus.splice(this.activeStatus.indexOf(0), 1)
        this.activeStatus.push(id);
      } else {
        this.activeStatus.push(id);
      }
      if (this.activeStatus.sort().toString() == [1,2,3,4,5,6,97,98,99].toString()) {
        this.activeStatus = [0];
      }
    } else {
      if (id == 0) {
        this.activeStatus = [0];
      } else {
        this.activeStatus = [id];
      }
    }

    this.filter.patchValue({
      status_id: this.activeStatus
    })

    this.getChats()
  }

  onResize() {
    this.attachSubscriptions(
      fromEvent(window, "resize").pipe(
        map(() => window.innerWidth)
      ).subscribe((wWidth) => {
          if (wWidth <= 769) {
            this.is_mobile = true;
            this.big_monitor = false;
          } else {
            this.is_mobile = false;
            if (wWidth >= 1400) {
              this.big_monitor = true;
            } else {
              this.big_monitor = false;
            }
          }
        }
      )
    )
  }

  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData().subscribe(resp => {
        this.activeLang = resp.active;
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  copyLink(type) {
    let snackData = {};
    snackData['name'] = marker(type + " link");
    this.layoutService.showSnackBar(snackData, marker("Copied"), SnackBarItem)
  }

  getChats() {
    this.attachSubscriptions(
      this.chatService.getChats(this.data.company.id, this.data.work.id, this.filter.value).subscribe(resp => {
        console.log(resp);
        this.chats = resp;
        if (this.isInit && this.data.chat_id && this.chats.find(el => el.id == this.data.chat_id)) {
          this.goToChat(this.chats.find(el => el.id == this.data.chat_id));
          this.isInit = false;
        }
      })
    )
  }

  addChat() {
    const createChatDialog = this.dialog.open(CreateOperationChatComponent, {
      disableClose: true,
      data: {
        is_create_chat_component: true,
        tasks: this.data.tasks,
        company: this.data.company,
        task: this.data.task,
        work: this.data.work,
        user: this.data.user,
        empl_status: this.data.empl_status,
        activeStatus: this.activeStatus
      }
    });

    this.attachSubscriptions(
      createChatDialog.afterClosed().subscribe(result => {
        if (!!result && result.event == 'create') {
          if (result.data.status_id != 4) {
            this.data.task.unClosedChats = this.data.task.unClosedChats + 1;
            this.data.work.unClosedChats = this.data.work.unClosedChats + 1;
          }
          if (this.data.work.status_id == 3 && (this.data.company.permissions.includes('owner') || this.data.checkIsManager(this.data.task, this.data.company, this.data.user))) {
            this.attachSubscriptions(
              this.taskService.editWork(this.data.work.id, {status_id: 4}, this.data.company.id).subscribe(resp => {
                  this.data.work.status_id = resp.status_id
              })
            )
          }
          this.getChats();
          this.getChatsCount();
        }
      })
    )
  }

  goToChat(chat) {
    console.log(chat);
    if(chat.is_read === '0') {
      this.data.task.read_discussion_count = this.data.task.read_discussion_count + 1;
      this.data.work.unReadChats = this.data.work.unReadChats - 1;
    }
    const goToChat = this.dialog.open(OperationChatComponent, {
      backdropClass: ['backdrop_under_header', "without_pad"],
      panelClass: ['full_screen_dialog', 'global_chats_dialog'],
      disableClose: true,
      data: {
        company: this.data.company,
        task: this.data.task,
        tasks: this.data.tasks,
        work: this.data.work,
        user: this.data.user,
        empl_status: this.data.empl_status,
        chat: chat,
        dialogType: 'operation_chat',
        chats: this.chats,
        dialogComp: this.dialogRef,
        operationsValues: this.data.operationsValues
      }
    });

    this.attachSubscriptions(
      goToChat.afterClosed().subscribe(result => {
        if (!!result && result.event == 'close') {
          this.getChats();
          this.getChatsCount();
          this.chatService.postChatRead(chat.id, 0, this.data.company.id).subscribe(resp => {
            console.log(resp);
          })
        }
      })
    )
  }

  deleteChat(chat) {
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: {},
        target: marker("chat")
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        if (chat.status_id != 4) {
          this.data.task.unClosedChats = this.data.task.unClosedChats - 1;
          this.data.work.unClosedChats = this.data.work.unClosedChats - 1;
        }
        this.attachSubscriptions(
          this.chatService.deleteChat(chat.id, this.data.company.id).subscribe(resp => {
            this.taskBarService.removeItems$.next({
              task_id: chat.task_id,
              task_operation_id: chat.task_operation_id,
              discussion_id: chat.id,
              company_id: chat.company_id
            })
            this.chats.splice(this.chats.indexOf(chat), 1);
            this.table.renderRows();
          })
        )
      }
    });

  }

  dblEditChat(e, chat, status) {
    if (!status) {
      return
    }

    this.editChat(chat);

  }

  addAdditionalEmplToWork(task, work, discussion) {
    const dialogRef = this.dialog.open(AddAdditionalEmployeesComponent, {
      disableClose: true,
      data: {
        user: this.data.user,
        company: this.data.company,
        task: task,
        work: work,
        discussion: discussion
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result && result.event == "add") {
          this.additionalEmployees(task, work, discussion);
        }
      })
    )
  }

  additionalEmployees(task, work, discussion) {
    const dialogRef = this.dialog.open(AdditionalEmployeesComponent, {
      disableClose: true,
      data: {
        user: this.data.user,
        company: this.data.company,
        task: task,
        work: work,
        discussion: discussion
      }
    });

    // this.attachSubscriptions(
    //   dialogRef.afterClosed().subscribe(result => {
    //     if (!!result && result.event == "update") {
          
    //     }
    //   })
    // )
  }

  editChat(chat) {

    const editChat = this.dialog.open(EditOperationChatComponent, {
      disableClose: true,
      data: {
        tasks: this.data.tasks,
        is_create_chat_component: true,
        company: this.data.company,
        task: this.data.task,
        work: this.data.work,
        user: this.data.user,
        empl_status: this.data.empl_status,
        chat: chat
      }
    });

    this.attachSubscriptions(
      editChat.afterClosed().subscribe(result => {
        if (!!result && result.event == 'edit') {
          console.log("result", result);
          
          if (result.beforeChatStatus != 4 && result.chatStatus == 4) {
            this.data.task.unClosedChats = this.data.task.unClosedChats - 1;
            this.data.work.unClosedChats = this.data.work.unClosedChats - 1;
          } else if (result.beforeChatStatus == 4 && result.chatStatus != 4) {
            this.data.task.unClosedChats = this.data.task.unClosedChats + 1;
            this.data.work.unClosedChats = this.data.work.unClosedChats + 1;
          }

          if (result.unClosedChats == 1 && result.chatStatus == 4) {
            this.taskService.editWork(this.data.work.id, {status_id: 3}, this.data.company.id).subscribe(response => {
              this.data.work.status_id = response.status_id;
            })
          }
          this.getChats();
          this.getChatsCount();
          this.chatService.postChatRead(chat.id, 0, this.data.company.id).subscribe(resp => {
            this.table.renderRows();
          })
        }
      })
    )
  }

  getColumnName(id) {
    if (!this.data.operationsValues || this.data.operationsValues?.filter(el => el.id == id).length == 0) {
      return '';
    }
    return this.data.operationsValues?.find(el => el.id == id)?.translation ? this.data.operationsValues.find(el => el.id == id).translation.name : this.data.operationsValues.find(el => el.id == id).name
  }

  getChatStatus(id) {
    switch (+id) {
      case 1:
        return "Waiting";
        break;
      case 2:
        return "In process";
        break;
      case 3:
        return "To approve";
        break;
      case 4:
        return "Approved";
        break;
      case 5:
        return "To correct";
        break;
      case 6:
        return "Sources needed";
        break;
      case 97:
        return "On hold";
        break;
      case 98:
        return "Ready";
        break;
      case 99:
        return "Canceled";
        break;
    }
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

}
