<h2 mat-dialog-title class="dialog_title">
    <span>
        {{ "Export audio from" | translate }}: {{ data.files && data.files.length ? ('selected files' | translate) : data.file.filename }}
    </span>
    <button class="ml-1" mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h2>
<mat-dialog-content class="mat-typography" dirEnter (dirSubmit)="!form.valid ? null : submitForm()">
    <div class="container">
        <form [formGroup]="form">
            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ "Inherit tags from categories" | translate }}</mat-label>
                <mat-select [disableOptionCentering]="true" selectDir [multiple]="true" formControlName="parameter_id">
                    <mat-select-trigger class="multi_users">
                        <ng-container *ngIf="form.get('parameter_id').value && form.get('parameter_id').value.length">
                            <ng-container *ngFor="let id of form.get('parameter_id').value">
                                <span>{{ getParamById(id)?.name }}</span>
                            </ng-container>
                        </ng-container>
                    </mat-select-trigger>
                    <mat-option>
                        <ngx-mat-select-search 
                        placeholderLabel="{{ 'Search' | translate }}" 
                        noEntriesFoundLabel="{{ 'no matching tag category found' | translate }}"
                        [formControl]="paramControl"></ngx-mat-select-search>
                    </mat-option>
                    <ng-container *ngFor="let item of parameters$ | async">
                        <mat-option *ngIf="!!item.id" [value]="item.id">
                            <span>{{ item.name }}</span>
                        </mat-option>
                    </ng-container>
                </mat-select>
            </mat-form-field>
        </form>
        <div class="box_wrp d-f-c" style="height: 40px;">
            <mat-checkbox color="primary" [(ngModel)]="is_remember">{{"Remember selection for this device" | translate}}</mat-checkbox>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button (click)="close()">
        {{ "Close" | translate }}
    </button>
    <ng-container *ngIf="data.files && data.files.length; else elseTemplateMultiFiles">        
        <button class="ml-1" (click)="multiSubmitForm()" mat-raised-button cdkFocusInitial color="primary">
            {{ "Export" | translate }}
        </button>
    </ng-container>
    <ng-template #elseTemplateMultiFiles>
        <button class="ml-1" (click)="submitForm()" mat-raised-button cdkFocusInitial color="primary" [disabled]="data.file.isImportingAudio">
            {{ "Export" | translate }}
        </button>
    </ng-template>
    
</mat-dialog-actions>
