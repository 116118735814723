import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { interval } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { FileService } from 'src/app/shared/services/rest/file.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';

@Component({
  selector: 'app-ask-file',
  templateUrl: './ask-file.component.html',
  styleUrls: ['./ask-file.component.scss']
})
export class AskFileComponent extends BaseClass implements OnInit, OnDestroy {
  public isSubmit: boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AskFileComponent>,
    private fb: FormBuilder,
    private fileService: FileService,
    private taskService: TaskService,
    public layoutService: LayoutService
  ) { super() }

  ngOnInit(): void {
    console.log("AskFileComponent", this.data)
    this.attachSubscriptions(
      interval(5000).pipe(
        filter(() => !this.data.request.data.files_count),
        switchMap(() => this.fileService.getCopyReq(this.data.company.id, {id: this.data.request.id} ).pipe(
          map(k => k.body[0]),
          tap(cReq => {
            if (!!cReq.data.files_count) {
              this.data.request.data.files_count = cReq.data.files_count
            }
          })
        ))
      ).subscribe(resp => {
        console.log("CheckProgress AskFileComponent", resp);
      })
    )
  }

  no() {
    this.isSubmit = true;
    this.attachSubscriptions(
      this.fileService.editCopyReq(this.data.request.id, this.data.company.id, {is_cancel: 1}).subscribe(resp => {
        this.data.request = resp;
        this.isSubmit = false;
        this.dialogRef.close({event: "no", data: false});
      },
      error => {
        this.isSubmit = false;
      })
    )
  }

  yes() {
    this.isSubmit = true;
    this.attachSubscriptions(
      this.fileService.editCopyReq(this.data.request.id, this.data.company.id, {is_to_process: 1}).subscribe(resp => {
        this.data.request = resp;
        this.isSubmit = false;
        this.dialogRef.close({event: "yes", data: resp})
      },
      error => {
        this.isSubmit = false;
      })
    )
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
