import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReplaySubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { AuthService } from 'src/app/shared/services/rest/auth.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-profile-edit',
  templateUrl: './profile-edit.component.html',
  styleUrls: ['./profile-edit.component.scss']
})
export class ProfileEditComponent extends BaseClass implements OnInit, OnDestroy {
  public host: any = environment.host;
  public imgRoute: any = '';
  public user: any = {};
  public user_id: number;
  public platforms: any;
  public form: FormGroup;
  public group_id: any;
  public profile: any;
  public profile_id: any;
  public isSubmit: boolean = false;
  public isFormChange: boolean = false;
  
  public profilesMoreControl: FormControl = new FormControl();
  public platforms$: ReplaySubject<any> = new ReplaySubject<any>(1);
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ProfileEditComponent>,
    private layoutService: LayoutService,
    private sm: StorageManagerService,
    private auth: AuthService,
    private taskService: TaskService,
    private companyService: CompanyService,
    private fb: FormBuilder,
  ) { super() }

  ngOnInit(): void {
    console.log(this.data)
    this.getCompany();
    this.getImgRoute();
    this.getCsrf();
    this.getUser();

    this.attachSubscriptions(
      this.profilesMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchProfiles(resp))
    )

    // platform_id: [this.data.profile.platform_id, Validators.required],
    this.form = this.fb.group({
      group_id: this.data.group ? this.data.group.id : '',
      name: [this.data.profile.name, Validators.required],
      url: [this.data.profile.url, Validators.required],
      ai_description: this.data.profile.ai_description || '',
      publishing_params: this.fb.group({
        description_prefix: !!this.data.profile.publishing_params && this.data.profile.publishing_params.hasOwnProperty('description_prefix') ? this.data.profile.publishing_params.description_prefix : '',
        description_postfix: !!this.data.profile.publishing_params && this.data.profile.publishing_params.hasOwnProperty('description_postfix') ? this.data.profile.publishing_params.description_postfix : ''
      })
    })

    if (this.data.profile.platform_id == 1) {
      (this.form.get('publishing_params') as FormGroup).addControl('made_for_kids', this.fb.control(!!this.data.profile.publishing_params && this.data.profile.publishing_params.hasOwnProperty('made_for_kids') ? this.data.profile.publishing_params.made_for_kids : false))
    }

    if (this.data.profile.is_user_email == 0 || this.data.company.permissions.includes('admin') || this.data.company.permissions.includes('owner')) {
      this.form.addControl('user_email', this.fb.control(!!this.data.profile.user_email ? this.data.profile.user_email : '', Validators.email))
    }
    
    if (this.data.post) {
      this.form.patchValue({
        ai_description: this.data.post.ai_description
      })
    }

    this.attachSubscriptions(
      this.form.valueChanges.subscribe(() => this.isFormChange = true)
    )

    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        if (this.isFormChange) {
          this.layoutService.openBottomSheet(this.dialogRef);
        } else {
          this.close();
        }
      })
    )

    this.getPlatforms();

  }

  onSearchProfiles(resp) {
    if (!this.platforms) {
      return;
    }

    if (!resp) {
      this.platforms$.next(this.platforms.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }

    // filter the banks
    this.platforms$.next(
      this.platforms.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  getCompany() {
    this.attachSubscriptions(
      this.companyService.getCompany(this.data.company_id).subscribe(resp => {
        this.companyService.company$.next(resp[0]);
      })
    )
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  getCsrf() {
    this.attachSubscriptions(
      this.auth.$userToken.subscribe(resp => {
        this.user_id = resp.user_id;
        this.sm.localStorageSetItem("csrf_param", resp.csrf_param)
        this.sm.localStorageSetItem("csrf_token", resp.csrf_token)
      })
    )
  }

  getUser() {
    this.attachSubscriptions(
      this.auth.$user.subscribe(resp => {
        this.user = resp;
      })
    )
  }

  getPlatforms() {
    this.attachSubscriptions(
      this.companyService.getGroupPlatforms().subscribe(resp => {
        this.platforms = resp;
        this.platforms$.next(this.platforms.slice());
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  onSubmit() {
    if (this.data.post) {
      this.attachSubscriptions(
        this.taskService.editProfile(this.data.post.id, {ai_description: this.form.value.ai_description}, this.data.company.id).subscribe(resp => {
          this.isSubmit = false;
          this.dialogRef.close({event: "update", data: resp});
        }, error => {
          this.isSubmit = false;
          this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
        })
      )
    } else {
      this.attachSubscriptions(
        this.companyService.editCompanyGroupProfile(this.data.profile.id, this.data.aiDesc ? {ai_description: this.form.value.ai_description} : this.form.value).subscribe(resp => {
          this.isSubmit = false;
          this.dialogRef.close({event: "update", data: resp});
        }, error => {
          this.isSubmit = false;
          this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
        })
      )
    }
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
