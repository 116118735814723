<div mat-dialog-title class="mobile_interface_title">
    <div class="mobile_interface_title_in" style="overflow: hidden;">
        <ng-container [ngSwitch]="selectedIndex.value">
            <ng-container *ngSwitchCase="0">
                <mat-tab-group class="title_tabs no_header" style="width: 100%;" [selectedIndex]="selectedWorkspaceIndex.value">
                    <mat-tab>
                        <div class="tab_title">
                            <span>
                                {{ "Add card" | translate }}
                            </span>
                            <span class="small_title">1 / 7 — {{ "SELECT WORKSPACE THE CARD WILL BELONG TO" | translate }}</span>
                        </div>
                    </mat-tab>
                    <mat-tab>
                        <div class="tab_title">
                            <span>
                                {{ "Add card" | translate }}
                            </span>
                            <span class="small_title">1 / 7 — {{ "SELECT WORKSPACE THE CARD WILL BELONG TO" | translate }}</span>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </ng-container>
            <ng-container *ngSwitchCase="1">
                <div>
                    <span>
                        {{ "Add card" | translate }}
                    </span>
                    <div class="small_title">
                        {{ "Post basic information" | translate }}
                    </div>
                </div>
            </ng-container>
            <ng-container *ngSwitchCase="2">
                <div>
                    <span>
                        {{ "Add card" | translate }}
                    </span>
                    <div class="small_title">
                        {{ "Content type" | translate }}
                    </div>
                </div>
            </ng-container>
            <ng-container *ngSwitchCase="3">
                <div>
                    <span>
                        {{ "Add card" | translate }}
                    </span>
                    <div class="small_title">
                        {{ "Publication status" | translate }}
                    </div>
                </div>
            </ng-container>
            <ng-container *ngSwitchDefault>
                <div class="d-f-c close_title">
                    <!-- <div class="icon_back" (click)="close()">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7 12L6.11612 11.1161C5.62796 11.6043 5.62796 12.3957 6.11612 12.8839L7 12ZM16.8839 3.88388C17.372 3.39573 17.372 2.60427 16.8839 2.11612C16.3957 1.62796 15.6043 1.62796 15.1161 2.11612L16.8839 3.88388ZM15.1161 21.8839C15.6043 22.372 16.3957 22.372 16.8839 21.8839C17.372 21.3957 17.372 20.6043 16.8839 20.1161L15.1161 21.8839ZM7.88388 12.8839L16.8839 3.88388L15.1161 2.11612L6.11612 11.1161L7.88388 12.8839ZM6.11612 12.8839L15.1161 21.8839L16.8839 20.1161L7.88388 11.1161L6.11612 12.8839Z" fill="#232323"/>
                        </svg>
                    </div> -->
                    <span>
                        {{ "Add card" | translate }}
                    </span>
                </div>
            </ng-container>
        </ng-container>
    </div>

</div>
<mat-dialog-content class="mobile_interface_content">
    <mat-tab-group class="no_header" [selectedIndex]="selectedIndex.value" (selectedIndexChange)="changeIndex($event)">
        <mat-tab>
            <mat-tab-group class="create_new_task_tabs" [selectedIndex]="selectedWorkspaceIndex" (selectedIndexChange)="changeWorkspaceTab($event)">
                <mat-tab>
                    <ng-template mat-tab-label>
                        {{ "Your workspaces" | translate }}
                    </ng-template>
                    <form [formGroup]="form" class="form_with_valids mobile_scrollable_int">
                        <div class="mob_interface_chips_out has_pads">
                            <div class="small_chips">
                                <ng-container *ngIf="!form.get('company_id').valid && submited">
                                    <div class="req_field"></div>
                                    <div class="req_label">
                                        <div class="angle"></div>
                                        <span>
                                            {{ "You need to choose Workspace" | translate }}
                                        </span>
                                    </div>
                                </ng-container>
                                <!-- <label>{{ "Choose a Workspace" | translate }}: </label> -->
                                <mat-chip-list class="new_task_chips" [multiple]="false" selectable formControlName="company_id">
                                    <ng-container *ngFor="let comp of data.companyes; let i = index">
                                        <mat-chip class="small_chip cond" style="margin-top: 28px !important;" (click)="toggleChip(comp)" [value]="comp.id">
                                            <app-user [class]="'executor'" [companyEmployee]="comp" [imgRoute]="data?.imgRoute" [host]="data?.host" [company]="comp"></app-user>
                                        </mat-chip>
                                        <mat-chip *ngIf="i == data.companyes.length - 1 && i > 6" style="margin-top: 28px !important;" class="small_chip more cond" (click)="companyesContainer.open()">
                                            {{ "more" | translate }}
                                        </mat-chip>
                                    </ng-container>
                                </mat-chip-list>
                            </div>
                
                            <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                                <mat-select #companyesContainer formControlName="company_id" (selectionChange)="toggleChip($event)">
                                    <mat-option>
                                        <ngx-mat-select-search 
                                        placeholderLabel="{{ 'Search' | translate }}" 
                                        noEntriesFoundLabel="{{ 'no matching Workspaces found' | translate }}"
                                        [formControl]="compMoreControl"></ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option *ngFor="let comp of companyes$ | async" [value]="comp.id">
                                        <app-user [class]="'executor'" [companyEmployee]="comp" [imgRoute]="data?.imgRoute" [host]="data?.host" [company]="comp"></app-user>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </form>
                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        {{ "External Workspaces" | translate }}
                    </ng-template>
                    <form [formGroup]="exForm" class="form_with_valids mobile_scrollable_int">
                        <div class="mob_interface_chips_out has_pads">

                            <div class="small_chips" [ngClass]="{'req_value': !exForm.get('company_id').valid}">
                                <ng-container *ngIf="!exForm.get('company_id').valid && exSubmited">
                                    <div class="req_field"></div>
                                    <div class="req_label">
                                        <div class="angle"></div>
                                        <span>
                                            {{ "Choose a Workspace" | translate }}
                                        </span>
                                    </div>
                                </ng-container>
                                <label>{{ "To select external workspace, please enter one of yours." | translate }}: </label>
                                <mat-chip-list class="new_task_chips" [multiple]="false" selectable formControlName="company_id">
                                    <ng-container *ngFor="let comp of myCompanyes; let i = index">
                                        <mat-chip class="small_chip cond" style="margin-top: 28px !important;" (click)="toggleExChip(comp)" [value]="comp.id">
                                            <app-user [class]="'executor'" [companyEmployee]="comp" [imgRoute]="data?.imgRoute" [host]="data?.host" [company]="comp"></app-user>
                                        </mat-chip>
                                        <mat-chip *ngIf="i == myCompanyes.length - 1 && i > 6" style="margin-top: 28px !important;" class="small_chip more cond" (click)="exCompanyesContainer.open()">
                                            {{ "more" | translate }}
                                        </mat-chip>
                                    </ng-container>
                                </mat-chip-list>
                            </div>
                
                            <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                                <mat-select #exCompanyesContainer formControlName="company_id" (selectionChange)="toggleExChip($event)">
                                    <mat-option>
                                        <ngx-mat-select-search 
                                        placeholderLabel="{{ 'Search' | translate }}" 
                                        noEntriesFoundLabel="{{ 'no matching Workspaces found' | translate }}"
                                        [formControl]="myCompMoreControl"></ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option *ngFor="let comp of myCompanyes$ | async" [value]="comp.id">
                                        <app-user [class]="'executor'" [companyEmployee]="comp" [imgRoute]="data?.imgRoute" [host]="data?.host" [company]="comp"></app-user>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
    
                        <div *ngIf="!!noPartners && !!exForm.get('company_id').value" style="padding: 15px 0; color: red;">
                            {{ "This workspace has no partners" | translate }}
                        </div>

                        <div class="mob_interface_chips_out only50vh has_pads">

                            <div *ngIf="partners && partners.length" class="small_chips" [ngClass]="{'req_value': !exForm.get('partner_company_id').valid}">
                                <ng-container *ngIf="!exForm.get('partner_company_id').valid && exSubmited">
                                    <div class="req_field"></div>
                                    <div class="req_label">
                                        <div class="angle"></div>
                                        <span>
                                            {{ "Choose a Workspace" | translate }}
                                        </span>
                                    </div>
                                </ng-container>
                                <label>{{ "Select external workspace" | translate }}: </label>
                                <mat-chip-list class="new_task_chips" [multiple]="false" selectable formControlName="partner_company_id">
                                    <ng-container *ngFor="let comp of partners; let i = index">
                                        <mat-chip class="small_chip cond" style="margin-top: 28px !important;" (click)="togglePartnerChip(comp)" [value]="comp?.partner_company_id">
                                            <app-user [class]="'executor'" [companyEmployee]="comp?.partnerCompany" [imgRoute]="data?.imgRoute" [host]="data?.host" [company]="comp"></app-user>
                                        </mat-chip>
                                        <mat-chip *ngIf="i == partners.length - 1 && i > 6" style="margin-top: 28px !important;" class="small_chip more cond" (click)="externalCompanyesSelect.open()">
                                            {{ "more" | translate }}
                                        </mat-chip>
                                    </ng-container>
                                </mat-chip-list>
                            </div>
                
                            <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                                <mat-select #externalCompanyesSelect formControlName="partner_company_id" (selectionChange)="togglePartnerChip($event)">
                                    <mat-option>
                                        <ngx-mat-select-search 
                                        placeholderLabel="{{ 'Search' | translate }}" 
                                        noEntriesFoundLabel="{{ 'no matching external workspaces found' | translate }}"
                                        [formControl]="partnerCompMoreControl"></ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option *ngFor="let comp of partners$ | async" [value]="comp?.partner_company_id">
                                        <app-user [class]="'executor'" [companyEmployee]="comp?.partnerCompany" [imgRoute]="data?.imgRoute" [host]="data?.host" [company]="comp"></app-user>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
    
                    </form>
                </mat-tab>
            </mat-tab-group>
        </mat-tab>
    </mat-tab-group>
</mat-dialog-content>
<mat-dialog-actions>
    <div class="mobile_interface_buttons" style="justify-content: space-between;">
        <ng-container [ngSwitch]="selectedIndex.value">
            <button class="mobile_interface_btn" (click)="close()">
                {{ "Close" | translate }}
            </button>

            <div class="d-f-c">
                <button style="padding: 8px 12px !important;" class="mobile_interface_btn mobile_interface_btn_white" *ngIf="!!taskTemplates && !!taskTemplates.length" (click)="useTemplate()" mat-raised-button cdkFocusInitial color="primary" [disabled]="!!isSubmit">
                    <mat-spinner class="spin_16 mr-1" *ngIf="isSubmit"></mat-spinner>  
                    {{ "Use template" | translate }}
                </button>
                <ng-container *ngIf="selectedWorkspaceIndex.value == 0; else elseTemplatePartnersTab">
                    <button class="mobile_interface_btn mobile_interface_btn_blue ml-1" (click)="selectWorkSpace()" mat-raised-button cdkFocusInitial color="primary" [disabled]="!!isSubmit">
                        <mat-spinner class="spin_16 mr-1" *ngIf="isSubmit"></mat-spinner>  
                        {{ "Next" | translate }}
                    </button>
                </ng-container>
                <ng-template #elseTemplatePartnersTab>
                    <button class="mobile_interface_btn mobile_interface_btn_blue ml-1" (click)="selectPartnerWorkSpace()" mat-raised-button cdkFocusInitial color="primary" [disabled]="!!exIsSubmit">
                        <mat-spinner class="spin_16 mr-1" *ngIf="exIsSubmit"></mat-spinner>  
                        {{ "Next" | translate }}
                    </button>
                </ng-template>
            </div>
        </ng-container>
    </div>
</mat-dialog-actions>
