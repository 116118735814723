import { Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { mimeTypes } from 'mime-wrapper';
import { concat, forkJoin, of } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { prioritys } from 'src/app/shared/consts/prioritys';
import { casesModel } from 'src/app/shared/functions/casesModel';
import { makeArray } from 'src/app/shared/functions/objToArray';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { ChatService } from 'src/app/shared/services/rest/chat.service';
import { FileService } from 'src/app/shared/services/rest/file.service';
import { RefreshService } from 'src/app/shared/services/rest/refresh.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { UploadService } from 'src/app/shared/services/rest/upload.service';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MY_FORMATS } from '../task-profile-add/task-profile-add.component';
import { NgxMatDateAdapter } from '@angular-material-components/datetime-picker';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import * as moment from 'moment';
@Component({
  selector: 'app-create-operation-chat',
  templateUrl: './create-operation-chat.component.html',
  styleUrls: ['./create-operation-chat.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
// deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
// {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true }},
export class CreateOperationChatComponent extends BaseClass implements OnInit, OnDestroy {
  public form: FormGroup;
  public isFormChange: boolean = false;
  public isSubmit: boolean = false;
  public submited: boolean = false;
  public statuses: any;
  
  public selectedText: any;
  public uploadLimit: boolean = false;
  public prioritys: any = prioritys;
  public dropOver: boolean = false;
  public filesArray: any[] = []
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CreateOperationChatComponent>,
    private layoutService: LayoutService,
    private chatService: ChatService,
    private fb: FormBuilder,
    private _adapter: DateAdapter<any>,
    private _ngx_adapter: NgxMatDateAdapter<any>,
    private languageService: LanguageService,
    private uploadService: UploadService,
    private taskService: TaskService,
    private fileService: FileService,
    private refreshService: RefreshService,
    private router: Router
  ) { super() }

  ngOnInit(): void {
    this.dialogRef.addPanelClass("modal_has_contenteditable")
    console.log("CreateOperationChatComponent", this.data)
    this.getChatStatuses();
    this.getLangData();
    this.form = this.fb.group({
      task_operation_id: this.data.work.id,
      status_id: [1, Validators.required],
      name: ['', Validators.required],
      text: '',
      priority: 0,
      planned_completed_at: '',
      date: this.fb.group({
        time: '',
        day: ''
      })
    })

    this.attachSubscriptions(
      this.form.get('date').valueChanges.subscribe(resp => {
        console.log(resp)
        let dateForm = {...this.form.get("date").value}
        if (!dateForm.day) {
          dateForm.day = moment(0).hour(0).minute(0)
        }
        let testTimeAndDate = dateForm.day.clone()
        testTimeAndDate.hour(Number(dateForm.time.split(":")[0]))
        testTimeAndDate.minute(Number(dateForm.time.split(":")[1]))
        this.form.patchValue({
          planned_completed_at: testTimeAndDate
        })
        console.log('this.form.value', this.form.value);
        console.log('testTimeAndDate', testTimeAndDate.clone().format("X"));
      })
    )

    this.attachSubscriptions(
      this.uploadService.getUploadLimit().subscribe(resp => {
        if (resp || this.data.company.filesize >= this.data.company.filesize_limit) {
          this.uploadLimit = true;
        }
      })
    )

    if (this.data.activeStatus && !this.data.activeStatus.includes(0)) {
      this.data.activeStatus.sort();
      this.form.patchValue({
        status_id: this.data.activeStatus[0]
      })
    }

    this.attachSubscriptions(
      this.form.valueChanges.subscribe(() => this.isFormChange = true)
    )

    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        if (this.isFormChange) {
          this.layoutService.openBottomSheet(this.dialogRef);
        } else {
          this.close();
        }
      })
    )
  }

  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData().subscribe(resp => {
        this._ngx_adapter.setLocale(resp.active);
        this._adapter.setLocale(resp.active);
      })
    )
  }

  consoleFiles() {
    console.log(this.filesArray)
  }

  closeBtn() {
    if (this.isFormChange) {
      this.layoutService.openBottomSheet(this.dialogRef);
    } else {
      this.close();
    }
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  workAfterClosed(resp = {body:[{}]}, task, work = null) {
    let data = casesModel([resp.body[0]], [], 'update')
    data['arr'][0]['isExpandedProfile'] = task.isExpandedProfile;
    data['arr'][0]['isExpandedWork'] = task.isExpandedWork;

    Object.keys(task).forEach(key => {
      task[key] = data.arr[0][key]
    })
    if (work) {
      Object.keys(work).forEach(key => {
        if (data['arr'][0].operations && data['arr'][0].operations.find(y => y.id == work.id)) {
          work[key] = data['arr'][0].operations.find(y => y.id == work.id)[key]
        }
      })
    }
    this.refreshService.refreshTask$.next({company_id: this.data.company.id, task_id: task.id})
    this.attachSubscriptions(
      this.chatService.getTasksChats(this.data.company.id, [task.id]).subscribe(res => {
          task['operations'].forEach(o_el => {
            o_el['chatsInfo'] = [];
            o_el['chatsInfo'].push(...res.filter(z => z.task_operation_id == o_el.id));
            o_el['unReadChats'] = res.filter(z => z.task_operation_id == o_el.id && z.is_read != '1').length;
            o_el['unClosedChats'] = res.filter(z => z.task_operation_id == o_el.id && z.status_id != '4').length;
          });
          task['chatsInfo'] = [];
          task['chatsInfo'].push(...res);
          task['unClosedChats'] = res.filter(z => z.status_id != '4').length;
          task.discussion_count = res.length;
          task.read_discussion_count = res.filter(z => z.is_read == '1').length;

          // this.data.tasks.splice(this.data.tasks.indexOf(task), 1, data.arr[0]);
          this.dialogRef.close({event: "create", data: resp})
      })
    )

  }

  selectPrioritys = (priority: any) => {
    this.form.patchValue({
      priority: priority.id
    })
  }

  selectStatus(status) {
    console.log(status.id)
    if (status.id == 3) {
      this.form.addControl("completed_at", this.fb.control(''));
      this.form.get("completed_at").updateValueAndValidity();
    } else if (!!this.form.get('completed_at')) {
      this.form.removeControl("completed_at");
      this.form.updateValueAndValidity();
    }
    this.form.patchValue({
      status_id: status.id
    })
  }

  onSubmit(e?) {

    if (!this.form.valid) {
      this.submited = true;
      this.layoutService.showSnackBar({name: ''}, marker("You need to fill in all required fields"), SnackBarItem)
      return
    }

    this.submited = false;
    if (!this.form.valid || this.isSubmit) {
      return
    }
    
    if (e && !e.shiftKey) {
      e.stopPropagation();
      e.preventDefault();
      console.log("a")
    } else if (e && e.shiftKey) {
      return
    }

    if (this.filesArray.filter(x => x.is_compress == true).length == 0) {
      this.createChat()
    } else {
      this.isSubmit = true;
      this.filesArray.filter(x => x.is_compress).forEach((x,i) => {
        this.compress(x, i)
      });
    }
  }

  getChatStatuses() {
    this.attachSubscriptions(
      this.chatService.getChatStatuses().subscribe(resp => {
        this.statuses = resp.filter(x => x.id != 98);
      })
    )
  }

  getColumnName(id){
    if (!this.data.operationsValues || this.data.operationsValues.filter(el => el.id == id).length == 0) {
      return '';
    }
    return this.data.operationsValues.find(el => el.id == id).translation ? this.data.operationsValues.find(el => el.id == id).translation.name : this.data.operationsValues.find(el => el.id == id).name
  }

  // files start

  onFileDropped(e) {
    this.dropOver = false;
    if (this.uploadLimit) {
      this.layoutService.showSnackBar({name: ''}, marker("This company has exceeded the file upload limit."), SnackBarItem)
      return
    }
    if (!e.dataTransfer.files.length) {
      return false;
    }

    this.getFilesDataTransferItems(e.dataTransfer.items).then((files:any) => {
      if (files.length > 0) {
        console.log("onFileDropped", files);
        files.forEach(el => {
          if (el.type.indexOf("image") != -1 || mimeTypes.getType(el.name).indexOf("image") != -1) {
            el.is_compress = true;
            el.dontCompress = false;
          } else {
            el.is_compress = false;
            el.dontCompress = true;
          }
          const reader = new FileReader();
          reader.onload = () => {
            el.src = reader.result as string;
          }
          reader.readAsDataURL(el)
        })

        this.filesArray.push(...files)
      }
    });
    
    if (this.data.company.filesize >= this.data.company.filesize_limit) {
      this.uploadService.uploadLimit$.next(true)
    }
  }

  uploadFile(event) {
    if (this.uploadLimit) {
      this.layoutService.showSnackBar({name: ''}, marker("This company has exceeded the file upload limit."), SnackBarItem)
      return
    }
    if (event.target.files.length > 0) {
      let files = makeArray(event.target.files)
      if (files.length > 0) {
        console.log("uploadFile", files)
        files.forEach(el => {
          if (el.type.indexOf("image") != -1 || mimeTypes.getType(el.name).indexOf("image") != -1) {
            el.is_compress = true;
            el.dontCompress = false;
          } else {
            el.is_compress = false;
            el.dontCompress = true;
          }
          const reader = new FileReader();
          reader.onload = () => {
            el.src = reader.result as string;
          }
          reader.readAsDataURL(el)
        })
        this.filesArray.push(...files)
      }
    } 
  }

  // putFile(file, chat) {
  //   console.log("IN PUT")
  //   let postData:any = {
  //     "discussion_id": chat.id,
  //   }

  //   postData.upload_filename = file.name,
  //   postData.upload_content_type = file.type ? file.type : mimeTypes.getType(file.name);
  //   postData.upload_filesize = file.size

  //   console.log(postData);
  //   return this.chatService.sendPost(postData, this.data.company.id)
  // }

  putFile(chat) {
    console.log("IN PUT")

    let postData:any = {
      discussion_id: chat.id,
      upload_files: [],
      text: this.form.value.text
    }


    for (let index = 0; index < this.filesArray.length; index++) {
      const file = this.filesArray[index];
      
      if (!file.withoutFile) {
        postData.upload_files.push({
          custom_upload_id: +index + 1,
          upload_filename: file.name,
          upload_content_type: file.type ? file.type : mimeTypes.getType(file.name),
          upload_filesize: file.size
        })
      }
      
      if (file.fileWithText) {
        postData.text = file.text
      }
    }

    console.log(postData);
    return this.chatService.sendPost(postData, this.data.company.id).pipe(
      switchMap(res => {
        return this.chatService.getTargetPost(chat.id, res.id, this.data.company.id).pipe(
          tap(n => {    
            n[0].upload_files = res.upload_files
          }),
          map((n) => n[0])
        )
      }),
      tap(rslt => {
        if (rslt.upload_files) {
          for (let index = 0; index < rslt.upload_files.length; index++) {
            const uploadFile = rslt.upload_files[index];
            rslt.discussionPostFiles[index].file.upload_url = uploadFile.upload_url;
            rslt.discussionPostFiles[index].file.size = rslt.discussionPostFiles[index].file.filesize;
            rslt.discussionPostFiles[index].file.name = rslt.discussionPostFiles[index].file.filename;
            let file = this.filesArray[index]
            if (!file.withoutFile) {
              let fileData = {...rslt}
              fileData.upload_url = uploadFile.upload_url
              fileData.fileVal = file;
              fileData.discussion_post_id = rslt.id;
              let fileTarget = rslt.discussionPostFiles[index].file;
              fileData.id = fileTarget.id;
              fileTarget.fileVal = file;

              console.log("fileData", fileData)
              console.log("file", file)
              console.log("uploadFile", uploadFile)
        
              this.fileService.files$.next({
                index: index,
                place: "chat",
                url: window.location.href,
                data: fileData,
                target: fileTarget,
                post: rslt,
                is_chat: true,
                task: this.data.task,
                work: this.data.work,
                activeLang: this.data.activeLang,
                operationsValues: this.data.operationsValues,
                posts: [],
                chat: chat,
                company: this.data.company,
                company_id: this.data.company.id,
                user: this.data.user
              })
            }
          }
        }
      })
    )
    
  }
  
  getFilesDataTransferItems(dataTransferItems) {
    function traverseFileTreePromise(item, folder) {
      return new Promise(resolve => {
        if (item.isFile) {
          item.file(file => {
            file.filepath = item.fullPath.replace(`/${file.name}`, "");
            folder.push(file);
            resolve(file);
          });
        } else if (item.isDirectory) {
          let dirReader = item.createReader();
          dirReader.readEntries(entries => {
            let entriesPromises = [];
            for (let entr of entries)
              entriesPromises.push(
                traverseFileTreePromise(entr, folder)
              );
            resolve(Promise.all(entriesPromises));
          });
        }
      });
    }
  
    let files = [];
    return new Promise((resolve, reject) => {
      let entriesPromises = [];
      for (let it of dataTransferItems)
        entriesPromises.push(
          traverseFileTreePromise(it.webkitGetAsEntry(), files)
        );
      Promise.all(entriesPromises).then(entries => {
        resolve(files);
      });
    });
  }

  detectBrowserName() { 
    const agent = window.navigator.userAgent.toLowerCase()
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }

  understandFileType(val) {
    if (!val) {
      return ""
    }
    
    if (val == 'application/pdf') {
      return 'pdf'
    } else if (val.indexOf("/") != -1) {
      return val.split('/')[0]
    } else {
      return ""
    }
  }

  compress(initFile, i) {
    const reader = new FileReader();
    reader.readAsDataURL(initFile);
    reader.onload = event => {
      let img = new Image() as any;
      img.src = event.target.result;
      
      img.onload = () => {
        const elem = document.createElement('canvas');
        elem.width = img.width*0.75;
        elem.height = img.height*0.75;
        const ctx = elem.getContext('2d');
        initFile.src = reader.result as string;
        
        // img.width и img.height будет содержать оригинальные размеры
        ctx.drawImage(img, 0, 0, img.width*0.75, img.height*0.75);
        ctx.canvas.toBlob((blob) => {
          const file = new File([blob], initFile.name, {
            type: initFile.type,
            lastModified: Date.now()
          });
          if (initFile.size > file.size) {
            this.filesArray.filter(x => x.is_compress == true)[i] = file
          }
          if (this.filesArray.filter(x => x.is_compress == true).length - 1 == i) {
            this.isSubmit = false;
            this.createChat()
          }
  
        }, initFile.type, 0.75);
      };
      
      reader.onerror = error => console.log(error);
    };
  }

  createChat() {
    this.isSubmit = true;
    let createData = JSON.parse(JSON.stringify(this.form.value))
    if (this.filesArray.length != 0) {
      delete createData.text
    }
    console.log("createData1", createData)

    if (!!createData.planned_completed_at) {
      createData.planned_completed_at = moment(createData.planned_completed_at).format("X");
    } else {
      delete createData.planned_completed_at;
    }
    delete createData.date;
    console.log("createData2", createData)
    this.chatService.createChat(createData, this.data.company.id).pipe(
      switchMap(res => {
        if (this.filesArray.length > 0) {
          return this.putFile(res).pipe(
            tap(el => console.log("CONCAT TAP", el)),
            map(() => res)
          )
        } else {
          return of(res)
        }
      }),
      take(1)
    ).subscribe(resp => {
      console.log(resp);
      this.isSubmit = false;
      this.dialogRef.close({event: "create", data: resp})
    })
  }

  deskInput(e) {
    if (e.dataTransfer && e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      console.log('prevent');
      e.preventDefault();
      e.stopPropagation();
    }
    this.mouseUp()
  }
  
  mouseUp(e?) {
    let selection = document.getSelection();
    let range = selection.getRangeAt(0);
    
    let clientRects = range.getClientRects();

    let posArr = [];
    Object.keys(clientRects).forEach(key => {
      posArr.push({x: clientRects[key].x + clientRects[key].width/2 , y: clientRects[key].y - clientRects[key].height/2})
    });

    let pos = {
      x: Math.round(posArr.map(n => n.x).reduce((a, b) => +a + +b, 0) / posArr.length),
      y: Math.round(posArr.map(n => n.y).reduce((a, b) => +a + +b, 0) / posArr.length),
    }


    this.selectedText = {
      is_selected: selection.toString().trim().length > 0,
      position: pos,
      state: {
        bold: document.queryCommandState('bold'),
        italic: document.queryCommandState('italic'),
        underline: document.queryCommandState('underline'),
        strikeThrough: document.queryCommandState('strikeThrough'),
        insertOrderedList: document.queryCommandState('insertOrderedList'),
        insertUnorderedList: document.queryCommandState('insertUnorderedList'),
        createLink: this.isLink(),
        link: this.isLink() ? this.getLink() : "",
        linkNode: this.isLink() ? this.getLinkNode() : null
      }
    }
  }

  formatText(key) {
    if (key == 'createLink') {

      const from = this.selectedText.state.linkNode;
      console.log(this.selectedText);
      console.log("!!from", from);
      if (!!from) {
        let sel = window.getSelection();
        const range = document.createRange();
        var linkURL = prompt('Enter a URL:', this.selectedText.state.link ? this.selectedText.state.link : '');
        // document.getSelection().removeAllRanges();
        // from.childNodes.forEach(element => {
        //   range.selectNode(element);
        // });
        sel.removeAllRanges();
        range.selectNodeContents(from)
        
        sel.addRange(range);
        var sText = sel;
        
        console.log('sText', sText);
        console.log('linkURL', linkURL);
        if (linkURL !== null && linkURL != '') {
          document.execCommand('insertHTML', false, '<a href="' + linkURL + '" target="_blank">' + sText + '</a>');
        } else {
          let sel2 = window.getSelection();
          let range2 = document.createRange();
          sel2.removeAllRanges();
          range2.selectNodeContents(from)
          sel2.addRange(range);
        }
        console.log("!!from", from);
      } else {
        var linkURL = prompt('Enter a URL:', '');
        var sText = document.getSelection();
        if (linkURL !== null && linkURL != '') {
          document.execCommand('insertHTML', false, '<a href="' + linkURL + '" target="_blank">' + sText + '</a>');
        }
      }
      this.mouseUp()
    } else {
      document.execCommand(key, false, null);
      this.mouseUp()
    }
  }

  getLink() {
    const selection = document.getSelection()
    const startA = selection.anchorNode.parentElement.getAttribute('href')
    const endA = selection.focusNode.parentElement.getAttribute('href')
    
    const altStartA = selection.anchorNode
    const altEndA = selection.anchorNode

    if (altStartA.nodeName === 'A' || altEndA.nodeName === 'A') {
      return altStartA['href'] || altEndA['href']
    }
    return startA || endA
  }

  
  goTolink(e) {
    console.log(e);
    console.log(this.selectedText);
    if (this.selectedText && this.selectedText.state && this.selectedText.state.link) {
      window.open(this.selectedText.state.link, '_blank')
    }
  }

  getLinkNode() {
    const selection = document.getSelection()
    const startA = selection.anchorNode.parentElement
    const endA = selection.focusNode.parentElement
    const altStartA = selection.anchorNode
    const altEndA = selection.anchorNode

    if (altStartA.nodeName === 'A' || altEndA.nodeName === 'A') {
      return altStartA || altEndA
    }
    return startA || endA
  }

  isLink () {
    const selection = document.getSelection()
    const startA = selection.anchorNode.parentElement.tagName === 'A'
    const endA = selection.focusNode.parentElement.tagName === 'A'
    const altStartA = selection.anchorNode.nodeName === 'A'
    const altEndA = selection.anchorNode.nodeName === 'A'

    // console.log("isLink selection", selection);
    // console.log("isLink startA", selection.anchorNode.parentElement);
    // console.log("isLink endA", selection.focusNode.parentElement);
    return startA || endA || altStartA || altEndA
  }

  onPasteDeskInput(e) {
    console.log("onPasteDeskInput", e);
    e.preventDefault()
    var text = e.clipboardData.getData('text/plain')
    document.execCommand('insertText', false, text)
    this.mouseUp()
  }

  onPaste(e) {
  // reports_copy
    // console.log('event$', e);
    // console.log("text/html", e.clipboardData.getData('text/html'));
    // console.log("text/html", e.clipboardData.getData('text/html') == '');
    // console.log("text/html", e.clipboardData.getData('text/html') == '<empty string>');
    if (e && e.clipboardData && ((!!e.clipboardData.getData('text/html') && e.clipboardData.getData('text/html').indexOf('on_reports_copy') == -1) || e.clipboardData.getData('text/html') == '')) {
      console.log("If PASTE")
      e.preventDefault();
      const text = e.clipboardData.getData('text/plain').replaceAll("\n","<br>");
      document.execCommand('insertHTML', false, text);
    }
    this.mouseUp()
  }

  onCopy(event) {
    const selection = document.getSelection();
    if (selection) {
      const range = selection.getRangeAt(0);
      const clonedContent = range.cloneContents();
  
      const wrapperDiv = document.createElement('div');
      const span = document.createElement('span');
      span.classList.add('on_reports_copy')
      wrapperDiv.appendChild(clonedContent);
      wrapperDiv.appendChild(span);
  
      event.clipboardData?.setData('text/html', wrapperDiv.innerHTML);
      event.clipboardData?.setData('text/plain', selection?.toString().replace(/<br>|<BR>|<\/br>|<\/BR>/gi, '\n'));
      
      event.preventDefault();
    }
  }

  pasteHandler(e) {
    console.log("create Chat component", e)
    // added && e.clipboardData.files && e.clipboardData.files.length
    if (this.uploadLimit) {
      this.layoutService.showSnackBar({name: ''}, marker("This company has exceeded the file upload limit."), SnackBarItem)
      return
    }

    if (!e.clipboardData) {
      return
    };

    console.log(e.clipboardData.items);
    
    // e.preventDefault();
    let items = e.clipboardData.items;
    let files = []
    if (items) {
      for (var i = 0; i < items.length; i++) {
        var blob = items[i].getAsFile();
        if (!!blob) {
          files.push(blob);
        }
      }
    }

    if (files.length) {
      files.forEach(el => {
        console.log("files el", el);
        if (el.type.indexOf("image") != -1 || mimeTypes.getType(el.name).indexOf("image") != -1) {
          el.is_compress = true;
          el.dontCompress = false;
        } else {
          el.is_compress = false;
          el.dontCompress = true;
        }
        const reader = new FileReader();
        reader.onload = () => {
          console.log("Reader result", reader.result as string);
          el.src = reader.result as string;
        }
        reader.readAsDataURL(el);
      })
      this.filesArray.push(...files);
    }
  }

  toggleCompress(file) {
    file.is_compress = !file.is_compress;
  }
  
  checkIsManager(task, company, _user) {
    return task && company && task.company_id == company.employees[0].company_id && (task?.managers.filter(x => x.user_id == _user.id).length > 0 || task?.group?.managers.filter(x => x.user_id == _user.id).length > 0 || company?.permissions.includes('admin') || company?.permissions.includes('manager')); 
  }

  deleteFile(ind) {
    this.filesArray.splice(ind, 1)
  }

  @HostListener('window:paste',['$event'])
  onKeyPress($event) {
    this.pasteHandler($event)
  }
  // files end

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

}
