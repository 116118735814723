<h2 mat-dialog-title class="dialog_title">
    <span (click)="log()">
        {{ "Speech to Text" | translate }}
    </span>
    <div class="d-f-c">
        <button mat-icon-button (click)="refresh()">
            <mat-icon [ngClass]="{'rotateble': !!isRefreshed}">refresh</mat-icon>
        </button>
        <button mat-icon-button (click)="close()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
</h2>
<mat-dialog-content class="mat-typography">
    <div class="container">
        <form [formGroup]="form">
            <mat-form-field appearance="standard" class="full_width_form" [ngClass]="{'not_active_field': !!form.get('detect').value}">
                <mat-label>{{ "Language" | translate }}</mat-label>
                <mat-select [disableOptionCentering]="true" selectDir [multiple]="false" formControlName="language">
                    <mat-select-trigger class="multi_users">
                        <ng-container *ngIf="form.get('language').value">
                            <span>{{ getLangByVal(form.get('language').value)?.value }}</span>
                        </ng-container>
                    </mat-select-trigger>
                    <mat-option>
                        <ngx-mat-select-search 
                        placeholderLabel="{{ 'Search' | translate }}" 
                        noEntriesFoundLabel="{{ 'no matching language found' | translate }}"
                        [formControl]="langsControl"></ngx-mat-select-search>
                    </mat-option>
                    <ng-container *ngFor="let item of langs$ | async">
                        <mat-option *ngIf="!!item.iso_value" [value]="item.iso_value">
                            <span>{{ item.value }}</span>
                        </mat-option>
                    </ng-container>
                </mat-select>
            </mat-form-field>

            <div class="d-f-c" style="height: 40px; flex-shrink: 0;">
                <mat-checkbox color="primary" formControlName="detect">{{"Detect Language" | translate}}</mat-checkbox>
            </div>

            <button style="flex-shrink: 0;" (click)="transcribeFile()" mat-raised-button cdkFocusInitial color="primary">
                {{ "Speech to Text" | translate }}
            </button>
        </form>
        <div #tableContainer class="example-container" [appDeviceClass]="'custom-scroll custom-scroll-t'">
            <table mat-table [dataSource]="reqs" class="centred_cols" multiTemplateDataRows>
                <!-- ["Date", "Order", "Operation type", "Text"] -->
                <ng-container matColumnDef="Date">
                    <th mat-header-cell *matHeaderCellDef> {{ "Created at" | translate }} </th>
                    <td mat-cell *matCellDef="let element">
                        <ng-container *ngIf="element?.created_at > today && element?.created_at < todayEnd; else createdAtTempl">
                            <span #tooltip="matTooltip"
                            matTooltip="{{ element?.created_at*1000 | date:'MMM d, y':'':activeLang | yearPipe }}"
                            [matTooltipPosition]="'above'">
                            {{ element?.created_at*1000 | date:'hh:mm a':'':activeLang }}
                            </span>
                        </ng-container>
                        <ng-template #createdAtTempl>
                            <span *ngIf="element?.created_at && element?.created_at > 30000" #tooltip="matTooltip"
                            matTooltip="{{ element?.created_at*1000 | date:'hh:mm a':'':activeLang | yearPipe }}"
                            [matTooltipPosition]="'above'">
                            {{ element?.created_at*1000 | date:'MM.dd.yy':'': activeLang }}
                            </span>
                        </ng-template>
                    </td>
                </ng-container>

                <ng-container matColumnDef="Status">
                    <th mat-header-cell *matHeaderCellDef> {{ "Status" | translate }} </th>
                    <td mat-cell *matCellDef="let element">
                        <span *ngIf="!!element.is_done">{{"Done" | translate}}</span>
                        <span *ngIf="!!element.is_error">{{"Error" | translate}}</span>
                        <span *ngIf="!!element.is_new">{{"New" | translate}}</span>
                        <span *ngIf="!!element.is_processing">{{"Processing" | translate}}</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="Language">
                    <th mat-header-cell *matHeaderCellDef> {{ "Language" | translate }} </th>
                    <td mat-cell *matCellDef="let element">
                        <span>{{element?.language}}</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="Costs">
                    <th mat-header-cell *matHeaderCellDef> {{ "Costs" | translate }} </th>
                    <td mat-cell *matCellDef="let element">
                        <span>{{element?.price_unit}}</span>
                    </td>
                </ng-container>
                    
                <ng-container matColumnDef="expandedDetail">
                    <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
            
                    <div class="row student-element-detail" [@detailExpand]="element.isExpanded ? 'expanded' : 'collapsed'">

                        <ng-container *ngIf="!!element.is_error">
                            <b style="color: rgb(161, 31, 31);">{{element?.error_message}}</b>
                            <p class="text" style="color: rgb(230, 92, 92);">{{element?.debug_message}}</p>
                        </ng-container>
                        
                        <p class="text">
                            {{element?.transcription_plain}}
                        </p>
                    </div>
                    </td>
                </ng-container>
            
                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="student-element-row cp"
                [class.student-expanded-row]="element.isExpanded"
                (click)="rowClick(element)"></tr>
    
                <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="student-detail-row"></tr>
            </table>
        </div>
    </div>
</mat-dialog-content>