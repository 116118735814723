import { CdkDragEnter, CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { MatHorizontalStepper } from '@angular/material/stepper';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import * as moment from 'moment';
import { fromEvent, of, ReplaySubject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { BaseClass } from 'src/app/shared/models/base-class';
import { GlobalDataService } from 'src/app/shared/services/common/global-data.service';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { MembersService } from 'src/app/shared/services/rest/members.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { OpenTaskComponent } from '../../workspace-pages/cases/dialogs/open-task/open-task.component';
import { MY_FORMATS } from '../task-profile-add/task-profile-add.component';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';

@Component({
  selector: 'app-partner-create-task',
  templateUrl: './partner-create-task.component.html',
  styleUrls: ['./partner-create-task.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class PartnerCreateTaskComponent extends BaseClass implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('stepper') stepper: MatHorizontalStepper;
  
  @ViewChild('consistPlaceholder') consistPlaceholder: any;
  @ViewChild('partPlaceholder') partPlaceholder: any;

  @ViewChild('consistDragPlaceholder') consistDragPlaceholder: ElementRef;
  @ViewChild('partDragPlaceholder') partDragPlaceholder: ElementRef;

  @ViewChild('relatedPlaceholder') relatedPlaceholder: any;
  @ViewChild('relatedDragPlaceholder') relatedDragPlaceholder: ElementRef;

  public target: CdkDropList;
  public targetIndex: number;
  public source: CdkDropList;
  public sourceIndex: number;
  
  public form: FormGroup;
  public operations: any;
  public pagination: any;
  public groups: any;
  public statuses: any;
  public statuses$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public statusesMoreControl: FormControl = new FormControl();
  public operationStatuses: any;
  public numberRegEx = /\d*\.?\d{1,2}/;
  public isShowPrice: boolean = false;
  public isFormChange: boolean = false;
  public is_mobile: boolean = false;
  public isSearch: boolean = false;
  public submited: boolean = false;
  public isSubmit: boolean = false;
  public showTypes: boolean = false;
  public relations: boolean = false;
  public showGroups: boolean = false;
  public showStatuses: boolean = false;
  public showOps: boolean = false;
  public submitedWork: boolean = false;
  public tasks: any[] = [];
  public page: number = 1;
  public selectedIndex: number = 0;
  public searchValue: any = '';
  public currencyList: any = '';
  public savedTasks: any[] = [];
  @ViewChild('contextMenu') contextMenu: TemplateRef<any>;
  overlayRef: OverlayRef | null;
  public backContextSub: Subscription;
  public origin = window.location.origin;
  
  public tasks$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public groupMoreControl: FormControl = new FormControl();
  public groups$: ReplaySubject<any> = new ReplaySubject<any>(1);
  
  public operationMoreControl: FormControl = new FormControl();
  public operations$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public typeOfSearchControl: FormControl = new FormControl('Everywhere');
  public consistOfControl: FormControl = new FormControl();
  public partOfControl: FormControl = new FormControl();
  public relativeControl: FormControl = new FormControl();

  public separatorKeysCodes: number[] = [ENTER, COMMA];
  public groupOfSearchControl: FormControl = new FormControl('');
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private taskService: TaskService,
    private companyService: CompanyService,
    private globalDataService: GlobalDataService,
    public dialogRef: MatDialogRef<PartnerCreateTaskComponent>,
    private layoutService: LayoutService,
    private dialog: MatDialog,
    public overlay: Overlay,
    public viewContainerRef: ViewContainerRef,
    private sm: StorageManagerService,
    private membersService: MembersService,
    private languageService: LanguageService,
    private _adapter: DateAdapter<any>,
  ) { super() }

  ngOnInit(): void {
    this.dialogRef.addPanelClass("create_task_by_manager_modal")
    console.log("PartnerCreateTaskComponent", this.data)
    this.form = this.fb.group({
      company_id: this.data.partner.id,
      name: ['', Validators.required],
      status_id: ['', Validators.required],
      group_id: '',
      task_operation: this.fb.group({
        operation_id: ['', Validators.required],
        status_id: ['', Validators.required],
        employee_comment: '',
      }),
      consist_of_task_id: this.fb.group({
        add: [[]]
      }),
      part_of_task_id: this.fb.group({
        add: [[]]
      }),
      related_task_id: this.fb.group({
        add: [[]]
      })
    })

    this.checkIsMobile();

    this.attachSubscriptions(
      this.groupMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchGroups(resp))
    )

    this.attachSubscriptions(
      this.operationMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchOperations(resp))
    )


    this.getTaskStatus();
    this.getLangData();
    this.getCurrencyList();

    let formTO = this.form.controls.task_operation as FormGroup;
    

    if (this.data.company.rate_target != '0.0000') {
      formTO.addControl("rate", this.fb.control('', Validators.pattern(this.numberRegEx)))
    } else {
      formTO.addControl("price", this.fb.control('', Validators.pattern(this.numberRegEx)))
    }
    
    this.attachSubscriptions(
      this.form.valueChanges.subscribe(() => this.isFormChange = true)
    )

    this.attachSubscriptions(
      this.consistOfControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchTasks(resp, this.typeOfSearchControl.value))
    )

    this.attachSubscriptions(
      this.partOfControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchTasks(resp, this.typeOfSearchControl.value))
    )

    this.attachSubscriptions(
      this.relativeControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchTasks(resp, this.typeOfSearchControl.value))
    )

    this.attachSubscriptions(
      this.statusesMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchStatuses(resp))
    )

    this.attachSubscriptions(
      this.typeOfSearchControl.valueChanges.subscribe((resp) => {
        this.tasks = []
        this.tasks$.next(this.tasks.slice())
        this.page = 1;
        this.pagination = undefined
        this.getTasks(this.page, {group_id: ['Everywhere', 'Custom ID', 'Task Name'].includes(resp) ? this.groupOfSearchControl.value : 0})
      })
    )

    this.attachSubscriptions(
      this.groupOfSearchControl.valueChanges.subscribe((resp) => {
        this.tasks = []
        this.tasks$.next(this.tasks.slice())
        this.page = 1;
        this.pagination = undefined
        this.getTasks(this.page, {group_id: ['Everywhere', 'Custom ID', 'Task Name'].includes(this.typeOfSearchControl.value) ? resp : 0})
      })
    )

    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        if (this.isFormChange) {
          this.layoutService.openBottomSheet(this.dialogRef);
        } else {
          this.close();
        }
      })
    )

    this.attachSubscriptions(
      this.companyService.getGroupsPartnerCompany(this.data.company.id, this.data.partner.id).subscribe(resp => {
        this.groups = resp;
        this.groups$.next(this.groups.slice());
      })
    )
    
    const op = this.form.get('task_operation') as FormGroup;

    this.getOperations();
    this.getOperationsStatus();

    if (this.data.partner) {

      this.attachSubscriptions(
        this.companyService.getInvites().subscribe(result => {
          if (!!result.find(el => el.company_id == this.data.partner) && +result.find(el => el.company_id == this.data.partner).rate_target != 0) {
            this.data.company['rate_target'] = result.find(el => el.company_id == this.data.partner).rate_target;
          } else {
            op.addControl('price', this.fb.control('0', [Validators.required, Validators.pattern(this.numberRegEx)]))
            this.isShowPrice = true;
          }
        })
      )
    } 

    this.getTasks(this.page);
  }

  onSearchOperations(resp) {
    if (!this.operations) {
      return;
    }

    if (!resp) {
      this.operations$.next(this.operations.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.operations$.next(
      this.operations.filter(z => (z.name.toLowerCase().indexOf(resp) > -1))
    );
  }

  onSearchGroups(resp) {
    if (!this.groups) {
      return;
    }

    if (!resp) {
      this.groups$.next(this.groups.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.groups$.next(
      this.groups.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }


  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData().subscribe(resp => {
        this._adapter.setLocale(resp.active);
      })
    )
  }

  showMoreOps() {
    this.showOps = true;
  }
  showMoreStatuses() {
    this.showStatuses = true;
  }

  selectWorkType(type) {
    this.form.get('task_operation').get('operation_id').setValue(!!type.id ? type.id : type.value)
  }

  selectStatus(status) {
    console.log(status.id)
    let formTO = this.form.controls.task_operation as FormGroup;
    
    if (status.id == 3) {
      formTO.addControl("completed_at", this.fb.control(''));
    } else if (!!this.form.get('completed_at')) {
      formTO.removeControl("completed_at");
    }
    formTO.updateValueAndValidity()
    this.form.get('task_operation').get('status_id').setValue(status.id)
  }

  selectGroup(group) {
    this.form.patchValue({
      group_id: !!group.id ? group.id : group.value
    })

    this.groupOfSearchControl.patchValue(!!group.id ? group.id : group.value)
  }

  selected(e, input: HTMLInputElement, key) {
    console.log("selected", e)
    let addArr = this.form.get(key).get('add').value.slice();

    if (!addArr.includes(e.option.value)) {
      addArr.push(e.option.value)
      if (this.tasks.find(x => x.id == e.option.value) && this.savedTasks.filter(u => u.id == e.option.value).length == 0) {
        this.savedTasks.push(this.tasks.find(x => x.id == e.option.value))
      }
    }
    this.form.get(key).patchValue({
      add: addArr
    })

    input.value = ''

    this.tasks$.next(this.tasks.slice());
  }

  showMoreGroups() {
    this.showGroups = true;
  }

  onSearchStatuses(resp) {
    if (!this.statuses) {
      return;
    }

    if (!resp) {
      this.statuses$.next(this.statuses.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.statuses$.next(
      this.statuses.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  selectType(type) {
    this.form.patchValue({
      status_id: !!type.id ? type.id : type.value
    })
  }

  openRelations() {
    this.relations = !this.relations;
  }

  showMoreTypes() {
    this.showTypes = true;
  }

  onScroll(type) {
    console.log("onScroll", type, this[type].value || '');
    this.onSearchTasks(this[type].value || '', this.typeOfSearchControl.value, true)
  }

  onSearchTasks(resp, query, notDel?) {
    
    resp = resp && typeof resp == 'string' ? resp.toLowerCase() : (resp || '');
    console.log('onSearchTasks', resp, query, notDel)

    let filter:any = {
      group_id: ['Everywhere', 'Custom ID', 'Task Name'].includes(this.typeOfSearchControl.value) ? this.groupOfSearchControl.value : 0
    }
    if (!notDel) {
      this.tasks = []
      this.tasks$.next(this.tasks.slice())
      this.page = 1;
      this.pagination = undefined
    }
    // filter the banks
    switch (query) {
      case 'Everywhere':
        filter.q = resp;
        break;
      case 'Task Name':
        filter.q_task_name = resp;
        break;
      case 'Custom ID':
        filter.custom_id = resp;
        break;
      case 'Internal ID':
        filter.internal_id = resp;
        break;
      case 'Global ID':
        filter.id = resp;
        break;
    }

    this.getTasks(this.page, filter)

  }

  getTaskStatus() {
    this.attachSubscriptions(
      this.taskService.getTaskPartnerStatuses(this.data.company.id, this.data.partner.id).subscribe(resp => {
        this.statuses = resp;
        this.statuses$.next(this.statuses.slice());
      })
    )
  }

  goToAddWork() {
    if (!this.form.get('name').valid || !this.form.get('status_id').valid) {
      this.submited = true;
    } else {
      this.stepper.next()
    }
  }

  selectionChange($event:StepperSelectionEvent):void {
    this.selectedIndex = $event.selectedIndex;
  }

  getTasks(page, filter:any = null) {
    console.log("getTasks filter", filter)
    this.attachSubscriptions(
      this.taskService.getTasksSelect(page, this.data.company_id, filter).pipe(
        tap(el => {
          this.pagination = {
            'pageCount': el.headers.get('x-pagination-page-count'),
            'perPage': el.headers.get('x-pagination-per-page'),
            'totalCount': el.headers.get('x-pagination-total-count'),
            'currentPage': el.headers.get('x-pagination-current-page'),
          }
        })
      ).subscribe(resp => {
        this.tasks.push(...resp.body)
        console.log("getTasks res" + this.page, this.tasks)
        this.page = this.page + 1; 
        this.tasks$.next(this.tasks.slice())
      })
    )
  }

  getOperations() {
    this.attachSubscriptions(
      this.taskService.getPartnerOperations(this.data.company.id, this.data.partner.id).subscribe(resp => {
        this.operations = resp;
        this.operations$.next(this.operations.slice())
      })
    )
  }

  getOperationsStatus() {
    this.attachSubscriptions(
      this.taskService.getOperationsStatus().subscribe(resp => {
        let statusesData = resp.slice()
        this.operationStatuses = statusesData.filter(el => el.is_lock == 0);
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false});
  }

  findTask(id) {
    if (this.savedTasks.find(x => x.id == id)) {
      return this.savedTasks.find(x => x.id == id)
    }
  }

  onRemoved(value: string, key) {
    const values = this.form.get(key).get('add').value as string[];
    this.removeFirst(values, value);
    this.form.get(key).get('add').setValue(values); // To trigger change detection

    console.log(this.form.value)
  }

  private removeFirst<T>(array: T[], toRemove: T): void {
    const index = array.indexOf(toRemove);
    if (index !== -1) {
      array.splice(index, 1);
    }
  }

  getCurrency(id) {
    if (!!this.currencyList) {
      return this.currencyList.find(el => el.id == id)
    }
  }

  getCurrencyList() {
    this.attachSubscriptions(
      this.globalDataService.getCurrencies().subscribe(resp => {
        console.log("getCurrencyList", resp)
        this.currencyList = resp.slice();
      })
    )
    // this.attachSubscriptions(
    //   this.companyService.getCurrencyList().subscribe(resp => {
    //     console.log("getCurrencyList", resp);
    //     this.currencyList = resp
    //   })
    // )
  }


  submitForm() {
    if (!this.form.valid) {
      this.submitedWork = true;
      console.log(this.form);
      return
    }
    this.isSubmit = true;
    if (!!!this.form.value.group_id) {
      delete this.form.value.group_id
    }
    if (String(this.form.value['task_operation']['rate']).indexOf(",") != -1) {
      this.form.value['task_operation']['rate'] = this.form.value['task_operation']['rate'].replace(",", ".");
    }
    if (String(this.form.value['task_operation']['price']).indexOf(",") != -1) {
      this.form.value['task_operation']['price'] = this.form.value['task_operation']['price'].replace(",", ".");
    }

    if (!!this.form.value.task_operation.completed_at && !!this.form.value.task_operation.completed_at._d) {
      this.form.value.task_operation.completed_at = moment(this.form.value.task_operation.completed_at._d).format("X");
    } else {
      delete this.form.value.task_operation.completed_at;
    }
    console.log(this.form);
    this.attachSubscriptions(
      this.taskService.createTask(this.data.company.id, this.form.value).pipe(
        switchMap(res => {
          if ( this.form.get('consist_of_task_id').get('add').value.length > 0 || this.form.get('part_of_task_id').get('add').value.length > 0 || this.form.get('related_task_id').get('add').value.length > 0 ) {
            let sortData = [];

            this.form.get('consist_of_task_id').get('add').value.forEach((el, i) => {
              sortData.push(
                {
                  "path": '/api/task-partition/register/',
                  "query": {'company_id': this.data.company_id},
                  "method": "POST",
                  "body": {
                    [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
                    company_id: this.data.company_id,
                    consist_of_task_id: res.id,
                    part_of_task_id: el,
                    consist_of_order: i,
                    part_of_order: null
                  }
                }
              )
            });

            this.form.get('part_of_task_id').get('add').value.forEach((el, i) => {
              sortData.push(
                {
                  "path": '/api/task-partition/register/',
                  "query": {'company_id': this.data.company_id},
                  "method": "POST",
                  "body": {
                    [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
                    company_id: this.data.company_id,
                    part_of_task_id: res.id,
                    consist_of_task_id: el,
                    part_of_order: i,
                    consist_of_order: null
                  }
                }
              )
            });

            this.form.get('related_task_id').get('add').value.forEach((el, i) => {
              sortData.push(
                {
                  "path": '/api/task-related/register/',
                  "query": {company_id: this.data.company_id},
                  "method": "POST",
                  "body": {
                    [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
                    company_id: this.data.company_id,
                    task_id: res.id,
                    related_task_id: el,
                    order: i
                  }
                }
              )
            });

            return this.taskService.multiRequest(sortData).pipe(map(() => res))
          } else {
            return of(res)
          }
        }),
        switchMap(res => {
          return this.taskService.getOneTaskPartnerExpand(this.data.company.id, this.data.partner.id, res.id).pipe(
            map(b => b.body[0]),
            switchMap(task => {
              if (task && task.operations[0].partnerEmployees.length > 0) {
                console.log("task", task)
                let memberData:any = {
                  price_currency_id: this.data.company.employees[0].currency_id
                }
                if (this.form.value['task_operation']['rate'] != null && this.form.value['task_operation']['rate'] != '') {
                  memberData.rate = this.form.value['task_operation']['rate']
                }
                if (+this.form.value['task_operation']['price'] != null && this.form.value['task_operation']['price'] != '') {
                  memberData.price = this.form.value['task_operation']['price']
                }
                return this.membersService.editTaskPartnerEmployee(task.operations[0].partnerEmployees[0].id, memberData, this.data.company.id).pipe(map(() => res))
              } else if (task && task.operations[0].partnerCompanies.length > 0) {
                console.log("task", task)
                let memberData:any = {
                  price_currency_id: this.data.company.employees[0].currency_id
                }
                if (this.form.value['task_operation']['rate'] != null && this.form.value['task_operation']['rate'] != '') {
                  memberData.rate = this.form.value['task_operation']['rate']
                }
                if (+this.form.value['task_operation']['price'] != null && this.form.value['task_operation']['price'] != '') {
                  memberData.price = this.form.value['task_operation']['price']
                }
                return this.membersService.editTaskPartner(task.operations[0].partnerCompanies[0].id, memberData, this.data.company.id).pipe(map(() => res))
              } else {
                return of(res)
              }
            })
          )
        })
      ).subscribe(resp => {
        this.isSubmit = false;
        this.layoutService.showSnackBar({name: this.form.value.name}, marker("created!"), SnackBarItem);
        this.openTask(resp);
        this.taskService.newCard$.next({company_id: resp.company_id, task_id: resp.id})
        this.dialogRef.close({event: "Add", data: resp});
      })
    )
  }

  onPartnerImgError(event) {
    event.target.src = this.data.imgRoute+'/assets/img/partner.png'
  }

  checkIsMobile() {
    if (window.innerWidth <= 769) {
      this.is_mobile = true;
    } else {
      this.is_mobile = false;
    }
    this.onResize();
  }

  onResize() {
    this.attachSubscriptions(
      fromEvent(window, "resize").pipe(
        map(() => window.innerWidth)
      ).subscribe((wWidth) => {
          if (wWidth <= 769) {
            this.is_mobile = true;
          } else {
            this.is_mobile = false;
          }
        }
      )
    )
  }


  openTask(task) {
    let taskData:any = {
      task_id: task.id,
      initCompanyId: this.data.company.id
    }
    if (task.hasOwnProperty("operations")) {
      taskData.task = task
    }
    const dialogRef = this.dialog.open(OpenTaskComponent, {
      backdropClass: 'backdrop_under_header',
      panelClass: !this.is_mobile ? ['open_task_dialog', 'show_header'] : 'open_task_dialog',
      autoFocus: false,
      data: taskData
    });
  }

  change() {
    this.companyService.addTask$.next(true);
    this.close();
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

  ngAfterViewInit() {
    let conPhElement = this.consistPlaceholder.nativeElement;

    conPhElement.style.display = 'none';
    conPhElement.parentNode.removeChild(conPhElement);

    let partPhElement = this.partPlaceholder.nativeElement;

    partPhElement.style.display = 'none';
    partPhElement.parentNode.removeChild(partPhElement);

    let relatedPhElement = this.relatedPlaceholder.nativeElement;

    relatedPhElement.style.display = 'none';
    relatedPhElement.parentNode.removeChild(relatedPhElement);
  }

  dropped(papa, ph, arr) {
    if (!this.target) return;

    const parent: HTMLElement = papa;
    const phElement: HTMLElement = ph;
    const phElementIndex = __indexOf(parent.children, phElement);

    phElement.style.display = 'none';
    parent.removeChild(phElement);
    parent.appendChild(phElement);

    parent.insertBefore(
      this.source.element.nativeElement,
      parent.children[this.sourceIndex]
    );

    console.log(this.sourceIndex, ' => ', phElementIndex);

    if (this.sourceIndex != phElementIndex) {
      moveItemInArray(arr, this.sourceIndex, phElementIndex);
    }

    this.target = null;
    this.targetIndex = undefined;
    this.source = null;
    this.sourceIndex = undefined;
  }

  entered({ item, container }: CdkDragEnter, papa, ph) {
    const phElement: HTMLElement = ph;
    const dropElement: HTMLElement = container.element.nativeElement;
    const prevTarget: CdkDropList = this.target;
    const prevTargetIndex: number = this.targetIndex;
    this.target = container;

    const dropElementIsTheSource: boolean = !dropElement.parentNode;
    const prevAndCurrentTargetAreTheSame: boolean = this.target === prevTarget;
    if (dropElementIsTheSource || prevAndCurrentTargetAreTheSame) {
      return;
    }

    this.targetIndex = __indexOf(dropElement.parentNode.children, dropElement);

    if (!this.source) {
      this.source = item.dropContainer;
      this.sourceIndex = __indexOf(
        dropElement.parentNode.children,
        item.dropContainer.element.nativeElement
      );
      const sourceElement: HTMLElement = this.source.element.nativeElement;

      this.fixPhElementStyling(phElement, sourceElement);

      sourceElement.parentNode.removeChild(sourceElement);
    }

    const index: number = prevTargetIndex ?? this.sourceIndex;
    const insertAfter: boolean = index < this.targetIndex;

    papa.insertBefore(
      phElement,
      insertAfter ? dropElement.nextSibling : dropElement
    );
  }

  dragReleased(ph, dragPh) {
    const phElementPositionWasChanged: boolean = !!this.source;
    if (phElementPositionWasChanged) {
      // console.log("dragReleased dragPh", ph, dragPh)
      dragPh.nativeElement.style.transform = 'none';
      dragPh.nativeElement.parentNode.removeChild(
        dragPh.nativeElement
      );
      ph.appendChild(
        dragPh.nativeElement
      );
    }
  }

  private fixPhElementStyling(
    phElement: HTMLElement,
    sourceElement: HTMLElement
  ) {
    phElement.style.width = sourceElement.clientWidth - 6 + 'px';
    phElement.style.height = sourceElement.clientHeight - 6 + 'px';

    const size = Array.from(sourceElement.classList).find((c) =>
      c.startsWith('content-item-c')
    );

    phElement.style.display = '';
    const oldSize = Array.from(phElement.classList).find((c) =>
      c.startsWith('content-item-c')
    );
    if (oldSize) {
      phElement.classList.remove(oldSize);
    }
    if (size) {
      phElement.classList.add(size);
    }
  }

  openContext({ x, y }: MouseEvent, contextData) {
    this.closeContext();
    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo({ x, y })
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        }
      ]);

    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });

    this.overlayRef.attach(new TemplatePortal(this.contextMenu, this.viewContainerRef, {
      $implicit: contextData
    }));
    
    this.backContextSub = fromEvent<MouseEvent>(document, 'click')
      .pipe(
        filter(event => {
          const clickTarget = event.target as HTMLElement;
          return !!this.overlayRef && !this.overlayRef.overlayElement.contains(clickTarget);
        }),
        take(1)
      ).subscribe(() => this.closeContext())

  }

  closeContext() {
    this.backContextSub && this.backContextSub.unsubscribe();
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
  }

  moveTo(contextData, targetControl) {
    console.log(contextData, targetControl)

    const fromValues = contextData.form.get(contextData.control).get('add').value as string[];
    this.removeFirst(fromValues, contextData.id);
    contextData.form.get(contextData.control).get('add').setValue(fromValues); // To trigger change detection

    let toValues = contextData.form.get(targetControl).get('add').value as string[];
    toValues.push(String(contextData.id))
    contextData.form.get(targetControl).get('add').setValue(toValues); // To trigger change detection

    console.log(contextData.form.value)
    this.closeContext()
  }

  remove(contextData) {
    const fromValues = contextData.form.get(contextData.control).get('add').value as string[];
    this.removeFirst(fromValues, contextData.id);
    contextData.form.get(contextData.control).get('add').setValue(fromValues);
    this.closeContext()
  }

  openCard(contextData) {
    this.closeContext()
    let task = this.findTask(contextData.id);
    console.log("task", task)
    if (!task) {
      return
    }
    window.open(`${this.origin}/task/${task.acm}`, '_blank')
  }
}

function __indexOf(collection, node) {
  return Array.prototype.indexOf.call(collection, node);
}
