import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseClass } from 'src/app/shared/models/base-class';
import { BoardService } from 'src/app/shared/services/rest/board.service';

@Component({
  selector: 'app-mob-status-filter',
  templateUrl: './mob-status-filter.component.html',
  styleUrls: ['./mob-status-filter.component.scss']
})

export class MobStatusFilterComponent extends BaseClass implements OnInit, OnDestroy {
  public tab = 0;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private boardService: BoardService,
    public dialogRef: MatDialogRef<MobStatusFilterComponent>,
  ) { super() }

  ngOnInit(): void {
    console.log("MobStatusFilterComponent", this.data)

    
    this.attachSubscriptions(
      this.boardService.getBoardTabStream().subscribe(res => {
        this.tab = res
      })
    )

    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        this.close();
      })
    )
  }

  togglePersonal() {
    this.data.filter.patchValue({
      discussion_other_employee_status_id: !!this.data.filter.value.discussion_other_employee_status_id ? 0 : 1
    })
    console.log(this.data.filter.value)
  }

  activeSections() {
    switch (window.location.pathname) {
      case '/dashboard':
        return 'Dashboard'
        break;
      case '/tasks':
        return 'Cards'
        break;
      case '/jobs':
        return 'Jobs'
        break;
      case '/chats':
        return 'Chats'
        break;
      case '/board':
        return 'Board by Date'
        break;
      case "/projects":
        return 'Projects';
        break;
      case '/board-list':
        return 'Board by Order'
        break;
      case '/urls':
        return 'Posts'
        break;
      case '/urls-dashboard':
        return 'Post Dashboards'
        break;
      case '/outlets-dashboard':
        return 'Outlet Dashboards'
        break;
      case '/regular-publications':
        return 'Regular Publications'
        break;
      case '/settings':
        return 'Settings'
        break;
      case '/files':
        return 'Files'
        break;
      default:
        return window.location.pathname.replace('/', '')
        break;
    }
  }

  close() {
    this.dialogRef.removePanelClass('animate__slideInUp');
    this.dialogRef.addPanelClass('animate__slideOutDown');
    setTimeout(()=>{this.dialogRef.close({event: 'close'})}, 300);
  }

  onSubmit() {
    this.dialogRef.removePanelClass('animate__slideInUp');
    this.dialogRef.addPanelClass('animate__slideOutDown');
    setTimeout(()=>{this.dialogRef.close({event: 'apply'})}, 300);
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

}
