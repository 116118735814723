import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpRestService } from '../common/http-rest.service';

@Injectable({
  providedIn: 'root'
})
export class PushNotificationsService {

  constructor(
    private http: HttpRestService,
    private httpClient: HttpClient
    ) { }


    getSubscriptions() {
      return this.http.executeGet<any>(`/api/push-subscription/`, true, {
        params: new HttpParams().set('per-page', '200')
      })
    }
  
    editSubscription(id, data) {
      return this.http.executePost<any>(`/api/push-subscription/${id}/`, data)
    }

    sendNotification(id) {
      return this.http.executePost<any>(`/development/test/push/`, {}, true, {
        params: new HttpParams().set('subscription_id', id)
      })
    }

    getPostNotificationEmployees(company_id, view_discussion_post_id) {
      let filter_params = new HttpParams().set('per-page', '200').set("company_id", company_id).set("view_discussion_post_id", view_discussion_post_id).set("is_user_id", "1")

      filter_params = filter_params.set("expand", "notificationSubscription")

      return this.http.executeGet<any>(`/api/employee/`, true, {
        params: filter_params
      })
    }

    getNotificationEmployees(company_id, view_discussion_id) {
      let filter_params = new HttpParams().set('per-page', '200').set("company_id", company_id).set("view_discussion_id", view_discussion_id).set("is_user_id", "1")

      filter_params = filter_params.set("expand", "notificationSubscription")

      return this.http.executeGet<any>(`/api/employee/`, true, {
        params: filter_params
      })
    }

    getNotificationSubs(company_id, discussion_id, employee_id?) {
      let filter_params = new HttpParams().set('per-page', '200').set("company_id", company_id).set("discussion_id", discussion_id)
      if (employee_id) {
        filter_params = filter_params.set("employee_id", employee_id)
      }
      filter_params = filter_params.set("expand", "company,employee,discussion")

      return this.http.executeGet<any>(`/api/notification-subscription/`, true, {
        params: filter_params
      })
    }

    getNotificationSub(id) {
      return this.http.executeGet<any>(`/api/notification-subscription/${id}/`)
    }
  
    addNotificationSubs(data, company_id) {
      return this.http.executePost<any>(`/api/notification-subscription/`, data, true, {
        params: new HttpParams().set("company_id", company_id)
      })
    }

    editNotificationSub(id, data, company_id) {
      return this.http.executePost<any>(`/api/notification-subscription/${id}/`, data, true, {
        params: new HttpParams().set("company_id", company_id)
      })
    }
    
    deleteNotificationSub(id, company_id) {
      return this.http.executeDelete<any>(`/api/notification-subscription/${id}/`, true, {
        params: new HttpParams().set("company_id", company_id)
      })
    }

}
