import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { ReplaySubject, forkJoin } from 'rxjs';
import { debounceTime, last, map, switchMap, tap } from 'rxjs/operators';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { MembersService } from 'src/app/shared/services/rest/members.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';

@Component({
  selector: 'app-edit-executor',
  templateUrl: './edit-executor.component.html',
  styleUrls: ['./edit-executor.component.scss']
})
export class EditExecutorComponent extends BaseClass implements OnInit, OnDestroy {
  public employees: any;
  public employees$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public employeesControl: FormControl = new FormControl();
  public form: FormGroup;
  public isFormChange: boolean = false;
  public isSubmit: boolean = false;

  constructor(
    private taskService: TaskService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<EditExecutorComponent>,
    private fb: FormBuilder,
    private layoutService: LayoutService,
    private membersService: MembersService,
  ) { super() }

  ngOnInit(): void {
    console.log("EditExecutorComponent", this.data)
    this.getEmployees();
    
    this.form = this.fb.group({
      company_id: this.data.employee.company_id,
      task_id: this.data.employee.task_id,
      task_operation_id: this.data.employee.task_operation_id,
      discussion_id: this.data.employee.discussion_id,
      status_id: !!this.data.employee.employeeStatus ? this.data.employee.employeeStatus.status_id : this.data.employee.status_id,
      price: this.data.employee.price,
      rate: this.data.employee.rate,
      is_manager: this.data.employee.is_manager,
      is_price_manager: this.data.employee.is_price_manager,
      employee_id: [this.data.employee.employee_id, Validators.required],
    })

    this.attachSubscriptions(
      this.form.valueChanges.subscribe(() => this.isFormChange = true)
    )
    
    this.attachSubscriptions(
      this.employeesControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchEmoloyees(resp))
    )

    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        if (this.isFormChange) {
          this.layoutService.openBottomSheet(this.dialogRef);
        } else {
          this.close();
        }
      })
    )
  }

  onSearchEmoloyees(resp) {
    if (!this.employees) {
      return;
    }

    if (!resp) {
      this.employees$.next(this.employees.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.employees$.next(
      this.employees.filter(z => z.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  getEmployees() {
    this.attachSubscriptions(
      this.taskService.getEmployeesDyn('1', this.data.company.id, null, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }
  
          return forkJoin(arr.map(x => this.taskService.getEmployeesDyn(x, this.data.company.id, null, '200').pipe(map(u => u.body)))).pipe(
            last(),
            tap(values => {
              let conValues = [].concat(...values)
              this.employees = conValues;
              this.employees$.next(this.employees.slice())
            }),
          )
        }),
      ).subscribe(resp => {
        console.log("getEmployees resp", resp);
        console.log("getEmployees", this.employees);
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  onSubmit() {
    if (!this.data.company.permissions.includes('owner') && !this.data.company.permissions.includes('admin')) {
      this.layoutService.showSnackBar({name: ''}, marker("This logic is not supported by your permission level. To do this action, you must have delete rights. Therefore, in this case, you need. appoint a new executor. And the current one can be deleted by an administrator with delete rights."), SnackBarItem)
    } else {
      this.isSubmit = true;
      if (this.data.task.company_id == this.data.company.id) {
        this.attachSubscriptions(
          this.membersService.addMember(this.form.value, this.data.company.id).pipe(
            switchMap(x => this.membersService.deleteMember(this.data.employee.id, this.data.employee.company_id).pipe(map(() => x)))
          ).subscribe(resp => {
            console.log(resp);
            this.isSubmit = false
            resp.employee = this.employees.find(x => x.id == resp.employee_id)
            this.dialogRef.close({event: "update", data: resp})
          },
          error => {
            this.data.showError()
            this.dialogRef.close({event: "update", data: {}})
          })
        )
      } else {
        let formData = {...this.form.value}
        formData.partner_company_id = this.data.company.id;
        formData.company_id = this.data.task.company_id;
        formData.partner_employee_id = formData.employee_id;

        delete formData.employee_id;

        this.attachSubscriptions(
          this.membersService.addTaskPartnerEmployee(formData, this.data.company.id).pipe(
            switchMap(x => this.membersService.deleteTaskPartnerEmployee(this.data.employee.id, this.data.employee.company_id).pipe(map(() => x)))
          ).subscribe(
            resp => {
              console.log(resp);
              this.isSubmit = false
              resp.employee = this.employees.find(x => x.id == resp.employee_id)
              this.dialogRef.close({event: "update", data: resp})
            },
            error => {
              this.data.showError()
              this.dialogRef.close({event: "update", data: {}})
            }
          )
        )
      }
    }
  }

  // if (this.data.task.company_id == this.data.company.id) {
  //   if (!!this.form.value.partner_company_id) {

  //     let partnerData = {...this.form.value}
  //     delete partnerData.status_id;
  //     delete partnerData.employee_id;

  //     this.membersService.addTaskPartner(partnerData, this.data.company.id).subscribe(resp => {
  //       this.isSubmit = false;
  //       this.dialogRef.close({event: "add", data: resp})
  //     })
  //   } else {
  //     let emplData = {...this.form.value}
  //     if (emplData.partner_company_id) {
  //       delete emplData.partner_company_id;
  //     }
  //     this.attachSubscriptions(
  //       this.membersService.addMember(emplData, this.data.company.id).subscribe(resp => {
  //         this.isSubmit = false;
  //         this.dialogRef.close({event: "add", data: resp})
  //       })
  //     )
  //   }
  // } else {
  //   let emplData = {...this.form.value}
  //   emplData.partner_company_id = emplData.company_id;
  //   emplData.company_id = this.data.task.company_id;
  //   emplData.partner_employee_id = emplData.employee_id;

  //   delete emplData.employee_id;

  //   this.attachSubscriptions(
  //     this.membersService.addTaskPartnerEmployee(emplData, this.data.company.id).subscribe(resp => {
  //       console.log("resp addTaskPartnerEmployee", resp)
  //       this.isSubmit = false;
  //       this.dialogRef.close({event: "add", data: resp})
  //     })
  //   )
  // }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

}
