export const prioritys = [
    {
        id: 1000,
        name: "Urgent"
    },
    {
        id: 900,
        name: "High"
    },
    {
        id: 800,
        name: "Middle"
    },
    {
        id: 700,
        name: "Low"
    },
    {
        id: 0,
        name: "No"
    }
]