<h2 mat-dialog-title class="dialog_title">
    <span (click)="log()">
        {{ "Compilations wizard" | translate }}
    </span>
    <button mat-icon-button class="ml-1" (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h2>
<mat-dialog-content class="mat-typography">
    <div class="compilations">
        <form [formGroup]="form">
            <div class="form_line">
                <span class="form_text">
                    {{"First card" | translate}}
                </span>

                <button *ngIf="!selectedTask" (click)="setEditCard()" class="ml-1" mat-raised-button color="primary">{{"Select" | translate}}</button>
                <button *ngIf="!!selectedTask" (click)="setEditCard()" class="ml-1" mat-icon-button color="primary"><mat-icon>edit</mat-icon></button>
                <div class="connections" *ngIf="!!selectedTask">
                    <div class="cons_wrp">
                        <div class="cons_in">
                            <a class="con con_wiz" [href]="origin + '/task/' + part?.acm" target="_blank" *ngFor="let part of [selectedTask]" (click)="$event.preventDefault(); $event.stopPropagation(); openTask(part)">
                                <div class="con_in" [ngClass]="{'full': part?.previewImgs?.length == 0 && (!part?.avatarFile || !part?.avatarFile?.thumbnail)}">
                                    <div class="d-f-c con_side" [ngStyle]="{'max-width': (!!data.is_shared || !part?.wiz_channels) ? '100%' : 'calc(100% - ' + (part?.wiz_channels?.length*18 + (part?.wiz_channels?.length - 1)*6 + (!!part.group_id ? 150 : 0) + 70) + 'px)'}" [ngClass]="{'full': !(!!part.group_id && !!getGroup(part.group_id))}">
                                        <div class="ids">
                                            <div>{{part.custom_id}}</div>
                                            <div>{{part.internal_id}}</div>
                                        </div>
                                        <div class="con_name text_one_line">
                                            {{part.name}}
                                        </div>
                                    </div>
                                    <div class="d-f-c">
                                        <div class="part_channels_count" *ngIf="part?.wiz_views_count">
                                            <svg width="9" height="10" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0 10V3H2V10H0ZM3.5 10V0H5.5V10H3.5ZM7 10V6H9V10H7Z" fill="#C9C9C9"/>
                                            </svg> 
                                            {{ part?.wiz_views_count | shortNumber }}
                                        </div>
                                        <div class="part_channels">
                                            <ng-container *ngFor="let channel of part?.wiz_channels; let ind = index;">
                                                <div #tippyTemplate class="fm_ch_tooltip">
                                                    <div class="chan_title">
                                                        <div class="chan_name">
                                                            {{channel?.channel?.name}}
                                                        </div>
                                                    </div>
                                                    <div class="chan_cont_name">
                                                        {{channel?.content_name}}
                                                    </div>
                                                    <div class="chan_info">
                                                        <div class="d-f-c">
                                                            <div class="status">{{(getProfileStatus(channel?.content_status_id)?.name + '_profile') | translate}}</div>
                                                            <div class="published_at" *ngIf="channel?.content_published_at">
                                                                {{ channel?.content_published_at | reportsDate:data.activeLang }}
                                                            </div>
                                                        </div>
                                                        <div class="views" *ngIf="channel?.content_views_count && channel?.content_views_count > 0">
                                                            <svg width="9" height="10" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M0 10V3H2V10H0ZM3.5 10V0H5.5V10H3.5ZM7 10V6H9V10H7Z" fill="#C9C9C9"/>
                                                            </svg>
                                                            {{channel?.content_views_count | number:'':'fr-FR'}}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="channel" (click)="$event.stopPropagation(); goToChanelLink(part, channel)" *ngIf="channel?.channel" [ngClass]="{
                                                'blue_name': !!channel.content_url,
                                                'silver_name': !channel.content_url}"
                                                [ngxTippy]="tippyTemplate">
                                                    <app-platform [platform_id]="channel?.channel?.platform_id"></app-platform>
                                                    <!-- <app-outlet-line [class]="'s_18'" [no_name]="true" [channel]="channel?.channel" [company_id]="data?.company_id"></app-outlet-line> -->
                                                    <div class="count">{{ channel.content_views_count | shortNumber }}</div>
                                                </div>
                                            </ng-container>
                                        </div>
                                        <div class="type_and_group">
                                            <div class="con_type" *ngIf="part?.status_id">
                                                {{ getTaskStatusById(part?.status_id)?.name }}
                                            </div>
                                            <div class="con_group text_one_line" *ngIf="!!part.group_id && !!getGroup(part.group_id)">
                                                {{ getGroup(part.group_id).name }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="con_preview_wrp" *ngIf="part?.previewImgs?.length || (part?.avatarFile && part?.avatarFile?.thumbnail)">
                                    <swiper [pagination]="false" [navigation]="false">
                                        <ng-container *ngIf="part?.previewImgs?.length > 0 && part?.custom_avatar_property == ''; else elseTemplateHasPreview">
                                          <ng-container *ngFor="let preview of part?.previewImgs">
                                            <ng-template swiperSlide>
                                              <div class="preview_img" [ngStyle]="{'background': 'url('+ (!preview.is_parser_url ? host : '') + preview.src +') no-repeat center / cover'}"></div>
                                            </ng-template>
                                          </ng-container>
                                        </ng-container>
                                        <ng-template #elseTemplateHasPreview>
                                          <ng-template swiperSlide>
                                            <div class="preview_img" [ngStyle]="{'background': 'url('+ host + part?.avatarFile?.thumbnail + '?company_id=' + data?.company?.id +') no-repeat center / cover'}"></div>
                                          </ng-template>
                                        </ng-template>
                                    </swiper>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form_line">
                <div>
                    <label class="form_text" for="suggestionsInp">Compilations quantity: </label>
                    <input class="ex_number" id="suggestionsInp" matInput type="number" min="1" max="10" formControlName="suggestions" (change)="changeInp()">
                </div>
                <div>
                    <label class="form_text" for="countInp">Quantity cards in compilation: </label>
                    <input class="ex_number" id="countInp" matInput type="number" min="1" max="100" formControlName="count" (change)="changeInp()">
                </div>

                <!-- <div class="box_wrp">
                    <mat-checkbox color="primary" #tooltip="matTooltip"
                    matTooltip="{{ 'Generate sets that never existed before even if some part of sets (particular order of components) has been used before. For example, there is a set 23-45-58. This option will allow to generate set 95-23-45-58, even though part 23-45-58 is common in both, the set 95-23-45-58 is different from set 23-45-58.' | translate }}"
                    [matTooltipPosition]="'above'" formControlName="is_compilation_repeats">{{"Unique set" | translate}}</mat-checkbox>
                </div> -->

                <mat-radio-group formControlName="flag">
                    <ng-container *ngFor="let item of flags">
                        <ng-container *ngIf="item == 'is_compilation_repeats'; else elseTemplateCompilationRepeat">
                            <mat-radio-button class="form_text radio_btn" [value]="item" #tooltip="matTooltip"
                            matTooltip="{{ 'Generate sets that never existed before even if some part of sets (particular order of components) has been used before. For example, there is a set 23-45-58. This option will allow to generate set 95-23-45-58, even though part 23-45-58 is common in both, the set 95-23-45-58 is different from set 23-45-58.' | translate }}"
                            [matTooltipPosition]="'above'">{{item|translate}}</mat-radio-button>
                        </ng-container>
                        <ng-template #elseTemplateCompilationRepeat>
                            <mat-radio-button class="form_text radio_btn" [value]="item">{{item|translate}}</mat-radio-button>
                        </ng-template>
                    </ng-container>
                </mat-radio-group>

                <div class="d-f-c">
                    <button (click)="generateCompilations()" mat-raised-button color="primary">{{"Generate" | translate}}</button> 
                    <button class="ml-1" (click)="generateCompilations(true)" mat-raised-button color="primary">{{"Deep Generate" | translate}}</button> 
                </div>
                
            </div>
        </form>

        <div class="generations" [appDeviceClass]="'custom-scroll'" *ngIf="generations && generations.length">

            <div class="generations_info">
                {{"Generated" | translate}} {{generations.length}} {{"compilations among" | translate}} {{data.tasks.length}} {{"available cards" | translate}}
            </div>

            <div class="generation" *ngFor="let item of generations; let i = index;">
                <div class="title">
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16 12V16H2V12H16ZM17 10H1C0.45 10 0 10.45 0 11V17C0 17.55 0.45 18 1 18H17C17.55 18 18 17.55 18 17V11C18 10.45 17.55 10 17 10ZM4 15.5C3.18 15.5 2.5 14.83 2.5 14C2.5 13.17 3.18 12.5 4 12.5C4.82 12.5 5.5 13.17 5.5 14C5.5 14.83 4.83 15.5 4 15.5ZM16 2V6H2V2H16ZM17 0H1C0.45 0 0 0.45 0 1V7C0 7.55 0.45 8 1 8H17C17.55 8 18 7.55 18 7V1C18 0.45 17.55 0 17 0ZM4 5.5C3.18 5.5 2.5 4.83 2.5 4C2.5 3.17 3.18 2.5 4 2.5C4.82 2.5 5.5 3.18 5.5 4C5.5 4.82 4.83 5.5 4 5.5Z" fill="#686868"/>
                    </svg> 
                    <span>
                        {{"Compilation" | translate}} #{{i+1}}
                    </span>
                    <div class="comp_btn show_on_hover" (click)="createTaskBasedCompilation(item)">Create compilation card</div>
                </div>

                <div class="connections">
                    <div class="cons_wrp" cdkDropList
                    [cdkDropListData]="item"
                    class="example-list"
                    (cdkDropListDropped)="drop($event)">
                        <div class="cons_in">
                            <!-- [href]="origin + '/task/' + part?.acm" target="_blank" -->
                            <!-- (click)="$event.preventDefault(); $event.stopPropagation(); openTask(part)" -->
                            <ng-container *ngFor="let part of item">
                                <div class="con con_wiz" *ngIf="!!part" cdkDrag (click)="$event.preventDefault(); $event.stopPropagation(); openTask(part)">
                                    <div cdkDragHandle class="handle_con" (click)="$event.preventDefault(); $event.stopPropagation();">
                                        <mat-icon>drag_handle</mat-icon>
                                    </div>
                                    <div class="con_in">
                                        <div class="d-f-c con_side" [ngStyle]="{'max-width': (!!data.is_shared || !part?.wiz_channels) ? '100%' : 'calc(100% - ' + (part?.wiz_channels?.length*18 + (part?.wiz_channels?.length - 1)*6 + (!!part.group_id ? 150 : 0) + 70) + 'px)'}" [ngClass]="{'full': !(!!part.group_id && !!getGroup(part.group_id))}">
                                            <div class="ids">
                                                <div>{{part.custom_id}}</div>
                                                <div>{{part.internal_id}}</div>
                                            </div>
                                            <div class="con_name text_one_line">
                                                {{part.name}}
                                            </div>
                                        </div>
                                        <div class="d-f-c">
                                            <div class="part_channels_count" *ngIf="part?.wiz_views_count">
                                                <svg width="9" height="10" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M0 10V3H2V10H0ZM3.5 10V0H5.5V10H3.5ZM7 10V6H9V10H7Z" fill="#C9C9C9"/>
                                                </svg> 
                                                {{ part?.wiz_views_count | shortNumber }}
                                            </div>
                                            <div class="part_channels">
                                                <ng-container *ngFor="let channel of part?.wiz_channels; let ind = index;">
                                                    <div #tippyTemplate class="fm_ch_tooltip">
                                                        <div class="chan_title">
                                                            <div class="chan_name">
                                                                {{channel?.channel?.name}}
                                                            </div>
                                                        </div>
                                                        <div class="chan_cont_name">
                                                            {{channel?.content_name}}
                                                        </div>
                                                        <div class="chan_info">
                                                            <div class="d-f-c">
                                                                <div class="status">{{(getProfileStatus(channel?.content_status_id)?.name + '_profile') | translate}}</div>
                                                                <div class="published_at" *ngIf="channel?.content_published_at">
                                                                    {{ channel?.content_published_at | reportsDate:data.activeLang }}
                                                                </div>
                                                            </div>
                                                            <div class="views" *ngIf="channel?.content_views_count && channel?.content_views_count > 0">
                                                                <svg width="9" height="10" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M0 10V3H2V10H0ZM3.5 10V0H5.5V10H3.5ZM7 10V6H9V10H7Z" fill="#C9C9C9"/>
                                                                </svg>
                                                                {{channel?.content_views_count | number:'':'fr-FR'}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="channel" *ngIf="channel?.channel" (click)="$event.stopPropagation(); goToChanelLink(part, channel)" [ngClass]="{
                                                    'blue_name': !!channel.content_url,
                                                    'silver_name': !channel.content_url}"
                                                    [ngxTippy]="tippyTemplate">
                                                        <app-platform [platform_id]="channel?.channel?.platform_id"></app-platform>
                                                        <!-- <app-outlet-line [class]="'s_18'" [no_name]="true" [channel]="channel?.channel" [company_id]="data?.company_id"></app-outlet-line> -->
                                                        <div class="count">{{ channel.content_views_count | shortNumber }}</div>
                                                    </div>
                                                </ng-container>
                                            </div>
                                            <div class="type_and_group">
                                                <div class="con_type" *ngIf="part?.status_id">
                                                    {{ getTaskStatusById(part?.status_id)?.name }}
                                                </div>
                                                <div class="con_group text_one_line" *ngIf="!!part.group_id && !!getGroup(part.group_id)">
                                                    {{ getGroup(part.group_id).name }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="con_preview_wrp">
                                        <swiper [pagination]="false" [navigation]="false">
                                            <ng-container *ngIf="part?.previewImgs?.length > 0 && part?.custom_avatar_property == ''; else elseTemplateHasPreview">
                                                <ng-container *ngFor="let preview of part?.previewImgs">
                                                <ng-template swiperSlide>
                                                    <div class="preview_img" [ngStyle]="{'background': 'url('+ (!preview.is_parser_url ? host : '') + preview.src +') no-repeat center / cover'}"></div>
                                                </ng-template>
                                                </ng-container>
                                            </ng-container>
                                            <ng-template #elseTemplateHasPreview>
                                                <ng-template swiperSlide>
                                                <div *ngIf="(part?.avatarFile && part?.avatarFile?.thumbnail)" class="preview_img" [ngStyle]="{'background': 'url('+ host + part?.avatarFile?.thumbnail + '?company_id=' + data?.company?.id +') no-repeat center / cover'}"></div>
                                                </ng-template>
                                            </ng-template>
                                        </swiper>
                                    </div>
                                </div>
                                <p-skeleton *ngIf="!part" height="41px" borderRadius="3px"></p-skeleton>
                            </ng-container>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</mat-dialog-content>
<!-- <mat-dialog-actions>
    <button mat-raised-button (click)="close()">
        {{ "Close" | translate }}
    </button>

    <button (click)="login()" class="ml-1" mat-raised-button cdkFocusInitial color="primary">
        {{ "Log_in" | translate }}
    </button>
</mat-dialog-actions> -->
