<h2 mat-dialog-title class="dialog_title">
    <span>
        {{ "Editing a group" | translate }}
    </span>
    <button mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h2>
<mat-dialog-content class="mat-typography" dirEnter (dirSubmit)="!form.valid ? null : submitForm()">
    <div class="container form_wrapper" style="padding: 20px 0;">
        <form [formGroup]="form">
            <ng-container *ngIf="groups && groups.length > 5; else elseTemplateWithSearch">
                <mat-form-field appearance="standard" class="full_width_form">
                    <mat-label>{{ "Choose a group" | translate }}</mat-label>
                    <mat-select disableOptionCentering formControlName="group_id">
                        <mat-option>
                            <ngx-mat-select-search 
                            placeholderLabel="{{ 'Search' | translate }}" 
                            noEntriesFoundLabel="{{ 'no matching group found' | translate }}"
                            [formControl]="groupsMoreControl"></ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let group of groups$ | async" [value]="group.id">
                            {{ group.name | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </ng-container>
            <ng-template #elseTemplateWithSearch>
                <mat-form-field appearance="standard" class="full_width_form">
                    <mat-label>{{ "Choose a group" | translate }}</mat-label>
                    <mat-select disableOptionCentering formControlName="group_id">
                        <mat-option *ngFor="let group of groups" [value]="group.id">
                            {{ group.name | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </ng-template>
                
            <mat-form-field *ngIf="form.get('custom_id')" appearance="standard" class="full_width_form number_mat">
                <mat-label>{{ 'Custom id' | translate }}</mat-label>
                <input formControlName="custom_id" matInput type="number" placeholder="{{ 'Custom id' | translate }}">
                <mat-hint><div class="paste_data" *ngIf="isGetId" (click)="pasteIdVal()" style="cursor: pointer;"><mat-icon>content_paste</mat-icon>{{ customIdValue }}</div></mat-hint>
            </mat-form-field>
        </form>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button (click)="close()">
        {{ "Back" | translate }}
    </button>
    <button class="ml-1" (click)="submitForm()" mat-raised-button cdkFocusInitial color="primary" [disabled]="!form.valid">
        {{ "Save" | translate }}
    </button>
</mat-dialog-actions>
