<h2 mat-dialog-title class="dialog_title">
    <div class="statement_head">
        <div class="s_logo">
            Reports.re
        </div>
        <div class="left">
            <div class="d-f-c" style="flex-direction: column; justify-content: flex-start; align-items: flex-start !important;">
                <div class="company_name">
                    {{data.company.name}}
                </div>
                <div class="date">
                    {{ "Workspace executor statement" | translate }} <b>{{data.statement.time_start_utc * 1000 | date:'MMM, y':"+0000":data.activeLang}}</b>
                </div>
            </div>
            <div class="company_exec">
                <div class="employee_name">
                    {{data?.statement?.employee?.name}}
                </div>
                <div class="user_position" *ngIf="departments && departments.length">
                    <div class="department" *ngFor="let item of departments">
                        <ng-container *ngFor="let pos of item.positions; let last = last">
                            <span>{{pos?.name}}</span>
                            <ng-container *ngIf="!last">, </ng-container>
                        </ng-container>
                        <div class="dep_name">{{item?.department?.name}}</div>
                    </div>
                </div>  
                <!-- <div> 
                    <div>
                        <span *ngIf="data?.statement?.rate_target != 0 || data?.statement?.salary != 0">{{ "Target" | translate }}: </span>
                        <span class="b_span" *ngIf="data?.statement?.rate_target != 0">(pts.{{ +data?.statement?.rate_target | reportsNumber }}) </span>
                        <span class="b_span" *ngIf="data?.statement?.salary != 0">({{ data?.statement?.salaryCurrency?.short_name}}{{+data?.statement?.salary | reportsNumber}})</span>
                    </div>
                </div> -->
            </div>
        </div>
        <div class="right">
            <div class="d-f-c">
                <div class="docs" (click)="openDocs()">
                    {{ "Documents" | translate }}
                </div>
            </div>
            <div class="date">
                <!-- {{data.statement.time_start_utc * 1000 | date:'MMM y':"+0000":data.activeLang}} -->
            </div>
        </div>
    </div>
</h2>
<mat-dialog-content class="mat-typography">
    <div class="statement_body">
        <div class="balance_wrp">
            <div class="balance_out" *ngFor="let summary of data?.statement?.summaries">
                <div class="balance">
                    <div class="top_balance_wrp">
                        <div class="top_balance">
                            <div class="desc">
                                Total {{data?.statement.time_end * 1000 | date:'MMMM':"":data.activeLang }} Balance <br> as of {{data?.statement.time_end * 1000 | date:'MMMM d, y':"":data.activeLang}}
                            </div>
                            <div class="value">
                                {{ summary?.currency?.short_name}}{{+summary.balance_pending | reportsNumber}} 
                            </div>
                        </div>
                        <div class="top_balance">
                            <div class="desc">
                                {{data?.statement.time_end * 1000 | date:'MMMM':"":data.activeLang }} Balance <br> as of {{data?.statement.time_end * 1000 | date:'MMMM d, y':"":data.activeLang}}
                            </div>
                            <div class="value">
                                {{ summary?.currency?.short_name}}{{+summary.balance | reportsNumber}} 
                            </div>
                        </div>
                        <div class="top_balance">
                            <div class="desc">
                                Pending {{data?.statement.time_end * 1000 | date:'MMMM':"":data.activeLang }} Balance <br> as of {{data?.statement.time_end * 1000 | date:'MMMM d, y':"":data.activeLang}}
                            </div>
                            <div class="value">
                                {{ summary?.currency?.short_name}}{{+summary.pending | reportsNumber}} 
                            </div>
                        </div>
                    </div>
                    <table class="balance_table">
                        <thead>
                            <tr>
                                <td></td>
                                <td>{{ "INSTANCES" | translate }}</td>
                                <td>{{ "AMOUNT" | translate }}</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <div style="font-weight: 700;">
                                        Previous Balance
                                    </div>
                                    <div>
                                        as of {{(data?.statement.time_start-1) * 1000 | date:'MMM d, y':"":data.activeLang}}
                                    </div>
                                </td>
                                <td></td>
                                <td style="font-weight: 700;">{{ summary?.currency?.short_name}}{{+summary.start_balance | reportsNumber}}</td>
                            </tr>
                            <tr>
                                <td>{{ "Earnings (Jobs & Chats with Ready status)" | translate }}</td>
                                <td></td>
                                <td>{{ summary?.currency?.short_name}}{{+summary.earned | reportsNumber}}</td>
                            </tr>
                            <tr>
                                <td>{{ "Pending Earnings (Jobs & Chats haven`t Ready status yet)" | translate }}</td>
                                <td></td>
                                <td>{{ summary?.currency?.short_name}}{{+summary.pending | reportsNumber}}</td>
                            </tr>
                            <tr>
                                <td>{{ "Payouts" | translate }}</td>
                                <td></td>
                                <td>{{ summary?.currency?.short_name}}{{+summary.paid | reportsNumber}}</td>
                            </tr>
                            <tr>
                                <td>{{ "Returned earnings (Items lost Ready status)" | translate }}</td>
                                <td></td>
                                <td>{{ summary?.currency?.short_name}}{{+summary.returned | reportsNumber}}</td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td>{{ "New Balance" | translate }}</td>
                                <td></td>
                                <td>{{ summary?.currency?.short_name}}{{+summary.balance | reportsNumber}}</td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
                <div class="recieve">
                    <div class="recieve_in">
                        <div class="recieve_info">
                            <div>
                                {{ "You will recieve" | translate }}
                            </div>
                            <div>
                                {{ summary?.currency?.short_name}}{{+summary.balance | reportsNumber}} 
                            </div>
                            <div>
                                {{ "Minimum payout due" | translate }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Payouts + -->
        <div class="state_table_wrp">
            <div class="state_table_title">{{ "Payouts" | translate }}</div>
            <table class="state_table">
                <thead>
                    <tr>
                        <td>{{ "Date Paid" | translate }}</td>
                        <td>{{ "Date Posted" | translate }}</td>
                        <td>{{ "Payment system" | translate }}</td>
                        <td>{{ "Description" | translate }}</td>
                        <td>{{ "Amount" | translate }}</td>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let payment of payments">
                        <td>{{ payment?.real_paid_at * 1000 | date:'MMM d, y':"":data.activeLang }}</td>
                        <td>{{ payment?.paid_at * 1000 | date:'MMM d, y':"":data.activeLang }}</td>
                        <td>{{ payment.paymentSystem.name }}</td>
                        <td>{{ payment.comment }}</td>
                        <td>{{ payment?.currency?.short_name }}{{ +payment.amount | reportsNumber }}</td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td>{{ "Total Payouts" | translate }}</td>
                        <td colspan="4">
                            <span *ngFor="let total of totalPayouts">
                                {{ total?.currency?.short_name }}{{ +total.amount | reportsNumber }}
                            </span>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>

        <!-- Earnings + -->
        <div class="state_table_wrp six_td" *ngIf="!!earnings?.length">
            <div class="state_table_title">{{ "Earnings" | translate }}</div>
            <table class="state_table list_table">
                <thead>
                    <tr>
                        <td>
                            <div>{{ "Date posted" | translate }}</div>
                            <div class="small-l">{{ "Date of operation" | translate }}</div>
                        </td>
                        <td>
                            <div>{{ "Item ID" | translate }}</div>
                            <div class="small-l">{{ "Global ID" | translate }}</div>
                        </td>
                        <td>
                            <div>{{ "Item" | translate }}</div>
                            <div class="small-l">{{ "Type" | translate }}</div>
                        </td>
                        <td>
                            <div>{{ "Job Type" | translate }}</div>
                            <div class="small-l">&nbsp;</div>
                        </td>
                        <td>
                            <div>{{ "Task and assignment description" | translate }}</div>
                            <div class="small-l">&nbsp;</div>
                        </td>
                        <td>
                            <div>{{ "Amount" | translate }}</div>
                            <div class="small-l">&nbsp;</div>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let earning of earnings">
                        <tr>
                            <td>
                                <div>{{ earning?.created_at * 1000 | date:'MMM d, y':"":data.activeLang }}</div>
                                <div class="small-m" *ngIf="!!earning?.completed_at">{{ earning?.completed_at * 1000 | date:'MMM d, y':"":data.activeLang }}</div>
                            </td>
                            <td style="cursor: pointer" (click)="openTask(earning?.task)">
                                <div>{{ earning?.task?.custom_id }}</div>
                                <div class="small-m">{{ earning?.task?.internal_id }}</div>
                            </td>
                            <td style="cursor: pointer;" (click)="!earning?.discussion_id ? openJob(earning?.task_id, earning?.task_operation_id) : openChat(earning?.discussion_id)">
                                <ng-container *ngIf="!!earning?.discussion_id; else elseTemplateType">
                                    {{ "Chat" | translate }}
                                </ng-container>
                                <ng-template #elseTemplateType>
                                    {{ "Work" | translate }}
                                </ng-template>
                                <div class="small-m" *ngIf="earning?.groupIn?.length == 1">{{ earning?.groupIn[0].type }}</div>
                                <div class="small-m">ID: {{earning.discussion_id ? earning.discussion_id : earning.task_operation_id}}</div>
                            </td>
                            <td>
                                <ng-container *ngIf="!!earning.taskOperation; else elseTemplateHasJob">
                                    <div>
                                        {{getOperationName(earning?.taskOperation?.operation_id)}}
                                    </div>
                                    <div class="small-m big">{{earning?.taskOperation?.name}}</div>
                                    <div class="small-m">{{earning?.taskOperation?.private_comment}}</div>
                                    <span *ngIf="getPrimaryTag(earning?.taskOperation)" class="text_one_line small-m blue">{{getPrimaryTag(earning?.taskOperation)?.parameterValue?.value}}</span>
                                </ng-container>
                                <ng-template #elseTemplateHasJob>
                                    <div style="color: red;" class="small-m big">{{ 'Deleted' | translate }}</div>
                                </ng-template>
                            </td>
                            <td>
                                <div class="">{{earning?.task?.name}}</div>
                                <div class="small-m big">{{getGroupById(earning?.task?.group_id)?.name}}</div>
                            </td>
                            <td>
                                <span style="margin-right: 5px;" *ngIf="+earning?.rate != 0">pts{{ +earning?.rate | reportsNumber }}</span>
                                <span *ngIf="+earning?.price != 0">{{ earning?.currency?.short_name }}{{ +earning?.price | reportsNumber }}</span>
                            </td>
                        </tr>
                        <tr class="group_in" *ngIf="earning?.groupIn?.length > 1">
                            <td colspan="7" class="group_in_values">
                                <table class="state_table list_table">
                                    <tbody>
                                        <tr>
                                            <td></td>
                                            <td class="three" colspan="3">
                                                <div class="line">{{ "All transactions for assignment" | translate }}</div>
                                            </td>
                                            <td class="two" colspan="2">
                                                <div class="line" *ngFor="let groupIn of earning?.groupIn">
                                                    <div class="date_and_type">
                                                        <span>{{ groupIn?.created_at * 1000 | date:'MMM d, y':"":data.activeLang }}</span>
                                                        <span>{{ groupIn.type }}</span>
                                                    </div>
                                                    <div>
                                                        <span style="margin-right: 5px;" *ngIf="+groupIn?.rate != 0">pts{{ +groupIn?.rate | reportsNumber }}</span>
                                                        <span *ngIf="+groupIn?.price != 0">{{ groupIn?.currency?.short_name }}{{ +groupIn?.price | reportsNumber }}</span>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
            
                                </table>
                            </td>
                        </tr>
                    </ng-container>
                    
                </tbody>
                <tfoot>
                    <tr>
                        <td>{{ "Total Earnings" | translate }}</td>
                        <td colspan="2">
                            <ng-container *ngIf="data?.user?.is_root">
                                <span *ngFor="let total of totalEarnings">
                                    <ng-container *ngIf="total?.currency_id != 'rate' || total?.amount != 0">
                                        {{ total?.currency?.short_name }}{{ +total?.amount | reportsNumber }}
                                    </ng-container>
                                </span>
                                <div class="small_text" *ngIf="checkIfHasSumm(totalEarnings, 'rate') || checkIfHasSumm(totalEarnings, 'amount')">{{"Control total for first 200 lines"|translate}}</div>
                            </ng-container>
                        </td>
                        <td colspan="3">
                            <div class="d-f-c" *ngIf="data?.statement?.summ.earned_salary != 0">
                                <div>
                                    Earned salary:&nbsp;
                                </div>
                                <div>
                                    <ng-container *ngFor="let total of data?.statement?.summaries" >
                                        <span *ngIf="total?.earned_salary != 0">
                                           {{ total?.currency?.short_name }}{{ +total?.earned_salary | reportsNumber }}
                                        </span>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="d-f-c" *ngIf="data?.statement?.summ.earned_price != 0">
                                <div>
                                    Earned price:&nbsp;
                                </div>
                                <div>
                                    <ng-container *ngFor="let total of data?.statement?.summaries">
                                        <span *ngIf="total?.earned_price != 0">
                                           {{ total?.currency?.short_name }}{{ +total?.earned_price | reportsNumber }}
                                        </span>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="d-f-c" *ngIf="data?.statement?.summ.earned_rate != 0">
                                <div>
                                    Earned points:&nbsp;
                                </div>
                                <div>
                                    <ng-container *ngFor="let total of data?.statement?.summaries" >
                                        <span *ngIf="total?.earned_rate != 0">
                                           pts. {{ +total?.earned_rate | reportsNumber }}
                                        </span>
                                    </ng-container>
                                </div>
                            </div>
                        
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>

        <!-- All transactions + -->
        <div class="state_table_wrp" *ngIf="!!allTransactions?.length">
            <div class="state_table_title">{{ "All transactions" | translate }}</div>
            <table class="state_table list_table">
                <thead>
                    <tr>
                        <td>
                            <div>{{ "Date posted" | translate }}</div>
                            <div class="small-l">{{ "Date of operation" | translate }}</div>
                        </td>
                        <td>
                            <div>{{ "Item ID" | translate }}</div>
                            <div class="small-l">{{ "Global ID" | translate }}</div>
                        </td>
                        <td>
                            <div>{{ "Item" | translate }}</div>
                            <div class="small-l">{{ "Type" | translate }}</div>
                        </td>
                        <td>
                            <div>{{ "Job type" | translate }}</div>
                            <div class="small-l">&nbsp;</div>
                        </td>
                        <td>
                            <div>{{ "Task and assignment description" | translate }}</div>
                            <div class="small-l">&nbsp;</div>
                        </td>
                        <td>
                            <div>{{ "Amount" | translate }}</div>
                            <div class="small-l">&nbsp;</div>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let earning of allTransactions">
                        <td>
                            <div>{{ earning?.created_at * 1000 | date:'MMM d, y':"":data.activeLang }}</div>
                            <div class="small-m" *ngIf="!!earning?.completed_at">{{ earning?.completed_at * 1000 | date:'MMM d, y':"":data.activeLang }}</div>
                        </td>
                        <td style="cursor: pointer" (click)="openTask(earning?.task)">
                            <div>{{ earning?.task?.custom_id }}</div>
                            <div class="small-m">{{ earning?.task?.internal_id }}</div>
                        </td>
                        <td style="cursor: pointer;" (click)="!earning?.discussion_id ? openJob(earning?.task_id, earning?.task_operation_id) : openChat(earning?.discussion_id)">
                            <ng-container *ngIf="!!earning?.discussion_id; else elseTemplateType">
                                {{ "Chat" | translate }}
                            </ng-container>
                            <ng-template #elseTemplateType>
                                {{ "Work" | translate }}
                            </ng-template>
                            <div class="small-m">{{ earning?.type }}</div>
                            <div class="small-m">ID: {{earning.discussion_id ? earning.discussion_id : earning.task_operation_id}}</div>
                        </td>
                        <td>
                            <ng-container *ngIf="!!earning.taskOperation; else elseTemplateHasJob">
                                <div>
                                    {{getOperationName(earning?.taskOperation?.operation_id)}}
                                </div>
                                <div class="small-m big">{{earning?.taskOperation?.name}}</div>
                                <div class="small-m">{{earning?.taskOperation?.private_comment}}</div>
                                <span *ngIf="getPrimaryTag(earning?.taskOperation)" class="text_one_line small-m blue">{{getPrimaryTag(earning?.taskOperation)?.parameterValue?.value}}</span>
                            </ng-container>
                            <ng-template #elseTemplateHasJob>
                                <div style="color: red;" class="small-m big">{{ 'Deleted' | translate }}</div>
                            </ng-template>
                        </td>
                        <!-- <td>
                            <div>
                                {{getOperationName(earning?.taskOperation?.operation_id)}}
                            </div>
                            <div class="small-m big">{{earning?.taskOperation?.name}}</div>
                            <div class="small-m">{{earning?.taskOperation?.private_comment}}</div>
                            <span *ngIf="getPrimaryTag(earning?.taskOperation)" class="text_one_line small-m blue">{{getPrimaryTag(earning?.taskOperation)?.parameterValue?.value}}</span>
                        </td> -->
                        <td>
                            <div class="">{{earning?.task?.name}}</div>
                            <div class="small-m big">{{getGroupById(earning?.task?.group_id)?.name}}</div>
                        </td>
                        <td>
                            <span style="margin-right: 5px;" *ngIf="+earning?.rate != 0">pts{{ +earning?.rate | reportsNumber }}</span>
                            <span *ngIf="+earning?.price != 0">{{ earning?.currency?.short_name }}{{ +earning?.price | reportsNumber }}</span>
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td>{{ "Total Earnings" | translate }}</td>
                        <td colspan="2">
                            <ng-container *ngIf="data?.user?.is_root">
                                <div class="d-f-c" *ngIf="checkIfHasSumm(totalAllTransactions, 'amount')">
                                    <div>
                                        Price:&nbsp;
                                    </div>
                                    <div>
                                        <ng-container *ngFor="let total of totalAllTransactions">
                                            <span *ngIf="total?.amount != 0 && total?.currency_id != 'rate'">
                                               {{ total?.currency?.short_name }}{{ +total?.amount | reportsNumber }}
                                            </span>
                                        </ng-container>
                                    </div>
                                </div>
                                <div class="d-f-c" *ngIf="checkIfHasSumm(totalAllTransactions, 'rate')">
                                    <div>
                                        Points:&nbsp;
                                    </div>
                                    <div>
                                        <ng-container *ngFor="let total of totalAllTransactions" >
                                            <span *ngIf="total?.amount != 0 && total?.currency_id == 'rate'">
                                               pts. {{ +total?.amount | reportsNumber }}
                                            </span>
                                        </ng-container>
                                    </div>
                                </div>
                                <div class="small_text" *ngIf="checkIfHasSumm(totalAllTransactions, 'rate') || checkIfHasSumm(totalAllTransactions, 'amount')">{{"Control total for first 200 lines"|translate}}</div>
                            </ng-container>
                        </td>
                        <td colspan="3">
                            <div class="d-f-c" *ngIf="data?.statement?.summ.check_salary != 0">
                                <div>
                                    Check salary:&nbsp;
                                </div>
                                <div>
                                    <ng-container *ngFor="let total of data?.statement?.summaries" >
                                        <span *ngIf="total?.check_salary != 0">
                                           {{ total?.currency?.short_name }}{{ +total?.check_salary | reportsNumber }}
                                        </span>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="d-f-c" *ngIf="data?.statement?.summ.check_price != 0">
                                <div>
                                    Check price:&nbsp;
                                </div>
                                <div>
                                    <ng-container *ngFor="let total of data?.statement?.summaries">
                                        <span *ngIf="total?.check_price != 0">
                                           {{ total?.currency?.short_name }}{{ +total?.check_price | reportsNumber }}
                                        </span>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="d-f-c" *ngIf="data?.statement?.summ.check_rate != 0">
                                <div>
                                    Check points:&nbsp;
                                </div>
                                <div>
                                    <ng-container *ngFor="let total of data?.statement?.summaries" >
                                        <span *ngIf="total?.check_rate != 0">
                                           pts. {{ +total?.check_rate | reportsNumber }}
                                        </span>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="d-f-c" *ngIf="data?.statement?.summ.check != 0">
                                <div>
                                    Check:&nbsp;
                                </div>
                                <div>
                                    <ng-container *ngFor="let total of data?.statement?.summaries" >
                                        <span *ngIf="total?.check != 0">
                                            {{ total?.currency?.short_name }}{{ +total?.check | reportsNumber }}
                                        </span>
                                    </ng-container>
                                </div>
                            </div>
                        
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>

        <!-- Returned earnings + -->
        <div class="state_table_wrp" *ngIf="!!returnedEarnings?.length">
            <div class="state_table_title">{{ "Returned earnings" | translate }}</div>
            <table class="state_table list_table">
                <thead>
                    <tr>
                        <td>
                            <div>{{ "Date posted" | translate }}</div>
                            <div class="small-l">{{ "Date of operation" | translate }}</div>
                        </td>
                        <td>
                            <div>{{ "Item ID" | translate }}</div>
                            <div class="small-l">{{ "Global ID" | translate }}</div>
                        </td>
                        <td>
                            <div>{{ "Item" | translate }}</div>
                            <div class="small-l">&nbsp;</div>
                        </td>
                        <td>
                            <div>{{ "Job type" | translate }}</div>
                            <div class="small-l">&nbsp;</div>
                        </td>
                        <td>
                            <div>{{ "Task and assignment description" | translate }}</div>
                            <div class="small-l">&nbsp;</div>
                        </td>
                        <td>
                            <div>{{ "Amount" | translate }}</div>
                            <div class="small-l">&nbsp;</div>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngIf="!returnedEarnings.length"></tr>
                    <tr *ngFor="let earning of returnedEarnings">
                        <td>
                            <div>{{ earning?.created_at * 1000 | date:'MMM d, y':"":data.activeLang }}</div>
                            <div class="small-m" *ngIf="!!earning?.completed_at">{{ earning?.completed_at * 1000 | date:'MMM d, y':"":data.activeLang }}</div>
                        </td>
                        <td style="cursor: pointer" (click)="openTask(earning?.task)">
                            <div>{{ earning?.task?.custom_id }}</div>
                            <div class="small-m">{{ earning?.task?.internal_id }}</div>
                        </td>
                        <td style="cursor: pointer;" (click)="!earning?.discussion_id ? openJob(earning?.task_id, earning?.task_operation_id) : openChat(earning?.discussion_id)">
                            <ng-container *ngIf="!!earning?.discussion_id; else elseTemplateType">
                                {{ "Chat" | translate }}
                            </ng-container>
                            <ng-template #elseTemplateType>
                                {{ "Work" | translate }}
                            </ng-template>
                            <div class="small-m">ID: {{earning.discussion_id ? earning.discussion_id : earning.task_operation_id}}</div>
                        </td>
                        <!-- <td>
                            <div>
                                {{getOperationName(earning?.taskOperation?.operation_id)}}
                            </div>
                            <div class="small-m big">{{earning?.taskOperation?.name}}</div>
                            <div class="small-m">{{earning?.taskOperation?.private_comment}}</div>
                            <span *ngIf="getPrimaryTag(earning?.taskOperation)" class="text_one_line small-m blue">{{getPrimaryTag(earning?.taskOperation)?.parameterValue?.value}}</span>
                        </td> -->
                        <td>
                            <ng-container *ngIf="!!earning.taskOperation; else elseTemplateHasJob">
                                <div>
                                    {{getOperationName(earning?.taskOperation?.operation_id)}}
                                </div>
                                <div class="small-m big">{{earning?.taskOperation?.name}}</div>
                                <div class="small-m">{{earning?.taskOperation?.private_comment}}</div>
                                <span *ngIf="getPrimaryTag(earning?.taskOperation)" class="text_one_line small-m blue">{{getPrimaryTag(earning?.taskOperation)?.parameterValue?.value}}</span>
                            </ng-container>
                            <ng-template #elseTemplateHasJob>
                                <div style="color: red;" class="small-m big">{{ 'Deleted' | translate }}</div>
                            </ng-template>
                        </td>
                        <td>
                            <div class="">{{earning?.task?.name}}</div>
                            <div class="small-m big">{{getGroupById(earning?.task?.group_id)?.name}}</div>
                        </td>
                        <td>
                            <span style="margin-right: 5px;" *ngIf="+earning?.rate != 0">pts{{ +earning?.rate | reportsNumber }}</span>
                            <span *ngIf="+earning?.price != 0">{{ earning?.currency?.short_name }}{{ +earning?.price | reportsNumber }}</span>
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td>{{ "Total Earnings" | translate }}</td>
                        <td></td>
                        <td colspan="2">
                            <ng-container *ngIf="data?.user?.is_root">
                                <div class="d-f-c" *ngIf="checkIfHasSumm(totalReturnedEarnings, 'amount')">
                                    <div>
                                        Returned price:&nbsp;
                                    </div>
                                    <div>
                                        <ng-container *ngFor="let total of totalReturnedEarnings">
                                            <span *ngIf="total?.amount != 0 && total?.currency_id != 'rate'">
                                               {{ total?.currency?.short_name }}{{ +total?.amount | reportsNumber }}
                                            </span>
                                        </ng-container>
                                    </div>
                                </div>
                                <div class="d-f-c" *ngIf="checkIfHasSumm(totalReturnedEarnings, 'rate')">
                                    <div>
                                        Returned points:&nbsp;
                                    </div>
                                    <div>
                                        <ng-container *ngFor="let total of totalReturnedEarnings" >
                                            <span *ngIf="total?.amount != 0 && total?.currency_id == 'rate'">
                                               pts. {{ +total?.amount | reportsNumber }}
                                            </span>
                                        </ng-container>
                                    </div>
                                </div>
                                <div class="small_text" *ngIf="checkIfHasSumm(totalReturnedEarnings, 'rate') || checkIfHasSumm(totalReturnedEarnings, 'amount')">{{"Control total for first 200 lines"|translate}}</div>
                            </ng-container>
                        </td>
                        <td colspan="2">
                            <div class="d-f-c" *ngIf="data?.statement?.summ.returned_salary != 0">
                                <div>
                                    Returned salary:&nbsp;
                                </div>
                                <div>
                                    <ng-container *ngFor="let total of data?.statement?.summaries" >
                                        <span *ngIf="total?.returned_salary != 0">
                                           {{ total?.currency?.short_name }}{{ +total?.returned_salary | reportsNumber }}
                                        </span>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="d-f-c" *ngIf="data?.statement?.summ.returned_price != 0">
                                <div>
                                    Returned price:&nbsp;
                                </div>
                                <div>
                                    <ng-container *ngFor="let total of data?.statement?.summaries">
                                        <span *ngIf="total?.returned_price != 0">
                                           {{ total?.currency?.short_name }}{{ +total?.returned_price | reportsNumber }}
                                        </span>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="d-f-c" *ngIf="data?.statement?.summ.returned_rate != 0">
                                <div>
                                    Returned points:&nbsp;
                                </div>
                                <div>
                                    <ng-container *ngFor="let total of data?.statement?.summaries" >
                                        <span *ngIf="total?.returned_rate != 0">
                                           pts. {{ +total?.returned_rate | reportsNumber }}
                                        </span>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="d-f-c" *ngIf="data?.statement?.summ.returned != 0">
                                <div>
                                    Returned:&nbsp;
                                </div>
                                <div>
                                    <ng-container *ngFor="let total of data?.statement?.summaries" >
                                        <span *ngIf="total?.returned != 0">
                                            {{ total?.currency?.short_name }}{{ +total?.returned | reportsNumber }}
                                        </span>
                                    </ng-container>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>

        <!-- Changes Earnings + -->
        <div class="state_table_wrp" *ngIf="!!changeEarnings?.length">
            <div class="state_table_title">{{ "Changes Earnings" | translate }}</div>
            <table class="state_table list_table">
                <thead>
                    <tr>
                        <td>
                            <div>{{ "Date posted" | translate }}</div>
                            <div class="small-l">{{ "Date of operation" | translate }}</div>
                        </td>
                        <td>
                            <div>{{ "Item ID" | translate }}</div>
                            <div class="small-l">{{ "Global ID" | translate }}</div>
                        </td>
                        <td>
                            <div>{{ "Item" | translate }}</div>
                            <div class="small-l">&nbsp;</div>
                        </td>
                        <td>
                            <div>{{ "Job type" | translate }}</div>
                            <div class="small-l">&nbsp;</div>
                        </td>
                        <td>
                            <div>{{ "Task and assignment description" | translate }}</div>
                            <div class="small-l">&nbsp;</div>
                        </td>
                        <td>
                            <div>{{ "Amount" | translate }}</div>
                            <div class="small-l">&nbsp;</div>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let earning of changeEarnings">
                        <td>
                            <div>{{ earning?.created_at * 1000 | date:'MMM d, y':"":data.activeLang }}</div>
                            <div class="small-m" *ngIf="!!earning?.completed_at">{{ earning?.completed_at * 1000 | date:'MMM d, y':"":data.activeLang }}</div>
                        </td>
                        <td style="cursor: pointer" (click)="openTask(earning?.task)">
                            <div>{{ earning?.task?.custom_id }}</div>
                            <div class="small-m">{{ earning?.task?.internal_id }}</div>
                        </td>
                        <td style="cursor: pointer;" (click)="!earning?.discussion_id ? openJob(earning?.task_id, earning?.task_operation_id) : openChat(earning?.discussion_id)">
                            <ng-container *ngIf="!!earning?.discussion_id; else elseTemplateType">
                                {{ "Chat" | translate }}
                            </ng-container>
                            <ng-template #elseTemplateType>
                                {{ "Work" | translate }}
                            </ng-template>
                            <div class="small-m">ID: {{earning.discussion_id ? earning.discussion_id : earning.task_operation_id}}</div>
                        </td>
                        <!-- <td>
                            <div>
                                {{getOperationName(earning?.taskOperation?.operation_id)}}
                            </div>
                            <div class="small-m big">{{earning?.taskOperation?.name}}</div>
                            <div class="small-m">{{earning?.taskOperation?.private_comment}}</div>
                            <span *ngIf="getPrimaryTag(earning?.taskOperation)" class="text_one_line small-m blue">{{getPrimaryTag(earning?.taskOperation)?.parameterValue?.value}}</span>
                        </td> -->
                        <td>
                            <ng-container *ngIf="!!earning.taskOperation; else elseTemplateHasJob">
                                <div>
                                    {{getOperationName(earning?.taskOperation?.operation_id)}}
                                </div>
                                <div class="small-m big">{{earning?.taskOperation?.name}}</div>
                                <div class="small-m">{{earning?.taskOperation?.private_comment}}</div>
                                <span *ngIf="getPrimaryTag(earning?.taskOperation)" class="text_one_line small-m blue">{{getPrimaryTag(earning?.taskOperation)?.parameterValue?.value}}</span>
                            </ng-container>
                            <ng-template #elseTemplateHasJob>
                                <div style="color: red;" class="small-m big">{{ 'Deleted' | translate }}</div>
                            </ng-template>
                        </td>
                        <td>
                            <div class="">{{earning?.task?.name}}</div>
                            <div class="small-m big">{{getGroupById(earning?.task?.group_id)?.name}}</div>
                        </td>
                        <td>
                            <span style="margin-right: 5px;" *ngIf="+earning?.rate != 0">pts{{ +earning?.rate | reportsNumber }}</span>
                            <span *ngIf="+earning?.price != 0">{{ earning?.currency?.short_name }}{{ +earning?.price | reportsNumber }}</span>
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td>{{ "Total Earnings" | translate }}</td>

                        <td colspan="2">
                            <ng-container *ngIf="data?.user?.is_root">
                                <div class="d-f-c" *ngIf="checkIfHasSumm(totalChangeEarnings, 'amount')">
                                    <div>
                                        Change price:&nbsp;
                                    </div>
                                    <div>
                                        <ng-container *ngFor="let total of totalChangeEarnings">
                                            <span *ngIf="total?.amount != 0 && total?.currency_id != 'rate'">
                                               {{ total?.currency?.short_name }}{{ +total?.amount | reportsNumber }}
                                            </span>
                                        </ng-container>
                                    </div>
                                </div>
                                <div class="d-f-c" *ngIf="checkIfHasSumm(totalChangeEarnings, 'rate')">
                                    <div>
                                        Change points:&nbsp;
                                    </div>
                                    <div>
                                        <ng-container *ngFor="let total of totalChangeEarnings" >
                                            <span *ngIf="total?.amount != 0 && total?.currency_id == 'rate'">
                                               pts. {{ +total?.amount | reportsNumber }}
                                            </span>
                                        </ng-container>
                                    </div>
                                </div>
                                <div class="small_text" *ngIf="checkIfHasSumm(totalChangeEarnings, 'rate') || checkIfHasSumm(totalChangeEarnings, 'amount')">{{"Control total for first 200 lines"|translate}}</div>
                            </ng-container>
                        </td>
                        <td colspan="2">
                            <div class="d-f-c" *ngIf="data?.statement?.summ.change_salary != 0">
                                <div>
                                    Change salary:&nbsp;
                                </div>
                                <div>
                                    <ng-container *ngFor="let total of data?.statement?.summaries" >
                                        <span *ngIf="total?.change_salary != 0">
                                           {{ total?.currency?.short_name }}{{ +total?.change_salary | reportsNumber }}
                                        </span>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="d-f-c" *ngIf="data?.statement?.summ.change_price != 0">
                                <div>
                                    Change price:&nbsp;
                                </div>
                                <div>
                                    <ng-container *ngFor="let total of data?.statement?.summaries">
                                        <span *ngIf="total?.change_price != 0">
                                           {{ total?.currency?.short_name }}{{ +total?.change_price | reportsNumber }}
                                        </span>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="d-f-c" *ngIf="data?.statement?.summ.change_rate != 0">
                                <div>
                                    Change points:&nbsp;
                                </div>
                                <div>
                                    <ng-container *ngFor="let total of data?.statement?.summaries" >
                                        <span *ngIf="total?.change_rate != 0">
                                           pts. {{ +total?.change_rate | reportsNumber }}
                                        </span>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="d-f-c" *ngIf="data?.statement?.summ.change != 0">
                                <div>
                                    Change:&nbsp;
                                </div>
                                <div>
                                    <ng-container *ngFor="let total of data?.statement?.summaries" >
                                        <span *ngIf="total?.change != 0">
                                            {{ total?.currency?.short_name }}{{ +total?.change | reportsNumber }}
                                        </span>
                                    </ng-container>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>

        <!-- Record Earnings + -->
        <div class="state_table_wrp" *ngIf="!!recordEarnings?.length">
            <div class="state_table_title">{{ "Record Earnings" | translate }}</div>
            <table class="state_table list_table">
                <thead>
                    <tr>
                        <td>
                            <div>{{ "Date posted" | translate }}</div>
                            <div class="small-l">{{ "Date of operation" | translate }}</div>
                        </td>
                        <td>
                            <div>{{ "Item ID" | translate }}</div>
                            <div class="small-l">{{ "Global ID" | translate }}</div>
                        </td>
                        <td>
                            <div>{{ "Item" | translate }}</div>
                            <div class="small-l">&nbsp;</div>
                        </td>
                        <td>
                            <div>{{ "Job type" | translate }}</div>
                            <div class="small-l">&nbsp;</div>
                        </td>
                        <td>
                            <div>{{ "Task and assignment description" | translate }}</div>
                            <div class="small-l">&nbsp;</div>
                        </td>
                        <td>
                            <div>{{ "Amount" | translate }}</div>
                            <div class="small-l">&nbsp;</div>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let earning of recordEarnings">
                        <td>
                            <div>{{ earning?.created_at * 1000 | date:'MMM d, y':"":data.activeLang }}</div>
                            <div class="small-m" *ngIf="!!earning?.completed_at">{{ earning?.completed_at * 1000 | date:'MMM d, y':"":data.activeLang }}</div>
                        </td>
                        <td style="cursor: pointer" (click)="openTask(earning?.task)">
                            <div>{{ earning?.task?.custom_id }}</div>
                            <div class="small-m">{{ earning?.task?.internal_id }}</div>
                        </td>
                        <td style="cursor: pointer;" (click)="!earning?.discussion_id ? openJob(earning?.task_id, earning?.task_operation_id) : openChat(earning?.discussion_id)">
                            <ng-container *ngIf="!!earning?.discussion_id; else elseTemplateType">
                                {{ "Chat" | translate }}
                            </ng-container>
                            <ng-template #elseTemplateType>
                                {{ "Work" | translate }}
                            </ng-template>
                            <div class="small-m">ID: {{earning.discussion_id ? earning.discussion_id : earning.task_operation_id}}</div>
                        </td>
                        <!-- <td>
                            <div>
                                {{getOperationName(earning?.taskOperation?.operation_id)}}
                            </div>
                            <div class="small-m big">{{earning?.taskOperation?.name}}</div>
                            <div class="small-m">{{earning?.taskOperation?.private_comment}}</div>
                            <span *ngIf="getPrimaryTag(earning?.taskOperation)" class="text_one_line small-m blue">{{getPrimaryTag(earning?.taskOperation)?.parameterValue?.value}}</span>
                        </td> -->
                        <td>
                            <ng-container *ngIf="!!earning.taskOperation; else elseTemplateHasJob">
                                <div>
                                    {{getOperationName(earning?.taskOperation?.operation_id)}}
                                </div>
                                <div class="small-m big">{{earning?.taskOperation?.name}}</div>
                                <div class="small-m">{{earning?.taskOperation?.private_comment}}</div>
                                <span *ngIf="getPrimaryTag(earning?.taskOperation)" class="text_one_line small-m blue">{{getPrimaryTag(earning?.taskOperation)?.parameterValue?.value}}</span>
                            </ng-container>
                            <ng-template #elseTemplateHasJob>
                                <div style="color: red;" class="small-m big">{{ 'Deleted' | translate }}</div>
                            </ng-template>
                        </td>
                        <td>
                            <div class="">{{earning?.task?.name}}</div>
                            <div class="small-m big">{{getGroupById(earning?.task?.group_id)?.name}}</div>
                        </td>
                        <td>
                            <span style="margin-right: 5px;" *ngIf="+earning?.rate != 0">pts{{ +earning?.rate | reportsNumber }}</span>
                            <span *ngIf="+earning?.price != 0">{{ earning?.currency?.short_name }}{{ +earning?.price | reportsNumber }}</span>
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td>{{ "Total Earnings" | translate }}</td>
                        <td></td>
                        <td colspan="2">
                            <ng-container *ngIf="data?.user?.is_root">
                                <div class="d-f-c" *ngIf="checkIfHasSumm(totalRecordEarnings, 'amount')">
                                    <div>
                                        Record price:&nbsp;
                                    </div>
                                    <div>
                                        <ng-container *ngFor="let total of totalRecordEarnings">
                                            <span *ngIf="total?.amount != 0 && total?.currency_id != 'rate'">
                                               {{ total?.currency?.short_name }}{{ +total?.amount | reportsNumber }}
                                            </span>
                                        </ng-container>
                                    </div>
                                </div>
                                <div class="d-f-c" *ngIf="checkIfHasSumm(totalRecordEarnings, 'rate')">
                                    <div>
                                        Record points:&nbsp;
                                    </div>
                                    <div>
                                        <ng-container *ngFor="let total of totalRecordEarnings" >
                                            <span *ngIf="total?.amount != 0 && total?.currency_id == 'rate'">
                                               pts. {{ +total?.amount | reportsNumber }}
                                            </span>
                                        </ng-container>
                                    </div>
                                </div>
                                <div class="small_text" *ngIf="checkIfHasSumm(totalRecordEarnings, 'rate') || checkIfHasSumm(totalRecordEarnings, 'amount')">{{"Control total for first 200 lines"|translate}}</div>
                            </ng-container>
                        </td>
                        <td colspan="2">
                            <div class="d-f-c" *ngIf="data?.statement?.summ.record_salary != 0">
                                <div>
                                    Record salary:&nbsp;
                                </div>
                                <div>
                                    <ng-container *ngFor="let total of data?.statement?.summaries" >
                                        <span *ngIf="total?.record_salary != 0">
                                           {{ total?.currency?.short_name }}{{ +total?.record_salary | reportsNumber }}
                                        </span>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="d-f-c" *ngIf="data?.statement?.summ.record_price != 0">
                                <div>
                                    Record price:&nbsp;
                                </div>
                                <div>
                                    <ng-container *ngFor="let total of data?.statement?.summaries">
                                        <span *ngIf="total?.record_price != 0">
                                           {{ total?.currency?.short_name }}{{ +total?.record_price | reportsNumber }}
                                        </span>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="d-f-c" *ngIf="data?.statement?.summ.record_rate != 0">
                                <div>
                                    Record points:&nbsp;
                                </div>
                                <div>
                                    <ng-container *ngFor="let total of data?.statement?.summaries" >
                                        <span *ngIf="total?.record_rate != 0">
                                           pts. {{ +total?.record_rate | reportsNumber }}
                                        </span>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="d-f-c" *ngIf="data?.statement?.summ.record != 0">
                                <div>
                                    Record:&nbsp;
                                </div>
                                <div>
                                    <ng-container *ngFor="let total of data?.statement?.summaries" >
                                        <span *ngIf="total?.record != 0">
                                            {{ total?.currency?.short_name }}{{ +total?.record | reportsNumber }}
                                        </span>
                                    </ng-container>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>

        <!-- Pending earnings + -->
        <div class="state_table_wrp" *ngIf="!!pendingEarnings?.length">
            <div class="state_table_title">{{ "Pending earnings" | translate }}</div>
            <table class="state_table list_table">
                <thead>
                    <tr>
                        <td>
                            <div>{{ "Date posted" | translate }}</div>
                            <div class="small-l">{{ "Date of operation" | translate }}</div>
                        </td>
                        <td>
                            <div>{{ "Item ID" | translate }}</div>
                            <div class="small-l">{{ "Global ID" | translate }}</div>
                        </td>
                        <td>
                            <div>{{ "Item" | translate }}</div>
                            <div class="small-l">&nbsp;</div>
                        </td>
                        <td>
                            <div>{{ "Job type" | translate }}</div>
                            <div class="small-l">&nbsp;</div>
                        </td>
                        <td>
                            <div>{{ "Task and assignment description" | translate }}</div>
                            <div class="small-l">&nbsp;</div>
                        </td>
                        <td>
                            <div>{{ "Amount" | translate }}</div>
                            <div class="small-l">&nbsp;</div>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngIf="!pendingEarnings.length"></tr>
                    <tr *ngFor="let earning of pendingEarnings">
                        <td>
                            <div>{{ earning?.created_at * 1000 | date:'MMM d, y':"":data.activeLang }}</div>
                            <div class="small-m" *ngIf="!!earning?.completed_at">{{ earning?.completed_at * 1000 | date:'MMM d, y':"":data.activeLang }}</div>
                        </td>
                        <td style="cursor: pointer" (click)="openTask(earning?.task)">
                            <div>{{ earning?.task?.custom_id }}</div>
                            <div class="small-m">{{ earning?.task?.internal_id }}</div>
                        </td>
                        <td style="cursor: pointer;" (click)="!earning?.discussion_id ? openJob(earning?.task_id, earning?.task_operation_id) : openChat(earning?.discussion_id)">
                            <ng-container *ngIf="!!earning?.discussion_id; else elseTemplateType">
                                {{ "Chat" | translate }}
                            </ng-container>
                            <ng-template #elseTemplateType>
                                {{ "Work" | translate }}
                            </ng-template>
                            <div class="small-m">ID: {{earning.discussion_id ? earning.discussion_id : earning.task_operation_id}}</div>
                        </td>
                        <!-- <td>
                            <div>
                                {{getOperationName(earning?.taskOperation?.operation_id)}}
                            </div>
                            <div class="small-m big">{{earning?.taskOperation?.name}}</div>
                            <div class="small-m">{{earning?.taskOperation?.private_comment}}</div>
                            <span *ngIf="getPrimaryTag(earning?.taskOperation)" class="text_one_line small-m blue">{{getPrimaryTag(earning?.taskOperation)?.parameterValue?.value}}</span>
                        </td> -->
                        <td>
                            <ng-container *ngIf="!!earning.taskOperation; else elseTemplateHasJob">
                                <div>
                                    {{getOperationName(earning?.taskOperation?.operation_id)}}
                                </div>
                                <div class="small-m big">{{earning?.taskOperation?.name}}</div>
                                <div class="small-m">{{earning?.taskOperation?.private_comment}}</div>
                                <span *ngIf="getPrimaryTag(earning?.taskOperation)" class="text_one_line small-m blue">{{getPrimaryTag(earning?.taskOperation)?.parameterValue?.value}}</span>
                            </ng-container>
                            <ng-template #elseTemplateHasJob>
                                <div style="color: red;" class="small-m big">{{ 'Deleted' | translate }}</div>
                            </ng-template>
                        </td>
                        <td>
                            <div class="">{{earning?.task?.name}}</div>
                            <div class="small-m big">{{getGroupById(earning?.task?.group_id)?.name}}</div>
                        </td>
                        <td>
                            <span style="margin-right: 5px;" *ngIf="+earning?.rate != 0">pts{{ +earning?.rate | reportsNumber }}</span>
                            <span *ngIf="+earning?.price != 0">{{ earning?.currency?.short_name }}{{ +earning?.price | reportsNumber }}</span>
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td>{{ "Total Earnings" | translate }}</td>
                        <td colspan="2">
                            <ng-container *ngIf="data?.user?.is_root">
                                <span *ngFor="let total of totalPendingEarnings">
                                    <ng-container *ngIf="total?.currency_id != 'rate' || total?.amount != 0">
                                        {{ total?.currency?.short_name }}{{ +total?.amount | reportsNumber }}
                                    </ng-container>
                                </span>
                                <div class="small_text" *ngIf="checkIfHasSumm(totalPendingEarnings, 'rate') || checkIfHasSumm(totalPendingEarnings, 'amount')">{{"Control total for first 200 lines"|translate}}</div>
                            </ng-container>
                        </td>
                        <td colspan="3">
                            <div class="d-f-c" *ngIf="data?.statement?.summ.pending_salary != 0">
                                <div>
                                    Pending salary:&nbsp;
                                </div>
                                <div>
                                    <ng-container *ngFor="let total of data?.statement?.summaries" >
                                        <span *ngIf="total?.pending_salary != 0">
                                           {{ total?.currency?.short_name }}{{ +total?.pending_salary | reportsNumber }}
                                        </span>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="d-f-c" *ngIf="data?.statement?.summ.pending_price != 0">
                                <div>
                                    Pending price:&nbsp;
                                </div>
                                <div>
                                    <ng-container *ngFor="let total of data?.statement?.summaries">
                                        <span *ngIf="total?.pending_price != 0">
                                           {{ total?.currency?.short_name }}{{ +total?.pending_price | reportsNumber }}
                                        </span>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="d-f-c" *ngIf="data?.statement?.summ.pending_rate != 0">
                                <div>
                                    Pending points:&nbsp;
                                </div>
                                <div>
                                    <ng-container *ngFor="let total of data?.statement?.summaries" >
                                        <span *ngIf="total?.pending_rate != 0">
                                           pts. {{ +total?.pending_rate | reportsNumber }}
                                        </span>
                                    </ng-container>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>


    </div>
</mat-dialog-content>
