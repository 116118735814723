<button mat-raised-button [color]="(data.data_updated_at > 0 && data.is_update_data == 0 && data?.can_update_at > 0) ? '' : ((data.is_update_data >= 1) ? 'warn' : 'primary')" (click)="onClick()">
    <ng-container *ngIf="data.data_updated_at > 0 && data.is_update_data == 0 && data?.can_update_at > 0; else elseTemplateShowTimer">
        <div class="two_lines_up_btn">
            <div>{{ "Can update in" | translate }}</div>
            <div>{{data?.can_update_at | timeFormat}}</div>
        </div>
    </ng-container>
    <ng-template #elseTemplateShowTimer>
        <ng-container *ngIf="data.is_update_data >= 1; else elseTemplateInProcess">
            {{ "Pending" | translate }}
        </ng-container>
        <ng-template #elseTemplateInProcess>
            {{ "Update" | translate }}
        </ng-template> 
    </ng-template>
</button>