<mat-dialog-content class="mat-typography">
    <div class="container form_wrapper" [appDeviceClass]="'custom-scroll custom-scroll-t'">
        <p class="p_source_table" (click)="log()">{{"System folders " + type + " permission sets" | translate}}</p>
        <div class="source_table">
            <div class="source_table_line source_table_head">
                <div>{{"Card type" | translate}}</div>
                <div>{{"Job" | translate}}</div>
                <div>{{"Job statuses" | translate}}</div>
                <div>{{"Role in job" | translate}}</div>
                <div>{{"Tags" | translate}}</div>
                <div>{{"Folder restrictions" | translate}}</div>
                <div>{{"In" | translate}}*</div>
            </div>
            <div class="source_table_line" *ngFor="let source of sources; let i = index;">
                <div>
                    <div class="items_by_id" *ngIf="source.task_statuses_ids && source.task_statuses_ids.length">
                        <div class="item_by_id text_one_line" *ngFor="let item of source.task_statuses_ids">
                            {{getTaskStatusesById(item)?.name}}
                        </div>
                    </div>
                </div>
                <div>
                    <div class="items_by_id" *ngIf="source.task_operation_operations_ids && source.task_operation_operations_ids.length">
                        <div class="item_by_id text_one_line" *ngFor="let item of source.task_operation_operations_ids">
                            {{getOperationsById(item)?.name}}
                        </div>
                    </div>
                </div>
                <div>
                    <div class="items_by_id" *ngIf="source.task_operation_statuses_ids && source.task_operation_statuses_ids.length">
                        <div class="item_by_id text_one_line" *ngFor="let item of source.task_operation_statuses_ids">
                            {{getStatusById(item)?.name}}
                        </div>
                    </div>
                </div>
                <div>
                    <ng-container [ngSwitch]="source.is_manager">
                        <ng-container *ngSwitchCase="2">
                            {{"All roles" | translate}}  
                        </ng-container>
                        <ng-container *ngSwitchCase="1">
                            {{"Executor" | translate}}
                        </ng-container>
                        <ng-container *ngSwitchCase="0">
                            {{"Assistant" | translate}}  
                        </ng-container>
                        <ng-container *ngSwitchDefault>
                        </ng-container>
                    </ng-container>
                </div>
                <div>
          
                </div>
                <div>
                    <div class="items_by_id" *ngIf="source.folders && source.folders.length">
                        <ng-container *ngFor="let item of source.folders">
                            <div class="item_by_id item_perm" *ngIf="item.cant_read || item.cant_write">
                                <span>
                                    {{item.name}}
                                </span>
                                <span><ng-container *ngIf="item.cant_write">{{'write'| translate}}</ng-container></span>
                                <span><ng-container *ngIf="item.cant_read">{{'read'| translate}}</ng-container></span>
                            </div>
                        </ng-container>
                    </div>  
                </div>
                <div></div>
                <button style="color: #198058;" class="edit_source" mat-icon-button (click)="setSource(false, source)"><mat-icon>edit</mat-icon></button>
                <button style="color: #C8232C;" class="delete_source" mat-icon-button (click)="deleteSource(i)"><mat-icon>delete</mat-icon></button>
            </div>
            <div class="source_table_line" >
                <div>
                    <div class="btn_w8" (click)="setSource(false)">{{"Set setting" | translate}}</div>
                </div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <div class="f_w_p_btns">
        <button mat-button (click)="close()">
            {{ "Close" | translate }}
        </button>
        <button class="ml-1 d-f-btn" (click)="onSubmit()" cdkFocusInitial mat-raised-button color="primary" [disabled]="isSubmit">
            <mat-spinner class="spin_16 mr-1" *ngIf="isSubmit"></mat-spinner>
            <ng-container *ngIf="type != 'user' || !!data?.employee?.employeeSetting; else elseTemplateAddSbmt">
                {{ "Edit" | translate }}
            </ng-container>
            <ng-template #elseTemplateAddSbmt>
                {{ "Add" | translate }}
            </ng-template>
        </button>
    </div>
</mat-dialog-actions>