import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { catchError, concatMap, debounceTime, finalize, last, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { FileService } from 'src/app/shared/services/rest/file.service';
import { SnackBarItem } from '../../../snack-bar/snack-bar-item';
import { forkJoin, from, of, ReplaySubject } from 'rxjs';
import { ParametersService } from 'src/app/shared/services/rest/parameters.service';
import { mimeTypes } from 'mime-wrapper';
import { LoadingService } from 'src/app/shared/services/rest/loading.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { MatMenuTrigger } from '@angular/material/menu/menu-trigger';
import { DeleteAlertComponent } from '../../../delete-alert/delete-alert.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { FilenameSchemeComponent } from './dialog/filename-scheme/filename-scheme.component';

@Component({
  selector: 'app-dubbing',
  templateUrl: './dubbing.component.html',
  styleUrls: ['./dubbing.component.scss']
})
export class DubbingComponent extends BaseClass implements OnInit, OnDestroy {
  public form: FormGroup;

  public isGettingTag: boolean = false;
  public isSubmit: boolean = false;
  // public is_remember: boolean = !!this.sm.localStorageGetItem('rememberExportAudioTags') ? true : false;
  public langs: any;
  public langsControl: FormControl = new FormControl();
  public langs$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public parameters: any;
  public paramControl: FormControl = new FormControl();
  public parameters$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public saveTags: any = [];
  public bookmarks: any = [];
  public is_scheme_editing: boolean = false;

  public filenameScheme: any;

  public folders: any[] = [
    {
      name: "Root",
      id: -1,
      location: "/",
      over: false,
      rootDir: true
    },
    {
      name: "Sources",
      id: 0,
      location: "/sources",
      over: false,
      rootDir: true
    },
    {
      name: "To Approve",
      id: 1,
      location: "/to_approve",
      over: false,
      rootDir: true
    },
    {
      name: "Ready Files",
      id: 2,
      location: "/ready_files",
      over: false,
      rootDir: true
    },
    {
      name: "Approved",
      id: 3,
      location: "/approved",
      over: false,
      rootDir: true
    },
    {
      name: "Project",
      id: 4,
      location: "/project",
      over: false,
      rootDir: true
    },
    {
      name: "Trash",
      id: 5,
      location: "/trash",
      over: false,
      rootDir: true
    }
  ]

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private ls: LoadingService,
    private bottomSheet: MatBottomSheet,
    private sm: StorageManagerService,
    private fileService: FileService,
    private companyService: CompanyService,
    private parametersService: ParametersService,
    public dialogRef: MatDialogRef<DubbingComponent>,
    public layoutService: LayoutService
  ) { super() }

  ngOnInit(): void {
    console.log("DubbingComponent", this.data)
    this.form = this.fb.group({
      source_language: '',
      target_language: [],
      folder: '/',
      inherit_parameters_ids: []
    })

    let langTag = this.data.file.parameterValuesToTask.find(k => !!k.parameter.is_language)

    console.log("langTag", langTag)
    if (this.data.files && this.data.files.length) {

      let count = 0;
      let lenSelection = this.data.files.length;
  
      this.isSubmit = true;
      this.ls.requests$.next({
        value: 0,
        target: `Get source language for ${lenSelection} files`
      })

      from(this.data.files).pipe(
        concatMap((file:any) => {
          file.dub = {
            source_language: '',
            target_language: [],
            inherit_parameters_ids: [],
            is_getting: true
          }
          let fileTag = file.parameterValuesToTask.find(k => !!k.parameter.is_language);

          if (this.saveTags && this.saveTags.length && this.saveTags.filter(p => p.id == fileTag.id).length) {
            file['dub'].source_language = this.saveTags.filter(p => p.id == fileTag.id)[0].iso_value;
            return of(null);
          } else {
            if (!!fileTag && !!fileTag.parameterValue) { 
              return this.parametersService.getAllTags('1', {id: fileTag.parameterValue.original_id}, '200').pipe(
                map(k => k.body),
                tap(fileLangs => {
                  if (fileLangs && fileLangs.length) {
                    file['dub'].source_language = fileLangs[0].iso_value;
                  }
                  this.saveTags.push({
                    id: fileTag.id,
                    language: fileLangs[0].iso_value
                  })
                  file.dub.is_getting = false
                }),
                catchError(() => {
                  file.dub.is_getting = false
                  return of(null);
                }),
              )
            } else {
              file.dub.is_getting = false
              return of(null);
            }
          }

        })
      ).subscribe({
        next: (next) => {
          console.log("next onSubmit", next);
          this.ls.requests$.next({
            value: Math.round((100 / lenSelection) * (count+1)),
            target: `Get source language for ${lenSelection} files`
          })
          count++;
        },
        complete: () => {
          console.log("complete onSubmit");
          this.isSubmit = false;
          this.layoutService.showSnackBar({name: 'Getting source language'}, marker("successfully!"), SnackBarItem)
        },
        error: (error) => {
          console.log("error onSubmit", error)
        }
      })
      
      this.attachSubscriptions(
        this.form.get('inherit_parameters_ids').valueChanges.subscribe((resp) => {
          console.log("inherit_parameters_ids CHANGE", resp)
          this.data.files.forEach(file => {
            file.dub.inherit_parameters_ids = resp;
          });
        })
      )

      this.attachSubscriptions(
        this.form.get('target_language').valueChanges.subscribe((resp) => {
          console.log("target_language CHANGE", resp)
          this.data.files.forEach(file => {
            file.dub.target_language = resp.filter(k => k != file.dub.source_language)
          });
        })
      )
    } else {
      if (!!langTag && !!langTag.parameterValue) {
        this.isGettingTag = true;
        this.attachSubscriptions(
          this.parametersService.getAllTags('1', {id: langTag.parameterValue.original_id}, '200').pipe(
            map(k => k.body)
          ).subscribe(resp => {
            console.log("getAllTags", resp)
            if (resp && resp.length) {
              this.form.patchValue({
                source_language: resp[0].iso_value
              })
            }
            this.isGettingTag = false;
          }, error => {
            this.isGettingTag = false;
          })
        )
      } 
    }

    // if (!!this.sm.localStorageGetItem('rememberExportAudioTags')) {
    //   let rememberExportAudioTags = JSON.parse(this.sm.localStorageGetItem('rememberExportAudioTags'));
    //   if (this.data.company.id && rememberExportAudioTags.company_id == this.data.company.id) {
    //     this.form.patchValue({
    //       parameter_id: rememberExportAudioTags.parameter_id
    //     })
    //   }
    // }
    
    this.attachSubscriptions(
      this.paramControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchParams(resp))
    )

    this.attachSubscriptions(
      this.langsControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchLang(resp))
    )


    
    this.getBookmarks();
    this.getParameters();
    this.getLangs();
  }
  
  activeScheme(item) {
    this.filenameScheme = item
  }

  onSearchParams(resp) {
    if (!this.parameters) {
      return;
    }

    console.log("JOBS", this.parameters)
    if (!resp) {
      this.parameters$.next(this.parameters.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }

    this.parameters$.next(this.parameters.filter(z => z.name && z.name.toLowerCase().indexOf(resp) > -1));
  }

  getLangByVal(iso_value) {
    return this.langs && this.langs.find(x => x.iso_value == iso_value)
  }

  getLangs() {
    this.attachSubscriptions(
      this.parametersService.getAllValues(1, 0, {is_language: 1}, '200').pipe(
        map(res => res.body),
        tap(res => {
          this.langs = res;
          this.langs$.next(this.langs.filter(x => !!x.id).slice())
        }),
      ).subscribe(resp => {
        console.log("this.langs", this.langs)
      })
    )
  }

  getFolderByLoc(loc) {
    return this.folders && this.folders.find(x => x.location == loc)
  }

  onSearchLang(resp) {
    if (!this.langs) {
      return;
    }

    if (!resp) {
      this.langs$.next(this.langs.filter(z => !!z.id).slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.langs$.next(
      this.langs.filter(z => !!z.id && (z.value.toLowerCase().indexOf(resp) > -1))
    );
  }

  close() {
    this.dialogRef.close({event: "close", data: false});
  }

  understandFileType(val) {
    if (!val) {
      return ""
    }
    
    if (val == 'application/pdf') {
      return 'pdf'
    } else if (val.indexOf("/") != -1) {
      return val.split('/')[0]
    } else {
      return ""
    }
  }

  replaceEmojisAndSpecialChars(text) {
    // Регулярное выражение для поиска эмоджи и всех символов, кроме разрешённых
    const regex = /[^\w.\-]|[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F900}-\u{1F9FF}\u{1FA70}-\u{1FAFF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{1F1E6}-\u{1F1FF}]/gu;
  
    // Замена найденных символов на '_'
    return text.replace(regex, '_');
  }

  multiSubmitForm() {
    // if (!this.form.value.source_language) {
    //   this.layoutService.showSnackBar({name: 'Sourse language'}, marker("Not selected!"), SnackBarItem)
    //   return
    // }
    // if (this.form.value.target_language.length == 0) {
    //   this.layoutService.showSnackBar({name: 'Target language(s)'}, marker("Not selected!"), SnackBarItem)
    //   return
    // }
    let dubbArr = [];
    this.data.files.filter(x => ["audio"].includes(mimeTypes.getType(x.content_type)) || ["audio"].includes(this.understandFileType(x.content_type))).forEach(file => {
      file.dub.target_language.forEach(targetLang => {
        if (targetLang != file.dub.source_language) {
          let resName = targetLang + ' - ' + file.filename;
          if (!!this.filenameScheme) {
            resName = this.getSchemeContent(this.filenameScheme, file, false, targetLang)
            resName = this.replaceEmojisAndSpecialChars(resName);
          }
          dubbArr.push({
            file_id: file.id,
            source_language: file.dub.source_language,
            target_language: targetLang,
            save_task_id: file.task_id,
            save_task_operation_id: file.task_operation_id,
            save_location: this.form.value.folder,
            save_filename: resName,
            params: {
              inherit_parameters_ids: file.dub.inherit_parameters_ids
            }
          })
        }
      });
    });

    let count = 0;
    let lenSelection = dubbArr.length;

    if (!lenSelection) {
      this.layoutService.showSnackBar({name: 'There is no audio from the selected files from which audio can be dubbing.'}, '', SnackBarItem)
      return
    }
    this.isSubmit = true;
    this.ls.requests$.next({
      value: 0,
      target: `Dubbing ${lenSelection} files`
    })

    from(dubbArr).pipe(
      mergeMap(x => this.fileService.createDubbingProject(x, this.data.company_id).pipe(
        tap(k => {
          this.ls.requests$.next({
            value: Math.round((100 / lenSelection) * (count+1)),
            target: `Dubbing ${lenSelection} files`
          })
          count++;
          if (!!this.dialogRef) {
            this.close();
          }
        }),
        catchError(() => of([])),
      ), 4), // 4 запроса параллельно
      finalize(() => {
        this.layoutService.showSnackBar({name: 'Dubbing started'}, marker("successfully!"), SnackBarItem)
        // Этот блок выполнится только после обработки всех элементов
        console.log('Все запросы завершены');
        this.isSubmit = false;
        this.close();
        // Здесь можно дополнительно обработать card.is_ok_tags или другие поля
      }),
      last()
    ).subscribe(resp => {
    })
  }

  getParamById(id) {
    return this.parameters && this.parameters.find(x => x.id == id)
  }

  getParameters() {
    this.attachSubscriptions(
      this.parametersService.getApiParameters(this.data.company.id).pipe(
        tap(res => {
          this.parameters = res;
          this.parameters$.next(this.parameters.filter(x => !!x.id).slice())
        }),
      ).subscribe(resp => {
        console.log("this.parameters", this.parameters)
      })
    )
  }

  getSchemeContent(item, file?, show:boolean = true, targetLang?) {
    if (item && item.settings && item.settings.devScheme) {
      let string = ``;

      item.settings.devScheme.forEach((el,i) => {
        if (el.name == 'Separator') {
          if (!el.not_use) {
            string += `${el.value}`
          }
        } else if (el.name == 'Card') {
          if (show) {
            string += `Card ${el.value}`
          } else {   
            if (el.value == 'group') {
              if (this.data.task && this.data.task.group && this.data.task.group.name) {
                string += `${this.data.task.group.name}`
              } else {
                if (item.settings.devScheme[i+i]) {
                  item.settings.devScheme[i+i].not_use = true;
                }
              }
            } else if (el.value == 'custom_id') {
              if (this.data.task && this.data.task.custom_id) {
                string += `${this.data.task.custom_id}`
              } else {
                if (item.settings.devScheme[i+i]) {
                  item.settings.devScheme[i+i].not_use = true;
                }
              }
            } else if (el.value == 'name') {
              if (this.data.task && this.data.task.name) {
                string += `${this.data.task.name.substr(0,50)}`
              } else {
                if (item.settings.devScheme[i+i]) {
                  item.settings.devScheme[i+i].not_use = true;
                }
              }
            } else {
              if (this.data.work) {
                string += `${this.data.task[el.value]}`
              } else {
                if (item.settings.devScheme[i+i]) {
                  item.settings.devScheme[i+i].not_use = true;
                }
              }
            }
          }
        } else if (el.name == 'Job') {
          if (show) {
              string += `Job ${el.value}`
          } else {
            if (el.value == 'name') {
              if (this.data.work && this.data.work.name) {
                string += `${this.data.work.name.substr(0,50)}`
              } else {
                if (item.settings.devScheme[i+i]) {
                  item.settings.devScheme[i+i].not_use = true;
                }
              }
            } else {
              if (this.data.work) {
                string += `${this.data.work[el.value]}`
              } else {
                if (item.settings.devScheme[i+i]) {
                  item.settings.devScheme[i+i].not_use = true;
                }
              }
            }
          }
        } else if (el.name == 'File') {
          if (el.value == 'name') {
            if (show) {
              string += `File name`
            } else {
              if (file && file.filename) {
                string += `${file.filename.split('.')[0].substr(0,50)}`
              } else {
                if (item.settings.devScheme[i+i]) {
                  item.settings.devScheme[i+i].not_use = true;
                }
              }
            }
          } else if (el.value == 'target_language_short') {
            if (show) {
              string += `Target language Short`
            } else {
              if (targetLang) {
                string += `${targetLang}`
              } else {
                if (item.settings.devScheme[i+i]) {
                  item.settings.devScheme[i+i].not_use = true;
                }
              }
            }
          } else if (el.value == 'target_language') {
            if (show) {
              string += `Target language`
            } else {
              if (targetLang) {
                string += `${this.getLangByVal(targetLang).value}`
              } else {
                if (item.settings.devScheme[i+i]) {
                  item.settings.devScheme[i+i].not_use = true;
                }
              }
            }
          } else {
            if (show) {
              string += `File ${el.value}`
            } else {
              if (file) {
                string += `${this.data.task[el.value]}`
                string += `${file[el.value]}`
              } else {
                if (item.settings.devScheme[i+i]) {
                  item.settings.devScheme[i+i].not_use = true;
                }
              }
            }
          }
        } else if (el.name == 'File Tag Category') {
          if (el.value && this.getParamById(el.value)) {
            if (show) {
              string += `${this.getParamById(el.value).name.substr(0,50)}`
            } else {
              if (file && file.parameterValuesToTask && file.parameterValuesToTask.filter(k => k.parameter_id == el.value).length) {
                string += `${file.parameterValuesToTask.filter(k => k.parameter_id == el.value).map(k => k.parameterValue ? k.parameterValue.value : k.parameter_value_id).join('-')}`
              } else {
                if (item.settings.devScheme[i+i]) {
                  item.settings.devScheme[i+i].not_use = true;
                }
              }
            }
          } else {
            if (item.settings.devScheme[i+i]) {
              item.settings.devScheme[i+i].not_use = true;
            }
          }
        }
      });

      return string
    } else {
      return "Target language - filename"
    }
  }

  getBookmarks() {
    this.attachSubscriptions(
      this.companyService.getBookmarks(this.data.company_id, {employee_id: this.data.company.employees[0].id, section: "File", type: "Dubbing filename"}).subscribe(res => {
        this.bookmarks = res;
        console.log("open card bookmarks", this.bookmarks);
      })
    )
  }

  toggleSchemeEditing(e) {
    e.preventDefault();
    e.stopPropagation();
    this.is_scheme_editing = !this.is_scheme_editing;
  }

  editScheme(e, trigger:MatMenuTrigger, scheme) {
    e.preventDefault();
    e.stopPropagation();

    console.log("trigger", trigger)
    
    trigger.closeMenu();

    console.log("editScheme")

    const dialogRef = this.dialog.open(FilenameSchemeComponent, {
      backdropClass: ['backdrop_under_header'],
      panelClass: ['show_header'],
      hasBackdrop: true,
      data: {
        company: this.data.company,
        company_id: this.data.company_id,
        // task: task,
        parameters: this.parameters,
        scheme: scheme
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        
        if (!!result && result.event == 'edit') {
          this.getBookmarks();
        }
      })
    )
  }

  deleteScheme(e, trigger:MatMenuTrigger, scheme) {
    e.preventDefault();
    e.stopPropagation();

    trigger.closeMenu();
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: scheme,
        target: marker("Dubbing filename scheme")
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        this.attachSubscriptions(
          this.companyService.deleteBookmark(scheme.id).subscribe(res => {
            this.bookmarks.splice(this.bookmarks.findIndex(p => p.id == scheme.id), 1);
            console.log("deleteScheme", this.bookmarks);
          }, error => {
            this.layoutService.showSnackBar({name: ''}, error, SnackBarItem);
          })
        )
      }
    });
  }
  
  log() {
    console.log("this.form.value", this.form.value)
    console.log("this.data.file", this.data.file)
    console.log("this.bookmarks", this.bookmarks)
    console.log("this.getSchemeContent(this.filenameScheme, this.data.file, false, targetLang)", this.getSchemeContent(this.filenameScheme, this.data.file, false, this.form.value.target_language[0]))
  }

  createNewScheme(e) {
    e.preventDefault();

    console.log("createNewScheme")

    const dialogRef = this.dialog.open(FilenameSchemeComponent, {
      backdropClass: ['backdrop_under_header'],
      panelClass: ['show_header'],
      hasBackdrop: true,
      data: {
        company: this.data.company,
        company_id: this.data.company_id,
        parameters: this.parameters
        // task: task
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        
        if (!!result && result.event == 'save') {
          this.getBookmarks();
        }
      })
    )
  }

  submitForm() {
    if (!this.form.value.source_language) {
      this.layoutService.showSnackBar({name: 'Sourse language'}, marker("Not selected!"), SnackBarItem)
      return
    }
    if (this.form.value.target_language.length == 0) {
      this.layoutService.showSnackBar({name: 'Target language(s)'}, marker("Not selected!"), SnackBarItem)
      return
    }

    let dubbArr = [];

    this.form.value.target_language.forEach(targetLang => {
      let resName = targetLang + ' - ' + this.data.file.filename;
      if (!!this.filenameScheme) {
        resName = this.getSchemeContent(this.filenameScheme, this.data.file, false, targetLang)
        resName = this.replaceEmojisAndSpecialChars(resName);
      }
      dubbArr.push({
        file_id: this.data.file.id,
        source_language: this.form.value.source_language,
        target_language: targetLang,
        save_task_id: this.data.file.task_id,
        save_task_operation_id: this.data.file.task_operation_id,
        save_location: this.form.value.folder,
        save_filename: resName,
        params: {
          inherit_parameters_ids: this.form.value.inherit_parameters_ids
        }
      })
    });


    // this.attachSubscriptions(
    //   forkJoin(arr).subscribe(resp => {
    //     console.log("createProjects", resp)
    //     this.layoutService.showSnackBar({name: 'Audio dubbing started'}, marker("successfully!"), SnackBarItem)
    //     this.isSubmit = false;
    //     this.close();
    //   })
    // )

    let count = 0;
    let lenSelection = dubbArr.length;

    if (!lenSelection) {
      this.layoutService.showSnackBar({name: 'There is no audio from the selected files from which audio can be dubbing.'}, '', SnackBarItem)
      return
    }
    this.isSubmit = true;
    this.ls.requests$.next({
      value: 0,
      target: `Dubbing file to ${lenSelection} languages`
    })

    from(dubbArr).pipe(
      mergeMap(x => this.fileService.createDubbingProject(x, this.data.company_id).pipe(
        tap(k => {
          this.ls.requests$.next({
            value: Math.round((100 / lenSelection) * (count+1)),
            target: `Dubbing file to ${lenSelection} languages`
          })
          count++;
          if (!!this.dialogRef) {
            this.close();
          }
        }),
        catchError(() => of([])),
      ), 4), // 4 запроса параллельно
      finalize(() => {
        this.layoutService.showSnackBar({name: 'Dubbing started'}, marker("successfully!"), SnackBarItem)
        // Этот блок выполнится только после обработки всех элементов
        console.log('Все запросы завершены');
        this.isSubmit = false;
        this.close();
        // Здесь можно дополнительно обработать card.is_ok_tags или другие поля
      }),
      last()
    ).subscribe(resp => {
    })
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
