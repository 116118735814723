import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { FileService } from 'src/app/shared/services/rest/file.service';

@Component({
  selector: 'app-mob-fm-create-folder',
  templateUrl: './mob-fm-create-folder.component.html',
  styleUrls: ['./mob-fm-create-folder.component.scss']
})
export class MobFmCreateFolderComponent extends BaseClass implements OnInit, OnDestroy {
  public form: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private fileService: FileService,
    public dialogRef: MatDialogRef<MobFmCreateFolderComponent>,
    public layoutService: LayoutService
  ) { super() }

  ngOnInit(): void {
    console.log("MobFmCreateFolderComponent")
    this.form = this.fb.group({
      company_id: this.data.target_company_id,
      task_id: this.data.is_global ? (this.data.task_id || this.data.globalFilterFmFiles.task_id) : this.data.task_id,
      task_operation_id: this.data.is_global ? (this.data.work_id || this.data.globalFilterFmFiles.task_operation_id) : this.data.work_id,
      filename: ["", Validators.required],
      location: this.data.location,
      is_dir: 1
    })
  }

  close() {
    this.dialogRef.removePanelClass('animate__slideInUp')
    this.dialogRef.addPanelClass('animate__slideOutDown')
    setTimeout(()=>{this.dialogRef.close()}, 300);
  }

  submitForm() {
    this.attachSubscriptions(
      this.fileService.uploadFile(this.form.value, this.data.company_id).subscribe(resp => {
        this.data.files.push(resp)
        this.dialogRef.removePanelClass('animate__slideInUp')
        this.dialogRef.addPanelClass('animate__slideOutDown')
        setTimeout(()=>{this.dialogRef.close({event: "create", data: resp})}, 300);
      })
    )
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
