import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { Component, ElementRef, Inject, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSelectionList } from '@angular/material/list';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { concat, forkJoin, fromEvent, of, ReplaySubject, Subscription } from 'rxjs';
import { catchError, concatMap, debounceTime, distinctUntilChanged, filter, last, map, switchMap, take, tap } from 'rxjs/operators';
import { prioritys } from 'src/app/shared/consts/prioritys';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { AuthService } from 'src/app/shared/services/rest/auth.service';
import { ChatService } from 'src/app/shared/services/rest/chat.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { MembersService } from 'src/app/shared/services/rest/members.service';
import { RefreshService } from 'src/app/shared/services/rest/refresh.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { MY_FORMATS } from '../atTasksDialog/task-profile-add/task-profile-add.component';
import { ChatConnectionsComponent } from '../workspace-pages/chats-page/dialogs/chat-connections/chat-connections.component';
import { AddChatToBoardComponent } from '../workspace-pages/chats-page/dialogs/add-chat-to-board/add-chat-to-board.component';
import { casesModel } from 'src/app/shared/functions/casesModel';
import { ChatStatusesComponent } from 'src/app/shared/global_components/chat/dialogs/chat-statuses/chat-statuses.component';
import { environment } from 'src/environments/environment';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { TargetParametersComponent } from '../atTasksDialog/target-parameters/target-parameters.component';

@Component({
  selector: 'app-chats',
  templateUrl: './chats.component.html',
  styleUrls: ['./chats.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
})
export class ChatsComponent extends BaseClass implements OnInit, OnDestroy {
  @ViewChild("chatsContainer") chatsContainer: ElementRef;
  @ViewChild('chatMenu') chatMenu: TemplateRef<any>;
  overlayRef: OverlayRef | null;
  public filter: FormGroup = this.fb.group({
    count: '',
    q: '',
    q_name: '',
    q_text: '',
    status_id: [],
    group_id: [],
    priority: [],
    employee_id: [],
    client_employee_id: [],
    operation_id: [],
    id: [],
    created_at_from : '',
    created_at_to : '',
    posted_at_from : '',
    posted_at_to : '',
    order_by: 'updated_desc',
  });

  public user: any = {};
  public chats: any;
  public prioritys = prioritys;
  public chatsPagination: any;
  public timeZone: any;
  public origin:any = window.location.origin;
  public host: any = environment.host;
  public activeLang: any;
  public statuses: any;
  public searchParams: boolean = false;
  public imgRoute: any = '';
  public sortValue: any = this.data.initData ? this.data.initData.filter.order_by : 'updated_desc';
  public operationsValues: any;
  public employees: any;
  public groups: any;

  public company_id: any;
  public sub: Subscription;
  public chatsDataSub: Subscription;
  public chatsSub: Subscription;
  public deleteChatSub: Subscription;
  
  public user_id: number;
  public filterValueCounter: number = 0;
  public currentPosition: number = 0;
  public chatsPage: number = 1;
  public selectMenuCounter: number = 0;
  public chat_id: number;
  
  public dateFromChanged: boolean = false;
  public dateToChanged: boolean = false;
  public isLoad: boolean = false;
  public menuState: boolean = false;
  public is_mobile: boolean = false;
  public filterOpen: boolean = false;
  public filterCount: number = this.data.initData ? this.data.initData.filter.count : 1;

  public today = moment().set({hour:0,minute:0,second:0}).unix();
  public groupsControl: FormControl = new FormControl();
  public groups$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public employees$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public execControl: FormControl = new FormControl();
  public clientControl: FormControl = new FormControl();
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ChatsComponent>,
    public fb: FormBuilder,
    public dialog: MatDialog,
    private chatService: ChatService,
    private languageService: LanguageService,
    private taskService: TaskService,
    private refreshService: RefreshService,
    private auth: AuthService,
    private layoutService: LayoutService,
    private membersService: MembersService,
    private _adapter: DateAdapter<any>,
    private activatedRoute: ActivatedRoute,
    private companyService: CompanyService,
    public viewContainerRef: ViewContainerRef,
    public overlay: Overlay,
    private sm: StorageManagerService
  ) { super() }

  ngOnInit(): void {
    document.getElementsByTagName('html')[0].classList.add('disable_scroll')
    document.body.classList.add("disable_scroll");

    this.data.dialogHasChat = true;
    if (this.data.cancelLoad) {
      // alert("ADA")
      this.data.cancelLoad()
    }
    
    this.company_id = this.data.company.id || this.data.company_id || this.activatedRoute.snapshot.queryParamMap.get('company_id');
    this.getCompany();
    console.log("ChatsComponent", this.data);
    if (window.innerWidth <= 769) {
      this.is_mobile = true;
    } else {
      this.is_mobile = false;
    }

    if (this.data.initData) {
      console.log("this.data.initData", this.data.initData)
      this.chats = this.data.initData.chats;
      this.chatsPage = JSON.parse(JSON.stringify(this.data.initData.page));
      this.chatsPagination = JSON.parse(JSON.stringify(this.data.initData.pagination));



      this.filter.patchValue(this.data.initData.filter)
      this.checkFilterCounter()
    }

    this.attachSubscriptions(
      this.chatService.getDeletedChats().subscribe(resp => {
        if (this.chats.filter(x => x.id == resp).length != 0) {
          let deletedChat = this.chats.find(x => x.id == resp);
          let indexDeletedChat = this.chats.indexOf(deletedChat);
          let nextChat = this.chats[indexDeletedChat + 1];
          let prevChat = this.chats[indexDeletedChat - 1];
          this.selectChat(nextChat ? nextChat.id : prevChat.id, 'click')
          this.chats.splice(indexDeletedChat, 1)
        }
      })
    )

    if (this.is_mobile && !!this.data.chat) {
      this.menuState = true;
    }

    this.getEmployees();
    this.getOperations();
    this.getGroupsCompany();

    this.getHost();
    this.getImgRoute();
    this.getUser();
    
    this.getLangData();
    this.getChatStatuses();
    this.timeZone = new Date().getTimezoneOffset();

    
    

    this.attachSubscriptions(
      this.filter.valueChanges.pipe(
        debounceTime(300)
      ).subscribe((f) => {
        console.log("valueChanges",f);
        console.log("valueChanges this.filter.value",this.filter.value);
        if (this.chatsSub) {
          this.chatsSub.unsubscribe()
        }
        
        this.filterCount = this.filterCount + 1;
        this.filter.value.count = this.filterCount;

        this.chatsPagination = undefined;
        this.chatsPage = 1;
        // let statuses = this.getStatusesArray(f)
        this.getCompanyChats(this.filter.value, this.chatsPage, !!this.data.chat);
        this.checkFilterCounter()
        this.chatsDataSub = this.chatService.getChatsModalDataStream().pipe(
          concatMap(chatsInPage => {
            return concat(...chatsInPage.map(taskInPage => this.neededData(taskInPage))).pipe(last(),map(x => chatsInPage))
          }),
        ).subscribe(resp => console.log("-----getChatsModalDataStream-----",resp));
      })
    )
    if (!this.data.initData) {
      this.filterCount = this.filterCount + 1;
      this.filter.patchValue({
        count: this.filterCount,
        q: '',
        q_name: '',
        q_text: '',
        status_id: [],
        group_id: [],
        priority: [],
        employee_id: [],
        client_employee_id: [],
        operation_id: [],
        id: [],
        created_at_from : '',
        created_at_to : '',
        posted_at_from : '',
        posted_at_to : '',
        order_by: 'updated_desc',
      })
    }


    this.refresh()
    
    this.attachSubscriptions(
      this.groupsControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchGroups(resp))
    )

    this.attachSubscriptions(
      this.execControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchEmoloyees(resp))
    )

    this.attachSubscriptions(
      this.clientControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchEmoloyees(resp))
    )
  }

  getHost() {
    this.attachSubscriptions(
      this.sm.getHost().subscribe(el => {
        this.data.host = el;
      })
    )
  }

  getUser() {
    this.attachSubscriptions(
      this.auth.$user.subscribe(resp => {
        this.data.user = resp;
      })
    )
  }


  onSearchEmoloyees(resp) {
    if (!this.employees) {
      return;
    }

    if (!resp) {
      this.employees$.next(this.employees.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.employees$.next(
      this.employees.filter(z => z.name.toLowerCase().indexOf(resp) > -1)
    );
  }
  onSearchGroups(resp) {
    if (!this.groups) {
      return;
    }

    if (!resp) {
      this.groups$.next(this.groups.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }

    this.groups$.next(
      this.groups.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  checkFilterCounter() {
    this.filterValueCounter = 0;
    Object.keys(this.filter.value).forEach(key => {
      if (["status_id", "group_id", "priority", "priority", "employee_id", "client_employee_id", "operation_id", "id"].includes(key)) {
        if (JSON.stringify(this.filter.value[key]) != JSON.stringify([])) {
          this.filterValueCounter += 1;
        }
      } else if (key != 'order_by' && key != 'count') {
        if (this.filter.value[key] != '') {
          this.filterValueCounter += 1;
        }
      }      
    })
  }

  getCompany() {
    this.attachSubscriptions(
      this.companyService.getCompany(this.company_id).subscribe(resp => {
        this.companyService.company$.next(resp[0]);
        this.data.company = resp[0];
      })
    )
  }

  getEmployees() {
    this.attachSubscriptions(
      this.taskService.getEmployees(this.company_id).subscribe(resp => {
        this.employees = resp;
        this.employees$.next(this.employees.slice());
      })
    )
  }

  getGroupsCompany() {
    this.attachSubscriptions(
      this.companyService.getInfiniteGroupsCompany(this.company_id, '1', '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.companyService.getInfiniteGroupsCompany(this.company_id, x).pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        this.groups = [].concat(...res);
        this.groups$.next(this.groups.slice());
      })
    )
  }

  toggleSearchParams() {
    this.searchParams = !this.searchParams
    console.log("searchParams", this.searchParams)
  }

  refresh() {
    this.attachSubscriptions(
      this.refreshService.getRefreshChats().pipe(
        concatMap(res => {
          if ( this.chats.filter(x => x.id == res.chat_id).length > 0 && this.company_id == res.company_id ) {
            return this.chatService.getChatsExpand(1, this.company_id, {id: [res.chat_id]}, !this.is_mobile ? '20' : '10').pipe(
              map(x => x.body[0]),
              switchMap(val => this.neededData(val).pipe(map(() => val))),
              tap(val => {
                this.chats.splice(this.chats.indexOf(this.chats.find(b => b.id == val.id)),1, val)
              })
            )
          } else {
            return of(false)
          }    
        })
      ).subscribe(resp => {
        console.log("getRefreshChats", resp)
      })
    )
  }


  neededData(chat) {
    return forkJoin([
      this.membersService.getMembers({ task_id: chat.task_id, task_operation_id: chat.task_operation_id }, this.company_id).pipe(
        tap(res => {
          chat.employees = res.filter(x => x.discussion_id == chat.id || x.discussion_id == 0 || !x.discussion_id);
          chat.employees.forEach(el => {
            if (chat.employeesStatuses.find(x => x.discussion_id == chat.id && el.employee_id == x.employee_id)) {
              el.employeeStatus = chat.employeesStatuses.find(x => x.discussion_id == chat.id && el.employee_id == x.employee_id)
            } else {
              el.employeeStatus = null
            }
          })
        }),
        catchError(err => of(err))
      ),
      this.chatService.getChatRead(chat.id, this.company_id).pipe(
        tap(resp => {
          if (resp.filter(el => el.user_id != this.user.id).length) {
            chat.readUsers = resp.filter(el => el.user_id != this.data.user.id && chat?.lastPost?.user_id != el.user_id && chat?.lastPost?.action?.user_id != el.user_id && el.updated_at > chat?.lastPost?.updated_at);
          }
        })
      )
    ])
  }

  understandFileType(val) {
    if (!val) {
      return ""
    }
    
    if (val == 'application/pdf') {
      return 'pdf'
    } else if (val.indexOf("/") != -1) {
      return val.split('/')[0]
    } else {
      return ""
    }
  }

  onImgError(event){
    event.target.src = this.imgRoute+'/assets/img/image_black_48dp.svg'
  }

  getChatStatus(id) {
    switch (+id) {
      case 1:
        return "Waiting";
        break;
      case 2:
        return "In process";
        break;
      case 3:
        return "To approve";
        break;
      case 4:
        return "Approved";
        break;
      case 5:
        return "To correct";
        break;
      case 6:
        return "Sources needed";
        break;
      case 97:
        return "On hold";
        break;
      case 98:
        return "Ready";
        break;
      case 99:
        return "Canceled";
        break;
    }
  }

  goToTargetChat() {

    console.log('goToTargetChat');
    if (this.chats.find(x => x.id == (this.data.chat.id))) {
      console.log("first", this.chatsPage)
      setTimeout(() => {
        document.getElementById(`chat_${this.data.chat.id}`).scrollIntoView({
          behavior: "smooth",
          block: "center"
        });
      }, 200)
      this.selectChat(this.data.chat.id, "init");
      if (this.chatsContainer) {
        this.chatsContainer['_element'].nativeElement.classList.remove("disable_scroll")
      }
      this.isLoad = false;
    } else {
      console.log("second", this.chatsPage)
      if (this.chatsContainer) {
        this.chatsContainer['_element'].nativeElement.classList.add("disable_scroll")
      }
      this.getChatsToTarget();
    }
  }

  getChatsToTarget() {
    this.isLoad = true;
    this.attachSubscriptions(
      this.chatService.getChatsExpand(this.chatsPage, this.company_id, this.filter.value, !this.is_mobile ? '20' : '10').pipe(
        map(el => el.body),
        tap(arr => {
          this.chatService.chatModalData$.next(arr)
          this.chats.push(...arr)
        })
      ).subscribe({
        next: (next) => {
          // console.log(next)
        },
        complete: () => {
          this.chatsPage = this.chatsPage + 1;
          this.goToTargetChat();
          console.log("getChatsToTarget eee", this.chatsPage, this.chats)
        },
        error: (error) => {
          console.log(error)
        }
      })
    )
  }

  
  getCompanyChats(filter, page, is_tab:boolean = false) {
    console.log("TRY")
    this.isLoad = true;
    
    this.chatsSub = this.chatService.getChatsExpand(page, this.company_id, filter, !this.is_mobile ? '20' : '10').pipe(
      tap(el => {
        if (page == 1) {
          this.chatsPagination = {
            'pageCount': el.headers.get('x-pagination-page-count'),
            'perPage': el.headers.get('x-pagination-per-page'),
            'totalCount': el.headers.get('x-pagination-total-count'),
            'currentPage': el.headers.get('x-pagination-current-page'),
          }
        }
      }),
      map(el => el.body),
      tap(resp => {
        this.chatService.chatModalData$.next(resp)
      })
    ).subscribe(resp => {



      if (this.chatsPage == 1) {
        this.chats = resp;
        console.log("getCompanyChats chatsPage ========== ", this.chatsPage, this.chats)
        this.chatsPage = this.chatsPage + 1;
        if (resp.length) {
          if (this.data.chat && is_tab) {
            this.goToTargetChat();
          } else {
            this.selectChat(resp[0].id, "init");
          }
        }
      } else {
        this.chats = [...this.chats, ...resp]
        console.log("getCompanyChats ", this.chatsPage, this.chats)
        this.chatsPage = this.chatsPage + 1;
      }
  

      this.isLoad = false;
      // if (this.chatsPage <= 3 && +this.chatsPagination['currentPage'] != +this.chatsPagination['pageCount']) {
      //   this.getCompanyChats(filter, this.chatsPage, !!this.data.chat);
      // } else {
        // if (resp.length) {
        //   if (this.data.chat && is_tab) {
        //     this.goToTargetChat();
        //   } else {
        //     this.selectChat(resp[0].id, "init");
        //   }
        // }
      // }
      
    },
    error => {
      this.isLoad = false;
    })
  
  }



  getOperations() {
    this.attachSubscriptions(
      this.taskService.getOperations(this.company_id, this.activeLang).subscribe(resp => {
        this.operationsValues = resp;
      })
    )
  }
  
  toggleMenu() {
    this.menuState = !this.menuState
    console.log("this.filter", this.filter)
  }

  getChatStatuses() {
    this.attachSubscriptions(
      this.chatService.getChatStatuses().subscribe(resp => {
        this.statuses = resp.slice();
      })
    )
  }

  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData().subscribe(resp => {
        this.activeLang = resp.active;
        this._adapter.setLocale(resp.active);
        moment.locale(resp.active);
      })
    )
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  getStatusesArray(f) {
    switch (f) {
      case 'All':
        return [1,2,3,4,5,6,99]
        break;
      case 'Inbox':
        return [1,2,3,5]
        break;
      case 'Solved':
        return [4]
        break;
      default:
        if (this.data.company.permissions.includes('owner') || this.data.company.permissions.includes('admin') || this.data.company.permissions.includes('manager')) {
          return [3]
        } else {
          return [1,2,5]
        }
        break;
    }
  }

  getColumnName(id) {
    if (!this.operationsValues || this.operationsValues.filter(el => el.id == id).length == 0) {
      return '';
    }
    return this.operationsValues.find(el => el.id == id) && this.operationsValues.find(el => el.id == id).translation ? this.operationsValues.find(el => el.id == id).translation.name : this.operationsValues.find(el => el.id == id).name
  }

  ngAfterViewInit(): void {
    this.chatsContainer['_element'].nativeElement.scroll(0,0);
    this.onChatsScroll();

    if (this.data.initData) {

      if(document.getElementById(`chat_${this.data.chat.id}`)) {
        document.getElementById(`chat_${this.data.chat.id}`).scrollIntoView({
          behavior: "smooth",
          block: "center"
        });
      }
      setTimeout(() => {
        this.selectChat(this.data.chat.id, "init");
      }, 0)
    }
  }

  onChatsScroll() {
    this.attachSubscriptions(
      fromEvent(this.chatsContainer['_element'].nativeElement, "scroll").pipe(
        tap((e:any) => {
          // if (e.target.scrollTop > this.currentPosition || e.target.scrollTop < 10) {
          //   this.chatsContainer['_element'].nativeElement.classList.remove("scroll_show")
          // } else {
          //   this.chatsContainer['_element'].nativeElement.classList.add("scroll_show")
          // }
          this.currentPosition = e.target.scrollTop
        }),
        filter((e:any) => e.target.scrollTop >=  e.target.scrollHeight - e.target.offsetHeight - 400),
        debounceTime(300),
        distinctUntilChanged()
      ).subscribe(() => {
          if (this.chatsPage <= this.chatsPagination['pageCount']) {
            this.getCompanyChats(this.getStatusesArray(this.filter.value.tab), this.chatsPage)
          }
        }
      )
    )
  }

  getPriority(id) {
    if (!this.prioritys) {
      return false;
    }
    return this.prioritys.find(el => el.id == id)
  } 

  getStatus(id) {
    if (!this.statuses) {
      return false;
    }
    return this.statuses.find(el => el.id == id)
  }  

  getGroup(id) {
    if (!this.groups || !this.groups.find(el => el.id == id)) {
      return false;
    }
    return this.groups.find(el => el.id == id)
  }  

  getEmployee(id) {
    if (!this.employees) {
      return false;
    }
    return this.employees.find(el => el.id == id)
  }  

  ifAllStatuses() {
    this.attachSubscriptions(
      this.filter.get('status_id').valueChanges.subscribe(val => {
        if (val.sort().toString() == [1,2,3,4,5,6,97,98,99].toString() || val.sort().toString() == [0,1,2,3,4,5,6,97,98,99].toString()) {
          this.filter.patchValue({
            status_id: [0]
          })
        }
      })
    )
  }

  // onStartDateChange(e: MatDatepickerInputEvent<Date, Date>) {
  //   if (e && e.value) {
  //     this.dateFromChanged = true;
  //   }
  //   this.checkRange();
  // }

  // onEndDateChange(e: MatDatepickerInputEvent<Date, Date>) {
  //   if (e && e.value) {
  //     this.dateToChanged = true;
  //   }
  //   this.checkRange();
  // }

  // checkRange(): void {
  //   if (this.dateFromChanged || this.dateToChanged) {
  //     this.dateFromChanged = false;
  //     this.dateToChanged = false;
  //   }
  // }

  sort(val) {
    console.log(val)
    this.sortValue = val;
    // this.chats = undefined;
    // this.chatsPage = 1;
    // this.getCompanyChats(this.getStatusesArray(this.filter.value.tab), this.chatsPage);
    this.filter.patchValue({
      order_by: val
    })
  }

  pinChat(chat) {
    this.attachSubscriptions(
      this.chatService.pinChat({
        discussion_id: chat.id,
        order: 0
      }, this.company_id).subscribe(resp => {
        chat.pin = resp;
      })
    )
  }

  // getPinnedChats() {
  //   this.attachSubscriptions(
  //     this.chatService.getPinnedChats().subscribe(resp => {
  //       console.log("getPinnedChats", resp)
  //     })
  //   ) 
  // }

  unPinChat(chat) {
    this.attachSubscriptions(
      this.chatService.deletePinnedChat(chat.pin.id, this.company_id).subscribe(resp => {
        chat.pin = null;
      })
    )
  }

  selectChat(id, event) {
    if (event == 'click') {
      this.menuState = true;
    }
    this.selectMenuCounter = this.selectMenuCounter + 1;
    this.chat_id = id;
  }

  toNextChat() {
    console.log("toNextChat");
    if (this.chat_id && this.chats) {
      let index = this.chats.findIndex(x => x.id == this.chat_id);
      console.log("index", index)
      if (index >= 0 && this.chats[index + 1]) {
        this.selectChat(this.chats[index + 1].id, 'click')
      }
    }
  }

  clickChat(id) {
    if (this.is_mobile) {
      this.menuState = true;
      this.selectMenuCounter = this.selectMenuCounter + 1;
    }
  }

  hideMenu() {
    if (this.data.closeFull) {
      this.close();
      return
    }
    
    this.menuState = false;
  }

  openChatMenu({ x, y }: MouseEvent, chat) {
    this.closeChatMenu();
    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo({ x, y })
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        }
      ]);

    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });


    this.overlayRef.attach(new TemplatePortal(this.chatMenu, this.viewContainerRef, {
      $implicit: {
        chat: chat
      }
    }));
    
    this.sub = fromEvent<MouseEvent>(document, 'click')
      .pipe(
        filter(event => {
          const clickTarget = event.target as HTMLElement;
          return !!this.overlayRef && !this.overlayRef.overlayElement.contains(clickTarget);
        }),
        take(1)
      ).subscribe(() => this.closeChatMenu())

  }

  closeChatMenu() {
    this.sub && this.sub.unsubscribe();
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
  }

  openTargetValues(info, element) {
    let initData:any = {
      company: this.data.company,
      chat: element
    }

    initData.target = {
      task_id: info[0],
      task_operation_id: info[1],
      discussion_id: info[2]
    }
    if (element.company_id != this.data.company.id) {
      initData.target.partner_company_id = element.company_id
    }
    const dialogRef = this.dialog.open(TargetParametersComponent, {
      backdropClass: ['parameters_modal_backdrop'],
      panelClass: ['without_paddings_modal', 'parameters_modal'],
      data: initData
    });

    // this.attachSubscriptions(
    //   dialogRef.afterClosed().subscribe(result => {
    //     this.refreshService.refreshTask$.next({company_id: this.company_id, task_id: element.id})
    //   })
    // )
  }

  checkIsManager(task, company, _user) { 
    return task?.managers.filter(x => x.user_id == _user.id).length > 0 || task?.group?.managers.filter(x => x.user_id == _user.id).length > 0 || company?.permissions.includes('admin') || company?.permissions.includes('owner') || company?.permissions.includes('manager'); 
  }

  neededTaskData(task, needChats:boolean = true) {
    let arr = [];
    if (this.data.company.permissions.includes('owner') || this.checkIsManager(task, this.data.company, this.user)) {
      arr.push(this.taskService.getTaskClients(task.id, this.data.company.id).pipe(
        tap(res => {
          task.clients = res
        }),
        catchError(err => {
          return err
        })
      ))
    }

    if (needChats) {
      arr.push(this.chatService.getTasksChats(this.data.company.id, [task.id]).pipe(
        tap(res => {
          task['operations'].map(o_el => {
            o_el['chatsInfo'] = [];
            o_el['chatsInfo'].push(...res.filter(z => z.task_operation_id == o_el.id));
            o_el['unReadChats'] = res.filter(z => z.task_operation_id == o_el.id && z.is_read != '1').length;
            o_el['unClosedChats'] = res.filter(z => z.task_operation_id == o_el.id && z.status_id != '4').length;
          });
          task['chatsInfo'] = [];
          task['chatsInfo'].push(...res.filter(z => z.task_id == task.id));
          task['unReadChats'] = res.filter(z => z.is_read != '1').length;
          task['unClosedChats'] = res.filter(z => z.status_id != '4').length;
        })
      ))
    }
    if (arr.length) {
      return forkJoin(arr)
    } else {
      return of(task)
    }
  }

  toPersonalStatuses(chat) {
    this.attachSubscriptions(
      this.taskService.getOneTaskExpand(this.data.company.id, chat.task_id).pipe(
        map(x => x.body[0]),
        switchMap(val => this.neededTaskData(val, false).pipe(map(() => casesModel([val], [], 'update')),map(val => val.arr[0])))
      ).subscribe(resp => {
        console.log("addToBoard", resp)
        let work = resp.operations.find(x => x.id == chat.task_operation_id);
        work.employees = work.employees.filter(x => x.discussion_id == chat.id || x.discussion_id == 0 || !x.discussion_id)
        work.mainEmployees = work.mainEmployees.filter(x => x.discussion_id == chat.id || x.discussion_id == 0 || !x.discussion_id)
        work.addEmployee = work.addEmployee.filter(x => x.discussion_id == chat.id || x.discussion_id == 0 || !x.discussion_id)
        work.addEmployee.forEach(x => {
          if (!x.employeeStatus && chat.employeesStatuses.find(z => z.employee_id == x.employee_id)) {
            x.employeeStatus = chat.employeesStatuses.find(z => z.employee_id == x.employee_id)
          }
        })
        const goToChat = this.dialog.open(ChatStatusesComponent, {
          panelClass: 'add_to_board_modal',
          data: {
            company: this.data.company,
            user: this.user,
            imgRoute: this.imgRoute,
            host: this.host,
            activeLang: this.activeLang,
            chat: chat,
            employees: chat.employees,
            needGetMembers: true,
            task: resp,
            job: work,
            statuses: this.statuses,
            empl_status: this.data.company.permissions.includes('owner') || this.checkIsManager(resp, this.data.company, this.user),
            operationsValues: this.operationsValues,
          }
        });

        // this.attachSubscriptions(
        //   goToChat.afterClosed().subscribe(result => {
        //     this.refreshService.refreshChat$.next({company_id: this.company_id, chat_id: chat.id})
        //   })
        // )
      })
    )

  }

  
  addToBoard(chat) {
    this.attachSubscriptions(
      this.taskService.getOneTaskExpand(this.data.company.id, chat.task_id).pipe(
        map(x => x.body[0]),
        switchMap(val => this.neededTaskData(val).pipe(map(() => casesModel([val], [], 'update')),map(val => val.arr[0])))
      ).subscribe(resp => {
        console.log("addToBoard", resp)
        let work = resp.operations.find(x => x.id == chat.task_operation_id);
        work.employees = work.employees.filter(x => x.discussion_id == chat.id || x.discussion_id == 0 || !x.discussion_id)
        work.mainEmployees = work.mainEmployees.filter(x => x.discussion_id == chat.id || x.discussion_id == 0 || !x.discussion_id)
        work.addEmployee = work.addEmployee.filter(x => x.discussion_id == chat.id || x.discussion_id == 0 || !x.discussion_id)
        work.addEmployee.forEach(x => {
          if (!x.employeeStatus && chat.employeesStatuses.find(z => z.employee_id == x.employee_id)) {
            x.employeeStatus = chat.employeesStatuses.find(z => z.employee_id == x.employee_id)
          }
        })
        const goToChat = this.dialog.open(AddChatToBoardComponent, {
          panelClass: 'add_to_board_modal',
          data: {
            company: this.data.company,
            user: this.user,
            imgRoute: this.imgRoute,
            host: this.host,
            activeLang: this.activeLang,
            chat: chat,
            employees: chat.employees,
            needGetMembers: true,
            task: resp,
            job: work,
            statuses: this.statuses,
            empl_status: this.data.company.permissions.includes('owner') || this.checkIsManager(resp, this.data.company, this.user),
            operationsValues: this.operationsValues,
          }
        });

        // this.attachSubscriptions(
        //   goToChat.afterClosed().subscribe(result => {
        //     this.refreshService.refreshChat$.next({company_id: this.company_id, chat_id: chat.id})
        //   })
        // )
      })
    )

  }

  openInNew(e, chat) {
    e.preventDefault();
    e.stopPropagation();
    console.log("openInNew", chat);
    window.open(`${this.origin}/chat/${chat.acm}`, '_blank')
  }

  copyLink(type) {
    this.layoutService.showSnackBar({name: type + " link"}, "Copied", SnackBarItem)
  }
  
  openConnectionChats(chat) {
    const goToChatCon = this.dialog.open(ChatConnectionsComponent, {
      backdropClass: 'backdrop_under_header',
      panelClass: !this.is_mobile ? ['open_task_dialog', 'show_header'] : 'open_task_dialog',
      data: {
        company: this.data.company,
        user: this.user,
        chat: chat,
        is_mobile: this.is_mobile,
        imgRoute: this.imgRoute,
        operationsValues: this.operationsValues,
        host: this.host,
        activeLang: this.activeLang,
      }
    });

    // this.attachSubscriptions(
    //   goToChatCon.afterClosed().subscribe(result => {
    //     this.updateDiscussion(this.chat_id)
    //     this.refreshService.refreshChat$.next({company_id: this.company.id, chat_id: this.chat_id})
    //     this.initRefreshBoard()
    //   })
    // )
  }


  toggleFilter() {
    this.filterOpen = !this.filterOpen;
  }


  close() {
    this.dialogRef.close({event: "close", data: false})
  }
  
  ngOnDestroy() {
    document.getElementsByTagName('html')[0].classList.remove('disable_scroll')
    document.body.classList.remove("disable_scroll");
    if (this.chatsSub) {
      this.chatsSub.unsubscribe();
    }
    if (this.chatsDataSub) {
      this.chatsDataSub.unsubscribe();
    }
    if (this.sub) {
      this.sub.unsubscribe();
    }
    this.clearSubscriptions();
  }
}
