<h2 mat-dialog-title class="dialog_title">
    <span class="text_2_line">
        {{ data?.chat?.name }}
    </span>
    <button class="ml-1" mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h2>
<mat-dialog-content class="lite_executors" [ngClass]="{'centred': target?.outputExecutors?.is_load}" [appDeviceClass]="'custom-scroll custom-scroll-t'">
    <ng-container *ngIf="target?.outputExecutors?.is_load; else elseTemplateLoadedDone">
        <mat-spinner class="loader"></mat-spinner>
    </ng-container>
    <ng-template #elseTemplateLoadedDone>
        <div class="white_exec">
            <ng-container *ngIf="target.outputExecutors.employees && jobHasUserExec()">
                <div class="white_title">
                    <div class="exec_title">
                        {{"Executors users"|translate}}
                    </div>
                </div>
                <div class="executors">
                    <ng-container *ngFor="let jobEmpl of target.outputExecutors.employees; let i = index;">
                        <div class="exec" *ngIf="!jobEmpl.is_partner && !jobEmpl.is_partner_employee && (jobEmpl.discussion_id == 0 || !jobEmpl.discussion_id)">
                            <app-user [class]="'executor black'" [lines]="2" [status_id]="jobEmpl?.employeeStatus?.status_id" [employee]="jobEmpl?.employee"></app-user>
    
                            <div class="d-f-c" style="position: relative;">
                                <div class="inside_job" (click)="stat.toggle()" [ngClass]="{
                                    'first': (jobEmpl?.employeeStatus ? jobEmpl?.employeeStatus : jobEmpl?.partnerCompanyStatus)?.status_id == '1',
                                    'second': (jobEmpl?.employeeStatus ? jobEmpl?.employeeStatus : jobEmpl?.partnerCompanyStatus)?.status_id == '2',
                                    'third': (jobEmpl?.employeeStatus ? jobEmpl?.employeeStatus : jobEmpl?.partnerCompanyStatus)?.status_id == '3',
                                    'fours': (jobEmpl?.employeeStatus ? jobEmpl?.employeeStatus : jobEmpl?.partnerCompanyStatus)?.status_id == '4',
                                    'fives': (jobEmpl?.employeeStatus ? jobEmpl?.employeeStatus : jobEmpl?.partnerCompanyStatus)?.status_id == '5',
                                    'sixs': (jobEmpl?.employeeStatus ? jobEmpl?.employeeStatus : jobEmpl?.partnerCompanyStatus)?.status_id == '6',
                                    'nine': (jobEmpl?.employeeStatus ? jobEmpl?.employeeStatus : jobEmpl?.partnerCompanyStatus)?.status_id == '97',
                                    'seven': (jobEmpl?.employeeStatus ? jobEmpl?.employeeStatus : jobEmpl?.partnerCompanyStatus)?.status_id == '98',
                                    'eight': (jobEmpl?.employeeStatus ? jobEmpl?.employeeStatus : jobEmpl?.partnerCompanyStatus)?.status_id == '99'}">
                                    <span>{{ getStatus((jobEmpl?.employeeStatus ? jobEmpl?.employeeStatus : jobEmpl?.partnerCompanyStatus)?.status_id)?.name }}</span>
                                </div>
                                <mat-form-field appearance="standard" class="full_width_form hide-form-field" style="position: absolute; top: 100%; left: 0; right: 0;">
                                    <mat-select disableOptionCentering #stat (selectionChange)="changeStatus($event, jobEmpl)">
                                        <mat-option *ngFor="let status of data.statuses" [value]="status.id" [ngClass]="{'cst_active_select_option': (jobEmpl?.employeeStatus ? jobEmpl?.employeeStatus : jobEmpl?.partnerCompanyStatus)?.status_id == status.id}">
                                            {{ status.name | translate }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            
                            <!-- <mat-icon class="mob_delete_executor" *ngIf="!!jobEmpl.activate" (click)="deleteEmployee(jobEmpl, i)">close</mat-icon> -->
                        </div>
                    </ng-container>
                </div>
            </ng-container>
            
            <ng-container *ngIf="jobHasChatExec() || jobHasChatPartn()">
                <div class="white_title">
                    <div class="exec_title">
                        {{"Chat executors"|translate}}
                    </div>
                </div>
                <div class="executors">
                    <ng-container *ngFor="let jobEmpl of target.outputExecutors.employees; let i = index;">
                        <div class="exec" *ngIf="!jobEmpl.is_partner && !jobEmpl.is_partner_employee && !(jobEmpl.discussion_id == 0 || !jobEmpl.discussion_id)">
                            <app-user [class]="'executor black'" [lines]="2" [status_id]="jobEmpl?.employeeStatus?.status_id" [employee]="jobEmpl?.employee"></app-user>
    
                            <div class="d-f-c" style="position: relative;">
                                <div class="inside_job" (click)="stat.toggle()" [ngClass]="{
                                    'first': (jobEmpl?.employeeStatus ? jobEmpl?.employeeStatus : jobEmpl?.partnerCompanyStatus)?.status_id == '1',
                                    'second': (jobEmpl?.employeeStatus ? jobEmpl?.employeeStatus : jobEmpl?.partnerCompanyStatus)?.status_id == '2',
                                    'third': (jobEmpl?.employeeStatus ? jobEmpl?.employeeStatus : jobEmpl?.partnerCompanyStatus)?.status_id == '3',
                                    'fours': (jobEmpl?.employeeStatus ? jobEmpl?.employeeStatus : jobEmpl?.partnerCompanyStatus)?.status_id == '4',
                                    'fives': (jobEmpl?.employeeStatus ? jobEmpl?.employeeStatus : jobEmpl?.partnerCompanyStatus)?.status_id == '5',
                                    'sixs': (jobEmpl?.employeeStatus ? jobEmpl?.employeeStatus : jobEmpl?.partnerCompanyStatus)?.status_id == '6',
                                    'nine': (jobEmpl?.employeeStatus ? jobEmpl?.employeeStatus : jobEmpl?.partnerCompanyStatus)?.status_id == '97',
                                    'seven': (jobEmpl?.employeeStatus ? jobEmpl?.employeeStatus : jobEmpl?.partnerCompanyStatus)?.status_id == '98',
                                    'eight': (jobEmpl?.employeeStatus ? jobEmpl?.employeeStatus : jobEmpl?.partnerCompanyStatus)?.status_id == '99'}">
                                    <span>{{ getStatus((jobEmpl?.employeeStatus ? jobEmpl?.employeeStatus : jobEmpl?.partnerCompanyStatus)?.status_id)?.name }}</span>
                                </div>
                                <mat-form-field appearance="standard" class="full_width_form hide-form-field" style="position: absolute; top: 100%; left: 0; right: 0;">
                                    <mat-select disableOptionCentering #stat (selectionChange)="changeStatus($event, jobEmpl)">
                                        <mat-option *ngFor="let status of data.statuses" [value]="status.id" [ngClass]="{'cst_active_select_option': (jobEmpl?.employeeStatus ? jobEmpl?.employeeStatus : jobEmpl?.partnerCompanyStatus)?.status_id == status.id}">
                                            {{ status.name | translate }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <!-- <mat-icon class="mob_delete_executor" *ngIf="!!jobEmpl.activate" (click)="deleteEmployee(jobEmpl, i)">close</mat-icon> -->
                        </div>
                    </ng-container>

                    <ng-container *ngFor="let partner of target.outputExecutors.partnerCompanies; let i = index;">
                        <div class="exec" *ngIf="!(partner.discussion_id == 0 || !partner.discussion_id)">
                            <app-user [class]="'executor black'" [lines]="2" [status_id]="partner?.partnerCompanyStatus?.status_id" [companyEmployee]="partner?.partnerCompany" [imgRoute]="imgRoute" [host]="host" [company]="company"></app-user>
                        
                            <div class="d-f-c" style="position: relative;">
                                <div class="inside_job" (click)="stat.toggle()" [ngClass]="{
                                    'first': (partner?.employeeStatus ? partner?.employeeStatus : partner?.partnerCompanyStatus)?.status_id == '1',
                                    'second': (partner?.employeeStatus ? partner?.employeeStatus : partner?.partnerCompanyStatus)?.status_id == '2',
                                    'third': (partner?.employeeStatus ? partner?.employeeStatus : partner?.partnerCompanyStatus)?.status_id == '3',
                                    'fours': (partner?.employeeStatus ? partner?.employeeStatus : partner?.partnerCompanyStatus)?.status_id == '4',
                                    'fives': (partner?.employeeStatus ? partner?.employeeStatus : partner?.partnerCompanyStatus)?.status_id == '5',
                                    'sixs': (partner?.employeeStatus ? partner?.employeeStatus : partner?.partnerCompanyStatus)?.status_id == '6',
                                    'nine': (partner?.employeeStatus ? partner?.employeeStatus : partner?.partnerCompanyStatus)?.status_id == '97',
                                    'seven': (partner?.employeeStatus ? partner?.employeeStatus : partner?.partnerCompanyStatus)?.status_id == '98',
                                    'eight': (partner?.employeeStatus ? partner?.employeeStatus : partner?.partnerCompanyStatus)?.status_id == '99'}">
                                    <span>{{ getStatus((partner?.employeeStatus ? partner?.employeeStatus : partner?.partnerCompanyStatus)?.status_id)?.name }}</span>
                                </div>
                                <mat-form-field appearance="standard" class="full_width_form hide-form-field" style="position: absolute; top: 100%; left: 0; right: 0;">
                                    <mat-select disableOptionCentering #stat (selectionChange)="changeStatus($event, partner)">
                                        <mat-option *ngFor="let status of data.statuses" [value]="status.id" [ngClass]="{'cst_active_select_option': (partner?.employeeStatus ? partner?.employeeStatus : partner?.partnerCompanyStatus)?.status_id == status.id}">
                                            {{ status.name | translate }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <!-- <mat-icon class="mob_delete_executor" *ngIf="!!partner.activate" (click)="deletePartner(partner, i)">close</mat-icon> -->
                        </div>
                    </ng-container>
                </div>
            </ng-container>
    
            <ng-container *ngIf="!!target.outputExecutors.partnerCompanies.length && jobHasNotChatPartn()">
                <div class="white_title">
                    <div class="exec_title">
                        {{"Executors workspaces"|translate}}
                    </div>
                </div>
                <div class="executors">
                    <ng-container *ngFor="let partner of target.outputExecutors.partnerCompanies; let i = index;">
                        <div class="exec" *ngIf="partner.discussion_id == 0 || !partner.discussion_id">
                            <app-user [class]="'executor black'" [lines]="2" [status_id]="partner?.partnerCompanyStatus?.status_id" [companyEmployee]="partner?.partnerCompany" [imgRoute]="imgRoute" [host]="host" [company]="company"></app-user>
                        
                            <div class="d-f-c" style="position: relative;">
                                <div class="inside_job" (click)="stat.toggle()" [ngClass]="{
                                    'first': (partner?.employeeStatus ? partner?.employeeStatus : partner?.partnerCompanyStatus)?.status_id == '1',
                                    'second': (partner?.employeeStatus ? partner?.employeeStatus : partner?.partnerCompanyStatus)?.status_id == '2',
                                    'third': (partner?.employeeStatus ? partner?.employeeStatus : partner?.partnerCompanyStatus)?.status_id == '3',
                                    'fours': (partner?.employeeStatus ? partner?.employeeStatus : partner?.partnerCompanyStatus)?.status_id == '4',
                                    'fives': (partner?.employeeStatus ? partner?.employeeStatus : partner?.partnerCompanyStatus)?.status_id == '5',
                                    'sixs': (partner?.employeeStatus ? partner?.employeeStatus : partner?.partnerCompanyStatus)?.status_id == '6',
                                    'nine': (partner?.employeeStatus ? partner?.employeeStatus : partner?.partnerCompanyStatus)?.status_id == '97',
                                    'seven': (partner?.employeeStatus ? partner?.employeeStatus : partner?.partnerCompanyStatus)?.status_id == '98',
                                    'eight': (partner?.employeeStatus ? partner?.employeeStatus : partner?.partnerCompanyStatus)?.status_id == '99'}">
                                    <span>{{ getStatus((partner?.employeeStatus ? partner?.employeeStatus : partner?.partnerCompanyStatus)?.status_id)?.name }}</span>
                                </div>
                                <mat-form-field appearance="standard" class="full_width_form hide-form-field" style="position: absolute; top: 100%; left: 0; right: 0;">
                                    <mat-select disableOptionCentering #stat (selectionChange)="changeStatus($event, partner)">
                                        <mat-option *ngFor="let status of data.statuses" [value]="status.id" [ngClass]="{'cst_active_select_option': (partner?.employeeStatus ? partner?.employeeStatus : partner?.partnerCompanyStatus)?.status_id == status.id}">
                                            {{ status.name | translate }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </ng-container>
            
        </div>
    </ng-template>
</mat-dialog-content>
