<mat-dialog-content class="mat-typography">
    <span class="head_title" (click)="log()">
        {{ "Posts names" | translate }}
    </span>
    <div class="autos" [appDeviceClass]="'custom-scroll custom-scroll-t'">

        <!-- <div class="title">{{"Videos" | translate}}</div> -->
        <div class="items">
            <div class="item tag_item" *ngIf="data.vidProject && data.vidProject.batch_data && data.vidProject.batch_data.selectedCols">
                <div class="item_head">
                    <div class="item_photo">
                        <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.3125 1.875C1.46168 1.875 1.60476 1.81574 1.71025 1.71025C1.81574 1.60476 1.875 1.46168 1.875 1.3125C1.875 1.16332 1.81574 1.02024 1.71025 0.914752C1.60476 0.809263 1.46168 0.75 1.3125 0.75C1.16332 0.75 1.02024 0.809263 0.914752 0.914752C0.809263 1.02024 0.75 1.16332 0.75 1.3125C0.75 1.46168 0.809263 1.60476 0.914752 1.71025C1.02024 1.81574 1.16332 1.875 1.3125 1.875ZM5.77875 2.8425C5.91375 2.9775 6 3.165 6 3.375C6 3.58125 5.9175 3.76875 5.77875 3.90375L3.90375 5.77875C3.76875 5.91375 3.58125 6 3.375 6C3.16875 6 2.98125 5.9175 2.8425 5.77875L0.22125 3.1575C0.0825 3.01875 0 2.83125 0 2.625V0.75C0 0.33375 0.33375 0 0.75 0H2.625C2.83125 0 3.01875 0.0825 3.15375 0.2175L5.77875 2.8425ZM4.3275 0.64125L4.7025 0.26625L7.27875 2.8425C7.4175 2.9775 7.5 3.16875 7.5 3.375C7.5 3.58125 7.4175 3.76875 7.2825 3.90375L5.265 5.92125L4.89 5.54625L7.03125 3.375L4.3275 0.64125Z" fill="#fff"/>
                        </svg> 
                    </div>
                    <div>
                        <div class="name text_one_line">{{"Tag" | translate}}</div>
                    </div>
                </div>
                <ng-container *ngFor="let el of data.vidProject.batch_data.selectedCols; let i = index;">
                    <div class="item_el">
                        <div>
                            <div class="name text_one_line">{{getValuesById(el)?.value}}</div>
                        </div>
                    </div>
                    <!-- <ng-container *ngIf="openedLines.includes(i)">
                        <ng-container *ngFor="let out of outputObj">
                            <div class="item_el">
                                <div>
                                    <input [(ngModel)]="out.output_filenames[i]" class="proj_name" matInput placeholder="{{ 'File Name' | translate }}">
                                </div>
                            </div>
                        </ng-container>
                    </ng-container> -->
                    
                </ng-container>
                
            </div>
            <div class="item" *ngFor="let item of parts">
                <div class="item_head">
                    <input style="padding: 2px 5px;" [(ngModel)]="item.postNames['0'].name" class="proj_name reports_white_input" matInput placeholder="{{ 'Post Name' | translate }}">
                </div>
                <ng-container *ngFor="let tID of data.vidProject.batch_data.selectedCols; let i = index;">
                    <div class="item_el" *ngIf="item.postNames[tID]">
                        <input style="padding: 2px 5px;" [(ngModel)]="item.postNames[tID].name" class="proj_name reports_white_input" matInput placeholder="{{ 'Post Name' | translate }}">
                        <div class="tmpl_val">
                            <div class="">
                                <div class="d-f-c" style="width: 8px; height: 8px" [ngClass]="{'rotateble': item.postNames[tID]?.is_getting}" (click)="$event.preventDefault(); $event.stopPropagation(); titleAI($event, item, tID)">
                                    <svg style="width: 8px; height: 8px" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#C9C9C9"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 6v3l4-4-4-4v3c-4.42 0-8 3.58-8 8 0 1.57.46 3.03 1.24 4.26L6.7 14.8c-.45-.83-.7-1.79-.7-2.8 0-3.31 2.69-6 6-6zm6.76 1.74L17.3 9.2c.44.84.7 1.79.7 2.8 0 3.31-2.69 6-6 6v-3l-4 4 4 4v-3c4.42 0 8-3.58 8-8 0-1.57-.46-3.03-1.24-4.26z"/></svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <div class="bottom_btns">
        <div class="white_inf">
            <ng-container *ngIf="data.vidProject && data.vidProject.batch_data && data.vidProject.batch_data.selectedCols">
                {{parts.length*data.vidProject.batch_data.selectedCols.length - getMissingNames().length}}/{{parts.length*data.vidProject.batch_data.selectedCols.length}} <span>({{getMissingNames().length}} {{"missing" | translate}})</span>
            </ng-container>
        </div>

        <div class="d-f-c" style="position: relative;">
            <button mat-raised-button (click)="close()">
                {{ "Close" | translate }}
            </button>

            <mat-form-field appearance="legacy" class="white_legacy_select ml-1" style="color: #fff;">
                <mat-label>{{ "AI model" | translate }}</mat-label>
                <mat-select [disableOptionCentering]="true" [multiple]="false" selectDir [(ngModel)]="ai_model">
                  <mat-option>
                    <ngx-mat-select-search 
                        placeholderLabel="{{ 'Search' | translate }}" 
                        noEntriesFoundLabel="{{ 'no matching model found' | translate }}"
                        [formControl]="aiModelControl"></ngx-mat-select-search>
                  </mat-option>
                  <ng-container *ngFor="let model of models$ | async">
                      <mat-option [value]="model.id">
                        {{model.name}}
                      </mat-option>
                  </ng-container>
                </mat-select>
            </mat-form-field>

            <button class="ml-1 d-f-btn" (click)="getTranslations()" mat-raised-button color="primary" [disabled]="data.isGettingTranslations">
                <mat-spinner class="spin_16 mr-1" *ngIf="data.isGettingTranslations"></mat-spinner>  
                {{ "Get Translations" | translate }}
            </button>
            <div class="post_names_box_wrp">
                <mat-checkbox color="primary" [formControl]="forMiss">{{ "For missing translations" | translate }}</mat-checkbox>
            </div>
        </div>
    </div>
</mat-dialog-actions>


<ng-template #contextMenu let-contextdata>
	<section class="dark_menu with_search" [ngClass]="{'ai_menu': !!contextdata?.is_ai, with_mistake: !!aiMistake}">
            <ng-container *ngIf="aiResults && aiResults.length; else elseTemplateHasResHead">
                <div class="context_head no_focus">
                    <div class="cont_title">
                        <div>{{aiResults.length}} {{"another options" | translate}}</div>
                        <div class="cp" (click)="$event.preventDefault(); $event.stopPropagation(); resetRes(); closeContext()">
                            <mat-icon style="color: #C9C9C9;">arrow_back_ios</mat-icon>
                        </div>
                    </div>
                    <div class="cont_desc">
                        {{"Tap option to apply" | translate}}
                    </div>
                </div>
            </ng-container>
            <ng-template #elseTemplateHasResHead>
                <ng-container *ngIf="!!aiMistake; else elseTemplateHasMistake">
                    <div class="context_head no_focus">
                        <div class="cont_title">
                            <div>{{"Your response was received in the wrong format" | translate}}</div>
                            <div class="cp" (click)="$event.preventDefault(); $event.stopPropagation(); resetRes();">
                                <mat-icon style="color: #C9C9C9;">arrow_back_ios</mat-icon>
                            </div>
                        </div>
                        <div class="cont_desc" [innerHTML]="aiMistake"></div>
                    </div>
                </ng-container>
                <ng-template #elseTemplateHasMistake>
                    <div class="context_head no_focus">
                        <div class="cont_title">
                            <div>{{"Title composer" | translate}}</div>
                            <div>
                                <svg style="width: 21px; height: 20px" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13.0817 9.25L12.1817 10.17C11.4617 10.89 11.0117 11.5 11.0117 13H9.01172V12.5C9.01172 11.39 9.46172 10.39 10.1817 9.67L11.4217 8.41C11.7917 8.05 12.0117 7.55 12.0117 7C12.0117 5.89 11.1117 5 10.0117 5C9.48129 5 8.97258 5.21071 8.59751 5.58579C8.22243 5.96086 8.01172 6.46957 8.01172 7H6.01172C6.01172 5.93913 6.43315 4.92172 7.18329 4.17157C7.93344 3.42143 8.95085 3 10.0117 3C11.0726 3 12.09 3.42143 12.8401 4.17157C13.5903 4.92172 14.0117 5.93913 14.0117 7C14.0117 7.88 13.6517 8.67 13.0817 9.25ZM11.0117 17H9.01172V15H11.0117M10.0117 0C8.6985 0 7.39814 0.258658 6.18488 0.761205C4.97163 1.26375 3.86924 2.00035 2.94065 2.92893C1.06529 4.8043 0.0117188 7.34784 0.0117188 10C0.0117188 12.6522 1.06529 15.1957 2.94065 17.0711C3.86924 17.9997 4.97163 18.7362 6.18488 19.2388C7.39814 19.7413 8.6985 20 10.0117 20C12.6639 20 15.2074 18.9464 17.0828 17.0711C18.9582 15.1957 20.0117 12.6522 20.0117 10C20.0117 4.47 15.5117 0 10.0117 0Z" fill="#219653"/>
                                </svg> 
                            </div>
                        </div>
                        <div class="cont_desc">
                            {{"Here you may compose title for all video publications" | translate}}
                        </div>
                    </div>
                </ng-template>
            </ng-template>

    
            <div class="context_body" [ngClass]="{'with_results': aiResults && aiResults.length}">
                <div class="res_list">
                    <div 
                    *ngFor="let item of aiResults" 
                    class="res text_one_line" 
                    [ngClass]="{'active': !!contextdata.contextData && !!contextdata.contextData.postNames && contextdata.contextData.postNames[contextdata.tID].name == item}"
                    (click)="$event.preventDefault(); $event.stopPropagation(); selectTitle(item, contextdata)">
                        {{item}}
                    </div>
                </div>
            </div>

        
	</section>
</ng-template>