import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { fromEvent } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { OpenTaskComponent } from 'src/app/components/workspace-pages/cases/dialogs/open-task/open-task.component';
import { DeleteAlertComponent } from 'src/app/shared/global_components/delete-alert/delete-alert.component';
import { BaseClass } from 'src/app/shared/models/base-class';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { ClientsService } from 'src/app/shared/services/rest/clients.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-files-settings-to-employee',
  templateUrl: './files-settings-to-employee.component.html',
  styleUrls: ['./files-settings-to-employee.component.scss']
})

export class FilesSettingsToEmployeeComponent extends BaseClass implements OnInit, OnDestroy {
  public form: FormGroup;
  public rules: any;
  public managerTasks: any;
  public clientTasks: any;
  public groupManagers: any;
  public emplRules: any;
  public partners: any;

  public imgRoute: any = '';
  public host: any = environment.host;

  public isSubmit: boolean = false;
  public is_mobile: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<FilesSettingsToEmployeeComponent>,
    private fb: FormBuilder,
    private bottomSheet: MatBottomSheet,
    private dialog: MatDialog,
    private companyService: CompanyService,
    private sm: StorageManagerService,
    private taskService: TaskService,
  ) { super() }

  ngOnInit(): void {
    console.log("FilesSettingsToEmployeeComponent", this.data);
    if (this.data.is_partner) {
      this.form = this.fb.group({
        company_id: this.data.company.id,
        partner_company_id: this.data.employee.partner_company_id,
        is_originals: 1,
        type: 0
      })
    } else {
      this.form = this.fb.group({
        company_id: this.data.company.id,
        employee_id: this.data.employee.id,
        is_originals: 1,
        partner_company_id: '',
        type: 0
      })
    }
    this.checkIsMobile()
    this.getImgRoute();
    if (!this.data.is_partner) {
      this.getPartners();
    }
    this.getFileRules();

    if (!this.data.is_partner && this.form.get('partner_company_id')) {
      this.form.get('partner_company_id').valueChanges.subscribe(res => {
        this.getFileRules(res)
      })
      this.form.get('type').valueChanges.subscribe(res => {
        if (!!res) {
          this.getFileRules(this.form.value.partner_company_id || 0)
        } else {
          this.getFileRules()
        }
      })
    }
  }

  getPartners() {
    this.attachSubscriptions(
      this.companyService.getPartners({company_id: this.data.company.id}).subscribe(resp => {
        console.log("getPartners", resp);
        this.partners = resp;
        // this.partners$.next(this.partners.slice());
      })
    )
  }

  getFileRules(val?) {
    if (!!val || val === 0) {
      let filterData:any = {company_id: this.data.company.id, partner_company_id: this.data.company.id, partner_employee_id: this.data.employee.id}
      if (!!val) {
        filterData.original_company_id = val
      }
      this.attachSubscriptions(
        this.taskService.getFileRules(filterData).subscribe(resp => {
          this.emplRules = resp
        })
      )
    } else {
      if (this.data.is_partner) {
        this.attachSubscriptions(
          this.taskService.getFileRules({company_id: this.data.company.id, partner_company_id: this.data.employee.partner_company_id}).subscribe(resp => {
            this.emplRules = resp
          })
        )
      } else {
        let filterData:any = {company_id: this.data.company.id, employee_id: this.data.employee.id, original_company_id: this.data.company.id}
        this.attachSubscriptions(
          this.taskService.getFileRules(filterData).subscribe(resp => {
            this.emplRules = resp
          })
        )
      }
    }
  }

  checkIsGlobal(item) {
    let arr = ["group_id", "task_id", "task_operation_id", "discussion_id", "file_id"]
    if (arr.filter(x => item[x] > 0).length > 0) {
      return false
    } else {
      return true
    }
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }


  checkIsMobile() {
    if (window.innerWidth <= 769) {
      this.is_mobile = true;
    } else {
      this.is_mobile = false;
    }
    this.onResize();
  }

  onResize() {
    this.attachSubscriptions(
      fromEvent(window, "resize").pipe(
        map(() => window.innerWidth)
      ).subscribe((wWidth) => {
          if (wWidth <= 769) {
            this.is_mobile = true;
          } else {
            this.is_mobile = false;
          }
        }
      )
    )
  }

  deleteFileRule(id, i) {
    this.attachSubscriptions(
      this.taskService.deleteFileRule(id, this.data.company.id).subscribe(resp => {
        this.emplRules.splice(i, 1);
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false});
  }

  getPartnerById(id) {
    return this.partners.find(x => x.partner_company_id == id)
  }

  onSubmit() {
    let formData = {...this.form.value};

    if (formData.type == 1) {
      formData.partner_employee_id = formData.employee_id
      formData.partner_company_id = this.form.value.company_id
      formData.company_id = this.form.value.partner_company_id
      delete formData.employee_id
    } else {
      if (!this.data.is_partner) {
        delete formData.partner_company_id
      }
    }
    delete formData.type
    
    this.isSubmit = true;
    this.attachSubscriptions(
      this.taskService.addFileRule(formData, this.data.company.id).subscribe(resp => {
        this.isSubmit = false;
        this.getFileRules(this.form.value.type == 1 ? formData.company_id : null);
      })
    )
  }


  ngOnDestroy() {
    this.clearSubscriptions()
  }

}
