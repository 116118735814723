import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReplaySubject } from 'rxjs';
import { debounceTime, map, tap } from 'rxjs/operators';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { FileService } from '../../services/rest/file.service';
import { makeArray } from '../../functions/objToArray';

@Component({
  selector: 'app-migrate-target-files',
  templateUrl: './migrate-target-files.component.html',
  styleUrls: ['./migrate-target-files.component.scss']
})
export class MigrateTargetFilesComponent extends BaseClass implements OnInit, OnDestroy {
  public form: FormGroup = this.fb.group({
    company_id: this.data.company.id,
    task_id: this.data.target.task_id,
    task_operation_id: this.data.target.task_operation_id,
    to_storage_code: ['', Validators.required]
  });

  public storages: any;
  public migrations: any;

  public isSubmit: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<MigrateTargetFilesComponent>,
    public layoutService: LayoutService,
    public fileService: FileService,
  ) { super() }

  ngOnInit(): void {
    console.log("MigrateTargetFilesComponent", this.data)
    this.getStorages();
    this.getMigrations();
  }

  getStorages() {
    this.attachSubscriptions(
      this.fileService.getStorages(this.data.company.id).subscribe(resp => {
        this.storages = makeArray(resp)
      })
    )
  }

  getMigrations() {
    let filter:any = {
      task_id: this.data.target.task_id,
      task_operation_id: this.data.target.task_operation_id
    }
    this.attachSubscriptions(
      this.fileService.getBatchMigrations(this.data.company.id, filter).subscribe(resp => {
        this.migrations = resp;
        console.log("getMigrations", this.migrations);
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false});
  }

  onSubmit() {
    this.isSubmit = true;
    let sbmtData = {...this.form.value}
    this.attachSubscriptions(
      this.fileService.addBatchMigration(this.data.company.id, sbmtData).subscribe(resp => {
        this.isSubmit = false;
        this.form.patchValue({
          to_storage_code: ['', Validators.required],
        })
        this.getMigrations();
      }, error => {
        this.isSubmit = false;
        this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
      })
    )
  }

  deleteBatchMigration(id, i) {
    this.attachSubscriptions(
      this.fileService.deleteBatchMigration(this.data.company.id, id).subscribe(resp => {
        this.migrations.splice(i, 1)
      }, error => {
        this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
      })
    )
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
