import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BaseClass } from 'src/app/shared/models/base-class';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { AuthService } from 'src/app/shared/services/rest/auth.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-company-create',
  templateUrl: './company-create.component.html',
  styleUrls: ['./company-create.component.scss']
})
export class CompanyCreateComponent extends BaseClass implements OnInit, OnDestroy {
  public host: any = environment.host;
  public imgRoute: any = '';
  public isSubmit: boolean = false;
  public user: any = {};
  public user_id: number;
  public form: FormGroup;

  constructor(
    private sm: StorageManagerService,
    private auth: AuthService,
    private companyService: CompanyService,
    private fb: FormBuilder,
    private router: Router
  ) { super() }

  ngOnInit(): void {
    window.scroll(0,0);
    this.getImgRoute();
    this.getCsrf();
    this.getUser();

    this.form = this.fb.group({
      name: ['', Validators.required]
    })

  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  getCsrf() {
    this.attachSubscriptions(
      this.auth.$userToken.subscribe(resp => {
        this.user_id = resp.user_id;
        this.sm.localStorageSetItem("csrf_param", resp.csrf_param)
        this.sm.localStorageSetItem("csrf_token", resp.csrf_token)
      })
    )
  }

  getUser() {
    this.attachSubscriptions(
      this.auth.$user.subscribe(resp => {
        this.user = resp;
      })
    )
  }

  onSubmit() {
    this.isSubmit = true;
    this.attachSubscriptions(
      this.companyService.createCompany(this.form.value).subscribe(resp => {
        this.isSubmit = false;
        this.router.navigate(['/main']);
      })
    )
  }

  goBack() {
    history.back()
  }

  ngOnDestroy(): void {
    this.clearSubscriptions();
  }

}
