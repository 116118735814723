<h2 mat-dialog-title class="dialog_title">
    <span>
        {{ "Move job to other task" | translate }}:
    </span>
    <button class="ml-1" mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h2>
<mat-dialog-content class="mat-typography" dirEnter (dirSubmit)="!form.valid ? null : onSubmit()">
    <div class="container form_wrapper">
        <form [formGroup]="form">
            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ "Select task" | translate }}</mat-label>
                <!-- (selectionChange)="selectValue($event)" -->
                <mat-select #consistContainer formControlName="task_id">
                    <mat-option>
                        <ngx-mat-select-search 
                        placeholderLabel="{{ 'Search' | translate }}" 
                        noEntriesFoundLabel="{{ 'no matching card found' | translate }}"
                        [formControl]="taskControl"></ngx-mat-select-search>
                    </mat-option>
                    <mat-progress-bar mode="indeterminate" *ngIf="isLoad" class="select_progress"></mat-progress-bar>
                    <!-- mat_proggress_w8 cases_progress -->
                    <mat-option *ngFor="let task of tasks$ | async" [value]="task.id">
                        <div class="task_ids_wrp"> 
                            {{ task.name }}
                            <div class="task_ids">
                                {{task.custom_id}} 
                                <span>
                                    <div>{{task.internal_id}}</div>
                                    <div>{{task.id}}</div>
                                </span>
                            </div>
                        </div>
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </form>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button (click)="close()">
        {{ "Back" | translate }}
    </button>
    <button class="ml-1 d-f-btn" (click)="onSubmit()" mat-raised-button cdkFocusInitial color="primary" [disabled]="!form.valid || isSubmit">
        <mat-spinner class="spin_16 mr-1" *ngIf="isSubmit"></mat-spinner>  
        {{ "Save" | translate }}
    </button>
</mat-dialog-actions>
