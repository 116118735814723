import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { AuthService } from 'src/app/shared/services/rest/auth.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { mimeTypes } from 'mime-wrapper';

import * as moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/en-gb';
import 'moment/locale/uk';
import 'moment/locale/ru';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { BaseClass } from 'src/app/shared/models/base-class';
import { TasksHaveSameUrlComponent } from 'src/app/shared/global_components/tasks-have-same-url/tasks-have-same-url.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { catchError, debounceTime, distinctUntilChanged, filter, map, retry, switchMap, tap } from 'rxjs/operators';
import { fromEvent, ReplaySubject, of, throwError, forkJoin } from 'rxjs';
import { MatSelect } from '@angular/material/select';
import { GlobalDataService } from 'src/app/shared/services/common/global-data.service';
import { NgxMatDateAdapter } from '@angular-material-components/datetime-picker';
import { SameUrlTasksComponent } from 'src/app/components/atTasksDialog/same-url-tasks/same-url-tasks.component';
import { DeleteAlertComponent } from 'src/app/shared/global_components/delete-alert/delete-alert.component';
import { UrlAnalyticsComponent } from '../url-analytics/url-analytics.component';
import { MobUrlAnalyticsComponent } from '../mob-url-analytics/mob-url-analytics.component';
import { environment } from 'src/environments/environment';
import { Moment } from 'moment';
import { transformToWatchUrl } from 'src/app/shared/functions/liveUrlTransform';


export const MY_FORMATS = {
  parse: {
    dateInput: 'LLLLL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
export const MY_NEW_FORMATS = {
  parse: {
    dateInput: 'MM/DD/YYYY',
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'MM/DD/YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@Component({
  selector: 'app-add-task-profile',
  templateUrl: './add-task-profile.component.html',
  styleUrls: ['./add-task-profile.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_NEW_FORMATS},
  ]
})
export class AddTaskProfileComponent extends BaseClass implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('profilesContainer') profilesContainer: MatSelect;

  public userIsSelectedStatus: boolean = true;

  public profiles: any[] = [];
  public pagination: any;
  public channelFiles: any = [];
  public selectedFiles: any = [];
  public unSelectedFiles: any = [];
  public page: number = 1;
  public host: any = environment.host;
  public disabledContentTypes: any = [];
  public imgRoute: any = '';
  public user: any = {};
  public previewImgErrorCount: number = 0;
  public user_id: number;
  public form: FormGroup;
  public company_id: any;
  public linkData: any;
  public activeLang: any;
  public task_id: any;
  public profileStatuses: any;
  public submited: boolean = false;
  public photoEdited: boolean = false;
  public hasErrorInYouTube: boolean = false;
  public showFiles: boolean = false;
  public isFormChange: boolean = false;
  public isParseWork: boolean = false;
  public isSubmit: boolean = false;
  
  public savedScrollPosition: number = 0;

  public required: any = {
    content_url: false,
    content_name: false,
    content_published_at: false,
    content_type_id: false,
    content_status_id: false
  }

  public is_system_status: boolean = false;
  public contentImage: FormControl = new FormControl();
  public profilesMoreControl: FormControl = new FormControl();
  public profiles$: ReplaySubject<any> = new ReplaySubject<any>(1);
  
  public contentPagination: any;
  public contentPage: number = 1;

  public urlNotYet: FormControl = new FormControl(false);

  public contentTypes: any[] = [];
  public contentTypeMoreControl: FormControl = new FormControl();
  public contentTypes$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public timeZone = - new Date().getTimezoneOffset();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddTaskProfileComponent>,
    private sm: StorageManagerService,
    private auth: AuthService,
    private companyService: CompanyService,
    private globalDataService: GlobalDataService,
    private fb: FormBuilder,
    private bottomSheet: MatBottomSheet,
    private dialog: MatDialog,
    private taskService: TaskService,
    private languageService: LanguageService,
    private _ngx_adapter: NgxMatDateAdapter<any>,
    private _adapter: DateAdapter<any>,
    private layoutService: LayoutService
  ) {super()}

  ngOnInit(): void {
    this.data.dialogHasFm = true;
    this.dialogRef.addPanelClass("create_task_by_manager_modal")
    console.log("AddTaskProfileComponent", this.data)

    this.company_id = this.data.company_id;
    this.task_id = this.data.task.id;
    if (this.data.profile && this.data.profile.contentStatus && this.data.profile.contentStatus.is_user == 0) {
      this.is_system_status = true
    }
    this.form = this.fb.group({
      task_id: this.task_id,
      channel_id: ['', Validators.required],
      content_url: ['', Validators.required],
      content_name: ['', Validators.required],
      is_content_views_count_update : 0,
      is_bravourl_content_views_count_update : 0,
      content_image: '',
      content_filename: '',
      external_content_id: '',
      content_type_id: ['', Validators.required],
      content_published_at: '',
      content_status_id: ['', Validators.required],
      content_views_count: '',
      date: this.fb.group({
        time: '',
        day: ''
      })
    })



    if (this.data.profile && this.data.profile.channel && this.data.profile.channel.platform_id) {
      this.contentPage = 1;
      this.contentTypes = [];
      this.contentTypes$.next(this.contentTypes.slice());
      console.log("getContentTypes ONINIT", this.data.profile.channel.platform_id)
      this.getContentTypes(this.data.profile.channel.platform_id, this.contentPage);
    }


    if (this.data.is_edit) {
      this.getTaskChannelFile()
    }

    this.attachSubscriptions(
      this.form.get('date').valueChanges.subscribe(resp => {
        console.log(resp)
        let dateForm = {...this.form.get("date").value}
        if (!dateForm.day) {
          dateForm.day = moment(0).hour(0).minute(0)
        }
        let testTimeAndDate = dateForm.day.clone()
        testTimeAndDate.hour(Number(dateForm.time.split(":")[0]))
        testTimeAndDate.minute(Number(dateForm.time.split(":")[1]))
        this.form.patchValue({
          content_published_at: testTimeAndDate
        })
        console.log('this.form.value', this.form.value);
      })
    )

    this.attachSubscriptions(
      this.form.get("content_status_id").valueChanges.subscribe(resp => {
        if (this.data.is_edit) {
          console.log('this.form.get("content_status_id").valueChanges', resp);
          if ([101, 102, 202, 203, 301, 401, 404].includes(this.form.get('content_type_id').value) && this.form.get('content_status_id').value == 4 && !!this.form.get('content_url').value) {
            this.form.patchValue({
              is_content_views_count_update: true,
              is_bravourl_content_views_count_update: true
            })
          } else {
            this.form.patchValue({
              is_content_views_count_update: false,
              is_bravourl_content_views_count_update: false
            })
          }

          if (resp == 4 && this.is_system_status && this.data.profile.publishing_error == 'Uploaded media not found' ) {
            console.log("URA URA URA");
            this.is_system_status = false;
          }
        }
      })
    )

    this.attachSubscriptions(
      this.form.get("channel_id").valueChanges.subscribe(resp => {
        console.log('"channel_id" valueChanges', resp)
        if (!!resp && this.profiles.find(x => x.id == resp)) {
          console.log('"channel_id" valueChanges 2', resp)
          if (!this.data.profile) {
            this.data.profile = {}
          }
          this.disabledContentTypes = []
          if (this.form.value.content_url) {
            if (this.form.value.content_url.indexOf("instagram.com") != -1 && this.form.value.content_url.indexOf("reel") != -1) {
              this.form.patchValue({
                content_type_id: 202
              })
            } else if (this.form.value.content_url.indexOf("facebook.com") != -1 && this.form.value.content_url.indexOf("reel") != -1) {
              this.form.patchValue({
                content_type_id: 404
              })
            } else if (this.form.value.content_url.indexOf("youtube.com") != -1 || this.form.value.content_url.indexOf("youtu.be") != -1) {
              if (this.form.value.content_url.indexOf("shorts") != -1) {
                this.form.patchValue({
                  content_type_id: 102
                })
              } else if (this.form.value.content_url.indexOf("community") != -1) {
                this.disabledContentTypes = [101, 102]
                if (this.disabledContentTypes.includes(this.form.value.content_type_id)) {
                  this.form.patchValue({
                    content_type_id: ''
                  })
                }
              }
            } else {
              this.form.patchValue({
                content_type_id: ''
              })
            }
          } else {
            this.form.patchValue({
              content_type_id: ''
            })
          }


          if (this.data.is_edit) {          
            if ([101, 102, 202, 203, 301, 401, 404].includes(this.form.get('content_type_id').value) && this.form.get('content_status_id').value == 4 && !!this.form.get('content_url').value) {
              this.form.patchValue({
                is_content_views_count_update: true,
                is_bravourl_content_views_count_update: true,
              })
            } else {
              this.form.patchValue({
                is_content_views_count_update: false,
                is_bravourl_content_views_count_update: false,
              })
            }
          }


          this.data.profile.channel = this.getProfileById(resp);
          let platform_id = this.profiles.find(x => x.id == resp).platform_id
       
          this.form.patchValue({
            external_content_id: this.data.profile && !!this.data.profile.external_content_id ? this.data.profile.external_content_id : ''
          })
          // if (platform_id == 2) {
          //   this.form.patchValue({
          //     external_content_id: this.data.profile && !!this.data.profile.external_content_id ? this.data.profile.external_content_id : ''
          //   })
          // }

          this.contentPage = 1;
          this.contentTypes = [];
          this.contentTypes$.next(this.contentTypes.slice());
          console.log("getContentTypes before", platform_id)
          this.getContentTypes(platform_id, this.contentPage);
        }
      })
    )

    this.attachSubscriptions(
      this.profilesMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchProfiles(resp))
    )

    this.attachSubscriptions(
      this.contentTypeMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchContentTypes(resp))
    )

    this.attachSubscriptions(
      this.contentImage.valueChanges.subscribe(res => {
        this.previewImgErrorCount = 0
      })
    )

    this.form.valueChanges.subscribe(() => this.isFormChange = true);

    this.dialogRef.backdropClick().subscribe(e => {
      e.preventDefault();
      if (this.isFormChange) {
        this.layoutService.openBottomSheet(this.dialogRef);
      } else {
        this.close();
      }
    });

    this.getCompany();
    this.getImgRoute();
    this.getCsrf();
    this.getUser();
    this.getProfiles(this.page);
    this.getLangData();
    this.getTaskProfiles();
    this.getProfilesStatus();
    
    if (this.data.is_edit) {
      if (!this.linkData) {
        this.linkData = {
          url: this.data.profile.content_url
        }
      }
      this.contentImage.patchValue(this.data.profile.content_image)

      console.log("this.linkDatathis.linkData", this.linkData)
      
      this.form.patchValue({
        task_id: this.task_id,
        channel_id: this.data.profile.channel_id,
        content_name: this.data.profile.content_name,
        content_filename: this.data.profile.content_filename,
        content_type_id: this.data.profile.content_type_id,
        content_image: this.data.profile.content_image,
        is_content_views_count_update: !!this.data.profile.is_content_views_count_update,
        is_bravourl_content_views_count_update: !!this.data.profile.is_bravourl_content_views_count_update,
        content_views_count: this.data.profile.content_views_count.toString(),
        content_url: this.data.profile.content_url,
        content_status_id: this.data.profile.content_status_id != 0 ? +this.data.profile.content_status_id : 1,
        content_published_at: this.data.profile.content_published_at != '0' ? moment(this.showUserDate(false, this.data.profile.content_published_at) * 1000) : '',
      })

      this.form.get('date').patchValue({
        day: this.data.profile.content_published_at ? moment(this.showUserDate(false, this.data.profile.content_published_at) * 1000).hour(0).minute(0) : '',
        time: this.data.profile.content_published_at ? (this.numberToTimeFormat(moment(this.showUserDate(false, this.data.profile.content_published_at) * 1000).hours()) + ":" + this.numberToTimeFormat(moment(this.showUserDate(false, this.data.profile.content_published_at) * 1000).minutes())) : '',
      })
      this.form.updateValueAndValidity()

      let platform_id = this.data.profile.channel ? this.data.profile.channel.platform_id : 0;
      
      this.form.patchValue({
        external_content_id: this.data.profile && !!this.data.profile.external_content_id ? this.data.profile.external_content_id : ''
      })
      // if (platform_id == 2) {
      //   this.form.patchValue({
      //     external_content_id: this.data.profile && !!this.data.profile.external_content_id ? this.data.profile.external_content_id : ''
      //   })
      // }

      this.urlNotYet.patchValue(!this.form.get("content_url").value && this.form.get("content_status_id").value != 1 && this.form.get("content_status_id").value != 4 ? true : false);
      // time: "06:00",

      // this.form.get('date').patchValue({
      //   day: resp.published_at ? moment(resp.published_at*1000).hour(0).minute(0) : '',
      //   time: resp.published_at ? (this.numberToTimeFormat(moment(resp.published_at*1000).hours()) + ":" + this.numberToTimeFormat(moment(resp.published_at*1000).minutes())) : '',
      // })

    }

    this.attachSubscriptions(
      this.form.get("content_url").valueChanges.pipe(
        debounceTime(300)
      ).subscribe(resp => {
        if (this.data.is_edit && !!resp && !this.data.profile.content_url && !this.userIsSelectedStatus) {
          this.form.patchValue({
            content_status_id: ''
          })
        } 

        if ([101, 102, 202, 203, 301, 401, 404].includes(this.form.get('content_type_id').value) && this.form.get('content_status_id').value == 4 && !!this.form.get('content_url').value) {
          this.form.patchValue({
            is_content_views_count_update: true,
            is_bravourl_content_views_count_update: true,
          })
        } else {
          this.form.patchValue({
            is_content_views_count_update: false,
            is_bravourl_content_views_count_update: false,
          })
        }

        if (!!resp) {
          this.getLinkData()
        }
      })
    )
  }

  showStats() {
    if (!this.data.is_mobile) {
      const dialogRef = this.dialog.open(UrlAnalyticsComponent, {
        disableClose: true,
        autoFocus: false,
        data: this.data
      });
  
      this.attachSubscriptions(
        dialogRef.afterClosed().subscribe(result => {
          if (!!result.data && !!result.data.profile.is_content_views_count_update != !!this.form.value.is_content_views_count_update) {
            this.form.patchValue({
              is_content_views_count_update: !!result.data.profile.is_content_views_count_update
            })
          }
          if (!!result.data && !!result.data.profile.is_bravourl_content_views_count_update != !!this.form.value.is_bravourl_content_views_count_update) {
            this.form.patchValue({
              is_bravourl_content_views_count_update: !!result.data.profile.is_bravourl_content_views_count_update
            })
          }
        })
      )
    } else {
      const dialogRef = this.dialog.open(MobUrlAnalyticsComponent, {
        backdropClass: ['mob_interface_backdrop'],
        panelClass: ['mob_interface_panel', 'animate__animated', 'animate__slideInUp'],
        disableClose: true,
        data: this.data
      });
  
      this.attachSubscriptions(
        dialogRef.afterClosed().subscribe(result => {
          if (!!result.data && !!result.data.profile.is_content_views_count_update != !!this.form.value.is_content_views_count_update) {
            this.form.patchValue({
              is_content_views_count_update: !!result.data.profile.is_content_views_count_update
            })
          }
          if (!!result.data && !!result.data.profile.is_bravourl_content_views_count_update != !!this.form.value.is_bravourl_content_views_count_update) {
            this.form.patchValue({
              is_bravourl_content_views_count_update: !!result.data.profile.is_bravourl_content_views_count_update
            })
          }
        })
      )
    }
  }

  userSelectedStatus(status) {
    if (status.is_user == 0) {
      return
    }
    let prevForm = {...this.form.value};
    // if (this.data.profile && this.data.profile.id && prevForm.content_status_id == 2 && [3,4].includes(status.id) && !!prevForm.content_url) {
    //   // this.getLinkData(status.id)
    //   // this.attachSubscriptions(
    //   //   this.taskService.editProfile(this.data.profile.id, {content_status_id: status.id}, this.company_id).subscribe(res => {

    //   //   })
    //   // )
    // }

    this.userIsSelectedStatus = true
    if (status.id == 1 || status.id == 4) {
      this.urlNotYet.patchValue(false);
    }
  }

  getTaskChannelFile() {
    this.attachSubscriptions(
      this.taskService.getTaskChannelFile(this.company_id, {task_channel_id: this.data.profile.id}).subscribe(resp => {
        this.selectedFiles = []
        this.channelFiles = resp
        // this.fileForm.patchValue({
        //   task_channel_id: this.data.profile.id,
        //   file_id: this.channelFile.file_id,
        //   is_thumbnail: !!this.channelFile.thumbnail ? 1 : 0
        // })
      })
    )
  }

  getChannelFilesId() {
    return this.channelFiles.slice().map(x => x.file_id).concat(this.selectedFiles.slice().map(x => x.id))
  }

  getUnSelectedFilesId() {
    return this.unSelectedFiles.slice().map(x => x.id)
  }

  changedTime(e) {
    console.log("changedTimechangedTimechangedTimechangedTimechangedTimechangedTimechangedTime",e)
  }

  numberToTimeFormat(val) {
    if (Number(val) < 10) {
      val = "0" + val
    }
    return val
  }

  deletePhoto() {
    this.form.patchValue({
      content_image: ''
    })
    this.photoEdited = false;
  }

  editPhoto() {
    this.contentImage.patchValue(this.form.get('content_image').value)
    this.photoEdited = true;
  }

  savePhoto() {
    this.form.patchValue({
      content_image: this.contentImage.value
    })
    this.photoEdited = false;
  }

  closePhoto() {
    this.photoEdited = false;
  }

  onImgError(event){
    event.target.src = this.imgRoute+'/assets/img/image_black_48dp.svg'
  }

  onPreviewImgError(event) {
    console.log("onPreviewImgError", this.linkData, this.contentImage.value)
    if (!!this.contentImage.value && this.previewImgErrorCount < 3) {
      this.previewImgErrorCount++
      if (event.target.src.indexOf("/api/get-url-data/proxy/") != -1 && this.data.profile && this.data.profile.content_image_file_id) {
        event.target.src = this.host + this.data.profile.file.original;
      } else {
        event.target.src = this.host + '/api/get-url-data/proxy/?company_id=' + this.data.company.id + (this.data.profile.company_id != this.data.company.id ? ('&partner_company_id=' + this.data.profile.company_id) : '') + '&url=' + encodeURIComponent(this.contentImage.value)
      }
    } else {
      return
    }
  }

  copyLink(type) {
    this.layoutService.showSnackBar({name: type}, marker("Copied"), SnackBarItem)
  }
  
  showUserDate(date:any = false, timestamp:any = false) {
    let timeAndDate;
    if (date) {
      timeAndDate = moment(date).clone()
    } else {
      timeAndDate = moment(timestamp*1000).clone()
    }

    const utcOffset = moment.tz(timeAndDate, this.data.company.timezone).utcOffset();
    
    let utcTimestamp = moment(timeAndDate).format("X")
    
    let difference = (Number(this.timeZone) - Number(utcOffset))*60
    // console.log(difference);
    
    let workSpaceTimeZone;
    if (date) {
      workSpaceTimeZone = Number(utcTimestamp) + Number(difference)
    } else {
      workSpaceTimeZone = Number(utcTimestamp) - Number(difference)
    }
    return workSpaceTimeZone
  }

  onSearchContentTypes(resp) {
    if (!this.contentTypes) {
      return;
    }

    if (!resp) {
      this.contentTypes$.next(this.contentTypes.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.contentTypes$.next(
      this.contentTypes.filter(z => (z.name.toLowerCase().indexOf(resp) > -1))
    );
  }

  selectType(type) {
    console.log(type);
    let id = !!type.id ? type.id : type.value
    if ([101, 102, 202, 203, 301, 401, 404].includes(id) && this.form.get('content_status_id').value == 4 && !!this.form.get('content_url').value) {
      this.form.patchValue({
        is_content_views_count_update: true,
        is_bravourl_content_views_count_update: true,
      })
    } else {
      this.form.patchValue({
        is_content_views_count_update: false,
        is_bravourl_content_views_count_update: false,
      })
    }
    this.form.patchValue({
      content_type_id: id
    })
  }

  getContentTypes(platform_id, contentPage) {
    console.log("getContentTypes")
    this.attachSubscriptions(
      this.companyService.getContentTypes(platform_id, contentPage).pipe(
        tap(el => {
          this.contentPagination = {
            'pageCount': el.headers.get('x-pagination-page-count'),
            'perPage': el.headers.get('x-pagination-per-page'),
            'totalCount': el.headers.get('x-pagination-total-count'),
            'currentPage': el.headers.get('x-pagination-current-page'),
          }
        }),
        map(el => el.body)
      ).subscribe(resp => {
        this.contentTypes.push(...resp);
        this.contentTypes$.next(this.contentTypes.slice());
        console.log("getContentTypes", this.contentPage, this.contentTypes)
        this.contentPage = this.contentPage + 1;

        if (this.contentPagination.pageCount >= this.contentPage) {
          this.getContentTypes(platform_id, this.contentPage);
        }
      })
    )
  }

  onSearchProfiles(resp) {
    if (!!resp) {
      resp = resp.toLowerCase();
    }

    this.page = 1;
    if (this.form.value.channel_id) {
      this.profiles = this.profiles.filter(x => x.id == this.form.value.channel_id);
    } else {
      this.profiles = [];
    }
    this.getProfiles(this.page, resp);
  }

  selectFile(file) {
    if (this.channelFiles.filter(x => x.file_id == file.id).length != 0) {
      if (this.unSelectedFiles.filter(x => x.id == file.id).length == 0) {
        this.unSelectedFiles.push(file)
      } else {
        this.unSelectedFiles.splice(this.unSelectedFiles.indexOf(file), 1)
      }
    } else if (this.selectedFiles.filter(x => x.id == file.id).length == 0) {
      this.selectedFiles.push(file)
      console.log("selectedFiles", this.selectedFiles)
      // this.fileForm.patchValue({
      //   file_id: this.selectedFile.id,
      //   is_thumbnail: !!this.selectedFile.thumbnail ? 1 : 0
      // })
    } else {
      this.selectedFiles.splice(this.selectedFiles.indexOf(file), 1)
      // this.selectedFile = undefined
      // this.fileForm.patchValue({
      //   file_id: '',
      //   is_thumbnail: 0
      // })
    }
    console.log("selectedFiles", this.selectedFiles)
    console.log("unSelectedFiles", this.unSelectedFiles)
    console.log("channelFiles", this.channelFiles)
    console.log("getChannelFilesId", this.getChannelFilesId())
  }

  ngAfterViewInit(): void {
    this.attachSubscriptions(
      this.profilesContainer.openedChange.subscribe((e) => {
        if (e) {
          this.onScroll()
        }
      })
    )
  }

  onScroll() {
    this.attachSubscriptions(
      fromEvent(this.profilesContainer.panel.nativeElement, "scroll").pipe(
        // Фильтр для определения, когда нужно подгружать новые данные
        filter((e: any) => e.target.scrollTop >= e.target.scrollHeight - e.target.offsetHeight - 400),
        // Сохранение текущей позиции скролла
        map(() => this.profilesContainer.panel.nativeElement.scrollTop),
        debounceTime(300),
        map(y => Math.ceil((y + this.profilesContainer.panel.nativeElement.innerHeight) / 400)),
        distinctUntilChanged()
      ).subscribe(() => {
        // Сохраняем текущую позицию скролла перед подгрузкой новых данных
        this.savedScrollPosition = this.profilesContainer.panel.nativeElement.scrollTop;

        // Загружаем новые данные, если нужно
        if (this.page <= this.pagination['pageCount']) {
          this.getProfiles(this.page, undefined, true)
        }
      })
    );
  }

  getProfiles(page, search?, is_scroll?) {
    let filterVal:any = {};
    if (search || this.profilesMoreControl.value) {
      filterVal.q = search || this.profilesMoreControl.value
    }
    this.attachSubscriptions(
      this.companyService.getAllProfilesDyn(page, this.company_id, '20', filterVal).pipe(
        tap(el => {
          this.pagination = {
            'pageCount': el.headers.get('x-pagination-page-count'),
            'perPage': el.headers.get('x-pagination-per-page'),
            'totalCount': el.headers.get('x-pagination-total-count'),
            'currentPage': el.headers.get('x-pagination-current-page'),
          }
        }),
        map(el => el.body)
      ).subscribe(resp => {
        resp.forEach(k => {
          if (this.profiles.filter(x => x.id == k.id).length == 0) {
            this.profiles.push(k);
          }
        });
        this.profiles$.next(this.profiles.slice());
        this.page = this.page + 1;

        console.log("this.profilesContainer.panel.nativeElement.scrollTop", this.profilesContainer.panel.nativeElement.scrollTop)
        console.log("this.savedScrollPosition", this.savedScrollPosition)
        this.profilesContainer.panel.nativeElement.scrollTop = this.savedScrollPosition;

        if (!!this.data.is_edit && !this.data.profile.channel && !!this.data.profile.channel_id && this.profiles.find(x => x.id == this.data.profile.channel_id)) {
          let platform_id = this.profiles.find(x => x.id == this.data.profile.channel_id).platform_id
          this.contentPage = 1;
          this.contentTypes = [];
          this.contentTypes$.next(this.contentTypes.slice());
          this.getContentTypes(platform_id, this.contentPage);
        }
      })
    )
  }

  getProfilesStatus() {
    this.attachSubscriptions(
      this.globalDataService.getContentStatus().subscribe(resp => {
        console.log("getContentStatus", resp);
        this.profileStatuses = resp.slice();
      })
    )
    // this.attachSubscriptions(
    //   this.taskService.getProfileStatuses().subscribe(resp => {
    //     console.log("getProfilesStatus", resp)
    //     this.profileStatuses = resp;
    //   })
    // )
  }

  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData().subscribe(resp => {
        this._ngx_adapter.setLocale(resp.active);
        this._adapter.setLocale(resp.active);
        this.activeLang = resp.active;
      })
    )
  }

  getTaskProfiles() {
    this.attachSubscriptions(
      this.taskService.getTaskProfiles(this.task_id, this.company_id).subscribe(resp => console.log("PROFILES", resp))
    )
  }

  getCompany() {
    this.attachSubscriptions(
      this.companyService.getCompany(this.company_id).subscribe(resp => {
        this.companyService.company$.next(resp[0]);
      })
    )
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  getCsrf() {
    this.attachSubscriptions(
      this.auth.$userToken.subscribe(resp => {
        this.user_id = resp.user_id;
        this.sm.localStorageSetItem("csrf_param", resp.csrf_param)
        this.sm.localStorageSetItem("csrf_token", resp.csrf_token)
      })
    )
  }

  getUser() {
    this.attachSubscriptions(
      this.auth.$user.subscribe(resp => {
        this.user = resp;

        if (this.user.is_root) {
          if (!this.data.is_edit) {
            this.form.addControl('is_get_data_queue', this.fb.control(0))
          }
        }
      })
    )
  }

  isValidNotYetDate() {
    return this.form.get('content_published_at') && this.form.get('content_published_at').value && (this.form.get('content_published_at').value as moment.Moment).isAfter(moment(30000))
  }

  understandFileType(val) {
    if (!val) {
      return ""
    }
    
    if (val == 'application/pdf') {
      return 'pdf'
    } else if (val.indexOf("/") != -1) {
      return val.split('/')[0]
    } else {
      return ""
    }
  }

  log() {
    console.log('form', this.form.value)
  }

  onSubmit() {
    // if (!this.form.valid || (this.urlNotYet.value && !this.isValidNotYetDate())) {
    //   this.submited = true;
    //   this.layoutService.showSnackBar({name: ''}, marker("You need to fill in all required fields"), SnackBarItem)
    //   return
    // }
    if (!!this.is_system_status) {
      this.attachSubscriptions(
        this.taskService.editProfile(this.data.profile.id, {content_status_id: this.form.value.content_status_id}, this.company_id).subscribe(
          resp => {
            this.dialogRef.close({event: "edit", data: resp});
            this.isSubmit = false;
          }, 
          error => {
            this.isSubmit = false;
            this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
          }
        )
      )

      return
    }

    this.submited = true;
    if (!!this.form.get("is_get_data_queue") && !!this.form.value.is_get_data_queue) {
      this.required.content_name = false;
      if (!this.form.get('content_url').value) {
        this.required.content_url = true;
      } else {
        this.required.content_url = false;
      }
      if (!this.form.get('content_type_id').value) {
        this.required.content_type_id = true;
      } else {
        this.required.content_type_id = false;
      }
      if (!this.form.get('content_status_id').value) {
        this.required.content_status_id = true;
      } else {
        this.required.content_status_id = false;
      }
    } else if (this.urlNotYet.value) {
      if (!this.form.get('content_published_at').value || !this.isValidNotYetDate()) {
        this.required.content_published_at = true;
      } else {
        this.required.content_published_at = false;
      }
      if (!this.form.get('content_type_id').value) {
        this.required.content_type_id = true;
      } else {
        this.required.content_type_id = false;
      }
      if (!this.form.get('content_status_id').value) {
        this.required.content_status_id = true;
      } else {
        this.required.content_status_id = false;
      }
    } else {
      if (this.form.get('content_status_id').value != 6 && !this.form.get('content_name').value) {
        this.required.content_name = true;
      } else {
        this.required.content_name = false;
      }
      if (this.form.get('content_status_id').value != 6 && !this.form.get('content_url').value) {
        this.required.content_url = true;
      } else {
        this.required.content_url = false;
      }
      if (!this.form.get('content_type_id').value) {
        this.required.content_type_id = true;
      } else {
        this.required.content_type_id = false;
      }
      if (!this.form.get('content_status_id').value) {
        this.required.content_status_id = true;
      } else {
        this.required.content_status_id = false;
      }
    }

    if (Object.values(this.required).includes(true)) {
      console.log("this.form.value", this.form.value)
      console.log("this.required", this.required)
      this.layoutService.showSnackBar({name: ''}, marker("You need to fill in all required fields"), SnackBarItem)
      return
    }

    this.required = {
      content_url: false,
      content_name: false,
      content_published_at: false,
      content_type_id: false,
      content_status_id: false
    }

        // this.attachSubscriptions(
    //   this.urlNotYet.valueChanges.subscribe(res => {
    //     console.log("res", res)
    //     if (res) {
    //       this.form.get('content_url').clearValidators();           
    //       this.form.get('content_name').clearValidators();           
    //       this.form.get('content_published_at').setValidators(Validators.required);
    //     } else {
    //       this.form.get('content_url').setValidators(Validators.required)
    //       this.form.get('content_name').setValidators(Validators.required)
    //       this.form.get('content_published_at').clearValidators()
    //     }
    //     this.form.get('content_url').updateValueAndValidity({onlySelf: true});
    //     this.form.get('content_name').updateValueAndValidity({onlySelf: true});
    //     this.form.get('content_published_at').updateValueAndValidity({onlySelf: true});
    //   })
    // )

    this.submited = false;
    this.isSubmit = true;


    let form = {...this.form.value};
    let sbmtData;
    if (!!this.form.get("is_get_data_queue") && !!this.form.value.is_get_data_queue) {
      sbmtData = {...form}
      delete sbmtData.date;
      delete sbmtData.content_published_at;
      delete sbmtData.content_name;
      delete sbmtData.content_image;
      delete sbmtData.content_filename;
      delete sbmtData.content_views_count;
      delete sbmtData.external_content_id;
      sbmtData.is_get_data_queue = !!sbmtData.is_get_data_queue ? 1 : 0;
      sbmtData.is_content_views_count_update = !!sbmtData.is_content_views_count_update ? 1 : 0;
      sbmtData.is_bravourl_content_views_count_update = !!sbmtData.is_bravourl_content_views_count_update ? 1 : 0;
    } else {

      let timeAndDate = form.content_published_at.clone().set({'second': 0, 'millisecond': 0})
  
      const utcOffset = moment.tz(timeAndDate, this.data.company.timezone).utcOffset();
      console.log(utcOffset);
  
      
      let utcTimestamp = moment(timeAndDate).format("X")
      console.log(utcTimestamp);
      
      let difference = (Number(this.timeZone) - Number(utcOffset))*60
      console.log("difference", difference);
      
      let workSpaceTimeZone = Number(utcTimestamp) + Number(difference)
      console.log("workSpaceTimeZone", workSpaceTimeZone);
  
      sbmtData = {...form}
  
      sbmtData.content_published_at = workSpaceTimeZone
  
      sbmtData.is_content_views_count_update = !!sbmtData.is_content_views_count_update ? 1 : 0;
      sbmtData.is_bravourl_content_views_count_update = !!sbmtData.is_bravourl_content_views_count_update ? 1 : 0;
  
      delete sbmtData.date
    }

    if (this.data.is_edit) {
      this.attachSubscriptions(
        this.taskService.editProfile(this.data.profile.id, sbmtData, this.company_id).pipe(
          switchMap(res => {
            if (this.selectedFiles.length) {
              let arr = [];
              this.selectedFiles.forEach(sFile => {
                if (this.understandFileType(sFile.content_type) == 'image' || (!!mimeTypes.getType(sFile.filename) && mimeTypes.getType(sFile.filename).indexOf("image") != -1)) {
                  arr.push({
                    company_id: sFile.company_id,
                    task_channel_id: this.data.profile.id,
                    file_id: sFile.id,
                    is_thumbnail: !!sFile.thumbnail ? 1 : 0
                  })
                } else {
                  arr.push({
                    company_id: sFile.company_id,
                    task_channel_id: this.data.profile.id,
                    file_id: sFile.id,
                    is_content: !!sFile.thumbnail ? 1 : 0
                  })
                }
              });
              return forkJoin([...arr.map(fileData => this.taskService.setTaskChannelFile(fileData, this.company_id).pipe(
                tap(b => {
                  this.channelFiles.push(b)
                  this.selectedFiles = []
                }),
                catchError((error) => {
                  res.hasError = true;
                  return of(res)
                })
              ))]).pipe(
                map(() => res),
              )
            } else {
              return of(res)
            }
          }),
          switchMap(res => {
            if (!!this.unSelectedFiles.length) {
              return forkJoin([...this.unSelectedFiles.map(file => this.taskService.unsetTaskChannelFile(this.channelFiles.find(x => x.file_id == file.id).id, this.company_id))]).pipe(
                tap(b => {
                  this.unSelectedFiles = []
                }),
                map(() => res),
                catchError((error) => {
                  res.hasError = true;
                  return of(res)
                })
              )
            } else {
              return of(res)
            }
          })
        ).subscribe(
          resp => {
            if (!!resp.hasError) {
              this.layoutService.showSnackBar({name: ''}, marker('You do not have permission to edit the publication file'), SnackBarItem)
            } 
            this.dialogRef.close({event: "edit", data: resp});
            this.isSubmit = false;
          }, 
          error => {
            this.isSubmit = false;
            this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
          }
        )
      )
    } else {
      this.attachSubscriptions(
        this.taskService.addProfile(sbmtData, this.company_id).pipe(
          switchMap(res => {
            if (this.selectedFiles.length) {
              let arr = [];
              this.selectedFiles.forEach(sFile => {
                if (this.understandFileType(sFile.content_type) == 'image' || (!!mimeTypes.getType(sFile.filename) && mimeTypes.getType(sFile.filename).indexOf("image") != -1)) {
                  arr.push({
                    company_id: sFile.company_id,
                    task_channel_id: this.data.profile.id,
                    file_id: sFile.id,
                    is_thumbnail: !!sFile.thumbnail ? 1 : 0
                  })
                } else {
                  arr.push({
                    company_id: sFile.company_id,
                    task_channel_id: this.data.profile.id,
                    file_id: sFile.id,
                    is_content: !!sFile.thumbnail ? 1 : 0
                  })
                }
              });
              return forkJoin([...arr.map(fileData => this.taskService.setTaskChannelFile(fileData, this.company_id).pipe(
                tap(b => {
                  this.channelFiles.push(b)
                  this.selectedFiles = []
                }),
                catchError((error) => {
                  res.hasError = true;
                  return of(res)
                })
              ))]).pipe(
                map(() => res),
              )
            } else {
              return of(res)
            }

          })
        ).subscribe(resp => {
          if (!!resp.hasError) {
            this.layoutService.showSnackBar({name: ''}, marker('You do not have permission to edit the publication file'), SnackBarItem)
          } 
          this.dialogRef.close({event: "Add", data: resp})
          this.isSubmit = false;
        },
        error => {
          this.isSubmit = false;
          this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
        })
      )
    }
  }

  getJob(id) {
    return (id && !!this.data.task.operations.find(x => x.id == id)) ? this.data.task.operations.find(x => x.id == id) : undefined
  }

  change() {
    console.log("CHANGE")
  }

  getLinkData() {
    // stepper: MatStepper
    if (!!this.form.value.is_get_data_queue) {
      return
    }
    if (this.isParseWork) {
      this.layoutService.showSnackBar({name: ""}, marker("Data is being received. Wait a moment, please."), SnackBarItem)
      return false;
    }

    this.disabledContentTypes = [];
    this.isParseWork = true;
 
    if (this.form.value.content_url) {
      if (this.form.value.content_url.indexOf("instagram.com") != -1 && this.form.value.content_url.indexOf("reel") != -1) {
        this.form.patchValue({
          content_type_id: 202
        })
      } else if (this.form.value.content_url.indexOf("facebook.com") != -1 && this.form.value.content_url.indexOf("reel") != -1) {
        this.form.patchValue({
          content_type_id: 404
        })
      } else if (this.form.value.content_url.indexOf("youtube.com") != -1 || this.form.value.content_url.indexOf("youtu.be") != -1) {
        if (this.form.value.content_url.indexOf("shorts") != -1) {
          this.form.patchValue({
            content_type_id: 102
          })
        } else if (this.form.value.content_url.indexOf("community") != -1) {
          this.disabledContentTypes = [101, 102]
          if (this.disabledContentTypes.includes(this.form.value.content_type_id)) {
            this.form.patchValue({
              content_type_id: ''
            })
          }
        }
      } else {
        this.form.patchValue({
          content_type_id: this.data.profile && this.data.profile.content_type_id ? this.data.profile.content_type_id : ''
        })
      }
    }

    this.attachSubscriptions(
      this.taskService.getSmartLinkData(this.form.value.content_url, this.data.profile && this.data.profile.channel_id ? this.data.profile.channel_id : 0, this.company_id, (this.data.task.company_id != this.company_id ? this.data.task.company_id : null)).subscribe(resp => {
        console.log(resp)
        console.log("urlNotYet", this.urlNotYet.value)
        this.hasErrorInYouTube = false;
        console.log("getLinkData############### ------", resp)
        this.linkData = resp
        console.log("test 1")
        if (resp.tasks && resp.tasks.length > 0 && (this.data.task && this.data.task.channels && this.data.profile && !!this.data.task.channels.find(x => x.id == this.data.profile.id) && this.data.task.channels.find(x => x.id == this.data.profile.id).content_url != this.form.value.content_url)) {

          console.log("test 2")
          let sameUrl =  this.bottomSheet.open(TasksHaveSameUrlComponent, {
            data: {
              tasks: resp.tasks
            }
          });
          console.log("test 3")

          sameUrl.afterDismissed().subscribe( data => {
            console.log("test 4")
            if (data && data.message == 'close') {
              console.log("close");
            } if (data && data.message == 'continue') {
              const dialogRef = this.dialog.open(SameUrlTasksComponent, {
                data: {
                  company: this.data.company,
                  tasks: resp.tasks,
                  resp: resp
                }
              });
          
              // this.attachSubscriptions(
              //   dialogRef.afterClosed().subscribe(result => {
              //   })
              // )
            }
          });
          
        }
        console.log("test 5")

        this.form.patchValue({
          content_name: ((!!resp.parsers && resp.parsers.includes("InstagramParser")) || (!!resp.url && resp.url.indexOf("instagram.com") != -1)) ? (resp.description ? resp.description : 'Post has no text') : (resp.name ? resp.name : 'Post has no text'),
          // content_published_at: moment(resp.published_at*1000),
          external_content_id: resp.external_content_id,
          content_filename: !!resp.filename ? resp.filename : '',
          content_image: resp.image,
          content_views_count: !!resp.views_count ? resp.views_count : '0' 
        })
        console.log("test 6")

        if (resp.published_at && this.data.profile) {
          console.log("test 7")
      
          if (moment(resp.published_at * 1000).isAfter(moment())) {
            if (this.data.profile.content_status_id <= 2) {
              this.form.patchValue({
                content_status_id: 3
              })
            }
          } else {
            if (this.data.profile.content_status_id <= 3) {
              this.form.patchValue({
                content_status_id: 4
              })
            }
          }
        }
        console.log("test 8")

        this.form.get('date').patchValue({
          day: resp.published_at ? moment(this.showUserDate(false, resp.published_at) * 1000).hour(0).minute(0) : '',
          time: resp.published_at ? (this.numberToTimeFormat(moment(this.showUserDate(false, resp.published_at) * 1000).hours()) + ":" + this.numberToTimeFormat(moment(this.showUserDate(false, resp.published_at) * 1000).minutes())) : '',
        })
        console.log("test 9")

        this.contentImage.patchValue(this.form.get('content_image').value)
        console.log("test 10")

        if (!resp.external_content_id) {
          console.log("test 11")
          if (!!resp.profile_url && this.profiles.find(el => el.url.replace("https://","").replace("http://","") == resp.profile_url.replace("https://","").replace("http://",""))) {
            console.log("test 12")
            let targetProfileId = this.profiles.find(el => el.url.replace("https://","").replace("http://","") == resp.profile_url.replace("https://","").replace("http://","")).id
            if (this.form.value.channel_id != targetProfileId) {
              console.log("test 13")
              this.form.patchValue({
                channel_id: targetProfileId
              })
              console.log("test 14")
            }
          } else {
            console.log("test 15")
            this.layoutService.showSnackBar({name: resp.profile_url}, marker("The profile for this link was not found in the system. Add it to Reports and the profile will be auto-detected."), SnackBarItem)
          }
        }

        console.log("test 16")
        if (resp.url) {
          console.log("test 17")
          if (resp.url.indexOf("instagram.com") != -1 && resp.url.indexOf("reel") != -1) {
            this.form.patchValue({
              content_type_id: 202
            })
          } else if (resp.url.indexOf("facebook.com") != -1 && resp.url.indexOf("reel") != -1) {
            this.form.patchValue({
              content_type_id: 404
            })
          } else if (resp.url.indexOf("youtube.com") != -1 || resp.url.indexOf("youtu.be") != -1) {
            if (resp.url.indexOf("shorts") != -1) {
              this.form.patchValue({
                content_type_id: 102
              })
            } else if (resp.url.indexOf("community") != -1) {
              this.disabledContentTypes = [101, 102]
              if (this.disabledContentTypes.includes(this.form.value.content_type_id)) {
                this.form.patchValue({
                  content_type_id: ''
                })
              }
            }
          }
        }
        console.log("test 18")
        this.isParseWork = false;
      })
    )
  }

  showError() {
    this.layoutService.showSnackBar({name: ''}, marker("Try again later"), SnackBarItem)
  }

  stepperBack(stepper: MatStepper) {
    stepper.previous();
  }

  onInputUrl(originalUrl: string): void {
    console.log('originalUrl', originalUrl);
    let transformedUrl = transformToWatchUrl(originalUrl);
    
    if (transformedUrl != originalUrl) {
      this.form.patchValue({
        content_url: transformedUrl
      })
    }
    console.log("transformedUrl", transformedUrl);
  }
  
  cancel() {
    this.dialogRef.close({event: "null", data: false})
  }

  getProfileById(id) {
    if (!this.profiles || this.profiles.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.profiles.find(el => el.id == id)
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
