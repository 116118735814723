import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { fromEvent, of, Subscription } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { QuestsService } from 'src/app/shared/services/rest/quests.service';

import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { Upload, UploadService } from 'src/app/shared/services/rest/upload.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { AuthService } from 'src/app/shared/services/rest/auth.service';
import { HttpClient } from '@angular/common/http';
import { FileService } from 'src/app/shared/services/rest/file.service';
import { mimeTypes } from 'mime-wrapper';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MY_NEW_FORMATS } from 'src/app/components/workspace-pages/cases/dialogs/open-task/dialogs/add-task-profile/add-task-profile.component';
@Component({
  selector: 'app-quest',
  templateUrl: './quest.component.html',
  styleUrls: ['./quest.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    {provide: MAT_DATE_FORMATS, useValue: MY_NEW_FORMATS},
  ]
})
export class QuestComponent extends BaseClass implements OnInit, OnDestroy {
  @ViewChild("tableContainer") tableContainer: ElementRef;
  public emplPage: number = 1;
  public emplPagination: any;
  public emplQuests: any[] = [];
  public company_id: number;
  public company: any;
  public user: any;
  public host: any = environment.host;
  public activeLang: any;
  public filter: FormGroup;
  public upload: Upload | undefined;
  public isSubmit: boolean = false;
  public form: FormGroup = this.fb.group({})
  public keys = [];
  public formData: {} = {};
  public values: [] = [];
  public appStatusId: number;
  private sub: Subscription;
  public uploadLimit: boolean = false;

  constructor(
    private languageService: LanguageService,
    private activatedRoute: ActivatedRoute,
    public sm: StorageManagerService,
    private companyService: CompanyService,
    private questsService: QuestsService,
    private layoutService: LayoutService,
    private uploads: UploadService,
    private _adapter: DateAdapter<any>,
    private fb: FormBuilder,
    private taskService: TaskService,
    private auth: AuthService,
    private fileService: FileService
  ) { super() }

  ngOnInit(): void {
    this.company_id = +this.activatedRoute.snapshot.queryParamMap.get('company_id');
    this.getUser();
    this.getCompany();
    this.getLangData();
  }


  cancelUpload(field) {
    field.is_paused = true;
    field.fileVal = null;
    this.uploads.cancelFile$.next({
      id: field.id,
      is_paused: true
    })
  }

  uploadFile(event, field) {
    if (this.uploadLimit) {
      this.layoutService.showSnackBar({name: ''}, marker("This company has exceeded the file upload limit."), SnackBarItem)
      return
    }
    if (event.target.files.length > 0) {
      const file = (event.target as HTMLInputElement).files[0];
      console.log(file);
      field.fileVal = file;
      this.putFile(field);
    } else {
      field.fileVal = null
    }
  }

  putFile(field) {
    field.size = field.fileVal.size;
    this.attachSubscriptions(
      this.questsService.editDataField(field.id, {
        company_id: this.company_id,
        upload_filename: field.fileVal.name,
        upload_content_type: field.fileVal.type ? field.fileVal.type : mimeTypes.getType(field.fileVal.name),
        upload_filesize: field.fileVal.size
      }, this.company_id).subscribe(resp => {
        console.log("putFile", resp)
        this.fileService.files$.next({
          place: "app_form",
          url: window.location.href,
          data: resp,
          target: field,
          company_id: this.company_id,
          company: this.company,
          user: this.user
        })
      })
    )
  }

  getUser() {
    this.attachSubscriptions(
      this.auth.$user.subscribe(resp => {
        this.user = resp;
      })
    )
  }

  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData().subscribe(resp => {
        this.activeLang = resp.active;
        this._adapter.setLocale(resp.active);
      })
    )
  }

  getCompany() {
    this.attachSubscriptions(
      this.companyService.getCompany(this.company_id).pipe(
        map(x => x[0]),
        tap(resp => {
          this.companyService.company$.next(resp);
          this.company = resp;
          if (resp.employees.find(x => x.user_id == this.user.id)) {
            this.appStatusId = resp.employees.find(x => x.user_id == this.user.id).application_status_id
          }
        }),
        switchMap(x => this.uploads.getUploadLimit().pipe(
          tap(z => {
            if (z || this.company.filesize >= this.company.filesize_limit) {
              this.uploadLimit = true;
            }
          })
        ))
      ).subscribe(resp => {
        console.log("getCompany", resp);

        let employee = this.company.employees.find(x => x.user_id == this.user.id)
        if (employee) {
          this.getQuestEmpl(this.emplPage, {employee_id: employee.id});
        } else {
          this.getQuestEmpl(this.emplPage);
        }
      })
    )
  }

  getQuestEmpl(page, filterData = {}) {
    this.attachSubscriptions(
      this.questsService.getQuestEmpl(this.company_id, filterData, page).pipe(
        tap(el => {
          this.emplPagination = {
            'pageCount': el.headers.get('x-pagination-page-count'),
            'perPage': el.headers.get('x-pagination-per-page'),
            'totalCount': el.headers.get('x-pagination-total-count'),
            'currentPage': el.headers.get('x-pagination-current-page'),
          }
        }),
        map(el => el.body)
      ).subscribe(resp => {
        console.log("getQuestEmpl", resp);
        this.emplPage = this.emplPage + 1;
        this.emplQuests.push(...resp);
        resp.forEach((ctrl, i) => {
          // (ctrl.uploadStream as Subscription) = undefined;
          ctrl.upload = {...this.upload};
          ctrl.is_paused = false;
          if (!this.formData.hasOwnProperty(ctrl.type)) {
            ctrl.key = ctrl.type
            this.formData[ctrl.type] = ctrl
            if (ctrl.type == 'file') {
              this.form.addControl(ctrl.type, this.fb.control(ctrl.file, Validators.required))
              if (this.appStatusId == 2 && !ctrl.comment && !!ctrl.file) {
                this.form.get(ctrl.type).disable()
              }
            } else if (ctrl.type == 'date') {
              this.form.addControl(ctrl.type, this.fb.control(ctrl.text ? moment(ctrl.text*1000).utc() : '', Validators.required))
              if (this.appStatusId == 2 && !ctrl.comment && !!ctrl.text) {
                this.form.get(ctrl.type).disable()
              }
            } else if (ctrl.type == 'rules') {
              this.form.addControl(ctrl.type, this.fb.control(ctrl.text, Validators.required))
              if (this.appStatusId == 2 && !ctrl.comment && !!ctrl.text) {
                this.form.get(ctrl.type).disable()
              }
            } else {
              this.form.addControl(ctrl.type, this.fb.control(ctrl.text, Validators.required))
              if (this.appStatusId == 2 && !ctrl.comment && !!ctrl.text) {
                this.form.get(ctrl.type).disable()
              }
            }
          } else {
            ctrl.key = ctrl.type+i;
            this.formData[ctrl.type+i] = ctrl;
            if (ctrl.type == 'file') {
              this.form.addControl(ctrl.type+i, this.fb.control(ctrl.file, Validators.required))
              if (this.appStatusId == 2 && !ctrl.comment && !!ctrl.file) {
                this.form.get(ctrl.type+i).disable()
              }
            } else if (ctrl.type == 'date') {
              this.form.addControl(ctrl.type+i, this.fb.control(ctrl.text ? moment(ctrl.text*1000).utc() : '', Validators.required))
              if (this.appStatusId == 2 && !ctrl.comment && !!ctrl.text) {
                this.form.get(ctrl.type+i).disable()
              }
            } else if (ctrl.type == 'rules') {
              this.form.addControl(ctrl.type+i, this.fb.control(ctrl.text, Validators.required))
              if (this.appStatusId == 2 && !ctrl.comment && !!ctrl.text) {
                this.form.get(ctrl.type+i).disable()
              }
            } else {
              this.form.addControl(ctrl.type+i, this.fb.control(ctrl.text, Validators.required))
              if (this.appStatusId == 2 && !ctrl.comment && !!ctrl.text) {
                this.form.get(ctrl.type+i).disable()
              }
            }

          }

          this.form.updateValueAndValidity();
        });
        this.keys = Object.keys(this.form.controls);
      })
    )
  }

  log() {
    console.log("this.keys", this.keys)
    console.log("this.formData", this.formData)
  }

  submit() {
    let filesNotUploadCount = 0;

    this.keys.filter(x => x.indexOf('file') != 1).forEach(key => {
      if (!!this.formData[key].upload.hasOwnProperty('progress') && this.formData[key].upload.progress != 100) {
        filesNotUploadCount++
      }
    })

    console.log("filesNotUploadCount", filesNotUploadCount);
    if (filesNotUploadCount > 0) {
      this.layoutService.showSnackBar({name: "Wait"}, marker(filesNotUploadCount+" file(s) not uploaded."), SnackBarItem)
      return
    }

    this.isSubmit = true;

    let sbmtKeys = this.keys.filter(x => x.indexOf('file') == -1);
    let sbmtData = [];
    
    sbmtKeys.forEach(element => {
      sbmtData.push({
        "path": `/api/employee-application-data/${this.formData[element].id}/`,
        "query": {company_id: this.company_id},
        "method": "POST",
        "body": Object.assign({
          [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
        }, {text: this.formData[element].text })
      });
    })

    this.attachSubscriptions(
      this.taskService.multiRequest(sbmtData).subscribe(resp => {

        if (this.form.valid) {
          if (this.company.employees.find(x => x.user_id == this.user.id)) {
            this.attachSubscriptions(
              this.taskService.editEmployee(this.company.employees.find(x => x.user_id == this.user.id).id, {
                application_status_id: 3
              }).subscribe(resp => {
                this.company.employees.find(x => x.user_id == this.user.id).application_status_id = 3;
                this.appStatusId = 3;
                this.isSubmit = false;
              })
            )
            
          }
        } else {
          this.layoutService.showSnackBar({name: ""}, marker("Your information form is not finished yet. Finish the highlighted fields."), SnackBarItem)
          this.isSubmit = false;
        }
      })
    )
  }

  changeDate(e, field) {
    let date = e.value.clone();

    console.log("changeDate", date);
    field.text = date.format("X")
  }

  printFieldVal(val, field) {
    field.text = val
  }

  onChangeRules(val, field) {
    console.log(val);
    if (val) {
      field.text = '1'
    } else {
      field.text = null
    }
  }

  saveField(field) {
    this.attachSubscriptions(
      this.questsService.saveFieldByEmpl(field.id, {
        text: field.text
      }).subscribe(resp => {
        console.log(resp);
      })
    )
  }

  goBack(e) {
    e.preventDefault();
    history.back();
  }

  ngAfterViewInit() {
    this.tableContainer.nativeElement.scrollTop = 0;
    this.onScroll();
  }

  onScroll() {
    this.attachSubscriptions(
      fromEvent(this.tableContainer.nativeElement, "scroll").pipe(
        filter((e:any) => e.target.scrollTop >=  e.target.scrollHeight - e.target.offsetHeight - 400),
        map(() => this.tableContainer.nativeElement.scrollTop),
        debounceTime(300),
        map(y => Math.ceil((y + this.tableContainer.nativeElement.innerHeight)/ (400))),
        distinctUntilChanged()
      ).subscribe(() => {
          if (this.emplPage <= this.emplPagination['pageCount']) {
            this.getQuestEmpl(this.emplPage);
          }
        }
      )
    )
  }
  
  ngOnDestroy() {
    if(this.sub) {
      this.sub.unsubscribe()
    }
    this.clearSubscriptions()
  }
}