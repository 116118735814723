import { Injectable } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CloseFromBackdropComponent } from '../../global_components/close-from-backdrop/close-from-backdrop.component';
import { DateAskComponent } from '../../global_components/date-ask/date-ask.component';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {
  constructor(
    private _snackBar: MatSnackBar,
    private bottomSheet: MatBottomSheet
  ) { }

  showSnackBar(item: any, message: string, comp, dur?, dates?, can_copy?, id?, copy_tmpl?) {
    this._snackBar.openFromComponent(comp, {
      duration: dur ? dur : 3000,
      horizontalPosition: 'end',
      verticalPosition: 'bottom',
      data: {
        name: item['name'],
        message: message,
        dates: dates,
        can_copy: can_copy,
        copy_tmpl: copy_tmpl,
        id: id
      }
    });
  }

  openBottomSheet(closeFunc) {
    let sheetRef =  this.bottomSheet.open(CloseFromBackdropComponent);
    sheetRef.afterDismissed().subscribe( data => {
      if(data && data.message=='close') {
        closeFunc.close({event: "close", data: false})
      } if(data && data.message=='continue') {
        console.log("continue");
      }
    });
  }

  overflowHidden() {
    document.documentElement.style.overflowY = "hidden";
    document.body.style.overflowY = "hidden";
  }

  overflowAuto() {
    document.documentElement.style.overflowY = "";
    document.body.style.overflowY = "";
  }

  // setNight() {
  //   document.documentElement.style.setProperty('--theme-background', '#181818');
  //   document.documentElement.style.setProperty('--theme-color', '#fff');
  //   document.documentElement.style.setProperty('--theme-header', '#232323');
  //   document.documentElement.style.setProperty('--theme-svg', '#fff');
  //   document.documentElement.style.setProperty('--theme-search', '#ffffff38');
  //   document.documentElement.style.setProperty('--theme-profile', '#797979');
  //   document.documentElement.style.setProperty('--theme-reverse', '#333');
  //   document.documentElement.style.setProperty('--theme-color4', '#ffffff0A');
  // }

  // setDay() {
  //   document.documentElement.style.setProperty('--theme-background', '#e9ecef');
  //   document.documentElement.style.setProperty('--theme-color', '#000');
  //   document.documentElement.style.setProperty('--theme-header', '#fff');
  //   document.documentElement.style.setProperty('--theme-svg', '#000');
  //   document.documentElement.style.setProperty('--theme-search', '#a6a6a638');
  //   document.documentElement.style.setProperty('--theme-profile', '#333');
  //   document.documentElement.style.setProperty('--theme-reverse', '#fff');
  //   document.documentElement.style.setProperty('--theme-color4', '#0000000A');
  // }

}
