import { Directive, HostListener, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[appCtrlScroll]'
})
export class CtrlScrollDirective {

  @Output() scrollDown: EventEmitter<any> = new EventEmitter();
  @Output() scrollTop: EventEmitter<any> = new EventEmitter();

  constructor() { }

  @HostListener('wheel', ['$event'])
  onMouseWheel(event: WheelEvent) {
    if (event.ctrlKey) {
      if (event.deltaY > 0) {
        event.preventDefault();
        // Ctrl + scrollDown
        this.scrollDown.emit();
      } else if (event.deltaY < 0) {
        event.preventDefault();
        // Ctrl + scrollTop
        this.scrollTop.emit();
      }
    }
  }
}