import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { forkJoin } from 'rxjs';
import { last, map, switchMap, tap } from 'rxjs/operators';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { QuestsService } from 'src/app/shared/services/rest/quests.service';
import { StatementService } from 'src/app/shared/services/rest/statement.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { EditColumnComponent } from './dialogs/edit-column/edit-column.component';

@Component({
  selector: 'app-add-table',
  templateUrl: './add-table.component.html',
  styleUrls: ['./add-table.component.scss']
})
export class AddTableComponent extends BaseClass implements OnInit, OnDestroy {
  public source: '';
  public isFormChange: boolean = false;
  public isSubmit: boolean = false;
  public isGettingFields: boolean = false;
  public sources:any = [
    {
      value: '$statement_records',
      name: "statement_records",
      type: "Statement record Variable",
      item: '$s_r'
    },
  ]
  public variables:any = {
    '$statement_records': {
      vars: ["task_group_name", 'task_name', 'task_status_name', 'task_operation_name', 'task_operation_operation_name', 'discussion_name', 'primaryParameterValuesToTask']
    }
  }

  public translations = {
    '$statement_records': {
      trans: {"task_group_name": "Group Name", 'task_name': "Card Name", 'task_status_name': "Card Type", 'task_operation_name': "Job Name", 'task_operation_operation_name': "Job Type", 'discussion_name': "Chat Name", 'primaryParameterValuesToTask': 'Primary Tag'}
    }
  }
  public columns = [
    {
      head_name: '',
      row_var: '',
      row_type: '',
      row_name: '',
      font_size: 12,
      padding_left: 0,
      padding_right: 0,
      padding_top: 0,
      padding_bottom: 0,
    }
  ]
//   {foreach $statement_records as $statement_record}

// {$statement_record.task_group_name|escape} - {$statement_record.task_name|escape}({$statement_record.task_status_name|escape}) - {$statement_record.task_operation_name|escape}({$statement_record.task_operation_operation_name|escape}) - {$statement_record.discussion_name|escape}

// {/foreach}

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<AddTableComponent>,
    private layoutService: LayoutService,
    private dialog: MatDialog,
    private questsService: QuestsService,
  ) { super() }

  ngOnInit(): void {

    console.log("AddTableComponent", this.data)

    if (this.data.values) {
      this.source = this.data.values.source;
      this.columns = this.data.values.columns;
    }

    // this.getForms()

    // this.form = this.fb.group({
    //   source: ['', Validators.required],
    //   form_id: ['', Validators.required],
    //   field: ['', Validators.required],
    // })

    // this.attachSubscriptions(
    //   this.form.get('form_id').valueChanges.subscribe(res => {
    //     if (!!res) {
    //       this.form.patchValue({
    //         field: ['', Validators.required],
    //       })
    //       this.getFields(res);
    //     }
    //   })
    // )

    // this.attachSubscriptions(
    //   this.form.valueChanges.subscribe(() => this.isFormChange = true)
    // )

    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        if (this.isFormChange) {
          this.layoutService.openBottomSheet(this.dialogRef);
        } else {
          this.close();
        }
      })
    )
  }

  log() {
    console.log("columns", this.columns)
  }

  deleteColumn(i) {
    this.columns.splice(i, 1)
  }

  addColumn(i) {
    this.columns.splice(i+1, 0, {
      head_name: '',
      row_var: '',
      row_type: '',
      row_name: '',
      font_size: 12,
      padding_left: 0,
      padding_right: 0,
      padding_top: 0,
      padding_bottom: 0,
    })
  }

  // getForms() {
  //   console.log("getForms this.data.company.id", this.data.company.id)
  //   this.attachSubscriptions(
  //     this.questsService.getQuestsDyn('1', this.data.company.id, '1').pipe(
  //       switchMap(el => {
  //         let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
  //         let arr = []
  //         for (let index = 1; index <= pages; index++) {
  //           arr.push(index)
  //         }

  //         return forkJoin(arr.map(x => this.questsService.getQuestsDyn(x, this.data.company.id, '200').pipe(map(u => u.body)))).pipe(
  //           last(),
  //         )
  //       }),
  //     ).subscribe(res => {
  //       this.forms = [].concat(...res);
  //     })
  //   )
  // }

  // getFields(aId) {
  //   this.isGettingFields = true;
  //   this.attachSubscriptions(
  //     this.questsService.getFields('1', this.data.company.id, aId, '1').pipe(
  //       switchMap(el => {
  //         let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
  //         let arr = []
  //         for (let index = 1; index <= pages; index++) {
  //           arr.push(index)
  //         }

  //         return forkJoin(arr.map(x => this.questsService.getFields(x, this.data.company.id, aId, '200').pipe(map(u => u.body)))).pipe(
  //           last(),
  //         )
  //       }),
  //     ).subscribe(res => {
  //       this.fields = [].concat(...res);
  //       this.isGettingFields = false
  //     })
  //   )
  // }

  editColumn(col) {
    const dialogRef = this.dialog.open(EditColumnComponent, {
      disableClose: true,
      data: {
        col: col
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log("addVariable result", result)
        if (result.data) {

        }
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false});
  }

  submitForm() {
    this.columns.forEach(col => {
      col.row_name = this.translations[this.source].trans[col.row_var];
      col.row_type = this.sources.find(x => x.value == this.source).type;
    })
    this.dialogRef.close({event: "close", data: {
      source: this.source,
      source_item: this.sources.find(x => x.value == this.source).item,
      columns: this.columns
    }});
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
