<h2 mat-dialog-title class="dialog_title">
    <span>
        {{ "Edditing a work" | translate }} : {{data?.task?.name}}<b>({{data?.task?.custom_id}})</b>
    </span>
    <button mat-button (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h2>
<mat-dialog-content class="mat-typography" dirEnter (dirSubmit)="!form.valid ? null : onSubmit()">
    <div class="container form_wrapper">
        <form [formGroup]="form">
            <mat-form-field appearance="standard" class="full_width_form" *ngIf="!data.comp || data.comp == 'Name'">
                <mat-label>{{ 'Title' | translate }}</mat-label>
                <input formControlName="name" matInput placeholder="{{ 'Title' | translate }}">
            </mat-form-field>

            <mat-form-field appearance="standard" class="full_width_form" *ngIf="!data.comp || data.comp == 'Comment'">
                <mat-label>{{ 'Description' | translate }}</mat-label>
                <textarea formControlName="comment" matInput placeholder="{{ 'Drief description' | translate }}"
                cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="5"></textarea>
            </mat-form-field>

            <mat-form-field appearance="standard" class="full_width_form" *ngIf="!data.comp && data?.empl_status">
                <mat-label>{{ 'Private comment' | translate }}</mat-label>
                <textarea formControlName="private_comment" matInput placeholder="{{ 'Private comment' | translate }}"></textarea>
            </mat-form-field>

            <mat-form-field appearance="standard" class="full_width_form" *ngIf="!data.comp">
                <mat-label>{{ 'Employee comment' | translate }}</mat-label>
                <textarea formControlName="employee_comment" matInput placeholder="{{ 'Employee comment' | translate }}"></textarea>
            </mat-form-field>
        </form>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button (click)="close()">
        {{ "Back" | translate }}
    </button>
    <button class="ml-1" (click)="onSubmit()" mat-raised-button color="primary" [disabled]="!form.valid">
        {{ "Save" | translate }}
    </button>
</mat-dialog-actions>
