import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReplaySubject } from 'rxjs';
import { debounceTime, map, tap } from 'rxjs/operators';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { RootService } from 'src/app/shared/services/rest/root.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';

@Component({
  selector: 'app-apply-new-promo',
  templateUrl: './apply-new-promo.component.html',
  styleUrls: ['./apply-new-promo.component.scss']
})
export class ApplyNewPromoComponent extends BaseClass implements OnInit, OnDestroy {
  public form: FormGroup;

  public result: any;
  public isFormChange: boolean = false;
  public isSearchPromo: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private rootService: RootService,
    public dialogRef: MatDialogRef<ApplyNewPromoComponent>,
    public layoutService: LayoutService,
    public companyService: CompanyService,
  ) { super() }

  ngOnInit(): void {
    console.log("ApplyNewPromoComponent", this.data)
    if (this.data.code) {
      this.form = this.fb.group({
        code: this.data.code
      })
    } else {
      this.form = this.fb.group({
        code: ''
      })
    }

    this.attachSubscriptions(
      this.form.valueChanges.subscribe(() => this.isFormChange = true)
    )

    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        if (this.isFormChange) {
          this.layoutService.openBottomSheet(this.dialogRef);
        } else {
          this.close();
        }
      })
    )
  }

  onInput(e) {
    this.result = undefined;
  }

  findCode() {
    this.isSearchPromo = true;
    let params:any = {
      code: this.form.value.code
    }
    this.attachSubscriptions(
      this.companyService.getWorkspacePromos('1', params).pipe(
        map(res => res.body),
      ).subscribe(resp => {        
        if (resp && resp.length) {
          this.result = resp[0]
        } else {
          this.result = 'Error'
        }
        this.isSearchPromo = false;
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false});
  }

  submitForm() {
    let sendData:any = {
      company_id: this.data.company_id,
      code: this.result.code
    };

    this.attachSubscriptions(
      this.companyService.addWorkspacePromoEm(sendData).subscribe(resp => {
        this.dialogRef.close({event: "close", data: resp});
      }, error => {
        console.log('error', error)
        this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
      })
    )
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
