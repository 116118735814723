
<div class="file_wrp">
    <div class="file_header">
        <!-- <ng-container *ngIf="uploadProgress?.uploadedFiles">
            <video #phVid id="phVideo" autoplay loop muted>
                <source src="{{ imgRoute }}assets/video/placeholder.mp4" type="video/mp4">
                Ваш браузер не поддерживает тег video.
            </video>
            {{phVid?.currentTime}}
        </ng-container> -->
        <div class="files_count" (click)="logFiles()">
            <ng-container *ngIf="type.value == 2; else elseTemplateTypeHead">
                <span class="files_number">{{ copiedFiles?.length }}</span>
                <ng-container *ngIf="copiedFiles?.length > 1; else elseTemplatecopiedFilesLength">
                    {{ "files" | translate}}
                </ng-container>
                <ng-template #elseTemplatecopiedFilesLength>
                    {{ "file" | translate}}
                </ng-template>
            </ng-container>
            <ng-template #elseTemplateTypeHead>
                <ng-container *ngIf="uploadProgress?.uploadedFiles; else elseTemplateUploading">
                    {{ "Uploading" | translate }}
                    <span class="files_number">{{ uploadProgress?.uploadedFiles }}/{{ files.length }}</span>
                    <span class="uploaded_size">{{ uploadProgress?.uploadedSize | filesize }} <b>{{ (is_mobile ? "remaining" : "remaining to upload") | translate }}</b></span>
                </ng-container>
                <ng-template #elseTemplateUploading>
                    {{ "Uploaded" | translate }} 
                    <span class="files_number">{{ files.length }}</span>
                    <ng-container *ngIf="files.length > 1; else elseTemplateFilesLength">
                        {{ "files" | translate}}
                    </ng-container>
                    <ng-template #elseTemplateFilesLength>
                        {{ "file" | translate}}
                    </ng-template>
                </ng-template>
            </ng-template>

            <div class="not_completed" *ngIf="getNotCompletedFiles() && !!getNotCompletedFiles().length" (click)="completeFiles()">
                <mat-spinner class="spin_16 mr-1" *ngIf="completedFiles"></mat-spinner>
                {{getNotCompletedFiles().length}} {{"files upload status n/a" | translate}}
            </div>
        </div>


        <div class="files_btns" style="padding-right: 10px;">
            <div class="clear_buffer" style="margin-right: 5px;" *ngIf="checkDuplicates()" (click)="$event.preventDefault(); $event.stopPropagation(); copyAll()" #tooltip="matTooltip" matTooltip="{{ 'Use existing files for all uploads' | translate }}" [matTooltipPosition]="'above'">
                {{ "Copy All" | translate }}
            </div>
            <div class="clear_buffer" *ngIf="true || (type.value == 1 && checkErrors())" style="margin-right: 5px;" (click)="$event.preventDefault(); $event.stopPropagation(); retryAll()">
                {{ "Retry All" | translate }}
            </div>

            <div class="clear_buffer" *ngIf="type.value == 2" style="margin-right: 5px;" (click)="$event.preventDefault(); $event.stopPropagation(); clearBuffer()">
                {{ "Clear" | translate }}
            </div>

            <div class="upload_manger_v" *ngIf="type.value == 1">
                <mat-form-field appearance="legacy" class="full_width_form">
                    <select matNativeControl [value]="!sm.localStorageGetItem('smartUpload') ? '0' : '1'" (change)="selectVersion($event)">
                        <option value="0">
                            {{ "V1" }}
                        </option>
                        <option value="1">
                            {{ "V2" }}
                        </option>
                    </select>
                </mat-form-field>

                <svg style="width: 10px; height: 10px" width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5 8L0.669872 0.5L9.33013 0.5L5 8Z" fill="white"/>
                </svg>
            </div>

            <div class="upload_manger_v" *ngIf="copiedIds">
                <mat-form-field appearance="legacy" class="full_width_form">
                    <select matNativeControl [formControl]="type" (change)="selectType($event)">
                        <option value="1">
                            {{ "Uploader" | translate }}
                        </option>
                        <option value="2">
                            {{ "Buffer" | translate }}
                        </option>
                    </select>
                </mat-form-field>

                <svg style="width: 10px; height: 10px" width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5 8L0.669872 0.5L9.33013 0.5L5 8Z" fill="white"/>
                </svg> 
            </div>
            
            <button mat-icon-button (click)="close()">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>
    <div class="file_body" [appDeviceClass]="'custom-scroll custom-scroll-t'"
    #filesWrapper
    infinite-scroll    
    [scrollWindow]="false"
    [infiniteScrollUpDistance]="scrollUpDistance"
    [infiniteScrollThrottle]="throttle"
    (scrolled)="onDown($event)">
        <div class="files">
            <ng-container *ngIf="type.value == 1; else elseTemplateTypeVal">
                <div class="file_item" [ngClass]="{
                    'red_file': !file?.target?.upload?.is_end && file?.target?.upload?.state == 'ERROR',
                    'orange_file': !file?.target?.upload?.is_end && file.countSameFiles > 0 && !file.data.tryAgain}" *ngFor="let file of files">
                        <div class="file_item_inside">
                            <div class="file_info">
                                <div class="d-f-c">
                                    <div class="file_item_more" [ngClass]="{'opened': !!file.showOptions}">
                                        <mat-icon style="color: #686868;" (click)="toggleFileOptions(file)">more_vert</mat-icon>
                                    </div>
                                    <div class="file_img" (click)="viewFile(file)" [ngClass]="{'cp': !!file.uploadedFile}">
                                        <ng-container [ngSwitch]="understandFileType(file?.target?.fileVal?.type ? file?.target?.fileVal?.type : file?.target?.type)">
                                            <ng-container *ngSwitchCase="'video'">
                                                <ng-container *ngIf="file?.uploadedFile?.thumbnail; else elseTemplateThumbnailInterface">
                                                    <div class="movie_thumb">
                                                        <img style="max-width: 100%; max-height: 100%;" (error)="onImgError($event)" [src]="host + file?.uploadedFile?.thumbnail + '?company_id=' + file?.company?.id">
                                                        <mat-icon>play_circle</mat-icon>
                                                    </div>
                                                </ng-container>
                                                <ng-template #elseTemplateThumbnailInterface>
                                                    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <g clip-path="url(#clip0_3006_50681)">
                                                        <path d="M7.00033 2.3335C5.71699 2.3335 4.67866 3.3835 4.67866 4.66683L4.66699 23.3335C4.66699 24.6168 5.70533 25.6668 6.98866 25.6668H21.0003C22.2837 25.6668 23.3337 24.6168 23.3337 23.3335V9.3335L16.3337 2.3335H7.00033ZM15.167 10.5002V4.0835L21.5837 10.5002H15.167Z" fill="#6299E1"/>
                                                        </g>
                                                        <defs>
                                                        <clipPath id="clip0_3006_50681">
                                                        <rect width="28" height="28" fill="white"/>
                                                        </clipPath>
                                                        </defs>
                                                    </svg>
                                                </ng-template>
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'pdf'">
                                                <div class="pdf">
                                                    <svg height="800px" width="800px" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
                                                        viewBox="0 0 303.188 303.188" xml:space="preserve">
                                                        <g>
                                                            <polygon style="fill:#E8E8E8;" points="219.821,0 32.842,0 32.842,303.188 270.346,303.188 270.346,50.525 	"/>
                                                            <path style="fill:#FB3449;" d="M230.013,149.935c-3.643-6.493-16.231-8.533-22.006-9.451c-4.552-0.724-9.199-0.94-13.803-0.936
                                                                c-3.615-0.024-7.177,0.154-10.693,0.354c-1.296,0.087-2.579,0.199-3.861,0.31c-1.314-1.36-2.584-2.765-3.813-4.202
                                                                c-7.82-9.257-14.134-19.755-19.279-30.664c1.366-5.271,2.459-10.772,3.119-16.485c1.205-10.427,1.619-22.31-2.288-32.251
                                                                c-1.349-3.431-4.946-7.608-9.096-5.528c-4.771,2.392-6.113,9.169-6.502,13.973c-0.313,3.883-0.094,7.776,0.558,11.594
                                                                c0.664,3.844,1.733,7.494,2.897,11.139c1.086,3.342,2.283,6.658,3.588,9.943c-0.828,2.586-1.707,5.127-2.63,7.603
                                                                c-2.152,5.643-4.479,11.004-6.717,16.161c-1.18,2.557-2.335,5.06-3.465,7.507c-3.576,7.855-7.458,15.566-11.815,23.02
                                                                c-10.163,3.585-19.283,7.741-26.857,12.625c-4.063,2.625-7.652,5.476-10.641,8.603c-2.822,2.952-5.69,6.783-5.941,11.024
                                                                c-0.141,2.394,0.807,4.717,2.768,6.137c2.697,2.015,6.271,1.881,9.4,1.225c10.25-2.15,18.121-10.961,24.824-18.387
                                                                c4.617-5.115,9.872-11.61,15.369-19.465c0.012-0.018,0.024-0.036,0.037-0.054c9.428-2.923,19.689-5.391,30.579-7.205
                                                                c4.975-0.825,10.082-1.5,15.291-1.974c3.663,3.431,7.621,6.555,11.939,9.164c3.363,2.069,6.94,3.816,10.684,5.119
                                                                c3.786,1.237,7.595,2.247,11.528,2.886c1.986,0.284,4.017,0.413,6.092,0.335c4.631-0.175,11.278-1.951,11.714-7.57
                                                                C231.127,152.765,230.756,151.257,230.013,149.935z M119.144,160.245c-2.169,3.36-4.261,6.382-6.232,9.041
                                                                c-4.827,6.568-10.34,14.369-18.322,17.286c-1.516,0.554-3.512,1.126-5.616,1.002c-1.874-0.11-3.722-0.937-3.637-3.065
                                                                c0.042-1.114,0.587-2.535,1.423-3.931c0.915-1.531,2.048-2.935,3.275-4.226c2.629-2.762,5.953-5.439,9.777-7.918
                                                                c5.865-3.805,12.867-7.23,20.672-10.286C120.035,158.858,119.587,159.564,119.144,160.245z M146.366,75.985
                                                                c-0.602-3.514-0.693-7.077-0.323-10.503c0.184-1.713,0.533-3.385,1.038-4.952c0.428-1.33,1.352-4.576,2.826-4.993
                                                                c2.43-0.688,3.177,4.529,3.452,6.005c1.566,8.396,0.186,17.733-1.693,25.969c-0.299,1.31-0.632,2.599-0.973,3.883
                                                                c-0.582-1.601-1.137-3.207-1.648-4.821C147.945,83.048,146.939,79.482,146.366,75.985z M163.049,142.265
                                                                c-9.13,1.48-17.815,3.419-25.979,5.708c0.983-0.275,5.475-8.788,6.477-10.555c4.721-8.315,8.583-17.042,11.358-26.197
                                                                c4.9,9.691,10.847,18.962,18.153,27.214c0.673,0.749,1.357,1.489,2.053,2.22C171.017,141.096,166.988,141.633,163.049,142.265z
                                                                M224.793,153.959c-0.334,1.805-4.189,2.837-5.988,3.121c-5.316,0.836-10.94,0.167-16.028-1.542
                                                                c-3.491-1.172-6.858-2.768-10.057-4.688c-3.18-1.921-6.155-4.181-8.936-6.673c3.429-0.206,6.9-0.341,10.388-0.275
                                                                c3.488,0.035,7.003,0.211,10.475,0.664c6.511,0.726,13.807,2.961,18.932,7.186C224.588,152.585,224.91,153.321,224.793,153.959z"/>
                                                            <polygon style="fill:#FB3449;" points="227.64,25.263 32.842,25.263 32.842,0 219.821,0 	"/>
                                                            <g>
                                                                <path style="fill:#A4A9AD;" d="M126.841,241.152c0,5.361-1.58,9.501-4.742,12.421c-3.162,2.921-7.652,4.381-13.472,4.381h-3.643
                                                                    v15.917H92.022v-47.979h16.606c6.06,0,10.611,1.324,13.652,3.971C125.321,232.51,126.841,236.273,126.841,241.152z
                                                                    M104.985,247.387h2.363c1.947,0,3.495-0.546,4.644-1.641c1.149-1.094,1.723-2.604,1.723-4.529c0-3.238-1.794-4.857-5.382-4.857
                                                                    h-3.348C104.985,236.36,104.985,247.387,104.985,247.387z"/>
                                                                <path style="fill:#A4A9AD;" d="M175.215,248.864c0,8.007-2.205,14.177-6.613,18.509s-10.606,6.498-18.591,6.498h-15.523v-47.979
                                                                    h16.606c7.701,0,13.646,1.969,17.836,5.907C173.119,235.737,175.215,241.426,175.215,248.864z M161.76,249.324
                                                                    c0-4.398-0.87-7.657-2.609-9.78c-1.739-2.122-4.381-3.183-7.926-3.183h-3.773v26.877h2.888c3.939,0,6.826-1.143,8.664-3.43
                                                                    C160.841,257.523,161.76,254.028,161.76,249.324z"/>
                                                                <path style="fill:#A4A9AD;" d="M196.579,273.871h-12.766v-47.979h28.355v10.403h-15.589v9.156h14.374v10.403h-14.374
                                                                    L196.579,273.871L196.579,273.871z"/>
                                                            </g>
                                                            <polygon style="fill:#D1D3D3;" points="219.821,50.525 270.346,50.525 219.821,0 	"/>
                                                        </g>
                                                    </svg>
                                                </div>
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'image'">
                                                <ng-container *ngIf="file?.uploadedFile; else elseTemplateThumbnailInterface">
                                                    <img (error)="onImgError($event)" style="max-width: 100%; max-height: 100%;" [src]="host + (file?.uploadedFile?.thumbnail ? file?.uploadedFile?.thumbnail : file?.uploadedFile?.original) + '?company_id=' + file?.company?.id">
                                                </ng-container>
                                                <ng-template #elseTemplateThumbnailInterface>
                                                    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <g clip-path="url(#clip0_3006_50681)">
                                                        <path d="M7.00033 2.3335C5.71699 2.3335 4.67866 3.3835 4.67866 4.66683L4.66699 23.3335C4.66699 24.6168 5.70533 25.6668 6.98866 25.6668H21.0003C22.2837 25.6668 23.3337 24.6168 23.3337 23.3335V9.3335L16.3337 2.3335H7.00033ZM15.167 10.5002V4.0835L21.5837 10.5002H15.167Z" fill="#6299E1"/>
                                                        </g>
                                                        <defs>
                                                        <clipPath id="clip0_3006_50681">
                                                        <rect width="28" height="28" fill="white"/>
                                                        </clipPath>
                                                        </defs>
                                                    </svg>
                                                </ng-template>
                                            </ng-container>
                                            <ng-container *ngSwitchDefault>
                                                <ng-container *ngIf="!!file?.uploadedFile?.thumbnail; else elseTemplateHasThumb">
                                                    <img (error)="onImgError($event)" style="max-width: 100%; max-height: 100%;" [src]="host + file?.uploadedFile?.thumbnail + '?company_id=' + file?.company?.id">
                                                </ng-container>
                                                <ng-template #elseTemplateHasThumb>
                                                    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <g clip-path="url(#clip0_3006_50681)">
                                                        <path d="M7.00033 2.3335C5.71699 2.3335 4.67866 3.3835 4.67866 4.66683L4.66699 23.3335C4.66699 24.6168 5.70533 25.6668 6.98866 25.6668H21.0003C22.2837 25.6668 23.3337 24.6168 23.3337 23.3335V9.3335L16.3337 2.3335H7.00033ZM15.167 10.5002V4.0835L21.5837 10.5002H15.167Z" fill="#6299E1"/>
                                                        </g>
                                                        <defs>
                                                        <clipPath id="clip0_3006_50681">
                                                        <rect width="28" height="28" fill="white"/>
                                                        </clipPath>
                                                        </defs>
                                                    </svg>
                                                </ng-template>
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                    <div class="file_name" (click)="sortFiles()">
                                        <ng-container *ngIf="file?.data?.filename; else elseTemplateFilename">
                                            {{(file?.data?.filename.length > 22 ? (file?.data?.filename | slice:0:15) + "..." + (file?.data?.filename | slice:-5) : file?.data?.filename | translate)}}
                                        </ng-container>
                                        <ng-template #elseTemplateFilename>
                                            {{ file?.target?.fileVal?.name ? (file?.target?.fileVal?.name.length > 25 ? (file?.target?.fileVal?.name | slice:0:15) + "..." + (file?.target?.fileVal?.name | slice:-5) : file?.target?.fileVal?.name | translate) : (file?.target?.name.length > 25 ? (file?.target?.name | slice:0:15) + "..." + (file?.target?.name | slice:-5) : file?.target?.name | translate) }}
                                        </ng-template>
                                    </div>
                                </div>
        
                                <div class="d-f-c" *ngIf="!is_mobile || (file?.target && file?.target?.upload && file?.target?.upload?.state != 'ERROR')">
                                    <ng-container *ngIf="!file?.target?.upload || (!file?.target?.upload?.is_end && file?.target?.upload?.state != 'ERROR') && (file.countSameFiles == 0 || !!file.data.tryAgain)">
                                        <div class="hover_icon mr-1" (click)="cancelUpload(file)" *ngIf="file">
                                            <svg style="width: 20px; height: 20px;" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clip-path="url(#clip0_3394_49421del)">
                                                <path d="M4.99984 15.8333C4.99984 16.75 5.74984 17.5 6.6665 17.5H13.3332C14.2498 17.5 14.9998 16.75 14.9998 15.8333V5.83333H4.99984V15.8333ZM15.8332 3.33333H12.9165L12.0832 2.5H7.9165L7.08317 3.33333H4.1665V5H15.8332V3.33333Z" fill="#FF6A6A"/>
                                                </g>
                                                <defs>
                                                <clipPath id="clip0_3394_49421del">
                                                <rect width="20" height="20" fill="white"/>
                                                </clipPath>
                                                </defs>
                                            </svg>
                                        </div>
                                    </ng-container>
                                    
                                    <div class="file_size">
                                        <ng-container *ngIf="file?.target?.upload?.size_is_uploaded && !file?.target?.upload?.is_end">
                                            <span>{{ file?.target.upload.size_is_uploaded | filesize:(is_mobile ? 1 : 2) }}</span> /
                                        </ng-container>
                                        <span>{{ (file?.target?.fileVal?.size ? file?.target?.fileVal?.size : file?.target?.size) | filesize:(is_mobile ? 1 : 2) }}</span>
        
                                        <ng-container *ngIf="!file?.target?.upload?.is_end && file?.target?.upload?.state != 'ERROR' && (file.countSameFiles == 0 || file.countSameFiles == -1 || !!file.data.tryAgain)">
                                            <mat-progress-bar
                                                class="file_progress_bar"
                                                color="primary"
                                                mode="determinate"
                                                [value]="file?.target?.upload?.progress">
                                            </mat-progress-bar>
                                        </ng-container>
                                    </div>

                                    <ng-container *ngIf="file?.target?.upload?.state != 'ERROR'">
                                        <div class="file_size" *ngIf="file?.target?.upload?.speed">
                                            <span>
                                                {{file?.target?.upload?.speed | sizepersec}}
                                            </span>
                                        </div>
            
                                        <div class="file_size" *ngIf="file?.target?.upload?.timeEnd || file?.target?.rangeUpload?.threads">
                                            <span *ngIf="file?.target?.upload?.timeEnd">
                                                <ng-container *ngIf="file.smartUpload; else elseTemplateSmart">
                                                    {{file?.target?.upload?.timeEnd | timeFormat:true}}
                                                </ng-container>
                                                <ng-template #elseTemplateSmart>
                                                    {{file?.target?.upload?.timeEnd | fromnowtotime:true}}
                                                </ng-template>
                                            </span>
                                            <div class="threads" *ngIf="file?.target?.rangeUpload?.threads">
                                                {{ file?.target?.rangeUpload?.threads }}
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>

                            <div class="d-f-c" *ngIf="!!file?.target?.upload?.is_end || file?.target?.upload?.state == 'ERROR' || (file.countSameFiles > 0 && !file.data.tryAgain)">
                                <div class="file_progress">
                                    <ng-container *ngIf="!file?.target?.upload?.is_end; else elseTemplateIsEnd">
                                        <ng-container *ngIf="file?.target?.upload?.state == 'ERROR'; else elseTemplateError">
                                            <div class="warning_block red" *ngIf="file">
                                                <span>{{ (is_mobile ? "Error" : "Upload interrupted") | translate }}</span>
                                                <button class="red" (click)="$event.preventDefault(); $event.stopPropagation(); cancelUpload(file)">{{ "Canel" | translate }}</button>
                                                <button (click)="$event.preventDefault(); $event.stopPropagation(); locateFile(file, intUploader)">{{ "Locate" | translate }}</button>
                                                <button (click)="$event.preventDefault(); $event.stopPropagation(); tryAgain(file)">{{ "Retry" | translate }}</button>
                                            </div>
                                        </ng-container>
                                        <ng-template #elseTemplateError>
                                            <ng-container *ngIf="file.countSameFiles > 0 && !file.data.tryAgain">
                                                <div class="warning_block orange" *ngIf="file">
                                                    <span>{{ "Duplicates found" | translate }}</span>
                                                    <button (click)="$event.preventDefault(); $event.stopPropagation(); openSameFiles(file)">{{ "Show options" | translate }}</button>
                                                </div>
                                            </ng-container>
                                        </ng-template>
                                    </ng-container>
                                    <ng-template #elseTemplateIsEnd>
                                        <mat-icon style="color: #009432;">check_circle</mat-icon>
                                    </ng-template>
                                </div>
                            </div>
        
                        </div>
        
                        <div class="file_options" *ngIf="!!file.showOptions">
                            <ng-container *ngIf="!!file?.data?.task_operation_id; else elseTemplateFileForJob">
                                <div class="file_option" *ngIf="!!file?.target?.selectedCheck">
                                    <span class="mr-1">{{ "More files needed for this Check point?" | translate }} </span>
                                    <mat-radio-group
                                        aria-labelledby="example-radio-group-label"
                                        class="example-radio-group"
                                        (change)="changeNeededFiles($event, file)"
                                        >
                                        <mat-radio-button [checked]="!file?.target?.selectedCheck?.status" class="example-radio-button" [value]="true">
                                            Yes
                                        </mat-radio-button>
                                        
                                        <mat-radio-button [checked]="!!file?.target?.selectedCheck?.status" class="example-radio-button ml-1" [value]="false">
                                            No
                                        </mat-radio-button>
                                    </mat-radio-group>
                                </div>
                                <div class="file_option" *ngIf="file?.target?.props">
                                    <mat-checkbox (change)="readyFile($event, file)" class="ready_checkbox" [(ngModel)]="file.target.props.is_ready_if_approved">{{ "Ready version, if approved" | translate }}</mat-checkbox>
                                    <select class="select_check" *ngIf="!!file?.work?.checklists && !!file?.work?.checklists?.length > 0 && !!file?.data?.task_operation_id && !!file.target.props.is_ready_if_approved" (change)="changeCheckList($event, file)">
                                        <option hidden [value]="0">{{ "This file related to Checklist point" | translate }}</option>
                                        <option *ngFor="let check of file?.work?.checklists" [selected]="+file?.target?.props?.operation_reminder_id == +check.id" [value]="check.id">
                                            {{ check.text }}
                                        </option>
                                    </select>
                                </div>
                            </ng-container>
                            <ng-template #elseTemplateFileForJob>
                                <div class="file_option">
                                    <span style="white-space: nowrap;">
                                        {{ "There are no available options." | translate}}
                                    </span>
                                </div>
                            </ng-template>
                        </div>
                </div>
                <div class="file_item uploaded_file_item" [ngClass]="{'canceled_file': file.is_uploaded == 0}" *ngFor="let file of uploadedFiles">
                    <div class="file_item_inside">
                        <div class="file_info">
                            <div class="d-f-c">
                                <div class="file_item_more" [ngClass]="{'opened': !!file.showOptions}">
                                    <mat-icon style="color: #686868;" (click)="toggleFileOptions(file)">more_vert</mat-icon>
                                </div>
                                <div class="file_img" (click)="viewUploadedFile(file)" [ngClass]="{'cp': !!file.is_uploaded}">
                                    <ng-container [ngSwitch]="understandFileType(file?.content_type)">
                                        <ng-container *ngSwitchCase="'video'">
                                            <ng-container *ngIf="file?.thumbnail; else elseTemplateThumbnailInterface">
                                                <div class="movie_thumb">
                                                    <img style="max-width: 100%; max-height: 100%;" (error)="onImgError($event)" [src]="host + file?.thumbnail + '?company_id=' + file?.company_id">
                                                    <mat-icon>play_circle</mat-icon>
                                                </div>
                                            </ng-container>
                                            <ng-template #elseTemplateThumbnailInterface>
                                                <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g clip-path="url(#clip0_3006_50681)">
                                                    <path d="M7.00033 2.3335C5.71699 2.3335 4.67866 3.3835 4.67866 4.66683L4.66699 23.3335C4.66699 24.6168 5.70533 25.6668 6.98866 25.6668H21.0003C22.2837 25.6668 23.3337 24.6168 23.3337 23.3335V9.3335L16.3337 2.3335H7.00033ZM15.167 10.5002V4.0835L21.5837 10.5002H15.167Z" fill="#6299E1"/>
                                                    </g>
                                                    <defs>
                                                    <clipPath id="clip0_3006_50681">
                                                    <rect width="28" height="28" fill="white"/>
                                                    </clipPath>
                                                    </defs>
                                                </svg>
                                            </ng-template>
                                        </ng-container>
                                        <ng-container *ngSwitchCase="'pdf'">
                                            <div class="pdf">
                                                <svg height="800px" width="800px" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
                                                    viewBox="0 0 303.188 303.188" xml:space="preserve">
                                                    <g>
                                                        <polygon style="fill:#E8E8E8;" points="219.821,0 32.842,0 32.842,303.188 270.346,303.188 270.346,50.525 	"/>
                                                        <path style="fill:#FB3449;" d="M230.013,149.935c-3.643-6.493-16.231-8.533-22.006-9.451c-4.552-0.724-9.199-0.94-13.803-0.936
                                                            c-3.615-0.024-7.177,0.154-10.693,0.354c-1.296,0.087-2.579,0.199-3.861,0.31c-1.314-1.36-2.584-2.765-3.813-4.202
                                                            c-7.82-9.257-14.134-19.755-19.279-30.664c1.366-5.271,2.459-10.772,3.119-16.485c1.205-10.427,1.619-22.31-2.288-32.251
                                                            c-1.349-3.431-4.946-7.608-9.096-5.528c-4.771,2.392-6.113,9.169-6.502,13.973c-0.313,3.883-0.094,7.776,0.558,11.594
                                                            c0.664,3.844,1.733,7.494,2.897,11.139c1.086,3.342,2.283,6.658,3.588,9.943c-0.828,2.586-1.707,5.127-2.63,7.603
                                                            c-2.152,5.643-4.479,11.004-6.717,16.161c-1.18,2.557-2.335,5.06-3.465,7.507c-3.576,7.855-7.458,15.566-11.815,23.02
                                                            c-10.163,3.585-19.283,7.741-26.857,12.625c-4.063,2.625-7.652,5.476-10.641,8.603c-2.822,2.952-5.69,6.783-5.941,11.024
                                                            c-0.141,2.394,0.807,4.717,2.768,6.137c2.697,2.015,6.271,1.881,9.4,1.225c10.25-2.15,18.121-10.961,24.824-18.387
                                                            c4.617-5.115,9.872-11.61,15.369-19.465c0.012-0.018,0.024-0.036,0.037-0.054c9.428-2.923,19.689-5.391,30.579-7.205
                                                            c4.975-0.825,10.082-1.5,15.291-1.974c3.663,3.431,7.621,6.555,11.939,9.164c3.363,2.069,6.94,3.816,10.684,5.119
                                                            c3.786,1.237,7.595,2.247,11.528,2.886c1.986,0.284,4.017,0.413,6.092,0.335c4.631-0.175,11.278-1.951,11.714-7.57
                                                            C231.127,152.765,230.756,151.257,230.013,149.935z M119.144,160.245c-2.169,3.36-4.261,6.382-6.232,9.041
                                                            c-4.827,6.568-10.34,14.369-18.322,17.286c-1.516,0.554-3.512,1.126-5.616,1.002c-1.874-0.11-3.722-0.937-3.637-3.065
                                                            c0.042-1.114,0.587-2.535,1.423-3.931c0.915-1.531,2.048-2.935,3.275-4.226c2.629-2.762,5.953-5.439,9.777-7.918
                                                            c5.865-3.805,12.867-7.23,20.672-10.286C120.035,158.858,119.587,159.564,119.144,160.245z M146.366,75.985
                                                            c-0.602-3.514-0.693-7.077-0.323-10.503c0.184-1.713,0.533-3.385,1.038-4.952c0.428-1.33,1.352-4.576,2.826-4.993
                                                            c2.43-0.688,3.177,4.529,3.452,6.005c1.566,8.396,0.186,17.733-1.693,25.969c-0.299,1.31-0.632,2.599-0.973,3.883
                                                            c-0.582-1.601-1.137-3.207-1.648-4.821C147.945,83.048,146.939,79.482,146.366,75.985z M163.049,142.265
                                                            c-9.13,1.48-17.815,3.419-25.979,5.708c0.983-0.275,5.475-8.788,6.477-10.555c4.721-8.315,8.583-17.042,11.358-26.197
                                                            c4.9,9.691,10.847,18.962,18.153,27.214c0.673,0.749,1.357,1.489,2.053,2.22C171.017,141.096,166.988,141.633,163.049,142.265z
                                                            M224.793,153.959c-0.334,1.805-4.189,2.837-5.988,3.121c-5.316,0.836-10.94,0.167-16.028-1.542
                                                            c-3.491-1.172-6.858-2.768-10.057-4.688c-3.18-1.921-6.155-4.181-8.936-6.673c3.429-0.206,6.9-0.341,10.388-0.275
                                                            c3.488,0.035,7.003,0.211,10.475,0.664c6.511,0.726,13.807,2.961,18.932,7.186C224.588,152.585,224.91,153.321,224.793,153.959z"/>
                                                        <polygon style="fill:#FB3449;" points="227.64,25.263 32.842,25.263 32.842,0 219.821,0 	"/>
                                                        <g>
                                                            <path style="fill:#A4A9AD;" d="M126.841,241.152c0,5.361-1.58,9.501-4.742,12.421c-3.162,2.921-7.652,4.381-13.472,4.381h-3.643
                                                                v15.917H92.022v-47.979h16.606c6.06,0,10.611,1.324,13.652,3.971C125.321,232.51,126.841,236.273,126.841,241.152z
                                                                M104.985,247.387h2.363c1.947,0,3.495-0.546,4.644-1.641c1.149-1.094,1.723-2.604,1.723-4.529c0-3.238-1.794-4.857-5.382-4.857
                                                                h-3.348C104.985,236.36,104.985,247.387,104.985,247.387z"/>
                                                            <path style="fill:#A4A9AD;" d="M175.215,248.864c0,8.007-2.205,14.177-6.613,18.509s-10.606,6.498-18.591,6.498h-15.523v-47.979
                                                                h16.606c7.701,0,13.646,1.969,17.836,5.907C173.119,235.737,175.215,241.426,175.215,248.864z M161.76,249.324
                                                                c0-4.398-0.87-7.657-2.609-9.78c-1.739-2.122-4.381-3.183-7.926-3.183h-3.773v26.877h2.888c3.939,0,6.826-1.143,8.664-3.43
                                                                C160.841,257.523,161.76,254.028,161.76,249.324z"/>
                                                            <path style="fill:#A4A9AD;" d="M196.579,273.871h-12.766v-47.979h28.355v10.403h-15.589v9.156h14.374v10.403h-14.374
                                                                L196.579,273.871L196.579,273.871z"/>
                                                        </g>
                                                        <polygon style="fill:#D1D3D3;" points="219.821,50.525 270.346,50.525 219.821,0 	"/>
                                                    </g>
                                                </svg>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngSwitchCase="'image'">
                                            <img (error)="onImgError($event)" style="max-width: 100%; max-height: 100%;" [src]="host + (file?.thumbnail ? file?.thumbnail : file?.original) + '?company_id=' + file?.company_id">
                                        </ng-container>
                                        <ng-container *ngSwitchDefault>
                                            <ng-container *ngIf="!!file?.thumbnail; else elseTemplateHasThumb">
                                                <img (error)="onImgError($event)" style="max-width: 100%; max-height: 100%;" [src]="host + file?.thumbnail + '?company_id=' + file?.company_id">
                                            </ng-container>
                                            <ng-template #elseTemplateHasThumb>
                                                <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g clip-path="url(#clip0_3006_50681)">
                                                    <path d="M7.00033 2.3335C5.71699 2.3335 4.67866 3.3835 4.67866 4.66683L4.66699 23.3335C4.66699 24.6168 5.70533 25.6668 6.98866 25.6668H21.0003C22.2837 25.6668 23.3337 24.6168 23.3337 23.3335V9.3335L16.3337 2.3335H7.00033ZM15.167 10.5002V4.0835L21.5837 10.5002H15.167Z" fill="#6299E1"/>
                                                    </g>
                                                    <defs>
                                                    <clipPath id="clip0_3006_50681">
                                                    <rect width="28" height="28" fill="white"/>
                                                    </clipPath>
                                                    </defs>
                                                </svg>
                                            </ng-template>
                                        </ng-container>
                                    </ng-container>
                                </div>
                                <div class="file_name" (click)="sortFiles()">
                                    {{(file?.filename.length > 22 ? (file?.filename | slice:0:15) + "..." + (file?.filename | slice:-5) : file?.filename | translate)}}
                                </div>
                            </div>

                            <div class="file_size">
                                <span>{{ file.filesize | filesize }}</span>
                            </div>
                        </div>
                        <div class="d-f-c">
                            <div class="file_progress">
                                <ng-container *ngIf="file.is_uploaded; else elseTemplate">
                                    <mat-icon style="color: #009432;">check_circle</mat-icon>
                                </ng-container>
                                <ng-template #elseTemplate>
                                    <span class="canceled_info">{{"You canceled upload" | translate}}</span>
                                </ng-template>
                            </div>
                        </div>

                    </div>

                    <div class="file_options" *ngIf="!!file.showOptions">
                        <ng-container *ngIf="!!file?.data?.task_operation_id; else elseTemplateFileForJob">
                            <div class="file_option" *ngIf="!!file.selectedCheck">
                                <span class="mr-1">{{ "More files needed for this Check point?" | translate }} </span>
                                <mat-radio-group
                                    aria-labelledby="example-radio-group-label"
                                    class="example-radio-group"
                                    (change)="changeNeededFiles($event, file)"
                                    >
                                    <mat-radio-button [checked]="!file.selectedCheck.status" class="example-radio-button" [value]="true">
                                        Yes
                                    </mat-radio-button>
                                    
                                    <mat-radio-button [checked]="!!file.selectedCheck.status" class="example-radio-button ml-1" [value]="false">
                                        No
                                    </mat-radio-button>
                                </mat-radio-group>
                            </div>
                            <div class="file_option">
                                <mat-checkbox (change)="readyFile($event, file)" class="ready_checkbox" [(ngModel)]="file.data.is_ready_if_approved">{{ "Ready version, if approved" | translate }}</mat-checkbox>
                                <select class="select_check" *ngIf="!!file?.checklist && file.checklist.length > 0 && !!file?.data?.task_operation_id && !!file.data.is_ready_if_approved" (change)="changeCheckList($event, file)">
                                    <option hidden [value]="0">{{ "This file related to Checklist point" | translate }}</option>
                                    <option *ngFor="let check of file.checklist" [selected]="+file.data.operation_reminder_id == +check.id" [value]="check.id">
                                        {{ check.text }}
                                    </option>
                                </select>
                            </div>
                        </ng-container>
                        <ng-template #elseTemplateFileForJob>
                            <div class="file_option">
                                <span style="white-space: nowrap;">
                                    {{ "There are no available options." | translate}}
                                </span>
                            </div>
                        </ng-template>
                    </div>
                </div>
            </ng-container>
            <ng-template #elseTemplateTypeVal>
                <div class="file_item uploaded_file_item" [ngClass]="{'canceled_file': file.is_uploaded == 0}" *ngFor="let file of copiedFiles">
                    <div class="file_item_inside">
                        <div class="file_info">
                            <div class="d-f-c">
                                <div class="file_item_more" [ngClass]="{'opened': !!file.showOptions}">
                                    <mat-icon style="color: #686868;" (click)="toggleFileOptions(file)">more_vert</mat-icon>
                                </div>
                                <div class="file_img" (click)="viewUploadedFile(file)" [ngClass]="{'cp': !!file.is_uploaded}">
                                    <ng-container [ngSwitch]="understandFileType(file?.content_type)">
                                        <ng-container *ngSwitchCase="'video'">
                                            <ng-container *ngIf="file?.thumbnail; else elseTemplateThumbnailInterface">
                                                <div class="movie_thumb">
                                                    <img style="max-width: 100%; max-height: 100%;" (error)="onImgError($event)" [src]="host + file?.thumbnail + '?company_id=' + file?.company_id">
                                                    <mat-icon>play_circle</mat-icon>
                                                </div>
                                            </ng-container>
                                            <ng-template #elseTemplateThumbnailInterface>
                                                <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g clip-path="url(#clip0_3006_50681)">
                                                    <path d="M7.00033 2.3335C5.71699 2.3335 4.67866 3.3835 4.67866 4.66683L4.66699 23.3335C4.66699 24.6168 5.70533 25.6668 6.98866 25.6668H21.0003C22.2837 25.6668 23.3337 24.6168 23.3337 23.3335V9.3335L16.3337 2.3335H7.00033ZM15.167 10.5002V4.0835L21.5837 10.5002H15.167Z" fill="#6299E1"/>
                                                    </g>
                                                    <defs>
                                                    <clipPath id="clip0_3006_50681">
                                                    <rect width="28" height="28" fill="white"/>
                                                    </clipPath>
                                                    </defs>
                                                </svg>
                                            </ng-template>
                                        </ng-container>
                                        <ng-container *ngSwitchCase="'pdf'">
                                            <div class="pdf">
                                                <svg height="800px" width="800px" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
                                                    viewBox="0 0 303.188 303.188" xml:space="preserve">
                                                    <g>
                                                        <polygon style="fill:#E8E8E8;" points="219.821,0 32.842,0 32.842,303.188 270.346,303.188 270.346,50.525 	"/>
                                                        <path style="fill:#FB3449;" d="M230.013,149.935c-3.643-6.493-16.231-8.533-22.006-9.451c-4.552-0.724-9.199-0.94-13.803-0.936
                                                            c-3.615-0.024-7.177,0.154-10.693,0.354c-1.296,0.087-2.579,0.199-3.861,0.31c-1.314-1.36-2.584-2.765-3.813-4.202
                                                            c-7.82-9.257-14.134-19.755-19.279-30.664c1.366-5.271,2.459-10.772,3.119-16.485c1.205-10.427,1.619-22.31-2.288-32.251
                                                            c-1.349-3.431-4.946-7.608-9.096-5.528c-4.771,2.392-6.113,9.169-6.502,13.973c-0.313,3.883-0.094,7.776,0.558,11.594
                                                            c0.664,3.844,1.733,7.494,2.897,11.139c1.086,3.342,2.283,6.658,3.588,9.943c-0.828,2.586-1.707,5.127-2.63,7.603
                                                            c-2.152,5.643-4.479,11.004-6.717,16.161c-1.18,2.557-2.335,5.06-3.465,7.507c-3.576,7.855-7.458,15.566-11.815,23.02
                                                            c-10.163,3.585-19.283,7.741-26.857,12.625c-4.063,2.625-7.652,5.476-10.641,8.603c-2.822,2.952-5.69,6.783-5.941,11.024
                                                            c-0.141,2.394,0.807,4.717,2.768,6.137c2.697,2.015,6.271,1.881,9.4,1.225c10.25-2.15,18.121-10.961,24.824-18.387
                                                            c4.617-5.115,9.872-11.61,15.369-19.465c0.012-0.018,0.024-0.036,0.037-0.054c9.428-2.923,19.689-5.391,30.579-7.205
                                                            c4.975-0.825,10.082-1.5,15.291-1.974c3.663,3.431,7.621,6.555,11.939,9.164c3.363,2.069,6.94,3.816,10.684,5.119
                                                            c3.786,1.237,7.595,2.247,11.528,2.886c1.986,0.284,4.017,0.413,6.092,0.335c4.631-0.175,11.278-1.951,11.714-7.57
                                                            C231.127,152.765,230.756,151.257,230.013,149.935z M119.144,160.245c-2.169,3.36-4.261,6.382-6.232,9.041
                                                            c-4.827,6.568-10.34,14.369-18.322,17.286c-1.516,0.554-3.512,1.126-5.616,1.002c-1.874-0.11-3.722-0.937-3.637-3.065
                                                            c0.042-1.114,0.587-2.535,1.423-3.931c0.915-1.531,2.048-2.935,3.275-4.226c2.629-2.762,5.953-5.439,9.777-7.918
                                                            c5.865-3.805,12.867-7.23,20.672-10.286C120.035,158.858,119.587,159.564,119.144,160.245z M146.366,75.985
                                                            c-0.602-3.514-0.693-7.077-0.323-10.503c0.184-1.713,0.533-3.385,1.038-4.952c0.428-1.33,1.352-4.576,2.826-4.993
                                                            c2.43-0.688,3.177,4.529,3.452,6.005c1.566,8.396,0.186,17.733-1.693,25.969c-0.299,1.31-0.632,2.599-0.973,3.883
                                                            c-0.582-1.601-1.137-3.207-1.648-4.821C147.945,83.048,146.939,79.482,146.366,75.985z M163.049,142.265
                                                            c-9.13,1.48-17.815,3.419-25.979,5.708c0.983-0.275,5.475-8.788,6.477-10.555c4.721-8.315,8.583-17.042,11.358-26.197
                                                            c4.9,9.691,10.847,18.962,18.153,27.214c0.673,0.749,1.357,1.489,2.053,2.22C171.017,141.096,166.988,141.633,163.049,142.265z
                                                            M224.793,153.959c-0.334,1.805-4.189,2.837-5.988,3.121c-5.316,0.836-10.94,0.167-16.028-1.542
                                                            c-3.491-1.172-6.858-2.768-10.057-4.688c-3.18-1.921-6.155-4.181-8.936-6.673c3.429-0.206,6.9-0.341,10.388-0.275
                                                            c3.488,0.035,7.003,0.211,10.475,0.664c6.511,0.726,13.807,2.961,18.932,7.186C224.588,152.585,224.91,153.321,224.793,153.959z"/>
                                                        <polygon style="fill:#FB3449;" points="227.64,25.263 32.842,25.263 32.842,0 219.821,0 	"/>
                                                        <g>
                                                            <path style="fill:#A4A9AD;" d="M126.841,241.152c0,5.361-1.58,9.501-4.742,12.421c-3.162,2.921-7.652,4.381-13.472,4.381h-3.643
                                                                v15.917H92.022v-47.979h16.606c6.06,0,10.611,1.324,13.652,3.971C125.321,232.51,126.841,236.273,126.841,241.152z
                                                                M104.985,247.387h2.363c1.947,0,3.495-0.546,4.644-1.641c1.149-1.094,1.723-2.604,1.723-4.529c0-3.238-1.794-4.857-5.382-4.857
                                                                h-3.348C104.985,236.36,104.985,247.387,104.985,247.387z"/>
                                                            <path style="fill:#A4A9AD;" d="M175.215,248.864c0,8.007-2.205,14.177-6.613,18.509s-10.606,6.498-18.591,6.498h-15.523v-47.979
                                                                h16.606c7.701,0,13.646,1.969,17.836,5.907C173.119,235.737,175.215,241.426,175.215,248.864z M161.76,249.324
                                                                c0-4.398-0.87-7.657-2.609-9.78c-1.739-2.122-4.381-3.183-7.926-3.183h-3.773v26.877h2.888c3.939,0,6.826-1.143,8.664-3.43
                                                                C160.841,257.523,161.76,254.028,161.76,249.324z"/>
                                                            <path style="fill:#A4A9AD;" d="M196.579,273.871h-12.766v-47.979h28.355v10.403h-15.589v9.156h14.374v10.403h-14.374
                                                                L196.579,273.871L196.579,273.871z"/>
                                                        </g>
                                                        <polygon style="fill:#D1D3D3;" points="219.821,50.525 270.346,50.525 219.821,0 	"/>
                                                    </g>
                                                </svg>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngSwitchCase="'image'">
                                            <img (error)="onImgError($event)" style="max-width: 100%; max-height: 100%;" [src]="host + (file?.thumbnail ? file?.thumbnail : file?.original) + '?company_id=' + file?.company_id">
                                        </ng-container>
                                        <ng-container *ngSwitchDefault>
                                            <ng-container *ngIf="!!file?.thumbnail; else elseTemplateHasThumb">
                                                <img (error)="onImgError($event)" style="max-width: 100%; max-height: 100%;" [src]="host + file?.thumbnail + '?company_id=' + file?.company_id">
                                            </ng-container>
                                            <ng-template #elseTemplateHasThumb>
                                                <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g clip-path="url(#clip0_3006_50681)">
                                                    <path d="M7.00033 2.3335C5.71699 2.3335 4.67866 3.3835 4.67866 4.66683L4.66699 23.3335C4.66699 24.6168 5.70533 25.6668 6.98866 25.6668H21.0003C22.2837 25.6668 23.3337 24.6168 23.3337 23.3335V9.3335L16.3337 2.3335H7.00033ZM15.167 10.5002V4.0835L21.5837 10.5002H15.167Z" fill="#6299E1"/>
                                                    </g>
                                                    <defs>
                                                    <clipPath id="clip0_3006_50681">
                                                    <rect width="28" height="28" fill="white"/>
                                                    </clipPath>
                                                    </defs>
                                                </svg>
                                            </ng-template>
                                        </ng-container>
                                    </ng-container>
                                </div>
                                <div class="file_name" (click)="sortFiles()">
                                    {{(file?.filename.length > 22 ? (file?.filename | slice:0:15) + "..." + (file?.filename | slice:-5) : file?.filename | translate)}}
                                </div>
                            </div>

                            <div class="file_size">
                                <span>{{ file.filesize | filesize }}</span>
                            </div>
                        </div>
                        <div class="d-f-c">
                            <div class="file_progress">
                                <ng-container *ngIf="file.is_uploaded; else elseTemplate">
                                    <mat-icon style="color: #009432;">check_circle</mat-icon>
                                </ng-container>
                                <ng-template #elseTemplate>
                                    <span class="canceled_info">{{"You canceled upload" | translate}}</span>
                                </ng-template>
                            </div>
                        </div>

                    </div>

                    <div class="file_options" *ngIf="!!file.showOptions">
                        <ng-container *ngIf="!!file?.data?.task_operation_id; else elseTemplateFileForJob">
                            <div class="file_option" *ngIf="!!file.selectedCheck">
                                <span class="mr-1">{{ "More files needed for this Check point?" | translate }} </span>
                                <mat-radio-group
                                    aria-labelledby="example-radio-group-label"
                                    class="example-radio-group"
                                    (change)="changeNeededFiles($event, file)"
                                    >
                                    <mat-radio-button [checked]="!file.selectedCheck.status" class="example-radio-button" [value]="true">
                                        Yes
                                    </mat-radio-button>
                                    
                                    <mat-radio-button [checked]="!!file.selectedCheck.status" class="example-radio-button ml-1" [value]="false">
                                        No
                                    </mat-radio-button>
                                </mat-radio-group>
                            </div>
                            <div class="file_option">
                                <mat-checkbox (change)="readyFile($event, file)" class="ready_checkbox" [(ngModel)]="file.data.is_ready_if_approved">{{ "Ready version, if approved" | translate }}</mat-checkbox>
                                <select class="select_check" *ngIf="!!file?.checklist && file.checklist.length > 0 && !!file?.data?.task_operation_id && !!file.data.is_ready_if_approved" (change)="changeCheckList($event, file)">
                                    <option hidden [value]="0">{{ "This file related to Checklist point" | translate }}</option>
                                    <option *ngFor="let check of file.checklist" [selected]="+file.data.operation_reminder_id == +check.id" [value]="check.id">
                                        {{ check.text }}
                                    </option>
                                </select>
                            </div>
                        </ng-container>
                        <ng-template #elseTemplateFileForJob>
                            <div class="file_option">
                                <span style="white-space: nowrap;">
                                    {{ "There are no available options." | translate}}
                                </span>
                            </div>
                        </ng-template>
                    </div>
                </div>
            </ng-template>
           
        </div>
    </div>
</div>

<input 
hidden 
type="file" 
#intUploader
(change)="uploadFile($event)"
/>