<h2 mat-dialog-title class="dialog_title">
    <span (click)="log()">
        {{ "Editing channel description" | translate }}
    </span>
    <button style="margin-left: 20px;" mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h2>
<mat-dialog-content class="mat-typography" dirEnter (dirSubmit)="!transForm.valid || isSubmit ? null : submitForm()">
    <div class="container">
        <form [formGroup]="transForm">
            <div class="d-f-c">
                <mat-form-field appearance="standard" class="full_width_form">
                    <mat-label>{{ 'Title' | translate }}</mat-label>
                    <input cdkFocusInitial formControlName="title" (input)="onInputChange($event)" [disabled]="!transForm.get('language').value" matInput type="text" placeholder="{{ 'Title' | translate }}">
                </mat-form-field>
                <mat-form-field class="language_block" appearance="standard">
                    <mat-label>{{ "Language" | translate }}</mat-label>
                    <mat-select formControlName="language">
                      <mat-option *ngFor="let lang of languages" [value]="lang?.id">{{lang?.snippet?.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            
            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ 'Description' | translate }}</mat-label>
                <textarea 
                [disabled]="!transForm.get('language').value"
                cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="3"
                cdkAutosizeMaxRows="9" formControlName="description" (input)="onInputChange($event)" matInput placeholder="{{ 'Description' | translate }}"></textarea>
                <mat-hint class="char_counter_hint" *ngIf="transForm.get('description').value" [ngClass]="{'red': transForm.get('description').value.length > 999999999}" align="end">{{transForm.get('description').value.length}}</mat-hint>
            </mat-form-field>
        </form>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button (click)="close()">
        {{ "Close" | translate }}
    </button>
    <button class="ml-1 d-f-btn" (click)="submitForm()" mat-raised-button cdkFocusInitial color="primary" [disabled]="!transForm.valid || isSubmit">
        <mat-spinner class="spin_16 mr-1" *ngIf="isSubmit"></mat-spinner>  
        {{ "Save" | translate }}
    </button>
</mat-dialog-actions>
