<canvas class="blur_bg" [id]="'bg_'+part.pId"  *ngIf="part.has_blur_bg"></canvas>
<div #box class="resizable-draggable no-select-text" 
    [class.cropOn]="part.crop_on"
    [style.width.px]="part.rd.width" 
    [style.height.px]="part.rd.height" 
    [style.left.px]="part.rd.left" 
    [style.top.px]="part.rd.top" 
    [style.transform]="'rotate(' + part.rd.deg + 'deg)'"
    [class.active]="status === 1 || status === 2 || status === 3"
    (mousedown)="part.crop_on ? setStatus($event, 2) : null"
    (window:mouseup)="part.crop_on ? setStatus($event, 0) : null"
>
    <div *ngIf="part.crop_on" class="resize-action" (mousedown)="setStatus($event, 1)"></div>
    <div #rotate class="rotate-action" [ngClass]="{'not-show': !part.crop_on}" (mousedown)="setStatus($event, 3)"></div>
    <!-- <div class="center" *ngIf="part.crop_on"
    [style.left.px]="center.x" 
    [style.top.px]="center.y"></div> -->
    <!-- <div *ngIf="part.crop_on" class="axis x_line" [style.transform]="'rotate(' + -(part.rd.deg) + 'deg)'"></div>
    <div *ngIf="part.crop_on" class="axis y_line" [style.transform]="'rotate(' + -(part.rd.deg) + 'deg)'"></div> -->
    <!-- <div #pointTopLeft [ngClass]="{'not-show': !part.crop_on}" class="point top left"></div>
    <div #pointTopRight [ngClass]="{'not-show': !part.crop_on}" class="point top right"></div>
    <div #pointBottomLeft [ngClass]="{'not-show': !part.crop_on}" class="point bottom left"></div>
    <div #pointBottomRight [ngClass]="{'not-show': !part.crop_on}" class="point bottom right"></div> -->
    <!-- <span>{{part.rd.width | number:'1.0-0'}}px</span>
    <span>{{part.rd.height | number:'1.0-0'}}px</span>
    <span class="xy">(x:{{part.rd.left}}, y:{{part.rd.top}})</span> -->
    <video 
    [ngStyle]="part?.video_filters_on ? {
        'filter': 'brightness(' + part?.video_filters?.brightness + '%) ' +
            'contrast(' + part?.video_filters?.contrast + '%) ' +
            'saturate(' + part?.video_filters?.saturation + '%) ' +
            'grayscale(' + part?.video_filters?.grayscale + '%) ' +
            'hue-rotate(' + part?.video_filters?.hue + 'deg)'
      } : {'filter': 'unset'}"
    #video 
    height="100%" 
    width="100%" 
    [id]="'video_'+part.pId" 
    class="player" 
    [src]="!!part?.blobSrc ? part?.blobSrc : (host + (part?.preview1080 ? part?.preview1080 : part?.original) + '?company_id=' + company_id)"
    (loadedmetadata)="loadedVideo($event)" 
    (timeupdate)="loadBlurBg($event)"
    >
        Your browser doesn't support HTML5 video tag.
    </video>
    <div [ngClass]="{'not-show': !part.rd.deg}" class="black_sq" #black_sq [style.transform]="'rotate(' + -(part.rd.deg) + 'deg)'" [style.left.px]="-(max_side - part.rd.width)/2" [style.top.px]="-(max_side - part.rd.height)/2" [style.width.px]="max_side" [style.height.px]="max_side"></div>
    <div class="curr_time">{{video?.currentTime | veTime}}</div>
    <!-- | {{'brightness(' + part?.video_filters?.brightness + '%) contrast(' + part?.video_filters?.contrast + '%) saturate(' + part?.video_filters?.saturation + '%) grayscale(' + part?.video_filters?.grayscale + '%) hue-rotate(' + part?.video_filters?.hue + 'deg);'}} -->

    <!-- <div class="sub">
        currentTimeProject: {{currentTimeProject}}
        <hr>
        currentCutTimeProject: {{currentCutTimeProject}}
        <hr>
        isProjectPlaying: {{isProjectPlaying}}
        <hr>
        verticalLine: {{verticalLine|json}}
        <hr>
    </div> -->
    <ng-container *ngIf="!hasCropOn && !!part?.subs_on && !!part?.subs">
        <div class="subs" 
        [ngClass]="{'edited_pos': !!part?.subSet?.pos?.is_edited}"
        [style.top.px]='part?.subSet?.pos?.y'
        [style.left.px]='part?.subSet?.pos?.x'
        [style.width.px]='part?.subSet?.pos?.width'
        [style.minHeight.px]='part?.subSet?.pos?.height'
        (mousedown)='onWindowPress($event)'
        (mousemove)='onWindowDrag($event)'
        [ngStyle]="{
            'font-size': (part?.subSet?.fontSize+'px') || '84px',
            'color': part?.subSet?.pastColor || '#fff',
            '-webkit-text-stroke-width': part?.subSet?.outline+'px' || '2px',
            '-webkit-text-stroke-color': part?.subSet?.outlineColor,
            'font-style': !!part?.subSet?.italic ? 'italic' : 'normal',
            'font-weight': !!part?.subSet?.bold ? 'bold' : 'normal'
        }">
            <ng-container *ngIf="!!part?.subSet?.pos?.is_edited">
                <div (mousedown)='onCornerClick($event, topLeftResize)' id='subs-top-left-resize' class='subs-corner-resize'></div>
                <div (mousedown)='onCornerClick($event, topRightResize)' id='subs-top-right-resize' class='subs-corner-resize'></div>
                <div (mousedown)='onCornerClick($event, bottomLeftResize)' id='subs-bottom-left-resize' class='subs-corner-resize'></div>
                <div (mousedown)='onCornerClick($event, bottomRightResize)' id='subs-bottom-right-resize' class='subs-corner-resize'></div>
            </ng-container>
            
            <!-- <pre style="font-size: 8px; -webkit-text-stroke: unset; color: red;">
                {{part?.subSet?.pos | json}}
            </pre> -->
            <ng-container *ngFor="let item of part.subs">
                <!-- <div class="sub" *ngIf="(verticalLine.val >= (+item.from - +part.from) && verticalLine.val < (+item.to - +part.from))"> -->
         
                <div class="sub" *ngIf="(!isProjectPlaying && !!verticalLine?.show) ? ((currentCutTimeProject === 0 && verticalLine.val >= (+item.from - +part.from) && verticalLine.val < (+item.to - +part.from))) : ((currentCutTimeProject === 0 && currentTimeProject >= (+item.from - +part.from) && currentTimeProject < (+item.to - +part.from)) || (currentCutTimeProject !== 0 && currentCutTimeProject >= (+item.from - +part.from) && currentCutTimeProject < (+item.to - +part.from)))">
                    <ng-container *ngFor="let word of item.words; let last = last">
                        <ng-container *ngIf="(!isProjectPlaying && !!verticalLine?.show) ? ((currentCutTimeProject === 0 && verticalLine.val >= (+word.from - +part.from) && verticalLine.val < (+word.to - +part.from))) : ((currentCutTimeProject === 0 && currentTimeProject >= (+word.from - +part.from) && currentTimeProject < (+word.to - +part.from)) || (currentCutTimeProject !== 0 && currentCutTimeProject >= (+word.from - +part.from) && currentCutTimeProject < (+word.to - +part.from))); else elseTemplateActiveWord">
                            <span class="sub_word active_word" 
                            [ngStyle]="{ 
                                'margin-right': last ? '0' : '0.25em',
                                'color': !!part?.subSet?.is_karaoke ? part?.subSet?.activeColor : part?.subSet?.pastColor,
                                'text-decoration': !!part?.subSet?.underline ? 'underline '+ part?.subSet?.activeColor : (!!part?.subSet?.strike ? 'line-through ' + part?.subSet?.activeColor : 'none')
                            }">{{word.value}}</span>
                        </ng-container>
                        <ng-template #elseTemplateActiveWord>
                            <ng-container *ngIf="(!isProjectPlaying && !!verticalLine?.show) ? ((currentCutTimeProject === 0 && verticalLine.val < (+word.to - +part.from))) : ((currentCutTimeProject === 0 && currentTimeProject < (+word.to - +part.from)) || (currentCutTimeProject !== 0 && currentCutTimeProject < (+word.to - +part.from))); else elseTemplateFutureWord">
                                <span class="sub_word future_word" 
                                [ngStyle]="{ 
                                    'margin-right': last ? '0' : '0.25em',
                                    'color': !!part?.subSet?.is_karaoke ? part?.subSet?.futureColor : part?.subSet?.pastColor,
                                    'text-decoration': !!part?.subSet?.underline ? 'underline '+ part?.subSet?.futureColor : (!!part?.subSet?.strike ? 'line-through ' + part?.subSet?.futureColor : 'none')
                                }">{{word.value}}</span>
                            </ng-container>
                            <ng-template #elseTemplateFutureWord>
                                <span class="sub_word past_word" 
                                [ngStyle]="{ 
                                    'margin-right': last ? '0' : '0.25em',
                                    'color': part?.subSet?.pastColor,
                                    'text-decoration': !!part?.subSet?.underline ? 'underline '+ part?.subSet?.pastColor : (!!part?.subSet?.strike ? 'line-through ' + part?.subSet?.pastColor : 'none')
                                }">{{word.value}}</span>
                            </ng-template>
                        </ng-template>
                        
                        <!-- <span class="sub_word" 
                        [ngStyle]="{ 'margin-right': last ? '0' : '0.25em' }"
                        [ngClass]="{
                            'active_word': (!isProjectPlaying && !!verticalLine?.show) ? ((currentCutTimeProject === 0 && verticalLine.val >= (+word.from - +part.from) && verticalLine.val < (+word.to - +part.from))) : ((currentCutTimeProject === 0 && currentTimeProject >= (+word.from - +part.from) && currentTimeProject < (+word.to - +part.from)) || (currentCutTimeProject !== 0 && currentCutTimeProject >= (+word.from - +part.from) && currentCutTimeProject < (+word.to - +part.from))),
                            'prev_word': (!isProjectPlaying && !!verticalLine?.show) ? ((currentCutTimeProject === 0 && verticalLine.val < (+word.to - +part.from))) : ((currentCutTimeProject === 0 && currentTimeProject < (+word.to - +part.from)) || (currentCutTimeProject !== 0 && currentCutTimeProject < (+word.to - +part.from)))
                        }">{{word.value}}</span> -->
                    </ng-container>
                </div>
            </ng-container>
        </div>
    </ng-container>
    
</div>