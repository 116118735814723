<mat-dialog-content class="mat-typography">
    <span class="head_title" (click)="log()">
        <b style="margin-right: 3.5px;">{{(urlsByChannel$ | async).length}} {{"channels" | translate}}</b> {{urlsCount}} {{"streams" | translate}}
        <ng-container *ngIf="liveCollection.selected && liveCollection.selected.length">
            <div class="head_split">|</div>
            <div class="collections_btns">
                <button type="button" style="background: red;" (click)="deleteProfiles()">
                    {{ "Delete" | translate }}
                </button>
                <button class="ml-1" type="button" style="background: #67214a;" (click)="multiBackup()" *ngIf="checkLengthWithExId() > 0">
                    {{ "Backup" | translate }}
                </button>
                <button class="ml-1 mr-1" type="button" style="background: #424091;" (click)="multiDuplicatePosts()" *ngIf="checkLengthWithExId() > 0">
                    {{ "Duplicate" | translate }}
                </button>
                <!-- <button class="ml-1" type="button" style="background: #424091;" (click)="changeStatuses()">
                {{ "Change statuses" | translate }}
                </button>
                <button class="ml-1" type="button" style="background: #55ACEE;" (click)="multiRetryPublish()">
                {{ "Retry publish" | translate }}
                </button>
                <button class="ml-1" type="button" style="background: #1f9eff;" (click)="multiRetryPublishToFirst()">
                {{ "Retry publish to first available slot" | translate }}
                </button>
                <button class="no_cst_style ml-1" (click)="multiRefreshViewCounts()" style="color: #27ae60;" mat-icon-button #tooltip="matTooltip" matTooltip="{{ 'Update the number of views for selected posts' | translate }}" [matTooltipPosition]="'above'">
                <mat-icon [ngClass]="{'rotateble': isRefreshed}">refresh</mat-icon>
                </button>
                <button class="ml-1" type="button" style="background: green;" (click)="setMultyAutoUpdate()">
                {{ "Set auto update" | translate }}
                </button> -->
            </div>
        </ng-container>
        <div class="head_split">|</div>
        <ng-container *ngIf="data?.company?.permissions?.includes('admin') || data?.company?.permissions?.includes('owner')">
            <div>{{"Total stream viewers" | translate}} {{viewersCount}}</div>
            <div class="head_split">|</div>
        </ng-container>
        <input id="viewers" name="viewers" class="ve_input reports_white_input" style="max-width: 40px; text-align: right;" type="number" [formControl]="viewersCtrl" matInput placeholder="{{ 'Viewers' | translate }}">
        <label class="viewers_label" for="viewers">{{"Viewers" | translate}}</label>
        <div class="cp symbol" (click)="toggleSymbol()">
            <ng-container *ngIf="symbolCtrl.value == '>'; else elseTemplateSymbol">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M6.5,2.27L20,10.14L6.5,18L5.5,16.27L16.03,10.14L5.5,4L6.5,2.27M20,20V22H5V20H20Z" /></svg>
            </ng-container>
            <ng-template #elseTemplateSymbol>
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M18.5,2.27L5,10.14L18.5,18L19.5,16.27L8.97,10.14L19.5,4L18.5,2.27M5,20V22H20V20H5Z" /></svg>
            </ng-template>
        </div>
    </span>
    <div class="autos" [appDeviceClass]="'custom-scroll custom-scroll-t'">

        <!-- <div class="d-f-c" style="justify-content: space-between;">
            <mat-form-field appearance="legacy" class="white_legacy_select" *ngIf="parameters" style="color: #fff;">
                <mat-label>{{ "Tag category" | translate }}</mat-label>
                <mat-select [disableOptionCentering]="true" [multiple]="false" selectDir [(ngModel)]="parameter_id" (selectionChange)="onParamChange($event)">
                  <mat-option>
                    <ngx-mat-select-search 
                        placeholderLabel="{{ 'Search' | translate }}" 
                        noEntriesFoundLabel="{{ 'no matching tag found' | translate }}"
                        [formControl]="parametersCtrl"></ngx-mat-select-search>
                  </mat-option>
                  <mat-option *ngFor="let parameter of parameters$ | async" [value]="parameter.id">
                    {{parameter.name}}
                  </mat-option>
                </mat-select>
            </mat-form-field>

            <div class="d-f-c" style="margin-left: 40px;">
                <mat-form-field appearance="legacy" class="white_legacy_select" *ngIf="checklists" style="color: #fff;">
                    <mat-label>{{ "Check List" | translate }}</mat-label>
                    <mat-select [disableOptionCentering]="true" [multiple]="false" selectDir [(ngModel)]="operation_reminder_id" (selectionChange)="onRemChange($event)">
                      <mat-option>
                        <ngx-mat-select-search 
                            placeholderLabel="{{ 'Search' | translate }}" 
                            noEntriesFoundLabel="{{ 'no matching checklists found' | translate }}"
                            [formControl]="checklistsCtrl"></ngx-mat-select-search>
                      </mat-option>
                      <mat-option *ngFor="let check of checklists$ | async" [value]="check.id">
                        {{check.text}}
                      </mat-option>
                    </mat-select>
                </mat-form-field>
    
                <mat-form-field appearance="legacy" class="white_legacy_select ml-1" *ngIf="parameters" style="color: #fff;">
                    <mat-label>{{ "Inherit tags from categories" | translate }}</mat-label>
                    <mat-select [disableOptionCentering]="true" [multiple]="true" selectDir [(ngModel)]="nestedTags">
                      <mat-option>
                        <ngx-mat-select-search 
                            placeholderLabel="{{ 'Search' | translate }}" 
                            noEntriesFoundLabel="{{ 'no matching tag found' | translate }}"
                            [formControl]="parametersCtrl"></ngx-mat-select-search>
                      </mat-option>
                      <mat-option *ngFor="let parameter of parameters$ | async" [value]="parameter.id" [disabled]="parameter.id == parameter_id" (selectionChange)="onNestedChange($event)">
                        {{parameter.name}}
                      </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div> -->

        <ng-container *ngIf="urls">
            <div class="items">      
                <!-- <div class="items_line items_line_head">
                    <div class="item check">
                        <div class="item_head">
                            <div class="check_item" (click)="toggleCol()">
                                <ng-container *ngIf="isAllChecked(); else elseTemplateNoInclTmlp">
                                    <mat-icon>check_box</mat-icon>
                                </ng-container>
                                <ng-template #elseTemplateNoInclTmlp>
                                    <mat-icon>check_box_outline_blank</mat-icon>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                    <div class="item post_item">
                        <div class="item_head">
                            <div class="item_photo">
                                <mat-icon>live_tv</mat-icon>
                            </div>
                            <div class="d-f-c">
                                <div class="name text_one_line">{{"Live Stream" | translate}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="item_head">
                            <div class="item_photo">
                                <mat-icon>movie</mat-icon>
                            </div>
                            <div>
                                <div class="name text_one_line">{{"Files" | translate}}</div>
                            </div>
                        </div>
                    </div>
                </div>    -->
                <ng-container *ngFor="let channelData of urlsByChannel$ | async; let i = index">
                    <div class="items_line">
                        <!-- <div class="item check">
                            <div class="check_item" (click)="url.checked = !url.checked">
                                <ng-container *ngIf="!!url?.checked; else elseTemplateNoIncl">
                                    <mat-icon>check_box</mat-icon>
                                </ng-container>
                                <ng-template #elseTemplateNoIncl>
                                    <mat-icon>check_box_outline_blank</mat-icon>
                                </ng-template>
                            </div>
                        </div> -->
                        <div class="item server_item">
                            <div class="item_top" style="align-items: center;">
                                <app-outlet-line [channel]="channelData.channel" [company_id]="data?.company?.id"></app-outlet-line>
                                <div class="d-f-c">
                                    <div class="copy_btn" ngxClipboard [cbContent]="channelDataIds(channelData)" (cbOnSuccess)="copyLink(channelData.channel.name + ' ids')">
                                        <mat-icon>content_copy</mat-icon>
                                    </div>
                                    <div class="d-f-c ml-5" style="flex-direction: column;">
                                        <div class="view_count" *ngIf="!!channelData?.urls" style="margin-bottom: 2.5px;">
                                            <div class="d-f-c">
                                                <mat-icon style="color: #C9C9C9; width: 11px; height: 11px; font-size: 11px; line-height: 11px;">live_tv</mat-icon>
                                                <span style="margin-left: 3px;">{{ channelData?.urls?.length }}</span>
                                            </div>
                                        </div>
                                        <div class="view_count" *ngIf="!!channelData.content_views_count" style="margin-top: 2.5px;">
                                            <div class="d-f-c">
                                                <svg style="width: 9px; height: 10px" width="9" height="10" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M0 10V3H2V10H0ZM3.5 10V0H5.5V10H3.5ZM7 10V6H9V10H7Z" fill="#C9C9C9"/>
                                                </svg>
                                                <span style="margin-left: 1px;">{{ channelData.content_views_count | shortNumber }}</span>
                                            </div>
                                        </div>
                                        <div class="view_count" *ngIf="!!channelData.content_concurrent_views_count" style="margin-top: 2.5px;">
                                            <div class="d-f-c">
                                                <mat-icon style="width: 10px; height: 10px; font-size: 10px; line-height: 10px; color: #C9C9C9;">person</mat-icon>
                                                <span style="margin-left: 1px;">{{ channelData.content_concurrent_views_count | shortNumber }}</span>
                                            </div>
                                        </div>
                                        <div class="view_count" *ngIf="!!channelData?.content_duration">
                                            <div class="d-f-c chat_timestamp">
                                                <mat-icon style="width: 10px; height: 10px; font-size: 10px; line-height: 10px; color: #C9C9C9; margin-right: 1px;">schedule</mat-icon>
                                                <span class="silver">{{(channelData?.content_duration | fullTime:false:true:true).silverTime}}</span>
                                                <span class="black">{{(channelData?.content_duration | fullTime:false:true:true).blackTime}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="item post_item" *ngFor="let el of channelData.urls; let j = index;" [ngClass]="{'cp': !!el.content_url, 'selected': liveCollection.isSelected(el), 'no_external_item': !el.external_content_id}" (click)="goToPost($event, el)" (contextmenu)="openPostContext($event, el, i, j, channelData); $event.preventDefault(); $event.stopPropagation();">
                            <div class="item_top">
                                <div class="item_photo">
                                    <img *ngIf="el?.content_image" [src]="el?.content_image" alt="">
                                </div>
                                <div class="item_name text_3_line">
                                    {{el?.content_name}}
                                </div>
                            </div>
                            <div class="item_bottom">
                                <div class="d-f-c">
                                    <div class="status">
                                        {{ (getProfileStatusById(el.content_status_id) ? (getProfileStatusById(el.content_status_id)?.name+'_profile') : 'Content status') | translate }}
                                    </div>
                                    <ng-container *ngIf="el?.content_published_at > today && el?.content_published_at < todayEnd; else publishedAtTempl">
                                        <span class="date" #tooltip="matTooltip"
                                          matTooltip="{{ el?.content_published_at*1000 | date:'MMM d, y':'':data.activeLang | yearPipe }}"
                                          [matTooltipPosition]="'above'">
                                          {{ el?.content_published_at*1000 | date:'hh:mm a':'':data.activeLang }}
                                        </span>
                                    </ng-container>
                                    <ng-template #publishedAtTempl>
                                        <span class="date" *ngIf="el?.content_published_at && el?.content_published_at > 30000" #tooltip="matTooltip"
                                          matTooltip="{{ el?.content_published_at*1000 | date:'hh:mm a':'':data.activeLang | yearPipe }}"
                                          [matTooltipPosition]="'above'">
                                          {{ el?.content_published_at*1000 | date:'MM.dd.yy':'': data.activeLang }}
                                        </span>
                                    </ng-template>
                                </div>

                                <div class="d-f-c">

                                    <ng-container *ngIf="el.backup_id && el.backupOriginal; else elseTemplateBckp">
                                        <mat-icon class="bckp_icon" (click)="openPostBckp($event, el, i, j, server, 'backup'); $event.preventDefault(); $event.stopPropagation();">backup</mat-icon>
                                    </ng-container>
                                    <ng-template #elseTemplateBckp>
                                        <mat-icon *ngIf="!!el?.backups" class="bckp_icon" (click)="openPostBckp($event, el, i, j, server, 'original'); $event.preventDefault(); $event.stopPropagation();">cloud_circle</mat-icon>
                                    </ng-template>

                                    <div class="id cp" (click)="$event.preventDefault(); $event.stopPropagation();" ngxClipboard [cbContent]="el.id" (cbOnSuccess)="copyLiveData('id')">
                                        {{el.id}}
                                    </div>
                                    <div class="view_count cp ml-5" *ngIf="!!el.content_views_count" (click)="$event.preventDefault(); $event.stopPropagation(); showStats(el)">
                                        <div class="d-f-c">
                                            <svg style="width: 9px; height: 10px" width="9" height="10" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0 10V3H2V10H0ZM3.5 10V0H5.5V10H3.5ZM7 10V6H9V10H7Z" fill="#C9C9C9"/>
                                            </svg>
                                            <span style="margin-left: 1px;">{{ el.content_views_count | shortNumber }}</span>
                                        </div>
                                    </div>
                                    <div class="view_count ml-5" *ngIf="!!el.content_concurrent_views_count">
                                        <div class="d-f-c">
                                            <mat-icon style="width: 10px; height: 10px; font-size: 10px; line-height: 10px; color: #C9C9C9;">person</mat-icon>
                                            <span style="margin-left: 1px;">{{ el.content_concurrent_views_count | shortNumber }}</span>
                                        </div>
                                    </div>
                                    <div class="view_count ml-5" *ngIf="!!el?.content_duration">
                                        <div class="d-f-c chat_timestamp">
                                            <mat-icon style="width: 10px; height: 10px; font-size: 10px; line-height: 10px; color: #C9C9C9; margin-right: 1px;">schedule</mat-icon>
                                            <span class="silver">{{(el?.content_duration | fullTime:false:true:true).silverTime}}</span>
                                            <span class="black">{{(el?.content_duration | fullTime:false:true:true).blackTime}}</span>
                                        </div>
                                      </div>
                                </div>
                            </div>
                            <!-- <div class="item_el">
                                <div class="item_photo">
                                    <img *ngIf="el?.content_image" [src]="el?.content_image" alt="">
                                </div>
                                <div class="name text_one_line">{{el?.content_name}}</div>
                                <div class="profile_edit mobActiveFilter" type="button" mat-icon-button [matMenuTriggerFor]="profileDots">
                                    <mat-icon style="color: #A3A3A3;">more_vert</mat-icon>
                                </div>
           
                                <mat-menu #profileDots="matMenu" class="sort_tablet" xPosition='before' overlayPanelClass='sortTAB'>
                                    <button class="flex_start" mat-menu-item (click)="editProfile(el.task, el)">
                                        <mat-icon>edit</mat-icon> 
                                        <span>{{ "Edit" | translate }}</span>
                                    </button>
                                    <button class="flex_start" mat-menu-item *ngIf="el.content_type_id == '106' && ![14,6].includes(el.content_status_id)" (click)="startLive(el.task, el)">
                                      <mat-icon>cast</mat-icon> 
                                      <span>{{ "Start Live" | translate }}</span>
                                    </button>
                                    <button class="flex_start" mat-menu-item *ngIf="el.content_type_id == '106' && [14,6].includes(el.content_status_id)" (click)="stopLive(el.task, el)">
                                      <mat-icon>cancel_presentation</mat-icon> 
                                      <span>{{ "Stop stream" | translate }}</span>
                                    </button>
                                    <button class="flex_start" mat-menu-item (click)="deleteProfile(el.task, el, j, channelData)">
                                        <mat-icon>delete</mat-icon> 
                                        <span>{{ "Delete" | translate }}</span>
                                    </button>
                                </mat-menu>
                            </div> -->
                            <!-- <div class="item_el" style="justify-content: space-between;">
                                <div class="cut_value">
                                    <div>
                                        {{ (getProfileStatusById(el.content_status_id) ? (getProfileStatusById(el.content_status_id)?.name+'_profile') : 'Content status') | translate }}
                                    </div>
                                </div>
                                <div class="d-f-c">
                                    <a *ngIf="el.content_url" [href]="el.content_url" target="_blank"><mat-icon style="width:18px; height:18px; font-size:18px; line-height: 18px; color: #fff;">open_in_new</mat-icon></a> 
                                    <div class="view_count ml-5 footer_item cp">
                                        <div class="d-f-c">
                                            ID:
                                            <span style="margin-left: 1px;">{{el.id}}</span>
                                        </div>
                                    </div>
                                    <div class="view_count ml-5 footer_item cp" *ngIf="!!el.content_views_count" (click)="showStats(el)">
                                        <div class="d-f-c">
                                            <svg style="width: 9px; height: 10px" width="9" height="10" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0 10V3H2V10H0ZM3.5 10V0H5.5V10H3.5ZM7 10V6H9V10H7Z" fill="#C9C9C9"/>
                                            </svg>
                                            <span style="margin-left: 1px;">{{ el.content_views_count | shortNumber }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </ng-container>            
                <div class="loading" *ngIf="isGettingFiles || isRealGettingFiles">
                    <mat-spinner></mat-spinner>
                </div>
            </div>
        </ng-container>
        
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <div class="bottom_btns">
        <div class="d-f-c">
            <!-- <button class="mr-1 d-f-btn" style="color: #fff !important;" *ngIf="hasChecked()" (click)="openFilesSettings()" mat-button [disabled]="isGettingFiles">
                <mat-spinner class="spin_16 mr-1" *ngIf="isGettingFiles"></mat-spinner>  
                {{ "Get files" | translate }}
            </button> -->
        </div>
        <div class="d-f-c">
            <!-- <div class="mr-1 loading" *ngIf="isRealGettingFiles">
                <mat-spinner class="spin_16 mr-1"></mat-spinner> 
                {{ "Loading live files" | translate }}...
            </div>
    
            <mat-form-field appearance="legacy" class="white_legacy_select full_width_form mr-1" style="color: #fff;">
                <mat-label>{{ "Content status for all" | translate }}</mat-label>
                <mat-select disableOptionCentering [formControl]="status_id">
                    <mat-option [value]="item.id" *ngFor="let item of profileStatuses" [disabled]="!item?.is_user">
                        {{ item.name+'_profile' | translate }}
                    </mat-option>
                </mat-select>
            </mat-form-field> -->

            <button mat-raised-button (click)="close()">
                {{ "Close" | translate }}
            </button>

            <!-- <button class="ml-1 d-f-btn" (click)="submitForm2()" mat-raised-button color="primary" [disabled]="isSubmit">
                <mat-spinner class="spin_16 mr-1" *ngIf="isSubmit"></mat-spinner>  
                {{ "Save" | translate }}
            </button> -->
        </div>
    </div>
</mat-dialog-actions>


<ng-template #postContext let-contextData>
    <section class="card-menu">
      <div (click)="closePostContext(); editProfile(contextData.el.task, contextData.el)">
        <mat-icon>edit</mat-icon> 
        <span>{{ "Edit" | translate }}</span>
      </div>
      <!-- <div (click)="closePostContext(); startLive(contextData.el.task, contextData.el)" *ngIf="!!contextData.el.external_content_id && contextData.el.content_type_id == '106' && ![14,6].includes(contextData.el.content_status_id)">
        <mat-icon>cast</mat-icon> 
        <span>{{ "Start Live" | translate }}</span>
      </div> -->
      <div (click)="closePostContext(); stopLive(contextData.el.task, contextData.el)" *ngIf="contextData.el.content_type_id == '106' && [14,6].includes(contextData.el.content_status_id)" #tooltip="matTooltip"
      matTooltip="{{ '4' | translate }}"
      [matTooltipPosition]="'above'">
        <mat-icon>cancel_presentation</mat-icon> 
        <span>{{ "Stop stream" | translate }}</span>
      </div>
      <div (click)="closePostContext(); deleteProfile(contextData.el.task, contextData.el, contextData.j, contextData.channelData)">
        <mat-icon>delete</mat-icon>
        <span>{{ 'Delete' | translate }}</span>
      </div>
    </section>
</ng-template>

<ng-template #postBckpContext let-contextData>
    <section class="bckp-menu">

        <ng-container *ngIf="contextData.type == 'backup'; else elseTemplateBckpOriginal">
            <!--  (contextmenu)="openPostContext($event, el, i, j, server); $event.preventDefault(); $event.stopPropagation();" -->
            <div class="item post_item" [ngClass]="{'cp': !!contextData?.el?.content_url}" (click)="goToPost($event, contextData?.el)">
                <div class="item_top">
                    <div class="item_photo">
                        <img *ngIf="contextData?.el?.content_image" [src]="contextData?.el?.content_image" alt="">
                    </div>
                    <div class="item_name text_3_line">
                        {{contextData?.el?.content_name}}
                    </div>
                </div>
                <div class="item_bottom">
                    <div class="d-f-c">
                        <div class="status">
                            {{ (getProfileStatusById(contextData?.el.content_status_id) ? (getProfileStatusById(contextData?.el.content_status_id)?.name+'_profile') : 'Content status') | translate }}
                        </div>
                        <ng-container *ngIf="contextData?.el?.content_published_at > today && contextData?.el?.content_published_at < todayEnd; else publishedAtTempl">
                            <span class="date" #tooltip="matTooltip"
                              matTooltip="{{ contextData?.el?.content_published_at*1000 | date:'MMM d, y':'':data.activeLang | yearPipe }}"
                              [matTooltipPosition]="'above'">
                              {{ contextData?.el?.content_published_at*1000 | date:'hh:mm a':'':data.activeLang }}
                            </span>
                        </ng-container>
                        <ng-template #publishedAtTempl>
                            <span class="date" *ngIf="contextData?.el?.content_published_at && contextData?.el?.content_published_at > 30000" #tooltip="matTooltip"
                              matTooltip="{{ contextData?.el?.content_published_at*1000 | date:'hh:mm a':'':data.activeLang | yearPipe }}"
                              [matTooltipPosition]="'above'">
                              {{ contextData?.el?.content_published_at*1000 | date:'MM.dd.yy':'': data.activeLang }}
                            </span>
                        </ng-template>
                    </div>
    
                    <div class="d-f-c">
                        <div class="id cp" (click)="$event.preventDefault(); $event.stopPropagation();" ngxClipboard [cbContent]="contextData?.el.id" (cbOnSuccess)="copyLiveData('id')">
                            {{contextData?.el.id}}
                        </div>
                        <div class="view_count cp ml-5" *ngIf="!!contextData?.el.content_views_count" (click)="$event.preventDefault(); $event.stopPropagation(); showStats(contextData?.el)">
                            <div class="d-f-c">
                                <svg style="width: 9px; height: 10px" width="9" height="10" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 10V3H2V10H0ZM3.5 10V0H5.5V10H3.5ZM7 10V6H9V10H7Z" fill="#C9C9C9"/>
                                </svg>
                                <span style="margin-left: 1px;">{{ contextData?.el.content_views_count | shortNumber }}</span>
                            </div>
                        </div>
                        <div class="view_count ml-5" *ngIf="!!contextData?.el.content_concurrent_views_count">
                            <div class="d-f-c">
                                <mat-icon style="width: 10px; height: 10px; font-size: 10px; line-height: 10px; color: #C9C9C9;">person</mat-icon>
                                <span style="margin-left: 1px;">{{ contextData?.el.content_concurrent_views_count | shortNumber }}</span>
                            </div>
                        </div>
                        <div class="view_count ml-5" *ngIf="!!contextData?.el?.content_duration">
                            <div class="d-f-c chat_timestamp">
                                <mat-icon style="width: 10px; height: 10px; font-size: 10px; line-height: 10px; color: #C9C9C9; margin-right: 1px;">schedule</mat-icon>
                                <span class="silver">{{(contextData?.el?.content_duration | fullTime:false:true:true).silverTime}}</span>
                                <span class="black">{{(contextData?.el?.content_duration | fullTime:false:true:true).blackTime}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-f-c" style="justify-content: space-between;">
                    <app-outlet-line [channel]="contextData?.el.channel" [company_id]="data?.company?.id"></app-outlet-line>
                </div>
                <div style="font-size: 8px;" *ngIf="!!data?.user?.is_root">{{contextData?.el?.server_id}}</div>
            </div>
        </ng-container>
        <ng-template #elseTemplateBckpOriginal>      
            <div class="item post_item" *ngFor="let el of contextData.el.backups; let j = index;" [ngClass]="{'cp': !!el.content_url}" (click)="goToPost($event, el)">
                <div class="item_top">
                    <div class="item_photo">
                        <img *ngIf="el?.content_image" [src]="el?.content_image" alt="">
                    </div>
                    <div class="item_name text_3_line">
                        {{el?.content_name}}
                    </div>
                </div>
                <div class="item_bottom">
                    <div class="d-f-c">
                        <div class="status">
                            {{ (getProfileStatusById(el.content_status_id) ? (getProfileStatusById(el.content_status_id)?.name+'_profile') : 'Content status') | translate }}
                        </div>
                        <ng-container *ngIf="el?.content_published_at > today && el?.content_published_at < todayEnd; else publishedAtTempl">
                            <span class="date" #tooltip="matTooltip"
                              matTooltip="{{ el?.content_published_at*1000 | date:'MMM d, y':'':data.activeLang | yearPipe }}"
                              [matTooltipPosition]="'above'">
                              {{ el?.content_published_at*1000 | date:'hh:mm a':'':data.activeLang }}
                            </span>
                        </ng-container>
                        <ng-template #publishedAtTempl>
                            <span class="date" *ngIf="el?.content_published_at && el?.content_published_at > 30000" #tooltip="matTooltip"
                              matTooltip="{{ el?.content_published_at*1000 | date:'hh:mm a':'':data.activeLang | yearPipe }}"
                              [matTooltipPosition]="'above'">
                              {{ el?.content_published_at*1000 | date:'MM.dd.yy':'': data.activeLang }}
                            </span>
                        </ng-template>
                    </div>
    
                    <div class="d-f-c">
                        <div class="id cp" (click)="$event.preventDefault(); $event.stopPropagation();" ngxClipboard [cbContent]="el.id" (cbOnSuccess)="copyLiveData('id')">
                            {{el.id}}
                        </div>
                        <div class="view_count cp ml-5" *ngIf="!!el.content_views_count" (click)="$event.preventDefault(); $event.stopPropagation(); showStats(el)">
                            <div class="d-f-c">
                                <svg style="width: 9px; height: 10px" width="9" height="10" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 10V3H2V10H0ZM3.5 10V0H5.5V10H3.5ZM7 10V6H9V10H7Z" fill="#C9C9C9"/>
                                </svg>
                                <span style="margin-left: 1px;">{{ el.content_views_count | shortNumber }}</span>
                            </div>
                        </div>
                        <div class="view_count ml-5" *ngIf="!!el.content_concurrent_views_count">
                            <div class="d-f-c">
                                <mat-icon style="width: 10px; height: 10px; font-size: 10px; line-height: 10px; color: #C9C9C9;">person</mat-icon>
                                <span style="margin-left: 1px;">{{ el.content_concurrent_views_count | shortNumber }}</span>
                            </div>
                        </div>
                        <div class="view_count ml-5" *ngIf="!!el?.content_duration">
                            <div class="d-f-c chat_timestamp">
                                <mat-icon style="width: 10px; height: 10px; font-size: 10px; line-height: 10px; color: #C9C9C9; margin-right: 1px;">schedule</mat-icon>
                                <span class="silver">{{(el?.content_duration | fullTime:false:true:true).silverTime}}</span>
                                <span class="black">{{(el?.content_duration | fullTime:false:true:true).blackTime}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-f-c" style="justify-content: space-between;">
                    <app-outlet-line [channel]="el.channel" [company_id]="data?.company?.id"></app-outlet-line>
                </div>
                <div style="font-size: 8px;" *ngIf="!!data?.user?.is_root">{{el?.server_id}}</div>
            </div>
        </ng-template>
        
    </section>
</ng-template>