<h2 mat-dialog-title class="dialog_title">
    <span>
        {{ data.chat.name }} {{ "connected chats" | translate }}:
    </span>
    <button mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h2>
<mat-dialog-content class="mat-typography">
    <div class="container form_wrapper">
        <div class="connections" *ngIf="data.chat?.consistOfDiscussionPartition.length > 0 || data.chat?.partOfDiscussionPartition.length > 0 || data.chat?.discussionRelated.length > 0">
            <div class="cons_wrp" *ngIf="data.chat?.partOfDiscussionPartition.length > 0">
                <div class="cons_in">
                    <div class="name">{{ "Part of" | translate }}</div>
                    <a class="con" [href]="host + '/task/' + part?.acm" *ngFor="let part of data.chat?.partOfDiscussionPartition" (click)="$event.preventDefault(); $event.stopPropagation(); openChat(part?.consistOfDiscussion)">
                        <div class="con_in" [ngClass]="{'full': part?.previewImgs?.length == 0 && (!part?.avatarFile || !part?.avatarFile?.thumbnail)}">
                            <div class="d-f-c con_side" [ngClass]="{'full': !(!!part.group_id && part.group_id != data.chat?.group_id && !!getGroup(part.group_id))}">
                                <div class="con_name text_one_line">
                                    {{part?.consistOfDiscussion?.name}}
                                </div>
                            </div>
        <!-- 
                            <div class="con_group text_one_line" *ngIf="!!part.group_id && part.group_id != data.chat?.group_id && !!getGroup(part.group_id)">
                                {{ getGroup(part.group_id).name }}
                            </div> -->
                        </div>
                        <!-- <div class="con_preview_wrp" *ngIf="part?.previewImgs?.length || (part?.avatarFile && part?.avatarFile?.thumbnail)">
                            <swiper [pagination]="false" [navigation]="false">
                                <ng-container *ngIf="part?.previewImgs?.length > 0 && part?.custom_avatar_property == ''; else elseTemplateHasPreview">
                                  <ng-container *ngFor="let preview of part?.previewImgs">
                                    <ng-template swiperSlide>
                                      <div class="preview_img" [ngStyle]="{'background': 'url('+ (!preview.is_parser_url ? host : '') + preview.src +') no-repeat center / cover'}"></div>
                                    </ng-template>
                                  </ng-container>
                                </ng-container>
                                <ng-template #elseTemplateHasPreview>
                                  <ng-template swiperSlide>
                                    <div class="preview_img" [ngStyle]="{'background': 'url('+ host + part?.avatarFile?.thumbnail + '?company_id=' + company?.id +') no-repeat center / cover'}"></div>
                                  </ng-template>
                                </ng-template>
                            </swiper>
                        </div> -->
                    </a>
                </div>
            </div>
            <div class="cons_wrp" *ngIf="data.chat?.consistOfDiscussionPartition.length > 0">
                <div class="cons_in">
                    <div class="name">{{ "Consist of" | translate }}</div>
                    <a class="con small_con" [href]="host + '/task/' + part?.acm" *ngFor="let part of data.chat?.consistOfDiscussionPartition" (click)="$event.preventDefault(); $event.stopPropagation(); openChat(part?.partOfDiscussion)">
                        <div class="con_in" [ngClass]="{'full': part?.previewImgs?.length == 0 && (!part?.avatarFile || !part?.avatarFile?.thumbnail)}">
                            <div class="d-f-c con_side" [ngClass]="{'full': !(!!part.group_id && part.group_id != data.chat?.group_id && !!getGroup(part.group_id))}">
                                <div class="con_name text_one_line">
                                    {{part?.partOfDiscussion?.name}}
                                </div>
                            </div>
                            
                            <!-- <div class="con_group" *ngIf="!!part.group_id && part.group_id != data.chat?.group_id && !!getGroup(part.group_id)">
                                {{ getGroup(part.group_id).name }}
                            </div> -->
                        </div>
                        <!-- <div class="con_preview_wrp small_prev" *ngIf="part?.previewImgs?.length || (part?.avatarFile && part?.avatarFile?.thumbnail)">
                            <swiper [pagination]="false" [navigation]="false">
                                <ng-container *ngIf="part?.previewImgs?.length > 0 && part?.custom_avatar_property == ''; else elseTemplateHasPreview">
                                  <ng-container *ngFor="let preview of part?.previewImgs">
                                    <ng-template swiperSlide>
                                      <div class="preview_img" [ngStyle]="{'background': 'url('+ (!preview.is_parser_url ? host : '') + preview.src +') no-repeat center / cover'}"></div>
                                    </ng-template>
                                  </ng-container>
                                </ng-container>
                                <ng-template #elseTemplateHasPreview>
                                  <ng-template swiperSlide>
                                    <div class="preview_img" [ngStyle]="{'background': 'url('+ host + part?.avatarFile?.thumbnail + '?company_id=' + company?.id +') no-repeat center / cover'}"></div>
                                  </ng-template>
                                </ng-template>
                            </swiper>
                        </div> -->
                    </a>
                </div>
            </div>
            <div class="cons_wrp" *ngIf="data.chat?.discussionRelated.length > 0">
                <div class="cons_in">
                    <div class="name">{{ "Relations" | translate }}</div>
                    <a class="con small_con" [href]="host + '/task/' + part?.acm" *ngFor="let part of data.chat?.discussionRelated" (click)="$event.preventDefault(); $event.stopPropagation(); openChat(part?.relatedDiscussion)">
                        <div class="con_in" [ngClass]="{'full': part?.previewImgs?.length == 0 && (!part?.avatarFile || !part?.avatarFile?.thumbnail)}">
                            <div class="d-f-c con_side" [ngClass]="{'full': !(!!part.group_id && part.group_id != data.chat?.group_id && !!getGroup(part.group_id))}">
                                <div class="con_name text_one_line">
                                    {{part?.relatedDiscussion?.name}}
                                </div>
                            </div>
                            <!-- <div class="con_group" *ngIf="!!part.group_id && part.group_id != data.chat?.group_id && !!getGroup(part.group_id)">
                                {{ getGroup(part.group_id).name }}
                            </div> -->
                        </div>
                        <!-- <div class="con_preview_wrp small_prev" *ngIf="part?.previewImgs?.length || (part?.avatarFile && part?.avatarFile?.thumbnail)">
                            <swiper [pagination]="false" [navigation]="false">
                                <ng-container *ngIf="part?.previewImgs?.length > 0 && part?.custom_avatar_property == ''; else elseTemplateHasPreview">
                                  <ng-container *ngFor="let preview of part?.previewImgs">
                                    <ng-template swiperSlide>
                                      <div class="preview_img" [ngStyle]="{'background': 'url('+ (!preview.is_parser_url ? host : '') + preview.src +') no-repeat center / cover'}"></div>
                                    </ng-template>
                                  </ng-container>
                                </ng-container>
                                <ng-template #elseTemplateHasPreview>
                                  <ng-template swiperSlide>
                                    <div class="preview_img" [ngStyle]="{'background': 'url('+ host + part?.avatarFile?.thumbnail + '?company_id=' + company?.id +') no-repeat center / cover'}"></div>
                                  </ng-template>
                                </ng-template>
                            </swiper>
                        </div> -->
                    </a>
                </div>
            </div>
            <div class="con_icon">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18 16.08C17.24 16.08 16.56 16.38 16.04 16.85L8.91 12.7C8.96 12.47 9 12.24 9 12C9 11.76 8.96 11.53 8.91 11.3L15.96 7.19C16.5 7.69 17.21 8 18 8C19.66 8 21 6.66 21 5C21 3.34 19.66 2 18 2C16.34 2 15 3.34 15 5C15 5.24 15.04 5.47 15.09 5.7L8.04 9.81C7.5 9.31 6.79 9 6 9C4.34 9 3 10.34 3 12C3 13.66 4.34 15 6 15C6.79 15 7.5 14.69 8.04 14.19L15.16 18.35C15.11 18.56 15.08 18.78 15.08 19C15.08 20.61 16.39 21.92 18 21.92C19.61 21.92 20.92 20.61 20.92 19C20.92 17.39 19.61 16.08 18 16.08Z" fill="#219653"/>
                </svg>
            </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button (click)="close()">
        {{ "Close" | translate }}
    </button>
    <button class="ml-1" (click)="editConnections()" mat-raised-button cdkFocusInitial color="primary">
        {{ "Edit" | translate }}
    </button>
</mat-dialog-actions>

