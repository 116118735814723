<mat-dialog-content class="mat-typography">
    <div class="container form_wrapper" [appDeviceClass]="'custom-scroll custom-scroll-t'" style="min-height: 650px;">
        <div class="title_modal" (click)="log()">
            <ng-container *ngIf="!!data.tmpl; else elseTemplateAdd">
                {{ "Edit Auto Tag" | translate }}
            </ng-container>
            <ng-template #elseTemplateAdd>
                {{ "Add Auto Tag" | translate }}
            </ng-template>
        </div>
        <form [formGroup]="form" class="form_with_pads form_with_valids">

            <mat-form-field appearance="standard" class="full_width_form" *ngIf="!data.tmpl">
                <mat-label>{{ "Auto Tag" | translate }}</mat-label>
                <mat-select [disableOptionCentering]="true" selectDir [multiple]="false" formControlName="parameter_value_id">
                    <mat-select-trigger class="multi_users">
                        <ng-container *ngIf="form.get('parameter_value_id').value">
                                <span>{{ getValuesById(form.get('parameter_value_id').value)?.value }}</span>
                        </ng-container>
                    </mat-select-trigger>
                    <mat-option>
                        <ngx-mat-select-search 
                        placeholderLabel="{{ 'Search' | translate }}" 
                        noEntriesFoundLabel="{{ 'no matching tag found' | translate }}"
                        [formControl]="allValuesControl"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let item of allValues$ | async" [value]="item.id">
                        <div class="d-f-c" style="justify-content: space-between;">
                            <span>{{ item.value }}</span>
                            <span>{{ item?.parameter?.name }}</span>
                        </div>
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <div class="box_wrp">
                <mat-checkbox color="primary" formControlName="is_primary">{{ "Is primary?" | translate }}</mat-checkbox>
            </div>

            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ "Card type" | translate }}</mat-label>
                <mat-select [disableOptionCentering]="true" selectDir [multiple]="false" formControlName="task_status_id">
                    <mat-option>
                        <ngx-mat-select-search 
                        placeholderLabel="{{ 'Search' | translate }}" 
                        noEntriesFoundLabel="{{ 'no matching card type found' | translate }}"
                        [formControl]="taskStatusesControl"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let item of taskStatuses" [value]="item.id">
                        {{ item.name | translate }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ "Card group" | translate }}</mat-label>
                <mat-select [disableOptionCentering]="true" selectDir [multiple]="false" formControlName="task_group_id">
                    <mat-option>
                        <ngx-mat-select-search 
                        placeholderLabel="{{ 'Search' | translate }}" 
                        noEntriesFoundLabel="{{ 'no matching group found' | translate }}"
                        [formControl]="groupsControl"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let group of groups" [value]="group.id">
                        {{ group.name | translate }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ "Card tag" | translate }}</mat-label>
                <mat-select [disableOptionCentering]="true" selectDir [multiple]="false" formControlName="task_parameter_value_id">
                    <mat-select-trigger class="multi_users">
                        <ng-container *ngIf="form.get('task_parameter_value_id').value">
                                <span>{{ getValuesById(form.get('task_parameter_value_id').value)?.value }}</span>
                        </ng-container>
                    </mat-select-trigger>
                    <mat-option>
                        <ngx-mat-select-search 
                        placeholderLabel="{{ 'Search' | translate }}" 
                        noEntriesFoundLabel="{{ 'no matching tag found' | translate }}"
                        [formControl]="allValuesControl"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let item of allValues$ | async" [value]="item.id">
                        <div class="d-f-c" style="justify-content: space-between;">
                            <span>{{ item.value }}</span>
                            <span>{{ item?.parameter?.name }}</span>
                        </div>
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <div class="small_chips" *ngIf="operations?.length > 0">
                <label>{{ "Job type" | translate }}: </label>
                <mat-chip-list [multiple]="false" selectable formControlName="operation_id" *ngIf="!jobTypeSwitch">
                    <ng-container *ngFor="let operation of operations; let i = index">
                        <mat-chip class="small_chip" (click)="toggleJobType(operation)" [value]="operation?.id">
                            {{ operation.name | translate }}
                        </mat-chip>
                        <mat-chip *ngIf="i == operations?.length - 1 && i > 6" class="small_chip more" (click)="operationIdContainerNew.open()">
                            {{ "More..." | translate }}
                        </mat-chip>
                    </ng-container>
                </mat-chip-list>
            </div>

            <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                <mat-select [multiple]="false" #operationIdContainerNew formControlName="operation_id" (selectionChange)="toggleJobType($event)">
                    <mat-option>
                        <ngx-mat-select-search 
                        placeholderLabel="{{ 'Search' | translate }}" 
                        noEntriesFoundLabel="{{ 'no matching job type found' | translate }}"
                        [formControl]="operationMoreControl"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let operation of operations$ | async" [value]="operation.id">
                        {{ operation.name | translate }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <div class="block_w8">
                <div class="label_w8">{{"Job Executor" | translate}}</div>
                <div class="users_select">
                    <!-- <label>{{ "Individuals" | translate }}: </label> -->
                    <div class="chips_spacer" *ngIf="!!emplSwitch"></div>
                    <mat-chip-list [multiple]="false" selectable formControlName="operation_employee_id" *ngIf="!emplSwitch">
                        <ng-container *ngFor="let employee of employees; let i = index">
                            <mat-chip class="chip_user" (click)="toggleEmplChip(employee)" [value]="employee.id">
                                <app-user [class]="'user_div'" [employee]="employee"></app-user>
                            </mat-chip>
                            <mat-chip *ngIf="i == employees.length - 1 && i > 6" class="chip_user more" (click)="employeeIdContainer.open()">
                                {{ "more" | translate }}
                            </mat-chip>
                        </ng-container>
                    </mat-chip-list>
                </div>
        
                <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                    <mat-select #employeeIdContainer formControlName="operation_employee_id" [multiple]="false" (selectionChange)="toggleEmplChip($event)">
                        <mat-option>
                            <ngx-mat-select-search 
                            placeholderLabel="{{ 'Search' | translate }}" 
                            noEntriesFoundLabel="{{ 'no matching individuals found' | translate }}"
                            [formControl]="employeeMoreControl"></ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let employee of employees$ | async" [value]="employee.id">
                            <app-user [class]="'user_div'" [employee]="employee"></app-user>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ "Job tag" | translate }}</mat-label>
                <mat-select [disableOptionCentering]="true" selectDir [multiple]="false" formControlName="task_operation_parameter_value_id">
                    <mat-select-trigger class="multi_users">
                        <ng-container *ngIf="form.get('task_operation_parameter_value_id').value">
                                <span>{{ getValuesById(form.get('task_operation_parameter_value_id').value)?.value }}</span>
                        </ng-container>
                    </mat-select-trigger>
                    <mat-option>
                        <ngx-mat-select-search 
                        placeholderLabel="{{ 'Search' | translate }}" 
                        noEntriesFoundLabel="{{ 'no matching tag found' | translate }}"
                        [formControl]="allValuesControl"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let item of allValues$ | async" [value]="item.id">
                        <div class="d-f-c" style="justify-content: space-between;">
                            <span>{{ item.value }}</span>
                            <span>{{ item?.parameter?.name }}</span>
                        </div>
                    </mat-option>
                </mat-select>
            </mat-form-field>

            
            <div class="block_w8">
                <div class="label_w8">{{"Files settings" | translate}}</div>
        
                <mat-form-field appearance="standard" class="full_width_form">
                    <mat-label>{{ 'Part of the file name' | translate }}</mat-label>
                    <input formControlName="filename_expression" matInput placeholder="{{ 'Part of the file name' | translate }}">
                </mat-form-field>

                <p>
                    <mat-button-toggle-group appearance="legacy" formControlName="filename_expression_key" [value]="form.get('filename_expression_key').value">
                        <mat-button-toggle [value]="'is_start_with_filename_expression'">{{ "Starts with this part" | translate }}</mat-button-toggle>
                        <mat-button-toggle [value]="'is_end_with_filename_expression'">{{ "Ends with this part" | translate }}</mat-button-toggle>
                        <mat-button-toggle [value]="'is_has_filename_expression'">{{ "Contains part" | translate }}</mat-button-toggle>
                    </mat-button-toggle-group>
                </p>

                <mat-form-field appearance="standard" class="full_width_form">
                    <mat-label>{{ 'File extension' | translate }}</mat-label>
                    <input formControlName="file_extension" matInput placeholder="{{ 'File extension' | translate }}">
                </mat-form-field>
    
                <mat-form-field appearance="standard" class="full_width_form">
                    <mat-label>{{ "File location" | translate }}</mat-label>
                    <mat-select [disableOptionCentering]="true" selectDir [multiple]="false" formControlName="file_location">
                        <mat-option *ngFor="let item of data.folders" [value]="item.location">
                            {{ item.name | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
    
                <p>
                    <mat-button-toggle-group appearance="legacy" formControlName="is_exact_file_location" [value]="form.get('is_exact_file_location').value">
                        <mat-button-toggle [value]="1">{{ "Only from this folder" | translate }}</mat-button-toggle>
                        <mat-button-toggle [value]="0">{{ "From all subfolders" | translate }}</mat-button-toggle>
                    </mat-button-toggle-group>
                </p>
            </div>

            
        </form>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <div class="f_w_p_btns">
        <button mat-button (click)="close()">
            {{ "Close" | translate }}
        </button>
        <button class="ml-1 d-f-btn" (click)="onSubmit()" cdkFocusInitial mat-raised-button color="primary" [disabled]="isSubmit">
            <mat-spinner class="spin_16 mr-1" *ngIf="isSubmit"></mat-spinner>
            <ng-container *ngIf="!!data.tmpl; else elseTemplateAddSbmt">
                {{ "Edit" | translate }}
            </ng-container>
            <ng-template #elseTemplateAddSbmt>
                {{ "Add" | translate }}
            </ng-template>
        </button>
    </div>
</mat-dialog-actions>