import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { concat } from 'rxjs';
import { tap } from 'rxjs/operators';
import { BaseClass } from 'src/app/shared/models/base-class';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { LoadingService } from 'src/app/shared/services/rest/loading.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';

@Component({
  selector: 'app-multi-edit-profiles-status',
  templateUrl: './multi-edit-profiles-status.component.html',
  styleUrls: ['./multi-edit-profiles-status.component.scss']
})
export class MultiEditProfilesStatusComponent extends BaseClass implements OnInit, OnDestroy {
  public form: FormGroup;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<MultiEditProfilesStatusComponent>,
    private ls: LoadingService,
    private fb: FormBuilder,
    private sm: StorageManagerService,
    private taskService: TaskService
  ) { super() }

  ngOnInit(): void {
    console.log("MultiEditProfilesStatusComponent", this.data)
    this.form = this.fb.group({
      content_status_id: ['', Validators.required]
    })
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  onSubmit() {

    let sbmtData = [];
    let partsSbmtData = [];

    this.data.collection.forEach(item => {
      sbmtData.push({
        "path": `/api/task-channel/${item.id}/`,
        "query": {company_id: this.data.company.id},
        "method": "POST",
        "body": Object.assign({
          [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token')
      }, this.form.value) 
      })
    });
  
    for (let i = 0; i < Math.ceil(sbmtData.length/5); i++) {
      partsSbmtData.push(sbmtData.slice(i*5, 5 + i*5))
    }
  
    let count = 1;
    concat(...partsSbmtData.map((param) => this.taskService.multiRequest(param))).subscribe({
      next: (next) => {
        console.log("next onSubmit", next);
        this.ls.requests$.next({
          value: Math.round((100 / partsSbmtData.length) * count),
          target: "Edditing selected urls status" 
        })
        count++;
      },
      complete: () => {
        console.log("complete onSubmit");
        this.dialogRef.close({event: "update", data: this.data.collection.slice()})
      },
      error: (error) => {
        console.log("error onSubmit", error)
      }
    })
    // resp => {
    //   console.log(resp);
    //   this.dialogRef.close({event: "update", data: this.data.collection.slice()})
    // }

    // let multiData = [];

    // this.data.collection.forEach(item => {
    //   multiData.push({
    //     "path": `/api/task-channel/${item.id}/`,
    //     "query": {company_id: this.data.company.id},
    //     "method": "POST",
    //     "body": Object.assign({
    //       [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token')
    //   }, this.form.value) 
    //   })
    // });

    // this.attachSubscriptions(
    //   this.taskService.multiRequest(multiData).subscribe(resp => {
    //     console.log(resp);
    //     this.dialogRef.close({event: "update", data: this.data.collection.slice()})
    //   })
    // )
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

}
