import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { ReplaySubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { prioritys } from 'src/app/shared/consts/prioritys';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';

@Component({
  selector: 'app-job-edit-type',
  templateUrl: './job-edit-type.component.html',
  styleUrls: ['./job-edit-type.component.scss']
})

export class JobEditTypeComponent extends BaseClass implements OnInit, OnDestroy {
  public form: FormGroup = this.fb.group({
    operation_id: [this.data.work.operation_id || '', Validators.required]
  })
  public operations: any;
  public operationMoreControl: FormControl = new FormControl();
  public operations$: ReplaySubject<any> = new ReplaySubject<any>(1);
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<JobEditTypeComponent>,
    private taskService: TaskService,
    private fb: FormBuilder,
  ) { super() }

  ngOnInit(): void {
    console.log("JobEditTypeComponent", this.data)
    this.getOperations();

    this.attachSubscriptions(
      this.operationMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchOperations(resp))
    )
  }

  onSearchOperations(resp) {
    if (!this.operations) {
      return;
    }

    if (!resp) {
      this.operations$.next(this.operations.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.operations$.next(
      this.operations.filter(z => (z.name.toLowerCase().indexOf(resp) > -1))
    );
  }

  getOperations() {
    this.attachSubscriptions(
      this.taskService.getOperations(this.data.company.id).subscribe(resp => {
        this.operations = resp;
        this.operations$.next(this.operations.slice())
      })
    )
  }

  selectType(status) {
    this.form.patchValue({
      operation_id: status.id
    })
  }

  close(type?) {
    this.dialogRef.removePanelClass('animate__slideInUp')
    this.dialogRef.addPanelClass('animate__slideOutDown')
    setTimeout(()=>{this.dialogRef.close({event: 'update', data: type})}, 300);
  }

  back() {
    this.dialogRef.removePanelClass('animate__slideInUp')
    this.dialogRef.addPanelClass('animate__slideOutDown')
    setTimeout(()=>{this.dialogRef.close({event: 'back'})}, 300);
  }

  onSubmit() {
    this.attachSubscriptions(
      this.taskService.editWork(this.data.work.id, this.form.value, this.data.company.id).subscribe(resp => {
        this.close(resp)
      })
    )
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
