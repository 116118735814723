import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';

@Component({
  selector: 'app-ai-scheme-variable',
  templateUrl: './ai-scheme-variable.component.html',
  styleUrls: ['./ai-scheme-variable.component.scss']
})
export class AiSchemeVariableComponent extends BaseClass implements OnInit, OnDestroy {
  public form: FormGroup;
  public forms: any;
  public fields: any;
  public isFormChange: boolean = false;
  public isSubmit: boolean = false;
  public isGettingFields: boolean = false;
  public variables:any = [
    {
      value: 'variable',
      name: "Document"
    },
    {
      value: '',
      name: "Card"
    }
  ]



  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<AiSchemeVariableComponent>,
    private layoutService: LayoutService,
  ) { super() }

  ngOnInit(): void {

    console.log("AiSchemeVariableComponent", this.data)

    this.form = this.fb.group({
      variable: ['', Validators.required]
    })

    this.attachSubscriptions(
      this.form.valueChanges.subscribe(() => this.isFormChange = true)
    )

    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        if (this.isFormChange) {
          this.layoutService.openBottomSheet(this.dialogRef);
        } else {
          this.close();
        }
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false});
  }

  submitForm() {
    let sbmtForm:any = {...this.form.value};
    this.dialogRef.close({event: "close", data: {
      value: 'variable',
      name: "Document"
    }});
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
