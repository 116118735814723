import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { ReplaySubject, forkJoin } from 'rxjs';
import { debounceTime, last, map, switchMap, tap } from 'rxjs/operators';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';

@Component({
  selector: 'app-task-add-client',
  templateUrl: './task-add-client.component.html',
  styleUrls: ['./task-add-client.component.scss']
})

export class TaskAddClientComponent extends BaseClass implements OnInit, OnDestroy {
  public form: FormGroup;

  public partners: any;
  public partnersControl: FormControl = new FormControl();
  public partners$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public employees: any;
  public employees$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public employeesControl: FormControl = new FormControl();

  public isSubmit: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private taskService: TaskService,
    public dialogRef: MatDialogRef<TaskAddClientComponent>,
    private fb: FormBuilder,
    private sm: StorageManagerService,
    private companyService: CompanyService,
    private layoutService: LayoutService
  ) { super() }

  ngOnInit(): void {

    console.log("TaskAddClientComponent", this.data);


    if (!this.data.is_partner) {
      this.form = this.fb.group({
        task_id: this.data.task ? this.data.task.id : 0,
        client_employee_id: ['', Validators.required],
        is_principal: 1
      })
      this.getEmployees();
    } else {
      this.form = this.fb.group({
        task_id: this.data.task ? this.data.task.id : 0,
        client_partner_company_id: ['', Validators.required],
        is_principal: 1
      })
      this.getPartners();
    }

    if (!!this.data.work) {
      this.form.addControl('task_operation_id', this.fb.control(this.data.work.id))
      this.form.get('task_operation_id').updateValueAndValidity();
    }

    if (!!this.data.chat) {
      this.form.addControl('discussion_id', this.fb.control(this.data.chat.id))
      this.form.get('discussion_id').updateValueAndValidity();
    }

    this.attachSubscriptions(
      this.partnersControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchPartners(resp))
    )

    this.attachSubscriptions(
      this.employeesControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchEmoloyees(resp))
    )
    
  }

  onSearchPartners(resp) {
    if (!this.partners) {
      return;
    }

    if (!resp) {
      this.partners$.next(this.partners.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.partners$.next(
      this.partners.filter(z => z.partnerCompany.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  onSearchEmoloyees(resp) {
    if (!this.employees) {
      return;
    }

    if (!resp) {
      this.employees$.next(this.employees.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.employees$.next(
      this.employees.filter(z => z.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  getPartners() {
    this.attachSubscriptions(
      this.companyService.getPartners({company_id: this.data.company.id}).subscribe(resp => {
        this.partners = resp.filter(x => x.partner_company_id != 0);
        this.partners$.next(this.partners.slice())
      })
    )
  }

  getEmployees() {
    this.attachSubscriptions(
      this.taskService.getEmployeesDyn('1', this.data.company.id, null, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }
  
          return forkJoin(arr.map(x => this.taskService.getEmployeesDyn(x, this.data.company.id, null, '200').pipe(map(u => u.body)))).pipe(
            last(),
            tap(values => {
              let conValues = [].concat(...values)
              this.employees = conValues;
              this.employees$.next(this.employees.slice())
            }),
          )
        }),
      ).subscribe(resp => {
        console.log("getEmployees resp", resp);
        console.log("getEmployees", this.employees);
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false});
  }

  submitForm() {
    this.isSubmit = true;

    if (this.data.selectedJobs) {
      let postData = [];
      let sbmtVal = {...this.form.value}
      delete sbmtVal.task_id;

      if (!!sbmtVal.is_principal) {
        sbmtVal.is_principal = 1
      } else {
        sbmtVal.is_principal = 0
      }

      this.data.selectedJobs.forEach(element => {
        let x = {
          [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
          task_id: element.task_id,
          task_operation_id: element.id,
        }
        if (element.company_id != this.data.company.id) {
          x.company_id = element.company_id;
          x.client_partner_company_id = this.data.company.id;
        }
        postData.push({
          "path": `/api/task-client/`,
          "query": {company_id: this.data.company.id},
          "method": "POST",
          "body": Object.assign(x, sbmtVal) 
        })
      });
      this.attachSubscriptions(
        this.taskService.multiRequest(postData).subscribe(resp => {
          console.log("multiRequest", resp)
          this.isSubmit = false;
          this.dialogRef.close({event: "change", data: resp})
        })
      )
    } else {
      let sbmtVal = {...this.form.value}
  
      if (!!sbmtVal.is_principal) {
        sbmtVal.is_principal = 1
      } else {
        sbmtVal.is_principal = 0
      }
      if (this.data.task.company_id != this.data.company.id) {
        sbmtVal.company_id = this.data.task.company_id;
        sbmtVal.client_partner_company_id = this.data.company.id;
      }
      this.attachSubscriptions(
        this.taskService.addClientToTask(sbmtVal, this.data.company.id).subscribe(resp => {
          this.isSubmit = false;
          this.dialogRef.close({event: "update", data: resp});
        }, error => {
          this.isSubmit = false;
          this.layoutService.showSnackBar({name: ''}, marker(error), SnackBarItem)
        })
      )
    }

  }


  ngOnDestroy() {
    this.clearSubscriptions()
  }

}
