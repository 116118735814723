import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { concat } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { LoadingService } from 'src/app/shared/services/rest/loading.service';
import { RefreshService } from 'src/app/shared/services/rest/refresh.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';

@Component({
  selector: 'app-url-playlists',
  templateUrl: './url-playlists.component.html',
  styleUrls: ['./url-playlists.component.scss']
})
export class UrlPlaylistsComponent extends BaseClass implements OnInit, OnDestroy {
  public form: FormGroup;
  public isSubmit: boolean = false;

  public playlists: any[] = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<UrlPlaylistsComponent>,
    private taskService: TaskService,
    private layoutService: LayoutService
  ) { super() }

  ngOnInit(): void {
    console.log("UrlPlaylistsComponent", this.data)
    this.getYtPlaylists();
  }

  onCheck(playlist) {
    playlist.isSave = !playlist.isSave;  
  }
  
  openPlaylist(playlist) {
    if (!playlist.isOpen) {
      playlist.isOpen = true;
      playlist.isLoad = true;
      this.attachSubscriptions(
        this.taskService.ytGetPlaylistItems({company_id: this.data.company.id, task_channel_id: this.data.profile.id, playlist_id: playlist.playlist_id}).pipe(
          map(x => x.playlistItems),
          map(arr => {
            arr.forEach(k => k.src = 'https://youtu.be/' + k.video_id);
            return arr
          })
        ).subscribe(resp => {
          playlist.videos = resp;
          playlist.isLoad = false;
          console.log("openPlaylist", playlist)
        }, error => {
          console.log('error', error)
          this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
        })
      )
    } else {
      playlist.isOpen = false;
      playlist.isLoad = false;
    }
  }

  getYtPlaylists() {
    this.attachSubscriptions(
      this.taskService.ytGetPlaylists({company_id: this.data.company.id, task_channel_id: this.data.profile.id}).pipe(map(x => x.playlists)).subscribe(resp => {
        this.playlists = resp;
        console.log("this.playlists", this.playlists)
      }, error => {
        console.log('error', error)
        this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
      })
    )
  }

  removeFromPlaylist(playlist, item) {
    this.attachSubscriptions(
      this.taskService.ytDeleteFromPlaylist({
        task_channel_id: this.data.profile.id,
        playlist_item_id: item.playlist_item_id
      }).subscribe(resp => {
        playlist.videos.splice(playlist.videos.findIndex(k => k.playlist_item_id == item.playlist_item_id), 1)
        console.log(resp)
      }, error => {
        console.log('error', error)
        this.isSubmit = false;
        this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
      })
    )
  }

  onSubmit() {
    if (!this.playlists.filter(k => !!k.isSave).length) {
      this.layoutService.showSnackBar({name: ''}, 'You need to select playlist(s)', SnackBarItem)
    }
    this.isSubmit = true;
    
    let plsts = [];

    this.playlists.filter(k => !!k.isSave).forEach(k => {
      plsts.push({
        "playlist_id": k.playlist_id,
        "position": 0
      })
    })

    this.attachSubscriptions(
      this.taskService.ytSaveToPlaylist({task_channel_id: this.data.profile.id, playlists: plsts}).subscribe(resp => {
        this.isSubmit = false;
        this.playlists.filter(k => !!k.isSave).forEach(k => {
          k.isSave = false;
          if (!!k.isOpen) {
            k.isOpen = false;
            this.openPlaylist(k)
          }
        })
      }, error => {
        console.log('error', error)
        this.isSubmit = false;
        this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

}