import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { Component, Inject, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReplaySubject, Subscription, forkJoin, fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, last, map, switchMap, take, tap } from 'rxjs/operators';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { ConnectedAppsService } from 'src/app/shared/services/rest/connected-apps.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-connect-with-youtube',
  templateUrl: './connect-with-youtube.component.html',
  styleUrls: ['./connect-with-youtube.component.scss']
})
export class ConnectWithYoutubeComponent extends BaseClass implements OnInit, OnDestroy {
  public form: FormGroup = this.fb.group({
    company_id: this.data.company_id,
    channel_account_id: this.data.profile.channel_account_id,
  });
  public fromCompany: boolean = false;
  public isFormChange: boolean = false;
  public switched: boolean = false;
  public channels: any[] = [];
  public expChannels: any;
  public host: any = environment.host;

  public selectedChan: any;
  public chanControl: FormControl = new FormControl();
  public page: number = 1;
  public pagination: any;
  public is_getting: boolean = false;

  public groupedProfiles: any = [
    {
      platform_id: 0,
      channels: [],
      channelsControl: new FormControl(),
      asChannels: new ReplaySubject<any>(1)
    },
    {
      platform_id: 2,
      channels: [],
      channelsControl: new FormControl(),
      asChannels: new ReplaySubject<any>(1)
    },
    {
      platform_id: 4,
      channels: [],
      channelsControl: new FormControl(),
      asChannels: new ReplaySubject<any>(1)
    }
  ];
  public type: string = '';

  @ViewChild('filterMenu') filterMenu: TemplateRef<any>;
  public subFilter: Subscription;
  public expChannels$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public expChannelsControl: FormControl = new FormControl();
  
  public channelsSubsControl: FormControl = new FormControl(0);
  overlayRefFilter: OverlayRef | null;

  constructor(
    private connectedAppsService: ConnectedAppsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ConnectWithYoutubeComponent>,
    private fb: FormBuilder,
    private companyService: CompanyService,
    public viewContainerRef: ViewContainerRef,
    public overlay: Overlay,
    private sm: StorageManagerService,
    private layoutService: LayoutService
  ) { super() }

  ngOnInit(): void {
    console.log("ConnectWithYoutubeComponent", this.data)

    if (this.data.profile.channelAccount) {
      this.selectedChan = this.data.profile.channelAccount;
      this.form.patchValue({
        channel_account_id: this.selectedChan.id
      })
      if (this.channels.filter(x => x.id == this.selectedChan.id).length == 0) {
        this.channels.unshift(this.selectedChan)
      }
    }



    switch (this.data.profile.platform_id) {
      case 1:
        this.type = "youtube"
        break;
      case 2:
        this.type = "facebook"
        break;
      case 3:
        this.type = "tiktok"
        break;
      case 4:
        this.type = "facebook"
        break;
      case 5:
        this.type = "snapchat"
        break;
      default:
        this.type = "youtube"
        break;
    }

    this.attachSubscriptions(
      this.expChannelsControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchExpChannels(resp))
    )

    if (!!this.data.profile.channel_account_id) {
      this.getChannelAccountsChannels(this.data.profile.channel_account_id);
      if (this.type == 'facebook') {
        this.form.addControl('external_id', this.fb.control(!!this.data.profile.external_id ? [this.data.profile.external_id] : []))
      } else {
        this.form.addControl('external_id', this.fb.control(!!this.data.profile.external_id ? this.data.profile.external_id : ''))
      }
    } else {
      if (!!this.form.get('external_id')) {
        this.form.removeControl('external_id')
      }
    }

    if (!!this.form.get('external_id')) {
      this.form.get('external_id').updateValueAndValidity({onlySelf: true})
    }


    this.form.get('channel_account_id').valueChanges.subscribe(res => {
      this.expChannels = []
      this.groupedProfiles.forEach(group => {
        group.channels = [];
        group.asChannels.next(group.channels.slice())
      })
      console.log("channel_account_id", res);
      if (res) {
        if (!!this.form.get('external_id')) {
          this.form.patchValue({
            external_id: ''
          })
        } else {
          this.form.addControl('external_id', this.fb.control(''))
        }
        this.getChannelAccountsChannels(res);
      } else { 
        if (!!this.form.get('external_id')) {
          this.form.removeControl('external_id')
        }
      }
      this.form.get('external_id').updateValueAndValidity({onlySelf: true})
    })

    this.attachSubscriptions(
      this.chanControl.valueChanges.pipe(debounceTime(600),distinctUntilChanged()).subscribe((resp) => {
        console.log("chanControl", resp)
        this.onSearchChans(resp)
      })
    )

    this.getChannels(this.page, {q: this.chanControl.value});

    this.attachSubscriptions(
      this.form.valueChanges.subscribe((res) => {
        console.log("this.form.valueChanges", res)

        if (!!res.channel_account_id) {
          this.getChan(res.channel_account_id);
        } else {
          this.selectedChan = undefined;
        }

        // if (this.selectedTask && !!res.task_channel_id) {
        //   this.selectedChannel = 
        // } else {
          
        // }
        this.isFormChange = true
      })
    )

    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        if (this.isFormChange) {
          this.layoutService.openBottomSheet(this.dialogRef);
        } else {
          this.close();
        }
      })
    )

  }

  changeFromCompany(val) {
    this.fromCompany = val;
    this.channels = [];
    this.page = 1;
    this.getChannels(this.page, {q: this.chanControl.value});
  }

  getChan(res) {
    this.attachSubscriptions(
      this.connectedAppsService.getOneChanExpand(res, this.type, !!this.fromCompany ? this.data.company_id : null)
      .pipe(
        map(rslt => rslt.body[0])
      ).subscribe(res => {
        this.selectedChan = res;
 
        if (this.channels.filter(x => x.id == this.selectedChan.id).length == 0) {
          this.channels.unshift(this.selectedChan)
        }
        console.log("getChan selectedChan", this.selectedChan);
      })
    )
  }

  onScroll(e, type) {
    console.log("onScroll", e);
    if (this.pagination.pageCount >= this.page) {
      this.onSearchChans(this[type].value, e, true)
    }
  }

  selectCard(task) {
    this.selectedChan = JSON.parse(JSON.stringify(task));
    console.log("selectCard selectedChan", this.selectedChan);
  }

  getChannels(page, filter, event?) {
    if (this.is_getting) {
      return
    }
    this.is_getting = true;
    let params = {...filter}
    if (!!this.fromCompany) {
      params.company_id = this.data.company_id
    }
    console.log("getChannels filter", filter)
    this.attachSubscriptions(
      this.connectedAppsService.getChannelAccountsScrollFilter(page, this.type, params).pipe(
        tap(el => {
          this.pagination = {
            'pageCount': el.headers.get('x-pagination-page-count'),
            'perPage': el.headers.get('x-pagination-per-page'),
            'totalCount': el.headers.get('x-pagination-total-count'),
            'currentPage': el.headers.get('x-pagination-current-page'),
          }
        })
      ).subscribe(resp => {

        resp.body.forEach(element => {
          if (this.channels.filter(x => x.id == element.id).length == 0) {
            this.channels.push(element)
          }
        });
        console.log("this.channels-"+this.page, this.channels);
        this.page = this.page + 1; 
        this.is_getting = false;

        if (event) {
          setTimeout(() => {
            // + event.scrollHeight
            event.e.target.scrollTop = event.scrollTop;
            console.log("END", event)
          }, 50)
          // event.e.target.scroll(0, event.scrollTop);
        }
      })
    )
  }

  onSearchChans(resp, e?, notDel?) {
    if (this.is_getting) {
      return
    }
    resp = resp && typeof resp == 'string' ? resp.toLowerCase() : (resp || '');
    console.log('onSearchChans', resp, notDel)

    let filter:any = {
      q: resp
    }
    
    if (!notDel) {
      if (this.form.value.channel_account_id) {
        this.channels = this.channels.filter(x => x.id == this.form.value.channel_account_id);
      } else {
        this.channels = [];
      }
      this.page = 1;
      this.pagination = undefined
    }
    // filter the banks


    this.getChannels(this.page, filter, e);
  }

  patchControl(control:FormControl, val) {
    this.switched = true;
    control.patchValue(val)
    setTimeout(() => {
      this.switched = false
    },0)
  }

  onSearchExpChannels(resp) {
    console.log(resp, this.expChannels, this.form.value)
    if (!this.expChannels) {
      return;
    }

    if (!resp) {
      this.expChannels$.next(this.expChannels.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }

    this.expChannels$.next(
      this.expChannels.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  getChannelAccountsChannels(id) {
    this.attachSubscriptions(
      this.connectedAppsService.getChannelAccountsChannelsDyn('1', null, id, null, 1, '1', !!this.fromCompany ? this.data.company_id : null).pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }
  
          return forkJoin(arr.map(x => this.connectedAppsService.getChannelAccountsChannelsDyn(x, null, id, null, 1, '200', !!this.fromCompany ? this.data.company_id : null).pipe(map(u => u.body)))).pipe(
            last(),
            tap(values => {
              let conValues = [].concat(...values)
              this.expChannels = conValues;
              this.expChannels.forEach(x => {
                x.avatarFile = x.imageFile
              })
              this.expChannels$.next(this.expChannels.slice())
              
              conValues.forEach((channel) => {
                if (this.groupedProfiles.find(x => x.platform_id == 0).channels.filter(b => b.id == channel.id).length == 0) {
                  this.groupedProfiles.find(x => x.platform_id == 0).channels.push(channel);
                  this.groupedProfiles.find(x => x.platform_id == 0).asChannels.next(this.groupedProfiles.find(x => x.platform_id == 0).channels)
                }
                if (this.groupedProfiles.find(x => x.platform_id == channel.platform_id) && this.groupedProfiles.find(x => x.platform_id == channel.platform_id).channels.filter(b => b.id == channel.id).length == 0) {
                  this.groupedProfiles.find(x => x.platform_id == channel.platform_id).channels.push(channel);
                  this.groupedProfiles.find(x => x.platform_id == channel.platform_id).asChannels.next(this.groupedProfiles.find(x => x.platform_id == channel.platform_id).channels)
                }
              })
              
              this.groupedProfiles.forEach(el => {
                el.channelsControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => {console.log(resp); this.onSearchGroupedChannels(resp, el);})
              })
              console.log("groupedProfiles", this.groupedProfiles);
            }),
          )
        }),
      ).subscribe(resp => {
        console.log("getChannelAccountsChannels", this.expChannels);
      })
    )
  }

  // getChannelAccountsChannels(id) {
  //   this.attachSubscriptions(
  //     this.connectedAppsService.getChannelAccountsChannels(null, id, null, 1).pipe(
  //       tap(arr => {
  //         arr.map(x => x.avatarFile = x.imageFile)
  //       })
  //     ).subscribe(res => {
  //       console.log("expChannels", res);
  //       this.expChannels = res
  //       this.expChannels$.next(this.expChannels.slice())
        
  //       res.forEach((channel) => {
  //         if (this.groupedProfiles.find(x => x.platform_id == 0).channels.filter(b => b.id == channel.id).length == 0) {
  //           this.groupedProfiles.find(x => x.platform_id == 0).channels.push(channel);
  //           this.groupedProfiles.find(x => x.platform_id == 0).asChannels.next(this.groupedProfiles.find(x => x.platform_id == 0).channels)
  //         }
  //         if (this.groupedProfiles.find(x => x.platform_id == channel.platform_id) && this.groupedProfiles.find(x => x.platform_id == channel.platform_id).channels.filter(b => b.id == channel.id).length == 0) {
  //           this.groupedProfiles.find(x => x.platform_id == channel.platform_id).channels.push(channel);
  //           this.groupedProfiles.find(x => x.platform_id == channel.platform_id).asChannels.next(this.groupedProfiles.find(x => x.platform_id == channel.platform_id).channels)
  //         }
  //       })
        
  //       this.groupedProfiles.forEach(el => {
  //         el.channelsControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => {console.log(resp); this.onSearchGroupedChannels(resp, el);})
  //       })
  //       console.log("groupedProfiles", this.groupedProfiles);
  //     })
  //   )
  // }

  selectChange(e) {
    this.closeOpenFilter();
  }

  closeOpenFilter() {
    this.subFilter && this.subFilter.unsubscribe();
    if (this.overlayRefFilter) {
      this.overlayRefFilter.dispose();
      this.overlayRefFilter = null;
    }
  }

  openFilter(el) {
    this.closeOpenFilter();
    let x = el.offsetLeft;
    let y =  el.offsetTop + el.offsetHeight;
    console.log({ x, y })
  // openFilter({ x, y }: MouseEvent) {
  //   this.closeOpenFilter();
    const positionStrategy = this.overlay.position()
    .flexibleConnectedTo({ x, y })
    .withPositions([
      {
        originX: 'start',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'top',
      }
    ]);

    this.overlayRefFilter = this.overlay.create({
      backdropClass: 'transparent_bg',
      hasBackdrop: true,
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });

    this.overlayRefFilter.attach(new TemplatePortal(this.filterMenu, this.viewContainerRef, {
      $implicit: el.offsetWidth
    }));
    
    this.subFilter = fromEvent<MouseEvent>(document, 'click')
      .pipe(
        filter(event => {
          const clickTarget = event.target as HTMLElement;
          return !!this.overlayRefFilter && !this.overlayRefFilter.overlayElement.contains(clickTarget);
        }),
        take(1)
      ).subscribe(() => this.closeOpenFilter())

  }
  
  onSearchGroupedChannels(resp, groupedChannels) {
    if (!groupedChannels.channels) {
      return;
    }

    if (!resp) {
      groupedChannels.asChannels.next(groupedChannels.channels.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }

    // filter the banks
    groupedChannels.asChannels.next(
      groupedChannels.channels.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  getExpChannel(id) {
    if (!this.expChannels) {
      return false;
    }
    return this.expChannels.find(el => el.external_id == id)
  }

  getChannelAccountAuthUrl() {
    this.attachSubscriptions(
      this.connectedAppsService.getChannelAccountAuthUrl(this.type, window.location.href + `&open_id=${this.data.profile.id}`, !!this.fromCompany ? this.data.company_id : null).subscribe(resp => {
        console.log(resp);
        console.log(this.host + resp);
        window.open(this.host + resp, "_self")
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  onSubmit() {
    let formData = {...this.form.value}

    if (this.type == 'facebook' && formData.external_id.length) {
      if (formData.external_id[0] == 0) {
        formData.external_id = ''
      } else {
        formData.external_id = formData.external_id[0] 
      }
    }
    this.attachSubscriptions(
      this.companyService.editCompanyGroupProfile(this.data.profile.id, formData).subscribe(resp => {
        this.dialogRef.close({event: "edit"});
      })
    )
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
