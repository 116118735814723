<div mat-dialog-title class="mobile_interface_title">
    <div class="mobile_interface_title_in" style="overflow: hidden;">
        <div class="tab_title" (click)="close()">
            <div class="d-f-c">
                <div class="icon_back">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7 12L6.11612 11.1161C5.62796 11.6043 5.62796 12.3957 6.11612 12.8839L7 12ZM16.8839 3.88388C17.372 3.39573 17.372 2.60427 16.8839 2.11612C16.3957 1.62796 15.6043 1.62796 15.1161 2.11612L16.8839 3.88388ZM15.1161 21.8839C15.6043 22.372 16.3957 22.372 16.8839 21.8839C17.372 21.3957 17.372 20.6043 16.8839 20.1161L15.1161 21.8839ZM7.88388 12.8839L16.8839 3.88388L15.1161 2.11612L6.11612 11.1161L7.88388 12.8839ZM6.11612 12.8839L15.1161 21.8839L16.8839 20.1161L7.88388 11.1161L6.11612 12.8839Z" fill="#232323"/>
                    </svg>
                </div>
                <span>
                    {{ data.title | translate }} 
                    <!-- <span style="font-size: 10px;">{{data.filter.get(outletKey).value | json}} - {{data.filter.get('no_channel_id').value | json}} | {{savedForm | json}} - {{noSavedForm | json}}</span> -->
                </span>
            </div>
        </div>
        <div class="out_tabs" *ngIf="data.showTabs">
            <span [ngClass]="{'active': outletKey == 'channel_id'}" (click)="setOutletKey('channel_id')">{{"Cards"|translate}}</span>
            <span [ngClass]="{'active': outletKey == 'discussion_channel_id'}" (click)="setOutletKey('discussion_channel_id')">{{"Chats"|translate}}</span>
        </div>
    </div>
</div>
<mat-dialog-content class="mobile_interface_content">
    <div class="mobile_scrollable_int_big" style="background: #fff;">
        <section class="sub_filter_wrp channels_sub_wrp">
            <div class="subs" [appDeviceClass]="'custom-scroll'">
                <div class="sub" [ngClass]="{'active': outletsSubsControl.value === ''}" (click)="patchControl(outletsSubsControl, '')">
                    <div class="d-f-c">
                        <app-platform [platform_id]="0"></app-platform>
                        <span class="ml-1 text_one_line">
                            {{ "All outlets" | translate }}
                        </span>
                    </div>
                </div>
                <ng-container *ngFor="let item of data.platforms; let i = index;">
                    <div class="sub" *ngIf="!!item.id" [ngClass]="{'active': outletsSubsControl.value == item.id}" (click)="patchControl(outletsSubsControl, item.id)">
                        <div class="d-f-c">
                            <app-platform [platform_id]="item.id"></app-platform>
                            <span class="ml-1 text_one_line">
                                {{item?.name}}
                            </span>
                        </div>
                    </div>
                </ng-container>
            </div>
            <div class="block">
                <div class="filter_exp_search">
                    <mat-form-field appearance="legacy" class="filter_form search">
                        <input class="search_inp" [formControl]="searchControl" matInput>
                        <mat-icon matSuffix>search</mat-icon>
                    </mat-form-field>
                </div>

                <ng-container *ngIf="!switched">
                    <form [formGroup]="data.filter" class="exp_form" [appDeviceClass]="'custom-scroll'" 
                        infinite-scroll
                        [scrollWindow]="false"
                        [infiniteScrollUpDistance]="scrollUpDistance"
                        [infiniteScrollThrottle]="throttle"
                        (scrolled)="onDown($event)">
                            <mat-selection-list [multiple]="false" formControlName="{{outletKey}}">
                                <mat-list-option [value]="0" [ngClass]="{'active': data.filter.get(outletKey).value.includes(0)}">
                                    <div class="user_div">
                                        <mat-icon style="visibility: none;">user</mat-icon>
                                        <span>
                                            {{ "Outlet not set" | translate }}
                                        </span>
                                    </div>
                                </mat-list-option>
                                <ng-container *ngFor="let fil of outlets">
                                    <mat-list-option class="cst_o flex_text" [value]="fil.id" [ngClass]="{'active': data.filter.get(outletKey).value.includes(fil.id)}">
                                        <div class="d-f-c">
                                            <app-outlet-line [class]="'for_nav'" [channel]="fil" [company_id]="company_id"></app-outlet-line>
                                            <mat-checkbox (click)="setChannelMulty($event, fil.id, outletKey)" [disableRipple]="true" class="outlet_fil_check" [checked]="data.filter.get(outletKey).value.includes(fil.id)" color="primary"></mat-checkbox>
                                        </div>
                                        <span class="not_assigned" (click)="noChannel($event, fil.id)" [ngClass]="{'active': data.filter.get('no_channel_id').value.includes(fil.id)}">
                                            {{ "n/a" | translate }}
                                            <mat-checkbox [disableRipple]="true" class="outlet_fil_check" [checked]="data.filter.get('no_channel_id').value.includes(fil.id)" (click)="noChannelMulty($event, fil.id)" color="primary"></mat-checkbox>
                                        </span>
                                    </mat-list-option>
                                </ng-container>
                        </mat-selection-list>
                    </form>
                    
                </ng-container>
            </div>
        </section>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <div class="mobile_interface_buttons small_mob_btns" style="justify-content: space-between !important;">
        <button class="mobile_interface_btn" (click)="close()">
            {{ "Cancel" | translate }}
        </button>
        
        <button class="mobile_interface_btn mobile_interface_btn_blue" (click)="onSubmit()">
            {{ "Apply" | translate }}
        </button>
    </div>
</mat-dialog-actions>