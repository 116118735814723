import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ReplaySubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { environment } from 'src/environments/environment';
import { SetEditFoldersPermissionComponent } from '../set-edit-folders-permission/set-edit-folders-permission.component';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';

@Component({
  selector: 'app-folders-permissions',
  templateUrl: './folders-permissions.component.html',
  styleUrls: ['./folders-permissions.component.scss']
})
export class FoldersPermissionsComponent extends BaseClass implements OnInit, OnDestroy {
  type: string = 'user'
  public folders: any[] = [
    {
      name: "Root folder",
      id: -1,
      location: "/",
      cant_read: false,
      cant_write: false,
    },
    {
      name: "Sources",
      id: 0,
      location: "/sources",
      cant_read: false,
      cant_write: false,
    },
    {
      name: "To Approve",
      id: 1,
      location: "/to_approve",
      cant_read: false,
      cant_write: false,
    },
    {
      name: "Ready Files",
      id: 2,
      location: "/ready_files",
      cant_read: false,
      cant_write: false,
    },
    {
      name: "Approved",
      id: 3,
      location: "/approved",
      cant_read: false,
      cant_write: false,
    },
    {
      name: "Project",
      id: 4,
      location: "/project",
      cant_read: false,
      cant_write: false,
    },
    {
      name: "Trash",
      id: 5,
      location: "/trash",
      cant_read: false,
      cant_write: false,
    }
  ]

  public imgRoute: any = '';
  public isSubmit: boolean = false;
  public host: any = environment.host;
  public taskStatuses: any;

  
  public statuses: any;
  
  public operations: any;
  public operationMoreControl: FormControl = new FormControl();
  public operations$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public sources: any = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private taskService: TaskService,
    private sm: StorageManagerService,
    private dialog: MatDialog,
    private companyService: CompanyService,
    private layoutService: LayoutService,
    public dialogRef: MatDialogRef<FoldersPermissionsComponent>,
  ) { super() }

  ngOnInit(): void {
    console.log('FoldersPermissionsComponent', this.data)


    if (this.data.type) {
      this.type = this.data.type

      if (!!this.data.target.employee_setting) {
        this.sources = JSON.parse(this.data.target.employee_setting);
    
        this.sources.forEach(source => {
          if (!source.hasOwnProperty('folders')) {
            source.folders = JSON.parse(JSON.stringify(this.folders))
          }
        });
      }
    } else {
      if (!!this.data.employee.employeeSetting) {
        this.sources = JSON.parse(this.data.employee.employeeSetting.file_manager);
  
        this.sources.forEach(source => {
          if (!source.hasOwnProperty('folders')) {
            source.folders = JSON.parse(JSON.stringify(this.folders))
          }
        });
      }
    }
    this.attachSubscriptions(
      this.operationMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchOperations(resp))
    )

    this.getTaskStatus();
    this.getOperations();
    this.getOperationsStatus();
    this.getImgRoute();
  }

  getOperationsStatus() {
    this.attachSubscriptions(
      this.taskService.getOperationsStatus().subscribe(resp => {
        resp.unshift({
          id: -2,
          name: "All statuses"
        })
        this.statuses = resp.slice();
        console.log("statuses", this.statuses);
      })
    )
  }

  onSearchOperations(resp) {
    if (!this.operations) {
      return;
    }

    if (!resp) {
      this.operations$.next(this.operations.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.operations$.next(
      this.operations.filter(z => (z.name.toLowerCase().indexOf(resp) > -1))
    );
  }

  getOperations() {
    this.attachSubscriptions(
      this.taskService.getOperations(this.data.company.id).subscribe(resp => {
        resp.unshift({
          id: -2,
          name: "All job types"
        })
        this.operations = resp;
        this.operations$.next(this.operations.slice())
      })
    )
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  getTaskStatus() {
    this.attachSubscriptions(
      this.taskService.getTaskStatuses(this.data.company.id).subscribe(resp => {
        console.log("getTaskStatuses", resp)
        resp.unshift({
          id: -2,
          name: "All card types"
        })
        this.taskStatuses = resp;
      })
    )
  }

  log() {
    console.log(this.sources)
  }

  getValuesById(id) {
    return this.data.allValues && this.data.allValues.find(x => x.id == id)
  }

  getParamById(id) {
    return this.data.parameters && this.data.parameters.find(x => x.id == id)
  }

  getStatusById(id) {
    return this.statuses && this.statuses.find(x => x.id == id)
  }

  getFolderByLoc(loc) {
    return this.folders && this.folders.find(x => x.location == loc)
  }

  getTaskStatusesById(id) {
    return this.taskStatuses && this.taskStatuses.find(x => x.id == id)
  }

  getOperationsById(id) {
    return this.operations && this.operations.find(x => x.id == id)
  }

  deleteSource(i) {
    this.sources.splice(i, 1)
  }

  setSource(is_loc:boolean = false, source?) {
    let initData:any = {
      company: this.data.company,
      is_loc: is_loc,
      host: this.host,
      imgRoute: this.imgRoute,
      taskStatuses: this.taskStatuses,
      operations: this.operations,
      statuses: this.statuses
    }

    if (source) {
      initData.source = source;
    } else {
      this.sources.push({
        'task_statuses_ids':[],
        'task_operation_operations_ids': [],
        'task_operation_statuses_ids': [],
        'is_manager': 0,
        'folders': JSON.parse(JSON.stringify(this.folders)),
      })
      initData.source = this.sources[this.sources.length - 1];
    }

    const dialogRef = this.dialog.open(SetEditFoldersPermissionComponent, {
      backdropClass: ['auto_back_modal'],
      panelClass: ['auto_panel_modal'],
      data: initData
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log(result)
        if (!!result) {
          // if (result.event == 'change') {
          //   if (source) {
          //     this.sources.push(result.data);
          //   } else {
          //     this.sources.push(result.data);
          //   }
          // }
        }
      })
    )
  }

  // onSearchValues(resp) {
  //   if (!this.data.allValues) {
  //     return;
  //   }

  //   if (!resp) {
  //     this.allValues$.next(this.data.allValues.slice());
  //     return;
  //   } else {
  //     resp = resp.toLowerCase();
  //   }
  //   // filter the banks
  //   this.allValues$.next(
  //     this.data.allValues.filter(z => z.value.toLowerCase().indexOf(resp) > -1 || (z.parameter.name && z.parameter.name.toLowerCase().indexOf(resp) > -1))
  //   );
  // }

  changeFolPerm(source, loc, is_add:boolean = false) {
    if (is_add) {
      source.write_directories.push(loc)
    } else {
      source.write_directories.splice(source.write_directories.indexOf(loc), 1)
    }
  }


  close() {
    this.dialogRef.close({event: "close", data: false});
  }

  onSubmit() {
    this.isSubmit = true;
    this.isSubmit = false;

    if (this.type == 'user') {
      if (!!this.data.employee.employeeSetting) {
        this.attachSubscriptions(
          this.taskService.editFoldersPermission(this.data.employee.employeeSetting.id, {
            file_manager: JSON.stringify(this.sources)
          }).subscribe(resp => {
            console.log(resp)
            this.dialogRef.close({event: "change", data: resp})
          })
        )
      } else {
        this.attachSubscriptions(
          this.taskService.createFoldersPermission({
            company_id: this.data.company.id,
            employee_id: this.data.employee.id,
            file_manager: JSON.stringify(this.sources)
          }).subscribe(resp => {
            console.log(resp)
            this.dialogRef.close({event: "change", data: resp})
          })
        )
      }
    } else {
      if (this.type == 'department') {
        this.attachSubscriptions(
          this.companyService.editDepartment(this.data.target.id, {employee_setting: JSON.stringify(this.sources)}).subscribe(resp => {
            this.data.target.employee_setting = resp.employee_setting;
            this.dialogRef.close({event: "change", data: resp})
          }, error => {
            this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
          })
        )
      } else {
        this.attachSubscriptions(
          this.companyService.editPosition(this.data.target.id, {employee_setting: JSON.stringify(this.sources)}).subscribe(resp => {
            this.data.target.employee_setting = resp.employee_setting;
            this.dialogRef.close({event: "change", data: resp})
          }, error => {
            this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
          })
        )
      }
    }

  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
