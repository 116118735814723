import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { forkJoin, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { getLessStatuses } from 'src/app/shared/functions/statusesLessModel';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { ChatService } from 'src/app/shared/services/rest/chat.service';
import { MembersService } from 'src/app/shared/services/rest/members.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { BackJobToPrevStatusComponent } from '../../../back-job-to-prev-status/back-job-to-prev-status.component';
import { SnackBarItem } from '../../../snack-bar/snack-bar-item';

@Component({
  selector: 'app-mob-personal-statuses',
  templateUrl: './mob-personal-statuses.component.html',
  styleUrls: ['./mob-personal-statuses.component.scss']
})
export class MobPersonalStatusesComponent extends BaseClass implements OnInit, OnDestroy {
  public isInit: boolean = true;
  public selectedStatus: FormGroup = this.fb.group({
    stat: [this.data.statuses.find(x => x.id == this.data.chat.status_id), Validators.required]
  });
  public employees: any;
  public employee_ids: number[] = [];
  public me: any;
  public selectedIndex: number = 0;
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<MobPersonalStatusesComponent>,
    private chatService: ChatService,
    private membersService: MembersService,
    private layoutService: LayoutService,
    private bottomSheet: MatBottomSheet,
    private taskService: TaskService,
    private fb: FormBuilder
  ) { super() }

  ngOnInit(): void {
    console.log("MobPersonalStatusesComponent", this.data)
    if (this.data.selectedStatus) {
      this.selectedStatus.patchValue({
        stat: this.data.selectedStatus
      })
      console.log(this.selectedStatus.value)
      if (this.data?.iAmEmployee && this.data?.iAmEmployee.is_manager == 0 && !this.checkIsManager(this.data.task, this.data.company, this.data.user)) {
        this.addEmlChangeStatus(this.data.selectedStatus);
      } else {
        this.managerChangeStatus(this.data.selectedStatus)
      }
      this.updateUsers();
    }
  }

  checkIsManager(task, company, _user) {
    if (!task || !company || !_user) {
      return false
    }
    return task.managers.filter(x => x.user_id == _user.id).length > 0 || task?.group?.managers.filter(x => x.user_id == _user.id).length > 0 || company?.permissions.includes('manager'); 
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  selectionChange($event?: StepperSelectionEvent): void { 
    console.log($event);
    if ($event.selectedIndex == 0) {
      this.selectedStatus.patchValue({
        stat: this.data.statuses.find(x => x.id == this.data.chat.status_id)
      })
    } else {
      this.updateUsers()
    }
    this.selectedIndex = $event.selectedIndex;
  }

  updateUsers() {
    let emplAll = [...this.data.chatEmlStats.filter(t => this.selectedStatus.value.stat.empl.filter(b => b.employee_id == t.employee_id || (!!t.partner_company_id && b.partner_company_id == t.partner_company_id)).length == 0)]
    this.employees = emplAll.slice();
    if (this.employees.find(x => x.employee_id == this.data.company.employees[0].id)) {
      this.me = this.employees.find(x => x.employee_id == this.data.company.employees[0].id);
      this.employees.splice(this.employees.indexOf(this.me), 1)
    } else if (this.employees.find(x => x.partner_employee_id == this.data.company.employees[0].id)) {
      this.me = this.employees.find(x => x.employee_id == this.data.company.employees[0].id);
      this.employees.splice(this.employees.indexOf(this.me), 1)
    } else {
      this.me = undefined
    }

    // else if (this.employees.find(x => x.partner_company_id && !x.partner_employee_id && (x.partner_company_id == this.data.company.id))){
    //   this.me = this.employees.find(x => x.partner_company_id && !x.partner_employee_id && (x.partner_company_id == this.data.company.id));
    //   this.employees.splice(this.employees.indexOf(this.me), 1)
    // }
    console.log("this.me", this.me)
    console.log("this.employees", this.employees)
  }

  selectWhole() {
    this.attachSubscriptions(
      this.chatService.editChat(this.data.chat.id, {status_id: this.selectedStatus.value.stat.id}, this.data.company.id)
      .pipe(
        switchMap(val => {
          if ([3,4,98,99].includes(this.selectedStatus.value.stat.id)) {
            return this.chatService.detectChats(this.data.company.id, this.data.work.id, [1,2,5,6]).pipe(
              map(x => x.headers.get('x-pagination-total-count')),
              switchMap(res => {
                if (res == 0) {
                  let questionAlert =  this.bottomSheet.open(BackJobToPrevStatusComponent, {
                    hasBackdrop: true,
                    backdropClass: 'bottom-sheed-backdrop',
                    data: {
                      msg: marker(`Do you really want to convert job to status: '${this.getOperationStatusName(this.selectedStatus.value.stat.id)}'?`)
                    }
                  });
              
                  return questionAlert.afterDismissed()
                  .pipe(
                    switchMap(data => {
                      if (data && data.message == 'no') {
                        console.log("no");
                        return of(val)
                      } else if (data && data.message == 'yes') {
                        return this.taskService.editWork(this.data.work.id, {status_id: this.selectedStatus.value.stat.id}, this.data.company.id).pipe(
                          map(() => val)
                        )
                      } else {
                        return of(val)
                      }
                    })
                  )
                  
                } else {
                  return of(val)
                }
              })
            )
          } else {
          return of(val)
          }
        })
      ).subscribe(resp => {
        if (this.data.selectedStatus) {
          this.close();
        } else {
          this.data.updateDiscussion();
          this.selectedIndex = 0;
        }
      }, error => {
        this.showError(error)
      })
    )
  }

  selectUser(user) {
    console.log(user)
    if (this.data.selectedStatus) {
      let id = user?.id
      if (this.employee_ids.includes(id)) {
        this.employee_ids.splice(this.employee_ids.indexOf(id), 1)
      } else {
        this.employee_ids.push(id)
      }
      console.log("employee_ids", this.employee_ids)
    } else {
      if (user.is_partner) {
        if (!!this.data.chatEmlStats.find(x => x.partner_company_id == user.partner_company_id)?.partnerCompanyStatus) {
          this.attachSubscriptions(
            this.membersService.editPartnerStatus(this.data.chatEmlStats.find(x => x.employee_id == user.employee_id)?.partnerCompanyStatus.id, {status_id: this.selectedStatus.value.stat.id}, this.data.company.id).subscribe(resp => {
              console.log(resp)
              user.partnerCompanyStatus.status_id = resp.status_id;
              user.partnerCompanyStatus.status = this.data.statuses.find(x => x.id == resp.statuses_id);
              this.data.updateDiscussion();
              this.selectedIndex = 0;
            }, error => {
              this.showError(error)
            })
          )
        } else {
          this.attachSubscriptions(
            this.membersService.addPartnerStatus({
              status_id: this.selectedStatus.value.stat.id,
              company_id: this.data.company.id,
              task_id: this.data.task.id,
              task_operation_id: this.data.work.id,
              discussion_id: this.data.chat.id,
              partner_company_id: user.partner_company_id
            }, this.data.company.id).subscribe(resp => {
              console.log(resp)
              user.partnerCompanyStatus.status_id = resp.status_id;
              user.partnerCompanyStatus.status = this.data.statuses.find(x => x.id == resp.statuses_id);
              this.data.updateDiscussion();
              this.selectedIndex = 0;
            }, error => {
              this.showError(error)
            })
          )
        }
      } else if (user.is_partner_employee) {
        if (!!user.employeeStatus?.id) {
          this.attachSubscriptions(
            this.membersService.editPartnerEmployeeStatus(user.employeeStatus.id, {status_id: this.selectedStatus.value.stat.id}, this.data.company.id)
            .pipe(
              switchMap(val => {
                console.log("drop RES1", val)
                if ([3,4,6,97,98,99].includes(this.selectedStatus.value.stat.id)) {
                  console.log("drop RES2", val)
                  return this.membersService.getTaskPartnerEmployeeStatuses({task_operation_id: this.data.work.id, status_id: getLessStatuses(this.selectedStatus.value.stat.id), partner_employee_id: user.partner_employee_id, partner_company_id: user.partner_company_id}, this.data.company.id, '2').pipe(
                    switchMap(res => {
                      console.log("drop RES3", res)
                      if (res.length == 1 && !res[0].discussion_id) {
                        console.log("drop RES4", val)
                        let questionAlert =  this.bottomSheet.open(BackJobToPrevStatusComponent, {
                          hasBackdrop: true,
                          backdropClass: 'bottom-sheed-backdrop',
                          data: {
                            msg: marker(`Do you really want to convert personal partner employee status in job to: '${this.getOperationStatusName(this.selectedStatus.value.stat.id)}'?`)
                          }
                        });
                    
                        return questionAlert.afterDismissed()
                        .pipe(
                          switchMap(data => {
                            if (data && data.message == 'no') {
                              console.log("no");
                              return of(val)
                            } else if (data && data.message == 'yes') {
                              return this.membersService.editPartnerEmployeeStatus(res[0].id, {status_id: this.selectedStatus.value.stat.id}, this.data.company.id).pipe(
                                map(() => val)
                              )
                            } else {
                              return of(val)
                            }
                          })
                        )
                      } else {
                        return of(val)
                      }
                    })
                  )
                } else {
                  return of(val)
                }
              })
            ).subscribe(resp => {
              console.log(resp)
              user.employeeStatus.status_id = resp.status_id;
              user.employeeStatus.status = this.data.statuses.find(x => x.id == resp.statuses_id);
              this.data.updateDiscussion();
              this.selectedIndex = 0;
            }, error => {
              this.showError(error)
            })
          )
        } else {
          this.attachSubscriptions(
            this.membersService.addPartnerEmployeeStatus({
              status_id: this.selectedStatus.value.stat.id,
              company_id: this.data.company.id,
              task_id: this.data.task.id,
              task_operation_id: this.data.work.id,
              discussion_id: this.data.chat.id,
              partner_company_id: user.partner_company_id,
              partner_employee_id: user.partner_employee_id
            }, this.data.company.id)
            .pipe(
              switchMap(val => {
                console.log("drop RES1", val)
                if ([3,4,6,97,98,99].includes(this.selectedStatus.value.stat.id)) {
                  console.log("drop RES2", val)
                  return this.membersService.getTaskPartnerEmployeeStatuses({task_operation_id: this.data.work.id, status_id: getLessStatuses(this.selectedStatus.value.stat.id), partner_employee_id: user.partner_employee_id, partner_company_id: user.partner_company_id}, this.data.company.id, '2').pipe(
                    switchMap(res => {
                      console.log("drop RES3", res)
                      if (res.length == 1 && !res[0].discussion_id) {
                        console.log("drop RES4", val)
                        let questionAlert =  this.bottomSheet.open(BackJobToPrevStatusComponent, {
                          hasBackdrop: true,
                          backdropClass: 'bottom-sheed-backdrop',
                          data: {
                            msg: marker(`Do you really want to convert personal partner employee status in job to: '${this.getOperationStatusName(this.selectedStatus.value.stat.id)}'?`)
                          }
                        });
                    
                        return questionAlert.afterDismissed()
                        .pipe(
                          switchMap(data => {
                            if (data && data.message == 'no') {
                              console.log("no");
                              return of(val)
                            } else if (data && data.message == 'yes') {
                              return this.membersService.editPartnerEmployeeStatus(res[0].id, {status_id: this.selectedStatus.value.stat.id}, this.data.company.id).pipe(
                                map(() => val)
                              )
                            } else {
                              return of(val)
                            }
                          })
                        )
                      } else {
                        return of(val)
                      }
                    })
                  )
                } else {
                  return of(val)
                }
              })
            ).subscribe(resp => {
              console.log(resp)
              user.employeeStatus.status_id = resp.status_id;
              user.employeeStatus.status = this.data.statuses.find(x => x.id == resp.statuses_id);
              this.data.updateDiscussion();
              this.selectedIndex = 0;
            }, error => {
              this.showError(error)
            })
          )
        }
      } else {
        if (!!this.data.chatEmlStats.find(x => x.employee_id == user.employee_id)?.employeeStatus) {
          this.attachSubscriptions(
            this.membersService.editTaskEmployeeStatus(this.data.chatEmlStats.find(x => x.employee_id == user.employee_id)?.employeeStatus.id, {status_id: this.selectedStatus.value.stat.id}, this.data.company.id)
            .pipe(
              switchMap(val => {
                if ([3,4,6,97,98,99].includes(this.selectedStatus.value.stat.id)) {
                  return this.membersService.getTaskEmployeeStatuses({task_operation_id: this.data.work.id, status_id: getLessStatuses(this.selectedStatus.value.stat.id), employee_id: user.employee_id}, this.data.company.id, '2').pipe(
                    switchMap(res => {
                      console.log("RES3", res)
                      if (res.length == 1 && !res[0].discussion_id) {
                        console.log("RES4", val)
                        let questionAlert =  this.bottomSheet.open(BackJobToPrevStatusComponent, {
                          hasBackdrop: true,
                          backdropClass: 'bottom-sheed-backdrop',
                          data: {
                            msg: marker(`Do you really want to convert job personal status to: '${this.getOperationStatusName(+this.selectedStatus.value.stat.id)}'?`)
                          }
                        });
                    
                        return questionAlert.afterDismissed()
                        .pipe(
                          switchMap(data => {
                            if (data && data.message == 'no') {
                              console.log("no");
                              return of(val)
                            } else if (data && data.message == 'yes') {
                              return this.membersService.editTaskEmployeeStatus(res[0].id, {status_id: this.selectedStatus.value.stat.id}, this.data.company.id).pipe(
                                map(() => val)
                              )
                            } else {
                              return of(val)
                            }
                          })
                        )
                      } else {
                        return of(val)
                      }
                    })
                  )
                } else {
                return of(val)
                }
              })
            ).subscribe(resp => {
              console.log(resp)
              user.employeeStatus.status_id = resp.status_id;
              user.employeeStatus.status = this.data.statuses.find(x => x.id == resp.statuses_id);
              this.data.updateDiscussion();
              this.selectedIndex = 0;
            }, error => {
              this.showError(error)
            })
          )
        } else {
          this.attachSubscriptions(
            this.membersService.addTaskEmployeeStatus({
              status_id: this.selectedStatus.value.stat.id,
              company_id: this.data.company.id,
              task_id: this.data.task.id,
              task_operation_id: this.data.work.id,
              discussion_id: this.data.chat.id,
              employee_id: user.employee_id
            }, this.data.company.id)
            .pipe(
              switchMap(val => {
                if ([3,4,6,97,98,99].includes(this.selectedStatus.value.stat.id)) {
                  return this.membersService.getTaskEmployeeStatuses({task_operation_id: this.data.work.id, status_id: getLessStatuses(this.selectedStatus.value.stat.id), employee_id: user.employee_id}, this.data.company.id, '2').pipe(
                    switchMap(res => {
                      console.log("RES3", res)
                      if (res.length == 1 && !res[0].discussion_id) {
                        console.log("RES4", val)
                        let questionAlert =  this.bottomSheet.open(BackJobToPrevStatusComponent, {
                          hasBackdrop: true,
                          backdropClass: 'bottom-sheed-backdrop',
                          data: {
                            msg: marker(`Do you really want to convert job personal status to: '${this.getOperationStatusName(+this.selectedStatus.value.stat.id)}'?`)
                          }
                        });
                    
                        return questionAlert.afterDismissed()
                        .pipe(
                          switchMap(data => {
                            if (data && data.message == 'no') {
                              console.log("no");
                              return of(val)
                            } else if (data && data.message == 'yes') {
                              return this.membersService.editTaskEmployeeStatus(res[0].id, {status_id: this.selectedStatus.value.stat.id}, this.data.company.id).pipe(
                                map(() => val)
                              )
                            } else {
                              return of(val)
                            }
                          })
                        )
                      } else {
                        return of(val)
                      }
                    })
                  )
                } else {
                return of(val)
                }
              })
            ).subscribe(resp => {
              console.log(resp)
              user.employeeStatus.status_id = resp.status_id;
              user.employeeStatus.status = this.data.statuses.find(x => x.id == resp.statuses_id);
              this.data.updateDiscussion();
              this.selectedIndex = 0;
            }, error => {
              this.showError(error)
            })
          )
        }
      }
    }
  }

  managerChangeStatus(status) {
    console.log("managerChangeStatus", status)
    this.selectedStatus.patchValue({
      stat: status
    })
    
    let empls = [...this.data.chatEmlStats.filter(t => this.selectedStatus.value.stat.empl.filter(b => b.employee_id == t.employee_id || (!!t.partner_company_id && b.partner_company_id == t.partner_company_id)).length == 0)]
    if (!this.data.selectedStatus && empls.length == 1) {
      let user = empls[0]

      console.log("empls", empls)
      console.log("user", user)
      if (user.is_partner) {
        if (!!this.data.chatEmlStats.find(x => x.partner_company_id == user.partner_company_id)?.partnerCompanyStatus) {
          this.attachSubscriptions(
            this.membersService.editPartnerStatus(this.data.chatEmlStats.find(x => x.employee_id == user.employee_id)?.partnerCompanyStatus.id, {status_id: this.selectedStatus.value.stat.id}, this.data.company.id).subscribe(resp => {
              console.log(resp)
              user.partnerCompanyStatus.status_id = resp.status_id;
              user.partnerCompanyStatus.status = this.data.statuses.find(x => x.id == resp.statuses_id);
              this.data.updateDiscussion();
              this.selectedIndex = 0;
            }, error => {
              this.showError(error)
            })
          )
        } else {
          this.attachSubscriptions(
            this.membersService.addPartnerStatus({
              status_id: this.selectedStatus.value.stat.id,
              company_id: this.data.company.id,
              task_id: this.data.task.id,
              task_operation_id: this.data.work.id,
              discussion_id: this.data.chat.id,
              partner_company_id: user.partner_company_id
            }, this.data.company.id).subscribe(resp => {
              console.log(resp)
              user.partnerCompanyStatus.status_id = resp.status_id;
              user.partnerCompanyStatus.status = this.data.statuses.find(x => x.id == resp.statuses_id);
              this.data.updateDiscussion();
              this.selectedIndex = 0;
            }, error => {
              this.showError(error)
            })
          )
        }
      } else if (user.is_partner_employee) {
        if (!!user.employeeStatus?.id) {
          this.attachSubscriptions(
            this.membersService.editPartnerEmployeeStatus(user.employeeStatus.id, {status_id: this.selectedStatus.value.stat.id}, this.data.company.id)
            .pipe(
              switchMap(val => {
                console.log("drop RES1", val)
                if ([3,4,6,97,98,99].includes(this.selectedStatus.value.stat.id)) {
                  console.log("drop RES2", val)
                  return this.membersService.getTaskPartnerEmployeeStatuses({task_operation_id: this.data.work.id, status_id: getLessStatuses(this.selectedStatus.value.stat.id), partner_employee_id: user.partner_employee_id, partner_company_id: user.partner_company_id}, this.data.company.id, '2').pipe(
                    switchMap(res => {
                      console.log("drop RES3", res)
                      if (res.length == 1 && !res[0].discussion_id) {
                        console.log("drop RES4", val)
                        let questionAlert =  this.bottomSheet.open(BackJobToPrevStatusComponent, {
                          hasBackdrop: true,
                          backdropClass: 'bottom-sheed-backdrop',
                          data: {
                            msg: marker(`Do you really want to convert personal partner employee status in job to: '${this.getOperationStatusName(this.selectedStatus.value.stat.id)}'?`)
                          }
                        });
                    
                        return questionAlert.afterDismissed()
                        .pipe(
                          switchMap(data => {
                            if (data && data.message == 'no') {
                              console.log("no");
                              return of(val)
                            } else if (data && data.message == 'yes') {
                              return this.membersService.editPartnerEmployeeStatus(res[0].id, {status_id: this.selectedStatus.value.stat.id}, this.data.company.id).pipe(
                                map(() => val)
                              )
                            } else {
                              return of(val)
                            }
                          })
                        )
                      } else {
                        return of(val)
                      }
                    })
                  )
                } else {
                  return of(val)
                }
              })
            ).subscribe(resp => {
              console.log(resp)
              user.employeeStatus.status_id = resp.status_id;
              user.employeeStatus.status = this.data.statuses.find(x => x.id == resp.statuses_id);
              this.data.updateDiscussion();
              this.selectedIndex = 0;
            }, error => {
              this.showError(error)
            })
          )
        } else {
          this.attachSubscriptions(
            this.membersService.addPartnerEmployeeStatus({
              status_id: this.selectedStatus.value.stat.id,
              company_id: this.data.company.id,
              task_id: this.data.task.id,
              task_operation_id: this.data.work.id,
              discussion_id: this.data.chat.id,
              partner_company_id: user.partner_company_id,
              partner_employee_id: user.partner_employee_id
            }, this.data.company.id)
            .pipe(
              switchMap(val => {
                console.log("drop RES1", val)
                if ([3,4,6,97,98,99].includes(this.selectedStatus.value.stat.id)) {
                  console.log("drop RES2", val)
                  return this.membersService.getTaskPartnerEmployeeStatuses({task_operation_id: this.data.work.id, status_id: getLessStatuses(this.selectedStatus.value.stat.id), partner_employee_id: user.partner_employee_id, partner_company_id: user.partner_company_id}, this.data.company.id, '2').pipe(
                    switchMap(res => {
                      console.log("drop RES3", res)
                      if (res.length == 1 && !res[0].discussion_id) {
                        console.log("drop RES4", val)
                        let questionAlert =  this.bottomSheet.open(BackJobToPrevStatusComponent, {
                          hasBackdrop: true,
                          backdropClass: 'bottom-sheed-backdrop',
                          data: {
                            msg: marker(`Do you really want to convert personal partner employee status in job to: '${this.getOperationStatusName(this.selectedStatus.value.stat.id)}'?`)
                          }
                        });
                    
                        return questionAlert.afterDismissed()
                        .pipe(
                          switchMap(data => {
                            if (data && data.message == 'no') {
                              console.log("no");
                              return of(val)
                            } else if (data && data.message == 'yes') {
                              return this.membersService.editPartnerEmployeeStatus(res[0].id, {status_id: this.selectedStatus.value.stat.id}, this.data.company.id).pipe(
                                map(() => val)
                              )
                            } else {
                              return of(val)
                            }
                          })
                        )
                      } else {
                        return of(val)
                      }
                    })
                  )
                } else {
                  return of(val)
                }
              })
            ).subscribe(resp => {
              console.log(resp)
              user.employeeStatus.status_id = resp.status_id;
              user.employeeStatus.status = this.data.statuses.find(x => x.id == resp.statuses_id);
              this.data.updateDiscussion();
              this.selectedIndex = 0;
            }, error => {
              this.showError(error)
            })
          )
        }
      } else {
        if (!!this.data.chatEmlStats.find(x => x.employee_id == user.employee_id)?.employeeStatus) {
          this.attachSubscriptions(
            this.membersService.editTaskEmployeeStatus(this.data.chatEmlStats.find(x => x.employee_id == user.employee_id)?.employeeStatus.id, {status_id: this.selectedStatus.value.stat.id}, this.data.company.id)
            .pipe(
              switchMap(val => {
                if ([3,4,6,97,98,99].includes(this.selectedStatus.value.stat.id)) {
                  return this.membersService.getTaskEmployeeStatuses({task_operation_id: this.data.work.id, status_id: getLessStatuses(this.selectedStatus.value.stat.id), employee_id: user.employee_id}, this.data.company.id, '2').pipe(
                    switchMap(res => {
                      console.log("RES3", res)
                      if (res.length == 1 && !res[0].discussion_id) {
                        console.log("RES4", val)
                        let questionAlert =  this.bottomSheet.open(BackJobToPrevStatusComponent, {
                          hasBackdrop: true,
                          backdropClass: 'bottom-sheed-backdrop',
                          data: {
                            msg: marker(`Do you really want to convert job personal status to: '${this.getOperationStatusName(+this.selectedStatus.value.stat.id)}'?`)
                          }
                        });
                    
                        return questionAlert.afterDismissed()
                        .pipe(
                          switchMap(data => {
                            if (data && data.message == 'no') {
                              console.log("no");
                              return of(val)
                            } else if (data && data.message == 'yes') {
                              return this.membersService.editTaskEmployeeStatus(res[0].id, {status_id: this.selectedStatus.value.stat.id}, this.data.company.id).pipe(
                                map(() => val)
                              )
                            } else {
                              return of(val)
                            }
                          })
                        )
                      } else {
                        return of(val)
                      }
                    })
                  )
                } else {
                return of(val)
                }
              })
            ).subscribe(resp => {
              console.log(resp)
              user.employeeStatus.status_id = resp.status_id;
              user.employeeStatus.status = this.data.statuses.find(x => x.id == resp.statuses_id);
              this.data.updateDiscussion();
              this.selectedIndex = 0;
            }, error => {
              this.showError(error)
            })
          )
        } else {
          this.attachSubscriptions(
            this.membersService.addTaskEmployeeStatus({
              status_id: this.selectedStatus.value.stat.id,
              company_id: this.data.company.id,
              task_id: this.data.task.id,
              task_operation_id: this.data.work.id,
              discussion_id: this.data.chat.id,
              employee_id: user.employee_id
            }, this.data.company.id)
            .pipe(
              switchMap(val => {
                if ([3,4,6,97,98,99].includes(this.selectedStatus.value.stat.id)) {
                  return this.membersService.getTaskEmployeeStatuses({task_operation_id: this.data.work.id, status_id: getLessStatuses(this.selectedStatus.value.stat.id), employee_id: user.employee_id}, this.data.company.id, '2').pipe(
                    switchMap(res => {
                      console.log("RES3", res)
                      if (res.length == 1 && !res[0].discussion_id) {
                        console.log("RES4", val)
                        let questionAlert =  this.bottomSheet.open(BackJobToPrevStatusComponent, {
                          hasBackdrop: true,
                          backdropClass: 'bottom-sheed-backdrop',
                          data: {
                            msg: marker(`Do you really want to convert job personal status to: '${this.getOperationStatusName(+this.selectedStatus.value.stat.id)}'?`)
                          }
                        });
                    
                        return questionAlert.afterDismissed()
                        .pipe(
                          switchMap(data => {
                            if (data && data.message == 'no') {
                              console.log("no");
                              return of(val)
                            } else if (data && data.message == 'yes') {
                              return this.membersService.editTaskEmployeeStatus(res[0].id, {status_id: this.selectedStatus.value.stat.id}, this.data.company.id).pipe(
                                map(() => val)
                              )
                            } else {
                              return of(val)
                            }
                          })
                        )
                      } else {
                        return of(val)
                      }
                    })
                  )
                } else {
                return of(val)
                }
              })
            ).subscribe(resp => {
              console.log(resp)
              user.employeeStatus.status_id = resp.status_id;
              user.employeeStatus.status = this.data.statuses.find(x => x.id == resp.statuses_id);
              this.data.updateDiscussion();
              this.selectedIndex = 0;
            }, error => {
              this.showError(error)
            })
          )
        }
      }

    } else {
      this.selectedIndex = 1;
    }
  }
  
  addEmlChangeStatus(status) {
    console.log("addEmlChangeStatus", status)
    if (status.is_lock == 1 || status.id == this.data.chat.status_id) {
      return
    }

    if (!!this.data.iAmEmployee.employeeStatus) {
      this.attachSubscriptions(
        this.membersService.editTaskEmployeeStatus(this.data.iAmEmployee.employeeStatus.id, {status_id: status.id}, this.data.company.id).subscribe(resp => {
          this.data.updateDiscussion()
          console.log("UPDATE", this.data.chat.employeeStatus)
        })
      )
    } else {
      this.attachSubscriptions(
        this.membersService.addTaskEmployeeStatus({
          status_id: status.id,
          company_id: this.data.company.id,
          task_id: this.data.task.id,
          task_operation_id: this.data.work.id,
          discussion_id: this.data.chat.id,
          employee_id: this.data.iAmEmployee?.employee_id
        }, this.data.company.id).subscribe(resp => {
          this.data.updateDiscussion()
        })
      )
    }

  }
  
  getOperationStatusName(id) {
    switch (+id) {
      case 1:
        return "Waiting";
        break;
      case 2:
        return "In process";
        break;
      case 3:
        return "To approve";
        break;
      case 4:
        return "Approved";
        break;
      case 5:
        return "To correct";
        break;
      case 6:
        return "Sources Needed";
        break;
      case 97:
        return "On hold";
        break;
      case 98:
        return "Ready";
        break;
      case 99:
        return "Canceled";
        break;
    }
  }

  onSubmit() {
    console.log("onSubmit");
    this.attachSubscriptions(
      forkJoin(this.employee_ids.map(empl_id => {
        if (this.data.chatEmlStats.find(x => x.id == empl_id).is_partner) {
          if (!!this.data.chatEmlStats.find(x => x.id == empl_id)?.partnerCompanyStatus) {
            return this.membersService.editPartnerStatus(this.data.chatEmlStats.find(x => x.id == empl_id)?.partnerCompanyStatus.id, {status_id: this.selectedStatus.value.stat.id}, this.data.company.id).pipe(
              catchError(() => {
                this.showError("for " + this.data.chatEmlStats.find(x => x.id == empl_id).partnerCompany.name)
                return of(false)
              })
            )
          } else {
            return this.membersService.addPartnerStatus({
              status_id: this.selectedStatus.value.stat.id,
              company_id: this.data.company.id,
              task_id: this.data.task.id,
              task_operation_id: this.data.work.id,
              discussion_id: this.data.chat.id,
              partner_company_id: empl_id
            }, this.data.company.id).pipe(
              catchError(() => {
                this.showError("for " + this.data.chatEmlStats.find(x => x.id == empl_id).partnerCompany.name)
                return of(false)
              })
            )
          }
        } else if (this.data.chatEmlStats.find(x => x.id == empl_id).is_partner_employee) {
          if (!!this.data.chatEmlStats.find(x => x.id == empl_id).employeeStatus?.id) {
            return this.membersService.editPartnerEmployeeStatus(this.data.chatEmlStats.find(x => x.id == empl_id).employeeStatus.id, {status_id: this.selectedStatus.value.stat.id}, this.data.company.id).pipe(
              catchError(() => {
                this.showError("for " + this.data.chatEmlStats.find(x => x.id == empl_id)?.employee?.name)
                return of(false)
              })
            )
          } else {
            return this.membersService.addPartnerEmployeeStatus({
              status_id: this.selectedStatus.value.stat.id,
              company_id: this.data.company.id,
              task_id: this.data.task.id,
              task_operation_id: this.data.work.id,
              discussion_id: this.data.chat.id,
              partner_company_id: this.data.chatEmlStats.find(x => x.id == empl_id).partner_company_id,
              partner_employee_id: this.data.chatEmlStats.find(x => x.id == empl_id).partner_employee_id
            }, this.data.company.id).pipe(
              catchError(() => {
                this.showError("for " + this.data.chatEmlStats.find(x => x.id == empl_id)?.employee?.name)
                return of(false)
              })
            )
          }
        } else {
          if (!!this.data.chatEmlStats.find(x => x.id == empl_id)?.employeeStatus) {
            return this.membersService.editTaskEmployeeStatus(this.data.chatEmlStats.find(x => x.id == empl_id)?.employeeStatus.id, {status_id: this.selectedStatus.value.stat.id}, this.data.company.id).pipe(
              catchError(() => {
                this.showError("for " + this.data.chatEmlStats.find(x => x.id == empl_id)?.employee?.name)
                return of(false)
              })
            )
          } else {
            return this.membersService.addTaskEmployeeStatus({
              status_id: this.selectedStatus.value.stat.id,
              company_id: this.data.company.id,
              task_id: this.data.task.id,
              task_operation_id: this.data.work.id,
              discussion_id: this.data.chat.id,
              employee_id: empl_id
            }, this.data.company.id).pipe(
              catchError(() => {
                this.showError("for " + this.data.chatEmlStats.find(x => x.id == empl_id)?.employee?.name)
                return of(false)
              })
            )
          }
        }
      }))
      .pipe(
        switchMap(val => {
          console.log("VAL1", val)
          if ([3,4,98,99].includes(this.selectedStatus.value.stat.id) && !!val) {
            return this.chatService.detectChats(this.data.company.id, this.data.work.id, [1,2,5,6]).pipe(
              map(x => x.headers.get('x-pagination-total-count')),
              switchMap(res => {
                console.log("VAL2", res)
                if (res == 0) {
                  let questionAlert =  this.bottomSheet.open(BackJobToPrevStatusComponent, {
                    hasBackdrop: true,
                    backdropClass: 'bottom-sheed-backdrop',
                    data: {
                      msg: marker(`Do you really want to convert job to status: '${this.getOperationStatusName(this.selectedStatus.value.stat.id)}'?`)
                    }
                  });
              
                  return questionAlert.afterDismissed()
                  .pipe(
                    switchMap(data => {
                      if (data && data.message == 'no') {
                        console.log("no");
                        return of(val)
                      } else if (data && data.message == 'yes') {
                        return this.taskService.editWork(this.data.work.id, {status_id: this.selectedStatus.value.stat.id}, this.data.company.id).pipe(
                          map(() => val)
                        )
                      } else {
                        return of(val)
                      }
                    })
                  )
                  
                } else {
                return of(val)
                }
              })
            )
          } else {
            return of(val)
          }
        })
      ).subscribe(resp => {
        this.dialogRef.close({event: "update", data: resp})
      })
    )
    
  }

  showError(error) {
    this.layoutService.showSnackBar({name: ''}, `You do not have permission to do this. ${error}`, SnackBarItem)
  }

  onPartnerImgError(event){
    event.target.src = this.data.imgRoute+'/assets/img/partner.png'
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
