
  <div class="reports_dash">
    <form class="reports_dash_header">
        <div class="head_tit">
            {{ "YouTube Partner CMS SFTP dropboxes" | translate }}
        </div>
    </form>

    <div class="reports_wrp" [appDeviceClass]="'custom-scroll custom-scroll-t'" #tableContainer>
        <div class="report" *ngFor="let item of sftps; let i = index">
            <div class="report_line">
                <div class="column report_col">
                    <span class="big">
                        {{ item?.name }}
                    </span>
                    <button class="show_on_hover" style="color: #A3A3A3;" mat-icon-button [matMenuTriggerFor]="menuBig" aria-label="Example icon-button with a menu">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menuBig="matMenu">
                        <ng-container *ngIf="item.name && item.host && item.username && item.reports_directory && item.port && item.public_key">
                            <button mat-menu-item (click)="submitSftp(item)">
                                <mat-icon>login</mat-icon>
                                <span>{{"Submit for activation"| translate}}</span>
                            </button>
                            <p style="font-size: 10px; padding: 0 16px; font-family: 'Roboto Condensed', sans-serif;">Before to submit, copy public key and enter it in YouTube CMS</p>
                            <mat-divider></mat-divider>
                        </ng-container>
                        
                        <button mat-menu-item (click)="generateSftpKeys(item)">
                            <mat-icon>sync</mat-icon>
                            <span>{{"Generate new Keys"| translate}}</span>
                        </button>
                        <button mat-menu-item ngxClipboard [cbContent]="item.public_key" (cbOnSuccess)="copyPublicKey()">
                            <mat-icon>content_copy</mat-icon>
                            <span>{{"Copy public key"| translate}}</span>
                        </button>
                        <button mat-menu-item (click)="showSftpKeys(item)" *ngIf="item.public_key">
                            <mat-icon>visibility</mat-icon>
                            <span>{{"Show public key"| translate}}</span>
                        </button>
                        <button mat-menu-item (click)="enterSftpKeys(item)">
                            <mat-icon>vpn_key</mat-icon>
                            <span>{{"Enter own keys"| translate}}</span>
                        </button>
                        <button mat-menu-item (click)="editSftp(item)">
                            <mat-icon>edit</mat-icon>
                            <span>{{"Edit"| translate}}</span>
                        </button>
                        <button mat-menu-item (click)="deleteSftp(item, i)">
                            <mat-icon>delete</mat-icon>
                            <span>{{"Delete"| translate}}</span>
                        </button>
                    </mat-menu>
                </div>
                <div class="column report_col">
                    <span class="normal is_active" [ngClass]="{'green': item.is_active == 1}">
                        {{ item.is_active == 1 ? 'Active' : "Not active" | translate }}
                    </span>
                </div>
                <div class="column report_col">
             
                </div>
                <div class="column report_col expand">
                    <button mat-icon-button style="color: #686868;" (click)="expandItem(item)">
                        <mat-icon>
                            {{ item.isOpen ? 'expand_less' : 'expand_more' }}
                        </mat-icon>
                    </button>
                </div>
            </div>
            <div class="param_values report_sub_lines" *ngIf="item.isOpen">
                <div class="item_val">
                    <div>host:</div>
                    <div>{{item.host}}</div>
                </div>
  
                <div class="item_val">
                    <div>Username:</div>
                    <div>{{item.username}}</div>
                </div>
                <div class="item_val">
                    <div>Port:</div>
                    <div>{{item.port}}</div>
                </div>
                <div class="item_val">
                    <div>Folder:</div>
                    <div>{{item.reports_directory}}</div>
                </div>
                <div class="item_val red" *ngIf="item.last_error_message">
                    <div>Error:</div>
                    <div>{{item.last_error_message}}</div>
                </div>

                <div class="reports_add_line" style="margin-top: 15px;">
                    <div class="add_line" (click)="addSftpChannel(item)">
                        <mat-icon>add_circle</mat-icon>
                        <span class="ml-1">{{"Add Channel" | translate}}</span>
                    </div>

                    <div class="count_channs ml-1" *ngIf="!!item?.valuesCount">
                        {{item?.valuesCount}} {{"channels"|translate}}
                    </div>
                </div>
                <div class="param_value report_sub_line" *ngFor="let val of item.values; let k = index;">
                    <div class="sub_line">
                        <div class="column report_col">
                            <span class="normal">
                                <app-outlet-line [channel]="val.channel" [company_id]="company_id"></app-outlet-line>
                            </span>
                            <button class="show_on_hover" style="color: #A3A3A3;" mat-icon-button [matMenuTriggerFor]="menuSmall" aria-label="Example icon-button with a menu">
                                <mat-icon>more_vert</mat-icon>
                            </button>
                            <mat-menu #menuSmall="matMenu">
                                <button mat-menu-item (click)="deleteSftpChannel(item, val, k)">
                                    <mat-icon>delete</mat-icon>
                                    <span>{{"Delete"| translate}}</span>
                                </button>
                            </mat-menu>
                        </div>
                        <div class="column report_col">
                    
                        </div>
                        <div class="column report_col">
                  
                        </div>
                        <div class="column report_col expand">
                            <!-- <button *ngIf="val?.employee_count" mat-icon-button style="color: #686868;" (click)="expandSubItem(item, val)">
                                <mat-icon>
                                    {{ val.isOpen ? 'expand_less' : 'expand_more' }}
                                </mat-icon>
                            </button> -->
                        </div>
                    </div>
                    <!-- <div class="executors" *ngIf="val.isOpen">
                        <div class="d-f-c" *ngFor="let exec of val.executors">
                            <app-user [class]="'user_div'" [employee]="exec.employee"></app-user>
                            
                            <mat-icon (click)="editPosToEm(exec, 'is_department_view')" class="cp data_icon ml-1" [ngClass]="{'active': exec?.is_department_view}" #tooltip="matTooltip"
                            matTooltip="{{ exec?.is_department_view ? ('Can sees everyone in the department.' | translate) : ('Can`t sees everyone in the department.' | translate)}}"
                            [matTooltipPosition]="'below'">groups</mat-icon>
                            
                            <mat-icon (click)="editPosToEm(exec, 'is_department_position_view')" class="data_icon cp ml-1" [ngClass]="{'active': exec?.is_department_position_view}" #tooltip="matTooltip" 
                            matTooltip="{{ exec?.is_department_position_view ? ('Can sees everyone with the same position.' | translate) : ('Can`t sees everyone with the same position.' | translate)}}"
                            [matTooltipPosition]="'below'">group</mat-icon>

                            <mat-icon (click)="editPosToEm(exec, 'is_department_manage_prices')" class="data_icon ml-1 cp" [ngClass]="{'active': exec?.is_department_manage_prices}" #tooltip="matTooltip" 
                            matTooltip="{{ exec?.is_department_manage_prices ? ('Can change department prices' | translate) : ('Can`t change department prices' | translate)}}"
                            [matTooltipPosition]="'below'">{{!!exec?.is_department_manage_prices ? 'credit_score' : 'payment'}}</mat-icon>
                            
                            <mat-icon (click)="editPosToEm(exec, 'is_department_position_manage_prices')" class="data_icon ml-1 cp" [ngClass]="{'active': exec?.is_department_position_manage_prices}" #tooltip="matTooltip" 
                            matTooltip="{{ exec?.is_department_position_manage_prices ? ('Can change position prices' | translate) : ('Can`t change position prices' | translate)}}"
                            [matTooltipPosition]="'below'">{{!!exec?.is_department_position_manage_prices ? 'attach_money' : 'money_off'}}</mat-icon>
                        </div>
                    </div> -->
                </div>

            </div>
        </div>
    </div>
</div>

<div style="padding: 10px 0;">
    <a mat-raised-button (click)="goBack($event)">
        {{ "Back" | translate }}
    </a>

    <button class="add_btn ml-1" mat-raised-button color="primary" (click)="addSftp($event)">
        <mat-icon>add</mat-icon>
        {{ "Youtube Sftp Dropbox" | translate }}
    </button>
</div>