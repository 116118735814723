import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { HttpRestService } from '../common/http-rest.service';

@Injectable({
  providedIn: 'root'
})
export class BoardService {
  public boardTab$: BehaviorSubject<any> = new BehaviorSubject(0);
  public todoData$: Subject<any> = new Subject();
  public doneData$: Subject<any> = new Subject();
  constructor(
    private http: HttpRestService
  ) { }

  getBoardTabStream() {
    return this.boardTab$.asObservable();
  }
  
  getTodoDataStream() {
    return this.todoData$.asObservable();
  }
  
  getDoneDataStream() {
    return this.doneData$.asObservable();
  }

  getBoard(page, company_id, filter:any = false, per_page, task_operation_id?) {
    let filter_params = new HttpParams().set('page', page).set("per-page", per_page).set("company_id", company_id).set("expand","partnerCompany.avatarFile,partnerEmployee,employee,task,task.group,discussion.lastPost.employee,discussion.lastPost.file,discussion.lastPost.action.createdEmployee,discussion.lastPost.action.employee,taskOperation")

    if (task_operation_id) {
      filter_params = filter_params.set('task_operation_id', task_operation_id);
    }
    if (filter) {
      Object.keys(filter).forEach(element => {
        if (element == 'task_custom_id' && !!filter[element] && filter[element].indexOf('-') != -1) {
          let start = +filter[element].trim().split('-')[0];
          let end = +filter[element].trim().split('-')[1];
          let val = []
          for (let index = start; index <= end; index++) {
            val.push(index);
          }
          filter_params = filter_params.set(element, val.join(","));
        } else if ((element == 'no_channel_id' || element == 'channel_id' || element == 'status_id') && filter[element].length > 0) {
          filter_params = filter_params.set(element, filter[element].join(","));
        } else if ((filter[element] === '0' || filter[element] === 0) && ['is_content_url', 'group_id', 'operation_employee_id', 'client_employee_id', 'operation_partner_company_id'].includes(element)) {
          filter_params = filter_params.set(element, filter[element]);
        } else {
          if (!!filter[element] && filter[element].toString() != '' && element != 'status_id') {
            filter_params = filter_params.set(element, filter[element]);
          }
        }
      })
    }

    console.log("getBoard filter_params", filter_params, filter)

    return this.http.executeGet<any>(`/api/task-board/`, true, {
      observe: 'response',
      params: filter_params
    })
  }
}
