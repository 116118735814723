<mat-dialog-content class="mat-typography" dirEnter (dirSubmit)="!form.valid || isSubmit ? null : onSubmit()">
    <div class="container form_wrapper">
        <div class="title_modal" (click)="log()">
            {{ "Create and link new outlet with" | translate }}:
        </div>
        <form [formGroup]="form" class="form_with_pads form_with_valids">
            <mat-form-field appearance="standard" class="full_width_form h_cst_t">
                <mat-label>{{ "Choose a channel" | translate }}</mat-label>
                <mat-select [disableOptionCentering]="true" selectDir #profilesContainer formControlName="external_id" required>
                    <mat-select-trigger>
                        <app-outlet-line [channel]="getExpChannel(form.get('external_id').value)" [company_id]="data?.company_id"></app-outlet-line>
                    </mat-select-trigger>
                    <mat-option>
                        <ngx-mat-select-search 
                        placeholderLabel="{{ 'Search' | translate }}" 
                        noEntriesFoundLabel="{{ 'no matching channel found' | translate }}"
                        [formControl]="expChannelsControl"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let channel of expChannels$ | async" [value]="channel.external_id" class="cst_o">
                        <div class="d-f-c" style="justify-content: space-between; width: 100%;">
                            <app-outlet-line [channel]="channel" [company_id]="data?.company_id"></app-outlet-line>
                            
                            <a *ngIf="channel.computedUrl && channel.computedUrl != 'https://www.youtube.com/'" target="_blank" (click)="$event.stopPropagation()" [href]="channel.computedUrl">
                                <mat-icon>open_in_new</mat-icon>
                            </a>
                        </div>
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ 'Name' | translate }}</mat-label>
                <input formControlName="name" matInput placeholder="{{ 'Name' | translate }}">
            </mat-form-field>
    
            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ "Choose a platform" | translate }}</mat-label>
                <mat-select formControlName="platform_id" required>
                <mat-option>
                    <ngx-mat-select-search 
                    placeholderLabel="{{ 'Search' | translate }}" 
                    noEntriesFoundLabel="{{ 'no matching outlet found' | translate }}"
                    [formControl]="profilesMoreControl"></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let platform of platforms$ | async" [value]="platform.id">
                    <div class="platform_wrp">
                        <app-platform [platform_id]="platform?.id"></app-platform>
                        <span class="ml-1">{{ platform.name }}</span>
                    </div>
                </mat-option>
                </mat-select>
            </mat-form-field>
    
            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ 'AI description' | translate }}</mat-label>
                <textarea 
                cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="2"
                cdkAutosizeMaxRows="6" formControlName="ai_description" matInput></textarea>
                <mat-hint class="char_counter_hint" *ngIf="form.get('ai_description').value" [ngClass]="{'red': form.get('ai_description').value.length > 999999999}" align="end">{{form.get('ai_description').value.length}}</mat-hint>
            </mat-form-field>

            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ 'Link to the platform' | translate }}</mat-label>
                <input formControlName="url" matInput placeholder="{{ 'Link to the platform' | translate }}">
            </mat-form-field>

            <ng-container formGroupName="publishing_params">
                <div class="box_wrp" style="height: 40px;">
                    <mat-checkbox formControlName="made_for_kids" color="primary">{{"Made for kids" | translate}}</mat-checkbox>
                </div>
            </ng-container>

            <div class="block_w8 form_with_pads" style="margin: 20px 40px;">
                <div class="label_w8" style="margin: 0;">{{"Outlet Tags" | translate}}</div>
                <div class="file_tags_out" style="padding-bottom: 18px;">
                    <!-- <label>{{ "Outlet Tags" | translate }}: </label> -->
                    <div class="file_tags">
                        <ng-container *ngIf="parameters && parameters.length && parameters[0] && parameters[0].activeValues && parameters[0].activeValues.length">
                            <div class="file_tag" *ngFor="let tag of parameters[0].activeValues; let t = index;">
                                {{tag.value}}
                                <div class="remove_out">
                                    <div class="remove" (click)="onRemove(tag, t)">
                                        <svg style="width: 5px; height: 5px;" width="5" height="5" viewBox="0 0 5 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M5 0.503571L4.49643 0L2.5 1.99643L0.503571 0L0 0.503571L1.99643 2.5L0 4.49643L0.503571 5L2.5 3.00357L4.49643 5L5 4.49643L3.00357 2.5L5 0.503571Z" fill="white"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <div class="add_tag cp" (click)="openTargetValues()">
                            <svg style="width: 16px; height: 16px;" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 8.8H8.8V12H7.2V8.8H4V7.2H7.2V4H8.8V7.2H12M8 0C6.94943 0 5.90914 0.206926 4.93853 0.608964C3.96793 1.011 3.08601 1.60028 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.08601 14.3997 3.96793 14.989 4.93853 15.391C5.90914 15.7931 6.94943 16 8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 6.94943 15.7931 5.90914 15.391 4.93853C14.989 3.96793 14.3997 3.08601 13.6569 2.34315C12.914 1.60028 12.0321 1.011 11.0615 0.608964C10.0909 0.206926 9.05058 0 8 0Z" fill="#C4C4C4"/>
                            </svg>
                            {{ "Tag" | translate }}
                        </div>
                    </div>
                </div>
            </div>

            <div class="block_w8 form_with_pads" style="margin: 20px 40px;">
                <div class="label_w8" style="margin: 0;">{{"Link with group" | translate}}</div>
                <mat-form-field appearance="standard" class="full_width_form">
                    <mat-label>{{ 'Select group' | translate }}:</mat-label>
                    <mat-select [multiple]="true" formControlName="group_id">
                        <mat-option>
                            <ngx-mat-select-search 
                            placeholderLabel="{{ 'Search' | translate }}" 
                            noEntriesFoundLabel="{{ 'no matching group found' | translate }}"
                            [formControl]="groupMoreControl"></ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let group of groups$ | async" [value]="group.id">
                            {{ group.name | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
    
                <div class="small_chips">
                    <label>{{ "Content type" | translate }}: </label>
                    <mat-chip-list [multiple]="false" selectable formControlName="content_type_id">
                        <ng-container *ngFor="let type of contentTypes; let i = index">
                            <mat-chip *ngIf="type.id != 203" class="small_chip" (click)="selectType(type)" [value]="type.id">
                                {{ type.name | translate }}
                            </mat-chip>
                        </ng-container>
                    </mat-chip-list>
                </div>
            </div>
            
            <div class="block_w8 form_with_pads" style="margin: 20px 40px;" *ngIf="!!form.get('youtube_sftp_dropbox_id')">
                <div class="label_w8" style="margin: 0;">{{"YouTube partner CMS" | translate}}</div>
                <mat-form-field appearance="standard" class="full_width_form">
                    <mat-label>{{ 'Select SFTP' | translate }}:</mat-label>
                    <mat-select [multiple]="false" formControlName="youtube_sftp_dropbox_id">
                        <mat-option>
                            <ngx-mat-select-search 
                            placeholderLabel="{{ 'Search' | translate }}" 
                            noEntriesFoundLabel="{{ 'no matching SFTP found' | translate }}"
                            [formControl]="sftpsMoreControl"></ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let sftp of sftps$ | async" [value]="sftp.id">
                            {{ sftp.name | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

        </form>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <div class="f_w_p_btns">
        <button mat-button (click)="close()">
            {{ "Close" | translate }}
        </button>
        <button class="ml-1 d-f-btn" (click)="onSubmit()" cdkFocusInitial mat-raised-button color="primary" [disabled]="isSubmit">
            <mat-spinner class="spin_16 mr-1" *ngIf="isSubmit"></mat-spinner>  
            {{ "Add" | translate }}
        </button>
    </div>
</mat-dialog-actions>
