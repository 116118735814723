import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { forkJoin } from 'rxjs';
import { last, map, switchMap } from 'rxjs/operators';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';

@Component({
  selector: 'app-group-add',
  templateUrl: './group-add.component.html',
  styleUrls: ['./group-add.component.scss']
})
export class GroupAddComponent extends BaseClass implements OnInit, OnDestroy {
  public form: FormGroup;
  public statuses: any;
  public groups: any;
  public isFormChange: boolean = false;
  public isSubmit: boolean = false;
  public customIdValue: number;
  public isGetId: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private taskService: TaskService,
    public dialogRef: MatDialogRef<GroupAddComponent>,
    private companyService: CompanyService,
    private layoutService: LayoutService
  ) { super() }

  ngOnInit(): void {

    this.getGroupsCompany()

    this.form = this.fb.group({
      group_id: ''
    })

    this.attachSubscriptions(
      this.form.get('group_id').valueChanges.subscribe(res => {
        if (!this.form.get('custom_id')) {
          this.form.addControl('custom_id', this.fb.control(this.data.task.custom_id))
          this.form.get('custom_id').updateValueAndValidity({onlySelf:true})
        }
        this.getCustomId(res);
      })
    )

    this.attachSubscriptions(
      this.form.valueChanges.subscribe(() => this.isFormChange = true)
    )

    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        if (this.isFormChange) {
          this.layoutService.openBottomSheet(this.dialogRef);
        } else {
          this.close();
        }
      })
    )
  }

  getGroupsCompany() {
    this.attachSubscriptions(
      this.companyService.getInfiniteGroupsCompany(this.data.company_id, '1', '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.companyService.getInfiniteGroupsCompany(this.data.company_id, x).pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        this.groups = [].concat(...res);
      })
    )
  }

  pasteIdVal() {
    this.form.patchValue({
      custom_id: this.customIdValue
    })
    this.isGetId = false;
  }

  getCustomId(value) {
    this.attachSubscriptions(
      this.taskService.getCustomId(this.data.company_id, value).subscribe(resp => {
        this.customIdValue = +resp;
        this.isGetId = true;
      })
    )
  }
  
  close() {
    this.dialogRef.close({event: "close", data: false});
  }

  submitForm() {
    this.isSubmit = true;
    this.attachSubscriptions(
      this.taskService.editTask(this.data.task.id, this.form.value, this.data.company.id).subscribe(resp => {
        this.isSubmit = false;
        this.dialogRef.close({event: "Add", data: resp})
      })
    )
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
