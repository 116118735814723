<h2 mat-dialog-title class="dialog_title">
    <span class="title_sp">
        <!-- {{ "Set working time" | translate }} -->
        {{ data.day.date | calendarDate:'dayViewTitle':activeLang }}
    </span>
    <div class="ml-1" *ngIf="data.company.employees[0].timezone" style="font-size: 12px; font-weight: 300;" (click)="data.company.employees[0].timezone = ''">
        {{data.company.employees[0].timezone}}
    </div>
    <span class="day_off">
        <mat-checkbox [disableRipple]="true" class="outlet_fil_check" color="primary" [checked]="day.is_off" (change)="dayOff($event, day)">{{ "Day off" | translate }}</mat-checkbox>
    </span>
    <button style="margin-left: 100px;" mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h2>
<mat-dialog-content class="mat-typography">
    <div class="container">
        <form [formGroup]="form" class="block" *ngIf="!(!!data.company.employees[0].timezone_offset && !!data.company.employees[0].timezone)">
            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ "Time zone" | translate }}</mat-label>
                <mat-select disableOptionCentering formControlName="timezone">
                    <mat-option>
                        <ngx-mat-select-search [formControl]="timezoneFilterCtrl" noEntriesFoundLabel="'no matching time zone found'" placeholderLabel="{{ 'Find time zone' | translate }}..." ></ngx-mat-select-search>
                    </mat-option>
                    <mat-option value="no">-- {{ "My time zone is not" | translate }} --</mat-option>
                    <mat-optgroup *ngFor="let zone of filteredTimezonesGroups | async" [label]="getTimeZone(zone.value)"
                    [disabled]="zone.disabled">
                        <mat-option *ngFor="let name of zone.names" [value]="name">
                            {{name}}
                        </mat-option>
                    </mat-optgroup>
                </mat-select>
            </mat-form-field>
        
            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ 'Timezone offset' | translate }}</mat-label>
                <input [readonly]="form.get('timezone').value != 'no'" formControlName="timezone_offset" matInput placeholder="{{ 'Timezone offset' | translate }}">
            </mat-form-field>

            <div class="bottom_line" style="padding: 0 !important;">
                <button style="color: #686868; background: #fff;" (click)="close()">
                    {{ "Close" | translate }}
                </button>

                <button class="ml-1" (click)="saveEmployee()">
                    {{ "Save" | translate }}
                </button>
            </div>
        </form>
        <div class="block no_p_right" *ngIf="!!(!!data.company.employees[0].timezone_offset && !!data.company.employees[0].timezone)">
            <div class="days">
                <div class="day" [ngClass]="{'active': !!day.is_open}">
                    <div class="day_settings">
                        <div class="lines">
                            <div class="line_title">
                                {{"Working day"|translate}}
                            </div>
                            <!-- [(ngModel)]="val.timeInFormat" [defaultTime]="'00:00'" [controlOnly]="true" -->
                            <div class="line">
                                <div class="white_el">
                                    <div class="white_label">
                                        {{ "Starts at" |translate }}
                                    </div>
                                    <div class="ngx-timepicker-field-example ml-1">
                                        <ngx-timepicker-field [(ngModel)]="day.working.from" [controlOnly]="true"></ngx-timepicker-field>
                                    </div>
                                </div>
                                <span class="range_spacer">-</span>
                                <div class="white_el">
                                    <div class="white_label">
                                        {{ "Ends at" |translate }}
                                    </div>
                                    <div class="ngx-timepicker-field-example ml-1">
                                        <ngx-timepicker-field [(ngModel)]="day.working.to" [controlOnly]="true"></ngx-timepicker-field>
                                    </div>
                                </div>
                            </div>

                            <div class="line_title">
                                {{"Break(s)"|translate}}
                            </div>
                            <div class="line break_line" *ngFor="let break of day.breaks; let i = index;">
                                <div class="white_el">
                                    <div class="white_label">
                                        {{ "Starts at" |translate }}
                                    </div>
                                    <div class="ngx-timepicker-field-example ml-1">
                                        <ngx-timepicker-field [(ngModel)]="break.from" [controlOnly]="true"></ngx-timepicker-field>
                                    </div>
                                </div>
                                <span class="range_spacer">-</span>
                                <div class="white_el">
                                    <div class="white_label">
                                        {{ "Ends at" |translate }}
                                    </div>
                                    <div class="ngx-timepicker-field-example ml-1">
                                        <ngx-timepicker-field [(ngModel)]="break.to" [controlOnly]="true"></ngx-timepicker-field>
                                    </div>
                                </div>
                                <mat-icon class="delete_break" (click)="deleteBreak(i)">delete</mat-icon>
                            </div>
                            <div class="add_another" (click)="log(); addBreak()">+ {{'Add another break' | translate}}</div>
                        </div>

                        <div class="save_l">
                            <div></div>
                            <!-- <mat-checkbox [disableRipple]="true" class="outlet_fil_check" color="primary" [checked]="day.for_all" (change)="itemAll($event, day)">{{"Set for all working days" | translate}}</mat-checkbox> -->
                            <button class="w8_btn" (click)="save()">
                                {{ "Save" | translate }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>
