<h2 mat-dialog-title class="video_editor_title">
    <span (click)="logCrCards()">
        {{ "Create Cards" | translate }}
    </span>
    <div class="cp d-f-c" style="justify-content: center; width: 24px; height: 24px;" (click)="close()">
        <mat-icon>close</mat-icon>
    </div>
</h2>
<mat-dialog-content class="ve_cc_content">
    <div class="panel_options cards_active">
        <div class="panel_line panel_opt_head has_sub">
            <div class="head_sub_wrp">
                <div class="form_wrp form_scroll" [appDeviceClass]="'custom-scroll'">
                    <ng-container *ngIf="form.get('type').value == 0; else elseTemplateCustom">
                        <form [formGroup]="form">
                            <div class="d-f-c" style="justify-content: space-between; height: 24px; margin-top: 15px;">
                                <div class="ve_input_out">
                                    <ng-container *ngIf="form.get('name').value == '' && submited">
                                        <div class="req_field small"></div>
                                        <div class="req_label small">
                                            <div class="angle"></div>
                                            <span>
                                                {{ "You need to write here" | translate }}
                                            </span>
                                        </div>
                                    </ng-container>
                                    <input class="ve_input reports_white_input" [ngClass]="{'blue': data.is_input_set_ai}" (input)="cardTitleInput()" formControlName="name" matInput placeholder="{{ 'Card title' | translate }}">
                                </div>

                                <div class="tmpl_val">
                                    <div class="dark" (click)="$event.preventDefault(); $event.stopPropagation(); titleAI($event, data?.file)">
                                        <svg style="width: 12px; height: 12px;" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M5.8376 8.1C5.28064 8.1 4.7465 7.87875 4.35267 7.48492C3.95885 7.0911 3.7376 6.55695 3.7376 6C3.7376 5.44305 3.95885 4.9089 4.35267 4.51508C4.7465 4.12125 5.28064 3.9 5.8376 3.9C6.39455 3.9 6.9287 4.12125 7.32252 4.51508C7.71635 4.9089 7.9376 5.44305 7.9376 6C7.9376 6.55695 7.71635 7.0911 7.32252 7.48492C6.9287 7.87875 6.39455 8.1 5.8376 8.1ZM10.2956 6.582C10.3196 6.39 10.3376 6.198 10.3376 6C10.3376 5.802 10.3196 5.604 10.2956 5.4L11.5616 4.422C11.6756 4.332 11.7056 4.17 11.6336 4.038L10.4336 1.962C10.3616 1.83 10.1996 1.776 10.0676 1.83L8.5736 2.43C8.2616 2.196 7.9376 1.992 7.5596 1.842L7.3376 0.252C7.3136 0.108 7.1876 0 7.0376 0H4.6376C4.4876 0 4.3616 0.108 4.3376 0.252L4.1156 1.842C3.7376 1.992 3.4136 2.196 3.1016 2.43L1.6076 1.83C1.4756 1.776 1.3136 1.83 1.2416 1.962L0.0415989 4.038C-0.0364011 4.17 -0.000400981 4.332 0.113599 4.422L1.3796 5.4C1.3556 5.604 1.3376 5.802 1.3376 6C1.3376 6.198 1.3556 6.39 1.3796 6.582L0.113599 7.578C-0.000400981 7.668 -0.0364011 7.83 0.0415989 7.962L1.2416 10.038C1.3136 10.17 1.4756 10.218 1.6076 10.17L3.1016 9.564C3.4136 9.804 3.7376 10.008 4.1156 10.158L4.3376 11.748C4.3616 11.892 4.4876 12 4.6376 12H7.0376C7.1876 12 7.3136 11.892 7.3376 11.748L7.5596 10.158C7.9376 10.002 8.2616 9.804 8.5736 9.564L10.0676 10.17C10.1996 10.218 10.3616 10.17 10.4336 10.038L11.6336 7.962C11.7056 7.83 11.6756 7.668 11.5616 7.578L10.2956 6.582Z" fill="#C9C9C9"/>
                                        </svg> 
                                    </div>
                                </div>
                            </div>
                            <div class="d-f-c" style="justify-content: space-between; height: 24px; margin-top: 5px;">
                                <div class="d-f-c" style="width: 100%;">

                                    <div class="tmpl_val">
                                        <div class="tmpl_label" *ngIf="form.get('status_id').value">
                                            {{"Type" | translate}}
                                        </div>
                                        <div (click)="typesContainer.open()" class="dark text_one_line" [ngClass]="{'active': form.get('status_id').value}">
                                            {{form.get('status_id').value ? getStatusById(form.get('status_id').value)?.name : ("Type" | translate)}}
                                        </div>
         
                                        <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                                            <mat-select disableOptionCentering #typesContainer formControlName="status_id" (selectionChange)="selectType($event)">
                                                <mat-option>
                                                    <ngx-mat-select-search 
                                                    placeholderLabel="{{ 'Search' | translate }}" 
                                                    noEntriesFoundLabel="{{ 'no matching type found' | translate }}"
                                                    [formControl]="taskStatusesMoreControl"></ngx-mat-select-search>
                                                </mat-option>
                                                <mat-option *ngFor="let status of taskStatuses$ | async" [value]="status.id">
                                                    {{ status.name | translate }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>

                                    <div class="tmpl_val">
                                        <div class="tmpl_label" *ngIf="form.get('group_id').value">
                                            {{"Group" | translate}}
                                        </div>
                                        <div (click)="groupIdContainer.open()" class="dark text_one_line" [ngClass]="{'active': form.get('group_id').value}">
                                            {{form.get('group_id').value ? getGroupById(form.get('group_id').value)?.name : ("Group" | translate)}}
                                        </div>
         
                                        <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                                            <mat-select disableOptionCentering #groupIdContainer formControlName="group_id" (selectionChange)="selectGroup($event)">
                                                <mat-option>
                                                    <ngx-mat-select-search 
                                                    placeholderLabel="{{ 'Search' | translate }}" 
                                                    noEntriesFoundLabel="{{ 'no matching group found' | translate }}"
                                                    [formControl]="groupsMoreControl"></ngx-mat-select-search>
                                                </mat-option>
                                                <mat-option *ngFor="let group of groups$ | async" [value]="group.id">
                                                    {{ group.name | translate }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>

                                    <div class="tmpl_val" *ngIf="!(!!data.publications && !!data.publications.length)" (click)="$event.stopPropagation(); $event.preventDefault(); openContext($event, data.file, form.get('template_id').value ? getItemById(taskTemplates, form.get('template_id').value).template_data.group_id: form.get('group_id').value)">
                                        <div class="dark">
                                            <mat-icon>public</mat-icon>
                                        </div>
                                    </div>

                                    <div class="tmpl_val" *ngIf="!!data.publications && !!data.publications.length" (click)="$event.stopPropagation(); $event.preventDefault(); deletePublications()">
                                        <div class="dark">
                                            <mat-icon style="color: red !important;">public_off</mat-icon>
                                        </div>
                                    </div>

                                    <div class="tmpl_val">
                                        <div class="dark" (click)="addJob()">
                                            {{ 'Job' | translate }}
                                        </div>
                                    </div>
                                    <div class="tmpl_val" *ngIf="!(parameters.length && parameters[0].activeValues && parameters[0].activeValues.length)">
                                        <div class="dark" (click)="openTargetValues()">
                                            <svg style="width: 12px; height: 12px;" width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M2.1 3C2.3387 3 2.56761 2.90518 2.7364 2.7364C2.90518 2.56761 3 2.3387 3 2.1C3 1.86131 2.90518 1.63239 2.7364 1.4636C2.56761 1.29482 2.3387 1.2 2.1 1.2C1.86131 1.2 1.63239 1.29482 1.4636 1.4636C1.29482 1.63239 1.2 1.86131 1.2 2.1C1.2 2.3387 1.29482 2.56761 1.4636 2.7364C1.63239 2.90518 1.86131 3 2.1 3ZM9.246 4.548C9.462 4.764 9.6 5.064 9.6 5.4C9.6 5.73 9.468 6.03 9.246 6.246L6.246 9.246C6.03 9.462 5.73 9.6 5.4 9.6C5.07 9.6 4.77 9.468 4.548 9.246L0.354 5.052C0.132 4.83 0 4.53 0 4.2V1.2C0 0.534 0.534 0 1.2 0H4.2C4.53 0 4.83 0.132 5.046 0.348L9.246 4.548ZM6.924 1.026L7.524 0.426L11.646 4.548C11.868 4.764 12 5.07 12 5.4C12 5.73 11.868 6.03 11.652 6.246L8.424 9.474L7.824 8.874L11.25 5.4L6.924 1.026Z" fill="#C9C9C9"/>
                                            </svg> 
                                        </div>
                                    </div>

        
                                </div>

                                <div class="tmpl_val" style="flex-shrink: 0;">
                                    <div class="tmpl_label" *ngIf="form.get('template_id').value">
                                        {{"Template" | translate}}
                                    </div>
                                    <div (click)="selectTemplate.open()" class="dark text_one_line" [ngClass]="{'active': form.get('template_id').value}">
                                        {{form.get('template_id').value ? getItemById(taskTemplates, form.get('template_id').value)?.name : ("Template" | translate)}}
                                    </div>
                                    <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                                        <mat-select disableOptionCentering #selectTemplate formControlName="template_id" (selectionChange)="selectTmpl($event)">
                                            <mat-option>
                                                <ngx-mat-select-search 
                                                placeholderLabel="{{ 'Search' | translate }}" 
                                                noEntriesFoundLabel="{{ 'no matching template found' | translate }}"
                                                [formControl]="taskTemplatesControl"></ngx-mat-select-search>
                                            </mat-option>
                                            <mat-option *ngFor="let tmpl of taskTemplates$ | async" [value]="tmpl.id">
                                                {{ tmpl?.name }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>

                            <div class="file_tags_out" *ngIf="parameters.length && parameters[0].activeValues && parameters[0].activeValues.length">
                                <div class="file_tags">
                                    <div class="file_tag" *ngFor="let tag of parameters[0].activeValues; let t = index;">
                                        {{tag.value}}
                                        <div class="remove" (click)="onRemove(tag, t)">
                                            <svg style="width: 8px; height: 8px;" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8 0.805714L7.19429 0L4 3.19429L0.805714 0L0 0.805714L3.19429 4L0 7.19429L0.805714 8L4 4.80571L7.19429 8L8 7.19429L4.80571 4L8 0.805714Z" fill="#686868"/>
                                            </svg> 
                                        </div>
                                    </div>
                                    <div class="add_tag cp" (click)="openTargetValues()">
                                        <svg style="width: 16px; height: 16px;" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12 8.8H8.8V12H7.2V8.8H4V7.2H7.2V4H8.8V7.2H12M8 0C6.94943 0 5.90914 0.206926 4.93853 0.608964C3.96793 1.011 3.08601 1.60028 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.08601 14.3997 3.96793 14.989 4.93853 15.391C5.90914 15.7931 6.94943 16 8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 6.94943 15.7931 5.90914 15.391 4.93853C14.989 3.96793 14.3997 3.08601 13.6569 2.34315C12.914 1.60028 12.0321 1.011 11.0615 0.608964C10.0909 0.206926 9.05058 0 8 0Z" fill="#C4C4C4"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </form>

                        <div class="jobs_wrp form_with_pads">
                            <div class="job dark" *ngFor="let job of jobs; let i = index;">

                                
                                <div class="job_main_line">
                                    <div class="job_info">
                                        <div class="job_name text_one_line">{{job?.name}}</div>
                                        <div class="operation">{{getOperationById(job.operation_id)?.name}}</div>
                                        <div class="status" [ngClass]="{
                                        'first': job.status_id == '1',
                                        'second': job.status_id == '2',
                                        'third': job.status_id == '3',
                                        'fours': job.status_id == '4',
                                        'fives': job.status_id == '5',
                                        'sixs': job.status_id == '6',
                                        'nine': job.status_id == '97',
                                        'seven': job.status_id == '98',
                                        'eight': job.status_id == '99'}">{{getJobStatusById(job.status_id)?.name}}</div>

                                        <div class="tmpl_val">
                                            <div class="dark" (click)="openTargetValues(false, job)">
                                                <svg style="width: 12px; height: 12px;" width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M2.1 3C2.3387 3 2.56761 2.90518 2.7364 2.7364C2.90518 2.56761 3 2.3387 3 2.1C3 1.86131 2.90518 1.63239 2.7364 1.4636C2.56761 1.29482 2.3387 1.2 2.1 1.2C1.86131 1.2 1.63239 1.29482 1.4636 1.4636C1.29482 1.63239 1.2 1.86131 1.2 2.1C1.2 2.3387 1.29482 2.56761 1.4636 2.7364C1.63239 2.90518 1.86131 3 2.1 3ZM9.246 4.548C9.462 4.764 9.6 5.064 9.6 5.4C9.6 5.73 9.468 6.03 9.246 6.246L6.246 9.246C6.03 9.462 5.73 9.6 5.4 9.6C5.07 9.6 4.77 9.468 4.548 9.246L0.354 5.052C0.132 4.83 0 4.53 0 4.2V1.2C0 0.534 0.534 0 1.2 0H4.2C4.53 0 4.83 0.132 5.046 0.348L9.246 4.548ZM6.924 1.026L7.524 0.426L11.646 4.548C11.868 4.764 12 5.07 12 5.4C12 5.73 11.868 6.03 11.652 6.246L8.424 9.474L7.824 8.874L11.25 5.4L6.924 1.026Z" fill="#C9C9C9"/>
                                                </svg> 
                                            </div>
                                        </div>
                                    </div>

                                    <div class="job_right">
                                        <!-- <div class="job_executors" *ngIf="job.create_task_employees.length && employees && employees.length > 0">
                                            <div class="executor" *ngFor="let executor of job.create_task_employees; let k = index;">
                                                <app-user [class]="'user_div'" [employee]="getEmployeeById(executor.employee_id)"></app-user>
                                            </div>
                                        </div> -->

                                        <div class="employees" *ngIf="job?.mobEmployees && job?.mobEmployees?.length" [ngStyle]="{'width': 18 + (job?.mobEmployees.length > 5 ? 60 : (job?.mobEmployees.length - 1)*12) + 'px', 'min-width': 18 + (job?.mobEmployees.length > 5 ? 60 : (job?.mobEmployees.length - 1)*12) + 'px'}">
                                            <div class="more_empls">
                                                <ng-container *ngFor="let item of job?.mobEmployees; let u = index;">
                                                  <ng-container *ngIf="u <= 4">
                                                    <ng-container *ngIf="item?.employee?.image; else elseMobWorkTemplate">
                                                      <img [src]="item?.employee?.image" alt="" [ngStyle]="{'z-index': job?.mobEmployees.length - u, 'right': ((job?.mobEmployees.length > 5 ? 60 : (12*(job?.mobEmployees.length - 1))) - u*12) + 'px'}">
                                                    </ng-container>
                                                    <ng-template #elseMobWorkTemplate>
                                                      <ng-container *ngIf="item?.is_partner; else elsePartner">
                                                        <ng-container *ngIf="!!item?.partnerCompany?.avatarFile?.thumbnail && !!host; else elseTemplatePartnerAvatar">
                                                          <img #tooltip="matTooltip" [ngStyle]="{'z-index': job?.mobEmployees.length - u, 'right': ((job?.mobEmployees.length > 5 ? 60 : (12*(job?.mobEmployees.length - 1))) - u*12) + 'px'}"
                                                          matTooltip="{{item?.partnerCompany?.name}}"
                                                          [matTooltipPosition]="'below'" class="partner_avatar" (error)="onPartnerImgError($event)" [src]="host + item?.partnerCompany?.avatarFile?.thumbnail + '?company_id=' + data?.company?.id" alt="">
                                                        </ng-container>
                                                        <ng-template #elseTemplatePartnerAvatar>
                                                          <img #tooltip="matTooltip" [ngStyle]="{'z-index': job?.mobEmployees.length - u, 'right': ((job?.mobEmployees.length > 5 ? 60 : (12*(job?.mobEmployees.length - 1))) - u*12) + 'px'}"
                                                          matTooltip="{{item?.partnerCompany?.name}}"
                                                          [matTooltipPosition]="'below'" class="partner_avatar" (error)="onPartnerImgError($event)" src="{{ imgRoute }}assets/img/partner.png" alt="">
                                                        </ng-template>
                                                      </ng-container>
                                                      <ng-template #elsePartner>
                                                        <mat-icon #tooltip="matTooltip" [ngStyle]="{'z-index': job?.mobEmployees.length - u, 'right': ((job?.mobEmployees.length > 5 ? 60 : (12*(job?.mobEmployees.length - 1))) - u*12) + 'px'}"
                                                        matTooltip="{{item?.employee?.name}}"
                                                        [matTooltipPosition]="'below'">account_circle</mat-icon>
                                                      </ng-template>
                                                    </ng-template>
                                                  </ng-container>      
                                                </ng-container>
                                            </div>
                                            <ng-container *ngIf="job?.mobEmployees?.length > 5;">
                                              <div class="plus">
                                                +{{job?.mobEmployees?.length - 5}}
                                              </div>
                                            </ng-container>
                                        </div>

                                        <button style="color: #A3A3A3;" class="active_vm_btn check_btn" mat-icon-button [matMenuTriggerFor]="jobDots" aria-label="Example icon-button with a menu">
                                            <mat-icon>more_vert</mat-icon>
                                        </button>
                                        <mat-menu #jobDots="matMenu" class="ratio_value_menu_class" overlayPanelClass='ratio_value_menu' yPosition="above" xPosition="before">
                                            <button mat-menu-item (click)="editJob(job)">
                                                <mat-icon style="color: #fff;">edit</mat-icon>
                                                <span class="ml-1">{{"Edit" | translate}}</span>
                                            </button>
                                            <button mat-menu-item (click)="deleteJob(i)">
                                                <mat-icon style="color: #fff;">delete</mat-icon>
                                                <span class="ml-1">{{"Delete" | translate}}</span>
                                            </button>
                                        </mat-menu>  
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-template #elseTemplateCustom>
                        <form [formGroup]="form">
                            <div class="d-f-c" style="justify-content: space-between; height: 24px; margin-top: 15px;">
                                <div class="ve_input_out">
                                    <ng-container *ngIf="form.get('name').value == '' && submited">
                                        <div class="req_field small"></div>
                                        <div class="req_label small">
                                            <div class="angle"></div>
                                            <span>
                                                {{ "You need to write here" | translate }}
                                            </span>
                                        </div>
                                    </ng-container>
                                    <input class="ve_input reports_white_input" [ngClass]="{'blue': data.is_input_set_ai}" (input)="cardTitleInput()" formControlName="name" matInput placeholder="{{ 'Card title' | translate }}">
                                </div>
                                <div class="tmpl_val">
                                    <div class="dark" (click)="$event.preventDefault(); $event.stopPropagation(); titleAI($event, data?.file)">
                                        <svg style="width: 12px; height: 12px;" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M5.8376 8.1C5.28064 8.1 4.7465 7.87875 4.35267 7.48492C3.95885 7.0911 3.7376 6.55695 3.7376 6C3.7376 5.44305 3.95885 4.9089 4.35267 4.51508C4.7465 4.12125 5.28064 3.9 5.8376 3.9C6.39455 3.9 6.9287 4.12125 7.32252 4.51508C7.71635 4.9089 7.9376 5.44305 7.9376 6C7.9376 6.55695 7.71635 7.0911 7.32252 7.48492C6.9287 7.87875 6.39455 8.1 5.8376 8.1ZM10.2956 6.582C10.3196 6.39 10.3376 6.198 10.3376 6C10.3376 5.802 10.3196 5.604 10.2956 5.4L11.5616 4.422C11.6756 4.332 11.7056 4.17 11.6336 4.038L10.4336 1.962C10.3616 1.83 10.1996 1.776 10.0676 1.83L8.5736 2.43C8.2616 2.196 7.9376 1.992 7.5596 1.842L7.3376 0.252C7.3136 0.108 7.1876 0 7.0376 0H4.6376C4.4876 0 4.3616 0.108 4.3376 0.252L4.1156 1.842C3.7376 1.992 3.4136 2.196 3.1016 2.43L1.6076 1.83C1.4756 1.776 1.3136 1.83 1.2416 1.962L0.0415989 4.038C-0.0364011 4.17 -0.000400981 4.332 0.113599 4.422L1.3796 5.4C1.3556 5.604 1.3376 5.802 1.3376 6C1.3376 6.198 1.3556 6.39 1.3796 6.582L0.113599 7.578C-0.000400981 7.668 -0.0364011 7.83 0.0415989 7.962L1.2416 10.038C1.3136 10.17 1.4756 10.218 1.6076 10.17L3.1016 9.564C3.4136 9.804 3.7376 10.008 4.1156 10.158L4.3376 11.748C4.3616 11.892 4.4876 12 4.6376 12H7.0376C7.1876 12 7.3136 11.892 7.3376 11.748L7.5596 10.158C7.9376 10.002 8.2616 9.804 8.5736 9.564L10.0676 10.17C10.1996 10.218 10.3616 10.17 10.4336 10.038L11.6336 7.962C11.7056 7.83 11.6756 7.668 11.5616 7.578L10.2956 6.582Z" fill="#C9C9C9"/>
                                        </svg> 
                                    </div>
                                </div>
                            </div>
                            <div class="d-f-c" style="justify-content: space-between; height: 24px; margin-top: 5px;">
                                <div class="d-f-c" style="width: 100%;">
                                    <div class="tmpl_val">
                                        <div class="tmpl_label" *ngIf="form.get('template_id').value">
                                            {{"Template" | translate}}
                                        </div>
                                        <div (click)="selectTemplate.open()" class="dark text_one_line" [ngClass]="{'active': form.get('template_id').value}">
                                            {{form.get('template_id').value ? getItemById(taskTemplates, form.get('template_id').value)?.name : ("Template" | translate)}}
                                        </div>
                                        <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                                            <mat-select disableOptionCentering #selectTemplate formControlName="template_id" (selectionChange)="selectTmpl($event)">
                                                <mat-option>
                                                    <ngx-mat-select-search 
                                                    placeholderLabel="{{ 'Search' | translate }}" 
                                                    noEntriesFoundLabel="{{ 'no matching template found' | translate }}"
                                                    [formControl]="taskTemplatesControl"></ngx-mat-select-search>
                                                </mat-option>
                                                <mat-option *ngFor="let tmpl of taskTemplates$ | async" [value]="tmpl.id">
                                                    {{ tmpl?.name }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>


                                    <div class="tmpl_val" *ngIf="!(!!data.publications && !!data.publications.length)" (click)="$event.stopPropagation(); $event.preventDefault(); openContext($event, data.file, form.get('template_id').value ? getItemById(taskTemplates, form.get('template_id').value).template_data.group_id: form.get('group_id').value)">
                                        <div class="dark">
                                            <mat-icon>public</mat-icon>
                                        </div>
                                    </div>

                                    <div class="tmpl_val" *ngIf="!!data.publications && !!data.publications.length" (click)="$event.stopPropagation(); $event.preventDefault(); deletePublications()">
                                        <div class="dark">
                                            <mat-icon style="color: red !important;">public_off</mat-icon>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </form>
                    </ng-template>
                </div>
            </div>


            <div class="fragments_head need_padding_left">
                <!-- (click)="cut.is_audio_only = !!cut.is_audio_only ? 0 : 1" -->
                <div class="fragments_head_btns" *ngIf="data.saveMode.value == 'parts'">
                    <button class="check_btn small" mat-icon-button [disableRipple]="true" [ngClass]="{'changing': hasValue() && !isAllSelected()}" (click)="masterToggle()">
                        <ng-container *ngIf="hasValue() && isAllSelected(); else elseTemplateIsAud">
                            <mat-icon>audiotrack</mat-icon>
                        </ng-container>
                        <ng-template #elseTemplateIsAud>
                            <mat-icon>music_off</mat-icon>
                        </ng-template>
                    </button>
                    <button class="check_btn small" mat-icon-button [disableRipple]="true" [ngClass]="{'changing': hasValueVid() && !isAllSelectedVid()}" (click)="masterToggleVid()">
                        <ng-container *ngIf="hasValueVid() && isAllSelectedVid(); else elseTemplateIsVid">
                            <mat-icon>videocam</mat-icon>
                        </ng-container>
                        <ng-template #elseTemplateIsVid>
                            <mat-icon>videocam_off</mat-icon>
                        </ng-template>
                    </button>
                </div>
                
                <input [(ngModel)]="data.file.filename" class="proj_name reports_white_input" matInput placeholder="{{ 'Project Name' | translate }}">

                <div class="fragments_head_info">
                    <div class="project_duration">
                        {{data.duration|veTime}}
                    </div>
                    <div class="parts_count">
                        {{getVideoParts().length}} {{"files" | translate}}
                    </div>
                </div>
            </div>
        </div>
        
        <div class="panel_options_out" [appDeviceClass]="'custom-scroll'">
            <div class="panel_options_in">
                <ng-container *ngIf="hasParts() && data.saveMode.value == 'parts'">
                    <div class="post_wrp d-f-c" style="margin: 12px;" *ngIf="data.vidProject && data.vidProject.batch_data && data.vidProject.batch_data.parameter_id && data.vidProject.batch_data.headCols && data.vidProject.batch_data.headCols.length && data.publications && data.publications.length">
                        <div class="ve_input_out">
                            <!-- {{checkIfGetPostMainNames()}} -->
                        </div>
                        <ng-container *ngIf="checkIfGetPostMainNames()">
                            <div class="tmpl_val" (click)="$event.stopPropagation(); $event.preventDefault(); openTextContext($event, data.file)">
                                <div class="dark">
                                    <mat-icon>format_size</mat-icon>
                                </div>
                            </div>
                            <div class="tmpl_val mr-5" (click)="$event.stopPropagation(); $event.preventDefault(); openPostsNames()">
                                <div class="dark">
                                    <mat-icon>view_column</mat-icon>
                                </div>
                            </div>
                            <div class="tmpl_val mr-5" (click)="$event.stopPropagation(); $event.preventDefault(); getTranslations()">
                                <div class="dark">
                                    <mat-spinner class="spin_16" *ngIf="!!isGettingTranslations"></mat-spinner> 
                                    <mat-icon style="color: #2F80ED !important;" *ngIf="!isGettingTranslations">translate</mat-icon>
                                </div>
                            </div>
                        </ng-container>
                        <mat-select [(ngModel)]="activeTag" class="select_tag" (selectionChange)="changeAllTags($event)">
                            <mat-option [value]="0">{{"Main"|translate}}</mat-option>
                            <mat-option *ngFor="let headCol of data.vidProject.batch_data.selectedCols" [value]="headCol">{{getValuesById(headCol)?.value}}</mat-option>
                        </mat-select>
                    </div>
                    <ng-container *ngFor="let trackEl of data.tracks">
                        <ng-container *ngIf="trackEl.type == 'video'">
                            <ng-container *ngFor="let cut of trackEl.parts; let i = index;">
                                <div class="panel_option_out forms_activated">
                                    <div class="fragments_head panel_option">
                                        <button class="check_btn small" mat-icon-button (click)="cut.is_audio_only = !!cut.is_audio_only ? 0 : 1" [disableRipple]="true">
                                            <ng-container *ngIf="!!cut.is_audio_only; else elseTemplateIsAud">
                                                <mat-icon>audiotrack</mat-icon>
                                            </ng-container>
                                            <ng-template #elseTemplateIsAud>
                                                <mat-icon>music_off</mat-icon>
                                            </ng-template>
                                        </button>
                                        <button class="check_btn small" mat-icon-button (click)="cut.is_video_only = !!cut.is_video_only ? 0 : 1" [disableRipple]="true">
                                            <ng-container *ngIf="!!cut.is_video_only; else elseTemplateIsVid">
                                                <mat-icon>videocam</mat-icon>
                                            </ng-container>
                                            <ng-template #elseTemplateIsVid>
                                                <mat-icon>videocam_off</mat-icon>
                                            </ng-template>
                                        </button>
                                        <button class="check_btn small" [ngClass]="{'visible_on_hover': !(playCrCardsCut && playCrCardsCut.pId == cut.pId)}" mat-icon-button (click)="playVidPart(cut)" [disableRipple]="true">
                                            <ng-container *ngIf="playCrCardsCut && playCrCardsCut.pId == cut.pId; else elseTemplatePlayedCrCards">
                                                <mat-icon>pause_circle</mat-icon>
                                            </ng-container>
                                            <ng-template #elseTemplatePlayedCrCards>
                                                <mat-icon>play_circle</mat-icon>
                                            </ng-template>
                                        </button>

                                        <div class="proj_name_out">
                                            <input [(ngModel)]="cut.filename" class="proj_name reports_white_input" matInput placeholder="{{ 'File Name' | translate }}">
                                            <div class="result_file_tags">
                                                <svg class="res_icon" width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M1.75 2.5C1.94891 2.5 2.13968 2.42098 2.28033 2.28033C2.42098 2.13968 2.5 1.94891 2.5 1.75C2.5 1.55109 2.42098 1.36032 2.28033 1.21967C2.13968 1.07902 1.94891 1 1.75 1C1.55109 1 1.36032 1.07902 1.21967 1.21967C1.07902 1.36032 1 1.55109 1 1.75C1 1.94891 1.07902 2.13968 1.21967 2.28033C1.36032 2.42098 1.55109 2.5 1.75 2.5ZM7.705 3.79C7.885 3.97 8 4.22 8 4.5C8 4.775 7.89 5.025 7.705 5.205L5.205 7.705C5.025 7.885 4.775 8 4.5 8C4.225 8 3.975 7.89 3.79 7.705L0.295 4.21C0.11 4.025 0 3.775 0 3.5V1C0 0.445 0.445 0 1 0H3.5C3.775 0 4.025 0.11 4.205 0.29L7.705 3.79ZM5.77 0.855L6.27 0.355L9.705 3.79C9.89 3.97 10 4.225 10 4.5C10 4.775 9.89 5.025 9.71 5.205L7.02 7.895L6.52 7.395L9.375 4.5L5.77 0.855Z" fill="#C9C9C9"/>
                                                </svg> 
                                                <ng-container *ngIf="cut.tags">
                                                    <div class="res_file_tag" *ngFor="let tag of cut.tags; let t = index;">
                                                        {{tag?.value}}
                                                        <div class="remove" (click)="onRemoveCut(cut, t)">
                                                            <svg style="width: 5px; height: 5px;" width="5" height="5" viewBox="0 0 5 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M5 0.503571L4.49643 0L2.5 1.99643L0.503571 0L0 0.503571L1.99643 2.5L0 4.49643L0.503571 5L2.5 3.00357L4.49643 5L5 4.49643L3.00357 2.5L5 0.503571Z" fill="white"/>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                                <div class="add_tag cp" (click)="openCutTargetValues(cut)">
                                                    <svg style="width: 14px; height: 14px;" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M12 8.8H8.8V12H7.2V8.8H4V7.2H7.2V4H8.8V7.2H12M8 0C6.94943 0 5.90914 0.206926 4.93853 0.608964C3.96793 1.011 3.08601 1.60028 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.08601 14.3997 3.96793 14.989 4.93853 15.391C5.90914 15.7931 6.94943 16 8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 6.94943 15.7931 5.90914 15.391 4.93853C14.989 3.96793 14.3997 3.08601 13.6569 2.34315C12.914 1.60028 12.0321 1.011 11.0615 0.608964C10.0909 0.206926 9.05058 0 8 0Z" fill="#C4C4C4"/>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                
                                        <div class="fragments_head_info">

                                            <div class="opt_block">
                                                <div>&nbsp;</div>
                                                <div>{{cut.duration | veTime}}</div>
                                            </div>
                                            <div class="opt_block">
                                                <div>{{"from" | translate}}</div>
                                                <div>{{cut.from | veTime}}</div>
                                            </div>
                                            <div class="opt_block" style="margin-left: 4px !important;">
                                                <div>&nbsp;</div>
                                                <div>-</div>
                                            </div>
                                            
                                            <div class="opt_block" style="margin-left: 4px !important;">
                                                <div>{{"to" | translate}}</div>
                                                <div>{{cut.to | veTime}}</div>
                                            </div>
                                        </div>

                                        <div class="is_custom_fragment" [ngClass]="{'active': !!cut.is_custom.value, 'show_on_hover': !cut.is_custom.value}" (click)="toggleControl(cut.is_custom, cut.is_custom.value)">
                                            <mat-icon>edit</mat-icon>
                                        </div>
<!-- 
                                        <mat-checkbox class="mr-1 select_all_checkbox" [formControl]="cut.is_custom" [disableRipple]="true">
                                            <span class="ml-1">{{ "Custimize" | translate }}</span>
                                        </mat-checkbox> -->
                                    </div>

                                    <div class="panel_form" [ngClass]="{'need-transform': cut.form && cut.form.get('type').value == 1}">
                                        <div class="form_in">
                                            <form *ngIf="cut.form && (!!cut.is_video_only || !!cut.is_audio_only)" [formGroup]="cut.form" class="form_with_pads form_with_valids" style="align-items: flex-start;">                                            
                                                <ng-container *ngIf="cut.form.get('type').value == 0; else elseTemplateCustomPanel">
                                                    
                                                    <div class="d-f-c" style="justify-content: space-between; height: 24px; margin-top: 15px;">
                                                        <div class="ve_input_out">
                                                            <ng-container *ngIf="cut.form.get('name').value == '' && submited">
                                                                <div class="req_field small"></div>
                                                                <div class="req_label small">
                                                                    <div class="angle"></div>
                                                                    <span>
                                                                        {{ "You need to write here" | translate }}
                                                                    </span>
                                                                </div>
                                                            </ng-container>
                                                            <input class="ve_input reports_white_input" [ngClass]="{'blue': cut?.is_input_set_ai}" (input)="cardTitleInput(cut)" formControlName="name" matInput placeholder="{{ 'Card title' | translate }}">
                                                        </div>
                                                        <div class="tmpl_val" *ngIf="cut?.is_input_set_ai">
                                                            <div class="">
                                                                <div class="d-f-c" style="width: 12px; height: 12px" [ngClass]="{'rotateble': cut?.isGettingResults}" (click)="$event.preventDefault(); $event.stopPropagation(); titleAI($event, cut, true)">
                                                                    <svg style="width: 12px; height: 12px" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#C9C9C9"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 6v3l4-4-4-4v3c-4.42 0-8 3.58-8 8 0 1.57.46 3.03 1.24 4.26L6.7 14.8c-.45-.83-.7-1.79-.7-2.8 0-3.31 2.69-6 6-6zm6.76 1.74L17.3 9.2c.44.84.7 1.79.7 2.8 0 3.31-2.69 6-6 6v-3l-4 4 4 4v-3c4.42 0 8-3.58 8-8 0-1.57-.46-3.03-1.24-4.26z"/></svg>
                                                                </div>
                                                                <input class="name_inp reports_white_input" type="text" (change)="changeOptions($event, cut)" [value]="cut.optionsCount">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="d-f-c" style="justify-content: space-between; height: 24px; margin-top: 5px;">
                                                        <div class="d-f-c" style="width: 100%;">
            
                                                            <div class="tmpl_val">
                                                                <div class="tmpl_label" *ngIf="cut.form.get('status_id').value">
                                                                    {{"Type" | translate}}
                                                                </div>
                                                                <div (click)="typesID.open()" class="text_one_line" [ngClass]="{'active': cut.form.get('status_id').value}">
                                                                    {{cut.form.get('status_id').value ? getStatusById(cut.form.get('status_id').value)?.name : ("Type" | translate)}}
                                                                </div>
                                 
                                                                <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                                                                    <mat-select disableOptionCentering #typesID formControlName="status_id" (selectionChange)="selectType($event, cut)">
                                                                        <mat-option>
                                                                            <ngx-mat-select-search 
                                                                            placeholderLabel="{{ 'Search' | translate }}" 
                                                                            noEntriesFoundLabel="{{ 'no matching type found' | translate }}"
                                                                            [formControl]="taskStatusesMoreControl"></ngx-mat-select-search>
                                                                        </mat-option>
                                                                        <mat-option *ngFor="let status of taskStatuses$ | async" [value]="status.id">
                                                                            {{ status.name | translate }}
                                                                        </mat-option>
                                                                    </mat-select>
                                                                </mat-form-field>
                                                            </div>
                
                                                            <div class="tmpl_val">
                                                                <div class="tmpl_label" *ngIf="cut.form.get('group_id').value">
                                                                    {{"Group" | translate}}
                                                                </div>
                                                                <div (click)="groupId.open()" class="text_one_line" [ngClass]="{'active': cut.form.get('group_id').value}">
                                                                    {{cut.form.get('group_id').value ? getGroupById(cut.form.get('group_id').value)?.name : ("Group" | translate)}}
                                                                </div>
                                 
                                                                <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                                                                    <mat-select disableOptionCentering #groupId formControlName="group_id" (selectionChange)="selectGroup($event, cut)">
                                                                        <mat-option>
                                                                            <ngx-mat-select-search 
                                                                            placeholderLabel="{{ 'Search' | translate }}" 
                                                                            noEntriesFoundLabel="{{ 'no matching group found' | translate }}"
                                                                            [formControl]="groupsMoreControl"></ngx-mat-select-search>
                                                                        </mat-option>
                                                                        <mat-option *ngFor="let group of groups$ | async" [value]="group.id">
                                                                            {{ group.name | translate }}
                                                                        </mat-option>
                                                                    </mat-select>
                                                                </mat-form-field>
                                                            </div>
            
                                                            
                                                            <div class="tmpl_val" *ngIf="!!cut.publications && !!cut.publications.length">
                                                                <div>
                                                                    <mat-icon style="color: blue !important;">public</mat-icon>
                                                                </div>
                                                            </div>

                                                            <div class="tmpl_val" *ngIf="!!cut.publications && !!cut.publications.length" (click)="$event.stopPropagation(); $event.preventDefault(); deletePublications(cut)">
                                                                <div>
                                                                    <mat-icon style="color: red !important;">public_off</mat-icon>
                                                                </div>
                                                            </div>
            
                                                            <div class="tmpl_val">
                                                                <div (click)="addJob(cut)">
                                                                    {{ 'Job' | translate }}
                                                                </div>
                                                            </div>
                                                            <div class="tmpl_val" *ngIf="!(cut.parameters.length && cut.parameters[0].activeValues && cut.parameters[0].activeValues.length)">
                                                                <div (click)="openTargetValues(cut)">
                                                                    <svg style="width: 12px; height: 12px;" width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M2.1 3C2.3387 3 2.56761 2.90518 2.7364 2.7364C2.90518 2.56761 3 2.3387 3 2.1C3 1.86131 2.90518 1.63239 2.7364 1.4636C2.56761 1.29482 2.3387 1.2 2.1 1.2C1.86131 1.2 1.63239 1.29482 1.4636 1.4636C1.29482 1.63239 1.2 1.86131 1.2 2.1C1.2 2.3387 1.29482 2.56761 1.4636 2.7364C1.63239 2.90518 1.86131 3 2.1 3ZM9.246 4.548C9.462 4.764 9.6 5.064 9.6 5.4C9.6 5.73 9.468 6.03 9.246 6.246L6.246 9.246C6.03 9.462 5.73 9.6 5.4 9.6C5.07 9.6 4.77 9.468 4.548 9.246L0.354 5.052C0.132 4.83 0 4.53 0 4.2V1.2C0 0.534 0.534 0 1.2 0H4.2C4.53 0 4.83 0.132 5.046 0.348L9.246 4.548ZM6.924 1.026L7.524 0.426L11.646 4.548C11.868 4.764 12 5.07 12 5.4C12 5.73 11.868 6.03 11.652 6.246L8.424 9.474L7.824 8.874L11.25 5.4L6.924 1.026Z" fill="#C9C9C9"/>
                                                                    </svg> 
                                                                </div>
                                                            </div>
            
        
                                                        </div>
            
                                                        <div class="tmpl_val" style="flex-shrink: 0;">
                                                            <div class="tmpl_label" *ngIf="cut.form.get('template_id').value">
                                                                {{"Template" | translate}}
                                                            </div>
                                                            <div (click)="selectTemplateCut.open()" class="text_one_line" [ngClass]="{'active': cut.form.get('template_id').value}">
                                                                {{cut.form.get('template_id').value ? getItemById(taskTemplates, cut.form.get('template_id').value)?.name : ("Template" | translate)}}
                                                            </div>
                                                            <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                                                                <mat-select disableOptionCentering #selectTemplateCut formControlName="template_id" (selectionChange)="selectTmpl($event, cut)">
                                                                    <mat-option>
                                                                        <ngx-mat-select-search 
                                                                        placeholderLabel="{{ 'Search' | translate }}" 
                                                                        noEntriesFoundLabel="{{ 'no matching template found' | translate }}"
                                                                        [formControl]="taskTemplatesControl"></ngx-mat-select-search>
                                                                    </mat-option>
                                                                    <mat-option *ngFor="let tmpl of taskTemplates$ | async" [value]="tmpl.id">
                                                                        {{ tmpl?.name }}
                                                                    </mat-option>
                                                                </mat-select>
                                                            </mat-form-field>
                                                        </div>
                                                    </div>

                                                    <div class="file_tags_out" *ngIf="cut.parameters.length && cut.parameters[0].activeValues && cut.parameters[0].activeValues.length">
                                                        <div class="file_tags">
                                                            <ng-container >
                                                                <div class="file_tag" *ngFor="let tag of cut.parameters[0].activeValues; let t = index;">
                                                                    {{tag.value}}
                                                                    <div class="remove_out">
                                                                        <div class="remove" (click)="onRemove(tag, t, cut)">
                                                                            <svg style="width: 5px; height: 5px;" width="5" height="5" viewBox="0 0 5 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M5 0.503571L4.49643 0L2.5 1.99643L0.503571 0L0 0.503571L1.99643 2.5L0 4.49643L0.503571 5L2.5 3.00357L4.49643 5L5 4.49643L3.00357 2.5L5 0.503571Z" fill="white"/>
                                                                            </svg>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </ng-container>
                                                            <div class="add_tag cp" (click)="openTargetValues(cut)">
                                                                <svg style="width: 16px; height: 16px;" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M12 8.8H8.8V12H7.2V8.8H4V7.2H7.2V4H8.8V7.2H12M8 0C6.94943 0 5.90914 0.206926 4.93853 0.608964C3.96793 1.011 3.08601 1.60028 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.08601 14.3997 3.96793 14.989 4.93853 15.391C5.90914 15.7931 6.94943 16 8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 6.94943 15.7931 5.90914 15.391 4.93853C14.989 3.96793 14.3997 3.08601 13.6569 2.34315C12.914 1.60028 12.0321 1.011 11.0615 0.608964C10.0909 0.206926 9.05058 0 8 0Z" fill="#C4C4C4"/>
                                                                </svg>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                                <ng-template #elseTemplateCustomPanel>
                                                    <div class="d-f-c" style="justify-content: space-between; height: 24px; margin-top: 15px;">
                                                        <div class="ve_input_out">
                                                            <ng-container *ngIf="cut.form.get('name').value == '' && submited">
                                                                <div class="req_field small"></div>
                                                                <div class="req_label small">
                                                                    <div class="angle"></div>
                                                                    <span>
                                                                        {{ "You need to write here" | translate }}
                                                                    </span>
                                                                </div>
                                                            </ng-container>
                                                            <input class="ve_input reports_white_input" [ngClass]="{'blue': cut?.is_input_set_ai}" (input)="cardTitleInput(cut)" formControlName="name" matInput placeholder="{{ 'Card title' | translate }}">
                                                        </div>
                                                        <div class="tmpl_val" *ngIf="cut?.is_input_set_ai">
                                                            <div class="">
                                                                <div class="d-f-c" style="width: 12px; height: 12px" [ngClass]="{'rotateble': cut?.isGettingResults}" (click)="$event.preventDefault(); $event.stopPropagation(); titleAI($event, cut, true)">
                                                                    <svg style="width: 12px; height: 12px" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#C9C9C9"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 6v3l4-4-4-4v3c-4.42 0-8 3.58-8 8 0 1.57.46 3.03 1.24 4.26L6.7 14.8c-.45-.83-.7-1.79-.7-2.8 0-3.31 2.69-6 6-6zm6.76 1.74L17.3 9.2c.44.84.7 1.79.7 2.8 0 3.31-2.69 6-6 6v-3l-4 4 4 4v-3c4.42 0 8-3.58 8-8 0-1.57-.46-3.03-1.24-4.26z"/></svg>
                                                                </div>
                                                                <input class="name_inp reports_white_input" type="text" (change)="changeOptions($event, cut)" [value]="cut.optionsCount">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="d-f-c" style="justify-content: space-between; height: 24px; margin-top: 5px;">
                                                        <div class="d-f-c" style="width: 100%;">
                                                            <div class="tmpl_val">
                                                                <div class="tmpl_label" *ngIf="cut.form.get('template_id').value">
                                                                    {{"Template" | translate}}
                                                                </div>
                                                                <div (click)="selectTemplateCut.open()" class="text_one_line" [ngClass]="{'active': cut.form.get('template_id').value}">
                                                                    {{cut.form.get('template_id').value ? getItemById(taskTemplates, cut.form.get('template_id').value)?.name : ("Template" | translate)}}
                                                                </div>
                                                                <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                                                                    <mat-select disableOptionCentering #selectTemplateCut formControlName="template_id" (selectionChange)="selectTmpl($event, cut)">
                                                                        <mat-option>
                                                                            <ngx-mat-select-search 
                                                                            placeholderLabel="{{ 'Search' | translate }}" 
                                                                            noEntriesFoundLabel="{{ 'no matching template found' | translate }}"
                                                                            [formControl]="taskTemplatesControl"></ngx-mat-select-search>
                                                                        </mat-option>
                                                                        <mat-option *ngFor="let tmpl of taskTemplates$ | async" [value]="tmpl.id">
                                                                            {{ tmpl?.name }}
                                                                        </mat-option>
                                                                    </mat-select>
                                                                </mat-form-field>
                                                            </div>
                    
                                                            <div class="tmpl_val" *ngIf="!!cut.publications && !!cut.publications.length">
                                                                <div>
                                                                    <mat-icon style="color: blue !important;">public</mat-icon>
                                                                </div>
                                                            </div>
                    
                                                            <div class="tmpl_val" *ngIf="!!cut.publications && !!cut.publications.length" (click)="$event.stopPropagation(); $event.preventDefault(); deletePublications(cut)">
                                                                <div>
                                                                    <mat-icon style="color: red !important;">public_off</mat-icon>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </ng-template>
                                            </form>
                                            <div class="post_wrp d-f-c" *ngIf="data.vidProject && cut.postNames && data.vidProject.batch_data && data.vidProject.batch_data.parameter_id && data.vidProject.batch_data.headCols && data.vidProject.batch_data.headCols.length && !!cut.publications && !!cut.publications.length">
                                                <div class="ve_input_out">
                                                    <input class="ve_input reports_white_input" [(ngModel)]="cut.postNames[cut.activeTag].name" matInput placeholder="{{ 'Post title' | translate }}">
                                                </div>
                                                <mat-select [(ngModel)]="cut.activeTag" class="select_tag">
                                                    <mat-option [value]="0">{{"Main"|translate}}</mat-option>
                                                    <mat-option *ngFor="let headCol of data.vidProject.batch_data.selectedCols" [value]="headCol">{{getValuesById(headCol)?.value}}</mat-option>
                                                </mat-select>
                                            </div>
                                            <ng-container *ngIf="cut.form.get('type').value == 0">
                                                <div class="jobs_wrp form_with_pads">
                                                    <div class="job" *ngFor="let job of cut.jobs; let j = index;"> 
                                                        <div class="job_main_line">
                                                            <div class="job_info">
                                                                <div class="job_name text_one_line">{{job?.name}}</div>
                                                                <div class="operation">{{getOperationById(job.operation_id)?.name}}</div>
                                                                <div class="status" [ngClass]="{
                                                                'first': job.status_id == '1',
                                                                'second': job.status_id == '2',
                                                                'third': job.status_id == '3',
                                                                'fours': job.status_id == '4',
                                                                'fives': job.status_id == '5',
                                                                'sixs': job.status_id == '6',
                                                                'nine': job.status_id == '97',
                                                                'seven': job.status_id == '98',
                                                                'eight': job.status_id == '99'}">{{getJobStatusById(job.status_id)?.name}}</div>
            
                                                                <div class="tmpl_val">
                                                                    <div class="" (click)="openTargetValues(cut, job)">
                                                                        <svg style="width: 12px; height: 12px;" width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M2.1 3C2.3387 3 2.56761 2.90518 2.7364 2.7364C2.90518 2.56761 3 2.3387 3 2.1C3 1.86131 2.90518 1.63239 2.7364 1.4636C2.56761 1.29482 2.3387 1.2 2.1 1.2C1.86131 1.2 1.63239 1.29482 1.4636 1.4636C1.29482 1.63239 1.2 1.86131 1.2 2.1C1.2 2.3387 1.29482 2.56761 1.4636 2.7364C1.63239 2.90518 1.86131 3 2.1 3ZM9.246 4.548C9.462 4.764 9.6 5.064 9.6 5.4C9.6 5.73 9.468 6.03 9.246 6.246L6.246 9.246C6.03 9.462 5.73 9.6 5.4 9.6C5.07 9.6 4.77 9.468 4.548 9.246L0.354 5.052C0.132 4.83 0 4.53 0 4.2V1.2C0 0.534 0.534 0 1.2 0H4.2C4.53 0 4.83 0.132 5.046 0.348L9.246 4.548ZM6.924 1.026L7.524 0.426L11.646 4.548C11.868 4.764 12 5.07 12 5.4C12 5.73 11.868 6.03 11.652 6.246L8.424 9.474L7.824 8.874L11.25 5.4L6.924 1.026Z" fill="#C9C9C9"/>
                                                                        </svg> 
                                                                    </div>
                                                                </div>
                                                            </div>
            
                                                            <div class="job_right">
                                      
            
                                                                <div class="employees" *ngIf="job?.mobEmployees && job?.mobEmployees?.length" [ngStyle]="{'width': 18 + (job?.mobEmployees.length > 5 ? 60 : (job?.mobEmployees.length - 1)*12) + 'px', 'min-width': 18 + (job?.mobEmployees.length > 5 ? 60 : (job?.mobEmployees.length - 1)*12) + 'px'}">
                                                                    <div class="more_empls">
                                                                        <ng-container *ngFor="let item of job?.mobEmployees; let u = index;">
                                                                          <ng-container *ngIf="u <= 4">
                                                                            <ng-container *ngIf="item?.employee?.image; else elseMobWorkTemplate">
                                                                              <img [src]="item?.employee?.image" alt="" [ngStyle]="{'z-index': job?.mobEmployees.length - u, 'right': ((job?.mobEmployees.length > 5 ? 60 : (12*(job?.mobEmployees.length - 1))) - u*12) + 'px'}">
                                                                            </ng-container>
                                                                            <ng-template #elseMobWorkTemplate>
                                                                              <ng-container *ngIf="item?.is_partner; else elsePartner">
                                                                                <ng-container *ngIf="!!item?.partnerCompany?.avatarFile?.thumbnail && !!host; else elseTemplatePartnerAvatar">
                                                                                  <img #tooltip="matTooltip" [ngStyle]="{'z-index': job?.mobEmployees.length - u, 'right': ((job?.mobEmployees.length > 5 ? 60 : (12*(job?.mobEmployees.length - 1))) - u*12) + 'px'}"
                                                                                  matTooltip="{{item?.partnerCompany?.name}}"
                                                                                  [matTooltipPosition]="'below'" class="partner_avatar" (error)="onPartnerImgError($event)" [src]="host + item?.partnerCompany?.avatarFile?.thumbnail + '?company_id=' + data?.company?.id" alt="">
                                                                                </ng-container>
                                                                                <ng-template #elseTemplatePartnerAvatar>
                                                                                  <img #tooltip="matTooltip" [ngStyle]="{'z-index': job?.mobEmployees.length - u, 'right': ((job?.mobEmployees.length > 5 ? 60 : (12*(job?.mobEmployees.length - 1))) - u*12) + 'px'}"
                                                                                  matTooltip="{{item?.partnerCompany?.name}}"
                                                                                  [matTooltipPosition]="'below'" class="partner_avatar" (error)="onPartnerImgError($event)" src="{{ imgRoute }}assets/img/partner.png" alt="">
                                                                                </ng-template>
                                                                              </ng-container>
                                                                              <ng-template #elsePartner>
                                                                                <mat-icon #tooltip="matTooltip" [ngStyle]="{'z-index': job?.mobEmployees.length - u, 'right': ((job?.mobEmployees.length > 5 ? 60 : (12*(job?.mobEmployees.length - 1))) - u*12) + 'px'}"
                                                                                matTooltip="{{item?.employee?.name}}"
                                                                                [matTooltipPosition]="'below'">account_circle</mat-icon>
                                                                              </ng-template>
                                                                            </ng-template>
                                                                          </ng-container>      
                                                                        </ng-container>
                                                                    </div>
                                                                    <ng-container *ngIf="job?.mobEmployees?.length > 5;">
                                                                      <div class="plus">
                                                                        +{{job?.mobEmployees?.length - 5}}
                                                                      </div>
                                                                    </ng-container>
                                                                </div>
            
                                                                <button style="color: #A3A3A3;" class="active_vm_btn check_btn" mat-icon-button [matMenuTriggerFor]="jobDots" aria-label="Example icon-button with a menu">
                                                                    <mat-icon>more_vert</mat-icon>
                                                                </button>
                                                                <mat-menu #jobDots="matMenu" class="ratio_value_menu_class" overlayPanelClass='ratio_value_menu' yPosition="above" xPosition="before">
                                                                    <button mat-menu-item (click)="editJob(job, cut)">
                                                                        <mat-icon style="color: #fff;">edit</mat-icon>
                                                                        <span class="ml-1">{{"Edit" | translate}}</span>
                                                                    </button>
                                                                    <button mat-menu-item (click)="deleteJob(j, cut)">
                                                                        <mat-icon style="color: #fff;">delete</mat-icon>
                                                                        <span class="ml-1">{{"Delete" | translate}}</span>
                                                                    </button>
                                                                </mat-menu>  
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>                                                     
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </div>
</mat-dialog-content>
<!-- <mat-dialog-actions align="end">
    <button class="ml-1" mat-raised-button>
        {{ "Save" | translate }}
    </button>
</mat-dialog-actions> -->


<ng-template #contextMenu let-contextdata>
	<section class="dark_menu with_search" [ngClass]="{'ai_menu': !!contextdata?.is_ai, with_mistake: !!aiMistake}">
        <ng-container *ngIf="!!contextdata?.is_ai; else elseTemplateIsAiMenu">
            <ng-container *ngIf="aiResults && aiResults.length; else elseTemplateHasResHead">
                <div class="context_head no_focus">
                    <div class="cont_title">
                        <div>{{aiResults.length}} {{"another options" | translate}}</div>
                        <div class="cp" (click)="$event.preventDefault(); $event.stopPropagation(); aiResults = []; aiMistake = undefined;">
                            <mat-icon style="color: #C9C9C9;">arrow_back_ios</mat-icon>
                        </div>
                    </div>
                    <div class="cont_desc">
                        {{"Tap option to apply" | translate}}
                    </div>
                </div>
            </ng-container>
            <ng-template #elseTemplateHasResHead>
                <ng-container *ngIf="!!aiMistake; else elseTemplateHasMistake">
                    <div class="context_head no_focus">
                        <div class="cont_title">
                            <div>{{"Your response was received in the wrong format" | translate}}</div>
                            <div class="cp" (click)="$event.preventDefault(); $event.stopPropagation(); resetRes();">
                                <mat-icon style="color: #C9C9C9;">arrow_back_ios</mat-icon>
                            </div>
                        </div>
                        <div class="cont_desc">
                            {{"Try again by clicking on the 'Get Results'" | translate}}
                        </div>
                    </div>
                </ng-container>
                <ng-template #elseTemplateHasMistake>
                    <div class="context_head no_focus">
                        <div class="cont_title">
                            <div>{{"Title composer" | translate}}</div>
                            <div>
                                <svg style="width: 21px; height: 20px" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13.0817 9.25L12.1817 10.17C11.4617 10.89 11.0117 11.5 11.0117 13H9.01172V12.5C9.01172 11.39 9.46172 10.39 10.1817 9.67L11.4217 8.41C11.7917 8.05 12.0117 7.55 12.0117 7C12.0117 5.89 11.1117 5 10.0117 5C9.48129 5 8.97258 5.21071 8.59751 5.58579C8.22243 5.96086 8.01172 6.46957 8.01172 7H6.01172C6.01172 5.93913 6.43315 4.92172 7.18329 4.17157C7.93344 3.42143 8.95085 3 10.0117 3C11.0726 3 12.09 3.42143 12.8401 4.17157C13.5903 4.92172 14.0117 5.93913 14.0117 7C14.0117 7.88 13.6517 8.67 13.0817 9.25ZM11.0117 17H9.01172V15H11.0117M10.0117 0C8.6985 0 7.39814 0.258658 6.18488 0.761205C4.97163 1.26375 3.86924 2.00035 2.94065 2.92893C1.06529 4.8043 0.0117188 7.34784 0.0117188 10C0.0117188 12.6522 1.06529 15.1957 2.94065 17.0711C3.86924 17.9997 4.97163 18.7362 6.18488 19.2388C7.39814 19.7413 8.6985 20 10.0117 20C12.6639 20 15.2074 18.9464 17.0828 17.0711C18.9582 15.1957 20.0117 12.6522 20.0117 10C20.0117 4.47 15.5117 0 10.0117 0Z" fill="#219653"/>
                                </svg> 
                            </div>
                        </div>
                        <div class="cont_desc">
                            {{"Here you may compose title for all video publications" | translate}}
                        </div>
                    </div>
                </ng-template>
            </ng-template>

    
            <div class="context_body" [ngClass]="{'with_results': aiResults && aiResults.length}">
                <ng-container *ngIf="aiResults && aiResults.length; else elseTemplateHasRes">
                    <div class="res_list">
                        <div class="res text_one_line" [ngClass]="{'active': (!!contextdata.contextData && !!contextdata.contextData.is_cut && !!contextdata.contextData.form && contextdata.contextData.form.get('name').value == item) || ((!contextdata.contextData || !contextdata.contextData.is_cut) && form.get('name').value == item)}" *ngFor="let item of aiResults" (click)="$event.preventDefault(); $event.stopPropagation(); selectTitle(item, contextdata)">
                            {{item}}
                        </div>
                    </div>
                </ng-container>
                <ng-template #elseTemplateHasRes>
                    <div class="body_left">
                        <form [formGroup]="aiForm">
                            <mat-form-field appearance="standard" class="full_width_form">
                                <mat-label>{{ 'On the video' | translate }}</mat-label>
                                <textarea 
                                cdkTextareaAutosize
                                #autosize="cdkTextareaAutosize"
                                cdkAutosizeMinRows="2"
                                cdkAutosizeMaxRows="3" formControlName="on_the_video" matInput></textarea>
                                <mat-hint class="char_counter_hint" *ngIf="aiForm.get('on_the_video').value" [ngClass]="{'red': aiForm.get('on_the_video').value.length > 999999999}" align="end">{{aiForm.get('on_the_video').value.length}}</mat-hint>
                            </mat-form-field>
    
                            <mat-form-field appearance="standard" class="full_width_form">
                                <mat-label>{{ 'Your idea of title' | translate }}</mat-label>
                                <textarea 
                                cdkTextareaAutosize
                                #autosize="cdkTextareaAutosize"
                                cdkAutosizeMinRows="2"
                                cdkAutosizeMaxRows="3" formControlName="your_idea_of_title" matInput></textarea>
                                <mat-hint class="char_counter_hint" *ngIf="aiForm.get('your_idea_of_title').value" [ngClass]="{'red': aiForm.get('your_idea_of_title').value.length > 999999999}" align="end">{{aiForm.get('your_idea_of_title').value.length}}</mat-hint>
                            </mat-form-field>

                            <mat-form-field class="full_width_form" style="margin-top: 10px;">
                                <mat-label>{{"Words to use" | translate}}</mat-label>
                                <!-- has_auto_trigger -->
                                <mat-chip-list class="without_ordered" #chipGrid aria-label="keywords selection" formControlName="keywords">
                                    <mat-chip class="chip_el" *ngFor="let keyword of aiForm.get('keywords').value" (removed)="removeKeyword(keyword)">
                                    {{keyword}}
                                    <button matChipRemove [attr.aria-label]="'remove ' + keyword">
                                        <mat-icon>close</mat-icon>
                                    </button>
                                    </mat-chip>
                                    <input class="auto_input" #keyInput placeholder="Other Keyword..." [formControl]="savedTagsControl" (input)="$event.stopPropagation()"
                                    #triggerKeys="matAutocompleteTrigger"
                                    [matChipInputFor]="chipGrid" [matAutocomplete]="keywordsAuto"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    (matChipInputTokenEnd)="addKeyword($event, keyInput, triggerKeys)"/>
                                    <!-- <div class="auto_trigger" (click)="$event.stopPropagation(); $event.stopImmediatePropagation(); $event.preventDefault(); triggerKeys.panelOpen ? triggerKeys.closePanel() : triggerKeys.openPanel()">
                                        <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M2.1 3C2.3387 3 2.56761 2.90518 2.7364 2.7364C2.90518 2.56761 3 2.3387 3 2.1C3 1.86131 2.90518 1.63239 2.7364 1.4636C2.56761 1.29482 2.3387 1.2 2.1 1.2C1.86131 1.2 1.63239 1.29482 1.4636 1.4636C1.29482 1.63239 1.2 1.86131 1.2 2.1C1.2 2.3387 1.29482 2.56761 1.4636 2.7364C1.63239 2.90518 1.86131 3 2.1 3ZM9.246 4.548C9.462 4.764 9.6 5.064 9.6 5.4C9.6 5.73 9.468 6.03 9.246 6.246L6.246 9.246C6.03 9.462 5.73 9.6 5.4 9.6C5.07 9.6 4.77 9.468 4.548 9.246L0.354 5.052C0.132 4.83 0 4.53 0 4.2V1.2C0 0.534 0.534 0 1.2 0H4.2C4.53 0 4.83 0.132 5.046 0.348L9.246 4.548ZM6.924 1.026L7.524 0.426L11.646 4.548C11.868 4.764 12 5.07 12 5.4C12 5.73 11.868 6.03 11.652 6.246L8.424 9.474L7.824 8.874L11.25 5.4L6.924 1.026Z" fill="#C9C9C9"/>
                                        </svg> 
                                    </div> -->
                                </mat-chip-list>
                                <mat-autocomplete class="list_of_keywords" classList="list_of_keywords" #keywordsAuto="matAutocomplete" (optionSelected)="selectedKeyword($event, keyInput)">
                                    <div class="select_all_auto" *ngIf="checkIfCanSelectAll((savedTags$ | async), aiForm.get('keywords').value)" (click)="$event.stopPropagation(); $event.preventDefault(); selectAllKeywords(keywordsAuto, aiForm.get('keywords').value, aiForm, triggerKeys)">{{"Select All" | translate}}</div>
                                    <ng-container *ngFor="let keyword of savedTags$ | async">
                                        <ng-container *ngIf="!aiForm.get('keywords').value.includes(keyword.value)">
                                            <mat-option (click)="$event.stopPropagation();" [value]="keyword.value">
                                                {{keyword.value}}
                                            </mat-option>
                                        </ng-container>
                                    </ng-container>
                                </mat-autocomplete>
                            </mat-form-field>

                            <mat-form-field class="full_width_form" style="margin-top: 15px;">
                                <mat-label>{{"Remember" | translate}}</mat-label>
                                <!-- has_auto_trigger -->
                                <mat-chip-list class="without_ordered" #remGrid aria-label="remembers selection" formControlName="remembers">
                                    <mat-chip class="chip_el" *ngFor="let remember of aiForm.get('remembers').value" (removed)="removeRemember(remember)">
                                    {{remember}}
                                    <button matChipRemove [attr.aria-label]="'remove ' + remember">
                                        <mat-icon>close</mat-icon>
                                    </button>
                                    </mat-chip>
                                    <input class="auto_input" #remInput placeholder="Other Remember..." [formControl]="remembersControl" (input)="$event.stopPropagation()"
                                    #triggerRem="matAutocompleteTrigger"
                                    [matChipInputFor]="remGrid" [matAutocomplete]="remembersAuto"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    (matChipInputTokenEnd)="addRemember($event, remInput, triggerRem)"/>
                                    <!-- <div class="auto_trigger" (click)="$event.stopPropagation(); $event.stopImmediatePropagation(); $event.preventDefault(); triggerRem.panelOpen ? triggerRem.closePanel() : triggerRem.openPanel()">
                                        <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0 0H2.66667V2.66667H0V0ZM4 0.666667V2H12V0.666667H4ZM0 4H2.66667V6.66667H0V4ZM4 4.66667V6H12V4.66667H4ZM0 8H2.66667V10.6667H0V8ZM4 8.66667V10H12V8.66667H4Z" fill="#C9C9C9"/>
                                        </svg> 
                                    </div> -->
                                </mat-chip-list>
                                <mat-autocomplete class="list_of_keywords" classList="list_of_keywords" #remembersAuto="matAutocomplete" (optionSelected)="selectedRemember($event, remInput)">
                                    <ng-container *ngFor="let remember of remembers$ | async">
                                        <ng-container *ngIf="!aiForm.get('remembers').value.includes(remember.message)">
                                            <mat-option (click)="$event.stopPropagation();" [value]="remember.message">
                                                {{remember.message}}
                                            </mat-option>
                                        </ng-container>
                                    </ng-container>
                                </mat-autocomplete>
                            </mat-form-field>
                                
    
                            <mat-form-field appearance="standard" class="full_width_form">
                                <mat-label>{{ 'Also remember' | translate }}</mat-label>
                                <textarea 
                                cdkTextareaAutosize
                                #autosize="cdkTextareaAutosize"
                                cdkAutosizeMinRows="1"
                                cdkAutosizeMaxRows="2" formControlName="also_remember" matInput></textarea>
                                <mat-hint class="char_counter_hint" *ngIf="aiForm.get('also_remember').value" [ngClass]="{'red': aiForm.get('also_remember').value.length > 999999999}" align="end">{{aiForm.get('also_remember').value.length}}</mat-hint>
                            </mat-form-field>
                        </form>
                    </div>
                    <div class="body_right">
                        <div class="msgs_preview">
                            <div class="msg" *ngIf="aiForm.get('on_the_video').value">
                                <b>On the video</b> {{aiForm.get('on_the_video').value}}
                            </div>
                            <div class="msg" *ngIf="aiForm.get('your_idea_of_title').value">
                                <b>Example of the title</b> {{aiForm.get('your_idea_of_title').value}}
                            </div>
                            <div class="msg" *ngIf="aiForm.get('keywords').value && aiForm.get('keywords').value.length">
                                <b>Use words</b> {{aiForm.get('keywords').value.join(', ')}}
                            </div>
                            <div class="msg" *ngIf="aiForm.get('remembers').value && aiForm.get('remembers').value.length">
                                <b>Remember</b> {{aiForm.get('remembers').value.join(', ')}}
                            </div>
                            <div class="msg" *ngIf="aiForm.get('also_remember').value">
                                <b>Also remember</b> {{aiForm.get('also_remember').value}}
                            </div>
                            <ng-container *ngIf="showSys">
                                <form class="form_sys" [formGroup]="aiSysForm">      
                                    <div class="sys_str" contenteditable="true" spellcheck="false" formControlName="str"></div>
                                </form>
                                <!-- <pre class="request_sys" *ngIf="!!getResultsReqObject() && getResultsReqObject().messages && getResultsReqObject().messages[0]">{{getResultsReqObject().messages[0]}}</pre> -->
                            </ng-container>
                        </div>
                        <div class="context_footer">
                            <div class="sys_btn" (click)="showSys = !showSys">
                                <ng-container *ngIf="showSys; else elseTemplate">
                                    {{"Hide full request" | translate}}
                                </ng-container>
                                <ng-template #elseTemplate>
                                    {{"Full request" | translate}}
                                </ng-template>
                            </div>
                            <button class="cntxt_btn blue" [disabled]="isGettingResults" (click)="getResults(aiForm, contextdata)">
                                <mat-spinner class="spin_16 mr-1" *ngIf="isGettingResults"></mat-spinner> 
                                {{ "Get Results" | translate }}
                            </button>
                        </div>
                    </div>
                    <div class="mistake" *ngIf="!!aiMistake">{{aiMistake}}</div>
                </ng-template>
            </div>
        </ng-container>
        <ng-template #elseTemplateIsAiMenu>            
            <div class="context_head no_focus">
                <div class="cont_title">
                    <div>{{"Mass publish setup" | translate}}</div>
                    <div>
                        <svg style="width: 21px; height: 20px" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.0817 9.25L12.1817 10.17C11.4617 10.89 11.0117 11.5 11.0117 13H9.01172V12.5C9.01172 11.39 9.46172 10.39 10.1817 9.67L11.4217 8.41C11.7917 8.05 12.0117 7.55 12.0117 7C12.0117 5.89 11.1117 5 10.0117 5C9.48129 5 8.97258 5.21071 8.59751 5.58579C8.22243 5.96086 8.01172 6.46957 8.01172 7H6.01172C6.01172 5.93913 6.43315 4.92172 7.18329 4.17157C7.93344 3.42143 8.95085 3 10.0117 3C11.0726 3 12.09 3.42143 12.8401 4.17157C13.5903 4.92172 14.0117 5.93913 14.0117 7C14.0117 7.88 13.6517 8.67 13.0817 9.25ZM11.0117 17H9.01172V15H11.0117M10.0117 0C8.6985 0 7.39814 0.258658 6.18488 0.761205C4.97163 1.26375 3.86924 2.00035 2.94065 2.92893C1.06529 4.8043 0.0117188 7.34784 0.0117188 10C0.0117188 12.6522 1.06529 15.1957 2.94065 17.0711C3.86924 17.9997 4.97163 18.7362 6.18488 19.2388C7.39814 19.7413 8.6985 20 10.0117 20C12.6639 20 15.2074 18.9464 17.0828 17.0711C18.9582 15.1957 20.0117 12.6522 20.0117 10C20.0117 4.47 15.5117 0 10.0117 0Z" fill="#219653"/>
                        </svg> 
                    </div>
                </div>
                <div class="cont_desc">
                    {{"All exported files will be published to corresponded outlet on selected platform at defined time" | translate}}
                </div>
            </div>
    
            <div class="context_body with_results" [appDeviceClass]="'custom-scroll'">
                <ng-container [ngSwitch]="profilesTab">
                    <ng-container *ngSwitchCase="0">
                        <div class="context_body_desc no_focus">
                            {{"In current setup for exporting files cards there are available outlets." | translate}}
                        </div>
                        <div class="grouped_outlets" *ngFor="let item of contextdata.outlets">
                            <div class="group_items">
                                <div class="title">
                                    <app-platform [platform_id]="item.platform.id"></app-platform>
                                    <span>
                                        {{item.platform.name}}
                                    </span>
                                </div>
                                <div class="items" *ngFor="let arrItem of item.arr">
                                    <div class="item" [ngClass]="{'active': !!arrItem.is_selected}" (click)="arrItem.is_selected = !arrItem.is_selected">
                                        <app-outlet-line [channel]="arrItem.channel" [company_id]="data?.company.id"></app-outlet-line> 
                                        <span>{{getTypeById(arrItem?.content_type_id)?.name}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="1">
                        <div class="context_body_desc no_focus">
                            {{"Media plans available for outlets." | translate}}<br>
                            {{"Define how frequently you want to publish files." | translate}}
                        </div>
                        <div class="selected_items">
                            <ng-container *ngFor="let item of contextdata.outlets">
                                <ng-container *ngFor="let arrItem of item.arr">
                                    <ng-container *ngIf="!!arrItem.is_selected">
                                        <div class="selected_item">
                                            <div class="chann">
                                                <app-outlet-line [channel]="arrItem.channel" [company_id]="data?.company.id"></app-outlet-line>
                                            </div>
                                            <div class="chann_settings">
                                                <ng-container *ngIf="data.version == 5.1; else elseTemplateNew">
                                                    <span class="time">
                                                    
                                                        <div (click)="regs.toggle()" [ngClass]="{'active': arrItem.publData.content_plan_content_schedules_ids.length}">
                                                            {{ arrItem.publData.content_plan_content_schedules_ids.length ? ('Selected ' + arrItem.publData.content_plan_content_schedules_ids.length + ' regulars') : ("All regular shedules"| translate) }}
                                                        </div>
                         
                                                        <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                                                            <mat-select class="list_of_keywords" disableOptionCentering #regs [multiple]="true" [(ngModel)]="arrItem.publData.content_plan_content_schedules_ids">    
                                                                <mat-option *ngFor="let reg of arrItem.regulars" [value]="reg.id">
                                                                    {{ reg.name | translate }}
                                                                </mat-option>
                                                            </mat-select>
                                                        </mat-form-field>
                                                    </span>
                                                </ng-container>
                                                <ng-template #elseTemplateNew>
                                                    <span class="time">Anytime</span>
                                                </ng-template>
                                                
                                        
                                                <span>skipping</span>
                                                <input class="min_int" type="number" [(ngModel)]="arrItem.publData.content_plan_min_interval">
                                                <span>time slots</span>
                                            </div>
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="2">
                        <div class="context_body_desc no_focus">
                            {{"Almost done." | translate}}<br>
                        </div>
    
                        + Autoassign time slots after file are ready?
                    </ng-container>
                </ng-container>
            </div>
            <div class="context_footer">
                <div>
                    <button class="cntxt_btn" *ngIf="profilesTab != 0" (click)="$event.preventDefault; $event.stopPropagation(); profilesTab = profilesTab - 1">
                        {{ "Back" | translate }}
                    </button>
                </div>
                <div class="d-f-c">
                    <button class="cntxt_btn" (click)="$event.preventDefault; $event.stopPropagation(); closeContext()">
                        {{ "Cancel" | translate }}
                    </button>
                    <button class="cntxt_btn" style="color: #2F80ED;" *ngIf="profilesTab == 0 && checkOutletsCount(contextdata.outlets) > 5" (click)="$event.preventDefault; $event.stopPropagation(); selectAll(contextdata.outlets)">
                        {{ "Select All" | translate }}
                    </button>
                    <button class="cntxt_btn blue" *ngIf="profilesTab < 2" (click)="$event.preventDefault; $event.stopPropagation(); nextTab()">
                        {{ "Next" | translate }}
                    </button>
                    <button class="cntxt_btn blue" *ngIf="profilesTab == 2" (click)="$event.preventDefault; $event.stopPropagation(); finishPubl(contextdata.outlets)">
                        {{ "Finish" | translate }}
                    </button>
                </div>
            </div>
        </ng-template>
        
	</section>
</ng-template>


<ng-template #textContextMenu let-contextdata>
	<section class="dark_menu get_text_menu with_search">
        <div class="context_head no_focus">
            <div class="cont_title">
                <div>{{"Get text from file name to Post and Card Title" | translate}}</div>
                <div class="cp" (click)="$event.preventDefault(); $event.stopPropagation(); closeGetText()" >
                    <mat-icon style="color: #C9C9C9;">arrow_back_ios</mat-icon>
                </div>
            </div>
            <div class="cont_desc">
                {{"Set up text receiving criteria" | translate}}
            </div>
        </div>
    
        <div class="context_body">
            <form [formGroup]="textForm">
                <h2>{{"Text destination"| translate}}:</h2>
                <p>
                    <mat-checkbox class="" formControlName="for_cards" [disableRipple]="true">
                        <span class="">{{ "Card title" | translate }}</span>
                    </mat-checkbox>
                </p>
                <p>
                    <mat-checkbox class="" formControlName="for_posts" [disableRipple]="true">
                        <span class="">{{ "Post Title" | translate }}</span>
                    </mat-checkbox>
                </p>

                <h2>{{"Get text from file name that matches criteria"| translate}}:</h2>

                <div class="d-f-c">
                    <span>Any value between symbols</span>
                    <div class="ve_input_out">
                        <input class="ve_input reports_white_input" formControlName="before" matInput placeholder="{{ 'After text' | translate }}">
                    </div>
                    <span>and</span>
                    <div class="ve_input_out">
                        <input class="ve_input reports_white_input" formControlName="after" matInput placeholder="{{ 'Before text' | translate }}">
                    </div>
                </div>
            </form>
        </div>

        <div class="context_footer">
            <div>
                <button class="cntxt_btn" (click)="$event.preventDefault; $event.stopPropagation(); closeGetText()">
                    {{ "Close" | translate }}
                </button>
            </div>
            <div class="d-f-c">
                <button class="cntxt_btn blue" (click)="$event.preventDefault; $event.stopPropagation(); getText()">
                    {{ "Get Text" | translate }}
                </button>
            </div>
        </div>
	</section>
</ng-template>