import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { prioritys } from 'src/app/shared/consts/prioritys';
import { casesModel } from 'src/app/shared/functions/casesModel';
import { getLessStatuses } from 'src/app/shared/functions/statusesLessModel';
import { BackJobToPrevStatusComponent } from 'src/app/shared/global_components/back-job-to-prev-status/back-job-to-prev-status.component';
import { MobPersonalStatusesComponent } from 'src/app/shared/global_components/chat/dialogs/mob-personal-statuses/mob-personal-statuses.component';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { ChatService } from 'src/app/shared/services/rest/chat.service';
import { MembersService } from 'src/app/shared/services/rest/members.service';
import { RefreshService } from 'src/app/shared/services/rest/refresh.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { environment } from 'src/environments/environment';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MY_FORMATS } from '../task-profile-add/task-profile-add.component';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { NgxMatDateAdapter } from '@angular-material-components/datetime-picker';
import * as moment from 'moment';
@Component({
  selector: 'app-edit-operation-chat',
  templateUrl: './edit-operation-chat.component.html',
  styleUrls: ['./edit-operation-chat.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class EditOperationChatComponent extends BaseClass implements OnInit, OnDestroy {
  public isFormChange: boolean = false;
  public isSubmit: boolean = false;
  public submited: boolean = false;
  public statuses: any;
  public chat: any;
  public chat_id: any;
  public iAmEmployee: any;
  public task: any;
  public chatEmlStats: any;
  public work: any;
  public host: any = environment.host;
  public prioritys: any = prioritys;
  public form: FormGroup;
  public personalStatusForm: FormGroup;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<EditOperationChatComponent>,
    private layoutService: LayoutService,
    private refreshService: RefreshService,
    private dialog: MatDialog,
    private sm: StorageManagerService,
    private taskService: TaskService,
    private membersService: MembersService,
    private _adapter: DateAdapter<any>,
    private _ngx_adapter: NgxMatDateAdapter<any>,
    private languageService: LanguageService,
    private bottomSheet: MatBottomSheet,
    private chatService: ChatService,
    private fb: FormBuilder
  ) { super() }

  ngOnInit(): void {
    this.dialogRef.addPanelClass("edit_chat_modal")
    this.chat_id = this.data.chat.id

    console.log("this.data", this.data);
    
    this.getChatStatuses();
    this.getLangData();

    this.personalStatusForm = this.fb.group({
      status_id: ['', Validators.required]
    })

    this.update();
    if (!this.data.onlyPriority && !this.data.onlyName) {
      this.form = this.fb.group({
        name: [this.data.chat.name, Validators.required],
        status_id: [this.data.chat.status_id, Validators.required],
        priority: !!this.data.chat.priority ? this.data.chat.priority : 0,
        date: this.fb.group({
          time: '',
          day: ''
        }),
        planned_completed_at: this.data.chat.planned_completed_at != "0" ? moment(this.data.chat.planned_completed_at*1000) : ''
      })
      this.attachSubscriptions(
        this.form.get('date').valueChanges.subscribe(resp => {
          console.log(resp)
          let dateForm = {...this.form.get("date").value}
          if (!dateForm.day) {
            dateForm.day = moment(0).hour(0).minute(0)
          }
          let testTimeAndDate = dateForm.day.clone()
          testTimeAndDate.hour(Number(dateForm.time.split(":")[0]))
          testTimeAndDate.minute(Number(dateForm.time.split(":")[1]))
          this.form.patchValue({
            planned_completed_at: testTimeAndDate
          })
          console.log('this.form.value', this.form.value);
          console.log('testTimeAndDate', testTimeAndDate.clone().format("X"));
        })
      )

      this.form.get('date').patchValue({
        day: this.data.chat.planned_completed_at ? moment(this.data.chat.planned_completed_at * 1000).hour(0).minute(0) : '',
        time: this.data.chat.planned_completed_at ? (this.numberToTimeFormat(moment(this.data.chat.planned_completed_at * 1000).hours()) + ":" + this.numberToTimeFormat(moment(this.data.chat.planned_completed_at * 1000).minutes())) : '',
      })
    } else {
      if (this.data.onlyPriority) {
        this.form = this.fb.group({
          priority: !!this.data.chat.priority ? this.data.chat.priority : 0,
        })
      } else if (this.data.onlyName) {
        this.form = this.fb.group({
          name: [this.data.chat.name, Validators.required]
        })
      }
    }

    this.attachSubscriptions(
      this.form.valueChanges.subscribe(() => this.isFormChange = true)
    )

    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        if (this.isFormChange) {
          this.layoutService.openBottomSheet(this.dialogRef);
        } else {
          this.close();
        }
      })
    )
  }

  numberToTimeFormat(val) {
    if (Number(val) < 10) {
      val = "0" + val
    }
    return val
  }

  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData().subscribe(resp => {
        this._ngx_adapter.setLocale(resp.active);
        this._adapter.setLocale(resp.active);
      })
    )
  }

  update() {
    this.stopDragged();
    this.attachSubscriptions(
      this.chatService.getChatsExpand('1', this.data.company.id, {id: [this.chat_id]})
      .pipe(
        tap(res => {
          this.chat = res.body[0]
        }),
        switchMap(res => {
          return this.taskService.getOneTaskExpand(this.data.company.id, this.data.chat.task_id).pipe(
            map((tasks) => casesModel(tasks.body, [], 'update').arr[0]),
            // map(val => val.arr[0])),
            tap(x => {
              this.task = x;
            })
          )
        })
      ).subscribe(resp => {
        console.log("update", resp)
        console.log("this.chat", this.chat)
        console.log("this.task", this.task)

        this.getJob(this.data.chat.task_operation_id)
      })
    )
  }

  checkIsManager(task, company, _user) {
    return task && company && task.company_id == company.employees[0].company_id && (task?.managers.filter(x => x.user_id == _user.id).length > 0 || task?.group?.managers.filter(x => x.user_id == _user.id).length > 0 || company?.permissions.includes('admin') || company?.permissions.includes('manager')); 
  }

  getJob(id) {
    if (!this.task || this.task.operations.filter(x => x.id == id).length == 0) {
      return
    }

    this.work = this.task.operations.find(x => x.id == id);

    // this.work.addEmployee = []
    // this.work.employees.forEach(element => {
    //   if (!this.work.addEmployee.includes(element) && element.discussion_id == this.chat_id) {
    //     this.work.addEmployee.push(element)
    //   }
    // });
    // console.log("CHECK", this.work.employees.filter(x => x.discussion_id == this.chat_id || x.discussion_id == 0 || !x.discussion_id))

    this.work.employees = this.work.employees.filter(x => x.discussion_id == this.chat_id || x.discussion_id == 0 || !x.discussion_id)
    this.work.mainEmployees = this.work.mainEmployees.filter(x => x.discussion_id == this.chat_id || x.discussion_id == 0 || !x.discussion_id)
    this.work.addEmployee = this.work.addEmployee.filter(x => x.discussion_id == this.chat_id || x.discussion_id == 0 || !x.discussion_id)
    this.work.addEmployee.forEach(x => {
      if (!x.employeeStatus && this.chat.employeesStatuses.find(z => z.employee_id == x.employee_id)) {
        x.employeeStatus = this.chat.employeesStatuses.find(z => z.employee_id == x.employee_id)
      }
    })
    this.work.allChatEmpl = [...this.work.mainEmployees, ...this.work.addEmployee].sort((a,b) => b.is_manager - a.is_manager);
    this.chatEmlStats = [];
    this.chatEmlStats = [...this.work.mainEmployees.slice(), ...this.work.addEmployee.slice()];
    this.chatEmlStats.forEach(x => {
      if (x.hasOwnProperty('employeeStatus')) {
        x.employeeStatus = null;
      } else if (x.hasOwnProperty('partnerCompanyStatus')) {
        x.partnerCompanyStatus = null;
      }
    });

    console.log("CHAT LOG",this.chat);

    this.chatEmlStats.forEach(x => {
      console.log("INSIDE", x)
      if (x.is_partner_employee && this.chat.partnerEmployeesStatuses.find(b => b.partner_employee_id == x.partner_employee_id)) {
        x.employeeStatus = this.chat.partnerEmployeesStatuses.find(b => b.partner_employee_id == x.partner_employee_id)
      }
      if (x.is_partner && this.chat.partnerCompaniesStatuses.find(b => b.partner_company_id == x.partner_company_id)) {
        x.partnerCompanyStatus = this.chat.partnerCompaniesStatuses.find(b => b.partner_company_id == x.partner_company_id)
      }
    });

    console.log("this.statuses BEFORE", {...this.statuses});
    console.log("this.chatEmlStats BEFORE", {...this.chatEmlStats});
    this.attachSubscriptions(
      this.membersService.getMembers({task_id: this.task.id, task_operation_id: this.work.id, discussion_id: this.chat_id}, this.data.company.id).subscribe(resp => {
        console.log("chatEmlStats", resp)
        this.chatEmlStats.forEach(x => {
          if (!x.is_partner && !x.is_partner_employee && resp.find(y => x.employee_id == y.employee_id)) {
            x.employeeStatus = resp.find(y => x.employee_id == y.employee_id).employeeStatus
          }
        })

        this.statuses.map(stat => {
          stat.empl = [];
          stat.empl.push(...this.chatEmlStats.filter(x => (x.employeeStatus && x.employeeStatus.status_id == stat.id) || (x.partnerCompanyStatus && x.partnerCompanyStatus.status_id == stat.id)))
        })

        console.log("this.statuses", this.statuses)
      })
    )
    

    console.log("this.statuses", this.statuses);


    
    if (!!this.task.clients) {
      this.work.clients = this.task.clients.filter(x => x.task_operation_id == 0 || x.task_operation_id == this.work.id);
    }
    this.work.managersArr = []
    this.task.managers.forEach(element => {
      if (!this.work.managersArr.includes(element)) {
        this.work.managersArr.push(element)
      }
    });
    this.task?.group?.managers.forEach(element => {
      if (!this.work.managersArr.includes(element)) {
        this.work.managersArr.push(element)
      }
    });

    if (this.data.company.employees.length > 0 && this.work.employees.filter(x => x.employee_id == this.data.company.employees[0].id).length > 0) {
      this.iAmEmployee = this.work.employees.find(x => (x.employee_id == this.data.company.employees[0].id) || (x.partner_company_id == this.data.company.id))
      this.personalStatusForm.patchValue({
        status_id: !!this.chat.employeeStatus ? this.chat.employeeStatus.status_id : this.chat.status_id
      })
    } else if (this.data.company.employees.length > 0 && this.work.employees.filter(x => x.partner_company_id == this.data.company.id).length > 0) {
      this.iAmEmployee = this.work.employees.find(x => x.partner_company_id == this.data.company.id)
    } else {
      this.iAmEmployee = undefined;
    }

    this.attachSubscriptions(
      this.personalStatusForm.valueChanges.subscribe(resp => {
        console.log(resp);
        if (this.checkIsManager(this.task, this.data.company, this.data.user)) {
          return
        }
        console.log('resp1');

          console.log('resp2', resp, this.chat.employeeStatus.status_id, !!this.chat.employeeStatus);
          
        if (resp.status_id != this.chat?.employeeStatus?.status_id) {
          if (!!this.chat.employeeStatus) {
            console.log('resp3');
            this.attachSubscriptions(
              this.membersService.editTaskEmployeeStatus(this.chat.employeeStatus.id, this.personalStatusForm.value, this.data.company.id).pipe(
                switchMap(val => {
                  console.log('resp4');
                  if (this.iAmEmployee?.is_manager == 1 && [3,4,6,97,98,99].includes(this.personalStatusForm.value.status_id)) {
                    console.log('resp5');
                    return this.membersService.getTaskEmployeeStatuses({task_operation_id: this.work.id, status_id: getLessStatuses(this.personalStatusForm.value.status_id), employee_id: this.iAmEmployee?.employee_id}, this.data.company.id, '2').pipe(
                      switchMap(res => {
                        console.log("manager Change RES3", res)
                        if (res.length == 1 && !res[0].discussion_id) {
                          console.log("manager Change RES4", val)
                          let questionAlert =  this.bottomSheet.open(BackJobToPrevStatusComponent, {
                            hasBackdrop: true,
                            backdropClass: 'bottom-sheed-backdrop',
                            data: {
                              msg: marker(`Do you really want to convert job personal status to: '${this.getOperationStatusName(this.personalStatusForm.value.status_id)}'?`)
                            }
                          });
                      
                          return questionAlert.afterDismissed()
                          .pipe(
                            switchMap(data => {
                              if (data && data.message == 'no') {
                                console.log("no");
                                return of(val)
                              } else if (data && data.message == 'yes') {
                                return this.membersService.editTaskEmployeeStatus(res[0].id, {status_id: this.personalStatusForm.value.status_id}, this.data.company.id).pipe(
                                  map(() => val)
                                )
                              } else {
                                return of(val)
                              }
                            })
                          )
                        } else {
                          return of(val)
                        }
                      })
                    )
                  } else {
                  return of(val)
                  }
                })
              ).subscribe(resp => {
                this.chat.employeeStatus.status = this.statuses.find(x => x.id == resp.status_id)
                this.chat.employeeStatus.status_id = resp.status_id;
                this.update()

                this.refreshService.refreshChat$.next({company_id: this.data.company.id, chat_id: this.chat_id})
                this.initRefreshBoard()
                console.log("UPDATE", this.chat.employeeStatus)
              })
            )
          } else {
            this.attachSubscriptions(
              this.membersService.addTaskEmployeeStatus(Object.assign(this.personalStatusForm.value, {
                company_id: this.data.company.id,
                task_id: this.task.id,
                task_operation_id: this.work.id,
                discussion_id: this.chat.id,
                employee_id: this.iAmEmployee?.employee_id
              }), this.data.company.id).pipe(
                switchMap(val => {
                  if (this.iAmEmployee?.is_manager == 1 && [3,4,6,97,98,99].includes(this.personalStatusForm.value.status_id)) {
                    return this.membersService.getTaskEmployeeStatuses({task_operation_id: this.work.id, status_id: getLessStatuses(this.personalStatusForm.value.status_id), employee_id: this.iAmEmployee?.employee_id}, this.data.company.id, '2').pipe(
                      switchMap(res => {
                        console.log("manager Change RES3", res)
                        if (res.length == 1 && !res[0].discussion_id) {
                          console.log("manager Change RES4", val)
                          let questionAlert =  this.bottomSheet.open(BackJobToPrevStatusComponent, {
                            hasBackdrop: true,
                            backdropClass: 'bottom-sheed-backdrop',
                            data: {
                              msg: marker(`Do you really want to convert job personal status to: '${this.getOperationStatusName(this.personalStatusForm.value.status_id)}'?`)
                            }
                          });
                      
                          return questionAlert.afterDismissed()
                          .pipe(
                            switchMap(data => {
                              if (data && data.message == 'no') {
                                console.log("no");
                                return of(val)
                              } else if (data && data.message == 'yes') {
                                return this.membersService.editTaskEmployeeStatus(res[0].id, {status_id: this.personalStatusForm.value.status_id}, this.data.company.id).pipe(
                                  map(() => val)
                                )
                              } else {
                                return of(val)
                              }
                            })
                          )
                        } else {
                          return of(val)
                        }
                      })
                    )
                  } else {
                  return of(val)
                  }
                })
              ).subscribe(resp => {
                if (!this.chat.employeeStatus) {
                  this.chat.employeeStatus = {}
                }
                this.chat.employeeStatus.status = this.statuses.find(x => x.id == resp.status_id)
                this.chat.employeeStatus.status_id = resp.status_id;
                this.chat.employeeStatus.id = resp.id;
                // if (this.data.hasOwnProperty('updateDiscussion')) {
                //   this.data.updateDiscussion()
                // }
                this.update()
                this.refreshService.refreshChat$.next({company_id: this.data.company.id, chat_id: this.chat_id})
                this.initRefreshBoard()
                console.log("ADD", this.chat.employeeStatus)
              })
            )
          }

        }
      })
    )
    console.log("iAmEmployee", this.iAmEmployee);
    console.log("JOB", this.work);
  
    // this.getAdditionalEmpl()
  }

  initRefreshBoard() {
    this.refreshService.refreshBoard$.next('refresh')
  }

  selectPrioritys = (priority: any) => {
    this.form.patchValue({
      priority: priority.id
    })
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  // selectStatus(status) {
  //   this.form.patchValue({
  //     status_id: status.id
  //   })
  // }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
      let chatEm = this.statuses.find(x => x.id == event.container.id).empl.find(y => y.id == event.item.data.id);
      if (!!chatEm.is_partner) {
        if (!!chatEm.partnerCompanyStatus) {
          console.log("PARTNER", chatEm)
          this.attachSubscriptions(
            this.membersService.editPartnerStatus(chatEm.partnerCompanyStatus.id, {status_id: event.container.id}, this.data.company.id)
            .pipe(
              switchMap(val => {
                console.log("drop RES1", val)
                if ((this.iAmEmployee?.is_manager == 1 || this.checkIsManager(this.task, this.data.company, this.data.user)) && [3,4,6,97,98,99].includes(+event.container.id)) {
                  console.log("drop RES2", val)
                  return this.membersService.getTaskPartnerStatuses({task_operation_id: this.work.id, status_id: getLessStatuses(event.container.id), partner_company_id: chatEm.partner_company_id}, this.data.company.id, '2').pipe(
                    switchMap(res => {
                      console.log("drop RES3", res)
                      if (res.length == 1 && !res[0].discussion_id) {
                        console.log("drop RES4", val)
                        let questionAlert =  this.bottomSheet.open(BackJobToPrevStatusComponent, {
                          hasBackdrop: true,
                          backdropClass: 'bottom-sheed-backdrop',
                          data: {
                            msg: marker(`Do you really want to convert personal partner status in job to: '${this.getOperationStatusName(+event.container.id)}'?`)
                          }
                        });
                    
                        return questionAlert.afterDismissed()
                        .pipe(
                          switchMap(data => {
                            if (data && data.message == 'no') {
                              console.log("no");
                              return of(val)
                            } else if (data && data.message == 'yes') {
                              return this.membersService.editPartnerStatus(res[0].id, {status_id: event.container.id}, this.data.company.id).pipe(
                                map(() => val)
                              )
                            } else {
                              return of(val)
                            }
                          })
                        )
                      } else {
                        return of(val)
                      }
                    })
                  )
                } else {
                  return of(val)
                }
              })
            ).subscribe(resp => {
              // if (event.item.data.is_manager == 0 && this.chat.partnerCompanyStatuses.find(z => z.partner_company_id == event.item.data.partner_company_id)) {
              //   this.chat.partnerCompanyStatuses.find(z => z.partner_company_id == event.item.data.partner_company_id).status_id = resp.status_id
              //   this.chat.partnerCompanyStatuses.find(z => z.partner_company_id == event.item.data.partner_company_id).status = this.statuses.find(b => b.id == resp.status_id)
              // }
              this.update();
              // this.updateDiscussion(this.chat_id)
              this.refreshService.refreshChat$.next({company_id: this.data.company.id, chat_id: this.chat_id});
              this.initRefreshBoard()
            },
            error => {
              transferArrayItem(
                event.container.data,
                event.previousContainer.data,
                event.currentIndex,
                event.previousIndex,
              );
            })
          )
        } else {
          this.attachSubscriptions(
            this.membersService.addPartnerStatus(Object.assign({status_id: event.container.id}, {
              company_id: this.data.company.id,
              task_id: this.task.id,
              task_operation_id: this.work.id,
              discussion_id: this.chat_id,
              partner_company_id: event.item.data.partner_company_id
            }), this.data.company.id)
            .pipe(
              switchMap(val => {
                console.log("drop RES1", val)
                if ((this.iAmEmployee?.is_manager == 1 || this.checkIsManager(this.task, this.data.company, this.data.user)) && [3,4,6,97,98,99].includes(+event.container.id)) {
                  console.log("drop RES2", val)
                  return this.membersService.getTaskPartnerStatuses({task_operation_id: this.work.id, status_id: getLessStatuses(event.container.id), partner_company_id: chatEm.partner_company_id}, this.data.company.id, '2').pipe(
                    switchMap(res => {
                      console.log("drop RES3", res)
                      if (res.length == 1 && !res[0].discussion_id) {
                        console.log("drop RES4", val)
                        let questionAlert =  this.bottomSheet.open(BackJobToPrevStatusComponent, {
                          hasBackdrop: true,
                          backdropClass: 'bottom-sheed-backdrop',
                          data: {
                            msg: marker(`Do you really want to convert personal partner status in job to: '${this.getOperationStatusName(+event.container.id)}'?`)
                          }
                        });
                    
                        return questionAlert.afterDismissed()
                        .pipe(
                          switchMap(data => {
                            if (data && data.message == 'no') {
                              console.log("no");
                              return of(val)
                            } else if (data && data.message == 'yes') {
                              return this.membersService.editPartnerStatus(res[0].id, {status_id: event.container.id}, this.data.company.id).pipe(
                                map(() => val)
                              )
                            } else {
                              return of(val)
                            }
                          })
                        )
                      } else {
                        return of(val)
                      }
                    })
                  )
                } else {
                  return of(val)
                }
              })
            ).subscribe(resp => {
              if (event.item.data.is_manager == 0 && this.chat.partnerCompanyStatuses.find(z => z.partner_company_id == event.item.data.partner_company_id)) {
                this.chat.partnerCompanyStatuses.find(z => z.partner_company_id == event.item.data.partner_company_id).status_id = resp.status_id
                this.chat.partnerCompanyStatuses.find(z => z.partner_company_id == event.item.data.partner_company_id).status = this.statuses.find(b => b.id == resp.status_id)
              }
                this.update();
            // this.updateDiscussion(this.chat_id)
              this.refreshService.refreshChat$.next({company_id: this.data.company.id, chat_id: this.chat_id});
              this.initRefreshBoard()
              
            },
            error => {
              transferArrayItem(
                event.container.data,
                event.previousContainer.data,
                event.currentIndex,
                event.previousIndex,
              );
            })
          )
        }
      } else if (!!chatEm.is_partner_employee) {
        if (!!chatEm.employeeStatus?.id) {
          this.attachSubscriptions(
            this.membersService.editPartnerEmployeeStatus(chatEm.employeeStatus.id, {status_id: event.container.id}, this.data.company.id)
            .pipe(
              switchMap(val => {
                console.log("drop RES1", val)
                if ((this.iAmEmployee?.is_manager == 1 || this.checkIsManager(this.task, this.data.company, this.data.user)) && [3,4,6,97,98,99].includes(+event.container.id)) {
                  console.log("drop RES2", val)
                  return this.membersService.getTaskPartnerEmployeeStatuses({task_operation_id: this.work.id, status_id: getLessStatuses(event.container.id), partner_employee_id: chatEm.partner_employee_id, partner_company_id: chatEm.partner_company_id}, this.data.company.id, '2').pipe(
                    switchMap(res => {
                      console.log("drop RES3", res)
                      if (res.length == 1 && !res[0].discussion_id) {
                        console.log("drop RES4", val)
                        let questionAlert =  this.bottomSheet.open(BackJobToPrevStatusComponent, {
                          hasBackdrop: true,
                          backdropClass: 'bottom-sheed-backdrop',
                          data: {
                            msg: marker(`Do you really want to convert personal partner status in job to: '${this.getOperationStatusName(+event.container.id)}'?`)
                          }
                        });
                    
                        return questionAlert.afterDismissed()
                        .pipe(
                          switchMap(data => {
                            if (data && data.message == 'no') {
                              console.log("no");
                              return of(val)
                            } else if (data && data.message == 'yes') {
                              return this.membersService.editPartnerEmployeeStatus(res[0].id, {status_id: event.container.id}, this.data.company.id).pipe(
                                map(() => val)
                              )
                            } else {
                              return of(val)
                            }
                          })
                        )
                      } else {
                        return of(val)
                      }
                    })
                  )
                } else {
                  return of(val)
                }
              })
            ).subscribe(resp => {
              console.log("DQWEQWEQW#!@EWQEQDSA", resp)
              if (event.item.data.is_manager == 0 && this.chat.employeesStatuses.find(z => z.employee_id == event.item.data.employee_id)) {
                this.chat.employeesStatuses.find(z => z.employee_id == event.item.data.employee_id).status_id = resp.status_id
                this.chat.employeesStatuses.find(z => z.employee_id == event.item.data.employee_id).status = this.statuses.find(b => b.id == resp.status_id)
              }
              this.update()
              this.refreshService.refreshChat$.next({company_id: this.data.company.id, chat_id: this.chat_id});
              this.initRefreshBoard()
            },
            error => {
              this.showError(error)
              transferArrayItem(
                event.container.data,
                event.previousContainer.data,
                event.currentIndex,
                event.previousIndex,
              );
            })
          )
        } else {
          this.attachSubscriptions(
            this.membersService.addPartnerEmployeeStatus({
              status_id: event.container.id,
              company_id: this.data.company.id,
              task_id: this.task.id,
              task_operation_id: this.work.id,
              discussion_id: this.chat.id,
              partner_company_id: chatEm.partner_company_id,
              partner_employee_id: chatEm.partner_employee_id
            }, this.data.company.id)
            .pipe(
              switchMap(val => {
                console.log("drop RES1", val)
                if ((this.iAmEmployee?.is_manager == 1 || this.checkIsManager(this.task, this.data.company, this.data.user)) && [3,4,6,97,98,99].includes(+event.container.id)) {
                  console.log("drop RES2", val)
                  return this.membersService.getTaskPartnerEmployeeStatuses({task_operation_id: this.work.id, status_id: getLessStatuses(event.container.id), partner_employee_id: chatEm.partner_employee_id, partner_company_id: chatEm.partner_company_id}, this.data.company.id, '2').pipe(
                    switchMap(res => {
                      console.log("drop RES3", res)
                      if (res.length == 1 && !res[0].discussion_id) {
                        console.log("drop RES4", val)
                        let questionAlert =  this.bottomSheet.open(BackJobToPrevStatusComponent, {
                          hasBackdrop: true,
                          backdropClass: 'bottom-sheed-backdrop',
                          data: {
                            msg: marker(`Do you really want to convert personal partner status in job to: '${this.getOperationStatusName(+event.container.id)}'?`)
                          }
                        });
                    
                        return questionAlert.afterDismissed()
                        .pipe(
                          switchMap(data => {
                            if (data && data.message == 'no') {
                              console.log("no");
                              return of(val)
                            } else if (data && data.message == 'yes') {
                              return this.membersService.editPartnerEmployeeStatus(res[0].id, {status_id: event.container.id}, this.data.company.id).pipe(
                                map(() => val)
                              )
                            } else {
                              return of(val)
                            }
                          })
                        )
                      } else {
                        return of(val)
                      }
                    })
                  )
                } else {
                  return of(val)
                }
              })
            ).subscribe(resp => {
              console.log("DQWEQWEQW#!@EWQEQDSA", resp)
              this.update()
              // this.updateDiscussion(this.chat_id)
              this.refreshService.refreshChat$.next({company_id: this.data.company.id, chat_id: this.chat_id});
              this.initRefreshBoard()
            },
            error => {
              this.showError(error)
              transferArrayItem(
                event.container.data,
                event.previousContainer.data,
                event.currentIndex,
                event.previousIndex,
              );
            })
          )
        }
      } else {
        if (!!chatEm.employeeStatus) {
          this.attachSubscriptions(
            this.membersService.editTaskEmployeeStatus(chatEm.employeeStatus.id, {status_id: event.container.id}, this.data.company.id)
            .pipe(
              switchMap(val => {
                console.log("drop RES1", val)
                if ((this.iAmEmployee?.is_manager == 1 || this.checkIsManager(this.task, this.data.company, this.data.user)) && [3,4,6,97,98,99].includes(+event.container.id)) {
                  console.log("drop RES2", val)
                  return this.membersService.getTaskEmployeeStatuses({task_operation_id: this.work.id, status_id: getLessStatuses(event.container.id), employee_id: chatEm.employee_id}, this.data.company.id, '2').pipe(
                    switchMap(res => {
                      console.log("drop RES3", res)
                      if (res.length == 1 && !res[0].discussion_id) {
                        console.log("drop RES4", val)
                        let questionAlert =  this.bottomSheet.open(BackJobToPrevStatusComponent, {
                          hasBackdrop: true,
                          backdropClass: 'bottom-sheed-backdrop',
                          data: {
                            msg: marker(`Do you really want to convert job personal status to: '${this.getOperationStatusName(+event.container.id)}'?`)
                          }
                        });
                    
                        return questionAlert.afterDismissed()
                        .pipe(
                          switchMap(data => {
                            if (data && data.message == 'no') {
                              console.log("no");
                              return of(val)
                            } else if (data && data.message == 'yes') {
                              return this.membersService.editTaskEmployeeStatus(res[0].id, {status_id: event.container.id}, this.data.company.id).pipe(
                                map(() => val)
                              )
                            } else {
                              return of(val)
                            }
                          })
                        )
                      } else {
                        return of(val)
                      }
                    })
                  )
                } else {
                  return of(val)
                }
              })
            ).subscribe(resp => {
              console.log("DQWEQWEQW#!@EWQEQDSA", resp)
              if (event.item.data.is_manager == 0 && this.chat.employeesStatuses.find(z => z.employee_id == event.item.data.employee_id)) {
                this.chat.employeesStatuses.find(z => z.employee_id == event.item.data.employee_id).status_id = resp.status_id
                this.chat.employeesStatuses.find(z => z.employee_id == event.item.data.employee_id).status = this.statuses.find(b => b.id == resp.status_id)
              }
                this.update();
            // this.updateDiscussion(this.chat_id)
              this.refreshService.refreshChat$.next({company_id: this.data.company.id, chat_id: this.chat_id});
              this.initRefreshBoard()
            },
            error => {
              this.showError(error)
              transferArrayItem(
                event.container.data,
                event.previousContainer.data,
                event.currentIndex,
                event.previousIndex,
              );
            })
          )
        } else {
          this.attachSubscriptions(
            this.membersService.addTaskEmployeeStatus(Object.assign({status_id: event.container.id}, {
              company_id: this.data.company.id,
              task_id: this.task.id,
              task_operation_id: this.work.id,
              discussion_id: this.chat_id,
              employee_id: event.item.data.employee_id
            }), this.data.company.id)
            .pipe(
              switchMap(val => {
                console.log("drop RES1", val)
                if ((this.iAmEmployee?.is_manager == 1 || this.checkIsManager(this.task, this.data.company, this.data.user)) && [3,4,6,97,98,99].includes(+event.container.id)) {
                  console.log("drop RES2", val)
                  return this.membersService.getTaskEmployeeStatuses({task_operation_id: this.work.id, status_id: getLessStatuses(event.container.id), employee_id: chatEm.employee_id}, this.data.company.id, '2').pipe(
                    switchMap(res => {
                      console.log("drop RES3", res)
                      if (res.length == 1 && !res[0].discussion_id) {
                        console.log("drop RES4", val)
                        let questionAlert =  this.bottomSheet.open(BackJobToPrevStatusComponent, {
                          hasBackdrop: true,
                          backdropClass: 'bottom-sheed-backdrop',
                          data: {
                            msg: marker(`Do you really want to convert job personal status to: '${this.getOperationStatusName(+event.container.id)}'?`)
                          }
                        });
                    
                        return questionAlert.afterDismissed()
                        .pipe(
                          switchMap(data => {
                            if (data && data.message == 'no') {
                              console.log("no");
                              return of(val)
                            } else if (data && data.message == 'yes') {
                              return this.membersService.editTaskEmployeeStatus(res[0].id, {status_id: event.container.id}, this.data.company.id).pipe(
                                map(() => val)
                              )
                            } else {
                              return of(val)
                            }
                          })
                        )
                      } else {
                        return of(val)
                      }
                    })
                  )
                } else {
                  return of(val)
                }
              })
            ).subscribe(resp => {
              if (event.item.data.is_manager == 0 && this.chat.employeesStatuses.find(z => z.employee_id == event.item.data.employee_id)) {
                this.chat.employeesStatuses.find(z => z.employee_id == event.item.data.employee_id).status_id = resp.status_id
                this.chat.employeesStatuses.find(z => z.employee_id == event.item.data.employee_id).status = this.statuses.find(b => b.id == resp.status_id)
              }
                this.update();
            // this.updateDiscussion(this.chat_id)
              this.refreshService.refreshChat$.next({company_id: this.data.company.id, chat_id: this.chat_id});
              this.initRefreshBoard()
            },
            error => {
              this.showError(error)
              transferArrayItem(
                event.container.data,
                event.previousContainer.data,
                event.currentIndex,
                event.previousIndex,
              );
            })
          )
        }
      }
    }
  }

  emplChangeStatus(status) {
    console.log("emplChangeStatus", status)
    if ([4,97,98,99].includes(status.id)) {
      return
    }

    let relativeEmpls = this.chatEmlStats.filter(t => status.empl.filter(b => b.id == t.id).length == 0)
    if (relativeEmpls.length > 1) {
      const dialogRef = this.dialog.open(MobPersonalStatusesComponent, {
        panelClass: ['chats_modal', 'desk_statuses'],
        data: {
          company: this.data.company,
          task: this.task,
          work: this.work,
          chat: this.chat,
          host: this.host,
          imgRoute: this.data.imgRoute,
          user: this.data.user,
          statuses: this.statuses,
          selectedStatus: status,
          chatEmlStats: this.chatEmlStats,
          iAmEmployee: this.iAmEmployee,
          updateDiscussion: () => {
              this.update();
            // this.updateDiscussion(this.chat_id)
            this.refreshService.refreshChat$.next({company_id: this.data.company.id, chat_id: this.chat_id})
            this.initRefreshBoard()
          }
        }
      });
  
      this.attachSubscriptions(
        dialogRef.afterClosed().subscribe(result => {
            this.update();
            // this.updateDiscussion(this.chat_id)
          this.refreshService.refreshChat$.next({company_id: this.data.company.id, chat_id: this.chat_id})
          this.initRefreshBoard()
        })
      )
    } else {
      this.addEmlChangeStatus(status)
    }
    // [4,98,99]
  }

  showError(error) {
    this.layoutService.showSnackBar({name: ''}, `You do not have permission to do this. ${error}`, SnackBarItem)
  }

  managerChangeStatus(status) {
    if (status.id == this.chat.status_id) {
      return
    }
    console.log("managerChangeStatus", status)
    console.log("managerChangeStatus", status.id)
    let relativeEmpls = this.chatEmlStats.filter(t => status.empl.filter(b => b.employee_id == t.employee_id || b.partner_company_id == t.partner_company_id).length == 0)

    if (relativeEmpls.length == 1) {
      let statEmpl = relativeEmpls[0]
      if (statEmpl.is_partner) {
        if (!!statEmpl.partnerCompanyStatus) {
          this.attachSubscriptions(
            this.membersService.editPartnerStatus(statEmpl.partnerCompanyStatus.id, {status_id: status.id}, this.data.company.id)
            .pipe(
              switchMap(val => {
                if ([3,4,6,97,98,99].includes(status.id)) {
                  return this.membersService.getTaskPartnerStatuses({task_operation_id: this.work.id, status_id: getLessStatuses(status.id), partner_company_id: statEmpl.partner_company_id}, this.data.company.id, '2').pipe(
                    switchMap(res => {
                      console.log("drop RES3", res)
                      if (res.length == 1 && !res[0].discussion_id) {
                        console.log("drop RES4", val)
                        let questionAlert =  this.bottomSheet.open(BackJobToPrevStatusComponent, {
                          hasBackdrop: true,
                          backdropClass: 'bottom-sheed-backdrop',
                          data: {
                            msg: marker(`Do you really want to convert personal partner status in job to: '${this.getOperationStatusName(status.id)}'?`)
                          }
                        });
                    
                        return questionAlert.afterDismissed()
                        .pipe(
                          switchMap(data => {
                            if (data && data.message == 'no') {
                              console.log("no");
                              return of(val)
                            } else if (data && data.message == 'yes') {
                              return this.membersService.editPartnerStatus(res[0].id, {status_id: status.id}, this.data.company.id).pipe(
                                map(() => val)
                              )
                            } else {
                              return of(val)
                            }
                          })
                        )
                      } else {
                        return of(val)
                      }
                    })
                  )
                } else {
                return of(val)
                }
              })
            ).subscribe(res => {
                this.update();
            // this.updateDiscussion(this.chat_id)
              this.refreshService.refreshChat$.next({company_id: this.data.company.id, chat_id: this.chat_id})
              this.initRefreshBoard()
            })
          )
        } else {
          this.attachSubscriptions(
            this.membersService.addPartnerStatus({
              status_id: status.id,
              company_id: this.data.company.id,
              task_id: this.task.id,
              task_operation_id: this.work.id,
              discussion_id: this.chat.id,
              partner_company_id: statEmpl.partner_company_id
            }, this.data.company.id)
            .pipe(
              switchMap(val => {
                if ([3,4,6,97,98,99].includes(status.id)) {
                  return this.membersService.getTaskPartnerStatuses({task_operation_id: this.work.id, status_id: getLessStatuses(status.id), partner_company_id: statEmpl.partner_company_id}, this.data.company.id, '2').pipe(
                    switchMap(res => {
                      console.log("drop RES3", res)
                      if (res.length == 1 && !res[0].discussion_id) {
                        console.log("drop RES4", val)
                        let questionAlert =  this.bottomSheet.open(BackJobToPrevStatusComponent, {
                          hasBackdrop: true,
                          backdropClass: 'bottom-sheed-backdrop',
                          data: {
                            msg: marker(`Do you really want to convert personal partner status in job to: '${this.getOperationStatusName(status.id)}'?`)
                          }
                        });
                    
                        return questionAlert.afterDismissed()
                        .pipe(
                          switchMap(data => {
                            if (data && data.message == 'no') {
                              console.log("no");
                              return of(val)
                            } else if (data && data.message == 'yes') {
                              return this.membersService.editPartnerStatus(res[0].id, {status_id: status.id}, this.data.company.id).pipe(
                                map(() => val)
                              )
                            } else {
                              return of(val)
                            }
                          })
                        )
                      } else {
                        return of(val)
                      }
                    })
                  )
                } else {
                return of(val)
                }
              })
            ).subscribe(resp => {
                this.update();
            // this.updateDiscussion(this.chat_id)
              this.refreshService.refreshChat$.next({company_id: this.data.company.id, chat_id: this.chat_id})
              this.initRefreshBoard()
            })
          )
        }
      } else if (statEmpl.is_partner_employee) {
        if (!!statEmpl.employeeStatus?.id) {
          this.attachSubscriptions(
            this.membersService.editPartnerEmployeeStatus(statEmpl.employeeStatus.id, {status_id: status.id}, this.data.company.id)
            .pipe(
              switchMap(val => {
                console.log("drop RES1", val)
                if ((this.iAmEmployee?.is_manager == 1 || this.checkIsManager(this.task, this.data.company, this.data.user)) && [3,4,6,97,98,99].includes(status.id)) {
                  console.log("drop RES2", val)
                  return this.membersService.getTaskPartnerEmployeeStatuses({task_operation_id: this.work.id, status_id: getLessStatuses(status.id), partner_employee_id: statEmpl.partner_employee_id, partner_company_id: statEmpl.partner_company_id}, this.data.company.id, '2').pipe(
                    switchMap(res => {
                      console.log("drop RES3", res)
                      if (res.length == 1 && !res[0].discussion_id) {
                        console.log("drop RES4", val)
                        let questionAlert =  this.bottomSheet.open(BackJobToPrevStatusComponent, {
                          hasBackdrop: true,
                          backdropClass: 'bottom-sheed-backdrop',
                          data: {
                            msg: marker(`Do you really want to convert personal partner employee status in job to: '${this.getOperationStatusName(status.id)}'?`)
                          }
                        });
                    
                        return questionAlert.afterDismissed()
                        .pipe(
                          switchMap(data => {
                            if (data && data.message == 'no') {
                              console.log("no");
                              return of(val)
                            } else if (data && data.message == 'yes') {
                              return this.membersService.editPartnerEmployeeStatus(res[0].id, {status_id: status.id}, this.data.company.id).pipe(
                                map(() => val)
                              )
                            } else {
                              return of(val)
                            }
                          })
                        )
                      } else {
                        return of(val)
                      }
                    })
                  )
                } else {
                  return of(val)
                }
              })
            ).subscribe(res => {
              
              this.update();
              // this.updateDiscussion(this.chat_id)
              this.refreshService.refreshChat$.next({company_id: this.data.company.id, chat_id: this.chat_id})
              this.initRefreshBoard()
            })
          )
        } else {
          this.attachSubscriptions(
            this.membersService.addPartnerEmployeeStatus({
              status_id: status.id,
              company_id: this.data.company.id,
              task_id: this.task.id,
              task_operation_id: this.work.id,
              discussion_id: this.chat.id,
              partner_company_id: statEmpl.partner_company_id,
              partner_employee_id: statEmpl.partner_employee_id
            }, this.data.company.id)
            .pipe(
              switchMap(val => {
                console.log("drop RES1", val)
                if ((this.iAmEmployee?.is_manager == 1 || this.checkIsManager(this.task, this.data.company, this.data.user)) && [3,4,6,97,98,99].includes(status.id)) {
                  console.log("drop RES2", val)
                  return this.membersService.getTaskPartnerEmployeeStatuses({task_operation_id: this.work.id, status_id: getLessStatuses(status.id), partner_employee_id: statEmpl.partner_employee_id, partner_company_id: statEmpl.partner_company_id}, this.data.company.id, '2').pipe(
                    switchMap(res => {
                      console.log("drop RES3", res)
                      if (res.length == 1 && !res[0].discussion_id) {
                        console.log("drop RES4", val)
                        let questionAlert =  this.bottomSheet.open(BackJobToPrevStatusComponent, {
                          hasBackdrop: true,
                          backdropClass: 'bottom-sheed-backdrop',
                          data: {
                            msg: marker(`Do you really want to convert personal partner employee status in job to: '${this.getOperationStatusName(status.id)}'?`)
                          }
                        });
                    
                        return questionAlert.afterDismissed()
                        .pipe(
                          switchMap(data => {
                            if (data && data.message == 'no') {
                              console.log("no");
                              return of(val)
                            } else if (data && data.message == 'yes') {
                              return this.membersService.editPartnerEmployeeStatus(res[0].id, {status_id: status.id}, this.data.company.id).pipe(
                                map(() => val)
                              )
                            } else {
                              return of(val)
                            }
                          })
                        )
                      } else {
                        return of(val)
                      }
                    })
                  )
                } else {
                  return of(val)
                }
              })
            ).subscribe(res => {
              
              this.update();
              // this.updateDiscussion(this.chat_id)
              this.refreshService.refreshChat$.next({company_id: this.data.company.id, chat_id: this.chat_id})
              this.initRefreshBoard()
            })
          )
        }
      } else {
        if (!!statEmpl.employeeStatus) {
          this.attachSubscriptions(
            this.membersService.editTaskEmployeeStatus(statEmpl.employeeStatus.id, {status_id: status.id}, this.data.company.id)
            .pipe(
              switchMap(val => {
                if ([3,4,6,97,98,99].includes(status.id)) {
                  return this.membersService.getTaskEmployeeStatuses({task_operation_id: this.work.id, status_id: getLessStatuses(status.id), employee_id: statEmpl.employee_id}, this.data.company.id, '2').pipe(
                    switchMap(res => {
                      console.log("manager Change RES3", res)
                      if (res.length == 1 && !res[0].discussion_id) {
                        console.log("manager Change RES4", val)
                        let questionAlert =  this.bottomSheet.open(BackJobToPrevStatusComponent, {
                          hasBackdrop: true,
                          backdropClass: 'bottom-sheed-backdrop',
                          data: {
                            msg: marker(`Do you really want to convert job personal status to: '${this.getOperationStatusName(status.id)}'?`)
                          }
                        });
                    
                        return questionAlert.afterDismissed()
                        .pipe(
                          switchMap(data => {
                            if (data && data.message == 'no') {
                              console.log("no");
                              return of(val)
                            } else if (data && data.message == 'yes') {
                              return this.membersService.editTaskEmployeeStatus(res[0].id, {status_id: status.id}, this.data.company.id).pipe(
                                map(() => val)
                              )
                            } else {
                              return of(val)
                            }
                          })
                        )
                      } else {
                        return of(val)
                      }
                    })
                  )
                } else {
                return of(val)
                }
              })
            ).subscribe(res => {
                this.update();
            // this.updateDiscussion(this.chat_id)
              this.refreshService.refreshChat$.next({company_id: this.data.company.id, chat_id: this.chat_id})
              this.initRefreshBoard()
            })
          )
        } else {
          this.attachSubscriptions(
            this.membersService.addTaskEmployeeStatus({
              status_id: status.id,
              company_id: this.data.company.id,
              task_id: this.task.id,
              task_operation_id: this.work.id,
              discussion_id: this.chat.id,
              employee_id: statEmpl.employee_id
            }, this.data.company.id)
            .pipe(
              switchMap(val => {
                if ([3,4,6,97,98,99].includes(status.id)) {
                  return this.membersService.getTaskEmployeeStatuses({task_operation_id: this.work.id, status_id: getLessStatuses(status.id), employee_id: statEmpl.employee_id}, this.data.company.id, '2').pipe(
                    switchMap(res => {
                      console.log("manager Change RES3", res)
                      if (res.length == 1 && !res[0].discussion_id) {
                        console.log("manager Change RES4", val)
                        let questionAlert =  this.bottomSheet.open(BackJobToPrevStatusComponent, {
                          hasBackdrop: true,
                          backdropClass: 'bottom-sheed-backdrop',
                          data: {
                            msg: marker(`Do you really want to convert job personal status to: '${this.getOperationStatusName(status.id)}'?`)
                          }
                        });
                    
                        return questionAlert.afterDismissed()
                        .pipe(
                          switchMap(data => {
                            if (data && data.message == 'no') {
                              console.log("no");
                              return of(val)
                            } else if (data && data.message == 'yes') {
                              return this.membersService.editTaskEmployeeStatus(res[0].id, {status_id: status.id}, this.data.company.id).pipe(
                                map(() => val)
                              )
                            } else {
                              return of(val)
                            }
                          })
                        )
                      } else {
                        return of(val)
                      }
                    })
                  )
                } else {
                return of(val)
                }
              })
            ).subscribe(resp => {
                this.update();
            // this.updateDiscussion(this.chat_id)
              this.refreshService.refreshChat$.next({company_id: this.data.company.id, chat_id: this.chat_id})
              this.initRefreshBoard()
            })
          )
        }
      }
    } else {
      const dialogRef = this.dialog.open(MobPersonalStatusesComponent, {
        panelClass: ['chats_modal', 'desk_statuses'],
        data: {
          company: this.data.company,
          task: this.task,
          work: this.work,
          chat: this.chat,
          host: this.host,
          imgRoute: this.data.imgRoute,
          user: this.data.user,
          statuses: this.statuses,
          selectedStatus: status,
          chatEmlStats: this.chatEmlStats,
          iAmEmployee: this.iAmEmployee,
          updateDiscussion: () => {
            this.update();
            // this.updateDiscussion(this.chat_id)
            this.refreshService.refreshChat$.next({company_id: this.data.company.id, chat_id: this.chat_id})
            this.initRefreshBoard()
          }
        }
      });
  
  
      this.attachSubscriptions(
        dialogRef.afterClosed().subscribe(result => {
            this.update();
            // this.updateDiscussion(this.chat_id)
          this.refreshService.refreshChat$.next({company_id: this.data.company.id, chat_id: this.chat_id})
          this.initRefreshBoard()
        })
      )
    }

  }

  startDragged() {
    document.body.classList.add("dragged");
  }
  
  stopDragged() {
    document.body.classList.remove("dragged");
  }
  
  addEmlChangeStatus(status) {
    console.log("addEmlChangeStatus", status)
    if (status.is_lock == 1) {
      return
    }
    this.personalStatusForm.patchValue({
      status_id: status.id
    })
  }
  


  onSubmit() {
    
    if (!this.form.valid) {
      this.submited = true;
      this.layoutService.showSnackBar({name: ''}, marker("You need to fill in all required fields"), SnackBarItem)
      return
    }

    let sbmtData = JSON.parse(JSON.stringify(this.form.value))

    if (!!sbmtData.planned_completed_at) {
      sbmtData.planned_completed_at = moment(sbmtData.planned_completed_at).format("X");
    } else {
      if (sbmtData.hasOwnProperty('planned_completed_at')) {
        delete sbmtData.planned_completed_at;
      }
    }

    delete sbmtData.date;
    this.isSubmit = true;
    this.submited = false;
    this.attachSubscriptions(
      this.chatService.editChat(this.data.chat.id, sbmtData, this.data.company.id)
      .pipe(
        switchMap(val => {
          if ([3,4,97,98,99].includes(this.form.value.status_id)) {
            return this.chatService.detectChats(this.data.company.id, this.data.chat.task_operation_id, [1,2,5,6]).pipe(
              map(x => x.headers.get('x-pagination-total-count')),
              switchMap(res => {
                if (res == 0) {
                  let questionAlert =  this.bottomSheet.open(BackJobToPrevStatusComponent, {
                    hasBackdrop: true,
                    backdropClass: 'bottom-sheed-backdrop',
                    data: {
                      msg: marker(`Do you really want to convert job to status: '${this.getOperationStatusName(this.form.value.status_id)}'?`)
                    }
                  });
              
                  return questionAlert.afterDismissed()
                  .pipe(
                    switchMap(data => {
                      if (data && data.message == 'no') {
                        console.log("no");
                        return of(val)
                      } else if (data && data.message == 'yes') {
                        return this.taskService.editWork(this.data.chat.task_operation_id, {status_id: this.form.value.status_id}, this.data.company.id).pipe(
                          map(() => val)
                        )
                      } else {
                        return of(val)
                      }
                    })
                  )
                } else {
                  return of(val)
                }
              })
            )
          } else {
            return of(val)
          }
        }) 
      ).subscribe(resp => {
        console.log(resp);
        this.data.chat.name = resp.name;
        this.data.chat.priority = resp.priority;
        this.data.chat.status_id = resp.status_id;
        this.isSubmit = false;
        this.close();
      })
    )
  }

  getOperationStatusName(id) {
    switch (+id) {
      case 1:
        return "Waiting";
        break;
      case 2:
        return "In process";
        break;
      case 3:
        return "To approve";
        break;
      case 4:
        return "Approved";
        break;
      case 5:
        return "To correct";
        break;
      case 6:
        return "Sources Needed";
        break;
      case 97:
        return "On hold";
        break;
      case 98:
        return "Ready";
        break;
      case 99:
        return "Canceled";
        break;
    }
  }

  getChatStatuses() {
    this.attachSubscriptions(
      this.chatService.getChatStatuses().subscribe(resp => {
        console.log("chat stats", resp)
        this.statuses = resp.slice();
      })
    )
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

}
