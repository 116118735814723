import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReplaySubject } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { MembersService } from 'src/app/shared/services/rest/members.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';

@Component({
  selector: 'app-move-jobs',
  templateUrl: './move-jobs.component.html',
  styleUrls: ['./move-jobs.component.scss']
})
export class MoveJobsComponent extends BaseClass implements OnInit, OnDestroy {
  public form: FormGroup;
  public isLoad: boolean = false;
  public isFormChange: boolean = false;
  public isSubmit: boolean = false;
  public numberRegEx = /\d*\.?\d{1,2}/;
  public page: number = 1;
  public pagination: any;
  public taskControl: FormControl = new FormControl();
  public tasks$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public tasks: any[] = [];
  constructor(
    private taskService: TaskService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<MoveJobsComponent>,
    private fb: FormBuilder,
    private membersService: MembersService,
    private layoutService: LayoutService
  ) { super() }

  ngOnInit(): void {
    console.log("MoveJobsComponent", this.data)

    this.form = this.fb.group({
      task_id: this.data.work.task_id,
      task_operation_id: this.data.work.id
    })

    this.attachSubscriptions(
      this.taskControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearch(resp))
    )

    this.attachSubscriptions(
      this.form.valueChanges.subscribe(() => this.isFormChange = true)
    )

    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        if (this.isFormChange) {
          this.layoutService.openBottomSheet(this.dialogRef);
        } else {
          this.close();
        }
      })
    )

    this.getTasks(this.page);
  }

  onSearch(resp) {
    if (!this.tasks) {
      return;
    }

    if (!resp) {
      this.tasks$.next(this.tasks.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.tasks$.next(
      this.tasks.filter(z => z.id.toString().indexOf(resp) > -1 || z.custom_id.toString().indexOf(resp) > -1 || z.internal_id.toString().indexOf(resp) > -1 || (z.name && z.name.toLowerCase().indexOf(resp) > -1))
    );
  }

  getTasks(page) {
    this.isLoad = true;
    this.attachSubscriptions(
      this.taskService.getTasksSelectNoExp(page, this.data.company.id).pipe(
        tap(el => {
          this.pagination = {
            'pageCount': el.headers.get('x-pagination-page-count'),
            'perPage': el.headers.get('x-pagination-per-page'),
            'totalCount': el.headers.get('x-pagination-total-count'),
            'currentPage': el.headers.get('x-pagination-current-page'),
          }
        })
      ).subscribe(resp => {
        this.isLoad = false;
        this.tasks.push(...resp.body)
        
        this.page = this.page + 1; 

        console.log(this.pagination, this.tasks);
        if (+this.pagination['currentPage'] != +this.pagination['pageCount']) {
          this.getTasks(this.page);
        } else {
          this.tasks$.next(this.tasks.slice())
        }

      })
    )
  }
  
  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  onSubmit() {
    
    this.isSubmit = true;
    console.log(this.form.value);
    this.attachSubscriptions(
      this.taskService.moveWork(this.form.value).subscribe(resp => {
        if (!!this.tasks.find(x => x.id == this.form.value.task_id)) {
          this.dialogRef.close({event: "update", data: resp, task: this.tasks.find(x => x.id == this.form.value.task_id)})
        } else {
          this.dialogRef.close({event: "update", data: resp})
        }
        this.isSubmit = false;
      },
      error => {
        this.isSubmit = false;
        this.data.showError()
        this.close()
      })
    )
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
