import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { tap } from 'rxjs/operators';
import { BaseClass } from '../../models/base-class';
import { LoadingService } from '../../services/rest/loading.service';
import { RefreshService } from '../../services/rest/refresh.service';

@Component({
  selector: 'app-loader-requests',
  templateUrl: './loader-requests.component.html',
  styleUrls: ['./loader-requests.component.scss']
})
export class LoaderRequestsComponent extends BaseClass implements OnInit, OnDestroy {
  public loaders: any[] = [];
  @Input() is_loader_expanded: boolean;
  @Output() HideMenu = new EventEmitter<any>();
  constructor(
    private refreshService: RefreshService,
    private ls: LoadingService
  ) { super() }

  ngOnInit(): void {
    this.ls.getRequests().pipe(
      tap(x => {
        if (!this.loaders.length || !this.loaders.find(z => z.target == x.target)) {
          this.loaders.push(x);
          if (x.value == 100) {
            setTimeout(() => {
              if (x.refresh) {
                if (x.type == 'task') {
                  x.refresh.forEach(x => {
                    this.refreshService.refreshTask$.next(x)
                  })
                }
              }
              this.loaders.splice(this.loaders.indexOf(x), 1)
            }, 1000)
          }
        } else {
          let index = this.loaders.indexOf(this.loaders.find(z => z.target == x.target))
          if (x.value != 100) {
            this.loaders.splice(index, 1, x)
          } else {
            if (x.refresh) {
              if (x.type == 'task') {
                x.refresh.forEach(x => {
                  this.refreshService.refreshTask$.next(x)
                })
              }
            }
            this.loaders.splice(index, 1)
          }
        }
      })
    ).subscribe(resp => {
      console.log(resp)
    })
  }

  ngOnDestroy(): void {
    this.clearSubscriptions();
  }

}
