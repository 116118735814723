import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { BehaviorSubject, Subject } from 'rxjs';
import { GlobalDataService } from '../common/global-data.service';
import { HttpRestService } from '../common/http-rest.service';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  public taskChatsData$: Subject<any> = new Subject();
  public chatData$: Subject<any> = new Subject();
  public postsData$: Subject<any> = new Subject();
  public deletedChats$: Subject<any> = new Subject();
  public chatModalData$: Subject<any> = new Subject();
  public unreadChats$: Subject<any> = new Subject();
  public isInChat$: BehaviorSubject<any> = new BehaviorSubject(0);
  public _isInChat$ = this.isInChat$.asObservable()

  public expandApiDiscussion: string = 'consistOfDiscussionPartition.partOfDiscussion.employee,consistOfDiscussionPartition.partOfDiscussion.lastPost.employee,consistOfDiscussionPartition.partOfDiscussion.lastPost.action.createdEmployee,consistOfDiscussionPartition.partOfDiscussion.lastPost.action.employee,partOfDiscussionPartition.consistOfDiscussion.employee,partOfDiscussionPartition.consistOfDiscussion.lastPost.employee,partOfDiscussionPartition.consistOfDiscussion.lastPost.action.createdEmployee,partOfDiscussionPartition.consistOfDiscussion.lastPost.action.employee,discussionRelated.relatedDiscussion.employee,discussionRelated.relatedDiscussion.lastPost.employee,discussionRelated.relatedDiscussion.lastPost.action.createdEmployee,discussionRelated.relatedDiscussion.lastPost.action.employee,partnerEmployeesStatuses,discussionPostDraft,pin,task,taskOperation,employee,status,lastPost.employee,lastPost.file,lastPost.action.employee,lastPost.action.createdEmployee,employeesStatuses.status,employeeStatus.status,partnerCompaniesStatuses';

  constructor(
    private http: HttpRestService,
    private globalDataService: GlobalDataService,
  ) { }

  getPostsDataStream() {
    return this.postsData$.asObservable();
  }

  getTaskChatsDataStream() {
    return this.taskChatsData$.asObservable();
  }

  getChatsDataStream() {
    return this.chatData$.asObservable();
  }

  getDeletedChats() {
    return this.deletedChats$.asObservable();
  }

  getChatsModalDataStream() {
    return this.chatModalData$.asObservable();
  }

  unregisterRelated(data, company_id) {
    return this.http.executePost<any>('/api/discussion-related/unregister/', data, true, {
      params: new HttpParams().set("company_id", company_id)
    })
  }

  unregisterPartition(data, company_id) {
    return this.http.executePost<any>('/api/discussion-partition/unregister/', data, true, {
      params: new HttpParams().set("company_id", company_id)
    })
  }

  createChat(data, company_id) {
    return this.http.executePost<any>('/api/discussion/', data, true, {
      params: new HttpParams().set("company_id", company_id)
    })
  }

  getUnreadChatsStream() {
    return this.unreadChats$.asObservable();
  }

  deleteChat(id, company_id) {
    return this.http.executeDelete<any>(`/api/discussion/${id}/`, true, {
      params: new HttpParams().set("company_id", company_id)
    })
  }

  editChat(id, data, company_id) {
    return this.http.executePost<any>(`/api/discussion/${id}/`, data, true, {
      params: new HttpParams().set("company_id", company_id)
    })
  }

  deleteChatCheck(id, company_id) {
    return this.http.executeDelete<any>(`/api/discussion-post-reminder/${id}/`, true, {
      params: new HttpParams().set("company_id", company_id)
    })
  }

  editChatCheck(id, data, company_id) {
    return this.http.executePost<any>(`/api/discussion-post-reminder/${id}/`, data, true, {
      params: new HttpParams().set("company_id", company_id)
    })
  }

  addChatCheck(data, company_id) {
    return this.http.executePost<any>(`/api/discussion-post-reminder/`, data, true, {
      params: new HttpParams().set("company_id", company_id)
    })
  }

  moveChat(data) {
    return this.http.executePost<any>('/api/discussion/move/', data)
  }

  getChatStatuses() {
    return this.globalDataService.getOperationStatus()
  }

  getChatsDyn(page, company_id, id, filter:any = false, per_page:any = '200') {
    let filter_params = new HttpParams().set("company_id", company_id).set('page', page).set('per-page', per_page).set("task_id", id).set("expand", this.expandApiDiscussion)

    console.log("getChatsDyn", filter)
    if (filter) {
      Object.keys(filter).forEach(element => {
        console.log("filter[element]", filter[element].length)
        if (element == 'status_id' && filter[element].length) {
          if (!filter[element].includes(0)){
            filter_params = filter_params.set(element, filter[element].join(","));
          }
        } else {
          filter_params = filter_params.set(element, filter[element]);
        }
      })
    }

    return this.http.executeGet<any>(`/api/discussion/`, true, {
      observe: 'response',
      params: filter_params
    })
  }

  getChats(company_id, id, filter:any = false) {
    let filter_params = new HttpParams().set("company_id", company_id).set('per-page', '200').set("task_id", id).set("expand", this.expandApiDiscussion)

    console.log("getChats", filter)
    if (filter) {
      Object.keys(filter).forEach(element => {
        console.log("filter[element]", filter[element], filter[element].length)
        if (element == 'status_id' && filter[element] && filter[element].length) {
          if (!filter[element].includes(0)){
            filter_params = filter_params.set(element, filter[element].join(","));
          }
        } else {
          filter_params = filter_params.set(element, filter[element]);
        }
      })
    }

    return this.http.executeGet<any>(`/api/discussion/`, true, {
      params: filter_params
    })
  }

  getCaseChats(page, company_id, task_id, filter:any = false) {
    let filter_params = new HttpParams().set("page", page).set("company_id", company_id).set("task_id", task_id).set("expand",this.expandApiDiscussion)
    console.log("getChats", filter)
    if (filter) {
      Object.keys(filter).forEach(element => {
        console.log("filter[element]", filter[element].length)
        if (element == 'status_id' && filter[element].length) {
          if (!filter[element].includes(0)){
            filter_params = filter_params.set(element, filter[element].join(","));
          }
        } else {
          filter_params = filter_params.set(element, filter[element]);
        }
      })
    }

    return this.http.executeGet<any>(`/api/discussion/`, true, {
      observe: 'response',
      params: filter_params
    })
  }

  getAllFileChatsCount(company_id, task_id, task_operation_id, data_file_id, filter) {
    let filter_params = new HttpParams().set("company_id", company_id).set("per-page", "0").set("data_file_id", data_file_id)
    if (!!task_id) {
      filter_params = filter_params.set("task_id", task_id)
    }

    if (!!task_operation_id) {
      filter_params = filter_params.set("task_operation_id", task_operation_id)
    }
    
    Object.keys(filter).forEach(element => {
      if (element == 'discussion_other_employee_status_id' || element == 'data_file_id') {
        return
      }
      if ((element == 'status_id' || element == 'group_id' || element == 'priority' || element == 'employee_id' || element == 'operation_employee_id' || element == 'client_employee_id' || element == 'operation_id' || element == 'id') && !!filter[element] && filter[element] != null && filter[element].length) {
        if (element == 'status_id' && !!filter.discussion_other_employee_status_id) {
          filter_params = filter_params.set('discussion_other_employee_status_id', filter[element].join(","));
        } else {
          filter_params = filter_params.set(element, filter[element].join(","));
        }
      } else if ((filter[element] === '0' || filter[element] === 0) && ['group_id', 'is_content_url', 'content_type_id', 'operation_employee_id', 'client_employee_id', 'operation_partner_company_id'].includes(element)) {
        filter_params = filter_params.set(element, filter[element]);
      } else if (filter[element] != '0' && filter[element] != '' && !!filter[element] && filter[element] != null) {
        if (element.indexOf('at_from') != -1 || element.indexOf('at_to') != -1) {
          filter_params = filter_params.set(element, moment(filter[element]._d).format("X"));
        } else {
          filter_params = filter_params.set(element, filter[element]);
        }
      }
    });

    return this.http.executeGet<any>(`/api/discussion/`, true, {
      observe: 'response',
      params: filter_params
    })
  }
  getAllChatsCount(company_id, status_id, task_id, task_operation_id, filter) {
    let filter_params = new HttpParams().set("company_id", company_id).set("status_id", status_id).set("per-page", "0")
    if (!!task_id) {
      filter_params = filter_params.set("task_id", task_id)
    }

    if (!!task_operation_id) {
      filter_params = filter_params.set("task_operation_id", task_operation_id)
    }
    
    Object.keys(filter).forEach(element => {
      if (element == 'discussion_other_employee_status_id' || element == 'status_id') {
        return
      }
      if (element == 'task_custom_id' && !!filter[element] && filter[element].indexOf('-') != -1) {
        let start = +filter[element].trim().split('-')[0];
        let end = +filter[element].trim().split('-')[1];
        let val = []
        for (let index = start; index <= end; index++) {
          val.push(index);
        }
        filter_params = filter_params.set(element, val.join(","));
      } else if ((element == 'status_id' || element == 'group_id' || element == 'priority' || element == 'employee_id' || element == 'operation_employee_id' || element == 'client_employee_id' || element == 'operation_id' || element == 'id') && !!filter[element] && filter[element] != null && filter[element].length) {
        if (element == 'status_id' && !!filter.discussion_other_employee_status_id) {
          filter_params = filter_params.set('discussion_other_employee_status_id', filter[element].join(","));
        } else {
          filter_params = filter_params.set(element, filter[element].join(","));
        }
      } else if (element == 'data_file_id' && filter[element] !== '') {
        filter_params = filter_params.set(element, filter[element]);
      } else if ((filter[element] === '0' || filter[element] === 0) && ['group_id', 'is_content_url', 'content_type_id', 'operation_employee_id', 'client_employee_id', 'operation_partner_company_id'].includes(element)) {
        filter_params = filter_params.set(element, filter[element]);
      } else if (filter[element] != '0' && filter[element] != '' && !!filter[element] && filter[element] != null) {
        if (element.indexOf('at_from') != -1 || element.indexOf('at_to') != -1) {
          filter_params = filter_params.set(element, moment(filter[element]._d).format("X"));
        } else {
          filter_params = filter_params.set(element, filter[element]);
        }
      }
    });

    return this.http.executeGet<any>(`/api/discussion/`, true, {
      observe: 'response',
      params: filter_params
    })
  }

  getChatsAvailabilityCount(company_id, content_type_id, discussion_channel_id, status_id) {
    return this.http.executeGet<any>(`/api/discussion/`, true, {
      observe: 'response',
      params: new HttpParams().set("company_id", company_id).set("content_type_id", content_type_id).set("status_id", status_id).set("discussion_channel_id", discussion_channel_id).set("per-page", "0")
    })
  }

  getChatsCount(company_id, id, status_id) {
    return this.http.executeGet<any>(`/api/discussion/`, true, {
      observe: 'response',
      params: new HttpParams().set("company_id", company_id).set("task_operation_id", id).set("status_id", status_id).set("per-page", "0")
    })
  }

  detectChats(company_id, id, status_id) {
    return this.http.executeGet<any>(`/api/discussion/`, true, {
      observe: 'response',
      params: new HttpParams().set("company_id", company_id).set("task_operation_id", id).set("status_id", status_id.join(',')).set("per-page", "0")
    })
  }

  getTaskChatsCount(company_id, id, status_id) {
    return this.http.executeGet<any>(`/api/discussion/`, true, {
      observe: 'response',
      params: new HttpParams().set("company_id", company_id).set("task_id", id).set("status_id", status_id).set("per-page", "0")
    })
  }

  getGlobalChatsCount(company_id, status_id) {
    return this.http.executeGet<any>(`/api/discussion/`, true, {
      observe: 'response',
      params: new HttpParams().set("company_id", company_id).set("status_id", status_id).set("per-page", "0")
    })
  }

  getUnreadChats(company_id) {
    return this.http.executeGet<any>(`/api/discussion/posts-not-read/`, true, {
      params: new HttpParams().set("company_id", company_id).set('per-page','100')
    })
  }

  getChatsExpand(page, id, filter, per_page?, task_id?, task_operation_id?) {
    let filter_params = new HttpParams().set("company_id", id).set('page', page).set("per-page", per_page);

    if (task_id) {
      filter_params = filter_params.set("task_id", task_id)
    }

    if (task_operation_id) {
      filter_params = filter_params.set("task_operation_id", task_operation_id)
    }
    
    console.log("filter getChatsExpand", filter)
    Object.keys(filter).forEach(element => {
      if (element == 'discussion_other_employee_status_id') {
        return
      }
      if (element == 'task_custom_id' && !!filter[element] && filter[element].indexOf('-') != -1) {
        let start = +filter[element].trim().split('-')[0];
        let end = +filter[element].trim().split('-')[1];
        let val = []
        for (let index = start; index <= end; index++) {
          val.push(index);
        }
        filter_params = filter_params.set(element, val.join(","));
      } else if ((element == 'no_channel_id' || element == 'channel_id' || element == 'discussion_channel_id' || element == 'status_id' || element == 'group_id' || element == 'priority' || element == 'employee_id' || element == 'operation_employee_id' || element == 'client_employee_id' || element == 'operation_id' || element == 'id') && !!filter[element] && filter[element] != null && filter[element].length) {
        if (element == 'status_id' && !!filter.discussion_other_employee_status_id) {
          filter_params = filter_params.set('discussion_other_employee_status_id', filter[element].join(","));
        } else {
          filter_params = filter_params.set(element, filter[element].join(","));
        }
        
      } else if (element == 'data_file_id' && filter[element] !== '') {
        filter_params = filter_params.set(element, filter[element]);
      } else if ((filter[element] === '0' || filter[element] === 0) && ['group_id', 'is_content_url', 'content_type_id', 'operation_employee_id', 'client_employee_id', 'operation_partner_company_id'].includes(element)) {
        filter_params = filter_params.set(element, filter[element]);
      } else if (filter[element] != '0' && filter[element] != '' && !!filter[element] && filter[element] != null) {
        if (element.indexOf('at_from') != -1 || element.indexOf('at_to') != -1) {
          filter_params = filter_params.set(element, moment(filter[element]._d).format("X"));
        } else {
          filter_params = filter_params.set(element, filter[element]);
        }
      }
    });
    
    filter_params = filter_params.set("expand", this.expandApiDiscussion + ',employees,employees.employee,employeesStatuses,partnerCompanies,partnerCompanies.partnerCompany,partnerCompanies.partnerCompany.avatarFile,partnerCompaniesStatuses')
    
    return this.http.executeGet<any>('/api/discussion/', true, {
      observe: 'response',
      params: filter_params
    })
  }

  getChatsUseOutExpand(page, id, filter, per_page?, task_id?, task_operation_id?) {
    let filter_params = new HttpParams().set("company_id", id).set("expand", this.expandApiDiscussion).set('page', page).set("per-page", per_page);

    if (task_id) {
      filter_params = filter_params.set("task_id", task_id)
    }

    if (task_operation_id) {
      filter_params = filter_params.set("task_operation_id", task_operation_id)
    }
    
    console.log("filter getChatsUseOutExpand", filter)
    Object.keys(filter).forEach(element => {
      if ((element == 'status_id' || element == 'group_id' || element == 'priority' || element == 'employee_id' || element == 'client_employee_id' || element == 'operation_id' || element == 'id') && filter[element] && filter[element].length) {
        filter_params = filter_params.set(element, filter[element].join(","));
      } else if ((filter[element] === '0' || filter[element] === 0) && ['is_content_url', 'content_type_id', 'operation_employee_id', 'client_employee_id', 'operation_partner_company_id'].includes(element)) {
        filter_params = filter_params.set(element, filter[element]);
      } else if (filter[element] != '0' && filter[element] != '') {
        if (element.indexOf('at_from') != -1 || element.indexOf('at_to') != -1) {
          filter_params = filter_params.set(element, moment(filter[element]._d).format("X"));
        } else {
          filter_params = filter_params.set(element, filter[element]);
        }
      }
    });
    
    return this.http.executeGet<any>('/api/discussion/', true, {
      observe: 'response',
      params: filter_params
    })
  }

  getCompanyChats(company_id, statuses, page, per_page, sortValue:string = 'updated_desc') {
    return this.http.executeGet<any>(`/api/discussion/`, true, {
      observe: 'response',
      params: new HttpParams().set("company_id", company_id).set("expand",this.expandApiDiscussion).set("per-page", per_page).set("status_id", statuses.join(",")).set("order_by", sortValue).set("page", page)
    })
  }

  getTasksChats(company_id, task_ids) {
    return this.http.executeGet<any>(`/api/discussion/`, true, {
      params: new HttpParams().set("company_id", company_id).set('task_id', task_ids.join(',')).set("per-page", "200").set("expand",this.expandApiDiscussion)
    })
  }

  getTasksChatsCount(company_id, task_ids) {
    return this.http.executeGet<any>(`/api/discussion/`, true, {
      observe: 'response',
      params: new HttpParams().set("company_id", company_id).set('task_id', task_ids.join(',')).set("per-page", "0")
    })
  }

  getTaskChats(company_id, task_ids, page) {
    return this.http.executeGet<any>(`/api/discussion/`, true, {
      observe: 'response',
      params: new HttpParams().set("company_id", company_id).set("page", page).set('task_id', task_ids.join(',')).set("per-page", "100").set("expand",this.expandApiDiscussion)
    })
  }

  getAllTasksChats(task_ids) {
    return this.http.executeGet<any>(`/api/discussion/`, true, {
      params: new HttpParams().set('task_id', task_ids.join(',')).set('v20220303', '1').set("per-page", "200").set("expand",this.expandApiDiscussion)
    })
  }

  getWorkChats(task_operation_id, company_id) {
    return this.http.executeGet<any>(`/api/discussion/`, true, {
      params: new HttpParams().set("company_id", company_id).set("task_operation_id", task_operation_id).set("expand",this.expandApiDiscussion).set("per-page", "200")
    })
  }
  
  getChat(id) {
    return this.http.executeGet<any>(`/api/discussion/${id}/`)
  }
  
  getSmartPosts(id, company_id, order_by?, per_page?, id_from?, id_to?, is_reminder?, is_reminder_done?) {
    let filter_params = new HttpParams().set("company_id", company_id).set('page', '1').set("discussion_id", id).set("expand","discussionPostReminder,discussionPostFiles.file.document,replyToPost.discussionPostFiles.file.document,discussionPostFiles.file.fileOriginalData,replyToPost.discussionPostFiles.file.fileOriginalData,employee,file,replies,replyToPost.file,replyToPost.employee,replyToPost.discussionPostFiles.file,discussionPostFiles.file,replyToPostFile.file,action.employee,action.createdEmployee")

    if (order_by) {
      filter_params = filter_params.set("order_by", order_by)
    }
    if (is_reminder) {
      filter_params = filter_params.set("is_reminder_set", '1')
      // filter_params = filter_params.set("is_reminder_done", '0')
    }
    
    if (is_reminder_done != undefined) {
      filter_params = filter_params.set("is_reminder_done", is_reminder_done)
    }

    if (id_from) {
      filter_params = filter_params.set("id_from", id_from)
    }

    if (id_to) {
      filter_params = filter_params.set("id_to", id_to)
    }

    if (per_page) {
      filter_params = filter_params.set("per-page", per_page)
    } else {
      filter_params = filter_params.set("per-page", '30')
    }
    return this.http.executeGet<any>(`/api/discussion-post/`, true, {
      observe: 'response',
      params: filter_params
    })
  }


  getPosts(id, page, company_id, order_by?, per_page?) {
    let filter_params = new HttpParams().set("company_id", company_id).set("per-page", '30').set("discussion_id", id).set("expand","discussionPostFiles.file.document,replyToPost.discussionPostFiles.file.document,discussionPostFiles.file.fileOriginalData,replyToPost.discussionPostFiles.file.fileOriginalData,employee,file,replies,replyToPost.file,replyToPost.employee,replyToPost.discussionPostFiles.file,discussionPostFiles.file,replyToPostFile.file,action.employee,action.createdEmployee").set("page", page)

    if (order_by) {
      filter_params = filter_params.set("order_by", order_by)
    }
    if (per_page) {
      filter_params = filter_params.set("per-page", per_page)
    } else {
      filter_params = filter_params.set("per-page", '30')
    }
    return this.http.executeGet<any>(`/api/discussion-post/`, true, {
      observe: 'response',
      params: filter_params
    })
  }

  searchPosts(id, page, q, company_id) {
    return this.http.executeGet<any>(`/api/discussion-post/`, true, {
      observe: 'response',
      params: new HttpParams().set("company_id", company_id).set("per-page", '200').set("q", q).set("discussion_id", id).set("expand","discussionPostFiles.file.document,replyToPost.discussionPostFiles.file.document,discussionPostFiles.file.fileOriginalData,replyToPost.discussionPostFiles.file.fileOriginalData,employee,file,replyToPost.employee,replyToPost.discussionPostFiles.file,discussionPostFiles.file,replyToPostFile.file,replies,replyToPost.file,action.employee,action.createdEmployee").set("page", page)
    })
  }

  getPinnedPosts(id, company_id) {
    return this.http.executeGet<any>(`/api/discussion-post/`, true, {
      params: new HttpParams().set("company_id", company_id).set("discussion_id", id).set("is_pinned", "1").set("expand","discussionPostFiles.file.document,replyToPost.discussionPostFiles.file.document,discussionPostFiles.file.fileOriginalData,replyToPost.discussionPostFiles.file.fileOriginalData,employee,file,replyToPost.employee,replyToPost.discussionPostFiles.file,discussionPostFiles.file,replyToPostFile.file,replies,replyToPost.file,action.employee,action.createdEmployee").set("per-page", "200")
    })
  }

  getTargetPost(chat_id, id, company_id) {
    return this.http.executeGet<any>(`/api/discussion-post/`, true, {
      params: new HttpParams().set("company_id", company_id).set("discussion_id", chat_id).set("id", id).set("expand","discussionPostFiles.file.document,replyToPost.discussionPostFiles.file.document,discussionPostFiles.file.fileOriginalData,replyToPost.discussionPostFiles.file.fileOriginalData,employee,file,replyToPost.employee,replyToPost.discussionPostFiles.file,discussionPostFiles.file,replyToPostFile.file,replies,replyToPost.file,action.employee,action.createdEmployee")
    })
  }

  getChatRead(id, company_id) {
    return this.http.executeGet<any>(`/api/discussion/read/`, true, {
      params: new HttpParams().set("company_id", company_id).set("id", id).set("expand","employee")
    })
  }

  postChatRead(chat_id, is_typing, company_id, text?, reply_to_post_id?, update_post_id?) {
    let data: any = {}
    data.is_typing = is_typing;
    
    if (text != undefined) {
      data.text = text;
    }

    if (reply_to_post_id != undefined) {
      data.reply_to_post_id = reply_to_post_id;
    }

    if (update_post_id != undefined) {
      data.update_post_id = update_post_id;
    }

    return this.http.executePost<any>(`/api/discussion/read/`, data, true, {
      params: new HttpParams().set("company_id", company_id).set('id', chat_id).set("expand","employee")
    })
  }

  copyPost(data, company_id) {
    return this.http.executePost<any>(`/api/discussion-post/copy/`, data, true, {
      params: new HttpParams().set("company_id", company_id)
    })
  }

  sendPost(data, company_id) {
    return this.http.executePost<any>(`/api/discussion-post/`, data, true, {
      params: new HttpParams().set("company_id", company_id)
    })
  }

  editPost(id, data, company_id) {
    return this.http.executePost<any>(`/api/discussion-post/${id}/`, data, true, {
      params: new HttpParams().set("company_id", company_id)
    })
  }

  deletePostFile(id, company_id) {
    return this.http.executeDelete<any>(`/api/discussion-post-file/${id}/`, true, {
      params: new HttpParams().set("company_id", company_id)
    })
  }

  deletePost(id, company_id) {
    return this.http.executeDelete<any>(`/api/discussion-post/${id}/`, true, {
      params: new HttpParams().set("company_id", company_id)
    })
  }
  
  getPinnedChats(company_id) {
    return this.http.executeGet<any>(`/api/discussion-pin/`, true, {
      params: new HttpParams().set("company_id", company_id)
    })
  }
  
  pinChat(data, company_id) {
    return this.http.executePost<any>(`/api/discussion-pin/`, data, true, {
      params: new HttpParams().set("company_id", company_id)
    })
  }

  editPinnedChat(id, data, company_id) {
    return this.http.executePost<any>(`/api/discussion-pin/${id}/`, data, true, {
      params: new HttpParams().set("company_id", company_id)
    })
  }

  deletePinnedChat(id, company_id) {
    return this.http.executeDelete<any>(`/api/discussion-pin/${id}/`, true, {
      params: new HttpParams().set("company_id", company_id)
    })
  }



}
