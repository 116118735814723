import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpRestService } from '../common/http-rest.service';

@Injectable({
  providedIn: 'root'
})
export class QuestsService {

  constructor(
    private http: HttpRestService,
    private httpClient: HttpClient
    ) { }

    getQuests(company_id, page) {
      return this.http.executeGet<any>(`/api/employee-application/`, true, {
        observe: 'response',
        params: new HttpParams().set("company_id", company_id).set('page', page).set('per-page', '100')
      })
    }

    getQuestsDyn(page, company_id, per_page = '200') {
      return this.http.executeGet<any>(`/api/employee-application/`, true, {
        observe: 'response',
        params: new HttpParams().set("company_id", company_id).set('page', page).set('per-page', per_page)
      })
    }

    getQuest(id) {
      return this.http.executeGet<any>(`/api/employee-application/${id}/`)
    }

    getQuestStatuses() {
      return this.http.executeGet<any>(`/api/employee-application-status/`)
    }

    getQuestForm(company_id, application_id) {
      return this.http.executeGet<any>(`/api/employee-application-field/`, true, {
        params: new HttpParams().set("company_id", company_id).set('application_id', application_id).set('expand', 'company,application').set("per-page", "100")
      })
    }

    getFields(page, company_id, application_id, per_page = '200') {
      return this.http.executeGet<any>(`/api/employee-application-field/`, true, {
        observe: 'response',
        params: new HttpParams().set("company_id", company_id).set('application_id', application_id).set('expand', 'company,application').set('page', page).set("per-page", per_page)
      })
    }

    getQuestEmpl(company_id, filter = {}, page = '1') {
      let filter_params = new HttpParams().set("company_id", company_id).set('page', page).set("per-page", "100").set('expand', 'file,group,operation,status,application')
      console.log("getQuestEmpl FILTER !!!", filter)
      if (filter) {
        Object.keys(filter).forEach(element => {
            filter_params = filter_params.set(element, filter[element]);
        });
      }
  
      return this.http.executeGet<any>(`/api/employee-application-data/`, true, {
        observe: 'response',
        params: filter_params
      })
    }

    getQuestEmplCount(company_id, employee_id) {
      return this.http.executeGet<any>(`/api/employee-application-data/`, true, {
        observe: 'response',
        params: new HttpParams().set("company_id", company_id).set("employee_id", employee_id).set('page', '1').set("per-page", "0")
      })
    }

    getOneFieldEmpl(company_id, id) {
      let filter_params = new HttpParams().set("company_id", company_id).set('expand', 'file')
  
      return this.http.executeGet<any>(`/api/employee-application-data/${id}/`, true, {
        params: filter_params
      })
    }

    applyToEmployee(data) {
      return this.http.executePost<any>(`/api/employee-application/apply-to-employee/`, data)
    }

    addField(data) {
      return this.http.executePost<any>(`/api/employee-application-field/`, data)
    }

    addFieldToEmpl(data) {
      return this.http.executePost<any>(`/api/employee-application-data/`, data)
    }

    saveFieldByEmpl(id, data) {
      return this.http.executePost<any>(`/api/employee-application-data/${id}/`, data)
    }

    saveFileByEmpl(id, data) {
      return this.httpClient.post(`${environment.host}/api/employee-application-data/${id}/`, data, {
          reportProgress: true,
          observe: 'events',
          headers: {"Content-Type": "multipart/form-data"}
        })
    }
  
    createQuest(data) {
      return this.http.executePost<any>(`/api/employee-application/`, data)
    }
  
    editQuest(id, data) {
      return this.http.executePost<any>(`/api/employee-application/${id}/`, data)
    }

    editField(id, data) {
      return this.http.executePost<any>(`/api/employee-application-field/${id}/`, data)
    }

    editDataField(id, data, company_id) {
      return this.http.executePost<any>(`/api/employee-application-data/${id}/`, data, true, {
        params: new HttpParams().set("company_id", company_id)
      })
    }
  
    deleteQuest(id) {
      return this.http.executeDelete<any>(`/api/employee-application/${id}/`)
    }

    deleteField(id) {
      return this.http.executeDelete<any>(`/api/employee-application-field/${id}/`)
    }

    deleteDataField(id) {
      return this.http.executeDelete<any>(`/api/employee-application-data/${id}/`)
    }
}
