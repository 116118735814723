import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { DeleteAlertComponent } from 'src/app/shared/global_components/delete-alert/delete-alert.component';
import { BaseClass } from 'src/app/shared/models/base-class';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { environment } from 'src/environments/environment';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { FileService } from 'src/app/shared/services/rest/file.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';

@Component({
  selector: 'app-note-relations',
  templateUrl: './note-relations.component.html',
  styleUrls: ['./note-relations.component.scss']
})
export class NoteRelationsComponent extends BaseClass implements OnInit, OnDestroy {
  public relations: any;
  public imgRoute: any;
  public host: any = environment.host;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private taskService: TaskService,
    public dialogRef: MatDialogRef<NoteRelationsComponent>,
    private dialog: MatDialog,
    private fileService: FileService,
    private companyService: CompanyService,
    private sm: StorageManagerService,
    private bottomSheet: MatBottomSheet,
  ) { super() }

  ngOnInit(): void {
    console.log("NoteRelationsComponent", this.data)
    this.getImgRoute();
    this.getRelations();
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  togglePartner(e, item) {
    item.disabled = true
    this.companyService.editRelation(item.id, {is_copyable: Number(e.checked)}, this.data.company_id).subscribe(resp => {
      item.is_copyable = Number(e.checked);
      item.disabled = false;
    })
  }

  getRelations() {
    this.companyService.getNoteRelations(this.data.company_id, {document_id: this.data.note.id}).subscribe(resp => {
      console.log("getClients", resp);
      this.relations = resp;
    })
  }

  close() {
    this.dialogRef.close({event: "close", data: false});
  }

  deleteRelation(item, i) {
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: {},
        target: marker("note relation")
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        this.attachSubscriptions(
          this.companyService.deleteNoteRelation(item.id, this.data.company_id).subscribe(resp => {
            console.log(resp)
            this.relations.splice(i,1);
          })
        )
      }
    });
  
  }

  ngOnDestroy() {
    this.clearSubscriptions()
  }

}
