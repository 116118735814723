import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { interval, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-update-channel-btn',
  templateUrl: './update-channel-btn.component.html',
  styleUrls: ['./update-channel-btn.component.scss']
})
export class UpdateChannelBtnComponent implements OnInit, OnDestroy {
  @Input() data: any;
  @Output() upClick = new EventEmitter<any>();

  public intervalSub: Subscription;

  constructor() {}

  ngOnInit(): void {
    this.data.can_update_at = Math.trunc(moment.duration(moment(this.data.data_updated_at*1000).clone().add(1, 'days').diff(moment())).asSeconds()) >= 0 ? Math.trunc(moment.duration(moment(this.data.data_updated_at*1000).clone().add(1, 'days').diff(moment())).asSeconds()) : 0
    console.log("UpdateChannelBtnComponent", this.data);
    console.log("this.data.can_update_at", this.data.can_update_at)

    this.intervalSub = interval(1000).pipe(
        tap(x => {
          this.data.can_update_at = this.data.can_update_at - 1
        })
      ).subscribe(resp => {
        // console.log(this.data.id, resp);

        if (this.data.can_update_at <= 0) {
          console.log('uns')
          this.data.can_update_at = 0
          this.intervalSub.unsubscribe()
        }
      })
  }

  onClick() {
    this.upClick.emit()
  }

  ngOnDestroy(): void {
    if (this.intervalSub) {
      this.intervalSub.unsubscribe()
    }
  }
}
