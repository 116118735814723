import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { forkJoin } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DeleteAlertComponent } from 'src/app/shared/global_components/delete-alert/delete-alert.component';
import { BaseClass } from 'src/app/shared/models/base-class';
import { MembersService } from 'src/app/shared/services/rest/members.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { AddAdditionalEmployeesComponent } from '../add-additional-employees/add-additional-employees.component';
import { EditAdditionalEmployeesComponent } from '../edit-additional-employees/edit-additional-employees.component';

@Component({
  selector: 'app-additional-employees',
  templateUrl: './additional-employees.component.html',
  styleUrls: ['./additional-employees.component.scss']
})
export class AdditionalEmployeesComponent extends BaseClass implements OnInit, OnDestroy {
  public additionalEmpls;
  public is_change: boolean = false;
  public filter:any = {task_id: this.data.task.id, task_operation_id: this.data.work.id, is_manager: 0};
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AdditionalEmployeesComponent>,
    private dialog: MatDialog,
    private taskService: TaskService,
    private bottomSheet: MatBottomSheet,
    private membersService: MembersService
  ) { super() }

  ngOnInit(): void {
    console.log(this.data);
    if (this.data.discussion) {
      this.filter.discussion_id = this.data.discussion.id
    }

    this.getAdditionalEmpl()
  }

  getAdditionalEmpl() {
    let arr = [
      this.membersService.getMembers(this.filter, this.data.company.id).pipe(
        tap(empls => {
          empls.map(b => {
            b.is_partner = false;
          })
        })
      ),
      this.membersService.getTaskPartners(this.filter, this.data.company.id).pipe(
        tap(empls => {
          empls.map(b => {
            b.is_partner = true;
          })
        })
      )
    ]

    if (this.data.company && this.data.company.id != this.data.work.company_id) {
      arr.push(
        this.membersService.getTaskPartnerEmployees(this.filter, this.data.company.id, this.data.company.id).pipe(
          tap(empls => {
            empls.map(b => {
              b.is_partner_employee = true;
              b.employeeStatus = b.partnerEmployeeStatus;
            })
          })
        )
      )
    }
    this.attachSubscriptions(
      forkJoin(arr).subscribe(resp => {
        console.log("getDiscussionAddEmployees", resp);
        if (!resp[2]) {
          resp[2] = []
        }
        this.additionalEmpls = [...resp[0], ...resp[1], ...resp[2]]

        if (this.additionalEmpls.length == 0 && !this.data.discussion) {
          this.addAdditionalEmplToWork()
        }
        console.log("JOB this.additionalEmpls", this.additionalEmpls)
      })
    )
  }

  checkIsManager(task, company, _user) { 
    return task?.managers.filter(x => x.user_id == _user.id).length > 0 || task?.group?.managers.filter(x => x.user_id == _user.id).length > 0 || company?.permissions?.includes('admin') || company?.permissions.includes('manager'); 
  }
  
  addAdditionalEmplToWork() {
    const dialogRef = this.dialog.open(AddAdditionalEmployeesComponent, {
      disableClose: true,
      data: {
        user: this.data.user,
        company: this.data.company,
        task: this.data.task,
        work: this.data.work,
        discussion: this.data.discussion
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result && result.event == "add") {
          this.is_change = true
          this.getAdditionalEmpl();
        }
      })
    )
  }

  goToEditAddEmpl(addEmpl) {
    const dialogRef = this.dialog.open(EditAdditionalEmployeesComponent, {
      disableClose: true,
      data: {
        user: this.data.user,
        company: this.data.company,
        task: this.data.task,
        work: this.data.work,
        discussion: this.data.discussion,
        addEmpl: addEmpl
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result && result.event == "update") {
          this.getAdditionalEmpl();
        }
      })
    )
  }

  deleteAddEmpl(addEmpl, i) {
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: {},
        target: marker("additional employee")
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        if (addEmpl.is_partner) {
          this.attachSubscriptions(
            this.membersService.deleteTaskPartner(addEmpl.id, this.data.company.id).subscribe(resp => {
              this.is_change = true;
              this.additionalEmpls.splice(i,1);
            })
          )
        } else if (addEmpl.is_partner_employee) {
          this.attachSubscriptions(
            this.membersService.deleteTaskPartnerEmployee(addEmpl.id, this.data.company.id).subscribe(resp => {
              this.is_change = true;
              this.additionalEmpls.splice(i,1);
            })
          )
        } else {
          this.attachSubscriptions(
            this.membersService.deleteMember(addEmpl.id, this.data.company.id).subscribe(resp => {
              this.is_change = true;
              this.additionalEmpls.splice(i,1);
            })
          )
        }
      }
    });
  }

  getOperationStatusName(id) {
    switch (+id) {
      case 1:
        return "Waiting";
        break;
      case 2:
        return "In process";
        break;
      case 3:
        return "To approve";
        break;
      case 4:
        return "Approved";
        break;
      case 5:
        return "To correct";
        break;
      case 6:
        return "Sources Needed";
        break;
      case 97:
        return "On hold";
        break;
      case 98:
        return "Ready";
        break;
      case 99:
        return "Canceled";
        break;
    }
  }

  close() {
    this.dialogRef.close({event: this.is_change ? "change" : "close", data: false})
  }

  ngOnDestroy(): void {
    this.clearSubscriptions();
  }
}
