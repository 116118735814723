<h2 mat-dialog-title class="dialog_title">
    <span>
        {{ "Access settings" | translate }}
    </span>
    <button mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h2>
<mat-dialog-content class="mat-typography">
    <div class="">
        <mat-list role="list" class="notes_list" [appDeviceClass]="'custom-scroll custom-scroll-t'">
            <mat-list-item *ngFor="let access of accesses; let i = index;" role="listitem" class="company_item ">
                <div class="company_name">
                    <span>{{access.id}}</span>
                    <span *ngIf="!!access.with_all">{{ "For all without authorization" | translate }}</span>
                    <span *ngIf="!!access.with_registered">{{ "For all with authorization" | translate }}</span>
                    <span *ngIf="!!access.with_company_id">{{ "For all from current workspace" | translate }}</span>
                </div>

                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="editAccesses(access)">
                        <mat-icon>edit</mat-icon>
                        <span>{{"Edit" | translate}}</span>
                    </button>
                    <button mat-menu-item (click)="deleteAccess(access, i)">
                        <mat-icon>delete</mat-icon>
                        <span>{{"Delete" | translate}}</span>
                    </button>
                </mat-menu>
                  
            </mat-list-item>
        </mat-list>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <div class="d-f-c" style="padding: 15px 0;">
        <button mat-raised-button (click)="close()">
            {{ "Close" | translate }}
        </button>
        <button class="d-f-c ml-1" mat-raised-button color='primary' (click)="addAccesses()">
            <mat-icon class="mr-1">playlist_add_circle</mat-icon>
            {{ "Add access" | translate }}
        </button>
    </div>
</mat-dialog-actions>
