import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { forkJoin, from, ReplaySubject } from 'rxjs';
import { concatMap, debounceTime, last, map, switchMap, tap } from 'rxjs/operators';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { LoadingService } from 'src/app/shared/services/rest/loading.service';
import { ParametersService } from 'src/app/shared/services/rest/parameters.service';

@Component({
  selector: 'app-add-parameter-from-collection',
  templateUrl: './add-parameter-from-collection.component.html',
  styleUrls: ['./add-parameter-from-collection.component.scss']
})
export class AddParameterFromCollectionComponent extends BaseClass implements OnInit {
  public form: FormGroup;
  public parameters: any;
  public parametersCtrl: FormControl = new FormControl();
  public parameters$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public isSubmit: boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddParameterFromCollectionComponent>,
    private parametersService: ParametersService,
    private fb: FormBuilder,
    private layoutService: LayoutService,
    private ls: LoadingService,
  ) { super() }

  ngOnInit(): void {
    console.log(this.data);
    this.form = this.fb.group({
      company_id: this.data.company_id,
      id: [[], Validators.required]
    })

    this.attachSubscriptions(
      this.parametersCtrl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchParams(resp))
    )

    this.getParameters();
  }

  onSearchParams(resp) {
    if (!this.parameters) {
      return;
    }

    if (!resp) {
      this.parameters$.next(this.parameters.filter(z => !!z.id).slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.parameters$.next(
      this.parameters.filter(z => !!z.id && (z.name.toLowerCase().indexOf(resp) > -1))
    );
  }
  
  // getParameters() {
  //   this.attachSubscriptions(
  //     this.parametersService.getApiParameters(0).subscribe(resp => {
  //       console.log("getParameters", resp)
  //       console.log("this.data.parameters", this.data.parameters)
  //       this.parameters = [];
  //       resp.forEach(element => {
  //         if (this.data.parameters.filter(x => x.original_id == element.id).length == 0) {
  //           this.parameters.push(element)
  //         }
  //       });
  //       this.parameters$.next(this.parameters.slice());
  //     })  
  //   )
  // }

  getParameters() {
    this.attachSubscriptions(
      this.parametersService.getApiParametersWithPage('1', 0, null, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.parametersService.getApiParametersWithPage(x, 0, null, '200').pipe(map(u => u.body)))).pipe(
            last(),
            tap(values => {
              this.parameters = [];
              let allParams = [].concat(...values);
              allParams.forEach(element => {
                if (this.data.parameters.filter(x => x.original_id == element.id).length == 0) {
                  this.parameters.push(element)
                }
              });
              this.parameters$.next(this.parameters.slice());
            }),
          )
        }),
      ).subscribe(resp => {
        console.log("getParameters sub", resp);
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  submitForm() {
    this.isSubmit = true;
    // this.attachSubscriptions(
    //   this.parametersService.importApiParameter(this.form.value).subscribe(resp => {
    //       this.isSubmit = false;
    //       this.dialogRef.close({event: "add"})
    //   })
    // )

    let sbmtData = {...this.form.value};
    delete sbmtData.id
    let lenSelection = this.form.value.id.length;
    let count = 0;

    if (!lenSelection) {
      this.layoutService.showSnackBar({name: ''}, "Select tag categories", SnackBarItem)
      return 
    }

    this.attachSubscriptions(
      from(this.form.value.id).pipe(
        concatMap((fID:any) => {
          return this.parametersService.importApiParameter(Object.assign({id: fID}, sbmtData))
        })
      ).subscribe({
        next: (next) => {
          console.log("next submitForm", next);
          this.ls.requests$.next({
            value: Math.round((100 / lenSelection) * (count+1)),
            target: `Importing tag categories`,
          })
          count++;
        },
        complete: () => {
          console.log("complete submitForm");
          this.isSubmit = false;
          this.dialogRef.close({event: "add"})
        },
        error: (error) => {
          console.log("error submitForm", error)
          this.isSubmit = false;
          this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
        }
      })
    )
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

}
