import { AfterContentChecked, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { mimeTypes } from 'mime-wrapper';
import * as moment from 'moment';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { DateAskComponent } from 'src/app/shared/global_components/date-ask/date-ask.component';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { WorkToApproveComponent } from 'src/app/shared/global_components/work-to-approve/work-to-approve.component';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { AuthService } from 'src/app/shared/services/rest/auth.service';
import { FileService } from 'src/app/shared/services/rest/file.service';
import { MembersService } from 'src/app/shared/services/rest/members.service';
import { RefreshService } from 'src/app/shared/services/rest/refresh.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { UploadService } from 'src/app/shared/services/rest/upload.service';
import { environment } from 'src/environments/environment';
import { CreateOperationChatComponent } from '../create-operation-chat/create-operation-chat.component';
import { OperationChatsComponent } from '../operation-chats/operation-chats.component';
import { MY_FORMATS } from '../task-profile-add/task-profile-add.component';

@Component({
  selector: 'app-work-empl-status-edit',
  templateUrl: './work-empl-status-edit.component.html',
  styleUrls: ['./work-empl-status-edit.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class WorkEmplStatusEditComponent extends BaseClass implements OnInit, AfterContentChecked, OnDestroy {
  @ViewChild('dp') dp: MatDatepicker<any>
  public operationStatuses: any;
  public host: any = environment.host;
  public operations: any;
  public user: any = {};
  public user_id: number;
  public showContentUrl: boolean = false;
  public is_checked: boolean = false;
  public form: FormGroup;
  public workEmpl: FormGroup = this.fb.group({});
  public isChangeRate: boolean = false;
  public numberRegEx = /\d*\.?\d{1,2}/;
  public isFormChange: boolean = false;
  public noChat: boolean = false;
  public activeIndex: number = 0;
  public uploadLimit: boolean = false;

  constructor(
    private taskService: TaskService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<WorkEmplStatusEditComponent>,
    private fb: FormBuilder,
    private auth: AuthService,
    private fileService: FileService,
    private dialog: MatDialog,
    private languageService: LanguageService,
    private _adapter: DateAdapter<any>,
    private cdref: ChangeDetectorRef,
    private bottomSheet: MatBottomSheet,
    private sm: StorageManagerService,
    private uploadService: UploadService,
    private layoutService: LayoutService,
    private refreshService: RefreshService,
    private membersService: MembersService
  ) { super() }

  ngOnInit(): void {
    this.data.dialogHasFm = true;
    console.log("this.data WORK STATUS", this.data)
    this.getOperationsStatus();
    this.getOperations();
    this.attachSubscriptions(
      this.uploadService.getUploadLimit().subscribe(resp => {
        if (resp || this.data.company.filesize >= this.data.company.filesize_limit) {
          this.uploadLimit = true;
        }
      })
    )
    this.getCsrf();
    this.getUser();

    this.attachSubscriptions(
      this.refreshService.getTaskCard().subscribe(resp => {
        if (resp.company_id == this.data.company.id && this.data.task.id == resp.task_id) {
          this.close();
        }
      })
    )

    this.form = this.fb.group({
      status_id: [this.data.work.status_id, Validators.required],
      content_url: this.data.work.content_url,
    })
    
    if (this.form.value.status_id == 5) {
      this.form.addControl('completed_at', this.fb.control(this.data.work.completed_at != "0" ? moment(this.data.work.completed_at*1000) : '', Validators.required))
      this.form.updateValueAndValidity()
    }
    // 3 to correct
    // 4 approved
    // 5 to approve
    // 98 Ready

    if (this.data.work.status_id == 3) {
      this.isChangeRate = true;
      this.showContentUrl = true;
      this.is_checked = true;
    }

    if (this.data.work?.employee?.employee_id) {
      if (this.data.company.rate_target != '0.0000') {
        this.workEmpl.addControl("rate", this.fb.control(this.data.work.employee.rate, Validators.pattern(this.numberRegEx)))
      } else {
        this.workEmpl.addControl("price", this.fb.control(this.data.work.employee.price, Validators.pattern(this.numberRegEx)))
      }
    }

    this.attachSubscriptions(
      this.form.valueChanges.subscribe((val) => {
        this.isFormChange = true        
      })
    )
    this.attachSubscriptions(
      this.workEmpl.valueChanges.subscribe((val) => {
        this.isFormChange = true        
      })
    )

    this.attachSubscriptions(
      this.form.controls.status_id.valueChanges.subscribe(value => {
        console.log(value);
        if (+value == 3 && this.data.work.status_id == 5) {
          this.form.addControl('completed_at', this.fb.control(this.data.work.completed_at != "0" ? moment(this.data.work.completed_at*1000) : '', Validators.required))
          let sheetRef =  this.bottomSheet.open(DateAskComponent);
          sheetRef.afterDismissed().subscribe( data => {
            if(data && data.message=='prev') {
              this.dp.open();
            } if(data && data.message=='today') {
              this.form.patchValue({
                completed_at: moment()
              })
            }
          });
        } else if (value == 3) {
          this.form.addControl('completed_at', this.fb.control(this.data.work.completed_at != "0" ? moment(this.data.work.completed_at*1000) : '', Validators.required))
        } else if (value == 5) {
          this.form.patchValue({
            completed_at: moment()
          })
        } else if (!!this.form.controls.completed_at) {
          this.form.removeControl('completed_at')
        }


        if (value == 3) {
          this.isChangeRate = true;
          this.showContentUrl = true;
          this.is_checked = true;
        } else {
          this.isChangeRate = false;
          this.showContentUrl = false;
          this.is_checked = false;
        }
    


        if (value == 5 && !this.data.work.chatsInfo.filter(z => z.status_id == 2 || z.status_id == 4).length && this.data.company && this.data.company.permissions.includes('create_discussion')) {
          this.noChat = true;
          const createChat = this.dialog.open(CreateOperationChatComponent, {
            disableClose: true,
            data: {
              is_create_chat_component: true,
              tasks: this.data.tasks,
              company: this.data.company,
              task: this.data.task,
              work: this.data.work,
              user: this.data.user,
              empl_status: this.data.company.permissions.includes('owner') || this.data.company.permissions.includes('admin') || this.data.company.permissions.includes('manager')
            }
          });

          this.attachSubscriptions(
            createChat.afterClosed().subscribe(result => {
              if (!!result && result.event == 'create') {
                this.noChat = false;
                this.onSubmit();
              } else {
                this.form.patchValue({
                  status_id: ''
                })
              }
            })
          )
        } else {
          this.noChat = false;
        }

        console.log("out", this.form.value)
      })
    )
    

    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        if (this.isFormChange) {
          this.layoutService.openBottomSheet(this.dialogRef);
        } else {
          this.close();
        }
      })
    )

  }

  uploadFile(event) {
    if (this.uploadLimit) {
      this.layoutService.showSnackBar({name: ''}, marker("This company has exceeded the file upload limit."), SnackBarItem)
      return
    }
    if (event.target.files.length > 0) {
      const file = (event.target as HTMLInputElement).files[0];
      console.log(file);
      this.putFile(file);
    }
  }

  changeTab(val) {
    console.log(val);
    if (val == 1) {
      this.form.controls.content_url.setValidators(Validators.required);
    } else {
      this.form.controls.content_url.clearValidators();
    }
    this.form.get('content_url').updateValueAndValidity();
    this.activeIndex = val;
  }

  putFile(file) {
    let path = "/to_approve"
    this.fileService.uploadFile({
      company_id: this.data.company.id,
      task_id: this.data.task.id,
      task_operation_id: this.data.work.id,
      filesize: file.size,
      filename: file.name,
      content_type: file.type ? file.type : mimeTypes.getType(file.name),
      location: path,
      is_dir: 0
    }, this.data.company.id).subscribe(resp => {
      console.log("putFile", resp)
      this.fileService.files$.next({
        place: "work_status",
        url: window.location.href,
        data: resp,
        location: path,
        target: file,
        task: this.data.task,
        work: this.data.work,
        activeLang: this.data.activeLang,
        operationsValues: this.data.operationsValues,
        task_id: this.data.task.id,
        work_id: this.data.work.id,
        company: this.data.company,
        company_id: this.data.company.id,
        user: this.data.user
      })
    })
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  enableContentUrl(value) {
    if (value) {
      if (this.activeIndex == 1) {
        this.form.controls.content_url.setValidators(Validators.required);
      }
      this.showContentUrl = true;
    } else {
      this.form.controls.content_url.clearValidators();
      this.showContentUrl = false;
    }
    this.form.get('content_url').updateValueAndValidity();
  }

  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData().subscribe(resp => {
        this._adapter.setLocale(resp.active);
      })
    )
  }

  getCsrf() {
    this.attachSubscriptions(
      this.auth.$userToken.subscribe(resp => {
        this.user_id = resp.user_id;
        this.sm.localStorageSetItem("csrf_param", resp.csrf_param)
        this.sm.localStorageSetItem("csrf_token", resp.csrf_token)
      })
    )
  }

  getUser() {
    this.attachSubscriptions(
      this.auth.$user.subscribe(resp => {
        this.user = resp;
      })
    )
  }


  getOperationsStatus() {
    this.attachSubscriptions(
      this.taskService.getOperationsStatus().subscribe(resp => {
        let statusesData = resp.slice();
        this.operationStatuses = statusesData.filter(el => el.is_lock != 1);
      })
    )
  }

  getOperations() {
    this.attachSubscriptions(
      this.taskService.getOperations(this.data.company.id).subscribe(resp => {
        this.operations = resp;
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  onSubmit() {
    // this.getFiles();
    if (this.showContentUrl && this.activeIndex == 0) {
      this.attachSubscriptions(
        this.fileService.detectFiles("/to_approve", "0", this.data.company.id, this.data.work.id).subscribe(resp => {
          if (resp.length) {
            this.submitFunc()
          } else {
            this.layoutService.showSnackBar({name: ''}, marker("Folder 'To approve' is empty! You must upload files to approve."), SnackBarItem)
          }
        })
      )
    } else {
      this.submitFunc()
    }
  }

  submitFunc() {
    let data = this.form.value;
    let dataEmpl = this.workEmpl.value;

    if (String(dataEmpl['rate']).indexOf(",") != -1) {
      dataEmpl['rate'] = dataEmpl['rate'].replace(",", ".");
    }
    if (String(dataEmpl['price']).indexOf(",") != -1) {
      dataEmpl['price'] = dataEmpl['price'].replace(",", ".");
    }

    if (dataEmpl.price == null) {
      dataEmpl.price = "0";
    }
    if (dataEmpl.rate == null) {
      dataEmpl.rate = "0";
    }

    if (!!data.completed_at && !!data.completed_at._d) {
      data.completed_at = moment(data.completed_at._d).format("X");
    } else {
      data.completed_at = 0;
    }

    dataEmpl.completed_at = data.completed_at
    dataEmpl.status_id = data.status_id

    if (this.data.work.unClosedChats > 0 && data.status_id == 4) {
      let workToApprove =  this.bottomSheet.open(WorkToApproveComponent, {
        data: {
          work: this.data.work
        }
      });

      workToApprove.afterDismissed().subscribe( dData => {
        if (dData && dData.message == 'show') {
          console.log("show");
          const dialogRef = this.dialog.open(OperationChatsComponent, {
            data: {
              tasks: this.data.tasks,
              company: this.data.company,
              task: this.data.task,
              work: this.data.work,
              user: this.data.user,
              empl_status: this.data.empl_status,
              host: this.host,
              operationsValues: this.data.operationsValues
            }
          });

          this.attachSubscriptions(
            dialogRef.afterClosed().subscribe(result => {
            })
          )
          
        } if (dData && dData.message == 'approve') {
          this.data.work.unClosedChats = 0;
          this.data.work.unReadChats = 0;
          this.attachSubscriptions(
            this.taskService.editWork(this.data.work.id, data, this.data.company.id).pipe(
              switchMap((x) => {
                if (!!this.data.work?.employee?.employee_id) {
                  return this.membersService.editMember(this.data.work?.employee.id, dataEmpl, this.data.company.id)
                } else {
                  return of(x)
                }
              })
            ).subscribe(resp => {
              this.dialogRef.close({event: "update", data: resp})
            })
          )
        }
      });
      return false;
    }

    this.attachSubscriptions(
      this.taskService.editWork(this.data.work.id, data, this.data.company.id).pipe(
        switchMap((x) => {
          if (!!this.data.work?.employee?.employee_id) {
            return this.membersService.editMember(this.data.work?.employee.id, dataEmpl, this.data.company.id)
          } else {
            return of(x)
          }
        })
      ).subscribe(resp => {
        this.dialogRef.close({event: "update", data: resp})
      })
    )
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

}
