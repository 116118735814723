import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { convertEnglishToRussian, convertRussianToEnglish } from 'src/app/shared/functions/translit';
import { BaseClass } from 'src/app/shared/models/base-class';

@Component({
  selector: 'app-mob-autoupdate-filter',
  templateUrl: './mob-autoupdate-filter.component.html',
  styleUrls: ['./mob-autoupdate-filter.component.scss']
})

export class MobAutoupdateFilterComponent extends BaseClass implements OnInit, OnDestroy {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<MobAutoupdateFilterComponent>,
  ) { super() }

  ngOnInit(): void {
    console.log("MobAutoupdateFilterComponent", this.data)
    console.log(convertRussianToEnglish('руддщ цщкдв')); // 'hello world'
    console.log(convertEnglishToRussian('hello world')); // 'hello world'
    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        this.close();
      })
    )
  }

  close() {
    this.dialogRef.removePanelClass('animate__slideInUp');
    this.dialogRef.addPanelClass('animate__slideOutDown');
    setTimeout(()=>{this.dialogRef.close({event: 'close'})}, 300);
  }

  onSubmit() {
    this.dialogRef.removePanelClass('animate__slideInUp');
    this.dialogRef.addPanelClass('animate__slideOutDown');
    setTimeout(()=>{this.dialogRef.close({event: 'apply'})}, 300);
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

}
