<div class="cases_page tasks_page">
    <div class="container">
      <div class="filter_out">  
        <div class="filter_values">
          <mat-chip-list class="chips_wrp without_ordered start_page_chips">
  
            <mat-chip (removed)="removeValue('q')" *ngIf="activatedRoute.snapshot.queryParamMap.get('q')">
                {{activatedRoute.snapshot.queryParamMap.get('q')}}
                <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
            </mat-chip>

            <mat-chip (removed)="removeValue('task_operation_operation_id')" *ngIf="autoTemplatesFilter.get('operation_id').value">
                {{ "Job type" | translate }}: {{ getColumnName(autoTemplatesFilter.get('operation_id').value) }}
              <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
            </mat-chip>
            <mat-chip class="user_chip" (removed)="removeValue('operation_employee_id')" *ngIf="autoTemplatesFilter.get('employee_id').value">
                <span class="mr-1" style="font-weight: 500;">{{ "Executor" | translate }}</span>
                <app-user [class]="'user_div'" [employee]="getEmployee(autoTemplatesFilter.get('employee_id').value)"></app-user>
                <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
            </mat-chip>
            <mat-chip *ngIf="autoTemplatesFilter.get('parameter_value_id').value" (removed)="removeValue('parameter_value_id')">
                {{ getValueById(autoTemplatesFilter.get('parameter_value_id').value)?.value }}
               <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
            </mat-chip>
  
            <mat-icon *ngIf="activatedRoute.snapshot.queryParamMap.get('q') || autoTemplatesFilter.get('operation_id').value || autoTemplatesFilter?.get('employee_id')?.value || autoTemplatesFilter?.get('parameter_value_id')?.value" class="remove_all_chips_btn" (click)="removeValue('all')">cancel</mat-icon>
          </mat-chip-list>
  
          <div class="stats_wrp ml-1">
            <div class="tasks_counters">
              
              <!-- <form [formGroup]="filter" class="preset_form" *ngIf="bookmarks && bookmarks.length > 0">
                <div class="simple_filter_snippet" (click)="presetFilter.open()">
                  <ng-container *ngIf="filter.get('preset_id').value; else elseTemplatePreset">
                    <span class="name">
                      {{ getBookmarkById(filter.get('preset_id').value)?.name }}
                    </span>
                  </ng-container>
                  <ng-template #elseTemplatePreset>
                    <span class="name">
                      {{ "Bookmark" | translate }}
                    </span>
                  </ng-template>
                  
  
                  <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_2474_38987)">
                    <path d="M7.91699 3.33325L2.08366 3.33325L5.00033 7.91658L7.91699 3.33325Z" fill="black"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_2474_38987">
                    <rect width="10" height="10" fill="white" transform="translate(10) rotate(90)"/>
                    </clipPath>
                    </defs>
                  </svg>
                </div>
  
                <mat-form-field appearance="legacy" class="filter_form preset_filter">
                  <mat-label>{{ "Bookmark" | translate }}</mat-label>
                  <mat-select #presetFilter [disableOptionCentering]="true" selectDir formControlName="preset_id" (selectionChange)="bookmarksChange($event)">
                      <mat-option *ngIf="bookmarks.length > 7">
                        <ngx-mat-select-search 
                            placeholderLabel="{{ 'Search' | translate }}" 
                            noEntriesFoundLabel="{{ 'no matching presets found' | translate }}"
                            [formControl]="bookmarksControl"></ngx-mat-select-search>
                      </mat-option>
                      <mat-option *ngFor="let bookmark of bookmarks$ | async" [value]="bookmark.id">
                        {{ bookmark.name | translate }}
                      </mat-option>
                  </mat-select>
                </mat-form-field>
              </form> -->
  
              <!-- <div class="counter_block" *ngIf="pagination && pagination['totalCount']">
                <div class="counter_top">{{ "Outlets" | translate }}</div>
                <div class="counter_bottom">{{pagination['totalCount']}}</div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
  
      <div class="lists_wrp">
      </div>   
    </div>
</div>
<div class="container centred_page scenarios">

    <div class="step">
        <mat-tab-group mat-align-tabs="center" class="scenarios_tabs" [selectedIndex]="selectedIndex.value" (selectedIndexChange)="changeTab($event)">
            <mat-tab>
                <ng-template mat-tab-label>
                    {{ 'Card Templates' | translate }}
                </ng-template>
                <mat-list role="list" class="job_types" [appDeviceClass]="'custom-scroll custom-scroll-t'"
                infinite-scroll
                [infiniteScrollDisabled]="isLoadTaskTemplates"
                [scrollWindow]="false"
                [infiniteScrollUpDistance]="scrollUpDistance"
                [infiniteScrollThrottle]="throttle"
                (scrolled)="onDownTaskTemplates($event)">
                    <mat-list-item *ngFor="let tmpl of taskTemplates; let i = index;" role="listitem" class="company_item">
                        <div class="d-f-c">
                            <div class="tmpl_id">
                                {{ tmpl?.id }}
                            </div>
                            <div class="type_name">
                                {{ tmpl?.name }}
                            </div>
                        </div>
        
                        <div class="d-f-c">
                            <div class="company_btns">
                                <mat-icon (click)="openExecutors(tmpl)">people</mat-icon>
                                <mat-icon (click)="editTaskTemplate(tmpl)">edit</mat-icon>
                                <mat-icon (click)="deleteTaskTemplate(tmpl, i)">delete</mat-icon>
                            </div>
                        </div>
                    </mat-list-item>
                </mat-list>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    {{ 'Card Scenario Templates' | translate }}
                </ng-template>
                <mat-list role="list" class="job_types" [appDeviceClass]="'custom-scroll custom-scroll-t'"
                infinite-scroll
                [infiniteScrollDisabled]="isLoadCardTemplates"
                [scrollWindow]="false"
                [infiniteScrollUpDistance]="scrollUpDistance"
                [infiniteScrollThrottle]="throttle"
                (scrolled)="onDownCardTemplates($event)">
                    <mat-list-item *ngFor="let tmpl of cardTemplates; let i = index;" role="listitem" class="company_item">
                        <div class="d-f-c">
                            <div class="tmpl_id">
                                {{ tmpl?.id }}
                            </div>
                            <div class="type_name">
                                {{ tmpl?.name }}
                            </div>
                        </div>
            
        
                        <div class="d-f-c">
                            <div class="type_scen_name">
                                {{tmpl?.scenario_name}}
                            </div>
                            <div class="company_btns">
                                <!-- <mat-icon (click)="openGroupsSettings(tmpl)">groups</mat-icon> -->
                                <mat-icon style="color: green;" (click)="editCardAutomationTemplate(tmpl)">edit</mat-icon>
                                <mat-icon (click)="deleteCardAutomationTemplate(tmpl, i)">delete</mat-icon>
                            </div>
                        </div>
                    </mat-list-item>
                </mat-list>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    {{ 'Job Scenario Templates' | translate }}
                </ng-template>
                <mat-list role="list" class="job_types" [appDeviceClass]="'custom-scroll custom-scroll-t'"
                infinite-scroll
                [infiniteScrollDisabled]="isLoadTemplates"
                [scrollWindow]="false"
                [infiniteScrollUpDistance]="scrollUpDistance"
                [infiniteScrollThrottle]="throttle"
                (scrolled)="onDownTemplates($event)">
                    <mat-list-item *ngFor="let tmpl of templates; let i = index;" role="listitem" class="company_item">
                        <div class="d-f-c">
                            <div class="tmpl_id">
                                {{ tmpl?.id }}
                            </div>
                            <div class="type_name">
                                {{ tmpl?.name }}
                            </div>
                        </div>
        
                        <div class="d-f-c">
                            <div class="scenarios_updated" [ngStyle]="{'color': !!tmpl.is_automation_scenarios_updated ? 'blue;' : 'red;' }">
                                {{ (!!tmpl.is_automation_scenarios_updated ? 'Existing scenarios update is complete' : 'Existing scenarios are being updated' ) | translate }}
                            </div>
                            <div class="type_scen_name">
                                {{tmpl?.scenario_name}}
                            </div>
                            <div class="company_btns">
                                <mat-icon (click)="openScenExecutors(tmpl)">people</mat-icon>
                                <mat-icon (click)="openGroupsSettings(tmpl)">groups</mat-icon>
                                <mat-icon (click)="editAutomationTemplate(tmpl)">edit</mat-icon>
                                <mat-icon (click)="openScheme(tmpl)" style="color: #686868; transform: rotate(90deg);">schema</mat-icon>
                                <mat-icon (click)="deleteAutomationTemplate(tmpl, i)">delete</mat-icon>
                            </div>
                        </div>
                    </mat-list-item>
                </mat-list>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    {{ 'Automation of assigning scenarios' | translate }}
                </ng-template>
                <mat-list role="list" class="job_types" [appDeviceClass]="'custom-scroll custom-scroll-t'"
                infinite-scroll
                [infiniteScrollDisabled]="isLoad"
                [scrollWindow]="false"
                [infiniteScrollUpDistance]="scrollUpDistance"
                [infiniteScrollThrottle]="throttle"
                (scrolled)="onDownAutos($event)">
                    <mat-list-item *ngFor="let tmpl of autoTemplates; let i = index;" role="listitem" class="company_item auto_item">
                        <div class="type_name">
                            <span class="text_2_line">
                                {{ tmpl?.automationTemplate?.name }}
                            </span>
                        </div>

                        <div class="job_type text_one_line">
                            <ng-container *ngIf="tmpl.operation_id == 0; else elseTemplateAllOps">
                                {{ "All job types" | translate }}
                            </ng-container>
                            <ng-template #elseTemplateAllOps>
                                <span class="text_one_line">
                                    {{ tmpl?.operation?.name }}
                                </span>
                            </ng-template>
                        </div>

                        <div class="job_type text_one_line">
                            <ng-container *ngIf="tmpl.group_id == 0; else elseTemplateAllGroups">
                                {{ "All groups" | translate }}
                            </ng-container>
                            <ng-template #elseTemplateAllGroups>
                                <span class="text_one_line">
                                    {{ getGroupById(tmpl?.group_id)?.name }}
                                </span>
                            </ng-template>
                        </div>

                        <div class="job_type text_one_line">
                            <ng-container *ngIf="tmpl.parameter_value_id != 0">
                                <div class="tag">
                                    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.5 5C3.89782 5 4.27936 4.84196 4.56066 4.56066C4.84196 4.27936 5 3.89782 5 3.5C5 3.10218 4.84196 2.72064 4.56066 2.43934C4.27936 2.15804 3.89782 2 3.5 2C3.10218 2 2.72064 2.15804 2.43934 2.43934C2.15804 2.72064 2 3.10218 2 3.5C2 3.89782 2.15804 4.27936 2.43934 4.56066C2.72064 4.84196 3.10218 5 3.5 5ZM15.41 7.58C15.77 7.94 16 8.44 16 9C16 9.55 15.78 10.05 15.41 10.41L10.41 15.41C10.05 15.77 9.55 16 9 16C8.45 16 7.95 15.78 7.58 15.41L0.59 8.42C0.22 8.05 0 7.55 0 7V2C0 0.89 0.89 0 2 0H7C7.55 0 8.05 0.22 8.41 0.58L15.41 7.58ZM11.54 1.71L12.54 0.71L19.41 7.58C19.78 7.94 20 8.45 20 9C20 9.55 19.78 10.05 19.42 10.41L14.04 15.79L13.04 14.79L18.75 9L11.54 1.71Z" fill="#686868"></path></svg>
                                    <span class="text_one_line">
                                        {{ tmpl?.parameterValue?.value }}
                                    </span>
                                </div>
                            </ng-container>
                        </div>

                        <div class="job_exec">
                            <ng-container *ngIf="tmpl.employee_id == 0; else elseTemplateAllUsers">
                                {{ "All executors" | translate }}
                            </ng-container>
                            <ng-template #elseTemplateAllUsers>
                                <app-user [class]="'user_div'" [employee]="tmpl?.employee"></app-user>
                            </ng-template>
                        </div>
        
                        <div class="d-f-c">
                            <div class="company_btns">
                                <!-- <mat-icon (click)="editAutomationTemplate(tmpl)">edit</mat-icon> -->
                                <mat-icon (click)="deleteAuto(tmpl, i)">delete</mat-icon>
                            </div>
                        </div>
                    </mat-list-item>
                </mat-list>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    {{ 'Automation of tag assigning' | translate }}
                </ng-template>
                <mat-list role="list" class="job_types" [appDeviceClass]="'custom-scroll custom-scroll-t'"
                infinite-scroll
                [infiniteScrollDisabled]="isLoad"
                [scrollWindow]="false"
                [infiniteScrollUpDistance]="scrollUpDistance"
                [infiniteScrollThrottle]="throttle"
                (scrolled)="onDownAutoTags($event)">
                    <div class="auto_tags_head_out">
                        <div class="auto_tags_head">
                            <div class="auto_tag_val">
                                {{ "Tag" | translate }}
                            </div>
                            <div class="card_info">
                                {{ "Cards info" | translate }}
                            </div>
                            <div class="job_info">
                                {{ "Jobs info" | translate }}
                            </div>
                            <div class="files_info">
                                {{ "Files info" | translate }}
                            </div>
                            <div class="company_btns">
                                {{ "Actions" | translate }}
                            </div>
                        </div>
                    </div>
                    <mat-list-item *ngFor="let tmpl of autoTags; let i = index;" role="listitem" class="auto_tag">
                        
                        <div class="auto_tag_val">
                            <span class="text_one_line">
                                {{tmpl?.parameterValue?.value}}
                            </span>
                            <mat-icon class="verified" [ngClass]="{'cst_primary': !!tmpl.is_primary}">verified</mat-icon>
                        </div>

                        <div class="card_info">
                            <div>{{tmpl?.taskStatus?.name}}</div>
                            <div>{{tmpl?.taskGroup?.name}}</div>
                            <div>{{tmpl?.taskParameterValue?.value}}</div>
                        </div>

                        <div class="job_info">
                            <app-user *ngIf="tmpl?.operationEmployee" style="margin-bottom: 5px; display: block;" [class]="'user_div'" [employee]="tmpl?.operationEmployee"></app-user>
                            <div>{{tmpl?.operation?.name}}</div>
                            <div>{{tmpl?.taskOperationParameterValue?.value}}</div>
                        </div>

                        <div class="files_info">
                            <div>{{getFolderByLoc(tmpl?.file_location)?.name}}</div>
                            <div *ngIf="!!tmpl?.is_exact_file_location">{{"Only from this folder" | translate}}</div>
                            <div *ngIf="!tmpl?.is_exact_file_location">{{"From all subfolders" | translate}}</div>
                            <div>{{"Part" | translate}}: {{tmpl?.filename_expression}}</div>
                            <div *ngIf="tmpl?.is_start_with_filename_expression">{{"Starts with this part" | translate}}</div>
                            <div *ngIf="tmpl?.is_end_with_filename_expression">{{"Ends with this part" | translate}}</div>
                            <div *ngIf="tmpl?.is_has_filename_expression">{{"Contains part" | translate}}</div>
                            <div>{{"Extension" | translate}}: {{tmpl?.file_extension}}</div>
                        </div>

                        <div class="company_btns">
                            <mat-icon (click)="editAutoTag(tmpl)">edit</mat-icon>
                            <mat-icon (click)="deleteAutoTag(tmpl, i)">delete</mat-icon>
                        </div>
                    </mat-list-item>
                </mat-list>
            </mat-tab>
        </mat-tab-group>
        

  
        <div class="btns_line">
            <button mat-raised-button (click)="goBack()">
                {{ "Back" | translate }}
            </button>
            <ng-container [ngSwitch]="selectedIndex.value">
                <ng-container *ngSwitchCase="0">
                    <button class="ml-1" (click)="addTaskTemplate()" mat-raised-button color="primary">
                        <mat-icon>add</mat-icon>
                        {{ "Create Card Template" | translate }}
                    </button>
                </ng-container>
                <ng-container *ngSwitchCase="1">
                    <button class="ml-1" (click)="addAutoCardTemplate()" mat-raised-button color="primary">
                        <mat-icon>add</mat-icon>
                        {{ "Create Card Scenario Template" | translate }}
                    </button>
                </ng-container>
                <ng-container *ngSwitchCase="2">
                    <button class="ml-1" (click)="addAutomationTemplate()" mat-raised-button color="primary">
                        <mat-icon>add</mat-icon>
                        {{ "Create Job Scenario Template" | translate }}
                    </button>
                </ng-container>
                <ng-container *ngSwitchCase="3">
                    <button class="ml-1" (click)="addAuto()" mat-raised-button color="primary">
                        <mat-icon>add</mat-icon>
                        {{ "Create automation of assigning scenarios" | translate }}
                    </button>
                </ng-container>
                <ng-container *ngSwitchCase="4">
                    <button class="ml-1" (click)="addAutoTag()" mat-raised-button color="primary">
                        <mat-icon>add</mat-icon>
                        {{ "Create Automation of tag assigning" | translate }}
                    </button>
                </ng-container>
            </ng-container>
            
        </div>
  
  
    </div>
</div>

<mat-progress-bar mode="indeterminate" *ngIf="isLoad" class="mat_proggress_w8"></mat-progress-bar>