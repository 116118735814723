import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpRestService } from '../common/http-rest.service';

@Injectable({
  providedIn: 'root'
})
export class RootService {

  constructor(
    private http: HttpRestService,
    private httpClient: HttpClient
    ) { }


    getRootCompany(page) {
      return this.http.executeGet<any>(`/root/company/`, true, {
        observe: 'response',
        params: new HttpParams().set('per-page', '200').set('page', page).set("expand", "owner,avatarFile,translations")
      })
    }
    
    getRootUsers(page) {
      return this.http.executeGet<any>(`/root/user/`, true, {
        observe: 'response',
        params: new HttpParams().set('page', page).set('per-page', '200')
      })
    }

    getBookmarksExp(page, filter) {
      let _params = new HttpParams().set('page', page).set('per-page', '100')
      if (filter) {
        Object.keys(filter).forEach(element => {
          _params = _params.set(element, filter[element]);
        });
      }
      _params = _params.set("expand", "employee.avatarFile,sectionToEmployees.employee.avatarFile");
      return this.http.executeGet<any>('/root/section/', true, {
        observe: 'response',
        params: _params
      });
    }

    addBookmark(data){
      return this.http.executePost<any>('/root/section/', data)
    }
  
    editBookmark(id, data) {
      return this.http.executePost<any>(`/root/section/${id}/`, data)
    }
  
    deleteBookmark(id) {
      return this.http.executeDelete<any>(`/root/section/${id}/`)
    }
  
    changeRoot(id, val) {
      return this.http.executePost<any>(`/root/user/${id}/`, {is_root: val})
    }

    getCompanyTranslation(id) {
      return this.http.executeGet<any>(`/root/company-translation/`, true, {
        params: new HttpParams().set("company_id", id)
      })
    }
  
    createCompanyTranslation(data) {
      return this.http.executePost<any>('/root/company-translation/', data)
    }
  
    updateCompanyTranslation(id, data) {
      return this.http.executePost<any>(`/root/company-translation/${id}/`, data)
    }

    changeLimit(id, data) {
      return this.http.executePost<any>(`/root/company/${id}/`, data)
    }

    getLocks() {
      return this.http.executeGet<any>(`/root/lock/list/`)
    }

    getRootPromos(page, filter?) {
      let filter_params = new HttpParams().set('page', page).set("per-page", '200')

      if (filter) {
        Object.keys(filter).forEach(element => {
          if (filter[element] !== '') {
            filter_params = filter_params.set(element, filter[element]);
          }
        });
      }

      return this.http.executeGet<any>(`/root/company-promo/`, true, {
        observe: 'response',
        params: filter_params
      })
    }

    getRootPromosEm(page, filter?) {
      let filter_params = new HttpParams().set('page', page).set("per-page", '200')

      if (filter) {
        Object.keys(filter).forEach(element => {
          if (filter[element] !== '') {
            filter_params = filter_params.set(element, filter[element]);
          }
        });
      }

      return this.http.executeGet<any>(`/root/company-promo-redeem/`, true, {
        observe: 'response',
        params: filter_params
      })
    }

    addRootPromo(data) {
      return this.http.executePost<any>('/root/company-promo/', data)
    }
  
    editRootPromo(id, data) {
      return this.http.executePost<any>(`/root/company-promo/${id}/`, data)
    }

    deleteRootPromo(id) {
      return this.http.executeDelete<any>(`/root/company-promo/${id}/`)
    }

    getRootServers(page, filter?) {
      let filter_params = new HttpParams().set('page', page).set("per-page", '200').set('expand','serverLog')

      if (filter) {
        Object.keys(filter).forEach(element => {
          if (filter[element] !== '') {
            filter_params = filter_params.set(element, filter[element]);
          }
        });
      }

      return this.http.executeGet<any>(`/root/server/`, true, {
        observe: 'response',
        params: filter_params
      })
    }

    getRootPlans(page, filter?) {
      let filter_params = new HttpParams().set('page', page).set("per-page", '200')

      if (filter) {
        Object.keys(filter).forEach(element => {
          if (filter[element] !== '') {
            filter_params = filter_params.set(element, filter[element]);
          }
        });
      }

      return this.http.executeGet<any>(`/root/balance-product/`, true, {
        observe: 'response',
        params: filter_params
      })
    }

    editRootPlan(id, data) {
      return this.http.executePost<any>(`/root/balance-product/${id}/`, data)
    }

    addRootPlan(data) {
      return this.http.executePost<any>(`/root/balance-product/`, data)
    }

    deleteRootPlan(id) {
      return this.http.executeDelete<any>(`/root/balance-product/${id}/`)
    }

    getRootTransactions(page, filter?) {
      let filter_params = new HttpParams().set('page', page).set("per-page", '200')

      if (filter) {
        Object.keys(filter).forEach(element => {
          if (filter[element] !== '') {
            filter_params = filter_params.set(element, filter[element]);
          }
        });
      }

      return this.http.executeGet<any>(`/root/balance-transaction/`, true, {
        observe: 'response',
        params: filter_params
      })
    }

    editRootTransaction(id, data) {
      return this.http.executePost<any>(`/root/balance-transaction/${id}/`, data)
    }

    addRootTransaction(data) {
      return this.http.executePost<any>(`/root/balance-transaction/`, data)
    }

    deleteRootTransaction(id) {
      return this.http.executeDelete<any>(`/root/balance-transaction/${id}/`)
    }

    getAiModels(page, filter?) {
      let filter_params = new HttpParams().set('page', page).set("per-page", '200')

      if (filter) {
        Object.keys(filter).forEach(element => {
          if (filter[element] !== '') {
            filter_params = filter_params.set(element, filter[element]);
          }
        });
      }

      return this.http.executeGet<any>(`/root/open-ai-model/`, true, {
        observe: 'response',
        params: filter_params
      })
    }

    editAiModel(id, data) {
      return this.http.executePost<any>(`/root/open-ai-model/${id}/`, data)
    }

    addAiModel(data) {
      return this.http.executePost<any>(`/root/open-ai-model/`, data)
    }

    deleteAiModel(id) {
      return this.http.executeDelete<any>(`/root/open-ai-model/${id}/`)
    }

    editRootServer(id, data) {
      return this.http.executePost<any>(`/root/server/${id}/`, data)
    }

    getRootTaskTypes() {
      return this.http.executeGet<any>(`/root/task-status/`, true, {
        params: new HttpParams().set("per-page", '200').set('expand','company')
      })
    }
    
    getRootTaskType(id) {
      return this.http.executeGet<any>(`/root/task-status/${id}/`)
    }

    addRootTaskType(data) {
      return this.http.executePost<any>(`/root/task-status/`, data)
    }

    editRootTaskType(id, data) {
      return this.http.executePost<any>(`/root/task-status/${id}/`, data)
    }

    deleteRootTaskType(id) {
      return this.http.executeDelete<any>(`/root/task-status/${id}/`)
    }

    getRootJobTypes() {
      return this.http.executeGet<any>(`/root/operation/`, true, {
        params: new HttpParams().set("per-page", '200').set('expand','company')
      })
    }

    getRootJobType(id) {
      return this.http.executeGet<any>(`/root/operation/${id}/`)
    }

    addRootJobType(data) {
      return this.http.executePost<any>(`/root/operation/`, data)
    }

    editRootJobType(id, data) {
      return this.http.executePost<any>(`/root/operation/${id}/`, data)
    }

    deleteRootJobType(id) {
      return this.http.executeDelete<any>(`/root/operation/${id}/`)
    }

    lockClear(data) {
      return this.http.executePost<any>(`/root/lock/clear/`, data)
    }
}
