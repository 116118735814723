import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ReplaySubject, concat, forkJoin } from "rxjs";
import { debounceTime, last, map, switchMap, tap } from "rxjs/operators";
import { BaseClass } from "src/app/shared/models/base-class";
import { LayoutService } from "src/app/shared/services/common/layout.service";
import { StorageManagerService } from "src/app/shared/services/common/storage-manager.service";
import { CompanyService } from "src/app/shared/services/rest/company.service";
import { LoadingService } from "src/app/shared/services/rest/loading.service";
import { RefreshService } from "src/app/shared/services/rest/refresh.service";
import { TaskService } from "src/app/shared/services/rest/task.service";

@Component({
  selector: 'app-multi-edit-groups',
  templateUrl: './multi-edit-groups.component.html',
  styleUrls: ['./multi-edit-groups.component.scss']
})

export class MultiEditGroupsComponent extends BaseClass implements OnInit, OnDestroy {
  public form: FormGroup;
  public isSubmit: boolean = false;

  public statuses: any;

  public groups$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public groupsMoreControl: FormControl = new FormControl();
  public groups: any;
  
  public isFormChange: boolean = false;
  public customIdValue: number;
  public isGetId: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private taskService: TaskService,
    public dialogRef: MatDialogRef<MultiEditGroupsComponent>,
    private fb: FormBuilder,
    private layoutService: LayoutService,
    private companyService: CompanyService,
    private refreshService: RefreshService,
    private sm: StorageManagerService,
    private ls: LoadingService,
  ) { super() }

  ngOnInit(): void {
    console.log("MultiEditGroupsComponent", this.data);
    this.getAllGroups();

    this.attachSubscriptions(
      this.groupsMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchGroups(resp))
    )

    this.form = this.fb.group({
      group_id: ['', Validators.required],
      custom_id: ''
    })

    this.attachSubscriptions(
      this.form.get('group_id').valueChanges.subscribe(res => {
        this.getCustomId(res);
      })
    )

    this.attachSubscriptions(
      this.form.valueChanges.subscribe(() => this.isFormChange = true)
    )

    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        if (this.isFormChange) {
          this.layoutService.openBottomSheet(this.dialogRef);
        } else {
          this.close();
        }
      })
    )

    console.log("MultiEditGroupsComponent", this.form);
  }

  getAllGroups() {
    this.attachSubscriptions(
      this.companyService.getInfiniteGroupsCompany(this.data.company_id, '1', '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.companyService.getInfiniteGroupsCompany(this.data.company_id, x).pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        this.groups = [].concat(...res);
        this.groups$.next(this.groups.slice())
      })
    )
  }

  getCustomId(value) {
    this.attachSubscriptions(
      this.taskService.getCustomId(this.data.company_id, value).subscribe(resp => {
        this.customIdValue = +resp;
        this.isGetId = true;
      })
    )
  }

  onSearchGroups(resp) {
    if (!this.groups) {
      return;
    }

    if (!resp) {
      this.groups$.next(this.groups.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.groups$.next(
      this.groups.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  close() {
    this.dialogRef.close({event: "close", data: false});
  }

  submitForm() {
    this.isSubmit = true;

    let sbmtData = [];
    let partsSbmtData = [];

    this.data.tasks.forEach((t_id, i) => {
      sbmtData.push({
        "path": `/api/task/${t_id}/`,
        "query": {},
        "method": "POST",
        "body": Object.assign({
          [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
        }, Object.assign(this.form.value, {custom_id: this.customIdValue + i}))
      });
    })

    for (let i = 0; i < Math.ceil(sbmtData.length/10); i++) {
      partsSbmtData.push(sbmtData.slice(i*10, 10 + i*10))
    }

    let count = 0;
    concat(...partsSbmtData.map((param) => this.taskService.multiRequest(param))).pipe(
      tap(el => {
        this.ls.requests$.next({
          value: Math.round((100 / partsSbmtData.length) * (count+1)),
          target: "Setting a group for cards"
        })
        if (count == 0) {
          this.isSubmit = false;
          this.close();
        }
        partsSbmtData[count].forEach(param => {
          this.refreshService.refreshTask$.next({company_id: this.data.company_id, task_id: +param.path.replace('/api/task/', '').replace('/','')})
        });
        count++;
      })
    ).subscribe(res => {
      console.log(res);
    })
  }

  ngOnDestroy() {
    this.clearSubscriptions()
  }

}
