import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChildren } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { concat, forkJoin, Observable, Subscription } from 'rxjs';
import { concatMap, debounceTime, last, map, switchMap, take, tap } from 'rxjs/operators';
import { BaseClass } from 'src/app/shared/models/base-class';
import { ParametersService } from 'src/app/shared/services/rest/parameters.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { FormControl } from '@angular/forms';
import { RefreshService } from 'src/app/shared/services/rest/refresh.service';

@Component({
  selector: 'app-set-auto-for-check',
  templateUrl: './set-auto-for-check.component.html',
  styleUrls: ['./set-auto-for-check.component.scss']
})
export class SetAutoForCheckComponent extends BaseClass implements OnInit, OnDestroy {
  public values: any;
  public parameters: any;
  public allValues: any;
  public paramDataSub: Subscription
  public separatorKeysCodes: number[] = [ENTER, COMMA];
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<SetAutoForCheckComponent>,
    private parametersService: ParametersService,
    private refreshService: RefreshService,
  ) { super() }

  ngOnInit(): void {
    console.log("SetAutoForCheckComponent", this.data);
    
    if (this.data.only) {
      this.attachSubscriptions(
        this.refreshService.getOnlyFor().subscribe(resp => {
          console.log("getOnlyFor", resp)
          let param = this.parameters.find(x => x.values.filter(b => b.id == resp.data.parameter_value_id).length > 0)
          if (resp.type == 'add') {
            param.activeValues.push(resp.result);
          } else {
            let ind = param.activeValues.indexOf(param.activeValues.find(u => u.parameter_value_id == resp.data.parameter_value_id))
            console.log(ind);
            param.activeValues.splice(ind, 1)
            console.log(param)
          }
        })
      )
    } else {
      this.attachSubscriptions(
        this.refreshService.getExcept().subscribe(resp => {
          console.log("getExcept", resp)
          let param = this.parameters.find(x => x.values.filter(b => b.id == resp.result.parameter_value_id).length > 0)
  
          param.activeValues.push(resp.result);

        })
      )
    }
    this.getAllApiParameterValues()
    this.paramDataSub = this.parametersService.getParamsStream().pipe(
      concatMap(params => {
        return forkJoin([...params.map(oneParam => this.neededParametersData(oneParam))]).pipe(last(),map(x => params))
      }),
    ).subscribe(resp => console.log("-----getParamsStream-----",resp));
    this.getParameters()
  }

  getParameters() {
    this.attachSubscriptions(
      this.parametersService.getApiParameters(this.data.company.id).pipe(
        tap(res => {
          this.parametersService.paramsData$.next(res)
          res.forEach(n => {
            n.search = new FormControl('');
            n.search.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearch(resp, n))
          })
        })
      ).subscribe(resp => {
        this.parameters = resp
        console.log("this.parameters", this.parameters)
      })
    )
  }

  withSearch(searchText: string, val) {
    if (searchText == '' || typeof searchText != 'string' || val.value.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ) {
      return true
    } else {
      return false
    }
  }
  
  noOption(arr, id) {
    return arr.filter(x => x.parameter_value_id == id).length > 0 ? false : true;
  }

  neededParametersData(param) {
    let arr = []

    arr.push(
      this.parametersService.getApiParameterValues(this.data.company.id, param.id, "20", !!param.page ? param.page : "1").pipe(
        tap(x => {
          param.pagination = {
            'pageCount': x.headers.get('x-pagination-page-count'),
            'perPage': x.headers.get('x-pagination-per-page'),
            'totalCount': x.headers.get('x-pagination-total-count'),
            'currentPage': x.headers.get('x-pagination-current-page'),
          }
          param.page = 2
          param.values = x.body;
          if (x.body.length > 0) {
            param.hasValues = true;
          }
          param.activeValues = this.data.check[this.data.only ? 'toParameterValues' : 'exceptParameterValues'].filter(x => x.parameterValue && x.parameterValue.parameter_id == param.id)
          // param.activeValues = this.values.filter(b => b.parameter_id == param.id);
          // this.data.check[this.data.only ? 'toParameterValues' : 'exceptParameterValues'].forEach(el => {
          //   if (param.values.find(x => x.id == el.parameter_value_id)) {
          //     param.activeValues.push(el)
          //   }
          // })
        })
      )
    )

    return forkJoin(arr)
  }

  onSearch(searchText, param) {
    console.log(searchText, param)
    param.page = 1;
    this.parametersService.getApiParameterValues(this.data.company.id, param.id, "20", !!param.page ? param.page : "1",  !!param.search.value ? param.search.value : '').subscribe(resp => {
      param.pagination = {
        'pageCount': resp.headers.get('x-pagination-page-count'),
        'perPage': resp.headers.get('x-pagination-per-page'),
        'totalCount': resp.headers.get('x-pagination-total-count'),
        'currentPage': resp.headers.get('x-pagination-current-page'),
      }
      param.page = 2
      param.values = resp.body;
    })
  }

  onScroll(param) {
    console.log(param.page, param.pagination.pageCount)
    if (param.pagination.pageCount >= param.page) {
      console.log("SCROLL !")
      this.parametersService.getApiParameterValues(this.data.company.id, param.id, "20", param.page, !!param.search.value ? param.search.value : '').pipe(take(1), map((x) => x.body)).subscribe(resp => {
        param.page++;
        param.values.push(...resp)
      })
    }
  }


  onRemove(id, param, i) {
    console.log("onRemove", id, param, i)
    this.attachSubscriptions(
      this.parametersService.deleteOnlyExceptParameterValue(id, this.data.only).subscribe(resp => {
        // this.refreshService.refreshOnlyFor$.next({type: 'delete', data: {parameter_value_id: id}, result: {parameter_value_id: id}})
        param.activeValues.splice(i, 1)
      })
    )
  }
  
  selected(e, param) {
    console.log("selected", e, param)
    this.attachSubscriptions(
      this.parametersService.addOnlyExceptParameterValue(Object.assign({
        company_id: this.data.company.id,
        operation_reminder_id: this.data.check.id,
        parameter_value_id: e.option.value,
      }, this.data.target), this.data.only).subscribe(resp => {
        // this.refreshService.refreshOnlyFor$.next({type: 'add', result: resp})
        param.activeValues.push(resp);
        param.search.patchValue("")
        if (document.getElementById(`param_input_${param.id}`)) {
          (document.getElementById(`param_input_${param.id}`) as HTMLInputElement).value = ''
        }
      }, error => {
        param.search.patchValue("")
        if (document.getElementById(`param_input_${param.id}`)) {
          (document.getElementById(`param_input_${param.id}`) as HTMLInputElement).value = ''
        }
      })
    )

    
  }

  // onRemove(id, param, i) {
  //   this.attachSubscriptions(
  //     this.parametersService.deleteTargetParameter(id).subscribe(resp => {
  //       param.activeValues.splice(i, 1)
  //     })
  //   )
  // }

  // selected(e, param) {
  //   this.attachSubscriptions(
  //     this.parametersService.addTargetParameter(Object.assign({
  //       company_id: this.data.company.id,
  //       parameter_id: param.id,
  //       parameter_value_id: e.option.value,
  //     },this.data.target)).subscribe(resp => {
  //       param.activeValues.push(resp);
  //       param.search.patchValue('')
  //       if (document.getElementById(`param_input_${param.id}`)) {
  //         (document.getElementById(`param_input_${param.id}`) as HTMLInputElement).value = ''
  //       }
  //     })
  //   )
  // }
      
  add(e) {
    console.log("add", e)
  }
  
  private removeFirst<T>(array: T[], toRemove: T): void {
    const index = array.indexOf(toRemove);
    if (index !== -1) {
      array.splice(index, 1);
    }
  }
    
  getAllApiParameterValues() {
    this.attachSubscriptions(
      this.parametersService.getAllValues('1', this.data.company.id, null, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.parametersService.getAllValues(x, this.data.company.id).pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        this.allValues = [].concat(...res)
      })
    )
  }


  getValueById(id) {
    if (!this.allValues || this.allValues.length == 0) {
      return null
    }
    return this.allValues.find(x => x.id == id)
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  ngOnDestroy(): void {
    if (this.paramDataSub) {
      this.paramDataSub.unsubscribe()
    }
    this.clearSubscriptions()
  }

}
