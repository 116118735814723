import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { mimeTypes } from 'mime-wrapper';
import { FileService } from 'src/app/shared/services/rest/file.service';
import { map } from 'rxjs/operators';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { SnackBarItem } from '../../../snack-bar/snack-bar-item';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

@Component({
  selector: 'app-file-speach-to-text',
  templateUrl: './file-speach-to-text.component.html',
  styleUrls: ['./file-speach-to-text.component.scss']
})
export class FileSpeachToTextComponent extends BaseClass implements OnInit, OnDestroy {

  public mimeTypes = mimeTypes;
  public activeLang: any;
  public timeZone = new Date().getTimezoneOffset()*60;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<FileSpeachToTextComponent>,
    private languageService: LanguageService,
    private fileService: FileService,
    private layoutService: LayoutService,
    private dialog: MatDialog,
  ) { super() }

  ngOnInit(): void {
    console.log("FileSpeachToTextComponent", this.data)
    if (this.timeZone > 0) {
      this.timeZone = -this.timeZone
    } else {
      this.timeZone = Math.abs(this.timeZone)
    }
    this.getLangData();
    this.getFile();
  }

  getFile() {
    this.attachSubscriptions(
      this.fileService.getFilesPreview(this.data.company.id, [this.data.file.id]).pipe(
        map(arr => arr[0])
      ).subscribe(resp => {
        this.data.file = resp
        console.log("RESP", resp)
        console.log("FILE", resp)
      })
    )
  }

  copySpeech() {
    this.layoutService.showSnackBar({name: 'Speech'}, marker("Copied"), SnackBarItem)
  }

  transcribeFile() {
    this.attachSubscriptions(
      this.fileService.editFile(this.data.file.id, {is_rev_transcribe: 1}, this.data.company.id).subscribe(resp => {
        // this.data.file.is_rev_transcribe = resp.is_rev_transcribe
        this.layoutService.showSnackBar({name: ''}, marker('File in the process of receiving text'), SnackBarItem);
        this.getFile()
      },
      error => {
        this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
      })
    )
  }

  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData().subscribe(resp => {
        this.activeLang = resp.active;
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false});
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
