import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { concatMap, finalize, map, tap } from 'rxjs/operators';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class Md5Service {
  calculateMd5(file: File): Observable<string> {
    const chunkSize = 1024 * 1024 * 10; // 10 MB chunks
    const hash = CryptoJS.algo.MD5.create();
    let offset = 0;
    let startTime: number;

    return new Observable<any>((subscriber) => {
      const fileReader = new FileReader();

      fileReader.onerror = () => {
        fileReader.abort();
        subscriber.error(new DOMException('Failed to read file.'));
      };

      fileReader.onload = (event) => {
        if (event.target.readyState === FileReader.DONE) {
          const chunk = CryptoJS.lib.WordArray.create(
            event.target.result as ArrayBuffer
          );
          hash.update(chunk);

          offset += chunkSize;
          if (offset >= file.size) {
            const md5Hash: string = hash.finalize().toString();
            const duration = Date.now() - startTime;
            console.log(
              `MD5 hash for ${file.name} calculated in ${duration} ms`
            );
            subscriber.next({ md5Hash: md5Hash, duration: duration });
            subscriber.complete();
            return;
          }

          readNextChunk();
        }
      };

      const readNextChunk = () => {
        const blob = file.slice(offset, offset + chunkSize);
        fileReader.readAsArrayBuffer(blob);
      };

      startTime = Date.now();
      readNextChunk();
    }).pipe(
      tap((res) => console.log(`MD5 hash for ${file.name}: ${res.md5Hash}`)),
      finalize(() =>
        console.log(`calculateMd5 took ${Date.now() - startTime} ms`)
      )
    );
  }
}