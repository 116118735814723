import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { ScenariosService } from 'src/app/shared/services/rest/scenarios.service';

@Component({
  selector: 'app-add-group-to-scenario',
  templateUrl: './add-group-to-scenario.component.html',
  styleUrls: ['./add-group-to-scenario.component.scss']
})
export class AddGroupToScenarioComponent extends BaseClass implements OnInit, OnDestroy {
  public form: FormGroup;
  public groups: any = this.data.groups;
  public isFormChange: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddGroupToScenarioComponent>,
    private fb: FormBuilder,
    private companyService: CompanyService,
    private layoutService: LayoutService,
    private scenariosService: ScenariosService
  ) { super() }

  ngOnInit(): void {
    console.log("AddGroupToScenarioComponent", this.data)

    this.form = this.fb.group({
      group_id: ''
    })

    this.attachSubscriptions(
      this.form.valueChanges.subscribe(() => this.isFormChange = true)
    )

    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        if (this.isFormChange) {
          this.layoutService.openBottomSheet(this.dialogRef);
        } else {
          this.close();
        }
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false});
  }

  submitForm() {
    this.attachSubscriptions(
      this.scenariosService.addGroupsToScenario(Object.assign(this.form.value, {
        company_id: this.data.company.id,
        automation_template_id: this.data.tmpl.id,
      }), this.data.company.id).subscribe(resp => {
        console.log(resp)
        this.dialogRef.close({event: "add", data: resp});
      })
    )
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

}
