import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { map, tap } from 'rxjs/operators';
import { DeleteAlertComponent } from 'src/app/shared/global_components/delete-alert/delete-alert.component';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { BaseClass } from 'src/app/shared/models/base-class';
import { GlobalDataService } from 'src/app/shared/services/common/global-data.service';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { AuthService } from 'src/app/shared/services/rest/auth.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { MembersService } from 'src/app/shared/services/rest/members.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { environment } from 'src/environments/environment';
import { FoldersPermissionsComponent } from '../../members/dialogs/folders-permissions/folders-permissions.component';

@Component({
  selector: 'app-company-structure',
  templateUrl: './company-structure.component.html',
  styleUrls: ['./company-structure.component.scss']
})
export class CompanyStructureComponent extends BaseClass implements OnInit, OnDestroy {
  public departments:any;
  public host: any = environment.host;
  public imgRoute: any = '';
  public origin = window.location.origin;
  public user: any = {};
  public user_id: number;
  public company_id: any;
  public company: any;
  constructor(
    private companyService: CompanyService,
    private sm: StorageManagerService,
    private auth: AuthService,
    private router: Router,
    private taskService: TaskService,
    private activatedRoute: ActivatedRoute,
    private globalDataService: GlobalDataService,
    private membersService: MembersService,
    private layoutService: LayoutService,
    private dialog: MatDialog,
    private bottomSheet: MatBottomSheet,
  ) { super() }

  ngOnInit(): void {
    window.scroll(0,0);
    this.company_id = this.activatedRoute.snapshot.queryParamMap.get('company_id');
    this.getUser();
    this.getImgRoute();
    this.getCsrf();
    this.getCompany();
    this.getDepartments();

    this.attachSubscriptions(
      this.companyService.getDepDeletedStream().subscribe(resp => {
        console.log("getDepDeletedStream", resp);
        if (this.departments.find(x => x.id == resp.department_id)) {
          this.departments.splice(this.departments.indexOf(this.departments.find(x => x.id == resp.department_id)), 1);
        }
      })
    )

    this.attachSubscriptions(
      this.companyService.getPosDeletedStream().subscribe(resp => {
        console.log("getDepDeletedStream", resp);
        if (this.departments.find(x => x.id == resp.department_id)) {
          let dep = this.departments.find(x => x.id == resp.department_id)
          if (dep.values && dep.values.find(y => y.id == resp.department_position_id)) {
            dep.values.splice(dep.values.indexOf(dep.values.find(y => y.id == resp.department_position_id)),1)
          }
        }
      })
    )
  }

  getDepartments() {
    this.attachSubscriptions(
      this.companyService.getDepartments(this.company_id).pipe(
        map(x => x.body)
      ).subscribe(resp => {
        this.departments = resp
        console.log("departments", this.departments);
      })
    )
  }

  addDepartment() {
    this.attachSubscriptions(
      this.companyService.addDepartment({company_id: this.company_id, name: "Department name"})
      .pipe(
        tap(b => { b.values = [] })
      ).subscribe(resp => {
        this.departments.push(resp);
        this.focusDep(resp);
      }, error => {
        this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
      })
    )
  }

  foldersPermissions(dep, pos) {
    const dialogRef = this.dialog.open(FoldersPermissionsComponent, {
      backdropClass: ['parameters_modal_backdrop'],
      panelClass: ['parameters_modal'],
      data: {
        company_id: this.company.id,
        company: this.company,
        imgRoute: this.imgRoute,
        host: this.host,
        target: !!dep ? dep : pos,
        type: !!dep ? 'department' : 'position'
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log("getONE BEFORE", result);
        this.getDepartments();
      })
    )
  }
  
  editDepartment(item, e) {
    item.isEddited = false;
    let savedName = item.name;

    this.attachSubscriptions(
      this.companyService.editDepartment(item.id, {name: e.target.value}).subscribe(resp => {
        console.log("editRootitemeterValue", resp)
        item.name = resp.name;
        item.updated_at = resp.updated_at
      }, error => {
        item.name = savedName;
        this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
      })
    )
  }

  deleteDepartment(item, i) {
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: item,
        target: marker("Department")
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        this.attachSubscriptions(
          this.companyService.deleteDepartment(item.id).subscribe(resp => {
            this.departments.splice(i, 1);
          }, error => {
            console.log(error)
          })
        )
      }
    });

  }

  saveValue(x) {
    x.isEddited = false
  }

  focusDep(item) {
    item.isEddited = true;

    setTimeout(() => {
      if (document.getElementById(`dep_${item.id}`)) {
        document.getElementById(`dep_${item.id}`).focus()
      }
    }, 0)
  }

  focusPos(item, pos) {
    pos.isEddited = true;

    setTimeout(() => {
      if (document.getElementById(`pos_${item.id}_${pos.id}`)) {
        document.getElementById(`pos_${item.id}_${pos.id}`).focus()
      }
    }, 0)
  }

  deletePosition(item, pos, i) {
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: pos,
        target: marker("Position")
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        this.attachSubscriptions(
          this.companyService.deletePosition(pos.id).subscribe(resp => {
            item.values.splice(i, 1)
          }, error => {
            console.log(error)
          })
        )
      }
    });

  }

  expandSubItem(item, pos) {
    pos.isOpen = !pos.isOpen;
    if (!pos.executors) {
      pos.executors = [];
    }

    this.attachSubscriptions(
      this.membersService.getPostionsToEmployee(this.company_id, {department_id: item.id, department_position_id: pos.id}).subscribe(resp => {
        pos.executors = resp

        console.log("expandSubItem", item, pos)
      })
    )
  }

  editPosToEm(posToEm, key) {
    if (posToEm.isEditing) {
      return
    }
    posToEm.isEditing = true;
    this.attachSubscriptions(
      this.membersService.editPositionToEmployee(posToEm.id, {[key]: !!posToEm[key] ? 0 : 1}).subscribe(resp => {
        posToEm.is_department_view = resp.is_department_view;
        posToEm.is_department_position_view = resp.is_department_position_view;
        posToEm.is_department_manage_prices = resp.is_department_manage_prices;
        posToEm.is_department_position_manage_prices = resp.is_department_position_manage_prices;
        posToEm.isEditing = false;
      }, error => {
        this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
        posToEm.isEditing = false;
      })
    )
  }

  expandItem(item) {
    item.isOpen = !item.isOpen;
    if (!item.values) {
      item.values = [];
    }
    this.attachSubscriptions(
      this.companyService.getPositions(this.company_id, {department_id: item.id}).pipe(
        map(x => x.body)
      ).subscribe(resp => {
        item.values = resp
      })
    )
  }

  addPosition(item) {
    this.attachSubscriptions(
      this.companyService.addPosition({department_id: item.id, company_id: this.company_id, name: "Position name"}).subscribe(resp => {
        if (!item.values) {
          item.values = [];
        }
        item.values.unshift(resp)
        this.focusPos(item, resp)
      }, error => {
        this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
      })
    )
  }


  editPosition(item, pos, e) {
    console.log(item, pos, e)
    pos.isEddited = false;
    let savedName = pos.name;

    this.attachSubscriptions(
      this.companyService.editPosition(pos.id, {name: e.target.value}).subscribe(resp => {
        console.log("editPosition", resp)
        pos = resp;
      }, error => {
        pos.name = savedName;
        this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
      })
    )
  }

  getCompany() {
    this.attachSubscriptions(
      this.companyService.getCompany(this.company_id).subscribe(resp => {
        this.company = resp[0];
        this.companyService.company$.next(resp[0]);
      })
    )
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  getCsrf() {
    this.attachSubscriptions(
      this.auth.$userToken.subscribe(resp => {
        this.user_id = resp.user_id;
        this.sm.localStorageSetItem("csrf_param", resp.csrf_param)
        this.sm.localStorageSetItem("csrf_token", resp.csrf_token)
      })
    )
  }

  getUser() {
    this.attachSubscriptions(
      this.auth.$user.subscribe(resp => {
        this.user = resp;
      })
    )
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
