import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fullTime'
})
export class FullTimeFormatPipe implements PipeTransform {
    transform(time: number, withDays:boolean = false, withoutMs:boolean = false, withoutS:boolean = false) {


        if (!withDays) {
            const hours = Math.floor(time / 3600);
            const minutes = Math.floor((time % 3600) / 60);
            const seconds = Math.floor(time % 60);
            const milliseconds = Math.floor((time % 1) * 1000);
            
            let silverTime:string = '';
            let blackTime:string = '';
            if (hours > 0) {
                blackTime += padZero(hours) + ':';
            } else {
                silverTime += '00:'
            }        
    
            if (minutes > 0) {
                blackTime += padZero(minutes) + ':';
            } else {
                if (hours > 0) {
                    blackTime += '00:'
                } else {
                    silverTime += '00:'
                }
            }
            
            if (seconds > 0) {
                blackTime += padZero(seconds) + '.';
            } else {
                if (hours > 0 || minutes > 0) {
                    blackTime += '00.'
                } else {
                    silverTime += '00.'
                }
            }
    
            if (milliseconds > 0) {
                blackTime += padZero(Math.floor(milliseconds / 10));
            } else {
                if (hours > 0 || minutes > 0 || seconds > 0) {
                    blackTime += '00'
                } else {
                    silverTime += '00'
                }
            }

            if (!!withoutMs) {
                console.log("withoutMs", withoutMs)
                console.log("withoutMs", {silverTime,blackTime})
                silverTime = silverTime.replace('.00', '')
                blackTime = blackTime.replace('.00', '')
            }

            if (!!withoutS) {
                console.log("withoutS", withoutMs)
                console.log("withoutS", {silverTime,blackTime})
                if (blackTime == '') {
                    let lastColonIndex = silverTime.lastIndexOf(':');
                    silverTime = silverTime.substring(0, lastColonIndex);
                } else {
                    let lastColonIndex = blackTime.lastIndexOf(':');
                    blackTime = blackTime.substring(0, lastColonIndex);
                }
            }
            
            return {silverTime,blackTime};
        } else {
            const days = Math.floor(time / 86400);
            const hours = Math.floor((time / 3600) / 3600);
            const minutes = Math.floor((time % 3600) / 60);
            const seconds = Math.floor(time % 60);
            const milliseconds = Math.floor((time % 1) * 1000);
            
            let silverTime = '';
            let blackTime = '';

            if (days > 0) {
                blackTime += padZero(days) + ':';
            } else {
                silverTime += '00:'
            }        

            if (hours > 0) {
                blackTime += padZero(hours) + ':';
            } else {
                if (days > 0) {
                    blackTime += '00:'
                } else {
                    silverTime += '00:'
                }
            }        
    
            if (minutes > 0) {
                blackTime += padZero(minutes) + ':';
            } else {
                if (days > 0 || hours > 0) {
                    blackTime += '00:'
                } else {
                    silverTime += '00:'
                }
            }
            
            if (seconds > 0) {
                blackTime += padZero(seconds) + '.';
            } else {
                if (days > 0 || hours > 0 || minutes > 0) {
                    blackTime += '00.'
                } else {
                    silverTime += '00.'
                }
            }
    
            if (milliseconds > 0) {
                blackTime += padZero(Math.floor(milliseconds / 10));
            } else {
                if (days > 0 || hours > 0 || minutes > 0 || seconds > 0) {
                    blackTime += '00'
                } else {
                    silverTime += '00'
                }
            }
            
            return {silverTime,blackTime};
        }


        
        
        function padZero(num: number, size: number = 2): string {
            let numStr = num.toString();
            while (numStr.length < size) {
                numStr = '0' + numStr;
            }
            return numStr;
        }
    }
}
