import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, of, ReplaySubject, timer } from 'rxjs';
import { catchError, debounceTime, finalize, last, map, switchMap, tap } from 'rxjs/operators';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { AuthService } from 'src/app/shared/services/rest/auth.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { ConnectedAppsService } from 'src/app/shared/services/rest/connected-apps.service';
import { ParametersService } from 'src/app/shared/services/rest/parameters.service';
import { environment } from 'src/environments/environment';
import { GroupCreateComponent } from '../../categorization/groups/group-create/group-create.component';
import { ProfileCreateComponent } from '../profile-create/profile-create.component';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
// import { MY_FORMATS } from 'src/app/components/atTasksDialog/task-profile-add/task-profile-add.component';
import { NgxMatDateAdapter } from '@angular-material-components/datetime-picker';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as moment from 'moment';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { TaskService } from 'src/app/shared/services/rest/task.service';

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/DD/YYYY, hh:mm:ss A', // Парсинг с разделителем "/"
  },
  display: {
    dateInput: 'MM/DD/YYYY, hh:mm:ss A', // Отображение с разделителем "/"
    monthYearLabel: 'MMM YYYY', // Отображение месяца и года
    dateA11yLabel: 'MMMM D, YYYY', // Для доступности
    monthYearA11yLabel: 'MMMM YYYY', // Для доступности месяца/года
  },
};

@Component({
  selector: 'app-outlet-assistant',
  templateUrl: './outlet-assistant.component.html',
  styleUrls: ['./outlet-assistant.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ]
})

export class OutletAssistantComponent extends BaseClass implements OnInit, OnDestroy {
  public host: any = environment.host;
  public imgRoute: any = '';
  public type: any = '';
  public activeLang: any;
  public user: any = {};
  public company_id: any;
  public company: any;
  public contentTypes: any;
  public isSubmit: boolean = false;
  public isRefreshedChannels: boolean = false;
  public isSubmitRegs: boolean = false;
  public isSubmitOutlet: boolean = false;
  public count:number = 1;
  public alertTypes: boolean = false;
  private retryCount = 0; // Добавляем счетчик попыток
  private maxRetries = 30; // Максимальное количество попыток
  public weekDays = new Array(7)
  public monthDays = new Array(31)
  
  public schedule_title: any = '';
  public types:any = ['is_daily', 'is_weekly', 'is_monthly']
  public slots: any[] = []
  public regFormat: any = 'is_daily'
  public apply_from_time: any = moment();
  public apply_to_time: any;
  public period: number = 90;
  
  public index: number = 1;
  public createOutlet: FormGroup = this.fb.group({
      // company_id: this.company_id,
      // platform_id: ['', Validators.required],
      name: ['', Validators.required],
      url: '',
      ai_description: ''
  });

  public channel_id: any = this.activatedRoute.snapshot.queryParamMap.get('channel_id');
  public platform_id: number = +this.activatedRoute.snapshot.queryParamMap.get('platform_id');
  public outlet_id: any = this.activatedRoute.snapshot.queryParamMap.get('outlet_id');
  // public group_id: number[] = !!this.activatedRoute.snapshot.queryParamMap.get('group_id') ? this.activatedRoute.snapshot.queryParamMap.get('group_id').split(',').map(Number) : [];
  // public content_type_id: number = +this.activatedRoute.snapshot.queryParamMap.get('content_type_id');
  public lang_id: number = +this.activatedRoute.snapshot.queryParamMap.get('lang_id');
  public sftp_id: any = this.activatedRoute.snapshot.queryParamMap.get('sftp_id');

  public chToGroups: any[];

  public expChannels: any = [];
  public expChannels$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public expChannelsControl: FormControl = new FormControl();

  
  public groups: any[] = [];
  public groups$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public groupsControl: FormControl = new FormControl();

  public sftps: any;
  public sftps$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public sftpsControl: FormControl = new FormControl();

  public langs: any;
  public langs$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public langsControl: FormControl = new FormControl();

  public platforms: any;
  public platforms$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public platformsMoreControl: FormControl = new FormControl();
  
  public profiles: any[] = [];
  public profilesMoreControl: FormControl = new FormControl();
  public profiles$: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(
      private sm: StorageManagerService,
      private auth: AuthService,
      private companyService: CompanyService,
      private languageService: LanguageService,
      private taskService: TaskService,
      private connectedAppsService: ConnectedAppsService,
      private parametersService: ParametersService,
      private fb: FormBuilder,
      private router: Router,
      private _ngx_adapter: NgxMatDateAdapter<any>,
      private dialog: MatDialog,
      private activatedRoute: ActivatedRoute,
      private bottomSheet: MatBottomSheet,
      private layoutService: LayoutService
  ) { super() }

  ngOnInit(): void {
    this.company_id = this.activatedRoute.snapshot.queryParamMap.get('company_id');
    
    if (this.activatedRoute.snapshot.queryParamMap.get('chToGroups')) {
      this.chToGroups = JSON.parse(decodeURIComponent(this.activatedRoute.snapshot.queryParamMap.get('chToGroups')))
    } else {
      this.chToGroups = []
    }

    this.attachSubscriptions(
      this.languageService.getLangData().subscribe(resp => {
        this.activeLang = resp.active;
        
        this._ngx_adapter.setLocale(resp.active);
        // this._adapter.setLocale(resp.active);
      })
    )

    


    this.attachSubscriptions(
      this.platformsMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchPlatforms(resp))
    )

    this.attachSubscriptions(
      this.profilesMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchProfiles(resp))
    )

    this.attachSubscriptions(
      this.groupsControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchGroups(resp))
    )

    this.attachSubscriptions(
      this.langsControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchLangs(resp))
    )

    this.getCompany();
    this.getPlatforms();

    this.getImgRoute();
    this.getCsrf();
    this.getUser();
    this.onRouteChange();
    this.setToTime();
  }

  onInput(event: Event, key, min?, max?): void {
    const inputElement = event.target as HTMLInputElement;
    let value = Number(inputElement.value);
  
    // Если значение меньше min или не является числом, устанавливаем 60
    if (!!min) {
      if (isNaN(value) || value < min) {
        value = min;
      }
    } else {
      if (isNaN(value)) {
        value = this[key];
      }
    }

    if (max && value > max) {
      value = max;
    }
  
    // Обновляем значение в форме
    this[key] = value;
    this.setToTime();
  }

  addEvent(event: MatDatepickerInputEvent<any>) {
    console.log("addEvent", event);
    this.setToTime();
  }

  setToTime() {
    this.apply_to_time = this.apply_from_time.clone().add(this.period, 'days');
  }

  setRegFormat(val) {
    if (val == this.regFormat) {
      return
    }
    this.slots = [];
    this.regFormat = val;
  }

  onSearchLangs(resp) {
    if (!this.langs) {
      return;
    }

    if (!resp) {
      this.langs$.next(this.langs.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }

    this.langs$.next(
      this.langs.filter(b => b.value.toLowerCase().indexOf(resp) > -1)
    );
  }

  onSearchGroups(resp) {
    if (!this.groups) {
      return;
    }

    if (!resp) {
      this.groups$.next(this.groups.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }

    this.groups$.next(
      this.groups.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  
  submitOutlet() {
    this.isSubmitOutlet = true;
    let createData = {...this.createOutlet.value};
    createData.company_id = this.company_id;
    createData.platform_id = this.platform_id;
    this.attachSubscriptions(
      this.companyService.createCompanyGroupProfile(createData).subscribe(resp => {
        this.isSubmitOutlet = false;
        this.router.navigate(['/outlet-assistant'], { queryParams: {company_id: this.company_id, platform_id: this.platform_id, channel_id: this.channel_id, outlet_id: resp.id, count: this.count} });
      }, error => {
        this.isSubmitOutlet = false;
        this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
      })
    )
  }

  onSearchProfiles(resp) {
    if (!this.profiles) {
      return;
    }

    if (!resp) {
      this.profiles$.next(this.profiles.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }

    // filter the banks
    this.profiles$.next(
      this.profiles.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  addProfile(e) {
    e.preventDefault();
    e.stopPropagation();
    const dialogRef = this.dialog.open(ProfileCreateComponent, {
      disableClose: true,
      data: {
        company: this.company,
        company_id: this.company_id,
        platform_id: this.platform_id,
        account: this.getExpChanById(this.channel_id)
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result && result.event == "update") {
          if (result.data) {
            this.outlet_id = result.data.id
          }
          this.getProfiles()
        }
      })
    )
  }

  addGroup(e) {
    e.preventDefault();
    e.stopPropagation();
    const dialogRef = this.dialog.open(GroupCreateComponent, {
      disableClose: true,
      data: {
        user: this.user,
        company: this.company,
        company_id: this.company_id,
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result && result.event == "update") {
          if (result.data) {
            this.chToGroups.push({
              group_id: result.data.id,
              content_type_id: []
            })
          }
          this.groups.unshift(result.data);
          this.groups$.next(this.groups.slice());
          // this.getGroupsCompany()
        }
      })
    )
  }
  
  goToIndex(val) {
    if (this.index >= val) {
      this.index = val;
    }

    this.focusInput();
  }

  focusInput() {
    setTimeout(() => {
      const parent = document.getElementById('assistant-body');
      if (!parent) return;
  
      const inputElement = parent.getElementsByClassName('search_inp')[0] as HTMLElement;
      if (inputElement) {
        inputElement.focus();
      }
    }, 0);
  }

  getProfiles() {
    this.attachSubscriptions(
      this.companyService.getAllProfilesDyn('1', this.company_id, '1', {platform_id: this.platform_id}).pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }
          return forkJoin(arr.map(x => this.companyService.getAllProfilesDyn(x, this.company_id, '200', {platform_id: this.platform_id}).pipe(map(u => u.body)))).pipe(
            last(),
            tap(values => {
              let conValues = [].concat(...values)
              this.profiles = conValues;
              this.profiles$.next(this.profiles.slice());
            }),
          )
        }),
      ).subscribe(resp => {
        if (this.outlet_id && this.profiles.length > 1) {
          const element = this.profiles.splice(this.profiles.findIndex(k => k.id == this.outlet_id), 1)[0]; // Удаляем элемент из массива
          this.profiles.unshift(element); // Добавляем его в начало массива
          this.profiles$.next(this.profiles.slice());
        }
        console.log("getProfiles resp", resp);
        console.log("getProfiles profiles", this.profiles);
      })
    )
  }

  getChannelAccountsChannels() {
    this.isRefreshedChannels = true;
  
    const channelAccountId = this.activatedRoute.snapshot.queryParamMap.get('channel_account_id');
  
    this.attachSubscriptions(
      this.connectedAppsService.getChannelAccountsChannelsDyn('1', null, channelAccountId, this.type, 1, '1', this.company_id).pipe(
        switchMap(el => {
          const totalCount = el.headers.get('x-pagination-total-count');
          const pages = Math.ceil(totalCount / 200);
  
          if (pages === 0) {
            this.expChannels = [];
            this.expChannels$.next(this.expChannels.slice());
            return of([]);
          }
  
          const requests = Array.from({ length: pages }, (_, index) =>
            this.connectedAppsService.getChannelAccountsChannelsDyn(index + 1, null, channelAccountId, this.type, 1, '200', this.company_id).pipe(
              map(u => u.body)
            )
          );
  
          return forkJoin(requests).pipe(
            map(responses => [].concat(...responses)),
            tap(conValues => {
              this.expChannels = conValues.map(x => ({ ...x, avatarFile: x.imageFile }));
              this.expChannels$.next(this.expChannels.slice());
            }),
            last()
          );
        }),
        finalize(() => {
          if (!this.expChannels.length && this.retryCount < this.maxRetries) {
            this.retryCount++; // Увеличиваем счетчик попыток
            timer(3000).subscribe(() => this.getChannelAccountsChannels());
          } else {
            this.isRefreshedChannels = false;
            this.retryCount = 0; // Сбрасываем счетчик после успешного выполнения или достижения максимума
          }
          console.log("getChannelAccountsChannels", this.expChannels);
        })
      ).subscribe()
    );
  }

  // getChannelAccountsChannels() {
  //   this.isRefreshedChannels = true;
  //   this.attachSubscriptions(
  //     this.connectedAppsService.getChannelAccountsChannelsDyn('1', null, this.activatedRoute.snapshot.queryParamMap.get('channel_account_id'), this.type, 1, '1', this.company_id).pipe(
  //       switchMap(el => {
  //         let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
  //         let arr = []
  //         for (let index = 1; index <= pages; index++) {
  //           arr.push(index)
  //         }
  
  //         if (arr.length) {
  //           return forkJoin(arr.map(x => this.connectedAppsService.getChannelAccountsChannelsDyn(x, null, this.activatedRoute.snapshot.queryParamMap.get('channel_account_id'), this.type, 1, '200', this.company_id).pipe(map(u => u.body)))).pipe(
  //             last(),
  //             tap(values => {
  //               let conValues = [].concat(...values)
  //               this.expChannels = conValues;
  //               this.expChannels.forEach(x => {
  //                 x.avatarFile = x.imageFile
  //               })
  //               this.expChannels$.next(this.expChannels.slice())
  //             }),
  //           )
  //         } else {
  //           return of([])
  //         }
  //       }),
  //     ).subscribe(resp => {
  //       this.isRefreshedChannels = false;
  //       if (!this.expChannels.length) {
  //         this.getChannelAccountsChannels();
  //       }
  //       console.log("getChannelAccountsChannels", this.expChannels);
  //     })
  //   )
  // }

  getLangs() {
    this.attachSubscriptions(
      this.parametersService.getAllValues(1, this.company_id, {is_language: 1}, '200').pipe(
        map(res => res.body),
        tap(res => {
          this.langs = res;
          this.langs$.next(this.langs.filter(x => !!x.id).slice())
        }),
      ).subscribe(resp => {
        console.log("this.langs", this.langs)
      })
    )
  }


  onRouteChange() {
    this.attachSubscriptions(
      this.activatedRoute.queryParams.pipe(
        tap((val) => {
          this.company_id = this.activatedRoute.snapshot.queryParamMap.get('company_id');
          this.platform_id = +this.activatedRoute.snapshot.queryParamMap.get('platform_id');
          this.channel_id = this.activatedRoute.snapshot.queryParamMap.get('channel_id');
          this.outlet_id = this.activatedRoute.snapshot.queryParamMap.get('outlet_id');
          // this.group_id = !!this.activatedRoute.snapshot.queryParamMap.get('group_id') ? this.activatedRoute.snapshot.queryParamMap.get('group_id').split(',').map(Number) : [];
          // this.content_type_id = +this.activatedRoute.snapshot.queryParamMap.get('content_type_id');
          this.lang_id = +this.activatedRoute.snapshot.queryParamMap.get('lang_id');
          this.sftp_id = this.activatedRoute.snapshot.queryParamMap.get('sftp_id');
          this.count = +this.activatedRoute.snapshot.queryParamMap.get('count') || 0;
          this.count++;

          if (this.activatedRoute.snapshot.queryParamMap.get('chToGroups')) {
            this.chToGroups = JSON.parse(decodeURIComponent(this.activatedRoute.snapshot.queryParamMap.get('chToGroups')))
          } else {
            this.chToGroups = []
          }

          if (!!this.platform_id) {
            switch (this.platform_id) {
              case 1:
                this.type = "youtube"
                break;
              case 2:
                this.type = "instagram"
                break;
              case 3:
                this.type = "tiktok"
                break;
              case 4:
                this.type = "facebook"
                break;
              case 5:
                this.type = "snapchat"
                break;
              default:
                this.type = "youtube"
                break;
            }
            if (this.platform_id == 1) {
              this.getSftps();
            } else {
              this.sftp_id = 'no';
            }
            this.index = 2;

            if (!!this.activatedRoute.snapshot.queryParamMap.get('is_auth')) {
              this.getChannelAccountAuthUrl();
            }

            this.getContentTypes(this.platform_id)
            this.getChannelAccountsChannels();
          }
          
          if (this.channel_id) {
            // if (!this.profiles || !this.profiles.length) {}
            if (this.channel_id != 'no') {
              this.getProfiles();
            } else {
              if (this.platform_id == 1 && !this.createOutlet.get('publishing_params')) {
                this.createOutlet.addControl('publishing_params', this.fb.group({
                  made_for_kids: false
                }))
              } else {
                if (!!this.createOutlet.get('publishing_params')) {
                  this.createOutlet.removeControl('publishing_params')
                }
              }
            }
            this.index = 3
          }

          if (this.outlet_id && this.outlet_id != 'new') {
            if (this.channel_id == 'no') {
              this.getProfiles();
            }
            this.getGroupsCompany();
            this.index = 4
          }

          if (this.chToGroups.length && this.chToGroups.every(k => !!k.content_type_id.length)) {
            this.getLangs();
            this.index = 5
          }

          // if (this.group_id && this.group_id.length && this.content_type_id) {
          //   this.getLangs();
          //   this.index = 5
          // }

          if (this.lang_id) {
            if (this.sftps && this.sftps.length) {
              this.index = 6;
              
              if (this.sftp_id && this.sftp_id == 'no') {
                this.index = 7;
              }
            } else {
              // if (this.sftp_id && this.sftp_id == 'no') {
              // }
              this.index = 7;
            }
          }
          
          if (this.sftp_id && this.sftp_id != 'no') {
            this.index = 7
          }
          
          this.focusInput();
        })
      ).subscribe(res => {
        console.log("this.activatedRoute.snapshot.queryParamMap.get('is_auth')", this.activatedRoute.snapshot.queryParamMap.get('is_auth'));
        console.log("company_id", this.company_id);
        console.log("platform_id", this.platform_id);
        console.log("chToGroups", this.chToGroups);
        console.log("channel_id", this.channel_id);
        console.log("lang_id", this.lang_id);
      })
    )
  }



  getSftps() {
    this.attachSubscriptions(
      this.companyService.getSftps('1', this.company_id, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.companyService.getSftps(x, this.company_id, '200').pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        if (!res.length) {
          this.sftp_id = 'no';
        }
        this.sftps = [].concat(...res);
        this.sftps$.next(this.sftps.slice());
      })
    )
  }

  getContentTypes(platform_id) {
    this.attachSubscriptions(
      this.companyService.getContentTypes(platform_id).pipe(
        map(el => el.body)
      ).subscribe(resp => {
        this.contentTypes = resp;
      })
    )
  }

  getChannelAccountAuthUrl() {
    this.attachSubscriptions(
      this.connectedAppsService.getChannelAccountAuthUrl(this.type, `${window.location.origin}/outlet-assistant?company_id=${this.company_id}&platform_id=${this.platform_id}`, 0, this.company_id).subscribe(resp => {
        console.log(resp);
        console.log(this.host + resp);
        window.open(this.host + resp, "_self")
      })
    )
  }

  addSlot() {
    // 
    let newItem:any = {
      company_id: this.company_id, 
      content_schedule_id: 0, 
      name: "New regular publication",
      timeInFormat: new Date(3600 * 1000).toISOString().slice(11, 16)
    }
    if (this.regFormat != 'is_daily') {
      newItem.day_number = 1
    }
    this.slots.push(newItem)
  }

  saveRegular() {
    console.log('schedule_title', this.schedule_title)
    console.log('slots', this.slots)
    console.log('regFormat', this.regFormat)
    console.log('apply_from_time', this.apply_from_time)
    console.log('apply_to_time', this.apply_to_time)
    console.log('period', this.period)
    // return
    this.isSubmitRegs = true;

    let dataRegs = [];

    this.chToGroups.forEach(chTo => {
      chTo.content_type_id.forEach(cT => {
        if (!dataRegs.filter(k => k.content_type_id == cT).length) {
          dataRegs.push({
            company_id: this.company_id,
            channel_id: this.outlet_id,
            content_type_id: cT,
            name: this.schedule_title,
            [this.regFormat]: 1,
            apply_from_time: moment(this.apply_from_time._d).utc().format("X"),
            apply_to_time: moment(this.apply_to_time._d).utc().format("X"),
          })
        }
      });
    })

    // let sbmtData:any = {
    //   company_id: this.company_id,
    //   channel_id: this.outlet_id,
    //   content_type_id: this.content_type_id,
    //   name: this.schedule_title,
    //   [this.regFormat]: 1,
    //   apply_from_time: moment(this.apply_from_time._d).utc().format("X"),
    //   apply_to_time: moment(this.apply_to_time._d).utc().format("X"),
    // }

    this.attachSubscriptions(
      forkJoin([...dataRegs.map(sbmtData => {
        return this.companyService.addRegular(sbmtData).pipe(
          switchMap(output => {
            if (this.slots && this.slots.length) {
              let postData = [];
                    
              this.slots.forEach(item => {
                let x:any = {
                  "path": `/api/content-schedule-item/`,
                  "query": {company_id: this.company_id},
                  "method": "POST",
                  "body": {
                    company_id: output.company_id,
                    content_schedule_id: output.id,
                    name:	item.name,
                    time:	Number(item.timeInFormat.split(":")[0])*3600 + Number(item.timeInFormat.split(":")[1])*60,
                    [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
                  }
                }
  
                if (item.hasOwnProperty('day_number')) {
                  x.body.day_number = item.day_number
                }
                postData.push(x)
              });
              return this.taskService.multiRequest(postData).pipe(map(() => output))
            } else {
              return of(output)
            }
          })
        )
      })]).subscribe(resp => {
        this.isSubmitRegs = false;
        this.goToOutlets();
      }, error => {
        this.isSubmitRegs = false;
        this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
      })
    )
    // this.router.navigate(['/settings'], { queryParams: {company_id: this.company_id, page: 'profiles'} });
  }

  goToOutlets() {
    this.router.navigate(['/settings'], { queryParams: {company_id: this.company_id, page: 'profiles'} });
  }

  selectSftp(id) {
    if (this.sftp_id && id) {
      let sftpData:any = {
        company_id: this.company_id,
        channel_id: this.outlet_id,
        youtube_sftp_dropbox_id: this.sftp_id
      }
      this.attachSubscriptions(
        this.companyService.addSftpChannel(sftpData).pipe(
          catchError(error => {
            console.log(error);
            sftpData.is_replace = 1;
            return this.companyService.addSftpChannel(sftpData)
          }),
          map((x) => x)
        ).subscribe(resp => {
          // this.router.navigate(['/settings'], { queryParams: {company_id: this.company_id, page: 'profiles'} });
          this.router.navigate(['/outlet-assistant'], { queryParams: {company_id: this.company_id, platform_id: this.platform_id, channel_id: this.channel_id, outlet_id: this.outlet_id, chToGroups: encodeURIComponent(JSON.stringify(this.chToGroups)), lang_id: this.lang_id, sftp_id: id, count: this.count} });
        })
      )
    } else {
      this.router.navigate(['/outlet-assistant'], { queryParams: {company_id: this.company_id, platform_id: this.platform_id, channel_id: this.channel_id, outlet_id: this.outlet_id, chToGroups: encodeURIComponent(JSON.stringify(this.chToGroups)), lang_id: this.lang_id, sftp_id: 'no', count: this.count} });
      // this.router.navigate(['/settings'], { queryParams: {company_id: this.company_id, page: 'profiles'} });
    }
  }

  selectLang(id) {
    this.router.navigate(['/outlet-assistant'], { queryParams: {company_id: this.company_id, platform_id: this.platform_id, channel_id: this.channel_id, outlet_id: this.outlet_id, chToGroups: encodeURIComponent(JSON.stringify(this.chToGroups)), lang_id: id, count: this.count} });
  }

  selectGroup() {
    if (!this.isGroupsOk()) {
      this.alertTypes = true;
      this.layoutService.showSnackBar({name: ''}, "Select content type", SnackBarItem)
      return
    }
    this.alertTypes = false;
    this.router.navigate(['/outlet-assistant'], { queryParams: {company_id: this.company_id, platform_id: this.platform_id, channel_id: this.channel_id, outlet_id: this.outlet_id, chToGroups: encodeURIComponent(JSON.stringify(this.chToGroups)), count: this.count} });
  }

  selectOutlet(id) {
    this.router.navigate(['/outlet-assistant'], { queryParams: {company_id: this.company_id, platform_id: this.platform_id, channel_id: this.channel_id, outlet_id: id, count: this.count} });
  }

  // selectPlatform(id, is_auth?) {
  //   this.router.navigate(['/outlet-assistant'], !!is_auth ? { queryParams: {company_id: this.company_id, platform_id: id, is_auth: 1} } : { queryParams: {company_id: this.company_id, platform_id: id} });
  // }
  selectPlatform(id, is_auth?, is_new_outlet?) {
    if (is_new_outlet) {
      this.channel_id = 'no';
      this.outlet_id = 'new';
      this.router.navigate(['/outlet-assistant'], { queryParams: {company_id: this.company_id, platform_id: this.platform_id, channel_id: this.channel_id, outlet_id: this.outlet_id, count: this.count} });
    } else {
      this.router.navigate(['/outlet-assistant'], !!is_auth ? { queryParams: {company_id: this.company_id, platform_id: id, is_auth: 1} } : { queryParams: {company_id: this.company_id, platform_id: id, count: this.count} });
    }
  }

  selectChannel(id) {
    this.router.navigate(['/outlet-assistant'], { queryParams: {company_id: this.company_id, platform_id: this.platform_id, channel_id: id, count: this.count} });
  }

  getGroupsCompany() {
    this.attachSubscriptions(
      this.companyService.getInfiniteGroupsCompanyWithoutExp(this.company_id, '1', '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          if (!arr.length) {
            return of([[]])
          }

          return forkJoin(arr.map(x => this.companyService.getInfiniteGroupsCompanyWithoutExp(this.company_id, x).pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        this.groups = [].concat(...res);
        this.groups$.next(this.groups.slice());
        console.log("app-outlet-assistant groups", this.groups)
      })
    )
  }

  getProfileById(id) {
    if (!this.profiles || this.profiles.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.profiles.find(el => el.id == id)
  }

  getExpChanById(id) {
    if (!this.expChannels || this.expChannels.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.expChannels.find(el => el.id == id)
  }

  getGroupById(id) {
    if (!this.groups || this.groups.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.groups.find(el => el.id == id)
  }

  onSearchPlatforms(resp) {
    if (!this.platforms) {
      return;
    }

    if (!resp) {
      this.platforms$.next(this.platforms.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }

    // filter the banks
    this.platforms$.next(
      this.platforms.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  getPlatforms() {
    this.attachSubscriptions(
      this.companyService.getGroupPlatforms().subscribe(resp => {
        this.platforms = resp;
        this.platforms$.next(this.platforms.slice());
      })
    )
  }

  getCompany() {
    this.attachSubscriptions(
      this.companyService.getCompany(this.company_id).subscribe(resp => {
        this.companyService.company$.next(resp[0]);
        this.company = resp[0];
      })
    )
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  getCsrf() {
    this.attachSubscriptions(
      this.auth.$userToken.subscribe(resp => {
        this.sm.localStorageSetItem("csrf_param", resp.csrf_param)
        this.sm.localStorageSetItem("csrf_token", resp.csrf_token)
      })
    )
  }

  getUser() {
    this.attachSubscriptions(
      this.auth.$user.subscribe(resp => {
        this.user = resp;
      })
    )
  }

  setType(e, id, group_id) {
    e.preventDefault();
    e.stopPropagation();
    // if (!this.group_id.includes(group_id)) {
    //   this.group_id.push(group_id)
    // }
    // this.content_type_id = id;
    if (this.chToGroups.find(k => k.group_id == group_id)) {
      if (this.chToGroups.find(k => k.group_id == group_id).content_type_id.includes(id)) {
        this.chToGroups.find(k => k.group_id == group_id).content_type_id.splice(this.chToGroups.find(k => k.group_id == group_id).content_type_id.findIndex(u => u == id), 1)
      } else {
        if (!!this.chToGroups.find(k => k.group_id == group_id).content_type_id.length) {
          this.chToGroups.find(k => k.group_id == group_id).content_type_id = [id]
        } else {
          this.chToGroups.find(k => k.group_id == group_id).content_type_id.push(id)
        }
      }
    } else {
      this.chToGroups.push({
        group_id: group_id,
        content_type_id: [id]
      })
    }
  }

  setGroup(id) {
    // if (!!this.group_id.includes(id)) {
    //   this.group_id.splice(this.group_id.findIndex(k => k == id), 1)
    // } else {
    //   this.group_id.push(id)
    // }
    if (this.chToGroups.find(k => k.group_id == id)) {
      this.chToGroups.splice(this.chToGroups.findIndex(k => k.group_id == id), 1)
    } else {
      this.chToGroups.push({
        group_id: id,
        content_type_id: []
      })
    }
  }

  isActiveGroup(id) {
    return !!this.chToGroups.filter(k => k.group_id == id).length
  }

  hasGroups() {
    return this.chToGroups.length
  }

  isGroupsOk() {
    return this.chToGroups.length && this.chToGroups.every(k => !!k.content_type_id.length)
  }

  isCorrectGroup(id) {
    if (this.chToGroups.find(k => k.group_id == id) && !!this.chToGroups.find(k => k.group_id == id).content_type_id.length) {
      return true
    } else {
      return false
    }
  }

  groupTypes(id) {
    if (this.chToGroups.find(k => k.group_id == id) && !!this.chToGroups.find(k => k.group_id == id).content_type_id.length) {
      return this.chToGroups.find(k => k.group_id == id).content_type_id
    } else {
      return []
    }
  }

  getLangById(id) {
    if (!this.langs || this.langs.length == 0) {
      return null
    }
    return this.langs.find(x => x.id == id)
  }

  finish() {
    this.isSubmit = true;

    if (this.channel_id == 'no') {
      if (!this.getProfileById(this.outlet_id)) {
        this.layoutService.showSnackBar({name: ''}, "Something went wrong, refresh the page and try again", SnackBarItem)
        this.isSubmit = false;
        return
      }
      // let finishData = {
      //   ai_description: "",
      //   company_id: this.company_id,
      //   content_type_id: this.content_type_id,
      //   // group_id: this.group_id,
      //   platform_id: this.platform_id,
      //   youtube_sftp_dropbox_id: this.sftp_id
      // }
      this.attachSubscriptions(
        of(this.getProfileById(this.outlet_id)).pipe(
          map((profile) => profile),
          switchMap(profile => {
            console.log("Test1")
            let activeVals = [];
            activeVals.push({
              channel_id: profile.id,
              company_id:	this.company_id,
              parameter_value_id: this.lang_id,
              parameter_id: this.getLangById(this.lang_id).parameter_id
            })
            if (activeVals.length) {
              return forkJoin([...activeVals.map(p => this.parametersService.addTargetChannelParameter(p).pipe(
                catchError(error => {
                  console.log(error);
                  return of(error)
                })
              ))]).pipe(map(() => profile))
            } else {
              return of(profile)
            }
          }),
          switchMap(profile => {
            console.log("Test2")
            let dataChToGroups = [];

            this.chToGroups.forEach(chTo => {
              chTo.content_type_id.forEach(cT => {
                dataChToGroups.push({
                  company_id: this.company_id,
                  channel_id: profile.id,
                  content_type_id: cT,
                  channel_group_id: chTo.group_id
                })
              });
            })
  
            if (dataChToGroups && dataChToGroups.length) {
              return forkJoin([...dataChToGroups.map(p => this.companyService.addChannelToGroup(p, this.company_id).pipe(
                catchError(error => {
                  console.log(error);
                  return of(error)
                })
              ))]).pipe(
                map(() => profile),
              )
            } else {
              return of(profile)
            }
          }),
          switchMap(profile => {
            console.log("Test3")
            if (this.sftp_id && this.sftp_id != 'no') {
              let sftpData:any = {
                company_id: this.company_id,
                channel_id: profile.id,
                youtube_sftp_dropbox_id: this.sftp_id
              }
              return this.companyService.addSftpChannel(sftpData).pipe(
                catchError(error => {
                  console.log(error);
                  sftpData.is_replace = 1;
                  return this.companyService.addSftpChannel(sftpData)
                }),
                map(() => profile)
              )
            } else {
              return of(profile)
            }
          })
        ).subscribe(resp => {
          console.log("Test4")
          this.isSubmit = false;
          this.selectLang(this.lang_id)
          // this.router.navigate(['/settings'], { queryParams: {company_id: this.company_id, page: 'profiles'} });
        }, error => {
          this.isSubmit = false;
          this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
        })
      )
    } else {
      if (!(this.getExpChanById(this.channel_id) && this.getExpChanById(this.channel_id).external_id)) {
        this.layoutService.showSnackBar({name: ''}, "Something went wrong, refresh the page and try again", SnackBarItem)
        this.isSubmit = false;
        return
      }
      let finishData = {
        ai_description: "",
        company_id: this.company_id,
        // content_type_id: this.content_type_id,
        external_id: this.getExpChanById(this.channel_id).external_id,
        // group_id: this.group_id,
        name: this.getExpChanById(this.channel_id).name,
        platform_id: this.platform_id,
        publishing_params: { made_for_kids: true },
        url: this.getExpChanById(this.channel_id).computedUrl,
        youtube_sftp_dropbox_id: this.sftp_id
      }
      let channelData = {
        company_id: this.company_id,
        channel_account_id: this.getExpChanById(this.channel_id).channel_account_id,
        external_id: finishData.external_id
      }
      this.attachSubscriptions(
        this.companyService.editCompanyGroupProfile(this.outlet_id, channelData).pipe(
          map((profile) => profile),
          switchMap(profile => {
            console.log("Test1")
            let activeVals = [];
            activeVals.push({
              channel_id: profile.id,
              company_id:	this.company_id,
              parameter_value_id: this.lang_id,
              parameter_id: this.getLangById(this.lang_id).parameter_id
            })
            if (activeVals.length) {
              return forkJoin([...activeVals.map(p => this.parametersService.addTargetChannelParameter(p).pipe(
                catchError(error => {
                  console.log(error);
                  return of(error)
                })
              ))]).pipe(map(() => profile))
            } else {
              return of(profile)
            }
          }),
          switchMap(profile => {
            console.log("Test2")

            let dataChToGroups = [];

            this.chToGroups.forEach(chTo => {
              chTo.content_type_id.forEach(cT => {
                dataChToGroups.push({
                  company_id: this.company_id,
                  channel_id: profile.id,
                  content_type_id: cT,
                  channel_group_id: chTo.group_id
                })
              });
            })
  
            if (dataChToGroups && dataChToGroups.length) {
              return forkJoin([...dataChToGroups.map(p => this.companyService.addChannelToGroup(p, this.company_id).pipe(
                catchError(error => {
                  console.log(error);
                  return of(error)
                })
              ))]).pipe(
                map(() => profile),
              )
            } else {
              return of(profile)
            }
          }),
          switchMap(profile => {
            console.log("Test3")
            if (this.sftp_id && this.sftp_id != 'no') {
              let sftpData:any = {
                company_id: this.company_id,
                channel_id: profile.id,
                youtube_sftp_dropbox_id: this.sftp_id
              }
              return this.companyService.addSftpChannel(sftpData).pipe(
                catchError(error => {
                  console.log(error);
                  sftpData.is_replace = 1;
                  return this.companyService.addSftpChannel(sftpData)
                }),
                map(() => profile)
              )
            } else {
              return of(profile)
            }
          })
        ).subscribe(resp => {
          console.log("Test4")
          this.isSubmit = false;
          this.selectLang(this.lang_id)
          // this.router.navigate(['/settings'], { queryParams: {company_id: this.company_id, page: 'profiles'} });
        }, error => {
          this.isSubmit = false;
          this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
        })
      )
    }
    

  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

}
