import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { switchMap, tap } from 'rxjs/operators';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { RootService } from 'src/app/shared/services/rest/root.service';

@Component({
  selector: 'app-translate-verified',
  templateUrl: './translate-verified.component.html',
  styleUrls: ['./translate-verified.component.scss']
})
export class TranslateVerifiedComponent extends BaseClass implements OnInit {
  public translations: any;
  public transForm: FormGroup;
  public activeLang: any;
  public languages: any;
  public isSubmit: boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<TranslateVerifiedComponent>,
    private rootService: RootService,
    private languageService: LanguageService,
    private fb: FormBuilder,
  ) { super() }

  ngOnInit(): void {
    console.log("TranslateVerifiedComponent", this.data)
    
    this.transForm = this.fb.group({
      verification_text: ['', Validators.required],
      company_id: this.data.company.id,
      language: ''
    })
    

    this.attachSubscriptions(
      this.transForm.get("language").valueChanges.subscribe(resp => {
        this.getTranslations()
      })
    )
    this.getLangData();
  }

  getTranslations() {
    this.attachSubscriptions(
      this.rootService.getCompanyTranslation(this.data.company.id).subscribe(resp => {
        console.log("getOperationTranslation", resp)
        this.translations = resp;
        if (resp.find(x => x.language == this.transForm.value.language)) {
          this.transForm.patchValue({
            verification_text: resp.find(x => x.language == this.transForm.value.language).verification_text
          })
        } else {
          this.transForm.patchValue({
            verification_text: ''
          })
        }
      })
    )
  }

  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData().pipe(
        tap(resp => {
          this.activeLang = resp.active;
          this.languages = resp.languages;
          this.transForm.patchValue({
            language: this.activeLang
          })
        })
      ).subscribe(resp => {
        console.log("getLangData", resp)
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }
  
  submitForm() {
    this.isSubmit = true;

    
    if (this.translations.filter(i => i.language == this.transForm.value.language).length == 0) {
      this.attachSubscriptions(
        this.rootService.createCompanyTranslation(this.transForm.value).pipe(
          tap(val => {
            this.translations.push(val)
          })
        ).subscribe(resp => {
          console.log(resp);
          this.isSubmit = false;
        })
      )
    } else {
      this.attachSubscriptions(
        this.rootService.updateCompanyTranslation(this.translations.find(i => i.language == this.transForm.value.language).id, {verification_text: this.transForm.value.verification_text}).pipe(
          tap(val => {
            this.translations.find(x => x.language == val.language).verification_text = this.transForm.value.verification_text
          })
        ).subscribe(resp => {
          console.log(resp);
          this.isSubmit = false;
        })
      )
    }
      
  }


  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

}
