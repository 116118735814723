<div mat-dialog-title class="title_modal">
    <div class="d-f-c" style="justify-content: space-between;" (click)="log()">
        <ng-container *ngIf="data.is_file; else elseTemplateNoFile">
            <ng-container *ngIf="!!data.is_edit; else elseTemplateDesc">
                <span class="text_one_line">
                    {{ "Document edditing" | translate }}: {{data?.file?.document?.name}}
                </span>
            </ng-container>
            <ng-template #elseTemplateDesc>
                <span>
                    {{ "Document adding" | translate }}: {{data?.file?.document?.name}}
                </span>
            </ng-template>
        </ng-container>
        <ng-template #elseTemplateNoFile>        
            <ng-container *ngIf="!!data.is_edit; else elseTemplateDesc">
                <span class="text_one_line">
                    {{ "Note edditing" | translate }}
                </span>
            </ng-container>
            <ng-template #elseTemplateDesc>
                <span>
                    {{ "Note adding" | translate }}
                </span>
            </ng-template>
        </ng-template>
    </div>
</div>
<mat-dialog-content class="mat-typography">
    <div class="container card_editor notes_editor" [ngClass]="{'sto_width': !!initData}">
        <form [formGroup]="form" class="form_with_pads">
            
            <ng-container *ngIf="!no_acssess || !data.is_edit; else elseTemplateEditTitle">
                <div class="ai_line_out" *ngIf="!initData">
                    <div class="ai_line ai_abs" *ngIf="!data.is_shared">
                        <mat-form-field appearance="legacy" class="mr-1">
                            <mat-label>{{ "AI model" | translate }}</mat-label>
                            <mat-select [disableOptionCentering]="true" [multiple]="false" selectDir [formControl]="ai_model">
                              <mat-option>
                                <ngx-mat-select-search 
                                    placeholderLabel="{{ 'Search' | translate }}" 
                                    noEntriesFoundLabel="{{ 'no matching model found' | translate }}"
                                    [formControl]="aiModelControl"></ngx-mat-select-search>
                              </mat-option>
                              <ng-container *ngFor="let model of models$ | async">
                                <mat-option [value]="model.id">
                                    {{model.name}}
                                </mat-option>
                              </ng-container>
                            </mat-select>
                        </mat-form-field>
    
                        <div class="action mr-1" *ngIf="aiActionName?.value" (click)="logAi()">
                            {{"Action" | translate}}: 
                            <div class="val">
                                {{aiActionName?.value?.name}}
                            </div>
                        </div>
    
                        <button class="mr-1" mat-icon-button [matMenuTriggerFor]="clipboardMenu" #menuTrigger="matMenuTrigger">
                            <mat-icon>assistant</mat-icon>
                        </button>
                        <mat-menu #clipboardMenu="matMenu" xPosition="after">
                            <button class="scheme_item" *ngFor="let item of bookmarks" (click)="selectAiAction(aiActionName, item)" mat-menu-item>
                                <span>{{ item.name }}</span>
                                <div class="d-f-c show_scheme_hover" *ngIf="is_scheme_editing">
                                    <mat-icon class="small_scheme_icon green" (click)="editScheme($event, menuTrigger, item)">edit</mat-icon>
                                    <mat-icon class="small_scheme_icon red" (click)="deleteScheme($event, menuTrigger, item)">delete</mat-icon>
                                </div>
                            </button>
                            <button class="scheme_item scheme_item_create" mat-menu-item (click)="createNewAiScheme($event)">
                                <mat-icon>add</mat-icon>
                                <span>{{ "Create new scheme" | translate }}</span>
                                <mat-icon style="margin-left: 50px !important; margin-right: 0 !important;" *ngIf="bookmarks && bookmarks.length" [ngStyle]="{'color': is_scheme_editing ? '#3f51b5' : '#686868'}" (click)="toggleSchemeEditing($event)">settings</mat-icon>
                            </button>
                        </mat-menu>
                        
                        <mat-form-field class="mr-1" class="language_block" appearance="legacy" *ngIf="aiActionName?.value && checkIfIsTranslate(aiActionName?.value)">
                            <mat-label>{{ "Language" | translate }}</mat-label>
                            <mat-select [formControl]="aiLangName">
                                <mat-option>
                                    <ngx-mat-select-search 
                                    placeholderLabel="{{ 'Search' | translate }}" 
                                    noEntriesFoundLabel="{{ 'no matching language found' | translate }}"
                                    [formControl]="langsControl"></ngx-mat-select-search>
                                </mat-option>
                                <ng-container *ngFor="let item of langs$ | async">
                                    <mat-option [value]="item.iso_value">
                                        <span>{{ item.value }}</span>
                                    </mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
    
                        <button mat-icon-button (click)="selectAiRequest('name', aiActionName, aiLangName)" *ngIf="aiActionName?.value">
                            <mat-icon style="transform: scaleX(-1);">reply</mat-icon>
                        </button>
                    </div>
                    <mat-form-field appearance="standard" class="full_width_form" *ngIf="!initData">
                        <mat-label>{{ (data.is_file ? 'Document Title' : 'Note Title') | translate }}</mat-label>
                        <input cdkFocusInitial formControlName="name" matInput placeholder="{{ (data.is_file ? 'Document Title' : 'Note Title') | translate }}">
                    </mat-form-field>
                </div>
            </ng-container>
            <ng-template #elseTemplateEditTitle>
                <mat-form-field appearance="standard" class="full_width_form" *ngIf="!initData">
                    <mat-label>{{ (data.is_file ? 'Document Title' : 'Note Title') | translate }}</mat-label>
                    <input [readonly]="true" cdkFocusInitial formControlName="name" matInput placeholder="{{ (data.is_file ? 'Document Title' : 'Note Title') | translate }}">
                </mat-form-field>
            </ng-template>

            <div class="ai_line_out">
                <div class="ai_line ai_abs" *ngIf="!data.is_shared">
                    <mat-form-field appearance="legacy" class="mr-1">
                        <mat-label>{{ "AI model" | translate }}</mat-label>
                        <mat-select [disableOptionCentering]="true" [multiple]="false" selectDir [formControl]="ai_model">
                          <mat-option>
                            <ngx-mat-select-search 
                                placeholderLabel="{{ 'Search' | translate }}" 
                                noEntriesFoundLabel="{{ 'no matching model found' | translate }}"
                                [formControl]="aiModelControl"></ngx-mat-select-search>
                          </mat-option>
                          <ng-container *ngFor="let model of models$ | async">
                            <mat-option [value]="model.id">
                                {{model.name}}
                            </mat-option>
                          </ng-container>
                        </mat-select>
                    </mat-form-field>

                    <div class="action mr-1" *ngIf="aiAction?.value" (click)="logAi()">
                        {{"Action" | translate}}: 
                        <div class="val">
                            {{aiAction?.value?.name}}
                        </div>
                    </div>

                    <button class="mr-1" mat-icon-button [matMenuTriggerFor]="clipboardMenu" #menuTrigger="matMenuTrigger">
                        <mat-icon>assistant</mat-icon>
                    </button>
                    <mat-menu #clipboardMenu="matMenu" xPosition="after">
                        <button class="scheme_item" *ngFor="let item of bookmarks" (click)="selectAiAction(aiAction,item)" mat-menu-item>
                            <span>{{ item.name }}</span>
                            <div class="d-f-c show_scheme_hover" *ngIf="is_scheme_editing">
                                <mat-icon class="small_scheme_icon green" (click)="editScheme($event, menuTrigger, item)">edit</mat-icon>
                                <mat-icon class="small_scheme_icon red" (click)="deleteScheme($event, menuTrigger, item)">delete</mat-icon>
                            </div>
                        </button>
                        <button class="scheme_item scheme_item_create" mat-menu-item (click)="createNewAiScheme($event)">
                            <mat-icon>add</mat-icon>
                            <span>{{ "Create new scheme" | translate }}</span>
                            <mat-icon style="margin-left: 50px !important; margin-right: 0 !important;" *ngIf="bookmarks && bookmarks.length" [ngStyle]="{'color': is_scheme_editing ? '#3f51b5' : '#686868'}" (click)="toggleSchemeEditing($event)">settings</mat-icon>
                        </button>
                    </mat-menu>
                    
                    <mat-form-field class="mr-1" class="language_block" appearance="legacy" *ngIf="aiAction?.value && checkIfIsTranslate(aiAction?.value)">
                        <mat-label>{{ "Language" | translate }}</mat-label>
                        <mat-select [formControl]="aiLang">
                            <mat-option>
                                <ngx-mat-select-search 
                                placeholderLabel="{{ 'Search' | translate }}" 
                                noEntriesFoundLabel="{{ 'no matching language found' | translate }}"
                                [formControl]="langsControl"></ngx-mat-select-search>
                            </mat-option>
                            <ng-container *ngFor="let item of langs$ | async">
                                <mat-option [value]="item.iso_value">
                                    <span>{{ item.value }}</span>
                                </mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>

                    <button mat-icon-button (click)="selectAiRequest('text', aiAction, aiLang)" *ngIf="aiAction?.value">
                        <mat-icon style="transform: scaleX(-1);">reply</mat-icon>
                    </button>
                </div>

                <div class="TextFormatter" [ngClass]="{'open': selectedText?.is_selected}" [ngStyle]="{'left': !!selectedText?.position?.x ? (selectedText?.position?.x + 'px') : '0px', 'top': !!selectedText?.position?.y ? (selectedText?.position?.y + 'px') : '0px'}">
                    <div class="TextFormatter-buttons">
                        <button type="button" [ngClass]="{'active': !!selectedText?.state?.bold}" (click)="formatText('bold')" class="Button default translucent" aria-label="Bold text" title="Bold text">
                            <mat-icon>format_bold</mat-icon>
                        </button>
                        <button type="button" [ngClass]="{'active': !!selectedText?.state?.italic}" (click)="formatText('italic')" class="Button default translucent" aria-label="Italic text" title="Italic text">
                            <mat-icon>format_italic</mat-icon>
                        </button>
                        <button type="button" [ngClass]="{'active': !!selectedText?.state?.underline}" (click)="formatText('underline')" class="Button default translucent" aria-label="Underlined text" title="Underlined text">
                            <mat-icon>format_underlined</mat-icon>
                        </button>
                        <button type="button" [ngClass]="{'active': !!selectedText?.state?.strikeThrough}" (click)="formatText('strikeThrough')" class="Button default translucent" aria-label="Strikethrough text" title="Strikethrough text">
                            <mat-icon>strikethrough_s</mat-icon>
                        </button>
                        <button type="button" [ngClass]="{'active': !!selectedText?.state?.insertUnorderedList}" (click)="formatText('insertUnorderedList')" class="Button default translucent" aria-label="Strikethrough text" title="Strikethrough text">
                            <mat-icon>format_list_bulleted</mat-icon>
                        </button>
                        <button type="button" [ngClass]="{'active': !!selectedText?.state?.insertOrderedList}" (click)="formatText('insertOrderedList')" class="Button default translucent" aria-label="Strikethrough text" title="Strikethrough text">
                            <mat-icon>format_list_numbered</mat-icon>
                        </button>
                        <div class="TextFormatter-divider"></div>
                        <button (click)="goTolink($event)" *ngIf="!!selectedText?.state?.createLink" type="button" class="active Button default translucent" aria-label="Go to Link" title="Go to Link">
                            <mat-icon>open_in_new</mat-icon>
                        </button>
                        <button (click)="formatText('createLink')" [ngClass]="{'active': !!selectedText?.state?.createLink}" type="button" class="Button default translucent" aria-label="Add Link" title="Add Link">
                            <mat-icon>link</mat-icon>
                        </button>
                        <button (click)="formatText('unlink')" *ngIf="!!selectedText?.state?.createLink" type="button" class="Button default translucent" aria-label="Delete Link" title="Delete Link">
                            <mat-icon>link_off</mat-icon>
                        </button>
                    </div>
                </div>
                <div class="note_wrp">
                    <div class="label" *ngIf="!form.get('text').value">
                        {{ (data.is_file ? 'Document' : 'Note') | translate }}
                    </div>
                    <ng-container *ngIf="!no_acssess || !data.is_edit; else elseTemplateHas">
                        <div class="content_editable" #editableContent [appDeviceClass]="'custom-scroll custom-scroll-t'" (copy)="onCopy($event)" (paste)="onPaste($event)" (mouseup)="mouseUp($event)" contenteditable="true" formControlName="text" matInput></div>
                    </ng-container>
                    <ng-template #elseTemplateHas>
                        <div class="content_editable" [appDeviceClass]="'custom-scroll custom-scroll-t'" (copy)="onCopy($event)" [innerHTML]="form.get('text').value | linky:{newWindow: true}" matInput></div>
                    </ng-template>
                </div>
            </div>
        </form>
        <div class="st_template_footer" *ngIf="!no_acssess || !data.is_edit">
            <div class="foot">
                <span>
                    {{"Add" | translate}}
                </span>
                <div class="foot_btn ml-1" (click)="$event.preventDefault(); $event.stopPropagation(); selectFile('image')">{{"Image" | translate}}</div>
                <div class="foot_btn ml-1" (click)="$event.preventDefault(); $event.stopPropagation(); selectFile('video')">{{"Video" | translate}}</div>
                <div class="foot_btn ml-1" (click)="$event.preventDefault(); $event.stopPropagation(); pasteFile($event)">{{"Paste" | translate}}</div>
            </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <div class="f_w_p_btns">
        <ng-container *ngIf="initData; else elseTemplateIsOut">
            <button *ngIf="!no_acssess || !data.is_edit" class="ml-1 d-f-btn" (click)="submitFile()" mat-raised-button cdkFocusInitial color="primary" [disabled]="!form.valid || isSubmit">
                <mat-spinner class="spin_16 mr-1" *ngIf="isSubmit"></mat-spinner>  
                {{ "Save" | translate }}
            </button>
        </ng-container>
        <ng-template #elseTemplateIsOut>
            <button mat-raised-button (click)="closeBtn()">
                {{ "Close" | translate }}
            </button>
            <button class="ml-1 d-f-btn" *ngIf="!no_acssess || !data.is_edit" (click)="data.is_file ? submitFile() : submitForm()" mat-raised-button cdkFocusInitial color="primary" [disabled]="!form.valid || isSubmit">
                <mat-spinner class="spin_16 mr-1" *ngIf="isSubmit"></mat-spinner>  
                {{ "Save" | translate }}
            </button>
        </ng-template>
    </div>
</mat-dialog-actions>
