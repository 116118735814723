import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import * as moment from 'moment';
import { ReplaySubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { BaseClass } from 'src/app/shared/models/base-class';
import { GlobalDataService } from 'src/app/shared/services/common/global-data.service';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { MembersService } from 'src/app/shared/services/rest/members.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-multi-delete-clients',
  templateUrl: './multi-delete-clients.component.html',
  styleUrls: ['./multi-delete-clients.component.scss']
})
export class MultiDeleteClientsComponent extends BaseClass implements OnInit, OnDestroy {
  public form: FormGroup;
  public deleteForm: FormGroup;
  public operationStatuses: any;
  public employees: any;
  public numberRegEx = /\d*\.?\d{1,2}/;
  public isSubmit: boolean = false;
  public showEm: boolean = false;
  public submited: boolean = false;
  public assignedEarlier: any;
  public currencyList: any;
  public partners: any;
  public imgRoute: any = '';
  public host: any = environment.host;
  public employeeMoreControl: FormControl = new FormControl();
  public deleteEmployeeMoreControl: FormControl = new FormControl();
  public employees$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public employeesAssignedEarlyer: any;
  public partnersAssignedEarlyer: any;

  public activeIndex = new FormControl(0);
  public partnerMoreControl: FormControl = new FormControl();
  public partners$: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<MultiDeleteClientsComponent>,
    private taskService: TaskService,
    private fb: FormBuilder,
    private companyService: CompanyService,
    private sm: StorageManagerService,
    private globalDataService: GlobalDataService,
    private layoutService: LayoutService,
    private membersService: MembersService
  ) { super() }

  ngOnInit(): void {
    this.dialogRef.addPanelClass("adding_executors_dialog")
    console.log("MultiDeleteClientsComponent", this.data);
    
    this.deleteForm = this.fb.group({
      with_card: true,
      employee_id: [[]],
      partner_company_id: [[]]
    })

    this.attachSubscriptions(
      this.deleteForm.get('with_card').valueChanges.subscribe(resp => {
        this.employeesAssignedEarlyer = this.getUniqueEmployeesAssignedEarlyer(resp);
        this.partnersAssignedEarlyer = this.getUniquePartnersAssignedEarlyer(resp);
        this.deleteForm.patchValue({
          employee_id: [[]],
          partner_company_id: [[]]
        })
      })
    )

    this.employeesAssignedEarlyer = this.getUniqueEmployeesAssignedEarlyer(this.deleteForm.get('with_card').value);
    this.partnersAssignedEarlyer = this.getUniquePartnersAssignedEarlyer(this.deleteForm.get('with_card').value);
    console.log("employeesAssignedEarlyer", this.employeesAssignedEarlyer)
    console.log("partnersAssignedEarlyer", this.partnersAssignedEarlyer)

    this.attachSubscriptions(
      this.employeeMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchEmoloyees(resp))
    )
    
    this.attachSubscriptions(
      this.deleteEmployeeMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchEmoloyees(resp))
    )

    this.attachSubscriptions(
      this.partnerMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchPartners(resp))
    )

    this.getImgRoute();
    this.getEmployees();
    this.getCurrencyList();
    this.getOperationsStatus();
    this.getPartners();
  }

  checkIsManager(task, company, _user) { 
    return task?.managers.filter(x => x.user_id == _user.id).length > 0 || task?.group?.managers.filter(x => x.user_id == _user.id).length > 0 || company?.permissions?.includes('admin') || company?.permissions.includes('manager'); 
  }

  changeTab(val) {
    this.activeIndex.patchValue(val);
  }

  getPartners() {
    this.attachSubscriptions(
      this.companyService.getPartners({company_id: this.data.company.id}).subscribe(resp => {
        console.log("getPartners", resp);
        this.partners = resp;
        this.partners$.next(this.partners.slice());
      })
    )
  }

  getUniqueEmployeesAssignedEarlyer(with_card?) {
    const uniqueEmployees = [];
    const seenIds = new Set();
  
    if (this.data.selectedChats) {
      this.data.selectedChats.forEach(obj => {
        obj.clients.filter(x => (with_card ? true : !!x.task_operation_id)).forEach(cli => {
          if (!!cli.client_employee_id && cli.employee && !seenIds.has(cli.client_employee_id)) {
            seenIds.add(cli.client_employee_id);
            uniqueEmployees.push(cli.employee);
          }
        });
      });
    } else if (this.data.selectedJobs) {
      this.data.selectedJobs.forEach(obj => {
        obj.clients.filter(x => (with_card ? true : !!x.task_operation_id)).forEach(cli => {
          if (!!cli.client_employee_id && cli.employee && !seenIds.has(cli.client_employee_id)) {
            seenIds.add(cli.client_employee_id);
            uniqueEmployees.push(cli.employee);
          }
        });
      });
    }
  
    return uniqueEmployees;
  }

  getUniquePartnersAssignedEarlyer (with_card?) {
    const uniquePartners = [];
    const seenIds = new Set();
  

    if (this.data.selectedChats) {
      this.data.selectedChats.forEach(obj => {
        obj.clients.filter(x => (with_card ? true : !!x.task_operation_id)).forEach(cli => {
          if (cli.client_partner_company_id && cli.partnerCompany && !seenIds.has(cli.client_partner_company_id)) {
            seenIds.add(cli.client_partner_company_id);
            uniquePartners.push(cli.partnerCompany);
          }
        });
      }); 
    } else if (this.data.selectedJobs) {
      this.data.selectedJobs.forEach(obj => {
        obj.clients.filter(x => (with_card ? true : !!x.task_operation_id)).forEach(cli => {
          if (cli.client_partner_company_id && cli.partnerCompany && !seenIds.has(cli.client_partner_company_id)) {
            seenIds.add(cli.client_partner_company_id);
            uniquePartners.push(cli.partnerCompany);
          }
        });
      }); 
    }
  
    return uniquePartners;
  }

  onSearchPartners(resp) {
    if (!this.partners) {
      return;
    }

    if (!resp) {
      this.partners$.next(this.partners.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.partners$.next(
      this.partners.filter(z => z.partnerCompany.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  onSearchEmoloyees(resp) {
    if (!this.employees) {
      return;
    }

    if (!resp) {
      this.employees$.next(this.employees.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.employees$.next(
      this.employees.filter(z => z.name.toLowerCase().indexOf(resp) > -1)
    );
  }


  toggleChip = (id: any, key, formName: string = 'form') => {
    if (!!this[formName].value[key] && this[formName].value[key].includes(id)) {
      let val = [...this[formName].value[key]]
      val.splice(this[formName].value[key].indexOf(id), 1)
      this[formName].patchValue({
        [key]: val
      })
    } else {
      if (!!this[formName].value[key]) {
        let val = [...this[formName].value[key]]
        val.push(id)
        this[formName].patchValue({
          [key]: [...val]
        })
      } else {
        this[formName].patchValue({
          [key]: [id]
        })
      }
    }
  }


  showMoreEm() {
    this.showEm = true;
  }

  getEmployees() {
    this.attachSubscriptions(
      this.taskService.getEmployees(this.data.company.id).subscribe(resp => {
        this.employees = resp;
        this.employees$.next(this.employees.slice())
      })
    )
  }
  
  getOperationsStatus() {
    this.attachSubscriptions(
      this.taskService.getOperationsStatus().subscribe(resp => {
        this.operationStatuses = resp.slice();
      })
    )
  }  

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  onPartnerImgError(event){
    event.target.src = this.imgRoute+'/assets/img/partner.png'
  }

  getCurrencyList() {
    this.attachSubscriptions(
      this.globalDataService.getCurrencies().subscribe(resp => {
        console.log("getCurrencyList", resp)
        this.currencyList = resp.slice();
      })
    )
    // this.attachSubscriptions(
    //   this.companyService.getCurrencyList().subscribe(resp => {
    //     this.currencyList = resp
    //   })
    // )
  }

  getEmployeeById(id) {
    return this.employees.find(x => x.id == id)
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  selectStatus(status) {
    this.form.patchValue({
      status_id: status.id
    })
  }

  onDelete() {
    console.log("this.assignedEarlier", this.assignedEarlier)
    console.log(this.deleteForm.value)
    if (!this.deleteForm.value.employee_id.length && !this.deleteForm.value.partner_company_id.length) {
      this.submited = true;
      this.layoutService.showSnackBar({name: ''}, marker("You need to choose executors or parters"), SnackBarItem)
      return
    }

    this.submited = false;
    this.isSubmit = true;


    let deleteData = [];
    let with_card = this.deleteForm.get('with_card').value;
    if (this.data.selectedChats) {
      this.data.selectedChats.forEach(chat => {
        if (!!this.deleteForm.value.employee_id && this.deleteForm.value.employee_id.length) {
          if (chat.company_id != this.data.company.id) {
            this.deleteForm.value.employee_id.forEach(id => {
              if (!!chat.clients && chat.clients.filter(b => b.partner_employee_id == id).filter(x => (with_card ? true : !!x.task_operation_id)).length != 0) {
                let client = chat.clients.find(x => x.partner_employee_id == id && (with_card ? true : !!x.task_operation_id))
                deleteData.push({
                  "path": `/api/task-client/${client.id}/`,
                  "query": {company_id: this.data.company.id},
                  "method": "DELETE"
                })
              }
            });
          } else {
            this.deleteForm.value.employee_id.forEach(id => {
              if (!!chat.clients && chat.clients.filter(b => b.employee_id == id).filter(x => (with_card ? true : !!x.task_operation_id)).length != 0) {
                let client = chat.clients.find(x => x.employee_id == id && (with_card ? true : !!x.task_operation_id))
                deleteData.push({
                  "path": `/api/task-client/${client.id}/`,
                  "query": {company_id: this.data.company.id},
                  "method": "DELETE" 
                })
              }
            });
          }
        }
  
  
  
        if (chat.company_id == this.data.company.id) {
          if (!!this.deleteForm.value.partner_company_id && this.deleteForm.value.partner_company_id.length) {
            this.deleteForm.value.partner_company_id.forEach(pc_id => {
              if (!!chat.clients && chat.clients.filter(b => b.partner_company_id == pc_id).filter(x => (with_card ? true : !!x.task_operation_id)).length != 0) {
                let client = chat.clients.find(x => x.partner_company_id == pc_id && (with_card ? true : !!x.task_operation_id))
                deleteData.push({
                  "path": `/api/task-client/${client.id}/`,
                  "query": {company_id: this.data.company.id},
                  "method": "DELETE"
                })
              }
            })
          }
        }
      })
    } else if (this.data.selectedJobs) {
      this.data.selectedJobs.forEach(job => {
        if (!!this.deleteForm.value.employee_id && this.deleteForm.value.employee_id.length) {
          this.deleteForm.value.employee_id.forEach(id => {
            if (!!job.clients && job.clients.filter(b => b.client_employee_id == id).filter(x => (with_card ? true : !!x.task_operation_id)).length != 0) {
              let client = job.clients.find(x => x.client_employee_id == id && (with_card ? true : !!x.task_operation_id))
              deleteData.push({
                "path": `/api/task-client/${client.id}/`,
                "query": {company_id: this.data.company.id},
                "method": "DELETE" 
              })
            }
          });
        }
  
  
  
        if (job.company_id == this.data.company.id) {
          if (!!this.deleteForm.value.partner_company_id && this.deleteForm.value.partner_company_id.length) {
            this.deleteForm.value.partner_company_id.forEach(pc_id => {
              if (!!job.clients && job.clients.filter(b => b.client_partner_company_id == pc_id).filter(x => (with_card ? true : !!x.task_operation_id)).length != 0) {
                let client = job.clients.find(x => x.client_partner_company_id == pc_id && (with_card ? true : !!x.task_operation_id))
                deleteData.push({
                  "path": `/api/task-client/${client.id}/`,
                  "query": {company_id: this.data.company.id},
                  "method": "DELETE"
                })
              }
            })
          }
        }
      })
    }


    if (deleteData.length) {
      this.attachSubscriptions(
        this.taskService.multiRequest(deleteData).subscribe(resp => {
          console.log("multiRequest", resp)
          this.isSubmit = false;
          this.dialogRef.close({event: "change", data: resp})
        })
      )
    } else {
      this.close();
    }
    
  }

  onAssign() {
    if (!this.form.valid) {
      this.submited = true;
      this.layoutService.showSnackBar({name: ''}, marker("You need to fill in all required fields"), SnackBarItem)
      return
    }
    if (!this.form.value.employee_id.length && !this.form.value.partner_company_id.length) {
      this.submited = true;
      this.layoutService.showSnackBar({name: ''}, marker("You need to choose executors or parters"), SnackBarItem)
      return
    }

    this.submited = false;
    this.isSubmit = true;


    let postData = [];
    
    if (this.data.selectedChats) {
      this.data.selectedChats.forEach(chat => {
        if (!!this.form.value.employee_id && this.form.value.employee_id.length) {
          let postInData = {...this.form.value};
          delete postInData.employee_id
          delete postInData.partner_company_id;
  
          if (chat.company_id != this.data.company.id) {
            this.form.value.employee_id.forEach(id => {
              if (!chat.employees || chat.employees.filter(b => b.partner_employee_id == id).length == 0) {
                postData.push({
                  "path": `/api/task-partner-employee/`,
                  "query": {company_id: this.data.company.id},
                  "method": "POST",
                  "body": Object.assign(postInData, {
                    [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
                    partner_employee_id: id,
                    task_id: chat.task_id,
                    task_operation_id: chat.task_operation_id,
                    discussion_id: chat.id,
                    partner_company_id: postInData.company_id,
                    company_id: chat.company_id
                  }) 
                })
              }
            });
          } else {
            this.form.value.employee_id.forEach(id => {
              if (!chat.employees || chat.employees.filter(b => b.employee_id == id).length == 0) {
                postData.push({
                  "path": `/api/task-employee/`,
                  "query": {company_id: this.data.company.id},
                  "method": "POST",
                  "body": Object.assign({
                    [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
                    employee_id: id,
                    task_id: chat.task_id,
                    task_operation_id: chat.task_operation_id,
                    discussion_id: chat.id,
                }, postInData) 
                })
              }
            });
          }
        }
  
  
  
        if (chat.company_id == this.data.company.id) {
          if (!!this.form.value.partner_company_id && this.form.value.partner_company_id.length) {
            let partnerData = {...this.form.value};
            delete partnerData.company_id
            delete partnerData.employee_id
            delete partnerData.partner_company_id;
            delete partnerData.status_id;
            this.form.value.partner_company_id.forEach(pc_id => {
              if (!!chat.partnerCompanies || chat.partnerCompanies.filter(b => b.partner_company_id == pc_id).length == 0) {
                postData.push({
                  "path": `/api/task-partner/`,
                  "query": {company_id: this.data.company.id},
                  "method": "POST",
                  "body": Object.assign({
                    [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
                    partner_company_id: pc_id,
                    task_id: chat.task_id,
                    task_operation_id: chat.task_operation_id,
                    discussion_id: chat.id,
                    company_id: chat.company_id
                }, partnerData) 
                })
              }
            });
          }
        }
      })
    } else if (this.data.selectedJobs) {
      this.data.selectedJobs.forEach(job => {
        if (!!this.form.value.employee_id && this.form.value.employee_id.length) {
          let postInData = {...this.form.value};
          delete postInData.employee_id
          delete postInData.partner_company_id;
  
          if (job.company_id != this.data.company.id) {
            this.form.value.employee_id.forEach(id => {
              if (!job.employees || job.employees.filter(b => b.partner_employee_id == id).length == 0) {
                postData.push({
                  "path": `/api/task-partner-employee/`,
                  "query": {company_id: this.data.company.id},
                  "method": "POST",
                  "body": Object.assign(postInData, {
                    [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
                    partner_employee_id: id,
                    task_id: job.task_id,
                    task_operation_id: job.id,
                    partner_company_id: postInData.company_id,
                    company_id: job.company_id
                  }) 
                })
              }
            });
          } else {
            this.form.value.employee_id.forEach(id => {
              if (!job.employees || job.employees.filter(b => b.employee_id == id).length == 0) {
                postData.push({
                  "path": `/api/task-employee/`,
                  "query": {company_id: this.data.company.id},
                  "method": "POST",
                  "body": Object.assign({
                    [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
                    employee_id: id,
                    task_id: job.task_id,
                    task_operation_id: job.id,
                }, postInData) 
                })
              }
            });
          }
        }
  
  
  
        if (job.company_id == this.data.company.id) {
          if (!!this.form.value.partner_company_id && this.form.value.partner_company_id.length) {
            let partnerData = {...this.form.value};
            delete partnerData.company_id
            delete partnerData.employee_id
            delete partnerData.partner_company_id;
            delete partnerData.status_id;
            this.form.value.partner_company_id.forEach(pc_id => {
              if (!!job.partnerCompanies || job.partnerCompanies.filter(b => b.partner_company_id == pc_id).length == 0) {
                postData.push({
                  "path": `/api/task-partner/`,
                  "query": {company_id: this.data.company.id},
                  "method": "POST",
                  "body": Object.assign({
                    [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
                    partner_company_id: pc_id,
                    task_id: job.task_id,
                    task_operation_id: job.id,
                    company_id: job.company_id
                }, partnerData) 
                })
              }
            });
          }
        }
      })
    }


    if (postData.length) {
      this.attachSubscriptions(
        this.taskService.multiRequest(postData).subscribe(resp => {
          console.log("multiRequest", resp)
          this.isSubmit = false;
          this.dialogRef.close({event: "change", data: resp})
        })
      )
    } else {
      this.close()
    }
  }

  ngOnDestroy(): void {
    this.clearSubscriptions();    
  }

}
