import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { DeleteAlertComponent } from 'src/app/shared/global_components/delete-alert/delete-alert.component';
import { BaseClass } from 'src/app/shared/models/base-class';
import { ScenariosService } from 'src/app/shared/services/rest/scenarios.service';
import { AddAndEditTaskTemplateExecutorComponent } from '../add-and-edit-task-template-executor/add-and-edit-task-template-executor.component';

@Component({
  selector: 'app-scen-ecexecutors',
  templateUrl: './scen-ecexecutors.component.html',
  styleUrls: ['./scen-ecexecutors.component.scss']
})

export class ScenEcexecutorsComponent extends BaseClass implements OnInit, OnDestroy {
  public executors;
  public is_change: boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ScenEcexecutorsComponent>,
    private dialog: MatDialog,
    private bottomSheet: MatBottomSheet,
    private scenariosService: ScenariosService,
  ) { super() }

  ngOnInit(): void {
    console.log("ScenEcexecutorsComponent", this.data);
    this.getScenExecutors();
  }

  getScenExecutors() {
    let filter:any = {automation_template_id: this.data.tmpl.id};
    // if (this.data.discussion) {
    //   filter.discussion_id = this.data.discussion.id
    // }
    
    this.attachSubscriptions(
      this.scenariosService.getScenExecutors(filter, this.data.company.id).subscribe(resp => {
        console.log("getScenExecutors", resp);
        this.executors = resp;
        if (resp.length == 0) {
          this.addExecutor()
        }
      })
    )
  }

  checkIsManager(task, company, _user) { 
    return company?.permissions?.includes('owner') || company?.permissions?.includes('admin') || company?.permissions.includes('manager'); 
  }
  
  addExecutor() {
    const dialogRef = this.dialog.open(AddAndEditTaskTemplateExecutorComponent, {
      disableClose: true,
      data: {
        user: this.data.user,
        company: this.data.company,
        tmpl: this.data.tmpl,
        scen: true
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result && result.event == "add") {
          this.is_change = true
          this.getScenExecutors();
        }
      })
    )
  }

  editExecutor(executor) {
    const dialogRef = this.dialog.open(AddAndEditTaskTemplateExecutorComponent, {
      disableClose: true,
      data: {
        user: this.data.user,
        company: this.data.company,
        executor: executor,
        tmpl: this.data.tmpl,
        scen: true
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result && result.event == "update") {
          this.getScenExecutors();
        }
      })
    )
  }

  deleteExecutor(id, i) {
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: {},
        target: marker("assigned executor")
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        this.attachSubscriptions(
          this.scenariosService.deleteExecScenario(id).subscribe(resp => {
            this.is_change = true;
            this.executors.splice(i,1);
          })
        )
      }
    });
  }

  close() {
    this.dialogRef.close({event: this.is_change ? "change" : "close", data: false})
  }

  ngOnDestroy(): void {
    this.clearSubscriptions();
  }
}
