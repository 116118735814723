import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { forkJoin, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { DeleteAlertComponent } from 'src/app/shared/global_components/delete-alert/delete-alert.component';
import { BaseClass } from 'src/app/shared/models/base-class';
import { MembersService } from 'src/app/shared/services/rest/members.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { CompanyService } from '../../services/rest/company.service';
import { AddEditNoteComponent } from '../add-edit-note/add-edit-note.component';
import { NoteRelationsComponent } from './dialogs/note-relations/note-relations.component';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss']
})
export class NotesComponent extends BaseClass implements OnInit, OnDestroy {
  public notes: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private companyService: CompanyService,
    public dialogRef: MatDialogRef<NotesComponent>,
    private dialog: MatDialog,
    private bottomSheet: MatBottomSheet,
    private membersService: MembersService,
  ) { super() }

  ngOnInit(): void {
    console.log("NotesComponent", this.data)
    this.dialogRef.addPanelClass("full_width_dialog")
    if (this.data.is_shared && this.data.documents) {
      this.notes = this.data.documents
    } else {
      this.getNotes();
    }
  }

  toggleNote(e, note, key) {
    let target:any = {};
    if (this.data.type == 'task') {
      target.task_id = this.data.item.id
      target.task_operation_id = 0
      target.discussion_id = 0
      target.file_id = 0
    } else if (this.data.type == "job") {
      target.task_id = this.data.item.task_id;
      target.task_operation_id = this.data.item.id;
      target.discussion_id = 0
      target.file_id = 0
    } else if (this.data.type == "chat") {
      target.task_id = this.data.item.task_id;
      target.task_operation_id = this.data.item.task_operation_id;
      target.discussion_id = this.data.item.id;
      target.file_id = 0
    } else if (this.data.type == "file") {
      target.task_id = this.data.item.task_id;
      target.task_operation_id = this.data.item.task_operation_id;
      target.discussion_id = this.data.item.discussion_id || 0;
      target.file_id = this.data.item.id;
    }

    if (this.data.item && this.data.item.company_id && this.data.item.company_id != this.data.company_id) {
      target.partner_company_id = this.data.item.company_id
    }
    this.companyService.editNote(note.id, {[key]: Number(e.checked)}, this.data.company_id, target).subscribe(resp => {
      note[key] = resp[key];
    })
  }
  
  editNote(note) {
    let editData = Object.assign({...this.data}, {is_edit: true, note: note})
    const dialogRef = this.dialog.open(AddEditNoteComponent, {
      backdropClass: 'backdrop_under_header',
      panelClass: !this.data.is_mobile ? ['open_task_dialog', 'show_header'] : 'open_task_dialog',
      disableClose: true,
      data: editData
    });
    
    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        this.getNotes();
      })
    )
  }


  addPostNote() {
    const dialogRef = this.dialog.open(AddEditNoteComponent, {
      backdropClass: 'backdrop_under_header',
      panelClass: !this.data.is_mobile ? ['open_task_dialog', 'show_header'] : 'open_task_dialog',
      disableClose: true,
      data: {
        ...this.data,
        is_post: true
      }
    });
    
    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (result.event == 'add') {
          this.getNotes();
          if (this.data.item.hasOwnProperty('documents_count')) {
            this.data.item.documents_count = +this.data.item.documents_count + 1
          }
        }
      })
    )
  }

  addNote() {
    const dialogRef = this.dialog.open(AddEditNoteComponent, {
      backdropClass: 'backdrop_under_header',
      panelClass: !this.data.is_mobile ? ['open_task_dialog', 'show_header'] : 'open_task_dialog',
      disableClose: true,
      data: this.data
    });
    
    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (result.event == 'add') {
          this.getNotes();
          if (this.data.item.hasOwnProperty('documents_count')) {
            this.data.item.documents_count = +this.data.item.documents_count + 1
          }
        }
      })
    )
  }

  noteRelations(note) {
    let target:any = {};
    if (this.data.type == 'task') {
      target.task_id = this.data.item.id
      target.task_operation_id = 0
      target.discussion_id = 0
      target.file_id = 0
    } else if (this.data.type == "job") {
      target.task_id = this.data.item.task_id;
      target.task_operation_id = this.data.item.id;
      target.discussion_id = 0
      target.file_id = 0
    } else if (this.data.type == "chat") {
      target.task_id = this.data.item.task_id;
      target.task_operation_id = this.data.item.task_operation_id;
      target.discussion_id = this.data.item.id;
      target.file_id = 0
    } else if (this.data.type == "file") {
      target.task_id = this.data.item.task_id;
      target.task_operation_id = this.data.item.task_operation_id;
      target.discussion_id = this.data.item.discussion_id || 0;
      target.file_id = this.data.item.id;
    }
    let relData = Object.assign({...this.data}, {note: note, target: target})

    const dialogRef = this.dialog.open(NoteRelationsComponent, {
      backdropClass: 'backdrop_under_header',
      panelClass: !this.data.is_mobile ? ['open_task_dialog', 'show_header'] : 'open_task_dialog',
      disableClose: true,
      data: relData
    });
    
    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
      })
    )
  }

  getNotes() {
    let target:any = {};
    if (this.data.type == 'task') {
      target.task_id = this.data.item.id
      target.task_operation_id = 0
      target.discussion_id = 0
      target.file_id = 0
    } else if (this.data.type == "job") {
      target.task_id = this.data.item.task_id;
      target.task_operation_id = this.data.item.id;
      target.discussion_id = 0
      target.file_id = 0
    } else if (this.data.type == "chat") {
      target.task_id = this.data.item.task_id;
      target.task_operation_id = this.data.item.task_operation_id;
      target.discussion_id = this.data.item.id;
      target.file_id = 0
    } else if (this.data.type == "file") {
      target.task_id = this.data.item.task_id;
      target.task_operation_id = this.data.item.task_operation_id;
      target.discussion_id = this.data.item.discussion_id || 0;
      target.file_id = this.data.item.id;
    }

    if (this.data.item && this.data.item.company_id && this.data.item.company_id != this.data.company_id) {
      target.partner_company_id = this.data.item.company_id
    }
    
    this.attachSubscriptions(
      this.companyService.getNotes(this.data.company_id, target).subscribe(resp => {
        console.log("notes", resp);
        this.notes = resp;

        if (this.notes.length == 0 && this.data.type != 'file') {
          this.addNote()
        }
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false});
  }

  deleteNote(note, i) {
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: {},
        target: marker("note")
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        this.attachSubscriptions(
          this.companyService.deleteNote(note.id, this.data.company_id).pipe(
            tap(x => {
              console.log(x)
              this.notes.splice(i,1);
              if (this.data.item.hasOwnProperty('documents_count')) {
                this.data.item.documents_count = +this.data.item.documents_count - 1
              }
            }),
          ).subscribe(resp => {
            console.log("END DELETE", resp)
          })
        )
      }
    });
  
  }

  ngOnDestroy() {
    this.clearSubscriptions()
  }

}
