import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseClass } from 'src/app/shared/models/base-class';

@Component({
  selector: 'app-same-values',
  templateUrl: './same-values.component.html',
  styleUrls: ['./same-values.component.scss']
})
export class SameValuesComponent extends BaseClass implements OnInit, OnDestroy {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<SameValuesComponent>,
  ) { super() }

  ngOnInit(): void {
    console.log("SameValuesComponent", this.data)
  }

  save() {
    this.dialogRef.close({event: "save", data: false})
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
