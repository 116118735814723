<mat-dialog-title class="video_editor_title">
    <span (click)="logCuts()">
        {{ "Video editor" | translate }} V{{version}}
    </span>

    <div class="d-f-c">
        <button mat-icon-button (click)="close()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
</mat-dialog-title>
<mat-dialog-content class="video_editor_content">
    <div class="container" #videoContainer>
        <div class="panels" [ngClass]="{'forms_activated': is_create_cards.value}">
            <div class="panel_options">
                <div class="panel_line panel_opt_head" [ngClass]="{'has_sub': is_create_cards.value}">
                    <div class="head_line">
                        <div>
                            <mat-checkbox *ngIf="cuts && cuts.length" class="select_all_checkbox" (click)="$event.preventDefault; $event.stopPropagation()" (change)="$event ? masterToggle() : null"
                                [checked]="hasValue() && isAllSelected()"
                                [indeterminate]="hasValue() && !isAllSelected()"
                                [aria-label]="checkboxLabel()"
                                [disableRipple]="true">
                                <span class="ml-1">{{checkboxLabel()}}</span>
                            </mat-checkbox>
                            <mat-checkbox *ngIf="cuts.length || (!cuts.length && (!!audioFile || (!data?.file?.is_audio_only && !!data?.file?.is_video_only) || (!!data?.file?.is_audio_only && !data?.file?.is_video_only)))" class="ml-1 select_all_checkbox" [formControl]="is_create_cards" [disableRipple]="true">
                                <span class="ml-1">{{ "Create cards" | translate }}</span>
                            </mat-checkbox>
                        </div>
                        <button class="panel_line_btn" style="cursor: normal;">
                            <span class="fl">{{mode}}</span>&nbsp;{{"mode"| translate}}
                        </button>
                    </div>
                    <div class="head_sub_wrp" *ngIf="is_create_cards.value">
                        
            
                            <form [formGroup]="form">
                                <div class="form_panel" [ngClass]="{'need-transform': form.get('type').value == 1}">
                                    <mat-button-toggle-group class="form_btn_grp" formControlName="type">
                                        <mat-button-toggle [value]="0">{{"Custom template" | translate}}</mat-button-toggle>
                                        <mat-button-toggle [value]="1">{{"Preset template" | translate}}</mat-button-toggle>
                                    </mat-button-toggle-group>
                                    <div class="tmpl_val" *ngIf="form.get('type').value == 1">
                                        <div (click)="selectTemplate.open()">
                                            {{form.get('template_id').value ? getItemById(taskTemplates, form.get('template_id').value)?.name : ("Select Template" | translate)}}
                                            <mat-icon>arrow_drop_down</mat-icon>
                                        </div>
                                        <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                                            <mat-select disableOptionCentering #selectTemplate formControlName="template_id" (selectionChange)="selectTmpl($event)">
                                                <mat-option *ngFor="let tmpl of taskTemplates" [value]="tmpl.id">
                                                    {{ tmpl?.name }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </form>
                            <div class="form_wrp form_scroll" [appDeviceClass]="'custom-scroll'">
                                <ng-container *ngIf="form.get('type').value == 0; else elseTemplateCustom">
                                    <form [formGroup]="form">

                                        <div class="form_with_valids full_width" *ngIf="!cuts.length">
                                            <mat-form-field appearance="standard" class="full_width_form">
                                                <ng-container *ngIf="form.get('name').value == '' && submited">
                                                    <div class="req_field"></div>
                                                    <div class="req_label">
                                                        <div class="angle"></div>
                                                        <span>
                                                            {{ "You need to write here" | translate }}
                                                        </span>
                                                    </div>
                                                </ng-container>
                                                <mat-label>{{ 'Card title' | translate }}</mat-label>
                                                <input formControlName="name" matInput placeholder="{{ 'Name' | translate }}">
                                            </mat-form-field>
                                        </div>
    
                                        <div class="small_chips" *ngIf="taskStatuses?.length > 0">
                                            <label>{{ "Card type" | translate }}: </label>
                                            <mat-chip-list [multiple]="false" selectable formControlName="status_id">
                                                <ng-container *ngFor="let status of taskStatuses; let i = index">
                                                    <mat-chip class="small_chip" (click)="selectType(status)" [value]="status?.id">
                                                        {{ status.name | translate }}
                                                    </mat-chip>
                                                    <mat-chip *ngIf="i == taskStatuses?.length - 1 && i > 6" class="small_chip more" (click)="typesContainer.open()">
                                                        {{ "More..." | translate }}
                                                    </mat-chip>
                                                </ng-container>
                                            </mat-chip-list>
                                        </div>
                    
                                        <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                                            <mat-select #typesContainer formControlName="status_id" (selectionChange)="selectType($event)">
                                                <mat-option>
                                                    <ngx-mat-select-search 
                                                    placeholderLabel="{{ 'Search' | translate }}" 
                                                    noEntriesFoundLabel="{{ 'no matching type found' | translate }}"
                                                    [formControl]="taskStatusesMoreControl"></ngx-mat-select-search>
                                                </mat-option>
                                                <mat-option *ngFor="let status of taskStatuses$ | async" [value]="status.id">
                                                    {{ status.name | translate }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
    
                                        <div class="small_chips" *ngIf="groups?.length > 0">
                                            <label>{{ "Card Group" | translate }}: </label>
                                            <mat-chip-list [multiple]="false" selectable formControlName="group_id">
                                                <ng-container *ngFor="let group of groups; let i = index">
                                                    <mat-chip class="small_chip" (click)="selectGroup(group)" [value]="group?.id">
                                                        {{ group.name | translate }}
                                                    </mat-chip>
                                                    <mat-chip *ngIf="i == groups?.length - 1 && i > 6" class="small_chip more" (click)="groupIdContainer.open()">
                                                        {{ "More..." | translate }}
                                                    </mat-chip>
                                                </ng-container>
                                                
                                            </mat-chip-list>
                                        </div>
                            
                                        <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                                            <mat-select #groupIdContainer formControlName="group_id" (selectionChange)="selectGroup($event)">
                                                <mat-option>
                                                    <ngx-mat-select-search 
                                                    placeholderLabel="{{ 'Search' | translate }}" 
                                                    noEntriesFoundLabel="{{ 'no matching group found' | translate }}"
                                                    [formControl]="groupsMoreControl"></ngx-mat-select-search>
                                                </mat-option>
                                                <mat-option *ngFor="let group of groups$ | async" [value]="group.id">
                                                    {{ group.name | translate }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
    
                                        <div class="file_tags_out">
                                            <label>{{ "Card Tags" | translate }}: </label>
                                            <div class="file_tags">
                                                <ng-container *ngIf="parameters.length && parameters[0].activeValues && parameters[0].activeValues.length">
                                                    <div class="file_tag" *ngFor="let tag of parameters[0].activeValues; let t = index;">
                                                        {{tag.value}}
                                                        <div class="remove_out">
                                                            <div class="remove" (click)="onRemove(tag, t)">
                                                                <svg style="width: 5px; height: 5px;" width="5" height="5" viewBox="0 0 5 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M5 0.503571L4.49643 0L2.5 1.99643L0.503571 0L0 0.503571L1.99643 2.5L0 4.49643L0.503571 5L2.5 3.00357L4.49643 5L5 4.49643L3.00357 2.5L5 0.503571Z" fill="white"/>
                                                                </svg>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                                <div class="add_tag cp" (click)="openTargetValues()">
                                                    <svg style="width: 16px; height: 16px;" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M12 8.8H8.8V12H7.2V8.8H4V7.2H7.2V4H8.8V7.2H12M8 0C6.94943 0 5.90914 0.206926 4.93853 0.608964C3.96793 1.011 3.08601 1.60028 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.08601 14.3997 3.96793 14.989 4.93853 15.391C5.90914 15.7931 6.94943 16 8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 6.94943 15.7931 5.90914 15.391 4.93853C14.989 3.96793 14.3997 3.08601 13.6569 2.34315C12.914 1.60028 12.0321 1.011 11.0615 0.608964C10.0909 0.206926 9.05058 0 8 0Z" fill="#C4C4C4"/>
                                                    </svg>
                                                    {{ "Tag" | translate }}
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    <div class="jobs_wrp form_with_pads">
                                        <div class="job" *ngFor="let job of jobs; let i = index;">
                            
                                            <mat-form-field appearance="standard" class="full_width_form">
                                                <mat-label>{{ 'Title' | translate }}</mat-label>
                                                <input [(ngModel)]="job.name" (change)="updateJobs()" matInput placeholder="{{ 'Title' | translate }}">
                                            </mat-form-field>
                                
                                            <mat-form-field appearance="standard" class="full_width_form">
                                                <mat-label>{{ 'Description' | translate }}</mat-label>
                                                <textarea [(ngModel)]="job.comment" (change)="updateJobs()" matInput placeholder="{{ 'Drief description' | translate }}"
                                                cdkTextareaAutosize
                                                #autosize="cdkTextareaAutosize"
                                                cdkAutosizeMinRows="1"
                                                cdkAutosizeMaxRows="5"></textarea>
                                            </mat-form-field>
                            
                                            <ng-container *ngIf="operations?.length > 0">
                                                <div class="small_chips" >
                                                    <label>{{ "Job type" | translate }}: </label>
                                                    <mat-chip-list [multiple]="false" selectable [(ngModel)]="job.operation_id" (selectionChange)="updateJobs()">
                                                        <ng-container *ngFor="let operation of operations; let i = index">
                                                            <mat-chip class="small_chip" (click)="job.operation_id = operation?.id" [value]="operation?.id" [selected]="job.operation_id == operation?.id">
                                                                {{ operation.name | translate }}
                                                            </mat-chip>
                                                            <mat-chip *ngIf="i == operations?.length - 1 && i > 6" class="small_chip more" (click)="operationIdContainer.open()">
                                                                {{ "More..." | translate }}
                                                            </mat-chip>
                                                        </ng-container>
                                                        
                                                    </mat-chip-list>
                                                </div>
                                    
                                                <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                                                    <mat-select #operationIdContainer [(ngModel)]="job.operation_id" (selectionChange)="updateJobs()">
                                                        <mat-option>
                                                            <ngx-mat-select-search 
                                                            placeholderLabel="{{ 'Search' | translate }}" 
                                                            noEntriesFoundLabel="{{ 'no matching job type found' | translate }}"
                                                            [formControl]="operationsMoreControl"></ngx-mat-select-search>
                                                        </mat-option>
                                                        <mat-option *ngFor="let operation of operations$ | async" [value]="operation.id">
                                                            {{ operation.name | translate }}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </ng-container>
                            
                                            <div class="small_chips" *ngIf="statuses?.length > 0">
                                                <label>{{ "Status" | translate }}: </label>
                                                <mat-chip-list [multiple]="false" selectable [(ngModel)]="job.status_id">
                                                    <ng-container *ngFor="let status of statuses">
                                                        <mat-chip class="small_chip" (click)="job.status_id = status.id; updateJobs();" [selected]="job.status_id == status.id" [value]="status?.id" [ngClass]="{
                                                            'first': status.id == '1',
                                                            'second': status.id == '2',
                                                            'third': status.id == '3',
                                                            'fours': status.id == '4',
                                                            'fives': status.id == '5',
                                                            'sixs': status.id == '6',
                                                            'nine': status.id == '97',
                                                            'seven': status.id == '98',
                                                            'eight': status.id == '99'}">
                                                            {{ status.name | translate }}
                                                        </mat-chip>
                                                    </ng-container>
                                                </mat-chip-list>
                                            </div>      
                                            
                                            <div class="priority_chips">
                                                <label>{{ "Priority" | translate }}: </label>
                                                <mat-chip-list [multiple]="false" selectable [(ngModel)]="job.priority">
                                                    <mat-chip *ngFor="let priority of prioritys" [selected]="job.priority == priority.id" [ngClass]="{
                                                        'Urgent': priority.id == '1000',
                                                        'High': priority.id == '900',
                                                        'Middle': priority.id == '800',
                                                        'Low': priority.id == '700',
                                                        'No': priority.id == '0'
                                                    }" class="priority_chip" (click)="job.priority = priority.id; updateJobs();" [value]="priority.id">
                                                        {{ priority.name | translate }}
                                                    </mat-chip>
                                                </mat-chip-list>
                                            </div>
                            
                            

                                            <div class="file_tags_out">
                                                <label>{{ "Job Tags" | translate }}: </label>
                                                <div class="file_tags">
                                                    <ng-container *ngIf="job.parameters.length && job.parameters[0].activeValues && job.parameters[0].activeValues.length">
                                                        <div class="file_tag" *ngFor="let tag of job.parameters[0].activeValues; let t = index;">
                                                            {{tag.value}}
                                                            <div class="remove_out">
                                                                <div class="remove" (click)="onRemove(tag, t, false, job)">
                                                                    <svg style="width: 5px; height: 5px;" width="5" height="5" viewBox="0 0 5 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M5 0.503571L4.49643 0L2.5 1.99643L0.503571 0L0 0.503571L1.99643 2.5L0 4.49643L0.503571 5L2.5 3.00357L4.49643 5L5 4.49643L3.00357 2.5L5 0.503571Z" fill="white"/>
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                    <div class="add_tag cp" (click)="openTargetValues(false, job)">
                                                        <svg style="width: 16px; height: 16px;" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M12 8.8H8.8V12H7.2V8.8H4V7.2H7.2V4H8.8V7.2H12M8 0C6.94943 0 5.90914 0.206926 4.93853 0.608964C3.96793 1.011 3.08601 1.60028 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.08601 14.3997 3.96793 14.989 4.93853 15.391C5.90914 15.7931 6.94943 16 8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 6.94943 15.7931 5.90914 15.391 4.93853C14.989 3.96793 14.3997 3.08601 13.6569 2.34315C12.914 1.60028 12.0321 1.011 11.0615 0.608964C10.0909 0.206926 9.05058 0 8 0Z" fill="#C4C4C4"/>
                                                        </svg>
                                                        {{ "Tag" | translate }}
                                                    </div>
                                                </div>
                                            </div>
                            
                            
                                            <div class="job_executors" *ngIf="job.create_task_employees.length && employees && employees.length > 0">
                                                <div class="executor" *ngFor="let executor of job.create_task_employees; let k = index;">
                                                    
                                                    <app-user [class]="'user_div'" [employee]="getEmployeeById(executor.employee_id)"></app-user>
                                                    
                                                    <p>
                                                        {{ "Choose member role" | translate }}:
                                                        <mat-button-toggle-group (change)="updateJobs()" appearance="legacy" class="ml-1" [(ngModel)]="executor.is_manager" [value]="executor.is_manager">
                                                            <mat-button-toggle [value]="1">{{ "Executor" | translate }}</mat-button-toggle>
                                                            <mat-button-toggle [value]="0">{{ "Assistant" | translate }}</mat-button-toggle>
                                                        </mat-button-toggle-group>
                                                    </p> 
                            
                                                    <button mat-icon-button color="warn" class="delete_executor" (click)="deleteExecutor(job, k)">
                                                        <mat-icon>person_remove</mat-icon>
                                                    </button>
                            
                                                </div>
                                            </div>
                    
                                            <button mat-icon-button color="warn" class="delete_job" (click)="deleteJob(i)">
                                                <mat-icon>do_disturb_on</mat-icon>
                                            </button>
                            
                                            <div class="add_new_executor" *ngIf="job.newExecutor" style="position: relative;">
                                                <div class="users_select">
                                                    <label>{{ "Add new executor" | translate }}: </label>
                                                    <mat-chip-list [multiple]="false" selectable [(ngModel)]="job.newExecutor.employee_id">
                                                        <ng-container *ngFor="let employee of employees; let i = index">
                                                            <mat-chip class="chip_user" (click)="!checkIfDisabled(job, employee.id) ? job.newExecutor.employee_id = employee.id : null" [selected]="job.newExecutor.employee_id == employee.id" [value]="employee.id" [disabled]="checkIfDisabled(job, employee.id)">
                                                                <app-user [class]="'user_div'" [employee]="employee"></app-user>
                                                            </mat-chip>
                                                            <mat-chip *ngIf="i == employees.length - 1 && i > 6" class="chip_user more" (click)="employeeIdContainer.open()">
                                                                {{ "more" | translate }}
                                                            </mat-chip>
                                                        </ng-container>
                                                    </mat-chip-list>
                                                </div>
                                    
                                                <mat-form-field appearance="standard" class="full_width_form hide-form-field" style="position: absolute;">
                                                    <mat-select #employeeIdContainer [(ngModel)]="job.newExecutor.employee_id">
                                                        <mat-option>
                                                            <ngx-mat-select-search 
                                                            placeholderLabel="{{ 'Search' | translate }}" 
                                                            noEntriesFoundLabel="{{ 'no matching employee found' | translate }}"
                                                            [formControl]="employeesMoreControl"></ngx-mat-select-search>
                                                        </mat-option>
                                                        <mat-option *ngFor="let employee of employees$ | async" [value]="employee.id" [ngClass]="{'opacity-6': checkIfDisabled(job, employee.id)}" [disabled]="checkIfDisabled(job, employee.id)">
                                                            <app-user [class]="'user_div'" [employee]="employee"></app-user>
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                            
                                                <button mat-icon-button color="primary" (click)="addExecutor(job)">
                                                    <mat-icon>person_add</mat-icon>
                                                </button>
                            
                                            </div>
                                        </div>
                                    </div>
                                    <div class="f_w_p_btns dbl">
                                        <div class="add_job" (click)="addJob()">
                                            + {{ 'Add job' | translate }}
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-template #elseTemplateCustom>
                                    <form [formGroup]="form">
                                        <div class="form_with_valids full_width" style="width: 100%;" *ngIf="form.get('template_id').value && !cuts.length">
                                            <mat-form-field appearance="standard" class="full_width_form">
                                                <ng-container *ngIf="form.get('name').value == '' && submited">
                                                    <div class="req_field"></div>
                                                    <div class="req_label">
                                                        <div class="angle"></div>
                                                        <span>
                                                            {{ "You need to write here" | translate }}
                                                        </span>
                                                    </div>
                                                </ng-container>
                                                <mat-label>{{ 'Card title' | translate }}</mat-label>
                                                <input formControlName="name" matInput placeholder="{{ 'Name' | translate }}">
                                            </mat-form-field>
                                        </div>
                                    </form>
                                </ng-template>
                            </div>
                    </div>
                </div>
                <div class="panel_options_out" [appDeviceClass]="'custom-scroll'">
                    <div class="panel_options_in">
                        <ng-container *ngIf="cuts && cuts.length; else elseTemplateNoCuts">
                            <div class="panel_option_out" [ngClass]="{'forms_activated': is_create_cards.value}" *ngFor="let cut of cuts; let i = index;">
                                <div class="panel_option">
                                    <button class="check_btn" [ngClass]="{'active_mode': !!cut.is_video_only}" mat-icon-button (click)="cut.is_video_only = !!cut.is_video_only ? 0 : 1" [disableRipple]="true">
                                        <mat-icon *ngIf="!!cut.is_video_only">videocam</mat-icon>
                                        <mat-icon *ngIf="!cut.is_video_only">videocam_off</mat-icon>
                                    </button>
                                    <button class="check_btn" [ngClass]="{'active_mode': !!cut.is_audio_only}" mat-icon-button (click)="cut.is_audio_only = !!cut.is_audio_only ? 0 : 1" [disableRipple]="true">
                                        <mat-icon *ngIf="!!cut.is_audio_only">audiotrack</mat-icon>
                                        <mat-icon *ngIf="!cut.is_audio_only">music_off</mat-icon>
                                    </button>
                                    <button mat-icon-button (click)="(!player?.paused && playCut && playCut.from == cut.from) ? pauseVideo() : playVideoInterval(cut)" [disableRipple]="true" style="color: #686868;">
                                        <ng-container *ngIf="!player?.paused && playCut && playCut.from == cut.from; else elseTemplatePlayed">
                                            <mat-icon>pause_circle</mat-icon>
                                        </ng-container>
                                        <ng-template #elseTemplatePlayed>
                                            <mat-icon>play_circle</mat-icon>
                                        </ng-template>
                                    </button>
                                    <mat-form-field appearance="legacy" class="full_width_form ml-1">
                                        <mat-label>{{ 'Name' | translate }}</mat-label>
                                        <input cdkFocusInitial [(ngModel)]="cut.name" matInput placeholder="{{ 'Name' | translate }}">
                                    </mat-form-field>
                                    <div class="option_btns ml-1">
                                        <div class="opt_block">
                                            <div>{{"Duration" | translate}}:</div>
                                            <!-- <div>{{cut.duration}}</div> -->
                                            <div>{{cut.duration | veTime}}</div>
                                        </div>
                                        <div class="opt_block">
                                            <div>{{"From" | translate}}:</div>
                                            <!-- <div>{{cut.from}}</div> -->
                                            <div>{{cut.from | veTime}}</div>
                                        </div>
                                        <div class="opt_block">
                                            <div>{{"To" | translate}}:</div>
                                            <!-- <div>{{cut.to}}</div> -->
                                            <div>{{cut.to | veTime}}</div>
                                        </div>
                                        <button class="show_on_opt_hover" mat-icon-button (click)="deleteCut(cut, i)" disableRipple style="color: #c0392b;">
                                            <mat-icon>delete</mat-icon>
                                        </button>
                                    </div>
                                </div>
                                <div class="panel_form" *ngIf="is_create_cards.value" [ngClass]="{'need-transform': cut.form.get('type').value == 1}">
                                    <form *ngIf="cut.form && (!!cut.is_video_only || !!cut.is_audio_only)" [formGroup]="cut.form" class="form_with_pads form_with_valids" style="transform: translateX(1px);">
                                        <div class="form_panel">
                                            <mat-checkbox class="mr-1 select_all_checkbox" [formControl]="cut.is_custom" [disableRipple]="true">
                                                <span class="ml-1">{{ "Custimize" | translate }}</span>
                                            </mat-checkbox>
                                            <mat-button-toggle-group class="form_btn_grp" formControlName="type" (change)="onChangeCutType(cut)">
                                                <mat-button-toggle [value]="0">{{"Custom template" | translate}}</mat-button-toggle>
                                                <mat-button-toggle [value]="1">{{"Preset template" | translate}}</mat-button-toggle>
                                            </mat-button-toggle-group>
                                            <div class="tmpl_val" *ngIf="cut.form.get('type').value == 1">
                                                <div (click)="selectTemplateCut.open()">
                                                    {{cut.form.get('template_id').value ? getItemById(cut.taskTemplates, cut.form.get('template_id').value)?.name : ("Select Template" | translate)}}
                                                    <mat-icon>arrow_drop_down</mat-icon>
                                                </div>
                                                <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                                                    <mat-select disableOptionCentering #selectTemplateCut formControlName="template_id" (selectionChange)="selectTmpl($event, cut)">
                                                        <mat-option *ngFor="let tmpl of cut.taskTemplates" [value]="tmpl.id">
                                                            {{ tmpl?.name }}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </form>
                                    <div class="form_in">
                                        <form *ngIf="cut.form && (!!cut.is_video_only || !!cut.is_audio_only)" [formGroup]="cut.form" class="form_with_pads form_with_valids" style="align-items: flex-start;">                                            
                                            <ng-container *ngIf="cut.form.get('type').value == 0; else elseTemplateCustomPanel">
                                                <mat-form-field *ngIf="cut.form.get('name')" appearance="standard" class="full_width_form">
                                                    <ng-container *ngIf="!cut.form.get('name').value == '' && submited">
                                                        <div class="req_field"></div>
                                                        <div class="req_label">
                                                            <div class="angle"></div>
                                                            <span>
                                                                {{ "You need to write here" | translate }}
                                                            </span>
                                                        </div>
                                                    </ng-container>
                                                    <mat-label>{{ 'Card title' | translate }}</mat-label>
                                                    <input formControlName="name" matInput placeholder="{{ 'Card title' | translate }}">
                                                </mat-form-field>

                                                <div class="d-f-c">
                                                    <div class="cut_value">
                                                        <div (click)="statusID.open()">
                                                            {{cut.form.get('status_id').value ? getStatusById(cut.form.get('status_id').value)?.name : ("Card Type" | translate)}}
                                                        </div>
                                                        <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                                                            <mat-select #statusID formControlName="status_id" (selectionChange)="selectType($event, cut)">
                                                                <mat-option>
                                                                    <ngx-mat-select-search 
                                                                    placeholderLabel="{{ 'Search' | translate }}" 
                                                                    noEntriesFoundLabel="{{ 'no matching type found' | translate }}"
                                                                    [formControl]="taskStatusesMoreControl"></ngx-mat-select-search>
                                                                </mat-option>
                                                                <mat-option *ngFor="let status of taskStatuses$ | async" [value]="status.id">
                                                                    {{ status.name | translate }}
                                                                </mat-option>
                                                            </mat-select>
                                                        </mat-form-field>
                                                    </div>
                                                    <div class="cut_value">
                                                        <div (click)="groupID.open()">
                                                            {{cut.form.get('group_id').value ? getGroupById(cut.form.get('group_id').value)?.name : ("Card Group" | translate)}}
                                                        </div>
                                                        <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                                                            <mat-select #groupID formControlName="group_id" (selectionChange)="selectGroup($event, cut)">
                                                                <mat-option>
                                                                    <ngx-mat-select-search 
                                                                    placeholderLabel="{{ 'Search' | translate }}" 
                                                                    noEntriesFoundLabel="{{ 'no matching group found' | translate }}"
                                                                    [formControl]="groupsMoreControl"></ngx-mat-select-search>
                                                                </mat-option>
                                                                <mat-option *ngFor="let group of groups$ | async" [value]="group.id">
                                                                    {{ group.name | translate }}
                                                                </mat-option>
                                                            </mat-select>
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                                <div class="file_tags_out">
                                                    <div class="file_tags">
                                                        <ng-container *ngIf="cut.parameters.length && cut.parameters[0].activeValues && cut.parameters[0].activeValues.length">
                                                            <div class="file_tag" *ngFor="let tag of cut.parameters[0].activeValues; let t = index;">
                                                                {{tag.value}}
                                                                <div class="remove_out">
                                                                    <div class="remove" (click)="onRemove(tag, t, cut)">
                                                                        <svg style="width: 5px; height: 5px;" width="5" height="5" viewBox="0 0 5 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M5 0.503571L4.49643 0L2.5 1.99643L0.503571 0L0 0.503571L1.99643 2.5L0 4.49643L0.503571 5L2.5 3.00357L4.49643 5L5 4.49643L3.00357 2.5L5 0.503571Z" fill="white"/>
                                                                        </svg>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                        <div class="add_tag cp" (click)="openTargetValues(cut)">
                                                            <svg style="width: 16px; height: 16px;" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M12 8.8H8.8V12H7.2V8.8H4V7.2H7.2V4H8.8V7.2H12M8 0C6.94943 0 5.90914 0.206926 4.93853 0.608964C3.96793 1.011 3.08601 1.60028 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.08601 14.3997 3.96793 14.989 4.93853 15.391C5.90914 15.7931 6.94943 16 8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 6.94943 15.7931 5.90914 15.391 4.93853C14.989 3.96793 14.3997 3.08601 13.6569 2.34315C12.914 1.60028 12.0321 1.011 11.0615 0.608964C10.0909 0.206926 9.05058 0 8 0Z" fill="#C4C4C4"/>
                                                            </svg>
                                                            {{ "Tag" | translate }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>
                                            <ng-template #elseTemplateCustomPanel>
                                                <div class="form_with_valids full_width" style="width: 100%;" *ngIf="cut.form.get('template_id').value">
                                                    <mat-form-field appearance="standard" class="full_width_form">
                                                        <ng-container *ngIf="cut.form.get('name').value == '' && submited">
                                                            <div class="req_field"></div>
                                                            <div class="req_label">
                                                                <div class="angle"></div>
                                                                <span>
                                                                    {{ "You need to write here" | translate }}
                                                                </span>
                                                            </div>
                                                        </ng-container>
                                                        <mat-label>{{ 'Card title' | translate }}</mat-label>
                                                        <input formControlName="name" matInput placeholder="{{ 'Name' | translate }}">
                                                    </mat-form-field>
                                                </div>
                                            </ng-template>
                                        </form>
                                        
                                        <ng-container *ngIf="cut.form.get('type').value == 0">
                                            <div class="jobs_wrp form_with_pads">
                                                <div class="job" *ngFor="let job of cut.jobs; let j = index;">
                                    
                                                    <mat-form-field appearance="standard" class="full_width_form">
                                                        <mat-label>{{ 'Title' | translate }}</mat-label>
                                                        <input [(ngModel)]="job.name" (change)="onChangeCutType(cut)" matInput placeholder="{{ 'Title' | translate }}">
                                                    </mat-form-field>
                                        
                                                    <mat-form-field appearance="standard" class="full_width_form">
                                                        <mat-label>{{ 'Description' | translate }}</mat-label>
                                                        <textarea [(ngModel)]="job.comment" (change)="onChangeCutType(cut)" matInput placeholder="{{ 'Drief description' | translate }}"
                                                        cdkTextareaAutosize
                                                        #autosize="cdkTextareaAutosize"
                                                        cdkAutosizeMinRows="1"
                                                        cdkAutosizeMaxRows="5"></textarea>
                                                    </mat-form-field>
                                    
                                                    <ng-container *ngIf="operations?.length > 0">
                                                        <div class="small_chips" >
                                                            <label>{{ "Job type" | translate }}: </label>
                                                            <mat-chip-list [multiple]="false" selectable [(ngModel)]="job.operation_id" (selectionChange)="onChangeCutType(cut)">
                                                                <ng-container *ngFor="let operation of operations; let i = index">
                                                                    <mat-chip class="small_chip" (click)="job.operation_id = operation?.id" [value]="operation?.id" [selected]="job.operation_id == operation?.id">
                                                                        {{ operation.name | translate }}
                                                                    </mat-chip>
                                                                    <mat-chip *ngIf="i == operations?.length - 1 && i > 6" class="small_chip more" (click)="operationIdContainer.open()">
                                                                        {{ "More..." | translate }}
                                                                    </mat-chip>
                                                                </ng-container>
                                                                
                                                            </mat-chip-list>
                                                        </div>
                                            
                                                        <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                                                            <mat-select #operationIdContainer [(ngModel)]="job.operation_id" (selectionChange)="onChangeCutType(cut)">
                                                                <mat-option>
                                                                    <ngx-mat-select-search 
                                                                    placeholderLabel="{{ 'Search' | translate }}" 
                                                                    noEntriesFoundLabel="{{ 'no matching job type found' | translate }}"
                                                                    [formControl]="operationsMoreControl"></ngx-mat-select-search>
                                                                </mat-option>
                                                                <mat-option *ngFor="let operation of operations$ | async" [value]="operation.id">
                                                                    {{ operation.name | translate }}
                                                                </mat-option>
                                                            </mat-select>
                                                        </mat-form-field>
                                                    </ng-container>
                                    
                                                    <div class="small_chips" *ngIf="statuses?.length > 0">
                                                        <label>{{ "Status" | translate }}: </label>
                                                        <mat-chip-list [multiple]="false" selectable [(ngModel)]="job.status_id">
                                                            <ng-container *ngFor="let status of statuses">
                                                                <mat-chip class="small_chip" (click)="job.status_id = status.id; onChangeCutType(cut);" [selected]="job.status_id == status.id" [value]="status?.id" [ngClass]="{
                                                                    'first': status.id == '1',
                                                                    'second': status.id == '2',
                                                                    'third': status.id == '3',
                                                                    'fours': status.id == '4',
                                                                    'fives': status.id == '5',
                                                                    'sixs': status.id == '6',
                                                                    'nine': status.id == '97',
                                                                    'seven': status.id == '98',
                                                                    'eight': status.id == '99'}">
                                                                    {{ status.name | translate }}
                                                                </mat-chip>
                                                            </ng-container>
                                                        </mat-chip-list>
                                                    </div>      
                                                    
                                                    <div class="priority_chips">
                                                        <label>{{ "Priority" | translate }}: </label>
                                                        <mat-chip-list [multiple]="false" selectable [(ngModel)]="job.priority">
                                                            <mat-chip *ngFor="let priority of prioritys" [selected]="job.priority == priority.id" [ngClass]="{
                                                                'Urgent': priority.id == '1000',
                                                                'High': priority.id == '900',
                                                                'Middle': priority.id == '800',
                                                                'Low': priority.id == '700',
                                                                'No': priority.id == '0'
                                                            }" class="priority_chip" (click)="job.priority = priority.id; onChangeCutType(cut);" [value]="priority.id">
                                                                {{ priority.name | translate }}
                                                            </mat-chip>
                                                        </mat-chip-list>
                                                    </div>
                                    
                                    
    
                                                    <div class="file_tags_out">
                                                        <label>{{ "Job Tags" | translate }}: </label>
                                                        <div class="file_tags">
                                                            <ng-container *ngIf="job.parameters && job.parameters.length && job.parameters[0].activeValues && job.parameters[0].activeValues.length">
                                                                <div class="file_tag" *ngFor="let tag of job.parameters[0].activeValues; let t = index;">
                                                                    {{tag.value}}
                                                                    <div class="remove_out">
                                                                        <div class="remove" (click)="onRemove(tag, t, cut, job)">
                                                                            <svg style="width: 5px; height: 5px;" width="5" height="5" viewBox="0 0 5 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M5 0.503571L4.49643 0L2.5 1.99643L0.503571 0L0 0.503571L1.99643 2.5L0 4.49643L0.503571 5L2.5 3.00357L4.49643 5L5 4.49643L3.00357 2.5L5 0.503571Z" fill="white"/>
                                                                            </svg>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </ng-container>
                                                            <div class="add_tag cp" (click)="openTargetValues(cut, job)">
                                                                <svg style="width: 16px; height: 16px;" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M12 8.8H8.8V12H7.2V8.8H4V7.2H7.2V4H8.8V7.2H12M8 0C6.94943 0 5.90914 0.206926 4.93853 0.608964C3.96793 1.011 3.08601 1.60028 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.08601 14.3997 3.96793 14.989 4.93853 15.391C5.90914 15.7931 6.94943 16 8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 6.94943 15.7931 5.90914 15.391 4.93853C14.989 3.96793 14.3997 3.08601 13.6569 2.34315C12.914 1.60028 12.0321 1.011 11.0615 0.608964C10.0909 0.206926 9.05058 0 8 0Z" fill="#C4C4C4"/>
                                                                </svg>
                                                                {{ "Tag" | translate }}
                                                            </div>
                                                        </div>
                                                    </div>
                                    
                                    
                                                    <div class="job_executors" *ngIf="job.create_task_employees.length && employees && employees.length > 0">
                                                        <div class="executor" *ngFor="let executor of job.create_task_employees; let k = index;">
                                                            
                                                            <app-user [class]="'user_div'" [employee]="getEmployeeById(executor.employee_id)"></app-user>
                                                        
                                                            <p>
                                                                {{ "Choose member role" | translate }}:
                                                                <mat-button-toggle-group (change)="onChangeCutType(cut)" appearance="legacy" class="ml-1" [(ngModel)]="executor.is_manager" [value]="executor.is_manager">
                                                                    <mat-button-toggle [value]="1">{{ "Executor" | translate }}</mat-button-toggle>
                                                                    <mat-button-toggle [value]="0">{{ "Assistant" | translate }}</mat-button-toggle>
                                                                </mat-button-toggle-group>
                                                            </p> 
                                    
                                                            <button mat-icon-button color="warn" class="delete_executor" (click)="deleteExecutor(job, k, cut)">
                                                                <mat-icon>person_remove</mat-icon>
                                                            </button>
                                    
                                                        </div>
                                                    </div>
                        
                                                    <button mat-icon-button color="warn" class="delete_job" (click)="deleteJob(j, cut)">
                                                        <mat-icon>do_disturb_on</mat-icon>
                                                    </button>
                                    
                                                    <div class="add_new_executor" *ngIf="job.newExecutor" style="position: relative;">
                                                        <div class="users_select">
                                                            <label>{{ "Add new executor" | translate }}: </label>
                                                            <mat-chip-list [multiple]="false" selectable [(ngModel)]="job.newExecutor.employee_id">
                                                                <ng-container *ngFor="let employee of employees; let i = index">
                                                                    <mat-chip class="chip_user" (click)="!checkIfDisabled(job, employee.id) ? selectExec(job, cut, employee) : null" [selected]="job.newExecutor.employee_id == employee.id" [value]="employee.id" [disabled]="checkIfDisabled(job, employee.id)">
                                                                        <app-user [class]="'user_div'" [employee]="employee"></app-user>
                                                                    </mat-chip>
                                                                    <mat-chip *ngIf="i == employees.length - 1 && i > 6" class="chip_user more" (click)="employeeIdContainer.open()">
                                                                        {{ "more" | translate }}
                                                                    </mat-chip>
                                                                </ng-container>
                                                            </mat-chip-list>
                                                        </div>
                                            
                                                        <mat-form-field appearance="standard" class="full_width_form hide-form-field" style="position: absolute;">
                                                            <mat-select #employeeIdContainer [(ngModel)]="job.newExecutor.employee_id" (selectionChange)="onChangeCutType(cut)">
                                                                <mat-option>
                                                                    <ngx-mat-select-search 
                                                                    placeholderLabel="{{ 'Search' | translate }}" 
                                                                    noEntriesFoundLabel="{{ 'no matching employee found' | translate }}"
                                                                    [formControl]="employeesMoreControl"></ngx-mat-select-search>
                                                                </mat-option>
                                                                <mat-option *ngFor="let employee of employees$ | async" [value]="employee.id" [ngClass]="{'opacity-6': checkIfDisabled(job, employee.id)}" [disabled]="checkIfDisabled(job, employee.id)">
                                                                    <app-user [class]="'user_div'" [employee]="employee"></app-user>
                                                                </mat-option>
                                                            </mat-select>
                                                        </mat-form-field>
                                    
                                                        <button mat-icon-button color="primary" (click)="addExecutor(job, cut)">
                                                            <mat-icon>person_add</mat-icon>
                                                        </button>
                                    
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="f_w_p_btns dbl">
                                                <div class="add_job" (click)="addJob(cut)">
                                                    + {{ 'Add job' | translate }}
                                                </div>
                                            </div>
                                        </ng-container>
                                        
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <ng-template #elseTemplateNoCuts>
                            <div class="panel_option">
                                <ng-container *ngIf="!audioFile">
                                    <button class="check_btn" [ngClass]="{'active_mode': !!data?.file?.is_video_only}" mat-icon-button (click)="data.file.is_video_only = !!data.file.is_video_only ? 0 : 1" [disableRipple]="true">
                                        <mat-icon *ngIf="!!data?.file?.is_video_only">videocam</mat-icon>
                                        <mat-icon *ngIf="!data?.file?.is_video_only">videocam_off</mat-icon>
                                    </button>
                                    <button class="check_btn" [ngClass]="{'active_mode': !!data?.file?.is_audio_only}" mat-icon-button (click)="data.file.is_audio_only = !!data.file.is_audio_only ? 0 : 1" [disableRipple]="true">
                                        <mat-icon *ngIf="!!data?.file?.is_audio_only">audiotrack</mat-icon>
                                        <mat-icon *ngIf="!data?.file?.is_audio_only">music_off</mat-icon>
                                    </button>
                                </ng-container>
                                
                                <button mat-icon-button (click)="player?.paused ? playVideo() : pauseVideo()" [disableRipple]="true" style="color: #686868;">
                                    <ng-container *ngIf="!player?.paused; else elseTemplatePlayedVideo">
                                        <mat-icon>pause_circle</mat-icon>
                                    </ng-container>
                                    <ng-template #elseTemplatePlayedVideo>
                                        <mat-icon>play_circle</mat-icon>
                                    </ng-template>
                                </button>
                                <mat-form-field appearance="legacy" class="full_width_form ml-1">
                                    <mat-label>{{ 'Name' | translate }}</mat-label>
                                    <input cdkFocusInitial [(ngModel)]="data.file.filename" matInput placeholder="{{ 'Name' | translate }}">
                                </mat-form-field>
                                <div class="option_btns ml-1">
                                    <div class="opt_block">
                                        <div>{{"Duration" | translate}}:</div>
                                        <!-- <div>{{duration}}</div> -->
                                        <div>{{duration | veTime}}</div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </div>
                </div>
                <div class="panel_line panel_opt_foot">
                    <div>
                        <!-- {{player?.paused}} -->
                    </div>
                    <button [disabled]="isSubmit" [ngStyle]="{'submited_btn': isSubmit}" class="panel_line_btn save_panel_line_btn" *ngIf="cuts.length || (!cuts.length && (!!audioFile || (!data?.file?.is_audio_only && !!data?.file?.is_video_only) || (!!data?.file?.is_audio_only && !data?.file?.is_video_only)))" (click)="save()" 
                    matRipple
                    [matRippleColor]="'rgba(0,0,0,.05)'">
                        <mat-spinner class="spin_16 mr-1" *ngIf="isSubmit"></mat-spinner> 
                        {{"Save"| translate}}
                    </button>
                </div>
            </div>
            <!-- (timeupdate)="onTimeUpdate(player.currentTime)" -->
            <div class="preview_panel">
                <video width="100%" class="player" controls #player (play)="playAudio()" (pause)="pauseAudio()" [muted]="!!audioFile" >
                    <source [src]="data?.host + (data?.file?.preview1080 ? data?.file?.preview1080 : data?.file?.original) + '?company_id=' + data?.company_id" [type]="data?.file?.content_type == 'video/quicktime' ? 'video/mp4' : data?.file?.content_type"/>
                    Your browser doesn't support HTML5 video tag.
                </video>
                <audio class="not_show" #audioPlayer [src]="data?.host + audioFile?.original + '?company_id=' + data?.company_id"></audio>
            </div>
        </div>

        <div class="time_and_btns">
            <div class="d-f-c">
                <div class="current_time">
                    {{player?.currentTime | veTime}}
                    <!-- {{currentTime}} -->
                </div>
                <button class="ml-1" [ngClass]="{'active_mode': mode == 'view'}" mat-icon-button (click)="changeMode('view')">
                    <mat-icon>ads_click</mat-icon>
                </button>
                <button class="ml-1" [ngClass]="{'active_mode': mode == 'cut'}" mat-icon-button (click)="changeMode('cut')">
                    <mat-icon>content_cut</mat-icon>
                </button>
                <!-- <button class="ml-1" mat-icon-button (click)="addCut()" *ngIf="mode == 'cut' && checkIfCanAdd()">
                    <mat-icon>add</mat-icon>
                </button> -->
            </div>

            <div class="add_media">
                <div class="add_media_btn" (click)="toggleMediaMenu()">
                    {{ "Add media" | translate }}
                </div>

                <div class="add_media_menu_out" *ngIf="isMediaMenuOpen">
                    <div class="add_media_menu_in">
                        <div class="add_media_item title">{{"Add media from" | translate}}</div>
                        <div class="add_media_divider"></div>
                        <div class="add_media_item" (click)="selectFile()">
                            {{ "File Manager" | translate }}
                        </div>
                    </div>
                </div>
            </div>
 
        </div>
        <div class="timeline-container noselect" 
            [ngClass]="{'cut_mode': mode == 'cut'}"  
            (mousemove)="onMouseMove($event, customTimeline?.offsetWidth)" 
            (mouseleave)="onMouseLeave($event)" 
            (mouseover)="onMouseOver($event)" 
            (mouseenter)="onMouseEnter($event)" 
            (mouseup)="onMouseUp($event)"
            (mousedown)="onMouseDown($event)"
            #outContainer>

            <div class="timeline">
                <div class="hovered-time-marker" *ngIf="verticalLine && verticalLine.show" [style.left.px]="(verticalLine.val / duration) * customTimeline?.offsetWidth">
                    <div class="value">
                        <!-- {{verticalLine.val}} -->
                        {{verticalLine.val | veTime}}
                    </div>
                </div>
                <div class="current-time-marker" (click)="clickToCurrentTime($event)" [ngClass]="{'current-cut-marker': mode == 'cut' && ((verticalLine.val|veTime) == (player?.currentTime|veTime))}" #timeMarker [style.left.px]="(player?.currentTime / duration) * customTimeline?.offsetWidth">
                    <div class="mark">
                        <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 0H7V5L3.5 9.5L0 5V0Z" fill="black"/>
                        </svg>
                    </div>
                    <div class="line">

                    </div>
                </div>
                <ng-container *ngIf="cuts && cuts.length">
                    <ng-container *ngFor="let cut of cuts; let k = index;">
                        <div *ngIf="cut.spliter.val != 0 && cut.spliter.val != duration" class="hovered-time-marker red" [style.left.px]="(cut.spliter.val / duration) * customTimeline?.offsetWidth">
                            <div class="value">
                                <!-- {{cut.spliter.val}} -->
                                {{cut.spliter.val | veTime}}
                            </div>
              
                            <div class="mask video_mask">
                                <span class="name text_one_line" [ngStyle]="{'max-width': ((((cut.to / duration) * customTimeline?.offsetWidth) - ((cut.from / duration) * customTimeline?.offsetWidth)) - 20) + 'px' }">{{cut?.name}}</span>
                                <div class="v_mask v_mask_left"
                                (mousedown)="onMouseDownCut($event, cut, k, true, customTimeline?.offsetWidth)">
                                    <div class="edited_mask" *ngIf="cutEdited && k == cutEdited.ind && cutEdited.is_left"></div>
                                    <div class="v_mask_in">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" >
                                            <path d="M 0 0 Q 8 0 8 8 L 8 0 Z" fill="#fff"/>
                                        </svg>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" >
                                            <path d="M 0 8 Q 8 8 8 0 L 8 8 Z" fill="#fff"/>
                                        </svg>
                                    </div>
                                    <div class="vertival_line"></div>
                                </div>
                                <div class="v_mask v_mask_right"
                                (mousedown)="onMouseDownCut($event, cut, k, false, customTimeline?.offsetWidth)">
                                    <div class="edited_mask" *ngIf="cutEdited && k == cutEdited.ind && !cutEdited.is_left"></div>
                                    <div class="vertival_line"></div>
                                    <div class="v_mask_in">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" >
                                            <path d="M 8 0 Q 0 0 0 8 L -0 0 Z" fill="#fff"/>
                                        </svg>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" >
                                            <path d="M 8 8 Q 0 8 0 0 L 0 8 Z" fill="#fff"/>
                                        </svg>
                                    </div>
                                </div>
                      
                            </div>

                            <div class="mask audio_mask">
                                <div class="v_mask v_mask_left"
                                (mousedown)="onMouseDownCut($event, cut, k, true, customTimeline?.offsetWidth)">
                                    <div class="edited_mask" *ngIf="cutEdited && k == cutEdited.ind && cutEdited.is_left"></div>
                                    <div class="v_mask_in">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" >
                                            <path d="M 0 0 Q 8 0 8 8 L 8 0 Z" fill="#fff"/>
                                        </svg>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" >
                                            <path d="M 0 8 Q 8 8 8 0 L 8 8 Z" fill="#fff"/>
                                        </svg>
                                    </div>
                                    <div class="vertival_line"></div>
                                </div>
                                <div class="v_mask v_mask_right" 
                                (mousedown)="onMouseDownCut($event, cut, k, false, customTimeline?.offsetWidth)">
                                    <div class="edited_mask" *ngIf="cutEdited && k == cutEdited.ind && !cutEdited.is_left"></div>
                                    <div class="vertival_line"></div>
                                    <div class="v_mask_in">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" >
                                            <path d="M 8 0 Q 0 0 0 8 L -0 0 Z" fill="#fff"/>
                                        </svg>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" >
                                            <path d="M 8 8 Q 0 8 0 0 L 0 8 Z" fill="#fff"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div class="mask add_audio_mask" *ngIf="!!audioFile">
                                <div class="v_mask v_mask_left"
                                (mousedown)="onMouseDownCut($event, cut, k, true, customTimeline?.offsetWidth)">
                                    <div class="edited_mask" *ngIf="cutEdited && k == cutEdited.ind && cutEdited.is_left"></div>
                                    <div class="v_mask_in">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" >
                                            <path d="M 0 0 Q 8 0 8 8 L 8 0 Z" fill="#fff"/>
                                        </svg>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" >
                                            <path d="M 0 8 Q 8 8 8 0 L 8 8 Z" fill="#fff"/>
                                        </svg>
                                    </div>
                                    <div class="vertival_line"></div>
                                </div>
                                <div class="v_mask v_mask_right"
                                (mousedown)="onMouseDownCut($event, cut, k, false, customTimeline?.offsetWidth)">
                                    <div class="edited_mask" *ngIf="cutEdited && k == cutEdited.ind && !cutEdited.is_left"></div>
                                    <div class="vertival_line"></div>
                                    <div class="v_mask_in">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" >
                                            <path d="M 8 0 Q 0 0 0 8 L -0 0 Z" fill="#fff"/>
                                        </svg>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" >
                                            <path d="M 8 8 Q 0 8 0 0 L 0 8 Z" fill="#fff"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="vanish video_van" *ngIf="(!audioFile && !cut.is_video_only) || (!!audioFile && !cut.is_audio_only && !cut.is_video_only)" [ngStyle]="{'width': ((((cut.to / duration) * customTimeline?.offsetWidth) - ((cut.from / duration) * customTimeline?.offsetWidth)) + (k == cuts.length - 1 ? 2 : 0)) + 'px', 'left': (cut.spliter.val / duration) * customTimeline?.offsetWidth + 'px'}">

                        </div>
                        <div class="vanish audio_van" *ngIf="!audioFile && !cut.is_audio_only" [ngStyle]="{'width': ((((cut.to / duration) * customTimeline?.offsetWidth) - ((cut.from / duration) * customTimeline?.offsetWidth)) + (k == cuts.length - 1 ? 2 : 0)) + 'px', 'left': (cut.spliter.val / duration) * customTimeline?.offsetWidth + 'px'}">

                        </div>
                        <div *ngIf="!!audioFile && !cut.is_audio_only && !cut.is_video_only" class="vanish add_audio_van" [ngStyle]="{'width': ((((cut.to / duration) * customTimeline?.offsetWidth) - ((cut.from / duration) * customTimeline?.offsetWidth)) + (k == cuts.length - 1 ? 2 : 0)) + 'px', 'left': (cut.spliter.val / duration) * customTimeline?.offsetWidth + 'px'}">

                        </div>
                    </ng-container>
                </ng-container>
                <div class="custom-timeline" #customTimeline>
                    <canvas class="canvas-timeline" height="50" #timelineCanvas></canvas>
                </div>
                <div class="video_line" #timelineThumbnails>
                    <span class="name" *ngIf="!cuts || !cuts.length">{{data?.file?.filename}}</span>
                    <span class="name text_one_line" *ngIf="cuts && !!cuts.length" [ngStyle]="{'max-width': (((cuts[0].to / duration) * customTimeline?.offsetWidth) - 20) + 'px' }">{{cuts[0]?.name}}</span>
                    <canvas height="68" #thumbnailCanvas></canvas>
                </div>
                <div class="audio_line" [ngClass]="{'active': !audioFile}" #originalAudio>
                    <span class="name">Original Audio</span>
                    <canvas height="50" #originalAudioCanvas></canvas>
                </div>
                <div class="audio_line" *ngIf="audioFile" [ngClass]="{'active': !!audioFile}">
                    <span class="name">{{audioFile.filename}}</span>
                    <canvas id="addOriginalAudioCanvas" style="width: 100%; height: 50px;" height="50"></canvas>
                </div>
            </div>
        </div>

    </div>
</mat-dialog-content>
