import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReplaySubject, forkJoin } from 'rxjs';
import { debounceTime, last, map, switchMap, tap } from 'rxjs/operators';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { MembersService } from 'src/app/shared/services/rest/members.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { casesModel } from '../../functions/casesModel';
import { CompanyService } from '../../services/rest/company.service';

@Component({
  selector: 'app-set-edit-card',
  templateUrl: './set-edit-card.component.html',
  styleUrls: ['./set-edit-card.component.scss']
})
export class SetEditCardComponent extends BaseClass implements OnInit, OnDestroy {
  public form: FormGroup;
  public isFormChange: boolean = false;
  public is_getting: boolean = false;
  public numberRegEx = /\d*\.?\d{1,2}/;
  public page: number = 1;
  public pagination: any;
  public taskControl: FormControl = new FormControl();
  public tasks$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public tasks: any[] = [];

  public groups: any;
  public groups$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public groupMoreControl: FormControl = new FormControl();

  public typeOfSearchControl: FormControl = new FormControl('Everywhere');

  public groupOfSearchControl: FormControl = new FormControl('');

  public selectedTask: any;
  public selectedChannel: any;
  constructor(
    private taskService: TaskService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<SetEditCardComponent>,
    private fb: FormBuilder,
    private companyService: CompanyService,
    private layoutService: LayoutService
  ) { super() }

  ngOnInit(): void {
    console.log("SetEditCardComponent", this.data)

    this.form = this.fb.group({
      task_id: this.data.val ? this.data.val.task_id : 0,
    })

    if (this.data.selectedTask) {
      this.selectedTask = this.data.selectedTask;
      this.form.patchValue({
        task_id: this.selectedTask.id
      })
    }
    
    if (this.data.needChannel) {
      if (this.data.selectedChannel) {
        this.selectedChannel = this.data.selectedChannel;
        this.form.addControl('task_channel_id', this.fb.control(this.selectedChannel.id))
      } else {
        this.form.addControl('task_channel_id', this.fb.control(this.data.val ? this.data.val.task_channel_id : 0))
      }
      this.form.updateValueAndValidity();
    }
    
    
    this.attachSubscriptions(
      this.typeOfSearchControl.valueChanges.subscribe((resp) => {
        this.tasks = [];
        this.tasks$.next(this.tasks.slice())
        this.page = 1;
        this.pagination = undefined
        this.getTasks(this.page, {group_id: ['Everywhere', 'Custom ID', 'Task Name'].includes(resp) ? this.groupOfSearchControl.value : 0})
      })
    )

    this.attachSubscriptions(
      this.groupOfSearchControl.valueChanges.subscribe((resp) => {
        this.tasks = [];
        this.tasks$.next(this.tasks.slice())
        this.page = 1;
        this.pagination = undefined
        this.getTasks(this.page, {group_id: ['Everywhere', 'Custom ID', 'Task Name'].includes(this.typeOfSearchControl.value) ? resp : 0})
      })
    )

    this.getGroupsCompany()

    
    if (this.data.val && this.data.val.task_id != 0) {
      this.getTask(this.data.val.task_id);
    }

    this.attachSubscriptions(
      this.taskControl.valueChanges.pipe(debounceTime(600)).subscribe((resp) => this.onSearchTasks(resp, this.typeOfSearchControl.value))
    )

    this.attachSubscriptions(
      this.groupMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchGroups(resp))
    )

    this.attachSubscriptions(
      this.form.valueChanges.subscribe((res) => {
        console.log("this.form.valueChanges", res)

        if (!!res.task_id) {
          this.getTask(res.task_id);
        } else {
          this.selectedTask = undefined;
        }

        // if (this.selectedTask && !!res.task_channel_id) {
        //   this.selectedChannel = 
        // } else {
          
        // }
        this.isFormChange = true
      })
    )

    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        if (this.isFormChange) {
          this.layoutService.openBottomSheet(this.dialogRef);
        } else {
          this.close();
        }
      })
    )

    this.getTasks(this.page);

    if (this.data.needChannel) {
      this.attachSubscriptions(
        this.form.get('task_id').valueChanges.subscribe(res => {
          this.form.patchValue({
            task_channel_id: 0
          })
        })
      )
    }
  }

  
  getGroupsCompany() {
    this.attachSubscriptions(
      this.companyService.getInfiniteGroupsCompany(this.data.company.id, '1', '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.companyService.getInfiniteGroupsCompany(this.data.company.id, x).pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        this.groups = [].concat(...res);
        this.groups$.next(this.groups.slice());
      })
    )
  }


  filteredChannels(arr) {
    return arr.filter(x => x.channel_id == this.data.val.channel_id && x.content_type_id == this.data.val.content_type_id && !x.contentPlan && x.content_status_id <= 2)
  }

  onSearchGroups(resp) {
    if (!this.groups) {
      return;
    }

    if (!resp) {
      this.groups$.next(this.groups.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.groups$.next(
      this.groups.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }
  
  selectCard(task) {
    this.selectedTask = JSON.parse(JSON.stringify(task));
    console.log("selectCard selectedTask", this.selectedTask);
  }

  selectChannel(channel) {
    this.selectedChannel = JSON.parse(JSON.stringify(channel));
    console.log("selectChannel selectedChannel", this.selectedChannel);
  }

  onSearch(resp) {
    if (!this.tasks) {
      return;
    }

    if (!resp) {
      this.tasks$.next(this.tasks.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.tasks$.next(
      this.tasks.filter(z => z.id.toString().indexOf(resp) > -1 || z.custom_id.toString().indexOf(resp) > -1 || z.internal_id.toString().indexOf(resp) > -1 || (z.name && z.name.toLowerCase().indexOf(resp) > -1))
    );
  }

  onScroll(e, type) {
    console.log("onScroll", e);
    this.onSearchTasks(this[type].value, this.typeOfSearchControl.value, e, true)
  }

  log() {
    console.log(this.form.value)
    console.log(this.selectedTask)
    console.log(this.selectedChannel)
  }

  getTask(res) {
    this.attachSubscriptions(
      this.taskService.getOneTaskExpand(this.data.company.id, res)
      .pipe(
        map(el => casesModel(el.body, [], 'update').arr[0]),
      ).subscribe(res => {
        this.selectedTask = res;
        if (this.data.needChannel && !!this.form.value.task_channel_id) {
          this.selectedChannel = this.selectedTask.channels.find(x => x.id == this.form.value.task_channel_id)
        }
        if (this.tasks.filter(x => x.id == this.selectedTask.id).length == 0) {
          this.tasks.unshift(this.selectedTask)
        }
        console.log("getTask selectedTask", this.selectedTask);
      })
    )
  }

  onSearchTasks(resp, query, e?, notDel?) {
    if (this.is_getting) {
      return
    }
    resp = resp && typeof resp == 'string' ? resp.toLowerCase() : (resp || '');
    console.log('onSearchTasks', resp, notDel)

    let filter:any = {
      group_id: ['Everywhere', 'Custom ID', 'Task Name'].includes(this.typeOfSearchControl.value) ? this.groupOfSearchControl.value : 0
    }
    
    switch (query) {
      case 'Everywhere':
        filter.q = resp;
        break;
      case 'Task Name':
        filter.q_task_name = resp;
        break;
      case 'Custom ID':
        filter.custom_id = resp;
        break;
      case 'Internal ID':
        filter.internal_id = resp;
        break;
      case 'Global ID':
        filter.id = resp;
        break;
    }
    if (!notDel) {
      if (this.form.value.task_id) {
        this.tasks = this.tasks.filter(x => x.id == this.form.value.task_id);
      } else {
        this.tasks = [];
      }
      this.tasks$.next(this.tasks.slice())
      this.page = 1;
      this.pagination = undefined
    }
    // filter the banks


    this.getTasks(this.page, filter, e);
  }

  getTasks(page, filter:any = null, event?) {
    if (this.is_getting) {
      return
    }
    this.is_getting = true;
    console.log("getTasks filter", filter)
    // 2 getTasksSelect
    this.attachSubscriptions(
      (!!this.data.only ? this.taskService.getTasksFullSelect(page, this.data.company.id, filter) : this.taskService.getTasksFullSelect(page, this.data.company.id, filter)).pipe(
        tap(el => {
          this.pagination = {
            'pageCount': el.headers.get('x-pagination-page-count'),
            'perPage': el.headers.get('x-pagination-per-page'),
            'totalCount': el.headers.get('x-pagination-total-count'),
            'currentPage': el.headers.get('x-pagination-current-page'),
          }
        })
      ).subscribe(resp => {
        let arr = this.tasks.slice();
        // this.tasks.push(...resp.body)
        resp.body.forEach(element => {
          if (this.tasks.filter(x => x.id == element.id).length == 0) {
            this.tasks.push(element)
          }
        });
        console.log("getTasks res" + this.page, this.tasks)
        this.page = this.page + 1; 
        this.tasks$.next(this.tasks.slice())
        this.is_getting = false;

        if (event) {
          setTimeout(() => {
            // + event.scrollHeight
            event.e.target.scrollTop = event.scrollTop;
            console.log("END", event)
          }, 50)
          // event.e.target.scroll(0, event.scrollTop);
        }
      })
    )
  }
  
  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  onSubmit() {
    if (this.data.only) {
      this.dialogRef.close({event: "select", data: this.selectedTask, channel: this.selectedChannel})
    } else {
      this.attachSubscriptions(
        this.companyService.editScheduledPost(this.data.val.id, this.form.value).subscribe(resp => {
          this.dialogRef.close({event: "update", data: resp})
          this.data.val.task_id = resp.task_id;
          this.data.val.task_channel_id = resp.task_channel_id;
        },
        error => {
          this.close()
        })
      )
    }
   
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
