import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReplaySubject } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { RootService } from 'src/app/shared/services/rest/root.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';

@Component({
  selector: 'app-add-edit-root-plan',
  templateUrl: './add-edit-root-plan.component.html',
  styleUrls: ['./add-edit-root-plan.component.scss']
})
export class AddEditRootPlanComponent extends BaseClass implements OnInit, OnDestroy {
  public form: FormGroup;
  public isFormChange: boolean = false;
  public companyes: any;
  public compMoreControl: FormControl = new FormControl();
  public companyes$: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private companyService: CompanyService,
    private rootService: RootService,
    public dialogRef: MatDialogRef<AddEditRootPlanComponent>,
    public layoutService: LayoutService
  ) { super() }

  ngOnInit(): void {

    this.form = this.fb.group({
      name: ['', Validators.required],
      price: 0,
      is_active: 1,
      live_stream_limit: 1,
      restriction_min_live_stream_limit: 1000,
      order: 0
    })

    if (this.data.plan) {
      this.form.patchValue({
        name: this.data.plan.name,
        price: this.data.plan.price,
        is_active: this.data.plan.is_active,
        is_type_plan: this.data.plan.is_type_plan,
        is_type_live_stream: this.data.plan.is_type_live_stream,
        live_stream_limit: this.data.plan.live_stream_limit,
        restriction_min_live_stream_limit: this.data.plan.restriction_min_live_stream_limit,
        order: this.data.plan.order
      })
    } else {
      this.getCompanyes();
      this.form.addControl('company_id', this.fb.control(this.data.company_id || 0));
      this.form.addControl('is_type_plan', this.fb.control(0));
      this.form.addControl('is_type_live_stream', this.fb.control(1));

      this.attachSubscriptions(
        this.compMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchWorkspaces(resp))
      )
    }

    this.attachSubscriptions(
      this.form.valueChanges.subscribe(() => this.isFormChange = true)
    )

    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        if (this.isFormChange) {
          this.layoutService.openBottomSheet(this.dialogRef);
        } else {
          this.close();
        }
      })
    )
  }

  onSearchWorkspaces(resp) {
    if (!this.companyes) {
      return;
    }

    if (!resp) {
      this.companyes$.next(this.companyes.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.companyes$.next(
      this.companyes.filter(z => z.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  getCompanyes() {
    this.attachSubscriptions(
      this.rootService.getRootCompany(1).pipe(
        map(k => k.body)
      ).subscribe(resp => {
        this.companyes = resp;
        this.companyes$.next(this.companyes.slice());

        console.log("this.companyes", this.companyes)
      })
    )
  }

  toggleChip = (chip: any) => {
    this.form.patchValue({
      company_id: !!chip.id ? chip.id : chip.value
    })
  }

  close() {
    this.dialogRef.close({event: "close", data: false});
  }

  submitForm() {
    if (this.data.plan) {
      this.attachSubscriptions(
        this.rootService.editRootPlan(this.data.plan.id, this.form.value).subscribe(resp => {
          this.dialogRef.close({event: "close", data: resp});
        }, error => {
          console.log('error', error)
          this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
        })
      )
    } else {
      this.attachSubscriptions(
        this.rootService.addRootPlan(this.form.value).subscribe(resp => {
          this.dialogRef.close({event: "close", data: resp});
        }, error => {
          console.log('error', error)
          this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
        })
      )
    }
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
