<h2 mat-dialog-title class="dialog_title">
    <span>
        {{ "Apply to employee" | translate }}:
    </span>
    <button mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h2>
<mat-dialog-content class="mat-typography">
    <div class="container">
        <form [formGroup]="form">
            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ "Choose an employee" | translate }}</mat-label>
                <mat-select formControlName="employee_id" required multiple>
                    <mat-select-trigger class="multi_users">
                        <ng-container *ngIf="!!form.get('employee_id').value && !!form.get('employee_id').value.length">
                            <ng-container *ngFor="let id of form.get('employee_id').value">
                                <app-user [class]="'user_div'" [employee]="getEmployeeById(id)"></app-user>
                            </ng-container>
                        </ng-container>
                    </mat-select-trigger>
                    <mat-option *ngFor="let employee of data?.employees" [value]="employee.id">
                        <app-user [class]="'user_div'" [employee]="employee"></app-user>
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ "Choose a quest" | translate }}</mat-label>
                <mat-select formControlName="application_id" required multiple>
                    <mat-option *ngFor="let quest of data.quests" [value]="quest.id">
                        {{ quest.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
          
        </form>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-raised-button (click)="close()">
        {{ "Back" | translate }}
    </button>
    <button class="ml-1 d-f-btn" (click)="onSubmit()" mat-raised-button color="primary" [disabled]="!form.valid || isSubmit">
        <mat-spinner class="spin_16 mr-1" *ngIf="isSubmit"></mat-spinner>  
        {{ "Add" | translate }}
    </button>
</mat-dialog-actions>