import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RefreshService {
  public refreshChecklist$: Subject<any> = new Subject();
  public refreshExcept$: Subject<any> = new Subject();
  public refreshOnlyFor$: Subject<any> = new Subject();
  public forceRequest$: Subject<any> = new Subject();
  public isTaskGetting$: Subject<any> = new Subject();
  public refreshTask$: Subject<any> = new Subject();
  public refreshJob$: Subject<any> = new Subject();
  public refreshChatPost$: Subject<any> = new Subject();
  public refreshChat$: Subject<any> = new Subject();
  public refreshUrls$: Subject<any> = new Subject();
  public refreshTaskCard$: Subject<any> = new Subject();
  public refreshBoard$: Subject<any> = new Subject();
  public refreshFileTags$: Subject<any> = new Subject();
  public refreshFiles$: Subject<any> = new Subject();
  public refreshLiteFiles$: Subject<any> = new Subject();
  public newWorkSpace$: Subject<any> = new Subject();
  public refreshBookmarks$: Subject<any> = new Subject();
  constructor() { }

  // slow
  // https://api.reports.re/api/file/?page=1&company_id=25&per-page=20&expand=fileMetaUpdateQueue,fileOriginalData,document,archives,task,taskOperation,discussion,createdEmployee,taskChannelFiles.taskChannel.channel.platform,parameterValuesToTask.parameter,parameterValuesToTask.parameterValue&summary=1&task_id=160783&task_operation_id=1007613&location=/&order_by=filename

  // fast
  // https://api.reports.re/api/file/?page=1&company_id=25&per-page=20&expand=document,archives,createdEmployee,parameterValuesToTask.parameter,parameterValuesToTask.parameterValue,task,TaskOperation&summary=1&task_id=160783&task_operation_id=1007613&location=/&order_by=filename
  // https://api.reports.re/api/file/?page=1&company_id=25&per-page=20&expand=document,archives,createdEmployee,parameterValuesToTask.parameter,parameterValuesToTask.parameterValue&summary=1&task_id=160783&task_operation_id=1007613&location=/&order_by=filename
  getIsTaskGetting() {
    return this.isTaskGetting$.asObservable();
  }

  getForceRequest() {
    return this.forceRequest$.asObservable();
  }
  getRefreshFileTags() {
    return this.refreshFileTags$.asObservable();
  }

  getRefreshBookmarks() {
    return this.refreshBookmarks$.asObservable();
  }

  getOnlyFor() {
    return this.refreshOnlyFor$.asObservable();
  }

  getChecklist() {
    return this.refreshChecklist$.asObservable();
  }

  getExcept() {
    return this.refreshExcept$.asObservable();
  }
  
  getTaskCard() {
    return this.refreshTaskCard$.asObservable();
  }

  getChatPost() {
    return this.refreshChatPost$.asObservable();
  }

  getNewWorkSpace() {
    return this.newWorkSpace$.asObservable();
  }

  getRefreshTasks() {
    return this.refreshTask$.asObservable();
  }

  getRefreshUrls() {
    return this.refreshUrls$.asObservable();
  }
  
  getRefreshJobs() {
    return this.refreshJob$.asObservable();
  }

  getRefreshChats() {
    return this.refreshChat$.asObservable();
  }

  getRefreshBoard() {
    return this.refreshBoard$.asObservable();
  }

  getRefreshFiles() {
    return this.refreshFiles$.asObservable();
  }
  
  getRefreshLiteFiles() {
    return this.refreshLiteFiles$.asObservable();
  }
}

