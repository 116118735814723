import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { map, tap } from 'rxjs/operators';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { AuthService } from 'src/app/shared/services/rest/auth.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { ApplyNewPromoComponent } from './apply-new-promo/apply-new-promo.component';

@Component({
  selector: 'app-promos',
  templateUrl: './promos.component.html',
  styleUrls: ['./promos.component.scss']
})
export class PromosComponent extends BaseClass implements OnInit, OnDestroy {

  public totalBalance: number = 0;
  public promosPagination: any;
  public isLoadPromos: boolean = false;
  public promos: any[] = [];
  public activeLang: any;
  public user: any;
  public promosPage: number = 1;
  public company_id: number = +this.activatedRoute.snapshot.queryParamMap.get('company_id');

  public is_em: number = 1;

  public today = moment().set({hour:0,minute:0,second:0}).unix();
  public todayEnd = moment().endOf('day').unix();

  throttle = 100;
  scrollUpDistance = 3.5;
  
  constructor(
    private fb: FormBuilder,
    private auth: AuthService,
    private dialog: MatDialog,
    private languageService: LanguageService,
    private companyService: CompanyService,
    private activatedRoute: ActivatedRoute,
    private layoutService: LayoutService,
  ) { 
    super()
   }

  ngOnInit(): void {
    this.getUser();
    this.getLangData();
    this.getWorkspacePromos(this.promosPage);
  }

  copyData(type) {
    this.layoutService.showSnackBar({name: type}, "Copied", SnackBarItem)
  }

  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData().subscribe(resp => {
        this.activeLang = resp.active;
      })
    )
  }

  onDownPromos(e) {
    console.log("SCROLL DOWN", e);
    if (this.promosPagination['pageCount'] >= this.promosPage) {
      console.log("onDownPromos")
      this.getWorkspacePromos(this.promosPage);
    }
  }

  wholeDisc(item) {
    if (+item.product_discounted_price > 0 && +item.product_base_price > 0) {
      return Math.round(100 - ((+item.product_discounted_price*100)/+item.product_base_price))
    } else {
      return 0
    }
  }

  specialDisc(item) {
    if (+item.product_discounted_price > 0) {
      return Math.round(100 - ((+item.product_price*100)/+item.product_discounted_price))
    } else {
      return 0
    }
  }

  getUser() {
    this.attachSubscriptions(
      this.auth.$user.subscribe(resp => {
        this.user = resp;
      })
    )
  }

  applyPromo() {
    let rootPromosData:any = {
      activeLang: this.activeLang,
      company_id: this.company_id
    }

    const dialogRef = this.dialog.open(ApplyNewPromoComponent, {
      data: rootPromosData
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (this.is_em == 2 || this.is_em == 0) {
          this.promosPage = 1;
          this.getWorkspacePromos(this.promosPage)
        }
      })
    )
  }

  changeFilter(val) {
    this.is_em = val;

    this.promosPage = 1;
    this.getWorkspacePromos(this.promosPage)
  }

  getWorkspacePromos(page) {
    this.isLoadPromos = true;
    if (this.is_em == 2) {
      let params:any = {
        company_id: this.company_id
      }
      this.attachSubscriptions(
        this.companyService.getWorkspacePromosEm(page, params).pipe(
          tap(el => {
            this.promosPagination = {
              'pageCount': el.headers.get('x-pagination-page-count'),
              'perPage': el.headers.get('x-pagination-per-page'),
              'totalCount': el.headers.get('x-pagination-total-count'),
              'currentPage': el.headers.get('x-pagination-current-page'),
            }
          }),
          map(res => res.body),
        ).subscribe(resp => {        
          if (page == 1) {
            this.promos = resp;
          } else {
            this.promos.push(...resp)
          }
          console.log("getWorkspacePromos-" + this.promosPage, this.promos);
          this.promosPage++;
          this.isLoadPromos = false;
        })
      )
    } else if (this.is_em == 1) {
      let params:any = {
        company_id: this.company_id
      }
      this.attachSubscriptions(
        this.companyService.getWorkspacePromos(page, params).pipe(
          tap(el => {
            this.promosPagination = {
              'pageCount': el.headers.get('x-pagination-page-count'),
              'perPage': el.headers.get('x-pagination-per-page'),
              'totalCount': el.headers.get('x-pagination-total-count'),
              'currentPage': el.headers.get('x-pagination-current-page'),
            }
          }),
          map(res => res.body),
        ).subscribe(resp => {        
          if (page == 1) {
            this.promos = resp;
          } else {
            this.promos.push(...resp)
          }
          console.log("getWorkspacePromos-" + this.promosPage, this.promos);
          this.promosPage++;
          this.isLoadPromos = false;
        })
      )
    } else {
      let params:any = {
        referred_by_company_id: this.company_id
      }
      this.attachSubscriptions(
        this.companyService.getWorkspacePromosEm(page, params).pipe(
          tap(el => {
            this.promosPagination = {
              'pageCount': el.headers.get('x-pagination-page-count'),
              'perPage': el.headers.get('x-pagination-per-page'),
              'totalCount': el.headers.get('x-pagination-total-count'),
              'currentPage': el.headers.get('x-pagination-current-page'),
            }
          }),
          map(res => res.body),
        ).subscribe(resp => {        
          if (page == 1) {
            this.promos = resp;
          } else {
            this.promos.push(...resp)
          }
          console.log("getWorkspacePromos-" + this.promosPage, this.promos);
          this.promosPage++;
          this.isLoadPromos = false;
        })
      )
    }


  }

  filterTransactionsData() {
    this.promosPage = 1;
    this.getWorkspacePromos(this.promosPage);
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

}
