import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { mimeTypes } from 'mime-wrapper';
import { FileService } from 'src/app/shared/services/rest/file.service';
import { catchError, filter, last, map, switchMap, tap } from 'rxjs/operators';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { SnackBarItem } from '../../../snack-bar/snack-bar-item';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { interval, of } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-file-text-to-speech',
  templateUrl: './file-text-to-speech.component.html',
  styleUrls: ['./file-text-to-speech.component.scss']
})
export class FileTextToSpeechComponent extends BaseClass implements OnInit, OnDestroy {

  // @ViewChild("text") text: ElementRef<any>;
  public mimeTypes = mimeTypes;
  public lang: any;
  public activeLang: any;
  public timeZone = new Date().getTimezoneOffset()*60;
  public form: FormGroup = this.fb.group({
    text: '',
    voice_id: '',
    model_id: "eleven_multilingual_v2",
    voice_settings: this.fb.group({
      stability: 0.5,
      similarity_boost: 0.5,
      style: 0.5,
      use_speaker_boost: true
    })
  })

  public isSubmit: boolean = false;
  public result: any;
  public voices: any;
  public voicesLegacy: any;
  public voicesShared: any;
  public speaches: any[] = [];
  public host: any = environment.host;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<FileTextToSpeechComponent>,
    private languageService: LanguageService,
    private sanitizer: DomSanitizer,
    private fileService: FileService,
    private fb: FormBuilder,
    private layoutService: LayoutService,
    private dialog: MatDialog,
  ) { super() }

  ngOnInit(): void {
    console.log("FileTextToSpeechComponent", this.data)
    if (this.timeZone > 0) {
      this.timeZone = -this.timeZone
    } else {
      this.timeZone = Math.abs(this.timeZone)
    }
    this.getLangData();
    if (this.data.text) {
      this.form.patchValue({
        text: this.extractTextFromHTML(this.data.text)
      })
    } else {
      this.getFile();
    }
    if (this.data.file.parameterValuesToTask) {
      this.lang = this.data.file.parameterValuesToTask.find(k => k.parameter.is_language) && this.data.file.parameterValuesToTask.find(k => k.parameter.is_language).parameterValue ? this.data.file.parameterValuesToTask.find(k => k.parameter.is_language).parameterValue.value : undefined;
    }
    if (this.lang) {
      this.getSharedVoices({company_id: this.data.company.id, language: this.lang}, 'voicesShared')
    } else {
      this.getSharedVoices({company_id: this.data.company.id}, 'voicesShared')
    }

    this.getVoices({show_legacy: true, company_id: this.data.company.id}, 'voicesLegacy')
    this.getVoices({company_id: this.data.company.id}, 'voices')

    this.attachSubscriptions(
      interval(2000).pipe(
        filter(() => !!this.speaches.length),
        switchMap(res => {
          let filterSpeech = {
            company_id: this.data.company.id,
            id: this.speaches.map(k => k.id).join(',')
          }
          console.log("getTextToSpeech", filterSpeech)
          return this.fileService.getTextToSpeech(filterSpeech).pipe(map(x => x.body))
        }),
        switchMap(results => {
          if (results.filter(k => k.is_done).length) {
            let filterProgress:any = {};
            if (!!this.data.file.task_id) {
              filterProgress.task_id = this.data.file.task_id;
            }
            if (!!this.data.file.task_operation_id) {
              filterProgress.task_operation_id = this.data.file.task_operation_id;
            }
            return this.fileService.getFilesProgress(this.data.company.id, [...results.filter(k => k.is_done).map(k => k.saved_file_id)], filterProgress,',parameterValuesToTask.parameter,parameterValuesToTask.parameterValue').pipe(
              tap(arr => {
                console.log("getTextToSpeech FILES K", arr)
                arr.forEach(element => {
                  results.find(p => p.saved_file_id == element.id).file = element;
                });
                console.log("getTextToSpeech results K", results)
              }),
              map(() => results)
            )
          } else {
            return of(results)
          }
        })
      ).subscribe(resp => {
        // saved_file_id	14973
        console.log("getTextToSpeech sub", resp)
        this.speaches = resp;
        if (this.speaches.filter(k => !!k.file).length) {
          this.result = this.speaches.find(k => !!k.file).file;
          this.speaches = []
        }
      })
    )

    this.attachSubscriptions(
      this.form.get('voice_id').valueChanges.subscribe(res => {
        this.form.updateValueAndValidity();
        this.getVoiceSettings(res)
      })
    )
  }

  getVoiceById(id) {
    if (this.voices && this.voices.find(k => k.voice_id == id)) {
      return this.voices.find(k => k.voice_id == id)
    } else if (this.voicesLegacy && this.voicesLegacy.find(k => k.voice_id == id)) {
      return this.voicesLegacy.find(k => k.voice_id == id)
    } else if (this.voicesShared && this.voicesShared.find(k => k.voice_id == id)) {
      return this.voicesShared.find(k => k.voice_id == id)
    } else {
      return null
    }
  }

  resetForm() {
    this.form.patchValue({
      voice_id: '',
      model_id: "eleven_multilingual_v2",
    })

    this.form.get("voice_settings").patchValue({
      stability: 0.5,
      similarity_boost: 0.5,
      style: 0.5,
      use_speaker_boost: true
    })
  }

  log() {
    console.log('form', this.form.value);
    console.log('result', this.result);
    console.log('speaches', this.speaches);
  }

  getVoiceSettings(v_id) {
    this.attachSubscriptions(
      this.fileService.getVoiceSettings({company_id: this.data.company.id, voice_id: v_id}).pipe(
        map(x => {
          if (x.success) {
            return x.data
          } else {
            return []
          }
        })
      ).subscribe(resp => {
        console.log("getVoiceSettings", resp)
        if (resp) {
          this.form.get("voice_settings").patchValue(resp)
        }
      })
    )
  }

  getVoices(fil, key) {
    this.attachSubscriptions(
      this.fileService.getVoices(fil).pipe(
        map(x => {
          if (x.success) {
            return x.data.voices
          } else {
            return []
          }
        })
      ).subscribe(resp => {
        this[key] = resp
        console.log(key, this[key])
      })
    )
  }

  getSharedVoices(fil, key) {
    this.attachSubscriptions(
      this.fileService.getVoices(fil).pipe(
        map(x => {
          if (x.success) {
            return x.data.voices
          } else {
            return []
          }
        })
      ).subscribe(resp => {
        this[key] = resp
        console.log(key, this[key])
      })
    )
  }

  getFile() {
    this.attachSubscriptions(
      this.fileService.getFilesPreview(this.data.company.id, [this.data.file.id]).pipe(
        map(arr => arr[0])
      ).subscribe(resp => {
        this.data.file = resp
        console.log("RESP", resp)
        console.log("FILE", resp)
        // console.log("text", this.text.nativeElement);
        if (this.data.file.document && this.data.file.document.text) {
          this.form.patchValue({
            text: this.extractTextFromHTML(this.data.file.document.text)
          })
        }
      })
    )
  }

  extractTextFromHTML(html: string): string {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    return doc.body.textContent || '';
  }

  copySpeech() {
    this.layoutService.showSnackBar({name: 'Speech'}, marker("Copied"), SnackBarItem)
  }

  start() {
    this.isSubmit = true;
    let startData = {
      company_id: this.data.file.company_id,
      params: {...this.form.value},
      save_task_id: this.data.file.task_id,
      save_task_operation_id: this.data.file.task_operation_id,
      save_location: this.data.file.location,
      save_filename: `TS-${this.data.file.filename.split('.')[0]}.mp3`
    }

    this.attachSubscriptions(
      this.fileService.textToSpeech(startData, this.data.company.id).subscribe(resp => {
        this.speaches.push(resp);
        this.layoutService.showSnackBar({name: 'The task for creating a voiceover has been added'}, marker("Successfully"), SnackBarItem)
        this.isSubmit = false;
        console.log('speaches', this.speaches);
        // if (resp) {
        //   this.result = resp;
  
        //   console.log("start RESP1", resp)
        //   console.log("start result1", this.result)
  
        //   this.result.blob = this.base64ToBlob(this.result.audio_base64, 'audio/mpeg')
        //   this.result.blobUrl = URL.createObjectURL(this.result.blob);
        //   this.result.blobSrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.result.blobUrl)
  
        //   console.log("start RESP2", resp)
        //   console.log("start result2", this.result)

        //   this.uploadFile(this.result)
        // } else {
        //   this.layoutService.showSnackBar({name: ''}, marker("Something went wrong"), SnackBarItem)
        // }
        // this.isSubmit = false;
      }, error => {
        this.layoutService.showSnackBar({name: error}, marker("Something went wrong"), SnackBarItem)
        this.isSubmit = false;
      })
    )
  }

  uploadFile(audio) {
    this.attachSubscriptions(
      this.fileService.uploadFile({
        company_id: this.data.file.company_id,
        task_id: this.data.file.task_id,
        task_operation_id: this.data.file.task_operation_id,
        filesize: audio.blob.size,
        filename: `TS-${this.data.file.filename.split('.')[0]}.mp3`,
        content_type: audio.blob.type ? audio.blob.type : 'audio/mpeg',
        location: this.data.file.location,
        is_dir: 0
      }, this.data.company.id).pipe(
        tap(res => {
          audio.reportsFile = res;
          audio.place = "cp_master";
          audio.url = window.location.href;
          audio.data = res;
          audio.files = [];
          audio.task = undefined;
          audio.work = undefined;
          audio.activeLang = this.data.activeLang;
          audio.location = this.data.file.location;
          audio.target = audio.blob;
          audio.user = this.data.user;
          audio.task_id = this.data.file.task_id;
          audio.task_operation_id = this.data.file.task_operation_id;
          audio.company = this.data.company;
          audio.partition = {
            company_id: this.data.file.company_id,
            file_id: this.data.file.id,
            consist_of_file_id: audio.reportsFile.id
          };
          audio.company_id = this.data.company.id;
          audio.target_company_id = this.data.file.company_id;
          this.fileService.files$.next(audio);
        }),
        catchError((error) => {
          if (error == "Company limit exceeded") {
            this.layoutService.showSnackBar({name: ''}, marker("This company has exceeded the file upload limit."), SnackBarItem)
          } else {
            this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
          }
          return of(false)
        })
      ).subscribe(resp => {
        console.log(resp)
      })
    )
  }

  base64ToBlob(base64: string, contentType: string = '', sliceSize: number = 512): Blob {
    const byteCharacters = atob(base64); // Декодируем Base64 строку
    const byteArrays = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
  
    return new Blob(byteArrays, { type: contentType });
  }

  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData().subscribe(resp => {
        this.activeLang = resp.active;
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false});
  }

  use() {
    this.dialogRef.close({event: "use", data: this.result});
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
