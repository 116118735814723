<!-- <mat-dialog-title class="dialog_title">
    <span>
        <ng-container *ngIf="data.basedVideos; else elseTemplateTitle">
            <ng-container *ngIf="data.isMulti; else elseTemplateMultiTitle">
                {{ data.videos.length }} {{ "new task(s) based on selected videos" | translate }}
            </ng-container>
            <ng-template #elseTemplateMultiTitle>
                {{ "New task based on" | translate }} {{ data.videos.length }} {{ "selected videos" | translate }}
            </ng-template>
        </ng-container>
        <ng-template #elseTemplateTitle>
            {{ "New task" | translate }}
        </ng-template>
    </span>
    <div class="for_space users_select">
        <span>{{ "Task belongs to workspace:" | translate }}</span>
        <mat-chip-list [multiple]="false" [selected]="false">
            <mat-chip class="chip_user" style="padding-left: 0 !important;" [value]="data.company.id">   
                <app-user [class]="'user_div'" [companyEmployee]="data.company" [imgRoute]="data?.imgRoute" [host]="data?.host" [company]="data?.company"></app-user>
            </mat-chip>
        </mat-chip-list>
        <span (click)="change()" *ngIf="!data.basedVideos && !data.file_ids">
            {{ "Change" | translate }}
        </span>
    </div>
</mat-dialog-title> -->
<div mat-dialog-title class="mobile_interface_title">
    <div class="mobile_interface_title_in" style="overflow: hidden;">
        <ng-container [ngSwitch]="selectedIndex.value">
            <ng-container *ngSwitchCase="0">
                <div class="tab_title" (click)="change()">
                    <div class="d-f-c">
                        <div class="icon_back">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7 12L6.11612 11.1161C5.62796 11.6043 5.62796 12.3957 6.11612 12.8839L7 12ZM16.8839 3.88388C17.372 3.39573 17.372 2.60427 16.8839 2.11612C16.3957 1.62796 15.6043 1.62796 15.1161 2.11612L16.8839 3.88388ZM15.1161 21.8839C15.6043 22.372 16.3957 22.372 16.8839 21.8839C17.372 21.3957 17.372 20.6043 16.8839 20.1161L15.1161 21.8839ZM7.88388 12.8839L16.8839 3.88388L15.1161 2.11612L6.11612 11.1161L7.88388 12.8839ZM6.11612 12.8839L15.1161 21.8839L16.8839 20.1161L7.88388 11.1161L6.11612 12.8839Z" fill="#232323"/>
                            </svg>
                        </div>
                        <span>
                            {{ "Add Card" | translate }}
                        </span>
                    </div>
                    <div class="small_title with_i">2 / 3 — {{ "SELECT TEMPLATE" | translate }}</div>
                </div>
            </ng-container>
            <ng-container *ngSwitchCase="1">
                <div class="tab_title" (click)="back()">
                    <div class="d-f-c">
                        <div class="icon_back">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7 12L6.11612 11.1161C5.62796 11.6043 5.62796 12.3957 6.11612 12.8839L7 12ZM16.8839 3.88388C17.372 3.39573 17.372 2.60427 16.8839 2.11612C16.3957 1.62796 15.6043 1.62796 15.1161 2.11612L16.8839 3.88388ZM15.1161 21.8839C15.6043 22.372 16.3957 22.372 16.8839 21.8839C17.372 21.3957 17.372 20.6043 16.8839 20.1161L15.1161 21.8839ZM7.88388 12.8839L16.8839 3.88388L15.1161 2.11612L6.11612 11.1161L7.88388 12.8839ZM6.11612 12.8839L15.1161 21.8839L16.8839 20.1161L7.88388 11.1161L6.11612 12.8839Z" fill="#232323"/>
                            </svg>
                        </div>
                        <span>
                            {{ activeTemplate?.name }}
                        </span>
                    </div>
                    <div class="small_title with_i">3 / {{ isAddConnections.value ? 4 : 3 }} — {{ "ADJUST TEMPLATE" | translate }}</div>
                </div>
            </ng-container>
            <ng-container *ngSwitchCase="2">
                <mat-tab-group class="title_tabs no_header" style="width: 100%;" [selectedIndex]="relationsIndex.value">
                    <mat-tab>
                        <div class="tab_title" (click)="back()">
                            <div class="d-f-c">
                                <div class="icon_back">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7 12L6.11612 11.1161C5.62796 11.6043 5.62796 12.3957 6.11612 12.8839L7 12ZM16.8839 3.88388C17.372 3.39573 17.372 2.60427 16.8839 2.11612C16.3957 1.62796 15.6043 1.62796 15.1161 2.11612L16.8839 3.88388ZM15.1161 21.8839C15.6043 22.372 16.3957 22.372 16.8839 21.8839C17.372 21.3957 17.372 20.6043 16.8839 20.1161L15.1161 21.8839ZM7.88388 12.8839L16.8839 3.88388L15.1161 2.11612L6.11612 11.1161L7.88388 12.8839ZM6.11612 12.8839L15.1161 21.8839L16.8839 20.1161L7.88388 11.1161L6.11612 12.8839Z" fill="#232323"/>
                                    </svg>
                                </div>
                                <span>
                                    {{ activeTemplate?.name }}
                                </span>
                            </div>
                            <span class="small_title with_i">4 / 4 — {{ "RELATIONS TO CARDS" | translate }}</span>
                        </div>
                    </mat-tab>
                    <mat-tab>
                        <div class="tab_title" (click)="backRelations()">
                            <div class="d-f-c">
                                <div class="icon_back">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7 12L6.11612 11.1161C5.62796 11.6043 5.62796 12.3957 6.11612 12.8839L7 12ZM16.8839 3.88388C17.372 3.39573 17.372 2.60427 16.8839 2.11612C16.3957 1.62796 15.6043 1.62796 15.1161 2.11612L16.8839 3.88388ZM15.1161 21.8839C15.6043 22.372 16.3957 22.372 16.8839 21.8839C17.372 21.3957 17.372 20.6043 16.8839 20.1161L15.1161 21.8839ZM7.88388 12.8839L16.8839 3.88388L15.1161 2.11612L6.11612 11.1161L7.88388 12.8839ZM6.11612 12.8839L15.1161 21.8839L16.8839 20.1161L7.88388 11.1161L6.11612 12.8839Z" fill="#232323"/>
                                    </svg>
                                </div>
                                <span>
                                    {{ "Consist of" | translate }}
                                </span>
                            </div>
                            <span class="small_title with_i">4 / 4 — {{ "RELATIONS TO CARDS" | translate }}</span>
                        </div>
                    </mat-tab>
                    <mat-tab>
                        <div class="tab_title" (click)="backRelations()">
                            <div class="d-f-c">
                                <div class="icon_back">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7 12L6.11612 11.1161C5.62796 11.6043 5.62796 12.3957 6.11612 12.8839L7 12ZM16.8839 3.88388C17.372 3.39573 17.372 2.60427 16.8839 2.11612C16.3957 1.62796 15.6043 1.62796 15.1161 2.11612L16.8839 3.88388ZM15.1161 21.8839C15.6043 22.372 16.3957 22.372 16.8839 21.8839C17.372 21.3957 17.372 20.6043 16.8839 20.1161L15.1161 21.8839ZM7.88388 12.8839L16.8839 3.88388L15.1161 2.11612L6.11612 11.1161L7.88388 12.8839ZM6.11612 12.8839L15.1161 21.8839L16.8839 20.1161L7.88388 11.1161L6.11612 12.8839Z" fill="#232323"/>
                                    </svg>
                                </div>
                                <span>
                                    {{ "Part of" | translate }}
                                </span>
                            </div>
                            <span class="small_title with_i">4 / 4 — {{ "RELATIONS TO CARDS" | translate }}</span>
                        </div>
                    </mat-tab>
                    <mat-tab>
                        <div class="tab_title" (click)="backRelations()">
                            <div class="d-f-c">
                                <div class="icon_back">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7 12L6.11612 11.1161C5.62796 11.6043 5.62796 12.3957 6.11612 12.8839L7 12ZM16.8839 3.88388C17.372 3.39573 17.372 2.60427 16.8839 2.11612C16.3957 1.62796 15.6043 1.62796 15.1161 2.11612L16.8839 3.88388ZM15.1161 21.8839C15.6043 22.372 16.3957 22.372 16.8839 21.8839C17.372 21.3957 17.372 20.6043 16.8839 20.1161L15.1161 21.8839ZM7.88388 12.8839L16.8839 3.88388L15.1161 2.11612L6.11612 11.1161L7.88388 12.8839ZM6.11612 12.8839L15.1161 21.8839L16.8839 20.1161L7.88388 11.1161L6.11612 12.8839Z" fill="#232323"/>
                                    </svg>
                                </div>
                                <span>
                                    {{ "Related" | translate }}
                                </span>
                            </div>
                            <span class="small_title with_i">4 / 4 — {{ "RELATIONS TO CARDS" | translate }}</span>
                        </div>
                    </mat-tab>
                    <mat-tab>
                        <div class="tab_title" (click)="backRelations()">
                            <div class="d-f-c">
                                <div class="icon_back">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7 12L6.11612 11.1161C5.62796 11.6043 5.62796 12.3957 6.11612 12.8839L7 12ZM16.8839 3.88388C17.372 3.39573 17.372 2.60427 16.8839 2.11612C16.3957 1.62796 15.6043 1.62796 15.1161 2.11612L16.8839 3.88388ZM15.1161 21.8839C15.6043 22.372 16.3957 22.372 16.8839 21.8839C17.372 21.3957 17.372 20.6043 16.8839 20.1161L15.1161 21.8839ZM7.88388 12.8839L16.8839 3.88388L15.1161 2.11612L6.11612 11.1161L7.88388 12.8839ZM6.11612 12.8839L15.1161 21.8839L16.8839 20.1161L7.88388 11.1161L6.11612 12.8839Z" fill="#232323"/>
                                    </svg>
                                </div>
                                <span>
                                    {{ "Card Thumbnail Settings" | translate }}
                                </span>
                            </div>
                            <span class="small_title with_i">4 / 4 — {{ "RELATIONS TO CARDS" | translate }}</span>
                        </div>
                    </mat-tab>
                    <mat-tab>
                        <div class="tab_title" (click)="smartBackRelations()">
                            <div class="d-f-c">
                                <div class="icon_back">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7 12L6.11612 11.1161C5.62796 11.6043 5.62796 12.3957 6.11612 12.8839L7 12ZM16.8839 3.88388C17.372 3.39573 17.372 2.60427 16.8839 2.11612C16.3957 1.62796 15.6043 1.62796 15.1161 2.11612L16.8839 3.88388ZM15.1161 21.8839C15.6043 22.372 16.3957 22.372 16.8839 21.8839C17.372 21.3957 17.372 20.6043 16.8839 20.1161L15.1161 21.8839ZM7.88388 12.8839L16.8839 3.88388L15.1161 2.11612L6.11612 11.1161L7.88388 12.8839ZM6.11612 12.8839L15.1161 21.8839L16.8839 20.1161L7.88388 11.1161L6.11612 12.8839Z" fill="#232323"/>
                                    </svg>
                                </div>
                                <span>
                                    {{ "Search within a group" | translate }}
                                </span>
                            </div>
                            <span class="small_title with_i">4 / 4 — {{ "RELATIONS TO CARDS" | translate }}</span>
                        </div>
                    </mat-tab>
                    <mat-tab>
                        <div class="tab_title" (click)="smartBackRelations()">
                            <div class="d-f-c">
                                <div class="icon_back">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7 12L6.11612 11.1161C5.62796 11.6043 5.62796 12.3957 6.11612 12.8839L7 12ZM16.8839 3.88388C17.372 3.39573 17.372 2.60427 16.8839 2.11612C16.3957 1.62796 15.6043 1.62796 15.1161 2.11612L16.8839 3.88388ZM15.1161 21.8839C15.6043 22.372 16.3957 22.372 16.8839 21.8839C17.372 21.3957 17.372 20.6043 16.8839 20.1161L15.1161 21.8839ZM7.88388 12.8839L16.8839 3.88388L15.1161 2.11612L6.11612 11.1161L7.88388 12.8839ZM6.11612 12.8839L15.1161 21.8839L16.8839 20.1161L7.88388 11.1161L6.11612 12.8839Z" fill="#232323"/>
                                    </svg>
                                </div>
                                <span>
                                    {{ "Where to search" | translate }}
                                </span>
                            </div>
                            <span class="small_title with_i">4 / 4 — {{ "RELATIONS TO CARDS" | translate }}</span>
                        </div>
                    </mat-tab>
         
                </mat-tab-group>
            </ng-container>
            <ng-container *ngSwitchDefault>
                <div class="d-f-c close_title">
                    <!-- <div class="icon_back" (click)="close()">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7 12L6.11612 11.1161C5.62796 11.6043 5.62796 12.3957 6.11612 12.8839L7 12ZM16.8839 3.88388C17.372 3.39573 17.372 2.60427 16.8839 2.11612C16.3957 1.62796 15.6043 1.62796 15.1161 2.11612L16.8839 3.88388ZM15.1161 21.8839C15.6043 22.372 16.3957 22.372 16.8839 21.8839C17.372 21.3957 17.372 20.6043 16.8839 20.1161L15.1161 21.8839ZM7.88388 12.8839L16.8839 3.88388L15.1161 2.11612L6.11612 11.1161L7.88388 12.8839ZM6.11612 12.8839L15.1161 21.8839L16.8839 20.1161L7.88388 11.1161L6.11612 12.8839Z" fill="#232323"/>
                        </svg>
                    </div> -->
                    <span>
                        {{ "Add Card" | translate }}
                    </span>
                </div>
            </ng-container>
        </ng-container>
        <div class="selected_company" *ngIf="relationsIndex.value == 0" (click)="change()">
            <app-user [class]="'user_div'" [companyEmployee]="data.company" [imgRoute]="data?.imgRoute" [host]="data?.host" [company]="data?.company"></app-user>
            <svg class="ml-1" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_2864_45355c1)">
                <path d="M7.91602 3.33333L2.08268 3.33333L4.99935 7.91666L7.91602 3.33333Z" fill="#686868"/>
                </g>
                <defs>
                <clipPath id="clip0_2864_45355c1">
                <rect width="10" height="10" fill="white" transform="translate(10) rotate(90)"/>
                </clipPath>
                </defs>
            </svg>
        </div>
    </div>

</div>
<mat-dialog-content class="mobile_interface_content">
    <mat-tab-group class="no_header" [selectedIndex]="selectedIndex.value" (selectedIndexChange)="changeIndex($event)">
        <mat-tab>
            <div class="mobile_scrollable_int">
                <div class="has_pads">
                    <div class="scenarios_list">
                        <div class="template_item" (click)="selectTmpl(tmpl)" *ngFor="let tmpl of data.taskTemplates;">
                            <div class="type_name cond">
                                {{ tmpl?.name }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-tab>
        <mat-tab>
            <div class="mobile_scrollable_int">
                <div class="form_with_valids has_pads">
                    <div class="white_wrp has_pads">
                        <ng-container *ngIf="!data.isMulti; else elseTemplateDataIsMulti">
                            <mat-form-field appearance="standard" class="full_width_form" *ngIf="!!activeTemplate">
                                <ng-container *ngIf="!activeTemplate.template_data.name && submited">
                                    <div class="req_field"></div>
                                    <div class="req_label">
                                        <div class="angle"></div>
                                        <span>
                                            {{ "You need to write here" | translate }}
                                        </span>
                                    </div>
                                </ng-container>
                                <mat-label>{{ 'Card title' | translate }}</mat-label>
                                <input [(ngModel)]="activeTemplate.template_data.name" matInput placeholder="{{ 'Name' | translate }}">
                            </mat-form-field>
                        </ng-container>
                        <ng-template #elseTemplateDataIsMulti>
                            <mat-form-field appearance="standard" class="full_width_form">
                                <mat-label>{{ 'Card title' | translate }}</mat-label>
                                <input style="color: red;" [disabled]="true" value="{{ 'Cards title will be equal to Video title' | translate }}" matInput placeholder="{{ 'Card title' | translate }}">
                            </mat-form-field>
                        </ng-template>
                    </div>
                </div>
                <div class="has_pads" style="padding-top: 0 !important;">
                    <div class="mob_white_check white_wrp">
                        <div class="box_wrp">
                            <mat-checkbox color="primary" [formControl]="isAddConnections">{{ "Set card relations onthe next step" | translate }}</mat-checkbox>
                        </div>
                    </div>
                </div>
            </div>
         
        </mat-tab>
        <mat-tab>
            <mat-tab-group class="no_header" [selectedIndex]="relationsIndex.value" (selectedIndexChange)="changeRelationsTab($event)">
                <mat-tab>
                    <div class="mobile_scrollable_int">
                        <div class="has_pads">
    
                            <div class="white_wrp has_pads">
                                <div class="form_switch has_active" (click)="changeRelationsTab(1); savedLvl2 = 1;">
                                    <div class="d-f-c" style="justify-content: space-between !important;">
                                        <div class="d-f-c">
                                            <div class="name">{{"Consist of" | translate}}</div>
                                        </div>
                                        <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0 10.59L4.59 6L0 1.41L1.42 0L7.42 6L1.42 12L0 10.59Z" fill="black"/>
                                        </svg>
                                    </div>
                                    <div class="active_values" *ngIf="templateForm.get('consist_of_task_id').get('add').value.length">
                                        <div class="task" *ngFor="let id of templateForm.get('consist_of_task_id').get('add').value; let i = index">
                                            <div class="d-f-c">
                                                <b>{{findTask(id)?.custom_id}}</b> 
                                                <span class="task_name text_one_line">{{ findTask(id).name }}</span>
                                            </div>
                                        </div>
                                        <div class="more_active" *ngIf="templateForm.get('consist_of_task_id').get('add').value.length > 3"> +{{ templateForm.get('consist_of_task_id').get('add').value.length - 3 }}</div>
                                    </div>
                                </div>
                                <div class="form_switch has_active" (click)="changeRelationsTab(2); savedLvl2 = 2;">
                                    <div class="d-f-c" style="justify-content: space-between !important;">
                                        <div class="d-f-c">
                                            <div class="name">{{"Part of" | translate}}</div>
                                        </div>
                                        <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0 10.59L4.59 6L0 1.41L1.42 0L7.42 6L1.42 12L0 10.59Z" fill="black"/>
                                        </svg>
                                    </div>
                                    <div class="active_values" *ngIf="templateForm.get('part_of_task_id').get('add').value.length">
                                        <div class="task" *ngFor="let id of templateForm.get('part_of_task_id').get('add').value; let i = index">
                                            <div class="d-f-c">
                                                <b>{{findTask(id)?.custom_id}}</b> 
                                                <span class="task_name text_one_line">{{ findTask(id).name }}</span>
                                            </div>
                                        </div>
                                        <div class="more_active" *ngIf="templateForm.get('part_of_task_id').get('add').value.length > 3"> +{{ templateForm.get('part_of_task_id').get('add').value.length - 3 }}</div>
                                    </div>
                                </div>
                                <div class="form_switch has_active" (click)="changeRelationsTab(3); savedLvl2 = 3;">
                                    <div class="d-f-c" style="justify-content: space-between !important;">
                                        <div class="d-f-c">
                                            <div class="name">{{"Related" | translate}}</div>
                                        </div>
                                        <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0 10.59L4.59 6L0 1.41L1.42 0L7.42 6L1.42 12L0 10.59Z" fill="black"/>
                                        </svg>
                                    </div>
                                    <div class="active_values" *ngIf="templateForm.get('related_task_id').get('add').value.length">
                                        <div class="task" *ngFor="let id of templateForm.get('related_task_id').get('add').value; let i = index">
                                            <div class="d-f-c">
                                                <b>{{findTask(id)?.custom_id}}</b> 
                                                <span class="task_name text_one_line">{{ findTask(id).name }}</span>
                                            </div>
                                        </div>
                                        <div class="more_active" *ngIf="templateForm.get('related_task_id').get('add').value.length > 3"> +{{ templateForm.get('related_task_id').get('add').value.length - 3 }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </mat-tab>
                <mat-tab>
                    <mat-tab-group class="connections_mobile_tabs no_header" [selectedIndex]="consistOfIndex.value" (selectedIndexChange)="changeConsistOfTab($event)">
                        <mat-tab>
                            <div class="mobile_scrollable_int">
                                <div class="has_pads">
                                    <div class="selected_tasks">
                                        <div class="task" *ngFor="let id of templateForm.get('consist_of_task_id').get('add').value; let i = index" (click)="taskActivatedId = id" [ngClass]="{'active': taskActivatedId == id}">
                                            <div class="task_in">
                                                <div class="d-f-c" style="width: calc(100% - 65px);">
                                                    <b>{{findTask(id)?.custom_id}}</b> 
                                                    <span class="task_name text_one_line">{{ findTask(id).name }}</span>
                                                </div>
    
                                                <div class="d-f-c" style="width: 60px;">
                                                    <div class="arrow_icon" (click)="moveDown(templateForm.get('consist_of_task_id').get('add').value, id, i)">
                                                        <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M17.6333 0.824987L10 8.45832L2.35 0.824987L0 3.17499L10 13.175L20 3.17499L17.6333 0.824987Z" fill="#A3A3A3"/>
                                                        </svg>                                                    
                                                    </div>
                                                    <div class="arrow_icon" (click)="moveUp(templateForm.get('consist_of_task_id').get('add').value, id, i)">
                                                        <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M2.36667 13.175L10 5.54168L17.65 13.175L20 10.825L10 0.825012L0 10.825L2.36667 13.175Z" fill="#A3A3A3"/>
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
    
                                            <div class="remove_task" *ngIf="taskActivatedId == id" (click)="onRemoved(id, 'consist_of_task_id')">
                                                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g clip-path="url(#clip0_2886_45854)">
                                                    <path d="M20.5827 6.94419L19.0552 5.41669L12.9993 11.4725L6.94352 5.41669L5.41602 6.94419L11.4718 13L5.41602 19.0559L6.94352 20.5834L12.9993 14.5275L19.0552 20.5834L20.5827 19.0559L14.5268 13L20.5827 6.94419Z" fill="#EDEDED"/>
                                                    </g>
                                                    <defs>
                                                    <clipPath id="clip0_2886_45854">
                                                    <rect width="26" height="26" fill="white"/>
                                                    </clipPath>
                                                    </defs>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </mat-tab>

                        <mat-tab>
                            <div class="d-f-c has_pads" style="justify-content: space-between !important; padding-bottom: 0 !important;">
                                <div class="mob_filter_btns" (click)="changeRelationsTab(5)">
                                    <span>
                                        {{ typeOfSearchControl.value }}
                                    </span>
                                    <svg class="ml-1" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_2864_45355c1)">
                                        <path d="M7.91602 3.33333L2.08268 3.33333L4.99935 7.91666L7.91602 3.33333Z" fill="#686868"/>
                                        </g>
                                        <defs>
                                        <clipPath id="clip0_2864_45355c1">
                                        <rect width="10" height="10" fill="white" transform="translate(10) rotate(90)"/>
                                        </clipPath>
                                        </defs>
                                    </svg>
                                </div>
        
                                <div class="mob_filter_btns" *ngIf="['Everywhere', 'Custom ID', 'Task Name'].includes(typeOfSearchControl.value)" (click)="changeRelationsTab(6)">
                                    <span>
                                        {{ groupOfSearchControl.value ? getGroupById(groupOfSearchControl.value)?.name : ("All groups" | translate) }}
                                    </span>
                                    <svg class="ml-1" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_2864_45355c1)">
                                        <path d="M7.91602 3.33333L2.08268 3.33333L4.99935 7.91666L7.91602 3.33333Z" fill="#686868"/>
                                        </g>
                                        <defs>
                                        <clipPath id="clip0_2864_45355c1">
                                        <rect width="10" height="10" fill="white" transform="translate(10) rotate(90)"/>
                                        </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                            </div>

                            <form [formGroup]="templateForm" class="has_pads form_with_valids mobile_scrollable_int_tasks">
                                <div class="white_wrp has_pads">
                                    <mat-form-field appearance="legacy" class="full_width_form">
                                        <mat-label>{{ 'Search' | translate }}</mat-label>
                                        <input placeholder="{{ 'Search' | translate }}" matInput #consistOfInput [formControl]="consistOfControl">
                                    </mat-form-field>
                                    <div class="task_results">
                                        <ng-container *ngFor="let task of tasks$ | async">
                                            <div class="task" (click)="selected(task.id, consistOfInput, 'consist_of_task_id'); changeConsistOfTab(0);" *ngIf="!templateForm.get('consist_of_task_id').get('add').value.includes(task.id) && (['Everywhere', 'Custom ID', 'Task Name'].includes(typeOfSearchControl.value) ? (groupOfSearchControl.value == task.group_id || groupOfSearchControl.value == '') : true)">
                                                <div class="d-f-c">
                                                    <span class="text_one_line">
                                                        {{ task.name }}
                                                    </span>
                                                    <div class="group" *ngIf="!!task?.group">
                                                        <span class="text_one_line">{{task?.group?.name}}</span>
                                                    </div>
                                                </div>
                                                <div class="task_ids">
                                                    <span>{{task.custom_id}}</span>
                                                    <span>{{task.id}}</span>
                                                </div>
                                            </div>
                                        </ng-container>
                                        
                                    </div>
                                </div>
                            </form>
                        </mat-tab>
                    </mat-tab-group>

                </mat-tab>
                <mat-tab>
                    <mat-tab-group class="connections_mobile_tabs no_header" [selectedIndex]="partOfIndex.value" (selectedIndexChange)="changePartOfTab($event)">
                        <mat-tab>
                            <div class="mobile_scrollable_int">
                                <div class="has_pads">
                                    <div class="selected_tasks">
                                        <div class="task" *ngFor="let id of templateForm.get('part_of_task_id').get('add').value; let i = index" (click)="taskActivatedId = id" [ngClass]="{'active': taskActivatedId == id}">
                                            <div class="task_in">
                                                <div class="d-f-c" style="width: calc(100% - 65px);">
                                                    <b>{{findTask(id)?.custom_id}}</b> 
                                                    <span class="task_name text_one_line">{{ findTask(id).name }}</span>
                                                </div>
        
                                                <div class="d-f-c" style="width: 60px;">
                                                    <div class="arrow_icon" (click)="moveDown(templateForm.get('part_of_task_id').get('add').value, id, i)">
                                                        <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M17.6333 0.824987L10 8.45832L2.35 0.824987L0 3.17499L10 13.175L20 3.17499L17.6333 0.824987Z" fill="#A3A3A3"/>
                                                        </svg>                                                    
                                                    </div>
                                                    <div class="arrow_icon" (click)="moveUp(templateForm.get('part_of_task_id').get('add').value, id, i)">
                                                        <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M2.36667 13.175L10 5.54168L17.65 13.175L20 10.825L10 0.825012L0 10.825L2.36667 13.175Z" fill="#A3A3A3"/>
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
        
                                            <div class="remove_task" *ngIf="taskActivatedId == id" (click)="onRemoved(id, 'part_of_task_id')">
                                                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g clip-path="url(#clip0_2886_45854)">
                                                    <path d="M20.5827 6.94419L19.0552 5.41669L12.9993 11.4725L6.94352 5.41669L5.41602 6.94419L11.4718 13L5.41602 19.0559L6.94352 20.5834L12.9993 14.5275L19.0552 20.5834L20.5827 19.0559L14.5268 13L20.5827 6.94419Z" fill="#EDEDED"/>
                                                    </g>
                                                    <defs>
                                                    <clipPath id="clip0_2886_45854">
                                                    <rect width="26" height="26" fill="white"/>
                                                    </clipPath>
                                                    </defs>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </mat-tab>
                        <mat-tab>
                            <div class="d-f-c has_pads" style="justify-content: space-between !important; padding-bottom: 0 !important;">
                                <div class="mob_filter_btns" (click)="changeRelationsTab(5)">
                                    <span>
                                        {{ typeOfSearchControl.value }}
                                    </span>
                                    <svg class="ml-1" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_2864_45355c1)">
                                        <path d="M7.91602 3.33333L2.08268 3.33333L4.99935 7.91666L7.91602 3.33333Z" fill="#686868"/>
                                        </g>
                                        <defs>
                                        <clipPath id="clip0_2864_45355c1">
                                        <rect width="10" height="10" fill="white" transform="translate(10) rotate(90)"/>
                                        </clipPath>
                                        </defs>
                                    </svg>
                                </div>
        
                                <div class="mob_filter_btns" *ngIf="['Everywhere', 'Custom ID', 'Task Name'].includes(typeOfSearchControl.value)" (click)="changeRelationsTab(6)">
                                    <span>
                                        {{ groupOfSearchControl.value ? getGroupById(groupOfSearchControl.value)?.name : ("All groups" | translate) }}
                                    </span>
                                    <svg class="ml-1" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_2864_45355c1)">
                                        <path d="M7.91602 3.33333L2.08268 3.33333L4.99935 7.91666L7.91602 3.33333Z" fill="#686868"/>
                                        </g>
                                        <defs>
                                        <clipPath id="clip0_2864_45355c1">
                                        <rect width="10" height="10" fill="white" transform="translate(10) rotate(90)"/>
                                        </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                            </div>

                            <form [formGroup]="templateForm" class="has_pads form_with_valids mobile_scrollable_int_tasks">
                                <div class="white_wrp has_pads">
                                    <mat-form-field appearance="legacy" class="full_width_form">
                                        <mat-label>{{ 'Search' | translate }}</mat-label>
                                        <input placeholder="{{ 'Search' | translate }}" matInput #partOfInput [formControl]="partOfControl">
                                    </mat-form-field>
                                    <div class="task_results">
                                        <ng-container *ngFor="let task of tasks$ | async">
                                            <div class="task" (click)="selected(task.id, partOfInput, 'part_of_task_id'); changePartOfTab(0);" *ngIf="!templateForm.get('part_of_task_id').get('add').value.includes(task.id) && (['Everywhere', 'Custom ID', 'Task Name'].includes(typeOfSearchControl.value) ? (groupOfSearchControl.value == task.group_id || groupOfSearchControl.value == '') : true)">
                                                <div class="d-f-c">
                                                    <span class="text_one_line">
                                                        {{ task.name }}
                                                    </span>
                                                    <div class="group" *ngIf="!!task?.group">
                                                        <span class="text_one_line">{{task?.group?.name}}</span>
                                                    </div>
                                                </div>
                                                <div class="task_ids">
                                                    <span>{{task.custom_id}}</span>
                                                    <span>{{task.id}}</span>
                                                </div>
                                            </div>
                                        </ng-container>
                                        
                                    </div>
                                </div>
                            </form>
                        </mat-tab>
                    </mat-tab-group>
                </mat-tab>
                <mat-tab>
                    <mat-tab-group class="connections_mobile_tabs no_header" [selectedIndex]="relatedIndex.value" (selectedIndexChange)="changeRelatedTab($event)">
                        <mat-tab>
                            <div class="mobile_scrollable_int">
                                <div class="has_pads">
                                    <div class="selected_tasks">
                                        <div class="task" *ngFor="let id of templateForm.get('related_task_id').get('add').value; let i = index" (click)="taskActivatedId = id" [ngClass]="{'active': taskActivatedId == id}">
                                            <div class="task_in">
                                                <div class="d-f-c" style="width: calc(100% - 65px);">
                                                    <b>{{findTask(id)?.custom_id}}</b> 
                                                    <span class="task_name text_one_line">{{ findTask(id).name }}</span>
                                                </div>
        
                                                <div class="d-f-c" style="width: 60px;">
                                                    <div class="arrow_icon" (click)="moveDown(templateForm.get('related_task_id').get('add').value, id, i)">
                                                        <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M17.6333 0.824987L10 8.45832L2.35 0.824987L0 3.17499L10 13.175L20 3.17499L17.6333 0.824987Z" fill="#A3A3A3"/>
                                                        </svg>                                                    
                                                    </div>
                                                    <div class="arrow_icon" (click)="moveUp(templateForm.get('related_task_id').get('add').value, id, i)">
                                                        <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M2.36667 13.175L10 5.54168L17.65 13.175L20 10.825L10 0.825012L0 10.825L2.36667 13.175Z" fill="#A3A3A3"/>
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
        
                                            <div class="remove_task" *ngIf="taskActivatedId == id" (click)="onRemoved(id, 'related_task_id')">
                                                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g clip-path="url(#clip0_2886_45854)">
                                                    <path d="M20.5827 6.94419L19.0552 5.41669L12.9993 11.4725L6.94352 5.41669L5.41602 6.94419L11.4718 13L5.41602 19.0559L6.94352 20.5834L12.9993 14.5275L19.0552 20.5834L20.5827 19.0559L14.5268 13L20.5827 6.94419Z" fill="#EDEDED"/>
                                                    </g>
                                                    <defs>
                                                    <clipPath id="clip0_2886_45854">
                                                    <rect width="26" height="26" fill="white"/>
                                                    </clipPath>
                                                    </defs>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </mat-tab>
                        <mat-tab>
                            <div class="d-f-c has_pads" style="justify-content: space-between !important; padding-bottom: 0 !important;">
                                <div class="mob_filter_btns" (click)="changeRelationsTab(5)">
                                    <span>
                                        {{ typeOfSearchControl.value }}
                                    </span>
                                    <svg class="ml-1" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_2864_45355c1)">
                                        <path d="M7.91602 3.33333L2.08268 3.33333L4.99935 7.91666L7.91602 3.33333Z" fill="#686868"/>
                                        </g>
                                        <defs>
                                        <clipPath id="clip0_2864_45355c1">
                                        <rect width="10" height="10" fill="white" transform="translate(10) rotate(90)"/>
                                        </clipPath>
                                        </defs>
                                    </svg>
                                </div>
        
                                <div class="mob_filter_btns" *ngIf="['Everywhere', 'Custom ID', 'Task Name'].includes(typeOfSearchControl.value)" (click)="changeRelationsTab(6)">
                                    <span>
                                        {{ groupOfSearchControl.value ? getGroupById(groupOfSearchControl.value)?.name : ("All groups" | translate) }}
                                    </span>
                                    <svg class="ml-1" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_2864_45355c1)">
                                        <path d="M7.91602 3.33333L2.08268 3.33333L4.99935 7.91666L7.91602 3.33333Z" fill="#686868"/>
                                        </g>
                                        <defs>
                                        <clipPath id="clip0_2864_45355c1">
                                        <rect width="10" height="10" fill="white" transform="translate(10) rotate(90)"/>
                                        </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                            </div>
                            <form [formGroup]="templateForm" class="has_pads form_with_valids mobile_scrollable_int_tasks">
                                <div class="white_wrp has_pads">
                                    <mat-form-field appearance="legacy" class="full_width_form">
                                        <mat-label>{{ 'Search' | translate }}</mat-label>
                                        <input placeholder="{{ 'Search' | translate }}" matInput #relatedInput [formControl]="relativeControl">
                                    </mat-form-field>
        
                                    <div class="task_results">
                                        <ng-container *ngFor="let task of tasks$ | async">
                                            <div class="task" (click)="selected(task.id, relatedInput, 'related_task_id'); changeRelatedTab(0);" *ngIf="!templateForm.get('related_task_id').get('add').value.includes(task.id) && (['Everywhere', 'Custom ID', 'Task Name'].includes(typeOfSearchControl.value) ? (groupOfSearchControl.value == task.group_id || groupOfSearchControl.value == '') : true)">
                                                <div class="d-f-c">
                                                    <span class="text_one_line">
                                                        {{ task.name }}
                                                    </span>
                                                    <div class="group" *ngIf="!!task?.group">
                                                        <span class="text_one_line">{{task?.group?.name}}</span>
                                                    </div>
                                                </div>
                                                <div class="task_ids">
                                                    <span>{{task.custom_id}}</span>
                                                    <span>{{task.id}}</span>
                                                </div>
                                            </div>
                                        </ng-container>
                                        
                                    </div>
                                </div>
                            </form>
                       
                        </mat-tab>
                    </mat-tab-group>
                </mat-tab>
                <mat-tab>
                    <div class="mobile_scrollable_int">
                        <div class="has_pads">
                            <div class="group_chips small_chips" *ngIf="groups?.length > 0">
                                <mat-chip-list [multiple]="false" selectable [formControl]="typeOfSearchControl">
                                    <mat-chip class="small_chip" (click)="selectTypeSearch('Everywhere')" value="Everywhere">Everywhere</mat-chip>
                                    <mat-chip class="small_chip" (click)="selectTypeSearch('Custom ID')" value="Custom ID">Custom ID</mat-chip>
                                    <mat-chip class="small_chip" (click)="selectTypeSearch('Internal ID')" value="Internal ID">Internal ID</mat-chip>
                                    <mat-chip class="small_chip" (click)="selectTypeSearch('Global ID')" value="Global ID">Global ID</mat-chip>
                                    <mat-chip class="small_chip" (click)="selectTypeSearch('Task Name')" value="Task Name">Task Name</mat-chip>
                                </mat-chip-list>
                            </div>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab>
                    <div class="mobile_scrollable_int">
                        <div class="has_pads">
                            <div class="group_chips small_chips" *ngIf="groups?.length > 0" style="padding: 0 !important;">
                                <mat-chip-list [multiple]="false" selectable [formControl]="groupOfSearchControl" style="padding: 0 !important;">
                                    <mat-chip style="margin-top: 28px !important;" class="small_chip" (click)="selectGroupSearch('')" [value]="''">
                                        {{ "All groups" | translate }}
                                    </mat-chip>
                                    <ng-container *ngFor="let group of groups; let i = index">
                                        <mat-chip style="margin-top: 28px !important;" class="small_chip" (click)="selectGroupSearch(group.id)" [value]="group?.id">
                                            {{ group.name | translate }}
                                        </mat-chip>
                                        <mat-chip style="margin-top: 28px !important;" *ngIf="i == groups?.length - 1 && i > 6" class="small_chip more" (click)="groupIdContainerSearch.open()">
                                            {{ "More..." | translate }}
                                        </mat-chip>
                                    </ng-container>
                                </mat-chip-list>
    
                                <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                                    <mat-select #groupIdContainerSearch [formControl]="groupOfSearchControl" (selectionChange)="selectGroupSearch($event.value)">
                                        <mat-option>
                                            <ngx-mat-select-search 
                                            placeholderLabel="{{ 'Search' | translate }}" 
                                            noEntriesFoundLabel="{{ 'no matching group found' | translate }}"
                                            [formControl]="groupMoreControl"></ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option [value]="''">
                                            {{ "All groups" | translate }}
                                        </mat-option>
                                        <mat-option *ngFor="let group of groups$ | async" [value]="group.id">
                                            {{ group.name | translate }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </mat-tab>
    </mat-tab-group>
</mat-dialog-content>


<mat-dialog-actions>
    <div class="mobile_interface_buttons" style="justify-content: space-between !important;">
        <ng-container *ngIf="relationsIndex.value == 0; else elseTemplateDownBtn">
            <button class="mobile_interface_btn" (click)="change()" *ngIf="selectedIndex.value == 0">
                {{ "Cancel" | translate }}
            </button>
            <button class="mobile_interface_btn" (click)="close()" *ngIf="selectedIndex.value != 0">
                {{ "Cancel" | translate }}
            </button>
        
            <div class="d-f-c">
                <button class="mobile_interface_btn" (click)="back()" style="padding: 8px 12px !important;" *ngIf="selectedIndex.value != 0">
                    {{ "Back" | translate }}
                </button>
                <button class="mobile_interface_btn mobile_interface_btn_blue ml-1" (click)="next(selectedIndex.value)" *ngIf="selectedIndex.value == 1">
                    {{ (isAddConnections.value ? "Next" : "Finish") | translate }}
                </button>
                <button class="mobile_interface_btn mobile_interface_btn_blue ml-1" (click)="next(selectedIndex.value)" *ngIf="selectedIndex.value == 2">
                    {{ "Finish" | translate }}
                </button>
            </div>
        </ng-container>
        <ng-template #elseTemplateDownBtn>
            <ng-container [ngSwitch]="relationsIndex.value">
                <ng-container *ngSwitchCase="1">
                    <ng-container *ngIf="consistOfIndex.value == 0; else elseTemplateIndex1">
                        <button class="mobile_interface_btn" (click)="backRelations()">
                            {{ "Back" | translate }}
                        </button>
                        
                        <button class="mobile_interface_btn mobile_interface_btn_blue" (click)="changeConsistOfTab(1)">
                            {{ "Add more consist of cards" | translate }}
                        </button>
                    </ng-container>
                    <ng-template #elseTemplateIndex1>
                        <button class="mobile_interface_btn" (click)="changeConsistOfTab(0)">
                            {{ "Cancel" | translate }}
                        </button>

                        <button class="mobile_interface_btn" (click)="changeConsistOfTab(0)">
                            {{ "Back" | translate }}
                        </button>
                    </ng-template>
                    
                </ng-container>
                <ng-container *ngSwitchCase="2">
                    <ng-container *ngIf="partOfIndex.value == 0; else elseTemplateIndex2">
                        <button class="mobile_interface_btn" (click)="backRelations()">
                            {{ "Back" | translate }}
                        </button>

                        <button class="mobile_interface_btn mobile_interface_btn_blue" (click)="changePartOfTab(1)">
                            {{ "Add more part of cards" | translate }}
                        </button>
                    </ng-container>
                    <ng-template #elseTemplateIndex2>
                        <button class="mobile_interface_btn" (click)="changePartOfTab(0)">
                            {{ "Cancel" | translate }}
                        </button>

                        <button class="mobile_interface_btn" (click)="changePartOfTab(0)">
                            {{ "Back" | translate }}
                        </button>
                    </ng-template>
                    
                </ng-container>
                <ng-container *ngSwitchCase="3">
                    <ng-container *ngIf="relatedIndex.value == 0; else elseTemplateIndex3">
                        <button class="mobile_interface_btn" (click)="backRelations()">
                            {{ "Back" | translate }}
                        </button>

                        <button class="mobile_interface_btn mobile_interface_btn_blue" (click)="changeRelatedTab(1)">
                            {{ "Add more related of cards" | translate }}
                        </button>
                    </ng-container>
                    <ng-template #elseTemplateIndex3>
                        <button class="mobile_interface_btn" (click)="changeRelatedTab(0)">
                            {{ "Cancel" | translate }}
                        </button>

                        <button class="mobile_interface_btn" (click)="changeRelatedTab(0)">
                            {{ "Back" | translate }}
                        </button>
                    </ng-template>
                    
                </ng-container>
                <ng-container *ngSwitchCase="4">
                    <div>

                    </div>
                    <button class="mobile_interface_btn" (click)="backRelations()">
                        {{ "Back" | translate }}
                    </button>
                </ng-container>
                <ng-container *ngSwitchCase="5">
                    <div>

                    </div>
                    <button class="mobile_interface_btn" (click)="smartBackRelations()">
                        {{ "Back" | translate }}
                    </button>
                </ng-container>
                <ng-container *ngSwitchCase="6">
                    <div>

                    </div>
                    <button class="mobile_interface_btn" (click)="smartBackRelations()">
                        {{ "Back" | translate }}
                    </button>
                </ng-container>
            </ng-container>
        </ng-template>
    </div>

        <!-- <div class="f_w_p_btns">
        <ng-container *ngIf="!templatesIsActive; else elseTemplateTempatesActive">
            <button mat-button (click)="close()">
                {{ "Close" | translate }}
            </button>
            <button class="ml-1 d-f-btn" (click)="submitForm()" mat-raised-button cdkFocusInitial color="primary" [disabled]="!!isSubmit">
                <mat-spinner class="spin_16 mr-1" *ngIf="isSubmit"></mat-spinner>  
                {{ "Save" | translate }}
            </button>
            <button *ngIf="!data.basedVideos && !!taskTemplates && taskTemplates.length > 0" class="ml-1" (click)="toggleTemplates()" mat-button cdkFocusInitial color="warn" [disabled]="!!isSubmit">
                {{ "Create from template" | translate }}
            </button>
        </ng-container>
        <ng-template #elseTemplateTempatesActive>
            <button mat-button (click)="close()">
                {{ "Close" | translate }}
            </button>
            <button class="ml-1" mat-button color="warn" (click)="toggleTemplates()">
                {{ "Back to default creation" | translate }}
            </button>
        </ng-template>
    </div> -->
</mat-dialog-actions>


<!-- 
        <ng-template #elseTemplateTempatesActiveForm>
            <div class="scenarios_list">

                <div class="template_item" (click)="selectTmpl(tmpl)" *ngFor="let tmpl of taskTemplates;">
                    <div class="type_name">
                        {{ tmpl?.name }}
                    </div>
                    <div class="tmpl_settings" *ngIf="tmpl.active">

                        <div class="form_with_valids full_width">
                            <mat-form-field appearance="standard" class="full_width_form">
                                <ng-container *ngIf="tmpl.template_data.name == '' && submitedTmpl">
                                    <div class="req_field"></div>
                                    <div class="req_label">
                                        <div class="angle"></div>
                                        <span>
                                            {{ "You need to write here" | translate }}
                                        </span>
                                    </div>
                                </ng-container>
                                <mat-label>{{ 'Task title' | translate }}</mat-label>
                                <input [(ngModel)]="tmpl.template_data.name" matInput placeholder="{{ 'Name' | translate }}">
                            </mat-form-field>
                        </div>

                        <button class="ml-1 d-f-btn" (click)="createTaskByTemplate(tmpl)" mat-raised-button cdkFocusInitial color="primary" [disabled]="!!isSubmitTmpl">
                            <mat-spinner class="spin_16 mr-1" *ngIf="isSubmitTmpl"></mat-spinner>  
                            {{ "Create task" | translate }}
                        </button>
                    </div>
                </div>

                <form [formGroup]="templateForm">

                    <div class="white_block white_expanded no_m_l_r">
                        <div class="d-f-c" (click)="openRelations()">
                            <div class="white_block_title">
                                {{ "Assign relations to other task(s)" | translate }}
                            </div>
        
                            <button mat-icon-button>
                                <mat-icon *ngIf="relations">expand_less</mat-icon>
                                <mat-icon *ngIf="!relations">expand_more</mat-icon>
                            </button>
                        </div>
                        <div class="white_inside" [ngClass]="{'open': relations}">
                            <div class="search_type">
                                <mat-button-toggle-group appearance="legacy" [formControl]="typeOfSearchControl">
                                    <mat-button-toggle value="Everywhere">Everywhere</mat-button-toggle>
                                    <mat-button-toggle value="Custom ID">Custom ID</mat-button-toggle>
                                    <mat-button-toggle value="Internal ID">Internal ID</mat-button-toggle>
                                    <mat-button-toggle value="Global ID">Global ID</mat-button-toggle>
                                    <mat-button-toggle value="Task Name">Task Name</mat-button-toggle>
                                </mat-button-toggle-group>
        
                                <mat-form-field appearance="legacy" class="ml-1" *ngIf="['Everywhere', 'Custom ID', 'Task Name'].includes(typeOfSearchControl.value)">
                                    <mat-label>{{ "Group" | translate }}</mat-label>
                                    <mat-select #groupIdContainer [formControl]="groupOfSearchControl">
                                        <mat-option>
                                            <ngx-mat-select-search 
                                            placeholderLabel="{{ 'Search' | translate }}" 
                                            noEntriesFoundLabel="{{ 'no matching group found' | translate }}"
                                            [formControl]="groupMoreControl"></ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option [value]="''">
                                            {{ 'All' | translate }}
                                        </mat-option>
                                        <mat-option *ngFor="let group of groups$ | async" [value]="group.id">
                                            {{ group.name | translate }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
        
                            <ng-container formGroupName="consist_of_task_id">
                                <mat-form-field appearance="standard" class="full_width_form">
                                    <mat-label>{{ "Consist of" | translate }}</mat-label>
        
                                    <div class="chips-boundary consist_boundary">
                                        <mat-chip-list class="sorted_chips without_ordered" #consistOfChipListTemplate formControlName="add">
                                            <div class="chips_sorter_container" cdkDropListGroup #consistGroupTemplate>
                                              
                                                <div class="chips_drag_placeholder" cdkDropList (cdkDropListEntered)="entered($event, consistGroupTemplate, consistPlaceholderTemplate)" (cdkDropListDropped)="dropped(consistGroupTemplate, consistPlaceholderTemplate, form.get('consist_of_task_id').get('add').value)" #consistPlaceholderTemplate></div>
        
                                                <div *ngFor="let id of templateForm.get('consist_of_task_id').get('add').value"  
                                                cdkDropList
                                                class="sorted_chips_drop_list"
                                                (cdkDropListEntered)="entered($event, consistGroupTemplate, consistPlaceholderTemplate)"
                                                (cdkDropListDropped)="dropped(consistGroupTemplate, consistPlaceholderTemplate, templateForm.get('consist_of_task_id').get('add').value)">
                                                    <div cdkDrag cdkDragBoundary=".consist_boundary" class="sorted_chip" (cdkDragReleased)="dragReleased(consistPlaceholderTemplate, consistDragPlaceholderTemplate)">
                                                        <div class="sorted_chip_custom_placeholder" #consistDragPlaceholderTemplate *cdkDragPlaceholder></div>
                                                        <mat-chip [removable]="true" color="primary" (removed)="onRemovedTemplate(id, 'consist_of_task_id')">
                                                            <ng-container *ngIf="findTask(id)">
                                                                {{ findTask(id).name.length > 25 ? (findTask(id).name | slice:0:25) + " ..." : findTask(id).name }} ({{findTask(id)?.custom_id}})
                                                            </ng-container>
                                                            <mat-icon matChipRemove>cancel</mat-icon>
                                                        </mat-chip>
                                                    </div>
        
                                                </div>
        
                                                <input class="auto_complete_input"
                                                placeholder="{{ 'Search' | translate }}"
                                                #consistOfInputTemplate
                                                [formControl]="consistOfControlTemplate"
                                                [matAutocomplete]="consistOfAutoTemplate"
                                                [matChipInputFor]="consistOfChipListTemplate"
                                                [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
                                                
                                            </div>
                                        </mat-chip-list>
                                    </div>
        
                                    <mat-autocomplete autoActiveFirstOption #consistOfAutoTemplate="matAutocomplete" (optionSelected)="selectedTemplate($event, consistOfInputTemplate, 'consist_of_task_id')">
                                        <ng-container *ngFor="let task of tasks$ | async">
                                            <mat-option *ngIf="!templateForm.get('consist_of_task_id').get('add').value.includes(task.id) && (['Everywhere', 'Custom ID', 'Task Name'].includes(typeOfSearchControl.value) ? (groupOfSearchControl.value == task.group_id || groupOfSearchControl.value == '') : true)" [value]="task.id">
                                                <div class="task_ids_wrp_out">
                                                    <div class="task_ids_wrp"> 
                                                        <span class="text_one_line">
                                                            {{ task.name }}
                                                        </span>
                                                        <div class="task_ids">
                                                            {{task.custom_id}} 
                                                            <span>
                                                                <div>{{task.internal_id}}</div>
                                                                <div>{{task.id}}</div>
                                                            </span>
                                                        </div>
                                                    </div>
        
                                                    <div class="group text_one_line">
                                                        {{task?.group?.name}}
                                                    </div>
                                                </div>
                                            </mat-option>
                                        </ng-container>
                                        
                                    </mat-autocomplete>
                                </mat-form-field>
                            </ng-container>
                            
                            <ng-container formGroupName="part_of_task_id">
                                <mat-form-field appearance="standard" class="full_width_form">
                                    <mat-label>{{ "Part of" | translate }}</mat-label>
        
                                    <div class="chips-boundary part_boundary">
                                        <mat-chip-list class="sorted_chips without_ordered" #partOfChipListTemplate formControlName="add">
                                            <div class="chips_sorter_container" cdkDropListGroup #partGroupTemplate>
                                              
                                                <div class="chips_drag_placeholder" cdkDropList (cdkDropListEntered)="entered($event, partGroupTemplate, partPlaceholderTemplate)" (cdkDropListDropped)="dropped(partGroupTemplate, partPlaceholderTemplate, templateForm.get('part_of_task_id').get('add').value)" #partPlaceholderTemplate></div>
        
                                                <div *ngFor="let id of templateForm.get('part_of_task_id').get('add').value"  
                                                cdkDropList
                                                class="sorted_chips_drop_list"
                                                (cdkDropListEntered)="entered($event, partGroupTemplate, partPlaceholderTemplate)"
                                                (cdkDropListDropped)="dropped(partGroupTemplate, partPlaceholderTemplate, templateForm.get('part_of_task_id').get('add').value)">
                                                    <div cdkDrag cdkDragBoundary=".part_boundary" class="sorted_chip" (cdkDragReleased)="dragReleased(partPlaceholderTemplate, partDragPlaceholderTemplate)">
                                                    <div class="sorted_chip_custom_placeholder" #partDragPlaceholderTemplate *cdkDragPlaceholder></div>
                                                    <mat-chip [removable]="true" color="primary" (removed)="onRemovedTemplate(id, 'part_of_task_id')">
                                                        <ng-container *ngIf="findTask(id)">
                                                            {{ findTask(id).name.length > 25 ? (findTask(id).name | slice:0:25) + " ..." : findTask(id).name }} ({{findTask(id)?.custom_id}})
                                                        </ng-container>
                                                        <mat-icon matChipRemove>cancel</mat-icon>
                                                    </mat-chip>
                                                    </div>
        
                                                </div>
        
                                                <input class="auto_complete_input"
                                                placeholder="{{ 'Search' | translate }}"
                                                #partOfInputTemplate
                                                [formControl]="partOfControlTemplate"
                                                [matAutocomplete]="partOfAutoTemplate"
                                                [matChipInputFor]="partOfChipListTemplate"
                                                [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
                                                
                                            </div>
                                        </mat-chip-list>
                                    </div>
        
                                    <mat-autocomplete autoActiveFirstOption #partOfAutoTemplate="matAutocomplete" (optionSelected)="selectedTemplate($event, partOfInputTemplate, 'part_of_task_id')">
                                        <ng-container *ngFor="let task of tasks$ | async">
                                            <mat-option *ngIf="!templateForm.get('part_of_task_id').get('add').value.includes(task.id) && (['Everywhere', 'Custom ID', 'Task Name'].includes(typeOfSearchControl.value) ? (groupOfSearchControl.value == task.group_id || groupOfSearchControl.value == '') : true)" [value]="task.id">
                                                <div class="task_ids_wrp_out">
                                                    <div class="task_ids_wrp"> 
                                                        <span class="text_one_line">
                                                            {{ task.name }}
                                                        </span>
                                                        <div class="task_ids">
                                                            {{task.custom_id}} 
                                                            <span>
                                                                <div>{{task.internal_id}}</div>
                                                                <div>{{task.id}}</div>
                                                            </span>
                                                        </div>
                                                    </div>
        
                                                    <div class="group text_one_line">
                                                        {{task?.group?.name}}
                                                    </div>
                                                </div>
                                            </mat-option>
                                        </ng-container>
                                        
                                    </mat-autocomplete>
                                </mat-form-field>
                            </ng-container>
        
                            <ng-container formGroupName="related_task_id">
                                <mat-form-field appearance="standard" class="full_width_form">
                                    <mat-label>{{ "Related" | translate }}</mat-label>
        
                                    <div class="chips-boundary related_boundary">
                                        <mat-chip-list class="sorted_chips without_ordered" #relatedOfChipListTemplate formControlName="add">
                                            <div class="chips_sorter_container" cdkDropListGroup #relatedGroupTemplate>
                                              
                                                <div class="chips_drag_placeholder" cdkDropList (cdkDropListEntered)="entered($event, relatedGroupTemplate, relatedPlaceholderTemplate)" (cdkDropListDropped)="dropped(relatedGroupTemplate, relatedPlaceholderTemplate, templateForm.get('related_task_id').get('add').value)" #relatedPlaceholderTemplate></div>
        
                                                <div *ngFor="let id of templateForm.get('related_task_id').get('add').value"  
                                                cdkDropList
                                                class="sorted_chips_drop_list"
                                                (cdkDropListEntered)="entered($event, relatedGroupTemplate, relatedPlaceholderTemplate)"
                                                (cdkDropListDropped)="dropped(relatedGroupTemplate, relatedPlaceholderTemplate, templateForm.get('related_task_id').get('add').value)">
                                                    <div cdkDrag cdkDragBoundary=".related_boundary" class="sorted_chip" (cdkDragReleased)="dragReleased(relatedPlaceholderTemplate, relatedDragPlaceholder)">
                                                        <div class="sorted_chip_custom_placeholder" #relatedDragPlaceholder *cdkDragPlaceholder></div>
                                                        <mat-chip [removable]="true" color="primary" (removed)="onRemovedTemplate(id, 'related_task_id')">
                                                            <ng-container *ngIf="findTask(id)">
                                                                {{ findTask(id).name.length > 25 ? (findTask(id).name | slice:0:25) + " ..." : findTask(id).name }} ({{findTask(id)?.custom_id}})
                                                            </ng-container>
                                                            <mat-icon matChipRemove>cancel</mat-icon>
                                                        </mat-chip>
                                                    </div>
        
                                                </div>
        
                                                <input class="auto_complete_input"
                                                placeholder="{{ 'Search' | translate }}"
                                                #relatedOfInputTemplate
                                                [formControl]="relativeControlTemplate"
                                                [matAutocomplete]="relatedOfAutoTemplate"
                                                [matChipInputFor]="relatedOfChipListTemplate"
                                                [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
                                                
                                            </div>
                                        </mat-chip-list>
                                    </div>
        
                                    <mat-autocomplete autoActiveFirstOption #relatedOfAutoTemplate="matAutocomplete" (optionSelected)="selectedTemplate($event, relatedOfInputTemplate, 'related_task_id')">
                                        <ng-container *ngFor="let task of tasks$ | async">
                                            <mat-option *ngIf="!templateForm.get('related_task_id').get('add').value.includes(task.id) && (['Everywhere', 'Custom ID', 'Task Name'].includes(typeOfSearchControl.value) ? (groupOfSearchControl.value == task.group_id || groupOfSearchControl.value == '') : true)" [value]="task.id">
                                                <div class="task_ids_wrp_out">
                                                    <div class="task_ids_wrp"> 
                                                        <span class="text_one_line">
                                                            {{ task.name }}
                                                        </span>
                                                        <div class="task_ids">
                                                            {{task.custom_id}} 
                                                            <span>
                                                                <div>{{task.internal_id}}</div>
                                                                <div>{{task.id}}</div>
                                                            </span>
                                                        </div>
                                                    </div>
        
                                                    <div class="group text_one_line">
                                                        {{task?.group?.name}}
                                                    </div>
                                                </div>
                                            </mat-option>
                                        </ng-container>
                                        
                                    </mat-autocomplete>
                                </mat-form-field>
                            </ng-container>
                        </div>
                    </div>
                </form>
            </div>
        </ng-template>  
    </div>
</mat-dialog-content> -->
