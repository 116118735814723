import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { debounceTime, filter, last, map, switchMap, tap } from 'rxjs/operators';
import { DeleteAlertComponent } from 'src/app/shared/global_components/delete-alert/delete-alert.component';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { AuthService } from 'src/app/shared/services/rest/auth.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { ScenariosService } from 'src/app/shared/services/rest/scenarios.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { environment } from 'src/environments/environment';
import { AddAndEditTaskTemplateComponent } from './dialogs/add-and-edit-task-template/add-and-edit-task-template.component';
import { AddAutomationTemplateComponent } from './dialogs/add-automation-template/add-automation-template.component';
import { AutoComponent } from './dialogs/auto/auto.component';
import { TaskTemplateExecutorsComponent } from './dialogs/task-template-executors/task-template-executors.component';
import { ScenarioToGroupComponent } from './dialogs/scenario-to-group/scenario-to-group.component';
import { AddCardAutomationTemplateComponent } from './dialogs/add-card-automation-template/add-card-automation-template.component';
import { AutoSchemeComponent } from './dialogs/auto-scheme/auto-scheme.component';
import { ScenEcexecutorsComponent } from './dialogs/scen-ecexecutors/scen-ecexecutors.component';
import { AutoTagComponent } from './dialogs/auto-tag/auto-tag.component';
import { forkJoin } from 'rxjs';
import { ParametersService } from 'src/app/shared/services/rest/parameters.service';
@Component({
  selector: 'app-scenarios',
  templateUrl: './scenarios.component.html',
  styleUrls: ['./scenarios.component.scss']
})
export class ScenariosComponent extends BaseClass implements OnInit, OnDestroy {
  throttle = 100;
  scrollUpDistance = 3.5;
  public host: any = environment.host;
  public imgRoute: any = '';
  public user: any = {};
  public user_id: number;
  public activeLang: any;
  public company_id: any;
  public company: any;
  public templates: any;
  public groups: any;
  public cardTemplates: any;
  public autoTemplates: any;
  public autoTags: any;
  public taskTemplates: any;
  public isLoad: boolean = false;
  public isLoadTemplates: boolean = false;
  public isLoadCardTemplates: boolean = false;
  public isLoadTaskTemplates: boolean = false;
  public page: number = 1;
  public cardPage: number = 1;
  public taskPage: number = 1;
  public autoPage: number = 1;
  public autoTagsPage: number = 1;
  public selectedIndex: FormControl = new FormControl(0);
  public operationsValues: any;
  public employees: any;
  public allValues: any;
  public pagination: any;
  public cardPagination: any;
  public autoPagination: any;
  public autoTagsPagination: any;
  public taskPagination: any;

  public taskTemplatesFilter: FormGroup = this.fb.group({
    q: '',
    company_id: ''
  })

  public cardTemplatesFilter: FormGroup = this.fb.group({
    q: '',
    company_id: '',
    is_task: 1,
  })

  public templatesFilter: FormGroup = this.fb.group({
    q: '',
    company_id: '',
    is_task_operation: 1,
  })

  public autoTemplatesFilter: FormGroup = this.fb.group({
    operation_id: '',
    employee_id: '',
    parameter_value_id: ''
  })

  public folders: any[] = [
    {
      name: "Root",
      id: -1,
      location: "/",
      over: false,
      rootDir: true
    },
    {
      name: "Sources",
      id: 0,
      location: "/sources",
      over: false,
      rootDir: true
    },
    {
      name: "To Approve",
      id: 1,
      location: "/to_approve",
      over: false,
      rootDir: true
    },
    {
      name: "Ready Files",
      id: 2,
      location: "/ready_files",
      over: false,
      rootDir: true
    },
    {
      name: "Approved",
      id: 3,
      location: "/approved",
      over: false,
      rootDir: true
    },
    {
      name: "Project",
      id: 4,
      location: "/project",
      over: false,
      rootDir: true
    },
    {
      name: "Trash",
      id: 5,
      location: "/trash",
      over: false,
      rootDir: true
    }
  ]

  public types: any[] = [
    {
      tab: 'card_templates'
    },
    {
      tab: 'card_scenario_templates'
    },
    {
      tab: 'job_scenario_templates'
    },
    {
      tab: 'automation_of_assigning_scenarios'
    },
    {
      tab: 'automation_of_tag_assigning'
    }
  ]

  constructor(
    public activatedRoute: ActivatedRoute,
    private sm: StorageManagerService,
    private auth: AuthService,
    private parametersService: ParametersService,
    private languageService: LanguageService,
    private taskService: TaskService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private companyService: CompanyService,
    private bottomSheet: MatBottomSheet,
    private layoutService: LayoutService,
    private router: Router,
    private scenariosService: ScenariosService,
  ) { 
    super()
  }

  ngOnInit(): void {
    this.company_id = this.activatedRoute.snapshot.queryParamMap.get('company_id');

    this.getLangData();
    this.getImgRoute();
    this.getCsrf();
    this.getUser();
    this.getCompany();
    this.getOperations();
    this.getEmployees();
    this.getAllApiParameterValues();
    this.getGroupsCompany();

    // this.getAutos(this.autoPage);

    // route change
    this.onRouteChange();
  }

  changeTab(e) {
    this.selectedIndex.patchValue(e);

    let params = {...this.activatedRoute.queryParamMap.source['_value']};
    params.tab = this.types[e].tab;
    this.router.navigate(['/settings'], {queryParams: params});
  }

  removeValue(val) {
    if (val == "all") {
      let params = {
        company_id: this.company_id,
        page: this.activatedRoute.snapshot.queryParamMap.get('page'),
        tab: this.activatedRoute.snapshot.queryParamMap.get('tab')
      }
      this.router.navigate(['/settings'], {queryParams: params})
    } else {
      let params = {...this.activatedRoute.queryParamMap.source['_value']}
      delete params[val]

      this.router.navigate(['/settings'], {queryParams: params})
    }
  }

  getEmployee(id) {
    if (!this.employees) {
      return false;
    }
    return this.employees.find(el => el.id == id)
  }

  getValueById(id) {
    if (!this.allValues || this.allValues.length == 0) {
      return null
    }
    return this.allValues.find(x => x.id == id)
  }

  getAllApiParameterValues() {
    this.attachSubscriptions(
      this.parametersService.getAllValues('1', this.company_id, null, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.parametersService.getAllValues(x, this.company_id).pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        this.allValues = [].concat(...res)
      })
    )
  }

  getEmployees() {
    this.attachSubscriptions(
      this.taskService.getEmployeesDyn('1', this.company_id, null, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }
  
          return forkJoin(arr.map(x => this.taskService.getEmployeesDyn(x, this.company_id, null, '200').pipe(map(u => u.body)))).pipe(
            last(),
            tap(values => {
              let conValues = [].concat(...values)
              this.employees = conValues;
            }),
          )
        }),
      ).subscribe(resp => {
        console.log("getEmployees resp", resp);
        console.log("getEmployees", this.employees);
      })
    )
  }

  getOperations() {
    this.attachSubscriptions(
      this.taskService.getOperations(this.company_id, this.activeLang).subscribe(resp => {
        this.operationsValues = resp;
      })
    )
  }

  getColumnName(id) {
    if (!this.operationsValues || this.operationsValues.filter(el => el.id == id).length == 0) {
      return '';
    }
    return this.operationsValues.find(el => el.id == id)?.translation?.name ? this.operationsValues.find(el => el.id == id).translation.name : this.operationsValues.find(el => el.id == id).name
  }

  removeValueFromMultiple(formName, key, value) {
    let arr = this[formName].value[key];

    console.log(key, value);
    console.log(arr);
    
    if (arr.includes(value)) {
      arr.splice(arr.indexOf(value), 1)
    }
    console.log(arr);
    console.log(this[formName].value[key]);
    // return 
    // this[formName].patchValue({
    //   [key]: arr
    // })
    this.filterData()
  }

  onRouteChange() {
    this.attachSubscriptions(
      this.activatedRoute.queryParams.subscribe(resp => {
        if (this.company_id != this.activatedRoute.snapshot.queryParamMap.get('company_id')) {
          this.company_id = this.activatedRoute.snapshot.queryParamMap.get('company_id');
          this.getCompany();
          this.getEmployees();
          this.getAllApiParameterValues();
          this.getGroupsCompany();
          this.getOperations();
        }
        
        switch (this.activatedRoute.snapshot.queryParamMap.get('tab')) {
          case this.types[0].tab:
            // tab 1
            this.selectedIndex.patchValue(0);
            this.taskPage = 1;
            this.taskTemplatesFilter.patchValue({
              q: !!this.activatedRoute.snapshot.queryParamMap.get('q') ? this.activatedRoute.snapshot.queryParamMap.get('q') : '',
              company_id: this.company_id
            })
            this.getTaskTemplates(this.taskPage);
            break;
          case this.types[1].tab:
            // tab 2
            this.selectedIndex.patchValue(1);
            this.cardPage = 1;
            this.cardTemplatesFilter.patchValue({
              q: !!this.activatedRoute.snapshot.queryParamMap.get('q') ? this.activatedRoute.snapshot.queryParamMap.get('q') : '',
              company_id: this.company_id
            })
            this.getCardAutomationTemplates(this.cardPage);
            break;
          case this.types[2].tab:
            // tab 3
            this.selectedIndex.patchValue(2);
            this.page = 1;
            this.templatesFilter.patchValue({
              q: !!this.activatedRoute.snapshot.queryParamMap.get('q') ? this.activatedRoute.snapshot.queryParamMap.get('q') : '',
              company_id: this.company_id
            })
            this.getAutomationTemplates(this.page);
            break;
          case this.types[3].tab:

            this.selectedIndex.patchValue(3);
            this.autoTemplatesFilter.patchValue({
              operation_id: !!this.activatedRoute.snapshot.queryParamMap.get('task_operation_operation_id') ? +this.activatedRoute.snapshot.queryParamMap.get('task_operation_operation_id') : '',
              employee_id: !!this.activatedRoute.snapshot.queryParamMap.get('operation_employee_id') ? +this.activatedRoute.snapshot.queryParamMap.get('operation_employee_id') : '',
              parameter_value_id: !!this.activatedRoute.snapshot.queryParamMap.get('parameter_value_id') ? +this.activatedRoute.snapshot.queryParamMap.get('parameter_value_id') : '',
            })
            this.autoPage = 1;
            // tab 4
            this.getAutos(this.autoPage);
            break;
          case this.types[4].tab:
            // tab 5
            this.selectedIndex.patchValue(4);
            this.autoTagsPage = 1;
            this.getAutoTags(this.autoTagsPage);
            break;
        
          default:
            // tab 1
            this.selectedIndex.patchValue(0);
            this.taskPage = 1;
            this.getTaskTemplates(this.taskPage);
            break;
        }
      })
    )
  }

  filterData() {
    let params = {
      company_id: this.company_id,
      page: 'automation'
    };

    Object.keys(this.autoTemplatesFilter.value).forEach(element => {
      if (!!this.autoTemplatesFilter.value[element]) {
        params[element] = this.autoTemplatesFilter.value[element]
      }
    });

    this.router.navigate(['/settings'], { queryParams: params});
  }


  onDownAutoTags(e) {
    console.log("SCROLL DOWN", e);
    if (this.autoTagsPagination['pageCount'] >= this.autoTagsPage) {
      console.log("onDownAutoTags")
      this.getAutoTags(this.autoTagsPage);
    }
  }
  onDownAutos(e) {
    console.log("SCROLL DOWN", e);
    if (this.autoPagination['pageCount'] >= this.autoPage) {
      console.log("onDownAutos")
      this.getAutos(this.autoPage);
    }
  }

  onDownTemplates(e) {
    console.log("SCROLL DOWN", e);
    if (this.pagination['pageCount'] >= this.page) {
      console.log("onDownTemplates")
      this.getAutomationTemplates(this.page);
    }
  }

  onDownCardTemplates(e) {
    console.log("SCROLL DOWN", e);
    if (this.cardPagination['pageCount'] >= this.cardPage) {
      console.log("onDownCardTemplates")
      this.getCardAutomationTemplates(this.cardPage);
    }
  }

  onDownTaskTemplates(e) {
    console.log("SCROLL DOWN", e);
    if (this.taskPagination['pageCount'] >= this.taskPage) {
      console.log("onDownTaskTemplates")
      this.getTaskTemplates(this.taskPage);
    }
  }

  goBack() {
    history.back();
  }

  getTaskTemplates(page) {
    this.isLoadTaskTemplates = true;
    this.attachSubscriptions(
      this.scenariosService.getTaskTemplates(page, this.taskTemplatesFilter.value, '200').pipe(
        tap(el => {
          this.taskPagination = {
            'pageCount': el.headers.get('x-pagination-page-count'),
            'perPage': el.headers.get('x-pagination-per-page'),
            'totalCount': el.headers.get('x-pagination-total-count'),
            'currentPage': el.headers.get('x-pagination-current-page'),
          }
        }),
        map(el => el.body)
      ).subscribe(resp => {
        if (page == 1) {
          this.taskTemplates = resp
        } else {
          this.taskTemplates.push(...resp)
        }
        console.log("getTaskTemplates" + this.taskPage, this.taskTemplates);
        this.taskPage++;
        this.isLoadTaskTemplates = false;
      })
    )
  }
  
  getAutos(page) {
    this.isLoad = true;
    this.attachSubscriptions(
      this.scenariosService.getAutos(page, Object.assign({company_id: this.company_id}, this.autoTemplatesFilter.value), '200').pipe(
        tap(el => {
          if (page == 1) {
            this.autoPagination = {
              'pageCount': el.headers.get('x-pagination-page-count'),
              'perPage': el.headers.get('x-pagination-per-page'),
              'totalCount': el.headers.get('x-pagination-total-count'),
              'currentPage': el.headers.get('x-pagination-current-page'),
            }
            console.log("this.autoPagination", this.autoPagination);
          }
        }),
        map(el => el.body)
      ).subscribe(resp => {
        if (page == 1) {
          this.autoTemplates = resp
        } else {
          this.autoTemplates.push(...resp)
        }
        console.log("getAutos" + this.autoPage, this.autoTemplates);

        this.autoPage++;
        this.isLoad = false;
      })
    )
  }

  getAutoTags(page) {
    this.isLoad = true;
    this.attachSubscriptions(
      this.scenariosService.getAutoTags(page, {company_id: this.company_id}, '200').pipe(
        tap(el => {
          if (page == 1) {
            this.autoTagsPagination = {
              'pageCount': el.headers.get('x-pagination-page-count'),
              'perPage': el.headers.get('x-pagination-per-page'),
              'totalCount': el.headers.get('x-pagination-total-count'),
              'currentPage': el.headers.get('x-pagination-current-page'),
            }
            console.log("this.autoTagsPagination", this.autoTagsPagination);
          }
        }),
        map(el => el.body)
      ).subscribe(resp => {
        if (page == 1) {
          this.autoTags = resp
        } else {
          this.autoTags.push(...resp)
        }
        console.log("getAutoTags" + this.autoTagsPage, this.autoTags);

        this.autoTagsPage++;
        this.isLoad = false;
      })
    )
  }

  editTaskTemplate(tmpl) {
    const addDialog = this.dialog.open(AddAndEditTaskTemplateComponent, {
      data: {
        company: this.company,
        templates: this.templates,
        host: this.host,
        imgRoute: this.imgRoute,
        user: this.user,
        activeLang: this.activeLang,
        tmpl: tmpl,
      }
    });

    this.attachSubscriptions(
      addDialog.afterClosed().subscribe(result => {
        this.taskPage = 1;
        this.getTaskTemplates(this.taskPage);
      })
    )
  }

  openScenExecutors(tmpl) {
    const addDialog = this.dialog.open(ScenEcexecutorsComponent, {
      data: {
        company: this.company,
        host: this.host,
        imgRoute: this.imgRoute,
        tmpl: tmpl,
        user: this.user,
        activeLang: this.activeLang,
      }
    });

    this.attachSubscriptions(
      addDialog.afterClosed().subscribe(result => {
        this.taskPage = 1;
        this.getTaskTemplates(this.taskPage);
      })
    )
  }

  openExecutors(tmpl) {
    const addDialog = this.dialog.open(TaskTemplateExecutorsComponent, {
      data: {
        company: this.company,
        host: this.host,
        imgRoute: this.imgRoute,
        tmpl: tmpl,
        user: this.user,
        activeLang: this.activeLang,
      }
    });

    this.attachSubscriptions(
      addDialog.afterClosed().subscribe(result => {
        this.taskPage = 1;
        this.getTaskTemplates(this.taskPage);
      })
    )
  }

  openGroupsSettings(tmpl) {
    const addDialog = this.dialog.open(ScenarioToGroupComponent, {
      data: {
        company: this.company,
        host: this.host,
        imgRoute: this.imgRoute,
        tmpl: tmpl,
        user: this.user,
        activeLang: this.activeLang,
      }
    });

    this.attachSubscriptions(
      addDialog.afterClosed().subscribe(result => {
        this.autoPage = 1;
        this.getAutos(this.autoPage);
      })
    )
  }
  
  addTaskTemplate() {
    const addDialog = this.dialog.open(AddAndEditTaskTemplateComponent, {
      data: {
        company: this.company,
        templates: this.templates,
        host: this.host,
        imgRoute: this.imgRoute,
        user: this.user,
        activeLang: this.activeLang,
      }
    });

    this.attachSubscriptions(
      addDialog.afterClosed().subscribe(result => {
        this.taskPage = 1;
        this.getTaskTemplates(this.taskPage);
      })
    )
  }

  getGroupById(id) {
    if (!this.groups || this.groups.length == 0) {
      return null
    }
    return this.groups.find(x => x.id == id)
  }

  getGroupsCompany() {
    this.attachSubscriptions(
      this.companyService.getInfiniteGroupsCompany(this.company_id, '1', '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.companyService.getInfiniteGroupsCompany(this.company_id, x).pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        this.groups = [].concat(...res);
      })
    )
  }

  addAuto() {
    const addDialog = this.dialog.open(AutoComponent, {
      data: {
        company: this.company,
        templates: this.templates,
        host: this.host,
        imgRoute: this.imgRoute,
        user: this.user,
        activeLang: this.activeLang,
      }
    });

    this.attachSubscriptions(
      addDialog.afterClosed().subscribe(result => {
        this.autoPage = 1;
        this.getAutos(this.autoPage);
      })
    )
  }

  getAutomationTemplates(page) {
    this.isLoadTemplates = true;
    this.attachSubscriptions(
      this.scenariosService.getAutomationTemplates(page, this.templatesFilter.value, '200').pipe(
        tap(el => {
          this.pagination = {
            'pageCount': el.headers.get('x-pagination-page-count'),
            'perPage': el.headers.get('x-pagination-per-page'),
            'totalCount': el.headers.get('x-pagination-total-count'),
            'currentPage': el.headers.get('x-pagination-current-page'),
          }
        }),
        map(el => el.body)
      ).subscribe(resp => {
        if (page == 1) {
          this.templates = resp
        } else {
          this.templates.push(...resp)
        }
        console.log("getAutomationTemplates" + this.page, this.templates);

        this.page++;
        this.isLoadTemplates = false;
      })
    )
  }
  
  getCardAutomationTemplates(page) {
    this.isLoadCardTemplates = true;
    this.attachSubscriptions(
      this.scenariosService.getAutomationTemplates(page, this.cardTemplatesFilter.value, '200').pipe(
        tap(el => {
          this.cardPagination = {
            'pageCount': el.headers.get('x-pagination-page-count'),
            'perPage': el.headers.get('x-pagination-per-page'),
            'totalCount': el.headers.get('x-pagination-total-count'),
            'currentPage': el.headers.get('x-pagination-current-page'),
          }
        }),
        map(el => el.body)
      ).subscribe(resp => {
        console.log("getCardAutomationTemplates", resp);

        if (page == 1) {
          this.cardTemplates = resp
        } else {
          this.cardTemplates.push(...resp)
        }
        this.cardPage++;
        this.isLoadCardTemplates = false;
      })
    )
  }

  deleteCardAutomationTemplate(tmpl, ind) {
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: {},
        target: marker("card scenario template")
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        this.attachSubscriptions(
          this.scenariosService.deleteAutomationTemplates(tmpl.id).subscribe(resp => {
            this.cardTemplates.splice(ind, 1)
          })
        )
      }
    });
  }
  deleteAutomationTemplate(tmpl, ind) {
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: {},
        target: marker("scenario template")
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        this.attachSubscriptions(
          this.scenariosService.deleteAutomationTemplates(tmpl.id).subscribe(resp => {
            this.templates.splice(ind, 1)
          })
        )
      }
    });
  }

  deleteAuto(tmpl, ind) {
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: {},
        target: marker("automation scenario template")
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        this.attachSubscriptions(
          this.scenariosService.deleteAuto(tmpl.id).subscribe(resp => {
            this.autoTemplates.splice(ind, 1)
          })
        )
      }
    });
  }

  deleteAutoTag(tmpl, ind) {
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: {},
        target: marker("auto tag")
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        this.attachSubscriptions(
          this.scenariosService.deleteAutoTag(tmpl.id).subscribe(resp => {
            this.autoTags.splice(ind, 1)
          })
        )
      }
    });
  }

  deleteTaskTemplate(tmpl, ind) {
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: {},
        target: marker("task template")
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        this.attachSubscriptions(
          this.scenariosService.deleteTaskTemplate(tmpl.id).subscribe(resp => {
            this.taskTemplates.splice(ind, 1)
          })
        )
      }
    });
  }

  addAutomationTemplate() {
    const addDialog = this.dialog.open(AddAutomationTemplateComponent, {
      backdropClass: ['parameters_modal_backdrop'],
      panelClass: ['parameters_modal'],
      data: {
        company: this.company,
        templates: this.templates,
        host: this.host,
        imgRoute: this.imgRoute,
        user: this.user,
        activeLang: this.activeLang,
      }
    });

    this.attachSubscriptions(
      addDialog.afterClosed().subscribe(result => {
        this.page = 1;
        this.getAutomationTemplates(this.page);
      })
    )
  }

  addAutoCardTemplate() {
    const addDialog = this.dialog.open(AddCardAutomationTemplateComponent, {
      data: {
        company: this.company,
        templates: this.templates,
        host: this.host,
        imgRoute: this.imgRoute,
        user: this.user,
        activeLang: this.activeLang,
      }
    });

    this.attachSubscriptions(
      addDialog.afterClosed().subscribe(result => {
        this.cardPage = 1;
        this.getCardAutomationTemplates(this.cardPage);
      })
    )
  }

  // addAutoTemplate() {
  //   const addDialog = this.dialog.open(AddAutomationTemplateComponent, {
  //     backdropClass: ['parameters_modal_backdrop'],
  //     panelClass: ['parameters_modal'],
  //     data: {
  //       company: this.company,
  //       templates: this.templates,
  //       host: this.host,
  //       imgRoute: this.imgRoute,
  //       user: this.user,
  //       activeLang: this.activeLang,
  //     }
  //   });

  //   this.attachSubscriptions(
  //     addDialog.afterClosed().subscribe(result => {
  //       this.page = 1;
  //       this.getAutomationTemplates(this.page);
  //     })
  //   )
  // }

  editCardAutomationTemplate(tmpl) {
    const addDialog = this.dialog.open(AddCardAutomationTemplateComponent, {
      data: {
        company: this.company,
        templates: this.templates,
        host: this.host,
        imgRoute: this.imgRoute,
        user: this.user,
        activeLang: this.activeLang,
        tmpl: tmpl
      }
    });

    this.attachSubscriptions(
      addDialog.afterClosed().subscribe(result => {
        this.cardPage = 1;
        this.getCardAutomationTemplates(this.cardPage);
      })
    )
  }

  openScheme(tmpl) {
    const addDialog = this.dialog.open(AutoSchemeComponent, {
      data: {
        company: this.company,
        host: this.host,
        imgRoute: this.imgRoute,
        user: this.user,
        activeLang: this.activeLang,
        tmpl: tmpl
      }
    });

    this.attachSubscriptions(
      addDialog.afterClosed().subscribe(result => {
        // this.page = 1;
        // this.getAutomationTemplates();
      })
    )
  }

  editAutomationTemplate(tmpl) {
    const addDialog = this.dialog.open(AddAutomationTemplateComponent, {
      backdropClass: ['parameters_modal_backdrop'],
      panelClass: ['parameters_modal'],
      data: {
        company: this.company,
        templates: this.templates,
        host: this.host,
        imgRoute: this.imgRoute,
        user: this.user,
        activeLang: this.activeLang,
        tmpl: tmpl
      }
    });

    this.attachSubscriptions(
      addDialog.afterClosed().subscribe(result => {
        this.page = 1;
        this.getAutomationTemplates(this.page);
      })
    )
  }

  getFolderByLoc(loc) {
    return this.folders && this.folders.find(x => x.location == loc)
  }

  addAutoTag() {
    const addDialog = this.dialog.open(AutoTagComponent, {
      data: {
        company: this.company,
        host: this.host,
        folders: this.folders,
        imgRoute: this.imgRoute,
        user: this.user,
        activeLang: this.activeLang,
      }
    });

    this.attachSubscriptions(
      addDialog.afterClosed().subscribe(result => {
        this.autoTagsPage = 1;
        this.getAutoTags(this.autoTagsPage);
      })
    )
  }

  editAutoTag(tmpl) {
    const addDialog = this.dialog.open(AutoTagComponent, {
      backdropClass: ['parameters_modal_backdrop'],
      panelClass: ['parameters_modal'],
      data: {
        company: this.company,
        host: this.host,
        folders: this.folders,
        imgRoute: this.imgRoute,
        user: this.user,
        activeLang: this.activeLang,
        tmpl: tmpl
      }
    });

    this.attachSubscriptions(
      addDialog.afterClosed().subscribe(result => {
        this.autoTagsPage = 1;
        this.getAutoTags(this.autoTagsPage);
      })
    )
  }
  
  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData().subscribe(resp => {
        this.activeLang = resp.active;
      })
    )
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  getCompany() {
    this.attachSubscriptions(
      this.companyService.getCompany(this.company_id).subscribe(resp => {
        this.companyService.company$.next(resp[0]);
        this.company = resp[0];
      })
    )
  }

  getCsrf() {
    this.attachSubscriptions(
      this.auth.$userToken.subscribe(resp => {
        this.user_id = resp.user_id;
        this.sm.localStorageSetItem("csrf_param", resp.csrf_param)
        this.sm.localStorageSetItem("csrf_token", resp.csrf_token)
      })
    )
  }

  getUser() {
    this.attachSubscriptions(
      this.auth.$user.subscribe(resp => {
        console.log("USER", resp)
        this.user = resp;
      })
    )
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

}
