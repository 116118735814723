<mat-dialog-content class="mat-typography">
    <div class="head_black" (click)="log()">
        <div>
            <div class="title_big">{{"Stream Restart assistant" | translate}}</div>
            <div class="title_small">{{"Just insert the link and specify the stream to restart it" | translate}}</div>
        </div>

        <svg style="margin-left: 40px;" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.07 9.25L12.17 10.17C11.45 10.89 11 11.5 11 13H9V12.5C9 11.39 9.45 10.39 10.17 9.67L11.41 8.41C11.78 8.05 12 7.55 12 7C12 5.89 11.1 5 10 5C9.46957 5 8.96086 5.21071 8.58579 5.58579C8.21071 5.96086 8 6.46957 8 7H6C6 5.93913 6.42143 4.92172 7.17157 4.17157C7.92172 3.42143 8.93913 3 10 3C11.0609 3 12.0783 3.42143 12.8284 4.17157C13.5786 4.92172 14 5.93913 14 7C14 7.88 13.64 8.67 13.07 9.25ZM11 17H9V15H11M10 0C8.68678 0 7.38642 0.258658 6.17317 0.761205C4.95991 1.26375 3.85752 2.00035 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C3.85752 17.9997 4.95991 18.7362 6.17317 19.2388C7.38642 19.7413 8.68678 20 10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 4.47 15.5 0 10 0Z" fill="#219653"/>
        </svg>
    </div>
    <div class="autos streams_restart" [appDeviceClass]="'custom-scroll custom-scroll-t'">
        <b>{{ "To restart insert the link to stream on Youtube" | translate }}</b>
        <form [formGroup]="filter" class="d-f-c form_with_pads form_with_valids" style="justify-content: space-between;">
            <mat-form-field class="white_legacy_select" appearance="legacy" style="width: 300px;">
                <mat-label>{{"Url" | translate}}</mat-label>
                <input (input)="onInputUrl($event.target.value)" matInput formControlName="url" placeholder="{{'Url' | translate}}">
            </mat-form-field>
        </form>
    
        <div class="elts elts_head">
            <div class="el el_head">
                <div class="el_in">{{ "YouTube stream URL" | translate }}</div>
                <div class="el_in el_in_small">{{ "Post ID" | translate }}</div>
                <div class="el_in">{{ "Channel from URL" | translate }}</div>
                <div class="el_in el_in_small">{{ "Status" | translate }}</div>
                <div class="el_in el_in_small">{{ "Stage" | translate }}</div>
                <div class="el_in">{{ "Card of post" | translate }}</div>
                <div class="el_in">{{ "Title" | translate }}</div>
            </div>
        </div>
        
        <div class="elts">
            <div class="elts_title">
                {{"Is being processed" | translate}}
            </div>
            <ng-container *ngFor="let el of elements">
                <ng-container *ngIf="el?.status == 'processed'">
                    <ng-container [ngTemplateOutlet]="itemElement" [ngTemplateOutletContext]="{ el: el }"></ng-container>
                </ng-container>
            </ng-container>
        </div>

        <div class="elts">
            <div class="elts_title">
                {{"Restarted" | translate}}
            </div>
            <ng-container *ngFor="let el of elements">
                <ng-container *ngIf="el?.status == 'restarted'">
                    <ng-container [ngTemplateOutlet]="itemElement" [ngTemplateOutletContext]="{ el: el }"></ng-container>
                </ng-container>
            </ng-container>
        </div>

        <div class="elts">
            <div class="elts_title">
                {{"Error" | translate}}
            </div>
            <ng-container *ngFor="let el of elements">
                <ng-container *ngIf="el?.status == 'error'">
                    <ng-container [ngTemplateOutlet]="itemElement" [ngTemplateOutletContext]="{ el: el }"></ng-container>
                </ng-container>
            </ng-container>
        </div>
        
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <div class="bottom_btns">
        <div class="d-f-c">
        </div>
        <div class="d-f-c">

            <button mat-raised-button (click)="close()">
                {{ "Close" | translate }}
            </button>
        </div>
    </div>
</mat-dialog-actions>

<ng-template #itemElement let-el="el">
    <div class="el" [class]="el.status">
        <div class="el_in">{{el?.url}}</div>
        <div class="el_in el_in_small">{{el?.post?.id}}</div>
        <div class="el_in">
            <ng-container *ngIf="el.post && el.post.channel">
                <app-outlet-line [channel]="el.post.channel" [company_id]="data.company.id"></app-outlet-line>
            </ng-container>
        </div>
        <div class="el_in stat_el el_in_small">
            <span *ngIf="el.post && el.post.contentStatusName" (dblclick)="dblEditProfileStatus(el)" [ngClass]="{
                'stat_1': el?.post?.content_status_id == 1,
                'stat_2': el?.post?.content_status_id == 2,
                'stat_3': el?.post?.content_status_id == 3,
                'stat_4': el?.post?.content_status_id == 4,
                'stat_5': el?.post?.content_status_id == 5,
                'stat_14': el?.post?.content_status_id == 14
            }">
                {{el.post.contentStatusName}}
            </span>
        </div>
        <div class="el_in el_in_small">{{el?.stage}}</div>
        <div class="el_in text_one_line">{{el?.post?.task?.name}}</div>
        <div class="el_in text_one_line">{{el?.post?.content_name}}</div>
    </div>
</ng-template>