import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { forkJoin, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { casesModel } from 'src/app/shared/functions/casesModel';
import { BaseClass } from 'src/app/shared/models/base-class';
import { PermissionsPipe } from 'src/app/shared/pipes/permissions.pipe';
import { ChatService } from 'src/app/shared/services/rest/chat.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { FileService } from 'src/app/shared/services/rest/file.service';
import { TaskBarService } from 'src/app/shared/services/rest/task-bar.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';

@Component({
  selector: 'app-mob-shortcuts',
  templateUrl: './mob-shortcuts.component.html',
  styleUrls: ['./mob-shortcuts.component.scss']
})

export class MobShortcutsComponent extends BaseClass implements OnInit, OnDestroy {
  public barItems:any;
  public isLoad:boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<MobShortcutsComponent>,
    public companyService: CompanyService,
    public taskService: TaskService,
    public chatService: ChatService,
    public fileService: FileService,
    public taskBarService: TaskBarService,
  ) { super() }

  ngOnInit(): void {
    console.log("MobShortcutsComponent", this.data)

    if (this.data.needGet) {
      this.isLoad = true;
      this.getFavoriteBookmarks();
      this.attachSubscriptions(
        this.taskBarService.getNeedGetBarItemsStream().pipe(
          tap(barItems => {
  
            console.log("getBarItemsStream 1", barItems)
            barItems.forEach(barItem => {
              if (!!barItem.file_id) {
                barItem.type = 'file'
              } else if (!!barItem.task_operation_id && !!barItem.discussion_id && !barItem.file_id) {
                barItem.type = 'chat'
              } else if (!!barItem.task_operation_id && !barItem.discussion_id && !barItem.file_id) {
                barItem.type = 'job'
              } else {
                barItem.type = 'task'
              }
            });
          }),
          switchMap(barItems => {
            console.log("getBarItemsStream 2", barItems)
            if (!barItems.length) {
              console.log("getBarItemsStream 3", barItems)
              return of(barItems)
            } else {
              console.log("getBarItemsStream 4", barItems)
              return forkJoin(barItems.map(barItem => {
                if (barItem.type == 'chat') {
                  return this.chatService.getChatsExpand('1', barItem.company_id, {id: [barItem.discussion_id]}).pipe(
                    map(chatsExpand => chatsExpand.body),
                    switchMap(chats => {
                      if (chats.length != 0) {
                        barItem.switchData = chats[0];
                        return this.taskService.getOneTaskExpand(barItem.company_id, barItem.task_id).pipe(
                          catchError(err => {
                            return err
                          }),
                          map((task) => casesModel(!!task ? task.body : [undefined], [], 'update').arr[0]),
                          tap(task => {
                            if (!!task) {                  
                              // console.log("casesModel", task)
                              barItem.work = task.operations.find(x => x.id == barItem.task_operation_id);
            
                              barItem.work.employees = barItem.work.employees.filter(x => x.discussion_id == barItem.discussion_id || x.discussion_id == 0 || !x.discussion_id)
                              barItem.work.mainEmployees = barItem.work.mainEmployees.filter(x => x.discussion_id == barItem.discussion_id || x.discussion_id == 0 || !x.discussion_id)
                              barItem.work.addEmployee = barItem.work.addEmployee.filter(x => x.discussion_id == barItem.discussion_id || x.discussion_id == 0 || !x.discussion_id)
                              barItem.work.addEmployee.forEach(x => {
                                if (!x.employeeStatus && barItem.switchData.employeesStatuses.find(z => z.employee_id == x.employee_id)) {
                                  x.employeeStatus = barItem.switchData.employeesStatuses.find(z => z.employee_id == x.employee_id)
                                }
                              })
                              barItem.work.allChatEmpl = [...barItem.work.mainEmployees, ...barItem.work.addEmployee].sort((a,b) => b.is_manager - a.is_manager);
                            }
                          }),
                          map(() => barItem)
                        )
                      } else {
                        return this.taskBarService.deleteBarItem(barItem.id).pipe(
                          tap(() => {
                            barItems.splice(barItems.indexOf(barItem), 1)
                          })
                        )
                      }
                    })
                  )
                } else if (barItem.type == 'job') {
                  return this.taskService.getOneTaskExpand(barItem.company_id, barItem.task_id).pipe(
                    catchError(err => {
                      let x:any = {body: []}
                      return x
                    }),
                    map(x => x.body[0]),
                    switchMap(x => this.neededData(x, barItem.company_id).pipe(
                      map(() => casesModel([x], [], 'update')),
                      map(x => x.arr[0]),
                      tap(task => {
                        if (!!task) {
                          barItem.task = task;
                          barItem.switchData = task.operations.find(x => x.id == barItem.task_operation_id);
                        }
                      }),
                      map(() => barItem)
                    ))
                  )
                } else if (barItem.type == 'task') {
                  return this.taskService.getOneTaskExpand(barItem.company_id, barItem.task_id).pipe(
                    catchError(err => {
                      let x:any = {body: []}
                      return x
                    }),
                    map(x => x.body[0]),
                    switchMap(x => this.neededData(x, barItem.company_id).pipe(
                      map(() => casesModel([x], [], 'update')),
                      map(x => x.arr[0]),
                      tap(task => {
                        if (!!task) {
                          barItem.switchData = task;
                        }
                      }),
                      map(() => barItem)
                    ))
                  )
                } else if (barItem.type == 'file') {
                  return this.fileService.getFilesPreview(barItem.company_id, [barItem.file_id]).pipe(
                    tap(file => {
                      barItem.switchData = file[0];
                    }),
                    map(() => barItem)
                  )
                } else {
                  return of(barItem)
                }
              })).pipe(
                map(() => barItems),
                tap(barItems => {
                  console.log("tap getBarItemsStream 5", barItems)
                  this.barItems = barItems;
                })
              )
            }
          })
        ).subscribe(resp => {
          if (!!resp) {
            this.barItems = [...resp]
            this.data.groupedBarItems = this.groupedBarItems(this.barItems)
            this.isLoad = false;
          }
          console.log("this.barItems", this.barItems)
        }, error => {
          console.log("this.barItems ERROR", error, this.barItems)
        })
      )

      this.attachSubscriptions(
        this.taskBarService.getBarItems(this.data.company.id).pipe(
          tap(barItems => {
            barItems.forEach(barItem => {
              barItem.company = this.data.company
              barItem.activeLang = this.data.activeLang
              barItem.user = this.data.user;
              barItem.isOpen == false;
            });
          })
        ).subscribe(resp => {
          console.log("getBarItems", resp)
          this.taskBarService.needGetBarItems$.next(resp)
        })
      )
    }

    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        this.close();
      })
    )
  }

  neededData(task, companyID) {
    if (!task) {
      return of(task)
    }
    let arr = [
      this.chatService.getTasksChats(companyID, [task.id]).pipe(
        tap(res => {
          task['operations'].map(o_el => {
            o_el['chatsInfo'] = [];
            o_el['chatsInfo'].push(...res.filter(z => z.task_operation_id == o_el.id));
            o_el['unReadChats'] = res.filter(z => z.task_operation_id == o_el.id && z.is_read != '1').length;
            o_el['unClosedChats'] = res.filter(z => z.task_operation_id == o_el.id && z.status_id != '4').length;
          });
          task['chatsInfo'] = [];
          task['chatsInfo'].push(...res.filter(z => z.task_id == task.id));
          task['unReadChats'] = res.filter(z => z.is_read != '1').length;
          task['unClosedChats'] = res.filter(z => z.status_id != '4').length;
        })
      )
    ]
    if (task.company_id == companyID) {
      arr.push(
        this.taskService.getTaskClients(task.id, companyID).pipe(
          tap(res => {
            task.clients = res
          }),
          catchError(err => {
            return err
          })
        )
      )
    }
    
    // if (this.company | PermissionsPipe:'see_files')
    if (new PermissionsPipe().transform(this.data.company, 'see_files')) {
      arr.push(...task.operations.filter(z => (z.status_id == 3 && z.is_to_approve_files) || (z.status_id == 98 && z.is_approved_files)).map(x => this.fileService.getFilesForOpenTask(companyID, x.id, x.status_id == 3 ? '/to_approve' : '/ready_files', this.data.is_mobile ? '20' : '3').pipe(
        tap(val => {
          x.filesCount = +val.headers.get('x-pagination-total-count');
          x.files = val.body.filter(x => !!x.thumbnail).slice(0,4);
          console.log("JOB FILES", x.files)
        })
      )))
    }

    return forkJoin(arr)
  }


  groupedBarItems(arr) {

    let data = {
      tasks: [],
      works: [],
      chats: [],
      files: []
    }

    if (arr) {
      arr.forEach(element => {
        if (element.type == 'file') {
          data.files.push(element)
        } else if (element.type == "chat") {
          data.chats.push(element)
        } else if (element.type == "job") {
          data.works.push(element)
        } else {
          data.tasks.push(element)
        }
      });
    }


    return data;
  }

  getFavoriteBookmarks() {
    this.attachSubscriptions(
      this.companyService.getBookmarks(this.data.company.id, {employee_id: this.data.company.employees[0].id, is_shortcut: 1}).subscribe(resp => {
        this.data.favoriteBookmarks = resp.slice();
        console.log("this.data.favoriteBookmarks", this.data.favoriteBookmarks)
      })
    )
  }

  activeSections() {
    switch (window.location.pathname) {
      case '/dashboard':
        return 'Dashboard'
        break;
      case '/tasks':
        return 'Cards'
        break;
      case '/jobs':
        return 'Jobs'
        break;
      case '/chats':
        return 'Chats'
        break;
      case '/board':
        return 'Board by Date'
        break;
      case '/board-list':
        return 'Board by Order'
        break;
      case '/urls':
        return 'Posts'
        break;
      case '/urls-dashboard':
        return 'Post Dashboards'
        break;
      case '/outlets-dashboard':
        return 'Outlet Dashboards'
        break;
      case '/settings':
        return 'Settings'
        break;
      case '/files':
        return 'Files'
        break;
      default:
        return window.location.pathname.replace('/', '')
        break;
    }
  }

  goToItem(item) {
    console.log(item)
    this.dialogRef.removePanelClass('animate__slideInUp');
    this.dialogRef.addPanelClass('animate__slideOutDown');
    setTimeout(()=>{this.dialogRef.close({event: 'go', data: item})}, 300);
  }

  goToBookmark(bookmark) {
    console.log(bookmark)
    this.dialogRef.removePanelClass('animate__slideInUp');
    this.dialogRef.addPanelClass('animate__slideOutDown');
    setTimeout(()=>{this.dialogRef.close({event: 'bookmark', data: bookmark, company: this.data.company})}, 300);
  }

  removeItem(item) {
    console.log(item);
    this.attachSubscriptions(
      this.taskBarService.deleteBarItem(item.id).subscribe(resp => {
        this.barItems.splice(this.barItems.indexOf(item), 1);
        this.data.groupedBarItems = this.groupedBarItems(this.barItems);
      })
    )
  }

  // removeItem(item) {
    
  //   console.log(item)
  //   this.dialogRef.removePanelClass('animate__slideInUp');
  //   this.dialogRef.addPanelClass('animate__slideOutDown');
  //   setTimeout(()=>{this.dialogRef.close({event: 'remove', data: item})}, 300);
  // }

  close() {
    this.dialogRef.removePanelClass('animate__slideInUp');
    this.dialogRef.addPanelClass('animate__slideOutDown');
    setTimeout(()=>{this.dialogRef.close({event: 'close'})}, 300);
  }


  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

}
