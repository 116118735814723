import { AfterContentChecked, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { AuthService } from 'src/app/shared/services/rest/auth.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { MY_FORMATS } from '../task-profile-add/task-profile-add.component';
import * as moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/en-gb';
import 'moment/locale/uk';
import 'moment/locale/ru';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { DateAskComponent } from 'src/app/shared/global_components/date-ask/date-ask.component';
import { MatDatepicker } from '@angular/material/datepicker';
import { CreateOperationChatComponent } from '../create-operation-chat/create-operation-chat.component';
import { FileService } from 'src/app/shared/services/rest/file.service';
import { UploadService } from 'src/app/shared/services/rest/upload.service';
import { map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { MembersService } from 'src/app/shared/services/rest/members.service';
import { mimeTypes } from 'mime-wrapper';
import { GlobalDataService } from 'src/app/shared/services/common/global-data.service';
import { environment } from 'src/environments/environment';
import { NgxMatDateAdapter } from '@angular-material-components/datetime-picker';
@Component({
  selector: 'app-work-empl-edit',
  templateUrl: './work-empl-edit.component.html',
  styleUrls: ['./work-empl-edit.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class WorkEmplEditComponent extends BaseClass implements OnInit, AfterContentChecked, OnDestroy {
  @ViewChild('dp') dp: MatDatepicker<any>
  public host: any = environment.host;
  public imgRoute: any = '';
  public user: any = {};
  public user_id: number;
  public form: FormGroup;
  public company_id: any;
  public uploadLimit: boolean = false;
  public task_id: any;
  public work_id: any;
  public name: any;
  public status_id: any;
  public employee_comment: any;
  public submited: boolean = false;
  public isSubmit: boolean = false;
  public isFormChange: boolean = false;
  public showContentUrl: boolean = false;
  public is_checked: boolean = false;
  public operations: any;
  public completed_at: any;
  public currencyList: any;
  public operationStatuses: any[] = [];
  public employees: any;
  public saveData: any;
  public numberRegEx = /\d*\.?\d{1,2}/;
  public noChat: boolean = false;
  public activeIndex: number = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<WorkEmplEditComponent>,
    private sm: StorageManagerService,
    private auth: AuthService,
    private companyService: CompanyService,
    private globalDataService: GlobalDataService,
    private cdref: ChangeDetectorRef,
    private fb: FormBuilder,
    private uploadService: UploadService,
    private taskService: TaskService,
    private dialog: MatDialog,
    private _adapter: DateAdapter<any>,
    private _ngx_adapter: NgxMatDateAdapter<any>,
    private languageService: LanguageService,
    private layoutService: LayoutService,
    private fileService: FileService,
    private membersService: MembersService,
    private bottomSheet: MatBottomSheet
  ) { super() }

  ngOnInit(): void {
    this.data.dialogHasFm = true;
    this.dialogRef.addPanelClass("create_task_by_manager_modal")
    console.log("WorkEmplEditComponent", this.data);
    window.scroll(0,0);
    
    this.attachSubscriptions(
      this.uploadService.getUploadLimit().subscribe(resp => {
        if (resp || this.data.company.filesize >= this.data.company.filesize_limit) {
          this.uploadLimit = true;
        }
      })
    )
    this.getCurrencyList();
    this.company_id = this.data.company.id;
    this.task_id = this.data.task.id;
    this.work_id = this.data.work.id;
    this.status_id = this.data.work.status_id;
    this.name = this.data.work.name;
    this.employee_comment = this.data.work.employee_comment;
    this.completed_at = this.data.company.id == this.data.work.company_id ? this.data.work.completed_at : this.data.work.employee.completed_at;

    this.form = this.fb.group({
      status_id: [+this.status_id, Validators.required],
      employee_comment: this.employee_comment,
      name: this.name,
      completed_at: this.completed_at != '0' ? moment(this.completed_at*1000) : '',
      content_url: this.data.work.content_url,
      planned_completed_at: this.data.work.planned_completed_at != "0" ? moment(this.data.work.planned_completed_at*1000) : '',
      date: this.fb.group({
        time: '',
        day: ''
      })
    })

    this.attachSubscriptions(
      this.form.get('date').valueChanges.subscribe(resp => {
        console.log(resp)
        let dateForm = {...this.form.get("date").value}
        if (!dateForm.day) {
          dateForm.day = moment(0).hour(0).minute(0)
        }
        let testTimeAndDate = dateForm.day.clone()
        testTimeAndDate.hour(Number(dateForm.time.split(":")[0]))
        testTimeAndDate.minute(Number(dateForm.time.split(":")[1]))
        this.form.patchValue({
          planned_completed_at: testTimeAndDate
        })
        console.log('this.form.value', this.form.value);
        console.log('testTimeAndDate', testTimeAndDate.clone().format("X"));
      })
    )

    this.form.get('date').patchValue({
      day: this.data.work.planned_completed_at ? moment(this.data.work.planned_completed_at * 1000).hour(0).minute(0) : '',
      time: this.data.work.planned_completed_at ? (this.numberToTimeFormat(moment(this.data.work.planned_completed_at * 1000).hours()) + ":" + this.numberToTimeFormat(moment(this.data.work.planned_completed_at * 1000).minutes())) : '',
    })


    if (this.status_id == 3) {
      this.showContentUrl = true;
      this.is_checked = true;
      // this.form.controls.content_url.setValidators(Validators.required);
      // this.form.get('content_url').updateValueAndValidity();
    }

    this.getCompany();
    this.getImgRoute();
    this.getCsrf();
    this.getUser();
    this.getLangData();

    this.saveData = {
      status_id: +this.status_id,
      employee_comment: this.employee_comment,
      completed_at: this.completed_at != '0' ? moment(this.completed_at*1000) : '',
      content_url: this.data.work.content_url
    }

    if (!!this.data.work.employee) {
      if (this.data.company.rate_target != '0.0000') {
        this.saveData.rate = this.data.work.employee.rate;
        this.form.addControl("rate", this.fb.control(this.data.work.employee.rate, Validators.pattern(this.numberRegEx)))
      } else {
        this.saveData.price = this.data.work.employee.price;
        this.form.addControl("price", this.fb.control(this.data.work.employee.price, Validators.pattern(this.numberRegEx)))
      }
    }

    this.attachSubscriptions(
      this.form.valueChanges.subscribe(() => this.isFormChange = true)
    )

    this.attachSubscriptions(
      this.form.controls.status_id.valueChanges.subscribe(value => {
        if (+value == 3 && this.data.work.status_id == 5) {
          this.form.controls.completed_at.setValidators(Validators.required)
          let sheetRef =  this.bottomSheet.open(DateAskComponent);
          sheetRef.afterDismissed().subscribe( data => {
            if(data && data.message=='prev') {
              this.dp.open();
              // this.form.get('completed_at').valueChanges.subscribe(result => {
              // })
            } if(data && data.message=='today') {
              this.form.patchValue({
                completed_at: moment()
              })
            }
          });
        } else if (value == 3) {
          this.form.controls.completed_at.setValidators(Validators.required);
        } else if (value == 5) {
          this.form.patchValue({
            completed_at: moment()
          })
          this.form.controls.completed_at.setValidators(Validators.required)
        } else if (!!this.form.controls.completed_at) {
          this.form.controls.completed_at.clearValidators();
        }
        this.form.get('completed_at').updateValueAndValidity();

        if (value == 3) {
          this.showContentUrl = true;
          this.is_checked = true;
          // this.form.controls.content_url.setValidators(Validators.required);
        } else {
          this.showContentUrl = false;
          this.is_checked = false;
          // this.form.controls.content_url.clearValidators();
        }
        // this.form.get('content_url').updateValueAndValidity();

        if (value == 5 && !this.data.work.chatsInfo.filter(z => z.status_id == 2 || z.status_id == 4).length && this.data.company && this.data.company.permissions.includes('create_discussion')) {
          this.noChat = true;
          const createChat = this.dialog.open(CreateOperationChatComponent, {
            disableClose: true,
            data: {
              is_create_chat_component: true,
              tasks: this.data.tasks,
              company: this.data.company,
              task: this.data.task,
              work: this.data.work,
              user: this.data.user,
              empl_status: this.data.company.permissions.includes('owner') || this.data.company.permissions.includes('admin') || this.data.company.permissions.includes('manager')
            }
          });

          this.attachSubscriptions(
            createChat.afterClosed().subscribe(result => {
              if (!!result && result.event == 'create') {
                this.noChat = false;
              } else {
                this.form.patchValue({
                  status_id: ''
                })
              }
            })
          )
        } else {
          this.noChat = false;
        }
      })
    )

    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        if (this.isFormChange) {
          this.layoutService.openBottomSheet(this.dialogRef);
        } else {
          this.close();
        }
      })
    )

    this.getOperationsStatus();
    
  }

  getCurrency(id) {
    if (!!this.currencyList) {
      return this.currencyList.find(el => el.id == id)
    }
  }

  getCurrencyList() {
    this.attachSubscriptions(
      this.globalDataService.getCurrencies().subscribe(resp => {
        console.log("getCurrencyList", resp)
        this.currencyList = resp.slice();
      })
    )
    // this.attachSubscriptions(
    //   this.companyService.getCurrencyList().subscribe(resp => {
    //     console.log("getCurrencyList", resp);
    //     this.currencyList = resp
    //   })
    // )
  }


  selectStatus(status) {
    this.form.patchValue({
      status_id: status.id
    })
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  changeTab(val) {
    console.log(val);
    if (val == 1) {
      this.form.controls.content_url.setValidators(Validators.required);
    } else {
      this.form.controls.content_url.clearValidators();
    }
    this.form.get('content_url').updateValueAndValidity();
    this.activeIndex = val;
  }

  enableContentUrl(value) {
    if (value) {
      if (this.activeIndex == 1) {
        this.form.controls.content_url.setValidators(Validators.required);
      }
      this.showContentUrl = true;
    } else {
      this.form.controls.content_url.clearValidators();
      this.showContentUrl = false;
    }
    this.form.get('content_url').updateValueAndValidity();
  }

  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData().subscribe(resp => {
        this._ngx_adapter.setLocale(resp.active);
        this._adapter.setLocale(resp.active);
      })
    )
  }
  
  getCompany() {
    this.attachSubscriptions(
      this.companyService.getCompany(this.company_id).subscribe(resp => {
        this.companyService.company$.next(resp[0]);
      })
    )
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  getCsrf() {
    this.attachSubscriptions(
      this.auth.$userToken.subscribe(resp => {
        this.user_id = resp.user_id;
        this.sm.localStorageSetItem("csrf_param", resp.csrf_param)
        this.sm.localStorageSetItem("csrf_token", resp.csrf_token)
      })
    )
  }

  getUser() {
    this.attachSubscriptions(
      this.auth.$user.subscribe(resp => {
        this.user = resp;
      })
    )
  }

  
  getOperationsStatus() {
    this.attachSubscriptions(
      this.taskService.getOperationsStatus().subscribe(resp => {
        let statusesData = resp.slice();
        statusesData.forEach(element => {
          if (element.is_lock == 0) {
            this.operationStatuses.push(element)
          }
        });
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }
  
  uploadFile(event) {
    if (this.uploadLimit) {
      this.layoutService.showSnackBar({name: ''}, marker("This company has exceeded the file upload limit."), SnackBarItem)
      return
    }
    if (event.target.files.length > 0) {
      const file = (event.target as HTMLInputElement).files[0];
      console.log(file);
      this.putFile(file);
    }
  }

  putFile(file) {
    let path = "/to_approve"
    this.fileService.uploadFile({
      company_id: this.data.company.id,
      task_id: this.data.task.id,
      task_operation_id: this.data.work.id,
      filesize: file.size,
      filename: file.name,
      content_type: file.type ? file.type : mimeTypes.getType(file.name),
      location: path,
      is_dir: 0
    }, this.data.company.id).subscribe(resp => {
      console.log("putFile", resp)
      this.fileService.files$.next({
        place: "work_status",
        url: window.location.href,
        data: resp,
        location: path,
        target: file,
        task: this.data.task,
        work: this.data.work,
        activeLang: this.data.activeLang,
        operationsValues: this.data.operationsValues,
        task_id: this.data.task.id,
        work_id: this.data.work.id,
        company_id: this.data.company.id,
        company: this.data.company,
        user: this.data.user
      })
    })
  }


  onSubmit() {
    if (!this.form.valid) {
      this.submited = true;
      console.log(this.form);
      return
    }
    this.isSubmit = true;
    if (this.showContentUrl && this.activeIndex == 0) {
      this.attachSubscriptions(
        this.fileService.detectFiles("/to_approve", "0", this.data.company.id, this.data.work.id).subscribe(resp => {
          if (resp.length) {
            this.submitFunc();
          } else {
            this.isSubmit = false;
            this.layoutService.showSnackBar({name: ''}, marker("Folder 'To approve' is empty! You must upload files to approve."), SnackBarItem);
          }
        })
      )
    } else {
      this.submitFunc()
    }
  }

  numberToTimeFormat(val) {
    if (Number(val) < 10) {
      val = "0" + val
    }
    return val
  }
  
  submitFunc() {
    if (!!this.form.value.completed_at && !!this.form.value.completed_at._d) {
      this.form.value.completed_at = moment(this.form.value.completed_at._d).format("X");
    } else {
      this.form.value.completed_at = '0';
    }

    if (!!this.form.value.planned_completed_at && !!this.form.value.planned_completed_at._d) {
      this.form.value.planned_completed_at = moment(this.form.value.planned_completed_at._d).format("X");
    } else {
      delete this.form.value.planned_completed_at;
    }
    delete this.form.value.date;

    let data = this.form.value;

    if (data.price == null) {
      data.price = "0";
    }
    if (data.rate == null) {
      data.rate = "0";
    }

    let emplData = {...data};
    delete emplData.employee_comment
    delete emplData.content_url

    if (emplData.price == "null" || emplData.price == '') {
      emplData.price = "0";
    }
    if (emplData.rate == null || emplData.rate == '') {
      emplData.rate = "0";
    }

    delete data.rate
    delete data.price

    Object.keys(data).forEach(k => {
      if( k != 'operation_id' && data[k] == this.saveData[k] || (k == 'completed_at' && (moment(data[k]*1000)['_i'] == this.saveData[k]._i || !data[k]))) {
        delete data[k];
      }
    });

    console.log("DATA", data)

    if (this.data.company.id == this.data.work.company_id) {
      this.attachSubscriptions(
        this.taskService.editWork(this.work_id, data, this.data.company.id).pipe(
          switchMap((x) => {
            if (!!this.data.work?.employee?.employee_id) {
              return this.membersService.editMember(this.data.work?.employee.id, emplData, this.data.company.id)
            } else {
              return of(x)
            }
          })
        ).subscribe(resp => {
          this.dialogRef.close({event: "update", data: resp})
        })
      )
    } else {

      let partnerData = {...emplData}
      delete partnerData.name
      delete partnerData.status_id
      
      if (partnerData.completed_at == '0') {
        delete partnerData.completed_at
      }
      this.attachSubscriptions(
        this.membersService.editTaskPartnerEmployee(this.data.work?.employee.id, partnerData, this.data.company.id).pipe(
          switchMap(x => {
            return this.membersService.editPartnerEmployeeStatus(this.data?.work?.employee.employeeStatus.id, {status_id: this.form.value.status_id}, this.data.company.id)
          })
        ).subscribe(resp => {
          console.log(resp);
          this.dialogRef.close({event: "update", data: resp})
        })
      )
    }

  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}

