import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpRestService } from '../common/http-rest.service';

@Injectable({
  providedIn: 'root'
})
export class AiService {

  constructor(
    private http: HttpRestService
  ) { }

  sendAIMsg(data) {
    return this.http.executePost<any>(`/api/open-ai/chat/`, data)
  }
  
  getAIMsg(filter) {
    let filter_params = new HttpParams().set("per-page", '200')

    if (filter) {
      Object.keys(filter).forEach(element => {
        filter_params = filter_params.set(element, filter[element]);
      });
    }

    return this.http.executeGet<any>('/api/open-ai-message/', true, {
      params: filter_params
    })
  }

  getAIModels(filter?) {
    let filter_params = new HttpParams().set("per-page", '200')

    if (filter) {
      Object.keys(filter).forEach(element => {
        filter_params = filter_params.set(element, filter[element]);
      });
    }

    return this.http.executeGet<any>('/api/open-ai-model/', true, {
      params: filter_params
    })
  }

  saveAiMsg(data) {
    return this.http.executePost<any>(`/api/open-ai-message/`, data)
  }

  editAiMsg(id, data) {
    return this.http.executePost<any>(`/api/open-ai-message/${id}/`, data)
  }

  deleteAiMsg(id) {
    return this.http.executeDelete<any>(`/api/open-ai-message/${id}/`)
  }
}
