import { AfterViewInit, Component, ElementRef, HostListener, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseClass } from '../../models/base-class';

@Component({
  selector: 'app-files-upload-ask',
  templateUrl: './files-upload-ask.component.html',
  styleUrls: ['./files-upload-ask.component.scss']
})
export class FilesUploadAskComponent extends BaseClass implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild("text_field") text_field: ElementRef<any>;
  public isSubmit: boolean = false;
  public form: FormGroup;
  public dropOver: boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<FilesUploadAskComponent>,
    private fb: FormBuilder
  ) { super() }

  // @HostListener('window:keyup.Enter', ['$event'])
  // onDialogClick(event: KeyboardEvent): void {
  //   this.submit()
  // }

  ngOnInit(): void {
    this.dialogRef.addPanelClass("upload_ask")
    console.log("FilesUploadAskComponent", this.data)

    this.form = this.fb.group({
      text: this.data.text
    })

    for (let index = 0; index < this.data.files.length; index++) {
      const x = this.data.files[index];
      let reader = new FileReader();
      reader.onload = () => {
        x.src = reader.result as string;
        console.log('reader', reader)
      }
      reader.readAsDataURL(x)
      if (x.type.indexOf("image") != -1) {
        x.is_compress = false;
        x.dontCompress = false;
      } else {
        x.is_compress = false;
        x.dontCompress = true;
      }

      // ЭТО РАБОТАЕТ
      // if (x.type.indexOf("video") != -1) {
      //   x.videoUrl = URL.createObjectURL(x);

      //   const video = document.createElement('video');
      //   video.src = x.videoUrl;
      //   document.body.appendChild(video);

      //   video.addEventListener('loadedmetadata', function() {
      //     console.log('Ширина:', this.videoWidth);
      //     console.log('Высота:', this.videoHeight);
      //     x.width = this.videoWidth;
      //     x.height = this.videoHeight;
      //     document.body.removeChild(video)
      //   });
      // }
      
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.text_field && this.text_field.nativeElement.innerText == '') {
        console.log("this.text_field", this.text_field)
        this.text_field.nativeElement.focus()
      }
    }, 300)
  }

  imgIsLoad(e, file, img) {
    file.width = img.naturalWidth;
    file.height = img.naturalHeight;
    console.log("imgIsLoad", e, file, img, {h: img.naturalHeight, w: img.naturalWidth})
  }

  compress(initFile, i) {
    const reader = new FileReader();
    reader.readAsDataURL(initFile);
    reader.onload = event => {
      let img = new Image() as any;
      img.src = event.target.result;
      
      img.onload = () => {
        const elem = document.createElement('canvas');
        elem.width = img.width*0.75;
        elem.height = img.height*0.75;
        const ctx = elem.getContext('2d');
        
        // img.width и img.height будет содержать оригинальные размеры
        ctx.drawImage(img, 0, 0, img.width*0.75, img.height*0.75);
        ctx.canvas.toBlob((blob) => {
          const file = new File([blob], initFile.name, {
            type: initFile.type,
            lastModified: Date.now()
          });
          file['width'] = initFile.width;
          file['height'] = initFile.height;
          if (initFile.size > file.size) {
            this.data.files.filter(x => x.is_compress == true)[i] = file
          }

          if (this.data.files.filter(x => x.is_compress == true).length - 1 == i) {
            this.isSubmit = false;
            this.trueClose()
          }
  
        }, initFile.type, 0.75);
      };
      
      reader.onerror = error => console.log("reader.onerror", error);
    };
  }

  toggleCompress(file) {
    file.is_compress = !file.is_compress;
  }

  understandFileType(val) {
    if (!val) {
      return ""
    }
    
    if (val == 'application/pdf') {
      return 'pdf'
    } else if (val.indexOf("/") != -1) {
      return val.split('/')[0]
    } else {
      return ""
    }
  }

  cancelFile(i) {
    this.data.files.splice(i,1);
    if (!this.data.files.length) {
      this.close()
    }
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  onPasteDeskInput(e) {
    console.log("onPasteDeskInput", e);
    e.preventDefault()
    var text = e.clipboardData.getData('text/plain')
    document.execCommand('insertText', false, text)
  }

  onPaste(e) {
    if (e && e.clipboardData && ((!!e.clipboardData.getData('text/html') && e.clipboardData.getData('text/html').indexOf('on_reports_copy') == -1) || e.clipboardData.getData('text/html') == '')) {
      e.preventDefault();
      const text = e.clipboardData.getData('text/plain').replaceAll("\n","<br>");
      document.execCommand('insertHTML', false, text);
    }
  }

  onCopy(event) {
    const selection = document.getSelection();
    if (selection) {
      const range = selection.getRangeAt(0);
      const clonedContent = range.cloneContents();
  
      const wrapperDiv = document.createElement('div');
      const span = document.createElement('span');
      span.classList.add('on_reports_copy')
      wrapperDiv.appendChild(clonedContent);
      wrapperDiv.appendChild(span);
  
      event.clipboardData?.setData('text/html', wrapperDiv.innerHTML);
      event.clipboardData?.setData('text/plain', selection?.toString().replace(/<br>|<BR>|<\/br>|<\/BR>/gi, '\n'));
      
      event.preventDefault();
    }
  }

  trueClose() {
    if (this.form.value.text) {
      if (this.data.files.length == 1) {
        this.data.files[0].text = this.form.value.text
        this.data.files[0].fileWithText = true
      } else{ 
        this.data.files.push({text: this.form.value.text, withoutFile: true, fileWithText: true})
      }
    }
    console.log('pre close', this.data.files);
    this.dialogRef.close({event: "send", data: this.data.files})
  }

  submit() {
    console.log("SUBMIT")
    if (this.data.files.filter(x => x.is_compress == true).length == 0) {
      this.trueClose()
    } else {
      this.isSubmit = true;
      this.data.files.filter(x => x.is_compress == true).forEach((x,i) => {
        this.compress(x, i)
      });
    }
  }

  handleDragOver(e) {
    console.log("Over")
    e.preventDefault();
    e.stopPropagation();
    this.dropOver = true
  }

  onFileDropped(e) {
    this.dropOver = false;

    if (!e.dataTransfer.files.length) {
      return false;
    }

    this.getFilesDataTransferItems(e.dataTransfer.items).then((files:any) => {
      if (files.length) {
        for (let index = 0; index < files.length; index++) {
          const x = files[index];
          let reader = new FileReader();
          reader.onload = () => {
            x.src = reader.result as string;
            console.log('reader', reader)
          }
          reader.readAsDataURL(x)
          if (x.type.indexOf("image") != -1) {
            x.is_compress = false;
            x.dontCompress = false;
          } else {
            x.is_compress = false;
            x.dontCompress = true;
          }
        }
        e.preventDefault();
        this.data.files.push(...files)
      }
    });
  }

  getFilesDataTransferItems(dataTransferItems) {
    function traverseFileTreePromise(item, folder) {
      return new Promise(resolve => {
        if (item.isFile) {
          item.file(file => {
            file.filepath = item.fullPath.replace(`/${file.name}`, "");
            folder.push(file);
            resolve(file);
          });
        } else if (item.isDirectory) {
          let dirReader = item.createReader();
          dirReader.readEntries(entries => {
            let entriesPromises = [];
            for (let entr of entries)
              entriesPromises.push(
                traverseFileTreePromise(entr, folder)
              );
            resolve(Promise.all(entriesPromises));
          });
        }
      });
    }
  
    let files = [];
    return new Promise((resolve, reject) => {
      let entriesPromises = [];
      for (let it of dataTransferItems)
        entriesPromises.push(
          traverseFileTreePromise(it.webkitGetAsEntry(), files)
        );
      Promise.all(entriesPromises).then(entries => {
        resolve(files);
      });
    });
  }

  pasteHandler(e) {
    console.log("Chat component", e)

    if (!e.clipboardData) {
      return
    };
    
    // e.preventDefault();
    let items = e.clipboardData.items;
    let files = []
    if (items) {
      for (var i = 0; i < items.length; i++) {
        var blob = items[i].getAsFile();
        if (!!blob) {
          files.push(blob);
        }
      }
    }

    

    if (files.length) {
      for (let index = 0; index < files.length; index++) {
        const x = files[index];
        let reader = new FileReader();
        reader.onload = () => {
          x.src = reader.result as string;
          console.log('reader', reader)
        }
        reader.readAsDataURL(x)
        if (x.type.indexOf("image") != -1) {
          x.is_compress = false;
          x.dontCompress = false;
        } else {
          x.is_compress = false;
          x.dontCompress = true;
        }
      }
      e.preventDefault();
      this.data.files.push(...files)
    }
  }

  ngOnDestroy(): void {
      this.clearSubscriptions()
  }

  @HostListener('window:paste',['$event'])
  onKeyPress($event) {
    console.log(document.getElementsByTagName('app-mini-chat'))
    this.pasteHandler($event)
  }

}
