<!-- NEW -->
<div mat-dialog-title class="mobile_interface_title">
    <div class="mobile_interface_title_in" style="overflow: hidden;">
        <ng-container [ngSwitch]="selectedIndex.value">
            <ng-container *ngSwitchCase="0">
                <div class="tab_title">
                    <span>
                        {{ "Add job" | translate }}
                    </span>
                    <div class="small_title">1 / 7 — {{ "JOB TYPE" | translate }}</div>
                </div>
            </ng-container>
            <ng-container *ngSwitchCase="1">
                <mat-tab-group class="title_tabs no_header" style="width: 100%;" [selectedIndex]="aboutIndex.value">
                    <mat-tab>
                        <div class="tab_title">
                            <span>
                                {{ "Add job" | translate }}
                            </span>
                            <span class="small_title">2 / 7 — {{ "ABOUT JOB" | translate }}</span>
                        </div>
                    </mat-tab>
                    <mat-tab>
                        <div class="tab_title" (click)="backAbout()">
                            <div class="d-f-c">
                                <div class="icon_back">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7 12L6.11612 11.1161C5.62796 11.6043 5.62796 12.3957 6.11612 12.8839L7 12ZM16.8839 3.88388C17.372 3.39573 17.372 2.60427 16.8839 2.11612C16.3957 1.62796 15.6043 1.62796 15.1161 2.11612L16.8839 3.88388ZM15.1161 21.8839C15.6043 22.372 16.3957 22.372 16.8839 21.8839C17.372 21.3957 17.372 20.6043 16.8839 20.1161L15.1161 21.8839ZM7.88388 12.8839L16.8839 3.88388L15.1161 2.11612L6.11612 11.1161L7.88388 12.8839ZM6.11612 12.8839L15.1161 21.8839L16.8839 20.1161L7.88388 11.1161L6.11612 12.8839Z" fill="#232323"/>
                                    </svg>
                                </div>
                                <span>
                                    {{ "Brief description" | translate }}
                                </span>
                            </div>
                            <span class="small_title with_i">2 / 7 — {{ "ABOUT JOB" | translate }}</span>
                        </div>
                    </mat-tab>
                    <mat-tab>
                        <div class="tab_title" (click)="backAbout()">
                            <div class="d-f-c">
                                <div class="icon_back">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7 12L6.11612 11.1161C5.62796 11.6043 5.62796 12.3957 6.11612 12.8839L7 12ZM16.8839 3.88388C17.372 3.39573 17.372 2.60427 16.8839 2.11612C16.3957 1.62796 15.6043 1.62796 15.1161 2.11612L16.8839 3.88388ZM15.1161 21.8839C15.6043 22.372 16.3957 22.372 16.8839 21.8839C17.372 21.3957 17.372 20.6043 16.8839 20.1161L15.1161 21.8839ZM7.88388 12.8839L16.8839 3.88388L15.1161 2.11612L6.11612 11.1161L7.88388 12.8839ZM6.11612 12.8839L15.1161 21.8839L16.8839 20.1161L7.88388 11.1161L6.11612 12.8839Z" fill="#232323"/>
                                    </svg>
                                </div>
                                <span>
                                    {{ "Hidden notes" | translate }}
                                </span>
                            </div>
                            <span class="small_title with_i">2 / 7 — {{ "ABOUT JOB" | translate }}</span>
                        </div>
                    </mat-tab>
                    <mat-tab>
                        <div class="tab_title" (click)="backAbout()">
                            <div class="d-f-c">
                                <div class="icon_back">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7 12L6.11612 11.1161C5.62796 11.6043 5.62796 12.3957 6.11612 12.8839L7 12ZM16.8839 3.88388C17.372 3.39573 17.372 2.60427 16.8839 2.11612C16.3957 1.62796 15.6043 1.62796 15.1161 2.11612L16.8839 3.88388ZM15.1161 21.8839C15.6043 22.372 16.3957 22.372 16.8839 21.8839C17.372 21.3957 17.372 20.6043 16.8839 20.1161L15.1161 21.8839ZM7.88388 12.8839L16.8839 3.88388L15.1161 2.11612L6.11612 11.1161L7.88388 12.8839ZM6.11612 12.8839L15.1161 21.8839L16.8839 20.1161L7.88388 11.1161L6.11612 12.8839Z" fill="#232323"/>
                                    </svg>
                                </div>
                                <span>
                                    {{ "Executor notes" | translate }}
                                </span>
                            </div>
                            <span class="small_title with_i">2 / 7 — {{ "ABOUT JOB" | translate }}</span>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </ng-container>
            <ng-container *ngSwitchCase="2">
                <div class="tab_title">
                    <span>
                        {{ "Add job" | translate }}
                    </span>
                    <div class="small_title">3 / 7 — {{ "EXECUTOR" | translate }}</div>
                </div>
            </ng-container>
            <ng-container *ngSwitchCase="3">
                <div class="tab_title">
                    <span>
                        {{ "Add job" | translate }}
                    </span>
                    <div class="small_title">
                        4 / 7 — {{ "STATUS" | translate }}
                    </div>
                </div>
            </ng-container>
            <ng-container *ngSwitchCase="4">
                <div class="tab_title">
                    <span>
                        {{ "Add job" | translate }}
                    </span>
                    <div class="small_title">
                        5 / 7 — {{ "PRIORITY" | translate }}
                    </div>
                </div>
            </ng-container>
            <ng-container *ngSwitchCase="5">
                <div class="tab_title">
                    <span>
                        {{ "Add job" | translate }}
                    </span>
                    <div class="small_title">
                        6 / 7 — {{ "SELECT AUTOMATION" | translate }}
                    </div>
                </div>
            </ng-container>
            <ng-container *ngSwitchCase="6">
                <mat-tab-group class="title_tabs no_header" style="width: 100%;" [selectedIndex]="priceIndex.value">
                    <mat-tab>
                        <div class="tab_title">
                            <span>
                                {{ "Add job" | translate }}
                            </span>
                            <span class="small_title">7 / 7 — {{ "WHEN & PRICE" | translate }}</span>
                        </div>
                    </mat-tab>
                    <mat-tab>
                        <div class="tab_title" (click)="backPrice()">
                            <div class="d-f-c">
                                <div class="icon_back">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7 12L6.11612 11.1161C5.62796 11.6043 5.62796 12.3957 6.11612 12.8839L7 12ZM16.8839 3.88388C17.372 3.39573 17.372 2.60427 16.8839 2.11612C16.3957 1.62796 15.6043 1.62796 15.1161 2.11612L16.8839 3.88388ZM15.1161 21.8839C15.6043 22.372 16.3957 22.372 16.8839 21.8839C17.372 21.3957 17.372 20.6043 16.8839 20.1161L15.1161 21.8839ZM7.88388 12.8839L16.8839 3.88388L15.1161 2.11612L6.11612 11.1161L7.88388 12.8839ZM6.11612 12.8839L15.1161 21.8839L16.8839 20.1161L7.88388 11.1161L6.11612 12.8839Z" fill="#232323"/>
                                    </svg>
                                </div>
                                <span>
                                    {{ "Date job was performed" | translate }}
                                </span>
                            </div>
                            <span class="small_title with_i">7 / 7 — {{ "WHEN & PRICE" | translate }}</span>
                        </div>
                    </mat-tab>
                    <mat-tab *ngIf="workEmpl.get('price')">
                        <div class="tab_title" (click)="backPrice()">
                            <div class="d-f-c">
                                <div class="icon_back">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7 12L6.11612 11.1161C5.62796 11.6043 5.62796 12.3957 6.11612 12.8839L7 12ZM16.8839 3.88388C17.372 3.39573 17.372 2.60427 16.8839 2.11612C16.3957 1.62796 15.6043 1.62796 15.1161 2.11612L16.8839 3.88388ZM15.1161 21.8839C15.6043 22.372 16.3957 22.372 16.8839 21.8839C17.372 21.3957 17.372 20.6043 16.8839 20.1161L15.1161 21.8839ZM7.88388 12.8839L16.8839 3.88388L15.1161 2.11612L6.11612 11.1161L7.88388 12.8839ZM6.11612 12.8839L15.1161 21.8839L16.8839 20.1161L7.88388 11.1161L6.11612 12.8839Z" fill="#232323"/>
                                    </svg>
                                </div>
                                <span>
                                    {{ "Price to be charged by the executor" | translate }}
                                </span>
                            </div>
                            <span class="small_title with_i">7 / 7 — {{ "WHEN & PRICE" | translate }}</span>
                        </div>
                    </mat-tab>
                    <mat-tab>
                        <div class="tab_title" (click)="backPrice()">
                            <div class="d-f-c">
                                <div class="icon_back">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7 12L6.11612 11.1161C5.62796 11.6043 5.62796 12.3957 6.11612 12.8839L7 12ZM16.8839 3.88388C17.372 3.39573 17.372 2.60427 16.8839 2.11612C16.3957 1.62796 15.6043 1.62796 15.1161 2.11612L16.8839 3.88388ZM15.1161 21.8839C15.6043 22.372 16.3957 22.372 16.8839 21.8839C17.372 21.3957 17.372 20.6043 16.8839 20.1161L15.1161 21.8839ZM7.88388 12.8839L16.8839 3.88388L15.1161 2.11612L6.11612 11.1161L7.88388 12.8839ZM6.11612 12.8839L15.1161 21.8839L16.8839 20.1161L7.88388 11.1161L6.11612 12.8839Z" fill="#232323"/>
                                    </svg>
                                </div>
                                <span>
                                    {{ "Points value for job " | translate }}
                                </span>
                            </div>
                            <span class="small_title with_i">7 / 7 — {{ "WHEN & PRICE" | translate }}</span>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </ng-container>
            <ng-container *ngSwitchDefault>
                <div class="d-f-c close_title">
                    <!-- <div class="icon_back" (click)="close()">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7 12L6.11612 11.1161C5.62796 11.6043 5.62796 12.3957 6.11612 12.8839L7 12ZM16.8839 3.88388C17.372 3.39573 17.372 2.60427 16.8839 2.11612C16.3957 1.62796 15.6043 1.62796 15.1161 2.11612L16.8839 3.88388ZM15.1161 21.8839C15.6043 22.372 16.3957 22.372 16.8839 21.8839C17.372 21.3957 17.372 20.6043 16.8839 20.1161L15.1161 21.8839ZM7.88388 12.8839L16.8839 3.88388L15.1161 2.11612L6.11612 11.1161L7.88388 12.8839ZM6.11612 12.8839L15.1161 21.8839L16.8839 20.1161L7.88388 11.1161L6.11612 12.8839Z" fill="#232323"/>
                        </svg>
                    </div> -->
                    <span>
                        {{ "Add job" | translate }}
                    </span>
                </div>
            </ng-container>
        </ng-container>
        <div class="selected_task" *ngIf="aboutIndex.value == 0 && priceIndex.value == 0">
            <b>{{data.task.custom_id}}</b> <span class="text_one_line">{{data.task.name}}</span>
        </div>
    </div>
</div>
<mat-dialog-content class="mobile_interface_content">
    <mat-tab-group class="no_header" [selectedIndex]="selectedIndex.value" (selectedIndexChange)="changeIndex($event)">
        <!-- 1 -->
        <mat-tab>
            <form [formGroup]="form" class="has_pads form_with_valids" style="position: relative;">
                <ng-container *ngIf="!form.get('operation_id').valid && submited">
                    <div class="mob_req">
                        <span>
                            {{ "You need to choose a value" | translate }}
                        </span>
                    </div>
                </ng-container>

                <mat-form-field appearance="legacy" class="full_width_form">
                    <mat-label>{{ 'Search' | translate }}</mat-label>
                    <input placeholder="{{ 'Search' | translate }}" matInput [formControl]="operationMoreControl">
                </mat-form-field>
    
                <div class="mobile_scrollable_int_tasks" *ngIf="operations?.length > 0">
                    <div class="items">
                        
                        <div class="item" *ngFor="let item of operations$ | async;" (click)="selectType(item)" [ngClass]="{'active': form.get('operation_id').value == item.id}">
                            <span class="text_one_line">
                                {{ item.name | translate }}
                            </span>
                        </div>
                    </div>
                </div>
            </form>
        </mat-tab>
        <!-- 2 -->
        <mat-tab>
            <mat-tab-group class="no_header" [selectedIndex]="aboutIndex.value" (selectedIndexChange)="changeAboutTab($event)">
                <mat-tab>
                    <div class="mobile_scrollable_int">
                        <form [formGroup]="form" class="form_with_valids has_pads">
                            <div class="white_wrp has_pads">
                                <mat-form-field appearance="standard" class="full_width_form">
                                    <!-- <ng-container *ngIf="!form.get('name').value && submited">
                                        <div class="req_field"></div>
                                        <div class="req_label">
                                            <div class="angle"></div>
                                            <span>
                                                {{ "You need to write here" | translate }}
                                            </span>
                                        </div>
                                    </ng-container> -->
                                    <mat-label>{{ 'Job title' | translate }}</mat-label>
                                    <input formControlName="name" matInput placeholder="{{ 'Name' | translate }}">
                                </mat-form-field>
                            </div>
                            <div class="white_wrp transp" style="margin-top: 40px !important;">
    
                                <div class="form_switch has_active" (click)="changeAboutTab(1)">
                                    <div class="d-f-c" style="justify-content: space-between !important;">
                                        <div class="d-f-c">
                                            <div class="name">Brief description</div>
                                        </div>
                                        <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0 10.59L4.59 6L0 1.41L1.42 0L7.42 6L1.42 12L0 10.59Z" fill="black"/>
                                        </svg>
                                    </div>
                                    <div class="active_values" *ngIf="form.get('comment').value">
                                        <span class="text_one_line">{{form.get('comment').value}}</span>
                                    </div>
                                </div>
        
                                <div class="form_switch has_active" (click)="changeAboutTab(2)">
                                    <div class="d-f-c" style="justify-content: space-between !important;">
                                        <div class="d-f-c" style="flex-direction: column; width: calc(100% - 20px); align-items: flex-start !important;">
                                            <div class="name">{{ "Hidden notes" | translate }}</div>
                                            <span>({{ "can see managers and above" | translate }})</span>
                                        </div>
            
                                        <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0 10.59L4.59 6L0 1.41L1.42 0L7.42 6L1.42 12L0 10.59Z" fill="black"/>
                                        </svg>
                                    </div>
                                    <div class="active_values" *ngIf="form.get('private_comment').value">
                                        <span class="text_one_line">{{form.get('private_comment').value}}</span>
                                    </div>
                                </div>
                                <div class="form_switch has_active" (click)="changeAboutTab(3)">
                                    <div class="d-f-c" style="justify-content: space-between !important;">
                                        <div class="d-f-c" style="flex-direction: column; width: calc(100% - 20px); align-items: flex-start !important;">
                                            <div class="name">{{ "Executor notes" | translate }}</div>
                                            <span>({{ "available only to owners, admins, managers and author of notes" | translate }})</span>
                                        </div>
            
                                        <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0 10.59L4.59 6L0 1.41L1.42 0L7.42 6L1.42 12L0 10.59Z" fill="black"/>
                                        </svg>
                                    </div>
                                    <div class="active_values" *ngIf="form.get('employee_comment').value">
                                        <span class="text_one_line">{{form.get('employee_comment').value}}</span>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </mat-tab>
                <mat-tab>
                    <div class="mobile_scrollable_int">
                        <form [formGroup]="form" class="form_with_valids has_pads">
                            <div class="white_wrp">
                                <mat-form-field appearance="standard" class="full_width_form">
                                    <textarea 
                                    cdkTextareaAutosize
                                    #autosize="cdkTextareaAutosize"
                                    cdkAutosizeMinRows="5"
                                    cdkAutosizeMaxRows="10" formControlName="comment" matInput></textarea>
                                </mat-form-field>
                            </div>
                        </form>
                    </div>
                </mat-tab>
                <mat-tab>
                    <div class="mobile_scrollable_int">
                        <form [formGroup]="form" class="form_with_valids has_pads">
                            <div class="white_wrp">
                                <mat-form-field appearance="standard" class="full_width_form">
                                    <textarea 
                                    cdkTextareaAutosize
                                    #autosize="cdkTextareaAutosize"
                                    cdkAutosizeMinRows="5"
                                    cdkAutosizeMaxRows="10" formControlName="private_comment" matInput></textarea>
                                </mat-form-field>
                            </div>
                        </form>
                    </div>
                </mat-tab>
                <mat-tab>
                    <div class="mobile_scrollable_int">
                        <form [formGroup]="form" class="form_with_valids has_pads">
                            <div class="white_wrp">
                                <mat-form-field appearance="standard" class="full_width_form">
                                    <textarea 
                                    cdkTextareaAutosize
                                    #autosize="cdkTextareaAutosize"
                                    cdkAutosizeMinRows="5"
                                    cdkAutosizeMaxRows="10" formControlName="employee_comment" matInput></textarea>
                                </mat-form-field>
                            </div>
                        </form>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </mat-tab>
        <!-- 3 -->
        <mat-tab>
            <mat-tab-group [selectedIndex]="executorIndex.value" (selectedIndexChange)="changeExecutorTab($event)">
                <!-- 0Executor -->
                <mat-tab>
                    <ng-template mat-tab-label>
                        {{ "Executor" | translate }}
                    </ng-template>
                    <form [formGroup]="workEmpl" class="has_pads form_with_valids" style="position: relative;">
        
                        <mat-form-field appearance="legacy" class="full_width_form">
                            <mat-label>{{ 'Search' | translate }}</mat-label>
                            <input placeholder="{{ 'Search' | translate }}" matInput [formControl]="employeeMoreControl">
                        </mat-form-field>
            
                        <div class="mobile_scrollable_int_tasks" *ngIf="employees?.length > 0">
                            <div class="items">
                                
                                <div class="item item_has_img" *ngFor="let item of employees$ | async;" (click)="toggleChip(item)" [ngClass]="{'active': workEmpl.get('employee_id').value == item.id}">
                                    <app-user [class]="'user_div'" [employee]="item"></app-user>
                                </div>
                            </div>
                        </div>
                    </form>
                </mat-tab>
                <!-- 1Executor -->
                <mat-tab *ngIf="partners?.length > 0">
                    <ng-template mat-tab-label>
                        {{ "Partner" | translate }}
                    </ng-template>
                    <form [formGroup]="partnerForm" class="has_pads form_with_valids" style="position: relative;">
        
                        <mat-form-field appearance="legacy" class="full_width_form">
                            <mat-label>{{ 'Search' | translate }}</mat-label>
                            <input placeholder="{{ 'Search' | translate }}" matInput [formControl]="partnerMoreControl">
                        </mat-form-field>
            
                        <div class="mobile_scrollable_int_tasks">
                            <div class="items">
                                <div class="item item_has_img" *ngFor="let item of partners$ | async;" (click)="togglePartnerChip(item)" [ngClass]="{'active': partnerForm.get('partner_company_id').value == item.partner_company_id}">
                                    <app-user [class]="'user_div'" [companyEmployee]="item?.partnerCompany" [imgRoute]="imgRoute" [host]="host" [company]="data?.company"></app-user>
                                </div>
                            </div>
                        </div>
                    </form>
                </mat-tab>
                <!-- 2Executor -->
                <mat-tab>
                    <ng-template mat-tab-label>
                        {{ "Additional" | translate }}
                    </ng-template>
                    <form [formGroup]="additional" class="has_pads form_with_valids" style="position: relative;">
        
                        <mat-form-field appearance="legacy" class="full_width_form">
                            <mat-label>{{ 'Search' | translate }}</mat-label>
                            <input placeholder="{{ 'Search' | translate }}" matInput [formControl]="addEmployeeMoreControl">
                        </mat-form-field>
            
                        <div class="mobile_scrollable_int_tasks" *ngIf="addEmployees?.length > 0">
                            <div class="items">
                                <div class="item item_has_img" *ngFor="let item of addEmployees$ | async;" (click)="(workEmpl.get('employee_id').value == item.id || !!partnerForm.get('partner_company_id').value) ? null : toggleAddChip(item)" [ngClass]="{'opacity-6': workEmpl.get('employee_id').value == item.id, 'active': additional.value.employee_id && additional.value.employee_id.length && additional.value.employee_id.includes(item.id)}" [disabled]="!!partnerForm.get('partner_company_id').value || workEmpl.get('employee_id').value == item.id">
                                    <app-user [class]="'user_div'" [employee]="item"></app-user>
                                </div>
                            </div>
                        </div>
                    </form>
                </mat-tab>
            </mat-tab-group>

        </mat-tab>
        <!-- 4 -->
        <mat-tab>
            <ng-container *ngIf="!form.get('status_id').value && submited">
                <div class="mob_req">
                    <span>
                        {{ "You need to choose a value" | translate }}
                    </span>
                </div>
            </ng-container>
            <div class="mobile_scrollable_int">
                <form [formGroup]="form" class="has_pads form_with_valids" *ngIf="operationStatuses?.length > 0">
        
                    <div class="items">
                        <div class="item" *ngFor="let item of operationStatuses" (click)="selectStatus(item)" 
                        [ngClass]="{
                        'active': form.get('status_id').value == item.id,
                        'first': item.id == '1',
                        'second': item.id == '2',
                        'third': item.id == '3',
                        'fours': item.id == '4',
                        'fives': item.id == '5',
                        'sixs': item.id == '6',
                        'nine': item.id == '97',
                        'seven': item.id == '98',
                        'eight': item.id == '99'}">
                            <span>
                                {{ item.name | translate }}
                            </span>
                        </div>
                    </div>
                </form>
            </div>
        </mat-tab>
        <!-- 5 -->
        <mat-tab>
            <div class="mobile_scrollable_int">
                <div class="mobile_scrollable_int">
                    <form [formGroup]="form" class="has_pads form_with_valids">
                        <div class="priority_chips">
                            <mat-chip-list [multiple]="false" selectable formControlName="priority">
                                <mat-chip *ngFor="let priority of prioritys" [ngClass]="{
                                    'Urgent': priority.id == '1000',
                                    'High': priority.id == '900',
                                    'Middle': priority.id == '800',
                                    'Low': priority.id == '700',
                                    'No': priority.id == '0'
                                }" class="priority_chip" (click)="selectPrioritys(priority)" [value]="priority.id">
                                    {{ priority.name | translate }}
                                </mat-chip>
                            </mat-chip-list>
                        </div>
                    </form>
                </div>

            </div>
        </mat-tab>
        <!-- 6 -->
        <mat-tab>
            <form [formGroup]="templateForm" class="has_pads form_with_valids">

                <mat-form-field appearance="legacy" class="full_width_form">
                    <mat-label>{{ 'Search' | translate }}</mat-label>
                    <input placeholder="{{ 'Search' | translate }}" matInput [formControl]="templatesMoreControl">
                </mat-form-field>
    
                <div class="mobile_scrollable_int_tasks" *ngIf="templates?.length > 0">
                    <div class="items">
                        <div class="item" *ngFor="let item of templates$ | async;" (click)="toggleChipTemplate(item)" [ngClass]="{'active': templateForm.value.template_id && templateForm.value.template_id.length && templateForm.value.template_id.includes(item.id)}">
                            <span class="text_one_line">
                                {{ item.name | translate }}
                            </span>
                        </div>
                    </div>
                </div>
            </form>
        </mat-tab>
        <!-- 7 -->
        <mat-tab>
            <mat-tab-group class="no_header" [selectedIndex]="priceIndex.value" (selectedIndexChange)="changePriceTab($event)">
                <!-- 0Price -->
                <mat-tab>
                    <div class="mobile_scrollable_int">
                        <form [formGroup]="form" class="form_with_valids has_pads">
       
                            <div class="white_wrp transp">
    
                                <div class="form_switch" style="margin-top: 15px !important;" (click)="changePriceTab(1)">
                                    <div class="d-f-c">
                                        <div class="name">{{ "Date job was performed" | translate }}</div>
                                    </div>
                                    <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0 10.59L4.59 6L0 1.41L1.42 0L7.42 6L1.42 12L0 10.59Z" fill="black"/>
                                    </svg>
                                </div>
        
                                <div class="form_switch" style="margin-top: 15px !important;" (click)="changePriceTab(2)" *ngIf="workEmpl.get('price')">
                                    <div class="d-f-c">
                                        <div class="name">{{ "Price to be charged by the executor" | translate }}</div>
                                    </div>
        
                                    <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0 10.59L4.59 6L0 1.41L1.42 0L7.42 6L1.42 12L0 10.59Z" fill="black"/>
                                    </svg>
                                </div>
                                <div class="form_switch" style="margin-top: 15px !important;" (click)="workEmpl.get('price') ? changePriceTab(3) : changePriceTab(2)">
                                    <div class="d-f-c">
                                        <div class="name">{{ "Points value for job" | translate }}</div>
                                    </div>
        
                                    <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0 10.59L4.59 6L0 1.41L1.42 0L7.42 6L1.42 12L0 10.59Z" fill="black"/>
                                    </svg>
                                </div>
                            </div>
                        </form>
                    </div>
                </mat-tab>
                <!-- 1Price -->
                <mat-tab>
                    <div class="mobile_scrollable_int">
                        <form [formGroup]="form" class="has_pads form_with_valids">
                            <div class="white_wrp has_pads">
                                <mat-form-field appearance="standard" class="full_width_form">
                                    <!-- <mat-label>{{ "Date the job was performed" | translate }}</mat-label> -->
                                    <input matInput [matDatepicker]="dp" formControlName="completed_at">
                                    <mat-datepicker-toggle matPrefix [for]="dp"></mat-datepicker-toggle>
                                    <mat-datepicker touchUi #dp></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </form>
                    </div>
                </mat-tab>
                <!-- 2Price -->
                <mat-tab *ngIf="workEmpl.get('price')">
                    <div class="mobile_scrollable_int">
                        <form [formGroup]="workEmpl" class="has_pads form_with_valids">
                            <div class="white_wrp has_pads">
                                <mat-form-field appearance="standard" class="full_width_form">
                                    <!-- <mat-label>{{ 'Price to be charged by executor' | translate }}</mat-label> -->
                                    <input formControlName="price" matInput type="number">
                                    <div class="cur_suffix" *ngIf="workEmpl && !!workEmpl.get('employee_id').value && !!getEmployeeById(workEmpl.get('employee_id').value)?.currency" matSuffix>{{ getEmployeeById(workEmpl.get('employee_id').value)?.currency?.iso_name }}</div>
                                </mat-form-field>
                            </div>
                        </form>
                    </div>
                </mat-tab>
                <!-- 3Price -->
                <mat-tab>
                    <div class="mobile_scrollable_int">
                        <form [formGroup]="workEmpl" class="has_pads form_with_valids">
                            <div class="white_wrp has_pads">
                                <mat-form-field *ngIf="workEmpl.get('rate')" appearance="standard" class="full_width_form">
                                    <!-- <mat-label>{{ 'Points value for job performance' | translate }}</mat-label> -->
                                    <input formControlName="rate" matInput type="number">
                                    <div class="cur_suffix" matSuffix>PTS</div>
                                </mat-form-field>
                            </div>
                        </form>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </mat-tab>
    </mat-tab-group>
</mat-dialog-content>


<mat-dialog-actions>
    <div class="mobile_interface_buttons" style="justify-content: space-between !important;">
        <ng-container *ngIf="aboutIndex.value == 0 && priceIndex.value == 0; else elseTemplateDownBtn">
            <button class="mobile_interface_btn" (click)="close()">
                {{ "Cancel" | translate }}
            </button>
        
            <div class="d-f-c">
                <button class="mobile_interface_btn" (click)="back()" style="padding: 8px 12px !important;" *ngIf="selectedIndex.value != 0">
                    {{ "Back" | translate }}
                </button>
                <button class="mobile_interface_btn mobile_interface_btn_white ml-1" *ngIf="selectedIndex.value == 5" (click)="next(selectedIndex.value)" style="padding: 8px 12px !important;">
                    {{ "Skip" | translate }}
                </button>
                <button class="mobile_interface_btn mobile_interface_btn_blue ml-1" (click)="next(selectedIndex.value)" *ngIf="selectedIndex.value != 6">
                    {{ "Next" | translate }}
                </button>
                <button class="mobile_interface_btn mobile_interface_btn_blue ml-1" (click)="onSubmit()" *ngIf="selectedIndex.value == 6" [disabled]="isSubmit">
                    <mat-spinner class="spin_16 mr-1" *ngIf="isSubmit"></mat-spinner>  
                    {{ "Finish" | translate }}
                </button>
            </div>
        </ng-container>
        <ng-template #elseTemplateDownBtn>
            <ng-container *ngIf="aboutIndex.value != 0; else elseTemplateAbout">
                <div>

                </div>
                <button class="mobile_interface_btn" (click)="cancelAbout()">
                    {{ "Cancel" | translate }}
                </button>
            </ng-container>
            <ng-template #elseTemplateAbout>
                <button class="mobile_interface_btn" (click)="backPrice()">
                    {{ "Back" | translate }}
                </button>
                
                <button class="mobile_interface_btn mobile_interface_btn_blue" (click)="backPrice()">
                    {{ "Save" | translate }}
                </button>
            </ng-template>
        </ng-template>
    </div>
</mat-dialog-actions>




<!-- OLD -->



<!-- <mat-dialog-content class="mat-typography" dirEnter (dirSubmit)="!form.valid || isSubmit ? null : onSubmit()">
    <div class="container form_wrapper">
        <div class="title_modal">
            {{ "New job" | translate }}
        </div>

        <form [formGroup]="partnerForm" class="form_with_pads form_with_valids">
            <div class="users_select" *ngIf="partners?.length > 0">
                <label>{{ "Choose a partner" | translate }}: </label>
                <mat-chip-list [multiple]="false" selectable formControlName="partner_company_id">
                    <mat-chip style="padding: 0 !important;" class="chip_user clear" (click)="togglePartnerChip({partner_company_id: 0})" [value]="0">
                        <mat-icon style="opacity: .7;">close</mat-icon>
                    </mat-chip>
                    <ng-container *ngFor="let partner of partners; let i = index">
                        <mat-chip class="chip_user" (click)="togglePartnerChip(partner)" [value]="partner.partner_company_id">
                            <app-user [class]="'user_div'" [companyEmployee]="partner?.partnerCompany" [imgRoute]="imgRoute" [host]="host" [company]="data?.company"></app-user>
                        </mat-chip>
                        <mat-chip *ngIf="i == partners.length - 1 && i > 6" class="chip_user more" (click)="partnerContainer.open()">
                            {{ "more" | translate }}
                        </mat-chip>
                    </ng-container>
                </mat-chip-list>
            </div>

            <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                <mat-select #partnerContainer formControlName="partner_company_id" (selectionChange)="togglePartnerChip($event)">
                    <mat-option>
                        <ngx-mat-select-search 
                        placeholderLabel="{{ 'Search' | translate }}" 
                        noEntriesFoundLabel="{{ 'no matching task found' | translate }}"
                        [formControl]="partnerMoreControl"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let partner of partners$ | async" [value]="partner?.partner_company_id">
                        <app-user [class]="'user_div'" [companyEmployee]="partner?.partnerCompany" [imgRoute]="imgRoute" [host]="host" [company]="data?.company"></app-user>
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </form>

        <form [formGroup]="additional" class="form_with_pads form_with_valids">
            <div class="users_select">
                <label>{{ "Choose an additional employee" | translate }}: </label>
                <mat-chip-list [multiple]="true" selectable formControlName="employee_id">
                    <ng-container *ngFor="let employee of employees; let i = index">
                        <mat-chip class="chip_user" (click)="workEmpl.get('employee_id').value == employee.id ? null : toggleAddChip(employee)" [value]="employee.id" [disabled]="!!partnerForm.get('partner_company_id').value || workEmpl.get('employee_id').value == employee.id">
                            <app-user [class]="'user_div'" [employee]="employee"></app-user>
                        </mat-chip>
                        <mat-chip *ngIf="i == employees.length - 1 && i > 6" class="chip_user more" (click)="addEmployeeIdContainer.open()">
                            {{ "more" | translate }}
                        </mat-chip>
                    </ng-container>
                </mat-chip-list>
            </div>

            <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                <mat-select #addEmployeeIdContainer formControlName="employee_id" multiple (selectionChange)="toggleAddChip($event)">
                    <mat-option>
                        <ngx-mat-select-search 
                        placeholderLabel="{{ 'Search' | translate }}" 
                        noEntriesFoundLabel="{{ 'no matching task found' | translate }}"
                        [formControl]="addEmployeeMoreControl"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let employee of addEmployees$ | async" [value]="employee.id" [ngClass]="{'opacity-6': workEmpl.get('employee_id').value == employee.id}" [disabled]="!!partnerForm.get('partner_company_id').value || workEmpl.get('employee_id').value == employee.id">
                        <app-user [class]="'user_div'" [employee]="employee"></app-user>
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </form>

    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <div class="f_w_p_btns">
        <button mat-button (click)="close()">
            {{ "Close" | translate }}
        </button>
        <button class="ml-1 d-f-btn" (click)="onSubmit()" cdkFocusInitial mat-raised-button color="primary" [disabled]="isSubmit">
            <mat-spinner class="spin_16 mr-1" *ngIf="isSubmit"></mat-spinner>  
            {{ "Save" | translate }}
        </button>
    </div>
</mat-dialog-actions> -->