import { NgxMatDateAdapter } from '@angular-material-components/datetime-picker';
import { SelectionModel } from '@angular/cdk/collections';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, SimpleChanges, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { ActivatedRoute, Router } from '@angular/router';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { mimeTypes } from 'mime-wrapper';
import { forkJoin, fromEvent, interval, merge, of, ReplaySubject, Subject, Subscription } from 'rxjs';
import { catchError, concatMap, debounceTime, distinctUntilChanged, filter, finalize, last, map, switchMap, take, takeUntil, takeWhile, tap } from 'rxjs/operators';
import { BaseClass } from '../../models/base-class';
import { LanguageService } from '../../services/common/language.service';
import { LayoutService } from '../../services/common/layout.service';
import { StorageManagerService } from '../../services/common/storage-manager.service';
import { AuthService } from '../../services/rest/auth.service';
import { CompanyService } from '../../services/rest/company.service';
import { ConnectedAppsService } from '../../services/rest/connected-apps.service';
import { FileService, Folder } from '../../services/rest/file.service';
import { RefreshService } from '../../services/rest/refresh.service';
import { TaskService } from '../../services/rest/task.service';
import { UploadService } from '../../services/rest/upload.service';
import { DeleteAlertComponent } from '../delete-alert/delete-alert.component';
import { SnackBarItem } from '../snack-bar/snack-bar-item';
import * as moment from 'moment-timezone';
import { MY_FORMATS } from 'src/app/components/atTasksDialog/task-profile-add/task-profile-add.component';
import { casesModel } from '../../functions/casesModel';
import { TargetParametersComponent } from 'src/app/components/atTasksDialog/target-parameters/target-parameters.component';
import { ScenariosService } from '../../services/rest/scenarios.service';
import { ClipboardService } from 'ngx-clipboard';
import { CreateTaskComponent } from 'src/app/components/atTasksDialog/create-task/create-task.component';
import { EmplCreateTaskComponent } from 'src/app/components/atTasksDialog/empl-create-task/empl-create-task.component';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ParametersService } from '../../services/rest/parameters.service';
import { MobAddTaskComponent } from 'src/app/components/mob-task/mob-add-task/mob-add-task.component';
import { SelectTaskTemplateComponent } from '../file-manager/dialog/select-task-template/select-task-template.component';
import { FilePrivacyComponent } from '../file-manager/dialog/file-privacy/file-privacy.component';
import { SelectContentComponent } from '../file-manager/dialog/select-content/select-content.component';
import { SelectChecklistComponent } from '../file-manager/dialog/select-checklist/select-checklist.component';
import { ApproveJobComponent } from '../file-manager/dialog/approve-job/approve-job.component';
import { FileSettingsComponent } from '../file-manager/dialog/file-settings/file-settings.component';
import { RenameComponent } from '../file-manager/dialog/rename/rename.component';
import { MobFmNavigationComponent } from './dialogs/mob-fm-navigation/mob-fm-navigation.component';
import { MobFmUploadComponent } from './dialogs/mob-fm-upload/mob-fm-upload.component';
import { MobFmCreateFolderComponent } from './dialogs/mob-fm-create-folder/mob-fm-create-folder.component';
import { MobFmViewComponent } from './dialogs/mob-fm-view/mob-fm-view.component';
import { environment } from 'src/environments/environment';
import { CreateArchiveComponent } from '../file-manager/dialog/create-archive/create-archive.component';
import { TimeFormatPipe } from '../../pipes/timeFormat';
import { Md5Service } from '../../services/common/media/md5.service';
import { AddEditNoteComponent } from '../add-edit-note/add-edit-note.component';
import { LoadingService } from '../../services/rest/loading.service';
import { AskFileComponent } from '../file-manager/dialog/ask-file/ask-file.component';
import { ExportAudioComponent } from '../file-manager/dialog/export-audio/export-audio.component';
import { DubbingComponent } from '../file-manager/dialog/dubbing/dubbing.component';
import { canTranscribe } from '../../consts/transcribe.extensions';
import { CardProjectComponent } from '../file-manager/dialog/cards-projects-list/dialog/card-project/card-project.component';
import { SpeechToTextComponent } from '../file-manager/dialog/speech-to-text/speech-to-text.component';
import { MobFileSettingsComponent } from '../file-manager/dialog/mob-file-settings/mob-file-settings.component';

@Component({
  selector: 'app-mob-file-manager',
  templateUrl: './mob-file-manager.component.html',
  styleUrls: ['./mob-file-manager.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})


export class MobFileManagerComponent extends BaseClass implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('filesContainer') filesContainer: ElementRef;
  // @ViewChild('mobUploaderFolder') mobUploaderFolder: ElementRef<HTMLInputElement>;
  @ViewChild('mobUploader') mobUploader: ElementRef<HTMLInputElement>;
  public form: FormGroup = this.fb.group({
    location: ''
  })
  public selectedFiles = new SelectionModel(
    true,
    []
  );
  public statusForm: FormGroup = this.fb.group({
    privacy_status: '',
    status_block: '',
  })

  public multiMode: boolean = false;
  public copyReqs: any[] = [];
  public done_all: boolean = false;
  public mob_fm_list_view: boolean = this.sm.localStorageGetItem('mob_fm_list_view') == "true" ? true : false;
  public origin = window.location.origin;
  public host: any = environment.host;
  public mode: any;
  throttle = 300;
  scrollUpDistance = 3.5;
  public company_id: any;
  public target_company_id: any;
  public task_id: any;
  public timeout: any;
  public imgRoute: any;
  public task_operation_id: any;
  public linkBefore: {} = {};
  public preview: any;
  public user: any;
  public uploadLimit: boolean = false;
  public timezones: any;
  public timezoneFilterCtrl: FormControl = new FormControl();
  protected _onDestroy = new Subject<void>();
    /** list of bank groups filtered by search keyword for option groups */
  public filteredTimezonesGroups: ReplaySubject<any> = new ReplaySubject<any>(1);
  
  @ViewChild('fileMenu') fileMenu: TemplateRef<any>;
  @ViewChild('outFileMenu') outFileMenu: TemplateRef<any>;
  overlayRef: OverlayRef | null;
  @Input() is_global: boolean = false;
  @Input() isGlobal: boolean = false;
  @Input() useOut: boolean = false;
  @Input() selectedMode: boolean = false;
  @Input() initLocation: any;
  @Input() initData: any;
  @Input() task: any;
  @Input() initCompanyId: any;
  @Input() selectedFileId: number;
  @Input() selectedFileIds: number[] = [];
  @Input() work: any;
  @Input() company: any;
  @Input() globalFilterFmFilesForm: any;
  @Input() globalFilterFmFiles: any;
  @Input() sortValue: any = 'filename';
  @Input() tasks: any;
  @Output() HideMenu = new EventEmitter<any>();
  @Output() CloseParentDialog = new EventEmitter<any>();
  @Output() onSelectFile = new EventEmitter<any>();
  public files: any = [];
  public canTranscribe: Function = canTranscribe;

  overlayParameterRefFilter: OverlayRef | null;
  public parameterFilterSub: Subscription;

  @ViewChild('filesFilterParameterMenu') filesFilterParameterMenu: TemplateRef<any>;
  public channelFiles: any = [];
  public folders: Folder[] = [
    {
      name: "Root",
      id: -1,
      location: "/",
      over: false,
      rootDir: true
    },
    {
      name: "Sources",
      id: 0,
      location: "/sources",
      over: false,
      rootDir: true
    },
    {
      name: "To Approve",
      id: 1,
      location: "/to_approve",
      over: false,
      rootDir: true
    },
    {
      name: "Ready Files",
      id: 2,
      location: "/ready_files",
      over: false,
      rootDir: true
    },
    {
      name: "Approved",
      id: 3,
      location: "/approved",
      over: false,
      rootDir: true
    },
    {
      name: "Project",
      id: 4,
      location: "/project",
      over: false,
      rootDir: true
    },
    {
      name: "Trash",
      id: 5,
      location: "/trash",
      over: false,
      rootDir: true
    }
  ]
  public _mimeTypes = mimeTypes
  public isLoad: boolean = false;
  public page: number = 1;
  public foldersCount: number = 1;

  public is_opened: boolean = true;

  public profilesPagination: any;
  public profilesPage: number = 1;
  public profiles: any;
  public activeFolder: any;
  public profilesMoreControl: FormControl = new FormControl();
  public profiles$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public copiedAllFiles: any;
  public filesCount: number = 1;
  public filesFilesize: number = 0;
  public folderFilesize: number = 0;
  public pagination: any;
  public isDragged: boolean = false;
  public usedFilter: boolean = false;
  public is_mobile: boolean = false;
  public crumbs: any[] = [{
    name: "Root",
    location: "/"
  }];
  public initCounter: number = 1;
  public systemDirs = ['sources', 'to_approve', 'ready_files', 'approved', 'project', 'trash']
  public timeZone = new Date().getTimezoneOffset();
  public sub: Subscription;
  public createdArchForDownSub: Subscription;
  public uploadProgress: Subscription;
  public activeLang: any;
  public operationsValues: any;
  public is_list: boolean = this.sm.localStorageGetItem('is_list') == "true" ? true : false;
  public fileRoute: FormGroup = this.fb.group({
    company_id: '',
    task_id: '',
    task_operation_id: '',
    profile_id: ''
  });
  public fmFilter: FormGroup = this.fb.group({
    q: '',
    meta_device: '',
    group_id: '',
    task_channel_id: '',
    operation_id: '',
    parameter_value_id: [[]],
    is_ready_if_approved: '',
    operation_reminder_id: [[]] 
  })

  public parameters: any;
  public allValues: any;
  public paramDataSub: Subscription
  public separatorKeysCodes: number[] = [ENTER, COMMA];
  
  public devices: any;
  public devices$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public devicesMoreControl: FormControl = new FormControl();

  public subOne: Subscription;
  public subTwo: Subscription;
  public subThird: Subscription;
  public subFour: Subscription;
  public subFiles: Subscription;
  public subFoldersCount: Subscription;
  public subFilesCount: Subscription;
  public refreshSub: Subscription;
  public copiedFiles: any;

  selectedDocuments: Array<any> = [];

  public groups: any;
  public groupsControl: FormControl = new FormControl();
  public groups$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public taskTemplates: any;
  public checklists: any;
  public checklistsControl: FormControl = new FormControl();
  public checklists$: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(
    private fileService: FileService,
    private connectedAppsService: ConnectedAppsService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private auth: AuthService,
    private _clipboardService: ClipboardService,
    private taskService: TaskService,
    private uploadService: UploadService,
    private md5Service: Md5Service,
    private scenariosService: ScenariosService,
    private companyService: CompanyService,
    private parametersService: ParametersService,
    public overlay: Overlay,
    private ls: LoadingService,
    private router: Router,
    public viewContainerRef: ViewContainerRef,
    private layoutService: LayoutService,
    private refreshService: RefreshService,
    private sm: StorageManagerService,
    private languageService: LanguageService,    
    private bottomSheet: MatBottomSheet,
    private _ngx_adapter: NgxMatDateAdapter<any>,
    private _adapter: DateAdapter<any>,
    ) { super() }

  ngOnInit(): void {
    if (window.innerWidth <= 769) {
      this.is_mobile = true;
    } else {
      this.is_mobile = false;
    }
    this.onResize();

    // alert(this.isMobile())
    if (this.sm.localStorageGetItem("copiedAllFiles")) {
      this.copiedAllFiles = JSON.parse(this.sm.localStorageGetItem("copiedAllFiles"))
    }
    if (this.is_global) {
      this.company_id = this.activatedRoute.snapshot.queryParamMap.get('company_id');
      this.target_company_id = this.company_id
      this.activeFolder = this.crumbs[0]
      this.getUser();
      this.getLangData();
      this.getOperations();
      this.getTimeZones();
      this.getImgRoute();

      this.getGlobalFiles();
      this.getGlobalFilesCount();
      this.getGlobalFoldersCount();

      this.getFileDevices();
      this.getGroupsCompany();
      this.getProfiles();
      this.getChecklists();

      this.attachSubscriptions(
        this.groupsControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchGroups(resp))
      )

      this.attachSubscriptions(
        this.profilesMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchProfiles(resp))
      )

      this.attachSubscriptions(
        this.checklistsControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchChecklists(resp))
      )
    } else {

      if (!!this.activatedRoute.snapshot.queryParamMap.get('company_id') || !!this.initCompanyId) {
        this.getCompany()
      } 

      if (this.sm.localStorageGetItem("copiedFiles")) {
        this.copiedFiles = JSON.parse(this.sm.localStorageGetItem("copiedFiles"))
      }
      // if (this.initData && this.initData.task) {
      //   this.company_id = this.initData.company ? this.initData.company.id : this.initData.task.company_id
      // }

      if (this.initData && this.initData.company && this.initData.company.id) {
        this.company_id = this.initData.company.id
      }

      if (this.initData && this.initData.task) {
        this.target_company_id = this.initData.task.company_id
      }
  
      console.log("FIRST", this.company_id)
      this.subFour = this.form.get("location").valueChanges.subscribe(resp => {
        console.log('form.get("location").valueChanges', resp)
        this.onLocationChange(resp)
      })
      // console.log( (document.getElementsByTagName("app-root")[0] as Element))
      // let root = document.getElementsByTagName("app-root")[0] as HTMLElement
      // root.style.display = "none";
      this.getUser();
      this.getImgRoute();
      this.getLangData();
      
      this.company = this.initData.company;
      console.log("TEST", this.company.filesize >= this.company.filesize_limit)
  
      this.subThird = this.uploadService.getUploadLimit().subscribe(resp => {
        console.log("getUploadLimit", resp)
        if (resp || this.company.filesize >= this.company.filesize_limit) {
          this.uploadLimit = true;
        }
      })
  
      this.subOne = this.timezoneFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterTimezoneGroups();
      });
  
      this.getOperations();
      this.getTimeZones();
      
      this.subTwo = this.fileRoute.valueChanges.subscribe(resp => {
        console.log("RESP", resp)
        // this.company_id = resp.company_id;
        this.task_id = resp.task_id;
        this.task_operation_id = resp.task_operation_id;  
        if (this.uploadProgress) {
          this.uploadProgress.unsubscribe()
        }
        if (this.fileRoute.value.profile_id) {
          this.getChannelContent();
          return
        }
  
        if (this.initLocation && this.initCounter == 1) {
          if (this.crumbs.filter(x => x.location == this.initLocation).length == 0) {
            this.crumbs.push({
              name: this.initLocation.replace("/", ""),
              location: this.initLocation
            })
          }
        }
        
        this.form.patchValue({
          location: !!this.initLocation && this.initCounter == 1 ? this.initLocation : '/'
        })
  
  
        if (this.task_id) {
          this.getTask();
        }
      })
  
      this.fileRoute.patchValue({
        company_id:  this.initData && this.initData.task ? this.initData.task.company_id : this.initData.company.id,
        task_id: this.initData && this.initData.task ? this.initData.task.id : 0,
        task_operation_id: !!this.initData.work ? this.initData.work.id : 0,
        profile_id: 0
      })
  
      this.getRootDirs();
      this.attachSubscriptions(
        this.devicesMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchDevices(resp))
      )
    }

    this.attachSubscriptions(
      this.fileService.getCopiesReq().subscribe(resp => {
        console.log(resp);
        resp.progress = 0;
        resp.is_done = 0;
        this.ls.requests$.next({
          value: 0,
          target: `Copy request (id: ${resp.id}) to ${resp.data.location}`
        })
        this.copyReqs.push(resp)
      })
    )

    interval(5000).pipe(
      filter(() => !!this.copyReqs.length),
      switchMap(() => {
        return forkJoin(this.copyReqs.map(k => this.fileService.getCopyReq(this.activatedRoute.snapshot.queryParamMap.get('company_id') || this.initCompanyId, {id: k.id} ).pipe(
          map(k => k.body[0]),
          switchMap(cReq => {
            if (cReq.is_done === 1 || cReq.progress === 100) {
              console.log('Задача завершена:', cReq.id, cReq);
              this.ls.requests$.next({
                value: 100,
                target: `Copy request (id: ${cReq.id}) to ${cReq.data.location}`
              })
              
              if (this.is_opened) {
                this.page = 1;
                this.pagination = undefined;
                if (!!this.is_global) {
                  this.getGlobalFiles();
                  this.getGlobalFilesCount();
                  this.getGlobalFoldersCount();
                } else {
                  this.folderFilesize = 0
                  this.getFiles(this.page, this.form.value.location);
                  this.getFilesCount(this.form.value.location);
                  this.getFoldersCount(this.form.value.location);
                }
              }
              this.copyReqs.splice(this.copyReqs.findIndex(q => q.id == cReq.id), 1);
              return of(cReq)
            } else {
              console.log('Задача в процессе:', cReq.id, cReq);
              this.ls.requests$.next({
                value: cReq.progress,
                target: `Copy request (id: ${cReq.id}) to ${cReq.data.location}`
              })
              return of(cReq)
            }
          })
        )))
      })
    ).subscribe(resp => {
      console.log("CheckProgress", resp);
    })

  }

  toggleView() {
    this.mob_fm_list_view = !this.mob_fm_list_view
    this.sm.localStorageSetItem('mob_fm_list_view', this.mob_fm_list_view)
  }

  toggleMultiMode() {
    this.multiMode = !this.multiMode;
    this.selectedFiles.clear();
  }
  
  getGlobalFoldersCount() {
    if (this.subFoldersCount) {
      this.subFoldersCount.unsubscribe()
    }

    let params = {...this.globalFilterFmFiles}
    // if (!!params.operation_reminder_id) {
    //   if (params.operation_reminder_id.length > 0) {
    //     params.operation_reminder_id = params.operation_reminder_id.join(",")
    //   } else {
    //     delete params.operation_reminder_id
    //   }
    // }
    delete params.operation_status_id
    if (!this.indicator()) {
      params.location = params.location_all;

      delete params.location_all;
    }
    console.log("params", params)
    this.subFoldersCount = this.fileService.getGlobalFileData("1", this.company_id, Object.assign(params, {is_dir: '1'}), '0').pipe(
      tap(el => this.foldersCount = el.headers.get('x-pagination-total-count'))
    ).subscribe(resp => {
      console.log(this.foldersCount)
    })
  }

  getGlobalFilesCount() {
    if (this.subFilesCount) {
      this.subFilesCount.unsubscribe()
    }

    let params = {...this.globalFilterFmFiles}
    // if (!!params.operation_reminder_id) {
    //   if (params.operation_reminder_id.length > 0) {
    //     params.operation_reminder_id = params.operation_reminder_id.join(",")
    //   } else {
    //     delete params.operation_reminder_id
    //   }
    // }
    delete params.operation_status_id

    console.log("params", params)
    if (!this.indicator()) {
      params.location = params.location_all;

      delete params.location_all;
    }
    this.subFilesCount = this.fileService.getGlobalFileData("1", this.company_id, Object.assign(params, {is_dir: '0'}), '0').pipe(
      tap(el => this.filesCount = el.headers.get('x-pagination-total-count'))
    ).subscribe(resp => {
      console.log(this.filesCount)
    })
  }


  indicator() {

    let paramsData = {...this.globalFilterFmFiles}
    delete paramsData.location_all;
    delete paramsData.count;
    delete paramsData.location;
    delete paramsData.operation_status_id;
    delete paramsData.task_id;
    delete paramsData.task_operation_id;
    delete paramsData.order_by;

    console.log("paramsData", paramsData);
    console.log("Object.values(paramsData)", Object.values(paramsData));
    console.log("Object.values(paramsData).filter(x => x != '' && !!x)", Object.values(paramsData).filter(x => x != "" && !!x));
    return Object.values(paramsData).filter(x => x != "" && !!x).length > 0
  }

  getGlobalFiles() {
    console.log("getGlobalFiles")
    if (this.subFiles) {
      this.subFiles.unsubscribe()
    }

    let params = {...this.globalFilterFmFiles}
    delete params.operation_status_id;
    // if (!!params.operation_reminder_id) {
    //   if (params.operation_reminder_id.length > 0) {
    //     params.operation_reminder_id = params.operation_reminder_id.join(",")
    //   } else {
    //     delete params.operation_reminder_id
    //   }
    // }



    if (!this.indicator()) {
      params.location = params.location_all;

      delete params.location_all;
    }

    console.log("params", params);


    this.isLoad = true;
    this.subFiles = this.fileService.getGlobalFileData(this.page, this.company_id, Object.assign(params , {summary: 1, order_by: this.sortValue})).pipe(
      tap(el => {
        this.pagination = {
          'pageCount': el.headers.get('x-pagination-page-count'),
          'perPage': el.headers.get('x-pagination-per-page'),
          'totalCount': el.headers.get('x-pagination-total-count'),
          'currentPage': el.headers.get('x-pagination-current-page'),
        }
        
        if (this.pagination['currentPage'] == 1) {
          if (this.filesContainer && this.filesContainer['hostElementRef'] && this.filesContainer['hostElementRef'].nativeElement) {
            this.filesContainer['hostElementRef'].nativeElement.scroll(0,0)
          }
          this.files = [];
          this.filesFilesize = el.headers.get('x-summary-filesize');
        }
      }),
      map(el => el.body),
    ).subscribe(resp => {
      console.log("getGlobalFiles", resp)
      resp.forEach(file => {
        if (file.parameterValuesToTask) {
          file.primaryTags = file.parameterValuesToTask.filter(x => !!x.is_primary)
        } else {
          file.primaryTags = []
        }
      })
      // this.folderFilesize = this.folderFilesize + resp.map(n => n.filesize).reduce((acc, number) => acc + number, 0)
      this.files.push(...resp);
      this.page = this.page + 1;
      this.isLoad = false;
    })
  }

  closeParentDialog() {
    if (this.is_global) {
      history.back();
    } else {
      this.CloseParentDialog.emit()
    }
  }

  // getInitedCrumbs() {

  // }
  
  onSearchChecklists(resp) {
    if (!this.checklists) {
      return;
    }

    if (!resp) {
      this.checklists$.next(this.checklists.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }

    // filter the banks
    this.checklists$.next(
      this.checklists.filter(b => b.text.toLowerCase().indexOf(resp) > -1)
    );
  }

  // mobUpload() {
  //   const dialogRef = this.dialog.open(MobFmUploadComponent, {
  //     backdropClass: ['mob_interface_backdrop'],
  //     panelClass: ['mob_interface_panel', 'animate__animated', 'animate__slideInUp'],
  //     disableClose: true,
  //     data: {
  //       company: this.company,
  //       company_id: this.company_id,
  //     }
  //   });

  //   this.attachSubscriptions(
  //     dialogRef.afterClosed().subscribe(result => {
  //       console.log(result)
  //       if (!!result) {
  //         if (result.event == 'file') {
  //           this.mobUploader.nativeElement.click()
  //         } else {
  //           this.mobUploaderFolder.nativeElement.click()
  //         }
  //       }
  //     })
  //   )
  // }

  createFolder() {
    const dialogRef = this.dialog.open(MobFmCreateFolderComponent, {
      backdropClass: ['mob_interface_backdrop'],
      panelClass: ['mob_interface_panel', 'animate__animated', 'animate__slideInUp'],
      disableClose: true,
      data: {
        company: this.company,
        company_id: this.company_id,
        target_company_id: this.target_company_id,
        globalFilterFmFiles: this.globalFilterFmFiles,
        task_id: this.task_id,
        work_id: this.task_operation_id,
        files: this.files,
        location: this.form.value.location,
        is_global: this.is_global
      }
    });

    // this.attachSubscriptions(
    //   dialogRef.afterClosed().subscribe(result => {
    //     if (!!result && result.event == 'create') {
    //       this.files.push(result.data)
    //     }
    //   })
    // )
  }

  openFoldersNav() {
    const dialogRef = this.dialog.open(MobFmNavigationComponent, {
      backdropClass: ['mob_interface_backdrop'],
      panelClass: ['mob_interface_panel', 'animate__animated', 'animate__slideInUp'],
      disableClose: true,
      data: {
        company: this.company,
        company_id: this.company_id,
        crumbs: this.crumbs,
        is_global: this.is_global,
        activeFolder: this.activeFolder,
        fileRoute: this.fileRoute.value,
        globalFilterFmFiles: this.globalFilterFmFiles,
        indicator: () => this.indicator()
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log(result)
        if (!!result && result.event == 'change') {
          if (this.is_global && result.data.folder.filename == 'Root') {
            this.crumbs = [this.crumbs[0]];
            this.router.navigate(['/files'], {queryParams: {
              company_id: this.company_id,
              location_all: '/',
              count: Math.round(Math.random()*100)
            }})
            return
          }
          if (result.data.crumbs.length) {
            this.crumbs = result.data.crumbs.slice();
            // let loc = result.data.folder + '/';
            // console.log("LOC", loc)
            this.page = 1;
            this.files = [];
            if (this.is_global) {
              this.form.patchValue({
                location: (result.data.folder.location == '/' ? result.data.folder.location : result.data.folder.location + "/") + result.data.folder.filename
              })
              let params = {...this.activatedRoute.queryParamMap.source['_value']}
      
              if (this.indicator()) {
                if (this.crumbs[this.crumbs.length - 1].is_nav) {
                  this.crumbs[this.crumbs.length - 1].params = {...this.activatedRoute.queryParamMap.source['_value']}
                } else {
                  this.crumbs.push({
                    name: 'Search',
                    params: {...this.activatedRoute.queryParamMap.source['_value']},
                    is_nav: true
                  })
                }
              }
      
              let newParams:any = {
                company_id: params.company_id
              }
      
              newParams.location_all = this.form.value.location;
              newParams.count = params.count ? +params.count + 1 : 1;
              if (result.data.folder.task_id) {
                newParams.task_id = result.data.folder.task_id;
              }
      
              if (result.data.folder.task_operation_id) {
                newParams.task_operation_id = result.data.folder.task_operation_id;
              }
      
              console.log("is_global newParams", newParams);
              this.router.navigate(['/files'], { queryParams: newParams })
              this.crumbs.push({
                name: result.data.folder.filename,
                location: result.data.folder.location,
                target: result.data.folder
              })
            } else {
              this.crumbs.push({
                name: result.data.folder.filename,
                location: result.data.folder.location,
                target: result.data.folder
              })
              this.form.patchValue({
                location: (this.form.value.location == '/' ? this.form.value.location : this.form.value.location + "/") + result.data.folder.filename
              })
            }
 
      
          } else {
            this.page = 1;
            this.files = [];
            this.crumbs = [{
              name: "Root",
              location: "/"
            }];
            this.form.patchValue({
              location: '/'
            })
            if (this.is_global) {
              let params = {...this.activatedRoute.queryParamMap.source['_value']}
              params.location_all = this.form.value.location
              params.count = params.count ? +params.count + 1 : 1;
              console.log("is_global params", params);
              this.router.navigate(['/files'], { queryParams: params })
            }
          }
        }
      })
    )
  }

  onSearchProfiles(resp) {
    if (!this.profiles) {
      return;
    }

    if (!resp) {
      this.profiles$.next(this.profiles.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }

    // filter the banks
    this.profiles$.next(
      this.profiles.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  onResize() {
    this.attachSubscriptions(
      fromEvent(window, "resize").pipe(
        map(() => window.innerWidth)
      ).subscribe((wWidth) => {
          if (wWidth <= 769) {
            this.is_mobile = true;
          } else {
            this.is_mobile = false;
          }
        }
      )
    )
  }

  onSearchGroups(resp) {
    if (!this.groups) {
      return;
    }

    if (!resp) {
      this.groups$.next(this.groups.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }

    // filter the banks
    this.groups$.next(
      this.groups.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  onSearchDevices(resp) {
    if (!this.devices) {
      return;
    }

    if (!resp) {
      this.devices$.next(this.devices.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.devices$.next(
      this.devices.filter(z => z.toLowerCase().indexOf(resp) > -1)
    );
  }

  getFileDevices() {
    this.attachSubscriptions(
      this.fileService.getFileDevices(this.activatedRoute.snapshot.queryParamMap.get('company_id') || this.initCompanyId).subscribe(resp => {
        console.log("getFileDevices", resp)
        this.devices = resp;
        this.devices$.next(this.devices.slice())
      })
    )
  }

  getPathForLink(val) {
    return val.replaceAll('//', '%2F').replaceAll('/', '%2F').replaceAll(' ', '%20')
  }

  neededParametersData(param) {
    let arr = []

    arr.push(
      this.parametersService.getApiParameterValues(this.company_id, param.id, "20", !!param.page ? param.page : "1").pipe(
        tap(x => {
          param.pagination = {
            'pageCount': x.headers.get('x-pagination-page-count'),
            'perPage': x.headers.get('x-pagination-per-page'),
            'totalCount': x.headers.get('x-pagination-total-count'),
            'currentPage': x.headers.get('x-pagination-current-page'),
          }
          param.page = 2
          param.values = x.body;
          if (x.body.length > 0) {
            param.hasValues = true;
          }
          this.getActiveValuesForParam(param)
        })
      )
    )

    return forkJoin(arr)
  }

  withSearch(searchText: string, val) {
    if (searchText == '' || typeof searchText != 'string' || val.value.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ) {
      return true
    } else {
      return false
    }
  }
  
  noOption(arr, id) {
    return arr.filter(x => x.id == id).length > 0 ? false : true;
  }

  getActiveValuesForParam(param) {
    let paramValues;
    if (param.id != 0) {
      paramValues = this.allValues.filter(b => b.parameter_id == param.id);
    } else {
      paramValues = this.allValues.slice();
    }

    param.activeValues = []
    this.fmFilter.value.parameter_value_id.forEach(x => {
      param.activeValues.push(...paramValues.filter(y => y.id == x))
    })
  }

  keySwitcher(key) {
    switch (key) {
      case 'task_parameter_value_id':
        return "activeTaskValues"
        break;
      case 'task_operation_parameter_value_id':
        return "activeJobValues"
        break;
      case 'discussion_parameter_value_id':
        return "activeDiscussionValues"
        break;
      case 'parameter_value_id':
        return "activeValues"
        break;
    }
  }
  
  onRemove(value, param, key) {
    console.log("onRemove", this.fmFilter.value)
    if (param.id == 0) {
      let parameterValues = this.parameters.find(x => x.id == value.parameter_id)[this.keySwitcher(key)]
      parameterValues.splice(parameterValues.indexOf(parameterValues.find(b => b.id == value.id)),1)
    }
    let val = this.fmFilter.value[key];
    val.splice(val.indexOf(value.id), 1);
    this.fmFilter.patchValue({
      [key]: val
    })
    if (param.id != 0) {
      this.getActiveValuesForParam(this.parameters[0]);
    }
    this.getActiveValuesForParam(param);
    this.filterFmFiles()
  }


  selected(e, param, key) {
    console.log("selected", e)

    
    if (param.id == 0) {
      console.log(this.parameters, e, param, key);
      let parameterValues = this.parameters.find(x => x.id == e.option.value.parameter_id)[this.keySwitcher(key)]
      parameterValues.push(e.option.value)
    }

    let val = this.fmFilter.value[key];
    val.push(e.option.value.id);
    this.fmFilter.patchValue({
      [key]: val
    })
    // param.search.patchValue("")
    // if (document.getElementById(`param_input_${param.id}`)) {
    //   (document.getElementById(`param_input_${param.id}`) as HTMLInputElement).value = ''
    // }
    if (param.id != 0) {
      this.getActiveValuesForParam(this.parameters[0]);
    }
    this.getActiveValuesForParam(param);
    this.filterFmFiles()
  }

  getValueById(id) {
    if (!this.allValues || this.allValues.length == 0) {
      return null
    }
    return this.allValues.find(x => x.id == id)
  }

  getParameterById(id) {
    if (!this.parameters || this.parameters.length == 0) {
      return null
    }
    return this.parameters.find(x => x.id == id)
  }

  getParameters() {
    this.attachSubscriptions(
      this.parametersService.getApiParameters(this.company.id).pipe(
        switchMap(res => {
          return this.parametersService.getAllValues('1', this.company.id, null, '1').pipe(
            switchMap(el => {
              let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
              let arr = []
              for (let index = 1; index <= pages; index++) {
                arr.push(index)
              }
    
              return forkJoin(arr.map(x => this.parametersService.getAllValues(x, this.company.id).pipe(map(u => u.body)))).pipe(
                last(),
                tap(values => {
                  this.allValues = [].concat(...values)
                  console.log("getAllApiParameterValues", this.allValues)
                }),
                map(() => res)
              )
            }),
            map(() => res)
          )
        }),
        tap(res => {
          res.unshift({
            company_id: this.company_id,
            created_at: 0,
            id: 0,
            is_new_value: 0,
            name: "All parameters",
            original_id: 0,
          })
          this.parametersService.filesParamsData$.next(res)
          res.forEach(n => {
            n.search = new FormControl('');
            n.search.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearch(resp, n))
          })
          this.parameters = res
        }),
      ).subscribe(resp => {
        console.log("this.parameters", this.parameters)
      })
    )
  }

  onSearch(searchText, param) {
    console.log(searchText, param)
    param.page = 1;
    this.parametersService.getApiParameterValues(this.company_id, param.id, "20", !!param.page ? param.page : "1",  !!param.search.value ? param.search.value : '').subscribe(resp => {
      param.pagination = {
        'pageCount': resp.headers.get('x-pagination-page-count'),
        'perPage': resp.headers.get('x-pagination-per-page'),
        'totalCount': resp.headers.get('x-pagination-total-count'),
        'currentPage': resp.headers.get('x-pagination-current-page'),
      }
      param.page = 2
      param.values = resp.body;
      console.log(resp.body)
      console.log(this.parameters)
    })
  }

  onScrollParams(param) {
    console.log(param.page, param.pagination.pageCount)
    if (param.pagination.pageCount >= param.page) {
      console.log("SCROLL !")
      this.parametersService.getApiParameterValues(this.company_id, param.id, "20", param.page, !!param.search.value ? param.search.value : '').pipe(take(1), map((x) => x.body)).subscribe(resp => {
        param.page++;
        param.values.push(...resp)
      })
    }
  }

  onLocationChange(resp) {
    console.log("onLocationChange", resp)
    this.usedFilter = false;
    this.initCounter++
    this.files = [];
    if (this.subFiles) {
      this.subFiles.unsubscribe()
    }
    if (this.subFilesCount) {
      this.subFilesCount.unsubscribe()
    }
    if (this.subFoldersCount) {
      this.subFoldersCount.unsubscribe()
    }
    if (this.refreshSub) {
      this.refreshSub.unsubscribe()
    }
    if (this.paramDataSub) {
      this.paramDataSub.unsubscribe();
    }
    this.folderFilesize = 0
    this.activeFolder = this.crumbs[this.crumbs.length - 1]

    let path = this.activeFolder.name == "Root" ? '/' : ((this.activeFolder.location != '/' ? (this.activeFolder.location + "/") : this.activeFolder.location) + this.activeFolder.name);
    this.getFiles(this.page, path);
    this.getFilesCount(path);
    this.getFoldersCount(path);
    this.getTaskTemplates();
    console.log("onLocationChange activeFolder", this.activeFolder)
    this.paramDataSub = this.parametersService.getFilesParamsStream().pipe(
      concatMap(params => {
        return forkJoin([...params.map(oneParam => this.neededParametersData(oneParam))]).pipe(last(),map(x => params))
      }),
    ).subscribe(res => console.log("-----getFilesParamsStream-----", res));
    
    this.getParameters()
    this.refresh();
  }

  add(e) {
    console.log(e)
  }
  
  filterFmFiles() {
    this.usedFilter = true;
    this.page = 1;
    this.files = [];
    this.getFilteredFiles(this.page, this.form.value.location);
  }

  getFilteredFiles(page, location) {
    if (this.subFiles) {
      this.subFiles.unsubscribe()
    }

    let params = {...this.fmFilter.value}
    if (params.operation_reminder_id.length > 0) {
      params.operation_reminder_id = params.operation_reminder_id.join(",")
    } else {
      delete params.operation_reminder_id
    }

    params.order_by = this.sortValue

    console.log("params", params)

    this.isLoad = true;
    this.subFiles = this.fileService.getFileData(page, this.company ? this.company.id : this.company_id, Object.assign({task_id: this.task_id, task_operation_id: this.task_operation_id, [!!this.task_id || Object.values(params).filter(x => x.toString() != '').length == 1 ? 'location' : 'location_all']: "/"}, params)).pipe(
      tap(el => {
        this.pagination = {
          'pageCount': el.headers.get('x-pagination-page-count'),
          'perPage': el.headers.get('x-pagination-per-page'),
          'totalCount': el.headers.get('x-pagination-total-count'),
          'currentPage': el.headers.get('x-pagination-current-page'),
        }
      }),
      map(el => el.body),
    ).subscribe(resp => {
      console.log("filterFmFiles", resp)
      resp.forEach(file => {
        if (file.parameterValuesToTask) {
          file.primaryTags = file.parameterValuesToTask.filter(x => !!x.is_primary)
        } else {
          file.primaryTags = []
        }
      })
      // this.folderFilesize = this.folderFilesize + resp.map(n => n.filesize).reduce((acc, number) => acc + number, 0)
      this.files.push(...resp);
      this.page = this.page + 1;
      this.isLoad = false;
    })
  }

  removeValueFromMultiple(key, value) {
    let arr = this.fmFilter.value[key];
    if (arr.includes(value)) {
      arr.splice(arr.indexOf(value), 1)
    }
    this.fmFilter.patchValue({
      [key]: arr
    })
    this.filterFmFiles()
  }

  removeValue(val) {
    if (val == "all") {
      this.fmFilter.patchValue({
        q: '',
        meta_device: '',
        group_id: '',
        task_channel_id: '',
        operation_id: '',
        is_ready_if_approved: '',
        operation_reminder_id: [[]] 
      })
      // this.filterFmFiles()
      this.page = 1;
      this.onLocationChange(this.form.value.location);
    } else {
      this.fmFilter.patchValue({
        [val]: ''
      })
      // this.filterFmFiles()
      this.page = 1;
      this.onLocationChange(this.form.value.location);
    }
  }

  resetFmFilter() {
    this.usedFilter = false;
    this.fmFilter.patchValue({
      q: '',
      meta_device: ''
    })
  }

  consoleFiles() {
    console.log(this.files);
  }

  getTaskTemplates() {
    this.attachSubscriptions(
      this.scenariosService.getTaskTemplates('1', {company_id: this.company ? this.company.id : this.company_id}, '200').pipe(
        map(el => el.body)
      ).subscribe(resp => {
        console.log("getAutos", resp);
        this.taskTemplates = resp;
      })
    )
  }

  createTaskBasedFilesV1(file) {
    this.close();

    let file_ids = [];
    if (this.selectedFiles.isSelected(file.id)) {
      file_ids = this.selectedFiles.selected.slice()
    } else {
      file_ids.push(file.id)
    }
    console.log('file_ids', file_ids)

    const dialogRef = this.dialog.open(SelectTaskTemplateComponent, {
      data: {
        company: this.company,
        user: this.user,
        file_ids: file_ids,
        taskTemplates: this.taskTemplates,
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        this.deSelectAll()
      })
    )
  }

  createTaskBasedFiles(file) {
    this.close();

    let file_ids = [];
    if (this.selectedFiles.isSelected(file.id)) {
      file_ids = this.selectedFiles.selected.slice()
    } else {
      file_ids.push(file.id)
    }
    console.log('file_ids', file_ids)

    if (this.company.permissions.includes("owner") || this.company.permissions.includes("manager") || this.company.permissions.includes("admin")) {
      this.createTask(this.company, file_ids);
      this.close();
    } else {
      this.emplCreateTask(this.company, file_ids)
      this.close();
    }
  }

  createTask(company, file_ids) {
    if (!this.is_mobile) {
      const dialogRef = this.dialog.open(CreateTaskComponent, {
        disableClose: true,
        data: {
          company_id: company.id,
          company: company,
          file_ids: file_ids,
          imgRoute: this.imgRoute,
          host: this.host,
          user: this.user
        }
      });
  
      this.attachSubscriptions(
        dialogRef.afterClosed().subscribe(result => {
          this.deSelectAll()
        })
      )
    } else {
      const dialogRef = this.dialog.open(MobAddTaskComponent, {
        backdropClass: ['mob_interface_backdrop'],
        panelClass: ['mob_interface_panel', 'animate__animated', 'animate__slideInUp'],
        disableClose: true,
        data: {
          company_id: company.id,
          company: company,
          file_ids: file_ids,
          imgRoute: this.imgRoute,
          host: this.host,
          user: this.user
        }
      });

      this.attachSubscriptions(
        dialogRef.afterClosed().subscribe(result => {
          this.deSelectAll()
        })
      )
    }

  }

  emplCreateTask(company, file_ids) {
    const dialogRef = this.dialog.open(EmplCreateTaskComponent, {
      disableClose: true,
      data: {
        company_id: company.id,
        company: company,
        file_ids: file_ids,
        imgRoute: this.imgRoute,
        host: this.host,
        user: this.user
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        this.deSelectAll()
      })
    )
  }


  copyLinkToFolder() {
    console.log("copyLinkToFolder")
    console.log(this.crumbs);
    // host + '/file/' + task?.acm + '?path=' + getPathForLink(form.get('location').value)
    if (this.crumbs[this.crumbs.length - 1].target && this.crumbs[this.crumbs.length - 1].target.acm) {
      this._clipboardService.copy(this.host + '/file/' + this.crumbs[this.crumbs.length - 1].target.acm + '?path=' + this.getPathForLink(this.form.get('location').value))
      this.copyLink("folder");
    } else if (this.files.length != 0) {
      this.attachSubscriptions(
        this.fileService.getFileData(1, this.company ? this.company.id : this.company_id, {is_dir: 1, task_id: this.task_id, task_operation_id: this.task_operation_id, [!!this.task_id ? 'location' : 'location_all']: this.crumbs.length > 1 ? this.crumbs[this.crumbs.length - 2].location : '/'}).pipe(
          map(x => x.body)
        ).subscribe(res => {
          if (!!res.length && res.find(x => x.filename == this.crumbs[this.crumbs.length - 1].name)) {
            let targetFolder = res.find(x => x.filename == this.crumbs[this.crumbs.length - 1].name)
            this._clipboardService.copy(this.host + '/file/' + targetFolder?.acm + '?path=' + this.getPathForLink(this.form.get('location').value))
            this.copyLink("folder");
          }
        })
      )
    } else {
      let folderData = {
        company_id: this.target_company_id,
        task_id: this.task_id,
        task_operation_id: this.task_operation_id,
        filename: this.crumbs[this.crumbs.length - 1].location.split("/")[this.crumbs[this.crumbs.length - 1].location.split("/").length - 1],
        location: '/',
        is_dir: 1
      }
      this.attachSubscriptions(
        this.fileService.uploadFile(folderData, this.company.id).subscribe(resp => {
          this.crumbs[this.crumbs.length - 1].target = resp
          this._clipboardService.copy(this.host + '/file/' + resp?.acm + '?path=' + this.getPathForLink(resp.location + '/' + resp.filename))
          this.copyLink("folder");
        }, error => {
          this.layoutService.showSnackBar({name: marker("Folder link")}, marker("Doesn't exist (The folder is not yet in the file manager)"), SnackBarItem)
        })
      )
    }
  }

  refresh() {
    this.refreshSub = this.refreshService.getRefreshFiles().pipe(
      switchMap(resp => {
        if (resp.task_id == this.fileRoute.value.task_id && resp.work_id == this.fileRoute.value.task_operation_id) {
          console.log("getRefreshFiles file DATA -------------------------", resp)
          console.log("getRefreshFiles crumbs ----------------------------", this.crumbs)
          console.log('getRefreshFiles location --------------------------', this.form.value.location)
  
          let folderNamePaths = resp.folder.path.split('/');
          console.log('folderNamePaths', folderNamePaths)
          let folderName = folderNamePaths[folderNamePaths.length - 1]
          console.log('folderName', folderName)
          
          if (this.form.value.location != resp.folder.path && this.files.filter(x => x.filename == folderName).length == 0) {
            return this.fileService.getFileData(1, this.company ? this.company.id : this.company_id, {task_id: this.task_id, task_operation_id: this.task_operation_id, location: this.form.value.location, is_dir: "1"}, "200")
            .pipe(
              map(b => b.body),
              tap(res => {
                console.log("getRefreshFolder +++++++++++++++++", res)
                res.forEach(dir => {
                  if (this.files.filter(x => x.id == dir.id).length == 0) {
                    this.files.push(dir);
                  }
                })
              }),
              map(b => resp),
            )
          } else {
            if (this.files.filter(x => !x.is_uploaded && x.filename == resp.data.filename).length != 0) {
              return this.fileService.getTargetFile(resp.data.upload_url.id, resp.company_id).pipe(
                tap(xFile => {
                  resp.user.name = resp.user.display_name
                  xFile.user = resp.user;
                  xFile.createdEmployee = resp.user;
                  this.files.splice(this.files.indexOf(this.files.find(y => y.id == resp.data.upload_url.id)), 1, xFile)
                }),
                map(b => resp)
              )
            } else {
              return of(resp)
            }
          }
        } else {
          return of(resp)
        }
      })
    ).subscribe(resp => {
      console.log("getRefreshFiles file RESULT =======================", resp)

      // this.fileService.getFileData(page, this.company ? this.company.id : this.company_id, {task_id: this.task_id, task_operation_id: this.task_operation_id, [!!this.task_id ? 'location' : 'location_all']: location}).pipe(
      //   tap(el => {
      //     this.pagination = {
      //       'pageCount': el.headers.get('x-pagination-page-count'),
      //       'perPage': el.headers.get('x-pagination-per-page'),
      //       'totalCount': el.headers.get('x-pagination-total-count'),
      //       'currentPage': el.headers.get('x-pagination-current-page'),
      //     }
      //   }),
      //   map(el => el.body),
      // ).subscribe(resp => {
      //   console.log("getFiles", resp)
      //   this.files.push(...resp);
      //   this.page = this.page + 1;
      //   this.isLoad = false;
      // })
    })
    
  }

  getCompany() {
    this.attachSubscriptions(
      this.companyService.getCompany(this.activatedRoute.snapshot.queryParamMap.get('company_id') ? this.activatedRoute.snapshot.queryParamMap.get('company_id') : this.initCompanyId).subscribe(resp => {
        this.companyService.company$.next(resp[0]);
        this.company = resp[0];
      })
    )
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.is_global) {
      if (this.initCompanyId) {
        this.company_id = this.initCompanyId
      }
      if (this.initData && this.initData.task) {
        this.target_company_id = this.initData.task.company_id
      }
    }
    if (this.is_global) {
      console.log("FILE MANAGER changes !!!!", changes)
      if (changes.company && changes.company.currentValue) {
        this.company = changes.company.currentValue
      }
      if (changes.globalFilterFmFiles && changes.globalFilterFmFiles.currentValue && this.company_id) {
        this.page = 1;
        this.pagination = undefined;
        this.getGlobalFiles();
        this.getGlobalFilesCount();
        this.getGlobalFoldersCount();
        this.activeFolder = this.crumbs[this.crumbs.length - 1]
      }
    } else if (this.useOut) {
      if (Object.keys(changes).length == 1 && changes.hasOwnProperty('selectedFileIds')) {
        return
      }
      console.log("FILE MANAGER changes !!!!", changes)
      if ((!!changes.initData && !!changes.initData.previousValue && (!changes.initData.previousValue.work || changes.initData.previousValue.work.id != changes.initData.currentValue.work.id))) {
        this.initCounter = 1;
        this.systemDirs = ['sources', 'to_approve', 'ready_files', 'approved', 'project', 'trash'];
        this.crumbs = [{
          name: "Root",
          location: "/"
        }];
        this.fileRoute.patchValue({
          company_id:  this.initData && this.initData.task ? this.initData.task.company_id : this.initData.company.id,
          task_id: this.initData && this.initData.task ? this.initData.task.id : 0,
          task_operation_id: !!this.initData.work ? this.initData.work.id : 0,
          profile_id: 0
        })
        this.getRootDirs();
      } else if (changes.initData && changes.initData.previousValue && (!changes.initData.previousValue.work || changes.initData.previousValue.work.status_id != changes.initData.currentValue.work.status_id || changes.initData.previousValue.work.operation_id != changes.initData.currentValue.work.operation_id)) {
        console.log("IN ASDQWEQWE!@#!#!@#")
        this.getTask();
        if (this.work) {
          this.work = changes.initData.currentValue.work
        }
      } else if (changes.initLocation && changes.initLocation.previousValue && changes.initLocation.previousValue != changes.initLocation.currentValue) {
        this.initCounter = 1;
        this.systemDirs = ['sources', 'to_approve', 'ready_files', 'approved', 'project', 'trash']
        this.crumbs = [{
          name: "Root",
          location: "/"
        }];
        if (this.crumbs.filter(x => x.location == this.initLocation).length == 0) {
          this.crumbs.push({
            name: this.initLocation.replace("/", ""),
            location: this.initLocation
          })
        }
        this.form.patchValue({
          location: !!this.initLocation ? this.initLocation : '/'
        })
        this.getRootDirs();
      }
    }
  }

  getChannelContent() {
    this.attachSubscriptions(
      this.connectedAppsService.getChannelContent(this.company_id, this.task_id, this.fileRoute.value.profile_id).pipe(
        switchMap(results => {
          console.log(results);
          if (results.filter(x => x.is_uploaded == 1 && !!x.channelAccount).length > 0) {
            return forkJoin(results.filter(x => x.is_uploaded == 1 && !!x.channelAccount).map(u => this.connectedAppsService.getYouTubeContentData(u.id).pipe(
              tap(value => {
                console.log(value)
                u.youTubeData = value
              })
            ))).pipe(map(() => results))
          } else {
            return of(results)
          }
        })
      ).subscribe(resp => {
        this.channelFiles = resp;
        if (resp.filter(x => x.uploading_error == "No YouTube `ChannelAccount` to upload to").length > 0) {
          this.layoutService.showSnackBar({name: ''}, marker("Upload is failed. Check channel authorization in the Reports App."), SnackBarItem)
        }
        this.checkUploadProgress();
        console.log("this.channelFiles", this.channelFiles)
      },
      error => {
        this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
      })
    )
  }

  getYouTubeData(item) {
    this.attachSubscriptions(
      this.connectedAppsService.getYouTubeContentData(item.id).subscribe(resp => {
        console.log("getYouTubeData single", resp)
        item.youTubeData = resp
      })
    )
  }

  checkUploadProgress() {
    this.uploadProgress = interval(2000).pipe(
      switchMap(() => {
        let arr = [];
        if (this.fileRoute.value.profile_id != 0) {
          arr.push(...this.channelFiles.filter(x => x.is_ready_to_upload == 1 && x.is_uploaded == 0 && x.uploading_error == ''))
        }
        return arr.length ? forkJoin(arr.map(u => this.connectedAppsService.getChannelContentItem(u.id))) : of(false)
      }),
      takeWhile(it => it != false)
    ).subscribe((resp:any) => {
      console.log(resp)
      if (resp) {
        resp.forEach(item => {
          this.channelFiles.find(b => b.id == item.id).is_uploaded = item.is_uploaded;
          this.channelFiles.find(b => b.id == item.id).uploading_error = item.uploading_error;
          this.channelFiles.find(b => b.id == item.id).uploading_progress = item.uploading_progress;
          this.channelFiles.find(b => b.id == item.id).is_uploading = item.is_uploading;
          if (item.is_uploaded == 1) {
            this.getYouTubeData(this.channelFiles.find(b => b.id == item.id))
          }
        });
      }
    })
  }

  
  checkIsManager(task, company, _user) { 
    return (task.managers && task.managers.filter(x => x.user_id == _user.id).length > 0) || (task.group && task.group.managers.filter(x => x.user_id == _user.id).length > 0) || company.permissions.includes('admin') || company?.permissions.includes('manager'); 
  }

  getOperations() {
    this.attachSubscriptions(
      this.taskService.getOperations(this.company_id || this.company.id, this.activeLang).subscribe(resp => {
        this.operationsValues = resp;
      })
    )
  }

  itemSelected(e) {
    if (e.filename != 'sources' && e.filename != 'to_approve' && e.filename != 'ready_files' && e.filename != 'project' && e.filename != 'trash') {
      this.selectedFiles.select(e.id);
    }
  }
  
  itemDeselected(e) {
    // if (!e.dragged) {
    //   this.selectedFiles.deselect(e.id);
    // }
  }

  getNextProfiles() {
    if (this.profilesPagination.pageCount >= this.profilesPage) {
      console.log("getNextProfiles")
      this.getProfiles()
    }
  }

  getProfiles() {
    this.attachSubscriptions(
      this.companyService.getProfiles(this.activatedRoute.snapshot.queryParamMap.get('company_id') || this.initCompanyId, this.profilesPage.toString()).pipe(
        tap(el => {
          this.profilesPagination = {
            'pageCount': el.headers.get('x-pagination-page-count'),
            'perPage': el.headers.get('x-pagination-per-page'),
            'totalCount': el.headers.get('x-pagination-total-count'),
            'currentPage': el.headers.get('x-pagination-current-page'),
          }
        }),
        map(el => el.body)
      ).subscribe(resp => {
        console.log("getProfiles", resp)

        if (this.profilesPage == 1) {
          this.profiles = resp
        } else {
          resp.forEach(profile => {
            if (this.profiles.filter(x => x.id == profile.id).length == 0) {
              this.profiles.push(...resp);
            }
          })
        }
        this.profiles$.next(this.profiles.slice());
        this.profilesPage = +this.profilesPage + 1;
      })
    )
  }

  dtsMouseDown(e) {
    console.log(e.target.className)
    if (e.target && e.which == 1 && typeof e.target.className == "string" && e.target.className.indexOf("files_wrp") != -1) {
      console.log(e,"dtsMouseDown");
      this.deSelectAll();
    }
  }

  ngAfterViewInit(): void {
    this.filesContainer.nativeElement.scroll(0, 0)
  }


  onDown(e) {
    console.log(e?.target?.scrollTop)
    if (!this.isLoad) {
      if (this.is_global) {
        console.log("SCROLL", this.page)
        console.log("SCROLL", this.pagination['pageCount'])
        if (this.page <= this.pagination['pageCount']) {
          this.getGlobalFiles();
        }
      } else {
        if (!this.usedFilter) {
          if (this.page <= this.pagination['pageCount']) {
            this.getFiles(this.page, this.form.value.location);
          }
        } else {
          if (this.page <= this.pagination['pageCount']) {
            this.getFilteredFiles(this.page, this.form.value.location);
          }
        }
      }
    }
  }

  getUser() {
    this.attachSubscriptions(
      this.auth.$user.subscribe(resp => {
        console.log("USER", resp)
        this.user = resp;
      })
    )
  }

  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData().subscribe(resp => {
        this.activeLang = resp.active;
        this._ngx_adapter.setLocale(resp.active)
        this._adapter.setLocale(resp.active);
      })
    )
  }

  toggleMode() {
    this.is_list = !this.is_list
    this.sm.localStorageSetItem('is_list', this.is_list)
  }
  
  selectSchedule(e:MatRadioChange) {
    console.log(e);
  }
  
  dubbingFile(file) {
    if (!!file.isImportingAudio) {
      this.layoutService.showSnackBar({name: 'Audio import'}, marker(" is already running"), SnackBarItem)
    }
    this.close()

    let _task = this.task || file.task;
    let _work;
    if (_task && _task.operations) {
      _work = _task.operations.find(z => z.id == this.fileRoute.value.task_operation_id) ? _task.operations.find(z => z.id == this.fileRoute.value.task_operation_id) : this.work;
    }
    
    let initDialogData:any = {
      file: file,
      company: this.company,
      company_id: this.company_id,
      task: _task,
      user: this.user,
      work: _work || file.work || file.taskOperation,
    }

    if (this.selectedFiles.selected.length > 1 && this.selectedFiles.selected.includes(file.id)) {
      let arr = [];
      this.selectedFiles.selected.forEach(id => {
        if (this.files.find(x => x.id == id)) {
          arr.push(this.files.find(x => x.id == id))
        }
      });

      initDialogData.files = arr;
    }

    const dialogRef = this.dialog.open(DubbingComponent, {
      data: initDialogData
    });
    
    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(resp => {
        console.log(resp)
      })
    )
  }

  exportAudio(file) {
    if (!!file.isImportingAudio) {
      this.layoutService.showSnackBar({name: 'Audio import'}, marker(" is already running"), SnackBarItem)
    }
    this.close()

    let initDialogData:any = {
      file: file,
      company: this.company,
      company_id: this.company_id,
    }

    if (this.selectedFiles.selected.length > 1 && this.selectedFiles.selected.includes(file.id)) {
      let arr = [];
      this.selectedFiles.selected.forEach(id => {
        if (this.files.find(x => x.id == id)) {
          arr.push(this.files.find(x => x.id == id))
        }
      });

      initDialogData.files = arr;
    }

    const dialogRef = this.dialog.open(ExportAudioComponent, {
      data: initDialogData
    });
    
    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(resp => {
        console.log(resp)
      })
    )
  }
  
  getFile(file) {
    this.attachSubscriptions(
      this.fileService.getFilesPreview(this.company.id, [file.id]).pipe(
        map(arr => arr[0])
      ).subscribe(resp => {
        this.files.splice(this.files.indexOf(file),1,resp)
        console.log("RESP", resp)
        console.log("FILE", resp)
      })
    )
  }
  
  transcribeFileV2(file) {
    this.close();
    const dialogRef = this.dialog.open(SpeechToTextComponent, {
      backdropClass: ['speech_file_backdrop'],
      panelClass: ['speech_file_pane', 'no_padding_dialog'],
      data: {
        company: this.company,
        file: file,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getFile(file)
    })
  }

  open(event: { x: number; y: number; event: MouseEvent | TouchEvent }, file: any) {
    // alert("open");
    event.event.preventDefault();
    event.event.stopPropagation();
  
    const { x, y } = event;
  
    // alert("Открытие меню в"+ x + ' ' + y);
  
    this.close();
    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo({ x, y })
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        }
      ]);
  
    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });
  
    if (file.task_operation_id) {
      this.attachSubscriptions(
        this.taskService.getOperationReminder(this.company_id, { task_operation_id: file.task_operation_id }).subscribe(resp => {
          file.checklist = resp.body;
        })
      )
    }
  
    if (file == 'outFiles') {
      this.overlayRef.attach(new TemplatePortal(this.outFileMenu, this.viewContainerRef, {
        $implicit: {}
      }));
    } else {
      this.overlayRef.attach(new TemplatePortal(this.fileMenu, this.viewContainerRef, {
        $implicit: file
      }));
    }
  
    this.sub = merge(
        fromEvent<MouseEvent>(document, 'click'),
        fromEvent<TouchEvent>(document, 'touchstart') // Закрывает меню при касании (скролле)
      ).pipe(
        filter(event => {
          const clickTarget = event.target as HTMLElement;
          return !!this.overlayRef && !this.overlayRef.overlayElement.contains(clickTarget);
        }),
        take(1)
      ).subscribe(() => this.close());
  }
  

  getChecklists() {
    this.attachSubscriptions(
      this.taskService.getOperationReminder(this.activatedRoute.snapshot.queryParamMap.get('company_id') || this.initCompanyId).subscribe(resp => {
        this.checklists = resp.body;
        this.checklists$.next(this.checklists.slice());

        console.log("getChecklists", this.checklists)
      })
    )
  }
  
  close() {
    this.sub && this.sub.unsubscribe();
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
  }

  getColumnName(id) {
    if (!this.operationsValues || this.operationsValues.filter(el => el.id == id).length == 0) {
      return '';
    }
    return this.operationsValues.find(el => el.id == id) && this.operationsValues.find(el => el.id == id).translation ? this.operationsValues.find(el => el.id == id).translation.name : this.operationsValues.find(el => el.id == id).name
  }

  getOperationStatusName(id) {
    switch (+id) {
      case 1:
        return "Waiting";
        break;
      case 2:
        return "In process";
        break;
      case 3:
        return "To approve";
        break;
      case 4:
        return "Approved";
        break;
      case 5:
        return "To correct";
        break;
      case 6:
        return "Sources Needed";
        break;
      case 97:
        return "On hold";
        break;
      case 98:
        return "Ready";
        break;
      case 99:
        return "Canceled";
        break;
    }
  }

  toggleTask(e, task) {
    e.preventDefault();
    e.stopPropagation();
    task.is_open = !task.is_open;
  }

  getTask() {
    this.attachSubscriptions(
      this.taskService.getTaskForManager(this.company ? this.company.id : this.company_id, this.task_id)
      .pipe(
        map(el => {
          if (el[0]) {
            if (this.task_operation_id != "0") {
              el[0].operations.find(x => x.id == this.task_operation_id).is_active = true;
              el[0].is_open = true;
            } else {
              el[0].is_open = false;
            }
            return casesModel(el, [], 'update').arr[0]
          } else {
            return false
          }
        }),
        switchMap(resp => {
          let expPermFilter:any = {task_id: this.task_id}
          if (this.company_id != this.target_company_id) {
            expPermFilter.partner_company_id = this.target_company_id
          }
          return this.taskService.getExpandPermUrls('1', this.company_id, expPermFilter, '100').pipe(
            tap(rslt => {
              resp.uniqueChannels = [];
              rslt.sort((a, b) => {
                if (a.content_status_id === 13 && b.content_status_id !== 13) {
                  return -1;
                } else if (a.content_status_id === 1 && b.content_status_id !== 13 && b.content_status_id !== 1) {
                  return -1;
                } else if (b.content_status_id === 13 || b.content_status_id === 1) {
                  return 1;
                } else {
                  return 0;
                }
              });
              rslt.filter(p => !!p.permission_prepare_upload).forEach(channel => {
                if (channel.channel && [1,2,3,4].includes(channel.channel.platform_id) && !!channel.channel.channel_account_id) {
                  if (resp.uniqueChannels.filter(ch => ch.channel_id == channel.channel_id).length == 0) {
                    resp.uniqueChannels.push(channel)
                  } else {
                    if (!([1, 13].includes(resp.uniqueChannels.find(ch => ch.channel_id == channel.channel_id).content_status_id) && !resp.uniqueChannels.find(ch => ch.channel_id == channel.channel_id).content_url)) {
                      resp.uniqueChannels.splice(resp.uniqueChannels.indexOf(resp.uniqueChannels.find(ch => ch.channel_id == channel.channel_id)), 1, channel)
                    }
                  }
                }
              });
            }),
            map(() => resp)
          )
        })
      ).subscribe(resp => {
        this.task = resp;
        this.target_company_id = this.task.company_id;
        this.work = this.task.operations.filter(z => z.id == this.fileRoute.value.task_operation_id).length > 0 ? this.task.operations.find(z => z.id == this.fileRoute.value.task_operation_id) : undefined
        console.log("this.task", this.task)
      })
    )
  }

  channelClick(e, profile) {
    e.preventDefault();
    e.stopPropagation()
    this.page = 1;
    this.files = [];
    this.crumbs = [{
      name: "Root",
      location: "/"
    }];
    this.crumbs.push({
      name: profile.channel.name,
      location: profile.channel.name
    })
    this.fileRoute.patchValue({
      task_id: this.task.id,
      profile_id: profile.channel.id
    })
  }

  taskClick(e,t) {
    e.preventDefault();
    e.stopPropagation()
    this.page = 1;
    this.files = [];
    this.crumbs = [{
      name: "Root",
      location: "/"
    }];
    this.fileRoute.patchValue({
      task_id: t.id,
      task_operation_id: 0,
      profile_id: 0
    })
    // this.router.navigate([`/files`], { queryParams: {company_id: this.company_id, task_id: t.id, work_id: 0}});
  }

  jobClick(e,j) {
    e.preventDefault();
    this.page = 1;
    this.files = [];
    this.crumbs = [{
      name: "Root",
      location: "/"
    }];
    this.fileRoute.patchValue({
      task_operation_id: j.id
    })
    // this.router.navigate([`/files`], { queryParams: {company_id: this.company_id, task_id: this.task_id, work_id: j.id}});
  }

  selectAll() {
    this.done_all = true;
    this.files.map(file => {
      if (!this.systemDirs.includes(file.filename)) {
        this.selectedFiles.select(file.id);
      }
    })
  }

  deSelectAll() {
    this.done_all = false;
    this.selectedFiles.clear();
  }


  createCardsAndPublish() {
    this.close();

    let _task = this.task;
    let _work;
    if (_task && _task.operations) {
      _work = _task.operations.find(z => z.id == this.fileRoute.value.task_operation_id) ? _task.operations.find(z => z.id == this.fileRoute.value.task_operation_id) : this.work;
    }

    let allFiles = [];
    this.selectedFiles.selected.forEach(id => {
      let exFile;
      if (this.files.find(b => b.id == id)) {
        exFile = this.files.find(b => b.id == id);
      } 
      if (exFile) {
        allFiles.push(exFile)
      }
    })
 

    const dialogRef = this.dialog.open(CardProjectComponent, {
      panelClass: ['full_screen_modal', 'video_editor', 'video_editor_black'],
      autoFocus: false,
      data: {
        company_id: this.company_id,
        target_company_id: this.target_company_id,
        company: this.company,
        activeLang: this.activeLang,
        user: this.user,
        imgRoute: this.imgRoute,
        host: this.host,
        task: _task,
        work: _work,
        allFiles: allFiles
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log(result)
        this.multiMode = false;
        this.selectedFiles.clear();
      })
    )
  }
  
  fileClick(file) {
    if (file.is_dir) {
      this.page = 1;
      this.files = [];

      if (this.is_global) {
        this.crumbs.push({
          name: file.filename,
          location: this.form.value.location,
          target: file
        })
        this.form.patchValue({
          location: (file.location == '/' ? file.location : file.location + "/") + file.filename
        })
        let params = {...this.activatedRoute.queryParamMap.source['_value']}

        if (this.indicator()) {
          if (this.crumbs[this.crumbs.length - 1].is_nav) {
            this.crumbs[this.crumbs.length - 1].params = {...this.activatedRoute.queryParamMap.source['_value']}
          } else {
            this.crumbs.push({
              name: 'Search',
              params: {...this.activatedRoute.queryParamMap.source['_value']},
              is_nav: true
            })
          }
        }

        let newParams:any = {
          company_id: params.company_id
        }

        newParams.location_all = this.form.value.location;
        newParams.count = params.count ? +params.count + 1 : 1;
        if (file.task_id) {
          newParams.task_id = file.task_id;
        }

        if (file.task_operation_id) {
          newParams.task_operation_id = file.task_operation_id;
        }

        console.log("is_global newParams", newParams);
        this.router.navigate(['/files'], { queryParams: newParams })
      } else {
        this.crumbs.push({
          name: file.filename,
          location: this.form.value.location,
          target: file
        })
        this.form.patchValue({
          location: (this.form.value.location == '/' ? this.form.value.location : this.form.value.location + "/") + file.filename
        })

      }
    } else {
      if (!this.selectedMode) {
        if (this.multiMode) {
          this.selectedFiles.toggle(file.id);
          return
        }
        if(!file.is_uploaded && !file.is_document) {
          this.layoutService.showSnackBar({name: ''}, marker("This file is corrupted."), SnackBarItem)
          this.deSelectAll();
          return
        }
        if (file.content_type.indexOf("image") != -1) {
          this.previewVideo(file);
        } else if (file.content_type.indexOf("video") != -1) {
          this.previewVideo(file);
        } else if (file.content_type == 'application/pdf') {
          this.previewVideo(file);
        } else if (file.content_type.indexOf("audio") != -1) {
          this.previewVideo(file);
        } else if (!!file.thumbnail) {
          this.previewVideo(file);
        } else if (!!file.is_document) {
          this.previewVideo(file);
        } else {
          this.download(file);
        }
      } else {
        // this.selectedFileId = file.id
        this.onSelectFile.emit(file)
      }
      // window.open(environment.host + file.original, '_blank');
    }
    this.deSelectAll();
  }

  crumbClick(crumb,i) {
    if (this.is_global) {
      if (crumb.name == 'Root') {
        this.crumbs = this.crumbs.slice(0,i+1);
        this.router.navigate(['/files'], {queryParams: {
          company_id: this.company_id,
          location_all: '/',
          count: Math.round(Math.random()*100)
        }})
        return
      }
  
      if (crumb.is_nav) {
        console.log(this.crumbs);
        this.crumbs = this.crumbs.slice(0,i+1);
        this.router.navigate(['/files'], {queryParams: crumb.params})
        return
      }
    }
    
    this.page = 1;
    this.files = [];
    this.fileRoute.value.profile_id = 0;
    this.form.patchValue({
      location: crumb.location
    })

    console.log('crumb', crumb);
    if (this.is_global) {
      let params = {...this.activatedRoute.queryParamMap.source['_value']}
      params.location_all = crumb.location
      params.count = params.count ? +params.count + 1 : 1;
      if (crumb.target && crumb.target.task_id) {
        params.task_id = crumb.target.task_id;
      } else {
        delete params.task_id
      }
      if (crumb.target && crumb.target.task_operation_id) {
        params.task_operation_id = crumb.target.task_operation_id;
      } else {
        delete params.task_operation_id
      }
      console.log("is_global params", params);
      this.router.navigate(['/files'], { queryParams: params })
    }

    this.crumbs = this.crumbs.slice(0,i+1);

    console.log(this.crumbs);
  }

  folderClick(folder) {
    this.page = 1;
    this.files = [];
    this.form.patchValue({
      location: folder.location
    })

    this.fileRoute.value.profile_id = 0;

    this.crumbs = [{
      name: "Root",
      location: "/"
    }];

    if (folder.id != -1) {
      this.crumbs.push({
        name: folder.name,
        location: folder.location,
        target: folder
      })
    }

    if (this.is_global) {
      let params = {...this.activatedRoute.queryParamMap.source['_value']}
      params.location_all = folder.location
      params.count = params.count ? +params.count + 1 : 1;
      if (folder.task_id) {
        params.task_id = folder.task_id;
      } else {
        delete params.task_id
      }
      if (folder.task_operation_id) {
        params.task_operation_id = folder.task_operation_id;
      } else {
        delete params.task_operation_id
      }
      console.log("is_global params", params);
      this.router.navigate(['/files'], { queryParams: params })
    }

  }

  back() {
    this.page = 1;
    this.files = [];
    this.crumbs.pop();
    this.form.patchValue({
      location: this.crumbs[this.crumbs.length -1].location.toString()
    })

    if (this.is_global) {
      let params = {...this.activatedRoute.queryParamMap.source['_value']}
      params.location_all = this.crumbs[this.crumbs.length -1].location.toString()
      params.count = params.count ? +params.count + 1 : 1;
      console.log("is_global params", params);
      this.router.navigate(['/files'], { queryParams: params })
    }
  }

  understandFileType(val) {
    if (!val) {
      return ""
    }
    
    if (val == 'application/pdf') {
      return 'pdf'
    } else if (val.indexOf("/") != -1) {
      return val.split('/')[0]
    } else {
      return ""
    }
  }

  getRootDirs() {
    this.folders = [
      {
        name: "Root",
        id: -1,
        location: "/",
        over: false,
        rootDir: true
      },
      {
        name: "Sources",
        id: 0,
        location: "/sources",
        over: false,
        rootDir: true
      },
      {
        name: "To Approve",
        id: 1,
        location: "/to_approve",
        over: false,
        rootDir: true
      },
      {
        name: "Ready Files",
        id: 2,
        location: "/ready_files",
        over: false,
        rootDir: true
      },
      {
        name: "Approved",
        id: 3,
        location: "/approved",
        over: false,
        rootDir: true
      },
      {
        name: "Project",
        id: 4,
        location: "/project",
        over: false,
        rootDir: true
      },
      {
        name: "Trash",
        id: 5,
        location: "/trash",
        over: false,
        rootDir: true
      }
    ]
    this.attachSubscriptions(
      this.fileService.getFileData(1, this.company ? this.company.id : this.company_id, {task_id: this.task_id, task_operation_id: this.task_operation_id, location: '/', is_dir: "1"}).pipe(
        map(el => el.body)
      ).subscribe(resp => {
        console.log("getRootDirs", resp)
        resp.forEach(element => {
          if (!this.folders.find(x => x.location.indexOf(element.filename) != -1)) {
            element.name = element.filename;
            element.location = "/" + element.filename;
            element.rootDir = true;
            delete element.is_dir;
            this.folders.push(element)
          }
        });
      })
    )
  }

  fileLocation(file) {
    return file.location.indexOf("/to_approve") != -1
  }

  readyFile(file) {
    this.attachSubscriptions(
      this.fileService.editFile(file.id, {is_ready_if_approved: !!file.is_ready_if_approved ? 0 : 1}, this.company.id).subscribe(resp => {
        file.is_ready_if_approved = resp.is_ready_if_approved;

        if (!!file.is_ready_if_approved && !file.operation_reminder_id && file?.checklist && file?.checklist?.length > 0 && !this.company.permissions.includes('owner') && !this.checkIsManager(this.task, this.company, this.user)) {
          this.selectCheckList(file);
        }
      })
    )
  }

  getFoldersCount(location?) {
    this.subFoldersCount = this.fileService.getFileData("1", this.company ? this.company.id : this.company_id, {task_id: this.task_id, is_dir: '1', task_operation_id: this.task_operation_id, [!!this.task_id ? 'location' : 'location_all']: location}, '0').pipe(
      tap(el => this.foldersCount = el.headers.get('x-pagination-total-count'))
    ).subscribe(resp => {
      console.log(this.foldersCount)
    })
  }
  
  getFilesCount(location?) {
    this.subFilesCount = this.fileService.getFileData("1", this.company ? this.company.id : this.company_id, {task_id: this.task_id, is_dir: '0', task_operation_id: this.task_operation_id, [!!this.task_id ? 'location' : 'location_all']: location}, '0').pipe(
      tap(el => this.filesCount = el.headers.get('x-pagination-total-count'))
    ).subscribe(resp => {
      console.log(this.filesCount)
    })
  }


  sort(val) {
    console.log(val);

    if (this.is_global) {
      let params = {...this.activatedRoute.queryParamMap.source['_value']}
      params.order_by = val
  
      this.router.navigate(['/files'], {queryParams: params})
    } else {
      this.sortValue = val;
      this.filterFmFiles()
    }

  }

  getFiles(page?, location?) {
    // console.log("this.task", this.task)
    // console.log("this.work", this.work)
    // console.log("this.initData", this.initData);
    // console.log("this.company_id", this.company_id);
    console.log("getFilesQUERYES", page, this.company ? this.company.id : this.company_id, {summary: 1, task_id: this.task_id, task_operation_id: this.task_operation_id, location: location})
    this.isLoad = true;
    this.subFiles = this.fileService.getFileData(page, this.company ? this.company.id : this.company_id, {summary: 1, task_id: this.task_id, task_operation_id: this.task_operation_id, [!!this.task_id ? 'location' : 'location_all']: location, order_by: this.sortValue}).pipe(
      tap(el => {
        this.pagination = {
          'pageCount': el.headers.get('x-pagination-page-count'),
          'perPage': el.headers.get('x-pagination-per-page'),
          'totalCount': el.headers.get('x-pagination-total-count'),
          'currentPage': el.headers.get('x-pagination-current-page'),
        }

        console.log("el.headers", el.headers)

        if (this.pagination['currentPage'] == 1) {
          this.filesFilesize = el.headers.get('x-summary-filesize')
        }
      }),
      map(el => el.body),
    ).subscribe(resp => {
      console.log("getFiles", resp)
      this.folderFilesize = this.folderFilesize + resp.map(n => n.filesize).reduce((acc, number) => acc + number, 0)
      resp.forEach(file => {
        if (file.parameterValuesToTask) {
          file.primaryTags = file.parameterValuesToTask.filter(x => !!x.is_primary)
        } else {
          file.primaryTags = []
        }
      })
      if (page == 1) {
        this.files = [...resp]
      } else {
        this.files.push(...resp);
      }
      this.page = this.page + 1;
      this.isLoad = false;
    })
    
  }

  getLastCreatedArchive(folder) {
    if (folder.archives && folder.archives.filter(x => x.is_created == 1).length > 0) {
      return folder.archives.filter(x => x.is_created == 1)[folder.archives.filter(x => x.is_created == 1).length - 1]
    } else {
      return false
    }
  }

  
  archive(close?, file?, download?) {
    if (close) {
      this.close()
    }

    if (file) {
      console.log("archive", file)
      if (download) {
        if (file.archives.filter(x => x.is_created == 1).length > 0) {
          this.downloadArchive(file)
        } else {
          this.downloadFolder(file)
        }
      } else {
        this.createArchive(file)
      }
    } else {
      console.log("archive", "no file")
      if (this.crumbs[this.crumbs.length - 1].target && this.crumbs[this.crumbs.length - 1].target.acm) {
        console.log("archive", "no file", this.crumbs[this.crumbs.length - 1].target);
      } else {
        this.attachSubscriptions(
          this.fileService.getFileData(1, this.company ? this.company.id : this.company_id, {is_dir: 1, task_id: this.task_id, task_operation_id: this.task_operation_id, [!!this.task_id ? 'location' : 'location_all']: this.crumbs.length > 1 ? this.crumbs[this.crumbs.length - 2].location : '/'}).pipe(
            map(x => x.body)
          ).subscribe(res => {
            if (!!res.length && res.find(x => x.filename == this.crumbs[this.crumbs.length - 1].name)) {
              let targetFolder = res.find(x => x.filename == this.crumbs[this.crumbs.length - 1].name)
              if (download) {
                if (targetFolder.archives.filter(x => x.is_created == 1).length > 0) {
                  this.downloadArchive(targetFolder)
                } else {
                  this.downloadFolder(targetFolder)
                }
              } else {
                this.createArchive(targetFolder)
              }
              console.log("archive targetFolder", "no file", targetFolder)
            }
          })
        )
      }
    }
  }

  downloadArchive(folder) {
    let archive = folder.archives.filter(x => x.is_created == 1)[0]
    this.close();
    this.attachSubscriptions(
      this.fileService.downloadCreatedArchive(archive.id, this.company_id).subscribe(resp => {
        console.log(resp)
        if (resp.download_url.length) {
          async function loop() {
            for (let i = 0; i < resp.download_url.length; i++) {
              await new Promise((res) => setTimeout(res, i == 0 ? 0 : 4000)); // пауза перед очередным
              console.log(resp.download_url[i]); // вывод блока
              if (resp.download_url[i]) {
                let a = document.createElement('a');
                a.setAttribute('href', resp.download_url[i]);
                a.setAttribute('download','download');
                // a.target = "_blank"
                a.click();
              }
            }
          }
          loop();
        }
      })
    )
  }

  createArchive(folder) {
    const dialogRef = this.dialog.open(CreateArchiveComponent, {
      data: {
        company: this.company,
        target_company_id: this.target_company_id,
        company_id: this.company_id,
        folder: folder
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log(result)
        if (!!result && result.event == 'create') {
          this.page = 1;
          this.getFiles(this.page, this.form.value.location);
        }
      })
    )
  }
  
  hideMenu() {
    this.HideMenu.emit()
  }

  uploadFile(event) {
    if (event.target.files.length > 0) {
      for (let index = 0; index < event.target.files.length; index++) {
        this.company.filesize = this.company.filesize + event.target.files[index].size
      }
    }

    if (this.uploadLimit) {
      this.layoutService.showSnackBar({name: ''}, marker("This company has exceeded the file upload limit."), SnackBarItem)
      return
    }

    if (event.target.files.length > 0) {
      for (let index = 0; index < event.target.files.length; index++) { 
        const element = event.target.files[index];
        if (this.sm.localStorageGetItem('md5hash') == '1') {
          this.layoutService.showSnackBar({name: element.name}, marker("MD5hash calculation started"), SnackBarItem)
          this.md5Service.calculateMd5(element).subscribe((resp:any) => {
            console.log("md5Service", resp);
            this.putFile(element);
            this.layoutService.showSnackBar({name: resp.md5Hash}, new TimeFormatPipe().transform(Math.ceil(resp.duration / 1000)), SnackBarItem, 10000)
          })
        } else {
          this.putFile(element);
        }
      }
    } 

    if (this.company.filesize >= this.company.filesize_limit) {
      this.uploadService.uploadLimit$.next(true)
    }
  }

  uploadFolder(event) {
    if (event.target.files.length > 0) {
      for (let index = 0; index < event.target.files.length; index++) {
        this.company.filesize = this.company.filesize + event.target.files[index].size
      }
    }
    if (this.uploadLimit) {
      this.layoutService.showSnackBar({name: ''}, marker("This company has exceeded the file upload limit."), SnackBarItem)
      return
    }

    if (event.target.files.length > 0) {
      for (let index = 0; index < event.target.files.length; index++) {
        const element = event.target.files[index];
        this.putFile(element, true);
      }
    }
    if (this.company.filesize >= this.company.filesize_limit) {
      this.uploadService.uploadLimit$.next(true)
    }
  }

  putFile(file, is_folder:boolean = false, initPath = false) {
    let path = (this.activeFolder.location != '/' ? (this.activeFolder.location + "/") : this.activeFolder.location) + this.activeFolder.name;
    if (initPath) {
      path = initPath;
    }

    if (is_folder) {
      path = (path[path.length - 1] == '/' ? path : path + "/") + file.filepath
    }

    let _task = this.task || file.task;
    let _work;
    if (_task && _task.operations) {
      _work = _task.operations.find(z => z.id == this.fileRoute.value.task_operation_id) ? _task.operations.find(z => z.id == this.fileRoute.value.task_operation_id) : this.work;
    }

    if (!!_work) {
      if ((_work.status_id == 98 || _work.status_id == 4) && path.indexOf("to_approve") != -1) {
        this.layoutService.showSnackBar({name: ''}, marker("Work in completed state, you can't upload files to 'To Approve' folder. Upload to any other folder."), SnackBarItem)
        return
      }
    }

    let needDelete = []
    if (this.files.filter(x => x.filename == file.name && x.is_uploaded == 0 && x.location.indexOf('/trash') == -1).length > 0) {
      needDelete = this.files.filter(x => x.filename == file.name && x.is_uploaded == 0 && x.location.indexOf('/trash') == -1)
    }

    if (needDelete.length > 0) {
      forkJoin(needDelete.map(x => this.fileService.editFile(x.id, {"location": '/trash'}, this.company.id))).pipe(
        last(),
        switchMap(() => {
          needDelete.forEach(x => {
            if (this.files.find(u => u.id == x.id)) {
              this.files.splice(this.files.indexOf(this.files.find(u => u.id == x.id)), 1)
            }
          })
          return this.fileService.uploadFile({
            company_id: this.target_company_id,
            task_id: this.is_global ? (this.task_id || this.globalFilterFmFiles.task_id) : this.task_id,
            task_operation_id: this.is_global ? (this.task_operation_id || this.globalFilterFmFiles.task_operation_id) : this.task_operation_id,
            filesize: file.size,
            filename: file.name,
            content_type: file.type ? file.type : mimeTypes.getType(file.name),
            location: path,
            is_dir: 0
          }, this.company.id)
        })
      ).subscribe(resp => {
        this.fileService.files$.next({
          place: "file_manager",
          url: window.location.href,
          data: resp,
          files: this.files,
          folder: {
            is_folder: is_folder,
            path: path
          },
          task: _task,
          work: _work,
          activeLang: this.activeLang,
          operationsValues: this.operationsValues,
          location: this.crumbs[this.crumbs.length - 1].location,
          target: file,
          user: this.user,
          task_id: this.is_global ? (this.task_id || this.globalFilterFmFiles.task_id) : this.task_id,
          task_operation_id: this.is_global ? (this.task_operation_id || this.globalFilterFmFiles.task_operation_id) : this.task_operation_id,
          company: this.company,
          company_id: this.company.id,
          target_company_id: this.target_company_id
        })
      },
      error => {
        if (error == "Company limit exceeded") {
          this.layoutService.showSnackBar({name: ''}, marker("This company has exceeded the file upload limit."), SnackBarItem)
        } else {
          this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
        }
      })
    } else {
      this.fileService.uploadFile({
        company_id: this.target_company_id,
        task_id: this.is_global ? (this.task_id || this.globalFilterFmFiles.task_id) : this.task_id,
        task_operation_id: this.is_global ? (this.task_operation_id || this.globalFilterFmFiles.task_operation_id) : this.task_operation_id,
        filesize: file.size,
        filename: file.name,
        content_type: file.type ? file.type : mimeTypes.getType(file.name),
        location: path,
        is_dir: 0
      }, this.company.id).subscribe(resp => {
        this.fileService.files$.next({
          place: "file_manager",
          url: window.location.href,
          data: resp,
          files: this.files,
          folder: {
            is_folder: is_folder,
            path: path
          },
          task: _task,
          work: _work,
          activeLang: this.activeLang,
          operationsValues: this.operationsValues,
          location: this.crumbs[this.crumbs.length - 1].location,
          target: file,
          user: this.user,
          task_id: this.is_global ? (this.task_id || this.globalFilterFmFiles.task_id) : this.task_id,
          task_operation_id: this.is_global ? (this.task_operation_id || this.globalFilterFmFiles.task_operation_id) : this.task_operation_id,
          company: this.company,
          company_id: this.company.id,
          target_company_id: this.target_company_id
        })
      },
      error => {
        if (error == "Company limit exceeded") {
          this.layoutService.showSnackBar({name: ''}, marker("This company has exceeded the file upload limit."), SnackBarItem)
        } else {
          this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
        }
      })
    }
  }

  openTargetValues(info, element) {
    this.close()
    let initData:any = {
      company: this.company,
      task: element
    }

    initData.target = {
      task_id: info[0],
      task_operation_id: info[1],
      discussion_id: info[2],
      file_id: info[3]
    }
    if (element.company_id != this.company.id) {
      initData.target.partner_company_id = element.company_id
    }
    const dialogRef = this.dialog.open(TargetParametersComponent, {
      backdropClass: ['parameters_modal_backdrop'],
      panelClass: ['without_paddings_modal', 'parameters_modal'],
      data: initData
    });

    // this.attachSubscriptions(
    //   dialogRef.afterClosed().subscribe(result => {
    //     this.refreshService.refreshTask$.next({company_id: this.company_id, task_id: element.id})
    //   })
    // )
  }


  detectBrowserName() { 
    const agent = window.navigator.userAgent.toLowerCase()
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }

  handleDragStart(e, file) {
    // e.preventDefault();
    // e.stopPropagation();
    // e.stopImmediatePropagation();

    console.log("drag start")
    // return
    if (this.selectedFiles.isSelected(file.id)) {
      file.dragged = true;
      this.isDragged = true;

      if (this.detectBrowserName() != 'safari') {
        let canvas = document.createElement('canvas');
        canvas.style.zIndex = '9999999999999999';
        canvas.width = 40;
        canvas.height = 40;
        let ctx = canvas.getContext("2d");
        ctx.font = "14px Comic Sans MS";
        ctx.fillStyle = "black";
        ctx.textAlign = "left";
        ctx.fillText(this.selectedFiles.selected.length.toString(), canvas.width/2, canvas.height/2);
        e.dataTransfer.setDragImage(canvas, 0, 0);
      }
      e.dataTransfer.setData("application/my-app", this.selectedFiles.selected.join(","));
      this.files.map(x => {
        if (this.selectedFiles.selected.includes(x.id)) {
          x.dragged = true;
        }
      })
      console.log(e.dataTransfer.getData("application/my-app").split(',').map(Number))
    } else {
      e.dataTransfer.setData("application/my-app", file.id);
      this.isDragged = true;
      file.dragged = true;
      this.deSelectAll();
    }
  }

  handleDragOver(e, folder:Folder) {
    // e.preventDefault();
    // e.stopPropagation();
    // // e.stopImmediatePropagation();
    if (e.preventDefault) e.preventDefault(); // allows us to drop
    folder.over = true;
    e.dataTransfer.dropEffect = 'copy';
    return false;

    console.log("drag over")
    folder.over = true
  }

  handleDragEnter(e, folder:Folder) {
    console.log("drag enter")
    e.dataTransfer.dropEffect = 'copy';
    folder.over = true;
  }
  
  handleDragLeave(e, folder:Folder) {
    // e.preventDefault();
    // e.stopPropagation();
    // e.stopImmediatePropagation();

    
    console.log("drag leave")
    folder.over = false;
  }

  handleDrop(e, folder:any) {
    e.stopPropagation();
    // e.stopImmediatePropagation();
    // e.preventDefault();
    // this.isLoad = true;


    console.log("handle drop", e, folder)
    folder.over = false
    let file_ids = e.dataTransfer.getData("application/my-app").split(',').map(Number);
    console.log(file_ids);

    if (!file_ids || (folder.rootDir && this.files.find(x => x.id == file_ids[0]).location == folder.location)) {
      // this.isLoad = false;
      return
    }
    let locationData = "";
    if (folder.is_dir == 1) {
      locationData = folder.location == "/" ? folder.location + folder.filename : folder.location + "/" + folder.filename
    } else {
      locationData = folder.location;
    }

    this.attachSubscriptions(
      forkJoin(file_ids.map(x => this.handleDropFunc(x, locationData))).pipe(
        finalize(() => {
          file_ids.forEach((z,i) => {
            this.files.splice(this.files.indexOf(this.files.find(x => x.id == z)), 1)
            if (i == file_ids.length - 1) {
              this.deSelectAll();
            }
          })
        })
      ).subscribe(resp => {
        if (['/approved', '/ready_files'].includes(locationData)) {
          this.detectFiles();
        }
        console.log("fork handleDropFunc", resp)
        console.log("end handle drop", this.files)
      })
    )

  }

  onImgError(event){
    event.target.src = this.imgRoute+'/assets/img/image_black_48dp.svg'
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  handleDropFunc(id, locationData) {
    return this.fileService.editFile(id, {"location": (locationData == '/approved' && !!this.files.find(x => x.id == id).is_ready_if_approved) ? '/ready_files' : locationData}, this.company.id).pipe(
      filter(() => !!this.task_operation_id),
      switchMap(() => {
        if (locationData.indexOf("to_approve") != -1) {
          return this.taskService.editWork(this.task_operation_id, {is_to_approve_files: 1}, this.company_id).pipe(map(() => of(id)))
        } else if (locationData.indexOf("ready_files") != -1) {
          return this.taskService.editWork(this.task_operation_id, {is_approved_files: 1}, this.company_id).pipe(map(() => of(id)))
        } else {
          return of(id)
        }
      }),
      switchMap(() => {
        if (this.files.find(x => x.id == id).location.indexOf("to_approve") != -1) {
          return this.fileService.detectFiles("/to_approve", 0, this.company_id, this.task_operation_id).pipe(
            switchMap(val => {
              if (val.length == 0) {
                return this.taskService.editWork(this.task_operation_id, {is_to_approve_files: 0}, this.company_id).pipe(map(() => of(id)))
              } else {
                return of(id)
              }
            })
          )
        } else if (this.files.find(x => x.id == id).location.indexOf("ready_files") != -1) {
          return this.fileService.detectFiles("/ready_files", 0, this.company_id, this.task_operation_id).pipe(
            switchMap(val => {
              if (val.length == 0) {
                return this.taskService.editWork(this.task_operation_id, {is_approved_files: 0}, this.company_id).pipe(map(() => of(id)))
              } else {
                return of(id)
              }
            })
          )
        } else {
          return of(id)
        }
      })
    )    
  }

  onFileDropped(e, folder:any = false, file:any = false) {
    console.log("onFileDropped", e, folder)
    if (this.isDragged) {
      return
    }
    this.getFilesDataTransferItems(e.dataTransfer.items).then((files:any) => {
      console.log("this.company", this.company)
      if (files.length > 0) {
        for (let index = 0; index < files.length; index++) {
          this.company.filesize = this.company.filesize + files[index].size
        }
      }
    })

    if (this.uploadLimit) {
      this.layoutService.showSnackBar({name: ''}, marker("This company has exceeded the file upload limit."), SnackBarItem)
      return
    }
    if (!e.dataTransfer.files.length) {
      return false;
    }
    let locationVal:any = false;
    if (file) {
      locationVal = file.location + "/" + file.filename
    }
    if (folder) {
      locationVal = folder.location
    }
    this.getFilesDataTransferItems(e.dataTransfer.items).then((files:any) => {
      if (files.length > 0) {
        console.log("getFilesDataTransferItems",files);
        for (let index = 0; index < files.length; index++) {
          this.putFile(files[index], !!files[index].filepath, locationVal);
        }
      }
    });
    
    if (this.company.filesize >= this.company.filesize_limit) {
      this.uploadService.uploadLimit$.next(true)
    }
  }

  getFilesDataTransferItems(dataTransferItems) {
    function traverseFileTreePromise(item, folder) {
      return new Promise(resolve => {
        if (item.isFile) {
          item.file(file => {
            file.filepath = item.fullPath.replace(`/${file.name}`, "");
            folder.push(file);
            resolve(file);
          });
        } else if (item.isDirectory) {
          let dirReader = item.createReader();
          dirReader.readEntries(entries => {
            let entriesPromises = [];
            for (let entr of entries)
              entriesPromises.push(
                traverseFileTreePromise(entr, folder)
              );
            resolve(Promise.all(entriesPromises));
          });
        }
      });
    }
  
    let files = [];
    return new Promise((resolve, reject) => {
      let entriesPromises = [];
      for (let it of dataTransferItems)
        entriesPromises.push(
          traverseFileTreePromise(it.webkitGetAsEntry(), files)
        );
      Promise.all(entriesPromises).then(entries => {
        resolve(files);
      });
    });
  }

  copy(file) {
    this.close();
    let copyData = [];
    if (this.selectedFiles.selected.length && this.selectedFiles.selected.includes(file.id)) {
      this.selectedFiles.selected.forEach(x => {
        copyData.push(
          {
            company_id: this.files.find(b => b.id == x).company_id,
            id: x,
            taskChannelFiles: this.selectedFiles.selected.length > 5 ? [] : this.files.find(b => b.id == x).taskChannelFiles
          }
        )
      })
    } else {
      copyData.push({
        company_id: file.company_id,
        id: file.id,
        taskChannelFiles: file.taskChannelFiles
      })
    }

    this.sm.localStorageSetItem('copiedFiles', copyData);
    this.copiedFiles = copyData
  }

  copyLink(type) {
    this.layoutService.showSnackBar({name: marker(type + " link")}, marker("Copied"), SnackBarItem)
  }

  

  privacy(file) {
    this.close();
    console.log(file);

    const dialogRef = this.dialog.open(FilePrivacyComponent, {
      data: {
        file: file,
        company: this.company
      }
    });
    
    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(resp => [
        console.log(resp)
      ])
    )
  }

  pasteBack(folder?) {
    this.close();
    let location = ''
    if (folder) {
      location = (folder.location != '/' ? (folder.location + "/") : folder.location) + folder.filename
    } else {
      location = this.form.get("location").value
    }

    console.log("PASTE folder",  folder)
    console.log("PASTE location",  location)
    let path = (this.activeFolder.location != '/' ? (this.activeFolder.location + "/") : this.activeFolder.location) + (this.activeFolder.name == "Root" ? '' : this.activeFolder.name);
    console.log("PASTE path",  path)


    let ifAll = false;
    if (!!this.sm.localStorageGetItem("copiedAllFiles")) {
      ifAll = true;
    }
    // return

    this.copiedFiles = JSON.parse(this.sm.localStorageGetItem("copiedFiles"));
    
    // this.attachSubscriptions(
    //   this.fileService.addCopyReq(this.company.id || this.company_id, {
    //     "company_id": this.target_company_id || this.company.id || this.company_id,
    //     task_id: !this.is_global ? this.task_id : this.globalFilterFmFiles.task_id,
    //     task_operation_id: !this.is_global ? this.task_operation_id : this.globalFilterFmFiles.work_id,
    //     "data": {
    //       location: !this.is_global ? path : location,
    //       [ifAll ? 'file_filters' : 'files_ids']: ifAll ? JSON.parse(this.sm.localStorageGetItem("copiedAllFiles")) : this.copiedFiles.map(x => x.id)
    //     }
    //   }).subscribe(resp => {
    //     this.fileService.copyesReq$.next(resp);
    //     if (ifAll) {
    //       localStorage.removeItem('copiedAllFiles');
    //       this.copiedAllFiles = undefined;
    //     }
    //     console.log(resp)
    //     this.layoutService.showSnackBar({name: ''}, marker("File(s) copy request successful!"), SnackBarItem)
    //   }, error => {
    //     this.layoutService.showSnackBar({name: ''}, marker("File(s) copy request was unsuccessful!"), SnackBarItem)
    //   })
    // )

    let x:any = {
      "company_id": this.target_company_id || this.company.id || this.company_id,
      task_id: !this.is_global ? this.task_id : this.globalFilterFmFiles.task_id,
      task_operation_id: !this.is_global ? this.task_operation_id : this.globalFilterFmFiles.work_id,
      "data": {
        location: !this.is_global ? path : location,
        [ifAll ? 'file_filters' : 'files_ids']: ifAll ? JSON.parse(this.sm.localStorageGetItem("copiedAllFiles")) : this.copiedFiles.map(x => x.id)
      }
    }

    if (ifAll) {
      x.is_new = 1;
    } else {
      x.is_to_process = 1;
    }
    this.attachSubscriptions(
      this.fileService.addCopyReq(this.company.id || this.company_id, x).subscribe(resp => {
        console.log(resp)
        console.log("TEST", ifAll)
        if (ifAll) {
          localStorage.removeItem('copiedAllFiles');
          this.copiedAllFiles = undefined;
  
          const dialogRef = this.dialog.open(AskFileComponent, {
            data: {
              company: this.company,
              request: resp,
            }
          });

          dialogRef.afterClosed().subscribe(result => {
            console.log(result)
            if (result && result.event == 'yes') {
              this.fileService.copyesReq$.next(resp);
            }
          })

        } else {
          this.fileService.copyesReq$.next(resp);
        }
        // if (!!clear) {
        //   localStorage.removeItem('copiedFiles');
        //   this.copiedFiles = JSON.parse(this.sm.localStorageGetItem("copiedFiles"));
        // }
        this.layoutService.showSnackBar({name: ''}, marker("File(s) copy request successful!"), SnackBarItem)
      }, error => {
        this.layoutService.showSnackBar({name: ''}, marker("File(s) copy request was unsuccessful!"), SnackBarItem)
      })
    )
  }

  paste(folder?) {
    this.close();
    let location = ''
    if (folder) {
      location = (folder.location != '/' ? (folder.location + "/") : folder.location) + folder.filename
    } else {
      location = this.form.get("location").value
    }

    console.log("PASTE folder",  folder)
    console.log("PASTE location",  location)
    let path = (this.activeFolder.location != '/' ? (this.activeFolder.location + "/") : this.activeFolder.location) + (this.activeFolder.name == "Root" ? '' : this.activeFolder.name);
    console.log("PASTE path",  path)

    // return

    this.copiedFiles = JSON.parse(this.sm.localStorageGetItem("copiedFiles"));
    
    this.attachSubscriptions(
      forkJoin(this.copiedFiles.map(x => this.fileService.copyFile(Object.assign({
        location: !this.is_global ? path : location,
        task_id: !this.is_global ? this.task_id : this.globalFilterFmFiles.task_id,
        task_operation_id: !this.is_global ? this.task_operation_id : this.globalFilterFmFiles.work_id,
      }, {id: x.id, company_id: this.target_company_id || this.company.id || this.company_id || x.company_id}), this.company.id)
      .pipe(
        switchMap((res) => {
          if (!folder) {
            return this.fileService.getTargetFile(res.id, this.company.id || x.company_id).pipe(
              tap(b => {
                this.user.name = this.user.display_name
                b.user = this.user
                b.createdEmployee = this.user
                b.taskChannelFiles = x.taskChannelFiles
                console.log("paste FILE",b)

                if (b.parameterValuesToTask) {
                  b.primaryTags = b.parameterValuesToTask.filter(x => !!x.is_primary)
                } else {
                  b.primaryTags = []
                }
                
                this.files.push(b)
              }),
              map(() => res)
            )
          } else {
            return of(res)
          }
        }),
        catchError(err => {
          this.layoutService.showSnackBar({name: ''}, marker(err), SnackBarItem)
          return of(err)
        }),
      ))).subscribe(resp => {
        this.layoutService.showSnackBar({name: ''}, marker("File copied successfully!"), SnackBarItem)
      })
    )


    console.log("this.copiedFiles", this.copiedFiles)
  }
  
  taskDrop(e, task:any) {
    task.over = false
    let file_ids = e.dataTransfer.getData("application/my-app").split(',');

    forkJoin(file_ids
      .map(x => this.copyFunc({
        id: x,
        company_id: this.target_company_id || this.company.id || this.company_id || x.company_id,
        task_id: task.id,
        task_operation_id: 0,
        location: '/'
      }))
    ).subscribe(resp => {
      this.deSelectAll()
    })
  }

  profileDrop(e, channel:any) {
    e.preventDefault();
    channel.over = false
    if (!channel.channel.channel_account_id) {
      return
    }
    let file_ids = e.dataTransfer.getData("application/my-app").split(',');

    let big_img_file_ids = file_ids.filter(val => this.files.find(x => x.id == val).filesize >= 2000000 && (this.files.find(x => x.id == val).content_type.indexOf("image") != -1 || mimeTypes.getType(this.files.find(x => x.id == val).filename).indexOf("image") != -1))
    let img_file_ids = file_ids.filter(val => this.files.find(x => x.id == val).filesize < 2000000 && (this.files.find(x => x.id == val).content_type.indexOf("image") != -1 || mimeTypes.getType(this.files.find(x => x.id == val).filename).indexOf("image") != -1))
    file_ids = file_ids.filter(val => this.files.find(x => x.id == val).content_type.indexOf("video") != -1 || mimeTypes.getType(this.files.find(x => x.id == val).filename).indexOf("video") != -1)
    console.log("file_ids", file_ids)
    
    if (file_ids.length) {
      forkJoin(file_ids
        .map(x => this.addChannelContent({
          company_id: this.company_id,
          task_id: this.task.id,
          channel_id: channel.channel.id,
          content_file_id: x,
        }))
      ).subscribe(resp => {
        console.log("postChannelContent", file_ids, resp)
        this.deSelectAll();
        if (big_img_file_ids.length) {
          this.layoutService.showSnackBar({name: big_img_file_ids.length + " cover(s)"}, marker("Larger than 2 mb. For the cover, you can choose a photo smaller than 2mb."), SnackBarItem)
        }
        if (img_file_ids.length) {
          console.log(img_file_ids, channel)
          this.attachSubscriptions(
            this.connectedAppsService.getChannelContent(this.company_id, this.task_id, channel.channel.id).pipe(
              map(res => res.filter(x => !x.is_ready_to_upload && !x.is_uploaded && !x.is_uploading))
            ).subscribe(resp => {
              if (resp.length == 1) {
  
                this.connectedAppsService.editChannelContent(resp[0].id, {thumbnail_file_id: img_file_ids[0]}).subscribe(result => {
                  console.log(result)
                },
                error => {
                  this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
                })
  
              } else if (resp.length > 1) {
                const dialogRef = this.dialog.open(SelectContentComponent, {
                  data: {
                    videos: resp,
                    imgRoute: this.imgRoute,
                    host: this.host,
                    thumb_id: img_file_ids[0],
                    company: this.company
                  }
                });
            
              } else {
                return
              }
            })
          )
        }
      }, error => {
        console.log("RERRWEQERQW", error)
        this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
      })
    } else {
      if (big_img_file_ids.length) {
        this.layoutService.showSnackBar({name: big_img_file_ids.length + " cover(s)"}, marker("Larger than 2 mb. For the cover, you can choose a photo smaller than 2mb."), SnackBarItem)
      }
      if (img_file_ids.length) {
        console.log(img_file_ids, channel)
        this.attachSubscriptions(
          this.connectedAppsService.getChannelContent(this.company_id, this.task_id, channel.channel.id).pipe(
            map(res => res.filter(x => !x.is_ready_to_upload && !x.is_uploaded && !x.is_uploading))
          ).subscribe(resp => {
            if (resp.length == 1) {

              this.connectedAppsService.editChannelContent(resp[0].id, {thumbnail_file_id: img_file_ids[0]}).subscribe(result => {
                console.log(result)
              },
              error => {
                this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
              })

            } else if (resp.length > 1) {
              const dialogRef = this.dialog.open(SelectContentComponent, {
                data: {
                  videos: resp,
                  imgRoute: this.imgRoute,
                  host: this.host,
                  thumb_id: img_file_ids[0],
                  company: this.company
                }
              });
          
            } else {
              return
            }
          }, error => {
            console.log("RERRWEQERQW2", error)
            this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
          })
        )
      }
    }
   
  }

  protected filterTimezoneGroups() {
    if (!this.timezones) {
      return;
    }
    // get the search keyword
    let search = this.timezoneFilterCtrl.value;
    const timezonesCopy = this.copyTimezoneGroups(this.timezones);
    if (!search) {
      this.filteredTimezonesGroups.next(timezonesCopy);
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredTimezonesGroups.next(
      timezonesCopy.filter(timezoneGroup => {
        const showBankGroup = timezoneGroup.value.toLowerCase().indexOf(search) > -1;
        if (!showBankGroup) {
          timezoneGroup.names = timezoneGroup.names.filter(name => name.toLowerCase().indexOf(search) > -1);
        }
        return timezoneGroup.names.length > 0;
      })
    );
  }

  protected copyTimezoneGroups(timezoneGroups: any) {
    const timezonesCopy = [];
    timezoneGroups.forEach(bankGroup => {
      timezonesCopy.push({
        value: bankGroup.value,
        names: bankGroup.names.slice()
      });
    });
    return timezonesCopy;
  }

  getTimeZone(val) {
    let o = Math.abs(val);
    return (val >= 0 ? "+" : "-") + ("00" + Math.floor(o / 3600)).slice(-2) + ":00";
  }
  
  getOffsetData(name) {
    if (!this.timezones) {
      return "0"
    }
    return this.timezones.find(x => x.names.filter(y => y == name).length > 0).value
  }

  getTimeZones() {
    this.attachSubscriptions(
      this.companyService.getTimeZones().subscribe(resp => {
        let val = [];
        Object.keys(resp).forEach(key => {
          val.push({
            value: key,
            names: resp[key]
          })
        })
        this.timezones = val;
        this.filteredTimezonesGroups.next(this.copyTimezoneGroups(this.timezones));
        console.log("getTimeZones",this.timezones);
      })
    )
  }

  saveStatus(item) {
    this.attachSubscriptions(
      this.connectedAppsService.editYouTubeContentData(item.id, {privacy_status: this.statusForm.value.privacy_status}).subscribe(resp => {
        if (resp) {
          item.youTubeData.privacy_status = this.statusForm.value.privacy_status
          item.status_opened = false
        }
        console.log("saveStatus", resp)
      },
      error => {
        this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
      })
    )
  }

  saveScheduleStatus(item) {
    let arr:Number[] = [];
    [0,15,30,45].forEach(x => {
      arr.push(Math.abs(x - this.statusForm.value.publish_at.minutes()))
    })
    
    this.statusForm.patchValue({
      publish_at: this.statusForm.value.publish_at.minute([0,15,30,45][arr.indexOf(Math.min.apply(Math, arr))])
    }) 

    // console.log("minutes", cloneDate);
    // console.log("minutes", cloneDate.minutes());

    let date = JSON.parse(JSON.stringify(moment(this.statusForm.value.publish_at).format("X")))
    let val = moment((+date + (-(+this.timeZone*60) - +this.getOffsetData(this.statusForm.value.timezone)))*1000).toISOString()
    // console.log(+date + (-(+this.timeZone*60) - +this.getOffsetData(this.statusForm.value.timezone)))
    // console.log(moment((+date + (-(+this.timeZone*60) - +this.getOffsetData(this.statusForm.value.timezone)))*1000).toISOString())

    this.attachSubscriptions(
      this.connectedAppsService.editYouTubeContentData(item.id, {privacy_status: "private", publish_at: val}).subscribe(resp => {
        console.log(resp)
        if (resp) {
          item.youTubeData.publish_at = val;
          item.status_opened = false
        }
      },
      error => {
        this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
      })
    )
  }

  openStatus(item) {
    this.channelFiles.map(x => {
      x.status_opened = false
    })

    if (item.is_uploaded == 1) {

      this.statusForm.get('status_block').valueChanges.subscribe(resp => {
        if (resp == 'first') {
          if (this.statusForm.contains('publish_at')) {
            this.statusForm.removeControl('publish_at')
            this.statusForm.removeControl('timezone')
          }
        } else {
          this.statusForm.patchValue({
            privacy_status: 'schedule'
          })
          this.statusForm.addControl('publish_at', this.fb.control(item.youTubeData.privacy_status.publish_at ? moment(item.youTubeData.privacy_status.publish_at) : moment().minute(0).second(0).hour(0)))
          this.statusForm.addControl('timezone', this.fb.control(this.company.timezone))
        }
        this.statusForm.updateValueAndValidity();

        console.log(this.statusForm.value)
      })


      this.statusForm.patchValue({
        status_block: item.youTubeData.privacy_status == 'private' && item.youTubeData.publish_at != '' ? 'second' : 'first',
        privacy_status: item.youTubeData.privacy_status
      })

      console.log(this.statusForm.value)
    }

    item.status_opened = true
  }

  closeStatus(item) {
    item.status_opened = false
  }

  tryToUpload(item) {
    this.attachSubscriptions(
      this.connectedAppsService.editChannelContent(item.id, {name: item.name ? item.name : item.contentFile.filename, is_ready_to_upload: 1, is_uploading: 0}).subscribe(
        resp => {
          console.log(resp)
          item.is_ready_to_upload = resp.is_ready_to_upload;
          item.is_uploaded = resp.is_uploaded;
          item.uploading_error = resp.uploading_error;
          item.uploading_progress = resp.uploading_progress;
          item.is_uploading = resp.is_uploading;
          if (this.uploadProgress) {
            this.uploadProgress.unsubscribe()
          }
          this.checkUploadProgress()
        }
      )
    )
  }

  uploadProfileVideo(item) {
    this.attachSubscriptions(
      this.connectedAppsService.editChannelContent(item.id, {name: item.name ? item.name : item.contentFile.filename, is_ready_to_upload: 1}).subscribe(
        resp => {
          console.log(resp)
          item.is_ready_to_upload = resp.is_ready_to_upload;
          item.is_uploaded = resp.is_uploaded;
          item.uploading_error = resp.uploading_error;
          item.uploading_progress = resp.uploading_progress;
          item.is_uploading = resp.is_uploading;
          if (resp.is_uploading == 0) {
            this.layoutService.showSnackBar({name: ''}, marker('Not accessible'), SnackBarItem)
          } else {            
            if (this.uploadProgress) {
              this.uploadProgress.unsubscribe()
            }
            this.checkUploadProgress()
          }
        },
        error => {
          this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
        }
      )
    )
  }

  editName(item) {
    this.channelFiles.map(x => {
      x.name_edited = false
    })
    item.name_edited = true

   
    setTimeout(() => {
      document.getElementById(`name_${item.id}`).focus()
      if (document.getElementById(`name_${item.id}`)) {
      }
    }, 0)
  }

  changeName(item) {
    let saveName = item.name;
    item.name = (document.getElementById(`name_${item.id}`) as HTMLInputElement).value
    if (item.is_uploaded == 1) {
      item.youTubeData.title = item.name;
    }
    this.attachSubscriptions(
      this.connectedAppsService.editChannelContent(item.id, {name: item.name}).pipe(
        switchMap(res => {
          if (item.is_uploaded == 1) {
            return this.connectedAppsService.editYouTubeContentData(item.id, {title: item.name}).pipe(tap(n => console.log("editYouTubeContentData", n)))
          } else {
            return of(res)
          }
        })
      ).subscribe(
        resp => {
          console.log(resp)
        },
        error => {
          item.name = saveName
          if (item.is_uploaded == 1) {
            item.youTubeData.title = saveName;
          };
          this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
        }
      )
    )

  }

  focusOutName(item) {
    item.name_edited = false
  }

  deleteProfileVideo(content, index) {
    this.attachSubscriptions(
      this.connectedAppsService.deleteChannelContent(content.id).subscribe(resp => {
        this.channelFiles.splice(index, 1)
      },
      error => {
        this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
      })
    )
  }
  
  focusOutDesc(item) {
    item.desc_edited = false
  }
  editDesc(item) {
    this.channelFiles.map(x => {
      x.desc_edited = false
    })
    item.desc_edited = true

   
    setTimeout(() => {
      if (document.getElementById(`desc_${item.id}`)) {
        document.getElementById(`desc_${item.id}`).focus()
      }
    }, 0)
  }

  changeDesc(item) {
    console.log(item);
    let saveDescription = item.youTubeData.description
    item.youTubeData.description = (document.getElementById(`desc_${item.id}`) as HTMLInputElement).value

    this.attachSubscriptions(
      this.connectedAppsService.editYouTubeContentData(item.id, {description: item.youTubeData.description}).subscribe(
        resp => {
          console.log(resp)
          if (!resp) {
            item.youTubeData.description = saveDescription
          }
        },
        error => {
          item.youTubeData.description = saveDescription
          this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
        }
      )
    )

  }



  addChannelContent(data) {
    return this.connectedAppsService.addChannelContent(data)
  }

  copyFunc(data) {
    return this.fileService.copyFile(data, this.company.id)
  }

  approveFile(file) {
    this.close();
    this.attachSubscriptions(
      this.fileService.editFile(file.id, {
        location: !!file.is_ready_if_approved ? '/ready_files' : '/approved'
      }, this.company.id).subscribe(res => {
        this.detectFiles();
        this.files.splice(this.files.indexOf(this.files.find(t => t.id == file.id)), 1)
        console.log(res)
      })
    )
  }

  clFile(file) {
    console.log(file)
  }

  selectCheckList(file) {
    console.log(file)
    this.close();
    let work = this.task.operations.find(z => z.id == this.fileRoute.value.task_operation_id) ? this.task.operations.find(z => z.id == this.fileRoute.value.task_operation_id) : this.work
    this.attachSubscriptions(
      this.taskService.getOperationReminder(this.company_id, {task_operation_id: work.id}).subscribe(resp => {
        console.log("getOperationReminder", resp);
        const dialogRef = this.dialog.open(SelectChecklistComponent, {
          data: {
            company: this.company,
            task: this.task,
            work: work,
            user: this.user,
            file: file,
            checklist: resp.body,
            operationsValues: this.operationsValues
          }
        });

        this.attachSubscriptions(
          dialogRef.afterClosed().subscribe(result => {
            if (!!result.must_delete && this.files.find(x => x.id == file.id)) {
              this.files.splice(this.files.indexOf(file), 1)
            }
            this.refreshService.refreshTask$.next({company_id: this.company.id, task_id: this.task.id, fromFileManager: true})
          })
        )
      })
    )
  }

  detectFiles() {
    console.log("START1")
    let work = this.task.operations.find(z => z.id == this.fileRoute.value.task_operation_id) ? this.task.operations.find(z => z.id == this.fileRoute.value.task_operation_id) : this.work
    console.log("detectFiles", work)

    // console.log("!work", !work)
    // console.log("work.status_id != 3", work.status_id != 3)
    // console.log("!this.company.permissions.includes('owner')", !this.company.permissions.includes("owner"))
    // console.log("!this.company.permissions.includes('owner') || !this.checkIsManager(this.task, this.company, this.user)", !(this.company.permissions.includes("owner") || this.checkIsManager(this.task, this.company, this.user)))
    // console.log("!this.checkIsManager(this.task, this.company, this.user)", !this.checkIsManager(this.task, this.company, this.user))
    
    if (!work || work.status_id != 3 || !(this.company.permissions.includes("owner") || this.checkIsManager(this.task, this.company, this.user))) {
      return
    }

    console.log("START2")
    
    this.fileService.detectFiles("/to_approve", "0", this.company.id, this.task_operation_id).pipe(
      map(x => !!x.length),
      filter(x => !x),
      switchMap(() => forkJoin([
        this.fileService.detectFiles("/approved", "0", this.company.id, this.task_operation_id).pipe(map(b => !!b.length)),
        this.fileService.detectFiles("/ready_files", "0", this.company.id, this.task_operation_id).pipe(map(b => !!b.length)),
      ])),
      tap(vals => console.log("vals", vals)),
      map(vals => vals.filter(x => x == true).length > 0 ? true : false)
    ).subscribe(resp => {
      console.log(resp);
      if (resp) {
        const dialogRef = this.dialog.open(ApproveJobComponent, {
          data: {
            task: this.task,
            work: work,
            company: this.company
          }
        });
        
        this.attachSubscriptions(
          dialogRef.afterClosed().subscribe(result => {
            if (result.event && result.event == "yes") {
              this.refreshService.refreshTask$.next({company_id: this.company.id, task_id: this.task.id, fromFileManager: true})
            }
          })
        )
      }
    })
  }

  jobDrop(e, job:any) {
    job.over = false
    let file_ids = e.dataTransfer.getData("application/my-app").split(',');

    forkJoin(file_ids.map(x => this.copyFunc({
      id: x,
      company_id: this.target_company_id || this.company.id || this.company_id || x.company_id,
      task_id: this.task_id,
      task_operation_id: job.id,
      location: '/'
    }))).subscribe(resp => {
      console.log(resp)
      this.deSelectAll()
    })
  }

  handleDragEnd(e, file) {
    this.folders.map(val => val.over = false)
    this.files.map(val => val.dragged = false)
    this.isDragged = false;
  }

  // FILE LOGIC
  
  download(file) {
    this.close();
    if (!!file.is_document) {
      const blob = new Blob([file.document.text], { type: 'text/html' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${file.filename}.html`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } else {
      window.open(this.host + file.original + '?company_id=' + this.company.id + `&filename=${file.filename}`, '_blank');
    }
  }

  downloadFolder(folder) {
    this.layoutService.showSnackBar({}, "Folder is getting ready for download. Don't close this browser tab. Download will start automatically when folder archive will be completed.", SnackBarItem)
    let postData:any = {
      company_id: folder.company_id,
      is_deferred: 1,
      file_id: [folder.id],
      delete_at: moment().add(1, 'd').format("X")
    }

    this.createdArchForDownSub = this.fileService.createArchive(postData, this.company_id).pipe(
      take(1)
    ).subscribe(resp => {
      console.log(resp)
      if (resp && resp.is_deferred == 1) {
        let download_archives = JSON.parse(this.sm.localStorageGetItem('download_archives')) || []
        download_archives.push({
          c_id: folder.company_id,
          a_id: resp.id,
          u_id: this.user.id
        })
        this.sm.localStorageSetItem('download_archives', JSON.stringify(download_archives))
      }
      // if (resp.download_url.length) {
      //   async function loop() {
      //     for (let i = 0; i < resp.download_url.length; i++) {
      //       await new Promise((res) => setTimeout(res, i == 0 ? 0 : 4000)); // пауза перед очередным
      //       console.log(resp.download_url[i]); // вывод блока
      //       if (resp.download_url[i]) {
      //         let a = document.createElement('a');
      //         a.setAttribute('href', this.host + resp.download_url[i]);
      //         a.setAttribute('download','download');
      //         // a.target = "_blank"
      //         a.click();
      //       }
      //     }
      //   }
      //   loop();
      // }
    }, error => {
      this.layoutService.showSnackBar({}, "Something went wrong.", SnackBarItem)
    })

  }

  delete(file) {
    this.close();
    if (!this.selectedFiles.isSelected(file.id)) {
      this.deSelectAll()
    }
    this.selectedFiles.select(file.id)


    if (this.crumbs.length == 1 || this.crumbs[1].location != "/trash") {
      console.log("this is trash")
      forkJoin(this.selectedFiles.selected.map(x => this.deleteFunc(this.files.find(z => z.id == x)))).subscribe(resp => {
        resp.forEach(z => {
          this.files.splice(this.files.indexOf(this.files.find(x => x.id == z)), 1)
        })
        this.detectFiles();
        this.deSelectAll()
      })
    } else {
      if (!this.company.permissions.includes('owner') && !this.company.permissions.includes('admin')) {
        this.layoutService.showSnackBar({name: ''}, marker("Your rights are limited to delete files from task file manager."), SnackBarItem)
        return
      }
      let alertData = {}
      if (this.selectedFiles.selected.length > 1) {
        alertData = {
          targetVal: {},
          msg: marker("After you delete the files it can't be restored anymore."),
          msg_1: marker("Proceed with file delete?")
        }
      } else {
        alertData = {
          targetVal: {},
          msg: marker("After you delete the file it can't be restored anymore."),
          msg_1: marker("Proceed with file delete?")
        }
      }

      let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
        data: alertData
      });
  
      deleteAlert.afterDismissed().subscribe( data => {
        if (data && data.message == 'no') {
          console.log("no");
          return
        } else if (data && data.message == 'yes') {
          forkJoin(this.selectedFiles.selected.map(x => this.fileService.deleteFile(x))).subscribe({
            next: (next) => {
              console.log("next", next)
            },
            complete: () => {
              console.log("complete")
              this.selectedFiles.selected.forEach((y,i) => {
                this.files.splice(this.files.indexOf(this.files.find(t => t.id == y)), 1)
                if (i == this.selectedFiles.selected.length - 1) {
                  this.deSelectAll()
                }
              })
              this.detectFiles();
            },
            error: (error) => {
              console.log(error)
              this.deSelectAll()
            }
          })
        }
      });
    }
  }


  deleteFunc(file) {
    return this.fileService.editFile(file.id, {"location": '/trash'}, this.company.id).pipe(
      switchMap(val => {
        if (file.location.indexOf("to_approve") != -1) {
          return this.fileService.detectFiles("/to_approve", 0, this.company_id, this.task_operation_id).pipe(
            switchMap(val => {
              if (val.length == 0) {
                return this.taskService.editWork(this.task_operation_id, {is_to_approve_files: 0}, this.company_id).pipe(map(() => of(file.id)))
              } else {
                return of(file.id)
              }
            })
          )
        } else if (file.location.indexOf("ready_files") != -1) {
          return this.fileService.detectFiles("/ready_files", 0, this.company_id, this.task_operation_id).pipe(
            switchMap(val => {
              if (val.length == 0) {
                return this.taskService.editWork(this.task_operation_id, {is_approved_files: 0}, this.company_id).pipe(map(() => of(file.id)))
              } else {
                return of(file.id)
              }
            })
          )
        } else {
          return of(file.id)
        }
      })
    )
  }

  settings(file) {
    if (file) {
      const dialogRef = this.dialog.open(MobFileSettingsComponent, {
        backdropClass: ['mob_interface_backdrop'],
        panelClass: ['mob_interface_panel', 'animate__animated', 'animate__slideInUp'],
        disableClose: true,
        data: {
          company_id: this.company_id,
          company: this.company,
          file: file,
          user: this.user,
          location: file.location,
          task: this.task,
          operationsValues: this.operationsValues
        }
      });
    }
  }

  private isMobile(): boolean {
    return /Android|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i.test(navigator.userAgent);
  }

  edit(file) {
    console.log(file)
    this.close();
    const dialogRef = this.dialog.open(RenameComponent, {
      data: {
        file: file,
        company: this.company
      }
    });

    // this.attachSubscriptions(
    //   dialogRef.afterClosed().subscribe(result => {
    //     if (!!result && result.event == 'Edit') {
    //       task.custom_id = result.data.custom_id;
    //     }
    //   })
    // )
  }

  setTaskCover(file) {
    let _task = this.task || file.task
    this.attachSubscriptions(
      this.taskService.editTask(_task.id, {avatar_file_id: file.id}, this.company.id).subscribe(resp => {
        _task.avatar_file_id = resp.avatar_file_id;
        _task.avatarFile = file;
        this.close()
      })
    )
  }

  getProfile(id) {
    if (!this.profiles || !this.profiles.find(el => el.id == id)) {
      return false;
    }
    return this.profiles.find(el => el.id == id)
  }  

  getGroup(id) {
    if (!this.groups || !this.groups.find(el => el.id == id)) {
      return false;
    }
    return this.groups.find(el => el.id == id)
  }  

  getChecklist(id) {
    if (!this.checklists || !this.checklists.find(el => el.id == id)) {
      return false;
    }
    return this.checklists.find(el => el.id == id)
  }  

  getGroupsCompany() {
    this.attachSubscriptions(
      this.companyService.getInfiniteGroupsCompany(this.activatedRoute.snapshot.queryParamMap.get('company_id') || this.initCompanyId, '1', '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.companyService.getInfiniteGroupsCompany(this.activatedRoute.snapshot.queryParamMap.get('company_id') || this.initCompanyId, x).pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        this.groups = [].concat(...res);
        this.groups$.next(this.groups.slice());
      })
    )
  }

  closeParameterFilter() {
    this.parameterFilterSub && this.parameterFilterSub.unsubscribe();
    if (this.overlayParameterRefFilter) {
      this.overlayParameterRefFilter.dispose();
      this.overlayParameterRefFilter = null;
    }
  }

  openParameterFilter(el, val) {
    this.closeParameterFilter();
    let x = el.offsetLeft;
    let y =  el.offsetTop + el.offsetHeight;
    const positionStrategy = this.overlay.position()
    .flexibleConnectedTo({ x, y })
    .withPositions([
      {
        originX: 'start',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'top',
      }
    ]);

    this.overlayParameterRefFilter = this.overlay.create({
      backdropClass: 'transparent_bg',
      hasBackdrop: true,
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });

    this.overlayParameterRefFilter.attach(new TemplatePortal(this.filesFilterParameterMenu, this.viewContainerRef, {
      $implicit: val
    }));
    
    this.parameterFilterSub = fromEvent<MouseEvent>(document, 'click')
      .pipe(
        filter(event => {
          const clickTarget = event.target as HTMLElement;
       
          let check1 = !!this.overlayParameterRefFilter && !this.overlayParameterRefFilter.overlayElement.contains(clickTarget)
          // let check2 = (document.getElementsByClassName('filter_autocomlete').length > 0 && !document.getElementsByClassName('filter_autocomlete')[0].contains(clickTarget))
          // let check3 = (document.getElementsByClassName('filter_autocomlete').length == 0);
          // let check4 = (clickTarget.classList.contains('mat-option-text') || clickTarget.classList.contains('mat-option'))
       
//(check1 && check2) || (check1 && check3 && !check4)
          return check1
        }),
        take(1)
      ).subscribe(() => this.closeParameterFilter())

  }

  previewVideo(file) {
    console.log(file)
    console.log(this.fileRoute)
    let _task = this.task || file.task;
    let _work;
    if (_task && _task.operations) {
      _work = _task.operations.find(z => z.id == this.fileRoute.value.task_operation_id) ? _task.operations.find(z => z.id == this.fileRoute.value.task_operation_id) : this.work;
    }
    this.close();
    let playlist = this.files.filter(x => (x.is_uploaded || !!x.is_document) && (!!x.is_document || (x.content_type.indexOf("image") != -1 || x.content_type.indexOf("video") != -1 || x.content_type.indexOf("audio") != -1 || x.content_type == 'application/pdf') || !!x.thumbnail))
    let currIndex = playlist.indexOf(file)
    const dialogRef = this.dialog.open(MobFmViewComponent, {
      backdropClass: ['mob_video_viewer_backdrop'],
      panelClass: 'mob_video_viewer',
      autoFocus: false,
      data: {
        file: file,
        files: this.files,
        playlist: playlist,
        currIndex: currIndex,
        task: _task,
        work: _work || file.work || file.taskOperation,
        operationsValues: this.operationsValues,
        company: this.company,
        activeLang: this.activeLang,
        user: this.user,
        initCompanyId: this.company_id
      }
    });


    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(res => {
        // if (res.event == 'open_next') {
        //   this.previewVideo(res.file)
        // } else {
        // }
        if (this.sm.localStorageGetItem("copiedFiles")) {
          this.copiedFiles = JSON.parse(this.sm.localStorageGetItem("copiedFiles"))
        } else {
          this.copiedFiles = undefined;
        }
        
        this.page = 1;
        this.getFiles(this.page, this.form.value.location);
      })
    )
  }

  createDocument() {
    this.close();

    if (this.is_global && !this.globalFilterFmFiles.task_id) {
      this.layoutService.showSnackBar({name: ''}, marker("You can only create a folder within a task or job. To add a folder, go to 1 of the existing folders (it already belongs to a task or work)."), SnackBarItem)
      return;
    }

    const dialogRef = this.dialog.open(AddEditNoteComponent, {
      backdropClass: 'backdrop_under_header',
      panelClass: !this.is_mobile ? ['open_task_dialog', 'show_header'] : 'open_task_dialog',
      disableClose: true,
      data: {
        company: this.company,
        company_id: this.company_id,
        target_company_id: this.target_company_id,
        task_id: !this.is_global ? this.task_id : this.globalFilterFmFiles.task_id,
        work_id: !this.is_global ? this.task_operation_id : this.globalFilterFmFiles.work_id,
        files: this.files,
        location: this.form.value.location,
        user: this.user,
        is_file: true
      }
    });
  }
  
  previewImage(e, file) {
    this.close()
    // console.log();
    // // console.log("previewImage", this._ngxViewer.viewerShow)
    // this._ngxViewer.viewerShow
    // this.fileClick(file)
  }

  @HostListener("window:visibilitychange", ["$event"])
  onVisibilityChange($event) {
    const isVisible = $event.target.visibilityState === 'visible';
    // console.log("&&&&", isVisible)
    if (isVisible) {
      if (this.sm.localStorageGetItem("copiedFiles")) {
        this.copiedFiles = JSON.parse(this.sm.localStorageGetItem("copiedFiles"))
      } else {
        this.copiedFiles = undefined;
      }
      if (this.sm.localStorageGetItem("copiedAllFiles")) {
        this.copiedAllFiles = JSON.parse(this.sm.localStorageGetItem("copiedAllFiles"))
      } else {
        this.copiedAllFiles = undefined;
      }
        // tab is visible
    } else {
        // tab is not-visible
    }
  }


  ngOnDestroy(): void {
    this.is_opened = false;
    if (this.uploadProgress) {
      this.uploadProgress.unsubscribe()
    }
    if (this.subOne) {
      this.subOne.unsubscribe()
    }
    if (this.paramDataSub) {
      this.paramDataSub.unsubscribe();
    }
    if (this.subTwo) {
      this.subOne.unsubscribe()
    }
    if (this.subThird) {
      this.subThird.unsubscribe()
    }
    if (this.subFour) {
      this.subFour.unsubscribe()
    }
    if (this.subFiles) {
      this.subFiles.unsubscribe()
    }
    if (this.subFilesCount) {
      this.subFilesCount.unsubscribe()
    }
    if (this.subFoldersCount) {
      this.subFoldersCount.unsubscribe()
    }
    if (this.refreshSub) {
      this.refreshSub.unsubscribe()
    }
    this.clearSubscriptions()
  }
}
