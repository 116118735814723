import { Injectable } from '@angular/core';
import { HttpRestService } from '../common/http-rest.service';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ClientsService {

  constructor(
    private http: HttpRestService
    ) { }


    deleteClient(id) {
      return this.http.executeDelete<any>(`/api/client/${id}/`)
    }
  
    getClients(id) {
      return this.http.executeGet<any>(`/api/client/`, true, {
        params: new HttpParams().set("company_id", id).set("expand","company,user")
      })
    }

    getInvites() {
      return this.http.executeGet<any>(`/api/client/`, true, {
        params: new HttpParams().set("expand","company,user")
      })
    }

    getInvite(code) {
      return this.http.executeGet<any>('/api/client/', true, {
        params: new HttpParams().set("invite", code).set("expand","company,user")
      })
    }

    acceptInvite(id, code) {
      return this.http.executePost<any>(`/api/client/${id}/`, {
        accept_invite: 1
      }, true, {
        params: new HttpParams().set("invite", code)
      })
    }

    rejectInvite(id) {
      return this.http.executePost<any>(`/api/client/${id}/`, {
        reject_invite: 1
      })
    }

    editClient(id,data) {
      return this.http.executePost<any>(`/api/client/${id}/`, data)
    }

    getClient(id) {
      return this.http.executeGet<any>(`/api/client/${id}/`)
    }

    addClient(data) {
      return this.http.executePost<any>(`/api/client/`, data)
    }
}

