import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit, HostListener, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
const enum Status {
  OFF = 0,
  RESIZE = 1,
  MOVE = 2,
  ROTATE = 3
}

@Component({
  selector: 'app-resizable-draggable',
  templateUrl: './resizable-draggable.component.html',
  styleUrls: ['./resizable-draggable.component.scss']
})
export class ResizableDraggableComponent implements OnInit, OnChanges, AfterViewInit {
  @Input('combine') public combine: any;
  @Input('part') public part: any;
  @Input('host') public host: any;
  @Input('forAll') public forAll: boolean = true;
  @Input('width') public width: any;
  @Input('height') public height: any;
  @Input('project') public project: any;
  @Input('verticalLine') public verticalLine: any;
  @Input('isProjectPlaying') public isProjectPlaying: any;
  @Input('hasCropOn') public hasCropOn: any;
  @Input('currentTimeProject') public currentTimeProject: any;
  @Input('currentCutTimeProject') public currentCutTimeProject: any;
  @Input('company_id') public company_id: any;
  @Input('ratio') public ratio: number;
  @Input('holst') public holst: any;
  @Output() Loadedmetadata = new EventEmitter<any>();
  @ViewChild("black_sq") public black_sq: ElementRef;
  @ViewChild("video") public videoEl: ElementRef;
  // @ViewChild("blurBg") public blurBg: ElementRef;
  // @ViewChild("pointTopLeft") public pointTopLeft: ElementRef;
  // @ViewChild("pointTopRight") public pointTopRight: ElementRef;
  // @ViewChild("pointBottomLeft") public pointBottomLeft: ElementRef;
  // @ViewChild("pointBottomRight") public pointBottomRight: ElementRef;
  @ViewChild("box") public box: ElementRef;
  @ViewChild("rotate") public rotateEl: ElementRef;
  @Input('texts') public texts: any;
  @Input('imgs') public imgs: any;
  
  resizer: Function;
  resizerDraw: Function;
  resizerImg: Function;

  @Input('changedDrawText') public changedDrawText: any;
  @Input('changedImg') public changedImg: any;
  // @Input('outChangedImg') public outChangedImg: any;

  private boxPosition: { left: number, top: number };
  private containerPos: { left: number, top: number, right: number, bottom: number };
  public mouse: {x: number, y: number};
  public status: Status = Status.OFF;
  private mouseClick: {x: number, y: number, left: number, top: number};
  public center: {x: number, y: number};
  public R2D = 180 / Math.PI;
  public startAngle:number = 0;
  public max_side:number = 0;
  public blackHolst: any;
  public resizeTimeout: any;

  ngOnInit() {
    this.center = {
      x: +(this.part.rd.width/2).toFixed(2),
      y: +(this.part.rd.height/2).toFixed(2)
    }

    if (this.part.rd.ratio >= 1) {
      this.max_side = this.part.rd.width*2;
    } else {
      this.max_side = this.part.rd.height*2;
    }
  }

  area() {
    return this.width * this.height;
  }

  onWindowPress(event: MouseEvent) {
    if (!this.part.subSet || !this.part.subSet.pos || !this.part.subSet.pos.is_edited) {
      return
    }
    this.changedDrawText = undefined;
    this.part.subSet.pos.draggingWindow = true;
    this.part.subSet.pos.px = event.clientX;
    this.part.subSet.pos.py = event.clientY;
    this.saveSubPosition();
  }

  onWindowDrag(event: MouseEvent) {
    if (!this.part.subSet || !this.part.subSet.pos || !this.part.subSet.pos.is_edited) {
      return
    }
     if (!this.part.subSet.pos.draggingWindow) {
        return;
    }
    this.changedDrawText = undefined;
    let offsetX = event.clientX - this.part.subSet.pos.px;
    let offsetY = event.clientY - this.part.subSet.pos.py;

    this.part.subSet.pos.x += offsetX;
    this.part.subSet.pos.y += offsetY;
    this.part.subSet.pos.px = event.clientX;
    this.part.subSet.pos.py = event.clientY;
    this.saveSubPosition();
  }

  topLeftResize(offsetX: number, offsetY: number) {
    this.part.subSet.pos.x += offsetX;
    this.part.subSet.pos.y += offsetY;
    this.part.subSet.pos.width -= offsetX;
    this.part.subSet.pos.height -= offsetY;
  }

  topRightResize(offsetX: number, offsetY: number) {
    this.part.subSet.pos.y += offsetY;
    this.part.subSet.pos.width += offsetX;
    this.part.subSet.pos.height -= offsetY;
  }

  bottomLeftResize(offsetX: number, offsetY: number) {
    this.part.subSet.pos.x += offsetX;
    this.part.subSet.pos.width -= offsetX;
    this.part.subSet.pos.height += offsetY;
  }

  bottomRightResize(offsetX: number, offsetY: number) {
    this.part.subSet.pos.width += offsetX;
    this.part.subSet.pos.height += offsetY;
  }

  onCornerClick(event: MouseEvent, resizer?: Function) {
    this.changedDrawText = undefined;
    this.part.subSet.pos.draggingCorner = true;
    this.part.subSet.pos.px = event.clientX;
    this.part.subSet.pos.py = event.clientY;
    this.resizer = resizer;
    event.preventDefault();
    event.stopPropagation();
    this.saveSubPosition();
  }

  @HostListener('document:mousemove', ['$event'])
  onCornerMove(event: MouseEvent) {
    if (this.changedDrawText && this.changedDrawText.settings.pos.draggingCorner) {
      let offsetX = event.clientX - this.changedDrawText.settings.pos.px;
      let offsetY = event.clientY - this.changedDrawText.settings.pos.py;
  
      let lastX = this.changedDrawText.settings.pos.x;
      let lastY = this.changedDrawText.settings.pos.y;
      let pWidth = this.changedDrawText.settings.pos.width;
      let pHeight = this.changedDrawText.settings.pos.height;
  
      this.resizerDraw(offsetX, offsetY);
      if (this.area() < 20000) {
        this.changedDrawText.settings.pos.x = lastX;
        this.changedDrawText.settings.pos.width = pWidth;
        
        this.changedDrawText.settings.pos.y = lastY;
        this.changedDrawText.settings.pos.height = pHeight;
      }
      // if (this.changedDrawText.settings.pos.x + this.changedDrawText.settings.pos.width <= this.part.rd.width) {
      // }
      // if (this.changedDrawText.settings.pos.y + this.changedDrawText.settings.pos.height <= this.part.rd.height) {
      // }
      this.changedDrawText.settings.pos.px = event.clientX;
      this.changedDrawText.settings.pos.py = event.clientY;
      this.saveTextDrawPosition();
    } else if (!!this.changedImg && this.changedImg.rd.draggingCorner) {
      let offsetX = event.clientX - this.changedImg.rd.px;
      let offsetY = event.clientY - this.changedImg.rd.py;
  
      let lastX = this.changedImg.rd.left;
      let lastY = this.changedImg.rd.top;
      let pWidth = this.changedImg.rd.width;
      let pHeight = this.changedImg.rd.height;

      this.resizerImg(offsetX, offsetY);
      if (this.area() < 20000) {
        this.changedImg.rd.left = lastX;
        this.changedImg.rd.width = pWidth;
        
        this.changedImg.rd.top = lastY;
        this.changedImg.rd.height = pHeight;
      }
      // if (this.changedImg.rd.x + this.changedImg.rd.width <= this.part.rd.width) {
      // }
      // if (this.changedImg.rd.y + this.changedImg.rd.height <= this.part.rd.height) {
      // }
      this.changedImg.rd.px = event.clientX;
      this.changedImg.rd.py = event.clientY;
      this.saveImgPosition();
    } else if (!!this.changedImg && this.changedImg.rd.rotating) {
      this.resizerImg(null, null, event);
    } else {
      if (!this.part.subs_on || !this.part.subSet.pos || !this.part.subSet.pos.draggingCorner) {
        return;
      }
      let offsetX = event.clientX - this.part.subSet.pos.px;
      let offsetY = event.clientY - this.part.subSet.pos.py;
  
      let lastX = this.part.subSet.pos.x;
      let lastY = this.part.subSet.pos.y;
      let pWidth = this.part.subSet.pos.width;
      let pHeight = this.part.subSet.pos.height;
  
      this.resizer(offsetX, offsetY);
      if (this.area() < 20000) {
        this.part.subSet.pos.x = lastX;
        this.part.subSet.pos.width = pWidth;
        
        this.part.subSet.pos.y = lastY;
        this.part.subSet.pos.height = pHeight;
      }
      // if (this.part.subSet.pos.x + this.part.subSet.pos.width <= this.part.rd.width) {
      // }
      // if (this.part.subSet.pos.y + this.part.subSet.pos.height <= this.part.rd.height) {
      // }
      this.part.subSet.pos.px = event.clientX;
      this.part.subSet.pos.py = event.clientY;
      this.saveSubPosition();
    }
  }

  // textDraw start
  onWindowPressDraw(event: MouseEvent, text) {
    console.log("w1")
    // if (!this.changedDrawText && !!text) {
    //   this.changedDrawText = text;
    // }
    if (!this.changedDrawText) {
      return
    } else if (this.changedDrawText.value != text.value) {
      return
    }
    this.changedDrawText.settings.pos.draggingWindow = true;
    this.changedDrawText.settings.pos.px = event.clientX;
    this.changedDrawText.settings.pos.py = event.clientY;
    this.saveTextDrawPosition();
  }

  onWindowDragDraw(event: MouseEvent, text) {
    if (!this.changedDrawText) {
      return
    }
    if (!this.changedDrawText.settings.pos.draggingWindow) {
      return;
    }
    let offsetX = event.clientX - this.changedDrawText.settings.pos.px;
    let offsetY = event.clientY - this.changedDrawText.settings.pos.py;

    this.changedDrawText.settings.pos.x += offsetX;
    this.changedDrawText.settings.pos.y += offsetY;
    this.changedDrawText.settings.pos.px = event.clientX;
    this.changedDrawText.settings.pos.py = event.clientY;
    this.saveTextDrawPosition();
  }

  topLeftResizeDraw(offsetX: number, offsetY: number) {
    if (!this.changedDrawText) {
      return
    }
    this.changedDrawText.settings.pos.x += offsetX;
    this.changedDrawText.settings.pos.y += offsetY;
    this.changedDrawText.settings.pos.width -= offsetX;
    this.changedDrawText.settings.pos.height -= offsetY;
  }

  topRightResizeDraw(offsetX: number, offsetY: number) {
    if (!this.changedDrawText) {
      return
    }
    this.changedDrawText.settings.pos.y += offsetY;
    this.changedDrawText.settings.pos.width += offsetX;
    this.changedDrawText.settings.pos.height -= offsetY;
  }

  bottomLeftResizeDraw(offsetX: number, offsetY: number) {
    if (!this.changedDrawText) {
      return
    }
    this.changedDrawText.settings.pos.x += offsetX;
    this.changedDrawText.settings.pos.width -= offsetX;
    this.changedDrawText.settings.pos.height += offsetY;
  }

  bottomRightResizeDraw(offsetX: number, offsetY: number) {
    if (!this.changedDrawText) {
      return
    }
    this.changedDrawText.settings.pos.width += offsetX;
    this.changedDrawText.settings.pos.height += offsetY;
  }

  toggleTextDrawChange(e, text) {
    e.preventDefault();
    e.stopPropagation();
    console.log("w2")
    this.changedImg = undefined;


    if (!!text.settings.pos.is_edited) {
      this.changedDrawText = undefined;
      this.texts.forEach(house => {
        house.arr.forEach(houseText => {
          houseText.settings.pos.is_edited = false;
        })
      })
    } else {
      this.texts.forEach(house => {
        house.arr.forEach(houseText => {
          houseText.settings.pos.is_edited = false;
        })
      })
      this.changedDrawText = text;
      this.changedDrawText.settings.pos.is_edited = true;
    }

    console.log("w22", this.changedDrawText);
  }

  
  onWindowPressImg(event: MouseEvent, img) {
    event.preventDefault();
    console.log("w1")
    // if (!this.changedDrawText && !!text) {
    //   this.changedDrawText = text;
    // }
    if (!this.changedImg || !this.changedImg.crop_on) {
      return
    } else if (this.changedImg.pId != img.pId) {
      return
    }
    this.changedImg.rd.draggingWindow = true;
    this.changedImg.rd.px = event.clientX;
    this.changedImg.rd.py = event.clientY;
    this.saveImgPosition();
  }

  onWindowDragImg(event: MouseEvent, text) {
    event.preventDefault();
    if (!this.changedImg || !this.changedImg.crop_on) {
      return
    }

    if (!this.changedImg.rd.draggingWindow) {
      return;
    }
    let offsetX = event.clientX - this.changedImg.rd.px;
    let offsetY = event.clientY - this.changedImg.rd.py;

    this.changedImg.rd.left += offsetX;
    this.changedImg.rd.top += offsetY;
    this.changedImg.rd.px = event.clientX;
    this.changedImg.rd.py = event.clientY;
    this.saveImgPosition();
  }

  topLeftResizeImg(offsetX: number, offsetY: number) {
    if (!this.changedImg || !this.changedImg.crop_on) {
      return;
    }
  
    const aspectRatio = this.changedImg.rd.width / this.changedImg.rd.height;
    const deltaX = offsetX;
    const deltaY = offsetY * aspectRatio;
    const delta = (Math.abs(deltaX) > Math.abs(deltaY)) ? deltaX : deltaY;
  
    this.changedImg.rd.left += delta;
    this.changedImg.rd.top += delta / aspectRatio;
    this.changedImg.rd.width -= delta;
    this.changedImg.rd.height -= delta / aspectRatio;
  }
  
  topRightResizeImg(offsetX: number, offsetY: number) {
    if (!this.changedImg || !this.changedImg.crop_on) {
      return;
    }
  
    const aspectRatio = this.changedImg.rd.width / this.changedImg.rd.height;
    const deltaX = offsetX;
    const deltaY = -offsetY * aspectRatio;
    const delta = (Math.abs(deltaX) > Math.abs(deltaY)) ? deltaX : deltaY;
  
    this.changedImg.rd.top -= delta / aspectRatio;
    this.changedImg.rd.width += delta;
    this.changedImg.rd.height += delta / aspectRatio;
  }
  
  bottomLeftResizeImg(offsetX: number, offsetY: number) {
    if (!this.changedImg || !this.changedImg.crop_on) {
      return;
    }
  
    const aspectRatio = this.changedImg.rd.width / this.changedImg.rd.height;
    const deltaX = -offsetX;
    const deltaY = offsetY * aspectRatio;
    const delta = (Math.abs(deltaX) > Math.abs(deltaY)) ? deltaX : deltaY;
  
    this.changedImg.rd.left -= delta;
    this.changedImg.rd.width += delta;
    this.changedImg.rd.height += delta / aspectRatio;
  }
  
  bottomRightResizeImg(offsetX: number, offsetY: number) {
    if (!this.changedImg || !this.changedImg.crop_on) {
      return;
    }
  
    const aspectRatio = this.changedImg.rd.width / this.changedImg.rd.height;
    const deltaX = offsetX;
    const deltaY = offsetY * aspectRatio;
    const delta = (Math.abs(deltaX) > Math.abs(deltaY)) ? deltaX : deltaY;
  
    this.changedImg.rd.width += delta;
    this.changedImg.rd.height += delta / aspectRatio;
  }
  
  onRotateImg(offsetX: number, offsetY: number, event) {
    if (!this.changedImg || !this.changedImg.rd.rotating) {
      return;
    }
  
    let center = {
      x: this.boxPosition.left + this.changedImg.rd.width / 2 + this.changedImg.rd.left,
      y: this.boxPosition.top + this.changedImg.rd.height / 2 + this.changedImg.rd.top
    };
  
    let x = event.clientX - center.x;
    let y = event.clientY - center.y;
    let d = this.R2D * Math.atan2(y, x); // Вычисляем текущий угол
  
    // Корректируем угол относительно стартового и предыдущего
    let newAngle = d - this.changedImg.rd.startAngle + (this.changedImg.rd.prevDeg || 0);
  
    // Упрощаем значение угла (если он близок к целому значению)
    let rotation = Math.abs(newAngle - Math.round(newAngle)) <= 0.05 ? Math.round(newAngle) : newAngle;
  
    this.changedImg.rd.deg = rotation;
  
    console.log("Rotation:", rotation);
    this.saveImgPosition();
  }

  rotateImg(event: MouseEvent, resizerImg?: Function, img?) {
    if (!this.changedImg || !this.changedImg.crop_on) {
      return
    }
    console.log('rotateImg event', event)

    this.changedImg.rd.rotating = true;

    let center = {x: this.boxPosition.left + this.changedImg.rd.width/2 + this.changedImg.rd.left, y: this.boxPosition.top + this.changedImg.rd.height/2 + this.changedImg.rd.top};
    let x = event.clientX - center.x;
    let y = event.clientY - center.y;
    this.changedImg.rd.startAngle = Math.round(this.R2D * Math.atan2(y, x));
    this.changedImg.rd.prevDeg = this.changedImg.rd.deg || 0;
    this.resizerImg = resizerImg;

    event.preventDefault();
    event.stopPropagation();
  }

  onCornerClickImg(event: MouseEvent, resizerImg?: Function, img?) {
    if (!this.changedImg || !this.changedImg.crop_on) {
      return
    }
    this.changedImg.rd.draggingCorner = true;
    this.changedImg.rd.px = event.clientX;
    this.changedImg.rd.py = event.clientY;
    this.resizerImg = resizerImg;
    // this.mouseClick = { x: event.clientX, y: event.clientY, left: this.part.rd.left, top: this.part.rd.top };
    // let center = {x: this.boxPosition.left + this.center.x, y: this.boxPosition.top + this.center.y};
    // let x = event.clientX - center.x;
    // let y = event.clientY - center.y;
    // this.startAngle = Math.round(this.R2D * Math.atan2(y, x));
    event.preventDefault();
    event.stopPropagation();
    this.saveImgPosition();
  }

  toggleImgChange(e, img) {
    e.preventDefault();
    e.stopPropagation();
    // img.crop_on = !img.crop_on;

    this.changedDrawText = undefined;
    if (!!img.crop_on) {
      this.changedImg = undefined;
      this.imgs.forEach(house => {
        house.arr.forEach(houseImg => {
          houseImg.crop_on = false;
        })
      })
    } else {
      this.imgs.forEach(house => {
        house.arr.forEach(houseImg => {
          houseImg.crop_on = false;
        })
      })
      this.changedImg = img;
      this.changedImg.crop_on = true;
    }
  }

  onCornerClickDraw(event: MouseEvent, resizerDraw?: Function, text?) {
    // if (!this.changedDrawText && !!text) {
    //   this.changedDrawText = text;
    // }
    if (this.changedDrawText.value != text.value) {
      return
    }
    // this.changedDrawText = text;
    this.changedDrawText.settings.pos.draggingCorner = true;
    this.changedDrawText.settings.pos.px = event.clientX;
    this.changedDrawText.settings.pos.py = event.clientY;
    this.resizerDraw = resizerDraw;
    event.preventDefault();
    event.stopPropagation();
    this.saveSubPosition();
  }

  saveTextDrawPosition() {
    if (!this.changedDrawText) {
      return
    }
    this.changedDrawText.settings.pos.perc = {
      x: (this.changedDrawText.settings.pos.x / this.width)*100,
      y: (this.changedDrawText.settings.pos.y / this.height)*100,
      px: (this.changedDrawText.settings.pos.px / this.width)*100,
      py: (this.changedDrawText.settings.pos.py / this.height)*100,
      width: (this.changedDrawText.settings.pos.width / this.width)*100,
      height: (this.changedDrawText.settings.pos.height / this.height)*100,
    }
  }

  loadTextDrawPosition() {
    if (!this.changedDrawText) {
      return
    }
    this.changedDrawText.settings.pos.x = Math.ceil((this.changedDrawText.settings.pos.perc.x / 100)*this.width);
    this.changedDrawText.settings.pos.y = Math.ceil((this.changedDrawText.settings.pos.perc.y / 100)*this.height);
    this.changedDrawText.settings.pos.px = Math.ceil((this.changedDrawText.settings.pos.perc.px / 100)*this.width);
    this.changedDrawText.settings.pos.py = Math.ceil((this.changedDrawText.settings.pos.perc.py / 100)*this.height);
    this.changedDrawText.settings.pos.width = Math.ceil((this.changedDrawText.settings.pos.perc.width / 100)*this.width);
    this.changedDrawText.settings.pos.height = Math.ceil((this.changedDrawText.settings.pos.perc.height / 100)*this.height);
  }

  saveImgPosition() {
    // console.log("saveImgPosition1", this.width + 'x' + this.height)
    if (!this.changedImg) {
      return
    }
    // console.log("saveImgPosition2", this.changedImg.rd)
    this.changedImg.rd.perc = {
      left: (this.changedImg.rd.left / this.width)*100,
      top: (this.changedImg.rd.top / this.height)*100,
      px: (this.changedImg.rd.px / this.width)*100,
      py: (this.changedImg.rd.py / this.height)*100,
      width: (this.changedImg.rd.width / this.width)*100,
      height: (this.changedImg.rd.height / this.height)*100,
    }
  }

  loadImgPosition(img) {
    // console.log("loadImgPosition1", this.width + 'x' + this.height)
    if (!img) {
      return
    }
    // console.log("loadImgPosition2", img.rd)
    img.rd.left = Math.ceil((img.rd.perc.left / 100)*this.width);
    img.rd.top = Math.ceil((img.rd.perc.top / 100)*this.height);
    if (img.rd.px && img.rd.perc.px) {
      img.rd.px = Math.ceil((img.rd.perc.px / 100)*this.width);
    }
    if (img.rd.py && img.rd.perc.py) {
      img.rd.py = Math.ceil((img.rd.perc.py / 100)*this.height);
    }
    img.rd.width = Math.ceil((img.rd.perc.width / 100)*this.width);
    img.rd.height = Math.ceil((img.rd.perc.height / 100)*this.height);
    // console.log("loadImgPosition3", img.rd)
  }

  // textDraw End

  logImg(img) {
    console.log('img', img) 
    console.log('img.crop_on', img.crop_on) 
    console.log('img.rd', img.rd) 
  }

  log() {
    console.log("this.holst", this.holst)
    console.log("this.height", this.height)
    console.log("this.width", this.width)
    console.log("this.project", this.project)
    // console.log("this.part.subSet", this.part.subSet)
    // console.log("this.part.subSet.pos", this.part.subSet.pos)
    // let x = Math.ceil((+this.part.rd.left + +this.part.subSet.pos.x + (this.part.subSet.pos.width/2))/this.project.percentX);
    // let y = Math.ceil((+this.part.rd.top + +this.part.subSet.pos.y + (this.part.subSet.pos.height/2))/this.project.percentY);
    
    // ${Math.ceil((+part.rd.left + +part.subSet.pos.x)/this.project.percentX)},
    // ${Math.ceil((+part.rd.top + +part.subSet.pos.y)/this.project.percentY) > this.projectForm.value.height / 2 ? 
    //     Math.ceil(this.projectForm.value.height - (+part.rd.top + +part.subSet.pos.y + part.subSet.pos.height)/this.project.percentY) > 0 ? 
    //     Math.ceil(this.projectForm.value.height - (+part.rd.top + +part.subSet.pos.y + part.subSet.pos.height)/this.project.percentY) 
    //     : '0' 
    //   : Math.ceil((+part.rd.top + +part.subSet.pos.y)/this.project.percentY)}
    // console.log("x,y", x,y)
  }

  @HostListener('document:mouseup', ['$event'])
  onCornerRelease(event: MouseEvent) {
    if (!!this.changedDrawText) {
      this.changedDrawText.settings.pos.draggingWindow = false;
      this.changedDrawText.settings.pos.draggingCorner = false;
    } else if (!!this.changedImg) {
      this.changedImg.rd.rotating = false;
      this.changedImg.rd.draggingWindow = false;
      this.changedImg.rd.draggingCorner = false;
    } else {
      if (!this.part.subs_on || !this.part.subSet || !this.part.subSet.pos) {
        return
      }
      this.part.subSet.pos.draggingWindow = false;
      this.part.subSet.pos.draggingCorner = false;
    }
  }

  saveSubPosition() {
    if (!this.part.subSet || !this.part.subSet.pos) {
      return
    }
    this.part.subSet.pos.perc = {
      x: (this.part.subSet.pos.x / this.width)*100,
      y: (this.part.subSet.pos.y / this.height)*100,
      px: (this.part.subSet.pos.px / this.width)*100,
      py: (this.part.subSet.pos.py / this.height)*100,
      width: (this.part.subSet.pos.width / this.width)*100,
      height: (this.part.subSet.pos.height / this.height)*100,
    }
  }

  loadSubPosition() {
    if (!this.part.subSet || !this.part.subSet.pos || !this.part.subSet.pos.perc) {
      return
    }
    this.part.subSet.pos.x = Math.ceil((this.part.subSet.pos.perc.x / 100)*this.width);
    this.part.subSet.pos.y = Math.ceil((this.part.subSet.pos.perc.y / 100)*this.height);
    this.part.subSet.pos.px = Math.ceil((this.part.subSet.pos.perc.px / 100)*this.width);
    this.part.subSet.pos.py = Math.ceil((this.part.subSet.pos.perc.py / 100)*this.height);
    this.part.subSet.pos.width = Math.ceil((this.part.subSet.pos.perc.width / 100)*this.width);
    this.part.subSet.pos.height = Math.ceil((this.part.subSet.pos.perc.height / 100)*this.height);
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    // if (!!changes.outChangedImg && !!changes.outChangedImg.currentValue) {
    //   this.imgs.forEach(house => {
    //     house.arr.forEach(houseImg => {
    //       houseImg.crop_on = false;
    //     })
    //   })
    //   this.changedImg = changes.outChangedImg.currentValue.item;
    //   this.changedImg.crop_on = true;
    // }

    // console.log("ngOnChanges", changes, (!!changes.project && !!changes.project.currentValue) || (changes.width && !!changes.width.currentValue && changes.width.previousValue != changes.width.currentValue) || (changes.height && !!changes.height.currentValue && changes.height.previousValue != changes.height.currentValue));
    if ((!!changes.project && !!changes.project.currentValue) || (changes.width && !!changes.width.currentValue && changes.width.previousValue != changes.width.currentValue) || (changes.height && !!changes.height.currentValue && changes.height.previousValue != changes.height.currentValue)) {
      this.loadBox();
      this.loadContainer();
      this.center = {
        x: +(this.part.rd.width/2).toFixed(2),
        y: +(this.part.rd.height/2).toFixed(2)
      }
      if (this.part.rd.ratio >= 1) {
        this.max_side = this.part.rd.width*2;
      } else {
        this.max_side = this.part.rd.height*2;
      }
      console.log("ngOnChanges0", this.center);
      if (this.resizeTimeout) {
        clearTimeout(this.resizeTimeout);
      }
      console.log("ngOnChanges0.5");

      if (!this.part.saveRD && this.part.rd.params && this.part.rd.params.hasOwnProperty('part_crop_height')) {
        this.part.saveRD = JSON.parse(JSON.stringify(this.part.rd));
      }
      
      this.loadSubPosition();
      this.loadTextDrawPosition();

      if (this.imgs && this.imgs.length) {
        this.imgs.filter(h => this.segmentsIntersect(h.from, h.to, +this.part.prevAllDuration, +this.part.prevAllDuration + +this.part.duration)).forEach(house => {
          house.arr.forEach(houseImg => {
            if (this.segmentsIntersect(houseImg.from, houseImg.to, +this.part.prevAllDuration, +this.part.prevAllDuration + +this.part.duration)) {
              this.loadImgPosition(houseImg);
            }
          });
        })
      }
      
      this.resizeTimeout = setTimeout(() => {
        console.log("ngOnChanges1");
        if (this.project.height && this.part && this.part.rd && this.part.rd.height) {
          this.calculateSizes(this.part, this.project);
        }
      }, 300)
    }
  }

  // перетинаюстья якщо 
  // максимум лівих кординат меньше за мінімум правих кординат
  segmentsIntersect(a1, a2, b1, b2) {
    // Перевіряємо, чи відрізки строго перетинаються
    return Math.max(a1, b1) < Math.min(a2, b2);
  }

  shouldApplyHeight(ind, array) {
    if (ind === 0) return false;

    return array[ind - 1].value === '#n#' || array[ind - 1].value === '';
  }

  ngAfterViewInit() {
    this.loadBox();
    this.loadContainer();
    // this.calculateSizes(this.part, this.project);
    // if (this.part && this.part.has_blur_bg) {
    //   this.loadBlurBg()
    // }
  }

  loadBlurBg(e) {
    if (this.part && !!this.part.has_blur_bg) {
      const video = (e.target as HTMLVideoElement);
      const canvas = document.getElementById(`bg_${this.part.pId}`) as HTMLCanvasElement;
      const ctx = canvas.getContext('2d');
      let rd = this.part.rd;
      let project = this.project;
      // let ratio = this.part.rd.ratio
      // let scale;
      // if (ratio >= 1) {
      //   // горизонтальный холст
      //   // Масштабирование по ширине
      //   scale = canvas.width / videoWidth;
      // } else {
      //   // вертикальный холст
      //   // Масштабирование по высоте
      //   scale = canvas.height / videoHeight;
      // }
      // Настройка размеров холста
      function resizeCanvas() {
        canvas.width = canvas.clientWidth;
        canvas.height = canvas.clientHeight;
      }
  
      // function drawVideoOnCanvas() {
      //   const videoWidth = video.videoWidth;
      //   const videoHeight = video.videoHeight;
      
      //   // Масштабирование по высоте
      //   const scale = canvas.height / videoHeight;
      
      //   // Новая ширина с учётом пропорций
      //   const scaledVideoWidth = videoWidth * scale;
      
      //   // Позиция для центрирования по ширине
      //   const offsetX = (canvas.width - scaledVideoWidth) / 2;
      
      //   // Очищаем холст
      //   ctx.clearRect(0, 0, canvas.width, canvas.height);
      
      //   // Рисуем видео с учётом масштабирования и позиционирования
      //   ctx.drawImage(video, offsetX, 0, scaledVideoWidth, canvas.height);
      // }

      function drawVideoOnCanvas() {
        const videoWidth = video.videoWidth;
        const videoHeight = video.videoHeight;
      
        // Определяем соотношения сторон видео и холста
        const videoRatio = videoWidth / videoHeight;
        const canvasRatio = canvas.width / canvas.height;
      
        let drawWidth, drawHeight, offsetX, offsetY;


        console.log("videoRatio", videoRatio)
        console.log("canvasRatio", canvasRatio)
        console.log("videoWidth", videoWidth)
        console.log("videoHeight", videoHeight)
        console.log("canvas.width", canvas.width)
        console.log("canvas.height", canvas.height)
      
        if (project.ratio >= 1) {
          // Видео более широкое, чем холст
          const scale = project.width / rd.width;
          drawWidth = project.width;
          drawHeight = rd.height * scale;
          offsetX = 0;
          offsetY = (project.height - drawHeight) / 2; // Центрируем по вертикали
        } else {
          // Видео более высокое, чем холст
          const scale = project.height / rd.height;
          drawWidth = rd.width * scale;
          drawHeight = project.height;
          offsetX = (project.width - drawWidth) / 2; // Центрируем по горизонтали
          offsetY = 0;
        }
      
        // Очищаем холст
        ctx.clearRect(0, 0, canvas.width, canvas.height);
      
        // Рисуем видео с учётом масштабирования и позиционирования
        ctx.drawImage(video, offsetX, offsetY, drawWidth, drawHeight);
      }
  
      // Устанавливаем правильные размеры холста при изменении размера окна
      window.addEventListener('resize', resizeCanvas);
      resizeCanvas();
    
      // Рисуем текущий кадр
      drawVideoOnCanvas();
    }
  }

  private loadBox(){
    if (this.box) {
      const {left, top} = this.box.nativeElement.getBoundingClientRect();
      this.boxPosition = {left, top};
    }

    if (this.holst) {
      this.blackHolst = JSON.parse(JSON.stringify(this.holst.getBoundingClientRect()))
      console.log("blackHolst", this.blackHolst)
    }

    // console.log("this.boxPosition", this.boxPosition)
    // const {l, t} = this.rotateEl.nativeElement.getBoundingClientRect();
    // let evRotate = {left: l + 6, top: t + 6};
    // let center = {x: this.boxPosition.left + this.center.x, y: this.boxPosition.top + this.center.y};
    // let x = evRotate.left - center.x;
    // let y = evRotate.top - center.y;
    // this.startAngle = Math.round(this.R2D * Math.atan2(y, x));
  }

  private loadContainer() {
    if (this.boxPosition) {
      const left = this.boxPosition.left - this.part.rd.left;
      const top = this.boxPosition.top - this.part.rd.top;
      const right = left + 600;
      const bottom = top + 450;
      this.containerPos = { left, top, right, bottom };
    }
  }

  cssContrast(item) {
    return ((item.video_filters.contrast - -1000) * (200 - 0)) / (1000 - -1000) + 0;
  }

  loadedVideo(e) {
    if (this.part.from) {
      // console.log("loadedVideo", this.part)
      // console.log("loadedVideo", (e.target as HTMLVideoElement).currentTime);
      (e.target as HTMLVideoElement).currentTime = Number(this.part.from.toFixed(6))
    }
    
    this.Loadedmetadata.emit(e)
  }

  setStatus(event: MouseEvent, status: number){
    if ([1,2,3].includes(status)) {
      document.body.classList.add('rd')
    } else {
      setTimeout(() => {
        document.body.classList.remove('rd')
      }, 0)
    }
    if (status === 1) {
      event.stopPropagation();
    } else if (status === 2) {
      this.mouseClick = { x: event.clientX, y: event.clientY, left: this.part.rd.left, top: this.part.rd.top };
    }
    else if (status === 3) {
      event.stopPropagation(); 
      this.mouseClick = { x: event.clientX, y: event.clientY, left: this.part.rd.left, top: this.part.rd.top };
      let center = {x: this.boxPosition.left + this.center.x, y: this.boxPosition.top + this.center.y};
      let x = event.clientX - center.x;
      let y = event.clientY - center.y;
      this.startAngle = Math.round(this.R2D * Math.atan2(y, x));
      // console.log("SET STATUS", this.startAngle)
    } else {
      this.loadBox();
    }
    this.status = status;
    console.log("setStatus", this.status)
  }

  @HostListener('window:mousemove', ['$event'])
  onMouseMove(event: MouseEvent){
    this.mouse = { x: event.clientX, y: event.clientY };

    if(this.status === Status.RESIZE) this.resize();
    else if(this.status === Status.MOVE) this.move();
    else if(this.status === Status.ROTATE) this.rotate();
  }

  private resize(){
    if(this.resizeCondMeet()){
      this.part.rd.height = Number(this.mouse.y > this.boxPosition.top) ? this.mouse.y - this.boxPosition.top : 0;
      this.part.rd.width = this.part.rd.ratio * this.part.rd.height;
    }

    this.center = {
      x: +(this.part.rd.width/2).toFixed(2),
      y: +(this.part.rd.height/2).toFixed(2)
    }
    this.part.rd.percentX =  this.part.rd.width / this.part.rd.mWidth;
    this.part.rd.percentY = this.part.rd.height / this.part.rd.mHeight;
    // console.log(this.part.rd) 
    this.calculateSizes(this.part, this.project)
  }

  checkDefault(part) {
    if (part.saveRD && Object.keys(part.saveRD.params).some(key => part.saveRD.params[key] != part.rd.params[key])) {
      part.is_changed = true;
    } else {
      part.is_changed = false;
    }
  }

  useSpeedRate(val, element) {
    return (val*(1/element.part_speed_rate)).toFixed(6)
  }

  private resizeCondMeet(){
    return true || (this.mouse.x < this.containerPos.right && this.mouse.y < this.containerPos.bottom);
  }

  private rotate(){
    let center = {x: this.boxPosition.left + this.center.x, y: this.boxPosition.top + this.center.y};
    let x = this.mouse.x - center.x;
    let y = this.mouse.y - center.y;
    let d = this.R2D * Math.atan2(y, x);
    console.log(" rotate center", center)
    let newAngle = Number((d - this.startAngle).toFixed(2));
    let rotation = Math.abs(newAngle - Math.round(newAngle)) <= 0.05 ? Math.round(newAngle) : newAngle;

    this.part.rd.deg = rotation;
    // console.log("startAngle", this.startAngle)
    // console.log("d", d)
    // console.log("rotation", rotation)
    // console.log("deg", this.part.rd.deg)
    this.part.rd.params.part_rotate_deg = this.part.rd.deg;
    this.calculateSizes(this.part, this.project)
  }

  private move(){
    if(this.moveCondMeet()){
      this.part.rd.left = Number(this.mouseClick.left + (this.mouse.x - this.mouseClick.x));
      this.part.rd.top = Number(this.mouseClick.top + (this.mouse.y - this.mouseClick.y));
    }
    this.loadBox();
    // console.log(this.part.rd)

    this.calculateSizes(this.part, this.project)

    // this.part.rd.params = {
    //   part_crop_width: this.part.mWidth,
    //   part_crop_height: this.part.mHeight,
    //   part_crop_x: this.part.rd.left < 0 ? this.part.rd.left/this.project.percentX : 0,
    //   part_crop_y: this.part.rd.top < 0 ? this.part.rd.top/this.project.percentY : 0,
    //   part_scale_width: this.project.mWidth,
    //   part_scale_height: this.project.mHeight,
    //   part_pad_x: 0,
    //   part_pad_y: 0
    // }
  }

  calculateSizes(part, project) {
    if (part.rd.ratio >= 1) {
      this.max_side = part.rd.width*2;
    } else {
      this.max_side = part.rd.height*2;
    }
    // console.log("this.boxPosition", this.boxPosition)
    // console.log("this.project.rect", this.project.rect)
    part.rd.params.part_rotate_deg = part.rd.deg%360;

    if (part.rd.params.part_rotate_deg != 0) {
      const {x, y, height, width} = this.black_sq.nativeElement.getBoundingClientRect();
      let black_sq = {x, y, height, width};
      let real_max_side = this.max_side*2;
      let blackHolst = JSON.parse(JSON.stringify(this.holst.getBoundingClientRect()))
      console.log("NGON #TEST calculateSizes", blackHolst.x, black_sq.x)
      part.rd.params.part_crop_x = blackHolst.x - black_sq.x > 0 ? Math.abs(Math.round((blackHolst.x - black_sq.x)/part.rd.percentX)) : 0;
      part.rd.params.part_crop_y = blackHolst.y - black_sq.y > 0 ? Math.abs(Math.round((blackHolst.y - black_sq.y)/part.rd.percentY)) : 0;
      part.rd.params.part_pad_x = blackHolst.x - black_sq.x < 0 ? Math.abs(Math.round((blackHolst.x - black_sq.x)/project.percentX)) : 0;
      part.rd.params.part_pad_y = blackHolst.y - black_sq.y < 0 ? Math.abs(Math.round((blackHolst.y - black_sq.y)/project.percentY)) : 0

      if ((black_sq.y - blackHolst.y) < 0) {
        if (black_sq.height - Math.abs((black_sq.y - blackHolst.y)) >= project.height) {
          part.rd.params.part_crop_height = Math.round(project.height / part.rd.percentY);
          part.rd.params.part_scale_height = Math.round(project.height / project.percentY);
        } else {
          part.rd.params.part_crop_height = Math.round((black_sq.height - Math.abs((black_sq.y - blackHolst.y))) / part.rd.percentY);
          part.rd.params.part_scale_height = Math.round((black_sq.height - Math.abs((black_sq.y - blackHolst.y))) / project.percentY);
        }
      } else if ((black_sq.y - blackHolst.y) == 0) {
        if (black_sq.height >= project.height) {
          part.rd.params.part_crop_height = Math.round(project.height / part.rd.percentY);
          part.rd.params.part_scale_height = Math.round(project.height / project.percentY);
        } else {
          part.rd.params.part_crop_height = Math.round((black_sq.height) / part.rd.percentY);
          part.rd.params.part_scale_height = Math.round((black_sq.height) / project.percentY);
        }
      } else {
        if (black_sq.height + (black_sq.y - blackHolst.y) <= project.height) {
          part.rd.params.part_crop_height = Math.round(black_sq.height / part.rd.percentY);
          part.rd.params.part_scale_height = Math.round(black_sq.height / project.percentY);
        } else {
          part.rd.params.part_crop_height = Math.round((project.height - (black_sq.y - blackHolst.y)) / part.rd.percentY);
          part.rd.params.part_scale_height = Math.round((project.height - (black_sq.y - blackHolst.y)) / project.percentY);
        }
      }
      if ((black_sq.x - blackHolst.x) < 0) {
        if (black_sq.width - Math.abs((black_sq.x - blackHolst.x)) >= project.width) {
          part.rd.params.part_crop_width = Math.round(project.width / part.rd.percentX);
          part.rd.params.part_scale_width = Math.round(project.width / project.percentX);
        } else {
          part.rd.params.part_crop_width = Math.round((black_sq.width - Math.abs((black_sq.x - blackHolst.x))) / part.rd.percentX);
          part.rd.params.part_scale_width = Math.round((black_sq.width - Math.abs((black_sq.x - blackHolst.x))) / project.percentX);
        }
      } else if ((black_sq.x - blackHolst.x) == 0) {
        if (black_sq.width >= project.width) {
          part.rd.params.part_crop_width = Math.round(project.width / part.rd.percentX);
          part.rd.params.part_scale_width = Math.round(project.width / project.percentX);
        } else {
          part.rd.params.part_crop_width = Math.round((black_sq.width) / part.rd.percentX);
          part.rd.params.part_scale_width = Math.round((black_sq.width) / project.percentX);
        }
      } else {
        if (black_sq.width + (black_sq.x - blackHolst.x) <= project.width) {
          part.rd.params.part_crop_width = Math.round(black_sq.width / part.rd.percentX);
          part.rd.params.part_scale_width = Math.round(black_sq.width / project.percentX);
        } else {
          part.rd.params.part_crop_width = Math.round((project.width - (black_sq.x - blackHolst.x)) / part.rd.percentX);
          part.rd.params.part_scale_width = Math.round((project.width - (black_sq.x - blackHolst.x)) / project.percentX);
        }
      }

    } else {
    
      part.rd.params.part_crop_x = part.rd.left < 0 ? Math.round(Math.abs(part.rd.left)/part.rd.percentX) : 0;
      part.rd.params.part_crop_y = part.rd.top < 0 ? Math.round(Math.abs(part.rd.top)/part.rd.percentY) : 0;
      part.rd.params.part_pad_x = part.rd.left > 0 ? Math.round(part.rd.left/project.percentX) : 0;
      part.rd.params.part_pad_y = part.rd.top > 0 ? Math.round(part.rd.top/project.percentY) : 0;
  
      if (part.rd.top < 0) {
        if (part.rd.height - Math.abs(part.rd.top) >= project.height) {
          part.rd.params.part_crop_height = Math.round(project.height / part.rd.percentY);
          part.rd.params.part_scale_height = Math.round(project.height / project.percentY);
        } else {
          part.rd.params.part_crop_height = Math.round((part.rd.height - Math.abs(part.rd.top)) / part.rd.percentY);
          part.rd.params.part_scale_height = Math.round((part.rd.height - Math.abs(part.rd.top)) / project.percentY);
        }
      } else if (part.rd.top == 0) {
        if (part.rd.height >= project.height) {
          part.rd.params.part_crop_height = Math.round(project.height / part.rd.percentY);
          part.rd.params.part_scale_height = Math.round(project.height / project.percentY);
        } else {
          part.rd.params.part_crop_height = Math.round((part.rd.height) / part.rd.percentY);
          part.rd.params.part_scale_height = Math.round((part.rd.height) / project.percentY);
        }
      } else {
        if (part.rd.height + part.rd.top <= project.height) {
          part.rd.params.part_crop_height = Math.round(part.rd.height / part.rd.percentY);
          part.rd.params.part_scale_height = Math.round(part.rd.height / project.percentY);
        } else {
          part.rd.params.part_crop_height = Math.round((project.height - part.rd.top) / part.rd.percentY);
          part.rd.params.part_scale_height = Math.round((project.height - part.rd.top) / project.percentY);
        }
      }
  
      if (part.rd.left < 0) {
        if (part.rd.width - Math.abs(part.rd.left) >= project.width) {
          part.rd.params.part_crop_width = Math.round(project.width / part.rd.percentX);
          part.rd.params.part_scale_width = Math.round(project.width / project.percentX);
        } else {
          part.rd.params.part_crop_width = Math.round((part.rd.width - Math.abs(part.rd.left)) / part.rd.percentX);
          part.rd.params.part_scale_width = Math.round((part.rd.width - Math.abs(part.rd.left)) / project.percentX);
        }
      } else if (part.rd.left == 0) {
        if (part.rd.width >= project.width) {
          part.rd.params.part_crop_width = Math.round(project.width / part.rd.percentX);
          part.rd.params.part_scale_width = Math.round(project.width / project.percentX);
        } else {
          part.rd.params.part_crop_width = Math.round((part.rd.width) / part.rd.percentX);
          part.rd.params.part_scale_width = Math.round((part.rd.width) / project.percentX);
        }
      } else {
        if (part.rd.width + part.rd.left <= project.width) {
          part.rd.params.part_crop_width = Math.round(part.rd.width / part.rd.percentX);
          part.rd.params.part_scale_width = Math.round(part.rd.width / project.percentX);
        } else {
          part.rd.params.part_crop_width = Math.round((project.width - part.rd.left) / part.rd.percentX);
          part.rd.params.part_scale_width = Math.round((project.width - part.rd.left) / project.percentX);
        }
      }
    }
    // console.log("PARAMS ### --- ", part.rd.params)
    this.checkDefault(part);

    if (!!this.combine && !!this.forAll) {
      this.combine.blends.forEach(blend => {
        if (blend.pId != part.pId && blend.meta_width == part.meta_width && blend.meta_height == part.meta_height) {
          blend.rd = part.rd;
          blend.is_changed = part.is_changed;
        }
      })
    }
  }

  private moveCondMeet(){
    const offsetLeft = this.mouseClick.x - this.boxPosition.left; 
    const offsetRight = this.part.rd.width - offsetLeft; 
    const offsetTop = this.mouseClick.y - this.boxPosition.top;
    const offsetBottom = this.part.rd.height - offsetTop;
    return true || (
      this.mouse.x > this.containerPos.left + offsetLeft && 
      this.mouse.x < this.containerPos.right - offsetRight &&
      this.mouse.y > this.containerPos.top + offsetTop &&
      this.mouse.y < this.containerPos.bottom - offsetBottom
      );
  }
}