<div class="container cases_page">
    <div class="dashboard_wrp">
        <div class="dashboard">
            <div class="dash_item" (mouseenter)="!is_mobile ? onMouseEnter($event, 0) : null" (click)="!hoveredStatus[0].hover ? onMouseEnter($event, 0) : null;" [ngClass]="{'hovered': hoveredStatus[0].hover}">
                <div class="page_title">
                    <div class="page_name">
                        {{ "Transactions" | translate }}
                    </div>
                    <div class="chat_icon_wrp">
                        <mat-icon>import_export</mat-icon>
                    </div>
                </div>

                <a (click)='goToTransactions()' class="link-to-page page_btn hover_viss">
                    {{ "Open" | translate }}
                </a>
            </div>            
            
            <div class="dash_item" (mouseenter)="!is_mobile ? onMouseEnter($event, 1) : null" (click)="!hoveredStatus[1].hover ? onMouseEnter($event, 1) : null;" [ngClass]="{'hovered': hoveredStatus[1].hover}">
                <div class="page_title">
                    <div class="page_name">
                        {{ "My services" | translate }}
                    </div>
                    <div class="chat_icon_wrp">
                        <mat-icon>miscellaneous_services</mat-icon>
                    </div>
                </div>

                <a (click)='goToServices()' class="link-to-page page_btn hover_viss">
                    {{ "Open" | translate }}
                </a>
            </div>            
            <div class="dash_item" (mouseenter)="!is_mobile ? onMouseEnter($event, 2) : null" (click)="!hoveredStatus[2].hover ? onMouseEnter($event, 2) : null;" [ngClass]="{'hovered': hoveredStatus[2].hover}">
                <div class="page_title">
                    <div class="page_name">
                        {{ "Promos" | translate }}
                    </div>
                    <div class="chat_icon_wrp">
                        <mat-icon>password</mat-icon>
                    </div>
                </div>

                <a (click)='goToPromos()' class="link-to-page page_btn hover_viss">
                    {{ "Open" | translate }}
                </a>
            </div>            
        </div>
    </div>
</div>

