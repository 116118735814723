import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class MinimizeService {
  public minimizes$: Subject<any> = new Subject();
  constructor() { }

  getMinimizes() {
    return this.minimizes$.asObservable();
  }
}
