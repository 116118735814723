import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { MatStepper } from '@angular/material/stepper';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { forkJoin, from, of, ReplaySubject, Subject, Subscription } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, finalize, last, map, mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';
import { DeleteAlertComponent } from 'src/app/shared/global_components/delete-alert/delete-alert.component';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { BaseClass } from 'src/app/shared/models/base-class';
import { GlobalDataService } from 'src/app/shared/services/common/global-data.service';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { UsersService } from 'src/app/shared/services/rest/users.service';
import { environment } from 'src/environments/environment';
import { DialogGroupCreateComponent } from '../dialogs/groups/group-create/group-create.component';
import { DialogGroupEditComponent } from '../dialogs/groups/group-edit/group-edit.component';
import { PaymentInfoComponent } from '../dialogs/member/payment-info/payment-info.component';
import { CreateTaskStatusComponent } from '../dialogs/types/create-task-status/create-task-status.component';
import { CreateWorkStatusComponent } from '../dialogs/types/create-work-status/create-work-status.component';
import { EditTaskStatusComponent } from '../dialogs/types/edit-task-status/edit-task-status.component';
import { EditWorkStatusComponent } from '../dialogs/types/edit-work-status/edit-work-status.component';
import { ParametersService } from 'src/app/shared/services/rest/parameters.service';
import { ScenariosService } from 'src/app/shared/services/rest/scenarios.service';
import { MembersService } from 'src/app/shared/services/rest/members.service';

@Component({
  selector: 'app-set-up-auto',
  templateUrl: './set-up-auto.component.html',
  styleUrls: ['./set-up-auto.component.scss']
})
export class SetUpAutoComponent extends BaseClass implements OnInit, OnDestroy {
  public editable: boolean = false;

  public creatingWorkspace: FormGroup;
  public selectedIndex: number = 0;
  public cardTypeEnd: boolean = false;
  public jobTypeEnd: boolean = false;
  public tagsEnd: boolean = false;
  public info: any;

  public companyTime: FormGroup;
  public companyTimeStart: boolean = false;
  public timeZoneEnd: boolean = false;
  public timezones: any;
  public timezoneFilterCtrl: FormControl = new FormControl();
  protected _onDestroy = new Subject<void>();
    /** list of bank groups filtered by search keyword for option groups */
  public filteredTimezonesGroups: ReplaySubject<any> = new ReplaySubject<any>(1);
  
  public creatingChannel: FormGroup;
  public langParam: any;
  public channel: any;
  public langTags: any;
  public langTagsControl: FormControl = new FormControl();
  public langTags$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public groups: any = [];
  public creatingChannelStart: boolean = false;
  public channelEnd: boolean = false;
  public videosEnd: boolean = false;
  public channelTagEnd: boolean = false;
  public groupsEnd: boolean = false;


  public streamAuto: FormGroup;
  public auto: any;
  public card: any;
  public streamAutoStart: boolean = false;
  public autoEnd: boolean = false;
  public cardEnd: boolean = false;
  public streamsEnd: boolean = false;

  public videosList: FormGroup;
  public videosListStart: boolean = false;
  public isLoadVid: boolean = false;
  public noVid: boolean = false;
  public typeStatus: any = 'All';
  public vidPage: number = 1;
  public vidPagination: any;
  public videos: any[] = [];
  throttle = 300;
  scrollUpDistance = 3.5;
  
  public cardsList: FormGroup;
  public isGettingCards: boolean = false;
  public isLoadCards: boolean = false;
  public isEndCards: boolean = false;
  public isEndJobs: boolean = false;
  public isEndImports: boolean = false;
  public cardsCount: number = 0;
  public cardPage = 1;
  public cardPagination: any;
  public cards: any = [];
  public eltsSub: Subscription;
  public progress:number = 0;

  public firstFormGroup: FormGroup;
  public form: FormGroup;
  public virtual: FormGroup;
  public secondFormGroup: FormGroup;
  public thirdFormGroup: FormGroup;
  public tabIndex: number = 0;
  public memberIndex: number = 0;
  public earningsVal: FormControl = this.fb.control(0);
  public company_id: any;
  public company: any;
  public activeLang: any;
  public host: any = environment.host;
  public origin = window.location.origin;
  public currencyList: any;
  public taskTypes: any;
  public types: any;
  public onlyMe: boolean = true;
  public employees: object[];
  public numberRegEx = /\d*\.?\d{1,2}/;
  public payState:number = 0;
  public newUser: any;

  constructor(
    private fb: FormBuilder,
    private parametersService: ParametersService,
    private scenariosService: ScenariosService,
    private usersService: UsersService,
    private membersService: MembersService,
    private companyService: CompanyService,
    private taskService: TaskService,
    private languageService: LanguageService,
    private bottomSheet: MatBottomSheet,
    private activatedRoute: ActivatedRoute,
    private globalDataService: GlobalDataService,
    private layoutService: LayoutService,
    private dialog: MatDialog,
    private router: Router,
    private sm: StorageManagerService
    ) {
      super()
    }

  ngOnInit(): void {
    this.company_id = this.activatedRoute.snapshot.queryParamMap.get('company_id');

    this.attachSubscriptions(
      this.taskService.getTaskTypesToCompany(this.company_id, 'en').pipe(
        switchMap(cardTypes => {
          this.taskTypes = cardTypes;
          let findedCardType = cardTypes.find(x => x.name	== "Content")
          if (findedCardType.taskStatusToCompany && findedCardType.taskStatusToCompany.length) {
            return of(findedCardType.taskStatusToCompany[0])
          } else {
            return this.taskService.selectTaskStatus({
              task_status_id: findedCardType.id,
              company_id: this.company_id
            }).pipe(
              tap(res => {
                console.log('selectTaskStatus', res)
              })
            )
          }
        }),
        switchMap(cardType => {
          this.cardTypeEnd = true;
          console.log("cardType", cardType)
          return this.taskService.getOperationsToCompany(this.company_id, 'en').pipe(
            switchMap(jobTypes => {
              this.taskTypes = jobTypes;
              let findedJobType = jobTypes.find(x => x.name	== "Import videos")
              if (findedJobType.operationToCompany && findedJobType.operationToCompany.length) {
                return of({
                  resCardType: cardType,
                  resJobType: findedJobType.operationToCompany[0]
                })
              } else {
                return this.taskService.selectOperation({
                  operation_id: findedJobType.id,
                  company_id: this.company_id
                }).pipe(
                  map(res => {
                    console.log('selectJobStatus', res)
                    return {
                      resCardType: cardType,
                      resJobType: res
                    }
                  })
                )
              }
            }),
          )
        }),
        switchMap(info => {
          this.jobTypeEnd = true;
          return this.parametersService.getApiParametersWithPage('1', 0, null, '1').pipe(
            switchMap(el => {
              let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
              let arr = []
              for (let index = 1; index <= pages; index++) {
                arr.push(index)
              }
    
              return forkJoin(arr.map(x => this.parametersService.getApiParametersWithPage(x, 0, null, '200').pipe(map(u => u.body)))).pipe(
                last(),
                switchMap(values => {
                  let allParams = [].concat(...values);
                  console.log("allParams", "TEST", allParams);
                  // return this.parametersService.importApiParameter(Object.assign({id: fID}, sbmtData))
                  return forkJoin(allParams.map(k => this.parametersService.importApiParameter({company_id: this.company_id, id: k.id}))).pipe(
                    map((params) => {
                      return {...info, params}
                    })
                  )
                }),
              )
            }),
          )
        }),
        switchMap(info => {
          this.tagsEnd = true;
          this.langParam = info.params.find(k => k.name == 'Language' || k.name == 'Language version');
          return this.parametersService.getApiParameterValues(this.company_id, this.langParam.id).pipe(
            map((res) => {
              console.log("res getApiParameterValues", res)
              this.langTags = res.body;
              this.langTags$.next(this.langTags.slice())
              return info;
            })
          )
        })
      ).subscribe(resp => {
        this.info = resp;
        console.log("Finish", this.info)
        // this.editable = true;
        this.creatingWorkspace.patchValue({
          is_ready: 1
        })
        this.selectedIndex = 1;

        // setTimeout(() => {
        //   this.editable = false;
        // }, 2000)
        // this.router.navigate(['/settings'], { queryParams: {company_id: this.company_id, page: 'profiles'} });
        // this.router.navigate()
      })
    )
    // this.getCompany();
    // this.getCurrencyList();
    // this.getEmployees();
    // this.getLangData();
    this.getTypes();
    // this.getTaskTypes();
    // this.form = this.fb.group({
    //   company_id: this.company_id,
    //   vuser_id: '',
    //   currency_id: 0,
    //   permissions: '',
    //   rate_target: [0, [Validators.pattern(this.numberRegEx)]],
    //   salary: [0, [Validators.pattern(this.numberRegEx)]],
    // })

    // this.virtual = this.fb.group({
    //   lastname: ['', Validators.required],
    //   firstname: ['', Validators.required],
    // })
    this.creatingWorkspace = this.fb.group({
      is_ready: ['', Validators.required],
    });

    this.companyTime = this.fb.group({
      timezone: ['', Validators.required],
      timezone_offset: ''
    });

    this.videosList = this.fb.group({
      is_ready: ['', Validators.required],
    });

    this.creatingChannel = this.fb.group({
      name: ['', Validators.required],
      url: ['', Validators.required],
      tag: ['', Validators.required]
    });

    this.streamAuto = this.fb.group({
      streams_count: [15, Validators.required],
      execute_interval: [40, Validators.required],
      concurrent_views_count_restart_threshold: [10, Validators.required],
      resumed_duration_restart_threshold: [120, Validators.required],
      made_for_kids: [false, Validators.required],
      record_from_start: [false, Validators.required]
    });

    this.cardsList = this.fb.group({
      is_ready: ['', Validators.required],
    });

    this.attachSubscriptions(
      this.langTagsControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchValues(resp))
    )
    // this.firstFormGroup = this.fb.group({
    //   firstCtrl: ['', Validators.required],
    // });
    // this.secondFormGroup = this.fb.group({
    //   secondCtrl: ['', Validators.required],
    // });
    // this.thirdFormGroup = this.fb.group({
    //   thirdCtrl: ['', Validators.required],
    // });

    this.getTimeZones();
    this.companyTime.get("timezone").valueChanges.subscribe(x => {
      this.companyTime.patchValue({
        timezone_offset: x == "no" ? '' : this.getOffsetData(x)
      })
    })

    

    this.timezoneFilterCtrl.valueChanges
    .pipe(takeUntil(this._onDestroy))
    .subscribe(() => {
      this.filterTimezoneGroups();
    });
  }

  protected filterTimezoneGroups() {
    if (!this.timezones) {
      return;
    }
    // get the search keyword
    let search = this.timezoneFilterCtrl.value;
    const timezonesCopy = this.copyTimezoneGroups(this.timezones);
    if (!search) {
      this.filteredTimezonesGroups.next(timezonesCopy);
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredTimezonesGroups.next(
      timezonesCopy.filter(timezoneGroup => {
        const showBankGroup = timezoneGroup.value.toLowerCase().indexOf(search) > -1;
        if (!showBankGroup) {
          timezoneGroup.names = timezoneGroup.names.filter(name => name.toLowerCase().indexOf(search) > -1);
        }
        return timezoneGroup.names.length > 0;
      })
    );
  }

  protected copyTimezoneGroups(timezoneGroups: any) {
    const timezonesCopy = [];
    timezoneGroups.forEach(bankGroup => {
      timezonesCopy.push({
        value: bankGroup.value,
        names: bankGroup.names.slice()
      });
    });
    return timezonesCopy;
  }

  getTimeZone(val) {
    let o = Math.abs(val);
    return (val >= 0 ? "+" : "-") + ("00" + Math.floor(o / 3600)).slice(-2) + ":00";
  }
  
  getOffsetData(name) {
    if (!this.timezones) {
      return "0"
    }
    return this.timezones.find(x => x.names.filter(y => y == name).length > 0).value
  }

  getTimeZones() {
    this.attachSubscriptions(
      this.companyService.getTimeZones().subscribe(resp => {
        let val = [];
        Object.keys(resp).forEach(key => {
          val.push({
            value: key,
            names: resp[key]
          })
        })
        this.timezones = val;
        this.filteredTimezonesGroups.next(this.copyTimezoneGroups(this.timezones));
        console.log("getTimeZones",this.timezones);
      })
    )
  }

  selectionChange($event?: StepperSelectionEvent): void {
    console.log("selectionChange", $event.selectedIndex);
    this.selectedIndex = $event.selectedIndex;
    if ($event.selectedIndex == 4) {
      this.getVideos(this.vidPage);
    }
    if ($event.selectedIndex == 5) {
      this.getCards(this.cardPage)
    }
  }

  getCards(n) {
    this.isLoadCards = true;
    let filterData:any = {"group_id": this.groups.find(k => k.name == `${this.creatingChannel.value.name} - Import`).id};

    this.eltsSub = this.taskService.getTasksSelectNoExpNew(n, this.company_id, filterData, 'channels').pipe(
      tap(el => {
        this.cardPagination = {
          'pageCount': el.headers.get('x-pagination-page-count'),
          'perPage': el.headers.get('x-pagination-per-page'),
          'totalCount': el.headers.get('x-pagination-total-count'),
          'currentPage': el.headers.get('x-pagination-current-page')
        }
        console.log("this.pagination TEST", this.cardPagination)
      }),
      map(el => el.body),
      distinctUntilChanged()
    ).subscribe(resp => {

      console.log("getCards", resp)
      
      if (this.cardPage == 1) {
        this.cards = resp;
      } else {
        this.cards.push(...resp);
      }
      console.log("cards - " + this.cardPage, this.cards)

      this.progress = +((this.cards.length / +this.cardPagination.totalCount)*100).toFixed(1);
      this.cardPage = this.cardPage + 1;
      
      
      if (+this.cardPagination['currentPage'] < +this.cardPagination['pageCount'] && +this.cardPagination['pageCount'] > 0) {
        this.getCards(this.cardPage)
      } else {
        this.isLoadCards = false;
        this.progress = 100;
        if (this.cards.length == this.cardsCount) {
          this.isEndCards = true;
          this.createJobs()
        } else {
          this.isEndCards = false;
        }
      }
    },
    error => {
      this.isLoadCards = false;
    })
  }

  refreshCards() {
    this.cardPage = 1;
    this.cards = []
    this.getCards(this.cardPage)
  }

  getVideos(page) {
    this.isLoadVid = true;
    let filterData:any = {
      channel_id: this.channel.id
    }

    filterData.type='channel' 

    if (this.typeStatus == 'is_shorts') {
      filterData.is_shorts = 1; 
    } else if (this.typeStatus == 'is_regular') {
      filterData.is_regular = 1; 
    }

    this.attachSubscriptions(
      this.companyService.getVideos(page, this.company_id, filterData).pipe(
        tap(el => {
          this.vidPagination = {
            'pageCount': el.headers.get('x-pagination-page-count'),
            'perPage': el.headers.get('x-pagination-per-page'),
            'totalCount': el.headers.get('x-pagination-total-count'),
            'currentPage': el.headers.get('x-pagination-current-page'),
          }
        }),
        map(el => el.body)
      ).subscribe(resp => {
        this.isLoadVid = false;
        console.log("getVideos", resp)
        if (page == 1) {
          if (resp.length == 0) {
            this.noVid = true;
          } else {
            this.noVid = false;
          }
          this.videos = resp
        } else {
          this.videos.push(...resp);
        }

        this.vidPage = this.vidPage + 1;
      }, error => {
        this.isLoadVid = false;
        this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
      })
    )
  }

  onInput(event: Event, key, min): void {
    const inputElement = event.target as HTMLInputElement;
    let value = Number(inputElement.value);
  
    // Если значение меньше min или не является числом, устанавливаем 60
    if (isNaN(value) || value < min) {
      value = min;
    }
  
    // Обновляем значение в форме
    this.streamAuto.get(key)?.setValue(value);
  }

  createChannel() {
    this.creatingChannelStart = true
    // https://www.youtube.com/@alexandrepic4065
    this.attachSubscriptions(
      this.companyService.createCompanyGroupProfile({
        "ai_description": "",
        "company_id": this.company_id,
        "group_id": "",
        "name": this.creatingChannel.value.name,
        "platform_id": 1,
        "publishing_params": {
          "made_for_kids": true
        },
        "url": this.creatingChannel.value.url
      }).pipe(
        switchMap(channel => {
          this.channelEnd = true;
          this.channel = {...channel}
          return this.companyService.editCompanyGroupProfile(channel.id, {
            "is_update_data": 1
          }).pipe(
            tap(() => this.videosEnd = true),
            map(() => channel)
          )
        }),
        switchMap(channel => {
          return this.parametersService.addTargetChannelParameter({
            company_id: this.company_id,
            parameter_id: this.langParam.id,
            parameter_value_id: this.creatingChannel.value.tag,
            channel_id: channel.id
          }).pipe(map(() => channel))
        }),
        switchMap(channel => {
          this.channelTagEnd = true;
          return forkJoin([this.companyService.createGroupCompany({
            "company_id": this.company_id,
            "description": "",
            "name": `${this.creatingChannel.value.name} - Import`
          }), this.companyService.createGroupCompany({
            "company_id": this.company_id,
            "description": "",
            "name": "Live"
          })]).pipe(
            map(groups => {
              this.groupsEnd = true;
              this.groups = groups

              return channel;
            })
          )
        })
      ).subscribe(resp => {
        this.selectedIndex = 2;
      })
    )
  }

  createAuto() {
    this.streamAutoStart = true;
    let x = {
      "company_id": this.company_id,
      "is_task": 1,
      "name": "Base Auto Stream",
      "scenario_data": {
        "concurrent_views_count_restart_threshold": this.streamAuto.value.concurrent_views_count_restart_threshold,
        "execute_interval": this.streamAuto.value.execute_interval*60,
        "publishing_params": {
          "enable_auto_start": 1,
          "enable_auto_stop": 1,
          "enable_dvr": 1,
          "is_backup": 0,
          "is_loop": 1,
          "is_random": 1,
          "made_for_kids": !!this.streamAuto.value.made_for_kids ? 1 : 0,
          "privacy_status": "public",
          "random_start_count": 2,
          "record_from_start": !!this.streamAuto.value.record_from_start ? 1 : 0
        },
        "restart_content_statuses_ids": [
          1,
          4,
          5,
          8
        ],
        "resumed_duration_restart_threshold": 7200
      },
      "scenario_name": "TaskLiveStreamRestart"
    }

    this.attachSubscriptions(
      this.scenariosService.addAutomationTemplates(x).pipe(
        switchMap(auto => {
          this.auto = auto;
          this.autoEnd = true;

          let cardData = {
            "comment": "",
            "company_id": this.company_id,
            "consist_of_task_id": {
              "add": []
            },
            "custom_id": 0,
            "group_id": this.groups.find(k => k.name == 'Live').id,
            "is_random_avatar": 1,
            "name": "Base Card",
            "part_of_task_id": {
              "add": []
            },
            "priority": 0,
            "private_comment": "",
            "related_task_id": {
              "add": []
            },
            "status_id": this.info.resCardType.task_status_id
          }

          return this.taskService.createTask(this.company_id, cardData).pipe(
            tap(k => {
              this.card = k;
              this.card.channels = []
              this.cardEnd = true;
            }),
          )
        }),
        switchMap(() => {
          let streamData = {
            "channel_id": this.channel.id,
            "content_description": "",
            "content_filename": "",
            "content_image": "",
            "content_name": "",
            "content_published_at": "",
            "content_status_id": 1,
            "content_type_id": 106,
            "content_url": "",
            "content_views_count": "",
            "external_content_id": "",
            "is_bravourl_content_views_count_update": 0,
            "is_content_views_count_update": 0,
            "publishing_params": {
              "is_api_set_up_new": 0,
              "is_backup": 0,
              "is_loop": 0,
              "is_random": 0,
              "live_stream_key": "",
              "live_stream_to": "rtmp://a.rtmp.youtube.com/live2/",
              "live_stream_to_backup": "rtmp://b.rtmp.youtube.com/live2/?backup=1",
              "live_stream_url": "rtmp://a.rtmp.youtube.com/live2",
              "live_stream_url_backup": "rtmp://b.rtmp.youtube.com/live2?backup=1",
              "random_start_count": 1
            },
            "task_id": this.card.id
          }
          let streamsArr = []
          for (let index = 0; index < this.streamAuto.value.streams_count; index++) {
            streamsArr.push(streamData);
          }
          // return this.taskService.addProfile(streamData, this.company_id)

          return from(streamsArr).pipe(
            mergeMap(x => this.taskService.addProfile(x, this.company_id).pipe(
              catchError(() => of([])),
              tap(val => { /* логику для каждого элемента */ 
                this.card.channels.push(val);
              })
            ), 10), // 10 запросов параллельно
            finalize(() => {
              // Этот блок выполнится только после обработки всех элементов
              this.streamsEnd = true;
              console.log('Все запросы завершены');
              // Здесь можно дополнительно обработать card.is_ok_tags или другие поля
            }),
            last()
          );
        })
      ).subscribe(resp => {
        console.log('subscribe завершен');
        this.selectedIndex = 3
      })
    )
  }

  createJobs() {
    let jobsArr = [];
    this.cards.forEach(element => {
      jobsArr.push({
        "comment": "",
        "completed_at": "",
        "create_parameter_values_to_task": [
          {
            "is_primary": 1,
            "parameter_id": this.langParam.id,
            "parameter_value_id": this.creatingChannel.value.tag
          }
        ],
        "employee_comment": "",
        "employee_id": 0,
        "name": "",
        "operation_id": this.info.resJobType.operation_id,
        "priority": 0,
        "private_comment": "",
        "status_id": 1,
        "task_id": element.id
      })
    });
    this.attachSubscriptions(

      from(jobsArr).pipe(
        mergeMap(x => this.taskService.addWork(this.company_id, x).pipe(
          catchError(() => of([])),
          switchMap(val => {
            return this.membersService.addMember({
              company_id: this.company_id,
              employee_id: this.company.employees[0].id,
              is_manager: 1,
              status_id: 1,
              task_id: x.task_id,
              task_operation_id: val.id
            }, this.company_id).pipe(map(() => val))
          }),
          tap(val => { /* логику для каждого элемента */ 
            this.cards.find(k => k.id == x.task_id).operations = [val];
          })
        ), 10), // 10 запросов параллельно
        finalize(() => {
          // Этот блок выполнится только после обработки всех элементов
          this.isEndJobs = true;
          console.log('Все jobsArr запросы завершены');
          // Здесь можно дополнительно обработать card.is_ok_tags или другие поля
        }),
        last()
      ).subscribe(res => {
        console.log('subscribe jobsArr завершен');
        this.createImports()
      })
    )
  }

  setTimeZone() {
    this.companyTimeStart = true;
    let sbmtData = {...this.companyTime.value}
    if (sbmtData.timezone == 'no') {
      sbmtData.timezone = ''
    } else {
      delete sbmtData.timezone_offset
    }

    this.attachSubscriptions(
      this.companyService.editCompany(this.company_id, sbmtData).subscribe(resp => {
        this.timeZoneEnd = true;
        console.log(resp);
        this.getCompany();
        this.selectedIndex = 4;
        this.companyTimeStart = false;
      })
    )
  }

  createImports() {
    this.attachSubscriptions(
      from(this.cards).pipe(
        mergeMap((x:any) => {
          let importData = {
            company_id: this.company_id,
            save_task_id: x.id,
            save_task_channel_id: x.channels[0].id,
            save_location: '/',
            save_storage_code: 'eeur-storage-cf',
            is_to_process: 1,
            save_task_operation_id: x.operations[0].id,
            is_prefix_filename: 1,
            is_save_auto_post_data: 1,
            params: {
              thumbnail: 1,
              file: 1
            }
          }
          return this.taskService.createImports(importData, this.company_id)
          .pipe(
            catchError(() => of([])),
          )
        }, 10), // 10 запросов параллельно
        finalize(() => {
          // Этот блок выполнится только после обработки всех элементов
          this.isEndImports = true;
          console.log('Все createImports запросы завершены');
          // Здесь можно дополнительно обработать card.is_ok_tags или другие поля
        }),
        last()
      ).subscribe(result => {
        console.log('subscribe createImports завершен');
        this.cardsList.patchValue({
          is_ready: 1
        })
        this.layoutService.showSnackBar({name: `The file import request was successful, please wait for the files to appear.`}, marker("Refresh the page periodically until the files appear."), SnackBarItem);
        this.goToFiles();
      })
    )
  }

  goToFiles() {
    this.router.navigate(['/files'], { queryParams: {company_id: this.company_id, location_all: '/', count: 1, group_id: this.groups.find(k => k.name == `${this.creatingChannel.value.name} - Import`).id, group_by: 'original_file_id'} });
  }

  goToOutlets() {
    this.router.navigate(['/settings'], { queryParams: {company_id: this.company_id, page: 'profiles'} });
  }
  
  onDown(e) {
    console.log(e?.target?.scrollTop)
    if (this.vidPage <= this.vidPagination['pageCount'] && !this.isLoadVid) {
      console.log("#########onDown")
      this.getVideos(this.vidPage);
    }
  }

  refreshVid() {
    this.vidPage = 1;
    this.getVideos(this.vidPage);
  }

  onSearchValues(resp) {
    if (!this.langTags) {
      return;
    }

    if (!resp) {
      this.langTags$.next(this.langTags.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.langTags$.next(
      this.langTags.filter(z => z.value.toLowerCase().indexOf(resp) > -1)
    );
  }

  createNotAssigned() {
    console.log("createNotAssigned");
    this.cardsCount = this.videos.length;
    this.videosListStart = true;
    let sbmtVal:any = {
      "channel_id": this.channel.id,
      "company_id": this.company_id,
      "data": {
        "comment": "",
        "consist_of_task_id": {
          "add": []
        },
        "custom_id_getter": "filename_integer_spaced",
        "group_id": this.groups.find(k => k.name == `${this.creatingChannel.value.name} - Import`).id,
        "is_not_assigned": 1,
        "is_random_avatar": 1,
        "name": "{video_name}",
        "part_of_task_id": {
          "add": []
        },
        "priority": 0,
        "private_comment": "",
        "related_task_id": {
          "add": []
        },
        "status_id": this.info.resCardType.task_status_id,
        "type": "channel"
      },
      "is_active": 1,
      "name": "Base Cards Reques"
    }

    if (this.typeStatus && this.typeStatus != 'All') {
      sbmtVal.data[this.typeStatus] = 1;
    }

    this.attachSubscriptions(
      this.taskService.createTasks(sbmtVal).subscribe(resp => {
        this.videosListStart = false
        console.log("Tasks for all unassigned posts", resp)
        this.layoutService.showSnackBar({name: `Tasks for all unassigned posts`}, marker("request created!"), SnackBarItem);
        // this.goToCompany();
        this.videosList.patchValue({
          is_ready: 1
        })
        this.selectedIndex = 5
      })
    )
  }

  goToCompany() {
    this.router.navigate(['/tasks'], { queryParams: {company_id: this.company_id, order_by: "updated_desc", count: 1} });
  }

  changeTypeStatus(val) {
    this.typeStatus = val;
    this.vidPagination = undefined
    this.vidPage = 1;
    this.getVideos(this.vidPage);
  }

  test() {
    this.creatingWorkspace.patchValue({
      is_ready: 1
    })
    this.selectedIndex = 1;

    // this.parametersService.addTargetChannelParameter({
    //   company_id: this.data.company.id,
    //   parameter_id: param.id != 0 ? param.id : e.option.value.parameter_id,
    //   parameter_value_id: e.option.value.id,
    //   channel_id: this.data.channel.id
    // })

    // this.parametersService.getApiParameterValues(this.data.company.id, param.id, "20", !!param.page ? param.page : "1", '', null, this.data.target && this.data.target.partner_company_id ? this.data.target.partner_company_id : null)
  }
  

  addAnother() {
    this.newUser = undefined;
    this.form.reset();
    this.form.patchValue({
      company_id: this.company_id,
      currency_id: 0
    })
    this.virtual.reset();
    this.payState = 0;
    this.memberIndex = 0;
    this.earningsVal.patchValue(0);
  }

  // getGroups() {
  //   this.attachSubscriptions(
  //     this.companyService.getInfiniteGroupsCompany(this.company_id, '1', '1').pipe(
  //       switchMap(el => {
  //         let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
  //         let arr = []
  //         for (let index = 1; index <= pages; index++) {
  //           arr.push(index)
  //         }

  //         return forkJoin(arr.map(x => this.companyService.getInfiniteGroupsCompany(this.company_id, x).pipe(map(u => u.body)))).pipe(
  //           last(),
  //         )
  //       }),
  //     ).subscribe(res => {
  //       this.groups = [].concat(...res);
  //     })
  //   )
  // }

  selectEarningsCalc(e: MatRadioChange) {
    this.form.patchValue({
      salary: 0
    })
    this.earningsVal.patchValue(e.value)
  }

  createVirtual() {
    if (!this.virtual.valid) {
      this.layoutService.showSnackBar({name: ''}, marker("Fill in all the fields"), SnackBarItem)
      return
    }
    this.attachSubscriptions(
      this.usersService.createUser({name: this.virtual.value.firstname + " " + this.virtual.value.lastname}, this.activatedRoute.snapshot.queryParamMap.get('company_id')).subscribe(resp => {
        this.form.patchValue({
          vuser_id: resp.id
        })
        this.newUser = resp;
        this.memberIndex = 1
      })
    )
  }

  get firstname() { return this.virtual.get('firstname'); }

  get lastname() { return this.virtual.get('lastname'); }
  
  skipNow() {
    this.form.patchValue({
      salary: 0
    })
    this.memberIndex = this.memberIndex + 1;
    this.payState = 0;
    this.createMember();
  }
  
  setEarning() {
    if (this.earningsVal.value == 0) {
      this.layoutService.showSnackBar({name: ''}, marker("Choose a way to calculate earnings"), SnackBarItem)
    } else if (this.earningsVal.value == 1) {
      this.createMember();
    } else if  (this.earningsVal.value == 2) {
      if (this.form.value.salary > 0) {
        this.payState = this.payState + 1;
      } else {
        this.layoutService.showSnackBar({name: ''}, marker("Fill monthly rate field"), SnackBarItem)
      }
    }
  }

  skipNowRate() {
    this.form.patchValue({
      rate_target: 0
    })
    this.memberIndex = this.memberIndex + 1;
    this.payState = 0;
    
    this.createMember()
  }

  setRate() {
    if (this.form.value.rate_target <= 0) {
      this.layoutService.showSnackBar({name: ''}, marker("Fill monthly points rate field"), SnackBarItem)
      return
    }

    this.createMember();
    
  }



  getCompany() {
    this.attachSubscriptions(
      this.companyService.getCompany(this.company_id).subscribe(resp => {
        this.company = resp[0]
        // this.companyService.company$.next(resp[0]);
      })
    )
  }

  editTaskType(type) {
    const dialogRef = this.dialog.open(EditTaskStatusComponent, {
      data: {
        company: this.company,
        taskTypes: this.taskTypes,
        type: type,
        activeLang: this.activeLang
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        this.getTaskTypes();
      })
    )
  }

  createTaskType() {
    this.dialog.open(CreateTaskStatusComponent, {
      data: {
        company: this.company,
        taskTypes: this.taskTypes
      }
    });
  }

  deleteTaskType(type, i) {
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: {},
        target: marker("task type")
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        this.attachSubscriptions(
          this.taskService.deleteTaskType(type.id).subscribe(resp => {
            this.taskTypes.splice(i,1);
          })
        )
      }
    });
   
  }

  toggleTaskType(e, type) {
    type.disabled = true
    console.log(this.types)
    if (e.checked) {
      this.selectTaskType(type, e);
    } else {
      this.unSelectTaskType(type, e);
    }
  }

  selectTaskType(type, e) {
    this.attachSubscriptions(
      this.taskService.selectTaskStatus({
        task_status_id: type.id,
        company_id: this.company_id
      }).pipe(
        switchMap(x => {
          return this.taskService.getSelectedTaskStatuses(this.company_id, type.id).pipe(
            tap(z => {
              type.taskStatusToCompany = [...z]
            })
          )
        })
      ).subscribe(
        resp => {
          console.log(resp);
          type.disabled = false;
        },
        error => {
          type.disabled = false;
          e.source.toggle();
          this.layoutService.showSnackBar({}, error, SnackBarItem)
        } 
      )
    )
  }

  unSelectTaskType(type, e) {

    this.attachSubscriptions(
      this.taskService.unSelectTaskStatus(type.taskStatusToCompany[0].id).subscribe(
        resp => {
          console.log(resp);
          type.taskStatusToCompany = [];
          type.disabled = false;
        },
        error => {
          type.disabled = false;
          e.source.toggle();
          this.layoutService.showSnackBar({}, error, SnackBarItem)
        } 
      )
    )
  }

  getTaskTypes() {
    this.attachSubscriptions(
      this.taskService.getTaskTypesToCompany(this.company_id, this.activeLang).subscribe(resp => {
        console.log(resp)
        this.taskTypes = resp
      })
    )
  }

  // 
  editType(type) {
    const dialogRef = this.dialog.open(EditWorkStatusComponent, {
      data: {
        company: this.company,
        types: this.types,
        type: type,
        activeLang: this.activeLang
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        this.getTypes();
      })
    )
  }

  createType() {
    this.dialog.open(CreateWorkStatusComponent, {
      data: {
        company: this.company,
        types: this.types
      }
    });
  }

  deleteType(type, i) {
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: {},
        target: marker("job type")
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        this.attachSubscriptions(
          this.taskService.deleteOperation(type.id).subscribe(resp => {
            this.types.splice(i,1);
          })
        )
      }
    });
   
  }

  toggleType(e, type) {
    type.disabled = true;
    if (e.checked) {
      this.selectType(type, e);
    } else {
      this.unSelectType(type, e);
    }
  }

  selectType(type, e) {
    this.attachSubscriptions(
      this.taskService.selectOperation({
        operation_id: type.id,
        company_id: this.company_id
      }).pipe(
        switchMap(x => {
          return this.taskService.getSelectedOperations(this.company_id, type.id).pipe(
            tap(z => {
              type.operationToCompany = [...z]
            })
          )
        })
      ).subscribe(
        resp => {
          console.log(resp);
          type.disabled = false;
        },
        error => {
          type.disabled = false;
          e.source.toggle();
          this.layoutService.showSnackBar({}, error, SnackBarItem)
        } 
      )
    )
  }

  unSelectType(type, e) {

    this.attachSubscriptions(
      this.taskService.unSelectOperation(type.operationToCompany[0].id).subscribe(
        resp => {
          console.log(resp);
          type.operationToCompany = [];
          type.disabled = false;
        },
        error => {
          type.disabled = false;
          e.source.toggle();
          this.layoutService.showSnackBar({}, error, SnackBarItem)
        } 
      )
    )
  }

  getTypes() {
    this.attachSubscriptions(
      this.taskService.getOperationsToCompany(this.company_id, this.activeLang).subscribe(resp => {
        console.log("getTypes", resp)
        this.types = resp
      })
    )
  }
  
  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData().subscribe(resp => {
        this.activeLang = resp.active;
      })
    )
  }

  setCurrency() {
    if (!!this.form.value.currency_id) {
      this.payState = this.payState + 1;
    } else {
      this.layoutService.showSnackBar({name: ''}, marker("Select currency"), SnackBarItem)
    }
  }

  typesNext(stepper: MatStepper) {
    if (this.types.filter(x => x.operationToCompany.length > 0).length > 0 && this.taskTypes.filter(x => x.taskStatusToCompany.length > 0).length > 0) {
      stepper.next()
    } else {
      this.layoutService.showSnackBar({name: ''}, marker("Select task and job types"), SnackBarItem)
    }
  }

  memberPaymentInfo() {
    const dialogRef = this.dialog.open(PaymentInfoComponent, {
      data: {
        company: this.company,
        form: this.form,
        virtual: this.virtual,
        activeLang: this.activeLang,
        currencyList: this.currencyList,
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log("PaymentInfoComponent closed", result);
        if (result.event == "add") {
          this.selectedIndex = 1;
        }
        // this.getTaskTypes();
      })
    )
  }

  getEmployees() {
    this.attachSubscriptions(
      this.taskService.getEmployees(this.company_id).pipe(
        tap(res => this.employees = res),
        switchMap(res => {
          if (res.filter(x => !x.invite).length == 0) {
            return of(res)
          } else {
            return forkJoin(res.filter(x => !x.invite).map(t => this.taskService.editEmployee(t.id, {
              company_id: this.company_id,
              reset_invite: 1,
              employee: {
                user_id: t.user_id
              }
            }))).pipe(
              switchMap(() => this.taskService.getEmployees(this.company_id).pipe(
                tap(res => this.employees = res),
              ))
            )
          }
        })
      ).subscribe(resp => {
        console.log("getEmployees" ,resp)
      })
    )
  }


  copyInvite(emp) {
    this.layoutService.showSnackBar({name: emp.invite}, marker("Copied"), SnackBarItem)
  }

  deleteEmployee(id, i) {
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: {},
        target: marker("employee")
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        this.attachSubscriptions(
          this.taskService.deleteEmployee(id).subscribe(resp => {
            console.log(resp)
            this.employees.splice(i,1);
          })
        )
      }
    });
  }

  removeInvite(id, vuser_id) {
    let form = {
      company_id: this.company_id,
      revoke_invite: 1,
      employee: {
        user_id: vuser_id
      }
    }

    this.attachSubscriptions(
      this.taskService.editEmployee(id, form).subscribe(resp => {
        resp.vuser = this.employees.find(el => el['id'] == id)['vuser'];
        let index = this.employees.indexOf(this.employees.find(el => el['id'] == id));
        this.employees.splice(index, 1, resp)
      })
    )
  }

  generateInvite(id, vuser_id) {
    let form = {
      company_id: this.company_id,
      reset_invite: 1,
      employee: {
        user_id: vuser_id
      }
    }
    this.attachSubscriptions(
      this.taskService.editEmployee(id, form).subscribe(resp => {
        resp.vuser = this.employees.find(el => el['id'] == id)['vuser'];
        let index = this.employees.indexOf(this.employees.find(el => el['id'] == id));
        this.employees.splice(index, 1, resp)
      })
    )
  }

  cancelOnlyMe() {
    this.selectedIndex = 0;
    this.onlyMe = true;
  }

  goBack() {
    history.back();
  }

  createMember() {
    this.attachSubscriptions(
      this.taskService.addVuser(Object.assign(this.form.value, {
        reset_invite: 1,
      })).pipe(
        switchMap((x) => this.taskService.getEmployees(this.company_id, {id: x.id})),
        map(res => res[0]),
        tap(res => this.newUser = res)
        ).subscribe(resp => {
          // this.form.reset();
          // this.form.patchValue({
          //   company_id: this.company_id,
          //   currency_id: 0
          // })
          // this.virtual.reset();
          this.payState = 0;
          this.memberIndex = this.memberIndex + 1;
          // this.earningsVal.patchValue(0);
      })
    
    )
  }

  getCurrency(id) {
    return this.currencyList.filter(x => x.id == id).length > 0 ? this.currencyList.find(x => x.id == id) : false 
  }

  getCurrencyList() {
    this.attachSubscriptions(
      this.globalDataService.getCurrencies().subscribe(resp => {
        console.log("getCurrencyList", resp)
        this.currencyList = resp.slice();
      })
    )
    // this.attachSubscriptions(
    //   this.companyService.getCurrencyList().subscribe(resp => {
    //     console.log("getCurrencyList", resp);
    //     this.currencyList = resp
    //   })
    // )
  }

  tabChange($event?: MatTabChangeEvent): void {
    this.memberIndex = $event.index;
  }



  deleteGroupCompany(id, g) {
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: {},
        target: marker("group")
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        this.attachSubscriptions(
          this.companyService.deleteGroupCompany(id).subscribe(resp => {
            console.log(resp)
            let group = this.groups.find(el => el == g);
            this.groups.splice(this.groups.indexOf(group), 1);
          })
        )
      }
    });

  }

  createGroup() {
    const dialogRef = this.dialog.open(DialogGroupCreateComponent, {
      data: {
        company: this.company,
        activeLang: this.activeLang,
        groups: this.groups
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log("DialogGroupCreateComponent closed", result);
      })
    )
  }

  editGroup(group) {
    const dialogRef = this.dialog.open(DialogGroupEditComponent, {
      data: {
        company: this.company,
        activeLang: this.activeLang,
        groups: this.groups,
        group: group
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log("DialogGroupEditComponent closed", result);
      })
    )
  }

  finish() {
    this.router.navigate(['/main']);
  }

  ngOnDestroy(): void {
    if (this.eltsSub) {
      this.eltsSub.unsubscribe()
    }
    this.clearSubscriptions();
  }

}
