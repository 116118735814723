import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { DeleteAlertComponent } from 'src/app/shared/global_components/delete-alert/delete-alert.component';
import { BaseClass } from 'src/app/shared/models/base-class';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { AuthService } from 'src/app/shared/services/rest/auth.service';
import { UsersService } from 'src/app/shared/services/rest/users.service';
import { environment } from 'src/environments/environment';
import { UserCreateComponent } from './user-create/user-create.component';
import { UserEditComponent } from './user-edit/user-edit.component';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent extends BaseClass implements OnInit, OnDestroy {
  public host: any = environment.host;
  public imgRoute: any = '';
  public user: any = {};
  public user_id: number;
  public users: any;
  
  constructor(
    private sm: StorageManagerService,
    private auth: AuthService,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private bottomSheet: MatBottomSheet,
    private usersService: UsersService
  ) { super() }

  ngOnInit(): void {
    window.scroll(0,0);

    this.getImgRoute();
    this.getCsrf();
    this.getUser();

    this.getUsers();

  }


  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  getCsrf() {
    this.attachSubscriptions(
      this.auth.$userToken.subscribe(resp => {
        this.user_id = resp.user_id;
        this.sm.localStorageSetItem("csrf_param", resp.csrf_param)
        this.sm.localStorageSetItem("csrf_token", resp.csrf_token)
      })
    )
  }

  getUser() {
    this.attachSubscriptions(
      this.auth.$user.subscribe(resp => {
        this.user = resp;
      })
    )
  }

  getUsers() {
    this.attachSubscriptions(
      this.usersService.getUsers(this.activatedRoute.snapshot.queryParamMap.get('company_id')).subscribe(resp => {
        console.log(resp);
        this.users = resp;
      })
    )
  }

  addUser(e) {
    e.preventDefault();
    const dialogRef = this.dialog.open(UserCreateComponent, {
      disableClose: true,
      data: {}
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result && result.event == "update") {
          this.getUsers();
        }
      })
    )
  }

  
  editUser(e, element) {
    e.preventDefault();
    const dialogRef = this.dialog.open(UserEditComponent, {
      disableClose: true,
      data: {
        user: element,
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result && result.event == "update") {
          this.getUsers();
        }
      })
    )
  }

  deleteUser(id, i) {
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: {},
        target: marker("user")
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        this.attachSubscriptions(
          this.usersService.deleteUser(id, this.activatedRoute.snapshot.queryParamMap.get('company_id')).subscribe(resp => {
            console.log(resp)
            this.users.splice(i,1);
          })
        )
      }
    });

  }

  goBack() {
    history.back();
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

}
