export const transcribe = [
    'mp4',
    'avi',
    'mov',
    'wmv',
    'flv',
    'mpg',
    'mpeg',
    'mkv',
    'webm',
    'wav'
]
export function canTranscribe(text) {
    return transcribe.filter(key => text.indexOf(key) != -1).length > 0
}
  