<h2 mat-dialog-title class="dialog_title">
    <span>
        {{ "Task clients" | translate }}
    </span>
    <button mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h2>
<mat-dialog-content class="mat-typography">
    <div class="container card_clients_wrp" [appDeviceClass]="'custom-scroll custom-scroll-t'"       
    infinite-scroll
    [infiniteScrollDisabled]="isLoad"
    [scrollWindow]="false"
    [infiniteScrollUpDistance]="scrollUpDistance"
    [infiniteScrollThrottle]="throttle"
    (scrolled)="onDown($event)">
        <mat-list role="list">
            <mat-list-item *ngFor="let client of clients; let i = index;" role="listitem" class="company_item">
                <div class="company_name">
                    <ng-container *ngIf="client.client_partner_company_id; else elseTemplateIsPartner">
                        <app-user [class]="'user_div'" [companyEmployee]="client?.partnerCompany" [imgRoute]="imgRoute" [host]="host" [company]="data?.company"></app-user>
                    </ng-container>
                    <ng-template #elseTemplateIsPartner>
                        <app-user [class]="'user_div'" [employee]="client?.employee"></app-user>
                    </ng-template>
                </div>
                <div class="client_operation">                    
                    <ng-container [ngSwitch]="client.task_operation_id">
                        <ng-container *ngSwitchCase="0">
                            {{ 'All jobs' | translate }}
                        </ng-container>
                        <ng-container *ngSwitchDefault>
                            {{ 'Work' | translate }} id: {{ client.task_operation_id }}
                        </ng-container>
                    </ng-container>
                </div>
                <div class="checkbox_clients_col">
                    <mat-checkbox [disabled]="client.disabled" class="ml-1 mr-1" color="primary" (change)="togglePartner($event, client)" [checked]="client.is_principal > 0">
                        {{ "Moderation" | translate }}
                    </mat-checkbox>
                </div>
                <div class="company_btns">
                    <!-- <mat-icon (click)="goToEditClient(client)">edit</mat-icon> -->
                    <mat-icon (click)="deleteClient(client, i)">delete</mat-icon>
                </div>
            </mat-list-item>
        </mat-list>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <div class="d-f-c" style="padding: 15px 0;">
        <button mat-raised-button (click)="close()">
            {{ "Close" | translate }}
        </button>
        <button class="d-f-c ml-1" mat-raised-button color='primary' (click)="addClient()">
            <mat-icon class="mr-1">add</mat-icon>
            {{ "Individual as client" | translate }}
        </button>
        <button class="d-f-c ml-1" mat-raised-button color='warn' (click)="addPartnerClient()">
            <mat-icon class="mr-1">add</mat-icon>
            {{ "Workspace as client" | translate }}
        </button>
    </div>
</mat-dialog-actions>
