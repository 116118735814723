import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DateRange } from '@angular/material/datepicker';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseClass } from 'src/app/shared/models/base-class';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MY_FORMATS } from 'src/app/components/atTasksDialog/task-profile-add/task-profile-add.component';
import { Moment } from 'moment';

@Component({
  selector: 'app-mob-date-filter',
  templateUrl: './mob-date-filter.component.html',
  styleUrls: ['./mob-date-filter.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
})

export class MobDateFilterComponent extends BaseClass implements OnInit, OnDestroy {
  public switched: boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dateAdapter: DateAdapter<Moment>,
    public dialogRef: MatDialogRef<MobDateFilterComponent>,
  ) { super() }

  ngOnInit(): void {
    console.log("MobDateFilterComponent", this.data)
    this._dateAdapter.setLocale(this.data.activeLang);

    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        this.close();
      })
    )
  }

  patchControl(control:FormControl, val) {
    this.switched = true;
    control.patchValue(val)
    setTimeout(() => {
      this.switched = false
    },0)
  }

  patchTab(ctrl, val) {
    console.log(ctrl, val);
    this.switched = true;
    ctrl = val
    setTimeout(() => {
      this.switched = false
    },0)
  }

  clearDate(key) {
    this.data.filter.patchValue({
      [key+'from']: '',
      [key+'to']: ''
    })
    this.data.datesModel[this.data.datesControl.value].filters[this.data.datesModel[this.data.datesControl.value].activeFilter.value].range = new DateRange("", "");
  }

  rangeChange(date: Moment, key): void {
    console.log(date);
    console.log(key);
    // console.log(range);

    if (!this.data.filter.value[key+'from']) {
      // this.filter.value[key+'from'] = date;
      this.data.filter.patchValue({
        [key+'from']: date,
      })
    } else if (
      !this.data.filter.value[key+'to'] &&
      this._dateAdapter.compareDate(date.endOf('day'), this.data.filter.value[key+'from']) >= 0
    ) {
      this.data.filter.patchValue({
        [key+'to']: date.endOf('day'),
      })
    } else {
      this.data.filter.patchValue({
        [key+'from']: date,
        [key+'to']: null
      })
    }
    
    this.data.datesModel[this.data.datesControl.value].filters[this.data.datesModel[this.data.datesControl.value].activeFilter.value].range = new DateRange(this.data.filter.value[key+'from'], this.data.filter.value[key+'to']);
    console.log("rangeChange", this.data.filter.value, this.data.datesModel);
  }

  close() {
    this.dialogRef.removePanelClass('animate__slideInUp');
    this.dialogRef.addPanelClass('animate__slideOutDown');
    setTimeout(()=>{this.dialogRef.close({event: 'close'})}, 300);
  }

  onSubmit() {
    this.dialogRef.removePanelClass('animate__slideInUp');
    this.dialogRef.addPanelClass('animate__slideOutDown');
    setTimeout(()=>{this.dialogRef.close({event: 'apply'})}, 300);
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

}
