import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, fromEvent, Subscription } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { casesModel } from 'src/app/shared/functions/casesModel';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { AuthService } from 'src/app/shared/services/rest/auth.service';
import { ChatService } from 'src/app/shared/services/rest/chat.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { BoardFilterComponent } from './dialogs/board-filter/board-filter.component';
import { ChatFilterComponent } from './dialogs/chat-filter/chat-filter.component';
import { TaskFilterComponent } from './dialogs/task-filter/task-filter.component';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-workspace-dashboard',
  templateUrl: './workspace-dashboard.component.html',
  styleUrls: ['./workspace-dashboard.component.scss']
})
export class WorkspaceDashboardComponent extends BaseClass implements OnInit, AfterViewInit, OnDestroy {
  @ViewChildren('search') searchList: QueryList<ElementRef>
  @ViewChild('filterMenu') filterMenu: TemplateRef<any>;
  public company_id: any;
  public company: any;
  public host: any = environment.host;
  public user_id: number;
  public is_mobile: boolean = false;
  public imgRoute: any = '';
  public user: any;
  public activeLang: any;
  public linkTo: any;
  overlayRefFilter: OverlayRef | null;

  public filterTasks: FormGroup = this.fb.group({
    q: '',
    order_by: 'updated_desc',
    content_status_id: '',
    id: '',
    custom_id: "",
    internal_id: "",
    task_company_id: '',
    discussion_id: "",
    task_operation_id: "",
    q_task_name: "",
    q_task_comment: "",
    q_task_private_comment: "",
    q_task_operation_comment: "",
    q_task_operation_employee_comment: "",
    q_task_operation_private_comment: ""
  });

  public filterChats: FormGroup = this.fb.group({
    q: '',
    q_name : '',
    q_text  : '',
  });

  public subFilter: Subscription;
  public hoveredStatus = [
    {
      page: "Cases",
      hover: true
    },
    {
      page: "Chats",
      hover: false
    },
    {
      page: "URLs",
      hover: false
    },
    {
      page: "Jobs",
      hover: false
    },
    {
      page: "Board",
      hover: false
    },
  ]
  
  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private companyService: CompanyService,
    private router: Router,
    private sm: StorageManagerService,
    private auth: AuthService,
    private taskService: TaskService,
    private languageService: LanguageService,
    private chatService: ChatService,
    private activatedRoute: ActivatedRoute,
    public viewContainerRef: ViewContainerRef,
    public overlay: Overlay,
    private cd: ChangeDetectorRef
  ) { super() }

  ngOnInit(): void {
    this.company_id = this.activatedRoute.snapshot.queryParamMap.get('company_id');

    this.checkIsMobile();
    this.getCompany();
    this.getImgRoute();
    this.getCsrf();
    this.getUser();
  }

  ngAfterViewInit(): void {
    this.setFocus(0)
  }

  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData().subscribe(resp => {
          console.log("getOperations", resp)
          this.activeLang = resp.active;
      })
    )
  }

  setFocus(i) {
    setTimeout(() => {
      this.searchList.toArray()[i].nativeElement.focus()
      this.searchList.forEach(item => item.nativeElement.value = '')
      this.cd.detectChanges();
    },0)
  }

  getCompany() {
    this.attachSubscriptions(
      this.companyService.getCompany(this.company_id).subscribe(resp => {
        this.companyService.company$.next(resp[0]);
        this.company = resp[0];
      })
    )
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  getCsrf() {
    this.attachSubscriptions(
      this.auth.$userToken.subscribe(resp => {
        this.user_id = resp.user_id;
        this.sm.localStorageSetItem("csrf_param", resp.csrf_param)
        this.sm.localStorageSetItem("csrf_token", resp.csrf_token)
      })
    )
  }

  getUser() {
    this.attachSubscriptions(
      this.auth.$user.subscribe(resp => {
        this.user = resp;
      })
    )
  }

  checkIsMobile() {
    if (window.innerWidth <= 769) {
      this.is_mobile = true;
    } else {
      this.is_mobile = false;
    }
    this.onResize();
  }

  onResize() {
    this.attachSubscriptions(
      fromEvent(window, "resize").pipe(
        map(() => window.innerWidth)
      ).subscribe((wWidth) => {
          if (wWidth <= 769) {
            this.is_mobile = true;
          } else {
            this.is_mobile = false;
          }
        }
      )
    )
  }

  resetSearchTasksParams() {
    this.filterTasks.patchValue({
    id: '',
    custom_id: "",
    internal_id: "",
    task_company_id: '',
    discussion_id: "",
    task_operation_id: "",
    q_task_name: "",
    q_task_comment: "",
    q_task_private_comment: "",
    q_task_operation_comment: "",
    q_task_operation_employee_comment: "",
    q_task_operation_private_comment: ""
    })
  }

  searchInPage(i) {
    let q = this.searchList.toArray()[i].nativeElement.value
    let url;
    switch (i) {
      case 0:
        url = ['/tasks']
        this.onFilterTasks()
        return
        break;
      case 1:
        url = ['/chats']
        this.onFilterChats()
        return
        break;
      case 2:
        url = ['/urls']
        break;
      case 3:
        url = ['/jobs']
        break;
      case 4:
        url = ['/board']
        break;
    
      default:
        url = ['/tasks']
        break;
    }

    if (i > 1) {
      this.router.navigate(url, { queryParams: {company_id: this.company_id, order_by: "updated_desc", count: 1, q: q} });
    }
  }

  openTaskFilter() {
    const dialogRef = this.dialog.open(TaskFilterComponent, {
      panelClass: ['filter_dialog'],
      data: {
        user: this.user,
        company: this.company,
        is_mobile: this.is_mobile,
        imgRoute: this.imgRoute
      }
    });
  }

  openBoardFilter() {
    const dialogRef = this.dialog.open(BoardFilterComponent, {
      panelClass: ['filter_dialog'],
      data: {
        user: this.user,
        company: this.company,
        is_mobile: this.is_mobile,
        imgRoute: this.imgRoute
      }
    });
  }

  openChatFilter() {
    const dialogRef = this.dialog.open(ChatFilterComponent, {
      panelClass: ['filter_dialog'],
      data: {
        user: this.user,
        company: this.company,
        is_mobile: this.is_mobile,
        imgRoute: this.imgRoute
      }
    });
  }

  goToCompany(e, company_id) {
    e.preventDefault();
    this.router.navigate(['/tasks'], { queryParams: {company_id: company_id, order_by: "updated_desc", count: 1} });
  }
  goToJobs(e, company_id) {
    e.preventDefault();
    this.router.navigate(['/jobs'], { queryParams: {company_id: company_id, order_by: "updated_desc", count: 1} });
  }
  goToUrls(e, company_id) {
    e.preventDefault();
    this.router.navigate(['/urls'], { queryParams: {company_id: company_id, order_by: "content_published_at_desc", count: 1} });
  }
  goToChats(e, company_id) {
    e.preventDefault();
    this.router.navigate(['/chats'], { queryParams: {company_id: company_id, order_by: "updated_desc", count: 1} });
  }
  goToBoard(e, company_id) {
    e.preventDefault();
    this.router.navigate(['/board'], { queryParams: {company_id: company_id, order_by: "updated_desc", count: 1} });
  }

  onMouseEnter(e, i) {
    this.hoveredStatus.map(x => x.hover = false);
    this.hoveredStatus[i].hover = true;
    this.setFocus(i);
  }

  onFilterTasks() {
    this.closeOpenFilter();
    let filterData = {...this.filterTasks.value};
    let params = {
      company_id: this.company_id
    };

    Object.keys(filterData).forEach(element => {
      if (filterData[element] != '') {
        params[element] = filterData[element]
      }
    });
    console.log("PARAMS", params)

    this.router.navigate(['/tasks'], { queryParams: params });
  }

  onFilterChats() {
    this.closeOpenFilter();
    let filterData = {...this.filterChats.value};
    let params = {
      company_id: this.company_id
    };

    Object.keys(filterData).forEach(element => {
      if (filterData[element] != '') {
        params[element] = filterData[element]
      }
    });
    console.log("PARAMS", params)

    this.router.navigate(['/chats'], { queryParams: params});
  }

  closeOpenFilter() {
    this.subFilter && this.subFilter.unsubscribe();
    if (this.overlayRefFilter) {
      this.overlayRefFilter.dispose();
      this.overlayRefFilter = null;
    }
  }

  openFilter(el, val) {
    this.closeOpenFilter();
    let x = el.offsetLeft;
    let y =  el.offsetTop + el.offsetHeight;
    const positionStrategy = this.overlay.position()
    .flexibleConnectedTo({ x, y })
    .withPositions([
      {
        originX: 'start',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'top',
      }
    ]);

    this.overlayRefFilter = this.overlay.create({
      backdropClass: 'transparent_bg',
      hasBackdrop: true,
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });

    this.overlayRefFilter.attach(new TemplatePortal(this.filterMenu, this.viewContainerRef, {
      $implicit: val
    }));
    
    this.subFilter = fromEvent<MouseEvent>(document, 'click')
      .pipe(
        filter(event => {
          const clickTarget = event.target as HTMLElement;
          return !!this.overlayRefFilter && !this.overlayRefFilter.overlayElement.contains(clickTarget);
        }),
        take(1)
      ).subscribe(() => this.closeOpenFilter())

  }


  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
