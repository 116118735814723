<div class="reports_dash">
    <form class="reports_dash_header">
        <div class="head_tit">
            {{ "Company structure: Departments and positions" | translate }}
        </div>
    </form>

    <div class="reports_wrp" [appDeviceClass]="'custom-scroll custom-scroll-t'" >
        <div class="report" *ngFor="let item of departments; let i = index">
            <div class="report_line">
                <div class="column report_col">
                    <ng-container *ngIf="item.isEddited; else elseTemplateEddited">
                        <input class="name_input" (focusout)="saveValue(item)" type="text" id="dep_{{item.id}}" [(ngModel)]="item.name" (change)="editDepartment(item, $event)">
                        <mat-icon class="cp ml-1" style="color: #219653;" (click)="saveValue(item)">done</mat-icon>
                    </ng-container>
                    <ng-template #elseTemplateEddited>
                        <span class="big" (dblclick)="focusDep(item)">
                            {{ item?.name }}
                        </span>
  
                        <button class="show_on_hover" style="color: #A3A3A3;" mat-icon-button [matMenuTriggerFor]="menuBig" aria-label="Example icon-button with a menu">
                            <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #menuBig="matMenu">
                            <button mat-menu-item (click)="foldersPermissions(item)">
                                <mat-icon>folder_shared</mat-icon>
                                <span>{{"Folders permission"| translate}}</span>
                            </button>
                            <button mat-menu-item (click)="focusDep(item)">
                                <mat-icon>edit</mat-icon>
                                <span>{{"Edit"| translate}}</span>
                            </button>
                            <button mat-menu-item (click)="deleteDepartment(item, i)">
                                <mat-icon>delete</mat-icon>
                                <span>{{"Delete"| translate}}</span>
                            </button>
                        </mat-menu>
                    </ng-template>
                </div>
                <div class="column report_col">
                    <span class="normal" *ngIf="item?.employee_count > 0">
                        {{item?.employee_count}} {{'executors' | translate}}
                    </span>
                </div>
                <div class="column report_col">
         
                </div>
                <div class="column report_col expand">
                    <button mat-icon-button style="color: #686868;" (click)="expandItem(item)">
                        <mat-icon>
                            {{ item.isOpen ? 'expand_less' : 'expand_more' }}
                        </mat-icon>
                    </button>
                </div>
            </div>
            <div class="param_values report_sub_lines" *ngIf="item.isOpen">
                <div class="reports_add_line">
                    <div class="add_line" (click)="addPosition(item)">
                        <mat-icon>add_circle</mat-icon>
                        <span class="ml-1">{{"Add position" | translate}}</span>
                    </div>
                </div>
                <div class="param_value report_sub_line" *ngFor="let val of item.values; let k = index;">
                    <div class="sub_line">
                        <div class="column report_col">
                            <ng-container *ngIf="val.isEddited; else elseTemplateEddited">
                                <input class="value_input" (focusout)="saveValue(val)" type="text" id="pos_{{item.id}}_{{val.id}}" [(ngModel)]="val.name" (change)="editPosition(item, val, $event)">
                                <mat-icon class="cp ml-1" style="color: #219653;" (click)="saveValue(val)">done</mat-icon>
                            </ng-container>
                            <ng-template #elseTemplateEddited>
                                <span class="normal" (dblclick)="focusPos(item, val)">
                                    {{val.name}}
                                </span>
                                <button class="show_on_hover" style="color: #A3A3A3;" mat-icon-button [matMenuTriggerFor]="menuBig" aria-label="Example icon-button with a menu">
                                    <mat-icon>more_vert</mat-icon>
                                </button>
                                <mat-menu #menuBig="matMenu">
                                    <button mat-menu-item (click)="foldersPermissions(false, val)">
                                        <mat-icon>folder_shared</mat-icon>
                                        <span>{{"Folders permission" | translate}}</span>
                                    </button>
                                    <button mat-menu-item (click)="focusPos(item, val)">
                                        <mat-icon>edit</mat-icon>
                                        <span>{{"Edit"| translate}}</span>
                                    </button>
                                    <button mat-menu-item (click)="deletePosition(item, val, k)">
                                        <mat-icon>delete</mat-icon>
                                        <span>{{"Delete"| translate}}</span>
                                    </button>
                                </mat-menu>
                            </ng-template>
                        </div>
                        <div class="column report_col">
                            <span class="small" *ngIf="val?.employee_count > 0">
                                {{val?.employee_count}} {{'executors' | translate}}
                            </span>
                        </div>
                        <div class="column report_col">
                  
                        </div>
                        <div class="column report_col expand">
                            <button *ngIf="val?.employee_count" mat-icon-button style="color: #686868;" (click)="expandSubItem(item, val)">
                                <mat-icon>
                                    {{ val.isOpen ? 'expand_less' : 'expand_more' }}
                                </mat-icon>
                            </button>
                        </div>
                    </div>
                    <div class="executors" *ngIf="val.isOpen">
                        <div class="d-f-c" *ngFor="let exec of val.executors">
                            <app-user [class]="'user_div'" [employee]="exec.employee"></app-user>
                            
                            <mat-icon (click)="editPosToEm(exec, 'is_department_view')" class="cp data_icon ml-1" [ngClass]="{'active': exec?.is_department_view}" #tooltip="matTooltip"
                            matTooltip="{{ exec?.is_department_view ? ('Can see everyone in the department.' | translate) : ('Can`t see everyone in the department.' | translate)}}"
                            [matTooltipPosition]="'below'">groups</mat-icon>
                            
                            <mat-icon (click)="editPosToEm(exec, 'is_department_position_view')" class="data_icon cp ml-1" [ngClass]="{'active': exec?.is_department_position_view}" #tooltip="matTooltip" 
                            matTooltip="{{ exec?.is_department_position_view ? ('Can see everyone with the same position.' | translate) : ('Can`t see everyone with the same position.' | translate)}}"
                            [matTooltipPosition]="'below'">group</mat-icon>

                            <mat-icon (click)="editPosToEm(exec, 'is_department_manage_prices')" class="data_icon ml-1 cp" [ngClass]="{'active': exec?.is_department_manage_prices}" #tooltip="matTooltip" 
                            matTooltip="{{ exec?.is_department_manage_prices ? ('Can change department prices' | translate) : ('Can`t change department prices' | translate)}}"
                            [matTooltipPosition]="'below'">{{!!exec?.is_department_manage_prices ? 'credit_score' : 'payment'}}</mat-icon>
                            
                            <mat-icon (click)="editPosToEm(exec, 'is_department_position_manage_prices')" class="data_icon ml-1 cp" [ngClass]="{'active': exec?.is_department_position_manage_prices}" #tooltip="matTooltip" 
                            matTooltip="{{ exec?.is_department_position_manage_prices ? ('Can change position prices' | translate) : ('Can`t change position prices' | translate)}}"
                            [matTooltipPosition]="'below'">{{!!exec?.is_department_position_manage_prices ? 'attach_money' : 'money_off'}}</mat-icon>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <!-- <div class="reports_dash_footer">
        <div class="column report_col">
            <span class="big">
                {{ "Total" | translate }}
            </span>
        </div>
        <div class="column day_view_col">
            <span class="normal">
                {{ totalValues.dayTotal | number:'':'fr-FR' }}
            </span>
        </div>
    </div> -->
</div>

<div style="padding: 10px 0;">
    <button class="add_btn" mat-raised-button color="primary" (click)="addDepartment()">
        <mat-icon>add</mat-icon>
        {{ "Department" | translate }}
    </button>
</div>