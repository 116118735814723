import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { map } from 'rxjs/operators';
import { DeleteAlertComponent } from 'src/app/shared/global_components/delete-alert/delete-alert.component';
import { BaseClass } from 'src/app/shared/models/base-class';
import { MembersService } from 'src/app/shared/services/rest/members.service';
import { ScenariosService } from 'src/app/shared/services/rest/scenarios.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { AddJobAutomationComponent } from '../add-job-automation/add-job-automation.component';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';

@Component({
  selector: 'app-job-automation',
  templateUrl: './job-automation.component.html',
  styleUrls: ['./job-automation.component.scss']
})
export class JobAutomationComponent extends BaseClass implements OnInit, OnDestroy {
  public automation: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<JobAutomationComponent>,
    private dialog: MatDialog,
    private bottomSheet: MatBottomSheet,
    private layoutService: LayoutService,
    private scenariosService: ScenariosService,
  ) { super() }

  ngOnInit(): void {
    console.log(this.data);
    this.getJobScenarios();
  }

  tryScenario(auto) {
    this.attachSubscriptions(
      this.scenariosService.tryScenario(auto.id).subscribe(resp => {
        this.layoutService.showSnackBar({name: 'Scenario execution started.'}, '', SnackBarItem)
      }, error => {
        this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
      })
    )
  }
  getJobScenarios() {
    let filter:any = {task_operation_id: this.data.work.id, 'per-page': 200};
    if (this.data.scenario_name) {
      filter.scenario_name = this.data.scenario_name
    }
    this.attachSubscriptions(
      this.scenariosService.getAutomationScenarios(this.data.company.id, filter).pipe(
        map(el => el.body)
      ).subscribe(resp => {
        console.log('getJobScenarios', resp)
        this.automation = resp
      })
    )
  }

  editAutomation() {
    let filter:any = {task_operation_id: this.data.work.id, 'per-page': 200};
    if (this.data.scenario_name) {
      filter.scenario_name = this.data.scenario_name
    }
    this.attachSubscriptions(
      this.scenariosService.getAutomationScenarios(this.data.company.id, filter).pipe(
        map(el => el.body)
      ).subscribe(resp => {
        this.automation = resp
      })
    )
  }

  addAutomation() {
    const dialogRef = this.dialog.open(AddJobAutomationComponent, {
      data: {
        company: this.data.company,
        work: this.data.work,
        user: this.data.user,
        host: this.data.host,
        imgRoute: this.data.imgRoute,
        scenario_name:  this.data.scenario_name
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        this.getJobScenarios();
      })
    )
  }
  

  deleteAutomation(auto, ind) {
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: {},
        target: marker("scenario templates")
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        this.attachSubscriptions(
          this.scenariosService.deleteAutomationScenario(auto.id).subscribe(resp => {
            this.automation.splice(ind, 1)
          })
        )
      }
    });
  }

  // 
  // getAdditionalEmpl() {
  //   this.attachSubscriptions(
  //     this.membersService.getMembers({task_id: this.data.task.id, task_operation_id: this.data.work.id, is_manager:0}, this.data.company.id).subscribe(resp => {
  //       console.log("getAdditionalEmpl", resp);
  //       this.additionalEmpls = resp;
  //       if (resp.length == 0) {
  //         this.addAdditionalEmplToWork()
  //       }
  //     })
  //   )
  // }

  checkIsManager(task, company, _user) { 
    return task?.managers.filter(x => x.user_id == _user.id).length > 0 || task?.group?.managers.filter(x => x.user_id == _user.id).length > 0 || company?.permissions?.includes('admin') || company?.permissions.includes('manager'); 
  }
  
  // addAdditionalEmplToWork() {
  //   const dialogRef = this.dialog.open(AddAdditionalEmployeesComponent, {
  //     disableClose: true,
  //     data: {
  //       user: this.data.user,
  //       company: this.data.company,
  //       task: this.data.task,
  //       work: this.data.work,
  //       discussion: this.data.discussion
  //     }
  //   });

  //   this.attachSubscriptions(
  //     dialogRef.afterClosed().subscribe(result => {
  //       if (!!result && result.event == "add") {
  //         this.is_change = true
  //         this.getAdditionalEmpl();
  //       }
  //     })
  //   )
  // }

  // goToEditAddEmpl(addEmpl) {
  //   const dialogRef = this.dialog.open(EditAdditionalEmployeesComponent, {
  //     disableClose: true,
  //     data: {
  //       user: this.data.user,
  //       company: this.data.company,
  //       task: this.data.task,
  //       work: this.data.work,
  //       discussion: this.data.discussion,
  //       addEmpl: addEmpl
  //     }
  //   });

  //   this.attachSubscriptions(
  //     dialogRef.afterClosed().subscribe(result => {
  //       if (!!result && result.event == "update") {
  //         this.getAdditionalEmpl();
  //       }
  //     })
  //   )
  // }

  // deleteAddEmpl(id, i) {
  //   let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      // hasBackdrop: true,
      // backdropClass: 'bottom-sheed-backdrop',
  //     data: {
  //       targetVal: {},
  //       target: marker("additional employee")
  //     }
  //   });

  //   deleteAlert.afterDismissed().subscribe( data => {
  //     if (data && data.message == 'no') {
  //       console.log("no");
  //       return
  //     } else if (data && data.message == 'yes') {
  //       this.attachSubscriptions(
  //         this.membersService.deleteMember(id, this.data.company.id).subscribe(resp => {
  //           this.is_change = true;
  //           this.additionalEmpls.splice(i,1);
  //         })
  //       )
  //     }
  //   });
  // }

  // getOperationStatusName(id) {
  //   switch (+id) {
  //     case 1:
  //       return "Waiting";
  //       break;
  //     case 2:
  //       return "In process";
  //       break;
  //     case 3:
  //       return "To approve";
  //       break;
  //     case 4:
  //       return "Approved";
  //       break;
  //     case 5:
  //       return "To correct";
  //       break;
  //     case 6:
  //       return "Sources Needed";
  //       break;
  //     case 98:
  //       return "Ready";
  //       break;
  //     case 99:
  //       return "Canceled";
  //       break;
  //   }
  // }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  ngOnDestroy(): void {
    this.clearSubscriptions();
  }
}
