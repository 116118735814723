import { Component, Inject, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ReplaySubject, Subscription, concat, forkJoin, from, fromEvent, of, scheduled } from 'rxjs';
import { catchError, concatMap, debounceTime, distinctUntilChanged, filter, finalize, last, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { BaseClass } from 'src/app/shared/models/base-class';
import { FileService } from 'src/app/shared/services/rest/file.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { LoadingService } from 'src/app/shared/services/rest/loading.service';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { MatBottomSheet } from '@angular/material/bottom-sheet';

import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as moment from 'moment';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { GlobalDataService } from 'src/app/shared/services/common/global-data.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { RefreshService } from 'src/app/shared/services/rest/refresh.service';
import { DeleteAlertComponent } from 'src/app/shared/global_components/delete-alert/delete-alert.component';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { SelectionModel } from '@angular/cdk/collections';
import { BackJobToPrevStatusComponent } from 'src/app/shared/global_components/back-job-to-prev-status/back-job-to-prev-status.component';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { ParametersService } from 'src/app/shared/services/rest/parameters.service';
import { CompilationsWizardComponent } from 'src/app/shared/global_components/compilations-wizard/compilations-wizard.component';
import { OpenTaskComponent } from '../open-task/open-task.component';
import { Router } from '@angular/router';
export const MY_FORMATS = {
  parse: {
    dateInput: 'LLLLL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
export const MY_NEW_FORMATS = {
  parse: {
    dateInput: 'MM/DD/YYYY',
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'MM/DD/YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-compilation-assistant',
  templateUrl: './compilation-assistant.component.html',
  styleUrls: ['./compilation-assistant.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_NEW_FORMATS},
  ]
})
export class CompilationAssistantComponent extends BaseClass implements OnInit, OnDestroy {
  public progress:number = 0;

  public totalTasksCount: number = 0;
  public page: number = 1;
  public hasMore: boolean = true;
  public pagination: any;

  public isLoad: boolean = false;
  public assistantTasksSub: Subscription;
  public assistantTaskDataSub: Subscription;

  public today = moment().set({hour:0,minute:0,second:0}).unix();
  public todayEnd = moment().endOf('day').unix();

  public tasks: any = [];

  public filters: any = [
    {
      task_operation_operation_id: '',
      group_id: '',
      operation_status_id: '',
      task_operation_parameter_value_id: ''
    }
  ];

  // public filters: any = [
  //   {
  //     "task_operation_operation_id": 12,
  //     "group_id": 3,
  //     "operation_status_id": 1,
  //     "task_operation_parameter_value_id": 29
  //   },
  //   {
  //     "task_operation_operation_id": 25,
  //     "group_id": 50,
  //     "operation_status_id": 1,
  //     "task_operation_parameter_value_id": 'ignore'
  //   },
  //   {
  //     "task_operation_operation_id": 9,
  //     "group_id": 50,
  //     "operation_status_id": 1,
  //     "task_operation_parameter_value_id": 'no'
  //   }
  // ]

  public stage = 'Waiting your filtered cards'

  public operationStatuses: any;

  public jobTypes: any;
  public jobTypesControl: FormControl = new FormControl();
  public jobTypes$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public groups: any;
  public groupMoreControl: FormControl = new FormControl();
  public groups$: ReplaySubject<any> = new ReplaySubject<any>(1);


  public allValues: any;
  public allValuesControl: FormControl = new FormControl();
  public allValues$: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private taskService: TaskService,
    private router: Router,
    private globalDataService: GlobalDataService,
    private layoutService: LayoutService,
    private companyService: CompanyService,
    public viewContainerRef: ViewContainerRef,
    public overlay: Overlay,
    public fb: FormBuilder,
    private bottomSheet: MatBottomSheet,
    private _adapter: DateAdapter<any>,
    private parametersService: ParametersService,
    private sm: StorageManagerService,
    private fileService: FileService,
    public dialogRef: MatDialogRef<CompilationAssistantComponent>,
    private dialog: MatDialog,
  ) { super() }

  ngOnInit(): void {
    console.log("CompilationAssistantComponent this.data", this.data)
    this._adapter.setLocale(this.data.activeLang);

    this.getJobTypes();
    this.getOperationsStatus();
    this.getGroupsCompany();
    this.getAllApiParameterValues();

    this.attachSubscriptions(
      this.jobTypesControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchJobTypes(resp))
    )

    this.attachSubscriptions(
      this.groupMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchGroups(resp))
    )

    this.attachSubscriptions(
      this.allValuesControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchValues(resp))
    )
  }

  compilations() {
    const dialogRef = this.dialog.open(CompilationsWizardComponent, {
      backdropClass: 'backdrop_under_header',
      panelClass: !this.data.is_mobile ? ['open_task_dialog', 'wizard_dialog', 'show_header'] : ['open_task_dialog', 'wizard_dialog'],
      autoFocus: false,
      data: {
        company_id: this.data.company_id,
        company: this.data.company,
        tasks: this.tasks,
        is_mobile: this.data.is_mobile,
        imgRoute: this.data.imgRoute,
        user: this.data.user,
        activeLang: this.data.activeLang,
        from_assistant: true
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log("compilations closed", result);
      })
    )
  }

  goToCards(e) {
    e.preventDefault();
    this.router.navigate([`/tasks`], { queryParams: {company_id: this.data.company_id, count: 1, id: this.getTasksIds()}});
    this.close();
  }

  getTasksIds() {
    return this.tasks.map(x => x.id).join(',')
  }

  neededData(card) {
    console.log('neededData card', card);
    let arr = [];
  
    this.filters.filter(k => k.group_id == card.group_id).forEach(fil => {
      card.operations.filter(job => job.operation_id == fil.task_operation_operation_id 
        && job.status_id == fil.operation_status_id 
        && fil.task_operation_parameter_value_id != 'ignore'
      ).forEach(t => {
        arr.push(t);
      })
    });
  
    console.log('neededData arr', arr);
  
    if (arr && arr.length) {
      return from(arr).pipe(
        mergeMap(x => this.parametersService.getTargetParameters(this.data.company.id, {
            task_id: card.id,
            task_operation_id: x.id,
            discussion_id: 0,
            file_id: 0
          }).pipe(
            catchError(error => {
              return of([]);
            }),
            tap(values => {
              x.tags = values; 
            })
          ),
          20
        ),
        finalize(() => {
          console.log("finalize")
          card.is_ok_tags = true;
          this.filters.filter(k => k.group_id == card.group_id && k.task_operation_parameter_value_id != 'ignore').forEach(fil => {
            if (fil.task_operation_parameter_value_id == 'no') {
              if (card.operations.find(job => job.tags && job.tags.length == 0 && !job.is_checked_tags)) {
                card.operations.find(job => job.tags && job.tags.length == 0 && !job.is_checked_tags).is_checked_tags = true;
              } else {
                card.is_ok_tags = false;
              }
            } else {
              if (card.operations.find(job => job.tags && !!job.tags.filter(tag => tag.parameter_value_id == fil.task_operation_parameter_value_id).length && !job.is_checked_tags)) {
                card.operations.find(job => job.tags && !!job.tags.filter(tag => tag.parameter_value_id == fil.task_operation_parameter_value_id).length && !job.is_checked_tags).is_checked_tags = true;
              } else {
                card.is_ok_tags = false;
              }
            }
          });
  
          if (!card.is_ok_tags) {
            console.log('splice');
            this.totalTasksCount--;
            this.tasks.splice(this.tasks.findIndex(l => l.id == card.id), 1);
            console.log('spliced');
          } else {
            card.neededEnd = true;
            if (this.tasks.filter(t => !!t.neededEnd).length == this.tasks.length) {
              this.stage = 'To Compilation wizard';
            }
          }
        })
      );
    } else {
      card.neededEnd = true;
      if (this.tasks.filter(t => !!t.neededEnd).length == this.tasks.length) {
        this.stage = 'To Compilation wizard';
      }
      return of(card); // Return the card if no valid operations
    }
  }

  neededDataOld(card) {
    console.log('neededData card', card);
    let arr = [];

    this.filters.filter(k => k.group_id == card.group_id).forEach(fil => {
      card.operations.filter(job => job.operation_id == fil.task_operation_operation_id && job.status_id == fil.operation_status_id && fil.task_operation_parameter_value_id != 'ignore').forEach(t => {
        arr.push(t);
      })
    });

    console.log('neededData arr', arr);

    if (arr && arr.length) {
      return concat(arr.map(x => this.parametersService.getTargetParameters(this.data.company.id, {
          task_id: card.id,
          task_operation_id: x.id,
          discussion_id: 0,
          file_id: 0
        }).pipe(
          catchError(error => {
            return of([])
          }),
          tap(values => {
            x.tags = values;
          })
        ))
      ).pipe(
        tap(() => {
          card.is_ok_tags = true;
          this.filters.filter(k => k.group_id == card.group_id).forEach(fil => {
            if (fil.task_operation_parameter_value_id == 'no') {
              if (card.operations.find(job => job.tags && job.tags.length == 0 && !job.is_checked_tags)) {
                card.operations.find(job => job.tags && job.tags.length == 0 && !job.is_checked_tags).is_checked_tags = true;
              } else {
                card.is_ok_tags = false;
              }
            } else {
              if (card.operations.find(job => job.tags && !!job.tags.filter(tag => tag.parameter_value_id == fil.task_operation_parameter_value_id).length && !job.is_checked_tags)) {
                card.operations.find(job => job.tags && !!job.tags.filter(tag => tag.parameter_value_id == fil.task_operation_parameter_value_id).length && !job.is_checked_tags).is_checked_tags = true;
              } else {
                card.is_ok_tags = false;
              }
            }
          });
  
          if (!card.is_ok_tags) {
            console.log('splice');
            this.totalTasksCount--;
            this.tasks.splice(this.tasks.findIndex(l => l.id == card.id), 1);
            console.log('spliced');
          } else {
            card.neededEnd = true;
            if (this.tasks.filter(t => !!t.neededEnd).length == this.tasks.length) {
              this.stage = 'To Compilation wizard'
            }
          }
  
        })
      )
    } else {
      card.neededEnd = true;
      if (this.tasks.filter(t => !!t.neededEnd).length == this.tasks.length) {
        this.stage = 'To Compilation wizard'
      }
      return of(card)
    }

  }

  checkNeededEndCount() {
    return this.tasks.filter(k => !!k.neededEnd).length
  }

  duplicate(fil) {
    this.filters.push({...fil})
  }

  addFilter() {
    this.filters.push({
      task_operation_operation_id: '',
      group_id: '',
      operation_status_id: '',
      task_operation_parameter_value_id: ''
    })
  }

  delFilter(i) {
    this.filters.splice(i, 1)
  }

  getAllApiParameterValues() {
    this.attachSubscriptions(
      this.parametersService.getAllValues('1', this.data.company_id, null, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.parametersService.getAllValues(x, this.data.company_id).pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        this.allValues = [].concat(...res)
        this.allValues$.next(this.allValues.slice())
      })
    )
  }

  onSearchValues(resp) {
    if (!this.allValues) {
      return;
    }

    if (!resp) {
      this.allValues$.next(this.allValues.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.allValues$.next(
      this.allValues.filter(z => z.value.toLowerCase().indexOf(resp) > -1 || (z.parameter.name && z.parameter.name.toLowerCase().indexOf(resp) > -1))
    );
  }
  
  getValuesById(id) {
    return this.allValues && this.allValues.find(x => x.id == id)
  }

  onSearchGroups(resp) {
    if (!this.groups) {
      return;
    }

    if (!resp) {
      this.groups$.next(this.groups.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.groups$.next(
      this.groups.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  getGroupsCompany() {
    this.attachSubscriptions(
      this.companyService.getInfiniteGroupsWithFilterCompanyWithoutExp(this.data.company_id, '1', '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.companyService.getInfiniteGroupsWithFilterCompanyWithoutExp(this.data.company_id, x).pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        this.groups = [].concat(...res);
        this.groups$.next(this.groups.slice());
      })
    )
  }

  // Функция для сравнения объектов
  private areObjectsEqual(obj1: any, obj2: any): boolean {
    return obj1.task_operation_operation_id === obj2.task_operation_operation_id &&
          obj1.group_id === obj2.group_id &&
          obj1.operation_status_id === obj2.operation_status_id &&
          obj1.task_operation_parameter_value_id === obj2.task_operation_parameter_value_id;
  }

  // Функция для нахождения дубликатов и их индексов
  public findDuplicateIndices(): number[][] {
    const duplicates: number[][] = [];

    for (let i = 0; i < this.filters.length; i++) {
      for (let j = i + 1; j < this.filters.length; j++) {
        if (this.areObjectsEqual(this.filters[i], this.filters[j])) {
          duplicates.push([i, j]);
        }
      }
    }

    return duplicates;
  }

  openTask(task) {
    const dialogRef = this.dialog.open(OpenTaskComponent, {
      backdropClass: 'backdrop_under_header',
      panelClass: !this.data.is_mobile ? ['open_task_dialog', 'show_header'] : 'open_task_dialog',
      autoFocus: false,
      data: {
        task_id: task.id,
        initCompanyId: this.data.company.id
      }
    });
  }

  getOperationsStatus() {
    this.attachSubscriptions(
      this.taskService.getOperationsStatus().subscribe(resp => {
        this.operationStatuses = resp.slice();
      })
    )
  }
  
  onSearchJobTypes(resp) {
    if (!this.jobTypes) {
      return;
    }

    if (!resp) {
      this.jobTypes$.next(this.jobTypes.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }

    // filter the banks
    this.jobTypes$.next(
      this.jobTypes.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  getJobTypes() {
    this.attachSubscriptions(
      this.taskService.getOperations(this.data.company_id, this.data.activeLang).pipe(
        tap(x => {
          this.jobTypes = x;
          this.jobTypes$.next(this.jobTypes.slice());
        })
      ).subscribe(resp => {
        console.log("getOperations", resp)
      })
    )
  }

  filterCards() {
    console.log('filterCards', this.tasks)
    
    let errorLine = -1;
    this.filters.forEach((fil, i) => {
      if (Object.values(fil).filter(p => !!p).length != 4) {
        errorLine = i + 1
      }
    });

    if (errorLine != -1) {
      let string = `The filter line ${errorLine} have empty value:`
      this.layoutService.showSnackBar({name: string}, marker("Fill all line fields."), SnackBarItem)
      return
    }

    const duplicateIndices = this.findDuplicateIndices();
    console.log(duplicateIndices);
    if (duplicateIndices.length > 0) {
      let string = `The filter lines are equal to each other:`
      duplicateIndices.forEach((k, i) => {
        string += (i != 0 ? ` and` : ``) + `${[...k].map(x => x+1).join(',')}` 
      })
      string += '.'
      this.layoutService.showSnackBar({name: string}, marker("Fix duplicate filters."), SnackBarItem)
      return
    }
    this.tasks = [];
    this.page = 1;
    if (this.assistantTasksSub) {
      this.assistantTasksSub.unsubscribe();
    }
    if (this.assistantTaskDataSub) {
      this.assistantTaskDataSub.unsubscribe();
    }
    this.assistantTaskDataSub = this.taskService.getAssistantTaskDataStream().pipe(
      concatMap(tasksInPage => {
        return concat(...tasksInPage.map(taskInPage => this.neededData(taskInPage))).pipe(last(),map(x => tasksInPage))
      }),
    ).subscribe(resp => console.log("-----getAssistantTaskDataStream-----",resp));

    this.getCards(this.page);
  }


  getCards(n) {
    console.log("FILTERs", this.filters)
    this.stage = 'Getting cards';
    this.isLoad = true;
    let filterData: any = {
      task_operation_operation_id: Array.from(new Set(this.filters.map(x => x.task_operation_operation_id))),
      group_id: Array.from(new Set(this.filters.map(x => x.group_id))),
      operation_status_id: Array.from(new Set(this.filters.map(x => x.operation_status_id))),
      task_operation_parameter_value_id: Array.from(new Set(this.filters.map(x => x.task_operation_parameter_value_id).filter(k => !['ignore', 'no'].includes(k)))),
    };

    this.assistantTasksSub = of(n).pipe(
      switchMap(pg => {
        if (pg == 1) {
          return this.taskService.getTasksCount(this.data.company_id, filterData).pipe(
            tap(el => {
              this.totalTasksCount = +el.headers.get('x-pagination-total-count')
            })
          )
        } else {
          return of(pg)
        }
      }),
      switchMap(pg => {
        return this.taskService.getCardsNoExpand(n, this.data.company_id, filterData, '50').pipe(
          tap(arrTasks => {
            console.log("arrTasks", arrTasks)
            if (arrTasks.length < 50) {
              this.hasMore = false
            } else {
              this.hasMore = true;
            }
          }),
          map(resArr => {
            resArr.forEach(card => {
              card.is_ok = true;
              this.filters.filter(k => k.group_id == card.group_id).forEach(fil => {
                if (card.operations.find(job => job.operation_id == fil.task_operation_operation_id && job.status_id == fil.operation_status_id && !job.is_checked_st_and_type)) {
                  card.operations.find(job => job.operation_id == fil.task_operation_operation_id && job.status_id == fil.operation_status_id && !job.is_checked_st_and_type).is_checked_st_and_type = true;
                } else {
                  card.is_ok = false;
                }
              });
            });
            if (!!this.totalTasksCount) {
              this.totalTasksCount = this.totalTasksCount - (resArr.length - resArr.filter(card => !!card.is_ok).length);
            }
            return resArr.filter(card => !!card.is_ok);
          }),
          tap(resp => {
            if (resp && resp.length) {
              this.taskService.assistantTaskData$.next(resp)
            }
          }),
          distinctUntilChanged()
        )
      })
    ).subscribe(resp => {

      if (+this.page == 1) {
        this.tasks = resp;
      } else {
        this.tasks.push(...resp)
      }
      console.log("this.tasks "+this.page, this.tasks)

      this.page = this.page + 1;

      if (this.hasMore) {
        this.getCards(this.page);
      } else {
        this.totalTasksCount = this.tasks.length;
        this.isLoad = false;
        this.stage = 'Getting job tags'
      }
    },
    error => {
      this.isLoad = false;
    })    
  }

  copyLiveData(type) {
    this.layoutService.showSnackBar({name: type}, marker("Copied"), SnackBarItem)
  }

  log() {
    console.log("CompilationAssistantComponent this.filters", this.filters)
  }

  close() {
    this.dialogRef.close({event: "close", data: false});
  }

  ngOnDestroy(): void {
    if (this.assistantTasksSub) {
      this.assistantTasksSub.unsubscribe()
    }
    if (this.assistantTaskDataSub) {
      this.assistantTaskDataSub.unsubscribe()
    }
    this.clearSubscriptions()
  }

}
