import { Component, OnInit } from '@angular/core';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';

@Component({
  selector: 'app-privacy-page',
  templateUrl: './privacy-page.component.html',
  styleUrls: ['./privacy-page.component.scss']
})
export class PrivacyPageComponent implements OnInit {
  public htmlData:any;
  constructor(
    private companyService: CompanyService,
    private layoutService: LayoutService,
  ) { }

  ngOnInit(): void {
    this.companyService.getPrivacyHtml().subscribe(resp => {
      this.htmlData = resp
    }, 
    error => {
      if (error.error && error.error.text) {
        this.htmlData = error.error.text
      } else [
        this.showError(error)
      ]
    })
  }

  showError(error?) {
    this.layoutService.showSnackBar({name: ''}, marker(error), SnackBarItem)
  }
}
