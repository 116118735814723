import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { Component, Inject, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSelectionList } from '@angular/material/list';
import { concat, forkJoin, fromEvent, of, Subscription } from 'rxjs';
import { catchError, concatMap, filter, last, map, switchMap, take, tap } from 'rxjs/operators';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { ChatService } from 'src/app/shared/services/rest/chat.service';
import { MembersService } from 'src/app/shared/services/rest/members.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { AddChatToBoardComponent } from '../../workspace-pages/chats-page/dialogs/add-chat-to-board/add-chat-to-board.component';
import { AuthService } from 'src/app/shared/services/rest/auth.service';
import { casesModel } from 'src/app/shared/functions/casesModel';
import { ChatStatusesComponent } from 'src/app/shared/global_components/chat/dialogs/chat-statuses/chat-statuses.component';
import { environment } from 'src/environments/environment';
import { ChatConnectionsComponent } from '../../workspace-pages/chats-page/dialogs/chat-connections/chat-connections.component';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { TargetParametersComponent } from '../target-parameters/target-parameters.component';

@Component({
  selector: 'app-operation-chat',
  templateUrl: './operation-chat.component.html',
  styleUrls: ['./operation-chat.component.scss']
})
export class OperationChatComponent extends BaseClass implements OnInit, OnDestroy {
  @ViewChild('chatMenu') chatMenu: TemplateRef<any>;
  @ViewChild("chatsList") chatsList: MatSelectionList;
  @ViewChild("jobsList") jobsList: MatSelectionList;
  public statuses: any;
  public timeZone: any;
  public user: any;
  public activeLang: any;
  public origin:any = window.location.origin;
  public host: any = environment.host;
  public menuState: boolean = true;
  public is_mobile: boolean = false;
  public selectMenuCounter: number = 0;
  public chat_id: number;
  public jobChatsModel: any[] = [];
  public activeJob: any;

  public overlayRefChatMenu: OverlayRef | null;
  public subChatMenu: Subscription;

  public chatsFilter: FormGroup = this.fb.group({
    data_file_id: ''
  });
  public operationsValues: any;
  public clickedJob: any;
  public chatsDataSub: Subscription;

  public imgRoute: any = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<OperationChatComponent>,
    public fb: FormBuilder,
    public chatService: ChatService,
    public dialog: MatDialog,
    public overlay: Overlay,
    public auth: AuthService,
    public viewContainerRef: ViewContainerRef,
    private layoutService: LayoutService,
    private languageService: LanguageService,
    private taskService: TaskService,
    private membersService: MembersService,
    private sm: StorageManagerService
  ) { super() }

  ngOnInit(): void {
    console.log("OperationChatComponent DATA", this.data);
    this.data.dialogHasChat = true;
    this.chat_id = this.data.chat.id;
    this.jobChatsModel = this.data.task.operations.filter(x => x.chatsInfo.length > 0);
    this.activeJob = this.data.task.operations.find(x => x.id == this.data.work.id);
    this.clickedJob = this.data.task.operations.find(x => x.id == this.data.work.id);

    // this.getAdditionalEmpl()
    console.log("jobChatsModel", this.jobChatsModel);
    if (window.innerWidth <= 769) {
      this.is_mobile = true;
    } else {
      this.is_mobile = false;
    }
    this.timeZone = new Date().getTimezoneOffset();

    this.getOperations();
    this.getUser();
    this.getChatStatuses();
    this.getLangData();
    this.getImgRoute();

    this.attachSubscriptions(
      this.chatsFilter.valueChanges.subscribe(res => {
        console.log("chatsFilter", res);
        this.getChats();
      })
    )

    this.attachSubscriptions(
      this.chatService.getDeletedChats().subscribe(resp => {
        let chats = this.activeJob.chatsArr ? this.activeJob.chatsArr : this.activeJob.chatsInfo;
        if (chats.filter(x => x.id == resp).length != 0) {
          let deletedChat = chats.find(x => x.id == resp);
          let indexDeletedChat = chats.indexOf(deletedChat);
          let nextChat = chats[indexDeletedChat + 1];
          let prevChat = chats[indexDeletedChat - 1];

          if (nextChat || prevChat) {
            chats.splice(indexDeletedChat, 1)
            this.selectChat(nextChat ? nextChat.id : prevChat.id, 'click')
          } else if (this.jobChatsModel.length > 1) {
            let targetJob = this.jobChatsModel.find(x => x.id != this.activeJob.id)
            this.jobChatsModel.splice(this.jobChatsModel.indexOf(this.activeJob), 1)
            this.setChat(targetJob, targetJob.chatsInfo[0])
          } else {
            this.close()
          }

        } else if (this.jobChatsModel.filter(b => b.chatsInfo.filter(x => x.id == resp).length != 0).length != 0) {
          let jobHasDeletedChat = this.jobChatsModel.find(b => b.chatsInfo.filter(x => x.id == resp).length != 0)
          let deletedChat = jobHasDeletedChat.chatsInfo.find(x => x.id == resp)
          let indexDeletedChat = jobHasDeletedChat.chatsInfo.indexOf(deletedChat);
          jobHasDeletedChat.chatsInfo.splice(indexDeletedChat, 1);
        }
      })
    )

    this.chatsDataSub = this.chatService.getChatsDataStream().pipe(
      concatMap(chatsInPage => {
        return concat(...chatsInPage.map(taskInPage => this.neededData(taskInPage))).pipe(last(),map(x => chatsInPage))
      }),
    ).subscribe(resp => console.log("-----chatsDataSubStream-----",resp));

    this.chatService.chatData$.next(this.activeJob.chatsInfo)
  }

  log() {
    console.log("this.activeJob", this.activeJob);
    console.log("this.chatsFilter", this.chatsFilter.value);
  }

  getChats() {
    let filter = JSON.parse(JSON.stringify(this.chatsFilter.value));
    filter.task_operation_id = this.activeJob.id
    Object.keys(filter).forEach(key => {
      if (filter[key] === '') {
        delete filter[key]
      }
    })

    this.attachSubscriptions(
      this.chatService.getChatsDyn('1', this.data.company.id, this.data.work.task_id, filter, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let pArr = []
          for (let index = 1; index <= pages; index++) {
            pArr.push(index)
          }

          return forkJoin(pArr.map(x => this.chatService.getChatsDyn(x, this.data.company.id, this.data.work.task_id, filter, '200').pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        let arr = [].concat(...res);
        console.log("getChats", arr);
        this.activeJob.chatsArr = arr;
        this.chatService.chatData$.next(this.activeJob.chatsArr);
        if (this.activeJob.chatsArr && this.activeJob.chatsArr.length && !this.activeJob.chatsArr.filter(u => u.id == this.chat_id).length) {
          this.selectChat(this.activeJob.chatsArr[0], "click")
        }
      })
    )
  }

  setChat(job, chat) {
    // console.log(chat, job)
    // return
    this.selectChat(chat.id, "click")
    this.activeJob = job;
    let chats = this.activeJob.chatsArr ? this.activeJob.chatsArr : this.activeJob.chatsInfo;
    this.chatService.chatData$.next(chats);
  }

  // getAdditionalEmpl() {
  //   this.attachSubscriptions(
  //     forkJoin(this.data.task.operations.map(x => this.taskService.getAdditionalEmpl(x.id).pipe(
  //       tap(val => {
  //         x.addEmployee = val
  //       })
  //     ))).subscribe(resp => {
  //       console.log("JOBS", this.data.task.operations);
  //     })
  //   )
  // }

  copyLink(type) {
    this.layoutService.showSnackBar({name: type + " link"}, "Copied", SnackBarItem)
  }
  
  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }
  
  neededData(chat) {
    return forkJoin([
      this.membersService.getMembers({ task_id: chat.task_id, task_operation_id: chat.task_operation_id }, this.data.company.id).pipe(
        tap(res => {
          chat.employees = res.filter(x => x.discussion_id == chat.id || x.discussion_id == 0 || !x.discussion_id);
          chat.employees.forEach(el => {
            if (chat.employeesStatuses.find(x => x.discussion_id == chat.id && el.employee_id == x.employee_id)) {
              el.employeeStatus = chat.employeesStatuses.find(x => x.discussion_id == chat.id && el.employee_id == x.employee_id)
            } else {
              el.employeeStatus = null
            }
          })
        }),
        catchError(err => of(err))
      ),
      this.chatService.getChatRead(chat.id, this.data.company.id).pipe(
        tap(resp => {
          if (resp.filter(el => el.user_id != this.data.user.id).length) {
            chat.readUsers = resp.filter(el => el.user_id != this.data.user.id && chat?.lastPost?.user_id != el.user_id && chat?.lastPost?.action?.user_id != el.user_id && el.updated_at > chat?.lastPost?.updated_at);
          }
        })
      )
    ])
  }

  understandFileType(val) {
    if (!val) {
      return ""
    }
    
    if (val == 'application/pdf') {
      return 'pdf'
    } else if (val.indexOf("/") != -1) {
      return val.split('/')[0]
    } else {
      return ""
    }
  }

  onImgError(event){
    event.target.src = this.imgRoute+'/assets/img/image_black_48dp.svg'
  }
  
  getChatStatuses() {
    this.attachSubscriptions(
      this.chatService.getChatStatuses().subscribe(resp => {
        this.statuses = resp.slice();
      })
    )
  }

  findUnread(arr) {
    return arr.filter(x => x.is_read == '0').length
  }

  toggleMenu() {
    this.menuState = !this.menuState
  }

  clickChat(id) {
    if (this.is_mobile) {
      this.menuState = true;
      this.selectMenuCounter = this.selectMenuCounter + 1;
    }
  }

  getColumnName(id) {
    if (!this.data.operationsValues || this.data.operationsValues.filter(el => el.id == id).length == 0) {
      return '';
    }
    return this.data.operationsValues.find(el => el.id == id) && this.data.operationsValues.find(el => el.id == id).translation ? this.data.operationsValues.find(el => el.id == id).translation.name : this.data.operationsValues.find(el => el.id == id).name
  }

  selectChat(id, event) {

    console.log("selectChat");
    if (event == 'click') {
      this.menuState = true;
    }
    this.selectMenuCounter = this.selectMenuCounter + 1;
    this.chat_id = id;
  }

  toNextChat() {
    console.log("toNextChat");
    let chats = this.activeJob.chatsArr ? this.activeJob.chatsArr : this.activeJob.chatsInfo;
    if (this.chat_id && chats) {
      let index = chats.findIndex(x => x.id == this.chat_id);
      if (index >= 0 && chats[index + 1]) {
        this.selectChat(chats[index + 1].id, 'click')
      }
    }
  }

  jobClick(job) {
    if (this.clickedJob && this.clickedJob.id == job.id) {
      this.clickedJob = undefined;
    } else {
      this.clickedJob = job
    }
    console.log(this.clickedJob)
  }

  selectJob(job) {
    this.clickedJob = job;
    // this.activeJob = job
    console.log(this.activeJob)
  }

  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData().subscribe(resp => {
        this.activeLang = resp.active;
      })
    )
  }

  hideMenu() {
    if (this.data.closeFull) {
      this.close()
      return
    }
    
    this.menuState = false;
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  getChatStatus(id) {
    switch (+id) {
      case 1:
        return "Waiting";
        break;
      case 2:
        return "In process";
        break;
      case 3:
        return "To approve";
        break;
      case 4:
        return "Approved";
        break;
      case 5:
        return "To correct";
        break;
      case 6:
        return "Sources needed";
        break;
      case 97:
        return "On hold";
        break;
      case 98:
        return "Ready";
        break;
      case 99:
        return "Canceled";
        break;
    }
  }

  
  closeChatMenu() {
    this.subChatMenu && this.subChatMenu.unsubscribe();
    if (this.overlayRefChatMenu) {
      this.overlayRefChatMenu.dispose();
      this.overlayRefChatMenu = null;
    }
  }

  getUser() {
    this.attachSubscriptions(
      this.auth.$user.subscribe(resp => {
        this.user = resp;
      })
    )
  }

  checkIsManager(task, company, _user) { 
    return task?.managers.filter(x => x.user_id == _user.id).length > 0 || task?.group?.managers.filter(x => x.user_id == _user.id).length > 0 || company?.permissions.includes('admin') || company?.permissions.includes('owner') || company?.permissions.includes('manager'); 
  }

  neededTaskData(task, needChats:boolean = true) {
    let arr = [];
    if (this.data.company.permissions.includes('owner') || this.checkIsManager(task, this.data.company, this.user)) {
      arr.push(this.taskService.getTaskClients(task.id, this.data.company.id).pipe(
        tap(res => {
          task.clients = res
        }),
        catchError(err => {
          return err
        })
      ))
    }

    if (needChats) {
      arr.push(this.chatService.getTasksChats(this.data.company.id, [task.id]).pipe(
        tap(res => {
          task['operations'].map(o_el => {
            o_el['chatsInfo'] = [];
            o_el['chatsInfo'].push(...res.filter(z => z.task_operation_id == o_el.id));
            o_el['unReadChats'] = res.filter(z => z.task_operation_id == o_el.id && z.is_read != '1').length;
            o_el['unClosedChats'] = res.filter(z => z.task_operation_id == o_el.id && z.status_id != '4').length;
          });
          task['chatsInfo'] = [];
          task['chatsInfo'].push(...res.filter(z => z.task_id == task.id));
          task['unReadChats'] = res.filter(z => z.is_read != '1').length;
          task['unClosedChats'] = res.filter(z => z.status_id != '4').length;
        })
      ))
    }
    if (arr.length) {
      return forkJoin(arr)
    } else {
      return of(task)
    }
  }

  toPersonalStatuses(chat) {
    this.attachSubscriptions(
      this.taskService.getOneTaskExpand(this.data.company.id, chat.task_id).pipe(
        map(x => x.body[0]),
        switchMap(val => this.neededTaskData(val, false).pipe(map(() => casesModel([val], [], 'update')),map(val => val.arr[0])))
      ).subscribe(resp => {
        console.log("addToBoard", resp)
        let work = resp.operations.find(x => x.id == chat.task_operation_id);
        work.employees = work.employees.filter(x => x.discussion_id == chat.id || x.discussion_id == 0 || !x.discussion_id)
        work.mainEmployees = work.mainEmployees.filter(x => x.discussion_id == chat.id || x.discussion_id == 0 || !x.discussion_id)
        work.addEmployee = work.addEmployee.filter(x => x.discussion_id == chat.id || x.discussion_id == 0 || !x.discussion_id)
        work.addEmployee.forEach(x => {
          if (!x.employeeStatus && chat.employeesStatuses.find(z => z.employee_id == x.employee_id)) {
            x.employeeStatus = chat.employeesStatuses.find(z => z.employee_id == x.employee_id)
          }
        })
        const goToChat = this.dialog.open(ChatStatusesComponent, {
          panelClass: 'add_to_board_modal',
          data: {
            company: this.data.company,
            user: this.user,
            imgRoute: this.imgRoute,
            host: this.host,
            activeLang: this.activeLang,
            chat: chat,
            employees: chat.employees,
            needGetMembers: true,
            task: resp,
            job: work,
            statuses: this.statuses,
            empl_status: this.data.company.permissions.includes('owner') || this.checkIsManager(resp, this.data.company, this.user),
            operationsValues: this.operationsValues,
          }
        });

        // this.attachSubscriptions(
        //   goToChat.afterClosed().subscribe(result => {
        //     this.refreshService.refreshChat$.next({company_id: this.company_id, chat_id: chat.id})
        //   })
        // )
      })
    )

  }

  
  addToBoard(chat) {
    this.attachSubscriptions(
      this.taskService.getOneTaskExpand(this.data.company.id, chat.task_id).pipe(
        map(x => x.body[0]),
        switchMap(val => this.neededTaskData(val).pipe(map(() => casesModel([val], [], 'update')),map(val => val.arr[0])))
      ).subscribe(resp => {
        console.log("addToBoard", resp)
        let work = resp.operations.find(x => x.id == chat.task_operation_id);
        work.employees = work.employees.filter(x => x.discussion_id == chat.id || x.discussion_id == 0 || !x.discussion_id)
        work.mainEmployees = work.mainEmployees.filter(x => x.discussion_id == chat.id || x.discussion_id == 0 || !x.discussion_id)
        work.addEmployee = work.addEmployee.filter(x => x.discussion_id == chat.id || x.discussion_id == 0 || !x.discussion_id)
        work.addEmployee.forEach(x => {
          if (!x.employeeStatus && chat.employeesStatuses.find(z => z.employee_id == x.employee_id)) {
            x.employeeStatus = chat.employeesStatuses.find(z => z.employee_id == x.employee_id)
          }
        })
        const goToChat = this.dialog.open(AddChatToBoardComponent, {
          panelClass: 'add_to_board_modal',
          data: {
            company: this.data.company,
            user: this.user,
            imgRoute: this.imgRoute,
            host: this.host,
            activeLang: this.activeLang,
            chat: chat,
            employees: chat.employees,
            needGetMembers: true,
            task: resp,
            job: work,
            statuses: this.statuses,
            empl_status: this.data.company.permissions.includes('owner') || this.checkIsManager(resp, this.data.company, this.user),
            operationsValues: this.operationsValues,
          }
        });

        // this.attachSubscriptions(
        //   goToChat.afterClosed().subscribe(result => {
        //     this.refreshService.refreshChat$.next({company_id: this.company_id, chat_id: chat.id})
        //   })
        // )
      })
    )

  }

  openInNew(e, chat) {
    e.preventDefault();
    e.stopPropagation();
    console.log("openInNew", chat);
    window.open(`${this.origin}/chat/${chat.acm}`, '_blank')
  }

  openConnectionChats(chat) {
    const goToChatCon = this.dialog.open(ChatConnectionsComponent, {
      backdropClass: 'backdrop_under_header',
      panelClass: !this.is_mobile ? ['open_task_dialog', 'show_header'] : 'open_task_dialog',
      data: {
        company: this.data.company,
        user: this.user,
        chat: chat,
        is_mobile: this.is_mobile,
        imgRoute: this.imgRoute,
        operationsValues: this.operationsValues,
        host: this.host,
        activeLang: this.activeLang,
      }
    });

    // this.attachSubscriptions(
    //   goToChatCon.afterClosed().subscribe(result => {
    //     this.updateDiscussion(this.chat_id)
    //     this.refreshService.refreshChat$.next({company_id: this.company.id, chat_id: this.chat_id})
    //     this.initRefreshBoard()
    //   })
    // )
  }

  getOperations() {
    this.attachSubscriptions(
      this.taskService.getOperations(this.data.company.id, this.activeLang).subscribe(resp => {
        this.operationsValues = resp;
      })
    )
  }


  openChatMenu({ x, y }: MouseEvent, chat) {
    this.closeChatMenu();
    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo({ x, y })
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        }
      ]);

    this.overlayRefChatMenu = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });


    this.overlayRefChatMenu.attach(new TemplatePortal(this.chatMenu, this.viewContainerRef, {
      $implicit: {
        chat: chat
      }
    }));
    
    this.subChatMenu = fromEvent<MouseEvent>(document, 'click')
      .pipe(
        filter(event => {
          const clickTarget = event.target as HTMLElement;
          return !!this.overlayRefChatMenu && !this.overlayRefChatMenu.overlayElement.contains(clickTarget);
        }),
        take(1)
      ).subscribe(() => this.closeChatMenu())

  }

  openTargetValues(info, element) {
    let initData:any = {
      company: this.data.company,
      chat: element
    }

    initData.target = {
      task_id: info[0],
      task_operation_id: info[1],
      discussion_id: info[2]
    }
    if (element.company_id != this.data.company.id) {
      initData.target.partner_company_id = element.company_id
    }
    const dialogRef = this.dialog.open(TargetParametersComponent, {
      backdropClass: ['parameters_modal_backdrop'],
      panelClass: ['without_paddings_modal', 'parameters_modal'],
      data: initData
    });

    // this.attachSubscriptions(
    //   dialogRef.afterClosed().subscribe(result => {
    //     this.refreshService.refreshTask$.next({company_id: this.company_id, task_id: element.id})
    //   })
    // )
  }

  ngOnDestroy(): void {
    if (this.chatsDataSub) {
      this.chatsDataSub.unsubscribe();
    }
    this.clearSubscriptions()
  }

}
