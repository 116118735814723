import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { AuthService } from 'src/app/shared/services/rest/auth.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { concatMap, debounceTime, last, map, switchMap, tap } from 'rxjs/operators';
import { forkJoin, of, ReplaySubject, Subscription } from 'rxjs';
import { MY_FORMATS } from 'src/app/components/workspace-settings/finances/statements/statements.component';
import { ScenariosService } from 'src/app/shared/services/rest/scenarios.service';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { EditApproveComponent } from '../edit-approve/edit-approve.component';
import { GlobalDataService } from 'src/app/shared/services/common/global-data.service';
import { environment } from 'src/environments/environment';
import { TargetParametersComponent } from 'src/app/components/atTasksDialog/target-parameters/target-parameters.component';
import { ParametersService } from 'src/app/shared/services/rest/parameters.service';
import { SetEditSourceComponent } from './dialogs/set-edit-source/set-edit-source.component';

@Component({
  selector: 'app-add-automation-template',
  templateUrl: './add-automation-template.component.html',
  styleUrls: ['./add-automation-template.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class AddAutomationTemplateComponent extends BaseClass implements OnInit, OnDestroy {
  public host: any = environment.host;
  public imgRoute: any = '';
  public user_id: number;
  public form: FormGroup;
  public company_id: any;
  public task_id: any;
  public operations: any;
  public profileStatuses: any;
  public automationType: any;
  public operationStatuses: any;
  public taskStatuses: any;
  public values: any;
  public parameters: any;
  public parametersCtrl: FormControl = new FormControl();
  public parameters$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public allValues: any;
  public allValuesControl: FormControl = new FormControl();
  public allValues$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public numberRegEx = /\d*\.?\d{1,2}/;
  public isFormChange: boolean = false;
  public isSubmit: boolean = false;
  public submited: boolean = false;
  public scnSwitch: boolean = false;
  public partnerSwitch: boolean = false;
  public emplSwitch: boolean = false;
  public cliPartnerSwitch: boolean = false;
  public cliEmplSwitch: boolean = false;

  public sbmtEmpl: any = [];
  public sbmtEmplIds: any = [];
  public sbmtPartners: any = [];
  public sbmtPartnersIds: any = [];

  public folders: any[] = [
    {
      name: "Root",
      id: -1,
      location: "/",
      over: false,
      rootDir: true
    },
    {
      name: "Sources",
      id: 0,
      location: "/sources",
      over: false,
      rootDir: true
    },
    {
      name: "To Approve",
      id: 1,
      location: "/to_approve",
      over: false,
      rootDir: true
    },
    {
      name: "Ready Files",
      id: 2,
      location: "/ready_files",
      over: false,
      rootDir: true
    },
    {
      name: "Approved",
      id: 3,
      location: "/approved",
      over: false,
      rootDir: true
    },
    {
      name: "Project",
      id: 4,
      location: "/project",
      over: false,
      rootDir: true
    },
    {
      name: "Trash",
      id: 5,
      location: "/trash",
      over: false,
      rootDir: true
    }
  ]
  
  public paramDataSub: Subscription;
  public jobs: any[] = [];
  
  public templates: any;
  public templatesMoreControl: FormControl = new FormControl();
  public templates$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public employees: any;
  public employeeMoreControl: FormControl = new FormControl();
  public employees$: ReplaySubject<any> = new ReplaySubject<any>(1);
  
  public operationMoreControl: FormControl = new FormControl();
  public operations$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public profiles: any = [];

  public partners: any;
  public partnersControl: FormControl = new FormControl();
  public partners$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public groups: any;
  public groupsControl: FormControl = new FormControl();
  public groups$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public checklistsPage: number = 1;
  public checklistsPagination: any;
  public checklists: any;
  public checklistsControl: FormControl = new FormControl();
  public checklists$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public paramControl: FormControl = new FormControl();
  public pCliSwitch: boolean = false;
  public cliSwitch: boolean = false;

  public clients = [];
  public partnerClients = [];
  public clients_ids = [];
  public partnerClients_ids = [];
  public sources: any = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddAutomationTemplateComponent>,
    private sm: StorageManagerService,
    private auth: AuthService,
    private companyService: CompanyService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private parametersService: ParametersService,
    private taskService: TaskService,
    private _adapter: DateAdapter<any>,
    private languageService: LanguageService,
    private globalDataService: GlobalDataService,
    private bottomSheet: MatBottomSheet,
    private layoutService: LayoutService,
    private scenariosService: ScenariosService,

  ) { super() }

  ngOnInit(): void {
    window.scroll(0,0);

    this.company_id = this.data.company.id;
    this.task_id = 0;

    this.getCompany();
    this.getImgRoute();
    this.getCsrf();

    this.attachSubscriptions(
      this.employeeMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchEmoloyees(resp))
    )
    this.attachSubscriptions(
      this.allValuesControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchValues(resp))
    )
    this.attachSubscriptions(
      this.partnersControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchPartners(resp))
    )
    
    this.attachSubscriptions(
      this.operationMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchOperations(resp))
    )
    this.attachSubscriptions(
      this.parametersCtrl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchParams(resp))
    )

    this.getAutomationTemplates();
    this.attachSubscriptions(
      this.templatesMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchTemplates(resp))
    )

    this.form = this.fb.group({
      company_id: this.company_id,
      name: ['', Validators.required],
      scenario_name: ['', Validators.required],
      scenario_data: this.fb.group({
      }),
      is_task_operation: 1,
    })

    this.attachSubscriptions(
      this.groupsControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchGroups(resp))
    )

    this.attachSubscriptions(
      this.form.get('scenario_name').valueChanges.subscribe(resp => {
        // if (resp == 'TaskOperationFilesCollector') {
        //   if (!this.form.get('scenario_data').get("create_task_operation")) {
        //     (this.form.get('scenario_data') as FormGroup).addControl('employees_ids', this.fb.control([[]]));
        //     (this.form.get('scenario_data') as FormGroup).addControl('partner_companies_ids', this.fb.control([[]]));
        //   }
        // } else {
        //   if (!!this.form.get('scenario_data').get("create_task_operation")) {
        //     (this.form.get('scenario_data') as FormGroup).removeControl('create_task_operation');
        //     (this.form.get('scenario_data') as FormGroup).removeControl('create_discussion');
        //     (this.form.get('scenario_data') as FormGroup).removeControl('employees_ids');
        //     (this.form.get('scenario_data') as FormGroup).removeControl('partner_companies_ids');
        //   }
        // }

        if (resp == 'TaskOperationChain') {

          if (!this.form.get('scenario_data').get("is_task_operation_one_per_template")) {
            (this.form.get('scenario_data') as FormGroup).addControl('status_id', this.fb.control(['', Validators.required]));
            (this.form.get('scenario_data') as FormGroup).addControl('operation_id', this.fb.control(['', Validators.required]));
            (this.form.get('scenario_data') as FormGroup).addControl('employees_ids', this.fb.control([]));
            (this.form.get('scenario_data') as FormGroup).addControl('partner_companies_ids', this.fb.control([]));
            (this.form.get('scenario_data') as FormGroup).addControl('task_operation_clients', this.fb.control([]));
            (this.form.get('scenario_data') as FormGroup).addControl('skip_task_channel_status', this.fb.control([[]]));
            (this.form.get('scenario_data') as FormGroup).addControl('task_operation_parameters_ids', this.fb.control([]));
            (this.form.get('scenario_data') as FormGroup).addControl('task_operation_automation_templates_ids', this.fb.control([]));
  
            (this.form.get('scenario_data') as FormGroup).addControl('is_automation_employee_position_task_employee', this.fb.control(0));
            (this.form.get('scenario_data') as FormGroup).addControl('is_automation_employee_position_task_employee_assistant', this.fb.control(0));
            (this.form.get('scenario_data') as FormGroup).addControl('is_automation_employee_position_task_client_principal', this.fb.control(0));
            (this.form.get('scenario_data') as FormGroup).addControl('is_automation_employee_position_task_client', this.fb.control(0));
            (this.form.get('scenario_data') as FormGroup).addControl('is_automation_employee_position_task_partner', this.fb.control(0));
            (this.form.get('scenario_data') as FormGroup).addControl('is_automation_employee_position_task_partner_assistant', this.fb.control(0));
            (this.form.get('scenario_data') as FormGroup).addControl('is_automation_employee_position_task_partner_client_principal', this.fb.control(0));
            (this.form.get('scenario_data') as FormGroup).addControl('is_automation_employee_position_task_partner_client', this.fb.control(0));

            (this.form.get('scenario_data') as FormGroup).addControl('is_inherit_task_employee', this.fb.control(0));
            (this.form.get('scenario_data') as FormGroup).addControl('is_inherit_task_employee_assistant', this.fb.control(0));
            (this.form.get('scenario_data') as FormGroup).addControl('is_inherit_task_client_principal', this.fb.control(0));
            (this.form.get('scenario_data') as FormGroup).addControl('is_inherit_task_client', this.fb.control(0));
            (this.form.get('scenario_data') as FormGroup).addControl('is_inherit_task_partner', this.fb.control(0));
            (this.form.get('scenario_data') as FormGroup).addControl('is_inherit_task_partner_assistant', this.fb.control(0));
            (this.form.get('scenario_data') as FormGroup).addControl('is_inherit_task_partner_client_principal', this.fb.control(0));
            (this.form.get('scenario_data') as FormGroup).addControl('is_inherit_task_partner_client', this.fb.control(0));

            (this.form.get('scenario_data') as FormGroup).addControl('is_copy_sources_files', this.fb.control(0));
            (this.form.get('scenario_data') as FormGroup).addControl('is_copy_to_approve_files', this.fb.control(0));
            (this.form.get('scenario_data') as FormGroup).addControl('is_copy_project_files', this.fb.control(0));
            (this.form.get('scenario_data') as FormGroup).addControl('is_copy_approved_files', this.fb.control(0));
            (this.form.get('scenario_data') as FormGroup).addControl('is_copy_ready_files', this.fb.control(0));
            (this.form.get('scenario_data') as FormGroup).addControl('is_create_discussions', this.fb.control(0));
            (this.form.get('scenario_data') as FormGroup).addControl('is_task_operation_one', this.fb.control(0));
            (this.form.get('scenario_data') as FormGroup).addControl('is_task_operation_one_per_template', this.fb.control(0));
            (this.form.get('scenario_data') as FormGroup).addControl('is_discussion_one', this.fb.control(0));
            (this.form.get('scenario_data') as FormGroup).addControl('is_discussion_one_per_template', this.fb.control(0));
            (this.form.get('scenario_data') as FormGroup).addControl('is_confirm_before_execute', this.fb.control(0));
            (this.form.get('scenario_data') as FormGroup).addControl('is_confirm_multiple_employees', this.fb.control(0));
            (this.form.get('scenario_data') as FormGroup).addControl('is_skip_task_channel_with_url', this.fb.control(0));
            (this.form.get('scenario_data') as FormGroup).addControl('is_link_discussion_status_to_task_channel', this.fb.control(0));
            (this.form.get('scenario_data') as FormGroup).addControl('is_execute_after_task_channel_created', this.fb.control(0));
          }
          if (!!this.form.get('scenario_data').get("is_execute_after_file_created")) {
            (this.form.get('scenario_data') as FormGroup).removeControl('status_ids');
            (this.form.get('scenario_data') as FormGroup).removeControl('channel_parameters_ids');
            (this.form.get('scenario_data') as FormGroup).removeControl('min_publish_period');
            (this.form.get('scenario_data') as FormGroup).removeControl('is_content_video');
            (this.form.get('scenario_data') as FormGroup).removeControl('is_file_document_json_auto_post');
            (this.form.get('scenario_data') as FormGroup).removeControl('is_file_photo_thumbnail');
            (this.form.get('scenario_data') as FormGroup).removeControl('is_execute_after_file_created');
            (this.form.get('scenario_data') as FormGroup).removeControl('file_content_parameters_ids');
            (this.form.get('scenario_data') as FormGroup).removeControl('is_file_content_without_parameters');
            (this.form.get('scenario_data') as FormGroup).removeControl('file_document_parameters_ids');
            (this.form.get('scenario_data') as FormGroup).removeControl('is_file_document_without_parameters');
            (this.form.get('scenario_data') as FormGroup).removeControl('file_thumbnail_parameters_ids');
            (this.form.get('scenario_data') as FormGroup).removeControl('is_file_thumbnail_without_parameters');
            (this.form.get('scenario_data') as FormGroup).removeControl('is_task_channel_channel_without_parameters');
            (this.form.get('scenario_data') as FormGroup).removeControl('is_task_channel_data');
            (this.form.get('scenario_data') as FormGroup).removeControl('published_status_id');
            (this.form.get('scenario_data') as FormGroup).removeControl('content_plan_and_task_parameters_ids');
            (this.form.get('scenario_data') as FormGroup).removeControl('content_plan_and_task_operation_parameters_ids');
            (this.form.get('scenario_data') as FormGroup).removeControl('content_plan_and_file_parameters_ids');
            (this.form.get('scenario_data') as FormGroup).removeControl('task_channel_channel_parameters_ids');
          }

        } else if (resp == 'TaskOperationFilesCollector') {

          if (!!this.form.get('scenario_data').get("is_task_operation_one_per_template")) {
            (this.form.get('scenario_data') as FormGroup).removeControl('status_id');
            (this.form.get('scenario_data') as FormGroup).removeControl('operation_id');
            (this.form.get('scenario_data') as FormGroup).removeControl('employees_ids');
            (this.form.get('scenario_data') as FormGroup).removeControl('partner_companies_ids');
            (this.form.get('scenario_data') as FormGroup).removeControl('task_operation_clients');
            (this.form.get('scenario_data') as FormGroup).removeControl('skip_task_channel_status');
            (this.form.get('scenario_data') as FormGroup).removeControl('task_operation_parameters_ids');
            (this.form.get('scenario_data') as FormGroup).removeControl('task_operation_automation_templates_ids');
            (this.form.get('scenario_data') as FormGroup).removeControl('is_copy_sources_files');
            (this.form.get('scenario_data') as FormGroup).removeControl('is_copy_to_approve_files');
            (this.form.get('scenario_data') as FormGroup).removeControl('is_copy_project_files');
            (this.form.get('scenario_data') as FormGroup).removeControl('is_copy_approved_files');
            (this.form.get('scenario_data') as FormGroup).removeControl('is_copy_ready_files');
            (this.form.get('scenario_data') as FormGroup).removeControl('is_create_discussions');
            (this.form.get('scenario_data') as FormGroup).removeControl('is_task_operation_one');
            (this.form.get('scenario_data') as FormGroup).removeControl('is_task_operation_one_per_template');
            (this.form.get('scenario_data') as FormGroup).removeControl('is_discussion_one');
            (this.form.get('scenario_data') as FormGroup).removeControl('is_discussion_one_per_template');
            (this.form.get('scenario_data') as FormGroup).removeControl('is_confirm_before_execute');
            (this.form.get('scenario_data') as FormGroup).removeControl('is_confirm_multiple_employees');
            (this.form.get('scenario_data') as FormGroup).removeControl('is_skip_task_channel_with_url');
            (this.form.get('scenario_data') as FormGroup).removeControl('is_link_discussion_status_to_task_channel');
            (this.form.get('scenario_data') as FormGroup).removeControl('is_execute_after_task_channel_created');

            (this.form.get('scenario_data') as FormGroup).removeControl('is_automation_employee_position_task_employee');
            (this.form.get('scenario_data') as FormGroup).removeControl('is_automation_employee_position_task_employee_assistant');
            (this.form.get('scenario_data') as FormGroup).removeControl('is_automation_employee_position_task_client_principal');
            (this.form.get('scenario_data') as FormGroup).removeControl('is_automation_employee_position_task_client');
            (this.form.get('scenario_data') as FormGroup).removeControl('is_automation_employee_position_task_partner');
            (this.form.get('scenario_data') as FormGroup).removeControl('is_automation_employee_position_task_partner_assistant');
            (this.form.get('scenario_data') as FormGroup).removeControl('is_automation_employee_position_task_partner_client_principal');
            (this.form.get('scenario_data') as FormGroup).removeControl('is_automation_employee_position_task_partner_client');

            (this.form.get('scenario_data') as FormGroup).removeControl('is_inherit_task_employee');
            (this.form.get('scenario_data') as FormGroup).removeControl('is_inherit_task_employee_assistant');
            (this.form.get('scenario_data') as FormGroup).removeControl('is_inherit_task_client_principal');
            (this.form.get('scenario_data') as FormGroup).removeControl('is_inherit_task_client');
            (this.form.get('scenario_data') as FormGroup).removeControl('is_inherit_task_partner');
            (this.form.get('scenario_data') as FormGroup).removeControl('is_inherit_task_partner_assistant');
            (this.form.get('scenario_data') as FormGroup).removeControl('is_inherit_task_partner_client_principal');
            (this.form.get('scenario_data') as FormGroup).removeControl('is_inherit_task_partner_client');
          }
          if (!!this.form.get('scenario_data').get("is_execute_after_file_created")) {
            (this.form.get('scenario_data') as FormGroup).removeControl('status_ids');
            (this.form.get('scenario_data') as FormGroup).removeControl('channel_parameters_ids');
            (this.form.get('scenario_data') as FormGroup).removeControl('min_publish_period');
            (this.form.get('scenario_data') as FormGroup).removeControl('is_content_video');
            (this.form.get('scenario_data') as FormGroup).removeControl('is_file_document_json_auto_post');
            (this.form.get('scenario_data') as FormGroup).removeControl('is_file_photo_thumbnail');
            (this.form.get('scenario_data') as FormGroup).removeControl('is_execute_after_task_channel_created');
            (this.form.get('scenario_data') as FormGroup).removeControl('is_execute_after_file_created');
            (this.form.get('scenario_data') as FormGroup).removeControl('file_content_parameters_ids');
            (this.form.get('scenario_data') as FormGroup).removeControl('is_file_content_without_parameters');
            (this.form.get('scenario_data') as FormGroup).removeControl('file_document_parameters_ids');
            (this.form.get('scenario_data') as FormGroup).removeControl('is_file_document_without_parameters');
            (this.form.get('scenario_data') as FormGroup).removeControl('file_thumbnail_parameters_ids');
            (this.form.get('scenario_data') as FormGroup).removeControl('is_file_thumbnail_without_parameters');
            (this.form.get('scenario_data') as FormGroup).removeControl('is_task_channel_channel_without_parameters');
            (this.form.get('scenario_data') as FormGroup).removeControl('published_status_id');
            (this.form.get('scenario_data') as FormGroup).removeControl('content_plan_and_task_parameters_ids');
            (this.form.get('scenario_data') as FormGroup).removeControl('content_plan_and_task_operation_parameters_ids');
            (this.form.get('scenario_data') as FormGroup).removeControl('content_plan_and_file_parameters_ids');
            (this.form.get('scenario_data') as FormGroup).removeControl('is_task_channel_data');
            (this.form.get('scenario_data') as FormGroup).removeControl('task_channel_channel_parameters_ids');
          }

        } else if (resp == 'TaskOperationContentPublisher') {

          if (!!this.form.get('scenario_data').get("is_task_operation_one_per_template")) {
            (this.form.get('scenario_data') as FormGroup).removeControl('status_id');
            (this.form.get('scenario_data') as FormGroup).removeControl('operation_id');
            (this.form.get('scenario_data') as FormGroup).removeControl('employees_ids');
            (this.form.get('scenario_data') as FormGroup).removeControl('partner_companies_ids');
            (this.form.get('scenario_data') as FormGroup).removeControl('task_operation_clients');
            (this.form.get('scenario_data') as FormGroup).removeControl('skip_task_channel_status');
            (this.form.get('scenario_data') as FormGroup).removeControl('task_operation_parameters_ids');
            (this.form.get('scenario_data') as FormGroup).removeControl('task_operation_automation_templates_ids');
            (this.form.get('scenario_data') as FormGroup).removeControl('is_copy_sources_files');
            (this.form.get('scenario_data') as FormGroup).removeControl('is_copy_to_approve_files');
            (this.form.get('scenario_data') as FormGroup).removeControl('is_copy_project_files');
            (this.form.get('scenario_data') as FormGroup).removeControl('is_copy_approved_files');
            (this.form.get('scenario_data') as FormGroup).removeControl('is_copy_ready_files');
            (this.form.get('scenario_data') as FormGroup).removeControl('is_create_discussions');
            (this.form.get('scenario_data') as FormGroup).removeControl('is_task_operation_one');
            (this.form.get('scenario_data') as FormGroup).removeControl('is_task_operation_one_per_template');
            (this.form.get('scenario_data') as FormGroup).removeControl('is_discussion_one');
            (this.form.get('scenario_data') as FormGroup).removeControl('is_discussion_one_per_template');
            (this.form.get('scenario_data') as FormGroup).removeControl('is_confirm_before_execute');
            (this.form.get('scenario_data') as FormGroup).removeControl('is_confirm_multiple_employees');
            (this.form.get('scenario_data') as FormGroup).removeControl('is_skip_task_channel_with_url');
            (this.form.get('scenario_data') as FormGroup).removeControl('is_link_discussion_status_to_task_channel');
            (this.form.get('scenario_data') as FormGroup).removeControl('is_execute_after_task_channel_created');

            (this.form.get('scenario_data') as FormGroup).removeControl('is_automation_employee_position_task_employee');
            (this.form.get('scenario_data') as FormGroup).removeControl('is_automation_employee_position_task_employee_assistant');
            (this.form.get('scenario_data') as FormGroup).removeControl('is_automation_employee_position_task_client_principal');
            (this.form.get('scenario_data') as FormGroup).removeControl('is_automation_employee_position_task_client');
            (this.form.get('scenario_data') as FormGroup).removeControl('is_automation_employee_position_task_partner');
            (this.form.get('scenario_data') as FormGroup).removeControl('is_automation_employee_position_task_partner_assistant');
            (this.form.get('scenario_data') as FormGroup).removeControl('is_automation_employee_position_task_partner_client_principal');
            (this.form.get('scenario_data') as FormGroup).removeControl('is_automation_employee_position_task_partner_client');

            (this.form.get('scenario_data') as FormGroup).removeControl('is_inherit_task_employee');
            (this.form.get('scenario_data') as FormGroup).removeControl('is_inherit_task_employee_assistant');
            (this.form.get('scenario_data') as FormGroup).removeControl('is_inherit_task_client_principal');
            (this.form.get('scenario_data') as FormGroup).removeControl('is_inherit_task_client');
            (this.form.get('scenario_data') as FormGroup).removeControl('is_inherit_task_partner');
            (this.form.get('scenario_data') as FormGroup).removeControl('is_inherit_task_partner_assistant');
            (this.form.get('scenario_data') as FormGroup).removeControl('is_inherit_task_partner_client_principal');
            (this.form.get('scenario_data') as FormGroup).removeControl('is_inherit_task_partner_client');
          }
          if (!this.form.get('scenario_data').get("is_execute_after_file_created")) {
            (this.form.get('scenario_data') as FormGroup).addControl('status_ids', this.fb.control([]));
            (this.form.get('scenario_data') as FormGroup).addControl('channel_parameters_ids', this.fb.control([]));
            (this.form.get('scenario_data') as FormGroup).addControl('min_publish_period', this.fb.control(0));
            (this.form.get('scenario_data') as FormGroup).addControl('is_content_video', this.fb.control(1));
            (this.form.get('scenario_data') as FormGroup).addControl('is_file_document_json_auto_post', this.fb.control(0));
            (this.form.get('scenario_data') as FormGroup).addControl('is_file_photo_thumbnail', this.fb.control(0));
            (this.form.get('scenario_data') as FormGroup).addControl('is_execute_after_task_channel_created', this.fb.control(0));
            (this.form.get('scenario_data') as FormGroup).addControl('is_execute_after_file_created', this.fb.control(0));
            (this.form.get('scenario_data') as FormGroup).addControl('file_content_parameters_ids', this.fb.control([]));
            (this.form.get('scenario_data') as FormGroup).addControl('is_file_content_without_parameters', this.fb.control(0));
            (this.form.get('scenario_data') as FormGroup).addControl('file_document_parameters_ids', this.fb.control([]));
            (this.form.get('scenario_data') as FormGroup).addControl('is_file_document_without_parameters', this.fb.control(0));
            (this.form.get('scenario_data') as FormGroup).addControl('file_thumbnail_parameters_ids', this.fb.control([]));
            (this.form.get('scenario_data') as FormGroup).addControl('is_file_thumbnail_without_parameters', this.fb.control(0));
            (this.form.get('scenario_data') as FormGroup).addControl('task_channel_channel_parameters_ids', this.fb.control([]));
            (this.form.get('scenario_data') as FormGroup).addControl('is_task_channel_channel_without_parameters', this.fb.control(0));
            (this.form.get('scenario_data') as FormGroup).addControl('published_status_id', this.fb.control(0));
            (this.form.get('scenario_data') as FormGroup).addControl('content_plan_and_task_parameters_ids', this.fb.control([]));
            (this.form.get('scenario_data') as FormGroup).addControl('content_plan_and_task_operation_parameters_ids', this.fb.control([]));
            (this.form.get('scenario_data') as FormGroup).addControl('content_plan_and_file_parameters_ids', this.fb.control([]));
            (this.form.get('scenario_data') as FormGroup).addControl('is_task_channel_data', this.fb.control(0));
          }

        }
        
        this.form.updateValueAndValidity();
      })
    )

    console.log(this.form.get('skip_task_channel_status'))

    this.getProfilesStatus();
    this.getPartners();
    this.getAllChecklists();

    this.attachSubscriptions(
      this.form.valueChanges.subscribe(() => this.isFormChange = true)
    )

    this.attachSubscriptions(
      this.paramControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchParams(resp))
    )

    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        if (this.isFormChange) {
          this.layoutService.openBottomSheet(this.dialogRef);
        } else {
          this.close();
        }
      })
    )
    this.getParameters()
    this.getAutomationTemplatesTypes();
    this.getOperations();
    this.getOperationsStatus();
    this.getEmployees();
    this.getLangData();
    this.getGroupProfiles();
    this.getTaskStatus();
    this.getGroupsCompany();
    
    if (this.data.tmpl) {
      this.getTmpl();
    }
  }

  onSearchValues(resp) {
    if (!this.allValues) {
      return;
    }

    if (!resp) {
      this.allValues$.next(this.allValues.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.allValues$.next(
      this.allValues.filter(z => z.value.toLowerCase().indexOf(resp) > -1 || (z.parameter.name && z.parameter.name.toLowerCase().indexOf(resp) > -1))
    );
  }

  toggleVal(key) {
    (this.form.get('scenario_data') as FormGroup).patchValue({
      [key]: !this.form.get('scenario_data').get(key).value
    })
  }

  onSearchGroups(resp) {
    if (!this.groups) {
      return;
    }

    if (!resp) {
      this.groups$.next(this.groups.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }

    this.groups$.next(
      this.groups.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  getChecklistsById(id) {
    if (!this.checklists || !this.checklists.find(el => el.id == id)) {
      return false;
    }
    return this.checklists.find(el => el.id == id)
  }  

  getGroupById(id) {
    if (!this.groups || !this.groups.find(el => el.id == id)) {
      return false;
    }
    return this.groups.find(el => el.id == id)
  }  

  onSearchTemplates(resp) {
    if (!this.templates) {
      return;
    }

    if (!resp) {
      this.templates$.next(this.templates.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.templates$.next(
      this.templates.filter(z => z.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  // getAutomationTemplates() {
  //   this.attachSubscriptions(
  //     this.scenariosService.getAutomationTemplates('1', {company_id: this.data.company.id}, '200').pipe(
  //       map(el => el.body)
  //     ).subscribe(resp => {
  //       console.log("getAutomationTemplates", resp);
  //       this.templates = resp;
  //       this.templates$.next(this.templates.slice());
  //     })
  //   )
  // }

  getAutomationTemplates() {
    this.attachSubscriptions(
      this.scenariosService.getAutomationTemplates('1', {company_id: this.data.company.id, is_task_operation: 1}, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.scenariosService.getAutomationTemplates(x, {company_id: this.data.company.id, is_task_operation: 1}, '200').pipe(map(u => u.body)))).pipe(
            last(),
            tap(values => {
              this.templates = [].concat(...values)
              console.log("getAutomationTemplates", this.templates)
              this.templates$.next(this.templates.slice());
            }),
          )
        }),
      ).subscribe(resp => {
        console.log("getAutomationTemplates sub", resp);
      })
    )
  }

  onSearchPartners(resp) {
    if (!this.partners) {
      return;
    }

    if (!resp) {
      this.partners$.next(this.partners.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.partners$.next(
      this.partners.filter(z => z.partnerCompany.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  getPartners() {
    this.attachSubscriptions(
      this.companyService.getPartners({company_id: this.data.company.id}).subscribe(resp => {
        this.partners = resp.filter(x => x.partner_company_id != 0);
        this.partners$.next(this.partners.slice())
        console.log("getPartners", this.partners)
      })
    )
  }

  getAllChecklists() {
    this.attachSubscriptions(
      this.taskService.getOperationReminder(this.data.company.id, {}, '1', '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.taskService.getOperationReminder(this.data.company.id, {}, x).pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        this.checklists = [].concat(...res);
        this.checklists$.next(this.checklists.slice());
      })
    )
  }

  getPartnerById(id) {
    if (!this.partners || this.partners.filter(el => el.partner_company_id == id).length == 0) {
      return false;
    }
    return this.partners.find(el => el.partner_company_id == id)
  }  

  addJob() {

    let newJob = {
      status_id: 1,
      operation_id: this.operations[0].id,
      parameter_values_ids: []
    }

    this.jobs.push(newJob)
  }

  getProfilesStatus() {
    this.attachSubscriptions(
      this.globalDataService.getContentStatus().subscribe(resp => {
        console.log("getContentStatus", resp);
        this.profileStatuses = resp.slice();
      })
    )
  }

  getGroupProfiles() {
    this.attachSubscriptions(
      this.companyService.getProfiles(this.company_id).pipe(
        map(x => x.body),
        tap(arr => {
          if (this.data.tmpl) {
            arr.forEach(item => {
              if (this.data.tmpl.scenario_data.channel_to_employee && this.data.tmpl.scenario_data.channel_to_employee.hasOwnProperty(item.id)) {
                item.employees_ids = this.data.tmpl.scenario_data.channel_to_employee[item.id].employees_ids
              } else {
                item.employees_ids = []
              }

              if (this.data.tmpl.scenario_data.channel_to_parameter_value && this.data.tmpl.scenario_data.channel_to_parameter_value.hasOwnProperty(item.id)) {
                let val = this.data.tmpl.scenario_data.channel_to_parameter_value[item.id].is_common_parameter_value
                item.is_common_parameter_value = val == 1 ? true : false
              } else {
                item.is_common_parameter_value = 0
              }
            })
          } else {
            arr.forEach(item => {
              item.employees_ids = []
            })
          }
          
        })
      ).subscribe(resp => {
        console.log("getProfiles ------- ", resp)
        this.profiles = resp;
      })
    )
  }


  toggleEmpl(empl: any, profile) {
    console.log("toggleEmpl", empl, profile)
    if (!!profile.employees_ids && profile.employees_ids.includes(empl.id)) {
      let val = [...profile.employees_ids]
      val.splice(profile.employees_ids.indexOf(empl.id), 1)
      profile.employees_ids = val;
    } else {
      if (!!profile.employees_ids) {
        let val = [...profile.employees_ids]
        val.push(empl.id)
        profile.employees_ids = val;
      } else {
        profile.employees_ids = [empl.id]
      }
    }

  }

  changeCreateDiscussionFlag(e) {
    console.log(e);
    let scenarioData = this.form.controls.scenario_data as FormGroup;
    if (e.checked) {
      scenarioData.addControl("discussion_name_template", this.fb.control("Publish to {channel_name} ({platform_name})"))
    } else {
      scenarioData.removeControl("discussion_name_template")
    }
    this.form.updateValueAndValidity();

    console.log("changeCreateDiscussionFlag", this.form.value)
  }

  getParamById(id) {
    return this.parameters && this.parameters.find(x => x.id == id)
  }

  getFolderByLoc(loc) {
    return this.folders && this.folders.find(x => x.location == loc)
  }

  getTaskStatusesById(id) {
    return this.taskStatuses && this.taskStatuses.find(x => x.id == id)
  }

  getOperationsById(id) {
    return this.operations && this.operations.find(x => x.id == id)
  }
  getOperationStatusesById(id) {
    return this.operationStatuses && this.operationStatuses.find(x => x.id == id)
  }

  getValuesById(id) {
    return this.allValues && this.allValues.find(x => x.id == id)
  }

  log() {
    console.log(this.data.tmpl)
    console.log(this.form.value)
    console.log(this.sources)
    console.log("sbmtEmpl", this.sbmtEmpl)
    console.log("sbmtEmplIds", this.sbmtEmplIds)
    console.log("sbmtPartners", this.sbmtPartners)
    console.log("sbmtPartnersIds", this.sbmtPartnersIds)
  }

  getTmpl() {
    console.log("getTmpl", this.data.tmpl)
    console.log("getTmpl scheme", JSON.parse(this.data.tmpl.scheme_data))
    this.form.patchValue({
      name: this.data.tmpl.name,
      scenario_name: this.data.tmpl.scenario_name,
      is_task_operation: this.data.tmpl.is_task_operation
    })

    if (this.data.tmpl.scenario_name == "TaskOperationFilesCollector") {
      this.sources = this.data.tmpl.scenario_data.sources;
    }

    if (this.data.tmpl.scenario_name == "TaskOperationContentPublisher") {
      this.form.get('scenario_data').patchValue({
        status_ids: !!this.data.tmpl.scenario_data.status_ids ? this.data.tmpl.scenario_data.status_ids : [],
        channel_parameters_ids: !!this.data.tmpl.scenario_data.channel_parameters_ids ? this.data.tmpl.scenario_data.channel_parameters_ids : [],
        min_publish_period: !!this.data.tmpl.scenario_data.min_publish_period ? this.data.tmpl.scenario_data.min_publish_period / 60 : 0,
        is_execute_after_task_channel_created: !!this.data.tmpl.scenario_data.is_execute_after_task_channel_created,
        is_content_video: !!this.data.tmpl.scenario_data.is_content_video,
        is_file_document_json_auto_post: !!this.data.tmpl.scenario_data.is_file_document_json_auto_post,
        is_file_photo_thumbnail: !!this.data.tmpl.scenario_data.is_file_photo_thumbnail,
        is_execute_after_file_created: !!this.data.tmpl.scenario_data.is_execute_after_file_created,
        file_content_parameters_ids: !!this.data.tmpl.scenario_data.file_content_parameters_ids ? this.data.tmpl.scenario_data.file_content_parameters_ids : [],
        content_plan_and_task_parameters_ids: !!this.data.tmpl.scenario_data.content_plan_and_task_parameters_ids ? this.data.tmpl.scenario_data.content_plan_and_task_parameters_ids : [],
        content_plan_and_task_operation_parameters_ids: !!this.data.tmpl.scenario_data.content_plan_and_task_operation_parameters_ids ? this.data.tmpl.scenario_data.content_plan_and_task_operation_parameters_ids : [],
        content_plan_and_file_parameters_ids: !!this.data.tmpl.scenario_data.content_plan_and_file_parameters_ids ? this.data.tmpl.scenario_data.content_plan_and_file_parameters_ids : [],
        is_file_content_without_parameters: !!this.data.tmpl.scenario_data.is_file_content_without_parameters,
        file_document_parameters_ids: !!this.data.tmpl.scenario_data.file_document_parameters_ids ? this.data.tmpl.scenario_data.file_document_parameters_ids : [],
        is_file_document_without_parameters: !!this.data.tmpl.scenario_data.is_file_document_without_parameters,
        file_thumbnail_parameters_ids: !!this.data.tmpl.scenario_data.file_thumbnail_parameters_ids ? this.data.tmpl.scenario_data.file_thumbnail_parameters_ids : [],
        is_file_thumbnail_without_parameters: !!this.data.tmpl.scenario_data.is_file_thumbnail_without_parameters,
        task_channel_channel_parameters_ids: !!this.data.tmpl.scenario_data.task_channel_channel_parameters_ids ? this.data.tmpl.scenario_data.task_channel_channel_parameters_ids : [],
        is_task_channel_channel_without_parameters: !!this.data.tmpl.scenario_data.is_task_channel_channel_without_parameters,
        is_task_channel_data: !!this.data.tmpl.scenario_data.is_task_channel_data,
        published_status_id: this.data.tmpl.scenario_data.published_status_id || 0,
      })
    }

    if (this.data.tmpl.scenario_name == "TaskOperationChain") {
      this.form.get('scenario_data').patchValue({
        status_id: this.data.tmpl.scenario_data.status_id,
        operation_id: this.data.tmpl.scenario_data.operation_id,
        task_operation_automation_templates_ids: !!this.data.tmpl.scenario_data.task_operation_automation_templates_ids ? this.data.tmpl.scenario_data.task_operation_automation_templates_ids : [],
        employees_ids: !!this.data.tmpl.scenario_data.employees_ids ? this.data.tmpl.scenario_data.employees_ids : [],
        partner_companies_ids: !!this.data.tmpl.scenario_data.partner_companies_ids ? this.data.tmpl.scenario_data.partner_companies_ids : [],
        task_operation_parameters_ids: this.data.tmpl.scenario_data.task_operation_parameters_ids,
        skip_task_channel_status: !!this.data.tmpl.scenario_data.skip_task_channel_status ? this.data.tmpl.scenario_data.skip_task_channel_status : [],
        is_copy_sources_files: !!this.data.tmpl.scenario_data.is_copy_sources_files ? this.data.tmpl.scenario_data.is_copy_sources_files : 0,
        is_copy_to_approve_files: !!this.data.tmpl.scenario_data.is_copy_to_approve_files ? this.data.tmpl.scenario_data.is_copy_to_approve_files : 0,
        is_copy_project_files: !!this.data.tmpl.scenario_data.is_copy_project_files ? this.data.tmpl.scenario_data.is_copy_project_files : 0,
        is_copy_approved_files: this.data.tmpl.scenario_data.is_copy_approved_files,
        is_copy_ready_files: this.data.tmpl.scenario_data.is_copy_ready_files,
        is_create_discussions: this.data.tmpl.scenario_data.is_create_discussions,
        is_task_operation_one: this.data.tmpl.scenario_data.is_task_operation_one,
        is_confirm_before_execute: this.data.tmpl.scenario_data.is_confirm_before_execute,
        is_confirm_multiple_employees: this.data.tmpl.scenario_data.is_confirm_multiple_employees,
        is_skip_task_channel_with_url: this.data.tmpl.scenario_data.is_skip_task_channel_with_url,
        is_task_operation_one_per_template: this.data.tmpl.scenario_data.is_task_operation_one_per_template,
        is_discussion_one: this.data.tmpl.scenario_data.is_discussion_one,
        is_discussion_one_per_template: this.data.tmpl.scenario_data.is_discussion_one_per_template,
        is_link_discussion_status_to_task_channel: this.data.tmpl.scenario_data.is_link_discussion_status_to_task_channel,
        is_execute_after_task_channel_created: this.data.tmpl.scenario_data.is_execute_after_task_channel_created,
        is_automation_employee_position_task_employee: !!this.data.tmpl.scenario_data.is_automation_employee_position_task_employee,
        is_automation_employee_position_task_employee_assistant: !!this.data.tmpl.scenario_data.is_automation_employee_position_task_employee_assistant,
        is_automation_employee_position_task_client_principal: !!this.data.tmpl.scenario_data.is_automation_employee_position_task_client_principal,
        is_automation_employee_position_task_client: !!this.data.tmpl.scenario_data.is_automation_employee_position_task_client,
        is_automation_employee_position_task_partner: !!this.data.tmpl.scenario_data.is_automation_employee_position_task_partner,
        is_automation_employee_position_task_partner_assistant: !!this.data.tmpl.scenario_data.is_automation_employee_position_task_partner_assistant,
        is_automation_employee_position_task_partner_client_principal: !!this.data.tmpl.scenario_data.is_automation_employee_position_task_partner_client_principal,
        is_automation_employee_position_task_partner_client: !!this.data.tmpl.scenario_data.is_automation_employee_position_task_partner_client,
        is_inherit_task_employee: !!this.data.tmpl.scenario_data.is_inherit_task_employee,
        is_inherit_task_employee_assistant: !!this.data.tmpl.scenario_data.is_inherit_task_employee_assistant,
        is_inherit_task_client_principal: !!this.data.tmpl.scenario_data.is_inherit_task_client_principal,
        is_inherit_task_client: !!this.data.tmpl.scenario_data.is_inherit_task_client,
        is_inherit_task_partner: !!this.data.tmpl.scenario_data.is_inherit_task_partner,
        is_inherit_task_partner_assistant: !!this.data.tmpl.scenario_data.is_inherit_task_partner_assistant,
        is_inherit_task_partner_client_principal: !!this.data.tmpl.scenario_data.is_inherit_task_partner_client_principal,
        is_inherit_task_partner_client: !!this.data.tmpl.scenario_data.is_inherit_task_partner_client
      })

      if (this.data.tmpl.scenario_data.task_operation_employees) {
        this.data.tmpl.scenario_data.task_operation_employees.forEach(obj => {
          this.sbmtEmpl.push(obj);
        })
        this.sbmtEmplIds = this.sbmtEmpl.map(x => x.employee_id);
      } else if (this.data.tmpl.scenario_data.employees_ids) {
        this.data.tmpl.scenario_data.employees_ids.forEach(id => {
          let x = {
            employee_id: id,
            is_manager:1
          }
          this.sbmtEmpl.push(x);
        })
        this.sbmtEmplIds = this.sbmtEmpl.map(x => x.employee_id);
      }

      if (this.data.tmpl.scenario_data.task_operation_partners) {
        this.data.tmpl.scenario_data.task_operation_partners.forEach(obj => {
          this.sbmtPartners.push(obj);
        })
        this.sbmtPartnersIds = this.sbmtPartners.map(x => x.partner_company_id);
      } else if (this.data.tmpl.scenario_data.partner_companies_ids) {
        this.data.tmpl.scenario_data.partner_companies_ids.forEach(id => {
          let x = {
            partner_company_id: id,
            is_manager:1
          }
          this.sbmtPartners.push(x);
        })
        this.sbmtPartnersIds = this.sbmtPartners.map(x => x.partner_company_id);
      }

      if (this.data.tmpl.scenario_data.task_operation_clients && this.data.tmpl.scenario_data.task_operation_clients.length) {
        this.data.tmpl.scenario_data.task_operation_clients.forEach(obj => {
          if (!!obj.hasOwnProperty('client_employee_id')) {
            this.clients.push(obj);
          } else {
            this.partnerClients.push(obj);
          }
        })
      } else {
        if (this.data.tmpl.scenario_data.client_employee_ids && this.data.tmpl.scenario_data.client_employee_ids.length) {
          this.data.tmpl.scenario_data.client_employee_ids.forEach(id => {
            this.clients.push({
              client_employee_id: id,
              is_principal: 0
            });
          })
        }
        if (this.data.tmpl.scenario_data.client_partner_company_ids && this.data.tmpl.scenario_data.client_partner_company_ids.length) {
          this.data.tmpl.scenario_data.client_partner_company_ids.forEach(id => {
            this.partnerClients.push({
              client_partner_company_id: id,
              is_principal: 0
            });
          })
        }
      }
  
      this.clients_ids = this.clients.map(x => x.client_employee_id);
      this.partnerClients_ids = this.partnerClients.map(x => x.client_partner_company_id);
  
      if (this.data.tmpl.scenario_data.task_operations) {
        this.data.tmpl.scenario_data.task_operations.forEach(x => {
          if (!x.hasOwnProperty('parameter_values_ids')) {
            x.parameter_values_ids = []
          }
        })
        this.jobs = this.data.tmpl.scenario_data.task_operations;
      }
  
      
      let scenarioData = this.form.controls.scenario_data as FormGroup;
      if (!!this.data.tmpl.scenario_data.is_create_discussions) {
        scenarioData.addControl("discussion_name_template", this.fb.control(this.data.tmpl.scenario_data.discussion_name_template))
      }
      this.form.updateValueAndValidity();
  
      console.log("this.data", this.data.tmpl)
      console.log("this.form", this.form.value)
    }
  }

  
  includesCli(id) {
    return this.clients.filter(x => x.client_employee_id == id).length > 0;
  }

  includesEmpl(id) {
    return this.sbmtEmpl.filter(x => x.employee_id == id).length > 0;
  }
  
  setCli(e, id, val) {
    e.preventDefault();
    e.stopPropagation();
    this.clients.find(x => x.client_employee_id == id).is_principal = val
  }

  setEmpl(e, id, val) {
    e.preventDefault();
    e.stopPropagation();
    this.sbmtEmpl.find(x => x.employee_id == id).is_manager = val
  }

  toggleEmplChip = (chip: any) => {
    console.log("toggleEmplChip", chip)
    let id = !!chip.id ? chip.id : null;
    if (!!id) {
      if (this.sbmtEmpl.filter(x => x.employee_id == id).length) {
        this.sbmtEmpl.splice(this.sbmtEmpl.indexOf(this.sbmtEmpl.find(x => x.employee_id == id)), 1)
      } else {
        this.sbmtEmpl.push({
          employee_id: id,
          is_manager: 1
        })
      }
    }

    this.sbmtEmplIds = this.sbmtEmpl.map(x => x.employee_id);

    // if (!chip.id) {
    //   this.cliSwitch = true;

    //   setTimeout(() => {
    //     this.cliSwitch = false;
    //   }, 0)
    // }
  }

  togglePartnerChip = (chip: any) => {
    console.log("togglePartnerChip", chip)
    let id = !!chip.partner_company_id ? chip.partner_company_id : null;
    if (!!id) {
      if (this.sbmtPartners.filter(x => x.partner_company_id == id).length) {
        this.sbmtPartners.splice(this.sbmtPartners.indexOf(this.sbmtPartners.find(x => x.partner_company_id == id)), 1)
      } else {
        this.sbmtPartners.push({
          partner_company_id: id,
          is_manager: 1
        })
      }
    }

    this.sbmtPartnersIds = this.sbmtPartners.map(x => x.partner_company_id);

    // if (!chip.id) {
    //   this.cliSwitch = true;

    //   setTimeout(() => {
    //     this.cliSwitch = false;
    //   }, 0)
    // }
  }

  toggleSelectEmplChip(e) {
    console.log('toggleSelectClientChip', e)

    this.sbmtEmpl.forEach((obj, ind) => {
      if (!this.sbmtEmplIds.filter(id => obj.employee_id == id).length) {
        this.sbmtEmpl.splice(ind, 1)
      }
    });

    this.sbmtEmplIds.forEach((id, ind) => {
      if (!this.sbmtEmpl.filter(x => x.employee_id == id).length) {
        this.sbmtEmpl.push({
          employee_id: id,
          is_manager: 1
        })
      }
    });

    console.log(this.sbmtEmplIds);
    console.log(this.sbmtEmpl);
  }

  toggleSelectPartnerChip(e) {
    console.log('toggleSelectClientChip', e)

    this.sbmtPartners.forEach((obj, ind) => {
      if (!this.sbmtPartnersIds.filter(id => obj.partner_company_id == id).length) {
        this.sbmtPartners.splice(ind, 1)
      }
    });

    this.sbmtPartnersIds.forEach((id, ind) => {
      if (!this.sbmtPartners.filter(x => x.partner_company_id == id).length) {
        this.sbmtPartners.push({
          partner_company_id: id,
          is_manager: 1
        })
      }
    });

    console.log(this.sbmtPartnersIds);
    console.log(this.sbmtPartners);
  }

  setCliPartn(e, id, val) {
    e.preventDefault();
    e.stopPropagation();
    this.partnerClients.find(x => x.client_partner_company_id == id).is_principal = val
  }
  setPartner(e, id, val) {
    e.preventDefault();
    e.stopPropagation();
    this.sbmtPartners.find(x => x.partner_company_id == id).is_manager = val
  }

  getCli(id) {
    return this.clients.find(x => x.client_employee_id == id);
  }

  getEmpl(id) {
    return this.sbmtEmpl.find(x => x.employee_id == id);
  }

  getPartner(id) {
    return this.sbmtPartners.find(x => x.partner_company_id == id);
  }
  
  getCliPartn(id) {
    return this.partnerClients.find(x => x.client_partner_company_id == id);
  }

  includesCliPartn(id) {
    return this.partnerClients.filter(x => x.client_partner_company_id == id).length > 0;
  }

  includesPartner(id) {
    return this.sbmtPartners.filter(x => x.partner_company_id == id).length > 0;
  }

  toggleSelectClientChip(e) {
    console.log('toggleSelectClientChip', e)

    this.clients.forEach((obj, ind) => {
      if (!this.clients_ids.filter(id => obj.client_employee_id == id).length) {
        this.clients.splice(ind, 1)
      }
    });

    this.clients_ids.forEach((id, ind) => {
      if (!this.clients.filter(x => x.client_employee_id == id).length) {
        this.clients.push({
          client_employee_id: id,
          is_principal: 1
        })
      }
    });

    console.log(this.clients_ids);
    console.log(this.clients);
  }

  toggleSelectClientPartnerChip(e) {
    console.log('toggleSelectClientPartnerChip', e)

    this.partnerClients.forEach((obj, ind) => {
      if (!this.partnerClients_ids.filter(id => obj.client_partner_company_id == id).length) {
        this.partnerClients.splice(ind, 1)
      }
    });

    this.partnerClients_ids.forEach((id, ind) => {
      if (!this.partnerClients.filter(x => x.client_partner_company_id == id).length) {
        this.partnerClients.push({
          client_partner_company_id: id,
          is_principal: 1
        })
      }
    });

    console.log(this.partnerClients_ids);
    console.log(this.partnerClients);
  }

  toggleClientPartnerChip = (chip: any) => {
    console.log("toggleClientPartnerChip", chip)
    let id = !!chip.partner_company_id ? chip.partner_company_id : chip.value
    if (!!id) {
      if (this.partnerClients.filter(x => x.client_partner_company_id == id).length) {
        this.partnerClients.splice(this.partnerClients.indexOf(this.partnerClients.find(x => x.client_partner_company_id == id)), 1)
      } else {
        this.partnerClients.push({
          client_partner_company_id: id,
          is_principal: 1
        })
      }
    }
    
    this.partnerClients_ids = this.partnerClients.map(x => x.client_partner_company_id);
    // if (!chip.partner_company_id) {
    //   this.pCliSwitch = true;

    //   setTimeout(() => {
    //     this.pCliSwitch = false;
    //   }, 0)
    // }
  }

  toggleClientChip = (chip: any) => {
    console.log("toggleClientChip", chip)
    let id = !!chip.id ? chip.id : null;
    if (!!id) {
      if (this.clients.filter(x => x.client_employee_id == id).length) {
        this.clients.splice(this.clients.indexOf(this.clients.find(x => x.client_employee_id == id)), 1)
      } else {
        this.clients.push({
          client_employee_id: id,
          is_principal: 1
        })
      }
    }

    this.clients_ids = this.clients.map(x => x.client_employee_id);

    // if (!chip.id) {
    //   this.cliSwitch = true;

    //   setTimeout(() => {
    //     this.cliSwitch = false;
    //   }, 0)
    // }
  }

  onSearchEmoloyees(resp) {
    if (!this.employees) {
      return;
    }

    if (!resp) {
      this.employees$.next(this.employees.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.employees$.next(
      this.employees.filter(z => z.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  toggleChipTemplate = (chip: any) => {
    if (!!chip.id) {
      if (!!this.form.get('scenario_data').value.task_operation_automation_templates_ids && this.form.get('scenario_data').value.task_operation_automation_templates_ids.includes(chip.id)) {
        let val = [...this.form.get('scenario_data').value.task_operation_automation_templates_ids]
        val.splice(this.form.get('scenario_data').value.task_operation_automation_templates_ids.indexOf(chip.id), 1)
        this.form.get('scenario_data').patchValue({
          task_operation_automation_templates_ids: val
        })
      } else {
        if (!!this.form.get('scenario_data').value.task_operation_automation_templates_ids) {
          let val = [...this.form.get('scenario_data').value.task_operation_automation_templates_ids]
          console.log("chip.id", chip.id, chip)
          val.push(chip.id)
          this.form.get('scenario_data').patchValue({
            task_operation_automation_templates_ids: val
          })
        } else {
          console.log("chip.id", chip.id, chip)
          this.form.get('scenario_data').patchValue({
            task_operation_automation_templates_ids: [chip.id]
          })
        }
      }
    }

    console.log("chip.id", chip.id, chip)

    if (!chip.id) {
      this.scnSwitch = true;

      setTimeout(() => {
        this.scnSwitch = false;
      }, 0)
    }
  }

  logJobs() {
    console.log("this.jobs", this.jobs)
  }

  onSearchParams(resp) {
    if (!this.parameters) {
      return;
    }

    if (!resp) {
      this.parameters$.next(this.parameters.filter(z => !!z.id).slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.parameters$.next(
      this.parameters.filter(z => !!z.id && (z.name.toLowerCase().indexOf(resp) > -1))
    );
  }

  onSearchOperations(resp) {
    if (!this.operations) {
      return;
    }

    if (!resp) {
      this.operations$.next(this.operations.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.operations$.next(
      this.operations.filter(z => (z.name.toLowerCase().indexOf(resp) > -1))
    );
  }

  selectAutomationType(type) {
    this.form.patchValue({
      scenario_name: type
    })
  }

  selectProfileStatus(typeId) {
    console.log(typeId)
    // console.log(this.form.get('scenario_data').value.skip_task_channel_status)
    // console.log(this.form.get('scenario_data').value.skip_task_channel_status.includes(typeId))
    // return;
    if (!!this.form.get('scenario_data').value.skip_task_channel_status && this.form.get('scenario_data').value.skip_task_channel_status.includes(typeId)) {
      let val = [...this.form.get('scenario_data').value.skip_task_channel_status]
      val.splice(this.form.get('scenario_data').value.skip_task_channel_status.indexOf(typeId), 1)
      this.form.get('scenario_data').patchValue({
        skip_task_channel_status: val
      })
    } else {
      let val = [...this.form.get('scenario_data').value.skip_task_channel_status]
      val.push(typeId)
      this.form.get('scenario_data').patchValue({
        skip_task_channel_status: val
      })
    }

    console.log(this.form.get('scenario_data').value.skip_task_channel_status)
  }

  selectType(type) {
    this.form.get("scenario_data").patchValue({
      operation_id: !!type.id ? type.id : type.value
    })
  }

  selectStatus(status) {
    this.form.get("scenario_data").patchValue({
      status_id: status.id
    })
  }

  getEmployeeById(id) {
    return this.employees && this.employees.find(x => x.id == id)
  }

  toggleAddPartnerChip = (chip: any) => {
    if (!!chip.partner_company_id) {
      if (!!this.form.get('scenario_data').value.partner_companies_ids && this.form.get('scenario_data').value.partner_companies_ids.includes(chip.partner_company_id)) {
        let val = [...this.form.get('scenario_data').value.partner_companies_ids]
        val.splice(this.form.get('scenario_data').value.partner_companies_ids.indexOf(chip.partner_company_id), 1)
        this.form.get('scenario_data').patchValue({
          partner_companies_ids: val
        })
      } else {
        if (!!this.form.get('scenario_data').value.partner_companies_ids) {
          let val = [...this.form.get('scenario_data').value.partner_companies_ids]
          console.log("chip.partner_company_id", chip.partner_company_id, chip)
          val.push(chip.partner_company_id)
          this.form.get('scenario_data').patchValue({
            partner_companies_ids: val
          })
        } else {
          console.log("chip.partner_company_id", chip.partner_company_id, chip)
          this.form.get('scenario_data').patchValue({
            partner_companies_ids: [chip.partner_company_id]
          })
        }
      }
    }

    console.log("chip.partner_company_id", chip.partner_company_id, chip)

    if (!chip.partner_company_id) {
      this.partnerSwitch = true;

      setTimeout(() => {
        this.partnerSwitch = false;
      }, 0)
    }
  }
  
  // toggleCliAddPartnerChip = (chip: any) => {
  //   if (!!chip.partner_company_id) {
  //     if (!!this.form.get('scenario_data').value.client_partner_company_ids && this.form.get('scenario_data').value.client_partner_company_ids.includes(chip.partner_company_id)) {
  //       let val = [...this.form.get('scenario_data').value.client_partner_company_ids]
  //       val.splice(this.form.get('scenario_data').value.client_partner_company_ids.indexOf(chip.partner_company_id), 1)
  //       this.form.get('scenario_data').patchValue({
  //         client_partner_company_ids: val
  //       })
  //     } else {
  //       if (!!this.form.get('scenario_data').value.client_partner_company_ids) {
  //         let val = [...this.form.get('scenario_data').value.client_partner_company_ids]
  //         console.log("chip.partner_company_id", chip.partner_company_id, chip)
  //         val.push(chip.partner_company_id)
  //         this.form.get('scenario_data').patchValue({
  //           client_partner_company_ids: val
  //         })
  //       } else {
  //         console.log("chip.partner_company_id", chip.partner_company_id, chip)
  //         this.form.get('scenario_data').patchValue({
  //           client_partner_company_ids: [chip.partner_company_id]
  //         })
  //       }
  //     }
  //   }

  //   console.log("chip.partner_company_id", chip.partner_company_id, chip)

  //   if (!chip.partner_company_id) {
  //     this.cliPartnerSwitch = true;

  //     setTimeout(() => {
  //       this.cliPartnerSwitch = false;
  //     }, 0)
  //   }
  // }

  toggleAddChip = (chip: any) => {
    if (!!chip.id) {
      if (!!this.form.get('scenario_data').value.employees_ids && this.form.get('scenario_data').value.employees_ids.includes(chip.id)) {
        let val = [...this.form.get('scenario_data').value.employees_ids]
        val.splice(this.form.get('scenario_data').value.employees_ids.indexOf(chip.id), 1)
        this.form.get('scenario_data').patchValue({
          employees_ids: val
        })
      } else {
        if (!!this.form.get('scenario_data').value.employees_ids) {
          let val = [...this.form.get('scenario_data').value.employees_ids]
          val.push(chip.id)
          this.form.get('scenario_data').patchValue({
            employees_ids: val
          })
        } else {
          this.form.get('scenario_data').patchValue({
            employees_ids: [chip.id]
          })
        }
      }
    }

    console.log("chip.id", chip.id, chip)

    if (!chip.id) {
      this.emplSwitch = true;

      setTimeout(() => {
        this.emplSwitch = false;
      }, 0)
    }
  }

  toggleCliAddChip = (chip: any) => {
    if (!!chip.id) {
      if (!!this.form.get('scenario_data').value.client_employee_ids && this.form.get('scenario_data').value.client_employee_ids.includes(chip.id)) {
        let val = [...this.form.get('scenario_data').value.client_employee_ids]
        val.splice(this.form.get('scenario_data').value.client_employee_ids.indexOf(chip.id), 1)
        this.form.get('scenario_data').patchValue({
          client_employee_ids: val
        })
      } else {
        if (!!this.form.get('scenario_data').value.client_employee_ids) {
          let val = [...this.form.get('scenario_data').value.client_employee_ids]
          val.push(chip.id)
          this.form.get('scenario_data').patchValue({
            client_employee_ids: val
          })
        } else {
          this.form.get('scenario_data').patchValue({
            client_employee_ids: [chip.id]
          })
        }
      }
    }

    console.log("chip.id", chip.id, chip)

    if (!chip.id) {
      this.cliEmplSwitch = true;

      setTimeout(() => {
        this.cliEmplSwitch = false;
      }, 0)
    }
  }

  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData().subscribe(resp => {
        this._adapter.setLocale(resp.active);
      })
    )
  }
  
  getCompany() {
    this.attachSubscriptions(
      this.companyService.getCompany(this.data.company.id).subscribe(resp => {
        this.companyService.company$.next(resp[0]);
      })
    )
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  onPartnerImgError(event){
    event.target.src = this.imgRoute+'/assets/img/partner.png'
  }
  
  getCsrf() {
    this.attachSubscriptions(
      this.auth.$userToken.subscribe(resp => {
        this.user_id = resp.user_id;
        this.sm.localStorageSetItem("csrf_param", resp.csrf_param)
        this.sm.localStorageSetItem("csrf_token", resp.csrf_token)
      })
    )
  }
  
  // getEmployees() {
  //   this.attachSubscriptions(
  //     this.taskService.getEmployees(this.data.company.id).subscribe(resp => {
  //       this.employees = resp;
  //       this.employees$.next(this.employees.slice())
  //     })
  //   )
  // }
  getEmployees() {
    this.attachSubscriptions(
      this.taskService.getEmployeesDyn('1', this.data.company.id, null, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }
  
          return forkJoin(arr.map(x => this.taskService.getEmployeesDyn(x, this.data.company.id, null, '200').pipe(map(u => u.body)))).pipe(
            last(),
            tap(values => {
              let conValues = [].concat(...values)
              this.employees = conValues;
              this.employees$.next(this.employees.slice())
            }),
          )
        }),
      ).subscribe(resp => {
        console.log("getEmployees resp", resp);
        console.log("getEmployees", this.employees);
      })
    )
  }

  
  getAutomationTemplatesTypes() {
    this.attachSubscriptions(
      this.scenariosService.getAutomationTemplatesTypes().subscribe(resp => {
        this.automationType = resp;
      })
    )
  }

  getOperations() {
    this.attachSubscriptions(
      this.taskService.getOperations(this.data.company.id).subscribe(resp => {
        this.operations = resp;
        this.operations$.next(this.operations.slice())
      })
    )
  }

  getOperationsStatus() {
    this.attachSubscriptions(
      this.taskService.getOperationsStatus().subscribe(resp => {
        this.operationStatuses = resp.slice();
      })
    )
  }

  getTaskStatus() {
    this.attachSubscriptions(
      this.taskService.getTaskStatuses(this.company_id).subscribe(resp => {
        console.log("getTaskStatuses", resp)
        this.taskStatuses = resp;
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  deleteJob(i) {
    this.jobs.splice(i,1);
  }

  getParameters() {
    this.attachSubscriptions(
      this.parametersService.getApiParameters(this.data.company.id).pipe(
        switchMap(res => {
          return this.parametersService.getAllValues('1', this.data.company.id, null, '1').pipe(
            switchMap(el => {
              let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
              let arr = []
              for (let index = 1; index <= pages; index++) {
                arr.push(index)
              }
    
              return forkJoin(arr.map(x => this.parametersService.getAllValues(x, this.data.company.id).pipe(map(u => u.body)))).pipe(
                last(),
                tap(values => {
                  this.allValues = [].concat(...values)
                  this.allValues$.next(this.allValues.slice())
                  console.log("getAllApiParameterValues", this.allValues)
                }),
                map(() => res)
              )
            }),
            map(() => res)
          )
        }),
        tap(res => {
          res.unshift({
            company_id: this.data.company.id,
            created_at: 0,
            id: 0,
            is_new_value: 0,
            name: "All parameters",
            original_id: 0,
          })
          this.parameters = res;
          this.parameters$.next(this.parameters.filter(x => !!x.id).slice())
        }),
      ).subscribe(resp => {
        console.log("this.parameters", this.parameters)
        this.parameters.forEach(element => {
          if (element.id == 0) {
            element.values = this.allValues;
          } else {
            element.values = this.allValues.filter(u => u.parameter_id = element.id);
          }
          if (!(this.data.tmpl && !!this.data.tmpl.scenario_data.task_operation_parameter_values && !!this.data.tmpl.scenario_data.task_operation_parameter_values.length)) {
            if (this.data.tmpl && this.data.tmpl.scenario_data.task_operation_parameter_values_ids) {
              element.activeValues = element.values.filter(x => this.data.tmpl.scenario_data.task_operation_parameter_values_ids.includes(x.id))
            } else {
              element.activeValues = []
            }
          }
        });

        if (this.data.tmpl && !!this.data.tmpl.scenario_data.task_operation_parameter_values && !!this.data.tmpl.scenario_data.task_operation_parameter_values.length) {
          if (!this.parameters[0].activeValues) {
            this.parameters[0].activeValues = []
          }
          this.data.tmpl.scenario_data.task_operation_parameter_values.forEach(obj => {
            if (this.allValues && this.allValues.find(u => u.id == obj.parameter_value_id)) {
              let value = JSON.parse(JSON.stringify(this.allValues.find(u => u.id == obj.parameter_value_id), this.getCircularReplacer()));
              value.is_primary = !!obj.is_primary ? 1 : 0;
              this.parameters[0].activeValues.push(value)
            }
          });
        }
      })
    )
  }

  getGroupsCompany() {
    this.attachSubscriptions(
      this.companyService.getInfiniteGroupsCompany(this.company_id, '1', '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.companyService.getInfiniteGroupsCompany(this.company_id, x).pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        this.groups = [].concat(...res);
        this.groups$.next(this.groups.slice());
      })
    )
  }

  getCircularReplacer() {
    const ancestors:any = [];
    return function (key, value) {
      if (typeof value !== "object" || value === null) {
        return value;
      }
      // `this` is the object that value is contained in,
      // i.e., its direct parent.
      while (ancestors.length > 0 && ancestors.at(-1) !== this) {
        ancestors.pop();
      }
      if (ancestors.includes(value)) {
        return "[Circular]";
      }
      ancestors.push(value);
      return value;
    };
  }

  onRemove(tagID, ind) {
    this.parameters[0].activeValues.splice(ind, 1)
  }

  openTargetValues() {
    let initData:any = {
      company: this.data.company,
      auto: true,
      parameters: this.parameters
    }

    const dialogRef = this.dialog.open(TargetParametersComponent, {
      backdropClass: ['parameters_modal_backdrop'],
      panelClass: ['without_paddings_modal', 'parameters_modal'],
      data: initData
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result) {
          if (result.event == 'save') {
            this.parameters = result.data
          }
        }
      })
    )
  }

  deleteSource(i) {
    this.sources.splice(i, 1)
  }

  setSource(is_loc:boolean = false, source?) {
    let initData:any = {
      company: this.data.company,
      is_loc: is_loc,
      host: this.host,
      imgRoute: this.imgRoute,
      parameters: this.parameters,
      taskStatuses: this.taskStatuses,
      operations: this.operations,
      operationStatuses: this.operationStatuses,
      allValues: this.allValues,
      groups: this.groups,
      checklists: this.checklists,
      partners: this.partners,
      employees: this.employees,
      folders: this.folders
    }

    if (source) {
      if (!source.hasOwnProperty('is_skip_task_operation_parameters')) {
        source.is_skip_task_operation_parameters = 0;
      }
      if (!source.hasOwnProperty('is_none_task_operation_parameters')) {
        source.is_none_task_operation_parameters = 0;
      }
      if (!source.hasOwnProperty('task_operation_parameters_ids')) {
        source.task_operation_parameters_ids = [];
      }

      if (!source.hasOwnProperty('is_skip_file_parameters')) {
        source.is_skip_file_parameters = 0;
      }
      if (!source.hasOwnProperty('is_none_file_parameters')) {
        source.is_none_file_parameters = 0;
      }
      if (!source.hasOwnProperty('file_parameters_ids')) {
        source.file_parameters_ids = [];
      }
      if (!source.hasOwnProperty('file_or_parameter_values_ids')) {
        source.file_or_parameter_values_ids = [];
      }
      initData.source = source;
    } else {
      this.sources.push({
        'tasks_ids': [],
        'task_statuses_ids':[],
        'task_groups_ids':[],
        'task_parameter_values_ids' :[],
        'is_task_related': 1,
        'is_task_consist_of': 0,
        'is_task_part_of': 0,
        'is_task_parent': 0,
        'is_skip_task_operation_parameters': 0,
        'is_none_task_operation_parameters ': 0,
        'file_or_parameter_values_ids':[],
        'file_parameters_ids': [],
        'is_skip_file_parameters': 0,
        'is_none_file_parameters': 0,
        'task_operations_ids': [],
        'task_operation_operations_ids': [],
        'task_operation_statuses_ids': [],
        'task_operation_employees_ids': [],
        'task_operation_partner_companies_ids': [],
        'task_operation_parameters_ids': [],
        'task_operation_parameter_values_ids': [],
        'file_directories': [],
        'file_operation_reminders_ids': [],
        'file_parameter_values_ids': [],
        'destination_directory': '/'
      })
      initData.source = this.sources[this.sources.length - 1];
    }

    const dialogRef = this.dialog.open(SetEditSourceComponent, {
      backdropClass: ['auto_back_modal'],
      panelClass: ['auto_panel_modal'],
      data: initData
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log(result)
        if (!!result) {
          // if (result.event == 'change') {
          //   if (source) {
          //     this.sources.push(result.data);
          //   } else {
          //     this.sources.push(result.data);
          //   }
          // }
        }
      })
    )
  }

  onSubmit() {
    if (!this.form.valid) {
      this.submited = true;
      this.layoutService.showSnackBar({name: ''}, marker("You need to fill in all required fields"), SnackBarItem)
      return
    }
    this.submited = false;
    this.isSubmit = true;
    let formData = {...this.form.value}

    if (formData.scenario_name == 'TaskOperationChain') {
      formData.scenario_data.task_operations = this.jobs;
      formData.scenario_data.is_copy_sources_files = !!formData.scenario_data.is_copy_sources_files ? 1 : 0;
      formData.scenario_data.is_copy_to_approve_files = !!formData.scenario_data.is_copy_to_approve_files ? 1 : 0;
      formData.scenario_data.is_copy_project_files = !!formData.scenario_data.is_copy_project_files ? 1 : 0;
      formData.scenario_data.is_copy_approved_files = !!formData.scenario_data.is_copy_approved_files ? 1 : 0;
      formData.scenario_data.is_copy_ready_files = !!formData.scenario_data.is_copy_ready_files ? 1 : 0;
      formData.scenario_data.is_task_operation_one = !!formData.scenario_data.is_task_operation_one ? 1 : 0;
      formData.scenario_data.is_confirm_before_execute = !!formData.scenario_data.is_confirm_before_execute ? 1 : 0;
      formData.scenario_data.is_confirm_multiple_employees = !!formData.scenario_data.is_confirm_multiple_employees ? 1 : 0;
      formData.scenario_data.is_skip_task_channel_with_url = !!formData.scenario_data.is_skip_task_channel_with_url ? 1 : 0;
      formData.scenario_data.is_task_operation_one_per_template = !!formData.scenario_data.is_task_operation_one_per_template ? 1 : 0;
      formData.scenario_data.is_discussion_one = !!formData.scenario_data.is_discussion_one ? 1 : 0;
      formData.scenario_data.is_discussion_one_per_template = !!formData.scenario_data.is_discussion_one_per_template ? 1 : 0;
      formData.scenario_data.is_link_discussion_status_to_task_channel = !!formData.scenario_data.is_link_discussion_status_to_task_channel ? 1 : 0;
      formData.scenario_data.is_execute_after_task_channel_created = !!formData.scenario_data.is_execute_after_task_channel_created ? 1 : 0;

      formData.scenario_data.is_automation_employee_position_task_employee = !!formData.scenario_data.is_automation_employee_position_task_employee ? 1 : 0;
      formData.scenario_data.is_automation_employee_position_task_employee_assistant = !!formData.scenario_data.is_automation_employee_position_task_employee_assistant ? 1 : 0;
      formData.scenario_data.is_automation_employee_position_task_client_principal = !!formData.scenario_data.is_automation_employee_position_task_client_principal ? 1 : 0;
      formData.scenario_data.is_automation_employee_position_task_client = !!formData.scenario_data.is_automation_employee_position_task_client ? 1 : 0;
      formData.scenario_data.is_automation_employee_position_task_partner = !!formData.scenario_data.is_automation_employee_position_task_partner ? 1 : 0;
      formData.scenario_data.is_automation_employee_position_task_partner_assistant = !!formData.scenario_data.is_automation_employee_position_task_partner_assistant ? 1 : 0;
      formData.scenario_data.is_automation_employee_position_task_partner_client_principal = !!formData.scenario_data.is_automation_employee_position_task_partner_client_principal ? 1 : 0;
      formData.scenario_data.is_automation_employee_position_task_partner_client = !!formData.scenario_data.is_automation_employee_position_task_partner_client ? 1 : 0;

      formData.scenario_data.is_inherit_task_employee = !!formData.scenario_data.is_inherit_task_employee ? 1 : 0;
      formData.scenario_data.is_inherit_task_employee_assistant = !!formData.scenario_data.is_inherit_task_employee_assistant ? 1 : 0;
      formData.scenario_data.is_inherit_task_client_principal = !!formData.scenario_data.is_inherit_task_client_principal ? 1 : 0;
      formData.scenario_data.is_inherit_task_client = !!formData.scenario_data.is_inherit_task_client ? 1 : 0;
      formData.scenario_data.is_inherit_task_partner = !!formData.scenario_data.is_inherit_task_partner ? 1 : 0;
      formData.scenario_data.is_inherit_task_partner_assistant = !!formData.scenario_data.is_inherit_task_partner_assistant ? 1 : 0;
      formData.scenario_data.is_inherit_task_partner_client_principal = !!formData.scenario_data.is_inherit_task_partner_client_principal ? 1 : 0;
      formData.scenario_data.is_inherit_task_partner_client = !!formData.scenario_data.is_inherit_task_partner_client ? 1 : 0;

      if (formData.scenario_data.hasOwnProperty('employees_ids')) {
        delete formData.scenario_data.employees_ids
      } 
      if (formData.scenario_data.hasOwnProperty('partner_companies_ids')) {
        delete formData.scenario_data.partner_companies_ids
      } 
      formData.scenario_data.task_operation_employees = this.sbmtEmpl;
      formData.scenario_data.task_operation_partners = this.sbmtPartners;

      let allClients = [...this.clients, ...this.partnerClients]
  
      console.log("clients", this.clients)
      console.log("partnerClients", this.partnerClients)
      console.log("allClients", allClients)
      formData.scenario_data.task_operation_clients = allClients;
  
      if (!!formData.scenario_data.is_create_discussions) {
        formData.scenario_data.channel_to_employee = []
  
        this.profiles.forEach(p => {
          formData.scenario_data.channel_to_employee.push({
            channel_id: p.id,
            employees_ids: p.employees_ids
          })
        })
  
        formData.scenario_data.channel_to_parameter_value = []
  
        this.profiles.forEach(p => {
          formData.scenario_data.channel_to_parameter_value.push({
            channel_id: p.id,
            is_common_parameter_value: !!p.is_common_parameter_value ? '1' : '0'
          })
        })
      }
      formData.scenario_data.is_create_discussions = !!formData.scenario_data.is_create_discussions ? 1 : 0;
      
      // if (this.parameters && this.parameters[0] && this.parameters[0].activeValues && this.parameters[0].activeValues.length) {
      //   formData.scenario_data.task_operation_parameter_values_ids = this.parameters[0].activeValues.map(x => x.id)
      // } else {
      //   formData.scenario_data.task_operation_parameter_values_ids = []
      // }
  
      let activeVals = [];
      if (this.parameters && this.parameters[0]) {
        this.parameters[0].activeValues.forEach(val => {
          activeVals.push({
            parameter_value_id: val.id,
            is_primary: val.is_primary
          })
        })
      } 
  
      formData.scenario_data.task_operation_parameter_values = activeVals
      console.log("formData", formData);
    }

    if (formData.scenario_name == 'TaskOperationContentPublisher') {
      formData.scenario_data.is_content_video = !!formData.scenario_data.is_content_video ? 1 : 0;
      formData.scenario_data.is_file_document_json_auto_post = !!formData.scenario_data.is_file_document_json_auto_post ? 1 : 0;
      formData.scenario_data.is_file_photo_thumbnail = !!formData.scenario_data.is_file_photo_thumbnail ? 1 : 0;
      formData.scenario_data.is_execute_after_task_channel_created = !!formData.scenario_data.is_execute_after_task_channel_created ? 1 : 0;
      formData.scenario_data.is_execute_after_file_created = !!formData.scenario_data.is_execute_after_file_created ? 1 : 0;
      formData.scenario_data.is_file_content_without_parameters = !!formData.scenario_data.is_file_content_without_parameters ? 1 : 0;
      formData.scenario_data.is_file_document_without_parameters = !!formData.scenario_data.is_file_document_without_parameters ? 1 : 0;
      formData.scenario_data.is_file_thumbnail_without_parameters = !!formData.scenario_data.is_file_thumbnail_without_parameters ? 1 : 0;
      formData.scenario_data.is_task_channel_data = !!formData.scenario_data.is_task_channel_data ? 1 : 0;
      formData.scenario_data.is_task_channel_channel_without_parameters = !!formData.scenario_data.is_task_channel_channel_without_parameters ? 1 : 0;
      formData.scenario_data.min_publish_period = !!formData.scenario_data.min_publish_period ? formData.scenario_data.min_publish_period*60 : 0;
    }

    if (formData.scenario_name == 'TaskOperationFilesCollector') {
      formData.scenario_data.sources = this.sources;
    }

    if (!this.data.tmpl) {
      // CREATE

      console.log("CREATE", formData)
      this.attachSubscriptions(
        this.scenariosService.addAutomationTemplates(formData).subscribe(resp => {   
          this.isSubmit = false;
          this.dialogRef.close({event: "update", data: resp});
        })
      )
    } else {
      // EDIT

      let editformData = {...formData}
      
      delete editformData.company_id;
      delete editformData.scenario_name;

      let count = 0;

      // console.log('editformData this.parameters[0].activeValues', this.parameters[0].activeValues)
      console.log("editformData", editformData)
      // return
      this.attachSubscriptions(
        this.scenariosService.getAutomationScenarios(this.data.company.id, {template_id: this.data.tmpl.id, is_active: 1, 'per-page': 1}).pipe(
          tap(el => {
            count = +el.headers.get('x-pagination-total-count')
          }),
          switchMap(() => {
            console.log("COunt", count)
            if (count == 0) {
              return this.scenariosService.editAutomationTemplates(this.data.tmpl.id, editformData).pipe(map(() => true))
            } else {
              return of(false)
            }
          })
          
        ).subscribe(resp => {
          console.log("resp", resp)
          if (resp) {
            // В случае если не используется в задачах count === 0 

            this.isSubmit = false;
            this.dialogRef.close({event: "update", data: resp});
          } else {

            // Создай новый или замени этот но возможны конфликты
            let deleteAlert =  this.bottomSheet.open(EditApproveComponent, {
              data: {
                count: count
              }
            });
        
            deleteAlert.afterDismissed().subscribe( data => {
              if (data && data.message == 'no') {
                this.attachSubscriptions(
                  this.scenariosService.addAutomationTemplates(formData).subscribe(resp => {
                    this.isSubmit = false;
                    this.dialogRef.close({event: "update", data: resp});
                  })
                )
              } else if (data && data.message == 'yes') {
                this.attachSubscriptions(
                  this.scenariosService.editAutomationTemplates(this.data.tmpl.id, editformData).subscribe(resp => {
                    this.isSubmit = false;
                    this.dialogRef.close({event: "update", data: resp});
                  })
                )
              }
            });
          }
        })
      )
    }
  }

  togglePrimary(tag) {
    tag.is_primary = !tag.is_primary;
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
