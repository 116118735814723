<div class="container cases_page">
    <div class="dashboard_wrp">
        <div class="dashboard">

            <div class="dash_item" (mouseenter)="!is_mobile ? onMouseEnter($event, 0) : null" (click)="!hoveredStatus[0].hover ? onMouseEnter($event, 0) : null;" [ngClass]="{'hovered': hoveredStatus[0].hover}">
                <div class="page_title">
                    <div class="page_name">
                        {{ 'Individuals' | translate }}
                    </div>
                    <div class="chat_icon_wrp">
                        <mat-icon>badge</mat-icon>
                    </div>
                </div>

                <a href="/settings?company_id={{company?.id}}&page=individuals" class="link-to-page page_btn hover_viss" (click)='goToEmployees($event, company?.id)'>
                    {{ "To page" | translate }}
                </a>
            </div>
 
            <div class="dash_item" (mouseenter)="!is_mobile ? onMouseEnter($event, 1) : null;" (click)="!hoveredStatus[1].hover ? onMouseEnter($event, 1) : null;" [ngClass]="{'hovered': hoveredStatus[1].hover}">
                <div class="page_title">
                    <div class="page_name">
                        {{ "Partner Workspaces" | translate }}
                    </div>
                    <div class="chat_icon_wrp">
                        <mat-icon>supervised_user_circle</mat-icon>
                    </div>
                </div>

                <a href="/settings?company_id={{company?.id}}&page=partner_workspaces" class="link-to-page page_btn hover_viss" (click)='goToPartners($event, company?.id)'>
                    {{ "To page" | translate }}
                </a>
            </div>

            <ng-container *ngIf="company?.permissions?.includes('owner') || company?.permissions?.includes('admin') || company?.permissions.includes('hr'); else templateNameApplication">
                <div class="dash_item" (mouseenter)="!is_mobile ? onMouseEnter($event, 2) : null" (click)="!hoveredStatus[2].hover ? onMouseEnter($event, 2) : null;" [ngClass]="{'hovered': hoveredStatus[2].hover}">
                    <div class="page_title">
                        <div class="page_name">
                            {{ "Onboarding forms" | translate }}
                        </div>
                        <div class="chat_icon_wrp">
                            <mat-icon>feed</mat-icon>
                        </div>
                    </div>
    
                    <a href="/settings?company_id={{company?.id}}&page=questionnaires" (click)='goToQuestionnaires($event, company?.id)' class="link-to-page page_btn hover_viss">
                        {{ "To page" | translate }}
                    </a>
                </div>
            </ng-container>
            <ng-template #templateNameApplication>
                <div class="dash_item" (mouseenter)="!is_mobile ? onMouseEnter($event, 2) : null" (click)="!hoveredStatus[2].hover ? onMouseEnter($event, 2) : null;" [ngClass]="{'hovered': hoveredStatus[2].hover}">
                    <div class="page_title">
                        <div class="page_name">
                            {{ "Form" | translate }}
                        </div>
                        <div class="chat_icon_wrp">
                            <mat-icon>feed</mat-icon>
                        </div>
                    </div>
    
                    <a href="/settings?company_id={{company?.id}}&page=form" (click)='goToForm($event, company?.id)' class="link-to-page page_btn hover_viss">
                        {{ "To page" | translate }}
                    </a>
                </div>
            </ng-template>



            <div class="dash_item" (mouseenter)="!is_mobile ? onMouseEnter($event, 3) : null" (click)="!hoveredStatus[3].hover ? onMouseEnter($event, 3) : null;" [ngClass]="{'hovered': hoveredStatus[3].hover}">
                <div class="page_title">
                    <div class="page_name">
                        {{ "Users" | translate }}
                    </div>
                    <div class="chat_icon_wrp">
                        <mat-icon>people</mat-icon>
                    </div>
                </div>

                <a class="link-to-page page_btn hover_viss" href="/settings?company_id={{company?.id}}&page=users" (click)="goToUsers($event, company?.id)">
                    {{ "To page" | translate }}
                </a>
            </div>



        </div>
    </div>
</div>