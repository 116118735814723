import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { AuthService } from 'src/app/shared/services/rest/auth.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { debounceTime, last, map, switchMap, tap } from 'rxjs/operators';
import { forkJoin, of, ReplaySubject } from 'rxjs';
import { MY_FORMATS } from 'src/app/components/workspace-settings/finances/statements/statements.component';
import { ScenariosService } from 'src/app/shared/services/rest/scenarios.service';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ActivatedRoute } from '@angular/router';
import { HttpRestService } from 'src/app/shared/services/common/http-rest.service';
import { RefreshService } from 'src/app/shared/services/rest/refresh.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-change-scenario-data',
  templateUrl: './change-scenario-data.component.html',
  styleUrls: ['./change-scenario-data.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class ChangeScenarioDataComponent extends BaseClass implements OnInit, OnDestroy {
  public host: any = environment.host;
  public imgRoute: any = '';
  public user_id: number;
  public form: FormGroup;
  public company_id: any;
  public task_id: any;
  public operations: any;
  public partners: any;
  public tmpl: any;
  public scenario_data: any;
  public automationType: any;
  public operationStatuses: any;
  public employees: any;
  public numberRegEx = /\d*\.?\d{1,2}/;
  public isFormChange: boolean = false;
  public isSubmit: boolean = false;
  public submited: boolean = false;
  public isConfirmScenarioMultipleEmployeesError: boolean = this.data.val.err.error.name == "ConfirmScenarioMultipleEmployees";
  
  public employeeMoreControl: FormControl = new FormControl();
  public employees$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public operationMoreControl: FormControl = new FormControl();
  public operations$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public profiles: any = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ChangeScenarioDataComponent>,
    private sm: StorageManagerService,
    private auth: AuthService,
    private companyService: CompanyService,
    private fb: FormBuilder,
    private taskService: TaskService,
    private _adapter: DateAdapter<any>,
    private languageService: LanguageService,
    private bottomSheet: MatBottomSheet,
    private refreshService: RefreshService,
    private http: HttpRestService,
    private layoutService: LayoutService,
    private activatedRoute: ActivatedRoute,
    private scenariosService: ScenariosService,

  ) { super() }

  ngOnInit(): void {
    window.scroll(0,0);

    console.log("is ConfirmScenarioMultipleEmployees", this.data.val.err.error.name == "ConfirmScenarioMultipleEmployees")

    this.company_id = this.activatedRoute.snapshot.queryParamMap.get('company_id');
    this.task_id = 0;

    this.getCompany();
    this.getImgRoute();
    this.getCsrf();

    if (this.data.val && this.data.val.err && this.data.val.err.error && this.data.val.err.error.params && this.data.val.err.error.params.automation_scenario) {
      this.tmpl = this.data.val.err.error.params.automation_scenario;

      this.scenario_data = this.tmpl.scenario_data || this.tmpl.template_data
    }

    this.attachSubscriptions(
      this.employeeMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchEmoloyees(resp))
    )
    this.attachSubscriptions(
      this.operationMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchOperations(resp))
    )

    this.form = this.fb.group({
      company_id: this.company_id,
      scenario_name: ['', Validators.required],
      scenario_data: this.fb.group({
        status_id: ['', Validators.required],
        operation_id: ['', Validators.required],
        employees_ids: [],
        is_copy_approved_files: 0,
        is_copy_ready_files: 0,
        is_create_discussions: 0,
        is_task_operation_one: 0,
        is_task_operation_one_per_template: 0,
        is_discussion_one: 0,
        is_discussion_one_per_template: 0,
        is_confirm_before_execute: 0,
        is_link_discussion_status_to_task_channel : 0,
        is_execute_after_task_channel_created: 0,
      }),
      is_task_operation: 1,
    })

    this.attachSubscriptions(
      this.form.valueChanges.subscribe(() => this.isFormChange = true)
    )

    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        if (this.isFormChange) {
          this.layoutService.openBottomSheet(this.dialogRef);
        } else {
          this.close();
        }
      })
    )

    this.getAutomationTemplatesTypes();
    this.getOperations();
    this.getOperationsStatus();
    this.getEmployees();
    this.getLangData();
    this.getGroupProfiles();
    
    if (this.tmpl) {
      this.getTmpl();
    }
  }

  getGroupProfiles() {
    this.attachSubscriptions(
      this.companyService.getProfiles(this.company_id).pipe(
        map(x => x.body),
        tap(arr => {
          if (this.tmpl) {
            arr.forEach(item => {
              if (this.scenario_data.channel_to_employee && this.scenario_data.channel_to_employee.hasOwnProperty(item.id)) {
                item.employees_ids = this.scenario_data.channel_to_employee[item.id].employees_ids
              } else {
                item.employees_ids = []
              }
            })
          } else {
            arr.forEach(item => {
              item.employees_ids = []
            })
          }
          
        })
      ).subscribe(resp => {
        console.log("getProfiles ------- ", resp)
        this.profiles = resp;
      })
    )
  }


  toggleEmpl(empl: any, profile) {
    console.log("toggleEmpl", empl, profile)
    if (!!profile.employees_ids && profile.employees_ids.includes(empl.id)) {
      let val = [...profile.employees_ids]
      val.splice(profile.employees_ids.indexOf(empl.id), 1)
      profile.employees_ids = val;
    } else {
      if (!!profile.employees_ids) {
        let val = [...profile.employees_ids]
        val.push(empl.id)
        profile.employees_ids = val;
      } else {
        profile.employees_ids = [empl.id]
      }
    }

  }

  changeCreateDiscussionFlag(e) {
    console.log(e);
    let scenarioData = this.form.controls.scenario_data as FormGroup;
    if (e.checked) {
      scenarioData.addControl("discussion_name_template", this.fb.control("Publish to {channel_name} ({platform_name})"))
    } else {
      scenarioData.removeControl("discussion_name_template")
    }
    this.form.updateValueAndValidity();

    console.log("changeCreateDiscussionFlag", this.form.value)
  }

  getTmpl() {
    this.form.patchValue({
      scenario_name: this.tmpl.scenario_name,
      is_task_operation: this.tmpl.is_task_operation
    })

    this.form.get('scenario_data').patchValue({
      status_id: this.scenario_data.status_id,
      operation_id: this.scenario_data.operation_id,
      employees_ids: this.scenario_data.employees_ids,
      is_copy_approved_files: this.scenario_data.is_copy_approved_files,
      is_copy_ready_files: this.scenario_data.is_copy_ready_files,
      is_create_discussions: this.scenario_data.is_create_discussions,
      is_task_operation_one: this.scenario_data.is_task_operation_one,
      is_confirm_before_execute: this.scenario_data.is_confirm_before_execute,
      is_task_operation_one_per_template: this.scenario_data.is_task_operation_one_per_template,
      is_discussion_one: this.scenario_data.is_discussion_one,
      is_discussion_one_per_template: this.scenario_data.is_discussion_one_per_template,
      is_link_discussion_status_to_task_channel: this.scenario_data.is_link_discussion_status_to_task_channel,
      is_execute_after_task_channel_created: this.scenario_data.is_execute_after_task_channel_created,
    })
    let scenarioData = this.form.controls.scenario_data as FormGroup;
    if (!!this.scenario_data.is_create_discussions) {
      scenarioData.addControl("discussion_name_template", this.fb.control(this.scenario_data.discussion_name_template))
    }
    this.form.updateValueAndValidity();

    console.log("this.data", this.tmpl)
    console.log("this.form", this.form.value)
  }

  onSearchEmoloyees(resp) {
    if (!this.employees) {
      return;
    }

    if (!resp) {
      this.employees$.next(this.employees.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.employees$.next(
      this.employees.filter(z => z.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  onSearchOperations(resp) {
    if (!this.operations) {
      return;
    }

    if (!resp) {
      this.operations$.next(this.operations.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.operations$.next(
      this.operations.filter(z => (z.name.toLowerCase().indexOf(resp) > -1))
    );
  }

  selectAutomationType(type) {
    this.form.patchValue({
      scenario_name: type
    })
  }

  selectType(type) {
    this.form.get("scenario_data").patchValue({
      operation_id: !!type.id ? type.id : type.value
    })
  }

  selectStatus(status) {
    this.form.get("scenario_data").patchValue({
      status_id: status.id
    })
  }

  getEmployeeById(id) {
    return this.employees.find(x => x.id == id)
  }

  toggleAddChip = (chip: any) => {
    if (!!this.form.get('scenario_data').value.employees_ids && this.form.get('scenario_data').value.employees_ids.includes(!!chip.id ? chip.id : chip.value)) {
      let val = [...this.form.get('scenario_data').value.employees_ids]
      val.splice(this.form.get('scenario_data').value.employees_ids.indexOf(!!chip.id ? chip.id : chip.value), 1)
      this.form.get('scenario_data').patchValue({
        employees_ids: val
      })
    } else {
      if (!!this.form.get('scenario_data').value.employees_ids) {
        let val = [...this.form.get('scenario_data').value.employees_ids]
        val.push(!!chip.id ? chip.id : chip.value)
        this.form.get('scenario_data').patchValue({
          employees_ids: val
        })
      } else {
        this.form.get('scenario_data').patchValue({
          employees_ids: [!!chip.id ? chip.id : chip.value]
        })
      }
    }
  }

  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData().subscribe(resp => {
        this._adapter.setLocale(resp.active);
      })
    )
  }
  
  getCompany() {
    this.attachSubscriptions(
      this.companyService.getCompany(this.company_id).subscribe(resp => {
        this.companyService.company$.next(resp[0]);
      })
    )
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  onPartnerImgError(event){
    event.target.src = this.imgRoute+'/assets/img/partner.png'
  }
  
  getCsrf() {
    this.attachSubscriptions(
      this.auth.$userToken.subscribe(resp => {
        this.user_id = resp.user_id;
        this.sm.localStorageSetItem("csrf_param", resp.csrf_param)
        this.sm.localStorageSetItem("csrf_token", resp.csrf_token)
      })
    )
  }
  
  getEmployees() {
    this.attachSubscriptions(
      this.taskService.getEmployeesDyn('1', this.data.company.id, null, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }
  
          return forkJoin(arr.map(x => this.taskService.getEmployeesDyn(x, this.data.company.id, null, '200').pipe(map(u => u.body)))).pipe(
            last(),
            tap(values => {
              let conValues = [].concat(...values)
              this.employees = conValues;
              this.employees$.next(this.employees.slice())
            }),
          )
        }),
      ).subscribe(resp => {
        console.log("getEmployees resp", resp);
        console.log("getEmployees", this.employees);
      })
    )
  }
  
  getAutomationTemplatesTypes() {
    this.attachSubscriptions(
      this.scenariosService.getAutomationTemplatesTypes().subscribe(resp => {
        this.automationType = resp;
      })
    )
  }

  getOperations() {
    this.attachSubscriptions(
      this.taskService.getOperations(this.company_id).subscribe(resp => {
        this.operations = resp;
        this.operations$.next(this.operations.slice())
      })
    )
  }

  getOperationsStatus() {
    this.attachSubscriptions(
      this.taskService.getOperationsStatus().subscribe(resp => {
        this.operationStatuses = resp.slice();
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  onSubmit() {
    if (!this.form.valid) {
      this.submited = true;
      this.layoutService.showSnackBar({name: ''}, marker("You need to fill in all required fields"), SnackBarItem)
      return
    }
    this.submited = false;
    this.isSubmit = true;
    let formData = {...this.form.value}

    formData.scenario_data.is_copy_approved_files = !!formData.scenario_data.is_copy_approved_files ? 1 : 0;
    formData.scenario_data.is_copy_ready_files = !!formData.scenario_data.is_copy_ready_files ? 1 : 0;
    formData.scenario_data.is_task_operation_one = !!formData.scenario_data.is_task_operation_one ? 1 : 0;
    formData.scenario_data.is_confirm_before_execute = !!formData.scenario_data.is_confirm_before_execute ? 1 : 0;
    formData.scenario_data.is_task_operation_one_per_template = !!formData.scenario_data.is_task_operation_one_per_template ? 1 : 0;
    formData.scenario_data.is_discussion_one = !!formData.scenario_data.is_discussion_one ? 1 : 0;
    formData.scenario_data.is_discussion_one_per_template = !!formData.scenario_data.is_discussion_one_per_template ? 1 : 0;
    formData.scenario_data.is_link_discussion_status_to_task_channel = !!formData.scenario_data.is_link_discussion_status_to_task_channel ? 1 : 0;
    formData.scenario_data.is_execute_after_task_channel_created = !!formData.scenario_data.is_execute_after_task_channel_created ? 1 : 0;
    if (!!formData.scenario_data.is_create_discussions) {
      formData.scenario_data.channel_to_employee = []

      this.profiles.forEach(p => {
        formData.scenario_data.channel_to_employee.push({
          channel_id: p.id,
          employees_ids: p.employees_ids
        })
      })
    }
    formData.scenario_data.is_create_discussions = !!formData.scenario_data.is_create_discussions ? 1 : 0;
    
    console.log(formData);

    let editformData = {...formData.scenario_data}
    this.attachSubscriptions(
      this.scenariosService.editAutomationScenario(this.tmpl.id, {scenario_data: editformData}, this.company_id).pipe(
        switchMap(res => {
          if (this.data.val.request.method == 'POST') {
              return this.http.executePost('/api/' + this.data.val.request.url.split("/api/")[1], Object.assign({
                [this.data.val.err.error.message]: {
                    __is_force: 1
                }
              }, this.data.val.request.body), true, {
                params: this.data.val.request.params
              })
          } else {
            return of(res)
          }
        })
      ).subscribe(resp => {   
        this.refreshService.refreshJob$.next({company_id: this.data.val.err.error.params.task_operation.company_id, job_id: this.data.val.err.error.params.task_operation.id})
        this.refreshService.refreshTask$.next({company_id: this.data.val.err.error.params.task_operation.company_id, task_id: this.data.val.err.error.params.task_operation.task_id})
        this.refreshService.refreshTaskCard$.next({company_id: this.data.val.err.error.params.task_operation.company_id, task_id: this.data.val.err.error.params.task_operation.task_id, task_operation_id: this.data.val.err.error.params.task_operation.id})
        this.isSubmit = false;
        this.dialogRef.close({event: "update", data: resp});
      })
    )
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
