import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { catchError, concatMap, debounceTime, finalize, last, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { FileService } from 'src/app/shared/services/rest/file.service';
import { SnackBarItem } from '../../../snack-bar/snack-bar-item';
import { forkJoin, from, of, ReplaySubject } from 'rxjs';
import { ParametersService } from 'src/app/shared/services/rest/parameters.service';
import { mimeTypes } from 'mime-wrapper';
import { LoadingService } from 'src/app/shared/services/rest/loading.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';

@Component({
  selector: 'app-dubbing',
  templateUrl: './dubbing.component.html',
  styleUrls: ['./dubbing.component.scss']
})
export class DubbingComponent extends BaseClass implements OnInit, OnDestroy {
  public form: FormGroup;

  public isGettingTag: boolean = false;
  public isSubmit: boolean = false;
  // public is_remember: boolean = !!this.sm.localStorageGetItem('rememberExportAudioTags') ? true : false;
  public langs: any;
  public langsControl: FormControl = new FormControl();
  public langs$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public saveTags: any = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private ls: LoadingService,
    private sm: StorageManagerService,
    private fileService: FileService,
    private parametersService: ParametersService,
    public dialogRef: MatDialogRef<DubbingComponent>,
    public layoutService: LayoutService
  ) { super() }

  ngOnInit(): void {
    console.log("DubbingComponent", this.data)
    this.form = this.fb.group({
      source_language: '',
      target_language: []
    })

    let langTag = this.data.file.parameterValuesToTask.find(k => !!k.parameter.is_language)

    console.log("langTag", langTag)
    if (this.data.files && this.data.files.length) {

      let count = 0;
      let lenSelection = this.data.files.length;
  
      this.isSubmit = true;
      this.ls.requests$.next({
        value: 0,
        target: `Get source language for ${lenSelection} files`
      })

      from(this.data.files).pipe(
        concatMap((file:any) => {
          file.dub = {
            source_language: '',
            target_language: [],
            is_getting: true
          }
          let fileTag = file.parameterValuesToTask.find(k => !!k.parameter.is_language);

          if (this.saveTags && this.saveTags.length && this.saveTags.filter(p => p.id == fileTag.id).length) {
            file['dub'].source_language = this.saveTags.filter(p => p.id == fileTag.id)[0].iso_value;
            return of(null);
          } else {
            if (!!fileTag && !!fileTag.parameterValue) { 
              return this.parametersService.getAllTags('1', {id: fileTag.parameterValue.original_id}, '200').pipe(
                map(k => k.body),
                tap(fileLangs => {
                  if (fileLangs && fileLangs.length) {
                    file['dub'].source_language = fileLangs[0].iso_value;
                  }
                  this.saveTags.push({
                    id: fileTag.id,
                    language: fileLangs[0].iso_value
                  })
                  file.dub.is_getting = false
                }),
                catchError(() => {
                  file.dub.is_getting = false
                  return of(null);
                }),
              )
            } else {
              return of(null);
            }
          }

        })
      ).subscribe({
        next: (next) => {
          console.log("next onSubmit", next);
          this.ls.requests$.next({
            value: Math.round((100 / lenSelection) * (count+1)),
            target: `Get source language for ${lenSelection} files`
          })
          count++;
        },
        complete: () => {
          console.log("complete onSubmit");
          this.layoutService.showSnackBar({name: 'Getting source language'}, marker("successfully!"), SnackBarItem)
        },
        error: (error) => {
          console.log("error onSubmit", error)
        }
      })
      
      this.attachSubscriptions(
        this.form.get('target_language').valueChanges.subscribe((resp) => {
          console.log("target_language CHANGE", resp)
          this.data.files.forEach(file => {
            file.dub.target_language = resp.filter(k => k != file.dub.source_language)
          });
        })
      )
    } else {
      if (!!langTag && !!langTag.parameterValue) {
        this.isGettingTag = true;
        this.attachSubscriptions(
          this.parametersService.getAllTags('1', {id: langTag.parameterValue.original_id}, '200').pipe(
            map(k => k.body)
          ).subscribe(resp => {
            console.log("getAllTags", resp)
            if (resp && resp.length) {
              this.form.patchValue({
                source_language: resp[0].iso_value
              })
            }
            this.isGettingTag = false;
          }, error => {
            this.isGettingTag = false;
          })
        )
      } 
    }

    // if (!!this.sm.localStorageGetItem('rememberExportAudioTags')) {
    //   let rememberExportAudioTags = JSON.parse(this.sm.localStorageGetItem('rememberExportAudioTags'));
    //   if (this.data.company.id && rememberExportAudioTags.company_id == this.data.company.id) {
    //     this.form.patchValue({
    //       parameter_id: rememberExportAudioTags.parameter_id
    //     })
    //   }
    // }
    

    this.attachSubscriptions(
      this.langsControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchLang(resp))
    )


    
    this.getLangs();
  }

  getLangByVal(iso_value) {
    return this.langs && this.langs.find(x => x.iso_value == iso_value)
  }

  getLangs() {
    this.attachSubscriptions(
      this.parametersService.getAllValues(1, 0, {is_language: 1}, '200').pipe(
        map(res => res.body),
        tap(res => {
          this.langs = res;
          this.langs$.next(this.langs.filter(x => !!x.id).slice())
        }),
      ).subscribe(resp => {
        console.log("this.langs", this.langs)
      })
    )
  }

  onSearchLang(resp) {
    if (!this.langs) {
      return;
    }

    if (!resp) {
      this.langs$.next(this.langs.filter(z => !!z.id).slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.langs$.next(
      this.langs.filter(z => !!z.id && (z.value.toLowerCase().indexOf(resp) > -1))
    );
  }

  close() {
    this.dialogRef.close({event: "close", data: false});
  }

  understandFileType(val) {
    if (!val) {
      return ""
    }
    
    if (val == 'application/pdf') {
      return 'pdf'
    } else if (val.indexOf("/") != -1) {
      return val.split('/')[0]
    } else {
      return ""
    }
  }

  multiSubmitForm() {
    if (!this.form.value.source_language) {
      this.layoutService.showSnackBar({name: 'Sourse language'}, marker("Not selected!"), SnackBarItem)
      return
    }
    if (this.form.value.target_language.length == 0) {
      this.layoutService.showSnackBar({name: 'Target language(s)'}, marker("Not selected!"), SnackBarItem)
      return
    }
    let dubbArr = [];
    this.data.files.filter(x => ["audio"].includes(mimeTypes.getType(x.content_type)) || ["audio"].includes(this.understandFileType(x.content_type))).forEach(file => {
      file.dub.target_language.forEach(targetLang => {
        if (targetLang != file.dub.source_language) {
          dubbArr.push({
            file_id: file.id,
            source_language: file.dub.source_language,
            target_language: targetLang,
            save_task_id: file.task_id,
            save_task_operation_id: file.task_operation_id,
            save_location: file.location,
            save_filename: targetLang + ' - ' + file.filename
          })
        }
      });
    });

    let count = 0;
    let lenSelection = dubbArr.length;

    if (!lenSelection) {
      this.layoutService.showSnackBar({name: 'There is no audio from the selected files from which audio can be dubbing.'}, '', SnackBarItem)
      return
    }
    this.isSubmit = true;
    this.ls.requests$.next({
      value: 0,
      target: `Dubbing ${lenSelection} files`
    })

    from(dubbArr).pipe(
      mergeMap(x => this.fileService.createDubbingProject(x, this.data.company_id).pipe(
        tap(k => {
          this.ls.requests$.next({
            value: Math.round((100 / lenSelection) * (count+1)),
            target: `Dubbing ${lenSelection} files`
          })
          count++;
          if (!!this.dialogRef) {
            this.close();
          }
        }),
        catchError(() => of([])),
      ), 4), // 4 запроса параллельно
      finalize(() => {
        this.layoutService.showSnackBar({name: 'Dubbing started'}, marker("successfully!"), SnackBarItem)
        // Этот блок выполнится только после обработки всех элементов
        console.log('Все запросы завершены');
        this.isSubmit = false;
        this.close();
        // Здесь можно дополнительно обработать card.is_ok_tags или другие поля
      }),
      last()
    ).subscribe(resp => {
    })
  }


  submitForm() {
    if (!this.form.value.source_language) {
      this.layoutService.showSnackBar({name: 'Sourse language'}, marker("Not selected!"), SnackBarItem)
      return
    }
    if (this.form.value.target_language.length == 0) {
      this.layoutService.showSnackBar({name: 'Target language(s)'}, marker("Not selected!"), SnackBarItem)
      return
    }

    let dubbArr = [];

    this.form.value.target_language.forEach(targetLang => {
      dubbArr.push({
        file_id: this.data.file.id,
        source_language: this.form.value.source_language,
        target_language: targetLang,
        save_task_id: this.data.file.task_id,
        save_task_operation_id: this.data.file.task_operation_id,
        save_location: this.data.file.location,
        save_filename: targetLang + ' - ' + this.data.file.filename
      })
    });

    // this.isSubmit = true;

    // this.attachSubscriptions(
    //   forkJoin(arr).subscribe(resp => {
    //     console.log("createProjects", resp)
    //     this.layoutService.showSnackBar({name: 'Audio dubbing started'}, marker("successfully!"), SnackBarItem)
    //     this.isSubmit = false;
    //     this.close();
    //   })
    // )

    let count = 0;
    let lenSelection = dubbArr.length;

    if (!lenSelection) {
      this.layoutService.showSnackBar({name: 'There is no audio from the selected files from which audio can be dubbing.'}, '', SnackBarItem)
      return
    }
    this.isSubmit = true;
    this.ls.requests$.next({
      value: 0,
      target: `Dubbing file to ${lenSelection} languages`
    })

    from(dubbArr).pipe(
      mergeMap(x => this.fileService.createDubbingProject(x, this.data.company_id).pipe(
        tap(k => {
          this.ls.requests$.next({
            value: Math.round((100 / lenSelection) * (count+1)),
            target: `Dubbing file to ${lenSelection} languages`
          })
          count++;
          if (!!this.dialogRef) {
            this.close();
          }
        }),
        catchError(() => of([])),
      ), 4), // 4 запроса параллельно
      finalize(() => {
        this.layoutService.showSnackBar({name: 'Dubbing started'}, marker("successfully!"), SnackBarItem)
        // Этот блок выполнится только после обработки всех элементов
        console.log('Все запросы завершены');
        this.isSubmit = false;
        this.close();
        // Здесь можно дополнительно обработать card.is_ok_tags или другие поля
      }),
      last()
    ).subscribe(resp => {
    })
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
