import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { ReplaySubject } from 'rxjs';
import { debounceTime, map, tap } from 'rxjs/operators';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { MembersService } from 'src/app/shared/services/rest/members.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';

@Component({
  selector: 'app-channels-to-group',
  templateUrl: './channels-to-group.component.html',
  styleUrls: ['./channels-to-group.component.scss']
})
export class ChannelsToGroupComponent extends BaseClass implements OnInit, OnDestroy {
  public form: FormGroup;
  public channelsToGroup: any;
  public profilesPagination: any;
  public contentTypes: any;
  public platforms: any;
  public profilesPage: number = 1;

  public profiles: any[] = [];
  public profilesMoreControl: FormControl = new FormControl();
  public profiles$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public isSubmit: boolean = false;
  public opened: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<ChannelsToGroupComponent>,
    public layoutService: LayoutService,
    public taskService: TaskService,
    public companyService: CompanyService,
    public membersService: MembersService,
  ) { super() }

  ngOnInit(): void {
    console.log("ChannelsToGroupComponent", this.data)
    this.dialogRef.addPanelClass('has_cst_outlets_fil')
    
    this.form = this.fb.group({
      company_id: this.data.company.id,
      channel_id: "",
      content_type_id: "",
      channel_group_id: this.data.group.id
    })
    this.getPlatforms();

    this.getChannelsToGroup();

    this.getProfiles(this.data.company.id, this.profilesPage)


    this.attachSubscriptions(
      this.form.get("channel_id").valueChanges.subscribe(resp => {
        if (resp && this.getProfileById(resp)) {
          this.getContentTypes(this.getProfileById(resp).platform_id)
        }
      })
    )

    this.attachSubscriptions(
      this.profilesMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchProfiles(resp))
    )
  }

  selectChange(e) {
    console.log('selectChange', e)
    if (!!e) {
      this.profiles.push(e);
      this.getContentTypes(e.platform_id)
    }
    this.opened = false;
  }

  setOutletKey(e) {
    console.log('setOutletKey', e)
  }

  getPlatforms() {
    this.attachSubscriptions(
      this.companyService.getGroupPlatforms().subscribe(resp => {
        this.platforms = resp;
        console.log("this.platforms", this.platforms)
      })
    )
  }
  
  selectType(type) {
    console.log("selectType", type)
    this.form.patchValue({
      content_type_id: type.id
    })
  }

  getNextProfiles() {
    if (this.profilesPagination.pageCount >= this.profilesPage) {
      console.log("getNextProfiles")
      this.getProfiles(this.data.company.id, this.profilesPage);
    }
  }

  onSearchProfiles(resp) {
    if (!this.profiles) {
      return;
    }

    if (!resp) {
      this.profiles$.next(this.profiles.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }

    // filter the banks
    this.profiles$.next(
      this.profiles.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  getProfiles(company_id, page) {
    this.attachSubscriptions(
      this.companyService.getProfilesDyn(company_id, page).pipe(
        tap(el => {
          this.profilesPagination = {
            'pageCount': el.headers.get('x-pagination-page-count'),
            'perPage': el.headers.get('x-pagination-per-page'),
            'totalCount': el.headers.get('x-pagination-total-count'),
            'currentPage': el.headers.get('x-pagination-current-page'),
          }
        }),
        map(el => el.body)
      ).subscribe(resp => {
        console.log("getProfiles", resp)
        resp.forEach(profile => {
          if (this.profiles.filter(x => x.id == profile.id).length == 0) {
            this.profiles.push(...resp);
          }
        })
        this.profiles$.next(this.profiles.slice());
        this.profilesPage = this.profilesPage + 1;
      })
    )
  }

  log() {
    console.log("log", this.profiles)
  }

  getProfileById(id) {
    return this.profiles.find(x => x.id == id)
  }

  close() {
    this.dialogRef.close({event: "close", data: false});
  }

  getContentTypes(platform_id) {
    this.attachSubscriptions(
      this.companyService.getContentTypes(platform_id).pipe(
        map(el => el.body)
      ).subscribe(resp => {
        this.contentTypes = resp;
      })
    )
  }

  getChannelsToGroup() {
    this.attachSubscriptions(
      this.companyService.getChannelsToGroup(this.data.company.id, {group_id: this.data.group.id}).pipe(
        map(el => el.body)
      ).subscribe(resp => {
        this.channelsToGroup = resp;
      })
    )
  }

  onSubmit() {
    if (!this.form.value.content_type_id && this.form.value.channel_id) {
      this.layoutService.showSnackBar({name: ''}, marker("You must select content type"), SnackBarItem)
      return
    }
    
    this.isSubmit = true;
    let sbmtData = {...this.form.value}

    this.attachSubscriptions(
      this.companyService.addChannelToGroup(sbmtData, this.data.company.id).subscribe(resp => {
        this.form.patchValue({
          channel_id: "",
          content_type_id: ""
        })
        this.isSubmit = false;
        this.getChannelsToGroup();
      }, error => {
        this.isSubmit = false;
        this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
      })
    )
  }

  deleteChannelToGroup(id, i) {
    this.attachSubscriptions(
      this.companyService.deleteChannelToGroup(id).subscribe(resp => {
        this.channelsToGroup.splice(i, 1)
      })
    )
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
