
<section class="lite_chats" [ngClass]="{'use_out': useOut}">
    <div class="lite_chats_head">
        <div class="lite_left">
            <div class="job_status_wrp">
                <button mat-button (click)='changeTab(0)' [ngClass]="{'active_status': chatsIndex.value == 0}">{{ "All" | translate }}</button>
                <button mat-button (click)='changeTab(1)' [ngClass]="{'active_status': chatsIndex.value == 1}">{{ "To Do" | translate }}</button>
                <button mat-button (click)='changeTab(2)' [ngClass]="{'active_status': chatsIndex.value == 2}">{{ "Done" | translate }}</button>
            </div>
        </div>
        <div class="lite_right">
            <div class="lite_btn mr-1" (click)="createChat()">
                {{"Add chat" | translate}}
            </div>

            <div class="lite_count" *ngIf="target?.outputChats?.pagination?.totalCount">
                {{target?.outputChats?.pagination?.totalCount}} {{"chats" | translate}}
            </div>
        </div>
    </div>
    <mat-tab-group class="lite_chats_tabs" animationDuration="0ms" [disableRipple]="true" [selectedIndex]="chatsIndex.value">
        <mat-tab>
            <ng-template mat-tab-label>
                {{ "All" | translate }}
            </ng-template>
            <ng-container [ngTemplateOutlet]="ChatsTemplate" [ngTemplateOutletContext]="{ target: target, index: chatsIndex.value }"></ng-container>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                {{ "To do" | translate }}
            </ng-template>
            <ng-container [ngTemplateOutlet]="ChatsTemplate" [ngTemplateOutletContext]="{ target: target, index: chatsIndex.value }"></ng-container>
        </mat-tab>
        
        <mat-tab>
            <ng-template mat-tab-label>
                {{ "Done" | translate }}
            </ng-template>
            <ng-container [ngTemplateOutlet]="ChatsTemplate" [ngTemplateOutletContext]="{ target: target, index: chatsIndex.value }"></ng-container>
        </mat-tab>
    </mat-tab-group>
</section>

<ng-template #ChatsTemplate let-target="target" let-index="index">
    <div class="lite_chats_content" [ngClass]="{'centred': target?.outputChats?.is_load && target?.outputChats?.chats?.length == 0}" [appDeviceClass]="'custom-scroll custom-scroll-t'"
    infinite-scroll
    [infiniteScrollDisabled]="target?.outputChats?.is_load"
    [scrollWindow]="false"
    [infiniteScrollUpDistance]="scrollUpDistance"
    [infiniteScrollThrottle]="throttle"
    (scrolled)="onDownChats($event, target)">     
        <ng-container *ngIf="target?.outputChats?.is_load && target?.outputChats?.chats?.length == 0; else elseTemplateLoadedDone">
            <mat-spinner></mat-spinner>
        </ng-container>
        <ng-template #elseTemplateLoadedDone>
            <ng-container *ngFor="let item of target?.outputChats?.chats">
                <div class="chat" 
                [ngClass]="{'todo_chat': [1,2,5].includes(item.status_id), 'done_chat': [3,4,6,97,98,99].includes(item.status_id)}" 
                (click)="goToChat(item, target)" 
                (contextmenu)="openChatMenu($event, target, item); $event.preventDefault(); $event.stopPropagation();">
                    <div class="chat_name text_one_line">{{item.name}}</div>
                    <div class="to_board_btn" (click)="$event.preventDefault(); $event.stopPropagation(); addToBoard(item)">
                        <svg style="width: 12px; height: 14px" width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M10.8 12L6 16.8H9.6V24H12V16.8H15.6L10.8 12ZM19.2 2.4H18V0H15.6V2.4H6V0H3.6V2.4H2.4C1.76348 2.4 1.15303 2.65286 0.702944 3.10294C0.252856 3.55303 0 4.16348 0 4.8V21.6C0 22.2365 0.252856 22.847 0.702944 23.2971C1.15303 23.7471 1.76348 24 2.4 24H7.2V21.6H2.4V8.4H19.2V21.6H14.4V24H19.2C19.8365 24 20.447 23.7471 20.8971 23.2971C21.3471 22.847 21.6 22.2365 21.6 21.6V4.8C21.6 4.16348 21.3471 3.55303 20.8971 3.10294C20.447 2.65286 19.8365 2.4 19.2 2.4Z" fill="#219653"/>
                        </svg>
                    </div>
                </div>
            </ng-container>
        </ng-template>
    </div>
</ng-template>

<ng-template #chatMenu let-menuData>
	<section class="proj-file-menu">
        <div (click)="addToBoard(menuData?.chat); closeChatMenu();">
            <svg class="mr-1" style="width: 18px; height: 18px" width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.8 12L6 16.8H9.6V24H12V16.8H15.6L10.8 12ZM19.2 2.4H18V0H15.6V2.4H6V0H3.6V2.4H2.4C1.76348 2.4 1.15303 2.65286 0.702944 3.10294C0.252856 3.55303 0 4.16348 0 4.8V21.6C0 22.2365 0.252856 22.847 0.702944 23.2971C1.15303 23.7471 1.76348 24 2.4 24H7.2V21.6H2.4V8.4H19.2V21.6H14.4V24H19.2C19.8365 24 20.447 23.7471 20.8971 23.2971C21.3471 22.847 21.6 22.2365 21.6 21.6V4.8C21.6 4.16348 21.3471 3.55303 20.8971 3.10294C20.447 2.65286 19.8365 2.4 19.2 2.4Z" fill="#219653"/>
            </svg>
            {{ 'Add to board' | translate }}
        </div>
        
        <div (click)="openTargetValues([menuData?.chat?.task_id, menuData?.chat?.task_operation_id, menuData?.chat?.id], menuData?.chat)">
            <svg class="mr-1" width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg" style="width: 18px; height: 15px;"><path d="M3.15 4.5C3.50804 4.5 3.85142 4.35777 4.10459 4.10459C4.35777 3.85142 4.5 3.50804 4.5 3.15C4.5 2.79196 4.35777 2.44858 4.10459 2.19541C3.85142 1.94223 3.50804 1.8 3.15 1.8C2.79196 1.8 2.44858 1.94223 2.19541 2.19541C1.94223 2.44858 1.8 2.79196 1.8 3.15C1.8 3.50804 1.94223 3.85142 2.19541 4.10459C2.44858 4.35777 2.79196 4.5 3.15 4.5ZM13.869 6.822C14.193 7.146 14.4 7.596 14.4 8.1C14.4 8.595 14.202 9.045 13.869 9.369L9.369 13.869C9.045 14.193 8.595 14.4 8.1 14.4C7.605 14.4 7.155 14.202 6.822 13.869L0.531 7.578C0.198 7.245 0 6.795 0 6.3V1.8C0 0.801 0.801 0 1.8 0H6.3C6.795 0 7.245 0.198 7.569 0.522L13.869 6.822ZM10.386 1.539L11.286 0.639L17.469 6.822C17.802 7.146 18 7.605 18 8.1C18 8.595 17.802 9.045 17.478 9.369L12.636 14.211L11.736 13.311L16.875 8.1L10.386 1.539Z" fill="#686868"></path></svg>
            {{ "Tags" | translate }}
        </div>

        <div ngxClipboard [cbContent]="origin + '/chat/' + menuData?.chat?.acm" (cbOnSuccess)="copyLink('chat'); closeChatMenu();">
            <mat-icon class="mr-1">link</mat-icon> 
            {{ 'Copy url to chat' | translate }}
        </div>

        <a [href]="origin + '/chat/' + menuData?.chat?.acm" (click)="openInNew($event, menuData?.chat); closeChatMenu();">
            <mat-icon class="mr-1">open_in_new</mat-icon>
            {{ 'Open in new tab' | translate }}
        </a>
        <div (click)="openConnectionChats(menuData?.chat); closeChatMenu();">
            <mat-icon class="mr-1">
                <svg style="cursor: pointer;" class="chat_cons" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18 16.08C17.24 16.08 16.56 16.38 16.04 16.85L8.91 12.7C8.96 12.47 9 12.24 9 12C9 11.76 8.96 11.53 8.91 11.3L15.96 7.19C16.5 7.69 17.21 8 18 8C19.66 8 21 6.66 21 5C21 3.34 19.66 2 18 2C16.34 2 15 3.34 15 5C15 5.24 15.04 5.47 15.09 5.7L8.04 9.81C7.5 9.31 6.79 9 6 9C4.34 9 3 10.34 3 12C3 13.66 4.34 15 6 15C6.79 15 7.5 14.69 8.04 14.19L15.16 18.35C15.11 18.56 15.08 18.78 15.08 19C15.08 20.61 16.39 21.92 18 21.92C19.61 21.92 20.92 20.61 20.92 19C20.92 17.39 19.61 16.08 18 16.08Z" fill="#D7D7D7"/>
                    <circle *ngIf="chat?.partOfDiscussionPartition.length != 0" cx="6" cy="12" r="3" fill="#96A6C2"/>
                    <circle *ngIf="chat?.discussionRelated.length != 0" cx="18" cy="19" r="3" fill="#FFD0A7"/>
                    <circle *ngIf="chat?.consistOfDiscussionPartition.length != 0" cx="18" cy="5" r="3" fill="#98F1BD"/>
                  </svg>
            </mat-icon> 
            {{ "Chat connections" | translate }}
        </div>

        <div (click)="toPersonalStatuses(menuData?.chat); closeChatMenu();">
            <mat-icon class="mr-1">manage_accounts</mat-icon> 
            {{ 'Statuses' | translate }}
        </div>
	</section>
</ng-template>