import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { forkJoin, ReplaySubject } from 'rxjs';
import { tap, debounceTime, map } from 'rxjs/operators';
import { BaseClass } from 'src/app/shared/models/base-class';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { ScenariosService } from 'src/app/shared/services/rest/scenarios.service';
import { CreateTaskComponent } from '../../atTasksDialog/create-task/create-task.component';
import { EmplCreateTaskComponent } from '../../atTasksDialog/empl-create-task/empl-create-task.component';
import { PartnerCreateTaskComponent } from '../../atTasksDialog/partner-create-task/partner-create-task.component';
import { MobAddTaskUseTemplateComponent } from '../mob-add-task-use-template/mob-add-task-use-template.component';
import { MobAddTaskComponent } from '../mob-add-task/mob-add-task.component';

@Component({
  selector: 'app-select-company',
  templateUrl: './select-company.component.html',
  styleUrls: ['./select-company.component.scss']
})
export class SelectCompanyComponent extends BaseClass implements OnInit, OnDestroy {
  public form: FormGroup;
  public exForm: FormGroup;
  public submited: boolean = false;
  public exSubmited: boolean = false;
  
  public isSubmit: boolean = false;
  public exIsSubmit: boolean = false;
  public noPartners: boolean = false;
  

  public partners: any = [];
  public myCompanyes: any;
  public taskTemplates: any;

  public selectedIndex: FormControl = new FormControl(0);
  public selectedWorkspaceIndex: FormControl = new FormControl(0);

  public compMoreControl: FormControl = new FormControl();
  public companyes$: ReplaySubject<any> = new ReplaySubject<any>(1);
  
  public myCompMoreControl: FormControl = new FormControl();
  public myCompanyes$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public partnerCompMoreControl: FormControl = new FormControl();
  public partners$: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<SelectCompanyComponent>,
    private fb: FormBuilder,
    private companyService: CompanyService,
    private scenariosService: ScenariosService,
    private dialog: MatDialog,
  ) { super() }

  ngOnInit(): void {
    console.log("SelectCompanyComponent", this.data)
    this.dialogRef.addPanelClass("create_task_by_manager_modal")
    this.companyes$.next(this.data.companyes.slice())

    // this.myCompanyes = this.data.companyes.filter(x => x.owner_user_id == this.data.user.id)
    this.myCompanyes = this.data.companyes.filter(x => x.permissions.includes("owner") || x.permissions.includes("manager") || x.permissions.includes("admin"))
    this.myCompanyes$.next(this.myCompanyes.slice())

    this.form = this.fb.group({
      company_id: ['', Validators.required]
    })
    this.exForm = this.fb.group({
      company_id: ['', Validators.required],
      partner_company_id: ['', Validators.required]
    })

    this.exForm.get('company_id').valueChanges.subscribe(res => {
      console.log(res);
      this.getPartners(res);
    })

    // this.getPartners();
    this.attachSubscriptions(
      this.compMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchWorkspaces(resp))
    )
    this.attachSubscriptions(
      this.myCompMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchMyWorkspaces(resp))
    )
    this.attachSubscriptions(
      this.partnerCompMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchPartners(resp))
    )
  }

  changeWorkspaceTab(val) {
    this.selectedWorkspaceIndex.patchValue(val);
  }

  changeIndex(val) {
    this.selectedIndex.patchValue(val);
  }

  onSearchPartners(resp) {
    if (!this.partners) {
      return;
    }

    if (!resp) {
      this.partners$.next(this.partners.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.partners$.next(
      this.partners.filter(z => z.partnerCompany.name.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  getPartners(comp_id) {
    this.attachSubscriptions(
      this.companyService.getPartners({company_id: comp_id}).subscribe(resp => {
        if (resp.length == 0) {
          this.noPartners = true;
        } else {
          this.noPartners = false;
        }
        this.partners = resp.filter(x => x.partner_company_id != 0);
        console.log(this.partners)
        this.partners$.next(this.partners.slice())
      })
    )
  }

  toggleChip = (chip: any) => {
    let id = !!chip.id ? chip.id : chip.value
    this.form.patchValue({
      company_id: id
    })
    this.taskTemplates = undefined;
    this.getTaskTemplates(id)
  }

  toggleExChip = (chip: any) => {
    let id = !!chip.id ? chip.id : chip.value
    this.exForm.patchValue({
      company_id: id
    })
  }

  getTaskTemplates(c_id) {
    this.attachSubscriptions(
      this.scenariosService.getTaskTemplates('1', {company_id: c_id}, '200').pipe(
        map(el => el.body)
      ).subscribe(resp => {
        console.log("getAutos", resp);
        this.taskTemplates = resp;
      })
    )
  }

  togglePartnerChip = (chip: any) => {
    this.exForm.patchValue({
      partner_company_id: !!chip.partner_company_id ? chip.partner_company_id : chip.value
    })
  }

  onSearchMyWorkspaces(resp) {
    if (!this.myCompanyes) {
      return;
    }

    if (!resp) {
      this.myCompanyes$.next(this.myCompanyes.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.myCompanyes$.next(
      this.myCompanyes.filter(z => z.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  onSearchWorkspaces(resp) {
    if (!this.data.companyes) {
      return;
    }

    if (!resp) {
      this.companyes$.next(this.data.companyes.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.companyes$.next(
      this.data.companyes.filter(z => z.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  selectWorkSpace() {
    if (!this.form.valid) {
      this.submited = true;
      console.log(this.form);
      return
    }
    this.submited = false;
    this.isSubmit = true;

    if (!this.data.companyes.find(x => x.id == this.form.value.company_id)) {
      return
    }
    console.log(this.data.companyes.find(x => x.id == this.form.value.company_id));
    
    let targetCompany = this.data.companyes.find(x => x.id == this.form.value.company_id)
    if (targetCompany.permissions.includes("owner") || targetCompany.permissions.includes("manager") || targetCompany.permissions.includes("admin")) {
      this.createTask(targetCompany);
    } else {
      this.emplCreateTask(targetCompany)
      this.close();
    }
    this.isSubmit = false;
  }

  useTemplate() {
    if (!this.form.valid) {
      this.submited = true;
      console.log(this.form);
      return
    }
    this.submited = false;
    this.isSubmit = true;

    if (!this.data.companyes.find(x => x.id == this.form.value.company_id)) {
      return
    }

    console.log(this.data.companyes.find(x => x.id == this.form.value.company_id));
    
    let targetCompany = this.data.companyes.find(x => x.id == this.form.value.company_id)
    if (targetCompany.permissions.includes("owner") || targetCompany.permissions.includes("manager") || targetCompany.permissions.includes("admin")) {
      this.createTaskUseTemplate(targetCompany);
    } else {
      this.emplCreateTask(targetCompany)
      this.close();
    }
    this.isSubmit = false;
  }

  createTaskUseTemplate(company) {
    const dialogRef = this.dialog.open(MobAddTaskUseTemplateComponent, {
      backdropClass: ['mob_interface_backdrop'],
      panelClass: ['mob_interface_panel', 'animate__animated', 'animate__slideInUp'],
      disableClose: true,
      data: {
        company_id: this.form.value.company_id,
        company: company,
        imgRoute: this.data.imgRoute,
        host: this.data.host,
        taskTemplates: this.taskTemplates
      }
    });
    
    this.close()
  }

  selectPartnerWorkSpace() {
    if (!this.exForm.valid) {
      this.exSubmited = true;
      console.log(this.exForm);
      return
    }
    this.exSubmited = false;
    this.exIsSubmit = true;
    
    if (!this.partners.find(x => x.partner_company_id == this.exForm.value.partner_company_id) || !this.myCompanyes.find(x => x.id == this.exForm.value.company_id)) {
      return
    }
    let targetCompany = this.myCompanyes.find(x => x.id == this.exForm.value.company_id)
    let targetPartnerCompany = this.partners.find(x => x.partner_company_id == this.exForm.value.partner_company_id)

    this.createPartnerTask(targetCompany, targetPartnerCompany.partnerCompany);
    this.exIsSubmit = false;
    this.close();
  }

  createPartnerTask(company, partner) {
    console.log("company", company)
    console.log("partner", partner)
    const dialogRef = this.dialog.open(PartnerCreateTaskComponent, {
      disableClose: true,
      data: {
        company_id: this.exForm.value.company_id,
        company: company,
        partner: partner,
        imgRoute: this.data.imgRoute,
        host: this.data.host,
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log("getONE BEFORE", result);
        // if (!!result && result.event == 'Add') {
        //   this.taskService.getOneTaskExpand(this.company_id, result.data.id).subscribe(resp => {
        //     console.log("getONE", resp.body[0]);
        //     this.addRowData(resp.body[0]);
        //   })
        // }
      })
    )
  }

  onPartnerImgError(event){
    event.target.src = this.data.imgRoute+'/assets/img/partner.png'
  }

  emplCreateTask(company) {
    const dialogRef = this.dialog.open(EmplCreateTaskComponent, {
      disableClose: true,
      data: {
        company_id: this.form.value.company_id,
        company: company,
        imgRoute: this.data.imgRoute,
        host: this.data.host,
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log("getONE BEFORE", result);
        // if (!!result && result.event == 'Add') {
        //   this.taskService.getOneTaskExpand(this.company_id, result.data.id).subscribe(resp => {
        //     console.log("getONE", resp.body[0]);
        //     this.addRowData(resp.body[0]);
        //   })
        // }
      })
    )
  }

  createTask(company) {
    // const dialogRef = this.dialog.open(CreateTaskComponent, {
    //   disableClose: true,
    //   data: {
    //     company_id: this.form.value.company_id,
    //     company: company,
    //     imgRoute: this.data.imgRoute,
    //     host: this.data.host,
    //   }
    // });



    const dialogRef = this.dialog.open(MobAddTaskComponent, {
      backdropClass: ['mob_interface_backdrop'],
      panelClass: ['mob_interface_panel', 'animate__animated', 'animate__slideInUp'],
      disableClose: true,
      data: {
        company_id: this.form.value.company_id,
        company: company,
        imgRoute: this.data.imgRoute,
        host: this.data.host,
      }
    });
    
    this.close()

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        
        if (!!result && result.event == 'Add') {
          // this.attachSubscriptions(
          //   this.taskService.getOneTaskExpand(this.company_id, result.data.id).subscribe(resp => {
          //     this.addRowData(resp.body[0]);
          //   })
          // )
        }
      })
    )
  }

  close() {
    this.dialogRef.removePanelClass('animate__slideInUp')
    this.dialogRef.addPanelClass('animate__slideOutDown')
    setTimeout(()=>{this.dialogRef.close()}, 300);
  }

  ngOnDestroy(): void {
    this.clearSubscriptions();
  }


}
