import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { fromEvent, forkJoin } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { OpenTaskComponent } from 'src/app/components/workspace-pages/cases/dialogs/open-task/open-task.component';
import { DeleteAlertComponent } from 'src/app/shared/global_components/delete-alert/delete-alert.component';
import { BaseClass } from 'src/app/shared/models/base-class';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { ClientsService } from 'src/app/shared/services/rest/clients.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-rules',
  templateUrl: './rules.component.html',
  styleUrls: ['./rules.component.scss']
})

export class RulesComponent extends BaseClass implements OnInit, OnDestroy {
  public form: FormGroup;
  public rules: any;
  public managerTasks: any;
  public clientTasks: any;
  public groupManagers: any;
  public emplRules: any;

  public imgRoute: any = '';
  public host: any = environment.host;

  public isSubmit: boolean = false;
  public is_mobile: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<RulesComponent>,
    private fb: FormBuilder,
    private bottomSheet: MatBottomSheet,
    private companyService: CompanyService,
    private dialog: MatDialog,
    private sm: StorageManagerService,
    private taskService: TaskService,
  ) { super() }

  ngOnInit(): void {
    console.log("RulesComponent", this.data);
    if (this.data.is_partner) {
      this.form = this.fb.group({
        company_id: this.data.company.id,
        partner_company_id: this.data.employee.partner_company_id,
        permission: [''],
      })
    } else {
      this.form = this.fb.group({
        company_id: this.data.company.id,
        employee_id: this.data.employee.id,
        permission: [''],
      })
    }
    this.checkIsMobile()
    this.getImgRoute();

    this.getRules();
    this.getEmployeeRules();
    this.getClientTasks();

    this.attachSubscriptions(
      this.companyService.getChToEmDeletedStream().subscribe(resp => {
        console.log("getChToEmDeletedStream", resp);
        if (this.emplRules.find(x => x.id == resp.rule_id)) {
          this.emplRules.splice(this.emplRules.indexOf(this.emplRules.find(x => x.id == resp.rule_id)), 1)
        }
      })
    )
    
    if (!this.data.is_partner) {
      this.getManagerTasks();
      this.getGroupManagers();
    }
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  getManagerTasks() {
    this.attachSubscriptions(
      this.taskService.getTasksWithoutExpand('1', this.data.company.id, {task_manager_employee_id: this.data.employee.id}, "200").pipe(
        map(x => x.body)
      ).subscribe(resp => {
        this.managerTasks = resp
        console.log("this.managerTasks", this.managerTasks)
      })
    )
  }

  checkIsMobile() {
    if (window.innerWidth <= 769) {
      this.is_mobile = true;
    } else {
      this.is_mobile = false;
    }
    this.onResize();
  }

  onResize() {
    this.attachSubscriptions(
      fromEvent(window, "resize").pipe(
        map(() => window.innerWidth)
      ).subscribe((wWidth) => {
          if (wWidth <= 769) {
            this.is_mobile = true;
          } else {
            this.is_mobile = false;
          }
        }
      )
    )
  }

  openTask(task) {
    let taskData:any = {
      task_id: task.id
    }

    const dialogRef = this.dialog.open(OpenTaskComponent, {
      backdropClass: 'backdrop_under_header',
      panelClass: !this.is_mobile ? ['open_task_dialog', 'show_header'] : 'open_task_dialog',
      autoFocus: false,
      data: taskData
    });
  }

  // deleteTaskManager(e, task, i) {
    
  // }
  
  // deleteClientInTask(e, task, i) {

  // }

  getClientTasks() {
    this.attachSubscriptions(
      this.taskService.getTasksWithoutExpand('1', this.data.company.id, this.data.is_partner ? {origin_company_id: this.data.employee.partner_company_id} : {client_employee_id: this.data.employee.id}, "200").pipe(
        map(x => x.body)
      ).subscribe(resp => {
        this.clientTasks = resp
        console.log("this.clientTasks", this.clientTasks)
      })
    )
  }

  getGroupManagers() {
    this.attachSubscriptions(
      this.taskService.getGroupManagers({employee_id: this.data.employee.id}).subscribe(resp => {
        console.log("getGroupManagers", resp)
        this.groupManagers = resp
      })
    )
  }

  deleteGroupManager(manager, i) {
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: {},
        target: marker("group manager")
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        this.attachSubscriptions(
          this.taskService.deleteManagerFromGroup(manager.id).pipe(
            tap(x => {
              console.log(x)
              this.groupManagers.splice(i,1);
            }),
          ).subscribe(resp => {
            console.log("END",resp)
          })
        )
      }
    });
  
  }

  getEmployeeRules() {
    if (this.data.is_partner) {
      this.attachSubscriptions(
        this.taskService.getPartnerRules(this.data.employee.partner_company_id, this.data.company.id).subscribe(resp => {
          this.emplRules = resp;
          console.log("emplRules", this.emplRules);
        })
      )
    } else {
      this.attachSubscriptions(
        this.taskService.getEmployeeRules(this.data.employee.id, this.data.company.id).subscribe(resp => {
          this.emplRules = resp;
          console.log("emplRules", this.emplRules);
        })
      )
    }
  }

  deleteEmployeeRule(id, i) {
    this.attachSubscriptions(
      this.taskService.deleteEmployeeRule(id).subscribe(resp => {
        this.emplRules.splice(i, 1);
      })
    )
  }

  getRules() {
    if (this.data.is_partner) {
      this.attachSubscriptions(
        this.taskService.getPartnerRulesList().subscribe(resp => {
          this.rules = resp;
          console.log("rules", this.rules);
        })
      )
    } else {
      this.attachSubscriptions(
        this.taskService.getRules().subscribe(resp => {
          this.rules = resp;
          console.log("rules", this.rules);
        })
      )
    }
  }

  close() {
    this.dialogRef.close({event: "close", data: false});
  }

  onSubmit() {
    this.isSubmit = true;
    if (this.form.value.permission == 'create_task') {
      let sbmtData = {...this.form.value};
      delete sbmtData.permission;
      let arr = [];
      if (this.emplRules.filter(x => x.permission == 'create_task').length == 0) {
        arr.push('create_task')
      }
      if (this.emplRules.filter(x => x.permission == 'create_task_operation').length == 0) {
        arr.push('create_task_operation')
      }
      if (this.emplRules.filter(x => x.permission == 'create_discussion').length == 0) {
        arr.push('create_discussion')
      }
      this.attachSubscriptions(
        forkJoin(arr.map(x => this.taskService.addEmployeeRule(Object.assign({permission: x}, sbmtData), this.data.company.id))).subscribe(resp => {
          this.isSubmit = false;
          this.getEmployeeRules();
  
          this.form.patchValue({
            permission: ''
          })
        })
      )
    } else if (this.form.value.permission == 'create_task_operation') {
      let sbmtData = {...this.form.value};
      delete sbmtData.permission;
      let arr = [];
      if (this.emplRules.filter(x => x.permission == 'create_task_operation').length == 0) {
        arr.push('create_task_operation')
      }
      if (this.emplRules.filter(x => x.permission == 'create_discussion').length == 0) {
        arr.push('create_discussion')
      }
      this.attachSubscriptions(
        forkJoin(arr.map(x => this.taskService.addEmployeeRule(Object.assign({permission: x}, sbmtData), this.data.company.id))).subscribe(resp => {
          this.isSubmit = false;
          this.getEmployeeRules();
  
          this.form.patchValue({
            permission: ''
          })
        })
      )
    } else {
      this.attachSubscriptions(
        this.taskService.addEmployeeRule(this.form.value, this.data.company.id).subscribe(resp => {
          this.isSubmit = false;
          this.getEmployeeRules();
  
          this.form.patchValue({
            permission: ''
          })
        })
      )
    }
  }


  ngOnDestroy() {
    this.clearSubscriptions()
  }

}
