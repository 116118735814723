import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { mimeTypes } from 'mime-wrapper';
import { FileService } from 'src/app/shared/services/rest/file.service';
import { debounceTime, distinctUntilChanged, filter, map, tap } from 'rxjs/operators';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { SnackBarItem } from '../../../snack-bar/snack-bar-item';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { fromEvent, ReplaySubject } from 'rxjs';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ParametersService } from 'src/app/shared/services/rest/parameters.service';
import * as moment from 'moment';

@Component({
  selector: 'app-speech-to-text',
  templateUrl: './speech-to-text.component.html',
  styleUrls: ['./speech-to-text.component.scss'],
    animations: [
      trigger('detailExpand', [
        state('collapsed', style({height: '0px', minHeight: '0'})),
        state('expanded', style({height: '*'})),
        transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      ]),
    ],
})
export class SpeechToTextComponent extends BaseClass implements OnInit, OnDestroy {
  @ViewChild("tableContainer") tableContainer: ElementRef;
  public reqs: any[] = [];
  public mimeTypes = mimeTypes;
  public form: FormGroup;
  public isRefreshed: boolean = false;
  public activeLang: any;
  public timeZone = new Date().getTimezoneOffset()*60;
  public page: number = 1;
  public pagination: any;
  public displayedColumns: string[] = ["Date", "Status", "Language", "Costs"];

  public today = moment().set({hour:0,minute:0,second:0}).unix();
  public todayEnd = moment().endOf('day').unix();
  
  public langs: any;
  public langs$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public langsControl: FormControl = new FormControl();
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<SpeechToTextComponent>,
    private languageService: LanguageService,
    private parametersService: ParametersService,
    private fileService: FileService,
    private layoutService: LayoutService,
    private fb: FormBuilder,
    private dialog: MatDialog,
  ) { super() }

  ngOnInit(): void {
    console.log("SpeechToTextComponent", this.data)
    if (this.timeZone > 0) {
      this.timeZone = -this.timeZone
    } else {
      this.timeZone = Math.abs(this.timeZone)
    }
    this.form = this.fb.group({
      company_id: this.data.file.company_id,
      original_file_id: this.data.file.original_file_id || this.data.file.id,
      language: '',
      detect: false
    })

    if (this.data.file.parameterValuesToTask) {
      let allLangs = this.data.file.parameterValuesToTask.filter(k => k.parameter.is_language);
      let primaryLangs = this.data.file.parameterValuesToTask.filter(k => k.parameter.is_language && !!k.is_primary);
      if (primaryLangs.length) {
        this.form.patchValue({
          language: primaryLangs[0].parameterValue.value
        })
      } else if (allLangs.length) {
        this.form.patchValue({
          language: allLangs[0].parameterValue.value
        })
      }
    }
    this.getLangData();
    this.getSpeechToText(this.page);

    this.getLangs();

    this.attachSubscriptions(
      this.langsControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchLangs(resp))
    )
  }

  getLangById(id) {
    if (!this.langs || this.langs.length == 0) {
      return null
    }
    return this.langs.find(x => x.id == id)
  }

  getLangByVal(iso_value) {
    return this.langs && this.langs.find(x => x.iso_value == iso_value)
  }

  onSearchLangs(resp) {
    if (!this.langs) {
      return;
    }

    if (!resp) {
      this.langs$.next(this.langs.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }

    this.langs$.next(
      this.langs.filter(b => b.value.toLowerCase().indexOf(resp) > -1)
    );
  }

  getLangs() {
    this.attachSubscriptions(
      this.parametersService.getAllValues(1, this.data.company.id, {is_language: 1}, '200').pipe(
        map(res => res.body),
        tap(res => {
          this.langs = res;
          this.langs$.next(this.langs.filter(x => !!x.id).slice())
        }),
      ).subscribe(resp => {
        console.log("this.langs", this.langs)
      })
    )
  }

  log() {
    console.log("this.reqs - " + this.page, this.reqs)
    console.log("this.form.value", this.form.value)
  }

  rowClick(element) {
    console.log(element);
    element.isExpanded = !element.isExpanded
  }

  refresh() {
    this.page = 1;
    this.getSpeechToText(this.page);
  }

  getSpeechToText(n) {
    this.isRefreshed = true;
    this.attachSubscriptions(
      this.fileService.getSpeechToText(n, this.data.company.id, {original_file_id: this.data.file.original_file_id || this.data.file.id}).pipe(
        tap(el => {
          this.pagination = {
            'pageCount': el.headers.get('x-pagination-page-count'),
            'perPage': el.headers.get('x-pagination-per-page'),
            'totalCount': el.headers.get('x-pagination-total-count'),
            'currentPage': el.headers.get('x-pagination-current-page'),
          }
        }),
        map(el => el.body)
      ).subscribe(resp => {
        if (this.page = 1) {
          this.reqs = resp
        } else {
          this.reqs.push(...resp)
        }
        this.page++;
        this.isRefreshed = false;
        console.log("this.reqs - " + this.page, this.reqs)
      })
    )
  }

  ngAfterViewInit() {
    this.tableContainer.nativeElement.scrollTop = 0;
    this.onScroll();
  }
  
  onScroll() {
    this.attachSubscriptions(
      fromEvent(this.tableContainer.nativeElement, "scroll").pipe(
        filter((e:any) => e.target.scrollTop >=  e.target.scrollHeight - e.target.offsetHeight - 400),
        debounceTime(300),
        distinctUntilChanged()
      ).subscribe(() => {
          if (this.page <= this.pagination['pageCount']) {
            this.getSpeechToText(this.page);
          }
        }
      )
    )
  }

  copySpeech() {
    this.layoutService.showSnackBar({name: 'Speech'}, marker("Copied"), SnackBarItem)
  }

  transcribeFile() {
    let formData = {...this.form.value}
    if (!!formData.detect) {
      delete formData.language
    }
    delete formData.detect
    this.attachSubscriptions(
      this.fileService.createSpeechToText(this.data.company.id, formData).subscribe(resp => {
        // this.data.file.is_rev_transcribe = resp.is_rev_transcribe
        this.layoutService.showSnackBar({name: ''}, marker('File in the process of receiving text'), SnackBarItem);
        this.page = 1;
        this.getSpeechToText(this.page);
      },
      error => {
        this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
      })
    )
  }

  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData().subscribe(resp => {
        this.activeLang = resp.active;
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false});
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
