<h2 mat-dialog-title class="dialog_title">
    <span>
        {{ "Replacements settings" | translate }}
    </span>
    <button class="ml-1" mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h2>
<mat-dialog-content class="mat-typography">
    <div class="container task_container form_wrapper" style="padding: 20px; width: calc(100% - 40px);">
        <p>
            <mat-checkbox [(ngModel)]="data.replSettings.is_all_target">{{ "All job" | translate }}</mat-checkbox>
        </p>

        <ng-container *ngIf="!data.replSettings.is_all_target">
            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ 'Folder' | translate }}</mat-label>
                <input [(ngModel)]="data.replSettings.folder" matInput placeholder="{{ 'Folder' | translate }}">
            </mat-form-field>
            
            <p>
                <mat-checkbox [(ngModel)]="data.replSettings.is_location_all">{{ "All subfolders" | translate }}</mat-checkbox>
            </p>
        </ng-container>
        
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-raised-button (click)="close()">
        {{ "Close" | translate }}
    </button>
    <button class="ml-1" mat-raised-button color="primary" (click)="find()">
        {{ "Find" | translate }}
    </button>
    <button class="ml-1" mat-raised-button color="warn" (click)="findAlt()">
        {{ "Alternative Find" | translate }}
    </button>
</mat-dialog-actions>