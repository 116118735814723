<h2 mat-dialog-title class="dialog_title">
    <span>
        {{ "Set group to Scenario" | translate }}
    </span>
    <button mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h2>
<mat-dialog-content class="mat-typography" dirEnter (dirSubmit)="!form.valid ? null : submitForm()">
    <div class="container form_wrapper" style="padding: 20px 0;">
        <form [formGroup]="form">
            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ "Choose a group" | translate }}</mat-label>
                <mat-select formControlName="group_id" required>
                    <mat-option *ngFor="let group of groups" [value]="group.id">
                        {{ group.name | translate }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </form>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button (click)="close()">
        {{ "Back" | translate }}
    </button>
    <button class="ml-1" (click)="submitForm()" mat-raised-button cdkFocusInitial color="primary" [disabled]="!form.valid">
        {{ "Save" | translate }}
    </button>
</mat-dialog-actions>
