import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ReplaySubject, forkJoin } from 'rxjs';
import { debounceTime, last, map, switchMap, tap } from 'rxjs/operators';
import { BaseClass } from 'src/app/shared/models/base-class';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { MembersService } from 'src/app/shared/services/rest/members.service';
import { ParametersService } from 'src/app/shared/services/rest/parameters.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { AssistantSchemePresetComponent } from '../assistant-scheme-preset/assistant-scheme-preset.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { DeleteAlertComponent } from 'src/app/shared/global_components/delete-alert/delete-alert.component';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';

@Component({
  selector: 'app-view-scheme',
  templateUrl: './view-scheme.component.html',
  styleUrls: ['./view-scheme.component.scss']
})

export class ViewSchemeComponent extends BaseClass implements OnInit, OnDestroy {
  public employees: any;
  public employees$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public employeesControl: FormControl = new FormControl();

  public operationStatuses: any;
  public bookmarks: any;
  public jobTypes: any;
  public jobTypesControl: FormControl = new FormControl();
  public jobTypes$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public isSubmit: boolean = false;

  // public allValuesControl: FormControl = new FormControl();
  public allValues$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public platforms: any;
  public parameters: any;
  public paramControl: FormControl = new FormControl();
  public parameters$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public is_scheme_editing: boolean = false;
  public selectedPreset: any;

  public result: any = {
    jobType: '',
    jobStatus: [],
    reflectJobStatus: [],
    platform_id: '',
    tagCategory: '',
    tags: [],
    categoryTags: [],
    refl: '-',
    is_one: false,
    is_two: false,
    ccol1: false,
    ccol2: false,
    ccol3: false
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private taskService: TaskService,
    private companyService: CompanyService,
    private parametersService: ParametersService,
    public dialogRef: MatDialogRef<ViewSchemeComponent>,
    private bottomSheet: MatBottomSheet,
    private layoutService: LayoutService,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private membersService: MembersService
  ) { super() }

  ngOnInit(): void {
    if (this.data.scheme) {
      this.result = JSON.parse(JSON.stringify(this.data.scheme));
      
      if (this.result.tags) {
        this.result.tags = this.result.tags.map(k => k.id)
      }
    }
    this.allValues$.next(this.data.allValues.slice())
    console.log("ViewSchemeComponent", this.data);

    this.attachSubscriptions(
      this.jobTypesControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchJobTypes(resp))
    )

    this.attachSubscriptions(
      this.paramControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchParams(resp))
    )
    this.getBookmarks();
    this.getParameters();
    this.getPlatforms();

    this.operationStatuses = this.data.operationStatuses;
    this.jobTypes = this.data.jobTypes;
    this.jobTypes$.next(this.jobTypes.slice())

    // this.attachSubscriptions(
    //   this.allValuesControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchValues(resp))
    // )
    // this.attachSubscriptions(
    //   this.employeesControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchEmoloyees(resp))
    // )

    // this.getEmployees();
  }

  // onSearchValues(resp) {
  //   if (!this.data.allValues) {
  //     return;
  //   }

  //   if (!resp) {
  //     this.allValues$.next(this.data.allValues.slice());
  //     return;
  //   } else {
  //     resp = resp.toLowerCase();
  //   }
  //   // filter the banks
  //   this.allValues$.next(
  //     this.data.allValues.filter(z => z.value.toLowerCase().indexOf(resp) > -1 || (z.parameter.name && z.parameter.name.toLowerCase().indexOf(resp) > -1))
  //   );
  // }
  getBookmarks() {
    this.attachSubscriptions(
      this.companyService.getBookmarks(this.data.company.id, {employee_id: this.data.company.employees[0].id, section: "Cards", type: "Custom filter scheme"}).subscribe(res => {
        this.bookmarks = res;
        console.log("comp assist bookmarks", this.bookmarks);
      })
    )
  }

  selectPreset(item) {
    console.log("selectPreset", item)
    if (item.settings.devScheme) {
      this.selectedPreset = item;
      this.result = item.settings.devScheme
    }
  }

  toggleSchemeEditing(e) {
    e.preventDefault();
    e.stopPropagation();
    this.is_scheme_editing = !this.is_scheme_editing;
  }

  editPreset(e, trigger:MatMenuTrigger, scheme, task) {
    e.preventDefault();
    e.stopPropagation();

    console.log("trigger", trigger)
    
    trigger.closeMenu();

    console.log("createNewOpenCardClipboard", task)

    const dialogRef = this.dialog.open(AssistantSchemePresetComponent, {
      backdropClass: ['backdrop_under_header'],
      // panelClass: !this.is_mobile ? ['show_header'] : '',
      hasBackdrop: true,
      data: {
        company: this.data.company,
        company_id: this.data.company_id,
        task: task,
        user: this.data.user,
        scheme: scheme,
        groups: this.data.groups,
        allValues: this.data.allValues,
        jobTypes: this.data.jobTypes,
        platforms: this.platforms,
        parameters: this.parameters,
        operationStatuses: this.data.operationStatuses,
        filters: this.selectedPreset && scheme.id == this.selectedPreset.id ? this.result : undefined
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        
        if (!!result && result.event == 'edit') {
          this.getBookmarks();
        }
      })
    )
  }

  deletePreset(e, trigger:MatMenuTrigger, scheme) {
    e.preventDefault();
    e.stopPropagation();

    trigger.closeMenu();
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: scheme,
        target: marker("Custom filter scheme preset")
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        this.attachSubscriptions(
          this.companyService.deleteBookmark(scheme.id).subscribe(res => {
            this.bookmarks.splice(this.bookmarks.findIndex(p => p.id == scheme.id), 1);
            console.log("deleteScheme", this.bookmarks);
          }, error => {
            this.layoutService.showSnackBar({name: ''}, error, SnackBarItem);
          })
        )
      }
    });
  }

  createNewPreset(e) {
    e.preventDefault();

    console.log("createNewPreset", e)

    const dialogRef = this.dialog.open(AssistantSchemePresetComponent, {
      backdropClass: ['backdrop_under_header'],
      // panelClass: !this.is_mobile ? ['show_header'] : '',
      hasBackdrop: true,
      data: {
        company: this.data.company,
        company_id: this.data.company_id,
        groups: this.data.groups,
        user: this.data.user,
        allValues: this.data.allValues,
        platforms: this.platforms,
        parameters: this.parameters,
        jobTypes: this.data.jobTypes,
        operationStatuses: this.data.operationStatuses,
        filters: this.result
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        
        if (!!result && result.event == 'save') {
          this.getBookmarks();
        }
      })
    )
  }
  

  onSearchParams(resp) {
    if (!this.parameters) {
      return;
    }

    console.log("JOBS", this.parameters)
    if (!resp) {
      this.parameters$.next(this.parameters.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }

    this.parameters$.next(this.parameters.filter(z => z.name && z.name.toLowerCase().indexOf(resp) > -1));
  }
  
  getPlatforms() {
    this.attachSubscriptions(
      this.companyService.getGroupPlatforms().subscribe(resp => {
        this.platforms = resp;
        console.log("this.platforms", this.platforms)
      })
    )
  }

  getPlatformById(id) {
    return this.platforms && this.platforms.find(x => x.id == id)
  }
  getParamById(id) {
    return this.parameters && this.parameters.find(x => x.id == id)
  }
  getStatusById(id) {
    return this.operationStatuses && this.operationStatuses.find(x => x.id == id)
  }

  log() {
    console.log("result", this.result)
    // this.scheme.tags = this.allValues.filter(k => k.parameter_id == this.scheme.tagCategory)
  }

  changeTagCategory(e) {
    console.log("changeTagCategory", e);
    this.result.tags = this.data.allValues.filter(k => k.parameter_id == this.result.tagCategory).map(k => k.id);
    this.result.categoryTags = this.data.allValues.filter(k => k.parameter_id == this.result.tagCategory);
  }

  getParameters() {
    this.attachSubscriptions(
      this.parametersService.getApiParameters(this.data.company.id).pipe(
        tap(res => {
          this.parameters = res;
          this.parameters$.next(this.parameters.filter(x => !!x.id).slice())
        }),
      ).subscribe(resp => {
        console.log("this.parameters", this.parameters)
      })
    )
  }

  getTypeById(id) {
    return this.jobTypes && this.jobTypes.find(x => x.id == id)
  }
    
  onSearchJobTypes(resp) {
    if (!this.jobTypes) {
      return;
    }

    if (!resp) {
      this.jobTypes$.next(this.jobTypes.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }

    // filter the banks
    this.jobTypes$.next(
      this.jobTypes.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  onSearchEmoloyees(resp) {
    if (!this.employees) {
      return;
    }

    if (!resp) {
      this.employees$.next(this.employees.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.employees$.next(
      this.employees.filter(z => z.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  getEmployees() {
    this.attachSubscriptions(
      this.taskService.getEmployeesDyn('1', this.data.company.id, null, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }
  
          return forkJoin(arr.map(x => this.taskService.getEmployeesDyn(x, this.data.company.id, null, '200').pipe(map(u => u.body)))).pipe(
            last(),
            tap(values => {
              let conValues = [].concat(...values)
              this.employees = conValues;
              this.employees$.next(this.employees.slice())
            }),
          )
        }),
      ).subscribe(resp => {
        console.log("getEmployees resp", resp);
        console.log("ViewSchemeComponent getEmployees", this.employees);
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false});
  }
  
  default() {
    this.dialogRef.close({event: "default", data: false});
  }

  submitForm() {
    this.dialogRef.close({event: "ok", data: this.result});
  }


  ngOnDestroy() {
    this.clearSubscriptions()
  }

}
