<h2 mat-dialog-title class="dialog_title">
    <span>
        <ng-container *ngIf="data.transaction; else elseTemplateIsAdd">
            {{ "Edit Transaction" | translate }}
        </ng-container>
        <ng-template #elseTemplateIsAdd>
            {{ "Add Transaction" | translate }}
        </ng-template>
    </span>
    <button class="ml-1" mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h2>
<mat-dialog-content class="mat-typography">
    <div class="container form_wrapper">
        <form [formGroup]="form">
            <ng-container *ngIf="!data.transaction && form.get('company_id')">
                <div class="users_select">
                    <!-- <label>{{ "Choose a Workspace" | translate }}: </label> -->
                    <mat-chip-list class="new_task_chips" [multiple]="false" selectable formControlName="company_id">
                        <ng-container *ngFor="let comp of companyes; let i = index">
                            <mat-chip class="chip_user" (click)="toggleChip(comp)" [value]="comp.id">
                                <app-user [class]="'user_div'" [companyEmployee]="comp" [imgRoute]="data?.imgRoute" [host]="data?.host" [company]="comp"></app-user>
                            </mat-chip>
                            <mat-chip *ngIf="i == companyes.length - 1 && i > 6" class="chip_user more" (click)="companyesContainer.open()">
                                {{ "more" | translate }}
                            </mat-chip>
                        </ng-container>
                    </mat-chip-list>
                </div>
    
                <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                    <mat-select #companyesContainer formControlName="company_id" (selectionChange)="toggleChip($event)">
                        <mat-option>
                            <ngx-mat-select-search 
                            placeholderLabel="{{ 'Search' | translate }}" 
                            noEntriesFoundLabel="{{ 'no matching Workspaces found' | translate }}"
                            [formControl]="compMoreControl"></ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let comp of companyes$ | async" [value]="comp.id">
                            <app-user [class]="'user_div'" [companyEmployee]="comp" [imgRoute]="data?.imgRoute" [host]="data?.host" [company]="comp"></app-user>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </ng-container>

            <mat-form-field appearance="standard" class="full_width_form" *ngIf="form.get('message')">
                <mat-label>{{ 'Message' | translate }}</mat-label>
                <input cdkFocusInitial formControlName="message" matInput placeholder="{{ 'Message' | translate }}">
            </mat-form-field>
            <mat-form-field appearance="standard" class="full_width_form" *ngIf="form.get('message_root')">
                <mat-label>{{ 'Message root' | translate }}</mat-label>
                <input cdkFocusInitial formControlName="message_root" matInput placeholder="{{ 'Message root' | translate }}">
            </mat-form-field>

            <mat-form-field appearance="standard" class="full_width_form" *ngIf="form.get('balance_movement')">
                <mat-label>{{ 'Balance movement' | translate }}</mat-label>
                <input formControlName="balance_movement" matInput type="number" placeholder="{{ 'Balance movement' | translate }}">
            </mat-form-field>

            <div *ngIf="form.get('type')" class="d-f-c" style="height: 40px; padding: 0 20px;">
                <mat-radio-group
                    aria-labelledby="example-radio-group-label"
                    class="example-radio-group"
                    formControlName="type">
                    <mat-radio-button class="example-radio-button" [value]="'is_refill'">
                        {{"Refill" | translate}}
                    </mat-radio-button>
                    <mat-radio-button class="ml-1" [value]="'is_expense'">
                        {{"Expense" | translate}}
                    </mat-radio-button>
                    <mat-radio-button class="ml-1" [value]="'is_adjustment'">
                        {{"Adjustment" | translate}}
                    </mat-radio-button>
                </mat-radio-group>
            </div>

            <div *ngIf="form.get('state')" class="d-f-c" style="height: 40px; padding: 0 20px;">
                <mat-radio-group
                    aria-labelledby="example-radio-group-label"
                    class="example-radio-group"
                    formControlName="state">
                    <mat-radio-button class="example-radio-button" [value]="'is_new'">
                        {{"New" | translate}}
                    </mat-radio-button>
                    <mat-radio-button class="ml-1" [value]="'is_done'">
                        {{"Done" | translate}}
                    </mat-radio-button>
                </mat-radio-group>
            </div>

            <div class="d-f-c" style="height: 40px; padding: 0 20px;" *ngIf="form.get('is_hide')">
                <mat-checkbox color="primary" formControlName="is_hide">{{"Hide" | translate}}</mat-checkbox>
            </div>
        </form>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button (click)="close()">
        {{ "Back" | translate }}
    </button>
    <button class="ml-1" (click)="submitForm()" mat-raised-button cdkFocusInitial color="primary" [disabled]="!form.valid">
        {{ "Save" | translate }}
    </button>
</mat-dialog-actions>
