import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { concat } from 'rxjs';
import { tap } from 'rxjs/operators';
import { makeArray } from 'src/app/shared/functions/objToArray';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { FileService } from 'src/app/shared/services/rest/file.service';
import { LoadingService } from 'src/app/shared/services/rest/loading.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';

@Component({
  selector: 'app-multi-migrate',
  templateUrl: './multi-migrate.component.html',
  styleUrls: ['./multi-migrate.component.scss']
})
export class MultiMigrateComponent extends BaseClass implements OnInit, OnDestroy {
  public form: FormGroup;
  public storages: any;
  public isSubmit: boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private ls: LoadingService,
    private sm: StorageManagerService,
    private fileService: FileService,
    private taskService: TaskService,
    public dialogRef: MatDialogRef<MultiMigrateComponent>,
    public layoutService: LayoutService
  ) { super() }

  ngOnInit(): void {
    console.log("MultiMigrateComponent", this.data)
    this.form = this.fb.group({
      company_id: this.data.company.id,
      to_storage_code: ['', Validators.required]
    })
    this.getStorages()
  }

  getStorages() {
    this.attachSubscriptions(
      this.fileService.getStorages(this.data.company.id).subscribe(resp => {
        this.storages = makeArray(resp)
      })
    )
  }

  addMigration() {
    // /api/file-transfer-task/
    // this.fileService.addMigration(this.data.company.id, ).subscribe(resp => {
    //   console.log("addMigration", resp);
    // })

    this.isSubmit = true;

    let sbmtData = [];
    let partsSbmtData = [];

    this.data.selectedFiles.forEach((file, i) => {
      sbmtData.push({
        "path": `/api/file-transfer-task/`,
        "query": {},
        "method": "POST",
        "body": Object.assign({
          [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
        }, Object.assign(this.form.value, {file_id: file.original_file_id}))
      });
    })

    for (let i = 0; i < Math.ceil(sbmtData.length/5); i++) {
      partsSbmtData.push(sbmtData.slice(i*5, 5 + i*5))
    }

    let count = 0;
    concat(...partsSbmtData.map((param) => this.taskService.multiRequest(param))).pipe(
      tap(el => {
        this.ls.requests$.next({
          value: Math.round((100 / partsSbmtData.length) * (count+1)),
          target: "Migrate files",
        })
        if (count == 0) {
          this.isSubmit = false;
          this.close();
        }
        count++;
      })
    ).subscribe(res => {
      console.log("addMigration", res);
    })
  }

  close() {
    this.dialogRef.close({event: "close", data: false});
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
