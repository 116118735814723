<div class="cases_page" *ngIf="filter">
    <div class="container">
        <ng-container *ngIf="!data.is_mobile; else elseTemplateMobFilter">
            <div class="filter_out">  
                <form [formGroup]="filter" class="filter_in">
                    <div class="filter_form_in">
                        <mat-form-field appearance="legacy" class="filter_form type" *ngIf="!useOut">
                            <mat-label>{{ "Status" | translate }}</mat-label>
                            <mat-select [disableOptionCentering]="true" multiple selectDir formControlName="status_id" >
                                <mat-option *ngFor="let status of statuses" [value]="status.id">
                                    {{ status.name | translate }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
              
                        <mat-form-field appearance="legacy" class="filter_form priority">
                            <mat-label>{{ "Priority" | translate }}</mat-label>
                            <mat-select [disableOptionCentering]="true" multiple selectDir formControlName="priority" >
                                <mat-option *ngFor="let priority of prioritys" [value]="priority.id">
                                    {{ priority.name | translate }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                
                        <mat-form-field appearance="legacy" class="filter_form group">
                            <mat-label>{{ "Group" | translate }}</mat-label>
                            <mat-select [disableOptionCentering]="true" selectDir formControlName="group_id">
                                <mat-option>
                                  <ngx-mat-select-search 
                                      placeholderLabel="{{ 'Search' | translate }}" 
                                      noEntriesFoundLabel="{{ 'no matching group found' | translate }}"
                                      [formControl]="groupsControl"></ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let group of groups$ | async" [value]="group.id">
                                    {{ group.name | translate }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    
                        <mat-form-field appearance="legacy" class="filter_form executor">
                            <mat-label>{{ "Executor" | translate }}</mat-label>
                            <mat-select [disableOptionCentering]="true" multiple selectDir formControlName="employee_id" >
                                <mat-option>
                                    <ngx-mat-select-search 
                                        placeholderLabel="{{ 'Search' | translate }}" 
                                        noEntriesFoundLabel="{{ 'no matching member found' | translate }}"
                                        [formControl]="execControl"></ngx-mat-select-search>
                                  </mat-option>
                                  <mat-option *ngFor="let employee of employees$ | async" [value]="employee.id">
                                    <app-user [class]="'user_div'" [employee]="employee"></app-user>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
            
                        <mat-form-field appearance="legacy" class="filter_form executor">
                            <mat-label>{{ "Client" | translate }}</mat-label>
                            <mat-select [disableOptionCentering]="true" multiple selectDir formControlName="client_employee_id" >
                                <mat-option>
                                    <ngx-mat-select-search 
                                        placeholderLabel="{{ 'Search' | translate }}" 
                                        noEntriesFoundLabel="{{ 'no matching member found' | translate }}"
                                        [formControl]="execControl"></ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let employee of employees$ | async" [value]="employee.id">
                                    <app-user [class]="'user_div'" [employee]="employee"></app-user>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                
                        <mat-form-field appearance="legacy" class="filter_form job" *ngIf="!useOut">
                            <mat-label>{{ "Work" | translate }}</mat-label>
                            <mat-select [disableOptionCentering]="true" multiple selectDir formControlName="operation_id" >
                                <mat-option *ngFor="let op of operationsValues" [value]="op.id">
                                    {{ op.name | translate }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
            
                   
                        <mat-form-field appearance="legacy" class="example-form-field filter_form date">
                            <mat-label>{{ 'Chat date' | translate }}</mat-label>
                            <mat-date-range-input  [rangePicker]="rangePicker">
                                <input formControlName="created_at_from" matStartDate placeholder="{{ 'Date from' | translate }}">
                                <input formControlName="created_at_to" matEndDate placeholder="{{ 'Date to' | translate }}">
                            </mat-date-range-input>
                            <mat-datepicker-toggle matPrefix [for]="rangePicker"></mat-datepicker-toggle>
                            <mat-date-range-picker #rangePicker>
                                <mat-date-range-picker-actions>
                                <button mat-button matDateRangePickerCancel>Cancel</button>
                                <button mat-raised-button color="primary" matDateRangePickerApply>Apply</button>
                                </mat-date-range-picker-actions>
                            </mat-date-range-picker>
                        </mat-form-field>
            
                        <mat-form-field appearance="legacy" class="example-form-field filter_form date">
                            <mat-label>{{ 'Post date' | translate }}</mat-label>
                            <mat-date-range-input  [rangePicker]="postRangePick">
                                <input formControlName="posted_at_from" matStartDate placeholder="{{ 'Date from' | translate }}">
                                <input formControlName="posted_at_to" matEndDate placeholder="{{ 'Date to' | translate }}">
                            </mat-date-range-input>
                            <mat-datepicker-toggle matPrefix [for]="postRangePick"></mat-datepicker-toggle>
                            <mat-date-range-picker #postRangePick>
                                <mat-date-range-picker-actions>
                                <button mat-button matDateRangePickerCancel>Cancel</button>
                                <button mat-raised-button color="primary" matDateRangePickerApply>Apply</button>
                                </mat-date-range-picker-actions>
                            </mat-date-range-picker>
                        </mat-form-field>
                      </div>
                </form>
              </div>
            
            
              <div class="lists_wrp">
                <div class="lists_left">
                  <div class="job_status_wrp">
                    <ng-container *ngFor="let stat of statusesForFilter">
                        <button *ngIf="stat.hasChats" mat-button (click)='changeActiveStatus(stat.id, $event)' [ngClass]="{'active_status': activeStatus.includes(stat.id)}">{{ stat.name | translate }}</button>
                      </ng-container>
                  </div>
                </div>
          
                <div class="d-f-c">          
                  <button type="button" mat-icon-button [matMenuTriggerFor]="sortBtn" class="mobActiveFilter" #tooltip="matTooltip" matTooltip="{{ 'Sort' | translate }}" [matTooltipPosition]="'above'">
                    <mat-icon>sort</mat-icon>
                  </button>
                </div>
          
                <mat-menu #sortBtn="matMenu" class="sort_tablet" xPosition='before' overlayPanelClass='sortTAB'>
                    <div class="mat-menu-title">{{"Sort By" | translate}}:</div>
                    <mat-divider></mat-divider>
                    <button mat-menu-item (click)="sort(sortValue == 'updated_desc' ? 'updated' : 'updated_desc')" [ngClass]="{'active_status': sortValue == 'updated_desc' || sortValue == 'updated'}">
                        {{  "Updated" | translate }}
                        <div class="d-f-c ml-1">
                            <mat-icon (click)="$event.preventDefault(); $event.stopPropagation(); sort('updated_desc')" [ngClass]="{'active': sortValue == 'updated_desc'}" style="margin-right: 0 !important;">arrow_drop_down</mat-icon>
                            <mat-icon (click)="$event.preventDefault(); $event.stopPropagation(); sort('updated')" [ngClass]="{'active': sortValue == 'updated'}" style="margin-right: 0 !important;">arrow_drop_up</mat-icon>
                        </div>
                    </button>
                    <button mat-menu-item  (click)="sort(sortValue == 'priority_desc' ? 'priority' : 'priority_desc')" [ngClass]="{'active_status': sortValue == 'priority_desc' || sortValue == 'priority'}">
                        {{  "Priority" | translate }}
                        <div class="d-f-c ml-1">
                            <mat-icon (click)="$event.preventDefault(); $event.stopPropagation(); sort('priority_desc')" [ngClass]="{'active': sortValue == 'priority_desc'}" style="margin-right: 0 !important;">arrow_drop_down</mat-icon>
                            <mat-icon (click)="$event.preventDefault(); $event.stopPropagation(); sort('priority')" [ngClass]="{'active': sortValue == 'priority'}" style="margin-right: 0 !important;">arrow_drop_up</mat-icon>
                        </div>
                    </button>
                    <button mat-menu-item  (click)="sort(sortValue == 'id_desc' ? 'id' : 'id_desc')" [ngClass]="{'active_status': sortValue == 'id_desc' || sortValue == 'id'}">
                        {{  "ID" | translate }}
                        <div class="d-f-c ml-1">
                            <mat-icon (click)="$event.preventDefault(); $event.stopPropagation(); sort('id_desc')" [ngClass]="{'active': sortValue == 'id_desc'}" style="margin-right: 0 !important;">arrow_drop_down</mat-icon>
                            <mat-icon (click)="$event.preventDefault(); $event.stopPropagation(); sort('id')" [ngClass]="{'active': sortValue == 'id'}" style="margin-right: 0 !important;">arrow_drop_up</mat-icon>
                        </div>
                    </button>
                    <button mat-menu-item  (click)="sort(sortValue == 'filesize_desc' ? 'filesize' : 'filesize_desc')" [ngClass]="{'active_status': sortValue == 'filesize_desc' || sortValue == 'filesize'}">
                        {{ "Filesize" | translate }}
                        <div class="d-f-c ml-1">
                            <mat-icon (click)="$event.preventDefault(); $event.stopPropagation(); sort('filesize_desc')" [ngClass]="{'active': sortValue == 'filesize_desc'}" style="margin-right: 0 !important;">arrow_drop_down</mat-icon>
                            <mat-icon (click)="$event.preventDefault(); $event.stopPropagation(); sort('filesize')" [ngClass]="{'active': sortValue == 'filesize'}" style="margin-right: 0 !important;">arrow_drop_up</mat-icon>
                        </div>
                    </button>
                </mat-menu>
              </div>
        </ng-container>
        <ng-template #elseTemplateMobFilter>
            <div class="d-f-c" style="justify-content: flex-end;">
                <button mat-icon-button (click)="close()">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
            <form [formGroup]="filter" class="filter_wrp">
                <div class="mob_filter_inputs_wrp">
                    <mat-form-field appearance="standard" class="filter_form type" *ngIf="!useOut">
                        <mat-label>{{ "Status" | translate }}</mat-label>
                        <mat-select [disableOptionCentering]="true" multiple selectDir formControlName="status_id" >
                            <mat-option *ngFor="let status of statuses" [value]="status.id">
                                {{ status.name | translate }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
          
                    <mat-form-field appearance="standard" class="filter_form priority">
                        <mat-label>{{ "Priority" | translate }}</mat-label>
                        <mat-select [disableOptionCentering]="true" multiple selectDir formControlName="priority" >
                            <mat-option *ngFor="let priority of prioritys" [value]="priority.id">
                                {{ priority.name | translate }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
            
                    <mat-form-field appearance="legacy" class="filter_form group">
                        <mat-label>{{ "Group" | translate }}</mat-label>
                        <mat-select [disableOptionCentering]="true" selectDir formControlName="group_id">
                            <mat-option>
                              <ngx-mat-select-search 
                                  placeholderLabel="{{ 'Search' | translate }}" 
                                  noEntriesFoundLabel="{{ 'no matching group found' | translate }}"
                                  [formControl]="groupsControl"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let group of groups$ | async" [value]="group.id">
                                {{ group.name | translate }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                
                    <mat-form-field appearance="standard" class="filter_form executor">
                        <mat-label>{{ "Executor" | translate }}</mat-label>
                        <mat-select [disableOptionCentering]="true" multiple selectDir formControlName="employee_id" >
                            <mat-option>
                                    <ngx-mat-select-search 
                                        placeholderLabel="{{ 'Search' | translate }}" 
                                        noEntriesFoundLabel="{{ 'no matching member found' | translate }}"
                                        [formControl]="execControl"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let employee of employees$ | async" [value]="employee.id">
                                <app-user [class]="'user_div'" [employee]="employee"></app-user>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
        
                    <mat-form-field appearance="standard" class="filter_form executor">
                        <mat-label>{{ "Client" | translate }}</mat-label>
                        <mat-select [disableOptionCentering]="true" multiple selectDir formControlName="client_employee_id" >
                            <mat-option>
                                <ngx-mat-select-search 
                                    placeholderLabel="{{ 'Search' | translate }}" 
                                    noEntriesFoundLabel="{{ 'no matching member found' | translate }}"
                                    [formControl]="execControl"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let employee of employees$ | async" [value]="employee.id">
                                <app-user [class]="'user_div'" [employee]="employee"></app-user>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
            
                    <mat-form-field appearance="standard" class="filter_form job" *ngIf="!useOut">
                        <mat-label>{{ "Work" | translate }}</mat-label>
                        <mat-select [disableOptionCentering]="true" multiple selectDir formControlName="operation_id" >
                            <mat-option *ngFor="let op of operationsValues" [value]="op.id">
                                {{ op.name | translate }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
        
               
                    <mat-form-field appearance="standard" class="example-form-field filter_form date">
                        <mat-label>{{ 'Chat date' | translate }}</mat-label>
                        <mat-date-range-input  [rangePicker]="rangePicker">
                            <input formControlName="created_at_from" matStartDate placeholder="{{ 'Date from' | translate }}">
                            <input formControlName="created_at_to" matEndDate placeholder="{{ 'Date to' | translate }}">
                        </mat-date-range-input>
                        <mat-datepicker-toggle matPrefix [for]="rangePicker"></mat-datepicker-toggle>
                        <mat-date-range-picker #rangePicker>
                            <mat-date-range-picker-actions>
                            <button mat-button matDateRangePickerCancel>Cancel</button>
                            <button mat-raised-button color="primary" matDateRangePickerApply>Apply</button>
                            </mat-date-range-picker-actions>
                        </mat-date-range-picker>
                    </mat-form-field>
        
                    <mat-form-field appearance="standard" class="example-form-field filter_form date">
                        <mat-label>{{ 'Post date' | translate }}</mat-label>
                        <mat-date-range-input  [rangePicker]="postRangePick">
                            <input formControlName="posted_at_from" matStartDate placeholder="{{ 'Date from' | translate }}">
                            <input formControlName="posted_at_to" matEndDate placeholder="{{ 'Date to' | translate }}">
                        </mat-date-range-input>
                        <mat-datepicker-toggle matPrefix [for]="postRangePick"></mat-datepicker-toggle>
                        <mat-date-range-picker #postRangePick>
                            <mat-date-range-picker-actions>
                            <button mat-button matDateRangePickerCancel>Cancel</button>
                            <button mat-raised-button color="primary" matDateRangePickerApply>Apply</button>
                            </mat-date-range-picker-actions>
                        </mat-date-range-picker>
                    </mat-form-field>
                </div>
              </form>
        </ng-template>
    </div>
</div>