<div class="container">
    <div class="page_start">

        <div class="left_start">
            <div class="left_start_info">
                <p>{{"Select some criteria to see the results" | translate}}</p>
                <div class="d-f-c" style="justify-content: space-between;">
                    <p>{{"Click the criteria to get instant results or use checkbox to combine Criteria then press button “Search” to get results." | translate}}</p>
                    <b class="cp" (click)="saveFilterToBookmarks()">{{'Save as preset' | translate}}</b>
                </div>
            </div>
            <div class="search_start_result">
                <ng-container *ngIf="filter.get('analytics_views_count_from').value || filter.get('analytics_views_count_to').value || filter?.get('analytics_views_count_to').value == '0' || filter.get('created_at_from').value || filter.get('created_at_to').value || filter?.get('created_at_to').value == '0' || filter.get('content_published_at_from').value || filter.get('content_published_at_to').value || filter?.get('content_published_at_to').value == '0' || filter.get('q').value || (filter.get('content_status_id').value && filter.get('content_status_id').value.length) || filter.get('content_type_id').value || (filter.get('group_id').value && filter.get('group_id').value.length) || (filter.get('channel_platform_id').value && filter.get('channel_platform_id').value.length) || (filter.get('channel_id').value && filter.get('channel_id').value.length) || (filter.get('content_status_id').value && filter.get('content_status_id').value.length); else elseTemplateNoChips">   
                  <mat-chip-list class="chips_wrp without_ordered start_page_chips">
              
                      <mat-chip (removed)="removeValue('q')" *ngIf="filter.get('q').value">
                        {{filter.get('q').value}}
                        <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
                      </mat-chip>
              
                      <!-- <mat-chip (removed)="removeValue('task_status_id')" *ngIf="filter.get('task_status_id').value">
                        {{ getTaskStatusById(filter.get('task_status_id').value)?.name }}
                        <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
                      </mat-chip> -->
  
                      <mat-chip (removed)="removeValue('task_custom_id')" *ngIf="filter?.get('task_custom_id')?.value">
                        {{ "Card custom ID" | translate }}: {{ filter?.get('task_custom_id')?.value }}
                        <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
                      </mat-chip>
  
                      <mat-chip (removed)="removeValue('content_status_id')" *ngIf="filter.get('content_status_id').value && filter.get('content_status_id').value.length">
                        {{ getStatusById(filter.get('content_status_id').value).name+'_profile' | translate }}
                        <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
                      </mat-chip>
          
                      <!-- <mat-chip (removed)="removeValue('views_update_interval')" *ngIf="filter.get('views_update_interval').value">
                        {{ getViewsUpdateIntervalListByValue(filter.get('views_update_interval').value)?.name | translate }}
                        <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
                      </mat-chip> -->
                      
                      <!-- <mat-chip (removed)="removeValue('external_status')" *ngIf="filter.get('external_status').value">
                        {{ "Privacy Status" | translate }}: <span class="fl">{{ filter.get('external_status').value | translate }}</span>
                        <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
                      </mat-chip> -->
          
                      <ng-container *ngIf="filter.get('group_id').value.length">
                        <span class="chip_out_label">{{ "Group" | translate }}</span>
                        <mat-chip *ngFor="let id of filter.get('group_id').value" (removed)="removeValueFromMultiple('group_id', id)">
                          <ng-container *ngIf="id === 0; else elseTemplateNoGroup">
                            {{ "Goup not set" | translate }}
                          </ng-container>
                          <ng-template #elseTemplateNoGroup>
                            {{getGroup(id) ? getGroup(id)?.name : ''}}
                            <!-- {{getGroup(id) ? getGroup(id)?.name : getPartnerGroup(id)?.name}} -->
                          </ng-template>
                          <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
                        </mat-chip>
                      </ng-container>
          
                      <!-- <mat-chip (removed)="removeValue('is_content_views_count_update')" *ngIf="filter.get('is_content_views_count_update').value">
                          <ng-container [ngSwitch]="filter.get('is_content_views_count_update').value">
                              <ng-container *ngSwitchCase="0">
                                  {{ "Without auto-update" | translate }}
                              </ng-container>
                              <ng-container *ngSwitchCase="1">
                                  {{ "With auto-update" | translate }}
                              </ng-container>
                              <ng-container *ngSwitchCase="2">
                                  {{ "Error in update" | translate }}
                              </ng-container>
                          </ng-container>
                        <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
                      </mat-chip> -->
          
                      <!-- <mat-chip (removed)="removeValue('is_bravourl_content_views_count_update')" *ngIf="filter.get('is_bravourl_content_views_count_update').value">
                          <ng-container [ngSwitch]="filter.get('is_bravourl_content_views_count_update').value">
                              <ng-container *ngSwitchCase="0">
                                  {{ "Without auto-update" | translate }}
                              </ng-container>
                              <ng-container *ngSwitchCase="1">
                                  {{ "With auto-update" | translate }}
                              </ng-container>
                              <ng-container *ngSwitchCase="2">
                                  {{ "Error in update" | translate }}
                              </ng-container>
                          </ng-container>
                        <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
                      </mat-chip> -->
          
                      <mat-chip (removed)="removeValue('content_type_id')" *ngIf="filter.get('content_type_id').value || filter.get('content_type_id').value.toString() == '0'">
                          <ng-container *ngIf="filter.get('content_type_id').value.toString() == '0'; else elseTemplateZeroContent">
                              {{ "Content Type" | translate }}: {{ "Not set" | translate }}
                          </ng-container>
                          <ng-template #elseTemplateZeroContent>
                              {{ "Content Type" | translate }}: {{ getContentById(filter.get('content_type_id').value)?.name }}
                          </ng-template>
                       
                          <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
                      </mat-chip>
          
                      <mat-chip class="user_chip" (removed)="removeValue('channel_platform_id')" *ngIf="filter.get('channel_platform_id').value || filter.get('channel_platform_id').value.toString() == '0'">
                        <div class="platform_wrp">
                          <div>
                              <app-platform [platform_id]="filter.get('channel_platform_id').value"></app-platform>
                          </div>
                          {{ getPlatformById(filter.get('channel_platform_id').value)?.name }}
                        </div>
                        <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
                      </mat-chip>
          
                      <!-- <ng-container *ngIf="filter.get('task_parameter_value_id').value.length">
                        <span class="chip_out_label">{{ "Tag for task" | translate }}</span>
                        <mat-chip *ngFor="let forTaskParamValueId of filter.get('task_parameter_value_id').value" (removed)="removeValueFromMultiple('task_parameter_value_id', forTaskParamValueId)">
                           {{ getValueById(forTaskParamValueId)?.value }}
                          <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
                        </mat-chip>
                      </ng-container>
            
                      <ng-container *ngIf="filter.get('task_operation_parameter_value_id').value.length">
                        <span class="chip_out_label">{{ "Tag for job" | translate }}</span>
                        <mat-chip *ngFor="let forTaskParamValueId of filter.get('task_operation_parameter_value_id').value" (removed)="removeValueFromMultiple('task_operation_parameter_value_id', forTaskParamValueId)">
                           {{ getValueById(forTaskParamValueId)?.value }}
                          <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
                        </mat-chip>
                      </ng-container>
            
                      <ng-container *ngIf="filter.get('discussion_parameter_value_id').value.length">
                        <span class="chip_out_label">{{ "Tag for chat" | translate }}</span>
                        <mat-chip *ngFor="let forTaskParamValueId of filter.get('discussion_parameter_value_id').value" (removed)="removeValueFromMultiple('discussion_parameter_value_id', forTaskParamValueId)">
                           {{ getValueById(forTaskParamValueId)?.value }}
                          <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
                        </mat-chip>
                      </ng-container>
            
                      <ng-container *ngIf="filter.get('file_parameter_value_id').value.length">
                        <span class="chip_out_label">{{ "Tag for file" | translate }}</span>
                        <mat-chip *ngFor="let forTaskParamValueId of filter.get('file_parameter_value_id').value" (removed)="removeValueFromMultiple('file_parameter_value_id', forTaskParamValueId)">
                           {{ getValueById(forTaskParamValueId)?.value }}
                          <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
                        </mat-chip>
                      </ng-container>
            
                      <ng-container *ngIf="filter.get('parameter_value_id').value.length">
                        <span class="chip_out_label">{{ "Tag for everywhere" | translate }}</span>
                        <mat-chip *ngFor="let forTaskParamValueId of filter.get('parameter_value_id').value" (removed)="removeValueFromMultiple('parameter_value_id', forTaskParamValueId)">
                           {{ getValueById(forTaskParamValueId)?.value }}
                          <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
                        </mat-chip>
                      </ng-container> -->
            
                      <!-- <mat-chip class="red_chip" (removed)="removeValue('no_operation_id')" *ngIf="filter.get('no_operation_id').value">
                        {{ "Not assigned" | translate }}: {{ getColumnName(filter.get('no_operation_id').value) }}
                        <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
                      </mat-chip>
          
                      <mat-chip (removed)="removeValue('scheduled_published_time_by')" *ngIf="filter.get('scheduled_published_time_by').value">
                        {{ "Posting method" | translate }}: {{filter.get('scheduled_published_time_by').value + '_sch_type' | translate}}
                        <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
                      </mat-chip> -->
          
                      <!-- <mat-chip class="user_chip" (removed)="removeValue('operation_partner_company_id')" *ngIf="filter.get('operation_partner_company_id').value">
                        {{ "Partner" | translate }}:
                        <app-user [class]="'user_div ml-1'" [companyEmployee]="getPartner(filter.get('operation_partner_company_id').value)?.partnerCompany" [imgRoute]="imgRoute" [host]="host" [company]="company"></app-user>
                        <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
                      </mat-chip>
          
                      <mat-chip class="user_chip" (removed)="removeValue('origin_company_id')" *ngIf="filter.get('origin_company_id').value">
                        {{ "Partner Client" | translate }}:
                        <app-user [class]="'user_div ml-1'" [companyEmployee]="getPartner(filter.get('origin_company_id').value)?.partnerCompany" [imgRoute]="imgRoute" [host]="host" [company]="company"></app-user>
                        <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
                      </mat-chip> -->
                      
                      <!-- <ng-container *ngIf="filter.get('created_employee_id').value && filter.get('created_employee_id').value.length > 0 && !filter.get('created_employee_id').value.includes(0)">
                          <mat-chip class="user_chip" (removed)="removeValue('created_employee_id')" >
                              <span class="mr-1 ml-1" style="font-weight: 500;">{{ "Created profile" | translate }}</span>
                              <app-user [class]="'user_div'" [employee]="getEmployee(filter.get('created_employee_id').value)"></app-user>
                              <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
                          </mat-chip>
                      </ng-container>
                      <ng-container *ngIf="filter.get('updated_employee_id').value && filter.get('updated_employee_id').value.length > 0 && !filter.get('updated_employee_id').value.includes(0)">
                          <mat-chip class="user_chip" (removed)="removeValue('updated_employee_id')" >
                              <span class="mr-1 ml-1" style="font-weight: 500;">{{ "Updated profile" | translate }}</span>
                              <app-user [class]="'user_div'" [employee]="getEmployee(filter.get('updated_employee_id').value)"></app-user>
                              <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
                          </mat-chip>
                      </ng-container>
                      <ng-container *ngIf="filter.get('created_url_employee_id').value && filter.get('created_url_employee_id').value.length > 0 && !filter.get('created_url_employee_id').value.includes(0)">
                          <mat-chip class="user_chip" (removed)="removeValue('created_url_employee_id')" >
                              <span class="mr-1 ml-1" style="font-weight: 500;">{{ "Created url" | translate }}</span>
                              <app-user [class]="'user_div'" [employee]="getEmployee(filter.get('created_url_employee_id').value)"></app-user>
                              <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
                          </mat-chip>
                      </ng-container>
                      <ng-container *ngIf="filter.get('updated_url_employee_id').value && filter.get('updated_url_employee_id').value.length > 0 && !filter.get('updated_url_employee_id').value.includes(0)">
                          <mat-chip class="user_chip" (removed)="removeValue('updated_url_employee_id')" >
                              <span class="mr-1 ml-1" style="font-weight: 500;">{{ "Updated url" | translate }}</span>
                              <app-user [class]="'user_div'" [employee]="getEmployee(filter.get('updated_url_employee_id').value)"></app-user>
                              <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
                          </mat-chip>
                      </ng-container> -->
          
                      <!-- <mat-chip (removed)="removeValue('is_content_url')" *ngIf="filter.get('is_content_url').value || filter?.get('is_content_url').value == '0'">
                        {{ contentUrl[filter.get('is_content_url').value].name }}
                        <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
                      </mat-chip> -->
          
                    <!-- filter.get('created_at_from').value || filter.get('created_at_to').value || filter?.get('created_at_to').value == '0' || filter.get('task_created_at_from').value || filter.get('task_created_at_to').value || filter?.get('task_created_at_to').value == '0' || filter.get('task_operation_created_at_from').value || filter.get('task_operation_created_at_to').value || filter?.get('task_operation_created_at_to').value == '0' || filter.get('task_operation_completed_at_from').value || filter.get('task_operation_completed_at_to').value || filter?.get('task_operation_completed_at_to').value == '0' || filter.get('discussion_created_at_from').value || filter.get('discussion_created_at_to').value || filter?.get('discussion_created_at_to').value == '0' || filter.get('file_created_at_from').value || filter.get('file_created_at_to').value || filter?.get('file_created_at_to').value == '0' || filter.get('file_updated_at_from').value || filter.get('file_updated_at_to').value || filter?.get('file_updated_at_to').value == '0' || filter.get('file_meta_creation_time_from').value || filter.get('file_meta_creation_time_to').value || filter?.get('file_meta_creation_time_to').value == '0' || filter.get('task_channel_published_at_from').value || filter.get('task_channel_published_at_to').value || filter?.get('task_channel_published_at_to').value == '0' -->
                    <!-- <ng-container *ngIf="filter.get('task_created_at_from').value || filter.get('task_created_at_to').value || filter?.get('task_created_at_to').value == '0'">
                      <span class="chip_out_label">{{ "Card Added" | translate }}</span>
          
                      <div class="chip_splash"></div>
                      
                      <ng-container *ngIf="filter.get('task_created_at_from').value">
                        <span class="small_chip_out_label">{{ "from" | translate}}</span>
                        <mat-chip (removed)="removeValue('task_created_at_from')">
                          {{ filter?.get('task_created_at_from').value | date:'MMM dd. Y':'':activeLang | yearPipe }}
                          <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
                        </mat-chip>
                      </ng-container>
              
                      <ng-container *ngIf="filter.get('task_created_at_to').value || filter?.get('task_created_at_to').value == '0'">
                        <span class="small_chip_out_label">{{ "to" | translate}}</span>
                        <mat-chip (removed)="removeValue('task_created_at_to')">
                          <ng-container *ngIf="filter.get('task_created_at_to').value == 0; else elseTemplateCompletedFilter">
                            {{ "Without date" | translate }}
                          </ng-container>
                          <ng-template #elseTemplateCompletedFilter>
                            {{ filter?.get('task_created_at_to').value | date:'MMM dd. Y':'':activeLang | yearPipe }}
                          </ng-template>
                          <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
                        </mat-chip>
                      </ng-container>
                    </ng-container>
          
                    <ng-container *ngIf="filter.get('task_operation_created_at_from').value || filter.get('task_operation_created_at_to').value || filter?.get('task_operation_created_at_to').value == '0'">
                      <span class="chip_out_label">{{ "Job added" | translate }}</span>
          
                      <div class="chip_splash"></div>
                      
                      <ng-container *ngIf="filter.get('task_operation_created_at_from').value">
                        <span class="small_chip_out_label">{{ "from" | translate}}</span>
                        <mat-chip (removed)="removeValue('task_operation_created_at_from')">
                          {{ filter?.get('task_operation_created_at_from').value | date:'MMM dd. Y':'':activeLang | yearPipe }}
                          <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
                        </mat-chip>
                      </ng-container>
              
                      <ng-container *ngIf="filter.get('task_operation_created_at_to').value || filter?.get('task_operation_created_at_to').value == '0'">
                        <span class="small_chip_out_label">{{ "to" | translate}}</span>
                        <mat-chip (removed)="removeValue('task_operation_created_at_to')">
                          <ng-container *ngIf="filter.get('task_operation_created_at_to').value == 0; else elseTemplateCompletedFilter">
                            {{ "Without date" | translate }}
                          </ng-container>
                          <ng-template #elseTemplateCompletedFilter>
                            {{ filter?.get('task_operation_created_at_to').value | date:'MMM dd. Y':'':activeLang | yearPipe }}
                          </ng-template>
                          <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
                        </mat-chip>
                      </ng-container>
                    </ng-container>
          
                    <ng-container *ngIf="filter.get('task_operation_completed_at_from').value || filter.get('task_operation_completed_at_to').value || filter?.get('task_operation_completed_at_to').value == '0'">
                      <span class="chip_out_label">{{ "Job completed" | translate }}</span>
          
                      <div class="chip_splash"></div>
                      
                      <ng-container *ngIf="filter.get('task_operation_completed_at_from').value">
                        <span class="small_chip_out_label">{{ "from" | translate}}</span>
                        <mat-chip (removed)="removeValue('task_operation_completed_at_from')">
                          {{ filter?.get('task_operation_completed_at_from').value | date:'MMM dd. Y':'':activeLang | yearPipe }}
                          <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
                        </mat-chip>
                      </ng-container>
              
                      <ng-container *ngIf="filter.get('task_operation_completed_at_to').value || filter?.get('task_operation_completed_at_to').value == '0'">
                        <span class="small_chip_out_label">{{ "to" | translate}}</span>
                        <mat-chip (removed)="removeValue('task_operation_completed_at_to')">
                          <ng-container *ngIf="filter.get('task_operation_completed_at_to').value == 0; else elseTemplateCompletedFilter">
                            {{ "Without date" | translate }}
                          </ng-container>
                          <ng-template #elseTemplateCompletedFilter>
                            {{ filter?.get('task_operation_completed_at_to').value | date:'MMM dd. Y':'':activeLang | yearPipe }}
                          </ng-template>
                          <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
                        </mat-chip>
                      </ng-container>
                    </ng-container>
          
                    <ng-container *ngIf="filter.get('discussion_created_at_from').value || filter.get('discussion_created_at_to').value || filter?.get('discussion_created_at_to').value == '0'">
                      <span class="chip_out_label">{{ "Chat added" | translate }}</span>
          
                      <div class="chip_splash"></div>
                      
                      <ng-container *ngIf="filter.get('discussion_created_at_from').value">
                        <span class="small_chip_out_label">{{ "from" | translate}}</span>
                        <mat-chip (removed)="removeValue('discussion_created_at_from')">
                          {{ filter?.get('discussion_created_at_from').value | date:'MMM dd. Y':'':activeLang | yearPipe }}
                          <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
                        </mat-chip>
                      </ng-container>
              
                      <ng-container *ngIf="filter.get('discussion_created_at_to').value || filter?.get('discussion_created_at_to').value == '0'">
                        <span class="small_chip_out_label">{{ "to" | translate}}</span>
                        <mat-chip (removed)="removeValue('discussion_created_at_to')">
                          <ng-container *ngIf="filter.get('discussion_created_at_to').value == 0; else elseTemplateCompletedFilter">
                            {{ "Without date" | translate }}
                          </ng-container>
                          <ng-template #elseTemplateCompletedFilter>
                            {{ filter?.get('discussion_created_at_to').value | date:'MMM dd. Y':'':activeLang | yearPipe }}
                          </ng-template>
                          <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
                        </mat-chip>
                      </ng-container>
                    </ng-container>
          
                    <ng-container *ngIf="filter.get('file_created_at_from').value || filter.get('file_created_at_to').value || filter?.get('file_created_at_to').value == '0'">
                      <span class="chip_out_label">{{ "File added" | translate }}</span>
          
                      <div class="chip_splash"></div>
                      
                      <ng-container *ngIf="filter.get('file_created_at_from').value">
                        <span class="small_chip_out_label">{{ "from" | translate}}</span>
                        <mat-chip (removed)="removeValue('file_created_at_from')">
                          {{ filter?.get('file_created_at_from').value | date:'MMM dd. Y':'':activeLang | yearPipe }}
                          <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
                        </mat-chip>
                      </ng-container>
              
                      <ng-container *ngIf="filter.get('file_created_at_to').value || filter?.get('file_created_at_to').value == '0'">
                        <span class="small_chip_out_label">{{ "to" | translate}}</span>
                        <mat-chip (removed)="removeValue('file_created_at_to')">
                          <ng-container *ngIf="filter.get('file_created_at_to').value == 0; else elseTemplateCompletedFilter">
                            {{ "Without date" | translate }}
                          </ng-container>
                          <ng-template #elseTemplateCompletedFilter>
                            {{ filter?.get('file_created_at_to').value | date:'MMM dd. Y':'':activeLang | yearPipe }}
                          </ng-template>
                          <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
                        </mat-chip>
                      </ng-container>
                    </ng-container>
          
                    <ng-container *ngIf="filter.get('file_updated_at_from').value || filter.get('file_updated_at_to').value || filter?.get('file_updated_at_to').value == '0'">
                      <span class="chip_out_label">{{ "File updated" | translate }}</span>
          
                      <div class="chip_splash"></div>
                      
                      <ng-container *ngIf="filter.get('file_updated_at_from').value">
                        <span class="small_chip_out_label">{{ "from" | translate}}</span>
                        <mat-chip (removed)="removeValue('file_updated_at_from')">
                          {{ filter?.get('file_updated_at_from').value | date:'MMM dd. Y':'':activeLang | yearPipe }}
                          <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
                        </mat-chip>
                      </ng-container>
              
                      <ng-container *ngIf="filter.get('file_updated_at_to').value || filter?.get('file_updated_at_to').value == '0'">
                        <span class="small_chip_out_label">{{ "to" | translate}}</span>
                        <mat-chip (removed)="removeValue('file_updated_at_to')">
                          <ng-container *ngIf="filter.get('file_updated_at_to').value == 0; else elseTemplateCompletedFilter">
                            {{ "Without date" | translate }}
                          </ng-container>
                          <ng-template #elseTemplateCompletedFilter>
                            {{ filter?.get('file_updated_at_to').value | date:'MMM dd. Y':'':activeLang | yearPipe }}
                          </ng-template>
                          <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
                        </mat-chip>
                      </ng-container>
                    </ng-container>
          
                    <ng-container *ngIf="filter.get('content_published_at_from').value || filter.get('content_published_at_to').value || filter?.get('content_published_at_to').value == '0'">
                      <span class="chip_out_label">{{ "Published" | translate }}</span>
          
                      <div class="chip_splash"></div>
                      
                      <ng-container *ngIf="filter.get('content_published_at_from').value">
                        <span class="small_chip_out_label">{{ "from" | translate}}</span>
                        <mat-chip (removed)="removeValue('content_published_at_from')">
                          {{ filter?.get('content_published_at_from').value | date:'MMM dd. Y':'':activeLang | yearPipe }}
                          <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
                        </mat-chip>
                      </ng-container>
              
                      <ng-container *ngIf="filter.get('content_published_at_to').value || filter?.get('content_published_at_to').value == '0'">
                        <span class="small_chip_out_label">{{ "to" | translate}}</span>
                        <mat-chip (removed)="removeValue('content_published_at_to')">
                          <ng-container *ngIf="filter.get('content_published_at_to').value == 0; else elseTemplateCompletedFilter">
                            {{ "Without date" | translate }}
                          </ng-container>
                          <ng-template #elseTemplateCompletedFilter>
                            {{ filter?.get('content_published_at_to').value | date:'MMM dd. Y':'':activeLang | yearPipe }}
                          </ng-template>
                          <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
                        </mat-chip>
                      </ng-container>
                    </ng-container>
          
                    <ng-container *ngIf="filter.get('file_meta_creation_time_from').value || filter.get('file_meta_creation_time_to').value || filter?.get('file_meta_creation_time_to').value == '0'">
                      <span class="chip_out_label">{{ "File created" | translate }}</span>
          
                      <div class="chip_splash"></div>
                      
                      <ng-container *ngIf="filter.get('file_meta_creation_time_from').value">
                        <span class="small_chip_out_label">{{ "from" | translate}}</span>
                        <mat-chip (removed)="removeValue('file_meta_creation_time_from')">
                          {{ filter?.get('file_meta_creation_time_from').value | date:'MMM dd. Y':'':activeLang | yearPipe }}
                          <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
                        </mat-chip>
                      </ng-container>
              
                      <ng-container *ngIf="filter.get('file_meta_creation_time_to').value || filter?.get('file_meta_creation_time_to').value == '0'">
                        <span class="small_chip_out_label">{{ "to" | translate}}</span>
                        <mat-chip (removed)="removeValue('file_meta_creation_time_to')">
                          <ng-container *ngIf="filter.get('file_meta_creation_time_to').value == 0; else elseTemplateCompletedFilter">
                            {{ "Without date" | translate }}
                          </ng-container>
                          <ng-template #elseTemplateCompletedFilter>
                            {{ filter?.get('file_meta_creation_time_to').value | date:'MMM dd. Y':'':activeLang | yearPipe }}
                          </ng-template>
                          <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
                        </mat-chip>
                      </ng-container>
                    </ng-container>
          
                    <ng-container *ngIf="filter.get('task_channel_published_at_from').value || filter.get('task_channel_published_at_to').value || filter?.get('task_channel_published_at_to').value == '0'">
                      <span class="chip_out_label">{{ "Post published" | translate }}</span>
          
                      <div class="chip_splash"></div>
                      
                      <ng-container *ngIf="filter.get('task_channel_published_at_from').value">
                        <span class="small_chip_out_label">{{ "from" | translate}}</span>
                        <mat-chip (removed)="removeValue('task_channel_published_at_from')">
                          {{ filter?.get('task_channel_published_at_from').value | date:'MMM dd. Y':'':activeLang | yearPipe }}
                          <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
                        </mat-chip>
                      </ng-container>
              
                      <ng-container *ngIf="filter.get('task_channel_published_at_to').value || filter?.get('task_channel_published_at_to').value == '0'">
                        <span class="small_chip_out_label">{{ "to" | translate}}</span>
                        <mat-chip (removed)="removeValue('task_channel_published_at_to')">
                          <ng-container *ngIf="filter.get('task_channel_published_at_to').value == 0; else elseTemplateCompletedFilter">
                            {{ "Without date" | translate }}
                          </ng-container>
                          <ng-template #elseTemplateCompletedFilter>
                            {{ filter?.get('task_channel_published_at_to').value | date:'MMM dd. Y':'':activeLang | yearPipe }}
                          </ng-template>
                          <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
                        </mat-chip>
                      </ng-container>
                    </ng-container>
          
                    <ng-container *ngIf="filter.get('created_at_from').value || filter.get('created_at_to').value || filter?.get('created_at_to').value == '0'">
                      <span class="chip_out_label">{{ "Post created" | translate }}</span>
          
                      <div class="chip_splash"></div>
                      
                      <ng-container *ngIf="filter.get('created_at_from').value">
                        <span class="small_chip_out_label">{{ "from" | translate}}</span>
                        <mat-chip (removed)="removeValue('created_at_from')">
                          {{ filter?.get('created_at_from').value | date:'MMM dd. Y':'':activeLang | yearPipe }}
                          <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
                        </mat-chip>
                      </ng-container>    
          
                      <ng-container *ngIf="filter.get('created_at_to').value || filter?.get('created_at_to').value == '0'">
                        <span class="small_chip_out_label">{{ "to" | translate}}</span>
                        <mat-chip (removed)="removeValue('created_at_to')">
                          <ng-container *ngIf="filter.get('created_at_to').value == 0; else elseTemplateCompletedFilter">
                            {{ "Without date" | translate }}
                          </ng-container>
                          <ng-template #elseTemplateCompletedFilter>
                            {{ filter?.get('created_at_to').value | date:'MMM dd. Y':'':activeLang | yearPipe }}
                          </ng-template>
                          <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
                        </mat-chip>
                      </ng-container>
                    </ng-container>
          
                    <ng-container *ngIf="filter.get('analytics_views_count_from').value || filter.get('analytics_views_count_to').value || filter?.get('analytics_views_count_to').value == '0'">
                      <span class="chip_out_label">{{ "Post analytics" | translate }}</span>
          
                      <div class="chip_splash"></div>
                      
                      <ng-container *ngIf="filter.get('analytics_views_count_from').value">
                        <span class="small_chip_out_label">{{ "from" | translate}}</span>
                        <mat-chip (removed)="removeValue('analytics_views_count_from')">
                          {{ filter?.get('analytics_views_count_from').value | date:'MMM dd. Y':'':activeLang | yearPipe }}
                          <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
                        </mat-chip>
                      </ng-container>
              
                      <ng-container *ngIf="filter.get('analytics_views_count_to').value || filter?.get('analytics_views_count_to').value == '0'">
                        <span class="small_chip_out_label">{{ "to" | translate}}</span>
                        <mat-chip (removed)="removeValue('analytics_views_count_to')">
                          <ng-container *ngIf="filter.get('analytics_views_count_to').value == 0; else elseTemplateCompletedFilter">
                            {{ "Without date" | translate }}
                          </ng-container>
                          <ng-template #elseTemplateCompletedFilter>
                            {{ filter?.get('analytics_views_count_to').value | date:'MMM dd. Y':'':activeLang | yearPipe }}
                          </ng-template>
                          <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
                        </mat-chip>
                      </ng-container>
                    </ng-container> -->
          
                    <ng-container *ngIf="filter.get('channel_id').value.length">
                      <span class="chip_out_label">{{ "Card Outlets" | translate }}</span>
                      <mat-chip [ngClass]="{'user_chip': id != 0}" *ngFor="let id of filter.get('channel_id').value" (removed)="removeValueFromMultiple('channel_id', id)">
                        <ng-container *ngIf="id != 0; else elseTemplateOutletNotSet">
                          <app-outlet-line [channel]="getProfileById(id)" [company_id]="company_id"></app-outlet-line>
                        </ng-container>
                        <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
                      </mat-chip>
                    </ng-container>
          
                    <ng-container *ngIf="filter.get('no_channel_id').value.length">
                      <span class="chip_out_label">{{ "Not Presented" | translate }}</span>
                      <mat-chip [ngClass]="{'user_chip': id != 0}" *ngFor="let id of filter.get('no_channel_id').value" (removed)="removeValueFromMultiple('no_channel_id', id)">
                        <ng-container *ngIf="id != 0; else elseTemplateOutletNotSet">
                          <app-outlet-line [channel]="getProfileById(id)" [company_id]="company_id"></app-outlet-line>
                        </ng-container>
                        <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
                      </mat-chip>
                    </ng-container> 

                    <ng-container *ngIf="filter.get('content_published_at_from').value || filter.get('content_published_at_to').value || filter?.get('content_published_at_to').value == '0'">
                      <span class="chip_out_label">{{ "Published" | translate }}</span>
          
                      <div class="chip_splash"></div>
                      
                      <ng-container *ngIf="filter.get('content_published_at_from').value">
                        <span class="small_chip_out_label">{{ "from" | translate}}</span>
                        <mat-chip (removed)="removeValue('content_published_at_from')">
                          {{ filter?.get('content_published_at_from').value | date:'MMM dd. Y':'':activeLang | yearPipe }}
                          <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
                        </mat-chip>
                      </ng-container>
              
                      <ng-container *ngIf="filter.get('content_published_at_to').value || filter?.get('content_published_at_to').value == '0'">
                        <span class="small_chip_out_label">{{ "to" | translate}}</span>
                        <mat-chip (removed)="removeValue('content_published_at_to')">
                          <ng-container *ngIf="filter.get('content_published_at_to').value == 0; else elseTemplateCompletedFilter">
                            {{ "Without date" | translate }}
                          </ng-container>
                          <ng-template #elseTemplateCompletedFilter>
                            {{ filter?.get('content_published_at_to').value | date:'MMM dd. Y':'':activeLang | yearPipe }}
                          </ng-template>
                          <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
                        </mat-chip>
                      </ng-container>
                    </ng-container>

                    <ng-container *ngIf="filter.get('created_at_from').value || filter.get('created_at_to').value || filter?.get('created_at_to').value == '0'">
                      <span class="chip_out_label">{{ "Post created" | translate }}</span>
          
                      <div class="chip_splash"></div>
                      
                      <ng-container *ngIf="filter.get('created_at_from').value">
                        <span class="small_chip_out_label">{{ "from" | translate}}</span>
                        <mat-chip (removed)="removeValue('created_at_from')">
                          {{ filter?.get('created_at_from').value | date:'MMM dd. Y':'':activeLang | yearPipe }}
                          <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
                        </mat-chip>
                      </ng-container>    
          
                      <ng-container *ngIf="filter.get('created_at_to').value || filter?.get('created_at_to').value == '0'">
                        <span class="small_chip_out_label">{{ "to" | translate}}</span>
                        <mat-chip (removed)="removeValue('created_at_to')">
                          <ng-container *ngIf="filter.get('created_at_to').value == 0; else elseTemplateCompletedFilter">
                            {{ "Without date" | translate }}
                          </ng-container>
                          <ng-template #elseTemplateCompletedFilter>
                            {{ filter?.get('created_at_to').value | date:'MMM dd. Y':'':activeLang | yearPipe }}
                          </ng-template>
                          <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
                        </mat-chip>
                      </ng-container>
                    </ng-container>
          
                    <ng-container *ngIf="filter.get('analytics_views_count_from').value || filter.get('analytics_views_count_to').value || filter?.get('analytics_views_count_to').value == '0'">
                      <span class="chip_out_label">{{ "Post analytics" | translate }}</span>
          
                      <div class="chip_splash"></div>
                      
                      <ng-container *ngIf="filter.get('analytics_views_count_from').value">
                        <span class="small_chip_out_label">{{ "from" | translate}}</span>
                        <mat-chip (removed)="removeValue('analytics_views_count_from')">
                          {{ filter?.get('analytics_views_count_from').value | date:'MMM dd. Y':'':activeLang | yearPipe }}
                          <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
                        </mat-chip>
                      </ng-container>
              
                      <ng-container *ngIf="filter.get('analytics_views_count_to').value || filter?.get('analytics_views_count_to').value == '0'">
                        <span class="small_chip_out_label">{{ "to" | translate}}</span>
                        <mat-chip (removed)="removeValue('analytics_views_count_to')">
                          <ng-container *ngIf="filter.get('analytics_views_count_to').value == 0; else elseTemplateCompletedFilter">
                            {{ "Without date" | translate }}
                          </ng-container>
                          <ng-template #elseTemplateCompletedFilter>
                            {{ filter?.get('analytics_views_count_to').value | date:'MMM dd. Y':'':activeLang | yearPipe }}
                          </ng-template>
                          <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
                        </mat-chip>
                      </ng-container>
                    </ng-container>
          
                    <ng-template #elseTemplateOutletNotSet>
                      {{ "Not Set" | translate}}
                    </ng-template>
          
                      <mat-icon class="remove_all_chips_btn" (click)="removeValue('all')">cancel</mat-icon>
                  </mat-chip-list>
                </ng-container>
                <ng-template #elseTemplateNoChips>
                  <div></div>
                </ng-template>
                

                <button class="start_btn" type="button" (click)="goToPage('/urls')">{{"Search" | translate}}</button>
            </div>
            <form class="left_start_form" [formGroup]="filter">
              <div class="double_block" style="margin-left: 10px !important;">
                <div class="block without_pad" (mouseenter)="!is_mobile ? onMouseEnter($event) : null" style="width: auto !important;">
                  <div style="padding: 15px;">
                    <div class="filter_exp_search start_search" style="visibility: visible !important; opacity: 1 !important; margin: 0 !important;">
                        <mat-form-field appearance="legacy" class="filter_form search">
                            <input class="search_inp" formControlName="q" matInput>
                            <mat-icon matSuffix>search</mat-icon>
                        </mat-form-field>
                    </div>
                  </div>     
                </div>
                <div class="block without_pad group" (mouseenter)="!is_mobile ? onMouseEnter($event) : null">
                    <div class="title">{{"Groups" | translate}}</div>
    
                    <div class="filter_exp_search start_search">
                        <mat-form-field appearance="legacy" class="filter_form search">
                            <input class="search_inp" [formControl]="groupsControl" matInput>
                            <mat-icon matSuffix>search</mat-icon>
                        </mat-form-field>
                        <mat-progress-bar mode="indeterminate" *ngIf="isLoadBooks" class="mat_proggress_w8"></mat-progress-bar>
                    </div>
    
                    <mat-selection-list class="start_items" [appDeviceClass]="'custom-scroll custom-scroll-t'" [multiple]="true" formControlName="group_id" (selectionChange)="selectChange($event)"        
                    infinite-scroll
                    [scrollWindow]="false"
                    [infiniteScrollDisabled]="isLoadGroups"
                    [infiniteScrollUpDistance]="scrollUpDistance"
                    [infiniteScrollThrottle]="throttle"
                    (scrolled)="onDownGroups($event)">
                        <ng-container *ngFor="let fil of groups$ | async">
                            <mat-list-option class="small_item" [value]="fil.id" (click)="setFilValue($event, 'group_id', fil.id)" [ngClass]="{'active': filter.get('group_id').value.includes(fil.id)}">
                                {{fil.name}}
                            </mat-list-option>
                        </ng-container>
                        <mat-list-option class="small_item" [value]="0" [ngClass]="{'active': filter.get('group_id').value.includes(0)}" (click)="setFilValue($event, 'group_id', 0)">
                            {{ "Group not set" | translate }}
                        </mat-list-option>
                    </mat-selection-list>
                </div>
              </div>
              <div class="double_block">
                  <div class="block without_pad" (mouseenter)="!is_mobile ? onMouseEnter($event) : null">
                      <div class="title">{{"Platforms" | translate}}</div>
      
                      <div class="filter_exp_search start_search">
                          <mat-form-field appearance="legacy" class="filter_form search">
                              <input class="search_inp" [formControl]="platformsMoreControl" matInput>
                              <mat-icon matSuffix>search</mat-icon>
                          </mat-form-field>
                          <mat-progress-bar mode="indeterminate" *ngIf="isLoadPlatforms" class="mat_proggress_w8"></mat-progress-bar>
                      </div>
      
                      <mat-selection-list class="start_items" [appDeviceClass]="'custom-scroll custom-scroll-t'" [multiple]="false" formControlName="channel_platform_id" (selectionChange)="selectChange($event)">
                          <ng-container *ngFor="let fil of platforms$ | async">
                              <mat-list-option *ngIf="fil.id <= 4" class="small_item" [value]="fil.id" [ngClass]="{'active': filter.get('channel_platform_id').value.includes(fil.id)}">
                                  <div class="d-f-c">
                                      <div class="platform_icon mr-1">
                                          <app-platform [platform_id]="fil?.id"></app-platform>
                                      </div>
                                      <span class="fil_name">
                                          {{fil.name}}
                                      </span>
                                  </div>
                              </mat-list-option>
                          </ng-container>  
                      </mat-selection-list>
                  </div>
                  <div class="block without_pad" (mouseenter)="!is_mobile ? onMouseEnter($event) : null">
                      <div class="title">{{"Content Types" | translate}}</div>
      
                      <div class="filter_exp_search start_search">
                          <mat-form-field appearance="legacy" class="filter_form search">
                              <input class="search_inp" [formControl]="contentTypesControl" matInput>
                              <mat-icon matSuffix>search</mat-icon>
                          </mat-form-field>
                          <mat-progress-bar mode="indeterminate" *ngIf="isLoadContentTypes" class="mat_proggress_w8"></mat-progress-bar>
                      </div>
      
                      <mat-selection-list class="start_items" [appDeviceClass]="'custom-scroll custom-scroll-t'"  [multiple]="false" formControlName="content_type_id" (selectionChange)="selectChange($event)">
                          <ng-container *ngFor="let contentType of contentTypes$ | async">
                              <ng-container *ngIf="contentType.channel_platform_id <= 4">
                                  <div mat-subheader>{{getPlatformById(contentType.channel_platform_id)?.name}}</div>
                                  <ng-container *ngFor="let fil of contentType.types">
                                    <mat-list-option *ngIf="[101,102,106,201,202,206,301,302,401,402,403,404].includes(fil.id)" class="small_item" [value]="fil.id" [ngClass]="{'active': fil.id == filter.get('content_type_id').value}">
                                        {{fil.name | translate}}
                                    </mat-list-option>
                                  </ng-container>
                                  <div style="margin-bottom: 4px;"></div>
                                  <!-- <mat-divider></mat-divider> -->
                              </ng-container>
                          </ng-container>
                          <mat-list-option class="small_item"  [value]="0" [ngClass]="{'active': 0 === filter.get('content_type_id').value}">
                              {{ "Content type not set" | translate}}
                          </mat-list-option>
                      </mat-selection-list>
                  </div>
              </div>
              <div class="double_block">
                <div class="block without_pad" style="width: 214px !important; margin-left: 0 !important;" (mouseenter)="!is_mobile ? onMouseEnter($event) : null">
                    <div class="title">{{"Outlets" | translate}}</div>
    
                    <div class="filter_exp_search start_search">
                        <mat-form-field appearance="legacy" class="filter_form search">
                            <input class="search_inp" [formControl]="searchControl" matInput>
                            <mat-icon matSuffix>search</mat-icon>
                        </mat-form-field>
                        <mat-progress-bar mode="indeterminate" *ngIf="isLoadOutlets" class="mat_proggress_w8"></mat-progress-bar>
                    </div>
    
                    <mat-selection-list class="start_items" [appDeviceClass]="'custom-scroll custom-scroll-t'" infinite-scroll
                    [infiniteScrollDisabled]="isLoadOutlets"
                    [scrollWindow]="false"
                    [infiniteScrollUpDistance]="scrollUpDistance"
                    [infiniteScrollThrottle]="throttle"
                    (scrolled)="onDown($event)" [multiple]="true" formControlName="channel_id" (selectionChange)="selectChange($event)">
                        <!-- <mat-list-option class="small_item cst_o flex_text" [value]="0" [ngClass]="{'active': filter.get('channel_id').value.includes(0)}">
                            <div class="user_div">
                                <mat-icon style="visibility: none;">user</mat-icon>
                                <span>
                                    {{ "Outlet not set" | translate }}
                                </span>
                            </div>
                        </mat-list-option> -->
                        <ng-container *ngFor="let fil of outlets$ | async">
                            <mat-list-option class="small_item cst_o flex_text" (click)="setFilValue($event, 'channel_id', fil.id)" [value]="fil.id" [ngClass]="{'active':  filter.get('channel_id').value.includes(fil.id)}">
                                <!-- <div class="d-f-c" style="justify-content: space-between;">
                                </div> -->
                                <app-outlet-line [class]="'for_nav'" [channel]="fil" [company_id]="company_id"></app-outlet-line>
                                <!-- <mat-checkbox (click)="setChannelMulty($event, fil.id, 'channel_id')" [disableRipple]="true" class="outlet_fil_check" [checked]="filter.get('channel_id').value.includes(fil.id)" color="primary"></mat-checkbox> -->
                                <!-- <span class="not_assigned" (click)="noChannel($event, fil.id)">
                                    {{ "n/a" | translate }}
                                    <mat-checkbox [disableRipple]="true" class="outlet_fil_check" color="primary" [checked]="filter.get('no_channel_id').value.includes(fil.id)" (click)="noChannelMulty($event, fil.id)"></mat-checkbox>
                                </span> -->
                            </mat-list-option>
                        </ng-container>
                    </mat-selection-list>
                </div>

                <div class="block without_pad" (mouseenter)="!is_mobile ? onMouseEnter($event) : null" style="width: auto !important;">
                  <div class="title">{{"Status" | translate}}</div>
  
                  <mat-selection-list style="margin-top: 15px; max-height: calc(100% - 12px);" class="start_items" [appDeviceClass]="'custom-scroll custom-scroll-t'" [multiple]="false" formControlName="content_status_id" (selectionChange)="selectChange($event)">
                      <ng-container *ngFor="let fil of profileStatuses">
                          <mat-list-option *ngIf="[1,6,8,3,4,5,14].includes(fil.id)" class="small_item" [value]="fil.id" [ngClass]="{'active': filter.get('content_status_id').value.includes(fil.id)}">
                              {{fil.name}}
                          </mat-list-option>
                      </ng-container>  
                  </mat-selection-list>
                </div>
              </div>
              <div class="triple_block">
                  <div class="block start_date_block without_pad">
                      <!-- <div class="title">{{"When" | translate}}</div> -->
      

                      <div class="sub_tabs">
                        <div class="sub_tabs_in">
                            <div class="sub_tab" [ngClass]="{'active': dateTab == 0}" (click)="changeTab(0)">
                              {{ "Published" | translate }}
                            </div>
                            <div class="sub_tab" [ngClass]="{'active': dateTab == 1}" (click)="changeTab(1)">
                              {{ "Created" | translate }}
                            </div>
                            <div class="sub_tab" [ngClass]="{'active': dateTab == 2}" (click)="changeTab(2)">
                              {{ "Views" | translate }}
                            </div>
                        </div>
                      </div>

                      <mat-tab-group mat-align-tabs="center" class="create_new_task_tabs" [selectedIndex]="dateTab">
                        <mat-tab>
                            <ng-template mat-tab-label>
                                {{ "Published" | translate }}
                            </ng-template>
                
                            <form [formGroup]="filter" class="filter_in parameter_filter scroll_task_filter" [appDeviceClass]="'custom-scroll'">
                                <mat-calendar (selectedChange)="rangeChange($event, 'content_published_at_', 'publishDateRange')" [selected]="publishDateRange"></mat-calendar>
                                <div class="calendar_inputs">
                                  <mat-form-field appearance="legacy">
                                      <mat-label>{{ "Date Start" | translate }}</mat-label>
                                      <input matInput [matDatepicker]="dp_cp_from" formControlName="content_published_at_from">
                                      <mat-datepicker #dp_cp_from></mat-datepicker>
                                  </mat-form-field>
                                  <span class="splash"></span>
                                  <mat-form-field appearance="legacy">
                                      <mat-label>{{ "Date End" | translate }}</mat-label>
                                      <input matInput [matDatepicker]="dp_cp_to" formControlName="content_published_at_to">
                                      <mat-datepicker #dp_cp_to></mat-datepicker>
                                  </mat-form-field>
                                </div>
                                <!-- <div class="apply_date">
                                    <button mat-raised-button color="warn" type="button" (click)="toNoPublishUrls()">{{ "Without Date" | translate }}</button>
                                    <button mat-raised-button class="ml-1" color="primary" type="submit">{{ "Apply" | translate }}</button>
                                </div> -->
                            </form>
                        </mat-tab>
                        <mat-tab>
                          <ng-template mat-tab-label>
                              {{ "Added" | translate }}
                          </ng-template>
                
                          <form [formGroup]="filter" class="filter_in parameter_filter scroll_task_filter" [appDeviceClass]="'custom-scroll'">
                              
                              <mat-calendar (selectedChange)="rangeChange($event, 'created_at_', 'createdDateRange')" [selected]="createdDateRange"></mat-calendar>
                              <div class="calendar_inputs">
                                <mat-form-field appearance="standard">
                                    <mat-label>{{ "Date Start" | translate }}</mat-label>
                                    <input matInput [matDatepicker]="dp_created_from" formControlName="created_at_from">
                                    <mat-datepicker #dp_created_from></mat-datepicker>
                                </mat-form-field>
                                <span class="splash"></span>
                                <mat-form-field appearance="standard">
                                    <mat-label>{{ "Date End" | translate }}</mat-label>
                                    <input matInput [matDatepicker]="dp_created_to" formControlName="created_at_to">
                                    <mat-datepicker #dp_created_to></mat-datepicker>
                                </mat-form-field>
                              </div>
                              <!-- <div class="apply_date">
                                  <button mat-raised-button color="primary" type="submit">{{ "Apply" | translate }}</button>
                              </div> -->
                          </form>
                        </mat-tab>
                        <mat-tab>
                          <ng-template mat-tab-label>
                              {{ "Views" | translate }}
                          </ng-template>
                
                          <form [formGroup]="filter" class="filter_in parameter_filter scroll_task_filter" [appDeviceClass]="'custom-scroll'">
                              <mat-calendar (selectedChange)="rangeChange($event, 'analytics_views_count_', 'viewsDateRange')" [selected]="viewsDateRange"></mat-calendar>
                              <div class="calendar_inputs">
                                <mat-form-field appearance="standard">
                                    <mat-label>{{ "Date Start" | translate }}</mat-label>
                                    <input matInput [matDatepicker]="dp_analytics_from" formControlName="analytics_views_count_from">
                                    <mat-datepicker #dp_analytics_from></mat-datepicker>
                                </mat-form-field>
                                <span class="splash"></span>
                                <mat-form-field appearance="standard">
                                    <mat-label>{{ "Date End" | translate }}</mat-label>
                                    <input matInput [matDatepicker]="dp_analytics_to" formControlName="analytics_views_count_to">
                                    <mat-datepicker #dp_analytics_to></mat-datepicker>
                                </mat-form-field>
                              </div>
                              <!-- <div class="apply_date">
                                  <button mat-raised-button class="ml-1" color="primary" type="submit">{{ "Apply" | translate }}</button>
                              </div> -->
                          </form>
                        </mat-tab>
                      </mat-tab-group>

                      <div class="schemes">
                        <div class="left_schemes">
                          <!-- <div class="scheme_title">{{ "Past" | translate }}</div> -->
                          <div class="scheme" [ngClass]="{'active': filter.get(getFilterDateKey()+'from').value == item.date_from && filter.get(getFilterDateKey()+'to').value == item.date_to}" (click)="useScheme(item)" *ngFor="let item of dateSchemes">
                            {{item.name}}
                          </div>
                        </div>
                        <div class="right_schemes">
                          <!-- <div class="scheme_title">{{ "Future" | translate }}</div> -->
                          <div class="scheme" [ngClass]="{'active': filter.get(getFilterDateKey()+'from').value == item.date_from && filter.get(getFilterDateKey()+'to').value == item.date_to}" (click)="useScheme(item)" *ngFor="let item of futureDateSchemes">
                            {{item.name}}
                          </div>
                        </div>
                      </div>
                  </div>
              </div>
            </form>
        </div>
        <div class="right_start">
            <div class="block" (mouseenter)="!is_mobile ? onMouseEnter($event) : null">
                <div class="title">{{"Presets" | translate}}</div>

                <div class="filter_exp_search start_search">
                    <mat-form-field appearance="legacy" class="filter_form search">
                        <input class="search_inp" [formControl]="bookmarksControl" matInput>
                        <mat-icon matSuffix>search</mat-icon>
                    </mat-form-field>
                    <mat-progress-bar mode="indeterminate" *ngIf="isLoadBooks" class="mat_proggress_w8"></mat-progress-bar>
                </div>

                <div class="start_items" [appDeviceClass]="'custom-scroll custom-scroll-t'">
                    <div class="big_item" *ngFor="let item of bookmarks$ | async" (click)="goToBook(item)">
                        <span class="text_2_line">{{item.name}}</span>
                    </div>
                </div>
            </div>

            <div class="block">
                <div class="title" style="margin: 2px 0 20px 0;">{{"Tools" | translate}}</div>

                <div class="start_items" [appDeviceClass]="'custom-scroll custom-scroll-t'">
                    <div class="big_item text_one_line" *ngIf="company?.permissions.includes('owner') || company?.permissions.includes('admin')" (click)="postsCleaner()">
                        {{"Cleaner" | translate}}
                    </div>
                    <div class="big_item text_one_line" (click)="managePosts()">
                        {{"Bulk schedule match" | translate}}
                    </div>
                    <div class="big_item text_one_line" (click)="goToRegularPublications($event)">
                        {{"Regular schedule" | translate}}
                    </div>
                    <div class="big_item text_one_line" (click)="addScheduledPost()">
                        {{"Add to schedule" | translate}}
                    </div>
                    <div class="big_item text_one_line" (click)="goToProgress()">
                        {{"Progress" | translate}}
                    </div>
                    <div class="big_item text_one_line" (click)="streamRestartAassistant()">
                        {{"Stream Restart assistant" | translate}}
                    </div>
                </div>
            </div>

        </div>

        <!-- <div class="centred">
            <mat-icon>north</mat-icon>
            <p (click)="log()">Use filters above</p>
            <p >Filter data before going to the posts section</p>
            <button mat-raised-button color="primary" (click)="goToPage('/urls')">{{'Go to Posts' | translate}}</button>
        </div> -->
        <a [routerLink]="['/urls']" [queryParams]="{company_id: company_id, order_by: 'content_published_at_desc', count: '1'}">{{"Show all posts" | translate}}</a>
    </div>
</div>

<pre class="filter_value" *ngIf="user?.external_id == 8166">
    {{filter.value|json}}
</pre>