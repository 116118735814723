import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseClass } from 'src/app/shared/models/base-class';

@Component({
  selector: 'app-select-parameter',
  templateUrl: './select-parameter.component.html',
  styleUrls: ['./select-parameter.component.scss']
})
export class SelectParameterComponent extends BaseClass implements OnInit, OnDestroy {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<SelectParameterComponent>,
  ) { super() }

  ngOnInit(): void {
    console.log("SelectParameterComponent", this.data)
  }

  selectChange(e) {
    this.dialogRef.close({event: "select", data: e.option.value})
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

}
