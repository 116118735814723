<div class="workspace_steps">


    <!-- <mat-stepper orientation="vertical" [linear]="true" #stepper [selectedIndex]="selectedIndex" labelPosition="bottom" (selectionChange)="selectionChange($event)">
        <mat-step>
          <form>
            <ng-template matStepLabel>Fill out your name</ng-template>
            <mat-form-field appearance="fill">
              <mat-label>Name</mat-label>
              <input matInput placeholder="Last name, First name" required>
            </mat-form-field>
            <div>
              <button mat-button matStepperNext>Next</button>
            </div>
          </form>
        </mat-step>
        <mat-step>
          <form>
            <ng-template matStepLabel>Fill out your name</ng-template>
            <mat-form-field appearance="fill">
              <mat-label>Name</mat-label>
              <input matInput placeholder="Last name, First name" required>
            </mat-form-field>
            <div>
              <button mat-button matStepperNext>Next</button>
            </div>
          </form>
        </mat-step>
      </mat-stepper> -->

    <mat-vertical-stepper [linear]="true" class="steper_vertical" #stepper [selectedIndex]="selectedIndex" labelPosition="bottom" (selectionChange)="selectionChange($event)" [disableRipple]="false">
      <mat-step [stepControl]="creatingWorkspace" [editable]="editable">
        <form [formGroup]="creatingWorkspace">
            <ng-template matStepLabel>{{ "Workspace" | translate }}</ng-template>
            <div class="step_in">
                <div class="step_check">Creating card type... <mat-icon class="ml-1" *ngIf="cardTypeEnd" style="color: green;">done</mat-icon></div>
                <div class="step_check">Creating job type... <mat-icon class="ml-1" *ngIf="jobTypeEnd" style="color: green;">done</mat-icon></div>
                <div class="step_check">Importing tags... <mat-icon class="ml-1" *ngIf="tagsEnd" style="color: green;">done</mat-icon></div>
            </div>
            <!-- <div class="btns_footer d-f-c" style="justify-content: space-between;">
              <button mat-button matStepperPrevious>{{ "Back" | translate }}</button>
              <div></div>
              <button mat-raised-button color="primary" (click)="test()">{{ "Test" | translate }}</button>
              <button mat-raised-button color="primary" matStepperNext>{{ "Next" | translate }}</button>
              <button mat-raised-button color="primary" (click)="finish()">{{ "Next" | translate }}</button>
            </div> -->
        </form>
      </mat-step>

      <mat-step [stepControl]="creatingChannel" [editable]="editable">
        <form [formGroup]="creatingChannel">
            <ng-template matStepLabel>{{ "Outlet" | translate }}</ng-template>
            <div class="step_in">
              <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ 'Name' | translate }}</mat-label>
                <input formControlName="name" matInput placeholder="{{ 'Name' | translate }}">
              </mat-form-field>

              <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ 'Url' | translate }}</mat-label>
                <input formControlName="url" matInput placeholder="{{ 'Url' | translate }}">
              </mat-form-field>
              <!-- https://www.youtube.com/@alexandrepic4065 -->

              <mat-form-field appearance="standard" class="full_width_form">
                  <mat-label>{{ "Language" | translate }}</mat-label>
                  <mat-select [disableOptionCentering]="true" selectDir [multiple]="false" formControlName="tag">
                      <mat-option>
                          <ngx-mat-select-search 
                          placeholderLabel="{{ 'Search' | translate }}" 
                          noEntriesFoundLabel="{{ 'no matching tag found' | translate }}"
                          [formControl]="langTagsControl"></ngx-mat-select-search>
                      </mat-option>
                      <mat-option *ngFor="let item of langTags$ | async" [value]="item.id">
                          {{ item.value }}
                      </mat-option>
                  </mat-select>
              </mat-form-field>

              <ng-container *ngIf="creatingChannelStart">
                <div class="step_check">Creating channel... <mat-icon class="ml-1" *ngIf="channelEnd" style="color: green;">done</mat-icon></div>
                <div class="step_check">Request to receive video... <mat-icon class="ml-1" *ngIf="videosEnd" style="color: green;">done</mat-icon></div>
                <div class="step_check">Setting channel language... <mat-icon class="ml-1" *ngIf="channelTagEnd" style="color: green;">done</mat-icon></div>
                <div class="step_check">Creating groups... <mat-icon class="ml-1" *ngIf="groupsEnd" style="color: green;">done</mat-icon></div>
              </ng-container>
              

              <div class="btns_footer d-f-c" style="justify-content: space-between;">
                <div></div>
                <button mat-raised-button color="primary" (click)="createChannel()" [disabled]="!creatingChannel.valid || creatingChannelStart">{{ "Create" | translate }}</button>
              </div>
            </div>
            <!-- <div class="btns_footer d-f-c" style="justify-content: space-between;">
              <button mat-button matStepperPrevious>{{ "Back" | translate }}</button>
              <div></div>
              <button mat-raised-button color="primary" (click)="test()">{{ "Test" | translate }}</button>
              <button mat-raised-button color="primary" matStepperNext>{{ "Next" | translate }}</button>
              <button mat-raised-button color="primary" (click)="finish()">{{ "Next" | translate }}</button>
            </div> -->
        </form>
      </mat-step>

      <mat-step [stepControl]="streamAuto" [editable]="editable">
        <form [formGroup]="streamAuto">
            <ng-template matStepLabel>{{ "Stream automation" | translate }}</ng-template>
              <div class="step_in">
                <mat-form-field appearance="standard" class="full_width_form">
                  <mat-label>{{ 'Streams count' | translate }}</mat-label>
                  <input formControlName="streams_count" matInput type="number" placeholder="{{ 'Streams count' | translate }}" (change)="onInput($event, 'streams_count', 1)">
                </mat-form-field>
                <mat-form-field appearance="standard" class="full_width_form">
                  <mat-label>{{ 'Execute interval (m)' | translate }}</mat-label>
                  <input formControlName="execute_interval" matInput type="number" placeholder="{{ 'Execute interval (m)' | translate }}" (change)="onInput($event, 'execute_interval', 40)">
                </mat-form-field>
                <mat-form-field appearance="standard" class="full_width_form">
                  <mat-label>{{ 'Concurrent views count restart threshold' | translate }}</mat-label>
                  <input formControlName="concurrent_views_count_restart_threshold" matInput type="number" placeholder="{{ 'Concurrent views count restart threshold' | translate }}" (change)="onInput($event, 'concurrent_views_count_restart_threshold', 1)">
                </mat-form-field>

                <div class="box_wrp">
                  <mat-checkbox color="primary" formControlName="made_for_kids">{{ "Made for kids" | translate }}</mat-checkbox>
                </div>
                <div class="box_wrp">
                  <mat-checkbox color="primary" formControlName="record_from_start">{{ "Enable recording" | translate }}</mat-checkbox>
                </div>


              <ng-container *ngIf="streamAutoStart">
                <div class="step_check">Create automation... <mat-icon class="ml-1" *ngIf="autoEnd" style="color: green;">done</mat-icon></div>
                <div class="step_check">Create card... <mat-icon class="ml-1" *ngIf="cardEnd" style="color: green;">done</mat-icon></div>
                <div class="step_check">Create streams... <mat-icon class="ml-1" *ngIf="streamsEnd" style="color: green;">done</mat-icon></div>
              </ng-container>
              

              <div class="btns_footer d-f-c" style="justify-content: space-between;">
                <div></div>
                <button mat-raised-button color="primary" (click)="createAuto()" [disabled]="!streamAuto.valid || streamAutoStart">{{ "Create" | translate }}</button>
              </div>
            </div>
            <!-- <div class="btns_footer d-f-c" style="justify-content: space-between;">
              <button mat-button matStepperPrevious>{{ "Back" | translate }}</button>
              <div></div>
              <button mat-raised-button color="primary" (click)="test()">{{ "Test" | translate }}</button>
              <button mat-raised-button color="primary" matStepperNext>{{ "Next" | translate }}</button>
              <button mat-raised-button color="primary" (click)="finish()">{{ "Next" | translate }}</button>
            </div> -->
        </form>
      </mat-step>

      <mat-step [stepControl]="companyTime" [editable]="editable">
        <form [formGroup]="companyTime">
            <ng-template matStepLabel>{{ "Time zone" | translate }}</ng-template>
              <div class="step_in">
                <mat-form-field appearance="standard" class="full_width_form">
                  <mat-label>{{ "Time zone" | translate }}</mat-label>
                  <mat-select disableOptionCentering formControlName="timezone">
                      <mat-option>
                          <ngx-mat-select-search [formControl]="timezoneFilterCtrl" noEntriesFoundLabel="'no matching time zone found'" placeholderLabel="{{ 'Find time zone' | translate }}..." ></ngx-mat-select-search>
                      </mat-option>
                      <mat-option value="no">-- {{ "My time zone is not" | translate }} --</mat-option>
                      <mat-optgroup *ngFor="let zone of filteredTimezonesGroups | async" [label]="getTimeZone(zone.value)"
                      [disabled]="zone.disabled">
                          <mat-option *ngFor="let name of zone.names" [value]="name">
                              {{name}}
                          </mat-option>
                      </mat-optgroup>
                  </mat-select>
              </mat-form-field>
          
              <mat-form-field appearance="standard" class="full_width_form">
                  <mat-label>{{ 'Timezone offset' | translate }}</mat-label>
                  <input [readonly]="companyTime.get('timezone').value != 'no'" formControlName="timezone_offset" matInput placeholder="{{ 'Timezone offset' | translate }}">
              </mat-form-field>


              <ng-container *ngIf="streamAutoStart">
                <div class="step_check">Setting workspace time zone... <mat-icon class="ml-1" *ngIf="timeZoneEnd" style="color: green;">done</mat-icon></div>
              </ng-container>
              

              <div class="btns_footer d-f-c" style="justify-content: space-between;">
                <div></div>
                <button mat-raised-button color="primary" (click)="setTimeZone()" [disabled]="!companyTime.valid || companyTimeStart">{{ "Save" | translate }}</button>
              </div>
            </div>
            <!-- <div class="btns_footer d-f-c" style="justify-content: space-between;">
              <button mat-button matStepperPrevious>{{ "Back" | translate }}</button>
              <div></div>
              <button mat-raised-button color="primary" (click)="test()">{{ "Test" | translate }}</button>
              <button mat-raised-button color="primary" matStepperNext>{{ "Next" | translate }}</button>
              <button mat-raised-button color="primary" (click)="finish()">{{ "Next" | translate }}</button>
            </div> -->
        </form>
      </mat-step>

      <mat-step [stepControl]="videosList" [editable]="editable">
        <form [formGroup]="videosList">
            <ng-template matStepLabel>{{ "Videos" | translate }}</ng-template>
              <div class="step_in">
                <div class="step_check">Getting Videos... <mat-icon class="ml-1" *ngIf="!noVid" style="color: green;">done</mat-icon></div>
                <ng-container *ngIf="noVid">
                  <p>Videos have not been received from the YouTube platform yet, please try again later.</p>
                  <div class="d-f-c">
                    <button class="ml-1 d-f-btn" (click)="refreshVid()" mat-raised-button color="primary" [disabled]="isLoadVid">
                      <mat-spinner class="spin_16 mr-1" *ngIf="isLoadVid"></mat-spinner>  
                      {{ "Refresh" | translate }}
                    </button>
                    <button class="ml-1" mat-raised-button (click)="goToOutlets()">{{ "Finish and go to Outlets" | translate }}</button>
                  </div>
                </ng-container>
              <div class="d-f-c" style="justify-content: space-between;" *ngIf="!noVid || typeStatus != 'All'">
                <div class="job_status_wrp">
                  <button mat-button (click)='changeTypeStatus("All")' [ngClass]="{'active_status': typeStatus === 'All'}">{{ "All" | translate }}</button>
                  <button mat-button (click)='changeTypeStatus("is_shorts")' [ngClass]="{'active_status': typeStatus === 'is_shorts'}">{{ "Shorts" | translate }}</button>
                  <button mat-button (click)='changeTypeStatus("is_regular")' [ngClass]="{'active_status': typeStatus === 'is_regular'}">{{ "Video" | translate }}</button>
                </div>
                <div class="counters">
                  <span>{{ "Items" | translate }}: </span>
                  <span>{{videos?.length}} / {{ vidPagination?.totalCount }}</span>
                </div>
              </div>
              <div class="container form_wrapper" *ngIf="videos && videos.length" [appDeviceClass]="'custom-scroll custom-scroll-t'"
              infinite-scroll
              [infiniteScrollDisabled]="isLoadVid"
              [scrollWindow]="false"
              [infiniteScrollUpDistance]="scrollUpDistance"
              [infiniteScrollThrottle]="throttle"
              (scrolled)="onDown($event)">
                  <div class="videos">
                    <!-- [ngClass]="{'checked': videosCollection.isSelected(video)}" -->
                      <div class="video" *ngFor="let video of videos">
                          <div class="video_thumb">
                              <!-- <div class="check_wrp" [ngClass]="{'show': videosCollection.isSelected(video)}">
                                  <mat-checkbox color="primary" class="example-margin" (click)="$event.stopPropagation()" (change)="toggleVideo(video)" [checked]='videosCollection.isSelected(video)'></mat-checkbox>
                              </div> -->
                              <img [src]="video.thumbnail" alt="">
                          </div>
                          <div class="video_info">
                              <div class="video_info_left">
                                  <div class="name"><span class="text_one_line">{{video?.name}}</span> <a [href]="'https://youtu.be/' + video?.video_id" target="_blank"><mat-icon>open_in_new</mat-icon></a></div>
                                  <div class="filename" *ngIf="!!video.filename">{{video?.filename}}</div>
                                  <!-- <div class="video_tasks">
                                      <div class="video_task" *ngFor="let vChannel of video.taskChannels" (click)="openTask(vChannel?.task)">
                                          <div class="task_name">
                                              <div class="case_ids">
                                                  <div class="case_c_id">{{vChannel?.task?.custom_id}} </div>
                                                  <div class="case_id">{{vChannel?.task?.id}}</div>
                                              </div>
                                              <span class="text_one_line">
                                                  {{ vChannel?.task?.name }}
                                              </span>
                                          </div>
                                      </div>
                                  </div> -->
                              </div>
                              <div class="video_info_right">
                                  <div class="id">
                                      <b class="mr-5">{{ "ID" | translate }}:</b>{{video?.id}}
                                  </div>
                                  <div class="status">
                                      <b class="mr-5">{{ "Status" | translate }}:</b>{{video?.status}}
                                  </div>
                                  <div class="published">
                                      <b class="mr-5">{{ "Published at" | translate }}:</b>{{video?.published_at*1000 | date:'MMM dd, Y':'': activeLang | yearPipe }}
                                  </div>
                                  <div class="view_count">
                                      <div class="d-f-c" style="justify-content: flex-end;">
                                          <mat-icon>play_arrow</mat-icon>
                                          {{ video?.views_count | number:'':'fr-FR' }}
                                      </div>
                                  </div>
                              </div>
          
                          </div>
                          
                      </div>
                      
                  </div>
              </div>

              <div class="btns_footer d-f-c" style="justify-content: space-between;" *ngIf="videos && videos.length">
                <div></div>
                <button mat-raised-button color="primary" (click)="createNotAssigned()" [disabled]="noVid">{{ "Create Cards" | translate }}</button>
              </div>
            </div>
            <!-- <div class="btns_footer d-f-c" style="justify-content: space-between;">
              <button mat-button matStepperPrevious>{{ "Back" | translate }}</button>
              <div></div>
              <button mat-raised-button color="primary" (click)="test()">{{ "Test" | translate }}</button>
              <button mat-raised-button color="primary" matStepperNext>{{ "Next" | translate }}</button>
              <button mat-raised-button color="primary" (click)="finish()">{{ "Next" | translate }}</button>
            </div> -->
        </form>
      </mat-step>

      <mat-step [stepControl]="cardsList" [editable]="editable">
        <form [formGroup]="cardsList">
            <ng-template matStepLabel>{{ "Cards & Jobs" | translate }}</ng-template>
            <div class="step_in">
                <div class="step_check">Creating cards... <mat-icon class="ml-1" *ngIf="isEndCards" style="color: green;">done</mat-icon></div>
                <ng-container *ngIf="!isEndCards">
                  <p>The cards are still in the process of being created, please try again later.</p>
                  <div class="d-f-c">
                    <button class="ml-1 d-f-btn" (click)="refreshCards()" mat-raised-button color="primary" [disabled]="isLoadCards">
                      <mat-spinner class="spin_16 mr-1" *ngIf="isLoadCards"></mat-spinner>  
                      {{ "Refresh" | translate }}
                    </button>
                    <button class="ml-1" mat-raised-button (click)="goToCompany()">{{ "Finish and go to Cards section" | translate }}</button>
                  </div>
                </ng-container>

                <ng-container *ngIf="isEndCards">
                  <div class="step_check">Creating jobs... <mat-icon class="ml-1" *ngIf="isEndJobs" style="color: green;">done</mat-icon></div>
                  <div class="step_check">Importing files... <mat-icon class="ml-1" *ngIf="isEndImports" style="color: green;">done</mat-icon></div>
                </ng-container>
                
            </div>
            <!-- <div class="btns_footer d-f-c" style="justify-content: space-between;">
              <button mat-button matStepperPrevious>{{ "Back" | translate }}</button>
              <div></div>
              <button mat-raised-button color="primary" (click)="test()">{{ "Test" | translate }}</button>
              <button mat-raised-button color="primary" matStepperNext>{{ "Next" | translate }}</button>
              <button mat-raised-button color="primary" (click)="finish()">{{ "Next" | translate }}</button>
            </div> -->
        </form>
      </mat-step>


    </mat-vertical-stepper>
</div>
