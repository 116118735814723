<h2 mat-dialog-title class="dialog_title">
    <span>
        {{ "File drop function supports uploading no more than 100 files" | translate }}
    </span>
</h2>
<mat-dialog-content class="mat-typography">
    <div class="container">
        <p class="alert">{{ "If you want to download a large number of files, use the secure browser function" | translate }} <span (click)="upload()">{{ "Upload File(s)" | translate }}</span> {{ "or" | translate}} <span (click)="uploadFolder()">{{ "Upload Folder" | translate }}</span></p>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button (click)="close()">
        {{ "Cancel" | translate }}
    </button>
</mat-dialog-actions>
