<h2 mat-dialog-title class="dialog_title">
    <span>
        {{ "Adding a client" | translate }}
    </span>
    <button mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h2>
<mat-dialog-content class="mat-typography">
    <div class="container">
        <div class="block_w8 form_with_pads" style="margin: 20px 40px;">
            <!-- <div class="label_w8" style="margin: 0;">{{"Job Clients" | translate}}</div> -->
            <div class="users_select" style="margin: 0 !important; width: 100% !important;">
                <label>{{ "Individuals" | translate }}: </label>
                <div class="chips_spacer" *ngIf="!!cliSwitch"></div>
                <mat-chip-list [multiple]="true" selectable *ngIf="!cliSwitch">
                    <ng-container *ngFor="let employee of employees; let i = index">
                        <mat-chip class="chip_user" (click)="toggleClientChip(employee)" [selected]="includesCli(employee.id)" [value]="employee.id">
                            <app-user [class]="'user_div'" [employee]="employee"></app-user>
                            <mat-icon *ngIf="!includesCli(employee.id)" style="margin-left: 3.5px; width: 14px; height: 14px; line-height: 14px; font-size: 14px; visibility: hidden;"></mat-icon>
                            <ng-container *ngIf="includesCli(employee.id)">
                                <mat-icon style="margin-left: 3.5px; width: 14px; height: 14px; line-height: 14px; font-size: 14px;" 
                                    #tooltip="matTooltip"
                                    matTooltip="{{'Permission to approve granted' | translate}}"
                                    [matTooltipPosition]="'above'" 
                                    (click)="setCli($event, employee.id, 0)" 
                                    *ngIf="!!getCli(employee.id)?.is_principal">thumb_up</mat-icon>
                                <mat-icon 
                                    style="margin-left: 3.5px; width: 14px; height: 14px; line-height: 14px; font-size: 14px;" 
                                    #tooltip="matTooltip"
                                    matTooltip="{{'Permission to approve not granted' | translate}}"
                                    [matTooltipPosition]="'above'" 
                                    (click)="setCli($event, employee.id, 1)" 
                                    *ngIf="!getCli(employee.id)?.is_principal">thumb_up_off_alt</mat-icon>
                            </ng-container>
                        </mat-chip>
                        <mat-chip *ngIf="i == employees.length - 1 && i > 6" class="chip_user more" (click)="cliCardEmployeeIdContainer.open()">
                            {{ "more" | translate }}
                        </mat-chip>
                    </ng-container>
                </mat-chip-list>
            </div>
    
            <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                <mat-select #cliCardEmployeeIdContainer multiple [(ngModel)]="clients_ids" (selectionChange)="toggleSelectClientChip($event)">
                    <mat-option>
                        <ngx-mat-select-search 
                        placeholderLabel="{{ 'Search' | translate }}" 
                        noEntriesFoundLabel="{{ 'no matching individuals found' | translate }}"
                        [formControl]="employeeMoreControl"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let employee of employees$ | async" [value]="employee.id">
                        <app-user [class]="'user_div'" [employee]="employee"></app-user>
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <div class="users_select" style="margin: 0 !important; width: 100% !important;">
                <label>{{ "Partners" | translate }}: </label>
                <div class="chips_spacer" *ngIf="!!pCliSwitch"></div>
                <mat-chip-list [multiple]="true" selectable *ngIf="!pCliSwitch">
                    <ng-container *ngFor="let partner of partners; let i = index">
                        <mat-chip class="chip_user" (click)="toggleClientPartnerChip(partner)" [selected]="includesCliPartn(partner.partner_company_id)" [value]="partner.partner_company_id">
                            <app-user [class]="'user_div'" [companyEmployee]="partner?.partnerCompany" [imgRoute]="imgRoute" [host]="host" [company]="data?.company"></app-user>
                            <mat-icon *ngIf="!includesCliPartn(partner.partner_company_id)" style="margin-left: 3.5px; width: 14px; height: 14px; line-height: 14px; font-size: 14px; visibility: hidden;"></mat-icon>
                            <ng-container *ngIf="includesCliPartn(partner.partner_company_id)">
                                <mat-icon 
                                #tooltip="matTooltip"
                                matTooltip="{{'Permission to approve granted' | translate}}"
                                [matTooltipPosition]="'above'" 
                                style="margin-left: 3.5px; width: 14px; height: 14px; line-height: 14px; font-size: 14px;" 
                                (click)="setCliPartn($event, partner.partner_company_id, 0)" 
                                *ngIf="!!getCliPartn(partner.partner_company_id)?.is_principal">thumb_up</mat-icon>
                                <mat-icon 
                                #tooltip="matTooltip"
                                matTooltip="{{'Permission to approve not granted' | translate}}"
                                [matTooltipPosition]="'above'" 
                                style="margin-left: 3.5px; width: 14px; height: 14px; line-height: 14px; font-size: 14px;" 
                                (click)="setCliPartn($event, partner.partner_company_id, 1)" 
                                *ngIf="!getCliPartn(partner.partner_company_id)?.is_principal">thumb_up_off_alt</mat-icon>
                            </ng-container>
                        </mat-chip>
                        <mat-chip *ngIf="i == partners.length - 1 && i > 6" class="chip_user more" (click)="cliCardPartnersIdContainer.open()">
                            {{ "more" | translate }}
                        </mat-chip>
                    </ng-container>
                </mat-chip-list>
            </div>
    
            <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                <mat-select #cliCardPartnersIdContainer multiple [(ngModel)]="partnerClients_ids" (selectionChange)="toggleSelectClientPartnerChip($event)">
                    <mat-option>
                        <ngx-mat-select-search 
                        placeholderLabel="{{ 'Search' | translate }}" 
                        noEntriesFoundLabel="{{ 'no matching partners found' | translate }}"
                        [formControl]="partnersControl"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let partner of partners$ | async" [value]="partner.partner_company_id">
                        <app-user [class]="'user_div'" [companyEmployee]="partner?.partnerCompany" [imgRoute]="imgRoute" [host]="host" [company]="data?.company"></app-user>
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button (click)="close()">
        {{ "Close" | translate }}
    </button>
    <button class="ml-1 d-f-btn" (click)="submitForm()" mat-raised-button color="primary" [disabled]="isSubmit">
        <mat-spinner class="spin_16 mr-1" *ngIf="isSubmit"></mat-spinner>  
        {{ "Add" | translate }}
    </button>
</mat-dialog-actions>
