import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { switchMap, tap } from 'rxjs/operators';
import { BaseClass } from 'src/app/shared/models/base-class';
import { CompanyService } from 'src/app/shared/services/rest/company.service';

@Component({
  selector: 'app-partner-dialog',
  templateUrl: './partner-dialog.component.html',
  styleUrls: ['./partner-dialog.component.scss']
})
export class PartnerDialogComponent extends BaseClass implements OnInit, OnDestroy {
  public companyes: any;
  public host: any;
  public form: FormGroup = this.fb.group({
    company_id: ['', Validators.required]
  })
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PartnerDialogComponent>,
    private companyService: CompanyService,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute
  ) { super() }

  ngOnInit(): void {
    console.log(this.data);
    this.getCompanyes()
    if (!this.data.partner) {
      this.data.partner = {
        id: this.activatedRoute.snapshot.queryParamMap.get('id'),
      }
    }
  }

  close() {
    this.dialogRef.close({event: "close", data: false});
  }

  login() {
    window.open(`${this.data.host}/user/auth/?action=lastauth&returnUrl=${window.location.origin}/main?id=${this.data.partner.id}&invite=${this.data.invite}`, "_self")
  }

  getCompanyes() {
    this.attachSubscriptions(
      this.companyService.getCompanyes().subscribe(resp => {
        this.companyes = resp.filter(x => x.owner_user_id == this.data.user.id && x.id != this.data.partner.company_id);
      })
    )
  }

  acceptInvite() {
    this.attachSubscriptions(
      this.companyService.acceptPartner(this.data.partner.id, this.data.invite, {accept_invite: 1, partner_company_id: this.form.value.company_id}).subscribe(resp => {
        this.close();
      })
    )
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
