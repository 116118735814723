<mat-dialog-content class="mat-typography" dirEnter (dirSubmit)="!form.valid || isSubmit ? null : onSubmit()">
    <div class="container form_wrapper">
        <div class="title_modal">
            {{ "Execute scenarios for" | translate }} {{data.selectedJobs.length}} {{ "selected jobs" | translate }}
        </div>
        <form [formGroup]="form" class="form_with_pads form_with_valids">
            <div class="users_select">
                <ng-container *ngIf="form.get('template_id').value.length == 0 && submited">
                    <div class="req_field"></div>
                    <div class="req_label">
                        <div class="angle"></div>
                        <span>
                            {{ "You need to choose a value" | translate }}
                        </span>
                    </div>
                </ng-container>
                <label>{{ "Choose a scenario" | translate }}: </label>
                <mat-chip-list [multiple]="true" selectable formControlName="template_id">
                    <ng-container *ngFor="let template of templates; let i = index">
                        <mat-chip style="padding-left: 12px !important;" class="chip_user" (click)="toggleChipTemplate(template)" [value]="template.id" [selected]="form.value.template_id && form.value.template_id.includes(template.id)">
                            {{ template.name }}
                        </mat-chip>
                        <mat-chip *ngIf="i == templates.length - 1 && i > 6" class="chip_user more" (click)="templatesIdContainer.open()">
                            {{ "more" | translate }}
                        </mat-chip>
                    </ng-container>
                </mat-chip-list>
            </div>
        
            <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                <mat-select [multiple]="true" #templatesIdContainer formControlName="template_id">
                    <mat-option>
                        <ngx-mat-select-search 
                        placeholderLabel="{{ 'Search' | translate }}" 
                        noEntriesFoundLabel="{{ 'no matching template found' | translate }}"
                        [formControl]="templatesMoreControl"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let template of templates$ | async" [value]="template.id">
                        {{template.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </form>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <div class="f_w_p_btns">
        <button mat-button (click)="close()">
            {{ "Close" | translate }}
        </button>
        <button class="ml-1 d-f-btn" (click)="onSubmit()" cdkFocusInitial mat-raised-button color="primary" [disabled]="isSubmit">
            <mat-spinner class="spin_16 mr-1" *ngIf="isSubmit"></mat-spinner>  
            {{ "Execute" | translate }}
        </button>
    </div>
</mat-dialog-actions>
