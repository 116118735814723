import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { Component, Inject, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { mimeTypes } from 'mime-wrapper';
import { ClipboardService } from 'ngx-clipboard';
import { fromEvent, Subscription } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { FileService } from 'src/app/shared/services/rest/file.service';
import { environment } from 'src/environments/environment';
import { MobFmViewComponent } from '../../../mob-file-manager/dialogs/mob-fm-view/mob-fm-view.component';
import { SnackBarItem } from '../../../snack-bar/snack-bar-item';
import { VideoViewerComponent } from '../video-viewer/video-viewer.component';

@Component({
  selector: 'app-public-interface',
  templateUrl: './public-interface.component.html',
  styleUrls: ['./public-interface.component.scss']
})
export class PublicInterfaceComponent extends BaseClass implements OnInit, OnDestroy {

  
  @ViewChild('fileMenu') fileMenu: TemplateRef<any>;
  @ViewChild('outFileMenu') outFileMenu: TemplateRef<any>;
  overlayRef: OverlayRef | null;
  public origin = window.location.origin;
  public host: any = environment.host;
  public imgRoute: any;
  public _mimeTypes = mimeTypes
  public files: any = [...this.data.link.publicFolder.files];
  public is_list: boolean = this.sm.localStorageGetItem('is_list') == "true" ? true : false;
  public is_mobile: boolean = false;
  public isLoad: boolean = false;
  public crumbs: any[] = [{
    name: this.data.link.publicFolder.file.filename,
    location: this.data.link.publicFolder.file.location,
    target: this.data.link.publicFolder.file
  }];

  public sub: Subscription;
  
  constructor(    
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PublicInterfaceComponent>,
    private _clipboardService: ClipboardService,
    public overlay: Overlay,
    public viewContainerRef: ViewContainerRef,
    private layoutService: LayoutService,
    private sm: StorageManagerService,
    private fileService: FileService,
    private fb: FormBuilder,
    private dialog: MatDialog,
  ) { super() }

  ngOnInit(): void {
    console.log("PublicInterfaceComponent", this.data)
    if (this.data.link.publicFolder.parent_dir) {
      this.crumbs.unshift({
        name: this.data.link.publicFolder.parent_dir.filename,
        location: this.data.link.publicFolder.parent_dir.location,
        target: this.data.link.publicFolder.parent_dir
      })
    }
    this.checkIsMobile();
    this.getImgRoute();
  }

  logCrumgs() {
    console.log(this.crumbs)
  }

  getPathForLink(val) {
    return val.replaceAll('//', '~').replaceAll('/', '~')
  }

  understandFileType(val) {
    if (!val) {
      return ""
    }
    
    if (val == 'application/pdf') {
      return 'pdf'
    } else if (val.indexOf("/") != -1) {
      return val.split('/')[0]
    } else {
      return ""
    }
  }

  crumbClick(crumb,i) {

    console.log(this.crumbs);
    console.log(crumb);
    this.attachSubscriptions(
      this.fileService.getPublicFiles(crumb.target.acm).subscribe(resp => {
        console.log('crumbClick', resp)
        this.crumbs = this.crumbs.slice(0,i+1);
        this.data.link.publicFolder = resp;
        this.files = [...resp.files];
        if (i == 0 && this.data.link.publicFolder.parent_dir && this.crumbs.filter(x => x.target.id == this.data.link.publicFolder.parent_dir)) {
          this.crumbs.unshift({
            name: this.data.link.publicFolder.parent_dir.filename,
            location: this.data.link.publicFolder.parent_dir.location,
            target: this.data.link.publicFolder.parent_dir
          })
        }
      }, error => {
        this.layoutService.showSnackBar({name: ''}, marker("Something went wrong"), SnackBarItem)
      })
    )
  }

  fileClick(e, file, i) {
    if (file.is_dir) {
      this.files = [];

      this.crumbs.push({
        name: file.filename,
        location: file.location,
        target: file
      })

      this.attachSubscriptions(
        this.fileService.getPublicFiles(file.acm).subscribe(resp => {
          console.log('folder fileClick', resp)
          this.data.link.publicFolder = resp;
          this.files = [...resp.files];
        }, error => {
          this.layoutService.showSnackBar({name: ''}, marker("Something went wrong"), SnackBarItem)
        })
      )
    } else {
      if(!file.is_uploaded) {
        this.layoutService.showSnackBar({name: ''}, marker("This file is corrupted."), SnackBarItem)
        return
      }
      if (file.content_type.indexOf("image") != -1) {
        this.openPublicFile(file);
      } else if (file.content_type.indexOf("video") != -1) {
        this.openPublicFile(file);
      } else if (file.content_type == 'application/pdf') {
        this.openPublicFile(file);
      } else if (!!file.thumbnail) {
        this.openPublicFile(file);
      } else {
        this.download(file);
      }
      // window.open(environment.host + file.original, '_blank');
    }
  }

  
  checkIsMobile() {
    if (window.innerWidth <= 769) {
      this.is_mobile = true;
    } else {
      this.is_mobile = false;
    }
    this.onResize();
  }

  onResize() {
    this.attachSubscriptions(
      fromEvent(window, "resize").pipe(
        map(() => window.innerWidth)
      ).subscribe((wWidth) => {
          if (wWidth <= 769) {
            this.is_mobile = true;
          } else {
            this.is_mobile = false;
          }
        }
      )
    )
  }

  openPublicFile(file) {
    console.log(file)
    if (!this.is_mobile) {
      const dialogRef = this.dialog.open(VideoViewerComponent, {
        panelClass: 'video_viewer',
        autoFocus: false,
        data: {
          file: file,
          initCompanyID: file.company_id,
          is_public: true
        }
      });
    } else {
      const dialogRef = this.dialog.open(MobFmViewComponent, {
        backdropClass: ['mob_video_viewer_backdrop'],
        panelClass: 'mob_video_viewer',
        autoFocus: false,
        data: {
          file: file,
          initCompanyID: file.company_id,
          is_public: true
        }
      });
    }
  }


  
  onImgError(event){
    event.target.src = this.imgRoute+'/assets/img/image_black_48dp.svg'
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  toggleMode() {
    this.is_list = !this.is_list
    this.sm.localStorageSetItem('is_list', this.is_list)
  }

  copyLink(type) {
    this.layoutService.showSnackBar({name: marker(type + " link")}, marker("Copied"), SnackBarItem)
  }

  // settings(file?) {
  //   this.close();
  //   if (file) {
  //     const dialogRef = this.dialog.open(FileSettingsComponent, {
  //       data: {
  //         company: this.company,
  //         file: file,
  //         location: this.form.value.location,
  //         task: this.task || file.task,
  //         operationsValues: this.operationsValues
  //       }
  //     });
  //   } else {
  //     console.log(this.crumbs)
  //   }


  //   // this.attachSubscriptions(
  //   //   dialogRef.afterClosed().subscribe(result => {
  //   //     if (!!result && result.event == 'create') {
  //   //       this.files.push(result.data)
  //   //     }
  //   //   })
  //   // )
  // }

  download(file) {
    this.close();
    window.open(this.host + file.original, '_blank');
    // var a = document.createElement('a');
    // a.href = this.host + element;
    // a.download = folder.name;
    // a.target = "_blank"
    // a.click();  
    // a.remove(); 
  }

  closeInterface() {
    this.dialogRef.close({event: "close", data: false})
  }

  open({ x, y }: MouseEvent, file) {
    this.close();
    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo({ x, y })
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        }
      ]);

    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });

    if (file == 'outFiles') {
      this.overlayRef.attach(new TemplatePortal(this.outFileMenu, this.viewContainerRef, {
        $implicit: {}
      }));
    } else {
      this.overlayRef.attach(new TemplatePortal(this.fileMenu, this.viewContainerRef, {
        $implicit: file
      }));
    }
    
    this.sub = fromEvent<MouseEvent>(document, 'click')
      .pipe(
        filter(event => {
          const clickTarget = event.target as HTMLElement;
          return !!this.overlayRef && !this.overlayRef.overlayElement.contains(clickTarget);
        }),
        take(1)
      ).subscribe(() => this.close())

  }

  close() {
    this.sub && this.sub.unsubscribe();
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
  
}
