<div *ngIf="user" class="user-info" (mouseenter)="mouseEnter.emit()"
(mouseleave)="mouseLeave.emit()">
    <div class="info_top">
        <ng-container *ngIf="!!user.partnerCompany; else elseTemplateIsPartner">
            <ng-container *ngIf="!!user?.partnerCompany?.avatarFile?.thumbnail && !!host; else elseTemplatePartnerAvatar">
                <img class="user_info_avatar" (error)="onPartnerImgError($event)" [src]="host + user?.partnerCompany?.avatarFile?.thumbnail + '?company_id=' + company_id" alt="">
            </ng-container>
            <ng-template #elseTemplatePartnerAvatar>
                <img class="user_info_avatar" (error)="onPartnerImgError($event)" src="{{ imgRoute }}assets/img/partner.png">
            </ng-template>
        </ng-container>
        <ng-template #elseTemplateIsPartner>
            <ng-container *ngIf="!!user?.image; else elseTemplateUserHasDataImage">
                <img class="user_info_avatar" [src]="user?.image" alt="">
            </ng-container>
            <ng-template #elseTemplateUserHasDataImage>
                <mat-icon class="user_info_icon">account_circle</mat-icon>
            </ng-template>
        </ng-template>

        <div class="info_data">
            <div class="user_name">{{ !!user.partnerCompany ? user.partnerCompany.name : user.name }}</div>
            <div class="user_position" *ngIf="departments && departments.length">
                <div class="department" *ngFor="let item of departments">
                    <ng-container *ngFor="let pos of item.positions; let last = last">
                        <span>{{pos?.name}}</span>
                        <ng-container *ngIf="!last">, </ng-container>
                    </ng-container>
                    <div class="dep_name">{{item?.department?.name}}</div>
                </div>
            </div>
            <div class="schedule user_worktime" *ngIf="todayScheduleData">
                <div class="intervals">
                    <div class="interval" *ngFor="let interval of todayScheduleData">
                        <div class="from">{{interval.from_time*1000|date:'hh:mm a':'':activeLang}}</div>
                        <div class="ft_spacer">-</div>
                        <div class="to">{{interval.to_time*1000|date:'hh:mm a':'':activeLang}}</div>
                        <div class="duration">({{(interval.to_time - interval.from_time) | timeFormat:true}})</div>
                    </div>
                </div>                
            </div>
        </div>
    </div>

    <div class="info_bottom">

        <div class="sections">
            <div class="section" [ngClass]="{'active': activeSection == 'cards'}" (click)="activateSection('cards')" #tooltip="matTooltip"
            matTooltip="{{ 'Cards' | translate }}"
            [matTooltipPosition]="'above'">
                <svg style="width: 18px; height: 18px" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16 12V16H2V12H16ZM17 10H1C0.45 10 0 10.45 0 11V17C0 17.55 0.45 18 1 18H17C17.55 18 18 17.55 18 17V11C18 10.45 17.55 10 17 10ZM4 15.5C3.18 15.5 2.5 14.83 2.5 14C2.5 13.17 3.18 12.5 4 12.5C4.82 12.5 5.5 13.17 5.5 14C5.5 14.83 4.83 15.5 4 15.5ZM16 2V6H2V2H16ZM17 0H1C0.45 0 0 0.45 0 1V7C0 7.55 0.45 8 1 8H17C17.55 8 18 7.55 18 7V1C18 0.45 17.55 0 17 0ZM4 5.5C3.18 5.5 2.5 4.83 2.5 4C2.5 3.17 3.18 2.5 4 2.5C4.82 2.5 5.5 3.18 5.5 4C5.5 4.82 4.83 5.5 4 5.5Z" fill="#686868"/>
                </svg>
            </div>
            <div class="section" [ngClass]="{'active': activeSection == 'jobs'}" (click)="activateSection('jobs')" #tooltip="matTooltip"
            matTooltip="{{ 'Jobs' | translate }}"
            [matTooltipPosition]="'above'">
                <svg style="width: 18px; height: 15px" width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 14.4H18V10.8H0V14.4ZM1.8 11.7H3.6V13.5H1.8V11.7ZM0 0V3.6H18V0H0ZM3.6 2.7H1.8V0.9H3.6V2.7ZM0 9H18V5.4H0V9ZM1.8 6.3H3.6V8.1H1.8V6.3Z" fill="#686868"/>
                </svg>
            </div>
            <div class="section" [ngClass]="{'active': activeSection == 'chats'}" (click)="activateSection('chats')" #tooltip="matTooltip"
            matTooltip="{{ 'Chats' | translate }}"
            [matTooltipPosition]="'above'">
                <svg style="width: 18px; height: 18px" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16.2 0H1.8C1.32261 0 0.864773 0.189642 0.527208 0.527208C0.189642 0.864773 0 1.32261 0 1.8V18L3.6 14.4H16.2C16.6774 14.4 17.1352 14.2104 17.4728 13.8728C17.8104 13.5352 18 13.0774 18 12.6V1.8C18 0.801 17.19 0 16.2 0Z" fill="#686868"/>
                </svg>
            </div>
            <div class="section" [ngClass]="{'active': activeSection == 'posts'}" (click)="activateSection('posts')" #tooltip="matTooltip"
            matTooltip="{{ 'Posts' | translate }}"
            [matTooltipPosition]="'above'">
                <svg style="width: 18px; min-width: 18px; height: 9px; min-height: 9px; transform: none;" width="18" height="9" viewBox="0 0 18 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.5 0H9.9V1.8H13.5C14.985 1.8 16.2 3.015 16.2 4.5C16.2 5.985 14.985 7.2 13.5 7.2H9.9V9H13.5C15.984 9 18 6.984 18 4.5C18 2.016 15.984 0 13.5 0ZM8.1 7.2H4.5C3.015 7.2 1.8 5.985 1.8 4.5C1.8 3.015 3.015 1.8 4.5 1.8H8.1V0H4.5C2.016 0 0 2.016 0 4.5C0 6.984 2.016 9 4.5 9H8.1V7.2ZM5.4 3.6H12.6V5.4H5.4V3.6Z" fill="#686868"/>
                </svg>
            </div>
            <div class="section" [ngClass]="{'active': activeSection == 'board_by_date'}" (click)="activateSection('board_by_date')" #tooltip="matTooltip"
            matTooltip="{{ 'Board by date' | translate }}"
            [matTooltipPosition]="'above'">
                <svg style="width: 16px; height: 18px;" width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.77778 11.5556H3.55556V13.3333H9.77778M14.2222 16H1.77778V6.22222H14.2222M14.2222 1.77778H13.3333V0H11.5556V1.77778H4.44444V0H2.66667V1.77778H1.77778C0.791111 1.77778 0 2.57778 0 3.55556V16C0 16.4715 0.187301 16.9237 0.520699 17.2571C0.854097 17.5905 1.30628 17.7778 1.77778 17.7778H14.2222C14.6937 17.7778 15.1459 17.5905 15.4793 17.2571C15.8127 16.9237 16 16.4715 16 16V3.55556C16 3.08406 15.8127 2.63188 15.4793 2.29848C15.1459 1.96508 14.6937 1.77778 14.2222 1.77778ZM12.4444 8H3.55556V9.77778H12.4444V8Z" fill="#686868"/>
                </svg>
            </div>
            <div class="section" [ngClass]="{'active': activeSection == 'board_by_order'}" (click)="activateSection('board_by_order')" #tooltip="matTooltip"
            matTooltip="{{ 'Board by order' | translate }}"
            [matTooltipPosition]="'above'">
                <svg style="width: 18px; height: 14px;" width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.8 13.5H18V11.7H10.8V13.5ZM10.8 8.55H18V6.75H10.8V8.55ZM10.8 3.6H18V1.8H10.8V3.6ZM0 7.65C0 4.428 2.628 1.8 5.85 1.8H6.3V0L9 2.7L6.3 5.4V3.6H5.85C3.6 3.6 1.8 5.4 1.8 7.65C1.8 9.9 3.6 11.7 5.85 11.7H9V13.5H5.85C2.628 13.5 0 10.872 0 7.65Z" fill="#686868"/>
                </svg>
            </div>
            <div class="section" [ngClass]="{'active': activeSection == 'attach_money'}" (click)="activateSection('attach_money')" *ngIf="company && (company?.permissions?.includes('owner') || company?.permissions?.includes('admin') || company?.permissions?.includes('manager'))" #tooltip="matTooltip"
            matTooltip="{{ 'Finances' | translate }}"
            [matTooltipPosition]="'above'">
                <mat-icon style="width: 18px; height: 18px; font-size: 18px; line-height: 18px; color: #686868;">attach_money</mat-icon>
            </div>
            <div class="section" (click)="openActivity($event)" *ngIf="company && (company?.permissions?.includes('owner') || company?.permissions?.includes('admin') || company?.permissions?.includes('manager'))" #tooltip="matTooltip"
            matTooltip="{{ 'Activity' | translate }}"
            [matTooltipPosition]="'above'">
                <!-- <mat-icon style="width: 18px; height: 18px; font-size: 18px; line-height: 18px; color: #686868;">notifications</mat-icon> -->
                <svg style="width: 18px; height: 16px;" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.6905 5.47619H12.0476V10.9524L16.7352 13.7343L17.5238 12.409L13.6905 10.131V5.47619ZM13.1429 0C10.5286 0 8.02138 1.03852 6.1728 2.88709C4.32423 4.73566 3.28571 7.24287 3.28571 9.85714H0L4.33714 14.271L8.7619 9.85714H5.47619C5.47619 7.82382 6.28393 5.87377 7.72171 4.43599C9.15948 2.99821 11.1095 2.19048 13.1429 2.19048C15.1762 2.19048 17.1262 2.99821 18.564 4.43599C20.0018 5.87377 20.8095 7.82382 20.8095 9.85714C20.8095 11.8905 20.0018 13.8405 18.564 15.2783C17.1262 16.7161 15.1762 17.5238 13.1429 17.5238C11.029 17.5238 9.11238 16.6586 7.73238 15.2676L6.17714 16.8229C7.96238 18.619 10.4048 19.7143 13.1429 19.7143C15.7571 19.7143 18.2643 18.6758 20.1129 16.8272C21.9615 14.9786 23 12.4714 23 9.85714C23 7.24287 21.9615 4.73566 20.1129 2.88709C18.2643 1.03852 15.7571 0 13.1429 0Z" fill="#686868"></path></svg>
            </div>
        </div>
        <div class="section_in" *ngIf="!!activeSection">
            <ng-container [ngSwitch]="activeSection">
                <ng-container *ngSwitchCase="'cards'">
                    <a href="/tasks?company_id={{company_id}}&count=1&operation_employee_id={{user?.id}}" target="_blank">{{"All" | translate}}</a>
                    <a href="/tasks?company_id={{company_id}}&count=1&operation_employee_id={{user?.id}}&operation_status_id=1,2,5" target="_blank">{{"All to do" | translate}}</a>
                    <a href="/tasks?company_id={{company_id}}&count=1&operation_employee_id={{user?.id}}&operation_status_id=3,4,6,97,98,99" target="_blank">{{"All done" | translate}}</a>
                </ng-container>
            
                <ng-container *ngSwitchCase="'jobs'">
                    <a href="/jobs?company_id={{company_id}}&count=1&operation_employee_id={{user?.id}}" target="_blank">{{"All" | translate}}</a>
                    <a href="/jobs?company_id={{company_id}}&count=1&operation_employee_id={{user?.id}}&status_id=1,2,5" target="_blank">{{"All to do" | translate}}</a>
                    <a href="/jobs?company_id={{company_id}}&count=1&operation_employee_id={{user?.id}}&status_id=3,4,6,97,98,99" target="_blank">{{"All done" | translate}}</a>
                </ng-container>

                <ng-container *ngSwitchCase="'chats'">
                    <a href="/chats?company_id={{company_id}}&count=1&operation_employee_id={{user?.id}}" target="_blank">{{"All" | translate}}</a>
                    <a href="/chats?company_id={{company_id}}&count=1&operation_employee_id={{user?.id}}&status_id=1,2,5" target="_blank">{{"All to do" | translate}}</a>
                    <a href="/chats?company_id={{company_id}}&count=1&operation_employee_id={{user?.id}}&status_id=3,4,6,97,98,99" target="_blank">{{"All done" | translate}}</a>
                </ng-container>

                <ng-container *ngSwitchCase="'posts'">
                    <a href="/urls?company_id={{company_id}}&count=1&created_employee_id={{user?.id}}" target="_blank">
                        <svg style="left: 10px; width: 12px; height: 12px;" version="1.1" viewBox="0 0 512 512" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <path fill="#2F80ED" d="M381,236H276V131c0-11-9-20-20-20s-20,9-20,20v105H131c-11,0-20,9-20,20s9,20,20,20h105v105c0,11,9,20,20,20  s20-9,20-20V276h105c11,0,20-9,20-20S392,236,381,236z"/>
                        </svg>
                        {{"Outlet" | translate}}
                    </a>
                    <a href="/urls?company_id={{company_id}}&count=1&created_url_employee_id={{user?.id}}" target="_blank">
                        <svg style="left: 10px; width: 12px; height: 12px;" version="1.1" viewBox="0 0 512 512" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <path fill="#2F80ED" d="M381,236H276V131c0-11-9-20-20-20s-20,9-20,20v105H131c-11,0-20,9-20,20s9,20,20,20h105v105c0,11,9,20,20,20  s20-9,20-20V276h105c11,0,20-9,20-20S392,236,381,236z"/>
                        </svg>
                        {{"Post" | translate}} 
                    </a>
                    <a href="/urls?company_id={{company_id}}&count=1&updated_employee_id={{user?.id}}" target="_blank">
                        <svg style="width: 8px; height: 8px;" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_3980_54429fil)">
                            <path d="M1 5.7501V7.0001H2.25L5.93667 3.31343L4.68667 2.06343L1 5.7501ZM6.90333 2.34677C7.03333 2.21677 7.03333 2.00677 6.90333 1.87677L6.12333 1.09677C5.99333 0.966768 5.78333 0.966768 5.65333 1.09677L5.04333 1.70677L6.29333 2.95677L6.90333 2.34677Z" fill="#2F80ED"/>
                            </g>
                            <defs>
                            <clipPath id="clip0_3980_54429fil">
                            <rect width="8" height="8" fill="white"/>
                            </clipPath>
                            </defs>
                        </svg>
                        {{"Outlet" | translate}}
                    </a>
                    <a href="/urls?company_id={{company_id}}&count=1&updated_url_employee_id={{user?.id}}" target="_blank">
                        <svg style="width: 8px; height: 8px;" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_3980_54429fil)">
                            <path d="M1 5.7501V7.0001H2.25L5.93667 3.31343L4.68667 2.06343L1 5.7501ZM6.90333 2.34677C7.03333 2.21677 7.03333 2.00677 6.90333 1.87677L6.12333 1.09677C5.99333 0.966768 5.78333 0.966768 5.65333 1.09677L5.04333 1.70677L6.29333 2.95677L6.90333 2.34677Z" fill="#2F80ED"/>
                            </g>
                            <defs>
                            <clipPath id="clip0_3980_54429fil">
                            <rect width="8" height="8" fill="white"/>
                            </clipPath>
                            </defs>
                        </svg>
                        {{"Post" | translate}}
                    </a>
                </ng-container>

                <ng-container *ngSwitchCase="'board_by_date'">
                    <a href="/board?company_id={{company_id}}&count=1&operation_employee_id={{user?.id}}" target="_blank">{{"All" | translate}}</a>
                </ng-container>

                <ng-container *ngSwitchCase="'board_by_order'">
                    <a href="/board-list?company_id={{company_id}}&count=1&operation_employee_id={{user?.id}}" target="_blank">{{"All" | translate}}</a>
                </ng-container>
                <ng-container *ngSwitchCase="'attach_money'">
                    <a href="/settings?company_id={{company_id}}&page=finances&tab=statements&count=1&operation_employee_id={{user?.id}}" target="_blank">{{"Statements" | translate}}</a>
                    <a href="/settings?company_id={{company_id}}&page=finances&tab=payouts&count=1&operation_employee_id={{user?.id}}" target="_blank">{{"Payouts" | translate}}</a>
                    <a href="/settings?company_id={{company_id}}&page=finances&tab=current_activity&count=1&operation_employee_id={{user?.id}}" target="_blank">{{"Activity" | translate}}</a>
                </ng-container>
            
            </ng-container>
        </div>

        <div class="user_job" *ngIf="job">
            <div class="job_type_out">
                <div class="job_type">
                    <span class="text_one_line type">
                        {{ job.operation && job.operation.name ? job.operation.name : getColumnName(job.operation_id) }}
                    </span>
                    <span *ngIf="getPrimaryTag(job)" class="text_one_line val">{{getPrimaryTag(job)?.parameterValue?.value}}</span>
                </div>
            </div>

            <div class="user_job_name text_2_line">
                {{job?.name}}
            </div>

            <div class="user_job_status" (click)="$event.preventDefault(); $event.stopPropagation(); changeUserJobStatus(job);" [ngClass]="{
                'first': job?.userInfo?.statusObj?.status_id == '1',
                'second': job?.userInfo?.statusObj?.status_id == '2',
                'third': job?.userInfo?.statusObj?.status_id == '3',
                'fours': job?.userInfo?.statusObj?.status_id == '4',
                'fives': job?.userInfo?.statusObj?.status_id == '5',
                'sixs': job?.userInfo?.statusObj?.status_id == '6',
                'nine': job?.userInfo?.statusObj?.status_id == '97',
                'seven': job?.userInfo?.statusObj?.status_id == '98',
                'eight': job?.userInfo?.statusObj?.status_id == '99'}">
                <div class="changed_statuses" *ngIf="job.is_changing_status">
                    <ng-container *ngFor="let item of statuses">
                        <div class="new_user_status" *ngIf="job?.userInfo?.statusObj?.status_id != item.id" (click)="changeStatusTo(item.id)" [ngClass]="{
                            'first': item.id == '1',
                            'second': item.id == '2',
                            'third': item.id == '3',
                            'fours': item.id == '4',
                            'fives': item.id == '5',
                            'sixs': item.id == '6',
                            'nine': item.id == '97',
                            'seven': item.id == '98',
                            'eight': item.id == '99'}">
                            {{item.name | translate}}
                        </div>
                    </ng-container>
                </div>
                <span class="text_one_line">{{ getOperationStatusName(job?.userInfo?.statusObj?.status_id) | translate }}</span>  
            </div>
        </div>
    </div>
    <!-- другие данные о пользователе -->
</div>