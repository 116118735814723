import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { forkJoin, fromEvent, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';
import { makeArray } from 'src/app/shared/functions/objToArray';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { AuthService } from 'src/app/shared/services/rest/auth.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-stat-by-month-creator',
  templateUrl: './stat-by-month-creator.component.html',
  styleUrls: ['./stat-by-month-creator.component.scss']
})
export class StatByMonthCreatorComponent extends BaseClass implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild("pointContainer") pointContainer: ElementRef;
  public host: any = environment.host;
  public imgRoute: any = '';
  public user: any = {};
  public user_id: number;
  public company_id: any;
  public activeLang: any;
  public company: any;
  public pagination: object = {};
  public page: number = 1;
  public empl_pagination: object = {};
  public empl_page: number = 1;
  public stats: any[] = [];
  public employees: any[] = [];
  public displayedColumns: string[] = ['Employees'];
  public selectedIndex: number = 0;
  public uniqueMonth: string[] = [];

  constructor(
    private sm: StorageManagerService,
    private auth: AuthService,
    private companyService: CompanyService,
    private fb: FormBuilder,
    private languageService: LanguageService,
    private activatedRoute: ActivatedRoute,
    private taskService: TaskService,
    private router: Router,
    private layoutService: LayoutService
  ) { super() }

  ngOnInit(): void {
    window.scroll(0,0);

    this.company_id = this.activatedRoute.snapshot.queryParamMap.get('company_id');
    this.getEmployees(this.empl_page);

    this.getLangData();
    this.getCompany();
    this.getImgRoute();
    this.getCsrf();
    this.getUser();

  }

  getAllStats(page) {
    this.attachSubscriptions(
      this.taskService.getAllRateStats(this.company_id, page).pipe(
        tap(el => {
          this.pagination = {
            'pageCount': el.headers.get('x-pagination-page-count'),
            'perPage': el.headers.get('x-pagination-per-page'),
            'totalCount': el.headers.get('x-pagination-total-count'),
            'currentPage': el.headers.get('x-pagination-current-page'),
          }
        }),
        map(el => el.body),
        tap(el => {
          makeArray(el).forEach(x => {
            if (!this.uniqueMonth.includes(String(x.time_start))) {
              this.uniqueMonth.push(String(x.time_start));
              this.displayedColumns.push(String(x.time_start));
            }
          })
        })
      ).subscribe(resp => {

        console.log("resp", resp)
        

        this.uniqueMonth.forEach(m => {
          console.log(m);
          this.employees.forEach(empl => {
            empl.stats[m] = {
              salary: [],
              rate: {}
            }
          })
        })


        resp.forEach(element => {
          this.employees.find(x => x.id == element.employee_id).stats[element.time_start].salary.push(element)
          if (element.currency_id == 0) {
            this.employees.find(x => x.id == element.employee_id).stats[element.time_start].rate = element
          }
        });
        console.log("employees", this.employees)

        this.page = this.page + 1;
      })
    )
  }

  getCurrencyHTML(id) {
    switch (String(id)) {
      case '1':
        return '$'
        break;
      case '2':
        return '₽'
        break;
      case '3':
        return '€'
        break;
      case '4':
        return '₴'
        break;
    }
  }

  goToStats(e, start, end, status, employee_id) {
    e.preventDefault();
    console.log(start, end, status);
    this.router.navigate(['/tasks'], { queryParams: {
      company_id: this.company_id,
      operation_completed_at_from: +start + 1,
      operation_completed_at_to: +end,
      operation_status_id: status.join(","),
      operation_employee_id: employee_id
    }});
  }

  getEmployees(page) {
    this.attachSubscriptions(
      this.taskService.getEmployeesPaging(this.company_id, this.empl_page).pipe(
        tap(el => {
          this.empl_pagination = {
            'pageCount': el.headers.get('x-pagination-page-count'),
            'perPage': el.headers.get('x-pagination-per-page'),
            'totalCount': el.headers.get('x-pagination-total-count'),
            'currentPage': el.headers.get('x-pagination-current-page'),
          }
        }),
        map(el => el.body),
        tap(x => {
          x.map(z => z.stats = {})
        })
      ).subscribe(resp => {
        this.employees.push(...resp);
        this.empl_page = this.empl_page + 1;

        if (+this.empl_page <= +this.empl_pagination['pageCount']) {
          this.getEmployees(this.empl_page)
        } else {
          this.getAllStats(this.page);
        }
      })
    )
  }

  refreshStats() {
    this.attachSubscriptions(
      this.taskService.refreshStats(this.company_id).subscribe(resp => {
        // console.log(resp);
        this.page = 1;
        this.empl_page = 1;
        this.employees = [];
        this.pagination = undefined;
        this.empl_pagination = undefined;
        this.displayedColumns = ['Employees'];
        this.selectedIndex = 0;
        this.uniqueMonth = [];
        this.ngOnInit();
        this.layoutService.showSnackBar({name: ''}, marker("Stats is updated"), SnackBarItem)
      })
    )
  }

  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData().subscribe(resp => {
        this.activeLang = resp.active;
      })
    )
  }

  getCompany() {
    this.attachSubscriptions(
      this.companyService.getCompany(this.company_id).subscribe(resp => {
        this.companyService.company$.next(resp[0]);
        this.company = resp[0];
        if (!!resp[0] && !!resp[0].employees && !!resp[0].employees[0]) {
          this.company['rate_target'] = resp[0].employees[0].rate_target
        }
      })
    )
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  getCsrf() {
    this.attachSubscriptions(
      this.auth.$userToken.subscribe(resp => {
        this.user_id = resp.user_id;
        this.sm.localStorageSetItem("csrf_param", resp.csrf_param)
        this.sm.localStorageSetItem("csrf_token", resp.csrf_token)
      })
    )
  }

  getUser() {
    this.attachSubscriptions(
      this.auth.$user.subscribe(resp => {
        this.user = resp;
      })
    )
  }

  ngAfterViewInit(): void {
    this.pointContainer.nativeElement.scrollTop = 0;
    // this.onScroll();
  }

  onScroll() {
    this.attachSubscriptions(
      fromEvent([this.pointContainer.nativeElement], "scroll").pipe(
        filter((e:any) => e.target.scrollTop >=  e.target.scrollHeight - e.target.offsetHeight - 400),
        debounceTime(300),
        map((e:any) => Math.ceil((e.target.scrollTop + e.target.innerHeight)/ (400))),
        distinctUntilChanged()
      ).subscribe(() => {
          if (this.page <= this.pagination['pageCount']) {
            // this.getAllRateStats(this.page);
          }
        }
      )
    )
  }



  selectTab(e) {
    this.selectedIndex = e;
  }

  goBack() {
    history.back();
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}