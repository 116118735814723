<h2 mat-dialog-title class="dialog_title">
    <span>
        {{ "Select video" | translate }}
    </span>
</h2>
<mat-dialog-content class="mat-typography">
    <div class="container">
        <div class="channel_files_wrp" [appDeviceClass]="'custom-scroll custom-scroll-t'">
            <div class="channel_file" *ngFor="let item of data.videos" (click)="selectVideo(item)">
                <img [src]="data.host + (item?.thumbnailFile?.thumbnail ? item?.thumbnailFile?.thumbnail : item?.contentFile?.thumbnail) + '?company_id=' + data?.company?.id" (error)="onImgError($event)">
                <div class="channel_file_info">
                    <div class="info">
                        <div class="name">
                            <div>
                                {{ item.name ? item.name : item.contentFile.filename }}
                            </div>
                        </div>
                        <div class="description">
                            <div [id]="'desc_'+item.id" [contenteditable]="item.desc_edited">
                                No description yet
                            </div>
                        </div>
                    </div>
                    <div class="status">
                        <div class="status_btn">
                            <svg style="width: 21px !important; height: 20px !important;" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M19.8577 16.3471L11.5473 8.03662C12.3692 5.93618 11.9126 3.47044 10.1774 1.73529C8.35097 -0.0911788 5.61126 -0.456473 3.41949 0.548086L7.34641 4.475L4.6067 7.2147L0.588464 3.28779C-0.507418 5.47956 -0.0508006 8.21926 1.77567 10.0457C3.51082 11.7809 5.97655 12.2375 8.07699 11.4156L16.3874 19.726C16.7527 20.0913 17.3007 20.0913 17.666 19.726L19.7664 17.6256C20.223 17.2603 20.223 16.621 19.8577 16.3471Z" fill="#686868"/>
                            </svg>
                            <span>
                                {{ "New" | translate }}
                            </span>
                        </div>
                    </div>
                    <div class="date">
                        <div class="val">
                            {{ item.created_at*1000 | date:'MMM d, y':'':activeLang | yearPipe }}
                        </div>
                        <div class="status_val">
                            <!-- Scheduled -->
                            {{ "Created" | translate }}
                        </div>
                    </div>
                    <div class="actions">
                        <!-- <div class="text_info">
                            {{ "You will be able to edit video after it is uploaded to YouTube" | translate }}
                        </div> -->
                    </div>

                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-raised-button (click)="close()">
        {{ "Close" | translate }}
    </button>
</mat-dialog-actions>
