import { HttpParams } from '@angular/common/http';
import { ComponentRef, Injectable } from '@angular/core';
import * as moment from 'moment';
import { BehaviorSubject, Subject } from 'rxjs';
import { HttpRestService } from '../common/http-rest.service';
import { UserInfoComponent } from '../../global_components/user-info/user-info.component';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  public profilesData$: Subject<any> = new Subject();
  public closeAllUserHover$: Subject<any> = new Subject();
  private openComponents: ComponentRef<UserInfoComponent>[] = [];
  public depDeleted$: Subject<any> = new Subject();
  public posDeleted$: Subject<any> = new Subject();
  public chToEmDeleted$: Subject<any> = new Subject();
  public invited$: Subject<any> = new Subject();
  public addTask$: Subject<any> = new Subject();
  public company$: Subject<any> = new Subject();
  public companyes$: Subject<any> = new Subject();
  public reminder$: BehaviorSubject<any> = new BehaviorSubject({});
  constructor(
    private http: HttpRestService
  ) { }

  getCloseAllUserHover() {
    return this.closeAllUserHover$.asObservable();
  }

  registerComponent(componentRef: ComponentRef<UserInfoComponent>) {
    this.openComponents.push(componentRef);
  }

  unregisterComponent(componentRef: ComponentRef<UserInfoComponent>) {
    this.openComponents = this.openComponents.filter(comp => comp !== componentRef);
  }

  closeAllOpened() {
    console.log("this.openComponents", this.openComponents)
    this.openComponents.forEach(comp => {
      if (comp && !comp.hostView.destroyed) {
        comp.destroy();
      }
    });
    this.openComponents = [];
  }

  getProfilesDataStream() {
    return this.profilesData$.asObservable();
  }

  getDepDeletedStream() {
    return this.depDeleted$.asObservable();
  }

  getPosDeletedStream() {
    return this.posDeleted$.asObservable();
  }
  
  getChToEmDeletedStream() {
    return this.chToEmDeleted$.asObservable();
  }

  getInvitedStream() {
    return this.invited$.asObservable();
  }

  getCompanyStream() {
    return this.company$.asObservable();
  }

  getAddTaskStream() {
    return this.addTask$.asObservable();
  }

  getReminderStream() {
    return this.reminder$.asObservable();
  }

  getCompanyesStream() {
    return this.companyes$.asObservable();
  }

  getTimeZones() {
    return this.http.executeGet<any>('/time/timezones/')
  }

  getCompanyUsage(page, company_id, filter:any = false, per_page:string = '20') {
    let filter_params = new HttpParams().set("page", page).set("company_id", company_id).set('per-page', per_page)
    
    if (filter) {
      Object.keys(filter).forEach(element => {
        if (filter[element] == 'empl') {
          filter_params = filter_params.set(element, '1654041600');
        } else {
          if (filter[element] != '0' && filter[element] != '') {
            filter_params = filter_params.set(element, filter[element]);
          }
        }
      })
    }

    return this.http.executeGet<any>(`/api/company-usage-analytics-by-day/`, true, {
      observe: 'response',
      params: filter_params
    })
  }

  getCompanyes() {
    return this.http.executeGet<any>('/api/company/', true, {
      params: new HttpParams().set('expand', "owner,employees.employeeSetting,permissions,avatarFile,translations,employees.employeeToDepartmentPositions.department,employees.employeeToDepartmentPositions.departmentPosition").set('per-page', '200')
    })
  }

  getCompanyesForLink(discover_by_task_id, discover_by_task_operation_id ,discover_by_discussion_id, discover_by_file_id, acm) {
    let reqParams = new HttpParams().set("acm", acm).set('expand', "owner,employees.employeeSetting,permissions,avatarFile,translations,employees.employeeToDepartmentPositions.department,employees.employeeToDepartmentPositions.departmentPosition").set('per-page', '100')
    if (discover_by_task_id) {
      reqParams = reqParams.set("discover_by_task_id", discover_by_task_id)
    } 
    if (discover_by_task_operation_id) {
      reqParams = reqParams.set("discover_by_task_operation_id", discover_by_task_operation_id)
    } 
    if (discover_by_discussion_id) {
      reqParams = reqParams.set("discover_by_discussion_id", discover_by_discussion_id)
    } 
    if (discover_by_file_id) {
      reqParams = reqParams.set("discover_by_file_id", discover_by_file_id)
    } 
    return this.http.executeGet<any>('/api/company/', true, {
      params: reqParams
    })
  }

  getCompanyesCount() {
    return this.http.executeGet<any>('/api/company/', true, {
      observe: 'response',
      params: new HttpParams().set('per-page', '0')
    })
  }

  getPartnerCompanyesCount(company_id) {
    return this.http.executeGet<any>('/api/company-partnership/', true, {
      observe: 'response',
      params: new HttpParams().set('per-page', '0').set("company_id", company_id)
    })
  }

  getCompany(id) {
    return this.http.executeGet<any>(`/api/company/`, true, {
      params: new HttpParams().set('id', id).set('expand', "owner,employees.employeeSetting,permissions,avatarFile,translations,employees.employeeToDepartmentPositions.department,employees.employeeToDepartmentPositions.departmentPosition")
    })
  }

  getSchedule(company_id, page, filter) {
    let filter_params = new HttpParams().set("page", page).set("per-page", '200').set("company_id", company_id)

    if (filter) {
      Object.keys(filter).forEach(key => {
        if (!!filter[key]) {
          filter_params = filter_params.set(key, filter[key]);
        }
      })
    }

    return this.http.executeGet<any>(`/api/employee-schedule/`, true, {
      observe: 'response',
      params: filter_params
    })
  }

  addSchedule(data) {
    return this.http.executePost<any>('/api/employee-schedule/', data)
  }

  editSchedule(id, data) {
    return this.http.executePost<any>(`/api/employee-schedule/${id}/`, data)
  }

  deleteSchedule(id) {
    return this.http.executeDelete<any>(`/api/employee-schedule/${id}/`)
  }

  getScheduleItem(company_id, page, per_page:any = '200', filter) {
    let filter_params = new HttpParams().set("page", page).set("per-page", per_page).set("company_id", company_id)

    if (filter) {
      Object.keys(filter).forEach(key => {
        if (filter[key] != '0' && filter[key] != '') {
          if (key.indexOf("from_") != -1 || key.indexOf("to_") != -1) {
            filter_params = filter_params.set(key, moment(filter[key]._d).format("X"));
          } else { 
            filter_params = filter_params.set(key, filter[key]);
          }
        } else if (!!filter[key]) {
          filter_params = filter_params.set(key, filter[key]);
        }
      })
    }

    return this.http.executeGet<any>(`/api/employee-schedule-item/`, true, {
      observe: 'response',
      params: filter_params
    })
  }

  addScheduleItem(data) {
    return this.http.executePost<any>('/api/employee-schedule-item/', data)
  }

  editScheduleItem(id, data) {
    return this.http.executePost<any>(`/api/employee-schedule-item/${id}/`, data)
  }

  deleteScheduleItem(id) {
    return this.http.executeDelete<any>(`/api/employee-schedule-item/${id}/`)
  }
  
  getPrivacyHtml() {
    return this.http.executeGet<any>(`/static/privacy/`)
  }

  createCompany(data) {
    return this.http.executePost<any>('/api/company/', data)
  }

  sendSubs(data) {
    return this.http.executePost<any>('/api/push-subscription/', data)
  }

  editCompany(id, data) {
    return this.http.executePost<any>(`/api/company/${id}/`, data)
  }

  addScheduledPost(data) {
    return this.http.executePost<any>('/api/content-plan/', data)
  }

  editScheduledPost(id, data) {
    return this.http.executePost<any>(`/api/content-plan/${id}/`, data)
  }

  deleteScheduledPost(id) {
    return this.http.executeDelete<any>(`/api/content-plan/${id}/`)
  }

  getExpandPublishScheduledPosts(page, company_id, filter, per_page) {
    let filter_params = new HttpParams().set("page", page).set("per-page", per_page).set("company_id", company_id).set("expand", "channel,contentType,contentSchedule,contentScheduleItem")

    if (filter) {
      Object.keys(filter).forEach(key => {
        if (filter[key] != '0' && filter[key] != '') {
          filter_params = filter_params.set(key, filter[key]);
        }
      })
    }

    return this.http.executeGet<any>('/api/content-plan/', true, {
      observe: 'response',
      params: filter_params
    })
  }

  getExpandScheduledPosts(page, company_id, filter, per_page) {
    let filter_params = new HttpParams().set("page", page).set("per-page", per_page).set("company_id", company_id).set("expand", "task,channel,contentType,contentSchedule,contentScheduleItem")

    if (filter) {
      Object.keys(filter).forEach(key => {
        if (filter[key] != '0' && filter[key] != '') {
          if (key.indexOf("_at_") != -1) {
            filter_params = filter_params.set(key, moment(filter[key]._d).format("X"));
          } else { 
            filter_params = filter_params.set(key, filter[key]);
          }
        }
      })
    }

    return this.http.executeGet<any>('/api/content-plan/', true, {
      observe: 'response',
      params: filter_params
    })
  }

  getRegulars(page, company_id, filter?) {
    let filter_params = new HttpParams().set("page", page).set("per-page", "200").set("company_id", company_id).set("expand", "channel,channel.avatarFile,contentType,items")

    if (filter) {
      Object.keys(filter).forEach(key => {
        if (['channel_id', 'group_id'].includes(key)) {
          if (!!filter[key] && filter[key] != null && filter[key].length) {
            filter_params = filter_params.set(key, filter[key].join(","));
          }
        } else if (!!filter[key]) {
          filter_params = filter_params.set(key, filter[key]);
        }
      })
    }

    return this.http.executeGet<any>('/api/content-schedule/', true, {
      observe: 'response',
      params: filter_params
    })
  }
  
  getRegularsDyn(page, company_id, per_page:any = '200', filter?) {
    let filter_params = new HttpParams().set("page", page).set("per-page", per_page).set("company_id", company_id).set("expand", "channel,channel.avatarFile,contentType,items")

    if (filter) {
      Object.keys(filter).forEach(key => {
        filter_params = filter_params.set(key, filter[key]);
      })
    }

    return this.http.executeGet<any>('/api/content-schedule/', true, {
      observe: 'response',
      params: filter_params
    })
  }
  
  getRegularsIfDyn(page, company_id, per_page:any = '200', filter?) {
    let filter_params = new HttpParams().set("page", page).set("per-page", per_page).set("company_id", company_id).set("expand", "channel,channel.avatarFile,contentType,items")

    if (filter) {
      Object.keys(filter).forEach(key => {
        if (!!filter[key]) {
          filter_params = filter_params.set(key, filter[key]);
        }
      })
    }

    return this.http.executeGet<any>('/api/content-schedule/', true, {
      observe: 'response',
      params: filter_params
    })
  }

  // DEPARTMENTS
  getDepartments(company_id, filter?) {
    let filter_params = new HttpParams().set("per-page", "200").set("company_id", company_id)

    if (filter) {
      Object.keys(filter).forEach(key => {
        filter_params = filter_params.set(key, filter[key]);
      })
    }

    return this.http.executeGet<any>('/api/department/', true, {
      observe: 'response',
      params: filter_params
    })
  }
  
  getDepartmentsDyn(page = '1', company_id, per_page = '200', filter?) {
    let filter_params = new HttpParams().set("company_id", company_id).set('per-page', per_page).set('page', page)

    if (filter) {
      Object.keys(filter).forEach(key => {
        if (!!filter[key]) {
          filter_params = filter_params.set(key, filter[key]);
        }
      })
    }
    
    return this.http.executeGet<any>('/api/department/', true, {
      observe: 'response',
      params: filter_params
    });
  }

  addDepartment(data) {
    return this.http.executePost<any>('/api/department/', data)
  }

  editDepartment(id, data) {
    return this.http.executePost<any>(`/api/department/${id}/`, data)
  }

  deleteDepartment(id) {
    return this.http.executeDelete<any>(`/api/department/${id}/`)
  }

  getPositions(company_id, filter?) {
    let filter_params = new HttpParams().set("per-page", "200").set("company_id", company_id)

    if (filter) {
      Object.keys(filter).forEach(key => {
        filter_params = filter_params.set(key, filter[key]);
      })
    }

    return this.http.executeGet<any>('/api/department-position/', true, {
      observe: 'response',
      params: filter_params
    })
  }

  getPositionsDyn(page = '1', company_id, per_page = '200', filter?) {
    let filter_params = new HttpParams().set("company_id", company_id).set('per-page', per_page).set('page', page)

    if (filter) {
      Object.keys(filter).forEach(key => {
        if (!!filter[key]) {
          filter_params = filter_params.set(key, filter[key]);
        }
      })
    }
    
    return this.http.executeGet<any>('/api/department-position/', true, {
      observe: 'response',
      params: filter_params
    });
  }
  addPosition(data) {
    return this.http.executePost<any>('/api/department-position/', data)
  }

  editPosition(id, data) {
    return this.http.executePost<any>(`/api/department-position/${id}/`, data)
  }

  deletePosition(id) {
    return this.http.executeDelete<any>(`/api/department-position/${id}/`)
  }
  // END DEPARTMENTS

  addRegular(data) {
    return this.http.executePost<any>('/api/content-schedule/', data)
  }

  editRegular(id, data) {
    return this.http.executePost<any>(`/api/content-schedule/${id}/`, data)
  }

  deleteRegular(id) {
    return this.http.executeDelete<any>(`/api/content-schedule/${id}/`)
  }

  addRegularItem(data) {
    return this.http.executePost<any>('/api/content-schedule-item/', data)
  }

  editRegularItem(id, data) {
    return this.http.executePost<any>(`/api/content-schedule-item/${id}/`, data)
  }

  deleteRegularItem(id) {
    return this.http.executeDelete<any>(`/api/content-schedule-item/${id}/`)
  }

  deleteCompany(id) {
    return this.http.executeDelete<any>(`/api/company/${id}/`)
  }

  // company Groups
  getGroupsCompany(id) {
    return this.http.executeGet<any>('/api/channel-group/', true, {
      params: new HttpParams().set("company_id", id).set("expand","channels,channels.avatarFile,channels.platform,groupManager,channelToGroup.channel.avatarFile,channelToGroup.channel.platform").set("per-page", "200")
    })
  }

  getGroupsDyn(company_id, page, per_page='200', filter?) {
    let filter_params = new HttpParams().set('page', page).set("company_id", company_id).set("per-page", per_page)
    if (filter) {
      Object.keys(filter).forEach(key => {
        if (!!filter[key]) {
          filter_params = filter_params.set(key, filter[key]);
        }
      })
    }
    return this.http.executeGet<any>('/api/channel-group/', true, {
      observe: 'response',
      params: filter_params
    })
  }

  getInfiniteGroupsCompany(id, page, per_page='200') {
    return this.http.executeGet<any>('/api/channel-group/', true, {
      observe: 'response',
      params: new HttpParams().set("company_id", id).set('page', page).set("expand","channels,channels.avatarFile,channels.platform,groupManager,channelToGroup.channel.avatarFile,channelToGroup.channel.platform").set("per-page", per_page)
    })
  }

  getInfiniteGroupsWithFilterCompany(id, page, per_page='200', filter?) {
    let filter_params = new HttpParams().set("company_id", id).set('page', page).set("expand","channels,channels.avatarFile,channels.platform,groupManager,channelToGroup.channel.avatarFile,channelToGroup.channel.platform").set("per-page", per_page)

    if (filter) {
      Object.keys(filter).forEach(key => {
        if (['channel_id', 'group_id'].includes(key)) {
          if (!!filter[key] && filter[key] != null && filter[key].length) {
            filter_params = filter_params.set(key, filter[key].join(","));
          }
        } else if (!!filter[key]) {
          filter_params = filter_params.set(key, filter[key]);
        }
      })
    }
    return this.http.executeGet<any>('/api/channel-group/', true, {
      observe: 'response',
      params: filter_params
    })
  }

  getInfiniteGroupsWithFilterCompanyWithoutExp(id, page, per_page='200', filter?) {
    let filter_params = new HttpParams().set("company_id", id).set('page', page).set("per-page", per_page)

    if (filter) {
      Object.keys(filter).forEach(key => {
        if (['channel_id', 'group_id'].includes(key)) {
          if (!!filter[key] && filter[key] != null && filter[key].length) {
            filter_params = filter_params.set(key, filter[key].join(","));
          }
        } else if (['min_farthest_content_published_at_from', 'max_farthest_content_published_at_to'].includes(key) && filter[key] !== '') {
          filter_params = filter_params.set(key, filter[key]);
        } else if (!!filter[key]) {
          filter_params = filter_params.set(key, filter[key]);
        }
      })
    }
    return this.http.executeGet<any>('/api/channel-group/', true, {
      observe: 'response',
      params: filter_params
    })
  }

  getGroupsPartnerCompany(company_id, partner_company_id) {
    return this.http.executeGet<any>('/api/channel-group/', true, {
      params: new HttpParams().set("company_id", company_id).set("partner_company_id", partner_company_id).set("expand","channels,channels.platform,groupManager").set("per-page", "200")
    })
  }

  getInfiniteGroupsPartnerCompany(company_id, partner_company_id, page, per_page='200') {
    return this.http.executeGet<any>('/api/channel-group/', true, {
      observe: 'response',
      params: new HttpParams().set("company_id", company_id).set('page', page).set("per-page", per_page).set("partner_company_id", partner_company_id).set("expand","channels,channels.platform,groupManager").set("per-page", "200")
    })
  }

  getGroupCompany(id) {
    return this.http.executeGet<any>(`/api/channel-group/${id}/`)
  }

  createGroupCompany(data) {
    return this.http.executePost<any>('/api/channel-group/', data)
  }

  editGroupCompany(id, data) {
    return this.http.executePost<any>(`/api/channel-group/${id}/`, data)
  }

  deleteGroupCompany(id) {
    return this.http.executeDelete<any>('/api/channel-group/', true, {
      params: new HttpParams().set("id", id)
    })
  }

  getChannelsToGroup(company_id, filter?) {
    let filter_params = new HttpParams().set("per-page", "200").set("company_id", company_id).set("expand", "channel,channel.avatarFile,contentType,channelGroup")

    if (filter) {
      Object.keys(filter).forEach(key => {
        filter_params = filter_params.set(key, filter[key]);
      })
    }

    return this.http.executeGet<any>('/api/channel-to-group/', true, {
      observe: 'response',
      params: filter_params
    })
  }

  addChannelToGroup(data, company_id) {
    return this.http.executePost<any>('/api/channel-to-group/', data, true, {
      params: new HttpParams().set("company_id", company_id)
    })
  }

  deleteChannelToGroup(id) {
    return this.http.executeDelete<any>('/api/channel-to-group/', true, {
      params: new HttpParams().set("id", id)
    })
  }

  deleteProfileCompany(id, group_id?) {
    return this.http.executeDelete<any>(`/api/channel/${id}/`, true)
  }

  getGroupProfiles(company_id, group_id) {
    return this.http.executeGet<any>('/api/channel/', true, {
      params: new HttpParams().set("company_id", company_id).set("expand", "avatarFile").set("group_id", group_id).set("per-page", "500")
    });
  }

  getProfiles(company_id, page = '1') {
    return this.http.executeGet<any>('/api/channel/', true, {
      observe: 'response',
      params: new HttpParams().set("company_id", company_id).set("expand","avatarFile,groups,channelAccount,channelAccount.computedUrl").set('per-page', '500').set('page', page)
    });
  }

  getProfileById(company_id, id) {
    return this.http.executeGet<any>('/api/channel/', true, {
      params: new HttpParams().set("company_id", company_id).set("id", id).set('per-page', '1')
    });
  }

  getProfilesDyn(company_id, page = '1') {
    return this.http.executeGet<any>('/api/channel/', true, {
      observe: 'response',
      params: new HttpParams().set("company_id", company_id).set("expand","avatarFile,groups,channelAccount,channelAccount.computedUrl").set('per-page', '50').set('page', page)
    });
  }

  getAllProfilesDyn(page = '1', company_id, per_page = '50', filter?) {
    let filter_params = new HttpParams().set("company_id", company_id).set("expand","avatarFile,groups,channelAccount,channelAccount.computedUrl,parameterValuesToChannel.parameterValue,parameterValuesToChannel.parameter").set('per-page', per_page).set('page', page)

    if (filter) {
      Object.keys(filter).forEach(key => {
        if (!!filter[key]) {
          filter_params = filter_params.set(key, filter[key]);
        }
      })
    }
    
    return this.http.executeGet<any>('/api/channel/', true, {
      observe: 'response',
      params: filter_params
    });
  }

  getFilteredProfilesDyn(company_id, page = '1', filter) {
    let filter_params = new HttpParams().set("company_id", company_id).set("expand","groups,channelAccount,channelAccount.computedUrl,avatarFile").set('per-page', '50').set('page', page)

    if (filter) {
      Object.keys(filter).forEach(key => {
        if (!!filter[key]) {
          filter_params = filter_params.set(key, filter[key]);
        }
      })
    }
    return this.http.executeGet<any>('/api/channel/', true, {
      observe: 'response',
      params: filter_params
    });
  }

  getFilteredProfiles(company_id, page = '1', filter) {
    let filter_params = new HttpParams().set("company_id", company_id).set("expand","groups,channelAccount,channelAccount.computedUrl,avatarFile").set('per-page', '500').set('page', page)

    console.log("getFilteredProfiles", filter)
    if (filter) {
      Object.keys(filter).forEach(key => {
        if (key == 'group_id' && filter[key] === 0) {
          filter_params = filter_params.set(key, filter[key]);
        } else if (!!filter[key]) {
          filter_params = filter_params.set(key, filter[key]);
        }
      })
    }
    return this.http.executeGet<any>('/api/channel/', true, {
      observe: 'response',
      params: filter_params
    });
  }

  getContentTypes(channel_platform_id:any = false, page = '1') {

    let filter_params = new HttpParams().set('per-page', '200').set('page', page)

    if (channel_platform_id) {
      filter_params = filter_params.set("channel_platform_id", channel_platform_id);
    }

    return this.http.executeGet<any>('/api/content-type/', true, {
      observe: 'response',
      params: filter_params
    });
  }

  getImports(page, company_id, per_page:any = '200') {
    let filter_params = new HttpParams().set('per-page', per_page).set('page', page).set('company_id', company_id)

    return this.http.executeGet<any>('/api/task-channel-data-import-queue/', true, {
      observe: 'response',
      params: filter_params
    });
  }

  getSftps(page, company_id, per_page:any = '200') {

    let filter_params = new HttpParams().set('per-page', per_page).set('page', page).set('company_id', company_id)

    return this.http.executeGet<any>('/api/youtube-sftp-dropbox/', true, {
      observe: 'response',
      params: filter_params
    });
  }

  getSftpChannels(page, company_id, youtube_sftp_dropbox_id, per_page:any = '200') {

    let filter_params = new HttpParams().set('per-page', per_page).set('page', page).set('youtube_sftp_dropbox_id', youtube_sftp_dropbox_id).set('company_id', company_id).set('expand', 'youtubeSftpDropbox,channel')

    return this.http.executeGet<any>('/api/youtube-sftp-dropbox-to-channel/', true, {
      observe: 'response',
      params: filter_params
    });
  }
  
  addImport(data) {
    return this.http.executePost<any>('/api/task-channel-data-import-queue/', data);
  }

  editImport(id, data) {
    return this.http.executePost<any>(`/api/task-channel-data-import-queue/${id}/`, data);
  }

  deleteImport(id) {
    return this.http.executeDelete<any>(`/api/task-channel-data-import-queue/${id}/`)
  }

  addSftp(data) {
    return this.http.executePost<any>('/api/youtube-sftp-dropbox/', data);
  }

  addSftpChannel(data) {
    return this.http.executePost<any>('/api/youtube-sftp-dropbox-to-channel/', data);
  }

  editSftp(id, data) {
    return this.http.executePost<any>(`/api/youtube-sftp-dropbox/${id}/`, data);
  }

  deleteSftp(id) {
    return this.http.executeDelete<any>(`/api/youtube-sftp-dropbox/${id}/`)
  }

  deleteSftpChannel(id) {
    return this.http.executeDelete<any>(`/api/youtube-sftp-dropbox-to-channel/${id}/`)
  }

  getGroupPlatforms() {
    return this.http.executeGet<any>('/api/channel-platform/', true, {
      params: new HttpParams().set('per-page', '200')
    });
  }

  getViewsUpdateIntervalList() {
    return this.http.executeGet<any>('/api/task-channel/views-update-interval-list/', true, {
      params: new HttpParams().set('per-page', '200')
    });
  }

  createCompanyGroupProfile(form) {
    return this.http.executePost<any>('/api/channel/', form);
  }

  getGroupProfile(id) {
    return this.http.executeGet<any>(`/api/channel/${id}/`, true, {
      params: new HttpParams().set("expand","groups,avatarFile")
    })
  }
  
  editCompanyGroupProfile(id, form) {
    return this.http.executePost<any>(`/api/channel/${id}/`, form);
  }

  getInvite(code) {
    return this.http.executeGet<any>('/api/employee/', true, {
      params: new HttpParams().set("invite", code)
    })
  }

  getCurrencyList() {
    return this.http.executeGet<any>('/api/currency/')
  }
  
  acceptInvite(id, code) {
    return this.http.executePost<any>(`/api/employee/${id}/`, {
      accept_invite: 1
    }, true, {
      params: new HttpParams().set("invite", code)
    })
  }

  rejectInvite(id) {
    return this.http.executePost<any>(`/api/employee/${id}/`, {
      reject_invite: 1
    })
  }

  getInvites() {
    return this.http.executeGet<any>('/api/employee/');
  }

  getEmployeee(id) {
    return this.http.executeGet<any>(`/api/employee/${id}/`);
  }

  editEmployeee(id, data) {
    return this.http.executePost<any>(`/api/employee/${id}/`, data)
  }

  addPayment(data, company_id) {
    return this.http.executePost<any>('/api/payment/', Object.assign({company_id: company_id}, data))
  }

  editPayment(id, data) {
    return this.http.executePost<any>(`/api/payment/${id}/`, data)
  }

  deletePayment(id) {
    return this.http.executeDelete<any>(`/api/payment/${id}/`)
  }

  getPayments(page, company_id, employee_id:any = 0, partner_company_id:any = 0, per_page:any = 50, paid_at_from?, paid_at_to?) {
    let _params = new HttpParams().set("company_id", company_id).set("expand","employee,paymentSystem,currency,files,partnerCompany.avatarFile").set('per-page', per_page).set('page', page)
    if (employee_id) {
      _params = _params.set("employee_id", employee_id.toString())
    }
    if (partner_company_id) {
      _params = _params.set("partner_company_id", partner_company_id.toString())
    }
    if (paid_at_from) {
      _params = _params.set("paid_at_from", paid_at_from)
      _params = _params.set("paid_at_to", paid_at_to)
    }
    return this.http.executeGet<any>('/api/payment/', true, {
      observe: 'response',
      params: _params
    });
  }

  getPayment(id) {
    return this.http.executeGet<any>(`/api/payment/${id}/`);
  }

  getPaymentSystem() {
    return this.http.executeGet<any>(`/api/payment-system/`)
  }

  // partners Start
  getPartners(filter) {
    let _params = new HttpParams().set("expand","company,partnerCompany,partnerCompany.avatarFile").set('per-page', '200')
    if (filter) {
      Object.keys(filter).forEach(element => {
        _params = _params.set(element, filter[element]);
      });
    }
    return this.http.executeGet<any>('/api/company-partnership/', true, {
      params: _params
    });
  }

  getPartnersDyn(page, company_id, filter?, per_page = '200') {
    let filter_params = new HttpParams().set('page', page).set("company_id", company_id).set("expand", 'company,partnerCompany,partnerCompany.avatarFile').set('per-page', per_page)

    if (filter) {
      Object.keys(filter).forEach(element => {
          filter_params = filter_params.set(element, filter[element]);
      });
    }

    return this.http.executeGet<any>(`/api/company-partnership/`, true, {
      observe: 'response',
      params: filter_params
    })
  }

  addPartner(data){
    return this.http.executePost<any>('/api/company-partnership/', data)
  }

  editPartner(id, data){
    return this.http.executePost<any>(`/api/company-partnership/${id}/`, data)
  }

  deletePartner(id) {
    return this.http.executeDelete<any>(`/api/company-partnership/${id}/`)
  }

  checkPartner(id, invite) {
    return this.http.executeGet<any>('/api/company-partnership/', true, {
      params: new HttpParams().set("expand","company,partnerCompany,partnerCompany.avatarFile").set('per-page', '200').set('id', id).set('invite', invite)
    })
  }
  
  acceptPartner(id, invite, data) {
    return this.http.executePost<any>(`/api/company-partnership/${id}/`, data, true, {
      params: new HttpParams().set('invite', invite)
    })
  }
  
  getVideos(page, company_id, filter) {
    let filter_params = new HttpParams().set('page', page).set("per-page", '20').set('company_id', company_id).set("expand", "taskChannels.task");

    if (filter) {
      Object.keys(filter).forEach(element => {
        filter_params = filter_params.set(element, filter[element]);
      });
    }

    return this.http.executeGet<any>('/api/youtube-data-video/', true, {
      observe: 'response',
      params: filter_params
    })
  }
  // partners End


  // bookmarks start

  getBookmarksExp(company_id, page, filter) {
    let _params = new HttpParams().set('company_id', company_id).set('page', page).set('per-page', '50')
    if (filter) {
      Object.keys(filter).forEach(element => {
        _params = _params.set(element, filter[element]);
      });
    }
    _params = _params.set("expand", "employee,sectionToEmployee,sectionToEmployee.employee,sectionToEmployees,sectionToEmployees.employee");
    return this.http.executeGet<any>('/api/section/', true, {
      observe: 'response',
      params: _params
    });
  }

  getBookmarks(company_id, filter) {
    let _params = new HttpParams().set('company_id', company_id).set('is_active', '1').set('per-page', '200')
    if (filter) {
      Object.keys(filter).forEach(element => {
        _params = _params.set(element, filter[element]);
      });
    }
    return this.http.executeGet<any>('/api/section/', true, {
      params: _params
    });
  }

  addBookmark(data){
    return this.http.executePost<any>('/api/section/', data)
  }

  editBookmark(id, data) {
    return this.http.executePost<any>(`/api/section/${id}/`, data)
  }

  deleteBookmark(id) {
    return this.http.executeDelete<any>(`/api/section/${id}/`)
  }

  addBookmarkEmpl(data){
    return this.http.executePost<any>('/api/section-to-employee/', data)
  }

  editBookmarkEmpl(id, data){
    return this.http.executePost<any>(`/api/section-to-employee/${id}/`, data)
  }

  deleteBookmarkEmpl(id) {
    return this.http.executeDelete<any>(`/api/section-to-employee/${id}/`)
  }

  getSharedTasks(filter) {
    let _params = new HttpParams().set('per-page', '200').set('expand', 'channels,files,documents,partOfTasks,consistOfTasks,relatedTasks')
    if (filter) {
      Object.keys(filter).forEach(element => {
        _params = _params.set(element, filter[element]);
      });
    }
    return this.http.executeGet<any>('/shared/task/', true, {
      params: _params
    });
  }

  getAccesses(company_id, filter) {
    let _params = new HttpParams().set('company_id', company_id).set('per-page', '200')
    if (filter) {
      Object.keys(filter).forEach(element => {
        _params = _params.set(element, filter[element]);
      });
    }
    return this.http.executeGet<any>('/api/task-share/', true, {
      params: _params
    });
  }

  addAccesses(company_id, data) {
    let _params = new HttpParams().set('company_id', company_id)
    return this.http.executePost<any>('/api/task-share/', data, true, {
      params: _params
    })
  }

  editAccesses(id, data, company_id) {
    let _params = new HttpParams().set('company_id', company_id)
    return this.http.executePost<any>(`/api/task-share/${id}/`, data, true, {
      params: _params
    })
  }

  deleteAccess(id, company_id) {
    return this.http.executeDelete<any>(`/api/task-share/${id}/`, true, {
      params: new HttpParams().set('company_id', company_id)
    })
  }

  getNotes(company_id, filter) {
    let _params = new HttpParams().set('company_id', company_id).set('per-page', '200')
    if (filter) {
      Object.keys(filter).forEach(element => {
        _params = _params.set(element, filter[element]);
      });
    }
    return this.http.executeGet<any>('/api/document/', true, {
      params: _params
    });
  }

  getNoteRelations(company_id, filter) {
    let _params = new HttpParams().set('company_id', company_id).set('per-page', '200')
    if (filter) {
      Object.keys(filter).forEach(element => {
        _params = _params.set(element, filter[element]);
      });
    }
    return this.http.executeGet<any>('/api/document-relation/', true, {
      params: _params
    });
  }

  addNote(company_id, data, target) {
    let _params = new HttpParams().set('company_id', company_id)
    Object.keys(target).forEach(element => {
      _params = _params.set(element, target[element]);
    });
    return this.http.executePost<any>('/api/document/', data, true, {
      params: _params
    })
  }

  editNote(id, data, company_id, target) {
    let _params = new HttpParams().set('company_id', company_id)
    Object.keys(target).forEach(element => {
      _params = _params.set(element, target[element]);
    });
    return this.http.executePost<any>(`/api/document/${id}/`, data, true, {
      params: _params
    })
  }

  editRelation(id, data, company_id) {
    let _params = new HttpParams().set('company_id', company_id)
    return this.http.executePost<any>(`/api/document-relation/${id}/`, data, true, {
      params: _params
    })
  }

  deleteNote(id, company_id) {
    return this.http.executeDelete<any>(`/api/document/${id}/`, true, {
      params: new HttpParams().set('company_id', company_id)
    })
  }
  
  deleteNoteRelation(id, company_id) {
    return this.http.executeDelete<any>(`/api/document-relation/${id}/`, true, {
      params: new HttpParams().set('company_id', company_id)
    })
  }
  // bookmarks end
}
