import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpRestService } from '../common/http-rest.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MembersService {
  public addMobExec$: Subject<any> = new Subject();
  public backMobExec$: Subject<any> = new Subject();
  public deleteMobExec$: Subject<any> = new Subject();
  constructor(
    private http: HttpRestService
  ) { }
  
  getDeleteMobExec() {
    return this.deleteMobExec$.asObservable();
  }

  getBackMobExec() {
    return this.backMobExec$.asObservable();
  }

  getAddingMobExec() {
    return this.addMobExec$.asObservable();
  }
  
  getMembers(filter, company_id) {
    let filter_params = new HttpParams().set("per-page", "200").set("company_id", company_id).set("expand","employee,employee.currency,currency,discussion,employeeStatus,employeeStatus.status")
    if (filter) {
      Object.keys(filter).forEach(key => {
        filter_params = filter_params.set(key, filter[key]);
      })
    }
    return this.http.executeGet<any>('/api/task-employee/', true, {
      params: filter_params
    })
  }

  getAutoEmplPos(company_id, filter, page) {
    let filter_params = new HttpParams().set("page", page).set("per-page", "200").set("company_id", company_id)
    if (filter) {
      Object.keys(filter).forEach(key => {
        filter_params = filter_params.set(key, filter[key]);
      })
    }
    return this.http.executeGet<any>('/api/automation-employee-position/', true, {
      observe: 'response',
      params: filter_params
    })
  }

  getAutoEmplPosDyn(company_id, filter, page, per_page) {
    let filter_params = new HttpParams().set("page", page).set("per-page", per_page).set("company_id", company_id)
    if (filter) {
      Object.keys(filter).forEach(key => {
        filter_params = filter_params.set(key, filter[key]);
      })
    }
    return this.http.executeGet<any>('/api/automation-employee-position/', true, {
      observe: 'response',
      params: filter_params
    })
  }

  addAutoEmplPos(data, company_id) {
    return this.http.executePost<any>('/api/automation-employee-position/', data, true, {
      params: new HttpParams().set("company_id", company_id)
    })
  }

  editAutoEmplPos(id, data, company_id) {
    return this.http.executePost<any>(`/api/automation-employee-position/${id}/`, data, true, {
      params: new HttpParams().set("company_id", company_id)
    })
  }

  deleteAutoEmplPos(id, company_id) {
    return this.http.executeDelete<any>(`/api/automation-employee-position/${id}/`, true, {
      params: new HttpParams().set("company_id", company_id)
    })
  }


  getTaskPartners(filter, company_id) {
    let filter_params = new HttpParams().set("per-page", "200").set("company_id", company_id).set("expand","partnerCompany,partnerCompany.avatarFile,partnerCompany.currency,currency,discussion,partnerCompanyStatus")
    if (filter) {
      Object.keys(filter).forEach(key => {
        filter_params = filter_params.set(key, filter[key]);
      })
    }
    return this.http.executeGet<any>('/api/task-partner/', true, {
      params: filter_params
    })
  }

  getTaskPartnerEmployees(filter, company_id, partner_company_id) {
    let filter_params = new HttpParams().set("per-page", "200").set("partner_company_id", partner_company_id).set("company_id", company_id).set("expand","partnerEmployee,discussion,partnerEmployeeStatus")
    if (filter) {
      Object.keys(filter).forEach(key => {
        filter_params = filter_params.set(key, filter[key]);
      })
    }
    return this.http.executeGet<any>('/api/task-partner-employee/', true, {
      params: filter_params
    })
  }

  getMember(id) {
    return this.http.executeGet<any>(`/api/task-employee/${id}/`)
  }

  getTaskPartner(id) {
    return this.http.executeGet<any>(`/api/task-partner/${id}/`)
  }

  addMember(data, company_id) {
    return this.http.executePost<any>('/api/task-employee/', data, true, {
      params: new HttpParams().set("company_id", company_id)
    })
  }

  editMember(id, data, company_id) {
    return this.http.executePost<any>(`/api/task-employee/${id}/`, data, true, {
      params: new HttpParams().set("company_id", company_id)
    })
  }

  deleteMember(id, company_id) {
    return this.http.executeDelete<any>(`/api/task-employee/${id}/`, true, {
      params: new HttpParams().set("company_id", company_id)
    })
  }
  
  addTaskPartner(data, company_id) {
    return this.http.executePost<any>('/api/task-partner/', data, true, {
      params: new HttpParams().set("company_id", company_id)
    })
  }

  editTaskPartner(id, data, company_id) {
    return this.http.executePost<any>(`/api/task-partner/${id}/`, data, true, {
      params: new HttpParams().set("company_id", company_id)
    })
  }

  deleteTaskPartner(id, company_id) {
    return this.http.executeDelete<any>(`/api/task-partner/${id}/`, true, {
      params: new HttpParams().set("company_id", company_id)
    })
  }

  addTaskPartnerEmployee(data, company_id) {
    return this.http.executePost<any>('/api/task-partner-employee/', data, true, {
      params: new HttpParams().set("company_id", company_id)
    })
  }

  editTaskPartnerEmployee(id, data, company_id) {
    return this.http.executePost<any>(`/api/task-partner-employee/${id}/`, data, true, {
      params: new HttpParams().set("company_id", company_id)
    })
  }

  deleteTaskPartnerEmployee(id, company_id) {
    return this.http.executeDelete<any>(`/api/task-partner-employee/${id}/`, true, {
      params: new HttpParams().set("company_id", company_id)
    })
  }
  // STATUSES

  getTaskEmployeeStatusesWithoutExp(filter, company_id, perPage:string = '200') {
    let filter_params = new HttpParams().set("per-page", perPage).set("company_id", company_id)

    if (filter) {
      Object.keys(filter).forEach(key => {
        filter_params = filter_params.set(key, filter[key]);
      })
    }

    return this.http.executeGet<any>('/api/task-employee-status/', true, {
      params: filter_params
    })
  }

  getTaskEmployeeStatuses(filter, company_id, perPage:string = '200') {
    let filter_params = new HttpParams().set("per-page", perPage).set("company_id", company_id).set("expand","employee,discussion,taskOperation")
    if (filter) {
      Object.keys(filter).forEach(key => {
        filter_params = filter_params.set(key, filter[key]);
      })
    }
    return this.http.executeGet<any>('/api/task-employee-status/', true, {
      params: filter_params
    })
  }

  getTaskPartnerStatusesWithoutExp(filter, company_id, perPage:string = '200') {
    let filter_params = new HttpParams().set("per-page", perPage).set("company_id", company_id)
    if (filter) {
      Object.keys(filter).forEach(key => {
        filter_params = filter_params.set(key, filter[key]);
      })
    }
    return this.http.executeGet<any>('/api/task-partner-status/', true, {
      params: filter_params
    })
  }

  getTaskPartnerStatuses(filter, company_id, perPage:string = '200') {
    let filter_params = new HttpParams().set("per-page", perPage).set("company_id", company_id).set("expand","partnerCompany,partnerCompany.avatarFile,discussion,taskOperation")
    if (filter) {
      Object.keys(filter).forEach(key => {
        filter_params = filter_params.set(key, filter[key]);
      })
    }
    return this.http.executeGet<any>('/api/task-partner-status/', true, {
      params: filter_params
    })
  }

  getTaskPartnerEmployeeStatuses(filter, company_id, perPage:string = '200') {
    let filter_params = new HttpParams().set("per-page", perPage).set("company_id", company_id).set("expand","partnerCompany,partnerCompany.avatarFile,discussion,taskOperation")
    if (filter) {
      Object.keys(filter).forEach(key => {
        filter_params = filter_params.set(key, filter[key]);
      })
    }
    return this.http.executeGet<any>('/api/task-partner-employee-status/', true, {
      params: filter_params
    })
  }



  getTaskEmployeeStatus(id) {
    return this.http.executeGet<any>(`/api/task-employee-status/${id}/`)
  }

  addTaskEmployeeStatus(data, company_id) {
    return this.http.executePost<any>('/api/task-employee-status/', data, true, {
      params: new HttpParams().set("company_id", company_id)
    })
  }

  editTaskEmployeeStatus(id, data, company_id) {
    return this.http.executePost<any>(`/api/task-employee-status/${id}/`, data, true, {
      params: new HttpParams().set("company_id", company_id)
    })
  }

  addPartnerEmployeeStatus(data, company_id) {
    return this.http.executePost<any>('/api/task-partner-employee-status/', data, true, {
      params: new HttpParams().set("company_id", company_id)
    })
  }

  editPartnerEmployeeStatus(id, data, company_id) {
    return this.http.executePost<any>(`/api/task-partner-employee-status/${id}/`, data, true, {
      params: new HttpParams().set("company_id", company_id)
    })
  }

  deleteTaskEmployeeStatus(id) {
    return this.http.executeDelete<any>(`/api/task-employee-status/${id}/`)
  }
  
  getPartnerStatus(id) {
    return this.http.executeGet<any>(`/api/task-partner-status/${id}/`)
  }

  addPartnerStatus(data, company_id) {
    return this.http.executePost<any>('/api/task-partner-status/', data, true, {
      params: new HttpParams().set("company_id", company_id)
    })
  }

  editPartnerStatus(id, data, company_id) {
    return this.http.executePost<any>(`/api/task-partner-status/${id}/`, data, true, {
      params: new HttpParams().set("company_id", company_id)
    })
  }

  deletePartnerStatus(id) {
    return this.http.executeDelete<any>(`/api/task-partner-status/${id}/`)
  }

  getChannelToEmployee(company_id, filter) {
    let filter_params = new HttpParams().set("per-page", '200').set("company_id", company_id).set("expand","employee,partnerEmployee,channel.avatarFile")

    if (filter) {
      Object.keys(filter).forEach(key => {
        filter_params = filter_params.set(key, filter[key]);
      })
    }

    return this.http.executeGet<any>(`/api/channel-to-employee/`, true, {
      params: filter_params
    })
  }

  getAllChannelToEmployee(page, company_id, filter:any = null, perPage:string = '200') {
    let filter_params = new HttpParams().set('company_id', company_id).set('per-page', perPage).set('page', page).set("expand","employee,partnerEmployee,channel.avatarFile")

    if (filter) {
      Object.keys(filter).forEach(element => {
        filter_params = filter_params.set(element, filter[element]);
      });
    }
    
    return this.http.executeGet<any>(`/api/channel-to-employee/`, true, {
      observe: 'response',
      params: filter_params
    })
  }

  addChannelToEmployee(data, company_id) {
    return this.http.executePost<any>('/api/channel-to-employee/', data, true, {
      params: new HttpParams().set("company_id", company_id)
    })
  }

  editChannelToEmployee(id, data) {
    return this.http.executePost<any>(`/api/channel-to-employee/${id}/`, data)
  }

  deleteChannelToEmployee(id) {
    return this.http.executeDelete<any>(`/api/channel-to-employee/${id}/`)
  }

  getChannelGroupToEmployee(company_id, filter) {
    let filter_params = new HttpParams().set("per-page", '200').set("company_id", company_id).set("expand","employee,channelGroup")

    if (filter) {
      Object.keys(filter).forEach(key => {
        filter_params = filter_params.set(key, filter[key]);
      })
    }

    return this.http.executeGet<any>(`/api/channel-group-to-employee/`, true, {
      params: filter_params
    })
  }

  getChannelGroupToEmployeeDyn(company_id, filter, page, per_page = '200') {
    let filter_params = new HttpParams().set("page", page).set("per-page", per_page).set("company_id", company_id).set("expand","employee,channelGroup")

    if (filter) {
      Object.keys(filter).forEach(key => {
        filter_params = filter_params.set(key, filter[key]);
      })
    }

    return this.http.executeGet<any>(`/api/channel-group-to-employee/`, true, {
      observe: 'response',
      params: filter_params
    })
  }


  getPostionsToEmployee(company_id, filter) {
    let filter_params = new HttpParams().set("per-page", '200').set("company_id", company_id).set("expand","employee,department,departmentPosition")

    if (filter) {
      Object.keys(filter).forEach(key => {
        filter_params = filter_params.set(key, filter[key]);
      })
    }

    return this.http.executeGet<any>(`/api/employee-to-department-position/`, true, {
      params: filter_params
    })
  }

  addPositionToEmployee(data) {
    return this.http.executePost<any>('/api/employee-to-department-position/', data)
  }

  editPositionToEmployee(id, data) {
    return this.http.executePost<any>(`/api/employee-to-department-position/${id}/`, data)
  }

  deletePositionToEmployee(id) {
    return this.http.executeDelete<any>(`/api/employee-to-department-position/${id}/`)
  }

  getPriceToEmployee(company_id, filter) {
    let filter_params = new HttpParams().set("per-page", '200').set("company_id", company_id)

    if (filter) {
      Object.keys(filter).forEach(key => {
        filter_params = filter_params.set(key, filter[key]);
      })
    }

    return this.http.executeGet<any>(`/api/automation-employee-price/`, true, {
      params: filter_params
    })
  }

  addPriceToEmployee(data) {
    return this.http.executePost<any>('/api/automation-employee-price/', data)
  }

  editPriceToEmployee(id, data) {
    return this.http.executePost<any>(`/api/automation-employee-price/${id}/`, data)
  }

  deletePriceToEmployee(id) {
    return this.http.executeDelete<any>(`/api/automation-employee-price/${id}/`)
  }
  
  getGroupToEmployee(company_id, filter) {
    let filter_params = new HttpParams().set("per-page", '200').set("company_id", company_id).set("expand","employee,group")

    if (filter) {
      Object.keys(filter).forEach(key => {
        filter_params = filter_params.set(key, filter[key]);
      })
    }

    return this.http.executeGet<any>(`/api/employee-to-group/`, true, {
      params: filter_params
    })
  }

  addGroupToEmployee(data) {
    return this.http.executePost<any>('/api/employee-to-group/', data)
  }

  editGroupToEmployee(id, data) {
    return this.http.executePost<any>(`/api/employee-to-group/${id}/`, data)
  }

  deleteGroupToEmployee(id) {
    return this.http.executeDelete<any>(`/api/employee-to-group/${id}/`)
  }

  addChannelGroupToEmployee(data, company_id?) {
    let filter_params = new HttpParams()
    if (company_id) {
      filter_params = filter_params.set("company_id", company_id)
    }
    return this.http.executePost<any>('/api/channel-group-to-employee/', data, true, {
      params: filter_params
    })
  }

  editChannelGroupToEmployee(id, data) {
    return this.http.executePost<any>(`/api/channel-group-to-employee/${id}/`, data)
  }

  deleteChannelGroupToEmployee(id) {
    return this.http.executeDelete<any>(`/api/channel-group-to-employee/${id}/`)
  }
}
