import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { BaseClass } from 'src/app/shared/models/base-class';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { AskTextComponent } from '../ask-text/ask-text.component';
import { SnackBarItem } from '../snack-bar/snack-bar-item';
import { LayoutService } from '../../services/common/layout.service';

@Component({
  selector: 'app-post-extra-info',
  templateUrl: './post-extra-info.component.html',
  styleUrls: ['./post-extra-info.component.scss']
})
export class PostExtraInfoComponent extends BaseClass implements OnInit, OnDestroy {

  public extras: any = [];
  public page: number = 1;
  public pagination: any;
  public today = moment().set({hour:0,minute:0,second:0}).unix();
  public todayEnd = moment().endOf('day').unix();
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PostExtraInfoComponent>,
    private taskService: TaskService,
    private dialog: MatDialog,
    private layoutService: LayoutService,
  ) { super() }

  ngOnInit(): void {
    console.log("PostExtraInfoComponent", this.data)
    this.getPostExtra(this.page);
  }

  startBroadcast(item) {
    this.attachSubscriptions(
      this.taskService.editBroadcast(item.id, {is_pause: 0}).subscribe(resp => {
        this.refresh()
      })
    )
  }

  pauseBroadcast(item) {
    const dialogRef = this.dialog.open(AskTextComponent, {
      disableClose: true,
      panelClass: 'push_settigns_modal',
      data: {
        title: "Fill pause time",
        field: 'Minutes',
        company_id: this.data.company.id
      }
    });
    
    this.attachSubscriptions(
      dialogRef.afterClosed().pipe(
        switchMap(result => {
          if (!!result && !!result.data) {
            return this.taskService.editBroadcast(item.id, {is_pause: 1, pause_timeout: typeof +result.data == 'number' ? result.data*60 : 60})
          } else {
            return of(false)
          }
        })
      ).subscribe(res => {
        if (!!res) {
          this.refresh()
        } else {
          this.layoutService.showSnackBar({name: "Something went wrong"}, "Try again", SnackBarItem)
        }
      }, error => {
        this.layoutService.showSnackBar({name: "Something went wrong"}, error, SnackBarItem)
      })
    )
  }

  

  moveBroadcast(item) {
    this.attachSubscriptions(
      this.taskService.editBroadcast(item.id, {is_active: 0}).subscribe(resp => {
        this.refresh()
      }, error => {
        this.layoutService.showSnackBar({name: "Something went wrong"}, error, SnackBarItem)
      })
    )
  }

  refresh() {
    this.page = 1;
    this.getPostExtra(this.page);
  }

  log() {
    console.log("extras", this.extras)
  }

  getPostExtra(page) {
    this.taskService.getPostExtra(page, this.data.company.id, {task_channel_id: this.data.post.id}).pipe(
      tap(el => {
        this.pagination = {
          'pageCount': el.headers.get('x-pagination-page-count'),
          'perPage': el.headers.get('x-pagination-per-page'),
          'totalCount': el.headers.get('x-pagination-total-count'),
          'currentPage': el.headers.get('x-pagination-current-page'),
        }
      }),
      map(el => el.body)
    ).subscribe(resp => {
      if (page == 1) {
        this.extras = resp;
      } else {
        this.extras.push(...resp);
      }
      console.log("getPostExtra - " +page, this.extras)
      this.page++;
    })
  }

  close() {
    this.dialogRef.close()
  }


  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
