<div class="container centred_page">

    <div class="step">
        <h2>{{ 'Company' | translate }}: {{ company?.name }}</h2>
  
        <h3>{{ 'Job types' | translate }}: </h3>
        
        <mat-list role="list">
            <mat-list-item *ngFor="let type of types; let i = index;" role="listitem" class="company_item">
                <div class="type_name">
                    <mat-checkbox [disabled]="type.disabled" class="mr-1" color="primary" (change)="toggleType($event, type)" [checked]="type?.operationToCompany?.length > 0"></mat-checkbox>
                    {{ type?.name }}
                </div>

                <div class="d-f-c">
                    <div class="company_btns" *ngIf="type.company_id != 0">
                        <mat-icon (click)="editType(type)">edit</mat-icon>
                        <mat-icon (click)="deleteType(type, i)">delete</mat-icon>
                    </div>
                </div>
            </mat-list-item>
        </mat-list>
  
        <div class="btns_line">
            <button mat-raised-button (click)="goBack()">
                {{ "Back" | translate }}
            </button>
            <button class="ml-1" (click)="createType()" mat-raised-button color="primary">
                <mat-icon>add</mat-icon>
                {{ "Create new type" | translate }}
            </button>
        </div>
  
  
    </div>
  </div>