import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { AuthService } from 'src/app/shared/services/rest/auth.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { concatMap, debounceTime, last, map, switchMap, take, tap } from 'rxjs/operators';
import { forkJoin, of, ReplaySubject, Subscription } from 'rxjs';
import { MY_FORMATS } from 'src/app/components/workspace-settings/finances/statements/statements.component';
import { ScenariosService } from 'src/app/shared/services/rest/scenarios.service';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { EditApproveComponent } from '../edit-approve/edit-approve.component';
import { prioritys } from 'src/app/shared/consts/prioritys';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ParametersService } from 'src/app/shared/services/rest/parameters.service';
import { environment } from 'src/environments/environment';
import { TargetParametersComponent } from 'src/app/components/atTasksDialog/target-parameters/target-parameters.component';

@Component({
  selector: 'app-add-and-edit-task-template',
  templateUrl: './add-and-edit-task-template.component.html',
  styleUrls: ['./add-and-edit-task-template.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class AddAndEditTaskTemplateComponent extends BaseClass implements OnInit, OnDestroy {
  public values: any;
  public allValues: any;
  public paramDataSub: Subscription;
  public separatorKeysCodes: number[] = [ENTER, COMMA];
  public host: any = environment.host;
  public imgRoute: any = '';
  public user_id: number;
  public form: FormGroup;
  public dataForm: FormGroup;
  public jobForm: FormGroup;
  public company_id: any;
  public task_id: any;
  public operations: any;
  public automationType: any;
  public taskStatuses: any;
  public statuses: any;
  public employees: any;
  public numberRegEx = /\d*\.?\d{1,2}/;
  public isFormChange: boolean = false;
  public isSubmit: boolean = false;
  public submited: boolean = false;
  public switched: boolean = false;
  public prioritys = prioritys;

  
  public parameters: any;
  public parametersCtrl: FormControl = new FormControl();
  public parameters$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public templates: any;
  public templatesMoreControl: FormControl = new FormControl();
  public templates$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public cardTemplates: any;
  public cardTemplatesMoreControl: FormControl = new FormControl();
  public cardTemplates$: ReplaySubject<any> = new ReplaySubject<any>(1);
  
  public employeeMoreControl: FormControl = new FormControl();
  public employees$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public operationMoreControl: FormControl = new FormControl();
  public operations$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public groups: any
  public groupMoreControl: FormControl = new FormControl();
  public groups$: ReplaySubject<any> = new ReplaySubject<any>(1);

  
  public partners: any;
  public partnersControl: FormControl = new FormControl();
  public partners$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public profiles: any = [];
  public jobs:any = [];

  public pCliSwitch: boolean = false;
  public cliSwitch: boolean = false;

  public clients = [];
  public partnerClients = [];
  public clients_ids = [];
  public partnerClients_ids = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddAndEditTaskTemplateComponent>,
    private sm: StorageManagerService,
    private auth: AuthService,
    private companyService: CompanyService,
    private fb: FormBuilder,
    private taskService: TaskService,
    private dialog: MatDialog,
    private _adapter: DateAdapter<any>,
    private languageService: LanguageService,
    private bottomSheet: MatBottomSheet,
    private layoutService: LayoutService,
    private scenariosService: ScenariosService,
    private parametersService: ParametersService

  ) { super() }

  ngOnInit(): void {
    window.scroll(0,0);
    

    console.log("AddAndEditTaskTemplateComponent", this.data)

    this.company_id = this.data.company.id;
    this.task_id = 0;
    this.paramDataSub = this.parametersService.getParamsStream().pipe(
      concatMap(params => {
        return forkJoin([...params.map(oneParam => this.neededParametersData(oneParam))]).pipe(last(),map(x => params))
      }),
    ).subscribe(resp => console.log("-----getParamsStream-----",resp));
    this.getParameters()

    this.getCompany();
    this.getAllApiParameterValues()
    this.getImgRoute();
    this.getCsrf();

    this.attachSubscriptions(
      this.parametersCtrl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchParams(resp))
    )

    this.attachSubscriptions(
      this.employeeMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchEmoloyees(resp))
    )
    this.attachSubscriptions(
      this.operationMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchOperations(resp))
    )
    this.attachSubscriptions(
      this.groupMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchGroups(resp))
    )
    this.attachSubscriptions(
      this.partnersControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchPartners(resp))
    )

    this.form = this.fb.group({
      company_id: this.company_id,
      name: ['', Validators.required],
      template_data: this.fb.group({
        name: ['', Validators.required],
        status_id: '',
        group_id: '',
        create_automation_scenarios: [[]],
        is_random_avatar: true,
        create_parameter_values_to_task: [[]]
      })
    })

    this.dataForm = this.fb.group({
      parameter_id: ''
    })
    // create_task_operations

    this.attachSubscriptions(
      this.form.valueChanges.subscribe(() => this.isFormChange = true)
    )

    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        if (this.isFormChange) {
          this.layoutService.openBottomSheet(this.dialogRef);
        } else {
          this.close();
        }
      })
    )

    this.getTaskStatus();
    this.getOperations();
    this.getOperationsStatus();
    this.getEmployees();
    this.getPartners();
    this.getLangData();
    this.getGroupsCompany();
    
    this.getGroupProfiles();

    if (this.data.tmpl) {
      this.getTmpl();
    }
    this.getAutomationTemplates();
    this.getAutomationCardTemplates();
    this.attachSubscriptions(
      this.templatesMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchTemplates(resp))
    )
    this.attachSubscriptions(
      this.cardTemplatesMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchCardTemplates(resp))
    )
  }

  onSearchParams(resp) {
    if (!this.parameters) {
      return;
    }

    if (!resp) {
      this.parameters$.next(this.parameters.filter(z => !!z.id).slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.parameters$.next(
      this.parameters.filter(z => !!z.id && (z.name.toLowerCase().indexOf(resp) > -1))
    );
  }

  // getAutomationTemplates() {
  //   this.attachSubscriptions(
  //     this.scenariosService.getAutomationTemplates('1', {company_id: this.data.company.id}, '200').pipe(
  //       map(el => el.body)
  //     ).subscribe(resp => {
  //       console.log("getAutomationTemplates", resp);
  //       this.templates = resp;
  //       this.templates$.next(this.templates.slice());
  //     })
  //   )
  // }

  getAutomationCardTemplates() {
    this.attachSubscriptions(
      this.scenariosService.getAutomationTemplates('1', {company_id: this.data.company.id, is_task: 1}, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.scenariosService.getAutomationTemplates(x, {company_id: this.data.company.id, is_task: 1}, '200').pipe(map(u => u.body)))).pipe(
            last(),
            tap(values => {
              this.cardTemplates = [].concat(...values)
              console.log("getAutomationcardTemplates", this.cardTemplates)
              this.cardTemplates$.next(this.cardTemplates.slice());
            }),
          )
        }),
      ).subscribe(resp => {
        console.log("getAutomationTemplates sub", resp);
      })
    )
  }

  getAutomationTemplates() {
    this.attachSubscriptions(
      this.scenariosService.getAutomationTemplates('1', {company_id: this.data.company.id, is_task_operation: 1}, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }
          // Math.round(Math.random()*100000)

          return forkJoin(arr.map(x => this.scenariosService.getAutomationTemplates(x, {company_id: this.data.company.id, is_task_operation: 1}, '200').pipe(map(u => u.body)))).pipe(
            last(),
            tap(values => {
              this.templates = [].concat(...values)
              console.log("getAutomationTemplates", this.templates)
              if (this.data.tmpl && this.data.tmpl.template_data && this.data.tmpl.template_data.create_task_operations.length && this.data.tmpl.template_data.create_task_operations.length > 0) {
                this.data.tmpl.template_data.create_task_operations.forEach(job => {
                  if (job.create_automation_scenarios && job.create_automation_scenarios) {
                    job.create_automation_scenarios.forEach(tID => {
                      if (this.templates.filter(x => x.template_id == tID).length == 0) {
                        this.templates.push({
                          id: tID,
                          name: `Deleted template ID: ${tID}`
                        })
                      }
                    })
                  }
                })
              }
              this.templates$.next(this.templates.slice());
            }),
          )
        }),
      ).subscribe(resp => {
        console.log("getAutomationTemplates sub", resp);
      })
    )
  }

  includesCli(id) {
    return this.clients.filter(x => x.client_employee_id == id).length > 0;
  }
  
  setCli(e, id, val) {
    e.preventDefault();
    e.stopPropagation();
    this.clients.find(x => x.client_employee_id == id).is_principal = val
  }

  setCliPartn(e, id, val) {
    e.preventDefault();
    e.stopPropagation();
    this.partnerClients.find(x => x.client_partner_company_id == id).is_principal = val
  }

  getCli(id) {
    return this.clients.find(x => x.client_employee_id == id);
  }
  
  getCliPartn(id) {
    return this.partnerClients.find(x => x.client_partner_company_id == id);
  }

  includesCliPartn(id) {
    return this.partnerClients.filter(x => x.client_partner_company_id == id).length > 0;
  }

  toggleSelectClientChip(e) {
    console.log('toggleSelectClientChip', e)

    this.clients.forEach((obj, ind) => {
      if (!this.clients_ids.filter(id => obj.client_employee_id == id).length) {
        this.clients.splice(ind, 1)
      }
    });

    this.clients_ids.forEach((id, ind) => {
      if (!this.clients.filter(x => x.client_employee_id == id).length) {
        this.clients.push({
          client_employee_id: id,
          is_principal: 1
        })
      }
    });

    console.log(this.clients_ids);
    console.log(this.clients);
  }

  toggleSelectClientPartnerChip(e) {
    console.log('toggleSelectClientPartnerChip', e)

    this.partnerClients.forEach((obj, ind) => {
      if (!this.partnerClients_ids.filter(id => obj.client_partner_company_id == id).length) {
        this.partnerClients.splice(ind, 1)
      }
    });

    this.partnerClients_ids.forEach((id, ind) => {
      if (!this.partnerClients.filter(x => x.client_partner_company_id == id).length) {
        this.partnerClients.push({
          client_partner_company_id: id,
          is_principal: 1
        })
      }
    });

    console.log(this.partnerClients_ids);
    console.log(this.partnerClients);
  }

  toggleClientPartnerChip = (chip: any) => {
    console.log("toggleClientPartnerChip", chip)
    let id = !!chip.partner_company_id ? chip.partner_company_id : chip.value
    if (!!id) {
      if (this.partnerClients.filter(x => x.client_partner_company_id == id).length) {
        this.partnerClients.splice(this.partnerClients.indexOf(this.partnerClients.find(x => x.client_partner_company_id == id)), 1)
      } else {
        this.partnerClients.push({
          client_partner_company_id: id,
          is_principal: 1
        })
      }
    }
    
    this.partnerClients_ids = this.partnerClients.map(x => x.client_partner_company_id);
    // if (!chip.partner_company_id) {
    //   this.pCliSwitch = true;

    //   setTimeout(() => {
    //     this.pCliSwitch = false;
    //   }, 0)
    // }
  }

  toggleClientChip = (chip: any) => {
    console.log("toggleClientChip", chip)
    let id = !!chip.id ? chip.id : null;
    if (!!id) {
      if (this.clients.filter(x => x.client_employee_id == id).length) {
        this.clients.splice(this.clients.indexOf(this.clients.find(x => x.client_employee_id == id)), 1)
      } else {
        this.clients.push({
          client_employee_id: id,
          is_principal: 1
        })
      }
    }

    this.clients_ids = this.clients.map(x => x.client_employee_id);

    // if (!chip.id) {
    //   this.cliSwitch = true;

    //   setTimeout(() => {
    //     this.cliSwitch = false;
    //   }, 0)
    // }
  }

  onSearchCardTemplates(resp) {
    if (!this.cardTemplates) {
      return;
    }

    if (!resp) {
      this.cardTemplates$.next(this.cardTemplates.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.cardTemplates$.next(
      this.cardTemplates.filter(z => z.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  onSearchTemplates(resp) {
    if (!this.templates) {
      return;
    }

    if (!resp) {
      this.templates$.next(this.templates.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.templates$.next(
      this.templates.filter(z => z.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  onSearchPartners(resp) {
    if (!this.partners) {
      return;
    }

    if (!resp) {
      this.partners$.next(this.partners.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.partners$.next(
      this.partners.filter(z => z.partnerCompany.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  getPartners() {
    this.attachSubscriptions(
      this.companyService.getPartners({company_id: this.data.company.id}).subscribe(resp => {
        this.partners = resp.filter(x => x.partner_company_id != 0);
        this.partners$.next(this.partners.slice())
        console.log("getPartners", this.partners)
      })
    )
  }

  getPartnerById(id) {
    // console.log("partners", id);
    // console.log("partners", this.partners.find(el => el.partner_company_id == id));
    if (!this.partners || this.partners.filter(el => el.partner_company_id == id).length == 0) {
      return false;
    }
    return this.partners.find(el => el.partner_company_id == id)
  }  

  getTaskStatus() {
    this.attachSubscriptions(
      this.taskService.getTaskStatuses(this.company_id).subscribe(resp => {
        console.log("getTaskStatuses", resp)
        this.taskStatuses = resp;
      })
    )
  }

  getGroupProfiles() {
    this.attachSubscriptions(
      this.companyService.getProfiles(this.company_id).pipe(
        map(x => x.body),
        tap(arr => {
          if (this.data.tmpl) {
            this.data.tmpl.template_data.create_task_operations.forEach(work => {

              work.profiles = JSON.parse(JSON.stringify(arr))

              work.profiles.forEach(item => {
                if (work.channel_to_employee && !!work.channel_to_employee.find(b => b.channel_id == item.id) && !!work.channel_to_employee.find(b => b.channel_id == item.id).employees_ids.length) {
                  item.employees_ids =  work.channel_to_employee.find(b => b.channel_id == item.id).employees_ids
                } else {
                  item.employees_ids = []
                }
  
                if (work.channel_to_parameter_value && !!work.channel_to_parameter_value.find(b => b.channel_id == item.id) && !!work.channel_to_parameter_value.find(b => b.channel_id == item.id).is_common_parameter_value) {
                  let val = work.channel_to_parameter_value.find(b => b.channel_id == item.id)
                  item.is_common_parameter_value = !!val ? true : false
                } else {
                  item.is_common_parameter_value = 0
                }
              })
            })
          }
        })
      ).subscribe(resp => {
        this.profiles = resp;
        console.log("getProfiles ------- ", this.profiles)
      })
    )
  }

  getParameters() {
    this.attachSubscriptions(
      this.parametersService.getApiParameters(this.data.company.id).pipe(
        tap(res => {
          this.parametersService.paramsData$.next(res)
          res.forEach(n => {
            n.search = new FormControl('');
            n.search.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearch(resp, n))
          })
        })
      ).subscribe(resp => {
        this.parameters = resp;
        console.log("this.parameters", this.parameters)
        if (this.data.tmpl && this.jobs && this.jobs.length) {
          this.jobs.forEach(job => {
            if (!job.parameters) {
              job.parameters = JSON.parse(JSON.stringify(this.parameters, this.getCircularReplacer()))
              job.parameters.unshift({
                company_id: this.data.company.id,
                created_at: 0,
                id: 0,
                is_new_value: 0,
                name: "All parameters",
                original_id: 0,
              })
              job.parameters.forEach(u => {
                u.activeValues = []
              });
              job.create_parameter_values_to_task.forEach(obj => {
                if (this.allValues && this.allValues.find(u => u.id == obj.parameter_value_id && u.parameter_id == obj.parameter_id)) {
                  let value = JSON.parse(JSON.stringify(this.allValues.find(u => u.id == obj.parameter_value_id && u.parameter_id == obj.parameter_id), this.getCircularReplacer()));
                  value.is_primary = !!obj.is_primary ? 1 : 0;
                  job.parameters[0].activeValues.push(value)
                }
              });
            }
          });
          // if(!element.hasOwnProperty("create_parameter_values_to_task")) {
          //   element.create_parameter_values_to_task = [];
          //   if (!!this.parameters) {
          //     element.parameters = JSON.parse(JSON.stringify(this.parameters, this.getCircularReplacer()))
          //     element.parameters.forEach(u => {
          //       u.activeValues = []
          //     });
          //   } else {
          //     element.parameters = undefined; 
          //   }
          // } else {
          //   if (!!this.parameters && !!this.allValues) {
          //     element.parameters = JSON.parse(JSON.stringify(this.parameters, this.getCircularReplacer()))
          //     element.parameters[0].activeValues = [];
          //     element.create_parameter_values_to_task.forEach(obj => {
          //       if (this.allValues && this.allValues.find(u => u.id == obj.parameter_value_id && u.parameter_id == obj.parameter_id)) {
          //         let value = JSON.parse(JSON.stringify(this.allValues.find(u => u.id == obj.parameter_value_id && u.parameter_id == obj.parameter_id), this.getCircularReplacer()));
          //         value.is_primary = !!obj.is_primary ? 1 : 0;
          //         element.parameters[0].activeValues.push(value)
          //       }
          //     });
          //   } else {
          //     element.parameters = undefined; 
          //   }
          // }
        }
        this.parameters$.next(this.parameters.slice())
      })
    )
  }

  withSearch(searchText: string, val) {
    if (searchText == '' || typeof searchText != 'string' || val.value.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ) {
      return true
    } else {
      return false
    }
  }
  
  noOption(arr, id) {
    let _arr; 
    if (arr) {
      _arr = arr
    } else {
      _arr = []
    }
    return _arr.filter(x => x.id == id).length > 0 ? false : true;
  }

  noOptionJob(arr, id) {
    let _arr; 
    if (arr) {
      _arr = arr
    } else {
      _arr = []
    }
    return _arr.filter(x => x.parameter_value_id == id).length > 0 ? false : true;
  }

  onScroll(param) {
    console.log(param.page, param.pagination.pageCount)
    if (param.pagination.pageCount >= param.page) {
      console.log("SCROLL !")
      this.parametersService.getApiParameterValues(this.data.company.id, param.id, "20", param.page, !!param.search.value ? param.search.value : '').pipe(take(1), map((x) => x.body)).subscribe(resp => {
        param.page++;
        param.values.push(...resp)
      })
    }
  }

  onSearch(searchText, param) {
    console.log(searchText, param)
    param.page = 1;
    this.parametersService.getApiParameterValues(this.data.company.id, param.id, "20", !!param.page ? param.page : "1",  !!param.search.value ? param.search.value : '').subscribe(resp => {
      param.pagination = {
        'pageCount': resp.headers.get('x-pagination-page-count'),
        'perPage': resp.headers.get('x-pagination-per-page'),
        'totalCount': resp.headers.get('x-pagination-total-count'),
        'currentPage': resp.headers.get('x-pagination-current-page'),
      }
      param.page = 2
      param.values = resp.body;
    })
  }

  neededParametersData(param) {
    let arr = []

    arr.push(
      this.parametersService.getApiParameterValues(this.data.company.id, param.id, "20", !!param.page ? param.page : "1").pipe(
        tap(x => {
          param.pagination = {
            'pageCount': x.headers.get('x-pagination-page-count'),
            'perPage': x.headers.get('x-pagination-per-page'),
            'totalCount': x.headers.get('x-pagination-total-count'),
            'currentPage': x.headers.get('x-pagination-current-page'),
          }
          param.page = 2
          param.values = x.body;
          param.taskActiveValues = []
          if (x.body.length > 0) {
            param.hasValues = true;
          }
          // param.activeValues = this.data.check[this.data.only ? 'toParameterValues' : 'exceptParameterValues'].filter(x => x.parameterValue && x.parameterValue.parameter_id == param.id)
          if (this.data.tmpl && this.data.tmpl.template_data.create_parameter_values_to_task && this.data.tmpl.template_data.create_parameter_values_to_task.length > 0 && this.data.tmpl.template_data.create_parameter_values_to_task.filter(b => b.parameter_id == param.id).length > 0) {
           let activeValues = this.data.tmpl.template_data.create_parameter_values_to_task.filter(b => b.parameter_id == param.id)
            activeValues.forEach(val => {
              if (this.allValues && this.allValues.find(x => x.id == val.parameter_value_id)) {
                param.taskActiveValues.push(this.allValues.find(x => x.id == val.parameter_value_id))
              }
            })
          }
        })
      )
    )

    return forkJoin(arr)
  }

  onRemoveJobParameter(id, param, job, i) {
    // console.log(job.create_parameter_values_to_task)
    // console.log(job.create_parameter_values_to_task.indexOf(job.create_parameter_values_to_task.find(x => x.parameter_id == param.id && x.parameter_value_id == id)))
    // console.log({parameter_id: param.id, parameter_value_id: id})
    job.create_parameter_values_to_task.splice(job.create_parameter_values_to_task.indexOf(job.create_parameter_values_to_task.find(x => x.parameter_id == param.id && x.parameter_value_id == id)), 1)
  }

  selectedJob(e, job, param, k) {
    if ( this.allValues.filter(x => x.id == e.option.value).length > 0 ) {
      job.create_parameter_values_to_task.push({
        parameter_id: param.id,
        parameter_value_id: e.option.value
      })
      param.search.patchValue("")
      if (document.getElementById(`job_${k}_param_input_${param.id}`)) {
        (document.getElementById(`job_${k}_param_input_${param.id}`) as HTMLInputElement).value = ''
      }
    }
  }

  togglePrimary(tag) {
    tag.is_primary = !tag.is_primary;
  }

  openTargetValues(job?) {
    let initData:any = {
      company: this.data.company,
      auto: true,
      parameters: !!job ? job.parameters : this.parameters
    }

    const dialogRef = this.dialog.open(TargetParametersComponent, {
      backdropClass: ['parameters_modal_backdrop'],
      panelClass: ['without_paddings_modal', 'parameters_modal'],
      data: initData
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result) {
          if (result.event == 'save') {
            if (!!job) {
              job.parameters = result.data
            } else {
              this.parameters = result.data
            }
          }
        }
      })
    )
  }

  forArray(arr, id) {
    return arr.filter(x => x.parameter_id == id)
  }

  onRemove(id, param, i) {
    param.taskActiveValues.splice(i, 1)
  }

  selectedTask(e, param) {
    if ( this.allValues.filter(x => x.id == e.option.value).length > 0 ) {
      param.taskActiveValues.push(this.allValues.find(x => x.id == e.option.value))
      param.search.patchValue("")
      if (document.getElementById(`param_input_${param.id}`)) {
        (document.getElementById(`param_input_${param.id}`) as HTMLInputElement).value = ''
      }
    }
  }

  deleteRole(job, key) {
    job[key] = false;
  }

  add(e) {
    console.log("add", e)
  }
  
  private removeFirst<T>(array: T[], toRemove: T): void {
    const index = array.indexOf(toRemove);
    if (index !== -1) {
      array.splice(index, 1);
    }
  }

  getAllApiParameterValues() {
    this.attachSubscriptions(
      this.parametersService.getAllValues('1', this.data.company.id, null, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.parametersService.getAllValues(x, this.data.company.id).pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        this.allValues = [].concat(...res);

        if (this.data.tmpl && this.jobs && this.jobs.length) {
          this.jobs.forEach(job => {
            if (!!job.parameters && job.parameters[0] && job.parameters[0].activeValues && job.parameters[0].activeValues.length == 0) {
              job.create_parameter_values_to_task.forEach(obj => {
                if (this.allValues.find(u => u.id == obj.parameter_value_id && u.parameter_id == obj.parameter_id)) {
                  let value = JSON.parse(JSON.stringify(this.allValues.find(u => u.id == obj.parameter_value_id && u.parameter_id == obj.parameter_id), this.getCircularReplacer()));
                  value.is_primary = !!obj.is_primary ? 1 : 0;
                  job.parameters[0].activeValues.push(value)
                }
              });
            }
          })
        }
      })
    )
  }


  getValueById(id) {
    if (!this.allValues || this.allValues.length == 0) {
      return null
    }
    return this.allValues.find(x => x.id == id)
  }

  selectType(status) {
    this.form.get("template_data").patchValue({
      status_id: !!status.id ? status.id : status.value
    })
  }

  selectGroup(group) {
    this.form.get("template_data").patchValue({
      group_id: !!group.id ? group.id : group.value
    })
  }

  deleteJob(i) {
    this.jobs.splice(i,1);
  }

  onSearchGroups(resp) {
    if (!this.groups) {
      return;
    }

    if (!resp) {
      this.groups$.next(this.groups.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.groups$.next(
      this.groups.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  toggleCardChipTemplate = (chip: any) => {
    if (!!this.form.value.template_data.create_automation_scenarios && this.form.value.template_data.create_automation_scenarios.includes(chip.id)) {
      let val = [...this.form.value.template_data.create_automation_scenarios]
      val.splice(this.form.value.template_data.create_automation_scenarios.indexOf(chip.id), 1)
      this.form.get('template_data').patchValue({
        create_automation_scenarios: val
      })
    } else {
      if (!!this.form.value.template_data.create_automation_scenarios) {
        let val = [...this.form.value.template_data.create_automation_scenarios]
        val.push(chip.id)
        this.form.get('template_data').patchValue({
          create_automation_scenarios: val
        })
      } else {
        this.form.get('template_data').patchValue({
          create_automation_scenarios: [chip.id]
        })
      }
    }
  }

  toggleChipTemplate = (chip: any, job) => {
    if (!!job.create_automation_scenarios && job.create_automation_scenarios.includes(chip.id)) {
      let val = [...job.create_automation_scenarios]
      val.splice(job.create_automation_scenarios.indexOf(chip.id), 1)
      job.create_automation_scenarios = val
    } else {
      if (!!job.create_automation_scenarios) {
        let val = [...job.create_automation_scenarios]
        val.push(chip.id)
        job.create_automation_scenarios = val
      } else {
        job.create_automation_scenarios = [chip.id]
      }
    }

    console.log("#### JOB",job)
  }

  onRemoveJobTag(job, tag, ind) {
    job.parameters[0].activeValues.splice(ind, 1)
  }

  addJob() {
    let jobData:any = {
      operation_id: 1,
      status_id: 1,
      priority: 0,
      name: '',
      comment: '',
      is_automation_employee_position_task_employee: false,
      is_automation_employee_position_task_employee_assistant: false,
      is_automation_employee_position_task_client_principal: false,
      is_automation_employee_position_task_client: false,
      is_automation_employee_position_task_partner: false,
      is_automation_employee_position_task_partner_assistant: false,
      is_automation_employee_position_task_partner_client_principal: false,
      is_automation_employee_position_task_partner_client: false,
      add_exec: '',
      add_p_exec: '',
      add_c_exec: '',
      add_c_p_exec: '',
      create_task_employees: [],
      create_task_clients: [],
      create_task_partners: [],
      create_automation_scenarios: [],
      create_parameter_values_to_task: [],
      is_create_discussions: 0,
      is_skip_task_channel_with_url: 0,
      parameters: JSON.parse(JSON.stringify(this.parameters, this.getCircularReplacer()))
    }

    jobData.parameters.unshift({
      company_id: this.data.company.id,
      created_at: 0,
      id: 0,
      is_new_value: 0,
      name: "All parameters",
      original_id: 0,
    })

    jobData.parameters.forEach(element => {
      element.activeValues = []
    });

    jobData.profiles = JSON.parse(JSON.stringify(this.profiles));

    jobData.profiles.map(item => {
      item.employees_ids = []
      item.is_common_parameter_value = 0
    })

    if (this.employees.length > 0) {
      jobData.newExecutor = {
        employee_id: '',
        is_manager: 1
      }
    }
    if (this.employees.length > 0) {
      jobData.newClientExec = {
        client_employee_id: '',
        is_principal: 1
      }
    }
    if (this.partners.length > 0) {
      jobData.newPartner = {
        partner_company_id: '',
        is_manager: 1
      }
    }
    if (this.partners.length > 0) {
      jobData.newClientPartn = {
        client_partner_company_id: '',
        is_principal: 1
      }
    }

    this.jobs.push(jobData)
  }

  checkIfDisabled(job, execId) {
    if (job.create_task_employees.filter(x => x.employee_id == execId).length > 0) {
      return true
    } else {
      return false
    }
  }

  checkCliIfDisabled(job, execId) {
    if (job.create_task_clients && job.create_task_clients.filter(x => x.client_employee_id == execId).length > 0) {
      return true
    } else {
      return false
    }
  }

  logJob(job) {
    console.log("logJob", job)
  }

  checkIfPartnerDisabled(job, partner_company_id) {
    if (job.create_task_partners.filter(x => x.partner_company_id == partner_company_id).length > 0) {
      return true
    } else {
      return false
    }
  }

  checkIfCliPartnerDisabled(job, client_partner_company_id) {
    if (job.create_task_clients && job.create_task_clients.filter(x => x.client_partner_company_id == client_partner_company_id).length > 0) {
      return true
    } else {
      return false
    }
  }

  onChange(e, job, key) {
    console.log(job);
    console.log(job.key);
  }

  addPartner(job) {
    if (!!job.add_p_exec) {
      job[job.add_p_exec] = true;
      job.add_p_exec = '';
      return
    }
    if (!job.newPartner.partner_company_id) {
      this.layoutService.showSnackBar({name: ''}, marker("Choose a partner"), SnackBarItem)
      return;
    }
    job.create_task_partners.push(JSON.parse(JSON.stringify(job.newPartner)));
    job.newPartner = {
      partner_company_id: '',
      is_manager: 1
    }
  }

  deletePartner(job, ind) {
    job.create_task_partners.splice(ind,1);
  }

  deleteClient(job, ind) {
    job.create_task_clients.splice(ind,1);
  }

  toggleVal(job, key, val) {
    if (key == 'add_exec') {
      job.newExecutor = {
        employee_id: '',
        is_manager: 1
      }
    }
    if (key == 'add_c_exec') {
      job.newClientExec = {
        client_employee_id: '',
        is_principal: 1
      }
    }
    if (key == 'add_p_exec') {
      job.newPartner = {
        partner_company_id: '',
        is_manager: 1
      }
    }
    if (key == 'add_c_p_exec') {
      job.newClientPartn = {
        client_partner_company_id: '',
        is_principal: 1
      }
    }
    job[key] = '';
    setTimeout(() => {
      job[key] = val;
    }, 0)
  }

  addExecutor(job) {
    if (!!job.add_exec) {
      job[job.add_exec] = true;
      job.add_exec = '';
      return
    }
    if (!job.newExecutor.employee_id) {
      this.layoutService.showSnackBar({name: ''}, marker("Choose an executor"), SnackBarItem)
      return;
    }
    job.create_task_employees.push(JSON.parse(JSON.stringify(job.newExecutor)));
    job.newExecutor = {
      employee_id: '',
      is_manager: 1
    }
  }

  addClient(job, key) {
    if (key == 'client_partner_company_id') {
      if (!!job.add_c_p_exec) {
        job[job.add_c_p_exec] = true;
        job.add_c_p_exec = '';
        return
      }
      if (!job.newClientPartn.client_partner_company_id) {
        this.layoutService.showSnackBar({name: ''}, marker("Choose a partner client"), SnackBarItem)
        return;
      }
      job.create_task_clients.push(JSON.parse(JSON.stringify(job.newClientPartn)));
      job.newClientPartn = {
        client_partner_company_id: '',
        is_principal: 1
      }
    } else {
      if (!!job.add_c_exec) {
        job[job.add_c_exec] = true;
        job.add_c_exec = '';
        return
      }
      if (!job.newClientExec.client_employee_id) {
        this.layoutService.showSnackBar({name: ''}, marker("Choose a client"), SnackBarItem)
        return;
      }
      job.create_task_clients.push(JSON.parse(JSON.stringify(job.newClientExec)));
      job.newClientExec = {
        client_employee_id: '',
        is_principal: 1
      }
    }
  }

  deleteExecutor(job, ind) {
    job.create_task_employees.splice(ind,1);
  }


  getGroupsCompany() {
    this.attachSubscriptions(
      this.companyService.getInfiniteGroupsCompany(this.company_id, '1', '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.companyService.getInfiniteGroupsCompany(this.company_id, x).pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        this.groups = [].concat(...res);
        this.groups$.next(this.groups.slice());
      })
    )
  }


  toggleEmpl(empl: any, profile) {
    console.log("toggleEmpl", empl, profile)
    if (!!profile.employees_ids && profile.employees_ids.includes(empl.id)) {
      let val = [...profile.employees_ids]
      val.splice(profile.employees_ids.indexOf(empl.id), 1)
      profile.employees_ids = val;
    } else {
      if (!!profile.employees_ids) {
        let val = [...profile.employees_ids]
        val.push(empl.id)
        profile.employees_ids = val;
      } else {
        profile.employees_ids = [empl.id]
      }
    }

  }

  changeCreateDiscussionFlag(e, job) {
    console.log(e);
    if (e.checked) {
      job.discussion_name_template = "Publish to {channel_name} ({platform_name})"
    } else {
      delete job.discussion_name_template
    }

    console.log("changeCreateDiscussionFlag", job)
  }

  getTmpl() {
    console.log("getTMPL", this.data.tmpl)
    this.form.patchValue({
      name: this.data.tmpl.name
    })

    if (this.data.tmpl.template_data) {
      this.form.patchValue({
        template_data: {
          name: this.data.tmpl.template_data.name,
          group_id: this.data.tmpl.template_data.group_id,
          is_random_avatar: !!this.data.tmpl.template_data.is_random_avatar,
          status_id: this.data.tmpl.template_data.status_id
        }
      })

      if (!this.data.tmpl.template_data.hasOwnProperty("create_automation_scenarios") || !this.data.tmpl.template_data.create_automation_scenarios || !this.data.tmpl.template_data.create_automation_scenarios.length) {
        this.form.get('template_data').patchValue({
          create_automation_scenarios: []
        })
      } else {
        this.form.get('template_data').patchValue({
          create_automation_scenarios: this.data.tmpl.template_data.create_automation_scenarios.map(u => u.template_id)
        })
      }

      if (this.data.tmpl.template_data.data && this.data.tmpl.template_data.data.length && this.data.tmpl.template_data.data[0].type == 'GroupBy') {
        this.dataForm = this.fb.group({
          parameter_id: this.data.tmpl.template_data.data[0].parameter_id
        })
      }

      if (this.data.tmpl.template_data.create_task_clients && this.data.tmpl.template_data.create_task_clients.length) {
        this.data.tmpl.template_data.create_task_clients.forEach(obj => {
          if (!!obj.hasOwnProperty('client_employee_id')) {
            this.clients.push(obj);
          } else {
            this.partnerClients.push(obj);
          }
        })
        this.clients_ids = this.clients.map(x => x.client_employee_id);
        this.partnerClients_ids = this.partnerClients.map(x => x.client_partner_company_id);
      }

      if (this.data.tmpl.template_data.create_task_operations.length && this.data.tmpl.template_data.create_task_operations.length > 0) {
        this.data.tmpl.template_data.create_task_operations.forEach(element => {
          if(!element.hasOwnProperty("create_parameter_values_to_task")) {
            element.create_parameter_values_to_task = [];
            if (!!this.parameters) {
              element.parameters = JSON.parse(JSON.stringify(this.parameters, this.getCircularReplacer()))
              element.parameters.unshift({
                company_id: this.data.company.id,
                created_at: 0,
                id: 0,
                is_new_value: 0,
                name: "All parameters",
                original_id: 0,
              })
              element.parameters.forEach(u => {
                u.activeValues = []
              });
            } else {
              element.parameters = undefined; 
            }
          } else {
            if (!!this.parameters && !!this.allValues) {
              element.parameters = JSON.parse(JSON.stringify(this.parameters, this.getCircularReplacer()))
              element.parameters.unshift({
                company_id: this.data.company.id,
                created_at: 0,
                id: 0,
                is_new_value: 0,
                name: "All parameters",
                original_id: 0,
              })
              element.parameters[0].activeValues = [];
              element.create_parameter_values_to_task.forEach(obj => {
                if (this.allValues && this.allValues.find(u => u.id == obj.parameter_value_id && u.parameter_id == obj.parameter_id)) {
                  let value = JSON.parse(JSON.stringify(this.allValues.find(u => u.id == obj.parameter_value_id && u.parameter_id == obj.parameter_id), this.getCircularReplacer()));
                  value.is_primary = !!obj.is_primary ? 1 : 0;
                  element.parameters[0].activeValues.push(value)
                }
              });
            } else {
              element.parameters = undefined; 
            }
          }
          if(!element.hasOwnProperty("is_create_discussions")) {
            element.is_create_discussions = 0
          }
          if(!element.hasOwnProperty("is_skip_task_channel_with_url")) {
            element.is_skip_task_channel_with_url = 0
          }
          if(!element.hasOwnProperty("create_automation_scenarios") || !element.create_automation_scenarios || !element.create_automation_scenarios.length) {
            element.create_automation_scenarios = []
          } else {
            element.create_automation_scenarios = element.create_automation_scenarios.map(u => u.template_id)
          }
          if (!!element.is_create_discussions) {
            element.discussion_name_template =  element.discussion_name_template || "Publish to {channel_name} ({platform_name})"
          }
          if (!element.create_task_partners) {
            element.create_task_partners = []
          }

          element.add_exec = '';
          element.add_p_exec = '';
          element.add_c_exec = '';
          element.add_c_p_exec = '';

          element.is_automation_employee_position_task_employee = !!element.is_automation_employee_position_task_employee;
          element.is_automation_employee_position_task_employee_assistant = !!element.is_automation_employee_position_task_employee_assistant;
          element.is_automation_employee_position_task_client_principal = !!element.is_automation_employee_position_task_client_principal;
          element.is_automation_employee_position_task_client = !!element.is_automation_employee_position_task_client;
          element.is_automation_employee_position_task_partner = !!element.is_automation_employee_position_task_partner;
          element.is_automation_employee_position_task_partner_assistant = !!element.is_automation_employee_position_task_partner_assistant;
          element.is_automation_employee_position_task_partner_client_principal = !!element.is_automation_employee_position_task_partner_client_principal;
          element.is_automation_employee_position_task_partner_client = !!element.is_automation_employee_position_task_partner_client;
        });

        this.jobs = this.data.tmpl.template_data.create_task_operations;
        this.jobs.map(b => {
          if (!b.hasOwnProperty('create_task_clients')) {
            b.create_task_clients = [];
          }
          b.newExecutor = {
            employee_id: '',
            is_manager: 1
          }
          b.newPartner = {
            partner_company_id: '',
            is_manager: 1
          }
          b.newClientExec = {
            client_employee_id: '',
            is_principal: 1
          }
          b.newClientPartn = {
            client_partner_company_id: '',
            is_manager: 1
          }
        })
      }
    }
  }

  log() {
    console.log(this.jobs)
    console.log(this.form.value)
    console.log(this.clients)
    console.log(this.clients_ids)
    console.log(this.partnerClients)
    console.log(this.partnerClients_ids)
  }

  onSearchEmoloyees(resp) {
    if (!this.employees) {
      return;
    }

    if (!resp) {
      this.employees$.next(this.employees.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.employees$.next(
      this.employees.filter(z => z.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  onSearchOperations(resp) {
    if (!this.operations) {
      return;
    }

    if (!resp) {
      this.operations$.next(this.operations.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.operations$.next(
      this.operations.filter(z => (z.name.toLowerCase().indexOf(resp) > -1))
    );
  }

  getEmployeeById(id) {
    return !!this.employees && this.employees.find(x => x.id == id)
  }

  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData().subscribe(resp => {
        this._adapter.setLocale(resp.active);
      })
    )
  }
  
  getCompany() {
    this.attachSubscriptions(
      this.companyService.getCompany(this.data.company.id).subscribe(resp => {
        this.companyService.company$.next(resp[0]);
      })
    )
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  getCircularReplacer() {
    const ancestors:any = [];
    return function (key, value) {
      if (typeof value !== "object" || value === null) {
        return value;
      }
      // `this` is the object that value is contained in,
      // i.e., its direct parent.
      while (ancestors.length > 0 && ancestors.at(-1) !== this) {
        ancestors.pop();
      }
      if (ancestors.includes(value)) {
        return "[Circular]";
      }
      ancestors.push(value);
      return value;
    };
  }

  onPartnerImgError(event){
    event.target.src = this.imgRoute+'/assets/img/partner.png'
  }
  
  getCsrf() {
    this.attachSubscriptions(
      this.auth.$userToken.subscribe(resp => {
        this.user_id = resp.user_id;
        this.sm.localStorageSetItem("csrf_param", resp.csrf_param)
        this.sm.localStorageSetItem("csrf_token", resp.csrf_token)
      })
    )
  }
  
  getEmployees() {
    this.attachSubscriptions(
      this.taskService.getEmployeesDyn('1', this.data.company.id, null, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }
  
          return forkJoin(arr.map(x => this.taskService.getEmployeesDyn(x, this.data.company.id, null, '200').pipe(map(u => u.body)))).pipe(
            last(),
            tap(values => {
              let conValues = [].concat(...values)
              this.employees = conValues;
              this.employees$.next(this.employees.slice())
            }),
          )
        }),
      ).subscribe(resp => {
        console.log("getEmployees resp", resp);
        console.log("getEmployees", this.employees);
      })
    )
  }

  getOperations() {
    this.attachSubscriptions(
      this.taskService.getOperations(this.data.company.id).subscribe(resp => {
        this.operations = resp;
        this.operations$.next(this.operations.slice())
      })
    )
  }

  getOperationsStatus() {
    this.attachSubscriptions(
      this.taskService.getOperationsStatus().subscribe(resp => {
        this.statuses = resp.slice();
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }


  // {
  //   "data": [
  //     {
  //       "order": [
  //         117,
  //         120,
  //         119,
  //         115,
  //         116,
  //         121,
  //         118
  //       ],
  //       "parameter_id": 51,
  //       "parameter_value_id": "",
  //       "type": "GroupBy"
  //     }
  //   ]
  // }
  // data: [{
  //   type: "GroupBy",
  //   parameter_id: this.jobsTabForm.value.parameter_id,
  //   parameter_value_id: this.jobsTabForm.value.parameter_value_id,
  //   order: this.tabs.map(x => x.id)
  // }]
  onSubmit() {
    if (!this.form.valid) {
      this.submited = true;
      this.layoutService.showSnackBar({name: ''}, marker("You need to fill in all required fields"), SnackBarItem)
      return
    }
    this.submited = false;
    this.isSubmit = true;
    let formData = {...this.form.value}

    formData.template_data.is_random_avatar = !!formData.template_data.is_random_avatar ? 1 : 0
    formData.template_data.create_parameter_values_to_task = [];

    this.parameters.filter(x => x.taskActiveValues.length > 0).forEach(param => {
      param.taskActiveValues.forEach(el => {
        formData.template_data.create_parameter_values_to_task.push({
          parameter_id : param.id,
          parameter_value_id : el.id
        })
      })
    });

    if (!!this.dataForm.value.parameter_id) {
      let orders = [];
      if (this.parameters.find(x => x.id == this.dataForm.value.parameter_id) && this.parameters.find(x => x.id == this.dataForm.value.parameter_id).values) {
        orders = this.parameters.find(x => x.id == this.dataForm.value.parameter_id).values.map(u => u.id)
      }
      formData.template_data.data = [{
        type: "GroupBy",
        parameter_id: this.dataForm.value.parameter_id,
        parameter_value_id: '',
        order: orders
      }]
    }

    let allClients = [...this.clients, ...this.partnerClients]

    console.log("clients", this.clients)
    console.log("partnerClients", this.partnerClients)
    console.log("allClients", allClients)
    formData.template_data.create_task_clients = allClients;

    let scenarios = formData.template_data.create_automation_scenarios.slice()
    delete formData.template_data.create_automation_scenarios;
    formData.template_data.create_automation_scenarios = [];
    scenarios.forEach((id) => {
      formData.template_data.create_automation_scenarios.push({template_id: id})
    })

    this.jobs.forEach(x => {
      delete x.newExecutor;
      delete x.newPartner;
      delete x.newClientExec;
      delete x.newClientPartn;

      delete x.add_exec;
      delete x.add_p_exec;
      delete x.add_c_exec;
      delete x.add_c_p_exec;

      x.is_automation_employee_position_task_employee = !!x.is_automation_employee_position_task_employee ? 1 : 0;
      x.is_automation_employee_position_task_employee_assistant = !!x.is_automation_employee_position_task_employee_assistant ? 1 : 0;
      x.is_automation_employee_position_task_client_principal = !!x.is_automation_employee_position_task_client_principal ? 1 : 0;
      x.is_automation_employee_position_task_client = !!x.is_automation_employee_position_task_client ? 1 : 0;
      x.is_automation_employee_position_task_partner = !!x.is_automation_employee_position_task_partner ? 1 : 0;
      x.is_automation_employee_position_task_partner_assistant = !!x.is_automation_employee_position_task_partner_assistant ? 1 : 0;
      x.is_automation_employee_position_task_partner_client_principal = !!x.is_automation_employee_position_task_partner_client_principal ? 1 : 0;
      x.is_automation_employee_position_task_partner_client = !!x.is_automation_employee_position_task_partner_client ? 1 : 0;

      x.is_create_discussions = !!x.is_create_discussions ? 1 : 0;
      x.is_skip_task_channel_with_url = !!x.is_skip_task_channel_with_url ? 1 : 0;
      x.create_parameter_values_to_task = [];
      x.parameters[0].activeValues.forEach(val => {
        x.create_parameter_values_to_task.push({
          parameter_id: val.parameter_id,
          parameter_value_id: val.id,
          is_primary: !!val.is_primary ? 1 : 0
        })
      })

      delete x.parameters;
     

      let scenarios = x.create_automation_scenarios.slice()
      delete x.create_automation_scenarios;
      x.create_automation_scenarios = [];
      scenarios.forEach((id) => {
        x.create_automation_scenarios.push({template_id: id})
      })
      x.channel_to_parameter_value = [];
      x.channel_to_employee = [];
      if (!!x.is_create_discussions) {        
        x.profiles.forEach(y => {
          x.channel_to_employee.push({
            channel_id: y.id,
            employees_ids: y.employees_ids
          })
          x.channel_to_parameter_value.push({
            channel_id: y.id,
            is_common_parameter_value: y.is_common_parameter_value
          })
          x.is_skip_task_channel_with_url = !!x.is_skip_task_channel_with_url ? 1 : 0;
        });
      }

      delete x.profiles
    })

    formData.template_data.create_task_operations = this.jobs.slice()
    console.log("formData DADADADADDAD", formData)

    if (!this.data.tmpl) {
      this.attachSubscriptions(
        this.scenariosService.addTaskTemplate(formData).subscribe(resp => {
          console.log(resp);
          this.close()
        })
      )      
    } else {
      this.attachSubscriptions(
        this.scenariosService.editTaskTemplate(this.data.tmpl.id, formData).subscribe(resp => {
          console.log(resp);
          this.close()
        })
      )
    }
  }

  ngOnDestroy(): void {
    if (this.paramDataSub) {
      this.paramDataSub.unsubscribe()
    }
    this.clearSubscriptions()
  }
}
