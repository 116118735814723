import { AfterViewInit, Component, Inject, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { AuthService } from 'src/app/shared/services/rest/auth.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { mimeTypes } from 'mime-wrapper';

import * as moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/en-gb';
import 'moment/locale/uk';
import 'moment/locale/ru';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { BaseClass } from 'src/app/shared/models/base-class';
import { TasksHaveSameUrlComponent } from 'src/app/shared/global_components/tasks-have-same-url/tasks-have-same-url.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { catchError, debounceTime, distinctUntilChanged, filter, map, retry, switchMap, take, tap } from 'rxjs/operators';
import { fromEvent, ReplaySubject, of, throwError, forkJoin, Subscription } from 'rxjs';
import { MatSelect } from '@angular/material/select';
import { GlobalDataService } from 'src/app/shared/services/common/global-data.service';
import { NgxMatDateAdapter } from '@angular-material-components/datetime-picker';
import { SameUrlTasksComponent } from 'src/app/components/atTasksDialog/same-url-tasks/same-url-tasks.component';
import { DeleteAlertComponent } from 'src/app/shared/global_components/delete-alert/delete-alert.component';
import { UrlAnalyticsComponent } from '../url-analytics/url-analytics.component';
import { MobUrlAnalyticsComponent } from '../mob-url-analytics/mob-url-analytics.component';
import { environment } from 'src/environments/environment';
import { Moment } from 'moment';
import { SelectFileComponent } from 'src/app/shared/global_components/file-manager/dialog/select-file/select-file.component';
import { casesModel } from 'src/app/shared/functions/casesModel';
import { ChatService } from 'src/app/shared/services/rest/chat.service';
import { FileSettingsComponent } from 'src/app/shared/global_components/file-manager/dialog/file-settings/file-settings.component';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { transformToWatchUrl } from 'src/app/shared/functions/liveUrlTransform';


export const MY_FORMATS = {
  parse: {
    dateInput: 'LLLLL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
export const MY_NEW_FORMATS = {
  parse: {
    dateInput: 'MM/DD/YYYY',
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'MM/DD/YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@Component({
  selector: 'app-add-edit-live-stream',
  templateUrl: './add-edit-live-stream.component.html',
  styleUrls: ['./add-edit-live-stream.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_NEW_FORMATS},
  ]
})
export class AddEditLiveStreamComponent extends BaseClass implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('profilesContainer') profilesContainer: MatSelect;

  public userIsSelectedStatus: boolean = true;

  public profiles: any[] = [];
  public pagination: any;

  public channelFiles: any = [];
  public selectedFiles: any = [];
  public unSelectedFiles: any = [];

  public today = moment().set({hour:0,minute:0,second:0}).unix();
  public todayEnd = moment().endOf('day').unix();

  public thumbFile: any;
  public page: number = 1;
  public host: any = environment.host;
  public imgRoute: any = '';
  public user: any = {};
  public previewImgErrorCount: number = 0;
  public user_id: number;
  public form: FormGroup;
  public operationsValues: any;
  public company_id: any;
  public linkData: any;
  public activeLang: any;
  public task_id: any;
  public profileStatuses: any;
  public submited: boolean = false;
  public photoEdited: boolean = false;
  public showFiles: boolean = false;
  public isFormChange: boolean = false;
  public isParseWork: boolean = false;
  public isSubmit: boolean = false;

  public savedScrollPosition: number = 0;

  public required: any = {
    content_url: false,
    content_name: false,
    content_published_at: false,
    content_type_id: false,
    content_status_id: false
  }

  public contentImage: FormControl = new FormControl();
  public profilesMoreControl: FormControl = new FormControl();
  public profiles$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public urlNotYet: FormControl = new FormControl(false);

  public timeZone = - new Date().getTimezoneOffset();

  
  overlayRefLiveFile: OverlayRef | null;
  @ViewChild('liveFileMenu') liveFileMenu: TemplateRef<any>;
  public liveFileContextSub: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddEditLiveStreamComponent>,
    private sm: StorageManagerService,
    private auth: AuthService,
    private companyService: CompanyService,
    private globalDataService: GlobalDataService,
    private fb: FormBuilder,
    public viewContainerRef: ViewContainerRef,
    public overlay: Overlay,
    private chatService: ChatService,
    private bottomSheet: MatBottomSheet,
    private dialog: MatDialog,
    private taskService: TaskService,
    private languageService: LanguageService,
    private _ngx_adapter: NgxMatDateAdapter<any>,
    private _adapter: DateAdapter<any>,
    private layoutService: LayoutService
  ) {super()}

  ngOnInit(): void {
    this.data.dialogHasFm = true;
    this.dialogRef.addPanelClass("create_task_by_manager_modal")
    console.log("AddEditLiveStreamComponent", this.data)

    this.company_id = this.data.company_id;
    this.task_id = this.data.task.id;
    this.form = this.fb.group({
      task_id: this.task_id,
      channel_id: ['', Validators.required],
      content_url: ['', Validators.required],
      content_name: ['', Validators.required],
      content_description: '',
      is_content_views_count_update : 0,
      is_bravourl_content_views_count_update : 0,
      content_image: '',
      content_filename: '',
      external_content_id: '',
      content_type_id: [106, Validators.required],
      content_published_at: '',
      content_status_id: ['', Validators.required],
      content_views_count: '',
      date: this.fb.group({
        time: '',
        day: ''
      }),
      publishing_params: this.fb.group({
        live_stream_url: "rtmp://a.rtmp.youtube.com/live2",
        live_stream_key: "",
        live_stream_to: "",
        is_loop: 0,
        is_random: 0,
        random_start_count: 1,
        is_backup: 0,
        live_stream_url_backup: 'rtmp://b.rtmp.youtube.com/live2?backup=1',
        live_stream_to_backup: '',
        is_api_set_up_new: 0
      })
    })


    if (this.data.is_edit) {
      this.getTaskChannelFile()
    }

    this.attachSubscriptions(
      this.form.get('publishing_params').get('is_api_set_up_new').valueChanges.subscribe(resp => {
        console.log("this.form.get('publishing_params').get('is_api_set_up_new').valueChanges", resp)
        if (!!resp) {
          (this.form.get('publishing_params') as FormGroup).addControl('made_for_kids', this.fb.control(this.data.is_edit ? this.data.profile.publishing_params.made_for_kids : 0));
          (this.form.get('publishing_params') as FormGroup).addControl('enable_auto_start', this.fb.control(this.data.is_edit ? this.data.profile.publishing_params.enable_auto_start : 1));
          (this.form.get('publishing_params') as FormGroup).addControl('enable_auto_stop', this.fb.control(this.data.is_edit ? this.data.profile.publishing_params.enable_auto_stop : 1));
          (this.form.get('publishing_params') as FormGroup).addControl('enable_dvr', this.fb.control(this.data.is_edit ? this.data.profile.publishing_params.enable_dvr : 1));
          (this.form.get('publishing_params') as FormGroup).addControl('record_from_start', this.fb.control(this.data.is_edit ? this.data.profile.publishing_params.record_from_start : 0));
        } else {
          (this.form.get('publishing_params') as FormGroup).removeControl('made_for_kids');
          (this.form.get('publishing_params') as FormGroup).removeControl('enable_auto_start');
          (this.form.get('publishing_params') as FormGroup).removeControl('enable_auto_stop');
          (this.form.get('publishing_params') as FormGroup).removeControl('enable_dvr');
          (this.form.get('publishing_params') as FormGroup).removeControl('record_from_start');
        }
      })
    )

    this.attachSubscriptions(
      this.form.get('date').valueChanges.subscribe(resp => {
        console.log(resp)
        let dateForm = {...this.form.get("date").value}
        if (!dateForm.day) {
          dateForm.day = moment(0).hour(0).minute(0)
        }
        let testTimeAndDate = dateForm.day.clone()
        testTimeAndDate.hour(Number(dateForm.time.split(":")[0]))
        testTimeAndDate.minute(Number(dateForm.time.split(":")[1]))
        this.form.patchValue({
          content_published_at: testTimeAndDate
        })
        console.log('this.form.value', this.form.value);
      })
    )

    this.attachSubscriptions(
      this.profilesMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchProfiles(resp))
    )

    this.attachSubscriptions(
      this.contentImage.valueChanges.subscribe(res => {
        this.previewImgErrorCount = 0
      })
    )

    this.form.valueChanges.subscribe(() => this.isFormChange = true);

    this.dialogRef.backdropClick().subscribe(e => {
      e.preventDefault();
      if (this.isFormChange) {
        this.layoutService.openBottomSheet(this.dialogRef);
      } else {
        this.close();
      }
    });

    this.getCompany();
    this.getOperations();
    this.getImgRoute();
    this.getCsrf();
    this.getUser();
    this.getProfiles(this.page);
    this.getLangData();
    this.getTaskProfiles();
    this.getProfilesStatus();
    
    if (this.data.is_edit) {
      if (!this.linkData) {
        this.linkData = {
          url: this.data.profile.content_url
        }
      }
      this.contentImage.patchValue(this.data.profile.content_image)

      console.log("this.linkDatathis.linkData", this.linkData)
      
      this.form.patchValue({
        task_id: this.task_id,
        channel_id: this.data.profile.channel_id,
        content_name: this.data.profile.content_name,
        content_description: this.data.profile.content_description,
        content_filename: this.data.profile.content_filename,
        content_type_id: this.data.profile.content_type_id,
        content_image: this.data.profile.content_image,
        external_content_id: this.data.profile.external_content_id,
        is_content_views_count_update: !!this.data.profile.is_content_views_count_update,
        is_bravourl_content_views_count_update: !!this.data.profile.is_bravourl_content_views_count_update,
        content_views_count: this.data.profile.content_views_count.toString(),
        content_url: this.data.profile.content_url,
        content_status_id: this.data.profile.content_status_id != 0 ? +this.data.profile.content_status_id : 1,
        content_published_at: this.data.profile.content_published_at != '0' ? moment(this.showUserDate(false, this.data.profile.content_published_at) * 1000) : '',
      })
      this.form.get('publishing_params').patchValue({
        live_stream_url: this.data.profile.publishing_params.live_stream_url,
        live_stream_url_backup: this.data.profile.publishing_params.live_stream_url_backup ? this.data.profile.publishing_params.live_stream_url_backup : "rtmp://b.rtmp.youtube.com/live2?backup=1",
        live_stream_to_backup: this.data.profile.publishing_params.live_stream_to_backup ? this.data.profile.publishing_params.live_stream_to_backup : "",
        live_stream_key: this.data.profile.publishing_params.live_stream_key,
        live_stream_to: this.data.profile.publishing_params.live_stream_to,
        is_loop: this.data.profile.publishing_params.is_loop,
        is_backup: this.data.profile.publishing_params.is_backup ? this.data.profile.publishing_params.is_backup : 0,
        is_api_set_up_new: this.data.profile.publishing_params.is_api_set_up_new ? this.data.profile.publishing_params.is_api_set_up_new : 0,
        is_random: this.data.profile.publishing_params.is_random,
        random_start_count: this.data.profile.publishing_params.random_start_count
      })

      this.form.get('date').patchValue({
        day: this.data.profile.content_published_at ? moment(this.showUserDate(false, this.data.profile.content_published_at) * 1000).hour(0).minute(0) : '',
        time: this.data.profile.content_published_at ? (this.numberToTimeFormat(moment(this.showUserDate(false, this.data.profile.content_published_at) * 1000).hours()) + ":" + this.numberToTimeFormat(moment(this.showUserDate(false, this.data.profile.content_published_at) * 1000).minutes())) : '',
      })
      this.form.updateValueAndValidity()

      // let platform_id = this.data.profile.channel ? this.data.profile.channel.platform_id : 0;
      
      // if (platform_id == 2) {
      //   this.form.patchValue({
      //     external_content_id: this.data.profile && !!this.data.profile.external_content_id ? this.data.profile.external_content_id : ''
      //   })
      // }

      // this.urlNotYet.patchValue(!this.form.get("content_url").value && this.form.get("content_status_id").value != 1 && this.form.get("content_status_id").value != 4 ? true : false);
      // time: "06:00",

      // this.form.get('date').patchValue({
      //   day: resp.published_at ? moment(resp.published_at*1000).hour(0).minute(0) : '',
      //   time: resp.published_at ? (this.numberToTimeFormat(moment(resp.published_at*1000).hours()) + ":" + this.numberToTimeFormat(moment(resp.published_at*1000).minutes())) : '',
      // })

    }

    this.attachSubscriptions(
      this.form.get("content_url").valueChanges.pipe(
        debounceTime(300)
      ).subscribe(resp => {
        if (!!resp) {
          this.getLinkData()
        }
      })
    )
  }


  withoutToUpload() {
    if (!!this.data.profile && !this.data.profile.external_content_id) {
      return true
    } else {
      return false
    }
  }
  

  openLiveFileContext({ x, y }: MouseEvent, val) {
    this.closeLiveFileContext();
    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo({ x, y })
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        }
      ]);

    this.overlayRefLiveFile = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });

    this.overlayRefLiveFile.attach(new TemplatePortal(this.liveFileMenu, this.viewContainerRef, {
      $implicit: val
    }));
    
    this.liveFileContextSub = fromEvent<MouseEvent>(document, 'click')
      .pipe(
        filter(event => {
          const clickTarget = event.target as HTMLElement;
          return !!this.overlayRefLiveFile && !this.overlayRefLiveFile.overlayElement.contains(clickTarget);
        }),
        take(1)
      ).subscribe(() => this.closeLiveFileContext())

  }

  closeLiveFileContext() {
    this.liveFileContextSub && this.liveFileContextSub.unsubscribe();
    if (this.overlayRefLiveFile) {
      this.overlayRefLiveFile.dispose();
      this.overlayRefLiveFile = null;
    }
  }

  neededData(task, company) {
    let arr = [
      this.chatService.getTasksChats(company.id, [task.id]).pipe(
        tap(res => {
          task['operations'].map(o_el => {
            o_el['chatsInfo'] = [];
            o_el['chatsInfo'].push(...res.filter(z => z.task_operation_id == o_el.id));
            o_el['unReadChats'] = res.filter(z => z.task_operation_id == o_el.id && z.is_read != '1').length;
            o_el['unClosedChats'] = res.filter(z => z.task_operation_id == o_el.id && z.status_id != '4').length;
          });
          task['chatsInfo'] = [];
          task['chatsInfo'].push(...res.filter(z => z.task_id == task.id));
          task['unReadChats'] = res.filter(z => z.is_read != '1').length;
          task['unClosedChats'] = res.filter(z => z.status_id != '4').length;
        })
      )
    ]
    if (task.company_id == company.id) {
      arr.push(
        this.taskService.getTaskClients(task.id, company.id).pipe(
          tap(res => {
            task.clients = res
          }),
          catchError(err => {
            return err
          })
        )
      )
    }
    return forkJoin(arr)
  }

  
  getOperations() {
    this.attachSubscriptions(
      this.taskService.getOperations(this.data.company.id, this.activeLang).subscribe(resp => {
        this.operationsValues = resp;
      })
    )
  }


  settings(file?, postFile?) {
    this.closeLiveFileContext();
    this.attachSubscriptions(
      this.taskService.getOneTaskExpand(this.data.company.id, this.data.task.id).pipe(
        map(x => x.body[0]),
        switchMap(x => this.neededData(x, this.data.company).pipe(map(() => casesModel([x], [], 'update')),map(x => x.arr[0]))),
      ).subscribe(resp => {
        console.log("settings OPEN", resp)
        const dialogRef = this.dialog.open(FileSettingsComponent, {
          data: {
            company_id: this.data.company.id,
            target_company_id: this.data.task.company_id,
            company: this.data.company,
            file: file,
            postFile: postFile,
            user: this.user,
            imgRoute: this.imgRoute,
            activeLang: this.activeLang,
            host: this.host,
            location: file.location,
            task: resp,
            work: !!file.task_operation_id && resp.operations ? resp.operations.find(x => x.id == file.task_operation_id) : undefined,
            operationsValues: this.operationsValues
          }
        });
      })
    )
  }

  getLinkData() {
    // stepper: MatStepper
    if (this.isParseWork) {
      this.layoutService.showSnackBar({name: ""}, marker("Data is being received. Wait a moment, please."), SnackBarItem)
      return false;
    }

    this.isParseWork = true;

    this.attachSubscriptions(
      this.taskService.getSmartLinkData(this.form.value.content_url, this.data.profile && this.data.profile.channel_id ? this.data.profile.channel_id : 0, this.company_id, (this.data.task.company_id != this.company_id ? this.data.task.company_id : null)).subscribe(resp => {
        console.log("getLinkData ------ ############### ------", resp)
        this.linkData = resp
        if (resp.tasks && resp.tasks.length > 0 && ((this.data.task && this.data.task.channels && this.data.task.channels.length == 0) || (this.data.task && this.data.task.channels && this.data.profile && this.data.task.channels.find(x => x.id == this.data.profile.id).content_url != this.form.value.content_url))) {

          let sameUrl =  this.bottomSheet.open(TasksHaveSameUrlComponent, {
            data: {
              tasks: resp.tasks
            }
          });

          sameUrl.afterDismissed().subscribe( data => {
            if (data && data.message == 'close') {
              console.log("close");
            } if (data && data.message == 'continue') {
              const dialogRef = this.dialog.open(SameUrlTasksComponent, {
                data: {
                  company: this.data.company,
                  tasks: resp.tasks,
                  resp: resp
                }
              });
          
              // this.attachSubscriptions(
              //   dialogRef.afterClosed().subscribe(result => {
              //   })
              // )
            }
          });
          
        }

        this.form.patchValue({
          content_name: resp.name ? resp.name : 'Post has no text',
          external_content_id: resp.external_content_id,
          content_filename: !!resp.filename ? resp.filename : '',
          content_image: resp.image,
          content_views_count: !!resp.views_count ? resp.views_count : '0' 
        })
        
        if (resp && (resp.description || resp.desc)) {
          this.form.patchValue({
            content_description: resp.description || resp.desc
          })
        }

        // if (resp.published_at && this.data.profile) {
      
        //   if (moment(resp.published_at * 1000).isAfter(moment())) {
        //     if (this.data.profile.content_status_id <= 2) {
        //       this.form.patchValue({
        //         content_status_id: 3
        //       })
        //     }
        //   } else {
        //     if (this.data.profile.content_status_id <= 3) {
        //       this.form.patchValue({
        //         content_status_id: 4
        //       })
        //     }
        //   }
        // }

        this.form.get('date').patchValue({
          day: resp.published_at ? moment(this.showUserDate(false, resp.published_at) * 1000).hour(0).minute(0) : '',
          time: resp.published_at ? (this.numberToTimeFormat(moment(this.showUserDate(false, resp.published_at) * 1000).hours()) + ":" + this.numberToTimeFormat(moment(this.showUserDate(false, resp.published_at) * 1000).minutes())) : '',
        })

        this.contentImage.patchValue(this.form.get('content_image').value)

        this.isParseWork = false;
      })
    )
  }


  onInputUrl(originalUrl: string): void {
    console.log('originalUrl', originalUrl);
    let transformedUrl = transformToWatchUrl(originalUrl);
    
    if (transformedUrl != originalUrl) {
      this.form.patchValue({
        content_url: transformedUrl
      })
    }
    console.log("transformedUrl", transformedUrl);
  }

  log() {
    console.log("form", this.form.value)
    console.log("this.channelFiles", this.channelFiles)
  }

  showStats() {
    if (!this.data.is_mobile) {
      const dialogRef = this.dialog.open(UrlAnalyticsComponent, {
        disableClose: true,
        autoFocus: false,
        data: this.data
      });
  
      this.attachSubscriptions(
        dialogRef.afterClosed().subscribe(result => {
          if (!!result.data && !!result.data.profile.is_content_views_count_update != !!this.form.value.is_content_views_count_update) {
            this.form.patchValue({
              is_content_views_count_update: !!result.data.profile.is_content_views_count_update
            })
          }
          if (!!result.data && !!result.data.profile.is_bravourl_content_views_count_update != !!this.form.value.is_bravourl_content_views_count_update) {
            this.form.patchValue({
              is_bravourl_content_views_count_update: !!result.data.profile.is_bravourl_content_views_count_update
            })
          }
        })
      )
    } else {
      const dialogRef = this.dialog.open(MobUrlAnalyticsComponent, {
        backdropClass: ['mob_interface_backdrop'],
        panelClass: ['mob_interface_panel', 'animate__animated', 'animate__slideInUp'],
        disableClose: true,
        data: this.data
      });
  
      this.attachSubscriptions(
        dialogRef.afterClosed().subscribe(result => {
          if (!!result.data && !!result.data.profile.is_content_views_count_update != !!this.form.value.is_content_views_count_update) {
            this.form.patchValue({
              is_content_views_count_update: !!result.data.profile.is_content_views_count_update
            })
          }
          if (!!result.data && !!result.data.profile.is_bravourl_content_views_count_update != !!this.form.value.is_bravourl_content_views_count_update) {
            this.form.patchValue({
              is_bravourl_content_views_count_update: !!result.data.profile.is_bravourl_content_views_count_update
            })
          }
        })
      )
    }
  }

  userSelectedStatus(status) {
    if (status.is_user == 0) {
      return
    }
    let prevForm = {...this.form.value};
    // if (this.data.profile && this.data.profile.id && prevForm.content_status_id == 2 && [3,4].includes(status.id) && !!prevForm.content_url) {
    //   // this.getLinkData(status.id)
    //   // this.attachSubscriptions(
    //   //   this.taskService.editProfile(this.data.profile.id, {content_status_id: status.id}, this.company_id).subscribe(res => {

    //   //   })
    //   // )
    // }

    this.userIsSelectedStatus = true
    if (status.id == 1 || status.id == 4) {
      this.urlNotYet.patchValue(false);
    }
  }

  getTaskChannelFile() {
    this.attachSubscriptions(
      this.taskService.getTaskChannelFile(this.company_id, {task_channel_id: this.data.profile.id}).subscribe(resp => {
        this.selectedFiles = []
        this.channelFiles = resp;
        if (resp.find(x => !!x.is_thumbnail)) {
          this.thumbFile = resp.find(x => !!x.is_thumbnail)
        }
        console.log("this.channelFiles", this.channelFiles);
        // this.fileForm.patchValue({
        //   task_channel_id: this.data.profile.id,
        //   file_id: this.channelFile.file_id,
        //   is_thumbnail: !!this.channelFile.thumbnail ? 1 : 0
        // })
      })
    )
  }

  reprocessMissingFiles() {
    this.channelFiles.forEach((chFile, ind) => {
      if (!!chFile.is_error) {
        chFile.deleted = true;
        if (this.channelFiles.filter(u => u.file_id == chFile.file_id && u.is_new).length == 0) {
          this.channelFiles.push({
            company_id: chFile.company_id,
            task_channel_id: '',
            file_id: chFile.file_id,
            is_to_upload: 1,
            is_live_stream_content: 1,
            file: chFile.file,
            is_new: true
          })
        }
      }
    });
  }

  selectFileBtn(only) {
    let x:any = {
      company: this.data.company,
      task: this.data.task,
      work: undefined,
      user: this.user,
      only: only,
      is_multi: true,
      selectedFiles: []
    }

    const dialogRef = this.dialog.open(SelectFileComponent, {
      data: x
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log("result", result)
        if (!!result && result.event == 'select' && result.data && result.data.length) {
          console.log("result.data", result.data)
          result.data.forEach(file => {         
            if (this.channelFiles.filter(u => u.file_id == file.id).length == 0) {
              this.channelFiles.push({
                company_id: file.company_id,
                task_channel_id: '',
                file_id: file.id,
                is_to_upload: 1,
                is_live_stream_content: 1,
                file: file,
                is_new: true
              })
            }
          });
        }
      })
    )
  }

  getChannelFilesId() {
    return this.channelFiles.slice().map(x => x.file_id).concat(this.selectedFiles.slice().map(x => x.id))
  }

  getUnSelectedFilesId() {
    return this.unSelectedFiles.slice().map(x => x.id)
  }

  changedTime(e) {
    console.log("changedTimechangedTimechangedTimechangedTimechangedTimechangedTimechangedTime",e)
  }

  numberToTimeFormat(val) {
    if (Number(val) < 10) {
      val = "0" + val
    }
    return val
  }

  deletePhoto() {
    if (this.channelFiles.find(x => !!x.is_new && !!x.is_thumbnail)) {
      this.thumbFile = undefined;
      this.channelFiles.splice(this.channelFiles.findIndex(x => !!x.is_new && !!x.is_thumbnail), 1)
    }
    
    this.form.patchValue({
      content_image: ''
    })
    this.photoEdited = false;
  }

  editPhoto() {
    this.contentImage.patchValue(this.form.get('content_image').value)
    this.photoEdited = true;
  }

  savePhoto() {
    this.form.patchValue({
      content_image: this.contentImage.value
    })
    this.photoEdited = false;
  }

  closePhoto() {
    this.photoEdited = false;
  }

  onImgError(event){
    event.target.src = this.imgRoute+'/assets/img/image_black_48dp.svg'
  }

  onPreviewImgError(event) {
    console.log("onPreviewImgError", this.linkData, this.contentImage.value)
    if (!!this.contentImage.value && this.previewImgErrorCount < 3) {
      this.previewImgErrorCount++
      if (event.target.src.indexOf("/api/get-url-data/proxy/") != -1 && this.data.profile && this.data.profile.content_image_file_id) {
        event.target.src = this.host + this.data.profile.file.original;
      } else {
        event.target.src = this.host + '/api/get-url-data/proxy/?company_id=' + this.data.company.id + (this.data.profile.company_id != this.data.company.id ? ('&partner_company_id=' + this.data.profile.company_id) : '') + '&url=' + encodeURIComponent(this.contentImage.value)
      }
    } else {
      return
    }
  }

  removeAllFiles() {
    this.channelFiles.forEach((item, i) => {
      console.log("removeAllFiles item", item)
      if (item.is_new) {
        // console.log("removeAllFiles findIndex", this.channelFiles.findIndex(p => p.file_id == item.file_id && p.is_new));
        delete this.channelFiles[i];
        // this.channelFiles.splice(this.channelFiles.findIndex(p => p.file_id == item.file_id && p.is_new), 1)
      } else {
        item.deleted = true;
      }
    })
    setTimeout(() => {
      this.channelFiles = this.channelFiles.filter(u => !!u);
    },0)
  }

  logFiles() {
    console.log(this.channelFiles)
  }

  deleteChFile(item, i) {
    if (item.is_new) {
      this.channelFiles.splice(i,1)
      if (item.is_thumbnail) {
        this.thumbFile = undefined;
      }
    } else {
      item.deleted = true;
    }
    console.log("this.channelFiles", this.channelFiles)
  }
  
  showUserDate(date:any = false, timestamp:any = false) {
    let timeAndDate;
    if (date) {
      timeAndDate = moment(date).clone()
    } else {
      timeAndDate = moment(timestamp*1000).clone()
    }

    const utcOffset = moment.tz(timeAndDate, this.data.company.timezone).utcOffset();
    
    let utcTimestamp = moment(timeAndDate).format("X")
    
    let difference = (Number(this.timeZone) - Number(utcOffset))*60
    // console.log(difference);
    
    let workSpaceTimeZone;
    if (date) {
      workSpaceTimeZone = Number(utcTimestamp) + Number(difference)
    } else {
      workSpaceTimeZone = Number(utcTimestamp) - Number(difference)
    }
    return workSpaceTimeZone
  }

  onSearchProfiles(resp) {
    if (!!resp) {
      resp = resp.toLowerCase();
    }

    this.page = 1;
    if (this.form.value.channel_id) {
      this.profiles = this.profiles.filter(x => x.id == this.form.value.channel_id);
    } else {
      this.profiles = [];
    }
    this.getProfiles(this.page, resp);
  }

  selectThumb() {
    let x:any = {
      company: this.data.company,
      task: this.data.task,
      work: undefined,
      user: this.user,
      only: 'image',
      is_multi: false,
      thumbFile: this.thumbFile,
      no_matches: true
    }

    const dialogRef = this.dialog.open(SelectFileComponent, {
      data: x
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log("result", result)
        if (!!result && result.event == 'select' && result.data) {
          console.log("result.data", result.data)
          this.thumbFile = result.data;
          console.log("this.thumbFile", this.thumbFile)   
          if (this.channelFiles.filter(u => u.is_thumbnail && !u.is_new).length != 0) {
            this.channelFiles.find(u => u.is_thumbnail && !u.is_new).deleted = true;
          } 
          if (this.channelFiles.filter(u => u.is_thumbnail && !!u.is_new).length != 0) {
            this.channelFiles.splice(this.channelFiles.findIndex(u => u.is_thumbnail && !!u.is_new), 1)
          } 
          this.channelFiles.push({
            company_id: result.data.company_id,
            task_channel_id: '',
            file_id: result.data.id,
            is_to_upload: 1,
            is_thumbnail: 1,
            file: result.data,
            is_new: true
          })
        }
      })
    )
  }

  selectFile(file) {
    if (this.channelFiles.filter(x => x.file_id == file.id).length != 0) {
      if (this.unSelectedFiles.filter(x => x.id == file.id).length == 0) {
        this.unSelectedFiles.push(file)
      } else {
        this.unSelectedFiles.splice(this.unSelectedFiles.indexOf(file), 1)
      }
    } else if (this.selectedFiles.filter(x => x.id == file.id).length == 0) {
      this.selectedFiles.push(file)
      console.log("selectedFiles", this.selectedFiles)
      // this.fileForm.patchValue({
      //   file_id: this.selectedFile.id,
      //   is_thumbnail: !!this.selectedFile.thumbnail ? 1 : 0
      // })
    } else {
      this.selectedFiles.splice(this.selectedFiles.indexOf(file), 1)
      // this.selectedFile = undefined
      // this.fileForm.patchValue({
      //   file_id: '',
      //   is_thumbnail: 0
      // })
    }
    console.log("selectedFiles", this.selectedFiles)
    console.log("unSelectedFiles", this.unSelectedFiles)
    console.log("channelFiles", this.channelFiles)
    console.log("getChannelFilesId", this.getChannelFilesId())
  }

  copyLink(type) {
    this.layoutService.showSnackBar({name: type}, marker("Copied"), SnackBarItem)
  }

  ngAfterViewInit(): void {
    this.attachSubscriptions(
      this.profilesContainer.openedChange.subscribe((e) => {
        if (e) {
          this.onScroll()
        }
      })
    )
  }

  trackByProfileId(index: number, profile: any): number {
    return profile.id;
  }

  // onScroll() {
  //   this.attachSubscriptions(
  //     fromEvent(this.profilesContainer.panel.nativeElement, "scroll").pipe(
  //       filter((e:any) => e.target.scrollTop >=  e.target.scrollHeight - e.target.offsetHeight - 400),
  //       map(() => this.profilesContainer.panel.nativeElement.scrollTop),
  //       debounceTime(300),
  //       map(y => Math.ceil((y + this.profilesContainer.panel.nativeElement.innerHeight)/ (400))),
  //       distinctUntilChanged()
  //     ).subscribe(() => {
  //         if (this.page <= this.pagination['pageCount']) {
  //           this.getProfiles(this.page);
  //         }
  //       }
  //     )
  //   )
  // }

  onScroll() {
    this.attachSubscriptions(
      fromEvent(this.profilesContainer.panel.nativeElement, "scroll").pipe(
        // Фильтр для определения, когда нужно подгружать новые данные
        filter((e: any) => e.target.scrollTop >= e.target.scrollHeight - e.target.offsetHeight - 400),
        // Сохранение текущей позиции скролла
        map(() => this.profilesContainer.panel.nativeElement.scrollTop),
        debounceTime(300),
        map(y => Math.ceil((y + this.profilesContainer.panel.nativeElement.innerHeight) / 400)),
        distinctUntilChanged()
      ).subscribe(() => {
        // Сохраняем текущую позицию скролла перед подгрузкой новых данных
        this.savedScrollPosition = this.profilesContainer.panel.nativeElement.scrollTop;

        // Загружаем новые данные, если нужно
        if (this.page <= this.pagination['pageCount']) {
          this.getProfiles(this.page, undefined, true)
        }
      })
    );
  }

  getProfiles(page, search?, is_scroll?) {
    let filterVal:any = {platform_id: 1};
    // let filterVal:any = {};
    if (search || this.profilesMoreControl.value) {
      filterVal.q = search || this.profilesMoreControl.value
    }
    this.attachSubscriptions(
      this.companyService.getAllProfilesDyn(page, this.company_id, '20', filterVal).pipe(
        tap(el => {
          this.pagination = {
            'pageCount': el.headers.get('x-pagination-page-count'),
            'perPage': el.headers.get('x-pagination-per-page'),
            'totalCount': el.headers.get('x-pagination-total-count'),
            'currentPage': el.headers.get('x-pagination-current-page'),
          }
        }),
        map(el => el.body)
      ).subscribe(resp => {
        console.log("getProfiles", resp)
        resp.forEach(k => {
          if (this.profiles.filter(x => x.id == k.id).length == 0) {
            this.profiles.push(k);
          }
        });
        this.profiles$.next(this.profiles.slice());
        this.page = this.page + 1;

        console.log("this.profilesContainer.panel.nativeElement.scrollTop", this.profilesContainer.panel.nativeElement.scrollTop)
        console.log("this.savedScrollPosition", this.savedScrollPosition)
        this.profilesContainer.panel.nativeElement.scrollTop = this.savedScrollPosition;
        // if (is_scroll) {
        //   setTimeout(() => {
        //   }, 1000);
        // }
      })
    )
  }

  getProfilesStatus() {
    this.attachSubscriptions(
      this.globalDataService.getContentStatus().subscribe(resp => {
        console.log("getContentStatus", resp);
        this.profileStatuses = resp.slice();
      })
    )
    // this.attachSubscriptions(
    //   this.taskService.getProfileStatuses().subscribe(resp => {
    //     console.log("getProfilesStatus", resp)
    //     this.profileStatuses = resp;
    //   })
    // )
  }

  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData().subscribe(resp => {
        this._ngx_adapter.setLocale(resp.active);
        this._adapter.setLocale(resp.active);
        this.activeLang = resp.active;
      })
    )
  }

  getTaskProfiles() {
    this.attachSubscriptions(
      this.taskService.getTaskProfiles(this.task_id, this.company_id).subscribe(resp => console.log("PROFILES", resp))
    )
  }

  getCompany() {
    this.attachSubscriptions(
      this.companyService.getCompany(this.company_id).subscribe(resp => {
        this.companyService.company$.next(resp[0]);
      })
    )
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  getCsrf() {
    this.attachSubscriptions(
      this.auth.$userToken.subscribe(resp => {
        this.user_id = resp.user_id;
        this.sm.localStorageSetItem("csrf_param", resp.csrf_param)
        this.sm.localStorageSetItem("csrf_token", resp.csrf_token)
      })
    )
  }

  getUser() {
    this.attachSubscriptions(
      this.auth.$user.subscribe(resp => {
        this.user = resp;
      })
    )
  }

  isValidNotYetDate() {
    return this.form.get('content_published_at') && this.form.get('content_published_at').value && (this.form.get('content_published_at').value as moment.Moment).isAfter(moment(30000))
  }

  understandFileType(val) {
    if (!val) {
      return ""
    }
    
    if (val == 'application/pdf') {
      return 'pdf'
    } else if (val.indexOf("/") != -1) {
      return val.split('/')[0]
    } else {
      return ""
    }
  }

  onSubmit() {
    // if (!this.form.valid || (this.urlNotYet.value && !this.isValidNotYetDate())) {
    //   this.submited = true;
    //   this.layoutService.showSnackBar({name: ''}, marker("You need to fill in all required fields"), SnackBarItem)
    //   return
    // }
    // if (!!this.is_system_status) {
    //   this.attachSubscriptions(
    //     this.taskService.editProfile(this.data.profile.id, {content_status_id: this.form.value.content_status_id}, this.company_id).subscribe(
    //       resp => {
    //         this.dialogRef.close({event: "edit", data: resp});
    //         this.isSubmit = false;
    //       }, 
    //       error => {
    //         this.isSubmit = false;
    //         this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
    //       }
    //     )
    //   )

    //   return
    // }

    this.submited = true;

    // if (Object.values(this.required).includes(true)) {
    //   console.log("this.form.value", this.form.value)
    //   console.log("this.required", this.required)
    //   this.layoutService.showSnackBar({name: ''}, marker("You need to fill in all required fields"), SnackBarItem)
    //   return
    // }

    // this.required = {
    //   content_url: false,
    //   content_name: false,
    //   content_published_at: false,
    //   content_type_id: false,
    //   content_status_id: false
    // }

        // this.attachSubscriptions(
    //   this.urlNotYet.valueChanges.subscribe(res => {
    //     console.log("res", res)
    //     if (res) {
    //       this.form.get('content_url').clearValidators();           
    //       this.form.get('content_name').clearValidators();           
    //       this.form.get('content_published_at').setValidators(Validators.required);
    //     } else {
    //       this.form.get('content_url').setValidators(Validators.required)
    //       this.form.get('content_name').setValidators(Validators.required)
    //       this.form.get('content_published_at').clearValidators()
    //     }
    //     this.form.get('content_url').updateValueAndValidity({onlySelf: true});
    //     this.form.get('content_name').updateValueAndValidity({onlySelf: true});
    //     this.form.get('content_published_at').updateValueAndValidity({onlySelf: true});
    //   })
    // )

    this.submited = false;
    this.isSubmit = true;


    let form = {...this.form.value};
    let workSpaceTimeZone;
    if (form.content_published_at) {
      let timeAndDate = form.content_published_at.clone().set({'second': 0, 'millisecond': 0})
  
      const utcOffset = moment.tz(timeAndDate, this.data.company.timezone).utcOffset();
      console.log(utcOffset);
  
      
      let utcTimestamp = moment(timeAndDate).format("X")
      console.log(utcTimestamp);
      
      let difference = (Number(this.timeZone) - Number(utcOffset))*60
      console.log("difference", difference);
      
      workSpaceTimeZone = Number(utcTimestamp) + Number(difference)
      console.log("workSpaceTimeZone", workSpaceTimeZone);
    }

    let sbmtData = {...form}
    if (form.content_published_at) {
      sbmtData.content_published_at = workSpaceTimeZone
    } else {
      sbmtData.content_published_at = ''
    }

    sbmtData.is_content_views_count_update = !!sbmtData.is_content_views_count_update ? 1 : 0;
    sbmtData.is_bravourl_content_views_count_update = !!sbmtData.is_bravourl_content_views_count_update ? 1 : 0;
    sbmtData.publishing_params.is_loop = !!sbmtData.publishing_params.is_loop ? 1 : 0;
    sbmtData.publishing_params.is_random = !!sbmtData.publishing_params.is_random ? 1 : 0;
    sbmtData.publishing_params.live_stream_to = (sbmtData.publishing_params.live_stream_url.length && sbmtData.publishing_params.live_stream_url[sbmtData.publishing_params.live_stream_url.length - 1] == '/' ? sbmtData.publishing_params.live_stream_url : (sbmtData.publishing_params.live_stream_url + '/')) + sbmtData.publishing_params.live_stream_key;
    sbmtData.publishing_params.live_stream_to_backup =  sbmtData.publishing_params.live_stream_url_backup.split('?')[0] + '/' + sbmtData.publishing_params.live_stream_key + "?backup=1";
    // sbmtData.publishing_params.live_stream_to = sbmtData.publishing_params.live_stream_to.replaceAll('//', '/')

    if (!!sbmtData.publishing_params.is_backup) {
      sbmtData.publishing_params.is_backup = 1;
    }
    if (!!sbmtData.publishing_params.is_api_set_up_new) {
      sbmtData.publishing_params.live_stream_to = '';
      sbmtData.publishing_params.live_stream_to_backup = '';
      sbmtData.publishing_params.live_stream_key = '';
      sbmtData.publishing_params.is_api_set_up_new = 1;

      sbmtData.publishing_params.made_for_kids = !!sbmtData.publishing_params.made_for_kids ? 1 : 0;
      sbmtData.publishing_params.enable_auto_start = !!sbmtData.publishing_params.enable_auto_start ? 1 : 0;
      sbmtData.publishing_params.enable_auto_stop = !!sbmtData.publishing_params.enable_auto_stop ? 1 : 0;
      sbmtData.publishing_params.enable_dvr = !!sbmtData.publishing_params.enable_dvr ? 1 : 0;
      sbmtData.publishing_params.record_from_start = !!sbmtData.publishing_params.record_from_start ? 1 : 0;
    }

    delete sbmtData.date

    if (this.data.is_edit) {
      this.attachSubscriptions(
        this.taskService.editProfile(this.data.profile.id, sbmtData, this.company_id).pipe(
          switchMap(res => {
            let newFiles = [...this.channelFiles.filter(u => !!u.is_new)];
            let deletedFiles = [...this.channelFiles.filter(u => !!u.deleted)];
            let oldFiles = [...this.channelFiles.filter(u => !u.is_new)]
            if (newFiles.length || deletedFiles.length || oldFiles.length) {
               return forkJoin([...newFiles.map(fileData => this.taskService.setTaskChannelFile({
                company_id: fileData.company_id,
                task_channel_id: res.id,
                file_id: fileData.file_id,
                is_to_upload: !!fileData.is_to_upload ? 1 : 0,
                [fileData.hasOwnProperty('is_thumbnail') ? 'is_thumbnail' : 'is_live_stream_content']: !!(fileData.hasOwnProperty('is_thumbnail') ? fileData.is_thumbnail : fileData.is_live_stream_content) ? 1 : 0
              }, this.company_id).pipe(
                catchError((error) => {
                  res.hasError = true;
                  return of(res)
                })
              )),...oldFiles.map(fileData => this.taskService.editTaskChannelFile(fileData.id, {
                company_id: fileData.company_id,
                task_channel_id: res.id,
                file_id: fileData.file_id,
                is_to_upload: !!fileData.is_to_upload ? 1 : 0,
                [fileData.hasOwnProperty('is_thumbnail') ? 'is_thumbnail' : 'is_live_stream_content']: !!(fileData.hasOwnProperty('is_thumbnail') ? fileData.is_thumbnail : fileData.is_live_stream_content) ? 1 : 0
              }, this.company_id).pipe(
                catchError((error) => {
                  res.hasError = true;
                  return of(res)
                })
              )),...deletedFiles.map(fileData => this.taskService.unsetTaskChannelFile(fileData.id, this.company_id).pipe(
                catchError((error) => {
                  res.hasError = true;
                  return of(res)
                })
              ))]).pipe(
                map(() => res),
              )
            } else {
              return of(res)
            }
          })
        ).subscribe(
          resp => {
            if (!!resp.hasError) {
              this.layoutService.showSnackBar({name: ''}, marker('One or more stream files could not be updated/deleted/added.'), SnackBarItem)
            } 
            this.dialogRef.close({event: "edit", data: resp});
            this.isSubmit = false;
          }, 
          error => {
            this.isSubmit = false;
            this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
          }
        )
      )
    } else {
      this.attachSubscriptions(
        this.taskService.addProfile(sbmtData, this.company_id).pipe(
          switchMap(res => {
            let newFiles = [...this.channelFiles.filter(u => !!u.is_new)];
            let deletedFiles = [...this.channelFiles.filter(u => !!u.deleted)];
            let oldFiles = [...this.channelFiles.filter(u => !u.is_new)]
            if (newFiles.length || deletedFiles.length || oldFiles.length) {
               return forkJoin([...newFiles.map(fileData => this.taskService.setTaskChannelFile({
                company_id: fileData.company_id,
                task_channel_id: res.id,
                file_id: fileData.file_id,
                is_to_upload: !!fileData.is_to_upload ? 1 : 0,
                [fileData.hasOwnProperty('is_thumbnail') ? 'is_thumbnail' : 'is_live_stream_content']: !!(fileData.hasOwnProperty('is_thumbnail') ? fileData.is_thumbnail : fileData.is_live_stream_content) ? 1 : 0
              }, this.company_id).pipe(
                catchError((error) => {
                  res.hasError = true;
                  return of(res)
                })
              )),...oldFiles.map(fileData => this.taskService.editTaskChannelFile(fileData.id, {
                company_id: fileData.company_id,
                task_channel_id: res.id,
                file_id: fileData.file_id,
                is_to_upload: !!fileData.is_to_upload ? 1 : 0,
                [fileData.hasOwnProperty('is_thumbnail') ? 'is_thumbnail' : 'is_live_stream_content']: !!(fileData.hasOwnProperty('is_thumbnail') ? fileData.is_thumbnail : fileData.is_live_stream_content) ? 1 : 0
              }, this.company_id).pipe(
                catchError((error) => {
                  res.hasError = true;
                  return of(res)
                })
              )),...deletedFiles.map(fileData => this.taskService.unsetTaskChannelFile(fileData.id, this.company_id).pipe(
                catchError((error) => {
                  res.hasError = true;
                  return of(res)
                })
              ))]).pipe(
                map(() => res),
              )
            } else {
              return of(res)
            }
          })
        ).subscribe(resp => {
          if (!!resp.hasError) {
            this.layoutService.showSnackBar({name: ''}, marker('One or more stream files could not be updated/deleted/added.'), SnackBarItem)
          } 
          this.dialogRef.close({event: "Add", data: resp})
          this.isSubmit = false;
        },
        error => {
          this.isSubmit = false;
          this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
        })
      )
    }
  }

  getJob(id) {
    return (id && !!this.data.task.operations.find(x => x.id == id)) ? this.data.task.operations.find(x => x.id == id) : undefined
  }

  change() {
    console.log("CHANGE")
  }

  showError() {
    this.layoutService.showSnackBar({name: ''}, marker("Try again later"), SnackBarItem)
  }

  stepperBack(stepper: MatStepper) {
    stepper.previous();
  }

  cancel() {
    this.dialogRef.close({event: "null", data: false})
  }

  getProfileById(id) {
    if (!this.profiles || this.profiles.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.profiles.find(el => el.id == id)
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
