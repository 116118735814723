<mat-dialog-content class="mat-typography">
    <span class="head_title" (click)="log()">
        <b style="margin-right: 3.5px;">{{projectsByServers?.length}} {{"servers" | translate}}</b> {{projects?.length}} {{"projects" | translate}}
    </span>
    <div class="autos" [appDeviceClass]="'custom-scroll custom-scroll-t'">

        <!-- <div class="d-f-c" style="justify-content: space-between;">
            <mat-form-field appearance="legacy" class="white_legacy_select" *ngIf="parameters" style="color: #fff;">
                <mat-label>{{ "Tag category" | translate }}</mat-label>
                <mat-select [disableOptionCentering]="true" [multiple]="false" selectDir [(ngModel)]="parameter_id" (selectionChange)="onParamChange($event)">
                  <mat-option>
                    <ngx-mat-select-search 
                        placeholderLabel="{{ 'Search' | translate }}" 
                        noEntriesFoundLabel="{{ 'no matching tag found' | translate }}"
                        [formControl]="parametersCtrl"></ngx-mat-select-search>
                  </mat-option>
                  <mat-option *ngFor="let parameter of parameters$ | async" [value]="parameter.id">
                    {{parameter.name}}
                  </mat-option>
                </mat-select>
            </mat-form-field>

            <div class="d-f-c" style="margin-left: 40px;">
                <mat-form-field appearance="legacy" class="white_legacy_select" *ngIf="checklists" style="color: #fff;">
                    <mat-label>{{ "Check List" | translate }}</mat-label>
                    <mat-select [disableOptionCentering]="true" [multiple]="false" selectDir [(ngModel)]="operation_reminder_id" (selectionChange)="onRemChange($event)">
                      <mat-option>
                        <ngx-mat-select-search 
                            placeholderLabel="{{ 'Search' | translate }}" 
                            noEntriesFoundLabel="{{ 'no matching checklists found' | translate }}"
                            [formControl]="checklistsCtrl"></ngx-mat-select-search>
                      </mat-option>
                      <mat-option *ngFor="let check of checklists$ | async" [value]="check.id">
                        {{check.text}}
                      </mat-option>
                    </mat-select>
                </mat-form-field>
    
                <mat-form-field appearance="legacy" class="white_legacy_select ml-1" *ngIf="parameters" style="color: #fff;">
                    <mat-label>{{ "Inherit tags from categories" | translate }}</mat-label>
                    <mat-select [disableOptionCentering]="true" [multiple]="true" selectDir [(ngModel)]="nestedTags">
                      <mat-option>
                        <ngx-mat-select-search 
                            placeholderLabel="{{ 'Search' | translate }}" 
                            noEntriesFoundLabel="{{ 'no matching tag found' | translate }}"
                            [formControl]="parametersCtrl"></ngx-mat-select-search>
                      </mat-option>
                      <mat-option *ngFor="let parameter of parameters$ | async" [value]="parameter.id" [disabled]="parameter.id == parameter_id" (selectionChange)="onNestedChange($event)">
                        {{parameter.name}}
                      </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div> -->

        <ng-container *ngIf="projects">
            <div class="items">      
                <!-- <div class="items_line items_line_head">
                    <div class="item check">
                        <div class="item_head">
                            <div class="check_item" (click)="toggleCol()">
                                <ng-container *ngIf="isAllChecked(); else elseTemplateNoInclTmlp">
                                    <mat-icon>check_box</mat-icon>
                                </ng-container>
                                <ng-template #elseTemplateNoInclTmlp>
                                    <mat-icon>check_box_outline_blank</mat-icon>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                    <div class="item post_item">
                        <div class="item_head">
                            <div class="item_photo">
                                <mat-icon>live_tv</mat-icon>
                            </div>
                            <div class="d-f-c">
                                <div class="name text_one_line">{{"Live Stream" | translate}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="item_head">
                            <div class="item_photo">
                                <mat-icon>movie</mat-icon>
                            </div>
                            <div>
                                <div class="name text_one_line">{{"Files" | translate}}</div>
                            </div>
                        </div>
                    </div>
                </div>    -->
                <ng-container *ngFor="let server of projectsByServers; let i = index">
                    <div class="items_line">
                        <!-- <div class="item check">
                            <div class="check_item" (click)="url.checked = !url.checked">
                                <ng-container *ngIf="!!url?.checked; else elseTemplateNoIncl">
                                    <mat-icon>check_box</mat-icon>
                                </ng-container>
                                <ng-template #elseTemplateNoIncl>
                                    <mat-icon>check_box_outline_blank</mat-icon>
                                </ng-template>
                            </div>
                        </div> -->
                        <div class="item server_item">
                             <div class="item_el" style="justify-content: space-between;">
                                {{!!server.server_id ? server.server_id : ("No server" | translate)}}

                                <div class="view_count" *ngIf="!!server?.projects" style="margin-bottom: 2.5px;">
                                    <div class="d-f-c">
                                        <mat-icon style="color: #C9C9C9; width: 11px; height: 11px; font-size: 11px; line-height: 11px;">movie_filter</mat-icon>
                                        <span style="margin-left: 3px;">{{ server?.projects?.length }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="item post_item" *ngFor="let el of server.projects; let j = index;">
                            <div class="item_el" style="justify-content: space-between;">
                                <div class="name text_one_line">
                                    {{el.name}}
                                </div>
                                <div class="d-f-c"> 
                                    <span class="ml-5">
                                        {{el.id}}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>            
                <div class="loading" *ngIf="isGettingFiles || isRealGettingFiles">
                    <mat-spinner></mat-spinner>
                </div>
            </div>
        </ng-container>
        
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <div class="bottom_btns">
        <div class="d-f-c">
            <!-- <button class="mr-1 d-f-btn" style="color: #fff !important;" *ngIf="hasChecked()" (click)="openFilesSettings()" mat-button [disabled]="isGettingFiles">
                <mat-spinner class="spin_16 mr-1" *ngIf="isGettingFiles"></mat-spinner>  
                {{ "Get files" | translate }}
            </button> -->
        </div>
        <div class="d-f-c">
            <!-- <div class="mr-1 loading" *ngIf="isRealGettingFiles">
                <mat-spinner class="spin_16 mr-1"></mat-spinner> 
                {{ "Loading live files" | translate }}...
            </div>
    
            <mat-form-field appearance="legacy" class="white_legacy_select full_width_form mr-1" style="color: #fff;">
                <mat-label>{{ "Content status for all" | translate }}</mat-label>
                <mat-select disableOptionCentering [formControl]="status_id">
                    <mat-option [value]="item.id" *ngFor="let item of profileStatuses" [disabled]="!item?.is_user">
                        {{ item.name+'_profile' | translate }}
                    </mat-option>
                </mat-select>
            </mat-form-field> -->

            <button mat-raised-button (click)="close()">
                {{ "Close" | translate }}
            </button>

            <!-- <button class="ml-1 d-f-btn" (click)="submitForm2()" mat-raised-button color="primary" [disabled]="isSubmit">
                <mat-spinner class="spin_16 mr-1" *ngIf="isSubmit"></mat-spinner>  
                {{ "Save" | translate }}
            </button> -->
        </div>
    </div>
</mat-dialog-actions>
