import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { BaseClass } from 'src/app/shared/models/base-class';
import { GlobalDataService } from 'src/app/shared/services/common/global-data.service';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { AuthService } from 'src/app/shared/services/rest/auth.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { environment } from 'src/environments/environment';
import { MY_FORMATS } from '../../../../atTasksDialog/task-profile-add/task-profile-add.component';
import { AddPaymentComponent } from '../add-payment/add-payment.component';

@Component({
  selector: 'app-edit-payment',
  templateUrl: './edit-payment.component.html',
  styleUrls: ['./edit-payment.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class EditPaymentComponent extends BaseClass implements OnInit, OnDestroy {
  public host: any = environment.host;
  public imgRoute: any = '';
  public user: any = {};
  public user_id: number;
  public employees: any;
  public form: FormGroup;
  public isSubmit: boolean = false;
  public isFormChange: boolean = false;
  public company_id: any;
  public currencyList: any;
  public statuses: any;
  public paymentSystems: any;
  public numberRegEx = /\d*\.?\d{1,2}/;
  public payment: any;
  public activeLang: any;
  public payment_id: number;
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddPaymentComponent>,
    private sm: StorageManagerService,
    private auth: AuthService,
    private companyService: CompanyService,
    private globalDataService: GlobalDataService,
    private fb: FormBuilder,
    private layoutService: LayoutService,
    private taskService: TaskService,
    private languageService: LanguageService,
    private _adapter: DateAdapter<any>,
  ) { super() }

  ngOnInit(): void {
    window.scroll(0,0);

    // employee_id: [this.data.payment.employee_id, Validators.required],
    this.form = this.fb.group({
      payment_system_id: [this.data.payment.payment_system_id, Validators.required],
      currency_id: [this.data.payment.currency_id, Validators.required],
      comment: this.data.payment.comment,
      paid_at: this.data.payment.paid_at ? moment(this.data.payment.paid_at*1000).utc() : '',
      real_paid_at: this.data.payment.real_paid_at ? moment(this.data.payment.real_paid_at*1000).utc() : '',
      amount: [this.data.payment.amount, [Validators.required, Validators.pattern(this.numberRegEx)]],
    })

    this.attachSubscriptions(
      this.form.valueChanges.subscribe(() => this.isFormChange = true)
    )

    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        if (this.isFormChange) {
          this.layoutService.openBottomSheet(this.dialogRef);
        } else {
          this.close();
        }
      })
    )

    this.getEmployees();
    this.getPaymentSystem();
    this.getCurrencyList();
    this.getCompany();
    this.getImgRoute();
    this.getCsrf();
    this.getUser();
    this.getLangData()
  }

  getEmployees() {
    this.attachSubscriptions(
      this.taskService.getEmployees(this.data.company_id).subscribe(resp => {
        console.log(resp);
        this.employees = resp;
      })
    )
  }

  getCompany() {
    this.attachSubscriptions(
      this.companyService.getCompany(this.data.company_id).subscribe(resp => {
        this.companyService.company$.next(resp[0]);
      })
    )
  }

  getCurrencyList() {
    this.attachSubscriptions(
      this.globalDataService.getCurrencies().subscribe(resp => {
        console.log("getCurrencyList", resp)
        this.currencyList = resp.slice();
      })
    )
  }


  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData().subscribe(resp => {
        this.activeLang = resp.active;
        this._adapter.setLocale(resp.active);
      })
    )
  }

  getPaymentSystem() {
    this.attachSubscriptions(
      this.companyService.getPaymentSystem().subscribe(resp => {
        console.log("getPaymentSystem", resp);
        this.paymentSystems = resp
      })
    )
  }

  getCsrf() {
    this.attachSubscriptions(
      this.auth.$userToken.subscribe(resp => {
        this.user_id = resp.user_id;
        this.sm.localStorageSetItem("csrf_param", resp.csrf_param)
        this.sm.localStorageSetItem("csrf_token", resp.csrf_token)
      })
    )
  }

  getUser() {
    this.attachSubscriptions(
      this.auth.$user.subscribe(resp => {
        this.user = resp;
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  onSubmit() {
    if (String(this.form.value['amount']).indexOf(",") != -1) {
      this.form.value['amount'] = this.form.value['amount'].replace(",", ".");
    }

    if (!!this.form.value.paid_at && !!this.form.value.paid_at._d) {
      this.form.value.paid_at = moment(this.form.value.paid_at._d).format("X");
    } else {
      delete this.form.value.paid_at;
    }
    
    if (!!this.form.value.real_paid_at && !!this.form.value.real_paid_at._d) {
      this.form.value.real_paid_at = moment(this.form.value.real_paid_at._d).format("X");
    } else {
      delete this.form.value.real_paid_at;
    }
    this.isSubmit = true;
    
    this.attachSubscriptions(
      this.companyService.editPayment(this.data.payment.id, this.form.value).subscribe(resp => {
        console.log(resp);
        this.isSubmit = false;
        this.dialogRef.close({event: "update", data: resp});
      }, error => {
        this.isSubmit = false;
        this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
      })
    )
  }

  ngOnDestroy(): void {
    this.clearSubscriptions();
  }

}
