<div class="cases_page tasks_page" *ngIf="!is_mobile">
    <div class="container">
      <div class="filter_out">  
        <div class="filter_values">
          <mat-chip-list class="chips_wrp without_ordered start_page_chips">

            <!-- <mat-chip (removed)="removeValue('q')" *ngIf="filter.get('q').value">
                {{filter.get('q').value}}
                <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
            </mat-chip>
             -->
            <!-- <mat-chip (removed)="removeValue('id')" *ngIf="filter?.get('id')?.value">
              {{ "Project ID" | translate }}: {{ filter?.get('id')?.value }}
              <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
            </mat-chip>
            <mat-chip (removed)="removeValue('task_id')" *ngIf="filter?.get('task_id')?.value">
              {{ "Card ID" | translate }}: {{ filter?.get('task_id')?.value }}
              <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
            </mat-chip>
            <mat-chip (removed)="removeValue('task_operation_id')" *ngIf="filter?.get('task_operation_id')?.value">
              {{ "Job ID" | translate }}: {{ filter?.get('task_operation_id')?.value }}
              <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
            </mat-chip>
            <mat-chip (removed)="removeValue('discussion_id')" *ngIf="filter?.get('discussion_id')?.value">
              {{ "Chat ID" | translate }}: {{ filter?.get('discussion_id')?.value }}
              <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
            </mat-chip> -->
            <mat-chip (removed)="removeValue('content_type_id')" *ngIf="filter.get('content_type_id').value">
                <ng-container *ngIf="filter.get('content_type_id').value.toString() == '0'; else elseTemplateZeroContent">
                    {{ "Content Type" | translate }}: {{ "Not set" | translate }}
                </ng-container>
                <ng-template #elseTemplateZeroContent>
                    {{ "Content Type" | translate }}: {{ getContentById(filter.get('content_type_id').value)?.name }}
                </ng-template>
             
                <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
            </mat-chip>

            <mat-chip (removed)="removeValue('id')" *ngIf="filter.get('id').value">
                {{ "ID" | translate }}: {{ filter.get('id').value }}     
                <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
            </mat-chip>

            <ng-container *ngIf="filter.get('channel_id').value.length">
              <span class="chip_out_label">{{ "Outlets" | translate }}</span>
              <mat-chip [ngClass]="{'user_chip': id != 0}" *ngFor="let id of filter.get('channel_id').value" (removed)="removeValueFromMultiple('channel_id', id)">
                <ng-container *ngIf="id != 0; else elseTemplateOutletNotSet">
                  <app-outlet-line [channel]="getChannelById(id)" [company_id]="company_id"></app-outlet-line>
                </ng-container>
                <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
              </mat-chip>
            </ng-container>

            <ng-container *ngIf="filter.get('group_id').value.length">
                <span class="chip_out_label">{{ "Group" | translate }}</span>
                <mat-chip *ngFor="let id of filter.get('group_id').value" (removed)="removeValueFromMultiple('group_id', id)">
                  <ng-container *ngIf="id === 0; else elseTemplateNoGroup">
                    {{ "Goup not set" | translate }}
                  </ng-container>
                  <ng-template #elseTemplateNoGroup>
                    {{getGroup(id)?.name}}
                  </ng-template>
                  <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
                </mat-chip>
              </ng-container>

            <mat-chip class="user_chip" (removed)="removeValue('platform_id')" *ngIf="filter.get('platform_id').value">
                <div class="platform_wrp">
                  <app-platform [platform_id]="filter.get('platform_id').value"></app-platform>
                  {{ getPlatformById(filter.get('platform_id').value)?.name }}
                </div>
                <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
            </mat-chip>
           
            <mat-icon *ngIf="filter.get('content_type_id').value || filter.get('id').value || filter.get('channel_id').value.length || filter.get('group_id').value.length || filter.get('platform_id').value" class="remove_all_chips_btn" (click)="removeValue('all')">cancel</mat-icon>
          </mat-chip-list>
  
          <div class="stats_wrp ml-1">
            <div class="tasks_counters">
              
              <!-- <form [formGroup]="filter" class="preset_form" *ngIf="bookmarks && bookmarks.length > 0">
                <div class="simple_filter_snippet" (click)="presetFilter.open()">
                  <ng-container *ngIf="filter.get('preset_id').value; else elseTemplatePreset">
                    <span class="name">
                      {{ getBookmarkById(filter.get('preset_id').value)?.name }}
                    </span>
                  </ng-container>
                  <ng-template #elseTemplatePreset>
                    <span class="name">
                      {{ "Bookmark" | translate }}
                    </span>
                  </ng-template>
                  

                  <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_2474_38987)">
                    <path d="M7.91699 3.33325L2.08366 3.33325L5.00033 7.91658L7.91699 3.33325Z" fill="black"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_2474_38987">
                    <rect width="10" height="10" fill="white" transform="translate(10) rotate(90)"/>
                    </clipPath>
                    </defs>
                  </svg>
                </div>

                <mat-form-field appearance="legacy" class="filter_form preset_filter">
                  <mat-label>{{ "Bookmark" | translate }}</mat-label>
                  <mat-select #presetFilter [disableOptionCentering]="true" selectDir formControlName="preset_id" (selectionChange)="bookmarksChange($event)">
                      <mat-option *ngIf="bookmarks.length > 7">
                        <ngx-mat-select-search 
                            placeholderLabel="{{ 'Search' | translate }}" 
                            noEntriesFoundLabel="{{ 'no matching presets found' | translate }}"
                            [formControl]="bookmarksControl"></ngx-mat-select-search>
                      </mat-option>
                      <mat-option *ngFor="let bookmark of bookmarks$ | async" [value]="bookmark.id">
                        {{ bookmark.name | translate }}
                      </mat-option>
                  </mat-select>
                </mat-form-field>
              </form> -->

              <div class="counter_block" *ngIf="pagination && pagination['totalCount']">
                <div class="counter_top">{{ "Regulars" | translate }}</div>
                <div class="counter_bottom">{{pagination['totalCount']}}</div>
                <!-- \{{chats.length}} -->
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="lists_wrp">
        <div class="lists_left">
          <div class="job_status_wrp" [ngClass]="{'active': filter.get('is_daily').value == 0 && filter.get('is_weekly').value == 0 && filter.get('is_monthly').value == 0}">
            <button mat-button (click)='changeActiveStatus("all")' [ngClass]="{'active_status': filter.get('is_daily').value == 0 && filter.get('is_weekly').value == 0 && filter.get('is_monthly').value == 0}">{{ "All" | translate }}</button>
          </div>
          <div class="job_status_wrp" style="margin-left: 25px;" [ngClass]="{'active': filter.get('is_daily').value != 0 || filter.get('is_weekly').value != 0 || filter.get('is_monthly').value != 0}">
            <button mat-button (click)='changeActiveStatus("is_daily")' [ngClass]="{'active_status': filter.get('is_daily').value != 0}">{{ "Daily" | translate }}</button>
            <button mat-button (click)='changeActiveStatus("is_weekly")' [ngClass]="{'active_status': filter.get('is_weekly').value != 0}">{{ "Weekly" | translate }}</button>
            <button mat-button (click)='changeActiveStatus("is_monthly")' [ngClass]="{'active_status': filter.get('is_monthly').value != 0}">{{ "Monthly" | translate }}</button>
          </div>
        </div>
  
        <div class="d-f-c">

        </div>
      </div>   
    </div>
</div>
<div class="container">
    <div class="regulars_page" #regs [appDeviceClass]="'custom-scroll custom-scroll-t'">
        <div class="regular" *ngFor="let regular of regulars; let i = index;">
            <div class="param_info" (click)="regular.isOpen = !regular.isOpen">
                <div class="name_wrp">
                    <div class="name name_col with_dates" (click)="$event.stopPropagation()" (dblclick)="editRegular(regular, i)">
                      <div class="c_u_date">
                        <mat-icon>add</mat-icon>
                        <ng-container *ngIf="regular?.created_at > today && regular?.created_at < todayEnd; else createdAtTempl">
                          <span #tooltip="matTooltip"
                            matTooltip="{{ regular?.created_at*1000 | date:'MMM d, y':'':activeLang | yearPipe }}"
                            [matTooltipPosition]="'above'">
                            {{ regular?.created_at*1000 | date:'hh:mm a':'':activeLang }}
                          </span>
                        </ng-container>
                        <ng-template #createdAtTempl>
                          <span *ngIf="regular?.created_at && regular?.created_at > 30000" #tooltip="matTooltip"
                            matTooltip="{{ regular?.created_at*1000 | date:'hh:mm a':'':activeLang | yearPipe }}"
                            [matTooltipPosition]="'above'">
                            {{ regular?.created_at*1000 | date:'MM.dd.yy':'': activeLang }}
                          </span>
                        </ng-template>

                        <mat-icon class="ml-1">edit</mat-icon>
                        <ng-container *ngIf="regular?.updated_at > today && regular?.updated_at < todayEnd; else updatedAtTempl">
                          <span #tooltip="matTooltip"
                            matTooltip="{{ regular?.updated_at*1000 | date:'MMM d, y':'':activeLang | yearPipe }}"
                            [matTooltipPosition]="'above'">
                            {{ regular?.updated_at*1000 | date:'hh:mm a':'':activeLang }}
                          </span>
                        </ng-container>
                        <ng-template #updatedAtTempl>
                          <span *ngIf="regular?.updated_at && regular?.updated_at > 30000" #tooltip="matTooltip"
                            matTooltip="{{ regular?.updated_at*1000 | date:'hh:mm a':'':activeLang | yearPipe }}"
                            [matTooltipPosition]="'above'">
                            {{ regular?.updated_at*1000 | date:'MM.dd.yy':'': activeLang }}
                          </span>
                        </ng-template>
                      </div>
                        <span class="text_one_line">
                            {{ regular.name }}
                        </span>
                    </div>
                    <div class="content_type outlet_col"> 
                        <app-outlet-line [class]="'s_32'" [channel]="regular?.channel" [company_id]="company_id"></app-outlet-line>          
                    </div>
                    <div class="format format_col">
                        <div *ngIf="regular.is_daily">
                            {{ "Every day" | translate }}
                        </div>
                        <div *ngIf="regular.is_weekly">
                            {{ "Every week" | translate }}
                        </div>
                        <div *ngIf="regular.is_monthly">
                            {{ "Every month" | translate }}
                        </div>
                    </div>
                    <div class="format format_col group_col">
                        <div *ngIf="regular?.group_id" class="text_one_line" #tooltip="matTooltip"
                        matTooltip="{{ 'Connected with group' | translate }}"
                        [matTooltipPosition]="'above'">
                            {{getGroup(regular?.group_id)?.name}}
                        </div>
                    </div>
                    <div class="time_col">
                        <!-- {{ regular?.apply_from_time*1000 | date:'MMM dd. Y, hh:mm':'':activeLang | yearPipe }} - {{ regular?.apply_to_time*1000 | date:'MMM dd. Y, hh:mm':'':activeLang | yearPipe }}
                        <hr>
                        {{ regular?.apply_from_time }} - {{ regular?.apply_to_time }}
                        <hr> -->
                        {{ regular?.apply_from_time*1000 | date:'MMM d, y, h:mm a':'':activeLang | yearPipe }} - {{ regular?.apply_to_time*1000 | date:'MMM d, y, h:mm a':'':activeLang | yearPipe }}
                    </div>
                    <div class="content_type type_col">
                        {{ regular?.contentType?.name }}
                    </div>
                    <div class="content_type slots_col" [ngClass]="{'red': !regular?.items.length}">
                      <span>
                        {{ regular?.items.length }} {{"slots" | translate}}
                      </span>
                      <span class="add" *ngIf="!regular?.items.length" (click)="addRegularItem(regular)">{{'Add slot' | translate}}</span>
                    </div>
                </div>
                <div class="d-f-c">
                    <button class="show_when_hover" mat-icon-button (click)="$event.stopPropagation(); openTargetValues([0, regular.id, 0], regular)">
                      <mat-icon>
                        <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.1875 3.125C2.43614 3.125 2.6746 3.02623 2.85041 2.85041C3.02623 2.6746 3.125 2.43614 3.125 2.1875C3.125 1.93886 3.02623 1.7004 2.85041 1.52459C2.6746 1.34877 2.43614 1.25 2.1875 1.25C1.93886 1.25 1.7004 1.34877 1.52459 1.52459C1.34877 1.7004 1.25 1.93886 1.25 2.1875C1.25 2.43614 1.34877 2.6746 1.52459 2.85041C1.7004 3.02623 1.93886 3.125 2.1875 3.125ZM9.63125 4.7375C9.85625 4.9625 10 5.275 10 5.625C10 5.96875 9.8625 6.28125 9.63125 6.50625L6.50625 9.63125C6.28125 9.85625 5.96875 10 5.625 10C5.28125 10 4.96875 9.8625 4.7375 9.63125L0.36875 5.2625C0.1375 5.03125 0 4.71875 0 4.375V1.25C0 0.55625 0.55625 0 1.25 0H4.375C4.71875 0 5.03125 0.1375 5.25625 0.3625L9.63125 4.7375ZM7.2125 1.06875L7.8375 0.44375L12.1313 4.7375C12.3625 4.9625 12.5 5.28125 12.5 5.625C12.5 5.96875 12.3625 6.28125 12.1375 6.50625L8.775 9.86875L8.15 9.24375L11.7188 5.625L7.2125 1.06875Z" fill="rgb(71, 90, 235)"></path></svg>
                      </mat-icon>
                    </button>
                    <button class="show_when_hover" mat-icon-button style="color: rgb(71, 197, 235);" (click)="$event.stopPropagation(); dublicateRegular(regular, i)" #tooltip="matTooltip"
                    matTooltip="{{ ('Create new regular schedule identical to' | translate) + ' ' + regular.name }}"
                    [matTooltipPosition]="'above'">
                        <mat-icon>content_copy</mat-icon>
                    </button>
                    <button (click)="$event.stopPropagation()" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                      <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                      <button mat-menu-item (click)="randomizeMinutesRegular(regular, i)" *ngIf="!!regular.items && !!regular.items.length">
                        <mat-icon>shuffle_on</mat-icon>
                        <span>{{"Randomize minutes" | translate}}</span>
                      </button>
                      <button mat-menu-item (click)="connectWithGroup(regular, i)">
                        <mat-icon>people</mat-icon>
                        <span>{{"Connect With Group" | translate}}</span>
                      </button>
                      <button mat-menu-item (click)="editRegular(regular, i)">
                        <mat-icon>edit</mat-icon>
                        <span>{{"Edit" | translate}}</span>
                      </button>
                      <button mat-menu-item (click)="deleteRegular(regular, i)">
                        <mat-icon>delete</mat-icon>
                        <span>{{"Delete" | translate}}</span>
                      </button>
                    </mat-menu>
                    <!-- <button mat-icon-button color="primary" (click)="regular.isOpen = !regular.isOpen">
                        <mat-icon>
                            {{ regular.isOpen ? 'expand_less' : 'expand_more' }}
                        </mat-icon>
                    </button> -->
                </div>
            </div>
            <div class="param_values" *ngIf="regular.isOpen">
                <div class="param_value" *ngFor="let val of regular.items; let k = index;">
                    <div class="value_wrp">
                        <ng-container *ngIf="!!val.isEddited; else elseTemplateEddited">
                            <div class="d-f-c">
                                <mat-form-field appearance="standard">
                                    <mat-label>{{ "Regular publication name" | translate }}</mat-label>
                                    <!-- <input matInput type="text" class="value_input" (focusout)="saveValue(param, val)" type="text" id="val_{{param.id}}_{{val.id}}" [(ngModel)]="val.name" (change)="editApiParameterValue(param, val, $event)"> -->
                                    <input matInput type="text" class="value_input" (focusin)="onFocusIn(val)" (focusout)="onFocusOut(val)" type="text" id="val_{{regular.id}}_{{val.id}}" [(ngModel)]="val.name">
                                </mat-form-field>

                                <div class="ngx-timepicker-field-example ml-1">
                                    <ngx-timepicker-field [format]="24" [(ngModel)]="val.timeInFormat"></ngx-timepicker-field>
                                </div>
                    
                                <mat-form-field appearance="standard" class="full_width_form ml-1" *ngIf="!!regular.is_weekly">
                                    <mat-label>{{ "Select day of week" | translate }}</mat-label>
                                    <mat-select #contentTypesContainer [(ngModel)]="val.day_number">
                                        <mat-option *ngFor="let day of weekDays; let m = index;" [value]="m+1">
                                            {{ m+1 }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <mat-form-field appearance="standard" class="full_width_form ml-1" *ngIf="!!regular.is_monthly">
                                    <mat-label>{{ "Select day of month" | translate }}</mat-label>
                                    <mat-select #contentTypesContainer [(ngModel)]="val.day_number">
                                        <mat-option *ngFor="let day of monthDays; let m = index;" [value]="m+1">
                                            {{ m+1 }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                   
                            </div>
                           
                            <button mat-icon-button style="color: blue;" (click)="saveRegularItem(regular, val)">
                                <mat-icon>done</mat-icon>
                            </button>
                        </ng-container>
                        <ng-template #elseTemplateEddited>
                            <div class="d-f-c with_dates">
                                <div class="value text_one_line" (dblclick)="focusValue(regular, val)">
                                    {{val.name}}
                                </div>
                                <div class="c_u_date" style="bottom: unset !important; top: 26px !important;">
                                  <mat-icon>add</mat-icon>
                                  <ng-container *ngIf="val?.created_at > today && val?.created_at < todayEnd; else createdAtTempl">
                                    <span #tooltip="matTooltip"
                                      matTooltip="{{ val?.created_at*1000 | date:'MMM d, y':'':activeLang | yearPipe }}"
                                      [matTooltipPosition]="'above'">
                                      {{ val?.created_at*1000 | date:'hh:mm a':'':activeLang }}
                                    </span>
                                  </ng-container>
                                  <ng-template #createdAtTempl>
                                    <span *ngIf="val?.created_at && val?.created_at > 30000" #tooltip="matTooltip"
                                      matTooltip="{{ val?.created_at*1000 | date:'hh:mm a':'':activeLang | yearPipe }}"
                                      [matTooltipPosition]="'above'">
                                      {{ val?.created_at*1000 | date:'MM.dd.yy':'': activeLang }}
                                    </span>
                                  </ng-template>

                                  <mat-icon class="ml-1">edit</mat-icon>
                                  <ng-container *ngIf="val?.updated_at > today && val?.updated_at < todayEnd; else updatedAtTempl">
                                    <span #tooltip="matTooltip"
                                      matTooltip="{{ val?.updated_at*1000 | date:'MMM d, y':'':activeLang | yearPipe }}"
                                      [matTooltipPosition]="'above'">
                                      {{ val?.updated_at*1000 | date:'hh:mm a':'':activeLang }}
                                    </span>
                                  </ng-container>
                                  <ng-template #updatedAtTempl>
                                    <span *ngIf="val?.updated_at && val?.updated_at > 30000" #tooltip="matTooltip"
                                      matTooltip="{{ val?.updated_at*1000 | date:'hh:mm a':'':activeLang | yearPipe }}"
                                      [matTooltipPosition]="'above'">
                                      {{ val?.updated_at*1000 | date:'MM.dd.yy':'': activeLang }}
                                    </span>
                                  </ng-template>
                                </div>
                                <div class="ngx-timepicker-field-example ml-1">
                                    <ngx-timepicker-field [format]="24" [disabled]="true" [(ngModel)]="val.timeInFormat"></ngx-timepicker-field>
                                </div>
                                <div class="ml-1 format format_col group_col" style="width: calc(10% + 36px); min-width: calc(10% + 36px);">
                                  
                                </div>
                                <div class="ml-1 format format_col group_col" style="width: calc(10% + 36px); min-width: calc(10% + 36px);">
                                  <div *ngIf="val?.group_id" class="text_one_line">
                                      {{getGroup(val?.group_id)?.name}}
                                  </div>
                                </div>
                            </div>

                            <div class="d-f-c">
                              <button class="show_when_hover_item" mat-icon-button (click)="openTargetValues([0, 0, val.id], val)">
                                <mat-icon>
                                  <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.1875 3.125C2.43614 3.125 2.6746 3.02623 2.85041 2.85041C3.02623 2.6746 3.125 2.43614 3.125 2.1875C3.125 1.93886 3.02623 1.7004 2.85041 1.52459C2.6746 1.34877 2.43614 1.25 2.1875 1.25C1.93886 1.25 1.7004 1.34877 1.52459 1.52459C1.34877 1.7004 1.25 1.93886 1.25 2.1875C1.25 2.43614 1.34877 2.6746 1.52459 2.85041C1.7004 3.02623 1.93886 3.125 2.1875 3.125ZM9.63125 4.7375C9.85625 4.9625 10 5.275 10 5.625C10 5.96875 9.8625 6.28125 9.63125 6.50625L6.50625 9.63125C6.28125 9.85625 5.96875 10 5.625 10C5.28125 10 4.96875 9.8625 4.7375 9.63125L0.36875 5.2625C0.1375 5.03125 0 4.71875 0 4.375V1.25C0 0.55625 0.55625 0 1.25 0H4.375C4.71875 0 5.03125 0.1375 5.25625 0.3625L9.63125 4.7375ZM7.2125 1.06875L7.8375 0.44375L12.1313 4.7375C12.3625 4.9625 12.5 5.28125 12.5 5.625C12.5 5.96875 12.3625 6.28125 12.1375 6.50625L8.775 9.86875L8.15 9.24375L11.7188 5.625L7.2125 1.06875Z" fill="rgb(71, 90, 235)"></path></svg>
                                </mat-icon>
                              </button>
                                <button class="show_when_hover_item" mat-icon-button style="color: green;" (click)="focusValue(regular, val)">
                                    <mat-icon>edit</mat-icon>
                                </button>
                                <button mat-icon-button [matMenuTriggerFor]="menuItem" aria-label="Example icon-button with a menu">
                                  <mat-icon>more_vert</mat-icon>
                                </button>
                                <mat-menu #menuItem="matMenu">
                                  <button mat-menu-item (click)="connectWithGroup(regular, i, val)">
                                    <mat-icon>people</mat-icon>
                                    <span>{{"Connect With Group" | translate}}</span>
                                  </button>
                                  <button mat-menu-item (click)="deleteRegularItem(regular, val, k)">
                                    <mat-icon>delete</mat-icon>
                                    <span>{{"Delete" | translate}}</span>
                                  </button>
                                </mat-menu>
                            </div>
                        </ng-template>
                        
                    </div>
                
                </div>
                <div class="d-f-c" style="justify-content: flex-end;">
                    <button class="add_btn" mat-raised-button (click)="regular.isOpen = !regular.isOpen">
                        {{ "Collapse" | translate }}
                    </button>
                    <button class="add_btn ml-1" mat-raised-button color="primary" (click)="addRegularItem(regular)">
                        {{ "Add slot" | translate }}
                    </button>
                </div>
            </div>
        </div>

    </div>
    <div class="btns_line">
        <button (click)="goBack($event)" mat-stroked-button>
            {{ "Back" | translate }}
        </button>
        <button class="ml-1" mat-raised-button color="primary" (click)="addRegular()">
            <!-- <mat-icon>add</mat-icon> -->
            {{ "Add regular schedule" | translate }}
        </button>
    </div>
</div>
<mat-progress-bar mode="indeterminate" *ngIf="isLoad" class="mat_proggress_w8"></mat-progress-bar>
