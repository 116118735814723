<div mat-dialog-title class="mobile_interface_title">
    <div class="mobile_interface_title_in" style="overflow: hidden;">
        <div class="tab_title" (click)="close()">
            <div class="d-f-c">
                <div class="icon_back">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7 12L6.11612 11.1161C5.62796 11.6043 5.62796 12.3957 6.11612 12.8839L7 12ZM16.8839 3.88388C17.372 3.39573 17.372 2.60427 16.8839 2.11612C16.3957 1.62796 15.6043 1.62796 15.1161 2.11612L16.8839 3.88388ZM15.1161 21.8839C15.6043 22.372 16.3957 22.372 16.8839 21.8839C17.372 21.3957 17.372 20.6043 16.8839 20.1161L15.1161 21.8839ZM7.88388 12.8839L16.8839 3.88388L15.1161 2.11612L6.11612 11.1161L7.88388 12.8839ZM6.11612 12.8839L15.1161 21.8839L16.8839 20.1161L7.88388 11.1161L6.11612 12.8839Z" fill="#232323"/>
                    </svg>
                </div>
                <span>
                    {{ "Search" | translate }}
                </span>
            </div>
        </div>
    </div>
</div>
<mat-dialog-content class="mobile_interface_content">
    <div class="mobile_scrollable_int_big mobile_search_filter" style="background: #fff;">
        <form [formGroup]="data.filter" class="exp_form has_pads" (ngSubmit)="onSubmit()">
            <mat-form-field class="search-field" appearance="legacy">
              <mat-label>{{ "Search everywhere" | translate }}</mat-label>
              <input autocomplete="off" matInput formControlName="q" #searchEverywhere>
              <mat-icon style="color: #686868" matSuffix>search</mat-icon>
            </mat-form-field>
            <ng-container [ngSwitch]="activeSections()">
              <ng-container *ngSwitchCase="'Cards'">
                  <div style="padding-top: 10px;"></div>
                  <mat-form-field class="search-field" appearance="legacy">
                  <mat-label>{{ "Card Title" | translate }}*</mat-label>
                  <input autocomplete="off" matInput formControlName="q_task_name">
                  </mat-form-field>
                  <mat-form-field class="search-field" appearance="legacy">
                  <mat-label>{{ "Card description" | translate }}*</mat-label>
                  <input autocomplete="off" matInput formControlName="q_task_comment">
                  </mat-form-field>
                  <mat-form-field class="search-field" appearance="legacy">
                  <mat-label>{{ "Card private comment" | translate }}*</mat-label>
                  <input autocomplete="off" matInput formControlName="q_task_private_comment">
                  </mat-form-field>
                  <div class="d-f-c search-field-group">
                  <mat-form-field class="search-field" appearance="legacy">
                      <mat-label>{{ "Card custom ID" | translate }}*</mat-label>
                      <input autocomplete="off" type="number" matInput formControlName="task_custom_id">
                  </mat-form-field>
                  <mat-form-field class="search-field ml-1" appearance="legacy">
                      <mat-label>{{ "Card internal ID" | translate }}*</mat-label>
                      <input autocomplete="off" type="number" matInput formControlName="internal_id">
                  </mat-form-field>
                  <mat-form-field class="search-field ml-1" appearance="legacy">
                      <mat-label>{{ "Card system ID" | translate }}*</mat-label>
                      <input autocomplete="off" type="number" matInput formControlName="id">
                  </mat-form-field>
                  </div>
                  <mat-form-field class="search-field" appearance="legacy">
                  <mat-label>{{ "Card workspace ID" | translate }}*</mat-label>
                  <input autocomplete="off" type="number" matInput formControlName="task_company_id">
                  </mat-form-field>
                  
                  <div style="padding-top: 10px;"></div>
                  
                  <mat-form-field class="search-field" appearance="legacy">
                  <mat-label>{{ "Job title" | translate }}*</mat-label>
                  <input autocomplete="off" matInput formControlName="q_task_operation_comment">
                  </mat-form-field>
                  <mat-form-field class="search-field" appearance="legacy">
                  <mat-label>{{ "Job executor commment" | translate }}*</mat-label>
                  <input autocomplete="off" matInput formControlName="q_task_operation_employee_comment">
                  </mat-form-field>
                  <mat-form-field class="search-field" appearance="legacy">
                  <mat-label>{{ "Job private comment" | translate }}*</mat-label>
                  <input autocomplete="off" matInput formControlName="q_task_operation_private_comment">
                  </mat-form-field>
                  <mat-form-field class="search-field" appearance="legacy">
                  <mat-label>{{ "Job System ID" | translate }}*</mat-label>
                  <input autocomplete="off" type="number" matInput formControlName="task_operation_id">
                  </mat-form-field>
          
                  <div style="padding-top: 10px;"></div>
                  <mat-form-field class="search-field" appearance="legacy">
                  <mat-label>{{ "Chat system ID" | translate }}*</mat-label>
                  <input autocomplete="off" type="number" matInput formControlName="discussion_id">
                  </mat-form-field>

                  <div style="padding-top: 10px;"></div>
                  <mat-form-field class="search-field" appearance="legacy">
                    <mat-label>{{ "Posts filename" | translate }}*</mat-label>
                    <input autocomplete="off" matInput formControlName="q_task_channel_content_filename">
                  </mat-form-field>
              </ng-container>
              <ng-container *ngSwitchCase="'Chats'">
                  <mat-form-field class="search-field" appearance="legacy">
                      <mat-label>{{ "Chat title" | translate }}*</mat-label>
                      <input matInput formControlName="q_name">
                    </mat-form-field>
                    <mat-form-field class="search-field" appearance="legacy">
                      <mat-label>{{ "Chat messages" | translate }}*</mat-label>
                      <input matInput formControlName="q_text">
                    </mat-form-field>
              </ng-container>

            </ng-container>
        </form>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <div class="mobile_interface_buttons small_mob_btns" style="justify-content: space-between !important;">
        <button class="mobile_interface_btn" (click)="close()">
            {{ "Cancel" | translate }}
        </button>
        
        <button class="mobile_interface_btn mobile_interface_btn_blue" (click)="onSubmit()">
            {{ "Search" | translate }}
        </button>
    </div>
</mat-dialog-actions>