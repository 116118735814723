<div mat-dialog-title class="mobile_interface_title">
    <div class="mobile_interface_title_in" style="overflow: hidden;">
        <ng-container [ngSwitch]="selectedIndex.value">
            <ng-container *ngSwitchCase="0">
                <mat-tab-group class="title_tabs no_header" style="width: 100%;" [selectedIndex]="aboutIndex.value">
                    <mat-tab>
                        <div class="tab_title">
                            <span>
                                {{ "Add Card" | translate }}
                            </span>
                            <span class="small_title">2 / 7 — {{ "ABOUT THE CARD" | translate }}</span>
                        </div>
                    </mat-tab>
                    <mat-tab>
                        <div class="tab_title" (click)="backAbout()">
                            <div class="d-f-c">
                                <div class="icon_back">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7 12L6.11612 11.1161C5.62796 11.6043 5.62796 12.3957 6.11612 12.8839L7 12ZM16.8839 3.88388C17.372 3.39573 17.372 2.60427 16.8839 2.11612C16.3957 1.62796 15.6043 1.62796 15.1161 2.11612L16.8839 3.88388ZM15.1161 21.8839C15.6043 22.372 16.3957 22.372 16.8839 21.8839C17.372 21.3957 17.372 20.6043 16.8839 20.1161L15.1161 21.8839ZM7.88388 12.8839L16.8839 3.88388L15.1161 2.11612L6.11612 11.1161L7.88388 12.8839ZM6.11612 12.8839L15.1161 21.8839L16.8839 20.1161L7.88388 11.1161L6.11612 12.8839Z" fill="#232323"/>
                                    </svg>
                                </div>
                                <span>
                                    {{ "Brief description" | translate }}
                                </span>
                            </div>
                            <span class="small_title with_i">2 / 7 — {{ "ABOUT THE CARD" | translate }}</span>
                        </div>
                    </mat-tab>
                    <mat-tab>
                        <div class="tab_title" (click)="backAbout()">
                            <div class="d-f-c">
                                <div class="icon_back">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7 12L6.11612 11.1161C5.62796 11.6043 5.62796 12.3957 6.11612 12.8839L7 12ZM16.8839 3.88388C17.372 3.39573 17.372 2.60427 16.8839 2.11612C16.3957 1.62796 15.6043 1.62796 15.1161 2.11612L16.8839 3.88388ZM15.1161 21.8839C15.6043 22.372 16.3957 22.372 16.8839 21.8839C17.372 21.3957 17.372 20.6043 16.8839 20.1161L15.1161 21.8839ZM7.88388 12.8839L16.8839 3.88388L15.1161 2.11612L6.11612 11.1161L7.88388 12.8839ZM6.11612 12.8839L15.1161 21.8839L16.8839 20.1161L7.88388 11.1161L6.11612 12.8839Z" fill="#232323"/>
                                    </svg>
                                </div>
                                <span>
                                    {{ "Hidden notes" | translate }}
                                </span>
                            </div>
                            <span class="small_title with_i">2 / 7 — {{ "ABOUT THE CARD" | translate }}</span>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </ng-container>
            <ng-container *ngSwitchCase="1">
                <div class="tab_title">
                    <span>
                        {{ "Add Card" | translate }}
                    </span>
                    <div class="small_title">3 / 7 — {{ "PRIORITY" | translate }}</div>
                </div>
            </ng-container>
            <ng-container *ngSwitchCase="2">
                <div class="tab_title">
                    <span>
                        {{ "Add Card" | translate }}
                    </span>
                    <div class="small_title">4 / 7 — {{ "GROUP" | translate }}</div>
                </div>
            </ng-container>
            <ng-container *ngSwitchCase="3">
                <div class="tab_title">
                    <span>
                        {{ "Add Card" | translate }}
                    </span>
                    <div class="small_title">
                        5 / 7 — {{ "CUSTOM ID" | translate }}
                    </div>
                </div>
            </ng-container>
            <ng-container *ngSwitchCase="4">
                <div class="tab_title">
                    <span>
                        {{ "Add Card" | translate }}
                    </span>
                    <div class="small_title">
                        6 / 7 — {{ "CARD TYPE" | translate }}
                    </div>
                </div>
            </ng-container>
            <ng-container *ngSwitchCase="5">
                <mat-tab-group class="title_tabs no_header" style="width: 100%;" [selectedIndex]="relationsIndex.value">
                    <mat-tab>
                        <div class="tab_title">
                            <!-- <div class="icon_back">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7 12L6.11612 11.1161C5.62796 11.6043 5.62796 12.3957 6.11612 12.8839L7 12ZM16.8839 3.88388C17.372 3.39573 17.372 2.60427 16.8839 2.11612C16.3957 1.62796 15.6043 1.62796 15.1161 2.11612L16.8839 3.88388ZM15.1161 21.8839C15.6043 22.372 16.3957 22.372 16.8839 21.8839C17.372 21.3957 17.372 20.6043 16.8839 20.1161L15.1161 21.8839ZM7.88388 12.8839L16.8839 3.88388L15.1161 2.11612L6.11612 11.1161L7.88388 12.8839ZM6.11612 12.8839L15.1161 21.8839L16.8839 20.1161L7.88388 11.1161L6.11612 12.8839Z" fill="#232323"/>
                                </svg>
                            </div> -->
                            <span>
                                {{ "Add Card" | translate }}
                            </span>
                            <span class="small_title">7 / 7 — {{ "RELATIONS TO CARDS" | translate }}</span>
                        </div>
                    </mat-tab>
                    <mat-tab>
                        <div class="tab_title" (click)="backRelations()">
                            <div class="d-f-c">
                                <div class="icon_back">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7 12L6.11612 11.1161C5.62796 11.6043 5.62796 12.3957 6.11612 12.8839L7 12ZM16.8839 3.88388C17.372 3.39573 17.372 2.60427 16.8839 2.11612C16.3957 1.62796 15.6043 1.62796 15.1161 2.11612L16.8839 3.88388ZM15.1161 21.8839C15.6043 22.372 16.3957 22.372 16.8839 21.8839C17.372 21.3957 17.372 20.6043 16.8839 20.1161L15.1161 21.8839ZM7.88388 12.8839L16.8839 3.88388L15.1161 2.11612L6.11612 11.1161L7.88388 12.8839ZM6.11612 12.8839L15.1161 21.8839L16.8839 20.1161L7.88388 11.1161L6.11612 12.8839Z" fill="#232323"/>
                                    </svg>
                                </div>
                                <span>
                                    {{ "Consist of" | translate }}
                                </span>
                            </div>
                            <span class="small_title with_i">7 / 7 — {{ "RELATIONS TO CARDS" | translate }}</span>
                        </div>
                    </mat-tab>
                    <mat-tab>
                        <div class="tab_title" (click)="backRelations()">
                            <div class="d-f-c">
                                <div class="icon_back">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7 12L6.11612 11.1161C5.62796 11.6043 5.62796 12.3957 6.11612 12.8839L7 12ZM16.8839 3.88388C17.372 3.39573 17.372 2.60427 16.8839 2.11612C16.3957 1.62796 15.6043 1.62796 15.1161 2.11612L16.8839 3.88388ZM15.1161 21.8839C15.6043 22.372 16.3957 22.372 16.8839 21.8839C17.372 21.3957 17.372 20.6043 16.8839 20.1161L15.1161 21.8839ZM7.88388 12.8839L16.8839 3.88388L15.1161 2.11612L6.11612 11.1161L7.88388 12.8839ZM6.11612 12.8839L15.1161 21.8839L16.8839 20.1161L7.88388 11.1161L6.11612 12.8839Z" fill="#232323"/>
                                    </svg>
                                </div>
                                <span>
                                    {{ "Part of" | translate }}
                                </span>
                            </div>
                            <span class="small_title with_i">7 / 7 — {{ "RELATIONS TO CARDS" | translate }}</span>
                        </div>
                    </mat-tab>
                    <mat-tab>
                        <div class="tab_title" (click)="backRelations()">
                            <div class="d-f-c">
                                <div class="icon_back">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7 12L6.11612 11.1161C5.62796 11.6043 5.62796 12.3957 6.11612 12.8839L7 12ZM16.8839 3.88388C17.372 3.39573 17.372 2.60427 16.8839 2.11612C16.3957 1.62796 15.6043 1.62796 15.1161 2.11612L16.8839 3.88388ZM15.1161 21.8839C15.6043 22.372 16.3957 22.372 16.8839 21.8839C17.372 21.3957 17.372 20.6043 16.8839 20.1161L15.1161 21.8839ZM7.88388 12.8839L16.8839 3.88388L15.1161 2.11612L6.11612 11.1161L7.88388 12.8839ZM6.11612 12.8839L15.1161 21.8839L16.8839 20.1161L7.88388 11.1161L6.11612 12.8839Z" fill="#232323"/>
                                    </svg>
                                </div>
                                <span>
                                    {{ "Related" | translate }}
                                </span>
                            </div>
                            <span class="small_title with_i">7 / 7 — {{ "RELATIONS TO CARDS" | translate }}</span>
                        </div>
                    </mat-tab>
                    <mat-tab>
                        <div class="tab_title" (click)="backRelations()">
                            <div class="d-f-c">
                                <div class="icon_back">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7 12L6.11612 11.1161C5.62796 11.6043 5.62796 12.3957 6.11612 12.8839L7 12ZM16.8839 3.88388C17.372 3.39573 17.372 2.60427 16.8839 2.11612C16.3957 1.62796 15.6043 1.62796 15.1161 2.11612L16.8839 3.88388ZM15.1161 21.8839C15.6043 22.372 16.3957 22.372 16.8839 21.8839C17.372 21.3957 17.372 20.6043 16.8839 20.1161L15.1161 21.8839ZM7.88388 12.8839L16.8839 3.88388L15.1161 2.11612L6.11612 11.1161L7.88388 12.8839ZM6.11612 12.8839L15.1161 21.8839L16.8839 20.1161L7.88388 11.1161L6.11612 12.8839Z" fill="#232323"/>
                                    </svg>
                                </div>
                                <span>
                                    {{ "Task Thumbnail Settings" | translate }}
                                </span>
                            </div>
                            <span class="small_title with_i">7 / 7 — {{ "RELATIONS TO CARDS" | translate }}</span>
                        </div>
                    </mat-tab>
                    <mat-tab>
                        <div class="tab_title" (click)="smartBackRelations()">
                            <div class="d-f-c">
                                <div class="icon_back">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7 12L6.11612 11.1161C5.62796 11.6043 5.62796 12.3957 6.11612 12.8839L7 12ZM16.8839 3.88388C17.372 3.39573 17.372 2.60427 16.8839 2.11612C16.3957 1.62796 15.6043 1.62796 15.1161 2.11612L16.8839 3.88388ZM15.1161 21.8839C15.6043 22.372 16.3957 22.372 16.8839 21.8839C17.372 21.3957 17.372 20.6043 16.8839 20.1161L15.1161 21.8839ZM7.88388 12.8839L16.8839 3.88388L15.1161 2.11612L6.11612 11.1161L7.88388 12.8839ZM6.11612 12.8839L15.1161 21.8839L16.8839 20.1161L7.88388 11.1161L6.11612 12.8839Z" fill="#232323"/>
                                    </svg>
                                </div>
                                <span>
                                    {{ "Search within a group" | translate }}
                                </span>
                            </div>
                            <span class="small_title with_i">7 / 7 — {{ "RELATIONS TO CARDS" | translate }}</span>
                        </div>
                    </mat-tab>
                    <mat-tab>
                        <div class="tab_title" (click)="smartBackRelations()">
                            <div class="d-f-c">
                                <div class="icon_back">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7 12L6.11612 11.1161C5.62796 11.6043 5.62796 12.3957 6.11612 12.8839L7 12ZM16.8839 3.88388C17.372 3.39573 17.372 2.60427 16.8839 2.11612C16.3957 1.62796 15.6043 1.62796 15.1161 2.11612L16.8839 3.88388ZM15.1161 21.8839C15.6043 22.372 16.3957 22.372 16.8839 21.8839C17.372 21.3957 17.372 20.6043 16.8839 20.1161L15.1161 21.8839ZM7.88388 12.8839L16.8839 3.88388L15.1161 2.11612L6.11612 11.1161L7.88388 12.8839ZM6.11612 12.8839L15.1161 21.8839L16.8839 20.1161L7.88388 11.1161L6.11612 12.8839Z" fill="#232323"/>
                                    </svg>
                                </div>
                                <span>
                                    {{ "Where to search" | translate }}
                                </span>
                            </div>
                            <span class="small_title with_i">7 / 7 — {{ "RELATIONS TO CARDS" | translate }}</span>
                        </div>
                    </mat-tab>
         
                </mat-tab-group>
            </ng-container>
            <ng-container *ngSwitchDefault>
                <div class="d-f-c close_title">
                    <!-- <div class="icon_back" (click)="close()">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7 12L6.11612 11.1161C5.62796 11.6043 5.62796 12.3957 6.11612 12.8839L7 12ZM16.8839 3.88388C17.372 3.39573 17.372 2.60427 16.8839 2.11612C16.3957 1.62796 15.6043 1.62796 15.1161 2.11612L16.8839 3.88388ZM15.1161 21.8839C15.6043 22.372 16.3957 22.372 16.8839 21.8839C17.372 21.3957 17.372 20.6043 16.8839 20.1161L15.1161 21.8839ZM7.88388 12.8839L16.8839 3.88388L15.1161 2.11612L6.11612 11.1161L7.88388 12.8839ZM6.11612 12.8839L15.1161 21.8839L16.8839 20.1161L7.88388 11.1161L6.11612 12.8839Z" fill="#232323"/>
                        </svg>
                    </div> -->
                    <span>
                        {{ "Add Card" | translate }}
                    </span>
                </div>
            </ng-container>
        </ng-container>
        <div class="selected_company" (click)="change()" *ngIf="aboutIndex.value == 0 && relationsIndex.value == 0">
            <app-user [class]="'user_div'" [companyEmployee]="data.company" [imgRoute]="data?.imgRoute" [host]="data?.host" [company]="data?.company"></app-user>
            <svg class="ml-1" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_2864_45355c1)">
                <path d="M7.91602 3.33333L2.08268 3.33333L4.99935 7.91666L7.91602 3.33333Z" fill="#686868"/>
                </g>
                <defs>
                <clipPath id="clip0_2864_45355c1">
                <rect width="10" height="10" fill="white" transform="translate(10) rotate(90)"/>
                </clipPath>
                </defs>
            </svg>
        </div>
    </div>
</div>
<mat-dialog-content class="mobile_interface_content">
    <mat-tab-group class="no_header" [selectedIndex]="selectedIndex.value" (selectedIndexChange)="changeIndex($event)">
        <mat-tab>
            <mat-tab-group class="no_header" [selectedIndex]="aboutIndex.value" (selectedIndexChange)="changeAboutTab($event)">
                <mat-tab>
                    <div class="mobile_scrollable_int">
                        <form [formGroup]="creating" class="has_pads form_with_valids" *ngIf="data.is_not_assigned">
                            <div class="white_wrp has_pads">
                                <mat-form-field appearance="standard" class="full_width_form">
                                    <ng-container *ngIf="!creating.get('name').valid && submited">
                                        <div class="req_field"></div>
                                        <div class="req_label">
                                            <div class="angle"></div>
                                            <span>
                                                {{ "You need to write here" | translate }}
                                            </span>
                                        </div>
                                    </ng-container>
                                    <mat-label>{{ 'Creating cards request title' | translate }}</mat-label>
                                    <input formControlName="name" matInput placeholder="{{ 'Title' | translate }}">
                                </mat-form-field>
                            </div>
                        </form>

                        <form [formGroup]="form" class="form_with_valids has_pads">
                            <div class="white_wrp has_pads">
                                <ng-container *ngIf="!data.isMulti; else elseTemplateDataIsMulti">
                                    <mat-form-field appearance="standard" class="full_width_form">
                                        <ng-container *ngIf="!form.get('name').valid && submited">
                                            <div class="req_field"></div>
                                            <div class="req_label">
                                                <div class="angle"></div>
                                                <span>
                                                    {{ "You need to write here" | translate }}
                                                </span>
                                            </div>
                                        </ng-container>
                                        <mat-label>{{ 'Task title' | translate }}</mat-label>
                                        <input formControlName="name" matInput placeholder="{{ 'Name' | translate }}">
                                    </mat-form-field>
                                </ng-container>
                                <ng-template #elseTemplateDataIsMulti>
                                    <ng-container *ngIf="!!data.is_not_assigned; else elseTemplateNotAssignTitle">
                                        <mat-form-field appearance="standard" class="full_width_form">
                                            <mat-select formControlName="name">
                                                <mat-option [value]="'{video_name}'">
                                                    {{ "Video title" | translate }}
                                                </mat-option>
                                                <mat-option [value]="'{video_filename}'">
                                                    {{ "File name" | translate }}
                                                </mat-option>
                                                <mat-option [value]="'{video_name} {video_filename}'">
                                                    {{ "Video title + file name" | translate }}
                                                </mat-option>
                                                <mat-option [value]="'{video_filename} {video_name}'">
                                                    {{ "File name + video title" | translate }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </ng-container>
                                    <ng-template #elseTemplateNotAssignTitle>
                                        <mat-form-field appearance="standard" class="full_width_form">
                                            <mat-label>{{ 'Task title' | translate }}</mat-label>
                                            <input style="color: red;" [disabled]="true" value="{{ 'Tasks title will be equal to Video title' | translate }}" matInput placeholder="{{ 'Task title' | translate }}">
                                        </mat-form-field>
                                    </ng-template>
                                </ng-template>
       
                            </div>
                            <div class="white_wrp transp" style="margin-top: 40px !important;">
    
                                <div class="form_switch has_active" (click)="changeAboutTab(1)">
                                    <div class="d-f-c" style="justify-content: space-between !important;">
                                        <div class="d-f-c">
                                            <div class="name">Brief description</div>
                                        </div>
                                        <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0 10.59L4.59 6L0 1.41L1.42 0L7.42 6L1.42 12L0 10.59Z" fill="black"/>
                                        </svg>
                                    </div>
                                    <div class="active_values" *ngIf="form.get('comment').value">
                                        <span class="text_one_line">{{form.get('comment').value}}</span>
                                    </div>
                                </div>
        
                                <div class="form_switch has_active" (click)="changeAboutTab(2)">
                                    <div class="d-f-c" style="justify-content: space-between !important;">
                                        <div class="d-f-c" style="flex-direction: column; width: calc(100% - 20px); align-items: flex-start !important;">
                                            <div class="name">{{ "Hidden notes" | translate }}</div>
                                            <span>({{ "can see managers and above" | translate }})</span>
                                        </div>
            
                                        <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0 10.59L4.59 6L0 1.41L1.42 0L7.42 6L1.42 12L0 10.59Z" fill="black"/>
                                        </svg>
                                    </div>
                                    <div class="active_values" *ngIf="form.get('private_comment').value">
                                        <span class="text_one_line">{{form.get('private_comment').value}}</span>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </mat-tab>
                <mat-tab>
                    <div class="mobile_scrollable_int">
                        <form [formGroup]="form" class="form_with_valids has_pads">
                            <div class="white_wrp">
                                <mat-form-field appearance="standard" class="full_width_form">
                                    <textarea 
                                    cdkTextareaAutosize
                                    #autosize="cdkTextareaAutosize"
                                    cdkAutosizeMinRows="5"
                                    cdkAutosizeMaxRows="10" formControlName="comment" matInput></textarea>
                                </mat-form-field>
                            </div>
                        </form>
                    </div>
                </mat-tab>
                <mat-tab>
                    <div class="mobile_scrollable_int">
                        <form [formGroup]="form" class="form_with_valids has_pads">
                            <div class="white_wrp">
                                <mat-form-field appearance="standard" class="full_width_form">
                                    <textarea 
                                    cdkTextareaAutosize
                                    #autosize="cdkTextareaAutosize"
                                    cdkAutosizeMinRows="5"
                                    cdkAutosizeMaxRows="10" formControlName="private_comment" matInput></textarea>
                                </mat-form-field>
                            </div>
                        </form>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </mat-tab>
        <mat-tab>
            <div class="mobile_scrollable_int">
                <form [formGroup]="form" class="has_pads form_with_valids">
                    <div class="priority_chips">
                        <mat-chip-list [multiple]="false" selectable formControlName="priority">
                            <mat-chip *ngFor="let priority of prioritys" [ngClass]="{
                                'Urgent': priority.id == '1000',
                                'High': priority.id == '900',
                                'Middle': priority.id == '800',
                                'Low': priority.id == '700',
                                'No': priority.id == '0'
                            }" class="priority_chip" (click)="selectPrioritys(priority)" [value]="priority.id">
                                {{ priority.name | translate }}
                            </mat-chip>
                        </mat-chip-list>
                    </div>
                </form>
            </div>
        </mat-tab>
        <mat-tab>
            <div class="mobile_scrollable_int">
                <form [formGroup]="form" class="has_pads form_with_valids">
                    <div class="group_chips small_chips" *ngIf="groups?.length > 0">
                        <!-- <label>{{ "Group" | translate }}: </label> -->
                        <mat-chip-list [multiple]="false" selectable formControlName="group_id">
                            <ng-container *ngFor="let group of groups; let i = index">
                                <mat-chip class="small_chip" style="margin-top: 28px !important;" (click)="selectGroup(group)" [value]="group?.id">
                                    {{ group.name | translate }}
                                </mat-chip>
                                <mat-chip *ngIf="i == groups?.length - 1 && i > 6" style="margin-top: 28px !important;" class="small_chip more" (click)="groupIdContainer.open()">
                                    {{ "More..." | translate }}
                                </mat-chip>
                            </ng-container>
                            
                        </mat-chip-list>
                    </div>
        
                    <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                        <mat-select #groupIdContainer formControlName="group_id" (selectionChange)="selectGroup($event)">
                            <mat-option>
                                <ngx-mat-select-search 
                                placeholderLabel="{{ 'Search' | translate }}" 
                                noEntriesFoundLabel="{{ 'no matching group found' | translate }}"
                                [formControl]="groupMoreControl"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let group of groups$ | async" [value]="group.id">
                                {{ group.name | translate }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </form>
            </div>
        </mat-tab>
        <mat-tab *ngIf="!data.isMulti">
            <div class="mobile_scrollable_int">
                <form [formGroup]="form" class="has_pads form_with_valids">
                    <div class="white_wrp">
                        <mat-form-field appearance="standard" class="full_width_form number_mat">
                            <mat-label>{{ 'Custom ID' | translate }}</mat-label>
                            <input formControlName="custom_id" matInput type="number" placeholder="{{ 'Custom id' | translate }}">
                            <mat-hint><div class="paste_data" *ngIf="isGetId" (click)="pasteIdVal()" style="cursor: pointer;"><mat-icon>content_paste</mat-icon>{{ customIdValue }}</div></mat-hint>
                        </mat-form-field>
                    </div>
                </form>
            </div>
        </mat-tab>
        <mat-tab>
            <div class="mobile_scrollable_int">
                <form [formGroup]="form" class="has_pads form_with_valids">
                    <div class="small_chips group_chips" *ngIf="statuses?.length > 0" style="padding-top: 10px;">
                        <ng-container *ngIf="!form.get('status_id').valid && submited">
                            <div class="req_field"></div>
                            <div class="req_label">
                                <div class="angle"></div>
                                <span>
                                    {{ "You need to choose a value" | translate }}
                                </span>
                            </div>
                        </ng-container>
                        <mat-chip-list [multiple]="false" selectable formControlName="status_id">
                            <ng-container *ngFor="let status of statuses; let i = index">
                                <mat-chip class="small_chip" (click)="selectType(status)" [value]="status?.id">
                                    {{ status.name | translate }}
                                </mat-chip>
                                <mat-chip *ngIf="i == statuses?.length - 1 && i > 6" class="small_chip more" (click)="showMoreTypes()">
                                    {{ "More..." | translate }}
                                </mat-chip>
                            </ng-container>
                            
                        </mat-chip-list>
                    </div>   
                </form>
            </div>
        </mat-tab>
        <mat-tab>
            <mat-tab-group class="no_header" [selectedIndex]="relationsIndex.value" (selectedIndexChange)="changeRelationsTab($event)">
                <mat-tab>
                    <div class="mobile_scrollable_int">
                        <div class="has_pads">
    
                            <div class="white_wrp has_pads">
                                <div class="form_switch has_active" (click)="changeRelationsTab(1); savedLvl2 = 1;">
                                    <div class="d-f-c" style="justify-content: space-between !important;">
                                        <div class="d-f-c">
                                            <div class="name">{{"Consist of" | translate}}</div>
                                        </div>
                                        <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0 10.59L4.59 6L0 1.41L1.42 0L7.42 6L1.42 12L0 10.59Z" fill="black"/>
                                        </svg>
                                    </div>
                                    <div class="active_values" *ngIf="form.get('consist_of_task_id').get('add').value.length">
                                        <div class="task" *ngFor="let id of form.get('consist_of_task_id').get('add').value; let i = index">
                                            <div class="d-f-c">
                                                <b>{{findTask(id)?.custom_id}}</b> 
                                                <span class="task_name text_one_line">{{ findTask(id).name }}</span>
                                            </div>
                                        </div>
                                        <div class="more_active" *ngIf="form.get('consist_of_task_id').get('add').value.length > 3"> +{{ form.get('consist_of_task_id').get('add').value.length - 3 }}</div>
                                    </div>
                                </div>
                                <div class="form_switch has_active" (click)="changeRelationsTab(2); savedLvl2 = 2;">
                                    <div class="d-f-c" style="justify-content: space-between !important;">
                                        <div class="d-f-c">
                                            <div class="name">{{"Part of" | translate}}</div>
                                        </div>
                                        <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0 10.59L4.59 6L0 1.41L1.42 0L7.42 6L1.42 12L0 10.59Z" fill="black"/>
                                        </svg>
                                    </div>
                                    <div class="active_values" *ngIf="form.get('part_of_task_id').get('add').value.length">
                                        <div class="task" *ngFor="let id of form.get('part_of_task_id').get('add').value; let i = index">
                                            <div class="d-f-c">
                                                <b>{{findTask(id)?.custom_id}}</b> 
                                                <span class="task_name text_one_line">{{ findTask(id).name }}</span>
                                            </div>
                                        </div>
                                        <div class="more_active" *ngIf="form.get('part_of_task_id').get('add').value.length > 3"> +{{ form.get('part_of_task_id').get('add').value.length - 3 }}</div>
                                    </div>
                                </div>
                                <div class="form_switch has_active" (click)="changeRelationsTab(3); savedLvl2 = 3;">
                                    <div class="d-f-c" style="justify-content: space-between !important;">
                                        <div class="d-f-c">
                                            <div class="name">{{"Related" | translate}}</div>
                                        </div>
                                        <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0 10.59L4.59 6L0 1.41L1.42 0L7.42 6L1.42 12L0 10.59Z" fill="black"/>
                                        </svg>
                                    </div>
                                    <div class="active_values" *ngIf="form.get('related_task_id').get('add').value.length">
                                        <div class="task" *ngFor="let id of form.get('related_task_id').get('add').value; let i = index">
                                            <div class="d-f-c">
                                                <b>{{findTask(id)?.custom_id}}</b> 
                                                <span class="task_name text_one_line">{{ findTask(id).name }}</span>
                                            </div>
                                        </div>
                                        <div class="more_active" *ngIf="form.get('related_task_id').get('add').value.length > 3"> +{{ form.get('related_task_id').get('add').value.length - 3 }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
    
                        <div class="has_pads">
                            <div class="white_wrp has_pads">
                                <div class="form_switch" (click)="changeRelationsTab(4); savedLvl2 = 4;">
                                    <div class="d-f-c">
                                        <div class="name">{{"Thumbnail setting" | translate}}</div>
                                    </div>
                                    <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0 10.59L4.59 6L0 1.41L1.42 0L7.42 6L1.42 12L0 10.59Z" fill="black"/>
                                    </svg>
                                </div>
                            </div>
                        </div>
    
                        <div class="has_pads">
                            <div class="mob_white_check white_wrp">
                                <div class="box_wrp">
                                    <mat-checkbox color="primary" [formControl]="is_create_job">{{ "Add job after finish adding task" | translate }}</mat-checkbox>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-tab>

                <mat-tab>
                    <mat-tab-group class="connections_mobile_tabs no_header" [selectedIndex]="consistOfIndex.value" (selectedIndexChange)="changeConsistOfTab($event)">
                        <mat-tab>
                            <div class="mobile_scrollable_int">
                                <div class="has_pads">
                                    <div class="selected_tasks">
                                        <div class="task" *ngFor="let id of form.get('consist_of_task_id').get('add').value; let i = index" (click)="taskActivatedId = id" [ngClass]="{'active': taskActivatedId == id}">
                                            <div class="task_in">
                                                <div class="d-f-c" style="width: calc(100% - 65px);">
                                                    <b>{{findTask(id)?.custom_id}}</b> 
                                                    <span class="task_name text_one_line">{{ findTask(id).name }}</span>
                                                </div>
    
                                                <div class="d-f-c" style="width: 60px;">
                                                    <div class="arrow_icon" (click)="moveDown(form.get('consist_of_task_id').get('add').value, id, i)">
                                                        <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M17.6333 0.824987L10 8.45832L2.35 0.824987L0 3.17499L10 13.175L20 3.17499L17.6333 0.824987Z" fill="#A3A3A3"/>
                                                        </svg>                                                    
                                                    </div>
                                                    <div class="arrow_icon" (click)="moveUp(form.get('consist_of_task_id').get('add').value, id, i)">
                                                        <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M2.36667 13.175L10 5.54168L17.65 13.175L20 10.825L10 0.825012L0 10.825L2.36667 13.175Z" fill="#A3A3A3"/>
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
    
                                            <div class="remove_task" *ngIf="taskActivatedId == id" (click)="onRemoved(id, 'consist_of_task_id')">
                                                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g clip-path="url(#clip0_2886_45854)">
                                                    <path d="M20.5827 6.94419L19.0552 5.41669L12.9993 11.4725L6.94352 5.41669L5.41602 6.94419L11.4718 13L5.41602 19.0559L6.94352 20.5834L12.9993 14.5275L19.0552 20.5834L20.5827 19.0559L14.5268 13L20.5827 6.94419Z" fill="#EDEDED"/>
                                                    </g>
                                                    <defs>
                                                    <clipPath id="clip0_2886_45854">
                                                    <rect width="26" height="26" fill="white"/>
                                                    </clipPath>
                                                    </defs>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </mat-tab>

                        <mat-tab>
                            <div class="d-f-c has_pads" style="justify-content: space-between !important; padding-bottom: 0 !important;">
                                <div class="mob_filter_btns" (click)="changeRelationsTab(5)">
                                    <span>
                                        {{ typeOfSearchControl.value }}
                                    </span>
                                    <svg class="ml-1" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_2864_45355c1)">
                                        <path d="M7.91602 3.33333L2.08268 3.33333L4.99935 7.91666L7.91602 3.33333Z" fill="#686868"/>
                                        </g>
                                        <defs>
                                        <clipPath id="clip0_2864_45355c1">
                                        <rect width="10" height="10" fill="white" transform="translate(10) rotate(90)"/>
                                        </clipPath>
                                        </defs>
                                    </svg>
                                </div>
        
                                <div class="mob_filter_btns" *ngIf="['Everywhere', 'Custom ID', 'Task Name'].includes(typeOfSearchControl.value)" (click)="changeRelationsTab(6)">
                                    <span>
                                        {{ groupOfSearchControl.value ? getGroupById(groupOfSearchControl.value)?.name : ("All groups" | translate) }}
                                    </span>
                                    <svg class="ml-1" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_2864_45355c1)">
                                        <path d="M7.91602 3.33333L2.08268 3.33333L4.99935 7.91666L7.91602 3.33333Z" fill="#686868"/>
                                        </g>
                                        <defs>
                                        <clipPath id="clip0_2864_45355c1">
                                        <rect width="10" height="10" fill="white" transform="translate(10) rotate(90)"/>
                                        </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                            </div>

                            <form [formGroup]="form" class="has_pads form_with_valids mobile_scrollable_int_tasks">
                                <div class="white_wrp has_pads">
                                    <mat-form-field appearance="legacy" class="full_width_form">
                                        <mat-label>{{ 'Search' | translate }}</mat-label>
                                        <input placeholder="{{ 'Search' | translate }}" matInput #consistOfInput [formControl]="consistOfControl">
                                    </mat-form-field>
                                    <div class="task_results">
                                        <ng-container *ngFor="let task of tasks$ | async">
                                            <div class="task" (click)="selected(task.id, consistOfInput, 'consist_of_task_id'); changeConsistOfTab(0);" *ngIf="!form.get('consist_of_task_id').get('add').value.includes(task.id) && (['Everywhere', 'Custom ID', 'Task Name'].includes(typeOfSearchControl.value) ? (groupOfSearchControl.value == task.group_id || groupOfSearchControl.value == '') : true)">
                                                <div class="d-f-c">
                                                    <span class="text_one_line">
                                                        {{ task.name }}
                                                    </span>
                                                    <div class="group" *ngIf="!!task?.group">
                                                        <span class="text_one_line">{{task?.group?.name}}</span>
                                                    </div>
                                                </div>
                                                <div class="task_ids">
                                                    <span>{{task.custom_id}}</span>
                                                    <span>{{task.id}}</span>
                                                </div>
                                            </div>
                                        </ng-container>
                                        
                                    </div>
                                </div>
                            </form>
                        </mat-tab>
                    </mat-tab-group>

                </mat-tab>
                <mat-tab>
                    <mat-tab-group class="connections_mobile_tabs no_header" [selectedIndex]="partOfIndex.value" (selectedIndexChange)="changePartOfTab($event)">
                        <mat-tab>
                            <div class="mobile_scrollable_int">
                                <div class="has_pads">
                                    <div class="selected_tasks">
                                        <div class="task" *ngFor="let id of form.get('part_of_task_id').get('add').value; let i = index" (click)="taskActivatedId = id" [ngClass]="{'active': taskActivatedId == id}">
                                            <div class="task_in">
                                                <div class="d-f-c" style="width: calc(100% - 65px);">
                                                    <b>{{findTask(id)?.custom_id}}</b> 
                                                    <span class="task_name text_one_line">{{ findTask(id).name }}</span>
                                                </div>
        
                                                <div class="d-f-c" style="width: 60px;">
                                                    <div class="arrow_icon" (click)="moveDown(form.get('part_of_task_id').get('add').value, id, i)">
                                                        <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M17.6333 0.824987L10 8.45832L2.35 0.824987L0 3.17499L10 13.175L20 3.17499L17.6333 0.824987Z" fill="#A3A3A3"/>
                                                        </svg>                                                    
                                                    </div>
                                                    <div class="arrow_icon" (click)="moveUp(form.get('part_of_task_id').get('add').value, id, i)">
                                                        <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M2.36667 13.175L10 5.54168L17.65 13.175L20 10.825L10 0.825012L0 10.825L2.36667 13.175Z" fill="#A3A3A3"/>
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
        
                                            <div class="remove_task" *ngIf="taskActivatedId == id" (click)="onRemoved(id, 'part_of_task_id')">
                                                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g clip-path="url(#clip0_2886_45854)">
                                                    <path d="M20.5827 6.94419L19.0552 5.41669L12.9993 11.4725L6.94352 5.41669L5.41602 6.94419L11.4718 13L5.41602 19.0559L6.94352 20.5834L12.9993 14.5275L19.0552 20.5834L20.5827 19.0559L14.5268 13L20.5827 6.94419Z" fill="#EDEDED"/>
                                                    </g>
                                                    <defs>
                                                    <clipPath id="clip0_2886_45854">
                                                    <rect width="26" height="26" fill="white"/>
                                                    </clipPath>
                                                    </defs>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </mat-tab>
                        <mat-tab>
                            <div class="d-f-c has_pads" style="justify-content: space-between !important; padding-bottom: 0 !important;">
                                <div class="mob_filter_btns" (click)="changeRelationsTab(5)">
                                    <span>
                                        {{ typeOfSearchControl.value }}
                                    </span>
                                    <svg class="ml-1" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_2864_45355c1)">
                                        <path d="M7.91602 3.33333L2.08268 3.33333L4.99935 7.91666L7.91602 3.33333Z" fill="#686868"/>
                                        </g>
                                        <defs>
                                        <clipPath id="clip0_2864_45355c1">
                                        <rect width="10" height="10" fill="white" transform="translate(10) rotate(90)"/>
                                        </clipPath>
                                        </defs>
                                    </svg>
                                </div>
        
                                <div class="mob_filter_btns" *ngIf="['Everywhere', 'Custom ID', 'Task Name'].includes(typeOfSearchControl.value)" (click)="changeRelationsTab(6)">
                                    <span>
                                        {{ groupOfSearchControl.value ? getGroupById(groupOfSearchControl.value)?.name : ("All groups" | translate) }}
                                    </span>
                                    <svg class="ml-1" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_2864_45355c1)">
                                        <path d="M7.91602 3.33333L2.08268 3.33333L4.99935 7.91666L7.91602 3.33333Z" fill="#686868"/>
                                        </g>
                                        <defs>
                                        <clipPath id="clip0_2864_45355c1">
                                        <rect width="10" height="10" fill="white" transform="translate(10) rotate(90)"/>
                                        </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                            </div>

                            <form [formGroup]="form" class="has_pads form_with_valids mobile_scrollable_int_tasks">
                                <div class="white_wrp has_pads">
                                    <mat-form-field appearance="legacy" class="full_width_form">
                                        <mat-label>{{ 'Search' | translate }}</mat-label>
                                        <input placeholder="{{ 'Search' | translate }}" matInput #partOfInput [formControl]="partOfControl">
                                    </mat-form-field>
                                    <div class="task_results">
                                        <ng-container *ngFor="let task of tasks$ | async">
                                            <div class="task" (click)="selected(task.id, partOfInput, 'part_of_task_id'); changePartOfTab(0);" *ngIf="!form.get('part_of_task_id').get('add').value.includes(task.id) && (['Everywhere', 'Custom ID', 'Task Name'].includes(typeOfSearchControl.value) ? (groupOfSearchControl.value == task.group_id || groupOfSearchControl.value == '') : true)">
                                                <div class="d-f-c">
                                                    <span class="text_one_line">
                                                        {{ task.name }}
                                                    </span>
                                                    <div class="group" *ngIf="!!task?.group">
                                                        <span class="text_one_line">{{task?.group?.name}}</span>
                                                    </div>
                                                </div>
                                                <div class="task_ids">
                                                    <span>{{task.custom_id}}</span>
                                                    <span>{{task.id}}</span>
                                                </div>
                                            </div>
                                        </ng-container>
                                        
                                    </div>
                                </div>
                            </form>
                        </mat-tab>
                    </mat-tab-group>
                </mat-tab>
                <mat-tab>
                    <mat-tab-group class="connections_mobile_tabs no_header" [selectedIndex]="relatedIndex.value" (selectedIndexChange)="changeRelatedTab($event)">
                        <mat-tab>
                            <div class="mobile_scrollable_int">
                                <div class="has_pads">
                                    <div class="selected_tasks">
                                        <div class="task" *ngFor="let id of form.get('related_task_id').get('add').value; let i = index" (click)="taskActivatedId = id" [ngClass]="{'active': taskActivatedId == id}">
                                            <div class="task_in">
                                                <div class="d-f-c" style="width: calc(100% - 65px);">
                                                    <b>{{findTask(id)?.custom_id}}</b> 
                                                    <span class="task_name text_one_line">{{ findTask(id).name }}</span>
                                                </div>
        
                                                <div class="d-f-c" style="width: 60px;">
                                                    <div class="arrow_icon" (click)="moveDown(form.get('related_task_id').get('add').value, id, i)">
                                                        <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M17.6333 0.824987L10 8.45832L2.35 0.824987L0 3.17499L10 13.175L20 3.17499L17.6333 0.824987Z" fill="#A3A3A3"/>
                                                        </svg>                                                    
                                                    </div>
                                                    <div class="arrow_icon" (click)="moveUp(form.get('related_task_id').get('add').value, id, i)">
                                                        <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M2.36667 13.175L10 5.54168L17.65 13.175L20 10.825L10 0.825012L0 10.825L2.36667 13.175Z" fill="#A3A3A3"/>
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
        
                                            <div class="remove_task" *ngIf="taskActivatedId == id" (click)="onRemoved(id, 'related_task_id')">
                                                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g clip-path="url(#clip0_2886_45854)">
                                                    <path d="M20.5827 6.94419L19.0552 5.41669L12.9993 11.4725L6.94352 5.41669L5.41602 6.94419L11.4718 13L5.41602 19.0559L6.94352 20.5834L12.9993 14.5275L19.0552 20.5834L20.5827 19.0559L14.5268 13L20.5827 6.94419Z" fill="#EDEDED"/>
                                                    </g>
                                                    <defs>
                                                    <clipPath id="clip0_2886_45854">
                                                    <rect width="26" height="26" fill="white"/>
                                                    </clipPath>
                                                    </defs>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </mat-tab>
                        <mat-tab>
                            <div class="d-f-c has_pads" style="justify-content: space-between !important; padding-bottom: 0 !important;">
                                <div class="mob_filter_btns" (click)="changeRelationsTab(5)">
                                    <span>
                                        {{ typeOfSearchControl.value }}
                                    </span>
                                    <svg class="ml-1" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_2864_45355c1)">
                                        <path d="M7.91602 3.33333L2.08268 3.33333L4.99935 7.91666L7.91602 3.33333Z" fill="#686868"/>
                                        </g>
                                        <defs>
                                        <clipPath id="clip0_2864_45355c1">
                                        <rect width="10" height="10" fill="white" transform="translate(10) rotate(90)"/>
                                        </clipPath>
                                        </defs>
                                    </svg>
                                </div>
        
                                <div class="mob_filter_btns" *ngIf="['Everywhere', 'Custom ID', 'Task Name'].includes(typeOfSearchControl.value)" (click)="changeRelationsTab(6)">
                                    <span>
                                        {{ groupOfSearchControl.value ? getGroupById(groupOfSearchControl.value)?.name : ("All groups" | translate) }}
                                    </span>
                                    <svg class="ml-1" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_2864_45355c1)">
                                        <path d="M7.91602 3.33333L2.08268 3.33333L4.99935 7.91666L7.91602 3.33333Z" fill="#686868"/>
                                        </g>
                                        <defs>
                                        <clipPath id="clip0_2864_45355c1">
                                        <rect width="10" height="10" fill="white" transform="translate(10) rotate(90)"/>
                                        </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                            </div>

                            <form [formGroup]="form" class="has_pads form_with_valids mobile_scrollable_int_tasks">
                                <div class="white_wrp has_pads">
                                    <mat-form-field appearance="legacy" class="full_width_form">
                                        <mat-label>{{ 'Search' | translate }}</mat-label>
                                        <input placeholder="{{ 'Search' | translate }}" matInput #relatedInput [formControl]="relativeControl">
                                    </mat-form-field>
      
                                    <div class="task_results">
                                        <ng-container *ngFor="let task of tasks$ | async">
                                            <div class="task" (click)="selected(task.id, relatedInput, 'related_task_id'); changeRelatedTab(0);" *ngIf="!form.get('related_task_id').get('add').value.includes(task.id) && (['Everywhere', 'Custom ID', 'Task Name'].includes(typeOfSearchControl.value) ? (groupOfSearchControl.value == task.group_id || groupOfSearchControl.value == '') : true)">
                                                <div class="d-f-c">
                                                    <span class="text_one_line">
                                                        {{ task.name }}
                                                    </span>
                                                    <div class="group" *ngIf="!!task?.group">
                                                        <span class="text_one_line">{{task?.group?.name}}</span>
                                                    </div>
                                                </div>
                                                <div class="task_ids">
                                                    <span>{{task.custom_id}}</span>
                                                    <span>{{task.id}}</span>
                                                </div>
                                            </div>
                                        </ng-container>
                                        
                                    </div>
                                </div>
                            </form>
                        </mat-tab>
                    </mat-tab-group>
                </mat-tab>
                <mat-tab>
                    <div class="mobile_scrollable_int">
                        <form [formGroup]="form" class="form_with_valids has_pads">
                            <div class="mob_white_check white_wrp mt-25">
                                <div class="box_wrp">
                                    <mat-checkbox color="primary" formControlName="is_random_avatar">{{ "Use random image for card thumbnail" | translate }}</mat-checkbox>
                                </div>
                            </div>
                        </form>
                    </div>
                </mat-tab>
                <mat-tab>
                    <div class="mobile_scrollable_int">
                        <div class="has_pads">
                            <div class="group_chips small_chips" *ngIf="groups?.length > 0">
                                <mat-chip-list [multiple]="false" selectable [formControl]="typeOfSearchControl">
                                    <mat-chip class="small_chip" (click)="selectTypeSearch('Everywhere')" value="Everywhere">Everywhere</mat-chip>
                                    <mat-chip class="small_chip" (click)="selectTypeSearch('Custom ID')" value="Custom ID">Custom ID</mat-chip>
                                    <mat-chip class="small_chip" (click)="selectTypeSearch('Internal ID')" value="Internal ID">Internal ID</mat-chip>
                                    <mat-chip class="small_chip" (click)="selectTypeSearch('Global ID')" value="Global ID">Global ID</mat-chip>
                                    <mat-chip class="small_chip" (click)="selectTypeSearch('Task Name')" value="Task Name">Card Name</mat-chip>
                                </mat-chip-list>
                            </div>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab>
                    <div class="mobile_scrollable_int">
                        <div class="has_pads">
                            <div class="group_chips small_chips" *ngIf="groups?.length > 0" style="padding: 0 !important;">
                                <mat-chip-list [multiple]="false" selectable [formControl]="groupOfSearchControl" style="padding: 0 !important;">
                                    <mat-chip style="margin-top: 28px !important;" class="small_chip" (click)="selectGroupSearch('')" [value]="''">
                                        {{ "All groups" | translate }}
                                    </mat-chip>
                                    <ng-container *ngFor="let group of groups; let i = index">
                                        <mat-chip style="margin-top: 28px !important;" class="small_chip" (click)="selectGroupSearch(group.id)" [value]="group?.id">
                                            {{ group.name | translate }}
                                        </mat-chip>
                                        <mat-chip style="margin-top: 28px !important;" *ngIf="i == groups?.length - 1 && i > 6" class="small_chip more" (click)="groupIdContainerSearch.open()">
                                            {{ "More..." | translate }}
                                        </mat-chip>
                                    </ng-container>
                                </mat-chip-list>
    
                                <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                                    <mat-select #groupIdContainerSearch [formControl]="groupOfSearchControl" (selectionChange)="selectGroupSearch($event.value)">
                                        <mat-option>
                                            <ngx-mat-select-search 
                                            placeholderLabel="{{ 'Search' | translate }}" 
                                            noEntriesFoundLabel="{{ 'no matching group found' | translate }}"
                                            [formControl]="groupMoreControl"></ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option [value]="''">
                                            {{ "All groups" | translate }}
                                        </mat-option>
                                        <mat-option *ngFor="let group of groups$ | async" [value]="group.id">
                                            {{ group.name | translate }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </mat-tab>
    </mat-tab-group>
</mat-dialog-content>


<mat-dialog-actions>
    <div class="mobile_interface_buttons" style="justify-content: space-between !important;">
        <ng-container *ngIf="aboutIndex.value == 0 && relationsIndex.value == 0; else elseTemplateDownBtn">
            <button class="mobile_interface_btn" (click)="change()" *ngIf="selectedIndex.value == 0">
                {{ "Cancel" | translate }}
            </button>
            <button class="mobile_interface_btn" (click)="close()" *ngIf="selectedIndex.value != 0">
                {{ "Cancel" | translate }}
            </button>
        
            <div class="d-f-c">
                <button class="mobile_interface_btn" (click)="back()" style="padding: 8px 12px !important;" *ngIf="selectedIndex.value != 0">
                    {{ "Back" | translate }}
                </button>
                <button class="mobile_interface_btn mobile_interface_btn_white ml-1" *ngIf="selectedIndex.value == 1" (click)="next(selectedIndex.value)" style="padding: 8px 12px !important;">
                    {{ "Skip" | translate }}
                </button>
                <button class="mobile_interface_btn mobile_interface_btn_blue ml-1" (click)="next(selectedIndex.value)" *ngIf="selectedIndex.value != 5">
                    {{ "Next" | translate }}
                </button>
                <button class="mobile_interface_btn mobile_interface_btn_blue ml-1" (click)="submitForm()" *ngIf="selectedIndex.value == 5">
                    {{ "Finish" | translate }}
                </button>
            </div>
        </ng-container>
        <ng-template #elseTemplateDownBtn>
            <ng-container *ngIf="aboutIndex.value != 0; else elseTemplateAbout">
                <div>

                </div>
                <button class="mobile_interface_btn" (click)="cancelAbout()">
                    {{ "Cancel" | translate }}
                </button>
            </ng-container>
            <ng-template #elseTemplateAbout>
                <ng-container [ngSwitch]="relationsIndex.value">
                    <ng-container *ngSwitchCase="1">
                        <ng-container *ngIf="consistOfIndex.value == 0; else elseTemplateIndex1">
                            <button class="mobile_interface_btn" (click)="backRelations()">
                                {{ "Back" | translate }}
                            </button>
                            
                            <button class="mobile_interface_btn mobile_interface_btn_blue" (click)="changeConsistOfTab(1)">
                                {{ "Add more consist of tasks" | translate }}
                            </button>
                        </ng-container>
                        <ng-template #elseTemplateIndex1>
                            <button class="mobile_interface_btn" (click)="changeConsistOfTab(0)">
                                {{ "Cancel" | translate }}
                            </button>

                            <button class="mobile_interface_btn" (click)="changeConsistOfTab(0)">
                                {{ "Back" | translate }}
                            </button>
                        </ng-template>
                        
                    </ng-container>
                    <ng-container *ngSwitchCase="2">
                        <ng-container *ngIf="partOfIndex.value == 0; else elseTemplateIndex2">
                            <button class="mobile_interface_btn" (click)="backRelations()">
                                {{ "Back" | translate }}
                            </button>
    
                            <button class="mobile_interface_btn mobile_interface_btn_blue" (click)="changePartOfTab(1)">
                                {{ "Add more part of tasks" | translate }}
                            </button>
                        </ng-container>
                        <ng-template #elseTemplateIndex2>
                            <button class="mobile_interface_btn" (click)="changePartOfTab(0)">
                                {{ "Cancel" | translate }}
                            </button>

                            <button class="mobile_interface_btn" (click)="changePartOfTab(0)">
                                {{ "Back" | translate }}
                            </button>
                        </ng-template>
                        
                    </ng-container>
                    <ng-container *ngSwitchCase="3">
                        <ng-container *ngIf="relatedIndex.value == 0; else elseTemplateIndex3">
                            <button class="mobile_interface_btn" (click)="backRelations()">
                                {{ "Back" | translate }}
                            </button>
    
                            <button class="mobile_interface_btn mobile_interface_btn_blue" (click)="changeRelatedTab(1)">
                                {{ "Add more related of tasks" | translate }}
                            </button>
                        </ng-container>
                        <ng-template #elseTemplateIndex3>
                            <button class="mobile_interface_btn" (click)="changeRelatedTab(0)">
                                {{ "Cancel" | translate }}
                            </button>

                            <button class="mobile_interface_btn" (click)="changeRelatedTab(0)">
                                {{ "Back" | translate }}
                            </button>
                        </ng-template>
                        
                    </ng-container>
                    <ng-container *ngSwitchCase="4">
                        <div>

                        </div>
                        <button class="mobile_interface_btn" (click)="backRelations()">
                            {{ "Back" | translate }}
                        </button>
                    </ng-container>
                    <ng-container *ngSwitchCase="5">
                        <div>

                        </div>
                        <button class="mobile_interface_btn" (click)="smartBackRelations()">
                            {{ "Back" | translate }}
                        </button>
                    </ng-container>
                    <ng-container *ngSwitchCase="6">
                        <div>

                        </div>
                        <button class="mobile_interface_btn" (click)="smartBackRelations()">
                            {{ "Back" | translate }}
                        </button>
                    </ng-container>
                </ng-container>
            </ng-template>
        </ng-template>
    </div>

        <!-- <div class="f_w_p_btns">
        <ng-container *ngIf="!templatesIsActive; else elseTemplateTempatesActive">
            <button mat-button (click)="close()">
                {{ "Close" | translate }}
            </button>
            <button class="ml-1 d-f-btn" (click)="submitForm()" mat-raised-button cdkFocusInitial color="primary" [disabled]="!!isSubmit">
                <mat-spinner class="spin_16 mr-1" *ngIf="isSubmit"></mat-spinner>  
                {{ "Save" | translate }}
            </button>
            <button *ngIf="!data.basedVideos && !!taskTemplates && taskTemplates.length > 0" class="ml-1" (click)="toggleTemplates()" mat-button cdkFocusInitial color="warn" [disabled]="!!isSubmit">
                {{ "Create from template" | translate }}
            </button>
        </ng-container>
        <ng-template #elseTemplateTempatesActive>
            <button mat-button (click)="close()">
                {{ "Close" | translate }}
            </button>
            <button class="ml-1" mat-button color="warn" (click)="toggleTemplates()">
                {{ "Back to default creation" | translate }}
            </button>
        </ng-template>
    </div> -->
</mat-dialog-actions>


<!-- <mat-dialog-content class="mat-typography">
    <div class="container form_wrapper">

        <ng-container *ngIf="!templatesIsActive; else elseTemplateTempatesActiveForm">
            <div class="form_with_pads form_with_valids" style="margin-top: 0 !important;" *ngIf="!this.data.isMulti">
                <div class="white_block white_expanded" style="margin-top: 0 !important;">
                    <div class="d-f-c" (click)="openJobs()">
                        <div class="white_block_title">
                            {{ "Jobs" | translate }}
                        </div>
        
                        <button mat-icon-button>
                            <mat-icon *ngIf="jobsIsOpen">expand_less</mat-icon>
                            <mat-icon *ngIf="!jobsIsOpen">expand_more</mat-icon>
                        </button>
                    </div>
                    <div class="white_inside" [ngClass]="{'open': jobsIsOpen}">        
                        <div class="jobs_wrp">
                            <div class="job" *ngFor="let job of jobs; let i = index;">
        
                                <mat-form-field appearance="standard" class="full_width_form">
                                    <mat-label>{{ 'Title' | translate }}</mat-label>
                                    <input [(ngModel)]="job.name" matInput placeholder="{{ 'Title' | translate }}">
                                </mat-form-field>
                    
                                <mat-form-field appearance="standard" class="full_width_form">
                                    <mat-label>{{ 'Description' | translate }}</mat-label>
                                    <textarea [(ngModel)]="job.comment" matInput placeholder="{{ 'Drief description' | translate }}"
                                    cdkTextareaAutosize
                                    #autosize="cdkTextareaAutosize"
                                    cdkAutosizeMinRows="1"
                                    cdkAutosizeMaxRows="5"></textarea>
                                </mat-form-field>
        
                                <ng-container *ngIf="operations?.length > 0">
                                    <div class="small_chips" >
                                        <label>{{ "Job type" | translate }}: </label>
                                        <mat-chip-list [multiple]="false" selectable [(ngModel)]="job.operation_id">
                                            <ng-container *ngFor="let operation of operations; let i = index">
                                                <mat-chip class="small_chip" (click)="job.operation_id = operation?.id" [value]="operation?.id" [selected]="job.operation_id == operation?.id">
                                                    {{ operation.name | translate }}
                                                </mat-chip>
                                                <mat-chip *ngIf="i == operations?.length - 1 && i > 6" class="small_chip more" (click)="operationIdContainer.open()">
                                                    {{ "More..." | translate }}
                                                </mat-chip>
                                            </ng-container>
                                            
                                        </mat-chip-list>
                                    </div>
                        
                                    <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                                        <mat-select #operationIdContainer [(ngModel)]="job.operation_id">
                                            <mat-option>
                                                <ngx-mat-select-search 
                                                placeholderLabel="{{ 'Search' | translate }}" 
                                                noEntriesFoundLabel="{{ 'no matching task found' | translate }}"
                                                [formControl]="operationMoreControl"></ngx-mat-select-search>
                                            </mat-option>
                                            <mat-option *ngFor="let operation of operations$ | async" [value]="operation.id">
                                                {{ operation.name | translate }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </ng-container>
        
                                <div class="small_chips" *ngIf="operationStatuses?.length > 0">
                                    <label>{{ "Status" | translate }}: </label>
                                    <mat-chip-list [multiple]="false" selectable [(ngModel)]="job.status_id">
                                        <ng-container *ngFor="let status of operationStatuses">
                                            <mat-chip class="small_chip" (click)="job.status_id = status.id" [selected]="job.status_id == status.id" [value]="status?.id" [ngClass]="{
                                                'first': status.id == '1',
                                                'second': status.id == '2',
                                                'third': status.id == '3',
                                                'fours': status.id == '4',
                                                'fives': status.id == '5',
                                                'sixs': status.id == '6',
                                                'nine': status.id == '97',
                                                'seven': status.id == '98',
                                                'eight': status.id == '99'}">
                                                {{ status.name | translate }}
                                            </mat-chip>
                                        </ng-container>
                                    </mat-chip-list>
                                </div>      
                                
                                <div class="priority_chips">
                                    <label>{{ "Priority" | translate }}: </label>
                                    <mat-chip-list [multiple]="false" selectable [(ngModel)]="job.priority">
                                        <mat-chip *ngFor="let priority of prioritys" [selected]="job.priority == priority.id" [ngClass]="{
                                            'Urgent': priority.id == '1000',
                                            'High': priority.id == '900',
                                            'Middle': priority.id == '800',
                                            'Low': priority.id == '700',
                                            'No': priority.id == '0'
                                        }" class="priority_chip" (click)="job.priority = priority.id" [value]="priority.id">
                                            {{ priority.name | translate }}
                                        </mat-chip>
                                    </mat-chip-list>
                                </div>
        
                                <div class="job_executors" *ngIf="job.create_task_employees.length && employees && employees.length > 0">
                                    <div class="executor" *ngFor="let executor of job.create_task_employees; let k = index;">
                                        
                                        <app-user [class]="'user_div'" [employee]="getEmployeeById(executor.employee_id)"></app-user>
                                    
                                        <p>
                                            {{ "Choose member role" | translate }}:
                                            <mat-button-toggle-group appearance="legacy" class="ml-1" [(ngModel)]="executor.is_manager" [value]="executor.is_manager">
                                                <mat-button-toggle [value]="1">{{ "Executor" | translate }}</mat-button-toggle>
                                                <mat-button-toggle [value]="0">{{ "Assistant" | translate }}</mat-button-toggle>
                                            </mat-button-toggle-group>
                                        </p> 
        
                                        <button mat-icon-button color="warn" class="delete_executor" (click)="deleteExecutor(job, k)">
                                            <mat-icon>person_remove</mat-icon>
                                        </button>
        
                                    </div>
                                </div>
        
        
                                <button mat-icon-button color="warn" class="delete_job" (click)="deleteJob(i)">
                                    <mat-icon>do_disturb_on</mat-icon>
                                </button>
        
                                <div class="add_new_executor" *ngIf="job.newExecutor" style="position: relative;">
                                    <div class="users_select">
                                        <label>{{ "Add new executor" | translate }}: </label>
                                        <mat-chip-list [multiple]="false" selectable [(ngModel)]="job.newExecutor.employee_id">
                                            <ng-container *ngFor="let employee of employees; let i = index">
                                                <mat-chip class="chip_user" (click)="!checkIfDisabled(job, employee.id) ? job.newExecutor.employee_id = employee.id : null" [selected]="job.newExecutor.employee_id == employee.id" [value]="employee.id" [disabled]="checkIfDisabled(job, employee.id)">
                                                    <app-user [class]="'user_div'" [employee]="employee"></app-user>
                                                </mat-chip>
                                                <mat-chip *ngIf="i == employees.length - 1 && i > 6" class="chip_user more" (click)="employeeIdContainer.open()">
                                                    {{ "more" | translate }}
                                                </mat-chip>
                                            </ng-container>
                                        </mat-chip-list>
                                    </div>
                        
                                    <mat-form-field appearance="standard" class="full_width_form hide-form-field" style="position: absolute;">
                                        <mat-select #employeeIdContainer [(ngModel)]="job.newExecutor.employee_id">
                                            <mat-option>
                                                <ngx-mat-select-search 
                                                placeholderLabel="{{ 'Search' | translate }}" 
                                                noEntriesFoundLabel="{{ 'no matching task found' | translate }}"
                                                [formControl]="employeeMoreControl"></ngx-mat-select-search>
                                            </mat-option>
                                            <mat-option *ngFor="let employee of employees$ | async" [value]="employee.id" [ngClass]="{'opacity-6': checkIfDisabled(job, employee.id)}" [disabled]="checkIfDisabled(job, employee.id)">
                                                <app-user [class]="'user_div'" [employee]="employee"></app-user>
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
        
                                    <button mat-icon-button color="primary" (click)="addExecutor(job)">
                                        <mat-icon>person_add</mat-icon>
                                    </button>
        
                                </div>
                            </div>
                        </div>
        
                        <div class="add_job" (click)="addJob()">
                            + {{ 'Add job' | translate }}
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-template #elseTemplateTempatesActiveForm>
            <div class="scenarios_list">

                <div class="template_item" (click)="selectTmpl(tmpl)" *ngFor="let tmpl of taskTemplates;">
                    <div class="type_name">
                        {{ tmpl?.name }}
                    </div>
                    <div class="tmpl_settings" *ngIf="tmpl.active">

                        <div class="form_with_valids full_width">
                            <mat-form-field appearance="standard" class="full_width_form">
                                <ng-container *ngIf="tmpl.template_data.name == '' && submitedTmpl">
                                    <div class="req_field"></div>
                                    <div class="req_label">
                                        <div class="angle"></div>
                                        <span>
                                            {{ "You need to write here" | translate }}
                                        </span>
                                    </div>
                                </ng-container>
                                <mat-label>{{ 'Task title' | translate }}</mat-label>
                                <input [(ngModel)]="tmpl.template_data.name" matInput placeholder="{{ 'Name' | translate }}">
                            </mat-form-field>
                        </div>

                        <button class="ml-1 d-f-btn" (click)="createTaskByTemplate(tmpl)" mat-raised-button cdkFocusInitial color="primary" [disabled]="!!isSubmitTmpl">
                            <mat-spinner class="spin_16 mr-1" *ngIf="isSubmitTmpl"></mat-spinner>  
                            {{ "Create task" | translate }}
                        </button>
                    </div>
                </div>

                <form [formGroup]="templateForm">

                    <div class="white_block white_expanded no_m_l_r">
                        <div class="d-f-c" (click)="openRelations()">
                            <div class="white_block_title">
                                {{ "Assign relations to other task(s)" | translate }}
                            </div>
        
                            <button mat-icon-button>
                                <mat-icon *ngIf="relations">expand_less</mat-icon>
                                <mat-icon *ngIf="!relations">expand_more</mat-icon>
                            </button>
                        </div>
                        <div class="white_inside" [ngClass]="{'open': relations}">
                            <div class="search_type">
                                <mat-button-toggle-group appearance="legacy" [formControl]="typeOfSearchControl">
                                    <mat-button-toggle value="Everywhere">Everywhere</mat-button-toggle>
                                    <mat-button-toggle value="Custom ID">Custom ID</mat-button-toggle>
                                    <mat-button-toggle value="Internal ID">Internal ID</mat-button-toggle>
                                    <mat-button-toggle value="Global ID">Global ID</mat-button-toggle>
                                    <mat-button-toggle value="Task Name">Task Name</mat-button-toggle>
                                </mat-button-toggle-group>
        
                                <mat-form-field appearance="legacy" class="ml-1" *ngIf="['Everywhere', 'Custom ID', 'Task Name'].includes(typeOfSearchControl.value)">
                                    <mat-label>{{ "Group" | translate }}</mat-label>
                                    <mat-select #groupIdContainer [formControl]="groupOfSearchControl">
                                        <mat-option>
                                            <ngx-mat-select-search 
                                            placeholderLabel="{{ 'Search' | translate }}" 
                                            noEntriesFoundLabel="{{ 'no matching group found' | translate }}"
                                            [formControl]="groupMoreControl"></ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option [value]="''">
                                            {{ 'All' | translate }}
                                        </mat-option>
                                        <mat-option *ngFor="let group of groups$ | async" [value]="group.id">
                                            {{ group.name | translate }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
        
                            <ng-container formGroupName="consist_of_task_id">
                                <mat-form-field appearance="standard" class="full_width_form">
                                    <mat-label>{{ "Consist of" | translate }}</mat-label>
        
                                    <div class="chips-boundary consist_boundary">
                                        <mat-chip-list class="sorted_chips without_ordered" #consistOfChipListTemplate formControlName="add">
                                            <div class="chips_sorter_container" cdkDropListGroup #consistGroupTemplate>
                                              
                                                <div class="chips_drag_placeholder" cdkDropList (cdkDropListEntered)="entered($event, consistGroupTemplate, consistPlaceholderTemplate)" (cdkDropListDropped)="dropped(consistGroupTemplate, consistPlaceholderTemplate, form.get('consist_of_task_id').get('add').value)" #consistPlaceholderTemplate></div>
        
                                                <div *ngFor="let id of templateForm.get('consist_of_task_id').get('add').value"  
                                                cdkDropList
                                                class="sorted_chips_drop_list"
                                                (cdkDropListEntered)="entered($event, consistGroupTemplate, consistPlaceholderTemplate)"
                                                (cdkDropListDropped)="dropped(consistGroupTemplate, consistPlaceholderTemplate, templateForm.get('consist_of_task_id').get('add').value)">
                                                    <div cdkDrag cdkDragBoundary=".consist_boundary" class="sorted_chip" (cdkDragReleased)="dragReleased(consistPlaceholderTemplate, consistDragPlaceholderTemplate)">
                                                        <div class="sorted_chip_custom_placeholder" #consistDragPlaceholderTemplate *cdkDragPlaceholder></div>
                                                        <mat-chip [removable]="true" color="primary" (removed)="onRemovedTemplate(id, 'consist_of_task_id')">
                                                            <ng-container *ngIf="findTask(id)">
                                                                {{ findTask(id).name.length > 25 ? (findTask(id).name | slice:0:25) + " ..." : findTask(id).name }} ({{findTask(id)?.custom_id}})
                                                            </ng-container>
                                                            <mat-icon matChipRemove>cancel</mat-icon>
                                                        </mat-chip>
                                                    </div>
        
                                                </div>
        
                                                <input class="auto_complete_input"
                                                placeholder="{{ 'Search' | translate }}"
                                                #consistOfInputTemplate
                                                [formControl]="consistOfControlTemplate"
                                                [matAutocomplete]="consistOfAutoTemplate"
                                                [matChipInputFor]="consistOfChipListTemplate"
                                                [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
                                                
                                            </div>
                                        </mat-chip-list>
                                    </div>
        
                                    <mat-autocomplete autoActiveFirstOption #consistOfAutoTemplate="matAutocomplete" (optionSelected)="selectedTemplate($event, consistOfInputTemplate, 'consist_of_task_id')">
                                        <ng-container *ngFor="let task of tasks$ | async">
                                            <mat-option *ngIf="!templateForm.get('consist_of_task_id').get('add').value.includes(task.id) && (['Everywhere', 'Custom ID', 'Task Name'].includes(typeOfSearchControl.value) ? (groupOfSearchControl.value == task.group_id || groupOfSearchControl.value == '') : true)" [value]="task.id">
                                                <div class="task_ids_wrp_out">
                                                    <div class="task_ids_wrp"> 
                                                        <span class="text_one_line">
                                                            {{ task.name }}
                                                        </span>
                                                        <div class="task_ids">
                                                            {{task.custom_id}} 
                                                            <span>
                                                                <div>{{task.internal_id}}</div>
                                                                <div>{{task.id}}</div>
                                                            </span>
                                                        </div>
                                                    </div>
        
                                                    <div class="group text_one_line">
                                                        {{task?.group?.name}}
                                                    </div>
                                                </div>
                                            </mat-option>
                                        </ng-container>
                                        
                                    </mat-autocomplete>
                                </mat-form-field>
                            </ng-container>
                            
                            <ng-container formGroupName="part_of_task_id">
                                <mat-form-field appearance="standard" class="full_width_form">
                                    <mat-label>{{ "Part of" | translate }}</mat-label>
        
                                    <div class="chips-boundary part_boundary">
                                        <mat-chip-list class="sorted_chips without_ordered" #partOfChipListTemplate formControlName="add">
                                            <div class="chips_sorter_container" cdkDropListGroup #partGroupTemplate>
                                              
                                                <div class="chips_drag_placeholder" cdkDropList (cdkDropListEntered)="entered($event, partGroupTemplate, partPlaceholderTemplate)" (cdkDropListDropped)="dropped(partGroupTemplate, partPlaceholderTemplate, templateForm.get('part_of_task_id').get('add').value)" #partPlaceholderTemplate></div>
        
                                                <div *ngFor="let id of templateForm.get('part_of_task_id').get('add').value"  
                                                cdkDropList
                                                class="sorted_chips_drop_list"
                                                (cdkDropListEntered)="entered($event, partGroupTemplate, partPlaceholderTemplate)"
                                                (cdkDropListDropped)="dropped(partGroupTemplate, partPlaceholderTemplate, templateForm.get('part_of_task_id').get('add').value)">
                                                    <div cdkDrag cdkDragBoundary=".part_boundary" class="sorted_chip" (cdkDragReleased)="dragReleased(partPlaceholderTemplate, partDragPlaceholderTemplate)">
                                                    <div class="sorted_chip_custom_placeholder" #partDragPlaceholderTemplate *cdkDragPlaceholder></div>
                                                    <mat-chip [removable]="true" color="primary" (removed)="onRemovedTemplate(id, 'part_of_task_id')">
                                                        <ng-container *ngIf="findTask(id)">
                                                            {{ findTask(id).name.length > 25 ? (findTask(id).name | slice:0:25) + " ..." : findTask(id).name }} ({{findTask(id)?.custom_id}})
                                                        </ng-container>
                                                        <mat-icon matChipRemove>cancel</mat-icon>
                                                    </mat-chip>
                                                    </div>
        
                                                </div>
        
                                                <input class="auto_complete_input"
                                                placeholder="{{ 'Search' | translate }}"
                                                #partOfInputTemplate
                                                [formControl]="partOfControlTemplate"
                                                [matAutocomplete]="partOfAutoTemplate"
                                                [matChipInputFor]="partOfChipListTemplate"
                                                [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
                                                
                                            </div>
                                        </mat-chip-list>
                                    </div>
        
                                    <mat-autocomplete autoActiveFirstOption #partOfAutoTemplate="matAutocomplete" (optionSelected)="selectedTemplate($event, partOfInputTemplate, 'part_of_task_id')">
                                        <ng-container *ngFor="let task of tasks$ | async">
                                            <mat-option *ngIf="!templateForm.get('part_of_task_id').get('add').value.includes(task.id) && (['Everywhere', 'Custom ID', 'Task Name'].includes(typeOfSearchControl.value) ? (groupOfSearchControl.value == task.group_id || groupOfSearchControl.value == '') : true)" [value]="task.id">
                                                <div class="task_ids_wrp_out">
                                                    <div class="task_ids_wrp"> 
                                                        <span class="text_one_line">
                                                            {{ task.name }}
                                                        </span>
                                                        <div class="task_ids">
                                                            {{task.custom_id}} 
                                                            <span>
                                                                <div>{{task.internal_id}}</div>
                                                                <div>{{task.id}}</div>
                                                            </span>
                                                        </div>
                                                    </div>
        
                                                    <div class="group text_one_line">
                                                        {{task?.group?.name}}
                                                    </div>
                                                </div>
                                            </mat-option>
                                        </ng-container>
                                        
                                    </mat-autocomplete>
                                </mat-form-field>
                            </ng-container>
        
                            <ng-container formGroupName="related_task_id">
                                <mat-form-field appearance="standard" class="full_width_form">
                                    <mat-label>{{ "Related" | translate }}</mat-label>
        
                                    <div class="chips-boundary related_boundary">
                                        <mat-chip-list class="sorted_chips without_ordered" #relatedOfChipListTemplate formControlName="add">
                                            <div class="chips_sorter_container" cdkDropListGroup #relatedGroupTemplate>
                                              
                                                <div class="chips_drag_placeholder" cdkDropList (cdkDropListEntered)="entered($event, relatedGroupTemplate, relatedPlaceholderTemplate)" (cdkDropListDropped)="dropped(relatedGroupTemplate, relatedPlaceholderTemplate, templateForm.get('related_task_id').get('add').value)" #relatedPlaceholderTemplate></div>
        
                                                <div *ngFor="let id of templateForm.get('related_task_id').get('add').value"  
                                                cdkDropList
                                                class="sorted_chips_drop_list"
                                                (cdkDropListEntered)="entered($event, relatedGroupTemplate, relatedPlaceholderTemplate)"
                                                (cdkDropListDropped)="dropped(relatedGroupTemplate, relatedPlaceholderTemplate, templateForm.get('related_task_id').get('add').value)">
                                                    <div cdkDrag cdkDragBoundary=".related_boundary" class="sorted_chip" (cdkDragReleased)="dragReleased(relatedPlaceholderTemplate, relatedDragPlaceholder)">
                                                        <div class="sorted_chip_custom_placeholder" #relatedDragPlaceholder *cdkDragPlaceholder></div>
                                                        <mat-chip [removable]="true" color="primary" (removed)="onRemovedTemplate(id, 'related_task_id')">
                                                            <ng-container *ngIf="findTask(id)">
                                                                {{ findTask(id).name.length > 25 ? (findTask(id).name | slice:0:25) + " ..." : findTask(id).name }} ({{findTask(id)?.custom_id}})
                                                            </ng-container>
                                                            <mat-icon matChipRemove>cancel</mat-icon>
                                                        </mat-chip>
                                                    </div>
        
                                                </div>
        
                                                <input class="auto_complete_input"
                                                placeholder="{{ 'Search' | translate }}"
                                                #relatedOfInputTemplate
                                                [formControl]="relativeControlTemplate"
                                                [matAutocomplete]="relatedOfAutoTemplate"
                                                [matChipInputFor]="relatedOfChipListTemplate"
                                                [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
                                                
                                            </div>
                                        </mat-chip-list>
                                    </div>
        
                                    <mat-autocomplete autoActiveFirstOption #relatedOfAutoTemplate="matAutocomplete" (optionSelected)="selectedTemplate($event, relatedOfInputTemplate, 'related_task_id')">
                                        <ng-container *ngFor="let task of tasks$ | async">
                                            <mat-option *ngIf="!templateForm.get('related_task_id').get('add').value.includes(task.id) && (['Everywhere', 'Custom ID', 'Task Name'].includes(typeOfSearchControl.value) ? (groupOfSearchControl.value == task.group_id || groupOfSearchControl.value == '') : true)" [value]="task.id">
                                                <div class="task_ids_wrp_out">
                                                    <div class="task_ids_wrp"> 
                                                        <span class="text_one_line">
                                                            {{ task.name }}
                                                        </span>
                                                        <div class="task_ids">
                                                            {{task.custom_id}} 
                                                            <span>
                                                                <div>{{task.internal_id}}</div>
                                                                <div>{{task.id}}</div>
                                                            </span>
                                                        </div>
                                                    </div>
        
                                                    <div class="group text_one_line">
                                                        {{task?.group?.name}}
                                                    </div>
                                                </div>
                                            </mat-option>
                                        </ng-container>
                                        
                                    </mat-autocomplete>
                                </mat-form-field>
                            </ng-container>
                        </div>
                    </div>
                </form>
            </div>
        </ng-template>  
    </div>
</mat-dialog-content> -->
