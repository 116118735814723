import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseClass } from 'src/app/shared/models/base-class';
import { QuestsService } from 'src/app/shared/services/rest/quests.service';

@Component({
  selector: 'app-add-quest-to-empl',
  templateUrl: './add-quest-to-empl.component.html',
  styleUrls: ['./add-quest-to-empl.component.scss']
})
export class AddQuestToEmplComponent extends BaseClass implements OnInit, OnDestroy  {
  public form: FormGroup;
  public isSubmit: boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddQuestToEmplComponent>,
    private fb: FormBuilder,
    private questsService: QuestsService
  ) { super() }

  ngOnInit(): void {

    console.log(this.data)

    this.form = this.fb.group({
      company_id: this.data.company.id,
      employee_id: [[], Validators.required],
      application_id: [[], Validators.required]
    })

    if (this.data.employee_id) {
      this.form.patchValue({
        employee_id: [this.data.employee_id]
      })
    }
  }

  getEmployeeById(id) {
    return this.data.employees.find(x => x.id == id)
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  onSubmit() {
    this.isSubmit = true;
    this.attachSubscriptions(
      this.questsService.applyToEmployee(this.form.value).subscribe(resp => {
        this.isSubmit = false;
        this.dialogRef.close({event: "add", data: resp});
      })
    )
  }

  ngOnDestroy() {
    this.clearSubscriptions()
  }
}