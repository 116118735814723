<h2 mat-dialog-title class="dialog_title">
    <span>
        {{ "Edditing a file size limit" | translate }}: {{data.name}}
    </span>
    <button mat-button (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h2>
<mat-dialog-content class="mat-typography" dirEnter (dirSubmit)="!form.valid ? null : onSubmit()">
    <div class="container">
        <form [formGroup]="form">
            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ 'File size limit' | translate }}</mat-label>
                <input cdkFocusInitial formControlName="filesize_limit" matInput type="number" placeholder="{{ 'File size limit' | translate }}">
            </mat-form-field>
        </form>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button (click)="close()">
        {{ "Back" | translate }}
    </button>
    <button class="ml-1" (click)="onSubmit()" mat-raised-button cdkFocusInitial color="primary" [disabled]="!form.valid">
        {{ "Save" | translate }}
    </button>
</mat-dialog-actions>
