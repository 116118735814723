import { Overlay, OverlayContainer, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { Component, ElementRef, HostListener, Inject, LOCALE_ID, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { mimeTypes } from 'mime-wrapper';
import { concat, forkJoin, from, fromEvent, interval, of, ReplaySubject, Subscription, throwError } from 'rxjs';
import { catchError, concatMap, debounceTime, distinctUntilChanged, filter, last, map, retry, switchMap, take, tap } from 'rxjs/operators';
import { AddAdditionalEmployeesComponent } from 'src/app/components/additionalEmpl/add-additional-employees/add-additional-employees.component';
import { AdditionalEmployeesComponent } from 'src/app/components/additionalEmpl/additional-employees/additional-employees.component';
import { CheckListComponent } from 'src/app/components/atTasksDialog/check-list/check-list.component';
import { CreateOperationChatComponent } from 'src/app/components/atTasksDialog/create-operation-chat/create-operation-chat.component';
import { TaskGroupEditComponent } from 'src/app/components/atTasksDialog/group-edit/group-edit.component';
import { OperationChatsComponent } from 'src/app/components/atTasksDialog/operation-chats/operation-chats.component';
import { EditChannelComponent } from 'src/app/components/atTasksDialog/profiles/edit-channel/edit-channel.component';
import { EditContentNameComponent } from 'src/app/components/atTasksDialog/profiles/edit-content-name/edit-content-name.component';
import { EditPublishedAtComponent } from 'src/app/components/atTasksDialog/profiles/edit-published-at/edit-published-at.component';
import { EditStatusComponent } from 'src/app/components/atTasksDialog/profiles/edit-status/edit-status.component';
import { TaskChatsComponent } from 'src/app/components/atTasksDialog/task-chats/task-chats.component';
import { TaskCommentEditComponent } from 'src/app/components/atTasksDialog/task-comment-edit/task-comment-edit.component';
import { TaskEditComponent } from 'src/app/components/atTasksDialog/task-edit/task-edit.component';
import { TaskIdEditComponent } from 'src/app/components/atTasksDialog/task-id-edit/task-id-edit.component';
import { TaskStatusEditComponent } from 'src/app/components/atTasksDialog/task-status-edit/task-status-edit.component';
import { WorkAddComponent } from 'src/app/components/atTasksDialog/work-add/work-add.component';
import { WorkCommentEditComponent } from 'src/app/components/atTasksDialog/work-comment-edit/work-comment-edit.component';
import { WorkCompletedAtEditComponent } from 'src/app/components/atTasksDialog/work-completed-at-edit/work-completed-at-edit.component';
import { WorkEditComponent } from 'src/app/components/atTasksDialog/work-edit/work-edit.component';
import { WorkEmplAddComponent } from 'src/app/components/atTasksDialog/work-empl-add/work-empl-add.component';
import { WorkEmplEditComponent } from 'src/app/components/atTasksDialog/work-empl-edit/work-empl-edit.component';
import { WorkEmplStatusEditComponent } from 'src/app/components/atTasksDialog/work-empl-status-edit/work-empl-status-edit.component';
import { WorkEmployeeCommentEditComponent } from 'src/app/components/atTasksDialog/work-employee-comment-edit/work-employee-comment-edit.component';
import { WorkEmployeeEditComponent } from 'src/app/components/atTasksDialog/work-employee-edit/work-employee-edit.component';
import { WorkOperationIdEditComponent } from 'src/app/components/atTasksDialog/work-operation-id-edit/work-operation-id-edit.component';
import { WorkRateEditComponent } from 'src/app/components/atTasksDialog/work-rate-edit/work-rate-edit.component';
import { WorkStatusEditComponent } from 'src/app/components/atTasksDialog/work-status-edit/work-status-edit.component';
import { TaskClientsComponent } from 'src/app/components/clients/task-clients/task-clients.component';
import { JobAutomationComponent } from 'src/app/components/workspace-pages/jobs/dialogs/job-automation/job-automation.component';
import { casesModel } from 'src/app/shared/functions/casesModel';
import { ConnectionsModalComponent } from 'src/app/shared/global_components/connections-modal/connections-modal.component';
import { DeleteAlertComponent } from 'src/app/shared/global_components/delete-alert/delete-alert.component';
import { InterfaceComponent } from 'src/app/shared/global_components/file-manager/dialog/interface/interface.component';
import { VideoViewerComponent } from 'src/app/shared/global_components/file-manager/dialog/video-viewer/video-viewer.component';
import { NotificationsBar } from 'src/app/shared/global_components/notifications-bar/notifications-bar';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { BaseClass } from 'src/app/shared/models/base-class';
import { GlobalDataService } from 'src/app/shared/services/common/global-data.service';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { AuthService } from 'src/app/shared/services/rest/auth.service';
import { ChatService } from 'src/app/shared/services/rest/chat.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { FileService } from 'src/app/shared/services/rest/file.service';
import { MembersService } from 'src/app/shared/services/rest/members.service';
import { RefreshService } from 'src/app/shared/services/rest/refresh.service';
import { ScenariosService } from 'src/app/shared/services/rest/scenarios.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { UploadService } from 'src/app/shared/services/rest/upload.service';
import { ExecutorsComponent } from '../executors/executors.component';
import { TaskManagerAddComponent } from '../managers/task-manager-add/task-manager-add.component';
import { TaskManagersComponent } from '../managers/task-managers/task-managers.component';
import { SetEmplComponent } from '../members/set-empl/set-empl.component';
import { MoveJobsComponent } from '../move-jobs/move-jobs.component';
import { OpenJobComponent } from '../open-job/open-job.component';
import { StatusReasonComponent } from '../status-reason/status-reason.component';
import { ToApproveStepperComponent } from '../to-approve-stepper/to-approve-stepper.component';
import { Clipboard } from '@angular/cdk/clipboard';
import { ChatsComponent } from 'src/app/components/chats/chats.component';
import { TargetParametersComponent } from 'src/app/components/atTasksDialog/target-parameters/target-parameters.component';
import { SelectionModel } from '@angular/cdk/collections';
import { BackJobToPrevStatusComponent } from 'src/app/shared/global_components/back-job-to-prev-status/back-job-to-prev-status.component';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { AddChannelToTaskComponent } from 'src/app/components/atTasksDialog/add-channel-to-task/add-channel-to-task.component';
import { AddTaskProfileComponent } from './dialogs/add-task-profile/add-task-profile.component';
import { MultiEditProfilesStatusComponent } from '../../../urls/dialogs/multi-edit-profiles-status/multi-edit-profiles-status.component';
import { MultiEditJobsTypeComponent } from '../../../jobs/dialogs/multi-edit-jobs-type/multi-edit-jobs-type.component';
import { MultiJobsAssignScenarioComponent } from '../../../jobs/dialogs/multi-jobs-assign-scenario/multi-jobs-assign-scenario.component';
import { PermissionsPipe } from 'src/app/shared/pipes/permissions.pipe';
import { UrlAnalyticsComponent } from './dialogs/url-analytics/url-analytics.component';
import { EditOperationChatComponent } from 'src/app/components/atTasksDialog/edit-operation-chat/edit-operation-chat.component';
import { TaskBarService } from 'src/app/shared/services/rest/task-bar.service';
import { AddChatToBoardComponent } from '../../../chats-page/dialogs/add-chat-to-board/add-chat-to-board.component';
import { MoveToOtherJobComponent } from 'src/app/shared/global_components/chat/dialogs/move-to-other-job/move-to-other-job.component';
import { MobAddTaskProfileComponent } from './dialogs/mob-add-task-profile/mob-add-task-profile.component';
import { MobEditTaskProfileComponent } from './dialogs/mob-edit-task-profile/mob-edit-task-profile.component';
import { MobUrlAnalyticsComponent } from './dialogs/mob-url-analytics/mob-url-analytics.component';
import { MobAddJobComponent } from 'src/app/components/mob-job/mob-add-job/mob-add-job.component';
import { MobFmViewComponent } from 'src/app/shared/global_components/mob-file-manager/dialogs/mob-fm-view/mob-fm-view.component';
import * as moment from 'moment';
import { MobChangePersonalStatusComponent } from './dialogs/mob-change-personal-status/mob-change-personal-status.component';
import { MobPriorityComponent } from 'src/app/shared/global_components/chat/dialogs/mob-priority/mob-priority.component';
import { MobTitleComponent } from 'src/app/shared/global_components/chat/dialogs/mob-title/mob-title.component';
import { MobChatOptionsComponent } from 'src/app/shared/global_components/chat/dialogs/mob-chat-options/mob-chat-options.component';
import { ChatConnectionsComponent } from '../../../chats-page/dialogs/chat-connections/chat-connections.component';
import { MobQuestionComponent } from 'src/app/shared/global_components/mob-question/mob-question.component';
import { JobEditComponent } from 'src/app/components/mob-job/job-edit/job-edit.component';
import { MobTaskEditComponent } from 'src/app/components/mob-task/mob-task-edit/mob-task-edit.component';
import { MinimizeService } from 'src/app/shared/services/rest/minimize.service';
import { JobEditTypeComponent } from 'src/app/components/mob-job/job-edit-type/job-edit-type.component';
import { JobEditCompletedAtComponent } from 'src/app/components/mob-job/job-edit-completed-at/job-edit-completed-at.component';
import { environment } from 'src/environments/environment';
import 'moment/locale/es';
import 'moment/locale/en-gb';
import 'moment/locale/uk';
import 'moment/locale/ru';
import { AddJobToBoardComponent } from '../../../jobs/dialogs/add-job-to-board/add-job-to-board.component';
import { NotesComponent } from 'src/app/shared/global_components/notes/notes.component';
import { EmplCreateTaskComponent } from 'src/app/components/atTasksDialog/empl-create-task/empl-create-task.component';
import { CreateTaskComponent } from 'src/app/components/atTasksDialog/create-task/create-task.component';
import { CommentsComponent } from 'src/app/shared/global_components/comments/comments.component';
import { SharedOptionsComponent } from 'src/app/shared/global_components/shared-options/shared-options.component';
import { TagTaskComponent } from 'src/app/shared/global_components/tag-task/tag-task.component';
import { MobPushSettingsComponent } from 'src/app/shared/global_components/chat/dialogs/mob-push-settings/mob-push-settings.component';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { PostToPlanComponent } from './dialogs/post-to-plan/post-to-plan.component';
import { ParametersService } from 'src/app/shared/services/rest/parameters.service';
import { CardScenarioComponent } from 'src/app/components/atTasksDialog/card-scenario/card-scenario.component';
import { TaskAddClientComponent } from 'src/app/components/clients/task-add-client/task-add-client.component';
import { MobAssignmentsComponent } from 'src/app/components/mob-job/mob-assignments/mob-assignments.component';
import { MultiJobsExecScenariosComponent } from '../../../jobs/dialogs/multi-jobs-exec-scenarios/multi-jobs-exec-scenarios.component';
import { LiteJobComponent } from '../lite-job/lite-job.component';
import { OperationChatComponent } from 'src/app/components/atTasksDialog/operation-chat/operation-chat.component';
import { MigrateTargetFilesComponent } from 'src/app/shared/global_components/migrate-target-files/migrate-target-files.component';
import { AddEditLiveStreamComponent } from './dialogs/add-edit-live-stream/add-edit-live-stream.component';
import { LivesSettingsComponent } from './dialogs/lives-settings/lives-settings.component';
import { ServersTableComponent } from './dialogs/servers-table/servers-table.component';
import { LoadingService } from 'src/app/shared/services/rest/loading.service';
import { AskTextComponent } from 'src/app/shared/global_components/ask-text/ask-text.component';
import { PostExtraInfoComponent } from 'src/app/shared/global_components/post-extra-info/post-extra-info.component';
import { SetPrivacyStatusComponent } from '../../../urls/dialogs/set-privacy-status/set-privacy-status.component';
import { CardClipboardComponent } from './dialogs/card-clipboard/card-clipboard.component';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { SelectFileComponent } from 'src/app/shared/global_components/file-manager/dialog/select-file/select-file.component';
import { AddAutoLivesComponent } from 'src/app/components/atTasksDialog/add-auto-lives/add-auto-lives.component';
registerLocaleData(localeFr);

@Component({
  selector: 'app-open-task',
  templateUrl: './open-task.component.html',
  styleUrls: ['./open-task.component.scss'],
  providers: [
    { provide: LOCALE_ID, useValue: 'fr-FR'},
  ]
})
export class OpenTaskComponent extends BaseClass implements OnInit, OnDestroy {
  @ViewChild('uploaderFolder') uploaderFolder: ElementRef<HTMLInputElement>;
  @ViewChild('uploader') uploader: ElementRef<HTMLInputElement>;
  @ViewChild('taskMenu') taskMenu: TemplateRef<any>;
  public tab: number = 0;
  public overlayRef: OverlayRef | null;
  public sub: Subscription;
  public timeZone = new Date().getTimezoneOffset();
  public view: any[] = [156, 54];
  public colorTimeScheme = {
    domain: ['#2ea5cb']
  };
  public origin = window.location.origin;
  public is_scheme_editing: boolean = false;
  public isRefreshed: boolean = false;
  public disableTemplate: boolean = false;
  public post_delete_after: boolean = this.sm.localStorageGetItem('post_delete_after') ? true : false;
  public post_backup_after: boolean = this.sm.localStorageGetItem('post_backup_after') ? true : false;
  public post_link_after: boolean = this.sm.localStorageGetItem('post_link_after') ? true : false;
  public post_status_after: any = this.sm.localStorageGetItem('post_status_after') ? +this.sm.localStorageGetItem('post_status_after') : 6;
  public selectedJob: any;
  public profileStatuses: any;
  public operationsValues: any;
  public chatPage: number = 1;
  public count: number = 1;
  public chatPagination: any;
  public company_id: any;
  public company: any;

  public gettingTaskSub: Subscription;
  public liteFmSub: Subscription;
  public liteFmOverlayRef: OverlayRef | null;
  @ViewChild('liteFmMenu') liteFmMenu: TemplateRef<any>;

  public today = moment().set({hour:0,minute:0,second:0}).unix();
  public todayEnd = moment().endOf('day').unix();

  public templates: any;
  public templatesControl: FormControl = new FormControl();
  public templates$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public jobCollection: any;
  public imgRoute: any;
  public user: any;
  public host: any = environment.host;
  public activeLang: any;
  public groups: any;
  // public task: any = !!this.data.task ? this.data.task : undefined;
  
  public task: any = !!this.data.task ? JSON.parse(JSON.stringify(this.data.task, this.getCircularReplacer())) : undefined;
  
  public taskStatuses: any;
  public statuses: any;
  public profileCollection: any;
  public profilesMenu: boolean = false;
  public cstMenu: boolean = false;
  public is_mobile: boolean = false;
  public searchIsOpen: boolean = false;
  public isVisible: boolean = true;
  public sortValue: string = 'custom_desc';
  public isEditedDescription: boolean = false;
  public uploadLimit: boolean = false;
  public isManager:boolean = false;
  public selectedEplForNewStatus: any[] = [];
  public curYear = (new Date()).getFullYear();

  public channelsTab: number = -1;

  public jobLoadSub: Subscription;
  public taskPostsSub: Subscription;

  public jobsTabForm: FormGroup;
  
  public jobsStatsControl: FormControl = new FormControl(0);
  public jobsControl: FormControl = new FormControl('');
  public jobs$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public paramControl: FormControl = new FormControl('');
  public parameters$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public parameters: any;
  public allValues: any;
  public tabs: any;
  // public clients: any;
  public bookmarks: any;
  public tabsSaved: boolean = true;
  public viewMode: number = 0;

  public uniqueNoPablChan = {
    related: {
      arr: [],
      ids: []
    },
    consist: {
      arr: [],
      ids: []
    },
    part: {
      arr: [],
      ids: []
    }
  }
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<OpenTaskComponent>,
    private taskService: TaskService,
    private minimizeService: MinimizeService,
    private dialog: MatDialog,
    private scenariosService: ScenariosService,
    private companyService: CompanyService,
    private sm: StorageManagerService,
    private bottomSheet: MatBottomSheet,
    private chatService: ChatService,
    private parametersService: ParametersService,
    private refreshService: RefreshService,
    private globalDataService: GlobalDataService,
    private layoutService: LayoutService,
    private fileService: FileService,
    public overlay: Overlay,
    private uploadService: UploadService,
    private membersService: MembersService,
    private taskBarService: TaskBarService,
    private clipboard: Clipboard,
    public viewContainerRef: ViewContainerRef,
    private router: Router,
    private alldialogRef: MatDialog,
    private auth: AuthService,
    private fb: FormBuilder,
    private ls: LoadingService,
    private languageService: LanguageService,
    private activatedRoute: ActivatedRoute
  ) { super() }

  ngOnInit(): void {
    if (!!this.sm.localStorageGetItem('card_jobs')) {
      this.viewMode = +this.sm.localStorageGetItem('card_jobs');
    }
    this.data.isTaskDialog = true;
    this.checkIsMobile();
    
    if (this.data.is_shared) {
      this.task = this.data.sharedData
    } else {
      this.jobsTabForm = this.fb.group({
        parameter_id : '',
        parameter_value_id: ''
      })

      this.attachSubscriptions(
        this.jobsTabForm.get('parameter_id').valueChanges.subscribe(resp => {
          console.log("parameter_id", resp)
          this.jobsTabForm.patchValue({
            parameter_value_id: ''
          })
          if (this.allValues) {
            this.tabs = this.allValues.filter(x => x.parameter_id == resp)
            this.tabsSaved = false;
          }
        })
      )

      this.attachSubscriptions(
        this.jobsTabForm.get('parameter_value_id').valueChanges.subscribe(resp => {
          console.log("this.jobsTabForm.get('parameter_value_id')", resp);
          this.sort(this.sortValue)
        })
      )

      console.log("OpenTaskComponent", this.data);
      // console.log("alldialogRef", this.alldialogRef.openDialogs);
      // console.log("alldialogRef", this.alldialogRef.openDialogs.filter(x => x.componentInstance.data.task_id == this.data.task_id));
      // if (this.alldialogRef.openDialogs.filter(x => x.componentInstance.data.task_id == this.data.task_id).length > 1) {
      //   this.alldialogRef.openDialogs.filter(x => x.componentInstance.data.task_id == this.data.task_id)[0].close()
      // }
      if (this.alldialogRef.openDialogs.filter(x => x.componentInstance.data.isTaskDialog).length > 1) {
        this.alldialogRef.openDialogs.filter(x => x.componentInstance.data.isTaskDialog)[0].close()
      }
      console.log("alldialogRef isTaskDialog", this.alldialogRef.openDialogs.filter(x => x.componentInstance.data.isTaskDialog));
      console.log("alldialogRef", this.alldialogRef.openDialogs.filter(x => x.componentInstance.data.task_id == this.data.task_id));
      this.company_id = this.activatedRoute.snapshot.queryParamMap.get('company_id');
      if (this.data.company) {
        this.company = this.data.company;
      }
      if (this.data.initCompanyId) {
        this.company_id = this.data.initCompanyId
      }
    
      this.getCompany();
      
      if (!!this.data.task && this.data.task.operations && this.data.task.operations.length > 0) {
        this.jobs$.next(this.data.task.operations.sort((a,b) => {
          return a.order - b.order;
        }).slice())
      }
  
      if (!!this.data.task && !!this.data.task.operations) {
        this.data.task.operations.forEach(element => {
          element.mobEmployees = [];
          let allEmployees = element.employees.filter(x => x.task_operation_id == element.id);
          allEmployees.forEach(empl => {
            if (!element.mobEmployees.find(x => x.employee_id == empl.employee_id)) {
              element.mobEmployees.push(empl)
            }
          });
  
          let allAddEmployee = element.employees.filter(x => x.task_operation_id == element.id && (x.is_manager == 0 || (x.is_manager == 1 && x.company_id != element.company_id)));
          element.uniqueAddEmployee = []
          allAddEmployee.forEach(empl => {
            if (!element.uniqueAddEmployee.find(x => x.employee_id == empl.employee_id)) {
              element.uniqueAddEmployee.push(empl)
            }
          });
        })
      }
  
      this.profileCollection = new SelectionModel(
        true,
        []
      )
  
      this.jobCollection = new SelectionModel(
        true,
        []
      )
  
      this.getUser();
  
      this.getOperationsStatus();
      this.getTask();
      this.getLangData();
      
      this.getImgRoute();
      this.getTaskStatus();
      this.getGroupsCompany();
      this.getAutomationTemplates();
      this.getProfilesStatus();
      
      this.attachSubscriptions(
        this.jobsControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchJobs(resp))
      )
      this.attachSubscriptions(
        this.paramControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchParams(resp))
      )
      this.attachSubscriptions(
        this.templatesControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchTemplates(resp))
      )
  
      this.attachSubscriptions(
        this.refreshService.getTaskCard().subscribe(resp => {
          if (resp.company_id == this.company.id && this.data.task_id == resp.task_id) {
            this.getTask()
          }
        })
      )
  
      this.attachSubscriptions(
        interval(7500).pipe(
          filter(() => this.channelsTab >= 0 && !!this.isVisible && !!this.task && !!this.task.meta && !!this.task.meta.task_channels_by_platform_arr && !!this.task.meta.task_channels_by_platform_arr.length && this.returnObjByVal(this.task.meta.task_channels_by_platform_arr) && this.returnObjByVal(this.task.meta.task_channels_by_platform_arr).arr && this.returnObjByVal(this.task.meta.task_channels_by_platform_arr).arr.filter(x => [6,7,9].includes(x.content_status_id)).length > 0 && (this.alldialogRef.openDialogs.filter(x => x.componentInstance.data.isTaskDialog).length > 0 && this.alldialogRef.openDialogs.lastIndexOf(this.alldialogRef.openDialogs.find(x => x.componentInstance.data.isTaskDialog)) == this.alldialogRef.openDialogs.length - 1)),
          switchMap(() => {
            return this.taskService.getOneTaskExpandNoChan(this.company_id, this.data.task_id).pipe(
              map(x => x.body[0]),
              switchMap(x => this.neededData(x).pipe(map(() => casesModel([x], [], 'update')),map(x => x.arr[0]))),
              tap(task => {

                if (this.task && this.task.meta) {
                  console.log("TEST", this.task, this.task.meta)
                  let u = JSON.parse(JSON.stringify(this.task.meta, this.getCircularReplacer()));
                  this.task.meta = task.meta;
                  if (u && u.task_channels_by_platform_arr) {
                    u.task_channels_by_platform_arr.forEach(el => {
                      if (el.arr && el.arr.length && this.task.meta.task_channels_by_platform_arr.find(p => p.platform_id == el.platform_id)) {
                        this.task.meta.task_channels_by_platform_arr.find(p => p.platform_id == el.platform_id).arr = el.arr;
                      }
                    });
                  }
                }
              })
            )
          })
        ).subscribe(resp => {
          if (!this.is_mobile) {

            if (this.task && this.task.meta) {
              let u = JSON.parse(JSON.stringify(this.task.meta, this.getCircularReplacer()));
              this.activeTab(null, {platform_id: this.channelsTab}, u)
            } else {
              this.activeTab(null, {platform_id: this.channelsTab})
            }
          }
          // console.log("this.dialog", this.dialog)
          console.log("this.dialog OpenTaskComponent", this.dialog.openDialogs)
          // console.log('Диалоговое окно активно:', this.overlayContainer.getContainerElement().classList.contains('cdk-overlay-pane'));
          // if (this.is_mobile) {
          //   this.attachSubscriptions(
          //     forkJoin([...this.task.channels.filter(x => x.external_content_id && x.content_published_at).map(channel => this.taskService.getContentAnlytics(this.company_id, {
          //       start_time_from: channel.content_published_at,
          //       period: 'day',
          //       totals: '1',
          //       order_by: 'start_time',
          //       'per-page': 1,
          //       content_id: channel.external_content_id,
          //     }, "1").pipe(
          //       tap(el => {
          //         channel.summaryAveragePercentageViewed = Math.round(+el.headers.get('x-summary-average-view-percentage'))
          //         channel.summaryAverageViewDuration = +el.headers.get('x-summary-average-view-duration')
          //       })
          //     ))]).subscribe(res => {
          //       console.log("this.task.channels", this.task.channels)
          //     })
          //   )
          // }
  
          console.log("RESP interval", this.task);
        })
      )
  
      this.attachSubscriptions(
        this.jobsStatsControl.valueChanges.subscribe((resp) => {
          this.onFilterByStatusJobs(resp)
        })
      )
      this.getParameters()
    }

    // if (!this.is_mobile) {
    //   this.getClients();
    // }
  }

  getConnectedCards() {
    this.gettingTaskSub = this.refreshService.getIsTaskGetting().pipe(
      tap(tapdata => console.log("getIsTaskGetting", tapdata)),
      filter(resp => resp.company_id == this.company.id && this.data.task_id == resp.task_id),
      switchMap(x => {
        let arr = [];
        if (this.task.consist_of_count) {
          arr.push(
            this.taskService.getCardsExpandForCard('1', this.company_id, {part_of_task_id: this.task.id}, '30').pipe(
              tap(el => {
                if (el.headers.get('x-pagination-current-page') == 1) {
                  this.task.c_o_count = el.headers.get('x-pagination-total-count');
                  this.task.consist_of_Pagination = {
                    'pageCount': +el.headers.get('x-pagination-page-count'),
                    'perPage': +el.headers.get('x-pagination-per-page'),
                    'totalCount': +el.headers.get('x-pagination-total-count'),
                    'currentPage': +el.headers.get('x-pagination-current-page'),
                  }
                  if (el.body.length < 30) {
                    this.task.consist_of_Pagination.getAll = true
                  }
                }
              }),
              map(p => p.body),
              tap(tasksRes => {
                this.task.partOfTasks = [...tasksRes];
                
                this.task.partOfTasks.forEach(el => {
                  el.previewImgs = [];
                  if (el.meta && el.meta.task_channels_by_platform) {
                    el.meta.task_channels_by_platform_arr = Object.values(el.meta.task_channels_by_platform).filter((x:any) => !!x.platform_id);
                  }
                  if (el.channels) {
                    el.channels.forEach(element => {
                      if (!element.content_url) {
                        if (this.uniqueNoPablChan.part.ids.filter(id => id == element.channel_id).length == 0) {
                          this.uniqueNoPablChan.part.arr.push(element)
                          this.uniqueNoPablChan.part.ids.push(element.channel_id)
                        }
                      }
                      if (!!element.file && !!element.file.original) {
                        el.previewImgs.push({
                            is_parser_url: false,
                            src: element.file.original
                          })
                      } else if (!!element.content_image) {
                        el.previewImgs.push({
                          is_parser_url: true,
                          src: element.content_image
                        })
                      }
                    })
                    el.channels.sort((a,b) => {
                      return b.content_views_count - a.content_views_count;
                    })
                  }
                });
              })
            )
          )
        } else {
          this.task.partOfTasks = []
        }
    
        if (this.task.part_of_count) {
          arr.push(
            this.taskService.getCardsExpandForCard('1', this.company_id, {consist_of_task_id: this.task.id}, '30').pipe(
              tap(el => {
                if (el.headers.get('x-pagination-current-page') == 1) {
                  this.task.p_o_count = el.headers.get('x-pagination-total-count');
                  this.task.part_of_Pagination = {
                    'pageCount': +el.headers.get('x-pagination-page-count'),
                    'perPage': +el.headers.get('x-pagination-per-page'),
                    'totalCount': +el.headers.get('x-pagination-total-count'),
                    'currentPage': +el.headers.get('x-pagination-current-page'),
                  }
                  if (el.body.length < 30) {
                    this.task.part_of_Pagination.getAll = true
                  }
                }
              }),
              map(p => p.body),
              tap(tasksRes => {
                this.task.consistOfTasks = [...tasksRes];
                this.task.consistOfTasks.forEach(el => {
                  if (el.meta && el.meta.task_channels_by_platform) {
                    el.meta.task_channels_by_platform_arr = Object.values(el.meta.task_channels_by_platform).filter((x:any) => !!x.platform_id);
                  }
                  el.previewImgs = [];
                  if (el.channels) {
                    el.channels.forEach(element => {
                      if (!element.content_url) {
                        if (this.uniqueNoPablChan.consist.ids.filter(id => id == element.channel_id).length == 0) {
                          this.uniqueNoPablChan.consist.arr.push(element)
                          this.uniqueNoPablChan.consist.ids.push(element.channel_id)
                        }
                      }
                      if (!!element.file && !!element.file.original) {
                        el.previewImgs.push({
                            is_parser_url: false,
                            src: element.file.original
                          })
                      } else if (!!element.content_image) {
                        el.previewImgs.push({
                          is_parser_url: true,
                          src: element.content_image
                        })
                      }
                    })
                    el.channels.sort((a,b) => {
                      return b.content_views_count - a.content_views_count;
                    })
                  }
                });
              })
            )
          )
        } else {
          this.task.consistOfTasks = []
        }
    
        if (this.task.related_count) {
          arr.push(
            this.taskService.getCardsExpandForCard('1', this.company_id, {related_task_id: this.task.id}, '30').pipe(
              tap(el => {
                if (el.headers.get('x-pagination-current-page') == 1) {
                  this.task.rel_count = el.headers.get('x-pagination-total-count');
                  this.task.related_Pagination = {
                    'pageCount': el.headers.get('x-pagination-page-count'),
                    'perPage': el.headers.get('x-pagination-per-page'),
                    'totalCount': el.headers.get('x-pagination-total-count'),
                    'currentPage': el.headers.get('x-pagination-current-page'),
                  }
                  if (el.body.length < 30) {
                    this.task.related_Pagination.getAll = true
                  }
                }
              }),
              map(p => p.body),
              tap(tasksRes => {
                this.task.relatedTasks = [...tasksRes];
    
                this.task.relatedTasks.forEach(el => {
                  if (el.meta && el.meta.task_channels_by_platform) {
                    el.meta.task_channels_by_platform_arr = Object.values(el.meta.task_channels_by_platform).filter((x:any) => !!x.platform_id);
                  }
                  el.previewImgs = [];
                  if (el.channels) {
                    el.channels.forEach(element => {
                      if (!element.content_url) {
                        if (this.uniqueNoPablChan.related.ids.filter(id => id == element.channel_id).length == 0) {
                          this.uniqueNoPablChan.related.arr.push(element)
                          this.uniqueNoPablChan.related.ids.push(element.channel_id)
                        }
                      }
                      if (!!element.file && !!element.file.original) {
                        el.previewImgs.push({
                            is_parser_url: false,
                            src: element.file.original
                          })
                      } else if (!!element.content_image) {
                        el.previewImgs.push({
                          is_parser_url: true,
                          src: element.content_image
                        })
                      }
                    })
                    el.channels.sort((a,b) => {
                      return b.content_views_count - a.content_views_count;
                    })
                  }
                });
              })
            )
          )
        } else {
          this.task.relatedTasks = []
        }
        
        if (arr.length) {
          return forkJoin(arr)
        } else {
          return of(x)
        }
      })
    ).subscribe(resp => {
      console.log("gettingTaskSub", resp)
    })
  }

  onSearchTemplates(resp) {
    if (!this.templates) {
      return;
    }

    if (!resp) {
      this.templates$.next(this.templates.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.templates$.next(
      this.templates.filter(z => z.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  getTabJobsCount(tab) {
    // console.log(tab, jobs);
    return this.task.operations.filter(x => !!x.parameterValuesToTask && x.parameterValuesToTask.filter(u => u.parameter_value_id == tab.id).length > 0).length
  }

  // getClients() {
  //   this.taskService.getTaskClients(this.task.id, this.company_id).subscribe(resp => {
  //     console.log("getClients resp", resp);
  //     console.log("getClients comp", this.company);
  //     this.clients = resp;
  //   })
  // }

  activeTab(e, item, copyMeta?) {
    if (!!e) {
      e.preventDefault();
      e.stopPropagation();
    }
    this.profileCollection.clear();
    this.channelsTab = item.platform_id;


    this.getTabPosts(copyMeta);
  }

  log() {
    console.log(this.task)
  }

  returnObjByVal(arr) {
    return arr.find(u => u.platform_id == this.channelsTab)
  }

  postToPlan(element, profile) {
    const dialogRef = this.dialog.open(PostToPlanComponent, {
      disableClose: true,
      data: {
        company: this.company,
        company_id: this.company_id,
        task: element,
        profile: profile
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result && result.event == 'edit') {
          this.getTask(true);
        }
      })
    )
  }

  removePlan(profile) {
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: profile,
        target: marker("Plan")
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        this.attachSubscriptions(
          this.companyService.editScheduledPost(profile.contentPlan.id, {task_id: 0, task_channel_id: 0}).pipe(
            switchMap(x => {
              return this.taskService.editProfile(profile.id, {content_status_id: 1}, this.company_id)
            })
          ).subscribe(resp => {
            this.getTask()
          })
        )
      }
    });
  }

  getTabPosts(copyMeta?) {
    if (this.channelsTab < 0) {
      return
    }
    if (this.taskPostsSub) {
      this.taskPostsSub.unsubscribe();
    }
    this.taskPostsSub = this.taskService.getTaskPostsStream().pipe(
      concatMap(itemsInPage => {
        return concat(...itemsInPage.map(itemInPage => this.neededPostsData(itemInPage))).pipe(last(),map(x => itemsInPage))
      }),
    ).subscribe(resp => console.log("-----taskPostsSub-----", resp));
    
    this.attachSubscriptions(
      this.taskService.getExpandUrls('1', this.company_id, {task_id: this.task.id, platform_id: this.channelsTab === 0 ? '' : this.channelsTab}, '200').pipe(
        map(x => x.body),
        tap(resp => {
          if (resp.length) {
            if (copyMeta) {
              console.log("copyMeta", copyMeta);
              console.log("this.returnObjByVal(copyMeta.task_channels_by_platform_arr)", this.returnObjByVal(copyMeta.task_channels_by_platform_arr));
  
              if (this.returnObjByVal(copyMeta.task_channels_by_platform_arr) && this.returnObjByVal(copyMeta.task_channels_by_platform_arr).arr) {
                resp.forEach(el => {
                  if (this.returnObjByVal(copyMeta.task_channels_by_platform_arr).arr.find(p => p.id == el.id)) {
                    el.summaryAveragePercentageViewed = this.returnObjByVal(copyMeta.task_channels_by_platform_arr).arr.find(p => p.id == el.id).summaryAveragePercentageViewed;
                    el.summaryAverageViewDuration = this.returnObjByVal(copyMeta.task_channels_by_platform_arr).arr.find(p => p.id == el.id).summaryAverageViewDuration;
                    el.timeChartSumm = this.returnObjByVal(copyMeta.task_channels_by_platform_arr).arr.find(p => p.id == el.id).timeChartSumm;
                    el.timeChart = this.returnObjByVal(copyMeta.task_channels_by_platform_arr).arr.find(p => p.id == el.id).timeChart;
                  }
                });
              }
            }
            this.taskService.taskPosts$.next(resp);
            resp.forEach(post => {
              if (post.content_type_id == 106) {
                if (post.publishing_params && post.publishing_params.live_stream_to && post.publishing_params.live_stream_to.indexOf('backup') != -1) {
                  if (!!resp.filter(k => k.publishing_params && k.publishing_params.live_stream_key == post.publishing_params.live_stream_key && k.content_url == post.content_url && k.publishing_params.live_stream_to.indexOf('backup') == -1).length) {
                    if (resp.find(k => k.publishing_params && k.publishing_params.live_stream_key == post.publishing_params.live_stream_key && k.content_url == post.content_url && k.publishing_params.live_stream_to.indexOf('backup') == -1).hasOwnProperty('backups')) {
                      resp.find(k => k.publishing_params && k.publishing_params.live_stream_key == post.publishing_params.live_stream_key && k.content_url == post.content_url && k.publishing_params.live_stream_to.indexOf('backup') == -1).backups.push(post)
                    } else {
                      resp.find(k => k.publishing_params && k.publishing_params.live_stream_key == post.publishing_params.live_stream_key && k.content_url == post.content_url && k.publishing_params.live_stream_to.indexOf('backup') == -1).backups = [post]
                    }
                    post.backup_id = resp.find(k => k.publishing_params && k.publishing_params.live_stream_key == post.publishing_params.live_stream_key && k.content_url == post.content_url && k.publishing_params.live_stream_to.indexOf('backup') == -1).id;
                    let copiedOrig = {...resp.find(k => k.publishing_params && k.publishing_params.live_stream_key == post.publishing_params.live_stream_key && k.content_url == post.content_url && k.publishing_params.live_stream_to.indexOf('backup') == -1)}
                    delete copiedOrig.backups;
                    post.backupOriginal = copiedOrig;
                  } else {
                    post.backup_id = -1;
                  }
                } else {
                  post.backup_id = 0;
                }
              } else {
                post.backup_id = 0;
              }
            });
            // resp.forEach(post => {
            //   if (post.content_type_id == 106) {
            //     if (post.publishing_params && post.publishing_params.live_stream_to && post.publishing_params.live_stream_to.indexOf('backup') != -1) {
            //       if (resp.filter(k => k.publishing_params && k.publishing_params.live_stream_key == post.publishing_params.live_stream_key &&))
            //     } else {
            //       post.backup_id = 0
            //     }
            //   } else {
            //     post.backup_id = 0
            //   }
            // })
          }
          if (this.returnObjByVal(this.task.meta.task_channels_by_platform_arr)) {
            this.returnObjByVal(this.task.meta.task_channels_by_platform_arr).arr = resp;
          }
        }),
      ).subscribe(resp => {
        console.log("getTabPosts", resp)
        // this.task.meta.task_channels_by_platform_arr[this.channelsTab].arr = resp;
      })
    )
  }

  copyData(dataType) {
    this.layoutService.showSnackBar({name: dataType}, marker("Copied"), SnackBarItem)
  }

  getCircularReplacer() {
    const ancestors:any = [];
    return function (key, value) {
      if (typeof value !== "object" || value === null) {
        return value;
      }
      // `this` is the object that value is contained in,
      // i.e., its direct parent.
      while (ancestors.length > 0 && ancestors.at(-1) !== this) {
        ancestors.pop();
      }
      if (ancestors.includes(value)) {
        return "[Circular]";
      }
      ancestors.push(value);
      return value;
    };
  }

  // ifJobClientModerator(job) {
  //   if (!this.clients || this.clients.length == 0 || !this.company) {
  //     return false;
  //   }
  //   // console.log("ifJobClient", this.clients.filter(x => (x.client_employee_id == this.company.employees[0].id || x.client_partner_company_id == this.company_id) && x.is_principal && (!x.task_operation_id || x.task_operation_id == job.id)).length)
  //   if (this.clients.filter(x => (x.client_employee_id == this.company.employees[0].id || x.client_partner_company_id == this.company_id) && !!x.is_principal && (!x.task_operation_id || x.task_operation_id == job.id)).length > 0) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }
  // ifJobClient(job) {
  //   if (!this.clients || this.clients.length == 0 || !this.company) {
  //     return false;
  //   }
  //   // console.log("ifJobClient", this.clients.filter(x => (x.client_employee_id == this.company.employees[0].id || x.client_partner_company_id == this.company_id) && x.is_principal && (!x.task_operation_id || x.task_operation_id == job.id)).length)
  //   if (this.clients.filter(x => (x.client_employee_id == this.company.employees[0].id || x.client_partner_company_id == this.company_id) && (!x.task_operation_id || x.task_operation_id == job.id)).length > 0) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  // multi logic

  setTab(tab?) {
    console.log('setTab', tab)
    if (tab) {
      this.jobsTabForm.patchValue({
        parameter_value_id: tab.id
      })
    } else {
      this.jobsTabForm.patchValue({
        parameter_value_id: ''
      })
    }
  }

  changeView(e) {
    this.viewMode = e.value;
    this.sm.localStorageSetItem('card_jobs', this.viewMode);
  }

  toggleProfilesMenu() {
    this.profilesMenu = !this.profilesMenu;
  }

  hideProfilesMenu() {
    this.profilesMenu = false;
  }
  
  toggleMenu() {
    this.cstMenu = !this.cstMenu;
  }

  editDesctiption() {
    this.isEditedDescription = !this.isEditedDescription;
  }

  hideMenu() {
    this.cstMenu = false;
  }

  toggleJob(job): void {
    this.jobCollection.toggle(job);
    console.log(this.jobCollection);
  }

  checkboxLabel(row?): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.jobCollection.isSelected(row) ? 'deselect' : 'select'} job ${row.name}`;
  }

  multiAddExecutors() {
    const dialogRef = this.dialog.open(SetEmplComponent, {
      data: {
        company: this.company,
        user: this.user,
        task: this.task,
        selectedJobs: this.jobCollection.selected
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result && result.event == "update") {
          // this.getTask(true);
          // this.jobCollection.selected.forEach(x => {
          // })
          this.getTask(true);
          this.jobCollection.clear()
        }
      })
    )
  }
  
  isAllSelected() {
    const numSelected = this.jobCollection.selected.length;
    const numRows = !!this.task && !!this.task.operations ? this.task.operations.length : 0;
    return numSelected === numRows;
  }
  
  masterToggle() {
    this.isAllSelected() ?
        this.jobCollection.clear() :
        this.jobs$.asObservable().pipe(take(1)).subscribe(resp => {
          console.log("resp asObservable", resp)
          resp.forEach(x => {
            this.jobCollection.select(x)
          })

          console.log("this.jobCollection", this.jobCollection)
          // this.jobs$.next(resp.slice())
        })
  }

  dropTabs(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.tabs, event.previousIndex, event.currentIndex);
    this.tabsSaved = false;
  }

  toggleMobSearch() {
    this.searchIsOpen = !this.searchIsOpen
  }

  onFilterByStatusJobs(resp) {
    if (!this.task.operations) {
      return;
    }

    if (!resp) {
      this.jobs$.next(this.task.operations.slice());
      return;
    }

    this.jobs$.next(this.task.operations.filter(z => z.status_id == resp));
  }

  onSearchParams(resp) {
    if (!this.parameters) {
      return;
    }

    console.log("JOBS", this.parameters)
    if (!resp) {
      this.parameters$.next(this.parameters.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }

    this.parameters$.next(this.parameters.filter(z => z.name && z.name.toLowerCase().indexOf(resp) > -1));
  }

  getPrimaryTag(job) {
    if (job.parameterValuesToTask && job.parameterValuesToTask.length) {
      return job.parameterValuesToTask.find(x => !!x.is_primary)
    } else {
      return undefined
    }
  }

  onSearchJobs(resp) {
    if (!this.task.operations) {
      return;
    }

    console.log("JOBS", this.task.operations, this.jobsTabForm.value)
    if (!resp) {
      let result = this.task.operations.slice();

      console.log("RESULT 1", result, this.jobsTabForm.get('parameter_value_id').value, !!this.jobsTabForm.get('parameter_value_id').value)
      if (!!this.jobsTabForm.get('parameter_value_id').value) {
        result = result.filter(x => x.parameterValuesToTask.filter(y => y.parameter_value_id == this.jobsTabForm.get('parameter_value_id').value && y.parameter_id == this.jobsTabForm.get('parameter_id').value).length > 0)
      }
      console.log("RESULT 2", result)

      this.jobs$.next(result);
      return;
    } else {
      resp = resp.toLowerCase();
    }
    let result = this.task.operations.filter(z => z.name && z.name.toLowerCase().indexOf(resp) > -1)

    console.log("RESULT 3", result, this.jobsTabForm.get('parameter_value_id').value, !!this.jobsTabForm.get('parameter_value_id').value)
    if (!!this.jobsTabForm.get('parameter_value_id').value) {
      result = result.filter(x => x.parameterValuesToTask.filter(y => y.parameter_value_id == this.jobsTabForm.get('parameter_value_id').value && y.parameter_id == this.jobsTabForm.get('parameter_id').value).length > 0)
    }

    console.log("RESULT 4", result)

    this.jobs$.next(result);
  }

  scenChangeOpen(e, job) {
    console.log(e, job);
    job.scen_visible = e;
  }

  getAutomationTemplates() {
    this.attachSubscriptions(
      this.scenariosService.getAutomationTemplates('1', {company_id: this.company_id, is_task_operation: 1}, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.scenariosService.getAutomationTemplates(x, {company_id: this.company_id, is_task_operation: 1}, '200').pipe(map(u => u.body)))).pipe(
            last(),
            tap(values => {
              this.templates = [].concat(...values)
              console.log("getAutomationTemplates", this.templates)
              this.templates$.next(this.templates.slice());
            }),
          )
        }),
      ).subscribe(resp => {
        console.log("getAutomationTemplates sub", resp);
      })
    )
  }

  openAccessSettings(task) {
    const dialogRef = this.dialog.open(SharedOptionsComponent, {
      data: {
        company_id: this.company_id,
        user: this.user,
        task: task,
        is_mobile: this.is_mobile
      }
    });
    
    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log(result)
      })
    )
  }

  openNotes(item, type?) {
    const dialogRef = this.dialog.open(NotesComponent, {
      data: {
        company: this.company,
        company_id: this.company_id,
        user: this.user,
        type: type || 'task',
        item: item,
        is_mobile: this.is_mobile,
        documents: this.task.documents,
        is_shared: this.data.is_shared
      }
    });
    
    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log(result)
      })
    )
  }
  
  openTagConnection(task) {
    const dialogRef = this.dialog.open(TagTaskComponent, {
      backdropClass: 'backdrop_under_header',
      panelClass: !this.is_mobile ? ['open_task_dialog', 'show_header'] : 'open_task_dialog',
      data: {
        company: this.company,
        user: this.user,
        task: task,
        is_mobile: this.is_mobile,
        activeLang: this.activeLang
      }
    });
    
    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log(result)
      })
    )
  }

  switchData() {
    if (this.tab != 5) {
      if (this.taskPostsSub) {
        this.taskPostsSub.unsubscribe();
      }
    }
    switch (this.tab) {
      case 0:
        // About
        break;
      case 1:
        // Jobs
        break;
      case 2:
        // Files
        break;
      case 3:
        // Posts
        this.activeTab(null, {platform_id: 0})
        break;
      case 4:
        // Roles
        break;
      case 5:
        this.getChatsForTask(this.task);
        // Chats
        break;
      case 6:
        // Tags
        break;
      case 7:
        // Connections
        this.refreshService.isTaskGetting$.next({company_id: this.company_id, task_id: this.task.id})
        break;
    
      default:
        break;
    }
  }
  
  changeTab(num) {
    if (num == 5) {
      let x = '';
      if (!!this.task.group_id) {
        x = `&group_id=${this.task.group_id}`
      }
      x += `&task_custom_id=${this.task.custom_id}`
      window.open(`${this.origin}/chats?company_id=${this.task.company_id}${x}`, '_blank')
      return
    }
    this.tab = num;
    console.log('this.tab', this.tab)
    if (this.is_mobile) {
      this.switchData()
    }
  }

  scenIsSelected(job, template) {
    return !!job.automationScenarios && !!job.automationScenarios.length && job.automationScenarios.filter(x => x.template_id == template.id).length > 0
  }

  activeScan(job, template) {
    return !!job.automationScenarios && !!job.automationScenarios.length && job.automationScenarios.find(x => x.template_id == template.id) && job.automationScenarios.find(x => x.template_id == template.id).is_active == 0
  }

  allDone(job) {
    return !!job.automationScenarios && job.automationScenarios.length == job.automationScenarios.filter(x => x.is_active == 0).length
  }

  someDone(job) {
    return !!job.automationScenarios && job.automationScenarios.filter(x => x.is_active == 0).length != 0
  }

  findScen(job, template) {
    if (!job.automationScenarios) {
      return undefined
    } else {
      return job.automationScenarios.find(x => x.template_id == template.id)
    }
  }

  tryScen(e, job, template) {
    e.preventDefault();
    e.stopPropagation();
    this.disableTemplate = true;
    this.attachSubscriptions(
      this.scenariosService.tryScenario(this.findScen(job,template).id).subscribe(result => {
        console.log('tryScenario', result);
        this.disableTemplate = false;
      }, error => {
        this.disableTemplate = false;
        this.layoutService.showSnackBar({name: ''}, marker(error), SnackBarItem)
      })
    )
  }

  openComments(profile) {
    const dialogRef = this.dialog.open(CommentsComponent, {
      panelClass: ['without_paddings_modal', 'comments_modal'],
      data: {
        profile: profile,
        company: this.company,
        task: this.task,
        host: this.host,
        imgRoute: this.imgRoute,
        origin: this.origin,
        user: this.user,
        activeLang: this.activeLang,
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log("SortToApproveFiles res", result)
      })
    )
  }
  
  tryDuplicateScen(e, job, template, q) {
    e.preventDefault();
    e.stopPropagation();
    this.disableTemplate = true;
    this.attachSubscriptions(
      this.scenariosService.tryScenario(job.automationScenarios.filter(x => x.template_id == template.id)[q].id).subscribe(result => {
        console.log('tryScenario', result);
        this.disableTemplate = false;
      }, error => {
        this.disableTemplate = false;
        this.layoutService.showSnackBar({name: ''}, marker(error), SnackBarItem)
      })
    )
  }

  activeDuplicateScan(job, template, index) {
    if (!!job.automationScenarios.length && job.automationScenarios.filter(x => x.template_id == template.id).length) {
      return job.automationScenarios.filter(x => x.template_id == template.id)[index].is_active == 0
    } else {
      return false
    }
    // return false;
    // return !!job.automationScenarios.length && job.automationScenarios.find(x => x.template_id == template.id) && job.automationScenarios.find(x => x.template_id == template.id).is_active == 0
  }

  duplicatesScenCount(job, template) {
    return job.automationScenarios.filter(x => x.template_id == template.id)
  }

  toggleDuplicateScen = (chip: any, job, q) => {
    let id = job.automationScenarios.filter(x => x.template_id == (chip.id))[q].id
    this.attachSubscriptions(
      this.scenariosService.deleteAutomationScenario(id).subscribe(resp => {
        job.automationScenarios.splice(job.automationScenarios.findIndex(t => t.id == id), 1)
        this.disableTemplate = false;

        if (job.automationScenarios && job.automationScenarios.length) {
          job.selectedScenIds = job.automationScenarios.map(x => x.template_id);
        } else {
          job.selectedScenIds = [];
        }
      }, error => {
        this.disableTemplate = false;
        this.layoutService.showSnackBar({name: 'You do not have permission to do this.'}, `${error}`, SnackBarItem)
      })
    )
  }

  toggleChipTemplate = (chip: any, job) => {
    this.disableTemplate = true;
    if (this.scenIsSelected(job, chip)) {
      console.log("Selected");
      let id = job.automationScenarios.find(x => x.template_id == (chip.id)).id
      this.attachSubscriptions(
        this.scenariosService.deleteAutomationScenario(id).subscribe(resp => {
          job.automationScenarios.splice(job.automationScenarios.indexOf(job.automationScenarios.find(x => x.template_id == (chip.id))), 1)
          this.disableTemplate = false;

          if (job.automationScenarios && job.automationScenarios.length) {
            job.selectedScenIds = job.automationScenarios.map(x => x.template_id);
          } else {
            job.selectedScenIds = [];
          }
        }, error => {
          this.disableTemplate = false;
          this.layoutService.showSnackBar({name: 'You do not have permission to do this.'}, `${error}`, SnackBarItem)
        })
      )
    } else {
      console.log("NO Selected")
      this.attachSubscriptions(
        this.scenariosService.addAutomationScenario({
          company_id: this.company_id,
          task_operation_id: job.id,
          template_id: chip.id
        }, this.company_id).pipe(
          switchMap(res => {
            return this.scenariosService.getAutomationTemplate(res.template_id).pipe(tap(result => res.template = result), map(() => res))
          })
        ).subscribe(resp => {
          console.log("addAutomationScenario", resp);
          job.automationScenarios.push(resp);
          this.disableTemplate = false;
          if (job.automationScenarios && job.automationScenarios.length) {
            job.selectedScenIds = job.automationScenarios.map(x => x.template_id);
          } else {
            job.selectedScenIds = [];
          }
          console.log("JOB", job)
        })
      )
    }
  }

  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData().pipe(
        tap(resp => {
          this.activeLang = resp.active;
        }),
        switchMap(() => this.taskService.getOperations(this.company_id, this.activeLang).pipe(
          tap(x => {
            this.operationsValues = x;
          })
        ))
      ).subscribe(resp => {
        console.log("getOperations", resp)
      })
    )
  }

  toShortCut(e, item, type) {
    if (type == 'job') {
      this.attachSubscriptions(
        this.taskBarService.addBarItem({company_id: this.company.id, task_id: item.task_id, task_operation_id: item.id, discussion_id: 0}).subscribe(res => {
          this.minimizeService.minimizes$.next(res)
        }, error => {
          console.log("error toShortCut")
        })
      )
    } else {
      this.attachSubscriptions(
        this.taskBarService.addBarItem({company_id: this.company.id, task_id: item.id, task_operation_id: 0, discussion_id: 0}).subscribe(res => {
          this.minimizeService.minimizes$.next(res)
        }, error => {
          console.log("error toShortCut")
        })
      )
    }
  }

  neededPostsData(channel) {
    let arr = [];

    if (channel.taskChannelFiles && channel.taskChannelFiles.length > 0 && !channel.file) {
      if (channel.taskChannelFiles.filter(x => this.understandFileType(x.originalFile.content_type) == 'video').length > 0) {
        channel.file = channel.taskChannelFiles.find(x => this.understandFileType(x.originalFile.content_type) == 'video').originalFile;
      } else {
        channel.file = channel.taskChannelFiles.filter(x => this.understandFileType(x.originalFile.content_type) != 'audio')[0].originalFile;
      }
    }

    // if (!!this.showAddOptions.value) {
    if (this.user && this.user.is_root) {
      arr.push(this.taskService.getPostExtra(1, this.company_id, {task_channel_id: channel.id}).pipe(
        map(x => x.body),
        tap(extras => {
          channel.extras = extras
        })
      ))
    }
  
    if (channel.external_content_id && !!channel.content_url) {
      let filterData:any = {
        start_time_from: channel.content_published_at,
        totals: '1',
        order_by: 'start_time',
        'per-page': 1,
        content_id: channel.external_content_id,
        period: 'day'
      }

      arr.push(this.taskService.getContentAnlytics(this.company_id, filterData, "1").pipe(
        tap(el => {
          channel.summaryAveragePercentageViewed = Math.round(+el.headers.get('x-summary-average-view-percentage'))
          channel.summaryAverageViewDuration = +el.headers.get('x-summary-average-view-duration')
        })
      ))
    }

    if (!!channel.content_url) {
      arr.push(
        this.taskService.getHoursChart(this.company_id, channel.id, {
          start_time_from: moment().subtract(48, 'h').format("X"),
          period: 'hour',
          totals: '1',
          order_by: 'start_time'
        }).pipe(
          tap(x => {
            channel.timeChartSumm = x.headers.get('x-summary-views')
            for (let index = 0; index < x.body.length; index++) {
              let element = x.body[index];
              element.value = element.views_count
              element.date = moment(element.start_time * 1000).clone()
              if (moment(element.start_time * 1000).clone().isAfter(moment().hours(0).minutes(0).seconds(0)) || moment(element.start_time * 1000).clone().isSame(moment().hours(0).minutes(0).seconds(0))) {
                element.name = moment(element.start_time * 1000).clone().format("hh:mm a") + " \u2013 " + moment(element.end_time * 1000).clone().format("hh:mm a");
              } else if (moment(element.start_time * 1000).clone().year() == moment().year()) {
                element.name = moment(element.start_time * 1000).clone().format("MM/DD, hh:mm a") + " \u2013 " + moment(element.end_time * 1000).clone().format("hh:mm a");
              } else {
                element.name = moment(element.start_time * 1000).clone().format("MM/DD/YYYY, hh:mm a") + " \u2013 " + moment(element.end_time * 1000).clone().format("hh:mm a");
              }
            }
              let model = [];
    
            for (let i:number = 1; i <= 48; i++) {
              let date = moment().minutes(0).seconds(0).clone().subtract(48-i, 'h')
              if (x.body.filter(u => date.isSameOrAfter(moment(u.start_time * 1000)) && date.clone().subtract(1, 's').isSameOrBefore(moment(u.end_time * 1000))).length == 0) {
                let name = '';
                if (date.clone().isSameOrAfter(moment().hours(0).minutes(0).seconds(0))) {
                  name = date.clone().format("hh:mm a") + " \u2013 " + date.clone().add(1,'h').subtract(1, 's').format("hh:mm a");
                } else if (date.clone().year() == moment().year()) {
                  name = date.clone().format("MM/DD, hh:mm a") + " \u2013 " + date.clone().add(1,'h').subtract(1, 's').format("hh:mm a");
                } else {
                  name = date.clone().format("MM/DD/YYYY, hh:mm a") + " \u2013 " + date.clone().add(1,'h').subtract(1, 's').format("hh:mm a");
                }
                model.push({
                  index: i,
                  hour: i - 48,
                  date: date,
                  name: name,
                  value: 0
                })
              } else {
                model.push(x.body.find(u => date.isSameOrAfter(moment(u.start_time * 1000)) && date.clone().subtract(1, 's').isSameOrBefore(moment(u.end_time * 1000))))
              }
            }
            channel.timeChart = [...model]
          }),
        )
      )
    }

    if (arr && arr.length) {
      return forkJoin(arr).pipe(tap(x => console.log("this.uniqueNoPablChan", this.uniqueNoPablChan)))
    } else {
      return of(arr)
    }

  }

  neededData(task) {
    this.uniqueNoPablChan = {
      related: {
        arr: [],
        ids: []
      },
      consist: {
        arr: [],
        ids: []
      },
      part: {
        arr: [],
        ids: []
      }
    }
    if (!task.hasOwnProperty('operations')) {
      task.operations = [];
    }
    let arr = [];

    arr.push(
      this.chatService.getTasksChats(this.company_id, [task.id]).pipe(
        tap(res => {
          task['operations'].map(o_el => {
            o_el['chatsInfo'] = [];
            o_el['chatsInfo'].push(...res.filter(z => z.task_operation_id == o_el.id));
            o_el['unReadChats'] = res.filter(z => z.task_operation_id == o_el.id && z.is_read != '1').length;
            o_el['unClosedChats'] = res.filter(z => z.task_operation_id == o_el.id && z.status_id != '4').length;
          });
          task['chatsInfo'] = [];
          task['chatsInfo'].push(...res.filter(z => z.task_id == task.id));
          task['unReadChats'] = res.filter(z => z.is_read != '1').length;
          task['unClosedChats'] = res.filter(z => z.status_id != '4').length;
        })
      )
    )
    // if (task && task.group_id && this.company && (this.company.permissions.includes('owner') || this.checkIsManager(task, this.company, this.user))) {
    //   arr.push(
    //     this.scenariosService.getAutomationTemplates('1', {company_id: this.company_id, group_id: task.group_id}, '200').pipe(
    //       catchError(error => {
    //         return of(error)
    //       }),
    //       map(el => el.body),
    //       tap(scenarios => {
    //         task.operations.forEach(job => {
    //           if (scenarios && scenarios.length) {
    //             job.scenarioTab = true;
    //             job.groupScenarios = scenarios;
    //           } else {
    //             job.groupScenarios = [];
    //           }
    //         })
    //       })
    //     )
    //   )
    // }

    if (task && task.group_id && this.company) {
      arr.push(
        this.scenariosService.getAutomationTemplates('1', {company_id: this.company_id, group_id: task.group_id, is_task_operation: 1}, '1').pipe(
          catchError(error => {
            return of(error);
          }),
          switchMap(el => {
            if ((typeof el == 'string' && el.indexOf('Not accessible') != -1) || +el.headers.get('x-pagination-total-count') == 0) {
              return of(null);
            } else {          
              let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
              let arr = []
              for (let index = 1; index <= pages; index++) {
                arr.push(index)
              }
    
              return forkJoin(arr.map(x => this.scenariosService.getAutomationTemplates(x, {company_id: this.company_id, group_id: task.group_id, is_task_operation: 1}, '200').pipe(map(u => u.body)))).pipe(
                last(),
                tap(values => {
                  let allVals = [].concat(...values)
                  task.operations.forEach(job => {
                    if (allVals && allVals.length) {
                      job.scenarioTab = true;
                      job.groupScenarios = allVals;
                    } else {
                      job.groupScenarios = [];
                    }
                  })
                })
              )
            }
          })
        )
      )
    }
    

    // if (task.channels && task.channels.length > 0) {
    //   task.channels.forEach(channel => {
    //     if (channel.taskChannelFiles && channel.taskChannelFiles.length > 0 && !channel.file) {
    //       if (channel.taskChannelFiles.filter(x => this.understandFileType(x.originalFile.content_type) == 'video').length > 0) {
    //         channel.file = channel.taskChannelFiles.find(x => this.understandFileType(x.originalFile.content_type) == 'video').originalFile;
    //       } else {
    //         channel.file = channel.taskChannelFiles.filter(x => this.understandFileType(x.originalFile.content_type) != 'audio')[0].originalFile;
    //       }
    //     }
    //   })
    // }

    // if (task.channels && task.channels.length > 0 && this.company && (this.company.permissions.includes('owner') || this.checkIsManager(task, this.company, this.user))) {
    //   task.channels.forEach(channel => {        
    //     if (channel.external_content_id) {
    //       let filterData:any = {
    //         start_time_from: channel.content_published_at,
    //         totals: '1',
    //         order_by: 'start_time',
    //         'per-page': 1,
    //         content_id: channel.external_content_id,
    //         period: 'day'
    //       }
    
    //       arr.push(this.taskService.getContentAnlytics(this.company_id, filterData, "1").pipe(
    //         tap(el => {
    //           channel.summaryAveragePercentageViewed = Math.round(+el.headers.get('x-summary-average-view-percentage'))
    //           channel.summaryAverageViewDuration = +el.headers.get('x-summary-average-view-duration')
    //         })
    //       ))
    
    //     }
    //     arr.push(
    //       this.taskService.getHoursChart(this.company_id, channel.id, {
    //         start_time_from: moment().subtract(48, 'h').format("X"),
    //         period: 'hour',
    //         totals: '1',
    //         order_by: 'start_time'
    //       }).pipe(
    //         tap(x => {
    //           channel.timeChartSumm = x.headers.get('x-summary-views')
    //           for (let index = 0; index < x.body.length; index++) {
    //             let element = x.body[index];
    //             element.value = element.views_count
    //             element.date = moment(element.start_time * 1000).clone()
    //             if (moment(element.start_time * 1000).clone().isAfter(moment().hours(0).minutes(0).seconds(0)) || moment(element.start_time * 1000).clone().isSame(moment().hours(0).minutes(0).seconds(0))) {
    //               element.name = moment(element.start_time * 1000).clone().format("hh:mm a") + " \u2013 " + moment(element.end_time * 1000).clone().format("hh:mm a");
    //             } else if (moment(element.start_time * 1000).clone().year() == moment().year()) {
    //               element.name = moment(element.start_time * 1000).clone().format("MM/DD, hh:mm a") + " \u2013 " + moment(element.end_time * 1000).clone().format("hh:mm a");
    //             } else {
    //               element.name = moment(element.start_time * 1000).clone().format("MM/DD/YYYY, hh:mm a") + " \u2013 " + moment(element.end_time * 1000).clone().format("hh:mm a");
    //             }
    //           }
    //             let model = [];
      
    //           for (let i:number = 1; i <= 48; i++) {
    //             let date = moment().minutes(0).seconds(0).clone().subtract(48-i, 'h')
    //             if (x.body.filter(u => date.isSameOrAfter(moment(u.start_time * 1000)) && date.clone().subtract(1, 's').isSameOrBefore(moment(u.end_time * 1000))).length == 0) {
    //               let name = '';
    //               if (date.clone().isSameOrAfter(moment().hours(0).minutes(0).seconds(0))) {
    //                 name = date.clone().format("hh:mm a") + " \u2013 " + date.clone().add(1,'h').subtract(1, 's').format("hh:mm a");
    //               } else if (date.clone().year() == moment().year()) {
    //                 name = date.clone().format("MM/DD, hh:mm a") + " \u2013 " + date.clone().add(1,'h').subtract(1, 's').format("hh:mm a");
    //               } else {
    //                 name = date.clone().format("MM/DD/YYYY, hh:mm a") + " \u2013 " + date.clone().add(1,'h').subtract(1, 's').format("hh:mm a");
    //               }
    //               model.push({
    //                 index: i,
    //                 hour: i - 48,
    //                 date: date,
    //                 name: name,
    //                 value: 0
    //               })
    //             } else {
    //               model.push(x.body.find(u => date.isSameOrAfter(moment(u.start_time * 1000)) && date.clone().subtract(1, 's').isSameOrBefore(moment(u.end_time * 1000))))
    //             }
    //           }
    //           channel.timeChart = [...model]
    //         }),
    //       )
    //     )
    //   })
    // }


    // if (!this.is_mobile && (task.company_id == this.company_id)) {
    //   arr.push(
    //     this.taskService.getTaskClients(task.id, this.company_id).pipe(
    //       tap(res => {
    //         task.clients = res
    //       }),
    //       catchError(err => {
    //         return err
    //       })
    //     )
    //   )
    // }
    
    // if (this.company | PermissionsPipe:'see_files')
    if (new PermissionsPipe().transform(this.company, 'see_files')) {
      arr.push(...task.operations.filter(z => (z.status_id == 3 && z.is_to_approve_files) || (z.status_id == 98 && z.is_approved_files)).map(x => this.fileService.getFilesForOpenTask(this.company_id, x.id, x.status_id == 3 ? '/to_approve' : '/ready_files', this.is_mobile ? '20' : '3').pipe(
        tap(val => {
          x.filesCount = +val.headers.get('x-pagination-total-count');
          x.files = val.body.filter(x => !!x.thumbnail).slice(0,3);
          console.log("JOB FILES", x.files, val)
        })
      )))
    }

    if (arr && arr.length) {
      return forkJoin(arr).pipe(tap(x => console.log("this.uniqueNoPablChan", this.uniqueNoPablChan)))
    } else {
      return of(arr)
    }
  }

  moreConnections(arr, pageData, key) {
    console.log("this.task[pageData]", arr, pageData, key, this.task[pageData])
    if (this.task[pageData].is_load) {
      return
    }
    this.task[pageData].is_load = true;
    this.task[pageData].currentPage++;

    this.attachSubscriptions(
      this.taskService.getCardsExpandForCard(this.task[pageData].currentPage, this.company_id, {[key]: this.task.id}, '30').pipe(
        map(p => p.body),
        tap(res => {
          if (res.length == 0) {
            this.task[pageData].getAll = true;
          }
          res.forEach(el => {
            if (el.meta && el.meta.task_channels_by_platform) {
              el.meta.task_channels_by_platform_arr = Object.values(el.meta.task_channels_by_platform).filter((x:any) => !!x.platform_id);
            }
            el.previewImgs = [];
            if (el.channels) {
              el.channels.forEach(element => {
                if (!element.content_url) {
                  if (this.uniqueNoPablChan.related.ids.filter(id => id == element.channel_id).length == 0) {
                    this.uniqueNoPablChan.related.arr.push(element)
                    this.uniqueNoPablChan.related.ids.push(element.channel_id)
                  }
                }
                if (!!element.file && !!element.file.original) {
                  el.previewImgs.push({
                      is_parser_url: false,
                      src: element.file.original
                    })
                } else if (!!element.content_image) {
                  el.previewImgs.push({
                    is_parser_url: true,
                    src: element.content_image
                  })
                }
              })
              el.channels.sort((a,b) => {
                return b.content_views_count - a.content_views_count;
              })
            }
          });
          this.task[arr].push(...res);

        })
      ).subscribe(resp => {
        this.task[pageData].is_load = false;
      })
    )
  }

  goToFilteredOutlets(e, key) {
    
    let url = `${this.origin}/tasks/?company_id=${this.company_id}&content_status_id=4`

    if (this.uniqueNoPablChan[key].ids.length) {
      let x = `&channel_id=${this.uniqueNoPablChan[key].ids.filter(k => !!k).join(',')}`
      url = url + x
    }

    window.open(url, '_blank')

  }

  
  mobEditPriority(chat) {
    const dialogRef = this.dialog.open(MobPriorityComponent, {
      backdropClass: ['mob_interface_backdrop'],
      panelClass: ['mob_interface_panel', 'animate__animated', 'animate__slideInUp'],
      data: {
        company: this.company,
        task: this.task,
        user: this.user,
        chat: chat,
        target: 'chat'
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          if (result.event == "back") {
            this.mobOptions(chat);
          } else {
            this.getTask(true);
          }
        } else {
          this.getTask(true);
        }
      })
    )
  }

  getProfileStatus(id) {
    if (!this.profileStatuses) {
      return false;
    }
    return this.profileStatuses.find(el => el.id == id)
  } 

  mobEditTitle(chat) {
    const dialogRef = this.dialog.open(MobTitleComponent, {
      backdropClass: ['mob_interface_backdrop'],
      panelClass: ['mob_interface_panel', 'animate__animated', 'animate__slideInUp'],
      data: {
        company: this.company,
        task: this.task,
        user: this.user,
        chat: chat,
        type: 'Title',
        type_prop: 'name',
        title: 'Edit chat',
        target: 'chat'
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          if (result.event == "back") {
            this.mobOptions(chat);
          } else {
            this.getTask(true);
          }
        } else {
          this.getTask(true);
        }
      })
    )
  }

  mobJobEditTitle(work, type_prop, type) {
    const dialogRef = this.dialog.open(MobTitleComponent, {
      backdropClass: ['mob_interface_backdrop'],
      panelClass: ['mob_interface_panel', 'animate__animated', 'animate__slideInUp'],
      data: {
        company: this.company,
        task: this.task,
        user: this.user,
        work: work,
        type: type,
        type_prop: type_prop,
        title: 'Edit job',
        target: 'work'
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          if (result.event == 'back') {
            this.editWork(this.task, work)
          } else if (result.event == 'update') {
            this.getTask(true);
          }
        } else {
          this.getTask(true);
        }
      })
    )
  }

  openConnectionChats(chat) {
    const goToChatCon = this.dialog.open(ChatConnectionsComponent, {
      backdropClass: 'backdrop_under_header',
      panelClass: !this.is_mobile ? ['open_task_dialog', 'show_header'] : 'open_task_dialog',
      data: {
        company: this.company,
        user: this.user,
        empl_status: this.company.permissions.includes('owner') || this.company.permissions.includes('admin') || this.checkIsManager(this.task, this.company, this.user),
        chat: chat,
        chats: this.task.chatsInfo,
        is_mobile: this.is_mobile,
        imgRoute: this.imgRoute,
        operationsValues: this.operationsValues,
        host: this.host,
        activeLang: this.activeLang,
      }
    });

    this.attachSubscriptions(
      goToChatCon.afterClosed().subscribe(result => {
        this.getTask(true);
      })
    )
  }

  chatAdditionalEmployees(chat) {
    const dialogRef = this.dialog.open(AdditionalEmployeesComponent, {
      disableClose: true,
      data: {
        user: this.user,
        company: this.company,
        task: this.task,
        work: chat.taskOperation,
        discussion: chat,
        initialAdd: true,
        empl_status: this.company?.permissions.includes('owner') || this.checkIsManager(this.task, this.company, this.user)
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        this.getTask(true)
      })
    )
  }

  mobOptions(chat) {
    const dialogRef = this.dialog.open(MobChatOptionsComponent, {
      backdropClass: ['mob_interface_backdrop'],
      panelClass: ['mob_interface_panel', 'animate__animated', 'animate__slideInUp'],
      data: {
        company: this.company,
        chat: chat,
        task: this.task,
        user: this.user,
        host: this.host
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log("afterClosed", result)
        if (!!result && !!result.data) {
          switch (result.data) {
            case 'copy link':
              console.log("copy link");
              // this._clipboardService.copy(this.host + '/chat/' + chat.acm)
              // this.copyLink("Chat")
              break;
            case 'delete':
              this.deleteChat(chat)
              break;
            case 'notes':
              this.openNotes(chat, 'chat')
              break;
            case 'move':
              this.moveChat(chat)
              break;
            case 'priority':
              this.mobEditPriority(chat)
              break;
            case 'search':
              console.log("search");
              break;
            case 'search':
              console.log("info");
              break;
            case 'edit title':
              this.mobEditTitle(chat)
              break;
            case 'assignments':
              this.chatAdditionalEmployees(chat)
              break;
            case 'conns':
              this.openConnectionChats(chat)
              break;
            case 'board':
              this.addToBoard(chat)
              break;
            case 'parameters':
              this.openTargetValues([chat.task_id, chat.task_operation_id, chat.id], chat)
              break;
            case 'push':
              this.mobPushSettigns(chat)
              break;
            default:
              console.log("Default")
              break;
          }
        }
      })
    )
  }

  mobPushSettigns(chat) {
    const pushSett = this.dialog.open(MobPushSettingsComponent, {
      backdropClass: ['mob_interface_backdrop'],
      panelClass: ['mob_interface_panel', 'animate__animated', 'animate__slideInUp'],
      data: {
        company: this.company,
        user: this.user,
        imgRoute: this.imgRoute,
        host: this.host,
        activeLang: this.activeLang, 
        chat: chat,
        task: this.task,
        job: chat.taskOperation,
        statuses: this.statuses,
        empl_status: this.company.permissions.includes('owner') || this.checkIsManager(this.task, this.company, this.user),
        operationsValues: this.operationsValues,
        type: 'notifications',
        type_prop: 'notifications',
        title: 'Notifications settings',
        target: 'Subscribe for notifications for new messages in this chat'
      }
    });

    this.attachSubscriptions(
      pushSett.afterClosed().subscribe(result => {
      })
    )
  }

  showStats(profile) {
    if (!this.is_mobile) {
      const dialogRef = this.dialog.open(UrlAnalyticsComponent, {
        disableClose: true,
        autoFocus: false,
        data: {
          company: this.company,
          company_id: this.company_id,
          task: this.task,
          profile: profile,
          is_edit: true,
        }
      });
  
      this.attachSubscriptions(
        dialogRef.afterClosed().subscribe(result => {
          
          if (!!result && result.event == 'edit') {
            this.getTask(true);
          }
        })
      )
    } else {
      const dialogRef = this.dialog.open(MobUrlAnalyticsComponent, {
        backdropClass: ['mob_interface_backdrop'],
        panelClass: ['mob_interface_panel', 'animate__animated', 'animate__slideInUp'],
        disableClose: true,
        data: {
          company: this.company,
          company_id: this.company_id,
          task: this.task,
          profile: profile,
          is_edit: true,
        }
      });
  
      this.attachSubscriptions(
        dialogRef.afterClosed().subscribe(result => {
          
          if (!!result && result.event == 'edit') {
            this.getTask(true);
          }
        })
      )
    }
  }

  openJob(task_id, job, initData?) {
    console.log("openJob");
    console.log(this.jobs$);
    this.jobs$.asObservable().pipe(take(1)).subscribe(resp => {
      console.log("resp asObservable", resp)
      resp.forEach(x => {
        x.isLoad = false
      })
      this.jobs$.next(resp.slice())
    })

    job.isLoad = true;
    if (this.jobLoadSub) {
      this.jobLoadSub.unsubscribe();
    }
    this.jobLoadSub = this.taskService.getSmartOneTaskExpand(this.company_id, task_id).pipe(
      map(x => x.body[0]),
      map((x) => casesModel([x], [], 'update')),
      map(x => x.arr[0])
    ).subscribe(resp => {
      job.isLoad = false;
      const dialogRef = this.dialog.open(OpenJobComponent, {
        backdropClass: !this.is_mobile ? 'backdrop_under_header': ['backdrop_under_header', 'mob_card_backdrop'],
        panelClass: !this.is_mobile ? ['open_task_dialog', 'show_header'] : ['open_task_dialog', 'animate__animated', 'animate__slideInUp'],
        autoFocus: false,
        data: {
          initCompanyId: this.company_id,
          task_id: task_id,
          task_operation_id: job.id,
          task: resp,
          job: job,
          fast_mode: true,
          selectedIndex: !this.is_mobile && !initData ? 4 : initData?.selectedIndex,
          loc: initData?.loc
        }
      });
      this.closeContext();

      this.attachSubscriptions(
        dialogRef.afterClosed().subscribe(result => {
          this.getTask(true);
        })
      )
    })
  }
  
  openTask(task) {
    const dialogRef = this.dialog.open(OpenTaskComponent, {
      backdropClass: 'backdrop_under_header',
      panelClass: !this.is_mobile ? ['open_task_dialog', 'show_header'] : 'open_task_dialog',
      autoFocus: false,
      data: {
        task_id: task.id,
        company: this.company,
        initCompanyId: this.company_id,
      }
    });
  }

  saveGroupBy() {
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        msg: 'Do you really want to save this setting for all users?',
        btn_no: 'No',
        btn_yes: 'Yes',
        targetVal: {},
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        this.attachSubscriptions(
          this.taskService.editTask(this.task.id, {data: [{
            type: "GroupBy",
            parameter_id: this.jobsTabForm.value.parameter_id,
            parameter_value_id: this.jobsTabForm.value.parameter_value_id,
            order: this.tabs.map(x => x.id)
          }]}, this.company.id).subscribe(resp => {
            console.log("saveGroupBy", resp)
            this.tabsSaved = true;
          }, error => {
            this.layoutService.showSnackBar({name: ''}, marker(error), SnackBarItem)
          })
        )
      }
    });
  }

  respawnTabs(task) {
    let groupByInfo = task.data[0];
    this.jobsTabForm.patchValue({
      parameter_id: groupByInfo.parameter_id,
      parameter_value_id: ''
    }, {emitEvent: false})

    let paramValues = this.allValues.filter(x => x.parameter_id == groupByInfo.parameter_id);
    let res = [];
    groupByInfo.order.forEach(id => {
      if (paramValues.find(x => x.id == id)) {
        res.push(paramValues.find(x => x.id == id))
      }
    })

    paramValues.forEach(val => {
      if (res.filter(x => x.id == val.id).length == 0) {
        res.push(val)
      }
    })
    this.tabs = res
  }

  postDeleteAfterToggle() {
    if (!!this.post_delete_after) {
      localStorage.removeItem('post_delete_after');
    } else {
      this.sm.localStorageSetItem('post_delete_after', 1)
    }
    this.post_delete_after = this.sm.localStorageGetItem('post_delete_after') ? true : false
  }
  
  postBckpAfterToggle() {
    if (!!this.post_backup_after) {
      localStorage.removeItem('post_backup_after');
    } else {
      this.sm.localStorageSetItem('post_backup_after', 1)
    }
    this.post_backup_after = this.sm.localStorageGetItem('post_backup_after') ? true : false
  }
  
  postLinkAfterToggle() {
    if (!!this.post_link_after) {
      localStorage.removeItem('post_link_after');
    } else {
      this.sm.localStorageSetItem('post_link_after', 1)
    }
    this.post_link_after = this.sm.localStorageGetItem('post_link_after') ? true : false
  }
  
  editStatusAfter() {
    this.sm.localStorageSetItem('post_status_after', this.post_status_after);
    this.post_status_after = this.sm.localStorageGetItem('post_status_after') ? +this.sm.localStorageGetItem('post_status_after') : '';
  }

  duplicatePost(card, post, not_new:boolean = false) {
    console.log("card", card);
    console.log("post", post);
    if (!post.external_content_id) {
      this.layoutService.showSnackBar({name: 'This post has no external content id, you cannot duplicate it.'}, '', SnackBarItem)
      return
    }

    let with_new_link = this.post_link_after;

    if (not_new) {
      with_new_link = false;
    }

    let sbmtData = {
      task_id: card.id,
      channel_id: post.channel_id,
      content_url: post.content_url,
      content_name: post.content_name,
      is_content_views_count_update: post.is_content_views_count_update,
      is_bravourl_content_views_count_update: post.is_bravourl_content_views_count_update,
      content_image: post.content_image,
      content_filename: post.content_filename,
      external_content_id: post.external_content_id,
      content_type_id: post.content_type_id,
      content_published_at: post.content_published_at,
      content_status_id: this.post_status_after ? +this.post_status_after : post.content_status_id,
      content_views_count: post.content_views_count,
      publishing_params: post.publishing_params,
    }

    if (with_new_link) {
      const dialogRef = this.dialog.open(AskTextComponent, {
        disableClose: true,
        panelClass: 'push_settigns_modal',
        data: {
          title: "Fill new url",
          field: 'Url',
          company_id: this.company_id
        }
      });
      // https://www.youtube.com/watch?v=36YnV9STBqc
      this.attachSubscriptions(
        dialogRef.afterClosed().pipe(
          switchMap(result => {
            if (!!result) {
              if (result.event == 'old link') {
                return of('old link')
              } else if (result.event == 'new link') {
                this.ls.requests$.next({
                  value: 0,
                  target: "Duplicate Post"
                })
                return this.taskService.getSmartLinkData(result.data, post.channel_id, this.company_id, (post.company_id != this.company_id ? post.company_id : null)).pipe(
                  switchMap(newLinkData => {
                    sbmtData.content_name = newLinkData.name ? newLinkData.name : 'Post has no text';
                    sbmtData.content_url = result.data;
                    sbmtData.external_content_id = newLinkData.external_content_id;
                    sbmtData.content_filename = !!newLinkData.filename ? newLinkData.filename : '';
                    sbmtData.content_image = newLinkData.image;
                    if (newLinkData.published_at) {
                      sbmtData.content_published_at = newLinkData.published_at;
                    }
                    sbmtData.content_views_count = !!newLinkData.views_count ? newLinkData.views_count : '0';

                    this.ls.requests$.next({
                      value: 25,
                      target: "Duplicate Post"
                    })

                    return this.taskService.getTaskChannelFile(this.company_id, {task_channel_id: post.id}).pipe(
                      switchMap(files => {
                        if (this.post_backup_after) {
                          sbmtData.publishing_params.live_stream_url = "rtmp://b.rtmp.youtube.com/live2";
                          sbmtData.publishing_params.live_stream_to = ((sbmtData.publishing_params.live_stream_url.length && sbmtData.publishing_params.live_stream_url[sbmtData.publishing_params.live_stream_url.length - 1] == '/' ? sbmtData.publishing_params.live_stream_url : (sbmtData.publishing_params.live_stream_url + '/')) + sbmtData.publishing_params.live_stream_key) + "?backup=1";
                        }
                        this.ls.requests$.next({
                          value: 50,
                          target: "Duplicate Post"
                        })
                        return this.taskService.addProfile(sbmtData, this.company_id).pipe(
                          switchMap(res => {
                            this.ls.requests$.next({
                              value: 75,
                              target: "Duplicate Post"
                            })
                            if (files.length) {
                               return forkJoin([...files.map(fileData => this.taskService.setTaskChannelFile({
                                company_id: fileData.company_id,
                                task_channel_id: res.id,
                                file_id: fileData.file_id,
                                is_to_upload: !!fileData.is_to_upload ? 1 : 0,
                                is_live_stream_content: !!fileData.is_live_stream_content ? 1 : 0
                              }, this.company_id).pipe(
                                catchError((error) => {
                                  res.hasError = true;
                                  return of(null)
                                })
                              ))]).pipe(
                                tap(u => {
                                  this.ls.requests$.next({
                                    value: 100,
                                    target: "Duplicate Post"
                                  })
                                }),
                                map((newFiles) => {
                                  return {
                                    newPost: res,
                                    files: files,
                                    newFiles: newFiles
                                  }
                                }),
                              )
                            } else {
                              this.ls.requests$.next({
                                value: 100,
                                target: "Duplicate Post"
                              })
                              return of({
                                newPost: res,
                                files: files
                              })
                            }
                          })
                        )
                      }),
                      switchMap(result => {
                        if (this.post_delete_after) {
                          return this.taskService.deleteProfile(post.id).pipe(
                            map(() => result)
                          )
                        } else {
                          return of(result)
                        }
                      })
                    )
                  })
                )
              } else {
                return of(null)
              }
            }
          })
        ).subscribe(resp => {
          console.log('resp with_new_link', resp)
          if (resp == 'old link') {
            this.duplicatePost(card, post, true);
          } else {
            this.getTask(true);
          }
        })
      )
    } else {
      
      this.ls.requests$.next({
        value: 0,
        target: "Duplicate Post"
      })
      this.attachSubscriptions(
        this.taskService.getTaskChannelFile(this.company_id, {task_channel_id: post.id}).pipe(
          switchMap(files => {
            if (this.post_backup_after) {
              sbmtData.publishing_params.live_stream_url = "rtmp://b.rtmp.youtube.com/live2";
              sbmtData.publishing_params.live_stream_to = ((sbmtData.publishing_params.live_stream_url.length && sbmtData.publishing_params.live_stream_url[sbmtData.publishing_params.live_stream_url.length - 1] == '/' ? sbmtData.publishing_params.live_stream_url : (sbmtData.publishing_params.live_stream_url + '/')) + sbmtData.publishing_params.live_stream_key) + "?backup=1";
            }
            this.ls.requests$.next({
              value: 33,
              target: "Duplicate Post"
            })
            return this.taskService.addProfile(sbmtData, this.company_id).pipe(
              switchMap(res => {
                this.ls.requests$.next({
                  value: 66,
                  target: "Duplicate Post"
                })
                if (files.length) {
                   return forkJoin([...files.map(fileData => this.taskService.setTaskChannelFile({
                    company_id: fileData.company_id,
                    task_channel_id: res.id,
                    file_id: fileData.file_id,
                    is_to_upload: !!fileData.is_to_upload ? 1 : 0,
                    is_live_stream_content: !!fileData.is_live_stream_content ? 1 : 0
                  }, this.company_id).pipe(
                    catchError((error) => {
                      res.hasError = true;
                      return of(null)
                    })
                  ))]).pipe(
                    tap(q => {
                      this.ls.requests$.next({
                        value: 100,
                        target: "Duplicate Post"
                      })
                    }),
                    map((newFiles) => {
                      return {
                        newPost: res,
                        files: files,
                        newFiles: newFiles
                      }
                    }),
                  )
                } else {
                  this.ls.requests$.next({
                    value: 100,
                    target: "Duplicate Post"
                  })
                  return of({
                    newPost: res,
                    files: files
                  })
                }
              })
            )
          }),
          switchMap(result => {
            if (this.post_delete_after) {
              return this.taskService.deleteProfile(post.id).pipe(
                map(() => result)
              )
            } else {
              return of(result)
            }
          })
        ).subscribe(resp => {
          console.log("duplicatePost", resp);
          this.getTask(true);
        })
      )
    }

  }

  restartProfile2(card, post, is_backup:boolean = false) {
    console.log("card", card)
    console.log("post", post)

    if (!post.taskChannelFiles || !post.taskChannelFiles.length) {
      this.layoutService.showSnackBar({name: 'No files selected for Live stream.'}, '', SnackBarItem)
      return
    }

    let deleteErrors:number = 0;   

    let sbmtData:any = {
      task_id: card.id,
      channel_id: post.channel_id,
      content_url: post.content_url,
      content_name: post.content_name,
      is_content_views_count_update: post.is_content_views_count_update,
      is_bravourl_content_views_count_update: post.is_bravourl_content_views_count_update,
      content_image: post.content_image,
      content_filename: post.content_filename,
      external_content_id: post.external_content_id,
      content_type_id: post.content_type_id,
      content_published_at: post.content_published_at,
      content_views_count: post.content_views_count,
      publishing_params: post.publishing_params,
    }

    if (is_backup) {
      sbmtData.publishing_params.is_backup = 1;
      sbmtData.publishing_params.live_stream_url_backup = 'rtmp://b.rtmp.youtube.com/live2?backup=1';
      sbmtData.publishing_params.live_stream_to_backup =  sbmtData.publishing_params.live_stream_url_backup.split('?')[0] + '/' + sbmtData.publishing_params.live_stream_key + "?backup=1";
    }

    const dialogRef = this.dialog.open(AskTextComponent, {
      disableClose: true,
      panelClass: 'push_settigns_modal',
      data: {
        title: "Broadcast restart setup",
        field: 'Url',
        withSeconds: true,
        company_id: this.company_id
      }
    });
    // https://www.youtube.com/watch?v=36YnV9STBqc
    this.attachSubscriptions(
      dialogRef.afterClosed().pipe(
        switchMap(result => {
          if (!!result) {
            let newLink;
            if (result.event == 'old link') {
              newLink = sbmtData.content_url
            } else if (result.event == 'new link') {
              newLink = result.data
            } else {
              return of(null)
            }
            this.ls.requests$.next({
              value: 0,
              target: `Restart post ID: ${post.id}`
            })
            return this.taskService.getExpandUrls("1", this.company_id, {id: post.id}, "1").pipe(
              map(x => x.body[0]),
              switchMap(refreshedPost => {
                this.ls.requests$.next({
                  value: 15,
                  target: `Restart post ID: ${post.id}`
                })
                sbmtData = {
                  task_id: card.id,
                  channel_id: refreshedPost.channel_id,
                  content_url: refreshedPost.content_url,
                  content_name: refreshedPost.content_name,
                  is_content_views_count_update: refreshedPost.is_content_views_count_update,
                  is_bravourl_content_views_count_update: refreshedPost.is_bravourl_content_views_count_update,
                  content_image: refreshedPost.content_image,
                  content_filename: refreshedPost.content_filename,
                  external_content_id: refreshedPost.external_content_id,
                  content_type_id: refreshedPost.content_type_id,
                  content_published_at: refreshedPost.content_published_at,
                  content_views_count: refreshedPost.content_views_count,
                  publishing_params: refreshedPost.publishing_params,
                }
            
                if (is_backup) {
                  sbmtData.publishing_params.is_backup = 1;
                  sbmtData.publishing_params.live_stream_url_backup = 'rtmp://b.rtmp.youtube.com/live2?backup=1';
                  sbmtData.publishing_params.live_stream_to_backup =  sbmtData.publishing_params.live_stream_url_backup.split('?')[0] + '/' + sbmtData.publishing_params.live_stream_key + "?backup=1";
                }
                if (refreshedPost.publishing_params.random_start_count <= refreshedPost.taskChannelFiles.length) {
                  return this.taskService.getSmartLinkData(newLink, post.channel_id, this.company_id, (post.company_id != this.company_id ? post.company_id : null)).pipe(
                    switchMap(newLinkData => {
                      sbmtData.content_name = newLinkData.name ? newLinkData.name : 'Post has no text';
                      sbmtData.content_url = newLink;
                      sbmtData.external_content_id = newLinkData.external_content_id;
                      sbmtData.content_filename = !!newLinkData.filename ? newLinkData.filename : '';
                      sbmtData.content_image = newLinkData.image;
                      sbmtData.content_resumed_at = 0;
                      if (newLinkData.published_at) {
                        sbmtData.content_published_at = newLinkData.published_at;
                      }
                      sbmtData.content_views_count = !!newLinkData.views_count ? newLinkData.views_count : '0';
      
                      this.ls.requests$.next({
                        value: 25,
                        target: `Restart post ID: ${post.id}`
                      })
                      return this.taskService.getTaskChannelFile(this.company_id, {task_channel_id: post.id}).pipe(
                        switchMap(files => {
                          if (files.length) {
      
                            files.forEach((chFile, ind) => {
                              if (!!chFile.is_error) {
                                chFile.deleted = true;
                                if (files.filter(u => u.file_id == chFile.file_id && u.is_new).length == 0) {
                                  files.push({
                                    company_id: chFile.company_id,
                                    task_channel_id: '',
                                    file_id: chFile.file_id,
                                    is_to_upload: 1,
                                    is_live_stream_content: 1,
                                    file: chFile.file,
                                    is_new: true
                                  })
                                }
                              }
                            });
      
                            let newFiles = [...files.filter(u => !!u.is_new)];
                            let deletedFiles = [...files.filter(u => !!u.deleted)];
                            console.log("newFiles", newFiles);
                            console.log("deletedFiles", deletedFiles);
                            if (deletedFiles.length) {
                              return forkJoin([...deletedFiles.map(fileData => this.taskService.unsetTaskChannelFile(fileData.id, this.company_id).pipe(
                                catchError((error) => {
                                  deleteErrors++;                            
                                  return of(null)
                                })
                              ))]).pipe(
                                switchMap(deleted => {
                                  if (deleteErrors == 0 && newFiles.length) {
                                    return forkJoin([...newFiles.map(fileData => this.taskService.setTaskChannelFile({
                                      company_id: fileData.company_id,
                                      task_channel_id: post.id,
                                      file_id: fileData.file_id,
                                      is_to_upload: !!fileData.is_to_upload ? 1 : 0,
                                      is_live_stream_content: !!fileData.is_live_stream_content ? 1 : 0
                                    }, this.company_id).pipe(
                                      catchError((error) => {
                                        return of(null)
                                      })
                                    ))]).pipe(
                                      map(() => files)
                                    )
                                  } else {
                                    return of(files)
                                  }
                                })
                              )
                            } else if (newFiles.length) {
                              return forkJoin([...newFiles.map(fileData => this.taskService.setTaskChannelFile({
                                company_id: fileData.company_id,
                                task_channel_id: post.id,
                                file_id: fileData.file_id,
                                is_to_upload: !!fileData.is_to_upload ? 1 : 0,
                                is_live_stream_content: !!fileData.is_live_stream_content ? 1 : 0
                              }, this.company_id).pipe(
                                catchError((error) => {
                                  return of(null)
                                })
                              ))]).pipe(
                                map(() => files)
                              )
                            } else {
                              return of(files)
                            }
      
                          } else {
                            return of(files)
                          }
                        }),
                        switchMap(files => {
                          this.ls.requests$.next({
                            value: 50,
                            target: `Restart post ID: ${post.id}`
                          })
                          if (deleteErrors > 0 || !files.length) {
                            return of(files)
                          } else {
                            return this.taskService.editProfile(post.id, sbmtData, this.company_id).pipe(map(() => files))
                          }
                        }),
                        switchMap(files => {
                          this.ls.requests$.next({
                            value: 75,
                            target: `Restart post ID: ${post.id}`
                          })
                          if (deleteErrors > 0 || !files.length) {
                            return of(false)
                          } else {
                            return this.taskService.getPostExtra(1, this.company_id, {task_channel_id: post.id, is_active: 1}).pipe(
                              map(broadsRes => broadsRes.body),
                              switchMap(broads => {
                                if (broads.length) {
                                  if (broads.filter(u => !u.is_pause).length) {
                                    return forkJoin(broads.filter(u => !u.is_pause).map(notPausedBroad => this.taskService.editBroadcast(notPausedBroad.id, {is_pause: 1, pause_timeout: result.time})))
                                  } else {
                                    return of('stream already restarted')
                                  }
                                } else {
                                  return of('no active broadcast')
                                }
                              })
                            )
                          }
                        })
                      )
                    })
                  )
                } else {
                  return of({error: 'random_start_count error', setCount: refreshedPost.publishing_params.random_start_count, count: refreshedPost.taskChannelFiles.length})
                }
              }) 
            )
          }
        })
      ).subscribe(resp => {
        console.log('resp', resp)
        this.ls.requests$.next({
          value: 100,
          target: `Restart post ID: ${post.id}`
        })
        if (!!resp) {
          this.refreshService.refreshUrls$.next({url_id: post.id, company_id: this.company_id})
        }

        if (resp == 'stream already restarted') {
          this.layoutService.showSnackBar({name: ``}, resp, SnackBarItem)
        }

        if (resp == 'no active broadcast') {
          this.layoutService.showSnackBar({name: ``}, resp, SnackBarItem)
        }
        if (resp.error && resp.error == 'random_start_count error') {
          this.layoutService.showSnackBar({name: ``}, `You didn't selected enough files to start stream.<br><br>It's set ${resp.setCount} files to start broadcast but selected ${resp.count} files for streaming.<br><br>Select ${resp.setCount - resp.count} files more to meet minimum requirement.`, SnackBarItem)
        }

        if (deleteErrors > 0) {
          this.layoutService.showSnackBar({name: `Post id ${post.id} has corrupted files. Contact admin.`}, 'Copy message to admin', SnackBarItem, 60000, undefined, true, post.id, `Post id ${post.id} has corrupted files.\n\nAdmin must restart stream to fix the issue.\n\nLink to find the post: ${this.origin}/urls?company_id=${this.company_id}&q=${post.id}`)
        }
      })
    )
  }

  restartProfile(card, post, is_backup:boolean = false) {
    console.log("card", card)
    console.log("post", post)

    if (!post.taskChannelFiles || !post.taskChannelFiles.length) {
      this.layoutService.showSnackBar({name: 'No files selected for Live stream.'}, '', SnackBarItem)
      return
    }

    let deleteErrors:number = 0;   

    let sbmtData:any = {
      task_id: card.id,
      channel_id: post.channel_id,
      content_url: post.content_url,
      content_name: post.content_name,
      is_content_views_count_update: post.is_content_views_count_update,
      is_bravourl_content_views_count_update: post.is_bravourl_content_views_count_update,
      content_image: post.content_image,
      content_filename: post.content_filename,
      external_content_id: post.external_content_id,
      content_type_id: post.content_type_id,
      content_published_at: post.content_published_at,
      content_status_id: 2,
      content_views_count: post.content_views_count,
      publishing_params: post.publishing_params,
    }

    if (is_backup) {
      sbmtData.publishing_params.is_backup = 1;
      sbmtData.publishing_params.live_stream_url_backup = 'rtmp://b.rtmp.youtube.com/live2?backup=1';
      sbmtData.publishing_params.live_stream_to_backup =  sbmtData.publishing_params.live_stream_url_backup.split('?')[0] + '/' + sbmtData.publishing_params.live_stream_key + "?backup=1";
    }

    const dialogRef = this.dialog.open(AskTextComponent, {
      disableClose: true,
      panelClass: 'push_settigns_modal',
      data: {
        title: "Fill new url",
        field: 'Url',
        company_id: this.company_id
      }
    });
    // https://www.youtube.com/watch?v=36YnV9STBqc
    this.attachSubscriptions(
      dialogRef.afterClosed().pipe(
        switchMap(result => {
          if (!!result) {
            let newLink;
            if (result.event == 'old link') {
              newLink = sbmtData.content_url
            } else if (result.event == 'new link') {
              newLink = result.data
            } else {
              return of(null)
            }
            this.ls.requests$.next({
              value: 0,
              target: `Restart post ID: ${post.id}`
            })
            return this.taskService.getExpandUrls("1", this.company_id, {id: post.id}, "1").pipe(
              map(x => x.body[0]),
              switchMap(refreshedPost => {
                this.ls.requests$.next({
                  value: 15,
                  target: `Restart post ID: ${post.id}`
                })
                sbmtData = {
                  task_id: card.id,
                  channel_id: refreshedPost.channel_id,
                  content_url: refreshedPost.content_url,
                  content_name: refreshedPost.content_name,
                  is_content_views_count_update: refreshedPost.is_content_views_count_update,
                  is_bravourl_content_views_count_update: refreshedPost.is_bravourl_content_views_count_update,
                  content_image: refreshedPost.content_image,
                  content_filename: refreshedPost.content_filename,
                  external_content_id: refreshedPost.external_content_id,
                  content_type_id: refreshedPost.content_type_id,
                  content_status_id: 2,
                  content_published_at: refreshedPost.content_published_at,
                  content_views_count: refreshedPost.content_views_count,
                  publishing_params: refreshedPost.publishing_params,
                }
            
                if (is_backup) {
                  sbmtData.publishing_params.is_backup = 1;
                  sbmtData.publishing_params.live_stream_url_backup = 'rtmp://b.rtmp.youtube.com/live2?backup=1';
                  sbmtData.publishing_params.live_stream_to_backup =  sbmtData.publishing_params.live_stream_url_backup.split('?')[0] + '/' + sbmtData.publishing_params.live_stream_key + "?backup=1";
                }
                if (refreshedPost.publishing_params.random_start_count <= refreshedPost.taskChannelFiles.length) {
                  return this.taskService.getSmartLinkData(newLink, post.channel_id, this.company_id, (post.company_id != this.company_id ? post.company_id : null)).pipe(
                    switchMap(newLinkData => {
                      sbmtData.content_name = newLinkData.name ? newLinkData.name : 'Post has no text';
                      sbmtData.content_url = newLink;
                      sbmtData.external_content_id = newLinkData.external_content_id;
                      sbmtData.content_filename = !!newLinkData.filename ? newLinkData.filename : '';
                      sbmtData.content_image = newLinkData.image;
                      if (newLinkData.published_at) {
                        sbmtData.content_published_at = newLinkData.published_at;
                      }
                      if (newLinkData.external_content_id) {
                        sbmtData.content_status_id = 6;
                        sbmtData.content_resumed_at = 0;
                      }
                      sbmtData.content_views_count = !!newLinkData.views_count ? newLinkData.views_count : '0';
      
                      this.ls.requests$.next({
                        value: 45,
                        target: `Restart post ID: ${post.id}`
                      })
                      return this.taskService.getTaskChannelFile(this.company_id, {task_channel_id: post.id}).pipe(
                        switchMap(files => {
                          if (files.length) {
      
                            files.forEach((chFile, ind) => {
                              if (!!chFile.is_error) {
                                chFile.deleted = true;
                                if (files.filter(u => u.file_id == chFile.file_id && u.is_new).length == 0) {
                                  files.push({
                                    company_id: chFile.company_id,
                                    task_channel_id: '',
                                    file_id: chFile.file_id,
                                    is_to_upload: 1,
                                    is_live_stream_content: 1,
                                    file: chFile.file,
                                    is_new: true
                                  })
                                }
                              }
                            });
      
                            let newFiles = [...files.filter(u => !!u.is_new)];
                            let deletedFiles = [...files.filter(u => !!u.deleted)];
                            console.log("newFiles", newFiles);
                            console.log("deletedFiles", deletedFiles);
                            if (deletedFiles.length) {
                              return forkJoin([...deletedFiles.map(fileData => this.taskService.unsetTaskChannelFile(fileData.id, this.company_id).pipe(
                                catchError((error) => {
                                  deleteErrors++;                            
                                  return of(null)
                                })
                              ))]).pipe(
                                switchMap(deleted => {
                                  if (deleteErrors == 0 && newFiles.length) {
                                    return forkJoin([...newFiles.map(fileData => this.taskService.setTaskChannelFile({
                                      company_id: fileData.company_id,
                                      task_channel_id: post.id,
                                      file_id: fileData.file_id,
                                      is_to_upload: !!fileData.is_to_upload ? 1 : 0,
                                      is_live_stream_content: !!fileData.is_live_stream_content ? 1 : 0
                                    }, this.company_id).pipe(
                                      catchError((error) => {
                                        return of(null)
                                      })
                                    ))]).pipe(
                                      map(() => files)
                                    )
                                  } else {
                                    return of(files)
                                  }
                                })
                              )
                            } else if (newFiles.length) {
                              return forkJoin([...newFiles.map(fileData => this.taskService.setTaskChannelFile({
                                company_id: fileData.company_id,
                                task_channel_id: post.id,
                                file_id: fileData.file_id,
                                is_to_upload: !!fileData.is_to_upload ? 1 : 0,
                                is_live_stream_content: !!fileData.is_live_stream_content ? 1 : 0
                              }, this.company_id).pipe(
                                catchError((error) => {
                                  return of(null)
                                })
                              ))]).pipe(
                                map(() => files)
                              )
                            } else {
                              return of(files)
                            }
      
                          } else {
                            return of(files)
                          }
                        }),
                        switchMap(files => {
                          this.ls.requests$.next({
                            value: 75,
                            target: `Restart post ID: ${post.id}`
                          })
                          if (deleteErrors > 0 || !files.length) {
                            return of(files)
                          } else {
                            return this.taskService.editProfile(post.id, sbmtData, this.company_id).pipe(map(() => files))
                          }
                        })
                      )
                    })
                  )
                } else {
                  return of({error: 'random_start_count error', setCount: refreshedPost.publishing_params.random_start_count, count: refreshedPost.taskChannelFiles.length})
                }
              }) 
            )
          }
        })
      ).subscribe(resp => {
        console.log('resp', resp)
        this.ls.requests$.next({
          value: 100,
          target: `Restart post ID: ${post.id}`
        })
        if (!!resp) {
          this.refreshService.refreshUrls$.next({url_id: post.id, company_id: this.company_id})
        }
        if (resp.error && resp.error == 'random_start_count error') {
          this.layoutService.showSnackBar({name: ``}, `You didn't selected enough files to start stream.<br><br>It's set ${resp.setCount} files to start broadcast but selected ${resp.count} files for streaming.<br><br>Select ${resp.setCount - resp.count} files more to meet minimum requirement.`, SnackBarItem)
        }

        if (deleteErrors > 0) {
          this.layoutService.showSnackBar({name: `Post id ${post.id} has corrupted files. Contact admin.`}, 'Copy message to admin', SnackBarItem, 60000, undefined, true, post.id, `Post id ${post.id} has corrupted files.\n\nAdmin must restart stream to fix the issue.\n\nLink to find the post: ${this.origin}/urls?company_id=${this.company_id}&q=${post.id}`)
        }
      })
    )
  }

  startLivePost(card, post, is_backup:boolean = false) {
    console.log("card", card)
    console.log("post", post)

    if (!post.taskChannelFiles || !post.taskChannelFiles.length) {
      this.layoutService.showSnackBar({name: 'No files selected for Live stream.'}, '', SnackBarItem)
      return
    }

    let deleteErrors:number = 0;   

    let sbmtData:any = {
      task_id: card.id,
      channel_id: post.channel_id,
      content_url: post.content_url,
      content_name: post.content_name,
      is_content_views_count_update: post.is_content_views_count_update,
      is_bravourl_content_views_count_update: post.is_bravourl_content_views_count_update,
      content_image: post.content_image,
      content_filename: post.content_filename,
      external_content_id: post.external_content_id,
      content_type_id: post.content_type_id,
      content_published_at: post.content_published_at,
      content_status_id: 2,
      content_views_count: post.content_views_count,
      publishing_params: post.publishing_params,
    }

    if (is_backup) {
      sbmtData.publishing_params.is_backup = 1;
      sbmtData.publishing_params.live_stream_url_backup = 'rtmp://b.rtmp.youtube.com/live2?backup=1';
      sbmtData.publishing_params.live_stream_to_backup =  sbmtData.publishing_params.live_stream_url_backup.split('?')[0] + '/' + sbmtData.publishing_params.live_stream_key + "?backup=1";
    }

    const dialogRef = this.dialog.open(AskTextComponent, {
      disableClose: true,
      panelClass: 'push_settigns_modal',
      data: {
        title: "Fill new url",
        field: 'Url',
        company_id: this.company_id
      }
    });
    // https://www.youtube.com/watch?v=36YnV9STBqc
    this.attachSubscriptions(
      dialogRef.afterClosed().pipe(
        switchMap(result => {
          if (!!result) {
            let newLink;
            if (result.event == 'old link') {
              newLink = sbmtData.content_url
            } else if (result.event == 'new link') {
              newLink = result.data
            } else {
              return of(null)
            }
            this.ls.requests$.next({
              value: 0,
              target: `Restart post ID: ${post.id}`
            })
            return this.taskService.getExpandUrls("1", this.company_id, {id: post.id}, "1").pipe(
              map(x => x.body[0]),
              switchMap(refreshedPost => {
                this.ls.requests$.next({
                  value: 15,
                  target: `Restart post ID: ${post.id}`
                })
                sbmtData = {
                  task_id: card.id,
                  channel_id: refreshedPost.channel_id,
                  content_url: refreshedPost.content_url,
                  content_name: refreshedPost.content_name,
                  is_content_views_count_update: refreshedPost.is_content_views_count_update,
                  is_bravourl_content_views_count_update: refreshedPost.is_bravourl_content_views_count_update,
                  content_image: refreshedPost.content_image,
                  content_filename: refreshedPost.content_filename,
                  external_content_id: refreshedPost.external_content_id,
                  content_type_id: refreshedPost.content_type_id,
                  content_status_id: 2,
                  content_published_at: refreshedPost.content_published_at,
                  content_views_count: refreshedPost.content_views_count,
                  publishing_params: refreshedPost.publishing_params,
                }
            
                if (is_backup) {
                  sbmtData.publishing_params.is_backup = 1;
                  sbmtData.publishing_params.live_stream_url_backup = 'rtmp://b.rtmp.youtube.com/live2?backup=1';
                  sbmtData.publishing_params.live_stream_to_backup =  sbmtData.publishing_params.live_stream_url_backup.split('?')[0] + '/' + sbmtData.publishing_params.live_stream_key + "?backup=1";
                }
                if (refreshedPost.publishing_params.random_start_count <= refreshedPost.taskChannelFiles.length) {
                  return this.taskService.getSmartLinkData(newLink, post.channel_id, this.company_id, (post.company_id != this.company_id ? post.company_id : null)).pipe(
                    switchMap(newLinkData => {
                      sbmtData.content_name = newLinkData.name ? newLinkData.name : 'Post has no text';
                      sbmtData.content_url = newLink;
                      sbmtData.external_content_id = newLinkData.external_content_id;
                      sbmtData.content_filename = !!newLinkData.filename ? newLinkData.filename : '';
                      sbmtData.content_image = newLinkData.image;
                      if (newLinkData.published_at) {
                        sbmtData.content_published_at = newLinkData.published_at;
                      }
                      if (newLinkData.external_content_id) {
                        sbmtData.content_status_id = 6
                      }
                      sbmtData.content_views_count = !!newLinkData.views_count ? newLinkData.views_count : '0';
      
                      this.ls.requests$.next({
                        value: 45,
                        target: `Restart post ID: ${post.id}`
                      })
                      return this.taskService.getTaskChannelFile(this.company_id, {task_channel_id: post.id}).pipe(
                        switchMap(files => {
                          if (files.length) {
      
                            files.forEach((chFile, ind) => {
                              if (!!chFile.is_error) {
                                chFile.deleted = true;
                                if (files.filter(u => u.file_id == chFile.file_id && u.is_new).length == 0) {
                                  files.push({
                                    company_id: chFile.company_id,
                                    task_channel_id: '',
                                    file_id: chFile.file_id,
                                    is_to_upload: 1,
                                    is_live_stream_content: 1,
                                    file: chFile.file,
                                    is_new: true
                                  })
                                }
                              }
                            });
      
                            let newFiles = [...files.filter(u => !!u.is_new)];
                            let deletedFiles = [...files.filter(u => !!u.deleted)];
                            console.log("newFiles", newFiles);
                            console.log("deletedFiles", deletedFiles);
                            if (deletedFiles.length) {
                              return forkJoin([...deletedFiles.map(fileData => this.taskService.unsetTaskChannelFile(fileData.id, this.company_id).pipe(
                                catchError((error) => {
                                  deleteErrors++;                            
                                  return of(null)
                                })
                              ))]).pipe(
                                switchMap(deleted => {
                                  if (deleteErrors == 0 && newFiles.length) {
                                    return forkJoin([...newFiles.map(fileData => this.taskService.setTaskChannelFile({
                                      company_id: fileData.company_id,
                                      task_channel_id: post.id,
                                      file_id: fileData.file_id,
                                      is_to_upload: !!fileData.is_to_upload ? 1 : 0,
                                      is_live_stream_content: !!fileData.is_live_stream_content ? 1 : 0
                                    }, this.company_id).pipe(
                                      catchError((error) => {
                                        return of(null)
                                      })
                                    ))]).pipe(
                                      map(() => files)
                                    )
                                  } else {
                                    return of(files)
                                  }
                                })
                              )
                            } else if (newFiles.length) {
                              return forkJoin([...newFiles.map(fileData => this.taskService.setTaskChannelFile({
                                company_id: fileData.company_id,
                                task_channel_id: post.id,
                                file_id: fileData.file_id,
                                is_to_upload: !!fileData.is_to_upload ? 1 : 0,
                                is_live_stream_content: !!fileData.is_live_stream_content ? 1 : 0
                              }, this.company_id).pipe(
                                catchError((error) => {
                                  return of(null)
                                })
                              ))]).pipe(
                                map(() => files)
                              )
                            } else {
                              return of(files)
                            }
      
                          } else {
                            return of(files)
                          }
                        }),
                        switchMap(files => {
                          this.ls.requests$.next({
                            value: 75,
                            target: `Restart post ID: ${post.id}`
                          })
                          if (deleteErrors > 0 || !files.length) {
                            return of(files)
                          } else {
                            return this.taskService.editProfile(post.id, sbmtData, this.company_id).pipe(map(() => files))
                          }
                        })
                      )
                    })
                  )
                } else {
                  return of({error: 'random_start_count error', setCount: refreshedPost.publishing_params.random_start_count, count: refreshedPost.taskChannelFiles.length})
                }
              }) 
            )
          }
        })
      ).subscribe(resp => {
        console.log('resp', resp)
        this.ls.requests$.next({
          value: 100,
          target: `Restart post ID: ${post.id}`
        })
        if (!!resp) {
          this.refreshService.refreshUrls$.next({url_id: post.id, company_id: this.company_id})
        }
        if (resp.error && resp.error == 'random_start_count error') {
          this.layoutService.showSnackBar({name: ``}, `You didn't selected enough files to start stream.<br><br>It's set ${resp.setCount} files to start broadcast but selected ${resp.count} files for streaming.<br><br>Select ${resp.setCount - resp.count} files more to meet minimum requirement.`, SnackBarItem)
        }

        if (deleteErrors > 0) {
          this.layoutService.showSnackBar({name: `Post id ${post.id} has corrupted files. Contact admin.`}, 'Copy message to admin', SnackBarItem, 60000, undefined, true, post.id, `Post id ${post.id} has corrupted files.\n\nAdmin must restart stream to fix the issue.\n\nLink to find the post: ${this.origin}/urls?company_id=${this.company_id}&q=${post.id}`)
        }
      })
    )
  }

  extraInfo(card, post) {
    const dialogRef = this.dialog.open(PostExtraInfoComponent, {
      disableClose: true,
      panelClass: 'push_settigns_modal',
      data: {
        post: post,
        company: this.company,
        card: card,
        host: this.host,
        imgRoute: this.imgRoute,
        origin: this.origin,
        user: this.user,
        activeLang: this.activeLang,
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        
      })
    )
  }
  
  multiBackup() {
    let lenSelection = this.profileCollection.selected.filter(u => !!u.external_content_id).length;
    let count = 0;
    if (!lenSelection) {
      this.layoutService.showSnackBar({name: 'All of selected posts has no external content id, you cannot duplicate them.'}, '', SnackBarItem)
      return
    }
    from(this.profileCollection.selected.filter(u => !!u.external_content_id)).pipe(
      concatMap((post:any) => {
        // let filterData = {
        //   channel_id: profile.channel_id,
        //   content_type_id: profile.content_type_id,
        //   publish_at_from: moment().format("X")
        // }

        return this.taskService.getTaskChannelFile(this.company_id, {task_channel_id: post.id}).pipe(
          switchMap(files => {
            let sbmtData = {
              task_id: post.task_id,
              channel_id: post.channel_id,
              content_url: post.content_url,
              content_name: post.content_name,
              is_content_views_count_update: post.is_content_views_count_update,
              is_bravourl_content_views_count_update: post.is_bravourl_content_views_count_update,
              content_image: post.content_image,
              content_filename: post.content_filename,
              external_content_id: post.external_content_id,
              content_type_id: post.content_type_id,
              content_published_at: post.content_published_at,
              content_status_id: 6,
              content_views_count: post.content_views_count,
              publishing_params: post.publishing_params,
            }
            sbmtData.publishing_params.live_stream_url = "rtmp://b.rtmp.youtube.com/live2";
            sbmtData.publishing_params.live_stream_to = ((sbmtData.publishing_params.live_stream_url.length && sbmtData.publishing_params.live_stream_url[sbmtData.publishing_params.live_stream_url.length - 1] == '/' ? sbmtData.publishing_params.live_stream_url : (sbmtData.publishing_params.live_stream_url + '/')) + sbmtData.publishing_params.live_stream_key) + "?backup=1";
            return this.taskService.addProfile(sbmtData, this.company_id).pipe(
              switchMap(res => {
                if (files.length) {
                   return forkJoin([...files.map(fileData => this.taskService.setTaskChannelFile({
                    company_id: fileData.company_id,
                    task_channel_id: res.id,
                    file_id: fileData.file_id,
                    is_to_upload: !!fileData.is_to_upload ? 1 : 0,
                    is_live_stream_content: !!fileData.is_live_stream_content ? 1 : 0
                  }, this.company_id).pipe(
                    catchError((error) => {
                      res.hasError = true;
                      return of(null)
                    })
                  ))]).pipe(
                    map((newFiles) => {
                      return {
                        newPost: res,
                        files: files,
                        newFiles: newFiles
                      }
                    }),
                  )
                } else {
                  return of({
                    newPost: res,
                    files: files
                  })
                }
              })
            )
          })
        )
      })
    ).subscribe({
      next: (next) => {
        console.log("next multiBackup", next);
        // this.refreshService.refreshUrls$.next({url_id: next.newPost.id, company_id: this.company_id, new: true})
        this.ls.requests$.next({
          value: Math.round((100 / lenSelection) * (count+1)),
          target: "Backup",
        })
        count++;
      },
      complete: () => {
        console.log("complete multiBackup");
        this.profileCollection.clear();
        this.getTask(true)
      },
      error: (error) => {
        console.log("error multiBackup", error)
      }
    })
  }

  getTask(refresh:boolean = false) {
    if (this.data.hasOwnProperty('onPause')) {
      this.data.onPause();
    }
    this.isRefreshed = true;
    if (refresh) {
      this.refreshService.refreshTask$.next({company_id: this.company_id, task_id: this.data.task_id})
      this.initRefreshBoard()
    }
    
    // getOneTaskExpandNoChan
    this.attachSubscriptions(
      this.taskService.getOneTaskExpandNoChan(this.company_id, this.data.task_id).pipe(
        map(x => x.body[0]),
        switchMap(x => this.neededData(x).pipe(map(() => casesModel([x], [], 'update')),map(x => x.arr[0]))),
        tap(resp => {
          resp.operations.forEach(element => {
            if (element.automationScenarios && element.automationScenarios.length) {
              element.selectedScenIds = element.automationScenarios.map(x => x.template_id);
            } else {
              element.selectedScenIds = [];
            }
            element.mobEmployees = [];
            let allEmployees = element.employees.filter(x => x.task_operation_id == element.id);
            allEmployees.forEach(empl => {
              if (!element.mobEmployees.find(x => x.employee_id == empl.employee_id)) {
                element.mobEmployees.push(empl)
              }
            });
  
            let allAddEmployee = element.employees.filter(x => x.task_operation_id == element.id && (x.is_manager == 0 || (x.is_manager == 1 && x.company_id != element.company_id)));
            element.uniqueAddEmployee = []
            allAddEmployee.forEach(empl => {
              if (!element.uniqueAddEmployee.find(x => x.employee_id == empl.employee_id)) {
                element.uniqueAddEmployee.push(empl)
              }
            });
          })
          if (!this.task) {
            (this.task as any) = {}
          }
          // if (resp.channels && resp.contentPlans) {
          //   resp.contentPlans.forEach(item => {
          //     if (resp.channels.find(x => x.channel_id == item.channel_id && x.content_type_id == item.content_type_id)) {
          //       resp.channels.find(x => x.channel_id == item.channel_id && x.content_type_id == item.content_type_id).contentPlan = item;
          //     }
          //   });
          // }
          Object.keys(resp).forEach(key => {
            if (key == 'meta' && this.task && this.task[key]) {
              console.log("TEST", this.task, this.task[key])
              let u = JSON.parse(JSON.stringify(this.task[key], this.getCircularReplacer()));
              this.task[key] = resp[key];
              if (u && u.task_channels_by_platform_arr) {
                u.task_channels_by_platform_arr.forEach(el => {
                  if (el.arr && el.arr.length && this.task[key].task_channels_by_platform_arr.find(p => p.platform_id == el.platform_id)) {
                    this.task[key].task_channels_by_platform_arr.find(p => p.platform_id == el.platform_id).arr = el.arr;
                  }
                });
              }
            } else {
              this.task[key] = resp[key]
            }
          })
  
          this.task.operations = [];
          this.task.operations = [...resp.operations]
          this.sort(this.sortValue);
        }),
      ).subscribe(resp => {
        // this.jobs$.next(this.task.operations.slice())
        if (this.gettingTaskSub) {
          this.gettingTaskSub.unsubscribe()
        }
        this.getConnectedCards();
        if (!this.is_mobile) {
          this.refreshService.isTaskGetting$.next({company_id: this.company_id, task_id: this.task.id})
        }
        console.log("getTask", this.task)
        if (!this.is_mobile) {
          this.getChatsForTask(this.task);
        }
        this.getCurrencyList();

        if (this.count == 1) {
          if (!!this.task && this.task.data && this.task.data[0] && this.task.data[0].type == 'GroupBy' && this.task.data[0].parameter_id && this.allValues && this.allValues.length) {
            this.respawnTabs(this.task);
          }
          if (this.data.loc) {
            if (this.is_mobile) {
              this.changeTab(2)
            } else {
              this.showFilesInterface(this.task, undefined, this.data.loc)
            }
          }
          
          this.openTab()

          if (this.data.createJob) {
            if ((this.company?.permissions.includes('owner') || this.checkIsManager(this.task, this.company, this.user)) && this.task?.company_id == this.company?.id) {
              this.addWork(this.task)
            } else {
              this.addEmplWork(this.task)
            }
          }
          if (!this.is_mobile) {
            if (this.task.meta && (this.task.meta.posts_count < 7 || this.is_mobile)) {
              this.activeTab(null, {platform_id: 0})
            }
          }
        } else {
          if (!this.is_mobile) {
            if (this.channelsTab >= 0) {
              this.activeTab(null, {platform_id: this.channelsTab})
            }
          }
        }

        // if (this.is_mobile && this.task.channels) {
        //   this.attachSubscriptions(
        //     forkJoin([...this.task.channels.filter(x => x.external_content_id && x.content_published_at).map(channel => this.taskService.getContentAnlytics(this.company_id, {
        //       start_time_from: channel.content_published_at,
        //       period: 'day',
        //       totals: '1',
        //       order_by: 'start_time',
        //       'per-page': 1,
        //       content_id: channel.external_content_id,
        //     }, "1").pipe(
        //       tap(el => {
        //         channel.summaryAveragePercentageViewed = Math.round(+el.headers.get('x-summary-average-view-percentage'))
        //         channel.summaryAverageViewDuration = +el.headers.get('x-summary-average-view-duration')
        //       })
        //     ))]).subscribe(res => {
        //       console.log("this.task.channels", this.task.channels)
        //     })
        //     )
        // }
          
        this.count++;



        if (this.data.hasOwnProperty('onResume')) {
          this.data.onResume();
        }
        
        this.isRefreshed = false;
        if (!refresh && !this.task.comment && !this.task.private_comment) {
          this.tab = 1;
          if (this.is_mobile) {
            this.switchData()
          }
        }
        
      })
    )
  }

  selectWorkSpace(related) {
    if (this.company.permissions.includes("owner") || this.company.permissions.includes("manager") || this.company.permissions.includes("admin")) {
      this.createTask(this.company, related);
    } else {
      this.emplCreateTask(this.company, related)
    }
  }

  emplCreateTask(company, related) {
    const dialogRef = this.dialog.open(EmplCreateTaskComponent, {
      disableClose: true,
      data: {
        company_id: company.id,
        company: company,
        imgRoute: this.data.imgRoute,
        host: this.data.host,
        related: related
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log("getONE BEFORE", result);
        if (!!result && result.event == 'Add') {
          this.getTask();
        }
      })
    )
  }

  createTask(company, related) {
    const dialogRef = this.dialog.open(CreateTaskComponent, {
      disableClose: true,
      data: {
        company_id: company.id,
        company: company,
        imgRoute: this.data.imgRoute,
        host: this.data.host,
        related: related
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result && result.event == 'Add') {
          this.getTask();
        }
      })
    )
  }

  openTab() {
    if (this.data.tab) {
      this.tab = this.data.tab

      if (this.is_mobile) {
        this.switchData()
      }
      
      if (!this.is_mobile) {
        switch (this.data.tab) {
          case 5:
            this.openTaskChats(this.task)
            break;
          case 3:
            setTimeout(() => {this.scrollToProfiles()}, 250)
            break;
          case 2:
            this.showFilesInterface(this.task)
            break;
        
          default:
            break;
        }
      }
    }
  }

  getCompany() {
    this.attachSubscriptions(
      this.companyService.getCompany(this.company_id).pipe(
        map(x => x[0]),
        tap(resp => {
          this.companyService.company$.next(resp);
          this.company = resp;
        }),
        switchMap(x => this.uploadService.getUploadLimit().pipe(
          tap(z => {
            if (z || this.company.filesize >= this.company.filesize_limit) {
              this.uploadLimit = true;
            }
          })
        ))
      ).subscribe(resp => {
        this.whoAmI()
        this.getBookmarks()
        console.log("getCompany", resp);
      })
    )
  }

  getUser() {
    this.attachSubscriptions(
      this.auth.$user.subscribe(resp => {
        this.user = resp;
      })
    )
  }

  whoAmI() {
    if (this.company.permissions.includes("owner") || this.company.permissions.includes("admin") || this.company.permissions.includes("manager")) {
      this.isManager = true;
      return
    }
    if (this.task?.managers.filter(x => x.id == this.company.employees[0].id).length > 0 || this.task?.group?.managers.filter(x => x.id == this.company.employees[0].id).length > 0) {
      this.isManager = true;
      return
    }
  }

  isMainEmpl(job) {
    if (job && this.company && job?.employees && (job?.employees.filter(x => !x.discussion_id && this.company && this.company.employees[0] && (x.employee_id == this.company.employees[0].id || x.partner_employee_id == this.company.employees[0].id) && x.is_manager == 1).length > 0 || (job.company_id != this.company.id && this.company.permissions.includes("owner")))) {
      return true;
    } else {
      return false;
    }
  }
  // || (x.partner_employee_id == this.company.employees[0].id)

  getCurrencyList() {
    this.attachSubscriptions(
      this.globalDataService.getCurrencies().subscribe(resp => {
        let currencyData = resp.slice()
        console.log("getCurrencyList", currencyData)
        currencyData.unshift({
          created_at: 0,
          display_format: "{value}",
          id: 8,
          iso_name: "",
          name: "",
          rate: "0.0000",
          short_name: "",
          updated_at: 0
        })
        currencyData.push({
          created_at: 0,
          display_format: "PTS {value}",
          id: 0,
          iso_name: "PTS",
          name: "Points",
          rate: "0.0000",
          short_name: "pts",
          updated_at: 0
        })
        currencyData.map(z => z.summary = 0);
        this.task.operations.map(x => {
          x.emplSummary = JSON.parse(JSON.stringify(currencyData));
          
          x.employees.map(y => {
            x.emplSummary[!!y.price_currency_id ? y.price_currency_id : 0].summary = Number(x.emplSummary[!!y.price_currency_id ? y.price_currency_id : 0].summary) + Number(y.price);
            x.emplSummary[currencyData.length - 1].summary = Number(x.emplSummary[currencyData.length - 1].summary) + Number(y.rate);
          })
          x.emplSummCount = x.emplSummary.filter(t => t.summary > 0).length
        });
      })
    )
  }

  onImgError(event){
    event.target.src = this.imgRoute+'/assets/img/image_black_48dp.svg'
  }

  onPartnerImgError(event){
    event.target.src = this.imgRoute+'/assets/img/partner.png'
  }

  multiEditJobsType() {
    const dialogRef = this.dialog.open(MultiEditJobsTypeComponent, {
      data: {
        company: this.company,
        user: this.user,
        selectedJobs: this.jobCollection.selected
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result && result.event == "update") {
          this.getTask(true);
          this.jobCollection.clear()
        }
      })
    )
  }

  multiJobsExecScenario() {
    const dialogRef = this.dialog.open(MultiJobsExecScenariosComponent, {
      data: {
        company: this.company,
        user: this.user,
        selectedJobs: this.jobCollection.selected
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result && result.event == "update") {
          this.getTask(true);
          this.jobCollection.clear()
        }
      })
    )
  }

  multiJobsAssignScenario() {
    const dialogRef = this.dialog.open(MultiJobsAssignScenarioComponent, {
      data: {
        company: this.company,
        user: this.user,
        is_manage: true,
        selectedJobs: this.jobCollection.selected
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result && result.event == "update") {
          this.getTask(true);
          this.jobCollection.clear()
        }
      })
    )
  }

  drop(event: CdkDragDrop<string[]>) {
    console.log("drop", event);
    console.log("drop", event.previousIndex + " -> " + event.currentIndex);


    let targetWork = event.item.data;
    moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    let works:any = event.container.data;
    let prevSibling:any = works[event.currentIndex - 1];
    let nextSibling:any = works[event.currentIndex + 1];

    let sbmtData:any = {
      order_after_id: 0
    }
    if (event.currentIndex == 0) {
      sbmtData.order_after_id = 0;
    } else if (event.currentIndex == works.length - 1) {
      sbmtData.order_after_id = -1;
    } else {
      sbmtData.order_after_id = prevSibling.id;
    }
    this.attachSubscriptions(
      this.taskService.editWork(targetWork.id, sbmtData, this.company_id).subscribe(resp => {
        console.log(resp);
        this.task.operations.find(x => x.id == resp.id).order = resp.order;
        console.log(this.task.operations);
        this.sort(this.sortValue);
      }, error => {
        moveItemInArray(event.container.data, event.currentIndex, event.previousIndex);
      })
    )
  }

  sort(val) {
    this.sortValue = val
    switch (val) {
      case 'custom_desc':
        this.task.operations.sort((a,b) => {
          return a.order - b.order;
        })
        break;
      case 'priority_desc':
        this.task.operations.sort((a,b) => {
          return b.priority - a.priority;
        })
        break;
      case 'priority':
        this.task.operations.sort((a,b) => {
          return a.priority - b.priority;
        })
        break;
      case 'updated_desc':
        this.task.operations.sort((a,b) => {
          return +b.updated_at - +a.updated_at;
        })
        break;
      case 'updated':
        this.task.operations.sort((a,b) => {
          return +a.updated_at - +b.updated_at;
        })
        break;
      case 'id_desc':
        this.task.operations.sort((a,b) => {
          return b.id - a.id;
        })
        break;
      case 'id':
        this.task.operations.sort((a,b) => {
          return a.id - b.id;
        })
        break;
      case 'filesize_desc':
        this.task.operations.sort((a,b) => {
          return b.filesize - a.filesize;
        })
        break;
      case 'filesize':
        this.task.operations.sort((a,b) => {
          return a.filesize - b.filesize;
        })
        break;
    }
    this.onSearchJobs(this.jobsControl.value)
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  getOperations() {
    this.attachSubscriptions(
      this.taskService.getOperations(this.company_id, this.activeLang).subscribe(resp => {
        this.operationsValues = resp;
      })
    )
  }

  addEmployee(job) {
    const dialogRef = this.dialog.open(SetEmplComponent, {
      data: {
        company: this.company,
        user: this.user,
        task: this.task,
        work: job
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result && result.event == "update") {
          this.getTask(true);
        }
      })
    )
  }

  deleteEmployee(job) {
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: {},
        target: marker("job employee")
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        this.attachSubscriptions(
          this.membersService.deleteMember(job.employee.id, this.company_id).subscribe(resp => {
            this.getTask(true);
          })
        )
      }
    });
  }
  // /to_approve

  // getAdditionalEmpl() {
  //   this.attachSubscriptions(
  //     forkJoin(this.task.operations.map(x => this.taskService.getAdditionalEmpl(x.id).pipe(
  //       tap(val => {
  //         x.addEmployee = val
  //       })
  //     ))).subscribe(resp => {
  //       console.log("JOBS", this.task.operations);
  //     })
  //   )
  // }

  goToTask(e,task) {
    e.preventDefault();
    this.alldialogRef.closeAll();
    this.router.navigate([`/task/${task.acm}`]);
  }

  understandFileType(val) {
    if (!val) {
      return ""
    }
    
    if (val == 'application/pdf') {
      return 'pdf'
    } else if (val.indexOf("/") != -1) {
      return val.split('/')[0]
    } else {
      return ""
    }
  }

  // openChat(_chat) {
  //   this.attachSubscriptions(
  //     this.chatService.getChatsExpand(1, this.company_id, {id: _chat.id}).pipe(
  //       map(res => res.body[0])
  //     ).subscribe(chat => {
  //       const goToChat = this.dialog.open(ChatsComponent, {
  //         backdropClass: ['backdrop_under_header', "without_pad"],
  //         panelClass: !this.is_mobile ? ['full_screen_dialog', 'global_chats_dialog', 'show_header'] : ['full_screen_dialog', 'global_chats_dialog'],
  //         position: !this.is_mobile ? { top: '58px', left: '0', right: '0', bottom: '0' } : {},
  //         data: {
  //           chat: chat,
  //           company: this.company,
  //           user: this.user,
  //           imgRoute: this.imgRoute,
  //           host: this.host,
  //           activeLang: this.activeLang,
  //           task: this.task
  //         }
  //       });

  //       this.attachSubscriptions(
  //         goToChat.afterClosed().subscribe(res => {
  //           this.getTask(true)
  //         })
  //       )
  //     })
  //   )
  // }

  deleteProfiles() {
    console.log(this.profileCollection.selected);

    let confirmAlert =  this.bottomSheet.open(BackJobToPrevStatusComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        msg: marker(`Are you sure you want to delete ${this.profileCollection.selected.length} profile(s):`),
        profileCollection: this.profileCollection.selected,
        btn_no: "Cancel",
        btn_yes: "Delete"
      }
    });

    confirmAlert.afterDismissed().subscribe(data => {
      if (data && data.message == 'no') {
        console.log("no");
        this.profileCollection.clear()
      } else if (data && data.message == 'yes') {

        if (this.profileCollection.selected.filter(x => !!x.content_url).length > 0) {
          let newConfirmAlert =  this.bottomSheet.open(BackJobToPrevStatusComponent, {
            hasBackdrop: true,
            backdropClass: 'bottom-sheed-backdrop',
            data: {
              msg: marker(`Are you sure you want to delete ${this.profileCollection.length} profile(s):`),
              profileCollection: this.profileCollection.selected,
              hasInfoProfileCollection: this.profileCollection.selected.filter(x => !!x.content_url),
              btn_no: "Yes!",
              btn_yes: "Leave non-empty profiles."
            }
          });
      
          newConfirmAlert.afterDismissed().subscribe(data => {
            if (data && data.message == 'no') {
              forkJoin(this.profileCollection.selected.map(x => this.taskService.deleteProfile(x.id))).subscribe(resp => {
                this.profileCollection.clear()
                this.getTask(true)
              })
            } else if (data && data.message == 'yes') {
              forkJoin(this.profileCollection.selected.filter(x => !x.content_url).map(x => this.taskService.deleteProfile(x.id))).subscribe(resp => {
                this.profileCollection.clear()
                this.getTask(true)
              })
            }
          })
        } else {
          forkJoin(this.profileCollection.selected.map(x => this.taskService.deleteProfile(x.id))).subscribe(resp => {
            this.profileCollection.clear()
            this.getTask(true)
          })
        }
      }
    })
  }

  editChat(chat) {
    const editChat = this.dialog.open(EditOperationChatComponent, {
      disableClose: true,
      data: {
        company: this.company,
        is_create_chat_component: true,
        chat: chat,
        user: this.user
      }
    });

    this.attachSubscriptions(
      editChat.afterClosed().subscribe(result => {
        if (!!result && result.event == 'edit') {
          this.getTask(true)
        }
      })
    )
  }

  

  deleteChat(chat) {
    if (!this.is_mobile) {
      let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
        hasBackdrop: true,
        backdropClass: 'bottom-sheed-backdrop',
        data: {
          targetVal: chat,
          target: marker("chat")
        }
      });
  
      deleteAlert.afterDismissed().subscribe( data => {
        if (data && data.message == 'no') {
          console.log("no");
          return
        } else if (data && data.message == 'yes') {
          this.attachSubscriptions(
            this.chatService.deleteChat(chat.id, this.company.id).subscribe(resp => {
              this.taskBarService.removeItems$.next({
                task_id: chat.task_id,
                task_operation_id: chat.task_operation_id,
                discussion_id: chat.id,
                company_id: chat.company_id
              })
              this.getTask(true)
            })
          )
        }
      });
    } else {
      const dialogRef = this.dialog.open(MobQuestionComponent, {
        backdropClass: ['mob_interface_backdrop'],
        panelClass: ['mob_interface_panel', 'animate__animated', 'animate__slideInUp'],
        disableClose: true,
        data: {
          dialog_title: "Delete chat",
          title: "Do you really want to delete the chat?",
          msg1: "All files, messages will be deleted if you proceed.",
          msg2: "This can`t be undone.",
          btn_yes: "Yes. Delete chat.",
          btn_no: "No. I want te keep this chat.",
        }
      });
  
      this.attachSubscriptions(
        dialogRef.afterClosed().subscribe(result => {
          
          if (!!result) {
            if (result.action == 'yes') {
              this.attachSubscriptions(
                this.chatService.deleteChat(chat.id, this.company.id).subscribe(resp => {
                  this.taskBarService.removeItems$.next({
                    task_id: chat.task_id,
                    task_operation_id: chat.task_operation_id,
                    discussion_id: chat.id,
                    company_id: chat.company_id
                  })
                  this.getTask(true)
                })
              )
            } else {
              this.mobOptions(chat)
            }
          }
        })
      )
    }
  }

  neededTaskData(task) {
    let arr = [];
    if ((this.company.permissions.includes('owner') || this.checkIsManager(task, this.company, this.user))) {
      arr.push(this.taskService.getTaskClients(task.id, this.company_id).pipe(
        tap(res => {
          task.clients = res
        }),
        catchError(err => {
          return err
        })
      ))
    }
    
    arr.push(this.chatService.getTasksChats(this.company.id, [task.id]).pipe(
      tap(res => {
        task['operations'].map(o_el => {
          o_el['chatsInfo'] = [];
          o_el['chatsInfo'].push(...res.filter(z => z.task_operation_id == o_el.id));
          o_el['unReadChats'] = res.filter(z => z.task_operation_id == o_el.id && z.is_read != '1').length;
          o_el['unClosedChats'] = res.filter(z => z.task_operation_id == o_el.id && z.status_id != '4').length;
        });
        task['chatsInfo'] = [];
        task['chatsInfo'].push(...res.filter(z => z.task_id == task.id));
        task['unReadChats'] = res.filter(z => z.is_read != '1').length;
        task['unClosedChats'] = res.filter(z => z.status_id != '4').length;
      })
    ))

    return forkJoin(arr)
  }

  addToBoardJob(job) {
    this.attachSubscriptions(
      this.taskService.getOneTaskExpand(this.company.id, job.task_id).pipe(
        map(x => x.body[0]),
        switchMap(val => this.neededTaskData(val).pipe(map(() => casesModel([val], [], 'update')),map(val => val.arr[0])))
      ).subscribe(resp => {
        console.log("addToBoard", resp)
        let work = resp.operations.find(x => x.id == job.id);
        work.employees = work.employees.filter(x => x.discussion_id == 0 || !x.discussion_id)
        work.mainEmployees = work.mainEmployees.filter(x => x.discussion_id == 0 || !x.discussion_id)
        work.addEmployee = work.addEmployee.filter(x => x.discussion_id == 0 || !x.discussion_id)
        work.addEmployee.forEach(x => {
          if (!x.employeeStatus && job.employeesStatuses.find(z => z.employee_id == x.employee_id)) {
            x.employeeStatus = job.employeesStatuses.find(z => z.employee_id == x.employee_id)
          }
        })
        const goToChat = this.dialog.open(AddJobToBoardComponent, {
          panelClass: 'add_to_board_modal',
          data: {
            company: this.company,
            user: this.user,
            imgRoute: this.imgRoute,
            host: this.host,
            activeLang: this.activeLang,
            employees: job.employees,
            task: resp,
            job: work,
            statuses: this.statuses,
            empl_status: this.company.permissions.includes('owner') || this.checkIsManager(resp, this.company, this.user),
            operationsValues: this.operationsValues,
          }
        });

        this.attachSubscriptions(
          goToChat.afterClosed().subscribe(result => {
            this.getTask(true)
          })
        )
      })
    )
  }

  addToBoard(chat) {
    this.attachSubscriptions(
      this.taskService.getOneTaskExpand(this.company.id, chat.task_id).pipe(
        map(x => x.body[0]),
        switchMap(val => this.neededTaskData(val).pipe(map(() => casesModel([val], [], 'update')),map(val => val.arr[0])))
      ).subscribe(resp => {
        console.log("addToBoard", resp)
        let work = resp.operations.find(x => x.id == chat.task_operation_id);
        work.employees = work.employees.filter(x => x.discussion_id == chat.id || x.discussion_id == 0 || !x.discussion_id)
        work.mainEmployees = work.mainEmployees.filter(x => x.discussion_id == chat.id || x.discussion_id == 0 || !x.discussion_id)
        work.addEmployee = work.addEmployee.filter(x => x.discussion_id == chat.id || x.discussion_id == 0 || !x.discussion_id)
        work.addEmployee.forEach(x => {
          if (!x.employeeStatus && chat.employeesStatuses.find(z => z.employee_id == x.employee_id)) {
            x.employeeStatus = chat.employeesStatuses.find(z => z.employee_id == x.employee_id)
          }
        })
        const goToChat = this.dialog.open(AddChatToBoardComponent, {
          panelClass: 'add_to_board_modal',
          data: {
            company: this.company,
            user: this.user,
            imgRoute: this.imgRoute,
            host: this.host,
            activeLang: this.activeLang,
            chat: chat,
            employees: chat.employees,
            task: resp,
            job: work,
            statuses: this.statuses,
            empl_status: this.company.permissions.includes('owner') || this.checkIsManager(resp, this.company, this.user),
            operationsValues: this.operationsValues,
          }
        });

        this.attachSubscriptions(
          goToChat.afterClosed().subscribe(result => {
            this.getTask(true)
          })
        )
      })
    )
  }

  moveChat(chat) {
    const dialogRef = this.dialog.open(MoveToOtherJobComponent, {
      disableClose: true,
      data: {
        user: this.user,
        company: this.company,
        task: this.task,
        work: chat.taskOperation,
        chat: chat,
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        this.refreshService.refreshChat$.next({company_id: this.company.id, chat_id: chat.id})
      })
    )
  }

  openChat(_chat) {
    this.attachSubscriptions(
      this.chatService.getChatsExpand(1, this.company_id, {id: _chat.id}).pipe(
        map(res => res.body[0])
      ).subscribe(chat => {
        const goToChat = this.dialog.open(ChatsComponent, {
          backdropClass: ['backdrop_under_header', "without_pad"],
          panelClass: !this.is_mobile ? ['full_screen_dialog', 'global_chats_dialog', 'show_header'] : ['full_screen_dialog', 'global_chats_dialog'],
          position: !this.is_mobile ? { top: '58px', left: '0', right: '0', bottom: '0' } : {},
          data: {
            company: this.company,
            user: this.user,
            imgRoute: this.imgRoute,
            host: this.host,
            dialogType: 'global_chat',
            activeLang: this.activeLang, 
            chat: chat
          }
        });

        this.attachSubscriptions(
          goToChat.afterClosed().subscribe(res => {
            this.getTask(true)
          })
        )
      })
    )
  }
  
  getOperationStatusName(id) {
    switch (+id) {
      case 1:
        return "Waiting";
        break;
      case 2:
        return "In process";
        break;
      case 3:
        return "To approve";
        break;
      case 4:
        return "Approved";
        break;
      case 5:
        return "To correct";
        break;
      case 6:
        return "Sources Needed";
        break;
      case 97:
        return "On hold";
        break;
      case 98:
        return "Ready";
        break;
      case 99:
        return "Canceled";
        break;
    }
  }

  checkIsMobile() {
    if (window.innerWidth <= 769) {
      this.is_mobile = true;
      this.channelsTab = 0;
    } else {
      this.is_mobile = false;
    }
    this.onResize();
  }
  
  onResize() {
    this.attachSubscriptions(
      fromEvent(window, "resize").pipe(
        map(() => window.innerWidth)
      ).subscribe((wWidth) => {
          if (wWidth <= 769) {
            this.is_mobile = true;
            this.channelsTab = 0;
          } else {
            this.is_mobile = false;
          }
        }
      )
    )
  }
  
  // getFiles() {
  //   forkJoin(this.task.operations.filter(z => (z.status_id == 3 && z.is_to_approve_files) || (z.status_id == 98 && z.is_approved_files)).map(x => this.fileService.getFilesForOpenTask(this.company_id, x.id, x.status_id == 3 ? '/to_approve' : '/ready_files').pipe(
  //     tap(val => {
  //       x.filesCount = val.headers.get('x-pagination-total-count')
  //       x.files = val.body
  //       console.log("job", x)
  //     })
  //   ))).subscribe(resp => {
  //     console.log(resp);
  //   })
  // }

  goToLink(val) {
    window.open(val, '_blank').focus();
  }

  addEmplWork(element) {
    const dialogRef = this.dialog.open(WorkEmplAddComponent, {
      disableClose: true,
      data: {
        company: this.company,
        task: element
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        
        if (!!result && result.event == "update") {
          this.getTask(true);
        }
      })
    )
  }

  taskManagers() {
    const dialogRef = this.dialog.open(TaskManagersComponent, {
      data: {
        company: this.company,
        activeLang: this.activeLang,
        user: this.user,
        task: this.task
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        this.getTask(true);
      })
    )
  }

  getTaskStatus() {
    this.attachSubscriptions(
      this.taskService.getTaskStatuses(this.company_id).subscribe(resp => {
        this.taskStatuses = resp;
      })
    )
  }

  checkWorkActions(e, task, work) {
    if (!this.is_mobile && e) {
      e.preventDefault();
      e.stopPropagation();
    }
    this.attachSubscriptions(
      this.taskService.getActions(null, 1, this.task.company_id, null, work.id).subscribe(resp => {
        
        if (resp.body.length) {
          this.dialog.open(NotificationsBar, {
            backdropClass: this.is_mobile ? ['mob_interface_backdrop'] : 'backdrop_under_header',
            panelClass: this.is_mobile ? ['mob_interface_panel', 'animate__animated', 'animate__slideInUp'] : ['open_task_dialog', 'show_header'],
            data: {
              notifications: resp.body,
              taskStatuses: this.taskStatuses,
              company: this.company,
              user: this.user,
              task: task,
              work: work,
              header: false,
              initCompanyId: this.company_id,
              empl_status: this.company.permissions.includes('owner') || this.checkIsManager(this.task, this.company, this.user),
              pagination: {
                'pageCount': resp.headers.get('x-pagination-page-count'),
                'perPage': resp.headers.get('x-pagination-per-page'),
                'totalCount': resp.headers.get('x-pagination-total-count'),
                'currentPage': resp.headers.get('x-pagination-current-page'),
              },
            }
          });
        }
      })
    )
  }

  editGroupDbl(element) {
    if (!(this.company?.permissions.includes('owner') || this.checkIsManager(this.task, this.company, this.user))) {
      return false;
    }

    const dialogRef = this.dialog.open(TaskGroupEditComponent, {
      disableClose: true,
      data: {
        company_id: this.company_id,
        company: this.company,
        task: element
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        
        if (!!result && result.event == "Add") {
          this.getTask(true);
        }
      })
    )
  }

  multiSetPrivacyStatus() {
    const dialogRef = this.dialog.open(SetPrivacyStatusComponent, {
      disableClose: true,
      data: {
        company: this.company,
        company_id: this.company_id,
        collection: this.profileCollection.selected
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result && result.event == 'update') {
          this.profileCollection.selected.forEach(x => {
            this.refreshService.refreshUrls$.next({url_id: x.id, company_id: this.company_id})
          })
          this.profileCollection.clear();
        }
      })
    )
  }

  multiSelectChannelThumb(job?) {
    let lenSelection = this.profileCollection.selected.filter(x => !!x.channel && x.channel.platform_id == 1).length;
    let count = 0;
    if (!lenSelection) {
      this.layoutService.showSnackBar({name: 'There are no YouTube channels selected through which you can set the thumbnail'}, '', SnackBarItem)
      return
    }
    let x:any = {
      company: this.company,
      task: this.profileCollection.selected.filter(x => !!x.channel && x.channel.platform_id == 1)[0].task,
      channel: this.profileCollection.selected.filter(x => !!x.channel && x.channel.platform_id == 1)[0],
      work: job,
      user: this.user,
      thumbFile: undefined,
      only: 'image',
      is_multi: false,
      no_matches: true
    }
    // if (is_photo) {
    //   x.selectedFiles = this.morePhotos;
    //   x.thumbFile = this.data.file;
    // }

    const dialogRef = this.dialog.open(SelectFileComponent, {
      data: x
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log(result);
        if (!!result && result.event == 'select' && result.data) {
          from(this.profileCollection.selected.filter(x => !!x.channel && x.channel.platform_id == 1)).pipe(
            concatMap((post:any) => {
              return this.taskService.setChannelThumbnail({
                task_channel_id: post.id,
                thumbnail_file_id: result.data.id
              }).pipe(
                catchError(error => {
                  return of(error)
                })
              )
            })
          ).subscribe({
            next: (next) => {
              console.log("next multiBackup", next);
              this.ls.requests$.next({
                value: Math.round((100 / lenSelection) * (count+1)),
                target: "Change Thumbnail",
              })
              count++;
            },
            complete: () => {
              console.log("complete multiBackup");
              this.profileCollection.clear();
              this.getTask(true)
            },
            error: (error) => {
              console.log("error multiBackup", error)
            }
          })
        }
      })
    )
  }

  selectChannelThumb(channel, job?) {
    let x:any = {
      company: this.company,
      task: channel.task,
      channel: channel,
      work: job,
      user: this.user,
      thumbFile: channel.file,
      only: 'image',
      is_multi: false,
      no_matches: true
    }
    // if (is_photo) {
    //   x.selectedFiles = this.morePhotos;
    //   x.thumbFile = this.data.file;
    // }

    const dialogRef = this.dialog.open(SelectFileComponent, {
      data: x
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log(result);
        if (!!result && result.event == 'select' && result.data) {
          this.taskService.setChannelThumbnail({
            task_channel_id: channel.id,
            thumbnail_file_id: result.data.id
          }).subscribe(resp => {
            this.refreshService.refreshUrls$.next({url_id: channel.id, company_id: this.company_id})
          }, error => {
            this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
          })
        }
      })
    )
  }
  
  isToday(val) {
    return moment(val*1000).clone().isSameOrAfter(moment().startOf('day')) && moment(val*1000).clone().isSameOrBefore(moment().endOf('day'))
  }

  editTaskNameComment(task, target:any = false) {
    if (!(this.company?.permissions.includes('owner') || this.checkIsManager(this.task, this.company, this.user))) {
      return false
    }

    const dialogRef = this.dialog.open(TaskCommentEditComponent, {
      backdropClass: 'backdrop_under_header',
      panelClass: !this.is_mobile ? ['open_task_dialog', 'show_header'] : 'open_task_dialog',
      disableClose: true,
      data: {
        task: task,
        company: this.company,
        target: target
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result && result.event == 'Edit') {
          task[target] = result.data[target];
          this.refreshService.refreshTask$.next({company_id: this.company_id, task_id: this.data.task_id})
          this.initRefreshBoard()
        }
      })
    )
  }

  editTaskStatus(task) {
    if (!(this.company?.permissions.includes('owner') || this.checkIsManager(this.task, this.company, this.user))) {
      return false
    }

    const dialogRef = this.dialog.open(TaskStatusEditComponent, {
      disableClose: true,
      data: {
        task: task,
        company_id: this.company_id
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result && result.event == 'update') {
          this.getTask(true);
        }
      })
    )
  }

  editTaskId(task) {
    if (!(this.company?.permissions.includes('owner') || this.checkIsManager(this.task, this.company, this.user))) {
      return false
    }

    const dialogRef = this.dialog.open(TaskIdEditComponent, {
      disableClose: true,
      data: task
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result && result.event == 'update') {
          task.custom_id = result.data.custom_id;
          this.refreshService.refreshTask$.next({company_id: this.company_id, task_id: this.data.task_id})
          this.initRefreshBoard()
        }
      })
    )
  }

  goToEditTask(element, relations:boolean = false) {
    if (!this.is_mobile) {
      const dialogRef = this.dialog.open(TaskEditComponent, {
        disableClose: true,
        data: {
          company_id: this.company_id,
          task: element,
          showRelations: relations
        }
      });
  
      this.attachSubscriptions(
        dialogRef.afterClosed().subscribe(result => {
          
          if (!!result && result.event == 'update') {
            this.getTask(true);
          }
        })
      )
    } else {
      const dialogRef = this.dialog.open(MobTaskEditComponent, {
        backdropClass: ['mob_interface_backdrop'],
        panelClass: ['mob_interface_panel', 'animate__animated', 'animate__slideInUp'],
        disableClose: true,
        data: {
          company_id: this.company_id,
          task: element,
        }
      });
  
      this.attachSubscriptions(
        dialogRef.afterClosed().subscribe(result => {
          if (!!result) {
            this.getTask(true);
            switch (result.event) {
              case 'private_comment':
                this.mobTaskEditTitle(element, 'private_comment', "Hidden notes");
                break;
              case 'comment':
                this.mobTaskEditTitle(element, 'comment', "Brief Description");
                break;
              case 'name':
                this.mobTaskEditTitle(element, 'name', "Title");
                break;
              case 'priority':
                this.mobEditTaskPriority(element);
                break;

              default:
                break;
            }
          }
        })
      )
    }
  }

  mobEditTaskPriority(task) {
    const dialogRef = this.dialog.open(MobPriorityComponent, {
      backdropClass: ['mob_interface_backdrop'],
      panelClass: ['mob_interface_panel', 'animate__animated', 'animate__slideInUp'],
      data: {
        company: this.company,
        task: task,
        user: this.user,
        target: 'task'
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        this.getTask(true);
      })
    )
  }

  mobTaskEditTitle(task, type_prop, type) {
    const dialogRef = this.dialog.open(MobTitleComponent, {
      backdropClass: ['mob_interface_backdrop'],
      panelClass: ['mob_interface_panel', 'animate__animated', 'animate__slideInUp'],
      data: {
        company: this.company,
        task: task,
        user: this.user,
        type: type,
        type_prop: type_prop,
        title: 'Edit task',
        target: 'task'
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        this.getTask(true);
      })
    )
  }

  goToClients(job?) {
    const dialogRef = this.dialog.open(TaskClientsComponent, {
      data: {
        company: this.company,
        company_id: this.company_id,
        task: this.task,
        user: this.user,
        // clients: this.task.clients,
        work: !!job ? job : ''
      }
    });
    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log(result)
      })
    )
  }
  
  closeTask() {
    if (this.is_mobile) {
      this.layoutService.overflowAuto();
      this.dialogRef.removePanelClass('animate__slideInUp')
      this.dialogRef.addPanelClass('animate__slideOutDown')
      setTimeout(()=>{this.dialogRef.close({event: "close", data: false})}, 300);
    } else {
      this.dialogRef.close({event: "close", data: false})
    }
  }

  openFiles(task, work:any = null, loc: any = null) {
    this.closeTask();
    this.fileService.url$.next(location.href);
    if (work != null) {
      if (loc != null) {
        this.router.navigate(['/files'], { queryParams: {company_id: this.company_id, task_id: task.id, work_id: work.id, initLocation: loc}});
      } else {
        this.router.navigate(['/files'], { queryParams: {company_id: this.company_id, task_id: task.id, work_id: work.id}});
      }
    } else {
      this.router.navigate(['/files'], { queryParams: {company_id: this.company_id, task_id: task.id, work_id: 0}});
    }
  }

  copyLink(type) {
    this.layoutService.showSnackBar(marker(type + " link"), marker("Copied"), SnackBarItem)
  }

  copyName(type) {
    this.layoutService.showSnackBar(marker(type), marker("Copied"), SnackBarItem)
  }

  copyScheme(item) {
    this.layoutService.showSnackBar({name: item.name + ' scheme: ' + this.getSchemeContent(item)}, marker("Copied"), SnackBarItem)
  }

  getSchemeContent(item) {
    if (item.settings && item.settings.devScheme) {
      let string = ``;

      item.settings.devScheme.forEach((el,i) => {
        if (el.name == 'Separator') {
          if (!el.not_use) {
            string += `${el.value}`
          }
        } else if (el.name == 'Card') {
          if (el.value == 'group') {
            if (this.task.group && this.task.group.name) {
              string += `${this.task.group.name}`
            } else {
              if (item.settings.devScheme[i+i]) {
                item.settings.devScheme[i+i].not_use = true;
              }
            }
          } else if (el.value == 'link') {
            if (this.task.acm) {
              string += `${this.origin}/task/${this.task.acm}`
            } else {
              if (item.settings.devScheme[i+i]) {
                item.settings.devScheme[i+i].not_use = true;
              }
            }
          } else if (el.value == 'firstConsistOfName') {
            if (this.task.consistOfTasks && this.task.consistOfTasks.length) {
              string += `${this.task.consistOfTasks[0].name}`
            } else {
              if (item.settings.devScheme[i+i]) {
                item.settings.devScheme[i+i].not_use = true;
              }
            }
          } else if (el.value == 'custom_id') {
            if (this.task.custom_id) {
              string += `${this.task.custom_id}`
            } else {
              if (item.settings.devScheme[i+i]) {
                item.settings.devScheme[i+i].not_use = true;
              }
            }
          } else {
            string += `${this.task[el.value]}`
          }
        }
      });

      return string
    }
  }

  copyMobLink(type, val) {
    this.clipboard.copy(val);
    // navigator.clipboard.writeText(content).then().catch(e => console.error(e));
    this.layoutService.showSnackBar(marker(type + " link"), marker("Copied"), SnackBarItem)
  }

  copied(e) {
    console.log(e);
  }

  migrateTargetFiles(task, job?) {
    const dialogRef = this.dialog.open(MigrateTargetFilesComponent, {
      panelClass: ['chats_dialog'],
      data: {
        company: this.company,
        target: {
          task_id: task.id,
          task_operation_id: job ? job.id : 0
        },
        user: this.user,
        host: this.host,
      }
    });
    
    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        this.refreshService.refreshTask$.next({company_id: this.company_id, task_id: task.id})
      })
    )
  }

  createChat(task, work) {
    const dialogRef = this.dialog.open(CreateOperationChatComponent, {
      disableClose: true,
      data: {
        is_create_chat_component: true,
        company: this.company,
        task: task,
        work: work,
        user: this.user,
        empl_status: this.company.permissions.includes('owner') || this.checkIsManager(this.task, this.company, this.user),
        operationsValues: this.operationsValues
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log(result)
        if (!!result && result.event == 'create') {
          this.openJob(task.id, work);
          // const dialogRefInside = this.dialog.open(OperationChatsComponent, {
          //   panelClass: ['chats_dialog'],
          //   disableClose: true,
          //   data: {
          //     company: this.company,
          //     task: task,
          //     work: work,
          //     user: this.user,
          //     empl_status: this.company.permissions.includes('owner') || this.checkIsManager(this.task, this.company, this.user),
          //     operationsValues: this.operationsValues,
          //     checkIsManager: this.checkIsManager
          //   }
          // });
          // work.unClosedChats = work.unClosedChats + 1;
          // task.unClosedChats = task.unClosedChats + 1;
          // if (work.status_id == 3 && (this.company.permissions.includes('owner') || this.checkIsManager(this.task, this.company, this.user))) {
          //   this.attachSubscriptions(
          //     this.taskService.editWork(work.id, {status_id: 5}, this.company_id).subscribe(resp => {
          //       this.getTask(true);
          //     })
          //   )
          // } else {
          //   this.getTask(true);
          // }
        }
      })
    )
  }

  // updateChats(task, job) {
  //   this.attachSubscriptions(
  //     this.chatService.getWorkChats(job.id).subscribe(resp => {
  //       console.log(resp)
  //     })
  //   )
  // }

  moreThenOneStatus(array) {
    let arr = []
    array.forEach(element => {
      if (element.status_id && !arr.includes(element.status_id)) {
        arr.push(element.status_id)
      }
    });

    return arr.length > 1 ? true : false
  }

  openMobAssignments(task, work) {
    const dialogRef = this.dialog.open(MobAssignmentsComponent, {
      backdropClass: ['mob_interface_backdrop'],
      panelClass: ['mob_interface_panel', 'animate__animated', 'animate__slideInUp'],
      disableClose: true,
      data: {
        user: this.user,
        company: this.company,
        task: task,
        work: work,
        empl_status: this.company.permissions.includes('owner') || this.checkIsManager(this.task, this.company, this.user),
        host: this.host,
        statuses: this.statuses,
        operationsValues: this.operationsValues,
        activeLang: this.activeLang
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result) {
          this.getTask(true);
        }
      })
    )
  }

  editWork(element, work) {
    if (!this.is_mobile) {
      const dialogRef = this.dialog.open(WorkEditComponent, {
        disableClose: true,
        data: {
          user: this.user,
          company: this.company,
          task: element,
          work: work,
          operationsValues: this.operationsValues
        }
      });
  
      this.attachSubscriptions(
  
        dialogRef.afterClosed().subscribe(result => {
          
          if (!!result && result.event == 'update') {
            this.getTask(true);
          }
        })
      )
    } else {
      const dialogRef = this.dialog.open(JobEditComponent, {
        backdropClass: ['mob_interface_backdrop'],
        panelClass: ['mob_interface_panel', 'animate__animated', 'animate__slideInUp'],
        disableClose: true,
        data: {
          user: this.user,
          company: this.company,
          task: element,
          work: work,
          operationsValues: this.operationsValues,
          activeLang: this.activeLang
        }
      });
  
      this.attachSubscriptions(
  
        dialogRef.afterClosed().subscribe(result => {
          
          if (!!result) {

            switch (result.event) {
              case 'update':
                this.getTask(true);
                break;
              case 'status':
                this.changeMobJobStatus(work);
                break;
              case 'private_comment':
                this.mobJobEditTitle(work, 'private_comment', "Hidden notes");
                break;
              case 'comment':
                this.mobJobEditTitle(work, 'comment', "Brief Description");
                break;
              case 'name':
                this.mobJobEditTitle(work, 'name', "Title");
                break;
              case 'type':
                this.mobJobEditType(work);
                break;
              case 'completed_at':
                this.mobJobEditCompletedAt(work);
                break;
              case 'priority':
                this.mobEditJobPriority(work);
                break;
              default:
                break;
            }
          }
        })
      )
    }
  }

  mobEditJobPriority(work) {
    const dialogRef = this.dialog.open(MobPriorityComponent, {
      backdropClass: ['mob_interface_backdrop'],
      panelClass: ['mob_interface_panel', 'animate__animated', 'animate__slideInUp'],
      data: {
        company: this.company,
        work: work,
        user: this.user,
        target: 'work'
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          if (result.event == 'back') {
            this.editWork(this.task, work)
          } else if (result.event == 'update') {
            this.getTask(true);
          }
        } else {
          this.getTask(true);
        }
      })
    )
  }
  

  mobJobEditCompletedAt(work) {
    const dialogRef = this.dialog.open(JobEditCompletedAtComponent, {
      backdropClass: ['mob_interface_backdrop'],
      panelClass: ['mob_interface_panel', 'animate__animated', 'animate__slideInUp'],
      data: {
        company: this.company,
        task: this.task,
        user: this.user,
        work: work,
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          if (result.event == 'back') {
            this.editWork(this.task, work)
          } else if (result.event == 'update') {
            this.getTask(true);
          }
        } else {
          this.getTask(true);
        }
      })
    )
  }
  mobJobEditType(work) {
    const dialogRef = this.dialog.open(JobEditTypeComponent, {
      backdropClass: ['mob_interface_backdrop'],
      panelClass: ['mob_interface_panel', 'animate__animated', 'animate__slideInUp'],
      data: {
        company: this.company,
        task: this.task,
        user: this.user,
        work: work,
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          if (result.event == 'back') {
            this.editWork(this.task, work)
          } else if (result.event == 'update') {
            this.getTask(true);
          }
        } else {
          this.getTask(true);
        }
      })
    )
  }

  anCardScen(task) {
    const dialogRef = this.dialog.open(CardScenarioComponent, {
      data: {
        company_id: this.company_id,
        company: this.company,
        task: task,
        user: this.user,
        host: this.host,
        imgRoute: this.imgRoute,
        activeLang: this.activeLang,
      }
    });
    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        // this.refreshService.refreshTask$.next({company_id: this.company_id, task_id: task.id})
      })
    )
  }

  openAutomation(work) {
    const dialogRef = this.dialog.open(JobAutomationComponent, {
      data: {
        company: this.company,
        work: work,
        user: this.user,
        host: this.host,
        imgRoute: this.imgRoute,
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result && result.event == 'update') {
          this.getTask(true);
          this.initRefreshBoard()
        }
      })
    )
  }

  multiDeleteJobs() {
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: {},
        target: this.jobCollection.selected.length + ' jobs'
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        console.log("jobCollection", this.jobCollection.selected)

        let multiData = [];

        this.jobCollection.selected.forEach(item => {
          multiData.push({
            "path": `/api/task-operation/${item.id}/`,
            "query": {company_id: this.company.id},
            "method": "DELETE",
            "body": {}
          })
        });
    
        if (multiData.length) {
          this.attachSubscriptions(
            this.taskService.multiRequest(multiData).subscribe(resp => {
              this.getTask(true)
              this.jobCollection.clear()
            })
          )
        }
      }
    });
  }

  setProfileUpdate(post) {
    this.attachSubscriptions(
      this.taskService.editProfile(post.id, {is_get_data_queue: 1}, this.company_id).subscribe(resp => {
        post.is_get_data_queue = resp.is_get_data_queue;
      })
    )
  }

  setPrivacyStatus(element, profile) {
    const dialogRef = this.dialog.open(SetPrivacyStatusComponent, {
      disableClose: true,
      data: {
        company: this.company,
        company_id: this.company_id,
        task: element,
        profile: profile
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {

      })
    )
  }

  deleteWork(task, work) {
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: {},
        target: marker("job")
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        this.attachSubscriptions(
          this.taskService.deleteWork(work.id).subscribe(resp => {
            this.getTask(true)
            // this.refreshService.refreshTask$.next({company_id: this.company_id, task_id: this.data.task_id})
            // this.initRefreshBoard()
          })
        )
      }
    });

  }

  editEmloyeeWork(element, work) {
    const dialogRef = this.dialog.open(WorkEmplEditComponent, {
      disableClose: true,
      data: {
        company: this.company,
        task: element,
        work: work,
        user: this.user
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        
        if (!!result && result.event == 'update') {
          this.getTask(true);
        }
      })
    )
  }

  addAdditionalEmplToWork(task, work) {
    const dialogRef = this.dialog.open(AddAdditionalEmployeesComponent, {
      disableClose: true,
      data: {
        user: this.user,
        company: this.company,
        task: task,
        work: work,
        discussion: false
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        this.getTask(true);
      })
    )
  }
  
  addClient() {
    const dialogRef = this.dialog.open(TaskAddClientComponent, {
      data: {
        company: this.company,
        company_id: this.company.id,
        user: this.user,
        selectedJobs: this.jobCollection.selected
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log(result)
        if (result.event == 'update') {
          // this.getClients();
          this.getTask()
        }
      })
    )
  }

  addPartnerClient() {
    const dialogRef = this.dialog.open(TaskAddClientComponent, {
      data: {
        company: this.company,
        company_id: this.company.id,
        user: this.user,
        selectedJobs: this.jobCollection.selected,
        is_partner: true,
        imgRoute: this.imgRoute,
        host: this.host
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log(result)
        if (result.event == 'update') {
          this.getTask()
        }
      })
    )
  }
  
  additionalEmployees(task, work) {
    const dialogRef = this.dialog.open(AdditionalEmployeesComponent, {
      disableClose: true,
      data: {
        user: this.user,
        company: this.company,
        task: task,
        work: work,
        discussion: false
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        this.getTask(true);
      })
    )
  }

  openOldChats(task, work) {
    const dialogRef = this.dialog.open(OperationChatsComponent, {
      panelClass: ['chats_dialog'],
      data: {
        company: this.company,
        task: task,
        work: work,
        user: this.user,
        empl_status: this.company.permissions.includes('owner') || this.checkIsManager(this.task, this.company, this.user),
        host: this.host,
        operationsValues: this.operationsValues,
        checkIsManager: this.checkIsManager
      }
    });
    
    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        this.getTask(true);
      })
    )
  }

  getParameters() {
    this.attachSubscriptions(
      this.parametersService.getApiParameters(this.company_id).pipe(
        switchMap(res => {
          return this.parametersService.getAllValues('1', this.company_id, null, '1').pipe(
            switchMap(el => {
              let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200);
              let arr = [];
              for (let index = 1; index <= pages; index++) {
                arr.push(index)
              }
    
              return forkJoin(arr.map(x => this.parametersService.getAllValues(x, this.company_id, null).pipe(map(u => u.body)))).pipe(
                last(),
                tap(values => {
                  this.allValues = [].concat(...values)
                  console.log("getAllApiParameterValues", this.allValues)
                }),
                map(() => res)
              )
            }),
            map(() => res)
          )
        }),
        tap(res => {
          // res.unshift({
          //   company_id: this.company_id,
          //   created_at: 0,
          //   id: 0,
          //   is_new_value: 0,
          //   name: "All",
          //   original_id: 0,
          // })
          
          this.parameters = res
          this.parameters$.next(this.parameters.slice())
        }),
      ).subscribe(resp => {
        if (!!this.task && this.task.data && this.task.data[0] && this.task.data[0].type == 'GroupBy' && this.task.data[0].parameter_id) {
          this.respawnTabs(this.task);
        }
        console.log("this.parameters", this.parameters)
      })
    )
  }

  openTargetValues(info, element) {
    let initData:any = {
      company: this.company
    }

    initData[!!info[2] ? 'chat' : (!!info[1] ? 'job' : 'task')] = element

    initData.target = {
      task_id: info[0],
      task_operation_id: info[1],
      discussion_id: info[2]
    }
    if (element.company_id != this.company.id) {
      initData.target.partner_company_id = element.company_id
    }
    const dialogRef = this.dialog.open(TargetParametersComponent, {
      backdropClass: ['parameters_modal_backdrop'],
      panelClass: ['without_paddings_modal', 'parameters_modal'],
      data: initData
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        this.getTask(true);
      })
    )
  }

  getBookmarks() {
    this.attachSubscriptions(
      this.companyService.getBookmarks(this.company_id, {employee_id: this.company.employees[0].id, section: "Card", type: "Clipboard"}).subscribe(res => {
        this.bookmarks = res;
        console.log("open card bookmarks", this.bookmarks);
      })
    )
  }

  toggleSchemeEditing(e) {
    e.preventDefault();
    e.stopPropagation();
    this.is_scheme_editing = !this.is_scheme_editing;
  }

  editScheme(e, trigger:MatMenuTrigger, scheme, task) {
    e.preventDefault();
    e.stopPropagation();

    console.log("trigger", trigger)
    
    trigger.closeMenu();

    console.log("createNewOpenCardClipboard", task)

    const dialogRef = this.dialog.open(CardClipboardComponent, {
      backdropClass: ['backdrop_under_header'],
      panelClass: !this.is_mobile ? ['show_header'] : '',
      hasBackdrop: true,
      data: {
        company: this.company,
        company_id: this.company_id,
        task: task,
        scheme: scheme
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        
        if (!!result && result.event == 'edit') {
          this.getBookmarks();
        }
      })
    )
  }

  deleteScheme(e, trigger:MatMenuTrigger, scheme) {
    e.preventDefault();
    e.stopPropagation();

    trigger.closeMenu();
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: scheme,
        target: marker("Card Clipboard scheme")
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        this.attachSubscriptions(
          this.companyService.deleteBookmark(scheme.id).subscribe(res => {
            this.bookmarks.splice(this.bookmarks.findIndex(p => p.id == scheme.id), 1);
            console.log("deleteScheme", this.bookmarks);
          }, error => {
            this.layoutService.showSnackBar({name: ''}, error, SnackBarItem);
          })
        )
      }
    });
  }

  createNewOpenCardClipboard(e, task) {
    e.preventDefault();

    console.log("createNewOpenCardClipboard", task)

    const dialogRef = this.dialog.open(CardClipboardComponent, {
      backdropClass: ['backdrop_under_header'],
      panelClass: !this.is_mobile ? ['show_header'] : '',
      hasBackdrop: true,
      data: {
        company: this.company,
        company_id: this.company_id,
        task: task
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        
        if (!!result && result.event == 'save') {
          this.getBookmarks();
        }
      })
    )
  }

  toggleProfile(profile) {
    this.profileCollection.toggle(profile);
  }

  openTaskChats(task) {
    const dialogRef = this.dialog.open(TaskChatsComponent, {
      panelClass: ['chats_dialog'],
      data: {
        company: this.company,
        task: task,
        user: this.user,
        empl_status: this.company.permissions.includes('owner') || this.checkIsManager(this.task, this.company, this.user),
        host: this.host,
        operationsValues: this.operationsValues,
        checkIsManager: this.checkIsManager,
        isTaskChats: true
      }
    });
    
    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        this.getTask(true);
      })
    )
  }

  openChecklist(task, work) {
    console.log(task, work);

    this.attachSubscriptions(
      this.fileService.getReminderFilesForOpenTask(this.company_id, work.id, '6').pipe(
        tap(val => work.files = val.body),
        switchMap(val => {
          return this.taskService.getOperationReminder(this.company_id, {task_operation_id: work.id})
        })
      ).subscribe(resp => {
        const dialogRef = this.dialog.open(CheckListComponent, {
          data: {
            company: this.company,
            task: task,
            work: work,
            user: this.user,
            checklist: resp.body,
            operationsValues: this.operationsValues
          }
        });

        this.attachSubscriptions(
          dialogRef.afterClosed().subscribe(result => {
            this.getTask(true);
          })
        )
      })
    )
  }

  download(file) {
    // window.open(this.host + file.original + '?company_id=' + this.company.id, '_blank');
    // new method
    var a = document.createElement('a');
    a.setAttribute('href', this.host + file.original + '?company_id=' + this.company.id + `&filename=${file.filename}`);
    a.setAttribute('download','download');
    // a.target = "_blank"
    a.click();
    // a.remove();
  }
  
  refreshViewCounts(profile) {
    profile.isRefreshed = true;

    if (profile && profile.channel && profile.channel.platform_id == 1) {
      this.attachSubscriptions(
        this.taskService.getYouTubeViews(profile.id, {}).pipe(
          switchMap(el => {
            return this.taskService.editProfile(profile.id, {content_views_count: !!el.content_views_count ? el.content_views_count : '0', is_bravourl_content_views_count_update: !!profile.is_bravourl_content_views_count_update ? '1' : '0', is_content_views_count_update: !!profile.is_content_views_count_update ? '1' : '0'}, this.company_id).pipe(
              tap(result => {
                profile.content_views_count = result.content_views_count.toString();
                profile.is_content_views_count_update = result.is_content_views_count_update;
                profile.is_bravourl_content_views_count_update = result.is_bravourl_content_views_count_update;
                profile.views_updated_at = result.views_updated_at;
                profile.views_update_at = result.views_update_at;
              })
            )
          }),
          retry(3)
        ).subscribe((resp:any) => {
          console.log("refreshViewCounts", resp)
          profile.isRefreshed = false;
        },
        error => {
          profile.isRefreshed = false;
          this.layoutService.showSnackBar({name: ''}, marker(error), SnackBarItem)
        })
      )
    } else if (profile && profile.channel && profile.channel.platform_id == 3) {
      this.attachSubscriptions(
        this.taskService.getTikTokViews(profile.id, {}).pipe(
          switchMap(el => {
            return this.taskService.editProfile(profile.id, {content_views_count: !!el.content_views_count ? el.content_views_count : '0', is_bravourl_content_views_count_update: !!profile.is_bravourl_content_views_count_update ? '1' : '0', is_content_views_count_update: !!profile.is_content_views_count_update ? '1' : '0'}, this.company_id).pipe(
              tap(result => {
                profile.content_views_count = result.content_views_count.toString();
                profile.is_content_views_count_update = result.is_content_views_count_update;
                profile.is_bravourl_content_views_count_update = result.is_bravourl_content_views_count_update;
                profile.views_updated_at = result.views_updated_at;
                profile.views_update_at = result.views_update_at;
              })
            )
          }),
          retry(3)
        ).subscribe((resp:any) => {
          console.log("refreshViewCounts", resp)
          profile.isRefreshed = false;
        },
        error => {
          profile.isRefreshed = false;
          this.layoutService.showSnackBar({name: ''}, marker(error), SnackBarItem)
        })
      )
    } else if (profile && profile.channel && profile.channel.platform_id == 4) {
      this.attachSubscriptions(
        this.taskService.getFacebookViews(profile.id, {}).pipe(
          switchMap(el => {
            return this.taskService.editProfile(profile.id, {content_views_count: !!el.content_views_count ? el.content_views_count : '0', is_bravourl_content_views_count_update: !!profile.is_bravourl_content_views_count_update ? '1' : '0', is_content_views_count_update: !!profile.is_content_views_count_update ? '1' : '0'}, this.company_id).pipe(
              tap(result => {
                profile.content_views_count = result.content_views_count.toString();
                profile.is_content_views_count_update = result.is_content_views_count_update;
                profile.is_bravourl_content_views_count_update = result.is_bravourl_content_views_count_update;
                profile.views_updated_at = result.views_updated_at;
                profile.views_update_at = result.views_update_at;
              })
            )
          }),
          retry(3)
        ).subscribe((resp:any) => {
          console.log("refreshViewCounts", resp)
          profile.isRefreshed = false;
        },
        error => {
          profile.isRefreshed = false;
          this.layoutService.showSnackBar({name: ''}, marker(error), SnackBarItem)
        })
      )
    } else {
      this.attachSubscriptions(
        this.taskService.getLinkData(profile.content_url, this.company_id, (profile.company_id != this.company_id ? profile.company_id : null), profile.channel_id).pipe(
          switchMap(el => {
            return this.taskService.editProfile(profile.id, {content_views_count: !!el.views_count ? el.views_count : '0', is_bravourl_content_views_count_update: !!profile.is_bravourl_content_views_count_update ? '1' : '0', is_content_views_count_update: !!profile.is_content_views_count_update ? '1' : '0'}, this.company_id).pipe(
              tap(result => {
                profile.content_views_count = result.content_views_count.toString();
                profile.is_content_views_count_update = result.is_content_views_count_update;
                profile.is_bravourl_content_views_count_update = result.is_bravourl_content_views_count_update;
                profile.views_updated_at = result.views_updated_at;
                profile.views_update_at = result.views_update_at;
              })
            )
          })
        ).subscribe((resp:any) => {
          console.log("refreshViewCounts", resp)
          profile.isRefreshed = false;
        },
        error => {
          profile.isRefreshed = false;
        })
      )
    }
  }

  openProfileLink(profile) {
    window.open(profile.content_url, '_blank')
  }

  previewVideo(file, job) {
    if (!this.is_mobile) {
      const dialogRef = this.dialog.open(VideoViewerComponent, {
        panelClass: 'video_viewer',
        autoFocus: false,
        data: {
          file: file,
          task: this.task,
          work: job,
          files: job.files,
          operationsValues: this.operationsValues,
          company: this.company,
          activeLang: this.activeLang,
          user: this.user,
          isTaskCard: true,
          initCompanyId: this.company_id
        }
      });

      this.attachSubscriptions(
        dialogRef.afterClosed().subscribe(result => {
          if (result && result.event == 'update') {
            this.getTask(true);
          }
        })
      )
    } else {
      const dialogRef = this.dialog.open(MobFmViewComponent, {
        backdropClass: ['mob_video_viewer_backdrop'],
        panelClass: 'mob_video_viewer',
        autoFocus: false,
        data: {
          file: file,
          task: this.task,
          work: job,
          files: job.files,
          operationsValues: this.operationsValues,
          company: this.company,
          activeLang: this.activeLang,
          user: this.user,
          isTaskCard: true,
          initCompanyId: this.company_id
        }
      });

      this.attachSubscriptions(
        dialogRef.afterClosed().subscribe(result => {
          if (result && result.event == 'update') {
            this.getTask(true);
          }
        })
      )
    }
  }

  copyInvite(emp) {
    this.layoutService.showSnackBar(emp.employee.name, marker("Copied"), SnackBarItem)
  }

  generateInvite(id, vuser_id, taskID) {
    let form = {
      company_id: this.company_id,
      reset_invite: 1,
      employee: {
        user_id: vuser_id
      }
    }

    this.attachSubscriptions(
      this.taskService.editEmployee(id, form).subscribe(resp => {
        this.task.operations.find(el => el.employee_id == id).employee.invite = resp.invite;
      })
    )
  }

  // removeInvite(id, vuser_id, taskID) {
  //   let form = {
  //     company_id: this.company_id,
  //     revoke_invite: 1,
  //     employee: {
  //       user_id: vuser_id
  //     }
  //   }

  //   this.attachSubscriptions(
  //     this.taskService.editEmployee(id, form).subscribe(resp => {
  //       this.task.operations.find(el => el.employee_id == id).employee.invite = resp.invite;
  //       this.task.operations.find(el => el.employee_id == id).employee.user_id = 0;
  //       this.task.operations.find(el => el.employee_id == id).employee.user = null;
  //     })
  //   )
  // }

  getOperationsStatus() {
    this.attachSubscriptions(
      this.taskService.getOperationsStatus().subscribe(resp => {
        console.log("operationStatuses", resp)
        this.statuses = resp.slice();
      })
    )
  }

  openMobScenarios(job) {
    job.scenarioOpen = !job.scenarioOpen;
  }

  checkActions(e, task) {
    if (!this.is_mobile) {
      e.preventDefault();
      e.stopPropagation();
    }
    this.attachSubscriptions(
      this.taskService.getActions(task.id, 1, this.task.company_id).subscribe(resp => {
        
        if (resp.body.length) {
          this.dialog.open(NotificationsBar, {
            backdropClass: this.is_mobile ? ['mob_interface_backdrop'] : 'backdrop_under_header',
            panelClass: this.is_mobile ? ['mob_interface_panel', 'animate__animated', 'animate__slideInUp'] : ['open_task_dialog', 'show_header'],
            data: {
              notifications: resp.body,
              taskStatuses: this.taskStatuses,
              company: this.company,
              user: this.user,
              task: task,
              header: false,
              initCompanyId: this.company_id,
              empl_status: this.company.permissions.includes('owner') || this.checkIsManager(this.task, this.company, this.user),
              pagination: {
                'pageCount': resp.headers.get('x-pagination-page-count'),
                'perPage': resp.headers.get('x-pagination-per-page'),
                'totalCount': resp.headers.get('x-pagination-total-count'),
                'currentPage': resp.headers.get('x-pagination-current-page'),
              },
            }
          });
        }
      })
    )
  }

  

  
  addWork(element) {
    if (!this.is_mobile) {
      const dialogRef = this.dialog.open(WorkAddComponent, {
        disableClose: true,
        data: {
          user: this.user,
          company: this.company,
          company_id: this.company_id,
          task: element,
          tabs: this.tabs,
          param_id: this.jobsTabForm.value.parameter_id
        }
      });
      this.attachSubscriptions(
        dialogRef.afterClosed().subscribe(result => {
          
          if (!!result && result.event == "update") {
            this.getTask(true);
          }
        })
      )
    } else {
      const dialogRef = this.dialog.open(MobAddJobComponent, {
        backdropClass: ['mob_interface_backdrop'],
        panelClass: ['mob_interface_panel', 'animate__animated', 'animate__slideInUp'],
        disableClose: true,
        data: {
          user: this.user,
          company: this.company,
          company_id: this.company_id,
          task: element,
          tabs: this.tabs,
          param_id: this.jobsTabForm.value.parameter_id
        }
      });
      
      this.attachSubscriptions(
        dialogRef.afterClosed().subscribe(result => {
          
          if (!!result && result.event == "update") {
            this.getTask(true);
          }
        })
      )
    }
  }

  scrollToProfiles() {
    document.getElementById(`open_profiles`).scrollIntoView({
      behavior: "smooth",
      block: "start"
    });
  }

  // manager

  showError() {
    this.layoutService.showSnackBar({name: ''}, marker("You do not have permission to do this"), SnackBarItem)
  }

  getGroupsCompany() {
    this.attachSubscriptions(
      this.companyService.getInfiniteGroupsCompany(this.company_id, '1', '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.companyService.getInfiniteGroupsCompany(this.company_id, x).pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        this.groups = [].concat(...res);
      })
    )
  }

  initRefreshBoard() {
    this.refreshService.refreshBoard$.next('refresh')
  }

  applyJobStats(job, initialStatus:any = false) {
    this.attachSubscriptions(
      this.taskService.editWork(job.id, {status_id: initialStatus ? initialStatus.id : job.newStatusFor.id}, this.company_id).subscribe(resp => {
        this.getTask(true);
      })
    )
  }

  applyStats(job, initialStatus:any = false) {
    console.log("job.empl_status", job)
    if (job.empl_status.filter(x => x.selected).length == 0) {
      return
    }

    console.log(job.empl_status.filter(x => x.selected))
    if ([3,4,98].includes(job.status_id) && [1,2,5,6].includes(initialStatus ? initialStatus.id : job.newStatusFor.id)) {
      const dialogRef = this.dialog.open(StatusReasonComponent, {
        panelClass: 'open_task_dialog',
        data: {
          company: this.company,
          user: this.user,
          task: this.task,
          activeLang: this.activeLang,
          job: job,
          status: initialStatus ? initialStatus : job.newStatusFor,
          statuses: this.statuses,
          groups: this.groups,
          imgRoute: this.imgRoute,
          is_mobile: this.is_mobile,
          host: this.host,
          empl_status: this.company.permissions.includes('owner') || this.checkIsManager(this.task, this.company, this.user),
          operationsValues: this.operationsValues,
        }
      });
  
      this.attachSubscriptions(
        dialogRef.afterClosed().subscribe(result => {
          if (result.change) {
            forkJoin(job.empl_status.filter(x => x.selected).map(empl => {
              if (!!empl.is_partner) {
                if (!!empl.partnerCompanyStatus?.id) {
                  return this.membersService.editPartnerStatus(empl.partnerCompanyStatus.id, {status_id: initialStatus ? initialStatus.id : job.newStatusFor.id}, this.company_id).pipe(
                    catchError(error => {
                      this.showStatusError(error, empl?.partnerCompany?.name)
                      return of(false)
                    })
                  )
                } else {
                  return this.membersService.addPartnerStatus({
                    status_id: initialStatus ? initialStatus.id : job.newStatusFor.id,
                    company_id: this.company_id,
                    task_id: job.task_id,
                    task_operation_id: job.id,
                    partner_company_id: empl.partner_company_id,
                  }, this.company_id).pipe(
                    catchError(error => {
                      this.showStatusError(error, empl?.partnerCompany?.name)
                      return of(false)
                    })
                  )
                }
              } else if (!!empl.is_partner_employee) {
                if (!!empl.employeeStatus?.id) {
                  return this.membersService.editPartnerEmployeeStatus(empl.employeeStatus.id, {status_id: initialStatus ? initialStatus.id : job.newStatusFor.id}, this.company_id).pipe(
                    catchError(error => {
                      this.showStatusError(error, empl?.employee?.display_name)
                      return of(false)
                    })
                  )
                } else {
                  return this.membersService.addPartnerEmployeeStatus({
                    status_id: initialStatus ? initialStatus.id : job.newStatusFor.id,
                    company_id: this.company_id,
                    task_id: job.task_id,
                    task_operation_id: job.id,
                    partner_company_id: empl.partner_company_id,
                    partner_employee_id: empl.partner_employee_id
                  }, this.company_id).pipe(
                    catchError(error => {
                      this.showStatusError(error, empl?.employee?.display_name)
                      return of(false)
                    })
                  )
                }
              } else {
                if (!!empl.employeeStatus?.id) {
                  return this.membersService.editTaskEmployeeStatus(empl.employeeStatus.id, {status_id: initialStatus ? initialStatus.id : job.newStatusFor.id}, this.company_id).pipe(
                    catchError(error => {
                      this.showStatusError(error, empl?.employee?.display_name)
                      return of(false)
                    })
                  )
                } else {
                  return this.membersService.addTaskEmployeeStatus({
                    status_id: initialStatus ? initialStatus.id : job.newStatusFor.id,
                    company_id: this.company_id,
                    task_id: job.task_id,
                    task_operation_id: job.id,
                    employee_id: empl.employee_id,
                  }, this.company_id).pipe(
                    catchError(error => {
                      this.showStatusError(error, empl?.employee?.display_name)
                      return of(false)
                    })
                  )
                }
              }
            })).subscribe(resp => {
              this.getTask(true);
            })
          } else {
            this.getTask(true);
          }
        })
      )
    } else if ((initialStatus ? initialStatus.id : job.newStatusFor.id) == 3) {
      const dialogRef = this.dialog.open(ToApproveStepperComponent, {
        panelClass: 'to_appove_dialog',
        data: {
          company: this.company,
          user: this.user,
          task: this.task,
          activeLang: this.activeLang,
          job: job,
          status: initialStatus ? initialStatus : job.newStatusFor,
          statuses: this.statuses,
          imgRoute: this.imgRoute,
          is_mobile: this.is_mobile,
          host: this.host,
          empl_status: this.company.permissions.includes('owner') || this.checkIsManager(this.task, this.company, this.user),
          operationsValues: this.operationsValues,
          getTask: () => {this.getTask(true)}
        }
      });
    } else {
      forkJoin(job.empl_status.filter(x => x.selected).map(empl => {
        if (!!empl.is_partner) {
          if (!!empl.partnerCompanyStatus?.id) {
            return this.membersService.editPartnerStatus(empl.partnerCompanyStatus.id, {status_id: initialStatus ? initialStatus.id : job.newStatusFor.id}, this.company_id).pipe(
              catchError(error => {
                this.showStatusError(error, empl?.partnerCompany?.name)
                return of(false)
              })
            )
          } else {
            return this.membersService.addPartnerStatus({
              status_id: initialStatus ? initialStatus.id : job.newStatusFor.id,
              company_id: this.company_id,
              task_id: job.task_id,
              task_operation_id: job.id,
              partner_company_id: empl.partner_company_id,
            }, this.company_id).pipe(
              catchError(error => {
                this.showStatusError(error, empl?.partnerCompany?.name)
                return of(false)
              })
            )
          }
        } else if (!!empl.is_partner_employee) {
          if (!!empl.employeeStatus?.id) {
            return this.membersService.editPartnerEmployeeStatus(empl.employeeStatus.id, {status_id: initialStatus ? initialStatus.id : job.newStatusFor.id}, this.company_id).pipe(
              catchError(error => {
                this.showStatusError(error, empl?.employee?.display_name)
                return of(false)
              })
            )
          } else {
            return this.membersService.addPartnerEmployeeStatus({
              status_id: initialStatus ? initialStatus.id : job.newStatusFor.id,
              company_id: this.company_id,
              task_id: job.task_id,
              task_operation_id: job.id,
              partner_company_id: empl.partner_company_id,
              partner_employee_id: empl.partner_employee_id
            }, this.company_id).pipe(
              catchError(error => {
                this.showStatusError(error, empl?.employee?.display_name)
                return of(false)
              })
            )
          }
        } else {
          if (!!empl.employeeStatus?.id) {
            return this.membersService.editTaskEmployeeStatus(empl.employeeStatus.id, {status_id: initialStatus ? initialStatus.id : job.newStatusFor.id}, this.company_id).pipe(
              catchError(error => {
                this.showStatusError(error, empl?.employee?.display_name)
                return of(false)
              })
            )
          } else {
            return this.membersService.addTaskEmployeeStatus({
              status_id: initialStatus ? initialStatus.id : job.newStatusFor.id,
              company_id: this.company_id,
              task_id: job.task_id,
              task_operation_id: job.id,
              employee_id: empl.employee_id,
            }, this.company_id).pipe(
              catchError(error => {
                this.showStatusError(error, empl?.employee?.display_name)
                return of(false)
              })
            )
          }
        }
      })).subscribe(resp => {
        this.getTask(true);
      })
    }
  }

  checkboxProfileLabel(row?): string {
    if (!row) {
      return `${this.isAllProfileSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.profileCollection.isSelected(row) ? 'deselect' : 'select'} profile ${row.name}`;
  }

  isAllProfileSelected() {
    const numSelected = this.profileCollection.selected.length;
    let numRows = 0;

    if (!!this.task.meta && !!this.task.meta.task_channels_by_platform_arr && !!this.task.meta.task_channels_by_platform_arr.length && this.returnObjByVal(this.task.meta.task_channels_by_platform_arr) && this.returnObjByVal(this.task.meta.task_channels_by_platform_arr).arr) {
      numRows = this.returnObjByVal(this.task.meta.task_channels_by_platform_arr).arr
    }
    // const numRows = !!(this.task && this.task.channels) ? this.task.channels.length : 0;
    return numSelected === numRows;
  }

  getProfilesStatus() {
    this.attachSubscriptions(
      this.globalDataService.getContentStatus().subscribe(resp => {
        console.log("getContentStatus", resp);
        this.profileStatuses = resp.slice();
      })
    )
  }

  getStatusById(id) {
    if (!this.profileStatuses || this.profileStatuses.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.profileStatuses.find(el => el.id == id)
  }

  setMultyAutoUpdate() {
    let multiData = [];

    this.profileCollection.selected.forEach(item => {
      if ([101, 102, 202, 203, 301, 401, 404].includes(item.content_type_id) && item.content_status_id == 4) {
        multiData.push({
          "path": `/api/task-channel/${item.id}/`,
          "query": {company_id: this.company.id},
          "method": "POST",
          "body": {
            [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
            is_content_views_count_update: 1,
            is_bravourl_content_views_count_update: 1
          }
        })
      }
    });

    if (multiData.length) {
      this.attachSubscriptions(
        this.taskService.multiRequest(multiData).subscribe(resp => {
          this.layoutService.showSnackBar({name: ''}, `Auto update for ${multiData.length} profile(s) succsess!`, SnackBarItem)
          this.profileCollection.selected.forEach(x => {
            this.refreshService.refreshUrls$.next({url_id: x.id, company_id: this.company.id})
          })
          this.profileCollection.clear()
        })
      )
    } else {
      this.layoutService.showSnackBar({name: ''}, `Selected ${this.profileCollection.selected.length} profile(s) can't auto update.`, SnackBarItem)
    }
  }
  
  changeStatuses() {
    console.log(this.profileCollection.selected);
    let chans:any = [];
    if (!!this.task.meta && !!this.task.meta.task_channels_by_platform_arr && !!this.task.meta.task_channels_by_platform_arr.length && this.returnObjByVal(this.task.meta.task_channels_by_platform_arr) && this.returnObjByVal(this.task.meta.task_channels_by_platform_arr).arr) {
      chans = this.returnObjByVal(this.task.meta.task_channels_by_platform_arr).arr
    }
    const goToChangingStatuses = this.dialog.open(MultiEditProfilesStatusComponent, {
      disableClose: true,
      data: {
        company: this.company,
        collection: this.profileCollection.selected,
        urls: chans,
        profileStatuses: this.profileStatuses
      }
    });

    this.attachSubscriptions(
      goToChangingStatuses.afterClosed().subscribe(result => {
        if (!!result && result.event == 'update') {
          this.getTask(true);
          this.profileCollection.clear()
        }
      })
    )
  }

  masterProfileToggle() {
    if (this.isAllProfileSelected()) {
      this.profileCollection.clear()
    } else {
      if (!!this.task.meta && !!this.task.meta.task_channels_by_platform_arr && !!this.task.meta.task_channels_by_platform_arr.length && this.returnObjByVal(this.task.meta.task_channels_by_platform_arr) && this.returnObjByVal(this.task.meta.task_channels_by_platform_arr).arr) {
        this.returnObjByVal(this.task.meta.task_channels_by_platform_arr).arr.forEach(row => this.profileCollection.select(row));
      }
    }
  }

  selectEmpl(job, empl, initial = false, initialStatus:any = false) {
    if (!!empl.initial_selected) {
      return
    }
    if (!!empl.selected) {
      empl.selected = false;
    } else {
      empl.selected = true;
    } 
  }

  chooseEmplStatus(job, status) {
    console.log("chooseEmplStatus", status)
    let empl = job.employees.find(x => ((x.employee_id == this.company.employees[0].id) || (x.partner_employee_id == this.company.employees[0].id)) && !x.discussion_id)
    if (!empl) {
      this.chooseStatus(job, status)
      return
    }
    if ((status.id) == 3) {
      empl.selected = true;
      job.empl_status = [empl]
      const dialogRef = this.dialog.open(ToApproveStepperComponent, {
        panelClass: 'to_appove_dialog',
        data: {
          company: this.company,
          user: this.user,
          task: this.task,
          activeLang: this.activeLang,
          job: job,
          status: status,
          statuses: this.statuses,
          imgRoute: this.imgRoute,
          is_mobile: this.is_mobile,
          host: this.host,
          empl_status: this.company.permissions.includes('owner') || this.checkIsManager(this.task, this.company, this.user),
          operationsValues: this.operationsValues,
          getTask: () => {this.getTask(true)}
        }
      });
      return
    }
    if (!!empl?.is_partner) {
      if (!!empl.partnerCompanyStatus?.id) {
        this.attachSubscriptions(
          this.membersService.editPartnerStatus(empl.partnerCompanyStatus.id, {status_id: status.id}, this.company_id).subscribe(resp => {
            this.getTask(true);
          },
          error => {
            this.showError()
          })
        )
      } else {
        let val:any = {
          status_id: status.id,
          company_id: this.company_id,
          task_id: this.data.task_id,
          task_operation_id: job.id,
          partner_company_id: empl.partner_company_id,
        }
        if (empl.discussion_id) {
          val.discussion_id = empl.discussion_id
        }
        this.attachSubscriptions(
          this.membersService.addPartnerStatus(val, this.company_id).subscribe(resp => {
            this.getTask(true);
          },
          error => {
            this.showError()
          })
        )
      }
    } else if (!!empl.is_partner_employee) {
      if (!!empl.employeeStatus?.id) {
        this.attachSubscriptions(
          this.membersService.editPartnerEmployeeStatus(empl.employeeStatus.id, {status_id: status.id}, this.company_id).subscribe(resp => {
            this.getTask(true);
          },
          error => {
            this.showError()
          })
        )
      
      } else {
        let val:any = {
          status_id: status.id,
          company_id: this.company_id,
          task_id: this.data.task_id,
          partner_company_id: empl.partner_company_id,
          partner_employee_id: empl.employee_id,
        }
        if (empl.discussion_id) {
          val.discussion_id = empl.discussion_id
        }
        this.attachSubscriptions(
          this.membersService.addPartnerEmployeeStatus(val, this.company_id).subscribe(resp => {
            this.getTask(true);
          },
          error => {
            this.showError()
          })
        )
      }
    } else {
      if (!!empl.employeeStatus?.id) {
        this.attachSubscriptions(
          this.membersService.editTaskEmployeeStatus(empl.employeeStatus.id, {status_id: status.id}, this.company_id).subscribe(resp => {
            this.getTask(true);
          },
          error => {
            this.showError()
          })
        )
      } else {
        let val:any = {
          status_id: status.id,
          company_id: this.company_id,
          task_id: this.data.task_id,
          task_operation_id: job.id,
          employee_id: empl.employee_id,
        }
        if (empl.discussion_id) {
          val.discussion_id = empl.discussion_id
        }
        this.attachSubscriptions(
          this.membersService.addTaskEmployeeStatus(val, this.company_id).subscribe(resp => {
            this.getTask(true);
          },
          error => {
            this.showError()
          })
        )
      }
    }

  }

  
  showStatusError(error, user?) {
    this.layoutService.showSnackBar({name: user}, `You do not have permission to do this. ${error}`, SnackBarItem)
  }

  chooseStatus(job, status) {
    console.log("chooseStatus", this.company)
    console.log("chooseStatus", job)
    // job.empl_status = job.employees.filter(x => !x.discussion_id);
    job.empl_status = job.employees.filter(x => !x.discussion_id && (this.company_id != job.company_id && !(this.company.permissions.includes('owner') || this.checkIsManager(this.task, this.company, this.user)) ? !x.is_partner && x.employee.company_id == this.company_id : true));
    job.empl_status.map(x => {
      if (x?.employeeStatus?.status_id == status.id) {
        x.initial_selected = true;
      }
      if (x?.partnerCompanyStatus?.status_id == status.id) {
        x.initial_selected = true;
      }
    })
    if (this.isManager && [4,98,99].includes(status.id) && status.id > job.status_id) {
      this.attachSubscriptions(
        this.taskService.editWork(job.id, {status_id: status.id}, this.company_id).subscribe(resp => {
          this.getTask(true);
        })
      )
    } else {
      if (job.empl_status.length == 1) {
        job.empl_status[0].selected = true;
        if (job.mainEmployees.length == 0) {
          this.attachSubscriptions(
            this.taskService.editWork(job.id, {status_id: status.id}, this.company_id).subscribe(resp => {
              this.applyStats(job, status)
            })
          )
        } else {
          this.applyStats(job, status)
        }
        // this.selectEmpl(job, job.empl_status[0], true, status)
      } else if (job.empl_status.length == 0) {
        this.attachSubscriptions(
          this.taskService.editWork(job.id, {status_id: status.id}, this.company_id).subscribe(resp => {
            this.getTask(true);
          })
        )
      } else {
        job.newStatusFor = status;
      }

    }
  }

  openEmplStatus(job) {
    console.log("openEmplStatus", job)
    job.if_i_cm = true;
    this.attachSubscriptions(
      this.taskService.getTaskClientsDyn("1", job.task_id, this.company_id, '200', {task_operation_id: `0,${job.id}`, client_employee_id: this.company.employees[0].id, is_principal: 1}).pipe(map((n) => n.body)).subscribe(resp => {
        job.if_i_cm = false;
        console.log("getTaskClientsDyn", resp)
        if (!!resp.length) {
          job.is_client_manager = true;
          this.openStatus(job)
        } else {
          job.is_client_manager = false;
          if (job.employees.filter(x => ((x.employee_id == this.company.employees[0].id) || (x.partner_employee_id == this.company.employees[0].id)) && x.partner_company_id == this.company.id && !x.discussion_id).length == 0) {
            return
          }
          if (!job.open) {
            this.task.operations.map(x => {
              x.open = false
              x.statuses = [];
            })
            job.open = true;
            let jobStatuses = [];
            if (!!job.employeeStatus || !!job.partnerCompanyStatus) {
              this.statuses.forEach(el => {
                if ((job.employeeStatus.status_id != el.id || job.partnerCompanyStatus.status_id != el.id) && el.is_lock == 0) {
                  jobStatuses.push(el)
                }
              })
            } else {
              this.statuses.forEach(el => {
                if (job.status_id != el.id && el.is_lock == 0) {
                  jobStatuses.push(el)
                }
              })
            }
      
            job.statuses = jobStatuses;
          } else {
            job.open = false;
            job.statuses = [];
            this.getTask(true);
          }
          console.log(job);
        }
      })
    );

  }

  openStatus(job) {
    console.log("openStatus", job)
    if (!job.open) {
      this.task.operations.map(x => {
        x.open = false
        x.statuses = [];
        x.newStatusFor = null
      })
      if (job.hasOwnProperty('is_client_manager') || this.isManager) {
        if (this.isMainEmpl(job) && !(this.isManager || !!job.is_client_manager)) {
          if (job.status.is_lock == 1) {
            return;
          }
  
          job.open = true;
          let jobStatuses = [];
          // jobStatuses.push(job.status);
          this.statuses.forEach(el => {
            if (el.id != job.status_id && el.is_lock == 0) {
              jobStatuses.push(el)
            }
          })
          job.statuses = jobStatuses;
        } else {
          job.open = true;
          let jobStatuses = [];
          // jobStatuses.push(job.status);
          this.statuses.forEach(el => {
            if (el.id != job.status_id) {
              jobStatuses.push(el)
            }
          })
          job.statuses = jobStatuses;
        }
      } else {
        job.if_i_cm = true;
        this.attachSubscriptions(
          this.taskService.getTaskClientsDyn("1", job.task_id, this.company_id, '200', {task_operation_id: `0,${job.id}`, client_employee_id: this.company.employees[0].id, is_principal: 1}).pipe(map((n) => n.body)).subscribe(resp => {
            job.if_i_cm = false;
            console.log("getTaskClientsDyn", resp)
            if (!!resp.length) {
              job.is_client_manager = true;
            } else {
              job.is_client_manager = false;
            }
            this.openStatus(job)
          })
        )
      }
    } else {
      job.open = false;
      job.statuses = [];
      job.newStatusFor = null;
      this.getTask(true);
    }
    console.log(job);
  }

  openExecutors(work) {
    const dialogRef = this.dialog.open(ExecutorsComponent, {
      panelClass: 'open_task_dialog',
      data: {
        company: this.company,
        user: this.user,
        task: this.task,
        activeLang: this.activeLang,
        job: work,
        statuses: this.statuses,
        sortValue: this.sortValue,
        host: this.host,
        empl_status: this.company.permissions.includes('owner') || this.checkIsManager(this.task, this.company, this.user),
        operationsValues: this.operationsValues,
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        this.getTask(true);
      })
    )
  }

  getColumnName(id){
    if (!this.operationsValues || this.operationsValues.filter(el => el.id == id).length == 0) {
      return '';
    }
    return this.operationsValues.find(el => el.id == id) && this.operationsValues.find(el => el.id == id).translation ? this.operationsValues.find(el => el.id == id).translation.name : this.operationsValues.find(el => el.id == id).name
  }

  getChatsForTask(task) {
    this.attachSubscriptions(
      this.chatService.getTaskChats(this.company_id, [task.id], this.chatPage).pipe(
        tap(el => {
          if (el.headers.get('x-pagination-current-page') == 1) {
            task.discussion_count = el.headers.get('x-pagination-total-count');
            this.chatPagination = {
              'pageCount': el.headers.get('x-pagination-page-count'),
              'perPage': el.headers.get('x-pagination-per-page'),
              'totalCount': el.headers.get('x-pagination-total-count'),
              'currentPage': el.headers.get('x-pagination-current-page'),
            }
            task['chatsInfo'] = [];
            task['operations'].map(z => z.chatsInfo = [])
          }
        }),
        map(x => x.body),
        tap(res => {
          task['operations'].forEach(o_el => {
            o_el['chatsInfo'].push(...res.filter(z => z.task_operation_id == o_el.id));
          });
         
          task['chatsInfo'].push(...res);
          
          task.read_discussion_count = res.filter(z => z.is_read == '1').length;
        })
      ).subscribe(res => {
        this.chatPage = this.chatPage + 1;
        if (this.chatPage <= this.chatPagination.pageCount) {
          this.getChatsForTask(task);
        }
      })
    )
  }

  needDialog(value) {
    let returnDialog;
    switch (value) {
      case 'employee':
        returnDialog = WorkEmployeeEditComponent
        break
      case 'CompletedAt':
        returnDialog = WorkCompletedAtEditComponent;
        break
      case 'OperationId':
        returnDialog = WorkOperationIdEditComponent;
        break
      case 'Status':
        returnDialog = WorkStatusEditComponent;
        break
      case 'StatusEmpl':
        returnDialog = WorkEmplStatusEditComponent;
        break
      case 'Rate':
        returnDialog = WorkRateEditComponent;
        break
      case 'Comment':
        returnDialog = WorkCommentEditComponent;
        break
      case 'EmployeeComment':
        returnDialog = WorkEmployeeCommentEditComponent;
        break
    }
    return returnDialog
  }

  goToChat(job, chat_id) {
    this.attachSubscriptions(
      this.chatService.getChatsExpand(1, this.company.id, {id: chat_id}).pipe(
        map(res => res.body[0]),
        switchMap(chat => {
          return this.taskService.getOneTaskExpand(this.company.id, chat.task_id).pipe(
            map(x => x.body[0]),
            switchMap(x => this.neededTaskData(x).pipe(map(() => casesModel([x], [], 'update')),
            switchMap(x => this.taskService.getOperations(this.company.id, this.activeLang).pipe(tap(u => x.arr[0].operationsValues = u), map(() => x))),
            map((x:any) => { return {task: x.arr[0], 'chat': chat}}))),
          )
        })
      ).subscribe((resp:any) => {
        console.log(resp);

        let data:any = {
          company: this.company,
          task: resp.task,
          dialogType: 'operation_chat',
          operationsValues: resp.task.operationsValues,
          work: resp.task.operations.find(el => el.id == job.id),
          user: this.user,
          empl_status: this.company.permissions.includes('owner') || this.company?.permissions.includes('admin') || this.company.permissions.includes('manager'),
          chat: resp.chat,
          chats: resp.task.operations.find(el => el.id == resp.chat.task_operation_id).chatsInfo
        }
 
        const goToChat = this.dialog.open(OperationChatComponent, {
          backdropClass: ['backdrop_under_header', "without_pad"],
          panelClass: !this.is_mobile ? ['full_screen_dialog', 'global_chats_dialog', 'show_header'] : ['full_screen_dialog', 'global_chats_dialog'],
          position: !this.is_mobile ? { top: '58px', left: '0', right: '0', bottom: '0' } : {},
          autoFocus: this.is_mobile ? false : true,
          data: data
        });
    
        this.attachSubscriptions(
          goToChat.afterClosed().subscribe(result => {
            if (!!result && result.event == 'close') {
              this.chatService.postChatRead(chat_id, 0, this.company.id).subscribe(resp => {
                this.getTask()
              })
            }
          })
        )
      })
    )
  }

  chatClick(e, job) {
    e.preventDefault(); 
    e.stopPropagation(); 
    if (job.chatsInfo.length == 1) {
      this.goToChat(job, job.chatsInfo[0].id)
      // this.openChat(job, job.chatsInfo[0])
      // this.goToChat(job)
    } else {
      // this.openJob(job.task_id, job, {selectedIndex: 0})
      this.openLiteJob(job, 0)
    }
  }

  openLiteJob(job, index?, loc?) {
    job.targetIds = {
      task_id: job.task_id,
      task_operation_id: job.id
    }
    console.log('openLiteJob')
    const dialogRef = this.dialog.open(LiteJobComponent, {
      backdropClass: !this.is_mobile ? 'backdrop_under_header': ['backdrop_under_header', 'mob_card_backdrop'],
      panelClass: !this.is_mobile ? ['open_task_dialog', 'show_header', 'lite_job_dialog'] : ['open_task_dialog', 'animate__animated', 'animate__slideInUp'],
      autoFocus: false,
      data: {
        task_id: job.task_id,
        task_operation_id: job.id,
        user: this.user,
        company: this.company,
        imgRoute: this.imgRoute,
        host: this.host,
        activeLang: this.activeLang,
        is_mobile: this.is_mobile,
        job: {...job},
        selectedIndex: index || 0,
        loc: loc,
        openJob: (task_id, item, inData) => {this.openJob(task_id, item, inData)} 
      }
    })

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        this.getTask(true);
      })
    )
  }
  
  moveWork(task, work) {
    const dialogRef = this.dialog.open(MoveJobsComponent, {
      data: {
        company: this.company,
        task: task,
        work: work,
        user: this.user,
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result && result.event == 'update') {
          this.getTask(true);
          if (result.task) {
            this.refreshService.refreshTask$.next({company_id: this.company_id, task_id: result.task.id})
            // this.initRefreshBoard()
          }
        }
      })
    )
  }

  showFilesInterface(task, work?, loc?) {
    const dialogRef = this.dialog.open(InterfaceComponent, {
      backdropClass: ['backdrop_under_header', 'file_backdrop_under_header'],
      panelClass: !this.is_mobile ? ['file_manager_modal', 'show_header'] : 'file_manager_modal',
      data: {
        company: this.company,
        task: task,
        work: work != undefined ? work : 0,
        user: this.user,
        loc: loc != undefined ? loc : ""
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        this.getTask(true);
      })
    )
  }

  editGroup(element) {
    const dialogRef = this.dialog.open(TaskGroupEditComponent, {
      disableClose: true,
      data: {
        company_id: this.company_id,
        company: this.company,
        task: element
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        
        if (!!result && result.event == "Add") {
          this.getTask(true);
        }
      })
    )
  }

  deleteGroup(element) {
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: element,
        target: marker("task group")
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        this.attachSubscriptions(
          this.taskService.editTask(element.id, {group_id: 0}, this.company.id).subscribe(resp => {
            this.getTask(true);
          })
        )
      }
    });

  }

  deleteTask(task) {
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: task,
        target: marker("task")
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        this.attachSubscriptions(
          this.taskService.deleteTask(task.id).subscribe(resp => {
            if (!!this.data.tasks) {
              this.data.tasks.splice(this.data.tasks.indexOf(this.data.tasks.find(x => x.id == task.id)),1);
            }
          })
        )
      }
    });

  }

  checkIsManager(task, company, _user) {
    if (!!this.data.is_shared) {
      return false
    } else {
      return (!!task && !!task.managers && task.managers.filter(x => x.user_id == _user.id).length > 0) || (!!task && !!task.group && !!task.group.managers && task.group?.managers.filter(x => x.user_id == _user.id).length > 0) || company?.permissions?.includes('admin') || company?.permissions.includes('manager'); 
    }
  }

  dblEditWork(e, task, work, comp, status, is_client: boolean = false) {
    console.log(e);
    e.stopPropagation();
    if (!status || is_client) {
      return
    }
    let component = this.needDialog(comp)

    const dialogRef = this.dialog.open(component, {
      data: {
        company_id: this.company_id,
        company: this.company,
        task: task,
        work: work,
        user: this.user,
        empl_status: this.company.permissions.includes('owner') || this.checkIsManager(this.task, this.company, this.user),
        operationsValues: this.operationsValues
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result && result.event == 'update') {
          this.getTask(true);
        }
      })
    )
  }
  
  addChannel(element) {
    console.log(element)
    const dialogRef = this.dialog.open(AddChannelToTaskComponent, {
      disableClose: true,
      data: {
        company: this.company,
        company_id: this.company_id,
        task: element
      }
    });
    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result && result.event == "Add") {
          this.getTask(true);
        }
      })
    )
  }

  openLiveStreamsSettings() {
    console.log('openLiveStreamsSettings'),
    this.attachSubscriptions(
      this.taskService.getExpandUrlsWithTags('1', this.company_id, {task_id: this.task.id, content_type_id: '106'}, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }
          return forkJoin(arr.map(x => this.taskService.getExpandUrlsWithTags(x, this.company_id, {task_id: this.task.id, content_type_id: '106'}, '200').pipe(map(u => u.body)))).pipe(
            last(),
            map(values => {
              return [].concat(...values)
            }),
          )
        }),
      ).subscribe(resp => {
        console.log("openLiveStreamsSettings resp", resp);
        const dialogRef = this.dialog.open(LivesSettingsComponent, {
          backdropClass: ['ve_back_auto'],
          panelClass: ['ve_panel_auto'],
          disableClose: true,
          autoFocus: false,
          data: {
            company: this.company,
            task: this.task,
            host: this.host,
            activeLang: this.activeLang,
            lives: resp,
          }
        });
    
        this.attachSubscriptions(
          dialogRef.afterClosed().subscribe(result => {
            this.getTask(true);
          })
        )
      })
    )
  }

  stopLive(element, profile) {
    this.attachSubscriptions(
      this.taskService.editProfile(profile.id, {
        task_id: element.id,
        content_status_id: 4,
      }, this.company_id).subscribe(resp => {
        this.getTask(true);
      })
    )
  }

  startLive(element, profile) {
    this.attachSubscriptions(
      this.taskService.editProfile(profile.id, {
        task_id: element.id,
        content_status_id: 6,
      }, this.company_id).subscribe(resp => {
        this.getTask(true);
      })
    )
  }

  editProfile(element, profile) {
    if (profile.content_type_id == '106') {
      this.addLive(element, profile);
      return
    }
    if (!this.is_mobile) {
      const dialogRef = this.dialog.open(AddTaskProfileComponent, {
        disableClose: true,
        data: {
          company: this.company,
          company_id: this.company_id,
          task: element,
          profile: profile,
          is_edit: true
        }
      });
  
      this.attachSubscriptions(
        dialogRef.afterClosed().subscribe(result => {
          
          if (!!result && result.event == 'edit') {
            this.getTask(true);
          }
        })
      )
    } else {
      // const dialogRef = this.aniDialog.open(MobEditTaskProfileComponent, {
      //   width: "250px",
      //   animation: { to: "aside" }
      // });
      const dialogRef = this.dialog.open(MobEditTaskProfileComponent, {
        backdropClass: ['mob_interface_backdrop'],
        panelClass: ['mob_interface_panel', 'animate__animated', 'animate__slideInUp', 'mob_edit_profile_int'],
        disableClose: true,
        data: {
          company: this.company,
          company_id: this.company_id,
          task: element,
          profile: profile,
          is_edit: true
        }
      });
  
      this.attachSubscriptions(
        dialogRef.afterClosed().subscribe(result => {
          
          if (!!result && result.event == 'edit') {
            this.getTask(true);
          }
        })
      )
    }
  }

  setIsBackup(post, val) {
    let publParams = JSON.parse(JSON.stringify(post.publishing_params));
    if (!!val) {
      publParams.live_stream_url_backup = 'rtmp://b.rtmp.youtube.com/live2?backup=1';
      publParams.live_stream_to_backup =  publParams.live_stream_url_backup.split('?')[0] + '/' + publParams.live_stream_key + "?backup=1";
    }
    publParams.is_backup = val;
    this.attachSubscriptions(
      this.taskService.editProfile(post.id, {
        publishing_params: publParams,
      }, this.company_id).subscribe(resp => {
        post.publishing_params = resp.publishing_params;
        // this.refreshService.refreshUrls$.next({url_id: post.id, company_id: this.company_id})
      })
    )
  }

  addLiveAuto(element) {
    console.log(element)
    const dialogRef = this.dialog.open(AddAutoLivesComponent, {
      disableClose: true,
      data: {
        company: this.company,
        company_id: this.company_id,
        task: element
      }
    });
    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result && result.event == "Add") {
          this.getTask(true);
        }
      })
    )
  }

  addLive(element, profile?) {
    let x:any = {
      company: this.company,
      company_id: this.company_id,
      task: element
    }
    if (profile) {
      x.profile = profile;
      x.is_edit = true
    }
    const dialogRef = this.dialog.open(AddEditLiveStreamComponent, {
      disableClose: true,
      data: x
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result && (result.event == "Add" || result.event == 'edit')) {
          this.getTask(true);
        }
      })
    )
  }

  addProfile(element) {
    if (!this.is_mobile) {
      const dialogRef = this.dialog.open(AddTaskProfileComponent, {
        disableClose: true,
        data: {
          company: this.company,
          company_id: this.company_id,
          task: element
        }
      });
  
      this.attachSubscriptions(
        dialogRef.afterClosed().subscribe(result => {
          if (!!result && result.event == "Add") {
            this.getTask(true);
          }
        })
      )
    } else {
      const dialogRef = this.dialog.open(MobEditTaskProfileComponent, {
        backdropClass: ['mob_interface_backdrop'],
        panelClass: ['mob_interface_panel', 'animate__animated', 'animate__slideInUp', 'mob_edit_profile_int'],
        data: {
          company: this.company,
          company_id: this.company_id,
          task: element
        }
      });
  
      this.attachSubscriptions(
        dialogRef.afterClosed().subscribe(result => {
          if (!!result && result.event == "Add") {
            this.getTask(true);
          }
        })
      )

    }
  }

  goToChanelLink(task, chanel) {
    if (!!chanel.content_url) {
      window.open(chanel.content_url, '_blank');
    } else {
      this.editProfile(task, chanel)
    }
  }

  deleteProfile(task, profile) {
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: task,
        target: marker("profile")
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        this.attachSubscriptions(
          this.taskService.deleteProfile(profile.id).subscribe(resp => {
            this.getTask(true);
          })
        )
      }
    });

  }

  showUserDate(date:any = false, timestamp:any = false) {
    if (!this.company) {
      return
    }
    let timeAndDate;
    if (date) {
      timeAndDate = moment(date).clone()
    } else {
      timeAndDate = moment(timestamp*1000).clone()
    }

    const utcOffset = moment.tz(timeAndDate, this.company.timezone).utcOffset();
    
    let utcTimestamp = moment(timeAndDate).format("X")
    
    let difference = (Number(this.timeZone) - Number(utcOffset))*60
    console.log(difference);
    
    let workSpaceTimeZone;
    if (date) {
      workSpaceTimeZone = Number(utcTimestamp) + Number(difference)
    } else {
      workSpaceTimeZone = Number(utcTimestamp) - Number(difference)
    }
    return workSpaceTimeZone
  }

  profileDialog(value) {
    let returnDialog;
    switch (value) {
      case 'PublishedAt':
        returnDialog = EditPublishedAtComponent;
        break
      case 'Status':
        returnDialog = EditStatusComponent;
        break
      case 'Channel':
        returnDialog = AddChannelToTaskComponent;
        break
      case 'Name':
        returnDialog = EditContentNameComponent;
        break
    }
    return returnDialog
  }

  dblEditProfile(profile, value) {
    if (!!this.data.is_shared) {
      return
    }
    const dialogRef = this.dialog.open(this.profileDialog(value), {
      disableClose: true,
      data: {
        company: this.company,
        company_id: this.company_id,
        task: this.task,
        profile: profile,
        is_edit: true
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        
        if (!!result && result.event == 'edit') {
          this.getTask(true);
        }
      })
    )
  }

  getGroup(id) {
    if (!this.groups || !this.groups.find(el => el.id == id)) {
      return false;
    }
    return this.groups.find(el => el.id == id)
  }

  getTaskStatusById(id) {
    if (!this.taskStatuses || this.taskStatuses.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.taskStatuses.find(el => el.id == id)
  }

  changeMobJobStatus(job) {
    const dialogRef = this.dialog.open(MobChangePersonalStatusComponent, {
      backdropClass: ['mob_interface_backdrop'],
      panelClass: ['mob_interface_panel', 'animate__animated', 'animate__slideInUp'],
      data: {
        company: this.company,
        task: this.task,
        work: job,
        host: this.host,
        imgRoute: this.imgRoute,
        user: this.user,
        statuses: this.statuses,
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          if (result.event == 'back') {
            this.editWork(this.task, job)
          } else if (result.event == 'update') {
            this.getTask(true);
            this.initRefreshBoard();
          }
        } else {
          this.getTask(true);
          this.initRefreshBoard();
        }
      })
    )
  }

  open({ x, y }: MouseEvent, val) {
    this.close();
    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo({ x, y })
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        }
      ]);

    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });

    this.overlayRef.attach(new TemplatePortal(this.taskMenu, this.viewContainerRef, {
      $implicit: val
    }));
    
    this.sub = fromEvent<MouseEvent>(document, 'click')
      .pipe(
        filter(event => {
          const clickTarget = event.target as HTMLElement;
          return !!this.overlayRef && !this.overlayRef.overlayElement.contains(clickTarget);
        }),
        take(1)
      ).subscribe(() => this.close())

  }

  openContext({ x, y }: MouseEvent, job) {
    this.closeContext();
    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo({ x, y })
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        }
      ]);

    console.log("openContext job", job);
    job.can_open = true;
    job.is_clicked = true;
    job.active_location = '/';
    job.output_location = '';
    job.outputFolder = {
      targetIds: {
        task_id: job.task_id,
        task_operation_id: job.id,
      },
      page: 1,
      pagination: undefined,
      files: [],
      is_load: true
    }

    this.liteFmOverlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });

    this.liteFmOverlayRef.attach(new TemplatePortal(this.liteFmMenu, this.viewContainerRef, {
      $implicit: job
    }));
    
    this.liteFmSub = fromEvent<MouseEvent>(document, 'click')
      .pipe(
        filter(event => {
          const clickTarget = event.target as HTMLElement;
          let check1 = !document.getElementsByClassName('proj-file-menu') || document.getElementsByClassName('proj-file-menu').length > 0 && !document.getElementsByClassName('proj-file-menu')[0].contains(clickTarget);
          let check2 = !!this.liteFmOverlayRef && !this.liteFmOverlayRef.overlayElement.contains(clickTarget) && !clickTarget.closest('.proj-file-menu')
          let check3 = !!this.liteFmOverlayRef && !this.liteFmOverlayRef.overlayElement.contains(clickTarget) 
          let check4 = !clickTarget.closest('.proj-file-menu')
          console.log("clickTarget", clickTarget)
          console.log("this.liteFmOverlayRef.overlayElement", this.liteFmOverlayRef.overlayElement)
          console.log("check1", check1)
          console.log("check2", check2)
          console.log("check3", check3)
          console.log("check4", check4)
          return check2;
        }),
        take(1)
      ).subscribe(() => {
        job.is_clicked = false;
        this.closeContext()
      })

  }

  closeContext() {
    this.liteFmSub && this.liteFmSub.unsubscribe();
    if (this.liteFmOverlayRef) {
      this.liteFmOverlayRef.dispose();
      this.liteFmOverlayRef = null;
    }
  }

  close() {
    this.sub && this.sub.unsubscribe();
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
  }

  @HostListener("window:visibilitychange", ["$event"])
  onVisibilityChange($event) {
      this.isVisible = $event.target.visibilityState === 'visible';
      // console.log("&&&&", isVisible)
  }

  ngOnDestroy(): void {
    if (this.taskPostsSub) {
      this.taskPostsSub.unsubscribe();
    }
    if (this.gettingTaskSub) {
      this.gettingTaskSub.unsubscribe()
    }
    if (this.jobLoadSub) {
      this.jobLoadSub.unsubscribe();
    }
    if (this.is_mobile) {
      this.layoutService.overflowAuto();
    }
    if (this.liteFmSub) {
      this.liteFmSub.unsubscribe();
    }

    this.jobs$.asObservable().pipe(take(1)).subscribe(resp => {
      resp.forEach(x => {
        x.open = false
      })
      this.jobs$.next(resp)
    })

    this.clearSubscriptions()
  }
}
