import { Component, Inject, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ReplaySubject, Subscription, concat, forkJoin, from, fromEvent, of, scheduled } from 'rxjs';
import { catchError, concatMap, debounceTime, distinctUntilChanged, filter, last, map, switchMap, take, tap } from 'rxjs/operators';
import { BaseClass } from 'src/app/shared/models/base-class';
import { FileService } from 'src/app/shared/services/rest/file.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { LoadingService } from 'src/app/shared/services/rest/loading.service';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { MatBottomSheet } from '@angular/material/bottom-sheet';

import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as moment from 'moment';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { GlobalDataService } from 'src/app/shared/services/common/global-data.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { RefreshService } from 'src/app/shared/services/rest/refresh.service';
import { DeleteAlertComponent } from 'src/app/shared/global_components/delete-alert/delete-alert.component';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { SelectionModel } from '@angular/cdk/collections';
import { BackJobToPrevStatusComponent } from 'src/app/shared/global_components/back-job-to-prev-status/back-job-to-prev-status.component';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { ParametersService } from 'src/app/shared/services/rest/parameters.service';
export const MY_FORMATS = {
  parse: {
    dateInput: 'LLLLL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
export const MY_NEW_FORMATS = {
  parse: {
    dateInput: 'MM/DD/YYYY',
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'MM/DD/YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
const colors: Record<string, any> = {
  1: {
    type: "Waiting",
    primary: '#2F80ED',
    secondary: '#BCD7FD',
  },
  2: {
    type: "Draft",
    primary: '#DB0000',
    secondary: '#FFAEAE',
  },
  3: {
    type: "Scheduled",
    primary: '#BEC11B',
    secondary: '#FEFFB9',
  },
  4: {
    type: "Published",
    primary: '#219653',
    secondary: '#98F1BD',
  },
  5: {
    type: "Canceled",
    primary: '#686868',
    secondary: '#E0E0E0',
  },
  6: {
    type: "content",
    primary: '#DD5E89',
    secondary: '#F7BB97',
  }
};

@Component({
  selector: 'app-posts-cleaner',
  templateUrl: './posts-cleaner.component.html',
  styleUrls: ['./posts-cleaner.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_NEW_FORMATS},
  ]
})
export class PostsCleanerComponent extends BaseClass implements OnInit, OnDestroy {
  public progress:number = 0;

  public page = 1;
  public pagination: any;

  public groups: any[] = [];
  public groupMoreControl: FormControl = new FormControl();
  public groups$: ReplaySubject<any> = new ReplaySubject<any>(1);
  
  public eltsSub: Subscription;

  public isLoad: boolean = false;

  public eltsCollection: any = new SelectionModel(
    true,
    []
  );

  public today = moment().set({hour:0,minute:0,second:0}).unix();
  public todayEnd = moment().endOf('day').unix();

  public platforms: any;
  public platforms$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public platformsMoreControl: FormControl = new FormControl();

  public cards: any = [];

  public isDeleting: boolean = false;

  public filter: FormGroup = this.fb.group({
    group_id: '',
    platform_id: '',
    task_created_at_from: '',
    task_created_at_to: '',
    parameter_id: ''
  })

  public parameters: any;
  public parametersCtrl: FormControl = new FormControl();
  public parameters$: ReplaySubject<any> = new ReplaySubject<any>(1);


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private taskService: TaskService,
    private ls: LoadingService,
    private globalDataService: GlobalDataService,
    private layoutService: LayoutService,
    private companyService: CompanyService,
    public viewContainerRef: ViewContainerRef,
    public overlay: Overlay,
    public fb: FormBuilder,
    private parametersService: ParametersService,
    private bottomSheet: MatBottomSheet,
    private _adapter: DateAdapter<any>,
    private refreshService: RefreshService,
    private sm: StorageManagerService,
    private fileService: FileService,
    public dialogRef: MatDialogRef<PostsCleanerComponent>,
    private dialog: MatDialog,
  ) { super() }

  ngOnInit(): void {
    console.log("PostsCleanerComponent this.data", this.data)
    this._adapter.setLocale(this.data.activeLang);
    this.getPlatforms();
    this.getParameters();
    this.getGroupsCompany();

    this.attachSubscriptions(
      this.filter.get('group_id').valueChanges.subscribe(resp => {
        console.log("this.filter.get('group_id').valueChanges", resp)
        // this.getRegulars();
      })
    )

    this.attachSubscriptions(
      this.filter.get('platform_id').valueChanges.subscribe(resp => {
        console.log("this.filter.get('platform_id').valueChanges", resp)
        // this.getRegulars();
      })
    )

    this.attachSubscriptions(
      this.parametersCtrl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchParams(resp))
    )

    this.attachSubscriptions(
      this.platformsMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchPlatforms(resp))
    )

    this.attachSubscriptions(
      this.groupMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchGroups(resp))
    )
  }

  onSearchParams(resp) {
    if (!this.parameters) {
      return;
    }

    if (!resp) {
      this.parameters$.next(this.parameters.filter(z => !!z.id).slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.parameters$.next(
      this.parameters.filter(z => !!z.id && (z.name.toLowerCase().indexOf(resp) > -1))
    );
  }

  getParameters() {
    this.attachSubscriptions(
      this.parametersService.getApiParameters(this.data.company.id).subscribe(resp => {
        this.parameters = resp;
        this.parameters$.next(this.parameters.slice())
        console.log("this.parameters", this.parameters)
      })
    )
  }

  onSearchPlatforms(resp) {
    if (!this.platforms) {
      return;
    }

    if (!resp) {
      this.platforms$.next(this.platforms.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }

    // filter the banks
    this.platforms$.next(
      this.platforms.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  getPlatformById(id) {
    if (!this.platforms || this.platforms.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.platforms.find(el => el.id == id)
  }

  getGroupsCompany() {
    this.attachSubscriptions(
      this.companyService.getInfiniteGroupsCompany(this.data.company.id, '1', '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.companyService.getInfiniteGroupsCompany(this.data.company.id, x).pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        this.groups = [].concat(...res);
        this.groups$.next(this.groups.slice());
      })
    )
  }

  onSearchGroups(resp) {
    if (!this.groups) {
      return;
    }

    if (!resp) {
      this.groups$.next(this.groups.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.groups$.next(
      this.groups.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  filterCards() {
    this.page = 1;
    this.cards = [];
    this.progress = 0;
    this.getCards(this.page);
  }

  getPlatforms() {
    this.attachSubscriptions(
      this.companyService.getGroupPlatforms().subscribe(resp => {
        this.platforms = resp;
        this.platforms$.next(this.platforms.slice());
        console.log("this.platforms", this.platforms)
      })
    )
  }

  setOutletKey(e) {
    console.log('setOutletKey', e)
  }

  selectChange(e) {
    console.log('selectChange', e)
  }

  copyLiveData(type) {
    this.layoutService.showSnackBar({name: type}, marker("Copied"), SnackBarItem)
  }

  elClick(e, card) {
    console.log("e", e);
    console.log("card", card);
    
    // if (!e.target.closest('.item_top')) {
      if (e.ctrlKey || e.metaKey) { // для Mac OS
        e.preventDefault();
        e.stopPropagation();
        this.eltsCollection.toggle(card)
        return
      }
    // }
  }

  refresh() {
    this.pagination = undefined;
    this.page = 1;
    this.cards = [];
    this.progress = 0;
    this.getCards(this.page)
  }

  logData(val) {
    console.log("logData", val)
  }

  deleteProfiles() {

    this.isDeleting = true;

    let array = this.findDeletedPosts();
    let lenSelection = array.length;
    console.log(array);

    if (!lenSelection) {
      this.layoutService.showSnackBar({name: 'There are no posts that require deletion'}, '', SnackBarItem)
      this.isDeleting = false;
      return
    }

    let confirmAlert =  this.bottomSheet.open(BackJobToPrevStatusComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        msg: marker(`Are you sure you want to delete ${array.length} post(s):`),
        profileCollection: array,
        btn_no: "Cancel",
        btn_yes: "Delete"
      }
    });

    confirmAlert.afterDismissed().subscribe(data => {
      if (data && data.message == 'no') {
        console.log("no");
        this.isDeleting = false;
        this.eltsCollection.clear()
      } else if (data && data.message == 'yes') {
        let count = 0;

        this.ls.requests$.next({
          value: 0,
          target: `Deleting ${lenSelection} post(s)`
        })
        from(array).pipe(
          concatMap((post:any) => {
            return this.taskService.deleteProfile(post.id)
          })
        ).subscribe({
          next: (next) => {
            console.log("next deleteProfiles", next);
            this.ls.requests$.next({
              value: Math.round((100 / lenSelection) * (count+1)),
              target: `Deleting ${lenSelection} post(s)`
            })
            count++;
          },
          complete: () => {
            console.log("complete deleteProfiles");
            this.eltsCollection.clear();
            this.refresh()
            this.isDeleting = false;
          },
          error: (error) => {
            console.log("error deleteProfiles", error)
            this.isDeleting = false;
          }
        })
      }
    })
  }

  checkboxLabel(row?): string {
    if (!row) {
      return `${this.isAllSelected() ? 'Deselect' : 'Select'} All`;
    }
    return `${this.eltsCollection.isSelected(row) ? 'Deselect' : 'Select'} post ${row.name}`;
  }

  isAllSelected() {
    const numSelected = this.eltsCollection.selected.length;
    const numRows = this.cards.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
        this.eltsCollection.clear() :
        this.cards.forEach(row => this.eltsCollection.select(row));
  }

  
  getCards(n) {
    this.isLoad = true;
    let filterData:any = {...this.filter.value};
    delete filterData.platform_id;
    delete filterData.parameter_id;
    // filterData.content_status_id = 6;

    if (!!filterData.task_created_at_from) {
      filterData.task_created_at_from = moment(filterData.task_created_at_from._d).format("X");
    } else {
      delete filterData.task_created_at_from;
    }
    if (!!filterData.task_created_at_to) {
      filterData.task_created_at_to = moment(filterData.task_created_at_to._d).endOf('day').format("X");
    } else {
      delete filterData.task_created_at_to;
    }
    // filterData.task_created_at_from_x = moment(filterData.task_created_at_from).startOf('day').format("X");
    // filterData.task_created_at_to_x = moment(filterData.task_created_at_to).endOf('day').format("X");
 
    this.eltsSub = this.taskService.getTasksSelectNoExp(n, this.data.company.id, filterData).pipe(
      tap(el => {
        this.pagination = {
          'pageCount': el.headers.get('x-pagination-page-count'),
          'perPage': el.headers.get('x-pagination-per-page'),
          'totalCount': el.headers.get('x-pagination-total-count'),
          'currentPage': el.headers.get('x-pagination-current-page')
        }
        console.log("this.pagination TEST", this.pagination)
      }),
      map(el => el.body),
      distinctUntilChanged()
    ).subscribe(resp => {

      console.log("getCards", resp)
      
      if (this.page == 1) {
        this.cards = resp;
      } else {
        this.cards.push(...resp);
      }

      this.progress = +((this.cards.length / +this.pagination.totalCount)*100).toFixed(1);
      this.page = this.page + 1;
      
      
      if (+this.pagination['currentPage'] < +this.pagination['pageCount'] && +this.pagination['pageCount'] > 0) {
        this.getCards(this.page)
      } else {
        console.log("cards", this.cards)
        this.getCardsPosts()
      }
    },
    error => {
      this.isLoad = false;
    })
  }

  findDeletedPosts() {

    let needDeletedPosts = [];

    (!!this.eltsCollection.selected.length ? this.eltsCollection.selected : this.cards).forEach(card => {
      if (card.postsByTags) {
        card.postsByTags.forEach(el => {
          if (!!el.waiting_posts.length && !!el.no_waiting_posts.length) {
            needDeletedPosts.push(...el.waiting_posts)
          }
        });
      }
    })
    return needDeletedPosts
  }

  getCardsPosts() {
    let lenSelection = this.cards.length;
    let count = 0;
    from(this.cards).pipe(
      concatMap((card:any) => {
        return this.taskService.getLiteUrlsWithTags('1', this.data.company.id, {task_id: card.id, platform_id: this.filter.value.platform_id}, '200').pipe(
          map(u => u.body),
          tap(res => {
            card.postsByTags = [];
            card.posts = res;

            if (card.posts) {
              card.posts.forEach(element => {
                if (element.channel.parameterValuesToChannel && element.channel.parameterValuesToChannel.filter(k => k.parameter_id == this.filter.value.parameter_id).length) {
                  let tag = element.channel.parameterValuesToChannel.filter(k => k.parameter_id == this.filter.value.parameter_id)[0];
                  if (!!card.postsByTags.filter(k => k.parameter_value_id == tag.parameter_value_id).length) {
                    if (element.content_status_id == 1) {
                      card.postsByTags.find(k => k.parameter_value_id == tag.parameter_value_id).waiting_posts.push(element)
                    } else {
                      card.postsByTags.find(k => k.parameter_value_id == tag.parameter_value_id).no_waiting_posts.push(element)
                    }
                  } else {
                    card.postsByTags.push({
                      parameter_value_id: tag.parameter_value_id,
                      tag: tag,
                      waiting_posts: element.content_status_id == 1 ? [element] : [],
                      no_waiting_posts: element.content_status_id == 1 ? [] : [element]
                    })
                  }
                }
              });
            }
          })
        )
      })
    ).subscribe({
      next: (next) => {
        console.log("next getCardsPosts", next);
        this.ls.requests$.next({
          value: Math.round((100 / lenSelection) * (count+1)),
          target: `Getting posts for ${lenSelection} card(s)`
        })
        count++;
      },
      complete: () => {
        console.log("complete getCardsPosts");
        this.isLoad = false;
      },
      error: (error) => {
        console.log("error getCardsPosts", error)
        this.isLoad = false;
      }
    })
  }

  log() {
    console.log("PostsCleanerComponent this.cards", this.cards)
    console.log("PostsCleanerComponent this.filter", this.filter.value)
  }

  understandFileType(val) {
    if (!val) {
      return ""
    }
    
    if (val == 'application/pdf') {
      return 'pdf'
    } else if (val.indexOf("/") != -1) {
      return val.split('/')[0]
    } else {
      return ""
    }
  }

  toggleCol() {
    if (this.isAllChecked()) {
      this.cards.map(x => x.checked = false)
    } else {
      this.cards.map(x => x.checked = true)
    }
  }

  isAllChecked() {
    return this.cards.filter(x => !!x.checked).length == this.cards.length
  }

  hasChecked() {
    return !!this.cards.filter(x => !!x.checked).length
  }

  close() {
    this.dialogRef.close({event: "close", data: false});
  }

  ngOnDestroy(): void {
    if (this.eltsSub) {
      this.eltsSub.unsubscribe()
    }
    this.clearSubscriptions()
  }

}
