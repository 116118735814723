import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { Moment } from 'moment';
import { forkJoin, fromEvent, of, ReplaySubject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, last, map, switchMap, tap } from 'rxjs/operators';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { AuthService } from 'src/app/shared/services/rest/auth.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { StatementService } from 'src/app/shared/services/rest/statement.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { environment } from 'src/environments/environment';
import { StatementComponent } from './dialogs/statement/statement.component';

export const MY_FORMATS = {
  parse: {
    dateInput: 'MMM YYYY',
  },
  display: {
    dateInput: 'MMM YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-statements',
  templateUrl: './statements.component.html',
  styleUrls: ['./statements.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
})

export class StatementsComponent extends BaseClass implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild("statementsContainer") statementsContainer: ElementRef;
  public host: any = environment.host;
  public imgRoute: any = '';
  public user: any = {};
  public user_id: number;
  public currMonthName: any;  
  public company_id: any;
  public activeLang: any;
  public currencyes: any;
  public page: number = 1;
  public filterCount: number = 1;
  public pagination: object = {};
  public timeZone = new Date().getTimezoneOffset();
  public is_mobile: boolean = false;
  public statements: any[] = [];
  public employees: any;
  public sortValue: string = 'updated_desc';
  public isLoad: boolean = false;
  public statementsSub: Subscription;
  public me: any;
  public curYear = (new Date()).getFullYear();
  public statementsDataSub: Subscription;
  public company: any;
  public operationsValues: any;
  public statuses: any;
  public minDate: any;

  public employees$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public execControl: FormControl = new FormControl();
  
  public departments: any;
  public departments$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public departmentsControl: FormControl = new FormControl();

  public positions: any;
  public positions$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public positionsControl: FormControl = new FormControl();
  
  public today = moment().set({hour:0,minute:0,second:0}).unix();
  public filter: FormGroup = this.fb.group({
    count: '',
    q: '',
    employee_id: "",
    department_id: "",
    department_position_id: "",
    time_start_utc_from : '',
    time_start_utc_to : '',
    order_by: 'time_start_utc_desc',
  });

  @Input() withoutFilter:boolean = false;
  @Input() useOut:boolean = false;

  constructor(
    private sm: StorageManagerService,
    private auth: AuthService,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private statementService: StatementService,
    private taskService: TaskService,
    private companyService: CompanyService,
    private languageService: LanguageService,
    private dialog: MatDialog,
    private router: Router,
    private _adapter: DateAdapter<any>,
  ) { super() }

  ngOnInit(): void {
    this.company_id = this.activatedRoute.snapshot.queryParamMap.get('company_id');

    this.getEmployees();
    this.checkIsMobile()
    this.getLangData(this.company_id);
    this.getImgRoute();
    this.getUser();
    this.getDepartmentsDyn();
    this.getPositionsDyn();
    this.onRouteChange();
    this.attachSubscriptions(
      this.execControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchEmoloyees(resp))
    )

    this.attachSubscriptions(
      this.departmentsControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchDep(resp))
    )

    this.attachSubscriptions(
      this.positionsControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchPos(resp))
    )
  }

  getDepById(id) {
    if (!this.departments || this.departments.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.departments.find(el => el.id == id)
  }

  getPosById(id) {
    if (!this.positions || this.positions.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.positions.find(el => el.id == id)
  }

  getDepartmentsDyn() {
    this.attachSubscriptions(
      this.companyService.getDepartmentsDyn('1', this.company_id, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }
          return forkJoin(arr.map(x => this.companyService.getDepartmentsDyn(x, this.company_id, '200').pipe(map(u => u.body)))).pipe(
            last(),
            tap(values => {
              let conValues = [].concat(...values)
              this.departments = conValues;
              this.departments$.next(this.departments.slice())
            }),
          )
        }),
      ).subscribe(resp => {
        console.log("getDepartmentsDyn resp", resp);
        console.log("getDepartmentsDyn departments", this.departments);
      })
    )
  }

  getPositionsDyn() {
    this.attachSubscriptions(
      this.companyService.getPositionsDyn('1', this.company_id, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }
          return forkJoin(arr.map(x => this.companyService.getPositionsDyn(x, this.company_id, '200').pipe(map(u => u.body)))).pipe(
            last(),
            tap(values => {
              let conValues = [].concat(...values)
              this.positions = conValues;
              this.positions$.next(this.positions.slice())
            }),
          )
        }),
      ).subscribe(resp => {
        console.log("getPositionsDyn resp", resp);
        console.log("getPositionsDyn positions", this.positions);
      })
    )
  }

  onSearchDep(resp) {
    if (!this.departments) {
      return;
    }

    if (!resp) {
      this.departments$.next(this.departments.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.departments$.next(
      this.departments.filter(z => z.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  onSearchPos(resp) {
    if (!this.positions) {
      return;
    }

    if (!resp) {
      this.positions$.next(this.positions.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.positions$.next(
      this.positions.filter(z => z.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  onSearchEmoloyees(resp) {
    if (!this.employees) {
      return;
    }

    if (!resp) {
      this.employees$.next(this.employees.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.employees$.next(
      this.employees.filter(z => z.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  goToStatementTemplates(e) {
    e.preventDefault()
    this.router.navigate(['/settings'], { queryParams: {company_id: this.company_id, page: 'finances', tab: 'statement_templates'} });
  }

  onRouteChange() {
    this.attachSubscriptions(
      this.activatedRoute.queryParams.pipe(
        tap((val) => {
          if (this.statementsDataSub) {
            this.statementsDataSub.unsubscribe();
          }
          if (this.company_id != this.activatedRoute.snapshot.queryParamMap.get('company_id')) {
            this.company_id = this.activatedRoute.snapshot.queryParamMap.get('company_id');
            this.getEmployees();
          }
          // this.sortValue = !!this.activatedRoute.snapshot.queryParamMap.get('order_by') ? this.activatedRoute.snapshot.queryParamMap.get('order_by') : "updated_desc";
          this.filter.patchValue({
            count: this.filterCount,
            employee_id: !!this.activatedRoute.snapshot.queryParamMap.get('operation_employee_id') ? this.activatedRoute.snapshot.queryParamMap.get('operation_employee_id') : "",
            department_id: !!this.activatedRoute.snapshot.queryParamMap.get('department_id') ? +this.activatedRoute.snapshot.queryParamMap.get('department_id') : "",
            department_position_id: !!this.activatedRoute.snapshot.queryParamMap.get('department_position_id') ? +this.activatedRoute.snapshot.queryParamMap.get('department_position_id') : "",
            q: !!this.activatedRoute.snapshot.queryParamMap.get('q') ? this.activatedRoute.snapshot.queryParamMap.get('q') : "",
            time_start_utc_from: !!this.activatedRoute.snapshot.queryParamMap.get('time_start_utc_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('time_start_utc_from')*1000).utc() : "",
            time_start_utc_to: this.activatedRoute.snapshot.queryParamMap.get('time_start_utc_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('time_start_utc_to')*1000).utc() : "",
            order_by: !!this.activatedRoute.snapshot.queryParamMap.get('order_by') ? this.activatedRoute.snapshot.queryParamMap.get('order_by') : "time_start_utc_desc",
          })
          this.filterCount++;
        }),
        switchMap((val) => {
          if (this.company == undefined || this.company.id != this.activatedRoute.snapshot.queryParamMap.get('company_id')) {
            return this.companyService.getCompany(this.activatedRoute.snapshot.queryParamMap.get('company_id')).pipe(
              map(x => x[0]),
              tap(x => {
                this.company = x;
                this.me = this.company.employees[0];
                this.companyService.company$.next(x);
                if (!this.company.permissions.includes('owner') && !this.company.permissions.includes('admin')) {
                  this.minDate = moment(1654041600000)
                }
              }),
              map(x => val)
            )
          } else {
            return of(val)
          }
        }),
        debounceTime(300)
      ).subscribe(params => {
        console.log("route STREAM", params);

        this.statements = [];
        this.page = 1;
        
        this.getStatements(this.page);
      })
    )
  }


  getStatements(page) {
    console.log("getStatements FILTER", this.filter.value)
    this.isLoad = true;
    let filterData = {...this.filter.value}

    if (!!filterData.time_start_utc_from) {
      filterData.time_start_utc_from = filterData.time_start_utc_from.unix();
    } else {
      if (!this.company.permissions.includes('owner') && !this.company.permissions.includes('admin')) {
        filterData.time_start_utc_from = "empl"
      }
    }

    if (!!filterData.time_start_utc_to) {
      filterData.time_start_utc_to = filterData.time_start_utc_to.unix();
    }
    console.log("filterData111", filterData)

    this.attachSubscriptions(
      this.statementService.getStatements(page, this.company_id, filterData, !this.is_mobile ? '20' : '10').pipe(
        tap(el => {
          this.pagination = {
            'pageCount': el.headers.get('x-pagination-page-count'),
            'perPage': el.headers.get('x-pagination-per-page'),
            'totalCount': el.headers.get('x-pagination-total-count'),
            'currentPage': el.headers.get('x-pagination-current-page'),
          }
          if (this.pagination['currentPage'] == '1') {
            this.statements = [];
          }
        }),
        map(el => el.body),
        // tap(x => {
        //   x.forEach(val => {
        //     val.time_start = moment(val.time_start*1000).tz(this.company.timezone).unix()
        //     val.time_end = moment(val.time_end*1000).tz(this.company.timezone).unix()
        //   })
        // }),
        distinctUntilChanged()
      ).subscribe(resp => {
        resp.forEach(val => {
          val.summ = {
            earned_price: 0,
            earned_rate: 0,
            earned_salary: 0,
            pending_price: 0,
            pending_rate: 0,
            pending_salary: 0,
            returned_price: 0,
            returned_rate: 0,
            returned_salary: 0,
            returned: 0,
            check_price: 0,
            check_rate: 0,
            check_salary: 0,
            check: 0,
            change_price: 0,
            change_rate: 0,
            change_salary: 0,
            change: 0,
            record_price: 0,
            record_rate: 0,
            record_salary: 0,
            record: 0,
          }
          val.summaries.forEach(sum => {
            val.summ.earned_price += +sum.earned_price;
            val.summ.earned_rate += +sum.earned_rate;
            val.summ.earned_salary += +sum.earned_salary;
            val.summ.pending_price += +sum.pending_price;
            val.summ.pending_rate += +sum.pending_rate;
            val.summ.pending_salary += +sum.pending_salary;
            val.summ.returned_price += +sum.returned_price;
            val.summ.returned_rate += +sum.returned_rate;
            val.summ.returned_salary += +sum.returned_salary;
            val.summ.returned += +sum.returned;
            val.summ.check_price += +sum.check_price;
            val.summ.check_rate += +sum.check_rate;
            val.summ.check_salary += +sum.check_salary;
            val.summ.check += +sum.check;
            val.summ.change_price += +sum.change_price;
            val.summ.change_rate += +sum.change_rate;
            val.summ.change_salary += +sum.change_salary;
            val.summ.change += +sum.change;
            val.summ.record_price += +sum.record_price;
            val.summ.record_rate += +sum.record_rate;
            val.summ.record_salary += +sum.record_salary;
            val.summ.record +=  +sum.changeEarnings;
          })
        })
        this.statements.push(...resp);
  
        console.log("this.statements "+this.page, this.statements)
  
        this.page = this.page + 1;
        this.isLoad = false;
        if (+this.pagination['currentPage'] == 1) {
          this.statementsContainer.nativeElement.scrollTop = 0;
        }
  
        if (this.page <= 3 && +this.pagination['pageCount'] != 1 && this.page <= this.pagination['pageCount']) {
          this.getStatements(this.page);
        }
  
      },
      error => {
        this.isLoad = false;
      })
    )
    
  }

  getEmployee(id) {
    if (!this.employees) {
      return false;
    }
    return this.employees.find(el => el.id == id)
  }  

  getEmployees() {
    this.attachSubscriptions(
      this.taskService.getEmployeesDyn('1', this.company_id, null, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }
  
          return forkJoin(arr.map(x => this.taskService.getEmployeesDyn(x, this.company_id, null, '200').pipe(map(u => u.body)))).pipe(
            last(),
            tap(values => {
              let conValues = [].concat(...values)
              this.employees = conValues;
              this.employees$.next(this.employees.slice())
            }),
          )
        }),
      ).subscribe(resp => {
        console.log("getEmployees resp", resp);
        console.log("getEmployees", this.employees);
      })
    )
  }

  ngAfterViewInit(): void {
    this.onScroll();
  }

  onScroll() {
    this.attachSubscriptions(
      fromEvent(this.statementsContainer.nativeElement, "scroll").pipe(
        filter((e:any) => e.target.scrollTop >=  e.target.scrollHeight - e.target.offsetHeight - 400),
        debounceTime(300)
      ).subscribe(() => {
        if (this.page > 3 && this.page <= this.pagination['pageCount']) {
          this.getStatements(this.page);
        }
      })
    )
  }

  chosenYearHandler(normalizedYear: Moment) {
    // const ctrlValue = this.filter.value;
    // ctrlValue.year(normalizedYear.year());
    // this.filter.setValue(ctrlValue);
  }
  
  log() {
    console.log("this.filter", this.filter.value)
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    var endDate = normalizedMonth.clone().endOf('month');
    this.filter.patchValue({
      time_start_utc_from: normalizedMonth,
      time_start_utc_to: endDate
    })
    console.log(this.filter.value)
    this.filterStatements();
    datepicker.close();
  }

  selectChangeDep(e) {
    if (e.value == 'zero') {
      this.filter.patchValue({
        department_id: ""
      })
    }
    this.filterStatements();
  }

  selectChangePos(e) {
    if (e.value == 'zero') {
      this.filter.patchValue({
        department_position_id: ""
      })
    }
    this.filterStatements();
  }

  selectChange(e) {
    this.filterStatements();
  }

  // removeValue(val, id = null) {
  //   if (val == "all") {
  //     this.filterReset()
  //     return
  //   } else {
  //     if (val == 'time_start_utc_from') {
  //       this.filter.patchValue({
  //         time_start_utc_from: '',
  //         time_start_utc_to: ''
  //       })
  //     } else {
  //       this.filter.patchValue({
  //         [val]: ''
  //       })
  //     }

  //     this.filterStatements()
  //   }
    
  // }

  removeValue(val) {
    if (val == "all") {
      let params = {
        company_id: this.company_id,
        page: this.activatedRoute.snapshot.queryParamMap.get('page'),
        tab: this.activatedRoute.snapshot.queryParamMap.get('tab')
      }
      this.router.navigate(['/settings'], {queryParams: params})
    } else {
      let params = {...this.activatedRoute.queryParamMap.source['_value']}
      delete params[val]
      if (val == 'time_start_utc_from') {
        delete params['time_start_utc_to']
      }

      this.router.navigate(['/settings'], {queryParams: params})
    }
  }

  removeValueFromMultiple(key, value) {
    let arr = this.filter.value[key];

    console.log(key, value);
    console.log(arr);
    
    if (arr.includes(value)) {
      arr.splice(arr.indexOf(value), 1)
    }
    console.log(arr);
    console.log(this.filter.value[key]);
    // return 
    // this.filter.patchValue({
    //   [key]: arr
    // })
    this.filterData()
  }

  filterData() {
    let params = {
      company_id: this.company_id,
      page: this.activatedRoute.snapshot.queryParamMap.get('page'),
      tab: this.activatedRoute.snapshot.queryParamMap.get('tab')
    };

    Object.keys(this.filter.value).forEach(element => {
      if (!!this.filter.value[element]) {
        if (element == 'employee_id') {
          params['operation_employee_id'] = this.filter.value[element]
        } else {
          params[element] = this.filter.value[element]
        }
      }
    });

    this.router.navigate(['/settings'], { queryParams: params});
  }

  filterReset() {
    this.filter.patchValue({
      count: '',
      q: '',
      employee_id: "",
      department_id: "",
      department_position_id: "",
      time_start_utc_from : '',
      time_start_utc_to : '',
      order_by: 'time_start_utc_desc',
    })
    // this.router.navigate(['/statements'], { queryParams: {company_id: this.company_id, order_by: "time_start_utc_desc"}});
  }

  filterStatements(order_by = null, is_btn = false, counting: boolean = true) {
    // if (this.statementsSub) {
    //   this.statementsSub.unsubscribe()
    // }

    // if (counting) {
    //   this.filterCount = this.filterCount + 1;
    //   this.filter.value.count = this.filterCount;
    // }
    
    // let this.filter.value = this.filter.value;

    // if (!!this.filter.value.time_start_utc_from) {
    //   this.filter.value.time_start_utc_from = this.filter.value.time_start_utc_from.unix();
    // }
    // if (!!this.filter.value.time_start_utc_to) {
    //   this.filter.value.time_start_utc_to = this.filter.value.time_start_utc_to.unix();
    // }


    // if (!!order_by || order_by == "0") {
    //   filterData.order_by = order_by
    // }

 
    let params = {
      company_id: this.company_id,
      tab: 'statements',
      page: 'finances'
    };

    Object.keys(this.filter.value).forEach(element => {
      if (this.filter.value[element] != '0' && this.filter.value[element] != '') {
        params[element] = this.filter.value[element]
      }
    });

    this.router.navigate(['/settings'], { queryParams: params});

    // if (this.is_mobile) {
    //   this.layoutService.overflowAuto();
    //   if (this.mobile_filter_wrp) {
    //     this.mobile_filter_wrp.nativeElement.classList.remove("active");
    //   }
    // }
  }

  openStatement(statement) {
    const dialogRef = this.dialog.open(StatementComponent, {
      panelClass: 'statement_dialog',
      data: {
        statement: statement,
        company: this.company,
        user: this.user,
        imgRoute: this.imgRoute,
        host: this.host,
        activeLang: this.activeLang,
        is_mobile: this.is_mobile
      }
    });
  }

  getLangData(id) {
    this.attachSubscriptions(
      this.languageService.getLangData().pipe(
        tap(resp => {
          this.activeLang = resp.active;
          this._adapter.setLocale(resp.active);
          this.currMonthName = moment().format('MMMM');
        })
      ).subscribe(resp => {
        console.log("getOperations", resp)
      })
    )
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  getUser() {
    this.attachSubscriptions(
      this.auth.$user.subscribe(resp => {
        this.user = resp;
      })
    )
  }

  checkIsMobile() {
    if (window.innerWidth <= 769) {
      this.is_mobile = true;
    } else {
      this.is_mobile = false;
    }
    this.onResize();
  }

  onResize() {
    this.attachSubscriptions(
      fromEvent(window, "resize").pipe(
        map(() => window.innerWidth)
      ).subscribe((wWidth) => {
          if (wWidth <= 769) {
            this.is_mobile = true;
          } else {
            this.is_mobile = false;
          }
        }
      )
    )
  }
  
  ngOnDestroy(): void {
    if (this.statementsSub) {
      this.statementsSub.unsubscribe();
    }
    
    this.clearSubscriptions();
  }

}
