<h2 mat-dialog-title class="dialog_title">
    <span>
        {{ "Speech to Text" | translate }}
    </span>
    <button mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h2>
<mat-dialog-content class="mat-typography">
    <div class="container">
        <div class="speech_to_text" [appDeviceClass]="'custom-scroll custom-scroll-t'">
            <p class="text">

                <ng-container *ngIf="data?.file?.fileOriginalData?.is_rev_transcription_done != 1; else elseTemplateNoTranscribe">
                    {{ "Click 'Speech to Text' to get the text" | translate }}
                </ng-container>
                <ng-template #elseTemplateNoTranscribe>      
                    <ng-container *ngIf="data?.file?.fileOriginalData?.transcription_plain; else elseTemplateBody">
                        {{data?.file?.fileOriginalData?.transcription_plain}}
                    </ng-container>
                    <ng-template #elseTemplateBody>
                        {{ "In process" | translate }}
                    </ng-template>
                </ng-template>
            </p>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-raised-button (click)="close()">
        {{ "Close" | translate }}
    </button>
    <ng-container *ngIf="data?.file?.fileOriginalData?.is_rev_transcription_done == 1; else elseTemplateBtns">
        <button class="ml-1" (click)="getFile()" mat-raised-button color="primary">
            {{ "Refresh File" | translate }}
        </button>
        
        <button *ngIf="data?.file?.fileOriginalData?.transcription_plain" class="ml-1" ngxClipboard [cbContent]="data?.file?.fileOriginalData?.transcription_plain" (cbOnSuccess)="copySpeech('folder')" mat-raised-button color="primary">
            {{ "Copy To Clipboard" | translate }}
        </button>
    </ng-container>
    <ng-template #elseTemplateBtns>
        <button class="ml-1" (click)="transcribeFile()" mat-raised-button cdkFocusInitial color="primary">
            {{ "Speech to Text" | translate }}
        </button>
    </ng-template>
</mat-dialog-actions>