import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { debounceTime, map, switchMap } from 'rxjs/operators';
import { BaseClass } from 'src/app/shared/models/base-class';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { RefreshService } from 'src/app/shared/services/rest/refresh.service';
import { RootService } from 'src/app/shared/services/rest/root.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';

@Component({
  selector: 'app-edit-bookmark',
  templateUrl: './edit-bookmark.component.html',
  styleUrls: ['./edit-bookmark.component.scss']
})

export class EditBookmarkComponent extends BaseClass implements OnInit, OnDestroy {
  public isSubmit: boolean = false;
  public filters: boolean = false;
  public submited: boolean = false;
  public switched: boolean = false;

  public is_active: FormControl = new FormControl(false);
  public is_shortcut: FormControl = new FormControl(false);

  // employee_id: [[this.data.company.employees[0].id]],
  public form: FormGroup = this.fb.group({
    name: [this.data.bookmark.name, Validators.required],
    order: this.data.bookmark.order
  })
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private rootService: RootService,
    private companyService: CompanyService,
    private refreshService: RefreshService,
    private taskService: TaskService,
    private sm: StorageManagerService,
    public dialogRef: MatDialogRef<EditBookmarkComponent>,
    private fb: FormBuilder,
  ) { super() }

  ngOnInit(): void {
    this.dialogRef.addPanelClass("create_task_by_manager_modal")
    console.log("EditBookmarkComponent", this.data)
    
    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        this.close();
      })
    )
  }

  close() {
    this.dialogRef.close({event: 'close'});
  }

  save() {
    if (!this.form.valid) {
      this.submited = true;
      console.log(this.form);
      return
    }
    this.submited = false;
    this.isSubmit = true;
    
    let addData = {...this.form.value}

    this.attachSubscriptions(
      (this.data.is_root ? this.rootService.editBookmark(this.data.bookmark.id, this.form.value) : this.companyService.editBookmark(this.data.bookmark.id, this.form.value)).subscribe(res => {
        console.log("addBookmark", res)
        this.isSubmit = false;
        this.dialogRef.close({event: 'edit', data: res});
      })
    )

  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

}
