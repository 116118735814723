import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { mimeTypes } from 'mime-wrapper';
import * as moment from 'moment';
import { forkJoin, of, ReplaySubject } from 'rxjs';
import { debounceTime, last, map, switchMap, tap } from 'rxjs/operators';
import { makeArray } from 'src/app/shared/functions/objToArray';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { BaseClass } from 'src/app/shared/models/base-class';
import { GlobalDataService } from 'src/app/shared/services/common/global-data.service';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { AuthService } from 'src/app/shared/services/rest/auth.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { FileService } from 'src/app/shared/services/rest/file.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { UploadService } from 'src/app/shared/services/rest/upload.service';
import { environment } from 'src/environments/environment';
import { MY_FORMATS } from '../../../../atTasksDialog/task-profile-add/task-profile-add.component';

@Component({
  selector: 'app-add-payment',
  templateUrl: './add-payment.component.html',
  styleUrls: ['./add-payment.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class AddPaymentComponent extends BaseClass implements OnInit, OnDestroy {
  public host: any = environment.host;
  public imgRoute: any = '';
  public user: any = {};
  public user_id: number;
  public form: FormGroup;
  public company_id: any;
  public currencyList: any;
  public isSubmit: boolean = false;
  public isFormChange: boolean = false;
  public isSelectPartner: boolean = false;
  public activeLang: any;
  public statuses: any;
  public paymentSystems: any;
  public numberRegEx = /\d*\.?\d{1,2}/;
  public uploadLimit: boolean = false;
  public dropOver: boolean = false;
  public filesArray: any[] = []

  public partners: any;
  public partnersControl: FormControl = new FormControl();
  public partners$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public employees: any;
  public employeesControl: FormControl = new FormControl();
  public employees$: ReplaySubject<any> = new ReplaySubject<any>(1);
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddPaymentComponent>,
    private layoutService: LayoutService,
    private sm: StorageManagerService,
    private auth: AuthService,
    private companyService: CompanyService,
    private fb: FormBuilder,
    private globalDataService: GlobalDataService,
    private taskService: TaskService,
    private fileService: FileService,
    private uploadService: UploadService,
    private languageService: LanguageService,
    private _adapter: DateAdapter<any>,
  ) { super() }

  ngOnInit(): void {
    console.log('AddPaymentComponent', this.data);

    this.form = this.fb.group({
      employee_id: '',
      partner_company_id: '',
      payment_system_id: ['', Validators.required],
      currency_id: ['', Validators.required],
      comment: '',
      paid_at: '',
      real_paid_at: '',
      amount: [0, [Validators.required, Validators.pattern(this.numberRegEx)]],
    })

    this.attachSubscriptions(
      this.form.valueChanges.subscribe(() => this.isFormChange = true)
    )

    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        if (this.isFormChange) {
          this.layoutService.openBottomSheet(this.dialogRef);
        } else {
          this.close();
        }
      })
    )

    this.attachSubscriptions(
      this.uploadService.getUploadLimit().subscribe(resp => {
        if (resp || this.data.company.filesize >= this.data.company.filesize_limit) {
          this.uploadLimit = true;
        }
      })
    )

    this.attachSubscriptions(
      this.partnersControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchPartners(resp))
    )

    this.attachSubscriptions(
      this.employeesControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchEmployees(resp))
    )

    this.getPartners();
    this.getImgRoute();
    this.getCsrf();
    this.getUser();

    this.getEmployees();
    this.getPaymentSystem();
    this.getCurrencyList();
    this.getLangData()
  }

  getEmployees() {
    this.attachSubscriptions(
      this.taskService.getEmployeesDyn('1', this.data.company_id, null, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }
  
          return forkJoin(arr.map(x => this.taskService.getEmployeesDyn(x, this.data.company_id, null, '200').pipe(map(u => u.body)))).pipe(
            last(),
            tap(values => {
              let conValues = [].concat(...values)
              this.employees = conValues;
              this.employees$.next(this.employees.slice())
            }),
          )
        }),
      ).subscribe(resp => {
        console.log("getEmployees resp", resp);
        console.log("getEmployees", this.employees);
      })
    )
  }
  
  onSearchEmployees(resp) {
    if (!this.employees) {
      return;
    }

    if (!resp) {
      this.employees$.next(this.employees.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.employees$.next(
      this.employees.filter(z => z.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  onSearchPartners(resp) {
    if (!this.partners) {
      return;
    }

    if (!resp) {
      this.partners$.next(this.partners.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.partners$.next(
      this.partners.filter(z => z.partnerCompany && z.partnerCompany.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  selectPartner(val) {
    this.isSelectPartner = val
    if (val) {
      this.form.patchValue({
        employee_id: ''
      })
    } else {
      this.form.patchValue({
        partner_company_id: ''
      })
    }
  }

  getCurrencyList() {
    this.attachSubscriptions(
      this.globalDataService.getCurrencies().subscribe(resp => {
        console.log("getCurrencyList", resp)
        this.currencyList = resp.slice();
      })
    )
    // this.attachSubscriptions(
    //   this.companyService.getCurrencyList().subscribe(resp => {
    //     console.log("getCurrencyList", resp);
    //     this.currencyList = resp
    //   })
    // )
  }

  deleteFile(ind) {
    this.filesArray.splice(ind, 1)
  }

  getFilesDataTransferItems(dataTransferItems) {
    function traverseFileTreePromise(item, folder) {
      return new Promise(resolve => {
        if (item.isFile) {
          item.file(file => {
            file.filepath = item.fullPath.replace(`/${file.name}`, "");
            folder.push(file);
            resolve(file);
          });
        } else if (item.isDirectory) {
          let dirReader = item.createReader();
          dirReader.readEntries(entries => {
            let entriesPromises = [];
            for (let entr of entries)
              entriesPromises.push(
                traverseFileTreePromise(entr, folder)
              );
            resolve(Promise.all(entriesPromises));
          });
        }
      });
    }
  
    let files = [];
    return new Promise((resolve, reject) => {
      let entriesPromises = [];
      for (let it of dataTransferItems)
        entriesPromises.push(
          traverseFileTreePromise(it.webkitGetAsEntry(), files)
        );
      Promise.all(entriesPromises).then(entries => {
        resolve(files);
      });
    });
  }

  detectBrowserName() { 
    const agent = window.navigator.userAgent.toLowerCase()
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }

  understandFileType(val) {
    if (!val) {
      return ""
    }
    
    if (val == 'application/pdf') {
      return 'pdf'
    } else if (val.indexOf("/") != -1) {
      return val.split('/')[0]
    } else {
      return ""
    }
  }

  uploadFile(event) {
    if (this.uploadLimit) {
      this.layoutService.showSnackBar({name: ''}, marker("This company has exceeded the file upload limit."), SnackBarItem)
      return
    }
    if (event.target.files.length > 0) {
      let files = makeArray(event.target.files)
      if (files.length > 0) {
        console.log("uploadFile", files)
        files.forEach(el => {
          const reader = new FileReader();
          reader.onload = () => {
            el.src = reader.result as string;
          }
          reader.readAsDataURL(el)
        })
        this.filesArray.push(...files)
      }
    } 
  }

  onFileDropped(e) {
    this.dropOver = false;
    if (this.uploadLimit) {
      this.layoutService.showSnackBar({name: ''}, marker("This company has exceeded the file upload limit."), SnackBarItem)
      return
    }
    if (!e.dataTransfer.files.length) {
      return false;
    }

    this.getFilesDataTransferItems(e.dataTransfer.items).then((files:any) => {
      if (files.length > 0) {
        console.log("onFileDropped", files);
        files.forEach(el => {
          const reader = new FileReader();
          reader.onload = () => {
            el.src = reader.result as string;
          }
          reader.readAsDataURL(el)
        })

        this.filesArray.push(...files)
      }
    });
    
    if (this.data.company.filesize >= this.data.company.filesize_limit) {
      this.uploadService.uploadLimit$.next(true)
    }
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData().subscribe(resp => {
        this.activeLang = resp.active;
        this._adapter.setLocale(resp.active);
      })
    )
  }

  getPaymentSystem() {
    this.attachSubscriptions(
      this.companyService.getPaymentSystem().subscribe(resp => {
        console.log("getPaymentSystem", resp);
        this.paymentSystems = resp
      })
    )
  }

  getCsrf() {
    this.attachSubscriptions(
      this.auth.$userToken.subscribe(resp => {
        this.user_id = resp.user_id;
        this.sm.localStorageSetItem("csrf_param", resp.csrf_param)
        this.sm.localStorageSetItem("csrf_token", resp.csrf_token)
      })
    )
  }

  getUser() {
    this.attachSubscriptions(
      this.auth.$user.subscribe(resp => {
        this.user = resp;
      })
    )
  }

  // getPartners() {
  //   this.attachSubscriptions(
  //     this.companyService.getPartners({company_id: this.data.company_id}).subscribe(resp => {
  //       console.log("getPartners", resp)
  //       this.partners = resp.filter(x => x.partner_company_id != 0);
  //       this.partners$.next(this.partners.slice())
  //     })
  //   )
  // }

  getPartners() {
    this.attachSubscriptions(
      this.companyService.getPartnersDyn('1', this.data.company_id, null, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }
  
          return forkJoin(arr.map(x => this.companyService.getPartnersDyn(x, this.data.company_id, null, '200').pipe(map(u => u.body)))).pipe(
            last(),
            tap(values => {
              let conValues = [].concat(...values)
              this.partners = conValues;
              this.partners$.next(this.partners.slice())
            }),
          )
        }),
      ).subscribe(resp => {
        console.log("getpartners resp", resp);
        console.log("getpartners", this.partners);
      })
    )
  }

  onSubmit() {
    this.isSubmit = true;
    if (!this.form.value.partner_company_id && !this.form.value.employee_id) {
      this.layoutService.showSnackBar({name: ''}, marker('You need to select Stakeholder'), SnackBarItem)
      this.isSubmit = false
      return
    }
    if (String(this.form.value['amount']).indexOf(",") != -1) {
      this.form.value['amount'] = this.form.value['amount'].replace(",", ".");
    }

    if (!!this.form.value.paid_at && !!this.form.value.paid_at._d) {
      this.form.value.paid_at = moment(this.form.value.paid_at._d).format("X");
    } else {
      delete this.form.value.paid_at;
    }
    
    if (!!this.form.value.real_paid_at && !!this.form.value.real_paid_at._d) {
      this.form.value.real_paid_at = moment(this.form.value.real_paid_at._d).format("X");
    } else {
      delete this.form.value.real_paid_at;
    }

    let sbmtData = {...this.form.value}

    if (!!sbmtData.employee_id) {
      delete sbmtData.partner_company_id
    } else {
      delete sbmtData.employee_id
    }
    
    this.attachSubscriptions(
      this.companyService.addPayment(sbmtData, this.data.company_id).pipe(
        switchMap(res => {
          if (this.filesArray.length > 0) {
            return forkJoin([...this.filesArray.map((param) => this.putFile(param, res.id))]).pipe(
              map(() => res)
            )
          } else {
            return of(res)
          }
        })
      ).subscribe(resp => {
        this.isSubmit = false;
        this.dialogRef.close({event: "update", data: resp});
      }, error => {
        this.isSubmit = false;
        this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
      })
    )
  }

  putFile(file, payment_id) {
    return this.fileService.uploadFile({
      company_id: this.data.company.id,
      payment_id: payment_id,
      filesize: file.size,
      filename: file.name,
      content_type: file.type ? file.type : mimeTypes.getType(file.name),
      is_dir: 0
    }, this.data.company.id).pipe(
      tap(resp => {
        this.fileService.files$.next({
          place: "payments",
          url: window.location.href,
          data: resp,
          files: [],
          target: file,
          company_id: this.data.company.id,
          company: this.data.company,
          user: this.data.user
        })
      })
    )
    
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  ngOnDestroy(): void {
    this.clearSubscriptions();
  }

}
