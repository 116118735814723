<mat-dialog-content class="mat-typography">
    <div class="container">
        <div class="title_modal d-f-c">
            <span class="" (click)="log()">
                {{ "Channels to Group" | translate }}:
            </span>
            <span class="ml-1">{{data?.group?.name}}</span>
        </div>

        <div class="outlet_set">
            <div class="outlet_selected">
                <div class="opened_trigger" (click)="opened = !opened">
                    <div class="label" [ngClass]="{'selected': form.get('channel_id').value}">
                        {{ "Choose a channel" | translate }}
                    </div>
                    <ng-container *ngIf="form.get('channel_id').value">
                        <app-outlet-line [channel]="getProfileById(form.get('channel_id').value)" [company_id]="data?.company.id"></app-outlet-line>
                    </ng-container>
                    <mat-icon>arrow_drop_down</mat-icon>
                </div>
                <button class="suffix_button" [ngStyle]="{'color': (!form.get('channel_id').value || isSubmit) ? '#686868' : '#4E844E'}" [disabled]="!form.get('channel_id').value || isSubmit" (click)="onSubmit()" mat-icon-button>
                    <mat-icon>add_circle</mat-icon>
                </button>
            </div>
            <app-outlet-filter *ngIf="platforms && opened"
                [company]="data.company" 
                [company_id]="data.company.id" 
                [multiple]="false" 
                [n_a]="false" 
                [needCst]="true" 
                [form]="form" 
                [profiles]="false" 
                [outletKey]="'channel_id'" 
                [activeSections]="''" 
                [platforms]="platforms" 
                [showOutletBtn]="false" 
                (SelectChange)="selectChange($event)"
                (SelectOutletTab)="setOutletKey($event)">
            </app-outlet-filter>
        </div>
        
        <form [formGroup]="form" class="form_with_pads">
            
            <!-- <mat-form-field appearance="legacy" class="full_width_form h_cst_t">
                <mat-label>{{ "Choose a channel" | translate }}</mat-label>
                <mat-select [disableOptionCentering]="true" selectDir (infiniteScroll)="getNextProfiles()" formControlName="channel_id">
                    <mat-select-trigger>
                        <app-outlet-line [channel]="getProfileById(form.get('channel_id').value)" [company_id]="data?.company.id"></app-outlet-line>
                    </mat-select-trigger>
                    <mat-option>
                        <ngx-mat-select-search 
                            placeholderLabel="{{ 'Search' | translate }}" 
                            noEntriesFoundLabel="{{ 'no matching channel found' | translate }}"
                            [formControl]="profilesMoreControl"></ngx-mat-select-search>
                    </mat-option>   
                    <mat-option *ngFor="let profile of profiles$ | async" [value]="profile.id" class="cst_o">
                        <div class="d-f-c" style="justify-content: space-between; width: 100%;">
                            <app-outlet-line [channel]="profile" [company_id]="data?.company.id"></app-outlet-line>
                        </div>
                    </mat-option>
                </mat-select>
        
                <button class="suffix_button" [ngStyle]="{'color': (!form.get('channel_id').value || isSubmit) ? '#686868' : '#4E844E'}" [disabled]="!form.get('channel_id').value || isSubmit" type="submit" (click)="$event.stopPropagation()" mat-icon-button matSuffix>
                    <mat-icon>add_circle</mat-icon>
                </button>
            </mat-form-field> -->

            <div class="small_chips" *ngIf="!!form.get('channel_id').value">
                <label>{{ "Content type" | translate }}: </label>
                <mat-chip-list [multiple]="false" selectable formControlName="content_type_id">
                    <ng-container *ngFor="let type of contentTypes; let i = index">
                        <mat-chip *ngIf="type.id != 203" class="small_chip" (click)="selectType(type)" [value]="type.id">
                            {{ type.name | translate }}
                        </mat-chip>
                    </ng-container>
                    
                </mat-chip-list>
            </div>

        </form>

        <mat-divider *ngIf="channelsToGroup && channelsToGroup.length"></mat-divider>

        <mat-nav-list class="scrolled_channels" [appDeviceClass]="'custom-scroll custom-scroll-t'">
            <mat-list-item *ngFor="let chToGroup of channelsToGroup; let i = index;" class="data_out_item">
                <div class="data_item">
                    <div class="data_item_info">
                        <div class="channel">
                            <div class="d-f-c" style="justify-content: space-between; width: 100%;">
                                <app-outlet-line [channel]="chToGroup?.channel" [company_id]="data?.company.id"></app-outlet-line>

                                <div class="type" style="margin-left: 30px;">
                                    <ng-container *ngIf="!!chToGroup.content_type_id; else elseTemplateHasType">
                                        {{ chToGroup.contentType.name | translate }}
                                    </ng-container>
                                    <ng-template #elseTemplateHasType>
                                        {{ "All types" | translate }}
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                    </div>
                    <mat-icon style="color: red;" (click)="deleteChannelToGroup(chToGroup.id, i)">delete</mat-icon>
                </div>
            </mat-list-item>
        </mat-nav-list>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <div class="f_w_p_btns">
        <button mat-raised-button (click)="close()">
            {{ "Close" | translate }}
        </button>
    </div>
</mat-dialog-actions>

  
