import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-platform',
  templateUrl: './platform.component.html',
  styleUrls: ['./platform.component.scss']
})
export class PlatformComponent implements OnInit {

  public first_id: number;
  public second_id: number;
  public third_id: number;
  @Input() platform_id: any;
  constructor() { }

  ngOnInit(): void {
    function getRandomInt(max) {
      return Math.floor(Math.random() * max);
    }
    this.first_id = getRandomInt(999999999)
    this.second_id = getRandomInt(999999999)
    this.third_id = getRandomInt(999999999)
  }
}
