import { Component, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { forkJoin, of, Subject, Subscription } from 'rxjs';
import { last, map, switchMap, tap } from 'rxjs/operators';
import { OperationChatComponent } from 'src/app/components/atTasksDialog/operation-chat/operation-chat.component';
import { casesModel } from 'src/app/shared/functions/casesModel';
import { DeleteAlertComponent } from 'src/app/shared/global_components/delete-alert/delete-alert.component';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { ChatService } from 'src/app/shared/services/rest/chat.service';
import { MembersService } from 'src/app/shared/services/rest/members.service';
import { RefreshService } from 'src/app/shared/services/rest/refresh.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { environment } from 'src/environments/environment';
import { TaskManagerAddComponent } from '../managers/task-manager-add/task-manager-add.component';
import { SetEmplComponent } from '../members/set-empl/set-empl.component';
import { EditChatAssStatusComponent } from './dialogs/edit-chat-ass-status/edit-chat-ass-status.component';
import { EditChatStatusComponent } from './dialogs/edit-chat-status/edit-chat-status.component';
import { EditCompletedAtComponent } from './dialogs/edit-completed-at/edit-completed-at.component';
import { EditExecutorComponent } from './dialogs/edit-executor/edit-executor.component';
import { EditPriceComponent } from './dialogs/edit-price/edit-price.component';
import { EditStartAtComponent } from './dialogs/edit-start-at/edit-start-at.component';
import { EditEmplStatusComponent } from './dialogs/edit-status/edit-status.component';
import { EditTypeComponent } from './dialogs/edit-type/edit-type.component';
import { TaskAddClientComponent } from 'src/app/components/clients/task-add-client/task-add-client.component';

@Component({
  selector: 'app-executors',
  templateUrl: './executors.component.html',
  styleUrls: ['./executors.component.scss']
})
export class ExecutorsComponent extends BaseClass implements OnInit, OnDestroy, OnChanges {
  public taskManagers: any;
  public statuses: any;
  public selectedExec: any;
  public clients: any[] = [];
  public isEndChatAss: boolean = false;
  public imgRoute: string = '';
  public host: any = environment.host;
  public addItemSub: Subscription;
  @Input() initData: any = false;
  @Input() addItem: Subject<boolean>;
  @Output() Update = new EventEmitter();
  @Output() ActUser = new EventEmitter();
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private taskService: TaskService,
    public dialogRef: MatDialogRef<ExecutorsComponent>,
    private dialog: MatDialog,
    private bottomSheet: MatBottomSheet,
    private refreshService: RefreshService,
    private membersService: MembersService,
    private chatService: ChatService,
    private layoutService: LayoutService,
    private sm: StorageManagerService
  ) { super() }

  ngOnInit(): void {
    if (this.initData) {
      this.data = this.initData
      console.log("ExecutorsComponent", this.initData)
      if (this.addItemSub) {
        this.addItemSub.unsubscribe()
      }
  
      this.addItemSub = this.addItem.subscribe(() => {
        this.addExecutor(this.data.job)
      })
    }
    console.log("ExecutorsComponent", this.data)
    if (this.data.task.company_id != this.data.company.id && !!this.data.job.partnerCompanies.find(b => b.company_id == this.data.task.company_id)) {
      this.data.job.partnerCompanies.find(b => b.company_id == this.data.task.company_id).employees = this.data.job.employees.filter(x => x.is_partner_employee)
    }
    
    this.getOperationsStatus();
    this.getClients();
    this.getMembers();
    this.getChatAssistsStatuses()
    this.getImgRoute();
    if (this.data.chat) {
      this.data.job.employees.forEach(el => {
        if (!el.discussion_id) {
          this.toggleChats(el);
        }
      })
    }

    this.attachSubscriptions(
      this.refreshService.getTaskCard().subscribe(resp => {
        if (resp.company_id == this.data.company.id && this.data.task.id == resp.task_id) {
          this.workAfterClosed()
        }
      })
    )

    this.attachSubscriptions(
      this.membersService.getAddingMobExec().subscribe(resp => {
        console.log("getAddingMobExec", resp)

        switch (resp) {
          case 'client':
            this.addClient()
            break;
          case 'partner_client':
            this.addPartnerClient()
            break;
          case 'executor':
            this.addExecutor(this.data.job)
            break;
          case 'assist':
            this.addExecutor(this.data.job, 0)
            break;
        
          default:
            break;
        }
      })
    )

    this.attachSubscriptions(
      this.membersService.getBackMobExec().subscribe(resp => {
        this.selectedExec = undefined;
        this.ActUser.next(this.selectedExec);
      })
    )
    this.attachSubscriptions(
      this.membersService.getDeleteMobExec().subscribe(resp => {
        if (this.selectedExec) {
          switch (this.selectedExec.type) {
            case 'executor user':
              this.deleteEmployee(this.selectedExec.exec, this.selectedExec.ind)
              break;
            case 'chat user':
              this.deleteEmployee(this.selectedExec.exec, this.selectedExec.ind)
              break;
            case 'chat workspace':
              this.deletePartner(this.selectedExec.exec, this.selectedExec.ind)
              break;
            case 'executor workspace':
              this.deletePartner(this.selectedExec.exec, this.selectedExec.ind)
              break;
            case 'client user':
              this.deleteClient(this.selectedExec.exec, this.selectedExec.ind)
              break;
            case 'client workspace':
              this.deleteClient(this.selectedExec.exec, this.selectedExec.ind)
              break;
            default:
              break;
          }
          this.selectedExec = undefined;
          this.ActUser.next(this.selectedExec);
        }
      })
    )
  }

  activeUser(exec, type, i) {
    this.selectedExec = {
      exec: exec,
      type: type,
      ind: i
    }

    if (type.indexOf("client") == -1) {
      this.toggleChats(exec);
    }
    this.ActUser.next(this.selectedExec);
  }

  changeType(exec) {
    exec.typeIsChanging = true;
    if (this.selectedExec) {
      this.selectedExec.exec.typeIsChanging = true
    }
    this.attachSubscriptions(
      (!!exec.is_partner ? this.membersService.editTaskPartner(exec.id, {is_manager: !!exec.is_manager ? 0 : 1}, this.data.company.id) : (!!exec.is_partner_employee ? this.membersService.editTaskPartnerEmployee(exec.id, {is_manager: !!exec.is_manager ? 0 : 1}, this.data.company.id) : this.membersService.editMember(exec.id, {is_manager: !!exec.is_manager ? 0 : 1}, this.data.company.id))).subscribe(resp => {
        this.workAfterClosed();
        if (this.selectedExec) {
          this.selectedExec.exec.is_manager = resp.is_manager
        }
        exec.is_manager = resp.is_manager
        exec.typeIsChanging = false;
        if (this.selectedExec) {
          this.selectedExec.exec.typeIsChanging = false
        }
      },
      error => {
        this.layoutService.showSnackBar({name: ''}, error, SnackBarItem);
        exec.typeIsChanging = false;
        if (this.selectedExec) {
          this.selectedExec.exec.typeIsChanging = false
        }
      })
    )
  }

  changePriceManager(jobEmpl) {
    jobEmpl.pricePermIsChanging = true;
    if (this.selectedExec) {
      this.selectedExec.exec.pricePermIsChanging = true
    }
    this.attachSubscriptions(
      (!!jobEmpl.is_partner ? this.membersService.editTaskPartner(jobEmpl.id, {is_price_manager: !!jobEmpl.is_price_manager ? 0 : 1}, this.data.company.id) : (!!jobEmpl.is_partner_employee ? this.membersService.editTaskPartnerEmployee(jobEmpl.id, {is_price_manager: !!jobEmpl.is_price_manager ? 0 : 1}, this.data.company.id) : this.membersService.editMember(jobEmpl.id, {is_price_manager: !!jobEmpl.is_price_manager ? 0 : 1}, this.data.company.id))).subscribe(resp => {
        jobEmpl.is_price_manager = !!jobEmpl.is_price_manager ? 0 : 1;
        jobEmpl.disabled = false;
        jobEmpl.pricePermIsChanging = false;
        if (this.selectedExec) {
          this.selectedExec.exec.pricePermIsChanging = false
        }
      },
      error => {
        this.showError();
        jobEmpl.pricePermIsChanging = false;
        if (this.selectedExec) {
          this.selectedExec.exec.pricePermIsChanging = false
        }
      })
    )
  }

  jobHasUserExec() {
    return this.data.job.employees.filter(jobEmpl => !jobEmpl.is_partner && !jobEmpl.is_partner_employee && (jobEmpl.discussion_id == 0 || !jobEmpl.discussion_id)).length
  }
  
  jobHasChatExec() {
    return this.data.job.employees.filter(jobEmpl => !jobEmpl.is_partner && !jobEmpl.is_partner_employee && !(jobEmpl.discussion_id == 0 || !jobEmpl.discussion_id)).length
  }

  jobHasChatPartn() {
    return this.data.job.partnerCompanies.filter(jobEmpl => !(jobEmpl.discussion_id == 0 || !jobEmpl.discussion_id)).length
  }

  jobHasNotChatPartn() {
    return this.data.job.partnerCompanies.filter(jobEmpl => (jobEmpl.discussion_id == 0 || !jobEmpl.discussion_id)).length
  }

  addClient() {
    const dialogRef = this.dialog.open(TaskAddClientComponent, {
      data: {
        company: this.data.company,
        company_id: this.data.company.id,
        task: this.data.task,
        work: this.data.job,
        chat: this.data.chat,
        user: this.data.user,
        clients: this.clients
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log(result)
        if (result.event == 'update') {
          this.getClients();
        }
      })
    )
  }

  addPartnerClient() {
    const dialogRef = this.dialog.open(TaskAddClientComponent, {
      data: {
        company: this.data.company,
        company_id: this.data.company.id,
        task: this.data.task,
        work: this.data.job,
        chat: this.data.chat,
        user: this.data.user,
        clients: this.clients,
        is_partner: true,
        imgRoute: this.imgRoute,
        host: this.host
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log(result)
        if (result.event == 'update') {
          this.getClients();
        }
      })
    )
  }

  getClients() {
    this.attachSubscriptions(
      this.taskService.getTaskClientsDyn('1', this.data.task.id, this.data.company.id, '1', {task_operation_id: `0,${this.data.job.id}`}).pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }
          return forkJoin(arr.map(x => this.taskService.getTaskClientsDyn(x, this.data.task.id, this.data.company.id, '200', {task_operation_id: `0,${this.data.job.id}`}).pipe(map(u => u.body)))).pipe(
            last(),
            tap(values => {
              let conValues = [].concat(...values);
              if (this.data.chat) {
                this.clients = conValues.filter(x => !x.discussion_id || x.discussion_id == this.data.chat.id);
              } else {
                this.clients = conValues;
              }
            }),
          )
        }),
      ).subscribe(resp => {
        console.log("getClients resp", resp);
        console.log("getClients clients", this.clients);
      })
    )
  }
  
  // getClients() {
  //   this.taskService.getTaskClients(this.data.task.id, this.data.company.id).subscribe(resp => {
  //     console.log("getClients resp", resp);
  //     if (this.data.chat) {
  //       this.clients = resp.filter(x => (!x.task_operation_id || x.task_operation_id == this.data.job.id) && (!x.discussion_id || x.discussion_id == this.data.chat.id));
  //     } else {
  //       this.clients = resp.filter(x => !x.task_operation_id || x.task_operation_id == this.data.job.id);
  //     }
  //     console.log("getClients this.clients", this.clients);
  //   })
  // }

  onPartnerImgError(event){
    event.target.src = this.imgRoute+'/assets/img/partner.png'
  }

  getStatus(id) {
    if (!this.statuses || this.statuses.filter(el => el.id == id).length == 0) {
      return '';
    }
    return this.statuses.find(el => el.id == id)
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.initData) {
      if (changes.initData && changes.initData.currentValue && changes.initData.previousValue && (changes.initData.currentValue.job.id != changes.initData.previousValue.job.id)) {
        this.ngOnInit()
      }
      console.log("ngOnChanges ExecutorsComponent",changes)
    }
  }

  togglePartner(e, client) {
    client.disabled = true
    this.taskService.editClientInTask(client.id, {is_principal: Number(e.checked)}, this.data.company.id).subscribe(resp => {
      client.is_principal = Number(e.checked);
      client.disabled = false;
    })
  }
  
  getOperationsStatus() {
    this.attachSubscriptions(
      this.taskService.getOperationsStatus().subscribe(resp => {
        this.statuses = resp.slice();
      })
    )
  }

  getChatAssistsStatuses() {
    if (this.data.job.employees.filter(x => !!x.discussion_id).length == 0) {
      return
    }

    this.attachSubscriptions(
      forkJoin(this.data.job.employees.filter(x => !x.is_partner && !x.is_partner_employee && !!x.discussion_id).map(t => this.membersService.getTaskEmployeeStatuses({task_operation_id: this.data.job.id, discussion_id: t.discussion_id}, this.data.company.id))).subscribe(resp => {
        console.log("getChatAssistsStatuses getTaskEmployeeStatuses", resp)
        this.isEndChatAss = true;
        resp.forEach((el : any) => {
          el.forEach(s => {
            if (this.data.job.employees.filter(x => x.employee_id == s.employee_id && x.task_operation_id == s.task_operation_id && x.discussion_id == s.discussion_id).length > 0) {
              this.data.job.employees.find(x => x.employee_id == s.employee_id && x.task_operation_id == s.task_operation_id && x.discussion_id == s.discussion_id).employeeStatus = s;
              this.data.job.employees.find(x => x.employee_id == s.employee_id && x.task_operation_id == s.task_operation_id && x.discussion_id == s.discussion_id).employeeStatus.status = this.data.statuses.find(b => b.id == s.status_id)
            }
          });
        })
      })
    )

    this.attachSubscriptions(
      forkJoin(this.data.job.employees.filter(x => x.is_partner && !!x.discussion_id).map(t => this.membersService.getTaskPartnerStatuses({task_operation_id: this.data.job.id, discussion_id: t.discussion_id}, this.data.company.id))).subscribe(resp => {
        console.log("getChatAssistsStatuses getTaskPartnerStatuses", resp)
        this.isEndChatAss = true;
        resp.forEach((el : any) => {
          el.forEach(s => {
            if (this.data.job.employees.filter(x => !x.partner_employee_id && x.partner_company_id == s.partner_company_id && x.task_operation_id == s.task_operation_id && x.discussion_id == s.discussion_id).length > 0) {
              this.data.job.employees.find(x => !x.partner_employee_id && x.partner_company_id == s.partner_company_id && x.task_operation_id == s.task_operation_id && x.discussion_id == s.discussion_id).partnerCompanyStatus = s;
              this.data.job.employees.find(x => !x.partner_employee_id && x.partner_company_id == s.partner_company_id && x.task_operation_id == s.task_operation_id && x.discussion_id == s.discussion_id).partnerCompanyStatus.status = this.data.statuses.find(b => b.id == s.status_id)
            }
          });
        })
      })
    )

    this.attachSubscriptions(
      forkJoin(this.data.job.employees.filter(x => x.is_partner_employee && !!x.discussion_id).map(t => this.membersService.getTaskPartnerEmployeeStatuses({company_id: this.data.company.id, partner_company_id: this.data.task.company_id, task_id: this.data.task.id, task_operation_id: this.data.job.id, discussion_id: t.discussion_id}, this.data.company.id))).subscribe(resp => {
        console.log("getChatAssistsStatuses getTaskPartnerEmployeeStatuses", resp)
        this.isEndChatAss = true;
        resp.forEach((el : any) => {
          el.forEach(s => {
            if (this.data.job.employees.filter(x => x.partner_employee_id == s.partner_employee_id && x.task_operation_id == s.task_operation_id && x.discussion_id == s.discussion_id).length > 0) {
              this.data.job.employees.find(x => x.partner_employee_id == s.partner_employee_id && x.task_operation_id == s.task_operation_id && x.discussion_id == s.discussion_id).employeeStatus = s;
              this.data.job.employees.find(x => x.partner_employee_id == s.partner_employee_id && x.task_operation_id == s.task_operation_id && x.discussion_id == s.discussion_id).employeeStatus.status = this.data.statuses.find(b => b.id == s.status_id)
            }
          });
        })
      })
    )
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  clData() {
    console.log(this.data)
  }

  toggleChats(empl) {
    console.log(empl)
    if (!!empl.opened) {
      empl.opened = false
    } else {
      empl.opened = true;
      let filter;
      if (empl.is_partner || empl.is_partner_employee) {
        filter = {partner_company_id: empl.partner_company_id, 'per-page': "200"}
      } else {
        filter = {employee_id: empl.employee_id, 'per-page': "200"}
      }
      if (this.data.chat) {
        filter['id'] = this.data.chat.id
      }
      filter.task_operation_id = this.data.job.id
      this.attachSubscriptions(
        this.chatService.getChats(this.data.company.id, this.data.job.task_id, filter).pipe(tap(element => {
          element.map(x => {
            if (empl.is_partner) {
              x.partnerCompanyStatus = undefined
              if (!x.partnerCompanyStatus && x.partnerCompaniesStatuses.filter(y => y.partner_company_id == empl.partner_company_id && y.task_operation_id == empl.task_operation_id).length > 0) {
                x.partnerCompanyStatus = x.partnerCompaniesStatuses.find(y => y.partner_company_id == empl.partner_company_id && y.task_operation_id == empl.task_operation_id)
              }
            } else if (empl.is_partner_employee) {
              console.log("set DISC STATUS", x.partnerEmployeesStatuses);
              console.log("set DISC STATUS", x.partnerEmployeesStatuses.filter(y => y.partner_employee_id == empl.partner_employee_id && y.task_operation_id == empl.task_operation_id));

              x.employeeStatus = undefined
              if (!x.employeeStatus && x.partnerEmployeesStatuses.filter(y => y.partner_employee_id == empl.partner_employee_id && y.task_operation_id == empl.task_operation_id).length > 0) {
                x.employeeStatus = x.partnerEmployeesStatuses.find(y => y.partner_employee_id == empl.partner_employee_id && y.task_operation_id == empl.task_operation_id)
              }
            } else {
              x.employeeStatus = undefined
              if (!x.employeeStatus && x.employeesStatuses.filter(y => y.employee_id == empl.employee_id && y.task_operation_id == empl.task_operation_id).length > 0) {
                x.employeeStatus = x.employeesStatuses.find(y => y.employee_id == empl.employee_id && y.task_operation_id == empl.task_operation_id)
              }
            }
          })
        })).subscribe(resp => {
          console.log("getChats", resp)
          empl.chats = resp;
          if (this.selectedExec) {
            this.selectedExec.exec.chats = resp;
          }
          console.log("getChats EMPLOYEE", empl)
          console.log("getChats EMPLOYEE DATA DATA DATA", this.data)
        })
      )
    }
  }

  getMembers() {
    let filter = {task_id: this.data.task.id, task_operation_id: this.data.job.id}

    // if (this.data.chat) {
    //   filter['discussion_id'] = this.data.chat.id
    // }

    let arr = [
      this.membersService.getMembers(this.data.company.id != this.data.task.company_id ? Object.assign({partner_company_id: this.data.task.company_id}, filter) : filter, this.data.company.id).pipe(
        tap(res => {
          res.forEach(el => {
            if (el.discussion_id == 0 || !el.discussion_id) {
              if (!!el.employeeStatus) {
                this.data.job.employees.find(x => x.id == el.id).employeeStatus = el.employeeStatus
              } 
            }
          })
        })
      ),
      this.membersService.getTaskPartners(this.data.company.id != this.data.task.company_id ? Object.assign({partner_company_id: this.data.task.company_id}, filter) : filter, this.data.company.id).pipe(
        tap(res => {
          res.forEach(el => {
            if ((el.discussion_id == 0 || !el.discussion_id) && el.partnerCompanyStatus) {
              this.data.job.employees.find(x => x.id == el.id).partnerCompanyStatus = el.partnerCompanyStatus
            }
          })
        })
      )
    ]
    if (this.data.company.id != this.data.task.company_id) {
      arr.push(
        this.membersService.getTaskPartnerEmployees(filter, this.data.company.id, this.data.task.company_id).pipe(
          tap(res => {
            console.log('res', res)
            console.log('this.data.job.employees', this.data.job.employees)
            res.forEach(el => {
              if ((el.discussion_id == 0 || !el.discussion_id) && el.partnerEmployeeStatus && this.data.job.employees.find(x => x.id == el.id)) {
                this.data.job.employees.find(x => x.id == el.id).employeeStatus = el.partnerEmployeeStatus
              }
            })
          })
        )
      )
    }
    this.attachSubscriptions(
      forkJoin(arr).subscribe(resp => {
        console.log("getMembers", resp)
      })
    )
  }

  getChatById(id) {
    if (!this.data.job || !this.data.job.chatsInfo || this.data.job.chatsInfo.filter(x => x.id == id).length == 0) {
      return
    }
    
    return this.data.job.chatsInfo.find(x => x.id == id)
  }

  goToChat(empl) {
    const goToChat = this.dialog.open(OperationChatComponent, {
      backdropClass: ['backdrop_under_header', "without_pad"],
      panelClass: ['full_screen_dialog', 'global_chats_dialog'],
      disableClose: true,
      data: {
        company: this.data.company,
        task: this.data.task,
        tasks: this.data.tasks,
        dialogType: 'operation_chat',
        operationsValues: this.data.operationsValues,
        work: this.data.job,
        user: this.data.user,
        empl_status: this.data.empl_status,
        chat: this.data.job.chatsInfo.find(x => x.id == empl.discussion_id),
        chats: this.data.job.chatsInfo
      }
    });

    this.attachSubscriptions(
      goToChat.afterClosed().subscribe(result => {
        if (!!result && result.event == 'close') {
          this.chatService.postChatRead(empl.discussion_id, 0, this.data.company.id).subscribe(resp => {
            console.log(resp);
          })
        }
      })
    )
  }

  showError() {
    this.layoutService.showSnackBar({name: ''}, marker("You do not have permission to do this"), SnackBarItem)
  }

  goToChatStatus(chat) {
    const goToChat = this.dialog.open(OperationChatComponent, {
      backdropClass: ['backdrop_under_header', "without_pad"],
      panelClass: ['full_screen_dialog', 'global_chats_dialog'],
      disableClose: true,
      data: {
        company: this.data.company,
        task: this.data.task,
        tasks: this.data.tasks,
        operationsValues: this.data.operationsValues,
        work: this.data.job,
        dialogType: 'operation_chat',
        user: this.data.user,
        empl_status: this.data.empl_status,
        chat: chat,
      }
    });

    this.attachSubscriptions(
      goToChat.afterClosed().subscribe(result => {
        if (!!result && result.event == 'close') {
          this.chatService.postChatRead(chat.id, 0, this.data.company.id).subscribe(resp => {
            console.log(resp);
          })
        }
      })
    )
  }
  
  getColumnName(id){
    if (!this.data.operationsValues || this.data.operationsValues.filter(el => el.id == id).length == 0) {
      return '';
    }
    return this.data.operationsValues.find(el => el.id == id) && this.data.operationsValues.find(el => el.id == id).translation ? this.data.operationsValues.find(el => el.id == id).translation.name : this.data.operationsValues.find(el => el.id == id).name
  }

  workAfterClosed() {
    this.attachSubscriptions(
      this.taskService.getOneTaskExpand(this.data.company.id, this.data.task.id).pipe(
        map(res => res.body[0]),
        tap(value => console.log("workAfterClosed TAP", value)),
        switchMap(val => this.chatService.getTasksChats(this.data.company.id, [this.data.task.id]).pipe(
          tap(b => {
            val['operations'].map(o_el => {
              o_el['chatsInfo'] = [];
              o_el['chatsInfo'].push(...b.filter(z => z.task_operation_id == o_el.id));
            });
            val['chatsInfo'] = [];
            val['chatsInfo'].push(...b.filter(z => z.task_id == b.id));
          }),
          map(() => val)
        ))
      ).subscribe(resp => {
        let data = casesModel([resp], [], 'update')

        // this.data.task = undefined;
        Object.keys(data.arr[0]).forEach(key => {
          this.data.task[key] = data.arr[0][key]
        })

        if (data['arr'][0].operations && data['arr'][0].operations.find(y => y.id == this.data.job.id)) {
          Object.keys(data['arr'][0].operations.find(y => y.id == this.data.job.id)).forEach(key => {
            if (this.data.chat && (key == 'employees' || key == "mainEmployees" || key == "addEmployee" || key == "partnerCompanies")) {
              this.data.job[key] = data['arr'][0].operations.find(y => y.id == this.data.job.id)[key].filter(x => x.discussion_id == this.data.chat.id || x.discussion_id == 0 || !x.discussion_id)
            } else {
              this.data.job[key] = data['arr'][0].operations.find(y => y.id == this.data.job.id)[key]
            }
          })
        }

        console.log(this.data.chat)
        this.getMembers();
        this.getChatAssistsStatuses();
        
        this.data.job.employees.forEach(el => {
          if (!el.discussion_id && !!el.opened) {
            el.opened = false;
            this.toggleChats(el);
          }
        })
        
        if (this.initData) {
          this.Update.emit()
        }
        this.sort();
        if (this.data.task.company_id != this.data.company.id && !!this.data.job.partnerCompanies.find(b => b.company_id == this.data.task.company_id)) {
          this.data.job.partnerCompanies.find(b => b.company_id == this.data.task.company_id).employees = this.data.job.employees.filter(x => x.is_partner_employee)
        }
      })
    )
  }

  setToOrderBoard(empl, jobEmpl?) {
    let setData = {
      company: this.data.company,
      user: this.data.user,
      taskManagers: this.taskManagers,
      task: this.data.task,
      employee: empl,
      empl_id: jobEmpl ? jobEmpl.employee_id : undefined,
      empl_status: this.data.empl_status,
      work: this.data.job,
      chat_id: jobEmpl ? empl.id : undefined,
      jobEmpl: jobEmpl
    }
    let submData:any = {
      start_at: 1,
      initial_start_at: 1
    };

    if ((setData?.jobEmpl && !!setData?.jobEmpl?.is_partner_employee) || !!setData?.employee?.is_partner_employee) {
      if (!!setData?.employee?.employeeStatus?.id) {
        this.attachSubscriptions(
          this.membersService.editPartnerEmployeeStatus(setData.employee.employeeStatus.id, submData, setData.company.id).subscribe(resp => {
            this.workAfterClosed()
          },
          error => {
            this.showError()
            this.workAfterClosed()
          })
        )
      } else {
        if (!!setData.chat_id) {
          submData['discussion_id'] = setData.chat_id
        }
        this.attachSubscriptions(
          this.membersService.addPartnerEmployeeStatus(Object.assign(submData, {
              company_id: setData.company.id,
              task_id: setData.task.id,
              task_operation_id: setData.work.id,
              partner_company_id: setData.employee.partner_company_id,
              partner_employee_id: setData.employee.partner_employee_id,
              status_id: setData.work.status_id
            }), setData.company.id).subscribe(resp => {
            this.workAfterClosed()
          },
          error => {
            this.showError()
            this.workAfterClosed()
          })
        )
      }
    } else {
      if (!!setData?.employee?.employeeStatus?.id) {
        this.attachSubscriptions(
          this.membersService.editTaskEmployeeStatus(setData.employee.employeeStatus.id, submData, this.data.company.id).subscribe(resp => {
            this.workAfterClosed()
          },
          error => {
            this.showError()
            this.workAfterClosed()
          })
        )
      } else {
        if (!!setData.chat_id) {
          submData['discussion_id'] = setData.chat_id
        }
        this.attachSubscriptions(
          this.membersService.addTaskEmployeeStatus(Object.assign(submData, {
              company_id: setData.company.id,
              task_id: setData.task.id,
              task_operation_id: setData.work.id,
              employee_id: !!setData.empl_id ? setData.empl_id : setData.employee.employee_id,
              status_id: setData.work.status_id
            }), this.data.company.id).subscribe(resp => {
            this.workAfterClosed()
          },
          error => {
            this.showError()
            this.workAfterClosed()
          })
        )
      }
    }
  }

  setStartAt(empl, jobEmpl?) {
    const dialogRef = this.dialog.open(EditStartAtComponent, {
      data: {
        company: this.data.company,
        user: this.data.user,
        taskManagers: this.taskManagers,
        task: this.data.task,
        employee: empl,
        empl_id: jobEmpl ? jobEmpl.employee_id : undefined,
        empl_status: this.data.empl_status,
        work: this.data.job,
        chat_id: jobEmpl ? empl.id : undefined,
        jobEmpl: jobEmpl,
        showError: () => { this.showError() }
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (result && result.event == "update") {
          this.workAfterClosed()
        }
      })
    )
  }

  sort() {
    switch (this.data.sortValue) {
      case 'priority_desc':
        this.data.task.operations.sort((a,b) => {
          return b.priority - a.priority;
        })
        break;
      case 'priority':
        this.data.task.operations.sort((a,b) => {
          return a.priority - b.priority;
        })
        break;
      case 'updated_desc':
        this.data.task.operations.sort((a,b) => {
          return +b.updated_at - +a.updated_at;
        })
        break;
      case 'updated':
        this.data.task.operations.sort((a,b) => {
          return +a.updated_at - +b.updated_at;
        })
        break;
      case 'id_desc':
        this.data.task.operations.sort((a,b) => {
          return b.id - a.id;
        })
        break;
      case 'id':
        this.data.task.operations.sort((a,b) => {
          return a.id - b.id;
        })
        break;
    }
  }

  dblChangeExecutor(e, empl) {
    e.preventDefault();
    e.stopPropagation();
    
    const dialogRef = this.dialog.open(EditExecutorComponent, {
      data: {
        company: this.data.company,
        user: this.data.user,
        taskManagers: this.taskManagers,
        task: this.data.task,
        employee: empl,
        empl_status: this.data.empl_status,
        work: this.data.job,
        showError: () => { this.showError() }
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (result && result.event == "update") {
          this.workAfterClosed()
        }
      })
    )
  }

  dblChangePrice(e, empl) {
    e.preventDefault();
    e.stopPropagation();
    
    const dialogRef = this.dialog.open(EditPriceComponent, {
      data: {
        company: this.data.company,
        user: this.data.user,
        taskManagers: this.taskManagers,
        task: this.data.task,
        employee: empl,
        empl_status: this.data.empl_status,
        work: this.data.job,
        showError: () => { this.showError() }
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (result && result.event == "update") {
          this.workAfterClosed()
        }
      })
    )
  }

  dblChangeStatus(e, empl) {
    e.preventDefault();
    e.stopPropagation();
    
    const dialogRef = this.dialog.open(EditEmplStatusComponent, {
      data: {
        company: this.data.company,
        user: this.data.user,
        taskManagers: this.taskManagers,
        task: this.data.task,
        employee: empl,
        empl_status: this.data.empl_status,
        work: this.data.job,
        showError: () => { this.showError() }
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (result && result.event == "update") {
          this.workAfterClosed()
        }
      })
    )
  }

  
  
  dblChangeChatAssStatus(e, empl, chat) {
    e.preventDefault();
    e.stopPropagation();
    
    const dialogRef = this.dialog.open(EditChatAssStatusComponent, {
      data: {
        company: this.data.company,
        user: this.data.user,
        taskManagers: this.taskManagers,
        task: this.data.task,
        employee: empl,
        empl_status: this.data.empl_status,
        work: this.data.job,
        chat: chat,
        showError: () => { this.showError() }
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (result && result.event == "update") {
          this.workAfterClosed()
        }
      })
    )
  }

  dblChangeChatStatus(e, empl, chat) {
    e.preventDefault();
    e.stopPropagation();
    
    const dialogRef = this.dialog.open(EditChatStatusComponent, {
      data: {
        company: this.data.company,
        user: this.data.user,
        taskManagers: this.taskManagers,
        task: this.data.task,
        employee: empl,
        empl_status: this.data.empl_status,
        work: this.data.job,
        chat: chat,
        showError: () => { this.showError() }
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (result && result.event == "update") {
          this.workAfterClosed()
        }
      })
    )
  }

  dblChangeType(e, empl) {
    console.log(e);
    e.preventDefault();
    e.stopPropagation();
    
    const dialogRef = this.dialog.open(EditTypeComponent, {
      data: {
        company: this.data.company,
        user: this.data.user,
        taskManagers: this.taskManagers,
        task: this.data.task,
        employee: empl,
        empl_status: this.data.empl_status,
        work: this.data.job,
        showError: () => { this.showError() }
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (result && result.event == "update") {
          this.workAfterClosed()
        }
      })
    )
  }

  changeCompletedAt(empl) {   
    const dialogRef = this.dialog.open(EditCompletedAtComponent, {
      data: {
        company: this.data.company,
        user: this.data.user,
        taskManagers: this.taskManagers,
        task: this.data.task,
        employee: empl,
        empl_status: this.data.empl_status,
        work: this.data.job,
        showError: () => { this.showError() }
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (result && result.event == "update") {
          if (this.selectedExec && result.data) {
            this.selectedExec.exec.completed_at = result.data.completed_at
          }
          this.workAfterClosed()
        }
      })
    )
  }

  changePrice(empl) {
    
    const dialogRef = this.dialog.open(EditPriceComponent, {
      data: {
        company: this.data.company,
        user: this.data.user,
        taskManagers: this.taskManagers,
        task: this.data.task,
        employee: empl,
        empl_status: this.data.empl_status,
        work: this.data.job,
        showError: () => { this.showError() }
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (result && result.event == "update") {
          if (this.selectedExec && result.data) {
            this.selectedExec.exec.price = result.data.price
            this.selectedExec.exec.price_currency_id = result.data.price_currency_id
            this.selectedExec.exec.rate = result.data.rate
          }
          this.workAfterClosed()
        }
      })
    )
  }


  dblChangeCompletedAt(e, empl) {
    e.preventDefault();
    e.stopPropagation();
    
    const dialogRef = this.dialog.open(EditCompletedAtComponent, {
      data: {
        company: this.data.company,
        user: this.data.user,
        taskManagers: this.taskManagers,
        task: this.data.task,
        employee: empl,
        empl_status: this.data.empl_status,
        work: this.data.job,
        showError: () => { this.showError() }
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (result && result.event == "update") {
          this.workAfterClosed()
        }
      })
    )
  }

  getOperationStatusName(id) {
    switch (+id) {
      case 1:
        return "Waiting";
        break;
      case 2:
        return "In process";
        break;
      case 3:
        return "To approve";
        break;
      case 4:
        return "Approved";
        break;
      case 5:
        return "To correct";
        break;
      case 6:
        return "Sources Needed";
        break;
      case 97:
        return "On hold";
        break;
      case 98:
        return "Ready";
        break;
      case 99:
        return "Canceled";
        break;
    }
  }

  addExecutor(job, is_manager?) {
    const dialogRef = this.dialog.open(SetEmplComponent, {
      data: {
        company: this.data.company,
        user: this.data.user,
        task: this.data.task,
        work: job,
        is_manager: is_manager,
        executors: true
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result && result.event == "update") {
          this.workAfterClosed()
        }
      })
    )
  }

  // checkIsManager(task, company, _user) { 
  //   return task.managers.filter(x => x.user_id == _user.id).length > 0 || task?.group?.managers.filter(x => x.user_id == _user.id).length > 0 || company?.permissions.includes('manager'); 
  // }

  togglePriceManager(e, jobEmpl) {
    jobEmpl.disabled = true;
    (!!jobEmpl.is_partner ? this.membersService.editTaskPartner(jobEmpl.id, {is_price_manager: Number(e.checked)}, this.data.company.id) : (!!jobEmpl.is_partner_employee ? this.membersService.editTaskPartnerEmployee(jobEmpl.id, {is_price_manager: Number(e.checked)}, this.data.company.id) : this.membersService.editMember(jobEmpl.id, {is_price_manager: Number(e.checked)}, this.data.company.id))).subscribe(resp => {
      jobEmpl.is_price_manager = Number(e.checked);
      jobEmpl.disabled = false;
    },
    error => {
      this.showError();
    })
  }

  // addTaskManager() {
  //   const dialogRef = this.dialog.open(TaskManagerAddComponent, {
  //     data: {
  //       company: this.data.company,
  //       user: this.data.user,
  //       taskManagers: this.taskManagers,
  //       task: this.data.task
  //     }
  //   });

  //   this.attachSubscriptions(
  //     dialogRef.afterClosed().subscribe(result => {
  //       console.log(result)
  //       if (result.event == 'update') {
  //         this.getTaskManagers();
  //       }
  //     })
  //   )
  // }

  // getTaskManagers() {
  //   this.attachSubscriptions(
  //     this.membersService.getMembers({task_id: this.data.task.id, is_manager: 1, is_price_manager: 1}, this.data.company.id).subscribe(resp => {
  //       console.log("getTaskManagers", resp);
  //       this.taskManagers = resp;
  //     })
  //   )
  // }

  close() {
    this.dialogRef.close({event: "close", data: false});
  }

  deleteEmployee(empl, i) {
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: {},
        target: marker("job executor")
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        this.attachSubscriptions(
          (!!empl.is_partner_employee ? this.membersService.deleteTaskPartnerEmployee(empl.id, this.data.company.id) : this.membersService.deleteMember(empl.id, this.data.company.id)).subscribe(resp => {
            this.workAfterClosed()
          },
          error => {
            this.showError();
          })
        )
        
      }
    });
  }

  deleteClient(client, i) {
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: {},
        target: marker("client")
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        this.attachSubscriptions(
          this.taskService.deleteClient(client.id).subscribe(resp => {
            console.log(resp)
            this.clients.splice(i,1);
          })
        )
      }
    });
  
  }

  deletePartner(partner, i) {
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: {},
        target: marker("job partner")
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        this.attachSubscriptions(
          this.membersService.deleteTaskPartner(partner.id, this.data.company.id).subscribe(resp => {
            this.workAfterClosed()
          },
          error => {
            this.showError();
          })
        )
      }
    });
  
  }

  ngOnDestroy() {
    if (this.addItemSub) {
      this.addItemSub.unsubscribe()
    }
    this.clearSubscriptions()
  }

}
