import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { marker } from "@biesbjerg/ngx-translate-extract-marker";
import { BaseClass } from "src/app/shared/models/base-class";


@Component({
  selector: 'app-show-answer',
  templateUrl: './show-answer.component.html',
  styleUrls: ['./show-answer.component.scss']
})
export class ShowAnswerComponent extends BaseClass implements OnInit, OnDestroy {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ShowAnswerComponent>,
    private dialog: MatDialog,
  ) { super() }

  ngOnInit(): void {
    console.log("ShowAnswerComponent", this.data)
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }
  
  accept() {
    this.dialogRef.close({event: "accept", data: false})
  }

  new() {
    this.dialogRef.close({event: "new", data: false})
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

}