import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GlobalDataService {

  public transferData$: Subject<any> = new Subject();
  public contentStatus$: BehaviorSubject<any> = new BehaviorSubject([]);
  public currencies$: BehaviorSubject<any> = new BehaviorSubject([]);
  public operationStatus$: BehaviorSubject<any> = new BehaviorSubject([]);
  constructor() { }

  getContentStatus() {
    return this.contentStatus$.asObservable();
  }

  getTransferData() {
    return this.transferData$.asObservable();
  }

  getCurrencies() {
    return this.currencies$.asObservable();
  }

  getOperationStatus() {
    return this.operationStatus$.asObservable();
  }
}
