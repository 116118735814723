<h2 mat-dialog-title class="dialog_title">
    <span>
        {{ "Note relations" | translate }}
    </span>
    <button mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h2>
<mat-dialog-content class="mat-typography">
    <div class="container card_clients_wrp" [appDeviceClass]="'custom-scroll custom-scroll-t'">
        <mat-list role="list">
            <mat-list-item *ngFor="let item of relations; let i = index;" role="listitem" class="company_item" [ngClass]="{'in_active': item?.task_id == data?.target?.task_id && item?.task_operation_id == data?.target?.task_operation_id && item?.discussion_id == data?.target?.discussion_id && item?.file_id == data?.target?.file_id}">
                <div class="company_name">
                   <div>Card ID: {{item.task_id}}</div>
                   <div>Job ID: {{item.task_operation_id}}</div>
                   <div>Chat ID: {{item.discussion_id}}</div>
                   <div>File ID: {{item.file_id}}</div>
                </div>
                <div class="checkbox_clients_col">
                    <mat-checkbox [disabled]="item.disabled" class="ml-1 mr-1" color="primary" (change)="togglePartner($event, item)" [checked]="item.is_copyable > 0">
                        {{ "Copyable" | translate }}
                    </mat-checkbox>
                </div>
                <div class="company_btns">
                    <!-- <mat-icon (click)="goToEditClient(client)">edit</mat-icon> -->
                    <mat-icon (click)="deleteRelation(item, i)">delete</mat-icon>
                </div>
            </mat-list-item>
        </mat-list>
    </div>
</mat-dialog-content>
