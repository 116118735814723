import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'yearPipe'
})
export class YearPipe implements PipeTransform {
    private curYear = (new Date()).getFullYear()
    private curTwoYear = this.curYear % 100;
    transform(number: string) {
        if (number) {
            number = number.replace(' г.','').replace(' р.','').replace('сент','сен').replace('февр','фев').replace('лист','лис').replace('жовт','жов').replace('серп','сер').replace('груд','гру').replace('Р.','').replace(`/${String(this.curYear)}`, "").replace(`, ${String(this.curYear)}`, "").replace(`, ${String(this.curTwoYear)}`, "").replace(`.${String(this.curYear)}`, "").replace(`. ${String(this.curYear)}`, "")
        }
        return number
    }
}