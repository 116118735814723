<div class="container centred_page">

    <div class="step">
        <h2>
          {{ 'Stats' | translate }}: {{ company?.name }} 
          <ng-container *ngIf="selectedIndex == 0; else elseTemplate">
            <span style="color: #3f51b5; font-weight: 400;">
              ({{ 'Points target' | translate }}:
              {{ company?.rate_target | reportsNumber }})
            </span>
          </ng-container>
          <ng-template #elseTemplate>
            <span style="color: #3f51b5; font-weight: 400;">
              ({{ 'Salary' | translate }}:
              {{ company?.salary | reportsNumber }})
            </span>
          </ng-template>
        </h2>
        <mat-tab-group mat-align-tabs="center" (selectedIndexChange)="selectTab($event)">
          <mat-tab>
            <ng-template mat-tab-label>
              <mat-icon class="example-tab-icon mr-1">radio_button_checked</mat-icon>
              {{ 'Points' | translate }}
              </ng-template>
            
            <div class="example-container mat-elevation-z8" #pointContainer>
              <table mat-table [dataSource]="stats">
            
                <ng-container matColumnDef="Month">
                  <th mat-header-cell *matHeaderCellDef> {{ "Month" | translate }} </th>
                  <td mat-cell *matCellDef="let element"> {{ element.time_start + "000" | date:'MMM YYYY':'UTC':activeLang | yearPipe }} </td>
                </ng-container>
      
                <ng-container matColumnDef="Pending">
                  <th mat-header-cell *matHeaderCellDef> {{ "Pending" | translate }} </th>
                  <td mat-cell *matCellDef="let element">
                    <a style="text-decoration: none;" *ngIf="element.rate && (+element.rate.to_approve_summary_rate || +element?.rate?.approved_summary_rate)" href="/tasks?company_id={{company_id}}&operation_completed_at_from={{element.time_start}}&operation_completed_at_to={{+element.time_end}}&operation_status_id=3,4" (click)="goToStats($event, element.time_start, element.time_end, [3,4])">
                      {{ (+element?.rate?.to_approve_summary_rate + +element?.rate?.approved_summary_rate) | reportsNumber }} <ng-container *ngIf="+company?.rate_target">({{((+element?.rate?.to_approve_summary_rate + +element?.rate?.approved_summary_rate)/+company?.rate_target)*100 | reportsNumber}}%)</ng-container>
                    </a> 
                  </td>
                </ng-container>

                <ng-container matColumnDef="Ready">
                  <th mat-header-cell *matHeaderCellDef> {{ "Ready" | translate }} </th>
                  <td mat-cell *matCellDef="let element"> 
                    <a style="text-decoration: none;" *ngIf="element.rate && +element.rate.ready_summary_rate" href="/tasks?company_id={{company_id}}&operation_completed_at_from={{element.time_start}}&operation_completed_at_to={{+element.time_end}}&operation_status_id=98" (click)="goToStats($event, element.time_start, element.time_end, [98])">
                      {{ element.rate.ready_summary_rate | reportsNumber }} <ng-container *ngIf="+company?.rate_target">({{(+element.rate.ready_summary_rate/+company?.rate_target)*100 | reportsNumber}}%)</ng-container>
                    </a> 
                  </td>
                </ng-container>
                
                <ng-container matColumnDef="Total">
                  <th mat-header-cell *matHeaderCellDef> {{ "Total" | translate }} </th>
                  <td mat-cell *matCellDef="let element"> 
                    <a style="text-decoration: none;" *ngIf="element.rate && +element.rate.summary_rate" href="/tasks?company_id={{company_id}}&operation_completed_at_from={{element.time_start}}&operation_completed_at_to={{+element.time_end}}&operation_status_id=3,4" (click)="goToStats($event, element.time_start, element.time_end, [3,4])">
                      {{ element.rate.summary_rate | reportsNumber }} <ng-container *ngIf="+company?.rate_target">({{(+element.rate.summary_rate/+company?.rate_target)*100 | reportsNumber}}%)</ng-container>
                    </a>
                  </td>
                </ng-container>           
            
                <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
                <tr mat-row *matRowDef="let element; columns: displayedColumns;"></tr>
      
              </table>
            </div>

          </mat-tab>
          <mat-tab>
            <ng-template mat-tab-label>
              <mat-icon class="example-tab-icon mr-1">attach_money</mat-icon>
              {{ 'Salary' | translate }}
            </ng-template>
            <div class="example-container mat-elevation-z8" #currencyContainer>
              <table mat-table [dataSource]="stats">
            
                <ng-container matColumnDef="Month">
                  <th mat-header-cell *matHeaderCellDef> {{ "Month" | translate }} </th>
                  <td mat-cell *matCellDef="let element"> {{ element.time_start + "000" | date:'MMM YYYY':'UTC':activeLang | yearPipe }} </td>
                </ng-container>
      
                <ng-container matColumnDef="Pending">
                  <th mat-header-cell *matHeaderCellDef> {{ "Pending" | translate }} </th>
                  <td mat-cell *matCellDef="let element"> 
                    <a class="link_to_stat" href="/tasks?company_id={{company_id}}&operation_completed_at_from={{element.time_start}}&operation_completed_at_to={{+element.time_end}}&operation_status_id=3,4" (click)="goToStats($event, element.time_start, element.time_end, [3,4])">
                      <ng-container *ngFor="let value of element.salary; let i = index;">
                        <span *ngIf="+value?.to_approve_summary_price || +value?.approved_summary_price">
                          <ng-container [ngSwitch]="value.currency_id">
                              <ng-container *ngSwitchCase="'1'">&#36;</ng-container>
                              <ng-container *ngSwitchCase="'2'">&#8381;</ng-container>
                              <ng-container *ngSwitchCase="'3'">&#8364;</ng-container>
                              <ng-container *ngSwitchCase="'4'">&#8372;</ng-container>
                          </ng-container>{{ +value?.to_approve_summary_price + +value?.approved_summary_price | reportsNumber }} 
                        </span>
                      </ng-container>
                    </a>
                  </td>
                </ng-container>

                <ng-container matColumnDef="Ready">
                  <th mat-header-cell *matHeaderCellDef> {{ "Ready" | translate }} </th>
                  <td mat-cell *matCellDef="let element"> 
                    <a class="link_to_stat" href="/tasks?company_id={{company_id}}&operation_completed_at_from={{element.time_start}}&operation_completed_at_to={{+element.time_end}}&operation_status_id=98" (click)="goToStats($event, element.time_start, element.time_end, [98])">
                      <ng-container *ngFor="let value of element.salary; let i = index;">
                        <span *ngIf="+value.ready_summary_price">
                          <ng-container [ngSwitch]="value.currency_id">
                            <ng-container *ngSwitchCase="'1'">&#36;</ng-container>
                            <ng-container *ngSwitchCase="'2'">&#8381;</ng-container>
                            <ng-container *ngSwitchCase="'3'">&#8364;</ng-container>
                            <ng-container *ngSwitchCase="'4'">&#8372;</ng-container>
                          </ng-container>{{ value.ready_summary_price | reportsNumber }}
                        </span>
                      </ng-container>
                    </a>
                  </td>
                </ng-container>
                
                <ng-container matColumnDef="Total">
                  <th mat-header-cell *matHeaderCellDef> {{ "Total" | translate }} </th>
                  <td mat-cell *matCellDef="let element"> 
                    <a class="link_to_stat" href="/tasks?company_id={{company_id}}&operation_completed_at_from={{element.time_start}}&operation_completed_at_to={{+element.time_end}}&operation_status_id=3,4" (click)="goToStats($event, element.time_start, element.time_end, [3,4])">
                      <ng-container *ngFor="let value of element.salary; let i = index;">
                        <span *ngIf="+value.summary_price">
                          <ng-container [ngSwitch]="value.currency_id">
                            <ng-container *ngSwitchCase="'1'">&#36;</ng-container>
                            <ng-container *ngSwitchCase="'2'">&#8381;</ng-container>
                            <ng-container *ngSwitchCase="'3'">&#8364;</ng-container>
                            <ng-container *ngSwitchCase="'4'">&#8372;</ng-container>
                          </ng-container>{{ value.summary_price | reportsNumber }}
                        </span>
                      </ng-container>  
                    </a>
                  </td>
                </ng-container>
      
                <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
                <tr mat-row *matRowDef="let element; columns: displayedColumns;"></tr>
      
              </table>
            </div>
          </mat-tab>
        </mat-tab-group>

        <div class="btns_line">
            <button mat-raised-button (click)="goBack()">
                {{ "Back" | translate }}
            </button>
            <button class="ml-1 d-f-c" style="display: inline-flex !important;" mat-raised-button color='warn' (click)="refreshStats()">
                <mat-icon>refresh</mat-icon>
                {{ 'Update stats' | translate }}
            </button>
        </div>
    </div>
</div>