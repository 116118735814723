import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { AuthService } from 'src/app/shared/services/rest/auth.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { MY_FORMATS } from '../task-profile-add/task-profile-add.component';
import * as moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/en-gb';
import 'moment/locale/uk';
import 'moment/locale/ru';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { bool } from 'aws-sdk/clients/signer';
import { debounceTime, last, map, switchMap, tap } from 'rxjs/operators';
import { forkJoin, of, ReplaySubject } from 'rxjs';
import { prioritys } from 'src/app/shared/consts/prioritys';
import { MembersService } from 'src/app/shared/services/rest/members.service';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { GlobalDataService } from 'src/app/shared/services/common/global-data.service';
import { ScenariosService } from 'src/app/shared/services/rest/scenarios.service';
import { environment } from 'src/environments/environment';
import { NgxMatDateAdapter } from '@angular-material-components/datetime-picker';
import { ParametersService } from 'src/app/shared/services/rest/parameters.service';
import { TargetParametersComponent } from '../target-parameters/target-parameters.component';

@Component({
  selector: 'app-work-add',
  templateUrl: './work-add.component.html',
  styleUrls: ['./work-add.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class WorkAddComponent extends BaseClass implements OnInit, OnDestroy {
  public host: any = environment.host;
  public imgRoute: any = '';
  public user_id: number;
  public form: FormGroup;
  public partnerForm: FormGroup;
  public additional: FormGroup;
  public templateForm: FormGroup;
  public workEmpl: FormGroup;
  public company_id: any;
  public task_id: any;
  public operations: any;
  public partners: any;

  public templates: any;
  public templatesMoreControl: FormControl = new FormControl();
  public templates$: ReplaySubject<any> = new ReplaySubject<any>(1);
  
  public currencyList: any;
  public prioritys: any = prioritys;
  public operationStatuses: any;
  public numberRegEx = /\d*\.?\d{1,2}/;
  public isFormChange: boolean = false;
  public showStatuses: boolean = false;
  public showPart: boolean = false;
  public isSubmit: boolean = false;
  public submited: boolean = false;
  
  
  public employees: any;
  public addEmployees: any;
  public employeeMoreControl: FormControl = new FormControl();
  public employees$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public addEmployeeMoreControl: FormControl = new FormControl();
  public addEmployees$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public operationMoreControl: FormControl = new FormControl();
  public operations$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public partnerMoreControl: FormControl = new FormControl();
  public partners$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public parameters: any;

  public tabsMoreControl: FormControl = new FormControl();
  public tabs$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public tabs: any;
  public allValues: any;
  public tabsSwitch: boolean = false;


  public pCliSwitch: boolean = false;
  public cliSwitch: boolean = false;

  public clients = [];
  public partnerClients = [];
  public clients_ids = [];
  public partnerClients_ids = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<WorkAddComponent>,
    private sm: StorageManagerService,
    private auth: AuthService,
    private dialog: MatDialog,
    private companyService: CompanyService,
    private globalDataService: GlobalDataService,
    private fb: FormBuilder,
    private taskService: TaskService,
    private _adapter: DateAdapter<any>,
    private _ngx_adapter: NgxMatDateAdapter<any>,
    private languageService: LanguageService,
    private parametersService: ParametersService,
    private layoutService: LayoutService,
    private scenariosService: ScenariosService,
    private membersService: MembersService
  ) { super() }

  ngOnInit(): void {
    window.scroll(0,0);
    console.log("WorkAddComponent", this.data)

    this.company_id = this.data.company_id || this.data.company.id;
    this.task_id = this.data.task.id;

    if (this.data.tabs) {
      this.tabs = this.data.tabs.slice();
      this.tabs$.next(this.tabs.slice());
    }

    this.getCompany();
    this.getPartners();
    this.getImgRoute();
    this.getCsrf();

    this.attachSubscriptions(
      this.employeeMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchEmoloyees(resp))
    )
    this.attachSubscriptions(
      this.addEmployeeMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchAddEmoloyees(resp))
    )
    this.attachSubscriptions(
      this.operationMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchOperations(resp))
    )
    this.attachSubscriptions(
      this.partnerMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchPartners(resp))
    )
    this.attachSubscriptions(
      this.tabsMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchTabs(resp))
    )

    this.workEmpl = this.fb.group({
      company_id: this.company_id,
      task_id: this.task_id,
      task_operation_id: 0,
      rate: [0, [Validators.required, Validators.pattern(this.numberRegEx)]],
      price: [0, [Validators.required, Validators.pattern(this.numberRegEx)]],
      employee_id: 0,
      is_manager: 1,
      status_id: 0,
      completed_at: ''
    })

    this.form = this.fb.group({
      task_id: this.task_id,
      operation_id: ['', Validators.required],
      status_id: ['', Validators.required],
      name: '',
      comment: '',
      priority: 0,
      private_comment: '',
      create_parameter_values_to_task: [],
      employee_comment: '',
      completed_at: '',
      planned_completed_at: '',
      date: this.fb.group({
        time: '',
        day: ''
      })
    })

    this.partnerForm = this.fb.group({
      company_id: this.company_id,
      task_id: this.task_id,
      task_operation_id: 0,
      partner_company_id: 0,
      is_manager: 1,
      rate: 0,
      price: 0,
      completed_at: '',
    })

    this.templateForm = this.fb.group({
      company_id: this.company_id,
      task_operation_id: 0,
      template_id: []
    })
    
    this.additional = this.fb.group({
      company_id: this.company_id,
      task_operation_id: '',
      employee_id: [],
      task_id: this.task_id,
      status_id: '',
      is_manager: 0
    })

    this.attachSubscriptions(
      this.form.get('date').valueChanges.subscribe(resp => {
        console.log(resp)
        let dateForm = {...this.form.get("date").value}
        if (!dateForm.day) {
          dateForm.day = moment(0).hour(0).minute(0)
        }
        let testTimeAndDate = dateForm.day.clone()
        testTimeAndDate.hour(Number(dateForm.time.split(":")[0]))
        testTimeAndDate.minute(Number(dateForm.time.split(":")[1]))
        this.form.patchValue({
          planned_completed_at: testTimeAndDate
        })
        console.log('this.form.value', this.form.value);
        console.log('testTimeAndDate', testTimeAndDate.clone().format("X"));
      })
    )

    this.attachSubscriptions(
      this.form.valueChanges.subscribe(() => this.isFormChange = true)
    )
    this.attachSubscriptions(
      this.additional.valueChanges.subscribe(() => this.isFormChange = true)
    )
    this.attachSubscriptions(
      this.partnerForm.valueChanges.subscribe(() => this.isFormChange = true)
    )

    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        if (this.isFormChange) {
          this.layoutService.openBottomSheet(this.dialogRef);
        } else {
          this.close();
        }
      })
    )

    this.getParameters();
    this.getOperations();
    this.getOperationsStatus();
    this.getEmployees();
    this.getCurrencyList();
    
    this.getLangData();
    this.getAutomationTemplates();
    this.attachSubscriptions(
      this.templatesMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchTemplates(resp))
    )

    if (this.data.is_edit_ve) {
      this.workEmpl.patchValue(this.data.job.result.data.workEmpl);
      this.form.patchValue(this.data.job.result.data.form);
      this.partnerForm.patchValue(this.data.job.result.data.partnerForm);
      this.templateForm.patchValue(this.data.job.result.data.templateForm);
      this.additional.patchValue(this.data.job.result.data.additional);
    }
  }

  logCli() {
    console.log(this.form.value)
    console.log(this.clients)
    console.log(this.clients_ids)
    console.log(this.partnerClients)
    console.log(this.partnerClients_ids)

    let allClients = [...this.clients, ...this.partnerClients]

    console.log("allClients", allClients)
  }

  includesCli(id) {
    return this.clients.filter(x => x.client_employee_id == id).length > 0;
  }
  
  setCli(e, id, val) {
    e.preventDefault();
    e.stopPropagation();
    this.clients.find(x => x.client_employee_id == id).is_principal = val
  }

  setCliPartn(e, id, val) {
    e.preventDefault();
    e.stopPropagation();
    this.partnerClients.find(x => x.client_partner_company_id == id).is_principal = val
  }

  getCli(id) {
    return this.clients.find(x => x.client_employee_id == id);
  }
  
  getCliPartn(id) {
    return this.partnerClients.find(x => x.client_partner_company_id == id);
  }

  includesCliPartn(id) {
    return this.partnerClients.filter(x => x.client_partner_company_id == id).length > 0;
  }

  toggleSelectClientChip(e) {
    console.log('toggleSelectClientChip', e)

    this.clients.forEach((obj, ind) => {
      if (!this.clients_ids.filter(id => obj.client_employee_id == id).length) {
        this.clients.splice(ind, 1)
      }
    });

    this.clients_ids.forEach((id, ind) => {
      if (!this.clients.filter(x => x.client_employee_id == id).length) {
        this.clients.push({
          client_employee_id: id,
          is_principal: 1
        })
      }
    });

    console.log(this.clients_ids);
    console.log(this.clients);
  }

  toggleSelectClientPartnerChip(e) {
    console.log('toggleSelectClientPartnerChip', e)

    this.partnerClients.forEach((obj, ind) => {
      if (!this.partnerClients_ids.filter(id => obj.client_partner_company_id == id).length) {
        this.partnerClients.splice(ind, 1)
      }
    });

    this.partnerClients_ids.forEach((id, ind) => {
      if (!this.partnerClients.filter(x => x.client_partner_company_id == id).length) {
        this.partnerClients.push({
          client_partner_company_id: id,
          is_principal: 1
        })
      }
    });

    console.log(this.partnerClients_ids);
    console.log(this.partnerClients);
  }

  toggleClientPartnerChip = (chip: any) => {
    console.log("toggleClientPartnerChip", chip)
    let id = !!chip.partner_company_id ? chip.partner_company_id : chip.value
    if (!!id) {
      if (this.partnerClients.filter(x => x.client_partner_company_id == id).length) {
        this.partnerClients.splice(this.partnerClients.indexOf(this.partnerClients.find(x => x.client_partner_company_id == id)), 1)
      } else {
        this.partnerClients.push({
          client_partner_company_id: id,
          is_principal: 1
        })
      }
    }
    
    this.partnerClients_ids = this.partnerClients.map(x => x.client_partner_company_id);
    // if (!chip.partner_company_id) {
    //   this.pCliSwitch = true;

    //   setTimeout(() => {
    //     this.pCliSwitch = false;
    //   }, 0)
    // }
  }

  toggleClientChip = (chip: any) => {
    console.log("toggleClientChip", chip)
    let id = !!chip.id ? chip.id : null;
    if (!!id) {
      if (this.clients.filter(x => x.client_employee_id == id).length) {
        this.clients.splice(this.clients.indexOf(this.clients.find(x => x.client_employee_id == id)), 1)
      } else {
        this.clients.push({
          client_employee_id: id,
          is_principal: 1
        })
      }
    }

    this.clients_ids = this.clients.map(x => x.client_employee_id);

    // if (!chip.id) {
    //   this.cliSwitch = true;

    //   setTimeout(() => {
    //     this.cliSwitch = false;
    //   }, 0)
    // }
  }

  log() {
    console.log(this.form.value);
    console.log(this.parameters);
    console.log(this.tabs);
    
  }
  
  getParameters() {
    this.attachSubscriptions(
      this.parametersService.getApiParameters(this.data.company.id).pipe(
        switchMap(res => {
          return this.parametersService.getAllValues('1', this.data.company.id, null, '1').pipe(
            switchMap(el => {
              let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
              let arr = []
              for (let index = 1; index <= pages; index++) {
                arr.push(index)
              }
    
              return forkJoin(arr.map(x => this.parametersService.getAllValues(x, this.data.company.id).pipe(map(u => u.body)))).pipe(
                last(),
                tap(values => {
                  this.allValues = [].concat(...values)
                  console.log("getAllApiParameterValues", this.allValues)
                }),
                map(() => res)
              )
            }),
            map(() => res)
          )
        }),
        tap(res => {
          res.unshift({
            company_id: this.data.company.id,
            created_at: 0,
            id: 0,
            is_new_value: 0,
            name: "All parameters",
            original_id: 0,
          })
          this.parameters = res;
        }),
      ).subscribe(resp => {
        console.log("this.parameters", this.parameters)
        this.parameters.forEach(element => {
          element.activeValues = [];
          if (element.id == 0) {
            element.values = this.allValues;
          } else {
            element.values = this.allValues.filter(u => u.parameter_id = element.id);
          }
        });
      })
    )
  }

  togglePrimary(tag) {
    tag.is_primary = !tag.is_primary;
  }

  onRemoveJobTag(tag, ind) {
    if (this.parameters && this.parameters[0] && this.parameters[0].hasOwnProperty('activeValues')) {
      this.parameters[0].activeValues.splice(ind, 1);
      this.setParams();
    }
  }

  openTargetValues() {
    let initData:any = {
      company: this.data.company,
      auto: true,
      parameters: this.parameters,
      highlightParam: this.data.param_id
    }

    const dialogRef = this.dialog.open(TargetParametersComponent, {
      backdropClass: ['parameters_modal_backdrop'],
      panelClass: ['without_paddings_modal', 'parameters_modal'],
      data: initData
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result) {
          if (result.event == 'save') {
            this.parameters = result.data;
            if (this.parameters && this.parameters[0] && this.parameters[0].hasOwnProperty('activeValues')) {
              this.setParams();
            }
          }
        }
      })
    )
  }

  setParams() {
    if (this.data.param_id) {
      this.form.patchValue({
        create_parameter_values_to_task: this.parameters[0].activeValues.filter(x => x.parameter_id == this.data.param_id).map(u => u.id)
      })
      this.tabsSwitch = true;
      setTimeout(() => {
        this.tabsSwitch = false
      }, 0);
    }
  }

  getTagById(id) {
    return this.data.tabs && this.data.tabs.find(x => x.id == id)
  }

  getAutomationTemplates() {
    this.attachSubscriptions(
      this.scenariosService.getAutomationTemplates('1', {company_id: this.data.company.id, is_task_operation: 1}, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.scenariosService.getAutomationTemplates(x || 1, {company_id: this.data.company.id, is_task_operation: 1}, '200').pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        this.templates = [].concat(...res);
        this.templates$.next(this.templates.slice());
      })
    )
  }

  onSearchTemplates(resp) {
    if (!this.templates) {
      return;
    }

    if (!resp) {
      this.templates$.next(this.templates.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.templates$.next(
      this.templates.filter(z => z.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  onSearchTabs(resp) {
    if (!this.tabs) {
      return;
    }

    if (!resp) {
      this.tabs$.next(this.tabs.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.tabs$.next(
      this.tabs.filter(z => z.value.toLowerCase().indexOf(resp) > -1)
    );
  }

  onSearchPartners(resp) {
    if (!this.partners) {
      return;
    }

    if (!resp) {
      this.partners$.next(this.partners.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.partners$.next(
      this.partners.filter(z => z.partnerCompany.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  onSearchAddEmoloyees(resp) {
    if (!this.employees) {
      return;
    }

    if (!resp) {
      this.addEmployees$.next(this.employees.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.addEmployees$.next(
      this.employees.filter(z => z.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  onSearchEmoloyees(resp) {
    if (!this.employees) {
      return;
    }

    if (!resp) {
      this.employees$.next(this.employees.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.employees$.next(
      this.employees.filter(z => z.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  onSearchOperations(resp) {
    if (!this.operations) {
      return;
    }

    if (!resp) {
      this.operations$.next(this.operations.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.operations$.next(
      this.operations.filter(z => (z.name.toLowerCase().indexOf(resp) > -1))
    );
  }

  getCurrency(id) {
    if (!!this.currencyList) {
      return this.currencyList.find(el => el.id == id)
    }
  }

  getCurrencyList() {
    this.attachSubscriptions(
      this.globalDataService.getCurrencies().subscribe(resp => {
        console.log("getCurrencyList", resp)
        this.currencyList = resp.slice();
      })
    )
    // this.attachSubscriptions(
    //   this.companyService.getCurrencyList().subscribe(resp => {
    //     console.log("getCurrencyList", resp);
    //     this.currencyList = resp
    //   })
    // )
  }

  showMoreStatuses() {
    this.showStatuses = true;
  }

  selectType(type) {
    this.form.patchValue({
      operation_id: !!type.id ? type.id : type.value
    })
  }

  selectPrioritys = (priority: any) => {
    this.form.patchValue({
      priority: priority.id
    })
  }

  selectStatus(status) {
    this.form.patchValue({
      status_id: status.id
    })
  }

  getEmployeeById(id) {
    return this.employees.find(x => x.id == id)
  }

  showMorePart() {
    this.showPart = true;
  }

  toggleChip = (chip: any) => {
    this.workEmpl.patchValue({
      employee_id: !!chip.id ? chip.id : chip.value
    })
    this.partnerForm.patchValue({
      partner_company_id: ''
    })
    if (!!this.additional.value.employee_id && this.additional.value.employee_id.includes(!!chip.id ? chip.id : chip.value)) {
      let val = [...this.additional.value.employee_id]
      val.splice(this.additional.value.employee_id.indexOf(!!chip.id ? chip.id : chip.value), 1)
      this.additional.patchValue({
        employee_id: val
      })
    }
  }

  togglePartnerChip = (chip: any) => {
    this.partnerForm.patchValue({
      partner_company_id: !!chip.partner_company_id ? chip.partner_company_id : chip.value
    })

    this.workEmpl.patchValue({
      employee_id: ''
    })
    this.additional.patchValue({
      employee_id: []
    })
  }

  toggleTabChip(chip:any) {
    console.log("toggleTabChip 1", chip)
    if (!!chip.id) {
      if (!!this.form.value.create_parameter_values_to_task && this.form.value.create_parameter_values_to_task.includes(chip.id)) {
        let val = [...this.form.value.create_parameter_values_to_task]
        val.splice(this.form.value.create_parameter_values_to_task.indexOf(chip.id), 1)
        this.form.patchValue({
          create_parameter_values_to_task: val
        })
      } else {
        if (!!this.form.value.create_parameter_values_to_task) {
          let val = [...this.form.value.create_parameter_values_to_task]
          val.push(chip.id)
          this.form.patchValue({
            create_parameter_values_to_task: val
          })
        } else {
          this.form.patchValue({
            create_parameter_values_to_task: [chip.id]
          })
        }
      }
    }

    if (this.parameters && this.tabs && this.parameters[0] && this.parameters[0].activeValues) {{
      this.form.value.create_parameter_values_to_task.forEach(vID => {
        if (!this.parameters[0].activeValues.filter(u => u.id == vID).length) {
          this.parameters[0].activeValues.push(this.tabs.find(x => x.id == vID))
        }
      })
      this.parameters[0].activeValues.filter(p => p.parameter_id == this.data.param_id).forEach(obj => {
        if (!this.form.value.create_parameter_values_to_task.filter(u => u == obj.id).length) {
          this.parameters[0].activeValues.splice(this.parameters[0].activeValues.indexOf(obj), 1)
        }
      })
    }}

    if (!chip.id) {
      console.log("toggleTabChip 2", chip)
      this.tabsSwitch = true;
      setTimeout(() => {
        this.tabsSwitch = false
      }, 0)
    }
  }

  toggleChipTemplate = (chip: any) => {
    if (!!this.templateForm.value.template_id && this.templateForm.value.template_id.includes(chip.id)) {
      let val = [...this.templateForm.value.template_id]
      val.splice(this.templateForm.value.template_id.indexOf(chip.id), 1)
      this.templateForm.patchValue({
        template_id: val
      })
    } else {
      if (!!this.templateForm.value.template_id) {
        let val = [...this.templateForm.value.template_id]
        val.push(chip.id)
        this.templateForm.patchValue({
          template_id: val
        })
      } else {
        this.templateForm.patchValue({
          template_id: [chip.id]
        })
      }
    }
  }

  toggleAddChip = (chip: any) => {
    if (!!this.additional.value.employee_id && this.additional.value.employee_id.includes(!!chip.id ? chip.id : chip.value)) {
      let val = [...this.additional.value.employee_id]
      val.splice(this.additional.value.employee_id.indexOf(!!chip.id ? chip.id : chip.value), 1)
      this.additional.patchValue({
        employee_id: val
      })
    } else {
      if (!!this.additional.value.employee_id) {
        let val = [...this.additional.value.employee_id]
        val.push(!!chip.id ? chip.id : chip.value)
        this.additional.patchValue({
          employee_id: val
        })
      } else {
        this.additional.patchValue({
          employee_id: [!!chip.id ? chip.id : chip.value]
        })
      }
    }

    if ((!!chip.id ? chip.id : chip.value) == this.workEmpl.value.employee_id) {
      this.workEmpl.patchValue({
        employee_id: ''
      })
    }
  }

  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData().subscribe(resp => {
        this._ngx_adapter.setLocale(resp.active);
        this._adapter.setLocale(resp.active);
      })
    )
  }
  
  getCompany() {
    this.attachSubscriptions(
      this.companyService.getCompany(this.data.company.id).subscribe(resp => {
        this.companyService.company$.next(resp[0]);
      })
    )
  }

  getPartners() {
    this.attachSubscriptions(
      this.companyService.getPartners({company_id: this.data.company.id}).subscribe(resp => {
        this.partners = resp;
        this.partners$.next(this.partners.slice());
      })
    )
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  
  onPartnerImgError(event){
    event.target.src = this.imgRoute+'/assets/img/partner.png'
  }
  
  getCsrf() {
    this.attachSubscriptions(
      this.auth.$userToken.subscribe(resp => {
        this.user_id = resp.user_id;
        this.sm.localStorageSetItem("csrf_param", resp.csrf_param)
        this.sm.localStorageSetItem("csrf_token", resp.csrf_token)
      })
    )
  }
  
  // getEmployees() {
  //   this.attachSubscriptions(
  //     this.taskService.getEmployees(this.data.company.id).subscribe(resp => {
  //       this.employees = resp;
  //       this.employees$.next(this.employees.slice())
  //       this.addEmployees = resp;
  //       this.addEmployees$.next(this.addEmployees.slice())
  //     })
  //   )
  // }

  getEmployees() {
    this.attachSubscriptions(
      this.taskService.getEmployeesDyn('1', this.data.company.id, null, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }
  
          return forkJoin(arr.map(x => this.taskService.getEmployeesDyn(x, this.data.company.id, null, '200').pipe(map(u => u.body)))).pipe(
            last(),
            tap(values => {
              let conValues = [].concat(...values)
              this.employees = conValues;
              this.employees$.next(this.employees.slice())
              this.addEmployees = conValues;
              this.addEmployees$.next(this.addEmployees.slice())
            }),
          )
        }),
      ).subscribe(resp => {
        console.log("getEmployees resp", resp);
        console.log("getEmployees", this.employees);
      })
    )
  }
  
  getOperations() {
    this.attachSubscriptions(
      this.taskService.getOperations(this.data.company.id).subscribe(resp => {
        this.operations = resp;
        this.operations$.next(this.operations.slice())
      })
    )
  }

  getOperationsStatus() {
    this.attachSubscriptions(
      this.taskService.getOperationsStatus().subscribe(resp => {
        this.operationStatuses = resp.slice();
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  onSubmit() {
    if (!this.form.valid || !this.workEmpl.valid) {
      this.submited = true;
      this.layoutService.showSnackBar({name: ''}, marker("You need to fill in all required fields"), SnackBarItem)
      return
    }

    this.submited = false;
    this.isSubmit = true;
    if (String(this.workEmpl.value['rate']).indexOf(",") != -1) {
      this.workEmpl.value['rate'] = this.workEmpl.value['rate'].replace(",", ".");
    }
    if (String(this.workEmpl.value['price']).indexOf(",") != -1) {
      this.workEmpl.value['price'] = this.workEmpl.value['price'].replace(",", ".");
    }
    let paramsValues = []
    if (this.parameters && this.parameters.length && this.parameters[0].activeValues) {
      this.parameters[0].activeValues.forEach(val => {
        paramsValues.push({
          parameter_id: val.parameter_id,
          parameter_value_id: val.id,
          is_primary: val.is_primary
        })
      })
    }

    this.form.patchValue({create_parameter_values_to_task: paramsValues})

    let formData = {...this.form.value}
    if (!!this.form.value.completed_at && !!this.form.value.completed_at._d) {
      formData.completed_at = moment(this.form.value.completed_at._d).format("X");
    } else {
      delete formData.completed_at;
    }

    if (!!this.form.value.planned_completed_at && !!this.form.value.planned_completed_at._d) {
      formData.planned_completed_at = moment(this.form.value.planned_completed_at._d).format("X");
    } else {
      delete formData.planned_completed_at;
    }
    delete formData.date;


    console.log("formData", formData)
    console.log("this.form.value", this.form.value)
    console.log("this.workEmpl.value", this.workEmpl.value)
    console.log("this.partnerForm.value", this.partnerForm.value)
    console.log("this.additional.value", this.additional.value)
    // this.isSubmit = false;
    // return
    if (this.data.is_ve) {
      this.dialogRef.close({event: "Add", data: {
        form: formData,
        partnerForm: this.partnerForm.value,
        workEmpl: this.workEmpl.value,
        additional: this.additional.value,
        templateForm: this.templateForm.value,
      }});
    } else {
      // this.scenariosService.addAutomationScenario(this.templateForm.value)
      this.attachSubscriptions(
        this.taskService.addWork(this.data.company.id, formData).pipe(
          switchMap(resp => {
            this.workEmpl.patchValue({
              task_operation_id: resp.id,
              status_id: resp.status_id,
              completed_at: formData.completed_at,
            })
  
            console.log("this.workEmpl.value", this.workEmpl.value);
            if (this.workEmpl.value.employee_id != 0 && !!this.workEmpl.value.employee_id) {
              return this.membersService.addMember(this.workEmpl.value, this.data.company.id).pipe(map(() => resp))
            } else {
              return of(resp)
            }
          }),
          switchMap(resp => {
            if (!!this.partnerForm.value.partner_company_id) {
              this.partnerForm.patchValue({
                task_operation_id: resp.id,
                rate: this.workEmpl.value.rate,
                price: this.workEmpl.value.price,
                completed_at: formData.completed_at,
                status_id: resp.status_id
              })
              console.log("this.partnerForm.value", this.partnerForm.value);
              return this.membersService.addTaskPartner(this.partnerForm.value, this.data.company.id).pipe(map(() => resp))
            } else {
              return of(resp)
            }
          }),
          switchMap((resp) => {
            this.additional.patchValue({
              task_operation_id: resp.id,
              status_id: resp.status_id
            })
            console.log("this.additional.value", this.additional.value);
            if (!!this.additional.value.employee_id && this.additional.value.employee_id.length) {
              let postData = [];
              let postInData = {...this.additional.value};
              delete postInData.employee_id
              this.additional.value.employee_id.forEach(element => {
                postData.push({
                  "path": `/api/task-employee/`,
                  "query": {},
                  "method": "POST",
                  "body": Object.assign({
                    [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
                    employee_id: element,
                }, postInData) 
                })
              });
              return this.taskService.multiRequest(postData).pipe(map(x => resp))
            } else {
              return of(resp)
            }
          }),
          switchMap((resp) => {
            let allClients = [...this.clients, ...this.partnerClients];
            if (allClients && allClients.length) {
              let postData = [];
              allClients.forEach(element => {
                postData.push({
                  "path": `/api/task-client/`,
                  "query": {company_id: this.company_id},
                  "method": "POST",
                  "body": Object.assign({
                    [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
                    task_id: this.task_id,
                    task_operation_id: resp.id,
                }, element) 
                })
              });
              return this.taskService.multiRequest(postData).pipe(map(x => resp))
            } else {
              return of(resp)
            }
          }),
          switchMap((resp) => {
            this.templateForm.patchValue({
              task_operation_id: resp.id,
              status_id: resp.status_id
            })
            console.log("this.templateForm.value", this.templateForm.value);
            if (!!this.templateForm.value.template_id && this.templateForm.value.template_id.length) {
              let postData = [];
              let postInData = {...this.templateForm.value};
              delete postInData.template_id
              this.templateForm.value.template_id.forEach(element => {
                postData.push({
                  "path": `/api/automation-scenario/`,
                  "query": {company_id: this.company_id},
                  "method": "POST",
                  "body": Object.assign({
                    [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
                    template_id: element,
                }, postInData) 
                })
              });
  
              return this.taskService.multiRequest(postData).pipe(
                switchMap(res => {
                  let tryData = [];
                  res.forEach(x => {
                    tryData.push({
                      "path": `/api/automation-scenario/execute/${x.body.id}/`,
                      "query": {company_id: this.company_id},
                      "method": "POST",
                      "body": {}
                    })
                  });
                  return this.taskService.multiRequest(tryData)
                }),
                map(x => resp)
              )
            } else {
              return of(resp)
            }
          })
        ).subscribe(resp => {
          this.isSubmit = false;
          this.dialogRef.close({event: "update", data: resp});
        })
      )
    }
    
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
