<div mat-dialog-title class="mobile_interface_title">
    <div class="mobile_interface_title_in" style="overflow: hidden;">
        <ng-container [ngSwitch]="selectedIndex.value">
            <ng-container *ngSwitchCase="0">
                <div class="tab_title" (click)="goBack()">
                    <div class="d-f-c">
                        <div class="icon_back">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7 12L6.11612 11.1161C5.62796 11.6043 5.62796 12.3957 6.11612 12.8839L7 12ZM16.8839 3.88388C17.372 3.39573 17.372 2.60427 16.8839 2.11612C16.3957 1.62796 15.6043 1.62796 15.1161 2.11612L16.8839 3.88388ZM15.1161 21.8839C15.6043 22.372 16.3957 22.372 16.8839 21.8839C17.372 21.3957 17.372 20.6043 16.8839 20.1161L15.1161 21.8839ZM7.88388 12.8839L16.8839 3.88388L15.1161 2.11612L6.11612 11.1161L7.88388 12.8839ZM6.11612 12.8839L15.1161 21.8839L16.8839 20.1161L7.88388 11.1161L6.11612 12.8839Z" fill="#232323"/>
                            </svg>
                        </div>
                        <span>
                            <ng-container *ngIf="!this.data.chat; else elseTemplateIsChat">
                                {{ "Set job status" | translate }}
                            </ng-container>
                            <ng-template #elseTemplateIsChat>
                                {{ "Set chat status" | translate }}
                            </ng-template>
                        </span>
                    </div>
                    <div class="small_title with_i">{{ "TAP STATUS OR DRAG USER AVATAR" | translate }}</div>
                </div>
            </ng-container>
            <ng-container *ngSwitchCase="1">
                <div class="tab_title" (click)="back()">
                    <div class="d-f-c">
                        <div class="icon_back">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7 12L6.11612 11.1161C5.62796 11.6043 5.62796 12.3957 6.11612 12.8839L7 12ZM16.8839 3.88388C17.372 3.39573 17.372 2.60427 16.8839 2.11612C16.3957 1.62796 15.6043 1.62796 15.1161 2.11612L16.8839 3.88388ZM15.1161 21.8839C15.6043 22.372 16.3957 22.372 16.8839 21.8839C17.372 21.3957 17.372 20.6043 16.8839 20.1161L15.1161 21.8839ZM7.88388 12.8839L16.8839 3.88388L15.1161 2.11612L6.11612 11.1161L7.88388 12.8839ZM6.11612 12.8839L15.1161 21.8839L16.8839 20.1161L7.88388 11.1161L6.11612 12.8839Z" fill="#232323"/>
                            </svg>
                        </div>
                        <span>
                            <ng-container *ngIf="!this.data.chat; else elseTemplateIsChat">
                                {{ "Set job status" | translate }}
                            </ng-container>
                            <ng-template #elseTemplateIsChat>
                                {{ "Set chat status" | translate }}
                            </ng-template>
                        </span>
                    </div>
                    <div class="small_title with_i" style="text-transform: uppercase;">
                        {{ "TAP EXECUTOR TO APPLY" | translate }} 
                        <span [ngClass]="{
                            'first': activeStatus?.id == '1',
                            'second': activeStatus?.id == '2',
                            'third': activeStatus?.id == '3',
                            'fours': activeStatus?.id == '4',
                            'fives': activeStatus?.id == '5',
                            'sixs': activeStatus?.id == '6',
                            'nine': activeStatus?.id == '97',
                            'seven': activeStatus?.id == '98',
                            'eight': activeStatus?.id == '99'}">
                            {{ activeStatus?.name }}
                        </span> 
                        {{ "STATUS" | translate }}
                    </div>
                </div>
            </ng-container>
        </ng-container>
    </div>
</div>
<mat-dialog-content class="mobile_interface_content">
    <mat-tab-group class="no_header" [selectedIndex]="selectedIndex.value" (selectedIndexChange)="changeIndex($event)">
        <!-- 0Price -->
        <mat-tab>
            <div class="mobile_scrollable_int" style="background: #fff !important;">
                <div class="interface" cdkDropListGroup>
                    <ng-container *ngFor="let status of emplStatuses">
                        <div class="line">
                            <div
                                cdkDropList
                                [cdkDropListData]="status"
                                [id]="status.id"
                                cdkDropListOrientation="horizontal"
                                class="example-list"
                                (cdkDropListDropped)="drop($event)" 
                                [ngClass]="{
                                'first': status.id == '1',
                                'second': status.id == '2',
                                'third': status.id == '3',
                                'fours': status.id == '4',
                                'fives': status.id == '5',
                                'sixs': status.id == '6',
                                'nine': status.id == '97',
                                'seven': status.id == '98',
                                'eight': status.id == '99'
                            }">
                                <div class="status_btn_out">
                                    <div class="status_btn text_one_line" (click)="selectStatus(status)" [ngClass]="{
                                        'active': !!data?.chat ? status.id == data?.chat?.status_id : status.id == data?.work?.status_id,
                                        'disabled': status.is_lock == 1 && !checkIsManager(data.task, data.company, data.user),
                                        'first': status.id == '1',
                                        'second': status.id == '2',
                                        'third': status.id == '3',
                                        'fours': status.id == '4',
                                        'fives': status.id == '5',
                                        'sixs': status.id == '6',
                                        'nine': status.id == '97',
                                        'seven': status.id == '98',
                                        'eight': status.id == '99'}">
                                        {{status.name}}
                                    </div>
                                </div>
                                
                                <div class="item_users">
                                    <div class="item_user" [cdkDragData]="item" cdkDrag (cdkDragEnded)="stopDragged()" (cdkDragStarted)="startDragged()" *ngFor="let item of status.empl" [ngClass]="{
                                        'first': status.id == '1',
                                        'second': status.id == '2',
                                        'third': status.id == '3',
                                        'fours': status.id == '4',
                                        'fives': status.id == '5',
                                        'sixs': status.id == '6',
                                        'nine': status.id == '97',
                                        'seven': status.id == '98',
                                        'eight': status.id == '99'}">
                                        <ng-container *ngIf="!item?.is_partner; else elseTemplateIsPartner">               
                                            <ng-container *ngIf="item?.employee?.image; else elseStatEmpl">
                                                <img class="partner_avatar" [src]="item?.employee?.image" alt="">
                                            </ng-container>
                                            <ng-template #elseStatEmpl>
                                                <mat-icon>account_circle</mat-icon>
                                            </ng-template>
                                        </ng-container>
                                        <ng-template #elseTemplateIsPartner>
                                            <ng-container *ngIf="!!item?.partnerCompany?.avatarFile?.thumbnail && !!data?.host; else elseTemplatePartnerAvatar">
                                                <img class="partner_avatar" (error)="onPartnerImgError($event)" [src]="data?.host + item?.partnerCompany?.avatarFile?.thumbnail + '?company_id=' + data?.company?.id" alt="">
                                            </ng-container>
                                            <ng-template #elseTemplatePartnerAvatar>
                                                <img class="partner_avatar" (error)="onPartnerImgError($event)" src="{{ data?.imgRoute }}assets/img/partner.png">
                                            </ng-template>
                                        </ng-template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    
                </div>
            </div>
        </mat-tab>
        <mat-tab>
            <div class="mobile_scrollable_int" style="background: #fff !important;">
                <div class="has_pads">
                    <div class="mob_stat_empl_wrp">
                        <div class="mob_stat_empl" *ngFor="let empl of employees" (click)="selectEmployee(empl)" [ngClass]="{
                            'active': empl?.is_select,
                            'first': activeStatus?.id == '1',
                            'second': activeStatus?.id == '2',
                            'third': activeStatus?.id == '3',
                            'fours': activeStatus?.id == '4',
                            'fives': activeStatus?.id == '5',
                            'sixs': activeStatus?.id == '6',
                            'nine': activeStatus?.id == '97',
                            'seven': activeStatus?.id == '98',
                            'eight': activeStatus?.id == '99'}">
                            <ng-container *ngIf="!empl.is_partner; else elseTemplatePartner">
                                <ng-container *ngIf="empl?.employee?.image; else elseStatEmplempl">
                                    <img [src]="empl?.employee?.image" alt="">
                                </ng-container>
                                <ng-template #elseStatEmplempl>
                                    <mat-icon>account_circle</mat-icon>
                                </ng-template>
                                <span>
                                    {{empl?.employee?.name}}
                                </span>
                            </ng-container>
                            <ng-template #elseTemplatePartner>
                                <ng-container *ngIf="!!empl?.partnerCompany?.avatarFile?.thumbnail && !!data?.host; else elseTemplatePartnerAvatar">
                                    <img class="partner_avatar" (error)="onPartnerImgError($event)" [src]="data?.host + empl?.partnerCompany?.avatarFile?.thumbnail + '?company_id=' + data?.company?.id" alt="">
                                </ng-container>
                                <ng-template #elseTemplatePartnerAvatar>
                                    <img class="partner_avatar" (error)="onPartnerImgError($event)" src="{{ data?.imgRoute }}assets/img/partner.png">
                                </ng-template>
                                <span>{{ empl.partnerCompany.name }}</span>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>
</mat-dialog-content>

<mat-dialog-actions>
    <div class="mobile_interface_buttons" style="justify-content: space-between !important;" [ngClass]="{'to_white': selectedIndex.value == 0 && changedEmployees.length == 0}">
        <ng-container [ngSwitch]="selectedIndex.value">
            <ng-container *ngSwitchCase="0">
                <button class="mobile_interface_btn" *ngIf="changedEmployees.length > 0" (click)="close()">
                    {{ "Cancel" | translate }}
                </button>

                <button class="mobile_interface_btn mobile_interface_btn_blue" (click)="onSubmit()" *ngIf="changedEmployees.length > 0" [disabled]="isSubmit">
                    <mat-spinner class="spin_16 mr-1" *ngIf="isSubmit"></mat-spinner>  
                    {{ "Apply" | translate }}
                </button>
            </ng-container>
            <ng-container *ngSwitchCase="1">
                <button class="mobile_interface_btn" (click)="back()">
                    {{ "Cancel" | translate }}
                </button>

                <div class="d-f-c">
                    <button class="mobile_interface_btn mobile_interface_btn_white" (click)="setAll()">
                        {{ "Set for all" | translate }}
                    </button>
                    <button class="mobile_interface_btn mobile_interface_btn_blue ml-1" (click)="swapEmployees()">
                        {{ "Apply" | translate }}
                    </button>
                </div>
            
            </ng-container>
        </ng-container>
    </div>
</mat-dialog-actions>
