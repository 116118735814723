<div class="container">

    <h2>
        {{ 'Stats' | translate }}: {{ company?.name }}
    </h2>
</div>


<div class="example-container mat-elevation-z8 creator_stats" #pointContainer>
    <table mat-table [dataSource]="employees">

    <ng-container matColumnDef="Employees" sticky>
        <th mat-header-cell *matHeaderCellDef> {{ "Employees" | translate }}\{{ "Month" | translate }} </th>
        <td mat-cell *matCellDef="let element"> 
            <div class="employee_user">
                <ng-container *ngIf="!!element.user_id; else elseOwnerTemplate">
                    <img *ngIf="element.user.image" [src]="element.user.image" alt="">
                    <mat-icon *ngIf="!element.user.image">account_circle</mat-icon>
                    <span>{{element.user.display_name}}</span>
                </ng-container>
                <ng-template #elseOwnerTemplate>
                    <mat-icon>account_circle</mat-icon>
                    <span>{{element.vuser?.name}}</span>
                </ng-template>
            </div>    
        </td>
    </ng-container>
    
    <ng-container *ngIf="displayedColumns.length > 1">
        <ng-container *ngFor="let col of displayedColumns.slice(1)" matColumnDef="{{+col}}">
            <th mat-header-cell *matHeaderCellDef class="centred_cols">
                <div class="th_custom head_custom">
                    <div class="stats_head">{{ col*1000 | date:'MMM y':'UTC':activeLang | yearPipe }}</div>
                    <div class="stats_value"> 
                        <span>
                            {{ "Points" | translate }}
                        </span>
                        <span >
                            {{ "Price" | translate }}
                        </span>
                    </div>
                </div>    
            </th>
            <td mat-cell *matCellDef="let element" class="centred_cols"> 
                <ng-container *ngIf="element?.stats[col]">
                    <div class="th_custom">
                        <div class="stats_value"> 
                            <ng-container *ngIf="element.stats[col].rate.summary_rate; else templateName">
                                <span matTooltipClass="dateTooltip" matTooltip="{{ 'Pending' | translate }}:{{ (+element.stats[col].rate.to_approve_summary_rate + +element.stats[col].rate.approved_summary_rate) | reportsNumber }}; {{ 'Ready' | translate }}:{{ element.stats[col].rate.ready_summary_rate | reportsNumber }} " [matTooltipPosition]="'above'">
                                    <a href="/tasks?company_id={{company_id}}&operation_completed_at_from={{+element.stats[col].rate.time_start}}&operation_completed_at_to={{+element.stats[col].rate.time_end}}&operation_status_id=3,4,98&operation_employee_id={{element.id}}" (click)="goToStats($event, element.stats[col].rate.time_start, element.stats[col].rate.time_end, [3,4,98], element.id)">
                                        {{ element.stats[col].rate.summary_rate | reportsNumber }} 
                                    </a>
                                    <div class="stats_percent" *ngIf="!!+element.rate_target">
                                        {{(+element.stats[col].rate.summary_rate/+element.rate_target)*100 | reportsNumber}}%
                                    </div>
                                </span>
                            </ng-container>
                            <ng-template #templateName>
                                <span></span>
                            </ng-template>
                            <ng-container *ngIf="element.stats[col].salary.length; else elseTemplateSoan">
                                <span>
                                    <ng-container *ngFor="let value of element.stats[col].salary; let i = index;">
                                        <ng-container *ngIf="+value.summary_price">
                                            <a href="/tasks?company_id={{company_id}}&operation_completed_at_from={{+element.stats[col].salary[0].time_start}}&operation_completed_at_to={{+element.stats[col].salary[0].time_end}}&operation_status_id=3,4,98&operation_employee_id={{element.id}}" (click)="goToStats($event, element.stats[col].salary[0].time_start, element.stats[col].salary[0].time_end, [3,4,98], element.id)">
                                                <ng-container [ngSwitch]="value.currency_id">
                                                  <ng-container *ngSwitchCase="'1'">&#36;</ng-container>
                                                  <ng-container *ngSwitchCase="'2'">&#8381;</ng-container>
                                                  <ng-container *ngSwitchCase="'3'">&#8364;</ng-container>
                                                  <ng-container *ngSwitchCase="'4'">&#8372;</ng-container>
                                                </ng-container>{{ value.summary_price | reportsNumber }}
                                            </a>
                                        </ng-container>
                                      </ng-container>
                                    <!-- <div class="stats_percent" *ngIf="!!+element.stats[col].salary.employee.salary">
                                        {{(+element.stats[col].salary.summary_price/+element.salary)*100 | reportsNumber}}%
                                    </div> -->
                                </span>
                            </ng-container>
                            <ng-template #elseTemplateSoan>
                                <span></span>
                            </ng-template>
                        </div>
                    </div> 
                    
                </ng-container>
                
            </td>
        </ng-container>
    </ng-container>
    

    <!-- <ng-container matColumnDef="Pending">
        <th mat-header-cell *matHeaderCellDef> {{ "" | translate }} </th>
        <td mat-cell *matCellDef="let element"> 
        {{ element.to_approve_summary_rate | reportsNumber }} ({{(+element.to_approve_summary_rate/+company?.rate_target)*100 | reportsNumber}}%)
        </td>
    </ng-container> -->

    <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
    <tr mat-row *matRowDef="let element; columns: displayedColumns;"></tr>

    </table>
</div>


<div class="container">
    <div class="btns_line">
        <button mat-raised-button (click)="goBack()">
            {{ "Back" | translate }}
        </button>
        <button class="ml-1 d-f-c" style="display: inline-flex !important;" mat-raised-button color='warn' (click)="refreshStats()">
            <mat-icon>refresh</mat-icon>
            {{ 'Update stats' | translate }}
        </button>
        <button color='primary' class="ml-1" *ngIf="pagination && page <= pagination['pageCount']" mat-raised-button (click)="getAllStats(page)">
            {{ "Load more" | translate }}
        </button>
    </div>
</div>