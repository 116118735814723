<div mat-dialog-title class="video_header">
    <div class="container video_header_inside">
        <div class="v_head v_header_left">
            <div class="video_title">
                <button class="ap_re_btn" style="color: green; width: 30px; height: 30px; line-height: 30px;" mat-icon-button *ngIf="data?.file && data?.task && (data.company?.permissions.includes('owner') || data.company?.permissions.includes('manager') || data.company?.permissions.includes('admin')) && !!data?.file?.task_operation_id && fileLocation(data?.file)" (click)="approveFile(data?.file)">
                    <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.4 6.3C15.4 5.523 14.77 4.9 14 4.9H9.576L10.248 1.701C10.262 1.631 10.269 1.554 10.269 1.477C10.269 1.19 10.15 0.924 9.961 0.735L9.219 0L4.613 4.606C4.354 4.865 4.2 5.215 4.2 5.6V12.6C4.2 12.9713 4.3475 13.3274 4.61005 13.5899C4.8726 13.8525 5.2287 14 5.6 14H11.9C12.481 14 12.978 13.65 13.188 13.146L15.302 8.211C15.365 8.05 15.4 7.882 15.4 7.7V6.3ZM0 14H2.8V5.6H0V14Z" fill="#219653"/>
                    </svg> 
                </button>              
                <button class="ap_re_btn" style="color: #DB0000; width: 30px; height: 30px; line-height: 30px;" mat-icon-button *ngIf="data?.file && data?.task && (data.company?.permissions.includes('owner') || data.company?.permissions.includes('manager') || data.company?.permissions.includes('admin')) && !!data?.file?.task_operation_id && fileLocation(data?.file)" (click)="rejectFile(data?.file)">
                    <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.6 8.4H15.4V0H12.6M9.8 0H3.5C2.919 0 2.422 0.35 2.212 0.854L0.098 5.789C0.035 5.95 0 6.118 0 6.3V7.7C0 8.0713 0.1475 8.4274 0.410051 8.68995C0.672602 8.9525 1.0287 9.1 1.4 9.1H5.817L5.152 12.299C5.138 12.369 5.131 12.439 5.131 12.516C5.131 12.81 5.25 13.069 5.439 13.258L6.181 14L10.787 9.387C11.046 9.135 11.2 8.785 11.2 8.4V1.4C11.2 0.623 10.57 0 9.8 0Z" fill="#DB0000"/>
                    </svg>  
                </button>              
                <button mat-icon-button style="color: #686868;" (click)="copy(data?.file)">
                    <mat-icon>file_copy</mat-icon>
                </button>
                <button mat-icon-button style="color: #686868;" ngxClipboard [cbContent]="origin + '/file/' + data.file?.acm" (cbOnSuccess)="copyLink('file')">
                    <mat-icon>link</mat-icon>
                </button>
                {{data?.file?.filename}}
                <button mat-icon-button style="color: #f39c12;" (click)="settings()">
                    <mat-icon>info</mat-icon>
                </button>
            </div>
            <div class="video_info" (click)="checkTime()">
                <div class="video_size">
                    {{ "Size" }}: {{data?.file?.filesize | filesize}}
                </div>
                <div class="video_location">
                    <mat-icon>folder</mat-icon> {{ fileLocationString | translate }} <span class="same_count" *ngIf="!!data?.file?.countSameFiles && data?.file?.countSameFiles > 0" (click)="openSameFiles()">+{{data?.file?.countSameFiles}}</span>
                </div>
                <div #viewTippyTemplate class="fm_ch_tooltip">
                    <div class="chat_name" *ngFor="let item of fileChats" (click)="$event.preventDefault(); $event.stopPropagation(); openChat(item.id, 0);">
                        <span class="text_one_line">{{item.name}}</span>
                    </div>
                </div>
                <div *ngIf="!!fileChats && !!fileChats.length" class="privacy_int_btn" 
                [ngxTippy]="viewTippyTemplate"
                [tippyProps]="{
                  trigger: 'click'
                }">
                    <mat-icon style="margin: 0;">chat</mat-icon>
                </div>
                <div class="file_resolution">{{data?.file?.meta_width + "px &#10005; " + data?.file?.meta_height + "px"}}</div>
                <div *ngIf="!!data.boxFiles && !data.boxFiles.isSelected(data.file)" class="privacy_int_btn" (click)="addToBox(data.file)">
                    <mat-icon style="margin: 0;">add_shopping_cart</mat-icon>
                </div>
                <div *ngIf="(data.company?.permissions.includes('owner') || checkIsManager(data.task, data.company, data.user))" class="privacy_int_btn" (click)="privacy(data.file)">
                    <mat-icon style="margin: 0;">lock_open</mat-icon>
                    <!-- {{ 'Privacy' | translate }} -->
                </div>
                <div *ngIf="data.file && canTranscribe(data?.file?.filename)" class="privacy_int_btn" (click)="transcribeFile(data.file)">
                    <mat-icon style="margin-right: 2.5px;">record_voice_over</mat-icon>
                    {{ 'Speech to Text' | translate }}
                </div>
                <div *ngIf="company && (company | perm:'see_task_channels') && data?.task && data?.task?.uniqueChannels" class="privacy_int_btn view_publish_to" (click)="open($event, data.file); $event.preventDefault();">
                    <mat-icon style="margin-right: 2.5px;">publish</mat-icon>
                    {{ 'Publish to' | translate }}
                </div>
                <div *ngIf="!!data.boxFiles && data.boxFiles.isSelected(data.file)" class="privacy_int_btn" (click)="removeFromBox(data.file)">
                    <mat-icon>remove_shopping_cart</mat-icon>
                </div>
                <div class="video_date">
                    {{ "Uploaded at" }}: 
                    {{ data?.file?.created_at*1000 | date:'MMM d, YYYY, HH:mm':'': data.activeLang | yearPipe }}
                    {{ "by" }}:
                    <div class="file_creator d-f-c ml-1">
                        <div class="file_creator_img">
                            <ng-container *ngIf="data?.file?.createdEmployee?.image; else elseTemplateUserAvatar">
                                <img [src]="data?.file?.createdEmployee?.image" alt="">
                            </ng-container>
                            <ng-template #elseTemplateUserAvatar>
                                <mat-icon>account_circle</mat-icon>
                            </ng-template>
                        </div>
                        <div class="file_creator_name">{{data?.file?.createdEmployee?.name}}</div>
                    </div>
                    <span class="download_btn" (click)="download(data?.file, 'full')">
                        <mat-icon>download</mat-icon>
                        {{ "Full" | translate }}
                    </span>
                    <ng-container *ngIf="data?.file?.content_type.indexOf('video') != -1; else elseTemplateMovie">              
                        <span class="download_btn download_btn_c" *ngIf="!!data?.file?.preview1080" (click)="download(data?.file, 'conv')">
                            <mat-icon>download</mat-icon>
                            {{ "Lite" | translate }}
                        </span>
                        <span class="download_btn download_btn_w" *ngIf="!!data?.file?.thumbnail" (click)="download(data?.file, 'thum')">
                            <mat-icon>download</mat-icon>
                            {{ "Thumbnail" | translate }}
                        </span>
                    </ng-container>
                    <ng-template #elseTemplateMovie>
                        <span class="download_btn download_btn_c" *ngIf="!!data?.file?.thumbnail" (click)="download(data?.file, 'thum')">
                            <mat-icon>download</mat-icon>
                            {{ "Lite" | translate }}
                        </span>
                    </ng-template>
                </div>               
            </div>
        </div>
        <div class="v_head v_header_center">
            <div class="file_tags">
                <ng-container *ngIf="data?.file?.tags">
                    <div class="file_tag" *ngFor="let tag of data.file.tags; let t = index;">
                        {{tag?.parameterValue?.value}}
                        <div class="remove_out">
                            <div class="remove" (click)="onRemove(data.file, tag, t)">
                                <svg style="width: 5px; height: 5px;" width="5" height="5" viewBox="0 0 5 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5 0.503571L4.49643 0L2.5 1.99643L0.503571 0L0 0.503571L1.99643 2.5L0 4.49643L0.503571 5L2.5 3.00357L4.49643 5L5 4.49643L3.00357 2.5L5 0.503571Z" fill="white"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <div class="add_tag cp" (click)="openTargetValues([data?.file?.task_id, data?.file?.task_operation_id ? data?.file?.task_operation_id : 0, data?.file?.discussion_id ? data?.file?.discussion_id : 0, data?.file?.id], data?.file?.task || data?.task)">
                    <svg style="width: 16px; height: 16px;" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 8.8H8.8V12H7.2V8.8H4V7.2H7.2V4H8.8V7.2H12M8 0C6.94943 0 5.90914 0.206926 4.93853 0.608964C3.96793 1.011 3.08601 1.60028 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.08601 14.3997 3.96793 14.989 4.93853 15.391C5.90914 15.7931 6.94943 16 8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 6.94943 15.7931 5.90914 15.391 4.93853C14.989 3.96793 14.3997 3.08601 13.6569 2.34315C12.914 1.60028 12.0321 1.011 11.0615 0.608964C10.0909 0.206926 9.05058 0 8 0Z" fill="#C4C4C4"/>
                    </svg>
                    {{ "Tag" | translate }}
                </div>
            </div>
            <div class="platforms_file_view" *ngIf="data?.file && data?.file?.taskChannelFiles && data?.file?.taskChannelFiles.length">
                <ng-container *ngFor="let item of data?.file?.taskChannelFiles">
                    <a *ngIf="item?.taskChannel?.content_status_id != 8" [ngClass]="{'b_w': [6,7,9].includes(item?.taskChannel?.content_status_id)}" [href]="item?.taskChannel?.content_url" target="_blank" (click)="$event.stopPropagation();" #tooltip="matTooltip" matTooltip="{{ (!!item?.taskChannel?.content_views_count ? ('(' + (item?.taskChannel?.content_views_count | number:'':'fr-FR') + ') - ') : '') + item?.taskChannel?.channel?.name + ': ' + item?.taskChannel?.content_name }}" [matTooltipPosition]="'left'">
                        <app-platform [platform_id]="item?.taskChannel?.channel?.platform_id"></app-platform>
                    </a>
                </ng-container>
            </div>
        </div>
        <div class="v_head v_header_right" *ngIf="!!data?.task">

            <div class="task_info">
                <div class="task_name text_one_line cp" (click)="openTask(data?.file)">{{ data?.task?.name }}</div>

                <div class="d-f-c cp" *ngIf="data.work" (click)="openJob(data?.file)">
                    <div class="job_type text_one_line">
                        {{ getColumnName(data.work.operation_id) }}
                    </div>
                    <div class="mv_job_id">
                        {{data.work.id}}
                    </div>
                </div>

                <div *ngIf="selectedChats.selected.length" class="multi_chats">
                    <div class="btn_w8 btn_w8_24" (click)="moveChats()">
                        {{"Move to other job" | translate}}
                    </div>
                </div>
                <!-- <div class="type_val_wrp">
          
                    <div class="type_val"></div>
                    <div class="job_private_comment" *ngIf="data.work.private_comment">
                        {{ data.work.private_comment.length > 20 ? (data.work.private_comment|slice:0:17) + '...' : data.work.private_comment }}
                    </div>
                </div>
                <ng-container *ngIf="data.work.comment">
                    <div class="job_comment">{{ data.work.comment.length > 35 ? (data.work.comment|slice:0:30) + '...' : data.work.comment }}</div>
                </ng-container>
                <div class="empl_comment" *ngIf="data.work.employee_comment">
                    {{ data.work.employee_comment.length > 35 ? (data.work.employee_comment|slice:0:32) + '...' : data.work.employee_comment }}
                </div> -->
                
                <!-- <ng-container *ngIf="data?.task.comment">
                    <ng-container *ngIf="data?.task.comment.length > 51; else elseTemplateComment">
                        <div class="task_comment fw400">
                            {{ data?.task?.comment | slice:0:38 }} ...<span style="cursor: pointer;" (click)="open($event, data?.task.comment); $event.preventDefault(); $event.stopPropagation();">{{ "more" | translate }}</span>
                        </div>
                    </ng-container>
                    <ng-template #elseTemplateComment>
                        <div class="task_comment fw400" [innerHTML]="data?.task.comment | linky:{newWindow: true}"></div>
                    </ng-template>
                </ng-container>
                <div class="task_comment fw400" *ngIf="!data?.task.comment">
                    &nbsp;
                </div>

                <ng-container *ngIf="data?.task.private_comment">
                    <ng-container *ngIf="data?.task.private_comment.length > 70; else elseTemplateprivate_comment">
                      <div class="task_private">
                        {{ data?.task?.private_comment | slice:0:60 }} ... <span style="cursor: pointer;" (click)="open($event, data?.task.private_comment); $event.preventDefault(); $event.stopPropagation();">{{ "read more" | translate }}</span>
                      </div>
                    </ng-container>
                    <ng-template #elseTemplateprivate_comment>
                      <div class="task_private" [innerHTML]="data?.task.private_comment | linky:{newWindow: true}"></div>
                    </ng-template>
                </ng-container>
                <div class="task_private fw400" *ngIf="!data?.task.private_comment">
                    &nbsp;
                </div> -->
            </div>
            <div class="task_ids">
                <div>{{data?.task.id}}</div>
                <div>{{data?.task.custom_id}}</div>
            </div>
        </div>

    </div>
</div>
<mat-dialog-content class="mat-typography">
    <div class="preview_wrp container">
        <div class="video_wrp" [ngClass]="{'full_video': !data?.work}">
            <div class="nav_next" (click)="navNext()" *ngIf="data.playlist && data.playlist.length > 1">
                <mat-icon>navigate_next</mat-icon>
            </div>
            <div class="nav_before" (click)="navBefore()" *ngIf="data.playlist && data.playlist.length > 1">
                <mat-icon>navigate_before</mat-icon>
            </div>
            <ng-container *ngIf="!!data.file; else elseTemplateNoFile">
                <ng-container *ngIf="data?.file?.content_type.indexOf('video') != -1; else elseTemplatePhoto">
                    <div class="player_wrp" #videoPlayerParrent>
                        <ng-container *ngIf="company?.id || data.is_public">
                            <ng-container *ngIf="!defaultPlayer; else elseTemplate">
                                <vm-player *ngIf="!srcIsChanging" (vmCurrentTimeChange)="onTimeChange($event)" [ngClass]="{'frame_is_active': frame_is_active}" (vmCurrentSrcChange)="onSrcChange($event)" [class]="vm_theme" #videoPlayer theme="dark" style="--vm-player-theme: #3f51b5;">  
                                    <app-drawer [selectedChat]="selectedChat" [color]="color" [strokeWidth]="strokeWidth" [only_show]="!new_chat" [ngClass]="{'only_show': !new_chat}" *ngIf="(new_chat && is_draw && is_add_data) || (selectedChat && selectedChat.data && selectedChat.data.file_timestamps.length > 1 && selectedChat.data.file_timestamps[1].draws && selectedChat.data.file_timestamps[1].type == 'drawer' && selectedChat.data.file_timestamps[1].draws.length && selectedChat.data.file_timestamps[0].currentTime == videoplayer?.currentTime)"></app-drawer>
                                    <vm-video>
                                        <source [src]="host + videoSrc + (!videoWithotWorkspaceID ? ('?company_id=' + company?.id) : '')" [type]="data?.file?.content_type == 'video/quicktime' ? 'video/mp4' : data?.file?.content_type"/>
                                    </vm-video>

                                    <vm-ui *ngIf="!is_draw">
                                        <vm-controls>
                                          <vm-control-group>
                                              <div #points class="points">
                                                <ng-container *ngFor="let point of data.file.points">
                                                    <div class="point" (click)="$event.preventDefault(); $event.stopPropagation(); selectChat(point.chat)"
                                                    [ngStyle]="{'left': (point.currentTime/videoplayer?.duration * 100) + '%'}" 
                                                    #tooltip="matTooltip" matTooltip="{{ point?.chat?.name }}" [matTooltipPosition]="'above'"
                                                    [ngClass]="{
                                                        'first': point.status_id == '1',
                                                        'second': point.status_id == '2',
                                                        'third': point.status_id == '3',
                                                        'fours': point.status_id == '4',
                                                        'fives': point.status_id == '5',
                                                        'sixs': point.status_id == '6',
                                                        'nine': point.status_id == '97',
                                                        'seven': point.status_id == '98',
                                                        'eight': point.status_id == '99'
                                                    }"></div>
                                                </ng-container>
                                              </div>
                                            <vm-scrubber-control>
                                            </vm-scrubber-control>
                                          </vm-control-group>
                                    
                                          <vm-control-group space="top">
                                            <vm-playback-control></vm-playback-control>
                                            <vm-volume-control></vm-volume-control>
                                            <vm-time-progress></vm-time-progress>
                                            
                                            <div class="vm_custom_btn with_number" (click)="changeVideoTime('replay')">
                                                <button style="color: #fff;" mat-icon-button>
                                                    <mat-icon>replay</mat-icon>
                                                    <span class="number">{{timeVal}}</span>
                                                </button>
                                            </div>

                                            <div class="vm_custom_btn with_number" (click)="changeVideoTime('forward')">
                                                <button style="color: #fff;" mat-icon-button>
                                                    <mat-icon style="transform: scaleX(-1);">replay</mat-icon>
                                                    <span class="number">{{timeVal}}</span>
                                                </button>
                                            </div>
                                            <div class="vm_custom_btn">
                                                <button style="color: #fff;" mat-icon-button [matMenuTriggerFor]="timeValueMenu" aria-label="Example icon-button with a menu">
                                                    {{timeVal}}s
                                                </button>
                                                <mat-menu #timeValueMenu="matMenu" class="ratio_value_menu_class" overlayPanelClass='ratio_value_menu' yPosition="above" xPosition="after">

                                                    <button mat-menu-item (click)="changeTime(1)">
                                                        <span>1s</span>
                                                    </button>
                                                    <button mat-menu-item (click)="changeTime(3)">
                                                        <span>3s</span>
                                                    </button>
                                                    <button mat-menu-item (click)="changeTime(5)">
                                                        <span>5s</span>
                                                    </button>
                                                    <button mat-menu-item (click)="changeTime(10)">
                                                        <span>10s</span>
                                                    </button>
                                                    <button mat-menu-item (click)="changeTime(20)">
                                                        <span>20s</span>
                                                    </button>
                                                    <button mat-menu-item (click)="changeTime(30)">
                                                        <span>30s</span>
                                                    </button>
                                                    <button mat-menu-item (click)="changeTime(60)">
                                                        <span>60s</span>
                                                    </button>
                                                </mat-menu>      
                                            </div>

                                            <vm-control-spacer></vm-control-spacer>

                                            <div class="vm_custom_spliter_out" *ngIf="data?.file?.preview1080">
                                                <div class="vm_custom_spliter">
                                                    <div class="vm_spliter_btn">
                                                        <button style="color: #fff;" [ngClass]="{'active_vm_btn': videoSrc == data.file.preview1080}" mat-icon-button (click)="changeSrc('lite')">
                                                            1080 <ng-container *ngIf="data?.file?.meta_height && data?.file?.meta_height == 1080">Lite</ng-container>
                                                        </button>
                                                    </div>
                                                    <div class="vm_spliter_btn">
                                                        <button style="color: #fff;" [ngClass]="{'active_vm_btn': videoSrc == data.file.original}" mat-icon-button (click)="changeSrc('full')">
                                                            {{ data?.file?.meta_height ? data?.file?.meta_height : 'Full' }}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="vm_custom_spliter_out">
                                                <div class="vm_custom_spliter">
                                                    <div class="vm_spliter_btn">
                                                        <button style="color: #fff;" [ngClass]="{'active_vm_btn': videoplayer?.playbackRate == 1}" mat-icon-button (click)="changeRate(1)">
                                                            x1
                                                        </button>
                                                    </div>
                                                    <div class="vm_spliter_btn">
                                                        <button style="color: #fff;" [ngClass]="{'active_vm_btn': videoplayer?.playbackRate == 2}" mat-icon-button (click)="changeRate(2)">
                                                            x2
                                                        </button>
                                                    </div>
                                                    <div class="vm_spliter_btn vm_spliter_btn_menu">
                                                        <button style="color: #fff;" [ngClass]="{'active_vm_btn': videoplayer?.playbackRate != 1 && videoplayer?.playbackRate != 2}" mat-icon-button [matMenuTriggerFor]="ratioValueMenu" aria-label="Example icon-button with a menu">
                                                            <ng-container *ngIf="videoplayer?.playbackRate == 1 || videoplayer?.playbackRate == 2; else elseTemplateNotDefaultRate">
                                                                <mat-icon>more_horiz</mat-icon>
                                                            </ng-container>
                                                            <ng-template #elseTemplateNotDefaultRate>
                                                                x{{videoplayer?.playbackRate}}
                                                            </ng-template>
                                                        </button>
                                                        <mat-menu #ratioValueMenu="matMenu" class="ratio_value_menu_class" overlayPanelClass='ratio_value_menu' yPosition="above" xPosition="before">
        
                                                            <button mat-menu-item (click)="changeRate(0.25)">
                                                                <span>0.25</span>
                                                            </button>
                                                            <button mat-menu-item (click)="changeRate(0.5)">
                                                                <span>0.5</span>
                                                            </button>
                                                            <button mat-menu-item (click)="changeRate(0.75)">
                                                                <span>0.75</span>
                                                            </button>
                                                            <button mat-menu-item (click)="changeRate(1)">
                                                                <span>1</span>
                                                            </button>
                                                            <button mat-menu-item (click)="changeRate(1.25)">
                                                                <span>1.25</span>
                                                            </button>
                                                            <button mat-menu-item (click)="changeRate(1.5)">
                                                                <span>1.5</span>
                                                            </button>
                                                            <button mat-menu-item (click)="changeRate(2)">
                                                                <span>2</span>
                                                            </button>
                                                        </mat-menu>      
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="vm_custom_spliter_out">
                                                <div class="vm_custom_spliter">
                                                    <div class="vm_spliter_btn">
                                                        <button style="color: #fff;" [ngClass]="{'active_vm_btn': vm_theme == 'light'}" mat-icon-button (click)="changeTheme('light')">
                                                            light
                                                        </button>
                                                    </div>
                                                    <div class="vm_spliter_btn">
                                                        <button style="color: #fff;" [ngClass]="{'active_vm_btn': vm_theme == 'dark'}" mat-icon-button (click)="changeTheme('dark')">
                                                            dark
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="vm_custom_btn" (click)="toggleFrame()">
                                                <button style="color: #fff;" mat-icon-button>
                                                    <mat-icon *ngIf="!frame_is_active">crop_landscape</mat-icon>
                                                    <mat-icon *ngIf="!!frame_is_active">filter_frames</mat-icon>
                                                </button>
                                            </div>

                                            <vm-pip-control keys="p"></vm-pip-control>
                                            <vm-fullscreen-control tooltip-direction="left"></vm-fullscreen-control>
                                          </vm-control-group>
                                        </vm-controls>
                                    </vm-ui>
                                </vm-player>
                            </ng-container>
                            <ng-template #elseTemplate>
                                <video width="100%" class="default_videoplayer" controls style="max-height: 60vh;">
                                    <source [src]="host + (data?.file?.preview1080 ? data?.file?.preview1080 : data?.file?.original) + (!videoWithotWorkspaceID ? ('?company_id=' + company?.id) : '')" [type]="data?.file?.content_type == 'video/quicktime' ? 'video/mp4' : data?.file?.content_type"/>
                                    Your browser doesn't support HTML5 video tag.
                                </video>
                            </ng-template>
                        </ng-container>
                    </div>
                </ng-container>
                <ng-template #elseTemplatePhoto>

                    <ng-container *ngIf="data?.file?.content_type == 'application/pdf'; else elseTemplatePDF">
                              <!-- <mat-form-field class="mb">
                                <input matInput type="text" placeholder="Set URL of the PDF file" [(ngModel)]="pdfSrc">
                              </mat-form-field>
                          
                              <div class="select-file__container">
                                <p>
                                  Or render preview by just selecting PDF file (no upload required)
                                </p>
                                <input (change)="onFileSelected()" type="file" id="file">
                              </div>
                          
                              <div *ngIf="error" class="error mb">
                                {{ error.message | json }}
                              </div>
                          
                              <div class="mb">
                                <mat-slide-toggle [(ngModel)]="originalSize">
                                  Original size
                                </mat-slide-toggle>
                              </div>
                          
                              <div class="mb">
                                <mat-slide-toggle [(ngModel)]="fitToPage">
                                  Fit to page
                                </mat-slide-toggle>
                              </div>
                          
                              <div class="mb">
                                <mat-slide-toggle [(ngModel)]="renderText">
                                  Render text layer
                                </mat-slide-toggle>
                              </div>
                          
                              <div class="mb">
                                <mat-slide-toggle [(ngModel)]="autoresize">
                                  Auto resize
                                </mat-slide-toggle>
                              </div>
                          
                              <div class="mb">
                                <mat-slide-toggle [(ngModel)]="showAll">
                                  Show all pages
                                </mat-slide-toggle>
                              </div>
                          
                              <mat-form-field *ngIf="!originalSize">
                                <mat-label>Select an option</mat-label>
                                <mat-select [(value)]="zoomScale">
                                  <mat-option value="page-width">Page Width</mat-option>
                                  <mat-option value="page-height">Page Height</mat-option>
                                  <mat-option value="page-fit">Page Fit</mat-option>
                                </mat-select>
                              </mat-form-field>
                          
                              <div class="mb">
                                <button (click)="incrementZoom(-0.1)" mat-button type="button">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none"
                                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                    <circle cx="11" cy="11" r="8"></circle>
                                    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                                    <line x1="8" y1="11" x2="14" y2="11"></line>
                                  </svg>
                                </button>
                          
                                <mat-form-field class="page-number">
                                  <input matInput type="number" placeholder="Zoom" [(ngModel)]="zoom" pattern="-?[0-9]*(\.[0-9]+)?">
                                  <mat-error>Input is not a number!</mat-error>
                                </mat-form-field>
                          
                                <button (click)="incrementZoom(0.1)" mat-button type="button">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none"
                                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                    <circle cx="11" cy="11" r="8"></circle>
                                    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                                    <line x1="11" y1="8" x2="11" y2="14"></line>
                                    <line x1="8" y1="11" x2="14" y2="11"></line>
                                  </svg>
                                </button>
                              </div>
                          
                          
                              <div *ngIf="showAll" class="mb">
                                <mat-slide-toggle [(ngModel)]="stickToPage">
                                  Stick to page
                                </mat-slide-toggle>
                              </div>
                          
                              <div *ngIf="stickToPage && showAll || !showAll" class="mb">
                                <button (click)="incrementPage(-1)" mat-button type="button">
                                  Previous
                                </button>
                          
                                <mat-form-field class="page-number">
                                  <input matInput type="number" placeholder="Page" [(ngModel)]="page" pattern="-?[0-9]*(\.[0-9]+)?">
                                  <mat-error>Input is not a number!</mat-error>
                                </mat-form-field>
                          
                                <span *ngIf="pdf">of {{ pdf.numPages }}</span>
                                <button (click)="incrementPage(1)" mat-button type="button">
                                  Next
                                </button>
                              </div>
                          
                              <div class="mb">
                                <button (click)="rotate(-90)" mat-button type="button" matTooltip="Rotate left" [matTooltipPosition]="'above'">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none"
                                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" transform="scale(-1,1)">
                                    <polyline points="23 4 23 10 17 10"></polyline>
                                    <path d="M20.49 15a9 9 0 1 1-2.12-9.36L23 10"></path>
                                  </svg>
                                </button>
                          
                                <mat-form-field class="page-number">
                                  <input matInput type="number" placeholder="Rotation" [ngModel]="rotation" disabled>
                                  <mat-error>Input is not a number!</mat-error>
                                </mat-form-field>
                          
                                <button (click)="rotate(90)" mat-button type="button" matTooltip="Rotate right" [matTooltipPosition]="'above'">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none"
                                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                    <polyline points="23 4 23 10 17 10"></polyline>
                                    <path d="M20.49 15a9 9 0 1 1-2.12-9.36L23 10"></path>
                                  </svg>
                                </button>
                              </div>
                          
                              <div *ngIf="showAll" class="mb">
                                <mat-slide-toggle [(ngModel)]="isOutlineShown">
                                  Show outline
                                </mat-slide-toggle>
                              </div>
                          
                              <div *ngIf="showAll && isOutlineShown">
                                <ul *ngFor="let item of outline" class="outline-list">
                                  <li>
                                    <a (click)="navigateTo(item.dest)">
                                      {{ item.title }}
                                    </a>
                          
                                    <ul *ngFor="let child of item.items">
                                      <li>
                                        <a (click)="navigateTo(child.dest)">
                                          {{ child.title }}
                                        </a>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </div>
                          
                              <div class="mb">
                                <mat-form-field>
                                  <input #queryInp matInput id="pdfQueryInput" type="text" placeholder="Search..." [value]="pdfQuery"
                                    (input)="searchQueryChanged($event.target.value)" (keyup.enter)="searchQueryChanged(queryInp.value)">
                                  <mat-hint>Search in entire pdf</mat-hint>
                                </mat-form-field>
                              </div>
                          
                              <div *ngIf="!isLoaded && !error && progressData" id="progress">
                                <div class="bg">
                                  <div class="bar" [style.width]="progressData.loaded / progressData.total * 100 + '%'"></div>
                                </div>
                                <span>{{ getInt(progressData.loaded / progressData.total * 100) }}%</span>
                              </div> -->
                          
                        <div class="pdf_wrp" #videoPlayerParrent>
                            <div class="pdf_nav">
                                <div class="zoom_wrp">
                                    <button (click)="incrementZoom(-0.1)" type="button">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none"
                                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                            <circle cx="11" cy="11" r="8"></circle>
                                            <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                                            <line x1="8" y1="11" x2="14" y2="11"></line>
                                        </svg>
                                    </button>
                              
                                    <input class="zoom_number" matInput type="number" placeholder="Zoom" [(ngModel)]="data.file.zoom" pattern="-?[0-9]*(\.[0-9]+)?">
                              
                                    <button (click)="incrementZoom(0.1)" type="button">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none"
                                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                        <circle cx="11" cy="11" r="8"></circle>
                                        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                                        <line x1="11" y1="8" x2="11" y2="14"></line>
                                        <line x1="8" y1="11" x2="14" y2="11"></line>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                            <div class="pdf_loader" *ngIf="progressData && !isLoaded">
                                <div class="value">
                                    {{progressData?.percent}}%
                                </div>
                                <mat-progress-bar
                                    class="pdf_progress"
                                    [color]="'primary'"
                                    [mode]="'determinate'"
                                    [value]="progressData?.percent">
                                </mat-progress-bar>
                            </div>
                            <pdf-viewer [src]="pdfSrc"
                                [(page)]="page"
                                [rotation]="rotation"
                                [original-size]="originalSize"
                                [show-all]="showAll"
                                [fit-to-page]="fitToPage"
                                (after-load-complete)="afterLoadComplete($event)"
                                [zoom]="data.file.zoom"
                                [zoom-scale]="data.file.zoomScale"
                                [stick-to-page]="stickToPage"
                                [render-text]="renderText"
                                [render-text]="renderText"
                                [external-link-target]="'blank'"
                                [autoresize]="autoresize"
                                (error)="onError($event)"
                                (on-progress)="onProgress($event)"
                                (page-rendered)="pageRendered($event)"
                                (pages-initialized)="pageInitialized($event)"
                                (pageChange)="pageChange($event)"
                                [show-borders]="false"
                            ></pdf-viewer>
                        </div>
                    </ng-container>
                    <ng-template #elseTemplatePDF>

                        <ng-container *ngIf="data?.file?.content_type.indexOf('audio') != -1; else elseTemplateAudio">
                            <!-- <mat-icon>music_note</mat-icon>  -->
                            <div class="audio_wrp">
                                <app-audio-player
                                    [host]="host"
                                    [company_id]="company?.id"
                                    [fileOriginal]="data?.file?.original"
                                    [fileIsUpload]="data?.file?.is_uploaded"
                                    [file]="data?.file"
                                    [forFM]="true"
                                ></app-audio-player>
                            </div>
                        </ng-container>
                        
                        <ng-template #elseTemplateAudio>
                            <ng-container *ngIf="data?.file?.is_document; else elseTemplateIsDocFile">
                                <ng-container *ngIf="data?.file?.content_type == 'reports-json-auto-post'; else elseTemplateDefDoc">
                                    <app-add-edit-post-document [initData]="{
                                        company: this.company,
                                        company_id: data?.file.company_id,
                                        task_id: data?.file?.task_id,
                                        work_id: data?.file?.task_operation_id,
                                        location: data?.file?.location,
                                        files: data?.files,
                                        playlist: data?.playlist,
                                        file: data?.file,
                                        is_file: true,
                                        is_edit: true,
                                        user: data?.user,
                                        note: data?.file?.document
                                    }"></app-add-edit-post-document>
                                </ng-container>
                                <ng-template #elseTemplateDefDoc>
                                    <app-add-edit-note [initData]="{
                                        company: this.company,
                                        company_id: data?.file.company_id,
                                        task_id: data?.file?.task_id,
                                        work_id: data?.file?.task_operation_id,
                                        location: data?.file?.location,
                                        file: data?.file,
                                        files: data?.files,
                                        playlist: data?.playlist,
                                        is_file: true,
                                        is_edit: true,
                                        user: data?.user,
                                        note: data?.file?.document
                                    }"></app-add-edit-note>
                                </ng-template>
                            </ng-container>
                            <ng-template #elseTemplateIsDocFile>
                                <div class="photo_wrp" #videoPlayerParrent>
                                    <app-drawer [selectedChat]="selectedChat" [color]="color" [ngStyle]="{'transform': 'scale('+data?.file?.zoom+')' + (!!data?.file?.translate ? (' ' + data?.file?.translate) : '')}" [strokeWidth]="strokeWidth" [ngClass]="{'only_show': !new_chat}" *ngIf="(new_chat && is_draw && is_add_data) || (selectedChat && selectedChat.data && selectedChat.data.file_timestamps.length > 1 && selectedChat.data.file_timestamps[1].draws && selectedChat.data.file_timestamps[1].type == 'drawer' && selectedChat.data.file_timestamps[1].draws.length && selectedChat.data.file_timestamps[0].currentTime == videoplayer?.currentTime)"></app-drawer>
                                    <!-- <app-draw *ngIf="new_chat || (selectedChat && selectedChat.data && selectedChat.data.file_timestamps.length > 1 && selectedChat.data.file_timestamps[1].draw.length)"></app-draw> -->
                                    <div class="photo_changer d-f-c">
                                        <!-- dragging{{dragging}} | startX{{startX}} | startY{{startY}} | offsetX{{offsetX}} | offsetY{{offsetY}} | {{data?.file?.translate}} | {{'scale('+data?.file?.zoom+')' + (!!data?.file?.translate ? (' ' + data?.file?.translate) : '')}} -->
                                        <div class="vm_custom_spliter_out" *ngIf="data?.file?.thumbnail && data?.file?.content_type.indexOf('video') == -1">
                                            <div class="vm_custom_spliter">
                                                <div class="vm_spliter_btn">
                                                    <button style="color: #fff;" [ngClass]="{'active_vm_btn': imgSrc == data.file.thumbnail}" mat-icon-button (click)="changeSrc('lite', true)">
                                                        Lite
                                                    </button>
                                                </div>
                                                <div class="vm_spliter_btn">
                                                    <button style="color: #fff;" [ngClass]="{'active_vm_btn': imgSrc == data.file.original}" mat-icon-button (click)="changeSrc('full', true)">
                                                        {{ data?.file?.meta_width ? data?.file?.meta_width : 'Full' }}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="vm_custom_spliter_out ml-1" *ngIf="!is_draw && data?.file?.thumbnail && data?.file?.content_type.indexOf('video') == -1">
                                            <div class="vm_custom_spliter">
                                                <div class="vm_spliter_btn" style="font-size: 24px; line-height: 14px;">
                                                    <button style="color: #fff;" [ngClass]="{'active_vm_btn': data?.file?.zoom > 1}" mat-icon-button (click)="incrementZoom(0.1)">
                                                        +
                                                    </button>
                                                </div>
                                                <div class="vm_spliter_btn" style="font-size: 24px; line-height: 14px;">
                                                    <button style="color: #fff;" [ngClass]="{'active_vm_btn': data?.file?.zoom < 1}" mat-icon-button (click)="incrementZoom(-0.1)">
                                                       -
                                                    </button>
                                                </div>
                                                <div class="vm_spliter_btn" style="font-size: 24px; line-height: 14px;">
                                                    <button style="color: #fff;" *ngIf="(data?.file?.zoom && data?.file?.zoom != 1) || !!data?.file?.translate" mat-icon-button (click)="noZoom()">
                                                        x
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <img [ngClass]="{'grab': !!data?.file?.grabData, grabbing: !!data?.file?.grabData?.dragging}" [src]="host + imgSrc + '?company_id=' + company?.id" [ngStyle]="{'transform': 'scale('+data?.file?.zoom+')' + (!!data?.file?.translate ? (' ' + data?.file?.translate) : '')}" (error)="onImgError($event)" [alt]="data?.file?.filename" (mousedown)="onMouseDown($event)">
                                    <button class="open_fullscreen" 
                                        matRipple
                                        [matRippleCentered]="true"
                                        [matRippleColor]="'rgba(0,0,0,.05)'">
                                        <mat-icon>fullscreen</mat-icon>
                                        <img [src]="host + imgSrc + '?company_id=' + company?.id" (error)="onImgError($event)" [alt]="data?.file?.filename" ngxViewer>
                                    </button>
                                </div>
                            </ng-template>
                             
                        </ng-template>   

                    </ng-template>

                </ng-template>
            </ng-container>
            <ng-template #elseTemplateNoFile>
                <div class="no_file_block">

                </div>
            </ng-template>
            <div class="playlist" *ngIf="data.playlist && data.playlist.length > 1">
                <swiper
                    #swiperRef
                    [slidesPerView]="3"
                    [spaceBetween]="10"
                    [freeMode]="true"
                    [mousewheel]="true"
                    class="mySwiper">
                    <ng-template *ngFor="let item of data.playlist; let ind = index;" swiperSlide>
                        <div class="play_item" [ngClass]="{'active': data?.file?.id == item.id, 'selected': fileCollection?.isSelected(item.id)}" (click)="data?.file?.id == item.id && !($event.ctrlKey || $event.metaKey || $event.shiftKey) ? return : selectItem($event, item, ind)">
                            <ng-container [ngSwitch]="understandFileType(item.content_type)">
                                <ng-container *ngSwitchCase="'video'">
                                    <ng-container *ngIf="item.thumbnail; else elseTemplateThumbnail">
                                        <div class="movie_thumb">
                                            <img style="max-width: 100%; max-height: 100%;" (error)="onImgError($event)"  [src]="host + item.thumbnail + '?company_id=' + company?.id">
                                        </div>
                                    </ng-container>
                                    <ng-template #elseTemplateThumbnail>
                                        <mat-icon class="file_img_icon" style="color: #3f51b5">
                                            movie
                                        </mat-icon>
                                    </ng-template>
                                </ng-container>
                                <ng-container *ngSwitchCase="'image'">
                                    <img [src]="host + (item.thumbnail ? item.thumbnail : item.original) + '?company_id=' + company?.id" (error)="onImgError($event)" [alt]="item.filename">
                                </ng-container>
                                <ng-container *ngSwitchCase="'audio'">
                                    <ng-container *ngIf="item.thumbnail; else elseTemplateThumbnailAudio">
                                        <div class="movie_thumb">
                                            <img style="max-width: 100%; max-height: 100%;" (error)="onImgError($event)"  [src]="host + item.thumbnail + '?company_id=' + company?.id">
                                        </div>
                                    </ng-container>
                                    <ng-template #elseTemplateThumbnailAudio>
                                        <mat-icon class="file_img_icon" style="color: #3f51b5">
                                            music_note
                                        </mat-icon>
                                    </ng-template>
                                </ng-container>
                                <ng-container *ngSwitchCase="'pdf'">
                                    <svg height="800px" width="800px" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
                                        viewBox="0 0 303.188 303.188" xml:space="preserve">
                                        <g>
                                            <polygon style="fill:#E8E8E8;" points="219.821,0 32.842,0 32.842,303.188 270.346,303.188 270.346,50.525 	"/>
                                            <path style="fill:#FB3449;" d="M230.013,149.935c-3.643-6.493-16.231-8.533-22.006-9.451c-4.552-0.724-9.199-0.94-13.803-0.936
                                                c-3.615-0.024-7.177,0.154-10.693,0.354c-1.296,0.087-2.579,0.199-3.861,0.31c-1.314-1.36-2.584-2.765-3.813-4.202
                                                c-7.82-9.257-14.134-19.755-19.279-30.664c1.366-5.271,2.459-10.772,3.119-16.485c1.205-10.427,1.619-22.31-2.288-32.251
                                                c-1.349-3.431-4.946-7.608-9.096-5.528c-4.771,2.392-6.113,9.169-6.502,13.973c-0.313,3.883-0.094,7.776,0.558,11.594
                                                c0.664,3.844,1.733,7.494,2.897,11.139c1.086,3.342,2.283,6.658,3.588,9.943c-0.828,2.586-1.707,5.127-2.63,7.603
                                                c-2.152,5.643-4.479,11.004-6.717,16.161c-1.18,2.557-2.335,5.06-3.465,7.507c-3.576,7.855-7.458,15.566-11.815,23.02
                                                c-10.163,3.585-19.283,7.741-26.857,12.625c-4.063,2.625-7.652,5.476-10.641,8.603c-2.822,2.952-5.69,6.783-5.941,11.024
                                                c-0.141,2.394,0.807,4.717,2.768,6.137c2.697,2.015,6.271,1.881,9.4,1.225c10.25-2.15,18.121-10.961,24.824-18.387
                                                c4.617-5.115,9.872-11.61,15.369-19.465c0.012-0.018,0.024-0.036,0.037-0.054c9.428-2.923,19.689-5.391,30.579-7.205
                                                c4.975-0.825,10.082-1.5,15.291-1.974c3.663,3.431,7.621,6.555,11.939,9.164c3.363,2.069,6.94,3.816,10.684,5.119
                                                c3.786,1.237,7.595,2.247,11.528,2.886c1.986,0.284,4.017,0.413,6.092,0.335c4.631-0.175,11.278-1.951,11.714-7.57
                                                C231.127,152.765,230.756,151.257,230.013,149.935z M119.144,160.245c-2.169,3.36-4.261,6.382-6.232,9.041
                                                c-4.827,6.568-10.34,14.369-18.322,17.286c-1.516,0.554-3.512,1.126-5.616,1.002c-1.874-0.11-3.722-0.937-3.637-3.065
                                                c0.042-1.114,0.587-2.535,1.423-3.931c0.915-1.531,2.048-2.935,3.275-4.226c2.629-2.762,5.953-5.439,9.777-7.918
                                                c5.865-3.805,12.867-7.23,20.672-10.286C120.035,158.858,119.587,159.564,119.144,160.245z M146.366,75.985
                                                c-0.602-3.514-0.693-7.077-0.323-10.503c0.184-1.713,0.533-3.385,1.038-4.952c0.428-1.33,1.352-4.576,2.826-4.993
                                                c2.43-0.688,3.177,4.529,3.452,6.005c1.566,8.396,0.186,17.733-1.693,25.969c-0.299,1.31-0.632,2.599-0.973,3.883
                                                c-0.582-1.601-1.137-3.207-1.648-4.821C147.945,83.048,146.939,79.482,146.366,75.985z M163.049,142.265
                                                c-9.13,1.48-17.815,3.419-25.979,5.708c0.983-0.275,5.475-8.788,6.477-10.555c4.721-8.315,8.583-17.042,11.358-26.197
                                                c4.9,9.691,10.847,18.962,18.153,27.214c0.673,0.749,1.357,1.489,2.053,2.22C171.017,141.096,166.988,141.633,163.049,142.265z
                                                M224.793,153.959c-0.334,1.805-4.189,2.837-5.988,3.121c-5.316,0.836-10.94,0.167-16.028-1.542
                                                c-3.491-1.172-6.858-2.768-10.057-4.688c-3.18-1.921-6.155-4.181-8.936-6.673c3.429-0.206,6.9-0.341,10.388-0.275
                                                c3.488,0.035,7.003,0.211,10.475,0.664c6.511,0.726,13.807,2.961,18.932,7.186C224.588,152.585,224.91,153.321,224.793,153.959z"/>
                                            <polygon style="fill:#FB3449;" points="227.64,25.263 32.842,25.263 32.842,0 219.821,0 	"/>
                                            <g>
                                                <path style="fill:#A4A9AD;" d="M126.841,241.152c0,5.361-1.58,9.501-4.742,12.421c-3.162,2.921-7.652,4.381-13.472,4.381h-3.643
                                                    v15.917H92.022v-47.979h16.606c6.06,0,10.611,1.324,13.652,3.971C125.321,232.51,126.841,236.273,126.841,241.152z
                                                    M104.985,247.387h2.363c1.947,0,3.495-0.546,4.644-1.641c1.149-1.094,1.723-2.604,1.723-4.529c0-3.238-1.794-4.857-5.382-4.857
                                                    h-3.348C104.985,236.36,104.985,247.387,104.985,247.387z"/>
                                                <path style="fill:#A4A9AD;" d="M175.215,248.864c0,8.007-2.205,14.177-6.613,18.509s-10.606,6.498-18.591,6.498h-15.523v-47.979
                                                    h16.606c7.701,0,13.646,1.969,17.836,5.907C173.119,235.737,175.215,241.426,175.215,248.864z M161.76,249.324
                                                    c0-4.398-0.87-7.657-2.609-9.78c-1.739-2.122-4.381-3.183-7.926-3.183h-3.773v26.877h2.888c3.939,0,6.826-1.143,8.664-3.43
                                                    C160.841,257.523,161.76,254.028,161.76,249.324z"/>
                                                <path style="fill:#A4A9AD;" d="M196.579,273.871h-12.766v-47.979h28.355v10.403h-15.589v9.156h14.374v10.403h-14.374
                                                    L196.579,273.871L196.579,273.871z"/>
                                            </g>
                                            <polygon style="fill:#D1D3D3;" points="219.821,50.525 270.346,50.525 219.821,0 	"/>
                                        </g>
                                    </svg>
                                </ng-container>
                                <ng-container *ngSwitchDefault>
                                    <ng-container *ngIf="!!item?.thumbnail; else elseTemplateHasThumb">
                                        <img (error)="onImgError($event)" style="max-width: 100%; max-height: 100%;" [src]="host + item.thumbnail + '?company_id=' + company?.id">
                                    </ng-container>
                                    <ng-template #elseTemplateHasThumb>
                                        <mat-icon class="file_img_icon" style="color: #3f51b5">
                                            insert_drive_file
                                        </mat-icon>
                                    </ng-template>
                                </ng-container>
                            </ng-container>
                        </div>
                    </ng-template>
                </swiper>
            </div>
        </div>
        <div class="chats_wrp" id="chats_h_wrp" *ngIf="data.work">
            <div class="chats_title" [ngClass]="{'need_padding': menuState}">
                <div class="new_chat_title" *ngIf="!!new_chat">
                    {{ "New chat" | translate }}
                </div>

                <div class="title_line" *ngIf="!new_chat && !chat_id">
                    <div class="tabs">
                        <div class="tab" [ngClass]="{'active': chatsFilter.value.data_file_id === ''}" (click)="log(); changeChatsFilter('data_file_id', ''); getChats()">{{"All" | translate}}</div>
                        <div class="tab" [ngClass]="{'active': chatsFilter.value.data_file_id == data?.file?.id}" (click)="changeChatsFilter('data_file_id', data?.file?.id); getChats()">{{"File chats" | translate}}</div>
                        <div class="tab" [ngClass]="{'active': chatsFilter.value.data_file_id === 0}" (click)="changeChatsFilter('data_file_id', 0); getChats()">{{"Just chats" | translate}}</div>
                    </div>
                    <form [formGroup]="chatsFilter">
    
                        <div class="simple_filter">
                            <div class="simple_filter_snippet" (click)="statusFilter.open()">
                            <ng-container [ngSwitch]="chatsFilter.get('status_id').value">
                                <ng-container *ngSwitchCase="'1,2,5'">
                                    {{ "All To Do" | translate }}
                                </ng-container>
                                <ng-container *ngSwitchCase="'3'">
                                    {{ "To approve" | translate }}
                                </ng-container>
                                <ng-container *ngSwitchCase="'3,4,6,97,98,99'">
                                    {{ "All Done" | translate }}
                                </ng-container>
                                <ng-container *ngSwitchDefault>
                                    {{ "Status" | translate }}
                                </ng-container>
                            </ng-container>
    
                              <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_2474_38987)">
                                <path d="M7.91699 3.33325L2.08366 3.33325L5.00033 7.91658L7.91699 3.33325Z" fill="black"/>
                                </g>
                                <defs>
                                <clipPath id="clip0_2474_38987">
                                <rect width="10" height="10" fill="white" transform="translate(10) rotate(90)"/>
                                </clipPath>
                                </defs>
                              </svg>
                            </div>
        
                            <mat-form-field appearance="legacy" class="dash_filter_simple">
                              <mat-label>{{ "Outlet" | translate }}</mat-label>
                              <mat-select [multiple]="false" #statusFilter [disableOptionCentering]="true" selectDir formControlName="status_id" (selectionChange)="statusChange()">
                                <mat-option [value]="">
                                    {{ "All" | translate }}
                                </mat-option>
                                <mat-option [value]="[1,2,5]">
                                    {{ "All To Do" | translate }}
                                </mat-option>
                                <mat-option [value]="[3]">
                                    {{ "To approve" | translate }}
                                </mat-option>
                                <mat-option [value]="[3,4,6,97,98,99]">
                                    {{ "All Done" | translate }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                    </form>
                </div>

                <button type="button" *ngIf="!new_chat && data.company && data.company?.permissions?.includes('create_discussion')" (click)="addChat()" class="add_chat mr-1">
                    + {{ "Add chat" | translate }}
                </button>
            </div>
            <ng-container *ngIf="new_chat; else elseTemplateCreatingChat">
                <div class="create_chat_wrp">
                    <div class="form_chat_out">
                        <form [formGroup]="form" class="form_with_valids" (ngSubmit)="createChat()" *ngIf="form" dirEnter (dirSubmit)="!form.valid || isSubmit ? null : createChat()">
                            <div class="form_chat_in">
                                <div class="white_field">
                                    <mat-form-field appearance="standard" class="full_width_form">
                                        <mat-label>{{ 'Chat title' | translate }}</mat-label>
                                        <input class="no_nav" required cdkFocusInitial formControlName="name" matInput type="text" placeholder="{{ 'Chat title' | translate }}">
                                    </mat-form-field>
                                </div>
                            </div>
                        </form>
                        <div class="form_with_valids">
                            <div class="form_chat_in">
                                <div class="d-f-c white_field tb_pad" style="justify-content: space-between;">
                                    <div [ngClass]="{'op-5': !is_add_data}">
                                        <div class="label_vid">{{ 'Show on file' | translate }}</div>
                                        <div class="d-f-c">
                                            <button type="button" [disableRipple]="true" mat-icon-button (click)="toggleDraw()" class="draw_btn" [ngClass]="{'active': is_draw}">
                                                <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M9.47321 21.8191C11.6493 20.9633 11.1725 18.6038 10.0722 17.1124C8.9842 15.5842 7.48049 14.5328 5.96455 13.5181C4.88872 12.8335 3.89847 11.9777 3.10383 10.9997C2.76152 10.5963 2.06468 9.85053 2.77375 9.70383C3.49504 9.55713 4.74202 10.2662 5.37773 10.5352C6.49024 10.9997 7.59051 11.5376 8.61744 12.1733L9.85219 10.095C7.94505 8.83583 5.49999 7.72333 3.22608 7.39325C1.9302 7.19764 0.560966 7.4666 0.120855 8.87251C-0.270355 10.0828 0.353136 11.3053 1.0622 12.2589C2.73707 14.4961 5.34106 15.572 7.28488 17.5036C7.70054 17.907 8.20178 18.3838 8.44628 18.9462C8.70302 19.4841 8.64189 19.5207 8.0673 19.5207C6.55136 19.5207 4.65644 18.3349 3.42169 17.5525L2.18693 19.6308C4.0574 20.78 7.18708 22.5771 9.47321 21.8191ZM23.0311 2.74764C23.3 2.47868 23.3 2.03857 23.0311 1.78184L21.4418 0.192548C21.185 -0.0641828 20.7449 -0.0641828 20.4882 0.192548L19.2412 1.43953L21.7841 3.98239M11.0014 9.67938V12.2222H13.5442L21.0628 4.70368L18.5199 2.16082L11.0014 9.67938Z" fill="#686868"/>
                                                </svg> 
                                            </button>
                                            <button [(colorPicker)]="color" type="button" mat-icon-button class="draw_btn">
                                                <mat-icon [style.color]="color">palette</mat-icon>
                                            </button>
                                            <div class="stroke_menu_out">
                                                <button class="check_btn" mat-icon-button (click)="onStrokeOpen()" [disableRipple]="true">
                                                    <mat-icon style="transform: rotate(90deg);">height</mat-icon>
                                                </button>
                                                <div class="stroke_menu_in" *ngIf="strokeOpen">
                                                    <mat-slider
                                                    [(ngModel)]="strokeWidth"
                                                    [value]="strokeWidth"
                                                    thumbLabel
                                                    min="1"
                                                    [vertical]="true"
                                                    step="1"
                                                    max="30">
                                                    </mat-slider>
                                                </div>
                                            </div>
                                            <button *ngIf="is_draw && ifHasPaths()" type="button" mat-icon-button style="color: #FB3449;" class="draw_btn" (click)="clearDraw()">
                                                <mat-icon>delete</mat-icon>
                                            </button>
                                        </div>
                                    </div>
                        
                                    <div class="time_in_vid" *ngIf="!!videoplayer">
                                        <div class="d-f-c" style="position: relative;">
                                            <div class="label_vid" [ngClass]="{'op-5': !is_add_data}">{{ 'Time in video' | translate }}</div>
                                            <mat-checkbox class="add_check_data" color="primary" (change)="changeAddData($event)" [checked]='is_add_data'
                                            #tooltip="matTooltip" matTooltip="{{ (is_add_data ? 'Time from the video will be linked to this chat' : 'To link time from the video with this chat select this checkbox') | translate }}" [matTooltipPosition]="'above'"></mat-checkbox>
                                        </div>
                                        <div class="like_inp" [ngClass]="{'op-5': !is_add_data}" *ngIf="connectObj.data.file_timestamps[0].currentTime">
                                            <span class="silver">{{(connectObj.data.file_timestamps[0].currentTime | fullTime).silverTime}}</span>
                                            <span class="black">{{(connectObj.data.file_timestamps[0].currentTime | fullTime).blackTime}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <form [formGroup]="form" class="form_with_valids" (ngSubmit)="createChat()" *ngIf="form" dirEnter (dirSubmit)="!form.valid || isSubmit ? null : createChat()">
                            <div class="form_chat_in">
                                <div class="white_field tb_pad" *ngIf="statuses?.length > 0">
                                    <div class="label_vid">{{ 'Status' | translate }}</div>
                                    <div class="small_chips">
                                        <mat-chip-list required [multiple]="false" selectable formControlName="status_id">
                                            <ng-container *ngFor="let status of statuses">
                                                <mat-chip *ngIf="status.is_lock == 0 || data.company?.permissions.includes('owner') || checkIsManager(data.task, data.company, data.user)" class="small_chip" (click)="selectStatus(status)" [value]="status?.id" [ngClass]="{
                                                    'first': status.id == '1',
                                                    'second': status.id == '2',
                                                    'third': status.id == '3',
                                                    'fours': status.id == '4',
                                                    'fives': status.id == '5',
                                                    'sixs': status.id == '6',
                                                    'nine': status.id == '97',
                                                    'seven': status.id == '98',
                                                    'eight': status.id == '99'}">
                                                    {{ status.name | translate }}
                                                </mat-chip>
                                            </ng-container>
                                        </mat-chip-list>
                                    </div>
                                    
                        
                                    <div style="margin-top: 20px;"></div>
                                    <div class="label_vid">{{ 'Priority' | translate }}</div>
                                    <div class="priority_chips">
                                        <mat-chip-list [multiple]="false" selectable formControlName="priority">
                                            <ng-container *ngFor="let priority of prioritys">
                                                <mat-chip *ngIf="priority.id != '0'" [ngClass]="{
                                                    'Urgent': priority.id == '1000',
                                                    'High': priority.id == '900',
                                                    'Middle': priority.id == '800',
                                                    'Low': priority.id == '700',
                                                    'No': priority.id == '0'
                                                }" class="priority_chip" (click)="selectPrioritys(priority)" [value]="priority.id">
                                                    {{ priority.name | translate }}
                                                </mat-chip>
                                            </ng-container>
                                            
                                        </mat-chip-list>
                                    </div>
                                </div>
                        
    
                                <div class="white_field" style="padding-top: 11px !important; padding-bottom: 8px !important;">
                                    <div class="label_vid" style="transform: translateY(4px);">{{ 'Message' | translate }}</div>
                                    <mat-form-field appearance="standard" class="full_width_form msg_area">
                                        <mat-label>{{ 'Message' | translate }}</mat-label>
                                        <textarea class="no_nav no_n_text_field" formControlName="text" matInput placeholder="{{ 'Message' | translate }}"></textarea>
                                        <mat-hint class="hint_text">
                                            <svg style="width: 12px; height: 12px;" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clip-path="url(#clip0_5363_74863)">
                                                <path d="M8.25 3V8.75C8.25 9.855 7.355 10.75 6.25 10.75C5.145 10.75 4.25 9.855 4.25 8.75V2.5C4.25 1.81 4.81 1.25 5.5 1.25C6.19 1.25 6.75 1.81 6.75 2.5V7.75C6.75 8.025 6.525 8.25 6.25 8.25C5.975 8.25 5.75 8.025 5.75 7.75V3H5V7.75C5 8.44 5.56 9 6.25 9C6.94 9 7.5 8.44 7.5 7.75V2.5C7.5 1.395 6.605 0.5 5.5 0.5C4.395 0.5 3.5 1.395 3.5 2.5V8.75C3.5 10.27 4.73 11.5 6.25 11.5C7.77 11.5 9 10.27 9 8.75V3H8.25Z" fill="#323232"/>
                                                </g>
                                                <defs>
                                                <clipPath id="clip0_5363_74863">
                                                    <rect width="12" height="12" fill="white"/>
                                                </clipPath>
                                                </defs>
                                            </svg>
                                            {{"You can add a file after you create a chat" | translate}}
                                        </mat-hint>
                                    </mat-form-field>
                                </div>
                            </div>
    
                            <div class="d-f-c" style="padding: 8px 0; justify-content: flex-end;">
                                <button mat-raised-button type="button" (click)="closeAddingChat()">
                                    {{ "Close" | translate }}
                                </button>
                                <button class="ml-1 d-f-btn" type="submit" mat-raised-button cdkFocusInitial color="primary" [disabled]="!form.valid || isSubmit">
                                    <mat-spinner class="spin_16 mr-1" *ngIf="isSubmit"></mat-spinner>  
                                    {{ "Create" | translate }}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </ng-container>
            <ng-template #elseTemplateCreatingChat>
                <div class="no_chats" *ngIf="(!!chatsFilter.value.data_file_id && (!chats || chats.length == 0)) || (chatsFilter.value.data_file_id === '' && !!groupedChats && !groupedChats[0].chats.length) || (chatsFilter.value.data_file_id === 0 && (!chats || chats.length == 0))">
                    <p>{{ ((chatsFilter.value.data_file_id === 0 && (!chats || chats.length == 0)) ? "No chats yet" : "The file is not connected to any of the chats.") | translate}}</p>
                    <button (click)="changeChatsFilter('data_file_id', ''); getChats()" *ngIf="!(chatsFilter.value.data_file_id === '' && !!groupedChats && !groupedChats[0].chats.length)">{{"Show all chats from the job" | translate}}</button>
                </div>
                <div class="chats_list" [ngClass]="{'hide': !!menuState}" *ngIf="chats">
                    <ng-container *ngIf="chatsFilter.value.data_file_id === ''; else elseTemplateTpl">
                        <ng-container *ngFor="let item of groupedChats; let i = index;">
                            <ng-container *ngIf="i == 0 || item.chats.length">
                                <div class="group_title" *ngIf="item.name" [ngClass]="{'mt-16': i != 0}">{{item.name | translate}}</div>
                                <div class="chat_item" *ngFor="let chat of item.chats" (click)="selectChat(chat)" (mouseleave)="leaveChat(chat)" [ngClass]="{
                                    'first': (!!chat.employeeStatus ? chat.employeeStatus.status_id : chat.status_id) == '1',
                                    'second': (!!chat.employeeStatus ? chat.employeeStatus.status_id : chat.status_id) == '2',
                                    'third': (!!chat.employeeStatus ? chat.employeeStatus.status_id : chat.status_id) == '3',
                                    'fours': (!!chat.employeeStatus ? chat.employeeStatus.status_id : chat.status_id) == '4',
                                    'fives': (!!chat.employeeStatus ? chat.employeeStatus.status_id : chat.status_id) == '5',
                                    'sixs': (!!chat.employeeStatus ? chat.employeeStatus.status_id : chat.status_id) == '6',
                                    'nine': (!!chat.employeeStatus ? chat.employeeStatus.status_id : chat.status_id) == '97',
                                    'seven': (!!chat.employeeStatus ? chat.employeeStatus.status_id : chat.status_id) == '98',
                                    'eight': (!!chat.employeeStatus ? chat.employeeStatus.status_id : chat.status_id) == '99'}">
                                    <div class="priority_line" [ngClass]="{
                                        'useOut': useOut,
                                        'Urgent': chat?.priority == '1000',
                                        'High': chat?.priority == '900',
                                        'Middle': chat?.priority == '800',
                                        'Low': chat?.priority == '700',
                                        'No': chat?.priority == '0' || !chat?.priority
                                    }"></div>
                                    <div class="unread_chat" *ngIf="chat?.is_read == 0"></div>
                                    <div class="check_chat" [ngClass]="{'show': !!selectedChats.isSelected(chat)}">
                                        <mat-checkbox color="primary" class="example-margin" (click)="$event.stopPropagation()" (change)="$event ? toggleChat(chat) : null" [checked]='selectedChats.isSelected(chat)'></mat-checkbox>
                                      </div>
                                    <div class="d-f-c" style="align-items: flex-start !important;">
                                        <div class="chat_photo">
                                            <ng-container *ngIf="!!chat?.employee?.image; else elseOwnerTemplate">
                                                <img [src]="chat?.employee?.image" alt="">
                                              </ng-container>
                                              <ng-template #elseOwnerTemplate>
                                                  <mat-icon>account_circle</mat-icon>
                                              </ng-template>
                                        </div>
                                        <div class="chat_info">
                                            <div class="chat_name">
                                                {{ chat?.name }}
                                            </div>
                                            <ng-container *ngIf="chat?.lastPost">
                                                <ng-container *ngIf="chat?.lastPost?.action; else elseTemplateHasAction">
                                                    <div class="chat_last_post">
                                
                                                    <div class="last_post_avatar">
                                                        <ng-container *ngIf="!!chat?.lastPost?.action?.createdEmployee?.image; else elseOwnerTemplateLastPost">
                                                            <img class="mr-5" [src]="chat?.lastPost?.action?.createdEmployee?.image" alt="">
                                                        </ng-container>
                                                        <ng-template #elseOwnerTemplateLastPost>
                                                            <mat-icon class="mr-5">account_circle</mat-icon>
                                                        </ng-template>
                                                    </div>
                                
                                                      {{ 'switched to' | translate }} 
                                                      <ng-container [ngSwitch]="chat?.lastPost?.action?.action">
                                                        <ng-container *ngSwitchCase="'task_employee_status_change'">
                                                          <b style="margin-left: 5px; margin-right: 5px;">{{ getChatStatus(chat?.lastPost?.action?.task_employee_status_id) }}</b>
                                                          {{ "for" | translate }}
                                                          <ng-container *ngIf="!!chat?.lastPost?.action?.employee?.image; else elseActionOwnerTemplate">
                                                              <img class="ml-5" [src]="chat?.lastPost?.action?.employee?.image" alt="">
                                                          </ng-container>
                                                          <ng-template #elseActionOwnerTemplate>
                                                              <mat-icon class="ml-5">account_circle</mat-icon>
                                                          </ng-template>
                                                        </ng-container>
                                                        <ng-container *ngSwitchCase="'discussion_status'">
                                                           <b style="margin-left: 5px;">{{ getChatStatus(chat?.lastPost?.action?.discussion_status_id) }}</b>
                                                        </ng-container>
                                                      </ng-container>
                                                    </div>
                                                  </ng-container>
                                                  <ng-template #elseTemplateHasAction>
                                                    <div class="chat_last_post">
                                                        <div class="last_post_avatar">
                                                            <ng-container *ngIf="!!chat?.lastPost?.employee?.image; else elseOwnerTemplateLastPost">
                                                                <img [src]="chat?.lastPost?.employee?.image" alt="">
                                                            </ng-container>
                                                            <ng-template #elseOwnerTemplateLastPost>
                                                                <mat-icon>account_circle</mat-icon>
                                                            </ng-template>
                                                        </div>
                                                      
                                                        <div class="" [innerHTML]='chat?.lastPost?.text'></div>
                                                      <!-- <div class="text_one_line" [innerHTML]='chat?.lastPost?.text ? (chat?.lastPost?.text) : ""'></div> -->
                                                  </div>
                                                  </ng-template>
                                            </ng-container>
                                            <!-- <div class="text_one_line" [innerHTML]='chat?.lastPost?.text ? (chat?.lastPost?.text.replaceAll("\n"," ").replaceAll("</BR>"," ").replaceAll("<BR>"," ").replaceAll("</br>"," ").replaceAll("<br>"," ")) : ""'></div> -->
                                        </div>
                                    </div>
                                    <div class="chat_values">
                                        <div class="d-f-c">
                                            <div class="chat_timestamp ml-1" (click)="chatTimestampClick($event, chat)" *ngIf="chat.data && chat.data.original_file_id == data?.file?.original_file_id && chat.data.file_timestamps && chat.data.file_timestamps.length && chat.data.file_timestamps[0].type == 'currentTime'">
                                                <ng-container>
                                                    <span class="silver">{{(chat.data.file_timestamps[0].currentTime | fullTime).silverTime}}</span>
                                                    <span class="black">{{(chat.data.file_timestamps[0].currentTime | fullTime).blackTime}}</span>
                                                </ng-container>
                                            </div>

                                            <div class="chat_stat" *ngIf="!!chat.employeeStatus && chat.employeeStatus.status_id != chat.status_id" [ngClass]="{
                                                'hide-hover': ![4,97,98,99].includes(chat.employeeStatus.status_id),
                                                'first': chat.employeeStatus.status_id == '1',
                                                'second': chat.employeeStatus.status_id == '2',
                                                'third': chat.employeeStatus.status_id == '3',
                                                'fours': chat.employeeStatus.status_id == '4',
                                                'fives': chat.employeeStatus.status_id == '5',
                                                'sixs': chat.employeeStatus.status_id == '6',
                                                'nine': chat.employeeStatus.status_id == '97',
                                                'seven': chat.employeeStatus.status_id == '98',
                                                'eight': chat.employeeStatus.status_id == '99'}">
                                                {{ getChatStatus(chat.employeeStatus.status_id) | translate }}
                                            </div>

                                            <div class="chat_stat show-hover" *ngIf="chat.employeeStatus && ![4,97,98,99].includes(chat.employeeStatus.status_id)" [ngClass]="{
                                                'stat_open': !!chat.open_stat,
                                                'first': (!!chat?.selectedStatus ? chat?.selectedStatus : statusesPredict[chat.employeeStatus.status_id].arr[0]) == '1',
                                                'second': (!!chat?.selectedStatus ? chat?.selectedStatus : statusesPredict[chat.employeeStatus.status_id].arr[0]) == '2',
                                                'third': (!!chat?.selectedStatus ? chat?.selectedStatus : statusesPredict[chat.employeeStatus.status_id].arr[0]) == '3',
                                                'fours': (!!chat?.selectedStatus ? chat?.selectedStatus : statusesPredict[chat.employeeStatus.status_id].arr[0]) == '4',
                                                'fives': (!!chat?.selectedStatus ? chat?.selectedStatus : statusesPredict[chat.employeeStatus.status_id].arr[0]) == '5',
                                                'sixs': (!!chat?.selectedStatus ? chat?.selectedStatus : statusesPredict[chat.employeeStatus.status_id].arr[0]) == '6',
                                                'nine': (!!chat?.selectedStatus ? chat?.selectedStatus : statusesPredict[chat.employeeStatus.status_id].arr[0]) == '97',
                                                'seven': (!!chat?.selectedStatus ? chat?.selectedStatus : statusesPredict[chat.employeeStatus.status_id].arr[0]) == '98',
                                                'eight': (!!chat?.selectedStatus ? chat?.selectedStatus : statusesPredict[chat.employeeStatus.status_id].arr[0]) == '99'}">
                                                <div class="d-f-c" (click)="openChatStat($event, chat)">
                                                    <div class="active_stat" (click)="changeStat($event, chat, item.chats)">
                                                        <ng-container *ngIf="!!chat?.statusChanged; else elseTemplateAct">
                                                            <svg class="check_s" style="width: 16px; height: 16px;" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M3.33333 2H12.6667C13.4067 2 14 2.6 14 3.33333V12.6667C14 13.4 13.4067 14 12.6667 14H3.33333C2.59333 14 2 13.4 2 12.6667V3.33333C2 2.6 2.59333 2 3.33333 2ZM3.33333 8L6.66667 11.3333L12.6667 5.33333L11.7267 4.38667L6.66667 9.44667L4.27333 7.06L3.33333 8Z" fill="#686868"/>
                                                            </svg>
                                                        </ng-container>
                                                        <ng-template #elseTemplateAct>
                                                            <svg class="check_s" style="width: 16px; height: 16px;" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M3.33333 2H12.6667C13.4 2 14 2.6 14 3.33333V12.6667C14 13.4 13.4 14 12.6667 14H3.33333C2.6 14 2 13.4 2 12.6667V3.33333C2 2.6 2.6 2 3.33333 2ZM12.6667 12.6667V3.33333H3.33333V12.6667H12.6667Z" fill="#686868"/>
                                                            </svg>                                                   
                                                        </ng-template>
                                                    </div>
                                                    {{ (!!chat?.selectedStatus ? getChatStatus(chat?.selectedStatus) : getChatStatus(statusesPredict[chat.employeeStatus.status_id].arr[0])) | translate }}
    
                                                    <svg style="margin-left: 8px; width: 10px; height: 10px" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M7.91669 3.33331L2.08335 3.33331L5.00002 7.91665L7.91669 3.33331Z" fill="#686868"/>
                                                    </svg> 
                                                </div>
                                                <div class="chat_stat_in">
                                                    <div *ngFor="let id of statusesPredict[chat.employeeStatus.status_id].arr; let m = index;" (click)="selectStat($event, id, chat)" [ngClass]="{
                                                        'active': (!chat?.selectedStatus && m == 0) || (id == chat?.selectedStatus),
                                                        'first': id == '1',
                                                        'second': id == '2',
                                                        'third': id == '3',
                                                        'fours': id == '4',
                                                        'fives': id == '5',
                                                        'sixs': id == '6',
                                                        'nine': id == '97',
                                                        'seven': id == '98',
                                                        'eight': id == '99'}">{{getChatStatus(id) | translate}}</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="d-f-c">
                                            <div class="chat_status" [ngClass]="{
                                                'first': chat.status_id == '1',
                                                'second': chat.status_id == '2',
                                                'third': chat.status_id == '3',
                                                'fours': chat.status_id == '4',
                                                'fives': chat.status_id == '5',
                                                'sixs': chat.status_id == '6',
                                                'nine': chat.status_id == '97',
                                                'seven': chat.status_id == '98',
                                                'eight': chat.status_id == '99'}">
                                                {{ getChatStatus(chat.status_id) | translate }}
                                            </div>
                                            <ng-container *ngIf="chat.updated_at > today; else updatedAtTempl">
                                                <div class="chat_updated">
                                                  {{ chat.updated_at*1000 | date:'hh:mm a':'timeZone':data.activeLang | yearPipe }}
                                                </div>
                                            </ng-container>
                                            <ng-template #updatedAtTempl>
                                                <div class="chat_updated" #tooltip="matTooltip"
                                                matTooltip="{{ chat.updated_at*1000 | date:'hh:mm a':'timeZone':data.activeLang | yearPipe }}"
                                                [matTooltipPosition]="'above'">
                                                  {{ chat.updated_at*1000 | date:'MMM d, y':'timeZone':data.activeLang | yearPipe }}
                                                </div>
                                            </ng-template>
                                        </div>
                                        <!-- <div class="chat_updated">
                                            {{ chat.updated_at*1000 | date:'MMM d, y':'timeZone':data.activeLang | yearPipe }}
                                        </div> -->
                              
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                    <ng-template #elseTemplateTpl>
                        <div class="chat_item" *ngFor="let chat of chats" (click)="selectChat(chat)" (mouseleave)="leaveChat(chat)" [ngClass]="{
                            'first': (!!chat.employeeStatus ? chat.employeeStatus.status_id : chat.status_id) == '1',
                            'second': (!!chat.employeeStatus ? chat.employeeStatus.status_id : chat.status_id) == '2',
                            'third': (!!chat.employeeStatus ? chat.employeeStatus.status_id : chat.status_id) == '3',
                            'fours': (!!chat.employeeStatus ? chat.employeeStatus.status_id : chat.status_id) == '4',
                            'fives': (!!chat.employeeStatus ? chat.employeeStatus.status_id : chat.status_id) == '5',
                            'sixs': (!!chat.employeeStatus ? chat.employeeStatus.status_id : chat.status_id) == '6',
                            'nine': (!!chat.employeeStatus ? chat.employeeStatus.status_id : chat.status_id) == '97',
                            'seven': (!!chat.employeeStatus ? chat.employeeStatus.status_id : chat.status_id) == '98',
                            'eight': (!!chat.employeeStatus ? chat.employeeStatus.status_id : chat.status_id) == '99'}">
                            <div class="priority_line" [ngClass]="{
                                'useOut': useOut,
                                'Urgent': chat?.priority == '1000',
                                'High': chat?.priority == '900',
                                'Middle': chat?.priority == '800',
                                'Low': chat?.priority == '700',
                                'No': chat?.priority == '0' || !chat?.priority
                            }"></div>
                            <div class="unread_chat" *ngIf="chat?.is_read == 0"></div>
                            <div class="check_chat" [ngClass]="{'show': !!selectedChats.isSelected(chat)}">
                                <mat-checkbox color="primary" class="example-margin" (click)="$event.stopPropagation()" (change)="$event ? toggleChat(chat) : null" [checked]='selectedChats.isSelected(chat)'></mat-checkbox>
                              </div>
                            <div class="d-f-c" style="align-items: flex-start !important;">
                                <div class="chat_photo">
                                    <ng-container *ngIf="!!chat?.employee?.image; else elseOwnerTemplate">
                                        <img [src]="chat?.employee?.image" alt="">
                                      </ng-container>
                                      <ng-template #elseOwnerTemplate>
                                          <mat-icon>account_circle</mat-icon>
                                      </ng-template>
                                    <!-- <ng-container *ngIf="chat?.lastPost?.action; else elseTemplateIsActionPost">                           
                                        <ng-container *ngIf="!!chat?.lastPost?.action?.createdEmployee?.image; else elseOwnerTemplate">
                                            <img [src]="chat?.lastPost?.action?.createdEmployee?.image" alt="">
                                        </ng-container>
                                        <ng-template #elseOwnerTemplate>
                                            <mat-icon>account_circle</mat-icon>
                                        </ng-template>
                                    </ng-container>
                                    <ng-template #elseTemplateIsActionPost>      
                                        <ng-container *ngIf="!!chat?.lastPost?.employee?.image; else elseOwnerTemplate">
                                            <img [src]="chat?.lastPost?.employee?.image" alt="">
                                        </ng-container>
                                        <ng-template #elseOwnerTemplate>
                                            <img src="{{ imgRoute }}/assets/img/outline_person_outline_black_24dp.png" alt="">
                                        </ng-template>
                                    </ng-template> -->
                                </div>
                                <div class="chat_info">
                                    <div class="chat_name">
                                        {{ chat?.name }}
                                    </div>
                                    <ng-container *ngIf="chat?.lastPost">
                                        <ng-container *ngIf="chat?.lastPost?.action; else elseTemplateHasAction">
                                            <div class="chat_last_post">
                        
                                            <div class="last_post_avatar">
                                                <ng-container *ngIf="!!chat?.lastPost?.action?.createdEmployee?.image; else elseOwnerTemplateLastPost">
                                                    <img class="mr-5" [src]="chat?.lastPost?.action?.createdEmployee?.image" alt="">
                                                </ng-container>
                                                <ng-template #elseOwnerTemplateLastPost>
                                                    <mat-icon class="mr-5">account_circle</mat-icon>
                                                </ng-template>
                                            </div>
                        
                                              {{ 'switched to' | translate }} 
                                              <ng-container [ngSwitch]="chat?.lastPost?.action?.action">
                                                <ng-container *ngSwitchCase="'task_employee_status_change'">
                                                  <b style="margin-left: 5px; margin-right: 5px;">{{ getChatStatus(chat?.lastPost?.action?.task_employee_status_id) }}</b>
                                                  {{ "for" | translate }}
                                                  <ng-container *ngIf="!!chat?.lastPost?.action?.employee?.image; else elseActionOwnerTemplate">
                                                      <img class="ml-5" [src]="chat?.lastPost?.action?.employee?.image" alt="">
                                                  </ng-container>
                                                  <ng-template #elseActionOwnerTemplate>
                                                      <mat-icon class="ml-5">account_circle</mat-icon>
                                                  </ng-template>
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'discussion_status'">
                                                   <b style="margin-left: 5px;">{{ getChatStatus(chat?.lastPost?.action?.discussion_status_id) }}</b>
                                                </ng-container>
                                              </ng-container>
                                            </div>
                                          </ng-container>
                                          <ng-template #elseTemplateHasAction>
                                            <div class="chat_last_post">
                                                <div class="last_post_avatar">
                                                    <ng-container *ngIf="!!chat?.lastPost?.employee?.image; else elseOwnerTemplateLastPost">
                                                        <img [src]="chat?.lastPost?.employee?.image" alt="">
                                                    </ng-container>
                                                    <ng-template #elseOwnerTemplateLastPost>
                                                        <mat-icon>account_circle</mat-icon>
                                                    </ng-template>
                                                </div>
                                              
                                                <div class="last_post_file" *ngIf="!!chat?.lastPost?.file_id">
                                                    <div class="file_view">
                                                    <ng-container [ngSwitch]="understandFileType(chat?.lastPost?.file?.content_type)">
                                                        <ng-container *ngSwitchCase="'video'">
                                                            <div class="file movie d-f-c">
                                                                <ng-container *ngIf="chat?.lastPost?.file?.thumbnail; else elseTemplateThumbnail">
                                                                    <div class="movie_thumb" >
                                                                        <img style="max-width: 100%; max-height: 100%;" (error)="onImgError($event)" [src]="host + chat?.lastPost?.file?.thumbnail + '?company_id=' + company?.id">
                                                                    </div>
                                                                </ng-container>
                                                                <ng-template #elseTemplateThumbnail>
                                                                    <mat-icon class="file_img_icon" style="color: #3f51b5">
                                                                        movie
                                                                    </mat-icon>
                                                                </ng-template>
                                                            </div>
                                                        </ng-container>
                                                        <ng-container *ngSwitchCase="'pdf'">
                                                            <div class="pdf">
                                                                <svg height="800px" width="800px" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
                                                                    viewBox="0 0 303.188 303.188" xml:space="preserve">
                                                                    <g>
                                                                        <polygon style="fill:#E8E8E8;" points="219.821,0 32.842,0 32.842,303.188 270.346,303.188 270.346,50.525 	"/>
                                                                        <path style="fill:#FB3449;" d="M230.013,149.935c-3.643-6.493-16.231-8.533-22.006-9.451c-4.552-0.724-9.199-0.94-13.803-0.936
                                                                            c-3.615-0.024-7.177,0.154-10.693,0.354c-1.296,0.087-2.579,0.199-3.861,0.31c-1.314-1.36-2.584-2.765-3.813-4.202
                                                                            c-7.82-9.257-14.134-19.755-19.279-30.664c1.366-5.271,2.459-10.772,3.119-16.485c1.205-10.427,1.619-22.31-2.288-32.251
                                                                            c-1.349-3.431-4.946-7.608-9.096-5.528c-4.771,2.392-6.113,9.169-6.502,13.973c-0.313,3.883-0.094,7.776,0.558,11.594
                                                                            c0.664,3.844,1.733,7.494,2.897,11.139c1.086,3.342,2.283,6.658,3.588,9.943c-0.828,2.586-1.707,5.127-2.63,7.603
                                                                            c-2.152,5.643-4.479,11.004-6.717,16.161c-1.18,2.557-2.335,5.06-3.465,7.507c-3.576,7.855-7.458,15.566-11.815,23.02
                                                                            c-10.163,3.585-19.283,7.741-26.857,12.625c-4.063,2.625-7.652,5.476-10.641,8.603c-2.822,2.952-5.69,6.783-5.941,11.024
                                                                            c-0.141,2.394,0.807,4.717,2.768,6.137c2.697,2.015,6.271,1.881,9.4,1.225c10.25-2.15,18.121-10.961,24.824-18.387
                                                                            c4.617-5.115,9.872-11.61,15.369-19.465c0.012-0.018,0.024-0.036,0.037-0.054c9.428-2.923,19.689-5.391,30.579-7.205
                                                                            c4.975-0.825,10.082-1.5,15.291-1.974c3.663,3.431,7.621,6.555,11.939,9.164c3.363,2.069,6.94,3.816,10.684,5.119
                                                                            c3.786,1.237,7.595,2.247,11.528,2.886c1.986,0.284,4.017,0.413,6.092,0.335c4.631-0.175,11.278-1.951,11.714-7.57
                                                                            C231.127,152.765,230.756,151.257,230.013,149.935z M119.144,160.245c-2.169,3.36-4.261,6.382-6.232,9.041
                                                                            c-4.827,6.568-10.34,14.369-18.322,17.286c-1.516,0.554-3.512,1.126-5.616,1.002c-1.874-0.11-3.722-0.937-3.637-3.065
                                                                            c0.042-1.114,0.587-2.535,1.423-3.931c0.915-1.531,2.048-2.935,3.275-4.226c2.629-2.762,5.953-5.439,9.777-7.918
                                                                            c5.865-3.805,12.867-7.23,20.672-10.286C120.035,158.858,119.587,159.564,119.144,160.245z M146.366,75.985
                                                                            c-0.602-3.514-0.693-7.077-0.323-10.503c0.184-1.713,0.533-3.385,1.038-4.952c0.428-1.33,1.352-4.576,2.826-4.993
                                                                            c2.43-0.688,3.177,4.529,3.452,6.005c1.566,8.396,0.186,17.733-1.693,25.969c-0.299,1.31-0.632,2.599-0.973,3.883
                                                                            c-0.582-1.601-1.137-3.207-1.648-4.821C147.945,83.048,146.939,79.482,146.366,75.985z M163.049,142.265
                                                                            c-9.13,1.48-17.815,3.419-25.979,5.708c0.983-0.275,5.475-8.788,6.477-10.555c4.721-8.315,8.583-17.042,11.358-26.197
                                                                            c4.9,9.691,10.847,18.962,18.153,27.214c0.673,0.749,1.357,1.489,2.053,2.22C171.017,141.096,166.988,141.633,163.049,142.265z
                                                                            M224.793,153.959c-0.334,1.805-4.189,2.837-5.988,3.121c-5.316,0.836-10.94,0.167-16.028-1.542
                                                                            c-3.491-1.172-6.858-2.768-10.057-4.688c-3.18-1.921-6.155-4.181-8.936-6.673c3.429-0.206,6.9-0.341,10.388-0.275
                                                                            c3.488,0.035,7.003,0.211,10.475,0.664c6.511,0.726,13.807,2.961,18.932,7.186C224.588,152.585,224.91,153.321,224.793,153.959z"/>
                                                                        <polygon style="fill:#FB3449;" points="227.64,25.263 32.842,25.263 32.842,0 219.821,0 	"/>
                                                                        <g>
                                                                            <path style="fill:#A4A9AD;" d="M126.841,241.152c0,5.361-1.58,9.501-4.742,12.421c-3.162,2.921-7.652,4.381-13.472,4.381h-3.643
                                                                                v15.917H92.022v-47.979h16.606c6.06,0,10.611,1.324,13.652,3.971C125.321,232.51,126.841,236.273,126.841,241.152z
                                                                                M104.985,247.387h2.363c1.947,0,3.495-0.546,4.644-1.641c1.149-1.094,1.723-2.604,1.723-4.529c0-3.238-1.794-4.857-5.382-4.857
                                                                                h-3.348C104.985,236.36,104.985,247.387,104.985,247.387z"/>
                                                                            <path style="fill:#A4A9AD;" d="M175.215,248.864c0,8.007-2.205,14.177-6.613,18.509s-10.606,6.498-18.591,6.498h-15.523v-47.979
                                                                                h16.606c7.701,0,13.646,1.969,17.836,5.907C173.119,235.737,175.215,241.426,175.215,248.864z M161.76,249.324
                                                                                c0-4.398-0.87-7.657-2.609-9.78c-1.739-2.122-4.381-3.183-7.926-3.183h-3.773v26.877h2.888c3.939,0,6.826-1.143,8.664-3.43
                                                                                C160.841,257.523,161.76,254.028,161.76,249.324z"/>
                                                                            <path style="fill:#A4A9AD;" d="M196.579,273.871h-12.766v-47.979h28.355v10.403h-15.589v9.156h14.374v10.403h-14.374
                                                                                L196.579,273.871L196.579,273.871z"/>
                                                                        </g>
                                                                        <polygon style="fill:#D1D3D3;" points="219.821,50.525 270.346,50.525 219.821,0 	"/>
                                                                    </g>
                                                                </svg>
                                                            </div>
                                                        </ng-container>
                                                        <ng-container *ngSwitchCase="'image'">
                                                            <div class="img_wrp">
                                                                <img (error)="onImgError($event)" [src]="host + (chat?.lastPost?.file?.thumbnail ? chat?.lastPost?.file?.thumbnail + '?company_id=' + company?.id : chat?.lastPost?.file?.original + '?company_id=' + company?.id)" alt="">
                                                            </div>
                                                        </ng-container>
                                                        <ng-container *ngSwitchDefault>
                                                            <ng-container *ngIf="!!chat?.lastPost?.file?.thumbnail; else elseTemplateHasThumb">
                                                            <div class="img_wrp">
                                                                <img (error)="onImgError($event)" [src]="host + chat?.lastPost?.file?.thumbnail + '?company_id=' + company?.id" alt="">
                                                            </div>
                                                            </ng-container>
                                                            <ng-template #elseTemplateHasThumb>
                                                            <div class="file d-f-c">
                                                                <mat-icon class="file_img_icon mr-1" style="color: #3f51b5">
                                                                    insert_drive_file
                                                                </mat-icon>
                                                            </div>
                                                            </ng-template>
                                                        </ng-container>
                                                    </ng-container>
                                                    </div>
                                                    <!-- <div class="file_info">
                                                        <span class="file_name">{{ chat?.lastPost?.file?.filename }}</span>
                                                        <span class="file_size">{{ chat?.lastPost?.file?.filesize | filesize }}</span>
                                                    </div> -->
                            
                                                </div>
                                                <div class="" [innerHTML]='chat?.lastPost?.text'></div>
                                              <!-- <div class="text_one_line" [innerHTML]='chat?.lastPost?.text ? (chat?.lastPost?.text) : ""'></div> -->
                                          </div>
                                          </ng-template>
                                    </ng-container>
                                    <!-- <div class="text_one_line" [innerHTML]='chat?.lastPost?.text ? (chat?.lastPost?.text.replaceAll("\n"," ").replaceAll("</BR>"," ").replaceAll("<BR>"," ").replaceAll("</br>"," ").replaceAll("<br>"," ")) : ""'></div> -->
                                </div>
                            </div>
                            <div class="chat_values">
                                <div class="d-f-c">
                                    <div class="chat_timestamp ml-1" (click)="chatTimestampClick($event, chat)" *ngIf="chat.data && chat.data.original_file_id == data?.file?.original_file_id && chat.data.file_timestamps && chat.data.file_timestamps.length && chat.data.file_timestamps[0].type == 'currentTime'">
                                        <ng-container>
                                            <span class="silver">{{(chat.data.file_timestamps[0].currentTime | fullTime).silverTime}}</span>
                                            <span class="black">{{(chat.data.file_timestamps[0].currentTime | fullTime).blackTime}}</span>
                                        </ng-container>
                                    </div>

                                    <div class="chat_stat" *ngIf="!!chat.employeeStatus && chat.employeeStatus.status_id != chat.status_id" [ngClass]="{
                                        'hide-hover': ![4,97,98,99].includes(chat.employeeStatus.status_id),
                                        'first': chat.employeeStatus.status_id == '1',
                                        'second': chat.employeeStatus.status_id == '2',
                                        'third': chat.employeeStatus.status_id == '3',
                                        'fours': chat.employeeStatus.status_id == '4',
                                        'fives': chat.employeeStatus.status_id == '5',
                                        'sixs': chat.employeeStatus.status_id == '6',
                                        'nine': chat.employeeStatus.status_id == '97',
                                        'seven': chat.employeeStatus.status_id == '98',
                                        'eight': chat.employeeStatus.status_id == '99'}">
                                        {{ getChatStatus(chat.employeeStatus.status_id) | translate }}
                                    </div>

                                    <div class="chat_stat show-hover" *ngIf="chat.employeeStatus && ![4,97,98,99].includes(chat.employeeStatus.status_id)" [ngClass]="{
                                        'stat_open': !!chat.open_stat,
                                        'first': (!!chat?.selectedStatus ? chat?.selectedStatus : statusesPredict[chat.employeeStatus.status_id].arr[0]) == '1',
                                        'second': (!!chat?.selectedStatus ? chat?.selectedStatus : statusesPredict[chat.employeeStatus.status_id].arr[0]) == '2',
                                        'third': (!!chat?.selectedStatus ? chat?.selectedStatus : statusesPredict[chat.employeeStatus.status_id].arr[0]) == '3',
                                        'fours': (!!chat?.selectedStatus ? chat?.selectedStatus : statusesPredict[chat.employeeStatus.status_id].arr[0]) == '4',
                                        'fives': (!!chat?.selectedStatus ? chat?.selectedStatus : statusesPredict[chat.employeeStatus.status_id].arr[0]) == '5',
                                        'sixs': (!!chat?.selectedStatus ? chat?.selectedStatus : statusesPredict[chat.employeeStatus.status_id].arr[0]) == '6',
                                        'nine': (!!chat?.selectedStatus ? chat?.selectedStatus : statusesPredict[chat.employeeStatus.status_id].arr[0]) == '97',
                                        'seven': (!!chat?.selectedStatus ? chat?.selectedStatus : statusesPredict[chat.employeeStatus.status_id].arr[0]) == '98',
                                        'eight': (!!chat?.selectedStatus ? chat?.selectedStatus : statusesPredict[chat.employeeStatus.status_id].arr[0]) == '99'}">
                                        <div class="d-f-c" (click)="openChatStat($event, chat)">
                                            <div class="active_stat" (click)="changeStat($event, chat)">
                                                <ng-container *ngIf="!!chat?.statusChanged; else elseTemplateAct">
                                                    <svg class="check_s" style="width: 16px; height: 16px;" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M3.33333 2H12.6667C13.4067 2 14 2.6 14 3.33333V12.6667C14 13.4 13.4067 14 12.6667 14H3.33333C2.59333 14 2 13.4 2 12.6667V3.33333C2 2.6 2.59333 2 3.33333 2ZM3.33333 8L6.66667 11.3333L12.6667 5.33333L11.7267 4.38667L6.66667 9.44667L4.27333 7.06L3.33333 8Z" fill="#686868"/>
                                                    </svg>
                                                </ng-container>
                                                <ng-template #elseTemplateAct>
                                                    <svg class="check_s" style="width: 16px; height: 16px;" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M3.33333 2H12.6667C13.4 2 14 2.6 14 3.33333V12.6667C14 13.4 13.4 14 12.6667 14H3.33333C2.6 14 2 13.4 2 12.6667V3.33333C2 2.6 2.6 2 3.33333 2ZM12.6667 12.6667V3.33333H3.33333V12.6667H12.6667Z" fill="#686868"/>
                                                    </svg>                                                   
                                                </ng-template>
                                            </div>
                                            {{ (!!chat?.selectedStatus ? getChatStatus(chat?.selectedStatus) : getChatStatus(statusesPredict[chat.employeeStatus.status_id].arr[0])) | translate }}

                                            <svg style="margin-left: 8px; width: 10px; height: 10px" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7.91669 3.33331L2.08335 3.33331L5.00002 7.91665L7.91669 3.33331Z" fill="#686868"/>
                                            </svg> 
                                        </div>
                                        <div class="chat_stat_in">
                                            <div *ngFor="let id of statusesPredict[chat.employeeStatus.status_id].arr; let m = index;" (click)="selectStat($event, id, chat)" [ngClass]="{
                                                'active': (!chat?.selectedStatus && m == 0) || (id == chat?.selectedStatus),
                                                'first': id == '1',
                                                'second': id == '2',
                                                'third': id == '3',
                                                'fours': id == '4',
                                                'fives': id == '5',
                                                'sixs': id == '6',
                                                'nine': id == '97',
                                                'seven': id == '98',
                                                'eight': id == '99'}">{{getChatStatus(id) | translate}}</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="d-f-c">
                                    <div class="chat_status" [ngClass]="{
                                        'first': chat.status_id == '1',
                                        'second': chat.status_id == '2',
                                        'third': chat.status_id == '3',
                                        'fours': chat.status_id == '4',
                                        'fives': chat.status_id == '5',
                                        'sixs': chat.status_id == '6',
                                        'nine': chat.status_id == '97',
                                        'seven': chat.status_id == '98',
                                        'eight': chat.status_id == '99'}">
                                        {{ getChatStatus(chat.status_id) | translate }}
                                    </div>
                                    <ng-container *ngIf="chat.updated_at > today; else updatedAtTempl">
                                        <div class="chat_updated">
                                          {{ chat.updated_at*1000 | date:'hh:mm a':'timeZone':data.activeLang | yearPipe }}
                                        </div>
                                    </ng-container>
                                    <ng-template #updatedAtTempl>
                                        <div class="chat_updated" #tooltip="matTooltip"
                                        matTooltip="{{ chat.updated_at*1000 | date:'hh:mm a':'timeZone':data.activeLang | yearPipe }}"
                                        [matTooltipPosition]="'above'">
                                          {{ chat.updated_at*1000 | date:'MMM d, y':'timeZone':data.activeLang | yearPipe }}
                                        </div>
                                    </ng-template>
                                </div>
                                <!-- <div class="chat_updated">
                                    {{ chat.updated_at*1000 | date:'MMM d, y':'timeZone':data.activeLang | yearPipe }}
                                </div> -->
                      
                            </div>
                        </div>
                    </ng-template>
                </div>
                
                <div class="target_chat" *ngIf="menuState">
                    <app-chat 
                    [chat_id]='chat_id'
                    [chats]='chats'
                    [timeZone]='timeZone'
                    [statuses]='statuses'
                    [activeLang]='data.activeLang'
                    [company]='data.company'
                    [user]='data?.user'
                    [task]="data?.task"
                    [work]="data?.work"
                    [tasks]="data?.tasks"
                    [is_mobile]='is_mobile'
                    [is_small]='true'
                    [dialogComp]='[dialogRef]'
                    [operationsValues]='data.operationsValues'
                    (HideMenu)="hideMenu()"
                    ></app-chat>
                    <!-- (ToNextChat)="toNextChat()" -->
                </div>
            </ng-template>
            
        </div>
    </div>
</mat-dialog-content>

<ng-template #taskMenu let-val>
	<section class="file-menu">
        <ng-container *ngFor="let outlet of data?.task?.uniqueChannels">
            <div class="sub_item sub_item_view" *ngIf="outlet && outlet.channel && !!outlet.channel.channel_account_id" (click)="outletClick(outlet, data.file)">
                <ng-container *ngIf="outlet.channel.avatarFile; else elseTemplateNoPhoto">
                    <div class="channel_avatar">
                        <img [src]="host + outlet.channel.avatarFile.original + '?company_id=' + company_id" alt="">
                        <div class="channel_avatar_platform">
                            <app-platform [platform_id]="outlet.channel?.platform_id"></app-platform>
                        </div>
                    </div>
                </ng-container>
                <ng-template #elseTemplateNoPhoto>      
                    <div class="platform_icon">
                        <app-platform [platform_id]="outlet.channel?.platform_id"></app-platform>
                    </div>
                </ng-template>
                <span class="text_one_line">
                    {{outlet.channel.name}}                                  
                </span>
            </div>
        </ng-container>
	</section>
</ng-template>
                