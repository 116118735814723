import { SelectionModel } from '@angular/cdk/collections';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { DateRange, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { mimeTypes } from 'mime-wrapper';
import * as moment from 'moment';
import { Moment } from 'moment';
import { concat, forkJoin, from, fromEvent, of, ReplaySubject, Subject, Subscription } from 'rxjs';
import { catchError, concatMap, debounceTime, distinctUntilChanged, filter, last, map, switchMap, take, tap } from 'rxjs/operators';
import { prioritys } from 'src/app/shared/consts/prioritys';
import { casesModel } from 'src/app/shared/functions/casesModel';
import { jobsModel } from 'src/app/shared/functions/jobsModel';
import { MobPriorityComponent } from 'src/app/shared/global_components/chat/dialogs/mob-priority/mob-priority.component';
import { MobTitleComponent } from 'src/app/shared/global_components/chat/dialogs/mob-title/mob-title.component';
import { DeleteAlertComponent } from 'src/app/shared/global_components/delete-alert/delete-alert.component';
import { VideoViewerComponent } from 'src/app/shared/global_components/file-manager/dialog/video-viewer/video-viewer.component';
import { MobFmViewComponent } from 'src/app/shared/global_components/mob-file-manager/dialogs/mob-fm-view/mob-fm-view.component';
import { NotesComponent } from 'src/app/shared/global_components/notes/notes.component';
import { NotificationsBar } from 'src/app/shared/global_components/notifications-bar/notifications-bar';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { BaseClass } from 'src/app/shared/models/base-class';
import { PermissionsPipe } from 'src/app/shared/pipes/permissions.pipe';
import { GlobalDataService } from 'src/app/shared/services/common/global-data.service';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { AuthService } from 'src/app/shared/services/rest/auth.service';
import { ChatService } from 'src/app/shared/services/rest/chat.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { FileService } from 'src/app/shared/services/rest/file.service';
import { MembersService } from 'src/app/shared/services/rest/members.service';
import { MinimizeService } from 'src/app/shared/services/rest/minimize.service';
import { ParametersService } from 'src/app/shared/services/rest/parameters.service';
import { RefreshService } from 'src/app/shared/services/rest/refresh.service';
import { ScenariosService } from 'src/app/shared/services/rest/scenarios.service';
import { TaskBarService } from 'src/app/shared/services/rest/task-bar.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { UploadService } from 'src/app/shared/services/rest/upload.service';
import { environment } from 'src/environments/environment';
import { CheckListComponent } from '../../atTasksDialog/check-list/check-list.component';
import { CreateOperationChatComponent } from '../../atTasksDialog/create-operation-chat/create-operation-chat.component';
import { OperationChatsComponent } from '../../atTasksDialog/operation-chats/operation-chats.component';
import { TargetParametersComponent } from '../../atTasksDialog/target-parameters/target-parameters.component';
import { MY_FORMATS } from '../../atTasksDialog/task-profile-add/task-profile-add.component';
import { WorkCommentEditComponent } from '../../atTasksDialog/work-comment-edit/work-comment-edit.component';
import { WorkCompletedAtEditComponent } from '../../atTasksDialog/work-completed-at-edit/work-completed-at-edit.component';
import { WorkEditComponent } from '../../atTasksDialog/work-edit/work-edit.component';
import { WorkEmplStatusEditComponent } from '../../atTasksDialog/work-empl-status-edit/work-empl-status-edit.component';
import { WorkEmployeeCommentEditComponent } from '../../atTasksDialog/work-employee-comment-edit/work-employee-comment-edit.component';
import { WorkEmployeeEditComponent } from '../../atTasksDialog/work-employee-edit/work-employee-edit.component';
import { WorkOperationIdEditComponent } from '../../atTasksDialog/work-operation-id-edit/work-operation-id-edit.component';
import { WorkRateEditComponent } from '../../atTasksDialog/work-rate-edit/work-rate-edit.component';
import { WorkStatusEditComponent } from '../../atTasksDialog/work-status-edit/work-status-edit.component';
import { JobEditCompletedAtComponent } from '../../mob-job/job-edit-completed-at/job-edit-completed-at.component';
import { JobEditTypeComponent } from '../../mob-job/job-edit-type/job-edit-type.component';
import { JobEditComponent } from '../../mob-job/job-edit/job-edit.component';
import { ExecutorsComponent } from '../cases/dialogs/executors/executors.component';
import { SetEmplComponent } from '../cases/dialogs/members/set-empl/set-empl.component';
import { MoveJobsComponent } from '../cases/dialogs/move-jobs/move-jobs.component';
import { OpenJobComponent } from '../cases/dialogs/open-job/open-job.component';
import { MobChangePersonalStatusComponent } from '../cases/dialogs/open-task/dialogs/mob-change-personal-status/mob-change-personal-status.component';
import { StatusReasonComponent } from '../cases/dialogs/status-reason/status-reason.component';
import { ToApproveStepperComponent } from '../cases/dialogs/to-approve-stepper/to-approve-stepper.component';
import { AddJobToBoardComponent } from './dialogs/add-job-to-board/add-job-to-board.component';
import { JobAutomationComponent } from './dialogs/job-automation/job-automation.component';
import { MultiEditJobsTypeComponent } from './dialogs/multi-edit-jobs-type/multi-edit-jobs-type.component';
import { MultiJobsAssignScenarioComponent } from './dialogs/multi-jobs-assign-scenario/multi-jobs-assign-scenario.component';
import { MultiEditExecutorsComponent } from '../chats-page/dialogs/multi-edit-executors/multi-edit-executors.component';
import { OpenTaskComponent } from '../cases/dialogs/open-task/open-task.component';
import { OperationChatComponent } from '../../atTasksDialog/operation-chat/operation-chat.component';
import { ChatsComponent } from '../../chats/chats.component';
import { TaskAddClientComponent } from '../../clients/task-add-client/task-add-client.component';
import { MobAssignmentsComponent } from '../../mob-job/mob-assignments/mob-assignments.component';
import { MultiJobsExecScenariosComponent } from './dialogs/multi-jobs-exec-scenarios/multi-jobs-exec-scenarios.component';
import { LiteJobComponent } from '../cases/dialogs/lite-job/lite-job.component';
import { MigrateTargetFilesComponent } from 'src/app/shared/global_components/migrate-target-files/migrate-target-files.component';
import { MultiDeleteClientsComponent } from './dialogs/multi-delete-clients/multi-delete-clients.component';
import { MultiDeleteTagsComponent } from './dialogs/multi-delete-tags/multi-delete-tags.component';
import { AddEditViewVariableComponent } from '../projects-page/diaglos/add-edit-view-variable/add-edit-view-variable.component';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { LoadingService } from 'src/app/shared/services/rest/loading.service';

@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
})

export class JobsComponent extends BaseClass implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @ViewChild("jobsContainer") jobsContainer: ElementRef;
  @ViewChild('uploaderFolder') uploaderFolder: ElementRef<HTMLInputElement>;
  @ViewChild('uploader') uploader: ElementRef<HTMLInputElement>;
  public host: any = environment.host;
  public imgRoute: any = '';
  public user: any = {};
  public origin = window.location.origin;
  public user_id: number;
  public currMonthName: any;  
  public company_id: any;
  public templates: any;
  public templatesControl: FormControl = new FormControl();
  public templates$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public currencyData: any;
  public activeLang: any;
  public currencyes: any;

  public groups_partner_id: any;
  // public partnerGroups: any;
  
  // @ViewChild('filterParameterMenu') filterParameterMenu: TemplateRef<any>;
  // public parameters: any;
  // public allValues: any;
  // public parameterFilterSub: Subscription;
  public separatorKeysCodes: number[] = [ENTER, COMMA];
  // overlayParameterRefFilter: OverlayRef | null;

  public page: number = 1;
  public filterCount: number = 1;
  public pagination: object = {};
  public timeZone = new Date().getTimezoneOffset();
  public is_mobile: boolean = false;
  public disableTemplate: boolean = false;
  public jobs: any[] = [];
  // public partners: any;
  public taskStatuses: any;
  public sortValue: string = 'updated_desc';
  public activeStatus: any = '';
  public prioritys = prioritys;
  public selectedJob: any;
  // public platforms: any;
  public isLoad: boolean = false;
  public uploadLimit: boolean = false;
  public dateFromChanged: boolean = false;
  public dateToChanged: boolean = false;
  public collectionIsActive: boolean = false;
  public isSettingsOpen: boolean = false;
  public jobsSub: Subscription;
  public me: any;
  public viewMode: number = 0;
  public jobCollection: any;
  public curYear = (new Date()).getFullYear();

  public liteFmSub: Subscription;
  public liteFmOverlayRef: OverlayRef | null;
  @ViewChild('liteFmMenu') liteFmMenu: TemplateRef<any>;

  public viewArch = {
    columns: []
  }
  public variables = [
    {
      key: 'id',
      name: 'Job ID',
      is_root: 0,
      fontSize: 14,
      color: "#000"
    },
    {
      key: 'job_name',
      name: 'Job Name',
      is_root: 0,
      fontSize: 14,
      color: "#000"
    },
    {
      key: 'task.name',
      name: 'Card Name',
      is_root: 0,
      fontSize: 14,
      color: "#000"
    },
    {
      key: 'task_id',
      name: 'Card ID',
      is_root: 0,
      fontSize: 14,
      color: "#000"
    },
    {
      key: 'task.custom_id',
      name: 'Card Custom ID',
      is_root: 0,
      fontSize: 14,
      color: "#000"
    },
    {
      key: 'employees',
      name: 'Employees',
      is_root: 0,
    },
    {
      key: 'chats',
      name: 'Chats',
      is_root: 0,
    },
    {
      key: 'notes',
      name: 'Notes',
      is_root: 0,
    },
    {
      key: 'filesize',
      name: 'Files',
      is_root: 0,
      fontSize: 14,
      color: "#000"
    },
    {
      key: 'job_type',
      name: 'Job type',
      is_root: 0,
      fontSize: 14,
      color: "#000"
    },
    {
      key: 'tag',
      name: 'Tag',
      is_root: 0,
      fontSize: 14,
      color: "#000"
    },
    {
      key: 'status_and_prices',
      name: 'Status and Prices',
      is_root: 0,
      fontSize: 14,
      color: "#000"
    },
    {
      key: 'group',
      name: 'Card Group',
      is_root: 0,
      fontSize: 14,
      color: "#000"
    },
    {
      key: 'connected_groups',
      name: 'Connected Groups',
      is_root: 0,
      fontSize: 14,
      color: "#000"
    },
    {
      key: 'scenarios',
      name: 'Scenarios',
      is_root: 0,
    },
    {
      key: 'deadline',
      name: 'Deadline',
      is_root: 0,
      fontSize: 14,
      color: "#000"
    },
    {
      key: 'completed',
      name: 'Completed',
      is_root: 0,
      fontSize: 14,
      color: "#000"
    },
    {
      key: 'created',
      name: 'Created',
      is_root: 0,
      fontSize: 14,
      color: "#000"
    },
    {
      key: 'updated',
      name: 'Updated',
      is_root: 0,
      fontSize: 14,
      color: "#000"
    },
  ]

  public jobsDataSub: Subscription;
  // public parametersSub: Subscription;
  public jobLoadSub: Subscription;
  public changingSub: Subscription;
  public company: any;
  public operationsValues: any;
  public statuses: any;
  public bookmarks: any;
  public bookmarksControl: FormControl = new FormControl();
  public bookmarks$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public job_exp: number = 0;
  
  public statusesForFilter: any = [
    {
      name: "All",
      id: 0,
      hasJobs: true
    },
    {
      name: "Waiting",
      id: 1,
      hasJobs: false
    },
    {
      name: "In process",
      id: 2,
      hasJobs: false
    },
    {
      name: "To approve",
      id: 3,
      hasJobs: false
    },
    {
      name: "Approved",
      id: 4,
      hasJobs: false
    },
    {
      name: "To correct",
      id: 5,
      hasJobs: false
    },
    {
      name: "Sources needed",
      id: 6,
      hasJobs: false
    },
    {
      name: "On hold",
      id: 97,
      hasJobs: false
    },
    {
      name: "Ready",
      id: 98,
      hasJobs: false
    },
    {
      name: "Canceled",
      id: 99,
      hasJobs: false
    },
  ];
  public contentUrl = [
    {
      name: marker("Without content url"),
      id: 0
    },
    {
      name: marker("With content url"),
      id: 1
    }
  ]
  public showFilter: boolean = !!this.sm.localStorageGetItem('showFilter')
  public today = moment().set({hour:0,minute:0,second:0}).unix();

  // public profiles: any[] = [];
  // public profilesPagination: any;
  // public profilesPage: number = 1;

  public filter: FormGroup = this.fb.group({
    count: '',
    q: '',
    order_by: 'updated_desc',
    status_id: [],
    task_operation_parameter_value_id: [[]],
    task_parameter_value_id: [[]],
    discussion_parameter_value_id: [[]],
    parameter_value_id: [[]],
    file_parameter_value_id : [[]],
    channel_id: [[]],
    no_channel_id: [[]],
    created_at_from: "",
    created_at_to: "",
    updated_at_from: "",
    updated_at_to: "",
    task_operation_planned_completed_at_from: "",
    task_operation_planned_completed_at_to: "",
    completed_at_from: "",
    completed_at_to: "",
    task_operation_operation_id: [[]],
    client_employee_id: "",
    origin_company_id: "",
    task_operation_other_employee_status_id: 0,
    group_id: [[]],
    priority: "",
    task_status_id: '',
    channel_platform_id: '',
    is_content_url: '',
    task_custom_id: '',
    task_created_at_from: "",
    task_created_at_to: "",
    task_operation_created_at_from: "",
    task_operation_created_at_to: "",
    task_operation_completed_at_from: "",
    task_operation_completed_at_to: "",
    discussion_created_at_from: "",
    discussion_created_at_to: "",
    file_created_at_from: "",
    file_created_at_to: "",
    file_updated_at_from: "",
    file_updated_at_to: "",
    file_meta_creation_time_from: "",
    file_meta_creation_time_to: "",
    task_channel_published_at_from: "",
    task_channel_published_at_to: "",
    content_type_id: '',
    preset_id: '',
    no_operation_id: '',
    content_status_id: '',
    operation_employee_id: "",
    operation_partner_company_id: "",
  });
  // status_id: [],
  // group_id: [],
  // priority: [],
  // employee_id: [],
  // client_employee_id: [],
  // operation_id: [],
  // id: [],
  // created_at_from : '',
  // created_at_to : '',
  // posted_at_from : '',
  // posted_at_to : '',
  @Input() useOut:boolean = false;
  @Input() task_id:any = false;
  @Input() task:any;
  @Input() job:any;
  @Input() tasks:any;
  @Input() initChats:any = false;
  @Input() task_operation_id:any = false;
  @Input() changing: Subject<boolean>;

  public groups: any;
  // public groupsControl: FormControl = new FormControl();
  // public groups$: ReplaySubject<any> = new ReplaySubject<any>(1);

  // public employees: any;
  // public employees$: ReplaySubject<any> = new ReplaySubject<any>(1);
  // public execControl: FormControl = new FormControl();
  // public clientControl: FormControl = new FormControl();

  public createdDateRange = new DateRange("", "");
  public updatedDateRange = new DateRange("", "");
  public completedDateRange = new DateRange("", "");
  public deadlineDateRange = new DateRange("", "");

  // overlayDateRefFilter: OverlayRef | null;
  // public dateFilterSub: Subscription;
  // @ViewChild('jobFilterDateMenu') jobFilterDateMenu: TemplateRef<any>;

  constructor(
    private sm: StorageManagerService,
    private auth: AuthService,
    private router: Router,
    private _snackBar: MatSnackBar,
    private chatService: ChatService,
    private uploadService: UploadService,
    private _dateAdapter: DateAdapter<Moment>,
    private membersService: MembersService,
    private globalDataService: GlobalDataService,
    public activatedRoute: ActivatedRoute,
    private taskService: TaskService,
    private parametersService: ParametersService,
    private companyService: CompanyService,
    private taskBarService: TaskBarService,
    private minimizeService: MinimizeService,
    private scenariosService: ScenariosService,
    public viewContainerRef: ViewContainerRef,
    public overlay: Overlay,
    private languageService: LanguageService,
    private layoutService: LayoutService,
    private fb: FormBuilder,
    private ls: LoadingService,
    private dialog: MatDialog,
    private bottomSheet: MatBottomSheet,
    private _adapter: DateAdapter<any>,
    private fileService: FileService,
    private refreshService: RefreshService
  ) { super() }

  ngOnInit(): void {
    this.company_id = this.activatedRoute.snapshot.queryParamMap.get('company_id');

    // if (!!this.sm.localStorageGetItem('jobsView')) {
    //   this.viewArch = JSON.parse(this.sm.localStorageGetItem('jobsView'));
    // } else {
    //   // this.viewArch.columns.push(...JSON.parse(JSON.stringify(this.columns)))
    //   this.toDefault();
    // }
    // this.sm.localStorageSetItem('jobsView', JSON.stringify(this.viewArch));

    this.attachSubscriptions(
      this.globalDataService.getTransferData().subscribe(resp => {
        console.log("JOBS getTransferData", resp)
        if (resp.groups) {
          this.groups = resp.groups;
        }
      })
    )

    this.checkIsMobile();
    this.getCurrencyList();
    this.ifAllStatuses();
    if (!!this.sm.localStorageGetItem('card_jobs')) {
      this.viewMode = +this.sm.localStorageGetItem('card_jobs');
    }
    this.getLangData(this.company_id);
    this.getImgRoute();
    this.getUser();
    this.getTaskStatus();
    // this.getPlatforms();
    // this.getPartners();
    this.onRouteChange();
    this.getAutomationTemplates();
    this.attachSubscriptions(
      this.refreshService.getRefreshBookmarks().subscribe(res => {
        if (res == "Jobs") {
          this.getBookmarks(true)
        }
      })
    )

    this.groups_partner_id = this.activatedRoute.snapshot.queryParamMap.get('groups_partner_id');
    // if (!!this.groups_partner_id) {
    //   this.getPartnerGroups()
    // }
    // this.attachSubscriptions(
    //   this.chatService.getDeletedJobs().subscribe(resp => {
    //     if (this.jobs.filter(x => x.id == resp).length != 0) {
    //       let deletedJob = this.jobs.find(x => x.id == resp);
    //       if (!deletedJob.opened) {
    //         let indexDeletedJob = this.jobs.indexOf(deletedJob);
    //         this.jobs.splice(indexDeletedJob, 1)
    //       }
    //     }
    //   })
    // )

    this.getOperationsStatus();
    this.getOperations();
    this.refresh();
    
    // this.attachSubscriptions(
    //   this.groupsControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchGroups(resp))
    // )
    this.attachSubscriptions(
      this.bookmarksControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchBookmarks(resp))
    )

    // this.attachSubscriptions(
    //   this.execControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchEmoloyees(resp))
    // )

    // this.attachSubscriptions(
    //   this.clientControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchEmoloyees(resp))
    // )
    this.attachSubscriptions(
      this.templatesControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchTemplates(resp))
    )
  }

  toDefault() {
    this.viewArch.columns = [
      {
        name: "ID",
        variables: [
          {
            key: 'id',
            name: 'Job ID',
            is_root: 0,
            fontSize: 14,
            color: "#000"
          },
        ]
      },
      {
        name: "Job Name",
        variables: [
          {
            key: 'job_name',
            name: 'Job Name',
            is_root: 0,
            fontSize: 12,
            color: "#000"
          }
        ]
      },
    ];
    // if (this.user && this.user.is_root && this.viewArch.columns.find(x => x.id == 3)) {
    //   this.viewArch.columns.find(x => x.id == 3).variables.push({
    //     key: 'server_and_region',
    //     name: 'Server and Region',
    //     is_root: 1,
    //     fontSize: 12,
    //     color: "#1877F2"
    //   })
    // }
    this.sm.localStorageSetItem('jobsView', JSON.stringify(this.viewArch));
    this.sm.localStorageSetItem('jobs_preset_id', -1);
  }

  openSettings() {
    this.isSettingsOpen = !this.isSettingsOpen
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.viewArch.columns, event.previousIndex, event.currentIndex);
    this.sm.localStorageSetItem('jobsView', JSON.stringify(this.viewArch));
  }

  dropVar(event: CdkDragDrop<string[]>, array) {
    moveItemInArray(array, event.previousIndex, event.currentIndex);
    this.sm.localStorageSetItem('jobsView', JSON.stringify(this.viewArch));
  }

  deleteColumn(item, i) {
    this.viewArch.columns.splice(i, 1);
    this.sm.localStorageSetItem('jobsView', JSON.stringify(this.viewArch));
  }

  deleteVariable(item, variable, k) {
    item.variables.splice(k, 1)
    this.sm.localStorageSetItem('jobsView', JSON.stringify(this.viewArch));
  }

  addVariable(col, variable?) {
    const dialogRef = this.dialog.open(AddEditViewVariableComponent, {
      disableClose: true,
      data: {
        col: col,
        variable: variable,
        variables: this.variables,
        is_edit: !!variable,
        user: this.user
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log("addVariable result", result)
        console.log("addVariable col", col)
        if (result.data && !variable) {
          col.variables.push(result.data);
        }
        this.sm.localStorageSetItem('jobsView', JSON.stringify(this.viewArch));
      })
    )
  }

  addColumn() {
    this.viewArch.columns.push({
      name: "Columnn " + (this.viewArch.columns.length + 1),
      variables: []
    })
  }

  changeView(e) {
    this.viewMode = e.value;
    this.sm.localStorageSetItem('card_jobs', this.viewMode);
  }

  onSearchTemplates(resp) {
    if (!this.templates) {
      return;
    }

    if (!resp) {
      this.templates$.next(this.templates.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.templates$.next(
      this.templates.filter(z => z.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  // onSearchEmoloyees(resp) {
  //   if (!this.employees) {
  //     return;
  //   }

  //   if (!resp) {
  //     this.employees$.next(this.employees.slice());
  //     return;
  //   } else {
  //     resp = resp.toLowerCase();
  //   }
  //   // filter the banks
  //   this.employees$.next(
  //     this.employees.filter(z => z.name.toLowerCase().indexOf(resp) > -1)
  //   );
  // }

  openNotes(item, type?) {
    console.log(item);
    const dialogRef = this.dialog.open(NotesComponent, {
      data: {
        company: this.company,
        company_id: this.company_id,
        user: this.user,
        type: type || 'job',
        item: item,
        is_mobile: this.is_mobile
      }
    });
    
    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log(result)
      })
    )
  }

  addClient() {
    const dialogRef = this.dialog.open(TaskAddClientComponent, {
      data: {
        company: this.company,
        company_id: this.company.id,
        user: this.user,
        selectedJobs: this.jobCollection.selected
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log(result)
        if (result.event == 'update') {
          // this.getClients();
          this.jobCollection.selected.forEach(job => {
            this.updateJob(job.id)
          });
        }
      })
    )
  }

  addPartnerClient() {
    const dialogRef = this.dialog.open(TaskAddClientComponent, {
      data: {
        company: this.company,
        company_id: this.company.id,
        user: this.user,
        selectedJobs: this.jobCollection.selected,
        is_partner: true,
        imgRoute: this.imgRoute,
        host: this.host
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log(result)
        if (result.event == 'update') {
          this.jobCollection.selected.forEach(job => {
            this.updateJob(job.id)
          });
        }
      })
    )
  }

  
  goToLink(val) {
    window.open(val, '_blank').focus();
  }
  
  // onSearchGroups(resp) {
  //   if (!this.groups) {
  //     return;
  //   }

  //   if (!resp) {
  //     this.groups$.next(this.groups.slice());
  //     return;
  //   } else {
  //     resp = resp.toLowerCase();
  //   }

  //   this.groups$.next(
  //     this.groups.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
  //   );
  // }

  // getChatsCount() {
  //   this.statusesForFilter.filter(y => y.id).map(x => x.hasJobs = false)
  //   this.attachSubscriptions(
  //     forkJoin(this.statusesForFilter.filter(y => y.id).map(x => this.chatService.getAllChatsCount(this.company_id, x.id, this.task_id, this.task_operation_id, this.filter.value).pipe(
  //       tap(z => {
  //         if (z.headers.get('x-pagination-total-count') > 0) {
  //           x.hasChats = true;
  //         }
  //       })
  //     ))).subscribe(resp => {
  //       console.log(this.statusesForFilter)
  //     })
  //   )
  // }

  openTargetValues(info, element) {
    let initData:any = {
      company: this.company,
      [!!info[1] ? 'job' : 'task']: element
    }

    initData.target = {
      task_id: info[0],
      task_operation_id: info[1],
      discussion_id: info[2]
    }
    if (element && element.company_id != this.company.id) {
      initData.target.partner_company_id = element.company_id
    }

    if (this.jobCollection.selected.length > 1) {
      let arr = [];
      this.jobCollection.selected.forEach(job => {
        arr.push(job);
      });
      
      initData.selectedJobs = arr
    }
    const dialogRef = this.dialog.open(TargetParametersComponent, {
      backdropClass: ['parameters_modal_backdrop'],
      panelClass: ['without_paddings_modal', 'parameters_modal'],
      data: initData
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (this.jobCollection.selected) {
          this.jobCollection.selected.forEach((job, ind) => {
            this.refreshService.refreshJob$.next({company_id: this.company_id, job_id: job.id})
            if (ind == this.jobCollection.selected.length - 1) {
              this.jobCollection.clear()
            }
          })
        }
      })
    )
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    console.log("JobsComponent changes", changes)
    // if (this.useOut) {
    //   if (changes.task_operation_id && changes.task_operation_id.previousValue != changes.task_operation_id.currentValue) {
    //     this.filterCount++
    //     this.filter.patchValue({
    //       count: this.filterCount
    //     })
    //   }
    // }
  }

  refresh() {
    this.attachSubscriptions(
      this.refreshService.getRefreshJobs().pipe(
        concatMap(res => {
          if ( this.jobs.filter(x => x.id == res.job_id).length > 0 && this.company_id == res.company_id ) {
            return this.taskService.getWorks(1, this.company_id, {id: [res.job_id]}, !this.is_mobile ? '20' : '10').pipe(
              map(x => x.body[0]),
              map(val => jobsModel([val], this.currencyData.slice())),
              map(res => res[0]),
              switchMap(val => this.neededData(val, true).pipe(map(() => val))),
              tap(val => {
                console.log(val);
                this.jobs.splice(this.jobs.indexOf(this.jobs.find(b => b.id == val.id)),1, val)
              })
            )
          } else {
            return of(false)
          }    
        })
      ).subscribe(resp => {
        console.log("getRefreshChats", resp)
      })
    )
  }

  // addToBoard(chat) {
  //   const goToChat = this.dialog.open(AddChatToBoardComponent, {
  //     panelClass: 'add_to_board_modal',
  //     data: {
  //       company: this.company,
  //       user: this.user,
  //       imgRoute: this.imgRoute,
  //       host: this.host,
  //       activeLang: this.activeLang, 
  //       chat: chat,
  //       employees: chat.employees
  //     }
  //   });
  // }

  neededTaskData(task, get_chats:boolean = true) {
    let arr = [];
    if (this.company.permissions.includes('owner') || this.checkIsManager(task, this.company)) {
      arr.push(this.taskService.getTaskClients(task.id, this.company_id).pipe(
        tap(res => {
          task.clients = res
        }),
        catchError(err => {
          return err
        })
      ))
    }
    if (get_chats) {
      arr.push(this.chatService.getTasksChats(this.company.id, [task.id]).pipe(
        tap(res => {
          task['operations'].map(o_el => {
            o_el['chatsInfo'] = [];
            o_el['chatsInfo'].push(...res.filter(z => z.task_operation_id == o_el.id));
            o_el['unReadChats'] = res.filter(z => z.task_operation_id == o_el.id && z.is_read != '1').length;
            o_el['unClosedChats'] = res.filter(z => z.task_operation_id == o_el.id && z.status_id != '4').length;
          });
          task['chatsInfo'] = [];
          task['chatsInfo'].push(...res.filter(z => z.task_id == task.id));
          task['unReadChats'] = res.filter(z => z.is_read != '1').length;
          task['unClosedChats'] = res.filter(z => z.status_id != '4').length;
        })
      ))
    }

    return forkJoin(arr)
  }

  chatClick(e, job) {
    e.preventDefault(); 
    e.stopPropagation(); 
    if (job.chatsInfo.length == 1) {
      this.goToChat(job, job.chatsInfo[0].id)
      // this.openChat(job, job.chatsInfo[0])
      // this.goToChat(job)
    } else {
      // this.openJob(job.task_id, job, {selectedIndex: 0})
      this.openLiteJob(job, 0)
    }
  }

  openChat(job, _chat) {
    this.attachSubscriptions(
      this.chatService.getChatsExpand(1, this.company_id, {id: _chat.id}).pipe(
        map(res => res.body[0])
      ).subscribe(chat => {
        const goToChat = this.dialog.open(ChatsComponent, {
          backdropClass: ['backdrop_under_header', "without_pad"],
          panelClass: !this.is_mobile ? ['full_screen_dialog', 'global_chats_dialog', 'show_header'] : ['full_screen_dialog', 'global_chats_dialog'],
          position: !this.is_mobile ? { top: '58px', left: '0', right: '0', bottom: '0' } : {},
          data: {
            company: this.company,
            user: this.user,
            imgRoute: this.imgRoute,
            host: this.host,
            dialogType: 'global_chat',
            activeLang: this.activeLang, 
            chat: chat
          }
        });

        this.attachSubscriptions(
          goToChat.afterClosed().subscribe(res => {
            this.updateJob(job.id)
          })
        )
      })
    )
  }

  goToChat(job, chat_id) {
    this.attachSubscriptions(
      this.chatService.getChatsExpand(1, this.company.id, {id: chat_id}).pipe(
        map(res => res.body[0]),
        switchMap(chat => {
          return this.taskService.getOneTaskExpand(this.company.id, chat.task_id).pipe(
            map(x => x.body[0]),
            switchMap(x => this.neededTaskData(x).pipe(map(() => casesModel([x], [], 'update')),
            switchMap(x => this.taskService.getOperations(this.company.id, this.activeLang).pipe(tap(u => x.arr[0].operationsValues = u), map(() => x))),
            map((x:any) => { return {task: x.arr[0], 'chat': chat}}))),
          )
        })
      ).subscribe((resp:any) => {
        console.log(resp);

        let data:any = {
          company: this.company,
          task: resp.task,
          dialogType: 'operation_chat',
          operationsValues: resp.task.operationsValues,
          work: resp.task.operations.find(el => el.id == job.id),
          user: this.user,
          empl_status: this.company.permissions.includes('owner') || this.company?.permissions.includes('admin') || this.company.permissions.includes('manager'),
          chat: resp.chat,
          chats: resp.task.operations.find(el => el.id == resp.chat.task_operation_id).chatsInfo
        }
 
        const goToChat = this.dialog.open(OperationChatComponent, {
          backdropClass: ['backdrop_under_header', "without_pad"],
          panelClass: !this.is_mobile ? ['full_screen_dialog', 'global_chats_dialog', 'show_header'] : ['full_screen_dialog', 'global_chats_dialog'],
          position: !this.is_mobile ? { top: '58px', left: '0', right: '0', bottom: '0' } : {},
          autoFocus: this.is_mobile ? false : true,
          data: data
        });
    
        this.attachSubscriptions(
          goToChat.afterClosed().subscribe(result => {
            if (!!result && result.event == 'close') {
              this.chatService.postChatRead(chat_id, 0, this.company.id).subscribe(resp => {
                this.updateJob(job.id)
              })
            }
          })
        )
      })
    )
  }

  // goToChat(job) {
  //   const goToChat = this.dialog.open(OperationChatComponent, {
  //     backdropClass: ['backdrop_under_header', "without_pad"],
  //     panelClass: !this.is_mobile ? ['full_screen_dialog', 'global_chats_dialog', 'show_header'] : ['full_screen_dialog', 'global_chats_dialog'],
  //     position: !this.is_mobile ? { top: '58px', left: '0', right: '0', bottom: '0' } : {},
  //     autoFocus: this.is_mobile ? false : true,
  //     data: {
  //       company: this.company,
  //       task: job.task,
  //       work: job,
  //       dialogType: 'operation_chat',
  //       user: this.user,
  //       empl_status: this.company.permissions.includes('owner') || this.checkIsManager(job, this.company),
  //       chat: job.chatsInfo[0],
  //       chats: job?.chatsInfo,
  //       closeFull: true,
  //       operationsValues: this.operationsValues
  //     }
  //   });

  //   this.attachSubscriptions(
  //     goToChat.afterClosed().subscribe(result => {
  //       this.updateJob(job.id)
  //     })
  //   )
  // }

  addToBoardJob(job) {
    this.attachSubscriptions(
      this.taskService.getOneTaskExpand(this.company.id, job.task_id).pipe(
        map(x => x.body[0]),
        switchMap(val => this.neededTaskData(val).pipe(map(() => casesModel([val], [], 'update')),map(val => val.arr[0])))
      ).subscribe(resp => {
        console.log("addToBoard", resp)
        let work = resp.operations.find(x => x.id == job.id);
        work.employees = work.employees.filter(x => x.discussion_id == 0 || !x.discussion_id)
        work.mainEmployees = work.mainEmployees.filter(x => x.discussion_id == 0 || !x.discussion_id)
        work.addEmployee = work.addEmployee.filter(x => x.discussion_id == 0 || !x.discussion_id)
        work.addEmployee.forEach(x => {
          if (!x.employeeStatus && job.employeesStatuses.find(z => z.employee_id == x.employee_id)) {
            x.employeeStatus = job.employeesStatuses.find(z => z.employee_id == x.employee_id)
          }
        })
        const goToChat = this.dialog.open(AddJobToBoardComponent, {
          panelClass: 'add_to_board_modal',
          data: {
            company: this.company,
            user: this.user,
            imgRoute: this.imgRoute,
            host: this.host,
            activeLang: this.activeLang,
            employees: job.employees,
            task: resp,
            job: work,
            statuses: this.statuses,
            empl_status: this.company.permissions.includes('owner') || this.checkIsManager(resp, this.company),
            operationsValues: this.operationsValues,
          }
        });

        this.attachSubscriptions(
          goToChat.afterClosed().subscribe(result => {
            this.updateJob(job.id)
          })
        )
      })
    )
  }

  
  checkIsManager(job, company) { 
    return job && (company?.permissions.includes('manager') || company?.permissions.includes('admin') || (!!job.task && !!job.task.group_id && !!this.getGroup(job.task.group_id).groupManager)); 
  }

  // addToBoard(chat) {
  //   this.attachSubscriptions(
  //     this.taskService.getOneTaskExpand(this.company.id, chat.task_id).pipe(
  //       map(x => x.body[0]),
  //       switchMap(val => this.neededTaskData(val).pipe(map(() => casesModel([val], [], 'update')),map(val => val.arr[0])))
  //     ).subscribe(resp => {
  //       console.log("addToBoard", resp)
  //       let work = resp.operations.find(x => x.id == chat.task_operation_id);
  //       work.employees = work.employees.filter(x => x.discussion_id == chat.id || x.discussion_id == 0 || !x.discussion_id)
  //       work.mainEmployees = work.mainEmployees.filter(x => x.discussion_id == chat.id || x.discussion_id == 0 || !x.discussion_id)
  //       work.addEmployee = work.addEmployee.filter(x => x.discussion_id == chat.id || x.discussion_id == 0 || !x.discussion_id)
  //       work.addEmployee.forEach(x => {
  //         if (!x.employeeStatus && chat.employeesStatuses.find(z => z.employee_id == x.employee_id)) {
  //           x.employeeStatus = chat.employeesStatuses.find(z => z.employee_id == x.employee_id)
  //         }
  //       })
  //       const goToChat = this.dialog.open(AddChatToBoardComponent, {
  //         panelClass: 'add_to_board_modal',
  //         data: {
  //           company: this.company,
  //           user: this.user,
  //           imgRoute: this.imgRoute,
  //           host: this.host,
  //           activeLang: this.activeLang,
  //           chat: chat,
  //           employees: chat.employees,
  //           task: resp,
  //           job: work,
  //           statuses: this.statuses,
  //           empl_status: this.company.permissions.includes('owner') || this.checkIsManager(resp, this.company, this.user),
  //           operationsValues: this.operationsValues,
  //         }
  //       });

  //       this.attachSubscriptions(
  //         goToChat.afterClosed().subscribe(result => {
  //           this.refreshService.refreshChat$.next({company_id: this.company_id, chat_id: chat.id})
  //         })
  //       )
  //     })
  //   )

  // }
  

  // changeView(e: MatButtonToggleChange) {
  //   if (e.value == "executor") {
  //     this.filter.patchValue({
  //       employee_id: [this.me.id]
  //     })
  //     this.filterJobs()
  //   } else {
  //     let idsArr = [...this.filter.value.employee_id]
  //     idsArr.splice(idsArr.indexOf(this.me.id), 1)
  //     this.filter.patchValue({
  //       employee_id: idsArr
  //     })
  //     this.filterJobs()
  //   }
  // }

  // getPlatforms() {
  //   this.attachSubscriptions(
  //     this.companyService.getGroupPlatforms().subscribe(resp => {
  //       resp.unshift({
  //         id:	0,
  //         name: "Without platform",
  //         url: '',
  //         created_at:	0,
  //         updated_at:	0,
  //       })
  //       this.platforms = resp;
  //     })
  //   )
  // }

  // getPlatformById(id) {
  //   if (!this.platforms || this.platforms.filter(el => el.id == id).length == 0) {
  //     return false;
  //   }
  //   return this.platforms.find(el => el.id == id)
  // }

  toShortCut(e, item, type) {
    if (type == 'job') {
      this.attachSubscriptions(
        this.taskBarService.addBarItem({company_id: this.company.id, task_id: item.task_id, task_operation_id: item.id, discussion_id: 0}).subscribe(res => {
          this.minimizeService.minimizes$.next(res)
        }, error => {
          console.log("error toShortCut")
        })
      )
    } else {
      this.attachSubscriptions(
        this.taskBarService.addBarItem({company_id: this.company.id, task_id: item.id, task_operation_id: 0, discussion_id: 0}).subscribe(res => {
          this.minimizeService.minimizes$.next(res)
        }, error => {
          console.log("error toShortCut")
        })
      )
    }
  }

  toggleJobExp(val) {
    if (this.job_exp == val) {
      this.job_exp = 0
    } else {
      this.job_exp = val
    }
    this.sm.sessionStorageSetItem('job_exp', this.job_exp);
    this.job_exp = +this.sm.sessionStorageGetItem('job_exp');
    this.filterJobs();
  }

  neededData(job, partnerEmpl?) {
    let arr = [
      this.chatService.getWorkChats(job.id, this.company_id).pipe(
        tap(res => {
          job.chatsInfo = res;
          job.unReadChats = res.filter(z => z.is_read != '1').length;
        }),
        catchError(err => of(err))
      )
    ]

    if (this.job_exp === 0) {
      job.connectedGroups = []
    }
    
    if (this.job_exp === 1) {
      arr.push(
        this.taskService.getCardsExpand('1', this.company_id, {part_of_task_id: job.task_id}, '200').pipe(
          tap(res => {
            job.connectedGroups = [];
            res.forEach(x => {
              if (x && x.group && job.connectedGroups.filter(u => u.id == x.group.id).length == 0) {
                job.connectedGroups.push(x.group)
              }
            })
          })
        )
      )
    }

    if (this.job_exp === 2) {
      arr.push(
        this.taskService.getCardsExpand('1', this.company_id, {consist_of_task_id: job.task_id}, '200').pipe(
          tap(res => {
            job.connectedGroups = [];
            res.forEach(x => {
              if (x && x.group && job.connectedGroups.filter(u => u.id == x.group.id).length == 0) {
                job.connectedGroups.push(x.group)
              }
            })
          })
        )
      )
    }

    if (this.job_exp === 3) {
      arr.push(
        this.taskService.getCardsExpand('1', this.company_id, {related_task_id: job.task_id}, '200').pipe(
          tap(res => {
            job.connectedGroups = [];
            res.forEach(x => {
              if (x && x.group && job.connectedGroups.filter(u => u.id == x.group.id).length == 0) {
                job.connectedGroups.push(x.group)
              }
            })
          })
        )
      )
    }

    if (job.task && job.task.group_id && this.company) {
      arr.push(
        this.companyService.getGroupCompany(job.task.group_id).pipe(
          catchError(error => {
            return of(false)
          }),
          tap(group => {
            if (!!group) {
              job.task.group = group;
            }
          })
        )
      )
      arr.push(
        this.scenariosService.getAutomationTemplates('1', {company_id: this.company_id, group_id: job.task.group_id, is_task_operation: 1}, '1').pipe(
          catchError(error => {
            return of(error)
          }),
          switchMap(el => {
            if ((typeof el == 'string' && el.indexOf('Not accessible') != -1) || +el.headers.get('x-pagination-total-count') == 0) {
              return of(null);
            } else {           
              let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
              let arr = []
              for (let index = 1; index <= pages; index++) {
                arr.push(index)
              }
    
              return forkJoin(arr.map(x => this.scenariosService.getAutomationTemplates(x, {company_id: this.company_id, group_id: job.task.group_id, is_task_operation: 1}, '200').pipe(map(u => u.body)))).pipe(
                last(),
                tap(values => {
                  let allVals = [].concat(...values)
                  if (allVals && allVals.length) {
                    job.scenarioTab = true;
                    job.groupScenarios = allVals;
                  } else {
                    job.groupScenarios = [];
                  }
                }),
              )
            }
          }),
        )
      )
    }

    if (job.task_id) {
      arr.push(
        this.taskService.getTaskClientsDyn("1", job.task_id, this.company_id, '200', {task_operation_id: `0,${job.id}`}).pipe(
          map(x => x.body),
          tap(res => {
            job.clients = res
          })
        )
      )
    }

    if (job.company_id != this.company.id && partnerEmpl) {
      arr.push(
        this.membersService.getTaskPartnerEmployees({task_id: job.task_id, task_operation_id: job.id, discussion_id: 0}, this.company.id, job.company_id).pipe(
          tap(empls => {
            empls.map(b => {
              b.is_partner_employee = true;
              b.employeeStatus = b.partnerEmployeeStatus;
              b.employee = b.partnerEmployee;

              if (b.is_manager == 1) {
                job.mainEmployees.push(b)
              } else {
                job.addEmployee.push(b)
              }
              job.employees.push(b)


              if (!job.hasOwnProperty('emplSummary')) {
                job.emplSummary = JSON.parse(JSON.stringify(this.currencyData));
              }
              
              // job.employees.forEach(y => {
              // })
              job.emplSummary[!!b.price_currency_id ? b.price_currency_id : 0].summary = Number(job.emplSummary[!!b.price_currency_id ? b.price_currency_id : 0].summary) + Number(b.price);
              job.emplSummary[this.currencyData.length - 1].summary = Number(job.emplSummary[this.currencyData.length - 1].summary) + Number(b.rate);
              job.emplSummCount = job.emplSummary.filter(t => t.summary > 0).length
            })
          })
        )
      )
    }
    
    if (new PermissionsPipe().transform(this.company, 'see_files')) {
      if ((job.status_id == 3 && job.is_to_approve_files) || (job.status_id == 98 && job.is_approved_files)) {
        arr.push(this.fileService.getFilesForOpenTask(this.company_id, job.id, job.status_id == 3 ? '/to_approve' : '/ready_files').pipe(
          tap(val => {
            job.filesCount = val.headers.get('x-pagination-total-count')
            job.files = val.body.filter(x => !!x.thumbnail).slice(0,3);
          })
        ))
      }
    }

    if (this.company && job.task) {
      arr.push(
        this.membersService.getMembers({task_id: job.task_id, is_manager: 1, task_operation_id: 0}, this.company_id).pipe(
          tap(res => {
            job.taskManagers = res;

            if (this.company.permissions.includes("owner") || this.company.permissions.includes("admin") || this.company.permissions.includes("manager")) {
              job.isManager = true;
            } else {
              if (job.taskManagers.filter(x => x.employee_id == this.company.employees[0].id).length > 0) {
                job.isManager = true;
              }
            }

          }),
          catchError(err => {
            return err
          })
        )
      )
    }
    
    // if (this.company && job.task && job.task.group_id) {
    //   arr.push(
    //     this.taskService.getGroupManagers({group_id: job.task.group_id}).pipe(
    //       tap(res => {
    //         job.taskGroupManagers = res;

    //         if (this.company.permissions.includes("owner") || this.company.permissions.includes("admin") || this.company.permissions.includes("manager")) {
    //           job.isManager = true;
    //         } else {
    //           if (job.taskGroupManagers.filter(x => x.employee_id == this.company.employees[0].id).length > 0) {
    //             job.isManager = true;
    //           }
    //         }
    //       }),
    //       catchError(err => {
    //         return err
    //       })
    //     )
    //   )
    // }

    return forkJoin(arr)
  }

  getCurrencyList() {
    this.attachSubscriptions(
      this.globalDataService.getCurrencies().subscribe(resp => {
        this.currencyData = resp.slice()
        console.log("getCurrencyList", this.currencyData)
        this.currencyData.unshift({
          created_at: 0,
          display_format: "{value}",
          id: 8,
          iso_name: "",
          name: "",
          rate: "0.0000",
          short_name: "",
          updated_at: 0
        })
        this.currencyData.push({
          created_at: 0,
          display_format: "PTS {value}",
          id: 0,
          iso_name: "PTS",
          name: "Points",
          rate: "0.0000",
          short_name: "pts",
          updated_at: 0
        })
        this.currencyData.map(z => z.summary = 0);
      })
    )
  }

  getOperationsStatus() {
    this.attachSubscriptions(
      this.taskService.getOperationsStatus().subscribe(resp => {
        this.statuses = resp.slice();
      })
    )
  }

  getPriority(id) {
    if (!this.prioritys) {
      return false;
    }
    return this.prioritys.find(el => el.id == id)
  } 

  getTaskStatusById(id) {
    if (!this.taskStatuses) {
      return false;
    }
    return this.taskStatuses.find(el => el.id == id)
  } 

  getStatus(id) {
    if (!this.statuses) {
      return false;
    }
    return this.statuses.find(el => el.id == id)
  }  

  getGroup(id) {
    if (!this.groups || !this.groups.find(el => el.id == id)) {
      return false;
    }
    return this.groups.find(el => el.id == id)
  }  

  // getEmployee(id) {
  //   if (!this.employees) {
  //     return false;
  //   }
  //   return this.employees.find(el => el.id == id)
  // }  

  onSearchBookmarks(resp) {
    if (!this.bookmarks) {
      return;
    }

    if (!resp) {
      this.bookmarks$.next(this.bookmarks.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }

    // filter the banks
    this.bookmarks$.next(
      this.bookmarks.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  getBookmarks(no_change:boolean = false) {
    this.attachSubscriptions(
      this.companyService.getBookmarks(this.company_id, {employee_id: this.company.employees[0].id, section: "Jobs"}).subscribe(res => {
        this.bookmarks = res;
        this.bookmarks.unshift({
          id: -1,
          name: 'Default mode'
        });
        this.bookmarks$.next(this.bookmarks.slice())

        if (!no_change) {
          if (!!this.activatedRoute.snapshot.queryParamMap.get('preset_id') && !!this.getBookmarkById(+this.activatedRoute.snapshot.queryParamMap.get('preset_id')).settings && !!this.getBookmarkById(+this.activatedRoute.snapshot.queryParamMap.get('preset_id')).settings.viewArch && !!this.activatedRoute.snapshot.queryParamMap.get('viewArch')) {
            let url = this.router.url.replace('&viewArch=%5Bobject%20Object%5D', '');
            // console.log("viewArch lal", this.activatedRoute.snapshot.queryParamMap.get('viewArch'))
            // console.log("viewArch lal", this.router.url);
            history.replaceState(null, '', url);
            let x = JSON.parse(JSON.stringify(this.getBookmarkById(+this.activatedRoute.snapshot.queryParamMap.get('preset_id')).settings));
            if (x.viewArch) {
              this.viewArch = x.viewArch;
              this.sm.localStorageSetItem('jobsView', JSON.stringify(this.viewArch));
            }
            this.sm.localStorageSetItem('jobs_preset_id', this.activatedRoute.snapshot.queryParamMap.get('preset_id'));
          } else if (!!this.activatedRoute.snapshot.queryParamMap.get('preset_id')) {
            this.sm.localStorageSetItem('jobs_preset_id', this.activatedRoute.snapshot.queryParamMap.get('preset_id'));
            if (this.activatedRoute.snapshot.queryParamMap.get('preset_id') == '-1') {
              this.toDefault();
            } else {
              let x = JSON.parse(JSON.stringify(this.getBookmarkById(+this.activatedRoute.snapshot.queryParamMap.get('preset_id')).settings));
              if (x.viewArch) {
                this.viewArch = x.viewArch;
                this.sm.localStorageSetItem('jobsView', JSON.stringify(this.viewArch));
              } else {
                this.toDefault();
              }
            }
          } else if (this.sm.localStorageGetItem('jobs_preset_id')) {
            if (this.sm.localStorageGetItem('jobs_preset_id') == '-1') {
              this.toDefault();
            } else {
              let x = JSON.parse(JSON.stringify(this.getBookmarkById(+this.sm.localStorageGetItem('jobs_preset_id')).settings));
              if (x.viewArch) {
                this.viewArch = x.viewArch;
                this.sm.localStorageSetItem('jobsView', JSON.stringify(this.viewArch));
              } else {
                this.toDefault();
              }
            }
          } else {
            this.toDefault()
          }
        }
        console.log("bookmarks", this.bookmarks)
      })
    )
  }

  bookmarksChange(e) {
    console.log("bookmarksChange", e)
    this.isSettingsOpen = false;
    if (e.value == -1) {
      this.toDefault()
      return
    }
    console.log("bookmarksChange this.getBookmarkById(e.value)", this.getBookmarkById(e.value))
    let x = JSON.parse(JSON.stringify(this.getBookmarkById(e.value).settings));
    if (x.viewArch) {
      this.viewArch = x.viewArch
      this.sm.localStorageSetItem('jobsView', JSON.stringify(this.viewArch));
      delete x.viewArch
    }

    this.router.navigate(['/jobs'], { queryParams: Object.assign({company_id: this.company_id, preset_id: e.value}, x)});
  }

  // bookmarksChange(e) {
  //   this.router.navigate(['/jobs'], { queryParams: Object.assign({company_id: this.company_id, preset_id: e.value}, this.getBookmarkById(e.value).settings)});
  // }
  
  getBookmarkById(id) {
    if (!this.bookmarks || this.bookmarks.length == 0) {
      return null
    }
    return this.bookmarks.find(x => x.id == id)
  }

  onRouteChange() {
    this.attachSubscriptions(
      this.activatedRoute.queryParams.pipe(
        tap((val) => {
          // this.profilesPage = 1;
          
          if (this.jobsDataSub) {
            this.jobsDataSub.unsubscribe();
          }
          if (this.jobsSub) {
            this.jobsSub.unsubscribe()
          }
          // if (this.parametersSub) {
          //   this.parametersSub.unsubscribe();
          // }
          this.company_id = this.activatedRoute.snapshot.queryParamMap.get('company_id');
          this.sortValue = !!this.activatedRoute.snapshot.queryParamMap.get('order_by') ? this.activatedRoute.snapshot.queryParamMap.get('order_by') : "updated_desc";
          this.filter.patchValue({
            count: this.filterCount,
            q: !!this.activatedRoute.snapshot.queryParamMap.get('q') ? this.activatedRoute.snapshot.queryParamMap.get('q') : "",
            order_by: !!this.activatedRoute.snapshot.queryParamMap.get('order_by') ? this.activatedRoute.snapshot.queryParamMap.get('order_by') : "updated_desc",
            status_id: !!this.activatedRoute.snapshot.queryParamMap.get('status_id') ? this.activatedRoute.snapshot.queryParamMap.get('status_id').split(',').map(Number) : [],
            created_at_from: this.activatedRoute.snapshot.queryParamMap.get('created_at_from') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('created_at_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('created_at_from')*1000).utc() : ""),
            created_at_to: this.activatedRoute.snapshot.queryParamMap.get('created_at_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('created_at_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('created_at_to')*1000).utc() : ""),
            updated_at_from: this.activatedRoute.snapshot.queryParamMap.get('updated_at_from') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('updated_at_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('updated_at_from')*1000).utc() : ""),
            updated_at_to: this.activatedRoute.snapshot.queryParamMap.get('updated_at_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('updated_at_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('updated_at_to')*1000).utc() : ""),
            task_operation_planned_completed_at_from: this.activatedRoute.snapshot.queryParamMap.get('task_operation_planned_completed_at_from') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('task_operation_planned_completed_at_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('task_operation_planned_completed_at_from')*1000).utc() : ""),
            task_operation_planned_completed_at_to: this.activatedRoute.snapshot.queryParamMap.get('task_operation_planned_completed_at_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('task_operation_planned_completed_at_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('task_operation_planned_completed_at_to')*1000).utc() : ""),
            completed_at_from: this.activatedRoute.snapshot.queryParamMap.get('completed_at_from') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('completed_at_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('completed_at_from')*1000).utc() : ""),
            completed_at_to: this.activatedRoute.snapshot.queryParamMap.get('completed_at_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('completed_at_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('completed_at_to')*1000).utc() : ""),
            task_operation_operation_id: !!this.activatedRoute.snapshot.queryParamMap.get('task_operation_operation_id') ? this.activatedRoute.snapshot.queryParamMap.get('task_operation_operation_id').split(',').map(Number) : [],
            task_operation_parameter_value_id: !!this.activatedRoute.snapshot.queryParamMap.get('task_operation_parameter_value_id') ? this.activatedRoute.snapshot.queryParamMap.get('task_operation_parameter_value_id').split(',').map(Number) : [],
            task_parameter_value_id: !!this.activatedRoute.snapshot.queryParamMap.get('task_parameter_value_id') ? this.activatedRoute.snapshot.queryParamMap.get('task_parameter_value_id').split(',').map(Number) : [],
            discussion_parameter_value_id: !!this.activatedRoute.snapshot.queryParamMap.get('discussion_parameter_value_id') ? this.activatedRoute.snapshot.queryParamMap.get('discussion_parameter_value_id').split(',').map(Number) : [],
            file_parameter_value_id: !!this.activatedRoute.snapshot.queryParamMap.get('file_parameter_value_id') ? this.activatedRoute.snapshot.queryParamMap.get('file_parameter_value_id').split(',').map(Number) : [],
            channel_id: !!this.activatedRoute.snapshot.queryParamMap.get('channel_id') ? this.activatedRoute.snapshot.queryParamMap.get('channel_id').split(',').map(Number) : [],
            no_channel_id: !!this.activatedRoute.snapshot.queryParamMap.get('no_channel_id') ? this.activatedRoute.snapshot.queryParamMap.get('no_channel_id').split(',').map(Number) : [],
            parameter_value_id: !!this.activatedRoute.snapshot.queryParamMap.get('parameter_value_id') ? this.activatedRoute.snapshot.queryParamMap.get('parameter_value_id').split(',').map(Number) : [],
            task_status_id: !!this.activatedRoute.snapshot.queryParamMap.get('task_status_id') ? +this.activatedRoute.snapshot.queryParamMap.get('task_status_id') : '',
            channel_platform_id: !!this.activatedRoute.snapshot.queryParamMap.get('channel_platform_id') ? +this.activatedRoute.snapshot.queryParamMap.get('channel_platform_id') : '',
            is_content_url: !!this.activatedRoute.snapshot.queryParamMap.get('is_content_url') ? +this.activatedRoute.snapshot.queryParamMap.get('is_content_url') : '',
            task_custom_id: !!this.activatedRoute.snapshot.queryParamMap.get('task_custom_id') ? this.activatedRoute.snapshot.queryParamMap.get('task_custom_id') : '',
            content_type_id: !!this.activatedRoute.snapshot.queryParamMap.get('content_type_id') ? +this.activatedRoute.snapshot.queryParamMap.get('content_type_id') : '',
            preset_id: !!this.activatedRoute.snapshot.queryParamMap.get('preset_id') ? +this.activatedRoute.snapshot.queryParamMap.get('preset_id') : '',
            no_operation_id: !!this.activatedRoute.snapshot.queryParamMap.get('no_operation_id') ? +this.activatedRoute.snapshot.queryParamMap.get('no_operation_id') : '',
            content_status_id: !!this.activatedRoute.snapshot.queryParamMap.get('content_status_id') ? +this.activatedRoute.snapshot.queryParamMap.get('content_status_id') : '',
            client_employee_id: !!this.activatedRoute.snapshot.queryParamMap.get('client_employee_id') ? +this.activatedRoute.snapshot.queryParamMap.get('client_employee_id') : "",
            origin_company_id: !!this.activatedRoute.snapshot.queryParamMap.get('origin_company_id') ? +this.activatedRoute.snapshot.queryParamMap.get('origin_company_id') : "",
            task_operation_other_employee_status_id: !!this.activatedRoute.snapshot.queryParamMap.get('task_operation_other_employee_status_id') ? +this.activatedRoute.snapshot.queryParamMap.get('task_operation_other_employee_status_id') : "",
            group_id: !!this.activatedRoute.snapshot.queryParamMap.get('group_id') ? this.activatedRoute.snapshot.queryParamMap.get('group_id').split(',').map(Number) : [],
            priority: !!this.activatedRoute.snapshot.queryParamMap.get('priority') ? +this.activatedRoute.snapshot.queryParamMap.get('priority') : "",
            operation_employee_id: !!this.activatedRoute.snapshot.queryParamMap.get('operation_employee_id') ? +this.activatedRoute.snapshot.queryParamMap.get('operation_employee_id') : "",
            operation_partner_company_id: !!this.activatedRoute.snapshot.queryParamMap.get('operation_partner_company_id') ? +this.activatedRoute.snapshot.queryParamMap.get('operation_partner_company_id') : "",
            task_created_at_from: this.activatedRoute.snapshot.queryParamMap.get('task_created_at_from') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('task_created_at_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('task_created_at_from')*1000) : ""),
            task_created_at_to: this.activatedRoute.snapshot.queryParamMap.get('task_created_at_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('task_created_at_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('task_created_at_to')*1000) : ""),
            task_operation_created_at_from: this.activatedRoute.snapshot.queryParamMap.get('task_operation_created_at_from') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('task_operation_created_at_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('task_operation_created_at_from')*1000) : ""),
            task_operation_created_at_to: this.activatedRoute.snapshot.queryParamMap.get('task_operation_created_at_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('task_operation_created_at_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('task_operation_created_at_to')*1000) : ""),
            task_operation_completed_at_from: this.activatedRoute.snapshot.queryParamMap.get('task_operation_completed_at_from') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('task_operation_completed_at_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('task_operation_completed_at_from')*1000) : ""),
            task_operation_completed_at_to: this.activatedRoute.snapshot.queryParamMap.get('task_operation_completed_at_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('task_operation_completed_at_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('task_operation_completed_at_to')*1000) : ""),
            discussion_created_at_from: this.activatedRoute.snapshot.queryParamMap.get('discussion_created_at_from') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('discussion_created_at_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('discussion_created_at_from')*1000) : ""),
            discussion_created_at_to: this.activatedRoute.snapshot.queryParamMap.get('discussion_created_at_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('discussion_created_at_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('discussion_created_at_to')*1000) : ""),
            file_created_at_from: this.activatedRoute.snapshot.queryParamMap.get('file_created_at_from') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('file_created_at_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('file_created_at_from')*1000) : ""),
            file_created_at_to: this.activatedRoute.snapshot.queryParamMap.get('file_created_at_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('file_created_at_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('file_created_at_to')*1000) : ""),
            file_updated_at_from: this.activatedRoute.snapshot.queryParamMap.get('file_updated_at_from') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('file_updated_at_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('file_updated_at_from')*1000) : ""),
            file_updated_at_to: this.activatedRoute.snapshot.queryParamMap.get('file_updated_at_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('file_updated_at_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('file_updated_at_to')*1000) : ""),
            file_meta_creation_time_from: this.activatedRoute.snapshot.queryParamMap.get('file_meta_creation_time_from') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('file_meta_creation_time_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('file_meta_creation_time_from')*1000) : ""),
            file_meta_creation_time_to: this.activatedRoute.snapshot.queryParamMap.get('file_meta_creation_time_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('file_meta_creation_time_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('file_meta_creation_time_to')*1000) : ""),
            task_channel_published_at_from: this.activatedRoute.snapshot.queryParamMap.get('task_channel_published_at_from') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('task_channel_published_at_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('task_channel_published_at_from')*1000) : ""),
            task_channel_published_at_to: this.activatedRoute.snapshot.queryParamMap.get('task_channel_published_at_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('task_channel_published_at_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('task_channel_published_at_to')*1000) : ""),
          })
          // status_id: !!this.activatedRoute.snapshot.queryParamMap.get('status_id') ? this.activatedRoute.snapshot.queryParamMap.get('status_id').split(',').map(Number) : [],
          // group_id: !!this.activatedRoute.snapshot.queryParamMap.get('group_id') ? this.activatedRoute.snapshot.queryParamMap.get('group_id').split(',').map(Number) : [],
          // priority: !!this.activatedRoute.snapshot.queryParamMap.get('priority') ? this.activatedRoute.snapshot.queryParamMap.get('priority').split(',').map(Number) : [],
          // employee_id: !!this.activatedRoute.snapshot.queryParamMap.get('employee_id') ? this.activatedRoute.snapshot.queryParamMap.get('employee_id').split(',').map(Number) : [],
          // client_employee_id: !!this.activatedRoute.snapshot.queryParamMap.get('client_employee_id') ? this.activatedRoute.snapshot.queryParamMap.get('client_employee_id').split(',').map(Number) : [],
          // id: !!this.activatedRoute.snapshot.queryParamMap.get('id') ? this.activatedRoute.snapshot.queryParamMap.get('id').split(',').map(Number) : [],
          // operation_id: !!this.activatedRoute.snapshot.queryParamMap.get('operation_id') ? this.activatedRoute.snapshot.queryParamMap.get('operation_id').split(',').map(Number) : [],
          // created_at_from: !!this.activatedRoute.snapshot.queryParamMap.get('created_at_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('created_at_from')*1000).utc() : "",
          // created_at_to: this.activatedRoute.snapshot.queryParamMap.get('created_at_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('created_at_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('created_at_to')*1000).utc() : ""),
          // posted_at_from: !!this.activatedRoute.snapshot.queryParamMap.get('posted_at_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('posted_at_from')*1000).utc() : "",
          // posted_at_to: this.activatedRoute.snapshot.queryParamMap.get('posted_at_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('posted_at_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('posted_at_to')*1000).utc() : ""),
        }),
        switchMap((val) => {
          console.log("switchMap", this.company)
          if (this.company == undefined || this.company.id != this.activatedRoute.snapshot.queryParamMap.get('company_id')) {
            return this.companyService.getCompany(this.activatedRoute.snapshot.queryParamMap.get('company_id')).pipe(
              map(x => x[0]),
              tap(x => {
                this.company = x;
                this.me = this.company.employees ? this.company.employees[0] : undefined;
                this.companyService.company$.next(x);
              }),
              switchMap(x => this.uploadService.getUploadLimit().pipe(
                tap(z => {
                  if (z || this.company.filesize >= this.company.filesize_limit) {
                    this.uploadLimit = true;
                  }
                })
              )),
              map(x => val)
            )
          } else {
            return of(val)
          }
        }),
      ).subscribe(params => {
        console.log("route STREAM", params);
        this.jobCollection = new SelectionModel(
          true,
          []
        )
        this.jobs = [];
        // this.getPlatforms();
        // this.getPartners();
        // this.getProfiles(this.company_id, this.profilesPage);
        this.page = 1;

        // if (this.activatedRoute.snapshot.queryParamMap.get('add_task')) {
        //   if (this.activatedRoute.snapshot.queryParamMap.get('is_employee') == 'true') {
        //     this.emplCreateTask();
        //   } else {
        //     this.createTask();
        //   }
        //   this.filterReset();
        // }
        
        // this.getChatsCount()
        if (!!this.activatedRoute.snapshot.queryParamMap.get('groups_partner_id') && this.groups_partner_id != +this.activatedRoute.snapshot.queryParamMap.get('groups_partner_id')) {
          this.groups_partner_id = +this.activatedRoute.snapshot.queryParamMap.get('groups_partner_id');
          // this.getPartnerGroups()
        }
        this.createdDateRange = new DateRange(this.filter.value['created_at_from'], this.filter.value['created_at_to']);
        this.updatedDateRange = new DateRange(this.filter.value['updated_at_from'], this.filter.value['updated_at_to']);
        this.completedDateRange = new DateRange(this.filter.value['completed_at_from'], this.filter.value['completed_at_to']);
        this.deadlineDateRange = new DateRange(this.filter.value['task_operation_planned_completed_at_from'], this.filter.value['task_operation_planned_completed_at_to']);

        console.log("INIT this.createdDateRange", this.createdDateRange)
        console.log("INIT this.updatedDateRange", this.updatedDateRange)
        console.log("INIT this.completedDateRange", this.completedDateRange)
        console.log("INIT this.deadlineDateRange", this.deadlineDateRange)
        
        if (!!this.activatedRoute.snapshot.queryParamMap.get('status_id')) {
          this.activeStatus = this.activatedRoute.snapshot.queryParamMap.get('status_id').split(',').map(Number)
        } else {
          this.activeStatus = [0]
        }
      
        // this.parametersSub = this.parametersService.getParamsStream().pipe(
        //   concatMap(params => {
        //     return forkJoin([...params.map(oneParam => this.neededParametersData(oneParam))]).pipe(last(),map(x => params))
        //   }),
        // ).subscribe(resp => console.log("-----getParamsStream-----",resp))
      
        this.jobsDataSub = this.taskService.getJobDataStream().pipe(
          concatMap(jobsInPage => {
            return concat(...jobsInPage.map(jobInPage => this.neededData(jobInPage, true))).pipe(last(),map(x => jobsInPage))
          }),
        ).subscribe(resp => console.log("-----getJobsDataStream-----",resp));
        this.getBookmarks();
        this.getJobs(this.page);
      })
    )
  }

  // getPartnerGroups() {
  //   this.attachSubscriptions(
  //     this.companyService.getInfiniteGroupsPartnerCompany(this.company_id, this.groups_partner_id, '1', '1').pipe(
  //       switchMap(el => {
  //         let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
  //         let arr = []
  //         for (let index = 1; index <= pages; index++) {
  //           arr.push(index)
  //         }

  //         return forkJoin(arr.map(x => this.companyService.getInfiniteGroupsPartnerCompany(this.company_id, this.groups_partner_id, x, '200').pipe(map(u => u.body)))).pipe(
  //           last(),
  //         )
  //       }),
  //     ).subscribe(res => {
  //       this.partnerGroups = [].concat(...res);
  //       console.log("getPartnerGroups", this.partnerGroups);
  //     })
  //   )
  // }

  // getPartnerGroup(id) {
  //   if (!this.partnerGroups || !this.partnerGroups.find(el => el.id == id)) {
  //     return false;
  //   }
  //   return this.partnerGroups.find(el => el.id == id)
  // } 

  rangeChange(date: Moment, key, range): void {
    console.log(date);

    if (!this.filter.value[key+'from']) {
      // this.filter.value[key+'from'] = date;
      this.filter.patchValue({
        [key+'from']: date,
      })
    } else if (
      !this.filter.value[key+'to'] &&
      this._dateAdapter.compareDate(date.endOf('day'), this.filter.value[key+'from']) >= 0
    ) {
      this.filter.patchValue({
        [key+'to']: date.endOf('day'),
      })
    } else {
      this.filter.patchValue({
        [key+'from']: date,
        [key+'to']: null
      })
    }
    console.log("createdRangeChange", this.filter.value);

    this[range] = new DateRange(this.filter.value[key+'from'], this.filter.value[key+'to']);
  }

  getOperations() {
    this.attachSubscriptions(
      this.taskService.getOperations(this.company_id, this.activeLang).subscribe(resp => {
        this.operationsValues = resp;
      })
    )
  }

  getColumnName(id) {
    if (!this.operationsValues || this.operationsValues.filter(el => el.id == id).length == 0) {
      return '';
    }
    return this.operationsValues.find(el => el.id == id)?.translation?.name ? this.operationsValues.find(el => el.id == id).translation.name : this.operationsValues.find(el => el.id == id).name
  }

  getOperationStatusName(id) {
    switch (+id) {
      case 1:
        return "Waiting";
        break;
      case 2:
        return "In process";
        break;
      case 3:
        return "To approve";
        break;
      case 4:
        return "Approved";
        break;
      case 5:
        return "To correct";
        break;
      case 6:
        return "Sources Needed";
        break;
      case 97:
        return "On hold";
        break;
      case 98:
        return "Ready";
        break;
      case 99:
        return "Canceled";
        break;
    }
  }

  sort(val) {
    this.filterJobs(null, val)
  }

  selectChange(e) {
    this.filterJobs();
  }

  removeValue(val, id = null) {
    if (val == "all") {
      this.activeStatus = '';
      
      this.filter.patchValue({
        count: '',
        q: '',
        order_by: 'updated_desc',
        status_id: [],
        created_at_from: "",
        created_at_to: "",
        updated_at_from: "",
        updated_at_to: "",
        task_operation_planned_completed_at_from: "",
        task_operation_planned_completed_at_to: "",
        completed_at_from: "",
        completed_at_to: "",
        task_parameter_value_id: [],
        discussion_parameter_value_id: [],
        file_parameter_value_id: [],
        channel_id: [],
        no_channel_id: [],
        task_operation_parameter_value_id: [],
        parameter_value_id: [],
        task_operation_operation_id: [],
        client_employee_id: "",
        origin_company_id: "",
        task_operation_other_employee_status_id: 0,
        group_id: [],
        priority: "",
        task_status_id: '',
        channel_platform_id: '',
        is_content_url: '',
        task_custom_id: '',
        task_created_at_from: "",
        task_created_at_to: "",
        task_operation_created_at_from: "",
        task_operation_created_at_to: "",
        task_operation_completed_at_from: "",
        task_operation_completed_at_to: "",
        discussion_created_at_from: "",
        discussion_created_at_to: "",
        file_created_at_from: "",
        file_created_at_to: "",
        file_updated_at_from: "",
        file_updated_at_to: "",
        file_meta_creation_time_from: "",
        file_meta_creation_time_to: "",
        task_channel_published_at_from: "",
        task_channel_published_at_to: "",
        content_type_id: '',
        preset_id: '',
        no_operation_id: '',
        content_status_id: '',
        operation_employee_id: "",
        operation_partner_company_id: "",
      })

      this.filterReset()
    } else {
      if (id) {
        let w = [...this.filter.value[val]]
        w.splice(this.filter.value[val].indexOf(id),1)
        this.filter.patchValue({
          [val]: w
        })
      } else {
        this.filter.patchValue({
          [val]: ''
        })
      }

      this.filterJobs()
    }
    
  }

  filterReset() {
    this.filter.patchValue({
      id: '',
      completed_at_from: '',
      completed_at_to: ''
    })
    console.log("this.activeStatus", this.activeStatus);
    console.log("this.filter.value", this.filter.value.status_id);
    if (this.activeStatus.includes(0) || this.filter.value.status_id === '' || this.activeStatus === '') {
      this.router.navigate(['/jobs'], { queryParams: {company_id: this.company_id, order_by: "updated_desc"}});
    } else {
      this.filter.value.status_id = this.activeStatus;
      this.router.navigate(['/jobs'], { queryParams: {company_id: this.company_id, status_id: this.filter.value.status_id.join(","), order_by: "updated_desc"}});
    }
  
    // if (this.is_mobile) {
    //   this.layoutService.overflowAuto();
    //   this.mobile_filter_wrp.nativeElement.classList.remove("active");
    // }
  }

  refreshPage() {
    this.filterJobs();
  }

  canSave() {
    if (this.filter.value.preset_id && this.getBookmarkById(this.filter.value.preset_id) && ((this.getBookmarkById(this.filter.value.preset_id).settings && JSON.stringify(this.getBookmarkById(this.filter.value.preset_id).settings.viewArch) != JSON.stringify(this.viewArch)) || this.getBookmarkById(this.filter.value.preset_id).settings.order_by != this.filter.value.order_by)) {
      return true
    } else {
      return false
    }
  }

  saveBookmark() {
    this.attachSubscriptions(
      this.companyService.editBookmark(this.filter.value.preset_id, {settings: {order_by: this.filter.value.order_by, viewArch: this.viewArch}}).subscribe(resp => {
        console.log("saveBookmark", resp)
        this.getBookmarks(true);
      })
    )
  }

  changeActiveStatus(id, event = null) {
    if (!!event && (event.ctrlKey || event.metaKey)) {
      if (id == 0) {
        this.activeStatus = [0];
      } else if (this.activeStatus.includes(id)) {
        this.activeStatus.splice(this.activeStatus.indexOf(id), 1);
      } else if (this.activeStatus.includes(0) && id != 0) {
        this.activeStatus.splice(this.activeStatus.indexOf(0), 1)
        this.activeStatus.push(id);
      } else {
        this.activeStatus.push(id);
      }
      if (this.activeStatus.sort().toString() == [1,2,3,4,5,6,97,98,99].toString()) {
        this.activeStatus = [0];
      }
    } else {
      if (id == 0) {
        this.activeStatus = [0];
      } else {
        this.activeStatus = [id];
      }
    }
    this.sm.localStorageSetItem("activeStatus", this.activeStatus);

    if (this.useOut) {
      this.filter.patchValue({
        status_id: JSON.stringify(this.activeStatus).indexOf('0') != -1 ? [] : this.activeStatus
      })
    } else {
      this.filterJobs(this.activeStatus)
    }
  }
  
  // changeActiveStatus(id, event = null) {
  //   console.log("changeActiveStatus", id)
  //   if (id == 0) {
  //     this.activeStatus = '';
  //   } else {
  //     this.activeStatus = id;
  //   }
  //   this.sm.localStorageSetItem("activeStatus", this.activeStatus);

  //   // if (this.useOut) {
  //   //   this.filter.patchValue({
  //   //     status_id: JSON.stringify(this.activeStatus).indexOf('0') != -1 ? [] : this.activeStatus
  //   //   })
  //   // } else {
  //   // }
  //   this.filterJobs(this.activeStatus)
  // }

  filterJobs(op_status_id = null, order_by = null, is_btn = false, counting: boolean = true) {
    if (this.jobsSub) {
      this.jobsSub.unsubscribe()
    }
    if (counting) {
      this.filterCount = this.filterCount + 1;
      this.filter.value.count = this.filterCount;
    }
    
    let filterData = this.filter.value;

    Object.keys(filterData).forEach((key:string) => {      
      if (key.indexOf('at_from') != -1 && !!filterData[key]) {
        filterData[key] = moment(filterData[key]._d).startOf('day').format("X");
      }
      if (key.indexOf('at_to') != -1 && !!filterData[key]) {
        filterData[key] = moment(filterData[key]._d).endOf('day').format("X");
      }
    })

    if (!!op_status_id && op_status_id.toString() != '0') {
      filterData.status_id = op_status_id
    }

    if (op_status_id && op_status_id.toString() === '0') {
      delete filterData.status_id;
    }

    if (!!order_by || order_by == "0") {
      filterData.order_by = order_by
    }

    // this.sm.localStorageSetItem("activeStatus", filterData.status_id);
    
    let params = {
      company_id: this.company_id
    };

    // if (is_btn) {
    //   this.filter.removeControl('id');
    // }

    Object.keys(filterData).filter(x => x != 'preset_id').forEach(element => {
      if ((element.indexOf("parameter_value_id") != -1 || ['status_id', 'group_id', 'channel_id', 'no_channel_id', 'task_operation_operation_id'].includes(element)) && filterData[element].length) {
        params[element] = filterData[element].join(',')
      } else if (filterData[element] != '') {
        params[element] = filterData[element]
      }
    });

    this.router.navigate(['/jobs'], { queryParams: params});

    // if (this.is_mobile) {
    //   this.layoutService.overflowAuto();
    //   if (this.mobile_filter_wrp) {
    //     this.mobile_filter_wrp.nativeElement.classList.remove("active");
    //   }
    // }
  }


  ngAfterViewInit(): void {
    this.onScroll();
  }

  onScroll() {
    this.attachSubscriptions(
      fromEvent(this.jobsContainer.nativeElement, "scroll").pipe(
        filter((e:any) => e.target.scrollTop >=  e.target.scrollHeight - e.target.offsetHeight - 400),
        debounceTime(300)
      ).subscribe(() => {
        if (this.page > 1 && this.page <= this.pagination['pageCount']) {
          this.getJobs(this.page);
        }
      })
    )
  }

  getLangData(id) {
    this.attachSubscriptions(
      this.languageService.getLangData().pipe(
        tap(resp => {
          this.activeLang = resp.active;
          this._adapter.setLocale(resp.active);
          this.currMonthName = moment().format('MMMM');
        })
      ).subscribe(resp => {
        console.log("getOperations", resp)
      })
    )
  }


  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  getUser() {
    this.attachSubscriptions(
      this.auth.$user.subscribe(resp => {
        this.user = resp;
      })
    )
  }
  
  checkIsMobile() {
    if (window.innerWidth <= 769) {
      this.is_mobile = true;
    } else {
      this.is_mobile = false;
    }
    this.onResize();
  }

  onResize() {
    this.attachSubscriptions(
      fromEvent(window, "resize").pipe(
        map(() => window.innerWidth)
      ).subscribe((wWidth) => {
          if (wWidth <= 769) {
            this.is_mobile = true;
          } else {
            this.is_mobile = false;
          }
        }
      )
    )
  }

  deleteJob(job) {
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: {},
        target: marker("job")
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        this.attachSubscriptions(
          this.taskService.deleteWork(job.id).subscribe(resp => {
            this.jobs.splice(this.jobs.indexOf(job), 1);
          })
        )
      }
    });

  }

  copyLink(type) {
    let snackData = {};
    snackData['name'] = marker(type + " link");
    this.layoutService.showSnackBar(snackData, marker("Copied"), SnackBarItem)
  }
  
  copyData(type) {
    let snackData = {
      name: type
    };
    this.layoutService.showSnackBar(snackData, marker("Copied"), SnackBarItem)
  }
  
  // editChat(chat) {

  //   const editChat = this.dialog.open(EditOperationChatComponent, {
  //     disableClose: true,
  //     data: {
  //       company: this.company,
  //       chat: chat
  //     }
  //   });

  //   this.attachSubscriptions(
  //     editChat.afterClosed().subscribe(result => {
  //       if (!!result && result.event == 'edit') {
  //         this.refreshService.refreshChat$.next({company_id: this.company_id, chat_id: chat.id})
  //       }
  //     })
  //   )
  // }
  
  understandFileType(val) {
    if (!val) {
      return ""
    }
    
    if (val == 'application/pdf') {
      return 'pdf'
    } else if (val.indexOf("/") != -1) {
      return val.split('/')[0]
    } else {
      return ""
    }
  }

  onImgError(event){
    event.target.src = this.imgRoute+'/assets/img/image_black_48dp.svg'
  }

  getChatStatus(id) {
    switch (+id) {
      case 1:
        return "Waiting";
        break;
      case 2:
        return "In process";
        break;
      case 3:
        return "To approve";
        break;
      case 4:
        return "Approved";
        break;
      case 5:
        return "To correct";
        break;
      case 6:
        return "Sources needed";
        break;
      case 97:
        return "On hold";
        break;
      case 98:
        return "Ready";
        break;
      case 99:
        return "Canceled";
        break;
    }
  }

  uploadFileBtn(is_file, job) {
    if (!job.hasOwnProperty('files')) {
      job.files = [];
    }
    this.selectedJob = job
    if (is_file) {
      this.uploader.nativeElement.click()
    } else {
      this.uploaderFolder.nativeElement.click()
    }
  }


  uploadFile(event) {
    if (this.uploadLimit) {
      this.layoutService.showSnackBar({name: ''}, marker("This company has exceeded the file upload limit."), SnackBarItem)
      return
    }
    if (event.target.files.length > 0) {
      this.selectedJob.filesCount = (this.selectedJob.filesCount ? this.selectedJob.filesCount : 0) + event.target.files.length
      for (let index = 0; index < event.target.files.length; index++) {
        const element = event.target.files[index];
        this.putFile(element, false, this.selectedJob.status_id == 3 ? "/to_approve" : (this.selectedJob.status_id == 98 ? "/ready_files" : ""));
      }
    } 
  }

  uploadFolder(event) {
    if (this.uploadLimit) {
      this.layoutService.showSnackBar({name: ''}, marker("This company has exceeded the file upload limit."), SnackBarItem)
      return
    }
    if (event.target.files.length > 0) {
      this.selectedJob.filesCount = (this.selectedJob.filesCount ? this.selectedJob.filesCount : 0) + event.target.files.length
      for (let index = 0; index < event.target.files.length; index++) {
        const element = event.target.files[index];
        this.putFile(element, true, this.selectedJob.status_id == 3 ? "/to_approve" : (this.selectedJob.status_id == 98 ? "/ready_files" : ""));
      }
    }
    console.log(event.target.files);
  }

  putFile(file, is_folder:boolean = false, initPath:any = false) {
    let path = ''
    if (initPath) {
      path = initPath;
    }

    if (is_folder) {
      console.log("isFOLDER", path)
      path = (path[path.length - 1] == '/' ? path : path + "/") + file.webkitRelativePath.replace(`/${file.name}`, "")
    }

    this.fileService.uploadFile({
      company_id: this.company_id,
      task_id: this.selectedJob.task_id,
      task_operation_id: this.jobs.find(x => x.id == this.selectedJob.id).id,
      filesize: file.size,
      filename: file.name,
      content_type: file.type ? file.type : mimeTypes.getType(file.name),
      location: path,
      is_dir: 0
    }, this.company.id).subscribe(resp => {
      console.log("putFile", this.jobs.find(x => x.id == this.selectedJob.id))
      this.fileService.files$.next({
        place: "file_manager",
        url: window.location.href,
        data: resp,
        files: this.jobs.find(x => x.id == this.selectedJob.id).files,
        location: path,
        task: this.task,
        work: this.jobs.find(x => x.id == this.selectedJob.id),
        activeLang: this.activeLang,
        operationsValues: this.operationsValues,
        target: file,
        task_id: this.selectedJob.task_id,
        work_id: this.jobs.find(x => x.id == this.selectedJob.id).id,
        company_id: this.company_id,
        company: this.company,
        user: this.user
      })
      // this.selectedJob = undefined
    })
  }

  addEmployee(job) {
    const dialogRef = this.dialog.open(SetEmplComponent, {
      data: {
        company: this.company,
        user: this.user,
        task: this.task,
        work: job
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result && result.event == "update") {
          this.updateJob(job.id)
        }
      })
    )
  }

  getTaskStatus() {
    this.attachSubscriptions(
      this.taskService.getTaskStatuses(this.company_id).subscribe(resp => {
        this.taskStatuses = resp;
      })
    )
  }

  ifJobClientModerator(job) {
    if (!job.clients || job.clients.length == 0 || !this.company) {
      return false;
    }
    // console.log("ifJobClient", job.clients.filter(x => (x.client_employee_id == this.company.employees[0].id || x.client_partner_company_id == this.company_id) && x.is_principal).length)
    if (job.clients.filter(x => (x.client_employee_id == this.company.employees[0].id || x.client_partner_company_id == this.company_id) && !!x.is_principal).length > 0) {
      return true;
    } else {
      return false;
    }
  }

  ifJobClient(job) {
    if (!job.clients || job.clients.length == 0 || !this.company) {
      return false;
    }
    // console.log("ifJobClient", job.clients.filter(x => (x.client_employee_id == this.company.employees[0].id || x.client_partner_company_id == this.company_id) && x.is_principal).length)
    if (job.clients.filter(x => (x.client_employee_id == this.company.employees[0].id || x.client_partner_company_id == this.company_id)).length > 0) {
      return true;
    } else {
      return false;
    }
  }

  checkWorkActions(e, work) {
    if (!this.is_mobile) {
      e.preventDefault();
      e.stopPropagation();
    }
    this.attachSubscriptions(
      this.taskService.getActions(null, 1, work.company_id, null, work.id).subscribe(resp => {
        
        if (resp.body.length) {
          this.dialog.open(NotificationsBar, {
            backdropClass: this.is_mobile ? ['mob_interface_backdrop'] : 'backdrop_under_header',
            panelClass: this.is_mobile ? ['mob_interface_panel', 'animate__animated', 'animate__slideInUp'] : ['open_task_dialog', 'show_header'],
            data: {
              notifications: resp.body,
              taskStatuses: this.taskStatuses,
              company: this.company,
              user: this.user,
              work: work,
              header: false,
              initCompanyId: this.company_id,
              empl_status: this.company.permissions.includes('owner') || this.checkIsManager(work, this.company),
              pagination: {
                'pageCount': resp.headers.get('x-pagination-page-count'),
                'perPage': resp.headers.get('x-pagination-per-page'),
                'totalCount': resp.headers.get('x-pagination-total-count'),
                'currentPage': resp.headers.get('x-pagination-current-page'),
              }
            }
          });
        }
      })
    )
  }

  showStatusError(error, user?) {
    this.layoutService.showSnackBar({name: user}, `You do not have permission to do this. ${error}`, SnackBarItem)
  }

  applyStats(job, initialStatus:any = false) {
    console.log("job.empl_status", job)
    if (job.empl_status.filter(x => x.selected).length == 0) {
      return
    }

    if ([3,4,98].includes(job.status_id) && [1,2,5,6].includes(initialStatus ? initialStatus.id : job.newStatusFor.id)) {
      const dialogRef = this.dialog.open(StatusReasonComponent, {
        panelClass: 'open_task_dialog',
        data: {
          company: this.company,
          user: this.user,
          task: this.task,
          activeLang: this.activeLang,
          job: job,
          status: initialStatus ? initialStatus : job.newStatusFor,
          statuses: this.statuses,
          groups: this.groups,
          imgRoute: this.imgRoute,
          is_mobile: this.is_mobile,
          host: this.host,
          empl_status: this.company.permissions.includes('owner') || this.checkIsManager(job, this.company),
          operationsValues: this.operationsValues,
        }
      });
  
      this.attachSubscriptions(
        dialogRef.afterClosed().subscribe(result => {
          if (result.change) {
            forkJoin(job.empl_status.filter(x => x.selected).map(empl => {
              if (!!empl.is_partner) {
                if (!!empl.partnerCompanyStatus?.id) {
                  return this.membersService.editPartnerStatus(empl.partnerCompanyStatus.id, {status_id: initialStatus ? initialStatus.id : job.newStatusFor.id}, this.company_id).pipe(
                    catchError(error => {
                      this.showStatusError(error, empl?.partnerCompany?.name)
                      return of(false)
                    })
                  )
                } else {
                  return this.membersService.addPartnerStatus({
                    status_id: initialStatus ? initialStatus.id : job.newStatusFor.id,
                    company_id: this.company_id,
                    task_id: job.task_id,
                    task_operation_id: job.id,
                    partner_company_id: empl.partner_company_id,
                  }, this.company_id).pipe(
                    catchError(error => {
                      this.showStatusError(error, empl?.partnerCompany?.name)
                      return of(false)
                    })
                  )
                }
              } else if (!!empl.is_partner_employee) {
                if (!!empl.employeeStatus?.id) {
                  return this.membersService.editPartnerEmployeeStatus(empl.employeeStatus.id, {status_id: initialStatus ? initialStatus.id : job.newStatusFor.id}, this.company_id).pipe(
                    catchError(error => {
                      this.showStatusError(error, empl?.employee?.display_name)
                      return of(false)
                    })
                  )
                } else {
                  return this.membersService.addPartnerEmployeeStatus({
                    status_id: initialStatus ? initialStatus.id : job.newStatusFor.id,
                    company_id: this.company_id,
                    task_id: job.task_id,
                    task_operation_id: job.id,
                    partner_company_id: empl.partner_company_id,
                    partner_employee_id: empl.partner_employee_id
                  }, this.company_id).pipe(
                    catchError(error => {
                      this.showStatusError(error, empl?.employee?.display_name)
                      return of(false)
                    })
                  )
                }
              } else {
                if (!!empl.employeeStatus?.id) {
                  return this.membersService.editTaskEmployeeStatus(empl.employeeStatus.id, {status_id: initialStatus ? initialStatus.id : job.newStatusFor.id}, this.company_id).pipe(
                    catchError(error => {
                      this.showStatusError(error, empl?.employee?.display_name)
                      return of(false)
                    })
                  )
                } else {
                  return this.membersService.addTaskEmployeeStatus({
                    status_id: initialStatus ? initialStatus.id : job.newStatusFor.id,
                    company_id: this.company_id,
                    task_id: job.task_id,
                    task_operation_id: job.id,
                    employee_id: empl.employee_id,
                  }, this.company_id).pipe(
                    catchError(error => {
                      this.showStatusError(error, empl?.employee?.display_name)
                      return of(false)
                    })
                  )
                }
              }
            })).subscribe(resp => {
              this.updateJob(job.id);
            })
          } else {
            this.updateJob(job.id);
          }
        })
      )
    } else if ((initialStatus ? initialStatus.id : job.newStatusFor.id) == 3) {
      
      this.attachSubscriptions(
        this.taskService.getOneTaskExpand(this.company_id, job.task_id).pipe(
          map(x => x.body[0]),
          switchMap(x => this.neededData(x).pipe(map(() => casesModel([x], [], 'update')),map(x => x.arr[0]))),
        ).subscribe(resp => {  
          const dialogRef = this.dialog.open(ToApproveStepperComponent, {
            panelClass: 'to_appove_dialog',
            data: {
              company: this.company,
              user: this.user,
              task: resp,
              activeLang: this.activeLang,
              job: job,
              status: initialStatus ? initialStatus : job.newStatusFor,
              statuses: this.statuses,
              imgRoute: this.imgRoute,
              is_mobile: this.is_mobile,
              host: this.host,
              empl_status: this.company.permissions.includes('owner') || this.checkIsManager(job, this.company),
              operationsValues: this.operationsValues,
              getTask: () => {this.updateJob(job.id);}
            }
          })
        })
      )
    } else {
      forkJoin(job.empl_status.filter(x => x.selected).map(empl => {
        if (!!empl.is_partner) {
          if (!!empl.partnerCompanyStatus?.id) {
            return this.membersService.editPartnerStatus(empl.partnerCompanyStatus.id, {status_id: initialStatus ? initialStatus.id : job.newStatusFor.id}, this.company_id).pipe(
              catchError(error => {
                this.showStatusError(error, empl?.partnerCompany?.name)
                return of(false)
              })
            )
          } else {
            return this.membersService.addPartnerStatus({
              status_id: initialStatus ? initialStatus.id : job.newStatusFor.id,
              company_id: this.company_id,
              task_id: job.task_id,
              task_operation_id: job.id,
              partner_company_id: empl.partner_company_id,
            }, this.company_id).pipe(
              catchError(error => {
                this.showStatusError(error, empl?.partnerCompany?.name)
                return of(false)
              })
            )
          }
        } else if (!!empl.is_partner_employee) {
          if (!!empl.employeeStatus?.id) {
            return this.membersService.editPartnerEmployeeStatus(empl.employeeStatus.id, {status_id: initialStatus ? initialStatus.id : job.newStatusFor.id}, this.company_id).pipe(
              catchError(error => {
                this.showStatusError(error, empl?.employee?.display_name)
                return of(false)
              })
            )
          } else {
            return this.membersService.addPartnerEmployeeStatus({
              status_id: initialStatus ? initialStatus.id : job.newStatusFor.id,
              company_id: this.company_id,
              task_id: job.task_id,
              task_operation_id: job.id,
              partner_company_id: empl.partner_company_id,
              partner_employee_id: empl.partner_employee_id
            }, this.company_id).pipe(
              catchError(error => {
                this.showStatusError(error, empl?.employee?.display_name)
                return of(false)
              })
            )
          }
        } else {
          if (!!empl.employeeStatus?.id) {
            return this.membersService.editTaskEmployeeStatus(empl.employeeStatus.id, {status_id: initialStatus ? initialStatus.id : job.newStatusFor.id}, this.company_id).pipe(
              catchError(error => {
                this.showStatusError(error, empl?.employee?.display_name)
                return of(false)
              })
            )
          } else {
            return this.membersService.addTaskEmployeeStatus({
              status_id: initialStatus ? initialStatus.id : job.newStatusFor.id,
              company_id: this.company_id,
              task_id: job.task_id,
              task_operation_id: job.id,
              employee_id: empl.employee_id,
            }, this.company_id).pipe(
              catchError(error => {
                this.showStatusError(error, empl?.employee?.display_name)
                return of(false)
              })
            )
          }
        }
      })).subscribe(resp => {
        this.updateJob(job.id);
      })
    }
  }

  selectEmpl(job, empl, initial = false, initialStatus:any = false) {
    if (!!empl.initial_selected) {
      return
    }
    if (!!empl.selected) {
      empl.selected = false;
    } else {
      empl.selected = true;
    } 
  }

  chooseEmplStatus(job, status) {
    console.log("chooseEmplStatus", status)
    let empl = job.employees.find(x => ((x.employee_id == this.company.employees[0].id) || (x.partner_employee_id == this.company.employees[0].id)) && !x.discussion_id)
    if ((status.id) == 3) {
      empl.selected = true;
      job.empl_status = [empl]
      const dialogRef = this.dialog.open(ToApproveStepperComponent, {
        panelClass: 'to_appove_dialog',
        data: {
          company: this.company,
          user: this.user,
          task: this.task,
          activeLang: this.activeLang,
          job: job,
          status: status,
          statuses: this.statuses,
          imgRoute: this.imgRoute,
          is_mobile: this.is_mobile,
          host: this.host,
          empl_status: this.company.permissions.includes('owner') || this.checkIsManager(job, this.company),
          operationsValues: this.operationsValues,
          getTask: () => {this.updateJob(job.id)}
        }
      });
      return
    }
    
    if (!!empl?.is_partner) {
      if (!!empl.partnerCompanyStatus?.id) {
        this.attachSubscriptions(
          this.membersService.editPartnerStatus(empl.partnerCompanyStatus.id, {status_id: status.id}, this.company_id).subscribe(resp => {
             this.updateJob(job.id);
          },
          error => {
            this.showError()
          })
        )
      } else {
        let val:any = {
          status_id: status.id,
          company_id: this.company_id,
          task_id: job.task_id,
          task_operation_id: job.id,
          partner_company_id: empl.partner_company_id,
        }
        if (empl.discussion_id) {
          val.discussion_id = empl.discussion_id
        }
        this.attachSubscriptions(
          this.membersService.addPartnerStatus(val, this.company_id).subscribe(resp => {
             this.updateJob(job.id);
          },
          error => {
            this.showError()
          })
        )
      }
    } else if (!!empl.is_partner_employee) {
      if (!!empl.employeeStatus?.id) {
        this.attachSubscriptions(
          this.membersService.editPartnerEmployeeStatus(empl.employeeStatus.id, {status_id: status.id}, this.company_id).subscribe(resp => {
             this.updateJob(job.id);
          },
          error => {
            this.showError()
          })
        )
      
      } else {
        let val:any = {
          status_id: status.id,
          company_id: this.company_id,
          task_id: job.task_id,
          partner_company_id: empl.partner_company_id,
          partner_employee_id: empl.employee_id,
        }
        if (empl.discussion_id) {
          val.discussion_id = empl.discussion_id
        }
        this.attachSubscriptions(
          this.membersService.addPartnerEmployeeStatus(val, this.company_id).subscribe(resp => {
             this.updateJob(job.id);
          },
          error => {
            this.showError()
          })
        )
      }
    } else {
      if (!!empl.employeeStatus?.id) {
        this.attachSubscriptions(
          this.membersService.editTaskEmployeeStatus(empl.employeeStatus.id, {status_id: status.id}, this.company_id).subscribe(resp => {
             this.updateJob(job.id);
          },
          error => {
            this.showError()
          })
        )
      } else {
        let val:any = {
          status_id: status.id,
          company_id: this.company_id,
          task_id: job.task_id,
          task_operation_id: job.id,
          employee_id: empl.employee_id,
        }
        if (empl.discussion_id) {
          val.discussion_id = empl.discussion_id
        }
        this.attachSubscriptions(
          this.membersService.addTaskEmployeeStatus(val, this.company_id).subscribe(resp => {
             this.updateJob(job.id);
          },
          error => {
            this.showError()
          })
        )
      }
    }

  }
  
  showError() {
    this.layoutService.showSnackBar({name: ''}, marker("You do not have permission to do this"), SnackBarItem)
  }
  applyJobStats(job, initialStatus:any = false) {
    this.attachSubscriptions(
      this.taskService.editWork(job.id, {status_id: initialStatus ? initialStatus.id : job.newStatusFor.id}, this.company_id).subscribe(resp => {
        this.updateJob(job.id);
      })
    )
  }

  chooseStatus(job, status) {
    console.log("chooseEmplStatus", status)
    // job.empl_status = job.employees.filter(x => !x.discussion_id);
    job.empl_status = job.employees.filter(x => !x.discussion_id && (this.company_id != job.company_id && !(this.company.permissions.includes('owner') || this.checkIsManager(job, this.company)) ? !x.is_partner && x.employee.company_id == this.company_id : true));
    job.empl_status.map(x => {
      if (x?.employeeStatus?.status_id == status.id) {
        x.initial_selected = true;
      }
      if (x?.partnerCompanyStatus?.status_id == status.id) {
        x.initial_selected = true;
      }
    })
    if (this.checkIsManager(job, this.company) && [4,98,99].includes(status.id) && status.id > job.status_id) {
      this.attachSubscriptions(
        this.taskService.editWork(job.id, {status_id: status.id}, this.company_id).subscribe(resp => {
          this.updateJob(job.id);
        })
      )
    } else {
      if (job.empl_status.length == 1) {
        job.empl_status[0].selected = true;
        if (job.mainEmployees.length == 0) {
          this.attachSubscriptions(
            this.taskService.editWork(job.id, {status_id: status.id}, this.company_id).subscribe(resp => {
              this.applyStats(job, status)
            })
          )
        } else {
          this.applyStats(job, status)
        }
        // this.selectEmpl(job, job.empl_status[0], true, status)
      } else if (job.empl_status.length == 0) {
        this.attachSubscriptions(
          this.taskService.editWork(job.id, {status_id: status.id}, this.company_id).subscribe(resp => {
            this.updateJob(job.id);
          })
        )
      } else {
        job.newStatusFor = status;
      }

    }
  }

  openEmplStatus(job) {
    console.log("openEmplStatus", job)
    // if (job.employees.filter(x => (x.employee_id == this.company.employees[0].id || x.partner_company_id == this.company.id) && !x.discussion_id).length == 0) {
    //   return
    // }
    if (job.employees.filter(x => ((x.employee_id == this.company.employees[0].id) || (x.partner_employee_id == this.company.employees[0].id) || (x.partner_company_id == this.company.id && !x.partner_employee_id)) && !x.discussion_id).length == 0) {
      return
    }
    if (!job.open) {
      this.jobs.map(x => {
        x.open = false
        x.statuses = [];
      })
      job.open = true;
      let jobStatuses = [];
      if (!!job.employeeStatus || !!job.partnerCompanyStatus) {
        this.statuses.forEach(el => {
          if ((job.employeeStatus.status_id != el.id || job.partnerCompanyStatus.status_id != el.id) && el.is_lock == 0) {
            jobStatuses.push(el)
          }
        })
      } else {
        this.statuses.forEach(el => {
          if (job.status_id != el.id && el.is_lock == 0) {
            jobStatuses.push(el)
          }
        })
      }

      job.statuses = jobStatuses;
    } else {
      job.open = false;
      job.statuses = [];
      this.updateJob(job.id);
    }
    console.log(job);
  }

  // getPartners() {
  //   this.attachSubscriptions(
  //     this.companyService.getPartners({company_id: this.company_id}).subscribe(resp => {
  //       this.partners = resp.filter(x => x.partner_company_id != 0);
  //     })
  //   )
  // }

  // getPartner(id) {
  //   if (!this.partners) {
  //     return false;
  //   }
  //   return this.partners.find(el => el.partner_company_id == id)
  // }  

  multiEditExecutors() {
    const dialogRef = this.dialog.open(MultiEditExecutorsComponent, {
      disableClose: true,
      data: {
        user: this.user,
        company: this.company,
        selectedChats: null,
        selectedJobs: this.jobCollection.selected,
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result && result.event == "change") {
          this.jobCollection.selected.forEach((job, ind) => {
            this.refreshService.refreshJob$.next({company_id: this.company_id, job_id: job.id})
            if (ind == this.jobCollection.selected.length - 1) {
              this.jobCollection.clear()
            }
          })
        }
      })
    )
  }

  multiDeleteClients() {
    const dialogRef = this.dialog.open(MultiDeleteClientsComponent, {
      disableClose: true,
      data: {
        user: this.user,
        company: this.company,
        selectedChats: null,
        selectedJobs: this.jobCollection.selected,
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result && result.event == "change") {
          this.jobCollection.selected.forEach((job, ind) => {
            this.refreshService.refreshJob$.next({company_id: this.company_id, job_id: job.id})
            if (ind == this.jobCollection.selected.length - 1) {
              this.jobCollection.clear()
            }
          })
        }
      })
    )
  }

  multiDeleteTags() {
    const dialogRef = this.dialog.open(MultiDeleteTagsComponent, {
      disableClose: true,
      data: {
        user: this.user,
        company: this.company,
        selectedChats: null,
        selectedJobs: this.jobCollection.selected,
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result && result.event == "change") {
          this.jobCollection.selected.forEach((job, ind) => {
            this.refreshService.refreshJob$.next({company_id: this.company_id, job_id: job.id})
            if (ind == this.jobCollection.selected.length - 1) {
              this.jobCollection.clear()
            }
          })
        }
      })
    )
  }

  openStatus(job) {
    console.log("openStatus", job)
    if (!job.open) {
      this.jobs.map(x => {
        x.open = false
        x.statuses = [];
        x.newStatusFor = null
      })
      if (this.isMainEmpl(job) && !(!!job.isManager || this.ifJobClientModerator(job))) {
        if (this.statuses.find(x => x.id == job.status_id).is_lock == 1) {
          return;
        }

        job.open = true;
        let jobStatuses = [];
        // jobStatuses.push(job.status);
        this.statuses.forEach(el => {
          if (el.id != job.status_id && el.is_lock == 0) {
            jobStatuses.push(el)
          }
        })
        job.statuses = jobStatuses;
      } else {
        job.open = true;
        let jobStatuses = [];
        // jobStatuses.push(job.status);
        this.statuses.forEach(el => {
          if (el.id != job.status_id) {
            jobStatuses.push(el)
          }
        })
        job.statuses = jobStatuses;
      }
    } else {
      job.open = false;
      job.statuses = [];
      job.newStatusFor = null;
      this.updateJob(job.id);
    }
    console.log(job);
  }

  openExecutors(job) {
    this.openJob(job.task_id, job, {selectedIndex: 1});
  }

  openTask(task) {
    const dialogRef = this.dialog.open(OpenTaskComponent, {
      backdropClass: 'backdrop_under_header',
      panelClass: !this.is_mobile ? ['open_task_dialog', 'show_header'] : 'open_task_dialog',
      autoFocus: false,
      data: {
        task_id: task.id,
        task: task,
        company: this.company,
        initCompanyId: this.company_id,
      }
    });
  }

  moveWork(work) {
    const dialogRef = this.dialog.open(MoveJobsComponent, {
      data: {
        company: this.company,
        work: work,
        user: this.user,
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result && result.event == 'update') {
          this.updateJob(work.id);
          if (result.task) {
            this.initRefreshBoard()
          }
        }
      })
    )
  }

  openAutomation(work) {
    const dialogRef = this.dialog.open(JobAutomationComponent, {
      data: {
        company: this.company,
        work: work,
        user: this.user,
        host: this.host,
        imgRoute: this.imgRoute,
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result && result.event == 'update') {
          this.updateJob(work.id);
          if (result.task) {
            this.initRefreshBoard()
          }
        }
      })
    )
  }

  initRefreshBoard() {
    this.refreshService.refreshBoard$.next('refresh')
  }

  editWork(work) {
    if (!this.is_mobile) {
      const dialogRef = this.dialog.open(WorkEditComponent, {
        disableClose: true,
        data: {
          user: this.user,
          company: this.company,
          task: work.task,
          work: work,
          operationsValues: this.operationsValues
        }
      });
  
      this.attachSubscriptions(
  
        dialogRef.afterClosed().subscribe(result => {
          
          if (!!result && result.event == 'update') {
            this.updateJob(work.id);
            this.initRefreshBoard()
          }
        })
      )
    } else {
      const dialogRef = this.dialog.open(JobEditComponent, {
        backdropClass: ['mob_interface_backdrop'],
        panelClass: ['mob_interface_panel', 'animate__animated', 'animate__slideInUp'],
        disableClose: true,
        data: {
          user: this.user,
          company: this.company,
          task: work.task,
          work: work,
          operationsValues: this.operationsValues,
          activeLang: this.activeLang
        }
      });
  
      this.attachSubscriptions(
  
        dialogRef.afterClosed().subscribe(result => {
          
          if (!!result) {

            switch (result.event) {
              case 'update':
                this.updateJob(work.id);
                this.initRefreshBoard()
                break;
              case 'status':
                this.changeMobJobStatus(work);
                break;
              case 'private_comment':
                this.mobJobEditTitle(work, 'private_comment', "Hidden notes");
                break;
              case 'comment':
                this.mobJobEditTitle(work, 'comment', "Brief Description");
                break;
              case 'name':
                this.mobJobEditTitle(work, 'name', "Title");
                break;
              case 'type':
                this.mobJobEditType(work);
                break;
              case 'completed_at':
                this.mobJobEditCompletedAt(work);
                break;
              case 'priority':
                this.mobEditJobPriority(work);
                break;
              default:
                break;
            }
          }
        })
      )
    }
  }

  migrateTargetFiles(task, job?) {
    const dialogRef = this.dialog.open(MigrateTargetFilesComponent, {
      panelClass: ['chats_dialog'],
      data: {
        company: this.company,
        target: {
          task_id: task.id,
          task_operation_id: job ? job.id : 0
        },
        user: this.user,
        host: this.host,
      }
    });
    
    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        this.updateJob(job.id);
      })
    )
  }
  
  mobEditJobPriority(work) {
    const dialogRef = this.dialog.open(MobPriorityComponent, {
      backdropClass: ['mob_interface_backdrop'],
      panelClass: ['mob_interface_panel', 'animate__animated', 'animate__slideInUp'],
      data: {
        company: this.company,
        work: work,
        user: this.user,
        target: 'work'
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          if (result.event == 'back') {
            this.editWork(work)
          } else if (result.event == 'update') {
            this.updateJob(work.id);
            this.initRefreshBoard()
          }
        } else {
          this.updateJob(work.id);
          this.initRefreshBoard()
        }
      })
    )
  }

  mobJobEditCompletedAt(work) {
    const dialogRef = this.dialog.open(JobEditCompletedAtComponent, {
      backdropClass: ['mob_interface_backdrop'],
      panelClass: ['mob_interface_panel', 'animate__animated', 'animate__slideInUp'],
      data: {
        company: this.company,
        task: this.task,
        user: this.user,
        work: work,
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          if (result.event == 'back') {
            this.editWork(work)
          } else if (result.event == 'update') {
            this.updateJob(work.id);
            this.initRefreshBoard()
          }
        } else {
          this.updateJob(work.id);
          this.initRefreshBoard()
        }
      })
    )
  }

  mobJobEditType(work) {
    const dialogRef = this.dialog.open(JobEditTypeComponent, {
      backdropClass: ['mob_interface_backdrop'],
      panelClass: ['mob_interface_panel', 'animate__animated', 'animate__slideInUp'],
      data: {
        company: this.company,
        task: this.task,
        user: this.user,
        work: work,
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          if (result.event == 'back') {
            this.editWork(work)
          } else if (result.event == 'update') {
            this.updateJob(work.id);
            this.initRefreshBoard()
          }
        } else {
          this.updateJob(work.id);
          this.initRefreshBoard()
        }
        
      })
    )
  }
  
  mobJobEditTitle(work, type_prop, type) {
    const dialogRef = this.dialog.open(MobTitleComponent, {
      backdropClass: ['mob_interface_backdrop'],
      panelClass: ['mob_interface_panel', 'animate__animated', 'animate__slideInUp'],
      data: {
        company: this.company,
        task: this.task,
        user: this.user,
        work: work,
        type: type,
        type_prop: type_prop,
        title: 'Edit job',
        target: 'work'
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          if (result.event == 'back') {
            this.editWork(work)
          } else {
            this.updateJob(work.id);
            this.initRefreshBoard()
          }
        } else {
          this.updateJob(work.id);
          this.initRefreshBoard()
        }
      })
    )
  }
  
  changeMobJobStatus(job) {

    this.attachSubscriptions(
      this.taskService.getOneTaskExpand(this.company_id, job.task_id).pipe(
        map(x => x.body[0]),
        switchMap(x => this.neededData(x).pipe(
          map(() => casesModel([x], [], 'update')),
          map(x => x.arr[0])
        ))
      ).subscribe(resp => {
        console.log(resp)
        let _job = resp.operations.find(x => x.id == job.id);
        _job.mobEmployees = [];
        let allEmployees = _job.employees.filter(x => x.task_operation_id == _job.id);
        allEmployees.forEach(empl => {
          if (!_job.mobEmployees.find(x => x.employee_id == empl.employee_id)) {
            _job.mobEmployees.push(empl)
          }
        });
        const dialogRef = this.dialog.open(MobChangePersonalStatusComponent, {
          backdropClass: ['mob_interface_backdrop'],
          panelClass: ['mob_interface_panel', 'animate__animated', 'animate__slideInUp'],
          data: {
            company: this.company,
            task: resp,
            work: _job,
            host: this.host,
            imgRoute: this.imgRoute,
            user: this.user,
            statuses: this.statuses,
          }
        });
    
        this.attachSubscriptions(
          dialogRef.afterClosed().subscribe(result => {
            if (result) {
              if (result.event == 'back') {
                this.editWork(job)
              } else if (result.event == 'update') {
                this.updateJob(job.id);
                this.initRefreshBoard()
              }
            }
          })
        )
      })
    )

  }
  
  openOldChats(work) {
    const dialogRef = this.dialog.open(OperationChatsComponent, {
      panelClass: ['chats_dialog'],
      data: {
        company: this.company,
        work: work,
        user: this.user,
        empl_status: this.company.permissions.includes('owner') || this.checkIsManager(work, this.company),
        host: this.host,
        operationsValues: this.operationsValues,
        checkIsManager: this.checkIsManager
      }
    });
    
    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        this.updateJob(work.id);
      })
    )
  }

  previewVideo(file, job) {
    if (!this.is_mobile) {
      const dialogRef = this.dialog.open(VideoViewerComponent, {
        panelClass: 'video_viewer',
        autoFocus: false,
        data: {
          file: file,
          work: job,
          task: job.task,
          files: job.files,
          operationsValues: this.operationsValues,
          company: this.company,
          activeLang: this.activeLang,
          user: this.user,
          isTaskCard: true
        }
      });
    } else {
      const dialogRef = this.dialog.open(MobFmViewComponent, {
        backdropClass: ['mob_video_viewer_backdrop'],
        panelClass: 'mob_video_viewer',
        autoFocus: false,
        data: {
          file: file,
          work: job,
          task: job.task,
          files: job.files,
          operationsValues: this.operationsValues,
          company: this.company,
          activeLang: this.activeLang,
          user: this.user,
          isTaskCard: true
        }
      });
    }
  }
  
  createChat(work) {
    const dialogRef = this.dialog.open(CreateOperationChatComponent, {
      disableClose: true,
      data: {
        is_create_chat_component: true,
        company: this.company,
        work: work,
        user: this.user,
        empl_status: this.company.permissions.includes('owner') || this.checkIsManager(work, this.company),
        operationsValues: this.operationsValues
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        this.updateJob(work.id)
      })
    )
  }

  deleteWork(work) {
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: {},
        target: marker("job")
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        this.attachSubscriptions(
          this.taskService.deleteWork(work.id).subscribe(resp => {
            this.pagination['totalCount'] = this.pagination['totalCount'] - 1;
            this.jobs.splice(this.jobs.indexOf(work), 1)
            this.refreshService.refreshTask$.next({company_id: this.company_id, task_id: work.task_id})
            this.initRefreshBoard();
          })
        )
      }
    });

  }

  deleteEmployee(job) {
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: {},
        target: marker("job employee")
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        this.attachSubscriptions(
          this.membersService.deleteMember(job.employee.id, this.company_id).subscribe(resp => {
            this.updateJob(job.id)
          })
        )
      }
    });
  }

  onPartnerImgError(event){
    event.target.src = this.imgRoute+'/assets/img/partner.png'
  }

  // multi logic

  toggleMobCollection(e): void { 
    this.collectionIsActive = !this.collectionIsActive;
    if (!this.collectionIsActive) {
      this.jobCollection.clear();
    }
  }

  toggleJob(job): void {
    this.jobCollection.toggle(job);
    console.log(this.jobCollection.selected);
  }

  checkboxLabel(row?): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.jobCollection.isSelected(row) ? 'deselect' : 'select'} job ${row.name}`;
  }

  isAllSelected() {
    const numSelected = this.jobCollection.selected.length;
    const numRows = this.jobs.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
        this.jobCollection.clear() :
        this.jobs.forEach(row => this.jobCollection.select(row));
        
    console.log(this.jobCollection.selected);
  }

  multiEditJobsType() {
    const dialogRef = this.dialog.open(MultiEditJobsTypeComponent, {
      data: {
        company: this.company,
        user: this.user,
        selectedJobs: this.jobCollection.selected
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result && result.event == "update") {
          this.jobCollection.selected.forEach(x => {
            this.updateJob(x.id)
          })
          this.jobCollection.clear()
        }
      })
    )
  }

  multiJobsExecScenario() {
    const dialogRef = this.dialog.open(MultiJobsExecScenariosComponent, {
      data: {
        company: this.company,
        user: this.user,
        selectedJobs: this.jobCollection.selected
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result && result.event == "update") {
          this.jobCollection.selected.forEach(x => {
            this.updateJob(x.id)
          })
          this.jobCollection.clear()
        }
      })
    )
  }

  multiJobsAssignScenario() {
    const dialogRef = this.dialog.open(MultiJobsAssignScenarioComponent, {
      data: {
        company: this.company,
        user: this.user,
        is_manage: true,
        selectedJobs: this.jobCollection.selected
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result && result.event == "update") {
          this.jobCollection.selected.forEach(x => {
            this.updateJob(x.id)
          })
          this.jobCollection.clear()
        }
      })
    )
  }

  multiDeleteJobs() {
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: {},
        target: this.jobCollection.selected.length + ' jobs'
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        console.log("jobCollection", this.jobCollection.selected)

        let multiData = [];

        this.jobCollection.selected.forEach(item => {
          multiData.push({
            "path": `/api/task-operation/${item.id}/`,
            "query": {company_id: this.company.id},
            "method": "DELETE",
            "body": {}
          })
        });
    
        if (multiData.length) {
          this.attachSubscriptions(
            this.taskService.multiRequest(multiData).subscribe(resp => {
              // this.getTask(true)
              // this.jobCollection.clear()
              this.jobCollection.selected.forEach((job, ind) => {
                if (this.jobs.find(x => x.id == job.id)) {
                  this.jobs.splice(this.jobs.findIndex(x => x.id == job.id), 1)
                }
                if (ind == this.jobCollection.selected.length - 1) {
                  this.jobCollection.clear()
                }
              })
            })
          )
        }
      }
    });
  }
  
  multiAddExecutors() {
    const dialogRef = this.dialog.open(SetEmplComponent, {
      data: {
        company: this.company,
        user: this.user,
        selectedJobs: this.jobCollection.selected
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result && result.event == "update") {
          // this.updateJob(job.id);
          this.jobCollection.selected.forEach(x => {
            this.updateJob(x.id)
          })
          this.jobCollection.clear()
        }
      })
    )
  }
  
  openChecklist(task, work) {
    console.log(task, work);
    this.attachSubscriptions(
      this.taskService.getOperationReminder(this.company_id, {task_operation_id: work.id}).subscribe(resp => {
        const dialogRef = this.dialog.open(CheckListComponent, {
          data: {
            company: this.company,
            task: task,
            work: work,
            user: this.user,
            checklist: resp.body,
            operationsValues: this.operationsValues
          }
        });

        this.attachSubscriptions(
          dialogRef.afterClosed().subscribe(result => {
            this.updateJob(work.id)
          })
        )
      })
    )
  }
  
  openMobScenarios(job) {
    job.scenarioOpen = !job.scenarioOpen;
  }

  getJobs(page) {
    console.log("FILTER", this.filter.value)
    this.isLoad = true;
    let filterData = {...this.filter.value};
    // if (!!filterData.created_at_from) {
    //   filterData.created_at_from = moment(filterData.created_at_from._d).format("X");
    // }
    // if (!!filterData.created_at_to) {
    //   filterData.created_at_to = moment(filterData.created_at_to._d).format("X");
    // }
    // if (!!filterData.updated_at_from) {
    //   filterData.updated_at_from = moment(filterData.updated_at_from._d).format("X");
    // }
    // if (!!filterData.updated_at_to) {
    //   filterData.updated_at_to = moment(filterData.updated_at_to._d).format("X");
    // }
    // if (!!filterData.task_operation_planned_completed_at_from) {
    //   filterData.task_operation_planned_completed_at_from = moment(filterData.task_operation_planned_completed_at_from._d).format("X");
    // }
    // if (!!filterData.task_operation_planned_completed_at_to) {
    //   filterData.task_operation_planned_completed_at_to = moment(filterData.task_operation_planned_completed_at_to._d).format("X");
    // }
    // if (!!filterData.completed_at_from) {
    //   filterData.completed_at_from = moment(filterData.completed_at_from._d).format("X");
    // }
    // if (!!filterData.completed_at_to) {
    //   filterData.completed_at_to = moment(filterData.completed_at_to._d).format("X");
    // }
    // Object.keys(filterData).filter(x => x != 'preset_id').forEach(key => {
    //   if (key.indexOf("_at_",) != -1 && !["created_at_from", "created_at_to", "updated_at_from", "updated_at_to", "task_operation_planned_completed_at_from", "task_operation_planned_completed_at_to", "completed_at_from", "completed_at_to"].includes(key) && !!filterData[key]) {
    //     filterData[key] = moment(this.filter.value[key]._d).format("X")
    //   }
    // })

    Object.keys(filterData).forEach((key:string) => {      
      if (key.indexOf('at_from') != -1 && !!filterData[key]) {
        filterData[key] = moment(filterData[key]._d).startOf('day').format("X");
      }
      if (key.indexOf('at_to') != -1 && !!filterData[key]) {
        filterData[key] = moment(filterData[key]._d).endOf('day').format("X");
      }
    })
    // this.jobsSub = this.taskService.getWorks(page, this.company_id, filterData, !this.is_mobile ? '20' : '10').pipe(
    this.jobsSub = this.taskService.getWorksSection(page, this.company_id, filterData, !this.is_mobile ? '40' : '20').pipe(
      tap(el => {
        this.pagination = {
          'pageCount': el.headers.get('x-pagination-page-count'),
          'perPage': el.headers.get('x-pagination-per-page'),
          'totalCount': el.headers.get('x-pagination-total-count'),
          'currentPage': el.headers.get('x-pagination-current-page'),
        }
        if (this.pagination['currentPage'] == '1') {
          this.jobs = [];
        }
      }),
      map(el => el.body),
      map(arr => jobsModel(arr, this.currencyData.slice())),
      tap(resp => {
        this.taskService.jobData$.next(resp)
      }),
      distinctUntilChanged()
    ).subscribe(resp => {
      
      console.log(resp, this.page)

      if (this.page == 1) {
        this.jobs = resp;

        // Delete before push
        // this.createChat(this.jobs[0])
        
        // this.getEmployees();
        // this.getGroupsCompany();
      } else {
        resp.forEach(x => {
          if (this.jobs.filter(y => y.id == x.id).length == 0) {
            this.jobs.push(x)
          }
        })
        // this.jobs.push(...resp);
      }
      console.log("this.jobs "+this.page, this.jobs)
      this.page = this.page + 1;
      this.isLoad = false;
      if (+this.pagination['currentPage'] == 1) {
        this.jobsContainer.nativeElement.scrollTop = 0;
      }

      // TEST
      // if (this.page <= 3 && +this.pagination['pageCount'] != 1) {
      //   this.getJobs(this.page);
      // }

      // if (+this.pagination['currentPage'] == 2 || +this.pagination['pageCount'] <= 1) {
      //   this.getParameters()
      // }
      // if (this.page == 1) {
      //   this.getParameters()
      // }

      // this.openJob(this.jobs[0].task_id, this.jobs[0]);

    },
    error => {
      this.isLoad = false;
    })
    
  }

  togglePersonal() {
    console.log(this.filter.value)
    this.filter.patchValue({
      task_operation_other_employee_status_id: !!this.filter.value.task_operation_other_employee_status_id ? 0 : 1
    })
    this.filterJobs();
  }

  closeFilter(e) {
    if (!e) {
      this.filterJobs(null, null, false, false);
    }
  }

  // getEmployees() {
  //   this.attachSubscriptions(
  //     this.taskService.getEmployees(this.company_id).subscribe(resp => {
  //       this.employees = resp;
  //       this.employees$.next(this.employees.slice())
  //     })
  //   )
  // }

  isMainEmpl(job) {
    if (job && this.company && job?.employees && (job?.employees.filter(x => !x.discussion_id && this.company && this.company.employees[0] && (x.employee_id == this.company.employees[0].id || x.partner_employee_id == this.company.employees[0].id) && x.is_manager == 1).length > 0 || (job.company_id != this.company.id && this.company.permissions.includes("owner")))) {
      return true;
    } else {
      return false;
    }
  }

  // getGroupsCompany() {
  //   this.attachSubscriptions(
  //     this.companyService.getInfiniteGroupsCompany(this.company_id, '1', '1').pipe(
  //       switchMap(el => {
  //         let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
  //         let arr = []
  //         for (let index = 1; index <= pages; index++) {
  //           arr.push(index)
  //         }

  //         return forkJoin(arr.map(x => this.companyService.getInfiniteGroupsCompany(this.company_id, x).pipe(map(u => u.body)))).pipe(
  //           last(),
  //         )
  //       }),
  //     ).subscribe(res => {
  //       this.groups = [].concat(...res);
  //       this.groups$.next(this.groups.slice());
  //     })
  //   )
  // }

  openJob(task_id, job, initData?, event?, index?) {
    if (event) {
      if (event.ctrlKey || event.metaKey) { // для Mac OS
        event.preventDefault();
        event.stopPropagation();
        // Если нажат Ctrl или Command (на Mac), то добавляем или удаляем из выбранных
        const selectedIndex = this.jobCollection.selected.indexOf(job);
        if (selectedIndex === -1) {
          this.jobCollection.select(job);
        } else {
          this.jobCollection.deselect(job)
        }
        return
      } else if (event.shiftKey) {
        event.preventDefault();
        event.stopPropagation();
        // Если нажат Shift и уже есть выбранные элементы
        if (this.jobCollection.selected.length === 0) {
          // Если нет выбранных элементов, начинаем выбор с первого элемента
          this.jobCollection.select(job);
        } else {
          // Если уже есть выбранные элементы
          const firstSelectedIndex = this.jobs.findIndex(x => x.id == this.jobCollection.selected[0].id);
          console.log("firstSelectedIndex", firstSelectedIndex)
          const start = Math.min(firstSelectedIndex, index);
          const end = Math.max(firstSelectedIndex, index);
          console.log("SE", start, end)
          this.jobCollection.clear();
          for (let i = start; i <= end; i++) {
            this.jobCollection.select(this.jobs[i]);
          }
        }
        return
      } 
    }

    console.log('openJob')
    this.jobs.map(x => x.isLoad = false)
    job.isLoad = true;
    if (this.jobLoadSub) {
      this.jobLoadSub.unsubscribe();
    }
    this.jobLoadSub = this.taskService.getSmartOneTaskExpand(this.company_id, task_id).pipe(
      map(x => x.body[0]),
      map((x) => casesModel([x], [], 'update')),
      map(x => x.arr[0])
    ).subscribe(resp => {
      this.jobs.map(x => x.isLoad = false)
      const dialogRef = this.dialog.open(OpenJobComponent, {
        backdropClass: !this.is_mobile ? 'backdrop_under_header': ['backdrop_under_header', 'mob_card_backdrop'],
        panelClass: !this.is_mobile ? ['open_task_dialog', 'show_header'] : ['open_task_dialog', 'animate__animated', 'animate__slideInUp'],
        autoFocus: false,
        data: {
          initCompanyId: this.company_id,
          task_id: task_id,
          task_operation_id: job.id,
          job: job,
          task: resp,
          fast_mode: true,
          selectedIndex: !this.is_mobile && !initData ? 4 : initData?.selectedIndex,
          loc: initData?.loc
        }
      });
      this.closeContext();

      this.attachSubscriptions(
        dialogRef.afterClosed().subscribe(result => {
          this.updateJob(job.id)
        })
      )
    })
  }

  getPrimaryTag(job) {
    if (job.parameterValuesToTask && job.parameterValuesToTask.length) {
      return job.parameterValuesToTask.find(x => !!x.is_primary)
    } else {
      return undefined
    }
  }

  updateJob(id) {
    this.refreshService.refreshJob$.next({company_id: this.company_id, job_id: id})
  }

  ifAllStatuses() {
    this.attachSubscriptions(
      this.filter.get('status_id').valueChanges.subscribe(val => {
        if (val.sort().toString() == [1,2,3,4,5,6,97,98,99].toString() || val.sort().toString() == [0,1,2,3,4,5,6,97,98,99].toString()) {
          this.filter.patchValue({
            status_id: [0]
          })
        }
      })
    )
  }

  onStartDateChange(e: MatDatepickerInputEvent<Date, Date>) {
    if (e && e.value) {
      this.dateFromChanged = true;
    }
    this.checkRange();
  }

  onEndDateChange(e: MatDatepickerInputEvent<Date, Date>) {
    if (e && e.value) {
      this.dateToChanged = true;
    }
    this.checkRange();
  }

  checkRange(): void {
    if (this.dateFromChanged || this.dateToChanged) {
      this.dateFromChanged = false;
      this.dateToChanged = false;
      this.filterJobs()
    }
  }

  scenChangeOpen(e, job) {
    console.log(e, job);
    job.scen_visible = e;
  }

  scenIsSelected(job, template) {
    return !!job.automationScenarios.length && job.automationScenarios.filter(x => x.template_id == template.id).length > 0
  }

  activeScan(job, template) {
    return !!job.automationScenarios.length && job.automationScenarios.find(x => x.template_id == template.id) && job.automationScenarios.find(x => x.template_id == template.id).is_active == 0
  }

  allDone(job) {
    return job.automationScenarios.length == job.automationScenarios.filter(x => x.is_active == 0).length
  }

  someDone(job) {
    return job.automationScenarios.filter(x => x.is_active == 0).length != 0
  }

  findScen(job, template) {
    return job.automationScenarios.find(x => x.template_id == template.id)
  }

  ifMeIsJobEmpl(job) {
    if (this.company && this.company.employees && this.company.employees[0].id) {
      return job.employees.filter(x => x.partner_employee_id == this.company.employees[0].id || x.employee_id == this.company.employees[0].id).length > 0
    } else {
      return false
    }
  }

  addMeToJob(job, is_manager) {
    if (!!job.isSubmitExec) {
      return
    }

    job.openAdd = false
    job.isSubmitExec = true;

    if (job.company_id == this.company_id) {
      this.attachSubscriptions(
        this.membersService.addMember({
          company_id: job.company_id,
          task_id: job.task_id,
          task_operation_id: job.id,
          discussion_id: 0,
          status_id: job.status_id,
          is_manager: is_manager,
          is_price_manager: 1,
          employee_id: this.company.employees[0].id
        }, this.company_id).subscribe(resp => {
          job.isSubmitExec = false;
          this.updateJob(job.id)
        }, error => {
          job.isSubmitExec = false;
          this.layoutService.showSnackBar({name: ''}, marker(error), SnackBarItem)
        })
      )
    } else {
      this.attachSubscriptions(
        this.membersService.addTaskPartnerEmployee({
          company_id: job.company_id,
          task_id: job.task_id,
          task_operation_id: job.id,
          discussion_id: 0,
          status_id: job.status_id,
          is_manager: is_manager,
          is_price_manager: 1,
          partner_employee_id: this.company.employees[0].id,
          partner_company_id: this.company_id,
        }, this.company_id).subscribe(resp => {
          job.isSubmitExec = false;
          this.updateJob(job.id)
        }, error => {
          job.isSubmitExec = false;
          this.layoutService.showSnackBar({name: ''}, marker(error), SnackBarItem)
        })
      )
    }
  }

  getAutomationTemplates() {
    this.attachSubscriptions(
      this.scenariosService.getAutomationTemplates('1', {company_id: this.company_id, is_task_operation: 1}, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.scenariosService.getAutomationTemplates(x, {company_id: this.company_id, is_task_operation: 1}, '200').pipe(map(u => u.body)))).pipe(
            last(),
            tap(values => {
              this.templates = [].concat(...values)
              console.log("getAutomationTemplates", this.templates)
              this.templates$.next(this.templates.slice());
            }),
          )
        }),
      ).subscribe(resp => {
        console.log("getAutomationTemplates sub", resp);
      })
    )
  }
  
  tryScen(e, job, template) {
    e.preventDefault();
    e.stopPropagation();
    this.disableTemplate = true;
    this.attachSubscriptions(
      this.scenariosService.tryScenario(this.findScen(job,template).id).subscribe(result => {
        console.log('tryScenario', result);
        this.disableTemplate = false;
      }, error => {
        this.disableTemplate = false;
        this.layoutService.showSnackBar({name: ''}, marker(error), SnackBarItem)
      })
    )
  }

  deleteDuplicateScens() {
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: {},
        target: 'duplicated scenarios'
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        let lenSelection = this.jobCollection.selected.length;
        let count = 0;
        from(this.jobCollection.selected).pipe(
          concatMap((job:any) => {
            let deletedIds = [];
            let ids = [];
            job.automationScenarios.forEach(el => {
              if (ids.includes(el.template_id)) {
                deletedIds.push(el)
              } else {
                ids.push(el.template_id)
              }
            });
    
            if (deletedIds.length) {
              return forkJoin(deletedIds.map(k => this.scenariosService.deleteAutomationScenario(k.id).pipe(
                tap(y => {
                  job.automationScenarios.splice(job.automationScenarios.findIndex(p => p.id == k.id), 1)
                })
              )))
            } else {
              return of(job)
            }
          })
        ).subscribe({
          next: (next) => {
            console.log("next Delete Duplicate Scenarios", next);
            this.ls.requests$.next({
              value: Math.round((100 / lenSelection) * (count+1)),
              target: "Delete Duplicated Scenarios",
            })
            count++;
          },
          complete: () => {
            console.log("complete Delete Duplicate Scenarios");
            // 
            // this.jobCollection.selected.forEach((job, ind) => {
            //   this.updateJob(job.id)
    
            //   if (ind == this.jobCollection.selected.length - 1) {
            //     this.jobCollection.clear()
            //   }
            // });
            this.jobCollection.clear();
          },
          error: (error) => {
            console.log("error Delete Duplicate Scenarios", error)
          }
        })
      }
    })
  }

  tryDuplicateScen(e, job, template, q) {
    e.preventDefault();
    e.stopPropagation();
    this.disableTemplate = true;
    this.attachSubscriptions(
      this.scenariosService.tryScenario(job.automationScenarios.filter(x => x.template_id == template.id)[q].id).subscribe(result => {
        console.log('tryScenario', result);
        this.disableTemplate = false;
      }, error => {
        this.disableTemplate = false;
        this.layoutService.showSnackBar({name: ''}, marker(error), SnackBarItem)
      })
    )
  }

  activeDuplicateScan(job, template, index) {
    if (!!job.automationScenarios.length && job.automationScenarios.filter(x => x.template_id == template.id).length) {
      return job.automationScenarios.filter(x => x.template_id == template.id)[index].is_active == 0
    } else {
      return false
    }
    // return false;
    // return !!job.automationScenarios.length && job.automationScenarios.find(x => x.template_id == template.id) && job.automationScenarios.find(x => x.template_id == template.id).is_active == 0
  }

  duplicatesScenCount(job, template) {
    return job.automationScenarios.filter(x => x.template_id == template.id)
  }

  toggleDuplicateScen = (chip: any, job, q) => {
    let id = job.automationScenarios.filter(x => x.template_id == (chip.id))[q].id;

    this.attachSubscriptions(
      this.scenariosService.deleteAutomationScenario(id).subscribe(resp => {
        job.automationScenarios.splice(job.automationScenarios.findIndex(t => t.id == id), 1)
        this.disableTemplate = false;

        if (job.automationScenarios && job.automationScenarios.length) {
          job.selectedScenIds = job.automationScenarios.map(x => x.template_id);
        } else {
          job.selectedScenIds = [];
        }
      }, error => {
        this.disableTemplate = false;
        this.layoutService.showSnackBar({name: 'You do not have permission to do this.'}, `${error}`, SnackBarItem)
      })
    )
  }

  toggleChipTemplate = (chip: any, job) => {
    this.disableTemplate = true;
    if (this.scenIsSelected(job, chip)) {
      console.log("Selected");
      let id = job.automationScenarios.find(x => x.template_id == (chip.id)).id
      this.attachSubscriptions(
        this.scenariosService.deleteAutomationScenario(id).subscribe(resp => {
          job.automationScenarios.splice(job.automationScenarios.indexOf(job.automationScenarios.find(x => x.template_id == (chip.id))), 1)
          this.disableTemplate = false;

          if (job.automationScenarios && job.automationScenarios.length) {
            job.selectedScenIds = job.automationScenarios.map(x => x.template_id);
          } else {
            job.selectedScenIds = [];
          }
        }, error => {
          this.disableTemplate = false;
          this.layoutService.showSnackBar({name: 'You do not have permission to do this.'}, `${error}`, SnackBarItem)
        })
      )
    } else {
      console.log("NO Selected")
      this.attachSubscriptions(
        this.scenariosService.addAutomationScenario({
          company_id: this.company_id,
          task_operation_id: job.id,
          template_id: chip.id
        }, this.company_id).pipe(
          switchMap(res => {
            return this.scenariosService.getAutomationTemplate(res.template_id).pipe(tap(result => res.template = result), map(() => res))
          })
        ).subscribe(resp => {
          console.log("addAutomationScenario", resp);
          job.automationScenarios.push(resp);
          this.disableTemplate = false;
          if (job.automationScenarios && job.automationScenarios.length) {
            job.selectedScenIds = job.automationScenarios.map(x => x.template_id);
          } else {
            job.selectedScenIds = [];
          }
          console.log("JOB", job)
        })
      )
    }
  }

  // add(e) {
  //   console.log(e)
  // }

  // getProfiles(company_id, page) {
  //   this.attachSubscriptions(
  //     this.companyService.getProfiles(company_id, page).pipe(
  //       tap(el => {
  //         this.profilesPagination = {
  //           'pageCount': el.headers.get('x-pagination-page-count'),
  //           'perPage': el.headers.get('x-pagination-per-page'),
  //           'totalCount': el.headers.get('x-pagination-total-count'),
  //           'currentPage': el.headers.get('x-pagination-current-page'),
  //         }
  //       }),
  //       map(el => el.body)
  //     ).subscribe(resp => {
  //       console.log("getProfiles", resp)

  //       if (page == 1) {
  //         this.profiles = resp
  //       } else {
  //         resp.forEach(profile => {
  //           if (this.profiles.filter(x => x.id == profile.id).length == 0) {
  //             this.profiles.push(...resp);
  //           }
  //         })
  //       }
  //     })
  //   )
  // }

  // getProfile(id) {
  //   if (!this.profiles) {
  //     return false;
  //   }
  //   return this.profiles.find(el => el.id == id)
  // }  
  
  removeValueFromMultiple(obj) {
    let key = obj.key;
    let value = obj.id;
    let arr = this.filter.value[key];
    if (arr.includes(value)) {
      arr.splice(arr.indexOf(value), 1)
    }
    this.filter.patchValue({
      [key]: arr
    })
    this.filterJobs()
  }

  noOption(arr, id) {
    return arr.filter(x => x.id == id).length > 0 ? false : true;
  }

  withSearch(searchText: string, val) {
    if (searchText == '' || typeof searchText != 'string' || val.value.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ) {
      return true
    } else {
      return false
    }
  }

  // neededParametersData(param) {
  //   let arr = []

  //   arr.push(
  //     this.parametersService.getApiParameterValues(this.company_id, param.id, "20", !!param.page ? param.page : "1").pipe(
  //       tap(x => {
  //         param.pagination = {
  //           'pageCount': x.headers.get('x-pagination-page-count'),
  //           'perPage': x.headers.get('x-pagination-per-page'),
  //           'totalCount': x.headers.get('x-pagination-total-count'),
  //           'currentPage': x.headers.get('x-pagination-current-page'),
  //         }
  //         param.page = 2
  //         param.values = x.body;
  //         if (x.body.length > 0) {
  //           param.hasValues = true;
  //         }
  //         this.getActiveValuesForParam(param)
  //       })
  //     )
  //   )

  //   return forkJoin(arr)
  // }

  // onSearch(searchText, param) {
  //   console.log(searchText, param)
  //   param.page = 1;
  //   this.parametersService.getApiParameterValues(this.company_id, param.id, "20", !!param.page ? param.page : "1",  !!param.search.value ? param.search.value : '').subscribe(resp => {
  //     param.pagination = {
  //       'pageCount': resp.headers.get('x-pagination-page-count'),
  //       'perPage': resp.headers.get('x-pagination-per-page'),
  //       'totalCount': resp.headers.get('x-pagination-total-count'),
  //       'currentPage': resp.headers.get('x-pagination-current-page'),
  //     }
  //     param.page = 2
  //     param.values = resp.body;
  //     console.log(resp.body)
  //     console.log(this.parameters)
  //   })
  // }

//   closeDateFilter() {
//     this.dateFilterSub && this.dateFilterSub.unsubscribe();
//     if (this.overlayDateRefFilter) {
//       this.overlayDateRefFilter.dispose();
//       this.overlayDateRefFilter = null;
//     }
//   }

//   openDateFilter(e, el, val) {
//     this.closeDateFilter();
//     let x = el.getBoundingClientRect().left - 350 + el.getBoundingClientRect().width;
//     let y =  el.getBoundingClientRect().top + el.getBoundingClientRect().height;
//     const positionStrategy = this.overlay.position()
//     .flexibleConnectedTo({ x, y })
//     .withPositions([
//       {
//         originX: 'start',
//         originY: 'bottom',
//         overlayX: 'start',
//         overlayY: 'top',
//       }
//     ]);

//     this.overlayDateRefFilter = this.overlay.create({
//       backdropClass: 'transparent_bg',
//       hasBackdrop: true,
//       positionStrategy,
//       scrollStrategy: this.overlay.scrollStrategies.close()
//     });

//     this.overlayDateRefFilter.attach(new TemplatePortal(this.jobFilterDateMenu, this.viewContainerRef, {
//       $implicit: val
//     }));
    
//     this.dateFilterSub = fromEvent<MouseEvent>(document, 'click')
//       .pipe(
//         filter(event => {
//           const clickTarget = event.target as HTMLElement;
       
//           let check1 = !!this.overlayDateRefFilter && !this.overlayDateRefFilter.overlayElement.contains(clickTarget)
//           // let check2 = (document.getElementsByClassName('mat-calendar-table').length > 0 && !document.getElementsByClassName('mat-calendar-table')[0].contains(clickTarget))
//           // let check3 = (document.getElementsByClassName('filter_autocomlete').length == 0);
//           // let check4 = (clickTarget.classList.contains('mat-option-text') || clickTarget.classList.contains('mat-option'))
//           let check5 = !clickTarget.classList.contains('mat-calendar-body-cell-content')
//           // console.log("check1", check1)
//           // console.log("check2", check2)
//           // console.log("clickTarget", clickTarget.classList.contains('mat-calendar-body-cell-content'))
//           // console.log("document.getElementsByClassName('mat-calendar-table')", document.getElementsByClassName('mat-calendar-table'))
//           // console.log("document.getElementsByClassName('mat-calendar-table')[0].contains(clickTarget)", document.getElementsByClassName('mat-calendar-table')[0].contains(clickTarget))
// //(check1 && check2) || (check1 && check3 && !check4)
//           // return false
//           return check1 && check5
//         }),
//         take(1)
//       ).subscribe(() => this.closeDateFilter())

//   }

  // getActiveValuesForParam(param) {
  //   let paramValues;
  //   if (param.id != 0) {
  //     paramValues = this.allValues.filter(b => b.parameter_id == param.id);
  //   } else {
  //     paramValues = this.allValues.slice();
  //   }
  //   param.activeTaskValues = []
  //   // console.log("paramValues",param.name, paramValues)
  //   this.filter.value.task_operation_parameter_value_id.forEach(x => {
  //     // console.log("x", x)
  //     // console.log("paramValues.filter(y => y.id == x)", paramValues.filter(y => y.id == x))
  //     param.activeTaskValues.push(...paramValues.filter(y => y.id == x))
  //   }) 
  //   param.activeValues = []
  //   this.filter.value.parameter_value_id.forEach(x => {
  //     param.activeValues.push(...paramValues.filter(y => y.id == x))
  //   })
  // }
  

  // getAllApiParameterValues() {
  //   this.attachSubscriptions(
  //     this.parametersService.getAllApiParameterValues(this.company.id).subscribe(resp => {
  //       this.allValues = resp
  //       console.log("getAllApiParameterValues", this.allValues)
  //     })
  //   )
  // }

  // getParameterById(id) {
  //   if (!this.parameters || this.parameters.length == 0) {
  //     return null
  //   }
  //   return this.parameters.find(x => x.id == id)
  // }


  // getValueById(id) {
  //   if (!this.allValues || this.allValues.length == 0) {
  //     return null
  //   }
  //   return this.allValues.find(x => x.id == id)
  // }

  // onRemove(value, param, key) {
  //   console.log("onRemove", this.parameters.find(x => x.id == value.parameter_id))
  //   if (param.id == 0) {
  //     let parameterValues = this.parameters.find(x => x.id == value.parameter_id)[key == 'task_operation_parameter_value_id' ? "activeTaskValues" : "activeValues"]
  //     parameterValues.splice(parameterValues.indexOf(parameterValues.find(b => b.id == value.id)),1)
  //   }
  //   let val = this.filter.value[key];
  //   val.splice(val.indexOf(value.id), 1);
  //   this.filter.patchValue({
  //     [key]: val
  //   })

  //   if (param.id != 0) {
  //     this.getActiveValuesForParam(this.parameters[0]);
  //   }
  //   this.getActiveValuesForParam(param);
  //   this.filterJobs()
  // }

  // selected(e, param, key) {
  //   console.log("selected", e)

    
  //   if (param.id == 0) {
  //     console.log(this.parameters, e, param, key);
  //     let parameterValues = this.parameters.find(x => x.id == e.option.value.parameter_id)[key == 'task_operation_parameter_value_id' ? "activeTaskValues" : "activeValues"]
  //     parameterValues.push(e.option.value)
  //   }

  //   let val = this.filter.value[key];
  //   val.push(e.option.value.id);
  //   this.filter.patchValue({
  //     [key]: val
  //   })
  //   // param.search.patchValue("")
  //   // if (document.getElementById(`param_input_${param.id}`)) {
  //   //   (document.getElementById(`param_input_${param.id}`) as HTMLInputElement).value = ''
  //   // }
  //   this.getActiveValuesForParam(param);
  //   if (param.id != 0) {
  //     this.getActiveValuesForParam(this.parameters[0]);
  //   }
  //   console.log(this.parameters)
  //   this.filterJobs()
  // }

  openMobAssignments(job) {
    this.attachSubscriptions(
      this.taskService.getOneTaskExpand(this.company.id, job.task_id).pipe(
        map(x => x.body[0]),
        switchMap(val => this.neededTaskData(val).pipe(map(() => casesModel([val], [], 'update')),map(val => val.arr[0])))
      ).subscribe(resp => {
        console.log("addToBoard", resp)
        let work = resp.operations.find(x => x.id == job.id);
        work.employees = work.employees.filter(x => x.discussion_id == 0 || !x.discussion_id)
        work.mainEmployees = work.mainEmployees.filter(x => x.discussion_id == 0 || !x.discussion_id)
        work.addEmployee = work.addEmployee.filter(x => x.discussion_id == 0 || !x.discussion_id)
        work.addEmployee.forEach(x => {
          if (!x.employeeStatus && job.employeesStatuses.find(z => z.employee_id == x.employee_id)) {
            x.employeeStatus = job.employeesStatuses.find(z => z.employee_id == x.employee_id)
          }
        })

        const dialogRef = this.dialog.open(MobAssignmentsComponent, {
          backdropClass: ['mob_interface_backdrop'],
          panelClass: ['mob_interface_panel', 'animate__animated', 'animate__slideInUp'],
          disableClose: true,
          data: {
            user: this.user,
            company: this.company,
            task: resp,
            work: work,
            empl_status: this.company.permissions.includes('owner') || this.checkIsManager(work, this.company),
            host: this.host,
            statuses: this.statuses,
            operationsValues: this.operationsValues,
            activeLang: this.activeLang
          }
        });
    
        dialogRef.afterClosed().subscribe(result => {
          if (!!result) {
            this.updateJob(work.id)
          }
        })
      })
    )

  }

  // getParameters() {
  //   this.attachSubscriptions(
  //     this.parametersService.getApiParameters(this.company_id).pipe(
  //       switchMap(res => {
  //         return this.parametersService.getAllValues('1', this.company_id, null, '1').pipe(
  //           switchMap(el => {
  //             let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
  //             let arr = []
  //             for (let index = 1; index <= pages; index++) {
  //               arr.push(index)
  //             }
    
  //             return forkJoin(arr.map(x => this.parametersService.getAllValues(x, this.company_id).pipe(map(u => u.body)))).pipe(
  //               last(),
  //               tap(values => {
  //                 this.allValues = [].concat(...values)
  //                 console.log("getAllApiParameterValues", this.allValues)
  //               }),
  //               map(() => res)
  //             )
  //           }),
  //           map(() => res)
  //         )
  //       }),
  //       tap(res => {
  //         res.unshift({
  //           company_id: this.company_id,
  //           created_at: 0,
  //           id: 0,
  //           is_new_value: 0,
  //           name: "All parameters",
  //           original_id: 0,
  //         })
  //         this.parametersService.paramsData$.next(res)
  //         res.forEach(n => {
  //           n.search = new FormControl('');
  //           n.search.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearch(resp, n))
  //         })
  //         this.parameters = res
  //       }),
  //     ).subscribe(resp => {
  //       console.log("this.parameters", this.parameters)
  //     })
  //   )
  // }

  // onScrollParams(param) {
  //   console.log(param.page, param.pagination.pageCount)
  //   if (param.pagination.pageCount >= param.page) {
  //     console.log("SCROLL !")
  //     this.parametersService.getApiParameterValues(this.company_id, param.id, "20", param.page, !!param.search.value ? param.search.value : '').pipe(take(1), map((x) => x.body)).subscribe(resp => {
  //       param.page++;
  //       param.values.push(...resp)
  //     })
  //   }
  // }

//   closeParameterFilter() {
//     this.parameterFilterSub && this.parameterFilterSub.unsubscribe();
//     if (this.overlayParameterRefFilter) {
//       this.overlayParameterRefFilter.dispose();
//       this.overlayParameterRefFilter = null;
//     }
//   }

//   openParameterFilter(el, val) {
//     this.closeParameterFilter();
//     let x = el.offsetLeft;
//     let y =  el.offsetTop + el.offsetHeight;
//     const positionStrategy = this.overlay.position()
//     .flexibleConnectedTo({ x, y })
//     .withPositions([
//       {
//         originX: 'start',
//         originY: 'bottom',
//         overlayX: 'start',
//         overlayY: 'top',
//       }
//     ]);

//     this.overlayParameterRefFilter = this.overlay.create({
//       positionStrategy,
//       scrollStrategy: this.overlay.scrollStrategies.close()
//     });

//     this.overlayParameterRefFilter.attach(new TemplatePortal(this.filterParameterMenu, this.viewContainerRef, {
//       $implicit: val
//     }));
    
//     this.parameterFilterSub = fromEvent<MouseEvent>(document, 'click')
//       .pipe(
//         filter(event => {
//           const clickTarget = event.target as HTMLElement;
       
//           let check1 = !!this.overlayParameterRefFilter && !this.overlayParameterRefFilter.overlayElement.contains(clickTarget)
//           // let check2 = (document.getElementsByClassName('filter_autocomlete').length > 0 && !document.getElementsByClassName('filter_autocomlete')[0].contains(clickTarget))
//           // let check3 = (document.getElementsByClassName('filter_autocomlete').length == 0);
//           // let check4 = (clickTarget.classList.contains('mat-option-text') || clickTarget.classList.contains('mat-option'))
       
// //(check1 && check2) || (check1 && check3 && !check4)
//           return check1
//         }),
//         take(1)
//       ).subscribe(() => this.closeParameterFilter())

//   }

  // (CloseContext)="closeContext()" 
  // (OpenJob)="openJob(item?.task_id, item, {selectedIndex: 3})" 
  // [target]="item" 
  // [is_mobile]="is_mobile" 
  // [user]="user" 
  // [activeLang]="activeLang" 
  // [company]="company" 
  // [imgRoute]="imgRoute" 
  // [host]="host"
  openLiteJob(job, index?, loc?) {
    console.log('openLiteJob')
    job.targetIds = {
      task_id: job.task_id,
      task_operation_id: job.id
    }
    const dialogRef = this.dialog.open(LiteJobComponent, {
      backdropClass: !this.is_mobile ? 'backdrop_under_header': ['backdrop_under_header', 'mob_card_backdrop'],
      panelClass: !this.is_mobile ? ['open_task_dialog', 'show_header', 'lite_job_dialog'] : ['open_task_dialog', 'animate__animated', 'animate__slideInUp'],
      autoFocus: false,
      data: {
        task_id: job.task_id,
        task_operation_id: job.id,
        user: this.user,
        company: this.company,
        imgRoute: this.imgRoute,
        host: this.host,
        activeLang: this.activeLang,
        is_mobile: this.is_mobile,
        job: {...job},
        selectedIndex: index || 0,
        loc: loc,
        openJob: (task_id, item, inData) => {this.openJob(task_id, item, inData)} 
      }
    })

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        this.updateJob(job.id)
      })
    )
  }

  openContext({ x, y }: MouseEvent, job) {
    this.closeContext();
    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo({ x, y })
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        }
      ]);

    console.log("openContext job", job);
    job.can_open = true;
    job.is_clicked = true;
    job.active_location = '/'
    job.output_location = ''
    job.outputFolder = {
      targetIds: {
        task_id: job.task_id,
        task_operation_id: job.id,
      },
      page: 1,
      pagination: undefined,
      files: [],
      is_load: true
    }

    this.liteFmOverlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });

    this.liteFmOverlayRef.attach(new TemplatePortal(this.liteFmMenu, this.viewContainerRef, {
      $implicit: job
    }));
    
    this.liteFmSub = fromEvent<MouseEvent>(document, 'click')
      .pipe(
        filter(event => {
          const clickTarget = event.target as HTMLElement;
          let check1 = !document.getElementsByClassName('proj-file-menu') || document.getElementsByClassName('proj-file-menu').length > 0 && !document.getElementsByClassName('proj-file-menu')[0].contains(clickTarget);
          let check2 = !!this.liteFmOverlayRef && !this.liteFmOverlayRef.overlayElement.contains(clickTarget) && !clickTarget.closest('.proj-file-menu')
          let check3 = !!this.liteFmOverlayRef && !this.liteFmOverlayRef.overlayElement.contains(clickTarget) 
          let check4 = !clickTarget.closest('.proj-file-menu')
          console.log("clickTarget", clickTarget)
          console.log("this.liteFmOverlayRef.overlayElement", this.liteFmOverlayRef.overlayElement)
          console.log("check1", check1)
          console.log("check2", check2)
          console.log("check3", check3)
          console.log("check4", check4)
          return check2;
        }),
        take(1)
      ).subscribe(() => {
        job.is_clicked = false;
        this.closeContext()
      })

  }

  closeContext() {
    this.liteFmSub && this.liteFmSub.unsubscribe();
    if (this.liteFmOverlayRef) {
      this.liteFmOverlayRef.dispose();
      this.liteFmOverlayRef = null;
    }
  }

  ngOnDestroy(): void {
    if (this.jobsDataSub) {
      this.jobsDataSub.unsubscribe();
    }
    if (this.liteFmSub) {
      this.liteFmSub.unsubscribe();
    }
    if (this.jobsSub) {
      this.jobsSub.unsubscribe()
    }
    // if (this.parametersSub) {
    //   this.parametersSub.unsubscribe();
    // }

    if (this.jobLoadSub) {
      this.jobLoadSub.unsubscribe();
    }
    this.clearSubscriptions()
  }

}
