import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReplaySubject, forkJoin, from } from 'rxjs';
import { concatMap, debounceTime, last, map, switchMap, tap } from 'rxjs/operators';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { LoadingService } from 'src/app/shared/services/rest/loading.service';
import { MembersService } from 'src/app/shared/services/rest/members.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';

@Component({
  selector: 'app-channel-to-employee',
  templateUrl: './channel-to-employee.component.html',
  styleUrls: ['./channel-to-employee.component.scss']
})
export class ChannelToEmployeeComponent extends BaseClass implements OnInit, OnDestroy {
  public form: FormGroup = this.fb.group({
    is_channel_content: '',
    is_channel_content_upload: ''
  });
  public channelsToEmployee: any;

  public repCollection = new SelectionModel(
    true,
    []
  )

  public profiles: any[] = [];
  public profilesMoreControl: FormControl = new FormControl();
  public profiles$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public isSubmit: boolean = false;
  public is_deleting: boolean = false;

  public employees: any;
  public employees$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public execControl: FormControl = new FormControl();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private ls: LoadingService,
    public dialogRef: MatDialogRef<ChannelToEmployeeComponent>,
    public layoutService: LayoutService,
    public taskService: TaskService,
    public companyService: CompanyService,
    public membersService: MembersService,
  ) { super() }

  ngOnInit(): void {
    console.log("ChannelToEmployeeComponent", this.data)
    
    if (!!this.data.fromEmployees) {
      this.form = this.fb.group({
        company_id: this.data.company.id,
        employee_id: this.data.employee.id,
        channel_id: [[]],
        is_channel_content: false,
        is_channel_content_upload: false
      })
      this.getProfiles();
      if (this.data.forPartnerWorkspace) {
        this.getChannelToEmployee({partner_company_id: this.data.employee.partner_company_id});
      } else {
        this.getChannelToEmployee({employee_id: this.data.employee.id});
      }
    } else {
      this.form = this.fb.group({
        company_id: this.data.company.id,
        employee_id: '',
        channel_id: this.data.channel.id,
        is_channel_content: false,
        is_channel_content_upload: false
      })
      this.getEmployees();
      if (this.data.forPartner) {
        this.getChannelToEmployee({channel_id: this.data.channel.id, partner_company_id: this.data.partner_company_id, is_partner_employee: 1});
      } else {
        this.getChannelToEmployee({channel_id: this.data.channel.id});
      }
    }

    this.attachSubscriptions(
      this.form.get("is_channel_content_upload").valueChanges.subscribe(resp => {
        if (resp) {
          this.form.patchValue({
            is_channel_content: true
          })
        }
      })
    )
    
    this.attachSubscriptions(
      this.execControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchEmoloyees(resp))
    )

    this.attachSubscriptions(
      this.profilesMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchProfiles(resp))
    )
  }

  getProfile(id) {
    if (!this.profiles) {
      return false;
    }
    return this.profiles.find(el => el.id == id)
  }
  
  editChToEm(chToEm, key) {
    if (chToEm.isEditing) {
      return
    }
    chToEm.isEditing = true;
    this.attachSubscriptions(
      this.membersService.editChannelToEmployee(chToEm.id, {[key]: !!chToEm[key] ? 0 : 1}).subscribe(resp => {
        chToEm.is_channel_content = resp.is_channel_content;
        chToEm.is_channel_content_upload = resp.is_channel_content_upload;
        chToEm.isEditing = false;
      }, error => {
        this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
        chToEm.isEditing = false;
      })
    )
  }

  getEmployees() {
    this.attachSubscriptions(
      this.taskService.getEmployeesDyn('1', this.data.company.id, null, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }
  
          return forkJoin(arr.map(x => this.taskService.getEmployeesDyn(x, this.data.company.id, null, '200').pipe(map(u => u.body)))).pipe(
            last(),
            tap(values => {
              let conValues = [].concat(...values)
              this.employees = conValues;
              this.employees$.next(this.employees.slice());
            }),
          )
        }),
      ).subscribe(resp => {
        console.log("getEmployees resp", resp);
        console.log("getEmployees", this.employees);
      })
    )
  }

  onSearchEmoloyees(resp) {
    if (!this.employees) {
      return;
    }

    if (!resp) {
      this.employees$.next(this.employees.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.employees$.next(
      this.employees.filter(z => z.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  getEmployee(id) {
    if (!this.employees) {
      return false;
    }
    return this.employees.find(el => el.id == id)
  }  

  onSearchProfiles(resp) {
    if (!this.profiles) {
      return;
    }

    if (!resp) {
      this.profiles$.next(this.profiles.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }

    // filter the banks
    this.profiles$.next(
      this.profiles.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  getProfiles() {
    this.attachSubscriptions(
      this.companyService.getAllProfilesDyn('1', this.data.company.id, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }
          return forkJoin(arr.map(x => this.companyService.getAllProfilesDyn(x, this.data.company.id, '200').pipe(map(u => u.body)))).pipe(
            last(),
            tap(values => {
              let conValues = [].concat(...values)
              this.profiles = conValues;
              this.profiles$.next(this.profiles.slice());
            }),
          )
        }),
      ).subscribe(resp => {
        console.log("getProfiles resp", resp);
        console.log("getProfiles profiles", this.profiles);
      })
    )
  }
  
  getChannelToEmployee(filter) {
    this.attachSubscriptions(
      this.membersService.getAllChannelToEmployee('1', this.data.company.id, filter, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          if (pages == 0) {
            pages = 1
          }
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }
          return forkJoin(arr.map(x => this.membersService.getAllChannelToEmployee(x, this.data.company.id, filter, '200').pipe(map(u => u.body)))).pipe(
            last(),
            tap(values => {
              let allVals = [].concat(...values)
              this.channelsToEmployee = allVals;
            }),
          )
        }),
      ).subscribe(resp => {
        console.log("getChannelToEmployee resp", resp);
        console.log("getChannelToEmployee channelsToEmployee", this.channelsToEmployee);
      })
    )
  }

  checkboxLabel(row?): string {
    if (!row) {
      return `${this.isAllSelected() ? 'Deselect' : 'Select'} All`;
    }
    return `${this.repCollection.isSelected(row) ? 'Deselect' : 'Select'} channel ${row.id}`;
  }

  isAllSelected() {
    const numSelected = this.repCollection.selected.length;
    const numRows = this.channelsToEmployee.length;
    return numSelected === numRows;
  }

  toggleElement(el): void {
    this.repCollection.toggle(el);
    console.log("this.repCollection.selected", this.repCollection.selected);
  }

  masterToggle() {
    this.isAllSelected() ?
        this.repCollection.clear() :
        this.channelsToEmployee.forEach(row => this.repCollection.select(row));
  }

  close() {
    this.dialogRef.close({event: "close", data: false});
  }

  multiDelete() {

    this.is_deleting = true;
    let lenSelection = this.repCollection.selected.length;
    let count = 0;
    from(this.repCollection.selected).pipe(
      concatMap((el:any) => {
        return this.membersService.deleteChannelToEmployee(el.id)
      })
    ).subscribe({
      next: (next) => {
        console.log("next onSubmit", next);
        this.ls.requests$.next({
          value: Math.round((100 / lenSelection) * (count+1)),
          target: `Deleting Channels permissions`,
        })
        count++;
      },
      complete: () => {
        console.log("complete onSubmit");
        this.is_deleting = false;

        if (!!this.data.fromEmployees) {
          if (this.data.forPartnerWorkspace) {
            this.getChannelToEmployee({partner_company_id: this.data.employee.partner_company_id});
          } else {
            this.getChannelToEmployee({employee_id: this.data.employee.id});
          }
        } else {
          if (this.data.forPartner) {
            this.getChannelToEmployee({channel_id: this.data.channel.id, partner_company_id: this.data.partner_company_id, is_partner_employee: 1});
          } else {
            this.getChannelToEmployee({channel_id: this.data.channel.id});
          }
        }
        this.repCollection.clear();
      },
      error: (error) => {
        console.log("error onSubmit", error)
        this.is_deleting = false;
        this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
      }
    })
  }

  onSubmit() {
    this.isSubmit = true;
    let sbmtData = {...this.form.value}
    sbmtData.is_channel_content = !!sbmtData.is_channel_content ? 1 : 0;
    sbmtData.is_channel_content_upload = !!sbmtData.is_channel_content_upload ? 1 : 0;

    if (this.data.forPartnerWorkspace) {
      sbmtData.partner_company_id = this.data.employee.partner_company_id
      delete sbmtData.employee_id
    }

    if (this.data.forPartner) {
      sbmtData.company_id = this.data.partner_company_id
      sbmtData.partner_company_id = this.data.company_id
      sbmtData.partner_employee_id = sbmtData.employee_id
      delete sbmtData.employee_id
    }
    

    if (!!this.data.fromEmployees) {
      delete sbmtData.channel_id;
      let lenSelection = this.form.value.channel_id.length;
      let count = 0;
      from(this.form.value.channel_id).pipe(
        concatMap((cID:any) => {
          return this.membersService.addChannelToEmployee(Object.assign({channel_id: cID}, sbmtData), this.data.company_id)
        })
      ).subscribe({
        next: (next) => {
          console.log("next onSubmit", next);
          this.ls.requests$.next({
            value: Math.round((100 / lenSelection) * (count+1)),
            target: `Adding Channels permissions`,
          })
          count++;
        },
        complete: () => {
          console.log("complete onSubmit");
          this.isSubmit = false;

          this.form.patchValue({
            channel_id: [],
            is_channel_content: false,
            is_channel_content_upload: false
          })
          if (this.data.forPartnerWorkspace) {
            this.getChannelToEmployee({partner_company_id: this.data.employee.partner_company_id});
          } else {
            this.getChannelToEmployee({employee_id: this.data.employee.id});
          }
        },
        error: (error) => {
          console.log("error onSubmit", error)
          this.isSubmit = false;
          this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
        }
      })
    } else {
      this.attachSubscriptions(
        this.membersService.addChannelToEmployee(sbmtData, this.data.company_id).subscribe(resp => {
          this.isSubmit = false;
          this.form.patchValue({
            employee_id: '',
            is_channel_content: false,
            is_channel_content_upload: false
          })
          if (this.data.forPartner) {
            this.getChannelToEmployee({channel_id: this.data.channel.id, partner_company_id: this.data.partner_company_id, is_partner_employee: 1});
          } else {
            this.getChannelToEmployee({channel_id: this.data.channel.id});
          }
        }, error => {
          this.isSubmit = false;
          this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
        })
      )
    }
  }

  deleteChannelsToEmployee(id, i) {
    this.attachSubscriptions(
      this.membersService.deleteChannelToEmployee(id).subscribe(resp => {
        this.channelsToEmployee.splice(i, 1)
      })
    )
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
