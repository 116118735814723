import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { tap } from 'rxjs/operators';
import { DeleteAlertComponent } from 'src/app/shared/global_components/delete-alert/delete-alert.component';
import { BaseClass } from 'src/app/shared/models/base-class';
import { CompanyService } from '../../services/rest/company.service';
import { AddEditOptionComponent } from './dialogs/add-edit-option/add-edit-option.component';

@Component({
  selector: 'app-shared-options',
  templateUrl: './shared-options.component.html',
  styleUrls: ['./shared-options.component.scss']
})
export class SharedOptionsComponent extends BaseClass implements OnInit, OnDestroy {
  public accesses: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private companyService: CompanyService,
    public dialogRef: MatDialogRef<SharedOptionsComponent>,
    private dialog: MatDialog,
    private bottomSheet: MatBottomSheet,
  ) { super() }

  ngOnInit(): void {
    console.log("SharedOptionsComponent", this.data)
    this.dialogRef.addPanelClass("full_width_dialog")
    this.getAccesses();
  }

  addAccesses() {
    const dialogRef = this.dialog.open(AddEditOptionComponent, {
      disableClose: true,
      data: this.data
    });
    
    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (result.event == 'add') {
          this.getAccesses();
        }
      })
    )
  }

  editAccesses(access) {
    let editData = {...this.data}
    const dialogRef = this.dialog.open(AddEditOptionComponent, {
      disableClose: true,
      data: Object.assign(editData, {...{is_edit: true, access: access}})
    });
    
    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (result.event == 'edit') {
          this.getAccesses();
        }
      })
    )
  }

  getAccesses() {
    let filter = {
      company_id: this.data.company_id,
      task_id: this.data.task.id
    }
    this.attachSubscriptions(
      this.companyService.getAccesses(this.data.company_id, filter).subscribe(resp => {
        console.log("notes", resp);
        this.accesses = resp;

        if (this.accesses.length == 0) {
          this.addAccesses()
        }
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false});
  }

  deleteAccess(note, i) {
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: {},
        target: marker("Access")
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        this.attachSubscriptions(
          this.companyService.deleteAccess(note.id, this.data.company_id).pipe(
            tap(x => {
              console.log(x)
              this.accesses.splice(i,1);
            }),
          ).subscribe(resp => {
            console.log("END DELETE", resp)
          })
        )
      }
    });
  
  }

  ngOnDestroy() {
    this.clearSubscriptions()
  }

}
