import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReplaySubject } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { RootService } from 'src/app/shared/services/rest/root.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';

@Component({
  selector: 'app-add-edit-aimodel',
  templateUrl: './add-edit-aimodel.component.html',
  styleUrls: ['./add-edit-aimodel.component.scss']
})
export class AddEditAimodelComponent extends BaseClass implements OnInit, OnDestroy {
  public form: FormGroup;
  public isFormChange: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private companyService: CompanyService,
    private rootService: RootService,
    public dialogRef: MatDialogRef<AddEditAimodelComponent>,
    public layoutService: LayoutService
  ) { super() }

  ngOnInit(): void {
    console.log("AddEditAimodelComponent", this.data)
    if (this.data.model) {
      this.form = this.fb.group({
        name: [this.data.model.name, Validators.required],
        model_id: [this.data.model.model_id, Validators.required],
        token_price_input_1m: this.data.model.token_price_input_1m,
        token_price_output_1m: this.data.model.token_price_output_1m,
        token_price_1m: this.data.model.token_price_1m,
        is_chat: this.data.model.is_chat,
      })
    } else {
      this.form = this.fb.group({
        name: ['', Validators.required],
        model_id: ['', Validators.required],
        token_price_input_1m: '',
        token_price_output_1m: '',
        token_price_1m: '',
        is_chat: 1,
      })
    }

    this.attachSubscriptions(
      this.form.valueChanges.subscribe(() => this.isFormChange = true)
    )

    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        if (this.isFormChange) {
          this.layoutService.openBottomSheet(this.dialogRef);
        } else {
          this.close();
        }
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false});
  }

  submitForm() {
    let sendData:any = {};
    Object.keys(this.form.value).forEach(element => {
      if (element == 'type' || element == 'state') {
        sendData[this.form.value[element]] = 1;
      } else {
        sendData[element] = this.form.value[element]
      }
    })

    if (this.data.model) {
      this.attachSubscriptions(
        this.rootService.editAiModel(this.data.model.id, sendData).subscribe(resp => {
          this.dialogRef.close({event: "close", data: resp});
        }, error => {
          console.log('error', error)
          this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
        })
      )
    } else {
      this.attachSubscriptions(
        this.rootService.addAiModel(sendData).subscribe(resp => {
          this.dialogRef.close({event: "close", data: resp});
        }, error => {
          console.log('error', error)
          this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
        })
      )
    }
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
