<mat-dialog-title class="dialog_title">
    <span>
        {{ "New Card" | translate }}
    </span>
    <div class="for_space users_select">
        <span>{{ "Card belongs to workspace:" | translate }}</span>
        <mat-chip-list [multiple]="false" [selected]="false">
            <mat-chip class="chip_user" style="padding-left: 0 !important;" [value]="selectedCompany.id">
                <app-user [class]="'user_div'" [companyEmployee]="selectedCompany" [imgRoute]="data?.imgRoute" [host]="data?.host" [company]="data?.company"></app-user>
            </mat-chip>
        </mat-chip-list>
        <span (click)="change()" *ngIf="!data.basedVideos && !data.file_ids">
            {{ "Change" | translate }}
        </span>
        <span (click)="toggleForPartner()" *ngIf="!!data.f_c_id && !!partners && !!partners.length">
            {{ (data.f_c_id != selectedCompany.id ? "For partner workspace" : "For active workspace") | translate }}
        </span>
    </div>
    <!-- <button mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
    </button> -->
</mat-dialog-title>
<mat-dialog-content class="mat-typography">
    <div class="container form_wrapper empl_cr_task">
        <ng-container *ngIf="!templatesIsActive; else elseTemplateTempatesActiveForm">
            <mat-horizontal-stepper labelPosition="bottom" [selectedIndex]="selectedIndex" style="background-color: transparent;" linear #stepper (selectionChange)="selectionChange($event)">
                <mat-step>
                    <ng-template matStepLabel>{{ 'Add Card' | translate }}</ng-template>
                    
                    <form [formGroup]="form" class="form_with_valids form_with_pads">
    
                        <div class="white_block">
                            <div class="white_block_title">
                                {{ "Card details" | translate }}
                            </div>
                        
                            <mat-form-field appearance="standard" class="full_width_form">
                                <ng-container *ngIf="!form.get('name').valid && submited">
                                    <div class="req_field"></div>
                                    <div class="req_label">
                                        <div class="angle"></div>
                                        <span>
                                            {{ "You need to write here" | translate }}
                                        </span>
                                    </div>
                                </ng-container>
                                <mat-label>{{ 'Card title' | translate }}</mat-label>
                                <input formControlName="name" matInput placeholder="{{ 'Name' | translate }}">
                            </mat-form-field>
                        </div>
    
                        <div class="white_block">
                            <div class="white_block_title">
                                {{ "Card identification" | translate }}
                            </div>
                        
                            <div class="small_chips" *ngIf="groups?.length > 0">
                                <label>{{ "Group" | translate }}: </label>
                                <mat-chip-list [multiple]="false" selectable formControlName="group_id">
                                    <ng-container *ngFor="let group of groups; let i = index">
                                        <mat-chip class="small_chip" (click)="selectGroup(group)" [value]="group?.id">
                                            {{ group.name | translate }}
                                        </mat-chip>
                                        <mat-chip *ngIf="i == groups?.length - 1 && i > 6" class="small_chip more" (click)="groupIdContainer.open()">
                                            {{ "More..." | translate }}
                                        </mat-chip>
                                    </ng-container>
                                    
                                </mat-chip-list>
                            </div>
                
                            <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                                <mat-select #groupIdContainer formControlName="group_id" (selectionChange)="selectGroup($event)">
                                    <mat-option>
                                        <ngx-mat-select-search 
                                        placeholderLabel="{{ 'Search' | translate }}" 
                                        noEntriesFoundLabel="{{ 'no matching group found' | translate }}"
                                        [formControl]="groupMoreControl"></ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option *ngFor="let group of groups$ | async" [value]="group.id">
                                        {{ group.name | translate }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            
                            <div class="small_chips" *ngIf="statuses?.length > 0">
                                <ng-container *ngIf="!form.get('status_id').valid && submited">
                                    <div class="req_field"></div>
                                    <div class="req_label">
                                        <div class="angle"></div>
                                        <span>
                                            {{ "You need to choose a value" | translate }}
                                        </span>
                                    </div>
                                </ng-container>
                                <label>{{ "Card type" | translate }}: </label>
                                <mat-chip-list [multiple]="false" selectable formControlName="status_id">
                                    <ng-container *ngFor="let status of statuses; let i = index">
                                        <mat-chip class="small_chip" (click)="selectType(status)" [value]="status?.id">
                                            {{ status.name | translate }}
                                        </mat-chip>
                                        <mat-chip *ngIf="i == statuses?.length - 1 && i > 6" class="small_chip more" (click)="typesContainer.open()">
                                            {{ "More..." | translate }}
                                        </mat-chip>
                                    </ng-container>
                                </mat-chip-list>
                            </div>

                            <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                                <mat-select #typesContainer formControlName="status_id" (selectionChange)="selectType($event)">
                                    <mat-option>
                                        <ngx-mat-select-search 
                                        placeholderLabel="{{ 'Search' | translate }}" 
                                        noEntriesFoundLabel="{{ 'no matching type found' | translate }}"
                                        [formControl]="statusesMoreControl"></ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option *ngFor="let status of statuses$ | async" [value]="status.id">
                                        {{ status.name | translate }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
    
    
                        <div class="white_block white_expanded">
                            <div class="d-f-c" (click)="openRelations()">
                                <div class="white_block_title">
                                    {{ "Assign relations to other card(s)" | translate }}
                                </div>
            
                                <button mat-icon-button>
                                    <mat-icon *ngIf="relations">expand_less</mat-icon>
                                    <mat-icon *ngIf="!relations">expand_more</mat-icon>
                                </button>
                            </div>
                            <div class="white_inside" [ngClass]="{'open': relations}">
                                <div class="search_type">
                                    <mat-button-toggle-group appearance="legacy" [formControl]="typeOfSearchControl">
                                        <mat-button-toggle value="Everywhere">Everywhere</mat-button-toggle>
                                        <mat-button-toggle value="Custom ID">Custom ID</mat-button-toggle>
                                        <mat-button-toggle value="Internal ID">Internal ID</mat-button-toggle>
                                        <mat-button-toggle value="Global ID">Global ID</mat-button-toggle>
                                        <mat-button-toggle value="Task Name">Card Name</mat-button-toggle>
                                    </mat-button-toggle-group>
            
                                    <mat-form-field appearance="legacy" class="ml-1" *ngIf="['Everywhere', 'Custom ID', 'Task Name'].includes(typeOfSearchControl.value)">
                                        <mat-label>{{ "Group" | translate }}</mat-label>
                                        <mat-select #groupIdContainer [formControl]="groupOfSearchControl">
                                            <mat-option>
                                                <ngx-mat-select-search 
                                                placeholderLabel="{{ 'Search' | translate }}" 
                                                noEntriesFoundLabel="{{ 'no matching group found' | translate }}"
                                                [formControl]="groupMoreControl"></ngx-mat-select-search>
                                            </mat-option>
                                            <mat-option [value]="''">
                                                {{ 'All' | translate }}
                                            </mat-option>
                                            <mat-option *ngFor="let group of groups$ | async" [value]="group.id">
                                                {{ group.name | translate }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
            
                                <ng-container formGroupName="consist_of_task_id">
                                    <mat-form-field appearance="standard" class="full_width_form">
                                        <mat-label>{{ "Consist of" | translate }}</mat-label>
            
                                        <div class="chips-boundary consist_boundary">
                                            <mat-chip-list class="sorted_chips without_ordered" #consistOfChipList formControlName="add">
                                                <div class="chips_sorter_container" cdkDropListGroup #consistGroup>
                                                  
                                                    <div class="chips_drag_placeholder" cdkDropList (cdkDropListEntered)="entered($event, consistGroup, consistPlaceholder)" (cdkDropListDropped)="dropped(consistGroup, consistPlaceholder, form.get('consist_of_task_id').get('add').value)" #consistPlaceholder></div>
            
                                                    <div *ngFor="let id of form.get('consist_of_task_id').get('add').value"  
                                                    cdkDropList
                                                    class="sorted_chips_drop_list"
                                                    (cdkDropListEntered)="entered($event, consistGroup, consistPlaceholder)"
                                                    (cdkDropListDropped)="dropped(consistGroup, consistPlaceholder, form.get('consist_of_task_id').get('add').value)">
                                                        <div cdkDrag cdkDragBoundary=".consist_boundary" class="sorted_chip" (cdkDragReleased)="dragReleased(consistPlaceholder, consistDragPlaceholder)" (contextmenu)="openContext($event, {'id': id, 'form': form, 'control': 'consist_of_task_id'}); $event.preventDefault(); $event.stopPropagation();">
                                                            <div class="sorted_chip_custom_placeholder" #consistDragPlaceholder *cdkDragPlaceholder></div>
                                                            <mat-chip [removable]="true" color="primary" (removed)="onRemoved(id, 'consist_of_task_id')">
                                                                <ng-container *ngIf="findTask(id)">
                                                                    {{ findTask(id).name.length > 25 ? (findTask(id).name | slice:0:25) + " ..." : findTask(id).name }} ({{findTask(id)?.custom_id}})
                                                                </ng-container>
                                                                <mat-icon matChipRemove>cancel</mat-icon>
                                                            </mat-chip>
                                                        </div>
            
                                                    </div>
            
                                                    <input class="auto_complete_input"
                                                    placeholder="{{ 'Search' | translate }}"
                                                    #consistOfInput
                                                    [formControl]="consistOfControl"
                                                    [matAutocomplete]="consistOfAuto"
                                                    [matChipInputFor]="consistOfChipList"
                                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
                                                    
                                                </div>
                                            </mat-chip-list>
                                        </div>
            
                                        <mat-autocomplete (optionsScroll)="onScroll('consistOfControl')" autoActiveFirstOption #consistOfAuto="matAutocomplete" (optionSelected)="selected($event, consistOfInput, 'consist_of_task_id')">
                                            <ng-container *ngFor="let task of tasks$ | async">
                                                <mat-option *ngIf="!form.get('consist_of_task_id').get('add').value.includes(task.id) && (['Everywhere', 'Custom ID', 'Task Name'].includes(typeOfSearchControl.value) ? (groupOfSearchControl.value == task.group_id || groupOfSearchControl.value == '') : true)" [value]="task.id">
                                                    <div class="task_ids_wrp_out">
                                                        <div class="task_ids_wrp"> 
                                                            <span class="text_one_line">
                                                                {{ task.name }}
                                                            </span>
                                                            <div class="task_ids">
                                                                {{task.custom_id}} 
                                                                <span>
                                                                    <div>{{task.internal_id}}</div>
                                                                    <div>{{task.id}}</div>
                                                                </span>
                                                            </div>
                                                        </div>
            
                                                        <div class="group text_one_line">
                                                            {{task?.group?.name}}
                                                        </div>
                                                    </div>
                                                </mat-option>
                                            </ng-container>
                                            
                                        </mat-autocomplete>
                                    </mat-form-field>
                                </ng-container>
                                
                                <ng-container formGroupName="part_of_task_id">
                                    <mat-form-field appearance="standard" class="full_width_form">
                                        <mat-label>{{ "Part of" | translate }}</mat-label>
            
                                        <div class="chips-boundary part_boundary">
                                            <mat-chip-list class="sorted_chips without_ordered" #partOfChipList formControlName="add">
                                                <div class="chips_sorter_container" cdkDropListGroup #partGroup>
                                                
                                                    <div class="chips_drag_placeholder" cdkDropList (cdkDropListEntered)="entered($event, partGroup, partPlaceholder)" (cdkDropListDropped)="dropped(partGroup, partPlaceholder, form.get('part_of_task_id').get('add').value)" #partPlaceholder></div>
            
                                                    <div *ngFor="let id of form.get('part_of_task_id').get('add').value"  
                                                    cdkDropList
                                                    class="sorted_chips_drop_list"
                                                    (cdkDropListEntered)="entered($event, partGroup, partPlaceholder)"
                                                    (cdkDropListDropped)="dropped(partGroup, partPlaceholder, form.get('part_of_task_id').get('add').value)">
                                                        <div cdkDrag cdkDragBoundary=".part_boundary" class="sorted_chip" (cdkDragReleased)="dragReleased(partPlaceholder, partDragPlaceholder)" (contextmenu)="openContext($event, {'id': id, 'form': form, 'control': 'part_of_task_id'}); $event.preventDefault(); $event.stopPropagation();">
                                                        <div class="sorted_chip_custom_placeholder" #partDragPlaceholder *cdkDragPlaceholder></div>
                                                        <mat-chip [removable]="true" color="primary" (removed)="onRemoved(id, 'part_of_task_id')">
                                                            <ng-container *ngIf="findTask(id)">
                                                                {{ findTask(id).name.length > 25 ? (findTask(id).name | slice:0:25) + " ..." : findTask(id).name }} ({{findTask(id)?.custom_id}})
                                                            </ng-container>
                                                            <mat-icon matChipRemove>cancel</mat-icon>
                                                        </mat-chip>
                                                        </div>
            
                                                    </div>
            
                                                    <input class="auto_complete_input"
                                                    placeholder="{{ 'Search' | translate }}"
                                                    #partOfInput
                                                    [formControl]="partOfControl"
                                                    [matAutocomplete]="partOfAuto"
                                                    [matChipInputFor]="partOfChipList"
                                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
                                                    
                                                </div>
                                            </mat-chip-list>
                                        </div>
            
                                        <mat-autocomplete (optionsScroll)="onScroll('partOfControl')" autoActiveFirstOption #partOfAuto="matAutocomplete" (optionSelected)="selected($event, partOfInput, 'part_of_task_id')">
                                            <ng-container *ngFor="let task of tasks$ | async">
                                                <mat-option *ngIf="!form.get('part_of_task_id').get('add').value.includes(task.id) && (['Everywhere', 'Custom ID', 'Task Name'].includes(typeOfSearchControl.value) ? (groupOfSearchControl.value == task.group_id || groupOfSearchControl.value == '') : true)" [value]="task.id">
                                                    <div class="task_ids_wrp_out">
                                                        <div class="task_ids_wrp"> 
                                                            <span class="text_one_line">
                                                                {{ task.name }}
                                                            </span>
                                                            <div class="task_ids">
                                                                {{task.custom_id}} 
                                                                <span>
                                                                    <div>{{task.internal_id}}</div>
                                                                    <div>{{task.id}}</div>
                                                                </span>
                                                            </div>
                                                        </div>
            
                                                        <div class="group text_one_line">
                                                            {{task?.group?.name}}
                                                        </div>
                                                    </div>
                                                </mat-option>
                                            </ng-container>
                                            
                                        </mat-autocomplete>
                                    </mat-form-field>
                                </ng-container>
            
                                <ng-container formGroupName="related_task_id">
                                    <mat-form-field appearance="standard" class="full_width_form">
                                        <mat-label>{{ "Related" | translate }}</mat-label>
            
                                        <div class="chips-boundary related_boundary">
                                            <mat-chip-list class="sorted_chips without_ordered" #relatedOfChipList formControlName="add">
                                                <div class="chips_sorter_container" cdkDropListGroup #relatedGroup>
                                                  
                                                    <div class="chips_drag_placeholder" cdkDropList (cdkDropListEntered)="entered($event, relatedGroup, relatedPlaceholder)" (cdkDropListDropped)="dropped(relatedGroup, relatedPlaceholder, form.get('related_task_id').get('add').value)" #relatedPlaceholder></div>
            
                                                    <div *ngFor="let id of form.get('related_task_id').get('add').value"  
                                                    cdkDropList
                                                    class="sorted_chips_drop_list"
                                                    (cdkDropListEntered)="entered($event, relatedGroup, relatedPlaceholder)"
                                                    (cdkDropListDropped)="dropped(relatedGroup, relatedPlaceholder, form.get('related_task_id').get('add').value)">
                                                        <div cdkDrag cdkDragBoundary=".related_boundary" class="sorted_chip" (cdkDragReleased)="dragReleased(relatedPlaceholder, relatedDragPlaceholder)" (contextmenu)="openContext($event, {'id': id, 'form': form, 'control': 'related_task_id'}); $event.preventDefault(); $event.stopPropagation();">
                                                            <div class="sorted_chip_custom_placeholder" #relatedDragPlaceholder *cdkDragPlaceholder></div>
                                                            <mat-chip [removable]="true" color="primary" (removed)="onRemoved(id, 'related_task_id')">
                                                                <ng-container *ngIf="findTask(id)">
                                                                    {{ findTask(id).name.length > 25 ? (findTask(id).name | slice:0:25) + " ..." : findTask(id).name }} ({{findTask(id)?.custom_id}})
                                                                </ng-container>
                                                                <mat-icon matChipRemove>cancel</mat-icon>
                                                            </mat-chip>
                                                        </div>
            
                                                    </div>
            
                                                    <input class="auto_complete_input"
                                                    placeholder="{{ 'Search' | translate }}"
                                                    #relatedOfInput
                                                    [formControl]="relativeControl"
                                                    [matAutocomplete]="relatedOfAuto"
                                                    [matChipInputFor]="relatedOfChipList"
                                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
                                                    
                                                </div>
                                            </mat-chip-list>
                                        </div>
            
                                        <mat-autocomplete (optionsScroll)="onScroll('relativeControl')" autoActiveFirstOption #relatedOfAuto="matAutocomplete" (optionSelected)="selected($event, relatedOfInput, 'related_task_id')">
                                            <ng-container *ngFor="let task of tasks$ | async">
                                                <mat-option *ngIf="!form.get('related_task_id').get('add').value.includes(task.id) && (['Everywhere', 'Custom ID', 'Task Name'].includes(typeOfSearchControl.value) ? (groupOfSearchControl.value == task.group_id || groupOfSearchControl.value == '') : true)" [value]="task.id">
                                                    <div class="task_ids_wrp_out">
                                                        <div class="task_ids_wrp"> 
                                                            <span class="text_one_line">
                                                                {{ task.name }}
                                                            </span>
                                                            <div class="task_ids">
                                                                {{task.custom_id}} 
                                                                <span>
                                                                    <div>{{task.internal_id}}</div>
                                                                    <div>{{task.id}}</div>
                                                                </span>
                                                            </div>
                                                        </div>
            
                                                        <div class="group text_one_line">
                                                            {{task?.group?.name}}
                                                        </div>
                                                    </div>
                                                </mat-option>
                                            </ng-container>
                                            
                                        </mat-autocomplete>
                                    </mat-form-field>
                                </ng-container>
                            </div>
                        </div>
                    </form>
    
    
    
                </mat-step>
                <mat-step>
                    <ng-template matStepLabel>{{ 'Add work' | translate }}</ng-template>
                    <form [formGroup]="form" class="form_with_valids form_with_pads" style="margin-top: 22px;">
                        <ng-container formGroupName="task_operation">
                            <div class="white_block">
                                <div class="white_block_title">
                                    {{ "Job identification" | translate }}
                                </div>

                                <ng-container *ngIf="!!form.get('task_operation').get('employee_id')">
                                    <div class="users_select">
                                        <label>{{ "Choose an employee" | translate }}: </label>
                                        <mat-chip-list [multiple]="false" selectable formControlName="employee_id">
                                            <mat-chip style="padding: 0 !important;" class="chip_user clear" (click)="toggleChip({id: 0})" [value]="0">
                                                <mat-icon style="opacity: .7;">close</mat-icon>
                                            </mat-chip>
                                            <ng-container *ngFor="let employee of employees; let i = index">
                                                <mat-chip class="chip_user" (click)="toggleChip(employee)" [value]="employee.id">
                                                    <app-user [class]="'user_div'" [employee]="employee"></app-user>
                                                </mat-chip>
                                                <mat-chip *ngIf="i == employees.length - 1 && i > 6" class="chip_user more" (click)="employeeIdContainer.open()">
                                                    {{ "more" | translate }}
                                                </mat-chip>
                                            </ng-container>
                                        </mat-chip-list>
                                    </div>
                        
                                    <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                                        <mat-select #employeeIdContainer formControlName="employee_id" (selectionChange)="toggleChip($event)">
                                            <mat-option>
                                                <ngx-mat-select-search 
                                                placeholderLabel="{{ 'Search' | translate }}" 
                                                noEntriesFoundLabel="{{ 'no matching card found' | translate }}"
                                                [formControl]="employeeMoreControl"></ngx-mat-select-search>
                                            </mat-option>
                                            <mat-option *ngFor="let employee of employees$ | async" [value]="employee.id">
                                                <app-user [class]="'user_div'" [employee]="employee"></app-user>
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>

                                    <p>
                                        {{ "Choose member role" | translate }}:
                                        <mat-button-toggle-group appearance="legacy" class="ml-1" formControlName="is_manager" [value]="form.get('task_operation').get('is_manager').value">
                                            <mat-button-toggle [value]="1">{{ "Executor" | translate }}</mat-button-toggle>
                                            <mat-button-toggle [value]="0">{{ "Assistant" | translate }}</mat-button-toggle>
                                        </mat-button-toggle-group>
                                    </p>
                                </ng-container>
    
                                <mat-form-field appearance="standard" class="full_width_form">
                                    <mat-label>{{ 'My notes on this job' | translate }}</mat-label>
                                    <textarea formControlName="employee_comment" matInput placeholder="{{ 'My notes on this job' | translate }}"
                                    cdkTextareaAutosize
                                    #autosize="cdkTextareaAutosize"
                                    cdkAutosizeMinRows="1"
                                    cdkAutosizeMaxRows="5"></textarea>
                                </mat-form-field>
    
                                <div class="small_chips" *ngIf="operations?.length > 0">
                                    <ng-container *ngIf="!form.get('task_operation').get('operation_id').valid && submitedWork">
                                        <div class="req_field"></div>
                                        <div class="req_label">
                                            <div class="angle"></div>
                                            <span>
                                                {{ "You need to choose a value" | translate }}
                                            </span>
                                        </div>
                                    </ng-container>
                                    <label>{{ "Job type" | translate }}: </label>
                                    <mat-chip-list [multiple]="false" selectable formControlName="operation_id">
                                        <ng-container *ngFor="let operation of operations; let i = index">
                                            <mat-chip class="small_chip" (click)="selectWorkType(operation)" [value]="operation?.id">
                                                {{ operation.name | translate }}
                                            </mat-chip>
                                            <mat-chip *ngIf="i == operations?.length - 1 && i > 6" class="small_chip more" (click)="operationIdContainer.open()">
                                                {{ "More..." | translate }}
                                            </mat-chip>
                                        </ng-container>
                                        
                                    </mat-chip-list>
                                </div>
                    
                                <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                                    <mat-select #operationIdContainer formControlName="operation_id" (selectionChange)="selectWorkType($event)">
                                        <mat-option>
                                            <ngx-mat-select-search 
                                            placeholderLabel="{{ 'Search' | translate }}" 
                                            noEntriesFoundLabel="{{ 'no matching card found' | translate }}"
                                            [formControl]="operationMoreControl"></ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option *ngFor="let operation of operations$ | async" [value]="operation.id">
                                            {{ operation.name | translate }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                    
                                <div class="small_chips" *ngIf="operationStatuses?.length > 0">
                                    <ng-container *ngIf="!form.get('task_operation').get('status_id').valid && submitedWork">
                                        <div class="req_field"></div>
                                        <div class="req_label">
                                            <div class="angle"></div>
                                            <span>
                                                {{ "You need to choose a value" | translate }}
                                            </span>
                                        </div>
                                    </ng-container>
                                    <label>{{ "Status" | translate }}: </label>
                                    <mat-chip-list [multiple]="false" selectable formControlName="status_id">
                                        <ng-container *ngFor="let status of operationStatuses; let i = index">
                                            <mat-chip class="small_chip" (click)="selectStatus(status)" [value]="status?.id" [ngClass]="{
                                                'first': status.id == '1',
                                                'second': status.id == '2',
                                                'third': status.id == '3',
                                                'fours': status.id == '4',
                                                'fives': status.id == '5',
                                                'sixs': status.id == '6',
                                                'nine': status.id == '97',
                                                'seven': status.id == '98',
                                                'eight': status.id == '99'}">
                                                {{ status.name | translate }}
                                            </mat-chip>
                                            <mat-chip *ngIf="i == operationStatuses?.length - 1 && i > 6" class="small_chip more" (click)="showMoreStatuses()">
                                                {{ "More..." | translate }}
                                            </mat-chip>
                                        </ng-container>
                                        
                                    </mat-chip-list>
                                </div>
                            </div>
                            <div class="white_block">
                                <div class="white_block_title">
                                    {{ "Job details" | translate }}
                                </div>
                    
                                <mat-form-field appearance="standard" class="full_width_form" *ngIf="!!form.get('task_operation').get('completed_at')">
                                    <ng-container *ngIf="form.get('task_operation').get('status_id').value == 3 && submitedWork && !form.get('task_operation').get('completed_at').valid">
                                        <div class="req_field"></div>
                                        <div class="req_label">
                                            <div class="angle"></div>
                                            <span>
                                                {{ "You need to choose a value" | translate }}
                                            </span>
                                        </div>
                                    </ng-container>
                                    <mat-label>{{ "Date I finished working on this job" | translate }}</mat-label>
                                    <input matInput [matDatepicker]="dp" formControlName="completed_at">
                                    <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
                                    <mat-datepicker #dp></mat-datepicker>
                                </mat-form-field>
    
                                <mat-form-field appearance="standard" class="full_width_form" *ngIf="form.get('task_operation').get('price')">
                                    <mat-label>{{ 'Price I charge for this job' | translate }}</mat-label>
                                    <input formControlName="price" matInput type="number" placeholder="{{ 'Price I charge for this job' | translate }}">
                                    <div class="cur_suffix" matSuffix>{{ getCurrency(data?.company?.employees[0]?.currency_id)?.iso_name }}</div>
                                </mat-form-field>
                        
                                <mat-form-field *ngIf="form.get('task_operation').get('rate')" appearance="standard" class="full_width_form">
                                    <mat-label>{{ 'Points I charge for this job' | translate }}</mat-label>
                                    <input formControlName="rate" matInput type="number" placeholder="{{ 'Points I charge for this job' | translate }}">
                                </mat-form-field>
    
                            </div>
    
                        </ng-container>
    
                        <div class="white_block" *ngIf="form.get('task_operation').get('status_id').value == 3">
                            <div class="white_block_title">
                                {{ "Files for approval" | translate }}
                            </div>
    
                            <div>
                                <div class="files">
                                    <!-- <div class="files_head">
                                        {{ "Files for approval" | translate }}
                                    </div> -->
                                    <div class="files_body" appDnd (fileDropped)="onFileDropped($event)" [ngClass]="{'has_files': filesArray.length != 0}">
                                        
                                        <mat-icon class="drop_icon">file_upload</mat-icon>
                                        <div class="to_approve_no_files" *ngIf="filesArray.length == 0">
                                            <div class="no_in">
                                                <div>{{ "Drag files here" | translate }}</div>
                                                <div>{{ "or" | translate }}</div>
                                                <button mat-raised-button (click)="uploader.click()">
                                                    {{ "Browse" | translate}}
                                                </button>
                                            </div>
                                        </div>
        
                                        <div class="file" *ngFor="let file of filesArray; let i = index;">
                                            <ng-container [ngSwitch]="understandFileType(file.type)">
                                                <ng-container *ngSwitchCase="'video'">
                                                    <mat-icon class="file_img_icon" style="color: #3f51b5">
                                                        movie
                                                    </mat-icon>
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'pdf'">
                                                    <div class="pdf">
                                                        <svg height="800px" width="800px" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
                                                            viewBox="0 0 303.188 303.188" xml:space="preserve">
                                                            <g>
                                                                <polygon style="fill:#E8E8E8;" points="219.821,0 32.842,0 32.842,303.188 270.346,303.188 270.346,50.525 	"/>
                                                                <path style="fill:#FB3449;" d="M230.013,149.935c-3.643-6.493-16.231-8.533-22.006-9.451c-4.552-0.724-9.199-0.94-13.803-0.936
                                                                    c-3.615-0.024-7.177,0.154-10.693,0.354c-1.296,0.087-2.579,0.199-3.861,0.31c-1.314-1.36-2.584-2.765-3.813-4.202
                                                                    c-7.82-9.257-14.134-19.755-19.279-30.664c1.366-5.271,2.459-10.772,3.119-16.485c1.205-10.427,1.619-22.31-2.288-32.251
                                                                    c-1.349-3.431-4.946-7.608-9.096-5.528c-4.771,2.392-6.113,9.169-6.502,13.973c-0.313,3.883-0.094,7.776,0.558,11.594
                                                                    c0.664,3.844,1.733,7.494,2.897,11.139c1.086,3.342,2.283,6.658,3.588,9.943c-0.828,2.586-1.707,5.127-2.63,7.603
                                                                    c-2.152,5.643-4.479,11.004-6.717,16.161c-1.18,2.557-2.335,5.06-3.465,7.507c-3.576,7.855-7.458,15.566-11.815,23.02
                                                                    c-10.163,3.585-19.283,7.741-26.857,12.625c-4.063,2.625-7.652,5.476-10.641,8.603c-2.822,2.952-5.69,6.783-5.941,11.024
                                                                    c-0.141,2.394,0.807,4.717,2.768,6.137c2.697,2.015,6.271,1.881,9.4,1.225c10.25-2.15,18.121-10.961,24.824-18.387
                                                                    c4.617-5.115,9.872-11.61,15.369-19.465c0.012-0.018,0.024-0.036,0.037-0.054c9.428-2.923,19.689-5.391,30.579-7.205
                                                                    c4.975-0.825,10.082-1.5,15.291-1.974c3.663,3.431,7.621,6.555,11.939,9.164c3.363,2.069,6.94,3.816,10.684,5.119
                                                                    c3.786,1.237,7.595,2.247,11.528,2.886c1.986,0.284,4.017,0.413,6.092,0.335c4.631-0.175,11.278-1.951,11.714-7.57
                                                                    C231.127,152.765,230.756,151.257,230.013,149.935z M119.144,160.245c-2.169,3.36-4.261,6.382-6.232,9.041
                                                                    c-4.827,6.568-10.34,14.369-18.322,17.286c-1.516,0.554-3.512,1.126-5.616,1.002c-1.874-0.11-3.722-0.937-3.637-3.065
                                                                    c0.042-1.114,0.587-2.535,1.423-3.931c0.915-1.531,2.048-2.935,3.275-4.226c2.629-2.762,5.953-5.439,9.777-7.918
                                                                    c5.865-3.805,12.867-7.23,20.672-10.286C120.035,158.858,119.587,159.564,119.144,160.245z M146.366,75.985
                                                                    c-0.602-3.514-0.693-7.077-0.323-10.503c0.184-1.713,0.533-3.385,1.038-4.952c0.428-1.33,1.352-4.576,2.826-4.993
                                                                    c2.43-0.688,3.177,4.529,3.452,6.005c1.566,8.396,0.186,17.733-1.693,25.969c-0.299,1.31-0.632,2.599-0.973,3.883
                                                                    c-0.582-1.601-1.137-3.207-1.648-4.821C147.945,83.048,146.939,79.482,146.366,75.985z M163.049,142.265
                                                                    c-9.13,1.48-17.815,3.419-25.979,5.708c0.983-0.275,5.475-8.788,6.477-10.555c4.721-8.315,8.583-17.042,11.358-26.197
                                                                    c4.9,9.691,10.847,18.962,18.153,27.214c0.673,0.749,1.357,1.489,2.053,2.22C171.017,141.096,166.988,141.633,163.049,142.265z
                                                                    M224.793,153.959c-0.334,1.805-4.189,2.837-5.988,3.121c-5.316,0.836-10.94,0.167-16.028-1.542
                                                                    c-3.491-1.172-6.858-2.768-10.057-4.688c-3.18-1.921-6.155-4.181-8.936-6.673c3.429-0.206,6.9-0.341,10.388-0.275
                                                                    c3.488,0.035,7.003,0.211,10.475,0.664c6.511,0.726,13.807,2.961,18.932,7.186C224.588,152.585,224.91,153.321,224.793,153.959z"/>
                                                                <polygon style="fill:#FB3449;" points="227.64,25.263 32.842,25.263 32.842,0 219.821,0 	"/>
                                                                <g>
                                                                    <path style="fill:#A4A9AD;" d="M126.841,241.152c0,5.361-1.58,9.501-4.742,12.421c-3.162,2.921-7.652,4.381-13.472,4.381h-3.643
                                                                        v15.917H92.022v-47.979h16.606c6.06,0,10.611,1.324,13.652,3.971C125.321,232.51,126.841,236.273,126.841,241.152z
                                                                        M104.985,247.387h2.363c1.947,0,3.495-0.546,4.644-1.641c1.149-1.094,1.723-2.604,1.723-4.529c0-3.238-1.794-4.857-5.382-4.857
                                                                        h-3.348C104.985,236.36,104.985,247.387,104.985,247.387z"/>
                                                                    <path style="fill:#A4A9AD;" d="M175.215,248.864c0,8.007-2.205,14.177-6.613,18.509s-10.606,6.498-18.591,6.498h-15.523v-47.979
                                                                        h16.606c7.701,0,13.646,1.969,17.836,5.907C173.119,235.737,175.215,241.426,175.215,248.864z M161.76,249.324
                                                                        c0-4.398-0.87-7.657-2.609-9.78c-1.739-2.122-4.381-3.183-7.926-3.183h-3.773v26.877h2.888c3.939,0,6.826-1.143,8.664-3.43
                                                                        C160.841,257.523,161.76,254.028,161.76,249.324z"/>
                                                                    <path style="fill:#A4A9AD;" d="M196.579,273.871h-12.766v-47.979h28.355v10.403h-15.589v9.156h14.374v10.403h-14.374
                                                                        L196.579,273.871L196.579,273.871z"/>
                                                                </g>
                                                                <polygon style="fill:#D1D3D3;" points="219.821,50.525 270.346,50.525 219.821,0 	"/>
                                                            </g>
                                                        </svg>
                                                    </div>
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'image'">
                                                    <img [src]="file.src" alt="">
                                                </ng-container>
                                                <ng-container *ngSwitchDefault>
                                                    <mat-icon class="file_img_icon" style="color: #3f51b5">
                                                        insert_drive_file
                                                    </mat-icon>
                                                </ng-container>
                                            </ng-container>
                                            
                            
                                            <div class="hover_info">
                            
                                                <div class="hover_btn delete_btn" (click)="deleteFile(i)">
                                                    <mat-icon>delete</mat-icon>
                                                    <span>
                                                        {{ "Delete" | translate }}
                                                    </span>
                                                </div>
                            
                                                <!-- <div class="hover_btn" *ngIf="!file.dontCompress" [ngClass]="{'active': file.is_compress}" (click)="toggleCompress(file)">
                                                    <mat-icon>compress</mat-icon>
                                                    <span>
                                                        {{ "Compress" | translate }}
                                                    </span>
                                                </div> -->
                            
                                            </div>
                                        </div>
                                    </div>
                                </div>
        
                                <input 
                                    hidden 
                                    type="file" 
                                    #uploader
                                    multiple
                                    (change)="uploadFile($event)"
                                />
                            </div>
                        </div>
    
    
                    </form>
                </mat-step>
            </mat-horizontal-stepper>
        </ng-container>
        <ng-template #elseTemplateTempatesActiveForm>
            <div class="scenarios_list">
                <!-- <mat-list role="list" class="job_types">
                    <mat-list-item *ngFor="let tmpl of taskTemplates; let i = index;" (click)="selectTmpl(tmpl)" role="listitem" class="company_item">
                        <div class="type_name">
                            {{ tmpl?.name }}
                        </div>
                    </mat-list-item>

                </mat-list> -->

                <div class="template_item" (click)="selectTmpl(tmpl)" *ngFor="let tmpl of taskTemplates;">
                    <div class="type_name">
                        {{ tmpl?.name }}
                    </div>
                    <div class="tmpl_settings" *ngIf="tmpl.active">

                        <div class="form_with_valids full_width">
                            <mat-form-field appearance="standard" class="full_width_form">
                                <ng-container *ngIf="tmpl.template_data.name == '' && submitedTmpl">
                                    <div class="req_field"></div>
                                    <div class="req_label">
                                        <div class="angle"></div>
                                        <span>
                                            {{ "You need to write here" | translate }}
                                        </span>
                                    </div>
                                </ng-container>
                                <mat-label>{{ 'Card title' | translate }}</mat-label>
                                <input [(ngModel)]="tmpl.template_data.name" matInput placeholder="{{ 'Name' | translate }}">
                            </mat-form-field>
                        </div>

                        <button class="ml-1 d-f-btn" (click)="createTaskByTemplate(tmpl)" mat-raised-button cdkFocusInitial color="primary" [disabled]="!!isSubmitTmpl">
                            <mat-spinner class="spin_16 mr-1" *ngIf="isSubmitTmpl"></mat-spinner>  
                            {{ "Create card" | translate }}
                        </button>
                    </div>
                </div>

                <form [formGroup]="templateForm">

                    <div class="white_block white_expanded no_m_l_r">
                        <div class="d-f-c" (click)="openRelations()">
                            <div class="white_block_title">
                                {{ "Assign relations to other card(s)" | translate }}
                            </div>
        
                            <button mat-icon-button>
                                <mat-icon *ngIf="relations">expand_less</mat-icon>
                                <mat-icon *ngIf="!relations">expand_more</mat-icon>
                            </button>
                        </div>
                        <div class="white_inside" [ngClass]="{'open': relations}">
                            <div class="search_type">
                                <mat-button-toggle-group appearance="legacy" [formControl]="typeOfSearchControl">
                                    <mat-button-toggle value="Everywhere">Everywhere</mat-button-toggle>
                                    <mat-button-toggle value="Custom ID">Custom ID</mat-button-toggle>
                                    <mat-button-toggle value="Internal ID">Internal ID</mat-button-toggle>
                                    <mat-button-toggle value="Global ID">Global ID</mat-button-toggle>
                                    <mat-button-toggle value="Task Name">Card Name</mat-button-toggle>
                                </mat-button-toggle-group>
        
                                <mat-form-field appearance="legacy" class="ml-1" *ngIf="['Everywhere', 'Custom ID', 'Task Name'].includes(typeOfSearchControl.value)">
                                    <mat-label>{{ "Group" | translate }}</mat-label>
                                    <mat-select #groupIdContainer [formControl]="groupOfSearchControl">
                                        <mat-option>
                                            <ngx-mat-select-search 
                                            placeholderLabel="{{ 'Search' | translate }}" 
                                            noEntriesFoundLabel="{{ 'no matching group found' | translate }}"
                                            [formControl]="groupMoreControl"></ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option [value]="''">
                                            {{ 'All' | translate }}
                                        </mat-option>
                                        <mat-option *ngFor="let group of groups$ | async" [value]="group.id">
                                            {{ group.name | translate }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
        
                            <ng-container formGroupName="consist_of_task_id">
                                <mat-form-field appearance="standard" class="full_width_form">
                                    <mat-label>{{ "Consist of" | translate }}</mat-label>
        
                                    <div class="chips-boundary consist_boundary">
                                        <mat-chip-list class="sorted_chips without_ordered" #consistOfChipListTemplate formControlName="add">
                                            <div class="chips_sorter_container" cdkDropListGroup #consistGroupTemplate>
                                              
                                                <div class="chips_drag_placeholder" cdkDropList (cdkDropListEntered)="entered($event, consistGroupTemplate, consistPlaceholderTemplate)" (cdkDropListDropped)="dropped(consistGroupTemplate, consistPlaceholderTemplate, form.get('consist_of_task_id').get('add').value)" #consistPlaceholderTemplate></div>
        
                                                <div *ngFor="let id of templateForm.get('consist_of_task_id').get('add').value"  
                                                cdkDropList
                                                class="sorted_chips_drop_list"
                                                (cdkDropListEntered)="entered($event, consistGroupTemplate, consistPlaceholderTemplate)"
                                                (cdkDropListDropped)="dropped(consistGroupTemplate, consistPlaceholderTemplate, templateForm.get('consist_of_task_id').get('add').value)">
                                                    <div cdkDrag cdkDragBoundary=".consist_boundary" class="sorted_chip" (cdkDragReleased)="dragReleased(consistPlaceholderTemplate, consistDragPlaceholderTemplate)" (contextmenu)="openContext($event, {'id': id, 'form': templateForm, 'control': 'consist_of_task_id'}); $event.preventDefault(); $event.stopPropagation();">
                                                        <div class="sorted_chip_custom_placeholder" #consistDragPlaceholderTemplate *cdkDragPlaceholder></div>
                                                        <mat-chip [removable]="true" color="primary" (removed)="onRemovedTemplate(id, 'consist_of_task_id')">
                                                            <ng-container *ngIf="findTask(id)">
                                                                {{ findTask(id).name.length > 25 ? (findTask(id).name | slice:0:25) + " ..." : findTask(id).name }} ({{findTask(id)?.custom_id}})
                                                            </ng-container>
                                                            <mat-icon matChipRemove>cancel</mat-icon>
                                                        </mat-chip>
                                                    </div>
        
                                                </div>
        
                                                <input class="auto_complete_input"
                                                placeholder="{{ 'Search' | translate }}"
                                                #consistOfInputTemplate
                                                [formControl]="consistOfControl"
                                                [matAutocomplete]="consistOfAutoTemplate"
                                                [matChipInputFor]="consistOfChipListTemplate"
                                                [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
                                                
                                            </div>
                                        </mat-chip-list>
                                    </div>
        
                                    <mat-autocomplete (optionsScroll)="onScroll('consistOfControl')" autoActiveFirstOption #consistOfAutoTemplate="matAutocomplete" (optionSelected)="selectedTemplate($event, consistOfInputTemplate, 'consist_of_task_id')">
                                        <ng-container *ngFor="let task of tasks$ | async">
                                            <mat-option *ngIf="!templateForm.get('consist_of_task_id').get('add').value.includes(task.id) && (['Everywhere', 'Custom ID', 'Task Name'].includes(typeOfSearchControl.value) ? (groupOfSearchControl.value == task.group_id || groupOfSearchControl.value == '') : true)" [value]="task.id">
                                                <div class="task_ids_wrp_out">
                                                    <div class="task_ids_wrp"> 
                                                        <span class="text_one_line">
                                                            {{ task.name }}
                                                        </span>
                                                        <div class="task_ids">
                                                            {{task.custom_id}} 
                                                            <span>
                                                                <div>{{task.internal_id}}</div>
                                                                <div>{{task.id}}</div>
                                                            </span>
                                                        </div>
                                                    </div>
        
                                                    <div class="group text_one_line">
                                                        {{task?.group?.name}}
                                                    </div>
                                                </div>
                                            </mat-option>
                                        </ng-container>
                                        
                                    </mat-autocomplete>
                                </mat-form-field>
                            </ng-container>
                            
                            <ng-container formGroupName="part_of_task_id">
                                <mat-form-field appearance="standard" class="full_width_form">
                                    <mat-label>{{ "Part of" | translate }}</mat-label>
        
                                    <div class="chips-boundary part_boundary">
                                        <mat-chip-list class="sorted_chips without_ordered" #partOfChipListTemplate formControlName="add">
                                            <div class="chips_sorter_container" cdkDropListGroup #partGroupTemplate>
                                              
                                                <div class="chips_drag_placeholder" cdkDropList (cdkDropListEntered)="entered($event, partGroupTemplate, partPlaceholderTemplate)" (cdkDropListDropped)="dropped(partGroupTemplate, partPlaceholderTemplate, templateForm.get('part_of_task_id').get('add').value)" #partPlaceholderTemplate></div>
        
                                                <div *ngFor="let id of templateForm.get('part_of_task_id').get('add').value"  
                                                cdkDropList
                                                class="sorted_chips_drop_list"
                                                (cdkDropListEntered)="entered($event, partGroupTemplate, partPlaceholderTemplate)"
                                                (cdkDropListDropped)="dropped(partGroupTemplate, partPlaceholderTemplate, templateForm.get('part_of_task_id').get('add').value)">
                                                    <div cdkDrag cdkDragBoundary=".part_boundary" class="sorted_chip" (cdkDragReleased)="dragReleased(partPlaceholderTemplate, partDragPlaceholderTemplate)" (contextmenu)="openContext($event, {'id': id, 'form': templateForm, 'control': 'part_of_task_id'}); $event.preventDefault(); $event.stopPropagation();">
                                                    <div class="sorted_chip_custom_placeholder" #partDragPlaceholderTemplate *cdkDragPlaceholder></div>
                                                    <mat-chip [removable]="true" color="primary" (removed)="onRemovedTemplate(id, 'part_of_task_id')">
                                                        <ng-container *ngIf="findTask(id)">
                                                            {{ findTask(id).name.length > 25 ? (findTask(id).name | slice:0:25) + " ..." : findTask(id).name }} ({{findTask(id)?.custom_id}})
                                                        </ng-container>
                                                        <mat-icon matChipRemove>cancel</mat-icon>
                                                    </mat-chip>
                                                    </div>
        
                                                </div>
        
                                                <input class="auto_complete_input"
                                                placeholder="{{ 'Search' | translate }}"
                                                #partOfInputTemplate
                                                [formControl]="partOfControl"
                                                [matAutocomplete]="partOfAutoTemplate"
                                                [matChipInputFor]="partOfChipListTemplate"
                                                [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
                                                
                                            </div>
                                        </mat-chip-list>
                                    </div>
        
                                    <mat-autocomplete (optionsScroll)="onScroll('partOfControl')" autoActiveFirstOption #partOfAutoTemplate="matAutocomplete" (optionSelected)="selectedTemplate($event, partOfInputTemplate, 'part_of_task_id')">
                                        <ng-container *ngFor="let task of tasks$ | async">
                                            <mat-option *ngIf="!templateForm.get('part_of_task_id').get('add').value.includes(task.id) && (['Everywhere', 'Custom ID', 'Task Name'].includes(typeOfSearchControl.value) ? (groupOfSearchControl.value == task.group_id || groupOfSearchControl.value == '') : true)" [value]="task.id">
                                                <div class="task_ids_wrp_out">
                                                    <div class="task_ids_wrp"> 
                                                        <span class="text_one_line">
                                                            {{ task.name }}
                                                        </span>
                                                        <div class="task_ids">
                                                            {{task.custom_id}} 
                                                            <span>
                                                                <div>{{task.internal_id}}</div>
                                                                <div>{{task.id}}</div>
                                                            </span>
                                                        </div>
                                                    </div>
        
                                                    <div class="group text_one_line">
                                                        {{task?.group?.name}}
                                                    </div>
                                                </div>
                                            </mat-option>
                                        </ng-container>
                                        
                                    </mat-autocomplete>
                                </mat-form-field>
                            </ng-container>
        
                            <ng-container formGroupName="related_task_id">
                                <mat-form-field appearance="standard" class="full_width_form">
                                    <mat-label>{{ "Related" | translate }}</mat-label>
        
                                    <div class="chips-boundary related_boundary">
                                        <mat-chip-list class="sorted_chips without_ordered" #relatedOfChipListTemplate formControlName="add">
                                            <div class="chips_sorter_container" cdkDropListGroup #relatedGroupTemplate>
                                              
                                                <div class="chips_drag_placeholder" cdkDropList (cdkDropListEntered)="entered($event, relatedGroupTemplate, relatedPlaceholderTemplate)" (cdkDropListDropped)="dropped(relatedGroupTemplate, relatedPlaceholderTemplate, templateForm.get('related_task_id').get('add').value)" #relatedPlaceholderTemplate></div>
        
                                                <div *ngFor="let id of templateForm.get('related_task_id').get('add').value"  
                                                cdkDropList
                                                class="sorted_chips_drop_list"
                                                (cdkDropListEntered)="entered($event, relatedGroupTemplate, relatedPlaceholderTemplate)"
                                                (cdkDropListDropped)="dropped(relatedGroupTemplate, relatedPlaceholderTemplate, templateForm.get('related_task_id').get('add').value)">
                                                    <div cdkDrag cdkDragBoundary=".related_boundary" class="sorted_chip" (cdkDragReleased)="dragReleased(relatedPlaceholderTemplate, relatedDragPlaceholder)" (contextmenu)="openContext($event, {'id': id, 'form': templateForm, 'control': 'related_task_id'}); $event.preventDefault(); $event.stopPropagation();">
                                                        <div class="sorted_chip_custom_placeholder" #relatedDragPlaceholder *cdkDragPlaceholder></div>
                                                        <mat-chip [removable]="true" color="primary" (removed)="onRemovedTemplate(id, 'related_task_id')">
                                                            <ng-container *ngIf="findTask(id)">
                                                                {{ findTask(id).name.length > 25 ? (findTask(id).name | slice:0:25) + " ..." : findTask(id).name }} ({{findTask(id)?.custom_id}})
                                                            </ng-container>
                                                            <mat-icon matChipRemove>cancel</mat-icon>
                                                        </mat-chip>
                                                    </div>
        
                                                </div>
        
                                                <input class="auto_complete_input"
                                                placeholder="{{ 'Search' | translate }}"
                                                #relatedOfInputTemplate
                                                [formControl]="relativeControl"
                                                [matAutocomplete]="relatedOfAutoTemplate"
                                                [matChipInputFor]="relatedOfChipListTemplate"
                                                [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
                                                
                                            </div>
                                        </mat-chip-list>
                                    </div>
        
                                    <mat-autocomplete (optionsScroll)="onScroll('relativeControl')" autoActiveFirstOption #relatedOfAutoTemplate="matAutocomplete" (optionSelected)="selectedTemplate($event, relatedOfInputTemplate, 'related_task_id')">
                                        <ng-container *ngFor="let task of tasks$ | async">
                                            <mat-option *ngIf="!templateForm.get('related_task_id').get('add').value.includes(task.id) && (['Everywhere', 'Custom ID', 'Task Name'].includes(typeOfSearchControl.value) ? (groupOfSearchControl.value == task.group_id || groupOfSearchControl.value == '') : true)" [value]="task.id">
                                                <div class="task_ids_wrp_out">
                                                    <div class="task_ids_wrp"> 
                                                        <span class="text_one_line">
                                                            {{ task.name }}
                                                        </span>
                                                        <div class="task_ids">
                                                            {{task.custom_id}} 
                                                            <span>
                                                                <div>{{task.internal_id}}</div>
                                                                <div>{{task.id}}</div>
                                                            </span>
                                                        </div>
                                                    </div>
        
                                                    <div class="group text_one_line">
                                                        {{task?.group?.name}}
                                                    </div>
                                                </div>
                                            </mat-option>
                                        </ng-container>
                                        
                                    </mat-autocomplete>
                                </mat-form-field>
                            </ng-container>
                        </div>
                    </div>
                </form>
            </div>
        </ng-template>

    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <ng-container *ngIf="selectedIndex == 0; else elseTemplateIndex">
        <ng-container *ngIf="!templatesIsActive; else elseTemplateTempatesActive">
            <div class="f_w_p_btns">
                <button mat-button (click)="close()">
                    {{ "Close" | translate }}
                </button>
                <button class="ml-1" (click)="goToAddWork()" mat-raised-button cdkFocusInitial color="primary">
                    {{ "Next" | translate }}
                </button>
                <button *ngIf="!!taskTemplates && taskTemplates.length > 0" class="ml-1" (click)="toggleTemplates()" mat-button color="warn">
                    {{ "Create from template" | translate }}
                </button>
            </div>
        </ng-container>
        <ng-template #elseTemplateTempatesActive>
            <div class="f_w_p_btns">
                <button mat-button (click)="close()">
                    {{ "Close" | translate }}
                </button>
                <button class="ml-1" mat-button color="warn" (click)="toggleTemplates()">
                    {{ "Back to default creation" | translate }}
                </button>
            </div>
        </ng-template>
    </ng-container>
    <ng-template #elseTemplateIndex>    
        <div class="f_w_p_btns">
            <button mat-raised-button (click)="selectedIndex = 0">
                {{ "Back" | translate }}
            </button>
            <button class="ml-1 d-f-btn" (click)="submitForm()" mat-raised-button cdkFocusInitial color="primary" [disabled]="isSubmit">
                <mat-spinner class="spin_16 mr-1" *ngIf="isSubmit"></mat-spinner>  
                {{ "Save" | translate }}
            </button>
        </div> 
    </ng-template>
</mat-dialog-actions>

<ng-template #contextMenu let-contextdata>
	<section class="context-menu">
        <div *ngIf="contextdata.control != 'consist_of_task_id'" (click)="moveTo(contextdata, 'consist_of_task_id')">
            <mat-icon class="mr-1">swipe_up_alt</mat-icon>
            {{ 'Move to Consist Of' | translate }}
        </div>
        <div *ngIf="contextdata.control != 'part_of_task_id'" (click)="moveTo(contextdata, 'part_of_task_id')">
            <mat-icon class="mr-1" *ngIf="contextdata.control == 'related_task_id'">swipe_up_alt</mat-icon>
            <mat-icon class="mr-1" *ngIf="contextdata.control == 'consist_of_task_id'">swipe_down_alt</mat-icon>
            {{ 'Move to Part Of' | translate }}
        </div>
        <div *ngIf="contextdata.control != 'related_task_id'" (click)="moveTo(contextdata, 'related_task_id')">
            <mat-icon class="mr-1">swipe_down_alt</mat-icon>
            {{ 'Move to Related' | translate }}
        </div>
        <div class="menu_divider"></div>
        <div (click)="openCard(contextdata)">
            <mat-icon class="mr-1">open_in_new</mat-icon>
            {{ 'Open Card' | translate }}
        </div>
        <div class="menu_divider"></div>
        <div (click)="remove(contextdata)">
            <mat-icon class="mr-1">delete</mat-icon>
            {{ 'Remove' | translate }}
        </div>
	</section>
</ng-template>