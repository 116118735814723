import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReplaySubject, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { AuthService } from 'src/app/shared/services/rest/auth.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-import',
  templateUrl: './add-import.component.html',
  styleUrls: ['./add-import.component.scss']
})
export class AddImportComponent extends BaseClass implements OnInit, OnDestroy {
  public host: any = environment.host;
  public imgRoute: any = '';
  public user: any = {};
  public user_id: number;
  public form: FormGroup;
  public isSubmit: boolean = false;
  public isFormChange: boolean = false;

  public timezones: any;
  public timezoneFilterCtrl: FormControl = new FormControl();
  protected _onDestroy = new Subject<void>();
    /** list of bank groups filtered by search keyword for option groups */
  public filteredTimezonesGroups: ReplaySubject<any> = new ReplaySubject<any>(1);
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddImportComponent>,
    private layoutService: LayoutService,
    private sm: StorageManagerService,
    private auth: AuthService,
    private companyService: CompanyService,
    private fb: FormBuilder,
  ) { super() }

  ngOnInit(): void {
    console.log("AddImportComponent", this.data)
    this.getCompany();
    this.getImgRoute();
    this.getCsrf();
    this.getUser();
    this.getTimeZones();

    if (this.data.import) {
      // this.form = this.fb.group({
      //   company_id: this.data.company_id,
      //   name: [this.data.import.name, Validators.required],
      //   timezone: [this.data.import.timezone, Validators.required],
      //   timezone_offset: [this.data.import.timezone_offset, Validators.required],
      //   file_url: [this.data.import.file_url, Validators.required],
      //   update_every: [!!this.data.import.update_every ? this.data.import.update_every / 3600 : this.data.import.update_every,, Validators.required],
      //   is_active: [this.data.import.is_active, Validators.required],
      // })
      this.form = this.fb.group({
        company_id: this.data.company_id,
        name: [this.data.import.name, Validators.required],
        timezone: [this.data.import.timezone, Validators.required],
        timezone_offset: [this.data.import.timezone_offset, Validators.required],
        file_url: [this.data.import.file_url, Validators.required],
        update_every: [!!this.data.import.update_every ? this.data.import.update_every / 3600 : this.data.import.update_every, Validators.required],
        is_active: [this.data.import.is_active, Validators.required]
      })
      console.log("form1", this.form.value)
    } else {
      this.form = this.fb.group({
        company_id: this.data.company_id,
        name: ['', Validators.required],
        timezone: ['', Validators.required],
        timezone_offset: ['', Validators.required],
        file_url: '',
        update_every: '',
        is_active: 1
      })
      console.log("form2", this.form.value)
    }


    this.attachSubscriptions(
      this.form.valueChanges.subscribe(() => this.isFormChange = true)
    )

    this.form.get("timezone").valueChanges.subscribe(x => {
      this.form.patchValue({
        timezone_offset: x == "no" ? '' : this.getOffsetData(x)
      })
    })

    this.timezoneFilterCtrl.valueChanges
    .pipe(takeUntil(this._onDestroy))
    .subscribe(() => {
      this.filterTimezoneGroups();
    });

    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        if (this.isFormChange) {
          this.layoutService.openBottomSheet(this.dialogRef);
        } else {
          this.close();
        }
      })
    )
  }

  
  protected filterTimezoneGroups() {
    if (!this.timezones) {
      return;
    }
    // get the search keyword
    let search = this.timezoneFilterCtrl.value;
    const timezonesCopy = this.copyTimezoneGroups(this.timezones);
    if (!search) {
      this.filteredTimezonesGroups.next(timezonesCopy);
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredTimezonesGroups.next(
      timezonesCopy.filter(timezoneGroup => {
        const showBankGroup = timezoneGroup.value.toLowerCase().indexOf(search) > -1;
        if (!showBankGroup) {
          timezoneGroup.names = timezoneGroup.names.filter(name => name.toLowerCase().indexOf(search) > -1);
        }
        return timezoneGroup.names.length > 0;
      })
    );
  }

  protected copyTimezoneGroups(timezoneGroups: any) {
    const timezonesCopy = [];
    timezoneGroups.forEach(bankGroup => {
      timezonesCopy.push({
        value: bankGroup.value,
        names: bankGroup.names.slice()
      });
    });
    return timezonesCopy;
  }

  getTimeZone(val) {
    let o = Math.abs(val);
    return (val >= 0 ? "+" : "-") + ("00" + Math.floor(o / 3600)).slice(-2) + ":00";
  }
  
  getOffsetData(name) {
    if (!this.timezones) {
      return "0"
    }
    return this.timezones.find(x => x.names.filter(y => y == name).length > 0).value
  }

  getTimeZones() {
    this.attachSubscriptions(
      this.companyService.getTimeZones().subscribe(resp => {
        let val = [];
        Object.keys(resp).forEach(key => {
          val.push({
            value: key,
            names: resp[key]
          })
        })
        this.timezones = val;
        this.filteredTimezonesGroups.next(this.copyTimezoneGroups(this.timezones));
        console.log("getTimeZones",this.timezones);
      })
    )
  }
  

  getCompany() {
    this.attachSubscriptions(
      this.companyService.getCompany(this.data.company_id).subscribe(resp => {
        this.companyService.company$.next(resp[0]);
      })
    )
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  getCsrf() {
    this.attachSubscriptions(
      this.auth.$userToken.subscribe(resp => {
        this.user_id = resp.user_id;
        this.sm.localStorageSetItem("csrf_param", resp.csrf_param)
        this.sm.localStorageSetItem("csrf_token", resp.csrf_token)
      })
    )
  }

  getUser() {
    this.attachSubscriptions(
      this.auth.$user.subscribe(resp => {
        this.user = resp;
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  onSubmit() {
    this.isSubmit = true;
    let formData = {...this.form.value};

    formData.update_every = formData.update_every*3600;

    if (this.data.import) {
      this.attachSubscriptions(
        this.companyService.editImport(this.data.import.id, formData).subscribe(resp => {
          this.isSubmit = false;
          this.dialogRef.close({event: "update", data: resp});
        }, error => {
          this.isSubmit = false;
          this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
        })
      )
    } else {  
      this.attachSubscriptions(
        this.companyService.addImport(formData).subscribe(resp => {
          this.isSubmit = false;
          this.dialogRef.close({event: "update", data: resp});
        }, error => {
          this.isSubmit = false;
          this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
        })
      )
    }
  }
  
  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
