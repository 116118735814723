import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpRestService } from '../common/http-rest.service';

@Injectable({
  providedIn: 'root'
})
export class ParametersService {
  public globalParamsData$: Subject<any> = new Subject();
  public paramsData$: Subject<any> = new Subject();
  public rParamsData$: Subject<any> = new Subject();
  public filesParamsData$: Subject<any> = new Subject();
  public globalFilesParamsData$: Subject<any> = new Subject();
  public paramsTargetData$: Subject<any> = new Subject();
  constructor(
    private http: HttpRestService
  ) { }

  getParamsStream() {
    return this.paramsData$.asObservable();
  }

  getRootParamsStream() {
    return this.rParamsData$.asObservable();
  }
  
  getGlobalParamsStream() {
    return this.globalParamsData$.asObservable();
  }
  
  getGlobalFilesParamsData() {
    return this.globalFilesParamsData$.asObservable();
  }

  getFilesParamsStream() {
    return this.filesParamsData$.asObservable();
  }

  getParamsTargetStream() {
    return this.paramsTargetData$.asObservable();
  }

  getRootParameters(filter) {
    let filter_params = new HttpParams().set('per-page', '200')

    if (filter) {
      Object.keys(filter).forEach(element => {
          filter_params = filter_params.set(element, filter[element]);
      });
    }
    return this.http.executeGet<any>(`/root/parameter/`, true, {
      observe: 'response',
      params: filter_params
    })
  }

  addRootParameter(data) {
    return this.http.executePost<any>(`/root/parameter/`, data)
  }
  
  editRootParameter(id, data) {
    return this.http.executePost<any>(`/root/parameter/${id}/`, data)
  }

  deleteRootParameter(id) {
    return this.http.executeDelete<any>(`/root/parameter/${id}/`)
  }

  getRootParameterValues(id, filter) {
    let filter_params = new HttpParams().set('per-page', '200').set('parameter_id', id).set("expand", "parameter")

    if (filter) {
      Object.keys(filter).forEach(element => {
          filter_params = filter_params.set(element, filter[element]);
      });
    }
    return this.http.executeGet<any>(`/root/parameter-value/`, true, {
      params: filter_params
    })
  }

  getRootParameterValuesFiltered(q, page) {
    return this.http.executeGet<any>(`/root/parameter-value/`, true, {
      observe: 'response',
      params: new HttpParams().set('q', q).set('per-page', '20').set('page', page).set("expand", "parameter,parameterValue")
    })
  }

  addRootParameterValue(data) {
    return this.http.executePost<any>(`/root/parameter-value/`, data)
  }
  
  getRootParameterValue(id) {
    return this.http.executeGet<any>(`/root/parameter-value/${id}/`)
  }
  
  editRootParameterValue(id, data) {
    return this.http.executePost<any>(`/root/parameter-value/${id}/`, data)
  }

  deleteRootParameterValue(id) {
    return this.http.executeDelete<any>(`/root/parameter-value/${id}/`)
  }

  getFilteredApiParameters(company_id, filter) {
    let filter_params = new HttpParams().set('per-page', '200').set('company_id', company_id)

    if (filter) {
      Object.keys(filter).forEach(element => {
          filter_params = filter_params.set(element, filter[element]);
      });
    }
    return this.http.executeGet<any>(`/api/parameter/`, true, {
      params: filter_params
    })
  }

  getApiParameters(company_id, partner_company_id?) {
    let filter_params = new HttpParams().set('per-page', '200').set('company_id', company_id)

    if (partner_company_id) {
      filter_params = filter_params.set("partner_company_id", partner_company_id)
    }
    
    return this.http.executeGet<any>(`/api/parameter/`, true, {
      params: filter_params
    })
  }

  getApiParametersWithPage(page, company_id, partner_company_id?, per_page = '200') {
    let filter_params = new HttpParams().set('per-page', per_page).set('company_id', company_id)

    if (partner_company_id) {
      filter_params = filter_params.set("partner_company_id", partner_company_id)
    }
    
    return this.http.executeGet<any>(`/api/parameter/`, true, {
      observe: 'response',
      params: filter_params
    })
  }

  addApiParameter(data) {
    return this.http.executePost<any>(`/api/parameter/`, data)
  }

  importApiParameter(data) {
    return this.http.executePost<any>(`/api/parameter/import/${data.id}/`, {}, true, {
      params: new HttpParams().set('company_id', data.company_id)
    })
  }
  
  getApiTips(company_id, filter) {
    let filter_params = new HttpParams().set('per-page', '200').set('company_id', company_id)

    if (filter) {
      Object.keys(filter).forEach(element => {
          filter_params = filter_params.set(element, filter[element]);
      });
    }
    return this.http.executeGet<any>(`/api/parameter-tip/`, true, {
      params: filter_params
    })
  }

  getRootTips(company_id, filter) {
    let filter_params = new HttpParams().set('per-page', '200').set('company_id', company_id)

    if (filter) {
      Object.keys(filter).forEach(element => {
          filter_params = filter_params.set(element, filter[element]);
      });
    }
    return this.http.executeGet<any>(`/root/parameter-tip/`, true, {
      params: filter_params
    })
  }

  addApiTip(data) {
    return this.http.executePost<any>(`/api/parameter-tip/`, data)
  }

  addRootTip(data) {
    return this.http.executePost<any>(`/root/parameter-tip/`, data)
  }

  editApiTip(id, data) {
    return this.http.executePost<any>(`/api/parameter-tip/${id}/`, data)
  }

  editRootTip(id, data) {
    return this.http.executePost<any>(`/root/parameter-tip/${id}/`, data)
  }
  
  deleteApiTip(id) {
    return this.http.executeDelete<any>(`/api/parameter-tip/${id}/`)
  }

  deleteRootTip(id) {
    return this.http.executeDelete<any>(`/root/parameter-tip/${id}/`)
  }

  editApiParameter(id, data) {
    return this.http.executePost<any>(`/api/parameter/${id}/`, data)
  }

  deleteApiParameter(id) {
    return this.http.executeDelete<any>(`/api/parameter/${id}/`)
  }

  getPaginationAllApiParameterValues(page, company_id) {
    return this.http.executeGet<any>(`/api/parameter-value/`, true, {
      observe: 'response',
      params: new HttpParams().set('page', page).set('per-page', '200').set('company_id', company_id).set("expand", "parameter,parameterValue").set('order_by', 'order')
    })
  }

  getAllApiParameterValues(company_id, partner_company_id?) {
    let filter_params = new HttpParams().set('per-page', '200').set('company_id', company_id).set("expand", "parameter,parameterValue").set('order_by', 'order')

    if (partner_company_id) {
      filter_params = filter_params.set("partner_company_id", partner_company_id)
    }
    
    return this.http.executeGet<any>(`/api/parameter-value/`, true, {
      params: filter_params
    })
  }

  getAllValues(page, company_id, filter:any = null, perPage:string = '200') {
    let filter_params = new HttpParams().set('company_id', company_id).set('per-page', perPage).set('page', page).set("expand", "parameter,parameterValue").set('order_by', 'order')

    if (filter) {
      Object.keys(filter).forEach(element => {
        filter_params = filter_params.set(element, filter[element]);
      });
    }
    
    return this.http.executeGet<any>(`/api/parameter-value/`, true, {
      observe: 'response',
      params: filter_params
    })
  }

  getAllTags(page, filter:any = null, perPage:string = '200') {
    let filter_params = new HttpParams().set('per-page', perPage).set('page', page).set("expand", "parameter,parameterValue").set('order_by', 'order')

    if (filter) {
      Object.keys(filter).forEach(element => {
        filter_params = filter_params.set(element, filter[element]);
      });
    }
    
    return this.http.executeGet<any>(`/api/parameter-value/`, true, {
      observe: 'response',
      params: filter_params
    })
  }

  getApiParameterValues(company_id, id, per_page = "200", page = "1", q = '', val_id?, partner_company_id?) {
    let filter_params = new HttpParams().set('per-page', per_page).set('company_id', company_id).set('page', page).set("expand", "parameter,parameterValue").set('order_by', 'order')

    if (!!id) {
      filter_params = filter_params.set('parameter_id', id)
    }
    if (val_id) {
      filter_params = filter_params.set("id", val_id)
    }
    if (partner_company_id) {
      filter_params = filter_params.set("partner_company_id", partner_company_id)
    }
    if (!!q) {
      filter_params = filter_params.set("q", q)
    }

    return this.http.executeGet<any>(`/api/parameter-value/`, true, {
      observe: 'response',
      params: filter_params
    })
  }

  getNewApiParameterValues(company_id, id) {
    return this.http.executeGet<any>(`/api/parameter-value/`, true, {
      params: new HttpParams().set('per-page', '200').set('parameter_id', id).set('company_id', company_id).set("new", '1').set('order_by', 'order')
    })
  }

  addApiParameterValue(data) {
    return this.http.executePost<any>(`/api/parameter-value/`, data, true, {
      params: new HttpParams().set("expand", "parameter,parameterValue")
    })
  }
  
  editApiParameterValue(id, data) {
    return this.http.executePost<any>(`/api/parameter-value/${id}/`, data)
  }

  deleteApiParameterValue(id) {
    return this.http.executeDelete<any>(`/api/parameter-value/${id}/`)
  }

  getTargetParameters(company_id, filter?) {
    let filter_params = new HttpParams().set("company_id", company_id).set("expand", "parameter,parameterValue").set('per-page','200')
    if (filter) {
      Object.keys(filter).forEach(element => {
        // if (!!filter[element]) {
          filter_params = filter_params.set(element, filter[element]);
        // }
      });
    }
    return this.http.executeGet<any>(`/api/parameter-value-to-task/`, true, {
      params: filter_params
    })
  }

  getTargetContentPlanParameters(company_id, filter?) {
    let filter_params = new HttpParams().set("company_id", company_id).set("expand", "parameter,parameterValue").set('per-page','200')
    if (filter) {
      Object.keys(filter).forEach(element => {
        // if (!!filter[element]) {
          filter_params = filter_params.set(element, filter[element]);
        // }
      });
    }
    return this.http.executeGet<any>(`/api/parameter-value-to-content-plan/`, true, {
      params: filter_params
    })
  }

  getTargetChannelParameters(company_id, filter?) {
    let filter_params = new HttpParams().set("company_id", company_id).set("expand", "parameter,parameterValue").set('per-page','200')

    if (filter) {
      Object.keys(filter).forEach(element => {
        if (!!filter[element]) {
          filter_params = filter_params.set(element, filter[element]);
        }
      });
    }

    return this.http.executeGet<any>(`/api/parameter-value-to-channel/`, true, {
      params: filter_params
    })
  }

  addTargetParameter(data, company_id) {
    return this.http.executePost<any>(`/api/parameter-value-to-task/`, data, true, {
      params: new HttpParams().set("expand", "parameter,parameterValue").set("company_id", company_id)
    })
  }
  
  addTargetContentPlanParameter(data) {
    return this.http.executePost<any>(`/api/parameter-value-to-content-plan/`, data)
  }

  addTargetChannelParameter(data) {
    return this.http.executePost<any>(`/api/parameter-value-to-channel/`, data)
  }

  deleteTargetChannelParameter(id) {
    return this.http.executeDelete<any>(`/api/parameter-value-to-channel/${id}/`)
  }

  deleteTargetParameter(id) {
    return this.http.executeDelete<any>(`/api/parameter-value-to-task/${id}/`)
  }

  deleteTargetContnetPlan(id) {
    return this.http.executeDelete<any>(`/api/parameter-value-to-content-plan/${id}/`)
  }

  editTargetParameter(id, data, company_id) {
    return this.http.executePost<any>(`/api/parameter-value-to-task/${id}/`, data, true, {
      params: new HttpParams().set("company_id", company_id)
    })
  }

  addOnlyExceptParameterValue(data, only:boolean) {
    if (only) {
      return this.http.executePost<any>(`/api/operation-reminder-to-parameter-value/`, data, true, {
        params: new HttpParams().set("expand", "parameter,parameterValue")
      })
    } else {
      return this.http.executePost<any>(`/api/operation-reminder-except-parameter-value/`, data, true, {
        params: new HttpParams().set("expand", "parameter,parameterValue")
      })
    }
  }

  deleteOnlyExceptParameterValue(id, only:boolean) {
    if (only) {
      return this.http.executeDelete<any>(`/api/operation-reminder-to-parameter-value/${id}/`)
    } else {
      return this.http.executeDelete<any>(`/api/operation-reminder-except-parameter-value/${id}/`)
    }
  }

}
