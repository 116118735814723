<mat-dialog-content class="mat-typography" dirEnter (dirSubmit)="!form.valid || isSubmit ? null : onSubmit()">
    <div class="container form_wrapper">
        <div class="title_modal">
            {{ "Adding a new payment" | translate }}
        </div>
        <form [formGroup]="form" class="form_with_pads form_with_valids">

            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ "Choose an payment system" | translate }}</mat-label>
                <mat-select formControlName="payment_system_id" required>
                <mat-option *ngFor="let pSys of paymentSystems" [value]="pSys.id">
                    {{ pSys.name }}
                </mat-option>
                </mat-select>
            </mat-form-field>
    
            <!-- <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ "Choose an employee" | translate }}</mat-label>
                <mat-select formControlName="employee_id" required>
                    
                    <ng-container *ngFor="let employee of employees">
                        <mat-option [value]="employee.id">
                            <app-user [class]="'user_div'" [employee]="employee"></app-user>
                        </mat-option>
                    </ng-container>
                    
                </mat-select>
            </mat-form-field> -->
    
            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ "Choose a currency" | translate }}</mat-label>
                <mat-select formControlName="currency_id" required>
                <mat-option *ngFor="let currency of currencyList" [value]="currency.id">
                    {{ currency.name }}
                </mat-option>
                </mat-select>
            </mat-form-field>
    
            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ 'Comment' | translate }}</mat-label>
                <textarea formControlName="comment" matInput placeholder="{{ 'Comment' | translate }}"></textarea>
            </mat-form-field>
    
            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ 'Amount' | translate }}</mat-label>
                <input formControlName="amount" matInput type="number" placeholder="{{ 'Amount' | translate }}">
            </mat-form-field>
    
            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ "Date Posted" | translate }}</mat-label>
                <input matInput [matDatepicker]="dp" formControlName="paid_at">
                <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
                <mat-datepicker #dp></mat-datepicker>
            </mat-form-field>
    
            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ "Date of operation performed" | translate }}</mat-label>
                <input matInput [matDatepicker]="dpReal" formControlName="real_paid_at">
                <mat-datepicker-toggle matSuffix [for]="dpReal"></mat-datepicker-toggle>
                <mat-datepicker #dpReal></mat-datepicker>
            </mat-form-field>
        </form>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <div class="f_w_p_btns">
        <button mat-button (click)="close()">
            {{ "Close" | translate }}
        </button>
        <button class="ml-1 d-f-btn" (click)="onSubmit()" cdkFocusInitial mat-raised-button color="primary" [disabled]="isSubmit">
            <mat-spinner class="spin_16 mr-1" *ngIf="isSubmit"></mat-spinner>  
            {{ "Edit" | translate }}
        </button>
    </div>
</mat-dialog-actions>