import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';

@Component({
  selector: 'app-select-status-and-employee',
  templateUrl: './select-status-and-employee.component.html',
  styleUrls: ['./select-status-and-employee.component.scss']
})
export class SelectStatusAndEmployeeComponent extends BaseClass implements OnInit, OnDestroy {
  public statuses: any = JSON.parse(JSON.stringify(this.data.statuses));
  public employees: any = JSON.parse(JSON.stringify(this.data.employees));
  public submited: boolean = false;
  public form: FormGroup;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<SelectStatusAndEmployeeComponent>,
    private fb: FormBuilder,
    private layoutService: LayoutService,
  ) { super() }

  ngOnInit(): void {
    console.log("SelectStatusAndEmployeeComponent", this.data)
    this.form = this.fb.group({
      status_id: ['', Validators.required],
      employee_ids: [[], Validators.required]
    })
  }

  close() {
    this.dialogRef.close({event: "close", data: false});
  }

  selectStatus(status) {
    this.form.patchValue({
      status_id: status.id
    })
  }

  selectEmpl = (chip: any) => {
    this.employees.find(x => x.id == chip.id).is_select = !this.employees.find(x => x.id == chip.id).is_select;
    this.form.patchValue({
      employee_ids: this.employees.filter(x => x.is_select == true).map(x => x.id)
    })
  }

  select() {
    if (!this.form.valid) {
      this.submited = true;
      this.layoutService.showSnackBar({name: ''}, marker("You need to select all required fields"), SnackBarItem)
      return
    }
    this.submited = false;
    this.dialogRef.close({event: "select", data: {
      status: this.statuses.find(x => x.id == this.form.value.status_id),
      employees: this.employees.filter(x => x.is_select == true)
    }});
  }

  ngOnDestroy() {
    this.clearSubscriptions()
  }

}
