import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { from, fromEvent, of, Subscription } from 'rxjs';
import { catchError, filter, finalize, last, map, mergeMap, take, tap } from 'rxjs/operators';
import { BaseClass } from 'src/app/shared/models/base-class';
import { GlobalDataService } from 'src/app/shared/services/common/global-data.service';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';

@Component({
  selector: 'app-platforms-file',
  templateUrl: './platforms-file.component.html',
  styleUrls: ['./platforms-file.component.scss']
})
export class PlatformsFileComponent extends BaseClass implements OnInit, OnDestroy {
  @Input() file: any;
  @Input() addClass: string = '';
  @Input() company: any;
  @Input() company_id: any;
  @Input() profileStatuses: any;
  public fileChannelsSub: Subscription;
  public activeLang: any;
  public fileChannelsSubData: Subscription;
  @ViewChild('channelsMenu') channelsMenu: TemplateRef<any>;
  overlayFileChannels: OverlayRef | null;

  constructor(
    private taskService: TaskService,
    private languageService: LanguageService,
    private globalDataService: GlobalDataService,
    public viewContainerRef: ViewContainerRef,
    public overlay: Overlay,
  ) { 
    super() 
  }

  ngOnInit(): void {
    this.getLangData();
    if (!!this.file.fileOriginalData && !!this.file.fileOriginalData.meta_channel && !this.file.fileOriginalData.allChannels) {
      if (!!this.file.fileOriginalData.meta_channel.task_channels_by_platform) {
        this.file.fileOriginalData.allChannels = {
          platform_id: 0,
          waiting: 0,
          published: 0,
          live: 0,
          task_channels: []
        }
        this.file.fileOriginalData.meta_channel.task_channels_by_platform_arr = Object.values(this.file.fileOriginalData.meta_channel.task_channels_by_platform).filter((x:any) => !!x.platform_id);
      

        this.file.fileOriginalData.meta_channel.task_channels_by_platform_arr.forEach(p => {
          if (p.task_channels) {
            this.file.fileOriginalData.allChannels.task_channels.push(...p.task_channels);
          }
          this.file.fileOriginalData.allChannels.waiting += p.waiting;
          this.file.fileOriginalData.allChannels.published += p.published;
          this.file.fileOriginalData.allChannels.live += p.live;
        });
      }
    }
  }

  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData().subscribe(resp => {
        this.activeLang = resp.active;
      })
    )
  }

  getProfileStatus(id) {
    if (!this.profileStatuses) {
      return false;
    }
    return this.profileStatuses.find(el => el.id == id)
  } 

  closeFileChannels() {
    this.fileChannelsSub && this.fileChannelsSub.unsubscribe();
    this.fileChannelsSubData && this.fileChannelsSubData.unsubscribe();
    if (this.overlayFileChannels) {
      this.overlayFileChannels.dispose();
      this.overlayFileChannels = null;
    }
  }  

  openChannelContent(url) {
    if (url) {
      window.open(url, "_blank")
    }
  }

  openFileChannels({ x, y }: MouseEvent, file, platform) {
    this.closeFileChannels();

    const positionStrategy = this.overlay.position()
    .flexibleConnectedTo({ x, y })
    .withPositions([
      {
        originX: 'start',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'top',
      }
    ]);

    if (platform.task_channels) {
      this.fileChannelsSubData = from(platform.task_channels).pipe(
        mergeMap((x:any) => this.taskService.getExpandUrls('1', this.company_id, {id: x.task_channel_id}, '1').pipe(
          catchError(() => of([])),
          map(res => res.body.length ? res.body[0] : null),
          tap(val => { /* логику для каждого элемента */ 
            if (!!val) {
              x.taskChannel = val;
            }
          })
        ), 10), // 10 запросов параллельно
        finalize(() => {
          console.log('Все openFileChannels запросы завершены');
        }),
        last()
      ).subscribe(res => {
        console.log('subscribe openFileChannels завершен');
      })
    }


    this.overlayFileChannels = this.overlay.create({
      backdropClass: 'transparent_bg',
      hasBackdrop: true,
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });

    this.overlayFileChannels.attach(new TemplatePortal(this.channelsMenu, this.viewContainerRef, {
      $implicit: {
        file: file,
        platform: platform
      }
    }));
  

    this.fileChannelsSub = fromEvent<MouseEvent>(document, 'click')
    .pipe(
      filter(event => {
        const clickTarget = event.target as HTMLElement;
      
        let check1 = !!this.overlayFileChannels && !this.overlayFileChannels.overlayElement.contains(clickTarget)
        return check1
      }),
      take(1)
    ).subscribe(() => this.closeFileChannels())
  }

  ngOnDestroy(): void {
    this.fileChannelsSubData && this.fileChannelsSubData.unsubscribe();
    this.clearSubscriptions()
  }
}
