import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { FileService } from 'src/app/shared/services/rest/file.service';

@Component({
  selector: 'app-file-privacy',
  templateUrl: './file-privacy.component.html',
  styleUrls: ['./file-privacy.component.scss']
})
export class FilePrivacyComponent extends BaseClass implements OnInit, OnDestroy {
  public form: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private fileService: FileService,
    public dialogRef: MatDialogRef<FilePrivacyComponent>,
    public layoutService: LayoutService
  ) { super() }

  ngOnInit(): void {
    console.log("FilePrivacyComponent")
    this.form = this.fb.group({
      is_public: this.data.file.is_public,
      is_public_registered: this.data.file.is_public_registered
    })
  }

  close() {
    this.dialogRef.close({event: "close", data: false});
  }

  submitForm() {
    let sbmtData = {...this.form.value}
    sbmtData.is_public = !!sbmtData.is_public ? 1 : 0;
    sbmtData.is_public_registered = !!sbmtData.is_public_registered ? 1 : 0;

    this.attachSubscriptions(
      this.fileService.editFile(this.data.file.id, sbmtData, this.data.company.id).subscribe(resp => {
        this.data.file.is_public = resp.is_public;
        this.data.file.is_public_registered = resp.is_public_registered;
        this.dialogRef.close({event: "update", data: resp})
      })
    )
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
