<h2 mat-dialog-title class="dialog_title">
    <span>
        {{ "Edditing a work" | translate }}: {{data?.task?.name}}<b>({{data?.task?.custom_id}})</b>
    </span>
    <button mat-button (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h2>
<mat-dialog-content class="mat-typography" dirEnter (dirSubmit)="!form.valid ? null : onSubmit()">
    <div class="container form_wrapper">
        <form [formGroup]="form">
            <ng-container *ngIf="data?.company?.permissions.includes('owner'); else elseTemplateManager">
                <mat-form-field appearance="standard" class="full_width_form">
                    <mat-label>{{ "Choose an employee" | translate }}</mat-label>
                    <mat-select formControlName="employee_id" required>
                    <mat-option *ngFor="let employee of employees" [value]="employee.id">
                        <app-user [class]="'user_div'" [employee]="employee"></app-user>
                    </mat-option>
                    </mat-select>
                </mat-form-field>
            </ng-container>
            <ng-template #elseTemplateManager>
                <mat-form-field appearance="standard" class="full_width_form">
                    <mat-label>{{ "Choose an employee" | translate }}</mat-label>
                    <mat-select formControlName="employee_id" required>
                        <ng-container *ngFor="let employee of employees">
                            <mat-option *ngIf="!!employee?.user_id" [value]="employee.id">
                                <app-user [class]="'user_div'" [employee]="employee"></app-user>
                            </mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
            </ng-template>
        </form>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button (click)="close()">
        {{ "Back" | translate }}
    </button>
    <button class="ml-1" (click)="onSubmit()" mat-raised-button cdkFocusInitial color="primary" [disabled]="!form.valid">
        {{ "Save" | translate }}
    </button>
</mat-dialog-actions>
