import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ReplaySubject, concat } from "rxjs";
import { debounceTime, tap } from "rxjs/operators";
import { BaseClass } from "src/app/shared/models/base-class";
import { StorageManagerService } from "src/app/shared/services/common/storage-manager.service";
import { CompanyService } from "src/app/shared/services/rest/company.service";
import { LoadingService } from "src/app/shared/services/rest/loading.service";
import { TaskService } from "src/app/shared/services/rest/task.service";

@Component({
  selector: 'app-task-multi-add-clients',
  templateUrl: './task-multi-add-clients.component.html',
  styleUrls: ['./task-multi-add-clients.component.scss']
})

export class TaskMultiAddClientsComponent extends BaseClass implements OnInit, OnDestroy {
  public form: FormGroup;
  
  public employees: any;
  public employeeMoreControl: FormControl = new FormControl();
  public employees$: ReplaySubject<any> = new ReplaySubject<any>(1);


  public partners: any;
  public partnersControl: FormControl = new FormControl();
  public partners$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public isSubmit: boolean = false;

  public pCliSwitch: boolean = false;
  public cliSwitch: boolean = false;

  public clients = [];
  public partnerClients = [];
  public clients_ids = [];
  public partnerClients_ids = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private taskService: TaskService,
    public dialogRef: MatDialogRef<TaskMultiAddClientsComponent>,
    private fb: FormBuilder,
    private sm: StorageManagerService,
    private companyService: CompanyService,
    private ls: LoadingService,
  ) { super() }

  ngOnInit(): void {
    console.log("TaskMultiAddClientsComponent", this.data);
    
    this.form = this.fb.group({
      client_employee_id: [[], Validators.required],
    })
    console.log("TaskMultiAddClientsComponent", this.form);

    this.attachSubscriptions(
      this.partnersControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchPartners(resp))
    )

    this.attachSubscriptions(
      this.employeeMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchEmoloyees(resp))
    )
    
    this.getPartners(); 
    this.getEmployees(); 
  }

  
  getPartners() {
    this.attachSubscriptions(
      this.companyService.getPartners({company_id: this.data.company.id}).subscribe(resp => {
        this.partners = resp.filter(x => x.partner_company_id != 0);
        this.partners$.next(this.partners.slice())
        console.log("getPartners", this.partners)
      })
    )
  }

  getPartnerById(id) {
    // console.log("partners", id);
    // console.log("partners", this.partners.find(el => el.partner_company_id == id));
    if (!this.partners || this.partners.filter(el => el.partner_company_id == id).length == 0) {
      return false;
    }
    return this.partners.find(el => el.partner_company_id == id)
  }  

  onSearchEmoloyees(resp) {
    if (!this.employees) {
      return;
    }

    if (!resp) {
      this.employees$.next(this.employees.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.employees$.next(
      this.employees.filter(z => z.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  onSearchPartners(resp) {
    if (!this.partners) {
      return;
    }

    if (!resp) {
      this.partners$.next(this.partners.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.partners$.next(
      this.partners.filter(z => z.partnerCompany.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  getEmployees() {
    this.attachSubscriptions(
      this.taskService.getEmployees(this.data.company.id).subscribe(resp => {
        console.log("getEmployees TaskMultiAddClientsComponent", resp)
        this.employees = resp;
        this.employees$.next(this.employees.slice());
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false});
  }

  
  includesCli(id) {
    return this.clients.filter(x => x.client_employee_id == id).length > 0;
  }
  
  setCli(e, id, val) {
    e.preventDefault();
    e.stopPropagation();
    this.clients.find(x => x.client_employee_id == id).is_principal = val
  }

  setCliPartn(e, id, val) {
    e.preventDefault();
    e.stopPropagation();
    this.partnerClients.find(x => x.client_partner_company_id == id).is_principal = val
  }

  getCli(id) {
    return this.clients.find(x => x.client_employee_id == id);
  }
  
  getCliPartn(id) {
    return this.partnerClients.find(x => x.client_partner_company_id == id);
  }

  includesCliPartn(id) {
    return this.partnerClients.filter(x => x.client_partner_company_id == id).length > 0;
  }

  toggleSelectClientChip(e) {
    console.log('toggleSelectClientChip', e)

    this.clients.forEach((obj, ind) => {
      if (!this.clients_ids.filter(id => obj.client_employee_id == id).length) {
        this.clients.splice(ind, 1)
      }
    });

    this.clients_ids.forEach((id, ind) => {
      if (!this.clients.filter(x => x.client_employee_id == id).length) {
        this.clients.push({
          client_employee_id: id,
          is_principal: 1
        })
      }
    });

    console.log(this.clients_ids);
    console.log(this.clients);
  }

  toggleSelectClientPartnerChip(e) {
    console.log('toggleSelectClientPartnerChip', e)

    this.partnerClients.forEach((obj, ind) => {
      if (!this.partnerClients_ids.filter(id => obj.client_partner_company_id == id).length) {
        this.partnerClients.splice(ind, 1)
      }
    });

    this.partnerClients_ids.forEach((id, ind) => {
      if (!this.partnerClients.filter(x => x.client_partner_company_id == id).length) {
        this.partnerClients.push({
          client_partner_company_id: id,
          is_principal: 1
        })
      }
    });

    console.log(this.partnerClients_ids);
    console.log(this.partnerClients);
  }

  toggleClientPartnerChip = (chip: any) => {
    console.log("toggleClientPartnerChip", chip)
    let id = !!chip.partner_company_id ? chip.partner_company_id : chip.value
    if (!!id) {
      if (this.partnerClients.filter(x => x.client_partner_company_id == id).length) {
        this.partnerClients.splice(this.partnerClients.indexOf(this.partnerClients.find(x => x.client_partner_company_id == id)), 1)
      } else {
        this.partnerClients.push({
          client_partner_company_id: id,
          is_principal: 1
        })
      }
    }
    
    this.partnerClients_ids = this.partnerClients.map(x => x.client_partner_company_id);
    // if (!chip.partner_company_id) {
    //   this.pCliSwitch = true;

    //   setTimeout(() => {
    //     this.pCliSwitch = false;
    //   }, 0)
    // }
  }

  toggleClientChip = (chip: any) => {
    console.log("toggleClientChip", chip)
    let id = !!chip.id ? chip.id : null;
    if (!!id) {
      if (this.clients.filter(x => x.client_employee_id == id).length) {
        this.clients.splice(this.clients.indexOf(this.clients.find(x => x.client_employee_id == id)), 1)
      } else {
        this.clients.push({
          client_employee_id: id,
          is_principal: 1
        })
      }
    }

    this.clients_ids = this.clients.map(x => x.client_employee_id);

    // if (!chip.id) {
    //   this.cliSwitch = true;

    //   setTimeout(() => {
    //     this.cliSwitch = false;
    //   }, 0)
    // }
  }


  submitForm() {
    this.isSubmit = true;

    let allClients = [...this.clients, ...this.partnerClients];
    if (!allClients || allClients.length == 0) {
      return
    }

    let sbmtData = [];
    let partsSbmtData = [];

    this.data.tasks.forEach(t_id => {

      allClients.forEach(element => {
        sbmtData.push({
          "path": `/api/task-client/`,
          "query": {company_id: this.data.company.id},
          "method": "POST",
          "body": Object.assign({
            [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
            task_id: t_id,
        }, element) 
        })
      });

      // this.form.value.client_employee_id.forEach(c_id => {
      //   sbmtData.push({
      //     "path": `/api/task-client/`,
      //     "query": {},
      //     "method": "POST",
      //     "body": Object.assign({
      //       [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
      //     }, {task_id: t_id, client_employee_id: c_id})
      //   });
      // })
    })

    for (let i = 0; i < Math.ceil(sbmtData.length/10); i++) {
      partsSbmtData.push(sbmtData.slice(i*10, 10 + i*10))
    }

    let count = 1;
    concat(...partsSbmtData.map((param) => this.taskService.multiRequest(param))).pipe(
      tap(el => {
        this.ls.requests$.next({
          value: Math.round((100 / partsSbmtData.length) * count),
          target: "Adding clients to work" 
        })
        if (count == 1) {
          this.close()
        }
        count++;
      })
    ).subscribe(res => {
      console.log(res);
    })
  }

  ngOnDestroy() {
    this.clearSubscriptions()
  }

}
