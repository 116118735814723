<mat-dialog-content class="mat-typography" dirEnter (dirSubmit)="!form.valid || isSubmit ? null : onSubmit()">
    <div class="container form_wrapper">
        <div class="title_modal">
            <div class="d-f-c" style="margin-right: 10px;">
                <app-outlet-line [channel]="data.profile" [company_id]="data.company_id"></app-outlet-line>
            </div>
            <ng-container *ngIf="data?.aiDesc; else elseTemplateAiTitle">
                <app-outlet-line></app-outlet-line>
                <ng-container *ngIf="data.post; else elseTemplateItIsPost">
                    {{ 'Write post Ai Description' | translate }}:
                </ng-container>
                <ng-template #elseTemplateItIsPost>
                    {{ 'Write channel Ai Description' | translate }}:
                </ng-template>
            </ng-container>
            <ng-template #elseTemplateAiTitle>
                {{ 'Editing a channel' | translate }}:
            </ng-template>
        </div>
        <form [formGroup]="form" class="form_with_pads form_with_valids">
            <mat-form-field *ngIf="!data?.aiDesc" appearance="standard" class="full_width_form">
                <mat-label>{{ 'Name' | translate }}</mat-label>
                <input formControlName="name" matInput placeholder="{{ 'Name' | translate }}">
            </mat-form-field>

            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ 'AI description' | translate }}</mat-label>
                <textarea 
                cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="2"
                cdkAutosizeMaxRows="6" formControlName="ai_description" matInput></textarea>
                <mat-hint class="char_counter_hint" *ngIf="form.get('ai_description').value" [ngClass]="{'red': form.get('ai_description').value.length > 999999999}" align="end">{{form.get('ai_description').value.length}}</mat-hint>
            </mat-form-field>
            
            <mat-form-field *ngIf="!data?.aiDesc" appearance="standard" class="full_width_form">
                <mat-label>{{ 'Link to the platform' | translate }}</mat-label>
                <input formControlName="url" matInput placeholder="{{ 'Link to the platform' | translate }}">
            </mat-form-field>

            
            <ng-container formGroupName="publishing_params">
                <div class="box_wrp" style="height: 40px;">
                    <mat-checkbox formControlName="made_for_kids" color="primary">{{"Made for kids" | translate}}</mat-checkbox>
                </div>
            </ng-container>
        </form>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <div class="f_w_p_btns">
        <button mat-button (click)="close()">
            {{ "Close" | translate }}
        </button>
        <button class="ml-1 d-f-btn" (click)="onSubmit()" cdkFocusInitial mat-raised-button color="primary" [disabled]="isSubmit">
            <mat-spinner class="spin_16 mr-1" *ngIf="isSubmit"></mat-spinner>  
            {{ "Save" | translate }}
        </button>
    </div>
</mat-dialog-actions>
