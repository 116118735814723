<div class="container centred_page">

    <div class="step">
        <h2>{{ 'Users' | translate }}:</h2>
        <mat-list role="list" class="users_list">
            <mat-list-item *ngFor="let user of users; let i = index;" role="listitem" class="company_item">
                <div class="company_name">
                    <mat-icon>person</mat-icon>
                    {{ user?.name }}
                </div>
                <div class="company_btns">
                    <a (click)="editUser($event, user)">
                        <mat-icon style="color: green;">edit</mat-icon>
                    </a>
                    <mat-icon (click)="deleteUser(user?.id, i)">delete</mat-icon>
                </div>
            </mat-list-item>
        </mat-list>
        <div class="btns_line">
            <button mat-raised-button (click)="goBack()">
                {{ "Back" | translate }}
            </button>
            <a class="ml-1" (click)="addUser($event)" mat-raised-button color="primary">
                <mat-icon>add</mat-icon>
                {{ "Add user" | translate }}
            </a>
        </div>
    </div>
    
</div>