import { AfterContentChecked, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { WorkToApproveComponent } from 'src/app/shared/global_components/work-to-approve/work-to-approve.component';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { AuthService } from 'src/app/shared/services/rest/auth.service';
import { MembersService } from 'src/app/shared/services/rest/members.service';
import { RefreshService } from 'src/app/shared/services/rest/refresh.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { environment } from 'src/environments/environment';
import { CreateOperationChatComponent } from '../create-operation-chat/create-operation-chat.component';
import { OperationChatsComponent } from '../operation-chats/operation-chats.component';

@Component({
  selector: 'app-work-status-edit',
  templateUrl: './work-status-edit.component.html',
  styleUrls: ['./work-status-edit.component.scss']
})
export class WorkStatusEditComponent extends BaseClass implements OnInit, AfterContentChecked, OnDestroy {
  @ViewChild('dp') dp: MatDatepicker<any>
  public operationStatuses: any;
  public host: any = environment.host;
  public operations: any;
  public user: any = {};
  public user_id: number;
  public form: FormGroup;
  public isChangeRate: boolean = false;
  public numberRegEx = /\d*\.?\d{1,2}/;
  public isFormChange: boolean = false;
  public noChat: boolean = false;

  constructor(
    private taskService: TaskService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<WorkStatusEditComponent>,
    private fb: FormBuilder,
    private auth: AuthService,
    private dialog: MatDialog,
    private cdref: ChangeDetectorRef,
    private bottomSheet: MatBottomSheet,
    private sm: StorageManagerService,
    private membersService: MembersService,
    private refreshService: RefreshService,
    private layoutService: LayoutService
  ) { super() }

  ngOnInit(): void {
    console.log("this.data WORK STATUS", this.data)
    this.getOperationsStatus();
    this.getOperations();
    
    this.form = this.fb.group({
      status_id: [this.data.work.status_id, Validators.required],
    })

    this.getUser();

    this.attachSubscriptions(
      this.refreshService.getTaskCard().subscribe(resp => {
        if (resp.company_id == this.data.company.id && this.data.task.id == resp.task_id) {
          this.close();
        }
      })
    )

    this.attachSubscriptions(
      this.form.valueChanges.subscribe((val) => {
        this.isFormChange = true        
      })
    )

    this.attachSubscriptions(
      this.form.controls.status_id.valueChanges.subscribe(value => {
        console.log(value);
        if (value == 5 && !this.data.work.chatsInfo.filter(z => z.status_id == 2 || z.status_id == 4).length && this.data.company && this.data.company.permissions.includes('create_discussion')) {
          this.noChat = true;
          const createChat = this.dialog.open(CreateOperationChatComponent, {
            disableClose: true,
            data: {
              is_create_chat_component: true,
              tasks: this.data.tasks,
              company: this.data.company,
              task: this.data.task,
              work: this.data.work,
              user: this.data.user,
              empl_status: this.data.company.permissions.includes('owner') || this.data.company.permissions.includes('admin') || this.data.company.permissions.includes('manager')
            }
          });

          this.attachSubscriptions(
            createChat.afterClosed().subscribe(result => {
              if (!!result && result.event == 'create') {
                this.noChat = false;
                this.onSubmit();
              } else {
                this.form.patchValue({
                  status_id: ''
                })
              }
            })
          )
        } else {
          this.noChat = false;
        }

        if (value == 4 || value == 98) {
          if (this.data.work.wage_updated_user_id != 0 && this.data.work.wage_updated_user_id != this.user.id) {
            this.layoutService.showSnackBar({}, marker("The performer changed the rate and price. Check this data!"), SnackBarItem)
            this.form.addControl("rate", this.fb.control(!!this.data.work.employee ? this.data.work.employee.rate : 0, Validators.pattern(this.numberRegEx)))
            this.form.addControl("price", this.fb.control(!!this.data.work.employee ? this.data.work.employee.price : 0, Validators.pattern(this.numberRegEx)))
           
            this.isChangeRate = true;
          } else {
            if (!!this.form.get("rate") || !!this.form.get("price")) {
              this.form.removeControl("rate");
              this.form.removeControl("price");
            }
            this.isChangeRate = false;
          }
        } else {
          if (!!this.form.get("rate") || !!this.form.get("price")) {
            this.form.removeControl("rate");
            this.form.removeControl("price");
          }
          this.isChangeRate = false;
        }
        this.form.updateValueAndValidity()
        console.log("out", this.form.value)
      })
    )
    

    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        if (this.isFormChange) {
          this.layoutService.openBottomSheet(this.dialogRef);
        } else {
          this.close();
        }
      })
    )

  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  getUser() {
    this.attachSubscriptions(
      this.auth.$user.subscribe(resp => {
        this.user = resp;
      })
    )
  }


  getOperationsStatus() {
    this.attachSubscriptions(
      this.taskService.getOperationsStatus().subscribe(resp => {
        console.log("operationStatuses", resp)
        this.operationStatuses = resp.slice();
      })
    )
  }

  getOperations() {
    this.attachSubscriptions(
      this.taskService.getOperations(this.data.company.id).subscribe(resp => {
        this.operations = resp;
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  onSubmit() {
    this.submitFunc()
  }

  submitFunc() {
    let data = this.form.value;

    if (!!this.data.work.employee) {
      if (data.rate && data.rate == this.data.work.employee.rate) {
        delete data.rate
      }
      if (data.price && data.price == this.data.work.employee.price) {
        delete data.price
      }
    }

    if (this.data.work.unClosedChats > 0 && data.status_id == 4) {
      let workToApprove =  this.bottomSheet.open(WorkToApproveComponent, {
        data: {
          work: this.data.work
        }
      });

      workToApprove.afterDismissed().subscribe( dData => {
        if (dData && dData.message == 'show') {
          console.log("show");
          const dialogRef = this.dialog.open(OperationChatsComponent, {
            data: {
              tasks: this.data.tasks,
              company: this.data.company,
              task: this.data.task,
              work: this.data.work,
              user: this.data.user,
              empl_status: this.data.empl_status,
              host: this.host,
              operationsValues: this.data.operationsValues
            }
          });

          this.attachSubscriptions(
            dialogRef.afterClosed().subscribe(result => {
            })
          )
          
        } if (dData && dData.message == 'approve') {
          this.data.work.unClosedChats = 0;
          this.data.work.unReadChats = 0;
          this.attachSubscriptions(
            this.taskService.editWork(this.data.work.id, {status_id: data.status_id}, this.data.company.id).pipe(
              switchMap(val => {
                if (!!this.data?.work?.employee?.id) {
                  return this.membersService.editMember(this.data.work.employee.id, data, this.data.company.id).pipe(map(() => val ))
                } else {
                  return of(val)
                }
              })
            ).subscribe(resp => {
              this.dialogRef.close({event: "update", data: resp})
            })
          )
        }
      });
      return false;
    }

    this.attachSubscriptions(
      this.taskService.editWork(this.data.work.id, {status_id: data.status_id}, this.data.company.id).pipe(
        switchMap(val => {
          if (!!this.data?.work?.employee?.id) {
            return this.membersService.editMember(this.data.work.employee.id, data, this.data.company.id).pipe(map(() => val ))
          } else {
            return of(val)
          }
        })
      ).subscribe(resp => {
        this.dialogRef.close({event: "update", data: resp})
      })
    )
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

}
