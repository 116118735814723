<div class="container cases_page">
    <div class="dashboard_wrp">
        <div class="dashboard">
            <div class="dash_item" (mouseenter)="!is_mobile ? onMouseEnter($event, 0) : null" (click)="!hoveredStatus[0].hover ? onMouseEnter($event, 0) : null;" [ngClass]="{'hovered': hoveredStatus[0].hover}">
                <div class="page_title">
                    <div class="page_name">
                        {{ "Video Processing Units (VPU)" | translate }}
                    </div>
                    <div class="chat_icon_wrp">
                        <mat-icon>movie_filter</mat-icon>
                    </div>
                </div>

                <div class="d-f-c">
                    <div class="limit" *ngIf="company">
                        <div class="spin_out">
                            <mat-progress-spinner
                                class="example-margin"
                                [color]="'primary'"
                                [mode]="'determinate'"
                                [value]="100*(company.vpu_used_total/company.vpu_limit)">
                            </mat-progress-spinner>

                            <div class="text">
                                <div class="now">{{company?.vpu_used_total}}</div>
                                <div class="split"></div>
                                <div class="lim">{{company?.vpu_limit}}</div>
                            </div>
                        </div>
                    </div>

                    <a (click)='goToUsagePage("vpu_usage")' class="link-to-page page_btn hover_viss">
                        {{ "Open" | translate }}
                    </a>
                </div>
            </div>            
            
            <div class="dash_item" (mouseenter)="!is_mobile ? onMouseEnter($event, 1) : null" (click)="!hoveredStatus[1].hover ? onMouseEnter($event, 1) : null;" [ngClass]="{'hovered': hoveredStatus[1].hover}">
                <div class="page_title">
                    <div class="page_name">
                        {{ "Storage usage" | translate }}
                    </div>
                    <div class="chat_icon_wrp">
                        <mat-icon>perm_media</mat-icon>
                    </div>
                </div>

                <div class="d-f-c">
                    <div class="limit" *ngIf="company">
                        <div class="spin_out">
                            <mat-progress-spinner
                                class="example-margin"
                                [color]="'primary'"
                                [mode]="'determinate'"
                                [value]="100*(company.original_filesize/company.filesize_limit)">
                            </mat-progress-spinner>

                            <div class="text">
                                <div class="now">{{company?.original_filesize | filesize}}</div>
                                <div class="split"></div>
                                <div class="lim">{{company?.filesize_limit | filesize}}</div>
                            </div>
                        </div>
                    </div>
                    <a (click)='goToUsagePage("total_usage")' class="link-to-page page_btn hover_viss">
                        {{ "Open" | translate }}
                    </a>
                </div>
            </div>            
            <div class="dash_item" (mouseenter)="!is_mobile ? onMouseEnter($event, 2) : null" (click)="!hoveredStatus[2].hover ? onMouseEnter($event, 2) : null;" [ngClass]="{'hovered': hoveredStatus[2].hover}">
                <div class="page_title">
                    <div class="page_name">
                        {{ "Dubbing" | translate }}
                    </div>
                    <div class="chat_icon_wrp">
                        <mat-icon>settings_voice</mat-icon>
                    </div>
                </div>

                <div class="d-f-c">
                    <div class="limit" *ngIf="company">
                        <div class="spin_out">
                            <mat-progress-spinner
                                class="example-margin"
                                [color]="'primary'"
                                [mode]="'determinate'"
                                [value]="100*(company.du_used_total/company.du_limit)">
                            </mat-progress-spinner>

                            <div class="text">
                                <div class="now">{{company?.du_used_total}}</div>
                                <div class="split"></div>
                                <div class="lim">{{company?.du_limit}}</div>
                            </div>
                        </div>
                    </div>
                    <a (click)='goToUsagePage("dubbing_usage")' class="link-to-page page_btn hover_viss">
                        {{ "Open" | translate }}
                    </a>
                </div>
            </div>            
            <div class="dash_item" (mouseenter)="!is_mobile ? onMouseEnter($event, 3) : null" (click)="!hoveredStatus[3].hover ? onMouseEnter($event, 3) : null;" [ngClass]="{'hovered': hoveredStatus[3].hover}">
                <div class="page_title">
                    <div class="page_name">
                        {{ "Speech to Text" | translate }}
                    </div>
                    <div class="chat_icon_wrp">
                        <mat-icon>record_voice_over</mat-icon>
                    </div>
                </div>

                <div class="d-f-c">
                    <div class="limit" *ngIf="company">
                        <div class="spin_out">
                            <mat-progress-spinner
                                class="example-margin"
                                [color]="'primary'"
                                [mode]="'determinate'"
                                [value]="100*(company.stu_used_total/company.stu_limit)">
                            </mat-progress-spinner>

                            <div class="text">
                                <div class="now">{{company?.stu_used_total}}</div>
                                <div class="split"></div>
                                <div class="lim">{{company?.stu_limit}}</div>
                            </div>
                        </div>
                    </div>
                    <a (click)='goToUsagePage("speech_to_text")' class="link-to-page page_btn hover_viss">
                        {{ "Open" | translate }}
                    </a>
                </div>
            </div>            
            <div class="dash_item" (mouseenter)="!is_mobile ? onMouseEnter($event, 4) : null" (click)="!hoveredStatus[4].hover ? onMouseEnter($event, 4) : null;" [ngClass]="{'hovered': hoveredStatus[4].hover}">
                <div class="page_title">
                    <div class="page_name">
                        {{ "Text to Speech" | translate }}
                    </div>
                    <div class="chat_icon_wrp">
                        <mat-icon>keyboard_voice</mat-icon>
                    </div>
                </div>

                <div class="d-f-c">
                    <div class="limit" *ngIf="company">
                        <div class="spin_out">
                            <mat-progress-spinner
                                class="example-margin"
                                [color]="'primary'"
                                [mode]="'determinate'"
                                [value]="100*(company.tsu_used_total/company.tsu_limit)">
                            </mat-progress-spinner>

                            <div class="text">
                                <div class="now">{{company?.tsu_used_total}}</div>
                                <div class="split"></div>
                                <div class="lim">{{company?.tsu_limit}}</div>
                            </div>
                        </div>
                    </div>
                    <a (click)='goToUsagePage("text_to_speech")' class="link-to-page page_btn hover_viss">
                        {{ "Open" | translate }}
                    </a>
                </div>
            </div>            
            <div class="dash_item" (mouseenter)="!is_mobile ? onMouseEnter($event, 5) : null" (click)="!hoveredStatus[5].hover ? onMouseEnter($event, 5) : null;" [ngClass]="{'hovered': hoveredStatus[5].hover}">
                <div class="page_title">
                    <div class="page_name">
                        {{ "AI usage" | translate }}
                    </div>
                    <div class="chat_icon_wrp">
                        <mat-icon>assistant</mat-icon>
                    </div>
                </div>

                <div class="d-f-c">
                    <div class="limit" *ngIf="company">
                        <div class="spin_out">
                            <mat-progress-spinner
                                class="example-margin"
                                [color]="'primary'"
                                [mode]="'determinate'"
                                [value]="100*(company.aiu_used_total/company.aiu_limit)">
                            </mat-progress-spinner>

                            <div class="text">
                                <div class="now">{{company?.aiu_used_total}}</div>
                                <div class="split"></div>
                                <div class="lim">{{company?.aiu_limit}}</div>
                            </div>
                        </div>
                    </div>
                    <a (click)='goToUsagePage("ai_usage")' class="link-to-page page_btn hover_viss">
                        {{ "Open" | translate }}
                    </a>
                </div>
            </div>            
        </div>
    </div>
</div>

