import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReplaySubject } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { RootService } from 'src/app/shared/services/rest/root.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';

@Component({
  selector: 'app-add-edit-root-promo',
  templateUrl: './add-edit-root-promo.component.html',
  styleUrls: ['./add-edit-root-promo.component.scss']
})
export class AddEditRootPromoComponent extends BaseClass implements OnInit, OnDestroy {
  public form: FormGroup;
  public isFormChange: boolean = false;
  public companyes: any;
  public compMoreControl: FormControl = new FormControl();
  public companyes$: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private companyService: CompanyService,
    private rootService: RootService,
    public dialogRef: MatDialogRef<AddEditRootPromoComponent>,
    public layoutService: LayoutService
  ) { super() }

  ngOnInit(): void {
    console.log("AddEditRootPromoComponent", this.data)
    if (this.data.promo) {
      this.form = this.fb.group({
        code: [this.data.promo.code, Validators.required],
        description: this.data.promo.description,
        description_company: this.data.promo.description_company,
        description_root: this.data.promo.description_root,
        is_initial: this.data.promo.is_initial,
      })
    } else {
      this.form = this.fb.group({
        company_id: this.data.company.id,
        code: ['', Validators.required],
        description: '',
        description_company: '',
        description_root: '',
        is_initial: 1
      })
    }

    this.attachSubscriptions(
      this.form.valueChanges.subscribe(() => this.isFormChange = true)
    )

    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        if (this.isFormChange) {
          this.layoutService.openBottomSheet(this.dialogRef);
        } else {
          this.close();
        }
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false});
  }

  submitForm() {
    let sendData:any = {};
    Object.keys(this.form.value).forEach(element => {
      if (element == 'type' || element == 'state') {
        sendData[this.form.value[element]] = 1;
      } else {
        sendData[element] = this.form.value[element]
      }
    })

    if (this.data.promo) {
      this.attachSubscriptions(
        this.rootService.editRootPromo(this.data.promo.id, sendData).subscribe(resp => {
          this.dialogRef.close({event: "close", data: resp});
        }, error => {
          console.log('error', error)
          this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
        })
      )
    } else {
      this.attachSubscriptions(
        this.rootService.addRootPromo(sendData).subscribe(resp => {
          this.dialogRef.close({event: "close", data: resp});
        }, error => {
          console.log('error', error)
          this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
        })
      )
    }
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
