import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { ConnectedAppsService } from 'src/app/shared/services/rest/connected-apps.service';

@Component({
  selector: 'app-select-content',
  templateUrl: './select-content.component.html',
  styleUrls: ['./select-content.component.scss']
})
export class SelectContentComponent extends BaseClass implements OnInit, OnDestroy {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private connectedAppsService: ConnectedAppsService,
    public dialogRef: MatDialogRef<SelectContentComponent>,
    public layoutService: LayoutService
  ) { super() }

  ngOnInit(): void {
    console.log("SelectContentComponent", this.data)
  }

  close() {
    this.dialogRef.close({event: "close", data: false});
  }

  onImgError(event){
    event.target.src = this.data.imgRoute+'/assets/img/image_black_48dp.svg'
  }

  selectVideo(content) {
    this.attachSubscriptions(
      this.connectedAppsService.editChannelContent(content.id, {thumbnail_file_id: this.data.thumb_id}).subscribe(resp => {
        this.close()
      })
    )
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
