import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { MatTable } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { concatMap, debounceTime, distinctUntilChanged, filter, last, map, switchMap, tap } from 'rxjs/operators';
import { DeleteAlertComponent } from 'src/app/shared/global_components/delete-alert/delete-alert.component';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { BaseClass } from 'src/app/shared/models/base-class';
import { GlobalDataService } from 'src/app/shared/services/common/global-data.service';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { AuthService } from 'src/app/shared/services/rest/auth.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { environment } from 'src/environments/environment';
import { ChannelGroupToEmployeeComponent } from './dialogs/channel-group-to-employee/channel-group-to-employee.component';
import { ChannelToEmployeeComponent } from './dialogs/channel-to-employee/channel-to-employee.component';
import { RulesComponent } from './dialogs/rules/rules.component';
import { EmployeeAddComponent } from './employee-add/employee-add.component';
import { EmployeeEditComponent } from './employee-edit/employee-edit.component';
import { FilesSettingsToEmployeeComponent } from './dialogs/files-settings-to-employee/files-settings-to-employee.component';
import { concat, forkJoin, fromEvent, interval, of, Subscription } from 'rxjs';
import * as moment from 'moment';
import { PositionToEmployeeComponent } from './dialogs/position-to-employee/position-to-employee.component';
import { MembersService } from 'src/app/shared/services/rest/members.service';
import { GroupToEmployeeComponent } from './dialogs/group-to-employee/group-to-employee.component';
import { UserCalendarComponent } from 'src/app/shared/global_components/user-calendar/user-calendar.component';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { AutoPositionComponent } from './dialogs/auto-position/auto-position.component';
import { FoldersPermissionsComponent } from '../dialogs/folders-permissions/folders-permissions.component';
import { PriceToEmployeeComponent } from './dialogs/price-to-employee/price-to-employee.component';
import { LoadingService } from 'src/app/shared/services/rest/loading.service';

@Component({
  selector: 'app-employees',
  templateUrl: './employees.component.html',
  styleUrls: ['./employees.component.scss']
})
export class EmployeesComponent extends BaseClass implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild("tableContainer") tableContainer: ElementRef;
  @ViewChild(MatTable) table: MatTable<any>;
  public host: any = environment.host;
  public imgRoute: any = '';
  public origin = window.location.origin;
  public user: any = {};
  public user_id: number;
  public company_id: any;
  public departments: any;
  public pos: any;
  public permissions: any;
  public company: any;
  public currencyList: any;
  public exportEmplData: any;
  public page: number = 1;
  public selectedIndex: FormControl = new FormControl(0);
  public is_deleted: number = 0;
  public pagination: any;
  public activeLang: any;
  public groups: any;

  public emplsDataSub: Subscription;
  public employees: any[];

  public displayedColumns: string[] = ["state", "timer", "Date", "User", "Status", "RSC", "Application status", "BravoregId", "Activity"]
  public isOwnerAccept: boolean = false;

  public filter: FormGroup = this.fb.group({
    count: '',
    q: '',
    content_type_id: '',
    permission: '',
    department_id: '',
    department_position_id: '',
    group_id: '',
    id: "",
  });

  
  constructor(
    private fb: FormBuilder,
    private sm: StorageManagerService,
    private auth: AuthService,
    private companyService: CompanyService,
    private router: Router,
    private ls: LoadingService,
    private taskService: TaskService,
    private membersService: MembersService,
    private languageService: LanguageService,
    private activatedRoute: ActivatedRoute,
    private globalDataService: GlobalDataService,
    private layoutService: LayoutService,
    private dialog: MatDialog,
    private bottomSheet: MatBottomSheet
  ) {
    super()
   }

  ngOnInit(): void {
    window.scroll(0,0);
    this.getCurrencyList();
    this.company_id = this.activatedRoute.snapshot.queryParamMap.get('company_id');
    
    this.getUser();
    this.getImgRoute();
    this.getCsrf();
    this.getGroupsCompany();
    // this.getEmployees(this.page);
    
    this.getPermissions();
    this.getLangData();
    this.getDepartmentsDyn();
    this.getPositionsDyn();
    // this.getCompany();

    this.attachSubscriptions(
      interval(1000).pipe(
        filter(() => this.company && this.employees && this.employees.length > 0)
      ).subscribe(() => {
        this.employees.filter(x => !!x.workDuration).forEach(x => {
          x.workDuration += 1;
        }) 
      })
    )

    this.onRouteChange()
  }

  removeValueFromMultiple(key, value) {
    let arr = this.filter.value[key];

    console.log(key, value);
    console.log(arr);
    
    if (arr.includes(value)) {
      arr.splice(arr.indexOf(value), 1)
    }
    console.log(arr);
    console.log(this.filter.value[key]);
    // return 
    // this.filter.patchValue({
    //   [key]: arr
    // })
    this.filterSettins()
  }

  onRouteChange() {
    this.attachSubscriptions(
      this.activatedRoute.queryParams.pipe(
        tap((val) => {
          
          console.log("getEmployees-onRouteChange")
          if (this.company_id != this.activatedRoute.snapshot.queryParamMap.get('company_id')) {
            // this.getProfiles();
            // this.getAllValues();
            // this.getGroupsCompany();
            // this.getProfiles();
            // this.getTaskStatus();
            // this.getProfilesStatus();
            // this.getEmployees();
            // this.getChecklists();
            // this.getPlatforms();
            this.getGroupsCompany();
            this.getDepartmentsDyn();
            this.getPositionsDyn();
            this.getLangData();
          }
          this.company_id = this.activatedRoute.snapshot.queryParamMap.get('company_id');
          this.filter.patchValue({
            count: this.activatedRoute.snapshot.queryParamMap.get('count') ? this.activatedRoute.snapshot.queryParamMap.get('count') : '/',
            q: !!this.activatedRoute.snapshot.queryParamMap.get('q') ? this.activatedRoute.snapshot.queryParamMap.get('q') : '',
            permission: !!this.activatedRoute.snapshot.queryParamMap.get('permission') ? this.activatedRoute.snapshot.queryParamMap.get('permission') : '',
            department_id: !!this.activatedRoute.snapshot.queryParamMap.get('department_id') ? +this.activatedRoute.snapshot.queryParamMap.get('department_id') : '',
            department_position_id: !!this.activatedRoute.snapshot.queryParamMap.get('department_position_id') ? +this.activatedRoute.snapshot.queryParamMap.get('department_position_id') : '',
            group_id: !!this.activatedRoute.snapshot.queryParamMap.get('group_id') ? +this.activatedRoute.snapshot.queryParamMap.get('group_id') : '',
          })
          console.log("getEmployees-FILTER VALUE", this.filter.value)
        }),
        switchMap((val) => {
          console.log("switchMap", this.company)
          if (this.company == undefined || this.company.id != this.activatedRoute.snapshot.queryParamMap.get('company_id')) {
            return this.companyService.getCompany(this.activatedRoute.snapshot.queryParamMap.get('company_id')).pipe(
              map(x => x[0]),
              tap(x => {
                this.company = x;
                this.companyService.company$.next(x);
              }),
              map(x => val)
            )
          } else {
            return of(val)
          }
        }),
      ).subscribe(params => {
        console.log("getEmployees-Files route STREAM 1", params);
        this.page = 1;
        this.employees = [];
        // if (this.table) {
        //   this.table.renderRows();
        // }
        if (this.emplsDataSub) {
          this.emplsDataSub.unsubscribe()
        }
        this.emplsDataSub = this.taskService.getEmployeesData().pipe(
          concatMap(itemsInPage => {
            return concat(...itemsInPage.map(itemInPage => this.neededEmployeesData(itemInPage))).pipe(last(),map(x => itemsInPage))
          }),
        ).subscribe(resp => console.log("-----getEmployeesDataStream-----",resp));
        console.log("getEmployees-Files route STREAM 2", params);
        this.getEmployees(this.page);
        // if (!!this.activatedRoute.snapshot.queryParamMap.get('groups_partner_id') && this.groups_partner_id != +this.activatedRoute.snapshot.queryParamMap.get('groups_partner_id')) {
        //   this.groups_partner_id = +this.activatedRoute.snapshot.queryParamMap.get('groups_partner_id');
        //   this.getPartnerGroups()
        // }
        // this.getBookmarks();
      })
    )
  }

  neededEmployeesData(empl) {
    let arr = []

    if (true) {
      arr.push(
        this.taskService.getEmployeeRules(empl.id, this.company_id).pipe(
          tap(res => {
            empl.rules = res.filter(x => ['admin', 'manager', 'manage_channel_account', 'see_files', 'create_discussions'].includes(x.permission));
          })
        )
      )
    }

    return forkJoin(arr)
  }

  getGroupsCompany() {
    this.attachSubscriptions(
      this.companyService.getInfiniteGroupsCompany(this.company_id, '1', '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.companyService.getInfiniteGroupsCompany(this.company_id, x).pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        this.groups = [].concat(...res);
      })
    )
  }

  getGroupById(id) {
    if (!this.groups || this.groups.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.groups.find(el => el.id == id)
  }
  
  getDepById(id) {
    if (!this.departments || this.departments.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.departments.find(el => el.id == id)
  }

  getPosById(id) {
    if (!this.pos || this.pos.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.pos.find(el => el.id == id)
  }

  getDepartmentsDyn() {
    this.attachSubscriptions(
      this.companyService.getDepartmentsDyn('1', this.company_id, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }
          return forkJoin(arr.map(x => this.companyService.getDepartmentsDyn(x, this.company_id, '200').pipe(map(u => u.body)))).pipe(
            last(),
            tap(values => {
              let conValues = [].concat(...values)
              this.departments = conValues;
            }),
          )
        }),
      ).subscribe(resp => {
        console.log("getDepartmentsDyn resp", resp);
        console.log("getDepartmentsDyn departments", this.departments);
      })
    )
  }

  getPermissions() {
    this.attachSubscriptions(
      this.taskService.getRules().subscribe(resp => {
        this.permissions = resp;
        console.log("getPermissions", this.permissions);
      })
    )
  }

  getPositionsDyn() {
    this.attachSubscriptions(
      this.companyService.getPositionsDyn('1', this.company_id, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }
          return forkJoin(arr.map(x => this.companyService.getPositionsDyn(x, this.company_id, '200').pipe(map(u => u.body)))).pipe(
            last(),
            tap(values => {
              let conValues = [].concat(...values)
              this.pos = conValues;
            }),
          )
        }),
      ).subscribe(resp => {
        console.log("getPositionsDyn resp", resp);
        console.log("getPositionsDyn positions", this.pos);
      })
    )
  }

  removeValue(val) {
    if (val == "all") {
      let params = {
        company_id: this.company_id,
        page: this.activatedRoute.snapshot.queryParamMap.get('page')
      }
      this.router.navigate(['/settings'], {queryParams: params})
    } else {
      let params = {...this.activatedRoute.queryParamMap.source['_value']}
      delete params[val]

      this.router.navigate(['/settings'], {queryParams: params})
    }
  }

  

  filterSettins() {
    let params = {
      company_id: this.company_id,
      page: this.activatedRoute.snapshot.queryParamMap.get('page')
    }

    let filterData = {...this.filter.value};

    console.log("filterData", filterData)
    
    Object.keys(filterData).filter(x => x != 'preset_id').forEach(element => {
      if (['is_content_url', 'part_of_count', 'consist_of_count', 'related_count'].includes(element) && filterData[element] == '0') {
        params[element] = filterData[element]
      } else if (["group_id", "channel_id", "no_channel_id", "operation_employee_id", "operation_partner_company_id", 'task_parameter_value_id', 'parameter_value_id', 'file_parameter_value_id'].includes(element) && filterData[element].length > 0) {
        params[element] = filterData[element].join(',')
      } else if (filterData[element] == '0' && filterData[element] == 0 && (element == 'operation_employee_id' || element == 'operation_partner_company_id')) {
        params[element] = filterData[element]
      } else if (filterData[element] != '0' && filterData[element] != '') {
        params[element] = filterData[element]
      }
    });


    // console.log("filterData.operation_completed_at_to", filterData.operation_completed_at_to)
    if (filterData.operation_completed_at_to === 0) {
      params['operation_completed_at_to'] = '0';
    }
    
    console.log("proj params", params)

    this.router.navigate(['/settings'], {queryParams: params})
  }

  changeTab(e) {
    this.selectedIndex.patchValue(e);
    this.is_deleted = this.selectedIndex.value;
    this.page = 1;
    this.pagination = undefined;
    this.getEmployees(this.page)
  }

  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData().subscribe(resp => {
        this.activeLang = resp.active;
      })
    )
  }

  foldersPermissions(empl) {
    const dialogRef = this.dialog.open(FoldersPermissionsComponent, {
      backdropClass: ['parameters_modal_backdrop'],
      panelClass: ['parameters_modal'],
      data: {
        company_id: this.company.id,
        company: this.company,
        imgRoute: this.imgRoute,
        host: this.host,
        employee: empl
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log("getONE BEFORE", result);
        this.page = 1;
        this.getEmployees(this.page);
      })
    )
  }
  
  userCalendar(empl) {
    const dialogRef = this.dialog.open(UserCalendarComponent, {
      backdropClass: ['back_user_calendar_schedule'],
      panelClass: ['panel_user_calendar_schedule'],
      data: {
        company_id: this.company.id,
        company: this.company,
        imgRoute: this.imgRoute,
        host: this.host,
        employee: empl
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log("getONE BEFORE", result);
      })
    )
  }

  getStatusName(val) {
    switch (val) {
      case 1:
        return "Waiting"
        break;
    
      case 2:
        return "To correct"
        break;
    
      case 3:
        return "To approve"
        break;
    
      case 4:
        return "Approved"
        break;
      default:
        return marker("Set status");
        break;
    }
  }

  getCompany() {
    this.attachSubscriptions(
      this.companyService.getCompany(this.company_id).subscribe(resp => {
        this.company = resp[0];
        this.companyService.company$.next(resp[0]);
      })
    )
  }


  Accept(member) {
    this.attachSubscriptions(
      this.taskService.addVuser({company_id: this.company_id, user_id: member.user_id}).subscribe(resp => {
        this.page = 1;
        this.getEmployees(this.page);
      })
    )
  }

  openChannelToEmployee(empl) {
    const dialogRef = this.dialog.open(ChannelToEmployeeComponent, {
      data: {
        company: this.company,
        company_id: this.company_id,
        user: this.user,
        employee: empl,
        fromEmployees: true
      }
    });

    // this.attachSubscriptions(
    //   dialogRef.afterClosed().subscribe(result => {
    //   })
    // )
  }

  openFilesSettingsToEmployee(empl) {
    const dialogRef = this.dialog.open(FilesSettingsToEmployeeComponent, {
      data: {
        company: this.company,
        company_id: this.company_id,
        user: this.user,
        employee: empl,
        fromEmployees: true
      }
    });

    // this.attachSubscriptions(
    //   dialogRef.afterClosed().subscribe(result => {
    //   })
    // )
  }

  exportEmplSettings(empl) {
    console.log("exportEmplSettings", empl)
    this.attachSubscriptions(
      this.membersService.getChannelGroupToEmployee(this.company_id, {employee_id: empl.id}).subscribe(resp => {
        console.log("getChannelGroupToEmployee", resp)
        this.exportEmplData = {
          employee_id: empl.id,
          employee: empl,
          groupsSettings: resp
        }
      })
    )
  }

  

  exportEmplToJobSettings(empl) {
    console.log("exportEmplToJobSettings", empl)
    this.attachSubscriptions(
        this.membersService.getAutoEmplPosDyn(this.company.id, {employee_id: empl.id, task_company_id: 0}, '1', '1').pipe(
          switchMap(el => {
            let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
            let arr = [];
            if (pages == 0) {
              pages = 1;
            }
  
            for (let index = 1; index <= pages; index++) {
              arr.push(index)
            }
            
            return forkJoin(arr.map(x => this.membersService.getAutoEmplPosDyn(this.company.id, {employee_id: empl.id, task_company_id: 0}, x, '200').pipe(map(u => u.body)))).pipe(
              last(),
            )
          }),
        ).subscribe(res => {

          let allEmplToJobSettings = [].concat(...res)
          
          if (allEmplToJobSettings && allEmplToJobSettings.length) {
            this.exportEmplData = {
              employee_id: empl.id,
              employee: empl,
              emplToJobSettings: allEmplToJobSettings
            }
          }
          console.log("exportEmplToJobSettings", this.exportEmplData)
        })
      
    )
  }

  importEmplToJobSettings(empl) {
    console.log("importEmplSettings", empl)
    console.log("this.exportEmplData", this.exportEmplData)

    let sbmtData = [];
    let partsSbmtData = [];

    this.exportEmplData.emplToJobSettings.forEach((jobSet, i) => {
      let bodyData:any = {
        company_id: jobSet.company_id,
        group_id: jobSet.group_id,
        operation_id: jobSet.operation_id,
        is_task_client: jobSet.is_task_client,
        is_task_client_principal: jobSet.is_task_client_principal,
        is_task_employee: jobSet.is_task_employee,
        is_task_employee_assistant: jobSet.is_task_employee_assistant,
        is_task_partner_employee: jobSet.is_task_partner_employee,
        is_task_partner_employee_assistant: jobSet.is_task_partner_employee_assistant,
        parameter_value_id: jobSet.parameter_value_id,
        employee_id: empl.id
      }
      sbmtData.push({
        "path": `/api/automation-employee-position/`,
        "query": {company_id: this.company.id},
        "method": "POST",
        "body": Object.assign({
          [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
        }, bodyData)
      });
    })

    for (let i = 0; i < Math.ceil(sbmtData.length/10); i++) {
      partsSbmtData.push(sbmtData.slice(i*10, 10 + i*10))
    }

    this.ls.requests$.next({
      value: 0,
      target: "Importing employee to gob settings"
    })

    let count = 0;
    concat(...partsSbmtData.map((param) => this.taskService.multiRequest(param))).pipe(
      tap(el => {
        this.ls.requests$.next({
          value: Math.round((100 / partsSbmtData.length) * (count+1)),
          target: "Importing employee to gob settings"
        })
        count++;
      })
    ).subscribe(res => {
      console.log(res);
    })
  }

  importEmplSettings(empl) {
    console.log("importEmplSettings", empl)
    console.log("this.exportEmplData", this.exportEmplData)
    
    let multiData = [];

    this.exportEmplData.groupsSettings.forEach(groupSet => {
      multiData.push({
        "path": `/api/channel-group-to-employee/`,
        "query": {company_id: this.company_id},
        "method": "POST",
        "body": {
          company_id: this.company_id,
          employee_id: empl.id,
          channel_group_id: groupSet.channel_group_id
        }
      })
    });

    this.attachSubscriptions(
      this.taskService.multiRequest(multiData).subscribe(resp => {
        this.layoutService.showSnackBar({name: ''}, `Group settings have been successfully exported from member ${this.exportEmplData.employee.name} to ${empl.name}`, SnackBarItem)
      })
    )
  }

  autoPrice(empl) {
    const dialogRef = this.dialog.open(PriceToEmployeeComponent, {
      data: {
        company: this.company,
        company_id: this.company_id,
        user: this.user,
        employee: empl,
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        this.page = 1;
        this.getEmployees(this.page);
      })
    )
  }
  
  positions(empl) {
    const dialogRef = this.dialog.open(PositionToEmployeeComponent, {
      data: {
        company: this.company,
        company_id: this.company_id,
        user: this.user,
        employee: empl,
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        this.page = 1;
        this.getEmployees(this.page);
      })
    )
  }

  openGroupToEmployee(empl) {
    const dialogRef = this.dialog.open(GroupToEmployeeComponent, {
      data: {
        company: this.company,
        company_id: this.company_id,
        user: this.user,
        employee: empl,
        fromEmployees: true
      }
    });

    // this.attachSubscriptions(
    //   dialogRef.afterClosed().subscribe(result => {
    //   })
    // )
  }
  
  posAutos(empl) {
    const dialogRef = this.dialog.open(AutoPositionComponent, {
      data: {
        company: this.company,
        company_id: this.company_id,
        user: this.user,
        employee: empl,
        is_partner: false,
        activeLang: this.activeLang,
        imgRoute: this.imgRoute,
        host: this.host,
      }
    });

    // this.attachSubscriptions(
    //   dialogRef.afterClosed().subscribe(result => {
    //   })
    // )
  }
  openChannelGroupToEmployee(empl) {
    const dialogRef = this.dialog.open(ChannelGroupToEmployeeComponent, {
      data: {
        company: this.company,
        company_id: this.company_id,
        user: this.user,
        employee: empl,
        fromEmployees: true
      }
    });

    // this.attachSubscriptions(
    //   dialogRef.afterClosed().subscribe(result => {
    //   })
    // )
  }
  
  rules(empl) {
    const dialogRef = this.dialog.open(RulesComponent, {
      data: {
        company: this.company,
        company_id: this.company_id,
        user: this.user,
        employee: empl
      }
    });

    // this.attachSubscriptions(
    //   dialogRef.afterClosed().subscribe(result => {
    //   })
    // )
  }

  ngAfterViewInit() {
    this.tableContainer.nativeElement.scrollTop = 0;
    this.onScroll();
  }

  onScroll() {
    this.attachSubscriptions(
      fromEvent(this.tableContainer.nativeElement, "scroll").pipe(
        filter((e:any) => e.target.scrollTop >=  e.target.scrollHeight - e.target.offsetHeight - 400),
        map(() => this.tableContainer.nativeElement.scrollTop),
        debounceTime(300),
        map(y => Math.ceil((y + this.tableContainer.nativeElement.innerHeight)/ (400))),
        distinctUntilChanged()
      ).subscribe(() => {
          if (this.page <= this.pagination['pageCount']) {
            this.getEmployees(this.page);
          }
        }
      )
    )
  }

  getEmployees(page) {
    let filter = {...this.filter.value}
    let params:any = {
      is_deleted: this.is_deleted
    }
    Object.keys(filter).forEach(key => {
      console.log("getEmployees-filter[key]", key, filter[key])
      if (!!filter[key]) {
        params[key] = filter[key];
      }
    })

    console.log("getEmployees-params", params)
    this.attachSubscriptions(
      this.taskService.getEmployeesDataDyn(page, this.company_id, params).pipe(
        tap(el => {
          this.pagination = {
            'pageCount': el.headers.get('x-pagination-page-count'),
            'perPage': el.headers.get('x-pagination-per-page'),
            'totalCount': el.headers.get('x-pagination-total-count'),
            'currentPage': el.headers.get('x-pagination-current-page'),
          }
        }),
        map(x => x.body),
        tap(res => {
          this.taskService.employeesData$.next(res)
        })
      ).subscribe(resp => {
        resp.forEach(x => {
          if (['active', 'break'].includes(x.status) && !!x.status_active_at) {
            
            let currentTime = moment();

            // Создание объекта Moment из временного штампа
            let timestampMoment = moment.unix(this.company.employees[0].status_active_at);

            // Вычисление разницы во времени
            x.workDuration = +(currentTime.diff(timestampMoment) / 1000).toFixed(0);
          }
        })
        if (page == 1) {
          this.employees = resp;
        } else {
          this.employees.push(...resp);
        }
        console.log("getEmployees-" + this.page, this.employees)
        if (!this.is_deleted && (this.filter.value.q == '' && this.filter.value.department_id == '' && this.filter.value.department_position_id == '' && this.filter.value.group_id == '' && this.filter.value.permission == '') && this.pagination.totalCount == this.employees.length && this.employees.filter((x:any) => x.user_id == this.user.id).length == 0) {
          this.employees.push({
            application_status_id: 0,
            company_id: this.company_id,
            created_at: 0,
            currency_id: 0,
            employee_count: null,
            id: 0,
            invite: "",
            is_application_done: 1,
            rate_target: "0.0000",
            salary: "0.0000",
            updated_at: 1648118613,
            user_id: this.user.id,
            vuser_id: 0,
            user: this.user,
            mustAccept: true
          })
        }

        this.page++;
        this.table.renderRows();
      })
    )
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  getCsrf() {
    this.attachSubscriptions(
      this.auth.$userToken.subscribe(resp => {
        this.user_id = resp.user_id;
        this.sm.localStorageSetItem("csrf_param", resp.csrf_param)
        this.sm.localStorageSetItem("csrf_token", resp.csrf_token)
      })
    )
  }

  getUser() {
    this.attachSubscriptions(
      this.auth.$user.subscribe(resp => {
        this.user = resp;
      })
    )
  }

  deleteEmployee(id) {
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: {},
        target: marker("employee")
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        this.attachSubscriptions(
          this.taskService.editEmployee(id, {is_deleted: 1}).subscribe(resp => {
            this.page = 1;
            this.getEmployees(this.page);
          })
        )
      }
    });
  }

  undeleteEmployee(id) {
    this.attachSubscriptions(
      this.taskService.editEmployee(id, {is_deleted: 0}).subscribe(resp => {
        this.page = 1;
        this.getEmployees(this.page);
      })
    )
  }

  removeInvite(id, vuser_id) {
    let form = {
      company_id: this.company_id,
      revoke_invite: 1,
      employee: {
        user_id: vuser_id
      }
    }

    this.attachSubscriptions(
      this.taskService.editEmployee(id, form).subscribe(resp => {
        resp.vuser = this.employees.find(el => el['id'] == id)['vuser'];
        let index = this.employees.indexOf(this.employees.find(el => el['id'] == id));
        this.employees.splice(index, 1, resp)
        this.table.renderRows();
      })
    )
  }

  generateInvite(id, vuser_id) {
    let form = {
      company_id: this.company_id,
      reset_invite: 1,
      employee: {
        user_id: vuser_id
      }
    }
    this.attachSubscriptions(
      this.taskService.editEmployee(id, form).subscribe(resp => {
        resp.vuser = this.employees.find(el => el['id'] == id)['vuser'];
        let index = this.employees.indexOf(this.employees.find(el => el['id'] == id));
        this.employees.splice(index, 1, resp)
        this.table.renderRows();
      })
    )
  }




  copyInvite(emp) {
    this.layoutService.showSnackBar(emp, marker("Copied"), SnackBarItem)
  }

  getCurrency(id) {
    if (!!this.currencyList) {
      return this.currencyList.find(el => el.id == id)
    }
  }

  getCurrencyList() {
    this.attachSubscriptions(
      this.globalDataService.getCurrencies().subscribe(resp => {
        console.log("getCurrencyList", resp)
        this.currencyList = resp.slice();
      })
    )
    // this.attachSubscriptions(
    //   this.companyService.getCurrencyList().subscribe(resp => {
    //     console.log("getCurrencyList", resp);
    //     this.currencyList = resp
    //   })
    // )
  }

  goBack() {
    history.back()
  }

  goToEmplApp(e, emp_id) {
    e.preventDefault();
    this.router.navigate(['/settings'], { queryParams: {company_id: this.company_id, page: 'questionnaires', id: emp_id} });
  }
  
  addEmployee(e) {
    e.preventDefault();
    const dialogRef = this.dialog.open(EmployeeAddComponent, {
      disableClose: true,
      data: {
        user: this.user,
        company: this.company,
        company_id: this.company_id,
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result && result.event == "update") {
          this.page = 1;
          this.getEmployees(this.page);
        }
      })
    )
  }

  
  editEmployee(element) {
    // e.preventDefault();
    const dialogRef = this.dialog.open(EmployeeEditComponent, {
      disableClose: true,
      data: {
        user: this.user,
        company: this.company,
        company_id: this.company_id,
        employee: element
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result && result.event == "update") {
          this.page = 1;
          this.getEmployees(this.page);
        }
      })
    )
  }
  
  ngOnDestroy(): void {
    this.clearSubscriptions();
  }
}
