<h2 mat-dialog-title class="dialog_title">
    <span (click)="log()">
        {{ "Speech to Text" | translate }} <ng-container *ngIf="!!data?.files">{{"selected" | translate}} {{data?.files?.length}} {{'files' | translate}}</ng-container>
        
    </span>
    <div class="d-f-c">
        <button mat-icon-button (click)="refresh()" *ngIf="!data.files">
            <mat-icon [ngClass]="{'rotateble': !!isRefreshed}">refresh</mat-icon>
        </button>
        <button mat-icon-button (click)="close()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
</h2>
<mat-dialog-content class="mat-typography">
    <div class="container">
        <ng-container *ngIf="data.files; else elseTemplateMulti">
            <div class="dub_files" style="padding: 0 20px;" [appDeviceClass]="'custom-scroll custom-scroll-t'" *ngIf="data.files && data.files.length">
                <div class="dub alert_text_out" *ngFor="let file of data.files">
                    <div class="file_info">
                        <div class="filename text_one_line">{{ file?.filename | translate }}</div>
                        <div class="tags" style="color: #3f51b5;" *ngIf="file.primaryTags">
                            <ng-container *ngFor="let prim of file.primaryTags; let last = last">
                              <span>{{prim?.parameterValue?.value}}</span>
                              <ng-container *ngIf="!last">, </ng-container>
                            </ng-container>
                        </div>
                    </div>
                    <div class="file_lang" *ngIf="file.stt" style="position: relative;">    
                        <mat-form-field appearance="standard" class="full_width_form" [ngClass]="{'not_active_field': !!file.stt.detect}">
                            <mat-label>{{ "Language" | translate }}</mat-label>
                            <mat-spinner class="spin_24 mr-1" matPrefix *ngIf="file.stt.is_getting"></mat-spinner>
                            <mat-select [disableOptionCentering]="true" selectDir [multiple]="false" [(ngModel)]="file.stt.language">
                                <mat-select-trigger class="multi_users">
                                    <ng-container *ngIf="file.stt.language">
                                        <span>{{ getLangByVal(file.stt.language)?.value }}</span>
                                    </ng-container>
                                </mat-select-trigger>
                                <mat-option>
                                    <ngx-mat-select-search 
                                    placeholderLabel="{{ 'Search' | translate }}" 
                                    noEntriesFoundLabel="{{ 'no matching language found' | translate }}"
                                    [formControl]="langsControl"></ngx-mat-select-search>
                                </mat-option>
                                <ng-container *ngFor="let item of langs$ | async">
                                    <mat-option *ngIf="!!item.iso_value" [value]="item.iso_value">
                                        <span>{{ item.value }}</span>
                                    </mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>

                        <div class="alert_text">
                            {{"What is the language of the speech in the file?" | translate}}
                        </div>
    
                        <div class="split"></div>
    
                        <div class="d-f-c" style="height: 40px; flex-shrink: 0;">
                            <mat-checkbox color="primary" [(ngModel)]="file.stt.detect">{{"Detect Language" | translate}}</mat-checkbox>
                        </div>
                    </div>
                </div>
            </div>
            <div class="btns_line" style="padding: 15px 20px;">
                <button style="flex-shrink: 0;" [disabled]="isSubmit" (click)="transcribeFile()" mat-raised-button cdkFocusInitial color="primary">
                    {{ "Speech to Text" | translate }}
                </button>
            </div>
        </ng-container>
        <ng-template #elseTemplateMulti>            
            <form [formGroup]="form" class="alert_text_out" style="position: relative;">
                <mat-form-field appearance="standard" class="full_width_form" [ngClass]="{'not_active_field': !!form.get('detect').value}">
                    <mat-spinner class="spin_24 mr-1" matPrefix *ngIf="isGettingTag"></mat-spinner>
                    <mat-label>{{ "Language" | translate }}</mat-label>
                    <mat-select [disableOptionCentering]="true" selectDir [multiple]="false" formControlName="language">
                        <mat-select-trigger class="multi_users">
                            <ng-container *ngIf="form.get('language').value">
                                <span>{{ getLangByVal(form.get('language').value)?.value }}</span>
                            </ng-container>
                        </mat-select-trigger>
                        <mat-option>
                            <ngx-mat-select-search 
                            placeholderLabel="{{ 'Search' | translate }}" 
                            noEntriesFoundLabel="{{ 'no matching language found' | translate }}"
                            [formControl]="langsControl"></ngx-mat-select-search>
                        </mat-option>
                        <ng-container *ngFor="let item of langs$ | async">
                            <mat-option *ngIf="!!item.iso_value" [value]="item.iso_value">
                                <span>{{ item.value }}</span>
                            </mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>

                <div class="alert_text" style="visibility: visible !important; opacity: 1 !important; margin-left: 14px !important;">
                    {{"What is the language of the speech in the file?" | translate}}
                </div>
    
                <div class="d-f-c" style="height: 40px; flex-shrink: 0;">
                    <mat-checkbox color="primary" formControlName="detect">{{"Detect Language" | translate}}</mat-checkbox>
                </div>
    
                <button style="flex-shrink: 0;" (click)="transcribeFile()" mat-raised-button cdkFocusInitial color="primary">
                    {{ "Speech to Text" | translate }}
                </button>
            </form>
            <div #tableContainer class="example-container" [appDeviceClass]="'custom-scroll custom-scroll-t'">
                <table mat-table [dataSource]="reqs" class="centred_cols" multiTemplateDataRows>
                    <!-- ["Date", "Order", "Operation type", "Text"] -->
                    <ng-container matColumnDef="Date">
                        <th mat-header-cell *matHeaderCellDef> {{ "Created at" | translate }} </th>
                        <td mat-cell *matCellDef="let element">
                            <ng-container *ngIf="element?.created_at > today && element?.created_at < todayEnd; else createdAtTempl">
                                <span #tooltip="matTooltip"
                                matTooltip="{{ element?.created_at*1000 | date:'MMM d, y':'':activeLang | yearPipe }}"
                                [matTooltipPosition]="'above'">
                                {{ element?.created_at*1000 | date:'hh:mm a':'':activeLang }}
                                </span>
                            </ng-container>
                            <ng-template #createdAtTempl>
                                <span *ngIf="element?.created_at && element?.created_at > 30000" #tooltip="matTooltip"
                                matTooltip="{{ element?.created_at*1000 | date:'hh:mm a':'':activeLang | yearPipe }}"
                                [matTooltipPosition]="'above'">
                                {{ element?.created_at*1000 | date:'MM.dd.yy':'': activeLang }}
                                </span>
                            </ng-template>
                        </td>
                    </ng-container>
    
                    <ng-container matColumnDef="Status">
                        <th mat-header-cell *matHeaderCellDef> {{ "Status" | translate }} </th>
                        <td mat-cell *matCellDef="let element">
                            <span *ngIf="!!element.is_done">{{"Done" | translate}}</span>
                            <span *ngIf="!!element.is_error">{{"Error" | translate}}</span>
                            <span *ngIf="!!element.is_new">{{"New" | translate}}</span>
                            <span *ngIf="!!element.is_processing">{{"Processing" | translate}}</span>
                        </td>
                    </ng-container>
    
                    <ng-container matColumnDef="Language">
                        <th mat-header-cell *matHeaderCellDef> {{ "Language" | translate }} </th>
                        <td mat-cell *matCellDef="let element">
                            <span>{{element?.language}}</span>
                        </td>
                    </ng-container>
    
                    <ng-container matColumnDef="Costs">
                        <th mat-header-cell *matHeaderCellDef> {{ "Costs" | translate }} </th>
                        <td mat-cell *matCellDef="let element">
                            <span>{{element?.price_unit}}</span>
                        </td>
                    </ng-container>
                        
                    <ng-container matColumnDef="expandedDetail">
                        <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                
                        <div class="row student-element-detail" [@detailExpand]="element.isExpanded ? 'expanded' : 'collapsed'">
    
                            <ng-container *ngIf="!!element.is_error">
                                <b style="color: rgb(161, 31, 31);">{{element?.error_message}}</b>
                                <p class="text" style="color: rgb(230, 92, 92);">{{element?.debug_message}}</p>
                            </ng-container>
                            
                            <p class="text">
                                {{element?.transcription_plain}}
                            </p>
                        </div>
                        </td>
                    </ng-container>
                
                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="student-element-row cp"
                    [class.student-expanded-row]="element.isExpanded"
                    (click)="rowClick(element)"></tr>
        
                    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="student-detail-row"></tr>
                </table>
            </div>
        </ng-template>   
    </div>
</mat-dialog-content>