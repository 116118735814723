import { animate, state, style, transition, trigger } from "@angular/animations";
import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from "@angular/material-moment-adapter";
import { MatButtonToggleChange } from "@angular/material/button-toggle";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatTable } from "@angular/material/table";
import { Router } from "@angular/router";
import { marker } from "@biesbjerg/ngx-translate-extract-marker";
import * as moment from "moment";
import { element } from "protractor";
import { forkJoin, fromEvent, ReplaySubject, Subscription } from "rxjs";
import { catchError, debounceTime, filter, finalize, last, map, switchMap, tap } from "rxjs/operators";
import { OperationChatsComponent } from "src/app/components/atTasksDialog/operation-chats/operation-chats.component";
import { MY_FORMATS } from "src/app/components/atTasksDialog/task-profile-add/task-profile-add.component";
import { OpenJobComponent } from "src/app/components/workspace-pages/cases/dialogs/open-job/open-job.component";
import { OpenTaskComponent } from "src/app/components/workspace-pages/cases/dialogs/open-task/open-task.component";
import { ChatsComponent } from "src/app/components/chats/chats.component";
import { casesModel } from "../../functions/casesModel";
import { makeRandomString } from "../../functions/randomString";
import { BaseClass } from "../../models/base-class";
import { GlobalDataService } from "../../services/common/global-data.service";
import { LanguageService } from "../../services/common/language.service";
import { StorageManagerService } from "../../services/common/storage-manager.service";
import { ChatService } from "../../services/rest/chat.service";
import { CompanyService } from "../../services/rest/company.service";
import { FileService } from "../../services/rest/file.service";
import { TaskService } from "../../services/rest/task.service";
import { environment } from "src/environments/environment";

@Component({
    selector: 'notifications-bar',
    templateUrl: 'notifications-bar.html',
    styleUrls: ['./notifications-bar.scss'],
    providers: [
      {
        provide: DateAdapter,
        useClass: MomentDateAdapter,
        deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
      },
      { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
      {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
    ],
    animations: [
      trigger('detailExpand', [
        state('collapsed', style({height: '0px', minHeight: '0'})),
        state('expanded', style({height: '*'})),
        transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      ]),
    ],
  })

export class NotificationsBar extends BaseClass implements OnInit, OnDestroy {
  @ViewChild("not_container") not_container: ElementRef;
  @ViewChild(MatTable) table: MatTable<any>;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<NotificationsBar>,
    private taskService: TaskService,
    private sm: StorageManagerService,
    private dialog: MatDialog,
    private languageService: LanguageService,
    private chatService: ChatService,
    private fileService: FileService,
    private _adapter: DateAdapter<any>,
    private fb: FormBuilder,
    private companyService: CompanyService,
    private globalDataService: GlobalDataService,
    private router: Router
  ) { super() }
  public notifications: any;
  public activeLang: any;
  public actions: any;
  public imgRoute: any = '';
  public host: any = environment.host;
  public timeZone: any;
  public allContentTypes: any;
  public employees: any;
  public pageByScroll$: Subscription;
  public page: number = 1;
  public contentPage: number = 1;
  public contentPagination: any;
  public groups: any;
  public is_mobile: boolean;
  public taskStatuses: any;
  public profileStatuses: any;
  public profiles: any[] = [];
  public filter: FormGroup;
  public dragPosition = {x: 0, y: 0};
  public displayedColumns: string[] = ["Date", "User", "Info", "Job"];
  public todaysDate = new Date().setHours(0,0,0,0);
  public randomString = makeRandomString();
  public operations: any;
  public operationStatuses: any;
  public hasSound: boolean = this.sm.localStorageGetItem("hasSound") == "0" ? false : true
  public groupsControl: FormControl = new FormControl();
  public groups$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public employees$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public execControl: FormControl = new FormControl();
  
  ngOnInit(): void {
    console.log("notifications", this.data)
    this.filter = this.fb.group({
      group_id: '',
      employee_id: this.data.employee_id ? this.data.employee_id : '',
      action: '',
      original_action_id: '',
      created_at_from: '',
      created_at_to: ''
    })
    
    this.checkIsMobile();
    this.getAllActions();
    this.getEmployees();
    this.getImgRoute();
    this.getTaskStatus();
    
    if (this.data.header) {

      this.filter.addControl("task_operation_operation_id", this.fb.control(''))
      this.filter.addControl("task_operation_status_id", this.fb.control(''))
      this.filter.updateValueAndValidity();
      
      this.getOperationsStatus();

    }
    this.attachSubscriptions(
      this.languageService.getLangData().subscribe(resp => {
        this.activeLang = resp.active;
        this._adapter.setLocale(resp.active);
        this.getOperations();
      })
    )

    this.getGroupsCompany();
    this.getProfiles(1);
    this.getProfilesStatus();
    this.timeZone = new Date().getTimezoneOffset();
    if (this.data.header) {
      this.onReadActions()
    }

    this.attachSubscriptions(
      this.groupsControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchGroups(resp))
    )

    this.attachSubscriptions(
      this.execControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchEmoloyees(resp))
    )
    this.getContentTypes(this.contentPage);
  }

  getContentTypes(contentPage) {
    this.attachSubscriptions(
      this.companyService.getContentTypes(false, contentPage).pipe(
        tap(el => {
          this.contentPagination = {
            'pageCount': el.headers.get('x-pagination-page-count'),
            'perPage': el.headers.get('x-pagination-per-page'),
            'totalCount': el.headers.get('x-pagination-total-count'),
            'currentPage': el.headers.get('x-pagination-current-page'),
          }
        }),
        map(el => el.body)
      ).subscribe(resp => {

        this.allContentTypes = resp;
        this.contentPage++;
        if (this.contentPagination.pageCount >= this.contentPage) {
          this.getContentTypes(this.contentPage);
        }
      })
    )
  }
  
  onSearchEmoloyees(resp) {
    if (!this.employees) {
      return;
    }

    if (!resp) {
      this.employees$.next(this.employees.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.employees$.next(
      this.employees.filter(z => z.name.toLowerCase().indexOf(resp) > -1)
    );
  }
  
  onSearchGroups(resp) {
    if (!this.groups) {
      return;
    }

    if (!resp) {
      this.groups$.next(this.groups.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }

    this.groups$.next(
      this.groups.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  expandAction($event, element) {
    $event.stopPropagation()
    element.isExpand = !element.isExpand;

    if (!!element.isExpand) {
      this.attachSubscriptions(
        this.taskService.getActions(!!this.data.task ? this.data.task.id : false, "1", this.data.company.id, {original_action_id: element.id}, !!this.data.work ? this.data.work.id : false).pipe(map(res => res.body)).subscribe(resp => {
          element.subActions = resp;
          console.log(resp)
          // this.table.renderRows();
        })
      )
    }

  }

  mobClose() {
    this.dialogRef.removePanelClass('animate__slideInUp');
    this.dialogRef.addPanelClass('animate__slideOutDown');
    setTimeout(()=>{this.dialogRef.close({event: "close", data: false})}, 300);
  }

  checkIsMobile() {
    if (window.innerWidth <= 769) {
      this.is_mobile = true;
    } else {
      this.is_mobile = false;
    }
    this.onResize();
  }

  onResize() {
    this.attachSubscriptions(
      fromEvent(window, "resize").pipe(
        map(() => window.innerWidth)
      ).subscribe((wWidth) => {
          if (wWidth <= 769) {
            this.is_mobile = true;
          } else {
            this.is_mobile = false;
          }
        }
      )
    )
  }
  
  getTaskStatusById(id) {
    if (!this.taskStatuses || this.taskStatuses.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.taskStatuses.find(el => el.id == id)
  }

  getAllActions() {
    this.attachSubscriptions(
      this.taskService.getAllActions().subscribe(resp => {
        this.actions = resp;
      })
    )
  }

  getTaskStatus() {
    this.attachSubscriptions(
      this.taskService.getTaskStatuses(this.data.company.id).subscribe(resp => {
        resp.unshift({
          id: 0,
          name: marker('All statuses')
        })
        this.taskStatuses = resp;
      })
    )
  }

  getEmployees() {
    this.attachSubscriptions(
      this.taskService.getEmployees(this.data.company.id).subscribe(resp => {
        this.employees = resp;
        this.employees$.next(this.employees.slice());
      })
    )
  }

  getEmployee(id) {
    if (!this.employees || this.employees.filter(x => x.id == id).length == 0) {
      return
    }

    return this.employees.find(x => x.id == id)
  }

  getProfilesStatus() {
    this.attachSubscriptions(
      this.globalDataService.getContentStatus().subscribe(resp => {
        this.profileStatuses = resp.slice();
      })
    )
      // this.taskService.getProfileStatuses().subscribe(resp => {
      //   this.profileStatuses = resp;
      // })
  }

  profileData(id) {
    if (!this.profiles) {
      return false;
    }
    return this.profiles.find(el => el.id == id) ? this.profiles.find(el => el.id == id) : false
  }
  
  profileStatusesData(id) {
    if (!this.profileStatuses && this.profileStatuses.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.profileStatuses.find(el => el.id == id) ? this.profileStatuses.find(el => el.id == id) : false
  }

  getProfiles(page) {
    this.attachSubscriptions(
      this.companyService.getProfiles(this.data.company.id, page).pipe(
        map(el => el.body)
      ).subscribe(resp => {
        console.log("getProfiles", resp)
        this.profiles.push(...resp);
      })
    )
  }

  toggleVolume() {
    this.hasSound = !this.hasSound;
    if (this.hasSound) {
      this.sm.localStorageSetItem("hasSound", "1")
    } else {
      this.sm.localStorageSetItem("hasSound", "0")
    }
  }

  getDay(val) {
    return new Date(val).setHours(0,0,0,0);
  }
  
  getGroupsCompany() {
    this.attachSubscriptions(
      this.companyService.getInfiniteGroupsCompany(this.data.company.id, '1', '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.companyService.getInfiniteGroupsCompany(this.data.company.id, x).pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        this.groups = [].concat(...res);
        this.groups$.next(this.groups.slice());
      })
    )
  }


  getOperations() {
    this.attachSubscriptions(
      this.taskService.getOperations(this.data.company.id, this.activeLang).subscribe(resp => {
        console.log(resp)
        this.operations = resp;
      })
    )
  }

  getOperationsStatus() {
    this.attachSubscriptions(
      this.taskService.getOperationsStatus().subscribe(resp => {
        this.operationStatuses = resp.slice();
      })
    )
  }

  neededData(task) {
    let arr = [
      this.chatService.getTasksChats(this.data.company.id, [task.id]).pipe(
        tap(res => {
          task['operations'].map(o_el => {
            o_el['chatsInfo'] = [];
            o_el['chatsInfo'].push(...res.filter(z => z.task_operation_id == o_el.id));
            o_el['unReadChats'] = res.filter(z => z.task_operation_id == o_el.id && z.is_read != '1').length;
            o_el['unClosedChats'] = res.filter(z => z.task_operation_id == o_el.id && z.status_id != '4').length;
          });
          task['chatsInfo'] = [];
          task['chatsInfo'].push(...res.filter(z => z.task_id == task.id));
          task['unReadChats'] = res.filter(z => z.is_read != '1').length;
          task['unClosedChats'] = res.filter(z => z.status_id != '4').length;
        })
      )
    ]
    if (task.company_id == this.data.company.id) {
      arr.push(
        this.taskService.getTaskClients(task.id, this.data.company.id).pipe(
          tap(res => {
            task.clients = res
          }),
          catchError(err => {
            return err
          })
        )
      )
    }
    arr.push(...task.operations.filter(z => (z.status_id == 3 && z.is_to_approve_files) || (z.status_id == 98 && z.is_approved_files)).map(x => this.fileService.getFilesForOpenTask(this.data.company.id, x.id, x.status_id == 3 ? '/to_approve' : '/ready_files').pipe(
      tap(val => {
        x.filesCount = val.headers.get('x-pagination-total-count')
        x.files = val.body
      })
    )))

    return forkJoin(arr)
  }


  openJob(task_id, task_operation_id) {
    this.attachSubscriptions(
      this.taskService.getOneTaskExpand(this.data.company.id, task_id).pipe(
        map(x => x.body[0]),
        switchMap(x => this.neededData(x).pipe(map(() => casesModel([x], [], 'update')),map(x => x.arr[0]))),
      ).subscribe(resp => {
        const dialogRef = this.dialog.open(OpenJobComponent, {
          backdropClass: 'backdrop_under_header',
          panelClass: !this.is_mobile ? ['open_task_dialog', 'show_header'] : 'open_task_dialog',
          autoFocus: false,
          data: {
            task_id: task_id,
            task_operation_id: task_operation_id,
            task: resp,
            initCompanyId: this.data.initCompanyId
          }
        });
      })
    )
  }

  openTask(task) {   
    const dialogRef = this.dialog.open(OpenTaskComponent, {
      backdropClass: 'backdrop_under_header',
      panelClass: !this.is_mobile ? ['open_task_dialog', 'show_header'] : 'open_task_dialog',
      autoFocus: false,
      data: {
        task_id: task.id,
        initCompanyId: this.data.initCompanyId
      }
    });
  }
  
  getContentById(id) {
    if (!this.allContentTypes || this.allContentTypes.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.allContentTypes.find(el => el.id == id)
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  openChat(not) {
    this.attachSubscriptions(
      this.chatService.getChatsExpand(1, this.data.company.id, {id: not.discussion.id}).pipe(
        map(res => res.body[0])
      ).subscribe(chat => {
        const goToChat = this.dialog.open(ChatsComponent, {
          backdropClass: ['backdrop_under_header', "without_pad"],
          disableClose: true,
          panelClass: ['full_screen_dialog', 'global_chats_dialog'],
          data: {
            company: this.data.company,
            user: this.data.user,
            imgRoute: this.data.imgRoute,
            dialogType: 'global_chat',
            host: this.host,
            activeLang: this.activeLang, 
            chat: chat
          }
        });
      })
    )
  }

  changeView(e: MatButtonToggleChange) {
    if (e.value == "member") {
      this.filter.patchValue({
        original_action_id: 0,
      })
      this.displayedColumns.unshift("Expand")
      this.filterNots()
    } else {
      this.filter.patchValue({
        original_action_id: '',
      })
      this.displayedColumns.splice(this.displayedColumns.indexOf("Expand"), 1)
      this.filterNots()
    }
  }

  onReadActions() {
    this.attachSubscriptions(
      this.taskService.readActions({
        company_id: this.data.company.id.toString(),
        read_at: moment().format("X")
      }).pipe(
        finalize(() => this.taskService.unreadNots$.next("change"))
      ).subscribe(resp => {
        console.log(resp)
      })
    )
  }

  filterReset() {
    this.filter.patchValue({
      group_id: '',
      employee_id: '',
      action: '',
      original_action_id: '',
      created_at_from: '',
      created_at_to: ''
    })
    this.displayedColumns.splice(this.displayedColumns.indexOf("Expand"), 1)
    this.getActions(this.data.header);
  }

  getActions(is_global: boolean = false) {
    this.page = 1;
    // if (is_global) {
    //   this.taskService.getActions(false, 1, this.data.company.id).pipe(
    //     tap(resp => {
    //       this.data.pagination = {
    //         'pageCount': resp.headers.get('x-pagination-page-count'),
    //         'perPage': resp.headers.get('x-pagination-per-page'),
    //         'totalCount': resp.headers.get('x-pagination-total-count'),
    //         'currentPage': resp.headers.get('x-pagination-current-page'),
    //       }
    //     })
    //   ).subscribe(resp => {
    //     this.data.notifications = resp.body;
    //     this.table.renderRows();
    //   })
    // } else {
    //   this.taskService.getActions(this.data.task.id, 1).pipe(
    //     tap(resp => {
    //       this.data.pagination = {
    //         'pageCount': resp.headers.get('x-pagination-page-count'),
    //         'perPage': resp.headers.get('x-pagination-per-page'),
    //         'totalCount': resp.headers.get('x-pagination-total-count'),
    //         'currentPage': resp.headers.get('x-pagination-current-page'),
    //       }
    //     })
    //   ).subscribe(resp => {
    //     this.data.notifications = resp.body;
    //     this.table.renderRows();
    //   })
    // }
    this.taskService.getActions(false, 1, this.data.company.id).pipe(
      tap(resp => {
        this.data.pagination = {
          'pageCount': resp.headers.get('x-pagination-page-count'),
          'perPage': resp.headers.get('x-pagination-per-page'),
          'totalCount': resp.headers.get('x-pagination-total-count'),
          'currentPage': resp.headers.get('x-pagination-current-page'),
        }
      })
    ).subscribe(resp => {
      this.data.notifications = resp.body;
      this.table.renderRows();
    })
  }

  filterNots() {
    this.page = 1;
    this.data.notifications = [];
    let data = this.filter.value;
    console.log("filterNots", this.filter.value)

    if (!!data.created_at_from) {
      data.created_at_from = moment(data.created_at_from._d).format("X");
    }
    if (!!data.created_at_to) {
      data.created_at_to = moment(data.created_at_to._d).format("X");
    }

    Object.keys(data).forEach(element => {
      if (data[element] === "") {
        delete data[element]
      }
    })

    this.taskService.getActions(!!this.data.task ? this.data.task.id : false, this.page, this.data.company.id, data, !!this.data.work ? this.data.work.id : false).subscribe(resp => {
      this.data.notifications.push(...resp.body);
      this.data.pagination = {
        'pageCount': resp.headers.get('x-pagination-page-count'),
        'perPage': resp.headers.get('x-pagination-per-page'),
        'totalCount': resp.headers.get('x-pagination-total-count'),
        'currentPage': resp.headers.get('x-pagination-current-page'),
      };
      this.table.renderRows();
    })
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  getOperationStatusName(id) {
    switch (+id) {
      case 1:
        return "Waiting";
        break;
      case 2:
        return "In process";
        break;
      case 3:
        return "To approve";
        break;
      case 4:
        return "Approved";
        break;
      case 5:
        return "To correct";
        break;
      case 6:
        return "Sources Needed";
        break;
      case 97:
        return "On hold";
        break;
      case 98:
        return "Ready";
        break;
      case 99:
        return "Canceled";
        break;
    }
  }

  getColumnName(id) {
    if (!this.operations || !this.operations.find(el => el.id == id)) {
      return '';
    }
    return this.operations.find(el => el.id == id) && this.operations.find(el => el.id == id).translation ? this.operations.find(el => el.id == id).translation.name : this.operations.find(el => el.id == id).name
  }

  getMore() {
    this.page = this.page + 1;
    if (this.page <= this.data.pagination['pageCount']) {
      let data = this.filter.value;
  
      Object.keys(data).forEach(element => {
        if (!data[element]) {
          delete data[element]
        }
      })

      this.attachSubscriptions(
        this.taskService.getActions(!!this.data.task ? this.data.task.id : false, this.page, this.data.company.id, data, !!this.data.work ? this.data.work.id : false).subscribe(resp => {
          this.data.notifications.push(...resp.body);
          this.data.pagination = {
            'pageCount': resp.headers.get('x-pagination-page-count'),
            'perPage': resp.headers.get('x-pagination-per-page'),
            'totalCount': resp.headers.get('x-pagination-total-count'),
            'currentPage': resp.headers.get('x-pagination-current-page'),
          };
          this.table.renderRows();
          if (this.data.header) {
            this.onReadActions()
          }
        })
      )
    }
  }

  closeBack(e) {
    if (e.target.className == "alert_backdrop") {
      this.dialogRef.close({event: "close", data: false})
    }
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

  getChatStatus(id) {
    switch (+id) {
      case 1:
        return "Waiting";
        break;
      case 2:
        return "In process";
        break;
      case 3:
        return "To approve";
        break;
      case 4:
        return "Approved";
        break;
      case 5:
        return "To correct";
        break;
      case 6:
        return "Sources needed";
        break;  
      case 97:
        return "On hold";
        break;
      case 98:
        return "Ready";
        break;
      case 99:
        return "Canceled";
        break;
    }
  }


  // drag(e) {
  //   console.log(e);
  //   this.dragPosition = {x: this.dragPosition.x + 50, y: this.dragPosition.y + 50};
  // }
}
  