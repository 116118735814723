import { NgxMatDateAdapter, NgxMatDatetimePicker } from "@angular-material-components/datetime-picker";
import { AfterViewInit, Component, Inject, OnDestroy, OnInit, QueryList, ViewChildren } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";
import { MatDatepickerInput } from "@angular/material/datepicker";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { marker } from "@biesbjerg/ngx-translate-extract-marker";
import * as moment from "moment";
import { forkJoin, of } from "rxjs";
import { catchError, map, switchMap, tap } from "rxjs/operators";
import { AdditionalEmployeesComponent } from "src/app/components/additionalEmpl/additional-employees/additional-employees.component";
import { MY_FORMATS } from "src/app/components/atTasksDialog/task-profile-add/task-profile-add.component";
import { ExecutorsComponent } from "src/app/components/workspace-pages/cases/dialogs/executors/executors.component";
import { SnackBarItem } from "src/app/shared/global_components/snack-bar/snack-bar-item";
import { BaseClass } from "src/app/shared/models/base-class";
import { LayoutService } from "src/app/shared/services/common/layout.service";
import { ChatService } from "src/app/shared/services/rest/chat.service";
import { MembersService } from "src/app/shared/services/rest/members.service";
import { RefreshService } from "src/app/shared/services/rest/refresh.service";
import { TaskService } from "src/app/shared/services/rest/task.service";
import { PushNotificationsService } from "../../services/rest/push-notifications.service";


@Component({
  selector: 'app-push-settings',
  templateUrl: './push-settings.component.html',
  styleUrls: ['./push-settings.component.scss']
})
export class PushSettingsComponent extends BaseClass implements OnInit, OnDestroy {
  public origin = window.location.origin;
  public employees: any;
  public notificationSubs: any;
  public is_changed: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PushSettingsComponent>,
    private membersService: MembersService,
    private layoutService: LayoutService,
    private dialog: MatDialog,
    private pushNotificationsService: PushNotificationsService,
    private chatService: ChatService,
  ) { super() }

  ngOnInit(): void {
    console.log("PushSettingsComponent", this.data)


    this.getNotificationEmployees();
  }

  getNotificationEmployees() {
    if (this.data.post) {
      this.attachSubscriptions(
        this.pushNotificationsService.getPostNotificationEmployees(this.data.company.id, this.data.post.id).pipe(
          tap(x => {
            x.forEach(el => {
              if (el.notificationSubscription == null) {
                el.notificationSubscription = {
                  company_id: el.company_id,
                  employee_id: el.id,
                  discussion_id: this.data.chat.id,
                  discussion_post_id: this.data.post.id,
                  id: 0,
                  is_webpush: 0
                }
              }
            })
          })
        ).subscribe(resp => {
          console.log("getPostNotificationEmployees", resp);
          this.employees = resp;
          console.log("this.employees", this.employees)
        })
      )
    } else {
      this.attachSubscriptions(
        this.pushNotificationsService.getNotificationEmployees(this.data.company.id, this.data.chat.id).pipe(
          tap(x => {
            x.forEach(el => {
              if (el.notificationSubscription == null) {
                el.notificationSubscription = {
                  company_id: el.company_id,
                  employee_id: el.id,
                  discussion_id: this.data.chat.id,
                  id: 0,
                  is_webpush: 0
                }
              }
            })
          })
        ).subscribe(resp => {
          console.log("getNotificationEmployees", resp);
          this.employees = resp;
          this.is_changed = false;
          console.log("this.employees", this.employees)
        })
      )
    }
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  showError(error?) {
    this.layoutService.showSnackBar({name: ''}, marker(error ? error + ". You do not have permission to do this." : "You do not have permission to do this"), SnackBarItem)
  }

  changeItemVal(e, item, key) {
    if (e.checked) {
      item.notificationSubscription[key] = 1;
    } else {
      item.notificationSubscription[key] = 0;
    }
    console.log(e, item, key)
    this.is_changed = true
  }

  save() {
    console.log("save", this.employees);
    let sbmtData = this.employees.slice().map(x => x.notificationSubscription)
    
    sbmtData.forEach(element => {
      element.is_webpush = !!element.is_webpush ? 1 : 0
      // element.is_sms = !!element.is_sms ? 1 : 0
      // element.is_email = !!element.is_email ? 1 : 0
    });
    
    
    let addData = [];
    
    let editIds = [];
    let editData = [];
    
    // return
    sbmtData.forEach(el => {
      if (!!el.id) {
        editIds.push(el.id);
        editData.push({
          is_webpush: !!el.is_webpush ? 1 : 0,
          is_sms: !!el.is_sms ? 1 : 0,
          is_email: !!el.is_email ? 1 : 0
        });
      } else {
        delete el.id
        addData.push(el)
      }
    })

    console.log("save", sbmtData, addData, editIds, editData);
    this.attachSubscriptions(
      forkJoin([...addData.map(x => this.pushNotificationsService.addNotificationSubs(x, this.data.company.id)), ...editData.map((x, i) => this.pushNotificationsService.editNotificationSub(editIds[i], x, this.data.company.id))]).subscribe(resp => {
        console.log(resp)
        this.close();
      }, error => {
        this.getNotificationEmployees();
      })
    )
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

}


// "actions": [
//   {"action": "foo", "title": "Open new tab"},
//   {"action": "foo2", "title": "Open new tab 2"},
//   {"action": "bar", "title": "Focus last and Nav"},
//   {"action": "baz", "title": "Focus last NO Nav"}
// ],
// "data": {
//   "onActionClick": {
//     "default": {"operation": "openWindow", "url": "/foo"},
//     "foo": {"operation": "openWindow", "url": "/foo"}, 
//     "foo2": {"operation": "openWindow", "url": "/foo2"}, 
//     "bar": {"operation": "lastFocusedOrOpen", "navigate": true, "url": "/bar"},
//     "baz": {"operation": "lastFocusedOrOpen", "navigate": false, "url": "/baz"}
//   }
// },


// {
//   "title": "New Notification!",
// badge: 'assets/icons/icon-128x128.png',
//   "data": {
//     "onActionClick": {
//       "default": {"operation": "openWindow", "url": "/chat/LCGR_UItlaSjZDFSW"}
//     }
//   }
// }
// https://www.google.com/url?sa=i&url=http%3A%2F%2Fwww.craiggilchrist.com.au%2F&psig=AOvVaw2rJeP4eBxEoSYaqAh9mWEp&ust=1675866736905000&source=images&cd=vfe&ved=0CBAQjRxqFwoTCOjur57Qg_0CFQAAAAAdAAAAABAJ
// let x = {
//   "notification": {
//     "title": "New Notification!",
//     "badge": "assets/icons/icon-128x128.png",
//     "data": {
//       "onActionClick": {
//         "default": {"operation": "openWindow", "url": "/chat/LCGR_UItlaSjZDFSW"}
//       }
//     }
//   }
// }

// {
//   "notification": {
//     "title": "Some title",
//     "body": "Some body",
//     "data": {
      // "onActionClick": {
      //   "default": { "operation": "openWindow", "url": "https://www.google.ru/" }
      // }
//     }
//   }
// }

// let x = {
//   "notification": {
//     "title": "New Notification!",
//     "actions": [
//       {"action": "foo", "title": "Open new tab"},
//       {"action": "foo2", "title": "Open new tab 2"},
//       {"action": "bar", "title": "Focus last and Nav"},
//       {"action": "baz", "title": "Focus last NO Nav"}
//     ],
//     "data": {
//       "onActionClick": {
//         "default": {"operation": "openWindow", "url": "https://www.twitch.tv/"},
//         "foo": {"operation": "openWindow", "url": "/main"}, 
//         "foo2": {"operation": "openWindow", "url": "/user/connected-apps?tab=youtube"}, 
//         "bar": {"operation": "lastFocusedOrOpen", "navigate": true, "url": "https://www.instagram.com/"},
//         "baz": {"operation": "lastFocusedOrOpen", "navigate": false, "url": "https://navi.gg/ua"}
//       }
//     },
//   }
// }