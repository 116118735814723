<mat-dialog-content class="mat-typography">
    <div class="container form_wrapper">
        <mat-tab-group [selectedIndex]="activeIndex" (selectedIndexChange)="changeTab($event)">
            <mat-tab>
                <ng-template mat-tab-label>
                    <mat-icon class="example-tab-icon mr-1">query_stats</mat-icon>
                    {{ "Views update limits" | translate }}
                </ng-template>
                
                <form [formGroup]="form" class="form_with_pads form_with_valids">
                    <div class="box_wrp">
                        <mat-checkbox formControlName="is_views_count_update" color="primary">{{"Activating auto-refresh views" | translate}}</mat-checkbox>
                    </div>
                    <div class="box_wrp">
                        <mat-checkbox formControlName="is_rate_views_count_update_grace" color="primary">{{"Grace update period views" | translate}}</mat-checkbox>
                    </div>
                    <mat-form-field appearance="standard" class="full_width_form">
                        <mat-label>{{ 'Grace views period (h)' | translate }}</mat-label>
                        <input formControlName="rate_views_count_update_grace_time" matInput type="number" placeholder="{{ 'Grace views period (h)' | translate }}">
                    </mat-form-field>
                    <div class="d-f-c">
                        <mat-form-field appearance="standard" class="full_width_form test_w">
                            <mat-label>{{ 'Maximum amount of views for 2 weeks to update weekly' | translate }}</mat-label>
                            <input formControlName="rate_views_count_update_week" matInput type="number" placeholder="{{ 'Maximum amount of views for 2 weeks to update weekly' | translate }}">
                        </mat-form-field>
                        <mat-form-field appearance="standard" class="full_width_form" style="width: 100px;">
                            <mat-label>{{ 'Views/day' | translate }}</mat-label>
                            <input formControlName="rate_views_count_update_week_calc" matInput type="number" placeholder="{{ 'Views/day' | translate }}">
                        </mat-form-field>
                    </div>
                    <div class="d-f-c">
                        <mat-form-field appearance="standard" class="full_width_form test_w">
                            <mat-label>{{ 'Maximum amount of views for 2 weeks to update daily' | translate }}</mat-label>
                            <input formControlName="rate_views_count_update_day" matInput type="number" placeholder="{{ 'Maximum amount of views for 2 weeks to update daily' | translate }}">
                        </mat-form-field>
                        <mat-form-field appearance="standard" class="full_width_form" style="width: 100px;">
                            <mat-label>{{ 'Views/day' | translate }}</mat-label>
                            <input formControlName="rate_views_count_update_day_calc" matInput type="number" placeholder="{{ 'Views/day' | translate }}">
                        </mat-form-field>
                    </div>
         
                    <p>{{"Update hourly if video gets more than"|translate}} <b *ngIf="form.get('rate_views_count_update_day').value">{{form.get('rate_views_count_update_day').value}}</b> {{"Views for 2 weeks"|translate}}</p>
                </form>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    <mat-icon class="example-tab-icon mr-1">storage</mat-icon>
                    {{ "Storage limits" | translate }}
                </ng-template>
                
                <form [formGroup]="storageForm" class="form_with_pads form_with_valids">
                    <mat-form-field appearance="standard" class="full_width_form">
                        <mat-label>{{ 'File size limit' | translate }} h1</mat-label>
                        <input cdkFocusInitial formControlName="filesize_h1_limit" matInput type="number" placeholder="{{ 'File size limit' | translate }} h1">
                    </mat-form-field>
                    <mat-form-field appearance="standard" class="full_width_form">
                        <mat-label>{{ 'File size limit' | translate }} h2</mat-label>
                        <input cdkFocusInitial formControlName="filesize_h2_limit" matInput type="number" placeholder="{{ 'File size limit' | translate }} h2">
                    </mat-form-field>
                    <mat-form-field appearance="standard" class="full_width_form">
                        <mat-label>{{ 'File size limit' | translate }} h3</mat-label>
                        <input cdkFocusInitial formControlName="filesize_h3_limit" matInput type="number" placeholder="{{ 'File size limit' | translate }} h3">
                    </mat-form-field>
                </form>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    <mat-icon class="example-tab-icon mr-1">cast</mat-icon>
                    {{ "LiveStreams" | translate }}
                </ng-template>
                
                <form [formGroup]="streamForm" class="form_with_pads form_with_valids">
                    <mat-form-field appearance="standard" class="full_width_form">
                        <mat-label>{{ 'Live stream limit' | translate }}</mat-label>
                        <input cdkFocusInitial formControlName="live_stream_limit" matInput type="number" placeholder="{{ 'Live stream limit' | translate }}">
                    </mat-form-field>
                </form>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    <mat-icon class="example-tab-icon mr-1">movie_filter</mat-icon>
                    {{ "VPU" | translate }}
                </ng-template>
                
                <form [formGroup]="vpuForm" class="form_with_pads form_with_valids">
                    <mat-form-field appearance="standard" class="full_width_form">
                        <mat-label>{{ 'VPU limit' | translate }}</mat-label>
                        <input cdkFocusInitial formControlName="vpu_limit" matInput type="number" placeholder="{{ 'VPU limit' | translate }}">
                    </mat-form-field>
                </form>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    <mat-icon class="example-tab-icon mr-1">settings_voice</mat-icon>
                    {{ "DU" | translate }}
                </ng-template>
                
                <form [formGroup]="duForm" class="form_with_pads form_with_valids">
                    <mat-form-field appearance="standard" class="full_width_form">
                        <mat-label>{{ 'DU limit' | translate }}</mat-label>
                        <input cdkFocusInitial formControlName="du_limit" matInput type="number" placeholder="{{ 'DU limit' | translate }}">
                    </mat-form-field>
                </form>
            </mat-tab>
        </mat-tab-group>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <div class="f_w_p_btns">
        <button mat-raised-button (click)="close()">
            {{ "Close" | translate }}
        </button>
        <button class="ml-1 d-f-btn" (click)="sumbitBtn()" mat-raised-button color="primary" [disabled]="isSubmit">
            <mat-spinner class="spin_16 mr-1" *ngIf="isSubmit"></mat-spinner>  
            {{ "Save" | translate }}
        </button>
    </div>
</mat-dialog-actions>
