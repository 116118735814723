import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ReplaySubject, forkJoin } from 'rxjs';
import { debounceTime, last, map, switchMap } from 'rxjs/operators';
import { BaseClass } from 'src/app/shared/models/base-class';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { ParametersService } from 'src/app/shared/services/rest/parameters.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { RefreshService } from '../../services/rest/refresh.service';

@Component({
  selector: 'app-delete-tag-from-selected',
  templateUrl: './delete-tag-from-selected.component.html',
  styleUrls: ['./delete-tag-from-selected.component.scss']
})
export class DeleteTagFromSelectedComponent extends BaseClass implements OnInit, OnDestroy {
  public parameter_value: any;
  public isSubmit: boolean = false;
  public allValues: any;
  public is_primary: FormControl = new FormControl(false);
  public allValuesControl: FormControl = new FormControl();
  public allValues$: ReplaySubject<any> = new ReplaySubject<any>(1);
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DeleteTagFromSelectedComponent>,
    private sm: StorageManagerService,
    private dialog: MatDialog,
    private taskService: TaskService,
    private refreshService: RefreshService,
    private parametersService: ParametersService,
  ) { super() }

  ngOnInit(): void {
    console.log("DeleteTagFromSelectedComponent", this.data)
    this.getAllApiParameterValues();

    this.attachSubscriptions(
      this.allValuesControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchValues(resp))
    )
  }

  log() {
    console.log("parameter_value", this.parameter_value)
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  save() {
    this.isSubmit = true;
    console.log("selectedFiles", this.data.selectedFiles)
    let postData = [];
                  
    this.data.selectedFiles.forEach(file => {
      let value = file.parameterValuesToTask.find(x => x.parameter_value_id == this.parameter_value.id) ? file.parameterValuesToTask.find(x => x.parameter_value_id == this.parameter_value.id) : null
      // if (value && ((!this.is_primary.value && !value.is_primary) || (!!this.is_primary.value && !!value.is_primary))) {
      if (value && (!this.is_primary.value || !!value.is_primary)) {
        postData.push({
          "path": `/api/parameter-value-to-task/${value.id}/`,
          "query": {"company_id": this.data.company.id},
          "method": "DELETE"
        })
      }
    });

    if (postData.length) {
      this.attachSubscriptions(
        this.taskService.multiRequest(postData).subscribe(resp => {
          console.log("resp", resp)

          console.log("this.data.selectedFiles", this.data.selectedFiles)
          this.isSubmit = false;
          this.dialogRef.close({event: "deleted", data: resp})
        })
      )
    } else {
      this.dialogRef.close({event: "closed", data: []})
      this.isSubmit = false;
    }
  }

  onSearchValues(resp) {
    if (!this.allValues) {
      return;
    }

    if (!resp) {
      this.allValues$.next(this.allValues.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.allValues$.next(
      this.allValues.filter(z => z.value.toLowerCase().indexOf(resp) > -1 || (z.parameter.name && z.parameter.name.toLowerCase().indexOf(resp) > -1))
    );
  }

  getAllApiParameterValues() {
    this.attachSubscriptions(
      this.parametersService.getAllValues('1', this.data.company.id, null, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.parametersService.getAllValues(x, this.data.company.id).pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        this.allValues = [].concat(...res);
        this.allValues$.next(this.allValues.slice())
      })
    )
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

}
