import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { AuthService } from 'src/app/shared/services/rest/auth.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { concatMap, debounceTime, last, map, switchMap, tap } from 'rxjs/operators';
import { forkJoin, of, ReplaySubject, Subscription } from 'rxjs';
import { MY_FORMATS } from 'src/app/components/workspace-settings/finances/statements/statements.component';
import { ScenariosService } from 'src/app/shared/services/rest/scenarios.service';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { EditApproveComponent } from '../edit-approve/edit-approve.component';
import { GlobalDataService } from 'src/app/shared/services/common/global-data.service';
import { environment } from 'src/environments/environment';
import { TargetParametersComponent } from 'src/app/components/atTasksDialog/target-parameters/target-parameters.component';
import { ParametersService } from 'src/app/shared/services/rest/parameters.service';
import { prioritys } from 'src/app/shared/consts/prioritys';

@Component({
  selector: 'app-auto-tag',
  templateUrl: './auto-tag.component.html',
  styleUrls: ['./auto-tag.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class AutoTagComponent extends BaseClass implements OnInit, OnDestroy {
  public host: any = environment.host;
  public imgRoute: any = '';
  public user_id: number;
  public form: FormGroup;
  public company_id: any;
  public task_id: any;
  public operations: any;
  public profileStatuses: any;
  public automationType: any;
  public operationStatuses: any;
  public values: any;

  public allValues: any;
  public allValuesControl: FormControl = new FormControl();
  public allValues$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public numberRegEx = /\d*\.?\d{1,2}/;
  public isFormChange: boolean = false;
  public isSubmit: boolean = false;
  public submited: boolean = false;
  public switched: boolean = false;
  public opened: boolean = false;

  public jobs: any[] = [];
  public newJobs: any[] = [];

  public operationMoreControl: FormControl = new FormControl();
  public operations$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public profiles: any = [];
  
  public prioritys = prioritys;

  public contentPage: number = 1;
  public contentPagination: any;
  public allContentTypes: any;
  public contentTypes: any[] = [];
  public contentTypesControl: FormControl = new FormControl();
  public contentTypes$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public platformsMoreControl: FormControl = new FormControl();
  public platforms$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public platforms: any;

  public regulars: any;
  public regularsControl: FormControl = new FormControl();
  public regularItemsControl: FormControl = new FormControl();
  public regulars$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public regularItems$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public partners: any;
  public partnersControl: FormControl = new FormControl();
  public partners$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public employees: any;
  public employeeMoreControl: FormControl = new FormControl();
  public employees$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public jobTypeSwitch: boolean = false;
  public emplSwitch: boolean = false;

  public pCliSwitch: boolean = false;
  public cliSwitch: boolean = false;

  public groups: any;
  public groupsControl: FormControl = new FormControl();
  public groups$: ReplaySubject<any> = new ReplaySubject<any>(1);
  
  public taskStatuses: any;
  public taskStatusesControl: FormControl = new FormControl();
  public taskStatuses$: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AutoTagComponent>,
    private sm: StorageManagerService,
    private auth: AuthService,
    private companyService: CompanyService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private parametersService: ParametersService,
    private taskService: TaskService,
    private _adapter: DateAdapter<any>,
    private languageService: LanguageService,
    private globalDataService: GlobalDataService,
    private bottomSheet: MatBottomSheet,
    private layoutService: LayoutService,
    private scenariosService: ScenariosService,

  ) { super() }

  ngOnInit(): void {
    console.log("AutoTagComponent", this.data)

    this.company_id = this.data.company.id;

    this.getImgRoute();
    this.getCsrf();
    this.getAllApiParameterValues();
    this.getTaskStatus();

    this.getGroupsCompany();
    this.attachSubscriptions(
      this.allValuesControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchValues(resp))
    )
    this.attachSubscriptions(
      this.employeeMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchEmoloyees(resp))
    )
    this.attachSubscriptions(
      this.groupsControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchGroups(resp))
    )
    this.attachSubscriptions(
      this.taskStatusesControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchTaskStatuses(resp))
    )
    this.attachSubscriptions(
      this.operationMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchOperations(resp))
    )
    this.getEmployees();

    this.form = this.fb.group({
      company_id: this.data.company.id,
      parameter_value_id: '',
      is_primary: 0,
      task_status_id: '',
      task_group_id: '',
      operation_id: '',
      operation_employee_id: '',
      file_location: '/',
      is_exact_file_location: 0,
      filename_expression: '',
      filename_expression_key: '',
      file_extension: '',
      task_parameter_value_id: '',
      task_operation_parameter_value_id: ''
    })

    this.attachSubscriptions(
      this.form.valueChanges.subscribe(() => this.isFormChange = true)
    )


    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        if (this.isFormChange) {
          this.layoutService.openBottomSheet(this.dialogRef);
        } else {
          this.close();
        }
      })
    )

    this.getOperations();
    this.getLangData();
    
    if (this.data.tmpl) {
      this.getTmpl();
    }
  }

  getGroupById(id) {
    if (!this.groups || !this.groups.find(el => el.id == id)) {
      return false;
    }
    return this.groups.find(el => el.id == id)
  } 

  getGroupsCompany() {
    this.attachSubscriptions(
      this.companyService.getInfiniteGroupsCompany(this.company_id, '1', '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.companyService.getInfiniteGroupsCompany(this.company_id, x).pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        this.groups = [].concat(...res);
        this.groups$.next(this.groups.slice());
      })
    )
  }

  onSearchTaskStatuses(resp) {
    if (!this.taskStatuses) {
      return;
    }

    if (!resp) {
      this.taskStatuses$.next(this.taskStatuses.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }

    this.taskStatuses$.next(
      this.taskStatuses.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }
  
  onSearchGroups(resp) {
    if (!this.groups) {
      return;
    }

    if (!resp) {
      this.groups$.next(this.groups.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }

    this.groups$.next(
      this.groups.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }
  
  getTaskStatusesById(id) {
    return this.taskStatuses && this.taskStatuses.find(x => x.id == id)
  }

  getTaskStatus() {
    this.attachSubscriptions(
      this.taskService.getTaskStatuses(this.company_id).subscribe(resp => {
        console.log("getTaskStatuses", resp)
        this.taskStatuses = resp;
        this.taskStatuses$.next(this.taskStatuses.slice());
      })
    )
  }

  onSearchValues(resp) {
    if (!this.allValues) {
      return;
    }

    if (!resp) {
      this.allValues$.next(this.allValues.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.allValues$.next(
      this.allValues.filter(z => z.value.toLowerCase().indexOf(resp) > -1 || (z.parameter.name && z.parameter.name.toLowerCase().indexOf(resp) > -1))
    );
  }

  getEmployees() {
    this.attachSubscriptions(
      this.taskService.getEmployeesDyn('1', this.data.company.id, null, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }
  
          return forkJoin(arr.map(x => this.taskService.getEmployeesDyn(x, this.data.company.id, null, '200').pipe(map(u => u.body)))).pipe(
            last(),
            tap(values => {
              let conValues = [].concat(...values)
              this.employees = conValues;
              this.employees$.next(this.employees.slice())
            }),
          )
        }),
      ).subscribe(resp => {
        console.log("getEmployees resp", resp);
        console.log("getEmployees", this.employees);
      })
    )
  }

  onSearchEmoloyees(resp) {
    if (!this.employees) {
      return;
    }

    if (!resp) {
      this.employees$.next(this.employees.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.employees$.next(
      this.employees.filter(z => z.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  getAllApiParameterValues() {
    this.attachSubscriptions(
      this.parametersService.getAllValues('1', this.data.company.id, null, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.parametersService.getAllValues(x, this.data.company.id).pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        this.allValues = [].concat(...res)
        this.allValues$.next(this.allValues.slice())
      })
    )
  }

  toggleEmplChip = (chip: any) => {
    if (!!chip.id) {
      this.form.patchValue({
        operation_employee_id: chip.id
      })
    }

    if (!chip.id) {
      this.emplSwitch = true;

      setTimeout(() => {
        this.emplSwitch = false;
      }, 0)
    }
  }
  toggleJobType = (chip: any) => {
    if (!!chip.id) {
      this.form.patchValue({
        operation_id: chip.id
      })
    }

    if (!chip.id) {
      this.jobTypeSwitch = true;

      setTimeout(() => {
        this.jobTypeSwitch = false;
      }, 0)
    }
  }

  getCircularReplacer() {
    const ancestors:any = [];
    return function (key, value) {
      if (typeof value !== "object" || value === null) {
        return value;
      }
      // `this` is the object that value is contained in,
      // i.e., its direct parent.
      while (ancestors.length > 0 && ancestors.at(-1) !== this) {
        ancestors.pop();
      }
      if (ancestors.includes(value)) {
        return "[Circular]";
      }
      ancestors.push(value);
      return value;
    };
  }

  getTmpl() {
    this.form.patchValue({
      company_id: this.data.tmpl.company_id,
      parameter_value_id: this.data.tmpl.parameter_value_id,
      is_primary: this.data.tmpl.is_primary,
      task_status_id: this.data.tmpl.task_status_id,
      task_group_id: this.data.tmpl.task_group_id,
      operation_id: this.data.tmpl.operation_id,
      operation_employee_id: this.data.tmpl.operation_employee_id,
      file_location: this.data.tmpl.file_location,
      is_exact_file_location: this.data.tmpl.is_exact_file_location,
      filename_expression: this.data.tmpl.filename_expression,
      file_extension: this.data.tmpl.file_extension,
      task_parameter_value_id: this.data.tmpl.task_parameter_value_id,
      task_operation_parameter_value_id: this.data.tmpl.task_operation_parameter_value_id,
      filename_expression_key: ''
    })

    if (!!this.data.tmpl.is_has_filename_expression) {
      this.form.patchValue({
        filename_expression_key: 'is_has_filename_expression'
      })
    }
    if (!!this.data.tmpl.is_start_with_filename_expression) {
      this.form.patchValue({
        filename_expression_key: 'is_start_with_filename_expression'
      })
    }
    if (!!this.data.tmpl.is_end_with_filename_expression) {
      this.form.patchValue({
        filename_expression_key: 'is_end_with_filename_expression'
      })
    }


    this.form.updateValueAndValidity();

    console.log("this.data", this.data.tmpl)
    console.log("this.form", this.form.value)
  }

  selectChange(e) {
    console.log("selectChange", e)
  }

  onSearchOperations(resp) {
    if (!this.operations) {
      return;
    }

    if (!resp) {
      this.operations$.next(this.operations.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.operations$.next(
      this.operations.filter(z => (z.name.toLowerCase().indexOf(resp) > -1))
    );
  }

  log() {
    console.log("FORM", this.form.value);
    console.log("JOBS", this.jobs);
  }

  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData().subscribe(resp => {
        this._adapter.setLocale(resp.active);
      })
    )
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }
  
  getCsrf() {
    this.attachSubscriptions(
      this.auth.$userToken.subscribe(resp => {
        this.user_id = resp.user_id;
        this.sm.localStorageSetItem("csrf_param", resp.csrf_param)
        this.sm.localStorageSetItem("csrf_token", resp.csrf_token)
      })
    )
  }

  getOperations() {
    this.attachSubscriptions(
      this.taskService.getOperations(this.data.company.id).subscribe(resp => {
        this.operations = resp;
        this.operations$.next(this.operations.slice())
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  getValuesById(id) {
    return this.allValues && this.allValues.find(x => x.id == id)
  }

  onSubmit() {
    if (!this.form.valid) {
      this.submited = true;
      this.layoutService.showSnackBar({name: ''}, marker("You need to fill in all required fields"), SnackBarItem)
      return
    }
    this.submited = false;
    this.isSubmit = true;
    let formData = {...this.form.value};
    
    
    if (formData.filename_expression_key) {
      formData[formData.filename_expression_key] = 1
    }

    delete formData.filename_expression_key;


    console.log(formData);

    if (!this.data.tmpl) {
      // CREATE


      this.attachSubscriptions(
        this.scenariosService.addAutoTag(formData).subscribe(resp => {   
          this.isSubmit = false;
          this.dialogRef.close({event: "update", data: resp});
        })
      )
    } else {
      // EDIT

      let editformData = {...formData};
      delete editformData.parameter_value_id;
      this.attachSubscriptions(
        this.scenariosService.editAutoTag(this.data.tmpl.id, editformData).subscribe(resp => {
          this.isSubmit = false;
          this.dialogRef.close({event: "update", data: resp});
        })
      )
    }
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
