<h2 mat-dialog-title class="dialog_title">
    <span>
        {{ "Choose where you want to paste files" | translate }}
    </span>
    <button mat-icon-button (click)="close()" class="ml-1">
        <mat-icon>close</mat-icon>
    </button>
</h2>
<mat-dialog-content class="ask_paste_container">
    <div class="container">
        <div class="ask_buttons">
            <div (click)="chose('reports')">
                <span>
                    {{ "Reports buffer" | translate }}
                </span>
            </div>
            <div></div>
            <div (click)="chose('device')">
                <span>
                    {{ "Device buffer" | translate }}
                </span>
            </div>
        </div>
        <div class="box_wrp">
            <mat-checkbox color="primary" [(ngModel)]="clearAfter">{{ "Clear the Reports buffer after pasting?" | translate }}</mat-checkbox>
        </div>
    </div>
</mat-dialog-content>
