import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { prioritys } from 'src/app/shared/consts/prioritys';
import { BaseClass } from 'src/app/shared/models/base-class';
import { ChatService } from 'src/app/shared/services/rest/chat.service';

@Component({
  selector: 'app-mob-question',
  templateUrl: './mob-question.component.html',
  styleUrls: ['./mob-question.component.scss']
})
export class MobQuestionComponent extends BaseClass implements OnInit, OnDestroy {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<MobQuestionComponent>,
  ) { super() }

  ngOnInit(): void {
    console.log("MobQuestionComponent", this.data)
  }

  yes() {
    this.dialogRef.removePanelClass('animate__slideInUp')
    this.dialogRef.addPanelClass('animate__slideOutDown')
    setTimeout(()=>{this.dialogRef.close({action: 'yes'})}, 300);
  }

  close() {
    this.dialogRef.removePanelClass('animate__slideInUp')
    this.dialogRef.addPanelClass('animate__slideOutDown')
    setTimeout(()=>{this.dialogRef.close({action: 'no'})}, 300);
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
