<mat-dialog-content class="mat-typography" dirEnter (dirSubmit)="!form.valid || isSubmit ? null : onSubmit()">
    <div class="container form_wrapper" [appDeviceClass]="'custom-scroll custom-scroll-t'">
        <div class="title_modal">
            <ng-container *ngIf="!!data.tmpl; else elseTemplateAdd">
                {{ "Edit automation template" | translate }}
            </ng-container>
            <ng-template #elseTemplateAdd>
                {{ "Add automation template" | translate }}
            </ng-template>
        </div>
        <form [formGroup]="form" class="form_with_pads form_with_valids">
            <div class="users_select">
                <ng-container *ngIf="!form.get('automation_template_id').valid && submited">
                    <div class="req_field"></div>
                    <div class="req_label">
                        <div class="angle"></div>
                        <span>
                            {{ "You need to Choose a scenario" | translate }}
                        </span>
                    </div>
                </ng-container>
                <label>{{ "Choose a scenario" | translate }}: </label>
                <div style="height: 24px;" *ngIf="scnSwitch"></div>
                <mat-chip-list [multiple]="true" selectable formControlName="automation_template_id" *ngIf="!scnSwitch">
                    <ng-container *ngFor="let template of templates; let i = index">
                        <mat-chip style="padding-left: 12px !important;" class="chip_user" (click)="toggleChipTemplate(template)" [value]="template.id" [selected]="form.value.automation_template_id.includes(template.id)">
                            {{ template.name }}
                        </mat-chip>
                        <mat-chip *ngIf="i == templates.length - 1 && i > 6" class="chip_user more" (click)="templatesIdContainer.open()">
                            {{ "more" | translate }}
                        </mat-chip>
                    </ng-container>
                </mat-chip-list>
            </div>

            <!-- (optionSelected)=“function($event)” try this -->
            <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                <mat-select [multiple]="true" #templatesIdContainer formControlName="automation_template_id" (selectionChange)="toggleChipTemplate($event)">
                    <mat-option>
                        <ngx-mat-select-search 
                        placeholderLabel="{{ 'Search' | translate }}" 
                        noEntriesFoundLabel="{{ 'no matching template found' | translate }}"
                        [formControl]="templatesMoreControl"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let template of templates$ | async" [value]="template.id">
                        {{template.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <div class="small_chips" *ngIf="groups?.length > 0">
                <label>{{ "Group" | translate }}: </label>
                <mat-chip-list [multiple]="false" selectable formControlName="group_id">
                    <ng-container *ngFor="let group of groups; let i = index">
                        <mat-chip class="small_chip" (click)="selectGroup(group)" [value]="group?.id" [selected]="group?.id == form.get('group_id').value">
                            {{ group.name | translate }}
                        </mat-chip>
                        <mat-chip *ngIf="i == groups?.length - 1 && i > 6" class="small_chip more" (click)="groupIdContainer.open()">
                            {{ "More..." | translate }}
                        </mat-chip>
                    </ng-container>
                    
                </mat-chip-list>
            </div>

            <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                <mat-select #groupIdContainer formControlName="group_id" (selectionChange)="selectGroup($event)">
                    <mat-option>
                        <ngx-mat-select-search 
                        placeholderLabel="{{ 'Search' | translate }}" 
                        noEntriesFoundLabel="{{ 'no matching group found' | translate }}"
                        [formControl]="groupMoreControl"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let group of groups$ | async" [value]="group.id">
                        {{ group.name | translate }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <div class="small_chips" *ngIf="operations?.length > 0">
                <ng-container *ngIf="!form.get('operation_id').valid && submited">
                    <div class="req_field"></div>
                    <div class="req_label">
                        <div class="angle"></div>
                        <span>
                            {{ "You need to choose a value" | translate }}
                        </span>
                    </div>
                </ng-container>
                <label>{{ "For job type" | translate }}: </label>
                <mat-chip-list [multiple]="false" selectable formControlName="operation_id">
                    <ng-container *ngFor="let operation of operations; let i = index">
                        <mat-chip class="small_chip" (click)="selectType(operation)" [value]="operation?.id" [selected]="form.value.operation_id == operation.id">
                            {{ operation.name | translate }}
                        </mat-chip>
                        <mat-chip *ngIf="i == operations?.length - 1 && i > 6" class="small_chip more" (click)="operationIdContainer.open()">
                            {{ "More..." | translate }}
                        </mat-chip>
                    </ng-container>
                    
                </mat-chip-list>
            </div>
            <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                <mat-select #operationIdContainer formControlName="operation_id">
                    <mat-option>
                        <ngx-mat-select-search 
                        placeholderLabel="{{ 'Search' | translate }}" 
                        noEntriesFoundLabel="{{ 'no matching job type found' | translate }}"
                        [formControl]="operationMoreControl"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let operation of operations$ | async" [value]="operation.id">
                        {{ operation.name | translate }}
                    </mat-option>
                </mat-select>
            </mat-form-field>


            <div class="users_select">
                <label>{{ "Choose an employee" | translate }}: </label>
                <mat-chip-list [multiple]="true" selectable formControlName="employee_id">
                    <ng-container *ngFor="let employee of employees; let i = index">
                        <mat-chip class="chip_user" (click)="toggleEmpl(employee)" [value]="employee.id" [selected]="form.value.employee_id && form.value.employee_id.includes(employee.id)">
                            <app-user [class]="'user_div'" [employee]="employee"></app-user>
                        </mat-chip>
                        <mat-chip *ngIf="i == employees.length - 1 && i > 6" class="chip_user more" (click)="employeeIdContainer.open()">
                            {{ "more" | translate }}
                        </mat-chip>
                    </ng-container>
                </mat-chip-list>
            </div>

            <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                <mat-select #employeeIdContainer formControlName="employee_id" multiple>
                    <mat-option>
                        <ngx-mat-select-search 
                        placeholderLabel="{{ 'Search' | translate }}" 
                        noEntriesFoundLabel="{{ 'no matching executor found' | translate }}"
                        [formControl]="employeeMoreControl"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let employee of employees$ | async" [value]="employee.id">
                        <app-user [class]="'user_div'" [employee]="employee"></app-user>
                    </mat-option>
                </mat-select>
            </mat-form-field>


            <div class="file_tags">
                <ng-container *ngIf="parameters.length && parameters[0].activeValues && parameters[0].activeValues.length">
                    <div class="file_tag" *ngFor="let tag of parameters[0].activeValues; let t = index;">
                        {{tag.value}}
                        <div class="remove_out">
                            <div class="remove" (click)="onRemove(tag, t)">
                                <svg style="width: 5px; height: 5px;" width="5" height="5" viewBox="0 0 5 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5 0.503571L4.49643 0L2.5 1.99643L0.503571 0L0 0.503571L1.99643 2.5L0 4.49643L0.503571 5L2.5 3.00357L4.49643 5L5 4.49643L3.00357 2.5L5 0.503571Z" fill="white"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <div class="add_tag cp" (click)="openTargetValues()">
                    <svg style="width: 16px; height: 16px;" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 8.8H8.8V12H7.2V8.8H4V7.2H7.2V4H8.8V7.2H12M8 0C6.94943 0 5.90914 0.206926 4.93853 0.608964C3.96793 1.011 3.08601 1.60028 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.08601 14.3997 3.96793 14.989 4.93853 15.391C5.90914 15.7931 6.94943 16 8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 6.94943 15.7931 5.90914 15.391 4.93853C14.989 3.96793 14.3997 3.08601 13.6569 2.34315C12.914 1.60028 12.0321 1.011 11.0615 0.608964C10.0909 0.206926 9.05058 0 8 0Z" fill="#C4C4C4"/>
                    </svg>
                    {{ "Tag" | translate }}
                </div>
            </div>
        </form>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <div class="f_w_p_btns">
        <button mat-button (click)="close()">
            {{ "Close" | translate }}
        </button>
        <button class="ml-1 d-f-btn" (click)="onSubmit()" cdkFocusInitial mat-raised-button color="primary" [disabled]="isSubmit">
            <mat-spinner class="spin_16 mr-1" *ngIf="isSubmit"></mat-spinner>
            <ng-container *ngIf="!!data.tmpl; else elseTemplateAddSbmt">
                {{ "Edit" | translate }}
            </ng-container>
            <ng-template #elseTemplateAddSbmt>
                {{ "Add" | translate }}
            </ng-template>
        </button>
    </div>
</mat-dialog-actions>