import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { ytLangs } from 'src/app/shared/consts/ytLangs';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { ConnectedAppsService } from 'src/app/shared/services/rest/connected-apps.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';

@Component({
  selector: 'app-channel-edit-description',
  templateUrl: './channel-edit-description.component.html',
  styleUrls: ['./channel-edit-description.component.scss']
})
export class ChannelEditDescriptionComponent extends BaseClass implements OnInit {
  public translations: any;
  public transForm: FormGroup;
  public activeLang: any;
  public languages: any = JSON.parse(JSON.stringify(ytLangs)).items;
  public isSubmit: boolean = false;

  public chanData: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ChannelEditDescriptionComponent>,
    private taskService: TaskService,
    private connectedAppsService: ConnectedAppsService,
    private languageService: LanguageService,
    private fb: FormBuilder,
  ) { super() }

  ngOnInit(): void {
    console.log("ChannelEditDescriptionComponent", this.data)
    this.languages.map(x => {
      x.title = '';
      x.description = '';
    })


    this.chanData = JSON.parse(this.data.exChannel.data)

    console.log('languages', this.languages)
    console.log('chanData', this.chanData)
    // localizations
    this.transForm = this.fb.group({
      title: ['', Validators.required],
      description: ['', Validators.required],
      language: ''
    })
    
    if (!!this.chanData.hasOwnProperty('localizations')) {
      Object.keys(this.chanData.localizations).forEach(key => {
        if (key.indexOf('_') != -1) {
          if (this.languages.find(x => x.id == key.split('_')[0])) {
            this.languages.find(x => x.id == key.split('_')[0]).title = this.chanData.localizations[key].title;
            this.languages.find(x => x.id == key.split('_')[0]).description = this.chanData.localizations[key].description;
          }
        } else {
          if (this.languages.find(x => x.id == key)) {
            this.languages.find(x => x.id == key).title = this.chanData.localizations[key].title;
            this.languages.find(x => x.id == key).description = this.chanData.localizations[key].description;
          }
        }
      })
    }
    

    this.attachSubscriptions(
      this.transForm.get("language").valueChanges.subscribe(resp => {
        this.getTranslations(resp)
      })
    )
    this.getLangData();
  }

  onInputChange(e) {
    console.log("onChange", e);
    console.log("this.languages", this.languages);
    console.log("this.transForm", this.transForm.value);
    if (this.languages.find(x => x.id == this.transForm.value.language)) {
      this.languages.find(x => x.id == this.transForm.value.language).title = this.transForm.value.title;
      this.languages.find(x => x.id == this.transForm.value.language).description = this.transForm.value.description;
    }
    console.log("this.languages.find(x => x.id == this.transForm.value.language)", this.languages.find(x => x.id == this.transForm.value.language))
  }

  log() {
    console.log("this.languages", this.languages);
    console.log("this.transForm", this.transForm.value);
  }

  getTranslations(lang) {
    if (this.languages.find(x => x.id == lang)) {
      this.transForm.patchValue({
        title: this.languages.find(x => x.id == lang).title,
        description: this.languages.find(x => x.id == lang).description
      })
    }
    console.log("this.languages.find(x => x.id == lang)", this.languages.find(x => x.id == lang))
    
    // this.attachSubscriptions(
    //   this.taskService.getTaskTypeTranslation(this.data.type.id).subscribe(resp => {
    //     console.log("getTaskTypeTranslation", resp)
    //     this.translations = resp;
    //     if (resp.find(x => x.language == this.transForm.value.language)) {
    //       this.transForm.patchValue({
    //         name: resp.find(x => x.language == this.transForm.value.language).name
    //       })
    //     } else {
    //       this.transForm.patchValue({
    //         name: ''
    //       })
    //     }
    //   })
    // )
  }

  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData().pipe(
        tap(resp => {
          console.log("resp")
          this.activeLang = resp.active;
          this.transForm.patchValue({
            language: this.activeLang
          })
        })
      ).subscribe(resp => {
        console.log("getOperationTranslation", resp)
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }
  
  submitForm() {
    let sbmtData;
    if (this.languages.filter(x => !!x.title && !!x.description).length > 0) {
      sbmtData = {
        localizations: {
        }
      }

      this.languages.filter(x => !!x.title && !!x.description).forEach(lang => {
        sbmtData.localizations[lang.id] = {
          title: lang.title,
          description: lang.description
        }
      })
    } else {
      return
    }
    this.isSubmit = true;

    this.attachSubscriptions(
      this.connectedAppsService.editYTChannelAccount(this.data.exChannel.id, sbmtData).subscribe(resp => {
        this.isSubmit = false;
        this.close();
      }, error => {
        this.isSubmit = false;
      })
    )
    // this.attachSubscriptions(
    //   this.taskService.editTaskType(this.data.type.id, this.form.value).pipe(
    //     switchMap(x => {
    //       if (this.translations.filter(i => i.language == this.transForm.value.language).length == 0) {
    //         if (this.transForm.value.name) {
    //           return this.taskService.createTaskTypeTranslation(this.transForm.value).pipe(
    //             tap(val => {
    //               this.translations.push(val)
    //             })
    //           )
    //         } else {
    //           return of(null)
    //         }
    //         } else {
    //         return this.taskService.updateTaskTypeTranslation(this.translations.find(i => i.language == this.transForm.value.language).id, {name: this.transForm.value.name}).pipe(
    //           tap(val => {
    //             this.translations.find(x => x.language == val.language).name = this.form.value.name
    //           })
    //         )
    //       }
    //     }),
    //   ).subscribe(resp => {
    //     console.log(resp);
    //     this.isSubmit = false;
    //     this.close()
    //   })
    // )
  }


  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

}
