<h2 mat-dialog-title class="dialog_title">
    <span>
        {{ "Executor calendar" | translate }}
    </span>
    <button style="margin-left: 30px;" mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h2>
<mat-dialog-content class="mat-typography">
    <div class="container">
        <div class="calendar_head">
            <div class="current_calendar_date">
              {{ viewDate | calendarDate:(view.value + 'ViewTitle'):activeLang:1 }}
            </div>



            <div class="calendar_btns">
                <button mat-raised-button (click)="scheduleSettings()">
                    {{"Edit regular schedule" | translate}}
                </button>
              <button class="ml-1"
              mat-mini-fab 
              color="primary" 
              mwlCalendarPreviousView
              [view]="view.value"
              [(viewDate)]="viewDate"
              (viewDateChange)="closeOpenMonthViewDay()">
                <mat-icon>navigate_before</mat-icon>
              </button>

              <button class="ml-1"
              mat-mini-fab 
              color="primary" 
              mwlCalendarNextView
              [view]="view.value"
              [(viewDate)]="viewDate"
              (viewDateChange)="closeOpenMonthViewDay()">
                <mat-icon>navigate_next</mat-icon>
              </button>

              <button class="ml-1"
              mwlCalendarToday
              [(viewDate)]="viewDate"
              (viewDateChange)="closeOpenMonthViewDay()"
              mat-flat-button>
                {{ "Today" | translate }}
              </button>
            </div>
            <!-- <mat-button-toggle-group class="view_group" (change)="setView($event)" [value]="view.value">
                <mat-button-toggle [value]="CalendarView.List">{{ "List" | translate }}</mat-button-toggle>
                <mat-button-toggle [value]="CalendarView.Month">{{ "Month" | translate }}</mat-button-toggle>
                <mat-button-toggle [value]="CalendarView.Week">{{ "Week" | translate }}</mat-button-toggle>
                <mat-button-toggle [value]="CalendarView.Day">{{ "Day" | translate }}</mat-button-toggle>
              </mat-button-toggle-group> -->
        </div>
        <ng-template #customCellTemplate let-day="day" let-locale="locale">
            <div class="reports-cell" [ngClass]="{'edited_cell': day.events && day.events[0] && !!day.events[0].is_fixed}">
                <div class="cal-cell-top">
                    <span class="cal-day-badge" *ngIf="day.badgeTotal > 0">
                        {{ day.badgeTotal }}
                    </span>
                    <div class="d-f-c">
                        <div class="show_on_hover edit_day" [attr.day]="day.date | calendarDate:'monthViewDayNumber':locale" (click)="editDay($event, day)">
                            <svg [attr.day]="day.date | calendarDate:'monthViewDayNumber':locale" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path [attr.day]="day.date | calendarDate:'monthViewDayNumber':locale" d="M5.8376 8.1C5.28064 8.1 4.7465 7.87875 4.35267 7.48492C3.95885 7.0911 3.7376 6.55695 3.7376 6C3.7376 5.44305 3.95885 4.9089 4.35267 4.51508C4.7465 4.12125 5.28064 3.9 5.8376 3.9C6.39455 3.9 6.9287 4.12125 7.32252 4.51508C7.71635 4.9089 7.9376 5.44305 7.9376 6C7.9376 6.55695 7.71635 7.0911 7.32252 7.48492C6.9287 7.87875 6.39455 8.1 5.8376 8.1ZM10.2956 6.582C10.3196 6.39 10.3376 6.198 10.3376 6C10.3376 5.802 10.3196 5.604 10.2956 5.4L11.5616 4.422C11.6756 4.332 11.7056 4.17 11.6336 4.038L10.4336 1.962C10.3616 1.83 10.1996 1.776 10.0676 1.83L8.5736 2.43C8.2616 2.196 7.9376 1.992 7.5596 1.842L7.3376 0.252C7.3136 0.108 7.1876 0 7.0376 0H4.6376C4.4876 0 4.3616 0.108 4.3376 0.252L4.1156 1.842C3.7376 1.992 3.4136 2.196 3.1016 2.43L1.6076 1.83C1.4756 1.776 1.3136 1.83 1.2416 1.962L0.0415989 4.038C-0.0364011 4.17 -0.000400981 4.332 0.113599 4.422L1.3796 5.4C1.3556 5.604 1.3376 5.802 1.3376 6C1.3376 6.198 1.3556 6.39 1.3796 6.582L0.113599 7.578C-0.000400981 7.668 -0.0364011 7.83 0.0415989 7.962L1.2416 10.038C1.3136 10.17 1.4756 10.218 1.6076 10.17L3.1016 9.564C3.4136 9.804 3.7376 10.008 4.1156 10.158L4.3376 11.748C4.3616 11.892 4.4876 12 4.6376 12H7.0376C7.1876 12 7.3136 11.892 7.3376 11.748L7.5596 10.158C7.9376 10.002 8.2616 9.804 8.5736 9.564L10.0676 10.17C10.1996 10.218 10.3616 10.17 10.4336 10.038L11.6336 7.962C11.7056 7.83 11.6756 7.668 11.5616 7.578L10.2956 6.582Z" fill="#2F80ED"/>
                            </svg>
                        </div>
                        <span class="cal-day-number">{{ day.date | calendarDate:'monthViewDayNumber':locale }}</span>
                    </div>
                </div>

                <div class="intervals" *ngIf="day.events">
                    <div class="interval" *ngFor="let interval of day.events">
                        <div class="from">{{interval.from_time*1000|date:'hh:mm a':'':activeLang}}</div>
                        <div class="ft_spacer">-</div>
                        <div class="to">{{interval.to_time*1000|date:'hh:mm a':'':activeLang}}</div>
                        <div class="duration">({{(interval.to_time - interval.from_time) | timeFormat:true}})</div>
                    </div>
                </div>
            </div>
          </ng-template>
        <mwl-calendar-month-view
        [viewDate]="viewDate"
        [locale]="activeLang"
        [events]="schedules"
        [cellTemplate]="customCellTemplate"
        [weekStartsOn]="1"
        [refresh]="refreshCalendar"
        [activeDayIsOpen]="activeDayIsOpen"
        [openDayEventsTemplate]="customOpenDayEventsTemplate"
        (dayClicked)="dayClicked($event.day)"
        (eventClicked)="handleEvent($event, 'Clicked', $event.event)"
        (eventTimesChanged)="eventTimesChanged($event)"
      >
      </mwl-calendar-month-view>

      <ng-template
      #customOpenDayEventsTemplate
      let-events="events"
      let-isOpen="isOpen">

        <!-- The [@collapse] is causing the error. -->
        <div class="cal-open-day-events report-cal-open-day-events" [@collapse] *ngIf="isOpen">
            <ng-container *ngFor="let item of events">
                <div>
                    {{item.start}} - {{item.end}}
                </div>
                <hr>
            </ng-container>
            
        </div>

    </ng-template>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <div class="bottom_line">
        <button (click)="close()">
            {{ "Close" | translate }}
        </button>
    </div>
</mat-dialog-actions>
