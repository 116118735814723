<div mat-dialog-title class="dialog_title">
    <div class="d-f-c"><span>{{ "Managers in" | translate }}</span> <b style="margin: 0 5px;">{{data?.group?.name}}</b> <span>{{'group' | translate}}</span></div>
    
    <button mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</div>
<mat-dialog-content class="mat-typography">
    <div class="">
        <p style="max-width: 500px;">{{"Group manager has same permissions as Workspace manager, but only within group assets (cards, jobs, chats, files)" | translate}}</p>
        <mat-list role="list">
            <mat-list-item *ngFor="let manager of groupManagers; let i = index;" role="listitem" class="company_item">
                <div class="company_name d-f-c">
                    <app-user [class]="'user_div'" [employee]="manager?.employee"></app-user>
                    <mat-icon class="ml-1 cp" [ngClass]="{'not_clickable': manager?.cabSeeDisabled}" [ngStyle]="{'color': !!manager.canSee && !!manager.canSee.length ? 'green' : 'brown'}" (click)="toggleCanSee(manager)" #tooltip="matTooltip"
                    matTooltip="{{ 'Permission to see the group in lists like selects in filtered and cards assignments. If disabled user can`t see the group in those lists but will be able see the assets of the group while remaining as a group managers' | translate }}"
                    [matTooltipPosition]="'above'">{{ !!manager.canSee && !!manager.canSee.length ? 'visibility' : 'visibility_off'}}</mat-icon>
                </div>
                <mat-checkbox [disabled]="manager.disabled" class="ml-1 mr-1" color="primary" (change)="toggleManager($event, manager)" [checked]="manager.is_price_manager > 0">
                    {{ "Access to prices" | translate }}
                </mat-checkbox>
                <div class="company_btns">
                    <mat-icon (click)="deleteGroupManager(manager, i)" class="cp" style="color: brown;">delete</mat-icon>
                </div>
            </mat-list-item>
        </mat-list>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <div class="d-f-c" style="padding: 15px 0;">
        <button mat-raised-button (click)="close()">
            {{ "Close" | translate }}
        </button>
        <button class="d-f-c ml-1" mat-raised-button color='primary' (click)="addGroupManager()">
            <mat-icon class="mr-1">add</mat-icon>
            {{ "Add manager" | translate }}
        </button>
    </div>
</mat-dialog-actions>
