<h2 mat-dialog-title class="dialog_title">
    <span>
        {{ "Playlists" | translate }}:
    </span>
    <button class="ml-1" mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h2>
<mat-dialog-content class="mat-typography">
    <div class="container form_wrapper" [appDeviceClass]="'custom-scroll'">
        <div class="playlists">
            <div class="playlist_out" *ngFor="let playlist of playlists">
                <div class="playlist">
                    <div class="name text_one_line">
                        {{playlist?.name}}
                    </div>
                    <div class="d-f-c" style="margin-left: 50px;">
                        <a mat-icon-button [href]="'https://www.youtube.com/playlist?list='+playlist.playlist_id" target="_blank">
                            <mat-icon>link</mat-icon>
                        </a>
                        <button mat-icon-button (click)="onCheck(playlist)">
                            <mat-icon>{{playlist.isSave ? 'check_box' : 'check_box_outline_blank'}}</mat-icon>
                        </button>
                        <button mat-icon-button (click)="openPlaylist(playlist)">
                            <mat-icon>{{playlist.isOpen ? 'expand_less' : 'expand_more'}}</mat-icon>
                        </button>
                    </div>
                </div>
                <div class="playlist_videos" *ngIf="!!playlist.isOpen">
                    <ng-container *ngIf="!!playlist.isLoad; else elseTemplateNotLoad">
                        <div class="loader d-f-c" style="height: 100px; justify-content: center; padding: 20px;">
                            <mat-spinner></mat-spinner>
                        </div>
                    </ng-container>
                    <ng-template #elseTemplateNotLoad>
                        <div class="pl_video" *ngFor="let item of playlist.videos">
                            <div class="yt_out">
                                <youtube-player
                                [videoId]="item.video_id"
                                [height]="225"
                                [width]="400"
                                ></youtube-player>
                            </div>
                            <div class="yt_info">
                                <div class="d-f-c" style="justify-content: space-between;">
                                    <div class="name">
                                        {{item.name}}
                                    </div>
                                    <button mat-icon-button (click)="removeFromPlaylist(playlist, item)">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end" style="margin-top: 5px;">
    <button mat-raised-button (click)="close()">
        {{ "Close" | translate }}
    </button>
    <button class="ml-1" (click)="onSubmit()" mat-raised-button cdkFocusInitial color="primary" [disabled]="!!isSubmit">
        {{ "Save to playlist(s)" | translate }}
    </button>
</mat-dialog-actions>
