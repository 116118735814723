import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { AuthService } from 'src/app/shared/services/rest/auth.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { BaseClass } from 'src/app/shared/models/base-class';
import { MatSelect } from '@angular/material/select';
import { GlobalDataService } from 'src/app/shared/services/common/global-data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-edit-status',
  templateUrl: './edit-status.component.html',
  styleUrls: ['./edit-status.component.scss']
})


export class EditStatusComponent extends BaseClass implements OnInit, OnDestroy {
  @ViewChild('profilesContainer') profilesContainer: MatSelect;
  public host: any = environment.host;
  public imgRoute: any = '';
  public user: any = {};
  public user_id: number;
  public form: FormGroup;
  public company_id: any;
  public task_id: any;
  public profileStatuses: any;
  public profile_id: any;
  public isFormChange: boolean = false;
  public activeLang: any;
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<EditStatusComponent>,
    private sm: StorageManagerService,
    private auth: AuthService,
    private companyService: CompanyService,
    private globalDataService: GlobalDataService,
    private fb: FormBuilder,
    private taskService: TaskService,
    private layoutService: LayoutService,
    private languageService: LanguageService,
  ) { super() }

  ngOnInit(): void {
    console.log("EditStatusComponent", this.data)
    window.scroll(0,0);
    if (this.data.needStatus) {
      this.form = this.fb.group({
        task_id: '',
        content_status_id: ['', Validators.required],
      })
    } else {
      this.task_id = this.data.task.id;
      this.profile_id = this.data.profile.id;
  
      this.form = this.fb.group({
        task_id: this.task_id,
        content_status_id: this.data.profile.content_status_id != 0 ? +this.data.profile.content_status_id : 1,
      })
  
    }
    this.company_id = this.data.company_id;

    this.attachSubscriptions(
      this.form.valueChanges.subscribe(() => this.isFormChange = true)
    )

    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        if (this.isFormChange) {
          this.layoutService.openBottomSheet(this.dialogRef);
        } else {
          this.close();
        }
      })
    )

    this.getImgRoute();
    this.getCompany();
    this.getCsrf();
    this.getUser();
    this.getLangData();

    this.getProfilesStatus();
  }

  withoutToUpload() {
    if (!!this.data.profile && this.data.profile.content_type_id == 106 && !this.data.profile.external_content_id) {
      return true
    } else {
      return false
    }
  }

  log() {
    console.log("statuses", this.profileStatuses)
  }

  getProfilesStatus() {
    this.attachSubscriptions(
      this.globalDataService.getContentStatus().subscribe(resp => {
        this.profileStatuses = resp.slice();
        console.log("profileStatuses", this.profileStatuses)
      })
    )
    // this.attachSubscriptions(
    //   this.taskService.getProfileStatuses().subscribe(resp => {
    //     console.log("getProfilesStatus", resp)
    //     this.profileStatuses = resp;
    //   })
    // )
  }

  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData().subscribe(resp => {
        this.activeLang = resp.active;
      })
    )
  }

  getCompany() {
    this.attachSubscriptions(
      this.companyService.getCompany(this.company_id).subscribe(resp => {
        this.companyService.company$.next(resp[0]);
      })
    )
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  getCsrf() {
    this.attachSubscriptions(
      this.auth.$userToken.subscribe(resp => {
        this.user_id = resp.user_id;
        this.sm.localStorageSetItem("csrf_param", resp.csrf_param)
        this.sm.localStorageSetItem("csrf_token", resp.csrf_token)
      })
    )
  }

  getUser() {
    this.attachSubscriptions(
      this.auth.$user.subscribe(resp => {
        this.user = resp;
      })
    )
  }

  onSubmit() {
    if (this.data.needStatus) {
      if (this.form.value.content_status_id) {
        this.dialogRef.close({event: "set", data: this.form.value.content_status_id});
      }
    } else {
      this.attachSubscriptions(
        this.taskService.editProfile(this.profile_id, this.form.value, this.company_id).subscribe(resp => {
          this.data.profile.content_status_id = resp.content_status_id;
          this.dialogRef.close({event: "edit", data: resp});
        })
      )
    }
  }

  close() {
    this.dialogRef.close({event: "close", data: false});
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
