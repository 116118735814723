import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeFormat'
})
export class TimeFormatPipe implements PipeTransform {
  transform(value: number, noSec: boolean = false) {
    let hasHour: boolean = false
    let newValue = "";
    if (value >= 3600) {
      newValue = Math.floor(value / 3600) + "h"
      value %= 3600
      hasHour = true
    }

    if (value > 60) {
      if (Math.floor(value / 60) < 10) {
        newValue = newValue + " 0" + Math.floor(value / 60) + "m"
      } else {
        newValue = newValue + " " + Math.floor(value / 60) + "m"
      }
      value %= 60
    }

    if (!noSec || (noSec && !hasHour)) {
      if (value < 10) {
        newValue = newValue + " 0" + value + "s"
      } else {
        newValue = newValue + " " + value + "s"
      }
    }

    return newValue
  }
}


// const formatterEN = new Intl.NumberFormat("en", {
//   style: "unit",
//   unit: "year", 
//   unitDisplay: "long"
// }) 