<h2 mat-dialog-title class="dialog_title">
    <span>
        {{ "Edditing a stats" | translate }}:
    </span>
    <button class="ml-1" mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h2>
<mat-dialog-content class="mat-typography" dirEnter (dirSubmit)="!form.valid ? null : onSubmit()">
    <div class="container form_wrapper">
        <form [formGroup]="form">
            <mat-form-field *ngIf="form.get('rate')" appearance="standard" class="full_width_form">
                <mat-label>{{ 'Points' | translate }}</mat-label>
                <input cdkFocusInitial formControlName="rate" matInput type="number" placeholder="{{ 'Points' | translate }}">
            </mat-form-field>
    
            <div class="d-f-c">
                <mat-form-field appearance="standard" class="full_width_form">
                    <mat-label>{{ 'Price' | translate }}</mat-label>
                    <input formControlName="price" matInput type="number" placeholder="{{ 'Price' | translate }}">
                </mat-form-field>
                <mat-form-field appearance="standard" class="currency_select">
                    <mat-label>{{ "Currency" | translate }}</mat-label>
                    <mat-select #currencySelect [disableOptionCentering]="true" selectDir formControlName="price_currency_id">
                        <mat-option *ngFor="let currency of currencyes" [value]="currency.id">
                          {{ currency.iso_name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </form>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button (click)="close()">
        {{ "Back" | translate }}
    </button>
    <button class="ml-1" (click)="onSubmit()" mat-raised-button cdkFocusInitial color="primary" [disabled]="!form.valid">
        {{ "Save" | translate }}
    </button>
</mat-dialog-actions>
