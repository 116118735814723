import { Injectable } from '@angular/core';
import { HttpRestService } from '../common/http-rest.service';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CommentService {

  constructor(
    private http: HttpRestService
    ) { }

    getComments(filter) {
      let req_params = new HttpParams()
      if (filter) {
        Object.keys(filter).forEach(element => {
          req_params = req_params.set(element, filter[element]);
        });
      }
      return this.http.executeGet<any>(`/api/task-channel-comment/`, true, {
        observe: 'response',
        params: req_params
      })
    }

    sendComment(data) {
      return this.http.executePost<any>(`/api/task-channel-comment/`, data)
    }

    editComment(id, data) {
      return this.http.executePost<any>(`/api/task-channel-comment/${id}/`, data)
    }
}

