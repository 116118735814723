<div (click)="closeLastDialog()" class="application_alert" *ngIf="isApplicationAlert && appStatusId <= 2">
    <div>
        {{ "Finish the form" | translate }}. <a href="/settings?company_id={{company?.id}}&page=form" (click)="closeAllDialogs($event); goToForm($event, company?.id); isApplicationAlert = !isApplicationAlert">{{ "Click here" | translate }}</a> {{ "and fill the form" | translate }}
    </div>
</div>
<header (click)="closeLastDialog()" [ngClass]="{'main_not_auth': is_main_not_auth}">
    <div class="logo_wrp">
        <a class="logo" href="" (click)="closeAllDialogs($event); nextFalseLimit()" [routerLink]="['/main']" routerLinkActive="router-link-active">
            <ng-container *ngIf="!is_mobile; else elseTemplateVersion">
                <div class="logo_name">Reports.re</div>
            </ng-container>
            <ng-template #elseTemplateVersion>
                <div class="logo_name">R</div>
            </ng-template>

            <div class="version" [ngClass]="{'mob_v': is_mobile}">{{version}}</div>
            <!-- <img class="new_year" src="{{ imgRoute }}assets/img/new_year.png" alt=""> -->
        </a>
    </div>

<!-- ng s --host 192.168.7.103 --disable-host-check -->
<!-- export NODE_OPTIONS=--max_old_space_size=4096 -->
<!-- export NODE_OPTIONS=--max_old_space_size=5120 -->
    <ng-container *ngIf="is_mobile; else elseTemplateMobilePages">
        <div (click)="openSections()" class="mobile_block_trigger" *ngIf="!!activeSections() && !!user_id">
            {{ activeSections() }}
            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_2651_37441)">
                <path d="M7.91675 3.33325L2.08342 3.33325L5.00008 7.91658L7.91675 3.33325Z" fill="#686868"/>
                </g>
                <defs>
                <clipPath id="clip0_2651_37441">
                <rect width="10" height="10" fill="white" transform="translate(10) rotate(90)"/>
                </clipPath>
                </defs>
            </svg>
        </div>

        <div class="settings_block">
            <div *ngIf="!!company" (click)="openWorkspaces()" class="mobile_workspace_trigger">
                <span class="text_one_line">
                    {{ company?.name }}
                </span>
                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_2651_37441)">
                    <path d="M7.91675 3.33325L2.08342 3.33325L5.00008 7.91658L7.91675 3.33325Z" fill="#686868"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_2651_37441">
                    <rect width="10" height="10" fill="white" transform="translate(10) rotate(90)"/>
                    </clipPath>
                    </defs>
                </svg>
            </div>
    
            <div class="add_task_wrp" *ngIf="!!user_id && canCreate">
                <button mat-icon-button class="add_task" (click)="addNewTask($event);" style="color: #219653 !important;">
                    <mat-spinner class="spin_24" *ngIf="createLoader"></mat-spinner>  
                    <mat-icon  style="color: #219653 !important;" *ngIf="!createLoader" >add_circle</mat-icon>
                </button>
            </div>
    
            <ng-container *ngIf="user_id == 0">

                <a class="login" title="{{ 'Log_in' | translate }}" href="{{ host }}/user/auth/?action=lastauth&amp;returnUrl={{ _window.location.href }}{{ invite }}">
                    {{'Log_in' | translate}}
                </a>
                <a class="login sign ml-5" title="{{ 'Sign up' | translate }}" href="{{ host }}/user/auth/?action=lastauth&amp;returnUrl={{ _window.location.href }}{{ invite }}">
                    {{'Sign up' | translate}}
                </a>
            </ng-container>
            

            <!-- <div *ngIf="!!company && !!company['id']" style="cursor: pointer;" (click)='$event.stopPropagation(); $event.preventDefault(); checkActions()'>
                <ng-container *ngIf="unReadNotsCount; else elseTemplateunReadNotsCount">
                    <mat-icon [ngClass]="{bigSize: +unReadNotsCount > 999}" [matBadge]="unReadNotsCount" matBadgeColor="primary">notifications</mat-icon>
                </ng-container>
                <ng-template #elseTemplateunReadNotsCount>
                    <mat-icon>notifications</mat-icon>
                </ng-template>
            </div> -->
            
            <button class="nots_icon_wrp" *ngIf="!!user_id" mat-icon-button (click)="openNotifications($event);" style="color: #686868;">
                <mat-icon class="nots_icon" style="color: #686868;">chat_bubble_outline</mat-icon>
            </button>


            <div style="margin: 0 7px !important" class="burger_trigger_wrp" *ngIf="!!user_id" (click)="openBurger();">
                <svg id="mob_burger" class="ham hamRotate ham1" viewBox="0 0 100 100" width="80" [ngClass]="{'active': isOpenBurger.value}">
                    <path
                          class="line top"
                          d="m 30,33 h 40 c 0,0 9.044436,-0.654587 9.044436,-8.508902 0,-7.854315 -8.024349,-11.958003 -14.89975,-10.85914 -6.875401,1.098863 -13.637059,4.171617 -13.637059,16.368042 v 40" />
                    <path
                          class="line middle"
                          d="m 30,50 h 40" />
                    <path
                          class="line bottom"
                          d="m 30,67 h 40 c 12.796276,0 15.357889,-11.717785 15.357889,-26.851538 0,-15.133752 -4.786586,-27.274118 -16.667516,-27.274118 -11.88093,0 -18.499247,6.994427 -18.435284,17.125656 l 0.252538,40" />
                  </svg>

                  <div class="upload_in_progress" *ngIf="!!uploadProgress && !!uploadProgress?.uploadedSize && uploadProgress?.uploadedSize > 0">
                    <mat-spinner></mat-spinner>
                    <mat-icon style="transform: rotate(-90deg);">forward</mat-icon>
                  </div>
            </div>
        </div>
    </ng-container>
    <ng-template #elseTemplateMobilePages>
        <app-navigation-bar *ngIf="!!company && !!company['id']"
        [company]="company"
        [company_id]="company.id"
        [is_mobile]="is_mobile"
        [activeLang]="activeLang"
        [user]="user"
        [barItems]="barItems"
        (RemoveItem)="removeItem($event)"
        (LinkToItem)="linkToItem($event)"
        (GoToItem)="goToItem($event)"
        ></app-navigation-bar>
        <!-- <div *ngIf="!!company && !!company['id']" class="pages">
            <a [routerLink]="['/tasks']" [routerLinkActiveOptions]="{ exact: true }" [queryParams]="{company_id: company?.id, order_by: 'updated_desc', count: '1'}" class="link-to-page" [class.page-active]="isLinkActive('/tasks') || isLinkActive('/reminder-tasks')" (click)='closeAllDialogs($event);  goToCompany(company?.id, $event)'>
                <div class="page_name">
                    {{ "Cases" | translate }}
                </div>
                <div class="chat_icon_wrp">
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16 12V16H2V12H16ZM17 10H1C0.45 10 0 10.45 0 11V17C0 17.55 0.45 18 1 18H17C17.55 18 18 17.55 18 17V11C18 10.45 17.55 10 17 10ZM4 15.5C3.18 15.5 2.5 14.83 2.5 14C2.5 13.17 3.18 12.5 4 12.5C4.82 12.5 5.5 13.17 5.5 14C5.5 14.83 4.83 15.5 4 15.5ZM16 2V6H2V2H16ZM17 0H1C0.45 0 0 0.45 0 1V7C0 7.55 0.45 8 1 8H17C17.55 8 18 7.55 18 7V1C18 0.45 17.55 0 17 0ZM4 5.5C3.18 5.5 2.5 4.83 2.5 4C2.5 3.17 3.18 2.5 4 2.5C4.82 2.5 5.5 3.18 5.5 4C5.5 4.82 4.83 5.5 4 5.5Z" fill="#686868"/>
                    </svg>
                </div>
            </a>
            <a [routerLink]="['/jobs']" [routerLinkActiveOptions]="{ exact: true }" [queryParams]="{company_id: company?.id, order_by: 'updated_desc', count: '1'}" class="link-to-page" [class.page-active]="isLinkActive('/jobs')" (click)='closeAllDialogs($event);  goToJobs($event, company?.id)'>
                <div class="page_name">
                    {{ "Jobs" | translate }}
                </div> 
                <div class="chat_icon_wrp">
                    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 16H20V12H0V16ZM2 13H4V15H2V13ZM0 0V4H20V0H0ZM4 3H2V1H4V3ZM0 10H20V6H0V10ZM2 7H4V9H2V7Z" fill="#686868"/>
                    </svg>
                </div>
            </a>
            <a *ngIf="company | perm:'see_task_channels'" [routerLink]="['/urls']" [routerLinkActiveOptions]="{ exact: true }" [queryParams]="{company_id: company?.id, order_by: 'content_published_at_desc', count: '1'}" class="link-to-page expanded_page" [class.page-active]="isLinkActive('/urls') || isLinkActive('/outlets-dashboard')" (click)='closeAllDialogs($event);  goToUrls($event, company?.id)'>
                <div class="page_name">
                    {{ "URLs" | translate }}
                </div>
                <div class="chat_icon_wrp url_wrp">
                    <svg class="urls_icon" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M3.9 12c0-1.71 1.39-3.1 3.1-3.1h4V7H7c-2.76 0-5 2.24-5 5s2.24 5 5 5h4v-1.9H7c-1.71 0-3.1-1.39-3.1-3.1zM8 13h8v-2H8v2zm9-6h-4v1.9h4c1.71 0 3.1 1.39 3.1 3.1s-1.39 3.1-3.1 3.1h-4V17h4c2.76 0 5-2.24 5-5s-2.24-5-5-5z"/></svg>
                </div>

                <div class="sub_pages" [ngClass]="{'show': isLinkActive('/outlets-dashboard') || isLinkActive('/urls-dashboard')}" (click)="$event.preventDefault(); $event.stopPropagation()">
                    <div class="sub_pages_in">
                        <a class="sub_page" [routerLink]="['/outlets-dashboard']" [routerLinkActiveOptions]="{ exact: true }" [queryParams]="{company_id: company?.id, count: '1'}" (click)="closeAllDialogs($event); goToOutletsDash($event, company?.id)" [class.active]="isLinkActive('/outlets-dashboard')">
                            <svg style="width: 20px !important; height: 20px !important;" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.66667 0V16.6667H10V0H6.66667ZM10 2.22222L14.4444 16.6667L17.7778 15.5556L13.3333 1.11111L10 2.22222ZM2.22222 2.22222V16.6667H5.55556V2.22222H2.22222ZM0 17.7778V20H20V17.7778H0Z" fill="black"/>
                            </svg>  
                        </a>
                        <a class="sub_page" [routerLink]="['/urls-dashboard']" [routerLinkActiveOptions]="{ exact: true }" [queryParams]="{company_id: company?.id, count: '1'}" (click)="closeAllDialogs($event); goToUrlsDash($event, company?.id)" [class.active]="isLinkActive('/urls-dashboard')">
                            <svg style="width: 20px !important; height: 20px !important;" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.1111 0V6.66667H20V0M11.1111 20H20V8.88889H11.1111M0 20H8.88889V13.3333H0M0 11.1111H8.88889V0H0V11.1111Z" fill="black"/>
                            </svg>
                        </a>
                        <a class="sub_page" [routerLink]="['/urls']" [class.active]="isLinkActive('/urls?')" [routerLinkActiveOptions]="{ exact: true }" [queryParams]="{company_id: company?.id, order_by: 'content_published_at_desc', count: '1'}" (click)="closeAllDialogs($event);  goToUrls($event, company?.id)">
                            <svg style="width: 18px !important; height: 16px !important;" width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0 0H4V4H0V0ZM6 1V3H18V1H6ZM0 6H4V10H0V6ZM6 7V9H18V7H6ZM0 12H4V16H0V12ZM6 13V15H18V13H6Z" fill="black"/>
                            </svg>
                        </a>
                    </div>
                </div>
            </a>
            <a [routerLink]="['/chats']" [routerLinkActiveOptions]="{ exact: true }" [queryParams]="{company_id: company?.id, order_by: 'updated_desc', count: '1'}" class="link-to-page" [class.page-active]="isLinkActive('/chats')" (click)='closeAllDialogs($event);  goToChats($event, company?.id)'>
                <div class="page_name">
                    {{ "Chats" | translate }}
                </div>
                <div class="chat_icon_wrp">
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.6667 0.666664H2.33333C1.41667 0.666664 0.666666 1.41666 0.666666 2.33333V17.3333L4 14H15.6667C16.5833 14 17.3333 13.25 17.3333 12.3333V2.33333C17.3333 1.41666 16.5833 0.666664 15.6667 0.666664Z" fill="#686868"/>
                    </svg>   
                    <div class="chats_badgje" *ngIf="unReadChatsCount">
                        {{unReadChatsCount}}
                    </div>
                </div>
            </a>
            <a [routerLink]="['/board']" [routerLinkActiveOptions]="{ exact: true }" [queryParams]="{company_id: company?.id, count: '1'}" class="link-to-page" [class.page-active]="isLinkActive('/board')" (click)='closeAllDialogs($event);  goToBoard($event, company?.id)'>
                <div class="page_name">
                    {{ "Board" | translate }}
                </div>
                <div class="chat_icon_wrp">
                    <svg class="board" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16.6667 8.33333V6.16667C16.6667 5.0621 15.7712 4.16667 14.6667 4.16667H5.33334C4.22877 4.16667 3.33334 5.0621 3.33334 6.16667V8.33333M16.6667 8.33333V15.5C16.6667 16.6046 15.7712 17.5 14.6667 17.5H5.33333C4.22876 17.5 3.33334 16.6046 3.33334 15.5V8.33333M16.6667 8.33333H3.33334M6.66667 2.5V5.83333M13.3333 2.5V5.83333" stroke="#686868" stroke-width="2" stroke-linecap="round"/>
                        <rect x="10.8333" y="11.6667" width="4.16667" height="4.16667" rx="1" fill="#686868"/>
                    </svg>
                </div>
            </a>
            <a class="link-to-page shortcuts_btn">
                <div class="page_name">
                    {{ "Shortcuts" | translate }}
                </div>
                <div class="chat_icon_wrp">
                    <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10 0L6.42 5.56L0 7.24L4.2 12.38L3.82 19L10 16.59L16.18 19L15.8 12.38L20 7.24L13.58 5.56L10 0ZM7.38 8C8 8 8.5 8.5 8.5 9.13C8.5 9.42704 8.382 9.71192 8.17196 9.92196C7.96192 10.132 7.67704 10.25 7.38 10.25C6.75 10.25 6.25 9.75 6.25 9.13C6.25 8.5 6.75 8 7.38 8ZM12.63 8C13.25 8 13.75 8.5 13.75 9.13C13.75 9.42704 13.632 9.71192 13.422 9.92196C13.2119 10.132 12.927 10.25 12.63 10.25C12 10.25 11.5 9.75 11.5 9.13C11.5 8.5 12 8 12.63 8ZM7 12.5H13C12.5 13.71 11.31 14.5 10 14.5C8.69 14.5 7.5 13.71 7 12.5Z" fill="#686868"/>
                    </svg>
                </div>

                <div class="shortcuts_out">
                    <div class="shortcuts_wrp">
                        <div class="short_title">
                            <div class="d-f-c">
                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9 0L5.778 5.004L0 6.516L3.78 11.142L3.438 17.1L9 14.931L14.562 17.1L14.22 11.142L18 6.516L12.222 5.004L9 0ZM6.642 7.2C7.2 7.2 7.65 7.65 7.65 8.217C7.65 8.48434 7.5438 8.74073 7.35476 8.92976C7.16573 9.1188 6.90934 9.225 6.642 9.225C6.075 9.225 5.625 8.775 5.625 8.217C5.625 7.65 6.075 7.2 6.642 7.2ZM11.367 7.2C11.925 7.2 12.375 7.65 12.375 8.217C12.375 8.48434 12.2688 8.74073 12.0798 8.92976C11.8907 9.1188 11.6343 9.225 11.367 9.225C10.8 9.225 10.35 8.775 10.35 8.217C10.35 7.65 10.8 7.2 11.367 7.2ZM6.3 11.25H11.7C11.25 12.339 10.179 13.05 9 13.05C7.821 13.05 6.75 12.339 6.3 11.25Z" fill="#686868"/>
                                </svg>
                                <span>{{ "Shortcuts" | translate }}</span>
                            </div>
                        </div>

                        <div class="shorts">
                            <div class="short">
                                <div class="short_img">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16 12V16H2V12H16ZM17 10H1C0.45 10 0 10.45 0 11V17C0 17.55 0.45 18 1 18H17C17.55 18 18 17.55 18 17V11C18 10.45 17.55 10 17 10ZM4 15.5C3.18 15.5 2.5 14.83 2.5 14C2.5 13.17 3.18 12.5 4 12.5C4.82 12.5 5.5 13.17 5.5 14C5.5 14.83 4.83 15.5 4 15.5ZM16 2V6H2V2H16ZM17 0H1C0.45 0 0 0.45 0 1V7C0 7.55 0.45 8 1 8H17C17.55 8 18 7.55 18 7V1C18 0.45 17.55 0 17 0ZM4 5.5C3.18 5.5 2.5 4.83 2.5 4C2.5 3.17 3.18 2.5 4 2.5C4.82 2.5 5.5 3.18 5.5 4C5.5 4.82 4.83 5.5 4 5.5Z" fill="#686868"/>
                                    </svg>
                                </div>
                                <div class="short_info">
                                    <div class="info_title">
                                        {{ "Cards" | translate }}
                                    </div>

                                    <div class="short_items">

                                        <div class="short_item" (click)="goToItem($event, item)" *ngFor="let item of groupedBarItems(barItems).tasks">
                                            <span class="text_one_line">
                                                {{item.switchData.name || "no name"}}
                                            </span>
                                            <mat-icon (click)="removeItem($event,item)">close</mat-icon>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="short">
                                <div class="short_img">
                                    <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0 14.4H18V10.8H0V14.4ZM1.8 11.7H3.6V13.5H1.8V11.7ZM0 0V3.6H18V0H0ZM3.6 2.7H1.8V0.9H3.6V2.7ZM0 9H18V5.4H0V9ZM1.8 6.3H3.6V8.1H1.8V6.3Z" fill="#686868"/>
                                    </svg>
                                </div>
                                <div class="short_info">
                                    <div class="info_title">
                                        {{ "Jobs" | translate }}
                                    </div>

                                    <div class="short_items">

                                        <div class="short_item" (click)="goToItem($event, item)" *ngFor="let item of groupedBarItems(barItems).works">
                                            <span class="text_one_line">
                                                {{item.switchData.name || "no name"}}
                                            </span>
                                            <mat-icon (click)="removeItem($event,item)">close</mat-icon>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="short">
                                <div class="short_img">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16.2 0H1.8C1.32261 0 0.864773 0.189642 0.527208 0.527208C0.189642 0.864773 0 1.32261 0 1.8V18L3.6 14.4H16.2C16.6774 14.4 17.1352 14.2104 17.4728 13.8728C17.8104 13.5352 18 13.0774 18 12.6V1.8C18 0.801 17.19 0 16.2 0Z" fill="#686868"/>
                                    </svg>
                                </div>
                                <div class="short_info">
                                    <div class="info_title">
                                        {{ "Chats" | translate }}
                                    </div>

                                    <div class="short_items">

                                        <div class="short_item" (click)="goToItem($event, item)" *ngFor="let item of groupedBarItems(barItems).chats">
                                            <span class="text_one_line">
                                                {{item.switchData.name || "no name"}}
                                            </span>
                                            <mat-icon (click)="removeItem($event,item)">close</mat-icon>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </a>
        </div> -->
        <div class="settings_block">
    
            <div class="add_task_wrp" *ngIf="!!user_id && canCreate">
                <button mat-icon-button class="add_task" (click)="addNewTask($event);" style="color: #219653 !important;" #tooltip="matTooltip"
                matTooltip="{{ 'Create card' | translate }}"
                [matTooltipPosition]="'above'">
                    <mat-spinner class="spin_24" *ngIf="createLoader"></mat-spinner>  
                    <mat-icon style="color: #219653 !important;" *ngIf="!createLoader">add_circle</mat-icon>
                </button>
            </div>
    
            <div class="upload_trigger_wrp" *ngIf="!!user_id" (click)="$event.stopImmediatePropagation(); $event.stopPropagation(); $event.preventDefault();">
                <button mat-icon-button class="upload_trigger" (click)="toggleUploads();" #tooltip="matTooltip"
                matTooltip="{{ 'Upload center' | translate }}"
                [matTooltipPosition]="'above'">
                    <mat-icon>
                        <svg fill="none" height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg"><path clip-rule="evenodd" d="M3 17C3 16.4477 3.44772 16 4 16H16C16.5523 16 17 16.4477 17 17C17 17.5523 16.5523 18 16 18H4C3.44772 18 3 17.5523 3 17ZM6.29289 6.70711C5.90237 6.31658 5.90237 5.68342 6.29289 5.29289L9.29289 2.29289C9.48043 2.10536 9.73478 2 10 2C10.2652 2 10.5196 2.10536 10.7071 2.29289L13.7071 5.29289C14.0976 5.68342 14.0976 6.31658 13.7071 6.70711C13.3166 7.09763 12.6834 7.09763 12.2929 6.70711L11 5.41421L11 13C11 13.5523 10.5523 14 10 14C9.44771 14 9 13.5523 9 13L9 5.41421L7.70711 6.70711C7.31658 7.09763 6.68342 7.09763 6.29289 6.70711Z" fill="#686868" fill-rule="evenodd"/></svg>
                    </mat-icon>
                    <ng-container *ngIf="uploadProgress?.uploadedFiles">
                        <span class="uploaded_files">{{ uploadProgress?.uploadedFiles }}</span>
                        <span class="uploaded_size">{{ uploadProgress?.uploadedSize | filesize }}</span>
                    </ng-container>
                </button>
            </div>

            <!-- <div class="add_task_wrp" *ngIf="!!user_id">
                <button mat-icon-button (click)="openNotifications($event);" style="color: #686868;">
                    <mat-icon style="color: #686868;">notifications</mat-icon>
                </button>
            </div> -->

            <div *ngIf="!!user_id && !!company && !!company['id']" style="margin: 0 0 0 15px !important" class="burger_trigger_wrp" (mouseenter)="isOpenDeskBurger = true" (mouseleave)="isOpenDeskBurger = false">
                <svg id="mob_burger" class="ham hamRotate ham1" viewBox="0 0 100 100" width="80" [ngClass]="{'active': isOpenDeskBurger}">
                    <path
                          class="line top"
                          d="m 30,33 h 40 c 0,0 9.044436,-0.654587 9.044436,-8.508902 0,-7.854315 -8.024349,-11.958003 -14.89975,-10.85914 -6.875401,1.098863 -13.637059,4.171617 -13.637059,16.368042 v 40" />
                    <path
                          class="line middle"
                          d="m 30,50 h 40" />
                    <path
                          class="line bottom"
                          d="m 30,67 h 40 c 12.796276,0 15.357889,-11.717785 15.357889,-26.851538 0,-15.133752 -4.786586,-27.274118 -16.667516,-27.274118 -11.88093,0 -18.499247,6.994427 -18.435284,17.125656 l 0.252538,40" />
                </svg>

                <div class="expanded_block_out" #burgNav>
                    <div class="expanded_block_in more_exp burger_exp">
                        <div class="title_nav" style="display: flex; align-items: center; justify-content: space-between;">
                            <div class="d-f-c">
                                <span>
                                    {{ company?.name }} {{"menu" | translate}}:
                                </span>
                                <span *ngIf="!!workTimer">{{workTimer | timeFormat}}</span>
                            </div>
                        </div>
                        <section class="">
                            <div class="exp_form">
                                <mat-list class="main_burger_list">
                                    <!-- activity_item -->
                                    <mat-list-item class="exp_page sub_exp_item activity_item" (click)="closeNav(burgNav); $event.stopImmediatePropagation(); $event.stopPropagation(); $event.preventDefault(); checkActions()">
                                        <mat-icon>
                                            <svg width="23" height="20" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M13.6905 5.47619H12.0476V10.9524L16.7352 13.7343L17.5238 12.409L13.6905 10.131V5.47619ZM13.1429 0C10.5286 0 8.02138 1.03852 6.1728 2.88709C4.32423 4.73566 3.28571 7.24287 3.28571 9.85714H0L4.33714 14.271L8.7619 9.85714H5.47619C5.47619 7.82382 6.28393 5.87377 7.72171 4.43599C9.15948 2.99821 11.1095 2.19048 13.1429 2.19048C15.1762 2.19048 17.1262 2.99821 18.564 4.43599C20.0018 5.87377 20.8095 7.82382 20.8095 9.85714C20.8095 11.8905 20.0018 13.8405 18.564 15.2783C17.1262 16.7161 15.1762 17.5238 13.1429 17.5238C11.029 17.5238 9.11238 16.6586 7.73238 15.2676L6.17714 16.8229C7.96238 18.619 10.4048 19.7143 13.1429 19.7143C15.7571 19.7143 18.2643 18.6758 20.1129 16.8272C21.9615 14.9786 23 12.4714 23 9.85714C23 7.24287 21.9615 4.73566 20.1129 2.88709C18.2643 1.03852 15.7571 0 13.1429 0Z" fill="#000"/>
                                            </svg>
                                        </mat-icon>
                                        <span class="sub_exp_val" [ngClass]="{'with_statuses': company && company.employees.length == 1}">
                                            {{ "Activity" | translate }}

                                            <div class="status_btn" *ngIf="company && company.employees.length == 1">
                                                <div style="color: #2ecc71;" (click)="setEmployeeStatus($event, 'active')" *ngIf="company.employees[0].status !== 'active'">{{ (company.employees[0].status !== 'break' ? "Start Working" : "Continue") | translate}}</div>
                                                <div style="color: #f1c40f;" (click)="setEmployeeStatus($event, 'break')" *ngIf="company.employees[0].status === 'active'">{{"Break" | translate}}</div>
                                                <div style="color: #c0392b;" (click)="setEmployeeStatus($event, 'inactive')" *ngIf="['active', 'break'].includes(company?.employees[0]?.status)">{{"Finish" | translate}}</div>
                                            </div>

                                            <div class="schedule" (click)="$event.preventDefault(); $event.stopPropagation(); (todayScheduleData && todayScheduleData?.length ? userCalendar(company) : null );" *ngIf="company.employees && company.employees.length">
                                                <div class="label">
                                                    {{ (todayScheduleData && todayScheduleData?.length) ? "My schedule Today" : "My schedule" | translate}}
                                                    <mat-icon *ngIf="todayScheduleData && todayScheduleData?.length" style="color: #1877F2;">edit</mat-icon>
                                                </div>
                                                <ng-container *ngIf="todayScheduleData && todayScheduleData?.length; else elseTemplateHasToday">
                                                    <div class="intervals">
                                                        <div class="interval" *ngFor="let interval of todayScheduleData">
                                                            <div class="from">{{interval.from_time*1000|date:'hh:mm a':'':activeLang}}</div>
                                                            <div class="ft_spacer">-</div>
                                                            <div class="to">{{interval.to_time*1000|date:'hh:mm a':'':activeLang}}</div>
                                                            <div class="duration">({{(interval.to_time - interval.from_time) | timeFormat:true}})</div>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                                <ng-template #elseTemplateHasToday>
                                                    <button (click)="scheduleSettings(company)">{{"Set schedule" | translate}}</button>
                                                </ng-template>
                                                
                                            </div>
                                        </span>
                                    </mat-list-item>

                                    <div class="exp_divider"></div>

                                    <a mat-list-item href="/settings?company_id={{company?.id}}&count=1&page=finances_dashboard" [class.active]="isLinkActive('page=finances_dashboard')" class="exp_page sub_exp_item" (click)='closeNav(burgNav); $event.stopImmediatePropagation(); $event.stopPropagation(); $event.preventDefault(); closeAllDialogs(); goToFinances($event, company?.id)'>
                                        <mat-icon>account_balance</mat-icon>
                                        <span class="sub_exp_val">
                                            {{ "Finances" | translate }}
                                        </span>

                                        <div class="expanded_block_out_sub">
                                            <div class="expanded_block_in_sub exp_def">
                                                <section class="">   
                                                    <mat-list>
                                                        <a *ngIf="company?.permissions.includes('owner') || company?.permissions.includes('admin')" mat-list-item class="exp_page" href="/settings?company_id={{company?.id}}&page=finances&tab=current_activity" [class.active]="isLinkActive('page=finances&tab=current_activity')" (click)='closeNav(burgNav); $event.stopImmediatePropagation(); $event.stopPropagation(); $event.preventDefault(); closeAllDialogs(); goToCurrentActivity($event, company?.id)'>
                                                            <mat-icon>analytics</mat-icon>
                                                            <span class="sub_exp_val">{{ "Current Activity" | translate }}</span> 
                                                        </a>
                                                        <a mat-list-item class="exp_page" href="/settings?company_id={{company?.id}}&page=finances&tab=statements" [class.active]="isLinkActive('page=finances&tab=statements')" (click)='closeNav(burgNav); $event.stopImmediatePropagation(); $event.stopPropagation(); $event.preventDefault(); closeAllDialogs(); goToStatements($event, company?.id)'>
                                                            <mat-icon>show_chart</mat-icon>
                                                            <span class="sub_exp_val">{{ "Statements" | translate }}</span> 
                                                        </a>
                                                        <a mat-list-item class="exp_page" href="/settings?company_id={{company?.id}}&page=finances&tab=payouts" [class.active]="isLinkActive('page=finances&tab=payouts')" (click)='closeNav(burgNav); $event.stopImmediatePropagation(); $event.stopPropagation(); $event.preventDefault(); closeAllDialogs(); goToPayouts($event, company?.id)'>
                                                            <mat-icon>payments</mat-icon>
                                                            <span class="sub_exp_val">{{ "Payouts" | translate }}</span>
                                                        </a>
                                                    </mat-list>
                                                </section>
                                            </div>
                                        </div>
                                    </a>
                    
                                    <a mat-list-item class="exp_page sub_exp_item" [class.active]="isLinkActive('page=automation')" *ngIf="company?.permissions.includes('owner') || company?.permissions.includes('admin') || company?.permissions.includes('manager')" href="/settings?company_id={{company?.id}}&page=automation" (click)='closeNav(burgNav); $event.stopImmediatePropagation(); $event.stopPropagation(); $event.preventDefault(); closeAllDialogs(); goToScenarios($event, company?.id)'>
                                        <mat-icon>
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M9 5H13V7H9V9H11C11.5304 9 12.0391 9.21071 12.4142 9.58579C12.7893 9.96086 13 10.4696 13 11V13C13 13.5304 12.7893 14.0391 12.4142 14.4142C12.0391 14.7893 11.5304 15 11 15H7V13H11V11H9C8.46957 11 7.96086 10.7893 7.58579 10.4142C7.21071 10.0391 7 9.53043 7 9V7C7 6.46957 7.21071 5.96086 7.58579 5.58579C7.96086 5.21071 8.46957 5 9 5ZM10 0C11.3132 0 12.6136 0.258658 13.8268 0.761205C15.0401 1.26375 16.1425 2.00035 17.0711 2.92893C17.9997 3.85752 18.7362 4.95991 19.2388 6.17317C19.7413 7.38642 20 8.68678 20 10C20 12.6522 18.9464 15.1957 17.0711 17.0711C15.1957 18.9464 12.6522 20 10 20C8.68678 20 7.38642 19.7413 6.17317 19.2388C4.95991 18.7362 3.85752 17.9997 2.92893 17.0711C1.05357 15.1957 0 12.6522 0 10C0 7.34784 1.05357 4.8043 2.92893 2.92893C4.8043 1.05357 7.34784 0 10 0ZM10 2C7.87827 2 5.84344 2.84285 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.84344 17.1571 7.87827 18 10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 7.87827 17.1571 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2Z" fill="black"/>
                                            </svg>
                                        </mat-icon>
                                        <span class="sub_exp_val">{{ "Automation" | translate }}</span> 

                                        <div class="expanded_block_out_sub">
                                            <div class="expanded_block_in_sub exp_def">
                                                <section class="">   
                                                    <mat-list>
                                                        <a mat-list-item class="exp_page" [class.active]="isLinkActive('page=checklists')" *ngIf="company?.permissions.includes('owner') || company?.permissions.includes('admin') || company?.permissions.includes('manager')" href="/settings?company_id={{company?.id}}&page=checklists" (click)='closeNav(burgNav); $event.stopImmediatePropagation(); $event.stopPropagation(); $event.preventDefault(); closeAllDialogs(); goToChecklists($event, company?.id)'>
                                                            <mat-icon>checklist</mat-icon>
                                                            <span class="sub_exp_val">{{ "Checklists" | translate }}</span>
                                                        </a>
                                                    </mat-list>
                                                </section>
                                            </div>
                                        </div>
                                    </a>
                    
                                    <a mat-list-item class="exp_page sub_exp_item" [class.active]="isLinkActive('page=workspace_members')" href="/settings?company_id={{company?.id}}&page=workspace_members" (click)='closeNav(burgNav); $event.stopImmediatePropagation(); $event.stopPropagation(); $event.preventDefault(); closeAllDialogs(); goToMembers($event, company?.id)'>
                                        <mat-icon>badge</mat-icon>
                                        <span class="sub_exp_val">
                                            {{ 'Workspace Members' | translate }}
                                        </span>

                                        <div class="expanded_block_out_sub">
                                            <div class="expanded_block_in_sub exp_def">
                                                <section class="">   
                                                    <mat-list>
                                                        <a mat-list-item class="exp_page" [class.active]="isLinkActive('page=individuals')" href="/settings?company_id={{company?.id}}&page=individuals" (click)='closeNav(burgNav); $event.stopImmediatePropagation(); $event.stopPropagation(); $event.preventDefault(); closeAllDialogs(); goToEmployees($event, company?.id)'>
                                                            <mat-icon>badge</mat-icon>
                                                            <span class="sub_exp_val">
                                                                {{ 'Individuals' | translate }}
                                                            </span>
                                                        </a>
                                        
                                                        <a mat-list-item class="exp_page" [class.active]="isLinkActive('page=partner_workspaces')" href="/settings?company_id={{company?.id}}&page=partner_workspaces" title="{{ 'Partner Workspaces' | translate }}" (click)="closeNav(burgNav); $event.stopImmediatePropagation(); $event.stopPropagation(); $event.preventDefault(); goToPartners($event, company?.id)">
                                                            <mat-icon>supervised_user_circle</mat-icon>
                                                            <span class="sub_exp_val">{{ "Partner Workspaces" | translate }}</span>
                                                        </a>
                                                        
                                                        <ng-container *ngIf="company?.permissions.includes('owner') || company?.permissions.includes('admin') || company?.permissions.includes('hr'); else templateNameApplication">
                                                            <a mat-list-item class="exp_page" [class.active]="isLinkActive('page=questionnaires')" href="/settings?company_id={{company?.id}}&page=questionnaires" (click)='closeNav(burgNav); $event.stopImmediatePropagation(); $event.stopPropagation(); $event.preventDefault(); closeAllDialogs(); goToQuestionnaires($event, company?.id)'>
                                                                <mat-icon>feed</mat-icon>
                                                                <span class="sub_exp_val">{{ "Onboarding forms" | translate }}</span> 
                                                            </a>
                                                            <a *ngIf="company?.permissions.includes('admin') || company?.permissions.includes('hr')" mat-list-item class="exp_page" [class.active]="isLinkActive('page=form')" href="/settings?company_id={{company?.id}}&page=form" (click)='closeNav(burgNav); $event.stopImmediatePropagation(); $event.stopPropagation(); $event.preventDefault(); closeAllDialogs(); goToForm($event, company?.id)'>
                                                                <mat-icon>feed</mat-icon>
                                                                <span class="sub_exp_val">{{ "Form" | translate }}</span> 
                                                            </a>
                                                        </ng-container>
                                                        <ng-template #templateNameApplication>
                                                            <a mat-list-item class="exp_page" [class.active]="isLinkActive('page=form')" href="/settings?company_id={{company?.id}}&page=form" (click)='closeNav(burgNav); $event.stopImmediatePropagation(); $event.stopPropagation(); $event.preventDefault(); closeAllDialogs(); goToForm($event, company?.id)'>
                                                                <mat-icon>feed</mat-icon>
                                                                <span class="sub_exp_val">{{ "Form" | translate }}</span> 
                                                            </a>
                                                        </ng-template>
                                        
                                                        <a mat-list-item class="exp_page" [class.active]="isLinkActive('page=users')" href="/settings?company_id={{company?.id}}&page=users" title="{{ 'Users' | translate }}" (click)='closeNav(burgNav); $event.stopImmediatePropagation(); $event.stopPropagation(); $event.preventDefault(); closeAllDialogs(); goToUsers($event, company?.id)'>
                                                            <mat-icon>people</mat-icon>
                                                            <span class="sub_exp_val">{{ "Users" | translate }}</span>
                                                        </a>
                                                    </mat-list>
                                                </section>
                                            </div>
                                        </div>
                                    </a>
                    
                                    <a mat-list-item class="exp_page sub_exp_item" [class.active]="isLinkActive('page=categorization')" href="/settings?company_id={{company?.id}}&page=categorization" (click)='closeNav(burgNav); $event.stopImmediatePropagation(); $event.stopPropagation(); $event.preventDefault(); closeAllDialogs(); goToCategorization($event, company?.id)'>
                                        <mat-icon>category</mat-icon>
                                        <span class="sub_exp_val">
                                            {{ 'Categorization' | translate }}
                                        </span>

                                        <div class="expanded_block_out_sub">
                                            <div class="expanded_block_in_sub exp_def">
                                                <section class="">   
                                                    <mat-list>
                                                        <a mat-list-item [class.active]="isLinkActive('page=structure')" class="exp_page" href="/settings?company_id={{company?.id}}&page=structure" (click)='closeNav(burgNav); $event.stopImmediatePropagation(); $event.stopPropagation(); $event.preventDefault(); closeAllDialogs(); goToStructure($event, company?.id)'>
                                                            <mat-icon>engineering</mat-icon>
                                                            <span class="sub_exp_val">
                                                                {{ 'Company structure' | translate }}
                                                            </span>
                                                        </a>
                                                        <a mat-list-item [class.active]="isLinkActive('page=task_types')" class="exp_page" href="/settings?company_id={{company?.id}}&page=task_types" (click)='closeNav(burgNav); $event.stopImmediatePropagation(); $event.stopPropagation(); $event.preventDefault(); closeAllDialogs(); goToTaskTypes($event, company?.id)'>
                                                            <mat-icon>checklist_rtl</mat-icon>
                                                            <span class="sub_exp_val">
                                                                {{ 'Card Types' | translate }}
                                                            </span>
                                                        </a>
                                                        <a mat-list-item [class.active]="isLinkActive('page=job_types')" class="exp_page" href="/settings?company_id={{company?.id}}&page=job_types" (click)='closeNav(burgNav); $event.stopImmediatePropagation(); $event.stopPropagation(); $event.preventDefault(); closeAllDialogs(); goToJobTypes($event, company?.id)'>
                                                            <mat-icon>checklist_rtl</mat-icon>
                                                            <span class="sub_exp_val">
                                                                {{ 'Job types' | translate }}
                                                            </span>
                                                        </a>
                                                        <a mat-list-item [class.active]="isLinkActive('page=company')" class="exp_page" href="/settings?company_id={{company?.id}}&page=company" (click)='closeNav(burgNav); $event.stopImmediatePropagation(); $event.stopPropagation(); $event.preventDefault(); closeAllDialogs(); goToGroups($event, company?.id)'>
                                                            <mat-icon>groups</mat-icon>
                                                            <span class="sub_exp_val">
                                                                {{ 'Groups' | translate }}
                                                            </span>
                                                        </a>
                                                        <a mat-list-item [class.active]="isLinkActive('page=bookmarks')" class="exp_page" href="/settings?company_id={{company?.id}}&page=bookmarks" (click)='closeNav(burgNav); $event.stopImmediatePropagation(); $event.stopPropagation(); $event.preventDefault(); closeAllDialogs(); goToBookmarks($event, company?.id)'>
                                                            <mat-icon>bookmarks</mat-icon>
                                                            <span class="sub_exp_val">
                                                                {{ 'Presets' | translate }}
                                                            </span>
                                                        </a>
                                                        <a mat-list-item [class.active]="isLinkActive('page=parameters')" href="/settings?company_id={{company?.id}}&page=parameters" *ngIf="company?.permissions.includes('owner') || company?.permissions.includes('admin') || company?.permissions.includes('manager')" class="exp_page" (click)='closeNav(burgNav); $event.stopImmediatePropagation(); $event.stopPropagation(); $event.preventDefault(); closeAllDialogs(); goToParameters($event, company?.id)'>
                                                            <mat-icon>settings_suggest</mat-icon>
                                                            <span class="sub_exp_val">
                                                                {{ "Tags" | translate }}
                                                            </span>
                                                        </a>
                                                    </mat-list>
                                                </section>
                                            </div>
                                        </div>
                                    </a>
                                    
                                    <a [class.active]="isLinkActive('page=profiles')" mat-list-item class="exp_page sub_exp_item" href="/settings?company_id={{company?.id}}&page=profiles" (click)='closeNav(burgNav); $event.stopImmediatePropagation(); $event.stopPropagation(); $event.preventDefault(); closeAllDialogs(); goToProfiles($event, company?.id)'>
                                        <mat-icon>public</mat-icon>
                                        <span class="sub_exp_val">
                                            {{ 'Outlets' | translate }}
                                        </span>

                                        <div class="expanded_block_out_sub">
                                            <div class="expanded_block_in_sub exp_def">
                                                <section class="">   
                                                    <mat-list>
                                                        <a mat-list-item class="exp_page" [class.active]="isLinkActive('page=sftp')" href="/settings?company_id={{company?.id}}&page=sftp" (click)='closeNav(burgNav); $event.stopImmediatePropagation(); $event.stopPropagation(); $event.preventDefault(); closeAllDialogs(); goToSftp($event, company?.id)'>
                                                            <mat-icon>outbox</mat-icon>
                                                            <span class="sub_exp_val">{{ "Youtube Partner CMS" | translate }}</span>
                                                        </a>
                                                        <a mat-list-item class="exp_page" [class.active]="isLinkActive('page=manage-auth')" href="/settings?company_id={{company?.id}}&page=manage-auth" (click)='closeNav(burgNav); $event.stopImmediatePropagation(); $event.stopPropagation(); $event.preventDefault(); closeAllDialogs(); goToManageAuth($event, company?.id)'>
                                                            <mat-icon>manage_accounts</mat-icon>
                                                            <span class="sub_exp_val">{{ "Authorizations" | translate }}</span>
                                                        </a>
                                                        <a mat-list-item class="exp_page" [class.active]="isLinkActive('page=import-data')" href="/settings?company_id={{company?.id}}&page=import-data" (click)='closeNav(burgNav); $event.stopImmediatePropagation(); $event.stopPropagation(); $event.preventDefault(); closeAllDialogs(); goToImportData($event, company?.id)'>
                                                            <mat-icon>publish</mat-icon>
                                                            <span class="sub_exp_val">{{ "Import Data" | translate }}</span>
                                                        </a>
                                                    </mat-list>
                                                </section>
                                            </div>
                                        </div>
                                    </a>

                                    <a [class.active]="isLinkActive('page=usage')" mat-list-item class="exp_page sub_exp_item" href="/settings?company_id={{company?.id}}&page=usage" (click)='closeNav(burgNav); $event.stopImmediatePropagation(); $event.stopPropagation(); $event.preventDefault(); closeAllDialogs(); goToUsage($event, company?.id)'>
                                        <mat-icon>data_usage</mat-icon>
                                        <span class="sub_exp_val">
                                            {{ 'Usage' | translate }}
                                        </span>

                                        <div class="expanded_block_out_sub">
                                            <div class="expanded_block_in_sub exp_def">
                                                <section class="">   
                                                    <mat-list>
                                                        <a mat-list-item class="exp_page" [class.active]="isLinkActive('page=vpu_usage')" href="/settings?company_id={{company?.id}}&page=vpu_usage" (click)='closeNav(burgNav); $event.stopImmediatePropagation(); $event.stopPropagation(); $event.preventDefault(); closeAllDialogs(); goToVpuUsage($event, company?.id)'>
                                                            <mat-icon>movie_filter</mat-icon>
                                                            <span class="sub_exp_val">{{ 'Video Processing Units (VPU)' | translate }}</span>
                                                        </a>
                                                        <a mat-list-item class="exp_page" [class.active]="isLinkActive('page=total_usage')" href="/settings?company_id={{company?.id}}&page=total_usage" (click)='closeNav(burgNav); $event.stopImmediatePropagation(); $event.stopPropagation(); $event.preventDefault(); closeAllDialogs(); goToTotalUsage($event, company?.id)'>
                                                            <mat-icon>perm_media</mat-icon>
                                                            <span class="sub_exp_val">{{ "Storage usage" | translate }}</span>
                                                        </a>
                                                        <a mat-list-item class="exp_page" [class.active]="isLinkActive('page=dubbing_usage')" href="/settings?company_id={{company?.id}}&page=dubbing_usage" (click)='closeNav(burgNav); $event.stopImmediatePropagation(); $event.stopPropagation(); $event.preventDefault(); closeAllDialogs(); goToDubbingUsage($event, company?.id)'>
                                                            <mat-icon>settings_voice</mat-icon>
                                                            <span class="sub_exp_val">{{ "Dubbing" | translate }}</span>
                                                        </a>
                                                        <a mat-list-item class="exp_page" [class.active]="isLinkActive('page=speech_to_text')" href="/settings?company_id={{company?.id}}&page=speech_to_text" (click)='closeNav(burgNav); $event.stopImmediatePropagation(); $event.stopPropagation(); $event.preventDefault(); closeAllDialogs(); goToStuUsage($event, company?.id)'>
                                                            <mat-icon>record_voice_over</mat-icon>
                                                            <span class="sub_exp_val">{{ "Speech to Text" | translate }}</span>
                                                        </a>
                                                        <a mat-list-item class="exp_page" [class.active]="isLinkActive('page=text_to_speech')" href="/settings?company_id={{company?.id}}&page=text_to_speech" (click)='closeNav(burgNav); $event.stopImmediatePropagation(); $event.stopPropagation(); $event.preventDefault(); closeAllDialogs(); goToTsuUsage($event, company?.id)'>
                                                            <mat-icon>keyboard_voice</mat-icon>
                                                            <span class="sub_exp_val">{{ "Text to Speech" | translate }}</span>
                                                        </a>
                                                        <a mat-list-item class="exp_page" [class.active]="isLinkActive('page=ai_usage')" href="/settings?company_id={{company?.id}}&page=ai_usage" (click)='closeNav(burgNav); $event.stopImmediatePropagation(); $event.stopPropagation(); $event.preventDefault(); closeAllDialogs(); goToAiUsage($event, company?.id)'>
                                                            <mat-icon>assistant</mat-icon>
                                                            <span class="sub_exp_val">{{ "Ai usage" | translate }}</span>
                                                        </a>
                                                    </mat-list>
                                                </section>
                                            </div>
                                        </div>
                                    </a>
                                    <!-- <a [class.active]="isLinkActive('page=usage')" mat-list-item class="exp_page" href="/settings?company_id={{company?.id}}&page=usage" (click)='closeNav(burgNav); $event.stopImmediatePropagation(); $event.stopPropagation(); $event.preventDefault(); closeAllDialogs(); goToUsage($event, company?.id)'>
                                        <mat-icon>movie_filter</mat-icon>
                                        <span class="sub_exp_val">
                                            {{ 'Usage' | translate }}
                                        </span>
                                    </a> -->

                                    <div class="exp_divider"></div>
                                    
                                    <a [class.active]="activeSections() == 'Dashboard'" mat-list-item href="/dashboard?company_id={{company?.id}}" class="exp_page sub_exp_item" (click)='closeNav(burgNav); $event.stopImmediatePropagation(); $event.stopPropagation(); $event.preventDefault(); closeAllDialogs(); goToDashboard($event, company?.id)'>
                                        <mat-icon>dashboard</mat-icon>
                                        <span class="sub_exp_val">
                                            {{ "Dashboard" | translate }}
                                        </span>
                                    </a>
                                    <a [class.active]="activeSections() == 'Cards'" mat-list-item href="/tasks?company_id={{company?.id}}&order_by=updated_desc&count=1" class="exp_page sub_exp_item" (click)='closeNav(burgNav); $event.stopImmediatePropagation(); $event.stopPropagation(); $event.preventDefault(); closeAllDialogs(); goToCompany(company?.id, $event)'>
                                        <mat-icon>format_list_bulleted</mat-icon>
                                        <span class="sub_exp_val">
                                            {{ "Cards" | translate }} <span *ngIf="(company?.permissions.includes('manager') || company?.permissions.includes('employee')) && +company?.rate_target">({{ "Points" | translate }}: {{company?.rate_target | reportsNumber }})</span> 
                                        </span>
                                    </a>
                                    <a [class.active]="activeSections() == 'Jobs'" mat-list-item href="/jobs?company_id={{company?.id}}&order_by=updated_desc&count=1" class="exp_page sub_exp_item" (click)='closeNav(burgNav); $event.stopImmediatePropagation(); $event.stopPropagation(); $event.preventDefault(); closeAllDialogs(); goToJobs($event, company?.id)'>
                                        <mat-icon>
                                            <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0 16H20V12H0V16ZM2 13H4V15H2V13ZM0 0V4H20V0H0ZM4 3H2V1H4V3ZM0 10H20V6H0V10ZM2 7H4V9H2V7Z" fill="#000"/>
                                            </svg>
                                        </mat-icon>
                                        <span class="sub_exp_val">
                                            {{ "Jobs" | translate }} <span *ngIf="(company?.permissions.includes('manager') || company?.permissions.includes('employee')) && +company?.rate_target">({{ "Points" | translate }}: {{company?.rate_target | reportsNumber }})</span> 
                                        </span>
                                    </a>
                                    <a [class.active]="activeSections() == 'Posts Start' || activeSections() == 'Posts'" mat-list-item href="/urls-start?company_id={{company?.id}}&order_by=content_published_at_desc&count=1" *ngIf="company | perm:'see_task_channels'" class="exp_page sub_exp_item" (click)='closeNav(burgNav); $event.stopImmediatePropagation(); $event.stopPropagation(); $event.preventDefault(); closeAllDialogs(); goToUrlsStart($event, company?.id)'>
                                        <mat-icon>
                                            <svg class="urls_icon" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M3.9 12c0-1.71 1.39-3.1 3.1-3.1h4V7H7c-2.76 0-5 2.24-5 5s2.24 5 5 5h4v-1.9H7c-1.71 0-3.1-1.39-3.1-3.1zM8 13h8v-2H8v2zm9-6h-4v1.9h4c1.71 0 3.1 1.39 3.1 3.1s-1.39 3.1-3.1 3.1h-4V17h4c2.76 0 5-2.24 5-5s-2.24-5-5-5z"/></svg>
                                        </mat-icon>
                                        <span class="sub_exp_val">
                                            {{ "Posts" | translate }} 
                                        </span>
                                    </a>
                                    <a [class.active]="activeSections() == 'Chats'" mat-list-item href="/chats?company_id={{company?.id}}&order_by=updated_desc&count=1" class="exp_page sub_exp_item" (click)='closeNav(burgNav); $event.stopImmediatePropagation(); $event.stopPropagation(); $event.preventDefault(); closeAllDialogs(); goToChats($event, company?.id)'>
                                        <mat-icon>chat</mat-icon>
                                        <span class="sub_exp_val">
                                            {{ "Chats" | translate }} 
                                        </span>
                                    </a>
                    
                                    <a [class.active]="activeSections() == 'Board by Order'" mat-list-item href="/board-list?company_id={{company?.id}}&count=1" class="exp_page sub_exp_item" (click)='closeNav(burgNav); $event.stopImmediatePropagation(); $event.stopPropagation(); $event.preventDefault(); closeAllDialogs(); goToBoardList($event, company?.id)'>
                                        <mat-icon>
                                            <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.8 13.5H18V11.7H10.8V13.5ZM10.8 8.55H18V6.75H10.8V8.55ZM10.8 3.6H18V1.8H10.8V3.6ZM0 7.65C0 4.428 2.628 1.8 5.85 1.8H6.3V0L9 2.7L6.3 5.4V3.6H5.85C3.6 3.6 1.8 5.4 1.8 7.65C1.8 9.9 3.6 11.7 5.85 11.7H9V13.5H5.85C2.628 13.5 0 10.872 0 7.65Z" fill="#686868"></path></svg>
                                        </mat-icon>
                                        <span class="sub_exp_val">
                                            {{ "Board by Order" | translate }} 
                                        </span>
                                    </a>
                                    
                                    <a [class.active]="activeSections() == 'Board by Date'" mat-list-item href="/board?company_id={{company?.id}}&count=1" class="exp_page sub_exp_item" (click)='closeNav(burgNav); $event.stopImmediatePropagation(); $event.stopPropagation(); $event.preventDefault(); closeAllDialogs(); goToBoard($event, company?.id)'>
                                        <mat-icon>
                                            <svg class="board" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M16.6667 8.33333V6.16667C16.6667 5.0621 15.7712 4.16667 14.6667 4.16667H5.33334C4.22877 4.16667 3.33334 5.0621 3.33334 6.16667V8.33333M16.6667 8.33333V15.5C16.6667 16.6046 15.7712 17.5 14.6667 17.5H5.33333C4.22876 17.5 3.33334 16.6046 3.33334 15.5V8.33333M16.6667 8.33333H3.33334M6.66667 2.5V5.83333M13.3333 2.5V5.83333" stroke="#000" stroke-width="2" stroke-linecap="round"/>
                                                <rect x="10.8333" y="11.6667" width="4.16667" height="4.16667" rx="1" fill="#000"/>
                                            </svg>
                                        </mat-icon>
                                        <span class="sub_exp_val">
                                            {{ "Board by Date" | translate }} 
                                        </span>
                                    </a>
                                    
                                    <a mat-list-item [routerLink]="['/files']" [queryParams]="{company_id: company?.id, count: '1'}" *ngIf="company | perm:'see_files_section'" class="exp_page sub_exp_item" [class.active]="activeSections() == 'Files'" (click)="closeNav(burgNav); $event.stopImmediatePropagation(); $event.stopPropagation(); $event.preventDefault(); closeAllDialogs(); goToMobPage('/files', $event)">
                                        <mat-icon>attach_file</mat-icon>
                                        <span class="sub_exp_val">
                                            {{ "Files" | translate }}
                                        </span>
                                    </a>

                                </mat-list>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
    
            <!-- <div *ngIf="user_id == 0" class="user">
                <a class="nav_link" title="{{ 'Log_in' | translate }}" href="{{ host }}/user/auth/?action=lastauth&amp;returnUrl={{ _window.location.href }}{{ invite }}" >
                    <mat-icon>login</mat-icon>
                </a>
            </div> -->
            
                
            <ng-container *ngIf="user_id == 0">

                <a class="login" title="{{ 'Log_in' | translate }}" href="{{ host }}/user/auth/?action=lastauth&amp;returnUrl={{ _window.location.href }}{{ invite }}">
                    {{'Log_in' | translate}}
                </a>
                <a class="login sign ml-5" title="{{ 'Sign up' | translate }}" href="{{ host }}/user/auth/?action=lastauth&amp;returnUrl={{ _window.location.href }}{{ invite }}">
                    {{'Sign up' | translate}}
                </a>
            </ng-container>

            <button class="nots_icon_wrp" mat-icon-button *ngIf="!!user_id" (click)="openNotifications($event);" style="color: #686868;">
                <ng-container *ngIf="activeNotsCount > 0; else elseTemplateNotsCo">
                    <mat-icon class="nots_icon" style="color: #1877F2;">chat_bubble</mat-icon>
                    <span class="count">{{activeNotsCount}}</span>
                    <!-- [disableRipple]="true" -->
                </ng-container>
                <ng-template #elseTemplateNotsCo>
                    <mat-icon class="nots_icon" style="color: #686868;">chat_bubble_outline</mat-icon>
                </ng-template>
                
            </button>

            <div class="desk_workspaces" *ngIf="!!company && !!company_id.value">
                <span class="text_one_line">
                    {{ company?.name }}
                </span>
                <svg style="width: 10px; height: 10px" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_3582_51300head)">
                    <path d="M7.91699 3.33325L2.08366 3.33325L5.00033 7.91658L7.91699 3.33325Z" fill="#686868"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_3582_51300head">
                    <rect width="10" height="10" fill="white" transform="matrix(0 1 -1 0 10 0)"/>
                    </clipPath>
                    </defs>
                </svg>

                <div class="expanded_block_out" [ngClass]="{'show': isDeskOpenWorkspaces}" #workspacesSelect>
                    <div class="expanded_block_in card_exp">
                        <div class="title_nav">
                            <span>
                                {{ "Workspaces" | translate }}
                            </span>
                        </div>
                        <div class="exp_pages">
                            <a class="exp_page" [ngClass]="{'active': comp.id == company_id.value}" *ngFor="let comp of companyes" (click)="closeAllDialogs($event); closeNav(workspacesSelect); goToCompany(comp.id)">
                                <div class="d-f-c">
                                    <div class="icon">
                                        <ng-container *ngIf="!!comp?.avatarFile?.thumbnail && !!host; else elseTemplatePartnerAvatar">
                                            <img class="partner_avatar" (error)="onPartnerImgError($event)" [src]="host + comp?.avatarFile?.thumbnail" alt="">
                                        </ng-container>
                                        <ng-template #elseTemplatePartnerAvatar>
                                            <img class="partner_avatar" (error)="onPartnerImgError($event)" src="{{ imgRoute }}assets/img/partner.png">
                                        </ng-template>
                                        <mat-icon class="verified_icon" style="color: #1877F2 !important;" *ngIf="comp?.is_verified" #tooltip="matTooltip"
                                        matTooltip="{{companyVerificationText(comp)?.verification_text}}"
                                        [matTooltipPosition]="'left'">verified</mat-icon>
                                    </div>
                                    <span class="text_one_line">
                                        {{ comp?.name }}
                                    </span>
                                </div>
                                <div class="desk_workspace_pages">
                                    <a [routerLink]="['/tasks']" [routerLinkActiveOptions]="{ exact: true }" [queryParams]="{company_id: comp?.id, order_by: 'updated_desc', count: '1'}" class="link-to-page" [class.page-active]="activeSections() == 'Cards'" (click)='closeAllDialogs($event); closeNav(workspacesSelect); goToCompany(comp?.id, $event)'>
                                        <svg style="width: 18px; height: 18px;" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M16 12V16H2V12H16ZM17 10H1C0.45 10 0 10.45 0 11V17C0 17.55 0.45 18 1 18H17C17.55 18 18 17.55 18 17V11C18 10.45 17.55 10 17 10ZM4 15.5C3.18 15.5 2.5 14.83 2.5 14C2.5 13.17 3.18 12.5 4 12.5C4.82 12.5 5.5 13.17 5.5 14C5.5 14.83 4.83 15.5 4 15.5ZM16 2V6H2V2H16ZM17 0H1C0.45 0 0 0.45 0 1V7C0 7.55 0.45 8 1 8H17C17.55 8 18 7.55 18 7V1C18 0.45 17.55 0 17 0ZM4 5.5C3.18 5.5 2.5 4.83 2.5 4C2.5 3.17 3.18 2.5 4 2.5C4.82 2.5 5.5 3.18 5.5 4C5.5 4.82 4.83 5.5 4 5.5Z" fill="#686868"/>
                                        </svg>
                                    </a>
                                    <a [routerLink]="['/jobs']" [routerLinkActiveOptions]="{ exact: true }" [queryParams]="{company_id: comp?.id, order_by: 'updated_desc', count: '1'}" class="link-to-page" [class.page-active]="activeSections() == 'Jobs'" (click)='closeAllDialogs($event); closeNav(workspacesSelect); goToJobs(comp?.id, $event)'>
                                        <svg style="width: 18px; height: 15px" width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0 14.4H18V10.8H0V14.4ZM1.8 11.7H3.6V13.5H1.8V11.7ZM0 0V3.6H18V0H0ZM3.6 2.7H1.8V0.9H3.6V2.7ZM0 9H18V5.4H0V9ZM1.8 6.3H3.6V8.1H1.8V6.3Z" fill="#686868"/>
                                        </svg>
                                    </a>
                                    <a [routerLink]="['/chats']" [routerLinkActiveOptions]="{ exact: true }" [queryParams]="{company_id: comp?.id, order_by: 'updated_desc', count: '1'}" class="link-to-page" [class.page-active]="activeSections() == 'Chats'" (click)='closeAllDialogs($event); closeNav(workspacesSelect); goToChats($event, comp?.id)'>
                                        <svg style="width: 18px; height: 18px;" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M15.6667 0.666664H2.33333C1.41667 0.666664 0.666666 1.41666 0.666666 2.33333V17.3333L4 14H15.6667C16.5833 14 17.3333 13.25 17.3333 12.3333V2.33333C17.3333 1.41666 16.5833 0.666664 15.6667 0.666664Z" fill="#686868"/>
                                        </svg>
                                    </a>
                                    <a *ngIf="company | perm:'see_task_channels'" [routerLink]="['/urls-start']" [routerLinkActiveOptions]="{ exact: true }" [queryParams]="{company_id: comp?.id, count: '1', order_by: 'content_published_at_desc'}" class="link-to-page" [class.page-active]="activeSections() == 'Posts' || activeSections() == 'Posts Start'" (click)='closeAllDialogs($event); closeNav(workspacesSelect); goToUrlsStart($event, comp?.id)'>
                                        <svg style="width: 18px; height: 9px" width="18" height="9" viewBox="0 0 18 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.5 0H9.9V1.8H13.5C14.985 1.8 16.2 3.015 16.2 4.5C16.2 5.985 14.985 7.2 13.5 7.2H9.9V9H13.5C15.984 9 18 6.984 18 4.5C18 2.016 15.984 0 13.5 0ZM8.1 7.2H4.5C3.015 7.2 1.8 5.985 1.8 4.5C1.8 3.015 3.015 1.8 4.5 1.8H8.1V0H4.5C2.016 0 0 2.016 0 4.5C0 6.984 2.016 9 4.5 9H8.1V7.2ZM5.4 3.6H12.6V5.4H5.4V3.6Z" fill="#686868"/>
                                        </svg>
                                    </a>
                                    <a *ngIf="company | perm:'see_task_channels'" [routerLink]="['/urls-dashboard']" [queryParams]="{company_id: comp?.id, count: '1'}" class="link-to-page" [class.page-active]="activeSections() == 'Post Dashboards'" (click)="closeAllDialogs($event); closeNav(workspacesSelect); goToUrlsDash($event, comp?.id)">   
                                        <svg style="width: 18px; min-width: 18px; height: 18px; min-height: 18px;" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11.1111 0V6.66667H20V0M11.1111 20H20V8.88889H11.1111M0 20H8.88889V13.3333H0M0 11.1111H8.88889V0H0V11.1111Z" fill="#686868"/>
                                        </svg>
                                    </a>
                                    <a *ngIf="company | perm:'see_task_channels'" [routerLink]="['/outlets-dashboard']" [queryParams]="{company_id: comp?.id, count: '1'}" class="link-to-page" [class.page-active]="activeSections() == 'Outlet Dashboards'" (click)="closeNav(workspacesSelect); goToOutletsDash($event, comp?.id)">
                                        <svg style="width: 18px; min-width: 18px; height: 18px; min-height: 18px;" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M6.66667 0V16.6667H10V0H6.66667ZM10 2.22222L14.4444 16.6667L17.7778 15.5556L13.3333 1.11111L10 2.22222ZM2.22222 2.22222V16.6667H5.55556V2.22222H2.22222ZM0 17.7778V20H20V17.7778H0Z" fill="#686868"/>
                                        </svg>
                                    </a>
                                    <a [routerLink]="['/board-list']" (contextmenu)="openContext($event, comp, '/board-list'); $event.preventDefault(); $event.stopPropagation();" [routerLinkActiveOptions]="{ exact: true }" [queryParams]="{company_id: comp?.id, count: '1'}" class="link-to-page" [class.page-active]="activeSections() == 'Board by Order'" (click)='closeAllDialogs($event); closeNav(workspacesSelect); goToBoardList($event, comp?.id)'>
                                        <svg style="width: 18px; height: 14px;" width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10.8 13.5H18V11.7H10.8V13.5ZM10.8 8.55H18V6.75H10.8V8.55ZM10.8 3.6H18V1.8H10.8V3.6ZM0 7.65C0 4.428 2.628 1.8 5.85 1.8H6.3V0L9 2.7L6.3 5.4V3.6H5.85C3.6 3.6 1.8 5.4 1.8 7.65C1.8 9.9 3.6 11.7 5.85 11.7H9V13.5H5.85C2.628 13.5 0 10.872 0 7.65Z" fill="#686868"/>
                                        </svg>
                                    </a>
                                    <a [routerLink]="['/board']" [routerLinkActiveOptions]="{ exact: true }" [queryParams]="{company_id: comp?.id, count: '1'}" class="link-to-page board_desk_ico" [class.page-active]="activeSections() == 'Board by Date'" (click)='closeAllDialogs($event); closeNav(workspacesSelect); goToBoard($event, comp?.id)'>
                                        <svg style="width: 20px; height: 20px;" class="board" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M16.6667 8.33333V6.16667C16.6667 5.0621 15.7712 4.16667 14.6667 4.16667H5.33334C4.22877 4.16667 3.33334 5.0621 3.33334 6.16667V8.33333M16.6667 8.33333V15.5C16.6667 16.6046 15.7712 17.5 14.6667 17.5H5.33333C4.22876 17.5 3.33334 16.6046 3.33334 15.5V8.33333M16.6667 8.33333H3.33334M6.66667 2.5V5.83333M13.3333 2.5V5.83333" stroke="#686868" stroke-width="2" stroke-linecap="round"/>
                                            <rect x="10.8333" y="11.6667" width="4.16667" height="4.16667" rx="1" fill="#686868"/>
                                        </svg>
                                    </a>
                                    <a [routerLink]="['/settings']" [routerLinkActiveOptions]="{ exact: true }" [queryParams]="{company_id: comp?.id, count: '1', page: 'finances_dashboard'}" class="link-to-page" [class.page-active]="activeSections() == 'Settings'" (click)='closeAllDialogs($event); closeNav(workspacesSelect); goToFinances($event, comp?.id)'>
                                        <svg style="width: 18px; height: 18px" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_3672_54608ps)">
                                            <path d="M14.3546 9.70505C14.3846 9.48005 14.3996 9.24755 14.3996 9.00005C14.3996 8.76005 14.3846 8.52005 14.3471 8.29505L15.8696 7.11005C16.0046 7.00505 16.0421 6.80255 15.9596 6.65255L14.5196 4.16255C14.4296 3.99755 14.2421 3.94505 14.0771 3.99755L12.2846 4.71755C11.9096 4.43255 11.5121 4.19255 11.0696 4.01255L10.7996 2.10755C10.7696 1.92755 10.6196 1.80005 10.4396 1.80005H7.55959C7.37959 1.80005 7.23709 1.92755 7.20709 2.10755L6.93709 4.01255C6.49459 4.19255 6.08959 4.44005 5.72209 4.71755L3.92959 3.99755C3.76459 3.93755 3.57709 3.99755 3.48709 4.16255L2.05459 6.65255C1.96459 6.81005 1.99459 7.00505 2.14459 7.11005L3.66709 8.29505C3.62959 8.52005 3.59959 8.76755 3.59959 9.00005C3.59959 9.23255 3.61459 9.48005 3.65209 9.70505L2.12959 10.89C1.99459 10.995 1.95709 11.1975 2.03959 11.3475L3.47959 13.8375C3.56959 14.0026 3.75709 14.055 3.92209 14.0025L5.71459 13.2825C6.08959 13.5675 6.48709 13.8075 6.92959 13.9875L7.19959 15.8925C7.23709 16.0725 7.37959 16.2 7.55959 16.2H10.4396C10.6196 16.2 10.7696 16.0725 10.7921 15.8925L11.0621 13.9875C11.5046 13.8075 11.9096 13.5675 12.2771 13.2825L14.0696 14.0025C14.2346 14.0625 14.4221 14.0026 14.5121 13.8375L15.9521 11.3475C16.0421 11.1825 16.0046 10.995 15.8621 10.89L14.3546 9.70505ZM8.99959 11.7C7.51459 11.7 6.29959 10.485 6.29959 9.00005C6.29959 7.51505 7.51459 6.30005 8.99959 6.30005C10.4846 6.30005 11.6996 7.51505 11.6996 9.00005C11.6996 10.485 10.4846 11.7 8.99959 11.7Z" fill="#686868"/>
                                            </g>
                                            <defs>
                                            <clipPath id="clip0_3672_54608ps">
                                            <rect width="18" height="18" fill="white"/>
                                            </clipPath>
                                            </defs>
                                        </svg>
                                    </a>
                                </div>
                            </a>

                        </div>
                    </div>
                </div>
            </div>

            <!-- <mat-form-field appearance="outline" class="workspace_select" *ngIf="companyes?.length > 1 && !!company && !!company_id.value">
               
                <mat-select [disableOptionCentering]="true" selectDir [sdType]="'SOME_HEAD_CLASS'" [formControl]="company_id" (selectionChange)="closeAllDialogs(); changeCompany($event);">
                    <mat-select-trigger>
                        <div class="d-f-c workspace_option">
                            <span>
                                {{ company?.name }}
                         
                            </span>
                        </div>
                    </mat-select-trigger>
                    <mat-option *ngFor="let comp of companyes" [value]="comp?.id">
                        <div class="d-f-c workspace_option">
                            <ng-container *ngIf="!!comp?.avatarFile?.thumbnail && !!host; else elseTemplatePartnerAvatar">
                                <img class="partner_avatar" (error)="onPartnerImgError($event)" [src]="host + comp?.avatarFile?.thumbnail" alt="">
                            </ng-container>
                            <ng-template #elseTemplatePartnerAvatar>
                                <img class="partner_avatar" (error)="onPartnerImgError($event)" src="{{ imgRoute }}assets/img/partner.png">
                            </ng-template>
                            <span class="underline">
                                {{ comp?.name }}
                                <mat-icon class="verified_icon" style="color: #1877F2 !important;" *ngIf="comp?.is_verified" #tooltip="matTooltip"
                                matTooltip="{{companyVerificationText(comp)?.verification_text}}"
                                [matTooltipPosition]="'left'">verified</mat-icon>
                            </span>
                        </div>
                    </mat-option>
                </mat-select>
            </mat-form-field> -->
    
            <div *ngIf="user_id != 0" class="user_menu" (click)="$event.stopImmediatePropagation(); $event.stopPropagation(); $event.preventDefault();">
                <button mat-button [matMenuTriggerFor]="userMenu" id="languages-dropdown">
                    <ng-container *ngIf="!!user?.image; else elseTemplate">
                        <div class="user_photo" [ngStyle]="{'background': 'url(' + user?.image + ') no-repeat center / cover'}"></div>
                    </ng-container>
                    <ng-template #elseTemplate>
                        <mat-icon>account_circle</mat-icon>
                    </ng-template>
                </button>
            
                <mat-menu #userMenu="matMenu" xPosition="before" class="header_menu">
    
                    <button mat-menu-item [matMenuTriggerFor]="languageMenu" id="mob-languages-dropdown">
                        <div class="d-f-c">
                            <mat-icon>language</mat-icon>
                            <span style="background-color: transparent !important;">{{ activeLang }}</span> 
                        </div>
                    </button>            
                    <button mat-menu-item *ngIf="!!user.is_root" (click)="closeAllDialogs(); goToRoot()">
                        <mat-icon>security</mat-icon>
                        <span style="background-color: transparent !important;">{{ "Global settings" | translate }}</span> 
                    </button>            
                    <button mat-menu-item (click)="closeAllDialogs(); goToConnectedApps($event)">
                        <mat-icon>settings</mat-icon>
                        <span style="background-color: transparent !important;">{{ "Settings" | translate }}</span> 
                    </button>
                    <a mat-menu-item href="/set" [routerLink]="['/set']" routerLinkActive="router-link-active">
                        <mat-icon>add</mat-icon>
                        <span style="background-color: transparent !important;">{{ "Set up new Workspace" | translate }}</span> 
                    </a>     
                    <!-- <a href="/all-tasks?v2=1&order_by=updated_desc&count=1" mat-menu-item class="mat-menu-item d-f-c" (click)='closeAllDialogs(); goToAllCompany($event)'>
                        <mat-icon>assignment</mat-icon>
                        <span class="underline">
                            {{ "All tasks" | translate }}
                        </span>
                    </a> -->
                    <div class="mat-menu-item d-f-c">
                        <ng-container *ngIf="!!user?.image; else elseTemplate">
                            <div class="user_photo" [ngStyle]="{'background': 'url(' + user?.image + ') no-repeat center / cover'}"></div>
                        </ng-container>
                        <ng-template #elseTemplate>
                            <mat-icon>account_circle</mat-icon>
                        </ng-template>
                        <span>{{ user?.display_name }}</span>
                    </div>
                    <div class="mat-menu-item d-f-c">
                        <mat-icon>email</mat-icon>
                        <span>{{ user?.email }}</span>
                    </div>
                    
                    <div class="mat-menu-item d-f-c" (click)="getSubscription(user?.web_push_public_key)">
                        <mat-icon>notifications_active</mat-icon>
                        <span>{{ "Allow Notifications" | translate }}
                            <ng-container [ngSwitch]="webPushStage">
                                <ng-container *ngSwitchCase="1">
                                    ({{"In process." | translate}})
                                </ng-container>
                                <ng-container *ngSwitchCase="2">
                                    ({{"In process.." | translate}})
                                </ng-container>
                                <ng-container *ngSwitchCase="3">
                                    ({{"Success" | translate}})
                                </ng-container>
                                <ng-container *ngSwitchCase="4">
                                    ({{"Error" | translate}})
                                </ng-container>
                                <ng-container *ngSwitchDefault>
                                
                                </ng-container>
                            </ng-container>
                        </span>
                    </div>
                    <a mat-menu-item href="{{host}}/user/auth/?action=update&returnUrl={{_window.location.href}}" (click)='closeAllDialogs(); menuHref($event, "/user/auth/?action=update", true)'>
                        <mat-icon>manage_accounts</mat-icon>
                        <span class="underline">
                            {{ 'Edit_account' | translate }}
                        </span>
                    </a>
                    <a mat-menu-item href="{{host}}/user/auth/?returnUrl={{_window.location.origin}}" (click)='closeAllDialogs(); menuHref($event, "/user/auth/", false)'>
                        <mat-icon>people_alt</mat-icon>
                        <span class="underline">
                            {{ 'Change_user' | translate }}
                        </span>
                    </a>
                    <a mat-menu-item href="{{host}}/user/logout/?returnUrl={{_window.location.origin}}" (click)='closeAllDialogs(); menuHref($event, "/user/logout/", false)'>
                        <mat-icon>logout</mat-icon>
                        <span class="underline">
                            {{ 'Log_out' | translate }}
                        </span>
                    </a>
                    <div class="policy_link">
                        <a href="https://reports.re/task/qDvNw-PNvnSjO3zDk" target="_blank">{{"Privacy policy / Terms of Use" | translate}}</a>
                    </div>
                </mat-menu>
    
                <mat-menu #languageMenu="matMenu" xPosition="before" class="header_menu">
                    <button *ngFor="let lang of languages"
                    (click)="selectLang(lang)"
                    mat-menu-item
                    name="language" 
                    value="{{ lang }}" 
                    class="mob_lang_btns"
                    >
                        <div class="lang_photo" [ngStyle]="{'background': 'url(' + imgRoute + 'assets/img/lang/' + lang + '.svg' + ') no-repeat center / contain'}"></div>
                        <span>{{ lang }}</span>
                    </button>
                </mat-menu>
            </div>
        </div>
    </ng-template>
</header>

<div class="mobile_global_filter" *ngIf="!!company && !!company['id'] && is_mobile">
    <app-navigation-bar
    [company]="company"
    [company_id]="company.id"
    [is_mobile]="is_mobile"
    [activeLang]="activeLang"
    [user]="user"
    [barItems]="barItems"
    (GoToPage)="goToPage($event)"
    (RemoveItem)="removeItem($event)"
    (LinkToItem)="linkToItem($event)"
    (GoToItem)="goToItem($event)"></app-navigation-bar>
</div>

<div *ngIf="!!company && !company?.permissions.includes('owner') && is_mobile" class="mob_toggled_stats" [ngClass]="{'open': mobStatsIsOpen, 'app_alert': isApplicationAlert && appStatusId <= 2}">
    <ng-container *ngIf="+company?.rate_target != 0;">
        <div class="employee_stat">
            <div class='month_stat'>
                <span>{{ currMonthName }}</span> {{ 'plan' | translate}}
            </div>
            <div class="percent_stat">
                <span>{{ (+company?.total / +company?.rate_target)*100 | reportsNumber }}% {{ "Total" | translate }}</span>
                <span>{{ (+company?.approved / +company?.rate_target)*100 | reportsNumber }}% {{ "Approved" | translate }}</span>
                <span>{{ (+company?.pending / +company?.rate_target)*100 | reportsNumber }}% {{ "Pending" | translate }}</span>
            </div>
            <div class="pts_stat">
                {{ (+company?.total / +company?.rate_target)*100 | reportsNumber }}% {{ company?.rate_target | reportsNumber }} {{ 'points' | translate }}
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="+company?.salary != 0">
        <div class="employee_stat">
            <div class='month_stat'>
                <span>{{ currMonthName }}</span> {{ 'plan' | translate}}
            </div>
            <div class="percent_stat">
                <span>
                    {{ company?.summaryPrice | reportsNumber }}
                    <ng-container [ngSwitch]="company?.currency_id">
                        <ng-container *ngSwitchCase="'1'">&#36;</ng-container>
                        <ng-container *ngSwitchCase="'2'">&#8381;</ng-container>
                        <ng-container *ngSwitchCase="'3'">&#8364;</ng-container>
                        <ng-container *ngSwitchCase="'4'">&#8372;</ng-container>
                    </ng-container>
                    {{ "of" | translate }} {{ company?.salary | reportsNumber }} 
                    <ng-container [ngSwitch]="company?.currency_id">
                        <ng-container *ngSwitchCase="'1'">&#36;</ng-container>
                        <ng-container *ngSwitchCase="'2'">&#8381;</ng-container>
                        <ng-container *ngSwitchCase="'3'">&#8364;</ng-container>
                        <ng-container *ngSwitchCase="'4'">&#8372;</ng-container>
                    </ng-container>
                </span>
            </div>
            <div class="pts_stat">
                {{ (+company?.summaryPrice / +company?.salary)*100 | reportsNumber }}% 
            </div>
        </div>
    </ng-container>
    <button mat-icon-button class="close_mob_stats" (click)="closeAllDialogs($event); mobStatsIsOpen = false">
        <mat-icon>close</mat-icon>
    </button>
</div>

<div class="mob_block_interface_out" [ngClass]="{'show': isOpenWorkspaces}" *ngIf="is_mobile" (click)="openWorkspaces()">
    <div class="mob_block_interface_in" (click)="$event.stopPropagation()">

        <div class="mob_title" (click)="openWorkspaces()">
            <svg style="margin-right: 30px;" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7 12L6.11612 11.1161C5.62796 11.6043 5.62796 12.3957 6.11612 12.8839L7 12ZM16.8839 3.88388C17.372 3.39573 17.372 2.60427 16.8839 2.11612C16.3957 1.62796 15.6043 1.62796 15.1161 2.11612L16.8839 3.88388ZM15.1161 21.8839C15.6043 22.372 16.3957 22.372 16.8839 21.8839C17.372 21.3957 17.372 20.6043 16.8839 20.1161L15.1161 21.8839ZM7.88388 12.8839L16.8839 3.88388L15.1161 2.11612L6.11612 11.1161L7.88388 12.8839ZM6.11612 12.8839L15.1161 21.8839L16.8839 20.1161L7.88388 11.1161L6.11612 12.8839Z" fill="#232323"/>
            </svg>
                
            {{"Switch Workspace" | translate}}
        </div>
    
        <div class="mob_body">
            <div class="workspaces">
                <div class="d-f-c workspace_option" style="padding: 0 10px;" [ngClass]="{'active_company': comp?.id == company?.id}" *ngFor="let comp of companyes" (click)='closeAllDialogs($event); openWorkspaces(); goToCompany(comp?.id, $event)'>
                    <div class="d-f-c" style="width: calc(100% - 156px);">
                        <ng-container *ngIf="!!comp?.avatarFile?.thumbnail && !!host; else elseTemplatePartnerAvatar">
                            <img class="partner_avatar" (error)="onPartnerImgError($event)" [src]="host + comp?.avatarFile?.thumbnail" alt="">
                        </ng-container>
                        <ng-template #elseTemplatePartnerAvatar>
                            <img class="partner_avatar" (error)="onPartnerImgError($event)" src="{{ imgRoute }}assets/img/partner.png">
                        </ng-template>
                        <span class="underline text_one_line">
                            {{ comp?.name }}
                            <mat-icon class="verified_icon" style="color: #1877F2 !important;" *ngIf="comp?.is_verified" #tooltip="matTooltip"
                            matTooltip="{{companyVerificationText(comp)?.verification_text}}"
                            [matTooltipPosition]="'left'">verified</mat-icon>
                        </span>
                    </div>
                    <div class="workspace_pages_out">
                        <div class="workspace_pages">
                            <a [routerLink]="['/tasks']" [routerLinkActiveOptions]="{ exact: true }" [queryParams]="{company_id: comp?.id, order_by: 'updated_desc', count: '1'}" class="link-to-page" [class.page-active]="isLinkActive('/tasks') || isLinkActive('/reminder-tasks')" (click)='closeAllDialogs($event); openWorkspaces(); goToCompany(comp?.id, $event)'>
                                <svg style="width: 20px; height: 20px;" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16 12V16H2V12H16ZM17 10H1C0.45 10 0 10.45 0 11V17C0 17.55 0.45 18 1 18H17C17.55 18 18 17.55 18 17V11C18 10.45 17.55 10 17 10ZM4 15.5C3.18 15.5 2.5 14.83 2.5 14C2.5 13.17 3.18 12.5 4 12.5C4.82 12.5 5.5 13.17 5.5 14C5.5 14.83 4.83 15.5 4 15.5ZM16 2V6H2V2H16ZM17 0H1C0.45 0 0 0.45 0 1V7C0 7.55 0.45 8 1 8H17C17.55 8 18 7.55 18 7V1C18 0.45 17.55 0 17 0ZM4 5.5C3.18 5.5 2.5 4.83 2.5 4C2.5 3.17 3.18 2.5 4 2.5C4.82 2.5 5.5 3.18 5.5 4C5.5 4.82 4.83 5.5 4 5.5Z" fill="#686868"/>
                                </svg>
                            </a>
                            <a [routerLink]="['/jobs']" [routerLinkActiveOptions]="{ exact: true }" [queryParams]="{company_id: comp?.id, order_by: 'updated_desc', count: '1'}" class="link-to-page" [class.page-active]="isLinkActive('/jobs')" (click)='closeAllDialogs($event); openWorkspaces(); goToJobs(comp?.id, $event)'>
                                <svg style="width: 18px; height: 15px" width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 14.4H18V10.8H0V14.4ZM1.8 11.7H3.6V13.5H1.8V11.7ZM0 0V3.6H18V0H0ZM3.6 2.7H1.8V0.9H3.6V2.7ZM0 9H18V5.4H0V9ZM1.8 6.3H3.6V8.1H1.8V6.3Z" fill="#D7D7D7"/>
                                </svg>
                            </a>
                            <a [routerLink]="['/chats']" [routerLinkActiveOptions]="{ exact: true }" [queryParams]="{company_id: comp?.id, order_by: 'updated_desc', count: '1'}" class="link-to-page" [class.page-active]="isLinkActive('/chats')" (click)='closeAllDialogs($event); openWorkspaces(); goToChats($event, comp?.id)'>
                                <svg style="width: 18px; height: 18px;" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.6667 0.666664H2.33333C1.41667 0.666664 0.666666 1.41666 0.666666 2.33333V17.3333L4 14H15.6667C16.5833 14 17.3333 13.25 17.3333 12.3333V2.33333C17.3333 1.41666 16.5833 0.666664 15.6667 0.666664Z" fill="#D7D7D7"/>
                                </svg>
                            </a>
                            <a [ngClass]="{'hide_btn': !(comp | perm:'see_task_channels')}" [routerLink]="['/urls-start']" [routerLinkActiveOptions]="{ exact: true }" [queryParams]="{company_id: comp?.id, count: '1', order_by: 'content_published_at_desc'}" class="link-to-page" [class.page-active]="activeSections() == 'Posts' || activeSections() == 'Posts Start'" (click)='closeAllDialogs($event); openWorkspaces(); goToUrlsStart($event, comp?.id)'>
                                <svg style="width: 18px; height: 9px" width="18" height="9" viewBox="0 0 18 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13.5 0H9.9V1.8H13.5C14.985 1.8 16.2 3.015 16.2 4.5C16.2 5.985 14.985 7.2 13.5 7.2H9.9V9H13.5C15.984 9 18 6.984 18 4.5C18 2.016 15.984 0 13.5 0ZM8.1 7.2H4.5C3.015 7.2 1.8 5.985 1.8 4.5C1.8 3.015 3.015 1.8 4.5 1.8H8.1V0H4.5C2.016 0 0 2.016 0 4.5C0 6.984 2.016 9 4.5 9H8.1V7.2ZM5.4 3.6H12.6V5.4H5.4V3.6Z" fill="#D7D7D7"/>
                                </svg>
                            </a>
                            <a [ngClass]="{'hide_btn': !(comp | perm:'see_task_channels')}" [routerLink]="['/urls-dashboard']" [queryParams]="{company_id: comp?.id, count: '1'}" class="link-to-page" [class.page-active]="isLinkActive('/urls-dashboard')" (click)="closeAllDialogs($event); openWorkspaces(); goToUrlsDash($event, comp?.id)">   
                                <svg style="width: 18px; min-width: 18px; height: 18px; min-height: 18px;" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.1111 0V6.66667H20V0M11.1111 20H20V8.88889H11.1111M0 20H8.88889V13.3333H0M0 11.1111H8.88889V0H0V11.1111Z" fill="#D7D7D7"/>
                                </svg>
                            </a>
                            <a [ngClass]="{'hide_btn': !(comp | perm:'see_task_channels')}" [routerLink]="['/outlets-dashboard']" [queryParams]="{company_id: comp?.id, count: '1'}" class="link-to-page" [class.page-active]="isLinkActive('/outlets-dashboard')" (click)="closeAllDialogs($event); openWorkspaces(); goToOutletsDash($event, comp?.id)">
                                <svg style="width: 18px; min-width: 18px; height: 18px; min-height: 18px;" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.66667 0V16.6667H10V0H6.66667ZM10 2.22222L14.4444 16.6667L17.7778 15.5556L13.3333 1.11111L10 2.22222ZM2.22222 2.22222V16.6667H5.55556V2.22222H2.22222ZM0 17.7778V20H20V17.7778H0Z" fill="#D7D7D7"/>
                                </svg>
                            </a>
                            <a [routerLink]="['/board']" [routerLinkActiveOptions]="{ exact: true }" [queryParams]="{company_id: comp?.id, count: '1'}" class="link-to-page" [class.page-active]="activeSections() == 'Board by Date'" (click)='closeAllDialogs($event); openWorkspaces(); goToBoard($event, comp?.id)'>
                                <svg class="board" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16.6667 8.33333V6.16667C16.6667 5.0621 15.7712 4.16667 14.6667 4.16667H5.33334C4.22877 4.16667 3.33334 5.0621 3.33334 6.16667V8.33333M16.6667 8.33333V15.5C16.6667 16.6046 15.7712 17.5 14.6667 17.5H5.33333C4.22876 17.5 3.33334 16.6046 3.33334 15.5V8.33333M16.6667 8.33333H3.33334M6.66667 2.5V5.83333M13.3333 2.5V5.83333" stroke="#D7D7D7" stroke-width="2" stroke-linecap="round"/>
                                    <rect x="10.8333" y="11.6667" width="4.16667" height="4.16667" rx="1" fill="#D7D7D7"/>
                                </svg>
                            </a>
                            <a [routerLink]="['/board-list']" [routerLinkActiveOptions]="{ exact: true }" [queryParams]="{company_id: comp?.id, count: '1'}" class="link-to-page" [class.page-active]="activeSections() == 'Board by Order'" (click)='closeAllDialogs($event); openWorkspaces(); goToBoardList($event, comp?.id)'>
                                <svg class="board-list" width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.8 13.5H18V11.7H10.8V13.5ZM10.8 8.55H18V6.75H10.8V8.55ZM10.8 3.6H18V1.8H10.8V3.6ZM0 7.65C0 4.428 2.628 1.8 5.85 1.8H6.3V0L9 2.7L6.3 5.4V3.6H5.85C3.6 3.6 1.8 5.4 1.8 7.65C1.8 9.9 3.6 11.7 5.85 11.7H9V13.5H5.85C2.628 13.5 0 10.872 0 7.65Z" fill="#D7D7D7"/>
                                </svg>
                            </a>
                            <a [routerLink]="['/files']" [queryParams]="{company_id: comp?.id, count: '1'}" class="link-to-page" [class.page-active]="isLinkActive('/files')" (click)="closeAllDialogs($event); openWorkspaces(); goToMobPage('/files', comp?.id, $event)">
                                <svg class="file" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.75 21.75V8.25C3.75 7.14543 4.64543 6.25 5.75 6.25H12.0858C12.351 6.25 12.6054 6.35536 12.7929 6.54289L15 8.75H24.25C25.3546 8.75 26.25 9.64543 26.25 10.75V21.75C26.25 22.8546 25.3546 23.75 24.25 23.75H5.75C4.64543 23.75 3.75 22.8546 3.75 21.75Z" stroke="#D7D7D7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </a>
                            <a [routerLink]="['/projects']" [queryParams]="{company_id: comp?.id, count: '1'}" class="link-to-page" [class.page-active]="isLinkActive('/projects')" (click)="closeAllDialogs($event); openWorkspaces(); goToMobPage('/projects', comp?.id, $event)">
                                <mat-icon>movie_filter</mat-icon>
                            </a>
                            <a class="link-to-page" (click)="closeAllDialogs($event); openWorkspaces(); openMobShortcuts(comp, $event)">
                                <svg style="width: 18px; height: 18px;" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9 0L5.778 5.004L0 6.516L3.78 11.142L3.438 17.1L9 14.931L14.562 17.1L14.22 11.142L18 6.516L12.222 5.004L9 0ZM6.642 7.2C7.2 7.2 7.65 7.65 7.65 8.217C7.65 8.48434 7.5438 8.74073 7.35476 8.92976C7.16573 9.1188 6.90934 9.225 6.642 9.225C6.075 9.225 5.625 8.775 5.625 8.217C5.625 7.65 6.075 7.2 6.642 7.2ZM11.367 7.2C11.925 7.2 12.375 7.65 12.375 8.217C12.375 8.48434 12.2688 8.74073 12.0798 8.92976C11.8907 9.1188 11.6343 9.225 11.367 9.225C10.8 9.225 10.35 8.775 10.35 8.217C10.35 7.65 10.8 7.2 11.367 7.2ZM6.3 11.25H11.7C11.25 12.339 10.179 13.05 9 13.05C7.821 13.05 6.75 12.339 6.3 11.25Z" fill="#D7D7D7"></path></svg>
                            </a>
                            <a [routerLink]="['/settings']" [routerLinkActiveOptions]="{ exact: true }" [queryParams]="{company_id: comp?.id, count: '1', page: 'finances_dashboard'}" class="link-to-page" [class.page-active]="isLinkActive('/settings')" (click)='closeAllDialogs($event); openWorkspaces(); goToFinances($event, comp?.id)'>
                                <svg style="width: 18px; height: 18px" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_3672_54608ps)">
                                    <path d="M14.3546 9.70505C14.3846 9.48005 14.3996 9.24755 14.3996 9.00005C14.3996 8.76005 14.3846 8.52005 14.3471 8.29505L15.8696 7.11005C16.0046 7.00505 16.0421 6.80255 15.9596 6.65255L14.5196 4.16255C14.4296 3.99755 14.2421 3.94505 14.0771 3.99755L12.2846 4.71755C11.9096 4.43255 11.5121 4.19255 11.0696 4.01255L10.7996 2.10755C10.7696 1.92755 10.6196 1.80005 10.4396 1.80005H7.55959C7.37959 1.80005 7.23709 1.92755 7.20709 2.10755L6.93709 4.01255C6.49459 4.19255 6.08959 4.44005 5.72209 4.71755L3.92959 3.99755C3.76459 3.93755 3.57709 3.99755 3.48709 4.16255L2.05459 6.65255C1.96459 6.81005 1.99459 7.00505 2.14459 7.11005L3.66709 8.29505C3.62959 8.52005 3.59959 8.76755 3.59959 9.00005C3.59959 9.23255 3.61459 9.48005 3.65209 9.70505L2.12959 10.89C1.99459 10.995 1.95709 11.1975 2.03959 11.3475L3.47959 13.8375C3.56959 14.0026 3.75709 14.055 3.92209 14.0025L5.71459 13.2825C6.08959 13.5675 6.48709 13.8075 6.92959 13.9875L7.19959 15.8925C7.23709 16.0725 7.37959 16.2 7.55959 16.2H10.4396C10.6196 16.2 10.7696 16.0725 10.7921 15.8925L11.0621 13.9875C11.5046 13.8075 11.9096 13.5675 12.2771 13.2825L14.0696 14.0025C14.2346 14.0625 14.4221 14.0026 14.5121 13.8375L15.9521 11.3475C16.0421 11.1825 16.0046 10.995 15.8621 10.89L14.3546 9.70505ZM8.99959 11.7C7.51459 11.7 6.29959 10.485 6.29959 9.00005C6.29959 7.51505 7.51459 6.30005 8.99959 6.30005C10.4846 6.30005 11.6996 7.51505 11.6996 9.00005C11.6996 10.485 10.4846 11.7 8.99959 11.7Z" fill="#D7D7D7"/>
                                    </g>
                                    <defs>
                                    <clipPath id="clip0_3672_54608ps">
                                    <rect width="18" height="18" fill="white"/>
                                    </clipPath>
                                    </defs>
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="mob_block_interface_out" [ngClass]="{'show': isOpenSections}" (click)="openSections();" *ngIf="is_mobile">
    <div class="mob_block_interface_in" (click)="$event.stopPropagation()">

        <div class="mob_title" (click)="openSections()">
            <svg style="margin-right: 30px;" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7 12L6.11612 11.1161C5.62796 11.6043 5.62796 12.3957 6.11612 12.8839L7 12ZM16.8839 3.88388C17.372 3.39573 17.372 2.60427 16.8839 2.11612C16.3957 1.62796 15.6043 1.62796 15.1161 2.11612L16.8839 3.88388ZM15.1161 21.8839C15.6043 22.372 16.3957 22.372 16.8839 21.8839C17.372 21.3957 17.372 20.6043 16.8839 20.1161L15.1161 21.8839ZM7.88388 12.8839L16.8839 3.88388L15.1161 2.11612L6.11612 11.1161L7.88388 12.8839ZM6.11612 12.8839L15.1161 21.8839L16.8839 20.1161L7.88388 11.1161L6.11612 12.8839Z" fill="#232323"/>
            </svg>
                
            {{"Workspace sections" | translate}}
        </div>
    
        <div class="mob_body">
            <div *ngIf="!!company && !!company['id']" class="pages">
                <a [routerLink]="['/tasks']" [routerLinkActiveOptions]="{ exact: true }" [queryParams]="queryParams" class="link-to-page" [class.page-active]="isLinkActive('/tasks') || isLinkActive('/reminder-tasks')" (click)='closeAllDialogs($event); openSections(); goToMobPage("/tasks", $event)'>
                    <div class="chat_icon_wrp">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16 12V16H2V12H16ZM17 10H1C0.45 10 0 10.45 0 11V17C0 17.55 0.45 18 1 18H17C17.55 18 18 17.55 18 17V11C18 10.45 17.55 10 17 10ZM4 15.5C3.18 15.5 2.5 14.83 2.5 14C2.5 13.17 3.18 12.5 4 12.5C4.82 12.5 5.5 13.17 5.5 14C5.5 14.83 4.83 15.5 4 15.5ZM16 2V6H2V2H16ZM17 0H1C0.45 0 0 0.45 0 1V7C0 7.55 0.45 8 1 8H17C17.55 8 18 7.55 18 7V1C18 0.45 17.55 0 17 0ZM4 5.5C3.18 5.5 2.5 4.83 2.5 4C2.5 3.17 3.18 2.5 4 2.5C4.82 2.5 5.5 3.18 5.5 4C5.5 4.82 4.83 5.5 4 5.5Z" fill="#686868"/>
                        </svg>
                    </div>
                    <div class="page_name">
                        {{ "Cards" | translate }}
                    </div>
                </a>
                <a [routerLink]="['/jobs']" [routerLinkActiveOptions]="{ exact: true }" [queryParams]="queryParams" class="link-to-page" [class.page-active]="isLinkActive('/jobs')" (click)='closeAllDialogs($event); openSections(); goToMobPage("/jobs", $event)'>
                    <div class="chat_icon_wrp">
                        <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 16H20V12H0V16ZM2 13H4V15H2V13ZM0 0V4H20V0H0ZM4 3H2V1H4V3ZM0 10H20V6H0V10ZM2 7H4V9H2V7Z" fill="#686868"/>
                        </svg>
                    </div>
                    <div class="page_name">
                        {{ "Jobs" | translate }}
                    </div> 
                </a>
                <a [routerLink]="['/chats']" [routerLinkActiveOptions]="{ exact: true }" [queryParams]="queryParams" class="link-to-page" [class.page-active]="isLinkActive('/chats')" (click)='closeAllDialogs($event); openSections(); goToMobPage("/chats", $event)'>
                    <div class="chat_icon_wrp">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.6667 0.666664H2.33333C1.41667 0.666664 0.666666 1.41666 0.666666 2.33333V17.3333L4 14H15.6667C16.5833 14 17.3333 13.25 17.3333 12.3333V2.33333C17.3333 1.41666 16.5833 0.666664 15.6667 0.666664Z" fill="#686868"/>
                        </svg>   
                        <!-- <div class="chats_badgje" *ngIf="unReadChatsCount">
                            {{unReadChatsCount}}
                        </div> -->
                    </div>
                    <div class="page_name">
                        {{ "Chats" | translate }}
                    </div>
                </a>
                <mat-divider></mat-divider>
                <!-- <a *ngIf="company | perm:'see_task_channels'" [routerLink]="['/urls']" [routerLinkActiveOptions]="{ exact: true }" [queryParams]="queryParams" class="link-to-page" [class.page-active]="activeSections() == 'Posts' || activeSections() == 'Posts Start'" (click)='closeAllDialogs($event); openSections(); goToMobPage("/urls", $event)'>
                    <div class="chat_icon_wrp url_wrp">
                        <svg style="width: 26px; min-width: 26px; height: 26px; min-height: 26px; transform: none;" width="18" height="9" viewBox="0 0 18 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.5 0H9.9V1.8H13.5C14.985 1.8 16.2 3.015 16.2 4.5C16.2 5.985 14.985 7.2 13.5 7.2H9.9V9H13.5C15.984 9 18 6.984 18 4.5C18 2.016 15.984 0 13.5 0ZM8.1 7.2H4.5C3.015 7.2 1.8 5.985 1.8 4.5C1.8 3.015 3.015 1.8 4.5 1.8H8.1V0H4.5C2.016 0 0 2.016 0 4.5C0 6.984 2.016 9 4.5 9H8.1V7.2ZM5.4 3.6H12.6V5.4H5.4V3.6Z" fill="#686868"/>
                        </svg>
                    </div>
                    <div class="page_name">
                        {{ "Posts" | translate }}
                    </div>
                </a> -->
                

                <a *ngIf="company | perm:'see_task_channels'" style="position: relative;" [routerLink]="['/urls-start']" [routerLinkActiveOptions]="{ exact: true }" [queryParams]="queryParams" class="link-to-page" [class.page-active]="activeSections() == 'Posts' || activeSections() == 'Posts Start'" (click)='closeAllDialogs($event); openSections(); goToMobPage("/urls-start", $event)'>
                    <div class="chat_icon_wrp url_wrp">
                        <svg style="width: 26px; min-width: 26px; height: 26px; min-height: 26px; transform: none;" width="18" height="9" viewBox="0 0 18 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.5 0H9.9V1.8H13.5C14.985 1.8 16.2 3.015 16.2 4.5C16.2 5.985 14.985 7.2 13.5 7.2H9.9V9H13.5C15.984 9 18 6.984 18 4.5C18 2.016 15.984 0 13.5 0ZM8.1 7.2H4.5C3.015 7.2 1.8 5.985 1.8 4.5C1.8 3.015 3.015 1.8 4.5 1.8H8.1V0H4.5C2.016 0 0 2.016 0 4.5C0 6.984 2.016 9 4.5 9H8.1V7.2ZM5.4 3.6H12.6V5.4H5.4V3.6Z" fill="#686868"/>
                        </svg>
                    </div>
                    <div class="page_name">
                        {{ "Posts" | translate }}
                    </div>

                    
                    <a [routerLink]="['/urls']" [queryParams]="queryParams" class="project_page_link" [class.active]="activeSections() == 'Posts'" (click)="$event.stopPropagation(); $event.preventDefault(); closeAllDialogs($event); openSections(); goToMobPage('/urls', $event)">
                        <mat-icon>filter_list</mat-icon>
                    </a>
                </a>
                <a *ngIf="company | perm:'see_task_channels'" [routerLink]="['/urls-dashboard']" [routerLinkActiveOptions]="{ exact: true }" [queryParams]="queryParams" class="link-to-page" [class.page-active]="activeSections() == 'Post Dashboards'" (click)='closeAllDialogs($event); openSections(); goToMobPage("/urls-dashboard", $event)'>
                    <div class="chat_icon_wrp url_wrp">
                        <svg style="width: 26px; min-width: 26px; height: 26px; min-height: 26px;" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.1111 0V6.66667H20V0M11.1111 20H20V8.88889H11.1111M0 20H8.88889V13.3333H0M0 11.1111H8.88889V0H0V11.1111Z" fill="#686868"/>
                        </svg>
                    </div>
                    <div class="page_name">
                        {{ "Post Dashboards" | translate }}
                    </div>
                </a>
                <a *ngIf="company | perm:'see_task_channels'" [routerLink]="['/outlets-dashboard']" [routerLinkActiveOptions]="{ exact: true }" [queryParams]="queryParams" class="link-to-page" [class.page-active]="activeSections() == 'Outlet Dashboards'" (click)='closeAllDialogs($event); openSections(); goToMobPage("/outlets-dashboard", $event)'>
                    <div class="chat_icon_wrp url_wrp">
                        <svg style="width: 26px; min-width: 26px; height: 26px; min-height: 26px;" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.66667 0V16.6667H10V0H6.66667ZM10 2.22222L14.4444 16.6667L17.7778 15.5556L13.3333 1.11111L10 2.22222ZM2.22222 2.22222V16.6667H5.55556V2.22222H2.22222ZM0 17.7778V20H20V17.7778H0Z" fill="#686868"/>
                        </svg>
                    </div>
                    <div class="page_name">
                        {{ "Outlet Dashboards" | translate }}
                    </div>
                </a>
                <mat-divider></mat-divider>

                <a [routerLink]="['/board']" [routerLinkActiveOptions]="{ exact: true }" [class.page-active]="activeSections() == 'Board by Date'" [queryParams]="queryParams" class="link-to-page" (click)='closeAllDialogs($event); openSections(); goToMobPage("/board", $event)'>
                    <div class="chat_icon_wrp">
                        <svg style="width: 26px; height: 26px;" width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.77778 11.5556H3.55556V13.3333H9.77778M14.2222 16H1.77778V6.22222H14.2222M14.2222 1.77778H13.3333V0H11.5556V1.77778H4.44444V0H2.66667V1.77778H1.77778C0.791111 1.77778 0 2.57778 0 3.55556V16C0 16.4715 0.187301 16.9237 0.520699 17.2571C0.854097 17.5905 1.30628 17.7778 1.77778 17.7778H14.2222C14.6937 17.7778 15.1459 17.5905 15.4793 17.2571C15.8127 16.9237 16 16.4715 16 16V3.55556C16 3.08406 15.8127 2.63188 15.4793 2.29848C15.1459 1.96508 14.6937 1.77778 14.2222 1.77778ZM12.4444 8H3.55556V9.77778H12.4444V8Z" fill="#686868"/>
                        </svg>
                    </div>
                    <div class="page_name">
                        {{ "Board by Date" | translate }}
                    </div>
                </a>

                <a [routerLink]="['/board-list']" [routerLinkActiveOptions]="{ exact: true }" [class.page-active]="activeSections() == 'Board by Order'" [queryParams]="queryParams" class="link-to-page" (click)='closeAllDialogs($event); openSections(); goToMobPage("/board-list", $event)'>
                    <div class="chat_icon_wrp">
                        <svg style="width: 26px; height: 26px;" width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.8 13.5H18V11.7H10.8V13.5ZM10.8 8.55H18V6.75H10.8V8.55ZM10.8 3.6H18V1.8H10.8V3.6ZM0 7.65C0 4.428 2.628 1.8 5.85 1.8H6.3V0L9 2.7L6.3 5.4V3.6H5.85C3.6 3.6 1.8 5.4 1.8 7.65C1.8 9.9 3.6 11.7 5.85 11.7H9V13.5H5.85C2.628 13.5 0 10.872 0 7.65Z" fill="#686868"/>
                        </svg>
                    </div>
                    <div class="page_name">
                        {{ "Board by Order" | translate }}
                    </div>
                </a>


                <mat-divider></mat-divider>

                <a *ngIf="company | perm:'see_files_section'" style="position: relative;" [routerLink]="['/files']" [routerLinkActiveOptions]="{ exact: true }" [queryParams]="queryParams" class="link-to-page" [class.page-active]="isLinkActive('/files')" (click)='closeAllDialogs($event); openSections(); goToMobPage("/files", $event)'>
                    <div class="chat_icon_wrp">
                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.75 21.75V8.25C3.75 7.14543 4.64543 6.25 5.75 6.25H12.0858C12.351 6.25 12.6054 6.35536 12.7929 6.54289L15 8.75H24.25C25.3546 8.75 26.25 9.64543 26.25 10.75V21.75C26.25 22.8546 25.3546 23.75 24.25 23.75H5.75C4.64543 23.75 3.75 22.8546 3.75 21.75Z" stroke="#686868" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                    <div class="page_name">
                        {{ "Files" | translate }}
                    </div>

                    
                    <a [routerLink]="['/projects']" [queryParams]="queryParams" class="project_page_link" [class.active]="isLinkActive('/projects')" (click)="$event.stopPropagation(); $event.preventDefault(); closeAllDialogs($event); openSections(); goToMobPage('/projects', $event)">
                        <mat-icon>movie_filter</mat-icon>
                    </a>
                </a>

                <mat-divider></mat-divider>

                <a [routerLink]="['/settings']" [queryParams]="{company_id: comp?.id, page: 'finances_dashboard'}" class="link-to-page" [class.active]="isLinkActive('/settings')" (click)="closeAllDialogs($event); openSections(); goToFinances($event, company?.id)">
                    <div class="chat_icon_wrp">
                        <svg style="width: 30px; height: 30px" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_3672_54608p)">
                            <path d="M14.3546 9.70505C14.3846 9.48005 14.3996 9.24755 14.3996 9.00005C14.3996 8.76005 14.3846 8.52005 14.3471 8.29505L15.8696 7.11005C16.0046 7.00505 16.0421 6.80255 15.9596 6.65255L14.5196 4.16255C14.4296 3.99755 14.2421 3.94505 14.0771 3.99755L12.2846 4.71755C11.9096 4.43255 11.5121 4.19255 11.0696 4.01255L10.7996 2.10755C10.7696 1.92755 10.6196 1.80005 10.4396 1.80005H7.55959C7.37959 1.80005 7.23709 1.92755 7.20709 2.10755L6.93709 4.01255C6.49459 4.19255 6.08959 4.44005 5.72209 4.71755L3.92959 3.99755C3.76459 3.93755 3.57709 3.99755 3.48709 4.16255L2.05459 6.65255C1.96459 6.81005 1.99459 7.00505 2.14459 7.11005L3.66709 8.29505C3.62959 8.52005 3.59959 8.76755 3.59959 9.00005C3.59959 9.23255 3.61459 9.48005 3.65209 9.70505L2.12959 10.89C1.99459 10.995 1.95709 11.1975 2.03959 11.3475L3.47959 13.8375C3.56959 14.0026 3.75709 14.055 3.92209 14.0025L5.71459 13.2825C6.08959 13.5675 6.48709 13.8075 6.92959 13.9875L7.19959 15.8925C7.23709 16.0725 7.37959 16.2 7.55959 16.2H10.4396C10.6196 16.2 10.7696 16.0725 10.7921 15.8925L11.0621 13.9875C11.5046 13.8075 11.9096 13.5675 12.2771 13.2825L14.0696 14.0025C14.2346 14.0625 14.4221 14.0026 14.5121 13.8375L15.9521 11.3475C16.0421 11.1825 16.0046 10.995 15.8621 10.89L14.3546 9.70505ZM8.99959 11.7C7.51459 11.7 6.29959 10.485 6.29959 9.00005C6.29959 7.51505 7.51459 6.30005 8.99959 6.30005C10.4846 6.30005 11.6996 7.51505 11.6996 9.00005C11.6996 10.485 10.4846 11.7 8.99959 11.7Z" fill="#686868"/>
                            </g>
                            <defs>
                            <clipPath id="clip0_3672_54608p">
                            <rect width="18" height="18" fill="white"/>
                            </clipPath>
                            </defs>
                        </svg>
                    </div>
                    <div class="page_name">
                        {{ "Workspace Settings" | translate }}
                    </div>
                </a>
            </div>
        </div>
    </div>
</div>

<div class="mob_block_interface_out" [ngClass]="{'show': isOpenBurger.value}" (click)="openBurger()" *ngIf="is_mobile">
    <div class="mob_block_interface_in" (click)="$event.stopPropagation()">

        <div class="mob_block_interface_inside" [ngClass]="{'switchLeft': isOpenLanguages || isOpenSettings}">
            <a class="mob_user" mat-menu-item href="{{host}}/user/auth/?returnUrl={{_window.location.origin}}" (click)='closeAllDialogs(); menuHref($event, "/user/auth/", false)'>
                <div class="d-f-c">
                    <img [src]="user.image" alt="">
                    <div class="user_info">
                        <div class="name">
                            {{user.display_name}}
                        </div>
                        <div class="email">
                            {{user.email}}
                        </div>
                        <span *ngIf="!!workTimer">{{workTimer | timeFormat}}</span>
                    </div>
                </div>
                <svg style="transform: rotate(-180deg)" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7 12L6.11612 11.1161C5.62796 11.6043 5.62796 12.3957 6.11612 12.8839L7 12ZM16.8839 3.88388C17.372 3.39573 17.372 2.60427 16.8839 2.11612C16.3957 1.62796 15.6043 1.62796 15.1161 2.11612L16.8839 3.88388ZM15.1161 21.8839C15.6043 22.372 16.3957 22.372 16.8839 21.8839C17.372 21.3957 17.372 20.6043 16.8839 20.1161L15.1161 21.8839ZM7.88388 12.8839L16.8839 3.88388L15.1161 2.11612L6.11612 11.1161L7.88388 12.8839ZM6.11612 12.8839L15.1161 21.8839L16.8839 20.1161L7.88388 11.1161L6.11612 12.8839Z" fill="#232323"/>
                </svg>
            </a>
    
            <a class="mob_user_settings" [ngClass]="{'with_statuses': company && company.employees.length == 1}" href="{{host}}/user/auth/?action=update&returnUrl={{_window.location.href}}" (click)='closeAllDialogs(); menuHref($event, "/user/auth/?action=update", true)'>
                {{ "Account settngs" | translate}}

                <div class="status_btn" *ngIf="company && company.employees.length == 1">
                    <span style="color: #2ecc71;" (click)="setEmployeeStatus($event, 'active')" *ngIf="company.employees[0].status !== 'active'">{{ (company.employees[0].status !== 'break' ? "Start Working" : "Continue") | translate}}</span>
                    <span style="color: #f1c40f;" (click)="setEmployeeStatus($event, 'break')" *ngIf="company.employees[0].status === 'active'">{{"Break" | translate}}</span>
                    <span style="color: #c0392b;" (click)="setEmployeeStatus($event, 'inactive')" *ngIf="['active', 'break'].includes(company?.employees[0]?.status)">{{"Finish" | translate}}</span>
                </div>
            </a>
        
            <div class="mob_body">
                <div class="burger_menu">
                    <div class="burger_item" (click)="closeAllDialogs(); openLanguages()">
                        <div class="d-f-c">
                            <div class="chat_icon_wrp">
                               <img [src]="imgRoute + 'assets/img/lang/' + activeLang + '.svg'" alt="">
                            </div>
                            <span class="underline text_one_line">
                                {{ activeLang+'_full' | translate }}
                            </span>
                        </div>
                        <svg style="transform: rotate(-180deg)" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7 12L6.11612 11.1161C5.62796 11.6043 5.62796 12.3957 6.11612 12.8839L7 12ZM16.8839 3.88388C17.372 3.39573 17.372 2.60427 16.8839 2.11612C16.3957 1.62796 15.6043 1.62796 15.1161 2.11612L16.8839 3.88388ZM15.1161 21.8839C15.6043 22.372 16.3957 22.372 16.8839 21.8839C17.372 21.3957 17.372 20.6043 16.8839 20.1161L15.1161 21.8839ZM7.88388 12.8839L16.8839 3.88388L15.1161 2.11612L6.11612 11.1161L7.88388 12.8839ZM6.11612 12.8839L15.1161 21.8839L16.8839 20.1161L7.88388 11.1161L6.11612 12.8839Z" fill="#232323"/>
                        </svg>
                    </div>

                    <div class="burger_item" *ngIf="user?.web_push_public_key" (click)="getSubscription(user?.web_push_public_key)">
                        <div class="d-f-c">
                            <mat-icon style="color: #686868; font-size: 30px; line-height: 30px; width: 30px; height: 30px;">notifications_active</mat-icon>
                            <span class="underline">{{ "Allow Notifications" | translate }}</span> 
                                <ng-container [ngSwitch]="webPushStage">
                                    <ng-container *ngSwitchCase="1">
                                        ({{"In process." | translate}})
                                    </ng-container>
                                    <ng-container *ngSwitchCase="2">
                                        ({{"In process.." | translate}})
                                    </ng-container>
                                    <ng-container *ngSwitchCase="3">
                                        ({{"Success" | translate}})
                                    </ng-container>
                                    <ng-container *ngSwitchCase="4">
                                        ({{"Error" | translate}})
                                    </ng-container>
                                    <ng-container *ngSwitchDefault>
                                    
                                    </ng-container>
                                </ng-container>
                        </div>
                    </div>

                    <div class="burger_item" *ngIf="company" (click)="closeAllDialogs(); openSettings()">
                        <div class="d-f-c">
                            <div class="burger_item_icon">
                                <ng-container *ngIf="!!company?.avatarFile?.thumbnail && !!host; else elseTemplatePartnerAvatar">
                                    <img class="partner_avatar" (error)="onPartnerImgError($event)" [src]="host + company?.avatarFile?.thumbnail" alt="">
                                </ng-container>
                                <ng-template #elseTemplatePartnerAvatar>
                                    <img class="partner_avatar" (error)="onPartnerImgError($event)" src="{{ imgRoute }}assets/img/partner.png">
                                </ng-template>

                                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_2651_37461)">
                                    <path d="M23.9249 16.175C23.9749 15.8 23.9999 15.4125 23.9999 15C23.9999 14.6 23.9749 14.2 23.9124 13.825L26.4499 11.85C26.6749 11.675 26.7374 11.3375 26.5999 11.0875L24.1999 6.9375C24.0499 6.6625 23.7374 6.575 23.4624 6.6625L20.4749 7.8625C19.8499 7.3875 19.1874 6.9875 18.4499 6.6875L17.9999 3.5125C17.9499 3.2125 17.6999 3 17.3999 3H12.5999C12.2999 3 12.0624 3.2125 12.0124 3.5125L11.5624 6.6875C10.8249 6.9875 10.1499 7.4 9.53739 7.8625L6.54989 6.6625C6.27489 6.5625 5.96239 6.6625 5.81239 6.9375L3.42489 11.0875C3.27489 11.35 3.32489 11.675 3.57489 11.85L6.11239 13.825C6.04989 14.2 5.99989 14.6125 5.99989 15C5.99989 15.3875 6.02489 15.8 6.08739 16.175L3.54989 18.15C3.32489 18.325 3.26239 18.6625 3.39989 18.9125L5.79989 23.0625C5.94989 23.3375 6.26239 23.425 6.53739 23.3375L9.52489 22.1375C10.1499 22.6125 10.8124 23.0125 11.5499 23.3125L11.9999 26.4875C12.0624 26.7875 12.2999 27 12.5999 27H17.3999C17.6999 27 17.9499 26.7875 17.9874 26.4875L18.4374 23.3125C19.1749 23.0125 19.8499 22.6125 20.4624 22.1375L23.4499 23.3375C23.7249 23.4375 24.0374 23.3375 24.1874 23.0625L26.5874 18.9125C26.7374 18.6375 26.6749 18.325 26.4374 18.15L23.9249 16.175ZM14.9999 19.5C12.5249 19.5 10.4999 17.475 10.4999 15C10.4999 12.525 12.5249 10.5 14.9999 10.5C17.4749 10.5 19.4999 12.525 19.4999 15C19.4999 17.475 17.4749 19.5 14.9999 19.5Z" fill="#686868"/>
                                    </g>
                                    <defs>
                                    <clipPath id="clip0_2651_37461">
                                    <rect width="30" height="30" fill="white"/>
                                    </clipPath>
                                    </defs>
                                </svg>
                            </div>
                            <span class="underline text_one_line">
                                {{ "Workspace settings" | translate }}
                            </span>
                        </div>
                        <svg style="transform: rotate(-180deg)" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7 12L6.11612 11.1161C5.62796 11.6043 5.62796 12.3957 6.11612 12.8839L7 12ZM16.8839 3.88388C17.372 3.39573 17.372 2.60427 16.8839 2.11612C16.3957 1.62796 15.6043 1.62796 15.1161 2.11612L16.8839 3.88388ZM15.1161 21.8839C15.6043 22.372 16.3957 22.372 16.8839 21.8839C17.372 21.3957 17.372 20.6043 16.8839 20.1161L15.1161 21.8839ZM7.88388 12.8839L16.8839 3.88388L15.1161 2.11612L6.11612 11.1161L7.88388 12.8839ZM6.11612 12.8839L15.1161 21.8839L16.8839 20.1161L7.88388 11.1161L6.11612 12.8839Z" fill="#232323"/>
                        </svg>
                    </div>

                    <a class="burger_item" href="/set" [routerLink]="['/set']" routerLinkActive="router-link-active" (click)="openBurger();">
                        <div class="d-f-c">
                            <mat-icon style="color: #686868; font-size: 30px; line-height: 30px; width: 30px; height: 30px;">add</mat-icon>
                            <span class="underline">{{ "Set up new Workspace" | translate }}</span> 
                        </div>
                    </a>    

                    <div class="burger_item" *ngIf="!!company && !!company['id']" style="cursor: pointer;" (click)='$event.stopPropagation(); $event.preventDefault(); openBurger(); checkActions()'>
                        <div class="d-f-c">
                            <ng-container *ngIf="unReadNotsCount; else elseTemplateunReadNotsCountMobInt">
                                <mat-icon style="color: #686868; font-size: 30px; line-height: 30px; width: 30px; height: 30px;" [ngClass]="{bigSize: +unReadNotsCount > 999}" [matBadge]="unReadNotsCount" matBadgeColor="primary">notifications</mat-icon>
                            </ng-container>
                            <ng-template #elseTemplateunReadNotsCountMobInt>
                                <mat-icon style="color: #686868; font-size: 30px; line-height: 30px; width: 30px; height: 30px;">notifications</mat-icon>
                            </ng-template>
                            <span class="underline">{{"Activity" | translate}}</span>
                        </div>
                    </div>

                    <div class="burger_item upload_trigger_wrp_mob" *ngIf="!!user_id" style="cursor: pointer;" (click)='$event.stopPropagation(); $event.preventDefault(); openBurger(); toggleUploads()'>
                        <div class="d-f-c">
                            <div class="upload_trigger">
                                <mat-icon>
                                    <svg fill="none" height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg"><path clip-rule="evenodd" d="M3 17C3 16.4477 3.44772 16 4 16H16C16.5523 16 17 16.4477 17 17C17 17.5523 16.5523 18 16 18H4C3.44772 18 3 17.5523 3 17ZM6.29289 6.70711C5.90237 6.31658 5.90237 5.68342 6.29289 5.29289L9.29289 2.29289C9.48043 2.10536 9.73478 2 10 2C10.2652 2 10.5196 2.10536 10.7071 2.29289L13.7071 5.29289C14.0976 5.68342 14.0976 6.31658 13.7071 6.70711C13.3166 7.09763 12.6834 7.09763 12.2929 6.70711L11 5.41421L11 13C11 13.5523 10.5523 14 10 14C9.44771 14 9 13.5523 9 13L9 5.41421L7.70711 6.70711C7.31658 7.09763 6.68342 7.09763 6.29289 6.70711Z" fill="#686868" fill-rule="evenodd"/></svg>
                                </mat-icon>
                                <ng-container *ngIf="uploadProgress?.uploadedFiles">
                                    <span class="uploaded_files">{{ uploadProgress?.uploadedFiles }}</span>
                                    <span class="uploaded_size">{{ uploadProgress?.uploadedSize | filesize }}</span>
                                </ng-container>
                            </div>
                            <span class="underline">{{"Uploader" | translate}}</span>
                        </div>
                    </div>

                    <div class="burger_item" *ngIf="!!company && !!company['id'] && !company?.permissions.includes('owner')" style="cursor: pointer;" (click)='$event.stopPropagation(); $event.preventDefault(); openStats()'>
                        <div class="d-f-c">
                            <mat-icon style="color: #686868; font-size: 30px; line-height: 30px; width: 30px; height: 30px;">insert_chart_outlined</mat-icon>
                            <span class="underline">{{ "Employee statistics" | translate }}</span>
                        </div>
                    </div>

                    <div class="burger_item" (click)="closeAllDialogs(); openBurger(); goToConnectedApps($event)">
                        <div class="d-f-c">
                            <div class="chat_icon_wrp">
                                <svg style="width: 26px !important; height: 26px !important;" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 13C0 15.5712 0.762437 18.0846 2.19089 20.2224C3.61935 22.3602 5.64967 24.0265 8.02511 25.0104C10.4006 25.9944 13.0144 26.2518 15.5362 25.7502C18.0579 25.2486 20.3743 24.0105 22.1924 22.1924C24.0105 20.3743 25.2486 18.0579 25.7502 15.5362C26.2518 13.0144 25.9944 10.4006 25.0104 8.02511C24.0265 5.64967 22.3602 3.61935 20.2224 2.19089C18.0846 0.762437 15.5712 0 13 0C11.2928 0 9.60234 0.336255 8.02511 0.989566C6.44788 1.64288 5.01477 2.60045 3.80761 3.80761C1.36964 6.24558 0 9.55218 0 13ZM17.68 15.236C18.0177 14.5387 18.1954 13.7748 18.2 13C18.2 11.6209 17.6521 10.2982 16.677 9.32304C15.7018 8.34785 14.3791 7.8 13 7.8V10.4L8.944 6.5L13 2.6V5.2C15.0687 5.2 17.0526 6.02178 18.5154 7.48457C19.9782 8.94735 20.8 10.9313 20.8 13C20.8026 14.4696 20.383 15.909 19.591 17.147M5.2 13C5.19738 11.5304 5.61703 10.091 6.409 8.853L8.32 10.764C7.98225 11.4613 7.80459 12.2252 7.8 13C7.8 14.3791 8.34785 15.7018 9.32304 16.677C10.2982 17.6521 11.6209 18.2 13 18.2V15.6L16.9 19.5L13 23.4V20.8C10.9313 20.8 8.94735 19.9782 7.48457 18.5154C6.02178 17.0526 5.2 15.0687 5.2 13Z" fill="#686868"/>
                                </svg>
                            </div>
                            <span class="underline text_one_line">
                                {{ 'Connected apps' | translate }}
                            </span>
                        </div>
                    </div>

                    <div class="burger_item" (click)="closeAllDialogs(); openBurger(); goToRoot()">
                        <div class="d-f-c">
                            <mat-icon style="color: #686868; font-size: 30px; line-height: 30px; width: 30px; height: 30px;"><mat-icon style="color: #686868; font-size: 30px; line-height: 30px; width: 30px; height: 30px;">admin_panel_settings</mat-icon></mat-icon>
                            <span class="underline text_one_line">
                                {{ 'Global settngs' | translate }}
                            </span>
                        </div>
                    </div>

                    <div class="burger_item" (click)="closeAllDialogs(); openBurger(); goToUserSettings()">
                        <div class="d-f-c">
                            <div class="chat_icon_wrp">
                                <svg style="width: 30px !important; height: 30px !important;" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_2651_37461)">
                                    <path d="M23.9249 16.175C23.9749 15.8 23.9999 15.4125 23.9999 15C23.9999 14.6 23.9749 14.2 23.9124 13.825L26.4499 11.85C26.6749 11.675 26.7374 11.3375 26.5999 11.0875L24.1999 6.9375C24.0499 6.6625 23.7374 6.575 23.4624 6.6625L20.4749 7.8625C19.8499 7.3875 19.1874 6.9875 18.4499 6.6875L17.9999 3.5125C17.9499 3.2125 17.6999 3 17.3999 3H12.5999C12.2999 3 12.0624 3.2125 12.0124 3.5125L11.5624 6.6875C10.8249 6.9875 10.1499 7.4 9.53739 7.8625L6.54989 6.6625C6.27489 6.5625 5.96239 6.6625 5.81239 6.9375L3.42489 11.0875C3.27489 11.35 3.32489 11.675 3.57489 11.85L6.11239 13.825C6.04989 14.2 5.99989 14.6125 5.99989 15C5.99989 15.3875 6.02489 15.8 6.08739 16.175L3.54989 18.15C3.32489 18.325 3.26239 18.6625 3.39989 18.9125L5.79989 23.0625C5.94989 23.3375 6.26239 23.425 6.53739 23.3375L9.52489 22.1375C10.1499 22.6125 10.8124 23.0125 11.5499 23.3125L11.9999 26.4875C12.0624 26.7875 12.2999 27 12.5999 27H17.3999C17.6999 27 17.9499 26.7875 17.9874 26.4875L18.4374 23.3125C19.1749 23.0125 19.8499 22.6125 20.4624 22.1375L23.4499 23.3375C23.7249 23.4375 24.0374 23.3375 24.1874 23.0625L26.5874 18.9125C26.7374 18.6375 26.6749 18.325 26.4374 18.15L23.9249 16.175ZM14.9999 19.5C12.5249 19.5 10.4999 17.475 10.4999 15C10.4999 12.525 12.5249 10.5 14.9999 10.5C17.4749 10.5 19.4999 12.525 19.4999 15C19.4999 17.475 17.4749 19.5 14.9999 19.5Z" fill="#686868"/>
                                    </g>
                                    <defs>
                                    <clipPath id="clip0_2651_37461">
                                    <rect width="30" height="30" fill="white"/>
                                    </clipPath>
                                    </defs>
                                </svg>
                            </div>
                            <span class="underline text_one_line">
                                {{ 'General settngs' | translate }}
                            </span>
                        </div>
                    </div>

                    <a class="burger_item" href="{{host}}/user/logout/?returnUrl={{_window.location.origin}}" (click)='closeAllDialogs(); openBurger(); menuHref($event, "/user/logout/", false)'>
                        <div class="d-f-c">
                            <div class="chat_icon_wrp">
                                <svg style="width: 21.6px !important; height: 24px !important;"  width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.6 18V14.4H7.2V9.6H15.6V6L21.6 12L15.6 18ZM13.2 0C13.8365 0 14.447 0.252856 14.8971 0.702944C15.3471 1.15303 15.6 1.76348 15.6 2.4V4.8H13.2V2.4H2.4V21.6H13.2V19.2H15.6V21.6C15.6 22.2365 15.3471 22.847 14.8971 23.2971C14.447 23.7471 13.8365 24 13.2 24H2.4C1.76348 24 1.15303 23.7471 0.702944 23.2971C0.252856 22.847 0 22.2365 0 21.6V2.4C0 1.76348 0.252856 1.15303 0.702944 0.702944C1.15303 0.252856 1.76348 0 2.4 0H13.2Z" fill="#686868"/>
                                </svg>
                            </div>
                                
                            <span class="underline text_one_line">
                                {{ 'Log_out' | translate }}
                            </span>
                        </div>
                    </a>

                    <div class="policy_link" style="margin-bottom: 10px;">
                        <a href="https://reports.re/task/qDvNw-PNvnSjO3zDk" target="_blank">{{"Privacy policy / Terms of Use" | translate}}</a>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="mob_block_interface_language" [ngClass]="{'show': isOpenLanguages}">
            <div class="mob_title" (click)="openLanguages()">
                <svg style="margin-right: 30px;" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7 12L6.11612 11.1161C5.62796 11.6043 5.62796 12.3957 6.11612 12.8839L7 12ZM16.8839 3.88388C17.372 3.39573 17.372 2.60427 16.8839 2.11612C16.3957 1.62796 15.6043 1.62796 15.1161 2.11612L16.8839 3.88388ZM15.1161 21.8839C15.6043 22.372 16.3957 22.372 16.8839 21.8839C17.372 21.3957 17.372 20.6043 16.8839 20.1161L15.1161 21.8839ZM7.88388 12.8839L16.8839 3.88388L15.1161 2.11612L6.11612 11.1161L7.88388 12.8839ZM6.11612 12.8839L15.1161 21.8839L16.8839 20.1161L7.88388 11.1161L6.11612 12.8839Z" fill="#232323"/>
                </svg>
                    
                {{"Set language version" | translate}}
            </div>
            <div class="mob_body">
                <div class="burger_menu">
                    <div class="burger_item" [ngClass]="{'active': lang == activeLang}" (click)="closeAllDialogs(); openLanguages(); selectLang(lang)" *ngFor="let lang of languages">
                        <div class="d-f-c">
                            <div class="chat_icon_wrp">
                               <img [src]="imgRoute + 'assets/img/lang/' + lang + '.svg'" alt="">
                            </div>
                            <span class="underline text_one_line">
                                {{ lang+'_full' | translate }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="mob_block_interface_settings" [ngClass]="{'show': isOpenSettings, 'switchLeft': isOpenFinances || isOpenAutomation || isOpenMembers || isOpenCategorization}" >
            <div class="mob_title" (click)="openSettings()">
                <svg style="margin-right: 30px;" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7 12L6.11612 11.1161C5.62796 11.6043 5.62796 12.3957 6.11612 12.8839L7 12ZM16.8839 3.88388C17.372 3.39573 17.372 2.60427 16.8839 2.11612C16.3957 1.62796 15.6043 1.62796 15.1161 2.11612L16.8839 3.88388ZM15.1161 21.8839C15.6043 22.372 16.3957 22.372 16.8839 21.8839C17.372 21.3957 17.372 20.6043 16.8839 20.1161L15.1161 21.8839ZM7.88388 12.8839L16.8839 3.88388L15.1161 2.11612L6.11612 11.1161L7.88388 12.8839ZM6.11612 12.8839L15.1161 21.8839L16.8839 20.1161L7.88388 11.1161L6.11612 12.8839Z" fill="#232323"/>
                </svg>
                    
                {{ "Workspace settings" | translate }}
            </div>

            <div class="mob_body">
                <div class="burger_menu">

                    <a class="burger_settings_item" href="/settings?company_id={{company?.id}}&count=1&page=finances_dashboard" (click)='closeAllDialogs(); openBurger(); goToFinances($event, company?.id)'>
                        <div class="d-f-c">
                            <mat-icon>account_balance</mat-icon>
                            <span class="underline">
                                {{ "Finances" | translate }}
                            </span>
                        </div>

                        <div class="expand" (click)="$event.preventDefault(); $event.stopPropagation(); openFinances();">
                            <svg style="transform: rotate(-180deg)" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7 12L6.11612 11.1161C5.62796 11.6043 5.62796 12.3957 6.11612 12.8839L7 12ZM16.8839 3.88388C17.372 3.39573 17.372 2.60427 16.8839 2.11612C16.3957 1.62796 15.6043 1.62796 15.1161 2.11612L16.8839 3.88388ZM15.1161 21.8839C15.6043 22.372 16.3957 22.372 16.8839 21.8839C17.372 21.3957 17.372 20.6043 16.8839 20.1161L15.1161 21.8839ZM7.88388 12.8839L16.8839 3.88388L15.1161 2.11612L6.11612 11.1161L7.88388 12.8839ZM6.11612 12.8839L15.1161 21.8839L16.8839 20.1161L7.88388 11.1161L6.11612 12.8839Z" fill="#232323"/>
                            </svg>
                        </div>
                    </a>
                    
                    <a class="burger_settings_item" *ngIf="company?.permissions.includes('owner') || company?.permissions.includes('admin') || company?.permissions.includes('manager')" href="/settings?company_id={{company?.id}}&page=automation" (click)='closeAllDialogs(); openBurger(); goToScenarios($event, company?.id)'>
                        <div class="d-f-c">
                            <mat-icon>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9 5H13V7H9V9H11C11.5304 9 12.0391 9.21071 12.4142 9.58579C12.7893 9.96086 13 10.4696 13 11V13C13 13.5304 12.7893 14.0391 12.4142 14.4142C12.0391 14.7893 11.5304 15 11 15H7V13H11V11H9C8.46957 11 7.96086 10.7893 7.58579 10.4142C7.21071 10.0391 7 9.53043 7 9V7C7 6.46957 7.21071 5.96086 7.58579 5.58579C7.96086 5.21071 8.46957 5 9 5ZM10 0C11.3132 0 12.6136 0.258658 13.8268 0.761205C15.0401 1.26375 16.1425 2.00035 17.0711 2.92893C17.9997 3.85752 18.7362 4.95991 19.2388 6.17317C19.7413 7.38642 20 8.68678 20 10C20 12.6522 18.9464 15.1957 17.0711 17.0711C15.1957 18.9464 12.6522 20 10 20C8.68678 20 7.38642 19.7413 6.17317 19.2388C4.95991 18.7362 3.85752 17.9997 2.92893 17.0711C1.05357 15.1957 0 12.6522 0 10C0 7.34784 1.05357 4.8043 2.92893 2.92893C4.8043 1.05357 7.34784 0 10 0ZM10 2C7.87827 2 5.84344 2.84285 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.84344 17.1571 7.87827 18 10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 7.87827 17.1571 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2Z" fill="black"/>
                                </svg>
                            </mat-icon>
                            <span class="underline">
                                {{ "Automation" | translate }}
                            </span>
                        </div>
                        <div class="expand" (click)="$event.preventDefault(); $event.stopPropagation(); openAutomation();">
                            <svg style="transform: rotate(-180deg)" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7 12L6.11612 11.1161C5.62796 11.6043 5.62796 12.3957 6.11612 12.8839L7 12ZM16.8839 3.88388C17.372 3.39573 17.372 2.60427 16.8839 2.11612C16.3957 1.62796 15.6043 1.62796 15.1161 2.11612L16.8839 3.88388ZM15.1161 21.8839C15.6043 22.372 16.3957 22.372 16.8839 21.8839C17.372 21.3957 17.372 20.6043 16.8839 20.1161L15.1161 21.8839ZM7.88388 12.8839L16.8839 3.88388L15.1161 2.11612L6.11612 11.1161L7.88388 12.8839ZM6.11612 12.8839L15.1161 21.8839L16.8839 20.1161L7.88388 11.1161L6.11612 12.8839Z" fill="#232323"/>
                            </svg>
                        </div>
                    </a>

                    <a class="burger_settings_item" href="/settings?company_id={{company?.id}}&page=workspace_members" (click)='closeAllDialogs(); openBurger(); goToMembers($event, company?.id)'>
                        <div class="d-f-c">
                            <mat-icon>badge</mat-icon>
                            <span class="underline">
                                {{ 'Workspace Members' | translate }}
                            </span>
                        </div>
                        <div class="expand" (click)="$event.preventDefault(); $event.stopPropagation(); openMembers();">
                            <svg style="transform: rotate(-180deg)" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7 12L6.11612 11.1161C5.62796 11.6043 5.62796 12.3957 6.11612 12.8839L7 12ZM16.8839 3.88388C17.372 3.39573 17.372 2.60427 16.8839 2.11612C16.3957 1.62796 15.6043 1.62796 15.1161 2.11612L16.8839 3.88388ZM15.1161 21.8839C15.6043 22.372 16.3957 22.372 16.8839 21.8839C17.372 21.3957 17.372 20.6043 16.8839 20.1161L15.1161 21.8839ZM7.88388 12.8839L16.8839 3.88388L15.1161 2.11612L6.11612 11.1161L7.88388 12.8839ZM6.11612 12.8839L15.1161 21.8839L16.8839 20.1161L7.88388 11.1161L6.11612 12.8839Z" fill="#232323"/>
                            </svg>
                        </div>
                    </a>

                    <a class="burger_settings_item" href="/settings?company_id={{company?.id}}&page=categorization" (click)='closeAllDialogs(); openBurger(); goToCategorization($event, company?.id)'>
                        <div class="d-f-c">
                            <mat-icon>category</mat-icon>
                            <span class="underline">
                                {{ 'Categorization' | translate }}
                            </span>
                        </div>

                        <div class="expand" (click)="$event.preventDefault(); $event.stopPropagation(); openCategorization();">
                            <svg style="transform: rotate(-180deg)" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7 12L6.11612 11.1161C5.62796 11.6043 5.62796 12.3957 6.11612 12.8839L7 12ZM16.8839 3.88388C17.372 3.39573 17.372 2.60427 16.8839 2.11612C16.3957 1.62796 15.6043 1.62796 15.1161 2.11612L16.8839 3.88388ZM15.1161 21.8839C15.6043 22.372 16.3957 22.372 16.8839 21.8839C17.372 21.3957 17.372 20.6043 16.8839 20.1161L15.1161 21.8839ZM7.88388 12.8839L16.8839 3.88388L15.1161 2.11612L6.11612 11.1161L7.88388 12.8839ZM6.11612 12.8839L15.1161 21.8839L16.8839 20.1161L7.88388 11.1161L6.11612 12.8839Z" fill="#232323"/>
                            </svg>
                        </div>
                    </a>
                    
                    <a class="burger_settings_item" href="/settings?company_id={{company?.id}}&page=profiles" (click)='closeAllDialogs(); openBurger(); goToProfiles($event, company?.id)'>
                        <div class="d-f-c">
                            <mat-icon>public</mat-icon>
                            <span class="underline">
                                {{ 'Outlets' | translate }}
                            </span>
                        </div>
                    </a>
                </div>
            </div>
        </div>

        <div class="mob_block_interface_lower" [ngClass]="{'show': isOpenFinances}" >
            <div class="mob_title" (click)="openFinances()">
                <svg style="margin-right: 30px;" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7 12L6.11612 11.1161C5.62796 11.6043 5.62796 12.3957 6.11612 12.8839L7 12ZM16.8839 3.88388C17.372 3.39573 17.372 2.60427 16.8839 2.11612C16.3957 1.62796 15.6043 1.62796 15.1161 2.11612L16.8839 3.88388ZM15.1161 21.8839C15.6043 22.372 16.3957 22.372 16.8839 21.8839C17.372 21.3957 17.372 20.6043 16.8839 20.1161L15.1161 21.8839ZM7.88388 12.8839L16.8839 3.88388L15.1161 2.11612L6.11612 11.1161L7.88388 12.8839ZM6.11612 12.8839L15.1161 21.8839L16.8839 20.1161L7.88388 11.1161L6.11612 12.8839Z" fill="#232323"/>
                </svg>
                    
                {{ "Finances" | translate }}
            </div>

            <div class="mob_body">
                <div class="burger_menu">
                    <a class="burger_settings_item" *ngIf="company?.permissions.includes('owner') || company?.permissions.includes('admin')" href="/settings?company_id={{company?.id}}&page=finances&tab=current_activity" (click)='closeAllDialogs(); openBurger(); goToCurrentActivity($event, company?.id)'>
                        <div class="d-f-c">
                            <mat-icon>analytics</mat-icon>
                            <span class="underline">{{ "Current Activity" | translate }}</span> 
                        </div>
                    </a>
                    <a class="burger_settings_item" href="/settings?company_id={{company?.id}}&page=finances&tab=statements" (click)='closeAllDialogs(); openBurger(); goToStatements($event, company?.id)'>
                        <div class="d-f-c">
                            <mat-icon>show_chart</mat-icon>
                            <span class="underline">{{ "Statements" | translate }}</span> 
                        </div>
                    </a>
                    <a class="burger_settings_item" href="/settings?company_id={{company?.id}}&page=finances&tab=payouts" (click)='closeAllDialogs(); openBurger(); goToPayouts($event, company?.id)'>
                        <div class="d-f-c">
                            <mat-icon>payments</mat-icon>
                            <span class="underline">{{ "Payouts" | translate }}</span> 
                        </div>
                    </a>
                </div>
            </div>
        </div>

        <div class="mob_block_interface_lower" [ngClass]="{'show': isOpenAutomation}" >
            <div class="mob_title" (click)="openAutomation()">
                <svg style="margin-right: 30px;" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7 12L6.11612 11.1161C5.62796 11.6043 5.62796 12.3957 6.11612 12.8839L7 12ZM16.8839 3.88388C17.372 3.39573 17.372 2.60427 16.8839 2.11612C16.3957 1.62796 15.6043 1.62796 15.1161 2.11612L16.8839 3.88388ZM15.1161 21.8839C15.6043 22.372 16.3957 22.372 16.8839 21.8839C17.372 21.3957 17.372 20.6043 16.8839 20.1161L15.1161 21.8839ZM7.88388 12.8839L16.8839 3.88388L15.1161 2.11612L6.11612 11.1161L7.88388 12.8839ZM6.11612 12.8839L15.1161 21.8839L16.8839 20.1161L7.88388 11.1161L6.11612 12.8839Z" fill="#232323"/>
                </svg>
                    
                {{ "Automation" | translate }}
            </div>

            <div class="mob_body">
                <div class="burger_menu">
                    <a class="burger_settings_item" *ngIf="company?.permissions.includes('owner') || company?.permissions.includes('admin') || company?.permissions.includes('manager')" href="/settings?company_id={{company?.id}}&page=checklists" (click)='closeAllDialogs(); openBurger(); goToChecklists($event, company?.id)'>
                        <div class="d-f-c">
                            <mat-icon>checklist</mat-icon>
                            <span class="underline">{{ "Checklists" | translate }}</span>
                        </div>
                    </a>
                </div>
            </div>
        </div>

        <div class="mob_block_interface_lower" [ngClass]="{'show': isOpenMembers}" >
            <div class="mob_title" (click)="openMembers()">
                <svg style="margin-right: 30px;" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7 12L6.11612 11.1161C5.62796 11.6043 5.62796 12.3957 6.11612 12.8839L7 12ZM16.8839 3.88388C17.372 3.39573 17.372 2.60427 16.8839 2.11612C16.3957 1.62796 15.6043 1.62796 15.1161 2.11612L16.8839 3.88388ZM15.1161 21.8839C15.6043 22.372 16.3957 22.372 16.8839 21.8839C17.372 21.3957 17.372 20.6043 16.8839 20.1161L15.1161 21.8839ZM7.88388 12.8839L16.8839 3.88388L15.1161 2.11612L6.11612 11.1161L7.88388 12.8839ZM6.11612 12.8839L15.1161 21.8839L16.8839 20.1161L7.88388 11.1161L6.11612 12.8839Z" fill="#232323"/>
                </svg>
                    
                {{ "Workspace Members" | translate }}
            </div>

            <div class="mob_body">
                <div class="burger_menu">                    
                    <a class="burger_settings_item" href="/settings?company_id={{company?.id}}&page=individuals" (click)='closeAllDialogs(); openBurger(); goToEmployees($event, company?.id)'>
                        <div class="d-f-c">
                            <mat-icon>badge</mat-icon>
                            <span class="underline">{{ 'Individuals' | translate }}</span>
                        </div>
                    </a>

                    <a class="burger_settings_item" href="/settings?company_id={{company?.id}}&page=partner_workspaces" title="{{ 'Partner Workspaces' | translate }}" (click)="closeAllDialogs(); openBurger(); goToPartners($event, company?.id)">
                        <div class="d-f-c">
                            <mat-icon>supervised_user_circle</mat-icon>
                            <span class="underline">{{ "Partner Workspaces" | translate }}</span>
                        </div>
                    </a>
                    
                    <ng-container *ngIf="company?.permissions.includes('owner') || company?.permissions.includes('admin') || company?.permissions.includes('hr'); else templateNameApplicationMobInterfaceV">
                        <a class="burger_settings_item" href="/settings?company_id={{company?.id}}&page=questionnaires" (click)='closeAllDialogs(); openBurger(); goToQuestionnaires($event, company?.id)'>
                            <div class="d-f-c">
                                <mat-icon>feed</mat-icon>
                                <span class="underline">{{ "Onboarding forms" | translate }}</span>
                            </div>
                        </a>
                        <a *ngIf="company?.permissions.includes('admin') || company?.permissions.includes('hr')" class="burger_settings_item" href="/settings?company_id={{company?.id}}&page=form" (click)='closeAllDialogs(); openBurger(); goToForm($event, company?.id)'>
                            <div class="d-f-c">
                                <mat-icon>feed</mat-icon>
                                <span class="underline">{{ "Onboarding forms" | translate }}</span>
                            </div>
                        </a>
                    </ng-container>
                    <ng-template #templateNameApplicationMobInterfaceV>
                        <a class="burger_settings_item" href="/settings?company_id={{company?.id}}&page=form" (click)='closeAllDialogs(); openBurger(); goToForm($event, company?.id)'>
                            <div class="d-f-c">
                                <mat-icon>feed</mat-icon>
                                <span class="underline">{{ "Onboarding forms" | translate }}</span>
                            </div>
                        </a>
                    </ng-template>

                    <a class="burger_settings_item" href="/settings?company_id={{company?.id}}&page=users" title="{{ 'Users' | translate }}" (click)='closeAllDialogs(); openBurger(); goToUsers($event, company?.id)'>
                        <div class="d-f-c">
                            <mat-icon>people</mat-icon>
                            <span class="underline">{{ "Users" | translate }}</span>
                        </div>
                    </a>
                </div>
            </div>
        </div>
        <div class="mob_block_interface_lower" [ngClass]="{'show': isOpenCategorization}" >
            <div class="mob_title" (click)="openCategorization()">
                <svg style="margin-right: 30px;" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7 12L6.11612 11.1161C5.62796 11.6043 5.62796 12.3957 6.11612 12.8839L7 12ZM16.8839 3.88388C17.372 3.39573 17.372 2.60427 16.8839 2.11612C16.3957 1.62796 15.6043 1.62796 15.1161 2.11612L16.8839 3.88388ZM15.1161 21.8839C15.6043 22.372 16.3957 22.372 16.8839 21.8839C17.372 21.3957 17.372 20.6043 16.8839 20.1161L15.1161 21.8839ZM7.88388 12.8839L16.8839 3.88388L15.1161 2.11612L6.11612 11.1161L7.88388 12.8839ZM6.11612 12.8839L15.1161 21.8839L16.8839 20.1161L7.88388 11.1161L6.11612 12.8839Z" fill="#232323"/>
                </svg>
                    
                {{ "Categorization" | translate }}
            </div>

            <div class="mob_body">
                <div class="burger_menu">
                    <a class="burger_settings_item" href="/settings?company_id={{company?.id}}&page=structure" (click)='closeAllDialogs(burgNav); openBurger(); goToStructure($event, company?.id)'>
                        <div class="d-f-c">
                            <mat-icon>engineering</mat-icon>
                            <span class="underline">{{ 'Company structure' | translate }}</span>
                        </div>
                    </a>
                    <a class="burger_settings_item" href="/settings?company_id={{company?.id}}&page=task_types" (click)='closeAllDialogs(); openBurger(); goToTaskTypes($event, company?.id)'>
                        <div class="d-f-c">
                            <mat-icon>checklist_rtl</mat-icon>
                            <span class="underline">{{ 'Card Types' | translate }}</span>
                        </div>
                    </a>
                    <a class="burger_settings_item" href="/settings?company_id={{company?.id}}&page=job_types" (click)='closeAllDialogs(); openBurger(); goToJobTypes($event, company?.id)'>
                        <div class="d-f-c">
                            <mat-icon>checklist_rtl</mat-icon>
                            <span class="underline">{{ 'Job types' | translate }}</span>
                        </div>
                    </a>
                    <a class="burger_settings_item" href="/settings?company_id={{company?.id}}&page=company" (click)='closeAllDialogs(); openBurger(); goToGroups($event, company?.id)'>
                        <div class="d-f-c">
                            <mat-icon>groups</mat-icon>
                            <span class="underline">{{ 'Groups' | translate }}</span>
                        </div>
                    </a>
                    <a class="burger_settings_item" href="/settings?company_id={{company?.id}}&page=parameters" *ngIf="company?.permissions.includes('owner') || company?.permissions.includes('admin') || company?.permissions.includes('manager')" (click)='closeAllDialogs(); openBurger(); goToParameters($event, company?.id)'>
                        <div class="d-f-c">
                            <mat-icon>settings_suggest</mat-icon>
                            <span class="underline">{{ "Tags" | translate }}</span>
                        </div>
                    </a>
                </div>
            </div>
        </div>

    </div>
</div>

<div class="push-modal push-modal--show" *ngIf="showPushModal && user?.id && !is_mobile">
    <div class="push-modal__wrapper">
        <button mat-icon-button class="push_close" (click)="hidePushModal()">
            <mat-icon>close</mat-icon>
        </button>
        <div class="push-modal__container-icon">
            <mat-icon>notifications</mat-icon>
        </div>
        <div class="push-modal__container-title">
            <span class="push-modal__title">{{ "Subscribe to Push notifications" | translate }}</span>
        </div>
        <div class="push-modal__container-description">
            <p class="push-modal__description">{{ "To receive only important alerts, like new messages in chats." | translate }}</p>
        </div>
        <div class="push-modal__buttons-group">
            <button class="tp-button tp-button--secondary" (click)="dontShowPushModal()">
                <span class="tp-button__text">{{ "Cancel" | translate }}</span>
            </button>
            <button class="tp-button tp-button--primary" (click)="is_mobile && user?.external_id == 8166 ? getMobSubscription(user?.web_push_public_key) : getSubscription(user?.web_push_public_key)">
                <span class="tp-button__text">{{ "Subscribe" | translate }}</span>
            </button> 
        </div>
    </div>
</div>

<app-file-interface [company]="company" [activeLang]="activeLang" [uploadsIsOpen]="uploadsIsOpen" (Open)="openUploads()" (Close)="closeUploads()" [ngClass]="{'opened': uploadsIsOpen}"></app-file-interface>

<ng-template #contextMenu let-ctxCompany="company" let-ctxPage="page">
    <section class="card-menu">
        <ng-container *ngIf="ctxCompany && ctxCompany.selEmployees; else elseTemplateUserIsGettingData">
            <mat-form-field appearance="legacy">
                <mat-label>{{"User" | translate}}</mat-label>
                <mat-select [disableOptionCentering]="true" selectDir [sdType]="'SOME_MENU_CST_CLASS'" (selectionChange)="goToFilterPage($event, ctxCompany, ctxPage)">
                    <mat-option>
                        <ngx-mat-select-search 
                        placeholderLabel="{{ 'Search' | translate }}" 
                        noEntriesFoundLabel="{{ 'no matching executor found' | translate }}"
                        [formControl]="ctxCompany.employeesControl"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option [value]="item.id" *ngFor="let item of ctxCompany.employees$ | async">
                        <app-user [class]="'user_div'" [employee]="item" [imgRoute]="imgRoute" [host]="host" [company]="ctxCompany"></app-user>
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div class="menu_divider"></div>
        </ng-container>
        <ng-template #elseTemplateUserIsGettingData>
            <div class="d-f-c" style="height: 60px;">
                <mat-spinner class="spin_16 mr-1"></mat-spinner>
                <span>{{"Getting users" | translate}}...</span>
            </div>
        </ng-template>
    </section>
</ng-template>