

<h2 mat-dialog-title class="dialog_title">
    <span>{{ "Card automations" | translate }}</span>
    <button mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h2>

<mat-dialog-content class="mat-typography">
    <div class="container">
        <mat-list role="list" class="job_types" [appDeviceClass]="'custom-scroll custom-scroll-t'">
            <mat-list-item *ngFor="let auto of automation; let i = index;" role="listitem" class="company_item">
                <div class="type_name">
                    <ng-container *ngIf="auto?.template; else elseTemplate">
                        {{ auto?.template.name }}
                    </ng-container>
                    <ng-template #elseTemplate>
                        Template with id: {{ auto.template_id }}
                    </ng-template>
                    
                    <span *ngIf="auto.is_active" style="color: green; font-weight: 500;" >({{ "Active" | translate }})</span>
                    <span *ngIf="auto?.executed_at > 0">{{auto.executed_at | reportsDate:data.activeLang}}</span>
                </div>

                <div class="d-f-c ml-1">
                    <div class="company_btns">
                        <mat-icon style="color: green;" *ngIf="!auto.template_id" (click)="editAutomation(auto)">edit</mat-icon>
                        <mat-icon style="color: red;" *ngIf="data?.company?.permissions.includes('owner') || data?.company?.permissions.includes('admin')" (click)="deleteAutomation(auto, i)">delete</mat-icon>
                    </div>
                </div>
            </mat-list-item>
          </mat-list>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-raised-button (click)="close()">
        {{ "Close" | translate }}
    </button>
    <button class="ml-1 d-f-btn" (click)="addAutomation()" mat-raised-button color="primary">
        {{ "Add" | translate }}
    </button>
</mat-dialog-actions>