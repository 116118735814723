import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { MY_FORMATS } from 'src/app/components/atTasksDialog/task-profile-add/task-profile-add.component';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { FileService } from 'src/app/shared/services/rest/file.service';

@Component({
  selector: 'app-create-archive',
  templateUrl: './create-archive.component.html',
  styleUrls: ['./create-archive.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ]
})

export class CreateArchiveComponent extends BaseClass implements OnInit, OnDestroy {
  public form: FormGroup;
  public minDate: any = moment().add(1, 'd').startOf('d');
  public maxDate: any =  moment().add(300, 'd');

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private fileService: FileService,
    private languageService: LanguageService,
    public dialogRef: MatDialogRef<CreateArchiveComponent>,
    private _adapter: DateAdapter<any>,
    public layoutService: LayoutService
  ) { super() }

  ngOnInit(): void {
    console.log("CreateArchiveComponent", this.data)
    this.getLangData();
    this.form = this.fb.group({
      delete_at: [this.minDate, Validators.required]
    })
    this.form.valueChanges.subscribe(res => {
      console.log(res);
    })
    console.log(this.form.value)
  }

  changeDate(e) {
    console.log("changeDate", e);
    if (!!e.target.value && moment(e.target.value)['_d'] != 'Invalid Date' && moment(e.target.value).isSameOrAfter(this.minDate) && moment(e.target.value).isSameOrBefore(this.maxDate)) {
      this.form.patchValue({
        delete_at: moment(e.target.value)
      })
    }
  }

  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData().subscribe(resp => {
        this._adapter.setLocale(resp.active);
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false});
  }

  submitForm() {
    let postData:any = {
      company_id: this.data.target_company_id,
      is_deferred: 1,
      file_id: [this.data.folder.id],
    }
    postData.delete_at = this.form.value.delete_at.endOf('d').format("X")

    this.attachSubscriptions(
      this.fileService.createArchive(postData, this.data.company_id).subscribe(resp => {
        this.dialogRef.close({event: "create", data: resp})
      })
    )
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
