<div class="container cases_page">
    <div class="dashboard_wrp">
        <div class="dashboard">
            <div class="dash_item" (mouseenter)="!is_mobile ? onMouseEnter($event, 0) : null" (click)="!hoveredStatus[0].hover ? onMouseEnter($event, 0) : null;" [ngClass]="{'hovered': hoveredStatus[0].hover}">
                <div class="page_title">
                    <div class="page_name">
                        {{ "Chats" | translate }} (old version 2.101)
                    </div>
                    <div class="chat_icon_wrp">
                        <mat-icon>chat</mat-icon>
                    </div>
                </div>

                <a (click)='goToChat()' class="link-to-page page_btn hover_viss">
                    {{ "Open" | translate }}
                </a>
            </div>

            <div class="dash_item" (mouseenter)="!is_mobile ? onMouseEnter($event, 1) : null;" (click)="!hoveredStatus[1].hover ? onMouseEnter($event, 1) : null;" [ngClass]="{'hovered': hoveredStatus[1].hover}">
                <div class="page_title">
                    <div class="page_name">
                        {{ "Stats" | translate }}
                    </div>
                    <div class="chat_icon_wrp">
                        <mat-icon>insights</mat-icon>
                    </div>
                </div>
                <a class="link-to-page page_btn hover_viss" href="/settings?company_id={{company?.id}}&page=stats" (click)='goToCompanyStats($event, company?.id)'>
                    {{ "To page" | translate }}
                </a>
            </div>
            
        </div>
    </div>
</div>

