<div class="cases_page">
    <div class="finances_wrp container" [appDeviceClass]="'custom-scroll custom-scroll-t'">
        <ng-container [ngSwitch]="filter.get('tab').value">
            <ng-container *ngSwitchCase="'current_activity'">

                <div class="container">
                    <div class="filter_out" *ngIf="!is_mobile">  
                        <div class="filter_values">
                            <mat-chip-list class="chips_wrp without_ordered start_page_chips">
                        
                            <mat-chip class="user_chip" *ngIf="filter.get('employee_id').value" (removed)="removeValue('operation_employee_id')" >
                                <span class="mr-1" style="font-weight: 500;">{{ "Executor" | translate }}</span>
                                <app-user [class]="'user_div'" [employee]="getEmployee(filter.get('employee_id').value)"></app-user>
                                <mat-icon class="remove_chip_btn" matChipRemove *ngIf="!withoutFilter">cancel</mat-icon>
                            </mat-chip>
                        
            
                            <mat-icon class="remove_all_chips_btn" (click)="removeValue('all')" *ngIf="!withoutFilter && (filter.get('employee_id').value)">cancel</mat-icon>
                            </mat-chip-list>
            
                    
                            <div class="stats_wrp">
            
              
                            </div>
                        </div>
                    </div>
                </div>

                <!-- <form *ngIf="company?.permissions?.includes('owner') || company?.permissions?.includes('admin') || company?.permissions?.includes('manager')" [formGroup]="filter" class="filter_wrp d-f-c" style="justify-content: space-between;">
                  
                    <div class="d-f-c">
                      <mat-form-field appearance="standard" class="full_width_form" >
                          <mat-label>{{ "Choose an employee" | translate }}</mat-label>
                          <mat-select formControlName="employee_id" [disableOptionCentering]="true" selectDir (selectionChange)="selectionChange($event)">
                            <mat-option>
                            <ngx-mat-select-search 
                                placeholderLabel="{{ 'Search' | translate }}" 
                                noEntriesFoundLabel="{{ 'no matching member found' | translate }}"
                                [formControl]="execControl"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let employee of employees$ | async" [value]="employee.id">
                                <app-user [class]="'user_div'" [employee]="employee"></app-user>
                          </mat-option>
                          </mat-select>
                      </mat-form-field>
                    </div>
                </form> -->

                <div class="activity_head" *ngFor="let summ of openSummary?.summaries; let i = index;">
                    <div>
                        <div class="big_numb">
                            {{getCurrency(summ?.currency_id)?.short_name}} {{summ.balance_pending | int }}
                        </div>
                        <div class="small_numb" *ngIf="+summ?.balance_pending != +(summ?.balance_pending | int)">
                            {{ ((mod(+summ?.balance_pending) - mod(+(summ?.balance_pending | int))) | reportsNumber).toString()|slice:1 }}
                        </div>
                        <div class="small_numb" *ngIf="+summ?.balance_pending == +(summ?.balance_pending | int)">
                            {{ '.00' }}
                        </div>
                        <span class="fin_title">{{ "Total Current Balance" | translate }}</span>
                    </div>
                    <div>
                        <div class="big_numb">
                            {{getCurrency(summ?.currency_id)?.short_name}} {{summ.pending | int }}
                        </div>
                        <div class="small_numb" *ngIf="+summ?.pending != +(summ?.pending | int)">
                            {{ ((mod(+summ?.pending) - mod(+(summ?.pending | int))) | reportsNumber).toString()|slice:1 }}
                        </div>
                        <div class="small_numb" *ngIf="+summ?.pending == +(summ?.pending | int)">
                            {{ '.00' }}
                        </div>
                        <span class="fin_title">{{ "Pending Current Balance" | translate }}</span>
                    </div>
                    <div>
                        <div class="big_numb green">
                            {{getCurrency(summ?.currency_id)?.short_name}} {{summ.balance  | int }}
                        </div>
                        <div class="small_numb green" *ngIf="+summ?.balance != +(summ?.balance  | int)">
                            {{ ((mod(+summ?.balance) - mod(+(summ?.balance | int))) | reportsNumber).toString()|slice:1 }}
                        </div>
                        <div class="small_numb green" *ngIf="+summ?.balance == +(summ?.balance  | int)">
                            {{ '.00' }}
                        </div>
                        <section>
                            <div class="hr"></div>

                            <div class="fin_row small">
                                <div class="price">
                                    <div class="big_price">
                                        {{getCurrency(summ?.currency_id)?.short_name}} {{summ.earned | int }}
                                    </div>
                                    <div class="small_price" *ngIf="+summ?.earned != +(summ?.earned | int)">
                                        {{ ((mod(+summ?.earned ) - mod(+(summ?.earned  | int))) | reportsNumber).toString()|slice:1 }}
                                    </div>
                                    <div class="small_price" *ngIf="+summ?.earned == +(summ?.earned | int)">
                                        {{ '.00' }}
                                    </div>
                                </div>
                                <div class="fin_text">
                                    {{ "Earnings" | translate}}
                                </div>
                            </div>

                            <div class="fin_row small">
                                <div class="price">
                                    <div class="big_price">
                                        {{getCurrency(summ?.currency_id)?.short_name}} {{summ.returned | int }}
                                    </div>
                                    <div class="small_price" *ngIf="+summ?.returned != +(summ?.returned | int)">
                                        {{ ((mod(+summ?.returned ) - mod(+(summ?.returned  | int))) | reportsNumber).toString()|slice:1 }}
                                    </div>
                                    <div class="small_price" *ngIf="+summ?.returned == +(summ?.returned | int)">
                                        {{ '.00' }}
                                    </div>
                                </div>
                                <div class="fin_text">
                                    {{ "Returns" | translate}}
                                </div>
                            </div>

                            <div class="fin_row">
                                <div class="price">
                                    <div class="big_price">
                                        {{getCurrency(summ?.currency_id)?.short_name}} {{summ.check | int }}
                                    </div>
                                    <div class="small_price" *ngIf="+summ?.check != +(summ?.check | int)">
                                        {{ ((mod(+summ?.check ) - mod(+(summ?.check  | int))) | reportsNumber).toString()|slice:1 }}
                                    </div>
                                    <div class="small_price" *ngIf="+summ?.check == +(summ?.check | int)">
                                        {{ '.00' }}
                                    </div>
                                </div>
                                <div class="fin_text">
                                    {{ "Earnings Balance" | translate }}
                                </div>
                            </div>

                            <div class="hr"></div>

                            <div class="fin_row" *ngIf="!!openSummary?.last_statement && !!openSummary?.last_statement.length > 0">
                                <div class="price">
                                    <span class="big_price" *ngIf="openSummary?.last_statement?.summaries[i]?.balance">
                                        {{ getCurrency(openSummary?.last_statement?.summaries[i]?.currency_id)?.short_name }}{{ +openSummary?.last_statement?.summaries[i]?.balance | int }}
                                    </span>
                                    <div class="small_price" *ngIf="+openSummary?.last_statement?.summaries[i]?.balance != +(openSummary?.last_statement?.summaries[i]?.balance  | int)">
                                        {{ ((mod(+openSummary?.last_statement?.summaries[i]?.balance) - mod(+(openSummary?.last_statement?.summaries[i]?.balance | int))) | reportsNumber).toString()|slice:1 }}
                                    </div>
                                    <div class="small_price" *ngIf="+openSummary?.last_statement?.summaries[i]?.balance == +(openSummary?.last_statement?.summaries[i]?.balance  | int)">
                                        {{ '.00' }}
                                    </div>
                                </div>
                                <div class="fin_text">
                                    {{openSummary?.last_statement?.time_start_utc * 1000 | date:'MMM':"+0000":activeLang}} {{ "Statament Balance" | translate }}
                                </div>
                            </div>

                            <div class="hr"></div>

                            <div class="fin_row">
                                <div class="price">
                                    <div class="big_price">
                                        {{getCurrency(summ?.currency_id)?.short_name}} {{summ.paid | int }}
                                    </div>
                                    <div class="small_price" *ngIf="+summ?.paid != +(summ?.paid | int)">
                                        {{ ((mod(+summ?.paid ) - mod(+(summ?.paid  | int))) | reportsNumber).toString()|slice:1 }}
                                    </div>
                                    <div class="small_price" *ngIf="+summ?.paid == +(summ?.paid | int)">
                                        {{ '.00' }}
                                    </div>
                                </div>
                                <div class="fin_text">
                                    {{ "Payouts" | translate }}
                                </div>
                            </div>

                            <div class="hr"></div>
                        </section>
                        <span class="fin_title green">{{ "Current Balance" | translate }}</span>
                    </div>
                </div>
            
                <!-- All transactions + -->
                <div class="state_table_wrp">
                    <div class="state_table_title" (click)="log()">{{ "All transactions" | translate }}</div>
                    <div class="state_table_out">
                        <table class="state_table list_table">
                            <thead>
                                <tr>
                                    <td>
                                        <div>{{ "Date posted" | translate }}</div>
                                        <div class="small-l">{{ "Date of operation" | translate }}</div>
                                    </td>
                                    <td>
                                        <div>{{ "Item" | translate }}</div>
                                        <div class="small-l">{{ "Type" | translate }}</div>
                                    </td>
                                    <td>
                                        <div>{{ "Job type" | translate }}</div>
                                        <div>{{ "Paymant method" | translate }}</div>
                                    </td>
                                    <td>
                                        <div>{{ "cID" | translate }}</div>
                                        <div class="small-l">{{ "wID" | translate }}</div>
                                    </td>
                                    <td>
                                        <div>{{ "Card title and group" | translate }}</div>
                                        <div>{{ "Payout description" | translate }}</div>
                                    </td>
                                    <td>
                                        <div>{{ "Amount" | translate }}</div>
                                        <div class="small-l">&nbsp;</div>
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let earning of allTransactions">
                                    <ng-container *ngIf="!!earning?.task; else elseTemplate">
                                        <td>
                                            <div>{{ earning?.created_at * 1000 | date:'MMM d, y':"":activeLang }}</div>
                                            <div class="small-m" *ngIf="!!earning?.completed_at">{{ earning?.completed_at * 1000 | date:'MMM d, y':"":activeLang }}</div>
                                        </td>
                                        <td style="cursor: pointer;" (click)="!earning?.discussion_id ? openJob(earning?.task_id, earning?.task_operation_id) : openChat(earning?.discussion_id)">
                                            <ng-container *ngIf="!!earning?.discussion_id; else elseTemplateType">
                                                {{ "Chat" | translate }}
                                            </ng-container>
                                            <ng-template #elseTemplateType>
                                                {{ "Work" | translate }}
                                            </ng-template>
                                            <div class="small-m">{{ earning?.type }}</div>
                                            <div class="small-m">ID: {{earning.discussion_id ? earning.discussion_id : earning.task_operation_id}}</div>
                                        </td>
                                        <td>
                                            <div>{{getOperationName(earning?.taskOperation?.operation_id)}}</div>
                                            <div class="small-m big">{{earning?.taskOperation?.name}}</div>
                                            <div class="small-m">{{earning?.taskOperation?.private_comment}}</div>
                                        </td>
                                        <td style="cursor: pointer" (click)="openTask(earning?.task)">
                                            <div>{{ earning?.task?.custom_id }}</div>
                                            <div class="small-m">{{ earning?.task?.internal_id }}</div>
                                        </td>
                                        <td>
                                            <div class="">{{earning?.task?.name}}</div>
                                            <div class="small-m big">{{getGroupById(earning?.task?.group_id)?.name}}</div>
                                        </td>
                                        <td>
                                            <span style="margin-right: 5px;" *ngIf="+earning?.rate != 0">pts{{ +earning?.rate | reportsNumber }}</span>
                                            <span *ngIf="+earning?.price != 0">{{ earning?.currency?.short_name }}{{ +earning?.price | reportsNumber }}</span>
                                        </td>
                                    </ng-container>
                                    <ng-template #elseTemplate>
                                        <td>
                                            <div>{{ earning?.created_at * 1000 | date:'MMM d, y':"":activeLang }}</div>
                                            <div class="small-m" *ngIf="!!earning?.real_paid_at">{{ earning?.real_paid_at * 1000 | date:'MMM d, y':"":activeLang }}</div>
                                        </td>
                                        <td>
                                            {{ "Payout" | translate }}
                                        </td>
                                        <td>
                                            {{earning?.paymentSystem?.name}}
                                        </td>
                                        <td></td>
                                        <td>
                                            <div>{{earning?.comment}}</div>
                                        </td>
                                        <td>
                                            <span *ngIf="+earning?.amount != 0">{{ earning?.currency?.short_name }}{{ +earning?.amount | reportsNumber }}</span>
                                        </td>
                                    </ng-template>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colspan="3">{{ "Total Current Earnings" | translate }}</td>
                                    <td colspan="3">
                                        <div class="d-f-c" *ngIf="checkIfHasSumm(totalAllTransactions, 'amount')">
                                            <div>
                                                Price:&nbsp;
                                            </div>
                                            <div>
                                                <ng-container *ngFor="let total of totalAllTransactions">
                                                    <span *ngIf="total?.amount != 0 && total?.currency_id != 'rate'">
                                                        {{ total?.currency?.short_name }}{{ +total?.amount | reportsNumber }}
                                                    </span>
                                                </ng-container>
                                            </div>
                                        </div>
                                        <div class="d-f-c" *ngIf="checkIfHasSumm(totalAllTransactions, 'rate')">
                                            <div>
                                                Points:&nbsp;
                                            </div>
                                            <div>
                                                <ng-container *ngFor="let total of totalAllTransactions" >
                                                    <span *ngIf="total?.amount != 0 && total?.currency_id == 'rate'">
                                                        pts. {{ +total?.amount | reportsNumber }}
                                                    </span>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>


                        <ng-container *ngIf="!!statements && statements.length">
                            <ng-container *ngFor="let statement of statements; let ind = index;">

                                <div class="box" *ngIf="statement.status == 'suggest'" (click)="getTargetAllTransactionsFinances(statement, ind)">
                                    <div class="btn btn-one" matRipple>
                                      <span>{{ "See transactions" | translate }} {{statement.time_start_utc * 1000 | date:'MMM y':"+0000":activeLang}}</span>
                                    </div>
                                </div>


                                <div class="statement_title" *ngIf="statement?.earnings">
                                    {{ statement.time_start_utc * 1000 | date:'MMM y':"+0000":activeLang }} {{ "Transactions" | translate }} 
                                </div>

                                <table class="state_table list_table" *ngIf="statement?.earnings">
                                    <thead>
                                        <tr>
                                            <td>
                                                <div>{{ "Date posted" | translate }}</div>
                                                <div class="small-l">{{ "Date of operation" | translate }}</div>
                                            </td>
                                            <td>
                                                <div>{{ "Item" | translate }}</div>
                                                <div class="small-l">{{ "Type" | translate }}</div>
                                            </td>
                                            <td>
                                                <div>{{ "Job type" | translate }}</div>
                                                <div>{{ "Paymant method" | translate }}</div>
                                            </td>
                                            <td>
                                                <div>{{ "cID" | translate }}</div>
                                                <div class="small-l">{{ "wID" | translate }}</div>
                                            </td>
                                            <td>
                                                <div>{{ "Card title and group" | translate }}</div>
                                                <div>{{ "Payout description" | translate }}</div>
                                            </td>
                                            <td>
                                                <div>{{ "Amount" | translate }}</div>
                                                <div class="small-l">&nbsp;</div>
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let earning of statement?.earnings">
                                            <ng-container *ngIf="!!earning?.task; else elseTemplate">
                                                <td>
                                                    <div>{{ earning?.created_at * 1000 | date:'MMM d, y':"":activeLang }}</div>
                                                    <div class="small-m" *ngIf="!!earning?.completed_at">{{ earning?.completed_at * 1000 | date:'MMM d, y':"":activeLang }}</div>
                                                </td>
                                                <td style="cursor: pointer;" (click)="!earning?.discussion_id ? openJob(earning?.task_id, earning?.task_operation_id) : openChat(earning?.discussion_id)">
                                                    <ng-container *ngIf="!!earning?.discussion_id; else elseTemplateType">
                                                        {{ "Chat" | translate }}
                                                    </ng-container>
                                                    <ng-template #elseTemplateType>
                                                        {{ "Work" | translate }}
                                                    </ng-template>
                                                    <div class="small-m">{{ earning?.type }}</div>
                                                    <div class="small-m">ID: {{earning.discussion_id ? earning.discussion_id : earning.task_operation_id}}</div>
                                                </td>
                                                <td>
                                                    <div>{{getOperationName(earning?.taskOperation?.operation_id)}}</div>
                                                    <div class="small-m big">{{earning?.taskOperation?.name}}</div>
                                                    <div class="small-m">{{earning?.taskOperation?.private_comment}}</div>
                                                </td>
                                                <td style="cursor: pointer" (click)="openTask(earning?.task)">
                                                    <div>{{ earning?.task?.custom_id }}</div>
                                                    <div class="small-m">{{ earning?.task?.internal_id }}</div>
                                                </td>
                                                <td>
                                                    <div class="">{{earning?.task?.name}}</div>
                                                    <div class="small-m big">{{getGroupById(earning?.task?.group_id)?.name}}</div>
                                                </td>
                                                <td>
                                                    <span style="margin-right: 5px;" *ngIf="+earning?.rate != 0">pts{{ +earning?.rate | reportsNumber }}</span>
                                                    <span *ngIf="+earning?.price != 0">{{ earning?.currency?.short_name }}{{ +earning?.price | reportsNumber }}</span>
                                                </td>
                                            </ng-container>
                                            <ng-template #elseTemplate>
                                                <td>
                                                    <div>{{ earning?.created_at * 1000 | date:'MMM d, y':"":activeLang }}</div>
                                                    <div class="small-m" *ngIf="!!earning?.real_paid_at">{{ earning?.real_paid_at * 1000 | date:'MMM d, y':"":activeLang }}</div>
                                                </td>
                                                <td>
                                                    {{ "Payout" | translate }}
                                                </td>
                                                <td>
                                                    {{earning?.paymentSystem?.name}}
                                                </td>
                                                <td></td>
                                                <td>
                                                    <div>{{earning?.comment}}</div>
                                                </td>
                                                <td>
                                                    <span *ngIf="+earning?.amount != 0">{{ earning?.currency?.short_name }}{{ +earning?.amount | reportsNumber }}</span>
                                                </td>
                                            </ng-template>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td colspan="3">{{ "Total Earnings from" | translate }} {{statement.time_start_utc * 1000 | date:'MMM y':"+0000":activeLang}}</td>
                                            <td colspan="3">
                                                <div class="d-f-c" *ngIf="checkIfHasSumm(statement?.totalAllTransactions, 'amount')">
                                                    <div>
                                                        Price:&nbsp;
                                                    </div>
                                                    <div>
                                                        <ng-container *ngFor="let total of statement?.totalAllTransactions">
                                                            <span *ngIf="total?.amount != 0 && total?.currency_id != 'rate'">
                                                                {{ total?.currency?.short_name }}{{ +total?.amount | reportsNumber }}
                                                            </span>
                                                        </ng-container>
                                                    </div>
                                                </div>
                                                <div class="d-f-c" *ngIf="checkIfHasSumm(statement?.totalAllTransactions, 'rate')">
                                                    <div>
                                                        Points:&nbsp;
                                                    </div>
                                                    <div>
                                                        <ng-container *ngFor="let total of statement?.totalAllTransactions" >
                                                            <span *ngIf="total?.amount != 0 && total?.currency_id == 'rate'">
                                                                pts. {{ +total?.amount | reportsNumber }}
                                                            </span>
                                                        </ng-container>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </ng-container>
                            
                        </ng-container>
                        
                    </div>
                </div>

                <!-- Pending earnings + -->
                <div class="state_table_wrp" *ngIf="!!pendingEarnings?.length">
                    <div class="state_table_title">{{ "Pending earnings" | translate }}</div>
                    <div class="state_table_out">
                        <table class="state_table list_table">
                            <thead>
                                <tr>
                                    <td>
                                        <div>{{ "Date posted" | translate }}</div>
                                        <div class="small-l">{{ "Date of operation" | translate }}</div>
                                    </td>
                                    <td>
                                        <div>{{ "Item" | translate }}</div>
                                        <div class="small-l">{{ "Type" | translate }}</div>
                                    </td>
                                    <td>
                                        <div>{{ "Job type" | translate }}</div>
                                        <div>{{ "Paymant method" | translate }}</div>
                                    </td>
                                    <td>
                                        <div>{{ "cID" | translate }}</div>
                                        <div class="small-l">{{ "wID" | translate }}</div>
                                    </td>
                                    <td>
                                        <div>{{ "Card title and group" | translate }}</div>
                                        <div>{{ "Payout description" | translate }}</div>
                                    </td>
                                    <td>
                                        <div>{{ "Amount" | translate }}</div>
                                        <div class="small-l">&nbsp;</div>
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngIf="!pendingEarnings.length"></tr>
                                <tr *ngFor="let earning of pendingEarnings">
                                    <td>
                                        <div>{{ earning?.created_at * 1000 | date:'MMM d, y':"":activeLang }}</div>
                                        <div class="small-m" *ngIf="!!earning?.completed_at">{{ earning?.completed_at * 1000 | date:'MMM d, y':"":activeLang }}</div>
                                    </td>
                      
                                    <td style="cursor: pointer;" (click)="!earning?.discussion_id ? openJob(earning?.task_id, earning?.task_operation_id) : openChat(earning?.discussion_id)">
                                        <ng-container *ngIf="!!earning?.discussion_id; else elseTemplateType">
                                            {{ "Chat" | translate }}
                                        </ng-container>
                                        <ng-template #elseTemplateType>
                                            {{ "Work" | translate }}
                                        </ng-template>
                                        <div class="small-m">ID: {{earning.discussion_id ? earning.discussion_id : earning.task_operation_id}}</div>
                                    </td>
                                    <td>
                                        <div>
                                            {{getOperationName(earning?.taskOperation?.operation_id)}}
                                        </div>
                                        <div class="small-m big">{{earning?.taskOperation?.name}}</div>
                                        <div class="small-m">{{earning?.taskOperation?.private_comment}}</div>
                                    </td>
                                    <td style="cursor: pointer" (click)="openTask(earning?.task)">
                                        <div>{{ earning?.task?.custom_id }}</div>
                                        <div class="small-m">{{ earning?.task?.internal_id }}</div>
                                    </td>
                                    <td>
                                        <div class="">{{earning?.task?.name}}</div>
                                        <div class="small-m big">{{getGroupById(earning?.task?.group_id)?.name}}</div>
                                    </td>
                                    <td>
                                        <span style="margin-right: 5px;" *ngIf="+earning?.rate != 0">pts{{ +earning?.rate | reportsNumber }}</span>
                                        <span *ngIf="+earning?.price != 0">{{ getCurrency(earning?.price_currency_id).short_name }}{{ +earning?.price | reportsNumber }}</span>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td>{{ "Total Earnings" | translate }}</td>
                                    <td colspan="2"></td>
                                    <td colspan="3">
                                        <span *ngFor="let total of totalPendingEarnings">
                                            <ng-container *ngIf="total?.currency_id != 'rate' || total?.amount != 0">
                                                {{ total?.currency?.short_name }}{{ +total?.amount | reportsNumber }}
                                            </ng-container>
                                        </span>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngSwitchCase="'statements'">
                <app-statements [useOut]="true" [withoutFilter]="!company?.permissions?.includes('owner') && !company?.permissions?.includes('admin') && !company?.permissions?.includes('manager')"></app-statements>
            </ng-container>

            <ng-container *ngSwitchCase="'payouts'">
                <app-payments [withoutFilter]="!company?.permissions?.includes('owner') && !company?.permissions?.includes('admin') && !company?.permissions?.includes('manager')"></app-payments>
            </ng-container>

            <ng-container *ngSwitchCase="'statement_templates'">
                <app-statements-templates></app-statements-templates>
            </ng-container>

        </ng-container>
    </div>
</div>