<div mat-dialog-title class="mobile_interface_title">
    <div class="mobile_interface_title_in" style="overflow: hidden;">
        <div class="tab_title" (click)="close()">
            <div class="d-f-c">
                <div class="icon_back">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7 12L6.11612 11.1161C5.62796 11.6043 5.62796 12.3957 6.11612 12.8839L7 12ZM16.8839 3.88388C17.372 3.39573 17.372 2.60427 16.8839 2.11612C16.3957 1.62796 15.6043 1.62796 15.1161 2.11612L16.8839 3.88388ZM15.1161 21.8839C15.6043 22.372 16.3957 22.372 16.8839 21.8839C17.372 21.3957 17.372 20.6043 16.8839 20.1161L15.1161 21.8839ZM7.88388 12.8839L16.8839 3.88388L15.1161 2.11612L6.11612 11.1161L7.88388 12.8839ZM6.11612 12.8839L15.1161 21.8839L16.8839 20.1161L7.88388 11.1161L6.11612 12.8839Z" fill="#232323"/>
                    </svg>
                </div>
                <span>
                    {{ "Tag" | translate }}
                </span>
            </div>
        </div>
    </div>
</div>
<mat-dialog-content class="mobile_interface_content">
    <div class="mobile_scrollable_int_big" style="background: #fff;">
        <section class="sub_filter_wrp">
            <div class="subs" [appDeviceClass]="'custom-scroll'">
                <div class="sub" [ngClass]="{'active': data.tagsControl.value == 0}" (click)="patchControl(data.tagsControl, 0)">
                    {{ "Everywhere" | translate }}
                </div>
                <div class="sub" [ngClass]="{'active': data.tagsControl.value == 1}" (click)="patchControl(data.tagsControl, 1)">
                    {{ "Cards" | translate }}
                </div>
                <div class="sub" [ngClass]="{'active': data.tagsControl.value == 2}" (click)="patchControl(data.tagsControl, 2)">
                    {{ "Jobs" | translate }}
                </div>
                <div class="sub" [ngClass]="{'active': data.tagsControl.value == 3}" (click)="patchControl(data.tagsControl, 3)">
                    {{ "Chats" | translate }}
                </div>
                <div class="sub" [ngClass]="{'active': data.tagsControl.value == 4}" (click)="patchControl(data.tagsControl, 4)">
                    {{ "Files" | translate }}
                </div>
            </div>
            <div class="block" style="padding: 0;">
                <ng-container *ngIf="!switched">
                    <form [formGroup]="data.filter" class="exp_param_form custom-scroll filter_in parameter_global_filter scroll_task_filter" [appDeviceClass]="'custom-scroll'">
                        <ng-container *ngFor="let param of data.parameters">

                            <mat-form-field appearance="standard" class="full_width_form" *ngIf="!!param?.hasValues" [ngStyle]="{'margin-bottom': param.id == 0 ? '20px': ''}">
                                <mat-label>{{ param.name }}</mat-label>
                    
                                <mat-chip-list 
                                    class="without_ordered"
                                    #paramsChipList
                                    formControlName="{{data.paramFilterProps[data.tagsControl['value']]}}"
                                >
                                    <mat-chip 
                                        class="param_chip_on_page"
                                        *ngFor="let val of paramSwitcher(data.paramFilterProps[data.tagsControl['value']], param); let k = index;"
                                        [removable]="true"
                                        color="primary"
                                        (removed)="data.onRemove(val, param, data.paramFilterProps[data.tagsControl['value']])">
                                            {{ getValueById(val.id)?.value }} <span class="parameter_name" *ngIf="param.id == 0 && getParameterById(val.parameter_id)">({{ getParameterById(val.parameter_id).name }})</span> 
                                        <mat-icon matChipRemove>cancel</mat-icon>
                                    </mat-chip>
                    
                                    <input
                                    class="auto_complete_input"
                                    placeholder="{{ 'Search' | translate }}"
                                    #paramsInput
                                    [formControl]="param.search"
                                    [matAutocomplete]="paramsAuto"
                                    [matChipInputFor]="paramsChipList"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    (matChipInputTokenEnd)="data.add($event)">
                                </mat-chip-list>
                                <mat-autocomplete #autoHead class="filter_autocomlete" autoActiveFirstOption #paramsAuto="matAutocomplete" (optionsScroll)="data.onScrollParams(param)" (optionSelected)="data.selected($event, param, data.paramFilterProps[data.tagsControl['value']])">
                                    <ng-container *ngFor="let val of param.values">
                                        <mat-option *ngIf="noOption(param.activeValues, val.id) && withSearch(param?.search?.value, val)" [value]="val">
                                          <div class="d-f-c" style="justify-content: space-between;">
                                            <span>
                                              {{ val.value }}
                                            </span>
                                            <span class="parameter_name_in_option" *ngIf="param.id == 0 && getParameterById(val.parameter_id)">({{ getParameterById(val.parameter_id).name }})</span> 
                                          </div>
                                        </mat-option>
                                    </ng-container>
                                </mat-autocomplete>
                            </mat-form-field>
                          </ng-container>
                    </form>
                </ng-container>
                
            </div>
        </section>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <div class="mobile_interface_buttons small_mob_btns" style="justify-content: space-between !important;">
        <button class="mobile_interface_btn" (click)="close()">
            {{ "Cancel" | translate }}
        </button>
        
        <button class="mobile_interface_btn mobile_interface_btn_blue" (click)="onSubmit()">
            {{ "Apply" | translate }}
        </button>
    </div>
</mat-dialog-actions>