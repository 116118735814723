<h2 mat-dialog-title class="dialog_title">
    {{data.title}}
</h2>
<mat-dialog-content class="mat-typography">
    <div class="container form_wrapper settings_body">
        <form [formGroup]="form">
            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ data.field | translate }}</mat-label>
                <ng-container *ngIf="data.field == 'Minutes'; else elseTemplateMinutes">
                    <input type="number" formControlName="text" matInput placeholder="{{ data.field | translate }}">
                </ng-container>
                <ng-template #elseTemplateMinutes>
                    <input (input)="onInputUrl($event.target.value)" formControlName="text" matInput placeholder="{{ data.field | translate }}">
                </ng-template>
                
            </mat-form-field>
        </form>
        <ng-container *ngIf="data.withSeconds">
            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ "Pause time (s)" | translate }}</mat-label>
                <input type="number" [(ngModel)]="seconds" matInput placeholder="{{ 'Pause time (s)' | translate }}">
            </mat-form-field>
        </ng-container>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <div class="start_btns">
        <button class="b_tn" (click)="close()">
            {{ "Close" | translate }}
        </button>

        <ng-container *ngIf="data.field == 'Minutes'; else elseTemplateMinutesBtn">
            <button class="b_tn b_tn_blue ml-1" (click)="save()">
                {{ "Pause" | translate }}
            </button>
        </ng-container>
        <ng-template #elseTemplateMinutesBtn>
            <button class="b_tn b_tn_brown ml-1" (click)="old()">
                {{ "Old link" | translate }}
            </button>
            <button class="b_tn b_tn_blue ml-1" (click)="save()">
                {{ "New link" | translate }}
            </button>
        </ng-template>
    </div>
</mat-dialog-actions>
