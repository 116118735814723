import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { Subscription, concat, forkJoin, of } from 'rxjs';
import { catchError, concatMap, last, map, switchMap, tap } from 'rxjs/operators';
import { DeleteAlertComponent } from 'src/app/shared/global_components/delete-alert/delete-alert.component';
import { BaseClass } from 'src/app/shared/models/base-class';
import { FileService } from 'src/app/shared/services/rest/file.service';
import { MembersService } from 'src/app/shared/services/rest/members.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { ChatService } from 'src/app/shared/services/rest/chat.service';
import { casesModel } from 'src/app/shared/functions/casesModel';
import { CardProjectComponent } from './dialog/card-project/card-project.component';

@Component({
  selector: 'app-cards-projects-list',
  templateUrl: './cards-projects-list.component.html',
  styleUrls: ['./cards-projects-list.component.scss']
})
export class CardsProjectsListComponent extends BaseClass implements OnInit, OnDestroy {
  public isLoad: boolean = false;
  public projects: any;
  public projectsPage: number = 1;
  public projectsPagination: any;
  
  public projectsDataSub: Subscription;

  throttle = 100;
  scrollUpDistance = 3.5;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private taskService: TaskService,
    public dialogRef: MatDialogRef<CardsProjectsListComponent>,
    private dialog: MatDialog,
    private bottomSheet: MatBottomSheet,
    private chatService: ChatService,
    private membersService: MembersService,
    private fileService: FileService,
  ) { super() }

  ngOnInit(): void {
    console.log("CardsProjectsListComponent", this.data)
    // this.projectsDataSub = this.fileService.getProjectsData().pipe(
    //   concatMap(itemsInPage => {
    //     return concat(...itemsInPage.map(itemInPage => this.neededProjectsData(itemInPage))).pipe(last(),map(x => itemsInPage))
    //   }),
    // ).subscribe(resp => console.log("-----getProjectsDataStream-----",resp));
    this.getProjects(this.projectsPage);
  }

  log() {
    console.log('projects', this.projects)
  }

  // neededProjectsData(proj) {
  //   return forkJoin([
  //     this.fileService.getVideoProjectRunHistory('1', this.data.company.id, { video_project_id: proj.id }).pipe(
  //       map(x => x.body),
  //       tap(res => {
  //         console.log('neededProjectsData res', res)
  //         proj.runHistory = res;
  //         let x = 0;
  //         if (res) {
  //           res.forEach(element => {
  //             x = +x + +element.price_unit;
  //           });
  //         }
  //         proj.total_price_unit = x;
  //       }),
  //       catchError(err => of(err))
  //     ),
  //   ])
  // }

  editProjectCardsAndPublish(project) {
    const dialogRef = this.dialog.open(CardProjectComponent, {
      panelClass: ['full_screen_modal', 'video_editor', 'video_editor_black'],
      autoFocus: false,
      data: {
        company_id: this.data.company_id,
        target_company_id: this.data.target_company_id,
        company: this.data.company,
        activeLang: this.data.activeLang,
        user: this.data.user,
        imgRoute: this.data.imgRoute,
        host: this.data.host,
        task: this.data.task,
        work: this.data.work,
        project: project
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log(result)
      })
    )
  }
  
  toggleManager(e, manager) {
    manager.disabled = true
    this.membersService.editMember(manager.id, {is_price_manager: Number(e.checked)}, this.data.company.id).subscribe(resp => {
      manager.is_price_manager = Number(e.checked);
      manager.disabled = false;
    })
  }

  refreshProjects() {
    this.projectsPage = 1;
    this.getProjects(this.projectsPage);
  }
  
  // addProject() {
  //   const dialogRef = this.dialog.open(TaskManagerAddComponent, {
  //     data: {
  //       company: this.data.company,
  //       user: this.data.user,
  //       taskManagers: this.taskManagers,
  //       task: this.data.task
  //     }
  //   });

  //   this.attachSubscriptions(
  //     dialogRef.afterClosed().subscribe(result => {
  //       console.log(result)
  //       if (result.event == 'update') {
  //         this.getTaskManagers();
  //       }
  //     })
  //   )
  // }

  getProjects(page) {
    this.isLoad = true;
    let filter:any = {};
    // if (this.data.task_id) {
    //   filter.task_id = this.data.task_id
    // }
    // if (this.data.task_operation_id) {
    //   filter.task_operation_id = this.data.task_operation_id
    // }
    this.attachSubscriptions(
      this.fileService.getCardProjects(page, this.data.company.id, filter, '200').pipe(
        tap(el => {
          this.projectsPagination = {
            'pageCount': el.headers.get('x-pagination-page-count'),
            'perPage': el.headers.get('x-pagination-per-page'),
            'totalCount': el.headers.get('x-pagination-total-count'),
            'currentPage': el.headers.get('x-pagination-current-page'),
          }
        }),
        map(x => x.body),
        // tap(resp => {
        //   this.fileService.projectsData$.next(resp)
        // }),
      ).subscribe(resp => {
        if (page == 1) {
          this.projects = resp;
        } else {
          this.projects.push(...resp);
        }
        console.log("getProjects - " + this.projectsPage, this.projects);
        this.projectsPage++;
        this.isLoad = false;
      })
    )
  }

  onDown(e) {
    console.log("SCROLL DOWN", e);
    if (this.projectsPagination['pageCount'] >= this.projectsPage) {
      console.log("getProjects")
      this.getProjects(this.projectsPage);
    }
  }


  neededData(task, company) {
    let arr = [
      this.chatService.getTasksChats(company.id, [task.id]).pipe(
        tap(res => {
          task['operations'].map(o_el => {
            o_el['chatsInfo'] = [];
            o_el['chatsInfo'].push(...res.filter(z => z.task_operation_id == o_el.id));
            o_el['unReadChats'] = res.filter(z => z.task_operation_id == o_el.id && z.is_read != '1').length;
            o_el['unClosedChats'] = res.filter(z => z.task_operation_id == o_el.id && z.status_id != '4').length;
          });
          task['chatsInfo'] = [];
          task['chatsInfo'].push(...res.filter(z => z.task_id == task.id));
          task['unReadChats'] = res.filter(z => z.is_read != '1').length;
          task['unClosedChats'] = res.filter(z => z.status_id != '4').length;
        })
      )
    ]
    if (task.company_id == company.id) {
      arr.push(
        this.taskService.getTaskClients(task.id, company.id).pipe(
          tap(res => {
            task.clients = res
          }),
          catchError(err => {
            return err
          })
        )
      )
    }
    return forkJoin(arr)
  }

  close() {
    this.dialogRef.close({event: "close", data: false});
  }

  // updateProject(project, i) {
  //   this.attachSubscriptions(
  //     this.fileService.getVideoProjects('1', this.data.company.id, {id: project.id}, '200').pipe(
  //       map(res => res.body),
  //       switchMap(res => {
  //         return this.fileService.getVideoEditorHistory('1', this.data.company.id, {
  //           company_id: project.company_id,
  //           video_project_id: res.id,
  //           is_active: 1
  //         }, '200').pipe(
  //           tap(el => {
  //             res.historyCount = +el.headers.get('x-pagination-total-count')
  //           }),
  //           map(() => res)
  //         )
  //       }),
  //     ).subscribe(result => {
  //       this.projects.splice(i,1,result[0]);
  //     })
  //   )
  // }

  refreshProject(project, i) {
    this.attachSubscriptions(
      this.fileService.editCardProject(project.id, {
        is_draft: 0,
        is_to_process: 1
      }, this.data.company.id).pipe(
        switchMap(x => {
          return this.fileService.getCardProjects('1', this.data.company.id, {id: project.id}, '200').pipe(
            map(res => res.body),
            // switchMap(res => {
            //   return this.fileService.getVideoEditorHistory('1', this.data.company.id, {
            //     company_id: this.data.target_company_id,
            //     video_project_id: res.id,
            //     is_active: 1
            //   }, '200').pipe(
            //     tap(el => {
            //       res.historyCount = +el.headers.get('x-pagination-total-count')
            //     }),
            //     map(() => res)
            //   )
            // }),
            tap(result => {
              this.projects.splice(i,1,result[0]);
            })
          )
        })
      ).subscribe(resp => {
        console.log("END Refresh", resp)
      })
    )
  }

  deleteProject(project, i) {
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: {},
        target: marker("project")
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        this.attachSubscriptions(
          this.fileService.deleteCardProject(project.id, this.data.company.id).pipe(
            tap(x => {
              console.log(x)
              this.projects.splice(i,1);
            }),
          ).subscribe(resp => {
            console.log("END DELETE", resp)
          })
        )
      }
    });
  
  }

  ngOnDestroy() {
    // if (this.projectsDataSub) {
    //   this.projectsDataSub.unsubscribe()
    // }
    this.clearSubscriptions()
  }

}
