<h2 mat-dialog-title class="dialog_title">
    <span>
        {{ "Create archive" | translate }}
    </span>
    <button mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h2>
<mat-dialog-content class="mat-typography" dirEnter (dirSubmit)="!form.valid ? null : submitForm()">
    <div class="container">
        <form [formGroup]="form">
            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ "Deleted at" | translate }}</mat-label>
                <input (input)="changeDate($event)" matInput [matDatepicker]="dp" formControlName="delete_at" [min]="minDate" [max]="maxDate">
                <mat-datepicker-toggle matPrefix [for]="dp"></mat-datepicker-toggle>
                <mat-datepicker #dp></mat-datepicker>
            </mat-form-field>
        </form>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button (click)="close()">
        {{ "Back" | translate }}
    </button>
    <button class="ml-1" (click)="submitForm()" mat-raised-button cdkFocusInitial color="primary" [disabled]="!form.valid">
        {{ "Create" | translate }}
    </button>
</mat-dialog-actions>
