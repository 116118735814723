import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { map, switchMap } from 'rxjs/operators';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { BaseClass } from 'src/app/shared/models/base-class';
import { GlobalDataService } from 'src/app/shared/services/common/global-data.service';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { AuthService } from 'src/app/shared/services/rest/auth.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { UsersService } from 'src/app/shared/services/rest/users.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-employee-add',
  templateUrl: './employee-add.component.html',
  styleUrls: ['./employee-add.component.scss']
})
export class EmployeeAddComponent extends BaseClass implements OnInit, OnDestroy {
  public host: any = environment.host;
  public imgRoute: any = '';
  public user: any = {};
  public user_id: number;
  public employees: any;
  public submited: boolean = false;
  public isSubmit: boolean = false;
  public isFormChange: boolean = false;
  public form: FormGroup;
  public virtual: FormGroup;
  public company_id: any;
  public currencyList: any;
  public statuses: any;
  public numberRegEx = /\d*\.?\d{1,2}/;
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<EmployeeAddComponent>,
    private sm: StorageManagerService,
    private layoutService: LayoutService,
    private auth: AuthService,
    private activatedRoute: ActivatedRoute,
    private companyService: CompanyService,
    private globalDataService: GlobalDataService,
    private fb: FormBuilder,
    private taskService: TaskService,
    private usersService: UsersService
  ) { super() }

  ngOnInit(): void {
    console.log('EmployeeAddComponent', this.data);

    this.form = this.fb.group({
      company_id: this.data.company_id,
      vuser_id: '',
      description: '',
      currency_id: ['', Validators.required],
      permissions: '',
      rate_target: [0, [Validators.required, Validators.pattern(this.numberRegEx)]],
      salary: [0, [Validators.required, Validators.pattern(this.numberRegEx)]],
    })

    this.virtual = this.fb.group({
      name: ['', Validators.required]
    })

    this.attachSubscriptions(
      this.virtual.valueChanges.subscribe(() => this.isFormChange = true)
    )
    this.attachSubscriptions(
      this.form.valueChanges.subscribe(() => this.isFormChange = true)
    )

    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        if (this.isFormChange) {
          this.layoutService.openBottomSheet(this.dialogRef);
        } else {
          this.close();
        }
      })
    )

    this.getEmployeeStatuses();
    this.getCompany();
    this.getImgRoute();
    this.getCsrf();
    this.getUser();

    this.getEmployees();
    this.getCurrencyList();
  }

  getEmployeeStatuses() {
    this.attachSubscriptions(
      this.taskService.getEmployeeStatuses().subscribe(resp => {
        console.log("getEmployeeStatuses", resp)
        this.statuses = resp;
      })
    )
  }

  getCompany() {
    this.attachSubscriptions(
      this.companyService.getCompany(this.data.company_id).subscribe(resp => {
        this.companyService.company$.next(resp[0]);
      })
    )
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  getCsrf() {
    this.attachSubscriptions(
      this.auth.$userToken.subscribe(resp => {
        this.user_id = resp.user_id;
        this.sm.localStorageSetItem("csrf_param", resp.csrf_param)
        this.sm.localStorageSetItem("csrf_token", resp.csrf_token)
      })
    )
  }

  getUser() {
    this.attachSubscriptions(
      this.auth.$user.subscribe(resp => {
        this.user = resp;
      })
    )
  }

  getCurrencyList() {
    this.attachSubscriptions(
      this.globalDataService.getCurrencies().subscribe(resp => {
        console.log("getCurrencyList", resp)
        this.currencyList = resp.slice();
      })
    )
    // this.attachSubscriptions(
    //   this.companyService.getCurrencyList().subscribe(resp => {
    //     console.log("getCurrencyList", resp);
    //     this.currencyList = resp
    //   })
    // )
  }

  getEmployees() {
    this.attachSubscriptions(
      this.usersService.getUsers(this.data.company_id).subscribe(resp => {
        console.log(resp);
        this.employees = resp;
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  onSubmit() {
    if (!this.form.valid) {
      this.submited = true;
      console.log(this.form);
      return
    }
    if (!this.virtual.valid) {
      this.submited = true;
      console.log(this.virtual);
      return
    }
    this.submited = false;
    this.isSubmit = true;
    if (String(this.form.value['rate_target']).indexOf(",") != -1) {
      this.form.value['rate_target'] = this.form.value['rate_target'].replace(",", ".");
    }
    if (String(this.form.value['salary']).indexOf(",") != -1) {
      this.form.value['salary'] = this.form.value['salary'].replace(",", ".");
    }

    this.attachSubscriptions(
      this.usersService.createUser(this.virtual.value, this.activatedRoute.snapshot.queryParamMap.get('company_id')).pipe(
        switchMap(x => {
          this.form.patchValue({
            vuser_id: x.id
          })
          return this.taskService.addVuser(this.form.value).pipe(
            switchMap(employee => {
              let editForm = {
                company_id: this.data.company_id,
                reset_invite: 1,
                employee: {
                  user_id: x.id
                }
              }
              return this.taskService.editEmployee(employee.id, editForm).pipe(
                map(() => employee)
              )
            })
          )
        })
        ).subscribe(resp => {
          this.isSubmit = false;
          this.dialogRef.close({event: "update", data: resp});
        }, error => {
          this.isSubmit = false;
          this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
        })
    
    )
  }

  ngOnDestroy(): void {
    this.clearSubscriptions();
  }
}
