<mat-dialog-content class="mat-typography" dirEnter (dirSubmit)="!form.valid || isSubmit ? null : onSubmit()">
    <div class="container form_wrapper">
        <div class="title_modal">
            <ng-container *ngIf="!!data?.dublicate; else elseTemplateTitleDublicate">
                {{ "Dublicate regular publications" | translate }}
            </ng-container>
            <ng-template #elseTemplateTitleDublicate>
                <ng-container *ngIf="!data.regular; else elseTemplateTitle">
                    {{ "Adding regular publications" | translate }}
                </ng-container>
                <ng-template #elseTemplateTitle>
                    {{ "Edditing regular publications" | translate }}
                </ng-template>
            </ng-template>
         
        </div>
        <form [formGroup]="form" class="reg_f_cst form_with_pads form_with_valids" style="min-height: 400px;">

            <div class="outlet_set add_reg" *ngIf="!!form.get('channel_id')">
                <div class="outlet_selected">
                    <div class="opened_trigger" (click)="opened = !opened">
                        <div class="label" [ngClass]="{'selected': form.get('channel_id').value}">
                            {{ "Choose a channel" | translate }}
                        </div>
                        <ng-container *ngIf="form.get('channel_id').value">
                            <app-outlet-line [channel]="getProfileById(form.get('channel_id').value)" [company_id]="data?.company.id"></app-outlet-line>
                        </ng-container>
                        <mat-icon>arrow_drop_down</mat-icon>
                    </div>
                </div>
                <app-outlet-filter *ngIf="platforms && opened"
                    [company]="data.company" 
                    [company_id]="data.company.id" 
                    [multiple]="false" 
                    [n_a]="false" 
                    [needCst]="true" 
                    [form]="form" 
                    [profiles]="false"
                    [outletKey]="'channel_id'" 
                    [activeSections]="''" 
                    [platforms]="platforms" 
                    [showOutletBtn]="false" 
                    (SelectChange)="selectChange($event)"
                    (SelectOutletTab)="setOutletKey($event)">
                </app-outlet-filter>
            </div>

            <!-- <pre>{{channels|json}}</pre> -->

            <mat-form-field appearance="standard" class="full_width_form">
                <ng-container *ngIf="!form.get('name').valid && submited">
                    <div class="req_field"></div>
                    <div class="req_label">
                        <div class="angle"></div>
                        <span>
                            {{ "You need to write here" | translate }}
                        </span>
                    </div>
                </ng-container>
                <mat-label>{{ 'Post title' | translate }}</mat-label>
                <input formControlName="name" matInput>
            </mat-form-field>

            <!-- <mat-form-field appearance="standard" class="full_width_form h_cst_t" *ngIf="!!form.get('channel_id')">
                <mat-label>{{ "Select publication outlet" | translate }}</mat-label>
                <mat-select [disableOptionCentering]="true" selectDir #profilesContainer formControlName="channel_id" required>
                    <mat-select-trigger>
                        <app-outlet-line [channel]="getProfile(form.get('channel_id').value)" [company_id]="data?.company_id"></app-outlet-line>
                    </mat-select-trigger>
                    <mat-option>
                        <ngx-mat-select-search 
                        placeholderLabel="{{ 'Search' | translate }}" 
                        noEntriesFoundLabel="{{ 'no matching outlet found' | translate }}"
                        [formControl]="channelMoreControl"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let channel of channels$ | async" [value]="channel.id" class="cst_o">
                        <app-outlet-line [channel]="channel" [company_id]="data?.company_id"></app-outlet-line>
                    </mat-option>
                </mat-select>
            </mat-form-field> -->

            <div class="small_chips" *ngIf="contentTypes?.length > 0 && !data.regular"> 
                <label>{{ "Content type" | translate }}: </label>
                <mat-chip-list [multiple]="false" selectable formControlName="content_type_id">
                    <ng-container *ngFor="let type of contentTypes; let i = index">
                        <mat-chip class="small_chip" (click)="selectType(type)" [value]="type?.id">
                            {{ type.name | translate }}
                        </mat-chip>
                        <mat-chip *ngIf="i == contentTypes?.length - 1 && i > 6" class="small_chip more" (click)="contentTypesContainer.open()">
                            {{ "More..." | translate }}
                        </mat-chip>
                    </ng-container>
                    
                </mat-chip-list>
            </div>

            <mat-form-field appearance="standard" class="full_width_form hide-form-field" *ngIf="!data.regular">
                <mat-select #contentTypesContainer formControlName="content_type_id" (selectionChange)="selectType($event)">
                    <mat-option>
                        <ngx-mat-select-search 
                        placeholderLabel="{{ 'Search' | translate }}" 
                        noEntriesFoundLabel="{{ 'no matching types found' | translate }}"
                        [formControl]="contentTypeMoreControl"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let type of contentTypes$ | async" [value]="type.id">
                        {{ type.name | translate }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <ng-container *ngIf="!data.dublicate">
                <p *ngIf="!data.regular">
                    {{ "Choose a regular publications format" | translate }}:
                    <mat-button-toggle-group class="ml-1" appearance="legacy" formControlName="type" [value]="form.get('type').value">
                        <mat-button-toggle [value]="0">{{ "Every day" | translate }}</mat-button-toggle>
                        <mat-button-toggle [value]="1">{{ "Every week" | translate }}</mat-button-toggle>
                        <mat-button-toggle [value]="2">{{ "Every month" | translate }}</mat-button-toggle>
                    </mat-button-toggle-group>
                </p>
    
                <mat-form-field class="full_width_form" appearance="standard">
                    <mat-label>{{ "Apply from time" | translate }}</mat-label>
                    <input matInput [ngxMatDatetimePicker]="pickerFrom" placeholder="Choose a date" formControlName="apply_from_time">
                    <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
                    <ngx-mat-datetime-picker #pickerFrom [showSpinners]="true" [touchUi]="false" [color]="'primary'">
    
                      <ng-template>
                        <span>OK</span>
                      </ng-template>
                    </ngx-mat-datetime-picker>
                </mat-form-field>
    
                <mat-form-field class="full_width_form" appearance="standard">
                    <mat-label>{{ "Apply to time" | translate }}</mat-label>
                    <input matInput [ngxMatDatetimePicker]="pickerTo" placeholder="Choose a date" formControlName="apply_to_time">
                    <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
                    <ngx-mat-datetime-picker #pickerTo [showSpinners]="true" [touchUi]="false" [color]="'primary'">
                      <ng-template>
                        <span>OK</span>
                      </ng-template>
                    </ngx-mat-datetime-picker>
                </mat-form-field>
            </ng-container>
        </form>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <div class="f_w_p_btns">
        <button mat-button (click)="close()">
            {{ "Close" | translate }}
        </button>
        <button class="ml-1 d-f-btn" (click)="onSubmit()" cdkFocusInitial mat-raised-button color="primary" [disabled]="isSubmit">
            <mat-spinner class="spin_16 mr-1" *ngIf="isSubmit"></mat-spinner>  
            {{ "Save" | translate }}
        </button>
    </div>
</mat-dialog-actions>