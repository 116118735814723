<div class="platforms_file p_f_comp" [class]="addClass">
    <ng-container *ngFor="let platform of file?.fileOriginalData?.meta_channel?.task_channels_by_platform_arr">
      <ng-container *ngIf="platform.platform_id">
        <div class="platform_consolid" (click)="$event.preventDefault(); $event.stopPropagation(); openFileChannels($event, file, platform)">
          <app-platform [ngClass]="{'without_published': !platform.published}" [platform_id]="platform.platform_id"></app-platform>
          <span 
          #tooltip="matTooltip"
          matTooltip="{{ 'All posts + Livestreams' | translate }}"
          [matTooltipPosition]="'above'"
          class="post_tab_count all" 
          *ngIf="((!!platform.published ? 1 : 0) + (!!platform.live ? 1 : 0) + (!!platform.waiting ? 1 : 0)) > 1">{{+platform.published + (+platform?.live || 0) + +platform.waiting}}</span>
          <span
          #tooltip="matTooltip"
          matTooltip="{{ 'Posts that are published or prepared for publishing' | translate }}"
          [matTooltipPosition]="'above'"
          class="post_tab_count publ" 
          *ngIf="platform.published > 0">{{platform.published}}</span>
          <span 
          #tooltip="matTooltip"
          matTooltip="{{ 'Live streaming now' | translate }}"
          [matTooltipPosition]="'above'"
          class="post_tab_count live" 
          *ngIf="platform.live > 0">{{platform.live}}</span>
          <span    
          #tooltip="matTooltip"
          matTooltip="{{ 'Posts that not prepared for publishing. No files assigned to post.' | translate }}"
          [matTooltipPosition]="'above'"
          class="post_tab_count wait" 
          *ngIf="platform.waiting > 0">{{platform.waiting}}</span>
        </div>
      </ng-container>
    </ng-container>
</div>

<ng-template #channelsMenu let-menuData>
	<section class="relations_menu channels_menu" [appDeviceClass]="'custom-scroll custom-scroll-t'">
        <ng-container *ngFor="let item of menuData?.platform?.task_channels">
            <ng-container *ngIf="!item.taskChannel; else elseTemplateNotLoad">
                <p-skeleton height="34.5px"></p-skeleton>
            </ng-container>
            <ng-template #elseTemplateNotLoad>
                <div class="in_menu" *ngIf="item?.taskChannel?.content_status_id != 8">
                    <div class="chan_title">
                        <div class="d-f-c" [ngClass]="{'cp': !!item?.taskChannel?.content_url}" (click)="openChannelContent(item?.taskChannel?.content_url);">
                            <app-platform [platform_id]="item?.taskChannel?.channel?.platform_id"></app-platform>
                            <div class="chan_name ml-5">
                                <span style="display: block;" class="text_one_line" #tooltip="matTooltip" matTooltip="{{ item?.taskChannel?.channel?.name }}" [matTooltipPosition]="'above'">{{item?.taskChannel?.channel?.name}}</span>
                                <div class="chan_id" *ngIf="item?.taskChannel?.id">
                                    ID: {{item?.taskChannel?.id}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="chan_cont_name text_2_line">
                        {{item?.taskChannel?.content_name}}
                    </div>
                    <div class="chan_info">
                        <div class="d-f-c">
                            <div class="status">{{(getProfileStatus(item?.taskChannel?.content_status_id)?.name + '_profile') | translate}}</div>
                            <div class="published_at" *ngIf="item?.taskChannel?.content_published_at">
                                {{ item?.taskChannel?.content_published_at | reportsDate:activeLang }}
                            </div>
                        </div>
                        <div class="views" *ngIf="item?.taskChannel?.content_views_count && item?.taskChannel?.content_views_count > 0">
                            <svg width="9" height="10" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0 10V3H2V10H0ZM3.5 10V0H5.5V10H3.5ZM7 10V6H9V10H7Z" fill="#C9C9C9"/>
                            </svg>
                            {{item?.taskChannel?.content_views_count | number:'':'fr-FR'}}
                        </div>
                    </div>
                </div>
            </ng-template>
            
        </ng-container>        
	</section>
</ng-template>