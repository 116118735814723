<div
  class="mob_video_wrp_out"
  swipe
  [ngClass]="{'show_panels': showPanels}"
  (touchmove)="data.playlist && data.currIndex && data.playlist[data.currIndex].content_type != 'application/pdf' ? $event.preventDefault() : null"
  (next)="onRotateNext()"
  (previous)="onRotatePrevious()"
>
    <mat-progress-bar *ngIf="data.playlist"
    class="mob_filemanager_progress_bar"
    color="primary"
    mode="determinate"
    [value]="getCurrentPosition()">
    </mat-progress-bar>
    <ng-container *ngIf="!!data.playlist">
        <ng-container *ngFor="let item of data.playlist; let u = index;">
            <div class="cst_slide" *ngIf="data?.currIndex == u">
                <div class="top_panel">
                    <div class="d-f-c">
                        <button mat-icon-button style="color: #fff;" (click)="closeDialog()">
                            <mat-icon >close</mat-icon>
                        </button>
    
                        <div class="file_name text_one_line">
                            {{item?.filename}}
                        </div>
                    </div>
            
                    <div class="d-f-c">
                        <div class="vm_custom_spliter_out" *ngIf="item?.thumbnail && item?.content_type.indexOf('video') == -1">
                            <div class="vm_custom_spliter">
                                <div class="vm_spliter_btn">
                                    <button style="color: #fff;" [ngClass]="{'active_vm_btn': item?.imgSrc == item.thumbnail}" mat-icon-button (click)="changeSrc(item, 'lite', true)">
                                        Lite
                                    </button>
                                </div>
                                <div class="vm_spliter_btn">
                                    <button style="color: #fff;" [ngClass]="{'active_vm_btn': item?.imgSrc == item.original}" mat-icon-button (click)="changeSrc(item, 'full', true)">
                                        {{ item?.meta_width ? item?.meta_width : 'Full' }}
                                    </button>
                                </div>
                            </div>
                        </div>
                
                        <div class="vm_custom_spliter_out" *ngIf="item?.preview1080">
                            <div class="vm_custom_spliter">
                                <div class="vm_spliter_btn">
                                    <button style="color: #fff;" [ngClass]="{'active_vm_btn': item?.videoSrc == item.preview1080}" mat-icon-button (click)="changeSrc(item, 'lite')">
                                        1080
                                    </button>
                                </div>
                                <div class="vm_spliter_btn">
                                    <button style="color: #fff;" [ngClass]="{'active_vm_btn': item?.videoSrc == item.original}" mat-icon-button (click)="changeSrc(item, 'full')">
                                        {{ item?.meta_width ? item?.meta_width : 'Full' }}
                                    </button>
                                </div>
                            </div>
                        </div>
    
                        <button style="color: #fff;" mat-icon-button (click)="settings(item)">
                            <mat-icon>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10 20C15.5 20 20 15.5 20 10C20 4.5 15.5 0 10 0C4.5 0 0 4.5 0 10C0 15.5 4.5 20 10 20ZM10.3 5.29C10.5 5.11 10.74 5 11 5C11.27 5 11.5 5.11 11.71 5.29C11.9 5.5 12 5.74 12 6C12 6.27 11.9 6.5 11.71 6.71C11.5 6.9 11.27 7 11 7C10.74 7 10.5 6.9 10.3 6.71C10.11 6.5 10 6.27 10 6C10 5.74 10.11 5.5 10.3 5.29ZM7.8 9.97C7.8 9.97 9.97 8.25 10.76 8.18C11.5 8.12 11.35 8.97 11.28 9.41L11.27 9.47C11.13 10 10.96 10.64 10.79 11.25C10.41 12.64 10.04 14 10.13 14.25C10.23 14.59 10.85 14.16 11.3 13.86C11.36 13.82 11.41 13.78 11.46 13.75C11.46 13.75 11.54 13.67 11.62 13.78C11.64 13.81 11.66 13.84 11.68 13.86C11.77 14 11.82 14.05 11.7 14.13L11.66 14.15C11.44 14.3 10.5 14.96 10.12 15.2C9.71 15.47 8.14 16.37 8.38 14.62C8.59 13.39 8.87 12.33 9.09 11.5C9.5 10 9.68 9.32 8.76 9.91C8.39 10.13 8.17 10.27 8.04 10.36C7.93 10.44 7.92 10.44 7.85 10.31L7.82 10.25L7.77 10.17C7.7 10.07 7.7 10.06 7.8 9.97Z" fill="white"/>
                                </svg>                                    
                            </mat-icon>
                        </button>
                    </div>
            
                </div>
                <div class="mob_video_wrp" (click)="togglePanels()">
                    <ng-container *ngIf="!!item; else elseTemplateNoFile">
                        <ng-container *ngIf="item?.content_type.indexOf('video') != -1; else elseTemplatePhoto">
                            <div class="player_wrp">
                                <ng-container *ngIf="company?.id || data.is_public">
                                    <ng-container *ngIf="!defaultPlayer; else elseTemplate">
                                        <vm-player *ngIf="!srcIsChanging" class="vm_video_player_mob" (vmCurrentSrcChange)="onSrcChange($event)" #videoPlayer theme="dark" style="--vm-player-theme: #3f51b5;">
                                            <vm-video>
                                                <source [src]="host + item?.videoSrc + (!videoWithotWorkspaceID ? ('?company_id=' + company?.id) : '')" [type]="item?.content_type == 'video/quicktime' ? 'video/mp4' : item?.content_type"/>
                                            </vm-video>
            
                                            <vm-ui>
                                                <vm-controls>
                                                <vm-control-group>
                                                    <vm-scrubber-control></vm-scrubber-control>
                                                </vm-control-group>
                                            
                                                <vm-control-group space="top">
                                                    <vm-playback-control></vm-playback-control>
                                                    <vm-volume-control></vm-volume-control>
                                                    <vm-time-progress></vm-time-progress>
                                                    
                                                    <div class="vm_custom_btn with_number" (click)="changeVideoTime(videoPlayer, 'replay')">
                                                        <button style="color: #fff;" mat-icon-button>
                                                            <mat-icon>replay</mat-icon>
                                                            <span class="number">{{timeVal}}</span>
                                                        </button>
                                                    </div>
            
                                                    <div class="vm_custom_btn with_number" (click)="changeVideoTime(videoPlayer, 'forward')">
                                                        <button style="color: #fff;" mat-icon-button>
                                                            <mat-icon style="transform: scaleX(-1);">replay</mat-icon>
                                                            <span class="number">{{timeVal}}</span>
                                                        </button>
                                                    </div>
                                                    <div class="vm_custom_btn">
                                                        <button style="color: #fff;" mat-icon-button [matMenuTriggerFor]="timeValueMenu" aria-label="Example icon-button with a menu">
                                                            {{timeVal}}s
                                                        </button>
                                                        <mat-menu #timeValueMenu="matMenu" class="ratio_value_menu_class" overlayPanelClass='ratio_value_menu' yPosition="above" xPosition="after">
            
                                                            <button mat-menu-item (click)="changeTime(1)">
                                                                <span>1s</span>
                                                            </button>
                                                            <button mat-menu-item (click)="changeTime(3)">
                                                                <span>3s</span>
                                                            </button>
                                                            <button mat-menu-item (click)="changeTime(5)">
                                                                <span>5s</span>
                                                            </button>
                                                            <button mat-menu-item (click)="changeTime(10)">
                                                                <span>10s</span>
                                                            </button>
                                                            <button mat-menu-item (click)="changeTime(20)">
                                                                <span>20s</span>
                                                            </button>
                                                            <button mat-menu-item (click)="changeTime(30)">
                                                                <span>30s</span>
                                                            </button>
                                                            <button mat-menu-item (click)="changeTime(60)">
                                                                <span>60s</span>
                                                            </button>
                                                        </mat-menu>      
                                                    </div>
            
                                                    <vm-control-spacer></vm-control-spacer>
            
            
            
                                                    <div class="vm_custom_spliter_out mr-25-per">
                                                        <div class="vm_custom_spliter">
                                                            <div class="vm_spliter_btn">
                                                                <button style="color: #fff;" [ngClass]="{'active_vm_btn': videoplayer?.playbackRate == 1}" mat-icon-button (click)="changeRate(videoPlayer, 1)">
                                                                    x1
                                                                </button>
                                                            </div>
                                                            <div class="vm_spliter_btn">
                                                                <button style="color: #fff;" [ngClass]="{'active_vm_btn': videoplayer?.playbackRate == 2}" mat-icon-button (click)="changeRate(videoPlayer, 2)">
                                                                    x2
                                                                </button>
                                                            </div>
                                                            <div class="vm_spliter_btn vm_spliter_btn_menu">
                                                                <button style="color: #fff;" [ngClass]="{'active_vm_btn': videoplayer?.playbackRate != 1 && videoplayer?.playbackRate != 2}" mat-icon-button [matMenuTriggerFor]="ratioValueMenu" aria-label="Example icon-button with a menu">
                                                                    <ng-container *ngIf="videoplayer?.playbackRate == 1 || videoplayer?.playbackRate == 2; else elseTemplateNotDefaultRate">
                                                                        <mat-icon>more_horiz</mat-icon>
                                                                    </ng-container>
                                                                    <ng-template #elseTemplateNotDefaultRate>
                                                                        x{{videoplayer?.playbackRate}}
                                                                    </ng-template>
                                                                </button>
                                                                <mat-menu #ratioValueMenu="matMenu" class="ratio_value_menu_class" overlayPanelClass='ratio_value_menu' yPosition="above" xPosition="before">
                
                                                                    <button mat-menu-item (click)="changeRate(videoPlayer, 0.25)">
                                                                        <span>0.25</span>
                                                                    </button>
                                                                    <button mat-menu-item (click)="changeRate(videoPlayer, 0.5)">
                                                                        <span>0.5</span>
                                                                    </button>
                                                                    <button mat-menu-item (click)="changeRate(videoPlayer, 0.75)">
                                                                        <span>0.75</span>
                                                                    </button>
                                                                    <button mat-menu-item (click)="changeRate(videoPlayer, 1)">
                                                                        <span>1</span>
                                                                    </button>
                                                                    <button mat-menu-item (click)="changeRate(videoPlayer, 1.25)">
                                                                        <span>1.25</span>
                                                                    </button>
                                                                    <button mat-menu-item (click)="changeRate(videoPlayer, 1.5)">
                                                                        <span>1.5</span>
                                                                    </button>
                                                                    <button mat-menu-item (click)="changeRate(videoPlayer, 2)">
                                                                        <span>2</span>
                                                                    </button>
                                                                </mat-menu>      
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <vm-pip-control keys="p"></vm-pip-control>
                                                    <vm-fullscreen-control tooltip-direction="left"></vm-fullscreen-control>
                                                </vm-control-group>
                                                </vm-controls>
                                            </vm-ui>
                                        </vm-player>
                                    </ng-container>
                                    <ng-template #elseTemplate>
                                        <video width="100%" autoplay class="default_videoplayer" controls>
                                            <source [src]="host + (item?.preview1080 ? item?.preview1080 : item?.original) + (!videoWithotWorkspaceID ? ('?company_id=' + company?.id) : '')" [type]="item?.content_type == 'video/quicktime' ? 'video/mp4' : item?.content_type"/>
                                            Your browser doesn't support HTML5 video tag.
                                        </video>
                                    </ng-template>
                                </ng-container>
                            </div>
                        </ng-container>
                        <ng-template #elseTemplatePhoto>
                            <ng-container *ngIf="item?.content_type == 'application/pdf'; else elseTemplatePDF">
                                <div class="pdf_wrp" *ngIf="item && item.pdfSrc">
                                    <div class="pdf_loader" *ngIf="item.progressData && !item.isLoaded">
                                        <div class="value">
                                            {{item.progressData.percent}}%
                                        </div>
                                        <mat-progress-bar
                                            class="pdf_progress"
                                            [color]="'primary'"
                                            [mode]="'determinate'"
                                            [value]="item.progressData.percent">
                                        </mat-progress-bar>
                                    </div>
                                    <pdf-viewer [src]="item.pdfSrc"
                                        [(page)]="item.page"
                                        [rotation]="item.rotation"
                                        [original-size]="item.originalSize"
                                        [show-all]="item.showAll"
                                        [fit-to-page]="item.fitToPage"
                                        (after-load-complete)="afterLoadComplete($event, item)"
                                        [zoom]="item.zoom"
                                        [zoom-scale]="item.zoomScale"
                                        [stick-to-page]="item.stickToPage"
                                        [render-text]="item.renderText"
                                        [external-link-target]="'blank'"
                                        [autoresize]="item.autoresize"
                                        (error)="onError($event, item)"
                                        (on-progress)="onProgress($event, item)"
                                        (page-rendered)="pageRendered($event, item)"
                                        (pages-initialized)="pageInitialized($event, item)"
                                        (pageChange)="pageChange($event, item)"
                                        [show-borders]="false"
                                    ></pdf-viewer>
                                </div>
                            </ng-container>
                            <ng-template #elseTemplatePDF>          
                                <ng-container *ngIf="item?.content_type.indexOf('audio') != -1; else elseTemplateAudio">
                                    <div class="audio_wrp">
                                        <app-audio-player
                                            [host]="host"
                                            [company_id]="company?.id"
                                            [fileOriginal]="item?.original"
                                            [fileIsUpload]="item?.is_uploaded"
                                            [file]="item"
                                            [activeIndex]="u"
                                            [forFM]="true"
                                            [isMOB]="true"
                                        ></app-audio-player>
                                    </div>
                                </ng-container>
                                <ng-template #elseTemplateAudio>    
                                    <ng-container *ngIf="item?.is_document; else elseTemplateIsDocFile">
                                        <ng-container *ngIf="item?.content_type == 'reports-json-auto-post'; else elseTemplateDefDoc">
                                            <app-add-edit-post-document [initData]="{
                                                company: this.company,
                                                company_id: item.company_id,
                                                task_id: item?.task_id,
                                                work_id: item?.task_operation_id,
                                                location: item?.location,
                                                file: item,
                                                is_file: true,
                                                is_edit: true,
                                                user: data?.user,
                                                note: item?.document
                                            }"></app-add-edit-post-document>
                                        </ng-container>
                                        <ng-template #elseTemplateDefDoc>
                                            <app-add-edit-note [initData]="{
                                                company: this.company,
                                                company_id: item.company_id,
                                                task_id: item?.task_id,
                                                work_id: item?.task_operation_id,
                                                location: item?.location,
                                                file: item,
                                                is_file: true,
                                                is_edit: true,
                                                user: data?.user,
                                                note: item?.document
                                            }"></app-add-edit-note>
                                        </ng-template>
                                    </ng-container>  
                                    <ng-template #elseTemplateIsDocFile>
                                        <div class="photo_wrp">
                                            <!-- [ngStyle]="{'transform': 'translate(' + item.posX + 'px, ' + item.posY + 'px) scale(' + item.scale + ')'}" -->
                                            <img [src]="host + item?.imgSrc + '?company_id=' + company?.id" (error)="onImgError($event)" [alt]="item?.filename"         
                                            (touchstart)="onTouchStart($event, item)" 
                                            (touchmove)="onTouchMove($event, item)" 
                                            (touchend)="onTouchEnd($event, item)"
                                            (dblclick)="onDoubleTap($event, item)">

                                            <!-- <div class="mob_info">
                                                posX: {{item.posX}}
                                                posY: {{item.posY}}
                                                scale: {{item.scale}}
                                            </div> -->
                                            <!-- (dblclick)="onDef($event, item)" -->
                                        </div>
                                    </ng-template>
                                </ng-template>            
                            </ng-template>
                        </ng-template>
                    </ng-container>
                    <ng-template #elseTemplateNoFile>
                        <div class="no_file_block">
            
                        </div>
                    </ng-template>
                </div>
                <div class="bottom_panel">
    
                    <div class="file_tags_out" (click)="$event.preventDefault(); $event.stopPropagation();">
                        <ul class="file_tags" *ngIf="item.tags">
                            <li class="file_tag" *ngFor="let tag of item.tags">
                                {{tag?.parameterValue?.value}}
                            </li>
                        </ul>
                    </div>
    
                    <div class="d-f-c">
                        <div class="button_group">
                            <div class="bottom_button bottom_button_copy" (click)="copy(item)">
                                <svg width="19" height="22" viewBox="0 0 19 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M17 20H6V6H17M17 4H6C5.46957 4 4.96086 4.21071 4.58579 4.58579C4.21071 4.96086 4 5.46957 4 6V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H17C17.5304 22 18.0391 21.7893 18.4142 21.4142C18.7893 21.0391 19 20.5304 19 20V6C19 5.46957 18.7893 4.96086 18.4142 4.58579C18.0391 4.21071 17.5304 4 17 4ZM14 0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V16H2V2H14V0Z" fill="#A3A3A3"/>
                                </svg>                            
                            </div>
                            <div class="bottom_button bottom_button_link" ngxClipboard [cbContent]="origin + '/file/' + item?.acm" (cbOnSuccess)="copyLink('file')">
                                <svg width="20" height="10" viewBox="0 0 20 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15 0H11V2H15C16.65 2 18 3.35 18 5C18 6.65 16.65 8 15 8H11V10H15C17.76 10 20 7.76 20 5C20 2.24 17.76 0 15 0ZM9 8H5C3.35 8 2 6.65 2 5C2 3.35 3.35 2 5 2H9V0H5C2.24 0 0 2.24 0 5C0 7.76 2.24 10 5 10H9V8ZM6 4H14V6H6V4Z" fill="#A3A3A3"/>
                                </svg>                       
                            </div>
                        </div>
    
                        <div class="button_group" *ngIf="item && data?.task && (data.company?.permissions.includes('owner') || data.company?.permissions.includes('manager') || data.company?.permissions.includes('admin')) && !!item?.task_operation_id && fileLocation(item)">                        
                            <div class="bottom_button bottom_button_reject" (click)="moveFile(item, '/reject', u)">
                                <svg width="19" height="21" viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M17.12 20.54L15 18.41L12.88 20.54L11.47 19.12L13.59 17L11.47 14.88L12.88 13.47L15 15.59L17.12 13.47L18.54 14.88L16.41 17L18.54 19.12L17.12 20.54ZM10 0H2C0.89 0 0 0.89 0 2V18C0 19.11 0.89 20 2 20H9.81C9.28 19.09 9 18.05 9 17C9 13.69 11.69 11 15 11C15.34 11 15.67 11.03 16 11.08V6L10 0ZM9 7V1.5L14.5 7H9Z" fill="#FF6A6A"/>
                                </svg>                          
                            </div>
                            <div class="bottom_button bottom_button_approve" (click)="approveFile(item, u)">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M19.5 15L14.5 20L11 16.5L12.5 15L14.5 17L18 13.5L19.5 15ZM2 0C0.89 0 0 0.89 0 2V18C0 19.11 0.89 20 2 20H9.81C9.28 19.09 9 18.05 9 17C9 13.69 11.69 11 15 11C15.34 11 15.67 11.03 16 11.08V6L10 0M9 1.5L14.5 7H9V1.5Z" fill="#98F1BD"/>
                                </svg>                        
                            </div>
                        </div>
    
                        <div class="button_group">                            
                            <div class="bottom_button bottom_button_yes" (click)="openTargetValues([item?.task_id, item?.task_operation_id ? item?.task_operation_id : 0, 0, item?.id], item)">
                                <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.5 5C3.89782 5 4.27936 4.84196 4.56066 4.56066C4.84196 4.27936 5 3.89782 5 3.5C5 3.10218 4.84196 2.72064 4.56066 2.43934C4.27936 2.15804 3.89782 2 3.5 2C3.10218 2 2.72064 2.15804 2.43934 2.43934C2.15804 2.72064 2 3.10218 2 3.5C2 3.89782 2.15804 4.27936 2.43934 4.56066C2.72064 4.84196 3.10218 5 3.5 5ZM15.41 7.58C15.77 7.94 16 8.44 16 9C16 9.55 15.78 10.05 15.41 10.41L10.41 15.41C10.05 15.77 9.55 16 9 16C8.45 16 7.95 15.78 7.58 15.41L0.59 8.42C0.22 8.05 0 7.55 0 7V2C0 0.89 0.89 0 2 0H7C7.55 0 8.05 0.22 8.41 0.58L15.41 7.58ZM11.54 1.71L12.54 0.71L19.41 7.58C19.78 7.94 20 8.45 20 9C20 9.55 19.78 10.05 19.42 10.41L14.04 15.79L13.04 14.79L18.75 9L11.54 1.71Z" fill="#A3A3A3"/>
                                </svg>
                            </div>
                        </div>
                    </div>
    
                    <div class="bottom_button bottom_button_dots">
                        <button style="color: #A3A3A3;" mat-icon-button [matMenuTriggerFor]="dotsMobFmView" aria-label="Example icon-button with a menu">
                        <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #dotsMobFmView="matMenu" class="ratio_value_menu_class" overlayPanelClass='ratio_value_menu' yPosition="above" xPosition="after">
                            <button mat-menu-item (click)="download(item, 'full')">
                                <mat-icon style="color:#fff;">download</mat-icon>
                                <span>
                                    {{ "Full" | translate }}
                                </span>
                            </button>
                            <ng-container *ngIf="item?.content_type.indexOf('video') != -1; else elseTemplateMovie">              
                                <button mat-menu-item *ngIf="!!item?.preview1080" (click)="download(item, 'conv')">
                                    <mat-icon style="color:#fff;">download</mat-icon>
                                    <span>
                                        {{ "Lite" | translate }}
                                    </span>
                                </button>
                                <button mat-menu-item *ngIf="!!item?.thumbnail" (click)="download(item, 'thum')">
                                    <mat-icon style="color:#fff;">download</mat-icon>
                                    <span>
                                        {{ "Thumbnail" | translate }}
                                    </span>
                                </button>
                            </ng-container>
                            <ng-template #elseTemplateMovie>
                                <button mat-menu-item *ngIf="!!item?.thumbnail" (click)="download(item, 'thum')">
                                    <mat-icon style="color:#fff;">download</mat-icon>
                                    <span>
                                        {{ "Lite" | translate }}
                                    </span>
                                </button>
                            </ng-template>
                            
                            <mat-divider></mat-divider>
                            <button mat-menu-item (click)="readyFile(item)">
                                <mat-icon style="color:#98F1BD;" *ngIf="!item?.is_ready_if_approved">
                                    done_outline
                                </mat-icon>
                                <mat-icon style="color:#98F1BD;" *ngIf="!!item?.is_ready_if_approved">
                                    done
                                </mat-icon>
                                <span *ngIf="!item?.is_ready_if_approved">{{ "Set as ready" | translate }}</span>
                                <span *ngIf="!!item?.is_ready_if_approved">{{ "Unset as ready" | translate }}</span>
                            </button>
                            <button mat-menu-item (click)="toShortCut(item)">
                                <mat-icon style="color:#fff;">
                                    <svg width="18" style="margin-right: 16px;" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9 0L5.778 5.004L0 6.516L3.78 11.142L3.438 17.1L9 14.931L14.562 17.1L14.22 11.142L18 6.516L12.222 5.004L9 0ZM6.642 7.2C7.2 7.2 7.65 7.65 7.65 8.217C7.65 8.48434 7.5438 8.74073 7.35476 8.92976C7.16573 9.1188 6.90934 9.225 6.642 9.225C6.075 9.225 5.625 8.775 5.625 8.217C5.625 7.65 6.075 7.2 6.642 7.2ZM11.367 7.2C11.925 7.2 12.375 7.65 12.375 8.217C12.375 8.48434 12.2688 8.74073 12.0798 8.92976C11.8907 9.1188 11.6343 9.225 11.367 9.225C10.8 9.225 10.35 8.775 10.35 8.217C10.35 7.65 10.8 7.2 11.367 7.2ZM6.3 11.25H11.7C11.25 12.339 10.179 13.05 9 13.05C7.821 13.05 6.75 12.339 6.3 11.25Z" fill="#fff"/>
                                    </svg>
                                </mat-icon>
                                <span>{{ "Shortcut" | translate }}</span>
                            </button>
                            <button *ngIf="item && canTranscribe(item?.filename)" mat-menu-item (click)="transcribeFileV2(item)">
                                <mat-icon style="color: #fff">record_voice_over</mat-icon>
                                <span>{{ 'Speech to Text' | translate }}</span>
                            </button>
                            <button mat-menu-item *ngIf="(!!data?.user?.is_root || data?.company?.permissions.includes('owner') || data?.company?.permissions.includes('admin')) && item.is_dir == 0 && understandFileType(item.content_type) == 'audio'" (click)="dubbingFile(item)">
                                <mat-icon style="color: #fff">settings_voice</mat-icon>
                                <span>{{ 'Dubbing' | translate }}</span>
                            </button>
                            <button *ngIf="(company | perm:'see_task_channels') && data?.task && data?.task?.uniqueChannels && item.is_dir == 0" mat-menu-item (click)="publishTo(item)">
                                <mat-icon style="color: #fff">publish</mat-icon>
                                <span>{{ 'Publish to' | translate }}</span>
                            </button>
                        </mat-menu>                   
                    </div>
                </div>
            </div>
        </ng-container>
    </ng-container>
</div>
