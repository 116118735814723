<mat-dialog-content class="mat-typography">
    <span class="head_title" (click)="log()">
        <b style="margin-right: 3.5px;" *ngIf="urls && toUploadUrls(urls)">{{"Manage future" | translate}} {{toUploadUrls(urls).length}} {{"posts" | translate}}</b>
        <ng-container *ngIf="freeContents && freeContents.length">
            <div class="head_split">|</div>
            <div>
                {{freeContents?.length}} {{"free slots" | translate}}
            </div>
        </ng-container>
        <div class="table_progress_wrp" [ngClass]="{'hidded': progress >= 100}">
            <mat-progress-bar
                class="table_progress"
                [color]="'primary'"
                [mode]="'determinate'"
                [value]="progress">
            </mat-progress-bar>
            <span class="percent">{{progress}}%</span>
        </div>
    </span>
    <div class="autos" [appDeviceClass]="'custom-scroll custom-scroll-t'">
        <form [formGroup]="filter" class="d-f-c form_with_pads form_with_valids" style="justify-content: space-between;">
            <div class="d-f-c">
                <div class="outlet_set add_reg" *ngIf="!!filter.get('channel_id')">
                    <div class="outlet_selected">
                        <div class="opened_trigger" (click)="opened = !opened">
                            <div class="label" [ngClass]="{'selected': filter.get('channel_id').value}">
                                {{ "Choose a channel" | translate }}
                            </div>
                            <ng-container *ngIf="filter.get('channel_id').value">
                                <app-outlet-line [channel]="getProfileById(filter.get('channel_id').value)" [company_id]="data?.company.id"></app-outlet-line>
                            </ng-container>
                            <mat-icon>arrow_drop_down</mat-icon>
                        </div>
                    </div>
                    <app-outlet-filter *ngIf="platforms && opened"
                        [company]="data.company" 
                        [company_id]="data.company.id" 
                        [multiple]="false" 
                        [n_a]="false" 
                        [needCst]="true" 
                        [form]="filter" 
                        [profiles]="false" 
                        [outletKey]="'channel_id'" 
                        [activeSections]="''" 
                        [platforms]="platforms" 
                        [showOutletBtn]="false" 
                        (SelectChange)="selectChange($event)"
                        (SelectOutletTab)="setOutletKey($event)">
                    </app-outlet-filter>
                </div>
    
                <mat-form-field appearance="legacy" class="ml-1 full_width_form white_legacy_select" style="width: 150px;">
                    <mat-label>{{"Type" | translate}}</mat-label>
                    <mat-select #contentTypesContainer formControlName="content_type_id">
                        <mat-option>
                            <ngx-mat-select-search 
                            placeholderLabel="{{ 'Search' | translate }}" 
                            noEntriesFoundLabel="{{ 'no matching types found' | translate }}"
                            [formControl]="contentTypeMoreControl"></ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let type of contentTypes$ | async" [value]="type.id">
                            {{ type.name | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>


            <div class="d-f-c" style="margin-left: 100px;">
                <mat-form-field appearance="legacy" class="full_width_form white_legacy_select" #tooltip="matTooltip"
                matTooltip="{{ 'Get posts that will be published on dates that not match selected shedules' | translate }}"
                [matTooltipPosition]="'above'">
                    <mat-label>{{"Select schedules" | translate}}</mat-label>
                    <mat-select [multiple]="true" [disableOptionCentering]="true" selectDir formControlName="schedules_ids">
                        <mat-option>
                            <ngx-mat-select-search 
                            placeholderLabel="{{ 'Search' | translate }}" 
                            noEntriesFoundLabel="{{ 'no matching schedules found' | translate }}"
                            [formControl]="regularsControl"></ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let regular of regulars$ | async" [value]="regular.id">
                            {{ regular.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <button mat-raised-button style="color: #fff; width: 255px;" class="ml-1" color="primary" (click)="filterPosts()" [disabled]="isLoad" #tooltip="matTooltip"
                matTooltip="{{ 'Get all posts that don`t match current regular schedule slots' | translate }}"
                [matTooltipPosition]="'above'">
                    {{ "Get unmatched posts" | translate }}
                </button>
                <!-- <button class="ml-1" mat-icon-button (click)="filterPosts()" style="color: #fff;">
                    <mat-icon style="transform: scaleX(-1);">reply</mat-icon>
                </button> -->
            </div>
        </form>
        <ng-container *ngIf="urls && toUploadUrls(urls)">
            <div class="items">      
                <ng-container *ngFor="let el of toUploadUrls(urls); let i = index">
                    <div class="items_line">
                        <div class="item post_item" [ngClass]="{'cp': !!el.content_url, 'selected': liveCollection.isSelected(el)}" (click)="goToPost($event, el)" (contextmenu)="openPostContext($event, el, i); $event.preventDefault(); $event.stopPropagation();">
                            <div class="item_top">
                                <div class="item_photo">
                                    <img *ngIf="el?.content_image" [src]="el?.content_image" alt="">
                                </div>
                                <div class="item_info_top">
                                    <div class="d-f-c" style="justify-content: space-between;">
                                        <app-outlet-line [channel]="el.channel" [company_id]="data?.company?.id"></app-outlet-line>

                                        <div>{{ getContentById(el.content_type_id)?.name }}</div>
                                    </div>
                                    <div class="item_name text_3_line">
                                        {{el?.content_name}}
                                    </div>
                                </div>
                            </div>
                            <div class="item_bottom">
                                <div class="d-f-c">
                                    <div class="status">
                                        {{ (getProfileStatusById(el.content_status_id) ? (getProfileStatusById(el.content_status_id)?.name+'_profile') : 'Content status') | translate }}
                                    </div>
                                    <ng-container *ngIf="el?.content_published_at > today && el?.content_published_at < todayEnd; else publishedAtTempl">
                                        <span class="date" #tooltip="matTooltip"
                                          matTooltip="{{ el?.content_published_at*1000 | date:'MMM d, y':'':data.activeLang | yearPipe }}"
                                          [matTooltipPosition]="'above'">
                                          {{ el?.content_published_at*1000 | date:'hh:mm a':'':data.activeLang }}
                                        </span>
                                    </ng-container>
                                    <ng-template #publishedAtTempl>
                                        <span class="date" *ngIf="el?.content_published_at && el?.content_published_at > 30000" #tooltip="matTooltip"
                                          matTooltip="{{ el?.content_published_at*1000 | date:'hh:mm a':'':data.activeLang | yearPipe }}"
                                          [matTooltipPosition]="'above'">
                                          {{ el?.content_published_at*1000 | date:'MM.dd.yy':'': data.activeLang }}
                                        </span>
                                    </ng-template>
                                </div>

                                <div class="d-f-c">
                                    <div class="id cp" (click)="$event.preventDefault(); $event.stopPropagation();" ngxClipboard [cbContent]="el.id" (cbOnSuccess)="copyLiveData('id')">
                                        {{el.id}}
                                    </div>
                                    <div class="view_count cp ml-5" *ngIf="!!el.content_views_count" (click)="$event.preventDefault(); $event.stopPropagation(); showStats(el)">
                                        <div class="d-f-c">
                                            <svg style="width: 9px; height: 10px" width="9" height="10" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0 10V3H2V10H0ZM3.5 10V0H5.5V10H3.5ZM7 10V6H9V10H7Z" fill="#C9C9C9"/>
                                            </svg>
                                            <span style="margin-left: 1px;">{{ el.content_views_count | shortNumber }}</span>
                                        </div>
                                    </div>
                                    <div class="view_count ml-5" *ngIf="!!el.content_concurrent_views_count">
                                        <div class="d-f-c">
                                            <mat-icon style="width: 10px; height: 10px; font-size: 10px; line-height: 10px; color: #C9C9C9;">person</mat-icon>
                                            <span style="margin-left: 1px;">{{ el.content_concurrent_views_count | shortNumber }}</span>
                                        </div>
                                    </div>
                                    <div class="view_count ml-5" *ngIf="!!el?.content_duration">
                                        <div class="d-f-c chat_timestamp">
                                            <mat-icon style="width: 10px; height: 10px; font-size: 10px; line-height: 10px; color: #C9C9C9; margin-right: 1px;">schedule</mat-icon>
                                            <span class="silver">{{(el?.content_duration | fullTime:false:true:true).silverTime}}</span>
                                            <span class="black">{{(el?.content_duration | fullTime:false:true:true).blackTime}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>            
                <div class="loading" *ngIf="isGettingFiles || isRealGettingFiles">
                    <mat-spinner></mat-spinner>
                </div>
            </div>
        </ng-container>
        
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <div class="bottom_btns">
        <div class="d-f-c">
            <mat-checkbox  style="color: #fff;" class="select_all_checkbox" *ngIf="liveCollection && urls && toUploadUrls(urls).length" (click)="$event.preventDefault; $event.stopPropagation()" (change)="$event ? masterToggle() : null"
            [checked]="liveCollection.hasValue() && isAllSelected()"
            [indeterminate]="liveCollection.hasValue() && !isAllSelected()"
            [aria-label]="checkboxLabel()">
                <span>{{ checkboxLabel() | translate }}</span>
            </mat-checkbox>
        </div>
        <div class="d-f-c">

            <button mat-raised-button (click)="close()">
                {{ "Close" | translate }}
            </button>

            <ng-container *ngIf="liveCollection.selected && liveCollection.selected.length">
                <button class="ml-1"  mat-raised-button color="warn" (click)="multiRetryPublishToFirst()" #tooltip="matTooltip"
                matTooltip="{{ 'Set new publishing time according to current corresponding (selected) regular schedules' | translate }}"
                [matTooltipPosition]="'above'" [disabled]="retryIsLoad">
                    {{ "Set new publishing time" | translate }}
                </button>
            </ng-container>
        </div>
    </div>
</mat-dialog-actions>


<ng-template #postContext let-contextData>
    <section class="card-menu">
      <div (click)="closePostContext(); editProfile(contextData.url.task, contextData.url)">
        <mat-icon>edit</mat-icon> 
        <span>{{ "Edit" | translate }}</span>
      </div>
      <div (click)="closePostContext(); deleteProfile(contextData.url, contextData.i)">
        <mat-icon>delete</mat-icon>
        <span>{{ 'Delete' | translate }}</span>
      </div>
    </section>
</ng-template>