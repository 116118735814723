<h2 mat-dialog-title class="dialog_title">
    <span>
        <ng-container *ngIf="data?.model; else elseTemplateIsAdd">
            {{ "Edit AI Model" | translate }}
        </ng-container>
        <ng-template #elseTemplateIsAdd>
            {{ "Add AI Model" | translate }}
        </ng-template>
    </span>
    <button class="ml-1" mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h2>
<mat-dialog-content class="mat-typography">
    <div class="container form_wrapper">
        <form [formGroup]="form">
            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ 'Name' | translate }}</mat-label>
                <input cdkFocusInitial formControlName="name" matInput placeholder="{{ 'Name' | translate }}">
            </mat-form-field>

            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ 'Model ID' | translate }}</mat-label>
                <input cdkFocusInitial formControlName="model_id" matInput placeholder="{{ 'Model ID' | translate }}">
            </mat-form-field>

            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ 'Input Token Price' | translate }}</mat-label>
                <input formControlName="token_price_input_1m" matInput type="number" placeholder="{{ 'Input Token Price' | translate }}">
            </mat-form-field>

            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ 'Output Token Price' | translate }}</mat-label>
                <input formControlName="token_price_output_1m" matInput type="number" placeholder="{{ 'Output Token Price' | translate }}">
            </mat-form-field>

            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ 'Price' | translate }}</mat-label>
                <input formControlName="token_price_1m" matInput type="number" placeholder="{{ 'Price' | translate }}">
            </mat-form-field>

            <div class="d-f-c" style="height: 40px; padding: 0 20px;">
                <mat-checkbox color="primary" formControlName="is_chat">{{"Available in chat" | translate}}</mat-checkbox>
            </div>
        </form>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button (click)="close()">
        {{ "Back" | translate }}
    </button>
    <button class="ml-1" (click)="submitForm()" mat-raised-button cdkFocusInitial color="primary" [disabled]="!form.valid">
        {{ "Save" | translate }}
    </button>
</mat-dialog-actions>
