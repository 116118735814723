<h2 mat-dialog-title class="dialog_title">
    <span>
        {{ "Adding a group" | translate }}
    </span>
    <button mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h2>
<mat-dialog-content class="mat-typography" dirEnter (dirSubmit)="!form.valid || isSubmit ? null : submitForm()">
    <div class="container form_wrapper" style="padding: 20px 0;">
        <form [formGroup]="form">
            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ "Choose a group" | translate }}</mat-label>
                <mat-select formControlName="group_id" required>
                    <mat-option *ngFor="let group of groups" [value]="group.id">
                        {{ group.name | translate }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field *ngIf="form.get('custom_id')" appearance="standard" class="full_width_form number_mat">
                <mat-label>{{ 'Custom id' | translate }}</mat-label>
                <input formControlName="custom_id" matInput type="number" placeholder="{{ 'Custom id' | translate }}">
                <mat-hint><div class="paste_data" *ngIf="isGetId" (click)="pasteIdVal()" style="cursor: pointer;"><mat-icon>content_paste</mat-icon>{{ customIdValue }}</div></mat-hint>
            </mat-form-field>
        </form>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button (click)="close()">
        {{ "Back" | translate }}
    </button>
    <button class="ml-1 d-f-btn" (click)="submitForm()" mat-raised-button cdkFocusInitial color="primary" [disabled]="!form.valid || isSubmit">
        <mat-spinner class="spin_16 mr-1" *ngIf="isSubmit"></mat-spinner>  
        {{ "Save" | translate }}
    </button>
</mat-dialog-actions>
