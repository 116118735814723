import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { FileService } from 'src/app/shared/services/rest/file.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { environment } from 'src/environments/environment';
import { SnackBarItem } from '../../../snack-bar/snack-bar-item';

@Component({
  selector: 'app-file-properties',
  templateUrl: './file-properties.component.html',
  styleUrls: ['./file-properties.component.scss']
})
export class FilePropertiesComponent extends BaseClass implements OnInit, OnDestroy {
  public host: any = environment.host;
  public imgRoute: any = ''
  public operations: any;
  public selectedIndex: FormControl = new FormControl(0);
  public activeLang: any;
  public activeFile: any;
  public forAll: FormControl = new FormControl(false);
  public count: number = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<FilePropertiesComponent>,
    private sm: StorageManagerService,
    private taskService: TaskService,
    private layoutService: LayoutService,
    private fileService: FileService,
    private languageService: LanguageService
  ) { super() }

  ngOnInit(): void {
    this.logData()
    if (this.data.filesNeedToSetProperties) {
      this.activeFile = this.data.filesNeedToSetProperties[0];
      this.activeFile.props = {
        is_ready_if_approved: '',
        operation_reminder_id: '',
        close_reminder: false
      }
      this.count = this.data.filesNeedToSetProperties.length
    }
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => this.imgRoute = el)
    )

    this.attachSubscriptions(
      this.languageService.getLangData().subscribe(resp => {
        this.activeLang = resp.active;
      })
    )

  }

  skipThis() {
    delete this.activeFile.props;
    delete this.activeFile.selectedCheck;

    this.goToNext()
  }

  skipAll() {
    this.count = 0
    this.close()
  }

  // withoutPropsFiles() {
  //   return this.data.filesNeedToSetProperties ? this.data.filesNeedToSetProperties.filter(x => !x.hasOwnProperty("props")) : []
  // }

  changeChecklist(e) {
    console.log(e)
    this.activeFile.selectedCheck = this.getCheck(e.value)
  }
  
  goToNext() {
    this.count--
    if (this.count > 0) {
      this.activeFile = this.data.filesNeedToSetProperties[this.data.filesNeedToSetProperties.length - this.count];
      this.activeFile.props = {
        is_ready_if_approved: '',
        operation_reminder_id: '',
        close_reminder: false
      }
      this.selectedIndex.patchValue(0)
    } else {
      this.close();
    }
  }

  yes() {
    this.activeFile.props.close_reminder = false;
    if (this.activeFile.upload && this.activeFile.upload.is_end && this.activeFile.upload.state == "DONE") {
      this.attachSubscriptions(
        this.fileService.editFile(this.activeFile.reportsFile.id, {is_ready_if_approved: !!this.activeFile.props.is_ready_if_approved ? 1 : 0, operation_reminder_id: this.activeFile.props.operation_reminder_id}, this.data.company.id).pipe(
          tap(res => {
            if (this.data.files.find(x => x.id == this.activeFile.reportsFile.id)) {
              this.data.files.find(x => x.id == this.activeFile.reportsFile.id).is_ready_if_approved = this.activeFile.props.is_ready_if_approved;
              this.data.files.find(x => x.id == this.activeFile.reportsFile.id).operation_reminder_id = this.activeFile.props.operation_reminder_id;
              if (!!this.activeFile.selectedCheck) {
                this.data.files.find(x => x.id == this.activeFile.reportsFile.id).selectedCheck = this.activeFile.selectedCheck
                this.data.files.find(x => x.id == this.activeFile.reportsFile.id).operation_reminder_name = this.activeFile.selectedCheck.text;
              }
            }
          })
        ).subscribe(resp => {
          // this.activeFile.reportsFile.operation_reminder_name = resp.operation_reminder_name;
          console.log("editFile YES", resp)
          this.goToNext();
        })
      )
    } else {
      this.activeFile.afterUploadData = this.activeFile.props
      this.goToNext();
    }
    // this.attachSubscriptions(
    //   this.taskService.deleteOperationReminderStatus(this.activeFile.selectedCheck.status.id).subscribe(resp => {
    //     this.activeFile.selectedCheck.status = resp
    //   })
    // )
  }

  no() {
    this.activeFile.props.close_reminder = true;
    if (this.activeFile.upload && this.activeFile.upload.is_end && this.activeFile.upload.state == "DONE") {
      this.attachSubscriptions(
        this.fileService.editFile(this.activeFile.reportsFile.id, {is_ready_if_approved: !!this.activeFile.props.is_ready_if_approved ? 1 : 0, operation_reminder_id: this.activeFile.props.operation_reminder_id}, this.data.company.id).pipe(
          switchMap(x => {
            return this.taskService.createOperationReminderStatus(this.data.work.id, this.activeFile.props.operation_reminder_id, this.data.company.id).pipe(
              tap(val => {
                this.activeFile.selectedCheck.status = val
              }),
              map(() => x)
            )
          }),
          tap(res => {
            if (this.data.files.find(x => x.id == this.activeFile.reportsFile.id)) {
              this.data.files.find(x => x.id == this.activeFile.reportsFile.id).is_ready_if_approved = this.activeFile.props.is_ready_if_approved;
              this.data.files.find(x => x.id == this.activeFile.reportsFile.id).operation_reminder_id = this.activeFile.props.operation_reminder_id;
              if (!!this.activeFile.selectedCheck) {
                this.data.files.find(x => x.id == this.activeFile.reportsFile.id).selectedCheck = this.activeFile.selectedCheck
                this.data.files.find(x => x.id == this.activeFile.reportsFile.id).operation_reminder_name = this.activeFile.selectedCheck.text;
              }
            }
          })
        ).subscribe(resp => {
          // this.activeFile.reportsFile.operation_reminder_name = resp.operation_reminder_name;
          console.log("editFile NO", resp)
          if (this.data.work.checklists.filter(b => !b.status).length > 0) {
            this.goToNext();
          } else {
            this.close()
          }
        })
      )
    } else {
      this.activeFile.afterUploadData = this.activeFile.props
      this.goToNext();
    }
  }

  logData() {
    console.log("FilePropertiesComponent", this.data)
  }

  showError() {
    this.layoutService.showSnackBar({name: ''}, marker("You do not have permission to do this"), SnackBarItem)
  }
  
  selectionChange($event:StepperSelectionEvent):void {
    console.log($event);
    this.selectedIndex.patchValue($event.selectedIndex);
  }

  next() {
    switch (this.selectedIndex.value) {
      case 0:
        if (this.activeFile.props.is_ready_if_approved === '') {
          this.layoutService.showSnackBar({name: ''}, marker("You must to set value what kind of file you are uploaded"), SnackBarItem)
        } else if (this.activeFile.props.is_ready_if_approved === 0) {
          this.skipThis()
        } else {
          this.selectedIndex.patchValue(this.selectedIndex.value + 1);
        }
        break;
      case 1:
        if (this.activeFile.props.operation_reminder_id === '') {
          this.layoutService.showSnackBar({name: ''}, marker("You must to set check point value"), SnackBarItem)
        } else {
          this.selectedIndex.patchValue(this.selectedIndex.value + 1);
        }
        break;
      default:
        break;
    }
  }

  back() {
    this.selectedIndex.patchValue(this.selectedIndex.value - 1);
  }

  onImgError(event){
    event.target.src = event.target.src
  }

  getCheck(id) {
    return this.data.work.checklists.find(x => x.id == id)
  }

  understandFileType(val) {
    if (!val) {
      return ""
    }
    
    if (val == 'application/pdf') {
      return 'pdf'
    } else if (val.indexOf("/") != -1) {
      return val.split('/')[0]
    } else {
      return ""
    }
  }

  close() {
    this.dialogRef.close({event: "close"})
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
