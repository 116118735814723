import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { ReplaySubject, forkJoin, fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, last, map, switchMap, tap } from 'rxjs/operators';
import { DeleteAlertComponent } from 'src/app/shared/global_components/delete-alert/delete-alert.component';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { AuthService } from 'src/app/shared/services/rest/auth.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dropbox-channel',
  templateUrl: './dropbox-channel.component.html',
  styleUrls: ['./dropbox-channel.component.scss']
})
export class DropboxChannelComponent extends BaseClass implements OnInit, OnDestroy {
  public host: any = environment.host;
  public imgRoute: any = '';
  public user: any = {};
  public user_id: number;
  public form: FormGroup;
  public isSubmit: boolean = false;
  public isFormChange: boolean = false;

  public profilesMoreControl: FormControl = new FormControl();
  public profiles$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public profiles: any[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DropboxChannelComponent>,
    private layoutService: LayoutService,
    private sm: StorageManagerService,
    private auth: AuthService,
    private taskService: TaskService,
    private bottomSheet: MatBottomSheet,
    private companyService: CompanyService,
    private fb: FormBuilder,
  ) { super() }

  ngOnInit(): void {
    console.log("DropboxChannelComponent", this.data)
    this.getCompany();
    this.getImgRoute();
    this.getCsrf();
    this.getUser();
    this.getProfiles();


    this.form = this.fb.group({
      company_id: this.data.company_id,
      youtube_sftp_dropbox_id: this.data.sftp.id,
      channel_id: [[], Validators.required]
    })

    this.attachSubscriptions(
      this.profilesMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchProfiles(resp))
    )

    this.attachSubscriptions(
      this.form.valueChanges.subscribe(() => this.isFormChange = true)
    )

    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        if (this.isFormChange) {
          this.layoutService.openBottomSheet(this.dialogRef);
        } else {
          this.close();
        }
      })
    )
  }

  getCompany() {
    this.attachSubscriptions(
      this.companyService.getCompany(this.data.company_id).subscribe(resp => {
        this.companyService.company$.next(resp[0]);
      })
    )
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  getCsrf() {
    this.attachSubscriptions(
      this.auth.$userToken.subscribe(resp => {
        this.user_id = resp.user_id;
        this.sm.localStorageSetItem("csrf_param", resp.csrf_param)
        this.sm.localStorageSetItem("csrf_token", resp.csrf_token)
      })
    )
  }

  getUser() {
    this.attachSubscriptions(
      this.auth.$user.subscribe(resp => {
        this.user = resp;
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  ifSftpHasChannel(id) {
    if (!this.data.sftp || !this.data.sftp.values || this.data.sftp.values.length == 0) {
      return false;
    } else {
      if (this.data.sftp.values.filter(x => x.channel_id == id).length > 0) {
        return true;
      } else {
        return false;
      }
    }
  }

  log() {
    console.log(this.form.value)
  }
  
  onSearchProfiles(resp) {
    this.log();
    if (!this.profiles) {
      return;
    }

    if (!resp) {
      this.profiles$.next(this.profiles.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }

    // filter the banks
    this.profiles$.next(
      this.profiles.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  getProfile(id) {
    if (!this.profiles) {
      return false;
    }
    return this.profiles.find(el => el.id == id)
  }  

  getProfiles() {
    this.attachSubscriptions(
      this.companyService.getAllProfilesDyn('1', this.data.company_id, '1', {platform_id: 1}).pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }
  
          return forkJoin(arr.map(x => this.companyService.getAllProfilesDyn(x, this.data.company_id, '200', {platform_id: 1}).pipe(map(u => u.body)))).pipe(
            last(),
            tap(values => {
              let conValues = [].concat(...values)
              this.profiles = conValues;
              this.profiles$.next(this.profiles.slice());
            }),
          )
        }),
      ).subscribe(resp => {
        console.log("getGroupProfiles resp", resp);
        console.log("getGroupProfiles profiles", this.profiles);
      })
    )
  }

  onSubmit2(is_replace:number = 0) {
    this.isSubmit = true;
    let formData = {...this.form.value}

    if (!!is_replace) {
      formData.is_replace = is_replace
    }

    this.attachSubscriptions(
      this.companyService.addSftpChannel(formData).subscribe(resp => {
        this.isSubmit = false;
        this.dialogRef.close({event: "update", data: resp});
      }, error => {
        if (is_replace == 0) {
          this.isSubmit = false;
          let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
            hasBackdrop: true,
            backdropClass: 'bottom-sheed-backdrop',
            data: {
              msg: `${this.getProfile(this.form.get('channel_id').value).name} is already assigned to another Youtube Sftp Dropbox.`,
              msg_1: "Do you want to transfer it to this dropbox?",
              btn_no: "No",
              btn_yes: "Yes"
            }
          });
      
          deleteAlert.afterDismissed().subscribe( data => {
            if (data && data.message == 'no') {
              console.log("no");
              return
            } else if (data && data.message == 'yes') {
              this.onSubmit2(1)
            }
          });
        } else {
          this.isSubmit = false;
          this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
        }
      })
    )
  }

  onSubmit(is_replace:number = 0) {
    let multiData = [];
    let formData = {...this.form.value}

    delete formData.channel_id;

    if (!!is_replace) {
      formData.is_replace = is_replace;
    }

    this.form.value.channel_id.forEach(chID => {
      let x = {
        "path": '/api/youtube-sftp-dropbox-to-channel/',
        "query": {},
        "method": "POST",
        "body": Object.assign({
          [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
          channel_id: chID
        }, formData)
      }
      multiData.push(x);
    });


    this.attachSubscriptions(
      this.taskService.multiRequest(multiData).subscribe(resp => {
        console.log("multiRequest", resp);
        if (resp.filter(x => x.status != 201).length == 0) {
          this.isSubmit = false;
          this.dialogRef.close({event: "update", data: resp});
        } else {
          let arr = [];
          resp.forEach((u, i) => {
            if (u.status != 201) {
              arr.push(this.form.value.channel_id[i]);
            }
          })

          this.isSubmit = false;
          console.log("NOT sftp sbmt arr", arr);
          let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
            hasBackdrop: true,
            backdropClass: 'bottom-sheed-backdrop',
            data: {
              msg: `${arr.length} channel(s) is already assigned to another Youtube Sftp Dropbox.`,
              msg_1: "Do you want to transfer all of this channels to this dropbox?",
              btn_no: "No",
              btn_yes: "Yes"
            }
          });
      
          deleteAlert.afterDismissed().subscribe( data => {
            if (data && data.message == 'no') {
              console.log("no");
              this.isSubmit = false;
              this.dialogRef.close({event: "update", data: resp});
              return
            } else if (data && data.message == 'yes') {
              this.form.patchValue({
                channel_id: arr
              })
              this.onSubmit(1)
            }
          });
        }
      }, error => {
        this.isSubmit = false;
        this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
      })
    )
  }
  
  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
