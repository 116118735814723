import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { FileService } from 'src/app/shared/services/rest/file.service';
import { CreateArchiveComponent } from '../create-archive/create-archive.component';
import { map } from 'rxjs/operators';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { DeleteAlertComponent } from '../../../delete-alert/delete-alert.component';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { SnackBarItem } from '../../../snack-bar/snack-bar-item';

@Component({
  selector: 'app-folder-archives',
  templateUrl: './folder-archives.component.html',
  styleUrls: ['./folder-archives.component.scss']
})
export class FolderArchivesComponent extends BaseClass implements OnInit, OnDestroy {
  public folders = [
    {
      name: "Sources",
      id: 0,
      location: "/sources",
      over: false,
      rootDir: true
    },
    {
      name: "To Approve",
      id: 1,
      location: "/to_approve",
      over: false,
      rootDir: true
    },
    {
      name: "Ready Files",
      id: 2,
      location: "/ready_files",
      over: false,
      rootDir: true
    },
    {
      name: "Approved",
      id: 3,
      location: "/approved",
      over: false,
      rootDir: true
    },
    {
      name: "Project",
      id: 4,
      location: "/project",
      over: false,
      rootDir: true
    },
    {
      name: "Trash",
      id: 5,
      location: "/trash",
      over: false,
      rootDir: true
    }
  ]
  public fileLocationString: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<FolderArchivesComponent>,
    private fileService: FileService,
    private dialog: MatDialog,
    private bottomSheet: MatBottomSheet,
    public layoutService: LayoutService
  ) { super() }

  ngOnInit(): void {
    console.log("FolderArchivesComponent", this.data)

    this.fileLocationString = this.data.folder.location
    console.log(this.fileLocationString)
    if (!!this.fileLocationString) {
      if (this.data.folder.location == '/') {
        this.fileLocationString = "Root"
      } else {
        this.folders.forEach(el => {
          this.fileLocationString = (this.fileLocationString as any).replaceAll(el.location, el.name)
          console.log(this.fileLocationString);
        })
        this.fileLocationString = (this.fileLocationString as any).replaceAll('/', " / ")
      }
    }

  }

  getPartName(queryString, item) {
    let url = decodeURIComponent(queryString);
    console.log("url", url)

    let start = url.indexOf(`/${item.id}/`) + item.id.toString().length + 2
    let end = url.indexOf("?")

    return url.slice(start, end)  
  }

  openArch(item) {
    item.open = !item.open

    if (item.open) {
      this.attachSubscriptions(
        this.fileService.downloadCreatedArchive(item.id, this.data.company_id).subscribe(resp => {
          let urls = [];
          if (resp.download_url) {
            resp.download_url.forEach(element => {
  
              let url = decodeURIComponent(element);        
              let start = url.indexOf(`/${item.id}/`) + item.id.toString().length + 2
              let end = url.indexOf("?")
  
              urls.push({
                is_clicked: false,
                url: element,
                name: url.split('?')[0].split('#')[0].split('/').pop()
                // name: url.slice(start, end) 
              })
            });
            item.download_url = urls
            console.log(item)
          }
        })
      )
    }
  }

  deleteArchive(item, i) {
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: {},
        target: marker("archive")
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        this.attachSubscriptions(
          this.fileService.deleteArchive(item.id, this.data.company_id).subscribe(resp => {
            this.data.folder.archives.splice(i, 1);
          }, error => {
            this.layoutService.showSnackBar({name: 'Delete failed'}, error, SnackBarItem)
          })
        )
      }
    });
  }

  getArchives() {
    let filterProgress:any = {}
    if (!!this.data.task_id) {
      filterProgress.task_id = this.data.task_id
    }
    if (!!this.data.task_operation_id) {
      filterProgress.task_operation_id = this.data.task_operation_id
    }
    this.fileService.getFilesArchives(this.data.company_id, [this.data.folder.id], filterProgress).pipe(map(x => x[0])).subscribe(file => {
      console.log("getArchives", file)
      this.data.folder.archives = file.archives;
    })
  }

  generateCombinedUrls(downloadUrls: any[]): string {
    // Используйте метод map для извлечения URL'ов
    const urls = downloadUrls.map(part => part.url);
  
    // Используйте метод join для объединения URL'ов в одну строку
    return urls.join('');
  }

  copyUrls(type) {
    this.layoutService.showSnackBar({name: type + " download url(s)"}, marker("Copied"), SnackBarItem)
  }

  downloadArchive(archive) {
    if (archive.download_url.length) {
      async function loop() {
        for (let i = 0; i < archive.download_url.length; i++) {
          await new Promise((res) => setTimeout(res, i == 0 ? 0 : 4000)); // пауза перед очередным
          console.log(archive.download_url[i], archive.download_url); // вывод блока
          // return
          if (archive.download_url[i]) {
            let a = document.createElement('a');
            a.setAttribute('href', archive.download_url[i].url);
            a.setAttribute('download','download');
            // a.target = "_blank"
            a.click();
          }
        }
      }
      loop();
    }
  }

  generateNewArchive() {
    const dialogRef = this.dialog.open(CreateArchiveComponent, {
      data: {
        company: this.data.company,
        target_company_id: this.data.target_company_id,
        company_id: this.data.company_id,
        folder: this.data.folder
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log(result)
        if (!!result && result.event == 'create') {
          this.getArchives()
        }
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false});
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
