import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { DeviceService } from '../services/common/device.service';

@Directive({
  selector: '[appDeviceClass]'
})
export class DeviceClassDirective implements OnInit {
  @Input() appDeviceClass: string;

  constructor(private deviceService: DeviceService, private elementRef: ElementRef) { }

  ngOnInit() {
    if (!this.deviceService.isMac) {
      this.elementRef.nativeElement.classList.add(...this.appDeviceClass.split(' '));
    }
  }
}
