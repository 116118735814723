<h2 mat-dialog-title class="dialog_title">
    <span>
        {{ "Adding field" | translate }}: <b>{{data?.quest?.name}}</b>
    </span>
    <button mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h2>
<mat-dialog-content class="mat-typography">
    <div class="container">
        <form [formGroup]="form">

            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ "Choose a type" | translate }}</mat-label>
                <mat-select formControlName="type" required>
                    <mat-option *ngFor="let type of types" [value]="type.name">
                        {{ type.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
          

            <angular-editor *ngIf="form?.get('rules')" formControlName="rules" placeholder="{{ 'Rules' | translate }}" ></angular-editor>


            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ 'Name' | translate }}</mat-label>
                <input formControlName="name" required matInput placeholder="{{ 'Name' | translate }}">
            </mat-form-field>

            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ 'Order' | translate }}</mat-label>
                <input formControlName="order" matInput required type="number" placeholder="{{ 'Order' | translate }}">
            </mat-form-field>
        
        </form>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-raised-button (click)="close()">
        {{ "Back" | translate }}
    </button>
    <button class="ml-1 d-f-btn" (click)="onSubmit()" mat-raised-button color="primary" [disabled]="!form.valid || isSubmit">
        <mat-spinner class="spin_16 mr-1" *ngIf="isSubmit"></mat-spinner>  
        {{ "Add" | translate }}
    </button>
</mat-dialog-actions>