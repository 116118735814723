import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpRestService } from '../common/http-rest.service';

@Injectable({
  providedIn: 'root'
})
export class RootService {

  constructor(
    private http: HttpRestService,
    private httpClient: HttpClient
    ) { }


    getRootCompany(page) {
      return this.http.executeGet<any>(`/root/company/`, true, {
        observe: 'response',
        params: new HttpParams().set('per-page', '200').set('page', page).set("expand", "owner,avatarFile,translations")
      })
    }
    
    getRootUsers(page) {
      return this.http.executeGet<any>(`/root/user/`, true, {
        observe: 'response',
        params: new HttpParams().set('page', page).set('per-page', '200')
      })
    }
  
    changeRoot(id, val) {
      return this.http.executePost<any>(`/root/user/${id}/`, {is_root: val})
    }

    getCompanyTranslation(id) {
      return this.http.executeGet<any>(`/root/company-translation/`, true, {
        params: new HttpParams().set("company_id", id)
      })
    }
  
    createCompanyTranslation(data) {
      return this.http.executePost<any>('/root/company-translation/', data)
    }
  
    updateCompanyTranslation(id, data) {
      return this.http.executePost<any>(`/root/company-translation/${id}/`, data)
    }

    changeLimit(id, data) {
      return this.http.executePost<any>(`/root/company/${id}/`, data)
    }

    getLocks() {
      return this.http.executeGet<any>(`/root/lock/list/`)
    }

    getRootServers(page, filter?) {
      let filter_params = new HttpParams().set('page', page).set("per-page", '200').set('expand','serverLog')

      if (filter) {
        Object.keys(filter).forEach(element => {
          if (filter[element] !== '') {
            filter_params = filter_params.set(element, filter[element]);
          }
        });
      }

      return this.http.executeGet<any>(`/root/server/`, true, {
        observe: 'response',
        params: filter_params
      })
    }

    editRootServer(id, data) {
      return this.http.executePost<any>(`/root/server/${id}/`, data)
    }

    getRootTaskTypes() {
      return this.http.executeGet<any>(`/root/task-status/`, true, {
        params: new HttpParams().set("per-page", '200').set('expand','company')
      })
    }
    
    getRootTaskType(id) {
      return this.http.executeGet<any>(`/root/task-status/${id}/`)
    }

    addRootTaskType(data) {
      return this.http.executePost<any>(`/root/task-status/`, data)
    }

    editRootTaskType(id, data) {
      return this.http.executePost<any>(`/root/task-status/${id}/`, data)
    }

    deleteRootTaskType(id) {
      return this.http.executeDelete<any>(`/root/task-status/${id}/`)
    }

    getRootJobTypes() {
      return this.http.executeGet<any>(`/root/operation/`, true, {
        params: new HttpParams().set("per-page", '200').set('expand','company')
      })
    }

    getRootJobType(id) {
      return this.http.executeGet<any>(`/root/operation/${id}/`)
    }

    addRootJobType(data) {
      return this.http.executePost<any>(`/root/operation/`, data)
    }

    editRootJobType(id, data) {
      return this.http.executePost<any>(`/root/operation/${id}/`, data)
    }

    deleteRootJobType(id) {
      return this.http.executeDelete<any>(`/root/operation/${id}/`)
    }

    lockClear(data) {
      return this.http.executePost<any>(`/root/lock/clear/`, data)
    }
}
