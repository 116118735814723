<ng-container *ngIf="!is_mobile; else elseTemplateNotMobile">
    <mat-dialog-content class="mat-typography">
        <div class="title_modal" [ngClass]="{'big': tab == 1}">
            <span (click)="log()">
                {{ "Activity" | translate }}
            </span>
    
            <div class="activity_tabs">
                <div class="activity_tab" [ngClass]="{'active': tab == 0}" (click)="changeTab(0)">{{"Snapshot" | translate}}</div>
                <div class="activity_tab" [ngClass]="{'active': tab == 1}" (click)="changeTab(1)">{{"Feed" | translate}}</div>
            </div>
    
            <ng-container *ngIf="tab == 0; else elseTemplateFilter">
                <form [formGroup]="filter" class="perf_down">                     
                    <div class="top_filter">
                      <div class="simple_filter">
                        <div class="simple_filter_snippet" (click)="activityRange.open()">
                            
                            <span>{{filters[activeFilterId.value].name}}</span>
        
                            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_2474_38987)">
                                <path d="M7.91699 3.33325L2.08366 3.33325L5.00033 7.91658L7.91699 3.33325Z" fill="black"/>
                                </g>
                                <defs>
                                <clipPath id="clip0_2474_38987">
                                <rect width="10" height="10" fill="white" transform="translate(10) rotate(90)"/>
                                </clipPath>
                                </defs>
                            </svg>
                        </div>
        
                        <mat-form-field appearance="legacy" class="dash_filter_simple">
                          <mat-label>{{ "Range" | translate }}</mat-label>
                          <mat-select [multiple]="false" #activityRange [disableOptionCentering]="true" selectDir [formControl]="activeFilterId" (selectionChange)="filterActivity()">                
                            <ng-container *ngFor="let fil of filters">
                                <mat-option [value]="fil.id" class="range_filter_options" [ngClass]="{'active': activeFilterId.value == fil.id}">
                                    {{ fil.name | translate}}
                                </mat-option>
                                <mat-divider *ngIf="fil.divider"></mat-divider>
                            </ng-container>
                          </mat-select>
                        </mat-form-field>
                      </div>
        
                    </div>
                </form>
            </ng-container>
            <ng-template #elseTemplateFilter>
                <div class="empty_block"></div>
                <form [formGroup]="notFilter" class="filter_wrp" (ngSubmit)="filterNots()">
                    <div class="actions_filter">
                        <mat-form-field appearance="legacy" class="filter_form group">
                            <mat-label>{{ "Group" | translate }}</mat-label>
                            <mat-select [disableOptionCentering]="true" selectDir formControlName="group_id">
                                <mat-option>
                                  <ngx-mat-select-search 
                                      placeholderLabel="{{ 'Search' | translate }}" 
                                      noEntriesFoundLabel="{{ 'no matching group found' | translate }}"
                                      [formControl]="groupsControl"></ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let group of groups$ | async" [value]="group.id">
                                    {{ group.name | translate }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="standard" class="filter_form ml-1">
                            <mat-label>{{ "By actions" | translate }}</mat-label>
                            <mat-select formControlName="action">
                                <mat-option *ngFor="let action of actions" [value]="action">
                                    {{ action | translate }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
        
                        <mat-form-field *ngIf="data?.company?.permissions.includes('owner') || data?.company?.permissions.includes('admin') || data?.company?.permissions.includes('manager')" appearance="standard" class="filter_form ml-1">
                            <mat-label>{{ "By employee" | translate }}</mat-label>
                            <mat-select formControlName="created_employee_id">
                                <mat-option>
                                    <ngx-mat-select-search 
                                        placeholderLabel="{{ 'Search' | translate }}" 
                                        noEntriesFoundLabel="{{ 'no matching member found' | translate }}"
                                        [formControl]="execControl"></ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let employee of notEmployees$ | async" [value]="employee.id">
                                    <app-user [class]="'user_div'" [employee]="employee"></app-user>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
        
                        <ng-container *ngIf="data.header">
                            <mat-form-field appearance="standard" class="filter_form ml-1">
                                <mat-label>{{ "By work type" | translate }}</mat-label>
                                <mat-select formControlName="task_operation_operation_id" multiple>
                                    <mat-option *ngFor="let op of operations" [value]="op.id">
                                        {{ op.name | translate }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field appearance="standard" class="filter_form ml-1">
                                <mat-label>{{ "By work status" | translate }}</mat-label>
                                <mat-select formControlName="task_operation_status_id" multiple>
                                    <mat-option *ngFor="let opS of operationStatuses" [value]="opS.id">
                                        {{ opS.name | translate }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </ng-container>
    
                        <mat-form-field appearance="legacy" class="example-form-field filter_form ml-1">
                            <mat-label>{{ 'Date' | translate }}</mat-label>
                            <mat-date-range-input  [rangePicker]="rangePicker">
                              <input formControlName="created_at_from" matStartDate placeholder="{{ 'Date from' | translate }}">
                              <input formControlName="created_at_to" matEndDate placeholder="{{ 'Date to' | translate }}">
                            </mat-date-range-input>
                            <mat-datepicker-toggle matPrefix [for]="rangePicker"></mat-datepicker-toggle>
                            <mat-date-range-picker #rangePicker>
                              <mat-date-range-picker-actions>
                                <button mat-button matDateRangePickerCancel>Cancel</button>
                                <button mat-raised-button color="primary" matDateRangePickerApply>Apply</button>
                              </mat-date-range-picker-actions>
                            </mat-date-range-picker>
                          </mat-form-field>
                    </div>
    
                    <div>
                        <mat-button-toggle-group class="view_group" (change)="changeView($event)" [value]="notFilter.get('original_action_id').value == '0' ? 'member' : 'all'">
                            <mat-button-toggle value="all">{{ "All" | translate }}</mat-button-toggle>
                            <mat-button-toggle value="member">{{ "By members" | translate }}</mat-button-toggle>
                        </mat-button-toggle-group>
                        <button mat-button type="button" (click)="filterReset()">{{ "Reset" | translate }}</button>
                        <button mat-raised-button style="padding: 0 5px !important;" type="submit" color="primary">{{ "Filter" | translate }}</button>
                    </div>
                </form>
            </ng-template>
        </div>
        <div class="container" [ngClass]="{'alert_wrp': tab == 1}">
            <ng-container *ngIf="tab == 0; else elseTemplateTab">     
                <div class="outlets_dash" [appDeviceClass]="'custom-scroll custom-scroll-t'"
                infinite-scroll
                [infiniteScrollDisabled]="isLoad"
                [scrollWindow]="false"
                [infiniteScrollUpDistance]="scrollUpDistance"
                [infiniteScrollThrottle]="throttle"
                (scrolled)="onDown($event)">
                    <form [formGroup]="filter" class="outlets_dash_header">
                        <div class="column empl_col">
                            <div class="title">
                                {{ "Stakeholder" | translate }}
                            </div>
                            <mat-form-field appearance="outline" class="outlet_filter">
                                <mat-select [disableOptionCentering]="true" selectDir formControlName="employee_id" (selectionChange)="selectChange($event)">
                                    <mat-option>
                                        <ngx-mat-select-search 
                                        placeholderLabel="{{ 'Search' | translate }}" 
                                        noEntriesFoundLabel="{{ 'no matching stakeholders found' | translate }}"
                                        [formControl]="employeesMoreControl"></ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option [value]="0">
                                        <div class="user_div">
                                            <img style="visibility: hidden;" src="" alt="">
                                            <span>
                                                {{ "All stakeholders" | translate }}
                                            </span>
                                        </div>
                                    </mat-option>
                                    <mat-option *ngFor="let employee of employees$ | async" [value]="employee.id">
                                        <app-user [class]="'user_div'" [employee]="employee"></app-user>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="column task_col">
                            <div class="title">
                                {{ "Cards" | translate }}
                            </div>
                            <div class="sub_titles">
                                <div class="sub">{{ "Created" | translate }}</div>
                                <div class="sub">{{ "Edited" | translate }}</div>
                            </div>
            
                        </div>
                        <div class="column job_col">
                            <div class="title">
                                {{ "Jobs" | translate }}
                            </div>
                            <div class="sub_titles">
                                <div class="sub">{{ "Created" | translate }}</div>
                                <div class="sub">{{ "Edited" | translate }}</div>
                            </div>
                        </div>
                        <div class="column chat_col">
                            <div class="title">
                                {{ "Chats" | translate }}
                            </div>
                            <div class="sub_titles">
                                <div class="sub">{{ "Created" | translate }}</div>
                                <div class="sub">{{ "Edited" | translate }}</div>
                            </div>
                        </div>
                        <div class="column ass_col">
                            <div class="title">
                                {{ "Assignments" | translate }}
                            </div>
                            <div class="sub_titles">
                                <div class="sub" style="width: 100%;">{{ "Recieved / performed" | translate }}</div>
                            </div>
                        </div>
                        <div class="column file_col">
                            <div class="title">
                                {{ "Files" | translate }}
                            </div>
                            <div class="sub_titles">
                                <div class="sub">{{ "Created" | translate }}</div>
                                <div class="sub">{{ "Edited" | translate }}</div>
                            </div>
                        </div>
                        <div class="column post_col">
                            <div class="title">
                                {{ "Posts" | translate }}
                            </div>
                            <div class="sub_titles">
                                <div class="sub">{{ "Added" | translate }}</div>
                                <div class="sub">{{ "Edited" | translate }}</div>
                            </div>
                        </div>
                        <div class="exp_col"></div>
                    </form>
            
                    <div class="outlet" *ngFor="let item of activity">
                        <div class="main" *ngIf="!item.is_expand">
                            <div class="column empl_col">
                                <app-user [class]="'user_div'" [employee]="getEmployee(item.employee_id)"></app-user>
                            </div>
                            <div class="column task_col">
                                <span class="normal">
                                    {{ +item?.tasks_created | number:'':'fr-FR'}}
                                </span>
                            </div>
                            <div class="column job_col">
                                <span class="normal">
                                    {{ +item?.task_operations_created | number:'':'fr-FR'}}
                                </span>
                            </div>
                            <div class="column chat_col">
                                <span class="normal">
                                    {{ +item?.discussions_created | number:'':'fr-FR'}}
                                </span>
                            </div>
                            <div class="column ass_col">
                                <span class="normal">
                                    {{ (+item?.task_operations_assigned + +item?.discussions_assigned) | number:'':'fr-FR'}}
                                </span>
                            </div>
                            <div class="column file_col">
                                <span class="normal">
                                    {{ +item?.files_uploaded | number:'':'fr-FR'}}
                                </span>
                            </div>
                            <div class="column post_col">
                                <span class="normal">
                                    {{ +item?.task_channels_combined | number:'':'fr-FR'}}
                                </span>
                            </div>
                            <div class="exp_col">
                                <div class="toggler" (click)="item.is_expand = !item.is_expand">
                                    <svg style="width: 19px; height: 10px;" width="19" height="11" viewBox="0 0 19 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 1L9.5 9.5L18.5 1" stroke="black"/>
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div class="exp_main" *ngIf="item.is_expand">
                            <div class="column empl_col">
                                <app-user [class]="'user_div'" [employee]="getEmployee(item.employee_id)"></app-user>
                            </div>
                            <div class="column task_col">
                                <div class="name_val big_name_val" style="padding-top: 0;">
                                    <div>
                                        <span>{{"Created"|translate}}</span>
                                        <span>{{ +item?.tasks_created | number:'':'fr-FR'}}</span>
                                    </div>
                                </div>
                                <div class="name_val" *ngIf="item?.tasks_created_direct > 0">
                                    <div>
                                        <span>{{"Directly"|translate}}</span>
                                        <span>{{ +item?.tasks_created_direct | number:'':'fr-FR'}}</span>
                                    </div>
                                </div>
                                <div class="name_val" *ngIf="item?.tasks_created_file > 0">
                                    <div>
                                        <span>{{"By the file"|translate}}</span>
                                        <span>{{ +item?.tasks_created_file | number:'':'fr-FR'}}</span>
                                    </div>
                                </div>
                                <!-- <div class="name_val exp_name_val">
                                    <div>
                                        <span class="cp" (click)="item.is_task_template = !item.is_task_template">{{"By template"|translate}} {{item.is_task_template ? "—" : "+"}}</span>
                                        <span>#</span>
                                    </div>
                                    <div class="sub_name_val" *ngIf="item?.is_task_template">
                                        <div>
                                            <span>{{"By the files"|translate}}</span>
                                            <span>#</span>
                                        </div>
                                        <div>
                                            <span>{{"Directly"|translate}}</span>
                                            <span>#</span>
                                        </div>
                                    </div>
                                </div> -->
        
                                <!-- <div class="group">
                                    {{"By task type"|translate}}:
                                </div>
                                <div class="name_val exp_name_val">
                                    <div>
                                        <span class="cp" (click)="item.is_task_content = !item.is_task_content">{{"Content"|translate}} {{item.is_task_content ? "—" : "+"}}</span>
                                        <span>#</span>
                                    </div>
        
                                    <div class="sub_name_val" *ngIf="item?.is_task_content">
                                        <div>
                                            <span>{{"Directly"|translate}}</span>
                                            <span>#</span>
                                        </div>
                                        <div>
                                            <span>{{"By scenario"|translate}}</span>
                                            <span>#</span>
                                        </div>
                                    </div>
                                </div> -->
                                <div class="name_val big_name_val">
                                    <div>
                                        <span>{{"Edited"|translate}}</span>
                                        <span>{{ +item?.tasks_updated | number:'':'fr-FR'}}</span>
                                    </div>
                                </div>
                                <div class="name_val exp_name_val" *ngIf="item?.tasks_updated_title > 0">
                                    <div>
                                        <span class="cp" (click)="item.is_task_title = !item.is_task_title">{{"Title"|translate}} {{item.is_task_title ? "—" : "+"}}</span>
                                        <span>{{ +item?.tasks_updated_title | number:'':'fr-FR'}}</span>
                                    </div>
                                    
                                    <div class="sub_name_val" *ngIf="item?.is_task_title">
                                        <div *ngIf="item?.tasks_updated_title_form > 0">
                                            <span>{{"In form"|translate}}</span>
                                            <span>{{ +item?.tasks_updated_title_form | number:'':'fr-FR'}}</span>
                                        </div>
                                        <div *ngIf="item?.tasks_updated_title_direct > 0">
                                            <span>{{"Directly"|translate}}</span>
                                            <span>{{ +item?.tasks_updated_title_direct | number:'':'fr-FR'}}</span>
                                        </div>
                                    </div>
                                </div>
        
                                <div class="name_val" *ngIf="item?.tasks_updated_description > 0">
                                    <div>
                                        <span>{{"Description"|translate}}</span>
                                        <span>{{ +item?.tasks_updated_description | number:'':'fr-FR'}}</span>
                                    </div>
                                </div>
                                <div class="name_val" *ngIf="item?.tasks_updated_status > 0">
                                    <div>
                                        <span>{{"Card type"|translate}}</span>
                                        <span>{{ +item?.tasks_updated_status | number:'':'fr-FR'}}</span>
                                    </div>
                                </div>
                                <div class="name_val" *ngIf="item?.tasks_updated_group > 0">
                                    <div>
                                        <span>{{"Group"|translate}}</span>
                                        <span>{{ +item?.tasks_updated_group | number:'':'fr-FR'}}</span>
                                    </div>
                                </div>
       
                                <div class="name_val exp_name_val" *ngIf="item?.tasks_updated_assignment > 0">
                                    <div>
                                        <span class="cp" (click)="item.is_task_stake = !item.is_task_stake">{{"Stakeholders"|translate}} {{item.is_task_stake ? "—" : "+"}}</span>
                                        <span>{{ +item?.tasks_updated_assignment | number:'':'fr-FR'}}</span>
                                    </div>
                                    
                                    <div class="sub_name_val" *ngIf="item?.is_task_stake">
                                        <div *ngIf="item?.tasks_updated_assignment_employee > 0">
                                            <span>{{"Individuals"|translate}}</span>
                                            <span>{{ +item?.tasks_updated_assignment_employee | number:'':'fr-FR'}}</span>
                                        </div>
                                        <div *ngIf="item?.tasks_updated_assignment_client > 0">
                                            <span>{{"Clients"|translate}}</span>
                                            <span>{{ +item?.tasks_updated_assignment_client | number:'':'fr-FR'}}</span>
                                        </div>
                                        <div *ngIf="item?.tasks_updated_assignment_partner > 0">
                                            <span>{{"Workspaces"|translate}}</span>
                                            <span>{{ +item?.tasks_updated_assignment_partner | number:'':'fr-FR'}}</span>
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="name_val" *ngIf="item?.tasks_updated_id > 0">
                                    <div>
                                        <span>{{"ID"|translate}}</span>
                                        <span>{{ +item?.tasks_updated_id | number:'':'fr-FR'}}</span>
                                    </div>
                                </div>
                                <div class="name_val exp_name_val" *ngIf="item?.tasks_updated_relation > 0">
                                    <div>
                                        <span class="cp" (click)="item.is_task_relations = !item.is_task_relations">{{"Relations"|translate}} {{item.is_task_relations ? "—" : "+"}}</span>
                                        <span>{{ +item?.tasks_updated_relation | number:'':'fr-FR'}}</span>
                                    </div>
                                    
                                    <div class="sub_name_val" *ngIf="item?.is_task_relations">
                                        <div *ngIf="item?.tasks_updated_relation_consist_of > 0">
                                            <span>{{"Consist of"|translate}}</span>
                                            <span>{{ +item?.tasks_updated_relation_consist_of | number:'':'fr-FR'}}</span>
                                        </div>
                                        <div *ngIf="item?.tasks_updated_relation_part_of > 0">
                                            <span>{{"Part of"|translate}}</span>
                                            <span>{{ +item?.tasks_updated_relation_part_of | number:'':'fr-FR'}}</span>
                                        </div>
                                        <div *ngIf="item?.tasks_updated_relation_related > 0">
                                            <span>{{"Related"|translate}}</span>
                                            <span>{{ +item?.tasks_updated_relation_related | number:'':'fr-FR'}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="name_val" *ngIf="item?.tasks_updated_add_parameter_value > 0">
                                    <div>
                                        <span>{{"Tagged"|translate}}</span>
                                        <span>{{ +item?.tasks_updated_add_parameter_value | number:'':'fr-FR'}}</span>
                                    </div>
                                </div>
                                <!-- <div class="two_name_val">
                                    <div>
                                        <div>{{"In the car"|translate}}</div>
                                        <div class="small_two">{{"Environment"|translate}}</div>
                                    </div>
                                    <div>
                                        #
                                    </div>
                                </div>
                                <div class="two_name_val">
                                    <div>
                                        <div>{{"Running"|translate}}</div>
                                        <div class="small_two">{{"Activity"|translate}}</div>
                                    </div>
                                    <div>
                                        #
                                    </div>
                                </div>
                                <div class="two_name_val">
                                    <div>
                                        <div>{{"Comedy"|translate}}</div>
                                        <div class="small_two">{{"Genre"|translate}}</div>
                                    </div>
                                    <div>
                                        #
                                    </div>
                                </div> -->
                                <div class="name_val" *ngIf="item?.tasks_updated_delete_parameter_value > 0">
                                    <div>
                                        <span>{{"Tag Deleted"|translate}}</span>
                                        <span>{{ +item?.tasks_updated_delete_parameter_value | number:'':'fr-FR'}}</span>
                                    </div>
                                </div>
                                <!-- <div class="two_name_val">
                                    <div>
                                        <div>{{"In the car"|translate}}</div>
                                        <div class="small_two">{{"Environment"|translate}}</div>
                                    </div>
                                    <div>
                                        #
                                    </div>
                                </div> -->
                            </div>
                            <div class="column job_col">
                                <div class="name_val big_name_val" style="padding-top: 0;">
                                    <div>
                                        <span>{{"Created"|translate}}</span>
                                        <span>{{ +item?.task_operations_created | number:'':'fr-FR'}}</span>
                                    </div>
                                </div>
                                <!-- <div class="name_val">
                                    <div>
                                        <span>{{"Directly"|translate}}</span>
                                        <span>#</span>
                                    </div>
                                </div>
                                <div class="name_val">
                                    <div>
                                        <span>{{"By the file"|translate}}</span>
                                        <span>#</span>
                                    </div>
                                </div>
                                <div class="name_val">
                                    <div>
                                        <span>{{"By Scenario"|translate}}</span>
                                        <span>#</span>
                                    </div>
                                </div>
        
                                <div class="group">
                                    {{"By job type"|translate}}:
                                </div>
             
                                <div class="name_val exp_name_val">
                                    <div>
                                        <span class="cp" (click)="item.is_job_coord = !item.is_job_coord">{{"Task coordination"|translate}} {{item.is_job_coord ? "—" : "+"}}</span>
                                        <span>#</span>
                                    </div>
                                    <div class="sub_name_val" *ngIf="item?.is_job_coord">
                                        <div>
                                            <span>{{"Directly"|translate}}</span>
                                            <span>#</span>
                                        </div>
                                        <div>
                                            <span>{{"By scenario"|translate}}</span>
                                            <span>#</span>
                                        </div>
                                        <div>
                                            <span>{{"By file"|translate}}</span>
                                            <span>#</span>
                                        </div>
                                    </div>
                                </div>
        
                                <div class="name_val">
                                    <div>
                                        <span>{{"Content Management Info"|translate}}</span>
                                        <span>#</span>
                                    </div>
                                </div>
        
                                <div class="name_val big_name_val">
                                    <div>
                                        <span>{{"Edited"|translate}}</span>
                                        <span>#</span>
                                    </div>
                                </div>
                                <div class="name_val">
                                    <div>
                                        <span>{{"Price"|translate}}</span>
                                        <span>#</span>
                                    </div>
                                </div>
                                <div class="name_val">
                                    <div>
                                        <span>{{"Time Finished"|translate}}</span>
                                        <span>#</span>
                                    </div>
                                </div>
                                <div class="name_val">
                                    <div>
                                        <span>{{"Job type"|translate}}</span>
                                        <span>#</span>
                                    </div>
                                </div>
                                <div class="name_val">
                                    <div>
                                        <span>{{"Title"|translate}}</span>
                                        <span>#</span>
                                    </div>
                                </div>
                                <div class="name_val">
                                    <div>
                                        <span>{{"Description"|translate}}</span>
                                        <span>#</span>
                                    </div>
                                </div>
                                <div class="name_val">
                                    <div>
                                        <span>{{"Executor"|translate}}</span>
                                        <span>#</span>
                                    </div>
                                </div>
                                <div class="name_val">
                                    <div>
                                        <span>{{"Priority"|translate}}</span>
                                        <span>#</span>
                                    </div>
                                </div>
                                <div class="name_val">
                                    <div>
                                        <span>{{"Tagged"|translate}}</span>
                                        <span>#</span>
                                    </div>
                                </div> -->
                     
                            </div>
                            <div class="column chat_col">
                                <div class="name_val big_name_val" style="padding-top: 0;">
                                    <div>
                                        <span>{{"Created"|translate}}</span>
                                        <span>{{ +item?.discussions_created | number:'':'fr-FR'}}</span>
                                    </div>
                                </div>
                                <div class="name_val" *ngIf="item?.discussions_created_direct > 0">
                                    <div>
                                        <span>{{"Directly"|translate}}</span>
                                        <span>{{ +item?.discussions_created_direct | number:'':'fr-FR'}}</span>
                                    </div>
                                </div>
                                <div class="name_val" *ngIf="item?.discussions_created_file > 0">
                                    <div>
                                        <span>{{"By the file"|translate}}</span>
                                        <span>{{ +item?.discussions_created_file | number:'':'fr-FR'}}</span>
                                    </div>
                                </div>
                                <div class="name_val" *ngIf="item?.discussions_created_scenario > 0">
                                    <div>
                                        <span>{{"By Scenario"|translate}}</span>
                                        <span>{{ +item?.discussions_created_scenario | number:'':'fr-FR'}}</span>
                                    </div>
                                </div>
        
        
                                <!-- <div class="name_val big_name_val">
                                    <div>
                                        <span>{{"Edited"|translate}}</span>
                                        <span>#</span>
                                    </div>
                                </div>
                                <div class="name_val">
                                    <div>
                                        <span>{{"Price"|translate}}</span>
                                        <span>#</span>
                                    </div>
                                </div>
                                <div class="name_val">
                                    <div>
                                        <span>{{"Time Finished"|translate}}</span>
                                        <span>#</span>
                                    </div>
                                </div>
                                <div class="name_val">
                                    <div>
                                        <span>{{"Priority"|translate}}</span>
                                        <span>#</span>
                                    </div>
                                </div>
                                <div class="name_val">
                                    <div>
                                        <span>{{"Title"|translate}}</span>
                                        <span>#</span>
                                    </div>
                                </div>
                                <div class="name_val">
                                    <div>
                                        <span>{{"Executor"|translate}}</span>
                                        <span>#</span>
                                    </div>
                                </div>
        
                                <div class="name_val">
                                    <div>
                                        <span>{{"Tagged"|translate}}</span>
                                        <span>#</span>
                                    </div>
                                </div>
        
                                <div class="name_val exp_name_val">
                                    <div>
                                        <span class="cp" (click)="item.is_chat_relations = !item.is_chat_relations">{{"Relations"|translate}} {{item.is_chat_relations ? "—" : "+"}}</span>
                                        <span>#</span>
                                    </div>
                                    
                                    <div class="sub_name_val" *ngIf="item?.is_chat_relations">
                                        <div>
                                            <span>{{"Consist of"|translate}}</span>
                                            <span>#</span>
                                        </div>
                                        <div>
                                            <span>{{"Part of"|translate}}</span>
                                            <span>#</span>
                                        </div>
                                        <div>
                                            <span>{{"Related"|translate}}</span>
                                            <span>#</span>
                                        </div>
                                    </div>
                                </div> -->
                            </div>
                            <div class="column ass_col">
                                <div class="name_val big_name_val" style="padding-top: 0;">
                                    <div>
                                        <span>{{"Assigned to"|translate}}</span>
                                        <span>{{ (+item?.task_operations_assigned + +item?.discussions_assigned) | number:'':'fr-FR'}}</span>
                                    </div>
                                </div>
                                <div class="name_val" *ngIf="item?.discussions_assigned > 0">
                                    <div>
                                        <span>{{"Chats"|translate}}</span>
                                        <span>{{ item?.discussions_assigned | number:'':'fr-FR'}}</span>
                                    </div>
                                </div>
                                <div class="name_val" *ngIf="item?.task_operations_assigned > 0">
                                    <div>
                                        <span>{{"Jobs"|translate}}</span>
                                        <span>{{ item?.task_operations_assigned | number:'':'fr-FR'}}</span>
                                    </div>
                                </div>
        
                                <!-- <div class="name_val big_name_val">
                                    <div>
                                        <span>{{"Progress"|translate}}</span>
                                        <span>#</span>
                                    </div>
                                </div>
        
                                <div class="divider_title">
                                    {{"Sent" | translate}}
                                </div>
          
        
                                <div class="name_val exp_name_val">
                                    <div>
                                        <span class="cp" (click)="item.is_as_to_approve = !item.is_as_to_approve">{{"To Approve"|translate}} {{item.is_as_to_approve ? "—" : "+"}}</span>
                                        <span>#</span>
                                    </div>
                                    
                                    <div class="sub_name_val" *ngIf="item?.is_as_to_approve">
                                        <div>
                                            <span>{{"Chats"|translate}}</span>
                                            <span>#</span>
                                        </div>
                                        <div>
                                            <span>{{"Jobs"|translate}}</span>
                                            <span>#</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="name_val exp_name_val">
                                    <div>
                                        <span class="cp" (click)="item.is_as_s_needed = !item.is_as_s_needed">{{"Sources needed"|translate}} {{item.is_as_s_needed ? "—" : "+"}}</span>
                                        <span>#</span>
                                    </div>
                                    
                                    <div class="sub_name_val" *ngIf="item?.is_as_s_needed">
                                        <div>
                                            <span>{{"Chats"|translate}}</span>
                                            <span>#</span>
                                        </div>
                                        <div>
                                            <span>{{"Jobs"|translate}}</span>
                                            <span>#</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="name_val exp_name_val">
                                    <div>
                                        <span class="cp" (click)="item.is_as_in_proc = !item.is_as_in_proc">{{"In Process"|translate}} {{item.is_as_in_proc ? "—" : "+"}}</span>
                                        <span>#</span>
                                    </div>
                                    
                                    <div class="sub_name_val" *ngIf="item?.is_as_in_proc">
                                        <div>
                                            <span>{{"Chats"|translate}}</span>
                                            <span>#</span>
                                        </div>
                                        <div>
                                            <span>{{"Jobs"|translate}}</span>
                                            <span>#</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="name_val exp_name_val">
                                    <div>
                                        <span class="cp" (click)="item.is_as_wait = !item.is_as_wait">{{"Waiting"|translate}} {{item.is_as_wait ? "—" : "+"}}</span>
                                        <span>#</span>
                                    </div>
                                    
                                    <div class="sub_name_val" *ngIf="item?.is_as_wait">
                                        <div>
                                            <span>{{"Chats"|translate}}</span>
                                            <span>#</span>
                                        </div>
                                        <div>
                                            <span>{{"Jobs"|translate}}</span>
                                            <span>#</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="divider_title">
                                    {{"Received" | translate}}
                                </div>
                                <div class="name_val exp_name_val">
                                    <div>
                                        <span class="cp" (click)="item.is_as_apr = !item.is_as_apr">{{"Approved"|translate}} {{item.is_as_apr ? "—" : "+"}}</span>
                                        <span>#</span>
                                    </div>
                                    
                                    <div class="sub_name_val" *ngIf="item?.is_as_apr">
                                        <div>
                                            <span>{{"Chats"|translate}}</span>
                                            <span>#</span>
                                        </div>
                                        <div>
                                            <span>{{"Jobs"|translate}}</span>
                                            <span>#</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="name_val exp_name_val">
                                    <div>
                                        <span class="cp" (click)="item.is_as_ready = !item.is_as_ready">{{"Ready"|translate}} {{item.is_as_ready ? "—" : "+"}}</span>
                                        <span>#</span>
                                    </div>
                                    
                                    <div class="sub_name_val" *ngIf="item?.is_as_ready">
                                        <div>
                                            <span>{{"Chats"|translate}}</span>
                                            <span>#</span>
                                        </div>
                                        <div>
                                            <span>{{"Jobs"|translate}}</span>
                                            <span>#</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="name_val exp_name_val">
                                    <div>
                                        <span class="cp" (click)="item.is_as_canc = !item.is_as_canc">{{"Canceled"|translate}} {{item.is_as_canc ? "—" : "+"}}</span>
                                        <span>#</span>
                                    </div>
                                    
                                    <div class="sub_name_val" *ngIf="item?.is_as_canc">
                                        <div>
                                            <span>{{"Chats"|translate}}</span>
                                            <span>#</span>
                                        </div>
                                        <div>
                                            <span>{{"Jobs"|translate}}</span>
                                            <span>#</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="name_val exp_name_val">
                                    <div>
                                        <span class="cp" (click)="item.is_as_hold = !item.is_as_hold">{{"On Hold"|translate}} {{item.is_as_hold ? "—" : "+"}}</span>
                                        <span>#</span>
                                    </div>
                                    
                                    <div class="sub_name_val" *ngIf="item?.is_as_hold">
                                        <div>
                                            <span>{{"Chats"|translate}}</span>
                                            <span>#</span>
                                        </div>
                                        <div>
                                            <span>{{"Jobs"|translate}}</span>
                                            <span>#</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="name_val exp_name_val">
                                    <div>
                                        <span class="cp" (click)="item.is_as_corr = !item.is_as_corr">{{"To correct"|translate}} {{item.is_as_corr ? "—" : "+"}}</span>
                                        <span>#</span>
                                    </div>
                                    
                                    <div class="sub_name_val" *ngIf="item?.is_as_corr">
                                        <div>
                                            <span>{{"Chats"|translate}}</span>
                                            <span>#</span>
                                        </div>
                                        <div>
                                            <span>{{"Jobs"|translate}}</span>
                                            <span>#</span>
                                        </div>
                                    </div>
                                </div> -->
                            </div>
                            <div class="column file_col">
                                <div class="name_val big_name_val" style="padding-top: 0;">
                                    <div>
                                        <span>{{"Created"|translate}}</span>
                                        <span>{{ +item?.files_created | number:'':'fr-FR'}}</span>
                                    </div>
                                </div>
        
                                <div class="name_val exp_name_val" *ngIf="item?.files_uploaded > 0">
                                    <div>
                                        <span class="cp" (click)="item.is_files_types = !item.is_files_types">{{"Files"|translate}} {{item.is_files_types ? "—" : "+"}}</span>
                                        <span>{{ +item?.files_uploaded | number:'':'fr-FR'}}</span>
                                    </div>
                                    <div class="sub_name_val" *ngIf="item?.is_files_types">
                                        <div *ngIf="item?.files_uploaded_image > 0">
                                            <span>{{"Images"|translate}}</span>
                                            <span>{{ +item?.files_uploaded_image | number:'':'fr-FR'}}</span>
                                        </div>
                                        <div *ngIf="item?.files_uploaded_video > 0">
                                            <span>{{"Videos"|translate}}</span>
                                            <span>{{ +item?.files_uploaded_video | number:'':'fr-FR'}}</span>
                                        </div>
                                        <div *ngIf="item?.files_uploaded_archive > 0">
                                            <span>{{"Archives"|translate}}</span>
                                            <span>{{ +item?.files_uploaded_archive | number:'':'fr-FR'}}</span>
                                        </div>
                                        <div *ngIf="item?.files_uploaded_other > 0">
                                            <span>{{"Other"|translate}}</span>
                                            <span>{{ +item?.files_uploaded_other | number:'':'fr-FR'}}</span>
                                        </div>
                                    </div>
                                </div>
    
                                <div class="name_val" *ngIf="item?.files_folders_created > 0">
                                    <div>
                                        <span>{{"Folders"|translate}}</span>
                                        <span>{{ +item?.files_folders_created | number:'':'fr-FR'}}</span>
                                    </div>
                                </div>
        
                                <div class="name_val big_name_val">
                                    <div>
                                        <span>{{"Edited"|translate}}</span>
                                        <span>{{ +item?.files_updated | number:'':'fr-FR'}}</span>
                                    </div>
                                </div>
    
                                <div class="name_val exp_name_val"  *ngIf="item?.files_files_updated > 0">
                                    <div>
                                        <span class="cp" (click)="item.is_files_types_edited = !item.is_files_types_edited">{{"Files"|translate}} {{item.is_files_types_edited ? "—" : "+"}}</span>
                                        <span>{{ +item?.files_files_updated | number:'':'fr-FR'}}</span>
                                    </div>
                                    <div class="sub_name_val" *ngIf="item?.is_files_types_edited">
                                        <div *ngIf="item?.files_renamed > 0">
                                            <span>{{"Name"|translate}}</span>
                                            <span>{{ +item?.files_renamed | number:'':'fr-FR'}}</span>
                                        </div>
                                        <div *ngIf="item?.files_deleted > 0">
                                            <span>{{"Deleted"|translate}}</span>
                                            <span>{{ +item?.files_deleted | number:'':'fr-FR'}}</span>
                                        </div>
                                        <div *ngIf="item?.files_moved > 0">
                                            <span>{{"Moved"|translate}}</span>
                                            <span>{{ +item?.files_moved | number:'':'fr-FR'}}</span>
                                        </div>
                                        <div *ngIf="item?.files_copied > 0">
                                            <span>{{"Copied"|translate}}</span>
                                            <span>{{ +item?.files_copied | number:'':'fr-FR'}}</span>
                                        </div>
                                        <div *ngIf="item?.files_shared > 0">
                                            <span>{{"Shared"|translate}}</span>
                                            <span>{{ +item?.files_shared | number:'':'fr-FR'}}</span>
                                        </div>
                                        <div *ngIf="item?.files_unshared > 0">
                                            <span>{{"Unshared"|translate}}</span>
                                            <span>{{ +item?.files_unshared | number:'':'fr-FR'}}</span>
                                        </div>
                                        <div *ngIf="item?.files_tagged > 0">
                                            <span>{{"Tagged"|translate}}</span>
                                            <span>{{ +item?.files_tagged | number:'':'fr-FR'}}</span>
                                        </div>
                                    </div>
                                </div>
    
                                <div class="name_val exp_name_val" *ngIf="item?.files_folders_updated > 0">
                                    <div>
                                        <span class="cp" (click)="item.is_folders_edited = !item.is_folders_edited">{{"Folders"|translate}} {{item.is_folders_edited ? "—" : "+"}}</span>
                                        <span>{{ +item?.files_folders_updated | number:'':'fr-FR'}}</span>
                                    </div>
                                    <div class="sub_name_val" *ngIf="item?.is_folders_edited">
                                        <div *ngIf="item?.files_folders_renamed > 0">
                                            <span>{{"Name"|translate}}</span>
                                            <span>{{ +item?.files_folders_renamed | number:'':'fr-FR'}}</span>
                                        </div>
                                        <div *ngIf="item?.files_folders_deleted > 0">
                                            <span>{{"Deleted"|translate}}</span>
                                            <span>{{ +item?.files_folders_deleted | number:'':'fr-FR'}}</span>
                                        </div>
                                        <div *ngIf="item?.files_folders_moved > 0">
                                            <span>{{"Moved"|translate}}</span>
                                            <span>{{ +item?.files_folders_moved | number:'':'fr-FR'}}</span>
                                        </div>
                                        <div *ngIf="item?.files_folders_copied > 0">
                                            <span>{{"Copied"|translate}}</span>
                                            <span>{{ +item?.files_folders_copied | number:'':'fr-FR'}}</span>
                                        </div>
                                        <div *ngIf="item?.files_folders_shared > 0">
                                            <span>{{"Shared"|translate}}</span>
                                            <span>{{ +item?.files_folders_shared | number:'':'fr-FR'}}</span>
                                        </div>
                                        <div *ngIf="item?.files_folders_unshared > 0">
                                            <span>{{"Unshared"|translate}}</span>
                                            <span>{{ +item?.files_folders_unshared | number:'':'fr-FR'}}</span>
                                        </div>
                                        <div *ngIf="item?.files_folders_tagged > 0">
                                            <span>{{"Tagged"|translate}}</span>
                                            <span>{{ +item?.files_folders_tagged | number:'':'fr-FR'}}</span>
                                        </div>
                                    </div>
                                </div>
                                <!--
                                <div class="name_val">
                                    <div>
                                        <span>{{"Name"|translate}}</span>
                                        <span>#</span>
                                    </div>
                                </div>
                                <div class="name_val">
                                    <div>
                                        <span>{{"Deleted"|translate}}</span>
                                        <span>#</span>
                                    </div>
                                </div>
                                <div class="name_val">
                                    <div>
                                        <span>{{"Moved"|translate}}</span>
                                        <span>#</span>
                                    </div>
                                </div>
                                <div class="name_val">
                                    <div>
                                        <span>{{"Copied"|translate}}</span>
                                        <span>#</span>
                                    </div>
                                </div>
                                <div class="name_val">
                                    <div>
                                        <span>{{"Shared"|translate}}</span>
                                        <span>#</span>
                                    </div>
                                </div>
                                <div class="name_val">
                                    <div>
                                        <span>{{"Unshared"|translate}}</span>
                                        <span>#</span>
                                    </div>
                                </div>
                                <div class="name_val">
                                    <div>
                                        <span>{{"Tagged"|translate}}</span>
                                        <span>#</span>
                                    </div>
                                </div> -->
                            </div>
                            <div class="column post_col">
                                <div class="name_val big_name_val" style="padding-top: 0;">
                                    <div>
                                        <span>{{"Added"|translate}}</span>
                                        <span>{{ +item?.task_channels_combined | number:'':'fr-FR'}}</span>
                                    </div>
                                </div>
           
                                <!-- Outlets added to tasks -->
                                <div class="name_val exp_name_val" style="padding-top: 12px; padding-bottom: 8px;" *ngIf="item?.task_channels_created > 0">
                                    <div>
                                        <span class="cp cp_bold" (click)="openOutletsAdded(item);">{{"Outlets added to tasks"|translate}} {{item.is_post_outlets ? "—" : "+"}}</span>
                                        <span>{{ +item?.task_channels_created | number:'':'fr-FR'}}</span>
                                    </div>
                                    
                                    <div class="sub_name_val sub_vertical" *ngIf="item?.is_post_outlets">
                                        <ng-container *ngFor="let platform of item?.groupedByPlatform">
                                            <div class="name_val exp_name_val" *ngIf="platform.channels && platform.channels.length && platform?.openOutletsAddedData?.task_channels_created > 0">
                                                <div>
                                                    <span class="cp" (click)="openPlatformAdded(item, platform);">{{platform.name}} {{platform.is_added_open ? "—" : "+"}}</span>
                                                    <span>{{ +platform?.openOutletsAddedData?.task_channels_created | number:'':'fr-FR'}}</span>
                                                </div>
                                                
                                                <div class="sub_name_val" *ngIf="platform.is_added_open">
                                                    <ng-container *ngFor="let channel of platform.channels">
                                                        <div *ngIf="channel?.openPlatformAddedData?.task_channels_created > 0">
                                                            <span>{{channel.name}}</span>
                                                            <span>{{ +channel?.openPlatformAddedData?.task_channels_created | number:'':'fr-FR'}}</span>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </ng-container>
                                        
                                        <div class="name_val exp_name_val" *ngIf="item?.task_channels_created_direct > 0 || item?.task_channels_created_url > 0">
                                            <div>
                                                <span class="cp_bold" >{{"By actions"|translate}}</span>
                                            </div>
                                            
                                            <div class="sub_name_val">
                                                <div *ngIf="item?.task_channels_created_direct > 0">
                                                    <span>{{"Directly"|translate}}</span>
                                                    <span>{{ +item?.task_channels_created_direct | number:'':'fr-FR'}}</span>
                                                </div>
                                                <div *ngIf="item?.task_channels_created_url > 0">
                                                    <span>{{"By adding Post URL"|translate}}</span>
                                                    <span>{{ +item?.task_channels_created_url | number:'':'fr-FR'}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
        
                                <!-- Posts added outlet(s) -->
                                <div class="name_val exp_name_val" style="padding-top: 12px; padding-bottom: 8px;" *ngIf="item?.task_channels_url_created > 0">
                                    <div>
                                        <span class="cp cp_bold" (click)="openPostsAdded(item);">{{"Posts added outlet(s)"|translate}} {{item.is_post_added ? "—" : "+"}}</span>
                                        <span>{{ +item?.task_channels_url_created | number:'':'fr-FR'}}</span>
                                    </div>
                                    
                                    <div class="sub_name_val sub_vertical" *ngIf="item?.is_post_added">
                                        <ng-container *ngFor="let stat of item?.groupedByStatus">
                                            <ng-container *ngIf="stat?.openPostsAddedData?.task_channels_url_created > 0">
                                                <div style="display: flex; align-items: center; justify-content: space-between;">
                                                    <span class="cp" style="color: #000;" (click)="openStatPostsAdded(item, stat);">{{stat.name}} {{stat.is_open ? "—" : "+"}}</span>
                                                    <span style="color: #000;">{{ +stat?.openPostsAddedData?.task_channels_url_created | number:'':'fr-FR'}}</span>
                                                </div>
                                                
                                                <div class="sub_name_val sub_vertical" style="width: calc(100% - 8px);" *ngIf="stat?.is_open">
                                                    <ng-container *ngFor="let platform of stat?.groupedByPlatform">
                                                        <div class="name_val exp_name_val" *ngIf="platform.channels && platform.channels.length && platform?.openStatPostsAddedData?.task_channels_url_created > 0">
                                                            <div>
                                                                <span class="cp" (click)="openPlatformPostsAdded(item, stat, platform);">{{platform.name}} {{platform.is_open ? "—" : "+"}}</span>
                                                                <span>{{ +platform?.openStatPostsAddedData?.task_channels_url_created | number:'':'fr-FR'}}</span>
                                                            </div>
                                                            
                                                            <div class="sub_name_val" style="width: calc(100% - 8px);" *ngIf="platform.is_open">
                                                                <ng-container *ngFor="let channel of platform.channels">
                                                                    <div *ngIf="channel?.openPlatformPostsAddedData?.task_channels_url_created > 0">
                                                                        <span>{{channel.name}}</span>
                                                                        <span>{{ +channel?.openPlatformPostsAddedData?.task_channels_url_created | number:'':'fr-FR'}}</span>
                                                                    </div>
                                                                </ng-container>
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                </div>
        
                                <div class="name_val big_name_val">
                                    <div>
                                        <span>{{"Edited"|translate}}</span>
                                        <span>{{ +item?.task_channels_updated | number:'':'fr-FR'}}</span>
                                    </div>
                                </div>
    
                                <ng-container *ngIf="item?.task_channels_updated > 0">
                                    <div class="name_val exp_name_val" style="padding-top: 12px; padding-bottom: 8px;" *ngIf="item?.task_channels_status_updated > 0">
                                        <div>
                                            <span class="cp cp_bold" (click)="openPostsEdited(item);">{{"Statuses changed"|translate}} {{item.is_post_edited ? "—" : "+"}}</span>
                                            <span>{{ +item?.task_channels_status_updated | number:'':'fr-FR'}}</span>
                                        </div>
    
                                        <div class="sub_name_val sub_vertical" *ngIf="item?.is_post_edited">
                                            <ng-container *ngFor="let stat of item?.editGroupedByStatus">
                                                <ng-container *ngIf="stat?.openPostsEditedData?.task_channels_status_updated > 0">
                                                    <div style="display: flex; align-items: center; justify-content: space-between;">
                                                        <span class="cp" style="color: #000;" (click)="openStatPostsEdited(item, stat);">{{stat.name}} {{stat.is_open ? "—" : "+"}}</span>
                                                        <span style="color: #000;">{{ +stat?.openPostsEditedData?.task_channels_status_updated | number:'':'fr-FR'}}</span>
                                                    </div>
                                                    
                                                    <div class="sub_name_val sub_vertical" style="width: calc(100% - 8px);" *ngIf="stat?.is_open">
                                                        <ng-container *ngFor="let platform of stat?.groupedByPlatform">
                                                            <div class="name_val exp_name_val" *ngIf="platform.channels && platform.channels.length && platform?.openStatPostsEditedData?.task_channels_status_updated > 0">
                                                                <div>
                                                                    <span class="cp" (click)="openPlatformPostsEdited(item, stat, platform);">{{platform.name}} {{platform.is_open ? "—" : "+"}}</span>
                                                                    <span>{{ +platform?.openStatPostsEditedData?.task_channels_status_updated | number:'':'fr-FR'}}</span>
                                                                </div>
                                                                
                                                                <div class="sub_name_val" style="width: calc(100% - 8px);" *ngIf="platform.is_open">
                                                                    <ng-container *ngFor="let channel of platform.channels">
                                                                        <div *ngIf="channel?.openPlatformPostsEditedData?.task_channels_status_updated > 0">
                                                                            <span>{{channel.name}}</span>
                                                                            <span>{{ +channel?.openPlatformPostsEditedData?.task_channels_status_updated | number:'':'fr-FR'}}</span>
                                                                        </div>
                                                                    </ng-container>
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                    </div>
                                                </ng-container>
                                            </ng-container>
                                        </div>
                                    </div>
    
                                    <div class="name_val exp_name_val" style="padding-top: 12px; padding-bottom: 8px;" *ngIf="item?.task_channels_url_updated > 0">
                                        <div>
                                            <span class="cp cp_bold" (click)="getItemPlatforms(item, 'url_edited_open', 'urlsEditedPlatform');">{{"URLs edited"|translate}} {{item.url_edited_open ? "—" : "+"}}</span>
                                            <span>{{ +item?.task_channels_url_updated | number:'':'fr-FR'}}</span>
                                        </div>
                                        <div class="sub_name_val sub_vertical" style="width: calc(100% - 8px);" *ngIf="item.url_edited_open">
                                            <ng-container *ngFor="let platform of item?.groupedByPlatform">
                                                <div class="name_val exp_name_val" *ngIf="platform.channels && platform.channels.length && platform?.urlsEditedPlatform?.task_channels_url_updated > 0">
                                                    <div>
                                                        <span class="cp" (click)="getItemPlatformChannels(item, platform, 'url_edited_open', 'urlsEditedPlatform');">{{platform.name}} {{platform?.url_edited_open ? "—" : "+"}}</span>
                                                        <span>{{ +platform?.urlsEditedPlatform?.task_channels_url_updated | number:'':'fr-FR'}}</span>
                                                    </div>
                                                    
                                                    <div class="sub_name_val" style="width: calc(100% - 8px);" *ngIf="platform?.url_edited_open">
                                                        <ng-container *ngFor="let channel of platform.channels">
                                                            <div *ngIf="channel?.urlsEditedPlatform?.task_channels_url_updated > 0">
                                                                <span>{{channel.name}}</span>
                                                                <span>{{ +channel?.urlsEditedPlatform?.task_channels_url_updated | number:'':'fr-FR'}}</span>
                                                            </div>
                                                        </ng-container>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
        
                                    <div class="name_val exp_name_val" style="padding-top: 12px; padding-bottom: 8px;" *ngIf="item?.task_channels_published_at_updated > 0">
                                        <div>
                                            <span class="cp cp_bold" (click)="getItemPlatforms(item, 'publication_times_edited', 'publicationTimesEdited');">{{"Time edited"|translate}} {{item.publication_times_edited ? "—" : "+"}}</span>
                                            <span>{{ +item?.task_channels_published_at_updated | number:'':'fr-FR'}}</span>
                                        </div>
                                        <div class="sub_name_val sub_vertical" style="width: calc(100% - 8px);" *ngIf="item.publication_times_edited">
                                            <ng-container *ngFor="let platform of item?.groupedByPlatform">
                                                <div class="name_val exp_name_val" *ngIf="platform.channels && platform.channels.length && platform?.publicationTimesEdited?.task_channels_published_at_updated > 0">
                                                    <div>
                                                        <span class="cp" (click)="getItemPlatformChannels(item, platform, 'publication_times_edited', 'publicationTimesEdited');">{{platform.name}} {{platform?.publication_times_edited ? "—" : "+"}}</span>
                                                        <span>{{ +platform?.publicationTimesEdited?.task_channels_published_at_updated | number:'':'fr-FR'}}</span>
                                                    </div>
                                                    
                                                    <div class="sub_name_val" style="width: calc(100% - 8px);" *ngIf="platform?.publication_times_edited">
                                                        <ng-container *ngFor="let channel of platform.channels">
                                                            <div *ngIf="channel?.publicationTimesEdited?.task_channels_published_at_updated > 0">
                                                                <span>{{channel.name}}</span>
                                                                <span>{{ +channel?.publicationTimesEdited?.task_channels_published_at_updated | number:'':'fr-FR'}}</span>
                                                            </div>
                                                        </ng-container>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
    
                                    <div class="name_val exp_name_val" style="padding-top: 12px; padding-bottom: 8px;" *ngIf="item?.task_channels_image_updated > 0">
                                        <div>
                                            <span class="cp cp_bold" (click)="getItemPlatforms(item, 'thumbnails_Edited_open', 'thumbnailsEdited');">{{"Thumbnails edited"|translate}} {{item.thumbnails_Edited_open ? "—" : "+"}}</span>
                                            <span>{{ +item?.task_channels_image_updated | number:'':'fr-FR'}}</span>
                                        </div>
                                        <div class="sub_name_val sub_vertical" style="width: calc(100% - 8px);" *ngIf="item.thumbnails_Edited_open">
                                            <ng-container *ngFor="let platform of item?.groupedByPlatform">
                                                <div class="name_val exp_name_val" *ngIf="platform.channels && platform.channels.length && platform?.thumbnailsEdited?.task_channels_image_updated > 0">
                                                    <div>
                                                        <span class="cp" (click)="getItemPlatformChannels(item, platform, 'thumbnails_Edited_open', 'thumbnailsEdited');">{{platform.name}} {{platform?.thumbnails_Edited_open ? "—" : "+"}}</span>
                                                        <span>{{ +platform?.thumbnailsEdited?.task_channels_image_updated | number:'':'fr-FR'}}</span>
                                                    </div>
                                                    
                                                    <div class="sub_name_val" style="width: calc(100% - 8px);" *ngIf="platform?.thumbnails_Edited_open">
                                                        <ng-container *ngFor="let channel of platform.channels">
                                                            <div *ngIf="channel?.thumbnailsEdited?.task_channels_image_updated > 0">
                                                                <span>{{channel.name}}</span>
                                                                <span>{{ +channel?.thumbnailsEdited?.task_channels_image_updated | number:'':'fr-FR'}}</span>
                                                            </div>
                                                        </ng-container>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
        
                                    <div class="name_val exp_name_val" style="padding-top: 12px; padding-bottom: 8px;" *ngIf="item?.task_channels_url_deleted > 0">
                                        <div>
                                            <span class="cp_bold">{{"URLs deleted"|translate}}</span>
                                            <span>{{ +item?.task_channels_url_deleted | number:'':'fr-FR'}}</span>
                                        </div>
                                    </div>
                                </ng-container>
                                
                            </div>
                            <div class="exp_col">
                                <div class="toggler" (click)="item.is_expand = !item.is_expand">
                                    <svg style="width: 19px; height: 10px;" width="19" height="10" viewBox="0 0 19 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M18.5 9.5L10 1L1 9.5" stroke="black"/>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="outlets_dash_footer">
                        <div class="column empl_col">
                            <span class="big">
                                {{ "Total" | translate }} <ng-container *ngIf="!!activity.length">{{activity.length}}</ng-container>
                            </span>
                        </div>
                        <div class="column task_col">
                            <span class="normal">
                                {{ +totals?.tasks_created | number:'':'fr-FR'}}
                            </span>
                        </div>
                        <div class="column job_col">
                            <span class="normal">
                                {{ +totals?.task_operations_created | number:'':'fr-FR'}}
                            </span>
                        </div>
                        <div class="column chat_col">
                            <span class="normal">
                                {{ +totals?.discussions_created | number:'':'fr-FR'}}
                            </span>
                        </div>
                        <div class="column ass_col">
                            <span class="normal">
                                {{ (+totals?.task_operations_assigned + +totals?.discussions_assigned) | number:'':'fr-FR'}}
                            </span>
                        </div>
                        <div class="column file_col">
                            <span class="normal">
                                {{ +totals?.files_uploaded | number:'':'fr-FR'}}
                            </span>
                        </div>
                        <div class="column post_col">
                            <span class="normal">
                                {{ +totals?.task_channels_combined | number:'':'fr-FR'}}
                            </span>
                        </div>
                        <div class="exp_col"></div>
                    </div>
                </div>
            </ng-container>
            <ng-template #elseTemplateTab>
                <div class="table_container not_table_container">
                    <table class="nots_table" mat-table [dataSource]="notifications" multiTemplateDataRows>
                  
                        <ng-container matColumnDef="Expand" *ngIf="notFilter.get('original_action_id').value == '0'">
                          <th mat-header-cell *matHeaderCellDef> {{ "Expand" | translate }} </th>
                          <td mat-cell *matCellDef="let element">
                            <button *ngIf="element.original_action_id == 0" mat-icon-button aria-label="expand row" (click)="expandAction($event,element)">
                                <mat-icon *ngIf="!element.isExpand">keyboard_arrow_down</mat-icon>
                                <mat-icon *ngIf="element.isExpand">keyboard_arrow_up</mat-icon>
                            </button>
                          </td>
                        </ng-container>
        
                        <ng-container matColumnDef="Date">
                          <th mat-header-cell *matHeaderCellDef> {{ "Date" | translate }} </th>
                          <td mat-cell *matCellDef="let element">
                              <ng-container *ngIf="element.created_at != '0'">                          
                                <div *ngIf="todaysDate == getDay(element.created_at*1000); else notToday;">
                                    {{ element.created_at*1000 | date:'shortTime':'':activeLang }}
                                </div>
                                <ng-template #notToday>
                                    <div matTooltip="{{ element.created_at*1000 | date:'shortTime':'':activeLang }}" matTooltipClass="dateTooltip" [matTooltipPosition]="'above'">
                                        {{ element.created_at*1000 | date:'mediumDate':'timeZone':activeLang | yearPipe }} 
                                    </div>
                                </ng-template>
                              </ng-container>
                          </td>
                        </ng-container>
        
                        <ng-container matColumnDef="User">
                            <th mat-header-cell *matHeaderCellDef> {{ "User" | translate }} </th>
                            <td mat-cell *matCellDef="let element"> 
                                <div class="user_div">
                                    <ng-container *ngIf="!!element?.createdEmployee?.image; else elseOwnerTemplate">
                                        <img [src]="element?.createdEmployee?.image" alt="">
                                    </ng-container>
                                    <ng-template #elseOwnerTemplate>
                                        <mat-icon>account_circle</mat-icon>
                                    </ng-template>
                                    <span>{{element?.createdEmployee?.name}}</span>
                                </div>
                            </td>
                        </ng-container>
        
                        <ng-container>
                            <ng-container matColumnDef="Info">
                              <th mat-header-cell *matHeaderCellDef> {{ "Information" | translate }} </th>
                              <td mat-cell *matCellDef="let element">
                                    <ng-template #relationTaskTemplate>
                                        <div class="task_ids_wrp not_link" *ngIf="element?.relationTask" (click)="openTask(element.relationTask)">
                                            {{ element.relationTask.name.length > 60 ? (element.relationTask.name | slice:0:60) + " ..." : element.relationTask.name }}
                                            <div class="task_ids">
                                                {{element.relationTask.custom_id}} 
                                                <span>
                                                    <div>{{element.relationTask.internal_id}}</div>
                                                    <div>{{element.relationTask.id}}</div>
                                                </span>
                                            </div>
                                        </div>
                                    </ng-template>
                                    <div class="not_info">
                                        <div class="task_ids_wrp not_link" *ngIf="!!data?.header && element?.task" (click)="openTask(element.task)">
                                            {{ element.task.name.length > 60 ? (element.task.name | slice:0:60) + " ..." : element.task.name }}
                                            <div class="task_ids">
                                                {{element.task.custom_id}} 
                                                <span>
                                                    <div>{{element.task.internal_id}}</div>
                                                    <div>{{element.task.id}}</div>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="value" [ngClass]="{'d-f-c': element.action == 'task_channel_channel'}">
                                            <b class="action">{{element.action | translate }}: </b>
                                  
                                            <ng-container [ngSwitch]="element.action">
                                                <ng-container *ngSwitchCase="'content_plan_deleted'" class="alert_value">
                                                    <ng-container *ngIf="element.parsedData && element.parsedData.attributes">
                                                        <b class="ml-1">{{element.parsedData.attributes.name}}</b>
                                                        <span class="ml-1">
                                                        </span>
                                                        <app-outlet-line class="ml-1" [channel]="getProfile(element.parsedData.attributes.channel_id)" [company_id]="data.company.id"></app-outlet-line>
                                                        <span class="ml-1">{{getContentById(element.parsedData.attributes.content_type_id)?.name}}</span>
                                                        <span class="ml-1">{{element.parsedData.attributes.publish_at*1000 | date:'MMM d, y, hh:mm a':'': activeLang}}</span>
                                                    </ng-container>
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'content_schedule_deleted'" class="alert_value">
                                                    <ng-container *ngIf="element.parsedData && element.parsedData.attributes">
                                                        <b class="ml-1">{{element.parsedData.attributes.name}}</b>
                                                        <span class="ml-1">
                                                        </span>
                                                        <app-outlet-line class="ml-1" [channel]="getProfile(element.parsedData.attributes.channel_id)" [company_id]="data.company.id"></app-outlet-line>
                                                        <span class="ml-1">{{getContentById(element.parsedData.attributes.content_type_id)?.name}}</span>
                                                        <span *ngIf="element.parsedData.attributes.is_daily" class="ml-1">
                                                            {{ "Every day" | translate }}
                                                        </span>
                                                        <span *ngIf="element.parsedData.attributes.is_weekly" class="ml-1">
                                                            {{ "Every week" | translate }}
                                                        </span>
                                                        <span *ngIf="element.parsedData.attributes.is_monthly" class="ml-1">
                                                            {{ "Every month" | translate }}
                                                        </span>
                                                    </ng-container>
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'content_schedule_item_deleted'" class="alert_value">
                                                    <ng-container *ngIf="element.parsedData && element.parsedData.attributes">
                                                        <b class="ml-1">{{element.parsedData.attributes.name}}</b>
                                                        <span class="ml-1">
                                                        </span>
                                                        <app-outlet-line [channel]="getProfile(element.parsedData.attributes.channel_id)" [company_id]="data.company.id"></app-outlet-line>
                                                        <span class="ml-1">{{getContentById(element.parsedData.attributes.content_type_id)?.name}}</span>
                                                        <div class="chat_timestamp" class="ml-1">
                                                            <ng-container>
                                                                <span class="silver">{{(element.parsedData.attributes.time | fullTime:false:true:true).silverTime}}</span>
                                                                <span class="black">{{(element.parsedData.attributes.time | fullTime:false:true:true).blackTime}}</span>
                                                            </ng-container>
                                                        </div>
                                                    </ng-container>
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'task_status'" class="alert_value">
                                                    {{ getTaskStatusById(element.task_status_id)?.name }}
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'task_channel_live_stream_pause'" class="alert_value">
                                                    <div class="ml-1 snippet">
                                                        Stream ID: {{element.task_channel_id}}
                                                    </div>
                                                    <div class="ml-1 snippet">
                                                        Broadcast ID: {{element.task_channel_live_stream_id }}
                                                    </div>
                                                    <div class="ml-1 d-f-c chat_timetamp" *ngIf="!!element.to_integer" #tooltip="matTooltip"
                                                    matTooltip="{{ 'Stream paused duration' | translate }}"
                                                    [matTooltipPosition]="'above'">
                                                        <mat-icon style="width: 10px; height: 10px; font-size: 10px; line-height: 10px; color: #C9C9C9; margin-right: 1px;">motion_photos_pause</mat-icon>
                                                        <span class="black">{{element?.to_integer | timeFormat}}</span>
                                                    </div>
                                                </ng-container>
                                                <!-- ['task_consist_of_created','task_consist_of_removed','task_part_of_created','task_part_of_removed','task_related_created','task_related_removed'].includes(element.action) -->
                                                <ng-container *ngSwitchCase="'task_consist_of_created'" class="alert_value">
                                                    <ng-container *ngTemplateOutlet="relationTaskTemplate"></ng-container>
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'task_consist_of_removed'" class="alert_value">
                                                    <ng-container *ngTemplateOutlet="relationTaskTemplate"></ng-container>
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'task_part_of_created'" class="alert_value">
                                                    <ng-container *ngTemplateOutlet="relationTaskTemplate"></ng-container>
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'task_part_of_removed'" class="alert_value">
                                                    <ng-container *ngTemplateOutlet="relationTaskTemplate"></ng-container>
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'task_related_created'" class="alert_value">
                                                    <ng-container *ngTemplateOutlet="relationTaskTemplate"></ng-container>
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'task_related_removed'" class="alert_value">
                                                    <ng-container *ngTemplateOutlet="relationTaskTemplate"></ng-container>
                                                </ng-container>
        
                                                
                                                <!-- <div *ngSwitchCase="'task_comment'" class="alert_value">
                                                    {{ element?.data?.text }}
                                                </div>
                                                <div *ngSwitchCase="'task_private_comment'" class="alert_value">
                                                    {{ element?.data?.text }}
                                                </div> -->
                                                <div *ngSwitchCase="'task_channel_channel'" class="alert_value profile_value">
                                                    <span>{{ "fromA" | translate }} </span>
                                                    <div class="outlet_block">
                                                        <ng-container [ngTemplateOutlet]="platformTemplate" [ngTemplateOutletContext]="{ platform_id: profileData(element.from_task_channel_channel_id)?.platform_id }"></ng-container>
                                                        {{ profileData(element.from_task_channel_channel_id).name }}
                                                    </div>
                                                    <span>{{ "toA" | translate }} </span>
                                                    <div class="outlet_block">
                                                        <ng-container [ngTemplateOutlet]="platformTemplate" [ngTemplateOutletContext]="{ platform_id: profileData(element.task_channel_channel_id)?.platform_id }"></ng-container>
                                                        {{ profileData(element.task_channel_channel_id)?.name }}
                                                    </div>
                                                </div>
                                                <div *ngSwitchCase="'task_channel_content_status'" class="alert_value">
                                                    <div *ngSwitchCase="'task_channel_content_status'" class="alert_value">
                                                        <span>{{ "fromA" | translate }} </span>
                                                        {{ profileStatusesData(element?.from_integer)?.name | translate }}
                                                        <span>{{ "toA" | translate }} </span>
                                                        {{ profileStatusesData(element?.to_integer)?.name | translate }}
                                                    </div>
                                                </div>
                                                <div *ngSwitchCase="'task_channel_content_published_at'" class="alert_value">
                                                    <span>{{ "fromA" | translate }} </span>
                                                    {{ element?.from_integer*1000 | date:'MMM dd, Y':'+0000': activeLang | yearPipe }}
                                                    <span>{{ "toA" | translate }} </span>
                                                    {{ element?.to_integer*1000 | date:'MMM dd, Y':'+0000': activeLang | yearPipe }}
                                                </div>
        
                                                <div *ngSwitchCase="'task_employee_assigned'" class="alert_value" style="display: inline-flex; align-items:center">
                                                    <ng-container *ngIf="!!employees?.length">
                                                        <app-user [class]="'user_div'" [employee]="getEmployee(element?.employee_id)"></app-user>
                                                    </ng-container>
                                                </div>
                                                <div *ngSwitchCase="'task_employee_is_manager'" class="alert_value">
                                                    {{ element?.task_employee_is_manager ? ( "manager" | translate ) : ( "not manager" | translate ) }}
                                                </div>
                                                <div *ngSwitchCase="'task_employee_rate'" class="alert_value">
                                                    {{ element?.task_employee_rate | reportsNumber }}
                                                </div>
                                                <div *ngSwitchCase="'task_employee_price'" class="alert_value">
                                                    {{ element?.task_employee_price | reportsNumber }}
                                                </div>
                                                <div *ngSwitchCase="'task_employee_status'" class="alert_value">
                                                    {{ getOperationStatusName(element?.task_employee_status) }}
                                                </div>
                                                <div *ngSwitchCase="'task_employee_completed'" class="alert_value">
                                                    {{ element?.task_employee_completed_at*1000 | date:'MMM dd, Y':'+0000': activeLang | yearPipe }}
                                                </div>
                                                <div *ngSwitchCase="'task_channel_created'" class="alert_value">
                                                    <ng-container [ngTemplateOutlet]="platformTemplate" [ngTemplateOutletContext]="{ platform_id: profileData(element.task_channel_channel_id)?.platform_id }"></ng-container>
                                                    {{ profileData(element.task_channel_channel_id)?.name }}
                                                </div>
                                                <div *ngSwitchCase="'task_channel_deleted'" class="alert_value">
                                                    <ng-container [ngTemplateOutlet]="platformTemplate" [ngTemplateOutletContext]="{ platform_id: profileData(element.task_channel_channel_id)?.platform_id }"></ng-container>
                                                    {{ profileData(element.task_channel_channel_id)?.name }}
                                                </div>
        
                                                <div *ngSwitchCase="'task_operation_status'" class="alert_value">
                                                    {{ getOperationStatusName(element?.task_operation_status_id) }}
                                                </div>
                                                <div *ngSwitchCase="'document_created'" class="alert_value">
                                                    {{ element?.document?.name }}
                                                </div>
                                                <div *ngSwitchCase="'document_updated'" class="alert_value">
                                                    {{ element?.document?.name }}
                                                </div>
                                                <div *ngSwitchCase="'task_employee_status_start_at'" class="alert_value">
                                                    {{ element.task_employee_start_at*1000 | date:'MMM dd. Y, hh:mm a':'':activeLang | yearPipe }}
                                                </div>
                                                <div *ngSwitchCase="'task_employee_status_change'" class="alert_value" style="display: inline-flex; align-items:center">
                                                    {{ getOperationStatusName(element?.task_employee_status_id) }}
                                                    <span class="ml-1 mr-1">{{ "for" | translate }}</span>
                                                    <ng-container *ngIf="!!employees?.length">
                                                        <app-user [class]="'user_div'" [employee]="getEmployee(element?.employee_id)"></app-user>
                                                    </ng-container>
                                                </div>
                                                <div *ngSwitchCase="'task_custom_id'" class="alert_value">
                                                    <div class="d-f-c">
                                                        {{ element?.task_old_custom_id}}<mat-icon class="mr-1 ml-1">arrow_right_alt</mat-icon>{{ element?.task_custom_id }}
                                                    </div>
                                                </div>
                                                <div *ngSwitchCase="'discussion_post_added'" class="alert_value not_link"  (click)="openChat(element)">
                                                    {{ element?.discussion?.name }}
                                                </div>
                                                <div *ngSwitchCase="'file_created'" class="alert_value">
                                                    <span class="file_name">{{element?.file?.filename}}</span>
                                                </div>
                                                <div *ngSwitchCase="'file_location'" class="alert_value">
                                                    <span class="file_name">{{element?.file?.filename}}</span>
                                                    
                                                    <ng-container *ngIf="element?.data.length > 1; else elseTemplateLoc">
                                                        <span>{{ "fromA" | translate }} </span>
                                                        <span>{{ ( element?.data[0].text == '/' ? "Root" : element?.data[0].text.split("/")[element?.data[0].text.split("/").length - 1] ) | translate }} </span>
                                                        <span>{{ "toA" | translate }} </span>
                                                        <span>{{ ( element?.data[1].text == '/' ? "Root" : element?.data[1].text.split("/")[element?.data[1].text.split("/").length - 1] ) | translate }} </span>
                                                    </ng-container>
                                                    <ng-template #elseTemplateLoc>
                                                        <ng-container *ngIf="element?.data.length == 1; else elseTemplateOnlyOneLoc">
                                                            <span>{{ "toA" | translate }} </span>
                                                            <span>{{ ( element?.data[0].text == '/' ? "Root" : element?.data[0].text.split("/")[element?.data[0].text.split("/").length - 1] ) | translate }} </span>
                                                        </ng-container>
                                                        <ng-template #elseTemplateOnlyOneLoc>
                                                            {{ element?.data | json }}
                                                        </ng-template>
                                                        <!-- <span [innerHTML]="element?.data?.text | linky:{newWindow: true}"></span> -->
                                                    </ng-template>
                                                </div>
                                                <!-- <div *ngSwitchCase="'task_operation_comment'" class="alert_value">
                                                    {{ element?.data?.text }}
                                                </div>
                                                <div *ngSwitchCase="'task_operation_private_comment'" class="alert_value">
                                                    {{ element?.data?.text }}
                                                </div>
                                                <div *ngSwitchCase="'task_operation_employee_comment'" class="alert_value">
                                                    {{ element?.data?.text }}
                                                </div> -->
                                                <div *ngSwitchCase="'task_operation_completed'" class="alert_value">
                                                    <ng-container *ngIf="element.task_operation_completed_at != '0'; else elseComplTemplate">
                                                        <div matTooltip="{{ element.task_operation_completed_at*1000 | date:'shortTime':'':activeLang }}" matTooltipClass="dateTooltip" [matTooltipPosition]="'above'">
                                                            {{ element.task_operation_completed_at*1000 | date:'mediumDate':'':activeLang | yearPipe }} 
                                                        </div>
                                                    </ng-container>
                                                    <ng-template #elseComplTemplate>
                                                        {{ "Without date" | translate }}
                                                    </ng-template>
                                                </div>
                                                <div *ngSwitchCase="'task_operation_price'" class="alert_value">
                                                    <ng-container [ngSwitch]="element?.task_operation_price_currency_id">
                                                        <ng-container *ngSwitchCase="'1'">&#36;</ng-container>
                                                        <ng-container *ngSwitchCase="'2'">&#8381;</ng-container>
                                                        <ng-container *ngSwitchCase="'3'">&#8364;</ng-container>
                                                        <ng-container *ngSwitchCase="'4'">&#8372;</ng-container>
                                                    </ng-container>{{ element?.task_operation_price | reportsNumber }}
                                                </div>
                                                <div *ngSwitchCase="'discussion_status'" class="alert_value not_link" (click)="openChat(element)">
                                                    {{ getChatStatus(element?.discussion_status_id) | translate }}
                                                </div>
                                                <div *ngSwitchCase="'task_operation_rate'" class="alert_value">
                                                    {{ element?.task_operation_rate | reportsNumber }}
                                                </div>
                                                <div *ngSwitchCase="'task_operation_employee'" class="alert_value user_div">
                                                    <ng-container *ngIf="!!employees?.length">
                                                        <app-user [class]="'user_div'" [employee]="getEmployee(element?.task_operation_employee_id)"></app-user>
                                                    </ng-container>
                                                </div>
                                                <div *ngSwitchCase="'task_operation_operation'" class="alert_value">
                                                    {{ getColumnName(element?.task_operation_operation_id) }}
                                                </div>
                                                <div *ngSwitchCase="'task_parameter_value_created'" class="alert_value">
                                                    <b class="mr-1">{{ element.parameterValue.value }} ({{element.parameterValue.parameter.name}})</b>
                                                    <span *ngIf="element.file_id">{{ "for" | translate }} {{ "File" | translate }}: {{element?.file?.filename}}</span>
                                                    <span *ngIf="element.discussion_id" style="cursor: pointer;" (click)="openChat(element)">{{ "for" | translate }} {{ "Chat" | translate }}: {{element?.discussion?.name}}</span>
                                                </div>
                                                <div *ngSwitchCase="'task_parameter_value_deleted'" class="alert_value">
                                                    <b class="mr-1">{{ element.parameterValue.value }} ({{element.parameterValue.parameter.name}})</b>
                                                    <span *ngIf="element.file_id">{{ "fromA" | translate }} {{ "File" | translate }}: {{element?.file?.filename}}</span>
                                                    <span *ngIf="element.discussion_id" style="cursor: pointer;" (click)="openChat(element)">{{ "fromA" | translate }} {{ "Chat" | translate }}: {{element?.discussion?.name}}</span>
                                                </div>
                                                <div *ngSwitchCase="'task_channel_content_type'" class="alert_value">
                                                    <ng-container *ngIf="element?.from_integer">
                                                        <span>{{ "fromA" | translate }} </span>
                                                        <span>{{ getContentById(element?.from_integer)?.name }} </span>
                                                    </ng-container>
                                                    <span>{{ "toA" | translate }} </span>
                                                    <span>{{ getContentById(element?.to_integer)?.name }} </span>
                                                </div>
                                                <div *ngSwitchDefault class="alert_value">
                                                    <span class="file_name" *ngIf="element?.file_id">{{element?.file?.filename}}</span>
                                                    <ng-container *ngIf="element?.data.length > 1; else elseTemplateSwitch">
                                                        <span>{{ "fromA" | translate }} </span>
                                                        <span>{{ element?.data[0].text }} </span>
                                                        <span>{{ "toA" | translate }} </span>
                                                        <span>{{ element?.data[1].text }} </span>
                                                    </ng-container>
                                                    <ng-template #elseTemplateSwitch>
                                                        <ng-container *ngIf="element?.data.length == 1; else elseTemplateOnlyOne">
                                                            <span>{{ "toA" | translate }} </span>
                                                            <span>{{ element?.data[0].text }} </span>
                                                        </ng-container>
                                                        <ng-template #elseTemplateOnlyOne>
                                                            {{element?.data | json}}
                                                        </ng-template>
                                                        <!-- <span [innerHTML]="element?.data?.text | linky:{newWindow: true}"></span> -->
                                                    </ng-template>
                                                </div>
                                            </ng-container>
                                  
                                        </div>
               
                                    </div>
                              </td>
                            </ng-container>
                        </ng-container>
                        
                        <ng-container matColumnDef="Job">
                            <th mat-header-cell *matHeaderCellDef>{{ "Work" | translate }}  \ {{"Post" | translate}}</th>
                            <td mat-cell class="white_s" *matCellDef="let element"> 
                                <div class="post_id" *ngIf="element.task_channel_id">
                                    {{"Post ID" | translate}}: <b>{{element.task_channel_id}}</b>
                                </div>
                                <div class="job_not not_link" (click)="openJob(element?.task_id, element?.task_operation_id)">
                                    <div class="type">{{ getColumnName(element?.taskOperation?.operation_id) }}</div>
                                    <div class="comment text_one_line" *ngIf="element?.taskOperation?.name">{{ element?.taskOperation?.name.length > 60 ? (element?.taskOperation?.name | slice:0:60) + " ..." : element?.taskOperation?.name }}</div>
                                </div>
                            </td>
                        </ng-container>
        
                          <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
                        <ng-container matColumnDef="expandedDetail">
                            <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                            <div class="example-element-detail"
                                [@detailExpand]="element.isExpand ? 'expanded' : 'collapsed'">
                                <!-- <div class="example-element-description" *ngFor="let action of element.subActions">
                                    {{action?.id}}
                                    <span class="example-element-description-attribution"> -- Wikipedia </span>
                                </div> -->
                                <table class="nots_table" mat-table [dataSource]="element.subActions" multiTemplateDataRows>
        
                                    <ng-container matColumnDef="Expand">
                                        <td mat-cell *matCellDef="let action">
                                            <button style="visibility: hidden;" mat-icon-button aria-label="expand row" (click)="expandAction($event,action)">
                                                <mat-icon *ngIf="!action.isExpand">keyboard_arrow_down</mat-icon>
                                                <mat-icon *ngIf="action.isExpand">keyboard_arrow_up</mat-icon>
                                            </button>
                                        </td>
                                    </ng-container>
                              
                                    <ng-container matColumnDef="Date">
                                      <td mat-cell *matCellDef="let action">
                                          <ng-container *ngIf="action.created_at != '0'">                          
                                            <div *ngIf="todaysDate == getDay(action.created_at*1000); else notToday;">
                                                {{ action.created_at*1000 | date:'shortTime':'':activeLang }}
                                            </div>
                                            <ng-template #notToday>
                                                <div matTooltip="{{ action.created_at*1000 | date:'shortTime':'':activeLang }}" matTooltipClass="dateTooltip" [matTooltipPosition]="'above'">
                                                    {{ action.created_at*1000 | date:'mediumDate':'timeZone':activeLang | yearPipe }} 
                                                </div>
                                            </ng-template>
                                          </ng-container>
                                      </td>
                                    </ng-container>
                    
                                    <ng-container matColumnDef="User">
                                        <td mat-cell *matCellDef="let action"> 
                                            <div class="user_div">
                                                <ng-container *ngIf="!!action?.createdEmployee?.image; else elseOwnerTemplate">
                                                    <img [src]="action?.createdEmployee?.image" alt="">
                                                </ng-container>
                                                <ng-template #elseOwnerTemplate>
                                                    <mat-icon>account_circle</mat-icon>
                                                </ng-template>
                                                <span>{{action?.createdEmployee?.name}}</span>
                                            </div>
                                        </td>
                                    </ng-container>
                    
                                    <ng-container>
                                        <ng-container matColumnDef="Info">
                                          <td mat-cell *matCellDef="let action">
                                                <ng-template #relationTaskTemplate>
                                                    <div class="task_ids_wrp not_link" *ngIf="action?.relationTask" (click)="openTask(action.relationTask)">
                                                        {{ action.relationTask.name.length > 60 ? (action.relationTask.name | slice:0:60) + " ..." : action.relationTask.name }}
                                                        <div class="task_ids">
                                                            {{action.relationTask.custom_id}} 
                                                            <span>
                                                                <div>{{action.relationTask.internal_id}}</div>
                                                                <div>{{action.relationTask.id}}</div>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </ng-template>
                                                <div class="not_info">
                                                    <div class="task_ids_wrp not_link" *ngIf="!!data?.header && action?.task" (click)="openTask(action.task)">
                                                        {{ action.task.name.length > 60 ? (action.task.name | slice:0:60) + " ..." : action.task.name }}
                                                        <div class="task_ids">
                                                            {{action.task.custom_id}} 
                                                            <span>
                                                                <div>{{action.task.internal_id}}</div>
                                                                <div>{{action.task.id}}</div>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="value" [ngClass]="{'d-f-c': action.action == 'task_channel_channel'}">
                                                        <b class="action">{{action.action | translate }}: </b>
                                              
                                                        <ng-container [ngSwitch]="action.action">
                                                            <ng-container *ngSwitchCase="'content_plan_deleted'" class="alert_value">
                                                                <ng-container *ngIf="action.parsedData && action.parsedData.attributes">
                                                                    <b class="ml-1">{{action.parsedData.attributes.name}}</b>
                                                                    <span class="ml-1">
                                                                    </span>
                                                                    <app-outlet-line class="ml-1" [channel]="getProfile(action.parsedData.attributes.channel_id)" [company_id]="data.company.id"></app-outlet-line>
                                                                    <span class="ml-1">{{getContentById(action.parsedData.attributes.content_type_id)?.name}}</span>
                                                                    <span class="ml-1">{{action.parsedData.attributes.publish_at*1000 | date:'MMM d, y, hh:mm a':'': activeLang}}</span>
                                                                </ng-container>
                                                            </ng-container>
                                                            <ng-container *ngSwitchCase="'content_schedule_deleted'" class="alert_value">
                                                                <ng-container *ngIf="action.parsedData && action.parsedData.attributes">
                                                                    <b class="ml-1">{{action.parsedData.attributes.name}}</b>
                                                                    <span class="ml-1">
                                                                    </span>
                                                                    <app-outlet-line class="ml-1" [channel]="getProfile(action.parsedData.attributes.channel_id)" [company_id]="data.company.id"></app-outlet-line>
                                                                    <span class="ml-1">{{getContentById(action.parsedData.attributes.content_type_id)?.name}}</span>
                                                                    <span *ngIf="action.parsedData.attributes.is_daily" class="ml-1">
                                                                        {{ "Every day" | translate }}
                                                                    </span>
                                                                    <span *ngIf="action.parsedData.attributes.is_weekly" class="ml-1">
                                                                        {{ "Every week" | translate }}
                                                                    </span>
                                                                    <span *ngIf="action.parsedData.attributes.is_monthly" class="ml-1">
                                                                        {{ "Every month" | translate }}
                                                                    </span>
                                                                </ng-container>
                                                            </ng-container>
                                                            <ng-container *ngSwitchCase="'content_schedule_item_deleted'" class="alert_value">
                                                                <ng-container *ngIf="action.parsedData && action.parsedData.attributes">
                                                                    <b class="ml-1">{{action.parsedData.attributes.name}}</b>
                                                                    <span class="ml-1">
                                                                    </span>
                                                                    <app-outlet-line [channel]="getProfile(action.parsedData.attributes.channel_id)" [company_id]="data.company.id"></app-outlet-line>
                                                                    <span class="ml-1">{{getContentById(action.parsedData.attributes.content_type_id)?.name}}</span>
                                                                    <div class="chat_timestamp" class="ml-1">
                                                                        <ng-container>
                                                                            <span class="silver">{{(action.parsedData.attributes.time | fullTime:false:true:true).silverTime}}</span>
                                                                            <span class="black">{{(action.parsedData.attributes.time | fullTime:false:true:true).blackTime}}</span>
                                                                        </ng-container>
                                                                    </div>
                                                                </ng-container>
                                                            </ng-container>
                                                            <ng-container *ngSwitchCase="'task_status'" class="alert_value">
                                                                {{ getTaskStatusById(action.task_status_id)?.name }}
                                                            </ng-container>
                                                            <ng-container *ngSwitchCase="'task_channel_live_stream_pause'" class="alert_value">
                                                                <div class="ml-1 snippet">
                                                                    Stream ID: {{action.task_channel_id}}
                                                                </div>
                                                                <div class="ml-1 snippet">
                                                                    Broadcast ID: {{action.task_channel_live_stream_id }}
                                                                </div>
                                                                <div class="ml-1 d-f-c chat_timestamp" *ngIf="!!action.to_integer" #tooltip="matTooltip"
                                                                matTooltip="{{ 'Stream paused duration' | translate }}"
                                                                [matTooltipPosition]="'above'">
                                                                    <mat-icon style="width: 10px; height: 10px; font-size: 10px; line-height: 10px; color: #C9C9C9; margin-right: 1px;">motion_photos_pause</mat-icon>
                                                                    <!-- <span class="silver">{{(action?.to_integer | fullTime:false:true:true).silverTime}}</span>
                                                                    <span class="black">{{(action?.to_integer | fullTime:false:true:true).blackTime}}</span> -->
                                                                    <span class="black">{{action?.to_integer | timeFormat}}</span>
                                                                </div>
                                                            </ng-container>
                                                            <!-- ['task_consist_of_created','task_consist_of_removed','task_part_of_created','task_part_of_removed','task_related_created','task_related_removed'].includes(action.action) -->
                                                            <ng-container *ngSwitchCase="'task_consist_of_created'" class="alert_value">
                                                                <ng-container *ngTemplateOutlet="relationTaskTemplate"></ng-container>
                                                            </ng-container>
                                                            <ng-container *ngSwitchCase="'task_consist_of_removed'" class="alert_value">
                                                                <ng-container *ngTemplateOutlet="relationTaskTemplate"></ng-container>
                                                            </ng-container>
                                                            <ng-container *ngSwitchCase="'task_part_of_created'" class="alert_value">
                                                                <ng-container *ngTemplateOutlet="relationTaskTemplate"></ng-container>
                                                            </ng-container>
                                                            <ng-container *ngSwitchCase="'task_part_of_removed'" class="alert_value">
                                                                <ng-container *ngTemplateOutlet="relationTaskTemplate"></ng-container>
                                                            </ng-container>
                                                            <ng-container *ngSwitchCase="'task_related_created'" class="alert_value">
                                                                <ng-container *ngTemplateOutlet="relationTaskTemplate"></ng-container>
                                                            </ng-container>
                                                            <ng-container *ngSwitchCase="'task_related_removed'" class="alert_value">
                                                                <ng-container *ngTemplateOutlet="relationTaskTemplate"></ng-container>
                                                            </ng-container>
                    
                                                            
                                                            <!-- <div *ngSwitchCase="'task_comment'" class="alert_value">
                                                                {{ action?.data?.text }}
                                                            </div>
                                                            <div *ngSwitchCase="'task_private_comment'" class="alert_value">
                                                                {{ action?.data?.text }}
                                                            </div> -->
                                                            <div *ngSwitchCase="'task_channel_channel'" class="alert_value profile_value">
                                                                <span>{{ "fromA" | translate }} </span>
                                                                <div class="outlet_block">
                                                                    <ng-container [ngTemplateOutlet]="platformTemplate" [ngTemplateOutletContext]="{ platform_id: profileData(action.from_task_channel_channel_id)?.platform_id }"></ng-container>
                                                                    {{ profileData(action.from_task_channel_channel_id).name }}
                                                                </div>
                                                                <span>{{ "toA" | translate }} </span>
                                                                <div class="outlet_block">
                                                                    <ng-container [ngTemplateOutlet]="platformTemplate" [ngTemplateOutletContext]="{ platform_id: profileData(action.task_channel_channel_id)?.platform_id }"></ng-container>
                                                                    {{ profileData(action.task_channel_channel_id)?.name }}
                                                                </div>
                                                            </div>
                                                            <div *ngSwitchCase="'task_channel_content_status'" class="alert_value">
                                                                <span>{{ "fromA" | translate }} </span>
                                                                {{ profileStatusesData(action?.from_integer)?.name | translate }}
                                                                <span>{{ "toA" | translate }} </span>
                                                                {{ profileStatusesData(action?.to_integer)?.name | translate }}
                                                            </div>
                                                            <div *ngSwitchCase="'task_channel_content_published_at'" class="alert_value">
                                                                <span>{{ "fromA" | translate }} </span>
                                                                {{ action?.from_integer*1000 | date:'MMM dd, Y':'+0000': activeLang | yearPipe }}
                                                                <span>{{ "toA" | translate }} </span>
                                                                {{ action?.to_integer*1000 | date:'MMM dd, Y':'+0000': activeLang | yearPipe }}
                                                            </div>
                    
                                                            <div *ngSwitchCase="'task_employee_assigned'" class="alert_value" style="display: inline-flex; align-items:center">
                                                                <ng-container *ngIf="!!employees?.length">
                                                                    <app-user [class]="'user_div'" [employee]="getEmployee(action?.employee_id)"></app-user>
                                                                </ng-container>
                                                            </div>
                                                            <div *ngSwitchCase="'task_employee_is_manager'" class="alert_value">
                                                                {{ action?.task_employee_is_manager ? ( "manager" | translate ) : ( "not manager" | translate ) }}
                                                            </div>
                                                            <div *ngSwitchCase="'task_employee_rate'" class="alert_value">
                                                                {{ action?.task_employee_rate | reportsNumber }}
                                                            </div>
                                                            <div *ngSwitchCase="'task_employee_price'" class="alert_value">
                                                                {{ action?.task_employee_price | reportsNumber }}
                                                            </div>
                                                            <div *ngSwitchCase="'task_employee_status'" class="alert_value">
                                                                {{ getOperationStatusName(action?.task_employee_status) }}
                                                            </div>
                                                            <div *ngSwitchCase="'task_employee_completed'" class="alert_value">
                                                                {{ action?.task_employee_completed_at*1000 | date:'MMM dd, Y':'+0000': activeLang | yearPipe }}
                                                            </div>
        
                                                            <div *ngSwitchCase="'task_channel_created'" class="alert_value">
                                                                <ng-container [ngTemplateOutlet]="platformTemplate" [ngTemplateOutletContext]="{ platform_id: profileData(action.task_channel_channel_id)?.platform_id }"></ng-container>
                                                                {{ profileData(action.task_channel_channel_id)?.name }}
                                                            </div>
                                                            <div *ngSwitchCase="'task_channel_deleted'" class="alert_value">
                                                                <ng-container [ngTemplateOutlet]="platformTemplate" [ngTemplateOutletContext]="{ platform_id: profileData(action.task_channel_channel_id)?.platform_id }"></ng-container>
                                                                {{ profileData(action.task_channel_channel_id)?.name }}
                                                            </div>
                    
                                                            <div *ngSwitchCase="'task_operation_status'" class="alert_value">
                                                                {{ getOperationStatusName(action?.task_operation_status_id) }}
                                                            </div>
                                                            <div *ngSwitchCase="'document_created'" class="alert_value">
                                                                {{ element?.document?.name }}
                                                            </div>
                                                            <div *ngSwitchCase="'document_updated'" class="alert_value">
                                                                {{ element?.document?.name }}
                                                            </div>
                                                            <div *ngSwitchCase="'task_employee_status_start_at'" class="alert_value">
                                                                {{ action.task_employee_start_at*1000 | date:'MMM dd. Y, hh:mm a':'':activeLang | yearPipe }}
                                                            </div>
                                                            <div *ngSwitchCase="'task_employee_status_change'" class="alert_value" style="display: inline-flex; align-items:center">
                                                                {{ getOperationStatusName(action?.task_employee_status_id) }}
                                                                <span class="ml-1 mr-1">{{ "for" | translate }}</span>
                                                                <ng-container *ngIf="!!employees?.length">
                                                                    <app-user [class]="'user_div'" [employee]="getEmployee(action?.employee_id)"></app-user>
                                                                </ng-container>
                                                            </div>
                                                            <div *ngSwitchCase="'task_custom_id'" class="alert_value">
                                                                <div class="d-f-c">
                                                                    {{ action?.task_old_custom_id}}<mat-icon class="mr-1 ml-1">arrow_right_alt</mat-icon>{{ action?.task_custom_id }}
                                                                </div>
                                                            </div>
                                                            <div *ngSwitchCase="'discussion_post_added'" class="alert_value not_link"  (click)="openChat(action)">
                                                                {{ action?.discussion?.name }}
                                                            </div>
                                                            <div *ngSwitchCase="'file_created'" class="alert_value">
                                                                <span class="file_name">{{action?.file?.filename}}</span>
                                                            </div>
                                                            <div *ngSwitchCase="'file_location'" class="alert_value">
                                                                <span class="file_name">{{action?.file?.filename}}</span>
                                                                
                                                                <ng-container *ngIf="action?.data.length > 1; else elseTemplateLoc">
                                                                    <span>{{ "fromA" | translate }} </span>
                                                                    <span>{{ ( action?.data[0].text == '/' ? "Root" : action?.data[0].text.split("/")[action?.data[0].text.split("/").length - 1] ) | translate }} </span>
                                                                    <span>{{ "toA" | translate }} </span>
                                                                    <span>{{ ( action?.data[1].text == '/' ? "Root" : action?.data[1].text.split("/")[action?.data[1].text.split("/").length - 1] ) | translate }} </span>
                                                                </ng-container>
                                                                <ng-template #elseTemplateLoc>
                                                                    <ng-container *ngIf="action?.data.length == 1; else elseTemplateOnlyOneLoc">
                                                                        <span>{{ "toA" | translate }} </span>
                                                                        <span>{{ ( action?.data[0].text == '/' ? "Root" : action?.data[0].text.split("/")[action?.data[0].text.split("/").length - 1] ) | translate }} </span>
                                                                    </ng-container>
                                                                    <ng-template #elseTemplateOnlyOneLoc>
                                                                        {{ action?.data | json }}
                                                                    </ng-template>
                                                                    <!-- <span [innerHTML]="action?.data?.text | linky:{newWindow: true}"></span> -->
                                                                </ng-template>
                                                            </div>
                                                            <!-- <div *ngSwitchCase="'task_operation_comment'" class="alert_value">
                                                                {{ action?.data?.text }}
                                                            </div>
                                                            <div *ngSwitchCase="'task_operation_private_comment'" class="alert_value">
                                                                {{ action?.data?.text }}
                                                            </div>
                                                            <div *ngSwitchCase="'task_operation_employee_comment'" class="alert_value">
                                                                {{ action?.data?.text }}
                                                            </div> -->
                                                            <div *ngSwitchCase="'task_operation_completed'" class="alert_value">
                                                                <ng-container *ngIf="action.task_operation_completed_at != '0'; else elseComplTemplate">
                                                                    <div matTooltip="{{ action.task_operation_completed_at*1000 | date:'shortTime':'':activeLang }}" matTooltipClass="dateTooltip" [matTooltipPosition]="'above'">
                                                                        {{ action.task_operation_completed_at*1000 | date:'mediumDate':'':activeLang | yearPipe }} 
                                                                    </div>
                                                                </ng-container>
                                                                <ng-template #elseComplTemplate>
                                                                    {{ "Without date" | translate }}
                                                                </ng-template>
                                                            </div>
                                                            <div *ngSwitchCase="'task_operation_price'" class="alert_value">
                                                                <ng-container [ngSwitch]="action?.task_operation_price_currency_id">
                                                                    <ng-container *ngSwitchCase="'1'">&#36;</ng-container>
                                                                    <ng-container *ngSwitchCase="'2'">&#8381;</ng-container>
                                                                    <ng-container *ngSwitchCase="'3'">&#8364;</ng-container>
                                                                    <ng-container *ngSwitchCase="'4'">&#8372;</ng-container>
                                                                </ng-container>{{ action?.task_operation_price | reportsNumber }}
                                                            </div>
                                                            <div *ngSwitchCase="'discussion_status'" class="alert_value not_link" (click)="openChat(action)">
                                                                {{ getChatStatus(action?.discussion_status_id) | translate }}
                                                            </div>
                                                            <div *ngSwitchCase="'task_operation_rate'" class="alert_value">
                                                                {{ action?.task_operation_rate | reportsNumber }}
                                                            </div>
                                                            <div *ngSwitchCase="'task_operation_employee'" class="alert_value user_div">
                                                                <ng-container *ngIf="!!employees?.length">
                                                                    <app-user [class]="'user_div'" [employee]="getEmployee(action?.task_operation_employee_id)"></app-user>
                                                                </ng-container>
                                                            </div>
                                                            <div *ngSwitchCase="'task_operation_operation'" class="alert_value">
                                                                {{ getColumnName(action?.task_operation_operation_id) }}
                                                            </div>
                                                            <div *ngSwitchCase="'task_parameter_value_created'" class="alert_value">
                                                                <b class="mr-1">{{ action.parameterValue.value }} ({{action.parameterValue.parameter.name}})</b>
                                                                <span *ngIf="action.file_id">{{ "for" | translate }} {{ "File" | translate }}: {{action?.file?.filename}}</span>
                                                                <span *ngIf="action.discussion_id" style="cursor: pointer;" (click)="openChat(action)">{{ "for" | translate }} {{ "Chat" | translate }}: {{action?.discussion?.name}}</span>
                                                            </div>
                                                            <div *ngSwitchCase="'task_parameter_value_deleted'" class="alert_value">
                                                                <b class="mr-1">{{ action.parameterValue.value }} ({{action.parameterValue.parameter.name}})</b>
                                                                <span *ngIf="action.file_id">{{ "fromA" | translate }} {{ "File" | translate }}: {{action?.file?.filename}}</span>
                                                                <span *ngIf="action.discussion_id" style="cursor: pointer;" (click)="openChat(action)">{{ "fromA" | translate }} {{ "Chat" | translate }}: {{action?.discussion?.name}}</span>
                                                            </div>
                                                            <div *ngSwitchDefault class="alert_value">
                                                                <span class="file_name" *ngIf="action?.file_id">{{action?.file?.filename}}</span>
                                                                <ng-container *ngIf="action?.data.length > 1; else elseTemplateSwitch">
                                                                    <span>{{ "fromA" | translate }} </span>
                                                                    <span>{{ action?.data[0].text }} </span>
                                                                    <span>{{ "toA" | translate }} </span>
                                                                    <span>{{ action?.data[1].text }} </span>
                                                                </ng-container>
                                                                <ng-template #elseTemplateSwitch>
                                                                    <ng-container *ngIf="action?.data.length == 1; else elseTemplateOnlyOne">
                                                                        <span>{{ "toA" | translate }} </span>
                                                                        <span>{{ action?.data[0].text }} </span>
                                                                    </ng-container>
                                                                    <ng-template #elseTemplateOnlyOne>
                                                                        {{action?.data | json}}
                                                                    </ng-template>
                                                                    <!-- <span [innerHTML]="action?.data?.text | linky:{newWindow: true}"></span> -->
                                                                </ng-template>
                                                            </div>
                                                        </ng-container>
                                              
                                                    </div>
                           
                                                </div>
                                          </td>
                                        </ng-container>
                                    </ng-container>
                                    
                                    <ng-container matColumnDef="Job">
                                        <td mat-cell class="white_s" *matCellDef="let action"> 
                                            <div class="post_id" *ngIf="action.task_channel_id">
                                                {{"Post ID" | translate}}: <b>{{action.task_channel_id}}</b>
                                            </div>
                                            <div class="job_not not_link" (click)="openJob(action?.task_id, action?.task_operation_id)">
                                                <div class="type">{{ getColumnName(action?.taskOperation?.operation_id) }}</div>
                                                <div class="comment text_one_line" *ngIf="action?.taskOperation?.name">{{ action?.taskOperation?.name.length > 60 ? (action?.taskOperation?.name | slice:0:60) + " ..." : action?.taskOperation?.name }}</div>
                                            </div>
                                        </td>
                                    </ng-container>
                    
                                    <tr mat-row *matRowDef="let action; columns: displayedColumns;" [ngClass]="{'highlight_not': data?.header ? action.is_action_read != '1' && data?.user.id != action?.user_id : action.is_read != '1'}"></tr>
                                </table>
                            </div>
                            </td>
                        </ng-container>
        
                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                        <tr mat-row *matRowDef="let element; columns: displayedColumns;" [ngClass]="{'example-expanded-row': element.isExpand, 'highlight_not': data?.header ? element.is_action_read != '1' && data?.user.id != element?.user_id : element.is_read != '1'}"></tr>
                        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
                    </table>
                </div>
            </ng-template>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="start">
        <div class="f_w_p_btns">
            <button mat-flat-button (click)="close()">
                {{ "Close" | translate }}
            </button>
    
            <button *ngIf="tab == 1" mat-raised-button color="primary" (click)="getMore()">
                {{ "More" | translate }}
            </button>
        </div>
    </mat-dialog-actions>
</ng-container>
<ng-template #elseTemplateNotMobile>
    <div mat-dialog-title class="mobile_interface_title">
        <div class="mobile_interface_title_in" style="overflow: hidden;">
            <div class="tab_title" (click)="mobClose()">
                <div class="d-f-c">
                    <div class="icon_back">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7 12L6.11612 11.1161C5.62796 11.6043 5.62796 12.3957 6.11612 12.8839L7 12ZM16.8839 3.88388C17.372 3.39573 17.372 2.60427 16.8839 2.11612C16.3957 1.62796 15.6043 1.62796 15.1161 2.11612L16.8839 3.88388ZM15.1161 21.8839C15.6043 22.372 16.3957 22.372 16.8839 21.8839C17.372 21.3957 17.372 20.6043 16.8839 20.1161L15.1161 21.8839ZM7.88388 12.8839L16.8839 3.88388L15.1161 2.11612L6.11612 11.1161L7.88388 12.8839ZM6.11612 12.8839L15.1161 21.8839L16.8839 20.1161L7.88388 11.1161L6.11612 12.8839Z" fill="#232323"/>
                        </svg>
                    </div>
                    <span>
                        {{ "Activity" | translate }}
                    </span>
                </div>
            </div>
            <div class="out_tabs">
                <span [ngClass]="{'active': tab == 0}" (click)="changeTab(0)">{{"Snapshot"|translate}}</span>
                <span [ngClass]="{'active': tab == 1}" (click)="changeTab(1)">{{"Feed"|translate}}</span>
                <span [ngClass]="{'active': is_filter_active}" (click)="toFilter()">{{"Filter"|translate}}</span>
            </div>
        </div>
    </div>
    <mat-dialog-content class="mobile_interface_content">
        <div class="mobile_scrollable_int_big" style="background: #fff;">
            <ng-container *ngIf="tab == 0; else elseTemplateFilterMob">
                <ng-container *ngIf="is_filter_active; else elseTemplateMobFilFeed">    
                    <form [formGroup]="filter" class="mob_fil_act">
                        <mat-form-field appearance="outline" class="full_width_form">
                            <mat-label>{{ "Stakeholder" | translate }}</mat-label>
                            <mat-select [disableOptionCentering]="true" selectDir formControlName="employee_id" (selectionChange)="selectChange($event)">
                                <mat-option>
                                    <ngx-mat-select-search 
                                    placeholderLabel="{{ 'Search' | translate }}" 
                                    noEntriesFoundLabel="{{ 'no matching stakeholders found' | translate }}"
                                    [formControl]="employeesMoreControl"></ngx-mat-select-search>
                                </mat-option>
                                <mat-option [value]="0">
                                    <div class="user_div">
                                        <img style="visibility: hidden;" src="" alt="">
                                        <span>
                                            {{ "All stakeholders" | translate }}
                                        </span>
                                    </div>
                                </mat-option>
                                <mat-option *ngFor="let employee of employees$ | async" [value]="employee.id">
                                    <app-user [class]="'user_div'" [employee]="employee"></app-user>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="legacy" class="full_width_form">
                            <mat-label>{{ "Range" | translate }}</mat-label>
                            <mat-select [multiple]="false" #activityRange [disableOptionCentering]="true" selectDir [formControl]="activeFilterId" (selectionChange)="filterActivity()">                
                              <ng-container *ngFor="let fil of filters">
                                  <mat-option [value]="fil.id" class="range_filter_options" [ngClass]="{'active': activeFilterId.value == fil.id}">
                                      {{ fil.name | translate}}
                                  </mat-option>
                                  <mat-divider *ngIf="fil.divider"></mat-divider>
                              </ng-container>
                            </mat-select>
                        </mat-form-field>
                    </form>
                </ng-container>
                <ng-template #elseTemplateMobFilFeed>
                    <div class="outlets_dash mob_outlets_dash" [appDeviceClass]="'custom-scroll custom-scroll-t'"
                    infinite-scroll
                    [infiniteScrollDisabled]="isLoad"
                    [scrollWindow]="false"
                    [infiniteScrollUpDistance]="scrollUpDistance"
                    [infiniteScrollThrottle]="throttle"
                    (scrolled)="onDown($event)">
                        <form [formGroup]="filter" class="outlets_dash_header">
                            <div class="column empl_col">
                                <div class="title">
                                    {{ "Stakeholder" | translate }}
                                </div>
                                <div class="sub_titles">
                                    &nbsp;
                                </div>
                            </div>
                            <div class="column task_col">
                                <div class="title">
                                    {{ "Cards" | translate }}
                                </div>
                                <div class="sub_titles">
                                    <div class="sub">{{ "Created" | translate }}</div>
                                    <div class="sub">{{ "Edited" | translate }}</div>
                                </div>
                
                            </div>
                            <div class="column job_col">
                                <div class="title">
                                    {{ "Jobs" | translate }}
                                </div>
                                <div class="sub_titles">
                                    <div class="sub">{{ "Created" | translate }}</div>
                                    <div class="sub">{{ "Edited" | translate }}</div>
                                </div>
                            </div>
                            <div class="column chat_col">
                                <div class="title">
                                    {{ "Chats" | translate }}
                                </div>
                                <div class="sub_titles">
                                    <div class="sub">{{ "Created" | translate }}</div>
                                    <div class="sub">{{ "Edited" | translate }}</div>
                                </div>
                            </div>
                            <div class="column ass_col">
                                <div class="title">
                                    {{ "Assignments" | translate }}
                                </div>
                                <div class="sub_titles">
                                    <div class="sub" style="width: 100%;">{{ "Recieved / performed" | translate }}</div>
                                </div>
                            </div>
                            <div class="column file_col">
                                <div class="title">
                                    {{ "Files" | translate }}
                                </div>
                                <div class="sub_titles">
                                    <div class="sub">{{ "Created" | translate }}</div>
                                    <div class="sub">{{ "Edited" | translate }}</div>
                                </div>
                            </div>
                            <div class="column post_col">
                                <div class="title">
                                    {{ "Posts" | translate }}
                                </div>
                                <div class="sub_titles">
                                    <div class="sub">{{ "Added" | translate }}</div>
                                    <div class="sub">{{ "Edited" | translate }}</div>
                                </div>
                            </div>
                            <div class="exp_col"></div>
                        </form>
                
                        <div class="outlet" *ngFor="let item of activity">
                            <div class="main" *ngIf="!item.is_expand">
                                <div class="column empl_col">
                                    <app-user [class]="'user_div'" [employee]="getEmployee(item.employee_id)"></app-user>
                                </div>
                                <div class="column task_col">
                                    <span class="normal">
                                        {{ +item?.tasks_created | number:'':'fr-FR'}}
                                    </span>
                                </div>
                                <div class="column job_col">
                                    <span class="normal">
                                        {{ +item?.task_operations_created | number:'':'fr-FR'}}
                                    </span>
                                </div>
                                <div class="column chat_col">
                                    <span class="normal">
                                        {{ +item?.discussions_created | number:'':'fr-FR'}}
                                    </span>
                                </div>
                                <div class="column ass_col">
                                    <span class="normal">
                                        {{ (+item?.task_operations_assigned + +item?.discussions_assigned) | number:'':'fr-FR'}}
                                    </span>
                                </div>
                                <div class="column file_col">
                                    <span class="normal">
                                        {{ +item?.files_uploaded | number:'':'fr-FR'}}
                                    </span>
                                </div>
                                <div class="column post_col">
                                    <span class="normal">
                                        {{ +item?.task_channels_combined | number:'':'fr-FR'}}
                                    </span>
                                </div>
                                <div class="exp_col">
                                    <div class="toggler" (click)="item.is_expand = !item.is_expand">
                                        <svg style="width: 19px; height: 10px;" width="19" height="11" viewBox="0 0 19 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1 1L9.5 9.5L18.5 1" stroke="black"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div class="exp_main" *ngIf="item.is_expand">
                                <div class="column empl_col">
                                    <app-user [class]="'user_div'" [employee]="getEmployee(item.employee_id)"></app-user>
                                </div>
                                <div class="column task_col">
                                    <div class="name_val big_name_val" style="padding-top: 0;">
                                        <div>
                                            <span>{{"Created"|translate}}</span>
                                            <span>{{ +item?.tasks_created | number:'':'fr-FR'}}</span>
                                        </div>
                                    </div>
                                    <div class="name_val" *ngIf="item?.tasks_created_direct > 0">
                                        <div>
                                            <span>{{"Directly"|translate}}</span>
                                            <span>{{ +item?.tasks_created_direct | number:'':'fr-FR'}}</span>
                                        </div>
                                    </div>
                                    <div class="name_val" *ngIf="item?.tasks_created_file > 0">
                                        <div>
                                            <span>{{"By the file"|translate}}</span>
                                            <span>{{ +item?.tasks_created_file | number:'':'fr-FR'}}</span>
                                        </div>
                                    </div>
                                    <!-- <div class="name_val exp_name_val">
                                        <div>
                                            <span class="cp" (click)="item.is_task_template = !item.is_task_template">{{"By template"|translate}} {{item.is_task_template ? "—" : "+"}}</span>
                                            <span>#</span>
                                        </div>
                                        <div class="sub_name_val" *ngIf="item?.is_task_template">
                                            <div>
                                                <span>{{"By the files"|translate}}</span>
                                                <span>#</span>
                                            </div>
                                            <div>
                                                <span>{{"Directly"|translate}}</span>
                                                <span>#</span>
                                            </div>
                                        </div>
                                    </div> -->
            
                                    <!-- <div class="group">
                                        {{"By task type"|translate}}:
                                    </div>
                                    <div class="name_val exp_name_val">
                                        <div>
                                            <span class="cp" (click)="item.is_task_content = !item.is_task_content">{{"Content"|translate}} {{item.is_task_content ? "—" : "+"}}</span>
                                            <span>#</span>
                                        </div>
            
                                        <div class="sub_name_val" *ngIf="item?.is_task_content">
                                            <div>
                                                <span>{{"Directly"|translate}}</span>
                                                <span>#</span>
                                            </div>
                                            <div>
                                                <span>{{"By scenario"|translate}}</span>
                                                <span>#</span>
                                            </div>
                                        </div>
                                    </div> -->
                                    <div class="name_val big_name_val">
                                        <div>
                                            <span>{{"Edited"|translate}}</span>
                                            <span>{{ +item?.tasks_updated | number:'':'fr-FR'}}</span>
                                        </div>
                                    </div>
                                    <div class="name_val exp_name_val" *ngIf="item?.tasks_updated_title > 0">
                                        <div>
                                            <span class="cp" (click)="item.is_task_title = !item.is_task_title">{{"Title"|translate}} {{item.is_task_title ? "—" : "+"}}</span>
                                            <span>{{ +item?.tasks_updated_title | number:'':'fr-FR'}}</span>
                                        </div>
                                        
                                        <div class="sub_name_val" *ngIf="item?.is_task_title">
                                            <div *ngIf="item?.tasks_updated_title_form > 0">
                                                <span>{{"In form"|translate}}</span>
                                                <span>{{ +item?.tasks_updated_title_form | number:'':'fr-FR'}}</span>
                                            </div>
                                            <div *ngIf="item?.tasks_updated_title_direct > 0">
                                                <span>{{"Directly"|translate}}</span>
                                                <span>{{ +item?.tasks_updated_title_direct | number:'':'fr-FR'}}</span>
                                            </div>
                                        </div>
                                    </div>
            
                                    <div class="name_val" *ngIf="item?.tasks_updated_description > 0">
                                        <div>
                                            <span>{{"Description"|translate}}</span>
                                            <span>{{ +item?.tasks_updated_description | number:'':'fr-FR'}}</span>
                                        </div>
                                    </div>
                                    <div class="name_val" *ngIf="item?.tasks_updated_status > 0">
                                        <div>
                                            <span>{{"Card type"|translate}}</span>
                                            <span>{{ +item?.tasks_updated_status | number:'':'fr-FR'}}</span>
                                        </div>
                                    </div>
                                    <div class="name_val" *ngIf="item?.tasks_updated_group > 0">
                                        <div>
                                            <span>{{"Group"|translate}}</span>
                                            <span>{{ +item?.tasks_updated_group | number:'':'fr-FR'}}</span>
                                        </div>
                                    </div>
           
                                    <div class="name_val exp_name_val" *ngIf="item?.tasks_updated_assignment > 0">
                                        <div>
                                            <span class="cp" (click)="item.is_task_stake = !item.is_task_stake">{{"Stakeholders"|translate}} {{item.is_task_stake ? "—" : "+"}}</span>
                                            <span>{{ +item?.tasks_updated_assignment | number:'':'fr-FR'}}</span>
                                        </div>
                                        
                                        <div class="sub_name_val" *ngIf="item?.is_task_stake">
                                            <div *ngIf="item?.tasks_updated_assignment_employee > 0">
                                                <span>{{"Individuals"|translate}}</span>
                                                <span>{{ +item?.tasks_updated_assignment_employee | number:'':'fr-FR'}}</span>
                                            </div>
                                            <div *ngIf="item?.tasks_updated_assignment_client > 0">
                                                <span>{{"Clients"|translate}}</span>
                                                <span>{{ +item?.tasks_updated_assignment_client | number:'':'fr-FR'}}</span>
                                            </div>
                                            <div *ngIf="item?.tasks_updated_assignment_partner > 0">
                                                <span>{{"Workspaces"|translate}}</span>
                                                <span>{{ +item?.tasks_updated_assignment_partner | number:'':'fr-FR'}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="name_val" *ngIf="item?.tasks_updated_id > 0">
                                        <div>
                                            <span>{{"ID"|translate}}</span>
                                            <span>{{ +item?.tasks_updated_id | number:'':'fr-FR'}}</span>
                                        </div>
                                    </div>
                                    <div class="name_val exp_name_val" *ngIf="item?.tasks_updated_relation > 0">
                                        <div>
                                            <span class="cp" (click)="item.is_task_relations = !item.is_task_relations">{{"Relations"|translate}} {{item.is_task_relations ? "—" : "+"}}</span>
                                            <span>{{ +item?.tasks_updated_relation | number:'':'fr-FR'}}</span>
                                        </div>
                                        
                                        <div class="sub_name_val" *ngIf="item?.is_task_relations">
                                            <div *ngIf="item?.tasks_updated_relation_consist_of > 0">
                                                <span>{{"Consist of"|translate}}</span>
                                                <span>{{ +item?.tasks_updated_relation_consist_of | number:'':'fr-FR'}}</span>
                                            </div>
                                            <div *ngIf="item?.tasks_updated_relation_part_of > 0">
                                                <span>{{"Part of"|translate}}</span>
                                                <span>{{ +item?.tasks_updated_relation_part_of | number:'':'fr-FR'}}</span>
                                            </div>
                                            <div *ngIf="item?.tasks_updated_relation_related > 0">
                                                <span>{{"Related"|translate}}</span>
                                                <span>{{ +item?.tasks_updated_relation_related | number:'':'fr-FR'}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="name_val" *ngIf="item?.tasks_updated_add_parameter_value > 0">
                                        <div>
                                            <span>{{"Tagged"|translate}}</span>
                                            <span>{{ +item?.tasks_updated_add_parameter_value | number:'':'fr-FR'}}</span>
                                        </div>
                                    </div>
                                    <!-- <div class="two_name_val">
                                        <div>
                                            <div>{{"In the car"|translate}}</div>
                                            <div class="small_two">{{"Environment"|translate}}</div>
                                        </div>
                                        <div>
                                            #
                                        </div>
                                    </div>
                                    <div class="two_name_val">
                                        <div>
                                            <div>{{"Running"|translate}}</div>
                                            <div class="small_two">{{"Activity"|translate}}</div>
                                        </div>
                                        <div>
                                            #
                                        </div>
                                    </div>
                                    <div class="two_name_val">
                                        <div>
                                            <div>{{"Comedy"|translate}}</div>
                                            <div class="small_two">{{"Genre"|translate}}</div>
                                        </div>
                                        <div>
                                            #
                                        </div>
                                    </div> -->
                                    <div class="name_val" *ngIf="item?.tasks_updated_delete_parameter_value > 0">
                                        <div>
                                            <span>{{"Tag Deleted"|translate}}</span>
                                            <span>{{ +item?.tasks_updated_delete_parameter_value | number:'':'fr-FR'}}</span>
                                        </div>
                                    </div>
                                    <!-- <div class="two_name_val">
                                        <div>
                                            <div>{{"In the car"|translate}}</div>
                                            <div class="small_two">{{"Environment"|translate}}</div>
                                        </div>
                                        <div>
                                            #
                                        </div>
                                    </div> -->
                                </div>
                                <div class="column job_col">
                                    <div class="name_val big_name_val" style="padding-top: 0;">
                                        <div>
                                            <span>{{"Created"|translate}}</span>
                                            <span>{{ +item?.task_operations_created | number:'':'fr-FR'}}</span>
                                        </div>
                                    </div>
                                    <!-- <div class="name_val">
                                        <div>
                                            <span>{{"Directly"|translate}}</span>
                                            <span>#</span>
                                        </div>
                                    </div>
                                    <div class="name_val">
                                        <div>
                                            <span>{{"By the file"|translate}}</span>
                                            <span>#</span>
                                        </div>
                                    </div>
                                    <div class="name_val">
                                        <div>
                                            <span>{{"By Scenario"|translate}}</span>
                                            <span>#</span>
                                        </div>
                                    </div>
            
                                    <div class="group">
                                        {{"By job type"|translate}}:
                                    </div>
                 
                                    <div class="name_val exp_name_val">
                                        <div>
                                            <span class="cp" (click)="item.is_job_coord = !item.is_job_coord">{{"Task coordination"|translate}} {{item.is_job_coord ? "—" : "+"}}</span>
                                            <span>#</span>
                                        </div>
                                        <div class="sub_name_val" *ngIf="item?.is_job_coord">
                                            <div>
                                                <span>{{"Directly"|translate}}</span>
                                                <span>#</span>
                                            </div>
                                            <div>
                                                <span>{{"By scenario"|translate}}</span>
                                                <span>#</span>
                                            </div>
                                            <div>
                                                <span>{{"By file"|translate}}</span>
                                                <span>#</span>
                                            </div>
                                        </div>
                                    </div>
            
                                    <div class="name_val">
                                        <div>
                                            <span>{{"Content Management Info"|translate}}</span>
                                            <span>#</span>
                                        </div>
                                    </div>
            
                                    <div class="name_val big_name_val">
                                        <div>
                                            <span>{{"Edited"|translate}}</span>
                                            <span>#</span>
                                        </div>
                                    </div>
                                    <div class="name_val">
                                        <div>
                                            <span>{{"Price"|translate}}</span>
                                            <span>#</span>
                                        </div>
                                    </div>
                                    <div class="name_val">
                                        <div>
                                            <span>{{"Time Finished"|translate}}</span>
                                            <span>#</span>
                                        </div>
                                    </div>
                                    <div class="name_val">
                                        <div>
                                            <span>{{"Job type"|translate}}</span>
                                            <span>#</span>
                                        </div>
                                    </div>
                                    <div class="name_val">
                                        <div>
                                            <span>{{"Title"|translate}}</span>
                                            <span>#</span>
                                        </div>
                                    </div>
                                    <div class="name_val">
                                        <div>
                                            <span>{{"Description"|translate}}</span>
                                            <span>#</span>
                                        </div>
                                    </div>
                                    <div class="name_val">
                                        <div>
                                            <span>{{"Executor"|translate}}</span>
                                            <span>#</span>
                                        </div>
                                    </div>
                                    <div class="name_val">
                                        <div>
                                            <span>{{"Priority"|translate}}</span>
                                            <span>#</span>
                                        </div>
                                    </div>
                                    <div class="name_val">
                                        <div>
                                            <span>{{"Tagged"|translate}}</span>
                                            <span>#</span>
                                        </div>
                                    </div> -->
                         
                                </div>
                                <div class="column chat_col">
                                    <div class="name_val big_name_val" style="padding-top: 0;">
                                        <div>
                                            <span>{{"Created"|translate}}</span>
                                            <span>{{ +item?.discussions_created | number:'':'fr-FR'}}</span>
                                        </div>
                                    </div>
                                    <div class="name_val" *ngIf="item?.discussions_created_direct > 0">
                                        <div>
                                            <span>{{"Directly"|translate}}</span>
                                            <span>{{ +item?.discussions_created_direct | number:'':'fr-FR'}}</span>
                                        </div>
                                    </div>
                                    <div class="name_val" *ngIf="item?.discussions_created_file > 0">
                                        <div>
                                            <span>{{"By the file"|translate}}</span>
                                            <span>{{ +item?.discussions_created_file | number:'':'fr-FR'}}</span>
                                        </div>
                                    </div>
                                    <div class="name_val" *ngIf="item?.discussions_created_scenario > 0">
                                        <div>
                                            <span>{{"By Scenario"|translate}}</span>
                                            <span>{{ +item?.discussions_created_scenario | number:'':'fr-FR'}}</span>
                                        </div>
                                    </div>
            
            
                                    <!-- <div class="name_val big_name_val">
                                        <div>
                                            <span>{{"Edited"|translate}}</span>
                                            <span>#</span>
                                        </div>
                                    </div>
                                    <div class="name_val">
                                        <div>
                                            <span>{{"Price"|translate}}</span>
                                            <span>#</span>
                                        </div>
                                    </div>
                                    <div class="name_val">
                                        <div>
                                            <span>{{"Time Finished"|translate}}</span>
                                            <span>#</span>
                                        </div>
                                    </div>
                                    <div class="name_val">
                                        <div>
                                            <span>{{"Priority"|translate}}</span>
                                            <span>#</span>
                                        </div>
                                    </div>
                                    <div class="name_val">
                                        <div>
                                            <span>{{"Title"|translate}}</span>
                                            <span>#</span>
                                        </div>
                                    </div>
                                    <div class="name_val">
                                        <div>
                                            <span>{{"Executor"|translate}}</span>
                                            <span>#</span>
                                        </div>
                                    </div>
            
                                    <div class="name_val">
                                        <div>
                                            <span>{{"Tagged"|translate}}</span>
                                            <span>#</span>
                                        </div>
                                    </div>
            
                                    <div class="name_val exp_name_val">
                                        <div>
                                            <span class="cp" (click)="item.is_chat_relations = !item.is_chat_relations">{{"Relations"|translate}} {{item.is_chat_relations ? "—" : "+"}}</span>
                                            <span>#</span>
                                        </div>
                                        
                                        <div class="sub_name_val" *ngIf="item?.is_chat_relations">
                                            <div>
                                                <span>{{"Consist of"|translate}}</span>
                                                <span>#</span>
                                            </div>
                                            <div>
                                                <span>{{"Part of"|translate}}</span>
                                                <span>#</span>
                                            </div>
                                            <div>
                                                <span>{{"Related"|translate}}</span>
                                                <span>#</span>
                                            </div>
                                        </div>
                                    </div> -->
                                </div>
                                <div class="column ass_col">
                                    <div class="name_val big_name_val" style="padding-top: 0;">
                                        <div>
                                            <span>{{"Assigned to"|translate}}</span>
                                            <span>{{ (+item?.task_operations_assigned + +item?.discussions_assigned) | number:'':'fr-FR'}}</span>
                                        </div>
                                    </div>
                                    <div class="name_val" *ngIf="item?.discussions_assigned > 0">
                                        <div>
                                            <span>{{"Chats"|translate}}</span>
                                            <span>{{ item?.discussions_assigned | number:'':'fr-FR'}}</span>
                                        </div>
                                    </div>
                                    <div class="name_val" *ngIf="item?.task_operations_assigned > 0">
                                        <div>
                                            <span>{{"Jobs"|translate}}</span>
                                            <span>{{ item?.task_operations_assigned | number:'':'fr-FR'}}</span>
                                        </div>
                                    </div>
            
                                    <!-- <div class="name_val big_name_val">
                                        <div>
                                            <span>{{"Progress"|translate}}</span>
                                            <span>#</span>
                                        </div>
                                    </div>
            
                                    <div class="divider_title">
                                        {{"Sent" | translate}}
                                    </div>
              
            
                                    <div class="name_val exp_name_val">
                                        <div>
                                            <span class="cp" (click)="item.is_as_to_approve = !item.is_as_to_approve">{{"To Approve"|translate}} {{item.is_as_to_approve ? "—" : "+"}}</span>
                                            <span>#</span>
                                        </div>
                                        
                                        <div class="sub_name_val" *ngIf="item?.is_as_to_approve">
                                            <div>
                                                <span>{{"Chats"|translate}}</span>
                                                <span>#</span>
                                            </div>
                                            <div>
                                                <span>{{"Jobs"|translate}}</span>
                                                <span>#</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="name_val exp_name_val">
                                        <div>
                                            <span class="cp" (click)="item.is_as_s_needed = !item.is_as_s_needed">{{"Sources needed"|translate}} {{item.is_as_s_needed ? "—" : "+"}}</span>
                                            <span>#</span>
                                        </div>
                                        
                                        <div class="sub_name_val" *ngIf="item?.is_as_s_needed">
                                            <div>
                                                <span>{{"Chats"|translate}}</span>
                                                <span>#</span>
                                            </div>
                                            <div>
                                                <span>{{"Jobs"|translate}}</span>
                                                <span>#</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="name_val exp_name_val">
                                        <div>
                                            <span class="cp" (click)="item.is_as_in_proc = !item.is_as_in_proc">{{"In Process"|translate}} {{item.is_as_in_proc ? "—" : "+"}}</span>
                                            <span>#</span>
                                        </div>
                                        
                                        <div class="sub_name_val" *ngIf="item?.is_as_in_proc">
                                            <div>
                                                <span>{{"Chats"|translate}}</span>
                                                <span>#</span>
                                            </div>
                                            <div>
                                                <span>{{"Jobs"|translate}}</span>
                                                <span>#</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="name_val exp_name_val">
                                        <div>
                                            <span class="cp" (click)="item.is_as_wait = !item.is_as_wait">{{"Waiting"|translate}} {{item.is_as_wait ? "—" : "+"}}</span>
                                            <span>#</span>
                                        </div>
                                        
                                        <div class="sub_name_val" *ngIf="item?.is_as_wait">
                                            <div>
                                                <span>{{"Chats"|translate}}</span>
                                                <span>#</span>
                                            </div>
                                            <div>
                                                <span>{{"Jobs"|translate}}</span>
                                                <span>#</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="divider_title">
                                        {{"Received" | translate}}
                                    </div>
                                    <div class="name_val exp_name_val">
                                        <div>
                                            <span class="cp" (click)="item.is_as_apr = !item.is_as_apr">{{"Approved"|translate}} {{item.is_as_apr ? "—" : "+"}}</span>
                                            <span>#</span>
                                        </div>
                                        
                                        <div class="sub_name_val" *ngIf="item?.is_as_apr">
                                            <div>
                                                <span>{{"Chats"|translate}}</span>
                                                <span>#</span>
                                            </div>
                                            <div>
                                                <span>{{"Jobs"|translate}}</span>
                                                <span>#</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="name_val exp_name_val">
                                        <div>
                                            <span class="cp" (click)="item.is_as_ready = !item.is_as_ready">{{"Ready"|translate}} {{item.is_as_ready ? "—" : "+"}}</span>
                                            <span>#</span>
                                        </div>
                                        
                                        <div class="sub_name_val" *ngIf="item?.is_as_ready">
                                            <div>
                                                <span>{{"Chats"|translate}}</span>
                                                <span>#</span>
                                            </div>
                                            <div>
                                                <span>{{"Jobs"|translate}}</span>
                                                <span>#</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="name_val exp_name_val">
                                        <div>
                                            <span class="cp" (click)="item.is_as_canc = !item.is_as_canc">{{"Canceled"|translate}} {{item.is_as_canc ? "—" : "+"}}</span>
                                            <span>#</span>
                                        </div>
                                        
                                        <div class="sub_name_val" *ngIf="item?.is_as_canc">
                                            <div>
                                                <span>{{"Chats"|translate}}</span>
                                                <span>#</span>
                                            </div>
                                            <div>
                                                <span>{{"Jobs"|translate}}</span>
                                                <span>#</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="name_val exp_name_val">
                                        <div>
                                            <span class="cp" (click)="item.is_as_hold = !item.is_as_hold">{{"On Hold"|translate}} {{item.is_as_hold ? "—" : "+"}}</span>
                                            <span>#</span>
                                        </div>
                                        
                                        <div class="sub_name_val" *ngIf="item?.is_as_hold">
                                            <div>
                                                <span>{{"Chats"|translate}}</span>
                                                <span>#</span>
                                            </div>
                                            <div>
                                                <span>{{"Jobs"|translate}}</span>
                                                <span>#</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="name_val exp_name_val">
                                        <div>
                                            <span class="cp" (click)="item.is_as_corr = !item.is_as_corr">{{"To correct"|translate}} {{item.is_as_corr ? "—" : "+"}}</span>
                                            <span>#</span>
                                        </div>
                                        
                                        <div class="sub_name_val" *ngIf="item?.is_as_corr">
                                            <div>
                                                <span>{{"Chats"|translate}}</span>
                                                <span>#</span>
                                            </div>
                                            <div>
                                                <span>{{"Jobs"|translate}}</span>
                                                <span>#</span>
                                            </div>
                                        </div>
                                    </div> -->
                                </div>
                                <div class="column file_col">
                                    <div class="name_val big_name_val" style="padding-top: 0;">
                                        <div>
                                            <span>{{"Created"|translate}}</span>
                                            <span>{{ +item?.files_created | number:'':'fr-FR'}}</span>
                                        </div>
                                    </div>
            
                                    <div class="name_val exp_name_val" *ngIf="item?.files_uploaded > 0">
                                        <div>
                                            <span class="cp" (click)="item.is_files_types = !item.is_files_types">{{"Files"|translate}} {{item.is_files_types ? "—" : "+"}}</span>
                                            <span>{{ +item?.files_uploaded | number:'':'fr-FR'}}</span>
                                        </div>
                                        <div class="sub_name_val" *ngIf="item?.is_files_types">
                                            <div *ngIf="item?.files_uploaded_image > 0">
                                                <span>{{"Images"|translate}}</span>
                                                <span>{{ +item?.files_uploaded_image | number:'':'fr-FR'}}</span>
                                            </div>
                                            <div *ngIf="item?.files_uploaded_video > 0">
                                                <span>{{"Videos"|translate}}</span>
                                                <span>{{ +item?.files_uploaded_video | number:'':'fr-FR'}}</span>
                                            </div>
                                            <div *ngIf="item?.files_uploaded_archive > 0">
                                                <span>{{"Archives"|translate}}</span>
                                                <span>{{ +item?.files_uploaded_archive | number:'':'fr-FR'}}</span>
                                            </div>
                                            <div *ngIf="item?.files_uploaded_other > 0">
                                                <span>{{"Other"|translate}}</span>
                                                <span>{{ +item?.files_uploaded_other | number:'':'fr-FR'}}</span>
                                            </div>
                                        </div>
                                    </div>
        
                                    <div class="name_val" *ngIf="item?.files_folders_created > 0">
                                        <div>
                                            <span>{{"Folders"|translate}}</span>
                                            <span>{{ +item?.files_folders_created | number:'':'fr-FR'}}</span>
                                        </div>
                                    </div>
            
                                    <div class="name_val big_name_val">
                                        <div>
                                            <span>{{"Edited"|translate}}</span>
                                            <span>{{ +item?.files_updated | number:'':'fr-FR'}}</span>
                                        </div>
                                    </div>
        
                                    <div class="name_val exp_name_val"  *ngIf="item?.files_files_updated > 0">
                                        <div>
                                            <span class="cp" (click)="item.is_files_types_edited = !item.is_files_types_edited">{{"Files"|translate}} {{item.is_files_types_edited ? "—" : "+"}}</span>
                                            <span>{{ +item?.files_files_updated | number:'':'fr-FR'}}</span>
                                        </div>
                                        <div class="sub_name_val" *ngIf="item?.is_files_types_edited">
                                            <div *ngIf="item?.files_renamed > 0">
                                                <span>{{"Name"|translate}}</span>
                                                <span>{{ +item?.files_renamed | number:'':'fr-FR'}}</span>
                                            </div>
                                            <div *ngIf="item?.files_deleted > 0">
                                                <span>{{"Deleted"|translate}}</span>
                                                <span>{{ +item?.files_deleted | number:'':'fr-FR'}}</span>
                                            </div>
                                            <div *ngIf="item?.files_moved > 0">
                                                <span>{{"Moved"|translate}}</span>
                                                <span>{{ +item?.files_moved | number:'':'fr-FR'}}</span>
                                            </div>
                                            <div *ngIf="item?.files_copied > 0">
                                                <span>{{"Copied"|translate}}</span>
                                                <span>{{ +item?.files_copied | number:'':'fr-FR'}}</span>
                                            </div>
                                            <div *ngIf="item?.files_shared > 0">
                                                <span>{{"Shared"|translate}}</span>
                                                <span>{{ +item?.files_shared | number:'':'fr-FR'}}</span>
                                            </div>
                                            <div *ngIf="item?.files_unshared > 0">
                                                <span>{{"Unshared"|translate}}</span>
                                                <span>{{ +item?.files_unshared | number:'':'fr-FR'}}</span>
                                            </div>
                                            <div *ngIf="item?.files_tagged > 0">
                                                <span>{{"Tagged"|translate}}</span>
                                                <span>{{ +item?.files_tagged | number:'':'fr-FR'}}</span>
                                            </div>
                                        </div>
                                    </div>
        
                                    <div class="name_val exp_name_val" *ngIf="item?.files_folders_updated > 0">
                                        <div>
                                            <span class="cp" (click)="item.is_folders_edited = !item.is_folders_edited">{{"Folders"|translate}} {{item.is_folders_edited ? "—" : "+"}}</span>
                                            <span>{{ +item?.files_folders_updated | number:'':'fr-FR'}}</span>
                                        </div>
                                        <div class="sub_name_val" *ngIf="item?.is_folders_edited">
                                            <div *ngIf="item?.files_folders_renamed > 0">
                                                <span>{{"Name"|translate}}</span>
                                                <span>{{ +item?.files_folders_renamed | number:'':'fr-FR'}}</span>
                                            </div>
                                            <div *ngIf="item?.files_folders_deleted > 0">
                                                <span>{{"Deleted"|translate}}</span>
                                                <span>{{ +item?.files_folders_deleted | number:'':'fr-FR'}}</span>
                                            </div>
                                            <div *ngIf="item?.files_folders_moved > 0">
                                                <span>{{"Moved"|translate}}</span>
                                                <span>{{ +item?.files_folders_moved | number:'':'fr-FR'}}</span>
                                            </div>
                                            <div *ngIf="item?.files_folders_copied > 0">
                                                <span>{{"Copied"|translate}}</span>
                                                <span>{{ +item?.files_folders_copied | number:'':'fr-FR'}}</span>
                                            </div>
                                            <div *ngIf="item?.files_folders_shared > 0">
                                                <span>{{"Shared"|translate}}</span>
                                                <span>{{ +item?.files_folders_shared | number:'':'fr-FR'}}</span>
                                            </div>
                                            <div *ngIf="item?.files_folders_unshared > 0">
                                                <span>{{"Unshared"|translate}}</span>
                                                <span>{{ +item?.files_folders_unshared | number:'':'fr-FR'}}</span>
                                            </div>
                                            <div *ngIf="item?.files_folders_tagged > 0">
                                                <span>{{"Tagged"|translate}}</span>
                                                <span>{{ +item?.files_folders_tagged | number:'':'fr-FR'}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <!--
                                    <div class="name_val">
                                        <div>
                                            <span>{{"Name"|translate}}</span>
                                            <span>#</span>
                                        </div>
                                    </div>
                                    <div class="name_val">
                                        <div>
                                            <span>{{"Deleted"|translate}}</span>
                                            <span>#</span>
                                        </div>
                                    </div>
                                    <div class="name_val">
                                        <div>
                                            <span>{{"Moved"|translate}}</span>
                                            <span>#</span>
                                        </div>
                                    </div>
                                    <div class="name_val">
                                        <div>
                                            <span>{{"Copied"|translate}}</span>
                                            <span>#</span>
                                        </div>
                                    </div>
                                    <div class="name_val">
                                        <div>
                                            <span>{{"Shared"|translate}}</span>
                                            <span>#</span>
                                        </div>
                                    </div>
                                    <div class="name_val">
                                        <div>
                                            <span>{{"Unshared"|translate}}</span>
                                            <span>#</span>
                                        </div>
                                    </div>
                                    <div class="name_val">
                                        <div>
                                            <span>{{"Tagged"|translate}}</span>
                                            <span>#</span>
                                        </div>
                                    </div> -->
                                </div>
                                <div class="column post_col">
                                    <div class="name_val big_name_val" style="padding-top: 0;">
                                        <div>
                                            <span>{{"Added"|translate}}</span>
                                            <span>{{ +item?.task_channels_combined | number:'':'fr-FR'}}</span>
                                        </div>
                                    </div>
               
                                    <!-- Outlets added to tasks -->
                                    <div class="name_val exp_name_val" style="padding-top: 12px; padding-bottom: 8px;" *ngIf="item?.task_channels_created > 0">
                                        <div>
                                            <span class="cp cp_bold" (click)="openOutletsAdded(item);">{{"Outlets added to tasks"|translate}} {{item.is_post_outlets ? "—" : "+"}}</span>
                                            <span>{{ +item?.task_channels_created | number:'':'fr-FR'}}</span>
                                        </div>
                                        
                                        <div class="sub_name_val sub_vertical" *ngIf="item?.is_post_outlets">
                                            <ng-container *ngFor="let platform of item?.groupedByPlatform">
                                                <div class="name_val exp_name_val" *ngIf="platform.channels && platform.channels.length && platform?.openOutletsAddedData?.task_channels_created > 0">
                                                    <div>
                                                        <span class="cp" (click)="openPlatformAdded(item, platform);">{{platform.name}} {{platform.is_added_open ? "—" : "+"}}</span>
                                                        <span>{{ +platform?.openOutletsAddedData?.task_channels_created | number:'':'fr-FR'}}</span>
                                                    </div>
                                                    
                                                    <div class="sub_name_val" *ngIf="platform.is_added_open">
                                                        <ng-container *ngFor="let channel of platform.channels">
                                                            <div *ngIf="channel?.openPlatformAddedData?.task_channels_created > 0">
                                                                <span>{{channel.name}}</span>
                                                                <span>{{ +channel?.openPlatformAddedData?.task_channels_created | number:'':'fr-FR'}}</span>
                                                            </div>
                                                        </ng-container>
                                                    </div>
                                                </div>
                                            </ng-container>
                                            
                                            <div class="name_val exp_name_val" *ngIf="item?.task_channels_created_direct > 0 || item?.task_channels_created_url > 0">
                                                <div>
                                                    <span class="cp_bold" >{{"By actions"|translate}}</span>
                                                </div>
                                                
                                                <div class="sub_name_val">
                                                    <div *ngIf="item?.task_channels_created_direct > 0">
                                                        <span>{{"Directly"|translate}}</span>
                                                        <span>{{ +item?.task_channels_created_direct | number:'':'fr-FR'}}</span>
                                                    </div>
                                                    <div *ngIf="item?.task_channels_created_url > 0">
                                                        <span>{{"By adding Post URL"|translate}}</span>
                                                        <span>{{ +item?.task_channels_created_url | number:'':'fr-FR'}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
            
                                    <!-- Posts added outlet(s) -->
                                    <div class="name_val exp_name_val" style="padding-top: 12px; padding-bottom: 8px;" *ngIf="item?.task_channels_url_created > 0">
                                        <div>
                                            <span class="cp cp_bold" (click)="openPostsAdded(item);">{{"Posts added outlet(s)"|translate}} {{item.is_post_added ? "—" : "+"}}</span>
                                            <span>{{ +item?.task_channels_url_created | number:'':'fr-FR'}}</span>
                                        </div>
                                        
                                        <div class="sub_name_val sub_vertical" *ngIf="item?.is_post_added">
                                            <ng-container *ngFor="let stat of item?.groupedByStatus">
                                                <ng-container *ngIf="stat?.openPostsAddedData?.task_channels_url_created > 0">
                                                    <div style="display: flex; align-items: center; justify-content: space-between;">
                                                        <span class="cp" style="color: #000;" (click)="openStatPostsAdded(item, stat);">{{stat.name}} {{stat.is_open ? "—" : "+"}}</span>
                                                        <span style="color: #000;">{{ +stat?.openPostsAddedData?.task_channels_url_created | number:'':'fr-FR'}}</span>
                                                    </div>
                                                    
                                                    <div class="sub_name_val sub_vertical" style="width: calc(100% - 8px);" *ngIf="stat?.is_open">
                                                        <ng-container *ngFor="let platform of stat?.groupedByPlatform">
                                                            <div class="name_val exp_name_val" *ngIf="platform.channels && platform.channels.length && platform?.openStatPostsAddedData?.task_channels_url_created > 0">
                                                                <div>
                                                                    <span class="cp" (click)="openPlatformPostsAdded(item, stat, platform);">{{platform.name}} {{platform.is_open ? "—" : "+"}}</span>
                                                                    <span>{{ +platform?.openStatPostsAddedData?.task_channels_url_created | number:'':'fr-FR'}}</span>
                                                                </div>
                                                                
                                                                <div class="sub_name_val" style="width: calc(100% - 8px);" *ngIf="platform.is_open">
                                                                    <ng-container *ngFor="let channel of platform.channels">
                                                                        <div *ngIf="channel?.openPlatformPostsAddedData?.task_channels_url_created > 0">
                                                                            <span>{{channel.name}}</span>
                                                                            <span>{{ +channel?.openPlatformPostsAddedData?.task_channels_url_created | number:'':'fr-FR'}}</span>
                                                                        </div>
                                                                    </ng-container>
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                    </div>
                                                </ng-container>
                                            </ng-container>
                                        </div>
                                    </div>
            
                                    <div class="name_val big_name_val">
                                        <div>
                                            <span>{{"Edited"|translate}}</span>
                                            <span>{{ +item?.task_channels_updated | number:'':'fr-FR'}}</span>
                                        </div>
                                    </div>
        
                                    <ng-container *ngIf="item?.task_channels_updated > 0">
                                        <div class="name_val exp_name_val" style="padding-top: 12px; padding-bottom: 8px;" *ngIf="item?.task_channels_status_updated > 0">
                                            <div>
                                                <span class="cp cp_bold" (click)="openPostsEdited(item);">{{"Statuses changed"|translate}} {{item.is_post_edited ? "—" : "+"}}</span>
                                                <span>{{ +item?.task_channels_status_updated | number:'':'fr-FR'}}</span>
                                            </div>
        
                                            <div class="sub_name_val sub_vertical" *ngIf="item?.is_post_edited">
                                                <ng-container *ngFor="let stat of item?.editGroupedByStatus">
                                                    <ng-container *ngIf="stat?.openPostsEditedData?.task_channels_status_updated > 0">
                                                        <div style="display: flex; align-items: center; justify-content: space-between;">
                                                            <span class="cp" style="color: #000;" (click)="openStatPostsEdited(item, stat);">{{stat.name}} {{stat.is_open ? "—" : "+"}}</span>
                                                            <span style="color: #000;">{{ +stat?.openPostsEditedData?.task_channels_status_updated | number:'':'fr-FR'}}</span>
                                                        </div>
                                                        
                                                        <div class="sub_name_val sub_vertical" style="width: calc(100% - 8px);" *ngIf="stat?.is_open">
                                                            <ng-container *ngFor="let platform of stat?.groupedByPlatform">
                                                                <div class="name_val exp_name_val" *ngIf="platform.channels && platform.channels.length && platform?.openStatPostsEditedData?.task_channels_status_updated > 0">
                                                                    <div>
                                                                        <span class="cp" (click)="openPlatformPostsEdited(item, stat, platform);">{{platform.name}} {{platform.is_open ? "—" : "+"}}</span>
                                                                        <span>{{ +platform?.openStatPostsEditedData?.task_channels_status_updated | number:'':'fr-FR'}}</span>
                                                                    </div>
                                                                    
                                                                    <div class="sub_name_val" style="width: calc(100% - 8px);" *ngIf="platform.is_open">
                                                                        <ng-container *ngFor="let channel of platform.channels">
                                                                            <div *ngIf="channel?.openPlatformPostsEditedData?.task_channels_status_updated > 0">
                                                                                <span>{{channel.name}}</span>
                                                                                <span>{{ +channel?.openPlatformPostsEditedData?.task_channels_status_updated | number:'':'fr-FR'}}</span>
                                                                            </div>
                                                                        </ng-container>
                                                                    </div>
                                                                </div>
                                                            </ng-container>
                                                        </div>
                                                    </ng-container>
                                                </ng-container>
                                            </div>
                                        </div>
        
                                        <div class="name_val exp_name_val" style="padding-top: 12px; padding-bottom: 8px;" *ngIf="item?.task_channels_url_updated > 0">
                                            <div>
                                                <span class="cp cp_bold" (click)="getItemPlatforms(item, 'url_edited_open', 'urlsEditedPlatform');">{{"URLs edited"|translate}} {{item.url_edited_open ? "—" : "+"}}</span>
                                                <span>{{ +item?.task_channels_url_updated | number:'':'fr-FR'}}</span>
                                            </div>
                                            <div class="sub_name_val sub_vertical" style="width: calc(100% - 8px);" *ngIf="item.url_edited_open">
                                                <ng-container *ngFor="let platform of item?.groupedByPlatform">
                                                    <div class="name_val exp_name_val" *ngIf="platform.channels && platform.channels.length && platform?.urlsEditedPlatform?.task_channels_url_updated > 0">
                                                        <div>
                                                            <span class="cp" (click)="getItemPlatformChannels(item, platform, 'url_edited_open', 'urlsEditedPlatform');">{{platform.name}} {{platform?.url_edited_open ? "—" : "+"}}</span>
                                                            <span>{{ +platform?.urlsEditedPlatform?.task_channels_url_updated | number:'':'fr-FR'}}</span>
                                                        </div>
                                                        
                                                        <div class="sub_name_val" style="width: calc(100% - 8px);" *ngIf="platform?.url_edited_open">
                                                            <ng-container *ngFor="let channel of platform.channels">
                                                                <div *ngIf="channel?.urlsEditedPlatform?.task_channels_url_updated > 0">
                                                                    <span>{{channel.name}}</span>
                                                                    <span>{{ +channel?.urlsEditedPlatform?.task_channels_url_updated | number:'':'fr-FR'}}</span>
                                                                </div>
                                                            </ng-container>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </div>
            
                                        <div class="name_val exp_name_val" style="padding-top: 12px; padding-bottom: 8px;" *ngIf="item?.task_channels_published_at_updated > 0">
                                            <div>
                                                <span class="cp cp_bold" (click)="getItemPlatforms(item, 'publication_times_edited', 'publicationTimesEdited');">{{"Time edited"|translate}} {{item.publication_times_edited ? "—" : "+"}}</span>
                                                <span>{{ +item?.task_channels_published_at_updated | number:'':'fr-FR'}}</span>
                                            </div>
                                            <div class="sub_name_val sub_vertical" style="width: calc(100% - 8px);" *ngIf="item.publication_times_edited">
                                                <ng-container *ngFor="let platform of item?.groupedByPlatform">
                                                    <div class="name_val exp_name_val" *ngIf="platform.channels && platform.channels.length && platform?.publicationTimesEdited?.task_channels_published_at_updated > 0">
                                                        <div>
                                                            <span class="cp" (click)="getItemPlatformChannels(item, platform, 'publication_times_edited', 'publicationTimesEdited');">{{platform.name}} {{platform?.publication_times_edited ? "—" : "+"}}</span>
                                                            <span>{{ +platform?.publicationTimesEdited?.task_channels_published_at_updated | number:'':'fr-FR'}}</span>
                                                        </div>
                                                        
                                                        <div class="sub_name_val" style="width: calc(100% - 8px);" *ngIf="platform?.publication_times_edited">
                                                            <ng-container *ngFor="let channel of platform.channels">
                                                                <div *ngIf="channel?.publicationTimesEdited?.task_channels_published_at_updated > 0">
                                                                    <span>{{channel.name}}</span>
                                                                    <span>{{ +channel?.publicationTimesEdited?.task_channels_published_at_updated | number:'':'fr-FR'}}</span>
                                                                </div>
                                                            </ng-container>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </div>
        
                                        <div class="name_val exp_name_val" style="padding-top: 12px; padding-bottom: 8px;" *ngIf="item?.task_channels_image_updated > 0">
                                            <div>
                                                <span class="cp cp_bold" (click)="getItemPlatforms(item, 'thumbnails_Edited_open', 'thumbnailsEdited');">{{"Thumbnails edited"|translate}} {{item.thumbnails_Edited_open ? "—" : "+"}}</span>
                                                <span>{{ +item?.task_channels_image_updated | number:'':'fr-FR'}}</span>
                                            </div>
                                            <div class="sub_name_val sub_vertical" style="width: calc(100% - 8px);" *ngIf="item.thumbnails_Edited_open">
                                                <ng-container *ngFor="let platform of item?.groupedByPlatform">
                                                    <div class="name_val exp_name_val" *ngIf="platform.channels && platform.channels.length && platform?.thumbnailsEdited?.task_channels_image_updated > 0">
                                                        <div>
                                                            <span class="cp" (click)="getItemPlatformChannels(item, platform, 'thumbnails_Edited_open', 'thumbnailsEdited');">{{platform.name}} {{platform?.thumbnails_Edited_open ? "—" : "+"}}</span>
                                                            <span>{{ +platform?.thumbnailsEdited?.task_channels_image_updated | number:'':'fr-FR'}}</span>
                                                        </div>
                                                        
                                                        <div class="sub_name_val" style="width: calc(100% - 8px);" *ngIf="platform?.thumbnails_Edited_open">
                                                            <ng-container *ngFor="let channel of platform.channels">
                                                                <div *ngIf="channel?.thumbnailsEdited?.task_channels_image_updated > 0">
                                                                    <span>{{channel.name}}</span>
                                                                    <span>{{ +channel?.thumbnailsEdited?.task_channels_image_updated | number:'':'fr-FR'}}</span>
                                                                </div>
                                                            </ng-container>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </div>
            
                                        <div class="name_val exp_name_val" style="padding-top: 12px; padding-bottom: 8px;" *ngIf="item?.task_channels_url_deleted > 0">
                                            <div>
                                                <span class="cp_bold">{{"URLs deleted"|translate}}</span>
                                                <span>{{ +item?.task_channels_url_deleted | number:'':'fr-FR'}}</span>
                                            </div>
                                        </div>
                                    </ng-container>
                                    
                                </div>
                                <div class="exp_col">
                                    <div class="toggler" (click)="item.is_expand = !item.is_expand">
                                        <svg style="width: 19px; height: 10px;" width="19" height="10" viewBox="0 0 19 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M18.5 9.5L10 1L1 9.5" stroke="black"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="outlets_dash_footer">
                            <div class="column empl_col">
                                <span class="big">
                                    {{ "Total" | translate }} <ng-container *ngIf="!!activity.length">{{activity.length}}</ng-container>
                                </span>
                            </div>
                            <div class="column task_col">
                                <span class="normal">
                                    {{ +totals?.tasks_created | number:'':'fr-FR'}}
                                </span>
                            </div>
                            <div class="column job_col">
                                <span class="normal">
                                    {{ +totals?.task_operations_created | number:'':'fr-FR'}}
                                </span>
                            </div>
                            <div class="column chat_col">
                                <span class="normal">
                                    {{ +totals?.discussions_created | number:'':'fr-FR'}}
                                </span>
                            </div>
                            <div class="column ass_col">
                                <span class="normal">
                                    {{ (+totals?.task_operations_assigned + +totals?.discussions_assigned) | number:'':'fr-FR'}}
                                </span>
                            </div>
                            <div class="column file_col">
                                <span class="normal">
                                    {{ +totals?.files_uploaded | number:'':'fr-FR'}}
                                </span>
                            </div>
                            <div class="column post_col">
                                <span class="normal">
                                    {{ +totals?.task_channels_combined | number:'':'fr-FR'}}
                                </span>
                            </div>
                            <div class="exp_col"></div>
                        </div>
                    </div>
                </ng-template>
            </ng-container>
            <ng-template #elseTemplateFilterMob>
                <ng-container *ngIf="is_filter_active; else elseTemplateMobFil">       
                    <form class="mob_fil_act" [formGroup]="notFilter" (ngSubmit)="filterNots()">
                        <mat-form-field appearance="legacy" class="full_width_form">
                            <mat-label>{{ "Group" | translate }}</mat-label>
                            <mat-select [disableOptionCentering]="true" selectDir formControlName="group_id">
                                <mat-option>
                                    <ngx-mat-select-search 
                                        placeholderLabel="{{ 'Search' | translate }}" 
                                        noEntriesFoundLabel="{{ 'no matching group found' | translate }}"
                                        [formControl]="groupsControl"></ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let group of groups$ | async" [value]="group.id">
                                    {{ group.name | translate }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="standard" class="full_width_form">
                            <mat-label>{{ "By actions" | translate }}</mat-label>
                            <mat-select formControlName="action">
                                <mat-option *ngFor="let action of actions" [value]="action">
                                    {{ action | translate }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
        
                        <mat-form-field *ngIf="data?.company?.permissions.includes('owner') || data?.company?.permissions.includes('admin') || data?.company?.permissions.includes('manager')" appearance="standard" class="full_width_form">
                            <mat-label>{{ "By employee" | translate }}</mat-label>
                            <mat-select formControlName="created_employee_id">
                                <mat-option>
                                    <ngx-mat-select-search 
                                        placeholderLabel="{{ 'Search' | translate }}" 
                                        noEntriesFoundLabel="{{ 'no matching member found' | translate }}"
                                        [formControl]="execControl"></ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let employee of notEmployees$ | async" [value]="employee.id">
                                    <app-user [class]="'user_div'" [employee]="employee"></app-user>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
        
                        <ng-container *ngIf="data.header">
                            <mat-form-field appearance="standard" class="full_width_form">
                                <mat-label>{{ "By work type" | translate }}</mat-label>
                                <mat-select formControlName="task_operation_operation_id" multiple>
                                    <mat-option *ngFor="let op of operations" [value]="op.id">
                                        {{ op.name | translate }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field appearance="standard" class="full_width_form">
                                <mat-label>{{ "By work status" | translate }}</mat-label>
                                <mat-select formControlName="task_operation_status_id" multiple>
                                    <mat-option *ngFor="let opS of operationStatuses" [value]="opS.id">
                                        {{ opS.name | translate }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </ng-container>
    
                        <mat-form-field appearance="legacy" class="example-form-field full_width_form">
                            <mat-label>{{ 'Date' | translate }}</mat-label>
                            <mat-date-range-input  [rangePicker]="rangePicker">
                                <input formControlName="created_at_from" matStartDate placeholder="{{ 'Date from' | translate }}">
                                <input formControlName="created_at_to" matEndDate placeholder="{{ 'Date to' | translate }}">
                            </mat-date-range-input>
                            <mat-datepicker-toggle matPrefix [for]="rangePicker"></mat-datepicker-toggle>
                            <mat-date-range-picker #rangePicker>
                                <mat-date-range-picker-actions>
                                <button mat-button matDateRangePickerCancel>Cancel</button>
                                <button mat-raised-button color="primary" matDateRangePickerApply>Apply</button>
                                </mat-date-range-picker-actions>
                            </mat-date-range-picker>
                            </mat-form-field>
        
                        <mat-button-toggle-group class="view_group" (change)="changeView($event)" [value]="notFilter.get('original_action_id').value == '0' ? 'member' : 'all'">
                            <mat-button-toggle value="all">{{ "All" | translate }}</mat-button-toggle>
                            <mat-button-toggle value="member">{{ "By members" | translate }}</mat-button-toggle>
                        </mat-button-toggle-group>

                        <div class="d-f-c" style="margin: 15px 0;">
                            <button mat-button type="button" (click)="filterReset()">{{ "Reset" | translate }}</button>
                            <button mat-raised-button style="padding: 0 5px !important;" type="submit" color="primary">{{ "Filter" | translate }}</button>
                        </div>
                    </form>
                </ng-container>
                <ng-template #elseTemplateMobFil>
                    <ng-container *ngFor="let element of notifications">
                        <div class="activity_wrp" [ngClass]="{'exp': !!element?.isExpand}">
                            <div class="activity">
                                <div class="activity_left">
                                    <ng-container *ngIf="element.created_at != '0'">                          
                                        <div class="activity_date" *ngIf="todaysDate == getDay(element.created_at*1000); else notToday;">
                                            {{ element.created_at*1000 | date:'shortTime':'':activeLang }}
                                        </div>
                                        <ng-template #notToday>
                                            <div class="activity_date" matTooltip="{{ element.created_at*1000 | date:'shortTime':'':activeLang }}" matTooltipClass="dateTooltip" [matTooltipPosition]="'above'">
                                                {{ element.created_at*1000 | date:'mediumDate':'timeZone':activeLang | yearPipe }} 
                                            </div>
                                        </ng-template>
                                    </ng-container>
                                    <app-user *ngIf="element?.createdEmployee" [class]="'activity_user'" [lines]="2" [employee]="element?.createdEmployee"></app-user>
                                    <div *ngIf="element?.taskOperation" class="job_not not_link" (click)="openJob(element?.task_id, element?.task_operation_id)">
                                        <div class="type">{{ getColumnName(element?.taskOperation?.operation_id) }}</div>
                                    </div>
                                    <button *ngIf="notFilter.get('original_action_id').value == '0' && element.original_action_id == 0" mat-icon-button aria-label="expand row" (click)="expandAction($event,element)">
                                        <mat-icon *ngIf="!element.isExpand">keyboard_arrow_down</mat-icon>
                                        <mat-icon *ngIf="element.isExpand">keyboard_arrow_up</mat-icon>
                                    </button>
                                </div>
                                <div class="activity_right">
                                    <ng-template #relationTaskTemplate>
                                        <div class="task_ids_wrp not_link" *ngIf="element?.relationTask" (click)="openTask(element.relationTask)">
                                            {{ element.relationTask.name.length > 60 ? (element.relationTask.name | slice:0:60) + " ..." : element.relationTask.name }}
                                            <div class="task_ids">
                                                {{element.relationTask.custom_id}} 
                                                <span>
                                                    <div>{{element.relationTask.internal_id}}</div>
                                                    <div>{{element.relationTask.id}}</div>
                                                </span>
                                            </div>
                                        </div>
                                    </ng-template>
                                    <div class="not_info">
                                        <div class="task_ids_wrp not_link" *ngIf="!!data?.header && element?.task" (click)="openTask(element.task)">
                                            {{ element.task.name.length > 60 ? (element.task.name | slice:0:60) + " ..." : element.task.name }}
                                            <div class="task_ids">
                                                {{element.task.custom_id}} 
                                                <span>
                                                    <div>{{element.task.internal_id}}</div>
                                                    <div>{{element.task.id}}</div>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="value" [ngClass]="{'d-f-c': element.action == 'task_channel_channel'}">
                                            <b class="action">{{element.action | translate }}: </b>
                                
                                            <ng-container [ngSwitch]="element.action">
                                                <ng-container *ngSwitchCase="'content_plan_deleted'" class="alert_value">
                                                    <ng-container *ngIf="element.parsedData && element.parsedData.attributes">
                                                        <b class="ml-1">{{element.parsedData.attributes.name}}</b>
                                                        <span class="ml-1">
                                                        </span>
                                                        <app-outlet-line class="ml-1" [channel]="getProfile(element.parsedData.attributes.channel_id)" [company_id]="data.company.id"></app-outlet-line>
                                                        <span class="ml-1">{{getContentById(element.parsedData.attributes.content_type_id)?.name}}</span>
                                                        <span class="ml-1">{{element.parsedData.attributes.publish_at*1000 | date:'MMM d, y, hh:mm a':'': activeLang}}</span>
                                                    </ng-container>
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'content_schedule_deleted'" class="alert_value">
                                                    <ng-container *ngIf="element.parsedData && element.parsedData.attributes">
                                                        <b class="ml-1">{{element.parsedData.attributes.name}}</b>
                                                        <span class="ml-1">
                                                        </span>
                                                        <app-outlet-line class="ml-1" [channel]="getProfile(element.parsedData.attributes.channel_id)" [company_id]="data.company.id"></app-outlet-line>
                                                        <span class="ml-1">{{getContentById(element.parsedData.attributes.content_type_id)?.name}}</span>
                                                        <span *ngIf="element.parsedData.attributes.is_daily" class="ml-1">
                                                            {{ "Every day" | translate }}
                                                        </span>
                                                        <span *ngIf="element.parsedData.attributes.is_weekly" class="ml-1">
                                                            {{ "Every week" | translate }}
                                                        </span>
                                                        <span *ngIf="element.parsedData.attributes.is_monthly" class="ml-1">
                                                            {{ "Every month" | translate }}
                                                        </span>
                                                    </ng-container>
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'content_schedule_item_deleted'" class="alert_value">
                                                    <ng-container *ngIf="element.parsedData && element.parsedData.attributes">
                                                        <b class="ml-1">{{element.parsedData.attributes.name}}</b>
                                                        <span class="ml-1">
                                                        </span>
                                                        <app-outlet-line [channel]="getProfile(element.parsedData.attributes.channel_id)" [company_id]="data.company.id"></app-outlet-line>
                                                        <span class="ml-1">{{getContentById(element.parsedData.attributes.content_type_id)?.name}}</span>
                                                        <div class="chat_timestamp" class="ml-1">
                                                            <ng-container>
                                                                <span class="silver">{{(element.parsedData.attributes.time | fullTime:false:true:true).silverTime}}</span>
                                                                <span class="black">{{(element.parsedData.attributes.time | fullTime:false:true:true).blackTime}}</span>
                                                            </ng-container>
                                                        </div>
                                                    </ng-container>
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'task_status'" class="alert_value">
                                                    {{ getTaskStatusById(element.task_status_id)?.name }}
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'task_channel_live_stream_pause'" class="alert_value">
                                                    <div class="ml-1 snippet">
                                                        Stream ID: {{element.task_channel_id}}
                                                    </div>
                                                    <div class="ml-1 snippet">
                                                        Broadcast ID: {{element.task_channel_live_stream_id }}
                                                    </div>
                                                    <div class="ml-1 d-f-c chat_timestamp" *ngIf="!!element.to_integer" #tooltip="matTooltip"
                                                    matTooltip="{{ 'Stream paused duration' | translate }}"
                                                    [matTooltipPosition]="'above'">
                                                        <mat-icon style="width: 10px; height: 10px; font-size: 10px; line-height: 10px; color: #C9C9C9; margin-right: 1px;">motion_photos_pause</mat-icon>
                                                        <!-- <span class="silver">{{(element?.to_integer | fullTime:false:true:true).silverTime}}</span>
                                                        <span class="black">{{(element?.to_integer | fullTime:false:true:true).blackTime}}</span> -->
                                                        <span class="black">{{element?.to_integer | timeFormat}}</span>
                                                    </div>
                                                </ng-container>
                                                <!-- ['task_consist_of_created','task_consist_of_removed','task_part_of_created','task_part_of_removed','task_related_created','task_related_removed'].includes(element.action) -->
                                                <ng-container *ngSwitchCase="'task_consist_of_created'" class="alert_value">
                                                    <ng-container *ngTemplateOutlet="relationTaskTemplate"></ng-container>
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'task_consist_of_removed'" class="alert_value">
                                                    <ng-container *ngTemplateOutlet="relationTaskTemplate"></ng-container>
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'task_part_of_created'" class="alert_value">
                                                    <ng-container *ngTemplateOutlet="relationTaskTemplate"></ng-container>
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'task_part_of_removed'" class="alert_value">
                                                    <ng-container *ngTemplateOutlet="relationTaskTemplate"></ng-container>
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'task_related_created'" class="alert_value">
                                                    <ng-container *ngTemplateOutlet="relationTaskTemplate"></ng-container>
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'task_related_removed'" class="alert_value">
                                                    <ng-container *ngTemplateOutlet="relationTaskTemplate"></ng-container>
                                                </ng-container>
            
                                                
                                                <!-- <div *ngSwitchCase="'task_comment'" class="alert_value">
                                                    {{ element?.data?.text }}
                                                </div>
                                                <div *ngSwitchCase="'task_private_comment'" class="alert_value">
                                                    {{ element?.data?.text }}
                                                </div> -->
                                                <div *ngSwitchCase="'task_channel_channel'" class="alert_value profile_value">
                                                    <span>{{ "fromA" | translate }} </span>
                                                    <div class="outlet_block">
                                                        <ng-container [ngTemplateOutlet]="platformTemplate" [ngTemplateOutletContext]="{ platform_id: profileData(element.from_task_channel_channel_id)?.platform_id }"></ng-container>
                                                        {{ profileData(element.from_task_channel_channel_id).name }}
                                                    </div>
                                                    <span>{{ "toA" | translate }} </span>
                                                    <div class="outlet_block">
                                                        <ng-container [ngTemplateOutlet]="platformTemplate" [ngTemplateOutletContext]="{ platform_id: profileData(element.task_channel_channel_id)?.platform_id }"></ng-container>
                                                        {{ profileData(element.task_channel_channel_id)?.name }}
                                                    </div>
                                                </div>
                                                <div *ngSwitchCase="'task_channel_content_status'" class="alert_value">
                                                    <div *ngSwitchCase="'task_channel_content_status'" class="alert_value">
                                                        <span>{{ "fromA" | translate }} </span>
                                                        {{ profileStatusesData(element?.from_integer)?.name | translate }}
                                                        <span>{{ "toA" | translate }} </span>
                                                        {{ profileStatusesData(element?.to_integer)?.name | translate }}
                                                    </div>
                                                </div>
                                                <div *ngSwitchCase="'task_channel_content_published_at'" class="alert_value">
                                                    <span>{{ "fromA" | translate }} </span>
                                                    {{ element?.from_integer*1000 | date:'MMM dd, Y':'+0000': activeLang | yearPipe }}
                                                    <span>{{ "toA" | translate }} </span>
                                                    {{ element?.to_integer*1000 | date:'MMM dd, Y':'+0000': activeLang | yearPipe }}
                                                </div>
            
                                                <div *ngSwitchCase="'task_employee_assigned'" class="alert_value" style="display: inline-flex; align-items:center">
                                                    <ng-container *ngIf="!!employees?.length">
                                                        <app-user [class]="'user_div'" [employee]="getEmployee(element?.employee_id)"></app-user>
                                                    </ng-container>
                                                </div>
                                                <div *ngSwitchCase="'task_employee_is_manager'" class="alert_value">
                                                    {{ element?.task_employee_is_manager ? ( "manager" | translate ) : ( "not manager" | translate ) }}
                                                </div>
                                                <div *ngSwitchCase="'task_employee_rate'" class="alert_value">
                                                    {{ element?.task_employee_rate | reportsNumber }}
                                                </div>
                                                <div *ngSwitchCase="'task_employee_price'" class="alert_value">
                                                    {{ element?.task_employee_price | reportsNumber }}
                                                </div>
                                                <div *ngSwitchCase="'task_employee_status'" class="alert_value">
                                                    {{ getOperationStatusName(element?.task_employee_status) }}
                                                </div>
                                                <div *ngSwitchCase="'task_employee_completed'" class="alert_value">
                                                    {{ element?.task_employee_completed_at*1000 | date:'MMM dd, Y':'+0000': activeLang | yearPipe }}
                                                </div>
                                                <div *ngSwitchCase="'task_channel_created'" class="alert_value profile_value">
                                                    <ng-container [ngTemplateOutlet]="platformTemplate" [ngTemplateOutletContext]="{ platform_id: profileData(element.task_channel_channel_id)?.platform_id }"></ng-container>
                                                    {{ profileData(element.task_channel_channel_id)?.name }}
                                                </div>
                                                <div *ngSwitchCase="'task_channel_deleted'" class="alert_value profile_value">
                                                    <ng-container [ngTemplateOutlet]="platformTemplate" [ngTemplateOutletContext]="{ platform_id: profileData(element.task_channel_channel_id)?.platform_id }"></ng-container>
                                                    {{ profileData(element.task_channel_channel_id)?.name }}
                                                </div>
            
                                                <div *ngSwitchCase="'task_operation_status'" class="alert_value">
                                                    {{ getOperationStatusName(element?.task_operation_status_id) }}
                                                </div>
                                                <div *ngSwitchCase="'task_employee_status_start_at'" class="alert_value">
                                                    {{ element.task_employee_start_at*1000 | date:'MMM dd. Y, hh:mm a':'':activeLang | yearPipe }}
                                                </div>
                                                <div *ngSwitchCase="'task_employee_status_change'" class="alert_value" style="display: inline-flex; align-items:center">
                                                    {{ getOperationStatusName(element?.task_employee_status_id) }}
                                                    <span class="ml-1 mr-1">{{ "for" | translate }}</span>
                                                    <ng-container *ngIf="!!employees?.length">
                                                        <app-user [class]="'user_div'" [employee]="getEmployee(element?.employee_id)"></app-user>
                                                    </ng-container>
                                                </div>
                                                <div *ngSwitchCase="'task_custom_id'" class="alert_value">
                                                    <div class="d-f-c">
                                                        {{ element?.task_old_custom_id}}<mat-icon class="mr-1 ml-1">arrow_right_alt</mat-icon>{{ element?.task_custom_id }}
                                                    </div>
                                                </div>
                                                <div *ngSwitchCase="'discussion_post_added'" class="alert_value not_link"  (click)="openChat(element)">
                                                    {{ element?.discussion?.name }}
                                                </div>
                                                <div *ngSwitchCase="'file_created'" class="alert_value">
                                                    <span class="file_name">{{element?.file?.filename}}</span>
                                                </div>
                                                <div *ngSwitchCase="'file_location'" class="alert_value">
                                                    <span class="file_name">{{element?.file?.filename}}</span>
                                                    
                                                    <ng-container *ngIf="element?.data.length > 1; else elseTemplateLoc">
                                                        <span>{{ "fromA" | translate }} </span>
                                                        <span>{{ ( element?.data[0].text == '/' ? "Root" : element?.data[0].text.split("/")[element?.data[0].text.split("/").length - 1] ) | translate }} </span>
                                                        <span>{{ "toA" | translate }} </span>
                                                        <span>{{ ( element?.data[1].text == '/' ? "Root" : element?.data[1].text.split("/")[element?.data[1].text.split("/").length - 1] ) | translate }} </span>
                                                    </ng-container>
                                                    <ng-template #elseTemplateLoc>
                                                        <ng-container *ngIf="element?.data.length == 1; else elseTemplateOnlyOneLoc">
                                                            <span>{{ "toA" | translate }} </span>
                                                            <span>{{ ( element?.data[0].text == '/' ? "Root" : element?.data[0].text.split("/")[element?.data[0].text.split("/").length - 1] ) | translate }} </span>
                                                        </ng-container>
                                                        <ng-template #elseTemplateOnlyOneLoc>
                                                            {{ element?.data | json }}
                                                        </ng-template>
                                                        <!-- <span [innerHTML]="element?.data?.text | linky:{newWindow: true}"></span> -->
                                                    </ng-template>
                                                </div>
                                                <!-- <div *ngSwitchCase="'task_operation_comment'" class="alert_value">
                                                    {{ element?.data?.text }}
                                                </div>
                                                <div *ngSwitchCase="'task_operation_private_comment'" class="alert_value">
                                                    {{ element?.data?.text }}
                                                </div>
                                                <div *ngSwitchCase="'task_operation_employee_comment'" class="alert_value">
                                                    {{ element?.data?.text }}
                                                </div> -->
                                                <div *ngSwitchCase="'task_operation_completed'" class="alert_value">
                                                    <ng-container *ngIf="element.task_operation_completed_at != '0'; else elseComplTemplate">
                                                        <div matTooltip="{{ element.task_operation_completed_at*1000 | date:'shortTime':'':activeLang }}" matTooltipClass="dateTooltip" [matTooltipPosition]="'above'">
                                                            {{ element.task_operation_completed_at*1000 | date:'mediumDate':'':activeLang | yearPipe }} 
                                                        </div>
                                                    </ng-container>
                                                    <ng-template #elseComplTemplate>
                                                        {{ "Without date" | translate }}
                                                    </ng-template>
                                                </div>
                                                <div *ngSwitchCase="'task_operation_price'" class="alert_value">
                                                    <ng-container [ngSwitch]="element?.task_operation_price_currency_id">
                                                        <ng-container *ngSwitchCase="'1'">&#36;</ng-container>
                                                        <ng-container *ngSwitchCase="'2'">&#8381;</ng-container>
                                                        <ng-container *ngSwitchCase="'3'">&#8364;</ng-container>
                                                        <ng-container *ngSwitchCase="'4'">&#8372;</ng-container>
                                                    </ng-container>{{ element?.task_operation_price | reportsNumber }}
                                                </div>
                                                <div *ngSwitchCase="'discussion_status'" class="alert_value not_link" (click)="openChat(element)">
                                                    {{ getChatStatus(element?.discussion_status_id) | translate }}
                                                </div>
                                                <div *ngSwitchCase="'task_operation_rate'" class="alert_value">
                                                    {{ element?.task_operation_rate | reportsNumber }}
                                                </div>
                                                <div *ngSwitchCase="'task_operation_employee'" class="alert_value user_div">
                                                    <ng-container *ngIf="!!employees?.length">
                                                        <app-user [class]="'user_div'" [employee]="getEmployee(element?.task_operation_employee_id)"></app-user>
                                                    </ng-container>
                                                </div>
                                                <div *ngSwitchCase="'task_operation_operation'" class="alert_value">
                                                    {{ getColumnName(element?.task_operation_operation_id) }}
                                                </div>
                                                <div *ngSwitchCase="'task_parameter_value_created'" class="alert_value">
                                                    <b class="mr-1">{{ element.parameterValue.value }} ({{element.parameterValue.parameter.name}})</b>
                                                    <span *ngIf="element.file_id">{{ "for" | translate }} {{ "File" | translate }}: {{element?.file?.filename}}</span>
                                                    <span *ngIf="element.discussion_id" style="cursor: pointer;" (click)="openChat(element)">{{ "for" | translate }} {{ "Chat" | translate }}: {{element?.discussion?.name}}</span>
                                                </div>
                                                <div *ngSwitchCase="'task_parameter_value_deleted'" class="alert_value">
                                                    <b class="mr-1">{{ element.parameterValue.value }} ({{element.parameterValue.parameter.name}})</b>
                                                    <span *ngIf="element.file_id">{{ "fromA" | translate }} {{ "File" | translate }}: {{element?.file?.filename}}</span>
                                                    <span *ngIf="element.discussion_id" style="cursor: pointer;" (click)="openChat(element)">{{ "fromA" | translate }} {{ "Chat" | translate }}: {{element?.discussion?.name}}</span>
                                                </div>
                                                <div *ngSwitchCase="'task_channel_content_type'" class="alert_value">
                                                    <ng-container *ngIf="element?.from_integer">
                                                        <span>{{ "fromA" | translate }} </span>
                                                        <span>{{ getContentById(element?.from_integer)?.name }} </span>
                                                    </ng-container>
                                                    <span>{{ "toA" | translate }} </span>
                                                    <span>{{ getContentById(element?.to_integer)?.name }} </span>
                                                </div>
                                                <div *ngSwitchDefault class="alert_value">
                                                    <span class="file_name" *ngIf="element?.file_id">{{element?.file?.filename}}</span>
                                                    <ng-container *ngIf="element?.data.length > 1; else elseTemplateSwitch">
                                                        <span>{{ "fromA" | translate }} </span>
                                                        <span>{{ element?.data[0].text }} </span>
                                                        <span>{{ "toA" | translate }} </span>
                                                        <span>{{ element?.data[1].text }} </span>
                                                    </ng-container>
                                                    <ng-template #elseTemplateSwitch>
                                                        <ng-container *ngIf="element?.data.length == 1; else elseTemplateOnlyOne">
                                                            <span>{{ "toA" | translate }} </span>
                                                            <span>{{ element?.data[0].text }} </span>
                                                        </ng-container>
                                                        <ng-template #elseTemplateOnlyOne>
                                                            {{element?.data | json}}
                                                        </ng-template>
                                                        <!-- <span [innerHTML]="element?.data?.text | linky:{newWindow: true}"></span> -->
                                                    </ng-template>
                                                </div>
                                            </ng-container>
                                
                                        </div>
            
                                    </div>
                                </div>
                            </div>
                            <ng-container *ngIf="!!element?.isExpand">
                                <div class="activity sub" *ngFor="let item of element.subActions">
                                    <div class="activity_left">
                                        <ng-container *ngIf="item.created_at != '0'">                          
                                            <div class="activity_date" *ngIf="todaysDate == getDay(item.created_at*1000); else notToday;">
                                                {{ item.created_at*1000 | date:'shortTime':'':activeLang }}
                                            </div>
                                            <ng-template #notToday>
                                                <div class="activity_date" matTooltip="{{ item.created_at*1000 | date:'shortTime':'':activeLang }}" matTooltipClass="dateTooltip" [matTooltipPosition]="'above'">
                                                    {{ item.created_at*1000 | date:'mediumDate':'timeZone':activeLang | yearPipe }} 
                                                </div>
                                            </ng-template>
                                        </ng-container>
                                        <app-user *ngIf="item?.createdEmployee" [class]="'activity_user'" [lines]="2" [employee]="item?.createdEmployee"></app-user>
                                        <div *ngIf="item?.taskOperation" class="job_not not_link" (click)="openJob(item?.task_id, item?.task_operation_id)">
                                            <div class="type">{{ getColumnName(item?.taskOperation?.operation_id) }}</div>
                                        </div>
                                        <button *ngIf="notFilter.get('original_action_id').value == '0' && item.original_action_id == 0" mat-icon-button aria-label="expand row" (click)="expandAction($event,item)">
                                            <mat-icon *ngIf="!item.isExpand">keyboard_arrow_down</mat-icon>
                                            <mat-icon *ngIf="item.isExpand">keyboard_arrow_up</mat-icon>
                                        </button>
                                    </div>
                                    <div class="activity_right">
                                        <ng-template #relationTaskTemplate>
                                            <div class="task_ids_wrp not_link" *ngIf="item?.relationTask" (click)="openTask(item.relationTask)">
                                                {{ item.relationTask.name.length > 60 ? (item.relationTask.name | slice:0:60) + " ..." : item.relationTask.name }}
                                                <div class="task_ids">
                                                    {{item.relationTask.custom_id}} 
                                                    <span>
                                                        <div>{{item.relationTask.internal_id}}</div>
                                                        <div>{{item.relationTask.id}}</div>
                                                    </span>
                                                </div>
                                            </div>
                                        </ng-template>
                                        <div class="not_info">
                                            <div class="task_ids_wrp not_link" *ngIf="!!data?.header && item?.task" (click)="openTask(item.task)">
                                                {{ item.task.name.length > 60 ? (item.task.name | slice:0:60) + " ..." : item.task.name }}
                                                <div class="task_ids">
                                                    {{item.task.custom_id}} 
                                                    <span>
                                                        <div>{{item.task.internal_id}}</div>
                                                        <div>{{item.task.id}}</div>
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="value" [ngClass]="{'d-f-c': item.action == 'task_channel_channel'}">
                                                <b class="action">{{item.action | translate }}: </b>
                                    
                                                <ng-container [ngSwitch]="item.action">
                                                    <ng-container *ngSwitchCase="'content_plan_deleted'" class="alert_value">
                                                        <ng-container *ngIf="item.parsedData && item.parsedData.attributes">
                                                            <b class="ml-1">{{item.parsedData.attributes.name}}</b>
                                                            <span class="ml-1">
                                                            </span>
                                                            <app-outlet-line class="ml-1" [channel]="getProfile(item.parsedData.attributes.channel_id)" [company_id]="data.company.id"></app-outlet-line>
                                                            <span class="ml-1">{{getContentById(item.parsedData.attributes.content_type_id)?.name}}</span>
                                                            <span class="ml-1">{{item.parsedData.attributes.publish_at*1000 | date:'MMM d, y, hh:mm a':'': activeLang}}</span>
                                                        </ng-container>
                                                    </ng-container>
                                                    <ng-container *ngSwitchCase="'content_schedule_deleted'" class="alert_value">
                                                        <ng-container *ngIf="item.parsedData && item.parsedData.attributes">
                                                            <b class="ml-1">{{item.parsedData.attributes.name}}</b>
                                                            <span class="ml-1">
                                                            </span>
                                                            <app-outlet-line class="ml-1" [channel]="getProfile(item.parsedData.attributes.channel_id)" [company_id]="data.company.id"></app-outlet-line>
                                                            <span class="ml-1">{{getContentById(item.parsedData.attributes.content_type_id)?.name}}</span>
                                                            <span *ngIf="item.parsedData.attributes.is_daily" class="ml-1">
                                                                {{ "Every day" | translate }}
                                                            </span>
                                                            <span *ngIf="item.parsedData.attributes.is_weekly" class="ml-1">
                                                                {{ "Every week" | translate }}
                                                            </span>
                                                            <span *ngIf="item.parsedData.attributes.is_monthly" class="ml-1">
                                                                {{ "Every month" | translate }}
                                                            </span>
                                                        </ng-container>
                                                    </ng-container>
                                                    <ng-container *ngSwitchCase="'content_schedule_item_deleted'" class="alert_value">
                                                        <ng-container *ngIf="item.parsedData && item.parsedData.attributes">
                                                            <b class="ml-1">{{item.parsedData.attributes.name}}</b>
                                                            <span class="ml-1">
                                                            </span>
                                                            <app-outlet-line [channel]="getProfile(item.parsedData.attributes.channel_id)" [company_id]="data.company.id"></app-outlet-line>
                                                            <span class="ml-1">{{getContentById(item.parsedData.attributes.content_type_id)?.name}}</span>
                                                            <div class="chat_timestamp" class="ml-1">
                                                                <ng-container>
                                                                    <span class="silver">{{(item.parsedData.attributes.time | fullTime:false:true:true).silverTime}}</span>
                                                                    <span class="black">{{(item.parsedData.attributes.time | fullTime:false:true:true).blackTime}}</span>
                                                                </ng-container>
                                                            </div>
                                                        </ng-container>
                                                    </ng-container>
                                                    <ng-container *ngSwitchCase="'task_status'" class="alert_value">
                                                        {{ getTaskStatusById(item.task_status_id)?.name }}
                                                    </ng-container>
                                                    <ng-container *ngSwitchCase="'task_channel_live_stream_pause'" class="alert_value">
                                                        <div class="ml-1 snippet">
                                                            Stream ID: {{item.task_channel_id}}
                                                        </div>
                                                        <div class="ml-1 snippet">
                                                            Broadcast ID: {{item.task_channel_live_stream_id }}
                                                        </div>
                                                        <div class="ml-1 d-f-c chat_timestamp" *ngIf="!!item.to_integer" #tooltip="matTooltip"
                                                        matTooltip="{{ 'Stream paused duration' | translate }}"
                                                        [matTooltipPosition]="'above'">
                                                            <mat-icon style="width: 10px; height: 10px; font-size: 10px; line-height: 10px; color: #C9C9C9; margin-right: 1px;">motion_photos_pause</mat-icon>
                                                            <!-- <span class="silver">{{(item?.to_integer | fullTime:false:true:true).silverTime}}</span>
                                                            <span class="black">{{(item?.to_integer | fullTime:false:true:true).blackTime}}</span> -->
                                                            <span class="black">{{item?.to_integer | timeFormat}}</span>
                                                        </div>
                                                    </ng-container>
                                                    <!-- ['task_consist_of_created','task_consist_of_removed','task_part_of_created','task_part_of_removed','task_related_created','task_related_removed'].includes(item.action) -->
                                                    <ng-container *ngSwitchCase="'task_consist_of_created'" class="alert_value">
                                                        <ng-container *ngTemplateOutlet="relationTaskTemplate"></ng-container>
                                                    </ng-container>
                                                    <ng-container *ngSwitchCase="'task_consist_of_removed'" class="alert_value">
                                                        <ng-container *ngTemplateOutlet="relationTaskTemplate"></ng-container>
                                                    </ng-container>
                                                    <ng-container *ngSwitchCase="'task_part_of_created'" class="alert_value">
                                                        <ng-container *ngTemplateOutlet="relationTaskTemplate"></ng-container>
                                                    </ng-container>
                                                    <ng-container *ngSwitchCase="'task_part_of_removed'" class="alert_value">
                                                        <ng-container *ngTemplateOutlet="relationTaskTemplate"></ng-container>
                                                    </ng-container>
                                                    <ng-container *ngSwitchCase="'task_related_created'" class="alert_value">
                                                        <ng-container *ngTemplateOutlet="relationTaskTemplate"></ng-container>
                                                    </ng-container>
                                                    <ng-container *ngSwitchCase="'task_related_removed'" class="alert_value">
                                                        <ng-container *ngTemplateOutlet="relationTaskTemplate"></ng-container>
                                                    </ng-container>
                
                                                    
                                                    <!-- <div *ngSwitchCase="'task_comment'" class="alert_value">
                                                        {{ item?.data?.text }}
                                                    </div>
                                                    <div *ngSwitchCase="'task_private_comment'" class="alert_value">
                                                        {{ item?.data?.text }}
                                                    </div> -->
                                                    <div *ngSwitchCase="'task_channel_channel'" class="alert_value profile_value">
                                                        <span>{{ "fromA" | translate }} </span>
                                                        <div class="outlet_block">
                                                            <ng-container [ngTemplateOutlet]="platformTemplate" [ngTemplateOutletContext]="{ platform_id: profileData(item.from_task_channel_channel_id)?.platform_id }"></ng-container>
                                                            {{ profileData(item.from_task_channel_channel_id).name }}
                                                        </div>
                                                        <span>{{ "toA" | translate }} </span>
                                                        <div class="outlet_block">
                                                            <ng-container [ngTemplateOutlet]="platformTemplate" [ngTemplateOutletContext]="{ platform_id: profileData(item.task_channel_channel_id)?.platform_id }"></ng-container>
                                                            {{ profileData(item.task_channel_channel_id)?.name }}
                                                        </div>
                                                    </div>
                                                    <div *ngSwitchCase="'task_channel_content_status'" class="alert_value">
                                                        <div *ngSwitchCase="'task_channel_content_status'" class="alert_value">
                                                            <span>{{ "fromA" | translate }} </span>
                                                            {{ profileStatusesData(item?.from_integer)?.name | translate }}
                                                            <span>{{ "toA" | translate }} </span>
                                                            {{ profileStatusesData(item?.to_integer)?.name | translate }}
                                                        </div>
                                                    </div>
                                                    <div *ngSwitchCase="'task_channel_content_published_at'" class="alert_value">
                                                        <span>{{ "fromA" | translate }} </span>
                                                        {{ item?.from_integer*1000 | date:'MMM dd, Y':'+0000': activeLang | yearPipe }}
                                                        <span>{{ "toA" | translate }} </span>
                                                        {{ item?.to_integer*1000 | date:'MMM dd, Y':'+0000': activeLang | yearPipe }}
                                                    </div>
                
                                                    <div *ngSwitchCase="'task_employee_assigned'" class="alert_value" style="display: inline-flex; align-items:center">
                                                        <ng-container *ngIf="!!employees?.length">
                                                            <app-user [class]="'user_div'" [employee]="getEmployee(item?.employee_id)"></app-user>
                                                        </ng-container>
                                                    </div>
                                                    <div *ngSwitchCase="'task_employee_is_manager'" class="alert_value">
                                                        {{ item?.task_employee_is_manager ? ( "manager" | translate ) : ( "not manager" | translate ) }}
                                                    </div>
                                                    <div *ngSwitchCase="'task_employee_rate'" class="alert_value">
                                                        {{ item?.task_employee_rate | reportsNumber }}
                                                    </div>
                                                    <div *ngSwitchCase="'task_employee_price'" class="alert_value">
                                                        {{ item?.task_employee_price | reportsNumber }}
                                                    </div>
                                                    <div *ngSwitchCase="'task_employee_status'" class="alert_value">
                                                        {{ getOperationStatusName(item?.task_employee_status) }}
                                                    </div>
                                                    <div *ngSwitchCase="'task_employee_completed'" class="alert_value">
                                                        {{ item?.task_employee_completed_at*1000 | date:'MMM dd, Y':'+0000': activeLang | yearPipe }}
                                                    </div>
                                                    <div *ngSwitchCase="'task_channel_created'" class="alert_value profile_value">
                                                        <ng-container [ngTemplateOutlet]="platformTemplate" [ngTemplateOutletContext]="{ platform_id: profileData(item.task_channel_channel_id)?.platform_id }"></ng-container>
                                                        {{ profileData(item.task_channel_channel_id)?.name }}
                                                    </div>
                                                    <div *ngSwitchCase="'task_channel_deleted'" class="alert_value profile_value">
                                                        <ng-container [ngTemplateOutlet]="platformTemplate" [ngTemplateOutletContext]="{ platform_id: profileData(item.task_channel_channel_id)?.platform_id }"></ng-container>
                                                        {{ profileData(item.task_channel_channel_id)?.name }}
                                                    </div>
                
                                                    <div *ngSwitchCase="'task_operation_status'" class="alert_value">
                                                        {{ getOperationStatusName(item?.task_operation_status_id) }}
                                                    </div>
                                                    <div *ngSwitchCase="'task_employee_status_start_at'" class="alert_value">
                                                        {{ item.task_employee_start_at*1000 | date:'MMM dd. Y, hh:mm a':'':activeLang | yearPipe }}
                                                    </div>
                                                    <div *ngSwitchCase="'task_employee_status_change'" class="alert_value" style="display: inline-flex; align-items:center">
                                                        {{ getOperationStatusName(item?.task_employee_status_id) }}
                                                        <span class="ml-1 mr-1">{{ "for" | translate }}</span>
                                                        <ng-container *ngIf="!!employees?.length">
                                                            <app-user [class]="'user_div'" [employee]="getEmployee(item?.employee_id)"></app-user>
                                                        </ng-container>
                                                    </div>
                                                    <div *ngSwitchCase="'task_custom_id'" class="alert_value">
                                                        <div class="d-f-c">
                                                            {{ item?.task_old_custom_id}}<mat-icon class="mr-1 ml-1">arrow_right_alt</mat-icon>{{ item?.task_custom_id }}
                                                        </div>
                                                    </div>
                                                    <div *ngSwitchCase="'discussion_post_added'" class="alert_value not_link"  (click)="openChat(item)">
                                                        {{ item?.discussion?.name }}
                                                    </div>
                                                    <div *ngSwitchCase="'file_created'" class="alert_value">
                                                        <span class="file_name">{{item?.file?.filename}}</span>
                                                    </div>
                                                    <div *ngSwitchCase="'file_location'" class="alert_value">
                                                        <span class="file_name">{{item?.file?.filename}}</span>
                                                        
                                                        <ng-container *ngIf="item?.data.length > 1; else elseTemplateLoc">
                                                            <span>{{ "fromA" | translate }} </span>
                                                            <span>{{ ( item?.data[0].text == '/' ? "Root" : item?.data[0].text.split("/")[item?.data[0].text.split("/").length - 1] ) | translate }} </span>
                                                            <span>{{ "toA" | translate }} </span>
                                                            <span>{{ ( item?.data[1].text == '/' ? "Root" : item?.data[1].text.split("/")[item?.data[1].text.split("/").length - 1] ) | translate }} </span>
                                                        </ng-container>
                                                        <ng-template #elseTemplateLoc>
                                                            <ng-container *ngIf="item?.data.length == 1; else elseTemplateOnlyOneLoc">
                                                                <span>{{ "toA" | translate }} </span>
                                                                <span>{{ ( item?.data[0].text == '/' ? "Root" : item?.data[0].text.split("/")[item?.data[0].text.split("/").length - 1] ) | translate }} </span>
                                                            </ng-container>
                                                            <ng-template #elseTemplateOnlyOneLoc>
                                                                {{ item?.data | json }}
                                                            </ng-template>
                                                            <!-- <span [innerHTML]="item?.data?.text | linky:{newWindow: true}"></span> -->
                                                        </ng-template>
                                                    </div>
                                                    <!-- <div *ngSwitchCase="'task_operation_comment'" class="alert_value">
                                                        {{ item?.data?.text }}
                                                    </div>
                                                    <div *ngSwitchCase="'task_operation_private_comment'" class="alert_value">
                                                        {{ item?.data?.text }}
                                                    </div>
                                                    <div *ngSwitchCase="'task_operation_employee_comment'" class="alert_value">
                                                        {{ item?.data?.text }}
                                                    </div> -->
                                                    <div *ngSwitchCase="'task_operation_completed'" class="alert_value">
                                                        <ng-container *ngIf="item.task_operation_completed_at != '0'; else elseComplTemplate">
                                                            <div matTooltip="{{ item.task_operation_completed_at*1000 | date:'shortTime':'':activeLang }}" matTooltipClass="dateTooltip" [matTooltipPosition]="'above'">
                                                                {{ item.task_operation_completed_at*1000 | date:'mediumDate':'':activeLang | yearPipe }} 
                                                            </div>
                                                        </ng-container>
                                                        <ng-template #elseComplTemplate>
                                                            {{ "Without date" | translate }}
                                                        </ng-template>
                                                    </div>
                                                    <div *ngSwitchCase="'task_operation_price'" class="alert_value">
                                                        <ng-container [ngSwitch]="item?.task_operation_price_currency_id">
                                                            <ng-container *ngSwitchCase="'1'">&#36;</ng-container>
                                                            <ng-container *ngSwitchCase="'2'">&#8381;</ng-container>
                                                            <ng-container *ngSwitchCase="'3'">&#8364;</ng-container>
                                                            <ng-container *ngSwitchCase="'4'">&#8372;</ng-container>
                                                        </ng-container>{{ item?.task_operation_price | reportsNumber }}
                                                    </div>
                                                    <div *ngSwitchCase="'discussion_status'" class="alert_value not_link" (click)="openChat(item)">
                                                        {{ getChatStatus(item?.discussion_status_id) | translate }}
                                                    </div>
                                                    <div *ngSwitchCase="'task_operation_rate'" class="alert_value">
                                                        {{ item?.task_operation_rate | reportsNumber }}
                                                    </div>
                                                    <div *ngSwitchCase="'task_operation_employee'" class="alert_value user_div">
                                                        <ng-container *ngIf="!!employees?.length">
                                                            <app-user [class]="'user_div'" [employee]="getEmployee(item?.task_operation_employee_id)"></app-user>
                                                        </ng-container>
                                                    </div>
                                                    <div *ngSwitchCase="'task_operation_operation'" class="alert_value">
                                                        {{ getColumnName(item?.task_operation_operation_id) }}
                                                    </div>
                                                    <div *ngSwitchCase="'task_parameter_value_created'" class="alert_value">
                                                        <b class="mr-1">{{ item.parameterValue.value }} ({{item.parameterValue.parameter.name}})</b>
                                                        <span *ngIf="item.file_id">{{ "for" | translate }} {{ "File" | translate }}: {{item?.file?.filename}}</span>
                                                        <span *ngIf="item.discussion_id" style="cursor: pointer;" (click)="openChat(item)">{{ "for" | translate }} {{ "Chat" | translate }}: {{item?.discussion?.name}}</span>
                                                    </div>
                                                    <div *ngSwitchCase="'task_parameter_value_deleted'" class="alert_value">
                                                        <b class="mr-1">{{ item.parameterValue.value }} ({{item.parameterValue.parameter.name}})</b>
                                                        <span *ngIf="item.file_id">{{ "fromA" | translate }} {{ "File" | translate }}: {{item?.file?.filename}}</span>
                                                        <span *ngIf="item.discussion_id" style="cursor: pointer;" (click)="openChat(item)">{{ "fromA" | translate }} {{ "Chat" | translate }}: {{item?.discussion?.name}}</span>
                                                    </div>
                                                    <div *ngSwitchCase="'task_channel_content_type'" class="alert_value">
                                                        <ng-container *ngIf="item?.from_integer">
                                                            <span>{{ "fromA" | translate }} </span>
                                                            <span>{{ getContentById(item?.from_integer)?.name }} </span>
                                                        </ng-container>
                                                        <span>{{ "toA" | translate }} </span>
                                                        <span>{{ getContentById(item?.to_integer)?.name }} </span>
                                                    </div>
                                                    <div *ngSwitchDefault class="alert_value">
                                                        <span class="file_name" *ngIf="item?.file_id">{{item?.file?.filename}}</span>
                                                        <ng-container *ngIf="item?.data.length > 1; else elseTemplateSwitch">
                                                            <span>{{ "fromA" | translate }} </span>
                                                            <span>{{ item?.data[0].text }} </span>
                                                            <span>{{ "toA" | translate }} </span>
                                                            <span>{{ item?.data[1].text }} </span>
                                                        </ng-container>
                                                        <ng-template #elseTemplateSwitch>
                                                            <ng-container *ngIf="item?.data.length == 1; else elseTemplateOnlyOne">
                                                                <span>{{ "toA" | translate }} </span>
                                                                <span>{{ item?.data[0].text }} </span>
                                                            </ng-container>
                                                            <ng-template #elseTemplateOnlyOne>
                                                                {{item?.data | json}}
                                                            </ng-template>
                                                            <!-- <span [innerHTML]="item?.data?.text | linky:{newWindow: true}"></span> -->
                                                        </ng-template>
                                                    </div>
                                                </ng-container>
                                    
                                            </div>
                
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>
                </ng-template>
            </ng-template>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions>
        <div class="mobile_interface_buttons" style="justify-content: space-between !important;">
            <button class="mobile_interface_btn" (click)="mobClose()">
                {{ "Close" | translate }}
            </button>
            
            <button *ngIf="tab == 1" class="mobile_interface_btn mobile_interface_btn_blue" (click)="getMore()">
                {{ "More" | translate }}
            </button>
        </div>
    </mat-dialog-actions>
</ng-template>

