<h2 mat-dialog-title class="dialog_title">
    <span>
        {{ "Variable" | translate }}
    </span>
</h2>
<mat-dialog-content class="mat-typography" dirEnter (dirSubmit)="!form.valid ? null : submitForm()">
    <div class="container form_wrapper" style="padding: 20px 0;">
        <form [formGroup]="form">
            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ "Choose a variable" | translate }}</mat-label>
                <mat-select formControlName="variable" required>
                    <mat-option *ngFor="let varItem of variables" [disabled]="varItem.name == 'Card'" [value]="varItem">
                        {{ varItem.name | translate }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </form>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button (click)="close()">
        {{ "Cancel" | translate }}
    </button>
    <button class="ml-1 d-f-btn" (click)="submitForm()" mat-raised-button cdkFocusInitial color="primary" [disabled]="!form.valid">
        {{ "Add" | translate }}
    </button>
</mat-dialog-actions>
