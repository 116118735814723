

<h2 mat-dialog-title class="dialog_title">
    <span>{{ "Assigned Groups for Job Scenario" | translate }}: {{data?.tmpl?.name}}</span>
    <button class="ml-1" mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h2>

<mat-dialog-content class="mat-typography">
    <div class="container">
        <mat-list role="list">
            <mat-list-item role="listitem" *ngFor="let item of groupsToScenarioList; let i = index;">
                <div class="add_empl">
                    <div class="add_empl_val">
                        {{getGroupById(item.group_id)?.name}}
                    </div>
                    <div class="add_empl_btns" style="padding-left: 50px;" *ngIf="data?.company?.permissions?.includes('owner') || data?.company?.permissions?.includes('admin') || data?.company?.permissions.includes('manager')">
                        <mat-icon style="color: brown;" (click)="deleteGroup(item?.id, i)">delete</mat-icon>
                    </div>
                </div>
            </mat-list-item>
        </mat-list>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-raised-button (click)="close()">
        {{ "Back" | translate }}
    </button>
    <button class="ml-1 d-f-btn" (click)="addGroup()" mat-raised-button color="primary">
        {{ "Add" | translate }}
    </button>
</mat-dialog-actions>