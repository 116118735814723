<h2 mat-dialog-title class="title_modal" (click)="log()">
    <ng-container *ngIf="!!data.tmpl; else elseTemplateDesc">
        <span class="text_one_line" >
            {{ "Template edditing" | translate }}
        </span>
    </ng-container>
    <ng-template #elseTemplateDesc>
        <span>
            {{ "Template adding" | translate }}
        </span>
    </ng-template>
</h2>
<mat-dialog-content class="mat-typography">
    <div class="container card_editor notes_editor st_template">
        <form [formGroup]="form" class="form_with_pads">
            <div class="d-f-c">
                <mat-form-field appearance="standard" class="full_width_form">
                    <mat-label>{{ 'Template Title' | translate }}</mat-label>
                    <input cdkFocusInitial formControlName="name" matInput placeholder="{{ 'Template Title' | translate }}">
                </mat-form-field>
                <mat-form-field appearance="standard" class="currency_select ml-1">
                    <mat-label>{{ "Record types" | translate }}</mat-label>
                    <mat-select [disableOptionCentering]="true" selectDir formControlName="statement_record_type">
                        <mat-option [value]="''">
                          {{ "Unset" | translate }}
                        </mat-option>
                        <mat-option [value]="'record'">
                          {{ "Record" | translate }}
                        </mat-option>
                        <mat-option [value]="'return'">
                          {{ "Return" | translate }}
                        </mat-option>
                        <mat-option [value]="'change'">
                          {{ "Change" | translate }}
                        </mat-option>
                        <mat-option [value]="'earnings'">
                          {{ "Earnings" | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="standard" class="currency_select ml-1">
                    <mat-label>{{ "Group By" | translate }}</mat-label>
                    <mat-select [disableOptionCentering]="true" selectDir formControlName="group_by">
                        <mat-option [value]="''">
                            {{ "Unset" | translate }}
                        </mat-option>
                        <mat-option [value]="'discussion'">
                            {{ "Discussion" | translate }}
                        </mat-option>
                        <mat-option [value]="'task_operation'">
                            {{ "Job" | translate }}
                        </mat-option>
                        <mat-option [value]="'task'">
                            {{ "Card" | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="TextFormatter" [ngClass]="{'open': selectedText?.is_selected}" [ngStyle]="{'left': !!selectedText?.position?.x ? (selectedText?.position?.x + 'px') : '0px', 'top': !!selectedText?.position?.y ? (selectedText?.position?.y + 'px') : '0px'}">
                <div class="TextFormatter-buttons">
                    <button type="button" [ngClass]="{'active': !!selectedText?.state?.bold}" (click)="formatText('bold')" class="Button default translucent" aria-label="Bold text" title="Bold text">
                        <mat-icon>format_bold</mat-icon>
                    </button>
                    <button type="button" [ngClass]="{'active': !!selectedText?.state?.italic}" (click)="formatText('italic')" class="Button default translucent" aria-label="Italic text" title="Italic text">
                        <mat-icon>format_italic</mat-icon>
                    </button>
                    <button type="button" [ngClass]="{'active': !!selectedText?.state?.underline}" (click)="formatText('underline')" class="Button default translucent" aria-label="Underlined text" title="Underlined text">
                        <mat-icon>format_underlined</mat-icon>
                    </button>
                    <button type="button" [ngClass]="{'active': !!selectedText?.state?.strikeThrough}" (click)="formatText('strikeThrough')" class="Button default translucent" aria-label="Strikethrough text" title="Strikethrough text">
                        <mat-icon>strikethrough_s</mat-icon>
                    </button>
                    <button type="button" [ngClass]="{'active': !!selectedText?.state?.insertUnorderedList}" (click)="formatText('insertUnorderedList')" class="Button default translucent" aria-label="Strikethrough text" title="Strikethrough text">
                        <mat-icon>format_list_bulleted</mat-icon>
                    </button>
                    <button type="button" [ngClass]="{'active': !!selectedText?.state?.insertOrderedList}" (click)="formatText('insertOrderedList')" class="Button default translucent" aria-label="Strikethrough text" title="Strikethrough text">
                        <mat-icon>format_list_numbered</mat-icon>
                    </button>
                    <div class="TextFormatter-divider"></div>
                    <button (click)="formatText('createLink')" [ngClass]="{'active': !!selectedText?.state?.createLink}" type="button" class="Button default translucent" aria-label="Add Link" title="Add Link">
                        <mat-icon>link</mat-icon>
                    </button>
                    <button (click)="formatText('unlink')" *ngIf="!!selectedText?.state?.createLink" type="button" class="Button default translucent" aria-label="Delete Link" title="Delete Link">
                        <mat-icon>link_off</mat-icon>
                    </button>
                    <div class="TextFormatter-divider"></div>
                    <button (click)="copySelected()" type="button" class="Button default translucent" aria-label="Copy selected template" title="Copy selected template">
                        <mat-icon>content_copy</mat-icon>
                    </button>
                </div>
            </div>

            <div class="BlockFormatter" [ngClass]="{'open': selectedBlock?.is_selected}" [ngStyle]="{'left': !!selectedBlock?.position?.x ? (selectedBlock?.position?.x + 'px') : '0px', 'top': !!selectedBlock?.position?.y ? (selectedBlock?.position?.y + 'px') : '0px'}">
                <div class="TextFormatter-buttons">
                    <button type="button" (click)="deleteBlock(selectedBlock)" class="Button default translucent" aria-label="Delete Block" title="Delete Block">
                        <mat-icon>delete</mat-icon>
                    </button>
                    <button type="button" *ngIf="selectedBlock?.type != 'divider'" (click)="editBlock(selectedBlock)" class="Button default translucent" aria-label="Edit Block" title="Edit Block">
                        <mat-icon>edit</mat-icon>
                    </button>
                </div>
            </div>

            <!--  -->
            <div class="note_wrp">
                <div class="content_editable" #editableDiv [appDeviceClass]="'custom-scroll custom-scroll-t'" contenteditable="true" formControlName="template" matInput (copy)="onCopy($event)" (paste)="onPaste($event)" (mouseup)="mouseUp($event)"></div>
                <!-- <ng-container *ngIf="!no_acssess || !data.is_edit; else elseTemplateHas">
                </ng-container>
                <ng-template #elseTemplateHas>
                    <div class="content_editable" [appDeviceClass]="'custom-scroll custom-scroll-t'" (copy)="onCopy($event)" [innerHTML]="form.get('text').value | linky:{newWindow: true}" matInput></div>
                </ng-template> -->
            </div>
        </form>

        <div class="st_template_footer">
            <div class="foot">
                <span>
                    {{"Add" | translate}}
                </span>
                <div class="foot_btn ml-1" (click)="$event.preventDefault(); $event.stopPropagation(); addVariable()">{{"Variable" | translate}}</div>
                <div class="foot_btn ml-1" (click)="$event.preventDefault(); $event.stopPropagation(); addDateVariable()">{{"Date" | translate}}</div>
                <div class="foot_btn ml-1" (click)="$event.preventDefault(); $event.stopPropagation(); addTable()">{{"Table" | translate}}</div>
                <div class="foot_btn ml-1" (click)="$event.preventDefault(); $event.stopPropagation(); addPageDivider()">{{"Page divider" | translate}}</div>
            </div>
            <div class="foot ml-1">
                <span>
                    {{"Template" | translate}}
                </span>
                <div class="foot_btn ml-1" (click)="$event.preventDefault(); $event.stopPropagation(); clearTmpl()">{{"Clear" | translate}}</div>
                <div class="foot_btn ml-1" (click)="$event.preventDefault(); $event.stopPropagation(); copyTmpl()">{{"Copy" | translate}}</div>
                <div class="foot_btn ml-1" (click)="$event.preventDefault(); $event.stopPropagation(); pasteTmpl()">{{"Paste" | translate}}</div>
            </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <div class="f_w_p_btns">
        <button mat-raised-button (click)="closeBtn()">
            {{ "Close" | translate }}
        </button>
        <button class="ml-1 d-f-btn" (click)="submitForm()" mat-raised-button cdkFocusInitial color="primary" [disabled]="!form.valid || isSubmit">
            <mat-spinner class="spin_16 mr-1" *ngIf="isSubmit"></mat-spinner>  
            {{ "Save" | translate }}
        </button>
        <!-- <button (click)="insertText()">Вставить текст</button> -->
    </div>
</mat-dialog-actions>
