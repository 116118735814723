import { NgxMatDateAdapter } from '@angular-material-components/datetime-picker';
import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { ReplaySubject, forkJoin, from, fromEvent, of } from 'rxjs';
import { concatMap, debounceTime, filter, last, map, switchMap, tap } from 'rxjs/operators';
import { DeleteAlertComponent } from 'src/app/shared/global_components/delete-alert/delete-alert.component';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { AuthService } from 'src/app/shared/services/rest/auth.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { MY_FORMATS } from '../../atTasksDialog/task-profile-add/task-profile-add.component';
import { AddRegularComponent } from './dialogs/add-regular/add-regular.component';
import { TargetParametersComponent } from '../../atTasksDialog/target-parameters/target-parameters.component';
import * as moment from 'moment';
import { LoadingService } from 'src/app/shared/services/rest/loading.service';
import { SelectGroupForRegComponent } from './dialogs/select-group-for-reg/select-group-for-reg.component';

@Component({
  selector: 'app-regular-publications',
  templateUrl: './regular-publications.component.html',
  styleUrls: ['./regular-publications.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ]
})
export class RegularPublicationsComponent extends BaseClass implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild("regs") regs: ElementRef;
  public regulars: any;
  public profiles: any;
  public imgRoute: any;
  public company_id: any;
  public today = moment().set({hour:0,minute:0,second:0}).unix();
  public todayEnd = moment().endOf('day').unix();
  public weekDays = new Array(7)
  public monthDays = new Array(31)
  public pagination: any;
  public me: any;
  public user: any;
  public company: any;
  public platforms: any;
  public activeLang: any;
  public sortValue: any = 'id';
  public page: number = 1;
  public filter: FormGroup = this.fb.group({
    channel_id: [[]],
    platform_id: '',
    content_type_id: '',
    group_id: [[]],
    id: '',
    is_daily: 0,
    is_weekly: 0,
    is_monthly: 0
  })

  public isLoad: boolean = false;
  public contentPagination: any;
  public contentPage: number = 1;
  public keys: any = ["is_daily", "is_weekly", "is_monthly"];
  public is_mobile: boolean = false;
  public allContentTypes: any;
  public groups: any;
  
  constructor(
    private companyService: CompanyService,
    private activatedRoute: ActivatedRoute,
    private languageService: LanguageService,
    private _ngx_adapter: NgxMatDateAdapter<any>,
    private auth: AuthService,
    private ls: LoadingService,
    private fb: FormBuilder,
    private router: Router,
    private sm: StorageManagerService,
    private dialog: MatDialog,
    private bottomSheet: MatBottomSheet,
  ) { super() }

  ngOnInit(): void {
    this.company_id = this.activatedRoute.snapshot.queryParamMap.get('company_id');
    if (window.innerWidth <= 769) {
      this.is_mobile = true;
    } else {
      this.is_mobile = false;
    }
    this.onResize();

    this.getUser();
    this.getProfiles();
    this.getLangData();
    this.getCompany();
    this.getPlatforms();
    this.getImgRoute();
    this.onRouteChange();
    this.getContentTypes();
    this.getAllGroups();
  }

  openTargetValues(info, element) {
    let initData:any = {
      company: this.company
    }

    initData.el = element;

    initData.targetContentPlan = {
      content_plan_id: info[0],
      content_schedule_id: info[1],
      content_schedule_item_id: info[2]
    }
    if (element.company_id != this.company.id) {
      initData.target.partner_company_id = element.company_id
    }
    const dialogRef = this.dialog.open(TargetParametersComponent, {
      backdropClass: ['parameters_modal_backdrop'],
      panelClass: ['without_paddings_modal', 'parameters_modal'],
      data: initData
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        this.page = 1;
        this.getRegulars(this.page);
      })
    )
  }

  getContentById(id) {
    if (!this.allContentTypes || this.allContentTypes.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.allContentTypes.find(el => el.id == id)
  }

  removeValueFromMultiple(key, value) {
    let arr = this.filter.value[key];

    console.log(key, value);
    console.log(arr);
    
    if (arr.includes(value)) {
      arr.splice(arr.indexOf(value), 1)
    }
    console.log(arr);
    console.log(this.filter.value[key]);
    // return 
    // this.filter.patchValue({
    //   [key]: arr
    // })
    this.filterRegulars()
  }

  removeValue(val) {
    if (val == "all") {
      let params = {
        company_id: this.company_id
      }
      this.router.navigate(['/regular-publications'], {queryParams: params})
    } else {
      let params = {...this.activatedRoute.queryParamMap.source['_value']}
      delete params[val]

      this.router.navigate(['/regular-publications'], {queryParams: params})
    }
  }

  onResize() {
    this.attachSubscriptions(
      fromEvent(window, "resize").pipe(
        map(() => window.innerWidth)
      ).subscribe((wWidth) => {
          if (wWidth <= 769) {
            this.is_mobile = true;
          } else {
            this.is_mobile = false;
          }
        }
      )
    )
  }

  changeActiveStatus(key) {
    if (key == "all") {
      this.filter.patchValue({
        is_daily: 0,
        is_weekly: 0,
        is_monthly: 0,
      })
    } else {
      let x:any = {};
      this.keys.forEach(el => {
        if (el == key) {
          x[el] = 1
        } else {
          x[el] = 0
        }
      });

      this.filter.patchValue(x)
    }

    this.filterRegulars()
  }

  filterRegulars() {
    let params = {
      company_id: this.company_id
    }

    let filterData = {...this.filter.value};

    console.log("filterData", filterData)
    
    Object.keys(filterData).filter(x => x != 'preset_id').forEach(element => {
      if (['is_content_url', 'part_of_count', 'consist_of_count', 'related_count'].includes(element) && filterData[element] == '0') {
        params[element] = filterData[element]
      } else if (["group_id", "channel_id", "no_channel_id", "operation_employee_id", "operation_partner_company_id", 'task_parameter_value_id', 'parameter_value_id', 'file_parameter_value_id'].includes(element) && filterData[element].length > 0) {
        params[element] = filterData[element].join(',')
      } else if (filterData[element] == '0' && filterData[element] == 0 && (element == 'operation_employee_id' || element == 'operation_partner_company_id')) {
        params[element] = filterData[element]
      } else if (filterData[element] != '0' && filterData[element] != '') {
        params[element] = filterData[element]
      }
    });


    // console.log("filterData.operation_completed_at_to", filterData.operation_completed_at_to)
    if (filterData.operation_completed_at_to === 0) {
      params['operation_completed_at_to'] = '0';
    }
    
    console.log("proj params", params)

    this.router.navigate(['/regular-publications'], {queryParams: params})
  }

  getContentTypes() {
    this.attachSubscriptions(
      this.companyService.getContentTypes().pipe(
        map(el => el.body)
      ).subscribe(resp => {
        this.allContentTypes = resp;
      })
    )
  }

  getAllGroups() {
    this.attachSubscriptions(
      this.companyService.getInfiniteGroupsCompany(this.company_id, '1', '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.companyService.getInfiniteGroupsCompany(this.company_id, x).pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        this.groups = [].concat(...res);
      })
    )
  }

  getGroup(id) {
    if (!this.groups || !this.groups.find(el => el.id == id)) {
      return false;
    }
    return this.groups.find(el => el.id == id)
  } 

  onRouteChange() {
    this.attachSubscriptions(
      this.activatedRoute.queryParams.pipe(
        tap((val) => {
          if (this.company_id != this.activatedRoute.snapshot.queryParamMap.get('company_id')) {
            this.getProfiles();
            this.getLangData();
          }
          this.company_id = this.activatedRoute.snapshot.queryParamMap.get('company_id');
          this.sortValue = this.activatedRoute.snapshot.queryParamMap.get('order_by') || 'id';

          this.filter.patchValue({
            channel_id: !!this.activatedRoute.snapshot.queryParamMap.get('channel_id') ? this.activatedRoute.snapshot.queryParamMap.get('channel_id').split(',').map(Number) : [],
            platform_id: !!this.activatedRoute.snapshot.queryParamMap.get('channel_platform_id') ? +this.activatedRoute.snapshot.queryParamMap.get('channel_platform_id') : '',
            id: !!this.activatedRoute.snapshot.queryParamMap.get('id') ? +this.activatedRoute.snapshot.queryParamMap.get('id') : '',
            content_type_id: !!this.activatedRoute.snapshot.queryParamMap.get('content_type_id') ? +this.activatedRoute.snapshot.queryParamMap.get('content_type_id') : '',
            group_id: !!this.activatedRoute.snapshot.queryParamMap.get('group_id') ? this.activatedRoute.snapshot.queryParamMap.get('group_id').split(',').map(Number) : [],
            is_daily: !!this.activatedRoute.snapshot.queryParamMap.get('is_daily') ? +this.activatedRoute.snapshot.queryParamMap.get('is_daily') : 0,
            is_weekly: !!this.activatedRoute.snapshot.queryParamMap.get('is_weekly') ? +this.activatedRoute.snapshot.queryParamMap.get('is_weekly') : 0,
            is_monthly: !!this.activatedRoute.snapshot.queryParamMap.get('is_monthly') ? +this.activatedRoute.snapshot.queryParamMap.get('is_monthly') : 0,
          })

          console.log("FILTER VALUE", this.filter.value)
        }),
        switchMap((val) => {
          console.log("switchMap", this.company)
          if (this.company == undefined || this.company.id != this.activatedRoute.snapshot.queryParamMap.get('company_id')) {
            return this.companyService.getCompany(this.activatedRoute.snapshot.queryParamMap.get('company_id')).pipe(
              map(x => x[0]),
              tap(x => {
                this.company = x;
                this.me = this.company.employees[0];
                this.companyService.company$.next(x);
              }),
              map(x => val)
            )
          } else {
            return of(val)
          }
        }),
      ).subscribe(params => {
        console.log("Files route STREAM", params);
        // if (!!this.activatedRoute.snapshot.queryParamMap.get('groups_partner_id') && this.groups_partner_id != +this.activatedRoute.snapshot.queryParamMap.get('groups_partner_id')) {
        //   this.groups_partner_id = +this.activatedRoute.snapshot.queryParamMap.get('groups_partner_id');
        //   this.getPartnerGroups()
        // }
        this.page = 1;
        this.getRegulars(this.page);
      })
    )
  }

  getPlatforms() {
    this.attachSubscriptions(
      this.companyService.getGroupPlatforms().subscribe(resp => {
        this.platforms = resp;
      })
    )
  }

  getPlatformById(id) {
    if (!this.platforms || this.platforms.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.platforms.find(el => el.id == id)
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData().subscribe(resp => {
        this.activeLang = resp.active;
        this._ngx_adapter.setLocale(resp.active);
      })
    )
  }

  onFocusIn(value) {
    if (value.name == "New regular publication") {
      value.name = "";
    }
  }

  onFocusOut(value) {
    if (value.name == "") {
      value.name = "New regular publication";
    }
  }

  ngAfterViewInit(): void {
    if (this.regs) {
      this.regs.nativeElement.scroll(0,0);
    }
    this.onScroll();
  }

  onScroll() {
    this.attachSubscriptions(
      fromEvent(this.regs.nativeElement, "scroll").pipe(
        filter((e:any) => (e.target.scrollTop >=  e.target.scrollHeight - e.target.offsetHeight - 400) && !this.isLoad),
        debounceTime(300)
      ).subscribe(() => {
        if (this.page <= this.pagination['pageCount']) {
          this.getRegulars(this.page);
        }
      })
    )
  }

  getCompany() {
    this.attachSubscriptions(
      this.companyService.getCompany(this.company_id).subscribe(resp => {
        this.companyService.company$.next(resp[0]);
        this.company = resp[0];
      })
    )
  }

  getProfiles() {
    this.attachSubscriptions(
      this.companyService.getAllProfilesDyn('1', this.company_id, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }
          return forkJoin(arr.map(x => this.companyService.getAllProfilesDyn(x, this.company_id, '200').pipe(map(u => u.body)))).pipe(
            last(),
            tap(values => {
              let conValues = [].concat(...values)
              this.profiles = conValues;
            }),
          )
        }),
      ).subscribe(resp => {
        console.log("getProfiles profiles", this.profiles);
      })
    )
  }

  getChannelById(id) {
    if (!this.profiles || this.profiles.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.profiles.find(el => el.id == id)
  }

  getRegulars(page) {
    this.isLoad = true;
    this.attachSubscriptions(
      this.companyService.getRegulars(page, this.company_id, this.filter.value).pipe(
        tap(el => {
          if (page == 1) {
            this.pagination = {
              'pageCount': el.headers.get('x-pagination-page-count'),
              'perPage': el.headers.get('x-pagination-per-page'),
              'totalCount': el.headers.get('x-pagination-total-count'),
              'currentPage': el.headers.get('x-pagination-current-page'),
            }
          }
        }),
        map(res => res.body),
        tap(regs => {
          regs.forEach(reg => {
            reg.items.forEach(element => {
              element.timeInFormat = new Date(element.time * 1000).toISOString().slice(11, 16)
            });
          });
        })
      ).subscribe(resp => {
        if (page == 1) {
          this.regulars = resp;
        } else {
          this.regulars.push(...resp)
        }
        if (this.regulars.length == 1) {
          this.regulars[0].isOpen = true;
        }
        console.log("getRegulars - " + this.page, this.regulars)
        this.page++;
        this.isLoad = false;
      }, error => {
        this.isLoad = false;
      })
    )
  }

  addRegularItem(regular) {
    console.log(regular)
    let newItem:any = {
      company_id: this.company_id, 
      content_schedule_id: regular.id, 
      name: "New regular publication",
      time: 3600
    }
    if (!regular.is_daily) {
      newItem.day_number = 1
    }
    this.attachSubscriptions(
      this.companyService.addRegularItem(newItem).subscribe(resp => {
        regular.items.push(resp)
        this.focusValue(regular, resp)
      })
    )
  }

  getParameters() {
    // this.attachSubscriptions(
    //   this.parametersService.getRootParameters().pipe(
    //     tap(res => {
    //       this.parametersService.paramsData$.next(res)
    //     })
    //   ).subscribe(resp => {
    //     this.parameters = resp
    //     console.log("this.parameters", this.parameters)
    //   })
    // )
  }

  saveRegularItem(regular, value) {
    let itemData = {
      name: value.name,
      day_number: value.day_number,
      time: Number(value.timeInFormat.split(":")[0])*3600 + Number(value.timeInFormat.split(":")[1])*60,
    }
    this.attachSubscriptions(
      this.companyService.editRegularItem(value.id, itemData).subscribe(resp => {
        console.log("saveRegularItem", regular, value, resp)
        Object.keys(resp).forEach(key => {
          value[key] = resp[key]
        })
        value.isEddited = false
      })
    )
  }

  focusValue(regular, value) {
    value.isEddited = true;
    
    if (value.value == "New regular publication") {
      value.value = ""
    }
    // setTimeout(() => {
    //   if (document.getElementById(`val_${regular.id}_${value.id}`)) {
    //     document.getElementById(`val_${regular.id}_${value.id}`).focus()
    //   }
    // }, 0)
  }

  deleteRegularItem(regular, val, i) {
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: val,
        target: marker("regular publicatuion item")
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        console.log("yes");
        this.attachSubscriptions(
          this.companyService.deleteRegularItem(val.id).subscribe(resp => {
            regular.items.splice(i, 1)
          })
        )
      }
    });

  }

  deleteRegular(regular, i) {
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: regular,
        target: marker("regular publications format")
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        console.log("yes");
        this.attachSubscriptions(
          this.companyService.deleteRegular(regular.id).subscribe(resp => {
            this.regulars.splice(i, 1)
          })
        )
      }
    });

  }

  focusRegular(regular) {
    regular.isEddited = true;

    setTimeout(() => {
      if (document.getElementById(`regular_${regular.id}`)) {
        document.getElementById(`regular_${regular.id}`).focus()
      }
    }, 0)
  }



    // "company_id": 0,
    // "channel_id": 0,
    // "content_type_id": 0,
    // "name": "string",
    // "is_daily": 0,
    // "is_weekly": 0,
    // "is_monthly": 0,
    // "apply_from_time": 0,
    // "apply_to_time": 0
  // addRegular() {
  //   this.attachSubscriptions(
  //     this.companyService.addRegular({
  //       company_id: this.company_id,
  //       name: "Regular publications format",
  //       is_daily: 1
  //     }).subscribe(resp => {
  //       this.regulars.push(resp);
  //       this.focusRegular(resp);
  //     })
  //   )
  // }

  
  getUser() {
    this.attachSubscriptions(
      this.auth.$user.subscribe(resp => {
        this.user = resp;
      })
    )
  }

  dublicateRegular(regular, i) {
    const dialogRef = this.dialog.open(AddRegularComponent, {
      disableClose: true,
      data: {
        user: this.user,
        company: this.company,
        company_id: this.company_id,
        activeLang: this.activeLang,
        imgRoute: this.imgRoute,
        dublicate: regular
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().pipe(
        switchMap(result => {
          if (result && result.event == 'add') {
            return this.companyService.getRegulars('1', this.company_id, {id: result.data.id}).pipe(
              map(x => x.body[0])
            )
          } else {
            return of(null)
          }
        })
      ).subscribe(resp => {
        console.log("addRegular", resp)
        if (resp) {
          if (resp.items) {
            resp.items.forEach(element => {
              element.timeInFormat = new Date(element.time * 1000).toISOString().slice(11, 16)
            });
          }
          this.regulars.push(resp)
        }
      })
    )
  }

  addRegular() {
    const dialogRef = this.dialog.open(AddRegularComponent, {
      disableClose: true,
      data: {
        user: this.user,
        company: this.company,
        company_id: this.company_id,
        activeLang: this.activeLang,
        imgRoute: this.imgRoute,
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().pipe(
        switchMap(result => {
          if (result && result.event == 'add') {
            return this.companyService.getRegulars('1', this.company_id, {id: result.data.id}).pipe(
              map(x => x.body[0])
            )
          } else {
            return of(null)
          }
        })
      ).subscribe(resp => {
        console.log("addRegular", resp)
        if (resp) {
          this.regulars.push(resp)
        }
      })
    )
  }

  connectWithGroup(schedule, i, item?) {
    const dialogRef = this.dialog.open(SelectGroupForRegComponent, {
      disableClose: true,
      data: {
        user: this.user,
        company: this.company,
        schedule: schedule,
        item: item,
        activeLang: this.activeLang,
        imgRoute: this.imgRoute,
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(resp => {
        console.log("addRegular", resp)
      })
    )
  }

  randomizeMinutesRegular(regular, i) {
    console.log("prev randomizeMinutesRegular", regular);
    let lenSelection = regular.items.length;
    let count = 0;


    this.ls.requests$.next({
      value: 0,
      target: "Randomize minutes"
    })
    from(regular.items).pipe(
      concatMap((x:any) => {
        let hours = Math.floor(x.time / 3600); // Получаем количество часов
        let randomMinutes = Math.floor(Math.random() * 60); // Генерируем случайное число от 0 до 59
      
        let totalSeconds = hours * 3600 + randomMinutes * 60; 
        let itemData = {
          time: totalSeconds
        }
        return this.companyService.editRegularItem(x.id, itemData).pipe(
          tap(res => {
            x.time = res.time;
            x.timeInFormat = new Date(x.time * 1000).toISOString().slice(11, 16)
          })
        )
      })
    ).subscribe({
      next: (next) => {
        console.log("next RandomizeMinutes", next);
        this.ls.requests$.next({
          value: Math.round((100 / lenSelection) * (count+1)),
          target: `Randomize minutes`
        })
        count++;
      },
      complete: () => {
        console.log("complete RandomizeMinutes");
      },
      error: (error) => {
        console.log("error RandomizeMinutes", error)
      }
    })
  }

  editRegular(regular, i) {
    const dialogRef = this.dialog.open(AddRegularComponent, {
      disableClose: true,
      data: {
        user: this.user,
        company: this.company,
        company_id: this.company_id,
        activeLang: this.activeLang,
        regular: regular
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().pipe(
        switchMap(result => {
          if (result && result.event == 'add') {
            return this.companyService.getRegulars('1', this.company_id, {id: regular.id}).pipe(
              map(x => x.body[0])
            )
          } else {
            return of(null)
          }
        })
      ).subscribe(resp => {
        console.log("editRegular", resp)
        if (resp) {
          if (resp.items) {
            resp.items.forEach(element => {
              element.timeInFormat = new Date(element.time * 1000).toISOString().slice(11, 16)
            });
          }
          this.regulars.splice(i, 1, resp)
        }
      })
    )
  }

  goBack(e) {
    e.preventDefault();
    history.back();
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }


  // @HostListener('window:beforeunload')
  // private someFunction() {
  //   let check = this.regulars.filter(reg => reg.items.filter(x => !x.isEddited && x.hasOwnProperty('isEddited')).length > 0).length > 0
  //   if (check) {
  //     let confirmObject = confirm("You want to leave ?");
  //     if (confirmObject  == true) {
  //       //call some thing then return true;
  //       return true;
  //     } 
  //     else {
  //       return false;
  //     }
  //   } else {
  //     return true
  //   }

  // }
}
