import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { off } from 'process';
import { fromEvent, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { BaseClass } from 'src/app/shared/models/base-class';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { AuthService } from 'src/app/shared/services/rest/auth.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { environment } from 'src/environments/environment';
import { OpenTaskComponent } from '../../workspace-pages/cases/dialogs/open-task/open-task.component';
import { MultiCompanyTargetComponent } from '../dialogs/multi-company-target/multi-company-target.component';
import { casesModel } from 'src/app/shared/functions/casesModel';

@Component({
  selector: 'app-task-link',
  templateUrl: './task-link.component.html',
  styleUrls: ['./task-link.component.scss']
})
export class TaskLinkComponent extends BaseClass implements OnInit, OnDestroy {

  public host: any = environment.host;
  public imgRoute: any = '';
  public link: any;
  public sharedTasks: any;
  public is_mobile: boolean = false;
  public is_load: boolean = false;
  public linkStr: any;
  public tab: number = 0;
  constructor(
    private auth: AuthService,
    private router: Router,
    private sm: StorageManagerService,
    private dialog: MatDialog,
    private companyService: CompanyService,
    private activatedRoute: ActivatedRoute,
    private taskService: TaskService
  ) { super() }

  ngOnInit(): void {
    this.linkStr = window.location.href;
    this.tab = +this.activatedRoute.snapshot.queryParamMap.get('tab')
    this.getImgRoute();
    this.checkIsMobile();
    this.getInitMeta();
  }

  
  checkIsMobile() {
    if (window.innerWidth <= 769) {
      this.is_mobile = true;
    } else {
      this.is_mobile = false;
    }
    this.onResize();
  }

  onResize() {
    this.attachSubscriptions(
      fromEvent(window, "resize").pipe(
        map(() => window.innerWidth)
      ).subscribe((wWidth) => {
          if (wWidth <= 769) {
            this.is_mobile = true;
          } else {
            this.is_mobile = false;
          }
        }
      )
    )
  }

  toMainPage() {
    this.router.navigate(['/main'])
  }

  openInCompany(company) {
    this.openTask({id: this.link.meta.id, name: this.link.meta.title}, company)
  }

  getInitMeta() {
    this.is_load = true;
    this.attachSubscriptions(
      this.auth.getInitMetaDev('task', this.router.url.split('/')[2].split("?")[0]).pipe(
        switchMap((res:any) => {
          if (res.meta && res.meta.id && res.meta.acm) {
            return this.companyService.getCompanyesForLink(res.meta.id, false, false, false, res.meta.acm).pipe(
              tap(el => {
                res.companyes = el
              }),
              catchError(error => {
                console.log(error, res)
                res.companyes = []
                return of(res)
              }),
              map(() => res)
            )
          } else {
            res.companyes = []
            return of(res)
          }
        })
      ).subscribe(resp => {
        this.link = resp
        console.log("getInitData", resp)
        if (resp.companyes && resp.companyes.length) {
          if (resp.companyes.length == 1) {
            this.openTask({id: resp.meta.id}, resp.companyes[0])
            setTimeout(() => {
              this.is_load = false
            }, 1000)
          } else {
            this.is_load = false
          }
        } else {
          this.is_load = false
          if (resp.meta.id) {
            this.getSharedTasks(resp.meta.id)
          }
        }
      })
    )
  }

  getSharedTasks(task_id) {
    this.attachSubscriptions(
      this.companyService.getSharedTasks({task_id: task_id}).pipe(
        map(arr => {
          let data = casesModel(arr, [], "get");
          return data.arr
        }),
      ).subscribe(resp => {
        console.log("getSharedTasks", resp)
        this.sharedTasks = resp
        if (resp && resp[0]) {
          let taskData:any = {
            task_id: resp[0].id,
            sharedData: resp[0],
            is_shared: true
          }
      
          const dialogRef = this.dialog.open(OpenTaskComponent, {
            backdropClass: 'backdrop_under_header',
            panelClass: !this.is_mobile ? ['open_task_dialog', 'show_header'] : 'open_task_dialog',
            autoFocus: false,
            data: taskData
          });
      
          dialogRef.afterClosed().subscribe(res => {
            this.toMainPage()
          })
        }
      })
    )
  }

  onPartnerImgError(event){
    event.target.src = this.imgRoute+'/assets/img/partner.png'
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  openTask(task, company) {
    let taskData:any = {
      task_id: task.id,
      task: task,
      company: company,
      initCompanyId: company.id,
      tab: this.tab,
      loc: this.activatedRoute.snapshot.queryParamMap.get('path') ? decodeURIComponent(this.activatedRoute.snapshot.queryParamMap.get('path')) : ''
    }

    const dialogRef = this.dialog.open(OpenTaskComponent, {
      backdropClass: 'backdrop_under_header',
      panelClass: !this.is_mobile ? ['open_task_dialog', 'show_header'] : 'open_task_dialog',
      autoFocus: false,
      data: taskData
    });

    dialogRef.afterClosed().subscribe(res => {
      this.toMainPage()
    })
  }

  goToDashboard(company_id) {
    this.router.navigate(['/dashboard'], { queryParams: {company_id: company_id} });
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
