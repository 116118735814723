<h2 mat-dialog-title class="dialog_title">
    <div class="d-f-c">
        <svg style="width: 21px; height: 22px;" width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16 10C12.13 10 9 13.13 9 17C9 17.34 9.03 17.67 9.08 18H0V17L2 15V9C2 5.9 4.03 3.17 7 2.29V2C7 0.9 7.9 0 9 0C10.1 0 11 0.9 11 2V2.29C13.97 3.17 16 5.9 16 9V10ZM7 19C7 20.11 7.9 21 9 21C9.39 21 9.75 20.88 10.06 20.69C9.74 20.17 9.5 19.6 9.3 19H7ZM20.77 18.32C20.87 18.39 20.89 18.53 20.83 18.64L19.83 20.37C19.77 20.5 19.64 20.5 19.53 20.5L18.28 19.97C18 20.17 17.75 20.34 17.44 20.47L17.25 21.79C17.23 21.91 17.13 22 17 22H15C14.88 22 14.77 21.91 14.75 21.79L14.57 20.47C14.25 20.34 14 20.17 13.72 19.97L12.5 20.5C12.37 20.5 12.23 20.5 12.17 20.37L11.17 18.64C11.11 18.53 11.14 18.39 11.23 18.32L12.29 17.5C12.27 17.33 12.25 17.17 12.25 17C12.25 16.83 12.27 16.67 12.29 16.5L11.23 15.68C11.14 15.61 11.11 15.5 11.17 15.36L12.17 13.64C12.23 13.53 12.37 13.5 12.5 13.53L13.72 14C14 13.83 14.25 13.66 14.57 13.54L14.75 12.21C14.77 12.09 14.88 12 15 12H17C17.13 12 17.23 12.09 17.25 12.21L17.44 13.54C17.75 13.66 18 13.83 18.28 14L19.53 13.53C19.64 13.5 19.77 13.53 19.83 13.64L20.83 15.36C20.89 15.5 20.87 15.61 20.77 15.68L19.72 16.5C19.74 16.67 19.75 16.84 19.75 17C19.75 17.16 19.74 17.33 19.72 17.5L20.77 18.32ZM17.75 17C17.75 16.03 16.97 15.25 16 15.25C15.03 15.25 14.25 16.03 14.25 17C14.25 17.97 15.04 18.75 16 18.75C16.96 18.75 17.75 17.97 17.75 17Z" fill="#EDEDED"/>
        </svg>
        <span>
            {{ "Notifications settings" | translate }}
        </span>
    </div>
    <p>{{"Subscribe for notifications for new messages in this chat" | translate}}</p>
</h2>
<mat-dialog-content class="mat-typography">
    <div class="container form_wrapper settings_body">
        <div class="item head_item">
            <div class="user">
            </div>
            <div class="d-f-c">
                <div class="box_name">
                    {{ "Web Push" | translate }}
                </div>
                <!-- <div class="box_name">
                    {{ "SMS" | translate }}
                </div>
                <div class="box_name">
                    {{ "E-mail" | translate }}
                </div> -->
            </div>
        </div>
        <div class="item" *ngFor="let item of employees">
            <div class="user">
                <app-user [class]="'user_div'" [employee]="item"></app-user>
            </div>

            <div class="d-f-c" *ngIf="item?.notificationSubscription">
                <div class="box_wrp">
                    <mat-checkbox color="primary" class="" (change)="changeItemVal($event, item, 'is_webpush')" [(ngModel)]="item.notificationSubscription.is_webpush"></mat-checkbox>
                </div>
                <!-- <div class="box_wrp">
                    <mat-checkbox color="primary" class="silver" (change)="changeItemVal($event, item, 'is_sms')" [(ngModel)]="item.notificationSubscription.is_sms"></mat-checkbox>
                </div>
                <div class="box_wrp">
                    <mat-checkbox color="primary" class="silver" (change)="changeItemVal($event, item, 'is_email')" [(ngModel)]="item.notificationSubscription.is_email"></mat-checkbox>
                </div> -->
            </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <div class="start_btns">
        <button class="b_tn" (click)="close()">
            {{ "Close" | translate }}
        </button>
        <button class="b_tn b_tn_blue ml-1" [ngClass]="{'dis_btn': !is_changed}" (click)="save()" [disabled]="!is_changed">
            {{ "Save" | translate }}
        </button>
    </div>
</mat-dialog-actions>
