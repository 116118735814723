<mat-dialog-title class="dialog_title">
    <span (click)="log()">
        <ng-container *ngIf="data.is_not_assigned; else elseTemplateis_not_assigned">
            {{ "Creating cards for all unassigned posts" | translate }}
        </ng-container>
        <ng-template #elseTemplateis_not_assigned>
            <ng-container *ngIf="data.basedVideos; else elseTemplateTitle">
                <ng-container *ngIf="data.isMulti; else elseTemplateMultiTitle">
                    {{ data.videos.length }} {{ "new card(s) based on selected videos" | translate }}
                </ng-container>
                <ng-template #elseTemplateMultiTitle>
                    {{ "New Card based on" | translate }} {{ data.videos.length }} {{ "selected videos" | translate }}
                </ng-template>
            </ng-container>
            <ng-template #elseTemplateTitle>
                {{ "New Card" | translate }}
            </ng-template>
        </ng-template>
        
    </span>
    <div class="for_space users_select">
        <span>{{ "Card belongs to workspace:" | translate }}</span>
        <mat-chip-list [multiple]="false" [selected]="false">
            <mat-chip class="chip_user" style="padding-left: 0 !important;" [value]="data.company.id">   
                <app-user [class]="'user_div'" [companyEmployee]="data.company" [imgRoute]="data?.imgRoute" [host]="data?.host" [company]="data?.company"></app-user>
            </mat-chip>
        </mat-chip-list>
        <span (click)="change()" *ngIf="!data.basedVideos && !data.file_ids">
            {{ "Change" | translate }}
        </span>
    </div>
    <!-- <button mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
    </button> -->
</mat-dialog-title>

<mat-dialog-content class="mat-typography">
    <div class="container form_wrapper">

        <ng-container *ngIf="!templatesIsActive; else elseTemplateTempatesActiveForm">
            <form [formGroup]="creating" class="form_with_pads form_with_valids" *ngIf="data.is_not_assigned">
                <div class="white_block">
                    <div class="white_block_title">
                        {{ "Creating cards request details" | translate }}
                    </div>

                    <mat-form-field appearance="standard" class="full_width_form">
                        <ng-container *ngIf="!creating.get('name').valid && submited">
                            <div class="req_field"></div>
                            <div class="req_label">
                                <div class="angle"></div>
                                <span>
                                    {{ "You need to write here" | translate }}
                                </span>
                            </div>
                        </ng-container>
                        <mat-label>{{ 'Card title' | translate }}</mat-label>
                        <input formControlName="name" matInput placeholder="{{ 'Title' | translate }}">
                    </mat-form-field>
                </div>
            </form>

            <form [formGroup]="form" class="form_with_pads form_with_valids">
                <div class="white_block">
                    <div class="white_block_title">
                        {{ "Card details" | translate }}
                    </div>
                    <ng-container *ngIf="!data.isMulti; else elseTemplateDataIsMulti">
                        <mat-form-field appearance="standard" class="full_width_form">
                            <ng-container *ngIf="!form.get('name').valid && submited">
                                <div class="req_field"></div>
                                <div class="req_label">
                                    <div class="angle"></div>
                                    <span>
                                        {{ "You need to write here" | translate }}
                                    </span>
                                </div>
                            </ng-container>
                            <mat-label>{{ 'Card title' | translate }}</mat-label>
                            <input formControlName="name" matInput placeholder="{{ 'Name' | translate }}">
                        </mat-form-field>
                    </ng-container>
                    <ng-template #elseTemplateDataIsMulti>
                        <ng-container *ngIf="!!data.is_not_assigned; else elseTemplateNotAssignTitle">
                            <mat-form-field appearance="standard" class="full_width_form">
                                <mat-select formControlName="name">
                                    <mat-option [value]="'{video_name}'">
                                        {{ "Video title" | translate }}
                                    </mat-option>
                                    <mat-option [value]="'{video_filename}'">
                                        {{ "File name" | translate }}
                                    </mat-option>
                                    <mat-option [value]="'{video_name} {video_filename}'">
                                        {{ "Video title + file name" | translate }}
                                    </mat-option>
                                    <mat-option [value]="'{video_filename} {video_name}'">
                                        {{ "File name + video title" | translate }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </ng-container>
                        <ng-template #elseTemplateNotAssignTitle>
                            <mat-form-field appearance="standard" class="full_width_form">
                                <mat-label>{{ 'Card title' | translate }}</mat-label>
                                <input style="color: red;" [disabled]="true" value="{{ 'Cards title will be equal to Video title' | translate }}" matInput placeholder="{{ 'Card title' | translate }}">
                            </mat-form-field>
                        </ng-template>
                    </ng-template>
                    
                    <mat-form-field appearance="standard" class="full_width_form" *ngIf="!!form.get('custom_id_getter')">
                        <mat-label>{{ "Custom id getter" | translate }}</mat-label>
                        <mat-select formControlName="custom_id_getter">
                            <mat-option [value]="'filename_integer_spaced'">
                                {{ "First number before a space in filename" | translate }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
            
                    <mat-form-field appearance="standard" class="full_width_form">
                        <mat-label>{{ 'Brief description' | translate }}</mat-label>
                        <textarea 
                        cdkTextareaAutosize
                        #autosize="cdkTextareaAutosize"
                        cdkAutosizeMinRows="1"
                        cdkAutosizeMaxRows="5" formControlName="comment" matInput placeholder="{{ 'Comment' | translate }}"></textarea>
                    </mat-form-field>
                    
                    <mat-form-field appearance="standard" class="full_width_form">
                        <mat-label>{{ 'Hidden notes' | translate }} <span>({{ 'available only to owners, admins and managers' | translate }})</span> </mat-label>
                        <textarea 
                        cdkTextareaAutosize
                        #autosize="cdkTextareaAutosize"
                        cdkAutosizeMinRows="1"
                        cdkAutosizeMaxRows="5" formControlName="private_comment" matInput placeholder="{{ 'Private comment' | translate }}"></textarea>
                    </mat-form-field>
        
                    <div class="priority_chips">
                        <label>{{ "Priority" | translate }}: </label>
                        <mat-chip-list [multiple]="false" selectable formControlName="priority">
                            <mat-chip *ngFor="let priority of prioritys" [ngClass]="{
                                'Urgent': priority.id == '1000',
                                'High': priority.id == '900',
                                'Middle': priority.id == '800',
                                'Low': priority.id == '700',
                                'No': priority.id == '0'
                            }" class="priority_chip" (click)="selectPrioritys(priority)" [value]="priority.id">
                                {{ priority.name | translate }}
                            </mat-chip>
                        </mat-chip-list>
                    </div>
                </div>

                <div class="white_block">
                    <div class="white_block_title">
                        {{ "Card identification" | translate }}
                    </div>
        
                    <div class="small_chips" *ngIf="groups?.length > 0">
                        <label>{{ "Group" | translate }}: </label>
                        <mat-chip-list [multiple]="false" selectable formControlName="group_id">
                            <ng-container *ngFor="let group of groups; let i = index">
                                <mat-chip class="small_chip" (click)="selectGroup(group)" [value]="group?.id">
                                    {{ group.name | translate }}
                                </mat-chip>
                                <mat-chip *ngIf="i == groups?.length - 1 && i > 6" class="small_chip more" (click)="groupIdContainer.open()">
                                    {{ "More..." | translate }}
                                </mat-chip>
                            </ng-container>
                            
                        </mat-chip-list>
                    </div>
        
                    <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                        <mat-select #groupIdContainer formControlName="group_id" (selectionChange)="selectGroup($event)">
                            <mat-option>
                                <ngx-mat-select-search 
                                placeholderLabel="{{ 'Search' | translate }}" 
                                noEntriesFoundLabel="{{ 'no matching group found' | translate }}"
                                [formControl]="groupMoreControl"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let group of groups$ | async" [value]="group.id">
                                {{ group.name | translate }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
        
        
                    <mat-form-field appearance="standard" class="full_width_form number_mat" *ngIf="!data.isMulti">
                        <mat-label>{{ 'Custom ID' | translate }}</mat-label>
                        <input formControlName="custom_id" matInput type="number" placeholder="{{ 'Custom id' | translate }}">
                        <mat-hint><div class="paste_data" *ngIf="isGetId" (click)="pasteIdVal()" style="cursor: pointer;"><mat-icon>content_paste</mat-icon>{{ customIdValue }}</div></mat-hint>
                    </mat-form-field>
        
                    <div class="small_chips" *ngIf="statuses?.length > 0">
                        <ng-container *ngIf="!form.get('status_id').valid && submited">
                            <div class="req_field"></div>
                            <div class="req_label">
                                <div class="angle"></div>
                                <span>
                                    {{ "You need to choose a value" | translate }}
                                </span>
                            </div>
                        </ng-container>
                        <label>{{ "Card type" | translate }}: </label>
                        <mat-chip-list [multiple]="false" selectable formControlName="status_id">
                            <ng-container *ngFor="let status of statuses; let i = index">
                                <mat-chip class="small_chip" (click)="selectType(status)" [value]="status?.id">
                                    {{ status.name | translate }}
                                </mat-chip>
                                <mat-chip *ngIf="i == statuses?.length - 1 && i > 6" class="small_chip more" (click)="typesContainer.open()">
                                    {{ "More..." | translate }}
                                </mat-chip>
                            </ng-container>
                        </mat-chip-list>
                    </div>

                    <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                        <mat-select #typesContainer formControlName="status_id" (selectionChange)="selectType($event)">
                            <mat-option>
                                <ngx-mat-select-search 
                                placeholderLabel="{{ 'Search' | translate }}" 
                                noEntriesFoundLabel="{{ 'no matching type found' | translate }}"
                                [formControl]="statusesMoreControl"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let status of statuses$ | async" [value]="status.id">
                                {{ status.name | translate }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>    
                
                <div class="white_block white_expanded">
                    <div class="d-f-c" (click)="openRelations()">
                        <div class="white_block_title">
                            {{ "Assign relations to other card(s)" | translate }}
                        </div>
    
                        <button mat-icon-button>
                            <mat-icon *ngIf="relations">expand_less</mat-icon>
                            <mat-icon *ngIf="!relations">expand_more</mat-icon>
                        </button>
                    </div>
                    <div class="white_inside" [ngClass]="{'open': relations}">
                        <div class="search_type">
                            <mat-button-toggle-group appearance="legacy" [formControl]="typeOfSearchControl">
                                <mat-button-toggle value="Everywhere">Everywhere</mat-button-toggle>
                                <mat-button-toggle value="Custom ID">Custom ID</mat-button-toggle>
                                <mat-button-toggle value="Internal ID">Internal ID</mat-button-toggle>
                                <mat-button-toggle value="Global ID">Global ID</mat-button-toggle>
                                <mat-button-toggle value="Task Name">Card Name</mat-button-toggle>
                            </mat-button-toggle-group>

                            <mat-form-field appearance="legacy" class="ml-1" *ngIf="['Everywhere', 'Custom ID', 'Task Name'].includes(typeOfSearchControl.value)">
                                <mat-label>{{ "Group" | translate }}</mat-label>
                                <mat-select #groupIdContainer [formControl]="groupOfSearchControl">
                                    <mat-option>
                                        <ngx-mat-select-search 
                                        placeholderLabel="{{ 'Search' | translate }}" 
                                        noEntriesFoundLabel="{{ 'no matching group found' | translate }}"
                                        [formControl]="groupMoreControl"></ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option [value]="''">
                                        {{ 'All' | translate }}
                                    </mat-option>
                                    <mat-option *ngFor="let group of groups$ | async" [value]="group.id">
                                        {{ group.name | translate }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <ng-container formGroupName="consist_of_task_id">
                            <mat-form-field appearance="standard" class="full_width_form">
                                <mat-label>{{ "Consist of" | translate }}</mat-label>

                                <div class="chips-boundary consist_boundary">
                                    <mat-chip-list class="sorted_chips without_ordered" #consistOfChipList formControlName="add">
                                        <div class="chips_sorter_container" cdkDropListGroup #consistGroup>
                                          
                                            <div class="chips_drag_placeholder" cdkDropList (cdkDropListEntered)="entered($event, consistGroup, consistPlaceholder)" (cdkDropListDropped)="dropped(consistGroup, consistPlaceholder, form.get('consist_of_task_id').get('add').value)" #consistPlaceholder></div>
    
                                            <div *ngFor="let id of form.get('consist_of_task_id').get('add').value"  
                                            cdkDropList
                                            class="sorted_chips_drop_list"
                                            (cdkDropListEntered)="entered($event, consistGroup, consistPlaceholder)"
                                            (cdkDropListDropped)="dropped(consistGroup, consistPlaceholder, form.get('consist_of_task_id').get('add').value)">
                                                <div cdkDrag cdkDragBoundary=".consist_boundary" class="sorted_chip" (cdkDragReleased)="dragReleased(consistPlaceholder, consistDragPlaceholder)" (contextmenu)="openContext($event, {'id': id, 'form': form, 'control': 'consist_of_task_id'}); $event.preventDefault(); $event.stopPropagation();">
                                                    <div class="sorted_chip_custom_placeholder" #consistDragPlaceholder *cdkDragPlaceholder></div>
                                                    <mat-chip [removable]="true" color="primary" (removed)="onRemoved(id, 'consist_of_task_id')">
                                                        <ng-container *ngIf="findTask(id)">
                                                            {{ findTask(id).name.length > 25 ? (findTask(id).name | slice:0:25) + " ..." : findTask(id).name }} ({{findTask(id)?.custom_id}})
                                                        </ng-container>
                                                        <mat-icon matChipRemove>cancel</mat-icon>
                                                    </mat-chip>
                                                </div>
    
                                            </div>
    
                                            <input class="auto_complete_input"
                                            placeholder="{{ 'Search' | translate }}"
                                            #consistOfInput
                                            [formControl]="consistOfControl"
                                            [matAutocomplete]="consistOfAuto"
                                            [matChipInputFor]="consistOfChipList"
                                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
                                            
                                        </div>
                                    </mat-chip-list>
                                </div>

                                <mat-autocomplete (optionsScroll)="onScroll('consistOfControl')" autoActiveFirstOption #consistOfAuto="matAutocomplete" (optionSelected)="selected($event, consistOfInput, 'consist_of_task_id')">
                                    <ng-container *ngFor="let task of tasks$ | async">
                                        <mat-option *ngIf="!form.get('consist_of_task_id').get('add').value.includes(task.id) && (['Everywhere', 'Custom ID', 'Task Name'].includes(typeOfSearchControl.value) ? (groupOfSearchControl.value == task.group_id || groupOfSearchControl.value == '') : true)" [value]="task.id">
                                            <div class="task_ids_wrp_out">
                                                <div class="task_ids_wrp"> 
                                                    <span class="text_one_line">
                                                        {{ task.name }}
                                                    </span>
                                                    <div class="task_ids">
                                                        {{task.custom_id}} 
                                                        <span>
                                                            <div>{{task.internal_id}}</div>
                                                            <div>{{task.id}}</div>
                                                        </span>
                                                    </div>
                                                </div>

                                                <div class="group text_one_line">
                                                    {{task?.group?.name}}
                                                </div>
                                            </div>
                                        </mat-option>
                                    </ng-container>
                                    
                                </mat-autocomplete>
                            </mat-form-field>
                        </ng-container>
                        
                        <ng-container formGroupName="part_of_task_id">
                            <mat-form-field appearance="standard" class="full_width_form">
                                <mat-label>{{ "Part of" | translate }}</mat-label>

                                <div class="chips-boundary part_boundary">
                                    <mat-chip-list class="sorted_chips without_ordered" #partOfChipList formControlName="add">
                                        <div class="chips_sorter_container" cdkDropListGroup #partGroup>
                                          
                                            <div class="chips_drag_placeholder" cdkDropList (cdkDropListEntered)="entered($event, partGroup, partPlaceholder)" (cdkDropListDropped)="dropped(partGroup, partPlaceholder, form.get('part_of_task_id').get('add').value)" #partPlaceholder></div>
    
                                            <div *ngFor="let id of form.get('part_of_task_id').get('add').value"  
                                            cdkDropList
                                            class="sorted_chips_drop_list"
                                            (cdkDropListEntered)="entered($event, partGroup, partPlaceholder)"
                                            (cdkDropListDropped)="dropped(partGroup, partPlaceholder, form.get('part_of_task_id').get('add').value)">
                                                <div cdkDrag cdkDragBoundary=".part_boundary" class="sorted_chip" (cdkDragReleased)="dragReleased(partPlaceholder, partDragPlaceholder)" (contextmenu)="openContext($event, {'id': id, 'form': form, 'control': 'part_of_task_id'}); $event.preventDefault(); $event.stopPropagation();">
                                                <div class="sorted_chip_custom_placeholder" #partDragPlaceholder *cdkDragPlaceholder></div>
                                                <mat-chip [removable]="true" color="primary" (removed)="onRemoved(id, 'part_of_task_id')">
                                                    <ng-container *ngIf="findTask(id)">
                                                        {{ findTask(id).name.length > 25 ? (findTask(id).name | slice:0:25) + " ..." : findTask(id).name }} ({{findTask(id)?.custom_id}})
                                                    </ng-container>
                                                    <mat-icon matChipRemove>cancel</mat-icon>
                                                </mat-chip>
                                                </div>
    
                                            </div>
    
                                            <input class="auto_complete_input"
                                            placeholder="{{ 'Search' | translate }}"
                                            #partOfInput
                                            [formControl]="partOfControl"
                                            [matAutocomplete]="partOfAuto"
                                            [matChipInputFor]="partOfChipList"
                                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
                                            
                                        </div>
                                    </mat-chip-list>
                                </div>

                                <mat-autocomplete (optionsScroll)="onScroll('partOfControl')" autoActiveFirstOption #partOfAuto="matAutocomplete" (optionSelected)="selected($event, partOfInput, 'part_of_task_id')">
                                    <ng-container *ngFor="let task of tasks$ | async">
                                        <mat-option *ngIf="!form.get('part_of_task_id').get('add').value.includes(task.id) && (['Everywhere', 'Custom ID', 'Task Name'].includes(typeOfSearchControl.value) ? (groupOfSearchControl.value == task.group_id || groupOfSearchControl.value == '') : true)" [value]="task.id">
                                            <div class="task_ids_wrp_out">
                                                <div class="task_ids_wrp"> 
                                                    <span class="text_one_line">
                                                        {{ task.name }}
                                                    </span>
                                                    <div class="task_ids">
                                                        {{task.custom_id}} 
                                                        <span>
                                                            <div>{{task.internal_id}}</div>
                                                            <div>{{task.id}}</div>
                                                        </span>
                                                    </div>
                                                </div>

                                                <div class="group text_one_line">
                                                    {{task?.group?.name}}
                                                </div>
                                            </div>
                                        </mat-option>
                                    </ng-container>
                                    
                                </mat-autocomplete>
                            </mat-form-field>
                        </ng-container>

                        <ng-container formGroupName="related_task_id">
                            <mat-form-field appearance="standard" class="full_width_form">
                                <mat-label>{{ "Related" | translate }}</mat-label>

                                <div class="chips-boundary related_boundary">
                                    <mat-chip-list class="sorted_chips without_ordered" #relatedOfChipList formControlName="add">
                                        <div class="chips_sorter_container" cdkDropListGroup #relatedGroup>
                                          
                                            <div class="chips_drag_placeholder" cdkDropList (cdkDropListEntered)="entered($event, relatedGroup, relatedPlaceholder)" (cdkDropListDropped)="dropped(relatedGroup, relatedPlaceholder, form.get('related_task_id').get('add').value)" #relatedPlaceholder></div>
    
                                            <div *ngFor="let id of form.get('related_task_id').get('add').value"  
                                            cdkDropList
                                            class="sorted_chips_drop_list"
                                            (cdkDropListEntered)="entered($event, relatedGroup, relatedPlaceholder)"
                                            (cdkDropListDropped)="dropped(relatedGroup, relatedPlaceholder, form.get('related_task_id').get('add').value)">
                                                <div cdkDrag cdkDragBoundary=".related_boundary" class="sorted_chip" (cdkDragReleased)="dragReleased(relatedPlaceholder, relatedDragPlaceholder)" (contextmenu)="openContext($event, {'id': id, 'form': form, 'control': 'related_task_id'}); $event.preventDefault(); $event.stopPropagation();">
                                                    <div class="sorted_chip_custom_placeholder" #relatedDragPlaceholder *cdkDragPlaceholder></div>
                                                    <mat-chip [removable]="true" color="primary" (removed)="onRemoved(id, 'related_task_id')">
                                                        <ng-container *ngIf="findTask(id)">
                                                            {{ findTask(id).name.length > 25 ? (findTask(id).name | slice:0:25) + " ..." : findTask(id).name }} ({{findTask(id)?.custom_id}})
                                                        </ng-container>
                                                        <mat-icon matChipRemove>cancel</mat-icon>
                                                    </mat-chip>
                                                </div>
    
                                            </div>
    
                                            <input class="auto_complete_input"
                                            placeholder="{{ 'Search' | translate }}"
                                            #relatedOfInput
                                            [formControl]="relativeControl"
                                            [matAutocomplete]="relatedOfAuto"
                                            [matChipInputFor]="relatedOfChipList"
                                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
                                            
                                        </div>
                                    </mat-chip-list>
                                </div>

                                <mat-autocomplete (optionsScroll)="onScroll('relativeControl')" autoActiveFirstOption #relatedOfAuto="matAutocomplete" (optionSelected)="selected($event, relatedOfInput, 'related_task_id')">
                                    <ng-container *ngFor="let task of tasks$ | async">
                                        <mat-option *ngIf="!form.get('related_task_id').get('add').value.includes(task.id) && (['Everywhere', 'Custom ID', 'Task Name'].includes(typeOfSearchControl.value) ? (groupOfSearchControl.value == task.group_id || groupOfSearchControl.value == '') : true)" [value]="task.id">
                                            <div class="task_ids_wrp_out">
                                                <div class="task_ids_wrp"> 
                                                    <span class="text_one_line">
                                                        {{ task.name }}
                                                    </span>
                                                    <div class="task_ids">
                                                        {{task.custom_id}} 
                                                        <span>
                                                            <div>{{task.internal_id}}</div>
                                                            <div>{{task.id}}</div>
                                                        </span>
                                                    </div>
                                                </div>

                                                <div class="group text_one_line">
                                                    {{task?.group?.name}}
                                                </div>
                                            </div>
                                        </mat-option>
                                    </ng-container>
                                    
                                </mat-autocomplete>
                            </mat-form-field>
                        </ng-container>
                    </div>
                </div>

                <div class="white_block white_expanded">
                    <div class="d-f-c" (click)="openThumbnail()">
                        <div class="white_block_title">
                            {{ "Card thumbnail" | translate }}
                        </div>
    
                        <button mat-icon-button>
                            <mat-icon *ngIf="thumbIsOpen">expand_less</mat-icon>
                            <mat-icon *ngIf="!thumbIsOpen">expand_more</mat-icon>
                        </button>
                    </div>
                    <div class="white_inside" [ngClass]="{'open': thumbIsOpen}">
                        <mat-checkbox color="primary" formControlName="is_random_avatar">{{ "Use random image for card thumbnail" | translate }}</mat-checkbox>
                    </div>
                </div>

            </form>

            <div class="form_with_pads form_with_valids" style="margin-top: 0 !important;" *ngIf="!this.data.isMulti">
                <div class="white_block white_expanded" style="margin-top: 0 !important;">
                    <div class="d-f-c" (click)="openJobs()">
                        <div class="white_block_title">
                            {{ "Jobs" | translate }}
                        </div>
        
                        <button mat-icon-button>
                            <mat-icon *ngIf="jobsIsOpen">expand_less</mat-icon>
                            <mat-icon *ngIf="!jobsIsOpen">expand_more</mat-icon>
                        </button>
                    </div>
                    <div class="white_inside" [ngClass]="{'open': jobsIsOpen}">        
                        <div class="jobs_wrp">
                            <div class="job" *ngFor="let job of jobs; let i = index;">
        
                                <mat-form-field appearance="standard" class="full_width_form">
                                    <mat-label>{{ 'Title' | translate }}</mat-label>
                                    <input [(ngModel)]="job.name" matInput placeholder="{{ 'Title' | translate }}">
                                </mat-form-field>
                    
                                <mat-form-field appearance="standard" class="full_width_form">
                                    <mat-label>{{ 'Description' | translate }}</mat-label>
                                    <textarea [(ngModel)]="job.comment" matInput placeholder="{{ 'Drief description' | translate }}"
                                    cdkTextareaAutosize
                                    #autosize="cdkTextareaAutosize"
                                    cdkAutosizeMinRows="1"
                                    cdkAutosizeMaxRows="5"></textarea>
                                </mat-form-field>
        
                                <ng-container *ngIf="operations?.length > 0">
                                    <div class="small_chips" >
                                        <label>{{ "Job type" | translate }}: </label>
                                        <mat-chip-list [multiple]="false" selectable [(ngModel)]="job.operation_id">
                                            <ng-container *ngFor="let operation of operations; let i = index">
                                                <mat-chip class="small_chip" (click)="job.operation_id = operation?.id" [value]="operation?.id" [selected]="job.operation_id == operation?.id">
                                                    {{ operation.name | translate }}
                                                </mat-chip>
                                                <mat-chip *ngIf="i == operations?.length - 1 && i > 6" class="small_chip more" (click)="operationIdContainer.open()">
                                                    {{ "More..." | translate }}
                                                </mat-chip>
                                            </ng-container>
                                            
                                        </mat-chip-list>
                                    </div>
                        
                                    <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                                        <mat-select #operationIdContainer [(ngModel)]="job.operation_id">
                                            <mat-option>
                                                <ngx-mat-select-search 
                                                placeholderLabel="{{ 'Search' | translate }}" 
                                                noEntriesFoundLabel="{{ 'no matching card found' | translate }}"
                                                [formControl]="operationMoreControl"></ngx-mat-select-search>
                                            </mat-option>
                                            <mat-option *ngFor="let operation of operations$ | async" [value]="operation.id">
                                                {{ operation.name | translate }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </ng-container>
        
                                <div class="small_chips" *ngIf="operationStatuses?.length > 0">
                                    <label>{{ "Status" | translate }}: </label>
                                    <mat-chip-list [multiple]="false" selectable [(ngModel)]="job.status_id">
                                        <ng-container *ngFor="let status of operationStatuses">
                                            <mat-chip class="small_chip" (click)="job.status_id = status.id" [selected]="job.status_id == status.id" [value]="status?.id" [ngClass]="{
                                                'first': status.id == '1',
                                                'second': status.id == '2',
                                                'third': status.id == '3',
                                                'fours': status.id == '4',
                                                'fives': status.id == '5',
                                                'sixs': status.id == '6',
                                                'nine': status.id == '97',
                                                'seven': status.id == '98',
                                                'eight': status.id == '99'}">
                                                {{ status.name | translate }}
                                            </mat-chip>
                                        </ng-container>
                                    </mat-chip-list>
                                </div>      
                                
                                <div class="priority_chips">
                                    <label>{{ "Priority" | translate }}: </label>
                                    <mat-chip-list [multiple]="false" selectable [(ngModel)]="job.priority">
                                        <mat-chip *ngFor="let priority of prioritys" [selected]="job.priority == priority.id" [ngClass]="{
                                            'Urgent': priority.id == '1000',
                                            'High': priority.id == '900',
                                            'Middle': priority.id == '800',
                                            'Low': priority.id == '700',
                                            'No': priority.id == '0'
                                        }" class="priority_chip" (click)="job.priority = priority.id" [value]="priority.id">
                                            {{ priority.name | translate }}
                                        </mat-chip>
                                    </mat-chip-list>
                                </div>
        
                                <div class="job_executors" *ngIf="job.create_task_employees.length && employees && employees.length > 0">
                                    <div class="executor" *ngFor="let executor of job.create_task_employees; let k = index;">
                                        
                                        <app-user [class]="'user_div'" [employee]="getEmployeeById(executor.employee_id)"></app-user>
                                    
                                        <p>
                                            {{ "Choose member role" | translate }}:
                                            <mat-button-toggle-group appearance="legacy" class="ml-1" [(ngModel)]="executor.is_manager" [value]="executor.is_manager">
                                                <mat-button-toggle [value]="1">{{ "Executor" | translate }}</mat-button-toggle>
                                                <mat-button-toggle [value]="0">{{ "Assistant" | translate }}</mat-button-toggle>
                                            </mat-button-toggle-group>
                                        </p> 
        
                                        <button mat-icon-button color="warn" class="delete_executor" (click)="deleteExecutor(job, k)">
                                            <mat-icon>person_remove</mat-icon>
                                        </button>
        
                                    </div>
                                </div>
                                <ng-container *ngIf="job.create_task_partners.length && partners && partners.length > 0">
                                    <mat-divider></mat-divider>
                                    <div class="job_executors">
                                        <div class="executor" *ngFor="let partner of job.create_task_partners; let k = index;">
                                            
                                            <app-user [class]="'user_div'" [companyEmployee]="getPartner(partner.partner_company_id)?.partnerCompany" [imgRoute]="data?.imgRoute" [host]="data?.host" [company]="data?.company"></app-user>
                                        
                                            <p>
                                                {{ "Choose partner role" | translate }}:
                                                <mat-button-toggle-group appearance="legacy" class="ml-1" [(ngModel)]="partner.is_manager" [value]="partner.is_manager">
                                                    <mat-button-toggle [value]="1">{{ "Executor" | translate }}</mat-button-toggle>
                                                    <mat-button-toggle [value]="0">{{ "Assistant" | translate }}</mat-button-toggle>
                                                </mat-button-toggle-group>
                                            </p> 
            
                                            <button mat-icon-button color="warn" class="delete_executor" (click)="deletePartner(job, k)">
                                                <mat-icon>person_remove</mat-icon>
                                            </button>
            
                                        </div>
                                    </div>
                                </ng-container>
                                
        
        
                                <button mat-icon-button color="warn" class="delete_job" (click)="deleteJob(i)">
                                    <mat-icon>do_disturb_on</mat-icon>
                                </button>
        
                                <div class="add_new_executor" *ngIf="job.newExecutor" style="position: relative;">
                                    <div class="users_select">
                                        <label>{{ "Add new executor" | translate }}: </label>
                                        <mat-chip-list [multiple]="false" selectable [(ngModel)]="job.newExecutor.employee_id">
                                            <ng-container *ngFor="let employee of employees; let i = index">
                                                <mat-chip class="chip_user" (click)="!checkIfDisabled(job, employee.id) ? job.newExecutor.employee_id = employee.id : null" [selected]="job.newExecutor.employee_id == employee.id" [value]="employee.id" [disabled]="checkIfDisabled(job, employee.id)">
                                                    <app-user [class]="'user_div'" [employee]="employee"></app-user>
                                                </mat-chip>
                                                <mat-chip *ngIf="i == employees.length - 1 && i > 6" class="chip_user more" (click)="employeeIdContainer.open()">
                                                    {{ "more" | translate }}
                                                </mat-chip>
                                            </ng-container>
                                        </mat-chip-list>
                                    </div>
                        
                                    <mat-form-field appearance="standard" class="full_width_form hide-form-field" style="position: absolute;">
                                        <mat-select #employeeIdContainer [(ngModel)]="job.newExecutor.employee_id">
                                            <mat-option>
                                                <ngx-mat-select-search 
                                                placeholderLabel="{{ 'Search' | translate }}" 
                                                noEntriesFoundLabel="{{ 'no matching executor found' | translate }}"
                                                [formControl]="employeeMoreControl"></ngx-mat-select-search>
                                            </mat-option>
                                            <mat-option *ngFor="let employee of employees$ | async" [value]="employee.id" [ngClass]="{'opacity-6': checkIfDisabled(job, employee.id)}" [disabled]="checkIfDisabled(job, employee.id)">
                                                <app-user [class]="'user_div'" [employee]="employee"></app-user>
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
        
                                    <button mat-icon-button color="primary" (click)="addExecutor(job)">
                                        <mat-icon>person_add</mat-icon>
                                    </button>
        
                                </div>

                                <div class="add_new_executor" *ngIf="job.newPartner" style="position: relative;">
                                    <div class="users_select">
                                        <label>{{ "Add new partner" | translate }}: </label>
                                        <mat-chip-list [multiple]="false" selectable [(ngModel)]="job.newPartner.partner_company_id">
                                            <ng-container *ngFor="let partner of partners; let i = index">
                                                <mat-chip class="chip_user" (click)="!checkIfDisabledPartner(job, partner.partner_company_id) ? job.newPartner.partner_company_id = partner.partner_company_id : null" [selected]="job.newPartner.partner_company_id == partner.partner_company_id" [value]="partner.partner_company_id" [disabled]="checkIfDisabledPartner(job, partner.partner_company_id)">
                                                    <app-user [class]="'user_div'" [companyEmployee]="partner?.partnerCompany" [imgRoute]="data?.imgRoute" [host]="data?.host" [company]="data?.company"></app-user>
                                                </mat-chip>
                                                <mat-chip *ngIf="i == partners.length - 1 && i > 6" class="chip_user more" (click)="partnerIdContainer.open()">
                                                    {{ "more" | translate }}
                                                </mat-chip>
                                            </ng-container>
                                        </mat-chip-list>
                                    </div>
                        
                                    <mat-form-field appearance="standard" class="full_width_form hide-form-field" style="position: absolute;">
                                        <mat-select #partnerIdContainer [(ngModel)]="job.newPartner.partner_company_id">
                                            <mat-option>
                                                <ngx-mat-select-search 
                                                placeholderLabel="{{ 'Search' | translate }}" 
                                                noEntriesFoundLabel="{{ 'no matching partner found' | translate }}"
                                                [formControl]="partnersControl"></ngx-mat-select-search>
                                            </mat-option>
                                            <mat-option *ngFor="let partner of partners$ | async" [value]="partner.partner_company_id" [ngClass]="{'opacity-6': checkIfDisabledPartner(job, partner.partner_company_id)}" [disabled]="checkIfDisabledPartner(job, partner.partner_company_id)">
                                                <app-user [class]="'user_div'" [companyEmployee]="partner?.partnerCompany" [imgRoute]="data?.imgRoute" [host]="data?.host" [company]="data?.company"></app-user>
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
        
                                    <button mat-icon-button color="primary" (click)="addPartner(job)">
                                        <mat-icon>person_add</mat-icon>
                                    </button>
        
                                </div>
                            </div>
                        </div>
        
                        <div class="add_job" (click)="addJob()">
                            + {{ 'Add job' | translate }}
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-template #elseTemplateTempatesActiveForm>
            <div class="scenarios_list">
                <!-- <mat-list role="list" class="job_types">
                    <mat-list-item *ngFor="let tmpl of taskTemplates; let i = index;" (click)="selectTmpl(tmpl)" role="listitem" class="company_item">
                        <div class="type_name">
                            {{ tmpl?.name }}
                        </div>
                    </mat-list-item>

                </mat-list> -->

                <div class="template_item" (click)="selectTmpl(tmpl)" *ngFor="let tmpl of taskTemplates;">
                    <div class="type_name">
                        {{ tmpl?.name }}
                    </div>
                    <div class="tmpl_settings" *ngIf="tmpl.active">

                        <div class="form_with_valids full_width">
                            <mat-form-field appearance="standard" class="full_width_form">
                                <ng-container *ngIf="tmpl.template_data.name == '' && submitedTmpl">
                                    <div class="req_field"></div>
                                    <div class="req_label">
                                        <div class="angle"></div>
                                        <span>
                                            {{ "You need to write here" | translate }}
                                        </span>
                                    </div>
                                </ng-container>
                                <mat-label>{{ 'Card title' | translate }}</mat-label>
                                <input [(ngModel)]="tmpl.template_data.name" matInput placeholder="{{ 'Name' | translate }}">
                            </mat-form-field>
                        </div>

                        <button class="ml-1 d-f-btn" (click)="createTaskByTemplate(tmpl)" mat-raised-button cdkFocusInitial color="primary" [disabled]="!!isSubmitTmpl">
                            <mat-spinner class="spin_16 mr-1" *ngIf="isSubmitTmpl"></mat-spinner>  
                            {{ "Create card" | translate }}
                        </button>
                    </div>
                </div>

                <form [formGroup]="templateForm">

                    <div class="white_block white_expanded no_m_l_r">
                        <div class="d-f-c" (click)="openRelations()">
                            <div class="white_block_title">
                                {{ "Assign relations to other card(s)" | translate }}
                            </div>
        
                            <button mat-icon-button>
                                <mat-icon *ngIf="relations">expand_less</mat-icon>
                                <mat-icon *ngIf="!relations">expand_more</mat-icon>
                            </button>
                        </div>
                        <div class="white_inside" [ngClass]="{'open': relations}">
                            <div class="search_type">
                                <mat-button-toggle-group appearance="legacy" [formControl]="typeOfSearchControl">
                                    <mat-button-toggle value="Everywhere">Everywhere</mat-button-toggle>
                                    <mat-button-toggle value="Custom ID">Custom ID</mat-button-toggle>
                                    <mat-button-toggle value="Internal ID">Internal ID</mat-button-toggle>
                                    <mat-button-toggle value="Global ID">Global ID</mat-button-toggle>
                                    <mat-button-toggle value="Task Name">Card Name</mat-button-toggle>
                                </mat-button-toggle-group>
        
                                <mat-form-field appearance="legacy" class="ml-1" *ngIf="['Everywhere', 'Custom ID', 'Task Name'].includes(typeOfSearchControl.value)">
                                    <mat-label>{{ "Group" | translate }}</mat-label>
                                    <mat-select #groupIdContainer [formControl]="groupOfSearchControl">
                                        <mat-option>
                                            <ngx-mat-select-search 
                                            placeholderLabel="{{ 'Search' | translate }}" 
                                            noEntriesFoundLabel="{{ 'no matching group found' | translate }}"
                                            [formControl]="groupMoreControl"></ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option [value]="''">
                                            {{ 'All' | translate }}
                                        </mat-option>
                                        <mat-option *ngFor="let group of groups$ | async" [value]="group.id">
                                            {{ group.name | translate }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
        
                            <ng-container formGroupName="consist_of_task_id">
                                <mat-form-field appearance="standard" class="full_width_form">
                                    <mat-label>{{ "Consist of" | translate }}</mat-label>
        
                                    <div class="chips-boundary consist_boundary">
                                        <mat-chip-list class="sorted_chips without_ordered" #consistOfChipListTemplate formControlName="add">
                                            <div class="chips_sorter_container" cdkDropListGroup #consistGroupTemplate>
                                              
                                                <div class="chips_drag_placeholder" cdkDropList (cdkDropListEntered)="entered($event, consistGroupTemplate, consistPlaceholderTemplate)" (cdkDropListDropped)="dropped(consistGroupTemplate, consistPlaceholderTemplate, form.get('consist_of_task_id').get('add').value)" #consistPlaceholderTemplate></div>
        
                                                <div *ngFor="let id of templateForm.get('consist_of_task_id').get('add').value"  
                                                cdkDropList
                                                class="sorted_chips_drop_list"
                                                (cdkDropListEntered)="entered($event, consistGroupTemplate, consistPlaceholderTemplate)"
                                                (cdkDropListDropped)="dropped(consistGroupTemplate, consistPlaceholderTemplate, templateForm.get('consist_of_task_id').get('add').value)">
                                                    <div cdkDrag cdkDragBoundary=".consist_boundary" class="sorted_chip" (cdkDragReleased)="dragReleased(consistPlaceholderTemplate, consistDragPlaceholderTemplate)" (contextmenu)="openContext($event, {'id': id, 'form': templateForm, 'control': 'consist_of_task_id'}); $event.preventDefault(); $event.stopPropagation();">
                                                        <div class="sorted_chip_custom_placeholder" #consistDragPlaceholderTemplate *cdkDragPlaceholder></div>
                                                        <mat-chip [removable]="true" color="primary" (removed)="onRemovedTemplate(id, 'consist_of_task_id')">
                                                            <ng-container *ngIf="findTask(id)">
                                                                {{ findTask(id).name.length > 25 ? (findTask(id).name | slice:0:25) + " ..." : findTask(id).name }} ({{findTask(id)?.custom_id}})
                                                            </ng-container>
                                                            <mat-icon matChipRemove>cancel</mat-icon>
                                                        </mat-chip>
                                                    </div>
        
                                                </div>
        
                                                <input class="auto_complete_input"
                                                placeholder="{{ 'Search' | translate }}"
                                                #consistOfInputTemplate
                                                [formControl]="consistOfControlTemplate"
                                                [matAutocomplete]="consistOfAutoTemplate"
                                                [matChipInputFor]="consistOfChipListTemplate"
                                                [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
                                                
                                            </div>
                                        </mat-chip-list>
                                    </div>
        
                                    <mat-autocomplete (optionsScroll)="onScroll('consistOfControlTemplate')" autoActiveFirstOption #consistOfAutoTemplate="matAutocomplete" (optionSelected)="selectedTemplate($event, consistOfInputTemplate, 'consist_of_task_id')">
                                        <ng-container *ngFor="let task of tasks$ | async">
                                            <mat-option *ngIf="!templateForm.get('consist_of_task_id').get('add').value.includes(task.id) && (['Everywhere', 'Custom ID', 'Task Name'].includes(typeOfSearchControl.value) ? (groupOfSearchControl.value == task.group_id || groupOfSearchControl.value == '') : true)" [value]="task.id">
                                                <div class="task_ids_wrp_out">
                                                    <div class="task_ids_wrp"> 
                                                        <span class="text_one_line">
                                                            {{ task.name }}
                                                        </span>
                                                        <div class="task_ids">
                                                            {{task.custom_id}} 
                                                            <span>
                                                                <div>{{task.internal_id}}</div>
                                                                <div>{{task.id}}</div>
                                                            </span>
                                                        </div>
                                                    </div>
        
                                                    <div class="group text_one_line">
                                                        {{task?.group?.name}}
                                                    </div>
                                                </div>
                                            </mat-option>
                                        </ng-container>
                                        
                                    </mat-autocomplete>
                                </mat-form-field>
                            </ng-container>
                            
                            <ng-container formGroupName="part_of_task_id">
                                <mat-form-field appearance="standard" class="full_width_form">
                                    <mat-label>{{ "Part of" | translate }}</mat-label>
        
                                    <div class="chips-boundary part_boundary">
                                        <mat-chip-list class="sorted_chips without_ordered" #partOfChipListTemplate formControlName="add">
                                            <div class="chips_sorter_container" cdkDropListGroup #partGroupTemplate>
                                              
                                                <div class="chips_drag_placeholder" cdkDropList (cdkDropListEntered)="entered($event, partGroupTemplate, partPlaceholderTemplate)" (cdkDropListDropped)="dropped(partGroupTemplate, partPlaceholderTemplate, templateForm.get('part_of_task_id').get('add').value)" #partPlaceholderTemplate></div>
        
                                                <div *ngFor="let id of templateForm.get('part_of_task_id').get('add').value"  
                                                cdkDropList
                                                class="sorted_chips_drop_list"
                                                (cdkDropListEntered)="entered($event, partGroupTemplate, partPlaceholderTemplate)"
                                                (cdkDropListDropped)="dropped(partGroupTemplate, partPlaceholderTemplate, templateForm.get('part_of_task_id').get('add').value)">
                                                    <div cdkDrag cdkDragBoundary=".part_boundary" class="sorted_chip" (cdkDragReleased)="dragReleased(partPlaceholderTemplate, partDragPlaceholderTemplate)" (contextmenu)="openContext($event, {'id': id, 'form': templateForm, 'control': 'part_of_task_id'}); $event.preventDefault(); $event.stopPropagation();">
                                                    <div class="sorted_chip_custom_placeholder" #partDragPlaceholderTemplate *cdkDragPlaceholder></div>
                                                    <mat-chip [removable]="true" color="primary" (removed)="onRemovedTemplate(id, 'part_of_task_id')">
                                                        <ng-container *ngIf="findTask(id)">
                                                            {{ findTask(id).name.length > 25 ? (findTask(id).name | slice:0:25) + " ..." : findTask(id).name }} ({{findTask(id)?.custom_id}})
                                                        </ng-container>
                                                        <mat-icon matChipRemove>cancel</mat-icon>
                                                    </mat-chip>
                                                    </div>
        
                                                </div>
        
                                                <input class="auto_complete_input"
                                                placeholder="{{ 'Search' | translate }}"
                                                #partOfInputTemplate
                                                [formControl]="partOfControlTemplate"
                                                [matAutocomplete]="partOfAutoTemplate"
                                                [matChipInputFor]="partOfChipListTemplate"
                                                [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
                                                
                                            </div>
                                        </mat-chip-list>
                                    </div>
        
                                    <mat-autocomplete (optionsScroll)="onScroll('partOfControlTemplate')" autoActiveFirstOption #partOfAutoTemplate="matAutocomplete" (optionSelected)="selectedTemplate($event, partOfInputTemplate, 'part_of_task_id')">
                                        <ng-container *ngFor="let task of tasks$ | async">
                                            <mat-option *ngIf="!templateForm.get('part_of_task_id').get('add').value.includes(task.id) && (['Everywhere', 'Custom ID', 'Task Name'].includes(typeOfSearchControl.value) ? (groupOfSearchControl.value == task.group_id || groupOfSearchControl.value == '') : true)" [value]="task.id">
                                                <div class="task_ids_wrp_out">
                                                    <div class="task_ids_wrp"> 
                                                        <span class="text_one_line">
                                                            {{ task.name }}
                                                        </span>
                                                        <div class="task_ids">
                                                            {{task.custom_id}} 
                                                            <span>
                                                                <div>{{task.internal_id}}</div>
                                                                <div>{{task.id}}</div>
                                                            </span>
                                                        </div>
                                                    </div>
        
                                                    <div class="group text_one_line">
                                                        {{task?.group?.name}}
                                                    </div>
                                                </div>
                                            </mat-option>
                                        </ng-container>
                                        
                                    </mat-autocomplete>
                                </mat-form-field>
                            </ng-container>
        
                            <ng-container formGroupName="related_task_id">
                                <mat-form-field appearance="standard" class="full_width_form">
                                    <mat-label>{{ "Related" | translate }}</mat-label>
        
                                    <div class="chips-boundary related_boundary">
                                        <mat-chip-list class="sorted_chips without_ordered" #relatedOfChipListTemplate formControlName="add">
                                            <div class="chips_sorter_container" cdkDropListGroup #relatedGroupTemplate>
                                              
                                                <div class="chips_drag_placeholder" cdkDropList (cdkDropListEntered)="entered($event, relatedGroupTemplate, relatedPlaceholderTemplate)" (cdkDropListDropped)="dropped(relatedGroupTemplate, relatedPlaceholderTemplate, templateForm.get('related_task_id').get('add').value)" #relatedPlaceholderTemplate></div>
        
                                                <div *ngFor="let id of templateForm.get('related_task_id').get('add').value"  
                                                cdkDropList
                                                class="sorted_chips_drop_list"
                                                (cdkDropListEntered)="entered($event, relatedGroupTemplate, relatedPlaceholderTemplate)"
                                                (cdkDropListDropped)="dropped(relatedGroupTemplate, relatedPlaceholderTemplate, templateForm.get('related_task_id').get('add').value)">
                                                    <div cdkDrag cdkDragBoundary=".related_boundary" class="sorted_chip" (cdkDragReleased)="dragReleased(relatedPlaceholderTemplate, relatedDragPlaceholder)" (contextmenu)="openContext($event, {'id': id, 'form': templateForm, 'control': 'related_task_id'}); $event.preventDefault(); $event.stopPropagation();">
                                                        <div class="sorted_chip_custom_placeholder" #relatedDragPlaceholder *cdkDragPlaceholder></div>
                                                        <mat-chip [removable]="true" color="primary" (removed)="onRemovedTemplate(id, 'related_task_id')">
                                                            <ng-container *ngIf="findTask(id)">
                                                                {{ findTask(id).name.length > 25 ? (findTask(id).name | slice:0:25) + " ..." : findTask(id).name }} ({{findTask(id)?.custom_id}})
                                                            </ng-container>
                                                            <mat-icon matChipRemove>cancel</mat-icon>
                                                        </mat-chip>
                                                    </div>
        
                                                </div>
        
                                                <input class="auto_complete_input"
                                                placeholder="{{ 'Search' | translate }}"
                                                #relatedOfInputTemplate
                                                [formControl]="relativeControlTemplate"
                                                [matAutocomplete]="relatedOfAutoTemplate"
                                                [matChipInputFor]="relatedOfChipListTemplate"
                                                [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
                                                
                                            </div>
                                        </mat-chip-list>
                                    </div>
        
                                    <mat-autocomplete (optionsScroll)="onScroll('relativeControlTemplate')" autoActiveFirstOption #relatedOfAutoTemplate="matAutocomplete" (optionSelected)="selectedTemplate($event, relatedOfInputTemplate, 'related_task_id')">
                                        <ng-container *ngFor="let task of tasks$ | async">
                                            <mat-option *ngIf="!templateForm.get('related_task_id').get('add').value.includes(task.id) && (['Everywhere', 'Custom ID', 'Task Name'].includes(typeOfSearchControl.value) ? (groupOfSearchControl.value == task.group_id || groupOfSearchControl.value == '') : true)" [value]="task.id">
                                                <div class="task_ids_wrp_out">
                                                    <div class="task_ids_wrp"> 
                                                        <span class="text_one_line">
                                                            {{ task.name }}
                                                        </span>
                                                        <div class="task_ids">
                                                            {{task.custom_id}} 
                                                            <span>
                                                                <div>{{task.internal_id}}</div>
                                                                <div>{{task.id}}</div>
                                                            </span>
                                                        </div>
                                                    </div>
        
                                                    <div class="group text_one_line">
                                                        {{task?.group?.name}}
                                                    </div>
                                                </div>
                                            </mat-option>
                                        </ng-container>
                                        
                                    </mat-autocomplete>
                                </mat-form-field>
                            </ng-container>
                        </div>
                    </div>
                </form>
            </div>
        </ng-template>  
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <div class="f_w_p_btns">
        <ng-container *ngIf="!templatesIsActive; else elseTemplateTempatesActive">
            <button mat-button (click)="close()">
                {{ "Close" | translate }}
            </button>
            <button class="ml-1 d-f-btn" (click)="submitForm()" mat-raised-button cdkFocusInitial color="primary" [disabled]="!!isSubmit">
                <mat-spinner class="spin_16 mr-1" *ngIf="isSubmit"></mat-spinner>  
                {{ "Save" | translate }}
            </button>
            <button *ngIf="!data.basedVideos && !!taskTemplates && taskTemplates.length > 0" class="ml-1" (click)="toggleTemplates()" mat-button cdkFocusInitial color="warn" [disabled]="!!isSubmit">
                {{ "Create from template" | translate }}
            </button>
        </ng-container>
        <ng-template #elseTemplateTempatesActive>
            <button mat-button (click)="close()">
                {{ "Close" | translate }}
            </button>
            <button class="ml-1" mat-button color="warn" (click)="toggleTemplates()">
                {{ "Back to default creation" | translate }}
            </button>
        </ng-template>
        
    </div>
</mat-dialog-actions>

<ng-template #contextMenu let-contextdata>
	<section class="context-menu">
        <div *ngIf="contextdata.control != 'consist_of_task_id'" (click)="moveTo(contextdata, 'consist_of_task_id')">
            <mat-icon class="mr-1">swipe_up_alt</mat-icon>
            {{ 'Move to Consist Of' | translate }}
        </div>
        <div *ngIf="contextdata.control != 'part_of_task_id'" (click)="moveTo(contextdata, 'part_of_task_id')">
            <mat-icon class="mr-1" *ngIf="contextdata.control == 'related_task_id'">swipe_up_alt</mat-icon>
            <mat-icon class="mr-1" *ngIf="contextdata.control == 'consist_of_task_id'">swipe_down_alt</mat-icon>
            {{ 'Move to Part Of' | translate }}
        </div>
        <div *ngIf="contextdata.control != 'related_task_id'" (click)="moveTo(contextdata, 'related_task_id')">
            <mat-icon class="mr-1">swipe_down_alt</mat-icon>
            {{ 'Move to Related' | translate }}
        </div>
        <div class="menu_divider"></div>
        <div (click)="openCard(contextdata)">
            <mat-icon class="mr-1">open_in_new</mat-icon>
            {{ 'Open Card' | translate }}
        </div>
        <div class="menu_divider"></div>
        <div (click)="remove(contextdata)">
            <mat-icon class="mr-1">delete</mat-icon>
            {{ 'Remove' | translate }}
        </div>
	</section>
</ng-template>