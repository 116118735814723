import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { AuthService } from 'src/app/shared/services/rest/auth.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import * as moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/en-gb';
import 'moment/locale/uk';
import 'moment/locale/ru';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { BaseClass } from 'src/app/shared/models/base-class';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { NgxMatDateAdapter } from '@angular-material-components/datetime-picker';
import { environment } from 'src/environments/environment';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-edit-published-at',
  templateUrl: './edit-published-at.component.html',
  styleUrls: ['./edit-published-at.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ]
})


export class EditPublishedAtComponent extends BaseClass implements OnInit, OnDestroy {
  public host: any = environment.host;
  public imgRoute: any = '';
  public user: any = {};
  public user_id: number;
  public form: FormGroup;
  public company_id: any;
  public task_id: any;
  public profile_id: any;
  public isFormChange: boolean = false;
  public activeLang: any;
  public timeZone = - new Date().getTimezoneOffset();
  
  constructor(
    private bottomSheet: MatBottomSheet,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<EditPublishedAtComponent>,
    private sm: StorageManagerService,
    private auth: AuthService,
    private companyService: CompanyService,
    private fb: FormBuilder,
    private taskService: TaskService,
    private layoutService: LayoutService,
    private languageService: LanguageService,
    private _ngx_adapter: NgxMatDateAdapter<any>,
    private _adapter: DateAdapter<any>,
  ) { super() }

  ngOnInit(): void {
    window.scroll(0,0);
    this.company_id = this.data.company_id;
    this.task_id = this.data.task.id;
    this.profile_id = this.data.profile.id;

    this.form = this.fb.group({
      task_id: this.task_id,
      content_published_at: this.data.profile.content_published_at != '0' ? moment(this.showUserDate(false, this.data.profile.content_published_at) * 1000) : '',
      // content_published_at: [this.data.profile.content_published_at != '0' ? moment(this.data.profile.content_published_at*1000) : '', Validators.required],
    })

    this.attachSubscriptions(
      this.form.valueChanges.subscribe(() => this.isFormChange = true)
    )

    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        if (this.isFormChange) {
          this.layoutService.openBottomSheet(this.dialogRef);
        } else {
          this.close();
        }
      })
    )

    this.getImgRoute();
    this.getCompany();
    this.getCsrf();
    this.getUser();
    this.getLangData();
  }

  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData().subscribe(resp => {
        this._ngx_adapter.setLocale(resp.active);
        this._adapter.setLocale(resp.active);
        this.activeLang = resp.active;
      })
    )
  }

  getTaskProfiles() {
    this.attachSubscriptions(
      this.taskService.getTaskProfiles(this.task_id, this.company_id).subscribe(resp => console.log("PROFILES", resp))
    )
  }

  showUserDate(date:any = false, timestamp:any = false) {
    let timeAndDate;
    if (date) {
      timeAndDate = moment(date).clone()
    } else {
      timeAndDate = moment(timestamp*1000).clone()
    }

    const utcOffset = moment.tz(timeAndDate, this.data.company.timezone).utcOffset();
    
    let utcTimestamp = moment(timeAndDate).format("X")
    
    let difference = (Number(this.timeZone) - Number(utcOffset))*60
    console.log(difference);
    
    let workSpaceTimeZone;
    if (date) {
      workSpaceTimeZone = Number(utcTimestamp) + Number(difference)
    } else {
      workSpaceTimeZone = Number(utcTimestamp) - Number(difference)
    }
    return workSpaceTimeZone
  }


  getCompany() {
    this.attachSubscriptions(
      this.companyService.getCompany(this.company_id).subscribe(resp => {
        this.companyService.company$.next(resp[0]);
      })
    )
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  getCsrf() {
    this.attachSubscriptions(
      this.auth.$userToken.subscribe(resp => {
        this.user_id = resp.user_id;
        this.sm.localStorageSetItem("csrf_param", resp.csrf_param)
        this.sm.localStorageSetItem("csrf_token", resp.csrf_token)
      })
    )
  }

  getUser() {
    this.attachSubscriptions(
      this.auth.$user.subscribe(resp => {
        this.user = resp;
      })
    )
  }
  
  onSubmit() {

    // if (this.form.value.content_published_at) {
    //   this.form.value.content_published_at = moment(this.form.value.content_published_at._d).utc().format("X")
    // } else {
    //   this.form.value.content_published_at = ""
    // }

    console.log(this.form.value)
    let timeAndDate = this.form.value.content_published_at.clone()

    const utcOffset = moment.tz(timeAndDate, this.data.company.timezone).utcOffset();
    console.log(utcOffset);

    
    let utcTimestamp = moment(timeAndDate).format("X")
    console.log(utcTimestamp);
    
    let difference = (Number(this.timeZone) - Number(utcOffset))*60
    console.log("difference", difference);
    
    let workSpaceTimeZone = Number(utcTimestamp) + Number(difference)
    console.log("workSpaceTimeZone", workSpaceTimeZone);

    let sbmtData = {...this.form.value}

    sbmtData.content_published_at = workSpaceTimeZone


    this.attachSubscriptions(
      this.taskService.editProfile(this.profile_id, sbmtData, this.company_id).subscribe(resp => {
        this.dialogRef.close({event: "edit", data: resp});
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false});
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
