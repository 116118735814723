<mat-dialog-content class="mat-typography">
    <span class="head_title" (click)="log()">
        {{ "Automation" | translate }}
    </span>
    <div class="autos" [appDeviceClass]="'custom-scroll custom-scroll-t'">

        <div class="d-f-c" style="justify-content: space-between;">
            <mat-form-field appearance="legacy" class="white_legacy_select" *ngIf="parameters" style="color: #fff;">
                <mat-label>{{ "Tag category" | translate }}</mat-label>
                <mat-select [disableOptionCentering]="true" [multiple]="false" selectDir [(ngModel)]="parameter_id" (selectionChange)="onParamChange($event)">
                  <mat-option>
                    <ngx-mat-select-search 
                        placeholderLabel="{{ 'Search' | translate }}" 
                        noEntriesFoundLabel="{{ 'no matching tag found' | translate }}"
                        [formControl]="parametersCtrl"></ngx-mat-select-search>
                  </mat-option>
                  <mat-option *ngFor="let parameter of parameters$ | async" [value]="parameter.id">
                    {{parameter.name}}
                  </mat-option>
                </mat-select>
            </mat-form-field>

            <div class="d-f-c" style="margin-left: 40px;">
                <mat-form-field appearance="legacy" class="white_legacy_select" *ngIf="checklists" style="color: #fff;">
                    <mat-label>{{ "Check List" | translate }}</mat-label>
                    <mat-select [disableOptionCentering]="true" [multiple]="false" selectDir [(ngModel)]="operation_reminder_id" (selectionChange)="onRemChange($event)">
                      <mat-option>
                        <ngx-mat-select-search 
                            placeholderLabel="{{ 'Search' | translate }}" 
                            noEntriesFoundLabel="{{ 'no matching checklists found' | translate }}"
                            [formControl]="checklistsCtrl"></ngx-mat-select-search>
                      </mat-option>
                      <mat-option *ngFor="let check of checklists$ | async" [value]="check.id">
                        {{check.text}}
                      </mat-option>
                    </mat-select>
                </mat-form-field>
    
                <mat-form-field appearance="legacy" class="white_legacy_select ml-1" *ngIf="parameters" style="color: #fff;">
                    <mat-label>{{ "Inherit tags from categories" | translate }}</mat-label>
                    <mat-select [disableOptionCentering]="true" [multiple]="true" selectDir [(ngModel)]="nestedTags">
                      <mat-option>
                        <ngx-mat-select-search 
                            placeholderLabel="{{ 'Search' | translate }}" 
                            noEntriesFoundLabel="{{ 'no matching tag found' | translate }}"
                            [formControl]="parametersCtrl"></ngx-mat-select-search>
                      </mat-option>
                      <mat-option *ngFor="let parameter of parameters$ | async" [value]="parameter.id" [disabled]="parameter.id == parameter_id" (selectionChange)="onNestedChange($event)">
                        {{parameter.name}}
                      </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="title">{{"Videos" | translate}}</div>
        <div class="items">
            <div class="item tag_item" *ngIf="parameter_id && headCols">
                <div class="item_head">
                    <div class="item_photo">
                        <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.3125 1.875C1.46168 1.875 1.60476 1.81574 1.71025 1.71025C1.81574 1.60476 1.875 1.46168 1.875 1.3125C1.875 1.16332 1.81574 1.02024 1.71025 0.914752C1.60476 0.809263 1.46168 0.75 1.3125 0.75C1.16332 0.75 1.02024 0.809263 0.914752 0.914752C0.809263 1.02024 0.75 1.16332 0.75 1.3125C0.75 1.46168 0.809263 1.60476 0.914752 1.71025C1.02024 1.81574 1.16332 1.875 1.3125 1.875ZM5.77875 2.8425C5.91375 2.9775 6 3.165 6 3.375C6 3.58125 5.9175 3.76875 5.77875 3.90375L3.90375 5.77875C3.76875 5.91375 3.58125 6 3.375 6C3.16875 6 2.98125 5.9175 2.8425 5.77875L0.22125 3.1575C0.0825 3.01875 0 2.83125 0 2.625V0.75C0 0.33375 0.33375 0 0.75 0H2.625C2.83125 0 3.01875 0.0825 3.15375 0.2175L5.77875 2.8425ZM4.3275 0.64125L4.7025 0.26625L7.27875 2.8425C7.4175 2.9775 7.5 3.16875 7.5 3.375C7.5 3.58125 7.4175 3.76875 7.2825 3.90375L5.265 5.92125L4.89 5.54625L7.03125 3.375L4.3275 0.64125Z" fill="#fff"/>
                        </svg> 
                    </div>
                    <div>
                        <div class="name text_one_line">{{"Tag" | translate}}</div>
                    </div>
                </div>
                <ng-container *ngFor="let el of headCols; let i = index;">
                    <div class="item_el">
                        <div class="delete_line" (click)="deleteLine(el, i)">
                            <mat-icon>close</mat-icon>
                        </div>
                        <ng-container *ngIf="el">
                            <div class="d-f-c">
                                <div class="check_item" (click)="toggleCol(el)">
                                    <ng-container *ngIf="selectedCols && selectedCols.includes(el); else elseTemplateNoIncl">
                                        <mat-icon>check_box</mat-icon>
                                    </ng-container>
                                    <ng-template #elseTemplateNoIncl>
                                        <mat-icon>check_box_outline_blank</mat-icon>
                                    </ng-template>
                                </div>
                                <div class="name text_one_line">{{getValueById(el)?.value}}</div>
                            </div>
                            <div class="toggle_el" (click)="toggleLine(i)">
                                <svg *ngIf="!openedLines.includes(i)" width="13" height="7" viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 1L6.34286 6L12 1" stroke="#A3A3A3"/>
                                </svg> 
                                <svg *ngIf="!!openedLines.includes(i)" width="13" height="7" viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 6L6.65714 1L1 6" stroke="#A3A3A3"/>
                                </svg> 
                            </div>
                        </ng-container>
                    </div>
                    <ng-container *ngIf="openedLines.includes(i)">
                        <ng-container *ngFor="let out of outputObj; let j = index;">
                            <div class="item_el">
                                <div>
                                    <input [(ngModel)]="out.output_filenames[i]" class="proj_name" (input)="onInputFileName($event, i, j)" matInput placeholder="{{ 'File Name' | translate }}">
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                    
                </ng-container>
                
            </div>
            <div class="item" *ngFor="let item of videoFiles">
                <div class="item_head">
                    <div class="item_photo">
                        <!-- <mat-icon>movie</mat-icon> -->
                        <img [src]="data?.host + item?.original?.thumbnail + '?company_id=' + data?.company?.id">
                    </div>
                    <div>
                        <div class="name text_one_line">{{item?.original?.filename}}</div>
                        <div class="info">
                            <span>{{item?.original?.meta_duration | timeFormat}}</span>
                            <span>{{item?.original?.meta_width + " &#10005; " + item?.original?.meta_height}}</span>
                        </div>
                    </div>
                </div>
                <ng-container *ngFor="let el of item.replaceArr; let i = index;">
                    <div class="item_el">
                        <ng-container *ngIf="el">
                            <div class="item_photo">
                                <!-- <mat-icon>movie</mat-icon> -->
                                <img [src]="data?.host + el?.thumbnail + '?company_id=' + data?.company?.id">
                            </div>
                            <div>
                                <div class="name text_one_line">{{el.id == item.file_id ? ("Original file" | translate) : el?.filename}}</div>
                                <div class="info">
                                    <span>{{el?.meta_duration | timeFormat}}</span>
                                    <span>{{el?.meta_width + " &#10005; " + el?.meta_height}}</span>
                                </div>
                            </div>
                            <div class="delete_el" (click)="deleteEl(item, i)">
                                <mat-icon>close</mat-icon>
                            </div>
                        </ng-container>
                    </div>
                    <ng-container *ngIf="openedLines.includes(i)">
                        <ng-container *ngFor="let out of outputObj">
                            <div class="item_el">
                            </div>
                        </ng-container>
                    </ng-container>
                </ng-container>
                <div class="btn_add_repl" (click)="selectFile('video', item)">
                    {{"Add replacements" | translate}}
                </div>
            </div>
        </div>
        <div class="title" *ngIf="audioFiles && audioFiles.length">{{"Audios" | translate}}</div>
        <div class="items" *ngIf="audioFiles && audioFiles.length">
            <div class="item" *ngFor="let item of audioFiles">
                <div class="item_head">
                    <div class="item_photo">
                        <mat-icon>music_note</mat-icon>
                    </div>
                    <div>
                        <div class="name text_one_line">{{item?.original?.filename}}</div>
                        <div class="info">
                            <span>{{item?.original?.meta_duration | timeFormat}}</span>
                            <span>{{item?.original?.meta_width + " &#10005; " + item?.original?.meta_height}}</span>
                        </div>
                    </div>
                </div>
                <div class="item_el" *ngFor="let el of item.replaceArr; let i = index;">
                    <ng-container *ngIf="el">
                        <div class="item_photo">
                            <mat-icon>music_note</mat-icon>
                        </div>
                        <div>
                            <div class="name text_one_line">{{el.id == item.file_id ? ("Original file" | translate) : el?.filename}}</div>
                            <div class="info">
                                <span>{{el?.meta_duration | timeFormat}}</span>
                                <!-- <span>{{el?.meta_width + " &#10005; " + el?.meta_height}}</span> -->
                            </div>
                        </div>
                        <div class="delete_el" (click)="deleteEl(item, i)">
                            <mat-icon>close</mat-icon>
                        </div>
                    </ng-container>
                </div>
                <div class="btn_add_repl" (click)="selectFile('audio', item)">
                    {{"Add replacements" | translate}}
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <div class="bottom_btns">
        <div class="d-f-c">
            <button class="mr-1 d-f-btn" style="color: #fff !important;" *ngIf="parameter_id" (click)="openReplacementsSettings()" mat-button [disabled]="isGettingReplacements">
                <mat-spinner class="spin_16 mr-1" *ngIf="isGettingReplacements"></mat-spinner>  
                {{ "Get replacements based on filenames" | translate }}
            </button>
        </div>
        <div class="d-f-c">
            <div class="mr-1 loading" *ngIf="loadAuto">
                <mat-spinner class="spin_16 mr-1"></mat-spinner> 
                {{ "Loading project automation" }}
            </div>
    
            <button mat-raised-button (click)="close()">
                {{ "Back" | translate }}
            </button>

            <button class="ml-1 d-f-btn" *ngIf="!!data?.project?.batch_data" (click)="deleteAuto()" mat-raised-button color="accent">
                {{ "Delete auto" | translate }}
            </button>
            <button class="ml-1 d-f-btn" *ngIf="!!data?.project?.batch_data && !data?.project?.is_to_process && !data?.project?.is_reset && !data?.project?.is_error && !data?.project?.is_batch_apply" (click)="exportProjects()" mat-raised-button color="warn">
                <mat-spinner class="spin_16 mr-1" *ngIf="isSubmitAuto"></mat-spinner>  
                {{ "Export auto projects" | translate }}
            </button>
            <button class="ml-1 d-f-btn" (click)="submitForm()" mat-raised-button color="primary">
                <mat-spinner class="spin_16 mr-1" *ngIf="isSubmit"></mat-spinner>  
                {{ "Save" | translate }}
            </button>
        </div>
    </div>
</mat-dialog-actions>
