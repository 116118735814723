import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { BaseClass } from 'src/app/shared/models/base-class';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { RefreshService } from 'src/app/shared/services/rest/refresh.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';

@Component({
  selector: 'app-set',
  templateUrl: './set.component.html',
  styleUrls: ['./set.component.scss']
})
export class SetComponent extends BaseClass implements OnInit, OnDestroy {
  public form: FormGroup;
  public isSubmit: boolean = false;
  public promoCode: any = this.activatedRoute.snapshot.queryParamMap.get('promo')
  public promo: any;
  constructor(
    private fb: FormBuilder,
    private companyService: CompanyService,
    private router: Router,
    private refreshService: RefreshService,
    private activatedRoute: ActivatedRoute
  ) { 
    super()
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      name: ['', Validators.required]
    })

    if (this.promoCode) {
      this.findCode();
    }
  }

  findCode() {
    let params:any = {
      code: this.promoCode
    }
    this.attachSubscriptions(
      this.companyService.getWorkspacePromos('1', params).pipe(
        map(res => res.body),
      ).subscribe(resp => {        
        if (resp && resp.length) {
          this.promo = resp[0]
        } else {
          this.promo = 'Error'
        }
      })
    )
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

  goBack() {
    history.back();
  }

  onSubmit(is_live:boolean = false) {
    this.isSubmit = true;
    this.attachSubscriptions(
      this.companyService.createCompany(this.form.value).pipe(
        switchMap(res => {
          if (this.promo && this.promo != 'Error') {
            let sendData:any = {
              company_id: res.id,
              code: this.promoCode
            };

            return this.companyService.addWorkspacePromoEm(sendData).pipe(
              catchError(() => {
                return of(res)
              }),
              map(() => res)
            )
          } else {
            return of(res)
          }
        })
      ).subscribe(resp => {
        this.isSubmit = false;
        let params:any = {company_id: resp.id};
        if (is_live) {
          this.router.navigate(['/set-up-auto'], { queryParams: params });
        } else {
          this.router.navigate(['/set-up'], { queryParams: params });
        }
        this.refreshService.newWorkSpace$.next(true)
      })
    )
  }

}
