import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { marker } from "@biesbjerg/ngx-translate-extract-marker";
import { transformToWatchUrl } from "src/app/shared/functions/liveUrlTransform";
import { SnackBarItem } from "src/app/shared/global_components/snack-bar/snack-bar-item";
import { BaseClass } from "src/app/shared/models/base-class";
import { GlobalDataService } from "src/app/shared/services/common/global-data.service";
import { LayoutService } from "src/app/shared/services/common/layout.service";
import { StorageManagerService } from "src/app/shared/services/common/storage-manager.service";


@Component({
  selector: 'app-duplicate-settings',
  templateUrl: './duplicate-settings.component.html',
  styleUrls: ['./duplicate-settings.component.scss']
})
export class DuplicateSettingsComponent extends BaseClass implements OnInit, OnDestroy {
  public form: FormGroup = this.fb.group({
    post_delete_after: this.sm.localStorageGetItem('post_delete_after') ? true : false,
    post_backup_after: this.sm.localStorageGetItem('post_backup_after') ? true : false,
    post_link_after: this.sm.localStorageGetItem('post_link_after') ? true : false,
    new_link: '',
    post_status_after: this.sm.localStorageGetItem('post_status_after') ? +this.sm.localStorageGetItem('post_status_after') : 6,
  })

  public profileStatuses: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DuplicateSettingsComponent>,
    private dialog: MatDialog,
    private layoutService: LayoutService,
    private globalDataService: GlobalDataService,
    private fb: FormBuilder,
    private sm: StorageManagerService,
  ) { super() }

  ngOnInit(): void {
    console.log("DuplicateSettingsComponent", this.data)
    this.getProfilesStatus();
  }

  onInputUrl(originalUrl: string): void {
    console.log('originalUrl', originalUrl);
    let transformedUrl = transformToWatchUrl(originalUrl);
    
    if (transformedUrl != originalUrl) {
      this.form.patchValue({
        new_link: transformedUrl
      })
    }
    console.log("transformedUrl", transformedUrl);
  }

  log() {
    console.log(this.form.value)
  }

  getProfileStatus(id) {
    if (!this.profileStatuses) {
      return false;
    }
    return this.profileStatuses.find(el => el.id == id)
  } 

  getProfilesStatus() {
    this.attachSubscriptions(
      this.globalDataService.getContentStatus().subscribe(resp => {
        console.log("getContentStatus", resp);
        this.profileStatuses = resp.slice();
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  save() {
    this.dialogRef.close({event: "save", data: this.form.value})
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

}