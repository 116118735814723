<div [class]="class" [ngClass]="{
    'zero': status_id == '0',
    'first': status_id == '1',
    'second': status_id == '2',
    'third': status_id == '3',
    'fours': status_id == '4',
    'fives': status_id == '5',
    'sixs': status_id == '6',
    'nine': status_id == '97',
    'seven': status_id == '98',
    'eight': status_id == '99'}">
    <ng-container *ngIf="!!companyEmployee; else elseTemplateThisIsPartnerUser">
        <ng-container *ngIf="!!companyEmployee?.avatarFile?.thumbnail && !!host; else elseTemplatePartnerAvatar">
            <img [appHoverUserInfo]="{ user: {partnerCompany: companyEmployee}, company_id: company?.id, task_operation_id: task_operation_id}" class="partner_avatar" (error)="onPartnerImgError($event)" [src]="host + companyEmployee?.avatarFile?.thumbnail + '?company_id=' + company.id" alt="">
        </ng-container>
        <ng-template #elseTemplatePartnerAvatar>
            <img [appHoverUserInfo]="{ user: {partnerCompany: companyEmployee}, company_id: company?.id, task_operation_id: task_operation_id}" class="partner_avatar" (error)="onPartnerImgError($event)" src="{{ imgRoute }}assets/img/partner.png">
        </ng-template>
        <ng-container *ngIf="lines == 1; else elseTemplateOneLine">
            <span class="text_one_line">{{ companyEmployee?.name }}</span>
        </ng-container>
        <ng-template #elseTemplateOneLine>
            <span *ngIf="lines == 2" class="text_2_line">{{ companyEmployee?.name }}</span>
        </ng-template>
    </ng-container>
    <ng-template #elseTemplateThisIsPartnerUser>     
        <ng-container *ngIf="!!employee?.image; else elseTemplateUserHasDataImage">
            <img [appHoverUserInfo]="{ user: employee, company_id: company?.id, task_operation_id: task_operation_id }" [src]="employee?.image" alt="">
        </ng-container>
        <ng-template #elseTemplateUserHasDataImage>
            <mat-icon [appHoverUserInfo]="{ user: employee, company_id: company?.id, task_operation_id: task_operation_id }">account_circle</mat-icon>
        </ng-template>
        <ng-container *ngIf="lines == 1; else elseTemplateOneLine">
            <span class="text_one_line">{{ employee?.name }}</span>
        </ng-container>
        <ng-template #elseTemplateOneLine>
            <span *ngIf="lines == 2" class="text_2_line">{{ employee?.name }}</span>
        </ng-template>
    </ng-template>
</div>