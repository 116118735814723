import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { Player } from '@vime/angular';
import { forkJoin, fromEvent, interval, of, Subscription } from 'rxjs';
import { filter, map, switchMap, take, tap, toArray } from 'rxjs/operators';
import { BaseClass } from 'src/app/shared/models/base-class';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { prioritys } from 'src/app/shared/consts/prioritys';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { SnackBarItem } from '../../../snack-bar/snack-bar-item';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { FileService } from 'src/app/shared/services/rest/file.service';
import { environment } from 'src/environments/environment';
import { PDFDocumentProxy, PDFProgressData, PdfViewerComponent } from 'ng2-pdf-viewer';
import { ApproveJobComponent } from '../../../file-manager/dialog/approve-job/approve-job.component';
import { RefreshService } from 'src/app/shared/services/rest/refresh.service';
import { FileSettingsComponent } from '../../../file-manager/dialog/file-settings/file-settings.component';
import { TaskBarService } from 'src/app/shared/services/rest/task-bar.service';
import { MinimizeService } from 'src/app/shared/services/rest/minimize.service';
import { TargetParametersComponent } from 'src/app/components/atTasksDialog/target-parameters/target-parameters.component';
import { ParametersService } from 'src/app/shared/services/rest/parameters.service';
import { el } from 'date-fns/locale';
import { FileSpeachToTextComponent } from '../../../file-manager/dialog/file-speach-to-text/file-speach-to-text.component';
import { canTranscribe, transcribe } from 'src/app/shared/consts/transcribe.extensions';
import { MobChannelsFilterComponent } from '../../../navigation-bar/mob-filters/mob-channels-filter/mob-channels-filter.component';
import { SelectOutletComponent } from '../select-outlet/select-outlet.component';
import { PublishToOutletComponent } from '../../../file-manager/dialog/publish-to-outlet/publish-to-outlet.component';
import { ConnectedAppsService } from 'src/app/shared/services/rest/connected-apps.service';
import { MobPublishToOutletComponent } from '../mob-publish-to-outlet/mob-publish-to-outlet.component';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { casesModel } from 'src/app/shared/functions/casesModel';
import { SwipeDirective } from 'src/app/shared/directives/swipe.directive';

// import SwiperCore, { Navigation, Swiper } from 'swiper/core';
// import { SwiperComponent } from 'swiper/angular';
// // install Swiper modules
// SwiperCore.use([Navigation]);

@Component({
  selector: 'app-mob-fm-view',
  templateUrl: './mob-fm-view.component.html',
  styleUrls: ['./mob-fm-view.component.scss']
})
export class MobFmViewComponent extends BaseClass implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild("videoPlayer") videoplayer: Player;
  // @ViewChild('swiperRef', { static: false }) swiper?: SwiperComponent;
  // @ViewChildren('videoPlayer') videoPlayers: QueryList<Player>
  public host: any = environment.host;
  public sub: Subscription;
  public chats: any;
  public chat_id: any;
  public origin = window.location.origin;
  public company_id: any;
  public videoWithotWorkspaceID: boolean = !!this.sm.localStorageGetItem('videoWithotWorkspaceID')
  public defaultPlayer: boolean = !!this.sm.localStorageGetItem('defaultPlayer')
  public company: any;
  public taskStatuses: any;
  public countSameFiles: number = 0;
  public employees: any;
  public prioritys = prioritys;
  public imgRoute: any = '';
  public statuses: any;
  public timeZone = new Date().getTimezoneOffset();
  public menuState: boolean = false;
  public new_chat: boolean = false;
  public showPanels: boolean = true;
  public srcIsChanging: boolean = false;
  public isSubmit: boolean = false;
  public is_mobile: boolean = false;
  public timeVal: number = 10;
  public videoSrc: any;
  public timeout: any;
  public imgSrc: any;
  
  public isDoubleTap: boolean = false;
  private lastTapTime: number = 0;
  private doubleTapThreshold: number = 150;
  doubleTapTimer: any = null; // Таймер для двойного нажатия

  public transcribe: any = transcribe;
  public canTranscribe: Function = canTranscribe;
  public fileLocationString: string;

  @ViewChildren(PdfViewerComponent) pdfComponent: QueryList<PdfViewerComponent>;
  
  public folders = [
    {
      name: "Sources",
      id: 0,
      location: "/sources",
      over: false,
      rootDir: true
    },
    {
      name: "To Approve",
      id: 1,
      location: "/to_approve",
      over: false,
      rootDir: true
    },
    {
      name: "Ready Files",
      id: 2,
      location: "/ready_files",
      over: false,
      rootDir: true
    },
    {
      name: "Approved",
      id: 3,
      location: "/approved",
      over: false,
      rootDir: true
    },
    {
      name: "Project",
      id: 4,
      location: "/project",
      over: false,
      rootDir: true
    },
    {
      name: "Trash",
      id: 5,
      location: "/trash",
      over: false,
      rootDir: true
    }
  ]
  @ViewChild(SwipeDirective) swipeDirective: SwipeDirective;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<MobFmViewComponent>,
    private activatedRoute: ActivatedRoute,
    private fileService: FileService,
    private companyService: CompanyService,
    private refreshService: RefreshService,
    private taskBarService: TaskBarService,
    private taskService: TaskService,
    private connectedAppsService: ConnectedAppsService,
    private parametersService: ParametersService,
    private minimizeService: MinimizeService,
    private dialog: MatDialog,
    private cd: ChangeDetectorRef,
    private layoutService: LayoutService,
    private languageService: LanguageService,
    private sm: StorageManagerService,
  ) { super() }

  ngOnInit(): void {
    console.log("MobFmViewComponent", this.data);
    this.company_id = this.activatedRoute.snapshot.queryParamMap.get('company_id');
    if (this.data.initCompanyId) {
      this.company_id = this.data.initCompanyId
    }
    if (this.data.initCompanyID) {
      this.company_id = this.data.initCompanyID
    }
    console.log(this.data.file.location)
    this.fileLocationString = this.data.file.location
    console.log(this.fileLocationString)
    if (!!this.fileLocationString) {
      if (this.data.file.location == '/') {
        this.fileLocationString = "Root"
      } else {
        this.folders.forEach(el => {
          this.fileLocationString = (this.fileLocationString as any).replaceAll(el.location, el.name)
          console.log(this.fileLocationString);
        })
        this.fileLocationString = (this.fileLocationString as any).replaceAll('/', " / ")
      }
    }

    if (!this.data.playlist || this.data.playlist.length == 0) {
      this.data.playlist = [this.data.file]
      this.data.currIndex = 0
    }
    // this.setSrcs()
    this.getImgRoute();
    this.setSrcForItem(this.data.playlist[this.data.currIndex])
    if (this.data.hasCompany) {
      this.company = this.data.hasCompany
    } else {
      this.getCompany();
    }
    
    if (this.data.isTaskCard && !this.data.hasCompany) {
      this.getPlaylist();
    }

    if (!this.data.activeLang || !this.data.operationsValues) {
      this.getLangData()
    }

    if (this.data.task) {
      if (!this.data.task.hasOwnProperty('uniqueChannels')) {
        let expPermFilter:any = {task_id: this.data.task.id}
        if (this.company_id != this.data.task.company_id) {
          expPermFilter.partner_company_id = this.data.task.company_id
        }
        this.attachSubscriptions(
          this.taskService.getExpandPermUrls('1', this.company_id, expPermFilter, '100').subscribe(result => {
            this.data.task.uniqueChannels = [];
            result.sort((a, b) => {
              if (a.content_status_id === 13 && b.content_status_id !== 13) {
                return -1;
              } else if (a.content_status_id === 1 && b.content_status_id !== 13 && b.content_status_id !== 1) {
                return -1;
              } else if (b.content_status_id === 13 || b.content_status_id === 1) {
                return 1;
              } else {
                return 0;
              }
            });
            result.forEach(channel => {
              if (channel.channel && [1,2,3,4].includes(channel.channel.platform_id) && !!channel.channel.channel_account_id) {
                if (this.data.task.uniqueChannels.filter(ch => ch.channel_id == channel.channel_id).length == 0) {
                  this.data.task.uniqueChannels.push(channel)
                } else {
                  if (!([1, 13].includes(this.data.task.uniqueChannels.find(ch => ch.channel_id == channel.channel_id).content_status_id) && !this.data.task.uniqueChannels.find(ch => ch.channel_id == channel.channel_id).content_url)) {
                    this.data.task.uniqueChannels.splice(this.data.task.uniqueChannels.indexOf(this.data.task.uniqueChannels.find(ch => ch.channel_id == channel.channel_id)), 1, channel)
                  }
                }
              }
            });
          })
        )
      }
    }

    this.layoutService.overflowHidden()
  }

  onRotateNext() {
    if (this.data.playlist[this.data.currIndex + 1]) {
      this.data.currIndex++
      this.setSrcForItem(this.data.playlist[this.data.currIndex])
    }
  }

  getCurrentPosition() {
    return this.data.playlist && this.data.playlist.length > 1 ? Math.ceil((this.data.currIndex / (this.data.playlist.length - 1)) * 100) : 100
  }
  
  onRotatePrevious() {
    if (this.data.playlist[this.data.currIndex - 1]) {
      this.data.currIndex--
      this.setSrcForItem(this.data.playlist[this.data.currIndex])
    }
  }

  hidePanels() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.showPanels = false;
    }, 3000)
  }

  getTags(file) {
    let tagsFilter:any = {task_id: file.task_id, task_operation_id: file.task_operation_id ? file.task_operation_id : 0, discussion_id: 0, file_id: file.id}

    if (file.company_id != this.data.company.id) {
      tagsFilter.partner_company_id = file.company_id
    }
    this.attachSubscriptions(
      this.parametersService.getTargetParameters(this.data.company.id, tagsFilter).subscribe(resp => {
        file.tags = resp;
        console.log("getTags", file.tags)
      })
    )
  }

  transcribeFile(file) {
    const dialogRef = this.dialog.open(FileSpeachToTextComponent, {
      backdropClass: ['speech_file_backdrop'],
      panelClass: ['speech_file_pane'],
      data: {
        company: this.company,
        file: file,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result)
    })
  }

  setSrcForItem(item) {
    console.log("setSrcForItem item", item)
    this.srcIsChanging = true;
    if (item.content_type == 'application/pdf') {
      item.error = '';
      item.page = 1;
      item.rotation = 0;
      item.zoom = 1.0;
      item.zoomScale = 'page-fit';
      item.originalSize = false;
      item.pdf = undefined;
      item.renderText = true;
      item.progressData = {};
      item.isLoaded = false;
      item.stickToPage = true;
      item.showAll = true;
      item.autoresize = true;
      item.fitToPage = false;
      item.outline = [];
      item.isOutlineShown = false;
      item.pdfQuery = '';
      item.mobile = false;
      if (item.progressData) {
        item.progressData['percent'] = 0;
        item.progressData.loaded = 0;
        item.isLoaded = false;
      }
      this.attachSubscriptions(
        this.fileService.getPdfSrc(item, this.company_id).pipe(
          tap((res:any) => {
            if (res.download_url) {
              item.pdfSrc = res.download_url
            } else {
              item.pdfSrc = ''
            }
          })
        ).subscribe(res => {
          console.log(res)
          setTimeout(() => {
            this.srcIsChanging = false
          }, 0)
        })
      )
    } else {
      item.scale = 1;
      item.lastScale = 1;
      item.posX = 0;
      item.posY = 0;
      item.lastPosX = 0;
      item.lastPosY = 0;
      item.startX = 0;
      item.startY = 0;
      item.initialDistance = 0;
      // item.imageElement = undefined;
      item.imgSrc = item.thumbnail ? item.thumbnail : item.original
      item.videoSrc = item.preview1080 ? item.preview1080 : item.original
      setTimeout(() => {
        this.srcIsChanging = false
      }, 0)
    }
    this.showPanelsFunc();
    this.getTags(item)
  }

  // onDef(e, item) {
  //   item.scale = 1;
  //   item.lastScale = 1;
  //   item.posX = 0;
  //   item.posY = 0;
  //   item.lastPosX = 0;
  //   item.lastPosY = 0;
  //   item.startX = 0;
  //   item.startY = 0;
  //   item.initialDistance = 0;
  // }
  

  // previos
  // onTouchStart(event: TouchEvent, file) {
  //   file.imageElement = event.target as HTMLElement;

  //   if (event.touches.length === 2) {
  //     file.initialDistance = this.getDistance(event.touches[0], event.touches[1]);
  //     file.lastScale = file.scale;
  //     event.preventDefault();
  //   } else if (event.touches.length === 3) {
  //     event.preventDefault();
  //     file.startX = event.touches[0].pageX - file.lastPosX;
  //     file.startY = event.touches[0].pageY - file.lastPosY;
  //   }
  // }

  // onTouchMove(event: TouchEvent, file) {
  //   if (event.touches.length === 2) {
  //     event.preventDefault();
  //     const currentDistance = this.getDistance(event.touches[0], event.touches[1]);
  //     file.scale = Math.max(1, Math.min(file.lastScale * (currentDistance / file.initialDistance), 4));
  //     file.imageElement.style.transform = `translate(${file.posX}px, ${file.posY}px) scale(${file.scale})`;
  //   } else if (event.touches.length === 3) {
  //     event.preventDefault();
  //     file.posX = event.touches[0].pageX - file.startX;
  //     file.posY = event.touches[0].pageY - file.startY;
  //     file.imageElement.style.transform = `translate(${file.posX}px, ${file.posY}px) scale(${file.scale})`;
  //   }
  // }

  // onTouchEnd(event: TouchEvent, file) {
  //   if (event.touches.length === 0) {
  //     file.lastPosX = file.posX;
  //     file.lastPosY = file.posY;
  //   }
  // }

  // private getDistance(touch1: Touch, touch2: Touch): number {
  //   const dx = touch2.pageX - touch1.pageX;
  //   const dy = touch2.pageY - touch1.pageY;
  //   return Math.sqrt(dx * dx + dy * dy);
  // }

  onTouchStart(event: TouchEvent, file) {
    file.imageElement = event.target as HTMLElement;
  
    const currentTime = new Date().getTime();
    const tapInterval = currentTime - this.lastTapTime;
    
    // Очистка таймера, если он уже запущен
    if (this.doubleTapTimer) {
      clearTimeout(this.doubleTapTimer);
      this.doubleTapTimer = null;
    }
    
    // Проверка на двойное нажатие
    if (this.isDoubleTap && tapInterval < this.doubleTapThreshold && tapInterval > 50) {
      // alert('Double tap detected');
      this.onDoubleTap(event, file);
      this.isDoubleTap = false; // Сбрасываем флаг двойного нажатия
    } else {
      // Установка флага двойного нажатия
      this.isDoubleTap = true;
    
      // Установка таймера для сброса флага через doubleTapThreshold миллисекунд
      this.doubleTapTimer = setTimeout(() => {
        this.isDoubleTap = false;
      }, this.doubleTapThreshold);
    }
    
    // Обновление времени последнего нажатия
    this.lastTapTime = currentTime;
  
  
    const rect = file.imageElement.getBoundingClientRect();
    file.imageCenterX = rect.left + rect.width / 2;
    file.imageCenterY = rect.top + rect.height / 2;
  
    if (event.touches.length === 2) {
      file.initialDistance = this.getDistance(event.touches[0], event.touches[1]);
      file.lastScale = file.scale;
  
      // Центр между двумя пальцами в момент начала зума
      file.startCenterX = (event.touches[0].pageX + event.touches[1].pageX) / 2;
      file.startCenterY = (event.touches[0].pageY + event.touches[1].pageY) / 2;
  
      file.startPosX = file.posX;
      file.startPosY = file.posY;
  
      event.preventDefault();
    } else if (event.touches.length === 1) {
      file.startX = event.touches[0].pageX - file.posX;
      file.startY = event.touches[0].pageY - file.posY;
    }
  }
  
  // onTouchMove(event: TouchEvent, file) {
  //   if (event.touches.length === 2) {
  //     event.preventDefault();
  //     const currentDistance = this.getDistance(event.touches[0], event.touches[1]);
  //     const newScale = Math.max(1, Math.min(file.lastScale * (currentDistance / file.initialDistance), 4));
  
  //     const currentCenterX = (event.touches[0].pageX + event.touches[1].pageX) / 2;
  //     const currentCenterY = (event.touches[0].pageY + event.touches[1].pageY) / 2;
  
  //     const deltaX = currentCenterX - file.startCenterX;
  //     const deltaY = currentCenterY - file.startCenterY;
  
  //     // Инвертирование корректировок для новой позиции изображения
  //     file.posX = file.startPosX + (file.imageCenterX - file.startCenterX) * (newScale / file.lastScale - 1) - deltaX * (newScale / file.lastScale - 1);
  //     file.posY = file.startPosY + (file.imageCenterY - file.startCenterY) * (newScale / file.lastScale - 1) - deltaY * (newScale / file.lastScale - 1);
  //     file.scale = newScale;
  
  //     file.imageElement.style.transform = `translate(${file.posX}px, ${file.posY}px) scale(${file.scale})`;
  
  //     this.swipeDirective.zoomActive = true;
  //   } else if (event.touches.length === 1) {
  //     event.preventDefault();
  //     file.posX = event.touches[0].pageX - file.startX;
  //     file.posY = event.touches[0].pageY - file.startY;
  //     file.imageElement.style.transform = `translate(${file.posX}px, ${file.posY}px) scale(${file.scale})`;
  
  //     if (file.scale > 1) {
  //       this.swipeDirective.zoomActive = true;
  //     } else {
  //       this.swipeDirective.zoomActive = false;
  //     }
  //   }
  // }

  onTouchMove(event: TouchEvent, file) {
    if (event.touches.length === 2) {
      event.preventDefault();
      const currentDistance = this.getDistance(event.touches[0], event.touches[1]);
      const newScale = Math.max(1, Math.min(file.lastScale * (currentDistance / file.initialDistance), 2.35));
  
      const currentCenterX = (event.touches[0].pageX + event.touches[1].pageX) / 2;
      const currentCenterY = (event.touches[0].pageY + event.touches[1].pageY) / 2;
  
      const deltaX = currentCenterX - file.startCenterX;
      const deltaY = currentCenterY - file.startCenterY;
  
      const newPosX = file.startPosX + (file.imageCenterX - file.startCenterX) * (newScale / file.lastScale - 1) - deltaX * (newScale / file.lastScale - 1);
      const newPosY = file.startPosY + (file.imageCenterY - file.startCenterY) * (newScale / file.lastScale - 1) - deltaY * (newScale / file.lastScale - 1);
  
      file.posX = this.limitPosition(newPosX, file.imageElement.clientWidth, newScale);
      file.posY = this.limitPosition(newPosY, file.imageElement.clientHeight, newScale);
      file.scale = parseFloat(newScale.toFixed(2));
  
      file.imageElement.style.transform = `translate(${file.posX.toFixed(2)}px, ${file.posY.toFixed(2)}px) scale(${file.scale})`;
      file.imageElement.style.visibility = 'visible';
  
      this.swipeDirective.zoomActive = true;
    } else if (event.touches.length === 1) {
      // Логика для перемещения
      event.preventDefault();
  
      const newPosX = event.touches[0].pageX - file.startX;
      const newPosY = event.touches[0].pageY - file.startY;
  
      const currentPosX = this.limitPosition(newPosX, file.imageElement.clientWidth, file.scale);
      const currentPosY = this.limitPosition(newPosY, file.imageElement.clientHeight, file.scale);
  
      file.posX = currentPosX;
      file.posY = currentPosY;
  
      file.imageElement.style.transform = `translate(${file.posX.toFixed(2)}px, ${file.posY.toFixed(2)}px) scale(${file.scale})`;
      file.imageElement.style.visibility = 'visible';
  
      if (file.scale > 1) {
        this.swipeDirective.zoomActive = true;
      } else {
        this.swipeDirective.zoomActive = false;
      }
  
      // if (file.scale > 1) {
      //   // Проверяем, достигли ли мы края фото или превысили лимиты на 150px
      //   const edgeThreshold = 150;
      //   const edgePosX = file.imageElement.clientWidth - file.imageElement.offsetWidth;
      //   const leftEdgeReached = currentPosX <= edgePosX;
      //   const rightEdgeReached = currentPosX >= 0;
    
      //   if (leftEdgeReached && newPosX < 0 && Math.abs(newPosX) >= edgeThreshold) {
      //     // Превышение левого края на 150px
      //     this.onRotatePrevious(); // Вызываем метод для предыдущего изображения
      //   } else if (rightEdgeReached && newPosX > 0 && newPosX >= edgeThreshold) {
      //     // Превышение правого края на 150px
      //     this.onRotateNext(); // Вызываем метод для следующего изображения
      //   }
      // }
    }
  }
  
  limitPosition(pos: number, dimension: number, scale: number): number {
    const maxOffset = (scale - 1) * dimension / 2;
    return parseFloat(Math.min(maxOffset, Math.max(-maxOffset, pos)).toFixed(2));
  }
  
  onTouchEnd(event: TouchEvent, file) {
    if (event.touches.length === 0) {
      file.lastPosX = file.posX;
      file.lastPosY = file.posY;
  
      if (file.scale <= 1) {
        this.swipeDirective.zoomActive = false;
      }
    }
  }
  
  onDoubleTap(event: TouchEvent, file) {
    // Сброс масштаба и позиции при двойном нажатии
    file.scale = 1;
    file.posX = 0;
    file.posY = 0;
    file.lastPosX = 0;
    file.lastPosY = 0;
  
    file.imageElement.style.transform = `translate(${file.posX}px, ${file.posY}px) scale(${file.scale})`;
    file.imageElement.style.visibility = 'visible';
    this.swipeDirective.zoomActive = false;
  }
  
  private getDistance(touch1: Touch, touch2: Touch): number {
    const dx = touch2.pageX - touch1.pageX;
    const dy = touch2.pageY - touch1.pageY;
    return Math.sqrt(dx * dx + dy * dy);
  }
  
  showPanelsFunc() {
    this.showPanels = true;
    this.hidePanels();
  }

  togglePanels() {
    if (!!this.showPanels) {
      clearTimeout(this.timeout);
      this.showPanels = false;
    } else {
      this.showPanelsFunc();
    }
  }
  
  fileLocation(file) {
    return file.location.indexOf("/to_approve") != -1
  }

   // Load pdf
  loadPdf(item) {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', '/assets/pdf-test.pdf', true);
    xhr.responseType = 'blob';

    xhr.onload = (e: any) => {
      console.log(xhr);
      if (xhr.status === 200) {
        const blob = new Blob([xhr.response], { type: 'application/pdf' });
        item.pdfSrc = URL.createObjectURL(blob);
      }
    };

    xhr.send();
  }

  moveFile(file, type, i) {
    this.attachSubscriptions(
      this.fileService.editFile(file.id, {location: type}, this.data.company.id).subscribe(res => {
        file.location = res.location
        this.data.playlist.splice(i, 1)
        if (this.data.playlist.length == 0) {
          this.closeDialog()
        }
      }, error => {
        console.log(error)
      })
    )
  }

  openTargetValues(info, element) {
    let initData:any = {
      company: this.company,
      task: element.task,
    }

    initData.target = {
      task_id: info[0],
      task_operation_id: info[1],
      discussion_id: info[2],
      file_id: info[3]
    }
    if (element.company_id != this.company.id) {
      initData.target.partner_company_id = element.company_id
    }
    const dialogRef = this.dialog.open(TargetParametersComponent, {
      backdropClass: ['parameters_modal_backdrop'],
      panelClass: ['without_paddings_modal', 'parameters_modal'],
      data: initData
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        this.getTags(element)
      })
    )
  }

  readyFile(file) {
    this.attachSubscriptions(
      this.fileService.editFile(file.id, {is_ready_if_approved: !!file.is_ready_if_approved ? 0 : 1}, this.company.id).subscribe(resp => {
        file.is_ready_if_approved = resp.is_ready_if_approved;
      })
    )
  }

  /**
   * Set custom path to pdf worker
   */


  incrementPage(amount: number, item) {
    item.page += amount;
  }

  incrementZoom(amount: number, item) {
    item.zoom += amount;
  }

  rotate(angle: number, item) {
    item.rotation += angle;
  }

  /**
   * Render PDF preview on selecting file
   */
  onFileSelected(item) {
    const $pdf: any = document.querySelector('#file');

    if (typeof FileReader !== 'undefined') {
      const reader = new FileReader();

      reader.onload = (e: any) => {
        item.pdfSrc = e.target.result;
      };

      reader.readAsArrayBuffer($pdf.files[0]);
    }
  }

  /**
   * Get pdf information after it's loaded
   * @param pdf pdf document proxy
   */
  afterLoadComplete(pdf: PDFDocumentProxy, item) {
    item.pdf = pdf;

    this.loadOutline(item);
  }

  /**
   * Get outline
   */
  loadOutline(item) {
    item.pdf.getOutline().then((outline: any[]) => {
      item.outline = outline;
    });
  }

  /**
   * Handle error callback
   *
   * @param error error message
   */
  onError(error: any, item) {
    item.error = error; // set error

    if (error.name === 'PasswordException') {
      const password = prompt(
        'This document is password protected. Enter the password:'
      );

      if (password) {
        item.error = null;
        this.setPassword(password, item);
      }
    }
  }

  setPassword(password: string, item) {
    let newSrc;

    if (item.pdfSrc instanceof ArrayBuffer) {
      newSrc = { data: item.pdfSrc };
    } else if (typeof item.pdfSrc === 'string') {
      newSrc = { url: item.pdfSrc };
    } else {
      newSrc = { ...item.pdfSrc };
    }

    newSrc.password = password;

    item.pdfSrc = newSrc;
  }

  /**
   * Pdf loading progress callback
   * @param progressData pdf progress data
   */
  onProgress(progressData: PDFProgressData, item) {
    console.log(progressData);
    item.progressData = Object.assign(progressData, {
      percent: Math.round((progressData.loaded/progressData.total)*100)
    });

    item.isLoaded = progressData.loaded >= progressData.total;
    item.error = null; // clear error
  }

  getInt(value: number): number {
    return Math.round(value);
  }

  /**
   * Navigate to destination
   * @param destination pdf navigate to
   */
  navigateTo(destination: any, item) {
    // this.pdfComponent.pdfLinkService.goToDestination(destination);
  }

  /**
   * Scroll view
   */
  scrollToPage(item) {
    // item.pdfComponent.pdfViewer.scrollPageIntoView({
    //   pageNumber: 3
    // });
  }

  /**
   * Page rendered callback, which is called when a page is rendered (called multiple times)
   *
   * @param e custom event
   */
  pageRendered(e: CustomEvent) {
    console.log('(page-rendered)', e);
  }

  /**
   * Page initialized callback.
   *
   * @param {CustomEvent} e
   */
  pageInitialized(e: CustomEvent) {
    console.log('(page-initialized)', e);
  }

  /**
   * Page change callback, which is called when a page is changed (called multiple times)
   *
   * @param e number
   */
  pageChange(e: number) {
    console.log('(page-change)', e);
  }

  searchQueryChanged(newQuery: string, item) {
    const type = newQuery !== item.pdfQuery ? '' : 'again';
    item.pdfQuery = newQuery;

    // this.pdfComponent.eventBus.dispatch('find', {
    //   type,
    //   query: this.pdfQuery,
    //   highlightAll: true,
    //   caseSensitive: false,
    //   phraseSearch: true,
    //   // findPrevious: undefined,
    // });
  }


  // setSrcs() {
    
  //   this.srcIsChanging = true;
  //   this.attachSubscriptions(
  //     forkJoin(this.data.playlist.filter(x => x.content_type == 'application/pdf').map(element => this.fileService.getPdfSrc(element, this.company_id).pipe(
  //       tap((res:any) => {
  //         if (res.download_url) {
  //           element.pdfSrc = res.download_url
  //         } else {
  //           element.pdfSrc = ''
  //         }
  //       })
  //     ))).subscribe(resp => {
  //       console.log(resp)
  //     })
  //   )

  //   this.data.playlist.forEach((element, ind) => {
  //     element.index = ind;
  //     element.imgSrc = element.thumbnail ? element.thumbnail : element.original
  //     element.videoSrc = element.preview1080 ? element.preview1080 : element.original
  //     if (element.content_type == 'application/pdf') {
  //       element.error = '';
  //       element.page = 1;
  //       element.rotation = 0;
  //       element.zoom = 1.0;
  //       element.zoomScale = 'page-fit';
  //       element.originalSize = false;
  //       element.pdf = undefined;
  //       element.renderText = true;
  //       element.progressData = {};
  //       element.isLoaded = false;
  //       element.stickToPage = true;
  //       element.showAll = true;
  //       element.autoresize = true;
  //       element.fitToPage = false;
  //       element.outline = [];
  //       element.isOutlineShown = false;
  //       element.pdfQuery = '';
  //       element.mobile = false;
  //       if (element.progressData) {
  //         element.progressData['percent'] = 0;
  //         element.progressData.loaded = 0;
  //         element.isLoaded = false;
  //       }
  //     }
  //   });
    
  //   setTimeout(() => {
  //     this.srcIsChanging = false;
  //   }, 1000)
  // }

  approveFile(file, i) {
    this.attachSubscriptions(
      this.fileService.editFile(file.id, {
        location: !!file.is_ready_if_approved ? '/ready_files' : '/approved'
      }, this.data.company.id).subscribe(res => {
        this.data.playlist.splice(i, 1)
        if (this.data.playlist.length == 0) {
          this.closeDialog()
        }
        this.detectFiles();
        console.log(res)
      })
    )
  }

  detectFiles() {
    let work = this.data.work;
    console.log("detectFiles work", work)

    if (!work || work.status_id != 3 || !(this.data.company.permissions.includes("owner") || this.checkIsManager(this.data.task, this.data.company, this.data.user))) {
      return
    }
    
    console.log("TRUE");

    this.fileService.detectFiles("/to_approve", "0", this.data.company.id, this.data.work.id).pipe(
      map(x => !!x.length),
      filter(x => !x),
      switchMap(() => forkJoin([
        this.fileService.detectFiles("/approved", "0", this.data.company.id, this.data.work.id).pipe(map(b => !!b.length)),
        this.fileService.detectFiles("/ready_files", "0", this.data.company.id, this.data.work.id).pipe(map(b => !!b.length)),
      ])),
      tap(vals => console.log("vals", vals)),
      map(vals => vals.filter(x => x == true).length > 0 ? true : false)
    ).subscribe(resp => {
      console.log("detectFiles result", resp);
      if (resp) {
        const dialogRef = this.dialog.open(ApproveJobComponent, {
          data: {
            task: this.data.task,
            work: work,
            company: this.data.company
          }
        });
        
        this.attachSubscriptions(
          dialogRef.afterClosed().subscribe(result => {
            if (result.event && result.event == "yes") {
              this.refreshService.refreshTask$.next({company_id: this.company_id, task_id: this.data.task.id, fromFileManager: true})
              this.refreshService.refreshJob$.next({company_id: this.company_id, job_id: work.id})
            }
            this.dialogRef.close({event: "update", data: false})
          })
        )
      } else {
        this.dialogRef.close({event: "update", data: false})
      }
    })
  }

  changeTime(val) {
    this.timeVal = val
  }

  changeVideoTime(player: Player, type) {
    if (type == 'replay') {
      player.currentTime = player.currentTime - this.timeVal 
    } else {
      player.currentTime = player.currentTime + this.timeVal 
    }
  }

  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData()
      .pipe(
        tap(val => {
          this.data.activeLang = val.active;
        }),
      ).subscribe(resp => {
        console.log("getLangData", this.data)
      })
    )
  }

  onSrcChange(e) {
    console.log("onSrcChange", e)
  }

  changeSrc(item, type, isImg:boolean = false) {
    this.srcIsChanging = true;

    if (isImg) {
      if (type == 'lite') {
        item.imgSrc = item.thumbnail
      } else {
        item.imgSrc = item.original
      }
    } else {
      let saveTime = this.videoplayer.currentTime;
      if (type == 'lite') {
        item.videoSrc = item.preview1080
      } else {
        item.videoSrc = item.original
      }

      setTimeout(() => {
        this.srcIsChanging = false;
        this.videoplayer.currentTime = saveTime
        // this.startVideo();
      }, 100)
    }
  }

  settings(file) {
    if (file) {
      const dialogRef = this.dialog.open(FileSettingsComponent, {
        data: {
          company_id: this.company_id,
          company: this.company,
          file: file,
          user: this.data.user,
          location: file.location,
          task: this.data.task || file.task,
          operationsValues: this.data.operationsValues
        }
      });
    }
  }

  checkIsManager(task, company, _user) { 
    return (!!task && ((!!task.managers && task.managers.filter(x => x.user_id == _user.id).length > 0) || (!!task.group && !!task.group.managers && task.group.managers.filter(x => x.user_id == _user.id).length > 0))) || (!!company && (company.permissions.includes('admin') || company.permissions.includes('manager'))); 
  }

  onImgError(event){
    event.target.src = this.imgRoute+'/assets/img/image_black_48dp.svg'
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  getCompany() {
    console.log(this.company_id);
    this.attachSubscriptions(
      this.companyService.getCompany(this.company_id).subscribe(resp => {
        console.log("getCompany", resp);
        if (resp) {
          this.companyService.company$.next(resp[0]);
          this.company = resp[0];
        }
      })
    )
  }
  
  ngAfterViewInit(): void {
    // if (this.data.playlist) {
    //   let item = this.data.playlist.find(x => this.data.file.id == x.id)
    //   this.swiper.swiperRef.slideTo(this.data.playlist.indexOf(item), 0);
    // }
    // this.startVideo()
    

    this.cd.detectChanges();
  }

  changeRate(player: Player, val) {
    player.playbackRate = val
  }

  // startVideo() {
  //   if (this.sub) {
  //     this.sub.unsubscribe()
  //   }

  //   this.sub = interval(100).pipe(
  //     filter(() => !!this.videoplayer)
  //   ).subscribe(res => {
  //     this.videoPlayers.forEach((item,i) => {
  //       // if (item['el'].classList.contains('active')) {
  //       //   if (item.canPlay) {
  //       //     item.play()
  //       //     this.sub.unsubscribe()
  //       //   }
  //       // } else {
  //       //   item.pause()
  //       // }
  //       if (!item['el'].classList.contains('active')) {
  //         this.videoplayer.pause()
  //       }
  //       if (i == this.videoPlayers.length - 1) {
  //         this.sub.unsubscribe()
  //       }
  //     })
  //   })
  // }

  checkTime() {
    // if (this.videoplayer) {
    //   console.log(this.videoplayer.currentTime)
    // }
  }

  copyLink(type) {
    this.layoutService.showSnackBar({name: marker(type + " link")}, marker("Copied"), SnackBarItem)
  }


  outletClick(outlet, file) {
    console.log("outletClick", outlet, file);


    // if (outlet.channel.platform_id != 4) {
    //   this.attachSubscriptions(
    //     this.connectedAppsService.addChannelContent({
    //       company_id: this.company_id,
    //       task_id: _task.id,
    //       channel_id: outlet.channel.id,
    //       content_file_id: file.id,
    //     }).subscribe(res => {
    //       this.layoutService.showSnackBar({name: ''}, marker("Publication successful"), SnackBarItem)
    //       console.log(res)
    //     }, error => {
    //       this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
    //     })
    //   )
    //   return
    // }

    if (outlet.content_status_id == 1 && !outlet.content_url) {
      let _task = this.data.task || file.task;
      let _work = this.data.work
      console.log("_task", _task)
      console.log("_work", _work)
  
      const dialogRef = this.dialog.open(MobPublishToOutletComponent, {
        backdropClass: ['mob_interface_backdrop'],
        panelClass: ['mob_interface_panel', 'mob_interface_panel_fil', 'animate__animated', 'animate__slideInUp', 'select-outlet-in-mob'],
        disableClose: true,
        autoFocus: false,
        data: {
          host: this.host,
          imgRoute: this.imgRoute,
          activeLang: this.data.activeLang,
          user: this.data.user,
          company: this.company,
          company_id: this.company_id,
          target_company_id: this.data.target_company_id,
          profile: outlet,
          file: file,
          task: _task,
          work: _work,
        }
      });
  
      this.attachSubscriptions(
        dialogRef.afterClosed().subscribe(result => {
          console.log(result)
        })
      )
    } else {
      console.log('create in new outlet same as selected')
      let _task = this.data.task || file.task;
      let _work = this.data.work
      console.log("_task", _task)
      console.log("_work", _work)
      this.attachSubscriptions(
        this.taskService.addProfile({
          task_id: _task.id,
          channel_id: outlet.channel.id,
          content_type_id: outlet.content_type_id,
          content_status_id: 1
        }, this.company_id).pipe(
          switchMap(res => {
            return this.taskService.getTaskForManager(this.company ? this.company.id : this.company_id, file.task_id).pipe(
              map(el => {
                if (el[0]) {
                  return casesModel(el, [], 'update').arr[0]
                } else {
                  return false
                }
              }),
              switchMap(resp => {
                let expPermFilter:any = {task_id: _task.id}
                if (this.company_id != _task.company_id) {
                  expPermFilter.partner_company_id = _task.company_id
                }
                return this.taskService.getExpandPermUrls('1', this.company_id, expPermFilter, '100').pipe(
                  tap(rslt => {
                    resp.uniqueChannels = [];
                    rslt.sort((a, b) => {
                      if (a.content_status_id === 13 && b.content_status_id !== 13) {
                        return -1;
                      } else if (a.content_status_id === 1 && b.content_status_id !== 13 && b.content_status_id !== 1) {
                        return -1;
                      } else if (b.content_status_id === 13 || b.content_status_id === 1) {
                        return 1;
                      } else {
                        return 0;
                      }
                    });
                    rslt.filter(p => !!p.permission_prepare_upload).forEach(channel => {
                      if (channel.channel && [1,2,3,4].includes(channel.channel.platform_id) && !!channel.channel.channel_account_id) {
                        if (resp.uniqueChannels.filter(ch => ch.channel_id == channel.channel_id).length == 0) {
                          resp.uniqueChannels.push(channel)
                        } else {
                          if (!([1, 13].includes(resp.uniqueChannels.find(ch => ch.channel_id == channel.channel_id).content_status_id) && !resp.uniqueChannels.find(ch => ch.channel_id == channel.channel_id).content_url)) {
                            resp.uniqueChannels.splice(resp.uniqueChannels.indexOf(resp.uniqueChannels.find(ch => ch.channel_id == channel.channel_id)), 1, channel)
                          }
                        }
                      }
                    });
                  }),
                  map(() => resp)
                )
              }),
              tap(resp => {
                this.data.task = resp;
                this.data.target_company_id = this.data.task.company_id;
                if (this.data.work) {
                  this.data.work = this.data.task.operations.filter(z => z.id == this.data.work.id).length > 0 ? this.data.task.operations.find(z => z.id == this.data.work.id) : undefined
                }
              }),
              map((resp) => resp.channels.find(x => x.id == res.id))
            )
          })
        ).subscribe(resOutlet => {
  
          let _task = this.data.task || file.task;
          let _work = this.data.work
          
          const dialogRef = this.dialog.open(MobPublishToOutletComponent, {
            backdropClass: ['mob_interface_backdrop'],
            panelClass: ['mob_interface_panel', 'mob_interface_panel_fil', 'animate__animated', 'animate__slideInUp', 'select-outlet-in-mob'],
            disableClose: true,
            autoFocus: false,
            data: {
              host: this.host,
              imgRoute: this.imgRoute,
              activeLang: this.data.activeLang,
              user: this.data.user,
              company: this.company,
              company_id: this.company_id,
              target_company_id: this.data.target_company_id,
              profile: resOutlet,
              file: file,
              task: _task,
              work: _work,
            }
          });
      
          this.attachSubscriptions(
            dialogRef.afterClosed().subscribe(result => {
              console.log(result)
            })
          )
        })
      )
    }

    
  }

  publishTo(item) {
    const dialogRef = this.dialog.open(SelectOutletComponent, {
      backdropClass: ['mob_interface_backdrop'],
      panelClass: ['mob_interface_panel', 'mob_interface_panel_fil', 'animate__animated', 'animate__slideInUp', 'select-outlet-in-mob'],
      disableClose: true,
      autoFocus: false,
      data: {
        user: this.data.user,
        company: this.company,
        activeLang: this.data.activeLang,
        uniqueChannels: this.data.task.uniqueChannels
      }
    });

    this.attachSubscriptions(

      dialogRef.afterClosed().subscribe(result => {
        
        if (!!result) {
          if (result.event == 'select') {
            let outlet = result.data;
            console.log("outlet", outlet);

            this.outletClick(outlet, item)
          }
        }
      })
    )
  }

  onDown($event, control) {
    console.log($event, control)
  }

  copy(file) {
    let copyData = [];

      copyData.push({
        company_id: file.company_id,
        id: file.id,
        taskChannelFiles: file.taskChannelFiles
      })

    this.sm.localStorageSetItem('copiedFiles', copyData);
    this.layoutService.showSnackBar({name: file.filename}, marker("Copied"), SnackBarItem)
  }

  download(file, type) {
    if (type == 'full') {
      if (this.data.is_public) {
        window.open(this.host + file.original);
      } else {
        window.open(this.host + file.original + '?company_id=' + this.data.company.id + `&filename=${file.filename}`, '_blank');
      }
    } else if (type == 'thum') {
      if (this.data.is_public) {
        window.open(this.host + file.thumbnail);
      } else {
        window.open(this.host + file.thumbnail + '?company_id=' + this.data.company.id + `&filename=${file.filename}`, '_blank');
      }
    } else if (type == 'conv') {
      if (this.data.is_public) {
        window.open(this.host + file.preview1080);
      } else {
        window.open(this.host + file.preview1080 + '?company_id=' + this.data.company.id + `&filename=${file.filename}`, '_blank');
      }
    } 
  }
  
  onPlaybackReady() {
    console.log("READY")
  }
  
  // toggleVideo() {
  //   // this.videoplayer.play();
  // }

  toShortCut(file) {
    this.attachSubscriptions(
      this.taskBarService.addBarItem({company_id: this.company.id, task_id: !!file.task_id ? file.task_id : 0, task_operation_id: !!file.task_operation_id ? file.task_operation_id : 0, discussion_id: !!file.discussion_id ? file.discussion_id : 0, file_id: file.id}).subscribe(res => {
        console.log("toShortCut", res)
        this.minimizeService.minimizes$.next(res)
      }, error => {
        console.log("error toShortCut", error)
      })
    )
  }

  closeDialog() {
    this.dialogRef.close(false)
  }

  getPlaylist() {
    this.attachSubscriptions(
      this.fileService.getFileData(1, this.company_id, {task_id: this.data.task.id, task_operation_id: this.data.work.id, location: this.data.work.status_id == 3 ? '/to_approve' : '/ready_files'}).pipe(
        map(x => x.body)
      ).subscribe(resp => {
        this.data.playlist = undefined;

        setTimeout(() => {
          this.data.playlist = resp.filter(x => (x.is_uploaded || !!x.is_document) && (!!x.is_document || (x.content_type.indexOf("image") != -1 || x.content_type.indexOf("video") != -1 || x.content_type.indexOf("audio") != -1 || x.content_type == 'application/pdf') || !!x.thumbnail));
          if (this.data.playlist) {
            let item = this.data.playlist.find(x => this.data.file.id == x.id);
            this.data.currIndex = this.data.playlist.indexOf(item)
            // this.swiper.swiperRef.slideTo(this.data.playlist.indexOf(item), 0);
            this.setSrcForItem(item)
          }
          // this.setSrcs();
        }, 0)

        console.log("getPlaylist", this.data.playlist)
      })
    )
  }

  understandFileType(val) {
    if (!val) {
      return ""
    }
    
    if (val == 'application/pdf') {
      return 'pdf'
    } else if (val.indexOf("/") != -1) {
      return val.split('/')[0]
    } else {
      return ""
    }
  }

  ngOnDestroy(): void {
    this.layoutService.overflowAuto()
    if (this.sub) {
      this.sub.unsubscribe()
    }
    this.clearSubscriptions()
  }

}
