import { Component, Inject, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { FileService } from 'src/app/shared/services/rest/file.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { FormControl } from '@angular/forms';
import { ReplaySubject, Subscription, concat, empty, forkJoin, fromEvent } from 'rxjs';
import { debounceTime, filter, last, map, switchMap, take, tap } from 'rxjs/operators';
import { ParametersService } from 'src/app/shared/services/rest/parameters.service';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { AiService } from 'src/app/shared/services/rest/ai.service';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { LoadingService } from 'src/app/shared/services/rest/loading.service';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';

@Component({
  selector: 'app-posts-names',
  templateUrl: './posts-names.component.html',
  styleUrls: ['./posts-names.component.scss']
})
export class PostsNamesComponent extends BaseClass implements OnInit, OnDestroy {
  public parts = (this.data.tracks.filter(x => x.type == 'video').map(k => k.parts) as any).flat(Infinity);
  public aiResults: any;
  public aiCount: number = 0;
  public aiMistake: any;
  public ai_model: any = 'gpt-4o-mini';
  public models = [
    {
      name: 'gpt-3.5-turbo',
      only_root: 0,
    },
    {
      name: 'gpt-3.5-turbo-0125',
      only_root: 0,
    },
    {
      name: 'gpt-3.5-turbo-1106',
      only_root: 0,
    },
    {
      name: 'gpt-4o',
      only_root: 0,
    },
    {
      name: 'gpt-4o-mini',
      only_root: 0,
    },
    {
      name: 'gpt-4o-2024-08-06',
      only_root: 0,
    },
    {
      name: 'chatgpt-4o-latest',
      only_root: 0,
    },
    {
      name: 'gpt-4',
      only_root: 1,
    },
    {
      name: 'gpt-4-0613',
      only_root: 1,
    },
    {
      name: 'gpt-4-1106-vision-preview',
      only_root: 1,
    },
    {
      name: 'gpt-4-turbo',
      only_root: 1,
    },
    {
      name: 'gpt-4-turbo-2024-04-09',
      only_root: 1,
    },
    {
      name: 'gpt-4-turbo-preview',
      only_root: 1,
    },
    {
      name: 'gpt-4-0125-preview',
      only_root: 1,
    },
    {
      name: 'gpt-4-1106-preview',
      only_root: 1,
    },
    {
      name: 'gpt-4-vision-preview',
      only_root: 1,
    },
  ]
  
  public aiModelControl: FormControl = new FormControl();
  public models$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public forMiss: FormControl = new FormControl(false);
  @ViewChild('contextMenu') contextMenu: TemplateRef<any>;
  overlayRef: OverlayRef | null;
  public backContextSub: Subscription;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private taskService: TaskService,
    public dialogRef: MatDialogRef<PostsNamesComponent>,
    private aiService: AiService,
    private fileService: FileService,
    private ls: LoadingService,
    public overlay: Overlay,
    private parametersService: ParametersService,
    private dialog: MatDialog,
    public viewContainerRef: ViewContainerRef,
    private layoutService: LayoutService
  ) { super() }

  ngOnInit(): void {
    if (!!this.data.vidProject.batch_data) {
      if (!this.data.vidProject.batch_data.selectedCols) {
        this.data.vidProject.batch_data.selectedCols = [...this.data.vidProject.batch_data.headCols]
      }
    }

    this.models$.next(this.models.slice());

    this.attachSubscriptions(
      this.aiModelControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchAiModels(resp))
    )
    console.log("PostsNamesComponent", this.data)
  }

  onSearchAiModels(resp) {
    if (!this.models) {
      return;
    }

    if (!resp) {
      this.models$.next(this.models.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }

    // filter the banks
    this.models$.next(
      this.models.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }
  
  log() {
    console.log("data", this.data)
  }

  getValuesById(id) {
    return this.data.allValues && this.data.allValues.find(x => x.id == id)
  }

  resetRes() {
    this.aiResults = [];
    this.aiMistake = undefined;
  }

  getMissingNames() {
    let tslData = []

    this.parts.forEach((part, partInd) => {
      Object.keys(part.postNames).forEach(tID => {
        if (!!tID && this.getValuesById(tID)) {
          if (!part.postNames[tID].name) {
            let x:any = {
              partInd: partInd,
              tID: tID,
              data: {
                company_id: this.data.company.id,
                partner_company_id: this.data.company.id != this.data.target_company_id ? this.data.target_company_id : 0,
                messages: [{
                  role: 'user',
                  content: `Translate '${part.postNames['0'].name}' into ${this.getValuesById(tID).value}. Return only result.`
                }],
                model: this.ai_model
              }
            }
            tslData.push(x)
          }
        }
      })
    })

    return tslData
  }

  titleAI(e, part, tID) {
    this.resetRes()
    this.aiCount = 0
    this.getPartPostResults(e, tID, part)
  }

  closeContext() {
    console.log("closeContext")
    this.backContextSub && this.backContextSub.unsubscribe();
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
  }

  selectTitle(item, contentData) {
    console.log("item, contentData", item, contentData)
    contentData.contextData.postNames[contentData.tID].name = item;
    this.closeContext();
  }

  openContext({ x, y }: MouseEvent, contextData, tID) {
    this.closeContext();
    console.log("{ x, y }: MouseEvent, contextData, group_id", { x, y }, contextData, tID)
    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo({ x, y })
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        }
      ]);

    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });

    contextData.is_cut = true

    let conData:any = {
      contextData: contextData,
      tID: tID
    }

    conData.is_ai = true

    this.overlayRef.attach(new TemplatePortal(this.contextMenu, this.viewContainerRef, {
      $implicit: conData
    }));

    console.log("this.contextMenu", this.contextMenu)
    console.log("conData.outlets", conData.outlets)
    console.log("conData", conData)

    this.backContextSub = fromEvent<MouseEvent>(document, 'click')
      .pipe(
        filter(event => {
          const clickTarget = event.target as HTMLElement;
          return !!this.overlayRef && !this.overlayRef.overlayElement.contains(clickTarget) && !clickTarget.closest('.list_of_keywords'); 
        }),
        take(1)
      ).subscribe(() => this.closeContext())

  }

  getPartPostResults(e, tID, part) {
    this.aiCount++
    let x:any = {
      company_id: this.data.company.id,
      partner_company_id: this.data.company.id != this.data.target_company_id ? this.data.target_company_id : 0,
      messages: [],
      model: this.ai_model
    }
    

    x.messages.push({
      role: 'user',
      content: `10 translation variants into ${this.getValuesById(tID).value} for '${part.postNames['0'].name}'. Write options in html ul.`
    })
    // x.messages.push({
    //   role: 'user',
    //   content: "Important: Answer without extra text, only the answer to my specific request. If I ask you to provide 1 option, give it in the form (for example js array: ['option']). If I ask for more options, give them out without iteration and in the form of a single array where each element of the array is an answer variant (for example js array: ['option1', 'option2', ...])"
    // })

    part.postNames[tID].is_getting = true;
    this.attachSubscriptions(
      this.aiService.sendAIMsg(x).pipe(
        map(u => {
          if (u.indexOf("<ul>") != -1) {
            const parser = new DOMParser();
            const doc = parser.parseFromString(u, 'text/html');
            const ulElement = doc.querySelector('ul');

            if (ulElement) {
              let liElements = ulElement.querySelectorAll('li');
              let liArray = Array.from(liElements).map(x => (String(x.textContent) as any).replaceAll("\n", "").trim())
              return {parsed: !!liArray && liArray.length ? liArray : false, before: u};
            } else {
              return {parsed: false, before: u}
            }
          } else {
            return {parsed: false, before: u}
          }
        })
      ).subscribe(resp => {
        if (resp) {
          this.aiCount = 0;
          part.postNames[tID].is_getting = false;
          this.aiResults = resp.parsed
          this.openContext(e, part, tID)
        } else {
          if (this.aiCount <= 3) {
            this.getPartPostResults(e, tID, part)
          } else {
            this.aiMistake = resp.before
            this.aiCount = 0;
            part.postNames[tID].is_getting = false;
            this.layoutService.showSnackBar({name: ''}, marker("Incorrect data received, please try again"), SnackBarItem)
          }
        }
      }, error => {
        if (this.aiCount <= 3) {
          this.getPartPostResults(e, tID, part)
        } else {
          this.aiMistake = error
          this.aiCount = 0;
          part.postNames[tID].is_getting = false;
          this.layoutService.showSnackBar({name: ''}, marker("Incorrect data received, please try again"), SnackBarItem)
        }
      })
    )
  }
  
  getTranslations() {
    if (this.data.isGettingTranslations) {
      return
    }
    console.log("getTranslations", this.data)
    

    let tslData = []

    if (this.forMiss.value) {
      tslData = this.getMissingNames()
    } else {
      this.parts.forEach((part, partInd) => {
        Object.keys(part.postNames).forEach(tID => {
          if (!!tID && this.getValuesById(tID)) {
            let x:any = {
              partInd: partInd,
              tID: tID,
              data: {
                company_id: this.data.company.id,
                partner_company_id: this.data.company.id != this.data.target_company_id ? this.data.target_company_id : 0,
                messages: [{
                  role: 'user',
                  content: `Translate '${part.postNames['0'].name}' into ${this.getValuesById(tID).value}. Return only result.`
                }],
                model: this.ai_model
              }
            }
            tslData.push(x)
          }
        })
      })
  
      this.data.isGettingTranslations = true;
    }
    
    
    if (tslData.length) {
      let count = 1;
      this.attachSubscriptions(
        concat(...tslData.map(x => this.aiService.sendAIMsg(x.data).pipe(
          tap(val => {
            let rsl = val.replaceAll('"','').replaceAll("'", "");
            if (rsl.indexOf('-->') != -1) {
              rsl = rsl.split('-->')[1]
            }
            if (rsl.indexOf('->') != -1) {
              rsl = rsl.split('->')[1]
            }
            if (rsl.indexOf('>') != -1 || rsl.indexOf('>') != -1) {
              rsl = rsl.replaceAll(">", "").replaceAll("<", "")
            }
            this.parts[x.partInd].postNames[x.tID].name = rsl;
          })
        ))).pipe(
          tap(el => {
            this.ls.requests$.next({
              value: Math.round((100 / tslData.length) * count),
              target: "Getting translations" 
            })
            count++;
          })
        ).subscribe(resp => {
          console.log('translate res', resp)
          console.log('translate result', this.parts)
          this.data.isGettingTranslations = false;
        }, error => {
          this.data.isGettingTranslations = false;
          this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
        })
      )
    }

  }

  close() {
    this.dialogRef.close({event: "close", data: false});
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
