import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { MatButton } from '@angular/material/button';
@Component({
  selector: 'app-tasks-have-same-url',
  templateUrl: './tasks-have-same-url.component.html',
  styleUrls: ['./tasks-have-same-url.component.scss']
})
export class TasksHaveSameUrlComponent implements OnInit, AfterViewInit {
  @ViewChild('btnFocus') btnFocus: MatButton

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private bottomSheetRef: MatBottomSheetRef<TasksHaveSameUrlComponent>
  ) { }

  ngOnInit(): void {
  }
  
  ngAfterViewInit(): void {
    setTimeout(() => {this.btnFocus.focus()},0)
  }

  close(): void {
    this.bottomSheetRef.dismiss({
      message: 'close',
    });
  }

  continue() {
    this.bottomSheetRef.dismiss({
      message: 'continue',
    });
  }

}
