import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { LanguageService } from '../../services/common/language.service';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { NgxMatDateAdapter } from '@angular-material-components/datetime-picker';
import { MY_NEW_FORMATS } from 'src/app/components/workspace-pages/cases/dialogs/open-task/dialogs/add-task-profile/add-task-profile.component';
import { CompanyService } from '../../services/rest/company.service';
import { ReplaySubject, Subject } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { StorageManagerService } from '../../services/common/storage-manager.service';
@Component({
  selector: 'app-user-schedule',
  templateUrl: './user-schedule.component.html',
  styleUrls: ['./user-schedule.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_NEW_FORMATS},
  ]
})
export class UserScheduleComponent extends BaseClass implements OnInit, OnDestroy {
  public activeLang: any;
  public schedules: any;
  public employee: any;
  public form: FormGroup;
  public days: any = [
    {
      day: 1,
      name: "Monday",
      data: [],
      working: {
        from: 0,
        to: 0
      },
      breaks: [{
        from:0,
        to:0
      }],
      is_off: false,
      for_all: false,
      is_load: false,
      is_open: false
    },
    {
      day: 2,
      name: "Tuesday",
      data: [],
      working: {
        from: 0,
        to: 0
      },
      breaks: [{
        from:0,
        to:0
      }],
      is_off: false,
      for_all: false,
      is_load: false,
      is_open: false
    },
    {
      day: 3,
      name: "Wednesday",
      data: [],
      working: {
        from: 0,
        to: 0
      },
      breaks: [{
        from:0,
        to:0
      }],
      is_off: false,
      for_all: false,
      is_load: false,
      is_open: false
    },
    {
      day: 4,
      name: "Thursday",
      data: [],
      working: {
        from: 0,
        to: 0
      },
      breaks: [{
        from:0,
        to:0
      }],
      is_off: false,
      for_all: false,
      is_load: false,
      is_open: false
    },
    {
      day: 5,
      name: "Friday",
      data: [],
      working: {
        from: 0,
        to: 0
      },
      breaks: [{
        from:0,
        to:0
      }],
      is_off: false,
      for_all: false,
      is_load: false,
      is_open: false
    },
    {
      day: 6,
      name: "Saturday",
      data: [],
      working: {
        from: 0,
        to: 0
      },
      breaks: [{
        from:0,
        to:0
      }],
      is_off: false,
      for_all: false,
      is_load: false,
      is_open: false
    },
    {
      day: 7,
      name: "Sunday",
      data: [],
      working: {
        from: 0,
        to: 0
      },
      breaks: [{
        from:0,
        to:0
      }],
      is_off: false,
      for_all: false,
      is_load: false,
      is_open: false
    },
  ];

  public timezones: any;
  public timezoneFilterCtrl: FormControl = new FormControl();
  protected _onDestroy = new Subject<void>();
    /** list of bank groups filtered by search keyword for option groups */
  public filteredTimezonesGroups: ReplaySubject<any> = new ReplaySubject<any>(1);
  constructor(
    private taskService: TaskService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<UserScheduleComponent>,
    private fb: FormBuilder,
    private languageService: LanguageService,
    private layoutService: LayoutService,
    private companyService: CompanyService,
    private sm: StorageManagerService,
    private _ngx_adapter: NgxMatDateAdapter<any>,
    private _adapter: DateAdapter<any>,
  ) { super() }

  ngOnInit(): void {
    console.log('UserScheduleComponent', this.data)
    console.log('UserScheduleComponent', this.timestampToTime(64800))

    if (this.data.employee) {
      this.employee = this.data.employee;
    } else {
      this.employee = this.data.company.employees[0];
    }

    this.form = this.fb.group({
      timezone: this.employee.timezone,
      timezone_offset: this.employee.timezone_offset,
    })
    
    this.getLangData();
    this.getSchedule({employee_id: this.employee.id});

    this.getTimeZones();
    this.form.get("timezone").valueChanges.subscribe(x => {
      this.form.patchValue({
        timezone_offset: x == "no" ? '' : this.getOffsetData(x)
      })
    })

    this.attachSubscriptions(
      this.timezoneFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterTimezoneGroups();
      })
    )
  }

  toggleDay(item) {
    item.is_open = !item.is_open;

    this.days.filter(x => x.day != item.day).forEach(element => {
      element.is_open = false;
    });
  }

  save(item) {
    console.log("save", item);
    console.log("save from", this.timeToTimestamp(item.working.from));
    console.log("save to", this.timeToTimestamp(item.working.to));
    
    if (item.working.from == 0 && item.working.to == 0) {
      return;
    }

    if (!!item.is_load) {
      return;
    }

    item.is_load = true;

    if (!!item.for_all) {
      let multiDeleteData = [];
      let multiAddData = [];

      this.days.forEach(d => {
        if (!d.is_off) {
          d.data.forEach(x => {
            multiDeleteData.push({
              "path": `/api/employee-schedule/${x.id}/`,
              "query": {company_id: this.data.company.id},
              "method": "DELETE",
              "body": {}
            })
          });
        }
      })

      if (item.breaks.filter(x => x.from != 0 && x.to != 0 && this.timeToTimestamp(x.from) > this.timeToTimestamp(item.working.from) && this.timeToTimestamp(x.to) < this.timeToTimestamp(item.working.to)).length > 0) {
        let work = {
          from: this.timeToTimestamp(item.working.from),
          to: this.timeToTimestamp(item.working.to)
        };
        let pairs = [];
        let cstPairs = [...item.breaks.filter(x => x.from != 0 && x.to != 0 && this.timeToTimestamp(x.from) > this.timeToTimestamp(item.working.from) && this.timeToTimestamp(x.to) < this.timeToTimestamp(item.working.to))]
        cstPairs.forEach(x => pairs.push(JSON.parse(JSON.stringify(x))));
        pairs.forEach(x => {
          x.from = this.timeToTimestamp(x.from);
          x.to = this.timeToTimestamp(x.to);
        });

        pairs.sort((a,b) => {
          return a.from - b.from;
        })

        console.log("work", work)

        let workingIntevals = [];
        pairs.forEach((p,ind) => {
          console.log(ind, p)
          if (ind != 0) {
            if (p.from > pairs[ind-1].to) {
              return
            } else {
              pairs[ind-1].to = p.to;
              pairs.splice(ind, 1)
            }
          }            
        });
        
        console.log("pairs", pairs)

        pairs.forEach((p,ind) => {
          if (ind == 0) {
            workingIntevals.push({
              from: work.from,
              to: p.from
            })
          } else {
            workingIntevals.push({
              from: pairs[ind-1].to,
              to: p.from
            })
          }

          if (ind == pairs.length - 1) {
            workingIntevals.push({
              from: p.to,
              to: work.to
            })
          }
        })

        this.days.forEach(d => {
          if (!d.is_off) {
            workingIntevals.forEach(wi => {
              multiAddData.push({
                "path": `/api/employee-schedule/`,
                "query": {company_id: this.data.company.id},
                "method": "POST",
                "body": {
                  [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
                  company_id: this.data.company.id,
                  employee_id: this.employee.id,
                  day: d.day,
                  from_time: wi.from,
                  to_time: wi.to
                }
              })
            })
          }
        })

      } else {
        this.days.forEach(d => {
          if (!d.is_off) {
            multiAddData.push({
              "path": `/api/employee-schedule/`,
              "query": {company_id: this.data.company.id},
              "method": "POST",
              "body": {
                [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
                company_id: this.data.company.id,
                employee_id: this.employee.id,
                day: d.day,
                from_time: this.timeToTimestamp(item.working.from),
                to_time: this.timeToTimestamp(item.working.to)
              }
            })
          }
        })
      }

      this.attachSubscriptions(
        this.taskService.multiRequest(multiDeleteData).pipe(
          switchMap(res => {
            return this.taskService.multiRequest(multiAddData)
          })
        ).subscribe(resp => {
          console.log(resp)
          this.getSchedule({employee_id: this.employee.id});
        })
      )

    } else {
      if (!item.data || !item.data.length) {

        let multiAddData = [];
        if (item.breaks.filter(x => x.from != 0 && x.to != 0 && this.timeToTimestamp(x.from) > this.timeToTimestamp(item.working.from) && this.timeToTimestamp(x.to) < this.timeToTimestamp(item.working.to)).length > 0) {
          let work = {
            from: this.timeToTimestamp(item.working.from),
            to: this.timeToTimestamp(item.working.to)
          };
          let pairs = [];
          let cstPairs = [...item.breaks.filter(x => x.from != 0 && x.to != 0 && this.timeToTimestamp(x.from) > this.timeToTimestamp(item.working.from) && this.timeToTimestamp(x.to) < this.timeToTimestamp(item.working.to))]
          cstPairs.forEach(x => pairs.push(JSON.parse(JSON.stringify(x))));
          pairs.forEach(x => {
            x.from = this.timeToTimestamp(x.from);
            x.to = this.timeToTimestamp(x.to);
          });

          pairs.sort((a,b) => {
            return a.from - b.from;
          })

          console.log("work", work)

          let workingIntevals = [];
          pairs.forEach((p,ind) => {
            console.log(ind, p)
            if (ind != 0) {
              if (p.from > pairs[ind-1].to) {
                return
              } else {
                pairs[ind-1].to = p.to;
                pairs.splice(ind, 1)
              }
            }            
          });
          
          console.log("pairs", pairs)

          pairs.forEach((p,ind) => {
            if (ind == 0) {
              workingIntevals.push({
                from: work.from,
                to: p.from
              })
            } else {
              workingIntevals.push({
                from: pairs[ind-1].to,
                to: p.from
              })
            }

            if (ind == pairs.length - 1) {
              workingIntevals.push({
                from: p.to,
                to: work.to
              })
            }
          })

          workingIntevals.forEach(wi => {
            multiAddData.push({
              "path": `/api/employee-schedule/`,
              "query": {company_id: this.data.company.id},
              "method": "POST",
              "body": {
                [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
                company_id: this.data.company.id,
                employee_id: this.employee.id,
                day: item.day,
                from_time: wi.from,
                to_time: wi.to
              }
            })
          })

        } else {
          multiAddData.push({
            "path": `/api/employee-schedule/`,
            "query": {company_id: this.data.company.id},
            "method": "POST",
            "body": {
              [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
              company_id: this.data.company.id,
              employee_id: this.employee.id,
              day: item.day,
              from_time: this.timeToTimestamp(item.working.from),
              to_time: this.timeToTimestamp(item.working.to)
            }
          })
        }

        this.attachSubscriptions(
          this.taskService.multiRequest(multiAddData).subscribe(resp => {
            console.log(resp)
            this.getSchedule({employee_id: this.employee.id});
          })
        )
      } else {
        let multiDeleteData = [];
        let multiAddData = [];
  
        item.data.forEach(item => {
          multiDeleteData.push({
            "path": `/api/employee-schedule/${item.id}/`,
            "query": {company_id: this.data.company.id},
            "method": "DELETE",
            "body": {}
          })
        });

        if (item.breaks.filter(x => x.from != 0 && x.to != 0 && this.timeToTimestamp(x.from) > this.timeToTimestamp(item.working.from) && this.timeToTimestamp(x.to) < this.timeToTimestamp(item.working.to)).length > 0) {
          let work = {
            from: this.timeToTimestamp(item.working.from),
            to: this.timeToTimestamp(item.working.to)
          };
          let pairs = [];
          let cstPairs = [...item.breaks.filter(x => x.from != 0 && x.to != 0 && this.timeToTimestamp(x.from) > this.timeToTimestamp(item.working.from) && this.timeToTimestamp(x.to) < this.timeToTimestamp(item.working.to))]
          cstPairs.forEach(x => pairs.push(JSON.parse(JSON.stringify(x))));
          pairs.forEach(x => {
            x.from = this.timeToTimestamp(x.from);
            x.to = this.timeToTimestamp(x.to);
          });

          pairs.sort((a,b) => {
            return a.from - b.from;
          })

          console.log("work", work)

          let workingIntevals = [];
          pairs.forEach((p,ind) => {
            console.log(ind, p)
            if (ind != 0) {
              if (p.from > pairs[ind-1].to) {
                return
              } else {
                pairs[ind-1].to = p.to;
                pairs.splice(ind, 1)
              }
            }            
          });
          
          console.log("pairs", pairs)

          pairs.forEach((p,ind) => {
            if (ind == 0) {
              workingIntevals.push({
                from: work.from,
                to: p.from
              })
            } else {
              workingIntevals.push({
                from: pairs[ind-1].to,
                to: p.from
              })
            }

            if (ind == pairs.length - 1) {
              workingIntevals.push({
                from: p.to,
                to: work.to
              })
            }
          })

          workingIntevals.forEach(wi => {
            multiAddData.push({
              "path": `/api/employee-schedule/`,
              "query": {company_id: this.data.company.id},
              "method": "POST",
              "body": {
                [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
                company_id: this.data.company.id,
                employee_id: this.employee.id,
                day: item.day,
                from_time: wi.from,
                to_time: wi.to
              }
            })
          })

        } else {
          multiAddData.push({
            "path": `/api/employee-schedule/`,
            "query": {company_id: this.data.company.id},
            "method": "POST",
            "body": {
              [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
              company_id: this.data.company.id,
              employee_id: this.employee.id,
              day: item.day,
              from_time: this.timeToTimestamp(item.working.from),
              to_time: this.timeToTimestamp(item.working.to)
            }
          })
        }

  
        this.attachSubscriptions(
          this.taskService.multiRequest(multiDeleteData).pipe(
            switchMap(res => {
              return this.taskService.multiRequest(multiAddData)
            })
          ).subscribe(resp => {
            console.log(resp)
            this.getSchedule({employee_id: this.employee.id});
          })
        )
      }
    }
  }

  dayOff(e, item) {
    console.log(e);
    console.log(item);
    if (item.is_load == true) {
      return;
    }

    item.is_load = true;
    if (e.checked) {
      if (!item.data || !item.data.length) {
        this.attachSubscriptions(
          this.companyService.addSchedule({
            company_id: this.data.company.id,
            employee_id: this.employee.id,
            day: item.day,
            from_time: 0,
            to_time: 0
          }).subscribe(resp => {
            console.log("addSchedule", resp);
            this.getSchedule({employee_id: this.employee.id});
          })
        )
      } else {
        let multiDeleteData = [];
  
        item.data.forEach(item => {
          multiDeleteData.push({
            "path": `/api/employee-schedule/${item.id}/`,
            "query": {company_id: this.data.company.id},
            "method": "DELETE",
            "body": {}
          })
        });
  
        this.attachSubscriptions(
          this.taskService.multiRequest(multiDeleteData).pipe(
            switchMap(res => {
              return this.companyService.addSchedule({
                company_id: this.data.company.id,
                employee_id: this.employee.id,
                day: item.day,
                from_time: 0,
                to_time: 0
              })
            })
          ).subscribe(resp => {
            console.log(resp)
            this.getSchedule({employee_id: this.employee.id});
          })
        )
      }
    } else {
      if (!!item.data && !!item.data.length) {
        let multiDeleteData = [];
  
        item.data.forEach(item => {
          multiDeleteData.push({
            "path": `/api/employee-schedule/${item.id}/`,
            "query": {company_id: this.data.company.id},
            "method": "DELETE",
            "body": {}
          })
        });
  
        this.attachSubscriptions(
          this.taskService.multiRequest(multiDeleteData).subscribe(resp => {
            console.log(resp)
            this.getSchedule({employee_id: this.employee.id});
          })
        )
      }
    }

  }

  addBreak(item) {
    console.log("addBreak", item)
    item.breaks.push({from: 0, to: 0})
  }

  log() {
    console.log(this.days);
    console.log("timeToTimestamp", this.timeToTimestamp(this.days[0].working.from));
    console.log("timestampToTime", this.timestampToTime(this.timeToTimestamp(this.days[0].working.from)));
    console.log("timestampToTime", this.timestampToTime(this.days[0].data[0].from_time));
    console.log("timestampToTime", this.timestampToTime(this.days[0].data[0].to_time));
  }

  // timeToTimestamp(value) {
  //   let testDate = value.split(' ')[0];
  //   let testDatePart = value.split(' ')[1];
  //   let newTestDate = 0;
  //   if (testDatePart == "PM") {
  //     newTestDate += 43200;
  //   }
    
  //   newTestDate += Number(testDate.split(":")[0])*3600;
  //   newTestDate += Number(testDate.split(":")[1])*60;
  //   return newTestDate
  // }
  timeToTimestamp(value) {
    // Разбиваем строку на часы, минуты и AM/PM
    const parts = value.split(' ');
    const time = parts[0];
    const period = parts[1];
  
    // Разбиваем время на часы и минуты
    const timeParts = time.split(':');
    let hours = parseInt(timeParts[0]);
    let minutes = parseInt(timeParts[1]);
  
    // Если период - PM и часы не равны 12, добавляем 12 часов
    if (period === 'PM' && hours !== 12) {
      hours += 12;
    }
  
    // Если период - AM и часы равны 12, устанавливаем их в 0
    if (period === 'AM' && hours === 12) {
      hours = 0;
    }
  
    // Вычисляем общее количество секунд
    const totalSeconds = (hours * 60 + minutes) * 60;
  
    return totalSeconds;
  }

  // timestampToTime(value) {
  //   let newValue = "";
  //   let period = value >= 43200 ? "PM" : "AM"; // Изменено "> 43200" на ">= 43200"
  
  //   if (value >= 3600) {
  //     if (Math.floor(value / 3600) < 10) {
  //       newValue = "0" + Math.floor(value / 3600) + ":";
  //     } else {
  //       newValue = Math.floor(value / 3600) + ":";
  //     }
  //     value %= 3600;
  //   } else {
  //     newValue = '00:';
  //   }
  
  //   if (value >= 60) {
  //     if (Math.floor(value / 60) < 10) {
  //       newValue = newValue + "0" + Math.floor(value / 60);
  //     } else {
  //       newValue = newValue + Math.floor(value / 60);
  //     }
  //     value %= 60;
  //   } else {
  //     newValue += '00';
  //   }
  
  //   return newValue + " " + period;
  // }

  timestampToTime(value) {
    let newValue = "";
    let period = value >= 43200 ? "PM" : "AM";
  
    if (value >= 46800) {
      value -= 43200;
    }
  
    if (Math.floor(value / 3600) < 10) {
      newValue = "0" + Math.floor(value / 3600) + ":";
    } else {
      newValue = Math.floor(value / 3600) + ":";
    }
    value %= 3600;
  
    if (value >= 60) {
      if (Math.floor(value / 60) < 10) {
        newValue = newValue + "0" + Math.floor(value / 60);
      } else {
        newValue = newValue + Math.floor(value / 60);
      }
      value %= 60;
    } else {
      newValue += '00';
    }
  
    return newValue + " " + period;
  }
  
  getSchedule(filter?) {
    this.attachSubscriptions(
      this.companyService.getSchedule(this.data.company.id, 1, filter ? filter : {}).pipe(
        map(x => x.body)
      ).subscribe(resp => {
        console.log("getSchedule", resp);
        console.log("getSchedule days", this.days);
        this.schedules = resp;
        this.generateModel()
        // new Date(element.time * 1000).toISOString().slice(11, 16)
      })
    )
  }

  itemAll(e, item) {
    item.for_all = e.checked;
  }

  generateModel() {
    if (!this.schedules || this.schedules.length == 0) {
      return
    }

    this.days.forEach(d => {
      d.working.from = 0;
      d.working.to = 0;
      d.for_all = false;
      d.data = this.schedules.filter(x => x.day == d.day);
      if (d.data && d.data.length) {
        if (d.data.length == 1 && d.data[0].from_time == 0 && d.data[0].to_time == 0) {
          d.breaks = [{from:0, to:0}];
          d.is_off = true;
        } else {
          d.is_off = false;
          d.data.sort((a,b) => {
            return a.from_time - b.from_time;
          })
          if (d.data.length == 1) {
            d.breaks = [{from:0, to:0}];
          } else {
            d.breaks = [];
          }
          d.data.forEach((el, ind) => {
            console.log("day-"+ d.day +" el-" + ind, el.to_time)
            if (d.working.from === 0 || this.timeToTimestamp(d.working.from) > el.from_time) {
              d.working.from = this.timestampToTime(el.from_time);
            }
            if (d.working.to === 0 || this.timeToTimestamp(d.working.to) < el.to_time) {
              console.log("day-"+ d.day +" check-" + ind, el.to_time)
              d.working.to = this.timestampToTime(el.to_time);
            }

            if (ind != 0) {
              d.breaks.push({from: this.timestampToTime(d.data[ind-1].to_time), to: this.timestampToTime(el.from_time)})
            }
          });
        }
      }
      d.is_load = false;
    })
  }
  
  protected filterTimezoneGroups() {
    if (!this.timezones) {
      return;
    }
    // get the search keyword
    let search = this.timezoneFilterCtrl.value;
    const timezonesCopy = this.copyTimezoneGroups(this.timezones);
    if (!search) {
      this.filteredTimezonesGroups.next(timezonesCopy);
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredTimezonesGroups.next(
      timezonesCopy.filter(timezoneGroup => {
        const showBankGroup = timezoneGroup.value.toLowerCase().indexOf(search) > -1;
        if (!showBankGroup) {
          timezoneGroup.names = timezoneGroup.names.filter(name => name.toLowerCase().indexOf(search) > -1);
        }
        return timezoneGroup.names.length > 0;
      })
    );
  }

  protected copyTimezoneGroups(timezoneGroups: any) {
    const timezonesCopy = [];
    timezoneGroups.forEach(bankGroup => {
      timezonesCopy.push({
        value: bankGroup.value,
        names: bankGroup.names.slice()
      });
    });
    return timezonesCopy;
  }

  saveEmployee() {
    let sbmtData = {...this.form.value}
    if (sbmtData.timezone == 'no') {
      sbmtData.timezone = ''
    } else {
      delete sbmtData.timezone_offset
    }
    
    this.attachSubscriptions(
      this.companyService.editEmployeee(this.employee.id, sbmtData).subscribe(resp => {
        this.employee.timezone = resp.timezone;
        this.employee.timezone_offset = resp.timezone_offset;
        console.log("saveEmployee", resp);
      })
    )
  }

  deleteBreak(day, i) {
    day.breaks.splice(i, 1)
  }

  getTimeZone(val) {
    let o = Math.abs(val);
    return (val >= 0 ? "+" : "-") + ("00" + Math.floor(o / 3600)).slice(-2) + ":00";
  }
  
  getOffsetData(name) {
    if (!this.timezones) {
      return "0"
    }
    return this.timezones.find(x => x.names.filter(y => y == name).length > 0).value
  }

  getTimeZones() {
    this.attachSubscriptions(
      this.companyService.getTimeZones().subscribe(resp => {
        let val = [];
        Object.keys(resp).forEach(key => {
          val.push({
            value: key,
            names: resp[key]
          })
        })
        this.timezones = val;
        this.filteredTimezonesGroups.next(this.copyTimezoneGroups(this.timezones));
        console.log("getTimeZones",this.timezones);
      })
    )
  }

  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData().subscribe(resp => {
        this._ngx_adapter.setLocale(resp.active);
        this._adapter.setLocale(resp.active);
        this.activeLang = resp.active;
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
