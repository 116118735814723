
<div class="player-container-wrp" [ngClass]="{
    'isMOB': isMOB,
    'forFM': forFM
}">

    <div class="audio_card" *ngIf="forFM">
        <div class="audio_img">
            <ng-container *ngIf="file.thumbnail; else elseTemplateThumbnailAudio">
                <img style="max-width: 100%; max-height: 100%;" (error)="onImgError($event)"  [src]="host + file.thumbnail + '?company_id=' + company?.id">
            </ng-container>
            <ng-template #elseTemplateThumbnailAudio>
                <mat-icon *ngIf="!state?.playing" class="file_img_icon" style="color: #3f51b5">
                    music_note
                </mat-icon>
                <div class="loader" *ngIf="state?.playing">
                    <span class="stroke"></span>
                    <span class="stroke"></span>
                    <span class="stroke"></span>
                    <span class="stroke"></span>
                    <span class="stroke"></span>
                    <span class="stroke"></span>
                    <span class="stroke"></span>
                </div>
            </ng-template>
        </div>
        <div class="audio_card_info">
            <div class="audio_name">
                {{ file?.filename }}
            </div>
        </div>
    </div>
    
    <div class="player-container" [ngClass]="{'need_bor': !file?.fileOriginalData?.transcription_plain}">


        <div class="">
            <a
                mat-icon-button
                class="play-btn"
                *ngIf="!state?.playing"
                [disabled]="state?.error"
                (click)="play()"
            >
                <mat-icon>play_arrow</mat-icon>
            </a>
            <a
                mat-icon-button
                class="play-btn"
                *ngIf="state?.playing"
                (click)="pause()"
            >
                <mat-icon>pause</mat-icon>
            </a>
        </div>
        <div class="timeline" (click)="$event.preventDefault()">
            <span class="time-value text-left">{{state?.readableCurrentTime}}</span>
            <mat-slider color="primary" class="time-slider" min="0" [max]="state?.duration" matBufferValue="3" step="1" [value]="state?.currentTime" [disabled]="state?.error" (input)="onSliderChangeEnd($event)" discrete></mat-slider>
            <span class="time-value text-right">{{ state?.readableDuration }}</span>
        </div>
        <!-- <div class="">
            <a mat-icon-button class="vol-btn" *ngIf="!state?.mute" (click)="mute()">
                <mat-icon>volume_up</mat-icon>
            </a>
            <a mat-icon-button class="vol-btn" *ngIf="state?.mute" (click)="unmute()">
                <mat-icon>volume_off</mat-icon>
            </a>
        </div> -->
        <div>
            <div class="spead" (click)="playbackRate.open()">
                <div class="left">
                    x{{state?.playbackRate}}
                </div>
                <div class="right">
                    <svg style="width:10px; height: 10px;" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_4380_103634)">
                        <path d="M7.91699 3.33337L2.08366 3.33337L5.00033 7.91671L7.91699 3.33337Z" fill="white"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_4380_103634">
                        <rect width="10" height="10" fill="white" transform="matrix(0 1 -1 0 10 0)"/>
                        </clipPath>
                        </defs>
                    </svg>
                </div>
                <mat-form-field appearance="legacy" class="select_playback_rate">
                    <mat-label>{{ "Outlet" | translate }}</mat-label>
                    <mat-select panelClass="select_playback_rate_panel" [multiple]="false" #playbackRate [disableOptionCentering]="true" selectDir [(ngModel)]="state.playbackRate" (selectionChange)="changeSpeed($event.value)">
                        <mat-option [value]="0.25">0.25</mat-option>
                        <mat-option [value]="0.5">0.5</mat-option>
                        <mat-option [value]="0.75">0.75</mat-option>
                        <mat-option [value]="1">1</mat-option>
                        <mat-option [value]="1.25">1.25</mat-option>
                        <mat-option [value]="1.5">1.5</mat-option>
                        <mat-option [value]="1.75">1.75</mat-option>
                        <mat-option [value]="2">2</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="" *ngIf="!forFM">
                <button class="vol-btn" mat-icon-button [matMenuTriggerFor]="beforeMenu"><mat-icon>more_vert</mat-icon></button>
                <mat-menu #beforeMenu="matMenu" xPosition="before">
                    <div class="file_info_dots">
                        <div class="file_name">{{ file?.filename }}</div>
                        <div class="file_filesize">{{ file?.filesize | filesize}}</div>
                    </div>
                    <mat-divider></mat-divider>
                    <button mat-menu-item (click)="download()">
                        <mat-icon>file_download</mat-icon>
                        {{"Download"|translate}}
                    </button>
                    <button *ngIf="canTranscribe" mat-menu-item (click)="transcribeFile()">
                        <mat-icon>record_voice_over</mat-icon>
                        {{ 'Speech to Text' | translate }}
                    </button>
                    <button mat-menu-item (click)="copy()">
                        <mat-icon>content_copy</mat-icon>
                        {{"Copy audio file"|translate}}
                    </button>
                    <button mat-menu-item (click)="delete()">
                        <mat-icon>delete</mat-icon>
                        {{"Delete audio file"|translate}}
                    </button>
                </mat-menu>
        </div>
    </div>
    <ng-container *ngIf="!forFM">
        <ng-container *ngIf="file.is_uploaded == 1; else elseTemplateIsUploadedVideo">
            <!-- <div class="audio_info">
                <div class="name">
                    {{ file?.filename.length > 30 ? (file?.filename | slice:0:20) + "..." + (file?.filename | slice:-7) : file?.filename }}
                </div>
                <div class="wrper file_size">
                    {{ file?.filesize | filesize}}
                </div>
            </div> -->
            <ng-container *ngIf="file?.fileOriginalData?.transcription_plain">
                <div class="transcription">
                    {{file?.fileOriginalData?.transcription_plain}}
                </div>
            </ng-container>
            
        </ng-container>
        <ng-template #elseTemplateIsUploadedVideo>
            <div class="audio_info">
                <div class="name">
                    {{ file?.filename.length > 30 ? (file?.filename | slice:0:20) + "..." + (file?.filename | slice:-7) : file?.filename }}
                </div>
                <div class="wrper file_size">
                    {{ file?.filesize | filesize}}
                </div>
                <div class="wrper progress_elem w_f_u" (click)="deleteAndCancel()">
                    <span>
                        {{ "Waiting for upload" | translate }}
                    </span>
                    <mat-icon class="cp" style="color: #ff7677 !important;">close</mat-icon>
                </div>
            </div>
            <ng-container *ngIf="file?.fileOriginalData?.transcription_plain">

                <div class="transcription">
                    {{file?.fileOriginalData?.transcription_plain}}
                </div>
            </ng-container>
        </ng-template>
    </ng-container>

</div>

<audio #audio muted="true" class="not_show_aud" [src]="host + (file?.preview1080 ? file?.preview1080 : file?.original) + '?company_id=' + company_id"></audio>