import { CdkDragEnter, CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { forkJoin, fromEvent, ReplaySubject, Subscription } from 'rxjs';
import { debounceTime, filter, last, map, switchMap, take, tap } from 'rxjs/operators';
import { OpenTaskComponent } from 'src/app/components/workspace-pages/cases/dialogs/open-task/open-task.component';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { SnackBarItem } from '../../../snack-bar/snack-bar-item';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';

@Component({
  selector: 'app-select-task-template',
  templateUrl: './select-task-template.component.html',
  styleUrls: ['./select-task-template.component.scss']
})

export class SelectTaskTemplateComponent extends BaseClass implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('consistPlaceholder') consistPlaceholder: any;
  @ViewChild('partPlaceholder') partPlaceholder: any;

  @ViewChild('consistDragPlaceholder') consistDragPlaceholder: ElementRef;
  @ViewChild('partDragPlaceholder') partDragPlaceholder: ElementRef;

  @ViewChild('relatedPlaceholder') relatedPlaceholder: any;
  @ViewChild('relatedDragPlaceholder') relatedDragPlaceholder: ElementRef;

  @ViewChild('contextMenu') contextMenu: TemplateRef<any>;
  overlayRef: OverlayRef | null;
  public backContextSub: Subscription;
  public origin = window.location.origin;
  
  public form: FormGroup;
  public isSubmit: boolean = false;
  public submited: boolean = false;
  public is_mobile: boolean = false;
  public relations: boolean = false;

  public target: CdkDropList;
  public targetIndex: number;
  public source: CdkDropList;
  public sourceIndex: number;
  
  public savedTasks: any[] = [];
  public separatorKeysCodes: number[] = [ENTER, COMMA];
  
  public page: number = 1;
  public pagination: any;
  
  public taskStatuses: any;
  public groups: any;
  public tasks: any[] = [];

  public taskTemplates$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public taskTemplatesMoreControl: FormControl = new FormControl();
  public taskTemplates: any;

  public typeOfSearchControl: FormControl = new FormControl('Everywhere');
  public consistOfControl: FormControl = new FormControl();
  public partOfControl: FormControl = new FormControl();
  public relativeControl: FormControl = new FormControl();

  public groupMoreControl: FormControl = new FormControl();
  public groupOfSearchControl: FormControl = new FormControl('');
  
  public tasks$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public groups$: ReplaySubject<any> = new ReplaySubject<any>(1);
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<SelectTaskTemplateComponent>,
    private sm: StorageManagerService,
    private layoutService: LayoutService,
    private companyService: CompanyService,
    private dialog: MatDialog,
    private taskService: TaskService,
    private fb: FormBuilder,
    public overlay: Overlay,
    public viewContainerRef: ViewContainerRef,
  ) { super() }

  ngOnInit(): void {
    console.log("SelectTaskTemplateComponent", this.data); 
    this.taskTemplates = this.data.taskTemplates;
    this.taskTemplates$.next(this.taskTemplates.slice())
    this.form = this.fb.group({
      id: ['', Validators.required],
      name: ['', Validators.required],
      status_id: ['', Validators.required],
      consist_of_task_id: this.fb.group({
        add: [[]]
      }),
      part_of_task_id: this.fb.group({
        add: [[]]
      }),
      related_task_id: this.fb.group({
        add: [[]]
      })
    })

    
    if (this.data.related) {
      if (!!this.data.related[0]) {
        this.form.get('consist_of_task_id').get('add').patchValue([this.data.related[0]])
      }
      if (!!this.data.related[1]) {
        this.form.get('part_of_task_id').get('add').patchValue([this.data.related[1]])
      }
      if (!!this.data.related[2]) {
        this.form.get('related_task_id').get('add').patchValue([this.data.related[2]])
      }

      let arr = this.data.related.filter(x => !!x);

      this.attachSubscriptions(
        forkJoin(arr.map(id => this.taskService.getTask(id, this.data.company.id))).subscribe(resp => {
          this.savedTasks.push(...resp)
        })
      )
    }

    if (this.data.related && this.data.related.filter(x => !!x).length == 0 && this.data.relatedArrs) {
      if (this.data.relatedArrs[0].length) {
        this.form.get('consist_of_task_id').get('add').patchValue([...this.data.relatedArrs[0]])
      }
      if (this.data.relatedArrs[1].length) {
        this.form.get('part_of_task_id').get('add').patchValue([...this.data.relatedArrs[1]])
      }
      if (this.data.relatedArrs[2].length) {
        this.form.get('related_task_id').get('add').patchValue([...this.data.relatedArrs[2]])
      }

      let arr = this.data.relatedArrs.flat();

      this.attachSubscriptions(
        forkJoin(arr.map(id => this.taskService.getTask(id, this.data.company.id))).subscribe(resp => {
          this.savedTasks.push(...resp)
        })
      )
    }


    this.getGroups();

    this.attachSubscriptions(
      this.typeOfSearchControl.valueChanges.subscribe((resp) => {
        this.tasks = []
        this.tasks$.next(this.tasks.slice())
        this.page = 1;
        this.pagination = undefined
        this.getTasks(this.page, {group_id: ['Everywhere', 'Custom ID', 'Task Name'].includes(resp) ? this.groupOfSearchControl.value : 0})
      })
    )

    this.attachSubscriptions(
      this.groupOfSearchControl.valueChanges.subscribe((resp) => {
        this.tasks = []
        this.tasks$.next(this.tasks.slice())
        this.page = 1;
        this.pagination = undefined
        this.getTasks(this.page, {group_id: ['Everywhere', 'Custom ID', 'Task Name'].includes(this.typeOfSearchControl.value) ? resp : 0})
      })
    )

    this.attachSubscriptions(
      this.consistOfControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchTasks(resp, this.typeOfSearchControl.value))
    )

    this.attachSubscriptions(
      this.groupMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchGroups(resp))
    )

    this.attachSubscriptions(
      this.partOfControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchTasks(resp, this.typeOfSearchControl.value))
    )

    this.attachSubscriptions(
      this.relativeControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchTasks(resp, this.typeOfSearchControl.value))
    )

    this.attachSubscriptions(
      this.taskTemplatesMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchTemplates(resp))
    )


    this.getTasks(this.page)
    this.getTaskStatus()
  }

  getTaskStatus() {
    this.attachSubscriptions(
      this.taskService.getTaskStatuses(this.data.company.id).subscribe(resp => {
        console.log("getTaskStatuses", resp)
        this.taskStatuses = resp;
      })
    )
  }

  onSearchGroups(resp) {
    if (!this.groups) {
      return;
    }

    if (!resp) {
      this.groups$.next(this.groups.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.groups$.next(
      this.groups.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  onSearchTemplates(resp) {
    if (!this.taskTemplates) {
      return;
    }

    if (!resp) {
      this.taskTemplates$.next(this.taskTemplates.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.taskTemplates$.next(
      this.taskTemplates.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  getGroups() {
    this.attachSubscriptions(
      this.companyService.getInfiniteGroupsCompany(this.data.company.id, '1', '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.companyService.getInfiniteGroupsCompany(this.data.company.id, x).pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        this.groups = [].concat(...res);
        this.groups$.next(this.groups.slice())
      })
    )
  }

  getTasks(page, filter:any = null) {
    console.log("getTasks filter", filter)
    this.attachSubscriptions(
      this.taskService.getTasksSelect(page, this.data.company.id, filter).pipe(
        tap(el => {
          this.pagination = {
            'pageCount': el.headers.get('x-pagination-page-count'),
            'perPage': el.headers.get('x-pagination-per-page'),
            'totalCount': el.headers.get('x-pagination-total-count'),
            'currentPage': el.headers.get('x-pagination-current-page'),
          }
        })
      ).subscribe(resp => {
        this.tasks.push(...resp.body)
        console.log("getTasks res" + this.page, this.tasks)
        this.page = this.page + 1; 
        this.tasks$.next(this.tasks.slice())
      })
    )
  }

  selected(e, input: HTMLInputElement, key) {
    console.log("selected", e)
    let addArr = this.form.get(key).get('add').value.slice();

    if (!addArr.includes(e.option.value)) {
      addArr.push(e.option.value)
      if (this.tasks.find(x => x.id == e.option.value) && this.savedTasks.filter(u => u.id == e.option.value).length == 0) {
        this.savedTasks.push(this.tasks.find(x => x.id == e.option.value))
      }
    }
    this.form.get(key).patchValue({
      add: addArr
    })

    input.value = ''

    this.tasks$.next(this.tasks.slice());
  }
  
  selectType(status) {
    this.form.patchValue({
      status_id: !!status.id ? status.id : status.value
    })
  }

  findTask(id) {
    if (this.savedTasks.find(x => x.id == id)) {
      return this.savedTasks.find(x => x.id == id)
    }
  }

  dropped(papa, ph, arr) {
    if (!this.target) return;

    const parent: HTMLElement = papa;
    const phElement: HTMLElement = ph;
    const phElementIndex = __indexOf(parent.children, phElement);

    phElement.style.display = 'none';
    parent.removeChild(phElement);
    parent.appendChild(phElement);

    parent.insertBefore(
      this.source.element.nativeElement,
      parent.children[this.sourceIndex]
    );

    console.log(this.sourceIndex, ' => ', phElementIndex);

    if (this.sourceIndex != phElementIndex) {
      moveItemInArray(arr, this.sourceIndex, phElementIndex);
    }

    this.target = null;
    this.targetIndex = undefined;
    this.source = null;
    this.sourceIndex = undefined;
  }

  ngAfterViewInit() {
    let conPhElement = this.consistPlaceholder.nativeElement;

    conPhElement.style.display = 'none';
    conPhElement.parentNode.removeChild(conPhElement);

    let partPhElement = this.partPlaceholder.nativeElement;

    partPhElement.style.display = 'none';
    partPhElement.parentNode.removeChild(partPhElement);

    let relatedPhElement = this.relatedPlaceholder.nativeElement;

    relatedPhElement.style.display = 'none';
    relatedPhElement.parentNode.removeChild(relatedPhElement);
  }

  entered({ item, container }: CdkDragEnter, papa, ph) {
    const phElement: HTMLElement = ph;
    const dropElement: HTMLElement = container.element.nativeElement;
    const prevTarget: CdkDropList = this.target;
    const prevTargetIndex: number = this.targetIndex;
    this.target = container;

    const dropElementIsTheSource: boolean = !dropElement.parentNode;
    const prevAndCurrentTargetAreTheSame: boolean = this.target === prevTarget;
    if (dropElementIsTheSource || prevAndCurrentTargetAreTheSame) {
      return;
    }

    this.targetIndex = __indexOf(dropElement.parentNode.children, dropElement);

    if (!this.source) {
      this.source = item.dropContainer;
      this.sourceIndex = __indexOf(
        dropElement.parentNode.children,
        item.dropContainer.element.nativeElement
      );
      const sourceElement: HTMLElement = this.source.element.nativeElement;

      this.fixPhElementStyling(phElement, sourceElement);

      sourceElement.parentNode.removeChild(sourceElement);
    }

    const index: number = prevTargetIndex ?? this.sourceIndex;
    const insertAfter: boolean = index < this.targetIndex;

    papa.insertBefore(
      phElement,
      insertAfter ? dropElement.nextSibling : dropElement
    );
  }

  dragReleased(ph, dragPh) {
    const phElementPositionWasChanged: boolean = !!this.source;
    if (phElementPositionWasChanged) {
      // console.log("dragReleased dragPh", ph, dragPh)
      dragPh.nativeElement.style.transform = 'none';
      dragPh.nativeElement.parentNode.removeChild(
        dragPh.nativeElement
      );
      ph.appendChild(
        dragPh.nativeElement
      );
    }
  }

  private fixPhElementStyling(
    phElement: HTMLElement,
    sourceElement: HTMLElement
  ) {
    phElement.style.width = sourceElement.clientWidth - 6 + 'px';
    phElement.style.height = sourceElement.clientHeight - 6 + 'px';

    const size = Array.from(sourceElement.classList).find((c) =>
      c.startsWith('content-item-c')
    );

    phElement.style.display = '';
    const oldSize = Array.from(phElement.classList).find((c) =>
      c.startsWith('content-item-c')
    );
    if (oldSize) {
      phElement.classList.remove(oldSize);
    }
    if (size) {
      phElement.classList.add(size);
    }
  }

  onRemoved(value: string, key) {
    const values = this.form.get(key).get('add').value as string[];
    this.removeFirst(values, value);
    this.form.get(key).get('add').setValue(values); // To trigger change detection

    console.log(this.form.value)
  }

  private removeFirst<T>(array: T[], toRemove: T): void {
    const index = array.indexOf(toRemove);
    if (index !== -1) {
      array.splice(index, 1);
    }
  }

  onScroll(type) {
    console.log("onScroll", type, this[type].value || '');
    this.onSearchTasks(this[type].value || '', this.typeOfSearchControl.value, true)
  }

  onSearchTasks(resp, query, notDel?) {
    
    resp = resp && typeof resp == 'string' ? resp.toLowerCase() : (resp || '');
    console.log('onSearchTasks', resp, query, notDel)

    let filter:any = {
      group_id: ['Everywhere', 'Custom ID', 'Task Name'].includes(this.typeOfSearchControl.value) ? this.groupOfSearchControl.value : 0
    }
    if (!notDel) {
      this.tasks = []
      this.tasks$.next(this.tasks.slice())
      this.page = 1;
      this.pagination = undefined
    }
    // filter the banks
    switch (query) {
      case 'Everywhere':
        filter.q = resp;
        break;
      case 'Task Name':
        filter.q_task_name = resp;
        break;
      case 'Custom ID':
        filter.custom_id = resp;
        break;
      case 'Internal ID':
        filter.internal_id = resp;
        break;
      case 'Global ID':
        filter.id = resp;
        break;
    }

    this.getTasks(this.page, filter)

  }

  close() {
    this.dialogRef.close({event: "close", data: false});
  }

  relationsOpen() {
    this.relations = true;
  }

  save() {
    if (!this.form.valid) {
      this.submited = true;
      console.log(this.form);
      return
    }

    this.submited = false;
    this.isSubmit = true;

    let tmplData = this.data.taskTemplates.find(x => x.id == this.form.value.id).template_data;
    tmplData.name = this.form.value.name;
    tmplData.status_id = this.form.value.status_id;
    tmplData.consist_of_task_id = this.form.value.consist_of_task_id;
    tmplData.part_of_task_id = this.form.value.part_of_task_id;
    tmplData.related_task_id = this.form.value.related_task_id;



    if (this.data.company.permissions.includes("owner") || this.data.company.permissions.includes("manager") || this.data.company.permissions.includes("admin")) {
      this.attachSubscriptions(
        this.taskService.createTask(this.data.company.id, Object.assign(tmplData, {created_task_template_id: this.form.value.id}, !this.data.file_ids ? {} : {created_file_id: this.data.file_ids[0]})).pipe(
          switchMap(task => {
            return this.taskService.getWorksIds(this.data.company.id, task.id).pipe(
              map(jobs => jobs.map(x => x.id)),
              switchMap(job_ids => {
  
                if (!!job_ids.length) {
                  let postData = [];
                  
                  job_ids.forEach(job_id => {
                    this.data.file_ids.forEach(file_id => {     
                      postData.push({
                        "path": `/api/file/copy/`,
                        "query": {company_id: this.data.company.id},
                        "method": "POST",
                        "body": {
                          location: '/',
                          task_id: task.id,
                          task_operation_id: job_id,
                          [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
                          id: file_id, 
                          company_id: this.data.company.id
                        }
                      })
                    });
                  });
  
                  return this.taskService.multiRequest(postData)
                } else {
                  let postData = [];
                  
                  this.data.file_ids.forEach(file_id => {     
                    postData.push({
                      "path": `/api/file/copy/`,
                      "query": {company_id: this.data.company.id},
                      "method": "POST",
                      "body": {
                        location: '/',
                        task_id: task.id,
                        [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
                        id: file_id, 
                        company_id: this.data.company.id
                      }
                    })
                  });
                  return this.taskService.multiRequest(postData)
                }
              }),
              map(() => task)
            )
          })
        ).subscribe(resp => {
          this.layoutService.showSnackBar({name: tmplData.name}, marker("created!"), SnackBarItem)
          this.isSubmit = false;
          this.openTask(resp)
          this.taskService.newCard$.next({company_id: resp.company_id, task_id: resp.id})
          this.dialogRef.close({event: "Add", data: resp})
        })
      )
    } else {
      let emplDataId = this.form.value.id;
      let emplData = {...this.form.value};
      emplData.task_operations_files = this.data.file_ids
      delete emplData.id;
      this.attachSubscriptions(
        this.taskService.createTaskByEmployeeUsedTemplate(emplDataId, emplData).subscribe(resp => {
          console.log("createTaskByEmployeeUsedTemplate", resp)
          this.layoutService.showSnackBar({name: emplData.name}, marker("created!"), SnackBarItem)
          this.isSubmit = false;
          this.openTask(resp)
          this.taskService.newCard$.next({company_id: resp.company_id, task_id: resp.id})
          this.dialogRef.close({event: "Add", data: resp})
        })
      )
    }
     
    
  }

  checkIsMobile() {
    if (window.innerWidth <= 769) {
      this.is_mobile = true;
    } else {
      this.is_mobile = false;
    }
    this.onResize();
  }

  onResize() {
    this.attachSubscriptions(
      fromEvent(window, "resize").pipe(
        map(() => window.innerWidth)
      ).subscribe((wWidth) => {
          if (wWidth <= 769) {
            this.is_mobile = true;
          } else {
            this.is_mobile = false;
          }
        }
      )
    )
  }

  openTask(task) {
    let taskData:any = {
      task_id: task.id,
      initCompanyId: this.data.company.id
    }
    if (task.hasOwnProperty("operations")) {
      taskData.task = task
    }
    const dialogRef = this.dialog.open(OpenTaskComponent, {
      backdropClass: 'backdrop_under_header',
      panelClass: !this.is_mobile ? ['open_task_dialog', 'show_header'] : 'open_task_dialog',
      autoFocus: false,
      data: taskData
    });
  }
  
  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

  openContext({ x, y }: MouseEvent, contextData) {
    this.closeContext();
    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo({ x, y })
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        }
      ]);

    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });

    this.overlayRef.attach(new TemplatePortal(this.contextMenu, this.viewContainerRef, {
      $implicit: contextData
    }));
    
    this.backContextSub = fromEvent<MouseEvent>(document, 'click')
      .pipe(
        filter(event => {
          const clickTarget = event.target as HTMLElement;
          return !!this.overlayRef && !this.overlayRef.overlayElement.contains(clickTarget);
        }),
        take(1)
      ).subscribe(() => this.closeContext())

  }

  closeContext() {
    this.backContextSub && this.backContextSub.unsubscribe();
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
  }

  moveTo(contextData, targetControl) {
    console.log(contextData, targetControl)

    const fromValues = contextData.form.get(contextData.control).get('add').value as string[];
    this.removeFirst(fromValues, contextData.id);
    contextData.form.get(contextData.control).get('add').setValue(fromValues); // To trigger change detection

    let toValues = contextData.form.get(targetControl).get('add').value as string[];
    toValues.push(String(contextData.id))
    contextData.form.get(targetControl).get('add').setValue(toValues); // To trigger change detection

    console.log(contextData.form.value)
    this.closeContext()
  }

  remove(contextData) {
    const fromValues = contextData.form.get(contextData.control).get('add').value as string[];
    this.removeFirst(fromValues, contextData.id);
    contextData.form.get(contextData.control).get('add').setValue(fromValues);
    this.closeContext()
  }

  openCard(contextData) {
    this.closeContext()
    let task = this.findTask(contextData.id);
    console.log("task", task)
    if (!task) {
      return
    }
    window.open(`${this.origin}/task/${task.acm}`, '_blank')
  }
}

function __indexOf(collection, node) {
  return Array.prototype.indexOf.call(collection, node);
}