<div class="container centred_page">

    <div class="step">
        <h2>{{ 'Company' | translate }}: {{ company?.name }}</h2>
  
        <h3>{{ 'Card Types' | translate }}: </h3>
        
        <mat-list role="list" class="job_types">
            <mat-list-item *ngFor="let type of taskTypes; let i = index;" role="listitem" class="company_item">
                <div class="type_name">
                    <mat-checkbox [disabled]="type.disabled" class="mr-1" color="primary" (change)="toggleTaskType($event, type)" [checked]="type?.taskStatusToCompany?.length > 0"></mat-checkbox>
                    {{ type?.name }}
                </div>

                <div class="d-f-c">
                    <div class="company_btns" *ngIf="type.company_id != 0">
                        <mat-icon (click)="editTaskType(type)">edit</mat-icon>
                        <mat-icon (click)="deleteTaskType(type, i)">delete</mat-icon>
                    </div>
                </div>
            </mat-list-item>
          </mat-list>
  
        <div class="btns_line">
            <button mat-raised-button (click)="goBack()">
                {{ "Back" | translate }}
            </button>
            <button class="ml-1" (click)="createTaskType()" mat-raised-button color="primary">
                <mat-icon>add</mat-icon>
                {{ "Create new type" | translate }}
            </button>
        </div>
  
  
    </div>
  </div>