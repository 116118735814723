import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, tap } from 'rxjs/operators';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { RootService } from 'src/app/shared/services/rest/root.service';
import { AddEditRootPromoComponent } from '../add-edit-root-promo/add-edit-root-promo.component';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';

@Component({
  selector: 'app-root-promo',
  templateUrl: './root-promo.component.html',
  styleUrls: ['./root-promo.component.scss'],
    animations: [
      trigger('detailExpand', [
        state('collapsed', style({height: '0px', minHeight: '0'})),
        state('expanded', style({height: '*'})),
        transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      ]),
    ],
})
export class RootPromoComponent extends BaseClass implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild("tableContainer") tableContainer: ElementRef;
  public promos: any[] = [];
  public page: number = 1;
  public pagination: any;
  public displayedColumns: string[] = ["Date", "Workspace", "Code", "Initial", "Description", "Workspace Description", "Root Description", "Actions"];
  
  public is_em: number = 1;
  
  public activeLang: any;
  public isRefreshed: boolean = false;

  public today = moment().set({hour:0,minute:0,second:0}).unix();
  public todayEnd = moment().endOf('day').unix();
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<RootPromoComponent>,
    private dialog: MatDialog,
    private languageService: LanguageService,
    private rootService: RootService,
    private layoutService: LayoutService
  ) { super() }

  ngOnInit(): void {
    console.log("RootPromoComponent", this.data)

    this.getLangData();
    this.getRootPromos(this.page);
  }

  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData().subscribe(resp => {
        this.activeLang = resp.active;
      })
    )
  }

  onPartnerImgError(event){
    event.target.src = this.data.imgRoute+'/assets/img/partner.png'
  }

  changeFilter(val) {
    this.is_em = val;

    this.page = 1;
    this.getRootPromos(this.page)
  }

  copyData(type) {
    this.layoutService.showSnackBar({name: type}, "Copied", SnackBarItem)
  }

  ngAfterViewInit() {
    if (this.tableContainer) {
      this.tableContainer.nativeElement.scrollTop = 0;
      this.onScroll();
    }
  }
  
  onScroll() {
    this.attachSubscriptions(
      fromEvent(this.tableContainer.nativeElement, "scroll").pipe(
        filter((e:any) => e.target.scrollTop >=  e.target.scrollHeight - e.target.offsetHeight - 400),
        debounceTime(300),
        distinctUntilChanged()
      ).subscribe(() => {
          if (this.page <= this.pagination['pageCount']) {
            this.getRootPromos(this.page);
          }
        }
      )
    )
  }

  refresh() {
    this.is_em = 1;
    this.page = 1;
    this.getRootPromos(this.page);
  }

  getRootPromos(n) {
    this.isRefreshed = true;
    if (this.is_em == 2) {
      this.attachSubscriptions(
        this.rootService.getRootPromosEm(n, {company_id: this.data.company.id}).pipe(
          tap(el => {
            this.pagination = {
              'pageCount': el.headers.get('x-pagination-page-count'),
              'perPage': el.headers.get('x-pagination-per-page'),
              'totalCount': el.headers.get('x-pagination-total-count'),
              'currentPage': el.headers.get('x-pagination-current-page'),
            }
          }),
          map(el => el.body)
        ).subscribe(resp => {
          if (this.page = 1) {
            this.promos = resp
          } else {
            this.promos.push(...resp)
          }
          this.page++;
          this.isRefreshed = false;
          console.log("this.promos - " + this.page, this.promos)
        })
      )
    } else if (this.is_em == 1) {
      this.attachSubscriptions(
        this.rootService.getRootPromos(n, {company_id: this.data.company.id}).pipe(
          tap(el => {
            this.pagination = {
              'pageCount': el.headers.get('x-pagination-page-count'),
              'perPage': el.headers.get('x-pagination-per-page'),
              'totalCount': el.headers.get('x-pagination-total-count'),
              'currentPage': el.headers.get('x-pagination-current-page'),
            }
          }),
          map(el => el.body)
        ).subscribe(resp => {
          if (this.page = 1) {
            this.promos = resp
          } else {
            this.promos.push(...resp)
          }
          this.page++;
          this.isRefreshed = false;
          console.log("this.promos - " + this.page, this.promos)
        })
      )
    } else {
      this.attachSubscriptions(
        this.rootService.getRootPromosEm(n, {referred_by_company_id: this.data.company.id}).pipe(
          tap(el => {
            this.pagination = {
              'pageCount': el.headers.get('x-pagination-page-count'),
              'perPage': el.headers.get('x-pagination-per-page'),
              'totalCount': el.headers.get('x-pagination-total-count'),
              'currentPage': el.headers.get('x-pagination-current-page'),
            }
          }),
          map(el => el.body)
        ).subscribe(resp => {
          if (this.page = 1) {
            this.promos = resp
          } else {
            this.promos.push(...resp)
          }
          this.page++;
          this.isRefreshed = false;
          console.log("this.promos - " + this.page, this.promos)
        })
      )
    }

  }

  getCompById(id) {
    if (!this.data.companyes || this.data.companyes.length == 0) {
      return null
    }
    return this.data.companyes.find(x => x.id == id)
  }

  rowClick(element) {
    console.log(element);
    element.isExpanded = !element.isExpanded
  }

  deletePromo(promo) {
    console.log("deletePromo")
    this.attachSubscriptions(
      this.rootService.deleteRootPromo(promo.id).subscribe(resp => {
        this.refresh();
      }, error => {
        console.log('error', error)
        this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
      })
    )
  }

  addPromo(item?) {
    console.log("addPromo")

    let rootPromosData:any = {
      imgRoute: this.data.imgRoute,
      host: this.data.host,
      company: this.data.company,
    }

    if (item) {
      rootPromosData.promo = item
    }

    const dialogRef = this.dialog.open(AddEditRootPromoComponent, {
      data: rootPromosData
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        this.refresh();
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
