
<section class="lite_board" [ngClass]="{'use_out': useOut}">
    <div class="lite_board_head">
        <div class="lite_left">
            <div class="job_status_wrp">
                <button mat-button (click)='changeTab(0)' [ngClass]="{'active_status': target?.outputBoard?.boardsIndex == 0}">{{ "Order" | translate }}</button>
                <!-- <button mat-button (click)='changeTab(1)' [ngClass]="{'active_status': target?.outputBoard?.boardsIndex == 1}">{{ "Date" | translate }}</button> -->
            </div>
            <!-- <div class="lite_count" *ngIf="target?.outputBoard?.todoPagination?.totalCount">
                {{target?.outputBoard?.todoPagination?.totalCount}} {{"To Do" | translate}}
            </div> -->
        </div>
    </div>

    <mat-tab-group class="lite_boards_tabs" animationDuration="0ms" [disableRipple]="true" [selectedIndex]="target?.outputBoard?.boardsIndex">
        <mat-tab>
            <ng-template mat-tab-label>
                {{ "Order" | translate }}
            </ng-template>
            <div class="lite_board_content" cdkDropListGroup>
                <div class="lite_board_wrp lite_board_left" 
                [ngClass]="{'centred': target?.outputBoard?.todo_is_load && target?.outputBoard?.todo?.length == 0}" 
                [appDeviceClass]="'custom-scroll custom-scroll-t'"
                infinite-scroll
                [infiniteScrollDisabled]="target?.outputBoard?.todo_is_load"
                [scrollWindow]="false"
                [infiniteScrollUpDistance]="scrollUpDistance"
                [infiniteScrollThrottle]="throttle"
                (scrolled)="onDownToDo($event, target)"
                #todoContainer
                cdkDropList
                [cdkDropListData]="target?.outputBoard?.todo"
                [id]="'todo'"
                (cdkDropListDropped)="drop($event)">
                    <ng-container *ngIf="target?.outputBoard?.todo_is_load && target?.outputBoard?.todo?.length == 0; else elseTemplateLoadedToDo">
                        <mat-spinner></mat-spinner>
                    </ng-container>
                    <ng-template #elseTemplateLoadedToDo>
                        <!-- <div class="board_item" *ngFor="let item of target?.outputBoard?.todo; let i = index;" (cdkDragStarted)="startDrag($event)" (cdkDragEnded)="endDrag($event)" [cdkDragData]="item" cdkDrag>
                            {{item.id}}
                        </div> -->
                        <div class="board_item done_item" (click)="item.discussion_id ? openChat(item) : openJobItem(item)" *ngFor="let item of target?.outputBoard?.todo" (cdkDragStarted)="startDrag($event)" (cdkDragEnded)="endDrag($event)" [cdkDragData]="item" cdkDrag>
                            <div class="new_priority_line br-4" [ngClass]="{
                              'Urgent': (!!item.discussion_id ? item.discussion.priority : item.taskOperation.priority) == '1000',
                              'High': (!!item.discussion_id ? item.discussion.priority : item.taskOperation.priority) == '900',
                              'Middle': (!!item.discussion_id ? item.discussion.priority : item.taskOperation.priority) == '800',
                              'Low': (!!item.discussion_id ? item.discussion.priority : item.taskOperation.priority) == '700',
                              'No': (!!item.discussion_id ? item.discussion.priority : item.taskOperation.priority) == '0' || !(!!item.discussion_id ? item.discussion.priority : item.taskOperation.priority)
                            }"></div>
                            <div class="board_btns" [ngClass]="{'show': is_mobile || boardCollection.isSelected(item)}">
          
                              <div class="hover_item" style="margin-top: 3px;">
                                <div class="hover_show">
                                  <button class="board_btn" (click)="$event.preventDefault; $event.stopPropagation()" ngxClipboard [cbContent]="!item?.discussion_id ? item?.taskOperation?.name : item?.discussion?.name" (cbOnSuccess)="copyName(!item?.discussion_id ? item?.taskOperation?.name : item?.discussion?.name)">
                                    <svg style="width: 14px; height: 16px;" width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M12.3636 14.5455H4.36364V4.36364H12.3636M12.3636 2.90909H4.36364C3.97787 2.90909 3.6079 3.06234 3.33512 3.33512C3.06234 3.6079 2.90909 3.97787 2.90909 4.36364V14.5455C2.90909 14.9312 3.06234 15.3012 3.33512 15.574C3.6079 15.8468 3.97787 16 4.36364 16H12.3636C12.7494 16 13.1194 15.8468 13.3922 15.574C13.6649 15.3012 13.8182 14.9312 13.8182 14.5455V4.36364C13.8182 3.97787 13.6649 3.6079 13.3922 3.33512C13.1194 3.06234 12.7494 2.90909 12.3636 2.90909ZM10.1818 0H1.45455C1.06878 0 0.698807 0.153246 0.426026 0.426027C0.153246 0.698807 0 1.06878 0 1.45455V11.6364H1.45455V1.45455H10.1818V0Z" fill="white"/>
                                    </svg>
                                  </button>
                                  <button class="board_btn" (click)="$event.preventDefault; $event.stopPropagation()" ngxClipboard [cbContent]="!item?.discussion_id ? (origin + '/job/' + item?.taskOperation.acm) : (origin + '/chat/' + item?.discussion.acm)" (cbOnSuccess)="copyLink(!item?.discussion_id ? 'Work' : 'Chat')">
                                    <svg style="width: 16px; height: 8px;" width="16" height="8" viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M12 0H8.8V1.6H12C13.32 1.6 14.4 2.68 14.4 4C14.4 5.32 13.32 6.4 12 6.4H8.8V8H12C14.208 8 16 6.208 16 4C16 1.792 14.208 0 12 0ZM7.2 6.4H4C2.68 6.4 1.6 5.32 1.6 4C1.6 2.68 2.68 1.6 4 1.6H7.2V0H4C1.792 0 0 1.792 0 4C0 6.208 1.792 8 4 8H7.2V6.4ZM4.8 3.2H11.2V4.8H4.8V3.2Z" fill="white"/>
                                    </svg>
                                  </button>
                                </div>
          
                                <div class="check_wrp white">
                                  <mat-checkbox style="color: #fff;" color="primary" class="example-margin" (click)="$event.stopPropagation()" (change)="$event ? toggleItem(item) : null" [checked]='boardCollection.isSelected(item)'></mat-checkbox>
                                </div> 
                              </div>
          
                              <div class="hover_drag" (click)="$event.stopPropagation()" cdkDragHandle>
                                <div>
                                  <span></span>
                                  <span></span>
                                  <span></span>
                                </div>
                              </div>
          
                            </div>
          
                            <!-- <div class="top">
                              <div class="group">{{item?.task?.group?.name}}</div> 
                              <span *ngIf="item?.task?.group?.name" class="slash">/</span>
                              <div class="job_type">
                                {{getColumnName(item?.taskOperation.operation_id)}}
                              </div>
                              <div class="job_title text_one_line" *ngIf="!!item?.discussion_id">{{ item?.taskOperation?.name }}</div>
                              <span class="slash">/</span>
                              <div class="task_name_wrp">
                                <span class="task_name text_one_line">{{item?.task?.name}}</span>
                                <span class="task_id" *ngIf="item?.task?.custom_id">{{item?.task?.custom_id}}</span>
                              </div>
                            </div> -->
                            
                            <div class="middle">
                              <div class="middle_name_wrp">
                                <div class="type_name text_one_line">
                                  <ng-container *ngIf="item?.discussion_id; else elseTemplateItIsDiscussion">
                                    {{ item?.discussion?.name}}
                                  </ng-container>
                                  <ng-template #elseTemplateItIsDiscussion>
                                    <ng-container *ngIf="item?.taskOperation?.name; else elseTemplateNBSP">
                                      {{ item?.taskOperation?.name }}
                                    </ng-container>
                                  </ng-template>
                                </div>
                                <div class="d-f-c" style="flex-wrap: wrap;">
                                  <div class="empl_line">
                                    <div class="employees" *ngIf="!liteFilter.operation_employee_id">
                                      <div class="employee">
                                        <ng-container *ngIf="item?.is_partner; else elsePartner">
                                          <ng-container *ngIf="!!item?.partnerCompany?.avatarFile?.thumbnail && !!host; else elseTemplatePartnerAvatar">
                                              <img class="partner_avatar" (error)="onPartnerImgError($event)" [src]="host + item?.partnerCompany?.avatarFile?.thumbnail + '?company_id=' + company?.id" alt="">
                                          </ng-container>
                                          <ng-template #elseTemplatePartnerAvatar>
                                              <img class="partner_avatar" (error)="onPartnerImgError($event)" src="{{ imgRoute }}assets/img/partner.png" alt="">
                                          </ng-template>
                                        </ng-container>
                                        <ng-template #elsePartner>
                                          <ng-container *ngIf="item?.employee?.image; else elseTemplatePhoto">
                                            <img [src]="item?.employee?.image">
                                          </ng-container>
                                          <ng-template #elseTemplatePhoto>
                                              <mat-icon>account_circle</mat-icon>
                                          </ng-template>
                                        </ng-template>
                                      </div>
                                    </div>
            
                                    <div class="status_type">
                                      <ng-container *ngIf="item.discussion_id; else elseTemplateType">
                                        {{ "Chat" | translate }}
                                      </ng-container>
                                      <ng-template #elseTemplateType>
                                        {{ "Work" | translate }}
                                      </ng-template>
                                    </div>
              
                                    <div class="inside_job" [ngClass]="{
                                      'first': item?.status_id == '1',
                                      'second': item?.status_id == '2',
                                      'third': item?.status_id == '3',
                                      'fours': item?.status_id == '4',
                                      'fives': item?.status_id == '5',
                                      'sixs': item?.status_id == '6',
                                      'nine': item?.status_id == '97',
                                      'seven': item?.status_id == '98',
                                      'eight': item?.status_id == '99'}">
                                      <span>{{ (getOperationStatusName(item?.status_id) | translate).length > 15 ? ((getOperationStatusName(item?.status_id) | translate) | slice:0:12) + "..." : (getOperationStatusName(item?.status_id) | translate) }}</span>  
                                    </div>
              
                                  </div>
                                  <div class="small_middle_info">
                                    <div>
                                      <div>{{ item.updated_at*1000 | date:'MMM dd. Y':'':activeLang | yearPipe }}</div>
                                      <div>{{ getTaskStatusById(item?.task?.status_id)?.name }}</div>
                                    </div>
                                    <div>
                                      <div>{{ item.created_at*1000 | date:'MMM dd. Y':'':activeLang | yearPipe }}</div>
                                      <div>{{item.id}}</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                    </ng-template>
                </div>
                <div class="lite_board_wrp lite_board_right" 
                [ngClass]="{'centred': target?.outputBoard?.done_is_load && target?.outputBoard?.done?.length == 0}" 
                [appDeviceClass]="'custom-scroll custom-scroll-t'"
                infinite-scroll
                [infiniteScrollDisabled]="target?.outputBoard?.done_is_load"
                [scrollWindow]="false"
                [infiniteScrollUpDistance]="scrollUpDistance"
                [infiniteScrollThrottle]="throttle"
                (scrolled)="onDownDone($event, target)"
                #doneContainer
                cdkDropList
                [cdkDropListData]="target?.outputBoard?.done"
                [id]="'done'"
                (cdkDropListDropped)="drop($event)">
                    <ng-container *ngIf="target?.outputBoard?.done_is_load && target?.outputBoard?.done?.length == 0; else elseTemplateLoadedDone">
                        <mat-spinner></mat-spinner>
                    </ng-container>
                    <ng-template #elseTemplateLoadedDone>
                        <div class="group_done" *ngFor="let group of target?.outputBoard?.groupedDone">
                            <div class="group_items">
                                <!-- <div class="board_item done_item" (click)="item.discussion_id ? openChat(item) : openJobItem(item)" *ngFor="let item of group.items" (cdkDragStarted)="startDrag($event)" (cdkDragEnded)="endDrag($event)" [cdkDragData]="item" cdkDrag>
                                    {{item.id}}
                                </div> -->
                              <div class="board_item done_item" (click)="item.discussion_id ? openChat(item) : openJobItem(item)" *ngFor="let item of group.items" (cdkDragStarted)="startDrag($event)" (cdkDragEnded)="endDrag($event)" [cdkDragData]="item" cdkDrag>
                                <div class="new_priority_line br-4" [ngClass]="{
                                  'Urgent': (!!item.discussion_id ? item.discussion.priority : item.taskOperation.priority) == '1000',
                                  'High': (!!item.discussion_id ? item.discussion.priority : item.taskOperation.priority) == '900',
                                  'Middle': (!!item.discussion_id ? item.discussion.priority : item.taskOperation.priority) == '800',
                                  'Low': (!!item.discussion_id ? item.discussion.priority : item.taskOperation.priority) == '700',
                                  'No': (!!item.discussion_id ? item.discussion.priority : item.taskOperation.priority) == '0' || !(!!item.discussion_id ? item.discussion.priority : item.taskOperation.priority)
                                }"></div>
                                <div class="board_btns" [ngClass]="{'show': is_mobile || boardCollection.isSelected(item)}">
              
                                  <div class="hover_item" style="margin-top: 3px;">
                                    <div class="hover_show">
                                      <button class="board_btn" (click)="$event.preventDefault; $event.stopPropagation()" ngxClipboard [cbContent]="!item?.discussion_id ? item?.taskOperation?.name : item?.discussion?.name" (cbOnSuccess)="copyName(!item?.discussion_id ? item?.taskOperation?.name : item?.discussion?.name)">
                                        <svg style="width: 14px; height: 16px;" width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M12.3636 14.5455H4.36364V4.36364H12.3636M12.3636 2.90909H4.36364C3.97787 2.90909 3.6079 3.06234 3.33512 3.33512C3.06234 3.6079 2.90909 3.97787 2.90909 4.36364V14.5455C2.90909 14.9312 3.06234 15.3012 3.33512 15.574C3.6079 15.8468 3.97787 16 4.36364 16H12.3636C12.7494 16 13.1194 15.8468 13.3922 15.574C13.6649 15.3012 13.8182 14.9312 13.8182 14.5455V4.36364C13.8182 3.97787 13.6649 3.6079 13.3922 3.33512C13.1194 3.06234 12.7494 2.90909 12.3636 2.90909ZM10.1818 0H1.45455C1.06878 0 0.698807 0.153246 0.426026 0.426027C0.153246 0.698807 0 1.06878 0 1.45455V11.6364H1.45455V1.45455H10.1818V0Z" fill="white"/>
                                        </svg>
                                      </button>
                                      <button class="board_btn" (click)="$event.preventDefault; $event.stopPropagation()" ngxClipboard [cbContent]="!item?.discussion_id ? (origin + '/job/' + item?.taskOperation.acm) : (origin + '/chat/' + item?.discussion.acm)" (cbOnSuccess)="copyLink(!item?.discussion_id ? 'Work' : 'Chat')">
                                        <svg style="width: 16px; height: 8px;" width="16" height="8" viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M12 0H8.8V1.6H12C13.32 1.6 14.4 2.68 14.4 4C14.4 5.32 13.32 6.4 12 6.4H8.8V8H12C14.208 8 16 6.208 16 4C16 1.792 14.208 0 12 0ZM7.2 6.4H4C2.68 6.4 1.6 5.32 1.6 4C1.6 2.68 2.68 1.6 4 1.6H7.2V0H4C1.792 0 0 1.792 0 4C0 6.208 1.792 8 4 8H7.2V6.4ZM4.8 3.2H11.2V4.8H4.8V3.2Z" fill="white"/>
                                        </svg>
                                      </button>
                                    </div>
              
                                    <div class="check_wrp white">
                                      <mat-checkbox style="color: #fff;" color="primary" class="example-margin" (click)="$event.stopPropagation()" (change)="$event ? toggleItem(item) : null" [checked]='boardCollection.isSelected(item)'></mat-checkbox>
                                    </div> 
                                  </div>
              
                                  <div class="hover_drag" (click)="$event.stopPropagation()" cdkDragHandle>
                                    <div>
                                      <span></span>
                                      <span></span>
                                      <span></span>
                                    </div>
                                  </div>
              
                                </div>
              
                                <!-- <div class="top">
                                  <div class="group">{{item?.task?.group?.name}}</div> 
                                  <span *ngIf="item?.task?.group?.name" class="slash">/</span>
                                  <div class="job_type">
                                    {{getColumnName(item?.taskOperation.operation_id)}}
                                  </div>
                                  <div class="job_title text_one_line" *ngIf="!!item?.discussion_id">{{ item?.taskOperation?.name }}</div>
                                  <span class="slash">/</span>
                                  <div class="task_name_wrp">
                                    <span class="task_name text_one_line">{{item?.task?.name}}</span>
                                    <span class="task_id" *ngIf="item?.task?.custom_id">{{item?.task?.custom_id}}</span>
                                  </div>
                                </div> -->
                                
                                <div class="middle">
                                  <div class="middle_name_wrp">
                                    <div class="type_name text_one_line">
                                      <ng-container *ngIf="item?.discussion_id; else elseTemplateItIsDiscussion">
                                        {{ item?.discussion?.name}}
                                      </ng-container>
                                      <ng-template #elseTemplateItIsDiscussion>
                                        <ng-container *ngIf="item?.taskOperation?.name; else elseTemplateNBSP">
                                          {{ item?.taskOperation?.name }}
                                        </ng-container>
                                      </ng-template>
                                    </div>
                                    <div class="d-f-c" style="flex-wrap: wrap;">
                                      <div class="empl_line">
                                        <div class="employees" *ngIf="!liteFilter.operation_employee_id">
                                          <div class="employee">
                                            <ng-container *ngIf="item?.is_partner; else elsePartner">
                                              <ng-container *ngIf="!!item?.partnerCompany?.avatarFile?.thumbnail && !!host; else elseTemplatePartnerAvatar">
                                                  <img class="partner_avatar" (error)="onPartnerImgError($event)" [src]="host + item?.partnerCompany?.avatarFile?.thumbnail + '?company_id=' + company?.id" alt="">
                                              </ng-container>
                                              <ng-template #elseTemplatePartnerAvatar>
                                                  <img class="partner_avatar" (error)="onPartnerImgError($event)" src="{{ imgRoute }}assets/img/partner.png" alt="">
                                              </ng-template>
                                            </ng-container>
                                            <ng-template #elsePartner>
                                              <ng-container *ngIf="item?.employee?.image; else elseTemplatePhoto">
                                                <img [src]="item?.employee?.image">
                                              </ng-container>
                                              <ng-template #elseTemplatePhoto>
                                                  <mat-icon>account_circle</mat-icon>
                                              </ng-template>
                                            </ng-template>
                                          </div>
                                        </div>
                
                                        <div class="status_type">
                                          <ng-container *ngIf="item.discussion_id; else elseTemplateType">
                                            {{ "Chat" | translate }}
                                          </ng-container>
                                          <ng-template #elseTemplateType>
                                            {{ "Work" | translate }}
                                          </ng-template>
                                        </div>
                  
                                        <div class="inside_job" [ngClass]="{
                                          'first': item?.status_id == '1',
                                          'second': item?.status_id == '2',
                                          'third': item?.status_id == '3',
                                          'fours': item?.status_id == '4',
                                          'fives': item?.status_id == '5',
                                          'sixs': item?.status_id == '6',
                                          'nine': item?.status_id == '97',
                                          'seven': item?.status_id == '98',
                                          'eight': item?.status_id == '99'}">
                                          <span>{{ (getOperationStatusName(item?.status_id) | translate).length > 15 ? ((getOperationStatusName(item?.status_id) | translate) | slice:0:12) + "..." : (getOperationStatusName(item?.status_id) | translate) }}</span>  
                                        </div>
                  
                                      </div>
                                      <div class="small_middle_info">
                                        <div>
                                          <div>{{ item.updated_at*1000 | date:'MMM dd. Y':'':activeLang | yearPipe }}</div>
                                          <div>{{ getTaskStatusById(item?.task?.status_id)?.name }}</div>
                                        </div>
                                        <div>
                                          <div>{{ item.created_at*1000 | date:'MMM dd. Y':'':activeLang | yearPipe }}</div>
                                          <div>{{item.id}}</div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="group_date">
                              <div class="date">
                                <ng-container *ngIf="group.timestamp == today; else elseIsToday">
                                  {{ "Today" | translate }}
                                </ng-container>
                                <ng-template #elseIsToday>
                                  <ng-container *ngIf="today - group.timestamp == 86400; else elseIsYesterday">
                                    {{ "Yesterday" | translate }}
                                  </ng-container>
                                  <ng-template #elseIsYesterday>
                                    {{ group.timestamp*1000 | date:'MMM dd. Y':'':activeLang | yearPipe }}
                                  </ng-template>
                                </ng-template>
                              </div>
                              <div class="week_day">
                                {{ group.timestamp*1000 | date:'EEEE':'':activeLang }}
                              </div>
                            </div>
                          </div>
                    </ng-template>
                </div>
            </div>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                {{ "Date" | translate }}
            </ng-template>
            <!-- <ng-container [ngTemplateOutlet]="ChatsTemplate" [ngTemplateOutletContext]="{ target: target, index: chatsIndex.value }"></ng-container> -->
            date
        </mat-tab>
    </mat-tab-group>
</section>

<!-- <ng-template #ChatsTemplate let-target="target" let-index="index">
    <div class="lite_chats_content" [ngClass]="{'centred': target?.outputBoard?.is_load && target?.outputBoard?.chats?.length == 0}" [appDeviceClass]="'custom-scroll custom-scroll-t'"
    infinite-scroll
    [infiniteScrollDisabled]="target?.outputBoard?.is_load"
    [scrollWindow]="false"
    [infiniteScrollUpDistance]="scrollUpDistance"
    [infiniteScrollThrottle]="throttle"
    (scrolled)="onDownChats($event, target)">     
        <ng-container *ngIf="target?.outputBoard?.is_load && target?.outputBoard?.chats?.length == 0; else elseTemplateLoadedDone">
            <mat-spinner></mat-spinner>
        </ng-container>
        <ng-template #elseTemplateLoadedDone>
            <ng-container *ngFor="let item of target?.outputBoard?.chats">
                <div class="chat" [ngClass]="{'todo_chat': [1,2,5].includes(item.status_id), 'done_chat': [3,4,6,97,98,99].includes(item.status_id)}" (click)="goToChat(item, target)">
                    <div class="chat_name text_one_line">{{item.name}}</div>
                    <div class="to_board_btn" (click)="$event.preventDefault(); $event.stopPropagation(); addToBoard(item)">
                        <svg style="width: 12px; height: 14px" width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M10.8 12L6 16.8H9.6V24H12V16.8H15.6L10.8 12ZM19.2 2.4H18V0H15.6V2.4H6V0H3.6V2.4H2.4C1.76348 2.4 1.15303 2.65286 0.702944 3.10294C0.252856 3.55303 0 4.16348 0 4.8V21.6C0 22.2365 0.252856 22.847 0.702944 23.2971C1.15303 23.7471 1.76348 24 2.4 24H7.2V21.6H2.4V8.4H19.2V21.6H14.4V24H19.2C19.8365 24 20.447 23.7471 20.8971 23.2971C21.3471 22.847 21.6 22.2365 21.6 21.6V4.8C21.6 4.16348 21.3471 3.55303 20.8971 3.10294C20.447 2.65286 19.8365 2.4 19.2 2.4Z" fill="#219653"/>
                        </svg>
                    </div>
                </div>
            </ng-container>
        </ng-template>
    </div>
</ng-template> -->