<div class="assistant_page">
    <div class="container assistant">
        <div class="ass_nav">
            <div class="title">{{"Assistant" | translate}}</div>
            <div class="text">{{"We will guide you through every steps." | translate}}</div>

            <div class="steps_nav">
                <div (click)="goToIndex(1)" class="step_nav cp" [ngClass]="{'done': index > 1, 'active': index == 1}">
                    <svg *ngIf="index == 1" style="width: 18px; height: 18px;" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.9" y="0.9" width="16.2" height="16.2" rx="8.1" fill="#E5ECFA"/>
                        <rect x="0.9" y="0.9" width="16.2" height="16.2" rx="8.1" stroke="#467EE5" stroke-width="1.8"/>
                    </svg>
                    <svg *ngIf="index != 1" style="width: 18px; height: 18px;" width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="1.5" y="1.5" width="16" height="16" rx="8" fill="#467EE5"/>
                        <rect x="1.5" y="1.5" width="16" height="16" rx="8" stroke="#467EE5" stroke-width="2"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.23672 12.7195L13.9166 7.10356C14.0934 6.89143 14.0647 6.57614 13.8526 6.39936L13.0848 5.75952C12.8727 5.58274 12.5574 5.61139 12.3806 5.8235L8.40072 10.5985L6.52927 8.72617C6.33402 8.53083 6.01738 8.53079 5.82209 8.72609L5.11527 9.4329C4.92001 9.62816 4.92001 9.94474 5.11527 10.14L7.76172 12.7865L8.14861 13.1733C8.3565 13.3812 8.698 13.3658 8.88624 13.1399L9.23672 12.7195Z" fill="#FAFAFA"/>
                    </svg>               
                    <span>{{"Get Posts" | translate}}</span>
                </div>
                <div (click)="goToIndex(2)" class="step_nav" [ngClass]="{'done': index > 2, 'active': index == 2, 'cp': index >= 2}">         
                    <svg *ngIf="index == 2" style="width: 18px; height: 18px;" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.9" y="0.9" width="16.2" height="16.2" rx="8.1" fill="#E5ECFA"/>
                        <rect x="0.9" y="0.9" width="16.2" height="16.2" rx="8.1" stroke="#467EE5" stroke-width="1.8"/>
                    </svg>
                    <svg *ngIf="index > 2" style="width: 18px; height: 18px;" width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="1.5" y="1.5" width="16" height="16" rx="8" fill="#467EE5"/>
                        <rect x="1.5" y="1.5" width="16" height="16" rx="8" stroke="#467EE5" stroke-width="2"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.23672 12.7195L13.9166 7.10356C14.0934 6.89143 14.0647 6.57614 13.8526 6.39936L13.0848 5.75952C12.8727 5.58274 12.5574 5.61139 12.3806 5.8235L8.40072 10.5985L6.52927 8.72617C6.33402 8.53083 6.01738 8.53079 5.82209 8.72609L5.11527 9.4329C4.92001 9.62816 4.92001 9.94474 5.11527 10.14L7.76172 12.7865L8.14861 13.1733C8.3565 13.3812 8.698 13.3658 8.88624 13.1399L9.23672 12.7195Z" fill="#FAFAFA"/>
                    </svg>  
                    <svg *ngIf="index < 2" style="width: 18px; height: 18px;" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.9" y="0.9" width="16.2" height="16.2" rx="8.1" stroke="#BDBDBD" stroke-width="1.8"/>
                    </svg> 
                    <span>{{"Create Cards" | translate}}</span>
                </div>
                <!-- <div (click)="goToIndex(3)" class="step_nav" [ngClass]="{'done': index > 3, 'active': index == 3, 'cp': index >= 3}">
                    <svg *ngIf="index == 3" style="width: 18px; height: 18px;" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.9" y="0.9" width="16.2" height="16.2" rx="8.1" fill="#E5ECFA"/>
                        <rect x="0.9" y="0.9" width="16.2" height="16.2" rx="8.1" stroke="#467EE5" stroke-width="1.8"/>
                    </svg>
                    <svg *ngIf="index > 3" style="width: 18px; height: 18px;" width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="1.5" y="1.5" width="16" height="16" rx="8" fill="#467EE5"/>
                        <rect x="1.5" y="1.5" width="16" height="16" rx="8" stroke="#467EE5" stroke-width="2"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.23672 12.7195L13.9166 7.10356C14.0934 6.89143 14.0647 6.57614 13.8526 6.39936L13.0848 5.75952C12.8727 5.58274 12.5574 5.61139 12.3806 5.8235L8.40072 10.5985L6.52927 8.72617C6.33402 8.53083 6.01738 8.53079 5.82209 8.72609L5.11527 9.4329C4.92001 9.62816 4.92001 9.94474 5.11527 10.14L7.76172 12.7865L8.14861 13.1733C8.3565 13.3812 8.698 13.3658 8.88624 13.1399L9.23672 12.7195Z" fill="#FAFAFA"/>
                    </svg>  
                    <svg *ngIf="index < 3" style="width: 18px; height: 18px;" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.9" y="0.9" width="16.2" height="16.2" rx="8.1" stroke="#BDBDBD" stroke-width="1.8"/>
                    </svg>
                    <span>{{"Create Jobs" | translate}}</span>
                </div>
                <div (click)="goToIndex(4)" class="step_nav" [ngClass]="{'done': index > 4, 'active': index == 4, 'cp': index >= 4}">
                    <svg *ngIf="index == 4" style="width: 18px; height: 18px;" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.9" y="0.9" width="16.2" height="16.2" rx="8.1" fill="#E5ECFA"/>
                        <rect x="0.9" y="0.9" width="16.2" height="16.2" rx="8.1" stroke="#467EE5" stroke-width="1.8"/>
                    </svg>
                    <svg *ngIf="index > 4" style="width: 18px; height: 18px;" width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="1.5" y="1.5" width="16" height="16" rx="8" fill="#467EE5"/>
                        <rect x="1.5" y="1.5" width="16" height="16" rx="8" stroke="#467EE5" stroke-width="2"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.23672 12.7195L13.9166 7.10356C14.0934 6.89143 14.0647 6.57614 13.8526 6.39936L13.0848 5.75952C12.8727 5.58274 12.5574 5.61139 12.3806 5.8235L8.40072 10.5985L6.52927 8.72617C6.33402 8.53083 6.01738 8.53079 5.82209 8.72609L5.11527 9.4329C4.92001 9.62816 4.92001 9.94474 5.11527 10.14L7.76172 12.7865L8.14861 13.1733C8.3565 13.3812 8.698 13.3658 8.88624 13.1399L9.23672 12.7195Z" fill="#FAFAFA"/>
                    </svg>  
                    <svg *ngIf="index < 4" style="width: 18px; height: 18px;" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.9" y="0.9" width="16.2" height="16.2" rx="8.1" stroke="#BDBDBD" stroke-width="1.8"/>
                    </svg>
                    <span>{{"Download Files" | translate}}</span>
                </div> -->
            </div>
        </div>
        <div class="ass_right" [ngClass]="{'not_scrolled': [3,4,5].includes(index)}" [appDeviceClass]="'custom-scroll custom-scroll-t'">
            <div class="ass_body">
                <ng-container [ngSwitch]="index">
                    <ng-container *ngSwitchCase="1">
                        <div class="title">
                            {{"Getting posts" | translate}}
                        
                            <div class="d-f-c" style="padding-bottom: 10px;">
                                <button class="mr-1" mat-icon-button (click)="getVideos()">
                                    <mat-icon [ngClass]="{'rotateble': isLoadVideos}">refresh</mat-icon>
                                </button>
                                <app-update-channel-btn *ngIf="outlet" (upClick)="updateVideos(outlet)" [data]="outlet"></app-update-channel-btn>
                            </div>
                        </div>
                        <div class="lists_wrp">
                            <div class="job_status_wrp">
                              <button mat-button (click)='changeActiveStatus("")' [ngClass]="{'active_status': activeStatus === ''}">{{ "All" | translate }}</button>
                              <button mat-button (click)='changeActiveStatus("isset")' [ngClass]="{'active_status': activeStatus === 'isset'}">{{ "Assigned" | translate }}</button>
                              <button mat-button (click)='changeActiveStatus(0)' [ngClass]="{'active_status': activeStatus === 0}">{{ "Not assigned" | translate }}</button>
                            </div>
                
                            <!-- <div class="job_status_wrp">
                                <button mat-button (click)='changeTypeStatus("")' [ngClass]="{'active_status': typeStatus === ''}">{{ "All" | translate }}</button>
                                <button *ngFor="let item of contentTypes" mat-button (click)='changeTypeStatus(item.id)' [ngClass]="{'active_status': typeStatus === item.id}">{{ item.name | translate }}</button>
                            </div> -->
                            <div class="counters">
                                <span>{{ "Items" | translate }}:</span>
                                <span>{{ videos?.length }}</span>
                            </div>
                        </div>
                        <div class="videos">
                            <!-- [ngClass]="{'checked': videosCollection.isSelected(video)}" -->
                            <div class="video" *ngFor="let video of videos" >
                                <div class="video_thumb">
                                    <!-- [ngClass]="{'show': videosCollection.isSelected(video)}" -->
                                    <!-- <div class="check_wrp">
                                        <mat-checkbox color="primary" class="example-margin" (click)="$event.stopPropagation()" (change)="toggleVideo(video)" [checked]='videosCollection.isSelected(video)'></mat-checkbox>
                                    </div> -->
                                    <ng-container *ngIf="!!video.file && !!video.file.original; else elseIMGTemplate">
                                        <img class="video_preview_img" [lazyLoad]="host + (video.file.thumbnail ? video.file.thumbnail : video.file.original) + '?company_id=' + company_id" style="object-fit: cover;" alt="">
                                    </ng-container>
                                    <ng-template #elseIMGTemplate>
                                        <ng-container *ngIf="video?.content_image; else elseIMGTemplateInside">
                                            <img class="video_preview_img" [lazyLoad]="video?.content_image" style="object-fit: cover;" alt="">
                                        </ng-container>
                                        <ng-template #elseIMGTemplateInside>
                                            <img class="video_preview_img" [lazyLoad]="imgRoute+'assets/img/no-image.jpg'" style="object-fit: cover;" alt="">
                                        </ng-template>
                                    </ng-template>
                                    <!-- <img [src]="video.thumbnail" alt=""> -->
                                </div>
                                <div class="video_info">
                                    <div class="video_info_left">
                                        <div class="name"><span class="text_one_line">{{video?.content_name ? video?.content_name : "Without content name"}}</span> <a [href]="video.content_url" target="_blank"><mat-icon>open_in_new</mat-icon></a></div>
                                        <div class="filename" *ngIf="!!video.content_filename">{{video?.content_filename}}</div>
                                        <div class="video_tasks">
                                            <div class="video_task" *ngIf="video.task" (click)="openTask(video.task)">
                                                <div class="task_name">
                                                    <div class="case_ids">
                                                        <div class="case_c_id">{{video.task?.custom_id}} </div>
                                                        <div class="case_id">{{video.task?.id}}</div>
                                                    </div>
                                                    <span class="text_one_line">
                                                        {{ video.task?.name }}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="video_info_right">
                                        <div class="id">
                                            <b class="mr-5">{{ "ID" | translate }}:</b>{{video?.id}}
                                        </div>
                                        <div class="status">
                                            <b class="mr-5">{{ "Status" | translate }}:</b>{{ getStatusById(video?.content_status_id)?.name+'_profile' | translate }}
                                        </div>
                                        <div class="published">
                                            <b class="mr-5">{{ "Published at" | translate }}:</b>
                                            <ng-container *ngIf="video?.content_published_at > today && video?.content_published_at < todayEnd; else publishedAtTempl">
                                                <span class="date" #tooltip="matTooltip"
                                                  matTooltip="{{ video?.content_published_at*1000 | date:'MMM d, y':'':activeLang | yearPipe }}"
                                                  [matTooltipPosition]="'above'">
                                                  {{ video?.content_published_at*1000 | date:'hh:mm a':'':activeLang }}
                                                </span>
                                              </ng-container>
                                              <ng-template #publishedAtTempl>
                                                <span class="date" *ngIf="video?.content_published_at && video?.content_published_at > 30000" #tooltip="matTooltip"
                                                  matTooltip="{{ video?.content_published_at*1000 | date:'hh:mm a':'':activeLang | yearPipe }}"
                                                  [matTooltipPosition]="'above'">
                                                  {{ video?.content_published_at*1000 | date:'MM.dd.yy':'': activeLang }}
                                                </span>
                                              </ng-template>
                                        </div>
                                        <div class="view_count">
                                            <div class="d-f-c" style="justify-content: flex-end;">
                                                <mat-icon>play_arrow</mat-icon>
                                                {{ video?.content_views_count | number:'':'fr-FR' }}
                                            </div>
                                        </div>
                                    </div>
                
                                </div>
                                
                            </div>
                            
                        </div>
                        <div class="btns_line">
                            <div></div>
                            <div class="d-f-c">
                                <button *ngIf="!!getNotAssigned().length" mat-raised-button (click)="goToCreateCards()">
                                    {{"Next" | translate}}
                                </button>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="2">
                        <div class="title" *ngIf="crCardIndex == 1">{{"Posts not assigned to cards" | translate}}</div>
                        <div class="title" *ngIf="crCardIndex == 2">{{"Create Cards" | translate}}</div>
                        <div class="title" *ngIf="crCardIndex == 3">{{"Create Cards Progress" | translate}}</div>
                        <div class="lists_wrp" *ngIf="crCardIndex == 1">
                            <!-- <div class="job_status_wrp">
                              <button mat-button (click)='changeActiveStatus("")' [ngClass]="{'active_status': activeStatus === ''}">{{ "All" | translate }}</button>
                              <button mat-button (click)='changeActiveStatus("isset")' [ngClass]="{'active_status': activeStatus === 'isset'}">{{ "Assigned" | translate }}</button>
                              <button mat-button (click)='changeActiveStatus(0)' [ngClass]="{'active_status': activeStatus === 0}">{{ "Not assigned" | translate }}</button>
                            </div> -->
                
                            <div class="job_status_wrp">
                                <button mat-button (click)='changeTypeStatus("")' [ngClass]="{'active_status': typeStatus === ''}">{{ "All" | translate }}</button>
                                <button *ngFor="let item of contentTypes" mat-button (click)='changeTypeStatus(item.id)' [ngClass]="{'active_status': typeStatus === item.id}">{{ item.name | translate }}</button>
                            </div>
                            <div class="counters">
                                <span>{{ "Items" | translate }}:</span>
                                <span>{{ videos?.length }}</span>
                            </div>
                        </div>
                        <ng-container [ngSwitch]="crCardIndex">
                            <ng-container *ngSwitchCase="1">               
                                <div class="videos">
                                    <!-- [ngClass]="{'checked': videosCollection.isSelected(video)}" -->
                                    <div class="video" *ngFor="let video of videos" >
                                        <div class="video_thumb">
                                            <!-- [ngClass]="{'show': videosCollection.isSelected(video)}" -->
                                            <!-- <div class="check_wrp">
                                                <mat-checkbox color="primary" class="example-margin" (click)="$event.stopPropagation()" (change)="toggleVideo(video)" [checked]='videosCollection.isSelected(video)'></mat-checkbox>
                                            </div> -->
                                            <ng-container *ngIf="!!video.file && !!video.file.original; else elseIMGTemplate">
                                                <img class="video_preview_img" [lazyLoad]="host + (video.file.thumbnail ? video.file.thumbnail : video.file.original) + '?company_id=' + company_id" style="object-fit: cover;" alt="">
                                            </ng-container>
                                            <ng-template #elseIMGTemplate>
                                                <ng-container *ngIf="video?.content_image; else elseIMGTemplateInside">
                                                    <img class="video_preview_img" [lazyLoad]="video?.content_image" style="object-fit: cover;" alt="">
                                                </ng-container>
                                                <ng-template #elseIMGTemplateInside>
                                                    <img class="video_preview_img" [lazyLoad]="imgRoute+'assets/img/no-image.jpg'" style="object-fit: cover;" alt="">
                                                </ng-template>
                                            </ng-template>
                                            <!-- <img [src]="video.thumbnail" alt=""> -->
                                        </div>
                                        <div class="video_info">
                                            <div class="video_info_left">
                                                <div class="name"><span class="text_one_line">{{video?.content_name ? video?.content_name : "Without content name"}}</span> <a [href]="video.content_url" target="_blank"><mat-icon>open_in_new</mat-icon></a></div>
                                                <div class="filename" *ngIf="!!video.content_filename">{{video?.content_filename}}</div>
                                                <div class="video_tasks">
                                                    <div class="video_task" *ngIf="video.task" (click)="openTask(video.task)">
                                                        <div class="task_name">
                                                            <div class="case_ids">
                                                                <div class="case_c_id">{{video.task?.custom_id}} </div>
                                                                <div class="case_id">{{video.task?.id}}</div>
                                                            </div>
                                                            <span class="text_one_line">
                                                                {{ video.task?.name }}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="video_info_right">
                                                <div class="id">
                                                    <b class="mr-5">{{ "ID" | translate }}:</b>{{video?.id}}
                                                </div>
                                                <div class="status">
                                                    <b class="mr-5">{{ "Status" | translate }}:</b>{{ getStatusById(video?.content_status_id)?.name+'_profile' | translate }}
                                                </div>
                                                <div class="published">
                                                    <b class="mr-5">{{ "Published at" | translate }}:</b>
                                                    <ng-container *ngIf="video?.content_published_at > today && video?.content_published_at < todayEnd; else publishedAtTempl">
                                                        <span class="date" #tooltip="matTooltip"
                                                          matTooltip="{{ video?.content_published_at*1000 | date:'MMM d, y':'':activeLang | yearPipe }}"
                                                          [matTooltipPosition]="'above'">
                                                          {{ video?.content_published_at*1000 | date:'hh:mm a':'':activeLang }}
                                                        </span>
                                                      </ng-container>
                                                      <ng-template #publishedAtTempl>
                                                        <span class="date" *ngIf="video?.content_published_at && video?.content_published_at > 30000" #tooltip="matTooltip"
                                                          matTooltip="{{ video?.content_published_at*1000 | date:'hh:mm a':'':activeLang | yearPipe }}"
                                                          [matTooltipPosition]="'above'">
                                                          {{ video?.content_published_at*1000 | date:'MM.dd.yy':'': activeLang }}
                                                        </span>
                                                      </ng-template>
                                                </div>
                                                <div class="view_count">
                                                    <div class="d-f-c" style="justify-content: flex-end;">
                                                        <mat-icon>play_arrow</mat-icon>
                                                        {{ video?.content_views_count | number:'':'fr-FR' }}
                                                    </div>
                                                </div>
                                            </div>
                        
                                        </div>
                                        
                                    </div>
                                    
                                </div>
                            </ng-container>
                            <ng-container *ngSwitchCase="2">
                                <form [formGroup]="form">
                                    <mat-form-field appearance="standard" class="full_width_form">
                                        <mat-label>{{ "Name" | translate }}</mat-label>
                                        <mat-select formControlName="name">
                                            <mat-option [value]="'{video_name}'">
                                                {{ "Video title" | translate }}
                                            </mat-option>
                                            <mat-option [value]="'{video_filename}'">
                                                {{ "File name" | translate }}
                                            </mat-option>
                                            <mat-option [value]="'{video_name} {video_filename}'">
                                                {{ "Video title + file name" | translate }}
                                            </mat-option>
                                            <mat-option [value]="'{video_filename} {video_name}'">
                                                {{ "File name + video title" | translate }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>

                                    <mat-form-field appearance="standard" class="full_width_form">
                                        <mat-label>{{ "Priority" | translate }}</mat-label>
                                        <mat-select [disableOptionCentering]="true" selectDir formControlName="priority" (selectionChange)="selectedJobsChange($event, 'priority')">
                                            <mat-option *ngFor="let priority of prioritys" [value]="priority.id">
                                                {{ priority.name | translate }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>

                                    <mat-form-field appearance="standard" class="full_width_form">
                                        <mat-label>{{ "Type" | translate }}</mat-label>
                                        <mat-select formControlName="status_id">
                                            <mat-option>
                                                <ngx-mat-select-search 
                                                placeholderLabel="{{ 'Search' | translate }}" 
                                                noEntriesFoundLabel="{{ 'no matching type found' | translate }}"
                                                [formControl]="statusesMoreControl"></ngx-mat-select-search>
                                            </mat-option>
                                            <mat-option *ngFor="let status of statuses$ | async" [value]="status.id">
                                                {{ status.name | translate }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>

                                    <mat-form-field appearance="standard" class="full_width_form">
                                        <mat-label>{{ "Group" | translate }}</mat-label>
                                        <mat-select formControlName="group_id">
                                            <mat-option>
                                                <ngx-mat-select-search 
                                                placeholderLabel="{{ 'Search' | translate }}" 
                                                noEntriesFoundLabel="{{ 'no matching group found' | translate }}"
                                                [formControl]="groupMoreControl"></ngx-mat-select-search>
                                            </mat-option>
                                            <mat-option *ngFor="let group of groups$ | async" [value]="group.id">
                                                {{ group.name | translate }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </form>
                            </ng-container>
                            <ng-container *ngSwitchCase="3">
                                <div class="cc__progres_wrp">
                                    <div class="cards_progress_wrp">
                                        <mat-progress-bar
                                            class="cards_progress"
                                            [color]="'primary'"
                                            [mode]="'determinate'"
                                            [value]="result.progress">
                                        </mat-progress-bar>

                                        <span>{{result.progress}}%</span>
                                    </div>

                                </div>
                                <!-- <pre>
                                    {{result | json}}
                                </pre> -->
                            </ng-container>
                        </ng-container>
                        <div class="btns_line">
                            <div></div>
                            <div class="d-f-c">
                                <button class="ml-1" *ngIf="crCardIndex == 2" mat-raised-button (click)="crCardsBack()">
                                    {{"Back" | translate}}
                                </button>

                                <ng-container [ngSwitch]="crCardIndex">
                                    <ng-container *ngSwitchCase="1">   
                                        <button class="ml-1" mat-raised-button color="primary" (click)="crCardsNext()">
                                            {{"Create Cards" | translate}}
                                        </button> 
                                    </ng-container>
                                    <ng-container *ngSwitchCase="2"> 
                                        <button class="ml-1" mat-raised-button color="primary" (click)="launchCardsCr()">
                                            {{"Launch Cards Creation" | translate}}
                                        </button>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="3"> 
                                        <button *ngIf="result && result.progress == 100" class="ml-1" mat-raised-button color="primary" (click)="goToCards()">
                                            {{"Go To Cards" | translate}}
                                        </button>
                                    </ng-container>
                                </ng-container>
                            
                                <!-- <button class="ml-1" mat-raised-button color="primary" (click)="createTasksForAll()">
                                    {{"Continue" | translate}}
                                </button> -->
                            </div>
                        </div>
                    </ng-container>
                    <!-- <ng-container *ngSwitchCase="3">
                        <div class="title">{{(outlet_id == 'new' ? "Create outlet" : "Select outlet to connect with") | translate}} {{getExpChanById(this.channel_id)?.name}}</div>
                        <div class="items with_border" *ngIf="outlet_id != 'new'">
                            <div class="items_title">
                                <span>{{"Outlets" | translate}}</span>

                                <div class="create_btn" (click)="addProfile($event)">
                                    <svg style="width: 10px; height: 10px;" width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.95833 5.71695C9.19831 5.71695 9.39285 5.52241 9.39285 5.28244V4.71783C9.39285 4.47786 9.19831 4.28332 8.95833 4.28332H6.21695V1.54194C6.21695 1.30196 6.02241 1.10742 5.78244 1.10742H5.21783C4.97786 1.10742 4.78332 1.30196 4.78332 1.54194V4.28332H2.04194C1.80196 4.28332 1.60742 4.47786 1.60742 4.71783V5.28244C1.60742 5.52241 1.80196 5.71695 2.04194 5.71695H4.78332V8.45833C4.78332 8.69831 4.97786 8.89285 5.21783 8.89285H5.78244C6.02241 8.89285 6.21695 8.69831 6.21695 8.45833V5.71695H8.95833Z" fill="#467EE5" stroke="#467EE5" stroke-width="0.375"/>
                                    </svg>
                                    {{"Create New" | translate}}
                                </div>
                            </div>
                            <div class="search">
                                <mat-form-field appearance="legacy" class="full_width_form">
                                    <mat-label>{{ 'Search' | translate }}</mat-label>
                                    <input class="search_inp" [formControl]="profilesMoreControl" matInput>
                                    <mat-icon matSuffix>search</mat-icon>
                                </mat-form-field>
                            </div>
                            <div class="items_in" [appDeviceClass]="'custom-scroll custom-scroll-t'">
                                <ng-container *ngFor="let item of profiles$ | async">
                                    <div (click)="outlet_id = item.id" [ngClass]="{'active': outlet_id == item.id}" class="item" style="justify-content: space-between;">
                                        <app-outlet-line [channel]="item" [company_id]="company_id"></app-outlet-line>
                                        <svg [ngClass]="{'not_active_sync': !item?.channelAccount || (item?.channelAccount && !item?.channelAccount?.is_active)}" *ngIf="!!item?.channel_account_id" style="width: 14px; height: 10px;" width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.25 2.5L8.75 5H10.625C10.625 5.99456 10.2299 6.94839 9.52665 7.65165C8.82339 8.35491 7.86956 8.75 6.875 8.75C6.25 8.75 5.64375 8.59375 5.125 8.3125L4.2125 9.225C4.98125 9.7125 5.89375 10 6.875 10C8.20108 10 9.47285 9.47322 10.4105 8.53553C11.3482 7.59785 11.875 6.32608 11.875 5H13.75L11.25 2.5ZM3.125 5C3.125 4.00544 3.52009 3.05161 4.22335 2.34835C4.92661 1.64509 5.88044 1.25 6.875 1.25C7.5 1.25 8.10625 1.40625 8.625 1.6875L9.5375 0.775C8.76875 0.2875 7.85625 0 6.875 0C5.54892 0 4.27715 0.526784 3.33947 1.46447C2.40178 2.40215 1.875 3.67392 1.875 5H0L2.5 7.5L5 5" fill="#1877F2"></path></svg>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                        <form *ngIf="outlet_id == 'new'" [formGroup]="createOutlet" class="form_with_pads form_with_valids">
                            <mat-form-field appearance="standard" class="full_width_form">
                                <mat-label>{{ 'Name' | translate }}</mat-label>
                                <input formControlName="name" matInput placeholder="{{ 'Name' | translate }}">
                            </mat-form-field>

                            <mat-form-field appearance="standard" class="full_width_form">
                                <mat-label>{{ 'AI description' | translate }}</mat-label>
                                <textarea 
                                cdkTextareaAutosize
                                #autosize="cdkTextareaAutosize"
                                cdkAutosizeMinRows="2"
                                cdkAutosizeMaxRows="6" formControlName="ai_description" matInput></textarea>
                                <mat-hint class="char_counter_hint" *ngIf="createOutlet.get('ai_description').value" [ngClass]="{'red': createOutlet.get('ai_description').value.length > 999999999}" align="end">{{createOutlet.get('ai_description').value.length}}</mat-hint>
                            </mat-form-field>
                
                            <mat-form-field appearance="standard" class="full_width_form">
                                <mat-label>{{ 'Link to the platform' | translate }}</mat-label>
                                <input formControlName="url" matInput placeholder="{{ 'Link to the platform' | translate }}">
                            </mat-form-field>
                
                            <ng-container *ngIf="createOutlet.get('publishing_params')">
                                <ng-container formGroupName="publishing_params">
                                    <div class="box_wrp" style="height: 40px;">
                                        <mat-checkbox formControlName="made_for_kids" color="primary">{{"Made for kids" | translate}}</mat-checkbox>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </form>
                        <div class="btns_line">
                            <div></div>
                            <div class="d-f-c">
                                <button *ngIf="!!outlet_id && outlet_id != 'new'" mat-raised-button color="primary" (click)="selectOutlet(outlet_id)">
                                    {{"Continue" | translate}}
                                </button>
                                <button class="d-f-btn" *ngIf="!!outlet_id && outlet_id == 'new' && createOutlet.valid" mat-raised-button color="primary" (click)="submitOutlet()" [disabled]="isSubmitOutlet">
                                    <mat-spinner class="spin_16 mr-1" *ngIf="isSubmitOutlet"></mat-spinner>  
                                    {{"Create" | translate}}
                                </button>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="4">
                        <div class="title">{{"Select group to connect with" | translate}} {{getExpChanById(this.channel_id)?.name}}</div>
                        <div class="items with_border">
                            <div class="items_title">
                                <span>{{"Groups" | translate}}</span>

                                <div class="create_btn" (click)="addGroup($event)">
                                    <svg style="width: 10px; height: 10px;" width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.95833 5.71695C9.19831 5.71695 9.39285 5.52241 9.39285 5.28244V4.71783C9.39285 4.47786 9.19831 4.28332 8.95833 4.28332H6.21695V1.54194C6.21695 1.30196 6.02241 1.10742 5.78244 1.10742H5.21783C4.97786 1.10742 4.78332 1.30196 4.78332 1.54194V4.28332H2.04194C1.80196 4.28332 1.60742 4.47786 1.60742 4.71783V5.28244C1.60742 5.52241 1.80196 5.71695 2.04194 5.71695H4.78332V8.45833C4.78332 8.69831 4.97786 8.89285 5.21783 8.89285H5.78244C6.02241 8.89285 6.21695 8.69831 6.21695 8.45833V5.71695H8.95833Z" fill="#467EE5" stroke="#467EE5" stroke-width="0.375"/>
                                    </svg>
                                    {{"Create New" | translate}}
                                </div>
                            </div>
                            <div class="search">
                                <mat-form-field appearance="legacy" class="full_width_form">
                                    <mat-label>{{ 'Search' | translate }}</mat-label>
                                    <input class="search_inp" [formControl]="groupsControl" matInput>
                                    <mat-icon matSuffix>search</mat-icon>
                                </mat-form-field>
                            </div>
                            <div class="items_in" [appDeviceClass]="'custom-scroll custom-scroll-t'">
                                <ng-container *ngFor="let item of groups$ | async">
                                    <div (click)="setGroup(item.id)" [ngClass]="{'active': isActiveGroup(item.id)}" class="item">
                                        <span style="margin: 0; width: 220px;" class="text_one_line">
                                            {{item?.name}}
                                        </span>
                                        <ng-container *ngFor="let ctype of contentTypes">
                                            <span class="type" (click)="setType($event, ctype.id, item.id)" [ngClass]="{'active': isActiveGroup(item.id) && groupTypes(item.id).includes(ctype.id), 'pulse': isActiveGroup(item.id) && !isCorrectGroup(item.id) && alertTypes}" *ngIf="ctype.id != 203">{{ctype.name}}</span>
                                        </ng-container>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                        <div class="btns_line">
                            <div></div>
                            <div class="d-f-c">
                                <button *ngIf="hasGroups()" mat-raised-button color="primary" (click)="selectGroup()">
                                    {{"Continue" | translate}}
                                </button>
                            </div>
                        </div>
                    </ng-container> -->
                </ng-container>
            </div>
       
        </div>
    </div>
</div>