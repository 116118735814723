<mat-dialog-content class="mat-typography" dirEnter (dirSubmit)="!form.valid || isSubmit ? null : onSubmit()">
    <div class="container form_wrapper">
        <div class="title_modal">
            {{ "Add assistant" | translate }}
        </div>
        <form [formGroup]="form" class="form_with_pads form_with_valids">

            <div class="users_select" [ngClass]="{'req_value': !form.get('employee_id').valid}">
                <label>{{ "Choose an executor" | translate }}: </label>
                <mat-chip-list [multiple]="false" selectable formControlName="employee_id">
                    <mat-chip style="padding: 0 !important;" class="chip_user clear" (click)="toggleChip({id: 0})" [value]="0">
                        <mat-icon style="opacity: .7;">close</mat-icon>
                    </mat-chip>
                    <ng-container *ngFor="let employee of employees; let i = index">
                        <mat-chip class="chip_user" (click)="toggleChip(employee)" [value]="employee.id">
                            <app-user [class]="'user_div'" [employee]="employee"></app-user>
                        </mat-chip>
                        <mat-chip *ngIf="i == employees.length - 1 && i > 6" class="chip_user more" (click)="employeeAddAdditionalContainer.open()">
                            {{ "more" | translate }}
                        </mat-chip>
                    </ng-container>
                </mat-chip-list>
            </div>

            <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                <mat-select #employeeAddAdditionalContainer formControlName="employee_id" (selectionChange)="toggleChip($event)">
                    <mat-option>
                        <ngx-mat-select-search 
                        placeholderLabel="{{ 'Search' | translate }}" 
                        noEntriesFoundLabel="{{ 'no matching executor found' | translate }}"
                        [formControl]="employeeMoreControl"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let employee of employees$ | async" [value]="employee.id">
                        <app-user [class]="'user_div'" [employee]="employee"></app-user>
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <div class="users_select" *ngIf="partners?.length > 0 && !!form.get('partner_company_id')">
                <label>{{ "Choose a partner" | translate }}: </label>
                <mat-chip-list [multiple]="false" selectable formControlName="partner_company_id">
                    <ng-container *ngFor="let partner of partners; let i = index">
                        <mat-chip class="chip_user" (click)="togglePartnerChip(partner)" [value]="partner?.partner_company_id">
                            <app-user [class]="'user_div'" [companyEmployee]="partner?.partnerCompany" [imgRoute]="imgRoute" [host]="host" [company]="data?.company"></app-user>
                        </mat-chip>
                        <mat-chip *ngIf="i == partners.length - 1 && i > 6" class="chip_user more" (click)="partnerAddAdditionalContainer.open()">
                            {{ "more" | translate }}
                        </mat-chip>
                    </ng-container>
                </mat-chip-list>
            </div>

            <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                <mat-select #partnerAddAdditionalContainer formControlName="partner_company_id" (selectionChange)="togglePartnerChip($event)">
                    <mat-option>
                        <ngx-mat-select-search 
                        placeholderLabel="{{ 'Search' | translate }}" 
                        noEntriesFoundLabel="{{ 'no matching partner found' | translate }}"
                        [formControl]="partnerMoreControl"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let partner of partners$ | async" [value]="partner?.partner_company_id">
                        <app-user [class]="'user_div'" [employee]="partner" [imgRoute]="imgRoute" [host]="host" [company]="data?.company"></app-user>
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <div class="small_chips" *ngIf="operationStatuses?.length > 0">
                <ng-container *ngIf="!form.get('status_id').valid && submited">
                    <div class="req_field"></div>
                    <div class="req_label">
                        <div class="angle"></div>
                        <span>
                            {{ "You need to choose a value" | translate }}
                        </span>
                    </div>
                </ng-container>
                <label>{{ "Status" | translate }}: </label>
                <mat-chip-list [multiple]="false" selectable formControlName="status_id">
                    <ng-container *ngFor="let status of operationStatuses">
                        <mat-chip class="small_chip" (click)="selectStatus(status)" [value]="status?.id" [ngClass]="{
                            'first': status.id == '1',
                            'second': status.id == '2',
                            'third': status.id == '3',
                            'fours': status.id == '4',
                            'fives': status.id == '5',
                            'sixs': status.id == '6',
                            'nine': status.id == '97',
                            'seven': status.id == '98',
                            'eight': status.id == '99'}">
                            {{ status.name | translate }}
                        </mat-chip>
                    </ng-container>
                </mat-chip-list>
            </div>
            
            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ 'Executor notes (available only to owners, admins, managers and author of notes)' | translate }}</mat-label>
                <textarea formControlName="employee_comment" matInput placeholder="{{ 'Executor notes (available only to owners, admins, managers and author of notes)' | translate }}"
                cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="5"></textarea>
            </mat-form-field>
    
            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ "Date the job was performed" | translate }}</mat-label>
                <input matInput [matDatepicker]="dp" formControlName="completed_at">
                <mat-datepicker-toggle matPrefix [for]="dp"></mat-datepicker-toggle>
                <mat-datepicker #dp></mat-datepicker>
            </mat-form-field>
    
            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ "Choose a currency" | translate }}</mat-label>
                <mat-select formControlName="price_currency_id" >
                <mat-option *ngFor="let currency of currencyList" [value]="currency.id">
                    {{ currency.name }}
                </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field *ngIf="form.get('rate')" appearance="standard" class="full_width_form">
                <mat-label>{{ 'Points value for job performance' | translate }}</mat-label>
                <input formControlName="rate" matInput type="number" placeholder="{{ 'Points value for job performance' | translate }}">
                <div class="cur_suffix" matSuffix>PTS</div>
            </mat-form-field>
    
            <mat-form-field appearance="standard" class="full_width_form" *ngIf="form.get('price')">
                <mat-label>{{ 'Price to be charged by executor' | translate }}</mat-label>
                <input formControlName="price" matInput type="number" placeholder="{{ 'Price to be charged by executor' | translate }}">
                <div class="cur_suffix" *ngIf="form && !!form.get('employee_id').value && !!getEmployeeById(form.get('employee_id').value)?.currency" matSuffix>{{ getEmployeeById(form.get('employee_id').value)?.currency?.iso_name }}</div>
            </mat-form-field>

            <p *ngIf="data?.discussion">
                {{ "Choose member role" | translate }}:
                <mat-button-toggle-group appearance="legacy" class="ml-1" formControlName="is_manager" [value]="form.get('is_manager').value">
                    <mat-button-toggle [value]="1">{{ "Executor" | translate }}</mat-button-toggle>
                    <mat-button-toggle [value]="0">{{ "Assistant" | translate }}</mat-button-toggle>
                </mat-button-toggle-group>
            </p>
        </form>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <div class="f_w_p_btns">
        <button mat-raised-button (click)="close()">
            {{ "Back" | translate }}
        </button>
        <button class="ml-1 d-f-btn" (click)="onSubmit()" mat-raised-button cdkFocusInitial color="primary" [disabled]="isSubmit">
            <mat-spinner class="spin_16 mr-1" *ngIf="isSubmit"></mat-spinner>  
            {{ "Add" | translate }}
        </button>
    </div>
</mat-dialog-actions>