import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class LoadingService {
  public requests$: Subject<any> = new Subject();
  constructor() { }

  getRequests() {
    return this.requests$.asObservable();
  }
}
