import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReplaySubject } from 'rxjs';
import { debounceTime, map, tap } from 'rxjs/operators';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { MembersService } from 'src/app/shared/services/rest/members.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';

@Component({
  selector: 'app-position-to-employee',
  templateUrl: './position-to-employee.component.html',
  styleUrls: ['./position-to-employee.component.scss']
})
export class PositionToEmployeeComponent extends BaseClass implements OnInit, OnDestroy {
  public form: FormGroup = this.fb.group({
    company_id: this.data.company.id,
    employee_id: this.data.employee.id,
    department_id: '',
    department_position_id: '',
    is_department_manage_prices: false,
    is_department_position_manage_prices: false,
    is_department_view: false,
    is_department_position_view: false
  });

  public positions: any[] = [];
  public positionsMoreControl: FormControl = new FormControl();
  public positions$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public positionsToEmployee: any;

  public departmentsPagination: any;
  public departmentsPage: number = 1;
  public departments: any[] = [];
  public departmentsMoreControl: FormControl = new FormControl();
  public departments$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public isSubmit: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<PositionToEmployeeComponent>,
    public layoutService: LayoutService,
    public taskService: TaskService,
    public companyService: CompanyService,
    public membersService: MembersService,
  ) { super() }

  ngOnInit(): void {
    console.log("PositionToEmployeeComponent", this.data)
    
    this.getDepartments(this.departmentsPage);
    this.getPositionsToEmployee({employee_id: this.data.employee.id});
  
    this.attachSubscriptions(
      this.form.get('department_id').valueChanges.subscribe(resp => {
        if (!!resp) {
          this.getPositions(resp)
        } else {
          this.positions = []
          this.positions$.next(this.positions.slice())
        }
      })
    )
    
    this.attachSubscriptions(
      this.positionsMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchPos(resp))
    )

    this.attachSubscriptions(
      this.departmentsMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchDep(resp))
    )
  }

  getPositions(id) {
    this.attachSubscriptions(
      this.companyService.getPositions(this.data.company.id, {department_id: id}).pipe(
        map(x => x.body)
      ).subscribe(resp => {
        this.positions = resp;
        this.positions$.next(this.positions.slice())
      })
    )
  }

  onSearchPos(resp) {
    if (!this.positions) {
      return;
    }

    if (!resp) {
      this.positions$.next(this.positions.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.positions$.next(
      this.positions.filter(z => z.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  getNextDepartments() {
    if (this.departmentsPagination.pageCount >= this.departmentsPage) {
      console.log("getNextdepartments")
      this.getDepartments(this.departmentsPage);
    }
  }

  onSearchDep(resp) {
    if (!this.departments) {
      return;
    }

    if (!resp) {
      this.departments$.next(this.departments.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }

    // filter the banks
    this.departments$.next(
      this.departments.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  getDepartments(page) {
    this.attachSubscriptions(
      this.companyService.getDepartments(this.data.company.id, page).pipe(
        tap(el => {
          this.departmentsPagination = {
            'pageCount': el.headers.get('x-pagination-page-count'),
            'perPage': el.headers.get('x-pagination-per-page'),
            'totalCount': el.headers.get('x-pagination-total-count'),
            'currentPage': el.headers.get('x-pagination-current-page'),
          }
        }),
        map(el => el.body)
      ).subscribe(resp => {
        console.log("getgroups", resp)
        resp.forEach(dep => {
          if (this.departments.filter(x => x.id == dep.id).length == 0) {
            this.departments.push(...resp);
          }
        })
        this.departments$.next(this.departments.slice());
        this.departmentsPage = this.departmentsPage + 1;
      })
    )
  }


  getPositionsToEmployee(filter) {
    this.attachSubscriptions(
      this.membersService.getPostionsToEmployee(this.data.company.id, filter).subscribe(resp => {
        console.log("getPostionsToEmployee", resp)
        this.positionsToEmployee = resp
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false});
  }

  onSubmit() {
    this.isSubmit = true;
    let sbmtData = {...this.form.value}
    sbmtData.is_department_view = !!sbmtData.is_department_view ? 1 : 0;
    sbmtData.is_department_position_view = !!sbmtData.is_department_position_view ? 1 : 0;
    sbmtData.is_department_manage_prices = !!sbmtData.is_department_manage_prices ? 1 : 0;
    sbmtData.is_department_position_manage_prices = !!sbmtData.is_department_position_manage_prices ? 1 : 0;

    this.attachSubscriptions(
      this.membersService.addPositionToEmployee(sbmtData).subscribe(resp => {
        this.isSubmit = false;
        this.form.patchValue({
          department_id: '',
          department_position_id: '',
          is_department_view: false,
          is_department_position_view: false,
          is_department_manage_prices: false,
          is_department_position_manage_prices: false
        })
        this.getPositionsToEmployee({employee_id: this.data.employee.id});
      }, error => {
        this.isSubmit = false;
        this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
      })
    )
  }

  editPosToEm(posToEm, key) {
    if (posToEm.isEditing) {
      return
    }
    posToEm.isEditing = true;
    this.attachSubscriptions(
      this.membersService.editPositionToEmployee(posToEm.id, {[key]: !!posToEm[key] ? 0 : 1}).subscribe(resp => {
        posToEm.is_department_view = resp.is_department_view;
        posToEm.is_department_position_view = resp.is_department_position_view;
        posToEm.is_department_manage_prices = resp.is_department_manage_prices;
        posToEm.is_department_position_manage_prices = resp.is_department_position_manage_prices;
        posToEm.isEditing = false;
      }, error => {
        this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
        posToEm.isEditing = false;
      })
    )
  }

  deletePositionToEmployee(id, i) {
    this.attachSubscriptions(
      this.membersService.deletePositionToEmployee(id).subscribe(resp => {
        this.positionsToEmployee.splice(i, 1)
      })
    )
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
