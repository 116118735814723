import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import * as moment from 'moment';
import { concat, forkJoin, fromEvent, of, ReplaySubject, Subject, Subscription, throwError } from 'rxjs';
import { concatMap, debounceTime, distinctUntilChanged, filter, flatMap, last, map, retry, switchMap, take, tap, toArray } from 'rxjs/operators';
import { DeleteAlertComponent } from 'src/app/shared/global_components/delete-alert/delete-alert.component';
import { NotificationsBar } from 'src/app/shared/global_components/notifications-bar/notifications-bar';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { AuthService } from 'src/app/shared/services/rest/auth.service';
import { ChatService } from 'src/app/shared/services/rest/chat.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import SwiperCore, { Pagination,  Mousewheel } from 'swiper/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { Overlay, OverlayRef, ViewportRuler } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { DateRange, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { casesModel } from 'src/app/shared/functions/casesModel';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { LoadingService } from 'src/app/shared/services/rest/loading.service';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { RefreshService } from 'src/app/shared/services/rest/refresh.service';
import { GlobalDataService } from 'src/app/shared/services/common/global-data.service';
import { MY_FORMATS } from '../../workspace-settings/finances/statements/statements.component';
import { ChatsComponent } from '../../chats/chats.component';
import { OperationChatComponent } from '../../atTasksDialog/operation-chat/operation-chat.component';
import { FileService } from 'src/app/shared/services/rest/file.service';
import { UploadService } from 'src/app/shared/services/rest/upload.service';
import { ParametersService } from 'src/app/shared/services/rest/parameters.service';
import { OpenJobComponent } from '../cases/dialogs/open-job/open-job.component';
import { OpenTaskComponent } from '../cases/dialogs/open-task/open-task.component';
import { EditPublishedAtComponent } from '../../atTasksDialog/profiles/edit-published-at/edit-published-at.component';
import { EditStatusComponent } from '../../atTasksDialog/profiles/edit-status/edit-status.component';
import { EditContentNameComponent } from '../../atTasksDialog/profiles/edit-content-name/edit-content-name.component';
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView } from 'angular-calendar';
import { endOfDay, endOfMonth, endOfWeek, isSameDay, isSameMonth, startOfDay, startOfMonth, startOfWeek } from 'date-fns';
import { AddAndEditScheduledPostComponent } from '../../workspace-settings/add-and-edit-scheduled-post/add-and-edit-scheduled-post.component';
import { AddTaskProfileComponent } from '../cases/dialogs/open-task/dialogs/add-task-profile/add-task-profile.component';
import { Moment } from 'moment';
import { UrlAnalyticsComponent } from '../cases/dialogs/open-task/dialogs/url-analytics/url-analytics.component';
import { AddChannelToTaskComponent } from '../../atTasksDialog/add-channel-to-task/add-channel-to-task.component';
import { MobUrlAnalyticsComponent } from '../cases/dialogs/open-task/dialogs/mob-url-analytics/mob-url-analytics.component';
import { environment } from 'src/environments/environment';
import { SaveFilterToBookmarkComponent } from 'src/app/shared/global_components/navigation-bar/dialogs/save-filter-to-bookmark/save-filter-to-bookmark.component';
import { CdkDrag, CdkDragEnter, CdkDragMove, CdkDropList, CdkDropListGroup, moveItemInArray } from '@angular/cdk/drag-drop';
SwiperCore.use([Mousewheel, Pagination]);



const colors: Record<string, any> = {
  1: {
    type: "Waiting",
    primary: '#2F80ED',
    secondary: '#BCD7FD',
  },
  2: {
    type: "Draft",
    primary: '#DB0000',
    secondary: '#FFAEAE',
  },
  3: {
    type: "Scheduled",
    primary: '#BEC11B',
    secondary: '#FEFFB9',
  },
  4: {
    type: "Published",
    primary: '#219653',
    secondary: '#98F1BD',
  },
  5: {
    type: "Canceled",
    primary: '#686868',
    secondary: '#E0E0E0',
  },
  6: {
    type: "content",
    primary: '#DD5E89',
    secondary: '#F7BB97',
  }
};


const users: any[] = [
  {
    id: 0,
    name: 'John smith',
    color: colors['1'],
  },
  {
    id: 1,
    name: 'Jane Doe',
    color: colors['4'],
  },
];

function getTimezoneOffsetString(date: Date): string {
  const timezoneOffset = date.getTimezoneOffset();
  const hoursOffset = String(
    Math.floor(Math.abs(timezoneOffset / 60))
  ).padStart(2, '0');
  const minutesOffset = String(Math.abs(timezoneOffset % 60)).padEnd(2, '0');
  const direction = timezoneOffset > 0 ? '-' : '+';

  return `T00:00:00${direction}${hoursOffset}:${minutesOffset}`;
}


// changeDetection: ChangeDetectionStrategy.OnPush,
@Component({
  selector: 'app-urls-dashboard',
  templateUrl: './urls-dashboard.component.html',
  styleUrls: ['./urls-dashboard.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: false } },
  ]
})
export class UrlsDashboardComponent extends BaseClass implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild("mobile_filter_wrp") mobile_filter_wrp: ElementRef;
  @ViewChild('partDragPlaceholder') partDragPlaceholder: ElementRef;
  @ViewChild('partPlaceholder') partPlaceholder: any;
  public target: CdkDropList;
  public targetIndex: number;
  public source: CdkDropList;
  public sourceIndex: number;

  public isActiveDashboard: boolean = true;

  public currDate = moment().minutes(0).seconds(0)
  modalData: {
    action: string;
    event: CalendarEvent;
  };
  public view: any[] = [134, 54];
  public colorTimeScheme = {
    domain: ['#2ea5cb']
  };
  // urls: CalendarEvent[] = 

  activeDayIsOpen: boolean = false;

  public host: any = environment.host;
  public imgRoute: any = '';
  public user: any = {};
  public user_id: number;
  public linkTo: string = '';
  public company: any;
  public me: any;
  public noPublishCount: number = 0;
  public currMonthName: any;
  public company_id: any;
  public activeLang: any;
  public operations: any;
  public operationsValues: any;
  public operationStatuses: any;
  public currencyes: any;
  public partnerGroups: any;

  public page: number = 1;
  public pagination: object = {};
  
  public spikesPage: number = 1;
  public spikesPagination: object = {};
  
  public contentTypePage: number = 1;
  public contentPage: number = 1;
  public contentTypePagination: any = {};
  public contentPagination: any = {};
  public profilesPagination: any;
  public profilesPage: number = 1;
  public isScrolledCollectionBtn: boolean = false;
  public collectionIsActive: boolean = false;
  public filter: FormGroup = this.fb.group({
    count: '',
    q: '',
    content_status_id: '',
    created_employee_id: '',
    content_type_id: '',
    is_content_views_count_update: '',
    is_bravourl_content_views_count_update: '',
    channel_id: '',
    group_id: '',
    platform_id: '',
    created_at_from : '',
    created_at_to : '',
    content_published_at_from : '',
    content_published_at_to : '',
    order_by: 'analytics_views_count_desc',
  });

  public topFilter: FormGroup = this.fb.group({
    analytics_id: 0,
    content_type_id: [[]],
    platform_id: [[]],
    channel_id: [[]]
  })

  public spikesFilter: FormGroup = this.fb.group({
    analytics_type_period: 'hour',
    analytics_id: 0,
    content_type_id: [[]],
    platform_id: [[]],
    channel_id: [[]],
    order_by: 'analytics_max_delta_desc'
  })

  public spikesOrders = [
    {
      value: "analytics_max_delta_desc",
      name: "Views Up"
    },
    {
      value: "analytics_max_percent_desc",
      name: "% Up"
    },
    {
      value: "analytics_max_delta",
      name: "Views Down"
    },
    {
      value: "analytics_max_percent",
      name: "% Drops"
    }
  ]
  public timeZone = new Date().getTimezoneOffset();
  public is_mobile: boolean = false;
  public urls: any[] = [];
  public spikes: any[] = [];
  public contents: any[] = [];
  public profileStatuses: any;
  public employees: any;
  public activeStatus: number = 0;
  public filterCount: number = 1;
  public profileCollection: any;
  public groups: any;
  public selectedCheck: any;
  public selectedJob: any;
  public sub: Subscription;
  public topChartSub: Subscription;
  public spikesChartSub: Subscription;
  public subFilter: Subscription;
  public subClientFilter: Subscription;
  public execFilterSub: Subscription;
  public urlsDataSub: Subscription;
  public mobScrollSub: Subscription;
  public scrollSub: Subscription;
  public taskStatuses: any;
  public allContentTypes: any;
  public partners: any;
  public summaryViews: any;
  public isLoad: boolean = false;
  public isSpikesLoad: boolean = false;
  public uploadLimit: boolean = false;
  public isMobSpikesOpen: boolean = false;
  public dateFromChanged: boolean = false;
  public dateToChanged: boolean = false;
  public dateFromCreatedChanged: boolean = false;
  public dateToCreatedChanged: boolean = false;
  public canAddNewTopPerforming: boolean = false;
  public switched: boolean = false;
  public urlsSub: Subscription;
  public contentSub: Subscription;
  public curYear = (new Date()).getFullYear()

  
  public createdDateRange = new DateRange("", "");
  public publishDateRange = new DateRange("", "");
  public viewsDateRange = new DateRange("", "");
  overlayDateRefFilter: OverlayRef | null;
  public dateFilterSub: Subscription;
  @ViewChild('filterDateMenu') filterDateMenu: TemplateRef<any>;
  
  public platforms: any;
  public platforms$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public platformsMoreControl: FormControl = new FormControl();

  public groupsControl: FormControl = new FormControl();
  public groups$: ReplaySubject<any> = new ReplaySubject<any>(1);
  
  public partnersControl: FormControl = new FormControl();
  public partners$: ReplaySubject<any> = new ReplaySubject<any>(1);
  
  public employees$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public execControl: FormControl = new FormControl();
  // public clientControl: FormControl = new FormControl();
  public selectedIndex: FormControl = new FormControl(0);

  viewDate: Date = new Date();
  
  public profiles: any[] = [];
  public profilesMoreControl: FormControl = new FormControl();
  public profiles$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public filters = [
    {
      id: 0,
      name: "Last 24 hours",
      divider: false,
      analytics_views_count_from: moment().subtract(24, 'h'),
      analytics_views_count_to: 0
    },
    {
      id: 1,
      name: "Last 48 hours",
      divider: false,
      analytics_views_count_from: moment().subtract(48, 'h'),
      analytics_views_count_to: 0
    },
    {
      id: 2,
      name: "Last 72 hours",
      divider: true,
      analytics_views_count_from: moment().subtract(72, 'h'),
      analytics_views_count_to: 0
    },
    {
      id: 3,
      name: "Last 7 days",
      divider: false,
      analytics_views_count_from: moment().subtract(7, 'd'),
      analytics_views_count_to: 0
    },
    {
      id: 4,
      name: "Last 30 days",
      divider: false,
      analytics_views_count_from: moment().subtract(30, 'd'),
      analytics_views_count_to: 0
    },
    {
      id: 5,
      name: "Last 90 days",
      divider: false,
      analytics_views_count_from: moment().subtract(90, 'd'),
      analytics_views_count_to: 0
    },
    {
      id: 6,
      name: "Last 365 days",
      divider: true,
      analytics_views_count_from: moment().subtract(365, 'd'),
      analytics_views_count_to: 0
    },
  ]

  public spikesFilters = [
    {
      id: 0,
      spikes_id: 0,
      name: "Last 3 days",
      divider: false,
      analytics_views_count_from: moment().subtract(3, 'd'),
      analytics_views_count_to: 0
    },
    {
      id: 1,
      spikes_id: 1,
      name: "Last 7 days",
      divider: false,
      analytics_views_count_from: moment().subtract(7, 'd'),
      analytics_views_count_to: 0
    },
    {
      id: 2,
      spikes_id: 2,
      name: "Last 30 days",
      divider: false,
      analytics_views_count_from: moment().subtract(30, 'd'),
      analytics_views_count_to: 0
    },
    {
      id: 3,
      spikes_id: 3,
      name: "Last 90 days",
      divider: false,
      analytics_views_count_from: moment().subtract(90, 'd'),
      analytics_views_count_to: 0
    },
    {
      id: 4,
      spikes_id: 4,
      name: "Last 365 days",
      divider: true,
      analytics_views_count_from: moment().subtract(365, 'd'),
      analytics_views_count_to: 0
    },
  ]
  overlayRefFilter: OverlayRef | null;
  // overlayRefClientFilter: OverlayRef | null;
  overlayExecRefFilter: OverlayRef | null;
  overlayRef: OverlayRef | null;
  // @ViewChild('filterMenu') filterMenu: TemplateRef<any>;
  // @ViewChild('filterClientMenu') filterClientMenu: TemplateRef<any>;
  // @ViewChild('filterExecMenu') filterExecMenu: TemplateRef<any>;
  @ViewChild('taskMenu') taskMenu: TemplateRef<any>;

  overlayTopPerformingRef: OverlayRef | null;
  public topPerformingOutletsSub: Subscription;
  @ViewChild('topPerformingOutletsMenu') topPerformingOutletsMenu: TemplateRef<any>;

  public totalInfo: object =  {
    'summaryRate': 0,
    'summaryPrice': [
      {
        currency_id: 0,
        summary: 0
      },
      {
        currency_id: 1,
        summary: 0
      },
      {
        currency_id: 2,
        summary: 0
      },
      {
        currency_id: 3,
        summary: 0
      },
      {
        currency_id: 4,
        summary: 0
      }
    ]
  }
  public contentUrl = [
    {
      name: marker("Without content url"),
      id: 0
    },
    {
      name: marker("With content url"),
      id: 1
    }
  ]
  public contentTypes: any[] = [];
  public contentTypeMoreControl: FormControl = new FormControl();
  public contentTypes$: ReplaySubject<any> = new ReplaySubject<any>(1);
  
  public groupedProfiles: any = [];
  public channelsSubsControl: FormControl = new FormControl(0);

  public bookmarks: any;

  constructor(
    private sm: StorageManagerService,
    private auth: AuthService,
    private router: Router,
    private chatService: ChatService,
    private globalDataService: GlobalDataService,
    public activatedRoute: ActivatedRoute,
    private taskService: TaskService,
    private companyService: CompanyService,
    private languageService: LanguageService,
    private parametersService: ParametersService,
    private layoutService: LayoutService,
    private fileService: FileService,
    private uploadService: UploadService,
    private refreshService: RefreshService,
    private _adapter: DateAdapter<any>,
    private viewportRuler: ViewportRuler,
    private dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private fb: FormBuilder,
    private bottomSheet: MatBottomSheet,
    public viewContainerRef: ViewContainerRef,
    public overlay: Overlay,
    private _dateAdapter: DateAdapter<Moment>,
    public ls: LoadingService,
    private cd: ChangeDetectorRef
  ) { super() }

  ngOnInit(): void {
    this.company_id = this.activatedRoute.snapshot.queryParamMap.get('company_id');
    this.checkIsMobile();

    this.addFilters();

    this.attachSubscriptions(
      this.topFilter.valueChanges.subscribe(fils => {
        console.log("topFilter.valueChanges", fils)
        if (fils.analytics_id != 0 || fils.channel_id.length != 0 || fils.content_type_id.length != 0 || fils.platform_id.length != 0) {
          this.canAddNewTopPerforming = true;
        } else {
          this.canAddNewTopPerforming = false;
        }
        // this.getTopDash(this.page);
      })
    )

    this.attachSubscriptions(
      this.refreshService.getRefreshBookmarks().subscribe(res => {
        if (res == "Post Dashboards") {
          this.getBookmarks()
        }
      })
    )

    this.getLangData(this.company_id);
    this.getProfilesStatus();
    this.getPlatforms();
    this.getImgRoute();
    this.getUser();
    this.getOperationsStatus();
    this.getTaskStatus();
    this.onRouteChange();
    this.refresh();



    this.attachSubscriptions(
      this.groupsControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchGroups(resp))
    )

    this.attachSubscriptions(
      this.contentTypeMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchContentTypes(resp))
    )

    this.attachSubscriptions(
      this.execControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchEmoloyees(resp))
    )
    
    this.attachSubscriptions(
      this.partnersControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchPartners(resp))
    )
    this.attachSubscriptions(
      this.profilesMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchProfiles(resp))
    )
    this.attachSubscriptions(
      this.platformsMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchPlatforms(resp))
    )

    // if (this.is_mobile) {
    //   this.attachSubscriptions(
    //     forkJoin([...this.task.channels.filter(x => x.external_content_id && x.content_published_at).map(channel => this.taskService.getContentAnlytics(this.company_id, {
    //       start_time_from: channel.content_published_at,
    //       period: 'day',
    //       totals: '1',
    //       order_by: 'start_time',
    //       'per-page': 1,
    //       content_id: channel.external_content_id,
    //     }, "1").pipe(
    //       tap(el => {
    //         channel.summaryAveragePercentageViewed = Math.round(+el.headers.get('x-summary-average-view-percentage'))
    //         channel.summaryAverageViewDuration = +el.headers.get('x-summary-average-view-duration')
    //       })
    //     ))]).subscribe(res => {
    //       console.log("this.task.channels", this.task.channels)
    //     })
    //   )
    // }
  }

  
  getBookmarks() {
    this.attachSubscriptions(
      this.companyService.getBookmarks(this.company_id, {employee_id: this.company.employees[0].id, section: "Post Dashboards"}).pipe(
        tap(arr => {
          arr.forEach(element => {
            element.is_load = false;
            element.page = 1;
            (element.pagination as any) = undefined;
            element.settings = {
              analytics_id: element.settings.analytics_id,
              content_type_id: !!element.settings.content_type_id ? element.settings.content_type_id.split(',').map(Number) : [],
              platform_id: !!element.settings.platform_id ? element.settings.platform_id.split(',').map(Number) : [],
              channel_id: !!element.settings.channel_id ? element.settings.channel_id.split(',').map(Number) : []
            }
            element.is_change = false;
          });
          this.bookmarks = arr;
        }),
        concatMap(arr => {
          return concat(...arr.map(bookmark => this.getBookmarkTopDash(bookmark))).pipe(last())
        }),
      ).subscribe(res => {
        console.log("bookmarks", this.bookmarks)
      })
    )
  }

  getBookmarkTopDashData(bookmark) {
    bookmark.is_load = true;
    bookmark.page = 1;
    bookmark.pagination = undefined;
    
    this.attachSubscriptions(
      of(null).pipe(
        switchMap(() => this.getBookmarkTopDash(bookmark))
      ).subscribe(resp => {
        console.log("getBookmarkTopDashData", resp)
        bookmark.is_load = false;
      })
    )
  }
  
  getBookmarkById(id) {
    if (!this.bookmarks || this.bookmarks.length == 0) {
      return null
    }
    return this.bookmarks.find(x => x.id == id)
  }
  
  addCustomDashboard(type) {
    if (type == 'top_performing') {
      let data = JSON.parse(JSON.stringify(this.topFilter.value))
      Object.keys(data).forEach(key => {
        if (key != 'analytics_id') {
          if (data[key].length == 0) {
            delete data[key]
          } else {
            data[key] = data[key].join(',')
          }
        }
      })
      this.saveFilterToBookmarks(type, data)
    }
  }

  saveFilterToBookmarks(type, values) {
    const dialogRef = this.dialog.open(SaveFilterToBookmarkComponent, {
      backdropClass: ['backdrop_under_header'],
      panelClass: !this.is_mobile ? ['show_header'] : '',
      hasBackdrop: true,
      data: {
        user: this.user,
        company: this.company,
        host: this.host,
        imgRoute: this.imgRoute,
        filter: this.filter,
        activeLang: this.activeLang,
        params: values,
        allContentTypes: this.allContentTypes,
        employees: this.employees,
        partners: this.partners,
        taskStatuses: this.taskStatuses,
        groups: this.groups,
        platforms: this.platforms,
        profiles: this.profiles,
        profileStatuses: this.profileStatuses, 
        contentUrl: this.contentUrl,
        filters: this.filters,
        type: type
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        
        if (!!result) {
          console.log("result", result)
        }
      })
    )
  }
  
  neededChartData(channel, type) {
    let arr = [];
    if (channel.external_content_id) {
      let filterData:any = {
        start_time_from: channel.content_published_at,
        totals: '1',
        order_by: 'start_time',
        'per-page': 1,
        content_id: channel.external_content_id,
      }
      if (type == 'top') {
        if (this.topFilter.value.analytics_id <= 2) {
          filterData.period = 'hour'
        } else {
          filterData.period = 'day'
        }
        filterData.start_time_from = this.filters[this.topFilter.value.analytics_id].analytics_views_count_from.clone().format("X");
        if (this.filters[this.topFilter.value.analytics_id].analytics_views_count_to) {
          filterData.start_time_to = moment(this.filters[this.topFilter.value.analytics_id].analytics_views_count_to).clone().format("X");
        }
      } else {
        if (this.spikesFilter.value.analytics_id < 1) {
          filterData.period = 'hour'
        } else {
          filterData.period = 'day'
        }
        filterData.start_time_from = this.spikesFilters[this.spikesFilter.value.analytics_id].analytics_views_count_from.clone().format("X");
        if (this.spikesFilters[this.spikesFilter.value.analytics_id].analytics_views_count_to) {
          filterData.start_time_to = moment(this.spikesFilters[this.spikesFilter.value.analytics_id].analytics_views_count_to).clone().format("X");
        }
      }

      arr.push(this.taskService.getContentAnlytics(this.company_id, filterData, "1").pipe(
        tap(el => {
          channel.averagePercentageViewed = Math.round(+el.headers.get('x-summary-average-view-percentage'))
          channel.averageViewDuration = +el.headers.get('x-summary-average-view-duration')
        })
      ))

    }
    // arr.push(
    //   this.taskService.getHoursChart(this.company_id, channel.id, {
    //     start_time_from: this.filters[1].analytics_views_count_from.clone().format("X"),
    //     period: 'hour',
    //     totals: '1',
    //     order_by: 'start_time'
    //   }).pipe(
    //     tap(x => {
          
    //       channel.maxChartVal = 0;
    //       channel.timeChartSumm = x.headers.get('x-summary-views')
    //       for (let index = 0; index < x.body.length; index++) {
    //         let element = x.body[index];
    //         element.value = element.views_count
            
    //         if (!!element.value && element.value > channel.maxChartVal) {
    //           channel.maxChartVal = element.value
    //         }
    //         element.date = moment(element.start_time * 1000).clone()
    //         if (moment(element.start_time * 1000).clone().isAfter(moment().hours(0).minutes(0).seconds(0)) || moment(element.start_time * 1000).clone().isSame(moment().hours(0).minutes(0).seconds(0))) {
    //           element.name = moment(element.start_time * 1000).clone().format("hh:mm a") + " \u2013 " + moment(element.end_time * 1000).clone().format("hh:mm a");
    //         } else if (moment(element.start_time * 1000).clone().year() == moment().year()) {
    //           element.name = moment(element.start_time * 1000).clone().format("MM/DD, hh:mm a") + " \u2013 " + moment(element.end_time * 1000).clone().format("hh:mm a");
    //         } else {
    //           element.name = moment(element.start_time * 1000).clone().format("MM/DD/YYYY, hh:mm a") + " \u2013 " + moment(element.end_time * 1000).clone().format("hh:mm a");
    //         }
    //       }
          
    //       let model = [];
  
    //       for (let i:number = 1; i <= 48; i++) {
    //         let date = moment().minutes(0).seconds(0).clone().subtract(48-i, 'h')
    //         if (x.body.filter(u => date.isSameOrAfter(moment(u.start_time * 1000)) && date.clone().subtract(1, 's').isSameOrBefore(moment(u.end_time * 1000))).length == 0) {
    //           let name = '';
    //           if (date.clone().isSameOrAfter(moment().hours(0).minutes(0).seconds(0))) {
    //             name = date.clone().format("hh:mm a") + " \u2013 " + date.clone().add(1,'h').subtract(1, 's').format("hh:mm a");
    //           } else if (date.clone().year() == moment().year()) {
    //             name = date.clone().format("MM/DD, hh:mm a") + " \u2013 " + date.clone().add(1,'h').subtract(1, 's').format("hh:mm a");
    //           } else {
    //             name = date.clone().format("MM/DD/YYYY, hh:mm a") + " \u2013 " + date.clone().add(1,'h').subtract(1, 's').format("hh:mm a");
    //           }
    //           model.push({
    //             index: i,
    //             hour: i - 48,
    //             date: date,
    //             name: name,
    //             value: 0
    //           })
    //         } else {
    //           model.push(x.body.find(u => date.isSameOrAfter(moment(u.start_time * 1000)) && date.clone().subtract(1, 's').isSameOrBefore(moment(u.end_time * 1000))))
    //         }
    //       }
    //       channel.timeChart = [...model]
    //       // channel.timeChart = [...x.body]
    //     }),
    //   )
    // )


    arr.push(
      this.taskService.getContentHistory(this.company_id, channel.id, {
        created_at_from: moment().subtract(4, 'd').startOf("day").format("X"),
        created_at_to: moment().endOf("day").format("X"),
      }).pipe(
        tap(vals => {
          let allHistory = JSON.parse(JSON.stringify(vals));
          if (!allHistory || allHistory.length == 0) {
            return 
          }
      
      
          channel.timeChartSumm_v1 = 0;
      
          let model = [];
      
          for (let i:number = 1; i <= 48; i++) {
            model.push({
              index: i,
              hour: i - 48,
              date: this.currDate.clone().subtract(48-i, 'h'),
              name: '',
              // name: this.currDate.clone().subtract(48-i, 'h').format("MM/DD/YYYY hh:mm a"),
              value: 0,
              arr: []
            })
          }
      
          let values = allHistory.slice();
      
          let modArrL = 0;
      
          model.forEach((item, ind) => {
            values.forEach(element => {
              if (ind == 0 && moment(element.created_at*1000).isBefore(item.date)) {
                item.arr.push(element)
              } else if (moment(element.created_at*1000).isSame(item.date) || (moment(element.created_at*1000).isAfter(item.date) && !!model[ind+1] && moment(element.created_at*1000).isBefore(model[ind+1].date))) {
                item.arr.push(element)
              } else if (moment(element.created_at*1000).isAfter(item.date) && !model[ind+1]) {
                item.arr.push(element)
                // console.log(item, ind, element)
              } 
            });
      
            item.arr.sort((a,b) => {
              return +a.content_views_count - +b.content_views_count;
            })
      
            let prevWithArrInd;
            let slicedModel:any = model.slice(0, ind)
            if (slicedModel.filter(x => x.arr.length).length > 0) {
              prevWithArrInd = slicedModel.indexOf(slicedModel.findLast(x => x.arr.length > 0))
              // console.log("LALA", ind, prevWithArrInd, slicedModel);
            }
            if (ind != 0 && item.arr && item.arr.length > 0 && prevWithArrInd != undefined && model[prevWithArrInd]) {
              // console.log(ind, prevWithArrInd);
              item.value = item.arr[item.arr.length - 1].content_views_count - model[prevWithArrInd].arr[model[prevWithArrInd].arr.length - 1].content_views_count
              if (item.value < 0) {
                item.value = 0
              }
            } else {
              item.value = 0
            }
      
            channel.timeChartSumm_v1 = +channel.timeChartSumm_v1 + +item.value
            modArrL = modArrL + item.arr.length
            if (item.date.isAfter(moment().hours(0).minutes(0).seconds(0)) || item.date.isSame(moment().hours(0).minutes(0).seconds(0))) {
              item.name = item.date.clone().format("hh:mm a") + " \u2013 " + item.date.clone().add(1, 'h').subtract(1, 's').format("hh:mm a");
            } else if (item.date.clone().year() == moment().year()) {
              item.name = item.date.clone().format("MM/DD, hh:mm a") + " \u2013 " + item.date.clone().add(1, 'h').subtract(1, 's').format("hh:mm a");
            } else {
              item.name = item.date.clone().format("MM/DD/YYYY, hh:mm a") + " \u2013 " + item.date.clone().add(1, 'h').subtract(1, 's').format("hh:mm a");
            }
          })
      
      
          // console.log("if we have only one hour data and this item has more then 1 arr data")
          // if we have only one hour data and this item has more then 1 arr data
          if (model.filter(x => x.arr.length > 1).length == 1 && model.filter(x => x.arr.length == 1).length == 0) {
            console.log("if we have only one hour data and this item has more then 1 arr data")
            let target = model.find(x => x.arr.length > 1)
            console.log("ONLY ONE Hour")
            target.value = target.arr[target.arr.length - 1].content_views_count - target.arr[0].content_views_count
          }
      
          channel.timeChartData = [...model]
      
          channel.timeChartDataMaxVal = Math.max(...channel.timeChartData.slice().map(x => x.value))
          
          console.log("timeChartData", channel, channel.timeChartData, channel.timeChartDataMaxVal)

      
          console.log("model", model)
          console.log("modArrL", modArrL)
        })
      )
    )

    return forkJoin(arr)
  }

  addFilters() {
    function monthsBetweenDates (startDate, endDate) {
      const now = startDate, dates = [];
        
      while (now.isSameOrBefore(endDate)) {
            dates.push(now.clone());
            now.add(1, 'months');
      }
      return dates;
    }
  
    const fromDate = moment().subtract(1, 'y').clone().startOf('month');
    const toDate = moment().endOf('month');
    const results = monthsBetweenDates(fromDate, toDate).reverse().slice(0,4);

    results.forEach((m, i) => {
      let x = {
        id: this.filters.length,
        spikes_id: this.spikesFilters.length,
        name: m.clone().format('MMMM'),
        // date: m.clone(),
        divider: false,
        analytics_views_count_from: m.clone().startOf("month"),
        analytics_views_count_to: m.clone().endOf("month"),
      }
      
      if (i == results.length - 1) {
        x.divider = true
      }

      this.filters.push(x);
      this.spikesFilters.push(x);
    })


    function yearsBetweenDates (startDate, endDate) {
      const now = startDate, years = [];
        
      while (now.isSameOrBefore(endDate)) {
            years.push(now.clone());
            now.add(1, 'year');
      }

      return years;
    }
    
    const fromDateYear = moment().subtract(6, 'y').clone().startOf('year');
    const toDateYear = moment().endOf('year');
    const resultsYear = yearsBetweenDates(fromDateYear, toDateYear).reverse().slice(0,4);

    resultsYear.forEach((y, i) => {
      let x = {
        id: this.filters.length,
        spikes_id: this.spikesFilters.length,
        name: y.clone().format('YYYY'),
        // date: y.clone(),
        divider: false,
        analytics_views_count_from: y.clone().startOf("year"),
        analytics_views_count_to: y.clone().endOf("year")
      }
      
      if (i == resultsYear.length - 1) {
        x.divider = true
      }

      this.filters.push(x);
      this.spikesFilters.push(x);
    })

  console.log("as____this.filters", this.filters)
  }

  showItemIcons(item, items) {
    if (!!item.showIcons) {
      items.map(x => {
        x.showIcons = false
      })
    } else {
      items.map(x => {
        x.showIcons = false
      })
  
      item.showIcons = true
    }
  }
  
  dayClicked({ date, events }: { date: Date; events: any[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  topChange(): void {
    this.page = 1;
    if (this.topChartSub) {
      this.topChartSub.unsubscribe()
    }
    this.topChartSub = this.taskService.getTopChartStream().pipe(
      concatMap(topsInPage => {
        return concat(...topsInPage.map(topInPage => this.neededChartData(topInPage, 'top'))).pipe(last(),map(x => topsInPage))
      }),
    ).subscribe(resp => console.log("-----getTopChartStream-----",resp));
    this.getTopDash(this.page);
  }

  getSpikesFilterById(id) {
    return this.spikesFilters.find(x => x.spikes_id == id)
  }

  getSpikesOrderByValue(val) {
    return this.spikesOrders.find(x => x.value == val)
  }

  spikesChange(): void {
    this.spikesPage = 1;
    if (this.spikesChartSub) {
      this.spikesChartSub.unsubscribe()
    }
    this.spikesChartSub = this.taskService.getSpikesChartStream().pipe(
      concatMap(topsInPage => {
        return concat(...topsInPage.map(topInPage => this.neededChartData(topInPage, 'spikes'))).pipe(last(),map(x => topsInPage))
      }),
    ).subscribe(resp => console.log("-----getSpikesChartStream-----",resp));
    this.getSpikes(this.spikesPage);
  }

  rangeChange(date: Moment, key, range): void {
    console.log(date);

    if (!this.filter.value[key+'from']) {
      // this.filter.value[key+'from'] = date;
      this.filter.patchValue({
        [key+'from']: date,
      })
    } else if (
      !this.filter.value[key+'to'] &&
      this._dateAdapter.compareDate(date.endOf('day'), this.filter.value[key+'from']) >= 0
    ) {
      this.filter.patchValue({
        [key+'to']: date.endOf('day'),
      })
    } else {
      this.filter.patchValue({
        [key+'from']: date,
        [key+'to']: null
      })
    }
    console.log("createdRangeChange", this.filter.value);

    this[range] = new DateRange(this.filter.value[key+'from'], this.filter.value[key+'to']);
  }

  onSearchContentTypes(resp) {
    if (!this.contentTypes) {
      return;
    }

    const contentTypesCopy = this.copyContentTypesGroups(this.contentTypes);
    if (!resp) {
      this.contentTypes$.next(contentTypesCopy);
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    // this.contentTypes$.next(
    //   this.contentTypes.filter(z => (z.name.toLowerCase().indexOf(resp) > -1))
    // );

    // filter the banks
    this.contentTypes$.next(
      contentTypesCopy.filter(type => {
        type.types = type.types.filter(t => t.name.toLowerCase().indexOf(resp) > -1);
        return type.types.length > 0;
      })
    );

    this.contentTypes$.asObservable().subscribe(res => {
      console.log("search", res)
      console.log(this.contentTypes)
    });
  }

  protected copyContentTypesGroups(contentTypes: any) {
    const contentTypesCopy = [];
    contentTypes.forEach(type => {
      contentTypesCopy.push({
        channel_platform_id: type.channel_platform_id,
        types: type.types.slice()
      });
    });
    return contentTypesCopy;
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    this.urls = this.urls.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  handleEvent(action: string, event: any): void {
    // this.modalData = { event, action };
    console.log(event);
    console.log(this.modalData)
    if (event.content_url) {
      window.open(event.content_url, '_blank')
    }
    // this.modal.open(this.modalContent, { size: 'lg' });
  }

  onSearchProfiles(resp) {
    if (!this.profiles) {
      return;
    }

    if (!resp) {
      this.profiles$.next(this.profiles.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }

    // filter the banks
    this.profiles$.next(
      this.profiles.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  onSearchPlatforms(resp) {
    if (!this.platforms) {
      return;
    }

    if (!resp) {
      this.platforms$.next(this.platforms.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }

    // filter the banks
    this.platforms$.next(
      this.platforms.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  onSearchPartners(resp) {
    if (!this.partners) {
      return;
    }

    if (!resp) {
      this.partners$.next(this.partners.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.partners$.next(
      this.partners.filter(z => z.partnerCompany.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  getProfilesStatus() {
    this.attachSubscriptions(
      this.globalDataService.getContentStatus().subscribe(resp => {
        console.log("getContentStatus", resp);
        this.profileStatuses = resp.slice();
      })
    )
  }

  onSearchEmoloyees(resp) {
    if (!this.employees) {
      return;
    }

    if (!resp) {
      this.employees$.next(this.employees.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.employees$.next(
      this.employees.filter(z => z.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  onSearchGroups(resp) {
    if (!this.groups) {
      return;
    }

    if (!resp) {
      this.groups$.next(this.groups.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }

    // filter the banks
    this.groups$.next(
      this.groups.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  changeTab(val) {
    this.selectedIndex.patchValue(val);
  }

  changeSelectionList(e, key) {
    console.log(e, key)
    console.log(e.option._value)

    this.filter.patchValue({
      [key]: e.option._value
    })
    // this.closeOpenClientFilter();
    // this.closeExecFilter();
    this.filterUrls();
  }

  refresh() {
    this.attachSubscriptions(
      this.refreshService.getRefreshUrls().pipe(
        concatMap(res => {
          if ( this.urls.filter(x => x.id == res.url_id).length > 0 && this.company_id == res.company_id ) {
            return this.taskService.getDashExpandUrls("1", this.company_id, {id: res.url_id}, "1").pipe(
              map(x => x.body[0]),
              // switchMap(x => this.neededData(x).pipe(map(() => casesModel([x], this.urls, 'update')),map(x => x.arr[0]))),
              tap(x => {
                this.urls.splice(this.urls.indexOf(this.urls.find(b => b.id == x.id)), 1, x)
              })
            )
          } else {
            return of(false)
          }    
        })
      ).subscribe(resp => {
        console.log("getRefreshUrls", resp)
      })
    )
  }

  // openCheck(job, check) {
  //   check.open = !check.open

  //   if (check.open) {
  //     this.attachSubscriptions(
  //       this.fileService.getFilesForCheck(this.company_id, job.id, check.id, '/ready_files', '200').subscribe(res => {
  //         check.files = res.body
  //         console.log(check.files)
  //       })
  //     )
  //   }
  // }

  
  // understandFileType(val) {
  //   if (!val) {
  //     return ""
  //   }
  //   if (val.indexOf("/") != -1) {
  //     return val.split('/')[0]
  //   } else {
  //     return ""
  //   }
  // }

  // deleteFile(file, check, ind) {
  //   let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
  //     hasBackdrop: true,
  //     backdropClass: 'bottom-sheed-backdrop',
  //     data: {
  //       msg: marker("Are you sure you want to unlink the file from this checklist item?")
  //     }
  //   });

  //   deleteAlert.afterDismissed().subscribe( data => {
  //     if (data && data.message == 'no') {
  //       console.log("no");
  //       return
  //     } else if (data && data.message == 'yes') {
  //       this.attachSubscriptions(
  //         this.fileService.editFile(file.id, {operation_reminder_id: '0'}, this.company.id).subscribe(resp => {
  //           check.files.splice(ind, 1)
  //         })
  //       )
  //     }
  //   });
  // }

  // onListSelectionChange(val: MatCheckboxChange, check, job) {
  //   check.disable = true;
  //   if (!check.status) {
  //     this.attachSubscriptions(
  //       this.taskService.createOperationReminderStatus(job.id, check.id).subscribe(resp => {
  //         check.status = resp;
  //         check.disable = false;
  //         console.log(check)
  //         // if (job.checklist.filter(x => x.status && x.status.is_done == 1).length == job.checklist.length || (job.checklist.length == 0 || !job.checklist)) {
  //         //   this.taskService.editWork(job.id, {is_reminder: 0}, this.company_id).subscribe(resp => {
  //         //     console.log(resp)
  //         //     job.is_reminder = resp.is_reminder
  //         //   })
  //         // }
  //       }, error => {
  //         delete check.status;
  //         val.source.toggle()
  //         this.layoutService.showSnackBar({name: ''}, marker("You do not have permission to do this. Error: " + error), SnackBarItem)
          
  //       })
  //     )
  //   } else {
  //     // if (job.checklist.filter(x => x.status && x.status.is_done == 1).length == job.checklist.length || (job.checklist.length == 0 || !job.checklist)) {
  //     //   this.taskService.editWork(job.id, {is_reminder: 1}, this.company_id).subscribe(resp => {
  //     //     console.log(resp)
  //     //     job.is_reminder = resp.is_reminder
  //     //   })
  //     // }
  //     this.attachSubscriptions(
  //       this.taskService.deleteOperationReminderStatus(check.status.id).subscribe(resp => {
  //         check.status = resp
  //         check.disable = false;
  //         console.log(check)
  //       })
  //     )
  //   }

  //   console.log("afterChange", job.checklist)
  // }

  // uploadFileBtn(job, check) {
  //   if (!check.hasOwnProperty('files')) {
  //     check.files = [];
  //   }
  //   this.selectedCheck = check
  //   this.selectedJob = job
  //   this.checkUploader.nativeElement.click()
  // }


  // uploadFile(event) {
  //   if (this.uploadLimit) {
  //     this.layoutService.showSnackBar({name: ''}, marker("This company has exceeded the file upload limit."), SnackBarItem)
  //     return
  //   }
  //   if (event.target.files.length > 0) {
  //     this.selectedCheck.filesCount = (this.selectedCheck.filesCount ? this.selectedCheck.filesCount : 0) + event.target.files.length
  //     for (let index = 0; index < event.target.files.length; index++) {
  //       const element = event.target.files[index];
  //       this.putFile(element, false, "/ready_files");
  //     }
  //   } 
  // }

  // putFile(file, is_folder:boolean = false, initPath:any = false) {
  //   let path = '/ready_files'

  //   this.fileService.uploadFile({
  //     company_id: this.company.id,
  //     task_id: this.selectedJob.task_id,
  //     task_operation_id: this.selectedJob.id,
  //     filesize: file.size,
  //     filename: file.name,
  //     operation_reminder_id: this.selectedCheck.id,
  //     content_type: file.type ? file.type : mimeTypes.getType(file.name),
  //     location: path,
  //     is_dir: 0
  //   }, this.company.id).subscribe(resp => {
  //     console.log("putFile", this.selectedCheck)
  //     this.fileService.files$.next({
  //       place: "file_manager",
  //       url: window.location.href,
  //       data: resp,
  //       files: this.selectedCheck.files,
  //       location: path,
  //       target: file,
  //       operation_reminder: this.selectedCheck,
  //       task_id: this.selectedJob.task_id,
  //       work_id: this.selectedJob.id,
  //       company_id: this.company.id,
  //       user: this.user
  //     })
  //     // this.selectedJob = undefined
  //   })
  // }

  // selectFilesFromJob(task, job, check) {
  //   const dialogRef = this.dialog.open(SelectFilesForCheckComponent, {
  //     data: {
  //       company: this.company,
  //       task: task,
  //       work: job,
  //       user: this.user,
  //       check: check,
  //     }
  //   });

  //   this.attachSubscriptions(
  //     dialogRef.afterClosed().pipe(
  //       switchMap(() => this.fileService.getFilesForCheck(this.company_id, job.id, check.id, '/ready_files', '200'))
  //     ).subscribe(result => {
  //       check.files = result.body
  //     })
  //   )
  // }

  // onFileDropped(e, check, job) {
  //   console.log("onFileDropped", e, check, job)

  //   this.getFilesDataTransferItems(e.dataTransfer.items).then((files:any) => {
  //     if (files.length > 0) {
  //       for (let index = 0; index < files.length; index++) {
  //         this.company.filesize = this.company.filesize + files[index].size
  //       }
  //     }
  //   })

  //   if (this.uploadLimit) {
  //     this.layoutService.showSnackBar({name: ''}, marker("This company has exceeded the file upload limit."), SnackBarItem)
  //     return
  //   }
  //   if (!e.dataTransfer.files.length) {
  //     return false;
  //   }

  //   this.selectedCheck = check;
  //   this.selectedJob = job;

  //   this.getFilesDataTransferItems(e.dataTransfer.items).then((files:any) => {
  //     if (files.length > 0) {
  //       for (let index = 0; index < files.length; index++) {
  //         this.putFile(files[index]);
  //       }
  //     }
  //   });
    
  //   if (this.company.filesize >= this.company.filesize_limit) {
  //     this.uploadService.uploadLimit$.next(true)
  //   }
  // }
  // getFilesDataTransferItems(dataTransferItems) {
  //   function traverseFileTreePromise(item, folder) {
  //     return new Promise(resolve => {
  //       if (item.isFile) {
  //         item.file(file => {
  //           file.filepath = item.fullPath.replace(`/${file.name}`, "");
  //           folder.push(file);
  //           resolve(file);
  //         });
  //       } else if (item.isDirectory) {
  //         let dirReader = item.createReader();
  //         dirReader.readEntries(entries => {
  //           let entriesPromises = [];
  //           for (let entr of entries)
  //             entriesPromises.push(
  //               traverseFileTreePromise(entr, folder)
  //             );
  //           resolve(Promise.all(entriesPromises));
  //         });
  //       }
  //     });
  //   }
  
  //   let files = [];
  //   return new Promise((resolve, reject) => {
  //     let entriesPromises = [];
  //     for (let it of dataTransferItems)
  //       entriesPromises.push(
  //         traverseFileTreePromise(it.webkitGetAsEntry(), files)
  //       );
  //     Promise.all(entriesPromises).then(entries => {
  //       resolve(files);
  //     });
  //   });
  // }

  // detectBrowserName() { 
  //   const agent = window.navigator.userAgent.toLowerCase()
  //   switch (true) {
  //     case agent.indexOf('edge') > -1:
  //       return 'edge';
  //     case agent.indexOf('opr') > -1 && !!(<any>window).opr:
  //       return 'opera';
  //     case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
  //       return 'chrome';
  //     case agent.indexOf('trident') > -1:
  //       return 'ie';
  //     case agent.indexOf('firefox') > -1:
  //       return 'firefox';
  //     case agent.indexOf('safari') > -1:
  //       return 'safari';
  //     default:
  //       return 'other';
  //   }
  // }

  neededData(task) {
    let arr = [
      ...task.operations.map(x => this.taskService.getOperationReminder(this.company_id, {task_operation_id: x.id}).pipe(
        tap(val => {
          let checklist = val.body;
          let jobParameters = task.parameterValuesToTask.filter(x => !!x.task_operation_id && !x.discussion_id && !x.file_id)
          x.checklist = [];
          checklist.forEach(element => {
            if (element.exceptParameterValues.length > 0) {
              if (element.exceptParameterValues.map(b => b.parameter_value_id).every(n => !jobParameters.map(m => m.parameter_value_id).includes(n))) {
                x.checklist.push(element)
              }
            } else if (element.toParameterValues.length > 0) {
              if (element.toParameterValues.map(b => b.parameter_value_id).some(n => jobParameters.map(m => m.parameter_value_id).includes(n))) {
                x.checklist.push(element)
              }
            } else {
              x.checklist.push(element)
            }
          });

          if (x.checklist.length == 0 && x.checklist.filter(b => !!b.status).length == x.checklist.length) {
            x.hasChecklistError = true;
          }
        })
      )),
      this.chatService.getTasksChats(this.company_id, [task.id]).pipe(
        tap(res => {
          task['operations'].map(o_el => {
            o_el['chatsInfo'] = [];
            o_el['chatsInfo'].push(...res.filter(z => z.task_operation_id == o_el.id));
            o_el['unReadChats'] = res.filter(z => z.task_operation_id == o_el.id && z.is_read != '1').length;
            o_el['unClosedChats'] = res.filter(z => z.task_operation_id == o_el.id && z.status_id != '4').length;
          });
          task['chatsInfo'] = [];
          task['chatsInfo'].push(...res.filter(z => z.task_id == task.id));
          task['unReadChats'] = res.filter(z => z.is_read != '1').length;
          task['unClosedChats'] = res.filter(z => z.status_id != '4').length;
        })
      ),
      ...task.operations.filter(x => !x.hasChecklistError).map(x => this.fileService.getFilesForOpenTask(this.company_id, x.id, '/ready_files', '6').pipe(
        tap(val => x.files = val.body)
      ))
    ]
    // if (task.company_id == this.company.id) {
    //   arr.push(
    //     this.taskService.getTaskClients(task.id).pipe(
    //       tap(res => {
    //         task.clients = res
    //       }),
    //       catchError(err => {
    //         return err
    //       })
    //     )
    //   )
    // }
    return forkJoin(arr)
  }

  onStartDateCreatedChange(e: MatDatepickerInputEvent<Date, Date>) {
    if (e && e.value) {
      this.dateFromCreatedChanged = true;
    }
    this.checkCreatedRange();
  }

  onEndDateCreatedChange(e: MatDatepickerInputEvent<Date, Date>) {
    if (e && e.value) {
      this.dateToCreatedChanged = true;
    }
    this.checkRange();
  }
  checkCreatedRange(): void {
    if (this.dateFromCreatedChanged || this.dateToCreatedChanged) {
      this.dateFromCreatedChanged = false;
      this.dateToCreatedChanged = false;
      this.filterUrls()
    }
  }

  onStartDateChange(e: MatDatepickerInputEvent<Date, Date>) {
    if (e && e.value) {
      this.dateFromChanged = true;
    }
    this.checkRange();
  }

  onEndDateChange(e: MatDatepickerInputEvent<Date, Date>) {
    if (e && e.value) {
      this.dateToChanged = true;
    }
    this.checkRange();
  }

  checkRange(): void {
    if (this.dateFromChanged || this.dateToChanged) {
      this.dateFromChanged = false;
      this.dateToChanged = false;
      this.filterUrls()
    }
  }

  copyLink(type) {
    this.layoutService.showSnackBar(marker(type + " link"), marker("Copied"), SnackBarItem)
  }

  checkIsManager(task, company, _user) { 
    return task.managers.filter(x => x.user_id == _user.id).length > 0 || task?.group?.managers.filter(x => x.user_id == _user.id).length > 0 || company?.permissions.includes('admin') || company?.permissions.includes('manager'); 
  }
  
  // noOperation(e, id, jobTypeSelect: MatSelect) {
  //   e.preventDefault();
  //   e.stopPropagation();
  //   this.filter.patchValue({
  //     no_operation_id: id,
  //   })

  //   jobTypeSelect.close();
  //   this.filterUrls()
  // }

  getContentTypes(platform_id, contentTypePage) {
    this.attachSubscriptions(
      this.companyService.getContentTypes(platform_id, contentTypePage).pipe(
        tap(el => {
          this.contentTypePagination = {
            'pageCount': el.headers.get('x-pagination-page-count'),
            'perPage': el.headers.get('x-pagination-per-page'),
            'totalCount': el.headers.get('x-pagination-total-count'),
            'currentPage': el.headers.get('x-pagination-current-page'),
          }
        }),
        map(el => el.body)
      ).subscribe(resp => {

        this.allContentTypes = resp;

        let arr = [];

        resp.forEach(el => {
          if (arr.filter(x => x.channel_platform_id == el.channel_platform_id).length == 0) {
            arr.push({
              channel_platform_id: el.channel_platform_id,
              types: [el]
            })
          } else {
            arr.find(x => x.channel_platform_id == el.channel_platform_id).types.push(el)
          }
        })

        this.contentTypes.push(...arr);
        this.contentTypes$.next(this.copyContentTypesGroups(this.contentTypes));
        this.contentTypePage = this.contentTypePage + 1;
        console.log("getContentTypes", this.contentTypePage, this.contentTypes)

        if (this.contentTypePagination.pageCount >= this.contentTypePage) {
          this.getContentTypes(platform_id, this.contentTypePage);
        }
      })
    )
  }

  logBook(book) {
    console.log(book)
  }

  removeMultiDashBookValue(book, key, value) {
    console.log(book)
    console.log(key)
    console.log(value)
    console.log(book.settings[key])

    if (book.settings[key].includes(value)) {
      book.settings[key].splice(book.settings[key].indexOf(value), 1)
    }
    console.log(book.settings[key])
    this.cd.detectChanges()
    book.is_change = true; 
    this.getBookmarkTopDashData(book)
  }

  removeMultiDashValue(dashFilter, key, value, type) {
    let arr = dashFilter.value[key];
    if (arr.includes(value)) {
      arr.splice(arr.indexOf(value), 1)
    }
    dashFilter.patchValue({
      [key]: arr
    })
    
    switch (type) {
      case 'top':
        this.page = 1;
        if (this.topChartSub) {
          this.topChartSub.unsubscribe()
        }
        this.topChartSub = this.taskService.getTopChartStream().pipe(
          concatMap(topsInPage => {
            return concat(...topsInPage.map(topInPage => this.neededChartData(topInPage, 'top'))).pipe(last(),map(x => topsInPage))
          }),
        ).subscribe(resp => console.log("-----getTopChartStream-----",resp));
        this.getTopDash(this.page)
        break;
      case 'spikes':
        this.spikesPage = 1;
        if (this.spikesChartSub) {
          this.spikesChartSub.unsubscribe()
        }
        this.spikesChartSub = this.taskService.getSpikesChartStream().pipe(
          concatMap(topsInPage => {
            return concat(...topsInPage.map(topInPage => this.neededChartData(topInPage, 'spikes'))).pipe(last(),map(x => topsInPage))
          }),
        ).subscribe(resp => console.log("-----getSpikesChartStream-----",resp));
        this.getSpikes(this.spikesPage)
        break;
    
      default:
        break;
    }
  }

  
  removeDashBookValue(book, val) {
    if (val == "all") {
      this.activeStatus = 0;
      book.settings = {
        analytics_id: 0,
        content_type_id: [],
        platform_id: [],
        channel_id: []
      }
    } else if (["content_type_id", "platform_id", "channel_id"].includes(val)) {
      book.settings[val] = []
    } else {
      book.settings[val] = []
    }
    this.getBookmarkTopDashData(book)
  }

  removeDashValue(dashFilter, val, type) {
    if (val == "all") {
      this.activeStatus = 0;
      dashFilter.patchValue({
        analytics_id: 0,
        content_type_id: [],
        platform_id: [],
        channel_id: []
      })
    } else if (["content_type_id", "platform_id", "channel_id"].includes(val)) {
      dashFilter.patchValue({
        [val]: []
      })
    } else {
      dashFilter.patchValue({
        [val]: ''
      })
    }

    switch (type) {
      case 'top':
        this.page = 1;
        if (this.topChartSub) {
          this.topChartSub.unsubscribe()
        }
        this.topChartSub = this.taskService.getTopChartStream().pipe(
          concatMap(topsInPage => {
            return concat(...topsInPage.map(topInPage => this.neededChartData(topInPage, 'top'))).pipe(last(),map(x => topsInPage))
          }),
        ).subscribe(resp => console.log("-----getTopChartStream-----",resp));
        this.getTopDash(this.page)
        break;
      case 'spikes':
        this.spikesPage = 1;
        if (this.spikesChartSub) {
          this.spikesChartSub.unsubscribe()
        }
        this.spikesChartSub = this.taskService.getSpikesChartStream().pipe(
          concatMap(topsInPage => {
            return concat(...topsInPage.map(topInPage => this.neededChartData(topInPage, 'spikes'))).pipe(last(),map(x => topsInPage))
          }),
        ).subscribe(resp => console.log("-----getSpikesChartStream-----",resp));
        this.getSpikes(this.spikesPage)
        break;
    
      default:
        break;
    }
  }


  removeValue(val) {
    if (val == "all") {
      this.activeStatus = 0;
      this.filter.patchValue({
        count: '',
        q: '',
        content_status_id: '',
        created_employee_id: '',
        channel_id: '',
        content_type_id: '',
        is_content_views_count_update: '',
        is_bravourl_content_views_count_update: '',
        group_id: '',
        platform_id: '',
        created_at_from : '',
        created_at_to : '',
        analytics_views_count_from : '',
        analytics_views_count_to : '',
        content_published_at_from : '',
        content_published_at_to : '',
        order_by: 'analytics_views_count_desc'
      })
      this.filterReset()
    } else {
      this.filter.patchValue({
        [val]: ''
      })
      this.filterUrls()
    }
    
  }

  getPlatforms() {
    this.attachSubscriptions(
      this.companyService.getGroupPlatforms().subscribe(resp => {
        this.platforms = resp;
        this.platforms$.next(this.platforms.slice());
      })
    )
  }

  onRouteChange() {
    this.attachSubscriptions(
      this.activatedRoute.queryParams.pipe(
        tap((val) => {
          console.log("onRouteChange", val)
          this.getCurrencyList();
          if (this.urlsDataSub) {
            this.urlsDataSub.unsubscribe();
          }
          if (this.company_id != this.activatedRoute.snapshot.queryParamMap.get('company_id')) {
            this.getLangData(this.activatedRoute.snapshot.queryParamMap.get('company_id'));
          }
          this.company_id = this.activatedRoute.snapshot.queryParamMap.get('company_id');
          this.filter.patchValue({
            count: this.filterCount,
            q: !!this.activatedRoute.snapshot.queryParamMap.get('q') ? this.activatedRoute.snapshot.queryParamMap.get('q') : "",
            content_status_id: !!this.activatedRoute.snapshot.queryParamMap.get('content_status_id') ? +this.activatedRoute.snapshot.queryParamMap.get('content_status_id') : "",
            created_employee_id: !!this.activatedRoute.snapshot.queryParamMap.get('created_employee_id') ? +this.activatedRoute.snapshot.queryParamMap.get('created_employee_id') : "",
            channel_id: !!this.activatedRoute.snapshot.queryParamMap.get('channel_id') ? +this.activatedRoute.snapshot.queryParamMap.get('channel_id') : "",
            content_type_id: !!this.activatedRoute.snapshot.queryParamMap.get('content_type_id') ? +this.activatedRoute.snapshot.queryParamMap.get('content_type_id') : "",
            is_content_views_count_update: !!this.activatedRoute.snapshot.queryParamMap.get('is_content_views_count_update') ? this.activatedRoute.snapshot.queryParamMap.get('is_content_views_count_update') : "",
            is_bravourl_content_views_count_update: !!this.activatedRoute.snapshot.queryParamMap.get('is_bravourl_content_views_count_update') ? this.activatedRoute.snapshot.queryParamMap.get('is_bravourl_content_views_count_update') : "",
            group_id: !!this.activatedRoute.snapshot.queryParamMap.get('group_id') ? +this.activatedRoute.snapshot.queryParamMap.get('group_id') : "",
            platform_id: !!this.activatedRoute.snapshot.queryParamMap.get('platform_id') ? +this.activatedRoute.snapshot.queryParamMap.get('platform_id') : "",
            created_at_from: !!this.activatedRoute.snapshot.queryParamMap.get('created_at_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('created_at_from')*1000) : "",
            created_at_to: !!this.activatedRoute.snapshot.queryParamMap.get('created_at_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('created_at_to')*1000) : "",
            analytics_views_count_from: !!this.activatedRoute.snapshot.queryParamMap.get('analytics_views_count_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('analytics_views_count_from')*1000) : "",
            analytics_views_count_to: this.activatedRoute.snapshot.queryParamMap.get('analytics_views_count_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('analytics_views_count_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('analytics_views_count_to')*1000) : ""),
            content_published_at_from: !!this.activatedRoute.snapshot.queryParamMap.get('content_published_at_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('content_published_at_from')*1000) : "",
            content_published_at_to: this.activatedRoute.snapshot.queryParamMap.get('content_published_at_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('content_published_at_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('content_published_at_to')*1000) : ""),
            order_by: !!this.activatedRoute.snapshot.queryParamMap.get('order_by') ? this.activatedRoute.snapshot.queryParamMap.get('order_by') : "analytics_views_count_desc",
          })

          this.topFilter.patchValue({
            analytics_id: !!this.activatedRoute.snapshot.queryParamMap.get('analytics_id') ? this.activatedRoute.snapshot.queryParamMap.get('analytics_id') : 0,
          })

        }),
        switchMap((val) => {
          console.log("switchMap", this.company)
          if (this.company == undefined || this.company.id != this.activatedRoute.snapshot.queryParamMap.get('company_id')) {
            return this.companyService.getCompany(this.activatedRoute.snapshot.queryParamMap.get('company_id')).pipe(
              map(x => x[0]),
              tap(x => {
                this.company = x;
                this.me = this.company.employees[0];
                this.companyService.company$.next(x);
                if (!!x && !!x.employees && !!x.employees[0]) {
                  this.company['rate_target'] = x.employees[0].rate_target
                }

              }),
              switchMap(() => this.uploadService.getUploadLimit()
              .pipe(
                tap(res => {
                  if (res || this.company.filesize >= this.company.filesize_limit) {
                    this.uploadLimit = true;
                  }
                })
              )),
              map(() => val)
            )
          } else {
            return of(val)
          }
        })
      ).subscribe(params => {
        console.log("route STREAM", params);
        this.linkTo = '';
        this.profileCollection = new SelectionModel(
          true,
          []
        )

        // console.log("this.linkTo", this.linkTo)
        // console.log('this.linkTo.split("_")[1]', this.linkTo.split("_")[1])
        // console.log("this.filter.value", this.filter.value)

        this.urls = [];
        this.contents = [];
        this.page = 1;
        this.spikesPage = 1;
        this.contentPage = 1;
        this.contentTypePage = 1;
        this.groupedProfiles = [];
        this.profiles = [];
        this.profilesPage = 1;
        
        // this.urlsDataSub = this.taskService.getTaskDataStream().pipe(
        //   concatMap(tasksInPage => {
        //     return concat(...tasksInPage.map(taskInPage => this.neededData(taskInPage))).pipe(last(),map(x => tasksInPage))
        //   }),
        // ).subscribe(resp => console.log("-----getTaskDataStream-----",resp));

        
        this.createdDateRange = new DateRange(this.filter.value['created_at_from'], this.filter.value['created_at_to']);
        this.publishDateRange = new DateRange(this.filter.value['content_published_at_from'], this.filter.value['content_published_at_to']);
        this.viewsDateRange = new DateRange(this.filter.value['analytics_views_count_from'], this.filter.value['analytics_views_count_to']);

        console.log("INIT this.createdDateRange", this.createdDateRange)
        console.log("INIT this.publishDateRange", this.publishDateRange)
        console.log("INIT this.viewsDateRange", this.viewsDateRange)

        this.getEmployees();
        if (this.company.permissions.includes('owner') || this.company.permissions.includes('admin') || this.company.permissions.includes('manager')) {
          this.getPartners();
        }
        this.getProfiles(this.company_id, this.profilesPage);
        this.getRateStats();
        this.getGroupsCompany();
        this.getContentTypes(this.activatedRoute.snapshot.queryParamMap.get('platform_id'), this.contentPage);

        
        if (!this.urls.length) {
          if (this.topChartSub) {
            this.topChartSub.unsubscribe()
          }
          this.topChartSub = this.taskService.getTopChartStream().pipe(
            concatMap(topsInPage => {
              return concat(...topsInPage.map(topInPage => this.neededChartData(topInPage, 'top'))).pipe(last(),map(x => topsInPage))
            }),
          ).subscribe(resp => console.log("-----getTopChartStream-----",resp));
          
          this.getTopDash(this.page);
          this.getBookmarks()
        }
        if (!this.spikes.length) {
          if (this.spikesChartSub) {
            this.spikesChartSub.unsubscribe()
          }
          this.spikesChartSub = this.taskService.getSpikesChartStream().pipe(
            concatMap(topsInPage => {
              return concat(...topsInPage.map(topInPage => this.neededChartData(topInPage, 'spikes'))).pipe(last(),map(x => topsInPage))
            }),
          ).subscribe(resp => console.log("-----getSpikesChartStream-----",resp));
          if (this.isMobSpikesOpen) {
            this.getSpikes(this.spikesPage);
          }
        }
        // this.getUrls(this.page);
      })
    )
  }

  getGroupsCompany() {
    this.attachSubscriptions(
      this.companyService.getInfiniteGroupsCompany(this.company_id, '1', '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.companyService.getInfiniteGroupsCompany(this.company_id, x).pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        this.groups = [].concat(...res);
        this.groups$.next(this.groups.slice());
      })
    )
  }

  openMobSpikes() {
    this.isMobSpikesOpen = true; 
    this.getSpikes(this.spikesPage);
  }

  goToReminderCases() {
    console.log("goToReminderCases");
  }

  openJob(task_id, task_operation_id) {
    this.attachSubscriptions(
      this.taskService.getOneTaskExpand(this.company_id, task_id).pipe(
        map(x => x.body[0]),
        switchMap(x => this.neededData(x).pipe(map(() => casesModel([x], [], 'update')),map(x => x.arr[0]))),
      ).subscribe(resp => {
        const dialogRef = this.dialog.open(OpenJobComponent, {
          backdropClass: 'backdrop_under_header',
          panelClass: !this.is_mobile ? ['open_task_dialog', 'show_header'] : 'open_task_dialog',
          autoFocus: false,
          data: {
            task_id: task_id,
            task_operation_id: task_operation_id,
            task: resp,
          }
        });
      })
    )
  }

  openTask(task) {
    let taskData:any = {
      task_id: task.id
    }
    if (task.hasOwnProperty("operations")) {
      taskData.task = task
    }
    if (this.urls.length) {
      taskData.tasks = this.urls
    }
    const dialogRef = this.dialog.open(OpenTaskComponent, {
      backdropClass: 'backdrop_under_header',
      panelClass: !this.is_mobile ? ['open_task_dialog', 'show_header'] : 'open_task_dialog',
      autoFocus: false,
      data: taskData
    });
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  getUser() {
    this.attachSubscriptions(
      this.auth.$user.subscribe(resp => {
        this.user = resp;
      })
    )
  }

  getRateStats() {
    this.attachSubscriptions(
      this.taskService.getRateStats(this.company_id, moment().utc().clone().startOf('month').format('X'), moment().utc().clone().endOf('month').format('X')).subscribe(resp => {
        this.taskService.userStats$.next(resp);
      })
    )
  }

  getEmployees() {
    this.attachSubscriptions(
      this.taskService.getEmployees(this.company_id).subscribe(resp => {
        this.employees = resp;
        this.employees$.next(this.employees.slice());
      })
    )
  }

  showStats(profile) {
    if (!this.is_mobile) {
      const dialogRef = this.dialog.open(UrlAnalyticsComponent, {
        disableClose: true,
        autoFocus: false,
        data: {
          company: this.company,
          company_id: this.company_id,
          task: profile.task,
          profile: profile,
          is_edit: true,
        }
      });
  
    } else {
      const dialogRef = this.dialog.open(MobUrlAnalyticsComponent, {
        backdropClass: ['mob_interface_backdrop'],
        panelClass: ['mob_interface_panel', 'animate__animated', 'animate__slideInUp'],
        disableClose: true,
        data: {
          company: this.company,
          company_id: this.company_id,
          task: profile.task,
          profile: profile,
          is_edit: true,
        }
      });
  
    }
  }

  getLangData(id) {
    this.attachSubscriptions(
      this.languageService.getLangData().pipe(
        tap(resp => {
          this.activeLang = resp.active;
          this._adapter.setLocale(resp.active);
          this.currMonthName = moment().format('MMMM');
        }),
        switchMap(() => this.taskService.getOperations(id, this.activeLang).pipe(
          tap(x => {
            this.operationsValues = x;
          })
        ))
      ).subscribe(resp => {
        console.log("getOperations", resp)
      })
    )
  }


  addRowData(row_obj) {
    let data = casesModel([row_obj], JSON.parse(JSON.stringify(this.urls)), "add")
    this.urls.unshift(...data.arr);
    this.pagination['totalCount'] = +this.pagination['totalCount'] + 1;
  }

  checkIsMobile() {
    if (window.innerWidth <= 769) {
      this.is_mobile = true;
      this.view = [88,31]
    } else {
      this.is_mobile = false;
      this.view = [134, 54]
    }
    this.onResize();
  }

  onResize() {
    this.attachSubscriptions(
      fromEvent(window, "resize").pipe(
        map(() => window.innerWidth)
      ).subscribe((wWidth) => {
          if (wWidth <= 769) {
            this.is_mobile = true;
            this.view = [88,31]
          } else {
            this.is_mobile = false;
            this.view = [134, 54]
          }
        }
      )
    )
  }

  ngAfterViewInit(): void {
    let partPhElement = this.partPlaceholder.nativeElement;

    partPhElement.style.display = 'none';
    partPhElement.parentNode.removeChild(partPhElement);

    this.cd.detectChanges();
  }

  closeDateFilter() {
    this.dateFilterSub && this.dateFilterSub.unsubscribe();
    if (this.overlayDateRefFilter) {
      this.overlayDateRefFilter.dispose();
      this.overlayDateRefFilter = null;
    }
  }

  openDateFilter(el, val) {
    this.closeDateFilter();
    let x = el.offsetLeft - 350 + el.offsetWidth;
    let y =  el.offsetTop + el.offsetHeight;
    const positionStrategy = this.overlay.position()
    .flexibleConnectedTo({ x, y })
    .withPositions([
      {
        originX: 'start',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'top',
      }
    ]);

    this.overlayDateRefFilter = this.overlay.create({
      backdropClass: 'transparent_bg',
      hasBackdrop: true,
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });

    this.overlayDateRefFilter.attach(new TemplatePortal(this.filterDateMenu, this.viewContainerRef, {
      $implicit: val
    }));
    
    this.dateFilterSub = fromEvent<MouseEvent>(document, 'click')
      .pipe(
        filter(event => {
          const clickTarget = event.target as HTMLElement;
       
          let check1 = !!this.overlayDateRefFilter && !this.overlayDateRefFilter.overlayElement.contains(clickTarget)
          // let check2 = (document.getElementsByClassName('mat-calendar-table').length > 0 && !document.getElementsByClassName('mat-calendar-table')[0].contains(clickTarget))
          // let check3 = (document.getElementsByClassName('filter_autocomlete').length == 0);
          // let check4 = (clickTarget.classList.contains('mat-option-text') || clickTarget.classList.contains('mat-option'))
          let check5 = !clickTarget.classList.contains('mat-calendar-body-cell-content')
          // console.log("check1", check1)
          // console.log("check2", check2)
          // console.log("clickTarget", clickTarget.classList.contains('mat-calendar-body-cell-content'))
          // console.log("document.getElementsByClassName('mat-calendar-table')", document.getElementsByClassName('mat-calendar-table'))
          // console.log("document.getElementsByClassName('mat-calendar-table')[0].contains(clickTarget)", document.getElementsByClassName('mat-calendar-table')[0].contains(clickTarget))
//(check1 && check2) || (check1 && check3 && !check4)
          // return false
          return check1 && check5
        }),
        take(1)
      ).subscribe(() => this.closeDateFilter())

  }

  refreshViewCounts(profile) {
    profile.isRefreshed = true;

    if (profile && profile.channel && profile.channel.platform_id == 1) {
      this.attachSubscriptions(
        this.taskService.getYouTubeViews(profile.id, {}).pipe(
          switchMap(el => {
            return this.taskService.editProfile(profile.id, {content_views_count: !!el.content_views_count ? el.content_views_count : '0', is_bravourl_content_views_count_update: !!profile.is_bravourl_content_views_count_update ? '1' : '0', is_content_views_count_update: !!profile.is_content_views_count_update ? '1' : '0'}, this.company_id).pipe(
              tap(result => {
                profile.content_views_count = result.content_views_count.toString();
                profile.is_content_views_count_update = result.is_content_views_count_update;
                profile.is_bravourl_content_views_count_update = result.is_bravourl_content_views_count_update;
                profile.views_updated_at = result.views_updated_at;
                profile.views_update_at = result.views_update_at;
              })
            )
          }),
          retry(3)
        ).subscribe((resp:any) => {
          console.log("refreshViewCounts", resp)
          profile.isRefreshed = false;
        },
        error => {
          profile.isRefreshed = false;
          this.layoutService.showSnackBar({name: ''}, marker(error), SnackBarItem)
        })
      )
    } else if (profile && profile.channel && profile.channel.platform_id == 3) {
      this.attachSubscriptions(
        this.taskService.getTikTokViews(profile.id, {}).pipe(
          switchMap(el => {
            return this.taskService.editProfile(profile.id, {content_views_count: !!el.content_views_count ? el.content_views_count : '0', is_bravourl_content_views_count_update: !!profile.is_bravourl_content_views_count_update ? '1' : '0', is_content_views_count_update: !!profile.is_content_views_count_update ? '1' : '0'}, this.company_id).pipe(
              tap(result => {
                profile.content_views_count = result.content_views_count.toString();
                profile.is_content_views_count_update = result.is_content_views_count_update;
                profile.is_bravourl_content_views_count_update = result.is_bravourl_content_views_count_update;
                profile.views_updated_at = result.views_updated_at;
                profile.views_update_at = result.views_update_at;
              })
            )
          }),
          retry(3)
        ).subscribe((resp:any) => {
          console.log("refreshViewCounts", resp)
          profile.isRefreshed = false;
        },
        error => {
          profile.isRefreshed = false;
          this.layoutService.showSnackBar({name: ''}, marker(error), SnackBarItem)
        })
      )
    } else if (profile && profile.channel && profile.channel.platform_id == 4) {
      this.attachSubscriptions(
        this.taskService.getFacebookViews(profile.id, {}).pipe(
          switchMap(el => {
            return this.taskService.editProfile(profile.id, {content_views_count: !!el.content_views_count ? el.content_views_count : '0', is_bravourl_content_views_count_update: !!profile.is_bravourl_content_views_count_update ? '1' : '0', is_content_views_count_update: !!profile.is_content_views_count_update ? '1' : '0'}, this.company_id).pipe(
              tap(result => {
                profile.content_views_count = result.content_views_count.toString();
                profile.is_content_views_count_update = result.is_content_views_count_update;
                profile.is_bravourl_content_views_count_update = result.is_bravourl_content_views_count_update;
                profile.views_updated_at = result.views_updated_at;
                profile.views_update_at = result.views_update_at;
              })
            )
          }),
          retry(3)
        ).subscribe((resp:any) => {
          console.log("refreshViewCounts", resp)
          profile.isRefreshed = false;
        },
        error => {
          profile.isRefreshed = false;
          this.layoutService.showSnackBar({name: ''}, marker(error), SnackBarItem)
        })
      )
    } else {
      this.attachSubscriptions(
        this.taskService.getLinkData(profile.content_url, this.company_id, (profile.company_id != this.company_id ? profile.company_id : null), profile.channel_id).pipe(
          switchMap(el => {
            return this.taskService.editProfile(profile.id, {content_views_count: !!el.views_count ? el.views_count : '0', is_bravourl_content_views_count_update: !!profile.is_bravourl_content_views_count_update ? '1' : '0', is_content_views_count_update: !!profile.is_content_views_count_update ? '1' : '0'}, this.company_id).pipe(
              tap(result => {
                profile.content_views_count = result.content_views_count.toString();
                profile.is_content_views_count_update = result.is_content_views_count_update;
                profile.is_bravourl_content_views_count_update = result.is_bravourl_content_views_count_update;
                profile.views_updated_at = result.views_updated_at;
                profile.views_update_at = result.views_update_at;
              })
            )
          })
        ).subscribe((resp:any) => {
          console.log("refreshViewCounts", resp)
          profile.isRefreshed = false;
        },
        error => {
          profile.isRefreshed = false;
        })
      )
    }

  }

  getProfiles(company_id, page) {
    this.attachSubscriptions(
      this.companyService.getProfiles(company_id, page).pipe(
        tap(el => {
          this.profilesPagination = {
            'pageCount': el.headers.get('x-pagination-page-count'),
            'perPage': el.headers.get('x-pagination-per-page'),
            'totalCount': el.headers.get('x-pagination-total-count'),
            'currentPage': el.headers.get('x-pagination-current-page'),
          }
        }),
        map(el => el.body)
      ).subscribe(resp => {
        console.log("getProfiles", resp)
        // public profilesControl: FormControl = new FormControl();
        // public profiles$: ReplaySubject<any> = new ReplaySubject<any>(1);
        if (page == 1) {
          this.groupedProfiles = [];
          this.channelsSubsControl.patchValue(0)
        }
        resp.forEach(channel => {
          if (this.groupedProfiles.filter(x => x.platform_id == channel.platform_id).length == 0) {
            this.groupedProfiles.push({
              platform_id: channel.platform_id,
              channels: [channel],
              channelsControl: new FormControl(),
              asChannels: new ReplaySubject<any>(1)
            })
            this.groupedProfiles.find(x => x.platform_id == channel.platform_id).asChannels.next(this.groupedProfiles.find(x => x.platform_id == channel.platform_id).channels)
          } else {
            if (this.groupedProfiles.find(x => x.platform_id == channel.platform_id).channels.filter(b => b.id == channel.id).length == 0) {
              this.groupedProfiles.find(x => x.platform_id == channel.platform_id).channels.push(channel);
              this.groupedProfiles.find(x => x.platform_id == channel.platform_id).asChannels.next(this.groupedProfiles.find(x => x.platform_id == channel.platform_id).channels)
            }
          }
        })

        console.log("this.groupedProfiles", this.groupedProfiles)

        if (page == 1) {
          this.profiles = resp
        } else {
          resp.forEach(profile => {
            if (this.profiles.filter(x => x.id == profile.id).length == 0) {
              this.profiles.push(...resp);
            }
          })
        }

        if (this.groupedProfiles.filter(x => x.platform_id == 0).length == 0) {
          this.groupedProfiles.unshift({
            platform_id: 0,
            channels: [...this.profiles],
            channelsControl: new FormControl(),
            asChannels: new ReplaySubject<any>(1)
          })
          this.groupedProfiles.find(x => x.platform_id == 0).asChannels.next(this.groupedProfiles.find(x => x.platform_id == 0).channels)
        } else {
          this.profiles.forEach((channel,ind) => {
            if (this.groupedProfiles.find(x => x.platform_id == 0).channels.filter(b => b.id == channel.id).length == 0) {
              this.groupedProfiles.find(x => x.platform_id == 0).channels.push(channel);
            }
            if (ind == this.profiles.length - 1) {
              this.groupedProfiles.find(x => x.platform_id == 0).asChannels.next(this.groupedProfiles.find(x => x.platform_id == 0).channels)
            }
          });
        }

        this.groupedProfiles.forEach(el => {
          el.channelsControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => {console.log(resp); this.onSearchGroupedChannels(resp, el);})
        })
        this.profiles$.next(this.profiles.slice());
        this.profilesPage = +this.profilesPage + 1;

        if (this.profilesPagination.pageCount >= this.profilesPage) {
          console.log("getNextProfiles")
          this.getProfiles(this.company_id, this.profilesPage);
        }
      })
    )
  }

  
  onSearchGroupedChannels(resp, groupedChannels) {
    if (!groupedChannels.channels) {
      return;
    }

    if (!resp) {
      groupedChannels.asChannels.next(groupedChannels.channels.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }

    // filter the banks
    groupedChannels.asChannels.next(
      groupedChannels.channels.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }
  
  searchInMobile(element: ElementRef) {
    this.filter.patchValue({
      q: element['value']
    })
    this.filterUrls();
  }

  openMobFilter() {
    this.layoutService.overflowHidden();
    this.mobile_filter_wrp.nativeElement.classList.add("active");
  }

  closeMobFilter() {
    this.layoutService.overflowAuto();
    this.mobile_filter_wrp.nativeElement.classList.remove("active");
  }

  sort(val) {
    this.filterUrls(null, val)
  }

  checkActions(e, task) {
    e.preventDefault();
    e.stopPropagation();
    this.attachSubscriptions(
      this.taskService.getActions(task.id, 1, this.company.id).subscribe(resp => {
        if (resp.body.length) {
          this.dialog.open(NotificationsBar, {
            backdropClass: 'backdrop_under_header',
            panelClass: !this.is_mobile ? ['open_task_dialog', 'show_header'] : 'open_task_dialog',
            data: {
              notifications: resp.body,
              employees: !!this.employees ? this.employees : [],
              taskStatuses: this.taskStatuses,
              company: this.company,
              user: this.user,
              task: task,
              header: false,
              tasks: this.urls,
              initCompanyId: this.company_id,
              empl_status: this.company.permissions.includes('owner') || this.company.permissions.includes('admin') || this.company.permissions.includes('manager'),
              pagination: {
                'pageCount': resp.headers.get('x-pagination-page-count'),
                'perPage': resp.headers.get('x-pagination-per-page'),
                'totalCount': resp.headers.get('x-pagination-total-count'),
                'currentPage': resp.headers.get('x-pagination-current-page'),
              },
            }
          });
        }
      })
    )
  }

  getGroup(id) {
    if (!this.groups || !this.groups.find(el => el.id == id)) {
      return false;
    }
    return this.groups.find(el => el.id == id)
  }  

  getEmployee(id) {
    if (!this.employees) {
      return false;
    }
    return this.employees.find(el => el.id == id)
  }  

  getPartner(id) {
    if (!this.partners) {
      return false;
    }
    return this.partners.find(el => el.partner_company_id == id)
  }

  selectChange(e) {
    // console.log(e)
    // console.log(this.filter.value);
    // this.closeExecFilter();
    this.filterUrls();
  }

  // selectOperation(e, id, jobTypeSelect) {
  //   e.preventDefault();
  //   e.stopPropagation();

  //   this.filter.patchValue({
  //     operation_id: id,
  //   })

  //   jobTypeSelect.close()
  //   this.closeExecFilter();
  //   this.filterUrls();

  // }

  addScheduledPost() {
    const dialogRef = this.dialog.open(AddAndEditScheduledPostComponent, {
      disableClose: true,
      data: {
        user: this.user,
        company: this.company,
        activeLang: this.activeLang
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log(result)
      })
    )
  }

  goToRegularPublications(e) {
    e.preventDefault();
    this.router.navigate(['/regular-publications'], { queryParams: {company_id: this.company_id}});
  }

  filterReset() {
    this.filter.patchValue({
    })
    this.router.navigate(['/urls-dashboard'], { queryParams: {company_id: this.company_id, order_by: "analytics_views_count_desc"}});
    if (this.is_mobile) {
      this.layoutService.overflowAuto();
      this.mobile_filter_wrp.nativeElement.classList.remove("active");
    }
  }

  // resetSearchParams() {
  //   this.filter.patchValue({
  //   custom_id: "",
  //   task_company_id: '',
  //   })
  // }

  getCountSumm(arr, startIndex) {
    let count = 0

    for (startIndex; startIndex < arr.length; startIndex++) {
      const element = arr[startIndex];
      count = count + element.count
    }
    
    return count
  }

  changeActiveStatus(id) {
    this.filter.patchValue({
      content_status_id: id
    })

    this.filterUrls()
  }

  // if (id == 0) {
  //   this.activeStatus = [0];
  // } else {
  //   this.activeStatus = [...id];
  // }

  toNoPublishUrls() {
    this.filter.patchValue({
      content_published_at_from: '',
      content_published_at_to: 0
    })
    this.filterUrls();
  }

  filterUrls(op_status_id = null, order_by = null, is_btn = false) {
    this.closeDateFilter()
    // this.closeOpenFilter();
    if (this.urlsSub) {
      this.urlsSub.unsubscribe()
    }
    if (this.contentSub) {
      this.contentSub.unsubscribe()
    }
    this.filterCount = this.filterCount + 1;
    this.filter.patchValue({
      count: this.filterCount
    })
    
    let filterData = {...this.filter.value};

    if (!!filterData.content_published_at_from) {
      filterData.content_published_at_from = moment(filterData.content_published_at_from._d).format("X");
    }
    if (!!filterData.content_published_at_to) {
      filterData.content_published_at_to = moment(filterData.content_published_at_to._d).format("X");
    }
    if (!!filterData.analytics_views_count_from) {
      filterData.analytics_views_count_from = moment(filterData.analytics_views_count_from._d).format("X");
    }
    if (!!filterData.analytics_views_count_to) {
      filterData.analytics_views_count_to = moment(filterData.analytics_views_count_to._d).format("X");
    }
    if (!!filterData.created_at_from) {
      filterData.created_at_from = moment(filterData.created_at_from._d).format("X");
    }
    if (!!filterData.created_at_to) {
      filterData.created_at_to = moment(filterData.created_at_to._d).format("X");
    }

    if (!!order_by || order_by == "0") {
      filterData.order_by = order_by
    }
    
    let params = {
      company_id: this.company_id
    };

    // if (is_btn) {
    //   this.filter.removeControl('id');
    // }

    Object.keys(filterData).forEach(element => {
      if (String(filterData[element]) != '') {
        params[element] = filterData[element]
      }
    });


    // console.log("filterData.content_published_at_to", filterData.content_published_at_to)
    if (filterData.content_published_at_to === 0) {
      params['content_published_at_to'] = '0';
    }
    
    if (filterData.is_content_views_count_update === '0') {
      params['is_content_views_count_update'] = '0';
    }
    if (filterData.is_bravourl_content_views_count_update === '0') {
      params['is_bravourl_content_views_count_update'] = '0';
    }

    console.log("params45555555555555555555555", params)
    this.router.navigate(['/urls-dashboard'], { queryParams: params });

    if (this.is_mobile) {
      this.layoutService.overflowAuto();
      if (this.mobile_filter_wrp) {
        this.mobile_filter_wrp.nativeElement.classList.remove("active");
      }
    }
  }

  getTaskStatus() {
    this.attachSubscriptions(
      this.taskService.getTaskStatuses(this.company_id).subscribe(resp => {
        this.taskStatuses = resp.slice()
        this.taskStatuses.unshift({
          id: 0,
          name: marker('All statuses')
        });
      })
    )
  }

  getOperationsStatus() {
    this.attachSubscriptions(
      this.taskService.getOperationsStatus().subscribe(resp => {
        this.operationStatuses = resp.slice();
        this.operationStatuses.unshift({
          id: 0,
          name: marker('All statuses')
        });
      })
    )
  }

  profileDialog(value) {
    let returnDialog;
    switch (value) {
      case 'PublishedAt':
        returnDialog = EditPublishedAtComponent;
        break
      case 'Status':
        returnDialog = EditStatusComponent;
        break
      case 'Channel':
        returnDialog = AddChannelToTaskComponent;
        break
      case 'Name':
        returnDialog = EditContentNameComponent;
        break
    }
    return returnDialog
  }

  dblEditProfile(profile, value) {
    const dialogRef = this.dialog.open(this.profileDialog(value), {
      disableClose: true,
      data: {
        company: this.company,
        company_id: this.company_id,
        task: profile.task,
        profile: profile,
        is_edit: true
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        
        if (!!result && result.event == 'edit') {
          // this.getTask(true);
          this.refreshService.refreshUrls$.next({url_id: profile.id, company_id: this.company_id})
        }
      })
    )
  }

  deleteProfile(task, profile, k) {
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: task,
        target: marker("profile")
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        this.attachSubscriptions(
          this.taskService.deleteProfile(profile.id).subscribe(resp => {
            this.urls.splice(k, 1)
          })
        )
      }
    });

  }

  saveBookmark(bookmark, k) {
    console.log(bookmark, k)
    let settData = {...bookmark.settings};
    Object.keys(settData).forEach(key => {
      if (key != 'analytics_id') {
        if (settData[key].length == 0) {
          delete settData[key]
        } else {
          settData[key] = settData[key].join(',')
        }
      }
    })
    this.attachSubscriptions(
      this.companyService.editBookmark(bookmark.id, {settings: settData}).subscribe(resp => {
        console.log("resp", resp)
        bookmark.is_change = false;
      })
    )
  }

  deleteBookmark(bookmark, k) {
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: {},
        target: marker("bookmark")
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        this.attachSubscriptions(
          this.companyService.deleteBookmark(bookmark.id).subscribe(resp => {
            this.bookmarks.splice(k, 1)
          })
        )
      }
    });

  }

  editProfile(element, profile) {
    const dialogRef = this.dialog.open(AddTaskProfileComponent, {
      disableClose: true,
      data: {
        company: this.company,
        company_id: this.company_id,
        task: element,
        profile: profile,
        is_edit: true
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        
        if (!!result && result.event == 'edit') {
          // this.getTask(true);
          this.refreshService.refreshUrls$.next({url_id: profile.id, company_id: this.company_id})
        }
      })
    )
  }


  sortUrls(val) {
    switch (val) {
      case 'content_views_count_desc':
        this.urls.sort((a,b) => {
          return b.content_views_count - a.content_views_count;
        })
        break;
      case 'content_views_count':
        this.urls.sort((a,b) => {
          return a.content_views_count - b.content_views_count;
        })
        break;
      case 'content_published_at_desc':
        this.urls.sort((a,b) => {
          return +(b.content_published_at || b.publish_at) - +(a.content_published_at || a.publish_at);
        })
        break;
      case 'content_published_at':
        this.urls.sort((a,b) => {
          return +(a.content_published_at || a.publish_at) - +(b.content_published_at || b.publish_at);
        })
        break;
      case 'id_desc':
        this.urls.sort((a,b) => {
          return b.id - a.id;
        })
        break;
      case 'id':
        this.urls.sort((a,b) => {
          return a.id - b.id;
        })
        break;
    }
  }

  setMultyAutoUpdate() {
    let multiData = [];

    this.profileCollection.selected.forEach(item => {
      if ([101, 102, 202, 203, 301, 401, 404].includes(item.content_type_id) && item.content_status_id == 4) {
        multiData.push({
          "path": `/api/task-channel/${item.id}/`,
          "query": {company_id: this.company.id},
          "method": "POST",
          "body": {
            [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
            is_content_views_count_update: 1,
            is_bravourl_content_views_count_update: 1,
          }
        })
      }
    });

    if (multiData.length) {
      this.attachSubscriptions(
        this.taskService.multiRequest(multiData).subscribe(resp => {
          this.layoutService.showSnackBar({name: ''}, `Auto update for ${multiData.length} profile(s) succsess!`, SnackBarItem)
          this.profileCollection.selected.forEach(x => {
            this.refreshService.refreshUrls$.next({url_id: x.id, company_id: this.company.id})
          })
          this.profileCollection.clear()
        })
      )
    } else {
      this.layoutService.showSnackBar({name: ''}, `Selected ${this.profileCollection.selected.length} profile(s) can't auto update.`, SnackBarItem)
    }
  }

  getMore() {
    this.getTopDash(this.page);
  }

  getMoreSpikes() {
    this.getSpikes(this.spikesPage);
  }

  getNextProfiles() {
    if (this.profilesPagination.pageCount >= this.profilesPage) {
      console.log("getNextProfiles")
      this.getProfiles(this.company_id, this.profilesPage);
    }
  }

  getSpikes(n) {
    console.log("FILTER getSpikes", this.filter.value)
    if (this.isSpikesLoad) {
      return
    }
    this.isSpikesLoad = true;

    let filterSpikesDash = JSON.parse(JSON.stringify(this.filter.value));

    switch (this.spikesFilter.value.order_by) {
      case 'analytics_max_delta_desc':
        filterSpikesDash.analytics_type = 'analytics_max_delta'
        filterSpikesDash.order_by = 'analytics_max_delta_desc'
        break;
      case 'analytics_max_percent_desc':
        filterSpikesDash.analytics_type = 'analytics_max_percent'
        filterSpikesDash.order_by = 'analytics_max_percent_desc'
        break;
      case 'analytics_max_delta':
        filterSpikesDash.analytics_type = 'analytics_min_delta'
        filterSpikesDash.order_by = 'analytics_max_delta'
        break;
      case 'analytics_max_percent':
        filterSpikesDash.analytics_type = 'analytics_min_percent'
        filterSpikesDash.order_by = 'analytics_max_percent'
        break;
      default:
        break;
    }
    
    filterSpikesDash.analytics_type_period = this.spikesFilter.value.analytics_type_period;
    filterSpikesDash.channel_id = this.spikesFilter.value.channel_id.join(',');
    filterSpikesDash.content_type_id = this.spikesFilter.value.content_type_id.join(',');
    filterSpikesDash.platform_id = this.spikesFilter.value.platform_id.join(',');
    filterSpikesDash.analytics_views_count_from = this.spikesFilters[this.spikesFilter.value.analytics_id].analytics_views_count_from;
    filterSpikesDash.analytics_views_count_to = this.spikesFilters[this.spikesFilter.value.analytics_id].analytics_views_count_to;
    console.log("filterSpikesDash", filterSpikesDash);

    this.urlsSub = this.taskService.getDashExpandUrls(n, this.company_id, {...filterSpikesDash}, '10').pipe(
      tap(el => {
        this.spikesPagination = {
          'pageCount': el.headers.get('x-pagination-page-count'),
          'perPage': el.headers.get('x-pagination-per-page'),
          'totalCount': el.headers.get('x-pagination-total-count'),
          'currentPage': el.headers.get('x-pagination-current-page')
        }
      }),
      map(el => el.body),
      tap(links => {
        this.taskService.spikesChart$.next(links)
      }),
      distinctUntilChanged()
    ).subscribe(resp => {

      console.log("RESP getTopDash", resp)
      if (this.spikesPage == 1) {
        this.spikes = [...resp]
      } else {
        this.spikes.push(...resp);
      }

      console.log("this.spikes "+this.spikesPage, this.spikes)

      this.spikesPage = this.spikesPage + 1;
      this.isSpikesLoad = false;
    },
    error => {
      this.isSpikesLoad = false;
    })
    
  }

  getMoreBookmarkTopDash(bookmark) {
    this.attachSubscriptions(
      of(bookmark).pipe(
        switchMap(x => this.getBookmarkTopDash(x))
      ).subscribe(res => {
        console.log("getMoreBookmarkTopDash", res)
      })
    )
  }

  getBookmarkTopDash(bookmark) {
    bookmark.is_load = true;

    let topDashData = {...bookmark.settings}
    if (bookmark.settings.analytics_id <= 2) {
      topDashData.analytics_type_period = 'hour'
    } else {
      topDashData.analytics_type_period = 'day'
    }

    if (bookmark.settings.channel_id) {
      topDashData.channel_id = bookmark.settings.channel_id.join(',')
    }
    if (bookmark.settings.content_type_id) {
      topDashData.content_type_id = bookmark.settings.content_type_id.join(',')
    }
    if (bookmark.settings.platform_id) {
      topDashData.platform_id = bookmark.settings.platform_id.join(',')
    }
    topDashData.order_by = 'analytics_views_count_desc',
    topDashData.analytics_views_count_from = this.filters[bookmark.settings.analytics_id].analytics_views_count_from;
    topDashData.analytics_views_count_to = this.filters[bookmark.settings.analytics_id].analytics_views_count_to;
    console.log("topDashData", topDashData);

    return this.taskService.getDashExpandUrls(bookmark.page, this.company_id, {...topDashData}, '10').pipe(
      tap(el => {
        bookmark.pagination = {
          'pageCount': el.headers.get('x-pagination-page-count'),
          'perPage': el.headers.get('x-pagination-per-page'),
          'totalCount': el.headers.get('x-pagination-total-count'),
          'currentPage': el.headers.get('x-pagination-current-page')
        }
        if (bookmark.pagination['currentPage'] == 1) {
          bookmark.summaryViews = el.headers.get('x-summary-views')
          bookmark.noPublishCount = el.headers.get('x-summary-not-published');
          console.log("GetUnPublishCount", bookmark.noPublishCount)
        }
        console.log("bookmark.pagination TEST", bookmark.pagination)
      }),
      map(el => el.body),
      tap(links => {
        this.taskService.topChart$.next(links)
        if (bookmark.page == 1) {
          bookmark.urls = [...links]
        } else {
          bookmark.urls.push(...links);
        }
  
        console.log("bookmark.urls "+bookmark.page, bookmark.urls)
  
        bookmark.page = +bookmark.page + 1;
        bookmark.is_load = false;
      })
    )
  }
  getTopDash(n) {
    console.log("FILTER getTopDash", this.filter.value)
    if (this.isLoad) {
      return
    }
    this.isLoad = true;

    let filterTopDash = JSON.parse(JSON.stringify(this.filter.value));

    if (this.topFilter.value.analytics_id <= 2) {
      filterTopDash.analytics_type_period = 'hour'
    } else {
      filterTopDash.analytics_type_period = 'day'
    }

    filterTopDash.channel_id = this.topFilter.value.channel_id.join(',')
    filterTopDash.content_type_id = this.topFilter.value.content_type_id.join(',')
    filterTopDash.platform_id = this.topFilter.value.platform_id.join(',')
    filterTopDash.analytics_views_count_from = this.filters[this.topFilter.value.analytics_id].analytics_views_count_from;
    filterTopDash.analytics_views_count_to = this.filters[this.topFilter.value.analytics_id].analytics_views_count_to;
    console.log("filterTopDash", filterTopDash);

    this.urlsSub = this.taskService.getDashExpandUrls(n, this.company_id, {...filterTopDash}, '10').pipe(
      tap(el => {
        this.pagination = {
          'pageCount': el.headers.get('x-pagination-page-count'),
          'perPage': el.headers.get('x-pagination-per-page'),
          'totalCount': el.headers.get('x-pagination-total-count'),
          'currentPage': el.headers.get('x-pagination-current-page')
        }
        if (this.pagination['currentPage'] == 1) {
          this.summaryViews = el.headers.get('x-summary-views')
          this.noPublishCount = el.headers.get('x-summary-not-published');
          console.log("GetUnPublishCount", this.noPublishCount)
        }
        console.log("this.pagination TEST", this.pagination)
      }),
      map(el => el.body),
      tap(links => {
        this.taskService.topChart$.next(links)
      }),
      distinctUntilChanged()
    ).subscribe(resp => {

      console.log("RESP getTopDash", resp)
      if (this.page == 1) {
        this.urls = [...resp]
      } else {
        this.urls.push(...resp);
      }

      console.log("this.urls "+this.page, this.urls)

      this.page = this.page + 1;
      this.isLoad = false;
    },
    error => {
      this.isLoad = false;
    })
    
  }

  patchControl(control:FormControl, val) {
    this.switched = true;
    control.patchValue(val)
    setTimeout(() => {
      this.switched = false
    },0)

    console.log("control", control.value)
  }

  patchTab(ctrl, val) {
    console.log(ctrl, val);
    this.switched = true;
    ctrl = val
    setTimeout(() => {
      this.switched = false
    },0)
  }

  closeTopOutletFilter() {
    this.topPerformingOutletsSub && this.topPerformingOutletsSub.unsubscribe();
    if (this.overlayTopPerformingRef) {
      this.overlayTopPerformingRef.dispose();
      this.overlayTopPerformingRef = null;
    }
  }

  openTopOutletFilter(el, val) {
    this.closeTopOutletFilter();
    let x = el.getBoundingClientRect().left;
    let y =  el.getBoundingClientRect().top + el.getBoundingClientRect().height;
    console.log(el)
    console.log({ x, y })
    const positionStrategy = this.overlay.position()
    .flexibleConnectedTo({ x, y })
    .withPositions([
      {
        originX: 'start',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'top',
      }
    ]);

    this.overlayTopPerformingRef = this.overlay.create({
      backdropClass: 'transparent_bg',
      hasBackdrop: true,
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });

    this.overlayTopPerformingRef.attach(new TemplatePortal(this.topPerformingOutletsMenu, this.viewContainerRef, {
      $implicit: val
    }));
    
    this.topPerformingOutletsSub = fromEvent<MouseEvent>(document, 'click')
      .pipe(
        filter(event => {
          const clickTarget = event.target as HTMLElement;
       
          let check1 = !!this.overlayTopPerformingRef && !this.overlayTopPerformingRef.overlayElement.contains(clickTarget)
          // let check2 = (document.getElementsByClassName('filter_autocomlete').length > 0 && !document.getElementsByClassName('filter_autocomlete')[0].contains(clickTarget))
          // let check3 = (document.getElementsByClassName('filter_autocomlete').length == 0);
          // let check4 = (clickTarget.classList.contains('mat-option-text') || clickTarget.classList.contains('mat-option'))
       
//(check1 && check2) || (check1 && check3 && !check4)
          return check1
        }),
        take(1)
      ).subscribe(() => this.closeTopOutletFilter())

  }

  getViewColumns() {
    console.log("getViewColumns")
    if ((this.filter.value.group_id || this.filter.value.group_id.toString() == '0') && (this.filter.value.channel_id || this.filter.value.channel_id.toString() == '0' || this.filter.value.platform_id || this.filter.value.platform_id.toString() == '0')) {
      console.log("getViewColumns 1")
      return [{
        name: ''
      }]
    }

    if ((this.filter.value.group_id || this.filter.value.group_id.toString() == '0') && !(this.filter.value.channel_id || this.filter.value.channel_id.toString() == '0' || this.filter.value.platform_id || this.filter.value.platform_id.toString() == '0')) {
      console.log("getViewColumns 2")
      return this.getViewPlatforms()
    } else {
      console.log("getViewColumns 3")
      return this.getViewGroups()
    }
  }

  getViewPlatforms() {
    // console.log("getViewPlatforms");
    if (!this.platforms) {
      return []
    }

    let viewPlatforms = []

    let filterGroup = this.getGroup(this.filter.value.group_id)
    if (filterGroup && filterGroup.channels && filterGroup.channels.length) {
      filterGroup.channels.forEach(chan => {
        if (viewPlatforms.filter(x => x.id == chan.platform_id).length == 0) {
          viewPlatforms.push(chan.platform)
        }          
      });
    }

    if (this.urls.length) {
      this.urls.forEach(x => {
        if (!x.channel && viewPlatforms.filter(b => b.id == 0).length == 0) {
          viewPlatforms.push(this.platforms[0]);
        } else if (viewPlatforms.filter(b => b.id == x.channel.platform_id).length == 0) {
          // if ()
          // x.channel.platform.isAdd = true;
          let platform = this.getPlatformById(x.channel.platform_id)
          platform.isAdd = true;
          viewPlatforms.push(platform);
        }
      })
    }

    console.log("viewPlatforms", viewPlatforms)
    return viewPlatforms
  }

  getViewGroups() {
    if (!this.groups) {
      return []
    }



    if (this.urls.length && !this.filter.value.channel_id && this.filter.value.channel_id.toString() == "" && !this.filter.value.platform_id && this.filter.value.platform_id.toString() == '') {
      let viewGroups = []
      console.log("WITHOUT FILTER")

      this.urls.forEach(x => {
        if ((!x.task || (!!x.task && x.task.group_id == 0)) && viewGroups.filter(b => b.id == 0).length == 0) {
          viewGroups.push(this.groups[0]);
        } else if (!!x.task && viewGroups.filter(b => b.id == x.task.group_id).length == 0) {
          let group = this.getGroup(x.task.group_id)
          // group.isAdd = true;
          viewGroups.push(group);
        }
      })

      return viewGroups

    } else {
      let viewGroups = this.groups.slice()

      if (!!this.filter.value.channel_id || this.filter.value.channel_id.toString() == '0' || !!this.filter.value.platform_id || this.filter.value.platform_id.toString() == '0') {
        viewGroups = viewGroups.filter(x => this.urls.filter(b => (!b.task && x.id == 0) || (!!b.task && b.task.group_id == x.id)).length > 0)
      }
      return viewGroups
    }
  }

  userChanged({ event, newUser }) {
    event.color = newUser.color;
    event.meta.user = newUser;
    this.urls = [...this.urls];
  }

  // getGroupsPartnerCompany() {
  //   console.log("getGroupsPartnerCompany", this.filter.value.operation_partner_company_id);
  //   this.attachSubscriptions(
  //     this.companyService.getGroupsPartnerCompany(this.company.id, this.filter.value.operation_partner_company_id).subscribe(resp => {
  //       // this.partnerGroups = resp
  //       this.groups = resp;
  //       this.groups$.next(this.groups.slice());
  //       console.log("getGroupsPartnerCompany", resp);
  //     })
  //   )
  // }

  groupedEvents(array) {
    if (array && !!array.length) {
      let groupedArray = [];

      array.forEach(ev => {
        // console.log(ev);
        if (!ev.channel) {
          return
        }
        if (groupedArray.filter(x => x.platform_id == ev.channel.platform_id).length == 0) {
          let item = {
            platform_id: ev.channel.platform_id,
            profiles: [],
            contents: []
          }
          if (ev.meta.type == 'content') {
            item.contents.push(ev)
          } else {
            item.profiles.push(ev)
          }
          groupedArray.push(item);
        } else if (groupedArray.find(x => x.platform_id == ev.channel.platform_id).profiles.filter(b => b.id == ev.id).length == 0) {
          let item = groupedArray.find(x => x.platform_id == ev.channel.platform_id)
          if (ev.meta.type == 'content') {
            item.contents.push(ev)
          } else {
            item.profiles.push(ev)
          }
        }
      });

      return groupedArray;
    } else {
      return []
    }

  }

  goToUrls() {
    this.router.navigate(['/urls'], { queryParams: {company_id: this.company_id}});
  }


  openChat(id) {
    this.attachSubscriptions(
      this.chatService.getChatsExpand(1, this.company_id, {id: id}).pipe(
        map(res => res.body[0])
      ).subscribe(chat => {
        const goToChat = this.dialog.open(ChatsComponent, {
          backdropClass: ['backdrop_under_header', "without_pad"],
          panelClass: !this.is_mobile ? ['full_screen_dialog', 'global_chats_dialog', 'show_header'] : ['full_screen_dialog', 'global_chats_dialog'],
          position: !this.is_mobile ? { top: '58px', left: '0', right: '0', bottom: '0' } : {},
          data: {
            company: this.company,
            user: this.user,
            imgRoute: this.imgRoute,
            host: this.host,
            dialogType: 'global_chat',
            activeLang: this.activeLang, 
            chat: chat
          }
        });
      })
    )
  }

  onPartnerImgError(event){
    event.target.src = this.imgRoute+'/assets/img/partner.png'
  }

  goToChat(chat) {
    const goToChat = this.dialog.open(OperationChatComponent, {
      backdropClass: ['backdrop_under_header', "without_pad"],
      panelClass: ['full_screen_dialog', 'global_chats_dialog'],
      disableClose: true,
      data: {
        company: this.company,
        task: this.urls[0],
        tasks: this.urls,
        dialogType: 'operation_chat',
        operationsValues: this.operationsValues,
        work: this.urls[0].operations.find(el => el.id == this.linkTo.split("_")[2]),
        user: this.user,
        empl_status: this.company.permissions.includes('owner') || this.company.permissions.includes('admin') || this.company.permissions.includes('manager'),
        chat: chat,
        chats: this.urls[0].operations.find(el => el.id == this.linkTo.split("_")[2]).chatsInfo
      }
    });

    this.attachSubscriptions(
      goToChat.afterClosed().subscribe(result => {
        if (!!result && result.event == 'close') {
          this.chatService.postChatRead(chat.id, 0, this.company.id).subscribe(resp => {
            console.log(resp);
          })
        }
      })
    )
  }

  getStatusById(id) {
    if (!this.profileStatuses || this.profileStatuses.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.profileStatuses.find(el => el.id == id)
  }

  getContentById(id) {
    if (!this.allContentTypes || this.allContentTypes.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.allContentTypes.find(el => el.id == id)
  }

  getPlatformById(id) {
    if (!this.platforms || this.platforms.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.platforms.find(el => el.id == id)
  }

  getChannelById(id) {
    if (!this.profiles || this.profiles.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.profiles.find(el => el.id == id)
  }

  getTaskStatusById(id) {
    if (!this.taskStatuses || this.taskStatuses.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.taskStatuses.find(el => el.id == id)
  }
  
  onMobScroll() {
    if (this.mobScrollSub) {
      this.mobScrollSub.unsubscribe()
    }
    this.mobScrollSub = fromEvent(window, "scroll").pipe(
      map(() => window.scrollY),
      tap((val) => {
        val > 100 ? this.isScrolledCollectionBtn = true : this.isScrolledCollectionBtn = false;
      }),
      filter(current => current >=  document.body.clientHeight - window.innerHeight - 100),
      debounceTime(300),
      distinctUntilChanged()
    ).subscribe(() => {
      if (this.page > 2 && this.page <= this.pagination['pageCount']) {
        this.getTopDash(this.page);
      }
    })
  }

  // onScroll() {
  //   if (this.scrollSub) {
  //     this.scrollSub.unsubscribe()
  //   }
  //   // tap((e:any) => console.log(e)),
  //   this.scrollSub = fromEvent(this.urlsContainer.nativeElement, "scroll").pipe(
  //     filter((e:any) => e.target.scrollTop >=  e.target.scrollHeight - e.target.offsetHeight - 400),
  //     debounceTime(300)
  //   ).subscribe(() => {
  //     if (this.page > 2 && this.page <= this.pagination['pageCount']) {
  //       this.getUrls(this.page);
  //     }
  //   })
  
  // }

  // @HostListener('window:scroll', ['$event']) // for window scroll events
  // onMobileScroll(e) {
  //   if (this.is_mobile) {
  //     window.pageYOffset > 100 ? this.isScrolledCollectionBtn = true : this.isScrolledCollectionBtn = false;
  //   }
  // }

  getPartners() {
    this.attachSubscriptions(
      this.companyService.getPartners({company_id: this.company_id}).subscribe(resp => {
        this.partners = resp.filter(x => x.partner_company_id != 0);
        console.log("getPartners", this.partners)
        this.partners$.next(this.partners.slice())
      }, error => {
        console.log(error)
      })
    )
  }

  open({ x, y }: MouseEvent, val) {
    this.close();
    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo({ x, y })
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        }
      ]);

    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });

    this.overlayRef.attach(new TemplatePortal(this.taskMenu, this.viewContainerRef, {
      $implicit: val
    }));
    
    this.sub = fromEvent<MouseEvent>(document, 'click')
      .pipe(
        filter(event => {
          const clickTarget = event.target as HTMLElement;
          return !!this.overlayRef && !this.overlayRef.overlayElement.contains(clickTarget);
        }),
        take(1)
      ).subscribe(() => this.close())

  }

  close() {
    this.sub && this.sub.unsubscribe();
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
  }

  checkboxLabel(row?): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.profileCollection.isSelected(row) ? 'deselect' : 'select'} task ${row.name}`;
  }

  isAllSelected() {
    const numSelected = this.profileCollection.selected.length;
    const numRows = this.urls.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
        this.profileCollection.clear() :
        this.urls.forEach(row => this.profileCollection.select(row));
  }

  toggleProfile(profile) {
    this.profileCollection.toggle(profile);
  }

  getCurrencyList() {
    this.attachSubscriptions(
      this.globalDataService.getCurrencies().subscribe(resp => {
        console.log("getCurrencyList", resp)
        let currencyData = resp.slice()
        currencyData.map(x => x.summary = 0)
        this.currencyes = currencyData;
        this.totalInfo['summaryPrice'] = [...currencyData]
        console.log("this.totalInfo", this.totalInfo);
      })
    )
  }

  getOperationStatusName(id) {
    switch (+id) {
      case 1:
        return "Waiting";
        break;
      case 2:
        return "In process";
        break;
      case 3:
        return "To approve";
        break;
      case 4:
        return "Approved";
        break;
      case 5:
        return "To correct";
        break;
      case 6:
        return "Sources Needed";
        break;
      case 97:
        return "On hold";
        break;
      case 98:
        return "Ready";
        break;
      case 99:
        return "Canceled";
        break;
    }
  }

  getColumnName(id) {
    if (!this.operationsValues || this.operationsValues.filter(el => el.id == id).length == 0) {
      return '';
    }
    return this.operationsValues.find(el => el.id == id)?.translation?.name ? this.operationsValues.find(el => el.id == id).translation.name : this.operationsValues.find(el => el.id == id).name
  }

 
  dropped(papa, ph, arr) {
    if (!this.target) return;

    const parent: HTMLElement = papa;
    const phElement: HTMLElement = ph;
    const phElementIndex = __indexOf(parent.children, phElement);

    phElement.style.display = 'none';
    parent.removeChild(phElement);
    parent.appendChild(phElement);

    parent.insertBefore(
      this.source.element.nativeElement,
      parent.children[this.sourceIndex]
    );

    console.log(this.sourceIndex, ' => ', phElementIndex);

    if (this.sourceIndex != phElementIndex) {
      moveItemInArray(arr, this.sourceIndex - 1, phElementIndex - 1);

      let sortBookmarks:any[] = [];

      this.bookmarks.forEach((el, i) => {
        sortBookmarks.push({
          "path": `/api/section/${el.id}/`,
          "query": {company_id: this.company_id},
          "method": "POST",
          "body": {
            [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
            order: i
          }
        })
      })
  
      this.attachSubscriptions(
        this.taskService.multiRequest(sortBookmarks).subscribe(resp => {
          console.log("multiRequest", resp)
          resp.forEach(el => {
            if (el.body && el.body.id) {
              this.bookmarks.find(x => x.id == el.body.id).order = el.body.order
            }
          })
        })
      )
      
    }

    this.target = null;
    this.targetIndex = undefined;
    this.source = null;
    this.sourceIndex = undefined;
  }

  entered({ item, container }: CdkDragEnter, papa, ph) {
    const phElement: HTMLElement = ph;
    const dropElement: HTMLElement = container.element.nativeElement;
    const prevTarget: CdkDropList = this.target;
    const prevTargetIndex: number = this.targetIndex;
    this.target = container;

    const dropElementIsTheSource: boolean = !dropElement.parentNode;
    const prevAndCurrentTargetAreTheSame: boolean = this.target === prevTarget;
    if (dropElementIsTheSource || prevAndCurrentTargetAreTheSame) {
      return;
    }

    this.targetIndex = __indexOf(dropElement.parentNode.children, dropElement);

    if (!this.source) {
      this.source = item.dropContainer;
      this.sourceIndex = __indexOf(
        dropElement.parentNode.children,
        item.dropContainer.element.nativeElement
      );
      const sourceElement: HTMLElement = this.source.element.nativeElement;

      this.fixPhElementStyling(phElement, sourceElement);

      sourceElement.parentNode.removeChild(sourceElement);
    }

    const index: number = prevTargetIndex ?? this.sourceIndex;
    const insertAfter: boolean = index < this.targetIndex;

    papa.insertBefore(
      phElement,
      insertAfter ? dropElement.nextSibling : dropElement
    );
  }
  
  dragReleased(ph, dragPh) {
    console.log('ph, dragPh', ph, dragPh)
    const phElementPositionWasChanged: boolean = !!this.source;
    if (phElementPositionWasChanged) {
      // console.log("dragReleased dragPh", ph, dragPh)
      dragPh.nativeElement.style.transform = 'none';
      dragPh.nativeElement.parentNode.removeChild(
        dragPh.nativeElement
      );
      ph.appendChild(
        dragPh.nativeElement
      );
    }
  }
  
  private fixPhElementStyling(
    phElement: HTMLElement,
    sourceElement: HTMLElement
  ) {
    phElement.style.width = sourceElement.clientWidth - 6 + 'px';
    phElement.style.height = sourceElement.clientHeight - 6 + 'px';

    const size = Array.from(sourceElement.classList).find((c) =>
      c.startsWith('content-item-c')
    );

    phElement.style.display = '';
    const oldSize = Array.from(phElement.classList).find((c) =>
      c.startsWith('content-item-c')
    );
    if (oldSize) {
      phElement.classList.remove(oldSize);
    }
    if (size) {
      phElement.classList.add(size);
    }
  }
  
  ngOnDestroy(): void {
    this.layoutService.overflowAuto();
    if (this.scrollSub) {
      this.scrollSub.unsubscribe()
    }
    if (this.topChartSub) {
      this.topChartSub.unsubscribe()
    }
    if (this.spikesChartSub) {
      this.spikesChartSub.unsubscribe()
    }
    if (this.mobScrollSub) {
      this.mobScrollSub.unsubscribe()
    }
    if (this.urlsSub) {
      this.urlsSub.unsubscribe()
    }
    if (this.contentSub) {
      this.contentSub.unsubscribe()
    }
    if (this.urlsDataSub) {
      this.urlsDataSub.unsubscribe();
    }
    this.clearSubscriptions()
  }

}
function __indexOf(collection, node) {
  return Array.prototype.indexOf.call(collection, node);
};
