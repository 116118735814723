import { CdkDrag, CdkDragDrop, CdkDragEnter, CdkDragMove, CdkDropList, CdkDropListGroup, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { ViewportRuler } from '@angular/cdk/overlay';
import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { switchMap } from 'rxjs/operators';
import { BaseClass } from 'src/app/shared/models/base-class';
import { FileService } from 'src/app/shared/services/rest/file.service';
import { environment } from 'src/environments/environment';

export interface MenuOptions {
  rowSelector: string;
  submenuSelector: string;
  submenuDirection: 'left' | 'right' | 'above' | 'below';
  tolerance: number;
  enter(): void;
  exit(): void;
  activate(row: HTMLElement): void;
  deactivate(row: HTMLElement): void;
  exitMenu(): boolean;
}
@Component({
  selector: 'app-test-ve',
  templateUrl: './test-ve.component.html',
  styleUrls: ['./test-ve.component.scss']
})



export class TestVeComponent extends BaseClass implements OnInit, AfterViewInit {
  @ViewChild('partDragPlaceholder') partDragPlaceholder: ElementRef;
  @ViewChild('partPlaceholder') partPlaceholder: any;
  // @ViewChild('content', { static: true }) content: ElementRef;


  x: number;
  y: number;
  px: number;
  py: number;
  width: number;
  height: number;
  minArea: number;
  draggingCorner: boolean;
  draggingWindow: boolean;
  resizer: Function;


  base64Svg: string;
  safeSvgUrl: SafeResourceUrl;
  public arr = [
    {id: 0, name: "!024 Big Medium and Small Food Challenge 2 RPP_ENG.mov"},
    {id: 1, name: "!024 Big Medium and Small Food Challenge 2 RPP_RU.mov"},
    {id: 2, name: "!024 Big Medium and Small Food Challenge 2 RPP_FR.mov"},
    {id: 3, name: "!024 Big Medium and Small Food Challenge 2 RPP_UK.mov"},
    {id: 4, name: "!024 Big Medium and Small Food Challenge 2 RPP_AU.mov"},
    {id: 5, name: "B !032 Small Week i Big Squad Challenge 2 RPP_ENG.mov"},
    {id: 6, name: "!032 Small Week i Big Squad Challenge 2 RPP_RU.mov"},
    {id: 7, name: "!032 Small Week i Big Squad Challenge 2 RPP_FR.mov"},
    {id: 8, name: "!032 Small Week i Big Squad Challenge 2 RPP_UK.mov"},
    {id: 9, name: "!032 Small Week i Big Squad Challenge 2 RPP_AU.mov"},
  ]

  public menuOptions: MenuOptions = {
    rowSelector: '.menu-item',
    submenuSelector: '.submenu',
    submenuDirection: 'right', 
    tolerance: 75,
    enter: () => {
      console.log('Enter menu');
    },
    exit: () => {
      console.log('Exit menu');
    },
    activate: (row: HTMLElement) => {
      row.classList.add('active');
      console.log('Activate menu item');
    },
    deactivate: (row: HTMLElement) => {
      row.classList.remove('active');
      console.log('Deactivate menu item');
    },
    exitMenu: () => {
      console.log('Exit menu');
      return true; // Возвращает true, чтобы деактивировать активный пункт меню при выходе из меню
    }
  };

  public items: Array<number> = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  public target: CdkDropList;
  public targetIndex: number;
  public source: CdkDropList;
  public host: any = environment.host;
  public testTime: number = 213.1567884109989;
  public sourceIndex: number;
  public audioFile:any = {
    "id": 9550,
    "company_id": 18,
    "task_id": 617,
    "task_operation_id": 0,
    "discussion_id": 0,
    "payment_id": 0,
    "original_file_id": 9209,
    "acm": "y3tSOXIqmY9uSIQg5",
    "original": "/file/v/P/good-night-160166.vPz.mp3",
    "filesize": 4707892,
    "original_filesize": 0,
    "thumbnail": "",
    "thumbnail_filesize": 0,
    "preview1080": "/file/v/P/preview.1080.vPz.m4a",
    "preview1080_filesize": 2393639,
    "filename": "good-night-160166.mp3",
    "hash": "vPz",
    "extension": "mp3",
    "content_type": "audio/mpeg",
    "location": "/audios",
    "is_s3": 1,
    "is_mh": 0,
    "is_dir": 0,
    "is_document": 0,
    "is_uploaded": 1,
    "is_public": 0,
    "is_public_registered": 0,
    "is_rev_transcribe": 0,
    "is_preview": 1,
    "is_ready_if_approved": 0,
    "operation_reminder_id": 0,
    "operation_reminder_name": "",
    "output_video_project_id": 0,
    "copied_from_task_id": 0,
    "created_user_id": 3,
    "created_employee_id": 58,
    "user_upload_progress": 0,
    "user_upload_speed_mbit": 8,
    "user_geo_country": "UA",
    "storage_code": "ams3",
    "files_count": 0,
    "documents_count": 0,
    "copies_count": 2,
    "related_count": 0,
    "part_of_count": 2,
    "consist_of_count": 14,
    "meta": "",
    "meta_width": 0,
    "meta_height": 0,
    "meta_has_audio": 1,
    "meta_duration": 148,
    "meta_creation_time": 0,
    "meta_creation_time_offset": 0,
    "meta_latitude": "0.0000000",
    "meta_longitude": "0.0000000",
    "meta_device": "",
    "custom_upload_id": 0,
    "created_at": 1694796193,
    "updated_at": 1694796193,
    "upload_url": null,
    "download_url": "/file/v/P/good-night-160166.vPz.mp3",
    "userIp": "92.38.41.91",
    "taskOperation": null,
    "discussion": null,
    "document": null,
    "fileOriginalData": {
      "id": 8571,
      "original_file_id": 9209,
      "meta": {
        "type": "audio",
        "has_audio": 1,
        "codec_name": "mp3",
        "duration": 148,
        "duration_ms": 147122
      },
      "is_rev_identification_done": 0,
      "rev_identification_job_id": "",
      "rev_identification_job_data": null,
      "is_rev_transcription_done": 0,
      "rev_transcription_job_id": "",
      "transcription_plain": null,
      "transcription_json": null,
      "transcription_srt": null,
      "transcription_vtt": null,
      "created_at": 1702553094,
      "updated_at": 1702553094
    },
    "archives": [],
    "parameterValuesToTask": [
      {
        "id": 8351,
        "company_id": 18,
        "parameter_id": 51,
        "parameter_value_id": 120,
        "task_id": 585,
        "task_operation_id": 0,
        "discussion_id": 0,
        "file_id": 9550,
        "is_primary": 0,
        "is_automatically_assigned": 0,
        "created_user_id": 0,
        "updated_user_id": 0,
        "created_at": 1694796193,
        "updated_at": 1694796193,
        "parameter": {
          "id": 51,
          "company_id": 18,
          "name": "File Type",
          "original_id": 39,
          "task_id": 0,
          "task_acm": "",
          "tips_count": 0,
          "is_new_value": 0,
          "is_file_meta_device": 0,
          "is_file_meta_orientation": 0,
          "is_file_type": 1,
          "is_file_info": 0,
          "is_geo_country": 0,
          "is_geo_state": 0,
          "is_geo_city": 0,
          "original_user_id": 3,
          "original_company_id": 18,
          "created_user_id": 3,
          "updated_user_id": 3,
          "created_at": 1683223115,
          "updated_at": 1683223115
        },
        "parameterValue": {
          "id": 120,
          "company_id": 18,
          "parameter_id": 51,
          "parent_value_id": 0,
          "value": "Audio",
          "system_value_id": 0,
          "is_not_automatically_assigned": 0,
          "original_id": 82,
          "task_id": 0,
          "task_acm": "",
          "order": 3374617158,
          "tips_count": 0,
          "original_user_id": 3,
          "original_company_id": 18,
          "created_user_id": 3,
          "updated_user_id": 3,
          "created_at": 1683223115,
          "updated_at": 1683223115
        }
      },
      {
        "id": 8352,
        "company_id": 18,
        "parameter_id": 51,
        "parameter_value_id": 120,
        "task_id": 617,
        "task_operation_id": 0,
        "discussion_id": 0,
        "file_id": 9550,
        "is_primary": 0,
        "is_automatically_assigned": 0,
        "created_user_id": 0,
        "updated_user_id": 0,
        "created_at": 1694796193,
        "updated_at": 1694796193,
        "parameter": {
          "id": 51,
          "company_id": 18,
          "name": "File Type",
          "original_id": 39,
          "task_id": 0,
          "task_acm": "",
          "tips_count": 0,
          "is_new_value": 0,
          "is_file_meta_device": 0,
          "is_file_meta_orientation": 0,
          "is_file_type": 1,
          "is_file_info": 0,
          "is_geo_country": 0,
          "is_geo_state": 0,
          "is_geo_city": 0,
          "original_user_id": 3,
          "original_company_id": 18,
          "created_user_id": 3,
          "updated_user_id": 3,
          "created_at": 1683223115,
          "updated_at": 1683223115
        },
        "parameterValue": {
          "id": 120,
          "company_id": 18,
          "parameter_id": 51,
          "parent_value_id": 0,
          "value": "Audio",
          "system_value_id": 0,
          "is_not_automatically_assigned": 0,
          "original_id": 82,
          "task_id": 0,
          "task_acm": "",
          "order": 3374617158,
          "tips_count": 0,
          "original_user_id": 3,
          "original_company_id": 18,
          "created_user_id": 3,
          "updated_user_id": 3,
          "created_at": 1683223115,
          "updated_at": 1683223115
        }
      }
    ],
    "createdEmployee": {
      "id": 58,
      "company_id": 18,
      "user_id": 3,
      "vuser_id": 0,
      "name": "Alexandr",
      "user_name": "Alexandr",
      "company_name": "Alexandr",
      "partner_name": "Alexandr",
      "timezone": "Europe/Kyiv",
      "timezone_offset": 7200,
      "status": "inactive",
      "status_active_at": 1700168431,
      "image": "https://api-dev.reports.re/file/6/n/thumbnail.6nc.jpg",
      "description": "",
      "invite": "",
      "rate_target": "0.0000",
      "salary": "0.0000",
      "currency_id": 0,
      "application_status_id": 3,
      "is_application_done": 0,
      "is_company_image": 0,
      "is_partner_image": 0,
      "is_deleted": 0,
      "created_at": 1648118613,
      "updated_at": 1700169277,
      "employee_count": null
    },
    "taskChannelFiles": [],
    "open_to_left": false,
    "countSameFiles": 1
  }
  public file = {
    acm: "UPPf5IuMtHQRQKCJO",
    company_id: 18,
    consist_of_count: 5,
    content_type: "video/mp4",
    copies_count: 1,
    created_at: 1693580068,
    created_employee_id: 58,
    created_user_id: 3,
    crop_on: false,
    custom_upload_id: 0,
    discussion: null,
    discussion_id: 0,
    document: null,
    documents_count: 0,
    download_url: "/file/v/P/eminem-legends-never-die-ft-linkin-park-alan-walker-video-clip1.vPB.mp4",
    extension: "mp4",
    fileOriginalData: null,
    filename: "ww Eminem - Legends Never Die.mp4",
    filesize: 43933548,
    hash: "vPB",
    id: 9206,
    is_audio_only: 1,
    is_dir: 0,
    is_document: 0,
    is_mh: 0,
    is_open: false,
    is_preview: 1,
    is_public: 0,
    is_public_registered: 0,
    is_ready_if_approved: 0,
    is_rev_transcribe: 0,
    is_s3: 1,
    is_uploaded: 1,
    is_video_only: 1,
    location: "/videos",
    meta: '{"creation_time":"2022-10-16T13:29:08.000000Z","type":"video","has_video":1,"codec_name":"h264","width":1280,"height":720,"duration":199,"duration_ms":198434,"has_audio":1,"device":"ISO Media file produced by Google Inc. Created on: 10\\/16\\/2022."}',
    meta_creation_time: 1665926948,
    meta_creation_time_offset: 0,
    meta_device: "ISO Media file produced by Google Inc. Created on:",
    meta_duration: 199,
    meta_has_audio: 1,
    meta_height: 720,
    meta_latitude: "0.0000000",
    meta_longitude: "0.0000000",
    meta_width: 1280,
    myId: "WtdwExWw5v",
    operation_reminder_id: 0,
    operation_reminder_name: "",
    optionsCount: 10,
    original: "/file/v/P/eminem-legends-never-die-ft-linkin-park-alan-walker-video-clip1.vPB.mp4",
    original_file_id: 9206,
    pId: "9vkaasjcQF",
    part_of_count: 1,
    part_speed_rate: 1,
    payment_id: 0,
    preview1080: "/file/v/P/preview.1080.vPB.mp4",
    preview1080_filesize: 42858067,
    related_count: 0,
    storage_code: "ams3",
    taskOperation: null,
    task_id: 585,
    task_operation_id: 0,
    thumbnail: "/file/v/P/thumbnail.vPB.jpg",
    thumbnail_filesize: 27353,
    trackElIndex: 0,
    updated_at: 1694011367,
    upload_url: null,
    userIp: "92.38.41.91",
    user_geo_country: "UA",
    user_upload_progress: 0,
    user_upload_speed_mbit: 51,
    volume: 1
  }

  public pngUrl: any = null;
  public selected: FormControl = new FormControl([])
  public testArray = [];
  constructor(
    private http: HttpClient,
    private fileService: FileService,
    private sanitizer: DomSanitizer,
  ) { super() }

  ngOnInit(): void {
    // this.downloadFile('https://api-dev.reports.re/file/v/P/preview.1080.vPB.mp4?company_id=18&download_url=1', this.file)

    for (let i = 0; i < 100; i++) {
      this.testArray.push({
        id: i,
        name: `Option ${i}`
      })
    }

    this.x = 300;
    this.y = 100;
    this.px = 0;
    this.py = 0;
    this.width = 600;
    this.height = 300; 
    this.draggingCorner = false;
    this.draggingWindow = false;
    this.minArea = 20000

    console.log("findMatches", this.findMatches("B !032 Small Week i Big Squad Challenge 2 RPP", this.arr));
    console.log("this.testArray");
  }

  area() {
    return this.width * this.height;
  }

  onWindowPress(event: MouseEvent) {
    this.draggingWindow = true;
    this.px = event.clientX;
    this.py = event.clientY;
  }

  onWindowDrag(event: MouseEvent) {
     if (!this.draggingWindow) {
        return;
    }
    let offsetX = event.clientX - this.px;
    let offsetY = event.clientY - this.py;

    this.x += offsetX;
    this.y += offsetY;
    this.px = event.clientX;
    this.py = event.clientY;
  }

  topLeftResize(offsetX: number, offsetY: number) {
    this.x += offsetX;
    this.y += offsetY;
    this.width -= offsetX;
    this.height -= offsetY;
  }

  topRightResize(offsetX: number, offsetY: number) {
    this.y += offsetY;
    this.width += offsetX;
    this.height -= offsetY;
  }

  bottomLeftResize(offsetX: number, offsetY: number) {
    this.x += offsetX;
    this.width -= offsetX;
    this.height += offsetY;
  }

  bottomRightResize(offsetX: number, offsetY: number) {
    this.width += offsetX;
    this.height += offsetY;
  }

  onCornerClick(event: MouseEvent, resizer?: Function) {
    this.draggingCorner = true;
    this.px = event.clientX;
    this.py = event.clientY;
    this.resizer = resizer;
    event.preventDefault();
    event.stopPropagation();
  }

  @HostListener('document:mousemove', ['$event'])
  onCornerMove(event: MouseEvent) {
    if (!this.draggingCorner) {
        return;
    }
    let offsetX = event.clientX - this.px;
    let offsetY = event.clientY - this.py;

    let lastX = this.x;
    let lastY = this.y;
    let pWidth = this.width;
    let pHeight = this.height;

    this.resizer(offsetX, offsetY);
    if (this.area() < this.minArea) {
        this.x = lastX;
        this.y = lastY;
        this.width = pWidth;
        this.height = pHeight;
    }
    this.px = event.clientX;
    this.py = event.clientY;
  }

  @HostListener('document:mouseup', ['$event'])
  onCornerRelease(event: MouseEvent) {
    this.draggingWindow = false;
    this.draggingCorner = false;
  }

  log() {
    console.log("this.audioFile", this.audioFile)
    console.log("this.file", this.file)
  }

  loadedAudioZero(event: Event) {
    const audioElement = event.target as HTMLAudioElement;
    const audioSrc = audioElement.src;
    this.http.get(audioSrc, { responseType: 'arraybuffer' }).subscribe((arrayBuffer) => {
      console.log("arrayBuffer 1", arrayBuffer)
      // Декодирование аудиофайла
      this.decodeAudioData(arrayBuffer);
    });
  }

  loadedAudio(event: Event) {
    const audioElement = event.target as HTMLAudioElement;
    const audioSrc = audioElement.src;
    this.attachSubscriptions(
      this.fileService.getSrc(audioSrc).pipe(
        switchMap((el:any) => {
          return this.fileService.downloadFileFromUrl(el.download_url, this.audioFile)
        })
      ).subscribe((resp:any) => {
        console.log("arrayBuffer downloadFile", resp);
        if (resp.content && resp.state == "DONE") {
          let newSrc = URL.createObjectURL(resp.content);
          this.audioFile.blobSrc = newSrc;
          this.audioFile.blob = resp.content;
          // const videoElement = document.createElement("video");
          // videoElement.src = newSrc;

          this.http.get(newSrc, { responseType: 'arraybuffer' }).subscribe((arrayBuffer) => {
            console.log("arrayBuffer 1", arrayBuffer)
            // Декодирование аудиофайла
            this.decodeAudioData(arrayBuffer, false);
          });
          
          // Append the video element to the DOM to display it.
          // document.body.appendChild(videoElement);
          
          // You can optionally specify attributes and controls for the video element.
          // videoElement.setAttribute("controls", "true");
        }
      })
    )
    // this.fileService.getSrc(audioSrc).pipe(
    //   switchMap((el:any) => {
    //     console.log("arrayBuffer 0", el)
    //     return this.http.get(el.download_url, { responseType: 'arraybuffer' })
    //     // return this.fileService.downloadFileFromUrl(el.download_url, file)
    //   })
    // ).subscribe((arrayBuffer) => {
    //   console.log("arrayBuffer 1", arrayBuffer)
    //   // Декодирование аудиофайла
    //   this.decodeAudioData(arrayBuffer);
    // });
    // this.http.get(audioSrc, { responseType: 'arraybuffer' }).subscribe((arrayBuffer) => {
    //   console.log("arrayBuffer 1", arrayBuffer)
    //   // Декодирование аудиофайла
    //   this.decodeAudioData(arrayBuffer);
    // });
  }

  findMatches(query: string, data) {
    const queryTokens = query.toLowerCase().split(/[\s+\-_]+/); // Разбиваем строку запроса на отдельные токены
    
    console.log("findMatches queryTokens", queryTokens);
    let maxMatches = 0;
    let matchingItems = [];

    for (const item of data) {
        let matches = 0;
        const itemNameTokens = item.name.toLowerCase().split(/[\s+\-_]+/); // Разбиваем имя элемента на токены

        for (const token of queryTokens) {
            if (itemNameTokens.includes(token)) { // Если токен из запроса есть в имени элемента, увеличиваем счетчик совпадений
                matches++;
            }
        }

        // if (matches > maxMatches) { // Если текущий элемент имеет больше совпадений, чем предыдущие элементы, обновляем максимальное число совпадений и список совпадающих элементов
        //     maxMatches = matches;
        //     matchingItems = [item];
        // } else if (matches === maxMatches) { // Если текущий элемент имеет такое же количество совпадений, добавляем его в список совпадающих элементов
        // }
        item.matches = matches;
        matchingItems.push(item);
    }

    return {matchingItems, maxMatches};
  }

  decodeAudioData(arrayBuffer: ArrayBuffer, is_zero: boolean = true) {
    console.log("arrayBuffer 2", arrayBuffer)
    const audioContext = new (window.AudioContext || (window as any).webkitAudioContext)();
  
    audioContext.decodeAudioData(arrayBuffer, (audioBuffer) => {
      // audioBuffer теперь содержит декодированное аудио
      console.log('Аудио успешно декодировано:', audioBuffer);
      let channel = audioBuffer.getChannelData(0);
  
      console.log("channel", channel)
      var allMin = 0;
      var allMax = 0;
      for (var i = 0; i < channel.length; i++) {
        allMin = channel[i] < allMin ? channel[i] : allMin;
        allMax = channel[i] > allMax ? channel[i] : allMax;
      }
      let allAvg = (allMin + allMax) / 2;
  
      let svg = document.getElementById(is_zero ? 'waveformSvg_0' : 'waveformSvg') as HTMLElement;
      svg.innerHTML = ""; // Clear previous content
  
      let width = 800;
      let height = 100;
  
      for (var i = 0; i < width; i++) {
        var min = 0;
        var max = 0;
        let sampleFrom = Math.floor(channel.length * i / width);
        let sampleTo = Math.floor(channel.length * (i + 1) / width);
        var rms = 0;
  
        // считаем минимум и максимум на участке + среднее квадратичное
        for (var j = sampleFrom; j < sampleTo; j++) {
          min = channel[j] < min ? channel[j] : min;
          max = channel[j] > max ? channel[j] : max;
          rms += channel[j] * channel[j] / (sampleTo - sampleFrom);
        }
  
        var fromY = Math.floor(height * (allMax - min) / (allMax - allMin));
        var toY = Math.floor(height * (allMax - max) / (allMax - allMin));
  
        var rmsY = Math.floor(height / 2 + height * (allAvg - Math.sqrt(rms)) / (allMax - allMin));
  
        // Create SVG elements for each segment
        let rect1 = document.createElementNS("http://www.w3.org/2000/svg", "rect");
        rect1.setAttribute("x", i.toString());
        rect1.setAttribute("y", fromY.toString());
        rect1.setAttribute("width", "1");
        rect1.setAttribute("height", (toY - fromY).toString());
        rect1.setAttribute("fill", '#414BD3');
  
        let rect2 = document.createElementNS("http://www.w3.org/2000/svg", "rect");
        rect2.setAttribute("x", i.toString());
        rect2.setAttribute("y", rmsY.toString());
        rect2.setAttribute("width", "1");
        rect2.setAttribute("height", "1");
        rect2.setAttribute("fill", '#777CE3');
  
        // Append the created elements to the SVG
        svg.appendChild(rect1);
        svg.appendChild(rect2);
      }
    });
  }

  // decodeAudioData(arrayBuffer: ArrayBuffer, is_zero:boolean = true) {
  //   console.log("arrayBuffer 2", arrayBuffer)
  //   const audioContext = new (window.AudioContext || (window as any).webkitAudioContext)();

  //   audioContext.decodeAudioData(arrayBuffer, (audioBuffer) => {
  //     // audioBuffer теперь содержит декодированное аудио
  //     console.log('Аудио успешно декодировано:', audioBuffer);
  //     let channel = audioBuffer.getChannelData(0);

  //     console.log("channel", channel)
  //     var allMin = 0;
  //     var allMax = 0;                    
  //     for (var i = 0; i < channel.length; i++) {
  //         allMin = channel[i] < allMin ? channel[i] : allMin;
  //         allMax = channel[i] > allMax ? channel[i] : allMax;
  //     }
  //     let allAvg = (allMin + allMax) / 2;
  //     let canvas = document.getElementById(is_zero ? 'waveformCanvas_0': 'waveformCanvas') as HTMLCanvasElement;
  //     let width = 800;
  //     let height = 100;
  //     canvas.width = width;
  //     canvas.height = height;
  //     let ctx = canvas.getContext('2d');
  //     ctx.fillStyle = '#CCCCCC';
  //     ctx.fillRect(0, 0, width, height);
  //     for (var i = 0; i < width; i++) {
  //         var min = 0;
  //         var max = 0;
  //         let sampleFrom = Math.floor(channel.length * i / width);
  //         let sampleTo = Math.floor(channel.length * (i + 1) / width);
  //         var rms = 0;
  //         // считаем минимум и максимум на участке + среднее квадратичное
  //         for (var j = sampleFrom; j < sampleTo; j++) {
  //             min = channel[j] < min ? channel[j] : min;
  //             max = channel[j] > max ? channel[j] : max;
  //             rms += channel[j] * channel[j] / (sampleTo - sampleFrom);
  //         }

  //         var fromY = Math.floor(height * (allMax - min) / (allMax - allMin));
  //         var toY = Math.floor(height * (allMax - max) / (allMax - allMin));
  //         ctx.fillStyle = '#414BD3';
  //         ctx.fillRect(i, fromY, 1, toY - fromY);

  //         rms = Math.sqrt(rms);
  //         var fromY = Math.floor(height / 2 + height * (allAvg - rms) / (allMax - allMin));
  //         var toY = Math.floor(height / 2 + height * (allAvg + rms) / (allMax - allMin));
  //         ctx.fillStyle = '#777CE3';
  //         ctx.fillRect(i, fromY, 1, toY - fromY);
  //     }
  //   });
  // }


  // convertToPNG() {
  //   const htmlContent = this.content.nativeElement.innerHTML;
  //   this.http.post('/api/convert-to-png/', { html: htmlContent }, { responseType: 'blob' }).subscribe(blob => {
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       // Sanitize the Data URL
  //       this.pngUrl = this.sanitizer.bypassSecurityTrustResourceUrl(reader.result as string) as SafeResourceUrl;
  //     };
  //     reader.readAsDataURL(blob);
  //     // this.pngUrl = window.URL.createObjectURL(blob);
  //     console.log("convertToPNG blob", blob)
  //     const url = window.URL.createObjectURL(blob);
  //     const a = document.createElement('a');
  //     a.href = url;
  //     a.download = 'converted.png';
  //     document.body.appendChild(a);
  //     a.click();
  //     window.URL.revokeObjectURL(url);
  //   });
  // }
 
  // convertHtmlToSvg() {
  //   const htmlContent = this.content.nativeElement.innerHTML;
  //   const svgNamespace = 'http://www.w3.org/2000/svg';


  //   const style = document.createElement('style');
  //   style.textContent = `
  //     @import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
  //   `;

  //   const svg = document.createElementNS(svgNamespace, 'svg');
  //   svg.setAttribute('xmlns', svgNamespace);
  //   svg.setAttribute('width', '1000');  // Set the desired width
  //   svg.setAttribute('height', '500'); // Set the desired height

  //   const htmlToSvg = (html: string) => {
  //     const svgContent = document.createElementNS(svgNamespace, 'foreignObject');
  //     svgContent.setAttribute('width', '100%');
  //     svgContent.setAttribute('height', '100%');
  //     svgContent.innerHTML = `<style>${style.textContent}</style>${html}`;
  //     svg.appendChild(svgContent);
  //   };

  //   htmlToSvg(htmlContent);

  //   const svgString = new XMLSerializer().serializeToString(svg);
  //   this.base64Svg = this.svgToBase64(svgString);
  //   this.safeSvgUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/svg+xml;base64,${this.base64Svg}`);
  // }

  // svgToBase64(svgString: string): string {
  //   return btoa(unescape(encodeURIComponent(svgString)));
  // }

  // downloadSvg() {
  //   const svgBlob = new Blob([atob(this.base64Svg)], { type: 'image/svg+xml' });
  //   const url = URL.createObjectURL(svgBlob);
  //   const a = document.createElement('a');
  //   a.href = url;
  //   a.download = 'image.svg';
  //   a.click();
  //   URL.revokeObjectURL(url);
  // }

  downloadFile(src, file) {
    this.attachSubscriptions(
      this.fileService.getSrc(src).pipe(
        switchMap((el:any) => {
          return this.fileService.downloadFileFromUrl(el.download_url, file)
        })
      ).subscribe((resp:any) => {
        console.log("downloadFile", resp);
        if (resp.content && resp.state == "DONE") {
          let newSrc = URL.createObjectURL(resp.content);
          file.blobSrc = newSrc;
          file.blob = resp.content;
          const videoElement = document.createElement("video");
          videoElement.src = newSrc;
          
          // Append the video element to the DOM to display it.
          document.body.appendChild(videoElement);
          
          // You can optionally specify attributes and controls for the video element.
          videoElement.setAttribute("controls", "true");
        }
      })
    )
  }

  ngAfterViewInit() {
    let partPhElement = this.partPlaceholder.nativeElement;

    partPhElement.style.display = 'none';
    partPhElement.parentNode.removeChild(partPhElement);
  }

  onMenuEnter(menuItem: HTMLElement) {
    console.log('Mouse entered menu:', menuItem);
    // Дополнительные действия при входе в меню
  }

  onMenuLeave(menuItem: HTMLElement) {
    console.log('Mouse left menu:', menuItem);
    // Дополнительные действия при выходе из меню
  }

  add() {
    this.items.push(this.items.length + 1);
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event) {
    console.log("beforeunload")
    // Выполнение навигации перед обновлением страницы
    // this.router.navigateByUrl(this.data.previusUrl);

    // event.returnValue = 'Close video editor?';
  }

  shuffle() {
    this.items.sort(function() {
      return .5 - Math.random();
    });
  }

  dropped(papa, ph, arr) {
    if (!this.target) return;

    const parent: HTMLElement = papa;
    const phElement: HTMLElement = ph;
    const phElementIndex = __indexOf(parent.children, phElement);

    phElement.style.display = 'none';
    parent.removeChild(phElement);
    parent.appendChild(phElement);

    parent.insertBefore(
      this.source.element.nativeElement,
      parent.children[this.sourceIndex]
    );

    console.log(this.sourceIndex, ' => ', phElementIndex);

    if (this.sourceIndex != phElementIndex) {
      moveItemInArray(arr, this.sourceIndex, phElementIndex);
    }

    this.target = null;
    this.targetIndex = undefined;
    this.source = null;
    this.sourceIndex = undefined;
  }

  entered({ item, container }: CdkDragEnter, papa, ph) {
    const phElement: HTMLElement = ph;
    const dropElement: HTMLElement = container.element.nativeElement;
    const prevTarget: CdkDropList = this.target;
    const prevTargetIndex: number = this.targetIndex;
    this.target = container;

    const dropElementIsTheSource: boolean = !dropElement.parentNode;
    const prevAndCurrentTargetAreTheSame: boolean = this.target === prevTarget;
    if (dropElementIsTheSource || prevAndCurrentTargetAreTheSame) {
      return;
    }

    this.targetIndex = __indexOf(dropElement.parentNode.children, dropElement);

    if (!this.source) {
      this.source = item.dropContainer;
      this.sourceIndex = __indexOf(
        dropElement.parentNode.children,
        item.dropContainer.element.nativeElement
      );
      const sourceElement: HTMLElement = this.source.element.nativeElement;

      this.fixPhElementStyling(phElement, sourceElement);

      sourceElement.parentNode.removeChild(sourceElement);
    }

    const index: number = prevTargetIndex ?? this.sourceIndex;
    const insertAfter: boolean = index < this.targetIndex;

    papa.insertBefore(
      phElement,
      insertAfter ? dropElement.nextSibling : dropElement
    );
  }
  
  dragReleased(ph, dragPh) {
    console.log('ph, dragPh', ph, dragPh)
    const phElementPositionWasChanged: boolean = !!this.source;
    if (phElementPositionWasChanged) {
      // console.log("dragReleased dragPh", ph, dragPh)
      dragPh.nativeElement.style.transform = 'none';
      dragPh.nativeElement.parentNode.removeChild(
        dragPh.nativeElement
      );
      ph.appendChild(
        dragPh.nativeElement
      );
    }
  }
  
  private fixPhElementStyling(
    phElement: HTMLElement,
    sourceElement: HTMLElement
  ) {
    phElement.style.width = sourceElement.clientWidth - 6 + 'px';
    phElement.style.height = sourceElement.clientHeight - 6 + 'px';

    const size = Array.from(sourceElement.classList).find((c) =>
      c.startsWith('content-item-c')
    );

    phElement.style.display = '';
    const oldSize = Array.from(phElement.classList).find((c) =>
      c.startsWith('content-item-c')
    );
    if (oldSize) {
      phElement.classList.remove(oldSize);
    }
    if (size) {
      phElement.classList.add(size);
    }
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.clearSubscriptions()
  }
}

function __indexOf(collection, node) {
  return Array.prototype.indexOf.call(collection, node);
};
