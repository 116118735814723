import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subscription, concat, forkJoin, of } from 'rxjs';
import { catchError, concatMap, distinctUntilChanged, last, map, switchMap, tap } from 'rxjs/operators';
import { BaseClass } from 'src/app/shared/models/base-class';
import { FileService } from 'src/app/shared/services/rest/file.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { LoadingService } from 'src/app/shared/services/rest/loading.service';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { MatBottomSheet } from '@angular/material/bottom-sheet';

import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as moment from 'moment';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { GlobalDataService } from 'src/app/shared/services/common/global-data.service';
import { FormControl } from '@angular/forms';
export const MY_FORMATS = {
  parse: {
    dateInput: 'LLLLL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
export const MY_NEW_FORMATS = {
  parse: {
    dateInput: 'MM/DD/YYYY',
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'MM/DD/YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
const colors: Record<string, any> = {
  1: {
    type: "Waiting",
    primary: '#2F80ED',
    secondary: '#BCD7FD',
  },
  2: {
    type: "Draft",
    primary: '#DB0000',
    secondary: '#FFAEAE',
  },
  3: {
    type: "Scheduled",
    primary: '#BEC11B',
    secondary: '#FEFFB9',
  },
  4: {
    type: "Published",
    primary: '#219653',
    secondary: '#98F1BD',
  },
  5: {
    type: "Canceled",
    primary: '#686868',
    secondary: '#E0E0E0',
  },
  6: {
    type: "content",
    primary: '#DD5E89',
    secondary: '#F7BB97',
  }
};

@Component({
  selector: 'app-projects-servers-table',
  templateUrl: './projects-servers-table.component.html',
  styleUrls: ['./projects-servers-table.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_NEW_FORMATS},
  ]
})
export class ProjectsServersTableComponent extends BaseClass implements OnInit, OnDestroy {
  public page = this.data.page;
  public pagination = this.data.pagination;
  public isLoad: boolean = false;
  public urlsTableSub: Subscription;
  public projectsByServers: any[] = [];

  public profileStatuses: any;
  public projects: any = this.data.projects;
  public isRealGettingFiles: boolean = false;
  public isGettingFiles: boolean = false;
  public isSubmit: boolean = false;
  public getFilesSettings:any = {
    job_id: 0,
    is_location_all: true,
    folder: "/",
  };

  public status_id: FormControl = new FormControl('');
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private taskService: TaskService,
    private ls: LoadingService,
    private globalDataService: GlobalDataService,
    private layoutService: LayoutService,
    private sm: StorageManagerService,
    private fileService: FileService,
    public dialogRef: MatDialogRef<ProjectsServersTableComponent>,
    private dialog: MatDialog,
  ) { super() }

  ngOnInit(): void {
    console.log("ProjectsServersTableComponent this.data", this.data)
    this.projects.forEach(project => {
      if (this.projectsByServers.filter(x => x.server_id == project.server_id).length == 0) {
        this.projectsByServers.push({
          server_id: project.server_id,
          projects: [project]
        })
      } else {
        this.projectsByServers.find(x => x.server_id == project.server_id).projects.push(project)
      }
    });
    this.getProjects(this.page);
    this.getProfilesStatus();

    // this.attachSubscriptions(
    //   this.status_id.valueChanges.subscribe(res => {
    //     this.urls.map(x => x.content_status_id = res)
    //   })
    // )

  }

  getProjects(page) {
    if (this.isLoad) {
      return
    }
    this.isLoad = true;
    let filter:any = {};
    if (this.data.isGlobal) {
      filter = {...this.data.filterVal}
    } else {
      if (this.data.task_id) {
        filter.task_id = this.data.task_id
      }
      if (this.data.task_operation_id) {
        filter.task_operation_id = this.data.task_operation_id
      }
    }
    this.attachSubscriptions(      
      this.fileService.getVideoProjects(page, this.data.company.id, filter, '100').pipe(
        tap(el => {
          this.pagination = {
            'pageCount': el.headers.get('x-pagination-page-count'),
            'perPage': el.headers.get('x-pagination-per-page'),
            'totalCount': el.headers.get('x-pagination-total-count'),
            'currentPage': el.headers.get('x-pagination-current-page'),
          }
        }),
        map(x => x.body),
        tap(resp => {
          resp.map(u => {
            u.save_waiting_time_limit = u.waiting_time_limit;
          })
        }),
      ).subscribe(resp => {
        if (this.page == 1) {
          this.projects = resp;
        } else {
          this.projects.push(...resp);
        }
        resp.forEach(project => {        
          if (this.projectsByServers.filter(x => x.server_id == project.server_id).length == 0) {
            this.projectsByServers.push({
              server_id: project.server_id,
              projects: [project]
            })
          } else {
            this.projectsByServers.find(x => x.server_id == project.server_id).projects.push(project)
          }
        });
        this.page++;
        this.isLoad = false;
        if (+this.pagination['currentPage'] < +this.pagination['pageCount'] && +this.pagination['pageCount'] > 0) {
          this.getProjects(this.page)
        }
      }, error => {
        this.isLoad = false;
      })
    )
    
  }


  // getUrls(n) {
  //   this.isLoad = true;
  //   this.urlsTableSub = this.taskService.getExpandUrls(n, this.data.company.id, {...this.data.filterVal}, this.viewVal == 'list' ? (!this.data.is_mobile ? '20' : '10') : '200').pipe(
  //     tap(el => {
  //       this.pagination = {
  //         'pageCount': el.headers.get('x-pagination-page-count'),
  //         'perPage': el.headers.get('x-pagination-per-page'),
  //         'totalCount': el.headers.get('x-pagination-total-count'),
  //         'currentPage': el.headers.get('x-pagination-current-page')
  //       }
  //       console.log("this.pagination TEST", this.pagination)
  //     }),
  //     map(el => el.body),
  //     tap(urls => {
  //       urls.map(url => {
  //         // url.start = moment(url.content_published_at * 1000);
  //         // url.start = startOfDay(new Date());
  //         url.start = new Date(url.content_published_at * 1000);
  //         url.title = url.content_name || marker("Without name");
  //         url.color = colors[url.content_status_id];
  //         // url.allDay = true;
  //         url.cssClass = 'publish_url';
  //         let by = ''
  //         if ((this.data.filterVal.group_id.length) && (this.data.filterVal.channel_id.length || this.data.filterVal.channel_platform_id || this.data.filterVal.channel_platform_id.toString() == '0')) {
  //           by = 'combine'
  //         } else {
  //           if ((this.data.filterVal.group_id.length) && !(this.data.filterVal.channel_id.length || this.data.filterVal.channel_platform_id || this.data.filterVal.channel_platform_id.toString() == '0')) {
  //             by = 'group'
  //           } else {
  //             by = 'platform'
  //           }
  //         }
      
  //         url.meta = {
  //           type:'publication',
  //           filterBy: by
  //         }
  //       });
  //     }),
  //     distinctUntilChanged()
  //   ).subscribe(resp => {

  //     console.log("getUrls", resp)
      
  //     if (this.page == 1) {
  //       this.urls = resp;
  //     } else {
  //       this.urls.push(...resp);
  //     }
  //     resp.forEach(url => {        
  //       if (this.urlsByServers.filter(x => x.server_id == url.server_id).length == 0) {
  //         this.urlsByServers.push({
  //           server_id: url.server_id,
  //           urls: [url]
  //         })
  //       } else {
  //         this.urlsByServers.find(x => x.server_id == url.server_id).urls.push(url)
  //       }
  //     });

  //     this.page = this.page + 1;
  //     this.isLoad = false;

      
  //     if (+this.pagination['currentPage'] < +this.pagination['pageCount'] && +this.pagination['pageCount'] > 0) {
  //       this.getUrls(this.page)
  //     }
  //   },
  //   error => {
  //     this.isLoad = false;
  //   })
    
  // }

  getProfilesStatus() {
    this.attachSubscriptions(
      this.globalDataService.getContentStatus().subscribe(resp => {
        this.profileStatuses = resp.slice();
        console.log("profileStatuses", this.profileStatuses)
      })
    )
  }

  getProfileStatusById(id) {
    if (this.profileStatuses) {
      return this.profileStatuses.find(x => x.id == id)
    } else {
      return undefined
    }
  }

  log() {
    console.log("ProjectsServersTableComponent this.projects", this.projects)
    console.log("ProjectsServersTableComponent this.projectsByServers", this.projectsByServers)
  }

  toggleCol() {
    if (this.isAllChecked()) {
      this.projects.map(x => x.checked = false)
    } else {
      this.projects.map(x => x.checked = true)
    }
  }

  isAllChecked() {
    return this.projects.filter(x => !!x.checked).length == this.projects.length
  }

  hasChecked() {
    return !!this.projects.filter(x => !!x.checked).length
  }


  close() {
    this.dialogRef.close({event: "close", data: false});
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

}
