import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-edit-option',
  templateUrl: './add-edit-option.component.html',
  styleUrls: ['./add-edit-option.component.scss']
})
export class AddEditOptionComponent extends BaseClass implements OnInit, OnDestroy {
  public host: any = environment.host;
  public form: FormGroup;
  public isFormChange: boolean = false;
  public isSubmit: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddEditOptionComponent>,
    private companyService: CompanyService,
    private fb: FormBuilder,
    private layoutService: LayoutService,

  ) { super() }

  ngOnInit(): void {
    if (this.data.is_edit) {
      this.form = this.fb.group({
        is_name: this.data.access.is_name,
        is_description: this.data.access.is_description,
        is_task_channels: this.data.access.is_task_channels,
        is_files: this.data.access.is_files,
        is_documents: this.data.access.is_documents,
      })
    } else {
      this.form = this.fb.group({
        company_id: this.data.company_id,
        task_id: this.data.task.id,
        with_all: 0,
        with_registered: 0,
        with_company_id: 0,
        // with_employee_id: 0,
        // with_user_id: 0,
        is_name: 0,
        is_description: 0,
        is_task_channels: 0,
        is_files: 0,
        is_documents: 0
      })
    }


    this.attachSubscriptions(
      this.form.valueChanges.subscribe(() => this.isFormChange = true)
    )

    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        if (this.isFormChange) {
          this.layoutService.openBottomSheet(this.dialogRef);
        } else {
          this.close();
        }
      })
    )

  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  onSubmit() {
    this.isSubmit = true;
    let formData = {...this.form.value}

    if (this.data.is_edit) {
      formData.is_name = !!formData.is_name ? 1 : 0;
      formData.is_description = !!formData.is_description ? 1 : 0;
      formData.is_task_channels = !!formData.is_task_channels ? 1 : 0;
      formData.is_files = !!formData.is_files ? 1 : 0;
      formData.is_documents = !!formData.is_documents ? 1 : 0;

      this.attachSubscriptions(
        this.companyService.editAccesses(this.data.access.id, formData, this.data.company_id).subscribe(resp => {   
          this.isSubmit = false;
          this.dialogRef.close({event: "edit", data: resp});
        })
      )
    } else {
      formData.with_all = !!formData.with_all ? 1 : 0;
      formData.with_registered = !!formData.with_registered ? 1 : 0;
      if (!!formData.with_company_id) {
        formData.with_company_id = this.data.company_id
      } else {
        delete formData.with_company_id
      } 
      // formData.with_employee_id = !!formData.with_employee_id ? 1 : 0;
      // formData.with_user_id = !!formData.with_user_id ? 1 : 0;
      formData.is_name = !!formData.is_name ? 1 : 0;
      formData.is_description = !!formData.is_description ? 1 : 0;
      formData.is_task_channels = !!formData.is_task_channels ? 1 : 0;
      formData.is_files = !!formData.is_files ? 1 : 0;
      formData.is_documents = !!formData.is_documents ? 1 : 0;
  
      this.attachSubscriptions(
        this.companyService.addAccesses(this.data.company_id, formData).subscribe(resp => {   
          this.isSubmit = false;
          this.dialogRef.close({event: "add", data: resp});
        })
      )
    }
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
