import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BaseClass } from '../../models/base-class';
import { TaskService } from '../../services/rest/task.service';

@Component({
  selector: 'app-reminder-alert',
  templateUrl: './reminder-alert.component.html',
  styleUrls: ['./reminder-alert.component.scss']
})
export class ReminderAlertComponent extends BaseClass implements OnInit, OnDestroy {

  constructor(
    private taskService: TaskService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ReminderAlertComponent>,
    private router: Router
  ) { super() }

  ngOnInit(): void {
    console.log("reminder", this.data)
  }

  close() {
    this.dialogRef.close()
  }

  onSubmit() {
    this.close();
    this.router.navigate(['/reminder-tasks'], { queryParams: {company_id: this.data.company.id, operation_employee_id: this.data.company.employees[0].id, operation_filter: 1, operation_is_reminder: 1, order_by: "updated_desc"}});
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
