import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { ReplaySubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';

@Component({
  selector: 'app-multi-edit-jobs-type',
  templateUrl: './multi-edit-jobs-type.component.html',
  styleUrls: ['./multi-edit-jobs-type.component.scss']
})
export class MultiEditJobsTypeComponent extends BaseClass implements OnInit, OnDestroy {
  public operations: any;
  public operationMoreControl: FormControl = new FormControl();
  public operations$: ReplaySubject<any> = new ReplaySubject<any>(1);
  
  public isSubmit: boolean = false;
  public submited: boolean = false;

  public form: FormGroup;
  public isFormChange: boolean = false;

  constructor(
    private taskService: TaskService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<MultiEditJobsTypeComponent>,
    private fb: FormBuilder,
    private sm: StorageManagerService,
    private layoutService: LayoutService
  ) { super() }

  ngOnInit(): void {
    console.log("MultiEditJobsTypeComponent", this.data)
    this.getOperations();
    
    this.form = this.fb.group({
      operation_id: ['', Validators.required],
    })

    this.attachSubscriptions(
      this.form.valueChanges.subscribe(() => this.isFormChange = true)
    )

    this.attachSubscriptions(
      this.operationMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchOperations(resp))
    )
    
    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        if (this.isFormChange) {
          this.layoutService.openBottomSheet(this.dialogRef);
        } else {
          this.close();
        }
      })
    )

  }

  onSearchOperations(resp) {
    if (!this.operations) {
      return;
    }

    if (!resp) {
      this.operations$.next(this.operations.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.operations$.next(
      this.operations.filter(z => (z.name.toLowerCase().indexOf(resp) > -1))
    );
  }


  getOperations() {
    this.attachSubscriptions(
      this.taskService.getOperations(this.data.company.id).subscribe(resp => {
        this.operations = resp;
        this.operations$.next(this.operations.slice())
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  selectType(type) {
    this.form.patchValue({
      operation_id: !!type.id ? type.id : type.value
    })
  }

  onSubmit() {
    if (!this.form.valid) {
      this.submited = true;
      this.layoutService.showSnackBar({name: ''}, marker("You need to fill in all required fields"), SnackBarItem)
      return
    }
    this.submited = false;
    this.isSubmit = true;

    let multiData = [];

    this.data.selectedJobs.forEach(job => {
      multiData.push({
        "path": `/api/task-operation/${job.id}/`,
        "query": {},
        "method": "POST",
        "body": Object.assign({
          [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token')
      }, this.form.value) 
      })
    });

    
    this.attachSubscriptions(
      this.taskService.multiRequest(multiData).subscribe(resp => {
        this.isSubmit = false;
        this.dialogRef.close({event: "update", data: resp})
      }, error => {
        this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
        this.isSubmit = true;
      })
    )
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

}
