import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'app-date-ask',
  templateUrl: './date-ask.component.html',
  styleUrls: ['./date-ask.component.scss']
})
export class DateAskComponent implements OnInit, AfterViewInit {
  @ViewChild('btnFocus') btnFocus: MatButton

  constructor(
    private bottomSheetRef: MatBottomSheetRef<DateAskComponent>
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    setTimeout(() => {this.btnFocus.focus()},0)
  }

  prev(): void {
    this.bottomSheetRef.dismiss({
      message: 'prev',
    });
  }

  today() {
    this.bottomSheetRef.dismiss({
      message: 'today',
    });
  }

}
