<h2 mat-dialog-title class="mobile_interface_title">
    {{ "Edit "+data.target+" priority" | translate }}
</h2>
<mat-dialog-content>
    <form [formGroup]="form">
        <div class="priority_chips priority_chips_desk">
            <mat-chip-list [multiple]="false" selectable formControlName="priority">
                <mat-chip *ngFor="let priority of prioritys" [ngClass]="{
                    'Urgent': priority.id == '1000',
                    'High': priority.id == '900',
                    'Middle': priority.id == '800',
                    'Low': priority.id == '700',
                    'No': priority.id == '0'
                }" class="priority_chip" (click)="selectPrioritys(priority)" [value]="priority.id">
                    {{ priority.name | translate }}
                </mat-chip>
            </mat-chip-list>
        </div>
    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <div class="d-f-c" style="justify-content: space-between !important;">
        <button mat-button (click)="close()">
            {{ "Close" | translate }}
        </button>
        <button class="ml-1" mat-raised-button color="primary" (click)="onSubmit()">
            {{ "Save" | translate }}
        </button>
    </div>
</mat-dialog-actions>