<h2 mat-dialog-title class="dialog_title">
    <span>
        {{ "Adding a Document" | translate }}
    </span>
    <button mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h2>
<mat-dialog-content class="mat-typography" dirEnter (dirSubmit)="!form.valid || isSubmit ? null : submitForm()">
    <div class="container form_wrapper" style="padding: 20px 0;">
        <form [formGroup]="form">
            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ "Choose a template" | translate }}</mat-label>
                <mat-select formControlName="statement_template_id" required>
                    <mat-option *ngFor="let tmpl of templates" [value]="tmpl.id">
                        {{ tmpl.name | translate }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ "Choose a group" | translate }}</mat-label>
                <mat-select disableOptionCentering formControlName="task_group_id">
                    <mat-option>
                        <ngx-mat-select-search 
                        placeholderLabel="{{ 'Search' | translate }}" 
                        noEntriesFoundLabel="{{ 'no matching group found' | translate }}"
                        [formControl]="groupsMoreControl"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option [value]="0">
                        {{ 'All' | translate }}
                    </mat-option>
                    <mat-option *ngFor="let group of groups$ | async" [value]="group.id">
                        {{ group.name | translate }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </form>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button (click)="close()">
        {{ "Back" | translate }}
    </button>
    <button class="ml-1 d-f-btn" (click)="submitForm()" mat-raised-button cdkFocusInitial color="primary" [disabled]="!form.valid || isSubmit">
        <mat-spinner class="spin_16 mr-1" *ngIf="isSubmit"></mat-spinner>  
        {{ "Save" | translate }}
    </button>
</mat-dialog-actions>
