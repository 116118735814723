<mat-dialog-content class="mat-typography">
    <div class="container form_wrapper">
        
        <mat-tab-group [selectedIndex]="activeIndex" (selectedIndexChange)="changeTab($event)">
            <!-- <mat-tab>
                <ng-template mat-tab-label>
                    <mat-icon class="example-tab-icon mr-1">auto_awesome_motion</mat-icon>
                    {{ "Adding a scenario" | translate }}
                </ng-template>
                
                <form [formGroup]="scenarioForm" class="form_with_pads form_with_valids">
                  
                </form>
            </mat-tab> -->
          
            <mat-tab>
                <ng-template mat-tab-label>
                    <mat-icon class="example-tab-icon mr-1">
                        work_history
                    </mat-icon>
                    {{ "Adding a template" | translate }}
                </ng-template>

                <form [formGroup]="templateForm" class="form_with_pads form_with_valids">
                    <div class="users_select">
                        <label>{{ "Choose a scenario" | translate }}: </label>
                        <mat-chip-list [multiple]="false" selectable formControlName="template_id">
                            <ng-container *ngFor="let template of templates; let i = index">
                                <mat-chip style="padding-left: 12px !important;" class="chip_user" (click)="toggleChip(template)" [value]="template.id">
                                    {{ template.name }}
                                </mat-chip>
                                <mat-chip *ngIf="i == templates.length - 1 && i > 6" class="chip_user more" (click)="templatesIdContainer.open()">
                                    {{ "more" | translate }}
                                </mat-chip>
                            </ng-container>
                        </mat-chip-list>
                    </div>
        
                    <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                        <mat-select #templatesIdContainer formControlName="template_id" (selectionChange)="toggleChip($event)">
                            <mat-option>
                                <ngx-mat-select-search 
                                placeholderLabel="{{ 'Search' | translate }}" 
                                noEntriesFoundLabel="{{ 'no matching template found' | translate }}"
                                [formControl]="templatesMoreControl"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let template of templates$ | async" [value]="template.id">
                                {{template.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </form>
            </mat-tab>

        </mat-tab-group>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <div class="f_w_p_btns">
        <button mat-raised-button (click)="close()">
            {{ "Close" | translate }}
        </button>
        <button class="ml-1 d-f-btn" (click)="addTemplate()" mat-raised-button color="primary" [disabled]="isSubmit">
            <mat-spinner class="spin_16 mr-1" *ngIf="isSubmit"></mat-spinner>  
            {{ "Add" | translate }}
        </button>
    </div>
</mat-dialog-actions>
