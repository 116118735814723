import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { MatButton } from '@angular/material/button';
@Component({
  selector: 'app-work-to-approve',
  templateUrl: './work-to-approve.component.html',
  styleUrls: ['./work-to-approve.component.scss']
})
export class WorkToApproveComponent implements OnInit, AfterViewInit {
  @ViewChild('btnFocus') btnFocus: MatButton

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private bottomSheetRef: MatBottomSheetRef<WorkToApproveComponent>
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    setTimeout(() => {this.btnFocus.focus()},0)
  }
  
  approve(): void {
    this.bottomSheetRef.dismiss({
      message: 'approve',
    });
  }

  show() {
    this.bottomSheetRef.dismiss({
      message: 'show',
    });
  }

}
