<mat-dialog-content class="mat-typography">
    <div class="container form_wrapper" [appDeviceClass]="'custom-scroll custom-scroll-t'">
        <div class="title_modal d-f-c">
            <span class="set_width">
                {{ "Can see groups" | translate }}:
            </span>
            <app-user *ngIf="data?.fromEmployees" [class]="'user_div ml-1'" [employee]="data?.employee"></app-user>
        </div>

        <form [formGroup]="form" class="form_with_pads" (ngSubmit)="onSubmit()">
            <ng-container *ngIf="data?.fromEmployees; else elseTemplateFromEmpl">
                <mat-form-field appearance="legacy" class="full_width_form">
                    <mat-label>{{ "Group" | translate }}</mat-label>
                    <mat-select [disableOptionCentering]="true" selectDir formControlName="channel_group_id">
                      <mat-option>
                        <ngx-mat-select-search 
                            placeholderLabel="{{ 'Search' | translate }}" 
                            noEntriesFoundLabel="{{ 'no matching groups found' | translate }}"
                            [formControl]="groupsMoreControl"></ngx-mat-select-search>
                      </mat-option>                  
                      <mat-option *ngFor="let group of groups$ | async" [value]="group.id">
                        {{ group.name }}
                      </mat-option>
                    </mat-select>           
                    <button class="suffix_button" [ngStyle]="{'color': (!form.get('channel_group_id').value || isSubmit) ? '#686868' : '#4E844E'}" [disabled]="!form.get('channel_group_id').value || isSubmit" type="submit" (click)="$event.stopPropagation()" mat-icon-button matSuffix>
                        <mat-icon>add_circle</mat-icon>
                    </button>
                </mat-form-field>
            </ng-container>
            <ng-template #elseTemplateFromEmpl>
                <mat-form-field appearance="legacy" class="full_width_form">
                    <mat-label>{{ "Executor" | translate }}</mat-label>
                    <mat-select [disableOptionCentering]="true" selectDir formControlName="employee_id">
                      <mat-option>
                          <ngx-mat-select-search 
                              placeholderLabel="{{ 'Search' | translate }}" 
                              noEntriesFoundLabel="{{ 'no matching member found' | translate }}"
                              [formControl]="execControl"></ngx-mat-select-search>
                        </mat-option>
                      <mat-option *ngFor="let employee of employees$ | async" [value]="employee.id">
                          <app-user [class]="'user_div'" [employee]="employee"></app-user>
                      </mat-option>
                    </mat-select>
                    <button class="suffix_button" [ngStyle]="{'color': (!form.get('employee_id').value || isSubmit) ? '#686868' : '#4E844E'}" [disabled]="!form.get('employee_id').value || isSubmit" type="submit" (click)="$event.stopPropagation()" mat-icon-button matSuffix>
                        <mat-icon>add_circle</mat-icon>
                    </button>
                </mat-form-field>
            </ng-template>
        </form>

        <mat-nav-list *ngIf="groupsToEmployee?.length">
            <mat-list-item *ngFor="let grToEm of groupsToEmployee; let i = index;" class="data_out_item">
                <div class="data_item">
                    <div class="data_item_info">
                        <ng-container *ngIf="data?.fromEmployees; else elseTemplateFromEmplData">
                            <div class="channel">
                                <div class="platform_wrp">
                                  {{ grToEm?.channelGroup?.name }}
                                </div>
                            </div>
                        </ng-container>
                        <ng-template #elseTemplateFromEmplData>
                            <div class="employee">
                                <app-user [class]="'user_div'" [employee]="getEmployee(grToEm?.employee_id)"></app-user>
                            </div>
                        </ng-template>
                    </div>
                    <mat-icon style="color: red;" (click)="deleteGroupToEmployee(grToEm.id, i)">delete</mat-icon>
                </div>
            </mat-list-item>
        </mat-nav-list>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <div class="f_w_p_btns">
        <button mat-raised-button (click)="close()">
            {{ "Close" | translate }}
        </button>
    </div>
</mat-dialog-actions>