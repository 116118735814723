import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { ReplaySubject, forkJoin } from 'rxjs';
import { debounceTime, last, map, switchMap } from 'rxjs/operators';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';

@Component({
  selector: 'app-create-checklist',
  templateUrl: './create-checklist.component.html',
  styleUrls: ['./create-checklist.component.scss']
})
export class CreateChecklistComponent extends BaseClass implements OnInit, OnDestroy {
  public form: FormGroup;
  public company_id: any;
  public channel_group_id: any;
  public operation_id: any;
  public activeLang: any;
  public groups$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public groupsMoreControl: FormControl = new FormControl();
  public groups: any;
  public operations: any;
  public isSubmit: boolean = false;
  public submited: boolean = false;
  public isFormChange: boolean = false;
  public operations$: ReplaySubject<any> = new ReplaySubject<any>(1);
  
  public operationMoreControl: FormControl = new FormControl();
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CreateChecklistComponent>,
    private layoutService: LayoutService,
    private companyService: CompanyService,
    private sm: StorageManagerService,
    private fb: FormBuilder,
    private languageService: LanguageService,
    private taskService: TaskService
  ) { super() }

  ngOnInit(): void {
    console.log('CreateChecklistComponent', this.data);

    this.form = this.fb.group({
      company_id: this.data.company_id,
      channel_group_id:  [!!this.data.channel_group_id ? +this.data.channel_group_id : [], Validators.required],
      operation_id: [!!this.data.operation_id ? +this.data.operation_id : '', Validators.required],
      text: ['', Validators.required],
      order: [0, Validators.required],
    })

    this.attachSubscriptions(
      this.groupsMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchGroups(resp))
    )

    this.attachSubscriptions(
      this.operationMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchOperations(resp))
    )

    this.attachSubscriptions(
      this.form.valueChanges.subscribe(() => this.isFormChange = true)
    )

    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        if (this.isFormChange) {
          this.layoutService.openBottomSheet(this.dialogRef);
        } else {
          this.close();
        }
      })
    )

    this.attachSubscriptions(
      this.languageService.getLangData().subscribe(resp => {
        this.activeLang = resp.active;
      })
    )

    this.getOperations();
    // this.getGroupsCompany();
    this.getAllGroups();
  }

  getAllGroups() {
    this.attachSubscriptions(
      this.companyService.getInfiniteGroupsCompany(this.data.company_id, '1', '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.companyService.getInfiniteGroupsCompany(this.data.company_id, x).pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        this.groups = [].concat(...res);
        this.groups$.next(this.groups.slice())
      })
    )
  }

  getGroupById(id) {
    if (!this.groups || this.groups.filter(x => x.id == id).length == 0) {
      return;
    }

    return this.groups.find(x => x.id == id)
  }

  onSearchGroups(resp) {
    if (!this.groups) {
      return;
    }

    if (!resp) {
      this.groups$.next(this.groups.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.groups$.next(
      this.groups.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  onSearchOperations(resp) {
    if (!this.operations) {
      return;
    }

    if (!resp) {
      this.operations$.next(this.operations.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.operations$.next(
      this.operations.filter(z => (z.name.toLowerCase().indexOf(resp) > -1))
    );
  }

  selectType(type) {
    this.form.patchValue({
      operation_id: !!type.id ? type.id : type.value
    })
  }

  onSubmit() {
    if (!this.form.valid) {
      this.submited = true;
      this.layoutService.showSnackBar({name: ''}, marker("You need to fill in all required fields"), SnackBarItem)
      return
    }

    this.isSubmit = true;
    let postData = [];
    this.form.value.channel_group_id.forEach(group_id => {
      let remData = JSON.parse(JSON.stringify(this.form.value))
      delete remData.channel_group_id
      postData.push({
        "path": '/api/operation-reminder/',
        "query": {company_id: this.data.company_id},
        "method": "POST",
        "body": Object.assign({
          [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
          channel_group_id: group_id
        }, remData) 
      })
        
    });
    this.attachSubscriptions(
      this.taskService.multiRequest(postData).subscribe(resp => {
        this.isSubmit = false;
        this.dialogRef.close({event: "update", data: resp});
      }, error => {
        this.isSubmit = false;
        this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
      })
    )


    // this.isSubmit = true;

    // let formData = JSON.parse(JSON.stringify(this.form.value))
    // delete formData.channel_group_id;
    // this.attachSubscriptions(
    //   forkJoin(this.form.value.channel_group_id.map(channel_group_id => this.taskService.createOperationReminder(Object.assign({channel_group_id: channel_group_id} ,formData)))).subscribe(resp => {
    //     this.isSubmit = false;
    //     this.dialogRef.close({event: "update", data: resp});
    //   }, error => {
    //     this.isSubmit = false;
    //     this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
    //   })
    // )
  }

  getOperations() {
    this.attachSubscriptions(
      this.taskService.getOperations(this.data.company_id, this.activeLang).subscribe(resp => {
        this.operations = resp;
        this.operations$.next(this.operations.slice())
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  ngOnDestroy(): void {
    this.clearSubscriptions();
  }

}
