import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { FileService } from 'src/app/shared/services/rest/file.service';

@Component({
  selector: 'app-rename',
  templateUrl: './rename.component.html',
  styleUrls: ['./rename.component.scss']
})
export class RenameComponent extends BaseClass implements OnInit, OnDestroy {
  public form: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<RenameComponent>,
    private fb: FormBuilder,
    private fileService: FileService,
    public layoutService: LayoutService
  ) { super() }

  ngOnInit(): void {
    console.log("RenameComponent")
    this.form = this.fb.group({
      filename: [this.data.file.filename, Validators.required],
    })
  }

  close() {
    this.dialogRef.close({event: "close", data: false});
  }

  submitForm() {
    this.attachSubscriptions(
      this.fileService.editFile(this.data.file.id, this.form.value, this.data.company.id).subscribe(resp => {
        this.data.file.filename = resp.filename;
        this.dialogRef.close({event: "Edit", data: this.form.value})
      }, error => {
        console.log("CreateFolderComponent error", error)
        if (error && error[0] && error[0].message && error[0].message == 'The directory or file exists' && error[1]) {
          this.form.patchValue({
            filename: error[1].message
          })
          this.submitForm();
        }
      })
    )
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
