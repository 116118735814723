import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ReplaySubject, forkJoin } from 'rxjs';
import { debounceTime, last, map, switchMap } from 'rxjs/operators';
import { BaseClass } from 'src/app/shared/models/base-class';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { ParametersService } from 'src/app/shared/services/rest/parameters.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';

@Component({
  selector: 'app-set-language',
  templateUrl: './set-language.component.html',
  styleUrls: ['./set-language.component.scss']
})
export class SetLanguageComponent extends BaseClass implements OnInit, OnDestroy {
  public parameter_value: any[] = [];
  public isSubmit: boolean = false;
  public allValues: any;
  public allValuesControl: FormControl = new FormControl();
  public allValues$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public savedTags: any[] = [];
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<SetLanguageComponent>,
    private sm: StorageManagerService,
    private dialog: MatDialog,
    private taskService: TaskService,
    private parametersService: ParametersService,
  ) { super() }

  ngOnInit(): void {
    console.log("SetLanguageComponent data", this.data)
  
    this.getAllApiParameterValues();

    this.attachSubscriptions(
      this.allValuesControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchValues(resp))
    )
  }

  log() {
    console.log("parameter_value", this.parameter_value)
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  save() {
    this.isSubmit = true;
    console.log("selectedFiles", this.data.selectedFiles)
    let postData = [];
                  

    this.parameter_value.forEach(parValue => {
      this.data.selectedFiles.forEach(file => {
        let value_id = file.parameterValuesToTask && file.parameterValuesToTask.find(x => x.parameter_value_id == parValue.id) ? file.parameterValuesToTask.find(x => x.parameter_value_id == parValue.id).id : null
        if (value_id) {
          postData.push({
            "path": `/api/parameter-value-to-task/${value_id}/`,
            "query": {"company_id": this.data.company.id, "expand": "parameter,parameterValue"},
            "method": "POST",
            "body": {
              [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
              is_primary: 1
            }
          })
        } else {
          postData.push({
            "path": `/api/parameter-value-to-task/`,
            "query": {"expand": "parameter,parameterValue", "company_id": this.data.company.id},
            "method": "POST",
            "body": {
              [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
              company_id: file.company_id,
              parameter_id: parValue.parameter_id,
              parameter_value_id: parValue.id,
              task_id: file.task_id,
              task_operation_id: file.task_operation_id,
              discussion_id: file.discussion_id && file.discussion ? file.discussion_id : 0,
              file_id: file.id,
              is_primary: 1
            }
          })
        }
      });
    })

    if (this.savedTags && this.savedTags.length) {
      this.savedTags.forEach(savedTag => {
        if (!this.parameter_value.filter(k => k.id == savedTag.id).length) {
          postData.push({
            "path": `/api/parameter-value-to-task/${savedTag.keyId}/`,
            "query": {"company_id": this.data.company.id},
            "method": "DELETE"
          })
        }
      })
    }

    if (postData.length) {
      this.attachSubscriptions(
        this.taskService.multiRequest(postData).subscribe(resp => {
          this.isSubmit = false;
          this.dialogRef.close({event: "update", data: resp})
        })
      )
    } else {
      this.isSubmit = false;
    }
  }

  getLangById(id) {
    if (!this.allValues || this.allValues.length == 0) {
      return null
    }
    return this.allValues.find(x => x.id == id)
  }

  onSearchValues(resp) {
    if (!this.allValues) {
      return;
    }

    if (!resp) {
      this.allValues$.next(this.allValues.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.allValues$.next(
      this.allValues.filter(z => z.value.toLowerCase().indexOf(resp) > -1 || (z.parameter.name && z.parameter.name.toLowerCase().indexOf(resp) > -1))
    );
  }

  getAllApiParameterValues() {
    this.attachSubscriptions(
      this.parametersService.getAllValues('1', this.data.company.id, {is_language: 1}, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.parametersService.getAllValues(x, this.data.company.id, {is_language: 1}).pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        this.allValues = [].concat(...res);
        this.allValues$.next(this.allValues.slice())

        if (this.data.selectedFiles.length == 1) {
          let arr = []
          this.data.file.parameterValuesToTask.filter(k => k.is_primary).forEach(el => {
            arr.push({
              ...el.parameterValue,
              ...{parameter: el.parameter, keyId: el.id}
            })
          });
          this.savedTags = [...arr];
          this.parameter_value = [...this.allValues.filter(k => this.savedTags.filter(p => p.id == k.id).length)];
        }
      })
    )
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

}
