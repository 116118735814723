<h2 mat-dialog-title class="dialog_title">
    <span>
        {{ "Editing a group" | translate }}
    </span>
    <button mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h2>
<mat-dialog-content class="mat-typography" dirEnter (dirSubmit)="!form.valid ? null : submitForm()">
    <div class="container form_wrapper" style="padding: 20px 0;">
        <form [formGroup]="form">
            <ng-container *ngIf="groups && groups.length > 5; else elseTemplateWithSearch">
                <mat-form-field appearance="standard" class="full_width_form">
                    <mat-label>{{ "Choose a group" | translate }}</mat-label>
                    <mat-select disableOptionCentering formControlName="group_id">
                        <mat-option>
                            <ngx-mat-select-search 
                            placeholderLabel="{{ 'Search' | translate }}" 
                            noEntriesFoundLabel="{{ 'no matching group found' | translate }}"
                            [formControl]="groupsMoreControl"></ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let group of groups$ | async" [value]="group.id">
                            {{ group.name | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </ng-container>
            <ng-template #elseTemplateWithSearch>
                <mat-form-field appearance="standard" class="full_width_form">
                    <mat-label>{{ "Choose a group" | translate }}</mat-label>
                    <mat-select disableOptionCentering formControlName="group_id">
                        <mat-option *ngFor="let group of groups" [value]="group.id">
                            {{ group.name | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </ng-template>
        </form>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button (click)="close()">
        {{ "Back" | translate }}
    </button>
    <button class="ml-1" (click)="submitForm()" mat-raised-button cdkFocusInitial color="primary" [disabled]="!form.valid || !!isSubmit">
        {{ "Save" | translate }}
    </button>
</mat-dialog-actions>
