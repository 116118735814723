<mat-dialog-content class="mat-typography">
    <div class="container form_wrapper" [appDeviceClass]="'custom-scroll custom-scroll-t'">
        <div class="title_modal d-f-c">
            <span class="set_width">
                {{ "Change rules" | translate }}:
            </span>
            <ng-container *ngIf="data?.is_partner; else elseTemplateIsPartner">
                <app-user [class]="'user_div ml-1'" [companyEmployee]="data?.employee?.partnerCompany" [imgRoute]="imgRoute" [host]="host" [company]="data?.company"></app-user>
            </ng-container>
            <ng-template #elseTemplateIsPartner>
                <app-user [class]="'user_div ml-1'" [employee]="data?.employee"></app-user>
            </ng-template>
        </div>

        <form [formGroup]="form" class="form_with_pads" (ngSubmit)="onSubmit()">
            <mat-form-field appearance="legacy" class="full_width_form">
                <mat-label>{{ "Rule" | translate }}</mat-label>
                <mat-select formControlName="permission">
                  <mat-option *ngFor="let rule of rules" [value]="rule">
                      {{ rule | translate}}
                  </mat-option>
                </mat-select>
                <button class="suffix_button" [ngStyle]="{'color': (!form.get('permission').value || isSubmit) ? '#686868' : '#4E844E'}" [disabled]="!form.get('permission').value || isSubmit" type="submit" (click)="$event.stopPropagation()" mat-icon-button matSuffix>
                    <mat-icon>add_circle</mat-icon>
                </button>
            </mat-form-field>
    
            <mat-nav-list *ngIf="emplRules?.length">
                <mat-list-item *ngFor="let emRule of emplRules; let i = index;" class="rule_out_item">
                    <div class="rule_item">
                        <span>{{emRule.permission | translate}}</span>
                        <mat-icon style="color: red;" (click)="deleteEmployeeRule(emRule.id, i)">delete</mat-icon>
                    </div>
                </mat-list-item>
            </mat-nav-list>
    
            <div class="block" *ngIf="groupManagers && groupManagers.length">
                <div class="title">
                    {{ "Manager in groups" }}:
                </div>
                
                <mat-nav-list>
                    <mat-list-item *ngFor="let groupManager of groupManagers; let i = index;" class="rule_out_item">
                        <div class="rule_item">
                            <span>{{ groupManager?.group?.name }}</span>
                            <mat-icon style="color: red;" (click)="deleteGroupManager(groupManager, i)">delete</mat-icon>
                        </div>
                    </mat-list-item>
                </mat-nav-list>
            </div>   

            <div class="block" *ngIf="managerTasks && managerTasks.length">
                <div class="title">
                    {{ "Manager in cards" }}:
                </div>
                
                <mat-nav-list>
                    <mat-list-item *ngFor="let managerTask of managerTasks; let i = index;" class="rule_out_item" (click)="openTask(managerTask)">
                        <div class="rule_item">
                            <div class="rule_line">
                                <div class="d-f-c">
                                    <span class="mr-1 block_task_id">{{ managerTask.id }}</span>
                                    <span class="text_one_line">{{ managerTask?.name }}</span>
                                </div>
                                <span class="ml-1 block_task_group text_one_line" style="color: #2d9cdb;">{{ managerTask?.group?.name }}</span></div>
                            <!-- <mat-icon style="color: red;" (click)="deleteTaskManager($event, managerTask, i)">delete</mat-icon> -->
                        </div>
                    </mat-list-item>
                </mat-nav-list>
            </div>

            <div class="block" *ngIf="clientTasks && clientTasks.length">
                <div class="title"> 
                    {{ "Client in cards" }}:
                </div>
                
                <mat-nav-list>
                    <mat-list-item *ngFor="let clientTask of clientTasks; let i = index;" class="rule_out_item" (click)="openTask(clientTask)">
                        <div class="rule_item">
                            <div class="rule_line">
                                <div class="d-f-c">
                                    <span class="mr-1 block_task_id">{{ clientTask.id }}</span>
                                    <span class="text_one_line">{{ clientTask?.name }}</span>
                                </div>
                                <span class="ml-1 block_task_group text_one_line" style="color: #2d9cdb;">{{ clientTask?.group?.name }}</span></div>
                            <!-- <mat-icon style="color: red;" (click)="deleteClientInTask($event, clientTask, i)">delete</mat-icon> -->
                        </div>
                    </mat-list-item>
                </mat-nav-list>
            </div>   
        </form>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <div class="f_w_p_btns">
        <button mat-raised-button type="button" (click)="close()"> 
            {{ "Close" | translate }}
        </button>
    </div>
</mat-dialog-actions>