<h2 mat-dialog-title class="dialog_title">
    <span>
        {{ "Do you want to approve a job?" | translate }}
    </span>
    <button mat-icon-button (click)="no()">
        <mat-icon>close</mat-icon>
    </button>
</h2>
<mat-dialog-actions align="end">
    <button mat-raised-button (click)="no()">
        {{ "No" | translate }}
    </button>
    <button class="ml-1" (click)="yes()" mat-raised-button cdkFocusInitial color="primary" [disabled]="isSubmit">
        {{ "Yes" | translate }}
    </button>
</mat-dialog-actions>
