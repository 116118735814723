import { Component, Inject, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ReplaySubject, Subscription, concat, forkJoin, from, fromEvent, of, scheduled } from 'rxjs';
import { catchError, concatMap, debounceTime, distinctUntilChanged, filter, last, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { BaseClass } from 'src/app/shared/models/base-class';
import { FileService } from 'src/app/shared/services/rest/file.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { GetFilesSettingsComponent } from '../get-files-settings/get-files-settings.component';
import { LoadingService } from 'src/app/shared/services/rest/loading.service';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { MatBottomSheet } from '@angular/material/bottom-sheet';

import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as moment from 'moment';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { GlobalDataService } from 'src/app/shared/services/common/global-data.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { UrlAnalyticsComponent } from '../url-analytics/url-analytics.component';
import { RefreshService } from 'src/app/shared/services/rest/refresh.service';
import { MobUrlAnalyticsComponent } from '../mob-url-analytics/mob-url-analytics.component';
import { AddEditLiveStreamComponent } from '../add-edit-live-stream/add-edit-live-stream.component';
import { AddTaskProfileComponent } from '../add-task-profile/add-task-profile.component';
import { DeleteAlertComponent } from 'src/app/shared/global_components/delete-alert/delete-alert.component';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { SelectionModel } from '@angular/cdk/collections';
import { BackJobToPrevStatusComponent } from 'src/app/shared/global_components/back-job-to-prev-status/back-job-to-prev-status.component';
import { DuplicateSettingsComponent } from '../servers-table/dialogs/duplicate-settings/duplicate-settings.component';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { EditStatusComponent } from 'src/app/components/atTasksDialog/profiles/edit-status/edit-status.component';
import { transformToWatchUrl } from 'src/app/shared/functions/liveUrlTransform';
export const MY_FORMATS = {
  parse: {
    dateInput: 'LLLLL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
export const MY_NEW_FORMATS = {
  parse: {
    dateInput: 'MM/DD/YYYY',
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'MM/DD/YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-stream-restart-assistant',
  templateUrl: './stream-restart-assistant.component.html',
  styleUrls: ['./stream-restart-assistant.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_NEW_FORMATS},
  ]
})
export class StreamRestartAssistantComponent extends BaseClass implements OnInit, OnDestroy {
  public progress:number = 0;

  public page = 1;
  public pagination: any;

  public elements: any[] = [];

  public contentTypes: any[] = [];
  public contentTypeMoreControl: FormControl = new FormControl();
  public contentTypes$: ReplaySubject<any> = new ReplaySubject<any>(1);
  
  public channels: any[] = [];
  public channelMoreControl: FormControl = new FormControl();
  public channels$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public today = moment().set({hour:0,minute:0,second:0}).unix();
  public todayEnd = moment().endOf('day').unix();

  public profileStatuses: any;

  public filter: FormGroup = this.fb.group({
    url: ""
  })

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ls: LoadingService,
    private taskService: TaskService,
    private globalDataService: GlobalDataService,
    private layoutService: LayoutService,
    private companyService: CompanyService,
    public viewContainerRef: ViewContainerRef,
    public dialog: MatDialog,
    public overlay: Overlay,
    public fb: FormBuilder,
    private _adapter: DateAdapter<any>,
    public dialogRef: MatDialogRef<StreamRestartAssistantComponent>,
  ) { super() }

  ngOnInit(): void {
    console.log("StreamRestartAssistantComponent this.data", this.data)
    this._adapter.setLocale(this.data.activeLang);
    this.getProfilesStatus();
    this.getContentTypes();

    this.attachSubscriptions(
      this.taskService.getRestartLivesStream().pipe(
        map(val => {
          console.log("getRestartLivesStream", val)
          let elementObject = {
            id: this.generateRandomId(),
            key: '',
            status: 'processed',
            url: val,
            stage: 'Getting key'
          }
          this.elements.push(elementObject);
          return elementObject
        }),
        // concatMap
        mergeMap(obj => {
          return this.taskService.getKeyFromYtUrl(obj.url, this.data.company.id).pipe(
            tap(x => {
              console.log("getKeyFromYtUrl", x)
            }),
            catchError(error => {
              // return of('123ewqaz')
              return of('error: '+error)
            }),
            map(key => {
              obj.key = key;
              obj.stage = key.indexOf('error') != -1 ? "No key" : "Searching the post"
              obj.status = key.indexOf('error') != -1 ? 'error' : 'processed';

              return obj
            }),
            switchMap((objData:any) => {
              if (objData.status != 'error') {
                return this.taskService.getLiteUrls('1', this.data.company.id, {external_stream_id: objData.key}, '200', ',task,taskChannelFiles.originalFile').pipe(
                  map(x => {
                    if (x.body.length == 1) {
                      objData.post = x.body[0];
                      if (this.getProfileStatusById(objData.post.content_status_id)) {
                        objData.post.contentStatusName = this.getProfileStatusById(objData.post.content_status_id).name
                      }
                      objData.stage = 'Updating post';
                    } else if (x.body.length == 0) {
                      objData.status = 'error';
                      objData.stage = "No post";
                    } else {
                      this.elements.splice(this.elements.findIndex(q => q.id == objData.id), 1);
                      x.body.forEach(post => {
                        let k = {...objData};
                        k.status = 'error';
                        k.stage = 'Duplicate';
                        k.post = post;
                        if (this.getProfileStatusById(k.post.content_status_id)) {
                          k.post.contentStatusName = this.getProfileStatusById(k.post.content_status_id).name
                        }
                        this.elements.push(k);
                      })
                    }
                    return objData;
                  }),
                  switchMap(() => {
                    if (objData.post && objData.stage == 'Updating post') {
                      if (objData.post.content_status_id == 14) {
                        let sbmtData:any = {
                          task_id: objData.post.task_id,
                          channel_id: objData.post.channel_id,
                          content_url: objData.post.content_url,
                          content_name: objData.post.content_name,
                          is_content_views_count_update: objData.post.is_content_views_count_update,
                          is_bravourl_content_views_count_update: objData.post.is_bravourl_content_views_count_update,
                          content_image: objData.post.content_image,
                          content_filename: objData.post.content_filename,
                          external_content_id: objData.post.external_content_id,
                          content_type_id: objData.post.content_type_id,
                          content_published_at: objData.post.content_published_at,
                          content_views_count: objData.post.content_views_count,
                          publishing_params: objData.post.publishing_params,
                        }
                        
                        let deleteErrors:number = 0;  

                        if (!objData.post.taskChannelFiles || !objData.post.taskChannelFiles.length) {
                          objData.stage = 'No files selected for Live stream.';
                          objData.status = 'error';
                          return of(objData)
                        }

                        if (objData.post.publishing_params.random_start_count <= objData.post.taskChannelFiles.length) {
                          return this.taskService.getSmartLinkData(objData.url, objData.post.channel_id, this.data.company.id, (objData.post.company_id != this.data.company.id ? objData.post.company_id : null)).pipe(
                            switchMap(newLinkData => {
                              sbmtData.content_name = newLinkData.name ? newLinkData.name : 'Post has no text';
                              sbmtData.content_url = objData.url;
                              sbmtData.external_content_id = newLinkData.external_content_id;
                              sbmtData.content_filename = !!newLinkData.filename ? newLinkData.filename : '';
                              sbmtData.content_image = newLinkData.image;
                              sbmtData.content_resumed_at = 0;
                              if (newLinkData.published_at) {
                                sbmtData.content_published_at = newLinkData.published_at;
                              }
                              sbmtData.content_views_count = !!newLinkData.views_count ? newLinkData.views_count : '0';
              
                              return this.taskService.getTaskChannelFile(this.data.company.id, {task_channel_id: objData.post.id}).pipe(
                                switchMap(files => {
                                  if (files.length) {
              
                                    files.forEach((chFile, ind) => {
                                      if (!!chFile.is_error) {
                                        chFile.deleted = true;
                                        if (files.filter(u => u.file_id == chFile.file_id && u.is_new).length == 0) {
                                          files.push({
                                            company_id: chFile.company_id,
                                            task_channel_id: '',
                                            file_id: chFile.file_id,
                                            is_to_upload: 1,
                                            is_live_stream_content: 1,
                                            file: chFile.file,
                                            is_new: true
                                          })
                                        }
                                      }
                                    });
              
                                    let newFiles = [...files.filter(u => !!u.is_new)];
                                    let deletedFiles = [...files.filter(u => !!u.deleted)];
                                    console.log("newFiles", newFiles);
                                    console.log("deletedFiles", deletedFiles);
                                    if (deletedFiles.length) {
                                      return forkJoin([...deletedFiles.map(fileData => this.taskService.unsetTaskChannelFile(fileData.id, this.data.company.id).pipe(
                                        catchError((error) => {
                                          deleteErrors++;                            
                                          return of(null)
                                        })
                                      ))]).pipe(
                                        switchMap(deleted => {
                                          if (deleteErrors == 0 && newFiles.length) {
                                            return forkJoin([...newFiles.map(fileData => this.taskService.setTaskChannelFile({
                                              company_id: fileData.company_id,
                                              task_channel_id: objData.post.id,
                                              file_id: fileData.file_id,
                                              is_to_upload: !!fileData.is_to_upload ? 1 : 0,
                                              is_live_stream_content: !!fileData.is_live_stream_content ? 1 : 0
                                            }, this.data.company.id).pipe(
                                              catchError((error) => {
                                                return of(null)
                                              })
                                            ))]).pipe(
                                              map(() => files)
                                            )
                                          } else {
                                            return of(files)
                                          }
                                        })
                                      )
                                    } else if (newFiles.length) {
                                      return forkJoin([...newFiles.map(fileData => this.taskService.setTaskChannelFile({
                                        company_id: fileData.company_id,
                                        task_channel_id: objData.post.id,
                                        file_id: fileData.file_id,
                                        is_to_upload: !!fileData.is_to_upload ? 1 : 0,
                                        is_live_stream_content: !!fileData.is_live_stream_content ? 1 : 0
                                      }, this.data.company.id).pipe(
                                        catchError((error) => {
                                          return of(null)
                                        })
                                      ))]).pipe(
                                        map(() => files)
                                      )
                                    } else {
                                      return of(files)
                                    }
              
                                  } else {
                                    return of(files)
                                  }
                                }),
                                switchMap(files => {
                                  if (deleteErrors > 0 || !files.length) {
                                    objData.status = 'error';
                                    objData.stage = 'Check post files';
                                    return of(objData)
                                  } else {
                                    return this.taskService.editProfile(objData.post.id, sbmtData, this.data.company.id).pipe(
                                      tap(editedPost => {
                                        objData.post.content_name = editedPost.content_name;
                                        objData.post.content_url = editedPost.content_url;
                                        objData.post.external_content_id = editedPost.external_content_id;
                                        objData.post.content_filename = editedPost.content_filename;
                                        objData.post.content_image = editedPost.content_image;
                                        objData.post.content_resumed_at = editedPost.content_resumed_at || 0;
                                        objData.post.content_published_at = editedPost.content_published_at;
                                        objData.post.content_views_count = editedPost.content_views_count;
                                        objData.post.content_status_id = editedPost.content_status_id;
                                        if (this.getProfileStatusById(objData.post.content_status_id)) {
                                          objData.post.contentStatusName = this.getProfileStatusById(objData.post.content_status_id).name
                                        }
                                      }),
                                      map(() => files)
                                    )
                                  }
                                }),
                                switchMap(files => {
                                  if (deleteErrors > 0 || !files.length) {
                                    objData.status = 'error';
                                    objData.stage = 'Check post files';
                                    return of(objData)
                                  } else {
                                    objData.stage = 'Restrating';
                                    return this.taskService.getPostExtra(1, this.data.company.id, {task_channel_id: objData.post.id, is_active: 1}).pipe(
                                      map(broadsRes => broadsRes.body),
                                      switchMap(broads => {
                                        if (broads.length) {
                                          if (broads.filter(u => !u.is_pause).length) {
                                            return forkJoin(broads.filter(u => !u.is_pause).map(notPausedBroad => this.taskService.editBroadcast(notPausedBroad.id, {is_pause: 1, pause_timeout: 20}))).pipe(last(), tap(() => {objData.stage = 'Restrated'; objData.status = 'restarted';}), map(() => objData))
                                          } else {
                                            objData.status = 'error';
                                            objData.stage = 'All broadcasts is paused now';
                                            return of(objData)
                                          }
                                        } else {
                                          objData.status = 'error';
                                          objData.stage = 'No broadcasts';
                                          return of(objData)
                                        }
                                      })
                                    )
                                  }
                                })
                              )
                            })
                          )
                        } else {
                          objData.status = 'error';
                          objData.stage = `Didn't selected enough files to start stream`;
                          this.layoutService.showSnackBar({name: ``}, `You didn't selected enough files to start stream.<br><br>It's set ${objData.post.publishing_params.random_start_count} files to start broadcast but selected ${objData.post.taskChannelFiles.length} files for streaming.<br><br>Select ${objData.post.publishing_params.random_start_count - objData.post.taskChannelFiles.length} files more to meet minimum requirement.`, SnackBarItem)
                          return of(objData)
                        }

                      } else {
                        let sbmtData:any = {
                          task_id: objData.post.task_id,
                          channel_id: objData.post.channel_id,
                          content_url: objData.post.content_url,
                          content_name: objData.post.content_name,
                          is_content_views_count_update: objData.post.is_content_views_count_update,
                          is_bravourl_content_views_count_update: objData.post.is_bravourl_content_views_count_update,
                          content_image: objData.post.content_image,
                          content_filename: objData.post.content_filename,
                          external_content_id: objData.post.external_content_id,
                          content_type_id: objData.post.content_type_id,
                          content_published_at: objData.post.content_published_at,
                          content_views_count: objData.post.content_views_count,
                          content_status_id: 2,
                          publishing_params: objData.post.publishing_params,
                        }
                        
                        let deleteErrors:number = 0;  

                        if (!objData.post.taskChannelFiles || !objData.post.taskChannelFiles.length) {
                          objData.stage = 'No files selected for Live stream.';
                          objData.status = 'error';
                          return of(objData)
                        }

                        if (objData.post.publishing_params.random_start_count <= objData.post.taskChannelFiles.length) {
                          return this.taskService.getSmartLinkData(objData.url, objData.post.channel_id, this.data.company.id, (objData.post.company_id != this.data.company.id ? objData.post.company_id : null)).pipe(
                            switchMap(newLinkData => {
                              sbmtData.content_name = newLinkData.name ? newLinkData.name : 'Post has no text';
                              sbmtData.content_url = objData.url;
                              sbmtData.external_content_id = newLinkData.external_content_id;
                              sbmtData.content_filename = !!newLinkData.filename ? newLinkData.filename : '';
                              sbmtData.content_image = newLinkData.image;
                              sbmtData.content_resumed_at = 0;
                              if (newLinkData.published_at) {
                                sbmtData.content_published_at = newLinkData.published_at;
                              }
                              sbmtData.content_views_count = !!newLinkData.views_count ? newLinkData.views_count : '0';
              
                              if (newLinkData.external_content_id) {
                                sbmtData.content_status_id = 6
                              }

                              return this.taskService.getTaskChannelFile(this.data.company.id, {task_channel_id: objData.post.id}).pipe(
                                switchMap(files => {
                                  if (files.length) {
              
                                    files.forEach((chFile, ind) => {
                                      if (!!chFile.is_error) {
                                        chFile.deleted = true;
                                        if (files.filter(u => u.file_id == chFile.file_id && u.is_new).length == 0) {
                                          files.push({
                                            company_id: chFile.company_id,
                                            task_channel_id: '',
                                            file_id: chFile.file_id,
                                            is_to_upload: 1,
                                            is_live_stream_content: 1,
                                            file: chFile.file,
                                            is_new: true
                                          })
                                        }
                                      }
                                    });
              
                                    let newFiles = [...files.filter(u => !!u.is_new)];
                                    let deletedFiles = [...files.filter(u => !!u.deleted)];
                                    console.log("newFiles", newFiles);
                                    console.log("deletedFiles", deletedFiles);
                                    if (deletedFiles.length) {
                                      return forkJoin([...deletedFiles.map(fileData => this.taskService.unsetTaskChannelFile(fileData.id, this.data.company.id).pipe(
                                        catchError((error) => {
                                          deleteErrors++;                            
                                          return of(null)
                                        })
                                      ))]).pipe(
                                        switchMap(deleted => {
                                          if (deleteErrors == 0 && newFiles.length) {
                                            return forkJoin([...newFiles.map(fileData => this.taskService.setTaskChannelFile({
                                              company_id: fileData.company_id,
                                              task_channel_id: objData.post.id,
                                              file_id: fileData.file_id,
                                              is_to_upload: !!fileData.is_to_upload ? 1 : 0,
                                              is_live_stream_content: !!fileData.is_live_stream_content ? 1 : 0
                                            }, this.data.company.id).pipe(
                                              catchError((error) => {
                                                return of(null)
                                              })
                                            ))]).pipe(
                                              map(() => files)
                                            )
                                          } else {
                                            return of(files)
                                          }
                                        })
                                      )
                                    } else if (newFiles.length) {
                                      return forkJoin([...newFiles.map(fileData => this.taskService.setTaskChannelFile({
                                        company_id: fileData.company_id,
                                        task_channel_id: objData.post.id,
                                        file_id: fileData.file_id,
                                        is_to_upload: !!fileData.is_to_upload ? 1 : 0,
                                        is_live_stream_content: !!fileData.is_live_stream_content ? 1 : 0
                                      }, this.data.company.id).pipe(
                                        catchError((error) => {
                                          return of(null)
                                        })
                                      ))]).pipe(
                                        map(() => files)
                                      )
                                    } else {
                                      return of(files)
                                    }
              
                                  } else {
                                    return of(files)
                                  }
                                }),
                                switchMap(files => {
                                  if (deleteErrors > 0 || !files.length) {
                                    objData.status = 'error';
                                    objData.stage = 'Check post files';
                                    return of(objData)
                                  } else {
                                    return this.taskService.editProfile(objData.post.id, sbmtData, this.data.company.id).pipe(
                                      tap(editedPost => {
                                        objData.post.content_name = editedPost.content_name;
                                        objData.post.content_url = editedPost.content_url;
                                        objData.post.external_content_id = editedPost.external_content_id;
                                        objData.post.content_filename = editedPost.content_filename;
                                        objData.post.content_image = editedPost.content_image;
                                        objData.post.content_resumed_at = editedPost.content_resumed_at || 0;
                                        objData.post.content_published_at = editedPost.content_published_at;
                                        objData.post.content_views_count = editedPost.content_views_count;
                                        objData.post.content_status_id = editedPost.content_status_id;

                                        if (this.getProfileStatusById(objData.post.content_status_id)) {
                                          objData.post.contentStatusName = this.getProfileStatusById(objData.post.content_status_id).name
                                        }
                                        
                                        if (sbmtData.content_status_id == 6) {
                                          objData.stage = 'Started';
                                          objData.status = 'restarted';
                                        } else {
                                          objData.stage = 'No external id';
                                          objData.status = 'error';
                                        }
                                      }),
                                      map(() => objData)
                                    )
                                  }
                                })
                              )
                            })
                          )
                        } else {
                          objData.status = 'error';
                          objData.stage = `Didn't selected enough files to start stream`;
                          this.layoutService.showSnackBar({name: ``}, `You didn't selected enough files to start stream.<br><br>It's set ${objData.post.publishing_params.random_start_count} files to start broadcast but selected ${objData.post.taskChannelFiles.length} files for streaming.<br><br>Select ${objData.post.publishing_params.random_start_count - objData.post.taskChannelFiles.length} files more to meet minimum requirement.`, SnackBarItem)
                          return of(objData)
                        }

                      }
                    } else {
                      return of(objData)
                    }
                  })
                )
              } else {
                return of(objData)
              }
            }),
          )
        })
      ).subscribe({
        next: (next) => {
          console.log("next getRestartLivesStream", next);
        },
        complete: () => {
          console.log("END getRestartLivesStream", this.elements);
        },
        error: (error) => {
          console.log("error getRestartLivesStream", error)
        }
      })
    )

    this.attachSubscriptions(
      this.filter.get("url").valueChanges.pipe(
        debounceTime(300),
        filter(val => !!val),
      ).subscribe(resp => {
        console.log("url", resp);
        this.taskService.restartLives$.next(resp);
        this.filter.patchValue({
          url: ''
        })
      })
    )
  }

  dblEditProfileStatus(el) {
    if (el.status != 'error' || !el.post) {
      return
    }
    
    const dialogRef = this.dialog.open(EditStatusComponent, {
      disableClose: true,
      data: {
        company: this.data.company,
        company_id: this.data.company.id,
        task: el.post.task,
        profile: el.post,
        is_edit: true
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        
        if (!!result && result.event == 'edit') {
          // this.getTask(true);
        }
      })
    )
  }

  generateRandomId(): string {
    const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    const length = 10;
    let randomId = '';
  
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      randomId += characters.charAt(randomIndex);
    }
  
    return randomId;
  }

  onInputUrl(originalUrl: string): void {
    console.log('originalUrl', originalUrl);
    let transformedUrl = transformToWatchUrl(originalUrl);
    
    if (transformedUrl != originalUrl) {
      this.filter.patchValue({
        url: transformedUrl
      })
    }
    console.log("transformedUrl", transformedUrl);
  }

  getProfileById(id) {
    return this.channels.find(x => x.id == id)
  }

  copyLiveData(type) {
    this.layoutService.showSnackBar({name: "Live " + type}, marker("Copied"), SnackBarItem)
  }

  getContentById(id) {
    if (!this.contentTypes || this.contentTypes.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.contentTypes.find(el => el.id == id)
  }

  getContentTypes() {
    this.attachSubscriptions(
      this.companyService.getContentTypes().pipe(
        map(el => el.body)
      ).subscribe(resp => {
        this.contentTypes = resp;
        this.contentTypes$.next(this.contentTypes.slice());
      })
    )
  }

  goToPost(e, profile) {
    console.log("e", e);
    console.log("profile", profile);

    if (!!profile.content_url) {
      window.open(profile.content_url, '_blank')
    }
  }

  toUploadUrls(array) {
    return [...array].filter(x => x.content_status_id == 6)
  }

  getProfilesStatus() {
    this.attachSubscriptions(
      this.globalDataService.getContentStatus().subscribe(resp => {
        this.profileStatuses = resp.slice();
        console.log("profileStatuses", this.profileStatuses)
      })
    )
  }

  getProfileStatusById(id) {
    if (this.profileStatuses) {
      return this.profileStatuses.find(x => x.id == id)
    } else {
      return undefined
    }
  }

  log() {
    console.log("StreamRestartAssistantComponent this.filter", this.filter.value)
    console.log("StreamRestartAssistantComponent this.elements", this.elements)
  }

  close() {
    this.dialogRef.close({event: "close", data: false});
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

}
