<h2 mat-dialog-title class="dialog_title">
    <span>
        {{ "Privacy" | translate }}: {{ data.file.filename }}
    </span>
    <button mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h2>
<mat-dialog-content class="mat-typography" dirEnter (dirSubmit)="!form.valid ? null : submitForm()">
    <div class="container">
        <form [formGroup]="form">
            <p>
                <mat-checkbox color="primary" formControlName="is_public">{{ "Public for all" | translate }}</mat-checkbox>
            </p>

            <p>
                <mat-checkbox color="primary" formControlName="is_public_registered">{{ "Public for registered" | translate }}</mat-checkbox>
            </p>
        </form>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button (click)="close()">
        {{ "Close" | translate }}
    </button>
    <button class="ml-1" (click)="submitForm()" mat-raised-button cdkFocusInitial color="primary" [disabled]="!form.valid">
        {{ "Save" | translate }}
    </button>
</mat-dialog-actions>
