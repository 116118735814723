import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { forkJoin, from, of } from 'rxjs';
import { concatMap, switchMap, tap } from 'rxjs/operators';
import { DeleteAlertComponent } from 'src/app/shared/global_components/delete-alert/delete-alert.component';
import { BaseClass } from 'src/app/shared/models/base-class';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { GroupManagerAddComponent } from '../group-manager-add/group-manager-add.component';
import { MembersService } from 'src/app/shared/services/rest/members.service';
import { LoadingService } from 'src/app/shared/services/rest/loading.service';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';

@Component({
  selector: 'app-group-managers',
  templateUrl: './group-managers.component.html',
  styleUrls: ['./group-managers.component.scss']
})
export class GroupManagersComponent extends BaseClass implements OnInit, OnDestroy {
  public groupManagers: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private taskService: TaskService,
    public dialogRef: MatDialogRef<GroupManagersComponent>,
    private dialog: MatDialog,
    private ls: LoadingService,
    private layoutService: LayoutService,
    private bottomSheet: MatBottomSheet,
    private membersService: MembersService,
  ) { super() }

  ngOnInit(): void {
    console.log("GroupManagersComponent", this.data)
    this.getGroupManagers();
  }

  toggleCanSee(manager) {
    if (!!manager.cabSeeDisabled) {
      return
    }
    manager.cabSeeDisabled = true
    if (manager.canSee && !!manager.canSee.length) {
      console.log('can See')
      this.attachSubscriptions(
        this.membersService.deleteChannelGroupToEmployee(manager.canSee[0].id).subscribe(resp => {
          this.getManagerCanSee(manager);
          manager.cabSeeDisabled = false
        })
      )
    } else {
      let sbmtData = {
        company_id: this.data.company.id,
        employee_id: manager.employee_id,
        channel_group_id: this.data.group.id,
      }
      this.attachSubscriptions(
        this.membersService.addChannelGroupToEmployee(sbmtData).subscribe(resp => {
          this.getManagerCanSee(manager);
          manager.cabSeeDisabled = false
        })
      )
      console.log('can NOT See')
    }
  }

  getManagerCanSee(manager) {
    let filter = {
      channel_group_id: this.data.group.id,
      employee_id: manager.employee_id
    }
    this.attachSubscriptions(
      this.membersService.getChannelGroupToEmployee(this.data.company.id, filter).subscribe(resp => {
        console.log("getChannelGroupToEmployee", resp)
        manager.canSee = resp;
      })
    )
  }

  getCanSee() {
    let lenSelection = this.groupManagers.length;
    let count = 0;

    this.ls.requests$.next({
      value: 0,
      target: `Getting can see permissions for ${lenSelection} manager(s)`
    })

    from(this.groupManagers).pipe(
      concatMap((manager:any) => {
        let filter = {
          channel_group_id: this.data.group.id,
          employee_id: manager.employee_id
        }
        return this.membersService.getChannelGroupToEmployee(this.data.company.id, filter).pipe(
          tap(res => {
            manager.canSee = res;
          })
        )
      })
    ).subscribe({
      next: (next) => {
        console.log("next getCanSee", next);
        this.ls.requests$.next({
          value: Math.round((100 / lenSelection) * (count+1)),
          target: `Getting can see permissions for ${lenSelection} manager(s)`
        })
        count++;
      },
      complete: () => {
        console.log("complete getCanSee");
      },
      error: (error) => {
        console.log("error getCanSee", error)
      }
    })
  }

  toggleManager(e, manager) {
    manager.disabled = true
    this.taskService.editManagerInGroup(manager.id, {is_price_manager: Number(e.checked)}).subscribe(resp => {
      manager.is_price_manager = Number(e.checked);
      manager.disabled = false;
    })
  }

  addGroupManager() {
    const dialogRef = this.dialog.open(GroupManagerAddComponent, {
      data: {
        company: this.data.company,
        user: this.data.user,
        groupManagers: this.groupManagers,
        group: this.data.group
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log(result)
        if (result.event == 'update') {
          this.getGroupManagers();
        }
      })
    )
  }

  getGroupManagers() {
    this.taskService.getGroupManagers({group_id: this.data.group.id}).subscribe(resp => {
      console.log("getGroupManagers", resp);
      this.groupManagers = resp;

      if (this.groupManagers && this.groupManagers.length) {
        this.getCanSee()
      }
    })
  }

  close() {
    this.dialogRef.close({event: "close", data: false});
  }

  deleteGroupManager(manager, i) {
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: {},
        target: marker("group manager")
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        this.attachSubscriptions(
          this.taskService.deleteManagerFromGroup(manager.id).pipe(
            tap(x => {
              console.log(x)
              this.groupManagers.splice(i,1);
            }),
            switchMap(x => this.taskService.getGroupManagers({employee_id: manager.employee.id})),
            switchMap(x => {
              if (x.length == 0) {
                return this.taskService.getEmployeeRules(manager.employee.id, this.data.company.id).pipe(
                  switchMap(c => {
                    return forkJoin(c.filter(z => z.permission == 'employee_list').map(t => this.taskService.deleteEmployeeRule(t.id)))
                  })
                )
              } else {
                return of(x)
              }
            }),
          
          ).subscribe(resp => {
            console.log("END",resp)
          })
        )
      }
    });
  
  }

  ngOnDestroy() {
    this.clearSubscriptions()
  }

}
