import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTable } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, tap } from 'rxjs/operators';
import { DeleteAlertComponent } from 'src/app/shared/global_components/delete-alert/delete-alert.component';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { AuthService } from 'src/app/shared/services/rest/auth.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { StatementService } from 'src/app/shared/services/rest/statement.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { environment } from 'src/environments/environment';
import { AddEditStTemplateComponent } from './dialogs/add-edit-st-template/add-edit-st-template.component';

@Component({
  selector: 'app-statements-templates',
  templateUrl: './statements-templates.component.html',
  styleUrls: ['./statements-templates.component.scss']
})
export class StatementsTemplatesComponent extends BaseClass implements OnInit, OnDestroy {
  @ViewChild("tableContainer") tableContainer: ElementRef;
  @ViewChild(MatTable) table: MatTable<any>;
  public host: any = environment.host;
  public imgRoute: any = '';
  public user: any = {};
  public is_mobile: boolean = false;
  public company_id: any;
  public company: any;
  public activeLang: any;
  public statementTempls: any;
  public pagination: any;
  public page: number = 1;
  public user_id: number;
  public displayedColumns: string[] = ["ID", "Created At", "Name", "Activity"];
  constructor(
    private sm: StorageManagerService,
    private auth: AuthService,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private statementService: StatementService,
    private bottomSheet: MatBottomSheet,
    private companyService: CompanyService,
    private languageService: LanguageService,
    private dialog: MatDialog,
    private router: Router,
    private _adapter: DateAdapter<any>,
  ) {
    super()
   }

  ngOnInit(): void {
    this.company_id = this.activatedRoute.snapshot.queryParamMap.get('company_id');

    this.checkIsMobile()
    this.getLangData();
    this.getImgRoute();
    this.getUser();
    this.getStatementTemplates(this.page);
    this.getCompany();
  }

  getCompany() {
    this.attachSubscriptions(
      this.companyService.getCompany(this.company_id).subscribe(resp => {
        this.companyService.company$.next(resp[0]);
        this.company = resp[0];
        // if (this.company.permissions.includes('owner') || this.company.permissions.includes('admin')) {
        //   this.displayedColumns.push("Activity")
        // }
      })
    )
  }

  ngAfterViewInit(): void {
    this.onScroll();
  }

  deleteTemplate(tmpl) {
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: {},
        target: marker("statement template")
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        this.attachSubscriptions(
          this.statementService.deleteStatementTemplate(this.company_id, tmpl.id).subscribe(resp => {
            this.statementTempls.splice(this.statementTempls.indexOf(tmpl), 1);
            if (this.table) {
              this.table.renderRows();
            }
          })
        )
      }
    });
  }

  onScroll() {
    this.attachSubscriptions(
      fromEvent(this.tableContainer.nativeElement, "scroll").pipe(
        filter((e:any) => e.target.scrollTop >=  e.target.scrollHeight - e.target.offsetHeight - 400),
        map(() => this.tableContainer.nativeElement.scrollTop),
        debounceTime(300),
        map(y => Math.ceil((y + this.tableContainer.nativeElement.innerHeight)/ (400))),
        distinctUntilChanged()
      ).subscribe(() => {
          if (this.page <= this.pagination['pageCount']) {
            this.getStatementTemplates(this.page);
          }
        }
      )
    )
  }

  getStatementTemplates(page) {
    this.attachSubscriptions(
      this.statementService.getStatementTemplates(page, this.company_id, null, '200').pipe(
        tap(el => {
          this.pagination = {
            'pageCount': el.headers.get('x-pagination-page-count'),
            'perPage': el.headers.get('x-pagination-per-page'),
            'totalCount': el.headers.get('x-pagination-total-count'),
            'currentPage': el.headers.get('x-pagination-current-page'),
          }
        }),
        map(el => el.body),
      ).subscribe(resp => {
        if (page == 1) {
          this.statementTempls = resp
        } else {
          this.statementTempls.push(...resp)
        }
        console.log("statementTempls", this.statementTempls)
        // if (this.table) {
        //   this.table.renderRows();
        // }
        this.page++;
      })
    )
  }

  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData().pipe(
        tap(resp => {
          this.activeLang = resp.active;
          this._adapter.setLocale(resp.active);
          // this.currMonthName = moment().format('MMMM');
        })
      ).subscribe(resp => {
        console.log("getOperations", resp)
      })
    )
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  getUser() {
    this.attachSubscriptions(
      this.auth.$user.subscribe(resp => {
        this.user = resp;
      })
    )
  }

  goBack(e) {
    e.preventDefault();
    history.back();
  }

  addEditTemplate(e, element?) {
    e.preventDefault();
    const dialogRef = this.dialog.open(AddEditStTemplateComponent, {
      backdropClass: 'backdrop_under_header',
      panelClass: !this.is_mobile ? ['open_task_dialog', 'show_header'] : 'open_task_dialog',
      disableClose: true,
      data: {
        user: this.user,
        company: this.company,
        company_id: this.company_id,
        tmpl: element
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result && result.event == "update") {
          this.page = 1;
          this.getStatementTemplates(this.page);
        }
      })
    )
  }

  checkIsMobile() {
    if (window.innerWidth <= 769) {
      this.is_mobile = true;
    } else {
      this.is_mobile = false;
    }
    this.onResize();
  }

  onResize() {
    this.attachSubscriptions(
      fromEvent(window, "resize").pipe(
        map(() => window.innerWidth)
      ).subscribe((wWidth) => {
          if (wWidth <= 769) {
            this.is_mobile = true;
          } else {
            this.is_mobile = false;
          }
        }
      )
    )
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

}
