<h2 mat-dialog-title class="dialog_title">
    <span>
        {{ "Edditing a task" | translate }}
    </span>
    <button mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h2>
<mat-dialog-content class="mat-typography"  dirEnter (dirSubmit)="!form.valid ? null : onSubmit()">
    <div class="container form_wrapper">
        <form [formGroup]="form">
            <div class="custom_radio">
                <label>{{ "Choose a status" | translate }}: </label>
                <mat-radio-group formControlName="status_id">
                  <mat-radio-button color="primary" *ngFor="let status of statuses" [value]="status.id">{{ status.name | translate }}</mat-radio-button>
                </mat-radio-group>
            </div>   
        </form>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">


    <div class="f_w_p_btns">
        <button mat-raised-button (click)="close()">
            {{ "Back" | translate }}
        </button>

        <ng-container *ngIf="!!data?.is_all_results; else elseTemplateAllRes">            
            <button class="ml-1 d-f-btn" (click)="onSubmit()" cdkFocusInitial mat-raised-button color="primary" [disabled]="isLoad || isSubmit">
                <mat-spinner class="spin_16 mr-1" *ngIf="isLoad || isSubmit"></mat-spinner>  
                {{ (isLoad ? "Getting Cards" : "Save") | translate }}
            </button>
        </ng-container>
        <ng-template #elseTemplateAllRes>
            <button class="ml-1" (click)="onSubmit()" mat-raised-button cdkFocusInitial color="primary" [disabled]="!form.valid">
                {{ "Save" | translate }}
            </button>
        </ng-template>
        
    </div>
    <!-- isLoad -->
    <div *ngIf="!!data?.is_all_results" class="table_progress_wrp" [ngClass]="{'hidded': progress >= 100}">
        <mat-progress-bar
            class="table_progress"
            [color]="'primary'"
            [mode]="'determinate'"
            [value]="progress">
        </mat-progress-bar>
        <span class="percent">{{progress}}%</span>
    </div>
</mat-dialog-actions>
