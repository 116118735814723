<div class="cases_page tasks_page">
  <div class="container">
    <div class="filter_out">  
      <div class="filter_values">
        <mat-chip-list class="chips_wrp without_ordered start_page_chips">

          <mat-chip (removed)="removeValue('q')" *ngIf="filter.get('q').value">
            {{filter.get('q').value}}
            <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
          </mat-chip>
        
          <mat-chip (removed)="removeValue('id')" *ngIf="filter?.get('id')?.value">
            {{ "Group ID" | translate }}: {{ filter?.get('id')?.value }}
            <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
          </mat-chip>

          <ng-container *ngIf="filter.get('parameter_value_id').value.length">
            <span class="chip_out_label">{{ "Group tag" | translate }}</span>
            <mat-chip *ngFor="let groupTagId of filter.get('parameter_value_id').value" (removed)="removeValueFromMultiple('parameter_value_id', groupTagId)">
               {{ getValueById(groupTagId)?.value }}
              <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
            </mat-chip>
          </ng-container>

          <mat-chip (removed)="removeValue('content_type_id')" *ngIf="filter.get('content_type_id').value">
            <ng-container *ngIf="filter.get('content_type_id').value.toString() == '0'; else elseTemplateZeroContent">
                {{ "Content Type" | translate }}: {{ "Not set" | translate }}
            </ng-container>
            <ng-template #elseTemplateZeroContent>
                {{ "Content Type" | translate }}: {{ getContentById(filter.get('content_type_id').value)?.name }}
            </ng-template>
         
            <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
          </mat-chip>

          <ng-container *ngIf="filter.get('channel_id').value.length">
            <span class="chip_out_label">{{ "Outlets" | translate }}</span>
            <mat-chip [ngClass]="{'user_chip': id != 0}" *ngFor="let id of filter.get('channel_id').value" (removed)="removeValueFromMultiple('channel_id', id)">
              <ng-container *ngIf="id != 0; else elseTemplateOutletNotSet">
                <app-outlet-line [channel]="getChannelById(id)" [company_id]="company_id"></app-outlet-line>
              </ng-container>
              <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
            </mat-chip>
          </ng-container>
         
          <mat-icon *ngIf="filter.get('q').value || filter.get('parameter_value_id').value.length || filter.get('id').value || filter.get('content_type_id').value || filter.get('channel_id').value.length" class="remove_all_chips_btn" (click)="removeValue('all')">cancel</mat-icon>
        </mat-chip-list>

        <div class="stats_wrp ml-1">
          <div class="tasks_counters">
            
            <!-- <form [formGroup]="filter" class="preset_form" *ngIf="bookmarks && bookmarks.length > 0">
              <div class="simple_filter_snippet" (click)="presetFilter.open()">
                <ng-container *ngIf="filter.get('preset_id').value; else elseTemplatePreset">
                  <span class="name">
                    {{ getBookmarkById(filter.get('preset_id').value)?.name }}
                  </span>
                </ng-container>
                <ng-template #elseTemplatePreset>
                  <span class="name">
                    {{ "Bookmark" | translate }}
                  </span>
                </ng-template>
                

                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_2474_38987)">
                  <path d="M7.91699 3.33325L2.08366 3.33325L5.00033 7.91658L7.91699 3.33325Z" fill="black"/>
                  </g>
                  <defs>
                  <clipPath id="clip0_2474_38987">
                  <rect width="10" height="10" fill="white" transform="translate(10) rotate(90)"/>
                  </clipPath>
                  </defs>
                </svg>
              </div>

              <mat-form-field appearance="legacy" class="filter_form preset_filter">
                <mat-label>{{ "Bookmark" | translate }}</mat-label>
                <mat-select #presetFilter [disableOptionCentering]="true" selectDir formControlName="preset_id" (selectionChange)="bookmarksChange($event)">
                    <mat-option *ngIf="bookmarks.length > 7">
                      <ngx-mat-select-search 
                          placeholderLabel="{{ 'Search' | translate }}" 
                          noEntriesFoundLabel="{{ 'no matching presets found' | translate }}"
                          [formControl]="bookmarksControl"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let bookmark of bookmarks$ | async" [value]="bookmark.id">
                      {{ bookmark.name | translate }}
                    </mat-option>
                </mat-select>
              </mat-form-field>
            </form> -->

            <div class="counter_block" (click)="log()" *ngIf="groupsPagination && groupsPagination['totalCount']">
              <div class="counter_top">{{ "Groups" | translate }}</div>
              <div class="counter_bottom">{{groupsPagination['totalCount']}}</div>
              <!-- \{{chats.length}} -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="lists_wrp">
    </div> 

    <div class="outlets_head">
      <div class="col_outlet">
        <div class="title">{{"Name" | translate}}</div>
      </div>
      <div class="col_group">
        <div class="title">{{"Outlets" | translate}}</div>
      </div>
      <div class="col_tags">
        <div class="title">{{"Checklist" | translate}}</div>
      </div>
      <div class="col_publisher">
        <div class="title">{{"Users" | translate}}</div>
      </div>
      <div class="col_action">
        <div class="title"></div>
      </div>
    </div>  

  </div>
</div>
<div class="container centred_page">

    <div class="step">        
        <!-- ["Date", "Id", "Name", "btns"] -->
        <div class="outlets" [appDeviceClass]="'custom-scroll custom-scroll-t'" #tableContainer>
          <div class="outlet" *ngFor="let element of groups; let i = index;">

            <div class="col_outlet">
              <div class="name">{{element.name}}</div>

              <div class="chan_tags" [ngClass]="{'has': element.tags && element.tags.length, 'show_on_hover': !(element.tags && element.tags.length)}" (click)="element.tags && element.tags.length ? null : openTargetValues(element)">
                <svg class="cp" (click)="openTargetValues(element)" style="width: 20px; height: 16px;" width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.5 5C3.89782 5 4.27936 4.84196 4.56066 4.56066C4.84196 4.27936 5 3.89782 5 3.5C5 3.10218 4.84196 2.72064 4.56066 2.43934C4.27936 2.15804 3.89782 2 3.5 2C3.10218 2 2.72064 2.15804 2.43934 2.43934C2.15804 2.72064 2 3.10218 2 3.5C2 3.89782 2.15804 4.27936 2.43934 4.56066C2.72064 4.84196 3.10218 5 3.5 5ZM15.41 7.58C15.77 7.94 16 8.44 16 9C16 9.55 15.78 10.05 15.41 10.41L10.41 15.41C10.05 15.77 9.55 16 9 16C8.45 16 7.95 15.78 7.58 15.41L0.59 8.42C0.22 8.05 0 7.55 0 7V2C0 0.89 0.89 0 2 0H7C7.55 0 8.05 0.22 8.41 0.58L15.41 7.58ZM11.54 1.71L12.54 0.71L19.41 7.58C19.78 7.94 20 8.45 20 9C20 9.55 19.78 10.05 19.42 10.41L14.04 15.79L13.04 14.79L18.75 9L11.54 1.71Z" fill="#686868"/>
                </svg>
                <span *ngIf="!(element.tags && element.tags.length)">{{"Set Tags" | translate}}</span>
                <div class="tags_wrp" *ngIf="element.tags && element.tags.length">
                    <ng-container *ngFor="let item of element.tags; let last = last">
                        <span>{{item?.parameterValue?.value}}<ng-container *ngIf="!last">,&nbsp;</ng-container></span>
                    </ng-container>
                </div>
              </div>

              <div class="id show_on_hover">ID {{element.id}}</div>
              <div class="date show_on_hover">
                <span>
                  <svg style="width: 8px; height: 8px; margin-right: 2.5px;" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.57031 0.571429C4.57031 0.255837 4.31448 0 3.99888 0C3.68329 0 3.42746 0.255837 3.42746 0.571429V3.42857H0.571429C0.255838 3.42857 1.37952e-08 3.68441 0 4C-1.37952e-08 4.31559 0.255837 4.57143 0.571429 4.57143H3.42746V7.42857C3.42746 7.74416 3.68329 8 3.99888 8C4.31448 8 4.57031 7.74416 4.57031 7.42857V4.57143H7.42857C7.74416 4.57143 8 4.31559 8 4C8 3.68441 7.74416 3.42857 7.42857 3.42857H4.57031V0.571429Z" fill="#84888C"/>
                  </svg>
                  {{element.created_at+ "000" | date:'mediumDate':'':activeLang | yearPipe}}</span>
                <span class="ml-5 mr-5">|</span>
                <span>
                  <svg style="width: 9px; height: 8px; margin-right: 2.5px;" width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.89541 1.79642C8.06872 1.62311 8.06872 1.33426 7.89541 1.16984L6.85555 0.129982C6.69113 -0.0433273 6.40228 -0.0433273 6.22897 0.129982L5.41131 0.943202L7.07775 2.60964M0.0253906 6.33357V8H1.69183L6.6067 3.08068L4.94026 1.41425L0.0253906 6.33357Z" fill="#84888C"/>
                  </svg>
                  {{element.updated_at+ "000" | date:'mediumDate':'':activeLang | yearPipe}}
                </span>
              </div>
            </div>

            <div class="col_group">
              <div class="channels">
                <ng-container *ngIf="company | perm:'see_task_channels'">
                  <div class="channel" *ngFor="let channel of element?.channels" [ngClass]="{
                    'pl1': channel.platform_id == 1,
                    'pl2': channel.platform_id == 2,
                    'pl3': channel.platform_id == 3,
                    'pl4': channel.platform_id == 4
                  }">
                    <app-outlet-line  [channel]="channel" [company_id]="data?.company.id"></app-outlet-line>
                  </div>
                </ng-container>
              </div>
            </div>

            <div class="col_tags">
              
              <div class="checklists">
                <ng-container *ngIf="!element?.isGetChecklists; else ChecksTmpl">
                  <p-skeleton width="7.5rem" styleClass="check"></p-skeleton>
                  <p-skeleton width="7.5rem" styleClass="check"></p-skeleton>
                  <p-skeleton width="7.5rem" styleClass="check"></p-skeleton>
                </ng-container>
                <ng-template #ChecksTmpl>
                  <ng-container *ngFor="let item of element?.checklists; let cInd = index;">
                    <div class="check" *ngIf="item.count" (click)="openPublisherMenu($event, profile, item, eInd); $event.preventDefault(); $event.stopPropagation();">
                      {{ item.type }}
                      <span>{{item.count}}</span>
                    </div>
                  </ng-container>
                </ng-template>
              </div>
              <!-- <button mat-raised-button color="primary" *ngIf="!element.showCheck" (click)="element.showCheck = true" style="margin: 10px 0;">{{"Show checklists" | translate}}</button>
              <ng-container *ngIf="element.showCheck">
                <div class="checklists_element" *ngFor="let checklist of element.checklists; let j = index">
                  <div class="job_type_check">{{ checklist.type }}</div>
                  <ng-container *ngIf="checklist.count; else elseTemplateCount">
                    <div class="d-f-c">
                      <div class="mr-1">{{ checklist.count }} {{ "Bullet points" | translate }}</div>
                      <div *ngIf="company?.permissions.includes('owner') || company?.permissions.includes('admin') || company?.permissions.includes('manager')" (click)="goToEditChecklists(element, checklist)" class="job_btn_check">{{ "Edit checklist" | translate }}</div>
                    </div>
                  </ng-container>
                  <ng-template #elseTemplateCount>
                    <div *ngIf="company?.permissions.includes('owner') || company?.permissions.includes('admin') || company?.permissions.includes('manager')" (click)="goToCreateChecklists($event, element, checklist)" class="job_btn_check">{{ "Add checklist" | translate }}</div>
                  </ng-template>
                </div>
              </ng-container>
              <button mat-raised-button *ngIf="!!element.showCheck" (click)="element.showCheck = false" style="margin: 10px 0;">{{"Hide checklists" | translate}}</button> -->
            </div>

            <div class="col_publisher">
                <!-- <ng-container *ngIf="!element?.isGetCanSee; else canSeeTmpl">
                  <p-skeleton height="30px" width="10rem" styleClass="can_see"></p-skeleton>
                </ng-container>
                <ng-template #canSeeTmpl>
                  <div class="can_see" *ngIf="element?.canSee?.length" >
                    <div class="users" [ngStyle]="{'width': element?.canSee?.length*22 + 10 + 'px'}">
                      <app-user *ngFor="let user of element?.canSee; let u = index;" [class]="'only_photo_big'" [employee]="user?.employee" [ngStyle]="{'z-index': element?.canSee?.length - u, 'transform': 'translateX(-' + u*10 + 'px)'}"></app-user>
                    </div>
                    <span>{{"Can See" | translate}}</span>
                  </div>
                </ng-template> -->
                <ng-container *ngIf="!element?.isGetGroups; else managersTmpl">
                  <p-skeleton height="42px" width="10rem" styleClass="can_see big"></p-skeleton>
                </ng-container>
                <ng-template #managersTmpl>
                  <div class="can_see big" *ngIf="element?.managers?.length">
                    <div class="users" [ngStyle]="{'width': element?.managers?.length*27 + 15 + 'px'}">
                      <app-user *ngFor="let user of element?.managers; let u = index;" [class]="'only_photo_super_big'" [employee]="user?.employee" [ngStyle]="{'z-index': element?.managers?.length - u, 'transform': 'translateX(-' + u*15 + 'px)'}"></app-user>
                    </div>
                    <span>{{"Managers" | translate}}</span>
                  </div>
                </ng-template>
            </div>

            <div class="col_action">
      
              <div class="ml-5 cp d-f-c show_on_hover" style="width: 24px; height: 24px; justify-content: center;" [matMenuTriggerFor]="outletMenu">
                <svg style="width: 20px; height: 20px;" width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M13.9992 16.6217C13.3039 16.6217 12.637 16.3455 12.1453 15.8538C11.6537 15.3621 11.3774 14.6953 11.3774 13.9999C11.3774 13.3046 11.6537 12.6378 12.1453 12.1461C12.637 11.6544 13.3039 11.3782 13.9992 11.3782C14.6945 11.3782 15.3614 11.6544 15.8531 12.1461C16.3447 12.6378 16.621 13.3046 16.621 13.9999C16.621 14.6953 16.3447 15.3621 15.8531 15.8538C15.3614 16.3455 14.6945 16.6217 13.9992 16.6217Z" fill="#272727"/>
                  <path d="M12.1453 5.0103C12.637 5.50197 13.3039 5.77819 13.9992 5.77819C14.6945 5.77819 15.3614 5.50197 15.8531 5.0103C16.3447 4.51863 16.621 3.85177 16.621 3.15644C16.621 2.46111 16.3447 1.79425 15.8531 1.30258C15.3614 0.810903 14.6945 0.534683 13.9992 0.534683C13.3039 0.534683 12.637 0.810903 12.1453 1.30258C11.6537 1.79425 11.3774 2.46111 11.3774 3.15644C11.3774 3.85177 11.6537 4.51863 12.1453 5.0103Z" fill="#272727"/>
                  <path d="M12.1453 26.6973C12.637 27.189 13.3039 27.4652 13.9992 27.4652C14.6945 27.4652 15.3614 27.189 15.8531 26.6973C16.3447 26.2056 16.621 25.5388 16.621 24.8435C16.621 24.1481 16.3447 23.4813 15.8531 22.9896C15.3614 22.4979 14.6945 22.2217 13.9992 22.2217C13.3039 22.2217 12.637 22.4979 12.1453 22.9896C11.6537 23.4813 11.3774 24.1481 11.3774 24.8435C11.3774 25.5388 11.6537 26.2056 12.1453 26.6973Z" fill="#272727"/>
                </svg>
              </div>

              <mat-menu #outletMenu="matMenu" xPosition="after">
                <a mat-menu-item href="/checklist?company_id={{company?.id}}&group_id={{element?.id}}" (click)="goToChecklists($event, element)">
                  <mat-icon style="color: blueviolet;">checklist</mat-icon>
                  <span>{{ "Checklists" | translate }}</span>
                </a>
                <button mat-menu-item (click)="openTargetValues(element)">
                  <mat-icon>
                    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="cp" style="width: 24px; height: 24px;"><path d="M3.5 5C3.89782 5 4.27936 4.84196 4.56066 4.56066C4.84196 4.27936 5 3.89782 5 3.5C5 3.10218 4.84196 2.72064 4.56066 2.43934C4.27936 2.15804 3.89782 2 3.5 2C3.10218 2 2.72064 2.15804 2.43934 2.43934C2.15804 2.72064 2 3.10218 2 3.5C2 3.89782 2.15804 4.27936 2.43934 4.56066C2.72064 4.84196 3.10218 5 3.5 5ZM15.41 7.58C15.77 7.94 16 8.44 16 9C16 9.55 15.78 10.05 15.41 10.41L10.41 15.41C10.05 15.77 9.55 16 9 16C8.45 16 7.95 15.78 7.58 15.41L0.59 8.42C0.22 8.05 0 7.55 0 7V2C0 0.89 0.89 0 2 0H7C7.55 0 8.05 0.22 8.41 0.58L15.41 7.58ZM11.54 1.71L12.54 0.71L19.41 7.58C19.78 7.94 20 8.45 20 9C20 9.55 19.78 10.05 19.42 10.41L14.04 15.79L13.04 14.79L18.75 9L11.54 1.71Z" fill="#686868"></path></svg>
                  </mat-icon> 
                  <span>{{ "Tag" | translate }}</span>
                </button>
                <button mat-menu-item (click)="channelsToGroup(element)">
                  <mat-icon style="color: #ff70a2;">public</mat-icon> 
                  <span>{{ "Add Outlet" | translate }}</span>
                </button>
                <button mat-menu-item (click)="editGroup($event, element)">
                  <mat-icon style="color: green;">edit</mat-icon> 
                  <span>{{ "Edit group" | translate }}</span>
                </button>
                <button mat-menu-item (click)="openChannelGroupToEmployee(element)">
                  <mat-icon style="color: #e58e26;">person_add</mat-icon> 
                  <span>{{ "Allow Group for executors" | translate }}</span>
                </button>
                <button mat-menu-item (click)="openGroupToEmployee(element)">
                  <mat-icon style="color: #e58e26;">group_add</mat-icon> 
                  <span>{{ "Add executors" | translate }}</span>
                </button>
                <button mat-menu-item (click)="groupManagers(element)">
                  <mat-icon style="color: #70a1ff;">manage_accounts</mat-icon> 
                  <span>{{ "Managers" | translate }}</span>
                </button>
                <button mat-menu-item (click)="deleteGroupCompany(element?.id)">
                  <mat-icon style="color: #BA202E;">delete</mat-icon> 
                  <span>{{ "Delete group" | translate }}</span>
                </button>
              </mat-menu>
            </div>

          </div>
        </div>

        <div class="btns_line">
            <a mat-raised-button href="/tasks?company_id={{company?.id}}&order_by=updated_desc" (click)="goToTasks($event)">
                {{ "Back" | translate }}
            </a>
            <a class="ml-1" (click)="addGroup($event)" mat-raised-button color="primary">
                <mat-icon>add</mat-icon>
                {{ "Create a group" | translate }}
            </a>
        </div>
    </div>
</div>



<ng-template #publisherMenu let-menuData>
  <section class="publisher_menu">
    <div class="top">
      {{menuData?.check?.type}}
    </div>
    <div class="content">
      <ul>
        <li *ngFor="let item of menuData?.check?.items">{{item.text}}</li>
      </ul>
    </div>
  </section>
</ng-template>