import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseClass } from 'src/app/shared/models/base-class';

@Component({
  selector: 'app-mob-type-filter',
  templateUrl: './mob-type-filter.component.html',
  styleUrls: ['./mob-type-filter.component.scss']
})

export class MobTypeFilterComponent extends BaseClass implements OnInit, OnDestroy {
  throttle = 300;
  scrollUpDistance = 3.5;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<MobTypeFilterComponent>,
  ) { super() }

  ngOnInit(): void {
    console.log("MobTypeFilterComponent", this.data)

    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        this.close();
      })
    )
  }

  getPlatformById(id) {
    if (!this.data.platforms || this.data.platforms.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.data.platforms.find(el => el.id == id)
  }

  close() {
    this.dialogRef.removePanelClass('animate__slideInUp');
    this.dialogRef.addPanelClass('animate__slideOutDown');
    setTimeout(()=>{this.dialogRef.close({event: 'close'})}, 300);
  }

  onSubmit() {
    this.dialogRef.removePanelClass('animate__slideInUp');
    this.dialogRef.addPanelClass('animate__slideOutDown');
    setTimeout(()=>{this.dialogRef.close({event: 'apply'})}, 300);
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

}
