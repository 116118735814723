<h2 mat-dialog-title class="dialog_title">
    <span>
        {{ "Conditions of emplyment / subcontracting" | translate }}
    </span>
    <button mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h2>
<mat-dialog-content class="mat-typography" dirEnter (dirSubmit)="!data.form.valid || isSubmit ? null : submitForm()">
    <div class="container">
        <form [formGroup]="data.form">
            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ "Currency of payouts" | translate }}</mat-label>
                <mat-select formControlName="currency_id" required>
                <mat-option *ngFor="let currency of data.currencyList" [value]="currency.id">
                    {{ currency.name }}
                </mat-option>
                </mat-select>
            </mat-form-field>
    
            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ 'Monthly points rate' | translate }}</mat-label>
                <input formControlName="rate_target" matInput type="number" placeholder="{{ 'Rate' | translate }}">
                <span matSuffix>PTS</span>
            </mat-form-field>
    
            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ 'Monthly rate' | translate }}</mat-label>
                <input formControlName="salary" matInput type="number" placeholder="{{ 'Salary' | translate }}">
                <span matSuffix>{{ getCurrency(data.form.get('currency_id').value) ? getCurrency(data.form.get('currency_id').value).iso_name : 'USD' }}</span>
            </mat-form-field>
        </form>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <div class="btns_layer">
        <button mat-raised-button (click)="close()">
            {{ "Cancel" | translate }}
        </button>
        <button class="d-f-btn" (click)="submitForm()" mat-raised-button cdkFocusInitial color="primary" [disabled]="!data.form.valid || isSubmit">
            <mat-spinner class="spin_16 mr-1" *ngIf="isSubmit"></mat-spinner>  
            {{ "Create" | translate }}
        </button>
    </div>
</mat-dialog-actions>
