<!-- <h2 mat-dialog-title class="dialog_title">
    <span>
        {{ "Select status and employee" | translate }}
    </span>
    <button mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h2> -->
<mat-dialog-content class="mat-typography">
    <div class="container form_wrapper">
        <div class="title_modal">
            {{ "Select status and employee" | translate }}
        </div>
        
        <form [formGroup]="form" class="form_with_pads form_with_valids">
            <p>{{ "You marked the file as 'Reject', if you want to change the status for the member(s), select the desired status and member(s)" | translate }}</p>

            <div class="users_select" [ngClass]="{'req_value': !form.get('employee_ids').valid && submited}">
                <label>{{ "Choose an employee" | translate }}: </label>
                <mat-chip-list [multiple]="true" selectable formControlName="employee_ids">
                    <ng-container *ngFor="let employee of employees; let i = index">
                        <mat-chip class="chip_user" (click)="selectEmpl(employee)" [value]="employee.id">
                            <app-user [class]="'user_div'" [employee]="employee.employee"></app-user>
                        </mat-chip>
                    </ng-container>
                </mat-chip-list>
            </div>

            <div class="small_chips" *ngIf="statuses?.length > 0">
                <ng-container *ngIf="!form.get('status_id').valid && submited">
                    <div class="req_field"></div>
                    <div class="req_label">
                        <div class="angle"></div>
                        <span>
                            {{ "You need to choose a value" | translate }}
                        </span>
                    </div>
                </ng-container>
                <label>{{ "Status" | translate }}: </label>
                <mat-chip-list [multiple]="false" selectable formControlName="status_id">
                    <ng-container *ngFor="let status of statuses">
                        <mat-chip class="small_chip" (click)="selectStatus(status)" [value]="status?.id" [ngClass]="{
                            'first': status.id == '1',
                            'second': status.id == '2',
                            'third': status.id == '3',
                            'fours': status.id == '4',
                            'fives': status.id == '5',
                            'sixs': status.id == '6',
                            'nine': status.id == '97',
                            'seven': status.id == '98',
                            'eight': status.id == '99'}">
                            {{ status.name | translate }}
                        </mat-chip>
                    </ng-container>
                </mat-chip-list>
            </div>
      </form>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <div class="f_w_p_btns" style="padding: 15px 40px;">
        <button mat-raised-button (click)="close()">
            {{ "Close" | translate }}
        </button>
        <button class="ml-1" mat-raised-button color='primary' (click)="select()">
            {{ "Select" | translate }}
        </button>
    </div>
</mat-dialog-actions>