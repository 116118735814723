import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import * as moment from 'moment';
import { ReplaySubject, forkJoin } from 'rxjs';
import { debounceTime, last, map, switchMap, tap } from 'rxjs/operators';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { BaseClass } from 'src/app/shared/models/base-class';
import { GlobalDataService } from 'src/app/shared/services/common/global-data.service';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { MembersService } from 'src/app/shared/services/rest/members.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { environment } from 'src/environments/environment';
import { MY_FORMATS } from '../../atTasksDialog/task-profile-add/task-profile-add.component';

@Component({
  selector: 'app-add-additional-employees',
  templateUrl: './add-additional-employees.component.html',
  styleUrls: ['./add-additional-employees.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ]
})
export class AddAdditionalEmployeesComponent extends BaseClass implements OnInit, OnDestroy {
  public form: FormGroup;
  public operationStatuses: any;
  public employees: any;
  public numberRegEx = /\d*\.?\d{1,2}/;
  public isSubmit: boolean = false;
  public showEm: boolean = false;
  public submited: boolean = false;
  public currencyList: any;
  public partners: any;
  public imgRoute: any = '';
  public host: any = environment.host;
  public employeeMoreControl: FormControl = new FormControl();
  public employees$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public partnerMoreControl: FormControl = new FormControl();
  public partners$: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddAdditionalEmployeesComponent>,
    private taskService: TaskService,
    private fb: FormBuilder,
    private _adapter: DateAdapter<any>,
    private companyService: CompanyService,
    private sm: StorageManagerService,
    private globalDataService: GlobalDataService,
    private layoutService: LayoutService,
    private languageService: LanguageService,
    private membersService: MembersService
  ) { super() }

  ngOnInit(): void {
    console.log(this.data);
    this.form = this.fb.group({
      company_id: this.data.company.id,
      task_id: this.data.task.id,
      task_operation_id: this.data.work.id,
      rate: [0, [Validators.pattern(this.numberRegEx)]],
      price: [0, [Validators.pattern(this.numberRegEx)]],
      is_manager: 0,
      price_currency_id: 0,
      employee_id: '',
      partner_company_id: '',
      status_id: ['', Validators.required],
      employee_comment: '',
      completed_at: ''
    })

    if (this.data.task.company_id == this.data.company.id && (this.data.company?.permissions.includes('owner') || this.checkIsManager(this.data.task, this.data.company, this.data.user))) {
      this.form.addControl("partner_company_id", this.fb.control(''))
      this.form.get('partner_company_id').updateValueAndValidity();
      this.getPartners();
    }

    this.attachSubscriptions(
      this.employeeMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchEmoloyees(resp))
    )

    this.attachSubscriptions(
      this.partnerMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchPartners(resp))
    )

    if (this.data.discussion) {
      this.form.addControl("discussion_id", this.fb.control(this.data.discussion.id))
      this.form.get('discussion_id').updateValueAndValidity();
    }

    this.getImgRoute();
    this.getEmployees();
    this.getCurrencyList();
    this.getOperationsStatus();
    this.getLangData();
  }

  checkIsManager(task, company, _user) { 
    return task?.managers.filter(x => x.user_id == _user.id).length > 0 || task?.group?.managers.filter(x => x.user_id == _user.id).length > 0 || company?.permissions?.includes('admin') || company?.permissions.includes('manager'); 
  }

  getPartners() {
    this.attachSubscriptions(
      this.companyService.getPartners({company_id: this.data.company.id}).subscribe(resp => {
        console.log("getPartners", resp);
        this.partners = resp;
        this.partners$.next(this.partners.slice());
      })
    )
  }

  togglePartnerChip = (chip: any) => {
    if (chip.hasOwnProperty("partner_company_id")) {
      if (this.form.value.partner_company_id === chip?.partner_company_id) {
        this.form.patchValue({
          partner_company_id: ''
        })
      } else {
        this.form.patchValue({
          partner_company_id: chip?.partner_company_id,
          employee_id: ''
        })
      }
    } else {
      this.form.patchValue({
        partner_company_id: chip.value,
        employee_id: ''
      })
    }
  }

  onSearchPartners(resp) {
    if (!this.partners) {
      return;
    }

    if (!resp) {
      this.partners$.next(this.partners.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.partners$.next(
      this.partners.filter(z => z.partnerCompany.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  onSearchEmoloyees(resp) {
    if (!this.employees) {
      return;
    }

    if (!resp) {
      this.employees$.next(this.employees.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.employees$.next(
      this.employees.filter(z => z.name.toLowerCase().indexOf(resp) > -1)
    );
  }


  toggleChip = (chip: any) => {
    if (this.data.company?.permissions.includes('owner') || this.checkIsManager(this.data.task, this.data.company, this.data.user)) {
      this.form.patchValue({
        employee_id: !!chip.id ? chip.id : chip.value,
        partner_company_id: ''
      })
    } else {
      this.form.patchValue({
        employee_id: !!chip.id ? chip.id : chip.value
      })
    }
  }

  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData().subscribe(resp => {
        this._adapter.setLocale(resp.active);
      })
    )
  }

  showMoreEm() {
    this.showEm = true;
  }

  getEmployees() {
    this.attachSubscriptions(
      this.taskService.getEmployeesDyn('1', this.data.company.id, null, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }
  
          return forkJoin(arr.map(x => this.taskService.getEmployeesDyn(x, this.data.company.id, null, '200').pipe(map(u => u.body)))).pipe(
            last(),
            tap(values => {
              let conValues = [].concat(...values)
              this.employees = conValues;
              this.employees$.next(this.employees.slice())
            }),
          )
        }),
      ).subscribe(resp => {
        console.log("getEmployees resp", resp);
        console.log("getEmployees", this.employees);
      })
    )
  }
  
  getOperationsStatus() {
    this.attachSubscriptions(
      this.taskService.getOperationsStatus().subscribe(resp => {
        this.operationStatuses = resp.slice();
      })
    )
  }  

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  onPartnerImgError(event){
    event.target.src = this.imgRoute+'/assets/img/partner.png'
  }

  getCurrencyList() {
    this.attachSubscriptions(
      this.globalDataService.getCurrencies().subscribe(resp => {
        console.log("getCurrencyList", resp)
        this.currencyList = resp.slice();
      })
    )
    // this.attachSubscriptions(
    //   this.companyService.getCurrencyList().subscribe(resp => {
    //     this.currencyList = resp
    //   })
    // )
  }

  getEmployeeById(id) {
    return this.employees.find(x => x.id == id)
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  selectStatus(status) {
    this.form.patchValue({
      status_id: status.id
    })
  }

  onSubmit() {
    if (!this.form.valid) {
      this.submited = true;
      if (this.data.company?.permissions.includes('owner') || this.checkIsManager(this.data.task, this.data.company, this.data.user)) {
        if (!this.form.value.partner_company_id && !this.form.value.employee_id) {
          this.layoutService.showSnackBar({name: ''}, marker("You need to choose executor or partner"), SnackBarItem)
        } else {
          this.layoutService.showSnackBar({name: ''}, marker("You need to fill in all required fields"), SnackBarItem)
        }
      } else {
        if (!this.form.value.employee_id) {
          this.layoutService.showSnackBar({name: ''}, marker("You need to choose executor"), SnackBarItem)
        } else {
          this.layoutService.showSnackBar({name: ''}, marker("You need to fill in all required fields"), SnackBarItem)
        }
      }
      return
    }

    this.submited = false;
    this.isSubmit = true;
    if (String(this.form.value['rate']).indexOf(",") != -1) {
      this.form.value['rate'] = this.form.value['rate'].replace(",", ".");
    }
    if (String(this.form.value['price']).indexOf(",") != -1) {
      this.form.value['price'] = this.form.value['price'].replace(",", ".");
    }
    if (!!this.form.value.completed_at && !!this.form.value.completed_at._d) {
      this.form.value.completed_at = moment(this.form.value.completed_at._d).format("X");
    } else {
      delete this.form.value.completed_at;
    }

    if (this.data.task.company_id == this.data.company.id) {
      if (!!this.form.value.partner_company_id) {
  
        let partnerData = {...this.form.value}
        delete partnerData.status_id;
        delete partnerData.employee_id;
  
        this.membersService.addTaskPartner(partnerData, this.data.company.id).subscribe(resp => {
          this.isSubmit = false;
          this.dialogRef.close({event: "add", data: resp})
        })
      } else {
        let emplData = {...this.form.value}
        if (emplData.partner_company_id) {
          delete emplData.partner_company_id;
        }
        this.attachSubscriptions(
          this.membersService.addMember(emplData, this.data.company.id).subscribe(resp => {
            this.isSubmit = false;
            this.dialogRef.close({event: "add", data: resp})
          })
        )
      }
    } else {
      let emplData = {...this.form.value}
      emplData.partner_company_id = emplData.company_id;
      emplData.company_id = this.data.task.company_id;
      emplData.partner_employee_id = emplData.employee_id;

      delete emplData.employee_id;

      this.attachSubscriptions(
        this.membersService.addTaskPartnerEmployee(emplData, this.data.company.id).subscribe(resp => {
          console.log("resp addTaskPartnerEmployee", resp)
          this.isSubmit = false;
          this.dialogRef.close({event: "add", data: resp})
        })
      )
    }
  }

  ngOnDestroy(): void {
    this.clearSubscriptions();    
  }

}
