<section class="lite_fm" [ngClass]="{'use_out': useOut}">
    <ng-container *ngIf="!useOut; else elseTemplateOut">
        <div class="lite_fm_head" [ngClass]="{'can_back': crumbs.length > 1}">
            <svg width="23" height="18" viewBox="0 0 23 18" fill="none" xmlns="http://www.w3.org/2000/svg" (click)="log()">
                <path opacity="0.8" d="M9.07826 0H2.26957C1.00996 0 0 1.00125 0 2.25V15.75C0 16.3467 0.239114 16.919 0.66474 17.341C1.09037 17.7629 1.66764 18 2.26957 18H20.4261C21.028 18 21.6053 17.7629 22.0309 17.341C22.4565 16.919 22.6957 16.3467 22.6957 15.75V4.5C22.6957 3.25125 21.6743 2.25 20.4261 2.25H11.3478L9.07826 0Z" fill="#A3A3A3"/>
            </svg>
            <mat-icon style="color: #A3A3A3;" (click)="log(); goBack()">arrow_back_ios_new</mat-icon>
            <div class="lite_fm_head_bread">
                <div class="crumbs">
                    <span class="crumb" *ngFor="let item of crumbs; let i = index" (click)="goToCrumb(i)" appDnd (fileDropped)="onFileDropped($event, (i == crumbs.length - 1 ? false : item))">
                        {{item.name}}
                    </span>
                </div>
   
                <span class="job" (click)="target.is_clicked = false; openJob(target?.outputFolder?.targetIds?.task_id, target?.outputFolder?.targetIds?.task_operation_id, target.output_location)">
                    JOB ID {{target?.outputFolder?.targetIds?.task_operation_id}}
                </span>
            </div>                
        </div>
    </ng-container>
    <ng-template #elseTemplateOut>     
        <div class="lite_fm_head" [ngClass]="{'can_back': crumbs.length > 1}">
            <div class="lite_fm_head_in">
                <svg style="width: 15px; height: 12px; flex-shrink: 0;" width="23" height="18" viewBox="0 0 23 18" fill="none" xmlns="http://www.w3.org/2000/svg" (click)="log()">
                    <path opacity="0.8" d="M9.07826 0H2.26957C1.00996 0 0 1.00125 0 2.25V15.75C0 16.3467 0.239114 16.919 0.66474 17.341C1.09037 17.7629 1.66764 18 2.26957 18H20.4261C21.028 18 21.6053 17.7629 22.0309 17.341C22.4565 16.919 22.6957 16.3467 22.6957 15.75V4.5C22.6957 3.25125 21.6743 2.25 20.4261 2.25H11.3478L9.07826 0Z" fill="#A3A3A3"/>
                </svg>
                <mat-icon style="color: #A3A3A3; width: 15px; height: 15px; font-size: 15px; line-height: 15px; flex-shrink: 0;" (click)="log(); goBack()">arrow_back_ios_new</mat-icon>
                <div class="lite_fm_head_bread">
                    <div class="crumbs">
                        <span class="crumb" *ngFor="let item of crumbs; let i = index" (click)="goToCrumb(i)" appDnd (fileDropped)="onFileDropped($event, (i == crumbs.length - 1 ? false : item))">
                            {{item.name}}
                        </span>
                    </div>
                </div>                
            </div>
            <div class="d-f-c">
                <div class="btn_del" *ngIf="selectedFiles?.selected && selectedFiles?.selected.length > 1" (click)="delete(target, null)">
                    {{'Delete' | translate}} {{selectedFiles?.selected.length}} {{'files' | translate}}
                </div>
                <div class="ml-5 btn_del" [matMenuTriggerFor]="liteFmResultsMenu">
                    {{"All files" | translate}}
                </div>
            
                <mat-menu #liteFmResultsMenu="matMenu" xPosition="before" class="header_menu sort_tablet" overlayPanelClass='sortTAB'>
                    <!-- <div (click)="logActions()">test</div> -->
                    <div mat-menu-item (click)="allAction('copy')">
                        {{ 'Copy' | translate }}
                    </div>
                    <div mat-menu-item (click)="allAction('delete')">
                        {{ 'Delete' | translate }}
                    </div>
                    <div mat-menu-item [matMenuTriggerFor]="foldersMenu">
                        {{ 'Move to folder' | translate }}
                    </div>
                    <div mat-menu-item (click)="allAction('tags')">
                        {{ 'Add tags' | translate }}
                    </div>
                    <mat-divider></mat-divider>

                    <div class="lite_filter_el" (click)="$event.preventDefault(); $event.stopPropagation();">
                        <mat-button-toggle-group appearance="legacy" [(ngModel)]="is_dir" class="lite_filter_toggle_group" style="width: 100%;">
                            <mat-button-toggle style="width: 33.333%;" [value]="''">{{"All" | translate}}</mat-button-toggle>
                            <mat-button-toggle style="width: 33.333%;" [value]="0">{{"Only Files" | translate}}</mat-button-toggle>
                            <mat-button-toggle style="width: 33.333%;" [value]="1">{{"Only Folders" | translate}}</mat-button-toggle>
                        </mat-button-toggle-group>
                    </div>

                    <div class="lite_filter_el" (click)="$event.preventDefault(); $event.stopPropagation();">
                        <mat-button-toggle-group appearance="legacy" [(ngModel)]="location_type" class="lite_filter_toggle_group" style="width: 100%;">
                            <mat-button-toggle style="width: 50%;" [value]="'location'">{{"In Folder" | translate}}</mat-button-toggle>
                            <mat-button-toggle style="width: 50%;" [value]="'location_all'">{{"In All Subfolders" | translate}}</mat-button-toggle>
                        </mat-button-toggle-group>
                    </div>
                    <div class="lite_filter_el" (click)="$event.preventDefault(); $event.stopPropagation();">
                        <mat-button-toggle-group appearance="legacy" [(ngModel)]="with_sys_folders" class="lite_filter_toggle_group" style="width: 100%;">
                            <mat-button-toggle style="width: 50%;" [value]="false">{{"Without Sys Folders" | translate}}</mat-button-toggle>
                            <mat-button-toggle style="width: 50%;" [value]="true">{{"With Sys Folders" | translate}}</mat-button-toggle>
                        </mat-button-toggle-group>
                    </div>
                </mat-menu>

                <mat-menu #foldersMenu="matMenu">
                    <button mat-menu-item (click)="allAction('move', {filename: '', location: '/'})">{{"Root" | translate}}</button>
                    <ng-container *ngFor="let folder of folders">
                        <!-- *ngIf="(target?.active_location || target?.output_location) != folder?.location" -->
                        <button mat-menu-item (click)="allAction('move', folder)">{{folder.filename | translate}}</button>
                    </ng-container>
                </mat-menu>

                <button class="lite_fm_ic_btn mr-5" mat-icon-button [matMenuTriggerFor]="liteFmUploadMenu">
                    <mat-icon>upload</mat-icon>
                </button>
            
                <mat-menu #liteFmUploadMenu="matMenu" xPosition="before" class="header_menu">
                    <div mat-menu-item (click)="liteUploader.click()">
                        <mat-icon>upload_file</mat-icon>
                        {{ 'Upload file' | translate }}
                    </div>
                    <div mat-menu-item (click)="liteUploaderFolder.click()">
                        <mat-icon>drive_folder_upload</mat-icon>
                        {{ 'Upload folder' | translate }}
                    </div>
                </mat-menu>

                <span class="lite_count" (click)="log()" *ngIf="target?.outputFolder?.pagination?.totalCount">
                    {{target?.outputFolder?.pagination?.totalCount}} {{"files" | translate}}
                </span>
            </div>
        </div>
    </ng-template>
    <div class="lite_files_block">
        <dts-select-container
        (mousedown)="dtsMouseDown($event)"
        infinite-scroll
        [infiniteScrollDisabled]="target?.outputFolder?.is_load"
        [scrollWindow]="false"
        [infiniteScrollUpDistance]="scrollUpDistance"
        [infiniteScrollThrottle]="throttle"
        (scrolled)="onDownProjectFiles($event, target)"
        class="lite_fm_files" [appDeviceClass]="'custom-scroll custom-scroll-t'"
        [ngClass]="{'centred': target?.outputFolder?.is_load && target?.outputFolder?.files?.length == 0}"
        appDnd
        multiple-selection-zone
        #filesContainer
        (fileDropped)="onFileDropped($event)"
        (contextmenu)="openFileMenu($event, target, {reports_type: 'outFiles'}); $event.preventDefault(); $event.stopPropagation();"
        data-cy="select-container"
        #selectContainer="dts-select-container"
        [selectMode]="false"
        [disableRangeSelection]="true"
        [(selectedItems)]="selectedDocuments"
        [selectOnClick]="false"
        [selectOnDrag]="false"
        [selectWithShortcut]="false"
        [dragOverItems]="false"
        (itemSelected)="itemSelected($event)"
        (itemDeselected)="itemDeselected($event)">
            <ng-container *ngIf="target?.outputFolder?.is_load && target?.outputFolder?.files?.length == 0; else elseTemplateLoaded">
                <mat-spinner></mat-spinner>
            </ng-container>
            <ng-template #elseTemplateLoaded>
                <ng-container *ngFor="let file of target?.outputFolder?.files">
                    <div class="file cp"
                    [dtsSelectItem]="file"
                    [dtsDisabled]="file.is_dir == 1 || (file.filename != 'sources' && file.filename != 'approved' && file.filename != 'to_approve' && file.filename != 'ready_files' && file.filename != 'project' && file.filename != 'trash') ? true : false"
                    multiple-selection-item
                    appDnd
                    (fileDropped)="file.is_dir == 1 ? onFileDropped($event, false, file) : null;"
                    [ngClass]="{'selected': selectedFiles.isSelected(file), 'dir_file': !!file.is_dir}" 
                    (click)="fileClick($event, target, file)" 
                    (contextmenu)="openFileMenu($event, target, file); $event.preventDefault(); $event.stopPropagation();">
                        <div class="file_img" [ngClass]="{'no_files': !!file.is_dir && !file.files_count}">
                            <ng-container *ngIf="file.is_dir == 1; else elseTemplateDir">
                                <mat-icon class="file_img_icon" style="color: #3f51b5">
                                    folder
                                </mat-icon>
                            </ng-container>
                            <ng-template #elseTemplateDir>
                                <ng-container [ngSwitch]="understandFileType(file.content_type)">
                                    <ng-container *ngSwitchCase="'video'">
                                        <ng-container *ngIf="file.thumbnail; else elseTemplateThumbnail">
                                            <img style="max-width: 100%; max-height: 100%;" (error)="onImgError($event)" [src]="host + file.thumbnail + '?company_id=' + data?.company?.id">
                                        </ng-container>
                                        <ng-template #elseTemplateThumbnail>
                                            <mat-icon class="file_img_icon" style="color: #3f51b5">
                                                movie
                                            </mat-icon>
                                        </ng-template>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'audio'">
                                        <ng-container *ngIf="file.thumbnail; else elseTemplateThumbnailAudio">
                                            <img style="max-width: 100%; max-height: 100%;" (error)="onImgError($event)" [src]="host + file.thumbnail + '?company_id=' + data?.company?.id">
                                        </ng-container>
                                        <ng-template #elseTemplateThumbnailAudio>
                                            <mat-icon class="file_img_icon" style="color: #3f51b5">
                                                music_note
                                            </mat-icon>
                                        </ng-template>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'image'">
                                        <img (error)="onImgError($event)" style="max-width: 100%; max-height: 100%;" [src]="host + (file.thumbnail ? file.thumbnail : file.original) + '?company_id=' + data?.company?.id">
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'pdf'">
                                        <div class="pdf">
                                            <svg height="800px" width="800px" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
                                                viewBox="0 0 303.188 303.188" xml:space="preserve">
                                                <g>
                                                    <polygon style="fill:#E8E8E8;" points="219.821,0 32.842,0 32.842,303.188 270.346,303.188 270.346,50.525 	"/>
                                                    <path style="fill:#FB3449;" d="M230.013,149.935c-3.643-6.493-16.231-8.533-22.006-9.451c-4.552-0.724-9.199-0.94-13.803-0.936
                                                        c-3.615-0.024-7.177,0.154-10.693,0.354c-1.296,0.087-2.579,0.199-3.861,0.31c-1.314-1.36-2.584-2.765-3.813-4.202
                                                        c-7.82-9.257-14.134-19.755-19.279-30.664c1.366-5.271,2.459-10.772,3.119-16.485c1.205-10.427,1.619-22.31-2.288-32.251
                                                        c-1.349-3.431-4.946-7.608-9.096-5.528c-4.771,2.392-6.113,9.169-6.502,13.973c-0.313,3.883-0.094,7.776,0.558,11.594
                                                        c0.664,3.844,1.733,7.494,2.897,11.139c1.086,3.342,2.283,6.658,3.588,9.943c-0.828,2.586-1.707,5.127-2.63,7.603
                                                        c-2.152,5.643-4.479,11.004-6.717,16.161c-1.18,2.557-2.335,5.06-3.465,7.507c-3.576,7.855-7.458,15.566-11.815,23.02
                                                        c-10.163,3.585-19.283,7.741-26.857,12.625c-4.063,2.625-7.652,5.476-10.641,8.603c-2.822,2.952-5.69,6.783-5.941,11.024
                                                        c-0.141,2.394,0.807,4.717,2.768,6.137c2.697,2.015,6.271,1.881,9.4,1.225c10.25-2.15,18.121-10.961,24.824-18.387
                                                        c4.617-5.115,9.872-11.61,15.369-19.465c0.012-0.018,0.024-0.036,0.037-0.054c9.428-2.923,19.689-5.391,30.579-7.205
                                                        c4.975-0.825,10.082-1.5,15.291-1.974c3.663,3.431,7.621,6.555,11.939,9.164c3.363,2.069,6.94,3.816,10.684,5.119
                                                        c3.786,1.237,7.595,2.247,11.528,2.886c1.986,0.284,4.017,0.413,6.092,0.335c4.631-0.175,11.278-1.951,11.714-7.57
                                                        C231.127,152.765,230.756,151.257,230.013,149.935z M119.144,160.245c-2.169,3.36-4.261,6.382-6.232,9.041
                                                        c-4.827,6.568-10.34,14.369-18.322,17.286c-1.516,0.554-3.512,1.126-5.616,1.002c-1.874-0.11-3.722-0.937-3.637-3.065
                                                        c0.042-1.114,0.587-2.535,1.423-3.931c0.915-1.531,2.048-2.935,3.275-4.226c2.629-2.762,5.953-5.439,9.777-7.918
                                                        c5.865-3.805,12.867-7.23,20.672-10.286C120.035,158.858,119.587,159.564,119.144,160.245z M146.366,75.985
                                                        c-0.602-3.514-0.693-7.077-0.323-10.503c0.184-1.713,0.533-3.385,1.038-4.952c0.428-1.33,1.352-4.576,2.826-4.993
                                                        c2.43-0.688,3.177,4.529,3.452,6.005c1.566,8.396,0.186,17.733-1.693,25.969c-0.299,1.31-0.632,2.599-0.973,3.883
                                                        c-0.582-1.601-1.137-3.207-1.648-4.821C147.945,83.048,146.939,79.482,146.366,75.985z M163.049,142.265
                                                        c-9.13,1.48-17.815,3.419-25.979,5.708c0.983-0.275,5.475-8.788,6.477-10.555c4.721-8.315,8.583-17.042,11.358-26.197
                                                        c4.9,9.691,10.847,18.962,18.153,27.214c0.673,0.749,1.357,1.489,2.053,2.22C171.017,141.096,166.988,141.633,163.049,142.265z
                                                        M224.793,153.959c-0.334,1.805-4.189,2.837-5.988,3.121c-5.316,0.836-10.94,0.167-16.028-1.542
                                                        c-3.491-1.172-6.858-2.768-10.057-4.688c-3.18-1.921-6.155-4.181-8.936-6.673c3.429-0.206,6.9-0.341,10.388-0.275
                                                        c3.488,0.035,7.003,0.211,10.475,0.664c6.511,0.726,13.807,2.961,18.932,7.186C224.588,152.585,224.91,153.321,224.793,153.959z"/>
                                                    <polygon style="fill:#FB3449;" points="227.64,25.263 32.842,25.263 32.842,0 219.821,0 	"/>
                                                    <g>
                                                        <path style="fill:#A4A9AD;" d="M126.841,241.152c0,5.361-1.58,9.501-4.742,12.421c-3.162,2.921-7.652,4.381-13.472,4.381h-3.643
                                                            v15.917H92.022v-47.979h16.606c6.06,0,10.611,1.324,13.652,3.971C125.321,232.51,126.841,236.273,126.841,241.152z
                                                            M104.985,247.387h2.363c1.947,0,3.495-0.546,4.644-1.641c1.149-1.094,1.723-2.604,1.723-4.529c0-3.238-1.794-4.857-5.382-4.857
                                                            h-3.348C104.985,236.36,104.985,247.387,104.985,247.387z"/>
                                                        <path style="fill:#A4A9AD;" d="M175.215,248.864c0,8.007-2.205,14.177-6.613,18.509s-10.606,6.498-18.591,6.498h-15.523v-47.979
                                                            h16.606c7.701,0,13.646,1.969,17.836,5.907C173.119,235.737,175.215,241.426,175.215,248.864z M161.76,249.324
                                                            c0-4.398-0.87-7.657-2.609-9.78c-1.739-2.122-4.381-3.183-7.926-3.183h-3.773v26.877h2.888c3.939,0,6.826-1.143,8.664-3.43
                                                            C160.841,257.523,161.76,254.028,161.76,249.324z"/>
                                                        <path style="fill:#A4A9AD;" d="M196.579,273.871h-12.766v-47.979h28.355v10.403h-15.589v9.156h14.374v10.403h-14.374
                                                            L196.579,273.871L196.579,273.871z"/>
                                                    </g>
                                                    <polygon style="fill:#D1D3D3;" points="219.821,50.525 270.346,50.525 219.821,0"/>
                                                </g>
                                            </svg>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngSwitchDefault>
                                        <ng-container *ngIf="!!file?.thumbnail; else elseTemplateHasThumb">
                                            <img (error)="onImgError($event)" style="max-width: 100%; max-height: 100%;" [src]="host + file.thumbnail + '?company_id=' + data?.company?.id">
                                        </ng-container>
                                        <ng-template #elseTemplateHasThumb>
                                            <mat-icon class="file_img_icon" style="color: #3f51b5">
                                                insert_drive_file
                                            </mat-icon>
                                        </ng-template>
                                    </ng-container>
                                </ng-container>
                            </ng-template>
                        </div>
                        <div class="file_info">
                            <div class="d-f-c">
                                <span class="text_one_line file_name">{{file.filename}}</span>
                                <span class="file_size" *ngIf="file?.filesize">{{ file?.filesize | filesize }}</span>
                            </div>
                            <div class="file_reminder_name text_one_line" style="color: #3f51b5;" *ngIf="file.primaryTags && file.primaryTags.length">
                                <ng-container *ngFor="let prim of file.primaryTags; let last = last">
                                  <span>{{prim?.parameterValue?.value}}</span>
                                  <ng-container *ngIf="!last">, </ng-container>
                                </ng-container>
                            </div>
                            <div class="file_reminder_name text_one_line" style="color: #3f51b5; left: unset; right: 0;" *ngIf="['image', 'video'].includes(understandFileType(file?.content_type)) || ['image', 'video'].includes(_mimeTypes.getType(file.name))">
                                {{file?.meta_width + " &#10005; " + file?.meta_height}}
                            </div>
                        </div>
                    </div>
                </ng-container>
            </ng-template>
        </dts-select-container>
        <div class="lite_sys_out">
            <div class="lite_sys_folder dir_file" *ngFor="let file of folders" (click)="fileClick($event, target, file, true)" appDnd (fileDropped)="onFileDropped($event, false, file)">
                <div class="file_img" [ngClass]="{'no_files': !file.files_count}">
                    <ng-container [ngSwitch]="file.filename">
                        <ng-container *ngSwitchCase="'sources'">
                            <mat-icon class="file_img_icon sources_folder">
                                source
                            </mat-icon>
                        </ng-container>
                        <ng-container *ngSwitchCase="'to_approve'">
                            <mat-icon class="file_img_icon to_approve_folder">
                                rule_folder
                            </mat-icon>
                        </ng-container>
                        <ng-container *ngSwitchCase="'ready_files'">
                            <mat-icon class="file_img_icon to_ready_folder">
                                cloud_done
                            </mat-icon>
                        </ng-container>
                        <ng-container *ngSwitchCase="'project'">
                            <mat-icon class="file_img_icon project_folder">
                                stars
                            </mat-icon>
                        </ng-container>
                        <ng-container *ngSwitchCase="'trash'">
                            <mat-icon class="file_img_icon trash_folder">
                                delete
                            </mat-icon>
                        </ng-container>
                        <ng-container *ngSwitchCase="'approved'">
                            <mat-icon class="file_img_icon approved_folder">
                                folder_special
                            </mat-icon>
                        </ng-container>
                        <ng-container *ngSwitchDefault>
                            <mat-icon class="file_img_icon default_folder">
                                folder
                            </mat-icon>                                     
                        </ng-container>
                    </ng-container>
                </div>
                <div class="file_info">
                    <span class="file_name">{{file.filename | translate}}</span>
                </div>
            </div>
        </div>
    </div>
</section>

<ng-template #fileMenu let-menuData>
	<section class="proj-file-menu">
        <!-- <div>
            {{menuData?.file?.id}} - {{menuData?.target?.id}}
        </div> -->
        <!-- <div *ngIf="understandFileType(file.content_type) == 'video'" (click)="previewVideo(file)">
            <mat-icon class="mr-1">preview</mat-icon>
            {{ "View" | translate }}
        </div> -->
        <!-- <div *ngIf="understandFileType(file.content_type) == 'image'" (click)="previewImage($event, file)">
            <mat-icon class="mr-1">preview</mat-icon>
            {{ "View" | translate }}
            <img style="max-width: 100%; max-height: 100%; display: none;" [src]="host + file.original">
        </div> -->
		<!-- <div *ngIf="file.filename != 'sources' && file.filename != 'approved' && file.filename != 'to_approve' && file.filename != 'ready_files' && file.filename != 'project' && file.filename != 'trash'" (click)="copy(file)">{{ 'Copy' | translate }}</div> -->
        <!-- <div *ngIf="file.is_dir == 0 && !boxFiles.isSelected(file)" (click)="addToBox(file)">
            <mat-icon class="mr-1">add_shopping_cart</mat-icon>
            {{ 'Add to cart' | translate }}
        </div>
        <div *ngIf="file.is_dir == 0 && boxFiles.isSelected(file)" (click)="removeFromBox(file)">
            <mat-icon class="mr-1">remove_shopping_cart</mat-icon>
            {{ 'Remove from cart' | translate }}
        </div>
        <div *ngIf="file.filename != 'sources' && file.filename != 'approved' && file.filename != 'to_approve' && file.filename != 'ready_files' && file.filename != 'project' && file.filename != 'trash'" (click)="createFolder(file)">
            <mat-icon class="mr-1">create_new_folder</mat-icon>
            <ng-container *ngIf="selectedFiles && selectedFiles.selected && selectedFiles.selected.length; else elseTemplateHasSelectedFiles">
                {{ 'New Folder with Selection' | translate }} <span>({{selectedFiles.selected.length}} {{"items" | translate }})</span>
            </ng-container>
            <ng-template #elseTemplateHasSelectedFiles>
                {{ 'New Folder with File' | translate }}
            </ng-template>
        </div>
        <div class="has_sub_menu" *ngIf="(company | perm:'see_task_channels') && task && task?.uniqueChannels && file.is_dir == 0">
            <mat-icon class="mr-1">publish</mat-icon>
            {{ 'Publish to' | translate }}

            <div class="sub_menu_out" [ngClass]="{'open_left': file?.open_to_left}">
                <div class="sub_menu">
                    <ng-container *ngFor="let outlet of task.uniqueChannels">
                        <div class="sub_item" *ngIf="outlet && outlet.channel && !!outlet.channel.channel_account_id" (click)="outletClick(outlet, file)">
                            <ng-container *ngIf="outlet.channel.avatarFile; else elseTemplateNoPhoto">
                                <div class="channel_avatar">
                                    <img [src]="host + outlet.channel.avatarFile.original + '?company_id=' + company_id" alt="">
                                    <div class="channel_avatar_platform">
                                        <app-platform [platform_id]="outlet.channel?.platform_id"></app-platform>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-template #elseTemplateNoPhoto>      
                                <div class="platform_icon">
                                    <app-platform [platform_id]="outlet.channel?.platform_id"></app-platform>
                                </div>
                            </ng-template>
                            <span class="text_one_line" style="position: relative;">
                                {{outlet.channel.name}}
                                <div class="chan_tags" *ngIf="getProfile(outlet.channel_id) && getProfile(outlet.channel_id)?.parameterValuesToChannel">
                                    <ng-container *ngFor="let tag of getProfile(outlet.channel_id)?.parameterValuesToChannel; let last = last">
                                        <span>{{tag?.parameterValue?.value}}</span>
                                        <ng-container *ngIf="!last">, </ng-container>
                                    </ng-container>
                                </div>                         
                            </span>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>


		<div *ngIf="!is_global && file.is_dir == 0 && understandFileType(file.content_type) == 'video'" (click)="videoEditor(file)">
            <mat-icon class="mr-1">movie</mat-icon>
            {{ 'Open in video edition project' | translate }}
        </div>
		<div *ngIf="!is_global && file.is_dir == 0 && understandFileType(file.content_type) == 'video' && !!file.meta_height && !!file.meta_width" (click)="videoEditorV2(file)">
            <mat-icon class="mr-1">movie</mat-icon>
            {{ 'Open in video edition project (V2)' | translate }}
        </div>
        <div *ngIf="!is_global && file.is_dir == 0 && understandFileType(file.content_type) == 'video' && !!file.meta_height && !!file.meta_width" (click)="videoEditorV4(file)">
            <mat-icon class="mr-1">movie</mat-icon>
            {{ 'Open in video edition project (V2.1)' | translate }}
        </div>
		<div *ngIf="!is_global && file.is_dir == 0 && understandFileType(file.content_type) == 'video' && !!file.meta_height && !!file.meta_width" (click)="videoEditorV3(file)">
            <mat-icon class="mr-1">movie</mat-icon>
            {{ 'Open in video edition project (V3)' | translate }}
        </div>
		<div *ngIf="!is_global && file.is_dir == 0 && understandFileType(file.content_type) == 'video' && !!file.meta_height && !!file.meta_width" (click)="videoEditorV5(file)">
            <mat-icon class="mr-1">movie</mat-icon>
            {{ 'Open in video edition project (V4)' | translate }}
        </div>
        <div *ngIf="!is_global && file.is_dir == 0 && understandFileType(file.content_type) == 'video' && !!file.meta_height && !!file.meta_width" (click)="V5VideoEditor(file)">
            <mat-icon class="mr-1">movie</mat-icon>
            {{ 'Open in video edition project (V5)' | translate }}
        </div>
        <div *ngIf="!is_global && file.is_dir == 0 && understandFileType(file.content_type) == 'video' && !!file.meta_height && !!file.meta_width" (click)="videoEditorV6(file)">
            <mat-icon class="mr-1">movie</mat-icon>
            {{ 'Open in video edition project (V5.1)' | translate }}
        </div>
		<div *ngIf="file.filename != 'sources' && file.filename != 'approved' && file.filename != 'to_approve' && file.filename != 'ready_files' && file.filename != 'project' && file.filename != 'trash'" (click)="edit(file)">
            <mat-icon class="mr-1">edit</mat-icon>
            {{ 'Rename' | translate }}
        </div>
        <div *ngIf="file.filename != 'sources' && file.filename != 'approved' && file.filename != 'to_approve' && file.filename != 'ready_files' && file.filename != 'reject' && file.filename != 'project' && file.filename != 'trash'" (click)="openNotes(file)">
            <mat-icon class="mr-1">text_snippet</mat-icon>
            {{ 'Notes' | translate }}
        </div>
        <div *ngIf="file.is_dir == 0 && canTranscribe(file.filename)" (click)="transcribeFile(file)">
            <mat-icon class="mr-1">record_voice_over</mat-icon>
            {{ 'Speech to Text' | translate }}
        </div>
		<div *ngIf="file.filename != 'sources' && file.filename != 'approved' && file.filename != 'to_approve' && file.filename != 'ready_files' && file.filename != 'project' && file.filename != 'trash'" (click)="copy(file)">
            <mat-icon class="mr-1">file_copy</mat-icon>
            {{ 'Copy' | translate }}
        </div>
		<div *ngIf="!!copiedFiles && file.is_dir == 1" (click)="paste(file)">
            <mat-icon class="mr-1">content_paste</mat-icon>
            {{ 'Paste' | translate }}
        </div>
		<div *ngIf="(company?.permissions.includes('owner') || checkIsManager(task, company, user))" (click)="privacy(file)">
            <mat-icon class="mr-1">lock_open</mat-icon>
            {{ 'Privacy' | translate }}
        </div>

        <ng-container *ngIf="!fileRoute.get('task_operation_id').value; else elseTemplateIsTaskDirLink">
            <div *ngIf="file.is_dir == 1" ngxClipboard [cbContent]="origin + '/file/' + file?.acm + '?path=' + getPathForLink(file.location + '/' + file.filename)" (cbOnSuccess)="copyLink('folder'); close()">
                <mat-icon class="mr-1">link</mat-icon> 
                {{ 'Link to folder' | translate }}
            </div>
        </ng-container>
        <ng-template #elseTemplateIsTaskDirLink>
            <div *ngIf="file.is_dir == 1" ngxClipboard [cbContent]="origin + '/file/' + file?.acm + '?path=' + getPathForLink(file.location + '/' + file.filename)" (cbOnSuccess)="copyLink('folder'); close()">
                <mat-icon class="mr-1">link</mat-icon> 
                {{ 'Link to folder' | translate }}
            </div>    
        </ng-template>
        
		<div *ngIf="file.is_dir == 0" ngxClipboard [cbContent]="origin + '/file/' + file?.acm" (cbOnSuccess)="copyLink('file'); close()">
            <mat-icon class="mr-1">link</mat-icon> 
            {{ 'Copy url to file' | translate }}
        </div>
        <div (click)="downloadJD(file)">
            <mat-icon class="mr-1">download</mat-icon>
            {{ 'Download with JD 2' | translate }}
        </div>
        <ng-container *ngIf="file.is_dir == 0; else elseTemplateDownload">
            <div (click)="download(file)">
                <mat-icon class="mr-1">download</mat-icon>
                {{ 'Download' | translate }}
            </div>
        </ng-container>
        <ng-template #elseTemplateDownload>
            <div *ngIf="!getLastCreatedArchive(file)" (click)="downloadFolder(true, file)">
                <mat-icon class="mr-1">download</mat-icon>
                {{ 'Download' | translate }}
            </div>
            <div *ngIf="!!getLastCreatedArchive(file)" (click)="downloadArchive(file)">
                <mat-icon class="mr-1">download</mat-icon>
                {{ 'Download' | translate }}
            </div>

        </ng-template>
        
        
        <div (click)="settings(file)">
            <mat-icon class="mr-1">settings</mat-icon>
            {{ 'Settings' | translate }}
        </div>
        <div (click)="toShortCut(file)">
            <mat-icon class="mr-1">
                <svg width="18" style="margin-right: 16px;" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9 0L5.778 5.004L0 6.516L3.78 11.142L3.438 17.1L9 14.931L14.562 17.1L14.22 11.142L18 6.516L12.222 5.004L9 0ZM6.642 7.2C7.2 7.2 7.65 7.65 7.65 8.217C7.65 8.48434 7.5438 8.74073 7.35476 8.92976C7.16573 9.1188 6.90934 9.225 6.642 9.225C6.075 9.225 5.625 8.775 5.625 8.217C5.625 7.65 6.075 7.2 6.642 7.2ZM11.367 7.2C11.925 7.2 12.375 7.65 12.375 8.217C12.375 8.48434 12.2688 8.74073 12.0798 8.92976C11.8907 9.1188 11.6343 9.225 11.367 9.225C10.8 9.225 10.35 8.775 10.35 8.217C10.35 7.65 10.8 7.2 11.367 7.2ZM6.3 11.25H11.7C11.25 12.339 10.179 13.05 9 13.05C7.821 13.05 6.75 12.339 6.3 11.25Z" fill="#333"/>
                </svg>
            </mat-icon>
            {{ "Shortcut" | translate }}
        </div>
        <div *ngIf="file.is_dir == 0 && file?.thumbnail" (click)="setTaskCover(file)">
            <mat-icon class="mr-1">wallpaper</mat-icon>
            {{ 'Set as card wallpaper' | translate }}
        </div>
        
        <div *ngIf="file.is_dir == 0 && company && company?.permissions?.includes('create_task')" (click)="createCardsAndPublish(file)">
            <mat-icon class="mr-1">post_add</mat-icon>
            {{ 'Create card and publish' | translate }}
        </div>
        <div *ngIf="file.is_dir == 0 && company && company?.permissions?.includes('create_task')" (click)="createTaskBasedFiles(file)">
            <mat-icon class="mr-1">post_add</mat-icon>
            <ng-container *ngIf="selectedFiles.selected.length; else elseTemplateHasSelFiles">
                {{ 'Create a card based on' | translate }} {{selectedFiles.selected.length}} {{ 'selected files' | translate }}
            </ng-container>
            <ng-template #elseTemplateHasSelFiles>
                {{ 'Create a card based on this file' | translate }}
            </ng-template>
        </div>
        <div *ngIf="file.is_dir == 0 && company && company?.permissions?.includes('create_task')" (click)="createTaskBasedFilesV1(file)">
            <mat-icon class="mr-1">post_add</mat-icon>
            <ng-container *ngIf="selectedFiles.selected.length; else elseTemplateHasSelFiles">
                {{ 'Create a card based on' | translate }} {{selectedFiles.selected.length}} {{ 'selected files by template (v1)' | translate }}
            </ng-container>
            <ng-template #elseTemplateHasSelFiles>
                {{ 'Create Card using this file by template (v1)' | translate }}
            </ng-template>
        </div>
        <div *ngIf="file.is_dir == 0 && (company?.permissions.includes('owner') || company?.permissions.includes('admin') || company?.permissions.includes('assign_task_parameter'))" (click)="openTargetValues([file.task_id, file.task_operation_id ? file.task_operation_id : 0, file.discussion_id && file.discussion ? file.discussion_id : 0, file.id], file)">
            <mat-icon class="mr-1">settings_suggest</mat-icon> 
            <span>{{ "Tags" | translate }}</span>
        </div>
        <div *ngIf="selectedFiles && selectedFiles.selected.length > 1 && (company?.permissions.includes('owner') || company?.permissions.includes('admin') || company?.permissions.includes('assign_task_parameter'))" (click)="setPrimaryTag()">
            <mat-icon class="mr-1" style="color: #b3d3fb !important;">settings_suggest</mat-icon> 
            <span>{{ "Primary Tag" | translate }}</span>
        </div>
        <div *ngIf="file.is_dir == 0 && (company?.permissions.includes('owner') || checkIsManager(task, company, user)) && fileLocation(file) && !!file.task_operation_id" (click)="approveFile(file)">
            <mat-icon class="mr-1">
                <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.4 6.3C15.4 5.523 14.77 4.9 14 4.9H9.576L10.248 1.701C10.262 1.631 10.269 1.554 10.269 1.477C10.269 1.19 10.15 0.924 9.961 0.735L9.219 0L4.613 4.606C4.354 4.865 4.2 5.215 4.2 5.6V12.6C4.2 12.9713 4.3475 13.3274 4.61005 13.5899C4.8726 13.8525 5.2287 14 5.6 14H11.9C12.481 14 12.978 13.65 13.188 13.146L15.302 8.211C15.365 8.05 15.4 7.882 15.4 7.7V6.3ZM0 14H2.8V5.6H0V14Z" fill="#219653"/>
                </svg>                                            
            </mat-icon>
            {{ 'Approve' | translate }}
        </div>
        <div *ngIf="file.is_dir == 0 && (company?.permissions.includes('owner') || checkIsManager(task, company, user)) && fileLocation(file) && !!file.task_operation_id" (click)="rejectFile(file)">
            <mat-icon class="mr-1">
                <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.6 8.4H15.4V0H12.6M9.8 0H3.5C2.919 0 2.422 0.35 2.212 0.854L0.098 5.789C0.035 5.95 0 6.118 0 6.3V7.7C0 8.0713 0.1475 8.4274 0.410051 8.68995C0.672602 8.9525 1.0287 9.1 1.4 9.1H5.817L5.152 12.299C5.138 12.369 5.131 12.439 5.131 12.516C5.131 12.81 5.25 13.069 5.439 13.258L6.181 14L10.787 9.387C11.046 9.135 11.2 8.785 11.2 8.4V1.4C11.2 0.623 10.57 0 9.8 0Z" fill="#DB0000"/>
                </svg>                                          
            </mat-icon>
            {{ 'Reject' | translate }}
        </div>
        <div *ngIf="file.is_dir == 0 && (company?.permissions.includes('owner') || company?.permissions.includes('manager') || company?.permissions.includes('admin') || user.id == file.user_id || user.id == file.created_user_id)" (click)="readyFile(file)">
            <ng-container *ngIf="!file.is_ready_if_approved; else elseTemplateReadyVersion">
                <mat-icon class="mr-1">check_box_outline_blank</mat-icon>
            </ng-container>
            <ng-template #elseTemplateReadyVersion>     
                <mat-icon class="mr-1">check_box</mat-icon>
            </ng-template>
            {{ 'Ready Version if Approved' | translate }}
        </div>
        <div *ngIf="file.is_dir == 0 && !!file.is_ready_if_approved && file?.checklist && file?.checklist?.length > 0" (click)="selectCheckList(file)">
            <mat-icon class="mr-1">checklist</mat-icon>
            {{ 'Select checklist item' | translate }}
        </div>
        <div class="menu_divider"></div> -->
        <div class="has_sub_menu" *ngIf="(company | perm:'see_task_channels') && uniqueChannels && menuData?.file.is_dir == 0">
            <mat-icon class="mr-1">publish</mat-icon>
            {{ 'Publish to' | translate }}

            <div class="sub_menu_out" [ngClass]="{'open_left': menuData?.file?.open_to_left}">
                <div class="sub_menu">
                    <ng-container *ngFor="let outlet of uniqueChannels">
                        <div class="sub_item" *ngIf="outlet && outlet.channel && !!outlet.channel.channel_account_id" (click)="outletClick(outlet, menuData?.file)">
                            <ng-container *ngIf="outlet.channel.avatarFile; else elseTemplateNoPhoto">
                                <div class="channel_avatar">
                                    <img [src]="host + outlet.channel.avatarFile.original + '?company_id=' + company_id" alt="">
                                    <div class="channel_avatar_platform">
                                        <app-platform [platform_id]="outlet.channel?.platform_id"></app-platform>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-template #elseTemplateNoPhoto>      
                                <div class="platform_icon">
                                    <app-platform [platform_id]="outlet.channel?.platform_id"></app-platform>
                                </div>
                            </ng-template>
                            <span class="text_one_line" style="position: relative;">
                                {{outlet.channel.name}}
                                <div class="chan_tags" *ngIf="getProfile(outlet.channel_id) && getProfile(outlet.channel_id)?.parameterValuesToChannel">
                                    <ng-container *ngFor="let tag of getProfile(outlet.channel_id)?.parameterValuesToChannel; let last = last">
                                        <span>{{tag?.parameterValue?.value}}</span>
                                        <ng-container *ngIf="!last">, </ng-container>
                                    </ng-container>
                                </div>                         
                            </span>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
        <div *ngIf="menuData?.file?.is_dir == 1" (click)="openFolderArchives(file)">
            <mat-icon class="mr-1">view_stream</mat-icon>
            {{ 'Folder Archives' | translate }}
        </div>
        <div *ngIf="menuData?.file?.is_dir == 0 && (company?.permissions.includes('owner') || company?.permissions.includes('admin') || company?.permissions.includes('assign_task_parameter'))" (click)="openTargetValues([menuData?.file?.task_id, menuData?.file?.task_operation_id ? menuData?.file?.task_operation_id : 0, menuData?.file?.discussion_id && menuData?.file?.discussion ? menuData?.file?.discussion_id : 0, menuData?.file?.id], menuData?.file)">
            <mat-icon class="mr-1">settings_suggest</mat-icon> 
            <span>{{ "Tags" | translate }}</span>
        </div>
        <div *ngIf="selectedFiles && selectedFiles.selected.length > 1 && (company?.permissions.includes('owner') || company?.permissions.includes('admin') || company?.permissions.includes('assign_task_parameter'))" (click)="setPrimaryTag()">
            <mat-icon class="mr-1" style="color: #b3d3fb !important;">settings_suggest</mat-icon> 
            <span>{{ "Primary Tag" | translate }}</span>
        </div>

		<div *ngIf="menuData?.file?.filename != 'sources' && menuData?.file?.filename != 'approved' && menuData?.file?.filename != 'to_approve' && menuData?.file?.filename != 'ready_files' && menuData?.file?.filename != 'project' && menuData?.file?.filename != 'trash'" (click)="copy(menuData?.file)">
            <mat-icon class="mr-1">file_copy</mat-icon>
            {{ 'Copy' | translate }}
        </div>

        <div *ngIf="menuData?.file.is_dir == 0" ngxClipboard [cbContent]="origin + '/file/' + menuData?.file?.acm" (cbOnSuccess)="copyLink('file'); closeFileMenu()">
            <mat-icon class="mr-1">link</mat-icon> 
            {{ 'Copy url to file' | translate }}
        </div>

        <div (click)="settings(menuData?.file)">
            <mat-icon class="mr-1">info</mat-icon>
            {{ 'Properties' | translate }}
        </div>

        <div *ngIf="menuData?.file?.filename != 'sources' && menuData?.file?.filename != 'approved' && menuData?.file?.filename != 'to_approve' && menuData?.file?.filename != 'ready_files' && menuData?.file?.filename != 'project' && menuData?.file?.filename != 'trash'" (click)="edit(menuData?.file)">
            <mat-icon class="mr-1">edit</mat-icon>
            {{ 'Rename' | translate }}
        </div>

        <ng-container *ngIf="menuData?.file?.is_dir == 0 && understandFileType(menuData?.file.content_type) == 'video' && !!menuData?.file.meta_height && !!menuData?.file.meta_width && !!menuData?.file.preview1080">
            <mat-divider></mat-divider>
            <div (click)="V5VideoEditor(menuData?.file)">
                <mat-icon class="mr-1">movie</mat-icon>
                {{ 'Open in video edition project (V5)' | translate }}
            </div>
            <div (click)="videoEditorV6(menuData?.file)">
                <mat-icon class="mr-1">movie</mat-icon>
                {{ 'Open in video edition project (V5.1)' | translate }}
            </div>
            <!-- <div (click)="videoEditorVA(menuData?.file)">
                <mat-icon class="mr-1">movie</mat-icon>
                {{ 'Open in video edition project (V. A)' | translate }}
            </div> -->
            <mat-divider></mat-divider>
        </ng-container>
        
        <div (click)="downloadJD(menuData?.file)">
            <mat-icon class="mr-1">download</mat-icon>
            {{ 'Download with JD 2' | translate }}
        </div>
        <ng-container *ngIf="menuData?.file.is_dir == 0">
            <div (click)="download(menuData?.file)">
                <mat-icon class="mr-1">download</mat-icon>
                {{ 'Download' | translate }}
            </div>
        </ng-container>
        <ng-container *ngIf="menuData?.file.filename != 'sources' && menuData?.file.filename != 'approved' && menuData?.file.filename != 'to_approve' && menuData?.file.filename != 'ready_files' && menuData?.file.filename != 'project' && menuData?.file.filename != 'trash'" >
            <div class="menu_divider"></div>
            <div (click)="delete(menuData.target, menuData?.file)">
                <mat-icon class="mr-1">delete</mat-icon>
                {{ 'Delete' | translate }} {{selectedFiles?.selected && selectedFiles?.selected.length > 1 ? (selectedFiles?.selected + ('files' | translate)) : ''}}
            </div>
        </ng-container>
	</section>
</ng-template>


<input 
hidden 
type="file" 
#liteUploader
multiple
(change)="uploadFile($event)"
/>
<input 
hidden 
type="file" 
webkitdirectory 
directory 
multiple
#liteUploaderFolder
(change)="uploadFolder($event)"
/>

<ng-template #outFileMenu let-menuData>
	<section class="proj-file-menu">
        <div (click)="createFolder()">
            <mat-icon class="mr-1">create_new_folder</mat-icon>
            {{ "Create folder" | translate }}
        </div>
        <div (click)="createDocument()">
            <mat-icon class="mr-1">note_add</mat-icon>
            {{ "Create document" | translate }}
        </div>
        <div *ngIf="crumbs[crumbs.length - 1].location != '/'" (click)="ctxOpenFolderArch()">
            <mat-icon class="mr-1">view_stream</mat-icon>
            {{ 'Folder Archives' | translate }}
        </div>
        <div *ngIf="crumbs[crumbs.length - 1].location != '/'" (click)="archive(true)">
            <mat-icon class="mr-1">folder_zip</mat-icon>
            {{ 'Archive' | translate }}
        </div>
        <div (click)="paste()" *ngIf="!!copiedFiles">
            <mat-icon class="mr-1">content_paste</mat-icon>
            {{ 'Paste' | translate }}
        </div>
        <div (click)="pasteBack()" *ngIf="(!!copiedFiles || !!copiedAllFiles)">
            <mat-icon class="mr-1">content_paste</mat-icon>
            {{ 'Paste on background' | translate }}
        </div>
        <div (click)="liteUploaderFolder.click()">
            <mat-icon class="mr-1">drive_folder_upload</mat-icon>
            {{ "Upload folder" | translate }}
        </div>
        <div (click)="liteUploader.click()">
            <mat-icon class="mr-1">upload</mat-icon>
            {{ "Upload file(s)" | translate }}
        </div>
        <div *ngIf="crumbs[crumbs.length - 1].location != '/'" (click)="settings()">
            <mat-icon class="mr-1">info</mat-icon>
            {{ 'Properties' | translate }}
        </div>
        <!-- <ng-container *ngIf="form.get('location').value == '/' && !is_global && task_id">
            <ng-container *ngIf="task_operation_id; else elseTemplateIsJob">
                <div (click)="openNotes({task_id: task_id, id: task_operation_id}, 'job')">
                    <mat-icon class="mr-1">text_snippet</mat-icon>
                    {{ 'Job Notes' | translate }} 
                </div>
            </ng-container>
            <ng-template #elseTemplateIsJob>
                <div (click)="openNotes({id: task_id}, 'task')">
                    <mat-icon class="mr-1">text_snippet</mat-icon>
                    {{ 'Card Notes' | translate }}
                </div>
            </ng-template>
        </ng-container>
        <div *ngIf="!!crumbs[crumbs.length - 1].target" (click)="settings(crumbs[crumbs.length - 1].target)">
            <mat-icon class="mr-1">info</mat-icon>
            {{ 'Properties' | translate }}
        </div>-->
	</section>
</ng-template>