<div class="cases_page" *ngIf="filter">
    <div class="container">
        <ng-container *ngIf="!data.is_mobile; else elseTemplateMobFilter">
            <div class="filter_out">  
                <form [formGroup]="filter" class="filter_in">
                    <div class="filter_form_in">
                    
                        <mat-form-field *ngIf="data?.company?.permissions.includes('owner') || company?.permissions.includes('admin') || data?.company?.permissions.includes('manager')" appearance="legacy" class="filter_form executor">
                          <mat-label>{{ "Executor" | translate }}</mat-label>
                          <mat-select [disableOptionCentering]="true" selectDir formControlName="employee_id">
                            <mat-option>
                              <ngx-mat-select-search 
                                  placeholderLabel="{{ 'Search' | translate }}" 
                                  noEntriesFoundLabel="{{ 'no matching member found' | translate }}"
                                  [formControl]="execControl"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let employee of employees$ | async" [value]="employee.id">
                              <app-user [class]="'user_div'" [employee]="employee"></app-user>
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                
                        <mat-form-field appearance="legacy" class="filter_form status">
                          <mat-label>{{ "Status" | translate }}</mat-label>
                          <mat-select formControlName="status_id" [multiple]="true">
                          <mat-option *ngFor="let status of statuses" [value]="status.id">
                              {{ status.name | translate }}
                          </mat-option>
                          </mat-select>
                        </mat-form-field>
                    
                        <mat-form-field appearance="legacy" class="example-form-field filter_form date">
                          <mat-label>{{ 'Date' | translate }}</mat-label>
                          <mat-date-range-input  [rangePicker]="rangePicker">
                              <input formControlName="start_at_from" matStartDate placeholder="{{ 'Date from' | translate }}">
                              <input formControlName="start_at_to" matEndDate placeholder="{{ 'Date to' | translate }}">
                          </mat-date-range-input>
                          <mat-datepicker-toggle matPrefix [for]="rangePicker"></mat-datepicker-toggle>
                          <mat-date-range-picker #rangePicker>
                            <mat-date-range-picker-actions>
                              <button mat-button matDateRangePickerCancel>Cancel</button>
                              <button mat-raised-button color="primary" (click)="filterData()" matDateRangePickerApply>Apply</button>
                            </mat-date-range-picker-actions>
                          </mat-date-range-picker>
                        </mat-form-field>
                      </div>
                </form>
              </div>
            
              <div class="board_out">
                <div class="board_wrp">
                    <div class="todos_wrp">
                        <div class="lists_wrp">
                            <div class="lists_left">
                                  <div class="job_status_wrp">
                                      <button mat-button (click)='changeActiveStatus(0, $event)' [ngClass]="{'active_status': activeStatus.includes(1) && activeStatus.includes(2) && activeStatus.includes(5)}">{{ "All to do" | translate }}</button>
                                      <button mat-button (click)='changeActiveStatus(1, $event)' [ngClass]="{'active_status': activeStatus.includes(1) && activeStatus.length == 1}">{{ "Waiting" | translate }}</button>
                                      <button mat-button (click)='changeActiveStatus(2, $event)' [ngClass]="{'active_status': activeStatus.includes(2) && activeStatus.length == 1}">{{ "In process" | translate }}</button>
                                      <button mat-button (click)='changeActiveStatus(5, $event)' [ngClass]="{'active_status': activeStatus.includes(5) && activeStatus.length == 1}">{{ "To correct" | translate }}</button>
                                  </div>
                              </div>
                        
                             
                              <div class="d-f-c">
                                <mat-button-toggle-group class="view_group" (change)="changeDay($event)">
                                    <mat-button-toggle value="today">{{ "Today" | translate }}</mat-button-toggle>
                                    <mat-button-toggle value="tomorow">{{ "Tomorrow" | translate }}</mat-button-toggle>
                                </mat-button-toggle-group>
                              </div>
                            
                          </div>
                    </div>
                    <div class="dones_wrp">
                        <div class="lists_wrp">
                            <div class="lists_left">     
                              <div class="job_status_wrp">
                                <button mat-button (click)='changeDoneStatus(0, $event)' [ngClass]="{'active_status': doneStatus.includes(3) && doneStatus.includes(4) && doneStatus.includes(6) && doneStatus.includes(98) && doneStatus.includes(99)}">{{ "All done" | translate }}</button>
                                <button mat-button (click)='changeDoneStatus(3, $event)' [ngClass]="{'active_status': doneStatus.includes(3) && doneStatus.length == 1}">{{ "To approve" | translate }}</button>
                                <button mat-button (click)='changeDoneStatus(4, $event)' [ngClass]="{'active_status': doneStatus.includes(4) && doneStatus.length == 1}">{{ "Approved" | translate }}</button>
                                <button mat-button (click)='changeDoneStatus(6, $event)' [ngClass]="{'active_status': doneStatus.includes(6) && doneStatus.length == 1}">{{ "Sources needed" | translate }}</button>
                                <button mat-button (click)='changeDoneStatus(97, $event)' [ngClass]="{'active_status': doneStatus.includes(97) && doneStatus.length == 1}">{{ "On hold" | translate }}</button>
                                <button mat-button (click)='changeDoneStatus(98, $event)' [ngClass]="{'active_status': doneStatus.includes(98) && doneStatus.length == 1}">{{ "Ready" | translate }}</button>
                                <button mat-button (click)='changeDoneStatus(99, $event)' [ngClass]="{'active_status': doneStatus.includes(99) && doneStatus.length == 1}">{{ "Canceled" | translate }}</button>
                              </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
              </div>
        </ng-container>
        <ng-template #elseTemplateMobFilter>
            <div class="d-f-c" style="justify-content: flex-end;">
                <button mat-icon-button (click)="close()">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
            <form [formGroup]="filter" class="filter_wrp">
                <div class="mob_filter_inputs_wrp">
      
                    <mat-form-field *ngIf="data?.company?.permissions.includes('owner') || company?.permissions.includes('admin') || data?.company?.permissions.includes('manager')" appearance="legacy" class="filter_form">
                      <mat-label>{{ "Executor" | translate }}</mat-label>
                      <mat-select [disableOptionCentering]="true" selectDir formControlName="employee_id" >
                        <mat-option>
                          <ngx-mat-select-search 
                              placeholderLabel="{{ 'Search' | translate }}" 
                              noEntriesFoundLabel="{{ 'no matching member found' | translate }}"
                              [formControl]="execControl"></ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let employee of employees$ | async" [value]="employee.id">
                          <app-user [class]="'user_div'" [employee]="employee"></app-user>
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
            
                    <mat-form-field appearance="legacy" class="filter_form">
                      <mat-label>{{ "Status" | translate }}</mat-label>
                      <mat-select formControlName="status_id" [multiple]="true">
                      <mat-option *ngFor="let status of statuses" [value]="status.id">
                          {{ status.name | translate }}
                      </mat-option>
                      </mat-select>
                    </mat-form-field>
                
                    <mat-form-field appearance="legacy" class="example-form-field filter_form">
                      <mat-label>{{ 'Date' | translate }}</mat-label>
                      <mat-date-range-input  [rangePicker]="rangePicker">
                          <input formControlName="start_at_from" matStartDate placeholder="{{ 'Date from' | translate }}">
                          <input formControlName="start_at_to" matEndDate placeholder="{{ 'Date to' | translate }}">
                      </mat-date-range-input>
                      <mat-datepicker-toggle matPrefix [for]="rangePicker"></mat-datepicker-toggle>
                      <mat-date-range-picker #rangePicker>
                        <mat-date-range-picker-actions>
                          <button mat-button matDateRangePickerCancel>Cancel</button>
                          <button mat-raised-button color="primary" (click)="filterData()" matDateRangePickerApply>Apply</button>
                        </mat-date-range-picker-actions>
                      </mat-date-range-picker>
                    </mat-form-field>
                </div>
              </form>
        </ng-template>
    </div>
</div>