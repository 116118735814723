<mat-dialog-content class="mat-typography">
    <div class="container a_r_blocks_wrp">
        <p class="in_block comment">Tap the file to add to checklist <b>“{{data.check.text}}”</b>.</p>
        <div class="in_block files_block">

            <app-file-manager
            [useOut]="true"
            [selectedFileIds]="selectedIds()"
            [selectedMode]="true"
            [multiSelectedMode]="true"
            (onSelectFile)="toggleFile($event)"
            [initLocation]="!!files.length ? '/ready_files' : '/'"
            [initData]="{
                'task': data?.task,
                'work': data.work,
                'company': data?.company
            }"></app-file-manager>
            <!-- <div class="files_wrp" [appDeviceClass]="'custom-scroll custom-scroll-t'">
                <ng-container *ngFor="let file of files; let i = index;">
                    <div class="file" (click)="toggleFile(file)"
                    [ngClass]="{'has_dmg': !file.is_uploaded, 'selected': !!file.selected}">
                        <mat-icon class="recomend" *ngIf="file.is_ready_if_approved">
                            recommend
                        </mat-icon>
                        <div class="file_data">
                            <div class="file_img">
                                <ng-container [ngSwitch]="understandFileType(file.content_type)">
                                    <ng-container *ngSwitchCase="'video'">
                                        <ng-container *ngIf="file.thumbnail; else elseTemplateThumbnail">
                                            <div class="movie_thumb">
                                                <img style="max-width: 100%; max-height: 100%;" (error)="onImgError($event)" [src]="host + file.thumbnail + '?company_id=' + company?.id">
                                                <mat-icon>play_circle</mat-icon>
                                            </div>
                                        </ng-container>
                                        <ng-template #elseTemplateThumbnail>
                                            <mat-icon class="file_img_icon" style="color: #3f51b5">
                                                movie
                                            </mat-icon>
                                        </ng-template>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'image'">
                                        <img (error)="onImgError($event)" style="max-width: 100%; max-height: 100%;" [src]="host + (file.thumbnail ? file.thumbnail : file.original) + '?company_id=' + company?.id">
                                    </ng-container>
                                    <ng-container *ngSwitchDefault>
                                        <ng-container *ngIf="!!file?.thumbnail; else elseTemplateHasThumb">
                                            <img (error)="onImgError($event)" style="max-width: 100%; max-height: 100%;" [src]="host + file.thumbnail + '?company_id=' + company?.id">
                                        </ng-container>
                                        <ng-template #elseTemplateHasThumb>
                                            <mat-icon class="file_img_icon" style="color: #3f51b5">
                                                insert_drive_file
                                            </mat-icon>
                                        </ng-template>
                                    </ng-container>
                                </ng-container>
                            </div>
                        </div>
                    </div>  
                </ng-container>
            </div> -->
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <div class="f_w_p_btns">
        <button mat-button (click)="close()">
            {{ "Close" | translate }}
        </button>
        <button class="ml-1 d-f-btn" (click)="save()" mat-raised-button color="primary" [disabled]="isSubmit">
            <mat-spinner class="spin_16 mr-1" *ngIf="isSubmit"></mat-spinner>  
            {{ "Save" | translate }}
        </button>
    </div>
</mat-dialog-actions>
