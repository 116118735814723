import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatTable } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { forkJoin, fromEvent, ReplaySubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, last, map, switchMap, tap } from 'rxjs/operators';
import { DeleteAlertComponent } from 'src/app/shared/global_components/delete-alert/delete-alert.component';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { ChecklistWithParameterComponent } from '../checklist-with-parameter/checklist-with-parameter.component';
import { MY_FORMATS } from '../../../../atTasksDialog/task-profile-add/task-profile-add.component';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { CreateChecklistComponent } from '../create-checklist/create-checklist.component';
import { EditChecklistComponent } from '../edit-checklist/edit-checklist.component';
import { RefreshService } from 'src/app/shared/services/rest/refresh.service';
import { SetAutoForCheckComponent } from '../set-auto-for-check/set-auto-for-check.component';
import { ParametersService } from 'src/app/shared/services/rest/parameters.service';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';

@Component({
  selector: 'app-checklist',
  templateUrl: './checklist.component.html',
  styleUrls: ['./checklist.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
})

export class ChecklistComponent extends BaseClass implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild("tableContainer") tableContainer: ElementRef;
  @ViewChild(MatTable) table: MatTable<any>;
  public page: number = 1;
  public pagination: any;
  public checklist: any[] = [];
  public company_id: number;
  public filter: FormGroup
  public company: any;
  public groups: any;
  public activeLang: any;
  public operations: any;
  public displayedColumns: string[] = ["Date", "Order", "Operation type", "Group", "Text"];
  public allValues: any;
  public allValuesControl: FormControl = new FormControl();
  public allValues$: ReplaySubject<any> = new ReplaySubject<any>(1);
  
  public groupsControl: FormControl = new FormControl();
  public groups$: ReplaySubject<any> = new ReplaySubject<any>(1);


  constructor(
    private taskService: TaskService,
    private fb: FormBuilder,
    private router: Router,
    private layoutService: LayoutService,
    private languageService: LanguageService,
    private parametersService: ParametersService,
    private dialog: MatDialog,
    private _adapter: DateAdapter<any>,
    private activatedRoute: ActivatedRoute,
    private refreshService: RefreshService,
    private companyService: CompanyService,
    private bottomSheet: MatBottomSheet
  ) { super() }

  ngOnInit(): void {
    this.filter = this.fb.group({
      channel_group_id: 0,
      operation_id: 0
    })

    this.attachSubscriptions(
      this.refreshService.getChecklist().subscribe(resp => {
        console.log("getChecklist", resp)
        if (resp.all) {
          this.filterChecklists();
        } else {
          let check = this.checklist.find(x => x.id == resp.data.id)
          this.checklist.splice(this.checklist.indexOf(check), 1);
          this.table.renderRows();
        }
      })
    )

    this.attachSubscriptions(
      this.allValuesControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchValues(resp))
    )

    this.company_id = +this.activatedRoute.snapshot.queryParamMap.get('company_id');

    this.getLangData();
    this.getCompany();
    this.getGroupsCompany();
    this.getAllApiParameterValues();
    this.getOperations();
  
    
    this.attachSubscriptions(
      this.activatedRoute.queryParams.subscribe(params => {
        console.log("route checklists", params);
        this.checklist = [];
        this.page = 1;

        this.filter.patchValue({
          channel_group_id: !!this.activatedRoute.snapshot.queryParamMap.get('group_id') ? +this.activatedRoute.snapshot.queryParamMap.get('group_id') : 0,
          operation_id: !!this.activatedRoute.snapshot.queryParamMap.get('task_operation_operation_id') ? +this.activatedRoute.snapshot.queryParamMap.get('task_operation_operation_id') : 0
        })

        this.getChecklist(this.page)
      })
    )
    this.attachSubscriptions(
      this.groupsControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchGroups(resp))
    )
  }

  removeValue(val) {
    if (val == "all") {
      let params = {
        company_id: this.company_id,
        page: this.activatedRoute.snapshot.queryParamMap.get('page')
      }
      this.router.navigate(['/settings'], {queryParams: params})
    } else {
      let params = {...this.activatedRoute.queryParamMap.source['_value']}
      if (params[val]) {
        delete params[val]
      }

      this.router.navigate(['/settings'], {queryParams: params})
    }
  }

  changeAutoCheckTag(e, check) {
    console.log("changeAutoCheckTag", e, check);
    this.attachSubscriptions(
      this.taskService.editOperationReminder(check.id, {file_parameter_value_id: e.value}).subscribe(resp => {
        console.log("changeAutoCheckTag resp", resp);
      }, error => {
        this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
      })
    )
  }

  getAllApiParameterValues() {
    this.attachSubscriptions(
      this.parametersService.getAllValues('1', this.company_id, null, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.parametersService.getAllValues(x, this.company_id).pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        this.allValues = [].concat(...res);
        this.allValues$.next(this.allValues.slice())
      })
    )
  }

  onSearchValues(resp) {
    if (!this.allValues) {
      return;
    }

    if (!resp) {
      this.allValues$.next(this.allValues.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.allValues$.next(
      this.allValues.filter(z => z.value.toLowerCase().indexOf(resp) > -1 || (z.parameter.name && z.parameter.name.toLowerCase().indexOf(resp) > -1))
    );
  }

  onSearchGroups(resp) {
    if (!this.groups) {
      return;
    }

    if (!resp) {
      this.groups$.next(this.groups.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }

    this.groups$.next(
      this.groups.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }
  
  deleteChecklist(check) {
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: check,
        target: marker("checklist")
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        this.attachSubscriptions(
          this.taskService.deleteOperationReminder(check.id).subscribe(resp => {
            this.checklist.splice(this.checklist.indexOf(check), 1);
            this.table.renderRows();
          })
        )
      }
    });
  }

  

  getChecklist(page) {
    let filterData = this.filter.value;

    Object.keys(filterData).forEach(key => {
      if (filterData[key] == 0) {
        delete filterData[key];
      }
    })
    console.log(filterData)
    this.attachSubscriptions(
      this.taskService.getOperationReminder(this.company_id, filterData, page).pipe(
        tap(el => {
          this.pagination = {
            'pageCount': el.headers.get('x-pagination-page-count'),
            'perPage': el.headers.get('x-pagination-per-page'),
            'totalCount': el.headers.get('x-pagination-total-count'),
            'currentPage': el.headers.get('x-pagination-current-page'),
          }
        }),
        map(el => el.body)
      ).subscribe(resp => {
        console.log('getChecklist', resp)
        if (this.page == 1) {
          this.checklist = resp;
        } else {
          this.checklist.push(...resp);
        }
        this.page = this.page + 1;
        this.table.renderRows();
      })
    )
  }

  getGroup(id) {
    if (!this.groups || !this.groups.find(el => el.id == id)) {
      return false;
    }
    return this.groups.find(el => el.id == id)
  }  

  getType(id) {
    if (!this.operations || !this.operations.find(el => el.id == id)) {
      return false;
    }
    return this.operations.find(el => el.id == id)
  }  

  getGroupsCompany() {
    this.attachSubscriptions(
      this.companyService.getInfiniteGroupsCompany(this.company_id, '1', '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.companyService.getInfiniteGroupsCompany(this.company_id, x).pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        this.groups = [].concat(...res);
        this.groups.unshift({
          id: 0,
          name: marker('All groups')
        })
        this.groups$.next(this.groups.slice());
      })
    )
  }

  getOperations() {
    this.attachSubscriptions(
      this.taskService.getOperations(this.company_id).subscribe(resp => {
        resp.unshift({
          id: 0,
          name: marker('All types')
        })
        this.operations = resp;
      })
    )
  }

  filterReset() {
    this.filter.patchValue({
      channel_group_id: 0,
      operation_id: 0
    })
    this.router.navigate(['/settings'], { queryParams: {page: 'checklists', company_id: this.company_id}});
  }

  getCompany() {
    this.attachSubscriptions(
      this.companyService.getCompany(this.company_id).subscribe(resp => {
        console.log("getCompany", resp);
        this.companyService.company$.next(resp[0]);
        this.company = resp[0];
        if (this.company.permissions.includes('owner') || this.company.permissions.includes('manager') || this.company.permissions.includes('admin')) {
          this.displayedColumns.push(...["Tag", "Auto set", "Activity"])
        }
      })
    )
  }

  connectionWithParameters(element, only:boolean) {
    let initData:any = {
      company: this.company,
      check: element,
      only: only
    }

    const dialogRef = this.dialog.open(ChecklistWithParameterComponent, {
      backdropClass: ['parameters_modal_backdrop'],
      panelClass: ['without_paddings_modal', 'parameters_modal'],
      data: initData
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        this.page = 1;
        this.getChecklist(this.page);
      })
    )
  }

  getValuesById(id) {
    return this.allValues && this.allValues.find(x => x.id == id)
  }

  setAuto(element, only:boolean) {
    let initData:any = {
      company: this.company,
      check: element,
      only: only
    }

    const dialogRef = this.dialog.open(SetAutoForCheckComponent, {
      backdropClass: ['parameters_modal_backdrop'],
      panelClass: ['without_paddings_modal', 'parameters_modal'],
      data: initData
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        this.page = 1;
        this.getChecklist(this.page);
      })
    )
  }

  goBack(e) {
    e.preventDefault();
    history.back();
  }

  filterChecklists() {
    let params = {
      company_id: this.company_id,
      page: 'checklists'
    };

    Object.keys(this.filter.value).forEach(element => {
      if (this.filter.value[element] != '0') {
        params[element] = this.filter.value[element]
      }
    });

    this.router.navigate(['/settings'], { queryParams: params});
  }

  ngAfterViewInit() {
    this.tableContainer.nativeElement.scrollTop = 0;
    this.onScroll();
  }

  onScroll() {
    this.attachSubscriptions(
      fromEvent(this.tableContainer.nativeElement, "scroll").pipe(
        filter((e:any) => e.target.scrollTop >=  e.target.scrollHeight - e.target.offsetHeight - 400),
        map(() => this.tableContainer.nativeElement.scrollTop),
        debounceTime(300),
        map(y => Math.ceil((y + this.tableContainer.nativeElement.innerHeight)/ (400))),
        distinctUntilChanged()
      ).subscribe(() => {
          if (this.page <= this.pagination['pageCount']) {
            this.getChecklist(this.page);
          }
        }
      )
    )
  }

  
  addChecklist(e) {
    e.preventDefault();
    const dialogRef = this.dialog.open(CreateChecklistComponent, {
      disableClose: true,
      data: {
        company: this.company,
        company_id: this.company_id,
        channel_group_id: this.filter.value.channel_group_id,
        operation_id: this.filter.value.operation_id
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result && result.event == "update") {
          this.page = 1;
          this.getChecklist(this.page);
        }
      })
    )
  }

  
  editChecklist(element) {
    const dialogRef = this.dialog.open(EditChecklistComponent, {
      disableClose: true,
      data: {
        company: this.company,
        company_id: this.company_id,
        checklist: element,
        channel_group_id: this.filter.value.channel_group_id,
        operation_id: this.filter.value.operation_id
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result && result.event == "update") {
          this.page = 1;
          this.getChecklist(this.page);
        }
      })
    )
  }


  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData().subscribe(resp => {
        this.activeLang = resp.active;
        this._adapter.setLocale(resp.active);
      })
    )
  }

  ngOnDestroy() {
    this.clearSubscriptions()
  }
}
