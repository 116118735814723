import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { ReplaySubject, Subscription, forkJoin } from 'rxjs';
import { debounceTime, map, tap } from 'rxjs/operators';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { ChatService } from 'src/app/shared/services/rest/chat.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { SnackBarItem } from '../../../snack-bar/snack-bar-item';

@Component({
  selector: 'app-move-to-other-job',
  templateUrl: './move-to-other-job.component.html',
  styleUrls: ['./move-to-other-job.component.scss']
})
export class MoveToOtherJobComponent extends BaseClass implements OnInit, OnDestroy {
  public form: FormGroup;
  public isFormChange: boolean = false;
  public isSubmit: boolean = false;

  public page: number = 1;
  public pagination: any;

  public cardJobsPage: number = 1;
  public cardJobsPagination: any;

  public jobsSub: Subscription;

  // public jobControl: FormControl = new FormControl('');
  // public jobs$: ReplaySubject<any> = new ReplaySubject<any>(1);
  // public jobs: any[] = [];

  public cardJobControl: FormControl = new FormControl('');
  public cardJobs$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public cardJobs: any[] = [];

  public newJobControl: FormControl = new FormControl();
  public newJobs$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public newJobs: any[] = [];
  public newJobsPage: number = 1;
  public newJobsPagination: any;

  public activeIndex = new FormControl(0);
  public selectedJob: any;
  
  public is_getting: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<MoveToOtherJobComponent>,
    private fb: FormBuilder,
    private chatService: ChatService,
    private taskService: TaskService,
    private layoutService: LayoutService
  ) { super() }

  ngOnInit(): void {
    console.log("MoveToOtherJobComponent", this.data)
    this.dialogRef.addPanelClass("adding_executors_dialog")

    this.form = this.fb.group({
      task_operation_id: ['', Validators.required],
      discussion_id: this.data.chat ? this.data.chat.id : ''
    })

    // this.attachSubscriptions(
    //   this.jobControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearch(resp))
    // )
    this.attachSubscriptions(
      this.cardJobControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onCardJobsSearch(resp))
    )

    this.attachSubscriptions(
      this.form.valueChanges.subscribe(() => this.isFormChange = true)
    )

    this.attachSubscriptions(
      this.newJobControl.valueChanges.pipe(debounceTime(600)).subscribe((resp) => this.onSearchNewJobs(resp))
    )

    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        if (this.isFormChange) {
          this.layoutService.openBottomSheet(this.dialogRef);
        } else {
          this.close();
        }
      })
    )

    // this.getWorks(this.page);
    this.getJobs(this.newJobsPage);
    this.getCardWorks(this.cardJobsPage);
  }

  onScroll(e, type) {
    console.log("onScroll", e);
    this.onSearchNewJobs(this[type].value, e, true)
  }

  onSearchNewJobs(resp, e?, notDel?) {
    console.log("onSearchNewJobs", resp, e, notDel)
    if (!!notDel && this.is_getting) {
      return
    }

    resp = resp && typeof resp == 'string' ? resp.toLowerCase() : (resp || '');
    console.log('onSearchNewJobs', resp, notDel)

    let filter:any = {
      q: resp
    }

    if (!notDel) {
      if (this.form.value.task_operation_id) {
        this.newJobs = this.newJobs.filter(x => x.id == this.form.value.task_operation_id);
      } else {
        this.newJobs = [];
      }
      this.newJobs$.next(this.newJobs.slice())
      this.newJobsPage = 1;
      this.newJobsPagination = undefined
    }
    // filter the banks

    console.log("IN SEARCH", this.newJobs)

    if (!notDel && this.is_getting) {
      this.is_getting = false;
      if (this.jobsSub) {
        this.jobsSub.unsubscribe()
      }
    }
    this.getJobs(this.newJobsPage, filter, e);
  }

  selectJob(job) {
    this.selectedJob = JSON.parse(JSON.stringify(job));
    console.log("selectJob selectedJob", this.selectedJob);
  }

  changeTab(val) {
    this.form.patchValue({
      task_operation_id: ['', Validators.required]
    })
    this.selectedJob = undefined;
    this.activeIndex.patchValue(val);
  }

  // onSearch(resp) {
  //   if (!this.jobs) {
  //     return;
  //   }

  //   if (!resp) {
  //     this.jobs$.next(this.jobs.slice());
  //     return;
  //   } else {
  //     resp = resp.toLowerCase();
  //   }
  //   // filter the banks
  //   this.jobs$.next(
  //     this.jobs.filter(z => z.id.toString().indexOf(resp) > -1 || (z.name && z.name.toLowerCase().indexOf(resp) > -1) || (z.comment && z.comment.toLowerCase().indexOf(resp) > -1))
  //   );
  // }

  onCardJobsSearch(resp) {
    if (!this.cardJobs) {
      return;
    }

    if (!resp) {
      this.cardJobs$.next(this.cardJobs.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.cardJobs$.next(
      this.cardJobs.filter(z => (!!z.operation && z.operation.name.toLowerCase().indexOf(resp) > -1) || z.id.toString().indexOf(resp) > -1 || (z.name && z.name.toLowerCase().indexOf(resp) > -1) || (z.comment && z.comment.toLowerCase().indexOf(resp) > -1))
    );
  }

  getCardJobById(id) {
    return this.cardJobs.find(x => x.id == id);
  }

  getPrimaryTag(job) {
    if (job.parameterValuesToTask && job.parameterValuesToTask.length) {
      return job.parameterValuesToTask.find(x => !!x.is_primary)
    } else {
      return undefined
    }
  }

  getCardWorks(page) {
    this.attachSubscriptions(
      this.taskService.getWorks(page, this.data.company.id, {task_id: this.data.task ? this.data.task.id : this.data.chat.task_id}, '200').pipe(
        tap(el => {
          this.cardJobsPagination = {
            'pageCount': el.headers.get('x-pagination-page-count'),
            'perPage': el.headers.get('x-pagination-per-page'),
            'totalCount': el.headers.get('x-pagination-total-count'),
            'currentPage': el.headers.get('x-pagination-current-page'),
          }
        })
      ).subscribe(resp => {
        this.cardJobs.push(...resp.body.slice())
        this.cardJobs$.next(this.cardJobs.slice())
        this.cardJobsPage = this.cardJobsPage + 1;
        // if (this.jobControl.value != '') {
        //   this.onSearch(this.jobControl.value)
        // }

        console.log(this.cardJobsPagination, this.cardJobs);
        if (+this.cardJobsPagination['currentPage'] != +this.cardJobsPagination['pageCount']) {
          this.getCardWorks(this.cardJobsPage);
        }
      })
    )
  }

  getJobs(page, filter:any = null, event?) {
    if (this.is_getting) {
      return
    }
    this.is_getting = true;
    console.log("getTasks filter", filter)
    // 2 getTasksSelect
    this.jobsSub = this.taskService.getWorks(page, this.data.company.id, filter, '20').pipe(
      tap(el => {
        this.newJobsPagination = {
          'pageCount': el.headers.get('x-pagination-page-count'),
          'perPage': el.headers.get('x-pagination-per-page'),
          'totalCount': el.headers.get('x-pagination-total-count'),
          'currentPage': el.headers.get('x-pagination-current-page'),
        }
      }),
      map(x => x.body)
    ).subscribe(resp => {
      this.newJobs.push(...resp)
      console.log("getJobs res" + this.page, this.newJobs)
      this.newJobsPage = this.newJobsPage + 1;
      this.newJobs$.next(this.newJobs.slice())
      this.is_getting = false;

      if (event) {
        setTimeout(() => {
          event.e.target.scrollTop = event.scrollTop;
          console.log("END", event)
        }, 150)
      }
    })
    
  }

  // getWorks(page) {
  //   this.attachSubscriptions(
  //     this.taskService.getWorks(page, this.data.company.id, undefined, '200').pipe(
  //       tap(el => {
  //         this.pagination = {
  //           'pageCount': el.headers.get('x-pagination-page-count'),
  //           'perPage': el.headers.get('x-pagination-per-page'),
  //           'totalCount': el.headers.get('x-pagination-total-count'),
  //           'currentPage': el.headers.get('x-pagination-current-page'),
  //         }
  //       })
  //     ).subscribe(resp => {
  //       this.jobs.push(...resp.body.slice())
  //       this.jobs$.next(this.jobs.slice())
  //       this.page = this.page + 1;
  //       if (this.jobControl.value != '') {
  //         this.onSearch(this.jobControl.value)
  //       }

  //       console.log(this.pagination, this.jobs);
  //       if (+this.pagination['currentPage'] != +this.pagination['pageCount']) {
  //         this.getWorks(this.page);
  //       }
  //     })
  //   )
  // }
  
  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  onSubmit() {
    this.isSubmit = true;
    if (!!this.data.selectedChats) {
      this.attachSubscriptions(
        forkJoin(this.data.selectedChats.map(chat => this.chatService.moveChat({
          task_operation_id: this.form.value.task_operation_id,
          discussion_id: chat.id
        }))).subscribe(resp => {
          this.isSubmit = false;
          this.close()
        },
        error => {
          this.isSubmit = false;
          this.showError(error)
          this.close()
        })
      )
    } else {
      console.log(this.form.value);
      this.attachSubscriptions(
        this.chatService.moveChat(this.form.value).subscribe(resp => {
          this.isSubmit = false;
          this.close()
        },
        error => {
          this.showError(error)
          this.isSubmit = false;
          this.close()
        })
      )
    }
  }

  showError(error) {
    this.layoutService.showSnackBar({name: error}, marker("You do not have permission to do this"), SnackBarItem)
  }

  ngOnDestroy(): void {
    if (this.jobsSub) {
      this.jobsSub.unsubscribe()
    }
    this.clearSubscriptions()
  }
}
