export function jobsModel(arr, currencyData:any) {
  
  arr.forEach(element => {

    element.employees.forEach(empl => {
      if (element.employeesStatuses.filter(y => y.employee_id == empl.employee_id && y.task_operation_id == empl.task_operation_id).length > 0) {
        if (element.employeesStatuses.filter(x => x.discussion_id == empl.discussion_id).length > 0) {
          empl.employeeStatus = element.employeesStatuses.find(x => x.employee_id == empl.employee_id && x.task_operation_id == empl.task_operation_id && x.discussion_id == empl.discussion_id)
        }
        if (element.employeesStatuses.filter(x => x.discussion_id == 0).length > 0) {
          empl.employeeStatus = element.employeesStatuses.find(x => x.employee_id == empl.employee_id && x.task_operation_id == empl.task_operation_id && x.discussion_id == 0)
        }
      }
    })

    
    element.addEmployee = element.employees.filter(x => x.task_operation_id == element.id && (x.is_manager == 0 || (x.is_manager == 1 && x.company_id != element.company_id)));
    element.uniqueAddEmployee = []
    element.addEmployee.forEach(empl => {
      if (!element.uniqueAddEmployee.find(x => x.employee_id == empl.employee_id)) {
        element.uniqueAddEmployee.push(empl)
      }
    });
    element.mainEmployees = element.employees.filter(x => x.task_operation_id == element.id && x.is_manager == 1 && x.company_id == element.company_id);
    if (element.partnerCompanies.length) {
      element.partnerCompanies.forEach(p => {
        if (element.partnerCompaniesStatuses.filter(y => y.partner_company_id == p.partner_company_id && y.task_operation_id == p.task_operation_id).length > 0) {
          if (element.partnerCompaniesStatuses.filter(x => x.discussion_id == p.discussion_id).length > 0) {
            p.partnerCompanyStatus = element.partnerCompaniesStatuses.find(x => x.partner_company_id == p.partner_company_id && x.task_operation_id == p.task_operation_id && x.discussion_id == p.discussion_id)
          }
          if (element.employeesStatuses.filter(x => x.discussion_id == 0).length > 0) {
            p.partnerCompanyStatus = element.partnerCompaniesStatuses.find(x => x.partner_company_id == p.partner_company_id && x.task_operation_id == p.task_operation_id && x.discussion_id == 0)
          }
        }
        p.is_partner = true;
        element.employees.push(p);
        if (p.is_manager == 1) {
          element.mainEmployees.push(p);
        } else {
          element.addEmployee.push(p)
        }
      
      })
    }

    if (element.automationScenarios && element.automationScenarios.length) {
      element.selectedScenIds = element.automationScenarios.map(x => x.template_id);
    } else {
      element.selectedScenIds = []
    }

    if (element.employees.filter(x => x.is_manager == 1 && x.is_price_manager == 0 && x.task_operation_id == element.id && x.discussion_id == 0).length > 0) {
      element.employee = element.employees.find(x => x.is_manager == 1 && x.is_price_manager == 0 && x.task_operation_id == element.id && x.discussion_id == 0)
    }
    
    if (!element.hasOwnProperty('emplSummary')) {
      element.emplSummary = JSON.parse(JSON.stringify(currencyData));
    }

    element.employees.map(y => {
      // console.log("currencyData y")
      element.emplSummary[!!y.price_currency_id ? y.price_currency_id : 0].summary = Number(element.emplSummary[!!y.price_currency_id ? y.price_currency_id : 0].summary) + Number(y.price);
      element.emplSummary[currencyData.length - 1].summary = Number(element.emplSummary[currencyData.length - 1].summary) + Number(y.rate);
    })

    element.mobEmployees = [];
    let allEmployees = element.employees.filter(x => x.task_operation_id == element.id);
    allEmployees.forEach(empl => {
      if (!element.mobEmployees.find(x => x.employee_id == empl.employee_id)) {
        element.mobEmployees.push(empl)
      }
    });
    
    element.emplSummCount = element.emplSummary.filter(t => t.summary > 0).length

  })
  return arr;
}