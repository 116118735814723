<div mat-dialog-title class="mobile_interface_title">
    <div class="mobile_interface_title_in" style="overflow: hidden;">
        <div class="tab_title" (click)="close()">
            <div class="d-f-c">
                <div class="icon_back">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7 12L6.11612 11.1161C5.62796 11.6043 5.62796 12.3957 6.11612 12.8839L7 12ZM16.8839 3.88388C17.372 3.39573 17.372 2.60427 16.8839 2.11612C16.3957 1.62796 15.6043 1.62796 15.1161 2.11612L16.8839 3.88388ZM15.1161 21.8839C15.6043 22.372 16.3957 22.372 16.8839 21.8839C17.372 21.3957 17.372 20.6043 16.8839 20.1161L15.1161 21.8839ZM7.88388 12.8839L16.8839 3.88388L15.1161 2.11612L6.11612 11.1161L7.88388 12.8839ZM6.11612 12.8839L15.1161 21.8839L16.8839 20.1161L7.88388 11.1161L6.11612 12.8839Z" fill="#232323"/>
                    </svg>
                </div>
                <span>
                    {{ "Shortcuts" | translate }}
                </span>
            </div>
        </div>
        <mat-progress-bar mode="indeterminate" *ngIf="isLoad" class="mat_proggress_w8 cases_progress"></mat-progress-bar>
    </div>
</div>
<mat-dialog-content class="mobile_interface_content">
    <div class="mobile_scrollable_int_big" style="background: #fff;">
        <mat-list>
            <ng-container *ngIf="data.groupedBarItems && data.groupedBarItems.tasks && data.groupedBarItems.tasks.length">
                <div mat-subheader>
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16 12V16H2V12H16ZM17 10H1C0.45 10 0 10.45 0 11V17C0 17.55 0.45 18 1 18H17C17.55 18 18 17.55 18 17V11C18 10.45 17.55 10 17 10ZM4 15.5C3.18 15.5 2.5 14.83 2.5 14C2.5 13.17 3.18 12.5 4 12.5C4.82 12.5 5.5 13.17 5.5 14C5.5 14.83 4.83 15.5 4 15.5ZM16 2V6H2V2H16ZM17 0H1C0.45 0 0 0.45 0 1V7C0 7.55 0.45 8 1 8H17C17.55 8 18 7.55 18 7V1C18 0.45 17.55 0 17 0ZM4 5.5C3.18 5.5 2.5 4.83 2.5 4C2.5 3.17 3.18 2.5 4 2.5C4.82 2.5 5.5 3.18 5.5 4C5.5 4.82 4.83 5.5 4 5.5Z" fill="#686868"/>
                    </svg>
                    {{ "Cards" | translate }}
                </div>
                <mat-list-item class="short_item" (click)="goToItem(item)" *ngFor="let item of data.groupedBarItems.tasks">
                    <span class="text_one_line">
                        {{item?.switchData?.name || "no name"}}
                    </span>
                    <mat-icon (click)="$event.stopPropagation(); $event.preventDefault(); removeItem(item)">close</mat-icon>
                </mat-list-item>
            </ng-container>

            <ng-container class="short" *ngIf="data.groupedBarItems && data.groupedBarItems.works && data.groupedBarItems.works.length">
                
                <div mat-subheader>
                    <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 14.4H18V10.8H0V14.4ZM1.8 11.7H3.6V13.5H1.8V11.7ZM0 0V3.6H18V0H0ZM3.6 2.7H1.8V0.9H3.6V2.7ZM0 9H18V5.4H0V9ZM1.8 6.3H3.6V8.1H1.8V6.3Z" fill="#686868"/>
                    </svg>
                    {{ "Jobs" | translate }}
                </div>
                <mat-list-item class="short_item" (click)="goToItem(item)" *ngFor="let item of data.groupedBarItems.works">
                    <span class="text_one_line">
                        {{item?.switchData?.name || "no name"}}
                    </span>
                    <mat-icon (click)="$event.stopPropagation(); $event.preventDefault(); removeItem(item)">close</mat-icon>
                </mat-list-item>
            </ng-container>

            <ng-container *ngIf="data.groupedBarItems && data.groupedBarItems.chats && data.groupedBarItems.chats.length">
                <div mat-subheader>
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16.2 0H1.8C1.32261 0 0.864773 0.189642 0.527208 0.527208C0.189642 0.864773 0 1.32261 0 1.8V18L3.6 14.4H16.2C16.6774 14.4 17.1352 14.2104 17.4728 13.8728C17.8104 13.5352 18 13.0774 18 12.6V1.8C18 0.801 17.19 0 16.2 0Z" fill="#686868"/>
                    </svg>
                    {{ "Chats" | translate }}
                </div>
                <mat-list-item class="short_item" (click)="goToItem(item)" *ngFor="let item of data.groupedBarItems.chats">
                    <span class="text_one_line">
                        {{item?.switchData?.name || "no name"}}
                    </span>
                    <mat-icon (click)="$event.stopPropagation(); $event.preventDefault(); removeItem(item)">close</mat-icon>
                </mat-list-item>
            </ng-container>

            <ng-container *ngIf="data.groupedBarItems && data.groupedBarItems.files && data.groupedBarItems.files.length">
                <div mat-subheader>
                    <svg width="9" height="18" viewBox="0 0 9 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.77273 4.09091V13.5C7.77273 14.368 7.42792 15.2004 6.81417 15.8142C6.20041 16.4279 5.36798 16.7727 4.5 16.7727C3.63202 16.7727 2.79959 16.4279 2.18583 15.8142C1.57208 15.2004 1.22727 14.368 1.22727 13.5V3.27273C1.22727 2.73024 1.44278 2.20997 1.82637 1.82637C2.20997 1.44278 2.73024 1.22727 3.27273 1.22727C3.81522 1.22727 4.33548 1.44278 4.71908 1.82637C5.10268 2.20997 5.31818 2.73024 5.31818 3.27273V11.8636C5.31818 12.0806 5.23198 12.2887 5.07854 12.4422C4.9251 12.5956 4.717 12.6818 4.5 12.6818C4.283 12.6818 4.0749 12.5956 3.92146 12.4422C3.76802 12.2887 3.68182 12.0806 3.68182 11.8636V4.09091H2.45455V11.8636C2.45455 12.4061 2.67005 12.9264 3.05365 13.31C3.43724 13.6936 3.95751 13.9091 4.5 13.9091C5.04249 13.9091 5.56276 13.6936 5.94635 13.31C6.32995 12.9264 6.54545 12.4061 6.54545 11.8636V3.27273C6.54545 2.40475 6.20065 1.57232 5.58689 0.95856C4.97314 0.344804 4.14071 0 3.27273 0C2.40475 0 1.57231 0.344804 0.958559 0.95856C0.344804 1.57232 0 2.40475 0 3.27273V13.5C0 14.6935 0.474106 15.8381 1.31802 16.682C2.16193 17.5259 3.30653 18 4.5 18C5.69347 18 6.83807 17.5259 7.68198 16.682C8.52589 15.8381 9 14.6935 9 13.5V4.09091H7.77273Z" fill="#686868"/>
                    </svg>
                    {{ "Files" | translate }}
                </div>
                <mat-list-item class="short_item" (click)="goToItem(item)" *ngFor="let item of data.groupedBarItems.files">
                    <div class="d-f-c" style="width: calc(100% - 30px);">
                        <mat-icon style="color: #686868; display: block; margin-right: 5px; margin-left: 0 !important;" *ngIf="item?.switchData?.is_dir == 1">folder</mat-icon>
                        <span class="text_one_line">
                            {{ (item?.switchData?.is_dir == 1 && item?.switchData?.location == '/' ? (item?.switchData?.filename | translate) : item?.switchData?.filename) }}
                        </span>
                    </div>
                    <mat-icon (click)="$event.stopPropagation(); $event.preventDefault(); removeItem(item)">close</mat-icon>
                </mat-list-item>
            </ng-container>

            <ng-container *ngIf="data.favoriteBookmarks && data.favoriteBookmarks.length">
                <div mat-subheader>
                    <mat-icon style="color: #686868;">bookmarks</mat-icon>
                    {{ "Presets" | translate }}
                </div>
                <mat-list-item class="short_item" (click)="goToBookmark(bookmark)"  *ngFor="let bookmark of data.favoriteBookmarks">
                    <span class="text_one_line">
                        {{bookmark?.name}}
                    </span>
                    <!-- <mat-icon (click)="removeBookmarkItem($event, bookmark)">close</mat-icon> -->
                </mat-list-item>
            </ng-container>

        </mat-list>
    </div>
</mat-dialog-content>