import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { AuthService } from 'src/app/shared/services/rest/auth.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { MY_FORMATS } from '../task-profile-add/task-profile-add.component';
import * as moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/en-gb';
import 'moment/locale/uk';
import 'moment/locale/ru';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { stat } from 'fs';
import { catchError, debounceTime, map, switchMap, tap } from 'rxjs/operators';
import { forkJoin, of, ReplaySubject } from 'rxjs';
import { MembersService } from 'src/app/shared/services/rest/members.service';
import { GlobalDataService } from 'src/app/shared/services/common/global-data.service';
import { UploadService } from 'src/app/shared/services/rest/upload.service';
import { FileService } from 'src/app/shared/services/rest/file.service';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { makeArray } from 'src/app/shared/functions/objToArray';
import { mimeTypes } from 'mime-wrapper';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { environment } from 'src/environments/environment';
import { NgxMatDateAdapter } from '@angular-material-components/datetime-picker';
@Component({
  selector: 'app-work-empl-add',
  templateUrl: './work-empl-add.component.html',
  styleUrls: ['./work-empl-add.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class WorkEmplAddComponent extends BaseClass implements OnInit, OnDestroy {
  public host: any = environment.host;
  public imgRoute: any = '';
  public user: any = {};
  public user_id: number;
  public form: FormGroup;
  public workEmpl: FormGroup;
  public company_id: any;
  public task_id: any;
  public operations: any;
  public currencyList: any;
  public operationStatuses: any;
  public isFormChange: boolean = false;
  public isSubmit: boolean = false;
  public submited: boolean = false;
  public numberRegEx = /\d*\.?\d{1,2}/;
  public showOps: boolean = false;
  public showStatuses: boolean = false;  
  public dropOver: boolean = false;
  public uploadLimit: boolean = false;
  public filesArray: any[] = [];
  
  public operationMoreControl: FormControl = new FormControl();
  public operations$: ReplaySubject<any> = new ReplaySubject<any>(1);
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<WorkEmplAddComponent>,
    private sm: StorageManagerService,
    private auth: AuthService,
    private companyService: CompanyService,
    private fb: FormBuilder,
    private router: Router,
    private uploadService: UploadService,
    private fileService: FileService,
    private globalDataService: GlobalDataService,
    private taskService: TaskService,
    private _adapter: DateAdapter<any>,
    private _ngx_adapter: NgxMatDateAdapter<any>,
    private languageService: LanguageService,
    private layoutService: LayoutService,
    private membersService: MembersService
  ) { super() }

  ngOnInit(): void {
    this.dialogRef.addPanelClass("create_task_by_manager_modal")
    window.scroll(0,0);
    console.log("WorkEmplAddComponent", this.data)

    this.company_id = this.data.task.company_id;
    this.task_id = this.data.task.id;

    this.getCompany();
    this.getImgRoute();
    this.getCsrf();
    this.getUser();

    this.attachSubscriptions(
      this.uploadService.getUploadLimit().subscribe(resp => {
        if (resp || this.data.company.filesize >= this.data.company.filesize_limit) {
          this.uploadLimit = true;
        }
      })
    )

    this.workEmpl = this.fb.group({
      is_manager: 1,
      status_id: 0,
      price_currency_id: this.data.company.employees[0].currency_id
    })

    this.form = this.fb.group({
      task_id: this.task_id,
      operation_id: ['', Validators.required],
      status_id: ['', Validators.required],
      name: '',
      employee_comment: '',
      planned_completed_at: '',
      date: this.fb.group({
        time: '',
        day: ''
      })
    })

    this.attachSubscriptions(
      this.form.get('date').valueChanges.subscribe(resp => {
        console.log(resp)
        let dateForm = {...this.form.get("date").value}
        if (!dateForm.day) {
          dateForm.day = moment(0).hour(0).minute(0)
        }
        let testTimeAndDate = dateForm.day.clone()
        testTimeAndDate.hour(Number(dateForm.time.split(":")[0]))
        testTimeAndDate.minute(Number(dateForm.time.split(":")[1]))
        this.form.patchValue({
          planned_completed_at: testTimeAndDate
        })
        console.log('this.form.value', this.form.value);
        console.log('testTimeAndDate', testTimeAndDate.clone().format("X"));
      })
    )

    if (this.data.company.rate_target != '0.0000') {
      this.workEmpl.addControl("rate", this.fb.control('', Validators.pattern(this.numberRegEx)))
    } else {
      this.workEmpl.addControl("price", this.fb.control('', Validators.pattern(this.numberRegEx)))
    }
    
    this.workEmpl.updateValueAndValidity();

    this.attachSubscriptions(
      this.form.valueChanges.subscribe(() => this.isFormChange = true)
    )
    this.attachSubscriptions(
      this.workEmpl.valueChanges.subscribe(() => this.isFormChange = true)
    )

    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        if (this.isFormChange) {
          this.layoutService.openBottomSheet(this.dialogRef);
        } else {
          this.close();
        }
      })
    )

    this.getOperations();
    this.getOperationsStatus();
    
    this.getCurrencyList();
    this.getLangData();

    this.attachSubscriptions(
      this.operationMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchOperations(resp))
    )
  }

  onSearchOperations(resp) {
    if (!this.operations) {
      return;
    }

    if (!resp) {
      this.operations$.next(this.operations.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.operations$.next(
      this.operations.filter(z => (z.name.toLowerCase().indexOf(resp) > -1))
    );
  }

  getCurrency(id) {
    if (!!this.currencyList) {
      return this.currencyList.find(el => el.id == id)
    }
  }

  getCurrencyList() {
    this.attachSubscriptions(
      this.globalDataService.getCurrencies().subscribe(resp => {
        console.log("getCurrencyList", resp)
        this.currencyList = resp.slice();
      })
    )
    // this.attachSubscriptions(
    //   this.companyService.getCurrencyList().subscribe(resp => {
    //     console.log("getCurrencyList", resp);
    //     this.currencyList = resp
    //   })
    // )
  }

  showMoreOps() {
    this.showOps = true;
  }
  showMoreStatuses() {
    this.showStatuses = true;
  }

  selectType(type) {
    this.form.patchValue({
      operation_id: !!type.id ? type.id : type.value
    })
  }

  selectStatus(status) {
    console.log(status.id)
    if (status.id == 3) {
      this.form.addControl("completed_at", this.fb.control(''));
      this.form.get("completed_at").updateValueAndValidity();
    } else if (!!this.form.get('completed_at')) {
      this.form.removeControl("completed_at");
      this.form.updateValueAndValidity();
    }
    this.form.patchValue({
      status_id: status.id
    })
  }
  
  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData().subscribe(resp => {
        this._ngx_adapter.setLocale(resp.active);
        this._adapter.setLocale(resp.active);
      })
    )
  }
  
  getCompany() {
    this.attachSubscriptions(
      this.companyService.getCompany(this.data.company.id).subscribe(resp => {
        this.companyService.company$.next(resp[0]);
      })
    )
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  getCsrf() {
    this.attachSubscriptions(
      this.auth.$userToken.subscribe(resp => {
        this.user_id = resp.user_id;
        this.sm.localStorageSetItem("csrf_param", resp.csrf_param)
        this.sm.localStorageSetItem("csrf_token", resp.csrf_token)
      })
    )
  }

  getUser() {
    this.attachSubscriptions(
      this.auth.$user.subscribe(resp => {
        this.user = resp;
      })
    )
  }
  
  getOperations() {
    this.attachSubscriptions(
      this.taskService.getOperations(this.data.company.id).subscribe(resp => {
        this.operations = resp;
        this.operations$.next(this.operations.slice())
      })
    )
  }

  getOperationsStatus() {
    this.attachSubscriptions(
      this.taskService.getOperationsStatus().subscribe(resp => {
        let statusesData = resp.slice();
        this.operationStatuses = statusesData.filter(el => el.is_lock == 0)
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  onSubmit() {
    if (!this.form.valid) {
      this.submited = true;
      console.log(this.form);
      return
    }
    this.submited = false;
    this.isSubmit = true;
    if (String(this.workEmpl.value['rate']).indexOf(",") != -1) {
      this.workEmpl.value['rate'] = this.workEmpl.value['rate'].replace(",", ".");
    }
    if (String(this.workEmpl.value['price']).indexOf(",") != -1) {
      this.workEmpl.value['price'] = this.workEmpl.value['price'].replace(",", ".");
    }
    if (!!this.form.value.completed_at && !!this.form.value.completed_at._d) {
      this.form.value.completed_at = moment(this.form.value.completed_at._d).format("X");
    } else {
      delete this.form.value.completed_at;
    }

    if (!!this.form.value.planned_completed_at && !!this.form.value.planned_completed_at._d) {
      this.form.value.planned_completed_at = moment(this.form.value.planned_completed_at._d).format("X");
    } else {
      delete this.form.value.planned_completed_at;
    }
    delete this.form.value.date;

    this.attachSubscriptions(
      this.taskService.addWork(this.data.company.id, this.form.value).pipe(
        switchMap(resp => {
          this.workEmpl.patchValue({
            task_operation_id: resp.id,
            status_id: resp.status_id
          })
          let sbmtData = {...this.workEmpl.value}

          console.log(sbmtData)

          if (sbmtData.price == "null" || sbmtData.price == '') {
            sbmtData.price = "0";
          }
          if (sbmtData.rate == null || sbmtData.rate == '') {
            sbmtData.rate = "0";
          }

          if (this.data.task.comapany_id == this.data.company.id) {
            return this.membersService.getMembers({task_id: this.data.task.id, task_operation_id: resp.id}, this.company_id).pipe(
              switchMap(emStats => {
                if (emStats && emStats[0]) {
                  return this.membersService.editMember(emStats[0].id, sbmtData, this.company_id).pipe(map(() => resp))
                } else {
                  return of(resp)
                }
              })
            )
          } else {
            return this.membersService.getTaskPartners({task_id: this.data.task.id, task_operation_id: resp.id}, this.data.company.id).pipe(
              switchMap(emStats => {
                if (emStats && emStats[0]) {
                  return this.membersService.editTaskPartner(emStats[0].id, sbmtData, this.data.company.id).pipe(map(() => resp))
                } else {
                  return of(resp)
                }
              })
            )
          }
        }),
        switchMap(resp => {
          if (this.filesArray.length > 0) {
            return forkJoin([...this.filesArray.map((param) => this.putFile(param, this.data.task, resp, resp))]).pipe(
              tap(el => console.log("CONCAT TAP", el)),
              map(() => resp)
            )
          } else {
            return of(resp)
          }
        })
      ).subscribe(resp => {
        this.isSubmit = false;
        this.dialogRef.close({event: "update", data: resp});
      }, error => {
        this.isSubmit = false;
        this.layoutService.showSnackBar({name: ''}, `You do not have permission to do this. ${error}`, SnackBarItem)
      })
    )
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }


   // FOR FILES

   deleteFile(ind) {
    this.filesArray.splice(ind, 1)
  }

  putFile(file, task, task_operation, res) {
    return this.fileService.uploadFile({
      company_id: this.data.company.id,
      task_id: task.id,
      task_operation_id: task_operation.id,
      filesize: file.size,
      filename: file.name,
      content_type: file.type ? file.type : mimeTypes.getType(file.name),
      location: '/to_approve',
      is_dir: 0
    }, this.data.company.id).pipe(
      tap(resp => {
        this.fileService.files$.next({
          place: "file_manager",
          url: window.location.href,
          data: resp,
          files: [],
          location: '/to_approve',
          target: file,
          user: this.data.user,
          task: task,
          work: task_operation,
          activeLang: this.data.activeLang,
          operationsValues: this.data.operationsValues,
          task_id: task.id,
          task_operation_id: task_operation.id,
          company: this.data.company,
          company_id: this.data.company.id
        })
      }),
      catchError(error => {
        if (error == "Company limit exceeded") {
          this.layoutService.showSnackBar({name: ''}, marker("This company has exceeded the file upload limit."), SnackBarItem)
        } else {
          this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
        }

        return of(res)
      })
    )
  }

   onFileDropped(e) {
    this.dropOver = false;
    if (this.uploadLimit) {
      this.layoutService.showSnackBar({name: ''}, marker("This company has exceeded the file upload limit."), SnackBarItem)
      return
    }
    if (!e.dataTransfer.files.length) {
      return false;
    }

    this.getFilesDataTransferItems(e.dataTransfer.items).then((files:any) => {
      if (files.length > 0) {
        console.log("onFileDropped", files);
        files.forEach(el => {
          if (el.type.indexOf("image") != -1 || mimeTypes.getType(el.name).indexOf("image") != -1) {
            el.is_compress = false;
            el.dontCompress = true;
          } else {
            el.is_compress = false;
            el.dontCompress = true;
          }
          const reader = new FileReader();
          reader.onload = () => {
            el.src = reader.result as string;
          }
          reader.readAsDataURL(el)
        })

        this.filesArray.push(...files)
      }
    });

    if (this.data.company.filesize >= this.data.company.filesize_limit) {
      this.uploadService.uploadLimit$.next(true)
    }
  }

  uploadFile(event) {
    if (this.uploadLimit) {
      this.layoutService.showSnackBar({name: ''}, marker("This company has exceeded the file upload limit."), SnackBarItem)
      return
    }
    if (event.target.files.length > 0) {
      let files = makeArray(event.target.files)
      if (files.length > 0) {
        console.log("uploadFile", files)
        files.forEach(el => {
          if (el.type.indexOf("image") != -1 || mimeTypes.getType(el.name).indexOf("image") != -1) {
            el.is_compress = false;
            el.dontCompress = true;
          } else {
            el.is_compress = false;
            el.dontCompress = true;
          }
          const reader = new FileReader();
          reader.onload = () => {
            el.src = reader.result as string;
          }
          reader.readAsDataURL(el)
        })
        this.filesArray.push(...files)
      }
    } 
  }


  getFilesDataTransferItems(dataTransferItems) {
    function traverseFileTreePromise(item, folder) {
      return new Promise(resolve => {
        if (item.isFile) {
          item.file(file => {
            file.filepath = item.fullPath.replace(`/${file.name}`, "");
            folder.push(file);
            resolve(file);
          });
        } else if (item.isDirectory) {
          let dirReader = item.createReader();
          dirReader.readEntries(entries => {
            let entriesPromises = [];
            for (let entr of entries)
              entriesPromises.push(
                traverseFileTreePromise(entr, folder)
              );
            resolve(Promise.all(entriesPromises));
          });
        }
      });
    }

    let files = [];
    return new Promise((resolve, reject) => {
      let entriesPromises = [];
      for (let it of dataTransferItems)
        entriesPromises.push(
          traverseFileTreePromise(it.webkitGetAsEntry(), files)
        );
      Promise.all(entriesPromises).then(entries => {
        resolve(files);
      });
    });
  }

  detectBrowserName() { 
    const agent = window.navigator.userAgent.toLowerCase()
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }
  
  understandFileType(val) {
    if (!val) {
      return ""
    }
    
    if (val == 'application/pdf') {
      return 'pdf'
    } else if (val.indexOf("/") != -1) {
      return val.split('/')[0]
    } else {
      return ""
    }
  }

  compress(initFile, i) {
    const reader = new FileReader();
    reader.readAsDataURL(initFile);
    reader.onload = event => {
      let img = new Image() as any;
      img.src = event.target.result;

      img.onload = () => {
        const elem = document.createElement('canvas');
        elem.width = img.width*0.75;
        elem.height = img.height*0.75;
        const ctx = elem.getContext('2d');
        initFile.src = reader.result as string;

        // img.width и img.height будет содержать оригинальные размеры
        ctx.drawImage(img, 0, 0, img.width*0.75, img.height*0.75);
        ctx.canvas.toBlob((blob) => {
          const file = new File([blob], initFile.name, {
            type: initFile.type,
            lastModified: Date.now()
          });
          if (initFile.size > file.size) {
            this.filesArray[i] = file
          }

          if (this.filesArray.lastIndexOf(this.filesArray.find(x => x.is_compress == true)) == i) {
            this.isSubmit = false;
            // this.createChat()
          }

        }, initFile.type, 0.75);
      };

      reader.onerror = error => console.log(error);
    };
  }

  // END FOR FILES
}
