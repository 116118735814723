import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({ selector: '[swipe]' })
export class SwipeDirective {

  @Output() next = new EventEmitter<void>();
  @Output() previous = new EventEmitter<void>();

  swipeCoord = [0, 0];
  swipeTime = new Date().getTime();
  zoomActive = false;

  constructor() { }

  @HostListener('touchstart', ['$event']) onSwipeStart($event) {
    this.onSwipe($event, 'start');
  }

  @HostListener('touchend', ['$event']) onSwipeEnd($event) {
    this.onSwipe($event, 'end');
  }

  onSwipe(e: TouchEvent, when: string) {
    if (this.zoomActive) return; // Не обрабатывать свайп, если активен зум
    this.swipe(e, when);
  }

  swipe(e: TouchEvent, when: string): void {
    const coord: [number, number] = [e.changedTouches[0].clientX, e.changedTouches[0].clientY];
    const time = new Date().getTime();

    if (when === 'start') {
      this.swipeCoord = coord;
      this.swipeTime = time;
    } else if (when === 'end') {
      const direction = [coord[0] - this.swipeCoord[0], coord[1] - this.swipeCoord[1]];
      const duration = time - this.swipeTime;

      if (duration < 1000 &&
          Math.abs(direction[0]) > 30 &&
          Math.abs(direction[0]) > Math.abs(direction[1] * 3)) {
        const swipeDir = direction[0] < 0 ? 'next' : 'previous';
        if (e.touches.length == 0) {
          if (swipeDir === 'next') {
            this.next.emit();
          } else {
            this.previous.emit();
          }
        }
      }
    }
  }
}
