<h2 mat-dialog-title class="dialog_title">
    <span>
        {{ "You are invited to be a partner in the workspace" | translate }}: {{data?.partner?.company?.name}}
    </span>
    <button mat-icon-button class="ml-1" (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h2>
<mat-dialog-content class="mat-typography">
    <div class="container invite_container">
        <ng-container *ngIf="!!!data.user?.id; else elseTemplateLogged">
            <h4>
                {{ "You need to log in" | translate }}
            </h4>
        </ng-container>
        <ng-template #elseTemplateLogged>
            <ng-container *ngIf="companyes?.length; else elseTemplateCompBlock">
                <form [formGroup]="form">
                    <mat-form-field appearance="standard" class="full_width_form">
                        <mat-label>{{ "Choose a workspace" | translate }}</mat-label>
                        <mat-select formControlName="company_id" required>
                        <mat-option *ngFor="let company of companyes" [value]="company.id">
                            {{ company.name }}
                        </mat-option>
                        </mat-select>
                    </mat-form-field>
                </form>
            </ng-container>
            <ng-template #elseTemplateCompBlock>
                <h4>
                    {{ 'First, create a workspace' | translate }}
                </h4>
            </ng-template>
            
        </ng-template>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-raised-button (click)="close()">
        {{ "Close" | translate }}
    </button>
    <ng-container *ngIf="!!data.user?.id">
        <button class="ml-1" mat-raised-button color="primary" (click)="acceptInvite()" [disabled]="!form.valid">
            <mat-icon>check</mat-icon>
            {{ "Accept" | translate }}
        </button>
    </ng-container>
  
    <button *ngIf="!!!data.user?.id" (click)="login()" class="ml-1" mat-raised-button cdkFocusInitial color="primary">
        {{ "Log_in" | translate }}
    </button>
</mat-dialog-actions>
