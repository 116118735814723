import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-projects-interface',
  templateUrl: './projects-interface.component.html',
  styleUrls: ['./projects-interface.component.scss']
})
export class ProjectsInterfaceComponent implements OnInit {

  constructor(    
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ProjectsInterfaceComponent>,
  ) { }

  ngOnInit(): void {
    this.data.dialogHasFm = true;
    console.log("ProjectsInterfaceComponent", this.data)
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }
  
}
