<div class="cases_page tasks_page">
    <div class="container">
      <div class="filter_out">  
        <div class="filter_values">
          <mat-chip-list class="chips_wrp without_ordered start_page_chips">
  
            <mat-chip (removed)="removeValue('q')" *ngIf="filter.get('q').value">
                {{ "Search" | translate }}: {{filter.get('q').value}}
              <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
            </mat-chip>

            <mat-chip (removed)="removeValue('department_id')" *ngIf="filter?.get('department_id')?.value">
                {{ "Department" | translate }}: {{ getDepById(filter?.get('department_id')?.value)?.name }}
                <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
            </mat-chip>

            <mat-chip (removed)="removeValue('department_position_id')" *ngIf="filter?.get('department_position_id')?.value">
                {{ "Position" | translate }}: {{ getPosById(filter?.get('department_position_id')?.value)?.name }}
                <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
            </mat-chip>
            
            <mat-chip (removed)="removeValue('permission')" *ngIf="filter?.get('permission')?.value">
                {{ "Permission" | translate }}: {{ filter?.get('permission')?.value }}
                <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
            </mat-chip>

            <mat-chip (removed)="removeValue('group_id')" *ngIf="filter?.get('group_id')?.value">
                {{ "Group" | translate }}: {{ getGroupById(filter?.get('group_id')?.value)?.name }}
                <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
            </mat-chip>
  
            <!-- <mat-chip (removed)="removeValue('content_type_id')" *ngIf="filter.get('content_type_id').value">
              <ng-container *ngIf="filter.get('content_type_id').value.toString() == '0'; else elseTemplateZeroContent">
                  {{ "Content Type" | translate }}: {{ "Not set" | translate }}
              </ng-container>
              <ng-template #elseTemplateZeroContent>
                  {{ "Content Type" | translate }}: {{ getContentById(filter.get('content_type_id').value)?.name }}
              </ng-template>
           
              <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
          </mat-chip>
  
          <ng-container *ngIf="filter.get('channel_id').value.length">
            <span class="chip_out_label">{{ "Outlets" | translate }}</span>
            <mat-chip [ngClass]="{'user_chip': id != 0}" *ngFor="let id of filter.get('channel_id').value" (removed)="removeValueFromMultiple('channel_id', id)">
              <ng-container *ngIf="id != 0; else elseTemplateOutletNotSet">
                <app-outlet-line [channel]="getChannelById(id)" [company_id]="company_id"></app-outlet-line>
              </ng-container>
              <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
            </mat-chip>
          </ng-container> -->
           
          <!-- || filter.get('id').value || filter.get('content_type_id').value || filter.get('channel_id').value.length -->
            <mat-icon *ngIf="filter.get('q').value || filter?.get('department_id')?.value || filter?.get('department_position_id')?.value || filter?.get('permission')?.value || filter?.get('group_id')?.value" class="remove_all_chips_btn" (click)="removeValue('all')">cancel</mat-icon>
          </mat-chip-list>
  
          <div class="stats_wrp ml-1">
            <div class="tasks_counters">
              
              <!-- <form [formGroup]="filter" class="preset_form" *ngIf="bookmarks && bookmarks.length > 0">
                <div class="simple_filter_snippet" (click)="presetFilter.open()">
                  <ng-container *ngIf="filter.get('preset_id').value; else elseTemplatePreset">
                    <span class="name">
                      {{ getBookmarkById(filter.get('preset_id').value)?.name }}
                    </span>
                  </ng-container>
                  <ng-template #elseTemplatePreset>
                    <span class="name">
                      {{ "Bookmark" | translate }}
                    </span>
                  </ng-template>
                  
  
                  <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_2474_38987)">
                    <path d="M7.91699 3.33325L2.08366 3.33325L5.00033 7.91658L7.91699 3.33325Z" fill="black"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_2474_38987">
                    <rect width="10" height="10" fill="white" transform="translate(10) rotate(90)"/>
                    </clipPath>
                    </defs>
                  </svg>
                </div>
  
                <mat-form-field appearance="legacy" class="filter_form preset_filter">
                  <mat-label>{{ "Bookmark" | translate }}</mat-label>
                  <mat-select #presetFilter [disableOptionCentering]="true" selectDir formControlName="preset_id" (selectionChange)="bookmarksChange($event)">
                      <mat-option *ngIf="bookmarks.length > 7">
                        <ngx-mat-select-search 
                            placeholderLabel="{{ 'Search' | translate }}" 
                            noEntriesFoundLabel="{{ 'no matching presets found' | translate }}"
                            [formControl]="bookmarksControl"></ngx-mat-select-search>
                      </mat-option>
                      <mat-option *ngFor="let bookmark of bookmarks$ | async" [value]="bookmark.id">
                        {{ bookmark.name | translate }}
                      </mat-option>
                  </mat-select>
                </mat-form-field>
              </form> -->
  


              <div class="calendar_head">
                <div class="current_calendar_date">
                  {{ viewDate | calendarDate:(view.value + 'ViewTitle'):activeLang:1 }}
                </div>
    
    
    
                <div class="calendar_btns">
                  <button class="ml-1"
                  mat-mini-fab 
                  color="primary" 
                  (click)="changeDate('prev')">
                    <mat-icon>navigate_before</mat-icon>
                  </button>
    
                  <button class="ml-1"
                  mat-mini-fab 
                  color="primary" 
                  (click)="changeDate('next')">
                    <mat-icon>navigate_next</mat-icon>
                  </button>
    
                  <button class="ml-1"
                  mwlCalendarToday
                  (click)="changeDate('today')"
                  mat-flat-button>
                    {{ "Today" | translate }}
                  </button>
    
                </div>
              </div>

              <mat-button-toggle-group class="view_group calendar_btn_group" (change)="setView($event)" [value]="view.value">
                <mat-button-toggle [value]="CalendarView.Week">{{ "Week" | translate }}</mat-button-toggle>
                <mat-button-toggle [value]="CalendarView.Month">{{ "Month" | translate }}</mat-button-toggle>
              </mat-button-toggle-group>
              <!-- <div class="counter_block" *ngIf="groupsPagination && groupsPagination['totalCount']">
                <div class="counter_top">{{ "Groups" | translate }}</div>
                <div class="counter_bottom">{{groupsPagination['totalCount']}}</div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
  
      <div class="lists_wrp">
      </div>   
    </div>
</div>
<div class="container centred_page">
  <div class="step">
    <div class="schedule_table">
      <table class="">
        <thead>
          <tr>
            <th class="user_td" (click)="log()">{{"Member" | translate}}</th>
            <th class="hour_td" [ngClass]="{'today': today == day?.from}" *ngFor="let day of (view.value == 'week' ? weekViewDays : monthViewDays)">{{ day?.timestamp | date:'dd.MM.yyyy EEEE':'':activeLang | yearPipe }}</th>
          </tr>
          <tr>
            <th class="user_td" (click)="log()">&nbsp;</th>
            <th class="hour_td" [ngClass]="{'today': today == day?.from}" *ngFor="let day of (view.value == 'week' ? weekViewDays : monthViewDays)">
              <div class="hour-container">
                <div class="hour" *ngFor="let hour of hoursList">{{ hour }}</div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let element of employees; let i = index;">
            <td class="user_td">
              <div class="user_block">
                <div class="icon">
                    <div [appHoverUserInfo]="{ user: element, company_id: company_id }">
                    <ng-container *ngIf="!!element?.image; else elseTemplateUserHasDataImage">
                        <img class="user_avatar" [src]="element?.image" alt="">
                    </ng-container>
                    <ng-template #elseTemplateUserHasDataImage>
                        <mat-icon>account_circle</mat-icon>
                    </ng-template>
                    </div>
                </div>
                <span class="text_one_line">{{ element?.name }}</span>
              </div>
            </td>
            <td class="hour_td" [ngClass]="{'today': today == day?.from}" *ngFor="let day of element?.table; let k = index;">

              <div class="hour-container">
                <ng-container *ngFor="let schedule of element?.schedules">
                  <div class="tippy_out" #tippyTemplate>
                    <div class="interval">
                        <div class="from">{{schedule.from_time*1000|date:'hh:mm a':'':activeLang}}</div>
                        <div class="ft_spacer">-</div>
                        <div class="to">{{schedule.to_time*1000|date:'hh:mm a':'':activeLang}}</div>
                        <div class="duration">({{(schedule.to_time - schedule.from_time) | timeFormat:true}})</div>
                    </div>
                  </div>
                  <div
                    class="green-strip" (click)="logStrip(schedule)" [ngxTippy]="tippyTemplate"
                    [ngStyle]="{
                      'left': getStripPosition(day, schedule).left + '%',
                      'width': getStripPosition(day, schedule).width + '%'
                    }"
                  ></div>
                </ng-container>
              
                <div class="hour" *ngFor="let hour of hoursList">&nbsp;</div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>