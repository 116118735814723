<div class="bottom-sheet">
    <ng-container *ngIf="data.msg; else elseTemplate">
        <div class="mr-1">
            <div>
                {{data.msg}}
            </div>
            <div *ngIf="data.msg_1">
                {{data.msg_1}}
            </div>
        </div>
    </ng-container>
    <ng-template #elseTemplate>
        <div>{{ 'You definitely want to delete' | translate }} {{ data.target | translate }}</div>
    </ng-template>
    <button mat-raised-button type="button" color="warn" *ngIf="!data?.not_show_no" (click)="no()">
        {{ (!!data.btn_no ? data.btn_no : "Cancel") | translate }}
    </button>
    <button mat-raised-button class="change" #btnFocus cdkFocusInitial type="submit" color="primary" (click)="yes()">
        {{ (!!data.btn_yes ? data.btn_yes : "Delete") | translate }}
    </button>
    <mat-checkbox class="ml-1" *ngIf="!data.btn_yes" color="primary" [(ngModel)]="is_delete_without_alert">{{"Don`t ask again" | translate}}</mat-checkbox>
</div>