<canvas class="blur_bg" [id]="'bg_'+part.pId"  *ngIf="part.has_blur_bg"></canvas>
<div #box class="resizable-draggable no-select-text" 
    [class.cropOn]="part.crop_on"
    [style.width.px]="part.rd.width" 
    [style.height.px]="part.rd.height" 
    [style.left.px]="part.rd.left" 
    [style.top.px]="part.rd.top" 
    [style.transform]="'rotate(' + part.rd.deg + 'deg)'"
    [class.active]="status === 1 || status === 2 || status === 3"
    (mousedown)="part.crop_on ? setStatus($event, 2) : null"
    (window:mouseup)="part.crop_on ? setStatus($event, 0) : null"
>
    <div *ngIf="part.crop_on" class="resize-action" (mousedown)="setStatus($event, 1)"></div>
    <div #rotate class="rotate-action" [ngClass]="{'not-show': !part.crop_on}" (mousedown)="setStatus($event, 3)"></div>
    <!-- <div class="center" *ngIf="part.crop_on"
    [style.left.px]="center.x" 
    [style.top.px]="center.y"></div> -->
    <!-- <div *ngIf="part.crop_on" class="axis x_line" [style.transform]="'rotate(' + -(part.rd.deg) + 'deg)'"></div>
    <div *ngIf="part.crop_on" class="axis y_line" [style.transform]="'rotate(' + -(part.rd.deg) + 'deg)'"></div> -->
    <!-- <div #pointTopLeft [ngClass]="{'not-show': !part.crop_on}" class="point top left"></div>
    <div #pointTopRight [ngClass]="{'not-show': !part.crop_on}" class="point top right"></div>
    <div #pointBottomLeft [ngClass]="{'not-show': !part.crop_on}" class="point bottom left"></div>
    <div #pointBottomRight [ngClass]="{'not-show': !part.crop_on}" class="point bottom right"></div> -->
    <!-- <span>{{part.rd.width | number:'1.0-0'}}px</span>
    <span>{{part.rd.height | number:'1.0-0'}}px</span>
    <span class="xy">(x:{{part.rd.left}}, y:{{part.rd.top}})</span> -->
    <video 
    [ngStyle]="part?.video_filters_on ? {
        'filter': 'brightness(' + part?.video_filters?.brightness + ') ' +
            'contrast(' + cssContrast(part) + '%) ' +
            'saturate(' + part?.video_filters?.saturation + '%) ' +
            'grayscale(' + part?.video_filters?.grayscale + '%) ' +
            'hue-rotate(' + part?.video_filters?.hue + 'deg)'
      } : {'filter': 'unset'}"
    #video 
    height="100%" 
    width="100%" 
    [id]="'video_'+part.pId" 
    class="player" 
    [src]="!!part?.blobSrc ? part?.blobSrc : (host + (part?.preview1080 ? part?.preview1080 : part?.original) + '?company_id=' + company_id)"
    (loadedmetadata)="loadedVideo($event)" 
    (timeupdate)="loadBlurBg($event)"
    >
        Your browser doesn't support HTML5 video tag.
    </video>
    <div [ngClass]="{'not-show': !part.rd.deg}" class="black_sq" #black_sq [style.transform]="'rotate(' + -(part.rd.deg) + 'deg)'" [style.left.px]="-(max_side - part.rd.width)/2" [style.top.px]="-(max_side - part.rd.height)/2" [style.width.px]="max_side" [style.height.px]="max_side"></div>
    <div class="curr_time" (click)="log()">{{video?.currentTime | veTime}}</div>
    <!-- | {{'brightness(' + part?.video_filters?.brightness + '%) contrast(' + part?.video_filters?.contrast + '%) saturate(' + part?.video_filters?.saturation + '%) grayscale(' + part?.video_filters?.grayscale + '%) hue-rotate(' + part?.video_filters?.hue + 'deg);'}} -->

    <!-- <div class="sub" style="position: absolute; z-index: 2; left: 0; top: 0; background: rgba(0, 0, 0, 0.5);">
        currentTimeProject: {{currentTimeProject}}
        <hr>
        currentCutTimeProject: {{currentCutTimeProject}}
        <hr>
        isProjectPlaying: {{isProjectPlaying}}
        <hr>
        verticalLine: {{verticalLine|json}}
        <hr>
        part.from: {{part.from}}
        <hr>
        part.prevAllDuration: {{part.prevAllDuration}}
        <hr>

        
        <pre *ngIf="changedImg && changedImg.crop_on">
            {{changedImg.rd | json}}
        </pre>

    </div> -->


    <ng-container *ngFor="let house of imgs; let k = index;">
        <ng-container *ngIf="(!isProjectPlaying && !!verticalLine?.show) ? ((currentCutTimeProject === 0 && verticalLine.val >= house.from && verticalLine.val < house.to)) : ((currentCutTimeProject === 0 && currentTimeProject >= house.from && currentTimeProject < house.to) || (currentCutTimeProject !== 0 && currentCutTimeProject >= house.from && currentCutTimeProject < house.to))">
            <ng-container *ngFor="let img of house.arr">
                <div class="rd_img"
                *ngIf="(!isProjectPlaying && !!verticalLine?.show) ? ((currentCutTimeProject === 0 && verticalLine.val >= img.from && verticalLine.val < img.to)) : ((currentCutTimeProject === 0 && currentTimeProject >= img.from && currentTimeProject < img.to) || (currentCutTimeProject !== 0 && currentCutTimeProject >= img.from && currentCutTimeProject < img.to))"
                [id]="'img_out_'+img.pId" 
                (click)="logImg(img)" (dblclick)="toggleImgChange($event, img)"
                [class.cropOn]="img.crop_on"
                (mousedown)='onWindowPressImg($event, img)'
                (mousemove)='onWindowDragImg($event)'
                [ngStyle]="{
                    'width': img?.rd?.width+'px',
                    'height': img?.rd?.height+'px',
                    'top': img?.rd?.top+'px',
                    'left': img?.rd?.left+'px',
                    'transform': 'rotate(' + img?.rd?.deg + 'deg)'
                }"><img style="max-width: 100%; max-height: 100%;" [ngStyle]="{'opacity': img.opacity || 1}" [src]="host + img.original + '?company_id=' + company_id">
                    <ng-container *ngIf="!!img?.crop_on">
                        <div (mousedown)='onCornerClickImg($event, topLeftResizeImg, img)' id='rd_img-top-left-resize' class='rd_img-corner-resize'></div>
                        <div (mousedown)='onCornerClickImg($event, topRightResizeImg, img)' id='rd_img-top-right-resize' class='rd_img-corner-resize'></div>
                        <div (mousedown)='onCornerClickImg($event, bottomLeftResizeImg, img)' id='rd_img-bottom-left-resize' class='rd_img-corner-resize'></div>
                        <div (mousedown)='onCornerClickImg($event, bottomRightResizeImg, img)' id='rd_img-bottom-right-resize' class='rd_img-corner-resize'></div>
                    </ng-container>
                    <!-- <div class="rotate-img_circle" [ngClass]="{'not-show': !img.crop_on}" (mousedown)="rotateImg($event, onRotateImg, img)"></div> -->
                </div>
            </ng-container>
        </ng-container>
    </ng-container>

    <ng-container *ngFor="let house of texts; let k = index;">
        <ng-container *ngIf="(!isProjectPlaying && !!verticalLine?.show) ? ((currentCutTimeProject === 0 && verticalLine.val >= house.from && verticalLine.val < house.to)) : ((currentCutTimeProject === 0 && currentTimeProject >= house.from && currentTimeProject < house.to) || (currentCutTimeProject !== 0 && currentCutTimeProject >= house.from && currentCutTimeProject < house.to))">
            <ng-container *ngFor="let text of house.arr">
                <div class="rd_text_draw" (dblclick)="toggleTextDrawChange($event, text)"
                *ngIf="(!isProjectPlaying && !!verticalLine?.show) ? ((currentCutTimeProject === 0 && verticalLine.val >= text.from && verticalLine.val < text.to)) : ((currentCutTimeProject === 0 && currentTimeProject >= text.from && currentTimeProject < text.to) || (currentCutTimeProject !== 0 && currentCutTimeProject >= text.from && currentCutTimeProject < text.to))"
                [ngClass]="{'edited_pos': !!text?.settings?.pos?.is_edited}"
                (mousedown)='onWindowPressDraw($event, text)'
                (mousemove)='onWindowDragDraw($event)'
                [ngStyle]="{
                    '-webkit-text-stroke-width': text?.settings?.outline*project?.percentX+'px' || '2px',
                    '-webkit-text-stroke-color': text?.settings?.borderColor,
                    'font-size': (text?.settings?.fontSize*project?.percentX+'px') || '84px',
                    'color': text?.settings?.fontColor || '#fff',
                    'font-style': !!text?.settings?.italic ? 'italic' : 'normal',
                    'font-weight': !!text?.settings?.bold ? 'bold' : 'normal',
                    'top': text?.settings?.pos?.y+'px',
                    'left': text?.settings?.pos?.x+'px',
                    'background': text?.settings?.box ? text?.settings.boxColor : '',
                    'width': text?.settings?.pos?.width+'px',
                    'height': text?.settings?.pos?.height+'px'
                }">{{text.value}}<ng-container *ngIf="!!text?.settings?.pos?.is_edited">
                        <div (mousedown)='onCornerClickDraw($event, topLeftResizeDraw, text)' id='rd_text_draw-top-left-resize' class='rd_text_draw-corner-resize'></div>
                        <div (mousedown)='onCornerClickDraw($event, topRightResizeDraw, text)' id='rd_text_draw-top-right-resize' class='rd_text_draw-corner-resize'></div>
                        <div (mousedown)='onCornerClickDraw($event, bottomLeftResizeDraw, text)' id='rd_text_draw-bottom-left-resize' class='rd_text_draw-corner-resize'></div>
                        <div (mousedown)='onCornerClickDraw($event, bottomRightResizeDraw, text)' id='rd_text_draw-bottom-right-resize' class='rd_text_draw-corner-resize'></div>
                    </ng-container>
                </div>
            </ng-container>
        </ng-container>
    </ng-container>
    
    
    <ng-container *ngIf="!hasCropOn && !!part?.subs_on && !!part?.subs">
        <div class="subs" 
        [ngClass]="{'edited_pos': !!part?.subSet?.pos?.is_edited}"
        [style.top.px]='part?.subSet?.pos?.y'
        [style.left.px]='part?.subSet?.pos?.x'
        [style.width.px]='part?.subSet?.pos?.width'
        [style.minHeight.px]='part?.subSet?.pos?.height'
        (mousedown)='onWindowPress($event)'
        (mousemove)='onWindowDrag($event)'
        [ngStyle]="{
            '-webkit-text-stroke-width': part?.subSet?.outline*project?.percentX+'px' || '2px',
            '-webkit-text-stroke-color': part?.subSet?.outlineColor,
            'font-size': (part?.subSet?.fontSize*project?.percentX+'px') || '84px',
            'color': part?.subSet?.pastColor || '#fff',
            'font-style': !!part?.subSet?.italic ? 'italic' : 'normal',
            'font-weight': !!part?.subSet?.bold ? 'bold' : 'normal'
        }">
            <ng-container *ngIf="!!part?.subSet?.pos?.is_edited">
                <div (mousedown)='onCornerClick($event, topLeftResize)' id='subs-top-left-resize' class='subs-corner-resize'></div>
                <div (mousedown)='onCornerClick($event, topRightResize)' id='subs-top-right-resize' class='subs-corner-resize'></div>
                <div (mousedown)='onCornerClick($event, bottomLeftResize)' id='subs-bottom-left-resize' class='subs-corner-resize'></div>
                <div (mousedown)='onCornerClick($event, bottomRightResize)' id='subs-bottom-right-resize' class='subs-corner-resize'></div>
            </ng-container>
 
            <ng-container *ngFor="let item of part.subs">
                <div class="sub" *ngIf="(!isProjectPlaying && !!verticalLine?.show) ? ((currentCutTimeProject === 0 && verticalLine.val - part.prevAllDuration >= useSpeedRate(+item.from - +part.from, part) && verticalLine.val - part.prevAllDuration < useSpeedRate(+item.to - +part.from, part))) : ((currentCutTimeProject === 0 && currentTimeProject - part.prevAllDuration >= useSpeedRate(+item.from - +part.from, part) && currentTimeProject - part.prevAllDuration < useSpeedRate(+item.to - +part.from, part)) || (currentCutTimeProject !== 0 && currentCutTimeProject - part.prevAllDuration >= useSpeedRate(+item.from - +part.from, part) && currentCutTimeProject - part.prevAllDuration < useSpeedRate(+item.to - +part.from, part)))">
                    <ng-container *ngFor="let word of item.words; let wInd = index; let last = last">
                        <ng-container *ngIf="word.value == '#n#'; else elseTemplateSplitRD">
                            <span class="next_line rd_next_line" [ngStyle]="{'height': shouldApplyHeight(wInd, item.words) ? ((part?.subSet?.fontSize*project?.percentX+'px') || '84px') : '0px'}"></span>
                        </ng-container>
                        <ng-template #elseTemplateSplitRD>
                            <ng-container *ngIf="(!isProjectPlaying && !!verticalLine?.show) ? ((currentCutTimeProject === 0 && verticalLine.val - part.prevAllDuration >= useSpeedRate(+word.from - +part.from, part) && verticalLine.val - part.prevAllDuration < useSpeedRate(+word.to - +part.from, part))) : ((currentCutTimeProject === 0 && currentTimeProject - part.prevAllDuration >= useSpeedRate(+word.from - +part.from, part) && currentTimeProject - part.prevAllDuration < useSpeedRate(+word.to - +part.from, part)) || (currentCutTimeProject !== 0 && currentCutTimeProject - part.prevAllDuration >= useSpeedRate(+word.from - +part.from, part) && currentCutTimeProject - part.prevAllDuration < useSpeedRate(+word.to - +part.from, part))); else elseTemplateActiveWord">
                                <span class="sub_word active_word" 
                                [ngStyle]="{ 
                                    'margin-right': last ? '0' : '0.25em',
                                    'color': !!part?.subSet?.is_karaoke ? part?.subSet?.activeColor : part?.subSet?.pastColor,
                                    'text-decoration': !!part?.subSet?.underline ? 'underline '+ part?.subSet?.activeColor : (!!part?.subSet?.strike ? 'line-through ' + part?.subSet?.activeColor : 'none')
                                }">{{word.value}}</span>
                            </ng-container>
                            <ng-template #elseTemplateActiveWord>
                                <ng-container *ngIf="(!isProjectPlaying && !!verticalLine?.show) ? ((currentCutTimeProject === 0 && verticalLine.val - part.prevAllDuration < useSpeedRate(+word.to - +part.from, part))) : ((currentCutTimeProject === 0 && currentTimeProject - part.prevAllDuration < useSpeedRate(+word.to - +part.from, part)) || (currentCutTimeProject !== 0 && currentCutTimeProject - part.prevAllDuration < useSpeedRate(+word.to - +part.from, part))); else elseTemplateFutureWord">
                                    <span class="sub_word future_word" 
                                    [ngStyle]="{ 
                                        'margin-right': last ? '0' : '0.25em',
                                        'color': !!part?.subSet?.is_karaoke ? part?.subSet?.futureColor : part?.subSet?.pastColor,
                                        'text-decoration': !!part?.subSet?.underline ? 'underline '+ part?.subSet?.futureColor : (!!part?.subSet?.strike ? 'line-through ' + part?.subSet?.futureColor : 'none')
                                    }">{{word.value}}</span>
                                </ng-container>
                                <ng-template #elseTemplateFutureWord>
                                    <span class="sub_word past_word" 
                                    [ngStyle]="{ 
                                        'margin-right': last ? '0' : '0.25em',
                                        'color': part?.subSet?.pastColor,
                                        'text-decoration': !!part?.subSet?.underline ? 'underline '+ part?.subSet?.pastColor : (!!part?.subSet?.strike ? 'line-through ' + part?.subSet?.pastColor : 'none')
                                    }">{{word.value}}</span>
                                </ng-template>
                            </ng-template>
                        </ng-template>
                    </ng-container>
                </div>
            </ng-container>
        </div>
    </ng-container>
    
</div>