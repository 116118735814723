import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeDotsFormat'
})
export class TimeDotsFormatPipe implements PipeTransform {
  transform(value: number) {
    if (!value) {
      return ""
    }
    
    let newValue = "";
    var hoursLeft = Math.floor( value / 3600 );
    var min = Math.floor(( value - hoursLeft * 3600 ) / 60 );
    var secondsLeft = value - hoursLeft * 3600 - min * 60;
    secondsLeft = Math.round( secondsLeft * 100 ) / 100;


    if (hoursLeft) {
      newValue += hoursLeft + '.'
      if (min) {
        if (min < 10) {
          newValue += '0' + min
        } else {
          newValue += min
        }
        if (secondsLeft) {
          if (secondsLeft > 30) {
            let minutes:any = newValue.split('.')[1];
            minutes = +minutes + 1

            newValue = newValue.split('.')[0] + '.';

            if (minutes < 10) {
              newValue += '0' + minutes
            } else {
              newValue += minutes
            }
          }
        }
      } else {
        if (secondsLeft) {
          if (secondsLeft > 30) {
            newValue += '01'
          } else {
            newValue += '00'
          }
        }
      }
    } else {
      if (min) {
        newValue += min + ":"
  
        if (secondsLeft) {
          if (secondsLeft < 10) {
            newValue += '0' + secondsLeft
          } else {
            newValue += secondsLeft
          }
        }
      } else {
        if (secondsLeft) {
          newValue += secondsLeft
        }
      }
    }

    


    return newValue
  }
}


// const formatterEN = new Intl.NumberFormat("en", {
//   style: "unit",
//   unit: "year", 
//   unitDisplay: "long"
// }) 