<mat-dialog-content class="mat-typography">
    <mat-tab-group [selectedIndex]="activeIndex" (selectedIndexChange)="changeTab($event)">
        <mat-tab>
            <ng-template mat-tab-label>
                {{ "Own workspace" | translate }}
            </ng-template>
            <div class="container form_wrapper" [appDeviceClass]="'custom-scroll custom-scroll-t'">
                <div class="title_modal d-f-c" (click)="log()">
                    <app-user *ngIf="!data.is_partner" [class]="'user_div ml-1'" [employee]="data?.employee"></app-user>
                    <app-user *ngIf="!!data.is_partner" [class]="'user_div'" [companyEmployee]="data?.employee.partnerCompany" [imgRoute]="data?.imgRoute" [host]="data?.host" [company]="data?.company"></app-user>
                    <span class="set_width ml-1">
                        {{ "is responsible for the job" | translate }}:
                    </span>
                </div>
        
                <form [formGroup]="form" class="form_with_pads" (ngSubmit)="onSubmit()">
                    <section style="margin-bottom: 10px;">
                        <mat-button-toggle-group multiple appearance="legacy" formControlName="type" style="width: calc(100% - 2px);">
                            <mat-button-toggle [value]="item.id" *ngFor="let item of types">{{item.name | translate}}</mat-button-toggle>
                        </mat-button-toggle-group>
                    </section>
        
                    <mat-form-field appearance="legacy" class="full_width_form">
                        <mat-label>{{ "Job" | translate }}</mat-label>
                        <mat-select [disableOptionCentering]="true" selectDir formControlName="operation_id" multiple>
                            <mat-option *ngFor="let op of operationsValues" [value]="op.id">
                                {{ op.name | translate }}
                            </mat-option>
                        </mat-select>
                        </mat-form-field>
        
                    <mat-form-field appearance="legacy" class="full_width_form">
                        <mat-label>{{ "Tag" | translate }}</mat-label>
                        <mat-select [disableOptionCentering]="true" selectDir formControlName="parameter_value_id" multiple>
                            <mat-select-trigger class="multi_users">
                                <ng-container *ngIf="form.get('parameter_value_id').value && form.get('parameter_value_id').value.length">
                                    <ng-container *ngFor="let id of form.get('parameter_value_id').value">
                                        <span>{{ getValueById(id)?.value }}</span>
                                    </ng-container>
                                </ng-container>
                            </mat-select-trigger>
                            <mat-option>
                                <ngx-mat-select-search 
                                placeholderLabel="{{ 'Search' | translate }}" 
                                noEntriesFoundLabel="{{ 'no matching tag found' | translate }}"
                                [formControl]="allValuesControl"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let item of allValues$ | async" [value]="item.id">
                                <div class="d-f-c" style="justify-content: space-between;">
                                    <span>{{ item.value }}</span>
                                    <span>{{ item?.parameter?.name }}</span>
                                </div>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
        
                    <mat-form-field appearance="legacy" class="full_width_form">
                        <mat-label>{{ "Group" | translate }}</mat-label>
                        <mat-select [disableOptionCentering]="true" selectDir formControlName="group_id" multiple>
                            <mat-option>
                            <ngx-mat-select-search 
                                placeholderLabel="{{ 'Search' | translate }}" 
                                noEntriesFoundLabel="{{ 'no matching groups found' | translate }}"
                                [formControl]="groupsMoreControl"></ngx-mat-select-search>
                            </mat-option>                  
                            <mat-option *ngFor="let group of groups$ | async" [value]="group.id">
                            {{ group.name }}
                            </mat-option>
                        </mat-select>           
                        <button class="suffix_button" [ngStyle]="{'color': (!((form.get('operation_id').value && form.get('operation_id').value.length) && (form.get('type').value && form.get('type').value.length)) || isSubmit) ? '#686868' : '#4E844E'}" [disabled]="!((form.get('operation_id').value && form.get('operation_id').value.length) && (form.get('type').value && form.get('type').value.length)) || isSubmit" type="submit" (click)="$event.stopPropagation()" mat-icon-button matSuffix>
                            <mat-icon>add_circle</mat-icon>
                        </button>
                    </mat-form-field>
                </form>
        
                <ng-container *ngIf="autoToEmployee?.length">
        
                    <div class="head_list">
                        <div class="head_left">
                            <div class="head_item">{{"Type" | translate}}</div>
                            <div class="head_item">{{"Job" | translate}}</div>
                            <div class="head_item">{{"Tag" | translate}}</div>
                            <div class="head_item">{{"Group" | translate}}</div>
                        </div>
                        <div class="head_action">
                            {{"Action" | translate}}
                        </div>
                    </div>
                    <mat-nav-list >
                        <mat-list-item *ngFor="let auto of autoToEmployee; let i = index;" class="data_out_item">
                            <div class="data_item">
                                <div class="data_item_info">
                                    <span class="bold black" *ngIf="auto.is_task_client">{{getTypeByKey('is_task_client').name | translate}}</span>
                                    <span class="bold black" *ngIf="auto.is_task_client_principal">{{getTypeByKey('is_task_client_principal').name | translate}}</span>
                                    <span class="bold black" *ngIf="auto.is_task_employee">{{getTypeByKey('is_task_employee').name | translate}}</span>
                                    <span class="bold black" *ngIf="auto.is_task_employee_assistant">{{getTypeByKey('is_task_employee_assistant').name | translate}}</span>
                                    <span class="black" *ngIf="auto.operation_id">{{getOperationById(auto.operation_id)?.name}}</span>
                                    <span *ngIf="auto.parameter_value_id">
                                        <div class="tag">{{getValueById(auto.parameter_value_id)?.value}}</div>
                                    </span>
                                    <span *ngIf="!auto.parameter_value_id">
                                        <div class="tag">{{"Tag not set" | translate}}</div>
                                    </span>
                                    <span *ngIf="auto.group_id">{{getGroupById(auto.group_id)?.name}}</span>
                                    <span *ngIf="!auto.group_id">
                                        <div class="tag">{{"Group not set" | translate}}</div>
                                    </span>
                                </div>
                                <mat-icon style="color: red;" (click)="deleteAutoToEmployee(auto.id, i)">delete</mat-icon>
                            </div>
                        </mat-list-item>
                    </mat-nav-list>
                </ng-container>
                
            </div>
        </mat-tab>
        <mat-tab *ngIf="!data.is_partner && partners && partners.length">
            <ng-template mat-tab-label>
                {{ "Partner Workspace" | translate }}
            </ng-template>
            <div class="container form_wrapper" [appDeviceClass]="'custom-scroll custom-scroll-t'">
                <div class="title_modal d-f-c" (click)="log()">
                    <app-user *ngIf="!data.is_partner" [class]="'user_div ml-1'" [employee]="data?.employee"></app-user>
                    <app-user *ngIf="!!data.is_partner" [class]="'user_div'" [companyEmployee]="data?.employee.partnerCompany" [imgRoute]="data?.imgRoute" [host]="data?.host" [company]="data?.company"></app-user>
                    <span class="set_width ml-1">
                        {{ "is responsible for the job" | translate }}:
                    </span>
                </div>
        
                <form [formGroup]="partnerForm" class="form_with_pads" (ngSubmit)="onSubmitPartner()">

                    <div class="users_select">
                        <label>{{ "Select external workspace" | translate }}: </label>
                        <mat-chip-list class="new_task_chips" [multiple]="false" selectable formControlName="task_company_id">
                            <ng-container *ngFor="let comp of partners; let i = index">
                                <mat-chip class="chip_user" (click)="togglePartnerChip(comp)" [value]="comp?.partner_company_id">
                                    <app-user [class]="'user_div'" [companyEmployee]="comp?.partnerCompany" [imgRoute]="data?.imgRoute" [host]="data?.host" [company]="comp"></app-user>
                                </mat-chip>
                                <mat-chip *ngIf="i == partners.length - 1 && i > 6" class="chip_user more" (click)="externalCompanyesSelect.open()">
                                    {{ "more" | translate }}
                                </mat-chip>
                            </ng-container>
                        </mat-chip-list>
                    </div>
        
                    <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                        <mat-select #externalCompanyesSelect formControlName="task_company_id" (selectionChange)="togglePartnerChip($event)">
                            <mat-option>
                                <ngx-mat-select-search 
                                placeholderLabel="{{ 'Search' | translate }}" 
                                noEntriesFoundLabel="{{ 'no matching external workspaces found' | translate }}"
                                [formControl]="partnerCompMoreControl"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let comp of partners$ | async" [value]="comp?.partner_company_id">
                                <app-user [class]="'user_div'" [companyEmployee]="comp?.partnerCompany" [imgRoute]="data?.imgRoute" [host]="data?.host" [company]="comp"></app-user>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <ng-container *ngIf="partnerForm.get('task_company_id').value">
                        <section style="margin-bottom: 10px;">
                            <mat-button-toggle-group appearance="legacy" formControlName="type" style="width: calc(100% - 2px);">
                                <mat-button-toggle class="partner_toggle" [value]="item.id" *ngFor="let item of partnerTypes">{{item.name | translate}}</mat-button-toggle>
                            </mat-button-toggle-group>
                        </section>
            
                        <mat-form-field appearance="legacy" class="full_width_form">
                            <mat-label>{{ "Job" | translate }}</mat-label>
                            <mat-select [disableOptionCentering]="true" selectDir formControlName="operation_id">
                                <mat-option *ngFor="let op of partnerOperationsValues" [value]="op.id">
                                    {{ op.name | translate }}
                                </mat-option>
                            </mat-select>
                            </mat-form-field>
            
                        <mat-form-field appearance="legacy" class="full_width_form">
                            <mat-label>{{ "Tag" | translate }}</mat-label>
                            <mat-select [disableOptionCentering]="true" selectDir formControlName="parameter_value_id">
                                <mat-select-trigger>
                                    <ng-container *ngIf="partnerForm.get('parameter_value_id').value">
                                        <span>{{ getPartnerValueById(partnerForm.get('parameter_value_id').value)?.value }}</span>
                                    </ng-container>
                                </mat-select-trigger>
                                <mat-option>
                                    <ngx-mat-select-search 
                                    placeholderLabel="{{ 'Search' | translate }}" 
                                    noEntriesFoundLabel="{{ 'no matching tag found' | translate }}"
                                    [formControl]="partnerAllValuesControl"></ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let item of partnerAllValues$ | async" [value]="item.id">
                                    <div class="d-f-c" style="justify-content: space-between;">
                                        <span>{{ item.value }}</span>
                                        <span>{{ item?.parameter?.name }}</span>
                                    </div>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
            
                        <mat-form-field appearance="legacy" class="full_width_form">
                            <mat-label>{{ "Group" | translate }}</mat-label>
                            <mat-select [disableOptionCentering]="true" selectDir formControlName="group_id">
                                <mat-option>
                                <ngx-mat-select-search 
                                    placeholderLabel="{{ 'Search' | translate }}" 
                                    noEntriesFoundLabel="{{ 'no matching groups found' | translate }}"
                                    [formControl]="partnerGroupsMoreControl"></ngx-mat-select-search>
                                </mat-option>                  
                                <mat-option *ngFor="let group of partnerGroups$ | async" [value]="group.id">
                                {{ group.name }}
                                </mat-option>
                            </mat-select>           
                            <button class="suffix_button" [ngStyle]="{'color': (!partnerForm.get('operation_id').value || isSubmit) ? '#686868' : '#4E844E'}" [disabled]="!partnerForm.get('operation_id').value || isSubmit" type="submit" (click)="$event.stopPropagation()" mat-icon-button matSuffix>
                                <mat-icon>add_circle</mat-icon>
                            </button>
                        </mat-form-field>
                    </ng-container>
                </form>
        
                <ng-container *ngIf="partnerForm.get('task_company_id').value && partnerAutoToEmployee?.length">
        
                    <div class="head_list">
                        <div class="head_left">
                            <div class="head_item">{{"Type" | translate}}</div>
                            <div class="head_item">{{"Job" | translate}}</div>
                            <div class="head_item">{{"Tag" | translate}}</div>
                            <div class="head_item">{{"Group" | translate}}</div>
                        </div>
                        <div class="head_action">
                            {{"Action" | translate}}
                        </div>
                    </div>
                    <mat-nav-list >
                        <mat-list-item *ngFor="let auto of partnerAutoToEmployee; let i = index;" class="data_out_item">
                            <div class="data_item">
                                <div class="data_item_info">
                                    <span class="bold black" *ngIf="auto.is_task_partner_employee">{{getPartnerTypeByKey('is_task_partner_employee').name | translate}}</span>
                                    <span class="bold black" *ngIf="auto.is_task_partner_employee_assistant">{{getPartnerTypeByKey('is_task_partner_employee_assistant').name | translate}}</span>
                                    <span class="black" *ngIf="auto.operation_id">{{getPartnerOperationById(auto.operation_id)?.name}}</span>
                                    <span>
                                        <div class="tag" *ngIf="auto.parameter_value_id">{{getPartnerValueById(auto.parameter_value_id)?.value}}</div>
                                    </span>
                                    <span *ngIf="auto.group_id">{{getPartnerGroupById(auto.group_id)?.name}}</span>
                                </div>
                                <mat-icon style="color: red;" (click)="deletePartnerAutoToEmployee(auto.id, i)">delete</mat-icon>
                            </div>
                        </mat-list-item>
                    </mat-nav-list>
                </ng-container>
                
            </div>
        </mat-tab>
    </mat-tab-group>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <div class="f_w_p_btns">
        <button mat-raised-button (click)="close()">
            {{ "Close" | translate }}
        </button>
    </div>
</mat-dialog-actions>