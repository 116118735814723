import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpClient,
  HttpEventType,
  HttpResponse,
  HttpProgressEvent,
} from '@angular/common/http'
import { BehaviorSubject, concat, forkJoin, interval, Observable, of, Subject, Subscription, timer } from 'rxjs';
import { catchError, concatMap, delayWhen, expand, filter, map, reduce, retryWhen, scan, switchMap, takeUntil, takeWhile, tap, toArray } from 'rxjs/operators';
import { StorageManagerService } from '../common/storage-manager.service';
import { HttpRestService } from '../common/http-rest.service';
import { TaskService } from './task.service';
import { FileService } from './file.service';
import { QuestsService } from './quests.service';
import { ChatService } from './chat.service';
import { CompanyService } from './company.service';
import { RefreshService } from './refresh.service';
import { filesSort } from '../../functions/filesSort';

@Injectable({
  providedIn: 'root'
})

export class UploadService {
  public cacelFile$: Subject<any> = new Subject();
  public tryAgainFile$: Subject<any> = new Subject();
  public sameFilesFile$: Subject<any> = new Subject();
  public showFileInInterface$: Subject<any> = new Subject();
  public cancelFile$: Subject<any> = new Subject();
  public uploadLimit$: BehaviorSubject<any> = new BehaviorSubject(false);
  public uploadState$: Subject<UploadState> = new Subject();
  public progressUpload$: Subject<any> = new Subject();

  constructor(
    private http: HttpClient,
    private sm: StorageManagerService,
    private taskService: TaskService,
    private companyService: CompanyService,
    private refreshService: RefreshService,
    private chatService: ChatService,
    private questsService: QuestsService,
    private fileService: FileService,
    private httpRest: HttpRestService
  ) { }

  getCancelFile() {
    return this.cancelFile$.asObservable();
  }

  getSameFilesFile() {
    return this.sameFilesFile$.asObservable();
  }

  getCacelFile() {
    return this.cacelFile$.asObservable();
  }

  getTryAgainFile() {
    return this.tryAgainFile$.asObservable();
  }

  getShowFileInInterface() {
    return this.showFileInInterface$.asObservable();
  }

  getProgressUpload() {
    return this.progressUpload$.asObservable();
  }

  getUploadState() {
    return this.uploadState$.asObservable();
  }
  
  getUploadLimit() {
    return this.uploadLimit$.asObservable();
  }

  subsDataToCsv(array, title) {
    const headers = [
      { key: "name", name: "File Name" },
      { key: "subs.from", name: "Start Time" },
      { key: "subs.to", name: "End Time" },
      { key: "subs.speaker", name: "Speaker" },
      { key: "subs.text", name: "Text" }
    ];
  
    const csvData = this.subsConvertToCSV(array, headers, title);
    console.log(csvData);
    const blob = new Blob(["\ufeff" + csvData], { type: "text/csv;charset=utf-8;" });
    const dwldLink = document.createElement("a");
    const url = URL.createObjectURL(blob);
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", title + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }
  
  subsConvertToCSV(objArray, headerList, title) {
    let str = title + "\r\n";
    let row = headerList.map(h => h.name).join(",") + "\r\n";
    str += row;
  
    objArray.forEach(item => {
      item.subs.forEach(sub => {
        const line = headerList
          .map(header => {
            const keys = header.key.split(".");
            const value = keys.length > 1 ? sub[keys[1]] || item[keys[0]] : item[keys[0]];
            return value ? value.toString().replaceAll(",", "") : "";
          })
          .join(",");
        str += line + "\r\n";
      });

      // Добавляем пустую строку между элементами массива
      str += "\r\n";
    });
  
    return str;
  }

  dataToCsv(data, title) {
    let csvData = this.ConvertToCSV(data, [{key: 'task.custom_id', name: "Card Custom ID"}, {key: 'task.name', name: "Card Name"}, {key: 'channel.name', name: "Channel"},  {key: 'content_url', name: "Url"}], title);
    console.log(csvData)
    let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
        dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", title + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  ConvertToCSV(objArray, headerList, title) {
      let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
      let str = '';
      let row = title + '\r\n';

      for (let index in headerList) {
          row += headerList[index].name + ',';
      }
      row = row.slice(0, -1);
      str += row + '\r\n';
      for (let i = 0; i < array.length; i++) {
          let line = '';
          for (let index in headerList) {
              let head = headerList[index].key;

              if (head.indexOf('.') != - 1) {
                if (head == 'task.custom_id') {
                  line += (line == '' ? '' : ',') + array[i][head.split('.')[0]][head.split('.')[1]];
                } else {
                  line += (line == '' ? '' : ',') + (!!array[i][head.split('.')[0]][head.split('.')[1]] ? array[i][head.split('.')[0]][head.split('.')[1]].replaceAll(',', '') : '');
                }
              } else {
                line += (line == '' ? '' : ',') + array[i][head];
              }

          }
          str += line + '\r\n';
      }
      return str;
  }

  upload(field, file, url?, allFiles?):Observable<any> {
    console.log("FIELD", field)
    let timeStamp = Date.now()
    // const data = new FormData()
    // data.append('file', file)
    // data.append(this.sm.localStorageGetItem('csrf_param'), this.sm.localStorageGetItem('csrf_token'));
    const initialState: Upload = { state: 'PENDING', progress: 0, is_end: false, size_is_uploaded: 0, speed: 0, timeEnd: null}
    const calculateState = (
      upload: Upload,
      event: HttpEvent<unknown>
    ): Upload => {
      if (isHttpProgressEvent(event)) {
        let time = Date.now() - timeStamp;
        return {
          progress: event.total
            ? Math.round((100 * event.loaded) / event.total)
            : upload.progress,
          state: 'IN_PROGRESS',
          is_end: false,
          size_is_uploaded: event.loaded,
          speed: event.loaded / (time / 1000),
          timeEnd: Math.round(timeStamp/1000 + (event.total / (event.loaded / (time / 1000)))),
        }
      }
      if (isHttpResponse(event)) {
        console.log("isHttpResponse", event);
        return {
          progress: 100,
          state: 'DONE',
          is_end: true,
          size_is_uploaded: file.size,
          speed: 0,
          timeEnd: null
        }
      }
      return upload
    }
    
    return this.http
      .put(url, file, {
        reportProgress: true,
        observe: 'events',
        headers: {"Content-Type": "multipart/form-data", "ngsw-bypass": 'true'}
      })
      .pipe(
        takeUntil(this.getCancelFile().pipe(
          tap(x => {
            console.log("field", field, file);
          }),
          filter((val) => val.id == 0 || (file.reportsFile && file.reportsFile.id == val.id && val.is_paused)),
          map(() => {
            console.log("takeUntil")
            return "is_paused"
          })
        )),
        scan(calculateState, initialState),
        catchError(err => {
          console.log("err in put", err);
          return of(err)
        })
      )
  }

  multiUploadOneByOne(file, multipart, field, data) {
    if (!multipart.info) {
      multipart.info = [];
      multipart.urls.forEach((e,i) => {
        const index = parseInt(i)
        const start = index * multipart.chunk_size
        const end = (index + 1) * multipart.chunk_size
        const blob = index < multipart.urls.length
        ? file.slice(start, end)
        : file.slice(start)
  
        multipart.info.push({
          etag: '',
          url: e,
          index: i,
          file: blob,
          status: 'pending'
        })
      });
    } else {
      multipart.info.forEach(x => {
        if (x.status == 'error') {
          x.status = 'pending'
        }
      });
    }
    console.log(multipart);
    let timeStamp = Date.now()
    return concat(...multipart.info.filter(x => x.status == 'pending').map((param) => this.putFile(param.url, param.file, multipart.info, field, timeStamp, data))).pipe(
      takeUntil(this.getCancelFile().pipe(
        filter((val) => val.id == 0 || (data.id == val.id && val.is_paused)),
        map(() => {
          console.log("takeUntil")
          return "is_paused"
        })
      )),
      toArray(),
    )
  }

  getUploadedSize(arr) {
    let size = 0;
    arr.forEach(x => {
      if (x.target.hasOwnProperty("upload")) {
        size = +size + (+x.target.size - +x.target.upload.size_is_uploaded)
      } else {
        size = +size + +x.target.size
      }
    })

    return size
  }

  smartMultiUpload(file, multipart, field, data, allResp, allFiles) {
    console.log("smartMultiUpload", "START", file, multipart, field, data, allResp)
    file.rangeUpload = {
      finishedSummary: 0, // summary size in bytes of all completed threads
      progressSummary: [], // array, uploaded bytes of each active thread
      threadTime: [], // array, time threads started
      threadSpeed: [[], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], []], // array, speed of each active thread
      threadSpeedSummary: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], // array, speed of each active thread
      minThreads: 7,
      maxThreads: 20,
      threads: 0,
    }

    // console.log("MULTIPART INFO", multipart.info)
    if (!multipart.info) {
      multipart.info = [];
      multipart.urls.forEach((e,i) => {
        const index = parseInt(i)
        const start = index * multipart.chunk_size
        const end = (index + 1) * multipart.chunk_size
        const blob = index < multipart.urls.length
        ? file.slice(start, end)
        : file.slice(start)
  
        multipart.info.push({
          etag: '',
          url: e,
          index: i,
          file: blob,
          status: 'pending',
          upload: {
            progress: 0,
            state: 'PENDING',
            is_end: false,
            size_is_uploaded: 0,
            speed: 0,
            timeEnd: null
          }
        })
      });
    } else {
      multipart.info.forEach(x => {
        if (x.status == 'error') {
          x.status = 'pending'
        }
      });
    }
    // console.log(multipart);
    

    return interval(200).pipe(
      takeUntil(this.getCancelFile().pipe(
        filter((val) => val.id == 0 || (data.id == val.id && val.is_paused)),
        map(() => {
          console.log("takeUntil")
          return "is_paused"
        })
      )),
      takeWhile(() => {
        console.log("smartMultiUpload takeWHILE", field)
        return !field.upload || field.upload.state != "DELETED" || field.upload.state != "ERROR"
      }),
      tap((t) => {
        console.log("interval", t);
        // console.log("getAverage 1s", file.rangeUpload);
        // const getAverage = (numbers) => {
          //   const sum = numbers.reduce((acc, number) => acc + number, 0);
          //   const length = numbers.length;
          //   return sum / length;
          // };
        if ((file.rangeUpload.threads < file.rangeUpload.minThreads || (file.rangeUpload.threadSpeedSummary[file.rangeUpload.threads - 1] < file.rangeUpload.threadSpeedSummary[file.rangeUpload.threads] && file.rangeUpload.threads < file.rangeUpload.maxThreads)) && multipart.info.filter(x => x.status == 'pending').length > 0) {
          file.rangeUpload.threads++;
          // console.log("before BUG 0---", file.rangeUpload.threads, file.rangeUpload.threadSpeed[file.rangeUpload.threads])
          if (file.rangeUpload.threadSpeed[file.rangeUpload.threads].length > 0) {
            file.rangeUpload.threadSpeed[file.rangeUpload.threads] = []
          }
          console.log("++++++ 1s threads", file.rangeUpload.threads);
          this.newPutFile(multipart.info.filter(x => x.status == 'pending')[0].url, multipart.info.filter(x => x.status == 'pending')[0].file, multipart.info, multipart.info.filter(x => x.status == 'pending')[0], field, data, file, allResp, allFiles)
          if (!(t % 1000)) {
            this.sendProgress(allResp)
          }
        }
      }),
    )
  }

  sendProgress(interfaceData) {
    this.fileService.editFile(interfaceData.data.id, {user_upload_progress: Math.ceil(interfaceData.target.upload.size_is_uploaded), user_upload_speed_mbit: Math.ceil(interfaceData.target.upload.speed)}, interfaceData.company_id).subscribe(resp => {
      console.log("sendProgress", resp)
    }, error => {
      console.log("sendProgress error", error)
    })
  }

  newPutFile(url, partfile, parts, part, field, data, file, allResp, allFiles) {
    let timeStamp = Date.now()
    const initialState: Upload = { state: 'PENDING', progress: 0, is_end: false, size_is_uploaded: 0, speed: 0, timeEnd: null}
    const calculateState = (
      upload: Upload,
      event: HttpEvent<unknown>
    ): Upload => {
      // console.log("HttpEvent", event, upload)
      if (isHttpProgressEvent(event)) {
        let time = Date.now() - timeStamp;
        return {
          progress: event.total
            ? Math.round((100 * event.loaded) / event.total)
            : upload.progress,
          state: 'IN_PROGRESS',
          is_end: false,
          size_is_uploaded: event.loaded,
          speed: event.loaded / (time / 1000),
          timeEnd: Math.round(timeStamp/1000 + (event.total / (event.loaded / (time / 1000)))),
        }
      }
      if (isHttpResponse(event)) {
        parts.find(z => z.url == url).etag = event.headers.get('etag');
        parts.find(z => z.url == url).status = 'end';
        return {
          progress: 100,
          state: 'DONE',
          is_end: true,
          size_is_uploaded: part.file.size,
          speed: 0,
          timeEnd: null
        }
      }
      return upload
    }
    
    parts.find(z => z.url == url).status = 'in_process';
    return this.http.put(url, partfile, {
      reportProgress: true,
      observe: 'events',
      headers: {"Content-Type": "multipart/form-data", "ngsw-bypass": 'true'}
    }).pipe(
      scan(calculateState, initialState),
      takeUntil(this.getCancelFile().pipe(
        tap(val => {
          console.log("takeUntil", val)
        }),
        filter((val) => val.id == 0 || (allResp.data.id == val.id && val.is_paused)),
        map(() => {
          file.rangeUpload.threads == 0
          console.log("takeUntil newPutFile", file)
          return "is_paused"
        })
      )),
      catchError(err => {
        console.log("err in put newPutFile", err);
        parts.find(z => z.url == url).status = 'error';
        return of(err)
      }),
    ).subscribe({
      next: (next) => {
        // console.log("subscribe > parts", parts);
        // console.log("subscribe > next", next);
        if (next == 'Unknown Error' || next === undefined) {
          field.upload = {
            progress: Math.round(parts.filter(m => m.status == 'end').map(n => n.upload).map(item => item.progress).reduce((a, b) => +a + +b, 0) / parts.length),
            state: 'ERROR',
            is_end: false,
            size_is_uploaded: parts.filter(m => m.status == 'end').map(n => n.upload).map(item => item.size_is_uploaded).reduce((a, b) => +a + +b, 0),
            speed: 0,
            timeEnd: null
          }
        } else {
          part.upload = next;
          // console.log("subscribe > part", next);
          field.upload = {
            progress: Math.round(parts.map(n => n.upload).map(item => item.progress).reduce((a, b) => +a + +b, 0) / parts.length),
            state: parts.filter(m => m.status == 'error').length == 0 ? 'IN_PROGRESS' : 'ERROR',
            is_end: false,
            size_is_uploaded: parts.map(n => n.upload).map(item => item.size_is_uploaded).reduce((a, b) => +a + +b, 0),
            speed: parts.map(n => n.upload).map(item => item.speed).reduce((a, b) => +a + +b, 0),
            timeEnd: Math.round((field.size - parts.map(n => n.upload).map(item => item.size_is_uploaded).reduce((a, b) => +a + +b, 0)) / parts.map(n => n.upload).map(item => item.speed).reduce((a, b) => +a + +b, 0))
          }
          // console.log("BEFORE BUG", file.rangeUpload.threads, file.rangeUpload.threadSpeed[file.rangeUpload.threads])
          file.rangeUpload.threadSpeed[file.rangeUpload.threads].push(parts.map(n => n.upload).map(item => item.speed).reduce((a, b) => +a + +b, 0))
          file.rangeUpload.threadSpeedSummary[file.rangeUpload.threads] = file.rangeUpload.threadSpeed[file.rangeUpload.threads].reduce((acc, number) => acc + number, 0) / file.rangeUpload.threadSpeed[file.rangeUpload.threads].length
        }

        this.progressUpload$.next({
          uploadedFiles: allFiles.filter(x => !x.target.hasOwnProperty("upload") || ['PENDING', 'IN_PROGRESS'].includes(x.target.upload.state)).length,
          uploadedSize: this.getUploadedSize(allFiles.filter(x => !x.target.hasOwnProperty("upload") || ['PENDING', 'IN_PROGRESS'].includes(x.target.upload.state)))
        })
        filesSort(allFiles)

        // console.log("speed", parts.map(n => n.upload).map(item => item.speed).reduce((a, b) => +a + +b, 0))
        // console.log(field)
        // console.log(field.size)
        // console.log(field.upload.timeEnd)
      },
      complete: () => {
        // console.log("END", parts);
        file.rangeUpload.threads--;
        if (file.rangeUpload.threads < 0) {
          file.rangeUpload.threads = 0;
        }
        if (parts.filter(x => x.status == 'end').length == parts.length) {

          if (parts.filter(x => !!x.etag).length == parts.length) {
            let sbmtData = {
              id: data.upload_url.id,
              upload_id: data.upload_url.upload_id,
              parts: []
            }
            parts.forEach((element, index) => {
              sbmtData.parts.push({
                "ETag": element.etag,
                "PartNumber": index + 1
              })
            });
            
            field.upload = {
              progress: 100,
              state: "DONE",
              is_end: true,
              size_is_uploaded: field.filesize,
              speed: 0,
              timeEnd: null
            }
            field.upload.speed = 0;
            this.progressUpload$.next({
              uploadedFiles: allFiles.filter(x => !x.target.hasOwnProperty("upload") || ['PENDING', 'IN_PROGRESS'].includes(x.target.upload.state)).length,
              uploadedSize: this.getUploadedSize(allFiles.filter(x => !x.target.hasOwnProperty("upload") || ['PENDING', 'IN_PROGRESS'].includes(x.target.upload.state)))
            })
            filesSort(allFiles)
            this.completeUpload(sbmtData).pipe(
              switchMap(val => {
                if (allResp.folder && allResp.folder.is_folder) {
                  this.refreshService.refreshFiles$.next(allResp);
                  return of('is_folder')
                }
                if (allResp.place == 'work_status') {
                  return of(allResp.place)
                }
                if (allResp.place == 'edit_company') {
                  return this.companyService.getCompany(allResp.data.id).pipe(
                    map(x => x[0])
                  )
                }
                if (allResp.posts) {
                  return this.chatService.getTargetPost(allResp.chat.id, allResp.post.id, allResp.company_id).pipe(
                    tap(x => {
                      allResp.user.name = allResp.user.display_name
                      x[0].discussionPostFiles[allResp.index].file.createdEmployee = allResp.user;
                      x[0].discussionPostFiles[allResp.index].file.user = allResp.user;
                      allResp.uploadedFile = x[0].discussionPostFiles[allResp.index].file;
                      allResp.post.discussionPostFiles[allResp.index].file.thumbnail = undefined;
                      allResp.post.discussionPostFiles[allResp.index].file.original = undefined;
                      setTimeout(() => {
                        allResp.post.discussionPostFiles[allResp.index].file.is_uploaded = 1;
                        allResp.post.discussionPostFiles[allResp.index].file.thumbnail = x[0].discussionPostFiles[allResp.index].file.thumbnail
                        allResp.post.discussionPostFiles[allResp.index].file.original = x[0].discussionPostFiles[allResp.index].file.original
                      }, 0)
                    }),
                    map(x => x[0])
                  )
                }
                if (allResp.files) {
                  return this.fileService.getTargetFile(allResp.data.upload_url.id, allResp.company_id).pipe(
                    tap(x => {
                      allResp.user.name = allResp.user.display_name
                      x.user = allResp.user
                      x.createdEmployee = allResp.user
                      allResp.uploadedFile = x
                    }),
                    switchMap(k => {
                      if (allResp.partition) {
                        return this.fileService.createFilePartition(allResp.partition, allResp.company_id)
                      } else {
                        return of(k)
                      }
                    })
                  )
                } else {
                  return this.questsService.getQuestEmpl(allResp.company_id, {id: allResp.target.id}, '1').pipe(
                    map(x => x.body[0]),
                  );
                }
              }),
              switchMap(x => {
                console.log("AFTER UPLOAD SMART", allResp, x)
                if (allResp.files && allResp.target.afterUploadData) {
                  return this.fileService.editFile(allResp.data.upload_url.id, {is_ready_if_approved: !!allResp.target.afterUploadData.is_ready_if_approved ? 1 : 0, operation_reminder_id: allResp.target.afterUploadData.operation_reminder_id}, allResp.company_id).pipe(
                    tap(res => {
                      x.is_ready_if_approved = allResp.target.afterUploadData.is_ready_if_approved;
                      x.operation_reminder_id = allResp.target.afterUploadData.operation_reminder_id;
                      if (!!allResp.target.selectedCheck) {
                        x.selectedCheck = allResp.target.selectedCheck
                        x.operation_reminder_name = allResp.target.selectedCheck.text;
                      }
                      // delete allResp.target.afterUploadData
                    }),
                    switchMap(x => {
                      if (allResp.target.afterUploadData.close_reminder) {
                        return this.taskService.createOperationReminderStatus(allResp.work.id, allResp.target.afterUploadData.operation_reminder_id, allResp.company_id).pipe(
                          tap(val => {
                            allResp.target.selectedCheck.status = val
                          }),
                          map(() => x)
                        )
                      } else {
                        return of(x);
                      }
                    }),
                  )
                } else {
                  return of(x)
                }
              }),
              tap((x) => {
                allResp.target.upload = {
                  progress: 100,
                  state: 'DONE',
                  is_end: true,
                  size_is_uploaded: allResp.target.fileVal ? allResp.target.fileVal.size : allResp.target ? allResp.target.size : 0,
                  speed: 0,
                  timeEnd: null
                }
                if (x != 'is_folder') {
                  console.log("###2")
                  if (allResp.place == 'edit_company') {
                    allResp.data.avatarFile = x.avatarFile;
                  }
                  if (allResp.files ) {
                    if (allResp.files.filter(u => u.id == x.id).length == 0) {
                      allResp.files.push(x)
                    } else {
                      allResp.files.splice(allResp.files.findIndex(u => u.id == x.id), 1, x)
                    }
                  } else {
                    if (x.file) {
                      allResp.target.file = x.file;
                    }
                    if (x.user) {
                      x.user.name = x.user.display_name
                      allResp.target.user = x.user;
                      allResp.target.createdEmployee =  x.user;
                    }
                  }
                }
              }),
              switchMap((x) => {
                if (allResp.work_id && allResp.work_id != 0 && (allResp.location.indexOf('to_approve') != -1 || allResp.location.indexOf('ready_files') != -1)) {
                  let postBody:any = {};
                  if (allResp.location.indexOf("to_approve") != -1) {
                    postBody.is_to_approve_files = 1
                  }
                  if (allResp.location.indexOf("ready_files") != -1) {
                    postBody.is_approved_files = 1
                  }
                  return this.taskService.editWork(allResp.work_id, postBody, allResp.company_id)
                } else {
                  return of(x)
                }
              }),
            ).subscribe(result => {
              console.log("FINISH", result)
            })
          } else {
            parts.forEach(el => {
              if (!el.etag) {
                el.status = 'error'
              }
            })
            field.upload = {
              progress: Math.round(parts.filter(m => m.status == 'end').map(n => n.upload).map(item => item.progress).reduce((a, b) => +a + +b, 0) / parts.length),
              state: 'ERROR',
              is_end: false,
              size_is_uploaded: parts.filter(m => m.status == 'end').map(n => n.upload).map(item => item.size_is_uploaded).reduce((a, b) => +a + +b, 0),
              speed: 0,
              timeEnd: null
            }
          }
          // console.log("newPutFile in", parts);

        }
        // let data = {
        //   id: resp.data.upload_url.id,
        //   upload_id: resp.data.upload_url.upload_id,
        //   parts: []
        // }
        // resp.data.upload_url.info.forEach((element, index) => {
        //   data.parts.push({
        //     "ETag": element.etag,
        //     "PartNumber": index + 1
        //   })
        // });
        // return this.uploads.completeUpload(data).pipe(
        //   tap((x) => {
        //     console.log("###1")
        //     this.updatedCount = this.updatedCount + 1;
        //   }),
        //   switchMap((val) => {
        //     if (resp.place == 'work_status') {
        //       return of(resp.place)
        //     }
        //     if (resp.place == 'edit_company') {
        //       return this.companyService.getCompany(resp.data.id).pipe(
        //         map(x => x[0])
        //       )
        //     }
        //     if (resp.posts) {
        //       return this.chatService.getTargetPost(resp.chat.id, resp.target.id, resp.company_id).pipe(
        //         map(x => x[0])
        //       )
        //     }
        //     if (resp.files) {
        //       return this.fileService.getTargetFile(resp.data.upload_url.id, resp.company_id).pipe(
        //         tap(x => x.user = resp.user)
        //       )
        //     } else {
        //       return this.questsService.getQuestEmpl(resp.company_id, {id: resp.target.id}, '1').pipe(
        //         map(x => x.body[0]),
        //       );
        //     }
        //   }),
        //   tap((x) => {
        //     console.log("###2")
        //     if (resp.place == 'edit_company') {
        //       resp.data.avatarFile = x.avatarFile;
        //     }
        //     if (resp.files) {
        //       resp.files.push(x)
        //     } else {
        //       if (x.file) {
        //         resp.target.file = x.file;
        //       }
        //       if (x.user) {
        //         resp.target.user = x.user;
        //       }
        //     }
        //   }),
        //   finalize(() => {
        //     resp.target.upload = {
        //       progress: 100,
        //       state: 'DONE',
        //       is_end: true,
        //       size_is_uploaded: resp.target.fileVal ? resp.target.fileVal.size : resp.target ? resp.target.size : 0,
        //       speed: 0,
        //       timeEnd: null
        //     }
        //   }),
        //   switchMap((x) => {
        //     if (resp.work_id && resp.work_id != 0 && (resp.location.indexOf('to_approve') != -1 || resp.location.indexOf('ready_files') != -1)) {
        //       let postBody:any = {};
        //       if (resp.location.indexOf("to_approve") != -1) {
        //         postBody.is_to_approve_files = 1
        //       }
        //       if (resp.location.indexOf("ready_files") != -1) {
        //         postBody.is_approved_files = 1
        //       }
        //       return this.taskService.editWork(resp.work_id, postBody, resp.company_id)
        //     } else {
        //       return of(x)
        //     }
        //   }),
        // );
      },
      error: (error) => {
        console.log("error", error)
        allResp.target.upload.state = "ERROR"
        file.rangeUpload.threads--;
          
        // timer(20000).subscribe(() => {
        //   this.files.filter(x => x.target.upload.state == 'ERROR').forEach(file => {
        //     this.tryAgain(file)
        //   })
        // })
        // return of(upload);
      }
    })
  }

  putFile(url, file, parts, field, timeStamp, data) {
    return this.http.put(url, file, {
      observe: 'response',
      headers: {"Content-Type": "multipart/form-data", "ngsw-bypass": 'true'}
    }).pipe(
      tap((el) => {
        if (el.headers.get('etag') != "Unknown Error") {
          parts.find(z => z.url == url).etag = el.headers.get('etag');
          parts.find(z => z.url == url).status = 'end';
          this.uploadState$.next({
            data: data,
            field: field,
            parts: parts,
            time: timeStamp,
            timeEnd: null,
            allResp: false
          })
        }
      }),
      takeUntil(this.getCancelFile().pipe(
        tap(val => {
          console.log("takeUntil", val)
        }),
        filter((val) => val.id == 0 || (data.id == val.id && val.is_paused)),
        map(() => {
          console.log("takeUntil putFile", file)
          return "is_paused"
        })
      )),
      map(el => el.headers.get('etag')),
      catchError(err => {
        console.log("err in put", err);
        parts.find(z => z.url == url).status = 'error';
        return of(err)
      }),
    )
  }

  completeUpload(data) {
    return this.httpRest.executePost("/file/complete-multipart-upload/", data)
  }
}


function isHttpResponse<T>(event: HttpEvent<T>): event is HttpResponse<T> {
  return event.type === HttpEventType.Response
}

function isHttpProgressEvent(
  event: HttpEvent<unknown>
): event is HttpProgressEvent {
  return (
    event.type === HttpEventType.DownloadProgress ||
    event.type === HttpEventType.UploadProgress
  )
}

export interface Upload {
  progress: number
  state: 'PENDING' | 'IN_PROGRESS' | 'DONE'
  is_end: boolean,
  size_is_uploaded: number,
  speed: number,
  timeEnd: any
}

export interface UploadState {
  data: any,
  field: any,
  parts: any[],
  time: any,
  timeEnd: any,
  allResp: any
}