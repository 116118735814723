import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import * as moment from 'moment';
import { concat, forkJoin, fromEvent, of, ReplaySubject, Subject, Subscription } from 'rxjs';
import { catchError, concatMap, debounceTime, distinctUntilChanged, expand, filter, flatMap, last, map, repeatWhen, switchMap, take, takeUntil, tap, toArray } from 'rxjs/operators';
import { ConnectionsModalComponent } from 'src/app/shared/global_components/connections-modal/connections-modal.component';
import { DeleteAlertComponent } from 'src/app/shared/global_components/delete-alert/delete-alert.component';
import { NotificationsBar } from 'src/app/shared/global_components/notifications-bar/notifications-bar';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { AuthService } from 'src/app/shared/services/rest/auth.service';
import { ChatService } from 'src/app/shared/services/rest/chat.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { CreateTaskComponent } from '../../atTasksDialog/create-task/create-task.component';
import { EmplCreateTaskComponent } from '../../atTasksDialog/empl-create-task/empl-create-task.component';
import { GroupAddComponent } from '../../atTasksDialog/group-add/group-add.component';
import { TaskGroupEditComponent } from '../../atTasksDialog/group-edit/group-edit.component';
import { TaskEditComponent } from '../../atTasksDialog/task-edit/task-edit.component';
import { MY_FORMATS } from '../../atTasksDialog/task-profile-add/task-profile-add.component';
import { TaskStatusEditComponent } from '../../atTasksDialog/task-status-edit/task-status-edit.component';
import { WorkAddComponent } from '../../atTasksDialog/work-add/work-add.component';
import { WorkEmplAddComponent } from '../../atTasksDialog/work-empl-add/work-empl-add.component';
import { TaskClientsComponent } from '../../clients/task-clients/task-clients.component';
import SwiperCore, { Pagination,  Mousewheel } from 'swiper/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatSelect } from '@angular/material/select';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { WorkMultiAddComponent } from '../../atTasksDialog/work-multi-add/work-multi-add.component';
import { OpenTaskComponent } from './dialogs/open-task/open-task.component';
import { InterfaceComponent } from 'src/app/shared/global_components/file-manager/dialog/interface/interface.component';
import { OperationChatComponent } from '../../atTasksDialog/operation-chat/operation-chat.component';
import { MatDatepickerInputEvent, MatDateRangePicker } from '@angular/material/datepicker';
import { casesModel } from 'src/app/shared/functions/casesModel';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { LoadingService } from 'src/app/shared/services/rest/loading.service';
import { TaskMultiAddClientsComponent } from '../../clients/task-multi-add-clients/task-multi-add-clients.component';
import { prioritys } from 'src/app/shared/consts/prioritys';
import { TaskManagerAddComponent } from './dialogs/managers/task-manager-add/task-manager-add.component';
import { TaskManagersComponent } from './dialogs/managers/task-managers/task-managers.component';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { RefreshService } from 'src/app/shared/services/rest/refresh.service';
import { ChatsComponent } from '../../chats/chats.component';
import { OpenJobComponent } from './dialogs/open-job/open-job.component';
import { GlobalDataService } from 'src/app/shared/services/common/global-data.service';
import { ApproveRejectFilesComponent } from 'src/app/shared/global_components/approve-reject-files/approve-reject-files.component';
import { TargetParametersComponent } from '../../atTasksDialog/target-parameters/target-parameters.component';
import { ParametersService } from 'src/app/shared/services/rest/parameters.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { AddTaskProfileComponent } from './dialogs/open-task/dialogs/add-task-profile/add-task-profile.component';
import { AddChannelToTaskComponent } from '../../atTasksDialog/add-channel-to-task/add-channel-to-task.component';
import { MobAddTaskComponent } from '../../mob-task/mob-add-task/mob-add-task.component';
import { MobAddJobComponent } from '../../mob-job/mob-add-job/mob-add-job.component';
import { MobTaskEditComponent } from '../../mob-task/mob-task-edit/mob-task-edit.component';
import { TaskBarService } from 'src/app/shared/services/rest/task-bar.service';
import { MinimizeService } from 'src/app/shared/services/rest/minimize.service';
import { TaskChatsComponent } from '../../atTasksDialog/task-chats/task-chats.component';
import { MobTitleComponent } from 'src/app/shared/global_components/chat/dialogs/mob-title/mob-title.component';
import { MobPriorityComponent } from 'src/app/shared/global_components/chat/dialogs/mob-priority/mob-priority.component';
import { environment } from 'src/environments/environment';
import { NotesComponent } from 'src/app/shared/global_components/notes/notes.component';
import { MobTaskEditGroupComponent } from '../../mob-task/mob-task-edit-group/mob-task-edit-group.component';
import { SharedOptionsComponent } from 'src/app/shared/global_components/shared-options/shared-options.component';
import { TagTaskComponent } from 'src/app/shared/global_components/tag-task/tag-task.component';
import { CompilationsWizardComponent } from 'src/app/shared/global_components/compilations-wizard/compilations-wizard.component';
import { MultiEditGroupsComponent } from '../../atTasksDialog/multi-edit-groups/multi-edit-groups.component';
import { CardScenarioComponent } from '../../atTasksDialog/card-scenario/card-scenario.component';
import { MoveAllCardJobsComponent } from './dialogs/move-all-card-jobs/move-all-card-jobs.component';
import { MigrateTargetFilesComponent } from 'src/app/shared/global_components/migrate-target-files/migrate-target-files.component';
import { ImportFilesComponent } from './dialogs/import-files/import-files.component';
import { ImportFilesV2Component } from './dialogs/import-files-v2/import-files-v2.component';
import { PostsCleanerComponent } from 'src/app/shared/global_components/cleaners/posts-cleaner/posts-cleaner.component';
import { CompilationAssistantComponent } from './dialogs/compilation-assistant/compilation-assistant.component';
import { AddCardScenarioComponent } from '../../atTasksDialog/card-scenario/dialogs/add-card-scenario/add-card-scenario.component';
import { AddEditLiveStreamComponent } from './dialogs/open-task/dialogs/add-edit-live-stream/add-edit-live-stream.component';
import { AddAutoLivesComponent } from '../../atTasksDialog/add-auto-lives/add-auto-lives.component';
SwiperCore.use([Mousewheel, Pagination]);

@Component({
  selector: 'app-cases',
  templateUrl: './cases.component.html',
  styleUrls: ['./cases.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ]
})
export class CasesComponent extends BaseClass implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild("mobile_filter_wrp") mobile_filter_wrp: ElementRef;
  @ViewChild("tasksContainer") tasksContainer: ElementRef;
  // @ViewChild('profilesContainer') profilesContainer: MatSelect;

  public cardsExpand = {
    jobs: false,
    execs: false,
    chats: false,
    thumbnail: false
  }
  //     posts: false,
  //     relations: false,
  public host: any = environment.host;
  public imgRoute: any = '';
  public user: any = {};
  public user_id: number;

  public isCardV2: boolean = !!this.sm.localStorageGetItem('isCardV2');
  public linkTo: string = '';
  public company: any;
  public me: any;
  public currMonthName: any;
  public company_id: any;
  public groups_partner_id: any;
  public activeLang: any;
  public operations: any;
  public operationsValues: any;
  public operationStatuses: any;
  public currencyes: any;

  private isPaused = new Subject<void>();
  private isResumed = new Subject<void>();

  public page: number = 1;
  public hasMore: boolean = true;
  public filterCount: number = 1;
  // public pagination: object = {};
  public isScrolledCollectionBtn: boolean = false;
  public collectionIsActive: boolean = false;
  public is_and_task_operation_parameter_value_id: boolean = false;
  public hasReminder: boolean = false;
  public multiDeletedCards: boolean = false;
  public reminderData: any;
  public filter: FormGroup = this.fb.group({
    count: '',
    q: '',
    task_status_id: '',
    task_content_status_id: '',
    group_id: [[]],
    content_type_id: '',
    operation_status_id: [0],
    task_parameter_value_id: [[]],
    task_operation_parameter_value_id : [[]],
    discussion_parameter_value_id: [[]],
    file_parameter_value_id : [[]],
    parameter_value_id: [[]],
    operation_employee_id: [[]],
    operation_partner_company_id: [[]],
    client_employee_id : '',
    channel_platform_id : '',
    channel_id: [[]],
    no_channel_id: [[]],
    priority: '',
    no_operation_id: '',
    task_operation_operation_id: '',
    operation_completed_at_from : '',
    operation_completed_at_to : '',
    is_content_url: '',
    is_parameter_value: '',
    order_by: 'updated_desc',
    content_status_id: '',
    id: '',
    is_original: '',
    is_related_task: '',
    is_consist_of_task: '',
    is_part_of_task: '',
    task_custom_id: "",
    internal_id: "",
    preset_id: "",
    task_company_id: '',
    origin_company_id: '',
    discussion_id: "",
    task_operation_id: "",
    q_task_name: "",
    q_task_channel_content_filename: "",
    q_task_comment: "",
    q_task_private_comment: "",
    q_task_operation_comment: "",
    q_task_operation_employee_comment: "",
    q_task_operation_private_comment: "",
    task_created_at_from: "",
    task_created_at_to: "",
    task_operation_created_at_from: "",
    task_operation_created_at_to: "",
    task_operation_completed_at_from: "",
    task_operation_completed_at_to: "",
    discussion_created_at_from: "",
    discussion_created_at_to: "",
    file_created_at_from: "",
    file_created_at_to: "",
    file_updated_at_from: "",
    file_updated_at_to: "",
    file_meta_creation_time_from: "",
    file_meta_creation_time_to: "",
    task_channel_published_at_from: "",
    task_channel_published_at_to: "",
  });
  public timeZone = new Date().getTimezoneOffset();
  public is_mobile: boolean = false;
  public tasks: any[] = [];
  public appStatusId: number;
  public profileStatuses: any;
  public sortValue: string = 'updated_desc';
  public activeStatus: number[] = [0];
  public taskCollection: any;
  public allContentTypes: any;
  public prioritys = prioritys;
  public cardContextSub: Subscription;
  public sub: Subscription;
  public subFilter: Subscription;
  public subClientFilter: Subscription;
  public execFilterSub: Subscription;
  public parameterFilterSub: Subscription;
  public taskDataSub: Subscription;
  public taskStatuses: any;
  // public values: any;
  // public parameters: any;
  // public allValues: any;
  // public paramDataSub: Subscription
  public separatorKeysCodes: number[] = [ENTER, COMMA];
  public isLoad: boolean = false;
  public dateFromChanged: boolean = false;
  public dateToChanged: boolean = false;
  public tasksSub: Subscription;
  public tasksCountSub: Subscription;
  public curYear = (new Date()).getFullYear()
  public showFilter: boolean = !!this.sm.localStorageGetItem('showFilter')

  public delCards: any[] = [];
  public delCardsPagination;
  public delCardsPage:number = 1;
  
  // public profiles: any[] = [];
  // public profilesPagination: any;
  // public profilesPage: number = 1;
  // public profilesMoreControl: FormControl = new FormControl();
  // public profiles$: ReplaySubject<any> = new ReplaySubject<any>(1);

  // public groups: any;
  // public groupsControl: FormControl = new FormControl();
  // public groups$: ReplaySubject<any> = new ReplaySubject<any>(1);
  // public partnerGroups: any;

  public card_exp: any;

  public bookmarks: any;
  public bookmarksControl: FormControl = new FormControl();
  public bookmarks$: ReplaySubject<any> = new ReplaySubject<any>(1);
  
  // public partners: any;
  // public partnersControl: FormControl = new FormControl();
  // public partners$: ReplaySubject<any> = new ReplaySubject<any>(1);
  
  // public employees: any;
  // public employees$: ReplaySubject<any> = new ReplaySubject<any>(1);
  // public execControl: FormControl = new FormControl();
  // public clientControl: FormControl = new FormControl();

  public selectedIndex: FormControl = new FormControl(0);

  public contentTypes: any[] = [];
  public contentTypeMoreControl: FormControl = new FormControl();
  public contentTypes$: ReplaySubject<any> = new ReplaySubject<any>(1);
  
  public totalTasksCount: number = 0;

  public contentPage: number = 1;
  public contentPagination: any = {};
  public today = moment().set({hour:0,minute:0,second:0}).unix();

  // public platforms: any;
  // public platforms$: ReplaySubject<any> = new ReplaySubject<any>(1);
  // public platformsMoreControl: FormControl = new FormControl();

  overlayRefFilter: OverlayRef | null;
  overlayRefClientFilter: OverlayRef | null;
  overlayExecRefFilter: OverlayRef | null;
  overlayParameterRefFilter: OverlayRef | null;
  overlayRef: OverlayRef | null;
  overlayRefCard: OverlayRef | null;
  @ViewChild('cardContext') cardContext: TemplateRef<any>;
  @ViewChild('filterMenu') filterMenu: TemplateRef<any>;
  @ViewChild('filterClientMenu') filterClientMenu: TemplateRef<any>;
  @ViewChild('filterParameterMenu') filterParameterMenu: TemplateRef<any>;
  @ViewChild('filterExecMenu') filterExecMenu: TemplateRef<any>;
  @ViewChild('taskMenu') taskMenu: TemplateRef<any>;
  public totalInfo: object =  {
    'summaryRate': 0,
    'summaryPrice': [
      {
        currency_id: 0,
        summary: 0
      },
      {
        currency_id: 1,
        summary: 0
      },
      {
        currency_id: 2,
        summary: 0
      },
      {
        currency_id: 3,
        summary: 0
      },
      {
        currency_id: 4,
        summary: 0
      }
    ],
    'totalPrice': 0
  }
  public contentUrl = [
    {
      name: marker("Without content url"),
      id: 0
    },
    {
      name: marker("With content url"),
      id: 1
    }
  ]

  public origin = window.location.origin;

  constructor(
    private sm: StorageManagerService,
    private auth: AuthService,
    private router: Router,
    private chatService: ChatService,
    private globalDataService: GlobalDataService,
    public activatedRoute: ActivatedRoute,
    private taskService: TaskService,
    private companyService: CompanyService,
    private languageService: LanguageService,
    private parametersService: ParametersService,
    private layoutService: LayoutService,
    private refreshService: RefreshService,
    private taskBarService: TaskBarService,
    private minimizeService: MinimizeService,
    private _adapter: DateAdapter<any>,
    private dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private fb: FormBuilder,
    private bottomSheet: MatBottomSheet,
    public viewContainerRef: ViewContainerRef,
    public overlay: Overlay,
    public ls: LoadingService
  ) { super() }

  ngOnInit(): void {
    if (!!this.sm.localStorageGetItem("cardsExpand")) {
      if (!JSON.parse(this.sm.localStorageGetItem("cardsExpand")).hasOwnProperty('posts')) {
        this.cardsExpand = JSON.parse(this.sm.localStorageGetItem("cardsExpand"))
        if (this.expandValues().includes(true) && !this.activatedRoute.snapshot.queryParamMap.get('expand')) {
          let addUrl = ''
          Object.keys(this.cardsExpand).forEach(key => {
            if (!!this.cardsExpand[key]) {
              if (!!addUrl) {
                addUrl = addUrl + `,${key}`
              } else {
                addUrl = `${key}`
              }
            }
          })
          this.router.navigateByUrl(this.router.url + `&expand=${addUrl}`);
          // history.replaceState(null, '', this.router.url + `&expand=${addUrl}`);
        }
      }
    }


    console.log("cardsExpand", this.cardsExpand);
    this.company_id = this.activatedRoute.snapshot.queryParamMap.get('company_id');
    this.groups_partner_id = this.activatedRoute.snapshot.queryParamMap.get('groups_partner_id');
    this.ifAllStatuses();
    this.checkIsMobile();

    this.card_exp = +this.sm.sessionStorageGetItem('card_exp');
    
    this.getLangData(this.company_id);
    this.getImgRoute();
    this.getUser();
    this.getOperationsStatus();
    this.getTaskStatus();
    this.getProfilesStatus();
    this.onRouteChange();
    this.refresh();
    this.checkNew();

    // if (!!this.groups_partner_id) {
    //   this.getPartnerGroups()
    // }

    this.attachSubscriptions(
      this.refreshService.getRefreshBookmarks().subscribe(res => {
        if (res == "Cards") {
          this.getBookmarks()
        }
      })
    )

    // this.attachSubscriptions(
    //   this.profilesMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchProfiles(resp))
    // )

    // this.attachSubscriptions(
    //   this.groupsControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchGroups(resp))
    // )

    this.attachSubscriptions(
      this.bookmarksControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchBookmarks(resp))
    )

    this.attachSubscriptions(
      this.contentTypeMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchContentTypes(resp))
    )

    // this.attachSubscriptions(
    //   this.execControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchEmoloyees(resp))
    // )

    // this.attachSubscriptions(
    //   this.clientControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchEmoloyees(resp))
    // )

    // this.attachSubscriptions(
    //   this.partnersControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchPartners(resp))
    // )
  }

  refreshPage() {
    this.filterTasks();
  }

  toggleExp(key, val) {
    console.log("toggleExp", key, val)
    if (key == 'all') {
      Object.keys(this.cardsExpand).forEach(k => {
        this.cardsExpand[k] = !val;
      })
    } else {
      this.cardsExpand[key] = !val;
      if (key == 'execs' && this.cardsExpand[key] == true) {
        this.cardsExpand.jobs = this.cardsExpand[key];
      }
      if (key == 'jobs' && this.cardsExpand[key] == false) {
        this.cardsExpand.execs = this.cardsExpand[key];
      }
    }
    console.log("this.cardsExpand", this.cardsExpand)
    this.sm.localStorageSetItem("cardsExpand", JSON.stringify(this.cardsExpand));
    this.filterTasks();
  }

  expandKeys() {
    return Object.keys(this.cardsExpand)
  }

  expandValues() {
    return Object.values(this.cardsExpand)
  }

  getHeaderStats() {
    let sources = [];
    if (!!this.company && !!this.company.id) {
      sources.push(this.taskService.getHeaderStats(this.company.id, '3', moment().utc().clone().startOf('month').format('X'), moment().utc().clone().endOf('month').format('X')))
      sources.push(this.taskService.getHeaderStats(this.company.id, '4', moment().utc().clone().startOf('month').format('X'), moment().utc().clone().endOf('month').format('X')))
      sources.push(this.taskService.getHeaderStats(this.company.id, '98', moment().utc().clone().startOf('month').format('X'), moment().utc().clone().endOf('month').format('X')))
      this.attachSubscriptions(
        forkJoin(sources).subscribe(result => {
          console.log(result)
          let rate_toAprove = result[0]['headers'].get('x-summary-rate') ? result[0]['headers'].get('x-summary-rate') : 0;
          let rate_Aproved = result[1]['headers'].get('x-summary-rate') ? result[1]['headers'].get('x-summary-rate') : 0;
          this.company.rate_pending = +rate_Aproved + +rate_toAprove;
          this.company.rate_ready = result[2]['headers'].get('x-summary-rate') ? result[2]['headers'].get('x-summary-rate') : 0;
  
          let price_toAprove = JSON.parse(result[0]['headers'].get('x-summary-price')).length ? JSON.parse(result[0]['headers'].get('x-summary-price'))[0].summary : 0;
          let price_Aproved = JSON.parse(result[1]['headers'].get('x-summary-price')).length ? JSON.parse(result[1]['headers'].get('x-summary-price'))[0].summary : 0;
          this.company.price_pending = +price_toAprove + +price_Aproved;
          this.company.price_ready = JSON.parse(result[2]['headers'].get('x-summary-price')).length ? JSON.parse(result[2]['headers'].get('x-summary-price'))[0].summary : 0;
        })
      )
    }
  }

  onSearchContentTypes(resp) {
    if (!this.contentTypes) {
      return;
    }

    const contentTypesCopy = this.copyContentTypesGroups(this.contentTypes);
    if (!resp) {
      this.contentTypes$.next(contentTypesCopy);
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    // this.contentTypes$.next(
    //   this.contentTypes.filter(z => (z.name.toLowerCase().indexOf(resp) > -1))
    // );

    // filter the banks
    this.contentTypes$.next(
      contentTypesCopy.filter(type => {
        type.types = type.types.filter(t => t.name.toLowerCase().indexOf(resp) > -1);
        return type.types.length > 0;
      })
    );

    this.contentTypes$.asObservable().subscribe(res => {
      console.log("search", res)
      console.log(this.contentTypes)
    });
  }

  protected copyContentTypesGroups(contentTypes: any) {
    const contentTypesCopy = [];
    contentTypes.forEach(type => {
      contentTypesCopy.push({
        channel_platform_id: type.channel_platform_id,
        types: type.types.slice()
      });
    });
    return contentTypesCopy;
  }

  moveAllJobs(task) {
    const dialogRef = this.dialog.open(MoveAllCardJobsComponent, {
      data: {
        company: this.company,
        task: task,
        user: this.user,
      }
    });
  }

  // onSearchPartners(resp) {
  //   if (!this.partners) {
  //     return;
  //   }

  //   if (!resp) {
  //     this.partners$.next(this.partners.slice());
  //     return;
  //   } else {
  //     resp = resp.toLowerCase();
  //   }
  //   // filter the banks
  //   this.partners$.next(
  //     this.partners.filter(z => z.partnerCompany.name.toLowerCase().indexOf(resp) > -1)
  //   );
  // }

  // onSearchEmoloyees(resp) {
  //   if (!this.employees) {
  //     return;
  //   }

  //   if (!resp) {
  //     this.employees$.next(this.employees.slice());
  //     return;
  //   } else {
  //     resp = resp.toLowerCase();
  //   }
  //   // filter the banks
  //   this.employees$.next(
  //     this.employees.filter(z => z.name.toLowerCase().indexOf(resp) > -1)
  //   );
  // }

  toShortCut(e, task) {
    this.attachSubscriptions(
      this.taskBarService.addBarItem({company_id: this.company.id, task_id: task.id, task_operation_id: 0, discussion_id: 0}).subscribe(res => {
        this.minimizeService.minimizes$.next(res)
      }, error => {
        console.log("error toShortCut")
      })
    )
  }

  onSearchBookmarks(resp) {
    if (!this.bookmarks) {
      return;
    }

    if (!resp) {
      this.bookmarks$.next(this.bookmarks.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }

    // filter the banks
    this.bookmarks$.next(
      this.bookmarks.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  getBookmarks() {
    this.attachSubscriptions(
      this.companyService.getBookmarks(this.company_id, {employee_id: this.company.employees[0].id, section: "Cards"}).subscribe(res => {
        this.bookmarks = res.filter(x => x.type != "Clipboard")
        this.bookmarks$.next(this.bookmarks.slice())
        console.log("bookmarks", this.bookmarks)
      })
    )
  }

  bookmarksChange(e) {
    this.router.navigate(['/tasks'], { queryParams: Object.assign({company_id: this.company_id, order_by: "updated_desc", preset_id: e.value}, this.getBookmarkById(e.value).settings)});
  }
  
  getBookmarkById(id) {
    if (!this.bookmarks || this.bookmarks.length == 0) {
      return null
    }
    return this.bookmarks.find(x => x.id == id)
  }

  // onSearchGroups(resp) {
  //   if (!this.groups) {
  //     return;
  //   }

  //   if (!resp) {
  //     this.groups$.next(this.groups.slice());
  //     return;
  //   } else {
  //     resp = resp.toLowerCase();
  //   }

  //   // filter the banks
  //   this.groups$.next(
  //     this.groups.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
  //   );
  // }

  // onSearchProfiles(resp) {
  //   if (!this.profiles) {
  //     return;
  //   }

  //   if (!resp) {
  //     this.profiles$.next(this.profiles.slice());
  //     return;
  //   } else {
  //     resp = resp.toLowerCase();
  //   }

  //   // filter the banks
  //   this.profiles$.next(
  //     this.profiles.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
  //   );
  // }

  changeTab(val) {
    this.selectedIndex.patchValue(val);
  }

  changeSelectionList(e, key) {
    console.log(e, key)
    console.log(e.option._value)

    this.filter.patchValue({
      [key]: e.option._value
    })
    this.closeOpenClientFilter();
    this.closeExecFilter();
    this.filterTasks();
  }

  checkNew() {
    let filterData = {...this.filter.value};
    if (this.is_and_task_operation_parameter_value_id && filterData.task_operation_parameter_value_id && filterData.task_operation_parameter_value_id.length > 1) {
      filterData.and_task_operation_parameter_value_id = filterData.task_operation_parameter_value_id;
      delete filterData.task_operation_parameter_value_id;
    }
    this.attachSubscriptions(
      this.taskService.getNewCard().pipe(
        concatMap(res => {
          if ( this.tasks.filter(x => x.id == res.task_id).length == 0 && this.company_id == res.company_id ) {
            return this.taskService.getCardsExpand(1, this.company_id, Object.assign(filterData, {id: res.task_id}), '1', +this.sm.sessionStorageGetItem('card_exp'), 0).pipe(
              filter(x => x.length > 0),
              map(x => x[0]),
              switchMap(x => this.neededData(x).pipe(map(() => casesModel([x], this.tasks, 'update')),map(x => x.arr[0]))),
              tap(x => {
                this.tasks.unshift(x)
              })
            )
          } else {
            return of(false)
          }    
        })
      ).subscribe(resp => {
        console.log("checkNew", resp)
      })
    )
  }

  refresh() {
    this.attachSubscriptions(
      this.refreshService.getRefreshTasks().pipe(
        concatMap(res => {
          if ( this.tasks.filter(x => x.id == res.task_id).length > 0 && this.company_id == res.company_id ) {
            return this.taskService.getOneTaskExpand(this.company_id, res.task_id).pipe(
              map(x => x.body[0]),
              switchMap(x => this.neededData(x).pipe(map(() => casesModel([x], this.tasks, 'update')),map(x => x.arr[0]))),
              tap(x => {
                this.tasks.splice(this.tasks.indexOf(this.tasks.find(b => b.id == x.id)),1, x)
              })
            )
          } else {
            return of(false)
          }    
        })
      ).subscribe(resp => {
        console.log("getRefreshTasks", resp)
      })
    )
  }

  neededData(task) {
    let arr = [
      this.chatService.getTasksChats(this.company_id, [task.id]).pipe(
        tap(res => {
          task['operations'].map(o_el => {
            o_el['chatsInfo'] = [];
            o_el['chatsInfo'].push(...res.filter(z => z.task_operation_id == o_el.id));
            o_el['unReadChats'] = res.filter(z => z.task_operation_id == o_el.id && z.is_read != '1').length;
            o_el['unClosedChats'] = res.filter(z => z.task_operation_id == o_el.id && z.status_id != '4').length;
          });
          task['chatsInfo'] = [];
          task['chatsInfo'].push(...res.filter(z => z.task_id == task.id));
          task['unReadChats'] = res.filter(z => z.is_read != '1').length;
          task['unClosedChats'] = res.filter(z => z.status_id != '4').length;
        })
      )
    ]
    // if (task.company_id == this.company.id) {
    //   arr.push(
    //     this.taskService.getTaskClients(task.id, this.company_id).pipe(
    //       tap(res => {
    //         task.clients = res
    //       }),
    //       catchError(err => {
    //         return err
    //       })
    //     )
    //   )
    // }

    if (this.cardsExpand.chats) {
      return forkJoin(arr).pipe(
        tap(x => {
          task.neededIsGetting = true;
          task.neededIsGettingAt = moment().format('X');
        })
      )
    } else {
      return of([])
    }
  }

  onStartDateChange(e: MatDatepickerInputEvent<Date, Date>) {
    if (e && e.value) {
      this.dateFromChanged = true;
    }
    this.checkRange();
  }

  onEndDateChange(e: MatDatepickerInputEvent<Date, Date>) {
    if (e && e.value) {
      this.dateToChanged = true;
    }
    this.checkRange();
  }

  changeView(e: MatButtonToggleChange) {
    if (e.value == "executor") {
      this.filter.patchValue({
        operation_employee_id: [this.me.id],
        operation_partner_company_id: []
      })
      this.filterTasks()
    } else {
      this.filter.patchValue({
        operation_employee_id: [],
        operation_partner_company_id: []
      })
      this.filterTasks()
    }
  }

  checkRange(): void {
    if (this.dateFromChanged || this.dateToChanged) {
      this.dateFromChanged = false;
      this.dateToChanged = false;
      this.filterTasks()
    }
  }

  copyLink(type) {
    this.layoutService.showSnackBar(marker(type + " link"), marker("Copied"), SnackBarItem)
  }

  checkIsManager(task, company, _user) { 
    return task.managers.filter(x => x.user_id == _user.id).length > 0 || task?.group?.managers.filter(x => x.user_id == _user.id).length > 0 || company?.permissions.includes('admin') || company?.permissions.includes('manager'); 
  }
  
  noOperation(e, id, jobTypeSelect: MatSelect) {
    e.preventDefault();
    e.stopPropagation();
    this.filter.patchValue({
      no_operation_id: id,
    })

    jobTypeSelect.close();
    this.filterTasks()
  }

  removeValueFromMultiple(obj) {
    let key = obj.key;
    let value = obj.id;
    let arr = this.filter.value[key].slice();
    if (arr.includes(value)) {
      arr.splice(arr.indexOf(value), 1)
    }
    this.filter.patchValue({
      [key]: arr
    })
    this.filterTasks()
  }

  removeValue(val) {
    if (val == "all") {
      this.activeStatus = [0];
      this.filter.patchValue({
        count: '',
        q: '',
        task_status_id: '',
        task_content_status_id: '',
        is_related_task: '',
        is_original: '',
        is_consist_of_task: '',
        is_part_of_task: '',
        group_id: [],
        content_type_id: '',
        no_operation_id: '',
        no_channel_id: [],
        channel_id: [],
        operation_status_id: [],
        task_parameter_value_id: [],
        task_operation_parameter_value_id : [],
        discussion_parameter_value_id: [],
        file_parameter_value_id: [],
        parameter_value_id: [],
        operation_employee_id: [],
        operation_partner_company_id: [],
        client_employee_id : '',
        channel_platform_id : '',
        task_company_id : '',
        origin_company_id : '',
        task_operation_operation_id: '',
        operation_completed_at_from : '',
        operation_completed_at_to : '',
        is_content_url: '',
        is_parameter_value: '',
        task_created_at_from: "",
        task_created_at_to: "",
        task_operation_created_at_from: "",
        task_operation_created_at_to: "",
        task_operation_completed_at_from: "",
        task_operation_completed_at_to: "",
        discussion_created_at_from: "",
        discussion_created_at_to: "",
        file_created_at_from: "",
        file_created_at_to: "",
        file_updated_at_from: "",
        file_updated_at_to: "",
        file_meta_creation_time_from: "",
        file_meta_creation_time_to: "",
        task_channel_published_at_from: "",
        task_channel_published_at_to: "",
        order_by: 'updated_desc',
        content_status_id: '',
        id: ''
      })
      this.filterReset()
    } else if (val == "operation_status_id") {
      this.changeActiveStatus(0);
    } else if (["operation_employee_id","operation_partner_company_id"].includes(val)) {
      this.filter.patchValue({
        [val]: []
      })
      this.filterTasks()
    } else {
      this.filter.patchValue({
        [val]: ''
      })
      this.filterTasks()
    }
  }

  onRouteChange() {
    this.attachSubscriptions(
      this.activatedRoute.queryParams.pipe(
        tap((val) => {
          this.getCurrencyList();
          if (this.taskDataSub) {
            this.taskDataSub.unsubscribe();
          }
          // if (this.paramDataSub) {
          //   this.paramDataSub.unsubscribe();
          // }
          if (this.tasksSub) {
            this.tasksSub.unsubscribe()
          }

          if (this.company_id != this.activatedRoute.snapshot.queryParamMap.get('company_id')) {
            this.getLangData(this.activatedRoute.snapshot.queryParamMap.get('company_id'));
          }

          if (this.activatedRoute.snapshot.queryParamMap.get('expand')) {
            this.activatedRoute.snapshot.queryParamMap.get('expand').split(',').forEach(key => {
              this.cardsExpand[key] = true;
            })
            
            this.sm.localStorageSetItem("cardsExpand", JSON.stringify(this.cardsExpand));
          }

          this.company_id = this.activatedRoute.snapshot.queryParamMap.get('company_id');
          this.sortValue = !!this.activatedRoute.snapshot.queryParamMap.get('order_by') ? this.activatedRoute.snapshot.queryParamMap.get('order_by') : "updated_desc";
          this.filter.patchValue({
            count: this.filterCount,
            id: this.activatedRoute.snapshot.queryParamMap.get('id') ? this.activatedRoute.snapshot.queryParamMap.get('id') : '',
            q: !!this.activatedRoute.snapshot.queryParamMap.get('q') ? this.activatedRoute.snapshot.queryParamMap.get('q') : "",
            no_operation_id: !!this.activatedRoute.snapshot.queryParamMap.get('no_operation_id') ? +this.activatedRoute.snapshot.queryParamMap.get('no_operation_id') : "",
            no_channel_id: !!this.activatedRoute.snapshot.queryParamMap.get('no_channel_id') ? this.activatedRoute.snapshot.queryParamMap.get('no_channel_id').split(',').map(Number) : [],
            channel_id: !!this.activatedRoute.snapshot.queryParamMap.get('channel_id') ? this.activatedRoute.snapshot.queryParamMap.get('channel_id').split(',').map(Number) : [],
            priority: !!this.activatedRoute.snapshot.queryParamMap.get('priority') ? +this.activatedRoute.snapshot.queryParamMap.get('priority') : "",
            task_status_id: !!this.activatedRoute.snapshot.queryParamMap.get('task_status_id') ? +this.activatedRoute.snapshot.queryParamMap.get('task_status_id') : '',
            task_content_status_id: !!this.activatedRoute.snapshot.queryParamMap.get('task_content_status_id') ? +this.activatedRoute.snapshot.queryParamMap.get('task_content_status_id') : '',
            content_status_id: !!this.activatedRoute.snapshot.queryParamMap.get('content_status_id') ? +this.activatedRoute.snapshot.queryParamMap.get('content_status_id') : '',
            is_related_task: !!this.activatedRoute.snapshot.queryParamMap.get('is_related_task') ? +this.activatedRoute.snapshot.queryParamMap.get('is_related_task') : '',
            is_original: !!this.activatedRoute.snapshot.queryParamMap.get('is_original') ? +this.activatedRoute.snapshot.queryParamMap.get('is_original') : +0,
            is_consist_of_task: !!this.activatedRoute.snapshot.queryParamMap.get('is_consist_of_task') ? +this.activatedRoute.snapshot.queryParamMap.get('is_consist_of_task') : '',
            is_part_of_task: !!this.activatedRoute.snapshot.queryParamMap.get('is_part_of_task') ? +this.activatedRoute.snapshot.queryParamMap.get('is_part_of_task') : '',
            group_id: !!this.activatedRoute.snapshot.queryParamMap.get('group_id') ? this.activatedRoute.snapshot.queryParamMap.get('group_id').split(',').map(Number) : [],
            content_type_id: !!this.activatedRoute.snapshot.queryParamMap.get('content_type_id') ? +this.activatedRoute.snapshot.queryParamMap.get('content_type_id') : "",
            operation_status_id: !!this.activatedRoute.snapshot.queryParamMap.get('operation_status_id') ? this.activatedRoute.snapshot.queryParamMap.get('operation_status_id').split(',').map(Number) : [0],
            task_parameter_value_id: !!this.activatedRoute.snapshot.queryParamMap.get('task_parameter_value_id') ? this.activatedRoute.snapshot.queryParamMap.get('task_parameter_value_id').split(',').map(Number) : [],
            task_operation_parameter_value_id: !!this.activatedRoute.snapshot.queryParamMap.get('task_operation_parameter_value_id') ? this.activatedRoute.snapshot.queryParamMap.get('task_operation_parameter_value_id').split(',').map(Number) : [],
            discussion_parameter_value_id: !!this.activatedRoute.snapshot.queryParamMap.get('discussion_parameter_value_id') ? this.activatedRoute.snapshot.queryParamMap.get('discussion_parameter_value_id').split(',').map(Number) : [],
            file_parameter_value_id: !!this.activatedRoute.snapshot.queryParamMap.get('file_parameter_value_id') ? this.activatedRoute.snapshot.queryParamMap.get('file_parameter_value_id').split(',').map(Number) : [],
            parameter_value_id: !!this.activatedRoute.snapshot.queryParamMap.get('parameter_value_id') ? this.activatedRoute.snapshot.queryParamMap.get('parameter_value_id').split(',').map(Number) : [],
            operation_employee_id: !!this.activatedRoute.snapshot.queryParamMap.get('operation_employee_id') ? [+this.activatedRoute.snapshot.queryParamMap.get('operation_employee_id')] : [],
            operation_partner_company_id: !!this.activatedRoute.snapshot.queryParamMap.get('operation_partner_company_id') ? [+this.activatedRoute.snapshot.queryParamMap.get('operation_partner_company_id')] : [],
            client_employee_id: !!this.activatedRoute.snapshot.queryParamMap.get('client_employee_id') ? +this.activatedRoute.snapshot.queryParamMap.get('client_employee_id') : '',
            channel_platform_id: !!this.activatedRoute.snapshot.queryParamMap.get('channel_platform_id') ? +this.activatedRoute.snapshot.queryParamMap.get('channel_platform_id') : '',
            task_operation_operation_id: !!this.activatedRoute.snapshot.queryParamMap.get('task_operation_operation_id') ? +this.activatedRoute.snapshot.queryParamMap.get('task_operation_operation_id').split(',')[0] : '',
            operation_completed_at_from: !!this.activatedRoute.snapshot.queryParamMap.get('operation_completed_at_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('operation_completed_at_from')*1000).utc() : "",
            operation_completed_at_to: this.activatedRoute.snapshot.queryParamMap.get('operation_completed_at_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('operation_completed_at_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('operation_completed_at_to')*1000).utc() : ""),
            order_by: !!this.activatedRoute.snapshot.queryParamMap.get('order_by') ? this.activatedRoute.snapshot.queryParamMap.get('order_by') : "updated_desc",
            is_content_url: (!!this.activatedRoute.snapshot.queryParamMap.get('is_content_url') || this.activatedRoute.snapshot.queryParamMap.get('is_content_url') == '0') ? +this.activatedRoute.snapshot.queryParamMap.get('is_content_url') : "", 
            is_parameter_value: (!!this.activatedRoute.snapshot.queryParamMap.get('is_parameter_value') || this.activatedRoute.snapshot.queryParamMap.get('is_parameter_value') == '0') ? +this.activatedRoute.snapshot.queryParamMap.get('is_parameter_value') : "", 
            task_custom_id: this.activatedRoute.snapshot.queryParamMap.get('task_custom_id') ? this.activatedRoute.snapshot.queryParamMap.get('task_custom_id') : '',
            internal_id: this.activatedRoute.snapshot.queryParamMap.get('internal_id') ? +this.activatedRoute.snapshot.queryParamMap.get('internal_id') : '',
            preset_id: this.activatedRoute.snapshot.queryParamMap.get('preset_id') ? +this.activatedRoute.snapshot.queryParamMap.get('preset_id') : '',
            task_company_id: this.activatedRoute.snapshot.queryParamMap.get('task_company_id') ? +this.activatedRoute.snapshot.queryParamMap.get('task_company_id') : '',
            origin_company_id: this.activatedRoute.snapshot.queryParamMap.get('origin_company_id') ? +this.activatedRoute.snapshot.queryParamMap.get('origin_company_id') : '',
            discussion_id: this.activatedRoute.snapshot.queryParamMap.get('discussion_id') ? +this.activatedRoute.snapshot.queryParamMap.get('discussion_id') : '',
            task_operation_id: this.activatedRoute.snapshot.queryParamMap.get('task_operation_id') ? +this.activatedRoute.snapshot.queryParamMap.get('task_operation_id') : '',
            q_task_name: this.activatedRoute.snapshot.queryParamMap.get('q_task_name') ? this.activatedRoute.snapshot.queryParamMap.get('q_task_name') : '',
            q_task_channel_content_filename: this.activatedRoute.snapshot.queryParamMap.get('q_task_channel_content_filename') ? this.activatedRoute.snapshot.queryParamMap.get('q_task_channel_content_filename') : '',
            q_task_comment: this.activatedRoute.snapshot.queryParamMap.get('q_task_comment') ? this.activatedRoute.snapshot.queryParamMap.get('q_task_comment') : '',
            q_task_private_comment: this.activatedRoute.snapshot.queryParamMap.get('q_task_private_comment') ? this.activatedRoute.snapshot.queryParamMap.get('q_task_private_comment') : '',
            q_task_operation_comment: this.activatedRoute.snapshot.queryParamMap.get('q_task_operation_comment') ? this.activatedRoute.snapshot.queryParamMap.get('q_task_operation_comment') : '',
            q_task_operation_employee_comment: this.activatedRoute.snapshot.queryParamMap.get('q_task_operation_employee_comment') ? this.activatedRoute.snapshot.queryParamMap.get('q_task_operation_employee_comment') : '',
            q_task_operation_private_comment: this.activatedRoute.snapshot.queryParamMap.get('q_task_operation_private_comment') ? this.activatedRoute.snapshot.queryParamMap.get('q_task_operation_private_comment') : '',
            task_created_at_from: this.activatedRoute.snapshot.queryParamMap.get('task_created_at_from') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('task_created_at_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('task_created_at_from')*1000) : ""),
            task_created_at_to: this.activatedRoute.snapshot.queryParamMap.get('task_created_at_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('task_created_at_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('task_created_at_to')*1000) : ""),
            task_operation_created_at_from: this.activatedRoute.snapshot.queryParamMap.get('task_operation_created_at_from') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('task_operation_created_at_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('task_operation_created_at_from')*1000) : ""),
            task_operation_created_at_to: this.activatedRoute.snapshot.queryParamMap.get('task_operation_created_at_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('task_operation_created_at_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('task_operation_created_at_to')*1000) : ""),
            task_operation_completed_at_from: this.activatedRoute.snapshot.queryParamMap.get('task_operation_completed_at_from') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('task_operation_completed_at_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('task_operation_completed_at_from')*1000) : ""),
            task_operation_completed_at_to: this.activatedRoute.snapshot.queryParamMap.get('task_operation_completed_at_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('task_operation_completed_at_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('task_operation_completed_at_to')*1000) : ""),
            discussion_created_at_from: this.activatedRoute.snapshot.queryParamMap.get('discussion_created_at_from') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('discussion_created_at_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('discussion_created_at_from')*1000) : ""),
            discussion_created_at_to: this.activatedRoute.snapshot.queryParamMap.get('discussion_created_at_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('discussion_created_at_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('discussion_created_at_to')*1000) : ""),
            file_created_at_from: this.activatedRoute.snapshot.queryParamMap.get('file_created_at_from') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('file_created_at_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('file_created_at_from')*1000) : ""),
            file_created_at_to: this.activatedRoute.snapshot.queryParamMap.get('file_created_at_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('file_created_at_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('file_created_at_to')*1000) : ""),
            file_updated_at_from: this.activatedRoute.snapshot.queryParamMap.get('file_updated_at_from') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('file_updated_at_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('file_updated_at_from')*1000) : ""),
            file_updated_at_to: this.activatedRoute.snapshot.queryParamMap.get('file_updated_at_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('file_updated_at_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('file_updated_at_to')*1000) : ""),
            file_meta_creation_time_from: this.activatedRoute.snapshot.queryParamMap.get('file_meta_creation_time_from') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('file_meta_creation_time_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('file_meta_creation_time_from')*1000) : ""),
            file_meta_creation_time_to: this.activatedRoute.snapshot.queryParamMap.get('file_meta_creation_time_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('file_meta_creation_time_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('file_meta_creation_time_to')*1000) : ""),
            task_channel_published_at_from: this.activatedRoute.snapshot.queryParamMap.get('task_channel_published_at_from') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('task_channel_published_at_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('task_channel_published_at_from')*1000) : ""),
            task_channel_published_at_to: this.activatedRoute.snapshot.queryParamMap.get('task_channel_published_at_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('task_channel_published_at_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('task_channel_published_at_to')*1000) : ""),
          })

          console.log("FILTER VALUE", this.filter.value)
        }),
        switchMap((val) => {
          console.log("switchMap", this.company)
          if (this.company == undefined || this.company.id != this.activatedRoute.snapshot.queryParamMap.get('company_id')) {
            return this.companyService.getCompany(this.activatedRoute.snapshot.queryParamMap.get('company_id')).pipe(
              map(x => x[0]),
              tap(x => {
                this.company = x;
                this.me = this.company.employees[0];
                this.companyService.company$.next(x);
                if (x.employees.find(x => x.user_id == this.user.id)) {
                  this.appStatusId = x.employees.find(x => x.user_id == this.user.id).application_status_id
                }
                if (!!x && !!x.employees && !!x.employees[0]) {
                  this.company['rate_target'] = x.employees[0].rate_target
                }

                // COMMENT BEFORE MERGE
                // const dialogRef = this.dialog.open(CreateTaskComponent, {
                //   disableClose: true,
                //   data: {
                //     company_id: this.company_id,
                //     company: this.company,
                //     imgRoute: this.imgRoute,
                //     host: this.host,
                //   }
                // });
            
              }),
              map(x => val)
            )
          } else {
            return of(val)
          }
        }),
        switchMap((val:any) => {
          return this.taskService.getReminderTasksCount(this.company_id).pipe(
            tap((resp) => {
              this.reminderData = resp
              if (+resp.my > 0) {
                this.hasReminder = true;
                if (this.filter.value.operation_status_id.includes(0) || this.filter.value.operation_status_id.includes(1)) {
                  this.companyService.reminder$.next({count: +resp.my, company: this.company});
                }
              } else if (+resp.all > 0) {
                this.hasReminder = true;
              }
            }),
            map(() => val)
          )
        })
      ).subscribe(params => {
        console.log("route STREAM", params);
        this.linkTo = '';
        this.taskCollection = new SelectionModel(
          true,
          []
        )

        // this.attachSubscriptions(
        //   this.taskService.getTaskClientsDyn("1", 501, this.company_id, '200', {task_operation_id: `0,1526`, client_employee_id: this.company.employees[0].id, is_principal: 1}).pipe(map((n) => n.body)).subscribe(resp => {
        //     console.log("getTaskClientsDyn", resp)
        //   })
        // )

        if (!!this.activatedRoute.snapshot.queryParamMap.get('groups_partner_id') && this.groups_partner_id != +this.activatedRoute.snapshot.queryParamMap.get('groups_partner_id')) {
          this.groups_partner_id = +this.activatedRoute.snapshot.queryParamMap.get('groups_partner_id');
          // this.getPartnerGroups()
        }
        // console.log("this.linkTo", this.linkTo)
        // console.log('this.linkTo.split("_")[1]', this.linkTo.split("_")[1])
        // console.log("this.filter.value", this.filter.value)

        this.tasks = [];
        // this.profiles = [];
        // this.profiles$.next(this.profiles.slice());
        this.page = 1;
        // this.profilesPage = 1;

        if (this.activatedRoute.snapshot.queryParamMap.get('add_task')) {
          if (this.activatedRoute.snapshot.queryParamMap.get('is_employee') == 'true') {
            this.emplCreateTask();
          } else {
            this.createTask();
          }
          this.filterReset();
        }
        
        
        
        if (!!this.activatedRoute.snapshot.queryParamMap.get('operation_status_id')) {
          this.activeStatus = this.activatedRoute.snapshot.queryParamMap.get('operation_status_id').split(',').map(Number)
        } else {
          this.activeStatus = [0]
        }


        // this.paramDataSub = this.parametersService.getParamsStream().pipe(
        //   concatMap(params => {
        //     return forkJoin([...params.map(oneParam => this.neededParametersData(oneParam))]).pipe(last(),map(x => params))
        //   }),
        // ).subscribe(resp => console.log("-----getParamsStream-----",resp));
      
        this.taskDataSub = this.taskService.getTaskDataStream().pipe(
          concatMap(tasksInPage => {
            return concat(...tasksInPage.map(taskInPage => this.neededData(taskInPage))).pipe(last(),map(x => tasksInPage))
          }),
        ).subscribe(resp => console.log("-----getTaskDataStream-----",resp));
        
        // this.taskDataSub = this.taskService.getTaskDataStream().pipe(
        //   concatMap(tasksInPage => {
        //     return concat(...tasksInPage.map(taskInPage => 
        //       this.neededData(taskInPage)
        //     )).pipe(
        //       takeUntil(this.isPaused),
        //       repeatWhen(() => this.isResumed),
        //       last(),
        //       map(() => tasksInPage)
        //     )
        //   })
        // ).subscribe(resp => console.log("-----getTaskDataStream-----", resp));

        // this.taskDataSub = this.taskService.getTaskDataStream().pipe(
        //   concatMap(tasksInPage => {
        //     return of(...tasksInPage).pipe(
        //       expand((task, index) => {
        //         if (index < tasksInPage.length) {
        //           return this.neededData(tasksInPage[index]).pipe(
        //             takeUntil(this.isPaused),
        //             repeatWhen(() => this.isResumed)
        //           );
        //         } else {
        //           return of(null);
        //         }
        //       }, 1),
        //       map(() => tasksInPage)
        //     )
        //   })
        // ).subscribe(resp => console.log("-----getTaskDataStream-----", resp));

        this.getBookmarks();
        this.getTasks(this.page);
      })
    )
  }

  pause() {
    console.log("pause")
    // if (this.taskDataSub) {
    //   this.taskDataSub.unsubscribe()
    // }
  }

  resume() {
    console.log("resume")
    // if (this.taskDataSub) {
    //   this.taskDataSub.unsubscribe()
    // }
    // this.taskDataSub = this.taskService.getTaskDataStream().pipe(
    //   concatMap(tasksInPage => {
    //     return concat(...tasksInPage.map(taskInPage => this.neededData(taskInPage))).pipe(last(),map(x => tasksInPage))
    //   }),
    // ).subscribe(resp => console.log("-----getTaskDataStream-----",resp));
    // this.taskService.taskData$.next(this.tasks.filter(u => !u.neededIsGetting))
  }

  goToReminderCases() {
    console.log("goToReminderCases");
    let params:any = {company_id: this.company_id, operation_filter: 1, operation_is_reminder: 1, order_by: "updated_desc"}
    if (this.reminderData && +this.reminderData.my > 0 && !!this.company.employees && !!this.company.employees.length) {
      params.operation_employee_id = this.company.employees[0].id
    }
    this.router.navigate(['/reminder-tasks'], { queryParams: params});
    // , operation_employee_id: this.data.company.employees[0].id, operation_filter: 1, operation_is_reminder: 1, 
  }

  openJob(task_id, task_operation_id) {
    this.attachSubscriptions(
      this.taskService.getOneTaskExpand(this.company_id, task_id).pipe(
        map(x => x.body[0]),
        switchMap(x => this.neededData(x).pipe(map(() => casesModel([x], [], 'update')),map(x => x.arr[0]))),
      ).subscribe(resp => {
        const dialogRef = this.dialog.open(OpenJobComponent, {
          backdropClass: 'backdrop_under_header',
          autoFocus: false,
          panelClass: !this.is_mobile ? ['open_task_dialog', 'show_header'] : 'open_task_dialog',
          data: {
            task_id: task_id,
            task_operation_id: task_operation_id,
            task: resp,
          }
        });
      })
    )
  }

  openTask(event, task, index) {
    if (event.ctrlKey || event.metaKey) { // для Mac OS
      event.preventDefault();
      event.stopPropagation();
      // Если нажат Ctrl или Command (на Mac), то добавляем или удаляем из выбранных
      const selectedIndex = this.taskCollection.selected.indexOf(task.id);
      if (selectedIndex === -1) {
        this.taskCollection.select(task.id);
      } else {
        this.taskCollection.deselect(task.id)
      }
      return
    } else if (event.shiftKey) {
      event.preventDefault();
      event.stopPropagation();
      // Если нажат Shift и уже есть выбранные элементы
      if (this.taskCollection.selected.length === 0) {
        // Если нет выбранных элементов, начинаем выбор с первого элемента
        this.taskCollection.select(task.id);
      } else {
        // Если уже есть выбранные элементы
        const firstSelectedIndex = this.tasks.findIndex(x => x.id == this.taskCollection.selected[0]);
        console.log("firstSelectedIndex", firstSelectedIndex)
        const start = Math.min(firstSelectedIndex, index);
        const end = Math.max(firstSelectedIndex, index);
        console.log("SE", start, end)
        this.taskCollection.clear();
        for (let i = start; i <= end; i++) {
          this.taskCollection.select(this.tasks[i].id);
        }
      }
      return
    } 

    this.pause();

    let taskData:any = {
      company: this.company,
      task_id: task.id,
      task: JSON.parse(JSON.stringify(task, this.getCircularReplacer())),
      onPause: () => {this.pause()},
      onResume: () => {this.resume()},
    }

    if (this.tasks.length) {
      taskData.tasks = this.tasks
    }
    const dialogRef = this.dialog.open(OpenTaskComponent, {
      backdropClass: 'backdrop_under_header',
      panelClass: !this.is_mobile ? ['open_task_dialog', 'show_header'] : 'open_task_dialog',
      autoFocus: false,
      data: taskData
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        this.resume();
      })
    )
  }

  
  getCircularReplacer() {
    const ancestors:any = [];
    return function (key, value) {
      if (typeof value !== "object" || value === null) {
        return value;
      }
      // `this` is the object that value is contained in,
      // i.e., its direct parent.
      while (ancestors.length > 0 && ancestors.at(-1) !== this) {
        ancestors.pop();
      }
      if (ancestors.includes(value)) {
        return "[Circular]";
      }
      ancestors.push(value);
      return value;
    };
  }
  
  getProfilesStatus() {
    this.attachSubscriptions(
      this.globalDataService.getContentStatus().subscribe(resp => {
        console.log("getProfilesStatus", resp)
        this.profileStatuses = resp.slice();
      })
    )
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  getUser() {
    this.attachSubscriptions(
      this.auth.$user.subscribe(resp => {
        this.user = resp;
      })
    )
  }

  getRateStats() {
    this.attachSubscriptions(
      this.taskService.getRateStats(this.company_id, moment().utc().clone().startOf('month').format('X'), moment().utc().clone().endOf('month').format('X')).subscribe(resp => {
        this.taskService.userStats$.next(resp);
      })
    )
  }

  // getProfiles(company_id, page) {
  //   this.attachSubscriptions(
  //     this.companyService.getProfiles(company_id, page).pipe(
  //       tap(el => {
  //         this.profilesPagination = {
  //           'pageCount': el.headers.get('x-pagination-page-count'),
  //           'perPage': el.headers.get('x-pagination-per-page'),
  //           'totalCount': el.headers.get('x-pagination-total-count'),
  //           'currentPage': el.headers.get('x-pagination-current-page'),
  //         }
  //       }),
  //       map(el => el.body)
  //     ).subscribe(resp => {
  //       console.log("getProfiles", resp)

  //       if (page == 1) {
  //         this.profiles = resp
  //         this.getContentTypes("", this.contentPage);
  //       } else {
  //         resp.forEach(profile => {
  //           if (this.profiles.filter(x => x.id == profile.id).length == 0) {
  //             this.profiles.push(...resp);
  //           }
  //         })
  //       }
  //       this.profiles$.next(this.profiles.slice());
  //       this.profilesPage = this.profilesPage + 1;
  //     })
  //   )
  // }

  compilations() {
    const dialogRef = this.dialog.open(CompilationsWizardComponent, {
      backdropClass: 'backdrop_under_header',
      panelClass: !this.is_mobile ? ['open_task_dialog', 'wizard_dialog', 'show_header'] : ['open_task_dialog', 'wizard_dialog'],
      autoFocus: false,
      data: {
        company_id: this.company_id,
        company: this.company,
        tasks: this.tasks,
        is_mobile: this.is_mobile,
        imgRoute: this.imgRoute,
        user: this.user,
        activeLang: this.activeLang,
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log("compilations closed", result);
      })
    )
  }

  emplCreateTask() {
    const dialogRef = this.dialog.open(EmplCreateTaskComponent, {
      disableClose: true,
      data: {
        company_id: this.company_id,
        company: this.company
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log("getONE BEFORE", result);
        if (!!result && result.event == 'Add') {
          this.taskService.getOneTaskExpand(this.company_id, result.data.id).subscribe(resp => {
            console.log("getONE", resp.body[0]);
            this.addRowData(resp.body[0]);
          })
        }
      })
    )
  }

  createTask() {
    if (this.is_mobile) {
      const dialogRef = this.dialog.open(MobAddTaskComponent, {
        backdropClass: ['mob_interface_backdrop'],
        panelClass: ['mob_interface_panel', 'animate__animated', 'animate__slideInUp'],
        disableClose: true,
        data: {
          company_id: this.company_id
        }
      });
      this.attachSubscriptions(
        dialogRef.afterClosed().subscribe(result => {
          
          if (!!result && result.event == 'Add') {
            this.attachSubscriptions(
              this.taskService.getOneTaskExpand(this.company_id, result.data.id).subscribe(resp => {
                this.addRowData(resp.body[0]);
              })
            )
          }
        })
      )
    } else {
      const dialogRef = this.dialog.open(CreateTaskComponent, {
        disableClose: true,
        data: {
          company_id: this.company_id
        }
      });
      this.attachSubscriptions(
        dialogRef.afterClosed().subscribe(result => {
          
          if (!!result && result.event == 'Add') {
            this.attachSubscriptions(
              this.taskService.getOneTaskExpand(this.company_id, result.data.id).subscribe(resp => {
                this.addRowData(resp.body[0]);
              })
            )
          }
        })
      )

    }
    

  }

  // getPartnerGroups() {
  //   this.attachSubscriptions(
  //     this.companyService.getInfiniteGroupsPartnerCompany(this.company_id, this.groups_partner_id, '1', '1').pipe(
  //       switchMap(el => {
  //         let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
  //         let arr = []
  //         for (let index = 1; index <= pages; index++) {
  //           arr.push(index)
  //         }

  //         return forkJoin(arr.map(x => this.companyService.getInfiniteGroupsPartnerCompany(this.company_id, this.groups_partner_id, x, '200').pipe(map(u => u.body)))).pipe(
  //           last(),
  //         )
  //       }),
  //     ).subscribe(res => {
  //       this.partnerGroups = [].concat(...res);
  //       console.log("getPartnerGroups", this.partnerGroups);
  //     })
  //   )
  // }

  // getPartnerGroup(id) {
  //   if (!this.partnerGroups || !this.partnerGroups.find(el => el.id == id)) {
  //     return false;
  //   }
  //   return this.partnerGroups.find(el => el.id == id)
  // }  

  // getGroupsCompany() {
  //   this.attachSubscriptions(
  //     this.companyService.getInfiniteGroupsCompany(this.company_id, '1', '1').pipe(
  //       switchMap(el => {
  //         let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
  //         let arr = []
  //         for (let index = 1; index <= pages; index++) {
  //           arr.push(index)
  //         }

  //         return forkJoin(arr.map(x => this.companyService.getInfiniteGroupsCompany(this.company_id, x).pipe(map(u => u.body)))).pipe(
  //           last(),
  //         )
  //       }),
  //     ).subscribe(res => {
  //       this.groups = [].concat(...res);
  //       this.groups$.next(this.groups.slice());
  //     })
  //   )
  // }

  // getEmployees() {
  //   this.attachSubscriptions(
  //     this.taskService.getEmployees(this.company_id).subscribe(resp => {
  //       this.employees = resp;
  //       this.employees$.next(this.employees.slice());
  //     })
  //   )
  // }

  getLangData(id) {
    this.attachSubscriptions(
      this.languageService.getLangData().pipe(
        tap(resp => {
          this.activeLang = resp.active;
          this._adapter.setLocale(resp.active);
          this.currMonthName = moment().format('MMMM');
        }),
        switchMap(() => this.taskService.getOperations(id, this.activeLang).pipe(
          tap(x => {
            this.operationsValues = x;
          })
        ))
      ).subscribe(resp => {
        console.log("getOperations", resp)
      })
    )
  }


  addRowData(row_obj) {
    let data = casesModel([row_obj], JSON.parse(JSON.stringify(this.tasks)), "add")
    this.tasks.unshift(...data.arr);
    // this.pagination['totalCount'] = +this.pagination['totalCount'] + 1;
  }

  checkIsMobile() {
    if (window.innerWidth <= 769) {
      this.is_mobile = true;
    } else {
      this.is_mobile = false;
    }
    this.onResize();
  }

  onResize() {
    this.attachSubscriptions(
      fromEvent(window, "resize").pipe(
        map(() => window.innerWidth)
      ).subscribe((wWidth) => {
          if (wWidth <= 769) {
            this.is_mobile = true;
          } else {
            this.is_mobile = false;
          }
        }
      )
    )
  }

  ifAllStatuses() {
    this.attachSubscriptions(
      this.filter.get('operation_status_id').valueChanges.subscribe(val => {
        if (val.sort().toString() == [1,2,3,4,5,6,97,98,99].toString() || val.sort().toString() == [0,1,2,3,4,5,6,97,98,99].toString()) {
          this.filter.patchValue({
            operation_status_id: [0]
          })
        }
      })
    )
  }

  ngAfterViewInit(): void {
    
    if (this.is_mobile) {
      this.onMobScroll()
    } else {
      this.onScroll();
    }

    // if (this.profilesContainer) {
    //   this.attachSubscriptions(
    //     this.profilesContainer.openedChange.subscribe((e) => {
    //       if (e) {
    //         this.onScrollProfiles()
    //       }
    //     })
    //   )
    // }
  }

  // onScrollProfiles() {
  //   this.attachSubscriptions(
  //     fromEvent(this.profilesContainer.panel.nativeElement, "scroll").pipe(
  //       filter((e:any) => e.target.scrollTop >=  e.target.scrollHeight - e.target.offsetHeight - 400),
  //       map(() => this.profilesContainer.panel.nativeElement.scrollTop),
  //       debounceTime(200),
  //       distinctUntilChanged()
  //     ).subscribe(() => {
  //         if (this.profilesPage <= this.profilesPagination['pageCount']) {
  //           this.getProfiles(this.company_id, this.profilesPage);
  //         }
  //       }
  //     )
  //   )
  // }

  searchInMobile(element: ElementRef) {
    this.filter.patchValue({
      q: element['value']
    })
    this.filterTasks();
  }

  openMobFilter() {
    this.layoutService.overflowHidden();
    this.mobile_filter_wrp.nativeElement.classList.add("active");
  }
  

  closeMobFilter() {
    this.layoutService.overflowAuto();
    this.mobile_filter_wrp.nativeElement.classList.remove("active");
  }

  compFilter(key, val) {
    console.log('key', key)
    console.log('val', val)
    this.filter.patchValue({
      [key]: val
    });

    console.log(this.filter.value)
    this.filterTasks()
  }

  sort(val) {
    this.filterTasks(null, val)
  }

  checkActions(e, task) {
    if (!this.is_mobile) {
      e.preventDefault();
      e.stopPropagation();
    }
    this.attachSubscriptions(
      this.taskService.getActions(task.id, 1, this.company.id).subscribe(resp => {
        if (resp.body.length) {
          this.dialog.open(NotificationsBar, {
            backdropClass: this.is_mobile ? ['mob_interface_backdrop'] : 'backdrop_under_header',
            panelClass: this.is_mobile ? ['mob_interface_panel', 'animate__animated', 'animate__slideInUp'] : ['open_task_dialog', 'show_header'],
            // backdropClass: 'backdrop_under_header',
            // panelClass: !this.is_mobile ? ['open_task_dialog', 'show_header'] : 'open_task_dialog',
            data: {
              notifications: resp.body,
              employees: [],
              taskStatuses: this.taskStatuses,
              company: this.company,
              user: this.user,
              task: task,
              header: false,
              tasks: this.tasks,
              initCompanyId: this.company_id,
              empl_status: this.company.permissions.includes('owner') || this.company.permissions.includes('admin') || this.company.permissions.includes('manager'),
              pagination: {
                'pageCount': resp.headers.get('x-pagination-page-count'),
                'perPage': resp.headers.get('x-pagination-per-page'),
                'totalCount': resp.headers.get('x-pagination-total-count'),
                'currentPage': resp.headers.get('x-pagination-current-page'),
              },
            }
          });
        }
      })
    )
  }

  postsCleaner() {
    console.log('openChannelsCounterTable');
    const dialogRef = this.dialog.open(PostsCleanerComponent, {
      backdropClass: ['ve_back_auto'],
      panelClass: ['ve_panel_auto'],
      disableClose: true,
      autoFocus: false,
      data: {
        company: this.company,
        host: this.host,
        is_mobile: this.is_mobile,
        activeLang: this.activeLang,
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result && result.event == 'update') {
          this.filterTasks()
        }
      })
    )
  }

  importFiles() {
    // console.log(this.taskCollection.selected);
    const dialogRef = this.dialog.open(ImportFilesComponent, {
      disableClose: true,
      data: {
        user: this.user,
        company: this.company,
        company_id: this.company_id,
        taskCollection: [...this.taskCollection.selected].map(tID => this.tasks.find(x => x.id == tID))
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result && result.event == "update") {
          this.taskCollection.clear();
        }
      })
    )
  }

  importFilesV2(is_all_results: boolean = false) {
    // console.log(this.taskCollection.selected);
    const dialogRef = this.dialog.open(ImportFilesV2Component, {
      disableClose: true,
      data: {
        user: this.user,
        company: this.company,
        company_id: this.company_id,
        taskCollection: [...this.taskCollection.selected].map(tID => this.tasks.find(x => x.id == tID)),
        is_all_results: is_all_results,
        filterData: this.filter.value
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result && result.event == "update") {
          if (is_all_results) {
            this.refreshPage()
          } else {
            this.taskCollection.clear();
          }
        }
      })
    )
  }

  addMultiWorks(is_all_results: boolean = false) {
    // console.log(this.taskCollection.selected);
    const dialogRef = this.dialog.open(WorkMultiAddComponent, {
      disableClose: true,
      data: {
        user: this.user,
        company: this.company,
        company_id: this.company_id,
        taskCollection: this.taskCollection.selected,
        is_all_results: is_all_results,
        filterData: this.filter.value
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        
        if (!!result && result.event == "update") {

          if (is_all_results) {
            this.refreshPage()
          } else {
            console.log(result)
            console.log(this.taskCollection.selected)
  
            this.taskCollection.selected.forEach(element => {
              this.refreshService.refreshTask$.next({company_id: this.company_id, task_id: element})
            });
            this.taskCollection.clear();
          }

        }
        
      })
    )
  }

  multiEditTaskStatus(is_all_results: boolean = false) {
    console.log(this.taskCollection.selected)

    const dialogRef = this.dialog.open(TaskStatusEditComponent, {
      disableClose: true,
      data: {
        task: false,
        collection: this.taskCollection.selected,
        multi: true,
        company: this.company,
        is_all_results: is_all_results,
        filterData: this.filter.value
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result && result.event == 'update') {
          if (is_all_results) {
            this.refreshPage()
          } else {
            this.taskCollection.selected.forEach((id, i) => {
              this.tasks.find(x => x.id == id).status_id = result.status_id;
              if (i == this.taskCollection.selected.length - 1) {
                this.taskCollection.clear()
                this.collectionIsActive = !this.collectionIsActive;
              }
            });
          }
        }
      })
    )
  }

  toggleTask(task): void {
    this.taskCollection.toggle(task.id);
    console.log(this.taskCollection);
  }

  multiEditGroups() {
    const dialogRef = this.dialog.open(MultiEditGroupsComponent, {
      data: {
        company: this.company,
        company_id: this.company_id,
        tasks: this.taskCollection.selected
      }
    });

    // this.attachSubscriptions(
    //   dialogRef.afterClosed().subscribe(result => {
    //     if (!!result && result.event == 'update') {
    //       this.taskCollection.selected.forEach(element => {
    //         this.refreshService.refreshTask$.next({company_id: this.company_id, task_id: element})
    //       });
    //       this.taskCollection.clear();
    //     }
    //   })
    // )
  }

  getCardsAndDel(n) {
    this.isLoad = true;
    let filterData:any = {...this.filter.value};

    this.ls.requests$.next({
      value: 0,
      target: "Getting cards" 
    })
    this.taskService.getTasksSelectNoExpNew(n, this.company.id, filterData).pipe(
      tap(el => {
        this.delCardsPagination = {
          'pageCount': el.headers.get('x-pagination-page-count'),
          'perPage': el.headers.get('x-pagination-per-page'),
          'totalCount': el.headers.get('x-pagination-total-count'),
          'currentPage': el.headers.get('x-pagination-current-page')
        }
        console.log("this.pagination TEST", this.delCardsPagination)
      }),
      map(el => el.body),
      distinctUntilChanged()
    ).subscribe(resp => {

      console.log("getCards", resp)
      
      if (this.delCardsPage == 1) {
        this.delCards = resp;
      } else {
        this.delCards.push(...resp);
      }

      this.ls.requests$.next({
        value: +((this.delCards.length / +this.delCardsPagination.totalCount)*100).toFixed(1),
        target: "Getting cards" 
      })
      this.delCardsPage = this.delCardsPage + 1;
      
      
      if (+this.delCardsPagination['currentPage'] < +this.delCardsPagination['pageCount'] && +this.delCardsPagination['pageCount'] > 0) {
        this.getCardsAndDel(this.delCardsPage)
      } else {
        this.ls.requests$.next({
          value: 100,
          target: "Getting cards" 
        })
        console.log("cards - " + this.delCardsPage, this.delCards)
        this.isLoad = false;

        let sbmtData = [];
        let partsSbmtData = [];
    
        this.delCards.forEach(task => {
          sbmtData.push({
            "path": `/api/task/${task.id}/`,
            "query": {company_id: this.company.id},
            "method": "DELETE",
          }) 
        })
    
        for (let i = 0; i < Math.ceil(sbmtData.length/5); i++) {
          partsSbmtData.push(sbmtData.slice(i*5, 5 + i*5))
        }
    
        let count = 1;
        if (partsSbmtData.length) {
          if (partsSbmtData.length > 1) {
            this.ls.requests$.next({
              value: 0,
              target: "Deleting cards" 
            })
            concat(...partsSbmtData.map((param) => this.taskService.multiRequest(param))).pipe(
              tap(el => {
                this.ls.requests$.next({
                  value: Math.round((100 / partsSbmtData.length) * count),
                  target: "Deleting cards" 
                })
                count++;
              }),
              last()
            ).subscribe(res => {
              console.log(res);
              this.refreshPage();
              this.multiDeletedCards = false;
            })
          } else {
            this.taskService.multiRequest(sbmtData).subscribe(res => {
              console.log(res);
              this.refreshPage();
              this.multiDeletedCards = false;
            })
          }
        }
      }
    },
    error => {
      this.isLoad = false;
    })
  }

  multiDeleteCards(is_all_results: boolean = false) {
    console.log("this.taskCollection.selected", this.taskCollection.selected)

    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        target: `${is_all_results ? 'All filtered' : this.taskCollection.selected.length} cards`
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        this.multiDeletedCards = true;

        if (is_all_results) {
          this.getCardsAndDel(this.delCardsPage)
        } else {
          let sbmtData = [];
          let partsSbmtData = [];
      
          this.taskCollection.selected.forEach(t_id => {
            sbmtData.push({
              "path": `/api/task/${t_id}/`,
              "query": {company_id: this.company.id},
              "method": "DELETE",
            }) 
          })
      
          for (let i = 0; i < Math.ceil(sbmtData.length/2); i++) {
            partsSbmtData.push(sbmtData.slice(i*2, 2 + i*2))
          }
      
          let count = 1;
          if (partsSbmtData.length) {
            if (partsSbmtData.length > 1) {
              concat(...partsSbmtData.map((param) => this.taskService.multiRequest(param))).pipe(
                tap(el => {
                  this.ls.requests$.next({
                    value: Math.round((100 / partsSbmtData.length) * count),
                    target: "Deleting cards" 
                  })
                  count++;
                })
              ).subscribe(res => {
                console.log(res);
                this.taskCollection.selected.forEach(t_id => {
                  this.tasks.splice(this.tasks.indexOf(this.tasks.find(b => b.id == t_id)), 1)
                });
                this.taskCollection.clear();
                this.multiDeletedCards = false;
              })
            } else {
              this.taskService.multiRequest(sbmtData).subscribe(res => {
                console.log(res);
                this.taskCollection.selected.forEach(t_id => {
                  this.tasks.splice(this.tasks.indexOf(this.tasks.find(b => b.id == t_id)), 1)
                });
                this.taskCollection.clear();
                this.multiDeletedCards = false;
              })
            }
          }
        }
      }
    });
  
  }

  multiAddClients() {
    const dialogRef = this.dialog.open(TaskMultiAddClientsComponent, {
      data: {
        company: this.company,
        company_id: this.company_id,
        tasks: this.taskCollection.selected
      }
    });
  }

  goToClients(task) {
    const dialogRef = this.dialog.open(TaskClientsComponent, {
      data: {
        company: this.company,
        company_id: this.company_id,
        task: task,
        user: this.user
      }
    });
    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log(result)
      })
    )
  }

  checkConnections(e, task) {
    let arr = [];

    if (task.consist_of_count) {
      arr.push(
        this.taskService.getCardsExpand('1', this.company_id, {part_of_task_id: task.id}, '100').pipe(
          tap(tasksRes => {
            task.partOfTasks = [...tasksRes];
          })
        )
      )
    } else {
      task.partOfTasks = []
    }

    if (task.part_of_count) {
      arr.push(
        this.taskService.getCardsExpand('1', this.company_id, {consist_of_task_id: task.id}, '100').pipe(
          tap(tasksRes => {
            task.consistOfTasks = [...tasksRes];
          })
        )
      )
    } else {
      task.consistOfTasks = []
    }
    if (task.related_count) {
      arr.push(
        this.taskService.getCardsExpand('1', this.company_id, {related_task_id: task.id}, '100').pipe(
          tap(tasksRes => {
            task.relatedTasks = [...tasksRes];
          })
        )
      )
    } else {
      task.relatedTasks = []
    }

    this.attachSubscriptions(
      forkJoin([...arr]).subscribe(res => {
        this.dialog.open(ConnectionsModalComponent, {
          disableClose: true,
          data: {
            company_id: this.company_id,
            company: this.company,
            task: task
          }
        });
      })
    )
  }

  getPriority(id) {
    if (!this.prioritys) {
      return false;
    }
    return this.prioritys.find(el => el.id == id)
  }  

  getGroup(id) {
    return undefined;
    // if (!this.groups || !this.groups.find(el => el.id == id)) {
    //   return false;
    // }
    // return this.groups.find(el => el.id == id)
  }  

  // getEmployee(id) {
  //   if (!this.employees) {
  //     return false;
  //   }
  //   return this.employees.find(el => el.id == id)
  // }  

  // getPartner(id) {
  //   if (!this.partners) {
  //     return false;
  //   }
  //   return this.partners.find(el => el.partner_company_id == id)
  // }  

  // getProfile(id) {
  //   if (!this.profiles) {
  //     return false;
  //   }
  //   return this.profiles.find(el => el.id == id)
  // }  

  getProfileStatus(id) {
    if (!this.profileStatuses) {
      return false;
    }
    return this.profileStatuses.find(el => el.id == id)
  }  

  selectChange(e) {
    // console.log(!e)
    this.closeExecFilter();
    this.filterTasks();
  }

  selectOperation(e, id, jobTypeSelect) {
    e.preventDefault();
    e.stopPropagation();

    this.filter.patchValue({
      task_operation_operation_id: id,
    })

    jobTypeSelect.close()
    this.closeExecFilter();
    this.filterTasks();

  }

  filterReset() {
    this.filter.patchValue({
      id: '',
      operation_completed_at_from: '',
      operation_completed_at_to: ''
    })
    if (this.activeStatus.includes(0)) {
      this.router.navigate(['/tasks'], { queryParams: {company_id: this.company_id, order_by: "updated_desc"}});
    } else {
      this.filter.value.operation_status_id = this.activeStatus;
      this.router.navigate(['/tasks'], { queryParams: {company_id: this.company_id, operation_status_id: this.filter.value.operation_status_id.join(","), order_by: "updated_desc"}});
    }
    if (this.is_mobile) {
      this.layoutService.overflowAuto();
      this.mobile_filter_wrp.nativeElement.classList.remove("active");
    }
  }

  resetSearchParams() {
    this.filter.patchValue({
    id: '',
    task_custom_id: "",
    internal_id: "",
    task_company_id: '',
    discussion_id: "",
    task_operation_id: "",
    q_task_name: "",
    q_task_channel_content_filename: "",
    q_task_comment: "",
    q_task_private_comment: "",
    q_task_operation_comment: "",
    q_task_operation_employee_comment: "",
    q_task_operation_private_comment: ""
    })
  }

  getCountSumm(arr, startIndex) {
    let count = 0

    for (startIndex; startIndex < arr.length; startIndex++) {
      const element = arr[startIndex];
      count = count + element.count
    }
    
    return count
  }

  changeActiveStatus(id, event = null) {
    // if (!!event && (event.ctrlKey || event.metaKey)) {
    //   if (id == 0) {
    //     this.activeStatus = [0];
    //   } else if (this.activeStatus.includes(id)) {
    //     this.activeStatus.splice(this.activeStatus.indexOf(id), 1);
    //   } else if (this.activeStatus.includes(0) && id != 0) {
    //     this.activeStatus.splice(this.activeStatus.indexOf(0), 1)
    //     this.activeStatus.push(id);
    //   } else {
    //     this.activeStatus.push(id);
    //   }
    //   if (this.activeStatus.sort().toString() == [1,2,3,4,5,6,97,98,99].toString()) {
    //     this.activeStatus = [0];
    //   }
    // } else {
    // }
    if (id == 0) {
      this.activeStatus = [0];
    } else {
      this.activeStatus = [id];
    }
    this.sm.localStorageSetItem("activeStatus", this.activeStatus);

    this.filterTasks(this.activeStatus)
  }

  // if (id == 0) {
  //   this.activeStatus = [0];
  // } else {
  //   this.activeStatus = [...id];
  // }

  getTasksNoComplete(picker:MatDateRangePicker<any>) {
    picker.close()
    this.filter.patchValue({
      operation_completed_at_from: '',
      operation_completed_at_to: 0
    })
    this.filterTasks();
  }

  toggleIsAndJobTags() {
    this.is_and_task_operation_parameter_value_id = !this.is_and_task_operation_parameter_value_id;

    this.filterTasks();
  }

  withSearch(searchText: string, val) {
    if (searchText == '' || typeof searchText != 'string' || val.value.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ) {
      return true
    } else {
      return false
    }
  }
  
  noOption(arr, id) {
    return arr.filter(x => x.id == id).length > 0 ? false : true;
  }

  // neededParametersData(param) {
  //   let arr = []

  //   arr.push(
  //     this.parametersService.getApiParameterValues(this.company_id, param.id, "20", !!param.page ? param.page : "1").pipe(
  //       tap(x => {
  //         param.pagination = {
  //           'pageCount': x.headers.get('x-pagination-page-count'),
  //           'perPage': x.headers.get('x-pagination-per-page'),
  //           'totalCount': x.headers.get('x-pagination-total-count'),
  //           'currentPage': x.headers.get('x-pagination-current-page'),
  //         }
  //         param.page = 2
  //         param.values = x.body;
  //         if (x.body.length > 0) {
  //           param.hasValues = true;
  //         }
  //         this.getActiveValuesForParam(param)
  //       })
  //     )
  //   )

  //   return forkJoin(arr)
  // }

  onSearch(searchText, param) {
    console.log(searchText, param)
    param.page = 1;
    this.parametersService.getApiParameterValues(this.company_id, param.id, "20", !!param.page ? param.page : "1",  !!param.search.value ? param.search.value : '').subscribe(resp => {
      param.pagination = {
        'pageCount': resp.headers.get('x-pagination-page-count'),
        'perPage': resp.headers.get('x-pagination-per-page'),
        'totalCount': resp.headers.get('x-pagination-total-count'),
        'currentPage': resp.headers.get('x-pagination-current-page'),
      }
      param.page = 2
      param.values = resp.body;
      console.log(resp.body)
      // console.log(this.parameters)
    })
  }

  add(e) {
    console.log(e)
  }

  // getActiveValuesForParam(param) {
  //   param.activeTaskValues = []
  //   param.activeJobValues = []
  //   param.activeDiscussionValues = []
  //   let paramValues;
  //   if (param.id != 0) {
  //     paramValues = this.allValues.filter(b => b.parameter_id == param.id);
  //   } else {
  //     paramValues = this.allValues.slice();
  //   }
  //   // console.log("paramValues",param.name, paramValues)
  //   this.filter.value.task_parameter_value_id.forEach(x => {
  //     // console.log("x", x)
  //     // console.log("paramValues.filter(y => y.id == x)", paramValues.filter(y => y.id == x))
  //     param.activeTaskValues.push(...paramValues.filter(y => y.id == x))
  //   }) 
  //   this.filter.value.task_operation_parameter_value_id.forEach(x => {
  //     // console.log("x", x)
  //     // console.log("paramValues.filter(y => y.id == x)", paramValues.filter(y => y.id == x))
  //     param.activeJobValues.push(...paramValues.filter(y => y.id == x))
  //   }) 
  //   this.filter.value.discussion_parameter_value_id.forEach(x => {
  //     // console.log("x", x)
  //     // console.log("paramValues.filter(y => y.id == x)", paramValues.filter(y => y.id == x))
  //     param.activeDiscussionValues.push(...paramValues.filter(y => y.id == x))
  //   }) 
  //   this.filter.value.file_parameter_value_id.forEach(x => {
  //     // console.log("x", x)
  //     // console.log("paramValues.filter(y => y.id == x)", paramValues.filter(y => y.id == x))
  //     param.activeFileValues.push(...paramValues.filter(y => y.id == x))
  //   }) 

  //   param.activeValues = []
  //   this.filter.value.parameter_value_id.forEach(x => {
  //     param.activeValues.push(...paramValues.filter(y => y.id == x))
  //   })
  // }
  

  // getAllApiParameterValues() {
  //   this.attachSubscriptions(
  //     this.parametersService.getAllApiParameterValues(this.company.id).subscribe(resp => {
  //       this.allValues = resp
  //       console.log("getAllApiParameterValues", this.allValues)
  //     })
  //   )
  // }


  // getValueById(id) {
  //   if (!this.allValues || this.allValues.length == 0) {
  //     return null
  //   }
  //   return this.allValues.find(x => x.id == id)
  // }

  // getParameterById(id) {
  //   if (!this.parameters || this.parameters.length == 0) {
  //     return null
  //   }
  //   return this.parameters.find(x => x.id == id)
  // }

  keySwitcher(key) {
    switch (key) {
      case 'task_parameter_value_id':
        return "activeTaskValues"
        break;
      case 'task_operation_parameter_value_id':
        return "activeJobValues"
        break;
      case 'discussion_parameter_value_id':
        return "activeDiscussionValues"
        break;
      case 'file_parameter_value_id':
        return "activeFileValues"
        break;
      case 'parameter_value_id':
        return "activeValues"
        break;
    }
  }


  addLiveAuto(element) {
    console.log(element)
    const dialogRef = this.dialog.open(AddAutoLivesComponent, {
      disableClose: true,
      data: {
        company: this.company,
        company_id: this.company_id,
        task: element
      }
    });
    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result && result.event == "Add") {
          this.refreshService.refreshTask$.next({company_id: this.company_id, task_id: element.id})
        }
      })
    )
  }


  addLive(element, profile?) {
    let x:any = {
      company: this.company,
      company_id: this.company_id,
      task: element
    }
    if (profile) {
      x.profile = profile;
      x.is_edit = true
    }
    const dialogRef = this.dialog.open(AddEditLiveStreamComponent, {
      disableClose: true,
      data: x
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result && (result.event == "Add" || result.event == 'edit')) {
          this.refreshService.refreshTask$.next({company_id: this.company_id, task_id: element.id})
        }
      })
    )
  }
  // onRemove(value, param, key) {
  //   console.log("onRemove", this.filter.value)
  //   if (param.id == 0) {
  //     let parameterValues = this.parameters.find(x => x.id == value.parameter_id)[this.keySwitcher(key)]
  //     parameterValues.splice(parameterValues.indexOf(parameterValues.find(b => b.id == value.id)),1)
  //   }
  //   let val = this.filter.value[key];
  //   val.splice(val.indexOf(value.id), 1);
  //   this.filter.patchValue({
  //     [key]: val
  //   })
  //   if (param.id != 0) {
  //     this.getActiveValuesForParam(this.parameters[0]);
  //   }
  //   this.getActiveValuesForParam(param);
  //   this.filterTasks()
  // }

  // selected(e, param, key) {
  //   console.log("selected", e)

    
  //   if (param.id == 0) {
  //     console.log(this.parameters, e, param, key);
  //     let parameterValues = this.parameters.find(x => x.id == e.option.value.parameter_id)[this.keySwitcher(key)]
  //     parameterValues.push(e.option.value)
  //   }

  //   let val = this.filter.value[key];
  //   val.push(e.option.value.id);
  //   this.filter.patchValue({
  //     [key]: val
  //   })
  //   // param.search.patchValue("")
  //   // if (document.getElementById(`param_input_${param.id}`)) {
  //   //   (document.getElementById(`param_input_${param.id}`) as HTMLInputElement).value = ''
  //   // }
  //   if (param.id != 0) {
  //     this.getActiveValuesForParam(this.parameters[0]);
  //   }
  //   this.getActiveValuesForParam(param);
  //   this.filterTasks()
  // }

  // getParameters() {
  //   this.attachSubscriptions(
  //     this.parametersService.getApiParameters(this.company.id).pipe(
  //       switchMap(res => {
  //         return this.parametersService.getAllValues('1', this.company.id, null, '1').pipe(
  //           switchMap(el => {
  //             let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
  //             let arr = []
  //             for (let index = 1; index <= pages; index++) {
  //               arr.push(index)
  //             }
    
  //             return forkJoin(arr.map(x => this.parametersService.getAllValues(x, this.company.id).pipe(map(u => u.body)))).pipe(
  //               last(),
  //               tap(values => {
  //                 this.allValues = [].concat(...values)
  //                 console.log("getAllApiParameterValues", this.allValues)
  //               }),
  //               map(() => res)
  //             )
  //           }),
  //           map(() => res)
  //         )
  //       }),
  //       tap(res => {
  //         res.unshift({
  //           company_id: this.company_id,
  //           created_at: 0,
  //           id: 0,
  //           is_new_value: 0,
  //           name: "All parameters",
  //           original_id: 0,
  //         })
  //         this.parametersService.paramsData$.next(res)
  //         res.forEach(n => {
  //           n.search = new FormControl('');
  //           n.search.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearch(resp, n))
  //         })
  //         this.parameters = res
  //       }),
  //     ).subscribe(resp => {
  //       console.log("this.parameters", this.parameters)
  //     })
  //   )
  // }

  // onScrollParams(param) {
  //   console.log(param.page, param.pagination.pageCount)
  //   if (param.pagination.pageCount >= param.page) {
  //     console.log("SCROLL !")
  //     this.parametersService.getApiParameterValues(this.company_id, param.id, "20", param.page, !!param.search.value ? param.search.value : '').pipe(take(1), map((x) => x.body)).subscribe(resp => {
  //       param.page++;
  //       param.values.push(...resp)
  //     })
  //   }
  // }

  filterTasks(op_status_id = null, order_by = null, is_btn = false) {
    this.closeOpenFilter();
    if (this.tasksSub) {
      this.tasksSub.unsubscribe()
    }
    if (this.tasksCountSub) {      
      this.tasksCountSub.unsubscribe()
    }
    this.filterCount = this.filterCount + 1;
    this.filter.patchValue({
      count: this.filterCount
    })
    
    let filterData = {...this.filter.value};
    Object.keys(filterData).forEach((key:string) => {      
      if (key.indexOf('at_from') != -1 && !!filterData[key]) {
        filterData[key] = moment(filterData[key]._d).startOf('day').format("X");
      }
      if (key.indexOf('at_to') != -1 && !!filterData[key]) {
        filterData[key] = moment(filterData[key]._d).startOf('day').format("X");
      }
    })

    if (!!op_status_id) {
      filterData.operation_status_id = op_status_id

      if (op_status_id.toString() == '0') {
        delete filterData.operation_status_id
      }
    }

    if (!!order_by || order_by == "0") {
      filterData.order_by = order_by
    }

    // this.sm.localStorageSetItem("activeStatus", filterData.operation_status_id);
    
    let params:any = {
      company_id: this.company_id
    };

    // if (is_btn) {
    //   this.filter.removeControl('id');
    // }
    
    Object.keys(filterData).filter(x => x != 'preset_id').forEach(element => {
      if (['is_related_task', 'is_consist_of_task', 'is_part_of_task', 'task_content_status_id', 'is_content_url', 'is_parameter_value'].includes(element) && filterData[element] == '0') {
        params[element] = filterData[element];
      } else if (element == 'operation_status_id' && !(filterData[element].length == 1 && filterData[element].includes(0))) {
        console.log("operation_status_id", filterData[element]);
        params[element] = filterData[element].join(',');
      } else if (["channel_id", "no_channel_id", "group_id", "task_operation_parameter_value_id", "operation_employee_id","operation_partner_company_id",'task_parameter_value_id','parameter_value_id','file_parameter_value_id'].includes(element) && filterData[element].length > 0) {
        params[element] = filterData[element].join(',');
      } else if (filterData[element] == '0' && filterData[element] == 0 && (element == 'operation_employee_id' || element == 'operation_partner_company_id')) {
        params[element] = filterData[element];
      } else if (filterData[element] != '0' && filterData[element] != '') {
        params[element] = filterData[element];
      }
    });


    // console.log("filterData.operation_completed_at_to", filterData.operation_completed_at_to)
    if (filterData.operation_completed_at_to === 0) {
      params['operation_completed_at_to'] = '0';
    }

    Object.keys(this.cardsExpand).forEach(key => {
      if (!!this.cardsExpand[key]) {
        if (params.expand) {
          params.expand = params.expand + `,${key}`
        } else {
          params.expand = `${key}`
        }
      }
    })

    this.router.navigate(['/tasks'], { queryParams: params });

    if (this.is_mobile) {
      this.layoutService.overflowAuto();
      if (this.mobile_filter_wrp) {
        this.mobile_filter_wrp.nativeElement.classList.remove("active");
      }
    }
  }

  getTaskStatus() {
    this.attachSubscriptions(
      this.taskService.getTaskStatuses(this.company_id).subscribe(resp => {
        this.taskStatuses = resp.slice()
        this.taskStatuses.unshift({
          id: 0,
          name: marker('All statuses')
        });
      })
    )
  }

  getOperationsStatus() {
    this.attachSubscriptions(
      this.taskService.getOperationsStatus().subscribe(resp => {
        this.operationStatuses = resp.slice();
        this.operationStatuses.unshift({
          id: 0,
          name: marker('All statuses')
        });
      })
    )
  }

  updateTask(task) {
    this.attachSubscriptions(
      this.taskService.getOneTaskExpand(this.company_id, task.id).pipe(
        map(x => x.body[0]),
        switchMap(val => this.neededData(val).pipe(map(() => casesModel([val], this.tasks, 'update')),map(val => val.arr[0])))
      ).subscribe(resp => {
        Object.keys(resp).forEach(key => {
          task[key] = resp[key]
        })
        console.log("task updated", task);
      })
    )
  }

  getActiveLang() {
    let langData = this.languageService.getLang();
    if (langData != false) {
      this.languageService.languageData$.next(langData);
    } 
  }

  getContentById(id) {
    if (!this.allContentTypes || this.allContentTypes.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.allContentTypes.find(el => el.id == id)
  }

  getTasksCount() {
    if (this.tasksCountSub) {      
      this.tasksCountSub.unsubscribe()
    }
    let filterData = {...this.filter.value};
    if (this.is_and_task_operation_parameter_value_id && filterData.task_operation_parameter_value_id && filterData.task_operation_parameter_value_id.length > 1) {
      filterData.and_task_operation_parameter_value_id = filterData.task_operation_parameter_value_id;
      delete filterData.task_operation_parameter_value_id;
    }
    this.tasksCountSub = this.taskService.getTasksCount(this.company_id, filterData).pipe(
      tap(el => {
        this.totalInfo['summaryRate'] = +el.headers.get('x-summary-rate');
        let parsePrice = JSON.parse(el.headers.get('x-summary-price'));
        this.totalInfo['summaryPrice'].forEach(price => {
          if (parsePrice.find(x => x.currency_id == price.id)) {
            price.summary = +parsePrice.find(x => x.currency_id == price.id).summary;
          } else {
            price.summary = 0
          }
        });

        this.totalInfo['totalPrice'] = this.totalInfo['summaryPrice'].reduce((sum, obj) => +sum + +obj.summary, 0)

        this.totalTasksCount = +el.headers.get('x-pagination-total-count')

        // console.log("parsePrice", parsePrice);
        console.log("tasksCountSub", el.headers.get('x-pagination-total-count'));
      })
    ).subscribe(resp => {
      console.log(resp)
    })
  }

  migrateTargetFiles(task) {
    const dialogRef = this.dialog.open(MigrateTargetFilesComponent, {
      panelClass: ['chats_dialog'],
      data: {
        company: this.company,
        target: {
          task_id: task.id,
          task_operation_id: 0
        },
        user: this.user,
        host: this.host,
      }
    });
    
    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        this.refreshService.refreshTask$.next({company_id: this.company_id, task_id: task.id})
      })
    )
  }

  openTaskChats(task) {
    const dialogRef = this.dialog.open(TaskChatsComponent, {
      panelClass: ['chats_dialog'],
      data: {
        company: this.company,
        task: task,
        user: this.user,
        empl_status: this.company.permissions.includes('owner') || this.checkIsManager(task, this.company, this.user),
        host: this.host,
        operationsValues: this.operationsValues,
        checkIsManager: this.checkIsManager,
        isTaskChats: true
      }
    });
    
    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        this.refreshService.refreshTask$.next({company_id: this.company_id, task_id: task.id})
      })
    )
  }

  openTagConnection(task) {
    const dialogRef = this.dialog.open(TagTaskComponent, {
      backdropClass: 'backdrop_under_header',
      panelClass: !this.is_mobile ? ['open_task_dialog', 'show_header'] : 'open_task_dialog',
      data: {
        company: this.company,
        user: this.user,
        task: task,
        is_mobile: this.is_mobile,
        activeLang: this.activeLang
      }
    });
    
    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log(result)
      })
    )
  }

  openNotes(item) {
    const dialogRef = this.dialog.open(NotesComponent, {
      data: {
        company: this.company,
        company_id: this.company_id,
        user: this.user,
        type: 'task',
        item: item,
        is_mobile: this.is_mobile
      }
    });
    
    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log(result)
      })
    )
  }

  openAccessSettings(task) {
    const dialogRef = this.dialog.open(SharedOptionsComponent, {
      data: {
        company_id: this.company_id,
        user: this.user,
        task: task,
        is_mobile: this.is_mobile
      }
    });
    
    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log(result)
      })
    )
  }

  toggleCardExp() {
    this.sm.sessionStorageSetItem('card_exp', this.card_exp == 0 ? 1 : 0);
    this.card_exp = +this.sm.sessionStorageGetItem('card_exp');
    this.filterTasks();
  }

  compilationAssistant() {
    console.log('openChannelsCounterTable');
    const dialogRef = this.dialog.open(CompilationAssistantComponent, {
      backdropClass: ['ve_back_auto'],
      panelClass: ['ve_panel_auto'],
      disableClose: true,
      autoFocus: false,
      data: {
        company: this.company,
        company_id: this.company_id,
        host: this.host,
        is_mobile: this.is_mobile,
        imgRoute: this.imgRoute,
        activeLang: this.activeLang,
        user: this.user
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
    
      })
    )
  }

  getTasks(n) {
    console.log("FILTER", this.filter.value)
    this.isLoad = true;
    let filterData = {...this.filter.value};
    if (this.is_and_task_operation_parameter_value_id && filterData.task_operation_parameter_value_id && filterData.task_operation_parameter_value_id.length > 1) {
      filterData.and_task_operation_parameter_value_id = filterData.task_operation_parameter_value_id;
      delete filterData.task_operation_parameter_value_id;
    }
    this.tasksSub = this.taskService.getCardsExpand(n, this.company_id, filterData, !this.is_mobile ? '15' : '10', +this.sm.sessionStorageGetItem('card_exp'), 0, this.cardsExpand).pipe(
      tap(arrTasks => {
        console.log("arrTasks", arrTasks)
        if (this.is_mobile) {
          if (arrTasks.length < 10) {
            this.hasMore = false;
          } else {
            this.hasMore = true;
          }
        } else {
          if (arrTasks.length < 15) {
            this.hasMore = false
          } else {
            this.hasMore = true;
          }
        }
      }), 
      map(arr => {
        let data = casesModel(arr, this.tasks, "get");
        return {
          arr: data.arr,
          data: data.tasks
        };
      }),
      tap(resp => {
        if (resp.arr.length) {
          this.taskService.taskData$.next(resp.arr)
        }
      }),
      distinctUntilChanged()
    ).subscribe(resp => {
      this.tasks = resp.data;

      console.log("this.tasks "+this.page, this.tasks)

      if (+this.page == 1) {
        this.totalTasksCount = 0;
        this.tasksContainer.nativeElement.scrollTop = 0;
        // if (this.filter.value.operation_partner_company_id && this.filter.value.operation_partner_company_id.length) {
        //   this.getGroupsPartnerCompany()
        // } else {
        //   this.getGroupsCompany()
        // }
        // this.getAllApiParameterValues()
        // this.getParameters()
        // this.getEmployees();
        // if (this.company.permissions.includes('owner') || this.company.permissions.includes('admin') || this.company.permissions.includes('manager')) {
        //   this.getPartners();
        // }
        // this.getProfiles(this.company_id, this.profilesPage);
        // this.getGroupsCompany();
        this.getRateStats();
        // this.getPlatforms()
      }
      this.page = this.page + 1;
      this.isLoad = false;
    },
    error => {
      this.isLoad = false;
    })
    
  }

  getContentTypes(platform_id, contentPage) {
    this.attachSubscriptions(
      this.companyService.getContentTypes(platform_id, contentPage).pipe(
        tap(el => {
          this.contentPagination = {
            'pageCount': el.headers.get('x-pagination-page-count'),
            'perPage': el.headers.get('x-pagination-per-page'),
            'totalCount': el.headers.get('x-pagination-total-count'),
            'currentPage': el.headers.get('x-pagination-current-page'),
          }
        }),
        map(el => el.body)
      ).subscribe(resp => {

        this.allContentTypes = resp;

        let arr = [];

        resp.forEach(el => {
          if (arr.filter(x => x.channel_platform_id == el.channel_platform_id).length == 0) {
            arr.push({
              channel_platform_id: el.channel_platform_id,
              types: [el]
            })
          } else {
            arr.find(x => x.channel_platform_id == el.channel_platform_id).types.push(el)
          }
        })

        this.contentTypes.push(...arr);
        this.contentTypes$.next(this.copyContentTypesGroups(this.contentTypes));
        this.contentPage = this.contentPage + 1;
        console.log("getContentTypes", this.contentPage, this.contentTypes)

        if (this.contentPagination.pageCount >= this.contentPage) {
          this.getContentTypes(platform_id, this.contentPage);
        }
      })
    )
  }

  // getGroupsPartnerCompany() {
  //   console.log("getGroupsPartnerCompany", this.filter.value.operation_partner_company_id);
  //   this.attachSubscriptions(
  //     this.companyService.getGroupsPartnerCompany(this.company.id, this.filter.value.operation_partner_company_id).subscribe(resp => {
  //       // this.partnerGroups = resp
  //       this.groups = resp;
  //       this.groups$.next(this.groups.slice());
  //       console.log("getGroupsPartnerCompany", resp);
  //     })
  //   )
  // }

  openChat(id) {
    this.attachSubscriptions(
      this.chatService.getChatsExpand(1, this.company_id, {id: id}).pipe(
        map(res => res.body[0])
      ).subscribe(chat => {
        const goToChat = this.dialog.open(ChatsComponent, {
          backdropClass: ['backdrop_under_header', "without_pad"],
          panelClass: !this.is_mobile ? ['full_screen_dialog', 'global_chats_dialog', 'show_header'] : ['full_screen_dialog', 'global_chats_dialog'],
          position: !this.is_mobile ? { top: '58px', left: '0', right: '0', bottom: '0' } : {},
          data: {
            company: this.company,
            user: this.user,
            imgRoute: this.imgRoute,
            host: this.host,
            dialogType: 'global_chat',
            activeLang: this.activeLang, 
            chat: chat
          }
        });
      })
    )
  }

  onPartnerImgError(event){
    event.target.src = this.imgRoute+'/assets/img/partner.png'
  }

  closeOpenFilter() {
    this.subFilter && this.subFilter.unsubscribe();
    if (this.overlayRefFilter) {
      this.overlayRefFilter.dispose();
      this.overlayRefFilter = null;
    }
  }

  openFilter(el, val) {
    this.closeOpenFilter();
    let x = el.offsetLeft;
    let y =  el.offsetTop + el.offsetHeight;
    const positionStrategy = this.overlay.position()
    .flexibleConnectedTo({ x, y })
    .withPositions([
      {
        originX: 'start',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'top',
      }
    ]);

    this.overlayRefFilter = this.overlay.create({
      backdropClass: 'transparent_bg',
      hasBackdrop: true,
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });

    this.overlayRefFilter.attach(new TemplatePortal(this.filterMenu, this.viewContainerRef, {
      $implicit: val
    }));
    
    this.subFilter = fromEvent<MouseEvent>(document, 'click')
      .pipe(
        filter(event => {
          const clickTarget = event.target as HTMLElement;
          return !!this.overlayRefFilter && !this.overlayRefFilter.overlayElement.contains(clickTarget);
        }),
        take(1)
      ).subscribe(() => this.closeOpenFilter())

  }

  closeOpenClientFilter() {
    this.subClientFilter && this.subClientFilter.unsubscribe();
    if (this.overlayRefClientFilter) {
      this.overlayRefClientFilter.dispose();
      this.overlayRefClientFilter = null;
    }
  }

  openClientFilter(el, val) {
    this.closeOpenFilter();
    let x = el.offsetLeft;
    let y =  el.offsetTop + el.offsetHeight;
    const positionStrategy = this.overlay.position()
    .flexibleConnectedTo({ x, y })
    .withPositions([
      {
        originX: 'start',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'top',
      }
    ]);

    this.overlayRefClientFilter = this.overlay.create({
      backdropClass: 'transparent_bg',
      hasBackdrop: true,
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });

    this.overlayRefClientFilter.attach(new TemplatePortal(this.filterClientMenu, this.viewContainerRef, {
      $implicit: val
    }));
    
    this.subClientFilter = fromEvent<MouseEvent>(document, 'click')
      .pipe(
        filter(event => {
          const clickTarget = event.target as HTMLElement;
          return !!this.overlayRefClientFilter && !this.overlayRefClientFilter.overlayElement.contains(clickTarget);
        }),
        take(1)
      ).subscribe(() => this.closeOpenClientFilter())

  }

  goToChat(chat) {
    const goToChat = this.dialog.open(OperationChatComponent, {
      backdropClass: ['backdrop_under_header', "without_pad"],
      panelClass: ['full_screen_dialog', 'global_chats_dialog'],
      disableClose: true,
      data: {
        company: this.company,
        task: this.tasks[0],
        tasks: this.tasks,
        dialogType: 'operation_chat',
        operationsValues: this.operationsValues,
        work: this.tasks[0].operations.find(el => el.id == this.linkTo.split("_")[2]),
        user: this.user,
        empl_status: this.company.permissions.includes('owner') || this.company.permissions.includes('admin') || this.company.permissions.includes('manager'),
        chat: chat,
        chats: this.tasks[0].operations.find(el => el.id == this.linkTo.split("_")[2]).chatsInfo
      }
    });

    this.attachSubscriptions(
      goToChat.afterClosed().subscribe(result => {
        if (!!result && result.event == 'close') {
          this.chatService.postChatRead(chat.id, 0, this.company.id).subscribe(resp => {
            console.log(resp);
          })
        }
      })
    )
  }

  getTaskStatusById(id) {
    if (!this.taskStatuses || this.taskStatuses.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.taskStatuses.find(el => el.id == id)
  }
  
  onMobScroll() {
    this.attachSubscriptions(
      fromEvent(window, "scroll").pipe(
        map(() => window.scrollY),
        tap((val) => {
          val > 100 ? this.isScrolledCollectionBtn = true : this.isScrolledCollectionBtn = false;
        }),
        filter(current => current >= document.body.clientHeight - window.innerHeight - 100),
        debounceTime(300),
        distinctUntilChanged()
      ).subscribe(() => {
        if (this.page > 1 && this.hasMore && !this.isLoad) {
          this.getTasks(this.page);
        }
      })
    )
  }

  onScroll() {
    this.attachSubscriptions(
      fromEvent(this.tasksContainer.nativeElement, "scroll").pipe(
        filter((e:any) => e.target.scrollTop >= e.target.scrollHeight - e.target.offsetHeight - 400),
        debounceTime(300)
      ).subscribe(() => {
        if (this.page > 1 && this.hasMore && !this.isLoad) {
          this.getTasks(this.page);
        }
      })
    )
  }

  // @HostListener('window:scroll', ['$event']) // for window scroll events
  // onMobileScroll(e) {
  //   if (this.is_mobile) {
  //     window.pageYOffset > 100 ? this.isScrolledCollectionBtn = true : this.isScrolledCollectionBtn = false;
  //   }
  // }

  // getPartners() {
  //   this.attachSubscriptions(
  //     this.companyService.getPartners({company_id: this.company_id}).subscribe(resp => {
  //       this.partners = resp.filter(x => x.partner_company_id != 0);
  //       console.log("getPartners", this.partners)
  //       this.partners$.next(this.partners.slice())
  //     })
  //   )
  // }

  closeExecFilter() {
    this.execFilterSub && this.execFilterSub.unsubscribe();
    if (this.overlayExecRefFilter) {
      this.overlayExecRefFilter.dispose();
      this.overlayExecRefFilter = null;
    }
  }

  openExecFilter(el, val) {
    this.closeExecFilter();
    let x = el.offsetLeft;
    let y =  el.offsetTop + el.offsetHeight;
    const positionStrategy = this.overlay.position()
    .flexibleConnectedTo({ x, y })
    .withPositions([
      {
        originX: 'start',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'top',
      }
    ]);

    this.overlayExecRefFilter = this.overlay.create({
      backdropClass: 'transparent_bg',
      hasBackdrop: true,
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });

    this.overlayExecRefFilter.attach(new TemplatePortal(this.filterExecMenu, this.viewContainerRef, {
      $implicit: val
    }));
    
    this.execFilterSub = fromEvent<MouseEvent>(document, 'click')
      .pipe(
        filter(event => {
          const clickTarget = event.target as HTMLElement;
          return !!this.overlayExecRefFilter && !this.overlayExecRefFilter.overlayElement.contains(clickTarget);
        }),
        take(1)
      ).subscribe(() => this.closeExecFilter())

  }

  closeParameterFilter() {
    this.parameterFilterSub && this.parameterFilterSub.unsubscribe();
    if (this.overlayParameterRefFilter) {
      this.overlayParameterRefFilter.dispose();
      this.overlayParameterRefFilter = null;
    }
  }

  openParameterFilter(el, val) {
    this.closeParameterFilter();
    this.closeExecFilter();
    this.closeOpenClientFilter();
    let x = el.offsetLeft;
    let y =  el.offsetTop + el.offsetHeight;
    const positionStrategy = this.overlay.position()
    .flexibleConnectedTo({ x, y })
    .withPositions([
      {
        originX: 'start',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'top',
      }
    ]);

    this.overlayParameterRefFilter = this.overlay.create({
      backdropClass: 'transparent_bg',
      hasBackdrop: true,
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });

    this.overlayParameterRefFilter.attach(new TemplatePortal(this.filterParameterMenu, this.viewContainerRef, {
      $implicit: val
    }));
    
    this.parameterFilterSub = fromEvent<MouseEvent>(document, 'click')
      .pipe(
        filter(event => {
          const clickTarget = event.target as HTMLElement;
       
          let check1 = !!this.overlayParameterRefFilter && !this.overlayParameterRefFilter.overlayElement.contains(clickTarget)
          // let check2 = (document.getElementsByClassName('filter_autocomlete').length > 0 && !document.getElementsByClassName('filter_autocomlete')[0].contains(clickTarget))
          // let check3 = (document.getElementsByClassName('filter_autocomlete').length == 0);
          // let check4 = (clickTarget.classList.contains('mat-option-text') || clickTarget.classList.contains('mat-option'))
       
//(check1 && check2) || (check1 && check3 && !check4)
          return check1
        }),
        take(1)
      ).subscribe(() => this.closeParameterFilter())

  }

  open({ x, y }: MouseEvent, val) {
    this.close();
    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo({ x, y })
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        }
      ]);

    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });

    this.overlayRef.attach(new TemplatePortal(this.taskMenu, this.viewContainerRef, {
      $implicit: val
    }));
    
    this.sub = fromEvent<MouseEvent>(document, 'click')
      .pipe(
        filter(event => {
          const clickTarget = event.target as HTMLElement;
          return !!this.overlayRef && !this.overlayRef.overlayElement.contains(clickTarget);
        }),
        take(1)
      ).subscribe(() => this.close())

  }

  close() {
    this.sub && this.sub.unsubscribe();
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
  }

  openInNew(task) {
    window.open(this.origin + '/task/' + task.acm, "_blank")
  }

  openCardContext({ x, y }: MouseEvent, val) {
    this.closeCardContext();
    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo({ x, y })
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        }
      ]);

    this.overlayRefCard = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });

    this.overlayRefCard.attach(new TemplatePortal(this.cardContext, this.viewContainerRef, {
      $implicit: val
    }));
    
    this.cardContextSub = fromEvent<MouseEvent>(document, 'click')
      .pipe(
        filter(event => {
          const clickTarget = event.target as HTMLElement;
          return !!this.overlayRefCard && !this.overlayRefCard.overlayElement.contains(clickTarget);
        }),
        take(1)
      ).subscribe(() => this.closeCardContext())

  }

  closeCardContext() {
    this.cardContextSub && this.cardContextSub.unsubscribe();
    if (this.overlayRefCard) {
      this.overlayRefCard.dispose();
      this.overlayRefCard = null;
    }
  }

  checkboxLabel(row?): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.taskCollection.isSelected(row.id) ? 'deselect' : 'select'} task ${row.name}`;
  }

  isAllSelected() {
    const numSelected = this.taskCollection.selected.length;
    const numRows = this.tasks.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
        this.taskCollection.clear() :
        this.tasks.forEach(row => this.taskCollection.select(row.id));
  }

  addCardsScen(is_all_results: boolean = false) {
    const dialogRef = this.dialog.open(AddCardScenarioComponent, {
      data: {
        company_id: this.company_id,
        company: this.company,
        tasks: this.taskCollection.selected,
        user: this.user,
        host: this.host,
        imgRoute: this.imgRoute,
        activeLang: this.activeLang,
        is_all_results: is_all_results,
        filterData: this.filter.value
      }
    });
    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (is_all_results) {
          this.refreshPage()
        } else {
          this.taskCollection.selected.forEach(task => {
            this.refreshService.refreshTask$.next({company_id: this.company_id, task_id: task.id})
          })
          this.taskCollection.clear()
        }
      })
    )
  }

  anCardScen(task) {
    const dialogRef = this.dialog.open(CardScenarioComponent, {
      data: {
        company_id: this.company_id,
        company: this.company,
        task: task,
        user: this.user,
        host: this.host,
        imgRoute: this.imgRoute,
        activeLang: this.activeLang,
      }
    });
    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        // this.refreshService.refreshTask$.next({company_id: this.company_id, task_id: task.id})
      })
    )
  }

  addGroup(element) {
    const dialogRef = this.dialog.open(GroupAddComponent, {
      disableClose: true,
      data: {
        company_id: this.company_id,
        task: element
      }
    });
    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        
        if (!!result && result.event == "Add") {
          this.refreshService.refreshTask$.next({company_id: this.company_id, task_id: element.id})
        }
      })
    )
  }

  editGroup(element) {
    if (this.is_mobile) {
      this.mobEditTaskGroup(element)
    } else {
      const dialogRef = this.dialog.open(TaskGroupEditComponent, {
        disableClose: true,
        data: {
          company_id: this.company_id,
          company: this.company,
          task: element
        }
      });
  
      this.attachSubscriptions(
        dialogRef.afterClosed().subscribe(result => {
          
          if (!!result && result.event == "Add") {
            this.refreshService.refreshTask$.next({company_id: this.company_id, task_id: element.id})
          }
        })
      )
    }
  }


  openTargetValues(info, element, is_all_results: boolean = false) {
    let initData:any = {
      company: this.company,
      task: element
    }

    initData.target = {
      task_id: info[0],
      task_operation_id: info[1],
      discussion_id: info[2]
    }
    if (element && element.company_id != this.company.id) {
      initData.target.partner_company_id = element.company_id
    }

    if (this.taskCollection.selected.length > 0) {
      initData.selectedCards = [...this.taskCollection.selected].map(tID => this.tasks.find(x => x.id == tID))
    }

    initData.is_all_cards_results = is_all_results;
    if (is_all_results) {
      initData.filterData = this.filter.value;
    }

    const dialogRef = this.dialog.open(TargetParametersComponent, {
      backdropClass: ['parameters_modal_backdrop'],
      panelClass: ['without_paddings_modal', 'parameters_modal'],
      data: initData
    });

    // this.attachSubscriptions(
    //   dialogRef.afterClosed().subscribe(result => {
    //     this.refreshService.refreshTask$.next({company_id: this.company_id, task_id: element.id})
    //   })
    // )
  }

  deleteGroup(element) {
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: element,
        target: marker("task group")
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        this.attachSubscriptions(
          this.taskService.editTask(element.id, {group_id: 0}, this.company.id).subscribe(resp => {
            this.refreshService.refreshTask$.next({company_id: this.company_id, task_id: element.id})
          })
        )
      }
    });

  }

  // getPlatforms() {
  //   this.attachSubscriptions(
  //     this.companyService.getGroupPlatforms().subscribe(resp => {
  //       resp.unshift({
  //         id:	0,
  //         name: "Without platform",
  //         url: '',
  //         created_at:	0,
  //         updated_at:	0,
  //       })
  //       this.platforms = resp;
  //       this.platforms$.next(this.platforms.slice());
  //     })
  //   )
  // }

  // getPlatformById(id) {
  //   if (!this.platforms || this.platforms.filter(el => el.id == id).length == 0) {
  //     return false;
  //   }
  //   return this.platforms.find(el => el.id == id)
  // }

  addProfile(element) {
    const dialogRef = this.dialog.open(AddTaskProfileComponent, {
      disableClose: true,
      data: {
        company: this.company,
        company_id: this.company_id,
        task: element
      }
    });
    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result && result.event == "Add") {
          this.refreshService.refreshTask$.next({company_id: this.company_id, task_id: element.id})
        }
      })
    )
  }

  addChannels(is_all_results: boolean = false) {
    const dialogRef = this.dialog.open(AddChannelToTaskComponent, {
      disableClose: true,
      data: {
        company: this.company,
        company_id: this.company_id,
        task: undefined,
        tasks: this.taskCollection.selected,
        is_all_results: is_all_results,
        filterData: this.filter.value
      }
    });
    
    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result && result.event == "Add") {
          if (is_all_results) {
            this.refreshPage()
          }
          // this.taskCollection.selected.forEach(element => {
          //   this.refreshService.refreshTask$.next({company_id: this.company_id, task_id: element})
          // });
          // this.taskCollection.clear();
        }
      })
    )
  }

  addChannel(element) {
    console.log(element)
    const dialogRef = this.dialog.open(AddChannelToTaskComponent, {
      disableClose: true,
      data: {
        company: this.company,
        company_id: this.company_id,
        task: element
      }
    });
    
    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result && result.event == "Add") {
          this.refreshService.refreshTask$.next({company_id: this.company_id, task_id: element.id})
        }
      })
    )
  }

  getCurrencyList() {
    this.attachSubscriptions(
      this.globalDataService.getCurrencies().subscribe(resp => {
        console.log("getCurrencyList", resp)
        let currencyData = resp.slice()
        currencyData.map(x => x.summary = 0)
        this.currencyes = currencyData;
        this.totalInfo['summaryPrice'] = [...currencyData]
        console.log("this.totalInfo", this.totalInfo);
      })
    )
  }

  addWork(element) {
    if (!this.is_mobile) {
      const dialogRef = this.dialog.open(WorkAddComponent, {
        disableClose: true,
        data: {
          user: this.user,
          company: this.company,
          company_id: this.company_id,
          task: element
        }
      });
  
      this.attachSubscriptions(
        dialogRef.afterClosed().subscribe(result => {
          
          if (!!result && result.event == "update") {
            this.refreshService.refreshTask$.next({company_id: this.company_id, task_id: element.id})
          }
        })
      )
    } else {
      const dialogRef = this.dialog.open(MobAddJobComponent, {
        backdropClass: ['mob_interface_backdrop'],
        panelClass: ['mob_interface_panel', 'animate__animated', 'animate__slideInUp'],
        disableClose: true,
        data: {
          user: this.user,
          company: this.company,
          company_id: this.company_id,
          task: element
        }
      });
      
      this.attachSubscriptions(
        dialogRef.afterClosed().subscribe(result => {
          
          if (!!result && result.event == "update") {
            this.refreshService.refreshTask$.next({company_id: this.company_id, task_id: element.id})
          }
        })
      )
    }
  }

  taskManagers(task) {
    const dialogRef = this.dialog.open(TaskManagersComponent, {
      data: {
        company: this.company,
        activeLang: this.activeLang,
        user: this.user,
        task: task
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
      })
    )
  }
    

  addManager(element) {
    const dialogRef = this.dialog.open(TaskManagerAddComponent, {
      disableClose: true,
      data: {
        user: this.user,
        company: this.company,
        task: element
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {

      })
    )
  }

  addEmplWork(element) {
    const dialogRef = this.dialog.open(WorkEmplAddComponent, {
      disableClose: true,
      data: {
        company: this.company,
        task: element
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        
        if (!!result && result.event == "update") {
          this.refreshService.refreshTask$.next({company_id: this.company_id, task_id: element.id})
        }
      })
    )
  }


  goToEditTask(element) {
    if (!this.is_mobile) {
      const dialogRef = this.dialog.open(TaskEditComponent, {
        disableClose: true,
        data: {
          company_id: this.company_id,
          task: element,
        }
      });
  
      this.attachSubscriptions(
        dialogRef.afterClosed().subscribe(result => {
          
          if (!!result && result.event == 'update') {
            this.refreshService.refreshTask$.next({company_id: this.company_id, task_id: element.id})
          }
        })
      )
    } else {
      const dialogRef = this.dialog.open(MobTaskEditComponent, {
        backdropClass: ['mob_interface_backdrop'],
        panelClass: ['mob_interface_panel', 'animate__animated', 'animate__slideInUp'],
        disableClose: true,
        data: {
          company_id: this.company_id,
          task: element,
        }
      });
  
      this.attachSubscriptions(
        dialogRef.afterClosed().subscribe(result => {
          if (!!result) {

            this.refreshService.refreshTask$.next({company_id: this.company_id, task_id: element.id})
            switch (result.event) {
              case 'private_comment':
                this.mobTaskEditTitle(element, 'private_comment', "Hidden notes");
                break;
              case 'comment':
                this.mobTaskEditTitle(element, 'comment', "Brief Description");
                break;
              case 'name':
                this.mobTaskEditTitle(element, 'name', "Title");
                break;
              case 'priority':
                this.mobEditTaskPriority(element)
                break;
              case 'group':
                this.mobEditTaskGroup(element)
                break;

              default:
                break;
            }
          }
        })
      )
    }
  }

  mobEditTaskGroup(task) {
    const dialogRef = this.dialog.open(MobTaskEditGroupComponent, {
      backdropClass: ['mob_interface_backdrop'],
      panelClass: ['mob_interface_panel', 'animate__animated', 'animate__slideInUp'],
      data: {
        company: this.company,
        task: task,
        user: this.user,
        target: 'task',
        // array: this.groups,
        control: 'group_id',
        title: 'Edit Card group'
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          if (result.event == 'back') {
            this.goToEditTask(task)
          } else if (result.event == 'apply') {
            if (task && task.channels && task.channels.length && result.data.group_id != task.group_id) {
              let deletedChannelsData = [];
              task.channels.filter(x => x.content_status_id == 1 && !x.content_url).forEach(channel => {
                deletedChannelsData.push({
                  "path": `/api/task-channel/${channel.id}/`,
                  "query": {},
                  "method": "DELETE",
                  "body": ''
                });
              });
              this.taskService.multiRequest(deletedChannelsData).pipe(
                switchMap(() => this.taskService.editTask(task.id, result.data, this.company.id))
              ).subscribe(resp => {
                this.refreshService.refreshTask$.next({company_id: this.company_id, task_id: task.id})
              })
            } else {
              this.taskService.editTask(task.id, result.data, this.company.id).subscribe(resp => {
                this.refreshService.refreshTask$.next({company_id: this.company_id, task_id: task.id})
              })
            }
          } else {
            this.refreshService.refreshTask$.next({company_id: this.company_id, task_id: task.id})
          }
        } else {
          this.refreshService.refreshTask$.next({company_id: this.company_id, task_id: task.id})
        }
      })
    )
  }

  mobEditTaskPriority(task) {
    const dialogRef = this.dialog.open(MobPriorityComponent, {
      backdropClass: ['mob_interface_backdrop'],
      panelClass: ['mob_interface_panel', 'animate__animated', 'animate__slideInUp'],
      data: {
        company: this.company,
        task: task,
        user: this.user,
        target: 'task'
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          if (result.event == 'back') {
            this.goToEditTask(task)
          } else {
            this.refreshService.refreshTask$.next({company_id: this.company_id, task_id: task.id})
          }
        } else {
          this.refreshService.refreshTask$.next({company_id: this.company_id, task_id: task.id})
        }
      })
    )
  }

  mobTaskEditTitle(task, type_prop, type) {
    const dialogRef = this.dialog.open(MobTitleComponent, {
      backdropClass: ['mob_interface_backdrop'],
      panelClass: ['mob_interface_panel', 'animate__animated', 'animate__slideInUp'],
      data: {
        company: this.company,
        task: task,
        user: this.user,
        type: type,
        type_prop: type_prop,
        title: 'Edit Task',
        target: 'task'
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        
        if (result) {
          if (result.event == 'back') {
            this.goToEditTask(task)
          } else {
            this.refreshService.refreshTask$.next({company_id: this.company_id, task_id: task.id})
          }
        } else {
          this.refreshService.refreshTask$.next({company_id: this.company_id, task_id: task.id})
        }
      })
    )
  }

  deleteTask(task) {
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: task,
        target: marker("task")
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        this.attachSubscriptions(
          this.taskService.deleteTask(task.id).subscribe(resp => {
            this.tasks.splice(this.tasks.indexOf(this.tasks.find(x => x.id == task.id)),1);
          })
        )
      }
    });

  }

  getOperationStatusName(id) {
    switch (+id) {
      case 1:
        return "Waiting";
        break;
      case 2:
        return "In process";
        break;
      case 3:
        return "To approve";
        break;
      case 4:
        return "Approved";
        break;
      case 5:
        return "To correct";
        break;
      case 6:
        return "Sources Needed";
        break;
      case 97:
        return "On hold";
        break;
      case 98:
        return "Ready";
        break;
      case 99:
        return "Canceled";
        break;
    }
  }

  getFilterOperationStatusName(id) {
    if (typeof id == "object") {

      if (id.sort().toString() == '1,2,5') {
        return 'All to do'
      } else if (id.sort().toString() == '3,4,6,97,98,99') {
        return 'All done'
      } else {
        let text = []
        id.forEach(x => {
          text.push(this.getOperationStatusName(x))
        })
        return text.join(", ")
      }
    } else {
      switch (+id) {
        case 1:
          return "Waiting";
          break;
        case 2:
          return "In process";
          break;
        case 3:
          return "To approve";
          break;
        case 4:
          return "Approved";
          break;
        case 5:
          return "To correct";
          break;
        case 6:
          return "Sources Needed";
          break;
        case 97:
          return "On hold";
          break;
        case 98:
          return "Ready";
          break;
        case 99:
          return "Canceled";
          break;
      }
    }
  }

  getColumnName(id) {
    if (!this.operationsValues || this.operationsValues.filter(el => el.id == id).length == 0) {
      return '';
    }
    return this.operationsValues.find(el => el.id == id)?.translation?.name ? this.operationsValues.find(el => el.id == id).translation.name : this.operationsValues.find(el => el.id == id).name
  }

  showFilesInterface(task, work?, loc?) {
    const dialogRef = this.dialog.open(InterfaceComponent, {
      backdropClass: ['backdrop_under_header', 'file_backdrop_under_header'],
      panelClass: !this.is_mobile ? ['file_manager_modal', 'show_header'] : 'file_manager_modal',
      data: {
        company: this.company,
        task: task,
        work: work != undefined ? work : 0,
        tasks: this.tasks,
        user: this.user,
        loc: loc != undefined ? loc : "",
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result && result.event == 'update') {

        }
      })
    )
  }

  // WORK
  // workAfterClosed(resp = {body:[{}]}, task, work = null) {
  //   let data = casesModel([resp.body[0]], this.tasks, 'update')

  //   Object.keys(task).forEach(key => {
  //     task[key] = data.arr[0][key]
  //   })
  //   if (work) {
  //     Object.keys(work).forEach(key => {
  //       if (data['arr'][0].operations && data['arr'][0].operations.find(y => y.id == work.id)) {
  //         work[key] = data['arr'][0].operations.find(y => y.id == work.id)[key]
  //       }
  //     })
  //   }

  //   this.attachSubscriptions(
  //     this.chatService.getTasksChats(this.company_id, [task.id]).subscribe(res => {
  //         task['operations'].forEach(o_el => {
  //           o_el['chatsInfo'] = [];
  //           o_el['chatsInfo'].push(...res.filter(z => z.task_operation_id == o_el.id));
  //           o_el['unReadChats'] = res.filter(z => z.task_operation_id == o_el.id && z.is_read != '1').length;
  //           o_el['unClosedChats'] = res.filter(z => z.task_operation_id == o_el.id && z.status_id != '4').length;
  //         });
  //         task['chatsInfo'] = [];
  //         task['chatsInfo'].push(...res);
  //         task['unReadChats'] = res.filter(z => z.is_read != '1').length;
  //         task['unClosedChats'] = res.filter(z => z.status_id != '4').length;

  //         this.tasks.splice(this.tasks.indexOf(task), 1, data.arr[0]);
  //     })
  //   )

  // }

  editProfile(element, profile) {
    const dialogRef = this.dialog.open(AddTaskProfileComponent, {
      disableClose: true,
      data: {
        company: this.company,
        company_id: this.company_id,
        task: element,
        profile: profile,
        is_edit: true
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        
        if (!!result && result.event == 'edit') {
          this.refreshService.refreshTask$.next({company_id: this.company_id, task_id: element.id})
        }
      })
    )
  }

  goToChanelLink(task, chanel) {
    if (!!chanel.content_url) {
      window.open(chanel.content_url, '_blank');
    } else {
      this.editProfile(task, chanel)
    }
  }

  toggleMobCollection(e): void { 
    this.collectionIsActive = !this.collectionIsActive;
    if (!this.collectionIsActive) {
      this.taskCollection.clear();
    }
  }
  
  ngOnDestroy(): void {
    this.layoutService.overflowAuto();
    if (this.tasksCountSub) {      
      this.tasksCountSub.unsubscribe()
    }
    if (this.tasksSub) {
      this.tasksSub.unsubscribe()
    }
    // if (this.paramDataSub) {
    //   this.paramDataSub.unsubscribe();
    // }
    if (this.taskDataSub) {
      this.taskDataSub.unsubscribe();
    }
    this.clearSubscriptions()
  }

}
