<h2 mat-dialog-title class="dialog_title">
    <span>
        {{ ((data.is_edit ? "Edit" : "Add") + " Column") | translate }}
    </span>
</h2>
<mat-dialog-content class="mat-typography" dirEnter (dirSubmit)="submitForm()">
    <div class="container form_wrapper" style="padding: 20px 0;">
        <mat-form-field appearance="standard" class="full_width_form">
            <mat-label>{{ "Choose a Column" | translate }}</mat-label>
            <mat-select [(ngModel)]="data.col" required>
                <mat-option *ngFor="let col of data.columns" [value]="col.id">
                    {{ col.name | translate }}
                </mat-option>
            </mat-select>
        </mat-form-field>       
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button (click)="close()">
        {{ "Cancel" | translate }}
    </button>
    <button class="ml-1 d-f-btn" (click)="submitForm()" mat-raised-button cdkFocusInitial color="primary">
        {{ "Save" | translate }}
    </button>
</mat-dialog-actions>
