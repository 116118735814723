<mat-dialog-content class="mat-typography">
    <div class="container form_wrapper" [appDeviceClass]="'custom-scroll custom-scroll-t'">
        <div class="title_modal d-f-c">
            <ng-container *ngIf="data.is_partner; else elseTemplatePartner">
                <span class="set_width">
                    {{ "Price to partner" | translate }}:
                </span>
                <app-user [class]="'user_div ml-1'" [companyEmployee]="data?.employee?.partnerCompany" [imgRoute]="imgRoute" [host]="host" [company]="data?.company"></app-user>
            </ng-container>
            <ng-template #elseTemplatePartner>
                <span class="set_width">
                    {{ "Price to employee" | translate }}:
                </span>
                <app-user [class]="'user_div ml-1'" [employee]="data?.employee"></app-user>
            </ng-template>
        </div>

        <!-- (infiniteScroll)="getNextDepartments()" -->
        <form [formGroup]="form" class="form_with_pads" (ngSubmit)="onSubmit()">

            <div class="d-f-c">
                <p *ngIf="form.get('is_task_employee')">
                    <mat-checkbox color="primary" formControlName="is_task_employee">{{ "Executor" | translate }}</mat-checkbox>
                </p>
                <p class="ml-1" *ngIf="form.get('is_task_employee_assistant')">
                    <mat-checkbox color="primary" formControlName="is_task_employee_assistant">{{ "Assistant" | translate }}</mat-checkbox>
                </p>
                <p *ngIf="form.get('is_task_partner_employee')">
                    <mat-checkbox color="primary" formControlName="is_task_partner_employee">{{ "Executor" | translate }}</mat-checkbox>
                </p>
                <p class="ml-1" *ngIf="form.get('is_task_partner_employee_assistant')">
                    <mat-checkbox color="primary" formControlName="is_task_partner_employee_assistant">{{ "Assistant" | translate }}</mat-checkbox>
                </p>
            </div>

            <mat-form-field appearance="legacy" class="full_width_form">
                <mat-label>{{ 'Points' | translate }}</mat-label>
                <input cdkFocusInitial formControlName="rate" matInput type="number" placeholder="{{ 'Points' | translate }}">
            </mat-form-field>
    
            <div class="d-f-c">
                <mat-form-field appearance="legacy" class="full_width_form">
                    <mat-label>{{ 'Price' | translate }}</mat-label>
                    <input formControlName="price" matInput type="number" placeholder="{{ 'Price' | translate }}">
                </mat-form-field>
                <mat-form-field appearance="legacy" class="currency_select">
                    <mat-label>{{ "Currency" | translate }}</mat-label>
                    <mat-select #currencySelect [disableOptionCentering]="true" selectDir formControlName="price_currency_id">
                        <mat-option *ngFor="let currency of currencyes" [value]="currency.id">
                          {{ currency.iso_name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <mat-form-field appearance="legacy" class="full_width_form">
                <mat-label>{{ "Job type" | translate }}</mat-label>
                <mat-select [disableOptionCentering]="true" selectDir formControlName="operation_id">
                    <mat-option>
                    <ngx-mat-select-search 
                        placeholderLabel="{{ 'Search' | translate }}" 
                        noEntriesFoundLabel="{{ 'no matching job type found' | translate }}"
                        [formControl]="operationMoreControl"></ngx-mat-select-search>
                    </mat-option>                  
                    <mat-option *ngFor="let operation of operations$ | async" [value]="operation.id">
                        {{ operation.name | translate }}
                    </mat-option>
                </mat-select>           
            </mat-form-field>

            <mat-form-field appearance="legacy" class="full_width_form">
                <mat-label>{{ "Group" | translate }}</mat-label>
                <mat-select [disableOptionCentering]="true" selectDir formControlName="group_id">
                    <mat-option>
                    <ngx-mat-select-search 
                        placeholderLabel="{{ 'Search' | translate }}" 
                        noEntriesFoundLabel="{{ 'no matching groups found' | translate }}"
                        [formControl]="groupMoreControl"></ngx-mat-select-search>
                    </mat-option>                  
                    <mat-option *ngFor="let item of groups$ | async" [value]="item.id">
                        {{ item.name }}
                    </mat-option>
                </mat-select>           
            </mat-form-field>

            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ "Tag" | translate }}</mat-label>
                <mat-select [disableOptionCentering]="true" selectDir formControlName="parameter_value_id">
                    <mat-select-trigger class="multi_users">
                        <ng-container *ngIf="form.get('parameter_value_id').value">
                            <span>{{ getValuesById(form.get('parameter_value_id').value)?.value }}</span>
                        </ng-container>
                    </mat-select-trigger>
                    <mat-option>
                        <ngx-mat-select-search 
                        placeholderLabel="{{ 'Search' | translate }}" 
                        noEntriesFoundLabel="{{ 'no matching tag found' | translate }}"
                        [formControl]="allValuesControl"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let item of allValues$ | async" [value]="item.id">
                        <div class="d-f-c" style="justify-content: space-between;">
                            <span>{{ item.value }}</span>
                            <span>{{ item?.parameter?.name }}</span>
                        </div>
                    </mat-option>
                </mat-select>
                <button class="suffix_button" [ngStyle]="{'color': ((!form.get('price').value && !form.get('rate').value) || isSubmit) ? '#686868' : '#4E844E'}" [disabled]="(!form.get('price').value && !form.get('rate').value) || isSubmit" type="submit" (click)="$event.stopPropagation()" mat-icon-button matSuffix>
                    <mat-icon>add_circle</mat-icon>
                </button>
            </mat-form-field>
        </form>

        <mat-nav-list *ngIf="pricesToEmployee?.length">
            <mat-list-item *ngFor="let priceToEm of pricesToEmployee; let i = index;" class="data_out_item">
                <div class="data_item">
                    <div class="data_item_info">
                        <div class="rpc">
                            <div class="d-f-c">
                                <div class="currency">pts</div>
                                <div class="rate">{{priceToEm.rate | reportsNumber}}</div>
                            </div>
                            <div class="d-f-c">
                                <div class="currency">{{getCurrency(priceToEm.price_currency_id)?.iso_name}}</div>
                                <div class="price">{{priceToEm.price | reportsNumber}}</div>
                            </div>
                        </div>
                        <div class="who text_one_line">
                            <div *ngIf="!!priceToEm.is_task_employee">{{ "Executor" | translate }}</div>
                            <div *ngIf="!!priceToEm.is_task_employee_assistant">{{ "Assistant" | translate }}</div>
                            <div *ngIf="!!priceToEm.is_task_partner_employee">{{ "Executor" | translate }}</div>
                            <div *ngIf="!!priceToEm.is_task_partner_employee_assistant">{{ "Assistant" | translate }}</div>
                        </div>
                        <div class="operation text_one_line">
                            {{getOperationsById(priceToEm.operation_id)?.name | translate}}
                        </div>
                        <div class="group text_one_line">
                            {{getGroupById(priceToEm.group_id)?.name}}
                        </div>
                        <div class="tag text_one_line">
                            {{getValuesById(priceToEm.parameter_value_id)?.value}}
                        </div>
                    </div>
                    <mat-icon style="color: red;" (click)="deletePriceToEmployee(priceToEm.id, i)">delete</mat-icon>
                </div>
            </mat-list-item>
        </mat-nav-list>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <div class="f_w_p_btns">
        <button mat-raised-button (click)="close()">
            {{ "Close" | translate }}
        </button>
    </div>
</mat-dialog-actions>