<h2 mat-dialog-title class="form_title">
    <span>{{ "Add Livestreams for automatization" | translate }}</span>
</h2>

<mat-dialog-content class="mat-typography"  dirEnter (dirSubmit)="!form.valid ? null : onSubmit()">
    <div class="container form_wrapper">

        <form [formGroup]="form" class="form_with_valids form_with_pads">
            <div class="silver_wrp">
                <mat-form-field appearance="standard" class="full_width_form">
                    <mat-label>{{ 'Streams count' | translate }}</mat-label>
                    <input formControlName="streams_count" matInput type="number" placeholder="{{ 'Streams count' | translate }}" (change)="onInput($event, 'streams_count', 1, 25)">
                </mat-form-field>

                <mat-form-field appearance="standard" class="full_width_form h_cst_t">
                    <mat-label>{{ "Select publication outlet" | translate }}</mat-label>
                    <mat-select [disableOptionCentering]="true" selectDir #profilesContainer formControlName="channel_id" required>
                        <mat-select-trigger>
                            <app-outlet-line [channel]="getProfile(form.controls['channel_id'].value)" [company_id]="company_id"></app-outlet-line>
                        </mat-select-trigger>
                        <mat-option>
                            <ngx-mat-select-search 
                            placeholderLabel="{{ 'Search' | translate }}" 
                            noEntriesFoundLabel="{{ 'no matching outlet found' | translate }}"
                            [formControl]="profilesMoreControl"></ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let profile of profiles$ | async" [value]="profile.id" class="cst_o">
                            <app-outlet-line [channel]="profile" [company_id]="company_id"></app-outlet-line>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </form>
        
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <div class="form_buttons">
        <button mat-raised-button (click)="close()">
            {{ "Close" | translate }}
        </button>
        <button class="ml-1 d-f-btn" (click)="onSubmit()" mat-raised-button cdkFocusInitial color="warn" [disabled]="!form.valid || isSubmit">
            <mat-spinner class="spin_16 mr-1" *ngIf="isSubmit"></mat-spinner>  
            {{ "Add" | translate }}
        </button>
    </div>
</mat-dialog-actions>