<mat-dialog-content class="mat-typography" dirEnter (dirSubmit)="!form.valid || isSubmit ? null : onSubmit()">
    <div class="container">
        <div class="title_modal">
            {{ "Edit additional employee to task" | translate }} {{data?.task?.name}}<b>({{data?.task?.custom_id}})</b>
        </div>
        <form [formGroup]="form" class="form_with_pads form_with_valids">

            <div class="small_chips" *ngIf="operationStatuses?.length > 0">
                <ng-container *ngIf="!form.get('status_id').valid && submited">
                    <div class="req_field"></div>
                    <div class="req_label">
                        <div class="angle"></div>
                        <span>
                            {{ "You need to choose a value" | translate }}
                        </span>
                    </div>
                </ng-container>
                <label>{{ "Status" | translate }}: </label>
                <mat-chip-list [multiple]="false" selectable formControlName="status_id">
                    <ng-container *ngFor="let status of operationStatuses">
                        <mat-chip class="small_chip" (click)="selectStatus(status)" [value]="status?.id" [ngClass]="{
                            'first': status.id == '1',
                            'second': status.id == '2',
                            'third': status.id == '3',
                            'fours': status.id == '4',
                            'fives': status.id == '5',
                            'sixs': status.id == '6',
                            'nine': status.id == '97',
                            'seven': status.id == '98',
                            'eight': status.id == '99'}">
                            {{ status.name | translate }}
                        </mat-chip>
                    </ng-container>
                </mat-chip-list>
            </div>
            
            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ 'Executor notes (available only to owners, admins, managers and author of notes)' | translate }}</mat-label>
                <textarea formControlName="employee_comment" matInput placeholder="{{ 'Executor notes (available only to owners, admins, managers and author of notes)' | translate }}"
                cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="5"></textarea>
            </mat-form-field>
    
            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ "Date the job was performed" | translate }}</mat-label>
                <input matInput [matDatepicker]="dp" formControlName="completed_at">
                <mat-datepicker-toggle matPrefix [for]="dp"></mat-datepicker-toggle>
                <mat-datepicker #dp></mat-datepicker>
            </mat-form-field>
    
            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ "Choose a currency" | translate }}</mat-label>
                <mat-select formControlName="price_currency_id" >
                <mat-option *ngFor="let currency of currencyList" [value]="currency.id">
                    {{ currency.name }}
                </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field *ngIf="form.get('rate')" appearance="standard" class="full_width_form">
                <mat-label>{{ 'Points value for job performance' | translate }}</mat-label>
                <input formControlName="rate" matInput type="number" placeholder="{{ 'Points value for job performance' | translate }}">
                <div class="cur_suffix" matSuffix>PTS</div>
            </mat-form-field>

            <mat-form-field appearance="standard" class="full_width_form" *ngIf="form.get('price')">
                <mat-label>{{ 'Price to be charged by executor' | translate }}</mat-label>
                <input formControlName="price" matInput type="number" placeholder="{{ 'Price to be charged by executor' | translate }}">
                <div class="cur_suffix" matSuffix *ngIf="data.addEmpl?.currency">{{ data.addEmpl?.currency?.iso_name }}</div>
            </mat-form-field>

        </form>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <div class="f_w_p_btns">
        <button mat-raised-button (click)="close()">
            {{ "Close" | translate }}
        </button>
        <button class="ml-1 d-f-btn" (click)="onSubmit()" mat-raised-button cdkFocusInitial color="primary" [disabled]="isSubmit">
            <mat-spinner class="spin_16 mr-1" *ngIf="isSubmit"></mat-spinner>  
            {{ "Edit" | translate }}
        </button>
    </div>
</mat-dialog-actions>