<div class="paint" #paint>
    <!-- <div class="options-container">
        <div class="options">

            
            
            
            <a (click)="undo()" (window:keydown.control.z) = "undo()"><i class="fa fa-fw fa-reply"></i> Undo</a>
            <a (click)="redo()" (window:keydown.control.y) = "redo()"><i class="fa fa-fw fa-share"></i> Redo</a> 
            <a (click)="copy()" (window:keydown.control.c) = "copy()"><i class="fa-regular fa-copy"></i> Copy</a> 
        </div>
        <div class="color-pickers">
   
            
        </div>
    </div> -->
    <ul class="shapes">
        <!-- (click)="pick()" -->
        <li><a><input [style.background]="color"
            [(colorPicker)]="color"
            (colorPickerChange)="changeColor()"/></a></li>
        <!-- <li><a type="button" (click)="loadj()"><i class="fa fa-fw fa-folder"></i>Open</a></li> -->
        <!-- <li><a type="button" (click)="savej()"><i class="fa fa-fw fa-save"></i>Save</a></li> -->
        <!-- <li><a (click)="deleteSelected([])"><i class="fa fa-fw fa-eraser"></i> Delete</a></li> -->
        <li><a (click)="deleteAll()" class="cp"><mat-icon>delete</mat-icon></a></li>
<!-- 
        <li>
            <a (click)="border([])"><i class="fa-sharp fa-solid fa-pen"></i>Stroke</a>
        </li> -->
        <li><a style="cursor: pointer" (click)="$event.preventDefault(); $event.stopPropagation(); rectangle()">&#9645;</a></li>
        <!-- <li><a style="font-size: 35px; cursor: pointer" (click)="$event.preventDefault(); $event.stopPropagation(); square()">&#9633;</a></li> -->
        <li><a style="font-size: 25px; cursor: pointer" (click)="$event.preventDefault(); $event.stopPropagation(); ellipse()">&#x2B2D;</a></li>
        <!-- <li style="cursor: pointer" (click)="$event.preventDefault(); $event.stopPropagation(); circle()"><a>&#9711;</a></li> -->
        <!-- <li><a style="font-size: 30px; cursor: pointer" (click)="$event.preventDefault(); $event.stopPropagation(); triangle()">&#9722;</a></li> -->
        <!-- <li style="cursor: pointer" (click)="$event.preventDefault(); $event.stopPropagation(); line()"><a>&#9586;</a></li> -->
    </ul>
    <div id="drawing-field" (click)="$event.preventDefault(); $event.stopPropagation(); draw_shape()"></div>
</div>