import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatBottomSheet, MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { MatButton } from '@angular/material/button';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { forkJoin, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { BaseClass } from '../../models/base-class';
import { StorageManagerService } from '../../services/common/storage-manager.service';
import { ChatService } from '../../services/rest/chat.service';
import { MembersService } from '../../services/rest/members.service';
import { TaskService } from '../../services/rest/task.service';
import { BackJobToPrevStatusComponent } from '../back-job-to-prev-status/back-job-to-prev-status.component';

@Component({
  selector: 'app-select-employee-for-edit-chat-status',
  templateUrl: './select-employee-for-edit-chat-status.component.html',
  styleUrls: ['./select-employee-for-edit-chat-status.component.scss']
})
export class SelectEmployeeForEditChatStatus extends BaseClass implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('btnFocus') btnFocus: MatButton
  public imgRoute: any;
  public employees: any[] = [];
  public host: any = environment.host;
  public targetStatusId: any = this.data.resp.request.body.status_id;
  public discussion: any = this.data.resp.err.error.params.discussion ? this.data.resp.err.error.params.discussion : null
  public job: any = this.data.resp.err.error.params.task_operation ? this.data.resp.err.error.params.task_operation : null
  public isUpperStatus:boolean = this.discussion ? (Number(this.discussion.status_id) < Number(this.targetStatusId)) : Number(this.job.status_id) < Number(this.targetStatusId);
  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private bottomSheetRef: MatBottomSheetRef<SelectEmployeeForEditChatStatus>,
    private sm: StorageManagerService,
    private membersService: MembersService,
    private chatService: ChatService,
    private bottomSheet: MatBottomSheet,
    private taskService: TaskService,
  ) { super() }

  ngOnInit(): void {
    console.log("SelectEmployeeForEditChatStatus", this.data)
    this.getImgRoute();

    if (this.job) {
      this.getJobEmployees()
    } else {
      this.getDiscussionEmployees()
    }

    console.log("this.job, this.discussion", this.job, this.discussion)
  }

  getJobEmployees() {

    let arr = [
      this.membersService.getMembers({task_id: this.job.task_id, task_operation_id: this.job.id, discussion_id: 0}, this.job.company_id).pipe(
        tap(empls => {
          empls.map(b => {
            b.is_partner = false;
            if (this.isUpperStatus) {
              b.active = true;
            }
          })
        })
      ),
      this.membersService.getTaskPartners({task_id: this.job.task_id, task_operation_id: this.job.id, discussion_id: 0}, this.job.company_id).pipe(
        tap(empls => {
          empls.map(b => {
            b.is_partner = true;
            if (this.isUpperStatus) {
              b.active = true;
            }
          })
        })
      )
    ]

    if (this.data.company && this.data.company.id != this.job.company_id) {
      arr.push(
        this.membersService.getTaskPartnerEmployees({task_id: this.job.task_id, task_operation_id: this.job.task_operation_id, discussion_id: 0}, this.data.company.id, this.job.company_id).pipe(
          tap(empls => {
            empls.map(b => {
              b.is_partner_employee = true;
              b.employeeStatus = b.partnerEmployeeStatus;
              if (this.isUpperStatus) {
                b.active = true;
              }
            })
          })
        )
      )
    }
    this.attachSubscriptions(
      forkJoin(arr).subscribe(resp => {
        console.log("getDiscussionEmployees", resp);
        if (this.isUpperStatus) {
          this.employees = resp[0].filter(x => !x.employeeStatus || (!!x.employeeStatus && x.employeeStatus.status_id < this.targetStatusId));
          this.employees.push(...resp[1].filter(x => !x.partnerCompanyStatus || (!!x.partnerCompanyStatus && x.partnerCompanyStatus.status_id < this.targetStatusId)))
          if (resp.length > 2) {
            this.employees.push(...resp[2].filter(x => !x.employeeStatus || (!!x.employeeStatus && x.employeeStatus.status_id < this.targetStatusId)))
          }
        } else {
          this.employees = resp[0].filter(x => !x.employeeStatus || (!!x.employeeStatus && x.employeeStatus.status_id != this.targetStatusId));
          this.employees.push(...resp[1].filter(x => !x.partnerCompanyStatus || (!!x.partnerCompanyStatus && x.partnerCompanyStatus.status_id != this.targetStatusId)))
          if (resp.length > 2) {
            this.employees.push(...resp[2].filter(x => !x.employeeStatus || (!!x.employeeStatus && x.employeeStatus.status_id != this.targetStatusId)))
          }
        }
        console.log("JOB this.employees", this.employees)
      })
    )
  }

  getDiscussionEmployees() {
    let arr = [
      this.membersService.getMembers({task_id: this.discussion.task_id, task_operation_id: this.discussion.task_operation_id, discussion_id: this.discussion.id}, this.discussion.company_id).pipe(
        tap(empls => {
          empls.map(b => {
            if (this.isUpperStatus) {
              b.active = true;
            }
          })
        })
      ),
      this.membersService.getTaskPartners({task_id: this.discussion.task_id, task_operation_id: this.discussion.task_operation_id, discussion_id: this.discussion.id}, this.discussion.company_id).pipe(
        tap(empls => {
          empls.map(b => {
            b.is_partner = true;
            if (this.isUpperStatus) {
              b.active = true;
            }
          })
        })
      )
    ]

    if (this.data.company && this.data.company.id != this.discussion.company_id) {
      arr.push(
        this.membersService.getTaskPartnerEmployees({task_id: this.discussion.task_id, task_operation_id: this.discussion.task_operation_id, discussion_id: this.discussion.id}, this.data.company.id, this.discussion.company_id).pipe(
          tap(empls => {
            empls.map(b => {
              b.is_partner_employee = true;
              b.employee = b.partnerEmployee;
              b.employeeStatus = b.partnerEmployeeStatus;
              if (this.isUpperStatus) {
                b.active = true;
              }
            })
          })
        )
      )
    }

    this.attachSubscriptions(
      forkJoin(arr).subscribe(resp => {
        console.log("getDiscussionEmployees", resp);
        if (this.isUpperStatus) {
          this.employees = resp[0].filter(x => !x.employeeStatus || (!!x.employeeStatus && x.employeeStatus.status_id < this.targetStatusId));
          this.employees.push(...resp[1].filter(x => !x.partnerCompanyStatus || (!!x.partnerCompanyStatus && x.partnerCompanyStatus.status_id < this.targetStatusId)))
          if (resp.length > 2) {
            this.employees.push(...resp[2].filter(x => !x.employeeStatus || (!!x.employeeStatus && x.employeeStatus.status_id < this.targetStatusId)))
          }
        } else {
          this.employees = resp[0].filter(x => !x.employeeStatus || (!!x.employeeStatus && x.employeeStatus.status_id != this.targetStatusId));
          this.employees.push(...resp[1].filter(x => !x.partnerCompanyStatus || (!!x.partnerCompanyStatus && x.partnerCompanyStatus.status_id != this.targetStatusId)))
          if (resp.length > 2) {
            this.employees.push(...resp[2].filter(x => !x.employeeStatus || (!!x.employeeStatus && x.employeeStatus.status_id != this.targetStatusId)))
          }
        }

        console.log("CHAT this.employees", this.employees)
      })
    )
  }

  ngAfterViewInit(): void {
    setTimeout(() => {this.btnFocus.focus()},0)
  }

  onPartnerImgError(event){
    event.target.src = this.imgRoute+'/assets/img/partner.png'
  }

  selectUser(empl) {
    empl.active = !empl.active
  }

  onImgError(event){
    event.target.src = this.imgRoute+'/assets/img/image_black_48dp.svg'
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  getStatusNameById(id) {
    switch (+id) {
      case 1:
        return "Waiting";
        break;
      case 2:
        return "In process";
        break;
      case 3:
        return "To approve";
        break;
      case 4:
        return "Approved";
        break;
      case 5:
        return "To correct";
        break;
      case 6:
        return "Sources Needed";
        break;
      case 97:
        return "On hold";
        break;
      case 98:
        return "Ready";
        break;
      case 99:
        return "Canceled";
        break;
    }
  }

  no(): void {
    this.bottomSheetRef.dismiss({
      message: 'no',
    });
  }

  yes() {
    if (this.job) {
      this.attachSubscriptions(
        forkJoin(this.employees.filter(x => x.active).map(employee => {
          if (!!employee.is_partner) {
            if (!!employee.partnerCompanyStatus?.id) {
              return this.membersService.editPartnerStatus(employee.partnerCompanyStatus.id, {status_id: this.targetStatusId}, this.data.company.id)
            } else {
              return this.membersService.addPartnerStatus({
                status_id: this.targetStatusId,
                company_id: this.job.company_id,
                task_id: this.job.task_id,
                task_operation_id: this.job.id,
                partner_company_id: employee.partner_company_id,
              }, this.data.company.id)
            }
          } else if (!!employee.is_partner_employee) {
            if (!!employee.employeeStatus?.id) {
              return this.membersService.editPartnerEmployeeStatus(employee.employeeStatus.id, {status_id: this.targetStatusId}, this.data.company.id)
            } else {
              return this.membersService.addPartnerEmployeeStatus({
                status_id: this.targetStatusId,
                company_id: this.discussion.company_id,
                task_id: this.discussion.task_id,
                task_operation_id: this.discussion.task_operation_id,
                partner_company_id: employee.partner_company_id,
                partner_employee_id: employee.partner_employee_id
              }, this.data.company.id)
            }
          } else {
            if (!!employee.employeeStatus?.id) {
              return this.membersService.editTaskEmployeeStatus(employee.employeeStatus.id, {status_id: this.targetStatusId}, this.data.company.id)
            } else {
              return this.membersService.addTaskEmployeeStatus({
                status_id: this.targetStatusId,
                company_id: this.job.company_id,
                task_id: this.job.task_id,
                task_operation_id: this.job.id,
                employee_id: employee.employee_id,
              }, this.data.company.id)
            }
          }
        })).subscribe(resp => {
          this.bottomSheetRef.dismiss({
            message: 'yes',
          });
        })
      ) 
    } else {
      this.attachSubscriptions(
        forkJoin(this.employees.filter(x => x.active).map(employee => {
          if (!!employee.is_partner) {
            if (!!employee?.partnerCompanyStatus) {
              return this.membersService.editPartnerStatus(employee?.partnerCompanyStatus.id, {status_id: this.targetStatusId}, this.data.company.id)
            } else {
              return this.membersService.addPartnerStatus({
                status_id: this.targetStatusId,
                company_id: this.discussion.company_id,
                task_id: this.discussion.task_id,
                task_operation_id: this.discussion.task_operation_id,
                discussion_id: this.discussion.id,
                partner_company_id: employee.partner_company_id
              }, this.data.company.id)
            }
          } else if (!!employee.is_partner_employee) {
            if (!!employee.employeeStatus?.id) {
              return this.membersService.editPartnerEmployeeStatus(employee.employeeStatus.id, {status_id: this.targetStatusId}, this.data.company.id)
            } else {
              return this.membersService.addPartnerEmployeeStatus({
                status_id: this.targetStatusId,
                company_id: this.discussion.company_id,
                task_id: this.discussion.task_id,
                task_operation_id: this.discussion.task_operation_id,
                discussion_id: this.discussion.id,
                partner_company_id: employee.partner_company_id,
                partner_employee_id: employee.partner_employee_id
              }, this.data.company.id)
            }
          } else {
            if (!!employee?.employeeStatus) {
              return this.membersService.editTaskEmployeeStatus(employee?.employeeStatus.id, {status_id: this.targetStatusId}, this.data.company.id)
            } else {
              return this.membersService.addTaskEmployeeStatus({
                status_id: this.targetStatusId,
                company_id: this.discussion.company_id,
                task_id: this.discussion.task_id,
                task_operation_id: this.discussion.task_operation_id,
                discussion_id: this.discussion.id,
                employee_id: employee.employee_id
              }, this.data.company.id)
            }
          }
  
        }))
        .pipe(
          switchMap(val => {
            console.log("VAL1", val)
            if ([3,4,98,99].includes(this.targetStatusId)) {
              return this.chatService.detectChats(this.discussion.company_id, this.discussion.task_operation_id, [1,2,5,6]).pipe(
                map(x => x.headers.get('x-pagination-total-count')),
                switchMap(res => {
                  console.log("VAL2", res)
                  if (res == 0) {
                    let questionAlert =  this.bottomSheet.open(BackJobToPrevStatusComponent, {
                      hasBackdrop: true,
                      backdropClass: 'bottom-sheed-backdrop',
                      data: {
                        msg: marker(`Do you really want to convert job to status: '${this.getStatusNameById(this.targetStatusId)}'?`)
                      }
                    });
                
                    return questionAlert.afterDismissed()
                    .pipe(
                      switchMap(data => {
                        if (data && data.message == 'no') {
                          console.log("no");
                          return of(val)
                        } else if (data && data.message == 'yes') {
                          return this.taskService.editWork(this.discussion.task_operation_id, {status_id: this.targetStatusId}, this.discussion.company_id).pipe(
                            map(() => val)
                          )
                        } else {
                          return of(val)
                        }
                      })
                    )
                    
                  } else {
                  return of(val)
                  }
                })
              )
            } else {
            return of(val)
            }
          })
        ).subscribe(resp => {
          this.bottomSheetRef.dismiss({
            message: 'yes',
          });
        })
      ) 
    }
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

}
