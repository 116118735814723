<h2 mat-dialog-title class="dialog_title">
    <span>
        {{ "Chat status" | translate }}:
    </span>
    <button class="ml-1" mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h2>
<mat-dialog-content class="mat-typography">
    <ng-container *ngIf="isInit; else elseTemplateInit">
        <div class="statuses_wrp">
            <ng-container *ngIf="data?.iAmEmployee && data?.iAmEmployee.is_manager == 0 && data?.iAmEmployee && !checkIsManager(data.task, data.company, data.user); else elseTemplate">
                <div class="title">
                    <div class="left">
                        {{ "Set your status" | translate }}:
                    </div>
                    <div class="right">
                        {{ "Other executors statuses" | translate }}
                    </div>
                </div>
                <div class="interface">
                    <ng-container *ngFor="let status of data.statuses">
                        <div class="line" *ngIf="status.id != 98">
                            <div class="status_btn_out">
                                <div class="status_btn text_one_line" (click)="addEmlChangeStatus(status)" [ngClass]="{
                                    'active': status.id == data?.chat?.status_id,
                                    'disabled': status.is_lock == 1 && !checkIsManager(data.task, data.company, data.user),
                                    'first': status.id == '1',
                                    'second': status.id == '2',
                                    'third': status.id == '3',
                                    'fours': status.id == '4',
                                    'fives': status.id == '5',
                                    'sixs': status.id == '6',
                                    'nine': status.id == '97',
                                    'seven': status.id == '98',
                                    'eight': status.id == '99'}">
                                    {{status.name}}
                                </div>
                            </div>
                             
                            <div class="items">
                                <div class="item" *ngFor="let item of status.empl" [ngClass]="{
                                    'first': status.id == '1',
                                    'second': status.id == '2',
                                    'third': status.id == '3',
                                    'fours': status.id == '4',
                                    'fives': status.id == '5',
                                    'sixs': status.id == '6',
                                    'nine': status.id == '97',
                                    'seven': status.id == '98',
                                    'eight': status.id == '99'}">
                                    <ng-container *ngIf="!item.is_partner; else elseTemplateIsPartner">               
                                        <ng-container *ngIf="item?.employee?.image; else elseStatEmpl">
                                            <img class="partner_avatar" [src]="item?.employee?.image" alt="">
                                        </ng-container>
                                        <ng-template #elseStatEmpl>
                                            <mat-icon>account_circle</mat-icon>
                                        </ng-template>
                                    </ng-container>
                                    <ng-template #elseTemplateIsPartner>
                                        <ng-container *ngIf="!!item?.partnerCompany?.avatarFile?.thumbnail && !!data?.host; else elseTemplatePartnerAvatar">
                                            <img class="partner_avatar" (error)="onPartnerImgError($event)" [src]="data?.host + item?.partnerCompany?.avatarFile?.thumbnail + '?company_id=' + data?.company?.id" alt="">
                                        </ng-container>
                                        <ng-template #elseTemplatePartnerAvatar>
                                            <img class="partner_avatar" (error)="onPartnerImgError($event)" src="{{ data?.imgRoute }}assets/img/partner.png">
                                        </ng-template>
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    
                </div>
            </ng-container>
            <ng-template #elseTemplate>
                <mat-horizontal-stepper [selectedIndex]="selectedIndex" labelPosition="bottom" #stepper (selectionChange)="selectionChange($event)">
                    <!-- [stepControl]="selectedStatus" errorMessage="Name is required." -->
                    <mat-step [stepControl]="selectedStatus">
                        <ng-template matStepLabel>{{ 'Status' | translate }}</ng-template>
                        <div class="title_step">
                            <div class="left">
                                {{ "Tap the status you want to apply to executor who you select on next step" | translate }}:
                            </div>
                            <div class="right">
                                {{ "Current statuses of other executors" | translate }}
                            </div>
                        </div>
                        <div class="interface">
                            <ng-container *ngFor="let status of data.statuses">
                                <div class="line" *ngIf="status.id != 98">
                                    <div class="status_btn_out">
                                        <div class="status_btn text_one_line" (click)="managerChangeStatus(status)" [ngClass]="{
                                            'active': status.id == data?.chat?.status_id,
                                            'first': status.id == '1',
                                            'second': status.id == '2',
                                            'third': status.id == '3',
                                            'fours': status.id == '4',
                                            'fives': status.id == '5',
                                            'sixs': status.id == '6',
                                            'nine': status.id == '97',
                                            'seven': status.id == '98',
                                            'eight': status.id == '99'}">
                                            {{status.name}}
                                        </div>
                                    </div>
                                     
                                    <div class="items">
                                        <div class="item" *ngFor="let item of status.empl" [ngClass]="{
                                            'first': status.id == '1',
                                            'second': status.id == '2',
                                            'third': status.id == '3',
                                            'fours': status.id == '4',
                                            'fives': status.id == '5',
                                            'sixs': status.id == '6',
                                            'nine': status.id == '97',
                                            'seven': status.id == '98',
                                            'eight': status.id == '99'}">
                                            <ng-container *ngIf="!item.is_partner; else elseTemplateIsPartner">               
                                                <ng-container *ngIf="item?.employee?.image; else elseStatEmpl">
                                                    <img class="partner_avatar" [src]="item?.employee?.image" alt="">
                                                </ng-container>
                                                <ng-template #elseStatEmpl>
                                                    <mat-icon>account_circle</mat-icon>
                                                </ng-template>
                                            </ng-container>
                                            <ng-template #elseTemplateIsPartner>
                                                <ng-container *ngIf="!!item?.partnerCompany?.avatarFile?.thumbnail && !!data?.host; else elseTemplatePartnerAvatar">
                                                    <img class="partner_avatar" (error)="onPartnerImgError($event)" [src]="data?.host + item?.partnerCompany?.avatarFile?.thumbnail + '?company_id=' + data?.company?.id" alt="">
                                                </ng-container>
                                                <ng-template #elseTemplatePartnerAvatar>
                                                    <img class="partner_avatar" (error)="onPartnerImgError($event)" src="{{ data?.imgRoute }}assets/img/partner.png">
                                                </ng-template>
                                            </ng-template>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </mat-step>
                    <mat-step>
                        <ng-template matStepLabel>{{ 'For executor' | translate }}</ng-template>
                        <div class="second_step">
                            <div class="stat_val">
                                {{ "Status" | translate }} 
                                <div class="status_btn_out">
                                    <div class="status_btn active text_one_line" [ngClass]="{
                                        'first': selectedStatus.value?.stat?.id == '1',
                                        'second': selectedStatus.value?.stat?.id == '2',
                                        'third': selectedStatus.value?.stat?.id == '3',
                                        'fours': selectedStatus.value?.stat?.id == '4',
                                        'fives': selectedStatus.value?.stat?.id == '5',
                                        'sixs': selectedStatus.value?.stat?.id == '6',
                                        'nine': selectedStatus.value?.stat?.id == '97',
                                        'seven': selectedStatus.value?.stat?.id == '98',
                                        'eight': selectedStatus.value?.stat?.id == '99'}">
                                        {{selectedStatus.value?.stat?.name}}
                                    </div>
                                </div>
                            </div>
                            <ng-container *ngIf="[4,98,99].includes(selectedStatus.value?.stat?.id); else elseTemplateIncludes">
                                <div class="def">{{ "should be applied for" | translate}}:</div>
                                <div class="mob_stat_empl_wrp m-t-1">
                                    <div class="mob_stat_empl" style="padding: 2px 10px; margin: 0" (click)="selectWhole()">
                                        <span style="margin: 0;">
                                            {{ "Whole chat" | translate }}
                                        </span>
                                    </div>
                                </div>
                                <div class="def def_bold" *ngIf="employees?.length">{{ "Or / and should be for" | translate }}:</div>
                                <div class="mob_stat_empl_wrp">
                                    <div class="mob_stat_empl" *ngIf="me" (click)="selectUser(me)" [ngClass]="{
                                        'active': employee_ids.includes(me?.id),
                                        'first': me?.employeeStatus?.status_id == '1' || me?.partnerCompanyStatus?.status_id == '1',
                                        'second': me?.employeeStatus?.status_id == '2' || me?.partnerCompanyStatus?.status_id == '2',
                                        'third': me?.employeeStatus?.status_id == '3' || me?.partnerCompanyStatus?.status_id == '3',
                                        'fours': me?.employeeStatus?.status_id == '4' || me?.partnerCompanyStatus?.status_id == '4',
                                        'fives': me?.employeeStatus?.status_id == '5' || me?.partnerCompanyStatus?.status_id == '5',
                                        'sixs': me?.employeeStatus?.status_id == '6' || me?.partnerCompanyStatus?.status_id == '6',
                                        'nine': me?.employeeStatus?.status_id == '97' || me?.partnerCompanyStatus?.status_id == '97',
                                        'seven': me?.employeeStatus?.status_id == '98' || me?.partnerCompanyStatus?.status_id == '98',
                                        'eight': me?.employeeStatus?.status_id == '99' || me?.partnerCompanyStatus?.status_id == '99'}">
                                        <ng-container *ngIf="!me.is_partner; else elseTemplateMeAddPartner">
                                            <ng-container *ngIf="me?.employee?.image; else elseStatEmplME">
                                                <img [src]="me?.employee?.image" alt="">
                                            </ng-container>
                                            <ng-template #elseStatEmplME>
                                                <mat-icon>account_circle</mat-icon>
                                            </ng-template>
                                        </ng-container>
                                        <ng-template #elseTemplateMeAddPartner>
                                            <ng-container *ngIf="!!empl?.partnerCompany?.avatarFile?.thumbnail && !!data?.host; else elseTemplatePartnerAvatar">
                                                <img class="partner_avatar" (error)="onPartnerImgError($event)" [src]="data?.host + empl?.partnerCompany?.avatarFile?.thumbnail + '?company_id=' + data?.company?.id" alt="">
                                            </ng-container>
                                            <ng-template #elseTemplatePartnerAvatar>
                                                <img class="partner_avatar" (error)="onPartnerImgError($event)" src="{{ data?.imgRoute }}assets/img/partner.png">
                                            </ng-template>
                                        </ng-template>
                                        <span>
                                            {{ "Me" | translate }}
                                        </span>
                                    </div>
                                    <div class="mob_stat_empl" *ngFor="let empl of employees" [ngClass]="{
                                        'active': employee_ids.includes(empl?.id),
                                        'first': empl?.employeeStatus?.status_id == '1' || empl?.partnerCompanyStatus?.status_id == '1',
                                        'second': empl?.employeeStatus?.status_id == '2' || empl?.partnerCompanyStatus?.status_id == '2',
                                        'third': empl?.employeeStatus?.status_id == '3' || empl?.partnerCompanyStatus?.status_id == '3',
                                        'fours': empl?.employeeStatus?.status_id == '4' || empl?.partnerCompanyStatus?.status_id == '4',
                                        'fives': empl?.employeeStatus?.status_id == '5' || empl?.partnerCompanyStatus?.status_id == '5',
                                        'sixs': empl?.employeeStatus?.status_id == '6' || empl?.partnerCompanyStatus?.status_id == '6',
                                        'nine': empl?.employeeStatus?.status_id == '97' || empl?.partnerCompanyStatus?.status_id == '97',
                                        'seven': empl?.employeeStatus?.status_id == '98' || empl?.partnerCompanyStatus?.status_id == '98',
                                        'eight': empl?.employeeStatus?.status_id == '99' || empl?.partnerCompanyStatus?.status_id == '99'}"
                                        (click)="selectUser(empl)">
                                        <ng-container *ngIf="!empl.is_partner; else elseTemplatePartnerAdd">
                                            <ng-container *ngIf="empl?.employee?.image; else elseStatEmplempl">
                                                <img [src]="empl?.employee?.image" alt="">
                                            </ng-container>
                                            <ng-template #elseStatEmplempl>
                                                <mat-icon>account_circle</mat-icon>
                                            </ng-template>
                                            <span>
                                                {{empl?.employee?.name}}
                                            </span>
                                        </ng-container>
                                        <ng-template #elseTemplatePartnerAdd>
                                            <ng-container *ngIf="!!empl?.partnerCompany?.avatarFile?.thumbnail && !!data?.host; else elseTemplatePartnerAvatar">
                                                <img class="partner_avatar" (error)="onPartnerImgError($event)" [src]="data?.host + empl?.partnerCompany?.avatarFile?.thumbnail + '?company_id=' + data?.company?.id" alt="">
                                            </ng-container>
                                            <ng-template #elseTemplatePartnerAvatar>
                                                <img class="partner_avatar" (error)="onPartnerImgError($event)" src="{{ data?.imgRoute }}assets/img/partner.png">
                                            </ng-template>
                                            <span>{{ empl.partnerCompany.name }}</span>
                                        </ng-template>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-template #elseTemplateIncludes>
                                <ng-container *ngIf="me; else elseTemplateHasMe">
                                    <div class="def">{{ "should be applied for" | translate}}:</div>
                                    <div class="mob_stat_empl_wrp m-t-1">
                                        <div class="mob_stat_empl" (click)="selectUser(me)" [ngClass]="{
                                            'active': employee_ids.includes(me?.id),
                                            'first': me?.employeeStatus?.status_id == '1' || me?.partnerCompanyStatus?.status_id == '1',
                                            'second': me?.employeeStatus?.status_id == '2'  || me?.partnerCompanyStatus?.status_id == '2',
                                            'third': me?.employeeStatus?.status_id == '3' || me?.partnerCompanyStatus?.status_id == '3',
                                            'fours': me?.employeeStatus?.status_id == '4' || me?.partnerCompanyStatus?.status_id == '4',
                                            'fives': me?.employeeStatus?.status_id == '5' || me?.partnerCompanyStatus?.status_id == '5',
                                            'sixs': me?.employeeStatus?.status_id == '6' || me?.partnerCompanyStatus?.status_id == '6',
                                            'nine': me?.employeeStatus?.status_id == '97' || me?.partnerCompanyStatus?.status_id == '97',
                                            'seven': me?.employeeStatus?.status_id == '98' || me?.partnerCompanyStatus?.status_id == '98',
                                            'eight': me?.employeeStatus?.status_id == '99' || me?.partnerCompanyStatus?.status_id == '99'}">
                                            <ng-container *ngIf="me.is_partner; else elseTemplateMePartner">
                                                <ng-container *ngIf="!!empl?.partnerCompany?.avatarFile?.thumbnail && !!data?.host; else elseTemplatePartnerAvatar">
                                                    <img class="partner_avatar" (error)="onPartnerImgError($event)" [src]="data?.host + empl?.partnerCompany?.avatarFile?.thumbnail + '?company_id=' + data?.company?.id" alt="">
                                                </ng-container>
                                                <ng-template #elseTemplatePartnerAvatar>
                                                    <img class="partner_avatar" (error)="onPartnerImgError($event)" src="{{ data?.imgRoute }}assets/img/partner.png">
                                                </ng-template>
                                            </ng-container>
                                            <ng-template #elseTemplateMePartner>
                                                <ng-container *ngIf="me?.employee?.image; else elseStatEmplME">
                                                    <img [src]="me?.employee?.image" alt="">
                                                </ng-container>
                                                <ng-template #elseStatEmplME>
                                                    <mat-icon>account_circle</mat-icon>
                                                </ng-template>
                                            </ng-template>
                                            <span>
                                                {{ "Me" | translate }}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="def def_bold" *ngIf="employees?.length">{{ "Or / and should be for" | translate }}:</div>
                                </ng-container>
                                <ng-template #elseTemplateHasMe>
                                    <div class="def def_bold">{{ "should be applied for" | translate}}:</div>
                                </ng-template>
                                <div class="mob_stat_empl_wrp">
                                    <div class="mob_stat_empl" *ngFor="let empl of employees" [ngClass]="{
                                        'active': employee_ids.includes(empl?.id),
                                        'first': empl?.employeeStatus?.status_id == '1' || empl?.partnerCompanyStatus?.status_id == '1',
                                        'second': empl?.employeeStatus?.status_id == '2' || empl?.partnerCompanyStatus?.status_id == '2',
                                        'third': empl?.employeeStatus?.status_id == '3' || empl?.partnerCompanyStatus?.status_id == '3',
                                        'fours': empl?.employeeStatus?.status_id == '4' || empl?.partnerCompanyStatus?.status_id == '4',
                                        'fives': empl?.employeeStatus?.status_id == '5' || empl?.partnerCompanyStatus?.status_id == '5',
                                        'sixs': empl?.employeeStatus?.status_id == '6' || empl?.partnerCompanyStatus?.status_id == '6',
                                        'nine': empl?.employeeStatus?.status_id == '97' || empl?.partnerCompanyStatus?.status_id == '97',
                                        'seven': empl?.employeeStatus?.status_id == '98' || empl?.partnerCompanyStatus?.status_id == '98',
                                        'eight': empl?.employeeStatus?.status_id == '99' || empl?.partnerCompanyStatus?.status_id == '99'}"
                                        (click)="selectUser(empl)">
                                        <ng-container *ngIf="!empl.is_partner; else elseTemplatePartner">
                                            <ng-container *ngIf="empl?.employee?.image; else elseStatEmplempl">
                                                <img [src]="empl?.employee?.image" alt="">
                                            </ng-container>
                                            <ng-template #elseStatEmplempl>
                                                <mat-icon>account_circle</mat-icon>
                                            </ng-template>
                                            <span>
                                                {{empl?.employee?.name}}
                                            </span>
                                        </ng-container>
                                        <ng-template #elseTemplatePartner>
                                            <ng-container *ngIf="!!empl?.partnerCompany?.avatarFile?.thumbnail && !!data?.host; else elseTemplatePartnerAvatar">
                                                <img class="partner_avatar" (error)="onPartnerImgError($event)" [src]="data?.host + empl?.partnerCompany?.avatarFile?.thumbnail + '?company_id=' + data?.company?.id" alt="">
                                            </ng-container>
                                            <ng-template #elseTemplatePartnerAvatar>
                                                <img class="partner_avatar" (error)="onPartnerImgError($event)" src="{{ data?.imgRoute }}assets/img/partner.png">
                                            </ng-template>
                                            <span>{{ empl.partnerCompany.name }}</span>
                                        </ng-template>
                                        
                        
                                    </div>
                                </div>
                            </ng-template>
                        </div>
                    </mat-step>
                </mat-horizontal-stepper>
            </ng-template>
        </div>
    </ng-container>
    <ng-template #elseTemplateInit>
        
    </ng-template>
    
</mat-dialog-content>
<mat-dialog-actions align="end" style="margin-top: 5px;" *ngIf="!!data?.selectedStatus && selectedIndex == 1">
    <div class="f_w_p_btns">
        <button mat-raised-button (click)="close()">
            {{ "Close" | translate }}
        </button>
        <button class="ml-1" (click)="onSubmit()" mat-raised-button cdkFocusInitial color="primary">
            {{ "Save" | translate }}
        </button>
    </div>
</mat-dialog-actions>
