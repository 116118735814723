import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { forkJoin, fromEvent, ReplaySubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, last, map, switchMap, tap } from 'rxjs/operators';
import { DeleteAlertComponent } from 'src/app/shared/global_components/delete-alert/delete-alert.component';
import { BaseClass } from 'src/app/shared/models/base-class';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { AuthService } from 'src/app/shared/services/rest/auth.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { environment } from 'src/environments/environment';
import { TargetParametersComponent } from '../../../atTasksDialog/target-parameters/target-parameters.component';
import { ChannelToEmployeeComponent } from '../../members/employees/dialogs/channel-to-employee/channel-to-employee.component';
import { ProfileCreateComponent } from '../profile-create/profile-create.component';
import { ConnectWithYoutubeComponent } from '../profile-edit/dialogs/connect-with-youtube/connect-with-youtube.component';
import { ProfileEditComponent } from '../profile-edit/profile-edit.component';
import { ProfilePlaylistComponent } from '../profile-playlist/profile-playlist.component';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { DropboxComponent } from './dialogs/dropbox/dropbox.component';
import { DropboxKeysComponent } from './dialogs/dropbox-keys/dropbox-keys.component';
import { DropboxChannelComponent } from './dialogs/dropbox-channel/dropbox-channel.component';

@Component({
  selector: 'app-dropboxes',
  templateUrl: './dropboxes.component.html',
  styleUrls: ['./dropboxes.component.scss']
})
export class DropboxesComponent extends BaseClass implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild("tableContainer") tableContainer: ElementRef;
  public host: any = environment.host;
  public imgRoute: any = '';
  public user: any = {};
  public user_id: number;
  public company_id: any;
  public company: any;
  public pagination: any;
  public page: number = 1;

  public sftps:any = []


  constructor(
    private sm: StorageManagerService,
    private auth: AuthService,
    private companyService: CompanyService,
    private fb: FormBuilder,
    private router: Router,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private bottomSheet: MatBottomSheet,
    private layoutService: LayoutService
  ) { super() }

  ngOnInit(): void {
    window.scroll(0,0);

    this.company_id = this.activatedRoute.snapshot.queryParamMap.get('company_id');

    this.getCompany();
   
    this.getImgRoute();
    this.getCsrf();
    this.getUser();

    this.attachSubscriptions(
      this.activatedRoute.queryParams.subscribe(params => {
        this.company_id = this.activatedRoute.snapshot.queryParamMap.get('company_id');
        this.page = 1;


        this.getSftps(this.company_id, this.page);
      })
    )
    
  }

  ngAfterViewInit(): void {
    this.onScroll();
  }

  onScroll() {
    this.attachSubscriptions(
      fromEvent(this.tableContainer.nativeElement, "scroll").pipe(
        filter((e:any) => e.target.scrollTop >=  e.target.scrollHeight - e.target.offsetHeight - 400),
        map(() => this.tableContainer.nativeElement.scrollTop),
        debounceTime(300),
        map(y => Math.ceil((y + this.tableContainer.nativeElement.innerHeight)/ (400))),
        distinctUntilChanged()
      ).subscribe(() => {
          if (this.page <= this.pagination['pageCount']) {
            this.getSftps(this.company_id, this.page);
          }
        }
      )
    )
  }

  getCompany() {
    this.attachSubscriptions(
      this.companyService.getCompany(this.company_id).subscribe(resp => {
        this.companyService.company$.next(resp[0]);
        this.company = resp[0];
      })
    )
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  getCsrf() {
    this.attachSubscriptions(
      this.auth.$userToken.subscribe(resp => {
        this.user_id = resp.user_id;
        this.sm.localStorageSetItem("csrf_param", resp.csrf_param)
        this.sm.localStorageSetItem("csrf_token", resp.csrf_token)
      })
    )
  }

  getUser() {
    this.attachSubscriptions(
      this.auth.$user.subscribe(resp => {
        this.user = resp;
      })
    )
  }

  getSftps(company_id, page) {
    this.attachSubscriptions(
      this.companyService.getSftps(page, company_id).pipe(
        tap(el => {
          this.pagination = {
            'pageCount': el.headers.get('x-pagination-page-count'),
            'perPage': el.headers.get('x-pagination-per-page'),
            'totalCount': el.headers.get('x-pagination-total-count'),
            'currentPage': el.headers.get('x-pagination-current-page'),
          }
        }),
        map(el => el.body)
      ).subscribe(resp => {
        console.log("getSftps", resp)
        if (page == 1) {
          this.sftps = resp
        } else {
          this.sftps.push(...resp);
        }

        this.page = this.page + 1;

      })
    )
  }

  copyPublicKey() {
    this.layoutService.showSnackBar({name: marker("Public key")}, marker("Copied"), SnackBarItem)
  }

  deleteSftp(item, i) {
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: {},
        target: marker("Youtube Sftp Dropbox")
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        this.attachSubscriptions(
          this.companyService.deleteSftp(item.id).subscribe(resp => {
            this.sftps.splice(i, 1);
          }, error => {
            this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
          })
        )
      }
    });
  }

  expandItem(item) {
    item.isOpen = !item.isOpen;

    if (!item.values) {
      item.values = [];
    }

    this.attachSubscriptions(
      this.companyService.getSftpChannels('1', this.company_id, item.id, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200);
          item.valuesCount = +el.headers.get('x-pagination-total-count');
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }
  
          return forkJoin(arr.map(x => this.companyService.getSftpChannels(x, this.company_id, item.id).pipe(map(u => u.body)))).pipe(
            last(),
            tap(values => {
              let conValues = [].concat(...values)
              item.values = conValues;
            }),
          )
        }),
      ).subscribe(resp => {
        console.log("expandItem", item)
      })
    )
    
    // this.attachSubscriptions(
    //   this.companyService.getSftpChannels('1', this.company_id, item.id).pipe(
    //     map(x => x.body)
    //   ).subscribe(resp => {
    //     item.values = resp
    //     console.log("expandItem", item)
    //   })
    // )
  }

  addSftpChannel(item) {
    const dialogRef = this.dialog.open(DropboxChannelComponent, {
      disableClose: true,
      data: {
        company: this.company,
        company_id: this.company_id,
        sftp: item
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result && result.event == "update") {
          this.page = 1;
          this.getSftps(this.company_id, this.page);
        }
      })
    )
  }

  deleteSftpChannel(item, ch, i) {
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: ch,
        target: marker("Youtube Sftp Dropbox Channel")
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        this.attachSubscriptions(
          this.companyService.deleteSftpChannel(ch.id).subscribe(resp => {
            item.values.splice(i, 1)
          }, error => {
            console.log(error)
          })
        )
      }
    });

  }

  addSftp(e) {
    e.preventDefault();
    const dialogRef = this.dialog.open(DropboxComponent, {
      disableClose: true,
      data: {
        company: this.company,
        company_id: this.company_id
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result && result.event == "update") {
          this.page = 1;
          this.getSftps(this.company_id, this.page);
        }
      })
    )
  }

  generateSftpKeys(element) {
    this.attachSubscriptions(
      this.companyService.editSftp(element.id, {is_generate_keys: 1}).subscribe(resp => {
        this.page = 1;
        this.getSftps(this.company_id, this.page);
      }, error => {
        this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
      })
    )
  }

  submitSftp(element) {
    this.attachSubscriptions(
      this.companyService.editSftp(element.id, {is_activate: 1}).subscribe(resp => {
        this.page = 1;
        this.getSftps(this.company_id, this.page);
      }, error => {
        this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
      })
    )
  }
  
  editSftp(element) {
    const dialogRef = this.dialog.open(DropboxComponent, {
      disableClose: true,
      data: {
        company: this.company,
        company_id: this.company_id,
        sftp: element
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result && result.event == "update") {
          this.page = 1;
          this.getSftps(this.company_id, this.page);
        }
      })
    )
  }
  
  enterSftpKeys(element) {
    const dialogRef = this.dialog.open(DropboxKeysComponent, {
      disableClose: true,
      data: {
        company: this.company,
        company_id: this.company_id,
        sftp: element
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result && result.event == "update") {
          this.page = 1;
          this.getSftps(this.company_id, this.page);
        }
      })
    )
  }

  showSftpKeys(element) {
    const dialogRef = this.dialog.open(DropboxKeysComponent, {
      disableClose: true,
      data: {
        company: this.company,
        company_id: this.company_id,
        sftp: element,
        key: true
      }
    });
  }

  goBack(e) {
    e.preventDefault();
    history.back();
  }


  goToCompany(e, company_id) {
    e.preventDefault();
    this.router.navigate(['/tasks'], { queryParams: {company_id: company_id, order_by: "updated_desc"} });
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

}

