import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'perm'
})
export class PermissionsPipe implements PipeTransform {
    transform(company: any, value:string) {
        // console.log("PermissionsPipe", company, value)
        switch (value) {
            case 'see_files':
                return !!company && (company.permissions.includes('owner') || company.permissions.includes('admin') || company.permissions.includes('manager') || company.permissions.includes('see_files'));
                break;

            case 'see_task_channels':
                return !!company && (company.permissions.includes('owner') || company.permissions.includes('admin') || company.permissions.includes('manager') || company.permissions.includes('see_task_channels'));
                break;
                
            case 'see_files_section':
                return !!company && (company.permissions.includes('owner') || (company.permissions.includes('see_files') && company.permissions.includes('see_files_section')));
                break;
                
            default:
                return true || company.permissions.includes('owner') || company.permissions.includes('admin') || company.permissions.includes('manager');
                break;
        }
    }
}