import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filesize'
})
export class FileSizePipe implements PipeTransform {
  transform(size: number, aftPoint: number = 2, is_smart: boolean = false) {
    if (is_smart && size >= 1073741824) {
      return Math.round(size / 1073741824) + (is_smart ? "GB" : " GB");
    } else if (size >= 1073741824) {
      return (size / 1073741824).toFixed(aftPoint) + (is_smart ? "GB" : " GB");
    } else if (size >= 1048576) {
      return Math.round((size / 1048576)) + (is_smart ? "MB" : " MB");
    } else if (size >= 10240) {
      return (size / 1048576).toFixed(aftPoint) + (is_smart ? "MB" : " MB");
    } else if (size >= 1) {
      return (size / 1024).toFixed(aftPoint) + (is_smart ? "KB" : " KB");
    } else {
      return "0" + (is_smart ? "B" : " B");
    }
  }
  // transform(size: number, extension: string = 'MB', aftPoint: number = 2) {
  //   if (size >= 1073741824) {

  //     if (aftPoint == 1 && ((size / 1073741824).toFixed(aftPoint) + " GB")[0] == '0') {
  //       return ((size / 1073741824).toFixed(aftPoint) + " GB").substr(1);
  //     } else {
  //       return (size / 1073741824).toFixed(aftPoint) + " GB";
  //     }

  //   } else if (size >= 1048576) {
  //     return Math.round((size / 1048576)) + " MB";
  //   } else if (size >= 10240) {

  //     if (aftPoint == 1 && ((size / 1048576).toFixed(aftPoint) + " MB")[0] == '0') {
  //       return ((size / 1048576).toFixed(aftPoint) + " MB").substr(1);
  //     } else {
  //       return (size / 1048576).toFixed(aftPoint) + " MB";
  //     }

  //   } else if (size >= 1) {

  //     if (aftPoint == 1 && ((size / 1024).toFixed(aftPoint) + " KB")[0] == '0') {
  //       return ((size / 1024).toFixed(aftPoint) + " KB").substr(1);
  //     } else {
  //       return (size / 1024).toFixed(aftPoint) + " KB";
  //     }
      
  //   } else {
  //     return "0 B";
  //   }

  //   // export NODE_OPTIONS=--max_old_space_size=4096
  // }
}