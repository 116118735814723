import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, fromEvent, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { casesModel } from 'src/app/shared/functions/casesModel';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { AuthService } from 'src/app/shared/services/rest/auth.service';
import { ChatService } from 'src/app/shared/services/rest/chat.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { environment } from 'src/environments/environment';
import { OperationChatComponent } from '../../atTasksDialog/operation-chat/operation-chat.component';
import { ChatsComponent } from '../../chats/chats.component';

@Component({
  selector: 'app-chat-link',
  templateUrl: './chat-link.component.html',
  styleUrls: ['./chat-link.component.scss']
})
export class ChatLinkComponent extends BaseClass implements OnInit, OnDestroy {

  public activeLang: any;
  public user: any;
  public m_id: any;
  public host: any = environment.host;
  public imgRoute: any = '';
  public link: any;
  public is_mobile: boolean = false;
  public is_load: boolean = false;
  public linkStr: any;
  constructor(
    private auth: AuthService,
    private router: Router,
    private sm: StorageManagerService,
    private taskService: TaskService,
    private languageService: LanguageService,
    private dialog: MatDialog,
    private companyService: CompanyService,
    private chatService: ChatService,
    private activatedRoute: ActivatedRoute
  ) { super() }

  ngOnInit(): void {
    this.linkStr = window.location.href;
    this.m_id = this.activatedRoute.snapshot.queryParamMap.get('post')
    this.getUser();
    this.getLangData();
    this.getImgRoute();
    this.checkIsMobile();
    this.getInitMeta();
    console.log("this.router.url.split('/')", this.router.url.split('/')[2].split('?post')[0])
  }

  
  checkIsMobile() {
    if (window.innerWidth <= 769) {
      this.is_mobile = true;
    } else {
      this.is_mobile = false;
    }
    this.onResize();
  }

  getUser() {
    this.attachSubscriptions(
      this.auth.$user.subscribe(resp => {
        console.log("USER", resp)
        this.user = resp;
      })
    )
  }

  onResize() {
    this.attachSubscriptions(
      fromEvent(window, "resize").pipe(
        map(() => window.innerWidth)
      ).subscribe((wWidth) => {
          if (wWidth <= 769) {
            this.is_mobile = true;
          } else {
            this.is_mobile = false;
          }
        }
      )
    )
  }

  toMainPage() {
    this.router.navigate(['/main'])
  }

  openInCompany(company) {
    this.openChat(this.link.meta.id, company, this.m_id)
  }

  getInitMeta() {
    this.is_load = true;
    this.attachSubscriptions(
      this.auth.getInitMetaDev('chat', this.router.url.split('/')[2].split('?post')[0]).pipe(
        switchMap((res:any) => {
          if (res.meta && res.meta.id && res.meta.acm) {
            return this.companyService.getCompanyesForLink(false, false, res.meta.id, false, res.meta.acm).pipe(
              tap(el => {
                res.companyes = el
              }),
              catchError(error => {
                console.log(error, res)
                res.companyes = []
                return of(res)
              }),
              map(() => res)
            )
          } else {
            res.companyes = []
            return of(res)
          }
        })
      ).subscribe(resp => {
        this.link = resp
        console.log("getInitData", resp)
        if (resp.companyes && resp.companyes.length) {
          if (resp.companyes.length == 1) {
            this.openChat(resp.meta.id, resp.companyes[0], this.m_id)
            setTimeout(() => {
              this.is_load = false
            }, 1000)
          } else {
            this.is_load = false
          }
        } else {
          this.is_load = false
        }
      })
    )
  }

  onPartnerImgError(event){
    event.target.src = this.imgRoute+'/assets/img/partner.png'
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  neededTaskData(task, company) {
    let arr = [
      this.chatService.getTasksChats(company.id, [task.id]).pipe(
        tap(res => {
          task['operations'].map(o_el => {
            o_el['chatsInfo'] = [];
            o_el['chatsInfo'].push(...res.filter(z => z.task_operation_id == o_el.id));
            o_el['unReadChats'] = res.filter(z => z.task_operation_id == o_el.id && z.is_read != '1').length;
            o_el['unClosedChats'] = res.filter(z => z.task_operation_id == o_el.id && z.status_id != '4').length;
          });
          task['chatsInfo'] = [];
          task['chatsInfo'].push(...res.filter(z => z.task_id == task.id));
          task['unReadChats'] = res.filter(z => z.is_read != '1').length;
          task['unClosedChats'] = res.filter(z => z.status_id != '4').length;
        })
      )
    ]
    return forkJoin(arr)
  }

  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData().subscribe(resp => {
        this.activeLang = resp.active;
      })
    )
  }

  openChat(chat_id, company, msg_id) {
    this.attachSubscriptions(
      this.chatService.getChatsExpand(1, company.id, {id: chat_id}).pipe(
        map(res => res.body[0]),
        switchMap(chat => {
          return this.taskService.getOneTaskExpand(company.id, chat.task_id).pipe(
            map(x => x.body[0]),
            switchMap(x => this.neededTaskData(x, company).pipe(map(() => casesModel([x], [], 'update')),
            switchMap(x => this.taskService.getOperations(company.id, this.activeLang).pipe(tap(u => x.arr[0].operationsValues = u), map(() => x))),
            map((x:any) => { return {task: x.arr[0], 'chat': chat}}))),
          )
        })
      ).subscribe((resp:any) => {
        console.log(resp);

        let data:any = {
          company: company,
          task: resp.task,
          dialogType: 'operation_chat',
          operationsValues: resp.task.operationsValues,
          work: resp.task.operations.find(el => el.id == resp.chat.task_operation_id),
          user: this.user,
          empl_status: company.permissions.includes('owner') || company?.permissions.includes('admin') || company.permissions.includes('manager'),
          chat: resp.chat,
          chats: resp.task.operations.find(el => el.id == resp.chat.task_operation_id).chatsInfo
        }
        if (msg_id) {
          data.m_id = msg_id
        }
        const goToChat = this.dialog.open(OperationChatComponent, {
          backdropClass: ['backdrop_under_header', "without_pad"],
          panelClass: !this.is_mobile ? ['full_screen_dialog', 'global_chats_dialog', 'show_header'] : ['full_screen_dialog', 'global_chats_dialog'],
          position: !this.is_mobile ? { top: '58px', left: '0', right: '0', bottom: '0' } : {},
          autoFocus: this.is_mobile ? false : true,
          data: data
        });
    
        this.attachSubscriptions(
          goToChat.afterClosed().subscribe(result => {
            if (!!result && result.event == 'close') {
              this.chatService.postChatRead(chat_id, 0, company.id).subscribe(resp => {
                console.log(resp);
              })
            }
          })
        )
      })
    )
  }

  // openChat(id, company, m_id?) {
  //   let data:any = {
  //     company: company,
  //     dialogType: 'global_chat'
  //   }
  //   if (m_id) {
  //     data.m_id = m_id
  //   }

  //   this.attachSubscriptions(
  //     this.chatService.getChatsExpand(1, company.id, {id: id}).pipe(
  //       map(res => res.body[0])
  //     ).subscribe(chat => {
  //       data.chat = chat
  //       const goToChat = this.dialog.open(ChatsComponent, {
  //         backdropClass: ['backdrop_under_header', "without_pad"],
  //         panelClass: !this.is_mobile ? ['full_screen_dialog', 'global_chats_dialog', 'show_header'] : ['full_screen_dialog', 'global_chats_dialog'],
  //         position: !this.is_mobile ? { top: '58px', left: '0', right: '0', bottom: '0' } : {},
  //         data: data
  //       });

  //       goToChat.afterClosed().subscribe(res => {
  //         this.toMainPage()
  //       })
  //     })
  //   )
  // }

  goToDashboard(company_id) {
    this.router.navigate(['/dashboard'], { queryParams: {company_id: company_id} });
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
