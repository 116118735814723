<div class="bookmarks_settings">
    <div class="container">
        <div class="filter_out">  
            <form [formGroup]="filter" class="filter_in" (ngSubmit)="filterBookmarks()" style="min-height: 38px;">
                <div class="filter_form_in">
                    <mat-form-field appearance="legacy" class="filter_form group" *ngIf="employees" style="border: none;" #tooltip="matTooltip" [matTooltipPosition]="'above'" matTooltip="{{ 'Show presets available to user' | translate }}">
                        <mat-label>{{ "Member" | translate }}</mat-label>
                        <mat-select [disableOptionCentering]="true" selectDir formControlName="employee_id" (selectionChange)="selectChange($event)">
                            <mat-option>
                                <ngx-mat-select-search 
                                    placeholderLabel="{{ 'Search' | translate }}" 
                                    noEntriesFoundLabel="{{ 'no matching employees found' | translate }}"
                                    [formControl]="employeesControl"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let employee of employees$ | async" [value]="employee.id">
                                <app-user [class]="'user_div'" [employee]="employee"></app-user>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="legacy" class="filter_form group" style="border: none;" #tooltip="matTooltip" [matTooltipPosition]="'above'" matTooltip="{{ 'Show presets for section' | translate }}">
                        <mat-label>{{ "Section" | translate }}</mat-label>
                        <mat-select [disableOptionCentering]="true" selectDir formControlName="section" (selectionChange)="selectChange($event)">
                            <mat-option [value]="''">
                                {{"All" | translate}}
                            </mat-option>
                            <mat-option *ngFor="let section of sections" [value]="section.name">
                                {{section.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field appearance="legacy" class="filter_form group" style="border: none;" #tooltip="matTooltip" [matTooltipPosition]="'above'" matTooltip="{{ 'Show presets for section type' | translate }}">
                        <mat-label>{{ "Type" | translate }}</mat-label>
                        <mat-select [disableOptionCentering]="true" selectDir formControlName="type" (selectionChange)="selectChange($event)">
                            <mat-option *ngFor="let type of types" [value]="type">
                                {{type == '' ? ('All' | translate) : type }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="d-f-c">
                    <mat-button-toggle-group class="view_group" (change)="changeView($event, 'is_active')" [value]="filter.get('is_active').value">
                        <mat-button-toggle value="">{{ "All" | translate }}</mat-button-toggle>
                        <mat-button-toggle value="0"><mat-icon>visibility_off</mat-icon></mat-button-toggle>
                        <mat-button-toggle value="1"><mat-icon>visibility</mat-icon></mat-button-toggle>
                    </mat-button-toggle-group>
    
                    <mat-button-toggle-group class="view_group" (change)="changeView($event, 'is_shortcut')" [value]="filter.get('is_shortcut').value">
                        <mat-button-toggle value="">{{ "All" | translate }}</mat-button-toggle>
                        <mat-button-toggle value="0"><mat-icon>star_border</mat-icon></mat-button-toggle>
                        <mat-button-toggle value="1"><mat-icon>star</mat-icon></mat-button-toggle>
                    </mat-button-toggle-group>

                    <div class="stats_wrp" style="margin-left: 20px;">
                        <div class="tasks_counters">
                            <div class="counter_block" *ngIf="pagination && pagination['totalCount']">
                                <div class="counter_top">{{ "Presets" | translate }}</div>
                                <div class="counter_bottom" style="text-align: right;">{{pagination['totalCount']}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <div class="filter_values" *ngIf="filter.get('employee_id').value || filter.get('section').value || filter.get('type').value">
                <mat-chip-list class="chips_wrp without_ordered start_page_chips">

                    <ng-container *ngIf="filter.get('employee_id').value">
                        <span class="chip_out_label">{{ "Member" | translate }}: </span>
                        <mat-chip class="user_chip" (removed)="removeValue('employee_id')">
                            <app-user [class]="'user_div'" [employee]="getEmployee(filter.get('employee_id').value)"></app-user>
                            
                            <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
                        </mat-chip>
                    </ng-container>
                    <ng-container *ngIf="filter.get('section').value">
                        <span class="chip_out_label">{{ "Section" | translate }}: </span>
                        <mat-chip (removed)="removeValue('section')">
                            {{filter.get('section').value}}
                            <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
                        </mat-chip>
                    </ng-container>
                    <ng-container *ngIf="filter.get('type').value">
                        <span class="chip_out_label">{{ "Type" | translate }}: </span>
                        <mat-chip (removed)="removeValue('type')">
                            {{filter.get('type').value}}
                            <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
                        </mat-chip>
                    </ng-container>
                    
                    <mat-icon class="remove_all_chips_btn" (click)="removeValue('all')">cancel</mat-icon>
                </mat-chip-list>
            </div>
        </div>
    
        <div class="lists_wrp">

          <!-- <div class="job_status_wrp" [ngClass]="{'active': !filter.get('section').value}">
            <button mat-button (click)='changeActiveStatus("")' [ngClass]="{'active_status': !filter.get('section').value}">{{ "All" | translate }}</button>
          </div>

          <div class="job_status_wrp" [ngClass]="{'active': sections.includes(filter.get('section').value)}">
            <button *ngFor="let section of sections" mat-button (click)='changeActiveStatus(section)' [ngClass]="{'active_status': filter.get('section').value == section}">{{ section }}</button>
          </div> -->
              
        </div>
      
        <div class="bookmarks_list" [appDeviceClass]="'custom-scroll custom-scroll-t'" 
        infinite-scroll
        [infiniteScrollDisabled]="isLoad"
        [scrollWindow]="false"
        [infiniteScrollUpDistance]="scrollUpDistance"
        [infiniteScrollThrottle]="throttle"
        (scrolled)="onDownBook($event)">
            <div class="bookmark" *ngFor="let bookmark of bookmarks; let i = index;">

                <div class="bookmark_in">
                    <div class="name text_one_line">
                        {{bookmark.name}}
                    </div>
                    <div class="section" *ngIf="!filter.get('section').value">
                        {{bookmark.section}}
                    </div>
         
                    <ng-container *ngIf="bookmark.employee_id; else elseTemplatePrivate">
                        <div class="info_empls">
                            <div class="creator_line">
                                <div class="created">
                                    <span>{{ "Private bookmark" | translate }}</span>
    
                                    <div class="empls_for" *ngIf="bookmark.sectionToEmployees && bookmark.sectionToEmployees.length">
                                        <div class="empl_with_settings" *ngFor="let item of bookmark.sectionToEmployees; let k = index;">
                                            <app-user [class]="'user_div'" [employee]="item.employee"></app-user>
                                            <div class="settings_employee">
                                                <button mat-icon-button class="empl_book_set" (click)="$event.preventDefault(); $event.stopPropagation(); editEmployeeBookmark(item, 'is_active')">
                                                    <ng-container *ngIf="item.is_active == 1; else elseTemplateIsActive">
                                                        <mat-icon>visibility</mat-icon>
                                                    </ng-container>
                                                    <ng-template #elseTemplateIsActive>
                                                        <mat-icon>visibility_off</mat-icon>
                                                    </ng-template>
                                                </button>
                                                <button mat-icon-button class="empl_book_set" (click)="$event.preventDefault(); $event.stopPropagation(); editEmployeeBookmark(item, 'is_shortcut')">
                                                    <ng-container *ngIf="item.is_shortcut == 1; else elseTemplateIsShortcut">
                                                        <mat-icon>star</mat-icon>
                                                    </ng-container>
                                                    <ng-template #elseTemplateIsShortcut>
                                                        <mat-icon>star_border</mat-icon>
                                                    </ng-template>
                                                </button>
                                                <button mat-icon-button class="empl_book_set" (click)="$event.preventDefault(); $event.stopPropagation(); deleteEmployeeBookmark(bookmark, item, k)">
                                                    <mat-icon>bookmark_remove</mat-icon>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-template #elseTemplatePrivate>
                        <div class="info_empls">
                            <div class="creator_line">
                                <div class="created">
                                    <span>{{ "System bookmark" | translate }}</span>
                                </div>
                            </div>
        
                            <mat-icon class="arrow" *ngIf="bookmark.sectionToEmployees && bookmark.sectionToEmployees.length">trending_flat</mat-icon>
        
                            <div class="creator_line creator_line_for" >
                                <div class="created">
                                    <span *ngIf="bookmark.sectionToEmployees && bookmark.sectionToEmployees.length">{{ "for" | translate }}</span>
            
                                    <div class="empls_for">
                                        <ng-container *ngIf="bookmark.sectionToEmployees && bookmark.sectionToEmployees.length">
                                            <div class="empl_with_settings" *ngFor="let item of bookmark.sectionToEmployees; let k = index;">
                                                <app-user [class]="'user_div'" [employee]="item.employee"></app-user>
                                                <div class="settings_employee">
                                                    <button mat-icon-button class="empl_book_set" (click)="$event.preventDefault(); $event.stopPropagation(); editEmployeeBookmark(item, 'is_active')">
                                                        <ng-container *ngIf="item.is_active == 1; else elseTemplateIsActive">
                                                            <mat-icon>visibility</mat-icon>
                                                        </ng-container>
                                                        <ng-template #elseTemplateIsActive>
                                                            <mat-icon>visibility_off</mat-icon>
                                                        </ng-template>
                                                    </button>
                                                    <button mat-icon-button class="empl_book_set" (click)="$event.preventDefault(); $event.stopPropagation(); editEmployeeBookmark(item, 'is_shortcut')">
                                                        <ng-container *ngIf="item.is_shortcut == 1; else elseTemplateIsShortcut">
                                                            <mat-icon>star</mat-icon>
                                                        </ng-container>
                                                        <ng-template #elseTemplateIsShortcut>
                                                            <mat-icon>star_border</mat-icon>
                                                        </ng-template>
                                                    </button>
                                                    <button mat-icon-button class="empl_book_set" (click)="$event.preventDefault(); $event.stopPropagation(); deleteEmployeeBookmark(bookmark, item, k)">
                                                        <mat-icon>bookmark_remove</mat-icon>
                                                    </button>
                                                </div>
                                            </div>
                                        </ng-container>
                                        
                                        <div class="empl_with_settings" style="padding: 0;"  *ngIf="(company?.permissions.includes('owner') || company?.permissions.includes('admin') || company?.permissions.includes('manager'))">
                                            <div class="settings_employee" style="margin: 0;">
                                                <button mat-icon-button class="empl_book_set" (click)="$event.preventDefault(); $event.stopPropagation(); addEmployeeToBookmark(bookmark, i)">
                                                    <mat-icon>add</mat-icon>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    
    
                    <div class="date">
                        {{bookmark.created_at | reportsDate:activeLang}}
                    </div>
    
                </div>

                <!-- manager -->
                <ng-container *ngIf="(company?.permissions.includes('owner') || company?.permissions.includes('admin') || company?.permissions.includes('manager')); else elseTemplateManager">
                    <div class="more_info">                  
                        <button mat-icon-button (click)="editBookmark(bookmark, 'is_shared')" *ngIf="bookmark.employee_id == 0">
                            <ng-container *ngIf="bookmark.is_shared == 1; else elseTemplateIsShared">
                                <mat-icon>public</mat-icon>
                            </ng-container>
                            <ng-template #elseTemplateIsShared>
                                <mat-icon>public_off</mat-icon>
                            </ng-template>
                        </button>
                        <button mat-icon-button (click)="goToEditBookmark(bookmark, i)">
                            <mat-icon>edit</mat-icon>
                        </button>
                        
                        <button mat-icon-button (click)="deleteBookmark(bookmark, i)">
                            <mat-icon>bookmark_remove</mat-icon>
                        </button>
                    </div>
                </ng-container>
                <ng-template #elseTemplateManager>
                    <div class="more_info"> 
                        <button mat-icon-button (click)="emplEditBookmark(bookmark, 'is_active')">
                            <ng-container *ngIf="bookmark.sectionToEmployee && bookmark.sectionToEmployee.is_active == 1; else elseTemplateIsActive">
                                <mat-icon>visibility</mat-icon>
                            </ng-container>
                            <ng-template #elseTemplateIsActive>
                                <mat-icon>visibility_off</mat-icon>
                            </ng-template>
                        </button>
                        <button mat-icon-button (click)="emplEditBookmark(bookmark, 'is_shortcut')">
                            <ng-container *ngIf="bookmark.sectionToEmployee && bookmark.sectionToEmployee.is_shortcut == 1; else elseTemplateIsShortcut">
                                <mat-icon>star</mat-icon>
                            </ng-container>
                            <ng-template #elseTemplateIsShortcut>
                                <mat-icon>star_border</mat-icon>
                            </ng-template>
                        </button>

                        <ng-container *ngIf="bookmark?.employee_id == company?.employees[0]?.id">
                            <button mat-icon-button (click)="goToEditBookmark(bookmark, i)">
                                <mat-icon>edit</mat-icon>
                            </button>
                            
                            <button mat-icon-button (click)="deleteBookmark(bookmark, i)">
                                <mat-icon>bookmark_remove</mat-icon>
                            </button>
                        </ng-container>
                        
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
</div>