<div mat-dialog-title class="mobile_interface_title">
    <div class="mobile_interface_title_in" style="overflow: hidden;">
        <div class="tab_title" (click)="close()">
            <div class="d-f-c">
                <div class="icon_back">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7 12L6.11612 11.1161C5.62796 11.6043 5.62796 12.3957 6.11612 12.8839L7 12ZM16.8839 3.88388C17.372 3.39573 17.372 2.60427 16.8839 2.11612C16.3957 1.62796 15.6043 1.62796 15.1161 2.11612L16.8839 3.88388ZM15.1161 21.8839C15.6043 22.372 16.3957 22.372 16.8839 21.8839C17.372 21.3957 17.372 20.6043 16.8839 20.1161L15.1161 21.8839ZM7.88388 12.8839L16.8839 3.88388L15.1161 2.11612L6.11612 11.1161L7.88388 12.8839ZM6.11612 12.8839L15.1161 21.8839L16.8839 20.1161L7.88388 11.1161L6.11612 12.8839Z" fill="#232323"/>
                    </svg>
                </div>
                <span>
                    {{ "Status" | translate }}
                </span>
            </div>
        </div>
    </div>
</div>
<mat-dialog-content class="mobile_interface_content">
    <div class="mobile_scrollable_int_big" style="background: #fff;">
        <form [formGroup]="data.filter" class="exp_form" [appDeviceClass]="'custom-scroll'">
            <ng-container *ngIf="['Cards'].includes(activeSections()); else elseTemplateCard">
                <mat-selection-list [multiple]="false" formControlName="operation_status_id">
                    <mat-list-option *ngFor="let fil of data.statuses" [value]="fil.id" [ngClass]="{'active': fil.id == data.filter.get('operation_status_id').value}">
                        <div class="d-f-c">
                            <span>
                                {{fil?.name}}
                            </span>
                        </div>
                    </mat-list-option>
                </mat-selection-list>
            </ng-container>
            <ng-template #elseTemplateCard>
                <ng-container *ngIf="['Jobs', 'Chats'].includes(activeSections()); else elseTemplateJob">
                    <mat-selection-list [multiple]="false" formControlName="status_id">
                        <mat-list-option *ngFor="let fil of data.statuses" [value]="fil.id" [ngClass]="{'active': fil.id == data.filter.get('status_id').value}">
                            <div class="d-f-c">
                                <span>
                                    {{fil?.name}}
                                </span>
                            </div>
                        </mat-list-option>
                    </mat-selection-list>
                </ng-container>
                <ng-template #elseTemplateJob>
                    <ng-container *ngIf="tab == 0; else elseTemplateToDo">
                        <mat-selection-list color="primary" [multiple]="true" formControlName="status_id">
                            <mat-list-option [value]="1" [ngClass]="{'active': data.filter.get('status_id').value.includes(1)}">
                                <div class="d-f-c">
                                    <span>
                                        {{ "Waiting" | translate }}
                                    </span>
                                </div>
                            </mat-list-option>
                            <mat-list-option [value]="2" [ngClass]="{'active': data.filter.get('status_id').value.includes(2)}">
                                <div class="d-f-c">
                                    <span>
                                        {{ "In process" | translate }}
                                    </span>
                                </div>
                            </mat-list-option>
                            <mat-list-option [value]="5" [ngClass]="{'active': data.filter.get('status_id').value.includes(5)}">
                                <div class="d-f-c">
                                    <span>
                                        {{ "To correct" | translate }}
                                    </span>
                                </div>
                            </mat-list-option>
                        </mat-selection-list>
                    </ng-container>
                    <ng-template #elseTemplateToDo>
                        <mat-selection-list color="primary" [multiple]="true" formControlName="doneStatus">
                            <mat-list-option [value]="3" [ngClass]="{'active': data.filter.get('doneStatus').value.includes(3)}">
                                <div class="d-f-c">
                                    <span>
                                        {{ "To approve" | translate }}
                                    </span>
                                </div>
                            </mat-list-option>
                            <mat-list-option [value]="4" [ngClass]="{'active': data.filter.get('doneStatus').value.includes(4)}">
                                <div class="d-f-c">
                                    <span>
                                        {{ "Approved" | translate }}
                                    </span>
                                </div>
                            </mat-list-option>
                            <mat-list-option [value]="6" [ngClass]="{'active': data.filter.get('doneStatus').value.includes(6)}">
                                <div class="d-f-c">
                                    <span>
                                        {{ "Sources needed" | translate }}
                                    </span>
                                </div>
                            </mat-list-option>
                            <mat-list-option [value]="97" [ngClass]="{'active': data.filter.get('doneStatus').value.includes(97)}">
                                <div class="d-f-c">
                                    <span>
                                        {{ "On hold" | translate }}
                                    </span>
                                </div>
                            </mat-list-option>
                            <mat-list-option [value]="98" [ngClass]="{'active': data.filter.get('doneStatus').value.includes(98)}">
                                <div class="d-f-c">
                                    <span>
                                        {{ "Ready" | translate }}
                                    </span>
                                </div>
                            </mat-list-option>
                            <mat-list-option [value]="99" [ngClass]="{'active': data.filter.get('doneStatus').value.includes(99)}">
                                <div class="Canceled">
                                    <span>
                                        {{ "To correct" | translate }}
                                    </span>
                                </div>
                            </mat-list-option>
                        </mat-selection-list>
                    </ng-template>
                    
                    
                </ng-template>
            </ng-template>
        </form>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <div class="mobile_interface_buttons small_mob_btns" style="justify-content: space-between !important;">
        <button class="mobile_interface_btn" (click)="close()">
            {{ "Cancel" | translate }}
        </button>
        
        <div class="d-f-c">
            <button class="btn_w8 mr-1" style="height: 35px;" type="button" (click)="togglePersonal()" *ngIf="activeSections() == 'Chats' && !!data.filter.get('status_id').value && !!data.filter.get('discussion_other_employee_status_id')">
                <mat-icon *ngIf="!data.filter.get('discussion_other_employee_status_id').value">check_box_outline_blank</mat-icon>
                <mat-icon *ngIf="!!data.filter.get('discussion_other_employee_status_id').value">check_box</mat-icon>
                {{"For personal" | translate}}
            </button>
            
            <button class="mobile_interface_btn mobile_interface_btn_blue" (click)="onSubmit()">
                {{ "Apply" | translate }}
            </button>
        </div>
    </div>
</mat-dialog-actions>