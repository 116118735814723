<div class="container centred_page">
    <form [formGroup]="form" dirEnter (dirSubmit)="!form.valid || isSubmit ? null : onSubmit()">
    
        <h2>{{ 'Setting up new Workspace' | translate }}</h2>

        <mat-form-field appearance="standard" class="full_width_form">
            <mat-label>{{ 'Workspace name' | translate }}</mat-label>
            <input formControlName="name" matInput placeholder="{{ 'Name' | translate }}">
        </mat-form-field>
        

        <div class="result" *ngIf="promo && promo != 'Error'">
            <div class="promo_title">
                <span>{{"Promo code applied" | translate}}:</span> <b>{{promo?.code}}</b>
            </div>
            <div class="promo_title">{{"Promo code benefits:" | translate}}</div>
            <div class="text_promo" [appDeviceClass]="'custom-scroll'">{{promo?.description}}</div>
        </div>

        <div class="btns_line">
            <button mat-raised-button type="button" (click)="goBack()">
                {{ "Back" | translate }}
            </button>
            <button class="ml-1 d-f-btn" (click)="onSubmit()" type="button" mat-raised-button color="primary" [disabled]="!form.valid || isSubmit">
                <mat-spinner class="spin_16 mr-1" *ngIf="isSubmit"></mat-spinner>  
                {{ "Full custom (pro)" | translate }}
            </button>
            <button class="ml-1 d-f-btn" (click)="onSubmit(true)" type="button" mat-raised-button color="warn" [disabled]="!form.valid || isSubmit">
                <mat-spinner class="spin_16 mr-1" *ngIf="isSubmit"></mat-spinner>  
                {{ "Livestream launch" | translate }}
            </button>
        </div>
    </form>

    <!-- <mat-step [stepControl]="promoForm" state="invite_members">
        <ng-template matStepLabel>{{ "Promo" | translate }}</ng-template>
          <div class="empl_wrp step_inside">
            <form [formGroup]="promoForm">
              <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ 'Promo' | translate }}</mat-label>
                <input formControlName="code" matInput placeholder="{{ 'Promo' | translate }}">
                <mat-spinner matSuffix class="spin_24 mr-1" *ngIf="isSubmitPromo"></mat-spinner>
              </mat-form-field>
            </form>
          </div>
          <div class="d-f-c btns_footer" style="justify-content: space-between;">
            <button mat-button (click)="goBack()">{{ "Back" | translate }}</button>
            <div class="d-f-c">
              <button class="mr-1" mat-raised-button (click)="selectedIndex = selectedIndex + 1">{{ "Skip" | translate }}</button>
              <button class="d-f-btn" mat-raised-button (click)="promoNext()" color="primary" [disabled]="isSubmitPromo">
                <mat-spinner class="spin_16 mr-1" *ngIf="isSubmitPromo"></mat-spinner>
                {{ "Next" | translate }}
              </button>
            </div>
          </div>
      </mat-step> -->
</div>