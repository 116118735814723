<div mat-dialog-title class="dialog_title" style="justify-content: space-between !important;">
    <span (click)="log()">
        {{ "Custom filter preset" | translate }}
    </span>
    <button class="ml-1" mat-icon-button (click)="close()">
        <mat-icon style="color: #fff;">close</mat-icon>
    </button>
</div>
<mat-dialog-content class="mat-typography">
    <div class="container form_wrapper bookmarks">
        <form [formGroup]="form" class="form_with_pads form_with_valids">
            <div class="white_block">
                <div class="white_block_title">
                    {{ "Preset details" | translate }}
                </div>

                <mat-form-field appearance="standard" class="full_width_form">
                    <ng-container *ngIf="!form.get('name').valid && submited">
                        <div class="req_field"></div>
                        <div class="req_label">
                            <div class="angle"></div>
                            <span>
                                {{ "You need to write here" | translate }}
                            </span>
                        </div>
                    </ng-container>
                    <mat-label>{{ 'Preset title' | translate }}</mat-label>
                    <input formControlName="name" matInput placeholder="{{ 'Name' | translate }}">
                </mat-form-field>
        
                <mat-form-field appearance="standard" class="full_width_form">
                    <mat-label>{{ 'Order' | translate }}</mat-label>
                    <input formControlName="order" matInput required type="number" placeholder="{{ 'Order' | translate }}">
                </mat-form-field>

            </div>


        </form>
        <!-- <div class="form_with_pads form_with_valids">
            <div class="white_block" style="margin-top: 0 !important;">
                <div class="white_block_title">
                    {{ "Scheme" | translate }}
                </div>
                <div class="scheme_in">
                    <pre>
                        {{devScheme | json}}
                    </pre>                    
                </div>
            </div>
        </div> -->

        <div class="white_block white_expanded" *ngIf="devScheme">
            <div class="d-f-c" (click)="openFilters()">
                <div class="white_block_title">
                    {{ "Preset filter values" | translate }}
                </div>

                <button mat-icon-button>
                    <mat-icon *ngIf="filtersOpen">expand_less</mat-icon>
                    <mat-icon *ngIf="!filtersOpen">expand_more</mat-icon>
                </button>
            </div>
            <div class="white_inside" [ngClass]="{'open': filtersOpen}">
                <ng-container *ngFor="let sch of devScheme; let lastSch = last">
                    <mat-list role="list" class="active_filter">
                        <mat-list-item role="listitem" class="active_filter_item" *ngFor="let key of getKeys(sch)">
                            <ng-container *ngIf="key == 'task_parameter_value_id'; else elseTemplateNoMulti">
                                <div class="d-f-c">
                                    <div class="key text_one_line">{{ key | translate }}:</div>
                                    <div class="val">
                                        <ng-container *ngIf="sch[key]">
                                            <ng-container *ngFor="let col of sch[key]; let last = last">
                                                <span>{{getValById(col, key)}}</span>
                                                <ng-container *ngIf="!last">, </ng-container>
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-template #elseTemplateNoMulti>
                                <div class="d-f-c">
                                    <div class="key text_one_line">{{ key | translate }}:</div>
                                    <div class="val">
                                        {{getValById(sch[key], key)}}
                                    </div>
                                </div>
                            </ng-template>
                        </mat-list-item>
                    </mat-list>
                    <ng-container *ngIf="!lastSch">
                        <mat-divider style="margin: 10px 0;"></mat-divider>
                    </ng-container>
                </ng-container>
            </div>
        </div>

        <ng-container *ngIf="!data.scheme">
            <form [formGroup]="form" class="form_with_pads form_with_valids" >
                <div class="white_block">
                    <div class="white_block_title">
                        {{ "Save for" | translate }}
                    </div>
    
                    <mat-tab-group [selectedIndex]="activeIndex" (selectedIndexChange)="changeTab($event)">
    
                        <mat-tab>
                            <ng-template mat-tab-label>
                                <mat-icon class="example-tab-icon" *ngIf="activeIndex != 0">radio_button_unchecked</mat-icon>
                                <mat-icon class="example-tab-icon"  *ngIf="activeIndex == 0">radio_button_checked</mat-icon>
                                {{ "Private" | translate }}
                            </ng-template>
    
                            <p>{{ "This bookmark is just for me" | translate }}</p>
                            <div>
                                <mat-checkbox [formControl]="is_active">{{ "Is Active" | translate }}</mat-checkbox>
                            </div>
    
                        </mat-tab>
                        <mat-tab *ngIf="data?.company?.permissions.includes('owner') || data?.company?.permissions.includes('admin') || data?.company?.permissions.includes('manager')">
                            <ng-template mat-tab-label>
                                <mat-icon class="example-tab-icon" *ngIf="activeIndex != 1">radio_button_unchecked</mat-icon>
                                <mat-icon class="example-tab-icon"  *ngIf="activeIndex == 1">radio_button_checked</mat-icon>
                                {{ "Shared with" | translate }}
                            </ng-template>
    
                            <ng-container *ngIf="activeIndex == 1">
                                <div class="users_select" [ngClass]="{'req_value': !form.get('employee_id').valid}">
                                    <label>{{ "Choose an employee" | translate }}: </label>
                                    <mat-chip-list *ngIf="!switched" [multiple]="true" selectable formControlName="employee_id">
                                        <ng-container *ngFor="let employee of employees; let i = index">
                                            <mat-chip class="chip_user" (click)="toggleChip(employee)" [value]="employee.id">
                                                <app-user [class]="'user_div'" [employee]="employee" #tooltip="matTooltip"
                                                [matTooltipPosition]="'above'"
                                                matTooltip="{{ ( form.get('employee_id').value.includes(employee.id) ? (employee.is_active == 1 ? 'Assigned and visible to user' : 'Assigned to user but hidden. User will not see it.') : 'Not assigned to user') | translate }}"></app-user>
                                                <div class="settings_employee" *ngIf="form.get('employee_id').value.includes(employee.id)">
                                                    <button mat-icon-button class="empl_book_set" (click)="$event.preventDefault(); $event.stopPropagation(); editEmployeeBookmark(employee, 'is_active')"
                                                    #tooltip="matTooltip"
                                                    [matTooltipPosition]="'above'"
                                                    matTooltip="{{ ( employee.is_active == 1 ? 'Visible to user' : 'Hidden from user') | translate }}">
                                                        <ng-container *ngIf="employee.is_active == 1; else elseTemplateIsActive">
                                                            <mat-icon>visibility</mat-icon>
                                                        </ng-container>
                                                        <ng-template #elseTemplateIsActive>
                                                            <mat-icon>visibility_off</mat-icon>
                                                        </ng-template>
                                                    </button>
                                                </div>
                                            </mat-chip>
                                            <mat-chip *ngIf="i == employees.length - 1 && i > 6" class="chip_user more" (click)="employeeIdContainer.open()">
                                                {{ "more" | translate }}
                                            </mat-chip>
                                        </ng-container>
                                    </mat-chip-list>
                                </div>
                    
                                <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                                    <mat-select [multiple]="true" #employeeIdContainer formControlName="employee_id" (selectionChange)="toggleChip($event)">
                                        <mat-option>
                                            <ngx-mat-select-search 
                                            placeholderLabel="{{ 'Search' | translate }}" 
                                            noEntriesFoundLabel="{{ 'no matching executor found' | translate }}"
                                            [formControl]="employeeMoreControl"></ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option *ngFor="let employee of employees$ | async" [value]="employee.id">
                                            <app-user [class]="'user_div'" [employee]="employee"></app-user>
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </ng-container>
                        </mat-tab>
    
                        <mat-tab *ngIf="data?.company?.permissions.includes('owner') || data?.company?.permissions.includes('admin') || data?.company?.permissions.includes('manager')">
                            <ng-template mat-tab-label>
                                <mat-icon class="example-tab-icon" *ngIf="activeIndex != 2">radio_button_unchecked</mat-icon>
                                <mat-icon class="example-tab-icon"  *ngIf="activeIndex == 2">radio_button_checked</mat-icon>
                                {{ "With entire workspace" | translate }}
                            </ng-template>
    
                            <p>{{ "This preset can be seen and used by all workspace members" | translate }}</p>
                        </mat-tab>
    
                        <mat-tab *ngIf="data?.user?.is_root">
                            <ng-template mat-tab-label>
                                <mat-icon class="example-tab-icon" *ngIf="activeIndex != 3">radio_button_unchecked</mat-icon>
                                <mat-icon class="example-tab-icon"  *ngIf="activeIndex == 3">radio_button_checked</mat-icon>
                                {{ "With all on platform" | translate }}
                            </ng-template>
    
                            <p>{{ "Available for all users on Reports.re" | translate }}</p>
                        </mat-tab>
    
                    </mat-tab-group>
                </div>
            </form>
        </ng-container>
        
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <div class="f_w_p_btns">
        <button mat-raised-button (click)="close()">
            {{ "Close" | translate }}
        </button>
        <button class="ml-1" mat-raised-button color="primary" (click)="!data.scheme ? save() : edit()">
            {{ (!data.scheme ? "Save" : "Edit") | translate }}
        </button>
    </div>
</mat-dialog-actions>