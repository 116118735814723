import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { concat, forkJoin, of } from 'rxjs';
import { catchError, concatMap, last, map, switchMap, tap } from 'rxjs/operators';
import { BaseClass } from 'src/app/shared/models/base-class';
import { FileService } from 'src/app/shared/services/rest/file.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { GetFilesSettingsComponent } from '../get-files-settings/get-files-settings.component';
import { LoadingService } from 'src/app/shared/services/rest/loading.service';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { MatBottomSheet } from '@angular/material/bottom-sheet';

import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as moment from 'moment';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { GlobalDataService } from 'src/app/shared/services/common/global-data.service';
import { FormControl } from '@angular/forms';
import { transformToWatchUrl } from 'src/app/shared/functions/liveUrlTransform';
export const MY_FORMATS = {
  parse: {
    dateInput: 'LLLLL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
export const MY_NEW_FORMATS = {
  parse: {
    dateInput: 'MM/DD/YYYY',
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'MM/DD/YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@Component({
  selector: 'app-lives-settings',
  templateUrl: './lives-settings.component.html',
  styleUrls: ['./lives-settings.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_NEW_FORMATS},
  ]
})
export class LivesSettingsComponent extends BaseClass implements OnInit, OnDestroy {
  public profileStatuses: any;
  public lives: any = this.data.lives;
  public isRealGettingFiles: boolean = false;
  public isGettingFiles: boolean = false;
  public isSubmit: boolean = false;
  public getFilesSettings:any = {
    job_id: 0,
    is_location_all: true,
    folder: "/",
  };

  public status_id: FormControl = new FormControl('');
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private taskService: TaskService,
    private ls: LoadingService,
    private globalDataService: GlobalDataService,
    private layoutService: LayoutService,
    private sm: StorageManagerService,
    private fileService: FileService,
    public dialogRef: MatDialogRef<LivesSettingsComponent>,
    private dialog: MatDialog,
  ) { super() }

  ngOnInit(): void {
    console.log("LivesSettingsComponent this.data", this.data)
    this.lives.forEach(live => {
      if (!live.publishing_params) { 
        live.publishing_params = {
          live_stream_url: "rtmp://a.rtmp.youtube.com/live2",
          live_stream_key: "",
          live_stream_to: "",
          is_loop: 1,
          is_random: 1,
          random_start_count: 0
        }
      }
    });
    this.getFilesForLives();
    this.getProfilesStatus();

    this.attachSubscriptions(
      this.status_id.valueChanges.subscribe(res => {
        // this.lives.map(x => x.content_status_id = res)
        this.lives.forEach(live => {
          if (res == 6 && !live.external_content_id) {
            live.content_status_id = 2;
            } else {
            live.content_status_id = res;
          }
        })
      })
    )

  }

  getProfilesStatus() {
    this.attachSubscriptions(
      this.globalDataService.getContentStatus().subscribe(resp => {
        this.profileStatuses = resp.slice();
        console.log("profileStatuses", this.profileStatuses)
      })
    )
  }

  getProfileStatusById(id) {
    if (this.profileStatuses) {
      return this.profileStatuses.find(x => x.id == id)
    } else {
      return undefined
    }
  }

  log() {
    console.log("LivesSettingsComponent this.data", this.data)
    console.log("LivesSettingsComponent this.lives", this.lives)
  }

  openFilesSettings() {
    const dialogRef = this.dialog.open(GetFilesSettingsComponent, {
      data: {
        getFilesSettings: this.getFilesSettings,
        task: this.data.task,
        company: this.data.company
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log("result", result)
        console.log("this.getFilesSettings", this.getFilesSettings)
        if (result) {
          if (result.event == 'find') {
            this.getFilesBySettings()
          }
        }
      })
    )
  }

  
  getFilesBySettings() {
    this.isGettingFiles = true;
    this.attachSubscriptions(
      (!this.getFilesSettings.job_id ? this.fileService.getFileDataWithTags('1', this.data.company.id, {is_dir: 0, exclude_root_dirs: 'trash', task_id: this.data.task.id, location_all: '/'}, '1') : this.fileService.getFileDataWithTags('1', this.data.company.id, {is_dir: 0, exclude_root_dirs: 'trash', task_id: this.data.task.id, task_operation_id: this.getFilesSettings.job_id, [this.getFilesSettings.is_location_all ? 'location_all' : 'location']: this.getFilesSettings.folder}, '1')).pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 100)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }
          if (pages == 0) {
            return of([]);
          } else {
            return forkJoin(arr.map(x => (!this.getFilesSettings.job_id ? this.fileService.getFileDataWithTags(x, this.data.company.id, {is_dir: 0, exclude_root_dirs: 'trash', task_id: this.data.task.id, location_all: '/'}, '100') : this.fileService.getFileDataWithTags(x, this.data.company.id, {is_dir: 0, exclude_root_dirs: 'trash', task_id: this.data.task.id, task_operation_id: this.getFilesSettings.job_id, [this.getFilesSettings.is_location_all ? 'location_all' : 'location']: this.getFilesSettings.folder}, '100')).pipe(map(u => u.body)))).pipe(
              last(),
              map(values => {
                return [].concat(...values);
              })
            )
          }
        }),
      ).subscribe(resp => {
        console.log("getFilesBySettings", resp);
        console.log("getFilesBySettings videos", resp.filter(x => this.understandFileType(x.content_type) == 'video'));
        if (resp.length && resp.filter(x => this.understandFileType(x.content_type) == 'video').length) {
          this.lives.filter(x => !!x.checked).forEach(live => {
            let live_tag_ids = live.channel && live.channel.parameterValuesToChannel ? live.channel.parameterValuesToChannel.map(u => u.parameter_value_id) : [];
            if (live_tag_ids.length == 0) {
              return
            }
  
            resp.filter(x => this.understandFileType(x.content_type) == 'video').forEach(file => {
              if (file.parameterValuesToTask && file.parameterValuesToTask.length) {
                let file_tag_ids = file.parameterValuesToTask.map(u => u.parameter_value_id);
                if (file_tag_ids.some(item => live_tag_ids.includes(item))) {
                  if (live.files.length == 0 || live.files.filter(x => x.file.id == file.id).length == 0) {
                    live.files.push({
                      company_id: file.company_id,
                      task_channel_id: live.id,
                      file_id: file.id,
                      is_to_upload: 1,
                      is_live_stream_content: 1,
                      file: file,
                      is_new: true
                    })
                  }
                }
              }
            })
          })
        }
        
        this.isGettingFiles = false;
      })
    )
  }
  
  deleteEl(live,chFile, i) {
    if (chFile.is_new) {
      live.files.splice(i,1)
    } else {
      chFile.deleted = true;
    }
  }

  understandFileType(val) {
    if (!val) {
      return ""
    }
    
    if (val == 'application/pdf') {
      return 'pdf'
    } else if (val.indexOf("/") != -1) {
      return val.split('/')[0]
    } else {
      return ""
    }
  }

  toggleCol() {
    if (this.isAllChecked()) {
      this.lives.map(x => x.checked = false)
    } else {
      this.lives.map(x => x.checked = true)
    }
  }

  isAllChecked() {
    return this.lives.filter(x => !!x.checked).length == this.lives.length
  }

  hasChecked() {
    return !!this.lives.filter(x => !!x.checked).length
  }

  getFilesForLives() {
    this.isRealGettingFiles = true;
    this.attachSubscriptions(
      forkJoin([...this.lives.map(live => this.taskService.getTaskChannelFileWithTags(this.data.company.id, {task_channel_id: live.id}).pipe(
        tap(chFiles => {
          live.files = chFiles
        })
      ))]).subscribe(resp => {
        this.isRealGettingFiles = false;
        console.log("getFilesForLives", this.lives)
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false});
  }

  inputContentUrl(live, e) {
   // stepper: MatStepper
    if (live.isParseWork) {
      this.layoutService.showSnackBar({name: ""}, marker("Data is being received. Wait a moment, please."), SnackBarItem)
      return false;
    }

    let transformedUrl = transformToWatchUrl(e.target.value);
    
    if (transformedUrl != e.target.value) {
      live.content_url = transformedUrl
    }

    live.isParseWork = true;

    this.attachSubscriptions(
      this.taskService.getSmartLinkData(live.content_url, live.channel_id, this.data.company.id, (this.data.task.company_id != this.data.company.id ? this.data.task.company_id : null)).subscribe(resp => {
        console.log("getLinkData ------ ############### ------", resp)
        live.linkData = resp
        live.content_name = resp.name ? resp.name : 'Post has no text';
        live.external_content_id = resp.external_content_id;
        live.content_filename = !!resp.filename ? resp.filename : '';
        live.content_image = resp.image;
        live.content_views_count = !!resp.views_count ? resp.views_count : '0';
        live.content_published_at = resp.published_at;

        // this.form.get('date').patchValue({
        //   day: resp.published_at ? moment(this.showUserDate(false, resp.published_at) * 1000).hour(0).minute(0) : '',
        //   time: resp.published_at ? (this.numberToTimeFormat(moment(this.showUserDate(false, resp.published_at) * 1000).hours()) + ":" + this.numberToTimeFormat(moment(this.showUserDate(false, resp.published_at) * 1000).minutes())) : '',
        // })

        live.isParseWork = false;
      })
    )
  }

  withoutToUpload(post) {
    if (!!post && post.content_type_id == 106 && !post.external_content_id) {
      return true
    } else {
      return false
    }
  }

  submitForm2() {
    if (this.isSubmit) {
      return
    }
    this.isSubmit = true;
    // let livesFiles = this.lives.map(x => x.files).flat(Infinity)

    let count = 0;
    this.attachSubscriptions(
      concat(...this.lives.map(live => {
        let sbmtData:any = {
          task_id: live.task_id,
          channel_id: live.channel_id,
          content_url: live.content_url,
          content_name: live.content_name,
          is_content_views_count_update : 1,
          is_bravourl_content_views_count_update : 1,
          content_image: live.content_image,
          content_filename: live.content_filename,
          external_content_id: live.external_content_id,
          content_type_id: live.content_type_id,
          content_published_at: live.content_published_at,
          content_status_id: live.content_status_id,
          content_views_count: live.content_views_count,
          publishing_params: {
            live_stream_url: live.publishing_params.live_stream_url,
            live_stream_key: live.publishing_params.live_stream_key,
            live_stream_to: "",
            is_loop: live.publishing_params.is_loop,
            is_random: live.publishing_params.is_random,
            random_start_count: !!live.publishing_params.is_random ? live.publishing_params.random_start_count : 0,
          }
        }
        sbmtData.publishing_params.is_loop = !!sbmtData.publishing_params.is_loop ? 1 : 0;
        sbmtData.publishing_params.is_random = !!sbmtData.publishing_params.is_random ? 1 : 0;
        sbmtData.publishing_params.live_stream_to = (sbmtData.publishing_params.live_stream_url.length && sbmtData.publishing_params.live_stream_url[sbmtData.publishing_params.live_stream_url.length - 1] == '/' ? sbmtData.publishing_params.live_stream_url : (sbmtData.publishing_params.live_stream_url + '/')) + sbmtData.publishing_params.live_stream_key;
        // sbmtData.publishing_params.live_stream_to = sbmtData.publishing_params.live_stream_url+'/'+sbmtData.publishing_params.live_stream_key;
        // sbmtData.publishing_params.live_stream_to = sbmtData.publishing_params.live_stream_to.replaceAll('//', '/')
        return this.taskService.editProfile(live.id, sbmtData, this.data.company.id).pipe(
          catchError((error) => {
            return of(error)
          }),
          switchMap(res => {
            let newFiles = [...live.files.filter(u => !!u.is_new)];
            let deletedFiles = [...live.files.filter(u => !!u.deleted)];
            let oldFiles = [...live.files.filter(u => !u.is_new && u.is_changed)]

            let filesData = [];
            
            newFiles.forEach(fileData => {
              filesData.push({
                "path": `/api/task-channel-file/`,
                "query": {company_id: this.data.company.id},
                "method": "POST",
                "body": {
                  [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
                  company_id: fileData.company_id,
                  task_channel_id: fileData.task_channel_id,
                  file_id: fileData.file_id,
                  is_to_upload: !!fileData.is_to_upload ? 1 : 0,
                  is_live_stream_content: !!fileData.is_live_stream_content ? 1 : 0
                }
              })
            })

            oldFiles.forEach(fileData => {
              filesData.push({
                "path": `/api/task-channel-file/${fileData.id}/`,
                "query": {company_id: this.data.company.id},
                "method": "POST",
                "body": {
                  [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
                  company_id: fileData.company_id,
                  task_channel_id: fileData.task_channel_id,
                  file_id: fileData.file_id,
                  is_to_upload: !!fileData.is_to_upload ? 1 : 0,
                  is_live_stream_content: !!fileData.is_live_stream_content ? 1 : 0
                }
              })
            })

            deletedFiles.forEach(fileData => {
              filesData.push({
                "path": `/api/task-channel-file/${fileData.id}/`,
                "query": {company_id: this.data.company.id},
                "method": "DELETE"
              })
            })
            
            if (filesData.length) {
              return this.taskService.multiRequest(filesData).pipe(map(() => res))
            } else {
              return of(res)
            }
          })
        )
      }))
      .subscribe({
        next: (next) => {
          // console.log("next Fin", next);
          console.log("submitForm2 next", next)
          count++;
          this.ls.requests$.next({
            value: Math.round((100 / (this.lives.length)) * (count)),
            target: "Lives settings"
          })
        },
        complete: () => {
          console.log("submitForm2 complete")
          this.isSubmit = false;
          this.refreshAll();
        },
        error: (error) => {
          console.log("submitForm2 error", error)
        }
      })
    )
  }

  refreshAll() {
    this.attachSubscriptions(
      this.taskService.getExpandUrlsWithTags('1', this.data.company.id, {task_id: this.data.task.id, content_type_id: '106'}, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }
          return forkJoin(arr.map(x => this.taskService.getExpandUrlsWithTags(x, this.data.company.id, {task_id: this.data.task.id, content_type_id: '106'}, '200').pipe(map(u => u.body)))).pipe(
            last(),
            map(values => {
              return [].concat(...values)
            }),
          )
        }),
      ).subscribe(resp => {
        this.lives = resp;
        console.log("refreshAll", this.lives);
        this.getFilesForLives();
      })
    )
  }

  submitForm() {
    console.log('submitForm')
    if (this.isSubmit) {
      return
    }
    this.isSubmit = true;
    let livesFiles = this.lives.map(x => x.files).flat(Infinity)
    console.log("livesFiles", livesFiles);

    let count = 0;
    let newFiles = [...livesFiles.filter(u => !!u.is_new)];
    let deletedFiles = [...livesFiles.filter(u => !!u.deleted)];
    let oldFiles = [...livesFiles.filter(u => !u.is_new && u.is_changed)]
    if (newFiles.length || deletedFiles.length || oldFiles.length) {
      this.attachSubscriptions(
        concat(...newFiles.map(fileData => this.taskService.setTaskChannelFile({
         company_id: fileData.company_id,
         task_channel_id: fileData.task_channel_id,
         file_id: fileData.file_id,
         is_to_upload: !!fileData.is_to_upload ? 1 : 0,
         is_live_stream_content: !!fileData.is_live_stream_content ? 1 : 0
       }, this.data.company.id).pipe(
         catchError((error) => {
           return of(error)
         })
       )),...oldFiles.map(fileData => this.taskService.editTaskChannelFile(fileData.id, {
         company_id: fileData.company_id,
         task_channel_id: fileData.task_channel_id,
         file_id: fileData.file_id,
         is_to_upload: !!fileData.is_to_upload ? 1 : 0,
         is_live_stream_content: !!fileData.is_live_stream_content ? 1 : 0
       }, this.data.company.id).pipe(
         catchError((error) => {
           return of(error)
         })
       )),...deletedFiles.map(fileData => this.taskService.unsetTaskChannelFile(fileData.id, this.data.company.id).pipe(
         catchError((error) => {
           return of(error)
         })
       ))).subscribe({
        next: (next) => {
          // console.log("next Fin", next);
          console.log("submitForm next", next)
          count++
          this.ls.requests$.next({
            value: Math.round((100 / (newFiles.length + deletedFiles.length + oldFiles.length)) * (count)),
            target: "Lives settings"
          })
        },
        complete: () => {
          console.log("submitForm complete")
          this.isSubmit = false;
          this.getFilesForLives();
        },
        error: (error) => {
          console.log("submitForm error", error)
        }
      })
      )
    } else {
      this.isSubmit = false;
    }
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

}
