import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReplaySubject, forkJoin } from 'rxjs';
import { debounceTime, last, map, switchMap, tap } from 'rxjs/operators';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { MembersService } from 'src/app/shared/services/rest/members.service';
import { ParametersService } from 'src/app/shared/services/rest/parameters.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';

@Component({
  selector: 'app-auto-position',
  templateUrl: './auto-position.component.html',
  styleUrls: ['./auto-position.component.scss']
})
export class AutoPositionComponent extends BaseClass implements OnInit, OnDestroy {
  public form: FormGroup = this.fb.group({
    company_id: this.data.company.id,
    group_id: [[]],
    operation_id: [[], Validators.required],
    parameter_value_id: [[]],
    type: [[0]]
  });

  public partnerForm: FormGroup = this.fb.group({
    company_id: this.data.company.id,
    task_company_id: ['', Validators.required],
    group_id: '',
    operation_id: ['', Validators.required],
    parameter_value_id: '',
    type: 0
  });

  public types: any = [
    {
      id: 0,
      name: 'Executor',
      key: 'is_task_employee'
    },
    {
      id: 1,
      name: 'Assistant',
      key: 'is_task_employee_assistant'
    },
    {
      id: 2,
      name: 'Client',
      key: 'is_task_client'
    },
    {
      id: 3,
      name: 'Client Moderator',
      key: 'is_task_client_principal'
    }
  ]
  public partnerTypes: any = [
    {
      id: 0,
      name: 'Executor',
      key: 'is_task_partner_employee'
    },
    {
      id: 1,
      name: 'Assistant',
      key: 'is_task_partner_employee_assistant'
    }
  ]

  public partners: any = [];
  public partnerCompMoreControl: FormControl = new FormControl();
  public partners$: ReplaySubject<any> = new ReplaySubject<any>(1);
  
  public autoToEmployee: any;
  public autoPagination: any;
  public autoPage: number = 1;

  public partnerAutoToEmployee: any;
  public partnerAutoPagination: any;
  public partnerAutoPage: number = 1;

  public operationsValues: any;
  public partnerOperationsValues: any;
  
  public allValues: any;
  public allValuesControl: FormControl = new FormControl();
  public allValues$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public partnerAllValues: any;
  public partnerAllValuesControl: FormControl = new FormControl();
  public partnerAllValues$: ReplaySubject<any> = new ReplaySubject<any>(1);
  
  public autoToPartner: any;
  public autoPartnerPagination: any;
  public autoPartnerPage: number = 1;
  
  public groupsPagination: any;
  public groups: any[] = [];
  public groupsMoreControl: FormControl = new FormControl();
  public groups$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public partnerGroupsPagination: any;
  public partnerGroups: any[] = [];
  public partnerGroupsMoreControl: FormControl = new FormControl();
  public partnerGroups$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public activeIndex = new FormControl(0);
  public isSubmit: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<AutoPositionComponent>,
    public layoutService: LayoutService,
    public parametersService: ParametersService,
    public taskService: TaskService,
    public companyService: CompanyService,
    public membersService: MembersService,
  ) { super() }

  ngOnInit(): void {
    console.log("AutoPositionComponent", this.data)

    if (this.data.is_partner) {
      this.form.addControl('partner_company_id', this.fb.control(this.data.employee.partner_company_id))
    } else {
      this.form.addControl('employee_id', this.fb.control(this.data.employee.id))
      this.partnerForm.addControl('employee_id', this.fb.control(this.data.employee.id))
      this.getPartners();

      this.partnerForm.get('task_company_id').valueChanges.subscribe(res => {
        console.log(res);
        this.partnerAutoPage = 1;
        this.getPartnerOperations(res);
        this.getPartnerAllApiParameterValues(res);
        this.getPartnerAutoEmplPos(res);
        this.getPartnerGroups(this.data.company.id, res);
      })
    }
    this.form.updateValueAndValidity();

    this.attachSubscriptions(
      this.allValuesControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchValues(resp))
    )
    this.attachSubscriptions(
      this.partnerAllValuesControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchPartnerValues(resp))
    )

    this.attachSubscriptions(
      this.groupsMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchGroups(resp))
    )
    this.attachSubscriptions(
      this.partnerCompMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchPartners(resp))
    )

    this.attachSubscriptions(
      this.partnerGroupsMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchPartnerGroups(resp))
    )

    this.getGroups();
    
    this.getAutoEmplPos();
    this.getAllApiParameterValues();
    this.getOperations();
  }

  onSearchPartners(resp) {
    if (!this.partners) {
      return;
    }

    if (!resp) {
      this.partners$.next(this.partners.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.partners$.next(
      this.partners.filter(z => z.partnerCompany.name.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  togglePartnerChip = (chip: any) => {
    this.partnerForm.patchValue({
      task_company_id: !!chip.partner_company_id ? chip.partner_company_id : chip.value
    })
    console.log("chip", chip)
    console.log("this.partnerForm.value", this.partnerForm.value)
  }

  getPartners() {
    this.attachSubscriptions(
      this.companyService.getPartners({company_id: this.data.company.id}).subscribe(resp => {
        this.partners = resp.filter(x => x.partner_company_id != 0);
        console.log("this.partners", this.partners)
        this.partners$.next(this.partners.slice())
      })
    )
  }

  getTypeByKey(key) {
    return this.types.find(x => x.key == key)
  }

  getPartnerTypeByKey(key) {
    return this.partnerTypes.find(x => x.key == key)
  }

  changeTab(val) {
    if (val == 0) {
      this.partnerForm.patchValue({
        task_company_id: 0
      })
    }
    this.activeIndex.patchValue(val);
  }

  getOperations() {
    this.attachSubscriptions(
      this.taskService.getOperations(this.data.company.id, this.data.activeLang).subscribe(resp => {
        this.operationsValues = resp;
      })
    )
  }

  getPartnerOperations(pci) {
    this.attachSubscriptions(
      this.taskService.getPartnerOperations(this.data.company.id, pci, this.data.activeLang).subscribe(resp => {
        this.partnerOperationsValues = resp;
      })
    )
  }

  log() {
    console.log("form", this.form.value);
    console.log("partnerForm", this.partnerForm.value);
  }

  onSearchValues(resp) {
    if (!this.allValues) {
      return;
    }

    if (!resp) {
      this.allValues$.next(this.allValues.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.allValues$.next(
      this.allValues.filter(z => z.value.toLowerCase().indexOf(resp) > -1 || (z.parameter.name && z.parameter.name.toLowerCase().indexOf(resp) > -1))
    );
  }

  getValueById(id) {
    if (!this.allValues || this.allValues.length == 0) {
      return null
    }
    return this.allValues.find(x => x.id == id)
  }

  onSearchPartnerValues(resp) {
    if (!this.partnerAllValues) {
      return;
    }

    if (!resp) {
      this.partnerAllValues$.next(this.partnerAllValues.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.partnerAllValues$.next(
      this.partnerAllValues.filter(z => z.value.toLowerCase().indexOf(resp) > -1 || (z.parameter.name && z.parameter.name.toLowerCase().indexOf(resp) > -1))
    );
  }

  getPartnerValueById(id) {
    if (!this.partnerAllValues || this.partnerAllValues.length == 0) {
      return null
    }
    return this.partnerAllValues.find(x => x.id == id)
  }

  getOperationById(id) {
    if (!this.operationsValues || this.operationsValues.length == 0) {
      return null
    }
    return this.operationsValues.find(x => x.id == id)
  }

  getPartnerOperationById(id) {
    if (!this.partnerOperationsValues || this.partnerOperationsValues.length == 0) {
      return null
    }
    return this.partnerOperationsValues.find(x => x.id == id)
  }

  getGroupById(id) {
    if (!this.groups || this.groups.length == 0) {
      return null
    }
    return this.groups.find(x => x.id == id)
  }
  
  getPartnerGroupById(id) {
    if (!this.partnerGroups || this.partnerGroups.length == 0) {
      return null
    }
    return this.partnerGroups.find(x => x.id == id)
  }

  getAllApiParameterValues() {
    this.attachSubscriptions(
      this.parametersService.getAllValues('1', this.data.company.id, null, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.parametersService.getAllValues(x, this.data.company.id).pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        this.allValues = [].concat(...res);
        this.allValues$.next(this.allValues.slice())
      })
    )
  }

  getPartnerAllApiParameterValues(pci) {
    this.attachSubscriptions(
      this.parametersService.getAllValues('1', this.data.company.id, {partner_company_id: pci}, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          if (pages === 0) {
            pages = 1;
          }
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.parametersService.getAllValues(x, this.data.company.id, {partner_company_id: pci}).pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        this.partnerAllValues = [].concat(...res);
        this.partnerAllValues$.next(this.partnerAllValues.slice());
        console.log("this.partnerAllValues res", res)
        console.log("this.partnerAllValues", this.partnerAllValues)
      })
    )
  }


  getAutoEmplPos() {
    this.attachSubscriptions(
      this.membersService.getAutoEmplPosDyn(this.data.company.id, !this.data.is_partner ? {employee_id: this.data.employee.id, task_company_id: 0} : {partner_company_id: this.data.employee.partner_company_id}, '1', '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = [];
          if (pages == 0) {
            pages = 1;
          }

          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }
          
          return forkJoin(arr.map(x => this.membersService.getAutoEmplPosDyn(this.data.company.id, !this.data.is_partner ? {employee_id: this.data.employee.id, task_company_id: 0} : {partner_company_id: this.data.employee.partner_company_id}, x, '200').pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        this.autoToEmployee = [].concat(...res);
      })
    )
  }

  // getAutoEmplPos(page) {
  //   this.attachSubscriptions(
  //     this.membersService.getAutoEmplPos(this.data.company.id, !this.data.is_partner ? {employee_id: this.data.employee.id, task_company_id: 0} : {partner_company_id: this.data.employee.partner_company_id}, page).pipe(
  //       tap(el => {
  //         this.autoPagination = {
  //           'pageCount': el.headers.get('x-pagination-page-count'),
  //           'perPage': el.headers.get('x-pagination-per-page'),
  //           'totalCount': el.headers.get('x-pagination-total-count'),
  //           'currentPage': el.headers.get('x-pagination-current-page'),
  //         }
  //       }),
  //       map(x => x.body)
  //     ).subscribe(resp => {
  //       if (page == 1) {
  //         this.autoToEmployee = resp;
  //       } else {
  //         this.autoToEmployee.push(...resp);
  //       }
  //       console.log("getAutoEmplPos-" + this.autoPage, this.autoToEmployee)
  //       this.autoPage++;
  //     })
  //   )
  // }

  getPartnerAutoEmplPos(pci) {
    this.attachSubscriptions(
      this.membersService.getAutoEmplPosDyn(this.data.company.id, {employee_id: this.data.employee.id, task_company_id: pci}, '1', '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          if (pages == 0) {
            pages = 1;
          }
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.membersService.getAutoEmplPosDyn(this.data.company.id, {employee_id: this.data.employee.id, task_company_id: pci}, x, '200').pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        this.partnerAutoToEmployee = [].concat(...res);
      })
    )
  }

  // getPartnerAutoEmplPos(page, pci) {
  //   this.attachSubscriptions(
  //     this.membersService.getAutoEmplPos(this.data.company.id, {employee_id: this.data.employee.id, task_company_id: pci}, page).pipe(
  //       tap(el => {
  //         this.partnerAutoPagination = {
  //           'pageCount': el.headers.get('x-pagination-page-count'),
  //           'perPage': el.headers.get('x-pagination-per-page'),
  //           'totalCount': el.headers.get('x-pagination-total-count'),
  //           'currentPage': el.headers.get('x-pagination-current-page'),
  //         }
  //       }),
  //       map(x => x.body)
  //     ).subscribe(resp => {
  //       if (page == 1) {
  //         this.partnerAutoToEmployee = resp;
  //       } else {
  //         this.partnerAutoToEmployee.push(...resp);
  //       }
  //       console.log("getpartnerAutoEmplPos-" + this.partnerAutoPage, this.partnerAutoToEmployee)
  //       this.partnerAutoPage++;
  //     })
  //   )
  // }

  
  onSearchGroups(resp) {
    if (!this.groups) {
      return;
    }

    if (!resp) {
      this.groups$.next(this.groups.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }

    // filter the banks
    this.groups$.next(
      this.groups.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  onSearchPartnerGroups(resp) {
    if (!this.partnerGroups) {
      return;
    }

    if (!resp) {
      this.partnerGroups$.next(this.partnerGroups.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }

    // filter the banks
    this.partnerGroups$.next(
      this.partnerGroups.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  getPartnerGroups(company_id, pci) {
    this.attachSubscriptions(
      this.companyService.getInfiniteGroupsPartnerCompany(company_id, pci, '1', '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.companyService.getInfiniteGroupsPartnerCompany(company_id, pci, x, '200').pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        this.partnerGroups = [].concat(...res);
        this.partnerGroups$.next(this.partnerGroups.slice());
      })
    )
  }

  getGroups() {
    this.attachSubscriptions(
      this.companyService.getInfiniteGroupsCompany(this.data.company.id, '1', '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.companyService.getInfiniteGroupsCompany(this.data.company.id, x).pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        this.groups = [].concat(...res);
        this.groups$.next(this.groups.slice());
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false});
  }


  deleteAutoToEmployee(id, i) {
    this.attachSubscriptions(
      this.membersService.deleteAutoEmplPos(id, this.data.company.id).subscribe(resp => {
        this.autoToEmployee.splice(i, 1)
      })
    )
  }

  deletePartnerAutoToEmployee(id, i) {
    this.attachSubscriptions(
      this.membersService.deleteAutoEmplPos(id, this.data.company.id).subscribe(resp => {
        this.partnerAutoToEmployee.splice(i, 1)
      })
    )
  }

  onSubmit() {
    this.isSubmit = true;
    let sbmtData = {...this.form.value}

    let autoPosData = [];
    
    sbmtData.type.forEach(type => {
      if (sbmtData.group_id && sbmtData.group_id.length) {
        sbmtData.group_id.forEach(group => {
          sbmtData.operation_id.forEach(operation => {
            if (!sbmtData.parameter_value_id.length) {
              sbmtData.parameter_value_id = ['']
            }
            let bodyData:any = {
              company_id: this.data.company.id,
              group_id: group,
              operation_id: operation,
              [this.types[type].key]: 1
            }
            if (this.data.is_partner) {
              bodyData.partner_company_id = sbmtData.partner_company_id;
            } else {
              bodyData.employee_id = sbmtData.employee_id;
            }
            sbmtData.parameter_value_id.forEach(param => {
              autoPosData.push({
                "path": `/api/automation-employee-position/`,
                "query": {company_id: this.data.company.id},
                "method": "POST",
                "body": Object.assign({
                  parameter_value_id: param,
                }, bodyData) 
              });
            })
          })
        })
      } else {
        sbmtData.operation_id.forEach(operation => {
          if (!sbmtData.parameter_value_id.length) {
            sbmtData.parameter_value_id = ['']
          }
          let bodyData:any = {
            company_id: this.data.company.id,
            operation_id: operation,
            [this.types[type].key]: 1
          }
          if (this.data.is_partner) {
            bodyData.partner_company_id = sbmtData.partner_company_id;
          } else {
            bodyData.employee_id = sbmtData.employee_id;
          }
          sbmtData.parameter_value_id.forEach(param => {
            autoPosData.push({
              "path": `/api/automation-employee-position/`,
              "query": {company_id: this.data.company.id},
              "method": "POST",
              "body": Object.assign({
                parameter_value_id: param,
              }, bodyData) 
            });
          })
        })
      }
    });

    if (autoPosData.length) {
      this.attachSubscriptions(
        this.taskService.multiRequest(autoPosData).subscribe(resp => {
          this.isSubmit = false;
          this.autoPage = 1;
          this.getAutoEmplPos();
          this.form.patchValue({
            group_id: [],
            operation_id: [],
            parameter_value_id: [],
            type: [0]
          })
        }, error => {
          this.isSubmit = false;
          this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
        })
      )
    } else {
      this.layoutService.showSnackBar({name: ''}, "Fill in all the fields", SnackBarItem)
    }

    // delete sbmtData.type;

    // sbmtData[this.types[this.form.value.type].key] = 1;
    // this.attachSubscriptions(
    //   this.membersService.addAutoEmplPos(sbmtData, this.data.company.id).subscribe(resp => {
    //     this.isSubmit = false;
    //     this.autoPage = 1;
    //     this.getAutoEmplPos();
    //     this.form.patchValue({
    //       group_id: [],
    //       operation_id: [],
    //       parameter_value_id: [],
    //       type: [0]
    //     })
    //   }, error => {
    //     this.isSubmit = false;
    //     this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
    //   })
    // )
  }

  onSubmitPartner() {
    this.isSubmit = true;
    let sbmtData = {...this.partnerForm.value}
    delete sbmtData.type;

    sbmtData[this.partnerTypes[this.partnerForm.value.type].key] = 1;
    this.attachSubscriptions(
      this.membersService.addAutoEmplPos(sbmtData, this.data.company.id).subscribe(resp => {
        this.isSubmit = false;
        this.partnerAutoPage = 1;
        this.getPartnerAutoEmplPos(this.partnerForm.get('task_company_id').value);
        this.partnerForm.patchValue({
          group_id: '',
          operation_id: '',
          parameter_value_id: '',
          type: 0
        })
      }, error => {
        this.isSubmit = false;
        this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
      })
    )
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
