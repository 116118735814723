<h2 mat-dialog-title class="title_modal">
    <ng-container *ngIf="!!data.is_edit; else elseTemplateIsEdit">
        <span class="text_one_line">
            {{ "Document edditing" | translate }}
        </span>
    </ng-container>
    <ng-template #elseTemplateIsEdit>
        <span>
            {{ "Document adding" | translate }}
        </span>
    </ng-template>
</h2>
<mat-dialog-content class="mat-typography">
    <div class="container card_editor notes_editor" [ngClass]="{'sto_width': !!initData}">
        <form [formGroup]="form" class="form_with_pads">
            <div class="ai_line_out">
                <div class="ai_line ai_abs" *ngIf="!initData && (!no_acssess || !data.is_edit) && (!!data?.user?.is_root || (bookmarks && bookmarks.length))">
                    <mat-form-field appearance="legacy" class="mr-1">
                        <mat-label>{{ "AI model" | translate }}</mat-label>
                        <mat-select [disableOptionCentering]="true" [multiple]="false" selectDir [formControl]="ai_model">
                          <mat-option>
                            <ngx-mat-select-search 
                                placeholderLabel="{{ 'Search' | translate }}" 
                                noEntriesFoundLabel="{{ 'no matching model found' | translate }}"
                                [formControl]="aiModelControl"></ngx-mat-select-search>
                          </mat-option>
                          <ng-container *ngFor="let model of models$ | async">
                              <mat-option [value]="model.name" *ngIf="model.only_root == 0 || !!data?.user?.is_root">
                                {{model.name}}
                              </mat-option>
                          </ng-container>
                        </mat-select>
                    </mat-form-field>

                    <div class="action mr-1" *ngIf="aiAction?.value" (click)="logAi()">
                        {{"Action" | translate}}: 
                        <div class="val">
                            {{aiAction?.value?.name}}
                        </div>
                    </div>

                    <button class="mr-1" mat-icon-button [matMenuTriggerFor]="clipboardMenu" #menuTrigger="matMenuTrigger">
                        <mat-icon>assistant</mat-icon>
                    </button>
                    <mat-menu #clipboardMenu="matMenu" xPosition="after">
                        <button class="scheme_item" *ngFor="let item of bookmarks" (click)="selectAiAction(aiAction,item)" mat-menu-item>
                            <span>{{ item.name }}</span>
                            <div class="d-f-c show_scheme_hover" *ngIf="is_scheme_editing">
                                <mat-icon class="small_scheme_icon green" (click)="editScheme($event, menuTrigger, item)">edit</mat-icon>
                                <mat-icon class="small_scheme_icon red" (click)="deleteScheme($event, menuTrigger, item)">delete</mat-icon>
                            </div>
                        </button>
                        <button class="scheme_item scheme_item_create" mat-menu-item (click)="createNewAiScheme($event)" *ngIf="!!data?.user?.is_root">
                            <mat-icon>add</mat-icon>
                            <span>{{ "Create new scheme" | translate }}</span>
                            <mat-icon style="margin-left: 50px !important; margin-right: 0 !important;" *ngIf="bookmarks && bookmarks.length" [ngStyle]="{'color': is_scheme_editing ? '#3f51b5' : '#686868'}" (click)="toggleSchemeEditing($event)">settings</mat-icon>
                        </button>
                    </mat-menu>
                    
                    <mat-form-field class="mr-1" class="language_block" appearance="legacy" *ngIf="aiAction?.value && checkIfIsTranslate(aiAction?.value)">
                        <mat-label>{{ "Language" | translate }}</mat-label>
                        <mat-select [formControl]="aiLang">
                          <mat-option *ngFor="let lang of languages" [value]="lang">{{lang}}</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <button mat-icon-button (click)="selectAiRequest('name', aiAction, aiLang)" *ngIf="aiAction?.value">
                        <mat-icon style="transform: scaleX(-1);">reply</mat-icon>
                    </button>
                </div>

                <mat-form-field appearance="standard" class="full_width_form" *ngIf="!initData">
                    <mat-label>{{ 'Document Title' | translate }}</mat-label>
                    <ng-container *ngIf="!no_acssess || !data.is_edit; else elseTemplateEditTitle">
                        <input cdkFocusInitial formControlName="name" matInput placeholder="{{ 'Document Title' | translate }}">
                    </ng-container>
                    <ng-template #elseTemplateEditTitle>
                        <input [readonly]="true" cdkFocusInitial formControlName="name" matInput placeholder="{{ 'Document Title' | translate }}">
                    </ng-template>
                </mat-form-field>
            </div>

            <div class="ai_line_out">
                <div class="ai_line ai_abs" *ngIf="(!no_acssess || !data.is_edit) && (!!data?.user?.is_root || (bookmarks && bookmarks.length))">
                    <mat-form-field appearance="legacy" class="mr-1">
                        <mat-label>{{ "AI model" | translate }}</mat-label>
                        <mat-select [disableOptionCentering]="true" [multiple]="false" selectDir [formControl]="ai_model">
                          <mat-option>
                            <ngx-mat-select-search 
                                placeholderLabel="{{ 'Search' | translate }}" 
                                noEntriesFoundLabel="{{ 'no matching model found' | translate }}"
                                [formControl]="aiModelControl"></ngx-mat-select-search>
                          </mat-option>
                          <ng-container *ngFor="let model of models$ | async">
                              <mat-option [value]="model.name" *ngIf="model.only_root == 0 || !!data?.user?.is_root">
                                {{model.name}}
                              </mat-option>
                          </ng-container>
                        </mat-select>
                    </mat-form-field>

                    <div class="action mr-1" *ngIf="aiActionName?.value" (click)="logAi()">
                        {{"Action" | translate}}: 
                        <div class="val">
                            {{aiActionName?.value?.name}}
                        </div>
                    </div>

                    <button class="mr-1" mat-icon-button [matMenuTriggerFor]="clipboardMenu" #menuTrigger="matMenuTrigger">
                        <mat-icon>assistant</mat-icon>
                    </button>
                    <mat-menu #clipboardMenu="matMenu" xPosition="after">
                        <button class="scheme_item" *ngFor="let item of bookmarks" (click)="selectAiAction(aiActionName,item)" mat-menu-item>
                            <span>{{ item.name }}</span>
                            <div class="d-f-c show_scheme_hover" *ngIf="is_scheme_editing">
                                <mat-icon class="small_scheme_icon green" (click)="editScheme($event, menuTrigger, item)">edit</mat-icon>
                                <mat-icon class="small_scheme_icon red" (click)="deleteScheme($event, menuTrigger, item)">delete</mat-icon>
                            </div>
                        </button>
                        <button class="scheme_item scheme_item_create" mat-menu-item (click)="createNewAiScheme($event)" *ngIf="!!data?.user?.is_root">
                            <mat-icon>add</mat-icon>
                            <span>{{ "Create new scheme" | translate }}</span>
                            <mat-icon style="margin-left: 50px !important; margin-right: 0 !important;" *ngIf="bookmarks && bookmarks.length" [ngStyle]="{'color': is_scheme_editing ? '#3f51b5' : '#686868'}" (click)="toggleSchemeEditing($event)">settings</mat-icon>
                        </button>
                    </mat-menu>
                    
                    <mat-form-field class="mr-1" class="language_block" appearance="legacy" *ngIf="aiActionName?.value && checkIfIsTranslate(aiActionName?.value)">
                        <mat-label>{{ "Language" | translate }}</mat-label>
                        <mat-select [formControl]="aiLangName">
                          <mat-option *ngFor="let lang of languages" [value]="lang">{{lang}}</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <button mat-icon-button (click)="selectAiRequest('post_name', aiActionName, aiLangName)" *ngIf="aiActionName?.value">
                        <mat-icon style="transform: scaleX(-1);">reply</mat-icon>
                    </button>
                </div>

                <mat-form-field appearance="standard" class="full_width_form">
                    <mat-label>{{ 'Post name' | translate }}</mat-label>
                    <ng-container *ngIf="!no_acssess || !data.is_edit; else elseTemplateEditPostName">
                        <textarea 
                        cdkTextareaAutosize
                        #autosize="cdkTextareaAutosize"
                        cdkAutosizeMinRows="3"
                        cdkAutosizeMaxRows="9" formControlName="post_name" matInput placeholder="{{ 'Post name' | translate }}"></textarea>
                    </ng-container>
                    <ng-template #elseTemplateEditPostName>
                        <textarea 
                        cdkTextareaAutosize
                        [readonly]="true"
                        #autosize="cdkTextareaAutosize"
                        cdkAutosizeMinRows="3"
                        cdkAutosizeMaxRows="9" formControlName="post_name" matInput placeholder="{{ 'Post name' | translate }}"></textarea>
                    </ng-template>
                    <mat-hint class="char_counter_hint" *ngIf="form.get('post_name').value" [ngClass]="{'red': form.get('post_name').value.length > 999999999}" align="end">{{form.get('post_name').value.length}}</mat-hint>
                </mat-form-field>
            </div>

            <div class="ai_line_out">
                <div class="ai_line ai_abs" *ngIf="(!no_acssess || !data.is_edit) && (!!data?.user?.is_root || (bookmarks && bookmarks.length))">
                    <mat-form-field appearance="legacy" class="mr-1">
                        <mat-label>{{ "AI model" | translate }}</mat-label>
                        <mat-select [disableOptionCentering]="true" [multiple]="false" selectDir [formControl]="ai_model">
                          <mat-option>
                            <ngx-mat-select-search 
                                placeholderLabel="{{ 'Search' | translate }}" 
                                noEntriesFoundLabel="{{ 'no matching model found' | translate }}"
                                [formControl]="aiModelControl"></ngx-mat-select-search>
                          </mat-option>
                          <ng-container *ngFor="let model of models$ | async">
                              <mat-option [value]="model.name" *ngIf="model.only_root == 0 || !!data?.user?.is_root">
                                {{model.name}}
                              </mat-option>
                          </ng-container>
                        </mat-select>
                    </mat-form-field>

                    <div class="action mr-1" *ngIf="aiActionDesc?.value" (click)="logAi()">
                        {{"Action" | translate}}: 
                        <div class="val">
                            {{aiActionDesc?.value?.name}}
                        </div>
                    </div>

                    <button class="mr-1" mat-icon-button [matMenuTriggerFor]="clipboardMenu" #menuTrigger="matMenuTrigger">
                        <mat-icon>assistant</mat-icon>
                    </button>
                    <mat-menu #clipboardMenu="matMenu" xPosition="after">
                        <button class="scheme_item" *ngFor="let item of bookmarks" (click)="selectAiAction(aiActionDesc,item)" mat-menu-item>
                            <span>{{ item.name }}</span>
                            <div class="d-f-c show_scheme_hover" *ngIf="is_scheme_editing">
                                <mat-icon class="small_scheme_icon green" (click)="editScheme($event, menuTrigger, item)">edit</mat-icon>
                                <mat-icon class="small_scheme_icon red" (click)="deleteScheme($event, menuTrigger, item)">delete</mat-icon>
                            </div>
                        </button>
                        <button class="scheme_item scheme_item_create" mat-menu-item (click)="createNewAiScheme($event)" *ngIf="!!data?.user?.is_root">
                            <mat-icon>add</mat-icon>
                            <span>{{ "Create new scheme" | translate }}</span>
                            <mat-icon style="margin-left: 50px !important; margin-right: 0 !important;" *ngIf="bookmarks && bookmarks.length" [ngStyle]="{'color': is_scheme_editing ? '#3f51b5' : '#686868'}" (click)="toggleSchemeEditing($event)">settings</mat-icon>
                        </button>
                    </mat-menu>
                    
                    <mat-form-field class="mr-1" class="language_block" appearance="legacy" *ngIf="aiActionDesc?.value && checkIfIsTranslate(aiActionDesc?.value)">
                        <mat-label>{{ "Language" | translate }}</mat-label>
                        <mat-select [formControl]="aiLangDesc">
                          <mat-option *ngFor="let lang of languages" [value]="lang">{{lang}}</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <button mat-icon-button (click)="selectAiRequest('post_desc', aiActionDesc, aiLangDesc)" *ngIf="aiActionDesc?.value">
                        <mat-icon style="transform: scaleX(-1);">reply</mat-icon>
                    </button>
                </div>

                <mat-form-field appearance="standard" class="full_width_form">
                    <mat-label>{{ 'Post description' | translate }}</mat-label>
                    <ng-container *ngIf="!no_acssess || !data.is_edit; else elseTemplateEditDesc">
                        <textarea 
                        cdkTextareaAutosize
                        #autosize="cdkTextareaAutosize"
                        cdkAutosizeMinRows="3"
                        cdkAutosizeMaxRows="9" formControlName="post_desc" matInput placeholder="{{ 'Post description' | translate }}"></textarea>
                    </ng-container>
                    <ng-template #elseTemplateEditDesc>
                        <textarea 
                        cdkTextareaAutosize
                        [readonly]="true"
                        #autosize="cdkTextareaAutosize"
                        cdkAutosizeMinRows="3"
                        cdkAutosizeMaxRows="9" formControlName="post_desc" matInput placeholder="{{ 'Post description' | translate }}"></textarea>
                    </ng-template>
                    <mat-hint class="char_counter_hint" *ngIf="form.get('post_desc').value" [ngClass]="{'red': form.get('post_desc').value.length > 999999999}" align="end">{{form.get('post_desc').value.length}}</mat-hint>
                </mat-form-field>
            </div>
        </form>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <div class="f_w_p_btns">
        <ng-container *ngIf="initData; else elseTemplateIsOut">
            <button *ngIf="!no_acssess || !data.is_edit" class="ml-1 d-f-btn" (click)="submitFile()" mat-raised-button cdkFocusInitial color="primary" [disabled]="!form.valid || isSubmit">
                <mat-spinner class="spin_16 mr-1" *ngIf="isSubmit"></mat-spinner>  
                {{ "Save" | translate }}
            </button>
        </ng-container>
        <ng-template #elseTemplateIsOut>
            <button mat-raised-button (click)="closeBtn()">
                {{ "Close" | translate }}
            </button>
            <button class="ml-1 d-f-btn" *ngIf="!no_acssess || !data.is_edit" (click)="data.is_file ? submitFile() : submitForm()" mat-raised-button cdkFocusInitial color="primary" [disabled]="!form.valid || isSubmit">
                <mat-spinner class="spin_16 mr-1" *ngIf="isSubmit"></mat-spinner>  
                {{ "Save" | translate }}
            </button>
        </ng-template>
    </div>
</mat-dialog-actions>
