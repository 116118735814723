import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { prioritys } from 'src/app/shared/consts/prioritys';
import { BaseClass } from 'src/app/shared/models/base-class';
import { ChatService } from 'src/app/shared/services/rest/chat.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';

@Component({
  selector: 'app-change-priority',
  templateUrl: './change-priority.component.html',
  styleUrls: ['./change-priority.component.scss']
})
export class ChangePriorityComponent extends BaseClass implements OnInit, OnDestroy {

  public prioritys = prioritys
  public form: FormGroup = this.fb.group({
    priority: ''
  })

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ChangePriorityComponent>,
    private fb: FormBuilder,
    private chatService: ChatService,
    private taskService: TaskService,
  ) { super() }

  ngOnInit(): void {
    console.log("ChangePriorityComponent", this.data)

    this.form = this.fb.group({
      priority: !!this.data[this.data.target].priority ? this.data[this.data.target].priority : ""
    })
  }

  selectPrioritys = (priority: any) => {
    this.form.patchValue({
      priority: priority.id
    })
    console.log(this.form.value);
  }

  onSubmit() {
    if (this.data.target == 'chat') {
      this.attachSubscriptions(
        this.chatService.editChat(this.data.chat.id, this.form.value, this.data.company.id).subscribe(resp => {
          this.dialogRef.close({event: 'update', data: resp})
        })
      )
    } else if (this.data.target == 'work') {
      this.attachSubscriptions(
        this.taskService.editWork(this.data.work.id, this.form.value, this.data.company.id).subscribe(resp => {
          this.dialogRef.close({event: 'update', data: resp})
        })
      )
    } else {
      this.attachSubscriptions(
        this.taskService.editTask(this.data.task.id, this.form.value, this.data.company.id).subscribe(resp => {
          this.dialogRef.close({event: 'update', data: resp})
        })
      )
    }
  }

  close() {
    this.dialogRef.close()
  }


  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
