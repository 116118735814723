import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpRestService } from '../common/http-rest.service';

@Injectable({
  providedIn: 'root'
})
export class TaskBarService {

  public goToItem$: Subject<any> = new Subject();
  public barItems$: Subject<any> = new Subject();
  public needGetBarItems$: Subject<any> = new Subject();
  public removeItems$: Subject<any> = new Subject();
  constructor(
    private http: HttpRestService
  ) { }

  getNeedGetBarItemsStream() {
    return this.needGetBarItems$.asObservable();
  }
  
  getGoToItemStream() {
    return this.goToItem$.asObservable();
  }

  getBarItemsStream() {
    return this.barItems$.asObservable();
  }
  
  getRemoveItemsStream() {
    return this.removeItems$.asObservable();
  }

  getBarItems(company_id) {
    return this.http.executeGet<any>('/api/task-bar/', true, {
      params: new HttpParams().set('per-page', '200').set("company_id", company_id)
    })
  }

  getBarItem(id) {
    return this.http.executeGet<any>(`/api/task-bar/${id}/`)
  }

  addBarItem(data) {
    return this.http.executePost<any>('/api/task-bar/', data)
  }

  editBarItem(id, data) {
    return this.http.executePost<any>(`/api/task-bar/${id}/`, data)
  }

  deleteBarItem(id) {
    return this.http.executeDelete<any>(`/api/task-bar/${id}/`)
  }
}
