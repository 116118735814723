<div mat-dialog-title class="mobile_interface_title">
    <div class="mobile_interface_title_in" style="overflow: hidden;">
        <div class="tab_title" (click)="close()">
            <div class="d-f-c">
                <div class="icon_back">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7 12L6.11612 11.1161C5.62796 11.6043 5.62796 12.3957 6.11612 12.8839L7 12ZM16.8839 3.88388C17.372 3.39573 17.372 2.60427 16.8839 2.11612C16.3957 1.62796 15.6043 1.62796 15.1161 2.11612L16.8839 3.88388ZM15.1161 21.8839C15.6043 22.372 16.3957 22.372 16.8839 21.8839C17.372 21.3957 17.372 20.6043 16.8839 20.1161L15.1161 21.8839ZM7.88388 12.8839L16.8839 3.88388L15.1161 2.11612L6.11612 11.1161L7.88388 12.8839ZM6.11612 12.8839L15.1161 21.8839L16.8839 20.1161L7.88388 11.1161L6.11612 12.8839Z" fill="#232323"/>
                    </svg>
                </div>
                <span>
                    {{ "Active Filters" | translate }}
                </span>
            </div>
        </div>
    </div>
</div>
<mat-dialog-content class="mobile_interface_content">
    <div class="mobile_scrollable_int_big" style="background: #fff;">
        <!-- <pre>
            {{activeParams | json}}
        </pre> -->
        <ng-container *ngIf="getKeys()">
            <mat-list role="list" class="active_filter" *ngFor="let key of getKeys()">
                <mat-list-item role="listitem" class="active_filter_item" *ngFor="let val of getValues(activeParams[key])">
                    <div class="d-f-c">
                        <div class="key text_one_line">{{ key | translate }}:</div>
                        <div class="val text_2_line">
                            <ng-container [ngSwitch]="key">
                                <ng-container *ngSwitchCase="'scheduled_published_time_by'">
                                    {{ val + '_sch_type' | translate }}
                                </ng-container>
                                <ng-container *ngSwitchCase="'task_created_at_from'">
                                    {{val | reportsDate:data.activeLang}}
                                </ng-container>
                                <ng-container *ngSwitchCase="'task_created_at_to'">
                                    {{val | reportsDate:data.activeLang}}
                                </ng-container>
                                <ng-container *ngSwitchCase="'task_operation_created_at_from'">
                                    {{val | reportsDate:data.activeLang}}
                                </ng-container>
                                <ng-container *ngSwitchCase="'task_operation_created_at_to'">
                                    {{val | reportsDate:data.activeLang}}
                                </ng-container>
                                <ng-container *ngSwitchCase="'task_operation_completed_at_from'">
                                    {{val | reportsDate:data.activeLang}}
                                </ng-container>
                                <ng-container *ngSwitchCase="'task_operation_completed_at_to'">
                                    {{val | reportsDate:data.activeLang}}
                                </ng-container>
                                <ng-container *ngSwitchCase="'discussion_created_at_from'">
                                    {{val | reportsDate:data.activeLang}}
                                </ng-container>
                                <ng-container *ngSwitchCase="'discussion_created_at_to'">
                                    {{val | reportsDate:data.activeLang}}
                                </ng-container>
                                <ng-container *ngSwitchCase="'file_created_at_from'">
                                    {{val | reportsDate:data.activeLang}}
                                </ng-container>
                                <ng-container *ngSwitchCase="'file_created_at_to'">
                                    {{val | reportsDate:data.activeLang}}
                                </ng-container>
                                <ng-container *ngSwitchCase="'file_updated_at_from'">
                                    {{val | reportsDate:data.activeLang}}
                                </ng-container>
                                <ng-container *ngSwitchCase="'file_updated_at_to'">
                                    {{val | reportsDate:data.activeLang}}
                                </ng-container>
                                <ng-container *ngSwitchCase="'file_meta_creation_time_from'">
                                    {{val | reportsDate:data.activeLang}}
                                </ng-container>
                                <ng-container *ngSwitchCase="'file_meta_creation_time_to'">
                                    {{val | reportsDate:data.activeLang}}
                                </ng-container>
                                <ng-container *ngSwitchCase="'task_channel_published_at_from'">
                                    {{val | reportsDate:data.activeLang}}
                                </ng-container>
                                <ng-container *ngSwitchCase="'task_channel_published_at_to'">
                                    {{val | reportsDate:data.activeLang}}
                                </ng-container>
                                <ng-container *ngSwitchCase="'created_at_from'">
                                    {{val | reportsDate:data.activeLang}}
                                </ng-container>
                                <ng-container *ngSwitchCase="'created_at_to'">
                                    {{val | reportsDate:data.activeLang}}
                                </ng-container>
                                <ng-container *ngSwitchCase="'analytics_views_count_from'">
                                    {{val | reportsDate:data.activeLang}}
                                </ng-container>
                                <ng-container *ngSwitchCase="'analytics_views_count_to'">
                                    {{val | reportsDate:data.activeLang}}
                                </ng-container>
                                <ng-container *ngSwitchCase="'start_at_from'">
                                    {{val | reportsDate:data.activeLang}}
                                </ng-container>
                                <ng-container *ngSwitchCase="'start_at_to'">
                                    {{val | reportsDate:data.activeLang}}
                                </ng-container>
                                <ng-container *ngSwitchCase="'updated_at_from'">
                                    {{val | reportsDate:data.activeLang}}
                                </ng-container>
                                <ng-container *ngSwitchCase="'updated_at_to'">
                                    {{val | reportsDate:data.activeLang}}
                                </ng-container>
                                <ng-container *ngSwitchCase="'posted_at_from'">
                                    {{val | reportsDate:data.activeLang}}
                                </ng-container>
                                <ng-container *ngSwitchCase="'posted_at_to'">
                                    {{val | reportsDate:data.activeLang}}
                                </ng-container>
                                <ng-container *ngSwitchCase="'discussion_planned_completed_at_from'">
                                    {{val | reportsDate:data.activeLang}}
                                </ng-container>
                                <ng-container *ngSwitchCase="'discussion_planned_completed_at_to'">
                                    {{val | reportsDate:data.activeLang}}
                                </ng-container>
                                <ng-container *ngSwitchCase="'discussion_completed_at_from'">
                                    {{val | reportsDate:data.activeLang}}
                                </ng-container>
                                <ng-container *ngSwitchCase="'discussion_completed_at_to'">
                                    {{val | reportsDate:data.activeLang}}
                                </ng-container>
                                <ng-container *ngSwitchCase="'operation_status_id'">
                                    {{getStatusById(val)?.name}}
                                </ng-container>
                                <ng-container *ngSwitchCase="'operation_reminder_id'">
                                    {{getChecklistById(val)?.name}}
                                </ng-container>
                                <ng-container *ngSwitchCase="'status_id'">
                                    {{getStatusById(val)?.name}}
                                </ng-container>
                                <ng-container *ngSwitchCase="'doneStatus'">
                                    {{getStatusById(val)?.name}}
                                </ng-container>
                                <ng-container *ngSwitchCase="'order_by'">
                                    <div class="d-f-c" style="padding: 0; width: 100%; justify-content: flex-start;">
                                        {{getOrder(val)?.name}}
                                        <mat-icon *ngIf="getOrder(val)?.arrow" style="margin-right: 0 !important;">arrow_drop_down</mat-icon>
                                        <mat-icon *ngIf="!getOrder(val)?.arrow" style="margin-right: 0 !important;">arrow_drop_down</mat-icon>
                                    </div>
                                </ng-container>
                                <ng-container *ngSwitchCase="'task_operation_operation_id'">
                                    {{getJobTypeById(val)?.name}}
                                </ng-container>
                                <ng-container *ngSwitchCase="'no_operation_id'">
                                    {{getJobTypeById(val)?.name}}
                                </ng-container>
                                <ng-container *ngSwitchCase="'no_channel_id'">
                                    <div class="d-f-c" style="padding: 0; width: 100%; justify-content: flex-start;">
                                        <ng-container *ngIf="val != 0; else elseTemplateNotSet">
                                            <app-platform [platform_id]="getProfileByID(val)?.platform_id"></app-platform>
                                            <span class="ml-1 text_one_line">
                                                {{getProfileByID(val)?.name}}
                                            </span>
                                        </ng-container>
                                        <ng-template #elseTemplateNotSet>
                                            {{"Not set" | translate}}
                                        </ng-template>
                                    </div>
                                </ng-container>
                                <ng-container *ngSwitchCase="'group_id'">
                                    {{getGroupById(val)?.name}}
                                </ng-container>
                                <ng-container *ngSwitchCase="'priority'">
                                    {{getPriorityById(val)?.name}}
                                </ng-container>
                                <ng-container *ngSwitchCase="'content_status_id'">
                                    {{getProfileStatusByID(val)?.name}}
                                </ng-container>
                                <ng-container *ngSwitchCase="'task_content_status_id'">
                                    <ng-container *ngIf="val != 0; else elseTemplateNotSet">
                                        {{getProfileStatusByID(val)?.name}}
                                    </ng-container>
                                    <ng-template #elseTemplateNotSet>
                                        {{"Different" | translate}}
                                    </ng-template>
                                </ng-container>
                                <ng-container *ngSwitchCase="'task_status_id'">
                                    {{getTaskStatusById(val)?.name}}
                                </ng-container>
                                <ng-container *ngSwitchCase="'is_content_url'">
                                    {{getContentUrlByID(val)?.name}}
                                </ng-container>
                                <ng-container *ngSwitchCase="'channel_id'">
                                    <div class="d-f-c" style="padding: 0; width: 100%; justify-content: flex-start;">
                                        <ng-container *ngIf="val != 0; else elseTemplateNotSet">
                                            <app-platform [platform_id]="getProfileByID(val)?.platform_id"></app-platform>
                                            <span class="ml-1 text_one_line">
                                                {{getProfileByID(val)?.name}}
                                            </span>
                                        </ng-container>
                                        <ng-template #elseTemplateNotSet>
                                            {{"Not set" | translate}}
                                        </ng-template>
                                    </div>
                                </ng-container>
                                <ng-container *ngSwitchCase="'discussion_channel_id'">
                                    <div class="d-f-c" style="padding: 0; width: 100%; justify-content: flex-start;">
                                        <ng-container *ngIf="val != 0; else elseTemplateNotSet">
                                            <app-platform [platform_id]="getProfileByID(val)?.platform_id"></app-platform>
                                            <span class="ml-1 text_one_line">
                                                {{getProfileByID(val)?.name}}
                                            </span>
                                        </ng-container>
                                        <ng-template #elseTemplateNotSet>
                                            {{"Not set" | translate}}
                                        </ng-template>
                                    </div>
                                </ng-container>
                                <ng-container *ngSwitchCase="'parameter_value_id'">
                                    {{getValueById(val)?.value}}
                                </ng-container>
                                <ng-container *ngSwitchCase="'task_parameter_value_id'">
                                    {{getValueById(val)?.value}}
                                </ng-container>
                                <ng-container *ngSwitchCase="'task_operation_parameter_value_id'">
                                    {{getValueById(val)?.value}}
                                </ng-container>
                                <ng-container *ngSwitchCase="'discussion_parameter_value_id'">
                                    {{getValueById(val)?.value}}
                                </ng-container>
                                <ng-container *ngSwitchCase="'file_parameter_value_id'">
                                    {{getValueById(val)?.value}}
                                </ng-container>
                                <ng-container *ngSwitchCase="'part_of_count'">
                                    {{ val + '_p' | translate}}
                                </ng-container>
                                <ng-container *ngSwitchCase="'consist_of_count'">
                                    {{ val + '_c' | translate}}
                                </ng-container>
                                <ng-container *ngSwitchCase="'related_count'">
                                    {{ val + '_r' | translate}}
                                </ng-container>
                                <ng-container *ngSwitchCase="'is_content_views_count_update'">
                                    <ng-container [ngSwitch]="val">
                                        <ng-container *ngSwitchCase="0">
                                            {{ "Without auto-update" | translate }}
                                        </ng-container>
                                        <ng-container *ngSwitchCase="1">
                                            {{ "With auto-update" | translate }}
                                        </ng-container>
                                        <ng-container *ngSwitchCase="2">
                                            {{ "Error in update" | translate }}
                                        </ng-container>
                                    </ng-container>
                                </ng-container>
                                <ng-container *ngSwitchCase="'is_bravourl_content_views_count_update'">
                                    <ng-container [ngSwitch]="val">
                                        <ng-container *ngSwitchCase="0">
                                            {{ "Without auto-update" | translate }}
                                        </ng-container>
                                        <ng-container *ngSwitchCase="1">
                                            {{ "With auto-update" | translate }}
                                        </ng-container>
                                        <ng-container *ngSwitchCase="2">
                                            {{ "Error in update" | translate }}
                                        </ng-container>
                                    </ng-container>
                                </ng-container>
                                <ng-container *ngSwitchCase="'content_type_id'">
                                    {{getContentById(val)?.name}}
                                </ng-container>
                                <ng-container *ngSwitchCase="'views_update_interval'">
                                    {{getViewsUpdateIntervalListByValue(val)?.name}}
                                </ng-container>
                                <ng-container *ngSwitchCase="'created_employee_id'">
                                  <app-user [class]="'user_div'" [employee]="getEmployeeById(val)"></app-user>
                                </ng-container>
                                <ng-container *ngSwitchCase="'updated_employee_id'">
                                  <app-user [class]="'user_div'" [employee]="getEmployeeById(val)"></app-user>
                                </ng-container>
                                <ng-container *ngSwitchCase="'created_url_employee_id'">
                                  <app-user [class]="'user_div'" [employee]="getEmployeeById(val)"></app-user>
                                </ng-container>
                                <ng-container *ngSwitchCase="'updated_url_employee_id'">
                                  <app-user [class]="'user_div'" [employee]="getEmployeeById(val)"></app-user>
                                </ng-container>
                                <ng-container *ngSwitchCase="'channel_platform_id'">
                                    <div class="d-f-c" style="padding: 0; width: 100%; justify-content: flex-start;">
                                        <app-platform [platform_id]="val"></app-platform>
                                        <span class="ml-1 text_one_line">
                                            {{ getPlatformById(val)?.name }}
                                        </span>
                                    </div>
                                </ng-container>
                                <ng-container *ngSwitchCase="'operation_employee_id'">
                                    <ng-container *ngIf="val == 0; else elseTemplateWithoutExecMobF">
                                        {{ "Not set" | translate }} 
                                    </ng-container>
                                    <ng-template #elseTemplateWithoutExecMobF>
                                        <app-user [class]="'user_div'" [employee]="getEmployeeById(val)"></app-user>
                                    </ng-template>
                                </ng-container>
                                <ng-container *ngSwitchCase="'client_employee_id'">
                                    <ng-container *ngIf="val == 0; else elseTemplateWithoutClientMobF">
                                        {{ "Not set" | translate }} 
                                    </ng-container>
                                    <ng-template #elseTemplateWithoutClientMobF>
                                        <app-user [class]="'user_div'" [employee]="getEmployeeById(val)"></app-user>
                                    </ng-template>
                                </ng-container>
                                <ng-container *ngSwitchCase="'operation_partner_company_id'">
                                    <ng-container *ngIf="val == 0; else elseTemplateWithoutClientMobF">
                                        {{ "Not set" | translate }} 
                                    </ng-container>
                                    <ng-template #elseTemplateWithoutClientMobF>
                                        <app-user [class]="'user_div ml-1'" [companyEmployee]="getPartnerById(val)?.partnerCompany" [imgRoute]="data.imgRoute" [host]="data.host" [company]="data.company"></app-user>
                                    </ng-template>
                                </ng-container>
                                <ng-container *ngSwitchCase="'origin_company_id'">
                                    <app-user [class]="'user_div ml-1'" [companyEmployee]="getPartnerById(val)?.partnerCompany" [imgRoute]="data.imgRoute" [host]="data.host" [company]="data.company"></app-user>
                                </ng-container>
                                <ng-container *ngSwitchDefault>
                                    {{ val }}
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>
                    <div class="remove" (click)="getValues(activeParams[key]).length > 1 ? removeValue(key, val) : removeValue(key)">
                        <mat-icon>close</mat-icon>
                    </div>
                </mat-list-item>
                <mat-list-item *ngIf="getValues(activeParams[key]).length > 1" class="remove_all" (click)="removeValue(key)" role="listitem">Remove all</mat-list-item>
            </mat-list>
        </ng-container>
        
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <div class="mobile_interface_buttons small_mob_btns" style="justify-content: space-between !important;">
        <button class="mobile_interface_btn" (click)="close()">
            {{ "Cancel" | translate }}
        </button>
        
        <button class="mobile_interface_btn mobile_interface_btn_blue" (click)="clearAll()">
            {{ "Clear all" | translate }}
        </button>
    </div>
</mat-dialog-actions>