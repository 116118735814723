<mat-dialog-content class="mat-typography">
    <span class="head_title" (click)="log()">
        {{ "Screenshot settings" | translate }}
    </span>
    <div class="autos" [appDeviceClass]="'custom-scroll custom-scroll-t'">
        <div class="setting" *ngFor="let item of screenSettings; let i = index;" [ngClass]="{'active': item.is_active}">
            <div class="check" (click)="toggleSettings(item)">
                <svg *ngIf="!item.is_active" style="width: 18px; height: 18px" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.75 2.25H14.25C15.075 2.25 15.75 2.925 15.75 3.75V14.25C15.75 15.075 15.075 15.75 14.25 15.75H3.75C2.925 15.75 2.25 15.075 2.25 14.25V3.75C2.25 2.925 2.925 2.25 3.75 2.25ZM14.25 14.25V3.75H3.75V14.25H14.25Z" fill="#E0E0E0"/>
                </svg>
                <svg *ngIf="!!item.is_active" style="width: 18px; height: 18px" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.75 2.25H14.25C15.0825 2.25 15.75 2.925 15.75 3.75V14.25C15.75 15.075 15.0825 15.75 14.25 15.75H3.75C2.9175 15.75 2.25 15.075 2.25 14.25V3.75C2.25 2.925 2.9175 2.25 3.75 2.25ZM3.75 9L7.5 12.75L14.25 6L13.1925 4.935L7.5 10.6275L4.8075 7.9425L3.75 9Z" fill="#E0E0E0"/>
                </svg>
                <span class="set_name">{{item.name}}</span>
            </div>
            <ng-container *ngIf="i > 0">
                <div class="d-f-c">
                    <span>W</span>
                    <input class="ex_number" matInput type="number" [min]="0" [max]="data.file.meta_width" [(ngModel)]="item.mWidth" (ngModelChange)="onChangeHolst('width', item)">
                </div>
                <div class="d-f-c ml-1">
                    <span>H</span>
                    <input class="ex_number" matInput type="number" [min]="0" [max]="data.file.meta_height" [(ngModel)]="item.mHeight" (ngModelChange)="onChangeHolst('height', item)">
                </div>
                <div class="spl_v"></div>
                <div class="d-f-c ml-1">
                    <span>{{"Size" | translate}} MB</span>
                    <input class="ex_number" matInput type="number" [min]="0" [(ngModel)]="item.filesize">
                </div>
                <mat-icon class="delete_setting cp" (click)="deleteSetting(i)" style="color: #f7f7f7;">close</mat-icon>
            </ng-container>
        </div>
        <div class="add_more" (click)="addNewSetting()">
            + Add more variants to export
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <div class="bottom_btns">
        <button mat-raised-button (click)="close()">
            {{ "Close" | translate }}
        </button>
        <button class="ml-1 d-f-btn" (click)="submitForm()" mat-raised-button color="primary">
            <mat-spinner class="spin_16 mr-1" *ngIf="isSubmit"></mat-spinner>  
            {{ "Save" | translate }}
        </button>
    </div>
</mat-dialog-actions>
