import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseClass } from 'src/app/shared/models/base-class';
import { TaskService } from 'src/app/shared/services/rest/task.service';

@Component({
  selector: 'app-create-task-status',
  templateUrl: './create-task-status.component.html',
  styleUrls: ['./create-task-status.component.scss']
})
export class CreateTaskStatusComponent extends BaseClass implements OnInit {
  public form: FormGroup;
  public isSubmit: boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CreateTaskStatusComponent>,
    private taskService: TaskService,
    private fb: FormBuilder,
  ) { super() }

  ngOnInit(): void {
    this.form = this.fb.group({
      name: ['', Validators.required],
      company_id: this.data.company.id
    })
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }
  
  submitForm() {
    this.isSubmit = true;
    this.attachSubscriptions(
      this.taskService.createTaskType(this.form.value).subscribe(resp => {
        console.log(resp);
        this.data.taskTypes.push(resp)
        this.isSubmit = false;
        this.close()
      })
    )
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

}
