<h2 mat-dialog-title class="dialog_title">
    <span>
        {{ "Checklist items" | translate }}:
    </span>
    <button mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h2>

<mat-dialog-content class="mat-typography">
    <div class="container form_wrapper">
        <ng-container *ngIf="hasChecklistError; else elseTemplateHasError">
            <p style="font-weight:500; color: red;">{{"Oops! Something went wrong, this job does not have active checklist items requiring your attention.. We are working on a fix for this error, please contact support about this situation." | translate}}</p>
        </ng-container>
        <ng-template #elseTemplateHasError>     
            <form [formGroup]="form">
                <mat-form-field appearance="standard" class="full_width_form">
                    <mat-select formControlName="operation_reminder_id">
                        <mat-option *ngFor="let check of checklist" [value]="check.id">
                            {{ check.text }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </form>
        </ng-template>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button (click)="close()">
        {{ "Close" | translate }}
    </button>
    <button color="primary" class="ml-1" mat-raised-button (click)="save()">
        {{ "Save" | translate }}
    </button>
</mat-dialog-actions>