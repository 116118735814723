import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { ReplaySubject, forkJoin } from 'rxjs';
import { debounceTime, last, map, switchMap, tap } from 'rxjs/operators';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { ScenariosService } from 'src/app/shared/services/rest/scenarios.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-and-edit-task-template-executor',
  templateUrl: './add-and-edit-task-template-executor.component.html',
  styleUrls: ['./add-and-edit-task-template-executor.component.scss']
})

export class AddAndEditTaskTemplateExecutorComponent extends BaseClass implements OnInit, OnDestroy {
  public form: FormGroup;
  public employees: any;
  public isSubmit: boolean = false;
  public submited: boolean = false;
  public imgRoute: any = '';
  public host: any = environment.host;
  public employeeMoreControl: FormControl = new FormControl();
  public employees$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public partners: any;
  public partnerMoreControl: FormControl = new FormControl();
  public partners$: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddAndEditTaskTemplateExecutorComponent>,
    private taskService: TaskService,
    private companyService: CompanyService,
    private fb: FormBuilder,
    private sm: StorageManagerService,
    private layoutService: LayoutService,
    private scenariosService: ScenariosService,
  ) { super() }

  ngOnInit(): void {
    console.log("AddAndEditTaskTemplateExecutorComponent", this.data);
    this.form = this.fb.group({
      company_id: this.data.company.id,
      task_template_id: this.data.tmpl.id,
      employee_id: '',
      partner_company_id: '',
    })

    this.attachSubscriptions(
      this.employeeMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchEmoloyees(resp))
    )

    this.getPartners();
    this.attachSubscriptions(
      this.partnerMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchPartners(resp))
    )
    if (this.data.discussion) {
      this.form.addControl("discussion_id", this.fb.control(this.data.discussion.id))
      this.form.get('discussion_id').updateValueAndValidity();
    }

    this.getImgRoute();
    this.getEmployees();
  }

  getPartners() {
    this.attachSubscriptions(
      this.companyService.getPartners({company_id: this.data.company.id}).subscribe(resp => {
        console.log("getPartners", resp);
        this.partners = resp;
        this.partners$.next(this.partners.slice());
      })
    )
  }

  log() {
    console.log('this.form.value', this.form.value)
  }

  togglePartnerChip = (chip: any) => {
    if (chip.hasOwnProperty("partner_company_id")) {
      if (this.form.value.partner_company_id === chip?.partner_company_id) {
        this.form.patchValue({
          partner_company_id: ''
        })
      } else {
        this.form.patchValue({
          partner_company_id: chip?.partner_company_id,
          employee_id: ''
        })
      }
    } else {
      this.form.patchValue({
        partner_company_id: chip.value,
        employee_id: ''
      })
    }
  }

  onSearchPartners(resp) {
    if (!this.partners) {
      return;
    }

    if (!resp) {
      this.partners$.next(this.partners.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.partners$.next(
      this.partners.filter(z => z.partnerCompany.name.toLowerCase().indexOf(resp) > -1)
    );
  }


  checkIsManager(task, company, _user) { 
    return task?.managers.filter(x => x.user_id == _user.id).length > 0 || task?.group?.managers.filter(x => x.user_id == _user.id).length > 0 || company?.permissions?.includes('admin') || company?.permissions.includes('manager'); 
  }

  onSearchEmoloyees(resp) {
    if (!this.employees) {
      return;
    }

    if (!resp) {
      this.employees$.next(this.employees.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.employees$.next(
      this.employees.filter(z => z.name.toLowerCase().indexOf(resp) > -1)
    );
  }


  toggleChip = (chip: any) => {
    console.log("chip", chip)
    console.log("this.form.value.employee_id === (!!chip.id ? chip.id : chip.value)", this.form.value.employee_id === (!!chip.id ? chip.id : chip.value))
    if (this.form.value.employee_id === chip.id) {
      this.form.patchValue({
        employee_id: ''
      })
    } else {
      if (this.data.company?.permissions.includes('owner') || this.checkIsManager(this.data.task, this.data.company, this.data.user)) {
        this.form.patchValue({
          employee_id: !!chip.id ? chip.id : chip.value,
          partner_company_id: ''
        })
      } else {
        this.form.patchValue({
          employee_id: !!chip.id ? chip.id : chip.value
        })
      }
    }
  }


  // getEmployees() {
  //   this.attachSubscriptions(
  //     this.taskService.getEmployees(this.data.company.id).subscribe(resp => {
  //       this.employees = resp;
  //       this.employees$.next(this.employees.slice())
  //     })
  //   )
  // }

  getEmployees() {
    this.attachSubscriptions(
      this.taskService.getEmployeesDyn('1', this.data.company.id, null, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }
  
          return forkJoin(arr.map(x => this.taskService.getEmployeesDyn(x, this.data.company.id, null, '200').pipe(map(u => u.body)))).pipe(
            last(),
            tap(values => {
              let conValues = [].concat(...values)
              this.employees = conValues;
              this.employees$.next(this.employees.slice())
            }),
          )
        }),
      ).subscribe(resp => {
        console.log("getEmployees resp", resp);
        console.log("getEmployees", this.employees);
      })
    )
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  onPartnerImgError(event){
    event.target.src = this.imgRoute+'/assets/img/partner.png'
  }

  getEmployeeById(id) {
    return this.employees && this.employees.find(x => x.id == id)
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  selectStatus(status) {
    this.form.patchValue({
      status_id: status.id
    })
  }

  onSubmit() {
    if (!this.form.value.employee_id && !this.form.value.partner_company_id) {
      this.layoutService.showSnackBar({name: ''}, marker("You need to choose executor"), SnackBarItem)
      return
    }
    this.isSubmit = true;

    if (!!this.data.scen) {
      let sbmtForm = {...this.form.value};
      sbmtForm.automation_template_id = this.form.value.task_template_id;
      delete sbmtForm.task_template_id;

      if (!!sbmtForm.employee_id && !!sbmtForm.partner_company_id) {
        let sbmtForm1 = {...sbmtForm};
        let sbmtForm2 = {...sbmtForm};
        delete sbmtForm1.employee_id;
        delete sbmtForm2.partner_company_id;
        this.attachSubscriptions(
          forkJoin([this.scenariosService.addExecScenario(sbmtForm1, this.data.company.id), this.scenariosService.addExecScenario(sbmtForm2, this.data.company.id)]).subscribe(resp => {
            this.isSubmit = false;
            this.dialogRef.close({event: "add", data: resp})
          })
        )
      } else {
        if (!sbmtForm.employee_id) {
          delete sbmtForm.employee_id
        }
        if (!sbmtForm.partner_company_id) {
          delete sbmtForm.partner_company_id
        }
        this.attachSubscriptions(
          this.scenariosService.addExecScenario(sbmtForm, this.data.company.id).subscribe(resp => {
            this.isSubmit = false;
            this.dialogRef.close({event: "add", data: resp})
          })
        )
      }
      
    } else {
      let formData = {...this.form.value};
      delete formData.partner_company_id;
      this.attachSubscriptions(
        this.scenariosService.addTaskTemplateExecutor(this.form.value).subscribe(resp => {
          this.isSubmit = false;
          this.dialogRef.close({event: "add", data: resp})
        })
      )
    }

    
  }

  ngOnDestroy(): void {
    this.clearSubscriptions();    
  }

}
