import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { AuthService } from 'src/app/shared/services/rest/auth.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { debounceTime, last, map, switchMap, tap } from 'rxjs/operators';
import { forkJoin, ReplaySubject } from 'rxjs';
import { ScenariosService } from 'src/app/shared/services/rest/scenarios.service';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { environment } from 'src/environments/environment';
import { TargetParametersComponent } from 'src/app/components/atTasksDialog/target-parameters/target-parameters.component';

@Component({
  selector: 'app-auto',
  templateUrl: './auto.component.html',
  styleUrls: ['./auto.component.scss']
})

export class AutoComponent extends BaseClass implements OnInit, OnDestroy {
  public host: any = environment.host;
  public imgRoute: any = '';
  public user_id: number;
  public form: FormGroup;
  public company: any;
  public parameters: any[] = [];
  public company_id: any;
  public task_id: any;
  public isFormChange: boolean = false;
  public isSubmit: boolean = false;
  public submited: boolean = false;
  public scnSwitch: boolean = false;
  
  public employees: any;
  public employeeMoreControl: FormControl = new FormControl();
  public employees$: ReplaySubject<any> = new ReplaySubject<any>(1);
  
  public operations: any;
  public operationMoreControl: FormControl = new FormControl();
  public operations$: ReplaySubject<any> = new ReplaySubject<any>(1);
  
  public templates: any;
  public templatesMoreControl: FormControl = new FormControl();
  public templates$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public groups: any
  public groupMoreControl: FormControl = new FormControl();
  public groups$: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AutoComponent>,
    private sm: StorageManagerService,
    private dialog: MatDialog,
    private auth: AuthService,
    private companyService: CompanyService,
    private fb: FormBuilder,
    private taskService: TaskService,
    private scenariosService: ScenariosService,
    private layoutService: LayoutService,

  ) { super() }

  ngOnInit(): void {
    window.scroll(0,0);

    this.company_id = this.data.company.id;

    this.getCompany();
    this.getImgRoute();
    this.getCsrf();

    this.attachSubscriptions(
      this.employeeMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchEmoloyees(resp))
    )
    this.attachSubscriptions(
      this.operationMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchOperations(resp))
    )

    this.attachSubscriptions(
      this.groupMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchGroups(resp))
    )

    this.form = this.fb.group({
      company_id: this.company_id,
      automation_template_id: [[], Validators.required],
      operation_id: '',
      group_id: '',
      employee_id: [[]],
      parameter_value_id: [[]]
    })

    this.attachSubscriptions(
      this.form.valueChanges.subscribe(() => this.isFormChange = true)
    )

    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        if (this.isFormChange) {
          this.layoutService.openBottomSheet(this.dialogRef);
        } else {
          this.close();
        }
      })
    )

    this.getGroupsCompany();
    this.getOperations();
    this.getEmployees();
    this.getAutomationTemplates();
    this.attachSubscriptions(
      this.templatesMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchTemplates(resp))
    )
  }

  onSearchGroups(resp) {
    if (!this.groups) {
      return;
    }

    if (!resp) {
      this.groups$.next(this.groups.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.groups$.next(
      this.groups.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  getGroupsCompany() {
    this.attachSubscriptions(
      this.companyService.getInfiniteGroupsCompany(this.company_id, '1', '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.companyService.getInfiniteGroupsCompany(this.company_id, x).pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        this.groups = [].concat(...res);
        this.groups$.next(this.groups.slice());
      })
    )
  }

  selectGroup(group) {
    this.form.patchValue({
      group_id: !!group.id ? group.id : group.value
    })
  }

  onRemove(tagID, ind) {
    this.parameters[0].activeValues.splice(ind, 1)
    this.form.patchValue({
      parameter_value_id: this.parameters[0].activeValues.map(x => x.id)
    })
  }

  openTargetValues() {
    let initData:any = {
      company: this.company,
      auto: true,
      parameters: this.parameters
    }

    const dialogRef = this.dialog.open(TargetParametersComponent, {
      backdropClass: ['parameters_modal_backdrop'],
      panelClass: ['without_paddings_modal', 'parameters_modal'],
      data: initData
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result) {
          if (result.event == 'save') {
            this.parameters = result.data
            this.form.patchValue({
              parameter_value_id: this.parameters[0].activeValues.map(x => x.id)
            })
          }
        }
      })
    )
  }

  getAutomationTemplates() {
    this.attachSubscriptions(
      this.scenariosService.getAutomationTemplates('1', {company_id: this.data.company.id, is_task_operation: 1}, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.scenariosService.getAutomationTemplates(x, {company_id: this.data.company.id, is_task_operation: 1}, '200').pipe(map(u => u.body)))).pipe(
            last(),
            tap(values => {
              this.templates = [].concat(...values)
              console.log("getAutomationTemplates", this.templates)
              this.templates$.next(this.templates.slice());
            }),
          )
        }),
      ).subscribe(resp => {
        console.log("getAutomationTemplates sub", resp);
      })
    )
  }

  onSearchTemplates(resp) {
    if (!this.templates) {
      return;
    }

    if (!resp) {
      this.templates$.next(this.templates.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.templates$.next(
      this.templates.filter(z => z.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  // toggleChipTemplate = (chip: any) => {
  //   this.form.patchValue({
  //     automation_template_id: chip.id
  //   })
  // }

  toggleChipTemplate = (chip: any) => {
    if (!!chip.id) {
      if (!!this.form.value.automation_template_id && this.form.value.automation_template_id.includes(chip.id)) {
        let val = [...this.form.value.automation_template_id]
        val.splice(this.form.value.automation_template_id.indexOf(chip.id), 1)
        this.form.patchValue({
          automation_template_id: val
        })
      } else {
        if (!!this.form.value.automation_template_id) {
          let val = [...this.form.value.automation_template_id]
          console.log("chip.id", chip.id, chip)
          val.push(chip.id)
          this.form.patchValue({
            automation_template_id: val
          })
        } else {
          console.log("chip.id", chip.id, chip)
          this.form.patchValue({
            automation_template_id: [chip.id]
          })
        }
      }
    }

    console.log("chip.id", chip.id, chip)

    if (!chip.id) {
      this.scnSwitch = true;

      setTimeout(() => {
        this.scnSwitch = false;
      }, 0)
    }
  }


  toggleEmpl(chip: any) {
    if (!!this.form.value.employee_id && this.form.value.employee_id.includes(chip.id)) {
      let val = [...this.form.value.employee_id]
      val.splice(this.form.value.employee_id.indexOf(chip.id), 1)
      this.form.patchValue({
        employee_id: val
      })
    } else {
      if (!!this.form.value.employee_id) {
        let val = [...this.form.value.employee_id]
        val.push(chip.id)
        this.form.patchValue({
          employee_id: val
        })
      } else {
        this.form.patchValue({
          employee_id: [chip.id]
        })
      }
    }
  }

  onSearchEmoloyees(resp) {
    if (!this.employees) {
      return;
    }

    if (!resp) {
      this.employees$.next(this.employees.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.employees$.next(
      this.employees.filter(z => z.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  onSearchOperations(resp) {
    if (!this.operations) {
      return;
    }

    if (!resp) {
      this.operations$.next(this.operations.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.operations$.next(
      this.operations.filter(z => (z.name.toLowerCase().indexOf(resp) > -1))
    );
  }

  selectType(type) {
    this.form.patchValue({
      operation_id: type.id
    })
  }

  getEmployeeById(id) {
    return this.employees.find(x => x.id == id)
  }

  toggleAddChip = (chip: any) => {
    if (!!this.form.get('scenario_data').value.employees_ids && this.form.get('scenario_data').value.employees_ids.includes(chip.id)) {
      let val = [...this.form.get('scenario_data').value.employees_ids]
      val.splice(this.form.get('scenario_data').value.employees_ids.indexOf(chip.id), 1)
      this.form.get('scenario_data').patchValue({
        employees_ids: val
      })
    } else {
      if (!!this.form.get('scenario_data').value.employees_ids) {
        let val = [...this.form.get('scenario_data').value.employees_ids]
        val.push(chip.id)
        this.form.get('scenario_data').patchValue({
          employees_ids: val
        })
      } else {
        this.form.get('scenario_data').patchValue({
          employees_ids: [chip.id]
        })
      }
    }
  }
  
  getCompany() {
    this.attachSubscriptions(
      this.companyService.getCompany(this.data.company.id).subscribe(resp => {
        this.companyService.company$.next(resp[0]);
        this.company = resp[0]
      })
    )
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  onPartnerImgError(event){
    event.target.src = this.imgRoute+'/assets/img/partner.png'
  }
  
  getCsrf() {
    this.attachSubscriptions(
      this.auth.$userToken.subscribe(resp => {
        this.user_id = resp.user_id;
        this.sm.localStorageSetItem("csrf_param", resp.csrf_param)
        this.sm.localStorageSetItem("csrf_token", resp.csrf_token)
      })
    )
  }
  
  getEmployees() {
    this.attachSubscriptions(
      this.taskService.getEmployeesDyn('1', this.data.company.id, null, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }
  
          return forkJoin(arr.map(x => this.taskService.getEmployeesDyn(x, this.data.company.id, null, '200').pipe(map(u => u.body)))).pipe(
            last(),
            tap(values => {
              let conValues = [].concat(...values)
              this.employees = conValues;
              this.employees$.next(this.employees.slice())
            }),
          )
        }),
      ).subscribe(resp => {
        console.log("getEmployees resp", resp);
        console.log("getEmployees", this.employees);
      })
    )
  }
  
  getOperations() {
    this.attachSubscriptions(
      this.taskService.getOperations(this.data.company.id).subscribe(resp => {
        this.operations = resp;
        this.operations$.next(this.operations.slice())
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  onSubmit() {
    if ( !this.form.valid || ( (!this.form.value.employee_id || this.form.value.employee_id.length == 0) && !this.form.value.operation_id ) ) {
      this.submited = true;
      this.layoutService.showSnackBar({name: ''}, marker("You need to fill in all required fields"), SnackBarItem)
      return
    }
    this.submited = false;
    this.isSubmit = true;
    let formData = {...this.form.value}

    if (formData.parameter_value_id.length) {
      formData.parameter_value_id = formData.parameter_value_id.join(',')
    } else {
      delete formData.parameter_value_id
    }

    delete formData.automation_template_id;
    delete formData.employee_id;
 

    let sbmtData = []

    // if (!!this.form.value.employee_id && this.form.value.employee_id.length > 0) {
      if (!formData.operation_id) {
        formData.operation_id = 0;
      }

      this.form.value.automation_template_id.forEach(tmpl_id => {
        if (!!this.form.value.employee_id && this.form.value.employee_id.length > 0) {
          this.form.value.employee_id.forEach(empl_id => {
            sbmtData.push(Object.assign({
              employee_id: empl_id,
              automation_template_id: tmpl_id
            }, formData))
          });
        } else {
          sbmtData.push(Object.assign({
            automation_template_id: tmpl_id
          }, formData))
        }
      });


  
      this.attachSubscriptions(
        forkJoin(sbmtData.map(x => this.scenariosService.addAuto(x))).subscribe(resp => {
          console.log("forkJoin", resp);
          this.isSubmit = false;
          this.close();
        })
      )
    // } else {
    //   formData.employee_id = 0;
    //   this.attachSubscriptions(
    //     this.scenariosService.addAuto(formData).subscribe(resp => {
    //       console.log("addAuto", resp);
    //       this.isSubmit = false;
    //       this.close();
    //     })
    //   )
    // }

    console.log(sbmtData);
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
