import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'veTime'
})
export class VideoEditorTimeFormatPipe implements PipeTransform {
    transform(time: number) {
        const hours = Math.floor(time / 3600);
        const minutes = Math.floor((time % 3600) / 60);
        const seconds = Math.floor(time % 60);
        const milliseconds = Math.floor((time % 1) * 1000);
        
        let formattedTime = '';
        
        if (hours > 0) {
            formattedTime += padZero(hours, 1) + ':';
        }
        
        if (hours > 0 || minutes > 0) {
            formattedTime += padZero(minutes, !(hours > 0) ? 2 : 1) + ':';
        }
        
        formattedTime += padZero(seconds) + '.' +
                            padZero(Math.floor(milliseconds / 10));
        
        return formattedTime;
        
        
        function padZero(num: number, size: number = 2): string {
            let numStr = num.toString();
            while (numStr.length < size) {
                numStr = '0' + numStr;
            }
            return numStr;
        }
    }
}
