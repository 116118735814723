import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { BaseClass } from '../../models/base-class';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CompanyService } from '../../services/rest/company.service';
import { CommentService } from '../../services/rest/comment.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { map, switchMap, tap } from 'rxjs/operators';
import * as moment from 'moment';
import { TaskService } from '../../services/rest/task.service';
import { ProfileEditComponent } from 'src/app/components/workspace-settings/publication-outlets/profile-edit/profile-edit.component';
import { AiService } from '../../services/rest/ai.service';
import { LayoutService } from '../../services/common/layout.service';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { SnackBarItem } from '../snack-bar/snack-bar-item';
import { ReplaySubject } from 'rxjs';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})
export class CommentsComponent extends BaseClass implements OnInit, OnDestroy {
  public comments: any;
  public reply_msg: any;
  public aiCount: number = 0;
  public isGettingResults: boolean = false;
  public is_dis: boolean = false;
  public is_get_more: boolean = true;
  public is_cst_reply: boolean = false;
  public form: FormGroup;
  public aiReq: FormGroup;
  public ai_model: FormControl = new FormControl();
  public channelForm: FormGroup;

  public aiModelControl: FormControl = new FormControl();
  public models$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public models: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CommentsComponent>,
    private commentService: CommentService,
    private dialog: MatDialog,
    private aiService: AiService,
    private layoutService: LayoutService,
    private taskService: TaskService,
    private fb: FormBuilder,
  ) { super() }

  ngOnInit(): void {
    console.log("CommentsComponent", this.data)

    this.getAIModels();
    this.getComments();
    this.form = this.fb.group({
      company_id: this.data.company.id,
      task_channel_id: this.data.profile.id,
      message: '',
      is_to_publish: 1
    })
    this.channelForm = this.fb.group({
      is_comments_update: !!this.data.profile.is_comments_update,
    })

    this.aiReq = this.fb.group({
      content: '',
    })

    console.log("test", this.data.profile)
    if (!this.data.profile.channel.ai_description) {
      this.editProfile()
    }

    this.attachSubscriptions(
      this.channelForm.valueChanges.pipe(
        tap(x => {
          this.is_dis = true;
        }),
        switchMap(res => {
          let sbmtData = {...res}
          sbmtData.is_comments_update = !!sbmtData.is_comments_update ? 1 : 0;
          return this.taskService.editProfile(this.data.profile.id, sbmtData, this.data.company.id)
        })
      ).subscribe(res => {
        this.is_dis = false;
        this.data.profile.is_comments_update = res.is_comments_update;
        console.log("this.channelForm.valueChanges", res.is_comments_update)
      })
    )
  }

  generateCustomReply() {
    this.is_cst_reply = true;
    this.generateReply()
  }

  generateReply() {
    if (!this.data.profile.channel.ai_description) {
      this.editProfile()
    } else if (!this.data.profile.ai_description) {
      this.editPost()
    } else {
      if (this.is_cst_reply) {
        this.aiReq.patchValue({
          content: `There is a video post in which ${this.data.profile.ai_description} and user commented ${this.reply_msg.message}. Write a reply comment to reply this comment from the post Author to keep conversation and positive environment.`,
        })
      }
      this.getResults()
    }
  }

  getAIModels() {
    this.attachSubscriptions(
      this.aiService.getAIModels().subscribe(resp => {
        this.models = resp;
        this.models$.next(resp);

        if (this.models.length) {
          let model_id = this.models[0].id;
          if (this.models.find(k => k.id == 1)) {
            model_id = this.models.find(k => k.id == 1).id
          }
          this.ai_model.patchValue(model_id)
        }
      })
    )
  }

  getResults() {
    this.aiCount++

    let x:any = {
      company_id: this.data.company.id,
      partner_company_id: this.data.company.id != this.data.profile.company_id ? this.data.profile.company_id : 0,
      messages: [],
      model: this.ai_model.value
    }

    let msg = "";
    // Object.keys(form.value).forEach(key => {
    //   if (!!form.value[key]) {
    //     if (['keywords', 'remembers'].includes(key) && form.value[key].length == 0) {
    //       return
    //     }

    //     if (key == 'keywords') {
    //       msg += "Use keywords "
    //     }

    //     if (['keywords', 'remembers'].includes(key)) {
    //       msg+= `${form.value[key].join(', ')}. `
    //     } else {
    //       msg+= `${form.value[key]}. `
    //     }

    //   }
    // })

    //  Provide 5 options.
    let content;

    if (this.is_cst_reply) {
      content = this.aiReq.value.content;
    } else {
      content = `There is a video post in which ${this.data.profile.ai_description} and user commented ${this.reply_msg.message}. Write a reply comment to reply this comment from the post Author to keep conversation and positive environment.`
    }

    x.messages.push({
      role: 'user',
      content: content
    })

    this.isGettingResults = true;
    this.attachSubscriptions(
      this.aiService.sendAIMsg(x).pipe(
        // map(u => {
        //   if (u.indexOf("<ul>") != -1) {
        //     const parser = new DOMParser();
        //     const doc = parser.parseFromString(u, 'text/html');
        //     const ulElement = doc.querySelector('ul');

        //     if (ulElement) {
        //       let liElements = ulElement.querySelectorAll('li');
        //       let liArray = Array.from(liElements).map(x => (String(x.textContent) as any).replaceAll("\n", "").trim())
        //       return {parsed: !!liArray && liArray.length ? liArray : false, before: u};
        //     } else {
        //       return {parsed: false, before: u}
        //     }
        //   } else {
        //     return {parsed: false, before: u}
        //   }
        // })
      ).subscribe(resp => {
        console.log("getResults", resp)
        // if (resp.parsed) {
        //   this.aiCount = 0;
        //   this.isGettingResults = false;
        //   this.aiResults = resp.parsed
        //   console.log("TESTS", !contextdata.contextData || !contextdata.contextData.is_cut)
        //   console.log("TESTS", this.aiResults)
        //   if (!contextdata.contextData || !contextdata.contextData.is_cut) {
        //     this.setTitlesForParts(parts)
        //   }
        // } else {
        //   if (this.aiCount <= 3) {
        //     this.getResults(form, contextdata)
        //   } else {
        //     this.aiMistake = resp.before
        //     this.aiCount = 0;
        //     this.isGettingResults = false;
        //     this.layoutService.showSnackBar({name: ''}, marker("Incorrect data received, please try again"), SnackBarItem)
        //   }
        // }
        if (resp) {
          this.form.patchValue({
            message: resp
          })
          this.isGettingResults = false;
        } else {
          if (this.aiCount <= 3) {
            this.getResults()
          } else {
            this.aiCount = 0;
            this.isGettingResults = false;
            this.layoutService.showSnackBar({name: ''}, marker("Incorrect data received, please try again"), SnackBarItem)
          }
        }
      }, error => {
        if (this.aiCount <= 3) {
          this.getResults()
        } else {
          this.aiCount = 0;
          this.isGettingResults = false;
          this.layoutService.showSnackBar({name: ''}, marker("Incorrect data received, please try again"), SnackBarItem)
        }
      })
    )
    // console.log("getResults form", form.value);
    // console.log("getResults x", x);
  }

  editPost() {
    const dialogRef = this.dialog.open(ProfileEditComponent, {
      disableClose: true,
      data: {
        company: this.data.company,
        company_id: this.data.company.id,
        profile: this.data.profile.channel,
        post: this.data.profile,
        group: null,
        aiDesc: true
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result && result.event == "update") {
          this.data.profile.ai_description = result.data.ai_description;
          this.generateReply()
        }
      })
    )
  }

  editProfile() {
    const dialogRef = this.dialog.open(ProfileEditComponent, {
      disableClose: true,
      data: {
        company: this.data.company,
        company_id: this.data.company.id,
        profile: this.data.profile.channel,
        group: null,
        aiDesc: true
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result && result.event == "update") {
          this.data.profile.channel.ai_description = result.data.ai_description;
        }
      })
    )
  }

  refreshComments(is_all) {
    let sbmtData: any = {};
    if (is_all) {
      sbmtData.is_comments_initialized = 0
    } else {
      sbmtData.is_comments_count_updated = 1
    }
    this.attachSubscriptions(
      this.taskService.editProfile(this.data.profile.id, sbmtData, this.data.company.id).subscribe(resp => {
        if (is_all) {
          this.data.profile.is_comments_initialized = resp.is_comments_initialized
        } else {
          this.data.profile.is_comments_count_updated = resp.is_comments_count_updated
        }
        this.getComments()
      })
    )
  }

  send(comment?) {
    let sbmtData = {...this.form.value}
    if (comment) {
      sbmtData.reply_to_id = comment.id
    }
    this.attachSubscriptions(
      this.commentService.sendComment(sbmtData).pipe(
        tap(x => {
          this.form.patchValue({
            message: ''
          })
        }),
        // switchMap(x => {
        //   return this.commentService.getComments({
        //     company_id: this.data.company.id,
        //     task_channel_id: this.data.profile.id,
        //     after: ""
        //   })
        // })
      ).subscribe(resp => {
        console.log("send", resp)
        if (comment) {
          this.reply_msg = undefined
        }
        this.is_cst_reply = false;
        this.getComments();
      })
    )
  }

  getComments() {
    this.attachSubscriptions(
      this.commentService.getComments({
        company_id: this.data.company.id,
        task_channel_id: this.data.profile.id,
        reply_to_id: 0
      }).pipe(
        tap(x => console.log("x", x)),
        map(x => x.body)
      ).subscribe(resp => {
        console.log("getComments", resp)
        // resp.data.forEach(element => {
        //   element.is_get_more = true;
        //   element.time = moment(element.created_time).format("X")
        // });
        this.comments = resp;
      })
    )
  }

  getMore(comments) {
    console.log("getMore", comments)
    this.attachSubscriptions(
      this.commentService.getComments({
        company_id: this.data.company.id,
        task_channel_id: this.data.profile.id,
        after: comments.paging.cursors.after
      }).subscribe(resp => {
        console.log("getMore", resp)
        resp.data.forEach(element => {
          element.time = moment(element.created_time).format("X")
        });
        if (resp.data && resp.data.length) {
          this.comments.data.push(...resp.data);
          this.comments.paging.cursors = resp.paging.cursors;
        } else {
          this.is_get_more = false;
        }
        // this.comments = resp;
      })
    )
  }

  getMoreReviews(comment) {
    console.log("getReviews", comment)

    this.attachSubscriptions(
      this.commentService.getComments({
        company_id: this.data.company.id,
        task_channel_id: this.data.profile.id,
        comment_id: comment.id,
        after: comment.reviews.paging.cursors.after
      }).subscribe(resp => {
        console.log("getReviews", resp)
        resp.data.forEach(element => {
          element.time = moment(element.created_time).format("X")
        });
        if (resp.data && resp.data.length) {
          comment.reviews.data.push(...resp.data);
          comment.reviews.paging.cursors = resp.paging.cursors;
        } else {
          comment.is_get_more = false;
        }
      })
    )
  }

  compareDates(val) {
    return moment(val*1000).isSameOrAfter(moment().startOf('d'))
  }
  
  getReviews(comment) {
    console.log("getReviews", comment)
    this.attachSubscriptions(
      this.commentService.getComments({
        company_id: this.data.company.id,
        task_channel_id: this.data.profile.id,
        reply_to_id: comment.id
      }).subscribe(resp => {
        console.log("getReviews", resp)
        comment.reviews = resp;
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

}
