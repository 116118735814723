<h2 mat-dialog-title class="dialog_title">
    <div class="d-f-c">
        <div>
            <b>{{ data.company.name }}</b><br>
            {{ "Promo(s)" | translate }}
        </div>
        <div class="filter_btn ml-1" (click)="changeFilter(2)" [ngClass]="{'active': is_em == 2}">
            {{"Rewards" | translate}}
        </div>
        <div class="filter_btn ml-5" (click)="changeFilter(1)" [ngClass]="{'active': is_em == 1}">
            {{"Referral codes" | translate}}
        </div>
        <div class="filter_btn ml-1" (click)="changeFilter(0)" [ngClass]="{'active': is_em == 0}">
            {{"Referral Activity" | translate}}
        </div>
    </div>
    <button class="ml-1" mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h2>
<mat-dialog-content class="mat-typography">
    <div class="container">
        <div #tableContainer class="example-container" [appDeviceClass]="'custom-scroll custom-scroll-t'">
            <table mat-table [dataSource]="promos" class="centred_cols" multiTemplateDataRows>
                <!-- ["Date", "Order", "Operation type", "Text"] -->
                <ng-container matColumnDef="Date">
                    <th mat-header-cell *matHeaderCellDef> {{ "Created at" | translate }} </th>
                    <td mat-cell *matCellDef="let element">
                        <ng-container *ngIf="element?.created_at > today && element?.created_at < todayEnd; else createdAtTempl">
                            <span #tooltip="matTooltip"
                            matTooltip="{{ element?.created_at*1000 | date:'MMM d, y':'':activeLang | yearPipe }}"
                            [matTooltipPosition]="'above'">
                            {{ element?.created_at*1000 | date:'hh:mm a':'':activeLang }}
                            </span>
                        </ng-container>
                        <ng-template #createdAtTempl>
                            <span *ngIf="element?.created_at && element?.created_at > 30000" #tooltip="matTooltip"
                            matTooltip="{{ element?.created_at*1000 | date:'hh:mm a':'':activeLang | yearPipe }}"
                            [matTooltipPosition]="'above'">
                            {{ element?.created_at*1000 | date:'MM.dd.yy':'': activeLang }}
                            </span>
                        </ng-template>
                    </td>
                </ng-container>

                <ng-container matColumnDef="Workspace">
                    <th mat-header-cell *matHeaderCellDef> {{ "Workspace" | translate }} </th>
                    <td mat-cell *matCellDef="let element">
                        <div class="company_block" *ngIf="getCompById(element?.company_id)">
                            <ng-container *ngIf="!!getCompById(element?.company_id)?.avatarFile?.thumbnail && !!data.host; else elseTemplatePartnerAvatar">
                                <img class="partner_avatar" (error)="onPartnerImgError($event)" [src]="data.host + getCompById(element?.company_id)?.avatarFile?.thumbnail" alt="">
                            </ng-container>
                            <ng-template #elseTemplatePartnerAvatar>
                                <img class="partner_avatar" (error)="onPartnerImgError($event)" src="{{ data.imgRoute }}assets/img/partner.png">
                            </ng-template>
                            <span class="company_name">
                                {{ getCompById(element?.company_id).name }}
                            </span>
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="Code">
                    <th mat-header-cell *matHeaderCellDef> {{ "Code" | translate }} </th>
                    <td mat-cell *matCellDef="let element">
                        <div class="cp" style="font-weight: 500;" ngxClipboard [cbContent]="element.code" (cbOnSuccess)="copyData('Code')">
                            <b>{{element?.code}}</b>
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="Initial">
                    <th mat-header-cell *matHeaderCellDef> {{ "Initial" | translate }} </th>
                    <td mat-cell *matCellDef="let element">
                        <div style="font-size: 18px;">
                            {{!!element.is_initial ? "+" : "-"}}
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="Description">
                    <th mat-header-cell *matHeaderCellDef> {{ "Description" | translate }} </th>
                    <td mat-cell *matCellDef="let element">
                        <div class="text_3_line" style="max-width: 20vw;">{{element?.description}}</div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="Workspace Description">
                    <th mat-header-cell *matHeaderCellDef> {{ "Workspace Description" | translate }} </th>
                    <td mat-cell *matCellDef="let element">
                        <div class="text_3_line" style="max-width: 20vw;">{{element?.description_company}}</div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="Root Description">
                    <th mat-header-cell *matHeaderCellDef> {{ "Root Description" | translate }} </th>
                    <td mat-cell *matCellDef="let element">
                        <div class="text_3_line" style="max-width: 20vw;">{{element?.description_root}}</div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="Actions">
                    <th mat-header-cell *matHeaderCellDef> {{ "" | translate }} </th>
                    <td mat-cell *matCellDef="let element">
                        <ng-container *ngIf="is_em == 1">
                            <button mat-icon-button (click)="$event.preventDefault(); $event.stopPropagation(); addPromo(element)" style="color: rgb(19, 85, 19);">
                                <mat-icon>edit</mat-icon>
                            </button>
                            <button mat-icon-button (click)="$event.preventDefault(); $event.stopPropagation(); deletePromo(element)" style="color: rgb(133, 38, 38);">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </ng-container> 
                    </td>
                </ng-container>
                    
                <ng-container matColumnDef="expandedDetail">
                    <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                    <div class="row student-element-detail" [@detailExpand]="element.isExpanded ? 'expanded' : 'collapsed'">
                        <h2>{{"Description" | translate}}</h2>
                        <div class="text_promo">{{element.description}}</div>
                        <h2>{{"Workspace Description" | translate}}</h2>
                        <div class="text_promo">{{element.description_company}}</div>
                        <h2>{{"Root Description" | translate}}</h2>
                        <div class="text_promo">{{element.description_root}}</div>
                        <hr>
                        <pre>
                            {{element | json}}
                        </pre>
                    </div>
                    </td>
                </ng-container>
            
                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="student-element-row cp"
                [class.student-expanded-row]="element.isExpanded"
                (click)="rowClick(element)"></tr>
    
                <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="student-detail-row"></tr>
            </table>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <div class="btns_line" style="padding-bottom: 0;">
        <button mat-raised-button (click)="close()">
            {{ "Close" | translate }}
        </button>
        <button class="ml-1 d-f-btn" (click)="addPromo()" mat-raised-button cdkFocusInitial color="primary">
            {{ "Add Promo" | translate }}
        </button>
    </div>
</mat-dialog-actions>
