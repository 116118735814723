import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReplaySubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';

@Component({
  selector: 'app-set-edit-folders-permission',
  templateUrl: './set-edit-folders-permission.component.html',
  styleUrls: ['./set-edit-folders-permission.component.scss']
})
export class SetEditFoldersPermissionComponent extends BaseClass implements OnInit, OnDestroy {
  public isSubmit: boolean = false;
  
  public paramControl: FormControl = new FormControl();
  public parameters$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public allValuesControl: FormControl = new FormControl();
  public allValues$: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private taskService: TaskService,
    public dialogRef: MatDialogRef<SetEditFoldersPermissionComponent>,
    private companyService: CompanyService,
    private layoutService: LayoutService
  ) { super() }

  ngOnInit(): void {
    console.log('SetEditFoldersPermissionComponent', this.data)


    // this.allValues$.next(this.data.allValues.slice())
    // this.parameters$.next(this.data.parameters.slice())
    

    this.attachSubscriptions(
      this.paramControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchValues(resp))
    )
    this.attachSubscriptions(
      this.allValuesControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchValues(resp))
    )
  }

  toggleKey(source, key, altKey) {
    source[key] = Number(!source[key]);
    if (!!source[key]) {
      source[altKey] = 0
    }
  }

  toogleFolRule(e, loc) {
    e.preventDefault();
    e.stopPropagation(); 
    if (this.data.source.write_directories.includes(loc)) {
      this.data.source.write_directories.splice(this.data.source.write_directories.indexOf(loc), 1);
    } else {
      this.data.source.write_directories.push(loc);
    }
  }

  changeCheck(e, item, key) {
    console.log(e, item, key)
    if (key == 'cant_read' && !!e.checked && item.id != -1) {
      item.cant_write = true
    }

    console.log(this.data.source);
  }

  getFolderByLoc(loc) {
    return this.data.folders && this.data.folders.find(x => x.location == loc)
  }

  getValuesById(id) {
    return this.data.allValues && this.data.allValues.find(x => x.id == id)
  }

  getParamById(id) {
    return this.data.parameters && this.data.parameters.find(x => x.id == id)
  }

  onSearchValues(resp) {
    if (!this.data.allValues) {
      return;
    }

    if (!resp) {
      this.allValues$.next(this.data.allValues.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.allValues$.next(
      this.data.allValues.filter(z => z.value.toLowerCase().indexOf(resp) > -1 || (z.parameter.name && z.parameter.name.toLowerCase().indexOf(resp) > -1))
    );
  }

  close() {
    this.dialogRef.close({event: "close", data: false});
  }

  submitForm() {
    this.isSubmit = true;
    this.dialogRef.close({event: "change", data: this.data.source})
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
