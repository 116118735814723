<div mat-dialog-title class="mobile_interface_title">
    <div class="mobile_interface_title_in" style="overflow: hidden;">
        <div class="tab_title" (click)="close()">
            <div class="d-f-c">
                <div class="icon_back">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7 12L6.11612 11.1161C5.62796 11.6043 5.62796 12.3957 6.11612 12.8839L7 12ZM16.8839 3.88388C17.372 3.39573 17.372 2.60427 16.8839 2.11612C16.3957 1.62796 15.6043 1.62796 15.1161 2.11612L16.8839 3.88388ZM15.1161 21.8839C15.6043 22.372 16.3957 22.372 16.8839 21.8839C17.372 21.3957 17.372 20.6043 16.8839 20.1161L15.1161 21.8839ZM7.88388 12.8839L16.8839 3.88388L15.1161 2.11612L6.11612 11.1161L7.88388 12.8839ZM6.11612 12.8839L15.1161 21.8839L16.8839 20.1161L7.88388 11.1161L6.11612 12.8839Z" fill="#232323"/>
                    </svg>
                </div>
                <span>
                    {{ "Chat options" | translate }}
                </span>
            </div>
        </div>
    </div>
</div>
<mat-dialog-content class="mobile_interface_content">
    <div class="mobile_scrollable_int_big">
        <div class="has_pads mob_2_grid">
            <div class="grid_item" (click)="close('delete')" *ngIf="(data?.company?.permissions.includes('owner') || data?.company?.permissions.includes('manager') || checkIsManager(data?.task, data?.company, data?.user)) && data?.task?.company_id == data?.company.id">
                <div class="icon">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_3166_43631)">
                        <path d="M6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V7H6V19ZM19 4H15.5L14.5 3H9.5L8.5 4H5V6H19V4Z" fill="#686868"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_3166_43631">
                        <rect width="24" height="24" fill="white"/>
                        </clipPath>
                        </defs>
                    </svg>
                </div>
                <span>
                    {{ "Delete" | translate }}
                </span>
            </div>
            <div class="grid_item" (click)="close('notes')">
                <div class="icon">
                    <mat-icon>text_snippet</mat-icon>
                </div>
                <span>{{ 'Notes' | translate }}</span>
            </div>
            <div class="grid_item" (click)="close('move')" *ngIf="(data?.company?.permissions.includes('owner') || data?.company?.permissions.includes('manager') || checkIsManager(data?.task, data?.company, data?.user)) && data?.task?.company_id == data?.company.id">
                <div class="icon">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.9756 4.14634V9.02439H15.8537V5.85366L20 10L15.8537 14.1463V10.9756H10.9756V15.8537H14.1463L10 20L5.85366 15.8537H9.02439V10.9756H4.14634V14.1463L0 10L4.14634 5.85366V9.02439H9.02439V4.14634H5.85366L10 0L14.1463 4.14634H10.9756Z" fill="#686868"/>
                    </svg>
                </div>
                <span>
                    {{ "Move to other job" | translate }}
                </span>
            </div>
            <div class="grid_item" (click)="close('parameters')" *ngIf="data?.company?.permissions.includes('owner') || data?.company?.permissions.includes('admin') || data?.company?.permissions.includes('manager') || data?.company?.permissions.includes('assign_task_parameter')">
                <div class="icon">
                    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3.5 5C3.89782 5 4.27936 4.84196 4.56066 4.56066C4.84196 4.27936 5 3.89782 5 3.5C5 3.10218 4.84196 2.72064 4.56066 2.43934C4.27936 2.15804 3.89782 2 3.5 2C3.10218 2 2.72064 2.15804 2.43934 2.43934C2.15804 2.72064 2 3.10218 2 3.5C2 3.89782 2.15804 4.27936 2.43934 4.56066C2.72064 4.84196 3.10218 5 3.5 5ZM15.41 7.58C15.77 7.94 16 8.44 16 9C16 9.55 15.78 10.05 15.41 10.41L10.41 15.41C10.05 15.77 9.55 16 9 16C8.45 16 7.95 15.78 7.58 15.41L0.59 8.42C0.22 8.05 0 7.55 0 7V2C0 0.89 0.89 0 2 0H7C7.55 0 8.05 0.22 8.41 0.58L15.41 7.58ZM11.54 1.71L12.54 0.71L19.41 7.58C19.78 7.94 20 8.45 20 9C20 9.55 19.78 10.05 19.42 10.41L14.04 15.79L13.04 14.79L18.75 9L11.54 1.71Z" fill="#686868"/>
                    </svg>
                </div>
                <span>
                    {{ "Tags" | translate }}
                </span>
            </div>
            <div class="grid_item" (click)="close('priority')">
                <div class="icon">
                    <svg width="4" height="20" viewBox="0 0 4 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 0H4V12.2222H0V0ZM0 20V15.5556H4V20H0Z" fill="#686868"/>
                    </svg>
                </div>
                <span>
                    {{ "Priority" | translate }}
                </span>
            </div>
            <div class="grid_item" (click)="close('search')">
                <div class="icon">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.42857 0C9.39875 0 11.2882 0.782651 12.6814 2.17578C14.0745 3.56891 14.8571 5.45839 14.8571 7.42857C14.8571 9.26857 14.1829 10.96 13.0743 12.2629L13.3829 12.5714H14.2857L20 18.2857L18.2857 20L12.5714 14.2857V13.3829L12.2629 13.0743C10.96 14.1829 9.26857 14.8571 7.42857 14.8571C5.45839 14.8571 3.56891 14.0745 2.17578 12.6814C0.782651 11.2882 0 9.39875 0 7.42857C0 5.45839 0.782651 3.56891 2.17578 2.17578C3.56891 0.782651 5.45839 0 7.42857 0ZM7.42857 2.28571C4.57143 2.28571 2.28571 4.57143 2.28571 7.42857C2.28571 10.2857 4.57143 12.5714 7.42857 12.5714C10.2857 12.5714 12.5714 10.2857 12.5714 7.42857C12.5714 4.57143 10.2857 2.28571 7.42857 2.28571Z" fill="#686868"/>
                    </svg>
                </div>
                <span>
                    {{ "Search" | translate }}
                </span>
            </div>
            <div class="grid_item" (click)="close('edit title')">
                <div class="icon">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_3166_43639)">
                        <path d="M3 17.2496V20.9996H6.75L17.81 9.93957L14.06 6.18957L3 17.2496ZM20.71 7.03957C21.1 6.64957 21.1 6.01957 20.71 5.62957L18.37 3.28957C17.98 2.89957 17.35 2.89957 16.96 3.28957L15.13 5.11957L18.88 8.86957L20.71 7.03957Z" fill="#686868"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_3166_43639">
                        <rect width="24" height="24" fill="white"/>
                        </clipPath>
                        </defs>
                    </svg>
                </div>
                <span>
                    {{ "Edit chat title" | translate }}
                </span>
            </div>
            <div class="grid_item" (click)="close('assignments')">
                <div class="icon">
                    <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.5 7C14.8283 7 15.1534 6.93534 15.4567 6.8097C15.76 6.68406 16.0356 6.49991 16.2678 6.26777C16.4999 6.03562 16.6841 5.76002 16.8097 5.45671C16.9353 5.15339 17 4.8283 17 4.5C17 3.83696 16.7366 3.20107 16.2678 2.73223C15.7989 2.26339 15.163 2 14.5 2C13.837 2 13.2011 2.26339 12.7322 2.73223C12.2634 3.20107 12 3.83696 12 4.5C12 4.8283 12.0647 5.15339 12.1903 5.45671C12.3159 5.76002 12.5001 6.03562 12.7322 6.26777C13.2011 6.73661 13.837 7 14.5 7ZM7 6C7.79565 6 8.55871 5.68393 9.12132 5.12132C9.68393 4.55871 10 3.79565 10 3C10 2.20435 9.68393 1.44129 9.12132 0.87868C8.55871 0.316071 7.79565 0 7 0C6.20435 0 5.44129 0.316071 4.87868 0.87868C4.31607 1.44129 4 2.20435 4 3C4 3.79565 4.31607 4.55871 4.87868 5.12132C5.44129 5.68393 6.20435 6 7 6ZM14.5 9C12.67 9 9 9.92 9 11.75V14H20V11.75C20 9.92 16.33 9 14.5 9ZM7 8C4.67 8 0 9.17 0 11.5V14H7V11.75C7 10.9 7.33 9.41 9.37 8.28C8.5 8.1 7.66 8 7 8Z" fill="#686868"/>
                    </svg>
                </div>
                <span>
                    {{ "Assignments" | translate }}
                </span>
            </div>
            <div class="grid_item" (click)="close('conns')">
                <div class="icon">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18 16.08C17.24 16.08 16.56 16.38 16.04 16.85L8.91 12.7C8.96 12.47 9 12.24 9 12C9 11.76 8.96 11.53 8.91 11.3L15.96 7.19C16.5 7.69 17.21 8 18 8C19.66 8 21 6.66 21 5C21 3.34 19.66 2 18 2C16.34 2 15 3.34 15 5C15 5.24 15.04 5.47 15.09 5.7L8.04 9.81C7.5 9.31 6.79 9 6 9C4.34 9 3 10.34 3 12C3 13.66 4.34 15 6 15C6.79 15 7.5 14.69 8.04 14.19L15.16 18.35C15.11 18.56 15.08 18.78 15.08 19C15.08 20.61 16.39 21.92 18 21.92C19.61 21.92 20.92 20.61 20.92 19C20.92 17.39 19.61 16.08 18 16.08Z" fill="#D7D7D7"/>
                        <circle *ngIf="data?.chat?.partOfDiscussionPartition.length != 0" cx="6" cy="12" r="3" fill="#96A6C2"/>
                        <circle *ngIf="data?.chat?.discussionRelated.length != 0" cx="18" cy="19" r="3" fill="#FFD0A7"/>
                        <circle *ngIf="data?.chat?.consistOfDiscussionPartition.length != 0" cx="18" cy="5" r="3" fill="#98F1BD"/>
                    </svg>
                </div>
                <span>
                    {{ "Connections to chats" | translate }}
                </span>
            </div>
            <div class="grid_item" (click)="close('board')">
                <div class="icon">
                    <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9 10L5 14H8V20H10V14H13L9 10ZM16 2H15V0H13V2H5V0H3V2H2C1.46957 2 0.960859 2.21071 0.585786 2.58579C0.210714 2.96086 0 3.46957 0 4V18C0 18.5304 0.210714 19.0391 0.585786 19.4142C0.960859 19.7893 1.46957 20 2 20H6V18H2V7H16V18H12V20H16C16.5304 20 17.0391 19.7893 17.4142 19.4142C17.7893 19.0391 18 18.5304 18 18V4C18 3.46957 17.7893 2.96086 17.4142 2.58579C17.0391 2.21071 16.5304 2 16 2Z" fill="#686868"/>
                    </svg>
                </div>
                <span>
                    {{ "Add to board" | translate }}
                </span>
            </div>
            <div class="grid_item" (click)="close('info')">
                <div class="icon">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9 7H11V5H9M10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18ZM10 0C8.68678 0 7.38642 0.258658 6.17317 0.761205C4.95991 1.26375 3.85752 2.00035 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C3.85752 17.9997 4.95991 18.7362 6.17317 19.2388C7.38642 19.7413 8.68678 20 10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7362 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0ZM9 15H11V9H9V15Z" fill="#686868"/>
                    </svg>
                </div>
                <span>
                    {{ "Information" | translate }}
                </span>
            </div>
            <div class="grid_item" (click)="close('copy link')" ngxClipboard [cbContent]="origin + '/chat/' + data?.chat?.acm" (cbOnSuccess)="copyLink('Chat')">
                <div class="icon">
                    <svg width="20" height="10" viewBox="0 0 20 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15 0H11V2H15C16.65 2 18 3.35 18 5C18 6.65 16.65 8 15 8H11V10H15C17.76 10 20 7.76 20 5C20 2.24 17.76 0 15 0ZM9 8H5C3.35 8 2 6.65 2 5C2 3.35 3.35 2 5 2H9V0H5C2.24 0 0 2.24 0 5C0 7.76 2.24 10 5 10H9V8ZM6 4H14V6H6V4Z" fill="#686868"/>
                    </svg>                        
                </div>
                <span>
                    {{ "Copy link" | translate }}
                </span>
            </div>

            <div class="grid_item full to_not_btn" (click)="close('push')" [ngClass]="{'transp': !(selectedNotEmployees && selectedNotEmployees.length)}">
                <div class="icon">
                    <svg style="width: 23px; height: 24px;" width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17.4545 10.9091C13.2327 10.9091 9.81818 14.3236 9.81818 18.5455C9.81818 18.9164 9.85091 19.2764 9.90545 19.6364H0V18.5455L2.18182 16.3636V9.81818C2.18182 6.43636 4.39636 3.45818 7.63636 2.49818V2.18182C7.63636 0.981818 8.61818 0 9.81818 0C11.0182 0 12 0.981818 12 2.18182V2.49818C15.24 3.45818 17.4545 6.43636 17.4545 9.81818V10.9091ZM7.63636 20.7273C7.63636 21.9382 8.61818 22.9091 9.81818 22.9091C10.2436 22.9091 10.6364 22.7782 10.9745 22.5709C10.6255 22.0036 10.3636 21.3818 10.1455 20.7273H7.63636ZM22.6582 19.9855C22.7673 20.0618 22.7891 20.2145 22.7236 20.3345L21.6327 22.2218C21.5673 22.3636 21.4255 22.3636 21.3055 22.3636L19.9418 21.7855C19.6364 22.0036 19.3636 22.1891 19.0255 22.3309L18.8182 23.7709C18.7964 23.9018 18.6873 24 18.5455 24H16.3636C16.2327 24 16.1127 23.9018 16.0909 23.7709L15.8945 22.3309C15.5455 22.1891 15.2727 22.0036 14.9673 21.7855L13.6364 22.3636C13.4945 22.3636 13.3418 22.3636 13.2764 22.2218L12.1855 20.3345C12.12 20.2145 12.1527 20.0618 12.2509 19.9855L13.4073 19.0909C13.3855 18.9055 13.3636 18.7309 13.3636 18.5455C13.3636 18.36 13.3855 18.1855 13.4073 18L12.2509 17.1055C12.1527 17.0291 12.12 16.9091 12.1855 16.7564L13.2764 14.88C13.3418 14.76 13.4945 14.7273 13.6364 14.76L14.9673 15.2727C15.2727 15.0873 15.5455 14.9018 15.8945 14.7709L16.0909 13.32C16.1127 13.1891 16.2327 13.0909 16.3636 13.0909H18.5455C18.6873 13.0909 18.7964 13.1891 18.8182 13.32L19.0255 14.7709C19.3636 14.9018 19.6364 15.0873 19.9418 15.2727L21.3055 14.76C21.4255 14.7273 21.5673 14.76 21.6327 14.88L22.7236 16.7564C22.7891 16.9091 22.7673 17.0291 22.6582 17.1055L21.5127 18C21.5345 18.1855 21.5455 18.3709 21.5455 18.5455C21.5455 18.72 21.5345 18.9055 21.5127 19.0909L22.6582 19.9855ZM19.3636 18.5455C19.3636 17.4873 18.5127 16.6364 17.4545 16.6364C16.3964 16.6364 15.5455 17.4873 15.5455 18.5455C15.5455 19.6036 16.4073 20.4545 17.4545 20.4545C18.5018 20.4545 19.3636 19.6036 19.3636 18.5455Z" fill="#866CC4"/>
                    </svg>
                </div>
                <div class="not_employees" *ngIf="selectedNotEmployees && selectedNotEmployees.length" [ngStyle]="{'width': selectedNotEmployees.length > 3 ? '90px' : 30 + (selectedNotEmployees.length-1)*15 + 'px'}">
                    <ng-container *ngFor="let item of selectedNotEmployees; let u = index;">
                        <div class="employee" *ngIf="u <= 2" [ngStyle]="{'z-index': selectedNotEmployees.length - u, 'transform': 'translateX(-' + u*15 + 'px)'}">
                            <ng-container *ngIf="item?.image; else elseMobWorkTemplate">
                                <img [src]="item?.image" alt="" >
                            </ng-container>
                            <ng-template #elseMobWorkTemplate>
                            <ng-container *ngIf="item?.is_partner; else elsePartner">
                                <ng-container *ngIf="!!item?.partnerCompany?.avatarFile?.thumbnail && !!host; else elseTemplatePartnerAvatar">
                                <img #tooltip="matTooltip"
                                matTooltip="{{item?.partnerCompany?.name}}"
                                [matTooltipPosition]="'below'" class="partner_avatar" (error)="onPartnerImgError($event)" [src]="host + item?.partnerCompany?.avatarFile?.thumbnail + '?company_id=' + company?.id" alt="">
                                </ng-container>
                                <ng-template #elseTemplatePartnerAvatar>
                                <img #tooltip="matTooltip"
                                matTooltip="{{item?.partnerCompany?.name}}"
                                [matTooltipPosition]="'below'" class="partner_avatar" (error)="onPartnerImgError($event)" src="{{ imgRoute }}assets/img/partner.png" alt="">
                                </ng-template>
                            </ng-container>
                            <ng-template #elsePartner>
                                <mat-icon #tooltip="matTooltip"
                                matTooltip="{{item?.name}}"
                                [matTooltipPosition]="'below'">account_circle</mat-icon>
                            </ng-template>
                            </ng-template>
                        </div>
                        <ng-container *ngIf="selectedNotEmployees.length > 3 && u == selectedNotEmployees.length - 1">
                        <div class="employee employee_plus" [ngStyle]="{'z-index': selectedNotEmployees.length - 3, 'transform': 'translateX(-' + 36 + 'px)'}">
                            +{{selectedNotEmployees.length - 3}}
                        </div>
                        </ng-container>
                    </ng-container>
                </div>
                <span [ngClass]="{'ml-1': selectedNotEmployees && selectedNotEmployees.length}">
                    {{ "Notifications" | translate }}
                </span>
            </div>
        </div>
    </div>
</mat-dialog-content>
