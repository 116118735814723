import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { FileService } from 'src/app/shared/services/rest/file.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { SelectFileComponent } from '../select-file/select-file.component';
import { FormControl } from '@angular/forms';
import { ReplaySubject, empty, forkJoin, of } from 'rxjs';
import { debounceTime, last, map, switchMap, tap } from 'rxjs/operators';
import { ParametersService } from 'src/app/shared/services/rest/parameters.service';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { SnackBarItem } from '../../../snack-bar/snack-bar-item';

@Component({
  selector: 'app-scrn-settings',
  templateUrl: './scrn-settings.component.html',
  styleUrls: ['./scrn-settings.component.scss']
})
export class ScrnSettingsComponent extends BaseClass implements OnInit, OnDestroy {
  public isFormChange: boolean = false;
  public isSubmit: boolean = false;
  public screenSettings: any = JSON.parse(JSON.stringify(this.data.screenSettings));

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private taskService: TaskService,
    public dialogRef: MatDialogRef<ScrnSettingsComponent>,
    private fileService: FileService,
    private parametersService: ParametersService,
    private dialog: MatDialog,
    private layoutService: LayoutService
  ) { super() }

  ngOnInit(): void {
    console.log("ScrnSettingsComponent", this.data)

    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        if (this.isFormChange) {
          this.layoutService.openBottomSheet(this.dialogRef);
        } else {
          this.close();
        }
      })
    )
  }

  onChangeHolst(key, item) {
    console.log('onInputHolst')

    if (key == 'height') {
      if (!+item.mHeight) {
        item.mHeight = this.data.file.meta_height;
      }
      item.mWidth = item.mHeight * item.ratio
    } else {
      if (!+item.mWidth) {
        item.mWidth = this.data.file.meta_width;
      }
      item.mHeight = item.mWidth / item.ratio
    }
  }

  toggleSettings(item) {
    item.is_active = !item.is_active;
    this.checkIsChange()
  }

  checkIsChange() {
    this.isFormChange = true;
    console.log("checkIsChange", this.isFormChange);
  }

  addNewSetting() {
    this.screenSettings.push({
      name: "Size",
      is_active: false,
      mWidth: this.data.file.meta_width,
      mHeight: this.data.file.meta_height,
      filesize: 0,
      ratio: this.data.file.meta_width / this.data.file.meta_height
    })
  }


  log() {
    console.log("data", this.data)
  }

  close() {
    this.dialogRef.close({event: "close", data: false});
  }

  deleteSetting(i) {
    this.screenSettings.splice(i, 1)
  }

  submitForm() {

    if (this.data.is_create) {
      if (this.screenSettings.filter(x => x.is_active).length == 0) {
        this.layoutService.showSnackBar({name: ''}, marker("No active screenshot settings"), SnackBarItem)
        return
      }
      this.isSubmit = true;

      this.attachSubscriptions(
        this.fileService.createVideoProject({
          company_id: this.data.target_company_id,
          task_id: this.data.project.task_id,
          task_operation_id: this.data.project.task_operation_id,
          discussion_id: this.data.project.discussion_id,
          name: this.data.file.filename.replace('.'+this.data.file.extension, ' Screenshots'),
          output_location: this.data.file.location == "/" ? this.data.file.location + `Screenshots of ${this.data.file.filename.replace('.'+this.data.file.extension, '')}` : this.data.file.location + `/Screenshots of ${this.data.file.filename.replace('.'+this.data.file.extension, '')}`,
          is_draft: 1,
          output_width: this.data.project.output_width,
          output_height: this.data.project.output_height,
          output_frame_rate: this.data.project.output_frame_rate,
          output_bit_rate: this.data.project.output_bit_rate,
          waiting_time_limit: this.data.project.waiting_time_limit
        }, this.data.company_id).pipe(
          switchMap(project => {
            return this.fileService.createVideoProjectFile({
              company_id: this.data.target.part.company_id,
              video_project_id: project.id,
              file_id: this.data.target.part.id,
              is_input: 1
            }, this.data.company_id).pipe(
              switchMap(original => {

                let arr = [];

                this.screenSettings.filter(x => x.is_active).forEach(element => {
                  let x:any = {
                    company_id: this.data.target.part.company_id,
                    video_project_id: project.id,
                    input_video_project_file_id: original.id,
                    is_output: 1,
                    is_frame: 1,
                    frame_time_ms: this.data.target.ms,
                  }
                  if (element.name == "Original quality and size") {
                    x.output_filename = 'Screenshot ' + this.data.target.part.filename.replace('.'+this.data.target.part.extension, '');
                    x.is_frame_lossless = 1;
                    arr.push(x)
                  } else {
                    x.frame_resize_side = Math.max(element.mWidth, element.mHeight);
                    if (element.filesize > 0) {
                      x.frame_filesize = Math.round(element.filesize*1000000)
                    }
                    x.output_filename = 'Screenshot w-' + element.mWidth + ' h-' + element.mHeight + " " + (element.filesize > 0 ? element.filesize + " MB ": ' ') + this.data.target.part.filename.replace('.'+this.data.target.part.extension, '');
                    arr.push(x)
                  }
                });

                return forkJoin(arr.map(pData => this.fileService.createVideoProjectFile(pData, this.data.company_id))).pipe(
                  switchMap(outputs => {
                    return this.fileService.editVideoProject(project.id, {
                      is_draft: 0,
                      is_to_process: 1,
                    }, this.data.company_id).pipe(
                      map(editedProject => {
                        return {
                          project: project,
                          original: original,
                          outputs: outputs,
                          editedProject: editedProject
                        }
                      })
                    )
                  })
                )

                // company_id: this.data.target_company_id,
                // video_project_id: project.id,
                // input_video_project_file_id: originals.find(x => x.file_id == blend.id || x.file_id == blend.original_file_id).id,
                // output_filename: `${part.filename.replace('.'+this.data.file.extension, '')}`,
                // part_start_ms: Math.round(blend.from*1000),
                // part_end_ms: Math.round(blend.to*1000),
                // part_speed_rate: blend.part_speed_rate,
                // is_part: 1,
              })
            )
          })
        ).subscribe(resp => {
          console.log("createProject", resp)
          this.isSubmit = false;
          this.dialogRef.close({event: "create", data: this.screenSettings})
        })
      )

    } else {
      this.dialogRef.close({event: "save", data: this.screenSettings})
    }

  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
