<mat-dialog-content class="mat-typography" dirEnter (dirSubmit)="!form.valid || isSubmit ? null : onSubmit()">
    <div class="container form_wrapper">
        <div class="title_modal">
            {{ "Youtube Sftp Dropbox Keys" | translate }}
        </div>

        <ng-container *ngIf="showPublicKey; else elseTemplateShow">
            <p [innerHTML]="data.sftp.public_key"></p>
        </ng-container>
        <ng-template #elseTemplateShow>
            <form [formGroup]="form" class="form_with_pads form_with_valids">
                <mat-form-field appearance="standard" class="full_width_form">
                    <mat-label>{{ 'Public key' | translate }}</mat-label>
                    <textarea 
                    cdkTextareaAutosize
                    #autosize="cdkTextareaAutosize"
                    cdkAutosizeMinRows="2"
                    cdkAutosizeMaxRows="10" formControlName="public_key" matInput></textarea>
                </mat-form-field>
                <mat-form-field appearance="standard" class="full_width_form">
                    <mat-label>{{ 'Private key' | translate }}</mat-label>
                    <textarea 
                    cdkTextareaAutosize
                    #autosize="cdkTextareaAutosize"
                    cdkAutosizeMinRows="2"
                    cdkAutosizeMaxRows="10" formControlName="private_key" matInput></textarea>
                </mat-form-field>
            </form>
        </ng-template>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <div class="f_w_p_btns">
        <button mat-button (click)="close()">
            {{ "Close" | translate }}
        </button>
        <button *ngIf="!showPublicKey" class="ml-1 d-f-btn" (click)="onSubmit()" cdkFocusInitial mat-raised-button [disabled]="isSubmit || !form.valid">
            <mat-spinner class="spin_16 mr-1" *ngIf="isSubmit"></mat-spinner>  
            {{ "Add" | translate }}
        </button>
        <button *ngIf="!!showPublicKey" class="ml-1 d-f-btn" mat-raised-button ngxClipboard [cbContent]="data.sftp.public_key" (cbOnSuccess)="copyPublicKey()">
            <mat-icon class="mr-1">content_copy</mat-icon>
            <span>{{"Copy public key"| translate}}</span>
        </button>
    </div>
</mat-dialog-actions>
