import { NgxMatDateAdapter, NgxMatDatetimePicker } from "@angular-material-components/datetime-picker";
import { AfterViewInit, Component, Inject, OnDestroy, OnInit, QueryList, ViewChildren } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";
import { MatDatepickerInput } from "@angular/material/datepicker";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { marker } from "@biesbjerg/ngx-translate-extract-marker";
import * as moment from "moment";
import { forkJoin, of } from "rxjs";
import { catchError, map, switchMap, tap } from "rxjs/operators";
import { AdditionalEmployeesComponent } from "src/app/components/additionalEmpl/additional-employees/additional-employees.component";
import { MY_FORMATS } from "src/app/components/atTasksDialog/task-profile-add/task-profile-add.component";
import { ExecutorsComponent } from "src/app/components/workspace-pages/cases/dialogs/executors/executors.component";
import { SnackBarItem } from "src/app/shared/global_components/snack-bar/snack-bar-item";
import { BaseClass } from "src/app/shared/models/base-class";
import { LayoutService } from "src/app/shared/services/common/layout.service";
import { ChatService } from "src/app/shared/services/rest/chat.service";
import { MembersService } from "src/app/shared/services/rest/members.service";
import { RefreshService } from "src/app/shared/services/rest/refresh.service";
import { TaskService } from "src/app/shared/services/rest/task.service";
import { SetEmplComponent } from "../../../cases/dialogs/members/set-empl/set-empl.component";


@Component({
  selector: 'app-add-job-to-board',
  templateUrl: './add-job-to-board.component.html',
  styleUrls: ['./add-job-to-board.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})

export class AddJobToBoardComponent extends BaseClass implements OnInit, OnDestroy, AfterViewInit {
  @ViewChildren('picker') picker : QueryList<NgxMatDatetimePicker<any>>;
  public operationStatuses: any;
  public upChat: any;
  public form: FormGroup = this.fb.group({});
  public origin = window.location.origin

  constructor(
    private taskService: TaskService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddJobToBoardComponent>,
    private membersService: MembersService,
    private layoutService: LayoutService,
    private dialog: MatDialog,
    private _ngx_adapter: NgxMatDateAdapter<any>,
    private fb: FormBuilder,
    private refreshService: RefreshService,
    private chatService: ChatService,
  ) { super() }

  ngOnInit(): void {
    console.log("AddJobToBoardComponent", this.data)
    this.getOperationsStatus();
    if (this.data.employees) {
      this.data.employees.forEach((empl, i) => {
        if (!!empl.is_partner) {
          this.form.addControl("start_at-"+i, this.fb.control(!!empl?.partnerCompanyStatus && empl?.partnerCompanyStatus.start_at && empl?.partnerCompanyStatus.start_at > 1 ? moment(empl?.partnerCompanyStatus.start_at*1000) : ''));
        } else {
          this.form.addControl("start_at-"+i, this.fb.control(!!empl?.employeeStatus && empl?.employeeStatus.start_at && empl?.employeeStatus.start_at > 1 ? moment(empl?.employeeStatus.start_at*1000) : ''));
        }
      })
      this.form.updateValueAndValidity();
    }
    
    this._ngx_adapter.setLocale(this.data.activeLang);
  }

  additionalEmployees() {
    const dialogRef = this.dialog.open(AdditionalEmployeesComponent, {
      disableClose: true,
      data: {
        user: this.data.user,
        company: this.data.company,
        task: this.data.task,
        work: this.data.job,
        initialAdd: true
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        this.refreshService.refreshJob$.next({company_id: this.data.company.id, job_id: this.data.job.id})
        this.updateBoard()
      })
    )
  }

  onPartnerImgError(event){
    event.target.src = this.data.imgRoute + '/assets/img/partner.png'
  }

  updateBoard() {
    let arr = [
      this.membersService.getMembers({task_id: this.data.job.task_id, task_operation_id: this.data.job.id}, this.data.company.id),
      this.membersService.getTaskPartners({task_id: this.data.job.task_id, task_operation_id: this.data.job.id}, this.data.company.id).pipe(
        tap(empls => {
          empls.map(b => {
            b.is_partner = true;
          })
        })
      )
    ]
    if (this.data.company && this.data.company.id != this.data.job.company_id) {
      arr.push(
        this.membersService.getTaskPartnerEmployees({task_id: this.data.job.task_id, task_operation_id: this.data.job.id}, this.data.company.id, this.data.job.company_id).pipe(
          tap(empls => {
            empls.map(b => {
              b.is_partner_employee = true;
              b.employee = b.partnerEmployee;
              b.employeeStatus = b.partnerEmployeeStatus;
            })
          })
        )
      )
    }

    this.attachSubscriptions(
      forkJoin(arr).subscribe(resp => {
        console.log("getDiscussionEmployees", resp);

        this.data.employees = []
        resp.forEach(el => {
          this.data.employees.push(...el)
        })

        this.form = this.fb.group({});
        this.data.employees.forEach((empl, i) => {
          if (!!empl.is_partner) {
            this.form.addControl("start_at-"+i, this.fb.control(!!empl?.partnerCompanyStatus && empl?.partnerCompanyStatus.start_at && empl?.partnerCompanyStatus.start_at > 1 ? moment(empl?.partnerCompanyStatus.start_at*1000) : ''));
          } else {
            this.form.addControl("start_at-"+i, this.fb.control(!!empl?.employeeStatus && empl?.employeeStatus.start_at && empl?.employeeStatus.start_at > 1 ? moment(empl?.employeeStatus.start_at*1000) : ''));
          }
        })
        this.form.updateValueAndValidity()
        console.log("BOARD this.data.employees", this.data.employees)
      })
    )

  }

  ngAfterViewInit(): void {
    // if (this.data.employees.length == 1 && this.picker) {
    //   setTimeout(() => {this.picker['_results'][0].open()},200)
    // }
  }

  openPicker(picker:NgxMatDatetimePicker<any>) {
    picker.open()
  }

  addExecutor() {
    const dialogRef = this.dialog.open(SetEmplComponent, {
      data: {
        company: this.data.company,
        user: this.data.user,
        task: this.data.task,
        work: this.data.job,
        executors: true
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        this.refreshService.refreshJob$.next({company_id: this.data.company.id, job_id: this.data.job.id})
        this.updateBoard()
      })
    )
  }

  openExecutors() {
    const dialogRef = this.dialog.open(ExecutorsComponent, {
      panelClass: 'open_task_dialog',
      data: {
        company: this.data.company,
        user: this.data.user,
        task: this.data.task,
        activeLang: this.data.activeLang,
        job: this.data.job,
        host: this.data.host,
        statuses: this.data.statuses,
        empl_status: this.data.empl_status,
        operationsValues: this.data.operationsValues
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        this.refreshService.refreshJob$.next({company_id: this.data.company.id, job_id: this.data.job.id})
        this.updateBoard()
        console.log(this.data)
      })
    )
  }

  changeStartAt(e, empl, planing:boolean = false) {
    console.log(e, empl)

    let submData:any;

    if (!planing) {
      submData = {
        start_at: e.value.unix(),
        initial_start_at: e.value.unix()
      };
    } else {
      submData = {
        start_at: 1,
        initial_start_at: 1
      };
    }

    if (!!empl.is_partner) {
      this.attachSubscriptions(
        this.membersService.editPartnerStatus(empl.partnerCompanyStatus.id, submData, this.data.company.id).subscribe(resp => {
          let ind = this.data.employees.indexOf(empl);
          if (resp.start_at > 1) {
            this.form.patchValue({
              ['start_at-'+ind]: moment(resp.start_at*1000)
            })
          }
          empl.partnerCompanyStatus.start_at = resp.start_at
          empl.partnerCompanyStatus.initial_start_at = resp.initial_start_at
        },
        error => {
          this.showError(error)
        })
      )
    } else if (!!empl.is_partner_employee) {
      this.attachSubscriptions(
        this.membersService.editPartnerEmployeeStatus(empl.employeeStatus.id, submData, this.data.company.id).subscribe(resp => {
          let ind = this.data.employees.indexOf(empl);
          if (resp.start_at > 1) {
            this.form.patchValue({
              ['start_at-'+ind]: moment(resp.start_at*1000)
            })
          }
          empl.employeeStatus.start_at = resp.start_at
          empl.employeeStatus.initial_start_at = resp.initial_start_at
        },
        error => {
          this.showError(error)
        })
      )
    } else {
      this.attachSubscriptions(
        this.membersService.editTaskEmployeeStatus(empl.employeeStatus.id, submData, this.data.company.id).subscribe(resp => {
          let ind = this.data.employees.indexOf(empl);
          if (resp.start_at > 1) {
            this.form.patchValue({
              ['start_at-'+ind]: moment(resp.start_at*1000)
            })
          }
          empl.employeeStatus.start_at = resp.start_at
          empl.employeeStatus.initial_start_at = resp.initial_start_at
        },
        error => {
          this.showError(error)
        })
      )
    }

  }

  getOperationsStatus() {
    this.attachSubscriptions(
      this.taskService.getOperationsStatus().subscribe(resp => {
        console.log("operationStatuses", resp)
        this.operationStatuses = resp.slice();
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  deleteStartAt(empl) {
    if (!!empl.is_partner) {
      this.attachSubscriptions(
        this.membersService.editPartnerStatus(empl.partnerCompanyStatus.id, {start_at: 0}, this.data.company.id).subscribe(resp => {
          // let ind = this.data.employees.indexOf(empl);
          // this.form.patchValue({
          //   ['start_at-'+ind]: moment(resp.start_at*1000)
          // })
          empl.partnerCompanyStatus.start_at = 0
        },
        error => {
          this.showError(error)
        })
      )
    } else if (!!empl.is_partner_employee) {
      this.attachSubscriptions(
        this.membersService.editPartnerEmployeeStatus(empl.employeeStatus.id, {start_at: 0}, this.data.company.id).subscribe(resp => {
          // let ind = this.data.employees.indexOf(empl);
          // this.form.patchValue({
          //   ['start_at-'+ind]: moment(resp.start_at*1000)
          // })
          empl.employeeStatus.start_at = 0
        },
        error => {
          this.showError(error)
        })
      )
    } else {
      this.attachSubscriptions(
        this.membersService.editTaskEmployeeStatus(empl.employeeStatus.id, {start_at: 0}, this.data.company.id).subscribe(resp => {
          // let ind = this.data.employees.indexOf(empl);
          // this.form.patchValue({
          //   ['start_at-'+ind]: moment(resp.start_at*1000)
          // })
          empl.employeeStatus.start_at = 0
        },
        error => {
          this.showError(error)
        })
      )
    }
  }

  showError(error?) {
    this.layoutService.showSnackBar({name: ''}, marker(error ? error + ". You do not have permission to do this." : "You do not have permission to do this"), SnackBarItem)
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

}
