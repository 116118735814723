import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { AuthService } from 'src/app/shared/services/rest/auth.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { BaseClass } from 'src/app/shared/models/base-class';
import { MatSelect } from '@angular/material/select';
import { GlobalDataService } from 'src/app/shared/services/common/global-data.service';
import { environment } from 'src/environments/environment';
import { Subscription, concat, forkJoin } from 'rxjs';
import { ParametersService } from '../../services/rest/parameters.service';
import { concatMap, last, map, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-tag-task',
  templateUrl: './tag-task.component.html',
  styleUrls: ['./tag-task.component.scss']
})


export class TagTaskComponent extends BaseClass implements OnInit, OnDestroy {
  public host: any = environment.host;
  public imgRoute: any = '';
  
  public activeIndex: number = 0;
  public apiTips: any;
  public rootTips: any;
  
  public wParameters: any;
  public wParamDataSub: Subscription;
  public rParameters: any;
  public rParamDataSub: Subscription;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<TagTaskComponent>,
    private sm: StorageManagerService,
    private auth: AuthService,
    private companyService: CompanyService,
    private parametersService: ParametersService,
    private globalDataService: GlobalDataService,
    private fb: FormBuilder,
    private taskService: TaskService,
    private layoutService: LayoutService,
    private languageService: LanguageService,
  ) { super() }

  ngOnInit(): void {
    console.log("TagTaskComponent", this.data)
    this.getImgRoute();

    this.wParamDataSub = this.parametersService.getParamsStream().pipe(
      concatMap(params => {
        return concat(...params.map(oneParam => this.neededParametersData(oneParam))).pipe(last(),map(x => params))
      }),
    ).subscribe(resp => console.log("-----getParamsStream-----",resp));
    this.getParameters();

    if (this.data.user.is_root == 1) {
      this.rParamDataSub = this.parametersService.getRootParamsStream().pipe(
        concatMap(params => {
          return concat(...params.map(oneParam => this.neededRootParametersData(oneParam))).pipe(last(),map(x => params))
        }),
      ).subscribe(resp => console.log("-----getParamsStream-----",resp));
      this.getRootParameters();
    }
  }

  getRootParameters() {
    let data:any = {company_id: 0};

    this.attachSubscriptions(
      this.parametersService.getRootTips(this.data.company.id, {company_id: 0, task_id: this.data.task.id}).pipe(
        tap(tips => {
          this.rootTips = tips;
        }),
        switchMap(tips => {
          return this.parametersService.getRootParameters(data).pipe(
            tap(res => {
              this.parametersService.rParamsData$.next(res)
            }),
            map((res) => res)
          )
        })
      ).subscribe(resp => {
        this.rParameters = resp
        this.rParameters.forEach(param => {
          param.tips = this.rootTips.filter(x => x.parameter_id == param.id && x.parameter_value_id == 0)
        });
        console.log("this.parameters", this.rParameters)
      })
    )
  }

  getParameters() {
    this.attachSubscriptions(
      this.parametersService.getApiTips(this.data.company.id, {company_id: this.data.company.id, task_id: this.data.task.id}).pipe(
        tap(tips => {
          this.apiTips = tips;
        }),
        switchMap(tips => {
          return this.parametersService.getApiParameters(this.data.company.id).pipe(
            tap(res => {
              this.parametersService.paramsData$.next(res)
            }),
            map((res) => res)
          )
        })
      ).subscribe(resp => {
        this.wParameters = resp
        this.wParameters.forEach(param => {
          param.tips = this.apiTips.filter(x => x.parameter_id == param.id && x.parameter_value_id == 0)
        });
        console.log("this.parameters", this.wParameters)
        console.log("this.apiTips", this.apiTips)
      })
    )
  }

  neededRootParametersData(param) {
    let arr = []
    let data:any = {company_id: 0};

    arr.push(
      this.parametersService.getRootParameterValues(param.id, data).pipe(
        tap(x => {
          param.values = x;
          param.values.forEach(val => {
            val.tips = this.rootTips.filter(x => x.parameter_value_id == val.id)
          });
        })
      )
    )

    return forkJoin(arr)
  }

  neededParametersData(param) {
    let arr = []

    arr.push(
      this.parametersService.getApiParameterValues(this.data.company.id, param.id).pipe(
        map(y => y.body),
        tap(x => {
          param.values = x;
          param.values.forEach(val => {
            val.tips = this.apiTips.filter(x => x.parameter_value_id == val.id)
          });
        })
      )
    )

    // arr.push(
    //   this.parametersService.getApiTips(this.data.company.id, {company_id: this.data.company.id, parameter_id: param.id}).pipe(
    //     tap(x => {
    //       param.tips = x;
    //     })
    //   )
    // )

    return forkJoin(arr)
  }

  changeWParam(e, param) {
    console.log("changeWParam", e, param)
    if (e.checked) {
      this.addApiTip(e, param)
    } else {
      this.deleteApiTips(e, param)
    }
  }

  changeWVal(e, param, val) {
    console.log("changeWVal", e, param, val)
    if (e.checked) {
      this.addApiTip(e, param, val)
    } else {
      this.deleteApiTips(e, param, val)
    }
  }

  changeRParam(e, param) {
    console.log("changeRParam", e, param)
    if (e.checked) {
      this.addRootTip(e, param)
    } else {
      this.deleteRootTips(e, param)
    }
  }

  changeRVal(e, param, val) {
    console.log("changeRVal", e, param, val)
    if (e.checked) {
      this.addRootTip(e, param, val)
    } else {
      this.deleteRootTips(e, param, val)
    }
  }

  deleteApiTips(e, param, value?) {
    let arr = !!value ? value.tips : param.tips;
    this.attachSubscriptions(
      forkJoin(arr.map(tip => this.parametersService.deleteApiTip(tip.id))).subscribe(resp => {
        console.log("deleteApiTips", resp)
        if (value) {
          value.isEddited = false;
          value.tips = [];
        } else {
          param.isEddited = false;
          param.tips = [];
        }
      }, error => {
        if (value) {
          value.isEddited = false;
        } else {
          param.isEddited = false;
        }
        e.source.toggle();
      })
    )
  }

  deleteRootTips(e, param, value?) {
    let arr = !!value ? value.tips : param.tips;
    this.attachSubscriptions(
      forkJoin(arr.map(tip => this.parametersService.deleteRootTip(tip.id))).subscribe(resp => {
        console.log("deleteRootTips", resp)
        if (value) {
          value.isEddited = false;
          value.tips = [];
        } else {
          param.isEddited = false;
          param.tips = [];
        }
      }, error => {
        if (value) {
          value.isEddited = false;
        } else {
          param.isEddited = false;
        }
        e.source.toggle();
      })
    )
  }

  addApiTip(e, param, value?) {
    if (value) {
      value.isEddited = true;
    } else {
      param.isEddited = true;
    }
    let sendData = {
      company_id: this.data.company.id,
      parameter_id: param.id,
      parameter_value_id: !!value ? value.id : 0,
      task_id: this.data.task.id
    }

    this.attachSubscriptions(
      this.parametersService.addApiTip(sendData).subscribe(resp => {
        console.log("editApiParameterValue", resp)
        if (value) {
          value.isEddited = false;
          value.tips.push(resp)
        } else {
          param.isEddited = false;
          param.tips.push(resp)
        }
      }, error => {
        if (value) {
          value.isEddited = false;
        } else {
          param.isEddited = false;
        }
        e.source.toggle();
      })
    )
  }
  

  addRootTip(e, param, value?) {
    if (value) {
      value.isEddited = true;
    } else {
      param.isEddited = true;
    }
    let sendData = {
      company_id: 0,
      parameter_id: param.id,
      parameter_value_id: !!value ? value.id : 0,
      task_id: this.data.task.id
    }
    this.attachSubscriptions(
      this.parametersService.addRootTip(sendData).subscribe(resp => {
        console.log("editApiParameterValue", resp)
        if (value) {
          value.isEddited = false;
          value.tips.push(resp)
        } else {
          param.isEddited = false;
          param.tips.push(resp)
        }
      }, error => {
        if (value) {
          value.isEddited = false;
        } else {
          param.isEddited = false;
        }
        e.source.toggle();
      })
    )
  }

  changeTab(val) {
    console.log(val);
    this.activeIndex = val;
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false});
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
