
<div class="link_page">
    <div class="link_info" *ngIf="!is_load">
        <ng-container *ngIf="link?.companyes && link?.companyes?.length > 0; else elseTemplateHasAcssess">
            <div class="title">
                {{ "Chat" | translate }} <ng-container *ngIf="m_id">{{ "post" | translate }}</ng-container>: {{ link?.meta?.title }}
            </div>
            <div class="msg">
                {{ "Select the company in which you want to open" | translate }}.
            </div>
            <div class="companyes_wrp">
                <div class="company" *ngFor="let company of link?.companyes" (click)="openInCompany(company)">
                    <ng-container *ngIf="!!company?.avatarFile?.thumbnail && !!host; else elseTemplatePartnerAvatar">
                        <img class="partner_avatar" (error)="onPartnerImgError($event)" [src]="host + company?.avatarFile?.thumbnail" alt="">
                    </ng-container>
                    <ng-template #elseTemplatePartnerAvatar>
                        <img class="partner_avatar" (error)="onPartnerImgError($event)" src="{{ imgRoute }}assets/img/partner.png">
                    </ng-template>
                    <span>{{company.name}}</span>
                </div>
            </div>  
        </ng-container>
        <ng-template #elseTemplateHasAcssess>
            <ng-container *ngIf="link && link.user && link.user.id; else elseTemplateIsAuth">
                <div class="d-f-c">
                    <div class="user_div">
                        <img *ngIf="link.user.image" [src]="link.user.image" alt="">
                        <span>
                            {{link?.user?.display_name}} 
                        </span>
                    </div>
                    <b class="ml-1" style="font-size: 18px; font-weight: 400;">
                        {{ "You don't have access" | translate }} 
                    </b>
                </div>
                <p>{{"Request access from the one who provided this link." | translate}}</p>
            </ng-container>
            <ng-template #elseTemplateIsAuth>     
                <div class="msg red">
                    {{ "You do not have access to this content, please" | translate }} 
                    <a class="nav_link" title="{{ 'Log_in' | translate }}" href="{{ host }}/user/auth/?action=lastauth&amp;returnUrl={{ linkStr }}">{{ "Login" | translate }}</a>
                     {{ "or" | translate }} 
                    <a class="nav_link" href="{{host}}/user/auth/">{{ "Change User" | translate }}</a>
                </div>
            </ng-template>
        </ng-template>
        <div class="btns">
            <button mat-stroked-button (click)="toMainPage()">
                {{ "To main page" | translate }}
            </button>
        </div>
    </div>
</div>
<mat-progress-bar mode="indeterminate" *ngIf="is_load" class="mat_proggress_w8"></mat-progress-bar>