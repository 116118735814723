import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { forkJoin, fromEvent, of, ReplaySubject } from 'rxjs';
import { debounceTime,last,map,switchMap,tap } from 'rxjs/operators';
import { prioritys } from 'src/app/shared/consts/prioritys';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { ScenariosService } from 'src/app/shared/services/rest/scenarios.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { OpenTaskComponent } from '../../workspace-pages/cases/dialogs/open-task/open-task.component';
import { CdkDragEnter, CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { MembersService } from 'src/app/shared/services/rest/members.service';
@Component({
  selector: 'app-mob-add-task-use-template',
  templateUrl: './mob-add-task-use-template.component.html',
  styleUrls: ['./mob-add-task-use-template.component.scss']
})

export class MobAddTaskUseTemplateComponent extends BaseClass implements OnInit, OnDestroy {

  public savedLvl2: number = 0;


  public values: any;
  public parameters: any;
  public allValues: any;
  public activeTemplate: any;

  public templateForm: FormGroup;
  public statuses: any;
  public prioritys: any = prioritys;
  public groups: any;
  public page: number = 1;
  public pagination: any;
  public taskTemplates: any;
  public customIdValue: number;
  public isGetId: boolean = false;
  public isFormChange: boolean = false;
  public relations: boolean = false;
  public showGroups: boolean = false;
  public submited: boolean = false;
  public submitedTmpl: boolean = false;
  public templatesIsActive: boolean = false;
  public showTypes: boolean = false;
  public thumbIsOpen: boolean = false;
  public jobsIsOpen: boolean = false;
  public is_mobile: boolean = false;
  public tasks: any[] = [];
  public isSubmit: boolean = false;
  public isSubmitTmpl: boolean = false;
  public taskActivatedId: number = 0;



  public isAddConnections: FormControl = new FormControl(0);

  public selectedIndex: FormControl = new FormControl(0);
  public aboutIndex: FormControl = new FormControl(0);
  public relationsIndex: FormControl = new FormControl(0);

  public consistOfIndex: FormControl = new FormControl(1);
  public partOfIndex: FormControl = new FormControl(1);
  public relatedIndex: FormControl = new FormControl(1);

  public typeOfSearchControl: FormControl = new FormControl('Everywhere');
  public consistOfControl: FormControl = new FormControl();
  public partOfControl: FormControl = new FormControl();
  public relativeControl: FormControl = new FormControl();
  public consistOfControlTemplate: FormControl = new FormControl();
  public partOfControlTemplate: FormControl = new FormControl();
  public relativeControlTemplate: FormControl = new FormControl();

  public groupMoreControl: FormControl = new FormControl();
  public groupOfSearchControl: FormControl = new FormControl('');

  public groupMoreControlTemplate: FormControl = new FormControl();
  public groupOfSearchControlTemplate: FormControl = new FormControl('');

  public tasks$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public groups$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public operationStatuses: any;

  public employees: any;
  public employeeMoreControl: FormControl = new FormControl();
  public employees$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public operations: any;
  public operationMoreControl: FormControl = new FormControl();
  public operations$: ReplaySubject<any> = new ReplaySubject<any>(1);
  
  public jobs:any = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<MobAddTaskUseTemplateComponent>,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private sm: StorageManagerService,
    private membersService: MembersService,
    private taskService: TaskService,
    private companyService: CompanyService,
    private scenariosService: ScenariosService,
    private layoutService: LayoutService
  ) { super() }

  ngOnInit(): void {
    this.dialogRef.addPanelClass("create_task_by_manager_modal")

    console.log("MobAddTaskUseTemplateComponent", this.data)

    this.templateForm = this.fb.group({
      consist_of_task_id: this.fb.group({
        add: [[]]
      }),
      part_of_task_id: this.fb.group({
        add: [[]]
      }),
      related_task_id: this.fb.group({
        add: [[]]
      })
    })

    this.checkIsMobile();
    this.getTaskTemplates();

    this.attachSubscriptions(
      this.consistOfControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchTasks(resp, this.typeOfSearchControl.value))
    )

    this.attachSubscriptions(
      this.partOfControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchTasks(resp, this.typeOfSearchControl.value))
    )

    this.attachSubscriptions(
      this.relativeControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchTasks(resp, this.typeOfSearchControl.value))
    )

    this.attachSubscriptions(
      this.consistOfControlTemplate.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchTasks(resp, this.typeOfSearchControl.value))
    )

    this.attachSubscriptions(
      this.partOfControlTemplate.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchTasks(resp, this.typeOfSearchControl.value))
    )

    this.attachSubscriptions(
      this.relativeControlTemplate.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchTasks(resp, this.typeOfSearchControl.value))
    )

    this.attachSubscriptions(
      this.typeOfSearchControl.valueChanges.subscribe((resp) => this.onSearchTasks(this.partOfControl.value || this.consistOfControl.value || this.relativeControl.value, resp))
    )

    this.attachSubscriptions(
      this.groupMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchGroups(resp))
    )

    this.attachSubscriptions(
      this.templateForm.valueChanges.subscribe(() => this.isFormChange = true)
    )

    this.attachSubscriptions(
      this.employeeMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchEmoloyees(resp))
    )
    this.attachSubscriptions(
      this.operationMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchOperations(resp))
    )

    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        if (this.isFormChange) {
          this.layoutService.openBottomSheet(this.dialogRef);
        } else {
          this.close();
        }
      })
    )

    this.getTaskStatus();

    this.getGroupsCompany()

    this.getTasks(this.page);

    this.getOperationsStatus();
    this.getEmployees();
    this.getOperations();
  
  }

  getGroupsCompany() {
    this.attachSubscriptions(
      this.companyService.getInfiniteGroupsCompany(this.data.company_id, '1', '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.companyService.getInfiniteGroupsCompany(this.data.company_id, x).pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        this.groups = [].concat(...res);
        this.groups$.next(this.groups.slice());
      })
    )
  }

  back() {
    this.selectedIndex.patchValue(this.selectedIndex.value - 1)
  }

  moveUp(arr, id, i) {
    if (i == 0) {
      return
    }

    moveItemInArray(arr, i, i - 1)
  }

  moveDown(arr, id, i) {
    if (i == arr.length - 1) {
      return
    }

    moveItemInArray(arr, i, i + 1)
  }

  next(val) {
    switch (val) {
      case 0:
        this.selectedIndex.patchValue(this.selectedIndex.value + 1)
        break;

      case 1:
        if (!this.activeTemplate.template_data.name) {
          this.submited = true;
          // this.layoutService.showSnackBar({name: ''}, marker("You need to fill in all required fields"), SnackBarItem)
          return false
        }
        this.submited = false;
        if (!!this.isAddConnections.value) {
          this.selectedIndex.patchValue(this.selectedIndex.value + 1)
        } else {
          this.createTaskByTemplate(this.activeTemplate)
        }
        break;

      case 2:
        this.createTaskByTemplate(this.activeTemplate)
        break;
    
      default:
        break;
    }
  }

  backAbout() {
    this.aboutIndex.patchValue(0)
  }
  
  cancelAbout() {
    
    this.backAbout();
  }

  backRelations() {
    this.relationsIndex.patchValue(0)
  }

  smartBackRelations() {
    this.relationsIndex.patchValue(this.savedLvl2)
  }
  
  changeRelationsTab(val) {
    if (val == 0) {
      this.savedLvl2 = 0
    }
    this.taskActivatedId = 0

    this.relationsIndex.patchValue(val);
  }

  changeConsistOfTab(val) {
    this.taskActivatedId = 0
    this.consistOfIndex.patchValue(val);
  }

  changePartOfTab(val) {
    this.taskActivatedId = 0
    this.partOfIndex.patchValue(val);
  }

  changeRelatedTab(val) {
    this.taskActivatedId = 0
    this.relatedIndex.patchValue(val);
  }

  changeAboutTab(val) {
    this.aboutIndex.patchValue(val);
  }

  changeIndex(val) {
    this.selectedIndex.patchValue(val);
  }

  selectTypeSearch(val) {
    this.typeOfSearchControl.patchValue(val);
  }

  selectGroupSearch(val) {
    this.groupOfSearchControl.patchValue(val);
  }

  getEmployeeById(id) {
    return this.employees.find(x => x.id == id)
  }
  
  getGroupById(id) {
    if (!this.groups || this.groups.filter(x => x.id == id).length == 0) {
      return;
    }

    return this.groups.find(x => x.id == id)
  }

  deleteExecutor(job, ind) {
    job.create_task_employees.splice(ind,1);
  }

  onSearchEmoloyees(resp) {
    if (!this.employees) {
      return;
    }

    if (!resp) {
      this.employees$.next(this.employees.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.employees$.next(
      this.employees.filter(z => z.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  getOperationsStatus() {
    this.attachSubscriptions(
      this.taskService.getOperationsStatus().subscribe(resp => {
        this.operationStatuses = resp.slice();
      })
    )
  }

  onSearchOperations(resp) {
    if (!this.operations) {
      return;
    }

    if (!resp) {
      this.operations$.next(this.operations.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.operations$.next(
      this.operations.filter(z => (z.name.toLowerCase().indexOf(resp) > -1))
    );
  }

  checkIfDisabled(job, execId) {
    if (job.create_task_employees.filter(x => x.employee_id == execId).length > 0) {
      return true
    } else {
      return false
    }
  }

  deleteJob(i) {
    this.jobs.splice(i,1);
  }

  addExecutor(job) {
    if (!job.newExecutor.employee_id) {
      this.layoutService.showSnackBar({name: ''}, marker("Choose an executor"), SnackBarItem)
      return;
    }
    job.create_task_employees.push(job.newExecutor);
    job.newExecutor = {
      employee_id: '',
      is_manager: 1
    }
  }

  openThumbnail() {
    this.thumbIsOpen = !this.thumbIsOpen
  }

  openJobs() {
    this.jobsIsOpen = !this.jobsIsOpen
  }
  
  getEmployees() {
    this.attachSubscriptions(
      this.taskService.getEmployees(this.data.company.id).subscribe(resp => {
        console.log('getEmployees', resp)
        this.employees = resp;
        this.employees$.next(this.employees.slice())
      })
    )
  }

  getOperations() {
    this.attachSubscriptions(
      this.taskService.getOperations(this.data.company.id).subscribe(resp => {
        this.operations = resp;
        this.operations$.next(this.operations.slice())
      })
    )
  }

  addJob() {
    let jobData:any = {
      operation_id: 1,
      status_id: 1,
      priority: 0,
      name: '',
      comment: '',
      create_task_employees: []
    }

    if (this.employees.length > 0) {
      jobData.newExecutor = {
        employee_id: '',
        is_manager: 1
      }
    }

    this.jobs.push(jobData)
  }

  onRemovedTemplate(value: string, key) {
    const values = this.templateForm.get(key).get('add').value as string[];
    this.removeFirst(values, value);
    this.templateForm.get(key).get('add').setValue(values); // To trigger change detection

    console.log(this.templateForm.value)
  }

  selected(id, input: HTMLInputElement, key) {
    let addArr = this.templateForm.get(key).get('add').value.slice();

    if (!addArr.includes(id)) {
      addArr.push(id)
    }
    this.templateForm.get(key).patchValue({
      add: addArr
    })

    input.value = ''

    this.tasks$.next(this.tasks.slice());
  }

  selectedTemplate(e, input: HTMLInputElement, key) {
    console.log("selected", e)
    let addArr = this.templateForm.get(key).get('add').value.slice();

    if (!addArr.includes(e.option.value)) {
      addArr.push(e.option.value)
    }
    this.templateForm.get(key).patchValue({
      add: addArr
    })

    input.value = ''

    this.tasks$.next(this.tasks.slice());
  }

  // drop(e) {
  //   moveItemInArray(this.form.get('consist_of_task_id').get('add').value, e.previousIndex, e.currentIndex);
  //   console.log(this.form.get('consist_of_task_id').get('add').value)
  // }

  getTaskTemplates() {
    this.attachSubscriptions(
      this.scenariosService.getTaskTemplates('1', {company_id: this.data.company_id}, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.scenariosService.getTaskTemplates(x, {company_id: this.data.company_id}, '200').pipe(map(u => u.body)))).pipe(
            last(),
            tap(values => {
              this.taskTemplates = [].concat(...values)
              console.log("taskTemplates", this.taskTemplates)
            }),
          )
        }),
      ).subscribe(resp => {
        console.log("getTaskTemplates sub", resp);
      })
    )
  }

  toggleTemplates() {
    this.templatesIsActive = !this.templatesIsActive
  }

  checkIsMobile() {
    if (window.innerWidth <= 769) {
      this.is_mobile = true;
    } else {
      this.is_mobile = false;
    }
    this.onResize();
  }

  onResize() {
    this.attachSubscriptions(
      fromEvent(window, "resize").pipe(
        map(() => window.innerWidth)
      ).subscribe((wWidth) => {
          if (wWidth <= 769) {
            this.is_mobile = true;
          } else {
            this.is_mobile = false;
          }
        }
      )
    )
  }

  onSearchTasks(resp, query) {    
    if (!this.tasks) {
      return;
    }
    
    if (!resp || typeof resp != 'string') {
      this.tasks$.next(this.tasks.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    
    // filter the banks
    switch (query) {
      case 'Everywhere':
        this.tasks$.next(
          this.tasks.filter(z => z.id == resp || z.custom_id == resp || z.internal_id == resp || (z.name && z.name.toLowerCase().indexOf(resp) > -1))
        );
        break;
      case 'Task Name':
        this.tasks$.next(
          this.tasks.filter(z => z.name && z.name.toLowerCase().indexOf(resp) > -1)
        );
        break;
      case 'Custom ID':
        this.tasks$.next(
          this.tasks.filter(z => z.custom_id == resp)
        );
        break;
      case 'Internal ID':
        this.tasks$.next(
          this.tasks.filter(z => z.internal_id == resp)
        );
        break;
      case 'Global ID':
        this.tasks$.next(
          this.tasks.filter(z => z.id == resp)
        );
        break;
    }

  }

  onSearchGroups(resp) {
    if (!this.groups) {
      return;
    }

    if (!resp) {
      this.groups$.next(this.groups.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.groups$.next(
      this.groups.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  getTasks(page) {
    this.attachSubscriptions(
      this.taskService.getTasksSelect(page, this.data.company_id).pipe(
        tap(el => {
          this.pagination = {
            'pageCount': el.headers.get('x-pagination-page-count'),
            'perPage': el.headers.get('x-pagination-per-page'),
            'totalCount': el.headers.get('x-pagination-total-count'),
            'currentPage': el.headers.get('x-pagination-current-page'),
          }
        })
      ).subscribe(resp => {
        this.tasks.push(...resp.body)
        
        this.page = this.page + 1; 

        console.log(this.pagination, this.tasks);
        if (+this.pagination['currentPage'] != +this.pagination['pageCount'] && +this.pagination['pageCount'] > 0) {
          this.getTasks(this.page);
        } else {
          this.tasks$.next(this.tasks.slice())
        }

      })
    )
  }

  getTaskStatus() {
    this.attachSubscriptions(
      this.taskService.getTaskStatuses(this.data.company_id).subscribe(resp => {
        console.log("getTaskStatuses", resp)
        this.statuses = resp;
      })
    )
  }
  

  showMoreGroups() {
    this.showGroups = true;
  }

  showMoreTypes() {
    this.showTypes = true;
  }

  close() {
    this.dialogRef.removePanelClass('animate__slideInUp')
    this.dialogRef.addPanelClass('animate__slideOutDown')
    setTimeout(()=>{this.dialogRef.close({event: "close", data: false})}, 300);
  }

  findTask(id) {
    if (this.tasks.find(x => x.id == id)) {
      return this.tasks.find(x => x.id == id)
    }
  }
  
  onRemoved(value: string, key) {
    const values = this.templateForm.get(key).get('add').value as string[];
    this.removeFirst(values, value);
    this.templateForm.get(key).get('add').setValue(values); // To trigger change detection

    console.log(this.templateForm.value)
  }

  private removeFirst<T>(array: T[], toRemove: T): void {
    const index = array.indexOf(toRemove);
    if (index !== -1) {
      array.splice(index, 1);
    }
  }

  openTask(task) {
    let taskData:any = {
      task_id: task.id,
      initCompanyId: this.data.company.id
    }
    if (task.hasOwnProperty("operations")) {
      taskData.task = task
    }
    const dialogRef = this.dialog.open(OpenTaskComponent, {
      backdropClass: 'backdrop_under_header',
      panelClass: !this.is_mobile ? ['open_task_dialog', 'show_header'] : 'open_task_dialog',
      autoFocus: false,
      data: taskData
    });
  }

  onPartnerImgError(event){
    event.target.src = this.data.imgRoute+'/assets/img/partner.png'
  }

  selectTmpl(tmpl) {
    this.activeTemplate = tmpl;
    this.next(0)
  }

  createTaskByTemplate(tmpl) {
    if (tmpl.template_data.name == '') {
      this.submitedTmpl = true;
      return
    }

    this.submitedTmpl = false;
    this.isSubmitTmpl = true;

    console.log(tmpl);
    this.attachSubscriptions(
      this.taskService.createTask(this.data.company_id, Object.assign(this.templateForm.value, tmpl.template_data, {created_task_template_id: tmpl.id}, !this.data.file_ids ? {} : {created_file_id: this.data.file_ids[0]})).pipe(
        switchMap(task => {
          if (!this.data.file_ids) {
            return of(task)
          } else {
            return this.taskService.getWorksIds(this.data.company.id, task.id).pipe(
              map(jobs => jobs.map(x => x.id)),
              switchMap(job_ids => {
  
                if (!!job_ids.length) {
                  let postData = [];
                  
                  job_ids.forEach(job_id => {
                    this.data.file_ids.forEach(file_id => {     
                      postData.push({
                        "path": `/api/file/copy/`,
                        "query": {company_id: this.data.company.id},
                        "method": "POST",
                        "body": {
                          location: '/',
                          task_id: task.id,
                          task_operation_id: job_id,
                          [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
                          id: file_id, 
                          company_id: this.data.company.id
                        }
                      })
                    });
                  });
  
                  return this.taskService.multiRequest(postData)
                } else {
                  let postData = [];
                  
                  this.data.file_ids.forEach(file_id => {
                    postData.push({
                      "path": `/api/file/copy/`,
                      "query": {company_id: this.data.company.id},
                      "method": "POST",
                      "body": {
                        location: '/',
                        task_id: task.id,
                        [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
                        id: file_id, 
                        company_id: this.data.company.id
                      }
                    })
                  });
                  return this.taskService.multiRequest(postData)
                }
              }),
              map(() => task)
            )
          }
        })
      ).subscribe(resp => {
        this.isSubmitTmpl = false;
        this.layoutService.showSnackBar({name: tmpl.template_data.name}, marker("created!"), SnackBarItem)
        this.openTask(resp);
        this.dialogRef.removePanelClass('animate__slideInUp')
        this.dialogRef.addPanelClass('animate__slideOutDown')
        this.taskService.newCard$.next({company_id: resp.company_id, task_id: resp.id})
        setTimeout(()=>{this.dialogRef.close({event: "Add", data: resp})}, 300);
      })
    )
  }

  change() {
    this.companyService.addTask$.next(true);
    this.close();
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

}