<mat-dialog-content class="mat-typography">
    <div class="container form_wrapper" [appDeviceClass]="'custom-scroll custom-scroll-t'" style="min-height: 650px;">
        <div class="title_modal" (click)="log()">
            <ng-container *ngIf="!!data.tmpl; else elseTemplateAdd">
                {{ "Edit card automation template" | translate }}
            </ng-container>
            <ng-template #elseTemplateAdd>
                {{ "Add card automation template" | translate }}
            </ng-template>
        </div>
        <form [formGroup]="form" class="form_with_pads form_with_valids">
            <mat-form-field appearance="standard" class="full_width_form">
                <ng-container *ngIf="!form.get('name').valid && submited">
                    <div class="req_field"></div>
                    <div class="req_label">
                        <div class="angle"></div>
                        <span>
                            {{ "You need to write here" | translate }}
                        </span>
                    </div>
                </ng-container>
                <mat-label>{{ 'Template title' | translate }}</mat-label>
                <input formControlName="name" matInput placeholder="{{ 'Template title' | translate }}">
            </mat-form-field>

            <div class="small_chips" *ngIf="automationType?.length > 0 && !data.tmpl">
                <ng-container *ngIf="!form.get('scenario_name').valid && submited">
                    <div class="req_field"></div>
                    <div class="req_label">
                        <div class="angle"></div>
                        <span>
                            {{ "You need to choose a value" | translate }}
                        </span>
                    </div>
                </ng-container>
                <label>{{ "Automation type" | translate }}: </label>
                <mat-chip-list [multiple]="false" selectable formControlName="scenario_name">
                    <ng-container *ngFor="let aType of automationType">
                        <mat-chip class="small_chip" (click)="selectAutomationType(aType)" [value]="aType">
                            {{ aType | translate }}
                        </mat-chip>
                    </ng-container>
                </mat-chip-list>
            </div>
        </form>
        
        
        <div class="form_with_pads form_with_valids" *ngIf="form.get('scenario_data').get('channels_ids')">
            <div class="outlet_set" style="margin-bottom: 1.25em;">
                <div class="outlet_selected">
                    <div class="opened_trigger" (click)="opened = !opened">
                        <div class="label" [ngClass]="{'selected': form.get('scenario_data').get('channels_ids').value.length}">
                            {{ "Choose a channel(s)" | translate }}
                        </div>
                        <div class="multi_users" *ngIf="!!form.get('scenario_data').get('channels_ids').value && !!form.get('scenario_data').get('channels_ids').value.length">
                            <span *ngFor="let id of form.get('scenario_data').get('channels_ids').value">
                                <app-outlet-line [channel]="getProfile(id)" [company_id]="data?.company.id"></app-outlet-line>
                            </span>
                        </div>
                        <mat-icon>arrow_drop_down</mat-icon>
                    </div>
                </div>
                <app-outlet-filter *ngIf="platforms && opened"
                    [company]="data.company" 
                    [company_id]="data.company.id" 
                    [multiple]="true" 
                    [needCst]="true" 
                    [n_a]="false"
                    [profiles]="false" 
                    [form]="form.get('scenario_data')" 
                    [outletKey]="'channels_ids'" 
                    [activeSections]="''" 
                    [platforms]="platforms" 
                    [showOutletBtn]="false" 
                    (SelectChange)="selectChange($event)">
                </app-outlet-filter>
            </div>
        </div>
        <form [formGroup]="form" class="form_with_pads form_with_valids" *ngIf="form.get('scenario_data').get('channel_platforms_ids')">
            <ng-container formGroupName="scenario_data">
                <mat-form-field appearance="legacy" class="full_width_form" *ngIf="platforms">
                    <mat-label>{{ "Platform(s)" | translate }}</mat-label>
                    <mat-select [disableOptionCentering]="true" [multiple]="true" selectDir formControlName="channel_platforms_ids">
                        <mat-select-trigger class="multi_users">
                            <ng-container *ngIf="!!form.get('scenario_data').get('channel_platforms_ids').value && !!form.get('scenario_data').get('channel_platforms_ids').value.length">
                                <span *ngFor="let id of form.get('scenario_data').get('channel_platforms_ids').value">
                                    <app-platform [platform_id]="getPlatformById(id)?.id"></app-platform>
                                    <span>{{getPlatformById(id)?.name}}</span>
                                </span>
                            </ng-container>
                        </mat-select-trigger>
                      <mat-option>
                        <ngx-mat-select-search 
                            placeholderLabel="{{ 'Search' | translate }}"
                            noEntriesFoundLabel="{{ 'no matching platform found' | translate }}"
                            [formControl]="platformsMoreControl"></ngx-mat-select-search>
                      </mat-option>
                      <mat-option *ngFor="let platform of platforms$ | async" [value]="platform.id">
                        <div class="d-f-c">
                            <app-platform [platform_id]="platform.id"></app-platform>
                            <span class="ml-1">{{platform.name}}</span>
                        </div>
                      </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="legacy" class="full_width_form" *ngIf="platforms">
                    <mat-label>{{ "Content type(s)" | translate }}</mat-label>
                    <mat-select [disableOptionCentering]="true" [multiple]="true" selectDir formControlName="channel_content_types_ids">
                        <mat-select-trigger class="multi_users">
                            <ng-container *ngIf="!!form.get('scenario_data').get('channel_content_types_ids').value && !!form.get('scenario_data').get('channel_content_types_ids').value.length">
                                <span *ngFor="let id of form.get('scenario_data').get('channel_content_types_ids').value">
                                    {{getContentById(id)?.name}}
                                </span>
                            </ng-container>
                        </mat-select-trigger>
                        <mat-option>
                            <ngx-mat-select-search 
                                placeholderLabel="{{ 'Search' | translate }}"
                                noEntriesFoundLabel="{{ 'no matching content type found' | translate }}"
                                [formControl]="contentTypesControl"></ngx-mat-select-search>
                        </mat-option>
                        <mat-optgroup *ngFor="let contentType of contentTypes$ | async" [label]="getPlatformById(contentType.channel_platform_id)?.name">
                            <mat-option *ngFor="let fil of contentType.types" [value]="fil.id" [ngClass]="{'active': form.get('scenario_data').get('channel_content_types_ids').value.includes(fil.id)}">
                                {{fil.name | translate}}
                            </mat-option>
                            <mat-divider></mat-divider>
                        </mat-optgroup>
                    </mat-select>
                </mat-form-field>

            </ng-container>
        </form>

        <ng-container *ngIf="form.get('scenario_data').get('content_schedules_ids')">
            <form [formGroup]="form" class="form_with_pads form_with_valids">
                <ng-container formGroupName="scenario_data">
                    <mat-form-field appearance="standard" class="full_width_form">
                        <mat-label>{{"Regular(s)" | translate}}</mat-label>
                        <mat-select [multiple]="true" [disableOptionCentering]="true" selectDir formControlName="content_schedules_ids">
                            <mat-option>
                                <ngx-mat-select-search 
                                placeholderLabel="{{ 'Search' | translate }}" 
                                noEntriesFoundLabel="{{ 'no matching regulars found' | translate }}"
                                [formControl]="regularsControl"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let regular of regulars$ | async" [value]="regular.id">
                                {{ regular.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field appearance="standard" class="full_width_form">
                        <mat-label>{{ "Regular item(s)" | translate }}</mat-label>
                        <mat-select [disableOptionCentering]="true" [multiple]="true" selectDir formControlName="content_schedule_items_ids">
                            <mat-option>
                                <ngx-mat-select-search 
                                    placeholderLabel="{{ 'Search' | translate }}"
                                    noEntriesFoundLabel="{{ 'no matching regular items found' | translate }}"
                                    [formControl]="regularItemsControl"></ngx-mat-select-search>
                            </mat-option>
                            <mat-optgroup *ngFor="let regular of regularItems$ | async" [label]="regular?.name">
                                <mat-option *ngFor="let fil of regular.items" [value]="fil.id" [ngClass]="{'active': form.get('scenario_data').get('content_schedule_items_ids').value.includes(fil.id)}">
                                    {{fil.name}}
                                </mat-option>
                                <mat-divider></mat-divider>
                            </mat-optgroup>
                        </mat-select>
                    </mat-form-field>
                </ng-container>
            </form>
        </ng-container>

        <ng-container *ngIf="form.get('scenario_data').get('create_task_operation') && form.get('scenario_data').get('create_discussion')">
            <form [formGroup]="form" class="form_with_pads form_with_valids">
                <ng-container formGroupName="scenario_data">

                    <div class="block_w8">
                        <div class="label_w8">{{"Executors" | translate}}</div>
                        <div class="users_select">
                            <label>{{ "Individuals" | translate }}: </label>
                            <div class="chips_spacer" *ngIf="!!emplSwitch"></div>
                            <mat-chip-list [multiple]="true" selectable formControlName="employees_ids" *ngIf="!emplSwitch">
                                <ng-container *ngFor="let employee of employees; let i = index">
                                    <mat-chip class="chip_user" (click)="toggleEmplChip(employee)" [value]="employee.id">
                                        <app-user [class]="'user_div'" [employee]="employee"></app-user>
                                    </mat-chip>
                                    <mat-chip *ngIf="i == employees.length - 1 && i > 6" class="chip_user more" (click)="employeeIdContainer.open()">
                                        {{ "more" | translate }}
                                    </mat-chip>
                                </ng-container>
                            </mat-chip-list>
                        </div>
                
                        <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                            <mat-select #employeeIdContainer formControlName="employees_ids" multiple (selectionChange)="toggleEmplChip($event)">
                                <mat-option>
                                    <ngx-mat-select-search 
                                    placeholderLabel="{{ 'Search' | translate }}" 
                                    noEntriesFoundLabel="{{ 'no matching individuals found' | translate }}"
                                    [formControl]="employeeMoreControl"></ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let employee of employees$ | async" [value]="employee.id">
                                    <app-user [class]="'user_div'" [employee]="employee"></app-user>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <div class="users_select">
                            <label>{{ "Partners" | translate }}: </label>
                            <div class="chips_spacer" *ngIf="!!partnerSwitch"></div>
                            <mat-chip-list [multiple]="true" selectable formControlName="partner_companies_ids" *ngIf="!partnerSwitch">
                                <ng-container *ngFor="let partner of partners; let i = index">
                                    <mat-chip class="chip_user" (click)="toggleAddPartnerChip(partner)" [value]="partner.partner_company_id">
                                        <app-user [class]="'user_div'" [companyEmployee]="partner?.partnerCompany" [imgRoute]="imgRoute" [host]="host" [company]="data?.company"></app-user>
                                    </mat-chip>
                                    <mat-chip *ngIf="i == partners.length - 1 && i > 6" class="chip_user more" (click)="partnersIdContainer.open()">
                                        {{ "more" | translate }}
                                    </mat-chip>
                                </ng-container>
                            </mat-chip-list>
                        </div>
                
                        <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                            <mat-select #partnersIdContainer formControlName="partner_companies_ids" multiple (selectionChange)="toggleAddPartnerChip($event)">
                                <mat-option>
                                    <ngx-mat-select-search 
                                    placeholderLabel="{{ 'Search' | translate }}" 
                                    noEntriesFoundLabel="{{ 'no matching partners found' | translate }}"
                                    [formControl]="partnersControl"></ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let partner of partners$ | async" [value]="partner.partner_company_id">
                                    <app-user [class]="'user_div'" [companyEmployee]="partner?.partnerCompany" [imgRoute]="imgRoute" [host]="host" [company]="data?.company"></app-user>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                </ng-container>
            </form>
            
            <div class="block_w8 form_with_pads form_with_valids" style="margin: 20px 40px;">
                <div class="label_w8" style="margin: 0;">{{"Clients" | translate}}</div>
                <div class="users_select" style="margin: 0 !important; width: 100% !important;">
                    <label>{{ "Individuals" | translate }}: </label>
                    <div class="chips_spacer"></div>
                    <mat-chip-list [multiple]="true" selectable>
                        <ng-container *ngFor="let employee of employees; let i = index">
                            <mat-chip class="chip_user" (click)="toggleClientChip(employee)" [selected]="includesCli(employee.id)" [value]="employee.id">
                                <app-user [class]="'user_div'" [employee]="employee"></app-user>
                                <mat-icon *ngIf="!includesCli(employee.id)" style="margin-left: 3.5px; width: 14px; height: 14px; line-height: 14px; font-size: 14px; visibility: hidden;"></mat-icon>
                                <ng-container *ngIf="includesCli(employee.id)">
                                    <mat-icon style="margin-left: 3.5px; width: 14px; height: 14px; line-height: 14px; font-size: 14px;" 
                                        #tooltip="matTooltip"
                                        matTooltip="{{'Permission to approve granted' | translate}}"
                                        [matTooltipPosition]="'above'" 
                                        (click)="setCli($event, employee.id, 0)" 
                                        *ngIf="!!getCli(employee.id)?.is_principal">thumb_up</mat-icon>
                                    <mat-icon 
                                        style="margin-left: 3.5px; width: 14px; height: 14px; line-height: 14px; font-size: 14px;" 
                                        #tooltip="matTooltip"
                                        matTooltip="{{'Permission to approve not granted' | translate}}"
                                        [matTooltipPosition]="'above'" 
                                        (click)="setCli($event, employee.id, 1)" 
                                        *ngIf="!getCli(employee.id)?.is_principal">thumb_up_off_alt</mat-icon>
                                </ng-container>
                            </mat-chip>
                            <mat-chip *ngIf="i == employees.length - 1 && i > 6" class="chip_user more" (click)="cliCardEmployeeIdContainer.open()">
                                {{ "more" | translate }}
                            </mat-chip>
                        </ng-container>
                    </mat-chip-list>
                </div>
        
                <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                    <mat-select #cliCardEmployeeIdContainer multiple [(ngModel)]="clients_ids" (selectionChange)="toggleSelectClientChip($event)">
                        <mat-option>
                            <ngx-mat-select-search 
                            placeholderLabel="{{ 'Search' | translate }}" 
                            noEntriesFoundLabel="{{ 'no matching individuals found' | translate }}"
                            [formControl]="employeeMoreControl"></ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let employee of employees$ | async" [value]="employee.id">
                            <app-user [class]="'user_div'" [employee]="employee"></app-user>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
    
                <div class="users_select" style="margin: 0 !important; width: 100% !important;">
                    <label>{{ "Partners" | translate }}: </label>
                    <div class="chips_spacer" *ngIf="!!pCliSwitch"></div>
                    <mat-chip-list [multiple]="true" selectable *ngIf="!pCliSwitch">
                        <ng-container *ngFor="let partner of partners; let i = index">
                            <mat-chip class="chip_user" (click)="toggleClientPartnerChip(partner)" [selected]="includesCliPartn(partner.partner_company_id)" [value]="partner.partner_company_id">
                                <app-user [class]="'user_div'" [companyEmployee]="partner?.partnerCompany" [imgRoute]="imgRoute" [host]="host" [company]="data?.company"></app-user>
                                <mat-icon *ngIf="!includesCliPartn(partner.partner_company_id)" style="margin-left: 3.5px; width: 14px; height: 14px; line-height: 14px; font-size: 14px; visibility: hidden;"></mat-icon>
                            <ng-container *ngIf="includesCliPartn(partner.partner_company_id)">
                                <mat-icon 
                                #tooltip="matTooltip"
                                matTooltip="{{'Permission to approve granted' | translate}}"
                                [matTooltipPosition]="'above'" 
                                style="margin-left: 3.5px; width: 14px; height: 14px; line-height: 14px; font-size: 14px;" 
                                (click)="setCliPartn($event, partner.partner_company_id, 0)" 
                                *ngIf="!!getCliPartn(partner.partner_company_id)?.is_principal">thumb_up</mat-icon>
                                <mat-icon 
                                #tooltip="matTooltip"
                                matTooltip="{{'Permission to approve not granted' | translate}}"
                                [matTooltipPosition]="'above'" 
                                style="margin-left: 3.5px; width: 14px; height: 14px; line-height: 14px; font-size: 14px;" 
                                (click)="setCliPartn($event, partner.partner_company_id, 1)" 
                                *ngIf="!getCliPartn(partner.partner_company_id)?.is_principal">thumb_up_off_alt</mat-icon>
                            </ng-container>
                            </mat-chip>
                            <mat-chip *ngIf="i == partners.length - 1 && i > 6" class="chip_user more" (click)="cliCardPartnersIdContainer.open()">
                                {{ "more" | translate }}
                            </mat-chip>
                        </ng-container>
                    </mat-chip-list>
                </div>
        
                <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                    <mat-select #cliCardPartnersIdContainer multiple [(ngModel)]="partnerClients_ids" (selectionChange)="toggleSelectClientPartnerChip($event)">
                        <mat-option>
                            <ngx-mat-select-search 
                            placeholderLabel="{{ 'Search' | translate }}" 
                            noEntriesFoundLabel="{{ 'no matching partners found' | translate }}"
                            [formControl]="partnersControl"></ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let partner of partners$ | async" [value]="partner.partner_company_id">
                            <app-user [class]="'user_div'" [companyEmployee]="partner?.partnerCompany" [imgRoute]="imgRoute" [host]="host" [company]="data?.company"></app-user>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <form [formGroup]="form" class="form_with_pads form_with_valids">
                <ng-container formGroupName="scenario_data">


                    <div class="block_out" formGroupName="create_task_operation">
                        <p>{{"Create Job"}}</p>

                        <mat-form-field appearance="standard" class="full_width_form">
                            <mat-label>{{ 'Job name' | translate }}</mat-label>
                            <input formControlName="name" matInput placeholder="{{ 'Job name' | translate }}">
                        </mat-form-field>

                        <div class="priority_chips">
                            <label>{{ "Priority" | translate }}: </label>
                            <mat-chip-list [multiple]="false" selectable formControlName="priority">
                                <mat-chip *ngFor="let priority of prioritys" [selected]="form.get('scenario_data').get('create_task_operation').get('priority').value == priority.id" [ngClass]="{
                                    'Urgent': priority.id == '1000',
                                    'High': priority.id == '900',
                                    'Middle': priority.id == '800',
                                    'Low': priority.id == '700',
                                    'No': priority.id == '0'
                                }" class="priority_chip" (click)="patchCtrl(form.get('scenario_data').get('create_task_operation'), 'priority', priority?.id)" [value]="priority.id">
                                    {{ priority.name | translate }}
                                </mat-chip>
                            </mat-chip-list>
                        </div>
        
                        <div class="small_chips" *ngIf="operations?.length > 0">
                            <label>{{ "Job type" | translate }}: </label>
                            <mat-chip-list [multiple]="false" selectable formControlName="operation_id">
                                <ng-container *ngFor="let operation of operations; let i = index">
                                    <mat-chip class="small_chip" [value]="operation?.id" (click)="patchCtrl(form.get('scenario_data').get('create_task_operation'), 'operation_id', operation?.id)" [selected]="form.get('scenario_data').get('create_task_operation').get('operation_id').value == operation.id">
                                        {{ operation.name | translate }}
                                    </mat-chip>
                                    <mat-chip *ngIf="i == operations?.length - 1 && i > 6" class="small_chip more" (click)="typeContainer.open()">
                                        {{ "More..." | translate }}
                                    </mat-chip>
                                </ng-container>
                            </mat-chip-list>
                        </div>
                        <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                            <mat-select [multiple]="false" #typeContainer  formControlName="operation_id">
                                <mat-option>
                                    <ngx-mat-select-search 
                                    placeholderLabel="{{ 'Search' | translate }}" 
                                    noEntriesFoundLabel="{{ 'no matching job type found' | translate }}"
                                    [formControl]="operationMoreControl"></ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let operation of operations$ | async" [value]="operation.id">
                                    {{ operation.name | translate }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="legacy" class="full_width_form" *ngIf="parameters">
                            <mat-label>{{ "Tag from {Tag Category} same as tag of posts channel" | translate }}</mat-label>
                            <mat-select [disableOptionCentering]="true" [multiple]="true" selectDir formControlName="parameters_ids">
                              <mat-option>
                                <ngx-mat-select-search 
                                    placeholderLabel="{{ 'Search' | translate }}" 
                                    noEntriesFoundLabel="{{ 'no matching tag category found' | translate }}"
                                    [formControl]="parametersCtrl"></ngx-mat-select-search>
                              </mat-option>
                              <mat-option *ngFor="let parameter of parameters$ | async" [value]="parameter.id">
                                {{parameter.name}}
                              </mat-option>
                            </mat-select>
                        </mat-form-field>
    
                        <div class="small_chips">
                            <label>{{ "If job exist this statuses then will be create/update deadline" | translate }}: </label>
                            <mat-chip-list [multiple]="true" selectable formControlName="statuses_ids">
                                <ng-container *ngFor="let status of operationStatuses">
                                    <mat-chip class="small_chip" (click)="setMultiStatuses(form.get('scenario_data').get('create_task_operation'), status)" [selected]="form.get('scenario_data').get('create_task_operation').get('statuses_ids').value.includes(status.id)" [value]="status?.id" [ngClass]="{
                                        'first': status.id == '1',
                                        'second': status.id == '2',
                                        'third': status.id == '3',
                                        'fours': status.id == '4',
                                        'fives': status.id == '5',
                                        'sixs': status.id == '6',
                                        'nine': status.id == '97',
                                        'seven': status.id == '98',
                                        'eight': status.id == '99'}">
                                        {{ status.name | translate }}
                                    </mat-chip>
                                </ng-container>
                            </mat-chip-list>
                        </div>
    
                        <div class="box_wrp">
                            <mat-checkbox color="primary" formControlName="is_force_create">{{ "Always create" | translate }}</mat-checkbox>
                        </div>
                    </div>
                    <div class="block_out" formGroupName="create_discussion">
                        <p>{{"Create Chat"}}</p>

                        <mat-form-field appearance="standard" class="full_width_form">
                            <mat-label>{{ 'Chat name' | translate }}</mat-label>
                            <input formControlName="name" matInput placeholder="{{ 'Job name' | translate }}">
                        </mat-form-field>

                        <div class="priority_chips">
                            <label>{{ "Priority" | translate }}: </label>
                            <mat-chip-list [multiple]="false" selectable formControlName="priority">
                                <mat-chip *ngFor="let priority of prioritys" [selected]="form.get('scenario_data').get('create_discussion').get('priority').value == priority.id" [ngClass]="{
                                    'Urgent': priority.id == '1000',
                                    'High': priority.id == '900',
                                    'Middle': priority.id == '800',
                                    'Low': priority.id == '700',
                                    'No': priority.id == '0'
                                }" class="priority_chip" (click)="patchCtrl(form.get('scenario_data').get('create_discussion'), 'priority', priority?.id)" [value]="priority.id">
                                    {{ priority.name | translate }}
                                </mat-chip>
                            </mat-chip-list>
                        </div>
        
                        <div class="small_chips">
                            <label>{{ "If chat exist this statuses then will be create/update deadline" | translate }}: </label>
                            <mat-chip-list [multiple]="true" selectable formControlName="statuses_ids">
                                <ng-container *ngFor="let status of operationStatuses">
                                    <mat-chip class="small_chip" (click)="setMultiStatuses(form.get('scenario_data').get('create_discussion'), status)" [selected]="form.get('scenario_data').get('create_discussion').get('statuses_ids').value.includes(status.id)" [value]="status?.id" [ngClass]="{
                                        'first': status.id == '1',
                                        'second': status.id == '2',
                                        'third': status.id == '3',
                                        'fours': status.id == '4',
                                        'fives': status.id == '5',
                                        'sixs': status.id == '6',
                                        'nine': status.id == '97',
                                        'seven': status.id == '98',
                                        'eight': status.id == '99'}">
                                        {{ status.name | translate }}
                                    </mat-chip>
                                </ng-container>
                            </mat-chip-list>
                        </div>
    
                        <div class="box_wrp">
                            <mat-checkbox color="primary" formControlName="is_force_create">{{ "Always create" | translate }}</mat-checkbox>
                        </div>
                    </div>
                </ng-container>
            </form>
        </ng-container>

        <ng-container *ngIf="form.get('scenario_name').value == 'TaskChain'; else elseTemplateTaskChainJobs">

            <form [formGroup]="form" class="form_with_pads form_with_valids">
                <ng-container formGroupName="scenario_data">
                    <ng-container formGroupName="create_task">
                        <mat-form-field appearance="standard" class="full_width_form">
                            <mat-label>{{ 'Card title' | translate }}</mat-label>
                            <input formControlName="name" matInput placeholder="{{ 'Card title' | translate }}">
                        </mat-form-field>

                        <div class="small_chips">
                            <label>{{ "Choose a Template" | translate }}: </label>
                            <mat-chip-list selectable formControlName="task_template_id">
                                <ng-container *ngFor="let template of templates; let i = index">
                                    <mat-chip style="padding-left: 12px !important;" (click)="chooseTemplate(template)" class="chip_user" [value]="template.id" [selected]="form.get('scenario_data').get('create_task').get('task_template_id').value == template.id">
                                        {{ template.name }}
                                    </mat-chip>
                                    <mat-chip *ngIf="i == templates.length - 1 && i > 6" class="chip_user more" (click)="templatesIdContainer.open()">
                                        {{ "more" | translate }}
                                    </mat-chip>
                                </ng-container>
                            </mat-chip-list>
                        </div>
            
                        <!-- (optionSelected)=“function($event)” try this -->
                        <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                            <mat-select #templatesIdContainer formControlName="task_template_id">
                                <mat-option>
                                    <ngx-mat-select-search 
                                    placeholderLabel="{{ 'Search' | translate }}" 
                                    noEntriesFoundLabel="{{ 'no matching template found' | translate }}"
                                    [formControl]="templatesMoreControl"></ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let template of templates$ | async" [value]="template.id">
                                    {{template.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </ng-container>
                </ng-container>
            </form>

            <div class="form_with_pads form_with_valids jobs_scen_wrp">
                <!-- feature chat id: 23450-->
                <div class="jobs_scen_title" (click)="logJobs()">{{"Execute only when all following jobs from the card meet requirements below:" | translate}}</div>
               
    
                <div class="job" *ngFor="let job of jobs; let i = index;">
                    <button mat-icon-button color="warn" class="delete_job" (click)="deleteJob(i)">
                        <mat-icon>do_disturb_on</mat-icon>
                    </button>
    
                    <div class="small_chips">
                        <label>{{ "Status" | translate }}: </label>
                        <mat-chip-list [multiple]="true" selectable [(ngModel)]="job.status_ids">
                            <ng-container *ngFor="let status of operationStatuses">
                                <mat-chip class="small_chip" (click)="selectStatus(job, status)" [selected]="job.status_ids.includes(status.id)" [value]="status?.id" [ngClass]="{
                                    'first': status.id == '1',
                                    'second': status.id == '2',
                                    'third': status.id == '3',
                                    'fours': status.id == '4',
                                    'fives': status.id == '5',
                                    'sixs': status.id == '6',
                                    'nine': status.id == '97',
                                    'seven': status.id == '98',
                                    'eight': status.id == '99'}">
                                    {{ status.name | translate }}
                                </mat-chip>
                            </ng-container>
                        </mat-chip-list>
                    </div>
    
                    <div class="small_chips" *ngIf="operations?.length > 0">
                        <label>{{ "Job type" | translate }}: </label>
                        <mat-chip-list [multiple]="true" selectable [(ngModel)]="job.operation_ids">
                            <ng-container *ngFor="let operation of operations; let i = index">
                                <mat-chip class="small_chip" (click)="selectType(job, operation)" [value]="operation?.id" [selected]="job.operation_ids.includes(operation.id)">
                                    {{ operation.name | translate }}
                                </mat-chip>
                                <mat-chip *ngIf="i == operations?.length - 1 && i > 6" class="small_chip more" (click)="operationIdContainerNew.open()">
                                    {{ "More..." | translate }}
                                </mat-chip>
                            </ng-container>
                        </mat-chip-list>
                    </div>
                    <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                        <mat-select [multiple]="true" #operationIdContainerNew [(ngModel)]="job.operation_ids">
                            <mat-option>
                                <ngx-mat-select-search 
                                placeholderLabel="{{ 'Search' | translate }}" 
                                noEntriesFoundLabel="{{ 'no matching job type found' | translate }}"
                                [formControl]="operationMoreControl"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let operation of operations$ | async" [value]="operation.id">
                                {{ operation.name | translate }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field appearance="standard" class="full_width_form">
                        <mat-label>{{ "Tag(s)" | translate }}</mat-label>
                        <mat-select [disableOptionCentering]="true" selectDir [multiple]="true" [(ngModel)]="job.parameter_values_ids">
                            <mat-select-trigger class="multi_users">
                                <ng-container *ngIf="job.parameter_values_ids && job.parameter_values_ids.length">
                                    <ng-container *ngFor="let id of job.parameter_values_ids">
                                        <span>{{ getValuesById(id)?.value }}</span>
                                    </ng-container>
                                </ng-container>
                            </mat-select-trigger>
                            <mat-option>
                                <ngx-mat-select-search 
                                placeholderLabel="{{ 'Search' | translate }}" 
                                noEntriesFoundLabel="{{ 'no matching tag found' | translate }}"
                                [formControl]="allValuesControl"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let item of allValues$ | async" [value]="item.id">
                                <div class="d-f-c" style="justify-content: space-between;">
                                    <span>{{ item.value }}</span>
                                    <span>{{ item?.parameter?.name }}</span>
                                </div>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    
                    <div class="block_w8">
                        <div class="label_w8">{{"Executors" | translate}}</div>
                        <div class="users_select">
                            <label>{{ "Individuals" | translate }}: </label>
                            <mat-chip-list [multiple]="true" selectable [(ngModel)]="job.employees_ids">
                                <ng-container *ngFor="let employee of employees; let i = index">
                                    <mat-chip class="chip_user" (click)="toggleJobEmpl(employee, job)" [value]="employee.id" [selected]="job.employees_ids.includes(employee.id)">
                                        <app-user [class]="'user_div'" [employee]="employee"></app-user>
                                    </mat-chip>
                                    <mat-chip *ngIf="i == employees.length - 1 && i > 6" class="chip_user more" (click)="employeeIdContainer.open()">
                                        {{ "more" | translate }}
                                    </mat-chip>
                                </ng-container>
                            </mat-chip-list>
                        </div>
                
                        <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                            <mat-select #employeeIdContainer [(ngModel)]="job.employees_ids" [value]="job.employees_ids" [multiple]="true">
                                <mat-option>
                                    <ngx-mat-select-search 
                                    placeholderLabel="{{ 'Search' | translate }}" 
                                    noEntriesFoundLabel="{{ 'no matching individuals found' | translate }}"
                                    [formControl]="employeeMoreControl"></ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let employee of employees$ | async" [value]="employee.id" [selected]="job.employees_ids.includes(employee.id)">
                                    <app-user [class]="'user_div'" [employee]="employee"></app-user>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <div class="users_select">
                            <label>{{ "Partners" | translate }}: </label>
                            <div class="chips_spacer" *ngIf="!!job.switch_partner_companies_ids"></div>
                            <mat-chip-list [multiple]="true" selectable [(ngModel)]="job.partner_companies_ids" *ngIf="!job.switch_partner_companies_ids">
                                <ng-container *ngFor="let partner of partners; let i = index">
                                    <mat-chip class="chip_user" (click)="toggleJobPartner(partner, job)" [value]="partner.partner_company_id" [selected]="job.partner_companies_ids.includes(partner.partner_company_id)">
                                        <app-user [class]="'user_div'" [companyEmployee]="partner?.partnerCompany" [imgRoute]="imgRoute" [host]="host" [company]="data?.company"></app-user>
                                    </mat-chip>
                                    <mat-chip *ngIf="i == partners.length - 1 && i > 6" class="chip_user more" (click)="partnersIdContainer.open()">
                                        {{ "more" | translate }}
                                    </mat-chip>
                                </ng-container>
                            </mat-chip-list>
                        </div>
                
                        <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                            <mat-select #partnersIdContainer [(ngModel)]="job.partner_companies_ids" [multiple]="true">
                                <mat-option>
                                    <ngx-mat-select-search 
                                    placeholderLabel="{{ 'Search' | translate }}" 
                                    noEntriesFoundLabel="{{ 'no matching partners found' | translate }}"
                                    [formControl]="partnersControl"></ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let partner of partners$ | async" [value]="partner.partner_company_id" [selected]="job.partner_companies_ids.includes(partner.partner_company_id)">
                                    <app-user [class]="'user_div'" [companyEmployee]="partner?.partnerCompany" [imgRoute]="imgRoute" [host]="host" [company]="data?.company"></app-user>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
    
                <div style="margin: 10px 40px;">
                    <div class="add_job" (click)="addJob()">
                        + {{ 'Add job' | translate }}
                    </div>
                </div>
    
            </div>
        </ng-container>
        <ng-template #elseTemplateTaskChainJobs>
            <ng-container *ngIf="form.get('scenario_name').value != 'TaskLiveStreamRestart' && form.get('scenario_name').value != 'TaskLocalizationPostPublisher'">
                <div class="form_with_pads form_with_valids jobs_scen_wrp">
                    <!-- feature chat id: 23450-->
                    <div class="jobs_scen_title" (click)="logJobs()">{{"Execute only when all following jobs from the card meet requirements below:" | translate}}</div>
                   
        
                    <div class="job" *ngFor="let job of jobs; let i = index;">
                        <button mat-icon-button color="warn" class="delete_job" (click)="deleteJob(i)">
                            <mat-icon>do_disturb_on</mat-icon>
                        </button>
        
                        <div class="small_chips">
                            <label>{{ "Status" | translate }}: </label>
                            <mat-chip-list [multiple]="true" selectable [(ngModel)]="job.status_ids">
                                <ng-container *ngFor="let status of operationStatuses">
                                    <mat-chip class="small_chip" (click)="selectStatus(job, status)" [selected]="job.status_ids.includes(status.id)" [value]="status?.id" [ngClass]="{
                                        'first': status.id == '1',
                                        'second': status.id == '2',
                                        'third': status.id == '3',
                                        'fours': status.id == '4',
                                        'fives': status.id == '5',
                                        'sixs': status.id == '6',
                                        'nine': status.id == '97',
                                        'seven': status.id == '98',
                                        'eight': status.id == '99'}">
                                        {{ status.name | translate }}
                                    </mat-chip>
                                </ng-container>
                            </mat-chip-list>
                        </div>
        
                        <div class="small_chips" *ngIf="operations?.length > 0">
                            <label>{{ "Job type" | translate }}: </label>
                            <mat-chip-list [multiple]="true" selectable [(ngModel)]="job.operation_ids">
                                <ng-container *ngFor="let operation of operations; let i = index">
                                    <mat-chip class="small_chip" (click)="selectType(job, operation)" [value]="operation?.id" [selected]="job.operation_ids.includes(operation.id)">
                                        {{ operation.name | translate }}
                                    </mat-chip>
                                    <mat-chip *ngIf="i == operations?.length - 1 && i > 6" class="small_chip more" (click)="operationIdContainerNew.open()">
                                        {{ "More..." | translate }}
                                    </mat-chip>
                                </ng-container>
                            </mat-chip-list>
                        </div>
                        <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                            <mat-select [multiple]="true" #operationIdContainerNew [(ngModel)]="job.operation_ids">
                                <mat-option>
                                    <ngx-mat-select-search 
                                    placeholderLabel="{{ 'Search' | translate }}" 
                                    noEntriesFoundLabel="{{ 'no matching job type found' | translate }}"
                                    [formControl]="operationMoreControl"></ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let operation of operations$ | async" [value]="operation.id">
                                    {{ operation.name | translate }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
        
                        <mat-form-field appearance="legacy" class="full_width_form" *ngIf="parameters">
                            <mat-label>{{ "Same tag(s)" | translate }}</mat-label>
                            <mat-select [disableOptionCentering]="true" [multiple]="true" [(ngModel)]="job.parameters_ids" selectDir>
                                <mat-select-trigger class="multi_users">
                                    <ng-container *ngIf="!!job.parameters_ids && !!job.parameters_ids.length">
                                        <span *ngFor="let id of job.parameters_ids">
                                            {{getParamById(id)?.name}}
                                        </span>
                                    </ng-container>
                                </mat-select-trigger>
                              <mat-option>
                                <ngx-mat-select-search 
                                    placeholderLabel="{{ 'Search' | translate }}" 
                                    noEntriesFoundLabel="{{ 'no matching tag found' | translate }}"
                                    [formControl]="parametersCtrl"></ngx-mat-select-search>
                              </mat-option>
                              <mat-option *ngFor="let parameter of parameters$ | async" [value]="parameter.id">
                                {{parameter.name}}
                              </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
        
                    <div style="margin: 10px 40px;">
                        <div class="add_job" (click)="addJob()">
                            + {{ 'Add job' | translate }}
                        </div>
                    </div>
        
                </div>
            </ng-container>
        </ng-template>

        <ng-container *ngIf="form.get('scenario_name').value == 'TaskLocalizationPostPublisher'">
            <form [formGroup]="form" class="form_with_pads form_with_valids">
                <ng-container formGroupName="scenario_data">
                    <mat-form-field appearance="standard" class="full_width_form">
                        <mat-label>{{ "Audio Job type" | translate }}</mat-label>
                        <mat-select [disableOptionCentering]="true" selectDir [multiple]="true" formControlName="audio_task_operation_operations_ids">
                            <mat-option>
                                <ngx-mat-select-search 
                                placeholderLabel="{{ 'Search' | translate }}" 
                                noEntriesFoundLabel="{{ 'no matching job type found' | translate }}"
                                [formControl]="operationMoreControl"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let operation of operations$ | async" [value]="operation.id">
                                {{ operation.name | translate }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="standard" class="full_width_form">
                        <mat-label>{{ "Audio Job status" | translate }}</mat-label>
                        <mat-select [disableOptionCentering]="true" selectDir [multiple]="true" formControlName="audio_task_operation_statuses_ids">
                            <mat-option *ngFor="let item of operationStatuses" [value]="item.id">
                                {{ item.name | translate }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="standard" class="full_width_form">
                        <mat-label>{{ "Audio Files tag(s)" | translate }}</mat-label>
                        <mat-select [disableOptionCentering]="true" selectDir [multiple]="true" formControlName="audio_file_parameter_values_ids">
                            <mat-select-trigger class="multi_users">
                                <ng-container *ngIf="form.get('scenario_data').value.audio_file_parameter_values_ids && form.get('scenario_data').value.audio_file_parameter_values_ids.length">
                                    <ng-container *ngFor="let id of form.get('scenario_data').value.audio_file_parameter_values_ids">
                                        <span>{{ getValuesById(id)?.value }}</span>
                                    </ng-container>
                                </ng-container>
                            </mat-select-trigger>
                            <mat-option>
                                <ngx-mat-select-search 
                                placeholderLabel="{{ 'Search' | translate }}" 
                                noEntriesFoundLabel="{{ 'no matching tag found' | translate }}"
                                [formControl]="allValuesControl"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let item of allValues$ | async" [value]="item.id">
                                <div class="d-f-c" style="justify-content: space-between;">
                                    <span>{{ item.value }}</span>
                                    <span>{{ item?.parameter?.name }}</span>
                                </div>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
        
                    <mat-form-field appearance="standard" class="full_width_form">
                        <mat-label>{{ "Post Job type" | translate }}</mat-label>
                        <mat-select [disableOptionCentering]="true" selectDir [multiple]="true" formControlName="post_data_task_operation_operations_ids">
                            <mat-option>
                                <ngx-mat-select-search 
                                placeholderLabel="{{ 'Search' | translate }}" 
                                noEntriesFoundLabel="{{ 'no matching job type found' | translate }}"
                                [formControl]="operationMoreControl"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let operation of operations$ | async" [value]="operation.id">
                                {{ operation.name | translate }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
        
                    <mat-form-field appearance="standard" class="full_width_form">
                        <mat-label>{{ "Post Job status" | translate }}</mat-label>
                        <mat-select [disableOptionCentering]="true" selectDir [multiple]="true" formControlName="post_data_task_operation_statuses_ids">
                            <mat-option *ngFor="let item of operationStatuses" [value]="item.id">
                                {{ item.name | translate }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
        
                    <mat-form-field appearance="standard" class="full_width_form">
                        <mat-label>{{ "Post Files tag(s)" | translate }}</mat-label>
                        <mat-select [disableOptionCentering]="true" selectDir [multiple]="true" formControlName="post_data_file_parameter_values_ids">
                            <mat-select-trigger class="multi_users">
                                <ng-container *ngIf="form.get('scenario_data').value.post_data_file_parameter_values_ids && form.get('scenario_data').value.post_data_file_parameter_values_ids.length">
                                    <ng-container *ngFor="let id of form.get('scenario_data').value.post_data_file_parameter_values_ids">
                                        <span>{{ getValuesById(id)?.value }}</span>
                                    </ng-container>
                                </ng-container>
                            </mat-select-trigger>
                            <mat-option>
                                <ngx-mat-select-search 
                                placeholderLabel="{{ 'Search' | translate }}" 
                                noEntriesFoundLabel="{{ 'no matching tag found' | translate }}"
                                [formControl]="allValuesControl"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let item of allValues$ | async" [value]="item.id">
                                <div class="d-f-c" style="justify-content: space-between;">
                                    <span>{{ item.value }}</span>
                                    <span>{{ item?.parameter?.name }}</span>
                                </div>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field appearance="standard" class="full_width_form">
                        <mat-label>{{ "Outlet tag(s)" | translate }}</mat-label>
                        <mat-select [disableOptionCentering]="true" selectDir [multiple]="true" formControlName="channel_parameter_values_ids">
                            <mat-select-trigger class="multi_users">
                                <ng-container *ngIf="form.get('scenario_data').value.channel_parameter_values_ids && form.get('scenario_data').value.channel_parameter_values_ids.length">
                                    <ng-container *ngFor="let id of form.get('scenario_data').value.channel_parameter_values_ids">
                                        <span>{{ getValuesById(id)?.value }}</span>
                                    </ng-container>
                                </ng-container>
                            </mat-select-trigger>
                            <mat-option>
                                <ngx-mat-select-search 
                                placeholderLabel="{{ 'Search' | translate }}" 
                                noEntriesFoundLabel="{{ 'no matching tag found' | translate }}"
                                [formControl]="allValuesControl"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let item of allValues$ | async" [value]="item.id">
                                <div class="d-f-c" style="justify-content: space-between;">
                                    <span>{{ item.value }}</span>
                                    <span>{{ item?.parameter?.name }}</span>
                                </div>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <div class="box_wrp">
                        <mat-checkbox color="primary" formControlName="is_post_data">{{ "Post data" | translate }}</mat-checkbox>
                    </div>
                    
                    <div class="box_wrp">
                        <mat-checkbox color="primary" formControlName="is_audio">{{ "Audio" | translate }}</mat-checkbox>
                    </div>
                </ng-container>
            </form>
        </ng-container>

        <ng-container *ngIf="form.get('scenario_name').value == 'TaskLiveStreamRestart'">
            <form [formGroup]="form" class="form_with_pads form_with_valids">
                <ng-container formGroupName="scenario_data">

                    <mat-form-field appearance="standard" class="full_width_form">
                        <mat-label>{{ 'Execute interval (m)' | translate }}</mat-label>
                        <input formControlName="execute_interval" matInput type="number" placeholder="{{ 'Execute interval (m)' | translate }}" (change)="onInput($event, 'execute_interval', data?.user?.is_root ? 30 : 240)">
                    </mat-form-field>
                    <mat-form-field appearance="standard" class="full_width_form">
                        <mat-label>{{ 'Concurrent views count restart threshold' | translate }}</mat-label>
                        <input formControlName="concurrent_views_count_restart_threshold" matInput type="number" placeholder="{{ 'Concurrent views count restart threshold' | translate }}" (change)="onInput($event, 'concurrent_views_count_restart_threshold', 1)">
                    </mat-form-field>
                    <mat-form-field appearance="standard" class="full_width_form">
                        <mat-label>{{ 'Resumed duration restart threshold (m)' | translate }}</mat-label>
                        <input formControlName="resumed_duration_restart_threshold" matInput type="number" placeholder="{{ 'Resumed duration restart threshold (m)' | translate }}" (change)="onInput($event, 'resumed_duration_restart_threshold', 120)">
                    </mat-form-field>

                    <mat-form-field appearance="legacy" class="full_width_form">
                        <mat-label>{{ "Restart content statuses" | translate }}</mat-label>
                        <mat-select [disableOptionCentering]="true" [multiple]="true" selectDir formControlName="restart_content_statuses_ids">
                            <!-- waiting, published, canceled, error -->
                          <mat-option [value]="1">
                            {{"Waiting" | translate}}
                          </mat-option>
                          <mat-option [value]="4">
                            {{"Published" | translate}}
                          </mat-option>
                          <mat-option [value]="5">
                            {{"Canceled" | translate}}
                          </mat-option>
                          <mat-option [value]="8">
                            {{"Error" | translate}}
                          </mat-option>
                          <mat-option [value]="17">
                            {{"Recording N/A_profile" | translate}}
                          </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field appearance="standard" class="full_width_form">
                        <mat-label>{{ 'How many video files to take for streaming from the video files stored within a card?' | translate }}</mat-label>
                        <input formControlName="select_content_files_count" matInput type="number" placeholder="{{ 'How many video files to take for streaming from the video files stored within a card?' | translate }}">
                    </mat-form-field>

                    <div class="box_wrp">
                        <mat-checkbox color="primary" formControlName="is_select_unique_task_content_files">{{ "Do not take files if they are already in any stream within the same card" | translate }}</mat-checkbox>
                    </div>
                    <div class="box_wrp">
                        <mat-checkbox color="primary" formControlName="is_select_unique_company_content_files">{{ "Do not take files if they are already used in any the stream within the entire workspace" | translate }}</mat-checkbox>
                    </div>

                    <ng-container formGroupName="publishing_params">
                        <div class="box_wrp">
                            <mat-checkbox color="primary" formControlName="is_loop">{{ "Loop" | translate }}</mat-checkbox>
                        </div>
                        <div class="box_wrp">
                            <mat-checkbox color="primary" formControlName="is_random">{{ "Random" | translate }}</mat-checkbox>
                        </div>
                        <mat-form-field appearance="standard" class="full_width_form" *ngIf="!!form.get('scenario_data').get('publishing_params').get('is_random').value">
                            <mat-label>{{ 'Start streaming when files are ready (pcs)' | translate }}</mat-label>
                            <input formControlName="random_start_count" type="number" matInput placeholder="{{ 'Start streaming when files are ready (pcs)' | translate }}">
                        </mat-form-field>
        
                        <!-- <mat-divider style="margin: 15px 0;"></mat-divider> -->
        
                        <div class="box_wrp">
                            <mat-checkbox color="primary" formControlName="is_backup">{{ "Additional stream from backup server" | translate }}</mat-checkbox>
                        </div>
            
                        <div class="block_w8">
        
                            <div class="label_w8">{{"Stream settings" | translate}}</div>
        
                            <div class="box_wrp">
                                <mat-checkbox color="primary" formControlName="made_for_kids">{{ "Made for kids" | translate }}</mat-checkbox>
                            </div>
                            <div class="box_wrp">
                                <mat-checkbox color="primary" formControlName="enable_auto_start">{{ "Enable auto start" | translate }}</mat-checkbox>
                            </div>
                            <div class="box_wrp">
                                <mat-checkbox color="primary" formControlName="enable_auto_stop">{{ "Enable auto stop" | translate }}</mat-checkbox>
                            </div>
                            <div class="box_wrp">
                                <mat-checkbox color="primary" formControlName="enable_dvr">{{ "Enable dvr" | translate }}</mat-checkbox>
                            </div>
                            <div class="box_wrp">
                                <mat-checkbox color="primary" formControlName="record_from_start">{{ "Enable recording" | translate }}</mat-checkbox>
                            </div>
                        </div>

                        <div class="tik_tok_wrp" style="margin-top: 20px;">
                            <div class="tik_title">
                                {{"Privacy" | translate}}
                            </div>
                            <mat-button-toggle-group class="tik_tok_btn_group" formControlName="privacy_status">
                              <mat-button-toggle [value]="'public'">{{"Public" | translate}}</mat-button-toggle>
                              <mat-button-toggle [value]="'private'">{{"Private" | translate}}</mat-button-toggle>
                              <mat-button-toggle [value]="'unlisted'">{{"Unlisted" | translate}}</mat-button-toggle>
                            </mat-button-toggle-group>
                        </div>
                    </ng-container>
                </ng-container>
            </form>
        </ng-container>
        
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <div class="f_w_p_btns">
        <button mat-button (click)="close()">
            {{ "Close" | translate }}
        </button>
        <button class="ml-1 d-f-btn" (click)="onSubmit()" cdkFocusInitial mat-raised-button color="primary" [disabled]="isSubmit">
            <mat-spinner class="spin_16 mr-1" *ngIf="isSubmit"></mat-spinner>
            <ng-container *ngIf="!!data.tmpl; else elseTemplateAddSbmt">
                {{ "Edit" | translate }}
            </ng-container>
            <ng-template #elseTemplateAddSbmt>
                {{ "Add" | translate }}
            </ng-template>
        </button>
    </div>
</mat-dialog-actions>