import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';

@Component({
  selector: 'app-task-comment-edit',
  templateUrl: './task-comment-edit.component.html',
  styleUrls: ['./task-comment-edit.component.scss']
})
export class TaskCommentEditComponent extends BaseClass implements OnInit, OnDestroy {
  public form: FormGroup;
  public statuses: any;
  public isFormChange: boolean = false;
  public selectedText: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private taskService: TaskService,
    public dialogRef: MatDialogRef<TaskCommentEditComponent>,
    public layoutService: LayoutService
  ) { super() }

  ngOnInit(): void {
    console.log("TaskCommentEditComponent", this.data)
    if (!this.data.target) {
      this.form = this.fb.group({
        name: [this.data.name, Validators.required],
        comment: this.data.comment.replaceAll("\n","<br>"),
        private_comment: this.data.private_comment.replaceAll("\n","<br>")
      })
    } else {
      this.form = this.fb.group({
        [this.data.target]: ['comment', 'private_comment'].includes(this.data.target) ? (this.data.task[this.data.target] != null ? this.data.task[this.data.target].replaceAll("\n","<br>") : '') : (this.data.task[this.data.target] != null ? this.data.task[this.data.target] : '')
      })
    }


    this.attachSubscriptions(
      this.form.valueChanges.subscribe(() => this.isFormChange = true)
    )

    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        if (this.isFormChange) {
          this.layoutService.openBottomSheet(this.dialogRef);
        } else {
          this.close();
        }
      })
    )
  }

  
  goTolink(e) {
    console.log(e);
    console.log(this.selectedText);
    if (this.selectedText && this.selectedText.state && this.selectedText.state.link) {
      window.open(this.selectedText.state.link, '_blank')
    }
  }

  formatText(key) {
    if (key == 'createLink') {

      const from = this.selectedText.state.linkNode;
      console.log(this.selectedText);
      console.log("!!from", from);
      if (!!from) {
        let sel = window.getSelection();
        const range = document.createRange();
        var linkURL = prompt('Enter a URL:', this.selectedText.state.link ? this.selectedText.state.link : '');
        // document.getSelection().removeAllRanges();
        from.childNodes.forEach(element => {
          console.log("childNodes", element)
          // range.selectNode(element);
        });
        sel.removeAllRanges();
        range.selectNodeContents(from)
        
        sel.addRange(range);
        var sText = sel.toString();
        
        console.log('sText', sText);
        console.log('linkURL', linkURL);
        if (linkURL !== null && linkURL != '') {
          document.execCommand('insertHTML', false, '<a href="' + linkURL + '" target="_blank">' + sText + '</a>');
        } else {
          // let sel2 = window.getSelection();
          // let range2 = document.createRange();
          // sel2.removeAllRanges();
          // range2.selectNodeContents(from)
          // sel2.addRange(range2);
          // // sel2.removeAllRanges();
          document.execCommand('unlink', false, null);
          sel.removeAllRanges();
        }
        console.log("!!from", from);
      } else {
        var linkURL = prompt('Enter a URL:', '');
        var sText = document.getSelection().toString();
        if (linkURL !== null && linkURL != '') {
          document.execCommand('insertHTML', false, '<a href="' + linkURL + '" target="_blank">' + sText + '</a>');
        }
      }
      this.mouseUp()
    } else {
      document.execCommand(key, false, null);
      this.mouseUp()
    }
  }

  onPaste(e) {
    if (e && e.clipboardData && ((!!e.clipboardData.getData('text/html') && e.clipboardData.getData('text/html').indexOf('on_reports_copy') == -1) || e.clipboardData.getData('text/html') == '')) {
      e.preventDefault();
      const text = e.clipboardData.getData('text/plain').replaceAll("\n","<br>");
      document.execCommand('insertHTML', false, text);
    }
    this.mouseUp()
  }

  onCopy(event) {
    const selection = document.getSelection();
    if (selection) {
      const range = selection.getRangeAt(0);
      const clonedContent = range.cloneContents();
  
      const wrapperDiv = document.createElement('div');
      const span = document.createElement('span');
      span.classList.add('on_reports_copy')
      wrapperDiv.appendChild(clonedContent);
      wrapperDiv.appendChild(span);
  
      event.clipboardData?.setData('text/html', wrapperDiv.innerHTML);
      event.clipboardData?.setData('text/plain', selection?.toString().replace(/<br>|<BR>|<\/br>|<\/BR>/gi, '\n'));
      
      event.preventDefault();
    }
  }

  mouseUp(e?) {
    let selection = document.getSelection();
    let range = selection.getRangeAt(0);
    
    let clientRects = range.getClientRects();

    let posArr = [];
    Object.keys(clientRects).forEach(key => {
      posArr.push({x: clientRects[key].x + clientRects[key].width/2 , y: clientRects[key].y})
    });

    let pos = {
      x: Math.round(posArr.map(n => n.x).reduce((a, b) => +a + +b, 0) / posArr.length),
      y: Math.round(posArr.map(n => n.y).reduce((a, b) => +a + +b, 0) / posArr.length),
    }


    this.selectedText = {
      is_selected: selection.toString().trim().length > 0,
      position: pos,
      state: {
        bold: document.queryCommandState('bold'),
        italic: document.queryCommandState('italic'),
        underline: document.queryCommandState('underline'),
        strikeThrough: document.queryCommandState('strikeThrough'),
        insertOrderedList: document.queryCommandState('insertOrderedList'),
        insertUnorderedList: document.queryCommandState('insertUnorderedList'),
        createLink: this.isLink(),
        link: this.isLink() ? this.getLink() : "",
        linkNode: this.isLink() ? this.getLinkNode() : null
      }
    }
  }

  closeBtn() {
    if (this.isFormChange) {
      this.layoutService.openBottomSheet(this.dialogRef);
    } else {
      this.close();
    }
  }

  getLink() {
    const selection = document.getSelection()
    const startA = selection.anchorNode.parentElement.getAttribute('href')
    const endA = selection.focusNode.parentElement.getAttribute('href')
    
    const altStartA = selection.anchorNode
    const altEndA = selection.anchorNode

    if (altStartA.nodeName === 'A' || altEndA.nodeName === 'A') {
      return altStartA['href'] || altEndA['href']
    }
    return startA || endA
  }

  getLinkNode() {
    const selection = document.getSelection()
    const startA = selection.anchorNode.parentElement
    const endA = selection.focusNode.parentElement
    const altStartA = selection.anchorNode
    const altEndA = selection.anchorNode

    if (altStartA.nodeName === 'A' || altEndA.nodeName === 'A') {
      return altStartA || altEndA
    }
    return startA || endA
  }


  isLink () {
    const selection = document.getSelection()
    const startA = selection.anchorNode.parentElement.tagName === 'A'
    const endA = selection.focusNode.parentElement.tagName === 'A'
    const altStartA = selection.anchorNode.nodeName === 'A'
    const altEndA = selection.anchorNode.nodeName === 'A'

    // console.log("isLink selection", selection);
    // console.log("isLink startA", selection.anchorNode.parentElement);
    // console.log("isLink endA", selection.focusNode.parentElement);
    return startA || endA || altStartA || altEndA
  }

  close() {
    this.dialogRef.close({event: "close", data: false});
  }

  submitForm() {
    this.attachSubscriptions(
      this.taskService.editTask(!this.data.target ? this.data.id : this.data.task.id, this.form.value, this.data.company.id).subscribe(resp => {
        this.dialogRef.close({event: "Edit", data: this.form.value})
      },error => {
        console.log(error);
        this.layoutService.showSnackBar({name: 'Failed'}, error, SnackBarItem)
      })
    )
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
