<h2 mat-dialog-title class="dialog_title">
    <span>
        {{data.channel.name}} {{ "channels" | translate }}:
    </span>
    <button mat-icon-button style="margin-left: 30px;" (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h2>
<mat-dialog-content class="mat-typography">
    <div class="container">
        <div class="tabs" *ngIf="data.channel.type == 'facebook'">
            <div class="tab" (click)="changeTab('facebook')" [ngClass]="{'active': type == 'facebook'}">
                {{"Facebook" | translate}}
            </div>
            <div class="tab" (click)="changeTab('instagram')" [ngClass]="{'active': type == 'instagram'}">
                {{"Instagram" | translate}}
            </div>
        </div>
        <mat-nav-list *ngIf="expChannels?.length" class="connected_channels" [appDeviceClass]="'custom-scroll custom-scroll-t'">
            <mat-list-item class="company_item" *ngFor="let item of expChannels; let i = index;">
                <div class="d-f-c" style="justify-content: space-between; width: 100%; position: relative;">
                    <app-outlet-line [channel]="item" [company_id]="0"></app-outlet-line>
                    <div class="is_active" [ngClass]="{'red': !item.is_active}">
                        {{item.is_active ? "Authorized" : "Not authorized" | translate}}
                    </div>
                    <div class="out_let">
                        <ng-container *ngFor="let chan of item.channels; let last = last;">
                            <app-outlet-line [channel]="chan" [company_id]="chan.company_id"></app-outlet-line><ng-container *ngIf="!last">, </ng-container>
                        </ng-container>
                    </div>
                    <a *ngIf="item.computedUrl && item.computedUrl != 'https://www.youtube.com/'" target="_blank" (click)="$event.stopPropagation()" [href]="item.computedUrl">
                        <mat-icon>open_in_new</mat-icon>
                    </a>
                </div>
            </mat-list-item>
        </mat-nav-list>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button (click)="close()">
        {{ "Close" | translate }}
    </button>
    <button class="ml-1" (click)="onUpdate()" mat-raised-button cdkFocusInitial color="primary" [disabled]="data.channel.channels_updated_at == 0">
        <div class="d-f-c">
            <ng-container *ngIf="data.channel.channels_updated_at == 0; else elseTemplateUpd">
                <mat-spinner class="spin_16 mr-1"></mat-spinner>  
                {{ "Updating" | translate }}
            </ng-container>
            <ng-template #elseTemplateUpd>
                {{ "Update" | translate }}
            </ng-template>
        </div>
    </button>
</mat-dialog-actions>
