<h2 mat-dialog-title class="dialog_title">
    <span>
        {{ "The reason for returning the job to executor" | translate }}:
    </span>
</h2>
<mat-dialog-content class="mat-typography">
    <div class="container">
        <ng-container *ngIf="data.job?.chatsInfo?.length; else elseTemplateHasChats">
            <div class="reason_wrp">
                <p>
                    {{ "Setting the status" | translate }}
                    <span [ngClass]="{
                        'first': data.status?.id == '1',
                        'second': data.status?.id == '2',
                        'third': data.status?.id == '3',
                        'fours': data.status?.id == '4',
                        'fives': data.status?.id == '5',
                        'sixs': data.status?.id == '6',
                        'nine': data.status?.id == '97',
                        'seven': data.status?.id == '98',
                        'eight': data.status?.id == '99'}">{{data.status.name}}</span>
                    {{ "you are returning the job to executor." | translate }}<br>
                    {{ "Please, explain the reason why you decided to change the status." | translate }}
                </p>

                <div class="reson_nav">
                    <div (click)="chatsTab = true;" [ngClass]="{'active': chatsTab}">
                        {{ "Send message in existing chats" | translate }}
                    </div>
                    <div class="or">
                        {{ "or" | translate }}
                    </div>
                    <div (click)="chatsTab = false;" [ngClass]="{'active': !chatsTab}">
                        {{ "Create new chat" | translate }}
                    </div>
                </div>

                <ng-container *ngIf="chatsTab; else elseTemplateChatsTab">
                    <div class="chats_wrp" #chatsContainer>
                        <div class="chats_out" *ngFor="let chat of chats" [ngClass]="{
                            'highlight_chat': chat.is_read == '0', 
                            'opened': chat.is_open,
                            'first': data.status?.id == '1',
                            'second': data.status?.id == '2',
                            'third': data.status?.id == '3',
                            'fours': data.status?.id == '4',
                            'fives': data.status?.id == '5',
                            'sixs': data.status?.id == '6',
                            'nine': data.status?.id == '97',
                            'seven': data.status?.id == '98',
                            'eight': data.status?.id == '99'}">
                            <div class="priority_line" [ngClass]="{
                                'Urgent': chat?.priority == '1000',
                                'High': chat?.priority == '900',
                                'Middle': chat?.priority == '800',
                                'Low': chat?.priority == '700',
                                'No': chat?.priority == '0' || !chat?.priority
                            }"></div>
                            <div class="chat" (click)="openChat(chat)">
                                <div class="chat_creator">
                                    <div class="chat_user" *ngIf="!!chat?.employee">
                                        <ng-container *ngIf="!!chat?.employee?.image; else elseOwnerTemplate">
                                          <img [src]="chat?.employee?.image" alt="">
                                        </ng-container>
                                        <ng-template #elseOwnerTemplate>
                                            <mat-icon>account_circle</mat-icon>
                                        </ng-template>
                                        <div class="created_info">
                                            <div class="text_one_line">{{chat?.employee?.name}}</div>
                                            <div
                                            matTooltip="{{ chat?.created_at*1000 | date:'h:mm a':'timeZone':activeLang }} "
                                            [matTooltipPosition]="'left'">
                                                {{ chat?.created_at*1000 | date:'MMM d, y':'timeZone':activeLang | yearPipe }} 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="chat_status">
                                  <div style="width: 100%">
                                    <ng-container *ngIf="chat?.is_open; else elseTemplateOpnSt">
                                        <div class="inside_opened_job" [ngClass]="{
                                            'first':data.status?.id == '1',
                                            'second':data.status?.id == '2',
                                            'third':data.status?.id == '3',
                                            'fours':data.status?.id == '4',
                                            'fives':data.status?.id == '5',
                                            'sixs':data.status?.id == '6',
                                            'nine':data.status?.id == '97',
                                            'seven':data.status?.id == '98',
                                            'eight':data.status?.id == '99'}">
                                            <span class="text_one_line">
                                                {{ data.status?.name | translate }} 
                                            </span>  
                                            <svg style="margin-left: 3px;" width="6" height="5" viewBox="0 0 6 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M5.9165 0.333313L0.0831699 0.333313L2.99984 4.91665L5.9165 0.333313Z" fill="white"/>
                                            </svg>                                                    
                                        </div>
                                    </ng-container>
                                    <ng-template #elseTemplateOpnSt>
                                        <div class="inside_job" [ngClass]="{
                                            'first': chat?.status_id == '1',
                                            'second': chat?.status_id == '2',
                                            'third': chat?.status_id == '3',
                                            'fours': chat?.status_id == '4',
                                            'fives': chat?.status_id == '5',
                                            'sixs': chat?.status_id == '6',
                                            'nine': chat?.status_id == '97',
                                            'seven': chat?.status_id == '98',
                                            'eight': chat?.status_id == '99'}">
                                            <span class="text_one_line">{{ getOperationStatusName(chat?.status_id) | translate }}</span>  
                                        </div>
                                    </ng-template>
                                  </div>
                                </div>
                                <div *ngIf="chat?.is_open" class="chat_opened_name">
                                    <span class="text_one_line">
                                        {{ chat?.name }}
                                    </span>
                                    <mat-icon ngxClipboard [cbContent]="origin + '/chat/' + chat.acm" (click)="$event.stopPropagation(); copyLink('Chat')">link</mat-icon>
                                </div>
                                <ng-container *ngIf="!chat?.is_open">
                                    <div class="chat_data">
                                        <div class="chat_name text_one_line">
                                            {{ chat?.name }}
                                        </div>
                    
                                        <ng-container *ngIf="chat?.lastPost?.action; else elseTemplateHasAction">
                                          <div class="chat_last_post">
                    
                                            <ng-container *ngIf="!!chat?.lastPost?.action?.uscreatedEmployeeer?.image; else elseOwnerTemplateLastPost">
                                                <img class="mr-5" [src]="chat?.lastPost?.action?.createdEmployee?.image" alt="">
                                            </ng-container>
                                            <ng-template #elseOwnerTemplateLastPost>
                                                <mat-icon class="mr-5">account_circle</mat-icon>
                                            </ng-template>
                    
                                            {{ 'switched to' | translate }} 
                                            <ng-container [ngSwitch]="chat?.lastPost?.action?.action">
                                              <ng-container *ngSwitchCase="'task_employee_status_change'">
                                                <b style="margin-left: 5px; margin-right: 5px;">{{ getOperationStatusName(chat?.lastPost?.action?.task_employee_status_id) }}</b>
                                                {{ "for" | translate }}
                                                <ng-container *ngIf="!!chat?.lastPost?.action?.employee?.image; else elseActionOwnerTemplate">
                                                    <img class="ml-5" [src]="chat?.lastPost?.action?.employee?.image" alt="">
                                                </ng-container>
                                                <ng-template #elseActionOwnerTemplate>
                                                    <mat-icon class="ml-5">account_circle</mat-icon>
                                                </ng-template>
                                              </ng-container>
                                              <ng-container *ngSwitchCase="'discussion_status'">
                                                 <b style="margin-left: 5px;">{{ getOperationStatusName(chat?.lastPost?.action?.discussion_status_id) }}</b>
                                              </ng-container>
                                            </ng-container>
                                          </div>
                                        </ng-container>
                    
                                        <ng-template #elseTemplateHasAction>
                                          <div class="chat_last_post">
                                            <ng-container *ngIf="!!chat?.lastPost?.employee?.image; else elseOwnerTemplateLastPost">
                                                <img [src]="chat?.lastPost?.employee?.image" alt="">
                                            </ng-container>
                                            <ng-template #elseOwnerTemplateLastPost>
                                                <mat-icon>account_circle</mat-icon>
                                            </ng-template>
                                            
                                            <div class="last_post_file" *ngIf="!!chat?.lastPost?.file_id">
                                              <div class="file_view">
                                                <ng-container [ngSwitch]="understandFileType(chat?.lastPost?.file?.content_type)">
                                                    <ng-container *ngSwitchCase="'video'">
                                                        <div class="file movie d-f-c">
                                                            <ng-container *ngIf="chat?.lastPost?.file?.thumbnail; else elseTemplateThumbnail">
                                                                <div class="movie_thumb" >
                                                                    <img style="max-width: 100%; max-height: 100%;" (error)="onImgError($event)" [src]="data?.host + chat?.lastPost?.file?.thumbnail + '?company_id=' + data?.company?.id">
                                                                </div>
                                                            </ng-container>
                                                            <ng-template #elseTemplateThumbnail>
                                                                <mat-icon class="file_img_icon" style="color: #3f51b5">
                                                                    movie
                                                                </mat-icon>
                                                            </ng-template>
                                                        </div>
                                                    </ng-container>
                                                    <ng-container *ngSwitchCase="'pdf'">
                                                        <div class="pdf">
                                                            <svg height="800px" width="800px" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
                                                                viewBox="0 0 303.188 303.188" xml:space="preserve">
                                                                <g>
                                                                    <polygon style="fill:#E8E8E8;" points="219.821,0 32.842,0 32.842,303.188 270.346,303.188 270.346,50.525 	"/>
                                                                    <path style="fill:#FB3449;" d="M230.013,149.935c-3.643-6.493-16.231-8.533-22.006-9.451c-4.552-0.724-9.199-0.94-13.803-0.936
                                                                        c-3.615-0.024-7.177,0.154-10.693,0.354c-1.296,0.087-2.579,0.199-3.861,0.31c-1.314-1.36-2.584-2.765-3.813-4.202
                                                                        c-7.82-9.257-14.134-19.755-19.279-30.664c1.366-5.271,2.459-10.772,3.119-16.485c1.205-10.427,1.619-22.31-2.288-32.251
                                                                        c-1.349-3.431-4.946-7.608-9.096-5.528c-4.771,2.392-6.113,9.169-6.502,13.973c-0.313,3.883-0.094,7.776,0.558,11.594
                                                                        c0.664,3.844,1.733,7.494,2.897,11.139c1.086,3.342,2.283,6.658,3.588,9.943c-0.828,2.586-1.707,5.127-2.63,7.603
                                                                        c-2.152,5.643-4.479,11.004-6.717,16.161c-1.18,2.557-2.335,5.06-3.465,7.507c-3.576,7.855-7.458,15.566-11.815,23.02
                                                                        c-10.163,3.585-19.283,7.741-26.857,12.625c-4.063,2.625-7.652,5.476-10.641,8.603c-2.822,2.952-5.69,6.783-5.941,11.024
                                                                        c-0.141,2.394,0.807,4.717,2.768,6.137c2.697,2.015,6.271,1.881,9.4,1.225c10.25-2.15,18.121-10.961,24.824-18.387
                                                                        c4.617-5.115,9.872-11.61,15.369-19.465c0.012-0.018,0.024-0.036,0.037-0.054c9.428-2.923,19.689-5.391,30.579-7.205
                                                                        c4.975-0.825,10.082-1.5,15.291-1.974c3.663,3.431,7.621,6.555,11.939,9.164c3.363,2.069,6.94,3.816,10.684,5.119
                                                                        c3.786,1.237,7.595,2.247,11.528,2.886c1.986,0.284,4.017,0.413,6.092,0.335c4.631-0.175,11.278-1.951,11.714-7.57
                                                                        C231.127,152.765,230.756,151.257,230.013,149.935z M119.144,160.245c-2.169,3.36-4.261,6.382-6.232,9.041
                                                                        c-4.827,6.568-10.34,14.369-18.322,17.286c-1.516,0.554-3.512,1.126-5.616,1.002c-1.874-0.11-3.722-0.937-3.637-3.065
                                                                        c0.042-1.114,0.587-2.535,1.423-3.931c0.915-1.531,2.048-2.935,3.275-4.226c2.629-2.762,5.953-5.439,9.777-7.918
                                                                        c5.865-3.805,12.867-7.23,20.672-10.286C120.035,158.858,119.587,159.564,119.144,160.245z M146.366,75.985
                                                                        c-0.602-3.514-0.693-7.077-0.323-10.503c0.184-1.713,0.533-3.385,1.038-4.952c0.428-1.33,1.352-4.576,2.826-4.993
                                                                        c2.43-0.688,3.177,4.529,3.452,6.005c1.566,8.396,0.186,17.733-1.693,25.969c-0.299,1.31-0.632,2.599-0.973,3.883
                                                                        c-0.582-1.601-1.137-3.207-1.648-4.821C147.945,83.048,146.939,79.482,146.366,75.985z M163.049,142.265
                                                                        c-9.13,1.48-17.815,3.419-25.979,5.708c0.983-0.275,5.475-8.788,6.477-10.555c4.721-8.315,8.583-17.042,11.358-26.197
                                                                        c4.9,9.691,10.847,18.962,18.153,27.214c0.673,0.749,1.357,1.489,2.053,2.22C171.017,141.096,166.988,141.633,163.049,142.265z
                                                                        M224.793,153.959c-0.334,1.805-4.189,2.837-5.988,3.121c-5.316,0.836-10.94,0.167-16.028-1.542
                                                                        c-3.491-1.172-6.858-2.768-10.057-4.688c-3.18-1.921-6.155-4.181-8.936-6.673c3.429-0.206,6.9-0.341,10.388-0.275
                                                                        c3.488,0.035,7.003,0.211,10.475,0.664c6.511,0.726,13.807,2.961,18.932,7.186C224.588,152.585,224.91,153.321,224.793,153.959z"/>
                                                                    <polygon style="fill:#FB3449;" points="227.64,25.263 32.842,25.263 32.842,0 219.821,0 	"/>
                                                                    <g>
                                                                        <path style="fill:#A4A9AD;" d="M126.841,241.152c0,5.361-1.58,9.501-4.742,12.421c-3.162,2.921-7.652,4.381-13.472,4.381h-3.643
                                                                            v15.917H92.022v-47.979h16.606c6.06,0,10.611,1.324,13.652,3.971C125.321,232.51,126.841,236.273,126.841,241.152z
                                                                            M104.985,247.387h2.363c1.947,0,3.495-0.546,4.644-1.641c1.149-1.094,1.723-2.604,1.723-4.529c0-3.238-1.794-4.857-5.382-4.857
                                                                            h-3.348C104.985,236.36,104.985,247.387,104.985,247.387z"/>
                                                                        <path style="fill:#A4A9AD;" d="M175.215,248.864c0,8.007-2.205,14.177-6.613,18.509s-10.606,6.498-18.591,6.498h-15.523v-47.979
                                                                            h16.606c7.701,0,13.646,1.969,17.836,5.907C173.119,235.737,175.215,241.426,175.215,248.864z M161.76,249.324
                                                                            c0-4.398-0.87-7.657-2.609-9.78c-1.739-2.122-4.381-3.183-7.926-3.183h-3.773v26.877h2.888c3.939,0,6.826-1.143,8.664-3.43
                                                                            C160.841,257.523,161.76,254.028,161.76,249.324z"/>
                                                                        <path style="fill:#A4A9AD;" d="M196.579,273.871h-12.766v-47.979h28.355v10.403h-15.589v9.156h14.374v10.403h-14.374
                                                                            L196.579,273.871L196.579,273.871z"/>
                                                                    </g>
                                                                    <polygon style="fill:#D1D3D3;" points="219.821,50.525 270.346,50.525 219.821,0 	"/>
                                                                </g>
                                                            </svg>
                                                        </div>
                                                    </ng-container>
                                                    <ng-container *ngSwitchCase="'image'">
                                                        <div class="img_wrp">
                                                            <img (error)="onImgError($event)" [src]="data?.host + (chat?.lastPost?.file?.thumbnail ? chat?.lastPost?.file?.thumbnail + '?company_id=' + data?.company?.id : chat?.lastPost?.file?.original + '?company_id=' + data?.company?.id)" alt="">
                                                        </div>
                                                    </ng-container>
                                                    <ng-container *ngSwitchDefault>
                                                      <ng-container *ngIf="!!chat?.lastPost?.file?.thumbnail; else elseTemplateHasThumb">
                                                        <div class="img_wrp">
                                                          <img (error)="onImgError($event)" [src]="data?.host + chat?.lastPost?.file?.thumbnail + '?company_id=' + data?.company?.id" alt="">
                                                        </div>
                                                      </ng-container>
                                                      <ng-template #elseTemplateHasThumb>
                                                        <div class="file d-f-c">
                                                          <mat-icon class="file_img_icon mr-1" style="color: #3f51b5">
                                                              insert_drive_file
                                                          </mat-icon>
                                                      </div>
                                                      </ng-template>
                                                    </ng-container>
                                                </ng-container>
                                              </div>
                         
                                            </div>
                                            
                                            <div class="text_one_line" [innerHTML]='chat?.lastPost?.text ? (chat?.lastPost?.text.replaceAll("\n"," ").replaceAll("</BR>"," ").replaceAll("<BR>"," ").replaceAll("</br>"," ").replaceAll("<br>"," ")) : ""'></div>
                                            <!-- <div class="text_one_line">
                                              {{ chat?.lastPost?.text }}
                                            </div> -->
                                        </div>
                                      </ng-template>
                                       
                                    </div>
                                    <div class="connections_info">
                                        <div class="job_desc text_one_line" *ngIf="chat?.taskOperation?.name">
                                            {{ chat?.taskOperation?.name }}
                                        </div>
                                        <div class="job_type">
                                            {{ getColumnName(chat?.taskOperation?.operation_id) }}
                                        </div>
                                        <div class="task text_one_line">
                                            {{ chat?.task?.name }}
                                        </div>
                                        <div class="group" *ngIf="chat?.task?.group_id">
                                            {{ getGroup(chat?.task?.group_id).name }}
                                        </div>
                                    </div>
                                    <div class="chat_employees">
                                        
                                        <div class="block_inside" *ngIf="chat?.employees?.length > 0">
                                            <ng-container *ngFor="let employee of chat?.employees; let i = index;">
                                                <div class="chat_empl" *ngIf="i < 3"  #tooltip="matTooltip"
                                                matTooltip="{{employee?.employee?.name}}"
                                                [matTooltipPosition]="'below'" [ngStyle]="{'z-index': chat?.employees?.length - i, 'transform': 'translateX(-' + i*6 + 'px)'}">
                                                  <ng-container *ngIf="employee?.employee?.image; else elseMobWorkTemplate">
                                                    <img [src]="employee?.employee?.image" alt="">
                                                  </ng-container>
                                                  <ng-template #elseMobWorkTemplate>
                                                    <mat-icon>account_circle</mat-icon>
                                                  </ng-template>
                    
                                                  <div class="calendar" #tooltip="matTooltip"
                                                  matTooltip="{{employee?.employeeStatus?.start_at*1000 | date:'MMM dd. Y':'+0000':activeLang | yearPipe}}"
                                                  [matTooltipPosition]="'right'"
                                                  *ngIf="!!employee?.employeeStatus && !!employee?.employeeStatus?.discussion_id && [1,2,5].includes(employee?.employeeStatus?.status_id) && employee?.employeeStatus.start_at > 0">
                                                    <svg [ngClass]="{'red': employee?.employeeStatus.initial_start_at < today}" width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path d="M1.90909 0V1.27273H1.27273C0.566364 1.27273 0 1.83909 0 2.54545V11.4545C0 12.1545 0.566364 12.7273 1.27273 12.7273H5.15455C5.95636 13.5164 7.05727 14 8.27273 14C10.7355 14 12.7273 12.0082 12.7273 9.54545C12.7273 8.33 12.2436 7.22909 11.4545 6.42727V2.54545C11.4545 1.84545 10.8882 1.27273 10.1818 1.27273H9.54545V0H8.27273V1.27273H3.18182V0H1.90909ZM1.27273 2.54545H10.1818V3.81818H1.27273V2.54545ZM1.27273 5.09091H10.1818V5.51727C9.60273 5.24364 8.95364 5.09091 8.27273 5.09091C5.81 5.09091 3.81818 7.08273 3.81818 9.54545C3.81818 10.2264 3.97091 10.8755 4.24455 11.4545H1.27273V5.09091ZM8.27273 6.45909C9.97818 6.45909 11.3591 7.84 11.3591 9.54545C11.3591 11.2509 9.97818 12.6318 8.27273 12.6318C6.56727 12.6318 5.18636 11.2509 5.18636 9.54545C5.18636 7.84 6.56727 6.45909 8.27273 6.45909ZM7.63636 7.63636V9.98455L9.66636 11.1555L10.1436 10.3282L8.59091 9.43091V7.63636H7.63636Z" fill="#219653"/>
                                                    </svg>
                                                  </div>
                    
                                                </div>
                                                <ng-container *ngIf="i >= 3 && i == chat?.employees?.length - 1">
                                                    <div class="more_empl" [ngStyle]="{'z-index': chat?.employees?.length - 3, 'transform': 'translateX(-' + 2*6 + 'px)'}">
                                                        +{{chat?.employees?.length - 3}}
                                                    </div>
                                                </ng-container>
                                            </ng-container>
                                        </div>
                                    </div>
                                    <ng-container *ngIf="chat.updated_at > today; else updatedAtTempl">
                                      <div class="chat_updated_at" #tooltip="matTooltip"
                                      matTooltip="{{ chat.updated_at*1000 | date:'MMM d, y':'timeZone':activeLang | yearPipe }}"
                                      [matTooltipPosition]="'left'">
                                        {{ chat.updated_at*1000 | date:'hh:mm a':'timeZone':activeLang | yearPipe }}
                                      </div>
                                    </ng-container>
                                    <ng-template #updatedAtTempl>
                                      <div class="chat_updated_at" #tooltip="matTooltip"
                                      matTooltip="{{ chat.updated_at*1000 | date:'hh:mm a':'timeZone':activeLang | yearPipe }}"
                                      [matTooltipPosition]="'left'">
                                        {{ chat.updated_at*1000 | date:'MMM d, y':'timeZone':activeLang | yearPipe }}
                                      </div>
                                    </ng-template>
                                    <div class="is_pin">
                                        <ng-container *ngIf="!chat?.pin; else elseTemplate">
                                          <button class="pin_btn" mat-icon-button (click)="pinChat($event, chat)">
                                            <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M7.2 1.6V5.6C7.2 6.496 7.496 7.328 8 8H3.2C3.72 7.312 4 6.48 4 5.6V1.6H7.2ZM9.6 0H1.6C1.16 0 0.8 0.36 0.8 0.8C0.8 1.24 1.16 1.6 1.6 1.6H2.4V5.6C2.4 6.928 1.328 8 0 8V9.6H4.776V15.2L5.576 16L6.376 15.2V9.6H11.2V8C9.872 8 8.8 6.928 8.8 5.6V1.6H9.6C10.04 1.6 10.4 1.24 10.4 0.8C10.4 0.36 10.04 0 9.6 0Z" fill="#A3A3A3"/>
                                            </svg>
                                          </button>
                                        </ng-container>
                                        <ng-template #elseTemplate>
                                          <button mat-icon-button (click)="unPinChat($event, chat)">
                                            <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <rect x="3" y="1" width="5" height="8" fill="#A3A3A3"/>
                                              <path d="M7.2 1.6V5.6C7.2 6.496 7.496 7.328 8 8H3.2C3.72 7.312 4 6.48 4 5.6V1.6H7.2ZM9.6 0H1.6C1.16 0 0.8 0.36 0.8 0.8C0.8 1.24 1.16 1.6 1.6 1.6H2.4V5.6C2.4 6.928 1.328 8 0 8V9.6H4.776V15.2L5.576 16L6.376 15.2V9.6H11.2V8C9.872 8 8.8 6.928 8.8 5.6V1.6H9.6C10.04 1.6 10.4 1.24 10.4 0.8C10.4 0.36 10.04 0 9.6 0Z" fill="#A3A3A3"/>
                                            </svg>
                                          </button>
                                        </ng-template>
                                        
                                        
                                    </div>
                                </ng-container>
                                
                            </div>
                            <div class="chat_block" *ngIf="chat.is_open">
                                <app-chat 
                                [chat_id]='chat_id'
                                [chats]='chats'
                                [timeZone]='timeZone'
                                [statuses]='data.statuses'
                                [activeLang]='data.activeLang'
                                [company]='data.company'
                                [user]='data?.user'
                                [task]="data?.task"
                                [work]="data?.job"
                                [tasks]="data?.tasks"
                                [is_mobile]='data?.is_mobile'
                                [is_small]='true'
                                [mustChangeStatus]="data.status"
                                [emplSelected]="emplSelected"
                                [dialogComp]='[dialogRef]'
                                [operationsValues]='data.operationsValues'
                                (HideMenu)="hideMenu()"
                                ></app-chat>
                            </div>
                        </div>
                    </div>
                    <div class="reason_btns">
                        <button mat-raised-button (click)="close()">
                            {{ "Close" | translate }}
                        </button>
                        <button class="ml-1" (click)="save()" mat-raised-button color="primary">
                            {{ "Save" | translate }}
                        </button>
                    </div>
                </ng-container>
                <ng-template #elseTemplateChatsTab>
                    <form [formGroup]="form">
                        <mat-form-field appearance="standard" class="full_width_form">
                            <mat-label>{{ 'Subject - in few words define the matter why you can`t accept the job' | translate }}</mat-label>
                            <input cdkFocusInitial formControlName="name" matInput type="text" placeholder="{{ 'Subject - in few words define the matter why you can`t accept the job' | translate }}">
                        </mat-form-field>
            
                        <mat-form-field appearance="standard" class="full_width_form">
                            <mat-label>{{ 'Message to executor about what should be done and what you expect' | translate }}</mat-label>
                            <textarea formControlName="text" matInput placeholder="{{ 'Message to executor about what should be done and what you expect' | translate }}"
                            cdkTextareaAutosize
                            #autosize="cdkTextareaAutosize"
                            cdkAutosizeMinRows="1"
                            cdkAutosizeMaxRows="5"></textarea>
                        </mat-form-field>
    
                        <div class="priority_chips">
                            <label>{{ "Priority" | translate }}: </label>
                            <mat-chip-list [multiple]="false" selectable formControlName="priority">
                                <mat-chip *ngFor="let priority of prioritys" [ngClass]="{
                                    'Urgent': priority.id == '1000',
                                    'High': priority.id == '900',
                                    'Middle': priority.id == '800',
                                    'Low': priority.id == '700',
                                    'No': priority.id == '0'
                                }" class="priority_chip" (click)="selectPrioritys(priority)" [value]="priority.id">
                                    {{ priority.name | translate }}
                                </mat-chip>
                            </mat-chip-list>
                        </div>
                
    
                    </form>
        
                    <div class="reason_btns">
                        <button mat-raised-button (click)="close()">
                            {{ "Close" | translate }}
                        </button>
                        <button class="ml-1" (click)="createChat()" mat-raised-button color="primary">
                            {{ "Save" | translate }}
                        </button>
                    </div>
                </ng-template>
                
            </div>
        </ng-container>
        <ng-template #elseTemplateHasChats>
            <div class="reason_wrp">
                <p>
                    {{ "Setting the status" | translate }}
                    <span [ngClass]="{
                        'first': data.status?.id == '1',
                        'second': data.status?.id == '2',
                        'third': data.status?.id == '3',
                        'fours': data.status?.id == '4',
                        'fives': data.status?.id == '5',
                        'sixs': data.status?.id == '6',
                        'nine': data.status?.id == '97',
                        'seven': data.status?.id == '98',
                        'eight': data.status?.id == '99'}">{{data.status.name}}</span>
                    {{ "you are returning the job to executor." | translate }}<br>
                    {{ "Send message to executor to explain the reason why you decided to change the status." | translate }}<br>
                    {{ "This will help executor to understand what you expect to be done or fixed." | translate }}<br>
                    {{ "You both can continue chattting duscussing the matter." | translate }}
                </p>

                <form [formGroup]="form">
                    <mat-form-field appearance="standard" class="full_width_form">
                        <mat-label>{{ 'Subject - in few words define the matter why you can`t accept the job' | translate }}</mat-label>
                        <input cdkFocusInitial formControlName="name" matInput type="text" placeholder="{{ 'Subject - in few words define the matter why you can`t accept the job' | translate }}">
                    </mat-form-field>
        
                    <mat-form-field appearance="standard" class="full_width_form">
                        <mat-label>{{ 'Message to executor about what should be done and what you expect' | translate }}</mat-label>
                        <textarea formControlName="text" matInput placeholder="{{ 'Message to executor about what should be done and what you expect' | translate }}"
                        cdkTextareaAutosize
                        #autosize="cdkTextareaAutosize"
                        cdkAutosizeMinRows="1"
                        cdkAutosizeMaxRows="5"></textarea>
                    </mat-form-field>

                    <div class="priority_chips">
                        <label>{{ "Priority" | translate }}: </label>
                        <mat-chip-list [multiple]="false" selectable formControlName="priority">
                            <mat-chip *ngFor="let priority of prioritys" [ngClass]="{
                                'Urgent': priority.id == '1000',
                                'High': priority.id == '900',
                                'Middle': priority.id == '800',
                                'Low': priority.id == '700',
                                'No': priority.id == '0'
                            }" class="priority_chip" (click)="selectPrioritys(priority)" [value]="priority.id">
                                {{ priority.name | translate }}
                            </mat-chip>
                        </mat-chip-list>
                    </div>
            

                </form>
    
                <div class="reason_btns">
                    <button mat-raised-button (click)="close()">
                        {{ "Close" | translate }}
                    </button>
                    <button class="ml-1" (click)="createChat()" mat-raised-button color="primary">
                        {{ "Save" | translate }}
                    </button>
                </div>
            </div>
        </ng-template>
    </div>
</mat-dialog-content>

