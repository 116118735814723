import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { concat, forkJoin, from, of, Subscription } from 'rxjs';
import { concatMap, debounceTime, last, map, switchMap, tap } from 'rxjs/operators';
import { DeleteAlertComponent } from 'src/app/shared/global_components/delete-alert/delete-alert.component';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { AuthService } from 'src/app/shared/services/rest/auth.service';
import { ParametersService } from 'src/app/shared/services/rest/parameters.service';
import { RootService } from 'src/app/shared/services/rest/root.service';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { SelectionModel } from '@angular/cdk/collections';
import { LoadingService } from 'src/app/shared/services/rest/loading.service';
import { MY_FORMATS } from 'src/app/components/atTasksDialog/task-profile-add/task-profile-add.component';
import { CompanyService } from 'src/app/shared/services/rest/company.service';

@Component({
  selector: 'app-urls-analytic',
  templateUrl: './urls-analytic.component.html',
  styleUrls: ['./urls-analytic.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ]
})
export class UrlsAnalyticComponent extends BaseClass implements OnInit, OnDestroy {
  throttle = 100;
  scrollUpDistance = 3.5;
  public host: any = environment.host;
  public imgRoute: any = '';
  public isExpanded: boolean = true;
  public activePage: any;
  public companyes: any;
  public taskTypes: any;
  public jobTypes: any;
  public servers: any;

  public usersPagination: any;
  public usersPage: number = 1;

  public companyesPagination: any;
  public companyesPage: number = 1;

  public serversPagination: any;
  public serversPage: number = 1;

  public serversCollection = new SelectionModel(
    true,
    []
  )

  public today = moment().set({hour:0,minute:0,second:0}).unix();
  public todayEnd = moment().endOf('day').unix();
  
  public company_id: any;
  public company: any;
  public pagination: any;
  public page: number = 1;
  public allValues: any[] = [];

  public isLoadComp: boolean = false;
  public isLoadServ: boolean = false;
  public isLoad: boolean = false;
  public users: any;
  public locks: any;
  public parameters: any;
  public activeLang: any;
  public user: any;
  
  public isLoadGroups: boolean = false;
  public groups: any;
  public groupsControl: FormControl = new FormControl();
  public groupsPagination: any;
  public groupsPage: number = 1;

  public allContentTypes: any;
  public profiles: any;

  public groupsFilter: FormGroup = this.fb.group({
    order_by: 'id_desc',
    count: '',
    q: '',
    content_type_id: '',
    min_farthest_content_published_at_from: '',
    max_farthest_content_published_at_to: '',
    channel_id: [[]],
    id: "",
  })
  
  public originalControl: FormControl = new FormControl('0');
  public valuesControl: FormControl = new FormControl();
  public groupsTagsControl: FormControl = new FormControl('');
  public tagsControl: FormControl = new FormControl('');
  public paramDataSub: Subscription;
  public uploadMethod: FormControl = new FormControl(!!this.sm.localStorageGetItem("smartUpload") ? '' : 1);
  public menuItems: any[] = [
    {
      icon: 'area_chart',
      name: 'Progress',
      tab: 'progress'
    }
  ]
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private auth: AuthService,
    private languageService: LanguageService,
    private rootService: RootService,
    private ls: LoadingService,
    private fb: FormBuilder,
    private companyService: CompanyService,
    private layoutService: LayoutService,
    private _adapter: DateAdapter<any>,
    private parametersService: ParametersService,
    private dialog: MatDialog,
    private bottomSheet: MatBottomSheet,
    private sm: StorageManagerService
  ) { 
    super()
   }

  ngOnInit(): void {
    this.company_id = this.activatedRoute.snapshot.queryParamMap.get('company_id');
    
    this.getUser();
    this.getCompany();
    this.getLangData();
    this.getImgRoute();
    this.getProfiles();
    this.getContentTypes();
    this.attachSubscriptions(
      this.activatedRoute.queryParams.subscribe(resp => {
        this.activePage = this.activatedRoute.snapshot.queryParamMap.get('page');

        if (this.company_id != this.activatedRoute.snapshot.queryParamMap.get('company_id')) {
          this.getProfiles();
          // this.getAllValues();
          // this.getGroupsCompany();
          // this.getProfiles();
          // this.getTaskStatus();
          // this.getProfilesStatus();
          // this.getEmployees();
          // this.getChecklists();
          // this.getPlatforms();
          // this.getContentTypes();
          this.getLangData();
        }

        this.company_id = this.activatedRoute.snapshot.queryParamMap.get('company_id');
        this.groupsFilter.patchValue({
          order_by: this.activatedRoute.snapshot.queryParamMap.get('order_by') ? this.activatedRoute.snapshot.queryParamMap.get('order_by') : 'id_desc',
          count: this.activatedRoute.snapshot.queryParamMap.get('count') ? this.activatedRoute.snapshot.queryParamMap.get('count') : '/',
          q: !!this.activatedRoute.snapshot.queryParamMap.get('q') ? this.activatedRoute.snapshot.queryParamMap.get('q') : '',
          content_type_id: !!this.activatedRoute.snapshot.queryParamMap.get('content_type_id') ? +this.activatedRoute.snapshot.queryParamMap.get('content_type_id') : '',
          min_farthest_content_published_at_from: !!this.activatedRoute.snapshot.queryParamMap.get('min_farthest_content_published_at_from') ? +this.activatedRoute.snapshot.queryParamMap.get('min_farthest_content_published_at_from') : '',
          max_farthest_content_published_at_to: !!this.activatedRoute.snapshot.queryParamMap.get('max_farthest_content_published_at_to') ? +this.activatedRoute.snapshot.queryParamMap.get('max_farthest_content_published_at_to') : '',
          channel_id: !!this.activatedRoute.snapshot.queryParamMap.get('channel_id') ? this.activatedRoute.snapshot.queryParamMap.get('channel_id').split(',').map(Number) : [],
          id: !!this.activatedRoute.snapshot.queryParamMap.get('id') ? this.activatedRoute.snapshot.queryParamMap.get('id') : '',
        })

        switch (this.activePage) {
          case 'progress':
            this.groupsPage = 1;
            this.getGroupsCompany(this.groupsPage);
            break;        
          default:
            break;
        }
        console.log(resp)
      })
    )
  }

  log() {
    console.log('groupsFilter', this.groupsFilter.value)
  }
  filterByPlans(key, val) {
    if (key == 'min_farthest_content_published_at_from') {
      this.groupsFilter.patchValue({
        min_farthest_content_published_at_from: val,
        max_farthest_content_published_at_to: ''
      })
    } else {
      this.groupsFilter.patchValue({
        min_farthest_content_published_at_from: '',
        max_farthest_content_published_at_to: val
      })
    }
    this.filterData();
  }

  removeValueFromMultiple(key, value) {
    let arr = this.groupsFilter.value[key];

    console.log(key, value);
    console.log(arr);
    
    if (arr.includes(value)) {
      arr.splice(arr.indexOf(value), 1)
    }
    console.log(arr);
    console.log(this.groupsFilter.value[key]);
    // return 
    // this.filter.patchValue({
    //   [key]: arr
    // })
    this.filterData()
  }

  removeValue(val) {
    if (val == "all") {
      let params = {
        company_id: this.company_id,
        page: this.activatedRoute.snapshot.queryParamMap.get('page')
      }
      this.router.navigate(['/urls-analytic'], {queryParams: params})
    } else {
      let params = {...this.activatedRoute.queryParamMap.source['_value']}
      delete params[val]

      this.router.navigate(['/urls-analytic'], {queryParams: params})
    }
  }

  getContentById(id) {
    if (!this.allContentTypes || this.allContentTypes.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.allContentTypes.find(el => el.id == id)
  }
  

  getProfiles() {
    this.attachSubscriptions(
      this.companyService.getAllProfilesDyn('1', this.company_id, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }
          return forkJoin(arr.map(x => this.companyService.getAllProfilesDyn(x, this.company_id, '200').pipe(map(u => u.body)))).pipe(
            last(),
            tap(values => {
              let conValues = [].concat(...values)
              this.profiles = conValues;
            }),
          )
        }),
      ).subscribe(resp => {
        console.log("getProfiles profiles", this.profiles);
      })
    )
  }

  getChannelById(id) {
    if (!this.profiles || this.profiles.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.profiles.find(el => el.id == id)
  }

  getContentTypes() {
    this.attachSubscriptions(
      this.companyService.getContentTypes().pipe(
        map(el => el.body)
      ).subscribe(resp => {
        this.allContentTypes = resp;
      })
    )
  }

  toggleGroup(item) {
    item.isOpen = !item.isOpen

    if (item.isOpen) {
      this.getChannelsToGroup(item);
    }
  }

  getChannelsToGroup(item) {
    let filter:any = {group_id: item.id};

    if (!!this.groupsFilter.value.content_type_id) {
      filter.content_type_id = this.groupsFilter.value.content_type_id;
    }

    this.attachSubscriptions(
      this.companyService.getChannelsToGroup(this.company_id, filter).pipe(
        map(el => el.body)
      ).subscribe(resp => {
        item.channelsToGroup = resp;
      })
    )
  }

  getGroupsCompany(page) {
    this.isLoadGroups = true;
    this.attachSubscriptions(
      this.companyService.getInfiniteGroupsWithFilterCompanyWithoutExp(this.company_id, page, '50', this.groupsFilter.value).pipe(
        tap(el => {
          this.groupsPagination = {
            'pageCount': el.headers.get('x-pagination-page-count'),
            'perPage': el.headers.get('x-pagination-per-page'),
            'totalCount': el.headers.get('x-pagination-total-count'),
            'currentPage': el.headers.get('x-pagination-current-page'),
          }
        }),
        map(u => u.body)
      ).subscribe(resp => {
        this.isLoadGroups = false;
        if (page == 1) {
          this.groups = [...resp]
        } else {
          this.groups.push(...resp)
        }
        console.log("groups", this.groups)
        this.groupsPage++;
      })
    )
  }

  getCompany() {
    this.attachSubscriptions(
      this.companyService.getCompany(this.company_id).subscribe(resp => {
        this.company = resp[0]
        this.companyService.company$.next(resp[0]);
      })
    )
  }

  logVal(val) {
    console.log("logVal", val)
  }
  
  sort(val) {
    this.groupsFilter.patchValue({
      order_by: val
    })

    this.filterData();
  }

  filterData() {
    let params = {
      page: this.activePage,
      company_id: this.company_id
    };

    Object.keys(this.groupsFilter.value).forEach(element => {
      if (this.groupsFilter.value[element] !== '') {
        params[element] = this.groupsFilter.value[element]
      }
    });

    this.router.navigate(['/urls-analytic'], { queryParams: params});
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData().pipe(
        tap(resp => {
          this.activeLang = resp.active;
          this._adapter.setLocale(resp.active);
        }),
      ).subscribe(resp => {
        console.log("getLangData", resp)
      })
    )
  }

  onPartnerImgError(event){
    event.target.src = this.imgRoute+'/assets/img/partner.png'
  }

  getUser() {
    this.attachSubscriptions(
      this.auth.$user.subscribe(resp => {
        this.user = resp;
      })
    )
  }

  goToMenuItem(item) {
    this.router.navigate(['/urls-analytic'], { queryParams: {page: item.tab, company_id: this.company_id}});
  }

  onDownGroups(e) {
    console.log(e?.target?.scrollTop)
    if (this.groupsPage <= this.groupsPagination['pageCount']) {
      console.log("#########onDown")
      this.getGroupsCompany(this.groupsPage);
    }
  }

  goBack() {
    history.back();
  }
  
  ngOnDestroy(): void {
    if (this.paramDataSub) {
      this.paramDataSub.unsubscribe();
    }
    this.clearSubscriptions()
  }

}
