import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import * as moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/en-gb';
import 'moment/locale/uk';
import 'moment/locale/ru';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { MembersService } from 'src/app/shared/services/rest/members.service';
import { map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { MY_FORMATS } from '../../atTasksDialog/task-profile-add/task-profile-add.component';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-job-edit-completed-at',
  templateUrl: './job-edit-completed-at.component.html',
  styleUrls: ['./job-edit-completed-at.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})

export class JobEditCompletedAtComponent extends BaseClass implements OnInit, AfterViewInit, OnDestroy {
  public isFormChange: boolean = false;
  public form: FormGroup;
  public activeLang: any;

  // @ViewChild('dateInput') dateInput: ElementRef;

  constructor(
    private taskService: TaskService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<JobEditCompletedAtComponent>,
    private fb: FormBuilder,
    private languageService: LanguageService,
    private layoutService: LayoutService,
    private _adapter: DateAdapter<any>,
    private membersService: MembersService
  ) { super() }

  ngOnInit(): void {

    this.form = this.fb.group({
      completed_at: this.data.work.completed_at != 0 ? moment(this.data.work.completed_at * 1000) : '',
    })

    this.attachSubscriptions(
      this.form.valueChanges.subscribe(() => this.isFormChange = true)
    )

    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        if (this.isFormChange) {
          this.layoutService.openBottomSheet(this.dialogRef);
        } else {
          this.close();
        }
      })
    )

    this.getLangData();

  }

  changeDate(e) {
    console.log(e)
    this.form.patchValue({
      completed_at: e
    })
  }

  formatX(val) {
    return moment(val).format("X")
  }

  ngAfterViewInit() {
    // console.log("this.dateInput", this.dateInput)
    // this.dateInput.nativeElement.focus()
    // this.dateInput.nativeElement.click()
    // this.dateInput.click()
  }

  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData().subscribe(resp => {
        this._adapter.setLocale(resp.active);
        this.activeLang = resp.active
      })
    )
  }

  close() {
    this.dialogRef.removePanelClass('animate__slideInUp');
    this.dialogRef.addPanelClass('animate__slideOutDown');
    setTimeout(()=>{this.dialogRef.close({event: 'close'})}, 300);
  }

  back() {
    this.dialogRef.removePanelClass('animate__slideInUp');
    this.dialogRef.addPanelClass('animate__slideOutDown');
    setTimeout(()=>{this.dialogRef.close({event: 'back'})}, 300);
  }

  onSubmit() {
    let editDate = {...this.form.value}

    if (!!editDate.completed_at) {
      editDate.completed_at = this.formatX(this.form.get('completed_at').value);
    } else {
      editDate.completed_at = '0';
    }
    this.attachSubscriptions(
      this.taskService.editWork(this.data.work.id, editDate, this.data.company.id).pipe(
        switchMap(val => {
          if (!!this.data?.work?.employee?.id) {
            return this.membersService.editMember(this.data.work.employee.id, editDate, this.data.company.id).pipe(map(() => val ))
          } else {
            return of(val)
          }
        })
      ).subscribe(resp => {
        this.dialogRef.removePanelClass('animate__slideInUp');
        this.dialogRef.addPanelClass('animate__slideOutDown');
        setTimeout(()=>{this.dialogRef.close({event: "update", data: resp})}, 300);
      })
    )
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

}
