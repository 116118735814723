import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { AuthService } from 'src/app/shared/services/rest/auth.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import * as moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/en-gb';
import 'moment/locale/uk';
import 'moment/locale/ru';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { bool } from 'aws-sdk/clients/signer';
import { catchError, debounceTime, finalize, map, startWith, switchMap, tap } from 'rxjs/operators';
import { forkJoin, Observable, of, ReplaySubject } from 'rxjs';
import { prioritys } from 'src/app/shared/consts/prioritys';
import { MembersService } from 'src/app/shared/services/rest/members.service';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { GlobalDataService } from 'src/app/shared/services/common/global-data.service';
import { ScenariosService } from 'src/app/shared/services/rest/scenarios.service';
import { environment } from 'src/environments/environment';
import { MY_NEW_FORMATS } from 'src/app/components/workspace-pages/cases/dialogs/open-task/dialogs/url-analytics/url-analytics.component';
import { NgxMatDateAdapter } from '@angular-material-components/datetime-picker';
import { ConnectedAppsService } from 'src/app/shared/services/rest/connected-apps.service';
import { SelectFileComponent } from '../select-file/select-file.component';
import { BetaVideoEditorComponent } from '../beta-video-editor/beta-video-editor.component';

@Component({
  selector: 'app-publish-to-outlet',
  templateUrl: './publish-to-outlet.component.html',
  styleUrls: ['./publish-to-outlet.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_NEW_FORMATS},
  ]
})
export class PublishToOutletComponent extends BaseClass implements OnInit, OnDestroy {
  public host: any = environment.host;
  public isFormChange: boolean = false;
  public isSubmit: boolean = false;
  public submited: boolean = false;
  public needData: boolean = false;
  public hasError: boolean = false;
  public isChecked: boolean = false;
  public cantPublish: boolean = false;
  public noUpdate: boolean = true;

  public morePhotos: any[] = [];
  public myControl: FormControl = new FormControl('');
  public filteredOptions$: Observable<any>;
  public regArch: any;
  public pbType: any;
  public profileStatuses: any;
  public contentTypes: any = [];
  public frame_ms: number = 0;
  public maxLength: number = 250;
  public tikTok: any;
  public thumbFile: any;
  public regularDateTimes: any = [];
  public activeLang: any;
  public form: FormGroup;
  public timeZone = - new Date().getTimezoneOffset();
  public profileForm: FormGroup;
  options: any[] = [{name: 'Mary'}, {name: 'Shelley'}, {name: 'Igor'}];
  public tikTokForm: FormGroup;
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PublishToOutletComponent>,
    private sm: StorageManagerService,
    private auth: AuthService,
    private companyService: CompanyService,
    private globalDataService: GlobalDataService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private taskService: TaskService,
    private _ngx_adapter: NgxMatDateAdapter<any>,
    private _adapter: DateAdapter<any>,
    private languageService: LanguageService,
    private connectedAppsService: ConnectedAppsService,
    private layoutService: LayoutService,
    private scenariosService: ScenariosService,
    private membersService: MembersService
  ) { super() }

  ngOnInit(): void {
    this.dialogRef.addPanelClass("create_task_by_manager_modal")
    console.log("PublishToOutletComponent", this.data)

    this.filteredOptions$ = this.myControl.valueChanges.pipe(
      tap(x => {
        console.log("this.myControl.value", this.myControl.value)
        console.log("this.filteredOptions$", x)
      }),
      startWith(''),
      switchMap(val => {
        return this.taskService.getFacebookPlaces(val)
      })
    );

    this.getLangData();
    this.profileForm = this.fb.group({
      channel_id: this.data.profile.id
    })

    if (!this.data.profile.hasOwnProperty('permission_upload')) {
      let expPermFilter:any = {task_id: this.data.task.id, channel_id: this.data.profile.channel_id}
      if (this.data.company_id != this.data.file.company_id) {
        expPermFilter.partner_company_id = this.data.file.company_id
      }
      this.attachSubscriptions(
        this.taskService.getExpandPermUrls('1', this.data.company_id, expPermFilter, '100').pipe(
          tap(x => {
            console.log("PublishTo GET PERM", x)
          }),
          map(arr => {
            if (arr && arr.length) {
              return arr[0]
            } else {
              return false
            }
          })
        ).subscribe((res:any) => {
          if (res) {
            this.data.profile.permission_upload = res.permission_upload;
            this.data.profile.permission_prepare_upload = res.permission_prepare_upload;
          }
        })
      )
    }

    console.log("data?.profile?.permission_upload", this.data.profile.permission_upload)

    this.getProfilesStatus();
    this.getContentTypes(this.data.profile.channel.platform_id);
    
    this.form = this.fb.group({
      company_id: this.data.company_id,
      task_id: this.data.task.id,
      channel_id: this.data.profile.channel.id,
      content_type_id: ['', Validators.required],
      // content_type_id: [!!this.data.profile.content_type_id ? this.data.profile.content_type_id : '', Validators.required],
      content_file_id: this.data.file.id,
      content_schedule_id: '',
      name: '',
      desc: '',
      thumbnail_file_id: 0,
      status_id: this.data?.profile?.permission_upload == 1 ? 0 : 2,
      published_at: ['', Validators.required],
      is_ready_to_upload: 1,
      date: this.fb.group({
        time: '',
        day: ''
      })
    })

    if (this.data.files.length > 1) {
      if (this.data.files.filter(k => this.understandFileType(k.content_type) == 'image').length) {
        this.thumbFile = this.data.files.find(k => this.understandFileType(k.content_type) == 'image')
        this.form.patchValue({
          thumbnail_file_id: this.thumbFile.id
        })
      }

      if (this.data.files.filter(k => k.content_type == 'reports-json-auto-post').length) {
        let docFile = this.data.files.find(k => k.content_type == 'reports-json-auto-post')
        this.form.patchValue({
          name: docFile.document.text && JSON.parse(docFile.document.text).name ? JSON.parse(docFile.document.text).name : '',
          desc: docFile.document.text && JSON.parse(docFile.document.text).desc ? JSON.parse(docFile.document.text).desc : '',
        })
      }
    }

    // if (!this.form.value.content_type_id && this.data.profile.channel.platform_id == 3) {
    //   this.form.patchValue({
    //     content_type_id: 301
    //   })
    // }

    // if (this.data.file && this.data.file.meta_duration && this.data.file.meta_duration <= 60) {
    //   if (!this.form.value.content_type_id && this.data.profile.channel.platform_id == 4) {
    //     this.form.patchValue({
    //       content_type_id: 404
    //     })
    //   }
    //   if (!this.form.value.content_type_id && this.data.profile.channel.platform_id == 2) {
    //     this.form.patchValue({
    //       content_type_id: 202
    //     })
    //   }
    // }
    
    // if (this.data.profile.channel.platform_id == 4 && this.pbType == 'image') {
    //   this.form.patchValue({
    //     content_type_id: 402
    //   })
    // }
    
    // if (this.data.profile.channel.platform_id == 2) {
    //   this.form.patchValue({
    //     content_type_id: 201
    //   })
    // }

    if (this.understandFileType(this.data.file.content_type) == 'image') {
      if (this.data.profile.channel.platform_id == 2) {
        if (this.form.value.content_type_id == 201) {
          this.pbType = 'ig_image'
        } else if (this.form.value.content_type_id == 206) {
          this.pbType = 'ig_carousel'
        }
        this.pbType = 'ig_image'
      } else {
        this.pbType = 'image'
      }
    } else {
      this.pbType = 'video'
    }

    if (this.data.profile.channel.platform_id == 1) {
      this.getYTData();
    }
    if (this.data.profile.channel.platform_id == 3) {
      this.getTikTokData();
    }

    // if ([404, 402, 202, 201, 206].includes(this.form.value.content_type_id)) {
    //   this.form.patchValue({
    //     desc: (this.data.task || this.data.file.task).name
    //   })
    // } else {
    //   this.form.patchValue({
    //     name: (this.data.task || this.data.file.task).name,
    //     desc: (this.data.task || this.data.file.task).name
    //   })
    // }

    // this.getRegulars();

    this.attachSubscriptions(
      this.form.get('date').valueChanges.subscribe(resp => {
        console.log(resp)
        let dateForm = {...this.form.get("date").value}
        if (!dateForm.day) {
          dateForm.day = moment(0).hour(0).minute(0)
        }
        let testTimeAndDate = dateForm.day.clone()
        testTimeAndDate.hour(Number(dateForm.time.split(":")[0]))
        testTimeAndDate.minute(Number(dateForm.time.split(":")[1]))
        this.form.patchValue({
          published_at: testTimeAndDate
        })
        console.log('this.form.value', this.form.value);
        console.log('this.form.value.published_at', this.form.value.published_at.format("X"));
      })
    )

    // channel_account_channel_id: this.data.profile.channel.channel_account_id

    // "company_id": 0,
    // "task_id": 0,
    // "channel_id": 0,
    // "name": "string",
    // "content_file_id": 0,
    // "thumbnail_file_id": 0,
    // "is_ready_to_upload": 0

    this.attachSubscriptions(
      this.profileForm.valueChanges.subscribe((res) => {
        this.data.profile = this.data.task.uniqueChannels.find(x => x.id == res.channel_id)

        this.form.patchValue({
          channel_id: this.data.profile.channel.id,
        })
      })
    )

    this.attachSubscriptions(
      this.form.get('content_schedule_id').valueChanges.subscribe((res) => {
        console.log("content_schedule_id", res)
        this.getRegulars(undefined, undefined, res);
      })
    )

    this.attachSubscriptions(
      this.form.get('content_type_id').valueChanges.subscribe((res) => {
        console.log(res)
        if ([404, 402, 202, 201, 206].includes(res)) {
          this.form.patchValue({
            name: '',
            desc: this.form.value.desc == '' ? (this.data.task || this.data.file.task).name : this.form.value.desc
          })
        } else {
          this.form.patchValue({
            name: this.form.value.name == '' ? (this.data.task || this.data.file.task).name : this.form.value.name,
            desc: this.form.value.desc == '' ? (this.data.task || this.data.file.task).name : this.form.value.desc
          })
        }
        this.getRegulars(res);
        this.getRegularsArch(res);

        if (res == 201) {
          this.pbType = 'ig_image'
        } else if (res == 206) {
          this.pbType = 'ig_carousel'
        }

        if (this.understandFileType(this.data.file.content_type) == 'image') {
          if (this.data.profile.channel.platform_id == 2) {
            if (res == 201) {
              this.pbType = 'ig_image'
            } else if (res == 206) {
              this.pbType = 'ig_carousel'
            }
            this.pbType = 'ig_image'
          } else {
            this.pbType = 'image'
          }
        } else {
          this.pbType = 'video'
        }
      })
    )

    this.attachSubscriptions(
      this.form.valueChanges.subscribe((res) => {
        console.log("form.valueChanges", res);
        this.isFormChange = true
      })
    )

    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        if (this.isFormChange) {
          this.layoutService.openBottomSheet(this.dialogRef);
        } else {
          this.close();
        }
      })
    )
  }

  selected(e) {
    console.log(e)
    console.log("this.myControl.value", this.myControl.value)
  };

  displayFn(place:any): string {
    return place && place.name ? place.name : '';
  }

  removeMorePhoto(photo, i) {
    this.morePhotos.splice(i,1);
  }

  videoEditorV3() {
    const dialogRef = this.dialog.open(BetaVideoEditorComponent, {
      panelClass: ['full_screen_modal', 'video_editor', 'video_editor_black'],
      autoFocus: false,
      disableClose: true,
      data: {
        file: {...this.data.file},
        company_id: this.data.company_id,
        target_company_id: this.data.target_company_id,
        company: this.data.company,
        user: this.data.user,
        imgRoute: this.data.imgRoute,
        activeLang: this.activeLang,
        host: this.host,
        task: this.data.task,
        work: this.data.work,
        thumbMode: true
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log(result)
        if (!!result && result.event == 'frame') {
          console.log("frame_ms", result.data)
          this.removeThumb();
          this.frame_ms = result.data
        }
      })
    )
  }

  loadedVideo(e) {
    e.target.currentTime = this.frame_ms;
  }

  removeFrame() {
    this.frame_ms = 0
  }

  getYTData() {
    this.form.addControl('publishing_params', this.fb.group({
      privacy : 'public',
      made_for_kids: 0
    }))
    this.form.get('publishing_params').updateValueAndValidity();
  }

  getTikTokData() {
    this.attachSubscriptions(
      this.taskService.getTikTokData(this.data.company_id, this.data.profile.id).subscribe(resp => {
        console.log("getTikTokData", resp);
        if (!(resp.error && resp.error.code == 'ok')) {
          this.cantPublish = true;
          this.layoutService.showSnackBar({name: ''}, resp.error.message, SnackBarItem)
          return
        }

        if (!this.data.file.meta_duration) {
          this.cantPublish = true;
          this.layoutService.showSnackBar({name: ''}, "Meta duration value not found for this file", SnackBarItem)
          return
        } else {
          // if (resp && resp.data && resp.data.max_video_post_duration_sec && resp.data.max_video_post_duration_sec < this.data.file.meta_duration) {
          //   this.cantPublish = true;
          //   this.layoutService.showSnackBar({name: ''}, "Length of the video does not fit requirements", SnackBarItem)
          //   return
          // }
        }

        this.cantPublish = false;
        this.tikTok = resp

        this.form.addControl('publishing_params', this.fb.group({
          privacy_level : 'PUBLIC_TO_EVERYONE',
          disable_duet: true,
          disable_stitch: true,
          disable_comment: false,
          brand_content_toggle: false,
          brand_organic_toggle: false,
        }))
        // disable_duet: !!resp.duet_disabled,
        // disable_stitch: !!resp.stitch_disabled,
        // disable_comment: !!resp.comment_disabled,
        this.form.get('publishing_params').updateValueAndValidity();

      }, error => {
        this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
      })
    )
  }

  onChangeDisc(e) {
    this.isChecked = e.checked;
  }

  getProfilesStatus() {
    this.attachSubscriptions(
      this.globalDataService.getContentStatus().subscribe(resp => {
        console.log("getContentStatus", resp);
        this.profileStatuses = resp.slice();
      })
    )
  }

  getArchById(id) {
    if (!this.regArch || this.regArch.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.regArch.find(el => el.id == id)
  }

  getStatusById(id) {
    if (!this.profileStatuses || this.profileStatuses.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.profileStatuses.find(el => el.id == id)
  }

  understandFileType(val) {
    if (!val) {
      return ""
    }
    
    if (val == 'application/pdf') {
      return 'pdf'
    } else if (val.indexOf("/") != -1) {
      return val.split('/')[0]
    } else {
      return ""
    }
  }

  onDateChange(e) {
    console.log("onDateChange", e);
    this.getRegulars(undefined, e.value);
  }

  getContentById(id) {
    if (!this.contentTypes || this.contentTypes.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.contentTypes.find(el => el.id == id)
  }

  numberToTimeFormat(val) {
    if (Number(val) < 10) {
      val = "0" + val
    }
    return val
  }

  acceptRegularTime(item) {
    console.log("acceptRegularTime", item);
    if (item.class != 'white') {
      this.layoutService.showSnackBar({name: ''}, 'You cannot select this date.', SnackBarItem)
      return
    }
    this.noUpdate = true
    this.form.get('date').patchValue({
      day: item.content_published_at ? moment(this.showUserDate(false, item.content_published_at) * 1000).hour(0).minute(0).second(0) : '',
      time: item.content_published_at ? (this.numberToTimeFormat(moment(this.showUserDate(false, item.content_published_at) * 1000).hours()) + ":" + this.numberToTimeFormat(moment(this.showUserDate(false, item.content_published_at) * 1000).minutes())) : '',
    })
    // this.regularDateTimes = [];
  }



  getFreeRegulars(content_type_id?, date?, content_schedule_id?) {
    let filterData = {
      channel_id: this.form.value.channel_id,
      content_type_id: content_type_id || this.form.value.content_type_id,
      publish_at_from: '',
      content_schedule_id: content_schedule_id || this.form.value.content_schedule_id
    }

    if (!!date) {
      filterData.publish_at_from = date.startOf('day').format("X");
    } else { 
      filterData.publish_at_from = moment().format("X");
    }

    
    this.attachSubscriptions(
      this.companyService.getExpandPublishScheduledPosts('1', this.data.company_id, {...filterData}, '200').pipe(
        map(el => el.body),
        switchMap(contents => {
          let urlsData:any = {
            channel_id: this.form.value.channel_id,
            content_type_id: content_type_id || this.form.value.content_type_id,
            content_published_at_from: ''
          };
    
          if (!!date) {
            urlsData.content_published_at_from = date.startOf('day').format("X");
          } else {
            urlsData.content_published_at_from = moment().format("X");
          }
          
          return this.taskService.getExpandPublishUrls('1', this.data.company_id, {...urlsData}, '200').pipe(
            map(expUrls => {
              return {    
                urls: expUrls.body,
                contents: contents
              }
            })
          )
        })
      ).subscribe((resp:any) => {

        let urls = resp.urls
        urls.forEach(x => {
          x.scheduledObj = {};
          x.name = x.content_name;
        })

        resp.contents.forEach(con => {
          if (urls.length > 0 && urls.filter(x => x.content_published_at == con.publish_at).length > 0) {
            urls.find(x => x.content_published_at == con.publish_at).scheduledObj = con
          } else {
            con.is_con = true;
            con.content_published_at = con.publish_at;
            urls.push(con)
          }
        })

        urls.sort(function(a, b) {
          return (a.content_published_at - b.content_published_at);
        })

        let regularFreeDateTimes = [...urls]

        regularFreeDateTimes.forEach(x => {
          if (x.hasOwnProperty('scheduledObj')) {
            if (moment().isSameOrAfter(moment(x.content_published_at*1000))) {
              x.class = 'green'
            } else {
              x.class = 'blue'
            }
          } else {
            if (moment().isSameOrAfter(moment(x.content_published_at*1000))) {
              x.class = 'silver'
            } else {
              x.class = 'white'
            }
          }
        })
        regularFreeDateTimes = regularFreeDateTimes.filter(x => x.class == 'white')
        console.log("regularFreeDateTimes", regularFreeDateTimes)

        let count = 0;
        regularFreeDateTimes.forEach(el => {
          count++
          if (count > 3) {
            return
          }
          if (this.regularDateTimes.filter(x => x.id == el.id).length == 0) {
            this.regularDateTimes.push(el);
          }
        })
        
      })
    )
  }

  getRegularsArch(type) {
    this.form.patchValue({
      content_schedule_id: ''
    })

    this.attachSubscriptions(
      this.companyService.getRegulars('1', this.data.company_id, {channel_id: [this.form.value.channel_id], content_type_id: type}).pipe(map(x => x.body)).subscribe(resp => {
        this.regArch = resp;
        console.log("getRegularsArch", resp)
      })
    )
  }

  getRegulars(content_type_id?, date?, content_schedule_id?) {
    let filterData:any = {
      channel_id: this.form.value.channel_id,
      content_type_id: content_type_id || this.form.value.content_type_id,
      publish_at_from: '',
      publish_at_to: '',
      content_schedule_id: content_schedule_id || this.form.value.content_schedule_id
    }

    if (!!date) {
      filterData.publish_at_from = date.startOf('day').format("X");
      filterData.publish_at_to = date.endOf('day').format("X");
    } else { 
      filterData.publish_at_from = moment().startOf('day').format("X");
      filterData.publish_at_to = moment().endOf('day').format("X");
    }
    
    this.attachSubscriptions(
      this.companyService.getExpandPublishScheduledPosts('1', this.data.company_id, {...filterData}, '50').pipe(
        map(el => el.body),
        switchMap(contents => {
          let urlsData:any = {
            channel_id: this.form.value.channel_id,
            content_type_id: content_type_id || this.form.value.content_type_id,
            content_published_at_from: '',
            content_published_at_to: ''
          };
    
          if (!!date) {
            urlsData.content_published_at_from = date.startOf('day').format("X");
            urlsData.content_published_at_to = date.endOf('day').format("X");
          } else {
            urlsData.content_published_at_from = moment().startOf('day').format("X");
            urlsData.content_published_at_to = moment().endOf('day').format("X");
          }
          return this.taskService.getExpandPublishUrls('1', this.data.company_id, {...urlsData}, '50').pipe(
            map(expUrls => {
              return {    
                urls: expUrls.body,
                contents: contents
              }
            })
          )
        })
      ).subscribe((resp:any) => {
        if (!resp) {
          this.regularDateTimes = []
        } else {
          let urls = resp.urls
          urls.forEach(x => {
            x.scheduledObj = {};
            x.name = x.content_name;
          })

          resp.contents.forEach(con => {
            if (urls.length > 0 && urls.filter(x => x.content_published_at == con.publish_at).length > 0) {
              urls.find(x => x.content_published_at == con.publish_at).scheduledObj = con
            } else {
              con.is_con = true;
              con.content_published_at = con.publish_at;
              urls.push(con)
            }
          })

          urls.sort(function(a, b) {
            return (a.content_published_at - b.content_published_at);
          })

          this.regularDateTimes = [...urls]

        }
        this.regularDateTimes.forEach(x => {
          if (x.hasOwnProperty('scheduledObj')) {
            if (moment().isSameOrAfter(moment(x.content_published_at*1000))) {
              x.class = 'green'
            } else {
              x.class = 'blue'
            }
          } else {
            if (moment().isSameOrAfter(moment(x.content_published_at*1000))) {
              x.class = 'silver'
            } else {
              x.class = 'white'
            }
          }
        })
        console.log("this.regularDateTimes", this.regularDateTimes)

        if (this.regularDateTimes.filter(x => x.class == 'white').length == 0) {
          this.getFreeRegulars(content_type_id, date, content_schedule_id)
        }
      })
    )
  }

  // getRegulars(content_type_id?, date?) {
  //   this.attachSubscriptions(
  //     this.companyService.getRegulars(this.data.company_id, {channel_id: this.form.value.channel_id, content_type_id: content_type_id || this.form.value.content_type_id}).pipe(
  //       map(res => res.body),
  //       tap(regs => {
  //         regs.forEach(reg => {
  //           reg.items.forEach(element => {
  //             element.timeInFormat = new Date(element.time * 1000).toISOString().slice(11, 16)
  //           });
  //         });
  //       }),
  //       switchMap(regs => {
  //         if (regs.length > 0) {
  //           let filterData = {
  //             channel_id: this.form.value.channel_id,
  //             content_type_id: content_type_id || this.form.value.content_type_id,
  //             publish_at_from: '',
  //             publish_at_to: ''
  //           }
  //           if (!!date) {
  //             filterData.publish_at_from = date.startOf('day').format("X");
  //             filterData.publish_at_to = date.endOf('day').format("X");
  //           } else { 
  //             filterData.publish_at_from = moment().startOf('day').format("X");
  //             filterData.publish_at_to = moment().endOf('day').format("X");
  //           }
            
  //           return this.companyService.getExpandPublishScheduledPosts('1', this.data.company_id, {...filterData}, '50').pipe(
  //             map(el => el.body),
  //             switchMap(contents => {
  //               let urlsData:any = {
  //                 channel_id: this.form.value.channel_id,
  //                 content_type_id: content_type_id || this.form.value.content_type_id,
  //                 content_published_at_from: '',
  //                 content_published_at_to: ''
  //               };
          
  //               if (!!date) {
  //                 urlsData.content_published_at_from = date.startOf('day').format("X");
  //                 urlsData.content_published_at_to = date.endOf('day').format("X");
  //               } else {
  //                 urlsData.content_published_at_from = moment().startOf('day').format("X");
  //                 urlsData.content_published_at_to = moment().endOf('day').format("X");
  //               }
  //               return this.taskService.getExpandPublishUrls('1', this.data.company_id, {...urlsData}, '50').pipe(
  //                 map(expUrls => {
  //                   return {    
  //                     urls: expUrls.body,
  //                     contents: contents
  //                   }
  //                 })
  //               )
  //             })
  //           )
  //         } else {
  //           return of(false)
  //         }
  //       })
  //     ).subscribe((resp:any) => {
  //       if (!resp) {
  //         this.regularDateTimes = []
  //       } else {
  //         let urls = resp.urls

  //         resp.contents.forEach(con => {
  //           if (urls.length > 0 && urls.filter(x => x.content_published_at == con.publish_at).length > 0) {
  //             urls.find(x => x.content_published_at == con.publish_at).scheduledObj = con
  //           } else {
  //             con.is_con = true;
  //             con.content_published_at = con.publish_at;
  //             urls.push(con)
  //           }
  //         })

  //         urls.sort(function(a, b) {
  //           return (a.content_published_at - b.content_published_at);
  //         })

  //         // if (urls.filter(x => x.is_con).length > 0) {
  //         //   console.log(urls, urls.find(x => x.is_con))
  //         //   this.regularDateTimes = [...urls]
  //         // } else {
  //         //   this.regularDateTimes = []
  //         // }
  //         this.regularDateTimes = [...urls]

  //       }
  //       this.regularDateTimes.forEach(x => {
  //         if (x.hasOwnProperty('scheduledObj')) {
  //           x.class = 'publication'
  //         }
  //         else if (moment().isAfter(moment(x.content_published_at*1000))) {
  //           x.class = 'before'
  //         }
  //       })
  //       console.log("this.regularDateTimes", this.regularDateTimes)
  //     })
  //   )
  // }

  getContentTypes(platform_id) {
    console.log("getContentTypes")
    this.attachSubscriptions(
      this.companyService.getContentTypes(platform_id, '1').pipe(
        map(el => el.body)
      ).subscribe(resp => {
        console.log("resp0", resp)
        if (platform_id == 1) {
          resp = resp.filter(k => k.id < 103);
          console.log("resp1", resp)
          if (this.data.file && this.data.file.meta_duration && this.data.file.meta_duration > 59) {
            resp.splice(1,1);
            console.log("resp2", resp)
            this.form.patchValue({
              content_type_id: 101
            })
          }
        } 
        this.contentTypes.push(...resp);
        console.log("finaly resp", this.contentTypes)
      })
    )
  }

  removeThumb() {
    this.thumbFile = undefined;

    this.form.patchValue({
      thumbnail_file_id: 0
    })
  }

  selectFile(is_photo:boolean = false, is_document:boolean = false) {
    let x:any = {
      company: this.data.company,
      task: this.data.task,
      work: this.data.work,
      user: this.data.user,
      thumbFile: this.thumbFile,
      only: is_document ? 'is_document' : 'image',
      is_multi: is_photo,
      no_matches: true
    }
    if (is_photo) {
      x.selectedFiles = this.morePhotos;
      x.thumbFile = this.data.file;
    }

    const dialogRef = this.dialog.open(SelectFileComponent, {
      data: x
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result && result.event == 'select' && result.data) {

          if (is_document) {
            console.log("is_document", result);
            console.log('text', this.extractPlainText(result.data.document.text.replaceAll('<br>', '\n').replaceAll('<BR>', '\n').replaceAll('</br>', '\n').replaceAll('</BR>', '\n')))
            if (result.data.content_type == 'reports-json-auto-post') {
              this.form.patchValue({
                name: result.data.document.text && JSON.parse(result.data.document.text).name ? JSON.parse(result.data.document.text).name : '',
                desc: result.data.document.text && JSON.parse(result.data.document.text).desc ? JSON.parse(result.data.document.text).desc : '',
              })
            } else {
              this.form.patchValue({
                name: this.extractPlainText(result.data.document.text.replaceAll('<br>', '\n').replaceAll('<BR>', '\n').replaceAll('</br>', '\n').replaceAll('</BR>', '\n')).name || result.data.document.name,
                desc: this.extractPlainText(result.data.document.text.replaceAll('<br>', '\n').replaceAll('<BR>', '\n').replaceAll('</br>', '\n').replaceAll('</BR>', '\n')).text.replaceAll('\n\n\n\n', '\n\n').replaceAll('\n\n\n', '\n\n')
              })
            }
          } else {
            if (is_photo) {
              this.morePhotos = result.data
            } else {
              if (!this.thumbFile || this.thumbFile.id != result.data.id) {
                this.thumbFile = result.data
                this.form.patchValue({
                  thumbnail_file_id: this.thumbFile.id
                })
              } else {
                this.thumbFile = undefined
                this.form.patchValue({
                  thumbnail_file_id: 0
                })
              }
            }
          }

        }
      })
    )
  }

  extractPlainText(htmlText: string): any {
    const doc = new DOMParser().parseFromString(htmlText, 'text/html');
    let name = '';
    let text = '';
    let level = 0;
    if (doc.body.childNodes && doc.body.childNodes[0]) {
      if (doc.body.childNodes[0].nodeName.toLowerCase() == 'b') {
        level = 1;
        name = doc.body.childNodes[0].textContent;
      } else {
        if (doc.body.childNodes[0].nodeName.toLowerCase() == 'div') {
          if (doc.body.childNodes[0].childNodes && doc.body.childNodes[0].childNodes[0] && doc.body.childNodes[0].childNodes[0].nodeName.toLowerCase() == 'b') {
            level = 2;
            name = doc.body.childNodes[0].childNodes[0].textContent;
          }
        }
      }
    }

    if (name) {
      text = (doc.body.innerText || '').replace(name, '')
    } else {
      text = doc.body.innerText || '';
    }

    return {name: name.trim(), text: text.trim(), doc: doc};
  }

  changedTime(e) {
    console.log("changedTimechangedTimechangedTimechangedTimechangedTimechangedTimechangedTime",e)
    console.log("this.form.value", this.form.value)
  }

  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData().subscribe(resp => {
        this._ngx_adapter.setLocale(resp.active);
        this._adapter.setLocale(resp.active);
        this.activeLang = resp.active;
      })
    )
  }

  selectStatus(id) {
    // this.regularDateTimes = []
    this.form.patchValue({
      status_id: id,
      published_at: id == 1 ? '' : 0,
      date: {
        time: '',
        day: ''
      }
    })

    this.needData = id == 1
  }

  showUserDate(date:any = false, timestamp:any = false) {
    let timeAndDate;
    if (date) {
      timeAndDate = moment(date).clone()
    } else {
      timeAndDate = moment(timestamp*1000).clone()
    }

    const utcOffset = moment.tz(timeAndDate, this.data.company.timezone).utcOffset();
    
    let utcTimestamp = moment(timeAndDate).format("X")
    
    let difference = (Number(this.timeZone) - Number(utcOffset))*60
    // console.log(difference);
    
    let workSpaceTimeZone;
    if (date) {
      workSpaceTimeZone = Number(utcTimestamp) + Number(difference)
    } else {
      workSpaceTimeZone = Number(utcTimestamp) - Number(difference)
    }
    return workSpaceTimeZone
  }

  isValidNotYetDate() {
    return this.form.get('published_at') && this.form.get('published_at').value && (this.form.get('published_at').value as moment.Moment).isAfter(moment(30000))
  }

  close() {
    this.dialogRef.close({event: "close", data: false});
  }

  selectType(type) {
    console.log(type);
    let id = !!type.id ? type.id : type.value
    this.form.patchValue({
      content_type_id: id
    })
  }

  submitForm() {
    if (this.needData && (!this.form.get('published_at').value || !this.isValidNotYetDate())) {
      this.submited = true;
      console.log(this.form);
      return
    }

    if (!this.form.get('content_type_id').valid) {
      this.submited = true;
      console.log(this.form);
      return
    }
    if (this.form.get('content_type_id').value == 206 && (this.morePhotos.length == 0 || this.morePhotos.length > 9)) {
      this.submited = true;
      this.layoutService.showSnackBar({name: ''}, 'A carousel publication should have 2 to 10 photos. Adjust the number of photos or choose a different publication type.', SnackBarItem)
      console.log(this.form);
      return
    }

    if (this.data.profile.channel.platform_id == 1 && this.form.value.name.length > 100) {
      this.layoutService.showSnackBar({name: ''}, `The title value is too large. The max length is 100. The current length is ${this.form.value.name.length}.`, SnackBarItem)
      console.log(this.form);
      return
    }

    console.log("this.form.value", this.form.value)

    this.submited = false;
    this.isSubmit = true;

    let formData = {...this.form.value}
    delete formData.status_id;
    delete formData.date;
    delete formData.content_schedule_id;
    if (this.form.value.status_id != 1) {
      delete formData.published_at;
    } else {
      let timeAndDate = formData.published_at.clone().set({'second': 0, 'millisecond': 0})

      const utcOffset = moment.tz(timeAndDate, this.data.company.timezone).utcOffset();
      console.log(utcOffset);
  
      
      let utcTimestamp = moment(timeAndDate).format("X")
      console.log(utcTimestamp);
      
      let difference = (Number(this.timeZone) - Number(utcOffset))*60
      console.log("difference", difference);
      
      let workSpaceTimeZone = Number(utcTimestamp) + Number(difference)
      console.log("workSpaceTimeZone", workSpaceTimeZone);

  
      formData.published_at = workSpaceTimeZone
    }

    // this.attachSubscriptions(
    //   this.connectedAppsService.addChannelContent(formData).pipe(
    //     finalize(() => {
    //       this.isSubmit = false;
    //     })
    //   ).subscribe(resp => {
    //     console.log(resp);
    //     this.dialogRef.close({event: "close", data: false});
    //   }, error => {
    //     this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
    //   })
    // )

    let fileArr = [];

    if (this.data.file) {
      fileArr.push({
        company_id: this.data.profile.company_id,
        task_channel_id: this.data.profile.id,
        file_id: this.data.file.id,
        is_to_upload: 1,
        is_content: 1
      })
    }

    if (!!this.morePhotos && this.morePhotos.length) {
      this.morePhotos.forEach(photo => {
        fileArr.push({
          company_id: this.data.profile.company_id,
          task_channel_id: this.data.profile.id,
          file_id: photo.id,
          is_to_upload: 1,
          is_content: 1
        })
      })
    }

    if (!!this.frame_ms) {
      fileArr.push({
        company_id: this.data.profile.company_id,
        task_channel_id: this.data.profile.id,
        file_id: this.data.file.id,
        thumbnail_frame_ms: Math.round(this.frame_ms*1000),
        is_to_upload: 1,
        is_thumbnail_frame: 1
      })
    }

    if (!!this.thumbFile) {
      fileArr.push({
        company_id: this.data.profile.company_id,
        task_channel_id: this.data.profile.id,
        file_id: this.thumbFile.id,
        is_to_upload: 1,
        is_thumbnail: 1
      })
    }

    let sbmtData:any = {
      content_type_id: formData.content_type_id,
      content_uploaded_at: !!formData.published_at && formData.published_at - 3600000 > 0 ? formData.published_at - 3600000 : formData.published_at,
      content_published_at: formData.published_at,
      content_name: formData.name || formData.desc
    }
    
    if (this.data.profile.channel.platform_id != 3) {
      sbmtData.content_description = formData.desc;
    } else {
      sbmtData.publishing_params = formData.publishing_params
    }

    if (this.data.profile.channel.platform_id == 1) {
      if (this.form.value.status_id == 1) {
        sbmtData.publishing_params = {
          privacy: 'private'
        }
      } else {
        sbmtData.publishing_params = formData.publishing_params
      }
      sbmtData.publishing_params.made_for_kids = !!sbmtData.publishing_params.made_for_kids ? 1 : 0;
    }

    if ((this.data.profile.channel.platform_id == 4 || [201, 202, 206].includes(this.form.get('content_type_id').value)) && this.myControl.value) {
      let facebook_place_id;
      if (this.myControl.value.place_id) {
        facebook_place_id = this.myControl.value.place_id
      } else {
        facebook_place_id = this.myControl.value
      }
      sbmtData.facebook_place_id = facebook_place_id
    }
    
    this.attachSubscriptions(
      this.taskService.editProfile(this.data.profile.id, sbmtData, this.data.company_id).pipe(
        catchError(error => {
          throw error
        }),
        switchMap(res => {
          return (!this.hasError ? forkJoin([...fileArr.map(x => this.taskService.setTaskChannelFile(x, this.data.company_id))]) : of(res)).pipe(
            catchError(error => {
              throw error
            }),
            switchMap(files => {
              return this.taskService.editProfile(this.data.profile.id, {content_status_id: this.form.value.status_id != 2 ? 6 : 2}, this.data.company_id).pipe(
                catchError(error => {
                  throw error
                })
              )
            })
          )
        })
      ).subscribe(resp => {
        this.dialogRef.close({event: "publish", data: false});
        this.hasError = false;
        this.layoutService.showSnackBar({name: ''}, marker("Publication successful"), SnackBarItem)
      }, error => {
        console.log("error complete", error);
        this.isSubmit = false;
        if (error) {
          if (typeof error != 'string') {
            let dates = [];
            let prev;
            let next;

            if (error.find(x => x.field == 'prev_publication_data')) {
              prev = error.find(x => x.field == 'prev_publication_data')
            }
            if (error.find(x => x.field == 'next_publication_data')) {
              next = error.find(x => x.field == 'next_publication_data')
            }

            if (prev && !!prev.message.predicted_content_published_at) {
              let x = +prev.message.predicted_content_published_at - 1800
              let y = +prev.message.predicted_content_published_at + 1800
              if (moment(x*1000).isAfter(moment())) {
                dates.push(x)
              }
              if (moment(y*1000).isAfter(moment())) {
                dates.push(y)
              }
            }
            if (next && !!next.message.predicted_content_published_at) {
              let x = +next.message.predicted_content_published_at - 1800
              let y = +next.message.predicted_content_published_at + 1800
              if (moment(x*1000).isAfter(moment()) && !dates.includes(x)) {
                dates.push(x)
              }
              if (moment(y*1000).isAfter(moment()) && !dates.includes(y)) {
                dates.push(y)
              }
            }

            let workspaceDates = []
            dates.forEach(d => {
              workspaceDates.push(moment(this.showUserDate(false, d) * 1000))
            })

            console.log("dates", dates)
            console.log("workspaceDates", workspaceDates)
            this.layoutService.showSnackBar({name: error[0].message}, "", SnackBarItem, 6000, {arr: workspaceDates, activeLang: this.activeLang})
            this.hasError = true;
          } else {
            if (error == 'The assignment already exists') {
              this.hasError = true;
              this.submitForm();
              // this.layoutService.showSnackBar({name: ''}, "Try again", SnackBarItem)
            } else {
              this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
            }
          }
        }
      })
    )
  }

  ngOnDestroy(): void {
    this.clearSubscriptions();
  }
}
