import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, fromEvent } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { ChatsComponent } from 'src/app/components/chats/chats.component';
import { PartnersListComponent } from 'src/app/components/main/dialogs/partners/partners-list/partners-list.component';
import { casesModel } from 'src/app/shared/functions/casesModel';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { AuthService } from 'src/app/shared/services/rest/auth.service';
import { ChatService } from 'src/app/shared/services/rest/chat.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-usage-dash',
  templateUrl: './usage-dash.component.html',
  styleUrls: ['./usage-dash.component.scss']
})
export class UsageDashComponent extends BaseClass implements OnInit, OnDestroy {
  public company_id: any;
  public company: any;
  public host: any = environment.host;
  public user_id: number;
  public is_mobile: boolean = false;
  public imgRoute: any = '';
  public user: any;
  public activeLang: any;
  public linkTo: any;
  public hoveredStatus = [
    {
      page: 'Video Processing Units (VPU)',
      hover: true
    },
    {
      page: 'Storage usage',
      hover: false
    },
    {
      page: 'Dubbing',
      hover: false
    },
    {
      page: 'Speech to Text',
      hover: false
    },
    {
      page: 'Text to Speech',
      hover: false
    },
    {
      page: 'AI usage',
      hover: false
    }
  ]
  
  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private companyService: CompanyService,
    private router: Router,
    private sm: StorageManagerService,
    private auth: AuthService,
    private taskService: TaskService,
    private languageService: LanguageService,
    private chatService: ChatService,
    private activatedRoute: ActivatedRoute,
    private cd: ChangeDetectorRef
  ) { super() }

  ngOnInit(): void {
    this.company_id = this.activatedRoute.snapshot.queryParamMap.get('company_id');

    this.checkIsMobile();
    this.getCompany();
    this.getImgRoute();
    this.getCsrf();
    this.getUser();
  }

  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData().subscribe(resp => {
          console.log("getOperations", resp)
          this.activeLang = resp.active;
      })
    )
  }

  getCompany() {
    this.attachSubscriptions(
      this.companyService.getCompany(this.company_id).subscribe(resp => {
        this.companyService.company$.next(resp[0]);
        this.company = resp[0];
      })
    )
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  getCsrf() {
    this.attachSubscriptions(
      this.auth.$userToken.subscribe(resp => {
        this.user_id = resp.user_id;
        this.sm.localStorageSetItem("csrf_param", resp.csrf_param)
        this.sm.localStorageSetItem("csrf_token", resp.csrf_token)
      })
    )
  }

  getUser() {
    this.attachSubscriptions(
      this.auth.$user.subscribe(resp => {
        this.user = resp;
      })
    )
  }

  checkIsMobile() {
    if (window.innerWidth <= 769) {
      this.is_mobile = true;
    } else {
      this.is_mobile = false;
    }
    this.onResize();
  }

  onResize() {
    this.attachSubscriptions(
      fromEvent(window, "resize").pipe(
        map(() => window.innerWidth)
      ).subscribe((wWidth) => {
          if (wWidth <= 769) {
            this.is_mobile = true;
          } else {
            this.is_mobile = false;
          }
        }
      )
    )
  }

  goToUsagePage(page) {
    this.router.navigate(['/settings'], { queryParams: {company_id: this.company_id, page: page} });
  }

  onMouseEnter(e, i) {
    this.hoveredStatus.map(x => x.hover = false);
    this.hoveredStatus[i].hover = true;
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
