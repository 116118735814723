import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { mimeTypes } from 'mime-wrapper';
import { FileService } from 'src/app/shared/services/rest/file.service';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { SameFilesComponent } from '../../../file-interface/dialogs/same-files/same-files.component';
import * as moment from 'moment';
import { BetaVideoEditorComponent } from '../beta-video-editor/beta-video-editor.component';
import { AlphaVideoEditorComponent } from '../alpha-video-editor/alpha-video-editor.component';
import { MainVideoEditorComponent } from '../main-video-editor/main-video-editor.component';
import { Router } from '@angular/router';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { VideoEditorTestComponent } from '../video-editor-test/video-editor-test.component';
import { VideoEditorV5Component } from '../video-editor-v5/video-editor-v5.component';
import { forkJoin, fromEvent, of } from 'rxjs';
import { FormControl } from '@angular/forms';
import { makeArray } from 'src/app/shared/functions/objToArray';
import { OpenTaskComponent } from 'src/app/components/workspace-pages/cases/dialogs/open-task/open-task.component';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { ChatService } from 'src/app/shared/services/rest/chat.service';
import { OpenJobComponent } from 'src/app/components/workspace-pages/cases/dialogs/open-job/open-job.component';
import { casesModel } from 'src/app/shared/functions/casesModel';
import { SnackBarItem } from '../../../snack-bar/snack-bar-item';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { GlobalDataService } from 'src/app/shared/services/common/global-data.service';
import { VideoViewerComponent } from '../video-viewer/video-viewer.component';
import { environment } from 'src/environments/environment';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { VideoFfmpegEditorComponent } from '../video-ffmpeg-editor/video-ffmpeg-editor.component';
import { MatMenuTrigger } from '@angular/material/menu';
import { SelectJobComponent } from '../../../select-job/select-job.component';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { ShortNumberPipe } from 'src/app/shared/pipes/short-number.pipe';
import { ParametersService } from 'src/app/shared/services/rest/parameters.service';
import { TargetParametersComponent } from 'src/app/components/atTasksDialog/target-parameters/target-parameters.component';

@Component({
  selector: 'app-mob-file-settings',
  templateUrl: './mob-file-settings.component.html',
  styleUrls: ['./mob-file-settings.component.scss']
})
export class MobFileSettingsComponent extends BaseClass implements OnInit, OnDestroy {
  public procClicked: boolean = false;
  public mimeTypes = mimeTypes;
  public activeLang: any;
  public part_of: any;
  public is_mobile: boolean = false;
  public isDoneChart: boolean = false;
  public relatedOpen: boolean = false;
  public consistOpen: boolean = false;
  public parsedMeta: any;
  public chats: any;
  public migrations: any;
  public storage: FormControl = new FormControl(this.data.file.storage_code || '');
  public storages: any;
  public profileStatuses: any;
  public contentTypes: any;
  public project: any;
  public waiting_time_limit: FormControl = new FormControl(1);
  public consist_of : any;
  public host: any = environment.host;
  public related: any;
  public origin = window.location.origin;
  public timeZone = new Date().getTimezoneOffset()*60;
  
  public today = moment().set({hour:0,minute:0,second:0}).unix();
  public todayEnd = moment().endOf('day').unix(); 
  
  public totalViews: number = 0;
  view: any[] = [400, 400];
  public platformsChart = []
  // public platformsColors = [
  //   '#FF6E6E', // YouTube
  //   '#F77777', // Instagram
  //   '#83D4D8', // TikTok (бирюзовый)
  //   '#4F9CF9'  // Facebook
  //   // '#FF6B82', // TikTok (розовый)
  // ];
  public platformsColors = {
    domain: [],
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<MobFileSettingsComponent>,
    private languageService: LanguageService,
    private taskService: TaskService,
    private globalDataService: GlobalDataService,
    private parametersService: ParametersService,
    private layoutService: LayoutService,
    private chatService: ChatService,
    private shortNumberPipe: ShortNumberPipe,
    private companyService: CompanyService,
    private router: Router,
    private sm: StorageManagerService,
    private fileService: FileService,
    private dialog: MatDialog,
  ) { super() }

  ngOnInit(): void {
    console.log("MobFileSettingsComponent", this.data);
    this.dialogRef.addPanelClass("file_info_panel");
    this.dialogRef.addPanelClass("back_not_panel");
    if (this.timeZone > 0) {
      this.timeZone = -this.timeZone;
    } else {
      this.timeZone = Math.abs(this.timeZone);
    }
    this.checkIsMobile();
    this.getLangData();
    this.getContentTypes();
    this.getProfilesStatus();
    this.getCopies();
    if (this.data.file.is_dir == 0 && this.data.user && this.data.user.is_root) {
      this.getMigrations();
      this.getStorages();
    }
    if (!!this.data.file.original_file_id) {
      this.getFileRelations({file_id: this.data.file.original_file_id})
      this.getFilePartition({file_id: this.data.file.original_file_id}, "consist_of")
      this.getFilePartition({consist_of_file_id: this.data.file.original_file_id}, "part_of")
    }
    if (!!this.data.file.output_video_project_id) {
      this.getProject()
    }

    this.getTags(this.data.file)

    let arr = [];
    if (this.data.file.taskChannelFiles.filter(chFile => chFile.taskChannel && chFile.taskChannel.channel && chFile.taskChannel.content_views_count).length) {
      this.data.file.taskChannelFiles.filter(chFile => chFile.taskChannel && chFile.taskChannel.channel && chFile.taskChannel.content_views_count).forEach(chFile => {        
        this.totalViews += +chFile.taskChannel.content_views_count;
        if (!arr.filter(k => k.extra.platform_id == chFile.taskChannel.channel.platform_id).length) {
          if (chFile.taskChannel.channel.platform_id == 1) {
            this.platformsColors.domain.push('#FF6E6E')
          } else if (chFile.taskChannel.channel.platform_id == 2) {
            this.platformsColors.domain.push('#F77777')
          } else if (chFile.taskChannel.channel.platform_id == 3) {
            this.platformsColors.domain.push('#1D1D1D')
          } else if (chFile.taskChannel.channel.platform_id == 4) {
            this.platformsColors.domain.push('#4F9CF9')
          } else {
            this.platformsColors.domain.push(`#${Math.floor(Math.random() * 16777215).toString(16).padStart(6, '0')}`)
          }
          arr.push({
            name: chFile.taskChannel.channel.platform.name,
            value: +chFile.taskChannel.content_views_count,
            extra: {
              channel: chFile.taskChannel.channel,
              platform_id: chFile.taskChannel.channel.platform_id
            }
          })
        } else {
          arr.find(k => k.extra.platform_id == chFile.taskChannel.channel.platform_id).value += +chFile.taskChannel.content_views_count;
        }
      });

      // arr.sort((a,b) => {
      //   return b.extra.platform_id - a.extra.platform_id;
      // })

      this.platformsChart = arr;
      if (this.platformsChart.length) {
        this.isDoneChart = true;
      }
    }

    if (this.data.file && !!this.data.file.fileOriginalData && !!this.data.file.fileOriginalData.meta) {
      // Пытаемся разобрать метаданные
      try {
        this.parsedMeta = this.data.file.fileOriginalData.meta;
      } catch (error) {
        console.error("Error parsing metadata:", error);
        // return;
      }
    }
  }

  getPrimaryTag(job) {
    if (job.parameterValuesToTask && job.parameterValuesToTask.length) {
      return job.parameterValuesToTask.find(x => !!x.is_primary)
    } else {
      return undefined
    }
  }

  customLabelFormatting(c:any) {
    // Кастомный шаблон метки
    console.log("customLabelFormatting", c)
    const formattedValue = this.shortNumberPipe.transform(this.platformsChart.find(x => x.name == c).value);
    return `${c} ${formattedValue}`; // Например: "YouTube (40%)"
  }

  getOperations() {
    this.attachSubscriptions(
      this.taskService.getOperations(this.data.company_id || this.data.company.id, this.activeLang).subscribe(resp => {
        this.data.operationsValues = resp;
      })
    )
  }

  checkIsMobile() {
    if (window.innerWidth <= 769) {
      this.is_mobile = true;
    } else {
      this.is_mobile = false;
    }
    this.onResize();
  }

  onResize() {
    this.attachSubscriptions(
      fromEvent(window, "resize").pipe(
        map(() => window.innerWidth)
      ).subscribe((wWidth) => {
          if (wWidth <= 769) {
            this.is_mobile = true;
          } else {
            this.is_mobile = false;
          }
        }
      )
    )
  }

  getTags(file) {
    let tagsFilter:any = {task_id: file.task_id, task_operation_id: file.task_operation_id ? file.task_operation_id : 0, discussion_id: file.discussion_id ? file.discussion_id : 0, file_id: file.id}

    if (file.company_id != this.data.company.id) {
      tagsFilter.partner_company_id = file.company_id
    }
    this.attachSubscriptions(
      this.parametersService.getTargetParameters(this.data.company.id, tagsFilter).subscribe(resp => {
        file.tags = resp;
        console.log("getTags", file.tags)
      })
    )
  }

  openTargetValues(info, element) {
    this.close()

    let initData:any = {
      company: this.data.company,
      task: element,
    }

    initData.target = {
      task_id: info[0],
      task_operation_id: info[1],
      discussion_id: info[2],
      file_id: info[3]
    }
    if (element.company_id != this.data.company.id) {
      initData.target.partner_company_id = element.company_id
    }
    const dialogRef = this.dialog.open(TargetParametersComponent, {
      backdropClass: ['parameters_modal_backdrop'],
      panelClass: ['without_paddings_modal', 'parameters_modal'],
      data: initData
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        this.getTags(this.data.file)
      })
    )
  }

  log() {
    console.log("platformsColors", this.platformsColors)
    console.log("platformsChart", this.platformsChart)
  }

  getContentTypes() {
    this.attachSubscriptions(
      this.companyService.getContentTypes().pipe(
        map(el => el.body)
      ).subscribe(resp => {
        this.contentTypes = resp;
      })
    )
  }

  getContentTypeById(id) {
    if (!this.contentTypes || this.contentTypes.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.contentTypes.find(el => el.id == id)
  }

  getStatusById(id) {
    if (!this.profileStatuses || this.profileStatuses.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.profileStatuses.find(el => el.id == id)
  }

  neededData(task, company) {
    let arr = [
      this.chatService.getTasksChats(company.id, [task.id]).pipe(
        tap(res => {
          task['operations'].map(o_el => {
            o_el['chatsInfo'] = [];
            o_el['chatsInfo'].push(...res.filter(z => z.task_operation_id == o_el.id));
            o_el['unReadChats'] = res.filter(z => z.task_operation_id == o_el.id && z.is_read != '1').length;
            o_el['unClosedChats'] = res.filter(z => z.task_operation_id == o_el.id && z.status_id != '4').length;
          });
          task['chatsInfo'] = [];
          task['chatsInfo'].push(...res.filter(z => z.task_id == task.id));
          task['unReadChats'] = res.filter(z => z.is_read != '1').length;
          task['unClosedChats'] = res.filter(z => z.status_id != '4').length;
        })
      )
    ]
    if (task.company_id == company.id) {
      arr.push(
        this.taskService.getTaskClients(task.id, company.id).pipe(
          tap(res => {
            task.clients = res
          }),
          catchError(err => {
            return err
          })
        )
      )
    }
    return forkJoin(arr)
  }

  download(file) {
    // this.close();
    window.open(this.host + file.original + '?company_id=' + this.data.company_id + `&filename=${file.filename}`, '_blank');
  }

  previewVideo(file) {
    console.log("previewVideo", file)
    if (file.task_id <= 1) {
      const dialogRef = this.dialog.open(VideoViewerComponent, {
        panelClass: 'video_viewer',
        autoFocus: false,
        data: {
          file: file,
          files: [],
          playlist: [],
          task: undefined,
          work: undefined,
          operationsValues: this.data.operationsValues,
          company: this.data.company,
          activeLang: this.activeLang,
          user: this.data.user,
          initCompanyId: this.data.company_id
        }
      });
    } else {    
      this.attachSubscriptions(
        this.taskService.getOneTaskExpand(this.data.company_id, file.task_id).pipe(
          map(x => x.body[0]),
          switchMap(x => this.neededData(x, this.data.company).pipe(map(() => casesModel([x], [], 'update')),map(x => x.arr[0]))),
        ).subscribe(resp => {
          const dialogRef = this.dialog.open(VideoViewerComponent, {
            panelClass: 'video_viewer',
            autoFocus: false,
            data: {
              file: file,
              files: [],
              playlist: [],
              task: resp,
              work: !!file.task_operation_id ? resp.operations.find(x => x.id == file.task_operation_id) : undefined,
              operationsValues: this.data.operationsValues,
              company: this.data.company,
              activeLang: this.activeLang,
              user: this.data.user,
              initCompanyId: this.data.company_id
            }
          });
        })
      )
    }
  }
  
  openInfo(file) {
    console.log("openInfo", file)
    if (file.task_id <= 1) {
      const dialogRef = this.dialog.open(MobFileSettingsComponent, {
        data: {
          company_id: this.data.company.id,
          target_company_id: file.company_id,
          company: this.data.company,
          file: file,
          user: this.data.user,
          imgRoute: this.data.imgRoute,
          activeLang: this.activeLang,
          host: this.host,
          location: file.location,
          task: undefined,
          work: undefined,
          operationsValues: this.data.operationsValues
        }
      });
    } else {
      this.attachSubscriptions(
        this.taskService.getOneTaskExpand(this.data.company.id, file.task_id).pipe(
          map(x => x.body[0]),
          switchMap(x => this.neededData(x, this.data.company).pipe(map(() => casesModel([x], [], 'update')),map(x => x.arr[0]))),
        ).subscribe(resp => {
          console.log("settings OPEN", resp)
          const dialogRef = this.dialog.open(MobFileSettingsComponent, {
            data: {
              company_id: this.data.company.id,
              target_company_id: file.company_id,
              company: this.data.company,
              file: file,
              user: this.data.user,
              imgRoute: this.data.imgRoute,
              activeLang: this.activeLang,
              host: this.host,
              location: file.location,
              task: resp,
              work: !!file.task_operation_id && resp.operations ? resp.operations.find(x => x.id == file.task_operation_id) : undefined,
              operationsValues: this.data.operationsValues
            }
          });
        })
      )
    }
  }

  fileClick(file) {
    console.log('file', file)
    if (file.content_type.indexOf("image") != -1) {
      this.previewVideo(file);
    } else if (file.content_type.indexOf("video") != -1) {
      this.previewVideo(file);
    } else if (file.content_type.indexOf("audio") != -1) {
      this.previewVideo(file);
    }  else if (file.content_type == 'application/pdf') {
      this.previewVideo(file);
    } else if (!!file.thumbnail) {
      this.previewVideo(file);
    } else if (!!file.is_document) {
      this.previewVideo(file);
    } else {
      this.download(file);
    }
  }

  openFolder(barItem) {
    console.log("openFolder", barItem)
    if (!barItem.task_operation_id) {
      this.openFolderTask(barItem, this.data.company)
    } else {
      this.openFolderJob(barItem, this.data.company)
    } 
  }
  
  openFolderTask(file, company) {
    let taskData:any = {
      task_id: file.task_id,
      company: company,
      initCompanyId: company.id,
      loc: file.location
    }

    const dialogRef = this.dialog.open(OpenTaskComponent, {
      backdropClass: 'backdrop_under_header',
      panelClass: !this.is_mobile ? ['open_task_dialog', 'show_header'] : 'open_task_dialog',
      autoFocus: false,
      data: taskData
    });

    dialogRef.afterClosed().subscribe(res => {
      // this.toMainPage()
    })
  }

  openFolderJob(file, company) {
    this.attachSubscriptions(
      this.taskService.getOneTaskExpand(file.company_id, file.task_id).pipe(
        map(x => x.body[0]),
        switchMap(x => this.neededData(x, file.company_id).pipe(map(() => casesModel([x], [], 'update')),map(x => x.arr[0]))),
      ).subscribe(resp => {
        const dialogRef = this.dialog.open(OpenJobComponent, {
          backdropClass: 'backdrop_under_header',
          panelClass: !this.is_mobile ? ['open_task_dialog', 'show_header'] : 'open_task_dialog',
          autoFocus: false,
          data: {
            task_id: file.task_id,
            task_operation_id: file.task_operation_id,
            task: resp,
            company: company,
            initCompanyId: file.company_id,
            loc: file.location
          }
        });

        dialogRef.afterClosed().subscribe(res => {
          // this.toMainPage()
        })
      })
    )
  }

  getProfilesStatus() {
    this.attachSubscriptions(
      this.globalDataService.getContentStatus().subscribe(resp => {
        this.profileStatuses = resp.slice();
        console.log("profileStatuses", this.profileStatuses)
      })
    )
  }

  getProfileStatus(id) {
    if (!this.profileStatuses) {
      return false;
    }
    return this.profileStatuses.find(el => el.id == id)
  }  
  
  getStorageByCode(code) {
    if (!this.storages || !this.storages.find(x => x.storage_code == code)) {
      return {}
    } else {
      return this.storages.find(x => x.storage_code == code)
    }
  }

  getMigrations() {
    this.fileService.getMigrations(this.data.company.id, this.data.file.original_file_id).subscribe(resp => {
      this.migrations = resp
    })
  }

  getStorages() {
    this.attachSubscriptions(
      this.fileService.getStorages(this.data.company.id).subscribe(resp => {
        this.storages = makeArray(resp)
      })
    )
  }
  
  copyData(type) {
    this.layoutService.showSnackBar({name: "File " + type}, marker("Copied"), SnackBarItem)
  }

  openTask(file) {
    let taskData:any = {
      task_id: file.task_id,
      company: this.data.company,
      initCompanyId: this.data.company.id,
      tab: 0,
    }

    const dialogRef = this.dialog.open(OpenTaskComponent, {
      backdropClass: 'backdrop_under_header',
      panelClass: !this.is_mobile ? ['open_task_dialog', 'show_header'] : 'open_task_dialog',
      autoFocus: false,
      data: taskData
    });

    // dialogRef.afterClosed().subscribe(res => {
    //   this.toMainPage()
    // })
  }

  neededJobData(task, company_id) {
    let arr = [
      this.chatService.getTasksChats(company_id, [task.id]).pipe(
        tap(res => {
          task['operations'].map(o_el => {
            o_el['chatsInfo'] = [];
            o_el['chatsInfo'].push(...res.filter(z => z.task_operation_id == o_el.id));
            o_el['unReadChats'] = res.filter(z => z.task_operation_id == o_el.id && z.is_read != '1').length;
            o_el['unClosedChats'] = res.filter(z => z.task_operation_id == o_el.id && z.status_id != '4').length;
          });
          task['chatsInfo'] = [];
          task['chatsInfo'].push(...res.filter(z => z.task_id == task.id));
          task['unReadChats'] = res.filter(z => z.is_read != '1').length;
          task['unClosedChats'] = res.filter(z => z.status_id != '4').length;
        })
      )
    ]
    if (task.company_id == company_id) {
      arr.push(
        this.taskService.getTaskClients(task.id, company_id).pipe(
          tap(res => {
            task.clients = res
          }),
          catchError(err => {
            return err
          })
        )
      )
    }
    return forkJoin(arr)
  }

  openJob(file) {
    this.attachSubscriptions(
      this.taskService.getOneTaskExpand(file.company_id, file.task_id).pipe(
        map(x => x.body[0]),
        switchMap(x => this.neededJobData(x, this.data.company.id).pipe(map(() => casesModel([x], [], 'update')),map(x => x.arr[0]))),
      ).subscribe(resp => {
        const dialogRef = this.dialog.open(OpenJobComponent, {
          backdropClass: 'backdrop_under_header',
          panelClass: !this.is_mobile ? ['open_task_dialog', 'show_header'] : 'open_task_dialog',
          autoFocus: false,
          data: {
            task_id: file.task_id,
            task_operation_id: file.task_operation_id,
            task: resp,
            company: this.data.company,
            initCompanyId: this.data.company.id
          }
        });

        // dialogRef.afterClosed().subscribe(res => {
        //   this.toMainPage()
        // })
      })
    )
  }

  addMigration() {
    let x = {
      company_id: this.data.company.id,
      file_id: this.data.file.original_file_id,
      to_storage_code: this.storage.value
    }
    this.attachSubscriptions(
      this.fileService.addMigration(this.data.company.id, x).subscribe(resp => {
        console.log("addMigration", resp);
        this.storage.patchValue(this.data.file.storage_code || '')
        this.getMigrations();
      })
    )
  }

  togglePrimary(tag) {
    this.attachSubscriptions(
      this.parametersService.editTargetParameter(tag.id, {is_primary: !!tag.is_primary ? 0 : 1}, this.data.company.id).subscribe(resp => {
        tag.is_primary = resp.is_primary;
      }, error => {
        this.layoutService.showSnackBar({name: error}, marker("You don`t have permission to do this"), SnackBarItem)
      })
    )
  }
  
  videoEditorV2(proj, i, openAsNew:boolean = false) {
    const dialogRef = this.dialog.open(MainVideoEditorComponent, {
      panelClass: ['full_screen_modal', 'video_editor', 'video_editor_black'],
      autoFocus: false,
      data: {
        company_id: this.data.company_id,
        target_company_id: this.data.target_company_id,
        company: this.data.company,
        user: this.data.user,
        imgRoute: this.data.imgRoute,
        activeLang: this.data.activeLang,
        host: this.data.host,
        task: this.data.task,
        work: this.data.work,
        project: proj,
        openAsNew: openAsNew
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log(result)
      })
    )
  }

  getCircularReplacer() {
    const ancestors:any = [];
    return function (key, value) {
      if (typeof value !== "object" || value === null) {
        return value;
      }
      // `this` is the object that value is contained in,
      // i.e., its direct parent.
      while (ancestors.length > 0 && ancestors.at(-1) !== this) {
        ancestors.pop();
      }
      if (ancestors.includes(value)) {
        return "[Circular]";
      }
      ancestors.push(value);
      return value;
    };
  }

  duplicateToOtherJob(proj, i, openAsNew:boolean = false, trigger:MatMenuTrigger, version) {
    trigger.closeMenu();

    const dialogRef = this.dialog.open(SelectJobComponent, {
      disableClose: true,
      data: {
        user: this.data.user,
        company: this.data.company,
        project: proj
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log("duplicateToOtherJob", result);
        if (result.data.job) {
          if (version == "A") {
            this.videoEditorVA(proj,i,openAsNew, result.data.job)
          } else {
            this.videoEditorAA(proj,i,openAsNew, result.data.job)
          }
        }
      })
    )
  } 

  videoEditorAA(proj, i, openAsNew:boolean = false, otherJob?) {
    let work = JSON.parse(JSON.stringify(this.data.work, this.getCircularReplacer()));
    if (!!otherJob) {
      work = otherJob;
    }
    if (work) {
      delete work.updatedEmployee;
      delete work.automationScenarios;
      delete work.uniqueEmployees;
      delete work.chatsInfo;
      delete work.createdEmployee;
      delete work.parameterValuesToTask;
      delete work.employees;
      delete work.employeesStatuses;
      delete work.mainEmployees;
      delete work.partnerCompanies;
      delete work.partnerCompaniesStatuses;
      delete work.partnerEmployees;
      delete work.partnerEmployeesStatuses;
    }
    console.log("work", work)

    // card
    let task = JSON.parse(JSON.stringify(this.data.task, this.getCircularReplacer()));
    delete task.channels;
    delete task.chatsInfo;
    delete task.clients;
    delete task.contentPlans;
    delete task.data;
    delete task.groupedParametersForTask;
    delete task.previewImgs;
    delete task.jobEmployees;
    delete task.parameterValuesToTask;
    delete task.worksByTypeAndStatus;
    task.operations = [work];
    console.log("task", task)


    let modalData = {
      previusUrl: this.router.url,
      company_id: this.data.company_id,
      target_company_id: this.data.target_company_id,
      company: this.data.company,
      user: this.data.user,
      imgRoute: this.data.imgRoute,
      activeLang: this.data.activeLang,
      host: this.data.host,
      task: task,
      work: work,
      project: {id: proj.id},
      openAsNew: openAsNew,
      otherJob: !!otherJob
    }

    console.log('modalData', modalData)
    this.sm.localStorageSetItem('ve_data', JSON.stringify(modalData, this.getCircularReplacer()))
    // window.open(this.host,)
    // console.log('sm modalData', JSON.parse(this.sm.localStorageGetItem('ve_data')))
    
    window.open(this.origin + `/video-editor-a?company_id=${this.data.company_id}`, '_blank');
  }

  videoEditorVA(proj, i, openAsNew:boolean = false, otherJob?) {
    let work = this.data.work;
    if (!!otherJob) {
      work = otherJob
    }
    const dialogRef = this.dialog.open(VideoFfmpegEditorComponent, {
      panelClass: ['full_screen_modal', 'video_editor', 'video_editor_black'],
      autoFocus: false,
      disableClose: true,
      data: {
        company_id: this.data.company_id,
        target_company_id: this.data.target_company_id,
        company: this.data.company,
        user: this.data.user,
        imgRoute: this.data.imgRoute,
        activeLang: this.data.activeLang,
        host: this.data.host,
        task: this.data.task,
        work: this.data.work,
        project: proj,
        openAsNew: openAsNew
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log(result)
      })
    )
  }

  videoEditorV6(proj, i, openAsNew:boolean = false) {

    let modalData = {
      previusUrl: this.router.url,
      company_id: this.data.company_id,
      target_company_id: this.data.target_company_id,
      company: this.data.company,
      user: this.data.user,
      imgRoute: this.data.imgRoute,
      activeLang: this.data.activeLang,
      host: this.data.host,
      task: this.data.task,
      work: this.data.work,
      project: proj,
      openAsNew: openAsNew
    }

    console.log('modalData', modalData)
    this.sm.localStorageSetItem('ve_data', JSON.stringify(modalData, this.getCircularReplacer()))
    // window.open(this.host,)
    // console.log('sm modalData', JSON.parse(this.sm.localStorageGetItem('ve_data')))
    
    window.open(this.origin + `/video-editor?company_id=${this.data.company_id}`, '_blank');
  }

  V5VideoEditor(proj, i, openAsNew:boolean = false) {
    const dialogRef = this.dialog.open(VideoEditorV5Component, {
      panelClass: ['full_screen_modal', 'video_editor', 'video_editor_black'],
      autoFocus: false,
      disableClose: true,
      data: {
        company_id: this.data.company_id,
        target_company_id: this.data.target_company_id,
        company: this.data.company,
        user: this.data.user,
        imgRoute: this.data.imgRoute,
        activeLang: this.data.activeLang,
        host: this.data.host,
        task: this.data.task,
        work: this.data.work,
        project: proj,
        openAsNew: openAsNew
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log(result)
      })
    )
  }
  
  videoEditorV5(proj, i, openAsNew:boolean = false) {
    const dialogRef = this.dialog.open(VideoEditorTestComponent, {
      panelClass: ['full_screen_modal', 'video_editor', 'video_editor_black'],
      autoFocus: false,
      disableClose: true,
      data: {
        company_id: this.data.company_id,
        target_company_id: this.data.target_company_id,
        company: this.data.company,
        user: this.data.user,
        imgRoute: this.data.imgRoute,
        activeLang: this.data.activeLang,
        host: this.data.host,
        task: this.data.task,
        work: this.data.work,
        project: proj,
        openAsNew: openAsNew
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log(result)
      })
    )
  }

  
  videoEditorV4(proj, i, openAsNew:boolean = false) {
    const dialogRef = this.dialog.open(AlphaVideoEditorComponent, {
      panelClass: ['full_screen_modal', 'video_editor', 'video_editor_black'],
      autoFocus: false,
      data: {
        company_id: this.data.company_id,
        target_company_id: this.data.target_company_id,
        company: this.data.company,
        user: this.data.user,
        imgRoute: this.data.imgRoute,
        activeLang: this.data.activeLang,
        host: this.data.host,
        task: this.data.task,
        work: this.data.work,
        project: proj,
        openAsNew: openAsNew
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log(result)
      })
    )
  }

  videoEditorV3(proj, i, openAsNew:boolean = false) {
    const dialogRef = this.dialog.open(BetaVideoEditorComponent, {
      panelClass: ['full_screen_modal', 'video_editor', 'video_editor_black'],
      autoFocus: false,
      disableClose: true,
      data: {
        company_id: this.data.company_id,
        target_company_id: this.data.target_company_id,
        company: this.data.company,
        user: this.data.user,
        imgRoute: this.data.imgRoute,
        activeLang: this.data.activeLang,
        host: this.data.host,
        task: this.data.task,
        work: this.data.work,
        project: proj,
        openAsNew: openAsNew
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log(result)
      })
    )
  }

  getProject() {
    this.attachSubscriptions(
      this.fileService.getVideoProjects('1', this.data.company ? this.data.company.id : this.data.company_id, {id: this.data.file.output_video_project_id}, '200').pipe(map(x => x.body)).subscribe(res => {
        console.log('getProject', res)
        if (res.length) {
          this.project = res[0];
        }
      })
    )
  }

  
  startProcessing(file) {
    if (this.procClicked) {
      return
    }

    this.procClicked = true;
    console.log("startProcessing file",  file)
    this.attachSubscriptions(
      this.fileService.fileMetaUpdate({
        company_id: file.company_id,
        waiting_time_limit: this.waiting_time_limit.value,
        id: file.id
      }, this.data.company.id).pipe(
        switchMap(res => {
          if (!!res) {
            return this.fileService.getFilesWithTags(this.data.company.id, [file.id]).pipe(
              tap(targets => {
                console.log("TARGETS", targets)
                if (targets && targets[0]) {
                  file.fileMetaUpdateQueue = targets[0].fileMetaUpdateQueue
                }
              })
            )
          } else {
            return of(res)
          }
        })
      ).subscribe(resp => {
        this.procClicked = false;
        console.log("startProcessing", file)
      }, error => {
        this.procClicked = false;
      })
    )
  }


  getFilePartition(filter:any = {}, type) {
    this.attachSubscriptions(
      this.fileService.getFilePartition("1", this.data.company ? this.data.company.id : this.data.company_id, filter).subscribe(resp => {
        this[type] = resp
        console.log("get " + type, this[type])
      })
    )
  }

  getFileRelations(filter:any = {}) {
    this.attachSubscriptions(
      this.fileService.getFileRelations("1", this.data.company ? this.data.company.id : this.data.company_id, filter).subscribe(resp => {
        this.related = resp
        console.log("get related", this.related)
      })
    )
  }

  convertSecondstoTime(val) {
    if (!val) {
      return '+00:00'
    }
    let given_seconds = Math.abs(val);
    let hours = Math.floor(given_seconds / 3600);
    let minutes = Math.floor((given_seconds - (hours * 3600)) / 60);
    
    let timeString = (val >= 0 ? '+' : '-') + hours.toString().padStart(2, '0') + ':' +
    minutes.toString().padStart(2, '0')
    return timeString;
  }

  getCopies() {
    this.attachSubscriptions(
      this.fileService.getFileData("1", this.data.company ? this.data.company.id : this.data.company_id, {'location_all': "/", task_id: 0, original_file_id : this.data.file.original_file_id || this.data.file.id}).pipe(
        tap(el => {
          this.data.file.countSameFiles = el.headers.get('x-pagination-total-count');
          this.data.file.sameFiles = el.body;

          if (this.data.file.original_file_id) {
            if (this.data.file.countSameFiles > 0) {
              this.data.file.countSameFiles = this.data.file.countSameFiles - 1
            }

            if (this.data.file.sameFiles.find(x => x.id == this.data.file.id)) {
              this.data.file.sameFiles.splice(this.data.file.sameFiles.indexOf(this.data.file.sameFiles.find(x => x.id == this.data.file.id)),1)
            }
          }
        })
      ).subscribe(resp => {
        console.log(this.data.file)
      })
    )
  }

  openSameFiles() {
    const dialogRef = this.dialog.open(SameFilesComponent, {
      data: {
        company_id: this.data.company ? this.data.company.id : this.data.company_id,
        file: this.data.file,
        no_btns: true
      }
    });
  }

  understandFileType(val) {
    if (!val) {
      return ""
    }
    
    if (val == 'application/pdf') {
      return 'pdf'
    } else if (val.indexOf("/") != -1) {
      return val.split('/')[0]
    } else {
      return ""
    }
  }

  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData().subscribe(resp => {
        this.activeLang = resp.active;

        if (!this.data.operationsValues) {
          this.getOperations()
        }
      })
    )
  }

  findWork(id) {
    if (this.data.task && this.data.task.operations && this.data.task.operations.find(x => x.id == id)) {
      return this.data.task.operations.find(x => x.id == id)
    } else if (this.data.file.taskOperation) {
      return this.data.file.taskOperation
    } else {
      return ''
    }
  }

  close() {
    this.dialogRef.removePanelClass('animate__slideInUp')
    this.dialogRef.addPanelClass('animate__slideOutDown')
    setTimeout(()=>{this.dialogRef.close({event: "close", data: false})}, 300);
  }

  getColumnName(id) {
    if (!this.data.operationsValues || !this.data.operationsValues.find(el => el.id == id)) {
      return '';
    }
    return this.data.operationsValues.find(el => el.id == id) && this.data.operationsValues.find(el => el.id == id).translation ? this.data.operationsValues.find(el => el.id == id).translation.name : this.data.operationsValues.find(el => el.id == id).name
  }

  getOperationStatusName(id) {
    switch (+id) {
      case 1:
        return "Waiting";
        break;
      case 2:
        return "In process";
        break;
      case 3:
        return "To approve";
        break;
      case 4:
        return "Approved";
        break;
      case 5:
        return "To correct";
        break;
      case 6:
        return "Sources Needed";
        break;
      case 97:
        return "On hold";
        break;
      case 98:
        return "Ready";
        break;
      case 99:
        return "Canceled";
        break;
    }
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
