import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpRestService } from '../common/http-rest.service';

@Injectable({
  providedIn: 'root'
})
export class ActivityService {

  constructor(
    private http: HttpRestService
  ) { }

  getActivity(company_id, filter) {
    let filter_params = new HttpParams().set("company_id", company_id)

    if (filter) {
      Object.keys(filter).forEach(element => {
        if (filter[element]) {
          filter_params = filter_params.set(element, filter[element]);
        }
      })
    }
    return this.http.executeGet<any>(`/api/task-action-analytics/`, true, {
      observe: 'response',
      params: filter_params
    })
  }


}
