import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { forkJoin, of, ReplaySubject } from 'rxjs';
import { debounceTime, last, map, switchMap, tap } from 'rxjs/operators';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { ScenariosService } from 'src/app/shared/services/rest/scenarios.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';

@Component({
  selector: 'app-multi-jobs-exec-scenarios',
  templateUrl: './multi-jobs-exec-scenarios.component.html',
  styleUrls: ['./multi-jobs-exec-scenarios.component.scss']
})
export class MultiJobsExecScenariosComponent extends BaseClass implements OnInit, OnDestroy {

  public templates: any = [];
  public templatesMoreControl: FormControl = new FormControl();
  public templates$: ReplaySubject<any> = new ReplaySubject<any>(1);
  
  public isSubmit: boolean = false;
  public submited: boolean = false;

  public form: FormGroup;
  public isFormChange: boolean = false;

  constructor(
    private taskService: TaskService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<MultiJobsExecScenariosComponent>,
    private fb: FormBuilder,
    private sm: StorageManagerService,
    private scenariosService: ScenariosService,
    private layoutService: LayoutService
  ) { super() }

  ngOnInit(): void {
    console.log("MultiJobsExecScenariosComponent", this.data)
    
    this.form = this.fb.group({
      template_id: [[], Validators.required],
    })

    this.attachSubscriptions(
      this.form.valueChanges.subscribe(() => this.isFormChange = true)
    )
    
    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        if (this.isFormChange) {
          this.layoutService.openBottomSheet(this.dialogRef);
        } else {
          this.close();
        }
      })
    )
    this.getAutomationTemplates();
    this.attachSubscriptions(
      this.templatesMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchTemplates(resp))
    )
  }

  getAutomationTemplates() {
    this.data.selectedJobs.forEach(job => {
      if (job.automationScenarios && job.automationScenarios.length) {
        job.automationScenarios.forEach(j_scen => {
          if (this.templates.filter(u => u.id == j_scen.template_id).length == 0) {
            this.templates.push(j_scen.template)
          }
        })
      }
    })


    this.templates$.next(this.templates.slice());
    // this.attachSubscriptions(
    //   this.scenariosService.getAutomationTemplates('1', {company_id: this.data.company.id, is_task_operation: 1}, '1').pipe(
    //     switchMap(el => {
    //       let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
    //       let arr = []
    //       for (let index = 1; index <= pages; index++) {
    //         arr.push(index)
    //       }

    //       return forkJoin(arr.map(x => this.scenariosService.getAutomationTemplates(x, {company_id: this.data.company.id, is_task_operation: 1}, '200').pipe(map(u => u.body)))).pipe(
    //         last(),
    //         tap(values => {
    //           this.templates = [].concat(...values)
    //           console.log("getAutomationTemplates", this.templates)
    //           this.templates$.next(this.templates.slice());
    //         }),
    //       )
    //     }),
    //   ).subscribe(resp => {
    //     console.log("getAutomationTemplates sub", resp);
    //   })
    // )
  }

  onSearchTemplates(resp) {
    if (!this.templates) {
      return;
    }

    if (!resp) {
      this.templates$.next(this.templates.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.templates$.next(
      this.templates.filter(z => z.name.toLowerCase().indexOf(resp) > -1)
    );
  }
  
  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  toggleChipTemplate = (chip: any) => {
    if (!!this.form.value.template_id && this.form.value.template_id.includes(chip.id)) {
      let val = [...this.form.value.template_id]
      val.splice(this.form.value.template_id.indexOf(chip.id), 1)
      this.form.patchValue({
        template_id: val
      })
    } else {
      if (!!this.form.value.template_id) {
        let val = [...this.form.value.template_id]
        val.push(chip.id)
        this.form.patchValue({
          template_id: val
        })
      } else {
        this.form.patchValue({
          template_id: [chip.id]
        })
      }
    }
  }

  onSubmit() {
    if (this.form.value.template_id.length == 0) {
      this.submited = true;
      this.layoutService.showSnackBar({name: ''}, marker("You need to fill in all required fields"), SnackBarItem)
      return
    }
    this.submited = false;
    this.isSubmit = true;
    // /api/automation-scenario/execute/${id}/
    let execData = [];


    this.data.selectedJobs.forEach(job => {
      if (job.automationScenarios && job.automationScenarios.length) {
        job.automationScenarios.forEach(j_scen => {
          if (!!this.form.value.template_id.includes(j_scen.template_id)) {
            execData.push({
              "path": `/api/automation-scenario/execute/${j_scen.id}/`,
              "query": {company_id: this.data.company.id},
              "method": "POST",
              "body": {}
            })
          }
        })
      }
    });

    console.log(this.form.value);
    console.log("execData", execData)
    
    this.attachSubscriptions(
      this.taskService.multiRequest(execData).subscribe(resp => {
        console.log("multiRequest", resp)
        this.isSubmit = false;
        this.dialogRef.close({event: "update", data: resp})
      }, error => {
        this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
        this.isSubmit = true;
      })
    )
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

}
