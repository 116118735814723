<h2 mat-dialog-title class="dialog_title">
    <span (click)="log()">
        {{ (!data.task ? 'Workspace' : (!data.task_operation_id ? "Card" : "Job") + " projects") | translate }}
    </span>
    <button mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h2>
<mat-dialog-content class="mat-typography">
    <div class="">
        <div class="head_wrp">
            <div class="project_values">
                <div class="id">{{"ID" | translate}}</div>
                <div class="name">{{"Project Name" | translate}}</div>
                <div class="created">{{"Created At" | translate}}</div>
                <div class="stage">{{"Stage" | translate}}</div>
                <div class="units">{{"Units" | translate}}</div>
                <!-- <div class="time" #tooltip="matTooltip" matTooltip="{{ 'Queue time' | translate }}" [matTooltipPosition]="'above'"><mat-icon>more_time</mat-icon></div>
                <div class="time" #tooltip="matTooltip" matTooltip="{{ 'Processing start time' | translate }}" [matTooltipPosition]="'above'"><mat-icon>schedule</mat-icon></div>
                <div class="time" #tooltip="matTooltip" matTooltip="{{ 'Processing end time' | translate }}" [matTooltipPosition]="'above'"><mat-icon>access_time_filled</mat-icon></div>
                <div class="time" #tooltip="matTooltip" matTooltip="{{ 'How many seconds did it take' | translate }}" [matTooltipPosition]="'above'"><mat-icon>alarm_on</mat-icon></div>
                <div class="time" #tooltip="matTooltip" matTooltip="{{ 'Preparing' | translate }}" [matTooltipPosition]="'above'"><mat-icon>downloading</mat-icon></div> -->
                <div class="time" #tooltip="matTooltip" matTooltip="{{ 'Created at' | translate }}" [matTooltipPosition]="'above'"><mat-icon>more_time</mat-icon></div>
                <div class="time" #tooltip="matTooltip" matTooltip="{{ 'Updated at' | translate }}" [matTooltipPosition]="'above'"><mat-icon>update</mat-icon></div>
            </div>
            
            <div class="company_btns">
                {{"Actions" | translate}}
            </div>
        </div>
        <mat-list 
        role="list" 
        class="scrolled_list" 
        [appDeviceClass]="'custom-scroll custom-scroll-t'"           
        infinite-scroll
        [infiniteScrollDisabled]="isLoad"
        [scrollWindow]="false"
        [infiniteScrollUpDistance]="scrollUpDistance"
        [infiniteScrollThrottle]="throttle"
        (scrolled)="onDown($event)">
            <mat-list-item *ngFor="let project of projects; let i = index;" role="listitem" class="company_item proj_list_item">
                <div class="project_out">
                    <div class="project_in">
                        <div class="project_values">
                            <div class="id">{{project?.id}}</div>
                            <div class="name">
                                <div class="name_in text_one_line">{{project?.name}}</div>
                                <!-- <div class="output_in">{{project?.output_location}}</div> -->
                            </div>
                            <div class="created">
                                <span #tooltip="matTooltip" matTooltip="{{ project?.created_at*1000 | date:'hh:mm, a':'':data.activeLang }}" [matTooltipPosition]="'above'">{{project?.created_at | reportsDate:data.activeLang}}</span>
                            </div>
                            <div class="stage">
                                <span *ngIf="project?.is_draft">{{"Draft" | translate}}</span>
                                <span *ngIf="project?.is_error" #tooltip="matTooltip" matTooltip="{{ project?.processing_error }}" [matTooltipPosition]="'above'">{{"Error" | translate}}</span>
                                <span *ngIf="project?.is_processing">
                                    {{"Processing" | translate}}
                                </span>
                                <span *ngIf="project?.is_ready">{{"Ready" | translate}}</span>
                                <span *ngIf="project?.is_reset == 1">{{"Is Reset" | translate}}</span>
                                <span *ngIf="project?.is_to_process">{{"To Process" | translate}}</span>
                            </div>
                            <div class="units">
                                <span *ngIf="project?.total_price_unit">{{project?.total_price_unit}}</span>
                            </div>
                            <!-- <div class="time">
                                <span #tooltip="matTooltip" matTooltip="{{ project?.processing_queue_at*1000 | date:'hh:mm, a':'':data.activeLang }}" [matTooltipPosition]="'above'">{{project?.processing_queue_at | reportsDate:data.activeLang}}</span>
                            </div>
                            <div class="time">
                                <span #tooltip="matTooltip" matTooltip="{{ project?.processing_started_at*1000 | date:'hh:mm, a':'':data.activeLang }}" [matTooltipPosition]="'above'">{{project?.processing_started_at | reportsDate:data.activeLang}}</span>
                            </div>
                            <div class="time">
                                <span #tooltip="matTooltip" matTooltip="{{ project?.processing_completed_at*1000 | date:'hh:mm, a':'':data.activeLang }}" [matTooltipPosition]="'above'">{{project?.processing_completed_at | reportsDate:data.activeLang}}</span>
                            </div>
                            <div class="time">
                                <span>{{project?.processing_time | timeFormat}}</span>
                            </div>
                            <div class="time">
                                <span>{{project?.processing_download_time | timeFormat}}</span>
                            </div> -->
                            <div class="time">
                                <span #tooltip="matTooltip" matTooltip="{{ project?.created_at*1000 | date:'hh:mm, a':'':data.activeLang }}" [matTooltipPosition]="'above'">{{project?.created_at | reportsDate:data.activeLang}}</span>
                            </div>
                            <div class="time">
                                <span #tooltip="matTooltip" matTooltip="{{ project?.updated_at*1000 | date:'hh:mm, a':'':data.activeLang }}" [matTooltipPosition]="'above'">{{project?.updated_at | reportsDate:data.activeLang}}</span>
                            </div>
                        </div>
                        
                        <div class="company_btns d-f-c">
                            <button [ngClass]="{'dont_show': !project?.custom_data}" style="color: #4263c6;" mat-icon-button (click)="editProjectCardsAndPublish(project)">
                                <mat-icon>edit</mat-icon>
                            </button>
                            <button [ngClass]="{'dont_show': project?.is_processing || project?.is_to_process || project?.is_reset == 1}" mat-icon-button (click)="refreshProject(project, i)" style="color: green;">
                                <mat-icon>refresh</mat-icon>
                            </button>
                            <button mat-icon-button (click)="deleteProject(project, i)" *ngIf="!!data?.user?.is_root" style="color: red;">
                                <mat-icon>delete</mat-icon>
                            </button>
                            <button mat-icon-button style="color: #686868;" (click)="project.isOpen = !project.isOpen" *ngIf="project?.runHistory && project?.runHistory.length">
                                <mat-icon>
                                    {{ project?.isOpen ? 'expand_less' : 'expand_more' }}
                                </mat-icon>
                            </button>
                        </div>
                    </div>
                    <div class="project_history" *ngIf="project?.isOpen">
                        <div class="history_item" *ngFor="let item of project?.runHistory">
                            <div class="project_values">
                                <div class="id"></div>
                                <div class="name">
                                    <app-user [class]="'user_div'" [employee]="item.employee"></app-user>
                                </div>
                                <div class="created">
                                    <span #tooltip="matTooltip" matTooltip="{{ item.created_at*1000 | date:'hh:mm, a':'':data.activeLang }}" [matTooltipPosition]="'above'">{{item.created_at | reportsDate:data.activeLang}}</span>
                                </div>
                                <div class="stage">
                                    <span *ngIf="item.is_draft">{{item?.server_id}}</span>
                                </div>
                                <div class="units">
                                    <span *ngIf="item?.price_unit">{{item?.price_unit}}</span>
                                </div>
                                <div class="time">
                                    <span #tooltip="matTooltip" matTooltip="{{ item.processing_queue_at*1000 | date:'hh:mm, a':'':data.activeLang }}" [matTooltipPosition]="'above'">{{item.processing_queue_at | reportsDate:data.activeLang}}</span>
                                </div>
                                <div class="time">
                                    <span #tooltip="matTooltip" matTooltip="{{ item.processing_started_at*1000 | date:'hh:mm, a':'':data.activeLang }}" [matTooltipPosition]="'above'">{{item.processing_started_at | reportsDate:data.activeLang}}</span>
                                </div>
                                <!-- <div class="time">
                                    <span #tooltip="matTooltip" matTooltip="{{ item.processing_completed_at*1000 | date:'hh:mm, a':'':data.activeLang }}" [matTooltipPosition]="'above'">{{item.processing_completed_at | reportsDate:data.activeLang}}</span>
                                </div>
                                <div class="time">
                                    <span>{{item.processing_time | timeFormat}}</span>
                                </div>
                                <div class="time">
                                    <span>{{item.processing_download_time | timeFormat}}</span>
                                </div> -->
                            </div>
                            <div class="company_btns">

                            </div>
                        </div>
                    </div>
                </div>
            </mat-list-item>
        </mat-list>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <div class="d-f-c" style="padding: 15px 0;">
        <button mat-raised-button (click)="close()">
            {{ "Close" | translate }}
        </button>
        <button class="d-f-c ml-1" mat-raised-button color='primary' (click)="refreshProjects()">
            {{ "Refresh" | translate }}
        </button>
    </div>
</mat-dialog-actions>
