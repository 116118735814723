<mat-dialog-content class="mat-typography" dirEnter (dirSubmit)="!form.valid || isSubmit ? null : onSubmit()">
    <div class="container form_wrapper">
        <div class="title_modal">
            {{ "Editing company member" | translate }}
        </div>

        <form [formGroup]="form" class="form_with_pads form_with_valids">
      
            <mat-form-field appearance="standard" class="full_width_form" *ngIf="!!user.is_root">
                <mat-label>{{ "Choose an employee" | translate }}</mat-label>
                <mat-select formControlName="vuser_id" required>
                <mat-option *ngFor="let employee of vEmployees" [value]="employee.id">
                    {{ employee.name }}
                </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ 'Name In Workspace' | translate }}</mat-label>
                <input formControlName="company_name" matInput placeholder="{{ 'Name In Workspace' | translate }}">
            </mat-form-field>

            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ 'Name in Partner Workspaces' | translate }}</mat-label>
                <input formControlName="partner_name" matInput placeholder="{{ 'Name in Partner Workspaces' | translate }}">
            </mat-form-field>

            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ 'Member description' | translate }}</mat-label>
                <textarea formControlName="description" matInput placeholder="{{ 'Member description' | translate }}"
                cdkTextareaAutosize
                [maxLength]="250"
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="5"></textarea>
            </mat-form-field>

            <div class="box_wrp">
                <mat-checkbox color="primary" formControlName="is_company_image">{{ "Show avatar within the company" | translate }}</mat-checkbox>
            </div>

            <div class="box_wrp">
                <mat-checkbox color="primary" formControlName="is_partner_image">{{ "Show avatar to other companies" | translate }}</mat-checkbox>
            </div>
    
            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ "Choose a currency" | translate }}</mat-label>
                <mat-select formControlName="currency_id" required [disabled]="disableSelect">
                <mat-option *ngFor="let currency of currencyList" [value]="currency.id">
                    {{ currency.name }}
                </mat-option>
                </mat-select>
            </mat-form-field>
    
            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ 'Points' | translate }}</mat-label>
                <input formControlName="rate_target" matInput type="number" placeholder="{{ 'Points' | translate }}">
            </mat-form-field>
    
            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ 'Salary' | translate }}</mat-label>
                <input formControlName="salary" matInput type="number" placeholder="{{ 'Salary' | translate }}">
            </mat-form-field>
    
        </form>

        
        
        <form [formGroup]="emplForm" class="form_with_pads form_with_valids">
            <p style="font-weight: 500; font-size: 15px;">{{"Can see and assign" | translate}}:</p>
            <div class="users_select">
                <label>{{ "Individuals" | translate }}: </label>
                <mat-chip-list multiple selectable formControlName="view_employee_id">
                    <ng-container *ngFor="let employee of employees; let i = index">
                        <mat-chip class="chip_user" (click)="toggleChip(employee)" [disabled]="isLoad" [value]="employee.id">
                            <app-user [class]="'user_div'" [employee]="employee"></app-user>
                        </mat-chip>
                        <mat-chip *ngIf="i == employees.length - 1 && i > 6" class="chip_user more" (click)="employeeIdContainer.open()">
                            {{ "more" | translate }}
                        </mat-chip>
                    </ng-container>
                </mat-chip-list>
            </div>
    
            <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                <mat-select multiple #employeeIdContainer formControlName="view_employee_id" (selectionChange)="toggleSelectChip($event)">
                    <mat-option>
                        <ngx-mat-select-search 
                        placeholderLabel="{{ 'Search' | translate }}" 
                        noEntriesFoundLabel="{{ 'no matching executor found' | translate }}"
                        [formControl]="employeeMoreControl"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option [disabled]="isLoad" *ngFor="let employee of employees$ | async" [value]="employee.id">
                        <app-user [class]="'user_div'" [employee]="employee"></app-user>
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </form>

        <form [formGroup]="partnerForm" class="form_with_pads form_with_valids" *ngIf="partners?.length > 0">

            <div class="users_select">
                <label>{{ "Workspaces" | translate }}: </label>
                <mat-chip-list multiple selectable formControlName="view_partner_company_id">
                    <ng-container *ngFor="let partner of partners; let i = index">
                        <mat-chip [disabled]="isLoad" class="chip_user" (click)="togglePartnerChip(partner)" [value]="partner.partner_company_id">
                            <app-user [class]="'user_div'" [companyEmployee]="partner?.partnerCompany" [imgRoute]="imgRoute" [host]="host" [company]="data?.company"></app-user>
                        </mat-chip>
                        <mat-chip *ngIf="i == partners.length - 1 && i > 6" class="chip_user more" (click)="partnerContainer.open()">
                            {{ "more" | translate }}
                        </mat-chip>
                    </ng-container>
                </mat-chip-list>
            </div>

            <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                <mat-select multiple #partnerContainer formControlName="view_partner_company_id" (selectionChange)="togglePartnerSelectChip($event)">
                    <mat-option>
                        <ngx-mat-select-search 
                        placeholderLabel="{{ 'Search' | translate }}" 
                        noEntriesFoundLabel="{{ 'no matching partner found' | translate }}"
                        [formControl]="partnerMoreControl"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option [disabled]="isLoad" *ngFor="let partner of partners$ | async" [value]="partner?.partner_company_id">
                        <app-user [class]="'user_div'" [companyEmployee]="partner?.partnerCompany" [imgRoute]="imgRoute" [host]="host" [company]="data?.company"></app-user>
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </form>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <div class="f_w_p_btns">
        <button mat-button (click)="close()">
            {{ "Close" | translate }}
        </button>
        <button class="ml-1 d-f-btn" (click)="onSubmit()" cdkFocusInitial mat-raised-button color="primary" [disabled]="isSubmit">
            <mat-spinner class="spin_16 mr-1" *ngIf="isSubmit"></mat-spinner>  
            {{ "Save" | translate }}
        </button>
    </div>
</mat-dialog-actions>
