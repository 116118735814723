import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { forkJoin, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { getLessStatuses } from 'src/app/shared/functions/statusesLessModel';
import { BackJobToPrevStatusComponent } from 'src/app/shared/global_components/back-job-to-prev-status/back-job-to-prev-status.component';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { ChatService } from 'src/app/shared/services/rest/chat.service';
import { MembersService } from 'src/app/shared/services/rest/members.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';

@Component({
  selector: 'app-mob-change-personal-status',
  templateUrl: './mob-change-personal-status.component.html',
  styleUrls: ['./mob-change-personal-status.component.scss']
})
export class MobChangePersonalStatusComponent extends BaseClass implements OnInit, OnDestroy {
  public employees: any;
  public me: any;
  public statuses: any = this.data.statuses.slice();
  public emplStatuses: any = this.data.statuses.slice();
  public selectedIndex: FormControl = new FormControl(0);
  public activeStatus: any;
  public changedEmployees: any = [];
  public interfaceEmployees: any;
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<MobChangePersonalStatusComponent>,
    private membersService: MembersService,
    private bottomSheet: MatBottomSheet,
    private layoutService: LayoutService,
    private fb: FormBuilder
  ) { super() }

  ngOnInit(): void {
    console.log("MobChangePersonalStatusComponent", this.data)
    this.emplStatuses.map(x => {
      x.empl = []
    })
    if (!!this.data.chat) {
      this.interfaceEmployees = this.data.chatEmlStats
    } else {
      this.interfaceEmployees = this.data.work.mobEmployees
    }
    if (this.interfaceEmployees) {
      this.interfaceEmployees.forEach(element => {
        if (element.is_partner) {
          if (element.partnerCompanyStatus.status_id === 0 || !element.partnerCompanyStatus.status_id) {
            element.partnerCompanyStatus.status_id = this.data.work.status_id
          }
          this.emplStatuses.find(x => x.id == element.partnerCompanyStatus.status_id).empl.push(element)
        } else {
          if (element.employeeStatus.status_id === 0 || !element.employeeStatus.status_id) {
            element.employeeStatus.status_id = this.data.work.status_id
          }
          this.emplStatuses.find(x => x.id == element.employeeStatus.status_id).empl.push(element)
        }
      });
    }

    console.log("this.emplStatuses", this.emplStatuses)
    console.log(this.data.work)
  }

  selectStatus(status) {
    this.activeStatus = status;
    this.employees = [];
    
    this.statuses.filter(x => x.id != status.id).forEach(el => {
      this.employees.push(...el.empl)
    });

    if (this.employees.length > 0) {
      this.next();
    }
  }

  selectEmployee(empl) {
    empl.is_select = !empl.is_select;
  }

  swapEmployees() {
    console.log("this.employees", this.employees)
    this.employees.forEach((element, index) => {
      if (element.is_select) {
        if (!this.changedEmployees.find(x => x.id == element.id)) {
          this.changedEmployees.push({
            id: element.id,
            item: element,
            to_status: this.activeStatus.id
          })
        } else {
          this.changedEmployees.find(x => x.id == element.id).item = element;
          this.changedEmployees.find(x => x.id == element.id).to_status = this.activeStatus.id
        }

        let target = this.statuses.find(x => x.empl.filter(u => u.id == element.id).length != 0).empl.find(b => b.id == element.id)
        target.is_select = false;
        this.statuses.find(x => x.empl.filter(u => u.id == element.id).length != 0).empl.splice(this.statuses.find(x => x.empl.filter(u => u.id == element.id).length != 0).empl.indexOf(target), 1)
        this.statuses.find(x => x.id == this.activeStatus.id).empl.push(target)
      }
      if (index == this.employees.length - 1) {
        setTimeout(() => {
          this.back();
        }, 0)
      }
    });

  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer !== event.container) {
      console.log(event.currentIndex, event.previousContainer.data['empl'])
      console.log(event)

      if (!this.changedEmployees.find(x => x.id == event.item.data.id)) {
        this.changedEmployees.push({
          id: event.item.data.id,
          item: event.item.data,
          to_status: event.container.data['id']
        })
      } else {
        this.changedEmployees.find(x => x.id == event.item.data.id).item = event.item.data;
        this.changedEmployees.find(x => x.id == event.item.data.id).to_status = event.container.data['id']
      }

      transferArrayItem(
        event.previousContainer.data['empl'],
        event.container.data['empl'],
        event.previousIndex,
        event.currentIndex,
      )

    }

    console.log("this.changedEmployees", this.changedEmployees)
  }
  
  startDragged() {
    document.body.classList.add("dragged");
  }
  
  stopDragged() {
    document.body.classList.remove("dragged");
  }

  setAll() {
    this.employees.forEach((element, index) => {
      element.is_select = true;
      if (!this.changedEmployees.find(x => x.id == element.id)) {
        this.changedEmployees.push({
          id: element.id,
          item: element,
          to_status: this.activeStatus.id
        })
      } else {
        this.changedEmployees.find(x => x.id == element.id).item = element;
        this.changedEmployees.find(x => x.id == element.id).to_status = this.activeStatus.id
      }
      let target = this.statuses.find(x => x.empl.filter(u => u.id == element.id).length != 0).empl.find(b => b.id == element.id)
      target.is_select = false;
      this.statuses.find(x => x.empl.filter(u => u.id == element.id).length != 0).empl.splice(this.statuses.find(x => x.empl.filter(u => u.id == element.id).length != 0).empl.indexOf(target), 1)
      this.statuses.find(x => x.id == this.activeStatus.id).empl.push(target)

      if (index == this.employees.length - 1) {
        this.activeStatus = undefined;
        this.employees = undefined;
        this.onSubmit();
      }
    });


  }

  checkIsManager(task, company, _user) {
    if (!task || !company || !_user) {
      return false
    }
    return task.managers.filter(x => x.user_id == _user.id).length > 0 || task?.group?.managers.filter(x => x.user_id == _user.id).length > 0 || company?.permissions.includes('manager'); 
  }

  close() {
    this.dialogRef.removePanelClass('animate__slideInUp')
    this.dialogRef.addPanelClass('animate__slideOutDown')
    setTimeout(()=>{this.dialogRef.close({event: "close", data: false})}, 300);
  }

  goBack() {
    this.dialogRef.removePanelClass('animate__slideInUp')
    this.dialogRef.addPanelClass('animate__slideOutDown')
    setTimeout(()=>{this.dialogRef.close({event: "back", data: false})}, 300);
  }

  back() {
    this.selectedIndex.patchValue(this.selectedIndex.value - 1);
    this.activeStatus = undefined;
    this.employees = undefined;
  }

  next() {
    this.selectedIndex.patchValue(this.selectedIndex.value + 1);
  }

  changeIndex(val) {
    this.selectedIndex.patchValue(val);
  }

  getOperationStatusName(id) {
    switch (+id) {
      case 1:
        return "Waiting";
        break;
      case 2:
        return "In process";
        break;
      case 3:
        return "To approve";
        break;
      case 4:
        return "Approved";
        break;
      case 5:
        return "To correct";
        break;
      case 6:
        return "Sources Needed";
        break;
      case 97:
        return "On hold";
        break;
      case 98:
        return "Ready";
        break;
      case 99:
        return "Canceled";
        break;
    }
  }

  onSubmit() {
    console.log("onSubmit");
    this.attachSubscriptions(
      forkJoin(this.changedEmployees.map(empl => {
        if (!!empl.item.is_partner) {
          if (!!empl.item.partnerCompanyStatus?.id) {
            return this.membersService.editPartnerStatus(empl.item.partnerCompanyStatus.id, {status_id: empl.to_status}, this.data.company_id).pipe(
              catchError(error => {
                this.showStatusError(error, empl.item.partnerCompany.name)
                return of(false)
              }),
              switchMap(val => {
                if ([3,4,6,97,98,99].includes(empl.to_status)) {
                  return this.membersService.getTaskPartnerStatuses({task_operation_id: this.data.work.id, status_id: getLessStatuses(empl.to_status), partner_company_id: empl.item.partner_company_id}, this.data.company.id, '2').pipe(
                    switchMap(res => {
                      console.log("drop RES3", res)
                      if (res.length == 1 && !res[0].discussion_id) {
                        return this.membersService.editPartnerStatus(res[0].id, {status_id: empl.to_status}, this.data.company_id).pipe(
                          map(() => val)
                        )
                      } else {
                        return of(val)
                      }
                    })
                  )
                } else {
                  return of(val)
                }
              })
            )
          } else {
            return this.membersService.addPartnerStatus({
              status_id: empl.to_status,
              company_id: this.data.company_id,
              discussion_id: this.data.chat ? this.data.chat.id : 0,
              task_id: this.data.taks.id,
              task_operation_id: this.data.work.id,
              partner_company_id: empl.item.partner_company_id,
            }, this.data.company_id).pipe(
              catchError(error => {
                this.showStatusError(error, empl.item.partnerCompany?.name)
                return of(false)
              })
            )
          }
        } else if (!!empl.item.is_partner_employee) {
          if (!!empl.item.employeeStatus?.id) {
            return this.membersService.editPartnerEmployeeStatus(empl.item.employeeStatus.id, {status_id: empl.to_status}, this.data.company_id).pipe(
              catchError(error => {
                this.showStatusError(error, empl.item.employee.display_name)
                return of(false)
              }),
              switchMap(val => {
                console.log("drop RES1", val)
                if ([3,4,6,97,98,99].includes(empl.to_status)) {
                  console.log("drop RES2", val)
                  return this.membersService.getTaskPartnerEmployeeStatuses({task_operation_id: this.data.work.id, status_id: getLessStatuses(empl.to_status), partner_employee_id: empl.item.partner_employee_id, partner_company_id: empl.item.partner_company_id}, this.data.company.id, '2').pipe(
                    switchMap(res => {
                      console.log("drop RES3", res)
                      if (res.length == 1 && !res[0].discussion_id) {
                        return this.membersService.editPartnerEmployeeStatus(res[0].id, {status_id: empl.to_status}, this.data.company.id).pipe(
                          map(() => val)
                        )
                      } else {
                        return of(val)
                      }
                    })
                  )
                } else {
                  return of(val)
                }
              })
            )
          } else {
            return this.membersService.addPartnerEmployeeStatus({
              status_id: empl.to_status,
              company_id: this.data.company_id,
              task_id: this.data.taks.id,
              task_operation_id: this.data.work.id,
              discussion_id: this.data.chat ? this.data.chat.id : 0,
              partner_company_id: empl.item.partner_company_id,
              partner_employee_id: empl.item.partner_employee_id
            }, this.data.company_id).pipe(
              catchError(error => {
                this.showStatusError(error, empl.item.employee.display_name)
                return of(false)
              })
            )
          }
        } else {
          if (!!empl.item.employeeStatus?.id) {
            return this.membersService.editTaskEmployeeStatus(empl.item.employeeStatus.id, {status_id: empl.to_status}, this.data.company_id).pipe(
              catchError(error => {
                this.showStatusError(error, empl.item.employee.display_name)
                return of(false)
              }),
              switchMap(val => {
                if ([3,4,6,97,98,99].includes(empl.to_status)) {
                  return this.membersService.getTaskEmployeeStatuses({task_operation_id: this.data.work.id, status_id: getLessStatuses(empl.to_status), employee_id: empl.item.employee_id}, this.data.company.id, '2').pipe(
                    switchMap(res => {
                      console.log("RES3", res)
                      if (res.length == 1 && !res[0].discussion_id) {
                        return this.membersService.editTaskEmployeeStatus(res[0].id, {status_id: empl.to_status}, this.data.company_id).pipe(
                          map(() => val)
                        )
                      } else {
                        return of(val)
                      }
                    })
                  )
                } else {
                  return of(val)
                }
              })
            )
          } else {
            return this.membersService.addTaskEmployeeStatus({
              status_id: empl.to_status,
              company_id: this.data.company_id,
              task_id: this.data.taks.id,
              task_operation_id: this.data.work.id,
              discussion_id: this.data.chat ? this.data.chat.id : 0,
              employee_id: empl.item.employee_id,
            }, this.data.company_id).pipe(
              catchError(error => {
                this.showStatusError(error, empl.item.employee.display_name)
                return of(false)
              })
            )
          }
        }
      })).subscribe(resp => {
        console.log("FORK", resp)
        this.dialogRef.removePanelClass('animate__slideInUp')
        this.dialogRef.addPanelClass('animate__slideOutDown')
        setTimeout(()=>{this.dialogRef.close({event: "update", data: resp})}, 300);
      })
    )
    
  }

  showStatusError(error, user?) {
    this.layoutService.showSnackBar({name: user}, `You do not have permission to do this. ${error}`, SnackBarItem)
  }

  onPartnerImgError(event){
    event.target.src = this.data.imgRoute+'/assets/img/partner.png'
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
