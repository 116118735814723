import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTable } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { concatMap, debounceTime, distinctUntilChanged, filter, last, map, switchMap, tap } from 'rxjs/operators';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { AuthService } from 'src/app/shared/services/rest/auth.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { environment } from 'src/environments/environment';
import { concat, forkJoin, fromEvent, interval, of, Subscription } from 'rxjs';
import * as moment from 'moment';
import { UserCalendarComponent } from 'src/app/shared/global_components/user-calendar/user-calendar.component';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { endOfDay, endOfMonth, endOfWeek, isSameDay, isSameMonth, startOfDay, startOfMonth, startOfWeek } from 'date-fns';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MY_FORMATS, MY_NEW_FORMATS } from 'src/app/components/workspace-pages/cases/dialogs/open-task/dialogs/add-task-profile/add-task-profile.component';
import { NgxMatDateAdapter } from '@angular-material-components/datetime-picker';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
@Component({
  selector: 'app-employees-schedules',
  templateUrl: './employees-schedules.component.html',
  styleUrls: ['./employees-schedules.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: false } },
  ]
})
export class EmployeesSchedulesComponent extends BaseClass implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild("tableContainer") tableContainer: ElementRef;
  @ViewChild(MatTable) table: MatTable<any>;
  public host: any = environment.host;
  public imgRoute: any = '';
  public origin = window.location.origin;
  public user: any = {};
  public user_id: number;
  public company_id: any;
  public departments: any;
  public pos: any;
  public permissions: any;
  public company: any;
  public currencyList: any;
  public exportEmplData: any;
  public page: number = 1;
  public selectedIndex: FormControl = new FormControl(0);
  public is_deleted: number = 0;
  public pagination: any;
  public activeLang: any;
  public groups: any;

  public emplsDataSub: Subscription;
  public employees: any[];

  public displayedColumns: string[] = ["state", "timer", "Date", "User", "Status", "RSC", "Application status", "BravoregId", "Activity"]
  public isOwnerAccept: boolean = false;

  public filterSchedule: FormGroup = this.fb.group({
    from_day: '',
    to_day: ''
  })

  public filter: FormGroup = this.fb.group({
    count: '',
    q: '',
    content_type_id: '',
    permission: '',
    department_id: '',
    department_position_id: '',
    group_id: '',
    id: "",
  });
  viewDate: Date = new Date();
  CalendarView: any = {
    Month: "month",
    Week: "week",
    Day: "day"
  }
  daysType: any = {
    month: "monthViewDays",
    week: "weekViewDays",
    day: "dayViewDays"
  }
  hoursList = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24]
  public view: FormControl = new FormControl(this.CalendarView.Week);
  public dayViewDays: any[] = [
    {
      timestamp: moment().valueOf(), // Текущий день
      from: moment().startOf('d').format("X"),
      to: moment().endOf('d').format("X"),
      time: moment().format('DD.MM.YYYY dddd') // Текущее время в формате "ЧЧ:ММ"
    }
  ];
  public today = moment().startOf('d').format("X")
  public weekViewDays: any[] = [];
  public monthViewDays: any[] = [];
  constructor(
    private fb: FormBuilder,
    private sm: StorageManagerService,
    private auth: AuthService,
    private companyService: CompanyService,
    private router: Router,
    private taskService: TaskService,
    private languageService: LanguageService,
    private activatedRoute: ActivatedRoute,
    private layoutService: LayoutService,
    private _ngx_adapter: NgxMatDateAdapter<any>,
    private _adapter: DateAdapter<any>,
    private dialog: MatDialog
  ) {
    super()
   }

  ngOnInit(): void {
    window.scroll(0,0);
    this.company_id = this.activatedRoute.snapshot.queryParamMap.get('company_id');

    this.getUser();
    this.getImgRoute();
    this.getCsrf();
    this.getGroupsCompany();
    // this.getEmployees(this.page);
    
    this.getPermissions();
    this.getLangData();
    this.getDepartmentsDyn();
    this.getPositionsDyn();
    // this.getCompany();

    const getStart: any = {
      month: startOfMonth,
      week: startOfWeek,
      day: startOfDay,
    }[this.view.value];

    const getEnd: any = {
      month: endOfMonth,
      week: endOfWeek,
      day: endOfDay,
    }[this.view.value];
    
    // console.log("week START", moment(startOfWeek(this.viewDate, {weekStartsOn: 1})).startOf('day').utcOffset(0, true));
    // console.log("week START x", moment(startOfWeek(this.viewDate, {weekStartsOn: 1})).startOf('day').utcOffset(0, true).format('X'));

    if (this.view.value == "week") {
      this.filterSchedule.patchValue({
        from_day : moment(startOfWeek(this.viewDate, {weekStartsOn: 1})).utcOffset(0, true),
        to_day : moment(endOfWeek(this.viewDate, {weekStartsOn: 1})).utcOffset(0, true),
      })
    } else {
      this.filterSchedule.patchValue({
        from_day : moment(moment(getStart(this.viewDate)).unix() * 1000).utcOffset(0, true),
        to_day : moment(moment(getEnd(this.viewDate)).unix() * 1000).utcOffset(0, true),
      })
    }

    this.attachSubscriptions(
      interval(1000).pipe(
        filter(() => this.company && this.employees && this.employees.length > 0)
      ).subscribe(() => {
        this.employees.filter(x => !!x.workDuration).forEach(x => {
          x.workDuration += 1;
        }) 
      })
    )

    this.onRouteChange()
  }

  changeDate(val) {
    console.log('changeDate val', val)
    console.log('changeDate viewDate', this.viewDate)
    if (val == 'today') {
      this.viewDate = new Date();
    } else if (val == 'prev') {
      if (this.view.value == 'week') {
        this.viewDate = moment(this.viewDate).subtract(1, 'week').toDate();
      } else {
        this.viewDate = moment(this.viewDate).subtract(1, 'month').toDate();
      }
    } else if (val == 'next') {
      if (this.view.value == 'week') {
        this.viewDate = moment(this.viewDate).add(1, 'week').toDate();
      } else {
        this.viewDate = moment(this.viewDate).add(1, 'month').toDate();
      }
    }
    console.log('changeDate viewDate AFTER', this.viewDate);
    
    const getStart: any = {
      month: startOfMonth,
      week: startOfWeek,
      day: startOfDay,
    }[this.view.value];

    const getEnd: any = {
      month: endOfMonth,
      week: endOfWeek,
      day: endOfDay,
    }[this.view.value];
    console.log("change viewDate", getStart(this.viewDate), getEnd(this.viewDate));
    

    if (this.view.value == "week") {
      this.filterSchedule.patchValue({
        from_day : moment(moment(startOfWeek(this.viewDate, {weekStartsOn: 1})).unix() * 1000).utcOffset(0, true),
        to_day : moment(moment(endOfWeek(this.viewDate, {weekStartsOn: 1})).unix() * 1000).utcOffset(0, true),
      })
    } else {
      this.filterSchedule.patchValue({
        from_day : moment(moment(getStart(this.viewDate)).unix() * 1000).utcOffset(0, true),
        to_day : moment(moment(getEnd(this.viewDate)).unix() * 1000).utcOffset(0, true),
      })
    }
      
    // moment
    console.log("change viewDate moment", moment(moment(getStart(this.viewDate)).unix() * 1000).utcOffset(0, true), moment(moment(getEnd(this.viewDate)).unix() * 1000).utcOffset(0, true));

    this.filterSettings()
  }

  setView(view: MatButtonToggleChange) {
    this.view.patchValue(view.value);
    this.page = 1;

    
    const getStart: any = {
      month: startOfMonth,
      week: startOfWeek,
      day: startOfDay,
    }[this.view.value];

    const getEnd: any = {
      month: endOfMonth,
      week: endOfWeek,
      day: endOfDay,
    }[this.view.value];

    if (this.view.value == "week") {
      this.filterSchedule.patchValue({
        from_day : moment(moment(startOfWeek(this.viewDate, {weekStartsOn: 1})).unix() * 1000).utcOffset(0, true),
        to_day : moment(moment(endOfWeek(this.viewDate, {weekStartsOn: 1})).unix() * 1000).utcOffset(0, true),
      })
    } else {
      this.filterSchedule.patchValue({
        from_day : moment(moment(getStart(this.viewDate)).unix() * 1000).utcOffset(0, true),
        to_day : moment(moment(getEnd(this.viewDate)).unix() * 1000).utcOffset(0, true),
      })
    }

    this.filterSettings()
  }

  log() {
    console.log("employees", this.employees)
    console.log("dayViewDays", this.dayViewDays)
    console.log("weekViewDays", this.weekViewDays)
    console.log("monthViewDays", this.monthViewDays)
  }

  // Генерация массива для недельного вида (7 дней)
  private generateWeekViewDays() {
    this.weekViewDays = []
    const start =  moment(startOfWeek(this.viewDate, {weekStartsOn: 1})); // Начало недели
    for (let i = 0; i < 7; i++) {
      const day = start.clone().add(i, 'days');
      this.weekViewDays.push({
        timestamp: day.valueOf(),
        from: day.startOf('d').format("X"),
        to: day.endOf('d').format("X"),
        time: day.format('DD.MM.YYYY dddd')
      });
    }
  }

  getStripPosition(day: any, schedule: any): any {
    const dayStart = +moment.unix(day.from).format('X');
    const dayEnd = +moment.unix(day.to).format('X');
  
    const startOverlap = Math.max(dayStart, schedule.from_time - 3600);
    const endOverlap = Math.min(dayEnd, schedule.to_time - 3600);
  
    // Рассчитываем проценты от ширины контейнера (24 часа = 100%)
    const startPercentage = ((startOverlap - dayStart) / (dayEnd - dayStart)) * 100;
    const widthPercentage = ((endOverlap - startOverlap) / (dayEnd - dayStart)) * 100;
  
    return {
      left: startPercentage, // Сдвиг полоски
      width: widthPercentage // Ширина полоски
    };
  }

  logStrip(schedule) {
    console.log("logStrip(schedule)", schedule)
  }

  // Генерация массива для месячного вида (все дни текущего месяца)
  private generateMonthViewDays() {
    this.monthViewDays = []
    const startOfMonth = moment(this.viewDate).startOf('month'); // Начало месяца
    const daysInMonth = moment(this.viewDate).daysInMonth(); // Количество дней в текущем месяце

    for (let i = 0; i < daysInMonth; i++) {
      const day = startOfMonth.clone().add(i, 'days');
      this.monthViewDays.push({
        timestamp: day.valueOf(),
        from: day.startOf('d').format("X"),
        to: day.endOf('d').format("X"),
        time: day.format('DD.MM.YYYY dddd')
      });
    }
  }

  getUser() {
    this.attachSubscriptions(
      this.auth.$user.subscribe(resp => {
        this.user = resp;
      })
    )
  }

  removeValueFromMultiple(key, value) {
    let arr = this.filter.value[key];

    console.log(key, value);
    console.log(arr);
    
    if (arr.includes(value)) {
      arr.splice(arr.indexOf(value), 1)
    }
    console.log(arr);
    console.log(this.filter.value[key]);
    // return 
    // this.filter.patchValue({
    //   [key]: arr
    // })
    this.filterSettings()
  }

  onRouteChange() {
    this.attachSubscriptions(
      this.activatedRoute.queryParams.pipe(
        tap((val) => {
          
          console.log("getEmployees-onRouteChange")
          if (this.company_id != this.activatedRoute.snapshot.queryParamMap.get('company_id')) {
            this.getGroupsCompany();
            this.getDepartmentsDyn();
            this.getPositionsDyn();
            this.getLangData();
          }
          this.company_id = this.activatedRoute.snapshot.queryParamMap.get('company_id');
          this.filter.patchValue({
            count: this.activatedRoute.snapshot.queryParamMap.get('count') ? this.activatedRoute.snapshot.queryParamMap.get('count') : '/',
            q: !!this.activatedRoute.snapshot.queryParamMap.get('q') ? this.activatedRoute.snapshot.queryParamMap.get('q') : '',
            permission: !!this.activatedRoute.snapshot.queryParamMap.get('permission') ? this.activatedRoute.snapshot.queryParamMap.get('permission') : '',
            department_id: !!this.activatedRoute.snapshot.queryParamMap.get('department_id') ? +this.activatedRoute.snapshot.queryParamMap.get('department_id') : '',
            department_position_id: !!this.activatedRoute.snapshot.queryParamMap.get('department_position_id') ? +this.activatedRoute.snapshot.queryParamMap.get('department_position_id') : '',
            group_id: !!this.activatedRoute.snapshot.queryParamMap.get('group_id') ? +this.activatedRoute.snapshot.queryParamMap.get('group_id') : '',
          })
          console.log("getEmployees-FILTER VALUE", this.filter.value)
        }),
        switchMap((val) => {
          console.log("switchMap", this.company)
          if (this.company == undefined || this.company.id != this.activatedRoute.snapshot.queryParamMap.get('company_id')) {
            return this.companyService.getCompany(this.activatedRoute.snapshot.queryParamMap.get('company_id')).pipe(
              map(x => x[0]),
              tap(x => {
                this.company = x;
                this.companyService.company$.next(x);
              }),
              map(x => val)
            )
          } else {
            return of(val)
          }
        }),
      ).subscribe(params => {
        console.log("getEmployees-Files route STREAM 1", params);
        this.page = 1;
        this.employees = [];

        if (this.emplsDataSub) {
          this.emplsDataSub.unsubscribe()
        }
        this.emplsDataSub = this.taskService.getEmployeesData().pipe(
          concatMap(itemsInPage => {
            return concat(...itemsInPage.map(itemInPage => this.neededEmployeesData(itemInPage))).pipe(last(),map(x => itemsInPage))
          }),
        ).subscribe(resp => console.log("-----getEmployeesDataStream-----",resp));
        console.log("getEmployees-Files route STREAM 2", params);
        this.getEmployees(this.page);
      })
    )
  }

  neededEmployeesData(empl) {
    console.log('neededEmployeesData empl', empl)
    let arr = []

    if (true) {
      let fil = {
        employee_id: empl.id,
        from_day: this.filterSchedule.value.from_day,
        to_day: this.filterSchedule.value.to_day
      }
      arr.push(
        this.companyService.getScheduleItem(this.company_id, '1', '200', fil).pipe(
          map(u => u.body),
          tap((arrValues:any) => {
            let values = arrValues.flat()
            console.log("getScheduleItem arrValues", arrValues)
            console.log("getScheduleItem values", values)
            values.map(val => {
              val.start = new Date((val.from_time) * 1000);
              val.end = new Date((val.to_time) * 1000);
              val.title = val.name;
            });
            empl.schedules = [].concat(...values)
          }),
          // switchMap(el => {
          //   let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          //   let arr = []
          //   for (let index = 1; index <= pages; index++) {
          //     arr.push(index)
          //   }
  
          //   return forkJoin(arr.map(x => this.companyService.getScheduleItem(this.company_id, x, '200', fil).pipe(map(u => u.body)))).pipe(
          //     last(),
          //     tap((arrValues:any) => {
          //       let values = arrValues.flat()
          //       console.log("getScheduleItem arrValues", arrValues)
          //       console.log("getScheduleItem values", values)
          //       values.map(val => {
          //         val.start = new Date((val.from_time) * 1000);
          //         val.end = new Date((val.to_time) * 1000);
          //         val.title = val.name;
          //         // val.allDay = true;
          //         val.cssClass = 'publish_url';
              
          //         val.meta = {
          //           type:'content',
          //           filterBy: 'employee'
          //         }
          //       });
          //       empl.schedules = [].concat(...values)
          //     }),
          //   )
          // }),
        )
        // this.taskService.getEmployeeRules(empl.id, this.company_id).pipe(
        //   tap(res => {
        //     empl.rules = res.filter(x => ['admin', 'manager', 'manage_channel_account', 'see_files', 'create_discussions'].includes(x.permission));
        //   })
        // )
      )
    }

    console.log("neededEmployeesData arr", arr);

    if (arr && arr.length) {
      return forkJoin(arr)
    } else {
      return of([])
    }

  }

  getGroupsCompany() {
    this.attachSubscriptions(
      this.companyService.getInfiniteGroupsCompany(this.company_id, '1', '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.companyService.getInfiniteGroupsCompany(this.company_id, x).pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        this.groups = [].concat(...res);
      })
    )
  }

  getGroupById(id) {
    if (!this.groups || this.groups.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.groups.find(el => el.id == id)
  }
  
  getDepById(id) {
    if (!this.departments || this.departments.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.departments.find(el => el.id == id)
  }

  getPosById(id) {
    if (!this.pos || this.pos.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.pos.find(el => el.id == id)
  }

  getDepartmentsDyn() {
    this.attachSubscriptions(
      this.companyService.getDepartmentsDyn('1', this.company_id, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }
          return forkJoin(arr.map(x => this.companyService.getDepartmentsDyn(x, this.company_id, '200').pipe(map(u => u.body)))).pipe(
            last(),
            tap(values => {
              let conValues = [].concat(...values)
              this.departments = conValues;
            }),
          )
        }),
      ).subscribe(resp => {
        console.log("getDepartmentsDyn resp", resp);
        console.log("getDepartmentsDyn departments", this.departments);
      })
    )
  }

  getPermissions() {
    this.attachSubscriptions(
      this.taskService.getRules().subscribe(resp => {
        this.permissions = resp;
        console.log("getPermissions", this.permissions);
      })
    )
  }

  getPositionsDyn() {
    this.attachSubscriptions(
      this.companyService.getPositionsDyn('1', this.company_id, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }
          return forkJoin(arr.map(x => this.companyService.getPositionsDyn(x, this.company_id, '200').pipe(map(u => u.body)))).pipe(
            last(),
            tap(values => {
              let conValues = [].concat(...values)
              this.pos = conValues;
            }),
          )
        }),
      ).subscribe(resp => {
        console.log("getPositionsDyn resp", resp);
        console.log("getPositionsDyn positions", this.pos);
      })
    )
  }

  removeValue(val) {
    if (val == "all") {
      let params = {
        company_id: this.company_id,
        page: this.activatedRoute.snapshot.queryParamMap.get('page')
      }
      this.router.navigate(['/settings'], {queryParams: params})
    } else {
      let params = {...this.activatedRoute.queryParamMap.source['_value']}
      delete params[val]

      this.router.navigate(['/settings'], {queryParams: params})
    }
  }

  

  filterSettings() {
    let params:any = {
      company_id: this.company_id,
      page: this.activatedRoute.snapshot.queryParamMap.get('page'),
    }

    let filterData = {...this.filter.value};

    console.log("filterData", filterData)
    
    Object.keys(filterData).filter(x => x != 'preset_id').forEach(element => {
      if (['is_content_url', 'part_of_count', 'consist_of_count', 'related_count'].includes(element) && filterData[element] == '0') {
        params[element] = filterData[element]
      } else if (["group_id", "channel_id", "no_channel_id", "operation_employee_id", "operation_partner_company_id", 'task_parameter_value_id', 'parameter_value_id', 'file_parameter_value_id'].includes(element) && filterData[element].length > 0) {
        params[element] = filterData[element].join(',')
      } else if (filterData[element] == '0' && filterData[element] == 0 && (element == 'operation_employee_id' || element == 'operation_partner_company_id')) {
        params[element] = filterData[element]
      } else if (filterData[element] != '0' && filterData[element] != '') {
        params[element] = filterData[element]
      }
    });


    // console.log("filterData.operation_completed_at_to", filterData.operation_completed_at_to)
    if (filterData.operation_completed_at_to === 0) {
      params['operation_completed_at_to'] = '0';
    }
    
    console.log("proj params", params)
    this.generateWeekViewDays();
    this.generateMonthViewDays();
    params.count = Math.floor(Math.random() * 1000) + 1;
    this.router.navigate(['/settings'], {queryParams: params})
  }


  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData().subscribe(resp => {
        this._ngx_adapter.setLocale(resp.active);
        this._adapter.setLocale(resp.active);
        this.activeLang = resp.active;

        this.generateWeekViewDays();
        this.generateMonthViewDays();
      })
    )
  }
  
  userCalendar(empl) {
    const dialogRef = this.dialog.open(UserCalendarComponent, {
      backdropClass: ['back_user_calendar_schedule'],
      panelClass: ['panel_user_calendar_schedule'],
      data: {
        company_id: this.company.id,
        company: this.company,
        imgRoute: this.imgRoute,
        host: this.host,
        employee: empl
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log("getONE BEFORE", result);
      })
    )
  }

  getCompany() {
    this.attachSubscriptions(
      this.companyService.getCompany(this.company_id).subscribe(resp => {
        this.company = resp[0];
        this.companyService.company$.next(resp[0]);
      })
    )
  }

  ngAfterViewInit() {

  }

  getEmployees(page) {
    console.log("getEmployees", page);
    let filter = {...this.filter.value}
    let params:any = {
      is_deleted: this.is_deleted
    }
    Object.keys(filter).forEach(key => {
      console.log("getEmployees-filter[key]", key, filter[key])
      if (!!filter[key]) {
        params[key] = filter[key];
      }
    })

    console.log("getEmployees-params", params)
    this.attachSubscriptions(
      this.taskService.getEmployeesDataDyn(page, this.company_id, params, true).pipe(
        tap(el => {
          this.pagination = {
            'pageCount': el.headers.get('x-pagination-page-count'),
            'perPage': el.headers.get('x-pagination-per-page'),
            'totalCount': el.headers.get('x-pagination-total-count'),
            'currentPage': el.headers.get('x-pagination-current-page'),
          }
        }),
        map(x => x.body),
        tap(res => {
          res.forEach(element => {
            let x;
            if (this.view.value == 'week') {
              x = [...this.weekViewDays];
            } else {
              x = [...this.monthViewDays];
            }
            element.table = x;
          });
          this.taskService.employeesData$.next(res)
        })
      ).subscribe(resp => {

        if (page == 1) {
          this.employees = resp;
        } else {
          this.employees.push(...resp);
        }
        console.log("getEmployees-" + this.page, this.employees)
        this.page++;
        if (this.page <= this.pagination['pageCount']) {
          this.getEmployees(this.page);
        }
      })
    )
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  getCsrf() {
    this.attachSubscriptions(
      this.auth.$userToken.subscribe(resp => {
        this.user_id = resp.user_id;
        this.sm.localStorageSetItem("csrf_param", resp.csrf_param)
        this.sm.localStorageSetItem("csrf_token", resp.csrf_token)
      })
    )
  }

  goBack() {
    history.back()
  }

  ngOnDestroy(): void {
    this.clearSubscriptions();
  }
}
