<h2 mat-dialog-title class="dialog_title">
    <span>
        {{ "Migration" | translate }}: {{ data.selectedFiles.length }} {{"files" | translate}}
    </span>
    <button mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h2>
<mat-dialog-content class="mat-typography" dirEnter (dirSubmit)="!form.valid ? null : addMigration()">
    <div class="container">
        <form [formGroup]="form">
            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ "Storage" | translate }}</mat-label>
                <mat-select disableOptionCentering formControlName="to_storage_code">
                    <mat-option [value]="item.storage_code" [ngClass]="{'cst_active_select_option': form.get('to_storage_code').value == item.storage_code}" *ngFor="let item of storages">
                        {{ item.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </form>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button (click)="close()">
        {{ "Close" | translate }}
    </button>
    <button class="ml-1 d-f-btn" (click)="addMigration()" mat-raised-button cdkFocusInitial color="primary" [disabled]="!form.valid || isSubmit">
        <mat-spinner class="spin_16 mr-1" *ngIf="isSubmit"></mat-spinner>  
        {{ "Migrate" | translate }}
    </button>
</mat-dialog-actions>
