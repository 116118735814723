<div class="form_service">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <h2 (click)="log()">{{ "Now" | translate }} {{data?.company?.live_stream_limit}} {{"Streams" | translate}}</h2>

        <div class="count_line" for="count">
            <label for="count" class="cp">
                {{"New q-ty now" | translate}}:
            </label>
            <div>
                <ng-container *ngIf="data?.nextPay && data?.nextPay?.is_overdue == 1; else elseTemplateis_overdue">
                    <input id="count" style="opacity: .5;" [readonly]="true" formControlName="count" name="count" matInput type="number" placeholder="{{ 'Streams count' | translate }}" (change)="onInput($event, 'count', data?.company?.live_stream_limit)">
                </ng-container>
                <ng-template #elseTemplateis_overdue>
                    <input id="count" formControlName="count" name="count" matInput type="number" placeholder="{{ 'Streams count' | translate }}" (change)="onInput($event, 'count', data?.company?.live_stream_limit)">
                </ng-template>
                
                <span>{{ form.value.count - data?.company?.live_stream_limit >= 0 ? '+' : ''}} {{form.value.count - data?.company?.live_stream_limit}} {{'streams' | translate}}</span> 
            </div>
            <div style="margin-left: 5px;" *ngIf="result && result.product_price" class="result_price">
                ${{result.product_price/(result.live_stream_limit/result.product_count)}} / {{'stream' | translate}}
            </div>
        </div>
        <div class="next_plan" style="color: rgb(222, 87, 87); margin-bottom: 20px;" *ngIf="data?.nextPay && data?.nextPay?.is_overdue == 1">
            {{"You can't buy streams now, you haven't paid for your planned plan" | translate}}
        </div>
        <div class="count_line" for="next">
            <label for="next" class="cp">
                {{"New q-ty next month" | translate}}:
            </label>
            <div>
                <input id="next" formControlName="planned_live_stream_limit" name="next" matInput type="number" placeholder="{{ 'Streams count' | translate }}" (change)="onInput($event, 'planned_live_stream_limit')">
                <span>{{ form.value.planned_live_stream_limit - data?.company?.planned_live_stream_limit >= 0 ? '+' : ''}} {{form.value.planned_live_stream_limit - data?.company?.planned_live_stream_limit}} {{'streams' | translate}}</span>
            </div>
            <div style="margin-left: 5px;" *ngIf="currentPlan" class="result_price">
                ${{currentPlan.price}} / {{'stream' | translate}} <b>( ${{currentPlan.price*form.value.planned_live_stream_limit}} )</b>
            </div>
        </div>

        <div class="next_plan" *ngIf="nextPlan">
            {{"You can buy streams cheaper in quantities from" | translate}} {{nextPlan?.restriction_min_live_stream_limit}}+ {{'streams' | translate}} <b>( ${{nextPlan.price}} / {{'stream' | translate}} )</b>
        </div>
        <!-- <mat-form-field appearance="standard" class="full_width_form">
            <mat-label>{{ 'Streams count' | translate }}</mat-label>
            <input formControlName="count" matInput type="number" placeholder="{{ 'Streams count' | translate }}" (change)="onInput($event, 'count', data?.company?.live_stream_limit)">
        </mat-form-field> -->

        <div *ngIf="!!result">
            <mat-divider style="margin-top: 20px;"></mat-divider>
            <pre>
                {{result|json}}
            </pre>
        </div>
    
        <div class="btns_line">
            <div>
                <b style="font-size: 20px; font-weight: 600; margin-right: 20px;" *ngIf="!!result && result.balance_movement">${{result.balance_movement}} {{"due now" | translate}}</b>
            </div>
            <div class="d-f-c">
                <button mat-raised-button type="button" (click)="goBack()">
                    {{ "Close" | translate }}
                </button>
                <ng-container *ngIf="!!result && calcRes.planned_live_stream_limit == form.value.planned_live_stream_limit && calcRes.count == form.value.count; else elseTemplateResult">
                    <button class="ml-1 d-f-btn" type="button" (click)="confirm()" mat-raised-button color="primary" [disabled]="isSubmit">
                        <mat-spinner class="spin_16 mr-1" *ngIf="isSubmit"></mat-spinner>  
                        {{ "Confirm" | translate }}
                    </button>
                </ng-container>
                <ng-template #elseTemplateResult>           
                    <button class="ml-1 d-f-btn" type="submit" mat-raised-button color="primary" [disabled]="isSubmit">
                        <mat-spinner class="spin_16 mr-1" *ngIf="isSubmit"></mat-spinner>  
                        {{ "Calculate" | translate }}
                    </button>
                </ng-template>
            </div>
        </div>
    </form>
</div>