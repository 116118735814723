import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { el } from 'date-fns/locale';
import { forkJoin, of, ReplaySubject } from 'rxjs';
import { debounceTime, last, map, switchMap, tap } from 'rxjs/operators';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { ScenariosService } from 'src/app/shared/services/rest/scenarios.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';

@Component({
  selector: 'app-multi-jobs-assign-scenario',
  templateUrl: './multi-jobs-assign-scenario.component.html',
  styleUrls: ['./multi-jobs-assign-scenario.component.scss']
})
export class MultiJobsAssignScenarioComponent extends BaseClass implements OnInit, OnDestroy {

  public templates: any;
  public templatesMoreControl: FormControl = new FormControl();
  public templates$: ReplaySubject<any> = new ReplaySubject<any>(1);
  
  public isSubmit: boolean = false;
  public submited: boolean = false;
  
  public form: FormGroup;
  public isFormChange: boolean = false;

  public deleteAll: boolean = false;
  public replaceAll: boolean = false;
  public assignScenarios: boolean = false;
  public deleteDuplicates: boolean = false;

  constructor(
    private taskService: TaskService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<MultiJobsAssignScenarioComponent>,
    private fb: FormBuilder,
    private sm: StorageManagerService,
    private scenariosService: ScenariosService,
    private layoutService: LayoutService
  ) { super() }

  ngOnInit(): void {
    console.log("MultiJobsAssignScenarioComponent", this.data)
    
    this.form = this.fb.group({
      template_id: [[], Validators.required],
    })

    this.attachSubscriptions(
      this.form.valueChanges.subscribe(() => this.isFormChange = true)
    )
    
    this.attachSubscriptions(
      this.form.get('template_id').valueChanges.subscribe((val) => {
        console.log(val);
        if (val.length) {
          this.assignScenarios = true;
        } else {
          this.assignScenarios = false;
        }
      })
    )
    
    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        if (this.isFormChange) {
          this.layoutService.openBottomSheet(this.dialogRef);
        } else {
          this.close();
        }
      })
    )
    this.getAutomationTemplates();
    this.attachSubscriptions(
      this.templatesMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchTemplates(resp))
    )
  }

  getAutomationTemplates() {
    this.attachSubscriptions(
      this.scenariosService.getAutomationTemplates('1', {company_id: this.data.company.id, is_task_operation: 1}, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.scenariosService.getAutomationTemplates(x, {company_id: this.data.company.id, is_task_operation: 1}, '200').pipe(map(u => u.body)))).pipe(
            last(),
            tap(values => {
              this.templates = [].concat(...values)
              console.log("getAutomationTemplates", this.templates)
              this.templates$.next(this.templates.slice());
            }),
          )
        }),
      ).subscribe(resp => {
        console.log("getAutomationTemplates sub", resp);
      })
    )
  }
  onSearchTemplates(resp) {
    if (!this.templates) {
      return;
    }

    if (!resp) {
      this.templates$.next(this.templates.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.templates$.next(
      this.templates.filter(z => z.name.toLowerCase().indexOf(resp) > -1)
    );
  }
  
  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  toggleChipTemplate = (chip: any) => {
    if (!!this.form.value.template_id && this.form.value.template_id.includes(chip.id)) {
      let val = [...this.form.value.template_id]
      val.splice(this.form.value.template_id.indexOf(chip.id), 1)
      this.form.patchValue({
        template_id: val
      })
    } else {
      if (!!this.form.value.template_id) {
        let val = [...this.form.value.template_id]
        val.push(chip.id)
        this.form.patchValue({
          template_id: val
        })
      } else {
        this.form.patchValue({
          template_id: [chip.id]
        })
      }
    }
  }

  changeDeleteAll(e) {
    console.log("changeDeleteAll", e)
    if (e.checked) {
      this.deleteDuplicates = false;
      this.replaceAll = false;
    }
  }

  changeDeleteDuplicates(e) {
    console.log("changeDeleteDuplicates", e)
    if (e.checked) {
      this.deleteAll = false;
    }
  }

  changeReplaceAll(e) {
    console.log("changeReplaceAll", e)
  }

  onSubmit() {
    if (!this.deleteAll && !this.deleteDuplicates) {
      if (this.form.value.template_id.length == 0) {
        this.submited = true;
        this.layoutService.showSnackBar({name: ''}, marker("You need to fill in all required fields"), SnackBarItem)
        return
      }
    }
    this.submited = false;
    this.isSubmit = true;

    let assignData = [];
    let deleteData = [];


    this.data.selectedJobs.forEach(job => {

      if (!!this.deleteAll && job.automationScenarios && job.automationScenarios.length) {
        job.automationScenarios.forEach(j_scen => {
          deleteData.push({
            "path": `/api/automation-scenario/${j_scen.id}/`,
            "query": {company_id: this.data.company.id},
            "method": "DELETE",
            "body": {}
          })
        })
      }

      if (!!this.replaceAll && job.automationScenarios && job.automationScenarios.length) {
        job.automationScenarios.forEach(j_scen => {
          if (!!this.form.value.template_id.includes(j_scen.template_id)) {
            deleteData.push({
              "path": `/api/automation-scenario/${j_scen.id}/`,
              "query": {company_id: this.data.company.id},
              "method": "DELETE",
              "body": {}
            })
          }
        })
      }

      if (!!this.deleteDuplicates && job.automationScenarios && job.automationScenarios.length) {
        let ids = [];
        let deletedIds = []
        job.automationScenarios.forEach(el => {
          if (ids.includes(el.template_id)) {
            deletedIds.push(el)
          } else {
            ids.push(el.template_id)
          }
        });

        if (deletedIds.length) {
          deletedIds.forEach(j_scen => {
            deleteData.push({
              "path": `/api/automation-scenario/${j_scen.id}/`,
              "query": {company_id: this.data.company.id},
              "method": "DELETE",
              "body": {}
            })
          })
        }
      }

      this.form.value.template_id.forEach(t_id => {
        assignData.push({
          "path": `/api/automation-scenario/`,
          "query": {company_id: this.data.company.id},
          "method": "POST",
          "body": {
            [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
            company_id: this.data.company.id,
            task_operation_id: job.id,
            template_id: t_id
          }
        })
      });
    });

    console.log(this.form.value);
    console.log("deleteData", deleteData)
    console.log("assignData", assignData)
    
    this.attachSubscriptions(
      this.taskService.multiRequest(deleteData).pipe(
        switchMap(deleted => {
          console.log("deleted", deleted)
          if (!deleted.filter(p => p.status == 500).length) {
            if (assignData && assignData.length) {
              return this.taskService.multiRequest(assignData).pipe(
                switchMap(assigned => {
                  let tryData = []
                  console.log("assigned", assigned)
                  assigned.forEach(ass => {
                    if (ass.body && ass.body.id) {
                      tryData.push({
                        "path": `/api/automation-scenario/execute/${ass.body.id}/`,
                        "query": {company_id: this.data.company.id},
                        "method": "POST",
                        "body": {
                          [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token')
                        }
                      })
                    }
                  })
                  if (tryData.length) {
                    return this.taskService.multiRequest(tryData)
                  } else {
                    return of(assigned)
                  }
                })
              )
            } else {
              return of(true)
            }
          } else {
            this.layoutService.showSnackBar({name: "You can`t proceed with replacement request because your permissions are limited."}, "You are not allowed to delete assigned scenario from job.", SnackBarItem)
            return of(false)
          }
        })
      ).subscribe(resp => {
        console.log("multiRequest", resp)
        this.isSubmit = false;
        if (!!resp) {
          this.dialogRef.close({event: "update", data: resp})
        }
      }, error => {
        this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
        this.isSubmit = true;
      })
    )
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

}
