<app-file-manager
[initLocation]="data.loc"
[task]="data.task"
[work]="data.work"
[company]="data.company"
[tasks]="data.tasks"
[is_global]="data.is_global"
(HideMenu)="close()"
[initData]="{
    'task': data?.task,
    'work': data?.work,
    'company': data?.company
}"></app-file-manager>