<mat-dialog-content class="mat-typography">
    <ng-container *ngIf="initData && initData.is_mob; else elseTemplateISMOBexec">

        <ng-container *ngIf="selectedExec; else elseTemplateselectedExec">
            <div class="selected_out">
                <div class="selected_head">
                    <ng-container *ngIf="selectedExec?.exec?.partnerCompany; else elseTemplateIsPartner">
                        <app-user [class]="'executor black'" [lines]="1" [status_id]="selectedExec?.exec?.partnerCompanyStatus?.status_id" [companyEmployee]="selectedExec?.exec?.partnerCompany" [imgRoute]="imgRoute" [host]="host" [company]="data?.company"></app-user>
                    </ng-container>
                    <ng-template #elseTemplateIsPartner>
                        <app-user [class]="'executor black'" [lines]="1" [status_id]="selectedExec?.exec?.employeeStatus?.status_id" [employee]="selectedExec?.exec?.employee"></app-user>
                    </ng-template>
                </div>

                <div class="selected_body">
                    <ng-container *ngIf="['client user', 'client workspace'].includes(selectedExec?.type); else elseTemplateIsCli">
                        <div class="open_exec_block silver">
                            <div class="title">
                                {{ "Client Permission" | translate }}:
                            </div>
                            <div class="info">
                                <div>{{ "Moderator" | translate }}</div>
                                <mat-checkbox [labelPosition]="'before'" [disabled]="selectedExec.exec.disabled" class="ml-1 mr-1" color="primary" [(ngModel)]="selectedExec.exec.is_principal" (change)="togglePartner($event, selectedExec.exec)">
                                </mat-checkbox>
                            </div>
                        </div>
                    </ng-container>
                    <ng-template #elseTemplateIsCli>
                        <div class="open_exec_block with_bord white">
                            <div class="d-f-c">
                                <div class="date_name">
                                    {{"Added" | translate}}:
                                </div>
                                <div class="create_date">
                                    <ng-container *ngIf="(selectedExec?.exec?.employeeStatus ? selectedExec?.exec?.employeeStatus : selectedExec?.exec?.partnerCompanyStatus)?.created_at; else elseTemplateComplete">
                                        {{ (selectedExec?.exec?.employeeStatus ? selectedExec?.exec?.employeeStatus : selectedExec?.exec?.partnerCompanyStatus)?.created_at*1000 | date:'MMM dd. yyyy':'+0000': data.activeLang | yearPipe }}
                                    </ng-container>
                                    <ng-template #elseTemplateComplete>
                                        &nbsp;
                                    </ng-template>
                                </div>
                            </div>
                            <div class="d-f-c">
                                <div class="date_name">
                                    {{"Updated" | translate}}:
                                </div>
                                <div class="create_date">
                                    <ng-container *ngIf="(selectedExec?.exec?.employeeStatus ? selectedExec?.exec?.employeeStatus : selectedExec?.exec?.partnerCompanyStatus)?.updated_at; else elseTemplateComplete">
                                        {{ (selectedExec?.exec?.employeeStatus ? selectedExec?.exec?.employeeStatus : selectedExec?.exec?.partnerCompanyStatus)?.updated_at*1000 | date:'MMM dd. yyyy':'+0000': data.activeLang | yearPipe }}
                                    </ng-container>
                                    <ng-template #elseTemplateComplete>
                                        &nbsp;
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                        <div class="open_exec_block with_bord white">
                            <div class="d-f-c" style="justify-content: space-between;">
                                <div (click)="changeType(selectedExec.exec)">
                                    <div class="title">{{"Role" | translate}}:</div>
                                    <div style="height: 20px;">
                                        <ng-container *ngIf="!!selectedExec?.exec?.typeIsChanging; else elseTemplateTypeChanging">
                                            <mat-spinner class="spin_16"></mat-spinner>  
                                        </ng-container>
                                        <ng-template #elseTemplateTypeChanging>
                                            <ng-container *ngIf="selectedExec.exec.is_manager > 0; else elseTemplateIsManager">
                                                {{ "Executor" | translate }}
                                            </ng-container>
                                            <ng-template #elseTemplateIsManager>
                                                {{ "Assistant" | translate }}
                                            </ng-template>
                                        </ng-template>
                                        
                                    </div>
                                </div>
                                <div>
                                    <div class="title">&nbsp;</div>
                                    <div>
                                        <div class="price_val_out" (click)="changePriceManager(selectedExec.exec)">
                                            <ng-container *ngIf="!!selectedExec?.exec?.pricePermIsChanging; else elseTemplatePriceChanging">
                                                <mat-spinner class="spin_16"></mat-spinner>  
                                            </ng-container>
                                            <ng-template #elseTemplatePriceChanging>
                                                <div class="price_val_in" [ngClass]="{'green': selectedExec.exec.is_price_manager > 0}">$</div>
                                                <span [ngClass]="{'cant': selectedExec.exec.is_price_manager == 0}">
                                                    {{"See prices" | translate}}                                               
                                                </span>
                                            </ng-template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="open_exec_block silver" (click)="changeCompletedAt(selectedExec?.exec)">
                            <div class="title">
                                {{ "When done" | translate }}:
                            </div>
                            <div class="info">
                                <ng-container *ngIf="selectedExec?.exec?.completed_at; else elseTemplateNoComplDate">
                                    {{ selectedExec?.exec?.completed_at*1000 | date:'MMM dd, yy':'': data.activeLang | yearPipe }}
                                </ng-container>
                                <ng-template #elseTemplateNoComplDate>
                                    &nbsp;
                                </ng-template>
                            </div>
                        </div>

                        <div class="open_exec_block silver" (click)="changePrice(selectedExec?.exec)">
                            <div class="title">
                                {{ "Charges for assignment" | translate }}:
                            </div>
                            <div class="info">
                                <ng-container *ngIf="+selectedExec?.exec.rate > 0; else elseTemplatePrice">
                                    <div class="exec_price">
                                        <span class="unit">
                                            {{ "pts" }}
                                        </span>
                                        <span class="price_numb">
                                            {{ selectedExec?.exec.rate | int }} {{ ((+selectedExec?.exec?.rate - +(selectedExec?.exec?.rate | int)) | reportsNumber).toString()|slice:1 }}
                                        </span>
                                    </div>
                                </ng-container>
                                <ng-template #elseTemplatePrice>
                                    <ng-container *ngIf="+selectedExec?.exec?.price; else elseTemplateCurr">
                                        <div class="exec_price">
                                            <span class="unit">
                                                <ng-container *ngIf="selectedExec?.exec?.currency?.short_name; else elseTemplateHasCur">
                                                    {{selectedExec?.exec?.currency?.short_name}}
                                                </ng-container>
                                                <ng-template #elseTemplateHasCur>     
                                                    <ng-container [ngSwitch]="selectedExec?.exec?.price_currency_id">
                                                        <ng-container *ngSwitchCase="'1'">&#36;</ng-container>
                                                        <ng-container *ngSwitchCase="'2'">&#8381;</ng-container>
                                                        <ng-container *ngSwitchCase="'3'">&#8364;</ng-container>
                                                        <ng-container *ngSwitchCase="'4'">&#8372;</ng-container>
                                                    </ng-container>
                                                </ng-template>  
                                            </span>
                                            <span class="price_numb">
                                                {{ selectedExec?.exec?.price | int }} {{ ((+selectedExec?.exec?.price - +(selectedExec?.exec?.price | int)) | reportsNumber).toString()|slice:1 }}
                                            </span>
                                        </div>
                                    </ng-container>
                                    <ng-template #elseTemplateCurr>
                                        &nbsp;
                                    </ng-template>
                                </ng-template>
                            </div>
                        </div>
                        <div class="open_exec_block silver">
                            <div class="title">
                                {{ "Status" | translate }}:
                            </div>
                            <div class="info">
                                <!-- (dblclick)="jobEmpl.discussion_id == 0 || !jobEmpl.discussion_id ? dblChangeStatus($event, jobEmpl) : dblChangeChatAssStatus($event, jobEmpl, getChatById(jobEmpl.discussion_id))" -->
                                <div class="job_status">
                                    <div class="inside_job" [ngClass]="{
                                        'first': (selectedExec?.exec?.employeeStatus ? selectedExec?.exec?.employeeStatus : selectedExec?.exec?.partnerCompanyStatus)?.status_id == '1',
                                        'second': (selectedExec?.exec?.employeeStatus ? selectedExec?.exec?.employeeStatus : selectedExec?.exec?.partnerCompanyStatus)?.status_id == '2',
                                        'third': (selectedExec?.exec?.employeeStatus ? selectedExec?.exec?.employeeStatus : selectedExec?.exec?.partnerCompanyStatus)?.status_id == '3',
                                        'fours': (selectedExec?.exec?.employeeStatus ? selectedExec?.exec?.employeeStatus : selectedExec?.exec?.partnerCompanyStatus)?.status_id == '4',
                                        'fives': (selectedExec?.exec?.employeeStatus ? selectedExec?.exec?.employeeStatus : selectedExec?.exec?.partnerCompanyStatus)?.status_id == '5',
                                        'sixs': (selectedExec?.exec?.employeeStatus ? selectedExec?.exec?.employeeStatus : selectedExec?.exec?.partnerCompanyStatus)?.status_id == '6',
                                        'nine': (selectedExec?.exec?.employeeStatus ? selectedExec?.exec?.employeeStatus : selectedExec?.exec?.partnerCompanyStatus)?.status_id == '97',
                                        'seven': (selectedExec?.exec?.employeeStatus ? selectedExec?.exec?.employeeStatus : selectedExec?.exec?.partnerCompanyStatus)?.status_id == '98',
                                        'eight': (selectedExec?.exec?.employeeStatus ? selectedExec?.exec?.employeeStatus : selectedExec?.exec?.partnerCompanyStatus)?.status_id == '99'}">
                                        <span>{{ getStatus((selectedExec?.exec?.employeeStatus ? selectedExec?.exec?.employeeStatus : selectedExec?.exec?.partnerCompanyStatus)?.status_id)?.name }}</span>
                                    </div>
    
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </div>

                <div class="selected_chats" *ngIf="selectedExec?.exec?.chats && selectedExec?.exec?.chats.length">
                    <div class="selected_head" style="height: 32px; margin-top: 20px;">{{"Statuses in chats" | translate}}:</div>
                    <div class="selected_body">
                        <div class="chat" *ngFor="let chat of selectedExec?.exec?.chats">
                            <div class="chat_name" (click)="goToChatStatus(chat)">
             
                                {{chat.name}}
                                
                                <!-- <button class="start_at_btn ml-1" *ngIf="!chat?.employeeStatus?.start_at" (click)="$event.stopPropagation(); setStartAt(chat, jobEmpl)" mat-icon-button>
                                    <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.6667 2.00002H10V0.666687H8.66667V2.00002H3.33333V0.666687H2V2.00002H1.33333C0.593333 2.00002 0.00666666 2.60002 0.00666666 3.33335L0 12.6667C0 13.4 0.593333 14 1.33333 14H10.6667C11.4 14 12 13.4 12 12.6667V3.33335C12 2.60002 11.4 2.00002 10.6667 2.00002ZM10.6667 12.6667H1.33333V5.33335H10.6667V12.6667ZM2.66667 6.66669H6V10H2.66667V6.66669Z" fill="#686868"/>
                                    </svg>  
                                </button>
                                <button class="start_at_btn ml-1" *ngIf="!chat?.employeeStatus?.start_at" (click)="setToOrderBoard(chat, jobEmpl)" mat-icon-button>
                                    <mat-icon>dashboard</mat-icon>
                                </button> -->
                            </div>
                            <div class="d-f-c chat_statuses_wrp">
                                <div class="chat_status">
                                    <div class="inside_job" [ngClass]="{
                                        'first': (chat?.employeeStatus ? chat?.employeeStatus : chat?.partnerCompanyStatus)?.status_id == '1',
                                        'second': (chat?.employeeStatus ? chat?.employeeStatus : chat?.partnerCompanyStatus)?.status_id == '2',
                                        'third': (chat?.employeeStatus ? chat?.employeeStatus : chat?.partnerCompanyStatus)?.status_id == '3',
                                        'fours': (chat?.employeeStatus ? chat?.employeeStatus : chat?.partnerCompanyStatus)?.status_id == '4',
                                        'fives': (chat?.employeeStatus ? chat?.employeeStatus : chat?.partnerCompanyStatus)?.status_id == '5',
                                        'sixs': (chat?.employeeStatus ? chat?.employeeStatus : chat?.partnerCompanyStatus)?.status_id == '6',
                                        'nine': (chat?.employeeStatus ? chat?.employeeStatus : chat?.partnerCompanyStatus)?.status_id == '97',
                                        'seven': (chat?.employeeStatus ? chat?.employeeStatus : chat?.partnerCompanyStatus)?.status_id == '98',
                                        'eight': (chat?.employeeStatus ? chat?.employeeStatus : chat?.partnerCompanyStatus)?.status_id == '99'}">
                                        <span>{{ getStatus((chat?.employeeStatus ? chat?.employeeStatus : chat?.partnerCompanyStatus)?.status_id)?.name }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-template #elseTemplateselectedExec>            
            <div class="white_exec">
                <ng-container *ngIf="data.job.employees && jobHasUserExec()">
                    <div class="white_title">
                        <div class="exec_title">
                            {{"Executors users"|translate}}
                        </div>
                    </div>
                    <div class="executors">
                        <ng-container *ngFor="let jobEmpl of data.job.employees; let i = index;">
                            <div class="exec" *ngIf="!jobEmpl.is_partner && !jobEmpl.is_partner_employee && (jobEmpl.discussion_id == 0 || !jobEmpl.discussion_id)">
                                <app-user (click)="activeUser(jobEmpl, 'executor user', i)" [class]="'executor black'" [lines]="2" [status_id]="jobEmpl?.employeeStatus?.status_id" [employee]="jobEmpl?.employee"></app-user>
        
                                <div class="d-f-c">
                                    <div class="exec_price_out">
                                        <ng-container *ngIf="+jobEmpl.rate > 0; else elseTemplatePrice">
                                            <div class="exec_price">
                                                <span class="unit">
                                                    {{ "pts" }}
                                                </span>
                                                <span class="price_numb">
                                                    {{ jobEmpl.rate | int }} {{ ((+jobEmpl?.rate - +(jobEmpl?.rate | int)) | reportsNumber).toString()|slice:1 }}
                                                </span>
                                            </div>
                                        </ng-container>
                                        <ng-template #elseTemplatePrice>
                                            <ng-container *ngIf="+jobEmpl?.price; else elseTemplateCurr">
                                                <div class="exec_price">
                                                    <span class="unit">
                                                        {{ jobEmpl?.currency?.short_name }}
                                                    </span>
                                                    <span class="price_numb">
                                                        {{ jobEmpl?.price | int }} {{ ((+jobEmpl?.price - +(jobEmpl?.price | int)) | reportsNumber).toString()|slice:1 }}
                                                    </span>
                                                </div>
                                            </ng-container>
                                            <ng-template #elseTemplateCurr>
                                                &nbsp;
                                            </ng-template>
                                        </ng-template>
                                    </div>
                                    <div class="date_out">
                                        <div class="date">
                                            <ng-container *ngIf="jobEmpl?.completed_at; else elseTemplateNoComplDate">
                                                {{ jobEmpl?.completed_at*1000 | date:'MMM dd, yy':'': data.activeLang | yearPipe }}
                                            </ng-container>
                                            <ng-template #elseTemplateNoComplDate>
                                                <b>{{"When done?" | translate}}</b>
                                            </ng-template>
                                        </div>
                                    </div>
                                    <div class="type_out">
                                        <div class="exec_type">
                                            <span (click)="changeType(jobEmpl)">
                                                <ng-container *ngIf="!!jobEmpl?.typeIsChanging; else elseTemplateTypeChang">
                                                    <mat-spinner class="spin_16"></mat-spinner>  
                                                </ng-container>
                                                <ng-template #elseTemplateTypeChang>
                                                    <ng-container *ngIf="jobEmpl.is_manager > 0; else elseTemplateIsManager">
                                                        {{ "Exec" | translate }}
                                                    </ng-container>
                                                    <ng-template #elseTemplateIsManager>
                                                        {{ "Assist" | translate }}
                                                    </ng-template>
                                                </ng-template>
                                            </span>
        
                                            <div class="price_val" (click)="changePriceManager(jobEmpl)" [ngClass]="{'green': jobEmpl.is_price_manager > 0}">
                                                <ng-container *ngIf="!!jobEmpl?.pricePermIsChanging; else elseTemplatePriceChang">
                                                    <mat-spinner class="spin_16"></mat-spinner>  
                                                </ng-container>
                                                <ng-template #elseTemplatePriceChang>
                                                    $
                                                </ng-template>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <!-- <mat-icon class="mob_delete_executor" *ngIf="!!jobEmpl.activate" (click)="deleteEmployee(jobEmpl, i)">close</mat-icon> -->
                            </div>
                        </ng-container>
                    </div>
                </ng-container>
                
                <ng-container *ngIf="jobHasChatExec() || jobHasChatPartn()">
                    <div class="white_title">
                        <div class="exec_title">
                            {{"Chat executors"|translate}}
                        </div>
                    </div>
                    <div class="executors">
                        <ng-container *ngFor="let jobEmpl of data.job.employees; let i = index;">
                            <div class="exec" *ngIf="!jobEmpl.is_partner && !jobEmpl.is_partner_employee && !(jobEmpl.discussion_id == 0 || !jobEmpl.discussion_id)">
                                <app-user (click)="activeUser(jobEmpl, 'chat user', i)" [class]="'executor black'" [lines]="2" [status_id]="jobEmpl?.employeeStatus?.status_id" [employee]="jobEmpl?.employee"></app-user>
        
                                <div class="d-f-c">
                                    <div class="exec_price_out">
                                        <ng-container *ngIf="+jobEmpl.rate > 0; else elseTemplatePrice">
                                            <div class="exec_price">
                                                <span class="unit">
                                                    {{ "pts" }}
                                                </span>
                                                <span class="price_numb">
                                                    {{ jobEmpl.rate | int }} {{ ((+jobEmpl?.rate - +(jobEmpl?.rate | int)) | reportsNumber).toString()|slice:1 }}
                                                </span>
                                            </div>
                                        </ng-container>
                                        <ng-template #elseTemplatePrice>
                                            <ng-container *ngIf="+jobEmpl?.price; else elseTemplateCurr">
                                                <div class="exec_price">
                                                    <span class="unit">
                                                        {{ jobEmpl?.currency?.short_name }}
                                                    </span>
                                                    <span class="price_numb">
                                                        {{ jobEmpl?.price | int }} {{ ((+jobEmpl?.price - +(jobEmpl?.price | int)) | reportsNumber).toString()|slice:1 }}
                                                    </span>
                                                </div>
                                            </ng-container>
                                            <ng-template #elseTemplateCurr>
                                                &nbsp;
                                            </ng-template>
                                        </ng-template>
                                    </div>
                                    <div class="date_out">
                                        <div class="date">
                                            <ng-container *ngIf="jobEmpl?.completed_at; else elseTemplateNoComplDate">
                                                {{ jobEmpl?.completed_at*1000 | date:'MMM dd, yy':'': data.activeLang | yearPipe }}
                                            </ng-container>
                                            <ng-template #elseTemplateNoComplDate>
                                                <b>{{"When done?" | translate}}</b>
                                            </ng-template>
                                        </div>
                                    </div>
                                    <div class="type_out">
                                        <div class="exec_type">
                                            <span (click)="changeType(jobEmpl)">
                                                <ng-container *ngIf="!!jobEmpl?.typeIsChanging; else elseTemplateTypeChang">
                                                    <mat-spinner class="spin_16"></mat-spinner>  
                                                </ng-container>
                                                <ng-template #elseTemplateTypeChang>
                                                    <ng-container *ngIf="jobEmpl.is_manager > 0; else elseTemplateIsManager">
                                                        {{ "Exec" | translate }}
                                                    </ng-container>
                                                    <ng-template #elseTemplateIsManager>
                                                        {{ "Assist" | translate }}
                                                    </ng-template>
                                                </ng-template>
                                            </span>
        
                                            <div class="price_val" (click)="changePriceManager(jobEmpl)" [ngClass]="{'green': jobEmpl.is_price_manager > 0}">
                                                <ng-container *ngIf="!!jobEmpl?.pricePermIsChanging; else elseTemplatePriceChang">
                                                    <mat-spinner class="spin_16"></mat-spinner>  
                                                </ng-container>
                                                <ng-template #elseTemplatePriceChang>
                                                    $
                                                </ng-template>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <mat-icon class="mob_delete_executor" *ngIf="!!jobEmpl.activate" (click)="deleteEmployee(jobEmpl, i)">close</mat-icon> -->
                            </div>
                        </ng-container>
                        <ng-container *ngFor="let partner of data.job.partnerCompanies; let i = index;">
                            <div class="exec" *ngIf="!(partner.discussion_id == 0 || !partner.discussion_id)">
                                <app-user (click)="activeUser(partner, 'chat workspace', i)" [class]="'executor black'" [lines]="2" [status_id]="partner?.partnerCompanyStatus?.status_id" [companyEmployee]="partner?.partnerCompany" [imgRoute]="imgRoute" [host]="host" [company]="data?.company"></app-user>
                            
                                <div class="d-f-c">
                                    <div class="exec_price_out">
                                        <ng-container *ngIf="+partner.rate > 0; else elseTemplatePrice">
                                            <div class="exec_price">
                                                <span class="unit">
                                                    {{ "pts" }}
                                                </span>
                                                <span class="price_numb">
                                                    {{ partner.rate | int }} {{ ((+partner?.rate - +(partner?.rate | int)) | reportsNumber).toString()|slice:1 }}
                                                </span>
                                            </div>
                                        </ng-container>
                                        <ng-template #elseTemplatePrice>
                                            <ng-container *ngIf="+partner?.price; else elseTemplateCurr">
                                                <div class="exec_price">
                                                    <span class="unit">
                                                        {{ partner?.currency?.short_name }}
                                                    </span>
                                                    <span class="price_numb">
                                                        {{ partner?.price | int }} {{ ((+partner?.price - +(partner?.price | int)) | reportsNumber).toString()|slice:1 }}
                                                    </span>
                                                </div>
                                            </ng-container>
                                            <ng-template #elseTemplateCurr>
                                                &nbsp;
                                            </ng-template>
                                        </ng-template>
                                    </div>
                                    <div class="date_out">
                                        <div class="date">
                                            <ng-container *ngIf="partner?.completed_at; else elseTemplateNoComplDate">
                                                {{ partner?.completed_at*1000 | date:'MMM dd, yy':'': data.activeLang | yearPipe }}
                                            </ng-container>
                                            <ng-template #elseTemplateNoComplDate>
                                                <b>{{"When done?" | translate}}</b>
                                            </ng-template>
                                        </div>
                                    </div>
                                    <div class="type_out">
                                        <div class="exec_type">
                                            <span (click)="changeType(partner)">
                                                <ng-container *ngIf="!!partner?.typeIsChanging; else elseTemplateTypeChang">
                                                    <mat-spinner class="spin_16"></mat-spinner>  
                                                </ng-container>
                                                <ng-template #elseTemplateTypeChang>
                                                    <ng-container *ngIf="partner.is_manager > 0; else elseTemplateIsManager">
                                                        {{ "Exec" | translate }}
                                                    </ng-container>
                                                    <ng-template #elseTemplateIsManager>
                                                        {{ "Assist" | translate }}
                                                    </ng-template>
                                                </ng-template>
                                            </span>
        
                                            <div class="price_val" (click)="changePriceManager(partner)" [ngClass]="{'green': partner.is_price_manager > 0}">
                                                <ng-container *ngIf="!!partner?.pricePermIsChanging; else elseTemplatePriceChang">
                                                    <mat-spinner class="spin_16"></mat-spinner>  
                                                </ng-container>
                                                <ng-template #elseTemplatePriceChang>
                                                    $
                                                </ng-template>
                                            </div>
                                        </div>
                                    </div>
              
                                </div>
                                <!-- <mat-icon class="mob_delete_executor" *ngIf="!!partner.activate" (click)="deletePartner(partner, i)">close</mat-icon> -->
                            </div>
                        </ng-container>
                    </div>
                </ng-container>
    
                <ng-container *ngIf="!!data.job.partnerCompanies.length && jobHasNotChatPartn()">
                    <div class="white_title">
                        <div class="exec_title">
                            {{"Executors workspaces"|translate}}
                        </div>
                    </div>
                    <div class="executors">
                        <ng-container *ngFor="let partner of data.job.partnerCompanies; let i = index;">
                            <div class="exec" *ngIf="partner.discussion_id == 0 || !partner.discussion_id">
                                <app-user (click)="activeUser(partner, 'executor workspace', i)" [class]="'executor black'" [lines]="2" [status_id]="partner?.partnerCompanyStatus?.status_id" [companyEmployee]="partner?.partnerCompany" [imgRoute]="imgRoute" [host]="host" [company]="data?.company"></app-user>
                            
                                <div class="d-f-c">
                                    <div class="exec_price_out">
                                        <ng-container *ngIf="+partner.rate > 0; else elseTemplatePrice">
                                            <div class="exec_price">
                                                <span class="unit">
                                                    {{ "pts" }}
                                                </span>
                                                <span class="price_numb">
                                                    {{ partner.rate | int }} {{ ((+partner?.rate - +(partner?.rate | int)) | reportsNumber).toString()|slice:1 }}
                                                </span>
                                            </div>
                                        </ng-container>
                                        <ng-template #elseTemplatePrice>
                                            <ng-container *ngIf="+partner?.price; else elseTemplateCurr">
                                                <div class="exec_price">
                                                    <span class="unit">
                                                        {{ partner?.currency?.short_name }}
                                                    </span>
                                                    <span class="price_numb">
                                                        {{ partner?.price | int }} {{ ((+partner?.price - +(partner?.price | int)) | reportsNumber).toString()|slice:1 }}
                                                    </span>
                                                </div>
                                            </ng-container>
                                            <ng-template #elseTemplateCurr>
                                                &nbsp;
                                            </ng-template>
                                        </ng-template>
                                    </div>
                                    <div class="date_out">
                                        <div class="date">
                                            <ng-container *ngIf="partner?.completed_at; else elseTemplateNoComplDate">
                                                {{ partner?.completed_at*1000 | date:'MMM dd, yy':'': data.activeLang | yearPipe }}
                                            </ng-container>
                                            <ng-template #elseTemplateNoComplDate>
                                                <b>{{"When done?" | translate}}</b>
                                            </ng-template>
                                        </div>
                                    </div>
                                    <div class="type_out">
                                        <div class="exec_type">
                                            <span (click)="changeType(partner)">
                                                <ng-container *ngIf="!!partner?.typeIsChanging; else elseTemplateTypeChang">
                                                    <mat-spinner class="spin_16"></mat-spinner>  
                                                </ng-container>
                                                <ng-template #elseTemplateTypeChang>
                                                    <ng-container *ngIf="partner.is_manager > 0; else elseTemplateIsManager">
                                                        {{ "Exec" | translate }}
                                                    </ng-container>
                                                    <ng-template #elseTemplateIsManager>
                                                        {{ "Assist" | translate }}
                                                    </ng-template>
                                                </ng-template>
                                            </span>
        
                                            <div class="price_val" (click)="changePriceManager(partner)" [ngClass]="{'green': partner.is_price_manager > 0}">
                                                <ng-container *ngIf="!!partner?.pricePermIsChanging; else elseTemplatePriceChang">
                                                    <mat-spinner class="spin_16"></mat-spinner>  
                                                </ng-container>
                                                <ng-template #elseTemplatePriceChang>
                                                    $
                                                </ng-template>
                                            </div>
                                        </div>
                                    </div>
              
                                </div>
                                <!-- <mat-icon class="mob_delete_executor" *ngIf="!!partner.activate" (click)="deletePartner(partner, i)">close</mat-icon> -->
                            </div>
                        </ng-container>
                    </div>
                </ng-container>
                
            </div>
    
            <div class="white_exec" *ngIf="!!clients.length">
                <div class="white_title">
                    <div class="exec_title">
                        {{"Clients users"|translate}}
                    </div>
                </div>
    
                <div class="executors">
                    <ng-container *ngFor="let client of clients; let i = index;">
                        <div class="exec" *ngIf="!(client.client_partner_company_id && !client.employee)">
                            <app-user (click)="activeUser(client, 'client user', i)" [class]="'executor black'" [lines]="2" [employee]="client?.employee"></app-user>
    
                            <div class="d-f-c">
                                <div class="cli_type_out">
                                    <div class="cli_exec_type">
                                        <span>
                                            <ng-container *ngIf="!!client.discussion_id; else elseTemplateChatCli">
                                                {{ 'Chat client' | translate }}
                                            </ng-container>
                                            <ng-template #elseTemplateChatCli>      
                                                <ng-container *ngIf="client.task_operation_id; else elseTemplateWorkCli">
                                                    {{ 'Job client' | translate }}
                                                </ng-container>
                                                <ng-template #elseTemplateWorkCli>
                                                    {{ 'Card client' | translate }}
                                                </ng-template>
                                            </ng-template>
                                        </span>
                                    </div>
                                </div>
                                <div class="checkbox_clients_col">
                                    <mat-checkbox [labelPosition]="'before'" [disabled]="client.disabled" class="ml-1 mr-1" color="primary" [(ngModel)]="client.is_principal" (change)="togglePartner($event, client)">
                                        {{ "Moderator" | translate }}
                                    </mat-checkbox>
                                </div>
                            </div>
                            <!-- <mat-icon class="mob_delete_executor" *ngIf="!!client.activate" (click)="deleteClient(client, i)">close</mat-icon> -->
                        </div>
                    </ng-container>
                </div>
    
                <div class="white_title">
                    <div class="exec_title">
                        {{"Clients workspaces"|translate}}
                    </div>
                </div>
                <div class="executors">
                    <ng-container *ngFor="let client of clients; let i = index;">
                        <div class="exec" *ngIf="client.client_partner_company_id && !client.employee">
                            <app-user (click)="activeUser(client, 'client workspace', i)" [class]="'executor black'" [lines]="2" [companyEmployee]="client?.partnerCompany" [imgRoute]="imgRoute" [host]="host" [company]="data?.company"></app-user>
                            <div class="d-f-c">
                                <div class="cli_type_out">
                                    <div class="cli_exec_type">
                                        <span>
                                            <ng-container *ngIf="!!client.discussion_id; else elseTemplateChatCli">
                                                {{ 'Chat client' | translate }}
                                            </ng-container>
                                            <ng-template #elseTemplateChatCli>      
                                                <ng-container *ngIf="client.task_operation_id; else elseTemplateWorkCli">
                                                    {{ 'Job client' | translate }}
                                                </ng-container>
                                                <ng-template #elseTemplateWorkCli>
                                                    {{ 'Card client' | translate }}
                                                </ng-template>
                                            </ng-template>
                                        </span>
                                    </div>
                                </div>
    
                                <div class="checkbox_clients_col">
                                    <mat-checkbox [labelPosition]="'before'" [disabled]="client.disabled" class="ml-1 mr-1" color="primary" [(ngModel)]="client.is_principal" (change)="togglePartner($event, client)">
                                        {{ "Moderator" | translate }}
                                    </mat-checkbox>
                                </div>
                            </div>
                            <!-- <mat-icon class="mob_delete_executor" *ngIf="!!client.activate" (click)="deleteClient(client, i)">close</mat-icon> -->
                        </div>
                    </ng-container>
                </div>
                
            </div>
        </ng-template> 
    </ng-container>
    <ng-template #elseTemplateISMOBexec>
        <div class="container">
            <h2 class="dialog_title">
                <div class="d-f-c">
                    <span (click)="clData()">
                        {{ "Job executors as individuals" | translate }}
                    </span>
            
                    <button class="ml-1 custom_btn color_btn" type="button" (click)="addExecutor(data.job)">
                        + {{ "Assign executor" | translate }}
                    </button>
                </div>
                <button mat-icon-button (click)="close()" *ngIf="!initData">
                    <mat-icon>close</mat-icon>
                </button>
            </h2>
            <div class="executors_wrp" [ngClass]="{'use_out': initData}">
                <div class="jobs_wrp">
                    <ng-container *ngFor="let jobEmpl of data.job.employees; let i = index;">
                        <ng-container *ngIf="!jobEmpl.is_partner && !jobEmpl.is_partner_employee">
                            <div class="job" [ngClass]="{'no_p_stat': jobEmpl.discussion_id == 0 ? (!jobEmpl?.employeeStatus) : (isEndChatAss && !jobEmpl?.employeeStatus)}">
                                <button class="chats_toggler" *ngIf="jobEmpl.discussion_id == 0 && !data.chat" (click)="toggleChats(jobEmpl)" mat-icon-button>
                                    <ng-container *ngIf="jobEmpl.opened; else elseTemplateChatsIsOpen">
                                        <mat-icon>remove</mat-icon>
                                    </ng-container>
                                    <ng-template #elseTemplateChatsIsOpen>
                                        <mat-icon>add</mat-icon>
                                    </ng-template>
                                </button>
            
                                <div class="job_info">
                                    <div class="empl_info" (dblclick)="dblChangeExecutor($event, jobEmpl)">
                                        
                                        <app-user [class]="'user_div'" [employee]="jobEmpl?.employee"></app-user>
    
                                        <button class="start_at_btn" *ngIf="!jobEmpl?.employeeStatus?.start_at" (click)="setStartAt(jobEmpl)" mat-icon-button>
                                            <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10.6667 2.00002H10V0.666687H8.66667V2.00002H3.33333V0.666687H2V2.00002H1.33333C0.593333 2.00002 0.00666666 2.60002 0.00666666 3.33335L0 12.6667C0 13.4 0.593333 14 1.33333 14H10.6667C11.4 14 12 13.4 12 12.6667V3.33335C12 2.60002 11.4 2.00002 10.6667 2.00002ZM10.6667 12.6667H1.33333V5.33335H10.6667V12.6667ZM2.66667 6.66669H6V10H2.66667V6.66669Z" fill="#686868"/>
                                            </svg>  
                                        </button>
                                        <button class="start_at_btn" *ngIf="!jobEmpl?.employeeStatus?.start_at" (click)="setToOrderBoard(jobEmpl)" mat-icon-button>
                                            <mat-icon>dashboard</mat-icon>
                                        </button>
                                    </div>
                                    <div class="job_dates">
                                        <div class="d-f-c">
                                            <div class="create_date" #tooltip="matTooltip" 
                                            matTooltip="{{'Assignment added' | translate}}"
                                            [matTooltipPosition]="'above'">
                                                {{ jobEmpl?.employeeStatus?.created_at*1000 | date:'MMM dd. yyyy':'': data.activeLang | yearPipe }}
                                            </div>
                                            <div class="create_date ml-1" *ngIf="jobEmpl?.completed_at" #tooltip="matTooltip" 
                                            matTooltip="{{'Assignment done' | translate}}"
                                            [matTooltipPosition]="'above'">
                                                <b>
                                                    {{ jobEmpl?.completed_at*1000 | date:'MMM dd. yyyy':'': data.activeLang | yearPipe }}
                                                </b>
                                            </div>
                                            <div class="job_id">
                                                {{ jobEmpl?.employeeStatus?.id }}
                                            </div>
                                        </div>
                
                                        <div class="update_date" #tooltip="matTooltip" 
                                        matTooltip="{{'Assignment updated' | translate}}"
                                        [matTooltipPosition]="'above'">
                                            <ng-container *ngIf="jobEmpl?.employeeStatus?.updated_at; else elseTemplateComplete">
                                                {{ jobEmpl?.employeeStatus?.updated_at*1000 | date:'MMM dd. yyyy':'+0000': data.activeLang | yearPipe }}
                                            </ng-container>
                                            <ng-template #elseTemplateComplete>
                                                &nbsp;
                                            </ng-template>
                                        </div>
    
                                        <div class="start_at" (click)="!!jobEmpl?.employeeStatus?.start_at ? setStartAt(jobEmpl) : null;">
                                            <ng-container *ngIf="!!jobEmpl?.employeeStatus?.start_at; else elseTemplateStart_at">
                                                <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M10.6667 2.00002H10V0.666687H8.66667V2.00002H3.33333V0.666687H2V2.00002H1.33333C0.593333 2.00002 0.00666666 2.60002 0.00666666 3.33335L0 12.6667C0 13.4 0.593333 14 1.33333 14H10.6667C11.4 14 12 13.4 12 12.6667V3.33335C12 2.60002 11.4 2.00002 10.6667 2.00002ZM10.6667 12.6667H1.33333V5.33335H10.6667V12.6667ZM2.66667 6.66669H6V10H2.66667V6.66669Z" fill="#686868"/>
                                                </svg> 
                                                <ng-container *ngIf="jobEmpl?.employeeStatus?.start_at > 1">
                                                    {{ jobEmpl?.employeeStatus?.start_at*1000 | date:'MMM dd. yyyy':'': data.activeLang | yearPipe }}
                                                </ng-container>
                                            </ng-container>
                                            <ng-template #elseTemplateStart_at>
                                                &nbsp;
                                            </ng-template>
                                        </div>
                                     
                                    </div>
                                </div>
                              
                                <div class="job_price">
                                    <div class="val" (dblclick)="dblChangePrice($event, jobEmpl)">
                                        <ng-container *ngIf="+jobEmpl.rate > 0; else elseTemplatePrice">
                                            <span class="price_numb">
                                                {{ jobEmpl.rate | int }}
                                            </span>
                                            <span class="dot" *ngIf="+jobEmpl.rate > +(jobEmpl.rate | int)">
                                                {{ ((+jobEmpl?.rate - +(jobEmpl?.rate | int)) | reportsNumber).toString()|slice:1 }}
                                            </span>
                                            <span class="unit">
                                                {{ "PTS" }}
                                            </span>
                                        </ng-container>
                                        <ng-template #elseTemplatePrice>
                                            <ng-container *ngIf="+jobEmpl?.price; else elseTemplateCurr">
                                                {{ jobEmpl?.price | int }}
                                                <span class="dot" *ngIf="+jobEmpl?.price > +(jobEmpl?.price | int)">
                                                    {{ ((+jobEmpl?.price - +(jobEmpl?.price | int)) | reportsNumber).toString()|slice:1 }}
                                                </span>
                                                <span class="unit">
                                                   {{ jobEmpl?.currency?.iso_name }}
                                                </span>
                                            </ng-container>
                                            <ng-template #elseTemplateCurr>
                                                &nbsp;
                                            </ng-template>
                                        </ng-template>
                                    </div>
                                </div>
                                <div class="job_type">
                                    <div (dblclick)="dblChangeType($event, jobEmpl)">
                                        <ng-container *ngIf="jobEmpl.is_manager > 0; else elseTemplateIsManager">
                                            {{ "Executor" | translate }}
                                        </ng-container>
                                        <ng-template #elseTemplateIsManager>
                                            <ng-container *ngIf="jobEmpl.discussion_id == 0 || !jobEmpl.discussion_id; else elseTemplateassistant">
                                                {{ "Assistant" | translate }}
                                            </ng-container>
                                            <ng-template #elseTemplateassistant>
                                                {{ "Chat assistant" | translate }}
                                            </ng-template>
                                        </ng-template>
                                    </div>
                                    
                
                                    <div>
                                        <ng-container *ngIf="jobEmpl.discussion_id == 0 || !jobEmpl.discussion_id; else chatAssistRight">
                                            <mat-checkbox [disabled]="jobEmpl.disabled" class="ml-1 mr-1" color="primary" (change)="togglePriceManager($event, jobEmpl)" [checked]="jobEmpl.is_price_manager > 0">
                                                {{ "See prices" | translate }}
                                            </mat-checkbox>
                                            <mat-checkbox *ngIf="jobEmpl.is_manager > 0" [disabled]="true" class="ml-1 mr-1" color="primary" [checked]="true">
                                                {{ "Manage whole job staus" | translate }}
                                            </mat-checkbox>
                                        </ng-container>
                                        <ng-template #chatAssistRight>
                                            <mat-icon class="ml-1 mr-1" style="color: #2F80ED; cursor: pointer;" (click)="goToChat(jobEmpl)">chat_bubble</mat-icon>
                                        </ng-template>
                                        
                                     
                                    </div>
                                </div>
                                <div class="job_status" (dblclick)="jobEmpl.discussion_id == 0 || !jobEmpl.discussion_id ? dblChangeStatus($event, jobEmpl) : dblChangeChatAssStatus($event, jobEmpl, getChatById(jobEmpl.discussion_id))">
                                    <div class="inside_job" [ngClass]="{
                                        'first': jobEmpl?.employeeStatus?.status_id == '1',
                                        'second': jobEmpl?.employeeStatus?.status_id == '2',
                                        'third': jobEmpl?.employeeStatus?.status_id == '3',
                                        'fours': jobEmpl?.employeeStatus?.status_id == '4',
                                        'fives': jobEmpl?.employeeStatus?.status_id == '5',
                                        'sixs': jobEmpl?.employeeStatus?.status_id == '6',
                                        'nine': jobEmpl?.employeeStatus?.status_id == '97',
                                        'seven': jobEmpl?.employeeStatus?.status_id == '98',
                                        'eight': jobEmpl?.employeeStatus?.status_id == '99'}">
                                        <span>{{ getStatus(jobEmpl?.employeeStatus?.status_id)?.name }}</span>
                                        <div>{{ "Personal" | translate }}</div>
                                    </div>
    
                                </div>
                                <!-- (dblclick)="dblChangeStatus($event, jobEmpl)" -->
    
                                <ng-container *ngIf="jobEmpl.discussion_id == 0 || !jobEmpl.discussion_id; else chatAssistStatus">
                                    <div class="job_status">
                                        <div class="inside_job" [ngClass]="{
                                            'first': data.job.status_id == '1',
                                            'second': data.job.status_id == '2',
                                            'third': data.job.status_id == '3',
                                            'fours': data.job.status_id == '4',
                                            'fives': data.job.status_id == '5',
                                            'sixs': data.job.status_id == '6',
                                            'nine': data.job.status_id == '97',
                                            'seven': data.job.status_id == '98',
                                            'eight': data.job.status_id == '99'}">
                                            <span>{{ data?.job?.status?.name || getStatus(data?.job?.status_id)?.name }}</span>
                                            <div>{{ "Job" | translate }}</div>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-template #chatAssistStatus>
                                    <div class="job_status">
                                        <div class="inside_job" [ngClass]="{
                                            'first': getChatById(jobEmpl.discussion_id)?.status_id == '1',
                                            'second': getChatById(jobEmpl.discussion_id)?.status_id == '2',
                                            'third': getChatById(jobEmpl.discussion_id)?.status_id == '3',
                                            'fours': getChatById(jobEmpl.discussion_id)?.status_id == '4',
                                            'fives': getChatById(jobEmpl.discussion_id)?.status_id == '5',
                                            'sixs': getChatById(jobEmpl.discussion_id)?.status_id == '6',
                                            'nine': getChatById(jobEmpl.discussion_id)?.status_id == '97',
                                            'seven': getChatById(jobEmpl.discussion_id)?.status_id == '98',
                                            'eight': getChatById(jobEmpl.discussion_id)?.status_id == '99'}">
                                            <span>{{ getChatById(jobEmpl.discussion_id)?.status?.name }}</span>
                                            <div>{{ "Chat" | translate }}</div>
                                        </div>
                                    </div>
                                </ng-template>
    
    
                                <!-- <div class="job_start_at">
                                    <div class="start_at">
                                        <mat-icon>schedule</mat-icon>
                                        <span *ngIf="jobEmpl?.employeeStatus?.start_at" #tooltip="matTooltip"
                                        matTooltip="{{ jobEmpl?.employeeStatus?.start_at*1000 | date:'hh:mm a':'': data.activeLang | yearPipe }}"
                                        [matTooltipPosition]="'below'">
                                            {{ jobEmpl?.employeeStatus?.start_at*1000 | date:'MMM dd. yyyy':'': data.activeLang | yearPipe }}
                                        </span>
                                    </div>
                                </div> -->
                
                                <mat-icon class="delete_executor" (click)="deleteEmployee(jobEmpl, i)">delete</mat-icon>
                            </div>
                            <div class="empl_chats" *ngIf="jobEmpl.opened && jobEmpl.discussion_id == 0">
                                <div class="chat" *ngFor="let chat of jobEmpl.chats" [ngClass]="{'no_p_stat': !chat?.employeeStatus}">
                                    <div class="chat_name" (click)="goToChatStatus(chat)">
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M14 0.5H2C1.175 0.5 0.5 1.175 0.5 2V15.5L3.5 12.5H14C14.825 12.5 15.5 11.825 15.5 11V2C15.5 1.175 14.825 0.5 14 0.5Z" fill="#0F3FEB" fill-opacity="0.19"/>
                                        </svg>
                                        {{chat.name}}
                                        
                                        <button class="start_at_btn ml-1" *ngIf="!chat?.employeeStatus?.start_at" (click)="$event.stopPropagation(); setStartAt(chat, jobEmpl)" mat-icon-button>
                                            <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10.6667 2.00002H10V0.666687H8.66667V2.00002H3.33333V0.666687H2V2.00002H1.33333C0.593333 2.00002 0.00666666 2.60002 0.00666666 3.33335L0 12.6667C0 13.4 0.593333 14 1.33333 14H10.6667C11.4 14 12 13.4 12 12.6667V3.33335C12 2.60002 11.4 2.00002 10.6667 2.00002ZM10.6667 12.6667H1.33333V5.33335H10.6667V12.6667ZM2.66667 6.66669H6V10H2.66667V6.66669Z" fill="#686868"/>
                                            </svg>  
                                        </button>
                                        <button class="start_at_btn ml-1" *ngIf="!chat?.employeeStatus?.start_at" (click)="setToOrderBoard(chat, jobEmpl)" mat-icon-button>
                                            <mat-icon>dashboard</mat-icon>
                                        </button>
                                    </div>
                                    <div class="d-f-c chat_statuses_wrp">
                                        <div class="start_at" (click)="!!chat?.employeeStatus?.start_at ? setStartAt(chat, jobEmpl) : null;">
                                            <ng-container *ngIf="!!chat?.employeeStatus?.start_at; else elseTemplateStart_at">
                                                <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M10.6667 2.00002H10V0.666687H8.66667V2.00002H3.33333V0.666687H2V2.00002H1.33333C0.593333 2.00002 0.00666666 2.60002 0.00666666 3.33335L0 12.6667C0 13.4 0.593333 14 1.33333 14H10.6667C11.4 14 12 13.4 12 12.6667V3.33335C12 2.60002 11.4 2.00002 10.6667 2.00002ZM10.6667 12.6667H1.33333V5.33335H10.6667V12.6667ZM2.66667 6.66669H6V10H2.66667V6.66669Z" fill="#686868"/>
                                                </svg>  
                                                <ng-container *ngIf="chat?.employeeStatus?.start_at > 1">
                                                    {{ chat?.employeeStatus?.start_at*1000 | date:'MMM dd. yyyy':'': data.activeLang | yearPipe }}
                                                </ng-container>
                                            </ng-container>
                                            <ng-template #elseTemplateStart_at>
                                                &nbsp;
                                            </ng-template>
                                        </div>
    
                                        <div class="chat_status" (dblclick)="dblChangeChatStatus($event, jobEmpl, chat)">
                                            <div class="inside_job" [ngClass]="{
                                                'first': chat?.employeeStatus?.status_id == '1',
                                                'second': chat?.employeeStatus?.status_id == '2',
                                                'third': chat?.employeeStatus?.status_id == '3',
                                                'fours': chat?.employeeStatus?.status_id == '4',
                                                'fives': chat?.employeeStatus?.status_id == '5',
                                                'sixs': chat?.employeeStatus?.status_id == '6',
                                                'nine': chat?.employeeStatus?.status_id == '97',
                                                'seven': chat?.employeeStatus?.status_id == '98',
                                                'eight': chat?.employeeStatus?.status_id == '99'}">
                                                <span>{{ getStatus(chat?.employeeStatus?.status_id)?.name }}</span>  
                                                <div>{{ "Personal" | translate }}</div>
                                            </div>
                                        </div>
                                        <!-- IF NO STATUS -->
                                        <!-- <div class="chat_status" (dblclick)="dblChangeChatStatus($event, jobEmpl, chat)">
                                            <ng-container *ngIf="!!chat?.employeeStatus; else elseTemplateStatusValue">
                                                <div class="inside_job" [ngClass]="{
                                                    'first': chat?.employeeStatus?.status_id == '1',
                                                    'second': chat?.employeeStatus?.status_id == '2',
                                                    'third': chat?.employeeStatus?.status_id == '3',
                                                    'fours': chat?.employeeStatus?.status_id == '4',
                                                    'fives': chat?.employeeStatus?.status_id == '5',
                                                    'sixs': chat?.employeeStatus?.status_id == '6',
                                                    'seven': chat?.employeeStatus?.status_id == '98',
                                                    'eight': chat?.employeeStatus?.status_id == '99'}">
                                                    <span>{{ chat?.employeeStatus?.status?.name }}</span>  
                                                    <div>{{ "Personal" | translate }}</div>
                                                </div>
                                            </ng-container>
                                            <ng-template #elseTemplateStatusValue>
                                                <ng-container *ngIf="jobEmpl?.employeeStatus; else elseTemplateEmplChatStat">
                                                    <div class="inside_job" [ngClass]="{
                                                        'first': jobEmpl?.employeeStatus?.status_id == '1',
                                                        'second': jobEmpl?.employeeStatus?.status_id == '2',
                                                        'third': jobEmpl?.employeeStatus?.status_id == '3',
                                                        'fours': jobEmpl?.employeeStatus?.status_id == '4',
                                                        'fives': jobEmpl?.employeeStatus?.status_id == '5',
                                                        'sixs': jobEmpl?.employeeStatus?.status_id == '6',
                                                        'seven': jobEmpl?.employeeStatus?.status_id == '98',
                                                        'eight': jobEmpl?.employeeStatus?.status_id == '99'}">
                                                        <span>{{ jobEmpl?.employeeStatus?.status?.name }}</span>  
                                                        <div>{{ "Personal" | translate }}</div>
                                                    </div>
                                                </ng-container>
                                                <ng-template #elseTemplateEmplChatStat>
                                                    
                                                    <div class="inside_job" [ngClass]="{
                                                        'first': chat?.status_id == '1',
                                                        'second': chat?.status_id == '2',
                                                        'third': chat?.status_id == '3',
                                                        'fours': chat?.status_id == '4',
                                                        'fives': chat?.status_id == '5',
                                                        'sixs': chat?.status_id == '6',
                                                        'seven': chat?.status_id == '98',
                                                        'eight': chat?.status_id == '99'}">
                                                        <span>{{ chat?.status?.name }}</span>  
                                                        <div>{{ "Personal" | translate }}</div>
                                                    </div>
                                                </ng-template>
                                                
                                            </ng-template>
                                        </div> -->
                                        <div class="chat_status">
                                            <div class="inside_job" [ngClass]="{
                                                'first': chat?.status_id == '1',
                                                'second': chat?.status_id == '2',
                                                'third': chat?.status_id == '3',
                                                'fours': chat?.status_id == '4',
                                                'fives': chat?.status_id == '5',
                                                'sixs': chat?.status_id == '6',
                                                'nine': chat?.status_id == '97',
                                                'seven': chat?.status_id == '98',
                                                'eight': chat?.status_id == '99'}">
                                                <span>{{ chat?.status?.name }}</span>  
                                                <div>{{ "Chat" | translate }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                    
                </div>
        
                <div class="partner_wrp" *ngIf="!!data.job.partnerCompanies.length">
                    <h2 class="dialog_title">
                        <div class="d-f-c">
                            <span>
                                {{ "Job executors as partners (workspaces)" | translate }}
                            </span>
                        </div>
                        <!-- <button mat-icon-button (click)="close()">
                            <mat-icon>close</mat-icon>
                        </button> -->
                    </h2>
                    <ng-container *ngFor="let partner of data.job.partnerCompanies">
    
                        <div class="jobs_wrp partner">
                            <div class="job">
                                <div class="job_info">
                                    <!-- (dblclick)="dblChangeExecutor($event, data.job.partnerCompany)" -->
                                    <div class="empl_info">
                                        <div class="empl_avatars">
                                            <ng-container *ngIf="!!partner?.partnerCompany?.avatarFile?.thumbnail && !!host; else elseTemplatePartnerAvatar">
                                                <img class="partner_avatar" (error)="onPartnerImgError($event)" [src]="host + partner?.partnerCompany?.avatarFile?.thumbnail + '?company_id=' + data?.company?.id" alt="">
                                            </ng-container>
                                            <ng-template #elseTemplatePartnerAvatar>
                                                <img class="partner_avatar" (error)="onPartnerImgError($event)" src="{{ imgRoute }}assets/img/partner.png">
                                            </ng-template>
                                        </div>
              
                                        <span>{{ partner.partnerCompany.name }}</span>
                                    </div>
                                    <div class="job_dates">
                                        <div class="job_id">
                                            {{ partner.partnerCompany.id }}
                                        </div>
                
                                        <!-- (dblclick)="dblChangeCompletedAt($event, data.job.partnerCompany)" -->
                                        <div class="completed_at" >
                                            <ng-container *ngIf="partner.partnerCompany.completed_at; else elseTemplateComplete">
                                                {{ partner.partnerCompany.completed_at*1000 | date:'MMM dd. yyyy':'': data.activeLang | yearPipe }}
                                            </ng-container>
                                            <ng-template #elseTemplateComplete>
                                                &nbsp;
                                            </ng-template>
                                        </div>
                                      
                                        <div class="create_date">
                                            {{ partner.partnerCompany.created_at*1000 | date:'MMM dd. yyyy':'': data.activeLang | yearPipe }}
                                        </div>
                                    </div>
                                </div>
                              
                                <div class="job_price">
                                    <div class="val" (dblclick)="dblChangePrice($event, partner)">
                                        <ng-container *ngIf="+partner.rate > 0; else elseTemplatePrice">
                                            <span class="price_numb">
                                                {{ partner.rate | int }}
                                            </span>
                                            <span class="dot" *ngIf="+partner.rate > +(partner.rate | int)">
                                                {{ ((+partner?.rate - +(partner?.rate | int)) | reportsNumber).toString()|slice:1 }}
                                            </span>
                                            <span class="unit">
                                                {{ "PTS" }}
                                            </span>
                                        </ng-container>
                                        <ng-template #elseTemplatePrice>
                                            <ng-container *ngIf="+partner?.price; else elseTemplateCurr">
                                                {{ partner?.price | int }}
                                                <span class="dot" *ngIf="+partner?.price > +(partner?.price | int)">
                                                    {{ ((+partner?.price - +(partner?.price | int)) | reportsNumber).toString()|slice:1 }}
                                                </span>
                                                <span class="unit">
                                                   {{ partner?.currency?.iso_name }}
                                                </span>
                                            </ng-container>
                                            <ng-template #elseTemplateCurr>
                                                &nbsp;
                                            </ng-template>
                                        </ng-template>
                                    </div>
                                </div>
                                <div class="job_type">
                                    <div (dblclick)="partner.discussion_id == 0 || !partner.discussion_id ? dblChangeType($event, partner) : null">
                                        <ng-container *ngIf="partner.is_manager > 0; else elseTemplateIsManager">
                                            {{ "Executor" | translate }}
                                        </ng-container>
                                        <ng-template #elseTemplateIsManager>
                                            <ng-container *ngIf="partner.discussion_id == 0 || !partner.discussion_id; else elseTemplateassistantPartner">
                                                {{ "Assistant" | translate }}
                                            </ng-container>
                                            <ng-template #elseTemplateassistantPartner>
                                                {{ "Chat assistant" | translate }}
                                            </ng-template>
                                        </ng-template>
                                    </div>
                                    
                                    <div>
                                        <ng-container *ngIf="partner.discussion_id == 0 || !partner.discussion_id; else chatAssistRightPartner">
                                            <mat-checkbox [disabled]="partner.disabled" (change)="togglePriceManager($event, partner)" class="ml-1 mr-1" color="primary" [checked]="partner.is_price_manager > 0">
                                                {{ "See prices" | translate }}
                                            </mat-checkbox>
                                            <mat-checkbox *ngIf="partner.is_manager > 0" [disabled]="true" class="ml-1 mr-1" color="primary" [checked]="true">
                                                {{ "Manage whole job staus" | translate }}
                                            </mat-checkbox>
                                        </ng-container>
                                        <ng-template #chatAssistRightPartner>
                                            <mat-icon class="ml-1 mr-1" style="color: #2F80ED; cursor: pointer;" (click)="goToChat(partner)">chat_bubble</mat-icon>
                                        </ng-template>
                                        
                                    </div>
                                </div>
                                <!--  (dblclick)="dblChangeStatus($event, partner)" -->
                                <!-- (dblclick)="partner.discussion_id == 0 || !partner.discussion_id ? dblChangeStatus($event, partner) : dblChangeChatAssStatus($event, partner, getChatById(partner.discussion_id))">-->
                                <div class="job_status" (dblclick)="partner.discussion_id == 0 || !partner.discussion_id ? dblChangeStatus($event, partner) : dblChangeChatAssStatus($event, partner, getChatById(partner.discussion_id))">
                                    <div class="inside_job" [ngClass]="{
                                        'first': partner?.partnerCompanyStatus?.status_id == '1',
                                        'second': partner?.partnerCompanyStatus?.status_id == '2',
                                        'third': partner?.partnerCompanyStatus?.status_id == '3',
                                        'fours': partner?.partnerCompanyStatus?.status_id == '4',
                                        'fives': partner?.partnerCompanyStatus?.status_id == '5',
                                        'sixs': partner?.partnerCompanyStatus?.status_id == '6',
                                        'nine': partner?.partnerCompanyStatus?.status_id == '97',
                                        'seven': partner?.partnerCompanyStatus?.status_id == '98',
                                        'eight': partner?.partnerCompanyStatus?.status_id == '99'}">
                                        <span>{{ getStatus(partner?.partnerCompanyStatus?.status_id)?.name }}</span>
                                        <div>{{ "Personal" | translate }}</div>
                                    </div>
    
                                </div>
    
                                <ng-container *ngIf="partner.discussion_id == 0 || !partner.discussion_id; else chatAssistStatusPartner">
                                    <div class="job_status">
                                        <div class="inside_job" [ngClass]="{
                                            'first': data.job.status_id == '1',
                                            'second': data.job.status_id == '2',
                                            'third': data.job.status_id == '3',
                                            'fours': data.job.status_id == '4',
                                            'fives': data.job.status_id == '5',
                                            'sixs': data.job.status_id == '6',
                                            'nine': data.job.status_id == '97',
                                            'seven': data.job.status_id == '98',
                                            'eight': data.job.status_id == '99'}">
                                            <span>{{ data?.job?.status?.name || getStatus(data?.job?.status_id)?.name }}</span>
                                            <div>{{ "Job" | translate }}</div>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-template #chatAssistStatusPartner>
                                    <div class="job_status">
                                        <div class="inside_job" [ngClass]="{
                                            'first': getChatById(partner.discussion_id)?.status_id == '1',
                                            'second': getChatById(partner.discussion_id)?.status_id == '2',
                                            'third': getChatById(partner.discussion_id)?.status_id == '3',
                                            'fours': getChatById(partner.discussion_id)?.status_id == '4',
                                            'fives': getChatById(partner.discussion_id)?.status_id == '5',
                                            'sixs': getChatById(partner.discussion_id)?.status_id == '6',
                                            'nine': getChatById(partner.discussion_id)?.status_id == '97',
                                            'seven': getChatById(partner.discussion_id)?.status_id == '98',
                                            'eight': getChatById(partner.discussion_id)?.status_id == '99'}">
                                            <span>{{ getChatById(partner.discussion_id)?.status?.name }}</span>
                                            <div>{{ "Chat" | translate }}</div>
                                        </div>
                                    </div>
                                </ng-template>
    
                <!-- (click)="deleteEmployee(data.job.partnerCompany, i)" -->
                                <mat-icon (click)="deletePartner(partner, i)" class="delete_executor">delete</mat-icon>
                            </div>
                        </div>
                        <div class="jobs_wrp sub_block">
                            <ng-container *ngFor="let jobEmpl of partner.employees; let i = index;">
                                <div class="job" [ngClass]="{'no_p_stat': jobEmpl.discussion_id == 0 ? (!jobEmpl?.employeeStatus) : (isEndChatAss && !jobEmpl?.employeeStatus)}">
                                    <button class="chats_toggler" *ngIf="jobEmpl.discussion_id == 0 && !data.chat" (click)="toggleChats(jobEmpl)" mat-icon-button>
                                        <ng-container *ngIf="jobEmpl.opened; else elseTemplateChatsIsOpen">
                                            <mat-icon>remove</mat-icon>
                                        </ng-container>
                                        <ng-template #elseTemplateChatsIsOpen>
                                            <mat-icon>add</mat-icon>
                                        </ng-template>
                                    </button>
                
                                    <div class="job_info">
                                        <div class="empl_info" (dblclick)="dblChangeExecutor($event, jobEmpl)">
                                            <app-user [class]="'user_div'" [employee]="jobEmpl?.employee"></app-user>
    
                                            <button class="start_at_btn" *ngIf="!jobEmpl?.employeeStatus?.start_at" (click)="setStartAt(jobEmpl)" mat-icon-button>
                                                <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M10.6667 2.00002H10V0.666687H8.66667V2.00002H3.33333V0.666687H2V2.00002H1.33333C0.593333 2.00002 0.00666666 2.60002 0.00666666 3.33335L0 12.6667C0 13.4 0.593333 14 1.33333 14H10.6667C11.4 14 12 13.4 12 12.6667V3.33335C12 2.60002 11.4 2.00002 10.6667 2.00002ZM10.6667 12.6667H1.33333V5.33335H10.6667V12.6667ZM2.66667 6.66669H6V10H2.66667V6.66669Z" fill="#686868"/>
                                                </svg>  
                                            </button>
                                            <button class="start_at_btn ml-1" *ngIf="!jobEmpl?.employeeStatus?.start_at" (click)="setToOrderBoard(jobEmpl)" mat-icon-button>
                                                <mat-icon>dashboard</mat-icon>
                                            </button>
                                        </div>
                                        <div class="job_dates">
                                            <div class="d-f-c">
                                                <div class="create_date" #tooltip="matTooltip" 
                                                matTooltip="{{'Assignment added' | translate}}"
                                                [matTooltipPosition]="'above'">
                                                    {{ jobEmpl?.employeeStatus?.created_at*1000 | date:'MMM dd. yyyy':'': data.activeLang | yearPipe }}
                                                </div>
                                                <div class="create_date ml-1" *ngIf="jobEmpl?.completed_at" #tooltip="matTooltip" 
                                                matTooltip="{{'Assignment done' | translate}}"
                                                [matTooltipPosition]="'above'">
                                                    <b>
                                                        {{ jobEmpl?.completed_at*1000 | date:'MMM dd. yyyy':'': data.activeLang | yearPipe }}
                                                    </b>
                                                </div>
                                                <div class="job_id">
                                                    {{ jobEmpl?.employeeStatus?.id }}
                                                </div>
                                            </div>
                    
                                            <div class="update_date" #tooltip="matTooltip" 
                                            matTooltip="{{'Assignment updated' | translate}}"
                                            [matTooltipPosition]="'above'">
                                                <ng-container *ngIf="jobEmpl?.employeeStatus?.updated_at; else elseTemplateComplete">
                                                    {{ jobEmpl?.employeeStatus?.updated_at*1000 | date:'MMM dd. yyyy':'+0000': data.activeLang | yearPipe }}
                                                </ng-container>
                                                <ng-template #elseTemplateComplete>
                                                    &nbsp;
                                                </ng-template>
                                            </div>
        
                                            <div class="start_at" (click)="!!jobEmpl?.employeeStatus?.start_at ? setStartAt(jobEmpl) : null;">
                                                <ng-container *ngIf="!!jobEmpl?.employeeStatus?.start_at; else elseTemplateStart_at">
                                                    <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M10.6667 2.00002H10V0.666687H8.66667V2.00002H3.33333V0.666687H2V2.00002H1.33333C0.593333 2.00002 0.00666666 2.60002 0.00666666 3.33335L0 12.6667C0 13.4 0.593333 14 1.33333 14H10.6667C11.4 14 12 13.4 12 12.6667V3.33335C12 2.60002 11.4 2.00002 10.6667 2.00002ZM10.6667 12.6667H1.33333V5.33335H10.6667V12.6667ZM2.66667 6.66669H6V10H2.66667V6.66669Z" fill="#686868"/>
                                                    </svg>  
                                                    <ng-container *ngIf="jobEmpl?.employeeStatus?.start_at > 1">
                                                        {{ jobEmpl?.employeeStatus?.start_at*1000 | date:'MMM dd. yyyy':'': data.activeLang | yearPipe }}
                                                    </ng-container>
                                                </ng-container>
                                                <ng-template #elseTemplateStart_at>
                                                    &nbsp;
                                                </ng-template>
                                            </div>
                                         
                                        </div>
                                    </div>
                                  
                                    <div class="job_price">
                                        <div class="val" (dblclick)="dblChangePrice($event, jobEmpl)">
                                            <ng-container *ngIf="+jobEmpl.rate > 0; else elseTemplatePrice">
                                                <span class="price_numb">
                                                    {{ jobEmpl.rate | int }}
                                                </span>
                                                <span class="dot" *ngIf="+jobEmpl.rate > +(jobEmpl.rate | int)">
                                                    {{ ((+jobEmpl?.rate - +(jobEmpl?.rate | int)) | reportsNumber).toString()|slice:1 }}
                                                </span>
                                                <span class="unit">
                                                    {{ "PTS" }}
                                                </span>
                                            </ng-container>
                                            <ng-template #elseTemplatePrice>
                                                <ng-container *ngIf="+jobEmpl?.price; else elseTemplateCurr">
                                                    {{ jobEmpl?.price | int }}
                                                    <span class="dot" *ngIf="+jobEmpl?.price > +(jobEmpl?.price | int)">
                                                        {{ ((+jobEmpl?.price - +(jobEmpl?.price | int)) | reportsNumber).toString()|slice:1 }}
                                                    </span>
                                                    <span class="unit">
                                                       {{ jobEmpl?.currency?.iso_name }}
                                                    </span>
                                                </ng-container>
                                                <ng-template #elseTemplateCurr>
                                                    &nbsp;
                                                </ng-template>
                                            </ng-template>
                                        </div>
                                    </div>
                                    <div class="job_type">
                                        <div (dblclick)="dblChangeType($event, jobEmpl)">
                                            <ng-container *ngIf="jobEmpl.is_manager > 0; else elseTemplateIsManager">
                                                {{ "Executor" | translate }}
                                            </ng-container>
                                            <ng-template #elseTemplateIsManager>
                                                <ng-container *ngIf="jobEmpl.discussion_id == 0 || !jobEmpl.discussion_id; else elseTemplateassistant">
                                                    {{ "Assistant" | translate }}
                                                </ng-container>
                                                <ng-template #elseTemplateassistant>
                                                    {{ "Chat assistant" | translate }}
                                                </ng-template>
                                            </ng-template>
                                        </div>
                                        
                    
                                        <div>
                                            <ng-container *ngIf="jobEmpl.discussion_id == 0 || !jobEmpl.discussion_id; else chatAssistRight">
                                                <mat-checkbox [disabled]="jobEmpl.disabled" class="ml-1 mr-1" color="primary" (change)="togglePriceManager($event, jobEmpl)" [checked]="jobEmpl.is_price_manager > 0">
                                                    {{ "See prices" | translate }}
                                                </mat-checkbox>
                                                <mat-checkbox *ngIf="jobEmpl.is_manager > 0" [disabled]="true" class="ml-1 mr-1" color="primary" [checked]="true">
                                                    {{ "Manage whole job staus" | translate }}
                                                </mat-checkbox>
                                            </ng-container>
                                            <ng-template #chatAssistRight>
                                                <mat-icon class="ml-1 mr-1" style="color: #2F80ED; cursor: pointer;" (click)="goToChat(jobEmpl)">chat_bubble</mat-icon>
                                            </ng-template>
                                            
                                         
                                        </div>
                                    </div>
                                    <div class="job_status" (dblclick)="jobEmpl.discussion_id == 0 || !jobEmpl.discussion_id ? dblChangeStatus($event, jobEmpl) : dblChangeChatAssStatus($event, jobEmpl, getChatById(jobEmpl.discussion_id))">
                                        <div class="inside_job" [ngClass]="{
                                            'first': jobEmpl?.employeeStatus?.status_id == '1',
                                            'second': jobEmpl?.employeeStatus?.status_id == '2',
                                            'third': jobEmpl?.employeeStatus?.status_id == '3',
                                            'fours': jobEmpl?.employeeStatus?.status_id == '4',
                                            'fives': jobEmpl?.employeeStatus?.status_id == '5',
                                            'sixs': jobEmpl?.employeeStatus?.status_id == '6',
                                            'nine': jobEmpl?.employeeStatus?.status_id == '97',
                                            'seven': jobEmpl?.employeeStatus?.status_id == '98',
                                            'eight': jobEmpl?.employeeStatus?.status_id == '99'}">
                                            <span>{{ getStatus(jobEmpl?.employeeStatus?.status_id)?.name }}</span>
                                            <div>{{ "Personal" | translate }}</div>
                                        </div>
        
                                    </div>
                                    <!-- (dblclick)="dblChangeStatus($event, jobEmpl)" -->
        
                                    <ng-container *ngIf="jobEmpl.discussion_id == 0 || !jobEmpl.discussion_id; else chatAssistStatus">
                                        <div class="job_status">
                                            <div class="inside_job" [ngClass]="{
                                                'first': data.job.status_id == '1',
                                                'second': data.job.status_id == '2',
                                                'third': data.job.status_id == '3',
                                                'fours': data.job.status_id == '4',
                                                'fives': data.job.status_id == '5',
                                                'sixs': data.job.status_id == '6',
                                                'nine': data.job.status_id == '97',
                                                'seven': data.job.status_id == '98',
                                                'eight': data.job.status_id == '99'}">
                                                <span>{{ data?.job?.status?.name || getStatus(data?.job?.status_id)?.name }}</span>
                                                <div>{{ "Job" | translate }}</div>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <ng-template #chatAssistStatus>
                                        <div class="job_status">
                                            <div class="inside_job" [ngClass]="{
                                                'first': getChatById(jobEmpl.discussion_id)?.status_id == '1',
                                                'second': getChatById(jobEmpl.discussion_id)?.status_id == '2',
                                                'third': getChatById(jobEmpl.discussion_id)?.status_id == '3',
                                                'fours': getChatById(jobEmpl.discussion_id)?.status_id == '4',
                                                'fives': getChatById(jobEmpl.discussion_id)?.status_id == '5',
                                                'sixs': getChatById(jobEmpl.discussion_id)?.status_id == '6',
                                                'nine': getChatById(jobEmpl.discussion_id)?.status_id == '97',
                                                'seven': getChatById(jobEmpl.discussion_id)?.status_id == '98',
                                                'eight': getChatById(jobEmpl.discussion_id)?.status_id == '99'}">
                                                <span>{{ getChatById(jobEmpl.discussion_id)?.status?.name }}</span>
                                                <div>{{ "Chat" | translate }}</div>
                                            </div>
                                        </div>
                                    </ng-template>
        
        
                                    <!-- <div class="job_start_at">
                                        <div class="start_at">
                                            <mat-icon>schedule</mat-icon>
                                            <span *ngIf="jobEmpl?.employeeStatus?.start_at" #tooltip="matTooltip"
                                            matTooltip="{{ jobEmpl?.employeeStatus?.start_at*1000 | date:'hh:mm a':'': data.activeLang | yearPipe }}"
                                            [matTooltipPosition]="'below'">
                                                {{ jobEmpl?.employeeStatus?.start_at*1000 | date:'MMM dd. yyyy':'': data.activeLang | yearPipe }}
                                            </span>
                                        </div>
                                    </div> -->
                    
                                    <mat-icon class="delete_executor" (click)="deleteEmployee(jobEmpl, i)">delete</mat-icon>
                                </div>
                                <div class="empl_chats" *ngIf="jobEmpl.opened && jobEmpl.discussion_id == 0">
                                    <div class="chat" *ngFor="let chat of jobEmpl.chats" [ngClass]="{'no_p_stat': !chat?.employeeStatus}">
                                        <div class="chat_name" (click)="goToChatStatus(chat)">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M14 0.5H2C1.175 0.5 0.5 1.175 0.5 2V15.5L3.5 12.5H14C14.825 12.5 15.5 11.825 15.5 11V2C15.5 1.175 14.825 0.5 14 0.5Z" fill="#0F3FEB" fill-opacity="0.19"/>
                                            </svg>
                                            {{chat.name}}
                                            
                                            <button class="start_at_btn ml-1" *ngIf="!chat?.employeeStatus?.start_at" (click)="$event.stopPropagation(); setStartAt(chat, jobEmpl)" mat-icon-button>
                                                <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M10.6667 2.00002H10V0.666687H8.66667V2.00002H3.33333V0.666687H2V2.00002H1.33333C0.593333 2.00002 0.00666666 2.60002 0.00666666 3.33335L0 12.6667C0 13.4 0.593333 14 1.33333 14H10.6667C11.4 14 12 13.4 12 12.6667V3.33335C12 2.60002 11.4 2.00002 10.6667 2.00002ZM10.6667 12.6667H1.33333V5.33335H10.6667V12.6667ZM2.66667 6.66669H6V10H2.66667V6.66669Z" fill="#686868"/>
                                                </svg>  
                                            </button>
                                            <button class="start_at_btn ml-1" *ngIf="!chat?.employeeStatus?.start_at" (click)="setToOrderBoard(chat, jobEmpl)" mat-icon-button>
                                                <mat-icon>dashboard</mat-icon>
                                            </button>
                                        </div>
                                        <div class="d-f-c chat_statuses_wrp">
                                            <div class="start_at" (click)="!!chat?.employeeStatus?.start_at ? setStartAt(chat, jobEmpl) : null;">
                                                <ng-container *ngIf="!!chat?.employeeStatus?.start_at; else elseTemplateStart_at">
                                                    <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M10.6667 2.00002H10V0.666687H8.66667V2.00002H3.33333V0.666687H2V2.00002H1.33333C0.593333 2.00002 0.00666666 2.60002 0.00666666 3.33335L0 12.6667C0 13.4 0.593333 14 1.33333 14H10.6667C11.4 14 12 13.4 12 12.6667V3.33335C12 2.60002 11.4 2.00002 10.6667 2.00002ZM10.6667 12.6667H1.33333V5.33335H10.6667V12.6667ZM2.66667 6.66669H6V10H2.66667V6.66669Z" fill="#686868"/>
                                                    </svg>  
                                                    <ng-container *ngIf="chat?.employeeStatus?.start_at > 1">
                                                        {{ chat?.employeeStatus?.start_at*1000 | date:'MMM dd. yyyy':'': data.activeLang | yearPipe }}
                                                    </ng-container>
                                                </ng-container>
                                                <ng-template #elseTemplateStart_at>
                                                    &nbsp;
                                                </ng-template>
                                            </div>
        
                                            <div class="chat_status" (dblclick)="dblChangeChatStatus($event, jobEmpl, chat)">
                                                <div class="inside_job" [ngClass]="{
                                                    'first': chat?.employeeStatus?.status_id == '1',
                                                    'second': chat?.employeeStatus?.status_id == '2',
                                                    'third': chat?.employeeStatus?.status_id == '3',
                                                    'fours': chat?.employeeStatus?.status_id == '4',
                                                    'fives': chat?.employeeStatus?.status_id == '5',
                                                    'sixs': chat?.employeeStatus?.status_id == '6',
                                                    'nine': chat?.employeeStatus?.status_id == '97',
                                                    'seven': chat?.employeeStatus?.status_id == '98',
                                                    'eight': chat?.employeeStatus?.status_id == '99'}">
                                                    <span>{{ getStatus(chat?.employeeStatus?.status_id)?.name }}</span>  
                                                    <div>{{ "Personal" | translate }}</div>
                                                </div>
                                            </div>
                                            <!-- IF NO STATUS -->
                                            <!-- <div class="chat_status" (dblclick)="dblChangeChatStatus($event, jobEmpl, chat)">
                                                <ng-container *ngIf="!!chat?.employeeStatus; else elseTemplateStatusValue">
                                                    <div class="inside_job" [ngClass]="{
                                                        'first': chat?.employeeStatus?.status_id == '1',
                                                        'second': chat?.employeeStatus?.status_id == '2',
                                                        'third': chat?.employeeStatus?.status_id == '3',
                                                        'fours': chat?.employeeStatus?.status_id == '4',
                                                        'fives': chat?.employeeStatus?.status_id == '5',
                                                        'sixs': chat?.employeeStatus?.status_id == '6',
                                                        'seven': chat?.employeeStatus?.status_id == '98',
                                                        'eight': chat?.employeeStatus?.status_id == '99'}">
                                                        <span>{{ chat?.employeeStatus?.status?.name }}</span>  
                                                        <div>{{ "Personal" | translate }}</div>
                                                    </div>
                                                </ng-container>
                                                <ng-template #elseTemplateStatusValue>
                                                    <ng-container *ngIf="jobEmpl?.employeeStatus; else elseTemplateEmplChatStat">
                                                        <div class="inside_job" [ngClass]="{
                                                            'first': jobEmpl?.employeeStatus?.status_id == '1',
                                                            'second': jobEmpl?.employeeStatus?.status_id == '2',
                                                            'third': jobEmpl?.employeeStatus?.status_id == '3',
                                                            'fours': jobEmpl?.employeeStatus?.status_id == '4',
                                                            'fives': jobEmpl?.employeeStatus?.status_id == '5',
                                                            'sixs': jobEmpl?.employeeStatus?.status_id == '6',
                                                            'seven': jobEmpl?.employeeStatus?.status_id == '98',
                                                            'eight': jobEmpl?.employeeStatus?.status_id == '99'}">
                                                            <span>{{ jobEmpl?.employeeStatus?.status?.name }}</span>  
                                                            <div>{{ "Personal" | translate }}</div>
                                                        </div>
                                                    </ng-container>
                                                    <ng-template #elseTemplateEmplChatStat>
                                                        
                                                        <div class="inside_job" [ngClass]="{
                                                            'first': chat?.status_id == '1',
                                                            'second': chat?.status_id == '2',
                                                            'third': chat?.status_id == '3',
                                                            'fours': chat?.status_id == '4',
                                                            'fives': chat?.status_id == '5',
                                                            'sixs': chat?.status_id == '6',
                                                            'seven': chat?.status_id == '98',
                                                            'eight': chat?.status_id == '99'}">
                                                            <span>{{ chat?.status?.name }}</span>  
                                                            <div>{{ "Personal" | translate }}</div>
                                                        </div>
                                                    </ng-template>
                                                    
                                                </ng-template>
                                            </div> -->
                                            <div class="chat_status">
                                                <div class="inside_job" [ngClass]="{
                                                    'first': chat?.status_id == '1',
                                                    'second': chat?.status_id == '2',
                                                    'third': chat?.status_id == '3',
                                                    'fours': chat?.status_id == '4',
                                                    'fives': chat?.status_id == '5',
                                                    'sixs': chat?.status_id == '6',
                                                    'nine': chat?.status_id == '97',
                                                    'seven': chat?.status_id == '98',
                                                    'eight': chat?.status_id == '99'}">
                                                    <span>{{ chat?.status?.name }}</span>  
                                                    <div>{{ "Chat" | translate }}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>
                    
                </div>
    
    
                <div class="partner_wrp">
                    <h2 class="dialog_title">
                        <div class="d-f-c">
                            <span>
                                {{ (!!data.chat ? "Chat clients" : "Job clients") | translate }}
                            </span>
    
                            <button class="ml-1 custom_btn color_btn" type="button" (click)="addClient()">
                                + {{ "Assign Individual as client" | translate }}
                            </button>
    
                            <button class="ml-1 custom_btn color_btn" type="button" (click)="addPartnerClient()">
                                + {{ "Assign Workspace as client" | translate }}
                            </button>
                        </div>
                        <!-- <button mat-icon-button (click)="close()">
                            <mat-icon>close</mat-icon>
                        </button> -->
                    </h2>
                    
                    <div class="jobs_wrp partner" *ngIf="!!clients.length">
                        <ng-container *ngFor="let client of clients; let i = index">
                            <div class="job">
                                <div class="job_info">
                                    <!-- (dblclick)="dblChangeExecutor($event, data.job.partnerCompany)" -->
                                    <div class="empl_info">
                                        <ng-container *ngIf="client.client_partner_company_id && !client.employee; else elseTemplateIsPartner">
                                            <app-user [class]="'user_div'" [companyEmployee]="client?.partnerCompany" [imgRoute]="imgRoute" [host]="host" [company]="data?.company"></app-user>
                                        </ng-container>
                                        <ng-template #elseTemplateIsPartner>
                                            <app-user [class]="'user_div'" [employee]="client?.employee"></app-user>
                                        </ng-template>
                                    </div>
                                    <div class="job_dates">
                                        <ng-container *ngIf="!!client.discussion_id; else elseTemplateChatCli">
                                            {{ 'Chat client' | translate }}
                                        </ng-container>
                                        <ng-template #elseTemplateChatCli>      
                                            <ng-container *ngIf="client.task_operation_id; else elseTemplateWorkCli">
                                                {{ 'Job client' | translate }}
                                            </ng-container>
                                            <ng-template #elseTemplateWorkCli>
                                                {{ 'Card client' | translate }}
                                            </ng-template>
                                        </ng-template>
                                    </div>
                                </div>
                              
                                <div class="checkbox_clients_col">
                                    <mat-checkbox [disabled]="client.disabled" class="ml-1 mr-1" color="primary" [(ngModel)]="client.is_principal" (change)="togglePartner($event, client)">
                                        {{ "Moderation" | translate }}
                                    </mat-checkbox>
                                </div>
    
                                <mat-icon (click)="deleteClient(client, i)" class="delete_executor">delete</mat-icon>
                            </div>
                        </ng-container>
                    </div>
                        <!-- <div class="jobs_wrp sub_block">
                            <ng-container *ngFor="let jobEmpl of partner.employees; let i = index;">
                                <div class="job" [ngClass]="{'no_p_stat': jobEmpl.discussion_id == 0 ? (!jobEmpl?.employeeStatus) : (isEndChatAss && !jobEmpl?.employeeStatus)}">
                                    <button class="chats_toggler" *ngIf="jobEmpl.discussion_id == 0 && !data.chat" (click)="toggleChats(jobEmpl)" mat-icon-button>
                                        <ng-container *ngIf="jobEmpl.opened; else elseTemplateChatsIsOpen">
                                            <mat-icon>remove</mat-icon>
                                        </ng-container>
                                        <ng-template #elseTemplateChatsIsOpen>
                                            <mat-icon>add</mat-icon>
                                        </ng-template>
                                    </button>
                
                                    <div class="job_info">
                                        <div class="empl_info" (dblclick)="dblChangeExecutor($event, jobEmpl)">
                                            <app-user [class]="'user_div'" [employee]="jobEmpl?.employee"></app-user>
    
                                            <button class="start_at_btn" *ngIf="!jobEmpl?.employeeStatus?.start_at" (click)="setStartAt(jobEmpl)" mat-icon-button>
                                                <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M10.6667 2.00002H10V0.666687H8.66667V2.00002H3.33333V0.666687H2V2.00002H1.33333C0.593333 2.00002 0.00666666 2.60002 0.00666666 3.33335L0 12.6667C0 13.4 0.593333 14 1.33333 14H10.6667C11.4 14 12 13.4 12 12.6667V3.33335C12 2.60002 11.4 2.00002 10.6667 2.00002ZM10.6667 12.6667H1.33333V5.33335H10.6667V12.6667ZM2.66667 6.66669H6V10H2.66667V6.66669Z" fill="#686868"/>
                                                </svg>  
                                            </button>
                                            <button class="start_at_btn ml-1" *ngIf="!jobEmpl?.employeeStatus?.start_at" (click)="setToOrderBoard(jobEmpl)" mat-icon-button>
                                                <mat-icon>dashboard</mat-icon>
                                            </button>
                                        </div>
                                        <div class="job_dates">
                                            <div class="d-f-c">
                                                <div class="create_date">
                                                    {{ jobEmpl?.employeeStatus?.created_at*1000 | date:'MMM dd. yyyy':'': data.activeLang | yearPipe }}
                                                </div>
                                                <div class="create_date ml-1" *ngIf="jobEmpl?.completed_at">
                                                    <b>
                                                        {{ jobEmpl?.completed_at*1000 | date:'MMM dd. yyyy':'': data.activeLang | yearPipe }}
                                                    </b>
                                                </div>
                                                <div class="job_id">
                                                    {{ jobEmpl?.employeeStatus?.id }}
                                                </div>
                                            </div>
                    
                                            <div class="update_date">
                                                <ng-container *ngIf="jobEmpl?.employeeStatus?.updated_at; else elseTemplateComplete">
                                                    {{ jobEmpl?.employeeStatus?.updated_at*1000 | date:'MMM dd. yyyy':'+0000': data.activeLang | yearPipe }}
                                                </ng-container>
                                                <ng-template #elseTemplateComplete>
                                                    &nbsp;
                                                </ng-template>
                                            </div>
        
                                            <div class="start_at" (click)="!!jobEmpl?.employeeStatus?.start_at ? setStartAt(jobEmpl) : null;">
                                                <ng-container *ngIf="!!jobEmpl?.employeeStatus?.start_at; else elseTemplateStart_at">
                                                    <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M10.6667 2.00002H10V0.666687H8.66667V2.00002H3.33333V0.666687H2V2.00002H1.33333C0.593333 2.00002 0.00666666 2.60002 0.00666666 3.33335L0 12.6667C0 13.4 0.593333 14 1.33333 14H10.6667C11.4 14 12 13.4 12 12.6667V3.33335C12 2.60002 11.4 2.00002 10.6667 2.00002ZM10.6667 12.6667H1.33333V5.33335H10.6667V12.6667ZM2.66667 6.66669H6V10H2.66667V6.66669Z" fill="#686868"/>
                                                    </svg>  
                                                    <ng-container *ngIf="jobEmpl?.employeeStatus?.start_at > 1">
                                                        {{ jobEmpl?.employeeStatus?.start_at*1000 | date:'MMM dd. yyyy':'': data.activeLang | yearPipe }}
                                                    </ng-container>
                                                </ng-container>
                                                <ng-template #elseTemplateStart_at>
                                                    &nbsp;
                                                </ng-template>
                                            </div>
                                         
                                        </div>
                                    </div>
                                  
                                    <div class="job_price">
                                        <div class="val" (dblclick)="dblChangePrice($event, jobEmpl)">
                                            <ng-container *ngIf="+jobEmpl.rate > 0; else elseTemplatePrice">
                                                <span class="price_numb">
                                                    {{ jobEmpl.rate | int }}
                                                </span>
                                                <span class="dot" *ngIf="+jobEmpl.rate > +(jobEmpl.rate | int)">
                                                    {{ ((+jobEmpl?.rate - +(jobEmpl?.rate | int)) | reportsNumber).toString()|slice:1 }}
                                                </span>
                                                <span class="unit">
                                                    {{ "PTS" }}
                                                </span>
                                            </ng-container>
                                            <ng-template #elseTemplatePrice>
                                                <ng-container *ngIf="+jobEmpl?.price; else elseTemplateCurr">
                                                    {{ jobEmpl?.price | int }}
                                                    <span class="dot" *ngIf="+jobEmpl?.price > +(jobEmpl?.price | int)">
                                                        {{ ((+jobEmpl?.price - +(jobEmpl?.price | int)) | reportsNumber).toString()|slice:1 }}
                                                    </span>
                                                    <span class="unit">
                                                       {{ jobEmpl?.currency?.iso_name }}
                                                    </span>
                                                </ng-container>
                                                <ng-template #elseTemplateCurr>
                                                    &nbsp;
                                                </ng-template>
                                            </ng-template>
                                        </div>
                                    </div>
                                    <div class="job_type">
                                        <div (dblclick)="dblChangeType($event, jobEmpl)">
                                            <ng-container *ngIf="jobEmpl.is_manager > 0; else elseTemplateIsManager">
                                                {{ "Executor" | translate }}
                                            </ng-container>
                                            <ng-template #elseTemplateIsManager>
                                                <ng-container *ngIf="jobEmpl.discussion_id == 0 || !jobEmpl.discussion_id; else elseTemplateassistant">
                                                    {{ "Assistant" | translate }}
                                                </ng-container>
                                                <ng-template #elseTemplateassistant>
                                                    {{ "Chat assistant" | translate }}
                                                </ng-template>
                                            </ng-template>
                                        </div>
                                        
                    
                                        <div>
                                            <ng-container *ngIf="jobEmpl.discussion_id == 0 || !jobEmpl.discussion_id; else chatAssistRight">
                                                <mat-checkbox [disabled]="jobEmpl.disabled" class="ml-1 mr-1" color="primary" (change)="togglePriceManager($event, jobEmpl)" [checked]="jobEmpl.is_price_manager > 0">
                                                    {{ "See prices" | translate }}
                                                </mat-checkbox>
                                                <mat-checkbox *ngIf="jobEmpl.is_manager > 0" [disabled]="true" class="ml-1 mr-1" color="primary" [checked]="true">
                                                    {{ "Manage whole job staus" | translate }}
                                                </mat-checkbox>
                                            </ng-container>
                                            <ng-template #chatAssistRight>
                                                <mat-icon class="ml-1 mr-1" style="color: #2F80ED; cursor: pointer;" (click)="goToChat(jobEmpl)">chat_bubble</mat-icon>
                                            </ng-template>
                                            
                                         
                                        </div>
                                    </div>
                                    <div class="job_status" (dblclick)="jobEmpl.discussion_id == 0 || !jobEmpl.discussion_id ? dblChangeStatus($event, jobEmpl) : dblChangeChatAssStatus($event, jobEmpl, getChatById(jobEmpl.discussion_id))">
                                        <div class="inside_job" [ngClass]="{
                                            'first': jobEmpl?.employeeStatus?.status_id == '1',
                                            'second': jobEmpl?.employeeStatus?.status_id == '2',
                                            'third': jobEmpl?.employeeStatus?.status_id == '3',
                                            'fours': jobEmpl?.employeeStatus?.status_id == '4',
                                            'fives': jobEmpl?.employeeStatus?.status_id == '5',
                                            'sixs': jobEmpl?.employeeStatus?.status_id == '6',
                                            'nine': jobEmpl?.employeeStatus?.status_id == '97',
                                            'seven': jobEmpl?.employeeStatus?.status_id == '98',
                                            'eight': jobEmpl?.employeeStatus?.status_id == '99'}">
                                            <span>{{ getStatus(jobEmpl?.employeeStatus?.status_id)?.name }}</span>
                                            <div>{{ "Personal" | translate }}</div>
                                        </div>
        
                                    </div>
                           
                                    <ng-container *ngIf="jobEmpl.discussion_id == 0 || !jobEmpl.discussion_id; else chatAssistStatus">
                                        <div class="job_status">
                                            <div class="inside_job" [ngClass]="{
                                                'first': data.job.status_id == '1',
                                                'second': data.job.status_id == '2',
                                                'third': data.job.status_id == '3',
                                                'fours': data.job.status_id == '4',
                                                'fives': data.job.status_id == '5',
                                                'sixs': data.job.status_id == '6',
                                                'nine': data.job.status_id == '97',
                                                'seven': data.job.status_id == '98',
                                                'eight': data.job.status_id == '99'}">
                                                <span>{{ data?.job?.status?.name || getStatus(data?.job?.status_id)?.name }}</span>
                                                <div>{{ "Job" | translate }}</div>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <ng-template #chatAssistStatus>
                                        <div class="job_status">
                                            <div class="inside_job" [ngClass]="{
                                                'first': getChatById(jobEmpl.discussion_id)?.status_id == '1',
                                                'second': getChatById(jobEmpl.discussion_id)?.status_id == '2',
                                                'third': getChatById(jobEmpl.discussion_id)?.status_id == '3',
                                                'fours': getChatById(jobEmpl.discussion_id)?.status_id == '4',
                                                'fives': getChatById(jobEmpl.discussion_id)?.status_id == '5',
                                                'sixs': getChatById(jobEmpl.discussion_id)?.status_id == '6',
                                                'nine': getChatById(jobEmpl.discussion_id)?.status_id == '97',
                                                'seven': getChatById(jobEmpl.discussion_id)?.status_id == '98',
                                                'eight': getChatById(jobEmpl.discussion_id)?.status_id == '99'}">
                                                <span>{{ getChatById(jobEmpl.discussion_id)?.status?.name }}</span>
                                                <div>{{ "Chat" | translate }}</div>
                                            </div>
                                        </div>
                                    </ng-template>
        
        
         
                    
                                    <mat-icon class="delete_executor" (click)="deleteEmployee(jobEmpl, i)">delete</mat-icon>
                                </div>
                                <div class="empl_chats" *ngIf="jobEmpl.opened && jobEmpl.discussion_id == 0">
                                    <div class="chat" *ngFor="let chat of jobEmpl.chats" [ngClass]="{'no_p_stat': !chat?.employeeStatus}">
                                        <div class="chat_name" (click)="goToChatStatus(chat)">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M14 0.5H2C1.175 0.5 0.5 1.175 0.5 2V15.5L3.5 12.5H14C14.825 12.5 15.5 11.825 15.5 11V2C15.5 1.175 14.825 0.5 14 0.5Z" fill="#0F3FEB" fill-opacity="0.19"/>
                                            </svg>
                                            {{chat.name}}
                                            
                                            <button class="start_at_btn ml-1" *ngIf="!chat?.employeeStatus?.start_at" (click)="$event.stopPropagation(); setStartAt(chat, jobEmpl)" mat-icon-button>
                                                <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M10.6667 2.00002H10V0.666687H8.66667V2.00002H3.33333V0.666687H2V2.00002H1.33333C0.593333 2.00002 0.00666666 2.60002 0.00666666 3.33335L0 12.6667C0 13.4 0.593333 14 1.33333 14H10.6667C11.4 14 12 13.4 12 12.6667V3.33335C12 2.60002 11.4 2.00002 10.6667 2.00002ZM10.6667 12.6667H1.33333V5.33335H10.6667V12.6667ZM2.66667 6.66669H6V10H2.66667V6.66669Z" fill="#686868"/>
                                                </svg>  
                                            </button>
                                            <button class="start_at_btn ml-1" *ngIf="!chat?.employeeStatus?.start_at" (click)="setToOrderBoard(chat, jobEmpl)" mat-icon-button>
                                                <mat-icon>dashboard</mat-icon>
                                            </button>
                                        </div>
                                        <div class="d-f-c chat_statuses_wrp">
                                            <div class="start_at" (click)="!!chat?.employeeStatus?.start_at ? setStartAt(chat, jobEmpl) : null;">
                                                <ng-container *ngIf="!!chat?.employeeStatus?.start_at; else elseTemplateStart_at">
                                                    <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M10.6667 2.00002H10V0.666687H8.66667V2.00002H3.33333V0.666687H2V2.00002H1.33333C0.593333 2.00002 0.00666666 2.60002 0.00666666 3.33335L0 12.6667C0 13.4 0.593333 14 1.33333 14H10.6667C11.4 14 12 13.4 12 12.6667V3.33335C12 2.60002 11.4 2.00002 10.6667 2.00002ZM10.6667 12.6667H1.33333V5.33335H10.6667V12.6667ZM2.66667 6.66669H6V10H2.66667V6.66669Z" fill="#686868"/>
                                                    </svg>  
                                                    <ng-container *ngIf="chat?.employeeStatus?.start_at > 1">
                                                        {{ chat?.employeeStatus?.start_at*1000 | date:'MMM dd. yyyy':'': data.activeLang | yearPipe }}
                                                    </ng-container>
                                                </ng-container>
                                                <ng-template #elseTemplateStart_at>
                                                    &nbsp;
                                                </ng-template>
                                            </div>
        
                                            <div class="chat_status" (dblclick)="dblChangeChatStatus($event, jobEmpl, chat)">
                                                <div class="inside_job" [ngClass]="{
                                                    'first': chat?.employeeStatus?.status_id == '1',
                                                    'second': chat?.employeeStatus?.status_id == '2',
                                                    'third': chat?.employeeStatus?.status_id == '3',
                                                    'fours': chat?.employeeStatus?.status_id == '4',
                                                    'fives': chat?.employeeStatus?.status_id == '5',
                                                    'sixs': chat?.employeeStatus?.status_id == '6',
                                                    'nine': chat?.employeeStatus?.status_id == '97',
                                                    'seven': chat?.employeeStatus?.status_id == '98',
                                                    'eight': chat?.employeeStatus?.status_id == '99'}">
                                                    <span>{{ getStatus(chat?.employeeStatus?.status_id)?.name }}</span>  
                                                    <div>{{ "Personal" | translate }}</div>
                                                </div>
                                            </div>
                                       
                                            <div class="chat_status" (dblclick)="dblChangeChatStatus($event, jobEmpl, chat)">
                                                <ng-container *ngIf="!!chat?.employeeStatus; else elseTemplateStatusValue">
                                                    <div class="inside_job" [ngClass]="{
                                                        'first': chat?.employeeStatus?.status_id == '1',
                                                        'second': chat?.employeeStatus?.status_id == '2',
                                                        'third': chat?.employeeStatus?.status_id == '3',
                                                        'fours': chat?.employeeStatus?.status_id == '4',
                                                        'fives': chat?.employeeStatus?.status_id == '5',
                                                        'sixs': chat?.employeeStatus?.status_id == '6',
                                                        'seven': chat?.employeeStatus?.status_id == '98',
                                                        'eight': chat?.employeeStatus?.status_id == '99'}">
                                                        <span>{{ chat?.employeeStatus?.status?.name }}</span>  
                                                        <div>{{ "Personal" | translate }}</div>
                                                    </div>
                                                </ng-container>
                                                <ng-template #elseTemplateStatusValue>
                                                    <ng-container *ngIf="jobEmpl?.employeeStatus; else elseTemplateEmplChatStat">
                                                        <div class="inside_job" [ngClass]="{
                                                            'first': jobEmpl?.employeeStatus?.status_id == '1',
                                                            'second': jobEmpl?.employeeStatus?.status_id == '2',
                                                            'third': jobEmpl?.employeeStatus?.status_id == '3',
                                                            'fours': jobEmpl?.employeeStatus?.status_id == '4',
                                                            'fives': jobEmpl?.employeeStatus?.status_id == '5',
                                                            'sixs': jobEmpl?.employeeStatus?.status_id == '6',
                                                            'seven': jobEmpl?.employeeStatus?.status_id == '98',
                                                            'eight': jobEmpl?.employeeStatus?.status_id == '99'}">
                                                            <span>{{ jobEmpl?.employeeStatus?.status?.name }}</span>  
                                                            <div>{{ "Personal" | translate }}</div>
                                                        </div>
                                                    </ng-container>
                                                    <ng-template #elseTemplateEmplChatStat>
                                                        
                                                        <div class="inside_job" [ngClass]="{
                                                            'first': chat?.status_id == '1',
                                                            'second': chat?.status_id == '2',
                                                            'third': chat?.status_id == '3',
                                                            'fours': chat?.status_id == '4',
                                                            'fives': chat?.status_id == '5',
                                                            'sixs': chat?.status_id == '6',
                                                            'seven': chat?.status_id == '98',
                                                            'eight': chat?.status_id == '99'}">
                                                            <span>{{ chat?.status?.name }}</span>  
                                                            <div>{{ "Personal" | translate }}</div>
                                                        </div>
                                                    </ng-template>
                                                    
                                                </ng-template>
                                            </div> 
                                            <div class="chat_status">
                                                <div class="inside_job" [ngClass]="{
                                                    'first': chat?.status_id == '1',
                                                    'second': chat?.status_id == '2',
                                                    'third': chat?.status_id == '3',
                                                    'fours': chat?.status_id == '4',
                                                    'fives': chat?.status_id == '5',
                                                    'sixs': chat?.status_id == '6',
                                                    'nine': chat?.status_id == '97',
                                                    'seven': chat?.status_id == '98',
                                                    'eight': chat?.status_id == '99'}">
                                                    <span>{{ chat?.status?.name }}</span>  
                                                    <div>{{ "Chat" | translate }}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div> -->
                    
                </div>
            </div>
        </div>
    </ng-template>
    
</mat-dialog-content>