<mat-dialog-content class="mat-typography">
    <span class="head_title" (click)="log()">
        {{ "Lives info" | translate }}
    </span>
    <div class="autos" [appDeviceClass]="'custom-scroll custom-scroll-t'">

        <!-- <div class="d-f-c" style="justify-content: space-between;">
            <mat-form-field appearance="legacy" class="white_legacy_select" *ngIf="parameters" style="color: #fff;">
                <mat-label>{{ "Tag category" | translate }}</mat-label>
                <mat-select [disableOptionCentering]="true" [multiple]="false" selectDir [(ngModel)]="parameter_id" (selectionChange)="onParamChange($event)">
                  <mat-option>
                    <ngx-mat-select-search 
                        placeholderLabel="{{ 'Search' | translate }}" 
                        noEntriesFoundLabel="{{ 'no matching tag found' | translate }}"
                        [formControl]="parametersCtrl"></ngx-mat-select-search>
                  </mat-option>
                  <mat-option *ngFor="let parameter of parameters$ | async" [value]="parameter.id">
                    {{parameter.name}}
                  </mat-option>
                </mat-select>
            </mat-form-field>

            <div class="d-f-c" style="margin-left: 40px;">
                <mat-form-field appearance="legacy" class="white_legacy_select" *ngIf="checklists" style="color: #fff;">
                    <mat-label>{{ "Check List" | translate }}</mat-label>
                    <mat-select [disableOptionCentering]="true" [multiple]="false" selectDir [(ngModel)]="operation_reminder_id" (selectionChange)="onRemChange($event)">
                      <mat-option>
                        <ngx-mat-select-search 
                            placeholderLabel="{{ 'Search' | translate }}" 
                            noEntriesFoundLabel="{{ 'no matching checklists found' | translate }}"
                            [formControl]="checklistsCtrl"></ngx-mat-select-search>
                      </mat-option>
                      <mat-option *ngFor="let check of checklists$ | async" [value]="check.id">
                        {{check.text}}
                      </mat-option>
                    </mat-select>
                </mat-form-field>
    
                <mat-form-field appearance="legacy" class="white_legacy_select ml-1" *ngIf="parameters" style="color: #fff;">
                    <mat-label>{{ "Inherit tags from categories" | translate }}</mat-label>
                    <mat-select [disableOptionCentering]="true" [multiple]="true" selectDir [(ngModel)]="nestedTags">
                      <mat-option>
                        <ngx-mat-select-search 
                            placeholderLabel="{{ 'Search' | translate }}" 
                            noEntriesFoundLabel="{{ 'no matching tag found' | translate }}"
                            [formControl]="parametersCtrl"></ngx-mat-select-search>
                      </mat-option>
                      <mat-option *ngFor="let parameter of parameters$ | async" [value]="parameter.id" [disabled]="parameter.id == parameter_id" (selectionChange)="onNestedChange($event)">
                        {{parameter.name}}
                      </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div> -->

        <ng-container *ngIf="lives">
            <div class="items">      
                <div class="items_line items_line_head">
                    <div class="item check">
                        <div class="item_head">
                            <div class="check_item" (click)="toggleCol()">
                                <ng-container *ngIf="isAllChecked(); else elseTemplateNoInclTmlp">
                                    <mat-icon>check_box</mat-icon>
                                </ng-container>
                                <ng-template #elseTemplateNoInclTmlp>
                                    <mat-icon>check_box_outline_blank</mat-icon>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                    <div class="item post_item">
                        <div class="item_head">
                            <div class="item_photo">
                                <mat-icon>live_tv</mat-icon>
                            </div>
                            <div class="d-f-c">
                                <div class="name text_one_line">{{"Live Stream" | translate}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="item post_item">
                        <div class="item_head">
                            <div class="item_photo">
                                <mat-icon>info</mat-icon>
                            </div>
                            <div class="d-f-c">
                                <div class="name text_one_line">{{"Live info" | translate}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="item_head">
                            <div class="item_photo">
                                <mat-icon>movie</mat-icon>
                            </div>
                            <div>
                                <div class="name text_one_line">{{"Files" | translate}}</div>
                            </div>
                        </div>
                    </div>
                </div>   
                <ng-container *ngFor="let live of lives; let i = index">
                    <div class="items_line">
                        <div class="item check">
                            <div class="check_item" (click)="live.checked = !live.checked">
                                <ng-container *ngIf="!!live?.checked; else elseTemplateNoIncl">
                                    <mat-icon>check_box</mat-icon>
                                </ng-container>
                                <ng-template #elseTemplateNoIncl>
                                    <mat-icon>check_box_outline_blank</mat-icon>
                                </ng-template>
                            </div>
                        </div>
                        <div class="item post_item">
                            <div class="item_el">
                                <app-outlet-line [channel]="live.channel" [company_id]="data?.company?.id"></app-outlet-line>
                            </div>
                            <div class="item_el">
                                <div class="item_photo">
                                    <img *ngIf="live?.content_image" [src]="live?.content_image" alt="">
                                </div>
                                <input class="reports_white_input proj_name" [(ngModel)]="live.content_name" matInput placeholder="{{ 'Content Name' | translate }}">
                            </div>
                            <div class="item_el">
                                <div class="cut_value">
                                    <div (click)="status.open()">
                                        {{ (getProfileStatusById(live.content_status_id) ? (getProfileStatusById(live.content_status_id)?.name+'_profile') : 'Content status') | translate }}
                                    </div>
                                    <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                                        <mat-select disableOptionCentering #status [(ngModel)]="live.content_status_id">
                                            <ng-container *ngFor="let item of profileStatuses">
                                                <ng-container *ngIf="!withoutToUpload(live) || item.id != 6">
                                                    <mat-option [value]="item.id" [disabled]="!item?.is_user">
                                                        {{ item.name+'_profile' | translate }}
                                                    </mat-option>
                                                </ng-container>
                                            </ng-container>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="item post_info">
                            <div class="item_el d-f-c">
                                <mat-spinner class="spin_16 mr-1" matPrefix *ngIf="!!live?.isParseWork"></mat-spinner>
                                <input class="reports_white_input proj_name" [(ngModel)]="live.content_url" (input)="inputContentUrl(live, $event)" matInput placeholder="{{ 'Content URL' | translate }}">
                            </div>
                            <div class="item_el d-f-c">
                                <input class="reports_white_input proj_name mr-5" style="width: calc(50% - 5px);" [(ngModel)]="live.publishing_params.live_stream_url" matInput placeholder="{{ 'Live stream url' | translate }}">
                                <input class="reports_white_input proj_name ml-5" style="width: calc(50% - 5px);" [(ngModel)]="live.publishing_params.live_stream_key" matInput placeholder="{{ 'Live stream key' | translate }}">
                            </div>
                            <div class="item_el d-f-c">
                                <div class="box_wrp mr-5">
                                    <mat-checkbox color="primary" [(ngModel)]="live.publishing_params.is_loop">{{ "Loop" | translate }}</mat-checkbox>
                                </div>
                                <div class="box_wrp ml-5">
                                    <mat-checkbox color="primary" [(ngModel)]="live.publishing_params.is_random">{{ "Random" | translate }}</mat-checkbox>
                                </div>
                            </div>
                            <div class="item_el" *ngIf="live.publishing_params.is_random">
                                <input class="reports_white_input proj_name" [(ngModel)]="live.publishing_params.random_start_count" type="number" matInput placeholder="{{ 'Start streaming when files are ready (pcs)' | translate }}">
                            </div>
                        </div>
                        <div class="item">
                            <ng-container *ngFor="let el of live.files; let j = index;">
                                <div class="item_el" [ngClass]="{'deleted': !!el?.deleted}">
                                    <div class="item_photo">
                                        <img [src]="data?.host + el?.file?.thumbnail + '?company_id=' + data?.company?.id">
                                    </div>
                                    <div>
                                        <div class="name text_one_line">{{el?.file?.filename}}</div>
                                        <div class="info">
                                            <span>{{el?.file?.meta_duration | timeFormat}}</span>
                                            <span>{{el?.file?.meta_width + " &#10005; " + el?.file?.meta_height}}</span>
                                        </div>
                                    </div>
                                    <div class="delete_el" (click)="deleteEl(live, el, j)" *ngIf="!el?.deleted">
                                        <mat-icon>close</mat-icon>
                                    </div>
                                    <div class="delete_el" (click)="el.deleted = false;" *ngIf="!!el?.deleted">
                                        <mat-icon style="color: green;">refresh</mat-icon>
                                    </div>
                                </div>
                            </ng-container>
                            <!-- <div class="btn_add_repl" (click)="selectFile('video', item)">
                                {{"Add File" | translate}}
                            </div> -->
                        </div>
                    </div>
                </ng-container>            
                <div class="loading" *ngIf="isGettingFiles || isRealGettingFiles">
                    <mat-spinner></mat-spinner>
                </div>
            </div>
        </ng-container>
        
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <div class="bottom_btns">
        <div class="d-f-c">
            <button class="mr-1 d-f-btn" style="color: #fff !important;" *ngIf="hasChecked()" (click)="openFilesSettings()" mat-button [disabled]="isGettingFiles">
                <mat-spinner class="spin_16 mr-1" *ngIf="isGettingFiles"></mat-spinner>  
                {{ "Get files" | translate }}
            </button>
        </div>
        <div class="d-f-c">
            <div class="mr-1 loading" *ngIf="isRealGettingFiles">
                <mat-spinner class="spin_16 mr-1"></mat-spinner> 
                {{ "Loading live files" | translate }}...
            </div>
    
            <mat-form-field appearance="legacy" class="white_legacy_select full_width_form mr-1" style="color: #fff;">
                <mat-label>{{ "Content status for all" | translate }}</mat-label>
                <mat-select disableOptionCentering [formControl]="status_id">
                    <mat-option [value]="item.id" *ngFor="let item of profileStatuses" [disabled]="!item?.is_user">
                        {{ item.name+'_profile' | translate }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <button mat-raised-button (click)="close()">
                {{ "Back" | translate }}
            </button>

            <button class="ml-1 d-f-btn" (click)="submitForm2()" mat-raised-button color="primary" [disabled]="isSubmit">
                <mat-spinner class="spin_16 mr-1" *ngIf="isSubmit"></mat-spinner>  
                {{ "Save" | translate }}
            </button>
        </div>
    </div>
</mat-dialog-actions>
