import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { Subject } from 'rxjs';
import { prioritys } from 'src/app/shared/consts/prioritys';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { MembersService } from 'src/app/shared/services/rest/members.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';

@Component({
  selector: 'app-mob-assignments',
  templateUrl: './mob-assignments.component.html',
  styleUrls: ['./mob-assignments.component.scss']
})

export class MobAssignmentsComponent extends BaseClass implements OnInit, OnDestroy {
  public addExecutorSub: Subject<boolean> = new Subject();
  public selectedExecOut: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<MobAssignmentsComponent>,
    private membersService: MembersService,
    private taskService: TaskService,
    private fb: FormBuilder,
  ) { super() }

  ngOnInit(): void {
    console.log("MobAssignmentsComponent", this.data)
  }

  addExec(type) {
    this.membersService.addMobExec$.next(type)
  }

  close(type?) {
    this.dialogRef.removePanelClass('animate__slideInUp')
    this.dialogRef.addPanelClass('animate__slideOutDown')
    setTimeout(()=>{this.dialogRef.close({event: 'update', data: type})}, 300);
  }

  update() {
    // this.getTask(true)
  }

  back() {
    this.membersService.backMobExec$.next(true)
  }

  delete() {
    this.membersService.deleteMobExec$.next(true)
  }

  actUser(e) {
    this.selectedExecOut = e;
    console.log("actUser", e)
  }

  onSubmit() {
    this.close('close')
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
