<div mat-dialog-title class="mobile_interface_title">
    <div class="mobile_interface_title_in" style="overflow: hidden;">
        <div class="tab_title" (click)="close()">
            <div class="d-f-c">
                <div class="icon_back">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7 12L6.11612 11.1161C5.62796 11.6043 5.62796 12.3957 6.11612 12.8839L7 12ZM16.8839 3.88388C17.372 3.39573 17.372 2.60427 16.8839 2.11612C16.3957 1.62796 15.6043 1.62796 15.1161 2.11612L16.8839 3.88388ZM15.1161 21.8839C15.6043 22.372 16.3957 22.372 16.8839 21.8839C17.372 21.3957 17.372 20.6043 16.8839 20.1161L15.1161 21.8839ZM7.88388 12.8839L16.8839 3.88388L15.1161 2.11612L6.11612 11.1161L7.88388 12.8839ZM6.11612 12.8839L15.1161 21.8839L16.8839 20.1161L7.88388 11.1161L6.11612 12.8839Z" fill="#232323"/>
                    </svg>
                </div>
                <span>
                    {{ data.dialog_title | translate }}
                </span>
            </div>
        </div>
    </div>
</div>
<mat-dialog-content class="mobile_interface_content">
    <div class="mobile_scrollable_int" style="background: #fff;">
        <div class="has_pads">
            <div class="has_pads">
                <div class="title_inside">
                    {{ data.title | translate }}
                </div>
                <p>{{ data.msg1 | translate}}</p>
                <p>{{ data.msg2 | translate}}</p>
        
                <button class="btn_yes" (click)="yes()">{{data.btn_yes}}</button>
                <button class="btn_no" (click)="close()">{{data.btn_no}}</button>
            </div>
        </div>
    </div>
</mat-dialog-content>

<!-- <mat-dialog-actions>
    <div class="mobile_interface_buttons" style="justify-content: space-between !important;">
        <button class="mobile_interface_btn" (click)="close()">
            {{ "Cancel" | translate }}
        </button>
        
        <button class="mobile_interface_btn mobile_interface_btn_blue" (click)="onSubmit()">
            {{ "Save" | translate }}
        </button>
    </div>
</mat-dialog-actions> -->