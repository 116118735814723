import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { forkJoin, from, of, ReplaySubject } from 'rxjs';
import { catchError, debounceTime, finalize, last, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { AuthService } from 'src/app/shared/services/rest/auth.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { ParametersService } from 'src/app/shared/services/rest/parameters.service';
import { ScenariosService } from 'src/app/shared/services/rest/scenarios.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { environment } from 'src/environments/environment';
import { NgxMatDateAdapter } from '@angular-material-components/datetime-picker';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MY_FORMATS } from 'src/app/components/atTasksDialog/task-profile-add/task-profile-add.component';
import { prioritys } from 'src/app/shared/consts/prioritys';
import { GlobalDataService } from 'src/app/shared/services/common/global-data.service';
import { SelectionModel } from '@angular/cdk/collections';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { LoadingService } from 'src/app/shared/services/rest/loading.service';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import * as moment from 'moment';
import { MembersService } from 'src/app/shared/services/rest/members.service';
import { L } from '@angular/cdk/keycodes';
@Component({
  selector: 'app-create-jobs',
  templateUrl: './create-jobs.component.html',
  styleUrls: ['./create-jobs.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class CreateJobsComponent extends BaseClass implements OnInit, OnDestroy {
  public jobs: any[] = [{
    name: ``,
    tags: [],
    jobsTab: [],
    task_id: this.data.task ? this.data.task.id : 0,
    operation_id: '',
    status_id: '',
    comment: '',
    priority: 0,
    private_comment: '',
    // create_parameter_values_to_task: [],
    employee_comment: '',
    langs: [],
    completed_at: '',
    planned_completed_at: '',
    employees: [],
    clients: [],
    partners: [],
    partnerClients: [],
    templates: [],
    date: {
      time: '',
      day: ''
    }
  }];

  public selectedJobs:any = {
    name: ``,
    tags: [],
    jobsTab: [],
    operation_id: '',
    status_id: '',
    comment: '',
    priority: 0,
    private_comment: '',
    // create_parameter_values_to_task: [],
    employee_comment: '',
    langs: [],
    completed_at: '',
    planned_completed_at: '',
    employees: [],
    clients: [],
    partners: [],
    partnerClients: [],
    templates: [],
    date: {
      time: '',
      day: ''
    }
  }

  public host: any = environment.host;
  public imgRoute: any = '';

  
  public itemsCollection: any = new SelectionModel(
    true,
    []
  )

  public isSubmit: boolean = false;
  public submited: boolean = false;

  public employees: any;
  public employeeMoreControl: FormControl = new FormControl();
  public employees$: ReplaySubject<any> = new ReplaySubject<any>(1);
  
  public addEmployees: any;
  public addEmployeeMoreControl: FormControl = new FormControl();
  public addEmployees$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public operations: any;
  public operationMoreControl: FormControl = new FormControl();
  public operations$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public partners: any;
  public partnerMoreControl: FormControl = new FormControl();
  public partners$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public langs: any;
  public langs$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public langsControl: FormControl = new FormControl();

  public templates: any;
  public templatesMoreControl: FormControl = new FormControl();
  public templates$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public currencyList: any;
  public prioritys: any = prioritys;
  public operationStatuses: any;

  
  public allValues: any;
  public allValuesControl: FormControl = new FormControl();
  public allValues$: ReplaySubject<any> = new ReplaySubject<any>(1);
  
  constructor(
    private taskService: TaskService,
    private languageService: LanguageService,
    private companyService: CompanyService,
    private globalDataService: GlobalDataService,
    private layoutService: LayoutService,
    private membersService: MembersService,
    private ls: LoadingService,
    private sm: StorageManagerService,
    private auth: AuthService,
    private _adapter: DateAdapter<any>,
    private _ngx_adapter: NgxMatDateAdapter<any>,
    private scenariosService: ScenariosService,
    private parametersService: ParametersService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CreateJobsComponent>,
  ) { super() }

  ngOnInit(): void {
    console.log("CreateJobsComponent", this.data)

    if (this.data.jobs) {
      this.jobs = this.data.jobs;
    }

    if (this.data.count && this.data.count > 1) {
      for (let index = 1; index < this.data.count; index++) {
        this.addJob();
      }
    }

    this.attachSubscriptions(
      this.langsControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchLangs(resp))
    )
    this.attachSubscriptions(
      this.employeeMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchEmoloyees(resp))
    )
    this.attachSubscriptions(
      this.addEmployeeMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchAddEmoloyees(resp))
    )
    this.attachSubscriptions(
      this.operationMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchOperations(resp))
    )
    this.attachSubscriptions(
      this.partnerMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchPartners(resp))
    )
    this.attachSubscriptions(
      this.allValuesControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchValues(resp))
    )
    this.attachSubscriptions(
      this.templatesMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchTemplates(resp))
    )

    this.getAllApiParameterValues();
    this.getOperations();
    this.getOperationsStatus();
    this.getEmployees();
    this.getPartners();
    this.getCurrencyList();
    
    this.getCsrf();
    this.getImgRoute();
    this.getLangData();
    this.getAutomationTemplates();

  }

  // getCardGroupByTags(id) {
  //   if (this.data.tasks && this.data.tasks.find(k => k.id == id)) {
  //     let targetCard = this.data.tasks.find(k => k.id == id);
  //     if (!!targetCard.data && !!targetCard.data.filter(k => k.type == 'GroupBy').length) {
  //       let category = targetCard.data.find(k => k.type == 'GroupBy').parameter_id;
  //       return this.allValues.filter(k => k.parameter_id == category)
  //     } else {
  //       return false
  //     }
  //   } else {
  //     return false
  //   }
  // }

  toggleBBO(e, item, multi?) {
    console.log(e, item);

    if (multi) {
      this.selectedJobsChange(e, 'bbo')
    }
  }

  getEmployees() {
    this.attachSubscriptions(
      this.taskService.getEmployeesDyn('1', this.data.company.id, null, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }
  
          return forkJoin(arr.map(x => this.taskService.getEmployeesDyn(x, this.data.company.id, null, '200').pipe(map(u => u.body)))).pipe(
            last(),
            tap(values => {
              let conValues = [].concat(...values)
              this.employees = conValues;
              this.employees$.next(this.employees.slice())
              this.addEmployees = conValues;
              this.addEmployees$.next(this.addEmployees.slice())
            }),
          )
        }),
      ).subscribe(resp => {
        console.log("getEmployees resp", resp);
        console.log("getEmployees", this.employees);
      })
    )
  }

  getPartnerById(id) {
    if (!this.partners || this.partners.filter(el => el.partner_company_id == id).length == 0) {
      return false;
    }
    return this.partners.find(el => el.partner_company_id == id)
  }  

  checkboxLabel(row?): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.itemsCollection.isSelected(row) ? 'deselect' : 'select'} job ${row.name}`;
  }

  isAllSelected() {
    const numSelected = this.itemsCollection.selected.length;
    const numRows = this.jobs.length;
    return numSelected === numRows;
  }

  onSearchValues(resp) {
    if (!this.allValues) {
      return;
    }

    if (!resp) {
      this.allValues$.next(this.allValues.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.allValues$.next(
      this.allValues.filter(z => z.value.toLowerCase().indexOf(resp) > -1 || (z.parameter.name && z.parameter.name.toLowerCase().indexOf(resp) > -1))
    );
  }

  selectedJobsChange(e, key) {
    this.itemsCollection.selected.forEach(job => {
      // job = {...{task_id: job.task_id}, ...this.selectedJobs};
      job[key] = this.selectedJobs[key]
      // Object.keys(job).forEach(key => {
      //   if (!['task_id'].includes(key)) {
      //     job[key] = this.selectedJobs[key]
      //   }
      // })
    });
  }
  
  toggleJob(row): void {
    this.itemsCollection.toggle(row);
    console.log(this.itemsCollection);
    this.resetSelectedJobs()
  }

  resetSelectedJobs() {
    this.selectedJobs = {
      name: ``,
      tags: [],
      jobsTab: [],
      operation_id: '',
      status_id: '',
      comment: '',
      priority: 0,
      private_comment: '',
      // create_parameter_values_to_task: [],
      employee_comment: '',
      langs: [],
      completed_at: '',
      planned_completed_at: '',
      employees: [],
      clients: [],
      partners: [],
      partnerClients: [],
      templates: [],
      date: {
        time: '',
        day: ''
      }
    }
  }

  masterToggle() {
    this.isAllSelected() ?
        this.itemsCollection.clear() :
        this.jobs.forEach(row => this.itemsCollection.select(row));

    this.resetSelectedJobs()
  }

  getValuesById(id) {
    return this.allValues && this.allValues.find(x => x.id == id)
  }
  
  getEmployeeById(id) {
    if (!this.employees || this.employees.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.employees.find(el => el.id == id)
  }
  getJobTypeById(id) {
    if (!this.operations || this.operations.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.operations.find(el => el.id == id)
  }
  getPriorityById(id) {
    if (!this.prioritys || this.prioritys.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.prioritys.find(el => el.id == id)
  }

  getStatusById(id) {
    if (!this.operationStatuses || this.operationStatuses.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.operationStatuses.find(el => el.id == id)
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  getOperations() {
    this.attachSubscriptions(
      this.taskService.getOperations(this.data.company.id).subscribe(resp => {
        this.operations = resp;
        this.operations$.next(this.operations.slice())
      })
    )
  }

  getOperationsStatus() {
    this.attachSubscriptions(
      this.taskService.getOperationsStatus().subscribe(resp => {
        this.operationStatuses = resp.slice();
      })
    )
  }

  getAllApiParameterValues() {
    this.attachSubscriptions(
      this.parametersService.getAllValues('1', this.data.company.id, null, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.parametersService.getAllValues(x, this.data.company.id).pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        this.allValues = [].concat(...res)
        this.allValues$.next(this.allValues.slice())
      })
    )
  }

  getCompany() {
    this.attachSubscriptions(
      this.companyService.getCompany(this.data.company.id).subscribe(resp => {
        this.companyService.company$.next(resp[0]);
      })
    )
  }

  getPartners() {
    this.attachSubscriptions(
      this.companyService.getPartners({company_id: this.data.company.id}).subscribe(resp => {
        this.partners = resp;
        this.partners$.next(this.partners.slice());
      })
    )
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  onPartnerImgError(event){
    event.target.src = this.imgRoute+'/assets/img/partner.png'
  }
  
  getCsrf() {
    this.attachSubscriptions(
      this.auth.$userToken.subscribe(resp => {
        this.sm.localStorageSetItem("csrf_param", resp.csrf_param)
        this.sm.localStorageSetItem("csrf_token", resp.csrf_token)
      })
    )
  }

  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData().subscribe(resp => {
        this._ngx_adapter.setLocale(resp.active);
        this._adapter.setLocale(resp.active);
      })
    )
  }

  getLangById(id) {
    if (!this.langs || this.langs.length == 0) {
      return null
    }
    return this.langs.find(x => x.id == id)
  }

  getTagById(id) {
    return this.data.tabs && this.data.tabs.find(x => x.id == id)
  }

  getAutomationTemplates() {
    this.attachSubscriptions(
      this.scenariosService.getAutomationTemplates('1', {company_id: this.data.company.id, is_task_operation: 1}, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.scenariosService.getAutomationTemplates(x || 1, {company_id: this.data.company.id, is_task_operation: 1}, '200').pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        this.templates = [].concat(...res);
        this.templates$.next(this.templates.slice());
      })
    )
  }

  getTemplateById(id) {
    if (!this.templates || this.templates.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.templates.find(el => el.id == id)
  }

  onSearchTemplates(resp) {
    if (!this.templates) {
      return;
    }

    if (!resp) {
      this.templates$.next(this.templates.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.templates$.next(
      this.templates.filter(z => z.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  onSearchPartners(resp) {
    if (!this.partners) {
      return;
    }

    if (!resp) {
      this.partners$.next(this.partners.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.partners$.next(
      this.partners.filter(z => z.partnerCompany.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  onSearchAddEmoloyees(resp) {
    if (!this.employees) {
      return;
    }

    if (!resp) {
      this.addEmployees$.next(this.employees.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.addEmployees$.next(
      this.employees.filter(z => z.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  onSearchEmoloyees(resp) {
    if (!this.employees) {
      return;
    }

    if (!resp) {
      this.employees$.next(this.employees.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.employees$.next(
      this.employees.filter(z => z.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  onSearchOperations(resp) {
    if (!this.operations) {
      return;
    }

    if (!resp) {
      this.operations$.next(this.operations.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.operations$.next(
      this.operations.filter(z => (z.name.toLowerCase().indexOf(resp) > -1))
    );
  }

  getCurrency(id) {
    if (!!this.currencyList) {
      return this.currencyList.find(el => el.id == id)
    }
  }

  getCurrencyList() {
    this.attachSubscriptions(
      this.globalDataService.getCurrencies().subscribe(resp => {
        console.log("getCurrencyList", resp)
        this.currencyList = resp.slice();
      })
    )
    // this.attachSubscriptions(
    //   this.companyService.getCurrencyList().subscribe(resp => {
    //     console.log("getCurrencyList", resp);
    //     this.currencyList = resp
    //   })
    // )
  }


  getLangByVal(iso_value) {
    return this.langs && this.langs.find(x => x.iso_value == iso_value)
  }

  onSearchLangs(resp) {
    if (!this.langs) {
      return;
    }

    if (!resp) {
      this.langs$.next(this.langs.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }

    this.langs$.next(
      this.langs.filter(b => b.value.toLowerCase().indexOf(resp) > -1)
    );
  }

  getLangs() {
    this.attachSubscriptions(
      this.parametersService.getAllValues(1, this.data.company_id, {is_language: 1}, '200').pipe(
        map(res => res.body),
        tap(res => {
          this.langs = res;
          this.langs$.next(this.langs.filter(x => !!x.id).slice())
        }),
      ).subscribe(resp => {
        console.log("this.langs", this.langs)
      })
    )
  }

  addJob() {
    this.jobs.push({
      name: ``,
      tags: [],
      jobsTab: [],
      task_id: this.data.task.id,
      operation_id: '',
      status_id: '',
      comment: '',
      priority: 0,
      private_comment: '',
      // create_parameter_values_to_task: [],
      employee_comment: '',
      langs: [],
      completed_at: '',
      planned_completed_at: '',
      employees: [],
      clients: [],
      partners: [],
      partnerClients: [],
      templates: [],
      date: {
        time: '',
        day: ''
      }
    })

    if (this.itemsCollection.selected.length) {
      this.itemsCollection.toggle(this.jobs[this.jobs.length - 1]);

      Object.keys(this.selectedJobs).forEach(key => {
        this.jobs[this.jobs.length - 1][key] = this.selectedJobs[key];
      })
    }
  }

  deleteJob(i) {
    this.jobs.splice(i, 1);
  }

  onSubmit() {
    this.submited = true;
    if (this.jobs.filter(k => !k.operation_id).length || this.jobs.filter(k => !k.status_id).length) {
      this.layoutService.showSnackBar({name: 'Fill in the fields job type and status'}, '', SnackBarItem)
      return
    }
    
    this.submited = false;
    console.log("itemsCollection", this.itemsCollection.selected)
    console.log("data", this.data)
    console.log("jobs", this.jobs)


    let count = 0;
    let lenSelection = this.jobs.length;

    if (!lenSelection) {
      this.layoutService.showSnackBar({name: 'No Jobs'}, '', SnackBarItem)
      return
    }
    this.isSubmit = true;

    this.ls.requests$.next({
      value: 0,
      target: `Create ${lenSelection} jobs`
    })

    from(this.jobs).pipe(
      mergeMap(jobData => {
        let formData = {...jobData};

        let dateForm = {...formData.date}
        if (!dateForm.day) {
          dateForm.day = moment(0).hour(0).minute(0)
        }
        let testTimeAndDate = dateForm.day.clone()
        testTimeAndDate.hour(Number(dateForm.time.split(":")[0]))
        testTimeAndDate.minute(Number(dateForm.time.split(":")[1]))
        formData.planned_completed_at = testTimeAndDate;

        let paramsValues = []
        if (formData.tags && formData.tags.length) {
          formData.tags.forEach(tag => {
            paramsValues.push(tag)
          })
        }

        formData.create_parameter_values_to_task = paramsValues


        delete formData.comment;
        delete formData.private_comment;
        delete formData.employee_comment;
        delete formData.date;
        delete formData.tags;
        delete formData.jobsTab;
        delete formData.clients;
        delete formData.employees;
        delete formData.partners;
        delete formData.partnerClients;
        delete formData.bbo;
        delete formData.templates;
        delete formData.employees;
        delete formData.langs;

        
        if (!!formData.planned_completed_at && !!formData.planned_completed_at._d) {
          formData.planned_completed_at = moment(formData.planned_completed_at._d).format("X");
        } else {
          delete formData.planned_completed_at;
        }

        return this.taskService.addWork(this.data.company.id, formData).pipe(
          switchMap(jobRes => {

            if (jobData.employees.length) {
              let emplsArr = [];
              jobData.employees.forEach(eID => {
                emplsArr.push({
                    "path": `/api/task-employee/`,
                    "query": {company_id: this.data.company.id},
                    "method": "POST",
                    "body": {
                      [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
                      company_id: jobRes.company_id,
                      task_id: jobRes.task_id,
                      task_operation_id: jobRes.id,
                      employee_id: eID,
                      is_manager: 1,
                      status_id: jobRes.status_id
                    } 
                  })
              });
    
              console.log("emplsArr", emplsArr);
              return this.taskService.multiRequest(emplsArr).pipe(
                switchMap(() => {
                  if (jobData.bbo) {
                    return this.membersService.getTaskEmployeeStatusesWithoutExp({task_operation_id: jobRes.id}, this.data.company.id, '200').pipe(
                      catchError(() => {
                        return of(null)
                      }),
                      switchMap(statuses => {
                        if (statuses) {
                          return forkJoin(statuses.map(k => this.membersService.editTaskEmployeeStatus(k.id, { start_at: 1, initial_start_at: 1}, this.data.company.id)))
                        } else {
                          return of(jobRes)
                        }
                      }),
                      map(x => jobRes)
                    )
                  } else {
                    return of(jobRes)
                  }
                }),
                map(x => jobRes)
              )
            } else {
              return of(jobRes)
            }
          }),
          switchMap(jobRes => {
            if (jobData.partners.length) {
              let partnrsArr = [];
              jobData.partners.forEach(pID => {
                partnrsArr.push({
                    "path": `/api/task-partner/`,
                    "query": {company_id: this.data.company.id},
                    "method": "POST",
                    "body": {
                      [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
                      company_id: jobRes.company_id,
                      task_id: jobRes.task_id,
                      task_operation_id: jobRes.id,
                      partner_company_id: pID,
                      is_manager: 1,
                      status_id: jobRes.status_id
                    } 
                  })
              });
    
              console.log("partnrsArr", partnrsArr);
              return this.taskService.multiRequest(partnrsArr).pipe(
                switchMap(() => {
                  if (jobData.bbo) {
                    return this.membersService.getTaskPartnerStatusesWithoutExp({task_operation_id: jobRes.id}, this.data.company.id, '200').pipe(
                      catchError(() => {
                        return of(null)
                      }),
                      switchMap(statuses => {
                        if (statuses) {
                          return forkJoin(statuses.map(k => this.membersService.editPartnerStatus(k.id, { start_at: 1, initial_start_at: 1}, this.data.company.id)))
                        } else {
                          return of(jobRes)
                        }
                      }),
                      map(x => jobRes)
                    )
                  } else {
                    return of(jobRes)
                  }
                }),
                map(x => jobRes)
              )
            } else {
              return of(jobRes)
            }
          }),
          switchMap((jobRes) => {
            if ((jobData.clients && jobData.clients.length) || (jobData.partnerClients && jobData.partnerClients.length)) {
              let postData = [];
              [...jobData.clients, ...jobData.partnerClients].forEach(element => {
                postData.push({
                  "path": `/api/task-client/`,
                  "query": {company_id: this.data.company.id},
                  "method": "POST",
                  "body": Object.assign({
                    [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
                    task_id: jobRes.task_id,
                    task_operation_id: jobRes.id,
                }, element) 
                })
              });

              return this.taskService.multiRequest(postData).pipe(map(x => jobRes))
            } else {
              return of(jobRes)
            }
          }),
          switchMap((jobRes) => {
            // company_id: this.company_id,
            // task_operation_id: 0,
            // template_id: []
  
            if (!!jobData.templates && !!jobData.templates.length) {
              let tmlsData = [];
              jobData.templates.forEach(tID => {
                tmlsData.push({
                  "path": `/api/automation-scenario/`,
                  "query": {company_id: this.data.company.id},
                  "method": "POST",
                  "body": {
                    [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
                    template_id: tID,
                    company_id: jobRes.company_id,
                    task_operation_id: jobRes.id
                  }
                })
              });
  
              return this.taskService.multiRequest(tmlsData).pipe(
                switchMap(res => {
                  let tryData = [];
                  res.forEach(x => {
                    tryData.push({
                      "path": `/api/automation-scenario/execute/${x.body.id}/`,
                      "query": {company_id: this.data.company.id},
                      "method": "POST",
                      "body": {}
                    })
                  });
                  return this.taskService.multiRequest(tryData)
                }),
                map(x => jobRes)
              )
            } else {
              return of(jobRes)
            }
          }),
          tap(k => {
            this.ls.requests$.next({
              value: Math.round((100 / lenSelection) * (count+1)),
              target: `Create ${lenSelection} jobs`
            })
            count++;
          }),
          catchError(() => of([])),
        )
      }, 4), // 4 запроса параллельно
      finalize(() => {
        this.layoutService.showSnackBar({name: 'Create'}, marker("successfully!"), SnackBarItem)
        // Этот блок выполнится только после обработки всех элементов
        console.log('Все запросы завершены');
        this.isSubmit = false;
        this.dialogRef.close({event: "update", data: false})
      }),
      last()
    ).subscribe(resp => {
    })
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
