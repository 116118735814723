import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { concat, of, ReplaySubject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { LoadingService } from 'src/app/shared/services/rest/loading.service';
import { ScenariosService } from 'src/app/shared/services/rest/scenarios.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';

@Component({
  selector: 'app-add-card-scenario',
  templateUrl: './add-card-scenario.component.html',
  styleUrls: ['./add-card-scenario.component.scss']
})
export class AddCardScenarioComponent extends BaseClass implements OnInit, OnDestroy {
  public templateForm: FormGroup;
  public host: any = '';
  public imgRoute: any = '';
  public templates: any;
  public activeIndex = new FormControl(0);
  public isSubmit: boolean = false;
  public submited: boolean = false;

  public templatesMoreControl: FormControl = new FormControl();
  public templates$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public eltsSub: Subscription;
  public page = 1;
  public pagination: any;
  public cards: any = [];
  public isLoad: boolean = false;
  public progress:number = 0;
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddCardScenarioComponent>,
    private fb: FormBuilder,
    private ls: LoadingService,
    private sm: StorageManagerService,
    private taskService: TaskService,
    private scenariosService: ScenariosService,
    private layoutService: LayoutService
    ) { super() }

  ngOnInit(): void {
    console.log('AddCardScenarioComponent', this.data)
    if (this.data.task) {
      this.templateForm = this.fb.group({
        company_id: this.data.company.id,
        task_id: this.data.task.id,
        template_id: ''
      })
    } else if (this.data.tasks) {
      this.templateForm = this.fb.group({
        company_id: this.data.company.id,
        task_id: '',
        template_id: ''
      })
    }

    if (this.data.is_all_results) {
      this.getCards(this.page);
    }

    // this.scenarioForm = this.fb.group({
    //   company_id: this.data.company.id,
    //   task_id: this.data.task.id,
    //   scenario_name: ['', Validators.required],
    //   scenario_data: this.fb.group({
    //     status_id: ['', Validators.required],
    //     operation_id: ['', Validators.required],
    //     employees_ids: [],
    //     is_copy_approved_files: 0,
    //     is_copy_ready_files: 0,
    //     is_create_discussions: 0,
    //   }),
    //   is_active: 1
    // })

    this.getAutomationTemplates();
    this.attachSubscriptions(
      this.templatesMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchTemplates(resp))
    )
  }

  
  getCards(n) {
    this.isLoad = true;
    let filterData:any = {...this.data.filterData};

    this.eltsSub = this.taskService.getTasksSelectNoExpNew(n, this.data.company.id, filterData).pipe(
      tap(el => {
        this.pagination = {
          'pageCount': el.headers.get('x-pagination-page-count'),
          'perPage': el.headers.get('x-pagination-per-page'),
          'totalCount': el.headers.get('x-pagination-total-count'),
          'currentPage': el.headers.get('x-pagination-current-page')
        }
        console.log("this.pagination TEST", this.pagination)
      }),
      map(el => el.body),
      distinctUntilChanged()
    ).subscribe(resp => {

      console.log("getCards", resp)
      
      if (this.page == 1) {
        this.cards = resp;
      } else {
        this.cards.push(...resp);
      }

      this.progress = +((this.cards.length / +this.pagination.totalCount)*100).toFixed(1);
      this.page = this.page + 1;
      
      
      if (+this.pagination['currentPage'] < +this.pagination['pageCount'] && +this.pagination['pageCount'] > 0) {
        this.getCards(this.page)
      } else {
        console.log("cards - " + this.page, this.cards)
        this.isLoad = false;
        this.progress = 100;
      }
    },
    error => {
      this.isLoad = false;
    })
  }

  getAutomationTemplates() {
    this.attachSubscriptions(
      this.scenariosService.getAutomationTemplates('1', {company_id: this.data.company.id, is_task: 1}, '200').pipe(
        map(el => el.body)
      ).subscribe(resp => {
        console.log("getAutomationTemplates", resp);
        this.templates = resp;
        this.templates$.next(this.templates.slice());
      })
    )
  }

  onSearchTemplates(resp) {
    if (!this.templates) {
      return;
    }

    if (!resp) {
      this.templates$.next(this.templates.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.templates$.next(
      this.templates.filter(z => z.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  close() {
    this.dialogRef.close({event: "close", data: false});
  }

  toggleChip = (chip: any) => {
    this.templateForm.patchValue({
      template_id: !!chip.id ? chip.id : chip.value
    })
  }

  changeTab(val) {
    this.activeIndex.patchValue(val);
  }

  addScenario() {

  }

  addTemplate() {
    if (this.data.task) {
      this.data.tasks = [this.data.task.id]
    } 
    this.addTemplates()
  }

  toggleChipTemplate = (chip: any) => {
    if (!!this.templateForm.value.template_id && this.templateForm.value.template_id.includes(chip.id)) {
      let val = [...this.templateForm.value.template_id]
      val.splice(this.templateForm.value.template_id.indexOf(chip.id), 1)
      this.templateForm.patchValue({
        template_id: val
      })
    } else {
      if (!!this.templateForm.value.template_id) {
        let val = [...this.templateForm.value.template_id]
        val.push(chip.id)
        this.templateForm.patchValue({
          template_id: val
        })
      } else {
        this.templateForm.patchValue({
          template_id: [chip.id]
        })
      }
    }
  }

  addTemplates() {

    this.submited = false;
    this.isSubmit = true;

    if (this.data.is_all_results) {
      let sbmtData = [];
      let partsSbmtData = [];
  
      this.cards.forEach(task => {
        this.templateForm.value.template_id.forEach(t_id => {
          sbmtData.push({
            "path": `/api/automation-scenario/`,
            "query": {company_id: this.data.company.id},
            "method": "POST",
            "body": {
              [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
              company_id: this.data.company.id,
              task_id: task.id,
              template_id: t_id
            }
          })
        })
      });
  
      for (let i = 0; i < Math.ceil(sbmtData.length/10); i++) {
        partsSbmtData.push(sbmtData.slice(i*10, 10 + i*10))
      }
  
      let count = 0;
      concat(...partsSbmtData.map((param) => this.taskService.multiRequest(param))).pipe(
        tap(el => {
          this.ls.requests$.next({
            value: Math.round((100 / partsSbmtData.length) * (count+1)),
            target: "Set scenarios"
          })
          if (count == 0) {
            this.isSubmit = false;
            this.dialogRef.close({event: "update", data: null});
          }
          // partsSbmtData[count].forEach(param => {
          //   this.refreshService.refreshTask$.next({company_id: this.data.company_id, task_id: +param.path.replace('/api/task/', '').replace('/','')})
          // });
          count++;
        })
      ).subscribe(res => {
        console.log(res);
      })

    } else {

      let assignData = [];
  
  
      this.data.tasks.forEach(task_id => {
        this.templateForm.value.template_id.forEach(t_id => {
          assignData.push({
            "path": `/api/automation-scenario/`,
            "query": {company_id: this.data.company.id},
            "method": "POST",
            "body": {
              [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
              company_id: this.data.company.id,
              task_id: task_id,
              template_id: t_id
            }
          })
        });
      });
  
      console.log(this.templateForm.value);
      console.log("assignData", assignData)
      
      this.attachSubscriptions(
        this.taskService.multiRequest(assignData).subscribe(resp => {
          console.log("multiRequest", resp)
          this.isSubmit = false;
          if (!!resp) {
            this.dialogRef.close({event: "update", data: resp})
          }
        }, error => {
          this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
          this.isSubmit = false;
        })
      )
    }

  }

  ngOnDestroy(): void {
    if (this.eltsSub) {
      this.eltsSub.unsubscribe()
    }
    this.clearSubscriptions();
  }
}
