import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { MY_FORMATS } from 'src/app/components/atTasksDialog/task-profile-add/task-profile-add.component';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { MembersService } from 'src/app/shared/services/rest/members.service';

@Component({
  selector: 'app-edit-completed-at',
  templateUrl: './edit-completed-at.component.html',
  styleUrls: ['./edit-completed-at.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class EditCompletedAtComponent extends BaseClass implements OnInit, OnDestroy {
  public form: FormGroup;
  public isFormChange: boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<EditCompletedAtComponent>,
    private fb: FormBuilder,
    private membersService: MembersService,
    private languageService: LanguageService,
    private layoutService: LayoutService,
    private _adapter: DateAdapter<any>,
  ) {
    super()
   }

  ngOnInit(): void {
    this.form = this.fb.group({
      completed_at: this.data.employee.completed_at != 0 ? moment(this.data.employee.completed_at*1000) : ''
    })
    this.attachSubscriptions(
      this.form.valueChanges.subscribe(() => this.isFormChange = true)
    )

    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        if (this.isFormChange) {
          this.layoutService.openBottomSheet(this.dialogRef);
        } else {
          this.close();
        }
      })
    )
    this.getLangData();
  }

  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData().subscribe(resp => {
        this._adapter.setLocale(resp.active);
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  onSubmit() {
    let dataVal = this.form.value
    if (!!dataVal.completed_at && !!dataVal.completed_at._d) {
      dataVal.completed_at = moment(dataVal.completed_at._d).format("X");
    } else {
      delete dataVal.completed_at;
    }
    this.attachSubscriptions(
      (!!this.data.employee.is_partner ? this.membersService.editTaskPartner(this.data.employee.id, dataVal, this.data.company.id) : (!!this.data.employee.is_partner_employee ? this.membersService.editTaskPartnerEmployee(this.data.employee.id, dataVal, this.data.company.id) : this.membersService.editMember(this.data.employee.id, dataVal, this.data.company.id))).subscribe(resp => {
        this.dialogRef.close({event: "update", data: resp})
      },
      error => {
        this.data.showError()
        this.dialogRef.close({event: "update", data: {}})
      })
    )
  }

  ngOnDestroy(): void {
    this.clearSubscriptions();
  }


}
