<h2 mat-dialog-title class="dialog_title">
    <span>{{ "Editing a profile" | translate }}: {{ data.profile.content_name.length > 20 ? (data.profile.content_name | slice:0:20) + " ..." : data.profile.content_name }}</span>
    <button mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h2>

<mat-dialog-content class="mat-typography"  dirEnter (dirSubmit)="!form.valid ? null : onSubmit()">
    <div class="container form_wrapper">
        <form [formGroup]="form">

            <mat-form-field class="full_width_form" appearance="standard">
                <mat-label>{{ "Content published at" | translate }}</mat-label>
                <input matInput [ngxMatDatetimePicker]="picker" placeholder="Choose a date" formControlName="content_published_at">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <ngx-mat-datetime-picker #picker [showSpinners]="true" [touchUi]="false" [color]="'primary'">

                  <ng-template>
                    <span>OK</span>
                  </ng-template>
                </ngx-mat-datetime-picker>
            </mat-form-field>

            <div *ngIf="form.get('content_published_at').value">{{ "In Your time zone" | translate }}: {{showUserDate(form.get('content_published_at').value) * 1000 | date:'full':'':activeLang }} ({{ "The publication date is set in the timezone of your workspace" | translate }}: <b>{{data.company.timezone}}</b>)</div>
            
        </form>
        
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-raised-button (click)="close()">
        {{ "Back" | translate }}
    </button>
    <button class="ml-1 d-f-btn" (click)="onSubmit()" mat-raised-button cdkFocusInitial color="primary" [disabled]="!form.valid">
        {{ "Save" | translate }}
    </button>
</mat-dialog-actions>