import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseClass } from 'src/app/shared/models/base-class';
import { ChatService } from 'src/app/shared/services/rest/chat.service';

@Component({
  selector: 'app-mob-chat-status-edit',
  templateUrl: './mob-chat-status-edit.component.html',
  styleUrls: ['./mob-chat-status-edit.component.scss']
})
export class MobChatStatusEditComponent extends BaseClass implements OnInit, OnDestroy {
  public statusForm: FormGroup
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<MobChatStatusEditComponent>,
    private chatService: ChatService,
    private fb: FormBuilder
  ) { super() }

  ngOnInit(): void {
    this.statusForm = this.fb.group({
      status_id: [this.data.streamData.status_id, Validators.required]
    })

  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  onSubmit() {
    this.attachSubscriptions(
      this.chatService.editChat(this.data.streamData.id, this.statusForm.value, this.data.company.id).subscribe(resp => {
        this.data.streamData.status_id = this.statusForm.value.status_id;
        this.dialogRef.close({event: "Edit", data: resp})
      })
    )
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

}
