<h2 mat-dialog-title class="title_modal">
    <ng-container *ngIf="!!form.get('comment') || !!form.get('private_comment'); else elseTemplateDesc">
        <span class="text_one_line">
            {{ data.task.name }}
        </span>
    </ng-container>
    <ng-template #elseTemplateDesc>
        <span>
            {{ "Edditing a task name" | translate }}
        </span>
    </ng-template>
</h2>
<mat-dialog-content class="mat-typography">
    <div class="container card_editor">
        <form [formGroup]="form" class="form_with_pads" [ngClass]="{'w600': data.target}">
            <mat-form-field appearance="standard" *ngIf="form.get('name')" class="full_width_form">
                <mat-label>{{ 'Name' | translate }}</mat-label>
                <input cdkFocusInitial formControlName="name" matInput placeholder="{{ 'Name' | translate }}">
            </mat-form-field>


            <div class="TextFormatter" *ngIf="!!form.get('comment') || !!form.get('private_comment')" [ngClass]="{'open': selectedText?.is_selected}" [ngStyle]="{'left': !!selectedText?.position?.x ? (selectedText?.position?.x + 'px') : '0px', 'top': !!selectedText?.position?.y ? (selectedText?.position?.y + 'px') : '0px'}">
                <div class="TextFormatter-buttons">
                    <button type="button" [ngClass]="{'active': !!selectedText?.state?.bold}" (click)="formatText('bold')" class="Button default translucent" aria-label="Bold text" title="Bold text">
                        <mat-icon>format_bold</mat-icon>
                    </button>
                    <button type="button" [ngClass]="{'active': !!selectedText?.state?.italic}" (click)="formatText('italic')" class="Button default translucent" aria-label="Italic text" title="Italic text">
                        <mat-icon>format_italic</mat-icon>
                    </button>
                    <button type="button" [ngClass]="{'active': !!selectedText?.state?.underline}" (click)="formatText('underline')" class="Button default translucent" aria-label="Underlined text" title="Underlined text">
                        <mat-icon>format_underlined</mat-icon>
                    </button>
                    <button type="button" [ngClass]="{'active': !!selectedText?.state?.strikeThrough}" (click)="formatText('strikeThrough')" class="Button default translucent" aria-label="Strikethrough text" title="Strikethrough text">
                        <mat-icon>strikethrough_s</mat-icon>
                    </button>
                    <button type="button" [ngClass]="{'active': !!selectedText?.state?.insertUnorderedList}" (click)="formatText('insertUnorderedList')" class="Button default translucent" aria-label="Strikethrough text" title="Strikethrough text">
                        <mat-icon>format_list_bulleted</mat-icon>
                    </button>
                    <button type="button" [ngClass]="{'active': !!selectedText?.state?.insertOrderedList}" (click)="formatText('insertOrderedList')" class="Button default translucent" aria-label="Strikethrough text" title="Strikethrough text">
                        <mat-icon>format_list_numbered</mat-icon>
                    </button>
                    <div class="TextFormatter-divider"></div>
                    <button (click)="formatText('createLink')" [ngClass]="{'active': !!selectedText?.state?.createLink}" type="button" class="Button default translucent" aria-label="Add Link" title="Add Link">
                        <mat-icon>link</mat-icon>
                    </button>
                    <button (click)="formatText('unlink')" *ngIf="!!selectedText?.state?.createLink" type="button" class="Button default translucent" aria-label="Delete Link" title="Delete Link">
                        <mat-icon>link_off</mat-icon>
                    </button>
                </div>
            </div>
            <div class="edit_comment_wrp" *ngIf="form.get('comment')">
                <div class="label" *ngIf="!form.get('comment').value">
                    {{ 'Comment' | translate }}
                </div>
                <div class="content_editable" [appDeviceClass]="'custom-scroll custom-scroll-t'" (paste)="onPaste($event)" (mouseup)="mouseUp($event)" (copy)="onCopy($event)" contenteditable="true" formControlName="comment" matInput></div>
            </div>
            
            <div class="edit_comment_wrp" *ngIf="form.get('private_comment')">
                <div class="label" *ngIf="!form.get('private_comment').value">
                    {{ 'Private comment' | translate }}
                </div>
                <div class="content_editable private" [appDeviceClass]="'custom-scroll custom-scroll-t'" (paste)="onPaste($event)" (mouseup)="mouseUp($event)" (copy)="onCopy($event)" contenteditable="true" formControlName="private_comment" matInput></div>
            </div>
        </form>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <div class="f_w_p_btns">
        <button mat-raised-button (click)="closeBtn()">
            {{ "Cancel" | translate }}
        </button>
        <button class="ml-1" (click)="submitForm()" mat-raised-button cdkFocusInitial color="primary" [disabled]="!form.valid">
            {{ "Save" | translate }}
        </button>
    </div>
</mat-dialog-actions>
