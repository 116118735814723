<div class="container cases_page">
    <div class="dashboard_wrp">
        <div class="dashboard">
            <!-- Cases -->
            <div class="dash_item" (mouseenter)="!is_mobile ? onMouseEnter($event, 0) : null" (click)="!hoveredStatus[0].hover ? onMouseEnter($event, 0) : null;" [ngClass]="{'hovered': hoveredStatus[0].hover}">
                <div class="page_title">
                    <div class="page_name">
                        {{ "Cases" | translate }}
                    </div>
                    <div class="chat_icon_wrp">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16 12V16H2V12H16ZM17 10H1C0.45 10 0 10.45 0 11V17C0 17.55 0.45 18 1 18H17C17.55 18 18 17.55 18 17V11C18 10.45 17.55 10 17 10ZM4 15.5C3.18 15.5 2.5 14.83 2.5 14C2.5 13.17 3.18 12.5 4 12.5C4.82 12.5 5.5 13.17 5.5 14C5.5 14.83 4.83 15.5 4 15.5ZM16 2V6H2V2H16ZM17 0H1C0.45 0 0 0.45 0 1V7C0 7.55 0.45 8 1 8H17C17.55 8 18 7.55 18 7V1C18 0.45 17.55 0 17 0ZM4 5.5C3.18 5.5 2.5 4.83 2.5 4C2.5 3.17 3.18 2.5 4 2.5C4.82 2.5 5.5 3.18 5.5 4C5.5 4.82 4.83 5.5 4 5.5Z" fill="#686868"/>
                        </svg>
                    </div>
                </div>
                <div class="filter_out hover_viss">
                    <form class="filter_in page_filter" [formGroup]="filterTasks" (ngSubmit)="searchInPage(0)">
                        <div class="filter_form_in">
                            <div class="d-f-c" #typeFilter>
                                <mat-form-field appearance="legacy" class="filter_form search">
                                    <mat-icon matPrefix>search</mat-icon>
                                    <input formControlName="q" matInput #search>
                                    <mat-icon style="cursor: pointer;" (click)="openFilter(typeFilter, 'tasks'); $event.preventDefault(); $event.stopPropagation();" matSuffix>tune</mat-icon>
                                </mat-form-field>
                                <button type="submit" class="filter_btn search_filter_btn">{{ "Search" | translate }}</button>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="filter_toggle page_btn hover_viss" (click)="openTaskFilter()">
                    <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3 7H15V5H3V7ZM0 0V2H18V0H0ZM7 12H11V10H7V12Z" fill="black"/>
                    </svg>
                    {{ "Filter" | translate }}
                </div>
                <a [routerLink]="['/tasks']" [queryParams]="{company_id: company?.id, order_by: 'updated_desc', count: '1'}" class="link-to-page page_btn hover_viss" (click)='goToCompany($event, company?.id)'>
                    {{ "All tasks" | translate }}
                </a>
            </div>
            
            <!-- Chats -->
            <div class="dash_item" (mouseenter)="!is_mobile ? onMouseEnter($event, 1) : null;" (click)="!hoveredStatus[1].hover ? onMouseEnter($event, 1) : null;" [ngClass]="{'hovered': hoveredStatus[1].hover}">
                <div class="page_title">
                    <div class="page_name">
                        {{ "Chats" | translate }}
                    </div>
                    <div class="chat_icon_wrp">
                        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M32 0.5H4C2.075 0.5 0.5 2.075 0.5 4V35.5L7.5 28.5H32C33.925 28.5 35.5 26.925 35.5 25V4C35.5 2.075 33.925 0.5 32 0.5Z" fill="#686868"/>
                        </svg>
                    </div>
                </div>
                <div class="filter_out hover_viss">
                    <form class="filter_in page_filter" [formGroup]="filterChats" (ngSubmit)="searchInPage(1)">
                        <div class="filter_form_in">
                            <div class="d-f-c" #chatsFilter>
                                <mat-form-field appearance="legacy" class="filter_form search">
                                    <mat-icon matPrefix>search</mat-icon>
                                    <input formControlName="q" matInput #search>
                                    <mat-icon style="cursor: pointer;" (click)="openFilter(chatsFilter, 'chats'); $event.preventDefault(); $event.stopPropagation();" (click)="$event.stopPropagation();" matSuffix>tune</mat-icon>
                                </mat-form-field>
                                <button type="submit" class="filter_btn search_filter_btn">{{ "Search" | translate }}</button>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="filter_toggle page_btn hover_viss" (click)="openChatFilter()">
                    <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3 7H15V5H3V7ZM0 0V2H18V0H0ZM7 12H11V10H7V12Z" fill="black"/>
                    </svg>
                    {{ "Filter" | translate }}
                </div>
                <a [routerLink]="['/chats']" [queryParams]="{company_id: company?.id, order_by: 'updated_desc', count: '1'}" class="link-to-page page_btn hover_viss" (click)='goToChats($event, company?.id)'>
                    {{ "All chats" | translate }}
                </a>
            </div>

            <!-- URLS -->
            <div class="dash_item" (mouseenter)="!is_mobile ? onMouseEnter($event, 2) : null" (click)="!hoveredStatus[2].hover ? onMouseEnter($event, 2) : null;" [ngClass]="{'hovered': hoveredStatus[2].hover}">
                <div class="page_title">
                    <div class="page_name">
                        {{ "URLs" | translate }}
                    </div>
                    <div class="chat_icon_wrp">
                        <svg class="urls_icon" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M3.9 12c0-1.71 1.39-3.1 3.1-3.1h4V7H7c-2.76 0-5 2.24-5 5s2.24 5 5 5h4v-1.9H7c-1.71 0-3.1-1.39-3.1-3.1zM8 13h8v-2H8v2zm9-6h-4v1.9h4c1.71 0 3.1 1.39 3.1 3.1s-1.39 3.1-3.1 3.1h-4V17h4c2.76 0 5-2.24 5-5s-2.24-5-5-5z"/></svg>
                    </div>
                </div>
                <div class="filter_out hover_viss">
                    <form class="filter_in page_filter" (ngSubmit)="searchInPage(2)">
                        <div class="filter_form_in">
                            <div class="d-f-c">
                            <mat-form-field appearance="legacy" class="filter_form search">
                                <mat-icon matPrefix>search</mat-icon>
                                <input matInput #search>
                                <mat-icon (click)="$event.stopPropagation();" matSuffix>tune</mat-icon>
                            </mat-form-field>
                            <button type="submit" class="filter_btn search_filter_btn">{{ "Search" | translate }}</button>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="filter_toggle page_btn hover_viss" style="visibility: hidden !important;">
                    <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3 7H15V5H3V7ZM0 0V2H18V0H0ZM7 12H11V10H7V12Z" fill="black"/>
                    </svg>
                    {{ "Filter" | translate }}
                </div>
                <a [routerLink]="['/urls']" [queryParams]="{company_id: company?.id, order_by: 'content_published_at_desc', count: '1'}" class="link-to-page page_btn hover_viss" (click)='goToUrls($event, company?.id)'>
                    {{ "All URLs" | translate }}
                </a>
            </div>

            <!-- Jobs -->
            <div class="dash_item" (mouseenter)="!is_mobile ? onMouseEnter($event, 3) : null" (click)="!hoveredStatus[3].hover ? onMouseEnter($event, 3) : null;" [ngClass]="{'hovered': hoveredStatus[3].hover}">
                <div class="page_title">
                    <div class="page_name">
                        {{ "Jobs" | translate }}
                    </div>
                    <div class="chat_icon_wrp">
                        <svg width="42" height="33" viewBox="0 0 42 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 33H42V24.75H0V33ZM4.2 26.8125H8.4V30.9375H4.2V26.8125ZM0 0V8.25H42V0H0ZM8.4 6.1875H4.2V2.0625H8.4V6.1875ZM0 20.625H42V12.375H0V20.625ZM4.2 14.4375H8.4V18.5625H4.2V14.4375Z" fill="#686868"/>
                        </svg>
                    </div>
                </div>
                <div class="filter_out hover_viss">
                    <form class="filter_in page_filter" (ngSubmit)="searchInPage(3)">
                        <div class="filter_form_in">
                            <div class="d-f-c">
                            <mat-form-field appearance="legacy" class="filter_form search">
                                <mat-icon matPrefix>search</mat-icon>
                                <input matInput #search>
                                <mat-icon (click)="$event.stopPropagation();" matSuffix>tune</mat-icon>
                            </mat-form-field>
                            <button type="submit" class="filter_btn search_filter_btn">{{ "Search" | translate }}</button>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="filter_toggle page_btn hover_viss" style="visibility: hidden !important;">
                    <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3 7H15V5H3V7ZM0 0V2H18V0H0ZM7 12H11V10H7V12Z" fill="black"/>
                    </svg>
                    {{ "Filter" | translate }}
                </div>
                <a [routerLink]="['/jobs']" [queryParams]="{company_id: company?.id, order_by: 'updated_desc', count: '1'}" class="link-to-page page_btn hover_viss" (click)='goToJobs($event, company?.id)'>
                    {{ "All jobs" | translate }}
                </a>
            </div>

            <!-- Board -->
            <div class="dash_item" (mouseenter)="!is_mobile ? onMouseEnter($event, 4) : null" (click)="!hoveredStatus[4].hover ? onMouseEnter($event, 4) : null;" [ngClass]="{'hovered': hoveredStatus[4].hover}">
                <div class="page_title">
                    <div class="page_name">
                        {{ "Board" | translate }}
                    </div>
                    <div class="chat_icon_wrp">
                        <svg class="board_icon" width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M35 17.5V10.75C35 9.64543 34.1046 8.75 33 8.75H9C7.89543 8.75 7 9.64543 7 10.75V17.5M35 17.5V34.75C35 35.8546 34.1046 36.75 33 36.75H9C7.89543 36.75 7 35.8546 7 34.75V17.5M35 17.5H7M14 5.25V12.25M28 5.25V12.25" stroke="#686868" stroke-width="2" stroke-linecap="round"/>
                            <rect x="22.75" y="24.5" width="8.75" height="8.75" rx="1" fill="#686868"/>
                        </svg>
                    </div>
                </div>
                <div class="filter_out hover_viss">
                    <form class="filter_in page_filter" (ngSubmit)="searchInPage(4)">
                        <div class="filter_form_in">
                            <div class="d-f-c">
                            <mat-form-field appearance="legacy" class="filter_form search">
                                <mat-icon matPrefix>search</mat-icon>
                                <input #search matInput>
                                <mat-icon (click)="$event.stopPropagation();" matSuffix>tune</mat-icon>
                            </mat-form-field>
                            <button type="submit" class="filter_btn search_filter_btn">{{ "Search" | translate }}</button>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="filter_toggle page_btn hover_viss" (click)="openBoardFilter()">
                    <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3 7H15V5H3V7ZM0 0V2H18V0H0ZM7 12H11V10H7V12Z" fill="black"/>
                    </svg>
                    {{ "Filter" | translate }}
                </div>
                <a [routerLink]="['/board']" [queryParams]="{company_id: company?.id, order_by: 'updated_desc', count: '1'}" class="link-to-page page_btn hover_viss" (click)='goToBoard($event, company?.id)'>
                    {{ "All board" | translate }}
                </a>
            </div>

        </div>
    </div>
</div>

<ng-template #filterMenu let-val>
    <section class="search_params">
        <ng-container [ngSwitch]="val">
            <ng-container *ngSwitchCase="'tasks'">
                <form [formGroup]="filterTasks" class="params_form" (ngSubmit)="onFilterTasks()" >
                    <mat-form-field class="search-field" appearance="legacy">
                      <mat-label>{{ "Card Title" | translate }}</mat-label>
                      <input matInput formControlName="q_task_name">
                    </mat-form-field>
                    <mat-form-field class="search-field" appearance="legacy">
                      <mat-label>{{ "Card description" | translate }}</mat-label>
                      <input matInput formControlName="q_task_comment">
                    </mat-form-field>
                    <mat-form-field class="search-field" appearance="legacy">
                      <mat-label>{{ "Card private comment" | translate }}</mat-label>
                      <input matInput formControlName="q_task_private_comment">
                    </mat-form-field>
                    <div class="d-f-c search-field-group">
                      <mat-form-field class="search-field" appearance="legacy">
                        <mat-label>{{ "Card custom ID" | translate }}</mat-label>
                        <input type="number" matInput formControlName="custom_id">
                      </mat-form-field>
                      <mat-form-field class="search-field ml-1" appearance="legacy">
                        <mat-label>{{ "Card internal ID" | translate }}</mat-label>
                        <input type="number" matInput formControlName="internal_id">
                      </mat-form-field>
                      <mat-form-field class="search-field ml-1" appearance="legacy">
                        <mat-label>{{ "Card system ID" | translate }}</mat-label>
                        <input type="number" matInput formControlName="id">
                      </mat-form-field>
                    </div>
                    <mat-form-field class="search-field" appearance="legacy">
                      <mat-label>{{ "Card workspace ID" | translate }}</mat-label>
                      <input type="number" matInput formControlName="task_company_id">
                    </mat-form-field>
                    
                    <div style="padding-top: 30px;"></div>
                    
                    <mat-form-field class="search-field" appearance="legacy">
                      <mat-label>{{ "Job title" | translate }}</mat-label>
                      <input matInput formControlName="q_task_operation_comment">
                    </mat-form-field>
                    <mat-form-field class="search-field" appearance="legacy">
                      <mat-label>{{ "Job executor commment" | translate }}</mat-label>
                      <input matInput formControlName="q_task_operation_employee_comment">
                    </mat-form-field>
                    <mat-form-field class="search-field" appearance="legacy">
                      <mat-label>{{ "Job private comment " | translate }}</mat-label>
                      <input matInput formControlName="q_task_operation_private_comment">
                    </mat-form-field>
                    <mat-form-field class="search-field" appearance="legacy">
                      <mat-label>{{ "Job System ID " | translate }}</mat-label>
                      <input type="number" matInput formControlName="task_operation_id">
                    </mat-form-field>
            
                    <div style="padding-top: 30px;"></div>
            
                    <mat-form-field class="search-field" appearance="legacy">
                      <mat-label>{{ "Chat system ID" | translate }}</mat-label>
                      <input type="number" matInput formControlName="discussion_id">
                    </mat-form-field>
            
                    <div class="d-f-c" style="padding-top: 20px; justify-content: flex-end;">
                      
                      <button mat-button type="button" (click)="closeOpenFilter()">{{ "Close" | translate }}</button>
                      <button mat-raised-button color="warn" (click)="resetSearchTasksParams()" type="button" class="ml-1">{{ "Clear" | translate }}</button>
                      <button mat-raised-button color="primary" type="submit" class="ml-1">{{ "Search" | translate }}</button>
                    </div>
                </form>
            </ng-container>
            <ng-container *ngSwitchCase="'chats'">
                <form [formGroup]="filterChats" class="params_form" (ngSubmit)="onFilterChats()">
                    <mat-form-field class="search-field" appearance="legacy">
                      <mat-label>{{ "Chat title" | translate }}</mat-label>
                      <input matInput formControlName="q_name">
                    </mat-form-field>

                    <mat-form-field class="search-field" appearance="legacy">
                      <mat-label>{{ "Chat messages" | translate }}</mat-label>
                      <input matInput formControlName="q_text">
                    </mat-form-field>
              
                    <div class="d-f-c" style="padding-top: 20px; justify-content: flex-end;">
                      <button mat-button type="button" (click)="closeOpenFilter()">{{ "Close" | translate }}</button>
                      <button mat-raised-button color="primary" type="submit" class="ml-1">{{ "Search" | translate }}</button>
                    </div>
                </form>
            </ng-container>
        </ng-container>

    </section>
</ng-template>