<h2 mat-dialog-title class="dialog_title">
    <span>
        {{ data.value ? data.value.value : data.param.name}} {{"tips"|translate}}:
    </span>
    <button mat-icon-button (click)="close()" style="margin-left: 40px;">
        <mat-icon>close</mat-icon>
    </button>
</h2>
<mat-dialog-content class="mat-typography">
    <div class="container" style="padding-bottom: 20px;">
        <a [href]="origin + '/task/' + item?.task_acm" target="_blank" class="text_one_line" *ngFor="let item of tips">
            {{item?.task.name}} (id: {{item.task_id}})
        </a>
    </div>
</mat-dialog-content>

