<div class="cases_page tasks_page outlets_sticky">
    <div class="container">
      <div class="filter_out">  
        <div class="filter_values">
          <mat-chip-list class="chips_wrp without_ordered start_page_chips">
  
            <mat-chip (removed)="removeValue('q')" *ngIf="filter.get('q').value">
                {{ "Search" | translate }}: {{filter.get('q').value}}
              <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
            </mat-chip>

            <mat-chip class="user_chip" (removed)="removeValue('operation_partner_company_id')" *ngIf="filter.get('partner_company_id').value">
                {{ "Partner Workspace" | translate }}:
                <app-user [class]="'user_div ml-1'" [companyEmployee]="getPartnerById(filter.get('partner_company_id').value)?.partnerCompany" [imgRoute]="imgRoute" [host]="host" [company]="company"></app-user>
                <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
            </mat-chip>

            <mat-chip class="user_chip" (removed)="removeValue('platform_id')" *ngIf="filter.get('platform_id').value">
                <div class="platform_wrp">
                  <app-platform [platform_id]="filter.get('platform_id').value"></app-platform>
                  {{ getPlatformById(filter.get('platform_id').value)?.name }}
                </div>
                <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
            </mat-chip>

            <mat-chip (removed)="removeValue('group_id')" *ngIf="filter?.get('group_id')?.value || filter?.get('group_id')?.value === 0">
                {{ "Group" | translate }}: {{ filter?.get('group_id')?.value === 0 ? 'Not Set' : getGroupById(filter?.get('group_id')?.value)?.name }}
                <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
            </mat-chip>

            <ng-container *ngIf="filter.get('parameter_value_id').value.length">
              <span class="chip_out_label">{{ "Tag for everywhere" | translate }}</span>
              <mat-chip *ngFor="let forTaskParamValueId of filter.get('parameter_value_id').value" (removed)="removeValueFromMultiple('parameter_value_id', forTaskParamValueId)">
                 {{ getValueById(forTaskParamValueId)?.value }}
                <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
              </mat-chip>
            </ng-container>
  
            <mat-icon *ngIf="filter.get('q').value || filter?.get('partner_company_id')?.value || filter.get('parameter_value_id').value.length || filter?.get('platform_id')?.value || (filter?.get('group_id')?.value || filter?.get('group_id')?.value === 0)" class="remove_all_chips_btn" (click)="removeValue('all')">cancel</mat-icon>
          </mat-chip-list>
  
          <div class="stats_wrp ml-1">
            <div class="tasks_counters">
              
              <!-- <form [formGroup]="filter" class="preset_form" *ngIf="bookmarks && bookmarks.length > 0">
                <div class="simple_filter_snippet" (click)="presetFilter.open()">
                  <ng-container *ngIf="filter.get('preset_id').value; else elseTemplatePreset">
                    <span class="name">
                      {{ getBookmarkById(filter.get('preset_id').value)?.name }}
                    </span>
                  </ng-container>
                  <ng-template #elseTemplatePreset>
                    <span class="name">
                      {{ "Bookmark" | translate }}
                    </span>
                  </ng-template>
                  
  
                  <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_2474_38987)">
                    <path d="M7.91699 3.33325L2.08366 3.33325L5.00033 7.91658L7.91699 3.33325Z" fill="black"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_2474_38987">
                    <rect width="10" height="10" fill="white" transform="translate(10) rotate(90)"/>
                    </clipPath>
                    </defs>
                  </svg>
                </div>
  
                <mat-form-field appearance="legacy" class="filter_form preset_filter">
                  <mat-label>{{ "Bookmark" | translate }}</mat-label>
                  <mat-select #presetFilter [disableOptionCentering]="true" selectDir formControlName="preset_id" (selectionChange)="bookmarksChange($event)">
                      <mat-option *ngIf="bookmarks.length > 7">
                        <ngx-mat-select-search 
                            placeholderLabel="{{ 'Search' | translate }}" 
                            noEntriesFoundLabel="{{ 'no matching presets found' | translate }}"
                            [formControl]="bookmarksControl"></ngx-mat-select-search>
                      </mat-option>
                      <mat-option *ngFor="let bookmark of bookmarks$ | async" [value]="bookmark.id">
                        {{ bookmark.name | translate }}
                      </mat-option>
                  </mat-select>
                </mat-form-field>
              </form> -->
  
              <div class="counter_block" *ngIf="pagination && pagination['totalCount']">
                <div class="counter_top">{{ "Outlets" | translate }}</div>
                <div class="counter_bottom">{{pagination['totalCount']}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <!-- <div class="lists_wrp">
      </div>    -->
    </div>
    <div class="outlets_head">
      <div class="col_outlet">
        <div class="title">{{"Outlet" | translate}}</div>
      </div>
      <div class="col_group">
        <div class="title">{{"Groups" | translate}}</div>
      </div>
      <div class="col_tags">
        <div class="title">{{"Tags" | translate}}</div>
      </div>
      <div class="col_publisher">
        <div class="title">{{"Publisher" | translate}}</div>
      </div>
      <div class="col_action">
        <div class="title">{{"Action" | translate}}</div>
      </div>
    </div>
</div>
<div class="container centred_page">

    <div class="step">
        <!-- <h4>{{ 'Outlets' | translate }}: {{ pagination?.totalCount }}</h4>  -->



        <div class="outlets" [appDeviceClass]="'custom-scroll custom-scroll-t'" #tableContainer>
          <div class="outlet" *ngFor="let profile of profiles; let i = index;">

            <div class="col_outlet">

              <div class="outlet_img">
                <ng-container *ngIf="profile.avatarFile; else elseTemplateNoPhoto">
                  <img [src]="host + profile.avatarFile.original + '?company_id=' + company_id" alt="">
                </ng-container>
                <ng-template #elseTemplateNoPhoto>   
                  <img style="object-fit: cover;" src="{{ imgRoute }}assets/img/no-image.jpg" alt="">
                </ng-template>
              </div>
              <div class="outlet_info">
                <div class="name">
                  <app-platform [platform_id]="profile?.platform_id"></app-platform>
                  <div class="text_one_line text">
                    {{ profile?.name || "no_name" }}
                  </div>
                  <a class="icon_out show_on_hover" *ngIf="profile.url" [href]="profile.url" target="_blank" #tooltip="matTooltip" matTooltip="{{ !!profile?.channel_account_id ? ('Link to authorized channel' | translate) : ('Link to not authorized channel' | translate) }}" [matTooltipPosition]="'above'">
                    <svg style="width: 16px; height: 15px;" width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6.5625 0.833344H3.22917C2.78714 0.833344 2.36322 1.00894 2.05066 1.3215C1.73809 1.63406 1.5625 2.05798 1.5625 2.50001V12.5C1.5625 12.942 1.73809 13.366 2.05066 13.6785C2.36322 13.9911 2.78714 14.1667 3.22917 14.1667H13.2292C13.6712 14.1667 14.0951 13.9911 14.4077 13.6785C14.7202 13.366 14.8958 12.942 14.8958 12.5V9.16668M8.22917 7.50001L14.8958 0.833344M14.8958 0.833344V5.00001M14.8958 0.833344H10.7292" [ngStyle]="{'stroke': !!profile?.channel_account_id ? '#3182E0' : '#686868'}" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>                      
                  </a>
                </div>
                <div class="small_val" [ngClass]="{'not_active': !profile?.channelAccount || (profile?.channelAccount && !profile?.channelAccount?.is_active)}" *ngIf="!!profile?.channel_account_id">
                  <div class="val_ico">
                    <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.25 2.5L8.75 5H10.625C10.625 5.99456 10.2299 6.94839 9.52665 7.65165C8.82339 8.35491 7.86956 8.75 6.875 8.75C6.25 8.75 5.64375 8.59375 5.125 8.3125L4.2125 9.225C4.98125 9.7125 5.89375 10 6.875 10C8.20108 10 9.47285 9.47322 10.4105 8.53553C11.3482 7.59785 11.875 6.32608 11.875 5H13.75L11.25 2.5ZM3.125 5C3.125 4.00544 3.52009 3.05161 4.22335 2.34835C4.92661 1.64509 5.88044 1.25 6.875 1.25C7.5 1.25 8.10625 1.40625 8.625 1.6875L9.5375 0.775C8.76875 0.2875 7.85625 0 6.875 0C5.54892 0 4.27715 0.526784 3.33947 1.46447C2.40178 2.40215 1.875 3.67392 1.875 5H0L2.5 7.5L5 5" fill="#1877F2"/>
                    </svg>                         
                  </div>
                  <span>{{profile?.channelAccount?.name}}({{profile?.channelAccount?.type}})</span>
                </div>
              </div>

        
            </div>

            <div class="col_group">
              <div *ngIf="!!profile?.groups.length" class="groups_wrp">
                <span class="group_name" *ngFor="let group of profile?.groups; let j = index;">
                    {{ group.name }}
                </span>
              </div>
            </div>

            <div class="col_tags">
              <div class="chan_tags" [ngClass]="{'has': profile.tags && profile.tags.length, 'show_on_hover': !(profile.tags && profile.tags.length)}" (click)="profile.tags && profile.tags.length ? null : openTargetValues(profile)">
                  <svg class="cp" (click)="openTargetValues(profile)" style="width: 20px; height: 16px;" width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3.5 5C3.89782 5 4.27936 4.84196 4.56066 4.56066C4.84196 4.27936 5 3.89782 5 3.5C5 3.10218 4.84196 2.72064 4.56066 2.43934C4.27936 2.15804 3.89782 2 3.5 2C3.10218 2 2.72064 2.15804 2.43934 2.43934C2.15804 2.72064 2 3.10218 2 3.5C2 3.89782 2.15804 4.27936 2.43934 4.56066C2.72064 4.84196 3.10218 5 3.5 5ZM15.41 7.58C15.77 7.94 16 8.44 16 9C16 9.55 15.78 10.05 15.41 10.41L10.41 15.41C10.05 15.77 9.55 16 9 16C8.45 16 7.95 15.78 7.58 15.41L0.59 8.42C0.22 8.05 0 7.55 0 7V2C0 0.89 0.89 0 2 0H7C7.55 0 8.05 0.22 8.41 0.58L15.41 7.58ZM11.54 1.71L12.54 0.71L19.41 7.58C19.78 7.94 20 8.45 20 9C20 9.55 19.78 10.05 19.42 10.41L14.04 15.79L13.04 14.79L18.75 9L11.54 1.71Z" fill="#686868"/>
                  </svg>
                  <span *ngIf="!(profile.tags && profile.tags.length)">{{"Set Tags" | translate}}</span>
                  <div class="tags_wrp" *ngIf="profile.tags && profile.tags.length">
                      <ng-container *ngFor="let item of profile.tags; let last = last">
                          <span>{{item?.parameterValue?.value}}<ng-container *ngIf="!last">,&nbsp;</ng-container></span>
                      </ng-container>
                  </div>
              </div>
            </div>

            <div class="col_publisher">
              <div class="publishers">
                <ng-container *ngIf="!profile?.isGetChannelsToEmployee; else elseTemplatePublishers">
                  <p-skeleton width="5rem" styleClass="publisher"></p-skeleton>
                  <p-skeleton width="5rem" styleClass="publisher"></p-skeleton>
                  <p-skeleton width="5rem" styleClass="publisher"></p-skeleton>
                </ng-container>
                <ng-template #elseTemplatePublishers>
                  <ng-container *ngFor="let item of profile?.channelsToEmployee; let eInd = index;">
                    <div class="publisher" *ngIf="!!item.is_channel_content">
                      <app-user [class]="'user_div'" [employee]="getEmployee(item?.employee_id)"></app-user>
                      <div class="publisher_icon cp" (click)="openPublisherMenu($event, profile, item, eInd); $event.preventDefault(); $event.stopPropagation();">
                        <ng-container *ngIf="!!item.is_channel_content_upload; else elseTemplatePublIcon">
                          <svg width="21" height="16" viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.83301 15.2859C4.45422 15.2859 3.27634 14.7937 2.29937 13.8093C1.3224 12.825 0.833614 11.6218 0.833008 10.2C0.833008 8.9812 1.18907 7.89526 1.90119 6.94214C2.61331 5.98901 3.54513 5.37964 4.69664 5.11401C5.07543 3.67651 5.83301 2.51245 6.96937 1.62183C8.10574 0.731201 9.39361 0.285889 10.833 0.285889C12.6057 0.285889 14.1097 0.922764 15.3448 2.19651C16.58 3.47026 17.1973 5.02089 17.1966 6.84839C18.2421 6.97339 19.1097 7.43839 19.7994 8.24339C20.4891 9.04839 20.8336 9.98964 20.833 11.0671C20.833 12.239 20.4354 13.2353 19.6403 14.0559C18.8451 14.8765 17.8791 15.2865 16.7421 15.2859H5.83301Z" fill="#80B2FF"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.0241 9.79888C12.7713 10.2131 12.4063 10.5473 11.9714 10.7625C11.5365 10.9778 11.0494 11.0653 10.5667 11.015C10.0841 10.9647 9.62557 10.7786 9.24439 10.4782C8.8632 10.1779 8.57496 9.77568 8.4131 9.31819H7.51931C7.68331 9.95212 8.02573 10.5257 8.50592 10.9708C8.98611 11.416 9.58393 11.7141 10.2284 11.8297C10.8729 11.9453 11.5371 11.8736 12.1421 11.6231C12.747 11.3726 13.2675 10.9538 13.6416 10.4164L14.6816 11.4565V8.89054H12.1157L13.0241 9.79888ZM8.64104 7.12647C8.89391 6.71215 9.25905 6.37799 9.69411 6.16274C10.1292 5.94749 10.6163 5.85997 11.0991 5.91033C11.5819 5.96069 12.0405 6.14686 12.4218 6.44726C12.803 6.74767 13.0914 7.14999 13.2533 7.60758H14.1471C13.9831 6.97342 13.6407 6.3996 13.1604 5.95422C12.6801 5.50884 12.0821 5.2106 11.4374 5.0949C10.7927 4.97919 10.1283 5.05087 9.52312 5.30144C8.91793 5.55201 8.39732 5.97094 8.02308 6.50851L6.98388 5.46932V8.03523H9.5498L8.64104 7.12647Z" fill="#fff"/>
                          </svg>
                        </ng-container>
                        <ng-template #elseTemplatePublIcon>
                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="0.333008" y="0.285889" width="15" height="15" rx="7.5" fill="#65A2FF"/>
                            <path d="M10.9211 8.31039H11.0044V8.22706V7.34471V7.26137H10.9211H8.35737V4.69765V4.61431H8.27404H7.39169H7.30835V4.69765V7.26137H4.74463H4.6613V7.34471V8.22706V8.31039H4.74463H7.30835V10.8741V10.9575H7.39169H8.27404H8.35737V10.8741V8.31039H10.9211Z" fill="white" stroke="#F3F7FF" stroke-width="0.166667"/>
                          </svg>
                        </ng-template>
                      </div>
                    </div>
                                  
                  </ng-container>
                </ng-template>
              </div>
              <svg style="width: 29px; height: 28px; margin-left: 2.5px" *ngIf="profile?.isGetChannelsToEmployee" class="cp show_on_hover" (click)="openChannelToEmployee(profile)" width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg" #tooltip="matTooltip" matTooltip="{{ 'Add publisher' | translate }}" [matTooltipPosition]="'above'">
                <rect x="0.748535" width="28" height="28" rx="14" fill="#C8DEFF"/>
                <path d="M20.606 15.0466C20.9199 15.0466 21.1744 14.7921 21.1744 14.4782V13.5219C21.1744 13.2079 20.9199 12.9535 20.606 12.9535H15.7951V8.14254C15.7951 7.82863 15.5406 7.57415 15.2267 7.57415H14.2704C13.9564 7.57415 13.702 7.82863 13.702 8.14254V12.9535H8.89105C8.57713 12.9535 8.32266 13.2079 8.32266 13.5219V14.4782C8.32266 14.7921 8.57713 15.0466 8.89105 15.0466H13.702V19.8575C13.702 20.1714 13.9564 20.4259 14.2704 20.4259H15.2267C15.5406 20.4259 15.7951 20.1714 15.7951 19.8575V15.0466H20.606Z" fill="#3182E0" stroke="#3182E0" stroke-width="0.3"/>
              </svg>  
            </div>

            <div class="col_action">
              <mat-icon class="cp show_on_hover" *ngIf="!filter.get('partner_company_id').value" style="color: rgb(78, 7, 7);" (click)="openPlaylist(profile)">video_library</mat-icon>

              <div class="ml-5 cp d-f-c show_on_hover" style="width: 24px; height: 24px; justify-content: center;" [matMenuTriggerFor]="outletMenu">
                <svg style="width: 20px; height: 20px;" width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M13.9992 16.6217C13.3039 16.6217 12.637 16.3455 12.1453 15.8538C11.6537 15.3621 11.3774 14.6953 11.3774 13.9999C11.3774 13.3046 11.6537 12.6378 12.1453 12.1461C12.637 11.6544 13.3039 11.3782 13.9992 11.3782C14.6945 11.3782 15.3614 11.6544 15.8531 12.1461C16.3447 12.6378 16.621 13.3046 16.621 13.9999C16.621 14.6953 16.3447 15.3621 15.8531 15.8538C15.3614 16.3455 14.6945 16.6217 13.9992 16.6217Z" fill="#272727"/>
                  <path d="M12.1453 5.0103C12.637 5.50197 13.3039 5.77819 13.9992 5.77819C14.6945 5.77819 15.3614 5.50197 15.8531 5.0103C16.3447 4.51863 16.621 3.85177 16.621 3.15644C16.621 2.46111 16.3447 1.79425 15.8531 1.30258C15.3614 0.810903 14.6945 0.534683 13.9992 0.534683C13.3039 0.534683 12.637 0.810903 12.1453 1.30258C11.6537 1.79425 11.3774 2.46111 11.3774 3.15644C11.3774 3.85177 11.6537 4.51863 12.1453 5.0103Z" fill="#272727"/>
                  <path d="M12.1453 26.6973C12.637 27.189 13.3039 27.4652 13.9992 27.4652C14.6945 27.4652 15.3614 27.189 15.8531 26.6973C16.3447 26.2056 16.621 25.5388 16.621 24.8435C16.621 24.1481 16.3447 23.4813 15.8531 22.9896C15.3614 22.4979 14.6945 22.2217 13.9992 22.2217C13.3039 22.2217 12.637 22.4979 12.1453 22.9896C11.6537 23.4813 11.3774 24.1481 11.3774 24.8435C11.3774 25.5388 11.6537 26.2056 12.1453 26.6973Z" fill="#272727"/>
                </svg>
              </div>
              <mat-menu #outletMenu="matMenu" xPosition="after">
                  <button *ngIf="!filter.get('partner_company_id').value" mat-menu-item (click)="editProfile($event, profile, group)">
                      <mat-icon>edit</mat-icon>
                      <span>{{ "Edit" | translate }}</span>
                  </button>
  
                  <button *ngIf="!filter.get('partner_company_id').value" mat-menu-item (click)="openChannelToEmployee(profile)">
                      <mat-icon>people</mat-icon>
                      <span>{{ "Publishers" | translate }}</span>
                  </button>

                  <button *ngIf="!filter.get('partner_company_id').value && (company?.permissions.includes('owner') || !!user?.is_root || (company?.permissions.includes('manage_channel') && !profile?.channel_account_id) || (company?.permissions.includes('manage_channel') && !!profile?.channelAccount && !profile?.channelAccount?.is_active) || (company?.permissions.includes('manage_channel') && !profile?.channelAccount))" mat-menu-item (click)="connectWithYouTube(profile)">
                      <mat-icon>share</mat-icon>
                      <span>{{ "Connect" | translate }}</span>
                  </button>

                  <mat-divider></mat-divider>
                  <button *ngIf="!filter.get('partner_company_id').value" mat-menu-item (click)="deleteProfileCompany(profile?.id, i)">
                    <mat-icon>delete</mat-icon>
                    <span>{{ "Delete" | translate }}</span>
                </button>
              </mat-menu>
            </div>

          </div>
        </div>
    
        <div class="btns_line" *ngIf="!filter.get('partner_company_id').value">
            <a mat-raised-button (click)="goBack($event)">
                {{ "Back" | translate }}
            </a>
            <a class="ml-1" (click)="addProfile($event)" mat-raised-button color="primary">
                <mat-icon>add</mat-icon>
                {{ "Add publication outlet" | translate }}
            </a>
        </div>
  
  
    </div>
  </div>


  <ng-template #publisherMenu let-menuData>
    <section class="publisher_menu">
      <div (click)="closePublisherMenu(); plusFunc(menuData)" [ngStyle]="{'color': !!menuData?.empl?.is_channel_content ? '#65A2FF' : '#8D8D8D'}">
        <div class="icon_outer">
          <svg style="width: 16px; height: 16px;" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.333008" y="0.285889" width="15" height="15" rx="7.5" [ngStyle]="{'fill': !!menuData?.empl?.is_channel_content ? '#65A2FF' : '#8D8D8D'}"/>
            <path d="M10.9211 8.31039H11.0044V8.22706V7.34471V7.26137H10.9211H8.35737V4.69765V4.61431H8.27404H7.39169H7.30835V4.69765V7.26137H4.74463H4.6613V7.34471V8.22706V8.31039H4.74463H7.30835V10.8741V10.9575H7.39169H8.27404H8.35737V10.8741V8.31039H10.9211Z" fill="white" stroke="#F3F7FF" stroke-width="0.166667"/>
          </svg>
        </div>
        <span>{{ "Creating posts in Reports" | translate }}</span>
      </div>

      <div (click)="closePublisherMenu(); cloudFunc(menuData)" [ngStyle]="{'color': !!menuData?.empl?.is_channel_content_upload ? '#65A2FF' : '#8D8D8D'}">
        <div class="icon_outer">          
          <svg style="width: 21px; height: 16px;" width="21" height="16" viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.83301 15.2859C4.45422 15.2859 3.27634 14.7937 2.29937 13.8093C1.3224 12.825 0.833614 11.6218 0.833008 10.2C0.833008 8.9812 1.18907 7.89526 1.90119 6.94214C2.61331 5.98901 3.54513 5.37964 4.69664 5.11401C5.07543 3.67651 5.83301 2.51245 6.96937 1.62183C8.10574 0.731201 9.39361 0.285889 10.833 0.285889C12.6057 0.285889 14.1097 0.922764 15.3448 2.19651C16.58 3.47026 17.1973 5.02089 17.1966 6.84839C18.2421 6.97339 19.1097 7.43839 19.7994 8.24339C20.4891 9.04839 20.8336 9.98964 20.833 11.0671C20.833 12.239 20.4354 13.2353 19.6403 14.0559C18.8451 14.8765 17.8791 15.2865 16.7421 15.2859H5.83301Z" [ngStyle]="{'fill': !!menuData?.empl?.is_channel_content_upload ? '#65A2FF' : '#8D8D8D'}"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.0241 9.79888C12.7713 10.2131 12.4063 10.5473 11.9714 10.7625C11.5365 10.9778 11.0494 11.0653 10.5667 11.015C10.0841 10.9647 9.62557 10.7786 9.24439 10.4782C8.8632 10.1779 8.57496 9.77568 8.4131 9.31819H7.51931C7.68331 9.95212 8.02573 10.5257 8.50592 10.9708C8.98611 11.416 9.58393 11.7141 10.2284 11.8297C10.8729 11.9453 11.5371 11.8736 12.1421 11.6231C12.747 11.3726 13.2675 10.9538 13.6416 10.4164L14.6816 11.4565V8.89054H12.1157L13.0241 9.79888ZM8.64104 7.12647C8.89391 6.71215 9.25905 6.37799 9.69411 6.16274C10.1292 5.94749 10.6163 5.85997 11.0991 5.91033C11.5819 5.96069 12.0405 6.14686 12.4218 6.44726C12.803 6.74767 13.0914 7.14999 13.2533 7.60758H14.1471C13.9831 6.97342 13.6407 6.3996 13.1604 5.95422C12.6801 5.50884 12.0821 5.2106 11.4374 5.0949C10.7927 4.97919 10.1283 5.05087 9.52312 5.30144C8.91793 5.55201 8.39732 5.97094 8.02308 6.50851L6.98388 5.46932V8.03523H9.5498L8.64104 7.12647Z" fill="#F9FBFF"/>
          </svg>
        </div>
        <span>{{ "Upload and edit posts on platform" | translate }}</span>
      </div>

      <div (click)="closePublisherMenu(); deleteFunc(menuData)" style="color: #FF6969;">
        <div class="icon_outer">
          <mat-icon style="color: #FF6969;">delete</mat-icon> 
        </div>
        <span>{{ "Remove all Permissions" | translate }}</span>
      </div>
    </section>
  </ng-template>