import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseClass } from 'src/app/shared/models/base-class';
import { ParametersService } from 'src/app/shared/services/rest/parameters.service';

@Component({
  selector: 'app-new-paramenter-values',
  templateUrl: './new-paramenter-values.component.html',
  styleUrls: ['./new-paramenter-values.component.scss']
})
export class NewParamenterValuesComponent extends BaseClass implements OnInit {
  public form: FormGroup;
  public values: any;
  public isSubmit: boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<NewParamenterValuesComponent>,
    private parametersService: ParametersService,
    private fb: FormBuilder,
  ) { super() }

  ngOnInit(): void {
    console.log(this.data);
    this.getNewParameterValues();
  }

  getNewParameterValues() {
    this.attachSubscriptions(
      this.parametersService.getNewApiParameterValues(this.data.company_id, this.data.param.original_id).subscribe(resp => {
        this.values = resp
        console.log("getNewApiParameterValues", this.values)
      })  
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  submitForm() {
    this.isSubmit = true;
    this.attachSubscriptions(
      this.parametersService.importApiParameter({company_id: this.data.company_id, id: this.data.param.original_id}).subscribe(resp => {
        this.isSubmit = false;
        this.dialogRef.close({event: "add"})
      })
    )
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

}
