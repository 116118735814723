<mat-dialog-title class="dialog_title">
    <span>
        {{ "Edditing" | translate }} {{ data.chat.name }} {{ "connected chats" | translate }}:
    </span>
</mat-dialog-title>

<mat-dialog-content class="mat-typography">
    <div class="container form_wrapper" style="width: 100%; min-width: 100%">
        <form [formGroup]="form" class="form_with_pads form_with_valids">
            <div class="white_block">
                <div class="white_block_title">
                    {{ "Assign relations to other chat(s)" | translate }}
                </div>

                <mat-form-field appearance="standard" class="full_width_form">
                    <mat-label>{{ "Consist of" | translate }}</mat-label>

                    <div class="chips-boundary consist_boundary">
                        <mat-chip-list class="sorted_chips without_ordered" #consistOfChipList formControlName="consist_of_discussion_id">
                            <div class="chips_sorter_container" cdkDropListGroup #consistGroup>
                                
                                <div class="chips_drag_placeholder" cdkDropList (cdkDropListEntered)="entered($event, consistGroup, consistPlaceholder)" (cdkDropListDropped)="dropped(consistGroup, consistPlaceholder, form.get('consist_of_discussion_id').value)" #consistPlaceholder></div>

                                <div *ngFor="let disc of form.get('consist_of_discussion_id').value"  
                                cdkDropList
                                class="sorted_chips_drop_list"
                                (cdkDropListEntered)="entered($event, consistGroup, consistPlaceholder)"
                                (cdkDropListDropped)="dropped(consistGroup, consistPlaceholder, form.get('consist_of_discussion_id').value)">
                                    <div cdkDrag cdkDragBoundary=".consist_boundary" class="sorted_chip" (cdkDragReleased)="dragReleased(consistPlaceholder, consistDragPlaceholder)" (contextmenu)="openContext($event, {'disc': disc, 'form': form, 'control': 'consist_of_discussion_id'}); $event.preventDefault(); $event.stopPropagation();">
                                        <div class="sorted_chip_custom_placeholder" #consistDragPlaceholder *cdkDragPlaceholder></div>
                                        <mat-chip [removable]="true" color="primary" (removed)="onRemoved(disc, 'consist_of_discussion_id')">
                                            {{ disc.name }}
                                            <mat-icon matChipRemove>cancel</mat-icon>
                                        </mat-chip>
                                    </div>

                                </div>

                                <input class="auto_complete_input"
                                placeholder="{{ 'Search' | translate }}"
                                #consistOfInput
                                [formControl]="searchControl"
                                [matAutocomplete]="consistOfAuto"
                                [matChipInputFor]="consistOfChipList"
                                [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
                                
                            </div>
                        </mat-chip-list>
                    </div>

                    <mat-autocomplete autoActiveFirstOption #consistOfAuto="matAutocomplete" (optionsScroll)="onScrollChats()" (optionSelected)="selected($event, consistOfInput, 'consist_of_discussion_id')">
                        <ng-container *ngFor="let chat of chats$ | async">
                            <mat-option *ngIf="!form.get('consist_of_discussion_id').value.includes(chat.id)" [value]="chat">
                                <div class="task_ids_wrp_out">
                                    <div class="task_ids_wrp"> 
                                        <span class="text_one_line">
                                            {{ chat.name }}
                                        </span>
                                    </div>
                                </div>
                            </mat-option>
                        </ng-container>
                        
                    </mat-autocomplete>
                </mat-form-field>
                
                <mat-form-field appearance="standard" class="full_width_form">
                    <mat-label>{{ "Part of" | translate }}</mat-label>

                    <div class="chips-boundary part_boundary">
                        <mat-chip-list class="sorted_chips without_ordered" #partOfChipList formControlName="part_of_discussion_id">
                            <div class="chips_sorter_container" cdkDropListGroup #partGroup>
                                
                                <div class="chips_drag_placeholder" cdkDropList (cdkDropListEntered)="entered($event, partGroup, partPlaceholder)" (cdkDropListDropped)="dropped(partGroup, partPlaceholder, form.get('part_of_discussion_id').value)" #partPlaceholder></div>

                                <div *ngFor="let disc of form.get('part_of_discussion_id').value"  
                                cdkDropList
                                class="sorted_chips_drop_list"
                                (cdkDropListEntered)="entered($event, partGroup, partPlaceholder)"
                                (cdkDropListDropped)="dropped(partGroup, partPlaceholder, form.get('part_of_discussion_id').value)">
                                    <div cdkDrag cdkDragBoundary=".part_boundary" class="sorted_chip" (cdkDragReleased)="dragReleased(partPlaceholder, partDragPlaceholder)" (contextmenu)="openContext($event, {'disc': disc, 'form': form, 'control': 'part_of_discussion_id'}); $event.preventDefault(); $event.stopPropagation();">
                                    <div class="sorted_chip_custom_placeholder" #partDragPlaceholder *cdkDragPlaceholder></div>
                                    <mat-chip [removable]="true" color="primary" (removed)="onRemoved(disc, 'part_of_discussion_id')">
                                        {{ disc.name }}
                                        <mat-icon matChipRemove>cancel</mat-icon>
                                    </mat-chip>
                                    </div>

                                </div>

                                <input class="auto_complete_input"
                                placeholder="{{ 'Search' | translate }}"
                                #partOfInput
                                [formControl]="searchControl"
                                [matAutocomplete]="partOfAuto"
                                [matChipInputFor]="partOfChipList"
                                [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
                                
                            </div>
                        </mat-chip-list>
                    </div>

                    <mat-autocomplete autoActiveFirstOption #partOfAuto="matAutocomplete" (optionsScroll)="onScrollChats()" (optionSelected)="selected($event, partOfInput, 'part_of_discussion_id')">
                        <ng-container *ngFor="let chat of chats$ | async">
                            <mat-option *ngIf="!form.get('part_of_discussion_id').value.includes(chat.id)" [value]="chat">
                                <div class="task_ids_wrp_out">
                                    <div class="task_ids_wrp"> 
                                        <span class="text_one_line">
                                            {{ chat.name }}
                                        </span>
                                    </div>
                                </div>
                            </mat-option>
                        </ng-container>
                        
                    </mat-autocomplete>
                </mat-form-field>

                <mat-form-field appearance="standard" class="full_width_form">
                    <mat-label>{{ "Related" | translate }}</mat-label>

                    <div class="chips-boundary related_boundary">
                        <mat-chip-list class="sorted_chips without_ordered" #relatedOfChipList formControlName="related_discussion_id">
                            <div class="chips_sorter_container" cdkDropListGroup #relatedGroup>
                                
                                <div class="chips_drag_placeholder" cdkDropList (cdkDropListEntered)="entered($event, relatedGroup, relatedPlaceholder)" (cdkDropListDropped)="dropped(relatedGroup, relatedPlaceholder, form.get('related_discussion_id').value)" #relatedPlaceholder></div>

                                <div *ngFor="let disc of form.get('related_discussion_id').value"  
                                cdkDropList
                                class="sorted_chips_drop_list"
                                (cdkDropListEntered)="entered($event, relatedGroup, relatedPlaceholder)"
                                (cdkDropListDropped)="dropped(relatedGroup, relatedPlaceholder, form.get('related_discussion_id').value)">
                                    <div cdkDrag cdkDragBoundary=".related_boundary" class="sorted_chip" (cdkDragReleased)="dragReleased(relatedPlaceholder, relatedDragPlaceholder)" (contextmenu)="openContext($event, {disc: disc, 'form': form, 'control': 'related_discussion_id'}); $event.preventDefault(); $event.stopPropagation();">
                                        <div class="sorted_chip_custom_placeholder" #relatedDragPlaceholder *cdkDragPlaceholder></div>
                                        <mat-chip [removable]="true" color="primary" (removed)="onRemoved(disc, 'related_discussion_id')">
                                            {{ disc.name }}
                                            <mat-icon matChipRemove>cancel</mat-icon>
                                        </mat-chip>
                                    </div>

                                </div>

                                <input class="auto_complete_input"
                                placeholder="{{ 'Search' | translate }}"
                                #relatedOfInput
                                [formControl]="searchControl"
                                [matAutocomplete]="relatedOfAuto"
                                [matChipInputFor]="relatedOfChipList"
                                [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
                            </div>
                        </mat-chip-list>
                    </div>

                    <mat-autocomplete autoActiveFirstOption #relatedOfAuto="matAutocomplete" (optionsScroll)="onScrollChats()" (optionSelected)="selected($event, relatedOfInput, 'related_discussion_id')">
                        <ng-container *ngFor="let chat of chats$ | async">
                            <mat-option *ngIf="!form.get('related_discussion_id').value.includes(chat.id)" [value]="chat">
                                <div class="task_ids_wrp_out">
                                    <div class="task_ids_wrp"> 
                                        <span class="text_one_line">
                                            {{ chat.name }}
                                        </span>
                                    </div>
                                </div>
                            </mat-option>
                        </ng-container>
                        
                    </mat-autocomplete>
                </mat-form-field>

            </div>  
        </form>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <div class="f_w_p_btns">
        <button mat-button (click)="close()">
            {{ "Close" | translate }}
        </button>
        <button class="ml-1 d-f-btn" (click)="submitForm()" mat-raised-button cdkFocusInitial color="primary" [disabled]="!!isSubmit">
            <mat-spinner class="spin_16 mr-1" *ngIf="isSubmit"></mat-spinner>  
            {{ "Save" | translate }}
        </button>
        <!-- <div (click)="check()">
            check
        </div> -->
    </div>
</mat-dialog-actions>

<ng-template #contextMenu let-contextdata>
	<section class="context-menu">
        <div *ngIf="contextdata.control != 'consist_of_discussion_id'" (click)="moveTo(contextdata, 'consist_of_discussion_id')">
            <mat-icon class="mr-1">swipe_up_alt</mat-icon>
            {{ 'Move to Consist Of' | translate }}
        </div>
        <div *ngIf="contextdata.control != 'part_of_discussion_id'" (click)="moveTo(contextdata, 'part_of_discussion_id')">
            <mat-icon class="mr-1" *ngIf="contextdata.control == 'related_discussion_id'">swipe_up_alt</mat-icon>
            <mat-icon class="mr-1" *ngIf="contextdata.control == 'consist_of_discussion_id'">swipe_down_alt</mat-icon>
            {{ 'Move to Part Of' | translate }}
        </div>
        <div *ngIf="contextdata.control != 'related_discussion_id'" (click)="moveTo(contextdata, 'related_discussion_id')">
            <mat-icon class="mr-1">swipe_down_alt</mat-icon>
            {{ 'Move to Related' | translate }}
        </div>
        <div class="menu_divider"></div>
        <div (click)="openChat(contextdata)">
            <mat-icon class="mr-1">open_in_new</mat-icon>
            {{ 'Open Chat' | translate }}
        </div>
        <div class="menu_divider"></div>
        <div (click)="remove(contextdata)">
            <mat-icon class="mr-1">delete</mat-icon>
            {{ 'Remove' | translate }}
        </div>
	</section>
</ng-template>