<h2 mat-dialog-title class="dialog_title">
    <div>
        <div>{{ "Chats for work" | translate }}: {{ getColumnName(data?.work?.operation_id) }}</div>
        <div style="color: rgba(0, 0, 0, 0.5); font-weight: normal;">{{ data?.work?.comment }}</div>
    </div>
    <button mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h2>
<mat-dialog-content class="mat-typography">
    <div class="chats_header_wrp">
        <div class="chat_status_wrp">
            <ng-container *ngFor="let status of statuses">
                <button *ngIf="status.hasChats" mat-button (click)='changeActiveStatus(status.id, $event)' [ngClass]="{'active_status': activeStatus.includes(status.id)}">{{ status.name | translate }}</button>
            </ng-container>
        </div>
        <button type="button" mat-icon-button [matMenuTriggerFor]="sortBtn" class="mobActiveFilter" #tooltip="matTooltip" matTooltip="{{ 'Sort' | translate }}" [matTooltipPosition]="'above'">
            <mat-icon>sort</mat-icon>
        </button>
        <mat-menu #sortBtn="matMenu" class="sort_tablet" xPosition='before' overlayPanelClass='sortTAB'>
            <div class="mat-menu-title">{{"Sort By" | translate}}:</div>
            <mat-divider></mat-divider>
            <button mat-menu-item (click)="sort(sortValue == 'updated_desc' ? 'updated' : 'updated_desc')" [ngClass]="{'active_status': sortValue == 'updated_desc' || sortValue == 'updated'}">
                {{  "Updated" | translate }}
                <div class="d-f-c ml-1">
                    <mat-icon (click)="$event.preventDefault(); $event.stopPropagation(); sort('updated_desc')" [ngClass]="{'active': sortValue == 'updated_desc'}" style="margin-right: 0 !important;">arrow_drop_down</mat-icon>
                    <mat-icon (click)="$event.preventDefault(); $event.stopPropagation(); sort('updated')" [ngClass]="{'active': sortValue == 'updated'}" style="margin-right: 0 !important;">arrow_drop_up</mat-icon>
                </div>
            </button>
            <button mat-menu-item  (click)="sort(sortValue == 'priority_desc' ? 'priority' : 'priority_desc')" [ngClass]="{'active_status': sortValue == 'priority_desc' || sortValue == 'priority'}">
                {{  "Priority" | translate }}
                <div class="d-f-c ml-1">
                    <mat-icon (click)="$event.preventDefault(); $event.stopPropagation(); sort('priority_desc')" [ngClass]="{'active': sortValue == 'priority_desc'}" style="margin-right: 0 !important;">arrow_drop_down</mat-icon>
                    <mat-icon (click)="$event.preventDefault(); $event.stopPropagation(); sort('priority')" [ngClass]="{'active': sortValue == 'priority'}" style="margin-right: 0 !important;">arrow_drop_up</mat-icon>
                </div>
            </button>
            <button mat-menu-item  (click)="sort(sortValue == 'id_desc' ? 'id' : 'id_desc')" [ngClass]="{'active_status': sortValue == 'id_desc' || sortValue == 'id'}">
                {{  "ID" | translate }}
                <div class="d-f-c ml-1">
                    <mat-icon (click)="$event.preventDefault(); $event.stopPropagation(); sort('id_desc')" [ngClass]="{'active': sortValue == 'id_desc'}" style="margin-right: 0 !important;">arrow_drop_down</mat-icon>
                    <mat-icon (click)="$event.preventDefault(); $event.stopPropagation(); sort('id')" [ngClass]="{'active': sortValue == 'id'}" style="margin-right: 0 !important;">arrow_drop_up</mat-icon>
                </div>
            </button>
            <button mat-menu-item  (click)="sort(sortValue == 'filesize_desc' ? 'filesize' : 'filesize_desc')" [ngClass]="{'active_status': sortValue == 'filesize_desc' || sortValue == 'filesize'}">
                {{ "Filesize" | translate }}
                <div class="d-f-c ml-1">
                    <mat-icon (click)="$event.preventDefault(); $event.stopPropagation(); sort('filesize_desc')" [ngClass]="{'active': sortValue == 'filesize_desc'}" style="margin-right: 0 !important;">arrow_drop_down</mat-icon>
                    <mat-icon (click)="$event.preventDefault(); $event.stopPropagation(); sort('filesize')" [ngClass]="{'active': sortValue == 'filesize'}" style="margin-right: 0 !important;">arrow_drop_up</mat-icon>
                </div>
            </button>
        </mat-menu>
    </div>
    <div class="chats_wrp">
        <table mat-table [dataSource]="chats" class="main_table">
    
            <ng-container matColumnDef="Date">
                <th mat-header-cell *matHeaderCellDef> {{ "Date" | translate }} </th>
                <td mat-cell *matCellDef="let element" style="white-space: nowrap;">
                    <span
                    matTooltip="{{ element.updated_at*1000 | date:'h:mm a':'timeZone':activeLang }} "
                    [matTooltipPosition]="'left'">
                        {{ element.updated_at*1000 | date:'MMM d, y':'timeZone':activeLang | yearPipe }} 
                    </span>
                </td>
            </ng-container>
    
            <ng-container matColumnDef="Creator">
                <th mat-header-cell *matHeaderCellDef> {{ "Chat creator" | translate }} </th>
                <td mat-cell *matCellDef="let element"> 
                    <div class="user_div" *ngIf="!!element?.employee">
                        <ng-container *ngIf="!!element?.employee?.image; else elseOwnerTemplate">
                          <img [src]="element?.employee?.image" alt="">
                        </ng-container>
                        <ng-template #elseOwnerTemplate>
                            <mat-icon>account_circle</mat-icon>
                        </ng-template>
                        <span>{{element.employee.name}}</span>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="Name">
              <th mat-header-cell *matHeaderCellDef> {{ "Chat name" | translate }} </th>
              <td mat-cell *matCellDef="let element" (click)="goToChat(element)" style="cursor: pointer;">{{element.name}}</td>
            </ng-container>

            <ng-container matColumnDef="Preview User">
                <th mat-header-cell *matHeaderCellDef> {{ "User" | translate }} </th>
                <td mat-cell *matCellDef="let element" (click)="goToChat(element)" style="cursor: pointer;">
                    <ng-container *ngIf="element?.lastPost">

                    </ng-container>
                </td>
            </ng-container>

            <ng-container matColumnDef="Preview">
                <th mat-header-cell *matHeaderCellDef> {{ "Preview" | translate }} </th>
                <td mat-cell *matCellDef="let element" (click)="goToChat(element)" style="cursor: pointer;">

                    <div class="d-f-c">
                        <ng-container *ngIf="element?.lastPost">
                            <div class="user_div" *ngIf="!!element?.lastPost?.employee"   
                            matTooltip="{{element?.lastPost?.employee?.name}}"
                            [matTooltipPosition]="'left'">
                                <ng-container *ngIf="!!element?.lastPost?.employee?.image; else elseOwnerTemplate">
                                  <img [src]="element?.lastPost?.employee?.image" alt="">
                                </ng-container>
                                <ng-template #elseOwnerTemplate>
                                    <mat-icon>account_circle</mat-icon>
                                </ng-template>
                            </div>
                            <ng-container *ngIf="element?.lastPost?.text">
                                <ng-container *ngIf="big_monitor; else elseTemplatePostMORE">
                                    {{ element?.lastPost?.text.length > 180 ? (element?.lastPost?.text | slice:0:176) + " ..." : element?.lastPost?.text }}
                                </ng-container>
                                <ng-template #elseTemplatePostMORE>
                                    {{ element?.lastPost?.text.length > 90 ? (element?.lastPost?.text | slice:0:86) + " ..." : element?.lastPost?.text }}
                                </ng-template>
                            </ng-container>
                        </ng-container>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="Preview Date">
                <th mat-header-cell *matHeaderCellDef> {{ "Message date" | translate }} </th>
                <td mat-cell *matCellDef="let element" (click)="goToChat(element)" style="cursor: pointer; white-space: nowrap;">
                    <ng-container *ngIf="element?.lastPost">
                        {{ element?.lastPost.updated_at*1000 | date:'MMM d, y h:mm':'timeZone':activeLang | yearPipe }} 
                    </ng-container>
                </td>
            </ng-container>
    
            <ng-container matColumnDef="Status">
                <th mat-header-cell *matHeaderCellDef> {{ "Status" | translate }} </th>
                <td mat-cell *matCellDef="let element" style="cursor: pointer;" (dblclick)="dblEditChat($event, element, (data?.company?.permissions.includes('owner') || data.checkIsManager(data.task, data.company, data.user)) || (element?.status?.is_lock != 1))">
                    <div class="chat_status">
                        <div class="inside_status" [ngClass]="{
                            'first': element.status_id == '1',
                            'second': element.status_id == '2',
                            'third': element.status_id == '3',
                            'fours': element.status_id == '4',
                            'fives': element.status_id == '5',
                            'sixs': element.status_id == '6',
                            'nine': element.status_id == '97',
                            'seven': element.status_id == '98',
                            'eight': element.status_id == '99'}">
                            <span>{{ getChatStatus(element.status_id) | translate }}</span>  
                        </div>
                    </div>
                </td> 
            </ng-container>
            
            <ng-container matColumnDef="Actions">
                <th mat-header-cell *matHeaderCellDef> {{ "Actions" | translate }} </th>
                <td mat-cell *matCellDef="let element"> 
                    <button mat-icon-button [matMenuTriggerFor]="menuActions" aria-label="Example icon-button with a menu">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menuActions="matMenu">
                        <button mat-menu-item (click)="goToChat(element)">
                            <mat-icon>arrow_forward</mat-icon>
                            <span>{{ "Go to chat" | translate }}</span>
                        </button>
                        <button mat-menu-item ngxClipboard [cbContent]="origin + '/chat/' + element.acm" (cbOnSuccess)="copyLink('Chat')">
                            <mat-icon>link</mat-icon> 
                            <span>{{ "Copy chat url" | translate }}</span>
                        </button>
                        <button mat-menu-item (click)="editChat(element)">
                            <mat-icon>edit</mat-icon> 
                            <span>{{ "Edit" | translate }}</span>
                        </button>
                        <ng-container *ngIf="data?.company?.permissions.includes('owner') || data.checkIsManager(data.task, data.company, data.user)">
                            <button mat-menu-item (click)="additionalEmployees(data?.task, data?.work, element)">
                              <mat-icon>group</mat-icon> 
                              <span>{{ "Additional emplyees" | translate }}</span>
                            </button>
                            <button mat-menu-item (click)="addAdditionalEmplToWork(data?.task, data?.work, element)">
                              <mat-icon>person_add</mat-icon> 
                              <span>{{ "Appoint an additional emplyee" | translate }}</span>
                            </button>
                        </ng-container>
                        <ng-container *ngIf="data?.company?.permissions.includes('owner') || data?.company?.permissions?.includes('admin'); else elseTemplateOwnerDel">
                            <button mat-menu-item (click)="deleteChat(element)">
                                <mat-icon>delete</mat-icon>
                                <span>{{ "Delete" | translate }}</span>
                            </button>
                        </ng-container>
                        <ng-template #elseTemplateOwnerDel>
                            <div
                            matTooltip="{{ 'You do not have access for this action.' | translate }}"
                            [matTooltipPosition]="'left'">
                                <button mat-menu-item disabled>
                                    <mat-icon>delete</mat-icon>
                                    <span>{{ "Delete" | translate }}</span>
                                </button>
                            </div>
                        </ng-template>
                        
                      
                    </mat-menu>
                </td>
            </ng-container>
          
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'highlight_chat': row.is_read == '0'}"></tr>
        </table>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button (click)="close()">
        {{ "Close" | translate }}
    </button>
    <button class="ml-1" (click)="addChat()" mat-raised-button cdkFocusInitial color="primary" *ngIf="data.company && data.company?.permissions?.includes('create_discussion')">
        {{ "Add chat" | translate }}
    </button>
</mat-dialog-actions>