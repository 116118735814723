import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { AuthService } from 'src/app/shared/services/rest/auth.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { BaseClass } from 'src/app/shared/models/base-class';
import { TasksHaveSameUrlComponent } from 'src/app/shared/global_components/tasks-have-same-url/tasks-have-same-url.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { SameUrlTasksComponent } from '../same-url-tasks/same-url-tasks.component';
import { catchError, concatMap, debounceTime, distinctUntilChanged, filter, finalize, last, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { concat, forkJoin, from, fromEvent, of, ReplaySubject, Subscription } from 'rxjs';
import { MatSelect } from '@angular/material/select';
import { GlobalDataService } from 'src/app/shared/services/common/global-data.service';
import { environment } from 'src/environments/environment';
import { LoadingService } from 'src/app/shared/services/rest/loading.service';
import { RefreshService } from 'src/app/shared/services/rest/refresh.service';

@Component({
  selector: 'app-add-auto-lives',
  templateUrl: './add-auto-lives.component.html',
  styleUrls: ['./add-auto-lives.component.scss']
})


export class AddAutoLivesComponent extends BaseClass implements OnInit, OnDestroy {
  @ViewChild('profilesContainer') profilesContainer: MatSelect;
  public profiles: any[] = [];
  public pagination: any;
  public page: number = 1;
  public host: any = environment.host;
  public imgRoute: any = '';
  public user: any = {};

  public form: FormGroup;
  public company_id: any;
  public task_id: any;
  
  public isFormChange: boolean = false;
  public activeLang: any;

  public profilesMoreControl: FormControl = new FormControl();
  public profiles$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public isSubmit: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddAutoLivesComponent>,
    private sm: StorageManagerService,
    private auth: AuthService,
    private companyService: CompanyService,
    private fb: FormBuilder,
    private taskService: TaskService,
    private layoutService: LayoutService,
    private languageService: LanguageService,
    private refreshService: RefreshService,
    public ls: LoadingService
  ) { super() }

  ngOnInit(): void {

    console.log("AddAutoLivesComponent", this.data)
    window.scroll(0,0);
    this.company_id = this.data.company_id;
    this.task_id = this.data.task ? this.data.task.id : 0;

    this.form = this.fb.group({
      channel_id: ['', Validators.required],
      streams_count: 1
    })

    this.attachSubscriptions(
      this.form.valueChanges.subscribe(() => this.isFormChange = true)
    )

    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        if (this.isFormChange) {
          this.layoutService.openBottomSheet(this.dialogRef);
        } else {
          this.close();
        }
      })
    )

    this.attachSubscriptions(
      this.profilesMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchProfiles(resp))
    )


    this.getImgRoute();
    this.getCompany();
    this.getCsrf();
    this.getUser();
    this.getProfiles();
    this.getLangData();
  }


  onSearchProfiles(resp) {
    if (!this.profiles) {
      return;
    }

    if (!resp) {
      this.profiles$.next(this.profiles.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }

    // filter the banks
    this.profiles$.next(
      this.profiles.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  getProfile(id) {
    if (!this.profiles) {
      return false;
    }
    return this.profiles.find(el => el.id == id)
  }  

  getProfiles() {
    this.attachSubscriptions(
      this.companyService.getAllProfilesDyn('1', this.company_id, '1', {platform_id: 1}).pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }
          return forkJoin(arr.map(x => this.companyService.getAllProfilesDyn(x, this.company_id, '200', {platform_id: 1}).pipe(map(u => u.body)))).pipe(
            last(),
            tap(values => {
              let conValues = [].concat(...values)
              this.profiles = conValues;
              this.profiles$.next(this.profiles.slice());
            }),
          )
        }),
      ).subscribe(resp => {
        console.log("getProfiles resp", resp);
        console.log("getProfiles profiles", this.profiles);
      })
    )
  }

  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData().subscribe(resp => {
        this.activeLang = resp.active;
      })
    )
  }


  getCompany() {
    this.attachSubscriptions(
      this.companyService.getCompany(this.company_id).subscribe(resp => {
        this.companyService.company$.next(resp[0]);
      })
    )
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  getCsrf() {
    this.attachSubscriptions(
      this.auth.$userToken.subscribe(resp => {
        this.sm.localStorageSetItem("csrf_param", resp.csrf_param)
        this.sm.localStorageSetItem("csrf_token", resp.csrf_token)
      })
    )
  }

  getUser() {
    this.attachSubscriptions(
      this.auth.$user.subscribe(resp => {
        this.user = resp;
      })
    )
  }

  onInput(event: Event, key, min, max): void {
    const inputElement = event.target as HTMLInputElement;
    let value = Number(inputElement.value);
  
    // Если значение меньше min или не является числом, устанавливаем 60
    if (isNaN(value) || value < min) {
      value = min;
    }

    if (value > max) {
      value = max;
    }
  
    // Обновляем значение в форме
    this.form.get(key)?.setValue(value);
  }
  
  onSubmit() {
    this.isSubmit = true;
    let streamData = {
      "channel_id": this.form.value.channel_id,
      "content_description": "",
      "content_filename": "",
      "content_image": "",
      "content_name": "",
      "content_published_at": "",
      "content_status_id": 1,
      "content_type_id": 106,
      "content_url": "",
      "content_views_count": "",
      "external_content_id": "",
      "is_bravourl_content_views_count_update": 0,
      "is_content_views_count_update": 0,
      "publishing_params": {
        "is_api_set_up_new": 0,
        "is_backup": 0,
        "is_loop": 0,
        "is_random": 0,
        "live_stream_key": "",
        "live_stream_to": "rtmp://a.rtmp.youtube.com/live2/",
        "live_stream_to_backup": "rtmp://b.rtmp.youtube.com/live2/?backup=1",
        "live_stream_url": "rtmp://a.rtmp.youtube.com/live2",
        "live_stream_url_backup": "rtmp://b.rtmp.youtube.com/live2?backup=1",
        "random_start_count": 1
      },
      "task_id": this.task_id
    }
    let streamsArr = []
    for (let index = 0; index < this.form.value.streams_count; index++) {
      streamsArr.push(streamData);
    }

    from(streamsArr).pipe(
      mergeMap(x => this.taskService.addProfile(x, this.company_id).pipe(
        catchError(() => of([])),
      ), 10), // 10 запросов параллельно
      finalize(() => {
        // Этот блок выполнится только после обработки всех элементов
        console.log('Все запросы завершены');
        // Здесь можно дополнительно обработать card.is_ok_tags или другие поля
      }),
      last()
    ).subscribe(resp => {
      this.isSubmit = false;
      this.dialogRef.close({event: "Add", data: false});
    })
  }

  close() {
    this.dialogRef.close({event: "close", data: false});
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
