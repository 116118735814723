import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseClass } from 'src/app/shared/models/base-class';

@Component({
  selector: 'app-replacements-settings',
  templateUrl: './replacements-settings.component.html',
  styleUrls: ['./replacements-settings.component.scss']
})
export class ReplacementsSettingsComponent extends BaseClass implements OnInit, OnDestroy  {
  public page: number = 1;
  public pagination: any;
  public tasks: any[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ReplacementsSettingsComponent>
  ) { super() }

  ngOnInit(): void {
    console.log("ReplacementsSettingsComponent", this.data)
  }

  find() {
    this.dialogRef.close({event: "find"});
  }
  findAlt() {
    this.dialogRef.close({event: "alt_find"});
  }

  close() {
    this.dialogRef.close({event: "close", data: false});
  }

  ngOnDestroy(): void {
    this.clearSubscriptions();
  }
}
