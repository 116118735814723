import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseClass } from 'src/app/shared/models/base-class';

@Component({
  selector: 'app-drop-files-limit',
  templateUrl: './drop-files-limit.component.html',
  styleUrls: ['./drop-files-limit.component.scss']
})
export class DropFilesLimitComponent extends BaseClass implements OnInit, OnDestroy {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DropFilesLimitComponent>,
  ) { super() }

  ngOnInit(): void {
    console.log("DropFilesLimitComponent")
  }

  close() {
    this.dialogRef.close({event: "close", data: false});
  }

  upload() {
    this.dialogRef.close({event: "upload", data: false});
  }

  uploadFolder() {
    this.dialogRef.close({event: "upload_folder", data: false});
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
