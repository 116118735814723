import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { fromEvent, ReplaySubject, of, throwError, forkJoin } from 'rxjs';
import { debounceTime, last, map, retry, switchMap, tap } from 'rxjs/operators';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MY_NEW_FORMATS } from '../url-analytics/url-analytics.component';
import * as moment from 'moment';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { TasksHaveSameUrlComponent } from 'src/app/shared/global_components/tasks-have-same-url/tasks-have-same-url.component';
import { SameUrlTasksComponent } from 'src/app/components/atTasksDialog/same-url-tasks/same-url-tasks.component';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { NgxMatDateAdapter } from '@angular-material-components/datetime-picker';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { visitAll } from '@angular/compiler';
import { GlobalDataService } from 'src/app/shared/services/common/global-data.service';
import { environment } from 'src/environments/environment';
import { transformToWatchUrl } from 'src/app/shared/functions/liveUrlTransform';
@Component({
  selector: 'app-mob-edit-task-profile',
  templateUrl: './mob-edit-task-profile.component.html',
  styleUrls: ['./mob-edit-task-profile.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_NEW_FORMATS},
  ]
})
export class MobEditTaskProfileComponent extends BaseClass implements OnInit, OnDestroy {
  public form: FormGroup;
  public isFormChange: boolean = false;
  public isOpenOutlet: boolean = false;
  public contentImage: FormControl = new FormControl();

  public contentPagination: any;
  public contentPage: number = 1;

  public contentTypes: any[] = [];
  public contentTypeMoreControl: FormControl = new FormControl();
  public contentTypes$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public timeZone = - new Date().getTimezoneOffset();
  public selectedIndex: FormControl = new FormControl(0);
  public subFirstSelectedIndex: FormControl = new FormControl(0);

  public groups: any;
  public groupsControl: FormControl = new FormControl();
  public groups$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public isParseWork: boolean = false;
  public submited: boolean = false;
  public isSubmit: boolean = false;
  public is_system_status: boolean = false;
  public profiles: any[] = [];
  public pagination: any;
  public page: number = 1;
  public profileStatuses: any;

  public profilesFilter: FormGroup = this.fb.group({
    group_id: '',
    platform_id: ''
  });

  public profilesMoreControl: FormControl = new FormControl();
  public profiles$: ReplaySubject<any> = new ReplaySubject<any>(1);
  

  public hasErrorInYouTube: boolean = false;
  public company_id: any;
  public previewImgErrorCount: number = 0;
  public linkData: any;
  public activeLang: any;
  public task_id: any;

  public host: any = environment.host;
  public imgRoute: any = '';
  public outletFilter: FormGroup = this.fb.group({
    platform_id: '',
    group_id: ''
  })
  public disabledContentTypes: any = [];

  public platforms: any;
  public platforms$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public platformsMoreControl: FormControl = new FormControl();

  @ViewChild('mobDialogInside') mobDialogInside: HTMLElement;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<MobEditTaskProfileComponent>,
    private fb: FormBuilder,
    private layoutService: LayoutService,
    private taskService: TaskService,
    private bottomSheet: MatBottomSheet,
    private languageService: LanguageService,
    private companyService: CompanyService,
    private globalDataService: GlobalDataService,
    private _ngx_adapter: NgxMatDateAdapter<any>,
    private _adapter: DateAdapter<any>,
    private dialog: MatDialog,
    private sm: StorageManagerService
  ) { super() }

  ngOnInit(): void {

    console.log("MobEditTaskProfileComponent", this.data)
    this.company_id = this.data.company_id;
    this.task_id = this.data.task.id;
    if (this.data.profile && this.data.profile.contentStatus && this.data.profile.contentStatus.is_user == 0) {
      this.is_system_status = true
      this.selectedIndex.patchValue(3);
    }

    this.form = this.fb.group({
      task_id: this.task_id,
      channel_id: ['', Validators.required],
      content_url: ['', Validators.required],
      content_name: ['', Validators.required],
      is_content_views_count_update : 0,
      is_bravourl_content_views_count_update : 0,
      content_filename: '',
      content_image: '',
      content_type_id: ['', Validators.required],
      content_published_at: '',
      content_status_id: ['', Validators.required],
      content_views_count: '',
      date: this.fb.group({
        time: '',
        day: ''
      })
    })

    this.getImgRoute();
    this.getGroupsCompany();
    this.getLangData();
    this.getProfilesStatus();

    this.getPlatforms();
    this.getProfiles(this.company_id, this.page);

    this.attachSubscriptions(
      this.contentImage.valueChanges.subscribe(res => {
        this.previewImgErrorCount = 0
      })
    )
    
    this.attachSubscriptions(
      this.profilesMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchProfiles(resp))
    )

    this.attachSubscriptions(
      this.platformsMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchPlatforms(resp))
    )

    this.attachSubscriptions(
      this.groupsControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchGroups(resp))
    )

    this.attachSubscriptions(
      this.form.valueChanges.subscribe(() => this.isFormChange = true)
    )

    this.attachSubscriptions(
      this.form.get('date').valueChanges.subscribe(resp => {
        console.log(resp)
        let dateForm = {...this.form.get("date").value}
        if (!dateForm.day) {
          dateForm.day = moment(0).hour(0).minute(0)
        }
        let testTimeAndDate = dateForm.day.clone()
        testTimeAndDate.hour(Number(dateForm.time.split(":")[0]))
        testTimeAndDate.minute(Number(dateForm.time.split(":")[1]))
        this.form.patchValue({
          content_published_at: testTimeAndDate
        })
        console.log('this.form.value', this.form.value);
      })
    )

    this.attachSubscriptions(
      this.form.get("content_status_id").valueChanges.subscribe(resp => {
        if (this.data.is_edit) {          
          if ([101, 102, 202, 203, 301, 401, 404].includes(this.form.get('content_type_id').value) && this.form.get('content_status_id').value == 4 && !!this.form.get('content_url').value) {
            this.form.patchValue({
              is_content_views_count_update: true,
              is_bravourl_content_views_count_update: true,
            })
          } else {
            this.form.patchValue({
              is_content_views_count_update: false,
              is_bravourl_content_views_count_update: false,
            })
          }
        }
      })
    )

    this.attachSubscriptions(
      this.outletFilter.valueChanges.subscribe(() => {
        this.page = 1;
        this.pagination = undefined;
        this.getProfiles(this.company_id, this.page)
      })
    )

    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        if (this.isFormChange) {
          this.layoutService.openBottomSheet(this.dialogRef);
        } else {
          this.close();
        }
      })
    )

    this.attachSubscriptions(
      this.form.get("channel_id").valueChanges.subscribe(resp => {
        console.log('"channel_id" valueChanges', resp)
        if (!!resp && this.profiles.find(x => x.id == resp)) {
          if (!this.data.profile) {
            this.data.profile = {}
          }
          this.disabledContentTypes = []
          if (this.form.value.content_url) {
            if (this.form.value.content_url.indexOf("instagram.com") != -1 && this.form.value.content_url.indexOf("reel") != -1) {
              this.form.patchValue({
                content_type_id: 202
              })
            } else if (this.form.value.content_url.indexOf("facebook.com") != -1 && this.form.value.content_url.indexOf("reel") != -1) {
              this.form.patchValue({
                content_type_id: 404
              })
            } else if (this.form.value.content_url.indexOf("youtube.com") != -1 || this.form.value.content_url.indexOf("youtu.be") != -1) {
              if (this.form.value.content_url.indexOf("shorts") != -1) {
                this.form.patchValue({
                  content_type_id: 102
                })
              } else if (this.form.value.content_url.indexOf("community") != -1) {
                this.disabledContentTypes = [101, 102]
                if (this.disabledContentTypes.includes(this.form.value.content_type_id)) {
                  this.form.patchValue({
                    content_type_id: ''
                  })
                }
              }
            } else {
              this.form.patchValue({
                content_type_id: ''
              })
            }
          } else {
            this.form.patchValue({
              content_type_id: ''
            })
          }


          if (this.data.is_edit) {          
            if ([101, 102, 202, 203, 301, 401, 404].includes(this.form.get('content_type_id').value) && this.form.get('content_status_id').value == 4 && !!this.form.get('content_url').value) {
              this.form.patchValue({
                is_content_views_count_update: true,
                is_bravourl_content_views_count_update: true,
              })
            } else {
              this.form.patchValue({
                is_content_views_count_update: false,
                is_bravourl_content_views_count_update: false,
              })
            }
          }


          this.data.profile.channel = this.getProfileById(resp);
          let platform_id = this.profiles.find(x => x.id == resp).platform_id
          this.contentPage = 1;
          this.contentTypes = [];
          this.contentTypes$.next(this.contentTypes.slice());
          console.log("getContentTypes before")
          this.getContentTypes(platform_id, this.contentPage);
        }
      })
    )

    if (this.data.is_edit) {
      if (this.data.profile.content_url) {
        this.selectedIndex.patchValue(4);
      }
      if (!this.linkData) {
        this.linkData = {
          url: this.data.profile.content_url
        }
      }
      this.contentImage.patchValue(this.data.profile.content_image)

      console.log("this.linkDatathis.linkData", this.linkData)
      
      this.form.patchValue({
        task_id: this.task_id,
        channel_id: this.data.profile.channel_id,
        content_name: this.data.profile.content_name,
        content_filename: this.data.profile.content_filename,
        content_type_id: this.data.profile.content_type_id,
        content_image: this.data.profile.content_image,
        is_content_views_count_update: !!this.data.profile.is_content_views_count_update,
        is_bravourl_content_views_count_update: !!this.data.profile.is_bravourl_content_views_count_update,
        content_views_count: this.data.profile.content_views_count.toString(),
        content_url: this.data.profile.content_url,
        content_status_id: this.data.profile.content_status_id != 0 ? +this.data.profile.content_status_id : 1,
        content_published_at: this.data.profile.content_published_at != '0' ? moment(this.showUserDate(false, this.data.profile.content_published_at) * 1000) : '',
      })

      this.form.get('date').patchValue({
        day: this.data.profile.content_published_at ? moment(this.showUserDate(false, this.data.profile.content_published_at) * 1000).hour(0).minute(0) : '',
        time: this.data.profile.content_published_at ? (this.numberToTimeFormat(moment(this.showUserDate(false, this.data.profile.content_published_at) * 1000).hours()) + ":" + this.numberToTimeFormat(moment(this.showUserDate(false, this.data.profile.content_published_at) * 1000).minutes())) : '',
      })
      this.form.updateValueAndValidity()
      // time: "06:00",

      // this.form.get('date').patchValue({
      //   day: resp.published_at ? moment(resp.published_at*1000).hour(0).minute(0) : '',
      //   time: resp.published_at ? (this.numberToTimeFormat(moment(resp.published_at*1000).hours()) + ":" + this.numberToTimeFormat(moment(resp.published_at*1000).minutes())) : '',
      // })
    }

    this.attachSubscriptions(
      this.form.get("content_url").valueChanges.pipe(
        debounceTime(300)
      ).subscribe(resp => {

        if ([101, 102, 202, 203, 301, 401, 404].includes(this.form.get('content_type_id').value) && this.form.get('content_status_id').value == 4 && !!this.form.get('content_url').value) {
          this.form.patchValue({
            is_content_views_count_update: true,
            is_bravourl_content_views_count_update: true,
          })
        } else {
          this.form.patchValue({
            is_content_views_count_update: false,
            is_bravourl_content_views_count_update: false,
          })
        }

        if (!!resp) {
          this.getLinkData()
        }
      })
    )

  }

  selectType(type) {
    console.log(type);
    let id = !!type.id ? type.id : type.value
    if ([101, 102, 202, 203, 301, 401, 404].includes(id) && this.form.get('content_status_id').value == 4 && !!this.form.get('content_url').value) {
      this.form.patchValue({
        is_content_views_count_update: true,
        is_bravourl_content_views_count_update: true,
      })
    } else {
      this.form.patchValue({
        is_content_views_count_update: false,
        is_bravourl_content_views_count_update: false,
      })
    }
    this.form.patchValue({
      content_type_id: id
    })
  }

  selectStatus(status) {
    if (status.is_user == 0) {
      return
    }
    console.log(status);
    let id = !!status.id ? status.id : status.value
    if ([101, 102, 202, 203, 301, 401, 404].includes(this.form.get('content_type_id').value) && id == 4 && !!this.form.get('content_url').value) {
      this.form.patchValue({
        is_content_views_count_update: true,
        is_bravourl_content_views_count_update: true,
      })
    } else {
      this.form.patchValue({
        is_content_views_count_update: false,
        is_bravourl_content_views_count_update: false,
      })
    }
    this.form.patchValue({
      content_status_id: id
    })
  }

  getTimestamp(val) {
    if (!val) {
      return 0
    }

    return +moment(val).clone().format("X")
  }

  getProfilesStatus() {
    this.attachSubscriptions(
      this.globalDataService.getContentStatus().subscribe(resp => {
        console.log("getContentStatus", resp);
        this.profileStatuses = resp.slice();
      })
    )
    // this.attachSubscriptions(
    //   this.taskService.getProfileStatuses().subscribe(resp => {
    //     console.log("getProfilesStatus", resp)
    //     this.profileStatuses = resp;
    //   })
    // )
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }
  
  onSearchGroups(resp) {
    if (!this.groups) {
      return;
    }

    if (!resp) {
      this.groups$.next(this.groups.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }

    // filter the banks
    this.groups$.next(
      this.groups.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData().subscribe(resp => {
        this._ngx_adapter.setLocale(resp.active);
        this._adapter.setLocale(resp.active);
        this.activeLang = resp.active;
      })
    )
  }

  onSearchProfiles(resp) {
    if (!this.profiles) {
      return;
    }

    if (!resp) {
      this.profiles$.next(this.profiles.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }

    // filter the banks
    this.profiles$.next(
      this.profiles.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  onSearchPlatforms(resp) {
    if (!this.platforms) {
      return;
    }

    if (!resp) {
      this.platforms$.next(this.platforms.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }

    // filter the banks
    this.platforms$.next(
      this.platforms.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  getPlatforms() {
    this.attachSubscriptions(
      this.companyService.getGroupPlatforms().subscribe(resp => {
        this.platforms = resp;
        this.platforms$.next(this.platforms.slice());
      })
    )
  }

  getProfiles(company_id, page) {
    this.attachSubscriptions(
      this.companyService.getFilteredProfiles(company_id, page, this.outletFilter.value).pipe(
        tap(el => {
          this.pagination = {
            'pageCount': el.headers.get('x-pagination-page-count'),
            'perPage': el.headers.get('x-pagination-per-page'),
            'totalCount': el.headers.get('x-pagination-total-count'),
            'currentPage': el.headers.get('x-pagination-current-page'),
          }
          if (page == 1) {
            this.profiles = []
          }
        }),
        map(el => el.body)
      ).subscribe(resp => {
        console.log("getProfiles", resp)
        if (page == 1) {
          this.profiles = resp
        } else {
          this.profiles.push(...resp);
        }
        this.profiles$.next(this.profiles.slice())

        this.page = this.page + 1;
        if (!!this.data.is_edit && !!this.data.profile.channel_id && this.profiles.find(x => x.id == this.data.profile.channel_id)) {
          let platform_id = this.profiles.find(x => x.id == this.data.profile.channel_id).platform_id
          this.contentPage = 1;
          this.contentTypes = [];
          this.contentTypes$.next(this.contentTypes.slice());
          this.getContentTypes(platform_id, this.contentPage);
        }
      })
    )
  }

  close() {
    this.dialogRef.removePanelClass('animate__slideInUp')
    this.dialogRef.addPanelClass('animate__slideOutDown')
    setTimeout(()=>{this.dialogRef.close({event: "close", data: false});}, 300);
  }

  goToEditLink() {
    this.selectedIndex.patchValue(0);
  }
  
  goToEditBasic() {
    this.selectedIndex.patchValue(1);
  }

  openOutlet() {
    // this.dialogRef.removePanelClass('animate__slideInUp')
    this.isOpenOutlet = !this.isOpenOutlet;
    this.selectedIndex.patchValue(0);
    this.subFirstSelectedIndex.patchValue(1);
  }

  backSubFirst() {
    this.isOpenOutlet = false;
    this.subFirstSelectedIndex.patchValue(this.subFirstSelectedIndex.value - 1);
  }

  selectOutlet(el) {
    this.form.patchValue({
      channel_id: el.id
    })

    this.outletFilter.patchValue({
      group_id: '',
      platform_id: ''
    })
    this.profilesMoreControl.patchValue("")
    this.platformsMoreControl.patchValue("")

    this.isOpenOutlet = false;
    this.subFirstSelectedIndex.patchValue(this.subFirstSelectedIndex.value - 1);
  }

  getGroupsCompany() {
    this.attachSubscriptions(
      this.companyService.getInfiniteGroupsCompany(this.company_id, '1', '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.companyService.getInfiniteGroupsCompany(this.company_id, x).pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        this.groups = [].concat(...res);
        this.groups$.next(this.groups.slice());
      })
    )
  }

  getPlatformById(id) {
    if (!this.platforms || this.platforms.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.platforms.find(el => el.id == id)
  }

  getProfileById(id) {
    if (!this.profiles || this.profiles.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.profiles.find(el => el.id == id)
  }
  
  changeIndex(e) {
    this.selectedIndex.patchValue(e);
  }

  back() {
    this.selectedIndex.patchValue(this.selectedIndex.value - 1)
  }

  changedTime(e) {
    console.log("changedTimechangedTimechangedTimechangedTimechangedTimechangedTimechangedTime",e)
  }

  next() {
    this.selectedIndex.patchValue(this.selectedIndex.value + 1)
  }

  onPreviewImgError(event) {
    console.log("onPreviewImgError", this.linkData, this.contentImage.value)
    if (!!this.contentImage.value && this.previewImgErrorCount < 3) {
      this.previewImgErrorCount++
      if (event.target.src.indexOf("/api/get-url-data/proxy/") != -1 && this.data.profile && this.data.profile.content_image_file_id) {
        event.target.src = this.host + this.data.profile.file.original;
      } else {
        event.target.src = this.host + '/api/get-url-data/proxy/?company_id=' + this.data.company.id + (this.data.profile.company_id != this.data.company.id ? ('&partner_company_id=' + this.data.profile.company_id) : '') + '&url=' + encodeURIComponent(this.contentImage.value)
      }
    } else {
      return
    }
  }

  next1St() {
    
    if (!this.form.value.channel_id || !this.form.value.content_url) {
      this.submited = true;
      this.layoutService.showSnackBar({name: ''}, marker("You need to fill in all required fields"), SnackBarItem)
      return false
    }

    this.submited = false;
    this.selectedIndex.patchValue(this.selectedIndex.value + 1)
  }

  next2St() {
    
    if (!this.form.value.content_name) {
      this.submited = true;
      this.layoutService.showSnackBar({name: ''}, marker("You need to fill in all required fields"), SnackBarItem)
      return false
    }

    this.submited = false;
    this.selectedIndex.patchValue(this.selectedIndex.value + 1)
  }

  next3St() {
    if (!this.form.value.content_type_id) {
      this.submited = true;
      this.layoutService.showSnackBar({name: ''}, marker("You need to fill in all required fields"), SnackBarItem)
      return false
    }

    this.submited = false;
    this.selectedIndex.patchValue(this.selectedIndex.value + 1)
  }

  next4St() {
    if (!!this.is_system_status) {
      this.attachSubscriptions(
        this.taskService.editProfile(this.data.profile.id, {content_status_id: this.form.value.content_status_id}, this.company_id).subscribe(
          resp => {
            this.dialogRef.removePanelClass('animate__slideInUp')
            this.dialogRef.addPanelClass('animate__slideOutDown')
            setTimeout(()=>{this.dialogRef.close({event: "edit", data: resp})}, 300);
            this.isSubmit = false;
          }, 
          error => {
            this.isSubmit = false;
            this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
          }
        )
      )

      return
    }

    if (!this.form.value.content_status_id) {
      this.submited = true;
      this.layoutService.showSnackBar({name: ''}, marker("You need to fill in all required fields"), SnackBarItem)
      return false
    }

    this.submited = false;
    this.selectedIndex.patchValue(this.selectedIndex.value + 1)
  }

  onSubmit() {
    // let testForm = {...this.form.value};
    // let dateForm = {...this.form.get("date").value}
    // // Number(value.timeInFormat.split(":")[0])*3600 + Number(value.timeInFormat.split(":")[1])*60,
    // console.log('dateForm', dateForm);
    // let testTimeAndDate = dateForm.day.clone()
    // testTimeAndDate.hour(Number(dateForm.time.split(":")[0]))
    // testTimeAndDate.minute(Number(dateForm.time.split(":")[1]))
    // console.log('testTimeAndDate', testTimeAndDate);
    // console.log('testForm', testForm);
    // console.log('this.form.value', this.form.value);
    // return

    if (!this.form.valid) {
      this.submited = true;
      this.layoutService.showSnackBar({name: ''}, marker("You need to fill in all required fields"), SnackBarItem)
      return
    }

    this.submited = false;
    this.isSubmit = true;


    let form = {...this.form.value};
    let timeAndDate = form.content_published_at.clone().set({'second': 0, 'millisecond': 0})

    const utcOffset = moment.tz(timeAndDate, this.data.company.timezone).utcOffset();
    console.log(utcOffset);

    
    let utcTimestamp = moment(timeAndDate).format("X")
    console.log(utcTimestamp);
    
    let difference = (Number(this.timeZone) - Number(utcOffset))*60
    console.log("difference", difference);
    
    let workSpaceTimeZone = Number(utcTimestamp) + Number(difference)
    console.log("workSpaceTimeZone", workSpaceTimeZone);

    let sbmtData = {...form}

    sbmtData.content_published_at = workSpaceTimeZone

    sbmtData.is_content_views_count_update = !!sbmtData.is_content_views_count_update ? 1 : 0;
    sbmtData.is_bravourl_content_views_count_update = !!sbmtData.is_bravourl_content_views_count_update ? 1 : 0;

    delete sbmtData.date

    if (this.data.is_edit) {
      this.attachSubscriptions(
        this.taskService.editProfile(this.data.profile.id, sbmtData, this.company_id).subscribe(
          resp => {
            this.dialogRef.removePanelClass('animate__slideInUp')
            this.dialogRef.addPanelClass('animate__slideOutDown')
            setTimeout(()=>{this.dialogRef.close({event: "edit", data: resp})}, 300);
            this.isSubmit = false;
          }, 
          error => {
            this.isSubmit = false;
            this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
          }
        )
      )
    } else {
      this.attachSubscriptions(
        this.taskService.addProfile(sbmtData, this.company_id).subscribe(resp => {
          this.dialogRef.removePanelClass('animate__slideInUp')
          this.dialogRef.addPanelClass('animate__slideOutDown')
          setTimeout(()=>{this.dialogRef.close({event: "Add", data: resp})}, 300);
          this.isSubmit = false;
        },
        error => {
          this.isSubmit = false;
          this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
        })
      )
    }
  }

  getContentTypes(platform_id, contentPage) {
    console.log("getContentTypes")
    this.attachSubscriptions(
      this.companyService.getContentTypes(platform_id, contentPage).pipe(
        tap(el => {
          this.contentPagination = {
            'pageCount': el.headers.get('x-pagination-page-count'),
            'perPage': el.headers.get('x-pagination-per-page'),
            'totalCount': el.headers.get('x-pagination-total-count'),
            'currentPage': el.headers.get('x-pagination-current-page'),
          }
        }),
        map(el => el.body)
      ).subscribe(resp => {
        this.contentTypes.push(...resp);
        this.contentTypes$.next(this.contentTypes.slice());
        this.contentPage = this.contentPage + 1;
        console.log("getContentTypes", this.contentPage, this.contentTypes)

        if (this.contentPagination.pageCount >= this.contentPage) {
          this.getContentTypes(platform_id, this.contentPage);
        }
      })
    )
  }

  getLinkData() {
    // stepper: MatStepper
    if (this.isParseWork) {
      this.layoutService.showSnackBar({name: ""}, marker("Data is being received. Wait a moment, please."), SnackBarItem)
      return false;
    }

    this.disabledContentTypes = [];
    this.isParseWork = true;
 
    if (this.form.value.content_url) {
      if (this.form.value.content_url.indexOf("instagram.com") != -1 && this.form.value.content_url.indexOf("reel") != -1) {
        this.form.patchValue({
          content_type_id: 202
        })
      } else if (this.form.value.content_url.indexOf("facebook.com") != -1 && this.form.value.content_url.indexOf("reel") != -1) {
        this.form.patchValue({
          content_type_id: 404
        })
      } else if (this.form.value.content_url.indexOf("youtube.com") != -1 || this.form.value.content_url.indexOf("youtu.be") != -1) {
        if (this.form.value.content_url.indexOf("shorts") != -1) {
          this.form.patchValue({
            content_type_id: 102
          })
        } else if (this.form.value.content_url.indexOf("community") != -1) {
          this.disabledContentTypes = [101, 102]
          if (this.disabledContentTypes.includes(this.form.value.content_type_id)) {
            this.form.patchValue({
              content_type_id: ''
            })
          }
        }
      } else {
        this.form.patchValue({
          content_type_id: this.data.profile && this.data.profile.content_type_id ? this.data.profile.content_type_id : ''
        })
      }
    }

    this.attachSubscriptions(
      this.taskService.getSmartLinkData(this.form.value.content_url, this.data.profile && this.data.profile.channel_id ? this.data.profile.channel_id : 0, this.company_id, (this.data.task.company_id != this.company_id ? this.data.task.company_id : null)).subscribe(resp => {
        console.log(resp)
        this.hasErrorInYouTube = false;
        console.log("getLinkData############### ------", resp)
        this.linkData = resp
        if (resp.tasks && resp.tasks.length > 0 && (this.data.task && this.data.task.channels && this.data.profile && this.data.task.channels.find(x => x.id == this.data.profile.id).content_url != this.form.value.content_url)) {

          let sameUrl =  this.bottomSheet.open(TasksHaveSameUrlComponent, {
            data: {
              tasks: resp.tasks
            }
          });

          sameUrl.afterDismissed().subscribe( data => {
            if (data && data.message == 'close') {
              console.log("close");
            } if (data && data.message == 'continue') {
              const dialogRef = this.dialog.open(SameUrlTasksComponent, {
                data: {
                  company: this.data.company,
                  tasks: resp.tasks,
                  resp: resp
                }
              });
          
              // this.attachSubscriptions(
              //   dialogRef.afterClosed().subscribe(result => {
              //   })
              // )
            }
          });
          
        }

        this.form.patchValue({
          content_name: (resp.parsers && resp.parsers.includes("InstagramParser")) || (resp.url && resp.url.indexOf("instagram.com") != -1) ? (resp.description ? resp.description : 'Post has no text') : (resp.name ? resp.name : 'Post has no text'),
          // content_published_at: moment(resp.published_at*1000),
          external_content_id: resp.external_content_id,
          content_filename: !!resp.filename ? resp.filename : '',
          content_image: resp.image,
          content_views_count: !!resp.views_count ? resp.views_count : '0' 
        })

        if (resp.published_at && this.data.profile) {
      
          if (moment(resp.published_at * 1000).isAfter(moment())) {
            if (this.data.profile.content_status_id <= 2) {
              this.form.patchValue({
                content_status_id: 3
              })
            }
          } else {
            if (this.data.profile.content_status_id <= 3) {
              this.form.patchValue({
                content_status_id: 4
              })
            }
          }
        }
        
        this.form.get('date').patchValue({
          day: resp.published_at ? moment(this.showUserDate(false, resp.published_at) * 1000).hour(0).minute(0) : '',
          time: resp.published_at ? (this.numberToTimeFormat(moment(this.showUserDate(false, resp.published_at) * 1000).hours()) + ":" + this.numberToTimeFormat(moment(this.showUserDate(false, resp.published_at) * 1000).minutes())) : '',
        })
        
        this.contentImage.patchValue(this.form.get('content_image').value)

        if (!resp.external_content_id) {
          if (!!resp.profile_url && this.profiles.find(el => el.url.replace("https://","").replace("http://","") == resp.profile_url.replace("https://","").replace("http://",""))) {
            let targetProfileId = this.profiles.find(el => el.url.replace("https://","").replace("http://","") == resp.profile_url.replace("https://","").replace("http://","")).id
            if (this.form.value.channel_id != targetProfileId) {
              this.form.patchValue({
                channel_id: targetProfileId
              })
            }
          } else {
            this.layoutService.showSnackBar({name: resp.profile_url}, marker("The profile for this link was not found in the system. Add it to Reports and the profile will be auto-detected."), SnackBarItem)
          }
        }

        if (resp.url) {
          if (resp.url.indexOf("instagram.com") != -1 && resp.url.indexOf("reel") != -1) {
            this.form.patchValue({
              content_type_id: 202
            })
          } else if (resp.url.indexOf("facebook.com") != -1 && resp.url.indexOf("reel") != -1) {
            this.form.patchValue({
              content_type_id: 404
            })
          } else if (resp.url.indexOf("youtube.com") != -1 || resp.url.indexOf("youtu.be") != -1) {
            if (resp.url.indexOf("shorts") != -1) {
              this.form.patchValue({
                content_type_id: 102
              })
            } else if (resp.url.indexOf("community") != -1) {
              this.disabledContentTypes = [101, 102]
              if (this.disabledContentTypes.includes(this.form.value.content_type_id)) {
                this.form.patchValue({
                  content_type_id: ''
                })
              }
            }
          }
        }
        this.isParseWork = false;
      })
    )
  }
  
  onInputUrl(originalUrl: string): void {
    console.log('originalUrl', originalUrl);
    let transformedUrl = transformToWatchUrl(originalUrl);
    
    if (transformedUrl != originalUrl) {
      this.form.patchValue({
        content_url: transformedUrl
      })
    }
    console.log("transformedUrl", transformedUrl);
  }
  
  showError() {
    this.layoutService.showSnackBar({name: ''}, marker("Try again later"), SnackBarItem)
  }

  showUserDate(date:any = false, timestamp:any = false) {
    let timeAndDate;
    if (date) {
      timeAndDate = moment(date).clone()
    } else {
      timeAndDate = moment(timestamp*1000).clone()
    }

    const utcOffset = moment.tz(timeAndDate, this.data.company.timezone).utcOffset();
    
    let utcTimestamp = moment(timeAndDate).format("X")
    
    let difference = (Number(this.timeZone) - Number(utcOffset))*60
    // console.log(difference);
    
    let workSpaceTimeZone;
    if (date) {
      workSpaceTimeZone = Number(utcTimestamp) + Number(difference)
    } else {
      workSpaceTimeZone = Number(utcTimestamp) - Number(difference)
    }
    return workSpaceTimeZone
  }

  numberToTimeFormat(val) {
    if (Number(val) < 10) {
      val = "0" + val
    }
    return val
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
