<div mat-dialog-title class="mobile_interface_title">
    <div class="mobile_interface_title_in" style="overflow: hidden;">
        <div class="tab_title" (click)="back()">
            <div class="d-f-c">
                <div class="icon_back">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7 12L6.11612 11.1161C5.62796 11.6043 5.62796 12.3957 6.11612 12.8839L7 12ZM16.8839 3.88388C17.372 3.39573 17.372 2.60427 16.8839 2.11612C16.3957 1.62796 15.6043 1.62796 15.1161 2.11612L16.8839 3.88388ZM15.1161 21.8839C15.6043 22.372 16.3957 22.372 16.8839 21.8839C17.372 21.3957 17.372 20.6043 16.8839 20.1161L15.1161 21.8839ZM7.88388 12.8839L16.8839 3.88388L15.1161 2.11612L6.11612 11.1161L7.88388 12.8839ZM6.11612 12.8839L15.1161 21.8839L16.8839 20.1161L7.88388 11.1161L6.11612 12.8839Z" fill="#232323"/>
                    </svg>
                </div>
                <span>
                    {{ "Edit Job" | translate }}
                </span>
                <span class="small_title with_i" style="text-transform: uppercase;">{{ "Completed At" | translate }}</span>
            </div>
        </div>
    </div>
</div>
<mat-dialog-content class="mobile_interface_content">
    <div class="mobile_scrollable_int_big mob_job_edit_completed" style="background: #fff;">
        <!-- {{form.get('completed_at').value}} \ {{formatX(this.data.work.completed_at * 1000)}} \ {{formatX(form.get('completed_at').value)}} -->

        <form [formGroup]="form" class="has_pads form_with_valids">
            <div class="value">
                <ng-container *ngIf="!!form.get('completed_at').value">
                    {{ formatX(form.get('completed_at').value * 1000) | date:'MMM dd. Y':'':activeLang }}
                </ng-container>
            </div>
            <mat-calendar (selectedChange)="changeDate($event)" [selected]="form.get('completed_at').value"></mat-calendar>
            <!-- <input #dateInput type="date" placeholder="dd.mm.yyyy" class="cst_mobile_datepicker" formControlName="completed_at"> -->
        </form>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <div class="mobile_interface_buttons small_mob_btns" style="justify-content: space-between !important;">
        <button class="mobile_interface_btn" (click)="close()">
            {{ "Cancel" | translate }}
        </button>
        
        <button class="mobile_interface_btn mobile_interface_btn_blue" (click)="onSubmit()">
            {{ "Save" | translate }}
        </button>
    </div>
</mat-dialog-actions>