<mat-dialog-title class="dialog_title">
    <span (click)="log()">
        {{ "Saving a filter to Presets" | translate }}
    </span>
</mat-dialog-title>

<mat-dialog-content class="mat-typography">
    <div class="container form_wrapper bookmarks">

        <form [formGroup]="form" class="form_with_pads form_with_valids">
            <div class="white_block">
                <div class="white_block_title">
                    {{ "Preset details" | translate }}
                </div>

                <mat-form-field appearance="standard" class="full_width_form">
                    <ng-container *ngIf="!form.get('name').valid && submited">
                        <div class="req_field"></div>
                        <div class="req_label">
                            <div class="angle"></div>
                            <span>
                                {{ "You need to write here" | translate }}
                            </span>
                        </div>
                    </ng-container>
                    <mat-label>{{ 'Preset title' | translate }}</mat-label>
                    <input formControlName="name" matInput placeholder="{{ 'Name' | translate }}">
                </mat-form-field>
        
                <mat-form-field appearance="standard" class="full_width_form">
                    <mat-label>{{ 'Order' | translate }}</mat-label>
                    <input formControlName="order" matInput required type="number" placeholder="{{ 'Order' | translate }}">
                </mat-form-field>

            </div>

            <div class="white_block">
                <div class="white_block_title">
                    {{ "Save for" | translate }}
                </div>

                <mat-tab-group [selectedIndex]="activeIndex" (selectedIndexChange)="changeTab($event)">

                    <mat-tab>
                        <ng-template mat-tab-label>
                            <mat-icon class="example-tab-icon" *ngIf="activeIndex != 0">radio_button_unchecked</mat-icon>
                            <mat-icon class="example-tab-icon"  *ngIf="activeIndex == 0">radio_button_checked</mat-icon>
                            {{ "Private" | translate }}
                        </ng-template>

                        <!-- <p>{{ "This bookmark is just for me" | translate }}</p> -->
                        <div>
                            <mat-checkbox [formControl]="is_active">{{ "Is Active" | translate }}</mat-checkbox>
                        </div>

                        <div>
                            <mat-checkbox [formControl]="is_shortcut">{{ "Is Shortcut" | translate }}</mat-checkbox>
                        </div>
                    </mat-tab>
                    <mat-tab *ngIf="data?.company?.permissions.includes('owner') || data?.company?.permissions.includes('admin') || data?.company?.permissions.includes('manager')">
                        <ng-template mat-tab-label>
                            <mat-icon class="example-tab-icon" *ngIf="activeIndex != 1">radio_button_unchecked</mat-icon>
                            <mat-icon class="example-tab-icon"  *ngIf="activeIndex == 1">radio_button_checked</mat-icon>
                            {{ "Shared with" | translate }}
                        </ng-template>

                        <ng-container *ngIf="activeIndex == 1">
                            <div class="users_select" [ngClass]="{'req_value': !form.get('employee_id').valid}">
                                <label>{{ "Choose an employee" | translate }}: </label>
                                <mat-chip-list *ngIf="!switched" [multiple]="true" selectable formControlName="employee_id">
                                    <ng-container *ngFor="let employee of data.employees; let i = index">
                                        <mat-chip class="chip_user" (click)="toggleChip(employee)" [value]="employee.id">
                                            <app-user [class]="'user_div'" [employee]="employee"></app-user>
                                            <div class="settings_employee" *ngIf="form.get('employee_id').value.includes(employee.id)">
                                                <button mat-icon-button class="empl_book_set" (click)="$event.preventDefault(); $event.stopPropagation(); editEmployeeBookmark(employee, 'is_active')">
                                                    <ng-container *ngIf="employee.is_active == 1; else elseTemplateIsActive">
                                                        <mat-icon>visibility</mat-icon>
                                                    </ng-container>
                                                    <ng-template #elseTemplateIsActive>
                                                        <mat-icon>visibility_off</mat-icon>
                                                    </ng-template>
                                                </button>
                                                <button mat-icon-button class="empl_book_set" (click)="$event.preventDefault(); $event.stopPropagation(); editEmployeeBookmark(employee, 'is_shortcut')">
                                                    <ng-container *ngIf="employee.is_shortcut == 1; else elseTemplateIsShortcut">
                                                        <mat-icon>star</mat-icon>
                                                    </ng-container>
                                                    <ng-template #elseTemplateIsShortcut>
                                                        <mat-icon>star_border</mat-icon>
                                                    </ng-template>
                                                </button>
                                            </div>
                                        </mat-chip>
                                        <mat-chip *ngIf="i == data.employees.length - 1 && i > 6" class="chip_user more" (click)="employeeIdContainer.open()">
                                            {{ "more" | translate }}
                                        </mat-chip>
                                    </ng-container>
                                </mat-chip-list>
                            </div>
                
                            <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                                <mat-select [multiple]="true" #employeeIdContainer formControlName="employee_id" (selectionChange)="toggleChip($event)">
                                    <mat-option>
                                        <ngx-mat-select-search 
                                        placeholderLabel="{{ 'Search' | translate }}" 
                                        noEntriesFoundLabel="{{ 'no matching employee found' | translate }}"
                                        [formControl]="employeeMoreControl"></ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option *ngFor="let employee of employees$ | async" [value]="employee.id">
                                        <app-user [class]="'user_div'" [employee]="employee"></app-user>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </ng-container>
                    </mat-tab>

                    <mat-tab *ngIf="data?.company?.permissions.includes('owner') || data?.company?.permissions.includes('admin') || data?.company?.permissions.includes('manager')">
                        <ng-template mat-tab-label>
                            <mat-icon class="example-tab-icon" *ngIf="activeIndex != 2">radio_button_unchecked</mat-icon>
                            <mat-icon class="example-tab-icon"  *ngIf="activeIndex == 2">radio_button_checked</mat-icon>
                            {{ "Shared with All" | translate }}
                        </ng-template>

                        <p>{{ "This bookmark can be seen and used by all workspace members" | translate }}</p>
                    </mat-tab>

                </mat-tab-group>
                  


            </div>

            <div class="white_block white_expanded" *ngIf="getKeys()">
                <div class="d-f-c" (click)="openFilters()">
                    <div class="white_block_title">
                        {{ "Bookmark filter values" | translate }}
                    </div>

                    <button mat-icon-button>
                        <mat-icon *ngIf="filters">expand_less</mat-icon>
                        <mat-icon *ngIf="!filters">expand_more</mat-icon>
                    </button>
                </div>
                <div class="white_inside" [ngClass]="{'open': filters}">
                    <ng-container *ngIf="getKeys()">
                        <mat-list role="list" class="active_filter" *ngFor="let key of getKeys()">
                            <ng-container *ngIf="key == 'viewArch'; else elseTemplateViewArch">
                                <mat-list-item role="listitem" class="active_filter_item" style="height: unset !important; min-height: 48px !important;">
                                    <div class="d-f-c">
                                        <div class="key text_one_line">{{ key | translate }}:</div>
                                        <div class="val">
                                            <ng-container *ngIf="getValues(activeParams[key])">
                                                <ng-container *ngFor="let col of getValues(activeParams[key]); let last = last">
                                                    <span>{{col?.name}}</span>
                                                    <ng-container *ngIf="!last">, </ng-container>
                                                </ng-container>
                                            </ng-container>
                                        </div>
                                    </div>
                                </mat-list-item>
                            </ng-container>
                            <ng-template #elseTemplateViewArch>
                                <mat-list-item role="listitem" class="active_filter_item" *ngFor="let val of getValues(activeParams[key])">
                                    <div class="d-f-c">
                                        <div class="key text_one_line">{{ key | translate }}:</div>
                                        <div class="val">
                                            <ng-container [ngSwitch]="key">
                                                <ng-container *ngSwitchCase="'scheduled_published_time_by'">
                                                    {{ val + '_sch_type' | translate }}
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'o__dayView'">
                                                    {{data.dayFilters[val].name}}
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'o__hourView'">
                                                    {{data.hourViewsFilters[val].name}}
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'o__realtime'">
                                                    {{data.realtimeFilters[val].name}}
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'analytics_id'">
                                                    {{data.filters[val].name}}
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'task_created_at_from'">
                                                    {{val | reportsDate:data.activeLang}}
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'task_created_at_to'">
                                                    {{val | reportsDate:data.activeLang}}
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'task_operation_created_at_from'">
                                                    {{val | reportsDate:data.activeLang}}
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'task_operation_created_at_to'">
                                                    {{val | reportsDate:data.activeLang}}
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'task_operation_completed_at_from'">
                                                    {{val | reportsDate:data.activeLang}}
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'task_operation_completed_at_to'">
                                                    {{val | reportsDate:data.activeLang}}
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'discussion_created_at_from'">
                                                    {{val | reportsDate:data.activeLang}}
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'discussion_created_at_to'">
                                                    {{val | reportsDate:data.activeLang}}
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'file_created_at_from'">
                                                    {{val | reportsDate:data.activeLang}}
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'file_created_at_to'">
                                                    {{val | reportsDate:data.activeLang}}
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'file_updated_at_from'">
                                                    {{val | reportsDate:data.activeLang}}
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'file_updated_at_to'">
                                                    {{val | reportsDate:data.activeLang}}
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'file_meta_creation_time_from'">
                                                    {{val | reportsDate:data.activeLang}}
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'file_meta_creation_time_to'">
                                                    {{val | reportsDate:data.activeLang}}
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'task_channel_published_at_from'">
                                                    {{val | reportsDate:data.activeLang}}
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'task_channel_published_at_to'">
                                                    {{val | reportsDate:data.activeLang}}
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'created_at_from'">
                                                    {{val | reportsDate:data.activeLang}}
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'created_at_to'">
                                                    {{val | reportsDate:data.activeLang}}
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'analytics_views_count_from'">
                                                    {{val | reportsDate:data.activeLang}}
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'analytics_views_count_to'">
                                                    {{val | reportsDate:data.activeLang}}
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'start_at_from'">
                                                    {{val | reportsDate:data.activeLang}}
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'start_at_to'">
                                                    {{val | reportsDate:data.activeLang}}
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'updated_at_from'">
                                                    {{val | reportsDate:data.activeLang}}
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'updated_at_to'">
                                                    {{val | reportsDate:data.activeLang}}
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'posted_at_from'">
                                                    {{val | reportsDate:data.activeLang}}
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'posted_at_to'">
                                                    {{val | reportsDate:data.activeLang}}
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'operation_status_id'">
                                                    {{getStatusById(val)?.name}}
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'status_id'">
                                                    {{getStatusById(val)?.name}}
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'doneStatus'">
                                                    {{getStatusById(val)?.name}}
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'order_by'">
                                                    <div class="d-f-c" style="padding: 0; width: 100%; justify-content: flex-start;">
                                                        {{getOrder(val)?.name}}
                                                        <mat-icon *ngIf="getOrder(val)?.arrow" style="margin-right: 0 !important;">arrow_drop_down</mat-icon>
                                                        <mat-icon *ngIf="!getOrder(val)?.arrow" style="margin-right: 0 !important;">arrow_drop_down</mat-icon>
                                                    </div>
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'task_operation_operation_id'">
                                                    {{getJobTypeById(val)?.name}}
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'group_id'">
                                                    {{getGroupById(val)?.name}}
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'priority'">
                                                    {{getPriorityById(val)?.name}}
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'content_status_id'">
                                                    {{getProfileStatusByID(val)?.name}}
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'task_status_id'">
                                                    {{getTaskStatusById(val)?.name}}
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'is_content_url'">
                                                    {{getContentUrlByID(val)?.name}}
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'channel_id'">
                                                    <div class="d-f-c" style="padding: 0; width: 100%; justify-content: flex-start;">
                                                        <app-platform [platform_id]="getProfileByID(val)?.platform_id"></app-platform>
                                                        <span class="ml-1 text_one_line">
                                                            {{getProfileByID(val)?.name}}
                                                        </span>
                                                    </div>
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'parameter_value_id'">
                                                    {{getValueById(val)?.value}}
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'task_parameter_value_id'">
                                                    {{getValueById(val)?.value}}
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'task_operation_parameter_value_id'">
                                                    {{getValueById(val)?.value}}
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'discussion_parameter_value_id'">
                                                    {{getValueById(val)?.value}}
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'file_parameter_value_id'">
                                                    {{getValueById(val)?.value}}
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'is_content_views_count_update'">
                                                    <ng-container [ngSwitch]="val">
                                                        <ng-container *ngSwitchCase="0">
                                                            {{ "Without auto-update" | translate }}
                                                        </ng-container>
                                                        <ng-container *ngSwitchCase="1">
                                                            {{ "With auto-update" | translate }}
                                                        </ng-container>
                                                        <ng-container *ngSwitchCase="2">
                                                            {{ "Error in update" | translate }}
                                                        </ng-container>
                                                    </ng-container>
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'is_bravourl_content_views_count_update'">
                                                    <ng-container [ngSwitch]="val">
                                                        <ng-container *ngSwitchCase="0">
                                                            {{ "Without auto-update" | translate }}
                                                        </ng-container>
                                                        <ng-container *ngSwitchCase="1">
                                                            {{ "With auto-update" | translate }}
                                                        </ng-container>
                                                        <ng-container *ngSwitchCase="2">
                                                            {{ "Error in update" | translate }}
                                                        </ng-container>
                                                    </ng-container>
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'content_type_id'">
                                                    {{getContentById(val)?.name}}
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'views_update_interval'">
                                                    {{getViewsUpdateIntervalListByValue(val)?.name}}
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'created_employee_id'">
                                                  <app-user [class]="'user_div'" [employee]="getEmployeeById(val)"></app-user>
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'updated_employee_id'">
                                                  <app-user [class]="'user_div'" [employee]="getEmployeeById(val)"></app-user>
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'created_url_employee_id'">
                                                  <app-user [class]="'user_div'" [employee]="getEmployeeById(val)"></app-user>
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'updated_url_employee_id'">
                                                  <app-user [class]="'user_div'" [employee]="getEmployeeById(val)"></app-user>
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'platform_id'">
                                                    <div class="d-f-c" style="padding: 0; width: 100%; justify-content: flex-start;">
                                                        <app-platform [platform_id]="val"></app-platform>
                                                        <span class="ml-1 text_one_line">
                                                            {{ getPlatformById(val)?.name }}
                                                        </span>
                                                    </div>
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'channel_platform_id'">
                                                    <div class="d-f-c" style="padding: 0; width: 100%; justify-content: flex-start;">
                                                        <app-platform [platform_id]="val"></app-platform>
                                                        <span class="ml-1 text_one_line">
                                                            {{ getPlatformById(val)?.name }}
                                                        </span>
                                                    </div>
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'operation_employee_id'">
                                                    <ng-container *ngIf="val == 0; else elseTemplateWithoutExecMobF">
                                                        {{ "Not set" | translate }} 
                                                    </ng-container>
                                                    <ng-template #elseTemplateWithoutExecMobF>
                                                        <app-user [class]="'user_div'" [employee]="getEmployeeById(val)"></app-user>
                                                    </ng-template>
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'client_employee_id'">
                                                    <ng-container *ngIf="val == 0; else elseTemplateWithoutClientMobF">
                                                        {{ "Not set" | translate }} 
                                                    </ng-container>
                                                    <ng-template #elseTemplateWithoutClientMobF>
                                                        <app-user [class]="'user_div'" [employee]="getEmployeeById(val)"></app-user>
                                                    </ng-template>
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'operation_partner_company_id'">
                                                    <ng-container *ngIf="val == 0; else elseTemplateWithoutClientMobF">
                                                        {{ "Not set" | translate }} 
                                                    </ng-container>
                                                    <ng-template #elseTemplateWithoutClientMobF>
                                                        <app-user [class]="'user_div ml-1'" [companyEmployee]="getPartnerById(val)?.partnerCompany" [imgRoute]="data.imgRoute" [host]="data.host" [company]="data.company"></app-user>
                                                    </ng-template>
                                                </ng-container>
                                                <ng-container *ngSwitchDefault>
                                                    {{ val }}
                                                </ng-container>
                                            </ng-container>
                                        </div>
                                    </div>
                                    <div class="remove" (click)="getValues(activeParams[key]).length > 1 ? removeValue(key, val) : removeValue(key)">
                                        <mat-icon>close</mat-icon>
                                    </div>
                                </mat-list-item>
                                <mat-list-item *ngIf="getValues(activeParams[key]).length > 1" class="remove_all" (click)="removeValue(key)" role="listitem">Remove all</mat-list-item>
                            </ng-template>
                        </mat-list>
                    </ng-container>
                </div>
            </div>
        </form>

    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <div class="f_w_p_btns">
        <button mat-button (click)="close()">
            {{ "Close" | translate }}
        </button>
        <button class="ml-1 d-f-btn" (click)="save()" mat-raised-button cdkFocusInitial color="primary" [disabled]="!!isSubmit">
            <mat-spinner class="spin_16 mr-1" *ngIf="isSubmit"></mat-spinner>  
            {{ "Save" | translate }}
        </button>
    </div>
</mat-dialog-actions>
