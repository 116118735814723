import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { switchMap, tap } from 'rxjs/operators';
import { DeleteAlertComponent } from 'src/app/shared/global_components/delete-alert/delete-alert.component';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { AuthService } from 'src/app/shared/services/rest/auth.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { CreateTaskStatusComponent } from '../../../../main/dialogs/types/create-task-status/create-task-status.component';
import { CreateWorkStatusComponent } from '../../../../main/dialogs/types/create-work-status/create-work-status.component';
import { EditTaskStatusComponent } from '../../../../main/dialogs/types/edit-task-status/edit-task-status.component';
import { EditWorkStatusComponent } from '../../../../main/dialogs/types/edit-work-status/edit-work-status.component';

@Component({
  selector: 'app-task-types',
  templateUrl: './task-types.component.html',
  styleUrls: ['./task-types.component.scss']
})
export class TaskTypesComponent extends BaseClass implements OnInit, OnDestroy {
  public imgRoute: any = '';
  public user: any = {};
  public user_id: number;
  public activeLang: any;
  public company_id: any;
  public selectedTypes: any;
  public company: any;
  public taskTypes: any[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private sm: StorageManagerService,
    private auth: AuthService,
    private languageService: LanguageService,
    private taskService: TaskService,
    private dialog: MatDialog,
    private companyService: CompanyService,
    private bottomSheet: MatBottomSheet,
    private layoutService: LayoutService,
    private router: Router,
  ) { 
    super()
  }

  ngOnInit(): void {
    this.company_id = this.activatedRoute.snapshot.queryParamMap.get('company_id');

    this.getLangData();
    this.getImgRoute();
    this.getCsrf();
    this.getUser();
    this.getTaskTypes();
    this.getCompany();
  }

 
  editTaskType(type) {
    const dialogRef = this.dialog.open(EditTaskStatusComponent, {
      data: {
        company: this.company,
        taskTypes: this.taskTypes,
        type: type,
        activeLang: this.activeLang
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        this.getTaskTypes();
      })
    )
  }

  createTaskType() {
    this.dialog.open(CreateTaskStatusComponent, {
      data: {
        company: this.company,
        taskTypes: this.taskTypes
      }
    });
  }

  deleteTaskType(type, i) {
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: {},
        target: marker("task type")
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        this.attachSubscriptions(
          this.taskService.deleteTaskType(type.id).subscribe(resp => {
            this.taskTypes.splice(i,1);
          })
        )
      }
    });
   
  }

  toggleTaskType(e, type) {
    type.disabled = true
    if (e.checked) {
      this.selectTaskType(type, e);
    } else {
      this.unSelectTaskType(type, e);
    }
  }

  selectTaskType(type, e) {
    this.attachSubscriptions(
      this.taskService.selectTaskStatus({
        task_status_id: type.id,
        company_id: this.company_id
      }).pipe(
        switchMap(x => {
          return this.taskService.getSelectedTaskStatuses(this.company_id, type.id).pipe(
            tap(z => {
              type.taskStatusToCompany = [...z]
            })
          )
        })
      ).subscribe(
        resp => {
          console.log(resp);
          type.disabled = false;
        },
        error => {
          type.disabled = false;
          e.source.toggle();
          this.layoutService.showSnackBar({}, error, SnackBarItem)
        } 
      )
    )
  }

  unSelectTaskType(type, e) {
    this.attachSubscriptions(
      this.taskService.unSelectTaskStatus(type.taskStatusToCompany[0].id).subscribe(
        resp => {
          console.log(resp);
          type.taskStatusToCompany = [];
          type.disabled = false;
        },
        error => {
          type.disabled = false;
          e.source.toggle();
          this.layoutService.showSnackBar({}, error, SnackBarItem)
        } 
      )
    )
  }

  getTaskTypes() {
    this.attachSubscriptions(
      this.taskService.getTaskTypesToCompany(this.company_id, this.activeLang).subscribe(resp => {
        console.log(resp)
        this.taskTypes = resp
      })
    )
  }

  goBack() {
    history.back();
  }
  
  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData().subscribe(resp => {
        this.activeLang = resp.active;
      })
    )
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  getCompany() {
    this.attachSubscriptions(
      this.companyService.getCompany(this.company_id).subscribe(resp => {
        this.companyService.company$.next(resp[0]);
        this.company = resp[0];
      })
    )
  }

  getCsrf() {
    this.attachSubscriptions(
      this.auth.$userToken.subscribe(resp => {
        this.user_id = resp.user_id;
        this.sm.localStorageSetItem("csrf_param", resp.csrf_param)
        this.sm.localStorageSetItem("csrf_token", resp.csrf_token)
      })
    )
  }

  getUser() {
    this.attachSubscriptions(
      this.auth.$user.subscribe(resp => {
        console.log("USER", resp)
        this.user = resp;
      })
    )
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

}
