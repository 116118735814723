import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { marker } from "@biesbjerg/ngx-translate-extract-marker";
import { BaseClass } from "src/app/shared/models/base-class";
import { LayoutService } from "../../services/common/layout.service";
import { SnackBarItem } from "../snack-bar/snack-bar-item";
import { StorageManagerService } from "../../services/common/storage-manager.service";
import { CompanyService } from "../../services/rest/company.service";
import { transformToWatchUrl } from "../../functions/liveUrlTransform";


@Component({
  selector: 'app-ask-text',
  templateUrl: './ask-text.component.html',
  styleUrls: ['./ask-text.component.scss']
})
export class AskTextComponent extends BaseClass implements OnInit, OnDestroy {
  public form: FormGroup = this.fb.group({
    text: ''
  })
  public company:any; 
  public seconds: number = !!this.sm.localStorageGetItem('breakSeconds') ? +this.sm.localStorageGetItem('breakSeconds') : 15;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AskTextComponent>,
    private dialog: MatDialog,
    private companyService: CompanyService,
    private layoutService: LayoutService,
    private sm: StorageManagerService,
    private fb: FormBuilder,
  ) { super() }

  ngOnInit(): void {
    console.log("AskTextComponent", this.data)

    if (this.data.company_id) {
      this.getCompany();
    }
  }

  getCompany() {
    this.attachSubscriptions(
      this.companyService.getCompany(this.data.company_id).subscribe(resp => {
        this.companyService.company$.next(resp[0]);
        this.company = resp[0];
      })
    )
  }

  onInputUrl(originalUrl: string): void {
    console.log('originalUrl', originalUrl);
    let transformedUrl = transformToWatchUrl(originalUrl);
    
    if (transformedUrl != originalUrl) {
      this.form.patchValue({
        text: transformedUrl
      })
    }
    console.log("transformedUrl", transformedUrl);
  }
  // https://youtube.com/shorts/231dqad
  // https://www.youtube.com/shorts/231dqad

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  save() {
    if (this.form.value.text === '') {
      this.layoutService.showSnackBar({name: ''}, marker("Fill in the input field"), SnackBarItem)
      return
    }
    
    if (this.data.withSeconds) {
      if (typeof this.seconds != 'number' || (this.seconds < 15 && !(this.company.permissions.includes('owner') || this.company?.permissions.includes('admin')))) {
        this.seconds = 15;
        this.layoutService.showSnackBar({name: ''}, marker("Minimum 15 seconds required"), SnackBarItem)
        return
      }
      this.sm.localStorageSetItem('breakSeconds', this.seconds)
      this.dialogRef.close({event: "new link", data: this.form.value.text, time: this.seconds})
    } else {
      this.dialogRef.close({event: "new link", data: this.form.value.text})
    }
  }

  old() {
    if (this.data.withSeconds) {
      if (typeof this.seconds != 'number' || (this.seconds < 15 && !(this.company.permissions.includes('owner') || this.company?.permissions.includes('admin')))) {
        this.seconds = 15;
        this.layoutService.showSnackBar({name: ''}, marker("Minimum 15 seconds required"), SnackBarItem)
        return
      }
      this.sm.localStorageSetItem('breakSeconds', this.seconds)
      this.dialogRef.close({event: "old link", data: false, time: this.seconds})
    } else {
      this.dialogRef.close({event: "old link", data: false})
    }
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

}