<h2 mat-dialog-title class="dialog_title">
    <span>
        {{ "Statement documents" | translate }}
    </span>
    <button class="ml-1" mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h2>
<mat-dialog-content class="mat-typography">
    <div class="task_container">
        <mat-list role="list" class="job_types" [appDeviceClass]="'custom-scroll custom-scroll-t'"
        infinite-scroll
        [infiniteScrollDisabled]="isLoadStatementDocs"
        [scrollWindow]="false"
        [infiniteScrollUpDistance]="scrollUpDistance"
        [infiniteScrollThrottle]="throttle"
        (scrolled)="onDownStatementDocuments($event)">
            <mat-list-item *ngFor="let tmpl of statementDocs; let i = index;" role="listitem" class="company_item">
                <div class="d-f-c">
                    <div class="type_name">
                        statement_template_id: {{ tmpl?.statement_template_id }}
                    </div>
                    <div class="group ml-1">
                        group_id: {{ tmpl?.task_group_id }}
                    </div>
                    <div class="tmpl_stat ml-1">
                        <span style="color: green;" *ngIf="tmpl.is_created">{{"Completed" | translate}}</span>
                        <span style="color: red;" *ngIf="tmpl.is_error"  #tooltip="matTooltip" matTooltip="{{ tmpl?.error_debug_message }}" [matTooltipPosition]="'above'">{{"Error" | translate}}</span>
                        <span style="color: orange;" *ngIf="tmpl.is_new">{{"In process" | translate}}</span>
                    </div>
    
                    <div class="tmpl_file ml-1" *ngIf="!!tmpl?.file">
                        <span class="cp" (click)="openPdf(tmpl?.file)">{{tmpl?.file?.filename}}</span>
                    </div>
                </div>

                <div class="d-f-c" style="margin-left: 40px;">
                    <div class="company_btns">
                        <mat-icon class="cp" (click)="download(tmpl.file)" *ngIf="!!tmpl?.file">download</mat-icon>
                        <!-- <mat-icon (click)="editTaskTemplate(tmpl)">edit</mat-icon>
                        <mat-icon (click)="deleteTaskTemplate(tmpl, i)">delete</mat-icon> -->
                    </div>
                </div>
            </mat-list-item>
        </mat-list>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-raised-button (click)="close()">
        {{ "Close" | translate }}
    </button>
    <button class="ml-1" mat-raised-button color="primary" (click)="addDoc()">
        {{ "Add document" | translate }}
    </button>
</mat-dialog-actions>