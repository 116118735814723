import { AfterViewInit, Component, Inject, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { AuthService } from 'src/app/shared/services/rest/auth.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { mimeTypes } from 'mime-wrapper';

import * as moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/en-gb';
import 'moment/locale/uk';
import 'moment/locale/ru';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { BaseClass } from 'src/app/shared/models/base-class';
import { TasksHaveSameUrlComponent } from 'src/app/shared/global_components/tasks-have-same-url/tasks-have-same-url.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { catchError, debounceTime, distinctUntilChanged, filter, last, map, retry, startWith, switchMap, take, tap } from 'rxjs/operators';
import { fromEvent, ReplaySubject, of, throwError, forkJoin, Subscription, Observable } from 'rxjs';
import { MatSelect } from '@angular/material/select';
import { GlobalDataService } from 'src/app/shared/services/common/global-data.service';
import { NgxMatDateAdapter } from '@angular-material-components/datetime-picker';
import { SameUrlTasksComponent } from 'src/app/components/atTasksDialog/same-url-tasks/same-url-tasks.component';
import { DeleteAlertComponent } from 'src/app/shared/global_components/delete-alert/delete-alert.component';
import { UrlAnalyticsComponent } from '../url-analytics/url-analytics.component';
import { MobUrlAnalyticsComponent } from '../mob-url-analytics/mob-url-analytics.component';
import { environment } from 'src/environments/environment';
import { Moment } from 'moment';
import { SelectFileComponent } from 'src/app/shared/global_components/file-manager/dialog/select-file/select-file.component';
import { casesModel } from 'src/app/shared/functions/casesModel';
import { ChatService } from 'src/app/shared/services/rest/chat.service';
import { FileSettingsComponent } from 'src/app/shared/global_components/file-manager/dialog/file-settings/file-settings.component';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { transformToWatchUrl } from 'src/app/shared/functions/liveUrlTransform';
import { ParametersService } from 'src/app/shared/services/rest/parameters.service';


export const MY_FORMATS = {
  parse: {
    dateInput: 'LLLLL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
export const MY_NEW_FORMATS = {
  parse: {
    dateInput: 'MM/DD/YYYY',
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'MM/DD/YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-update-platform-content',
  templateUrl: './update-platform-content.component.html',
  styleUrls: ['./update-platform-content.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_NEW_FORMATS},
  ]
})

export class UpdatePlatformContentComponent extends BaseClass implements OnInit, OnDestroy {
  public host: any = environment.host;
  public imgRoute: any = '';
  public user: any = {};
  public user_id: number;
  public form: FormGroup;
  public company_id: any;
  public activeLang: any;
  public isFormChange: boolean = false;
  public isSubmit: boolean = false;
  public contentTypes: any = [];

  
  public isChecked: boolean = false;

  public allValues: any;
  public allValuesControl: FormControl = new FormControl();
  public allValues$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public timeZone = - new Date().getTimezoneOffset();


  public myControl: FormControl = new FormControl('');
  public filteredOptions$: Observable<any>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<UpdatePlatformContentComponent>,
    private sm: StorageManagerService,
    private auth: AuthService,
    private companyService: CompanyService,
    private globalDataService: GlobalDataService,
    private fb: FormBuilder,
    public viewContainerRef: ViewContainerRef,
    public overlay: Overlay,
    private parametersService: ParametersService,
    private chatService: ChatService,
    private bottomSheet: MatBottomSheet,
    private dialog: MatDialog,
    private taskService: TaskService,
    private languageService: LanguageService,
    private _ngx_adapter: NgxMatDateAdapter<any>,
    private _adapter: DateAdapter<any>,
    private layoutService: LayoutService
  ) {super()}

  ngOnInit(): void {
    this.dialogRef.addPanelClass("create_task_by_manager_modal")
    console.log("UpdatePlatformContentComponent", this.data)

    this.filteredOptions$ = this.myControl.valueChanges.pipe(
      tap(x => {
        console.log("this.myControl.value", this.myControl.value)
        console.log("this.filteredOptions$", x)
      }),
      startWith(''),
      switchMap(val => {
        return this.taskService.getFacebookPlaces(val)
      })
    );

    this.getContentTypes(this.data.profile.channel.platform_id);

    this.company_id = this.data.company_id;

    this.form = this.fb.group({
      content_name: ['', Validators.required],
      content_description: '',
    })

    this.attachSubscriptions(
      this.allValuesControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchValues(resp))
    )

    this.getCompany();
    this.getAllApiParameterValues();
    this.getImgRoute();
    this.getCsrf();
    this.getUser();
    this.getLangData();
    
    this.form.patchValue({
      content_name: this.data.profile.content_name,
      content_description: this.data.profile.content_description
    })

    if (this.data.profile.channel.platform_id == 1) {

      if (this.data.profile.content_type_id == '106') {
        this.form.addControl('publishing_params', this.fb.group({
          live_stream_url: this.data.profile.publishing_params.live_stream_url,
          live_stream_url_backup: this.data.profile.publishing_params.live_stream_url_backup ? this.data.profile.publishing_params.live_stream_url_backup : "rtmp://b.rtmp.youtube.com/live2?backup=1",
          live_stream_to_backup: this.data.profile.publishing_params.live_stream_to_backup ? this.data.profile.publishing_params.live_stream_to_backup : "",
          live_stream_key: this.data.profile.publishing_params.live_stream_key,
          live_stream_to: this.data.profile.publishing_params.live_stream_to,
          is_loop: this.data.profile.publishing_params.is_loop,
          is_backup: this.data.profile.publishing_params.is_backup ? this.data.profile.publishing_params.is_backup : 0,
          is_api_set_up_new: this.data.profile.publishing_params.is_api_set_up_new ? this.data.profile.publishing_params.is_api_set_up_new : 0,
          is_random: this.data.profile.publishing_params.is_random,
          random_start_count: this.data.profile.publishing_params.random_start_count,
          files_parameter_values_ids: this.data.profile.publishing_params.files_parameter_values_ids
        }))
      } else {
        if (!!this.data.profile.publishing_params) {
          this.form.addControl('publishing_params', this.fb.group({
            privacy: this.data.profile.publishing_params && this.data.profile.publishing_params.privacy ? this.data.profile.publishing_params.privacy : 'public',
            made_for_kids: this.data.profile.publishing_params && this.data.profile.publishing_params.made_for_kids ? this.data.profile.publishing_params.made_for_kids : 0,
          }))
        } else {
          this.form.addControl('publishing_params', this.fb.group({
            privacy : 'public',
            made_for_kids: 0
          }))
        }
      }
    }
    
    if (this.data.profile.channel.platform_id == 3) {
      this.form.addControl('publishing_params', this.fb.group({
        privacy_level : this.data.profile.publishing_params.privacy_level,
        disable_duet : !!this.data.profile.publishing_params.disable_duet,
        disable_stitch : !!this.data.profile.publishing_params.disable_stitch,
        disable_comment : !!this.data.profile.publishing_params.disable_comment,
        brand_content_toggle : !!this.data.profile.publishing_params.brand_content_toggle,
        brand_organic_toggle : !!this.data.profile.publishing_params.brand_organic_toggle
      }))
    }
    
    this.form.updateValueAndValidity()

    if (this.form.get('publishing_params') && this.form.get('publishing_params').get('is_api_set_up_new')) {
      this.attachSubscriptions(
        this.form.get('publishing_params').get('is_api_set_up_new').valueChanges.subscribe(resp => {
          console.log("this.form.get('publishing_params').get('is_api_set_up_new').valueChanges", resp)
          if (!!resp) {
            (this.form.get('publishing_params') as FormGroup).addControl('made_for_kids', this.fb.control(this.data.is_edit ? this.data.profile.publishing_params.made_for_kids : 0));
            (this.form.get('publishing_params') as FormGroup).addControl('enable_auto_start', this.fb.control(this.data.is_edit ? this.data.profile.publishing_params.enable_auto_start : 1));
            (this.form.get('publishing_params') as FormGroup).addControl('enable_auto_stop', this.fb.control(this.data.is_edit ? this.data.profile.publishing_params.enable_auto_stop : 1));
            (this.form.get('publishing_params') as FormGroup).addControl('enable_dvr', this.fb.control(this.data.is_edit ? this.data.profile.publishing_params.enable_dvr : 1));
            (this.form.get('publishing_params') as FormGroup).addControl('record_from_start', this.fb.control(this.data.is_edit ? this.data.profile.publishing_params.record_from_start : 0));
          } else {
            (this.form.get('publishing_params') as FormGroup).removeControl('made_for_kids');
            (this.form.get('publishing_params') as FormGroup).removeControl('enable_auto_start');
            (this.form.get('publishing_params') as FormGroup).removeControl('enable_auto_stop');
            (this.form.get('publishing_params') as FormGroup).removeControl('enable_dvr');
            (this.form.get('publishing_params') as FormGroup).removeControl('record_from_start');
          }
        })
      )
    }

    this.form.valueChanges.subscribe(() => this.isFormChange = true);

    this.dialogRef.backdropClick().subscribe(e => {
      e.preventDefault();
      if (this.isFormChange) {
        this.layoutService.openBottomSheet(this.dialogRef);
      } else {
        this.close();
      }
    });

  }

  onChangeDisc(e) {
    this.isChecked = e.checked;
  }

  getContentTypes(platform_id) {
    console.log("getContentTypes")
    this.attachSubscriptions(
      this.companyService.getContentTypes(platform_id, '1').pipe(
        map(el => el.body)
      ).subscribe(resp => {
        this.contentTypes.push(...resp);
      })
    )
  }

  getContentById(id) {
    if (!this.contentTypes || this.contentTypes.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.contentTypes.find(el => el.id == id)
  }

  getAllApiParameterValues() {
    this.attachSubscriptions(
      this.parametersService.getAllValues('1', this.company_id, null, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.parametersService.getAllValues(x, this.company_id).pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        this.allValues = [].concat(...res);
        this.allValues$.next(this.allValues.slice())
      })
    )
  }

  onSearchValues(resp) {
    if (!this.allValues) {
      return;
    }

    if (!resp) {
      this.allValues$.next(this.allValues.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.allValues$.next(
      this.allValues.filter(z => z.value.toLowerCase().indexOf(resp) > -1 || (z.parameter.name && z.parameter.name.toLowerCase().indexOf(resp) > -1))
    );
  }

  getValuesById(id) {
    return this.allValues && this.allValues.find(x => x.id == id)
  }
  
  log() {
    console.log("form", this.form.value)
    console.log("profile", this.data.profile)
  }


  showUserDate(date:any = false, timestamp:any = false) {
    let timeAndDate;
    if (date) {
      timeAndDate = moment(date).clone()
    } else {
      timeAndDate = moment(timestamp*1000).clone()
    }

    const utcOffset = moment.tz(timeAndDate, this.data.company.timezone).utcOffset();
    
    let utcTimestamp = moment(timeAndDate).format("X")
    
    let difference = (Number(this.timeZone) - Number(utcOffset))*60
    // console.log(difference);
    
    let workSpaceTimeZone;
    if (date) {
      workSpaceTimeZone = Number(utcTimestamp) + Number(difference)
    } else {
      workSpaceTimeZone = Number(utcTimestamp) - Number(difference)
    }
    return workSpaceTimeZone
  }

  copyLink(type) {
    this.layoutService.showSnackBar({name: type}, marker("Copied"), SnackBarItem)
  }

  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData().subscribe(resp => {
        this._ngx_adapter.setLocale(resp.active);
        this._adapter.setLocale(resp.active);
        this.activeLang = resp.active;
      })
    )
  }

  getCompany() {
    this.attachSubscriptions(
      this.companyService.getCompany(this.company_id).subscribe(resp => {
        this.companyService.company$.next(resp[0]);
      })
    )
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  getCsrf() {
    this.attachSubscriptions(
      this.auth.$userToken.subscribe(resp => {
        this.user_id = resp.user_id;
        this.sm.localStorageSetItem("csrf_param", resp.csrf_param)
        this.sm.localStorageSetItem("csrf_token", resp.csrf_token)
      })
    )
  }

  getUser() {
    this.attachSubscriptions(
      this.auth.$user.subscribe(resp => {
        this.user = resp;
      })
    )
  }

  selected(e) {
    console.log(e)
    console.log("this.myControl.value", this.myControl.value)
  };

  displayFn(place:any): string {
    return place && place.name ? place.name : '';
  }

  understandFileType(val) {
    if (!val) {
      return ""
    }
    
    if (val == 'application/pdf') {
      return 'pdf'
    } else if (val.indexOf("/") != -1) {
      return val.split('/')[0]
    } else {
      return ""
    }
  }

  onSubmit() {
    this.isSubmit = true;

    this.attachSubscriptions(
      this.taskService.updatePlatformContent(this.data.profile.id, this.form.value).subscribe(
        resp => {
          this.dialogRef.close({event: "edit", data: resp});
          this.isSubmit = false;
        }, 
        error => {
          this.isSubmit = false;
          this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
        }
      )
    )
  }

  showError() {
    this.layoutService.showSnackBar({name: ''}, marker("Try again later"), SnackBarItem)
  }

  cancel() {
    this.dialogRef.close({event: "null", data: false})
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
