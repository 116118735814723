
<mat-dialog-content class="mat-typography" dirEnter (dirSubmit)="!form.valid || isSubmit ? null : onSubmit()">
    <div class="container form_wrapper">
        <div class="title_modal">
            {{ "Adding a new company member" | translate }}
        </div>

        <form [formGroup]="virtual" class="form_with_pads form_with_valids">
            <mat-form-field appearance="standard" class="full_width_form">
                <ng-container *ngIf="!virtual.get('name').valid && submited">
                    <div class="req_field"></div>
                    <div class="req_label">
                        <div class="angle"></div>
                        <span>
                            {{ "You need to write here" | translate }}
                        </span>
                    </div>
                </ng-container>
                <mat-label>{{ 'Name' | translate }}</mat-label>
                <input formControlName="name" matInput placeholder="{{ 'Name' | translate }}">
            </mat-form-field>
        </form>
        <form [formGroup]="form" class="form_with_pads form_with_valids">

            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ 'Member description' | translate }}</mat-label>
                <textarea formControlName="description" matInput placeholder="{{ 'Member description' | translate }}"
                cdkTextareaAutosize
                [maxLength]="250"
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="5"></textarea>
            </mat-form-field>
    
            <mat-form-field appearance="standard" class="full_width_form">
                <ng-container *ngIf="!form.get('currency_id').valid && submited">
                    <div class="req_field"></div>
                    <div class="req_label">
                        <div class="angle"></div>
                        <span>
                            {{ "You need to select currency" | translate }}
                        </span>
                    </div>
                </ng-container>
                <mat-label>{{ "Choose a currency" | translate }}</mat-label>
                <mat-select formControlName="currency_id">
                <mat-option *ngFor="let currency of currencyList" [value]="currency.id">
                    {{ currency.name }}
                </mat-option>
                </mat-select>
            </mat-form-field>
    
            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ 'Points' | translate }}</mat-label>
                <input formControlName="rate_target" matInput type="number" placeholder="{{ 'Points' | translate }}">
            </mat-form-field>
    
            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ 'Salary' | translate }}</mat-label>
                <input formControlName="salary" matInput type="number" placeholder="{{ 'Salary' | translate }}">
            </mat-form-field>
        
        </form>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <div class="f_w_p_btns">
        <button mat-button (click)="close()">
            {{ "Close" | translate }}
        </button>
        <button class="ml-1 d-f-btn" (click)="onSubmit()" cdkFocusInitial mat-raised-button color="primary" [disabled]="isSubmit">
            <mat-spinner class="spin_16 mr-1" *ngIf="isSubmit"></mat-spinner>  
            {{ "Add" | translate }}
        </button>
    </div>
</mat-dialog-actions>
