
<div mat-dialog-title class="dialog_title">
    <div class="d-f-c">
        <span class="modal_title">
            {{ "Notifications" | translate }}
        </span>
    </div>
    <div class="d-f-c">
        <form [formGroup]="filter" class="hidden_filter_wrp_out">
            <div class="hidden_filter_wrp" (click)="comapnyes.open()">
                <div class="active_filter">
                    <span>
                        {{ getCompanyById(filter.get('company_id').value)?.name }}
                    </span>
                    <svg style="width: 10px; height: 10px" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_3582_51300head)">
                        <path d="M7.91699 3.33325L2.08366 3.33325L5.00033 7.91658L7.91699 3.33325Z" fill="#686868"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_3582_51300head">
                        <rect width="10" height="10" fill="white" transform="matrix(0 1 -1 0 10 0)"/>
                        </clipPath>
                        </defs>
                    </svg>
                </div>
                <mat-form-field appearance="legacy" class="hidden_filter_element">
                    <mat-label>{{ "Workspace" | translate }}</mat-label>
                    <mat-select #comapnyes [disableOptionCentering]="true" selectDir formControlName="company_id">
                        <mat-option>
                            <ngx-mat-select-search 
                                placeholderLabel="{{ 'Search' | translate }}" 
                                noEntriesFoundLabel="{{ 'no matching Workspace found' | translate }}"
                                [formControl]="companyesControl"></ngx-mat-select-search>
                        </mat-option>
                        <mat-option value="">{{ "All Workspaces" | translate }}</mat-option>
                        <mat-option *ngFor="let comp of companyes$ | async" [value]="comp.id">
                                {{comp.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </form>
        <button class="ml-1" mat-icon-button (click)="close()"><mat-icon>close</mat-icon></button>
    </div>
</div>
<mat-dialog-content class="mat-typography">
    <div class="container form_wrapper not_center" [appDeviceClass]="'custom-scroll custom-scroll-t'" 
    infinite-scroll
    [infiniteScrollDisabled]="isLoad"
    [scrollWindow]="false"
    [infiniteScrollUpDistance]="scrollUpDistance"
    [infiniteScrollThrottle]="throttle"
    (scrolled)="onDown($event)">

    <div class="chats_out" *ngFor="let not of notifications;" [ngClass]="{'highlight_chat': not?.discussion.is_active == 1}">
        <div class="chat" (click)="openNot(not)" [ngClass]="{'no_p_stat': not?.discussion?.employees?.length > not?.discussion?.employeesStatuses?.length}">
            <div class="priority_line" [ngClass]="{
                'useOut': useOut,
                'Urgent': not?.discussion?.priority == '1000',
                'High': not?.discussion?.priority == '900',
                'Middle': not?.discussion?.priority == '800',
                'Low': not?.discussion?.priority == '700',
                'No': not?.discussion?.priority == '0' || !not?.discussion?.priority
            }"></div>

            <div class="chat_status">
              <div style="width: 100%">
                <div class="inside_job" [ngClass]="{
                    'first': not?.discussion?.status_id == '1',
                    'second': not?.discussion?.status_id == '2',
                    'third': not?.discussion?.status_id == '3',
                    'fours': not?.discussion?.status_id == '4',
                    'fives': not?.discussion?.status_id == '5',
                    'sixs': not?.discussion?.status_id == '6',
                    'nine': not?.discussion?.status_id == '97',
                    'seven': not?.discussion?.status_id == '98',
                    'eight': not?.discussion?.status_id == '99'}">
                    <span class="text_one_line">{{ getOperationStatusName(not?.discussion?.status_id) | translate }}</span>  
                </div>
              </div>
            </div>
            <div class="chat_data">
                <div class="chat_name text_one_line">
                    {{ not?.discussion?.name }}
                </div>


                <div class="chat_last_post">
                    <div class="last_post_file" *ngIf="not?.imageFile && !!not?.imageFile.thumbnail">
                      <div class="file_view">
                        <div class="img_wrp">
                            <img [src]="data?.host + not?.imageFile.thumbnail + '?company_id=' + not?.company_id" alt="">
                        </div>
                      </div>
                    </div>
                    <div class="text_one_line" [innerHTML]='not?.discussionPost?.text ? (not?.discussionPost?.text.replaceAll("\n"," ").replaceAll("</BR>"," ").replaceAll("<BR>"," ").replaceAll("</br>"," ").replaceAll("<br>"," ")) : ""'></div>
                </div>
               
            </div>

            <ng-container *ngIf="not?.discussion.updated_at > today; else updatedAtTempl">
              <div class="chat_updated_at" #tooltip="matTooltip"
              matTooltip="{{ not?.discussion.updated_at*1000 | date:'MMM d, y':'':data?.activeLang | yearPipe }}"
              [matTooltipPosition]="'left'">
                {{ not?.discussion.updated_at*1000 | date:'hh:mm a':'':data?.activeLang | yearPipe }}
              </div>
            </ng-container>
            <ng-template #updatedAtTempl>
              <div class="chat_updated_at" #tooltip="matTooltip"
              matTooltip="{{ not?.discussion.updated_at*1000 | date:'hh:mm a':'':data?.activeLang | yearPipe }}"
              [matTooltipPosition]="'left'">
                {{ not?.discussion.updated_at*1000 | date:'MMM d, y':'':data?.activeLang | yearPipe }}
              </div>
            </ng-template>
        </div>
    </div>

    </div>
</mat-dialog-content>

