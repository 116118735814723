<mat-dialog-content class="mat-typography" dirEnter (dirSubmit)="!form.valid || isSubmit ? null : onSubmit()">
    <div class="container form_wrapper">
        <div class="title_modal">
            {{ "Connect with group" | translate }}
        </div>
        <form [formGroup]="form" class="form_with_pads form_with_valids">
            <mat-form-field appearance="legacy" class="full_width_form">
                <mat-label>{{ "Group" | translate }}</mat-label>
                <mat-select [disableOptionCentering]="true" selectDir formControlName="group_id">
                  <mat-option>
                    <ngx-mat-select-search 
                        placeholderLabel="{{ 'Search' | translate }}" 
                        noEntriesFoundLabel="{{ 'no matching groups found' | translate }}"
                        [formControl]="groupsMoreControl"></ngx-mat-select-search>
                  </mat-option>                  
                  <mat-option *ngFor="let group of groups$ | async" [value]="group.id">
                    {{ group.name }}
                  </mat-option>
                </mat-select>
            </mat-form-field>
        </form>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <div class="f_w_p_btns">
        <button mat-button (click)="close()">
            {{ "Close" | translate }}
        </button>
        <button class="ml-1 d-f-btn" (click)="onSubmit()" cdkFocusInitial mat-raised-button color="primary" [disabled]="isSubmit">
            <mat-spinner class="spin_16 mr-1" *ngIf="isSubmit"></mat-spinner>  
            {{ "Save" | translate }}
        </button>
    </div>
</mat-dialog-actions>