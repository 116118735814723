<mat-dialog-content class="mat-typography">
    <mat-tab-group [@.disabled]="true" [selectedIndex]="activeIndex" (selectedIndexChange)="changeTab($event)">
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon mr-1">delete</mat-icon>
                {{ "Delete Clients" | translate }}
            </ng-template>
            <form [formGroup]="deleteForm" class="multi_tab_form form_with_pads form_with_valids">
                <div class="box_wrp">
                    <mat-checkbox color="primary" formControlName="with_card">{{ "Including card clients" | translate }}</mat-checkbox>
                </div>

                <div class="users_select" *ngIf="employeesAssignedEarlyer && employeesAssignedEarlyer.length" [ngClass]="{'req_value': !deleteForm.get('employee_id').valid}">
                    <label>{{ "Select client(s) you want to remove" | translate }}:</label>
                    <mat-chip-list class="without_ordered" [multiple]="true" selectable formControlName="employee_id">
                        <ng-container *ngFor="let employee of employeesAssignedEarlyer; let i = index">
                            <mat-chip class="chip_user" (click)="toggleChip(employee.id, 'employee_id', 'deleteForm')" [value]="employee.id" [selected]="deleteForm.value.employee_id && deleteForm.value.employee_id.includes(employee.id)">
                                <app-user [class]="'user_div'" [employee]="employee"></app-user>
                            </mat-chip>
                        </ng-container>
                    </mat-chip-list>
                </div>
                <div class="users_select" *ngIf="partnersAssignedEarlyer && partnersAssignedEarlyer.length" [ngClass]="{'req_value': !deleteForm.get('employee_id').valid}">
                    <label>{{ "Select client(s) you want to remove" | translate }}:</label>
                    <mat-chip-list class="without_ordered" [multiple]="true" selectable formControlName="partner_company_id">
                        <ng-container *ngFor="let partner of partnersAssignedEarlyer; let i = index">
                            <mat-chip class="chip_user" (click)="toggleChip(partner.id, 'partner_company_id', 'deleteForm')" [value]="partner.id" [selected]="deleteForm.value.partner_company_id && deleteForm.value.partner_company_id.includes(partner.id)">
                                <app-user [class]="'user_div'" [companyEmployee]="partner" [imgRoute]="imgRoute" [host]="host" [company]="data?.company"></app-user>
                            </mat-chip>
                        </ng-container>
                    </mat-chip-list>
                </div>
            </form>
        </mat-tab>
    </mat-tab-group>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <div class="f_w_p_btns">
        <button mat-raised-button (click)="close()">
            {{ "Cancel" | translate }}
        </button>
        <ng-container *ngIf="activeIndex.value == 0 && data.selectedChats; else elseTemplateTabIndex">
            <button class="ml-1 d-f-btn" (click)="onAssign()" mat-raised-button cdkFocusInitial color="primary" [disabled]="isSubmit">
                <mat-spinner class="spin_16 mr-1" *ngIf="isSubmit"></mat-spinner>
                {{ "Assign" | translate }}
            </button>
        </ng-container>
        <ng-template #elseTemplateTabIndex>
            <button class="ml-1 d-f-btn" (click)="onDelete()" mat-raised-button cdkFocusInitial color="primary" [disabled]="isSubmit">
                <mat-spinner class="spin_16 mr-1" *ngIf="isSubmit"></mat-spinner>
                {{ "Delete" | translate }}
            </button>
        </ng-template>
    </div>
</mat-dialog-actions>