<h2 mat-dialog-title class="dialog_title">
    <span>
        {{ "Dubbing" | translate }}: {{ data.files && data.files.length ? ('selected files' | translate) : data.file.filename }}
    </span>
    <button class="ml-1" mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h2>
<mat-dialog-content class="mat-typography" dirEnter (dirSubmit)="!form.valid ? null : submitForm()">
    <div class="container">
        <form [formGroup]="form">
            <mat-form-field appearance="standard" class="full_width_form" *ngIf="!(data.files && data.files.length)">
                <mat-label>{{ "Source language" | translate }}</mat-label>
                <mat-spinner class="spin_24 mr-1" matPrefix *ngIf="isGettingTag"></mat-spinner>
                <mat-select [disableOptionCentering]="true" selectDir [multiple]="false" formControlName="source_language">
                    <mat-select-trigger class="multi_users">
                        <ng-container *ngIf="form.get('source_language').value">
                            <span>{{ getLangByVal(form.get('source_language').value)?.value }}</span>
                        </ng-container>
                    </mat-select-trigger>
                    <mat-option>
                        <ngx-mat-select-search 
                        placeholderLabel="{{ 'Search' | translate }}" 
                        noEntriesFoundLabel="{{ 'no matching language found' | translate }}"
                        [formControl]="langsControl"></ngx-mat-select-search>
                    </mat-option>
                    <ng-container *ngFor="let item of langs$ | async">
                        <mat-option *ngIf="!!item.iso_value" [value]="item.iso_value">
                            <span>{{ item.value }}</span>
                        </mat-option>
                    </ng-container>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ "Target language(s)" | translate }}</mat-label>
                <mat-select [disableOptionCentering]="true" selectDir [multiple]="true" formControlName="target_language">
                    <mat-select-trigger class="multi_users">
                        <ng-container *ngIf="form.get('target_language').value && form.get('target_language').value.length">
                            <ng-container *ngFor="let val of form.get('target_language').value">
                                <span>{{ getLangByVal(val)?.value }}</span>
                            </ng-container>
                        </ng-container>
                    </mat-select-trigger>
                    <mat-option>
                        <ngx-mat-select-search 
                        placeholderLabel="{{ 'Search' | translate }}" 
                        noEntriesFoundLabel="{{ 'no matching language found' | translate }}"
                        [formControl]="langsControl"></ngx-mat-select-search>
                    </mat-option>
                    <ng-container *ngFor="let item of langs$ | async">
                        <mat-option *ngIf="!!item.iso_value" [value]="item.iso_value">
                            <span>{{ item.value }}</span>
                        </mat-option>
                    </ng-container>
                </mat-select>
            </mat-form-field>
        </form>

        <div class="dub_files" [appDeviceClass]="'custom-scroll custom-scroll-t'" *ngIf="data.files && data.files.length">
            <div class="dub" *ngFor="let file of data.files">
                <div class="file_info">
                    <div class="filename text_one_line">{{ file?.filename | translate }}</div>
                    <div class="tags" style="color: #3f51b5;" *ngIf="file.primaryTags">
                        <ng-container *ngFor="let prim of file.primaryTags; let last = last">
                          <span>{{prim?.parameterValue?.value}}</span>
                          <ng-container *ngIf="!last">, </ng-container>
                        </ng-container>
                    </div>
                </div>
                <div class="file_lang" *ngIf="file.dub">
                    <mat-form-field appearance="standard" class="full_width_form">
                        <mat-label>{{ "Source language" | translate }}</mat-label>
                        <mat-spinner class="spin_24 mr-1" matPrefix *ngIf="file.dub.is_getting"></mat-spinner>
                        <mat-select [disableOptionCentering]="true" selectDir [multiple]="false" [(ngModel)]="file.dub.source_language">
                            <mat-select-trigger class="multi_users">
                                <ng-container *ngIf="file.dub.source_language">
                                    <span>{{ getLangByVal(file.dub.source_language)?.value }}</span>
                                </ng-container>
                            </mat-select-trigger>
                            <mat-option>
                                <ngx-mat-select-search 
                                placeholderLabel="{{ 'Search' | translate }}" 
                                noEntriesFoundLabel="{{ 'no matching language found' | translate }}"
                                [formControl]="langsControl"></ngx-mat-select-search>
                            </mat-option>
                            <ng-container *ngFor="let item of langs$ | async">
                                <mat-option *ngIf="!!item.iso_value" [value]="item.iso_value">
                                    <span>{{ item.value }}</span>
                                </mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>

                    <div class="split"></div>

                    <mat-form-field appearance="standard" class="full_width_form">
                        <mat-label>{{ "Target language(s)" | translate }}</mat-label>
                        <mat-select [disableOptionCentering]="true" selectDir [multiple]="true" [(ngModel)]="file.dub.target_language">
                            <mat-select-trigger class="multi_users">
                                <ng-container *ngIf="file.dub.target_language && file.dub.target_language.length">
                                    <ng-container *ngFor="let val of file.dub.target_language">
                                        <span>{{ getLangByVal(val)?.value }}</span>
                                    </ng-container>
                                </ng-container>
                            </mat-select-trigger>
                            <mat-option>
                                <ngx-mat-select-search 
                                placeholderLabel="{{ 'Search' | translate }}" 
                                noEntriesFoundLabel="{{ 'no matching language found' | translate }}"
                                [formControl]="langsControl"></ngx-mat-select-search>
                            </mat-option>
                            <ng-container *ngFor="let item of langs$ | async">
                                <mat-option *ngIf="!!item.iso_value" [value]="item.iso_value">
                                    <span>{{ item.value }}</span>
                                </mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <!-- <div class="box_wrp d-f-c" style="height: 40px;">
            <mat-checkbox color="primary" [(ngModel)]="is_remember">{{"Remember selection for this device" | translate}}</mat-checkbox>
        </div> -->
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button (click)="close()">
        {{ "Close" | translate }}
    </button>
    <ng-container *ngIf="data.files && data.files.length; else elseTemplateMultiFiles">        
        <button class="ml-1" (click)="multiSubmitForm()" mat-raised-button color="primary" [disabled]="isSubmit">
            {{ "Start Dubbing" | translate }}
        </button>
    </ng-container>
    <ng-template #elseTemplateMultiFiles>
        <button class="ml-1" (click)="submitForm()" mat-raised-button color="primary" [disabled]="isSubmit">
            {{ "Start Dubbing" | translate }}
        </button>
    </ng-template>
    
</mat-dialog-actions>
