<h2 mat-dialog-title class="dialog_title">
    <span>
        {{ "Edditing a work" | translate }}: {{data?.task?.name}}<b>({{data?.task?.custom_id}})</b>
    </span>
    <button mat-button (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h2>
<mat-dialog-content class="mat-typography" dirEnter (dirSubmit)="!form.valid ? null : onSubmit()">
    <div class="container form_wrapper">
        <form [formGroup]="form">
            <div class="custom_radio">
                <label>{{ "Choose a operation" | translate }}: </label>
                <mat-radio-group required formControlName="operation_id">
                  <mat-radio-button color="primary" *ngFor="let operation of operations" [value]="operation.id">{{ operation.name | translate }}</mat-radio-button>
                </mat-radio-group>
            </div>   
        </form>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button (click)="close()">
        {{ "Back" | translate }}
    </button>
    <button class="ml-1" (click)="onSubmit()" mat-raised-button cdkFocusInitial color="primary" [disabled]="!form.valid">
        {{ "Save" | translate }}
    </button>
</mat-dialog-actions>
