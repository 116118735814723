import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TaskService } from 'src/app/shared/services/rest/task.service';
// import * as moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/en-gb';
import 'moment/locale/uk';
import 'moment/locale/ru';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { MembersService } from 'src/app/shared/services/rest/members.service';
import { map, switchMap } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import { MY_FORMATS } from 'src/app/components/atTasksDialog/task-profile-add/task-profile-add.component';
import * as moment from 'moment-timezone';
import { NgxMatDateAdapter } from '@angular-material-components/datetime-picker';
import { RefreshService } from 'src/app/shared/services/rest/refresh.service';
@Component({
  selector: 'app-multi-edit-start-at',
  templateUrl: './multi-edit-start-at.component.html',
  styleUrls: ['./multi-edit-start-at.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})

export class MultiEditStartAtComponent extends BaseClass implements OnInit, OnDestroy {
  public operations: any;
  public isFormChange: boolean = false;
  public form: FormGroup;

  constructor(
    private taskService: TaskService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<MultiEditStartAtComponent>,
    private fb: FormBuilder,
    private languageService: LanguageService,
    private layoutService: LayoutService,
    private _ngx_adapter: NgxMatDateAdapter<any>,
    private _adapter: DateAdapter<any>,
    private membersService: MembersService,
    private refreshService: RefreshService
  ) { super() }

  ngOnInit(): void {
    console.log("MultiEditStartAtComponent", this.data);
    this.getOperations();

  
    this.form = this.fb.group({
      start_at: '',
    })


    this.attachSubscriptions(
      this.form.valueChanges.subscribe(() => this.isFormChange = true)
    )

    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        if (this.isFormChange) {
          this.layoutService.openBottomSheet(this.dialogRef);
        } else {
          this.close();
        }
      })
    )

    this.getLangData();

  }

  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData().subscribe(resp => {
        this._ngx_adapter.setLocale(resp.active)
        this._adapter.setLocale(resp.active);
      })
    )
  }

  getOperations() {
    this.attachSubscriptions(
      this.taskService.getOperations(this.data.company.id).subscribe(resp => {
        this.operations = resp;
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  initRefreshBoard() {
    this.refreshService.refreshBoard$.next('refresh')
  }
  
  onSubmit() {
    let submData = {...this.form.value};


    if (!!this.form.value.start_at && !!this.form.value.start_at._d) {
      submData.start_at = submData.start_at.unix();
    } else {
      submData.start_at = '0';
    }
    // submData.initial_start_at = submData.start_at;

    this.attachSubscriptions(
      forkJoin(this.data.boardCollection.map(x => this.membersService.editTaskEmployeeStatus(x.id, submData, this.data.company.id))).subscribe(resp => {
        this.initRefreshBoard();
        this.data.boardCollection.map(x => x.start_at = submData.start_at);
        this.dialogRef.close({event: "update"})
      })
    )
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

}
