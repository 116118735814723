import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseClass } from 'src/app/shared/models/base-class';

@Component({
  selector: 'app-mob-scheduled-types-filter',
  templateUrl: './mob-scheduled-types-filter.component.html',
  styleUrls: ['./mob-scheduled-types-filter.component.scss']
})

export class MobScheduledTypesFilterComponent extends BaseClass implements OnInit, OnDestroy {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<MobScheduledTypesFilterComponent>,
  ) { super() }

  ngOnInit(): void {
    console.log("MobScheduledTypesFilterComponent", this.data)

    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        this.close();
      })
    )
  }

  close() {
    this.dialogRef.removePanelClass('animate__slideInUp');
    this.dialogRef.addPanelClass('animate__slideOutDown');
    setTimeout(()=>{this.dialogRef.close({event: 'close'})}, 300);
  }

  onSubmit() {
    this.dialogRef.removePanelClass('animate__slideInUp');
    this.dialogRef.addPanelClass('animate__slideOutDown');
    setTimeout(()=>{this.dialogRef.close({event: 'apply'})}, 300);
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

}
