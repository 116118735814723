import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { MembersService } from 'src/app/shared/services/rest/members.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';

@Component({
  selector: 'app-work-rate-edit',
  templateUrl: './work-rate-edit.component.html',
  styleUrls: ['./work-rate-edit.component.scss']
})
export class WorkRateEditComponent extends BaseClass implements OnInit, OnDestroy {
  public form: FormGroup;
  public isFormChange: boolean = false;
  public numberRegEx = /\d*\.?\d{1,2}/;
  
  constructor(
    private taskService: TaskService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<WorkRateEditComponent>,
    private fb: FormBuilder,
    private membersService: MembersService,
    private layoutService: LayoutService
  ) { super() }

  ngOnInit(): void {

    this.form = this.fb.group({
      rate: [this.data.work.employee.rate, [Validators.pattern(this.numberRegEx)]],
      price: [this.data.work.employee.price, [Validators.pattern(this.numberRegEx)]]
    })

    if (!this.data.empl_status) {
      if (this.data.company.rate_target == '0.0000') {
        this.form.removeControl("rate");
      } else {
        this.form.removeControl("price");
      }
    }

    this.attachSubscriptions(
      this.form.valueChanges.subscribe(() => this.isFormChange = true)
    )

    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        if (this.isFormChange) {
          this.layoutService.openBottomSheet(this.dialogRef);
        } else {
          this.close();
        }
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  onSubmit() {
    if (String(this.form.value['rate']).indexOf(",") != -1) {
      this.form.value['rate'] = this.form.value['rate'].replace(",", ".");
    }
    if (String(this.form.value['price']).indexOf(",") != -1) {
      this.form.value['price'] = this.form.value['price'].replace(",", ".");
    }

    if (this.form.value.price == null) {
      this.form.value.price = "0";
    }

    this.attachSubscriptions(
      this.membersService.editMember(this.data.work.employee.id, this.form.value, this.data.company.id).subscribe(resp => {
        this.dialogRef.close({event: "update", data: resp})
      })
    )
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

}
