import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { FileService } from 'src/app/shared/services/rest/file.service';
import { UploadService } from 'src/app/shared/services/rest/upload.service';
import { environment } from 'src/environments/environment';
import { SnackBarItem } from '../../snack-bar/snack-bar-item';
import { switchMap, tap } from 'rxjs/operators';
import { mimeTypes } from 'mime-wrapper';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { SelectFilesForCheckComponent } from 'src/app/components/workspace-pages/cases/reminders/dialogs/select-files-for-check/select-files-for-check.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-check-list-items',
  templateUrl: './check-list-items.component.html',
  styleUrls: ['./check-list-items.component.scss']
})
export class CheckListItemsComponent extends BaseClass implements OnInit, OnDestroy {
  @ViewChild('checkUploader') checkUploader: ElementRef<HTMLInputElement>;
  public host: any = environment.host;
  public uploadLimit: boolean = false;
  @Input() job: any;
  @Input() task: any;
  @Input() user: any;
  @Input() activeLang: any;
  @Input() operationsValues: any;
  @Input() company: any;
  @Output() upClick = new EventEmitter<any>();
  public selectedCheck: any;
  public selectedJob: any;
  public selectedTask: any;
  
  constructor(
    private fileService: FileService,
    private uploadService: UploadService,
    private dialog: MatDialog,
    private taskService: TaskService,
    private layoutService: LayoutService,
  ) {
    super()
   }

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges): void {
    this.attachSubscriptions(
      this.uploadService.getUploadLimit().pipe(
        tap(res => {
          if (res || (this.company && this.company.filesize >= this.company.filesize_limit)) {
            this.uploadLimit = true;
          }
        })
      ).subscribe(res => {
        console.log(res)
      })
    )
  }

  understandFileType(val) {
    if (!val) {
      return ""
    }
    
    if (val == 'application/pdf') {
      return 'pdf'
    } else if (val.indexOf("/") != -1) {
      return val.split('/')[0]
    } else {
      return ""
    }
  }

  
  onListSelectionChange(val: MatCheckboxChange, check, job) {
    check.disable = true;
    if (!check.status) {
      this.attachSubscriptions(
        this.taskService.createOperationReminderStatus(job.id, check.id, this.company.id).subscribe(resp => {
          check.status = resp;
          check.disable = false;
          console.log(check)
          // if (job.checklist.filter(x => x.status && x.status.is_done == 1).length == job.checklist.length || (job.checklist.length == 0 || !job.checklist)) {
          //   this.taskService.editWork(job.id, {is_reminder: 0}, this.company_id).subscribe(resp => {
          //     console.log(resp)
          //     job.is_reminder = resp.is_reminder
          //   })
          // }
        }, error => {
          delete check.status;
          val.source.toggle()
          this.layoutService.showSnackBar({name: ''}, marker("You do not have permission to do this. Error: " + error), SnackBarItem)
          
        })
      )
    } else {
      // if (job.checklist.filter(x => x.status && x.status.is_done == 1).length == job.checklist.length || (job.checklist.length == 0 || !job.checklist)) {
      //   this.taskService.editWork(job.id, {is_reminder: 1}, this.company_id).subscribe(resp => {
      //     console.log(resp)
      //     job.is_reminder = resp.is_reminder
      //   })
      // }
      this.attachSubscriptions(
        this.taskService.deleteOperationReminderStatus(check.status.id).subscribe(resp => {
          check.status = resp
          check.disable = false;
          console.log(check)
        })
      )
    }

    console.log("afterChange", job.checklist)
  }

  uploadFileBtn(job, check, task) {
    if (!check.hasOwnProperty('files')) {
      check.files = [];
    }
    this.selectedCheck = check
    this.selectedJob = job;
    this.selectedTask = task;
    this.checkUploader.nativeElement.click()
  }


  uploadFile(event) {
    if (this.uploadLimit) {
      this.layoutService.showSnackBar({name: ''}, marker("This company has exceeded the file upload limit."), SnackBarItem)
      return
    }
    if (event.target.files.length > 0) {
      this.selectedCheck.filesCount = (this.selectedCheck.filesCount ? this.selectedCheck.filesCount : 0) + event.target.files.length
      for (let index = 0; index < event.target.files.length; index++) {
        const element = event.target.files[index];
        this.putFile(element, false, "/ready_files");
      }
    } 
  }

  putFile(file, is_folder:boolean = false, initPath:any = false) {
    let path = '/ready_files'

    this.fileService.uploadFile({
      company_id: this.company.id,
      task_id: this.selectedJob.task_id,
      task_operation_id: this.selectedJob.id,
      filesize: file.size,
      filename: file.name,
      operation_reminder_id: this.selectedCheck.id,
      content_type: file.type ? file.type : mimeTypes.getType(file.name),
      location: path,
      is_dir: 0
    }, this.company.id).subscribe(resp => {
      console.log("putFile", this.selectedCheck)
      this.fileService.files$.next({
        place: "file_manager",
        url: window.location.href,
        data: resp,
        files: this.selectedCheck.files,
        location: path,
        task: this.selectedTask,
        work: this.selectedJob,
        activeLang: this.activeLang,
        operationsValues: this.operationsValues,
        target: file,
        operation_reminder: this.selectedCheck,
        task_id: this.selectedJob.task_id,
        work_id: this.selectedJob.id,
        company_id: this.company.id,
        company: this.company,
        user: this.user
      })
      // this.selectedJob = undefined
    })
  }

  selectFilesFromJob(task, job, check) {
    const dialogRef = this.dialog.open(SelectFilesForCheckComponent, {
      data: {
        company: this.company,
        task: task,
        work: job,
        user: this.user,
        check: check,
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().pipe(
        switchMap(() => this.fileService.getFilesForCheck(this.company.id, job.id, check.id, '/', '200'))
      ).subscribe(result => {
        check.files = result.body
      })
    )
  }

  
  onFileDropped(e, check, job) {
    console.log("onFileDropped", e, check, job)

    this.getFilesDataTransferItems(e.dataTransfer.items).then((files:any) => {
      if (files.length > 0) {
        for (let index = 0; index < files.length; index++) {
          this.company.filesize = this.company.filesize + files[index].size
        }
      }
    })

    if (this.uploadLimit) {
      this.layoutService.showSnackBar({name: ''}, marker("This company has exceeded the file upload limit."), SnackBarItem)
      return
    }
    if (!e.dataTransfer.files.length) {
      return false;
    }

    this.selectedCheck = check;
    this.selectedJob = job;

    this.getFilesDataTransferItems(e.dataTransfer.items).then((files:any) => {
      if (files.length > 0) {
        for (let index = 0; index < files.length; index++) {
          this.putFile(files[index]);
        }
      }
    });
    
    if (this.company.filesize >= this.company.filesize_limit) {
      this.uploadService.uploadLimit$.next(true)
    }
  }
  getFilesDataTransferItems(dataTransferItems) {
    function traverseFileTreePromise(item, folder) {
      return new Promise(resolve => {
        if (item.isFile) {
          item.file(file => {
            file.filepath = item.fullPath.replace(`/${file.name}`, "");
            folder.push(file);
            resolve(file);
          });
        } else if (item.isDirectory) {
          let dirReader = item.createReader();
          dirReader.readEntries(entries => {
            let entriesPromises = [];
            for (let entr of entries)
              entriesPromises.push(
                traverseFileTreePromise(entr, folder)
              );
            resolve(Promise.all(entriesPromises));
          });
        }
      });
    }
  
    let files = [];
    return new Promise((resolve, reject) => {
      let entriesPromises = [];
      for (let it of dataTransferItems)
        entriesPromises.push(
          traverseFileTreePromise(it.webkitGetAsEntry(), files)
        );
      Promise.all(entriesPromises).then(entries => {
        resolve(files);
      });
    });
  }

  detectBrowserName() { 
    const agent = window.navigator.userAgent.toLowerCase()
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }


  openCheck(job, check) {
    check.open = !check.open

    if (check.open) {
      this.attachSubscriptions(
        this.fileService.getFilesForCheck(this.company.id, job.id, check.id, '/', '200').subscribe(res => {
          check.files = res.body
          console.log(check.files)
        })
      )
    }
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

}
