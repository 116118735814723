<div class="video_editor_page video_editor video_editor_black video_editor_dialog">
    <mat-dialog-title class="video_editor_title" [ngClass]="{'sp_b': data?.thumbMode}">
        <div class="left_ve">
            <ng-container *ngIf="!data?.thumbMode">
                <span (click)="logCuts()" class="version">
                    V{{version}}
                    <span>{{vidProject?.id}}</span>
                </span>
                <span class="mode" [ngClass]="{'active': saveMode.value == 'parts'}" (click)="toggleMode(saveMode.value)">
                    {{"Parts mode" | translate}}
                </span>
                <span class="mode" [ngClass]="{'active': !!is_create_cards.value}" (click)="toggleControl(is_create_cards, is_create_cards.value)" *ngIf="data.company && data.company?.permissions?.includes('create_task')">
                    {{"Create cards" | translate}}
                    <mat-icon *ngIf="!!is_create_cards.value" (click)="$event.preventDefault(); $event.stopPropagation(); openCreateCards()" class="icon-16" style="margin-left: 2.5px;">settings</mat-icon>
                </span>
                <span class="mode" *ngIf="saveMode.value != 'parts'" [ngClass]="{'active': !!is_save_audio.value}" (click)="toggleControl(is_save_audio, is_save_audio.value)">
                    {{"Save Audio" | translate}}
                </span>
                <span class="mode" (click)="getTags(true)">
                    {{"Refresh Tags" | translate}}
                </span>
                <span class="mode" [ngClass]="{'active': !!isSubsShow}" (click)="openSubs()">
                    <!-- {{"Subs" | translate}} -->
                    <mat-icon>subtitles</mat-icon>
                </span>
                <div class="relations">
                    <span>
                        {{ "Connect cards" | translate }}
                    </span>
                    <div [ngClass]="{'active': !!is_create_vid_relations.value}" (click)="toggleControl(is_create_vid_relations, is_create_vid_relations.value)">{{ "Video" | translate }}</div>
                    <div [ngClass]="{'active': !!is_create_aud_relations.value}" (click)="toggleControl(is_create_aud_relations, is_create_aud_relations.value)">{{ "Audio" | translate }}</div>
                </div>
            </ng-container>
        </div>
    
        <div class="right_ve">
            <div class="d-f-c" style="margin-right: 20px;" *ngIf="!data?.thumbMode">
                <button [disableRipple]="true" mat-icon-button [matMenuTriggerFor]="menu" class="mr-1"><mat-icon class="icon-16">cameraswitch</mat-icon></button>
                <mat-menu #menu="matMenu" class="ratio_value_menu_class" overlayPanelClass='ratio_value_menu'>
                    <button (click)="toNewOrient(false)" [disableRipple]="true" mat-menu-item [ngClass]="{'active_orient': projectForm.get('width').value > projectForm.get('height').value}"><mat-icon style="color: #fff;">width_wide</mat-icon>{{ "Horizontal" | translate}}</button>
                    <button (click)="toNewOrient(true)" [disableRipple]="true" mat-menu-item [ngClass]="{'active_orient': projectForm.get('width').value < projectForm.get('height').value}"><mat-icon style="transform: rotate(90deg); color: #fff;">width_wide</mat-icon>{{ "Vertical" | translate}}</button>
                </mat-menu>
    
                <form [formGroup]="projectForm" class="d-f-c w-h-form">
                    <div class="d-f-c">
                        <span>W</span>
                        <input class="ex_number reports_white_input" matInput type="number" formControlName="width" (input)="onInputHolst('width')">
                    </div>
                    <span class="ml-1 mr-1">x</span>
                    <div class="d-f-c">
                        <span>H</span>
                        <input class="ex_number reports_white_input" matInput type="number" formControlName="height" (input)="onInputHolst('height')">
                    </div>
                </form>
                <div (click)="toggleRatio()" class="holst_ratio" [ngClass]="{'active': !!holstRatio}">
                    <ng-container *ngIf="holstRatio == 0; else elseTemplateRat">
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#fff"><path d="M0 0h24v24H0z" fill="none"/><path d="M 14 3.9 c 1.71 0 3.1 1.39 3.1 3.1 v 4 H 19 V 7 c 0 -2.76 -2.24 -5 -5 -5 s -5 2.24 -5 5 v 4 h 1.9 V 7 c 0 -1.71 1.39 -3.1 3.1 -3.1 z m -1 4.1 z m 6 9 v -4 h -1.9 v 4 c 0 1.71 -1.39 3.1 -3.1 3.1 s -3.1 -1.39 -3.1 -3.1 v -4 H 9 v 4 c 0 2.76 2.24 5 5 5 s 5 -2.24 5 -5 z"/></svg>
                    </ng-container>
                    <ng-template #elseTemplateRat>
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#fff"><path d="M0 0h24v24H0z" fill="none"/><path d="M 14 3.9 c 1.71 -0 3.1 1.39 3.1 3.1 v 4 H 19 V 7 c -0 -2.76 -2.24 -5 -5 -5 s -5 2.24 -5 5 v 4 h 1.9 V 7 c -0 -1.71 1.39 -3.1 3.1 -3.1 z M 13 8 v 8 h 2 V 8 h -2 z m 6 9 v -4 h -1.9 v 4 c 0 1.71 -1.39 3.1 -3.1 3.1 s -3.1 -1.39 -3.1 -3.1 v -4 H 9 v 4 c 0 2.76 2.24 5 5 5 s 5 -2.24 5 -5 z"/></svg>
                    </ng-template>
                </div>
    
                <form [formGroup]="rateForm" class="d-f-c w-h-form ml-1">
                    <div class="d-f-c">
                        <span>FPS</span>
                        <span class="ex_number">{{rateForm.value.output_frame_rate}}</span>
                        <button type="button" mat-icon-button [matMenuTriggerFor]="rateMenu" aria-label="Example icon-button with a menu">
                            <mat-icon>arrow_drop_down</mat-icon>
                        </button>
                        <mat-menu #rateMenu="matMenu" class="ratio_value_menu_class" overlayPanelClass='ratio_value_menu'>
                            <button mat-menu-item [ngClass]="{'active': rateForm.value.output_frame_rate == 23.98}" (click)="patchRate(23.98)">
                              <span>23.98</span>
                            </button>
                            <button mat-menu-item [ngClass]="{'active': rateForm.value.output_frame_rate == 24}" (click)="patchRate(24)">
                              <span>24p</span>
                            </button>
                            <button mat-menu-item [ngClass]="{'active': rateForm.value.output_frame_rate == 25}" (click)="patchRate(25)">
                              <span>25p</span>
                            </button>
                            <button mat-menu-item [ngClass]="{'active': rateForm.value.output_frame_rate == 30}" (click)="patchRate(30)">
                              <span>30p</span>
                            </button>
                            <button mat-menu-item [ngClass]="{'active': rateForm.value.output_frame_rate == 50}" (click)="patchRate(50)">
                              <span>50p</span>
                            </button>
                            <button mat-menu-item [ngClass]="{'active': rateForm.value.output_frame_rate == 59.94}" (click)="patchRate(59.94)">
                              <span>59.94p</span>
                            </button>
                            <button mat-menu-item [ngClass]="{'active': rateForm.value.output_frame_rate == 60}" (click)="patchRate(60)">
                              <span>60p</span>
                            </button>
                            <button mat-menu-item [ngClass]="{'active': rateForm.value.output_frame_rate == 120}" (click)="patchRate(120)">
                              <span>120p</span>
                            </button>
                        </mat-menu>
                          
                    </div>
                    <div class="d-f-c ml-1">
                        <span>BR</span>
                        <input class="ex_number reports_white_input" matInput type="number" min="0" max="30" step="1" formControlName="output_bit_rate" (change)="onInputRate($event)">
                    </div>
                    <div class="d-f-c ml-1" #tooltip="matTooltip" [matTooltipPosition]="'below'" matTooltip="{{ 'Bitrate Audio' | translate }}">
                        <span>BRA</span>
                        <span class="ex_number">{{rateForm.value.output_audio_bit_rate}}</span>
                        <button type="button" mat-icon-button [matMenuTriggerFor]="rateAMenu" aria-label="Example icon-button with a menu">
                            <mat-icon>arrow_drop_down</mat-icon>
                        </button>
                        <mat-menu #rateAMenu="matMenu" class="ratio_value_menu_class" overlayPanelClass='ratio_value_menu'>
                            <button mat-menu-item [ngClass]="{'active': rateForm.value.output_audio_bit_rate == 32000}" (click)="patchARate(32000)">
                                <span>32 kbit/s</span>
                            </button>
                            <button mat-menu-item [ngClass]="{'active': rateForm.value.output_audio_bit_rate == 96000}" (click)="patchARate(96000)">
                                <span>96 kbit/s</span>
                            </button>
                            <button mat-menu-item [ngClass]="{'active': rateForm.value.output_audio_bit_rate == 128000}" (click)="patchARate(128000)">
                                <span>128 kbit/s</span>
                            </button>
                            <button mat-menu-item [ngClass]="{'active': rateForm.value.output_audio_bit_rate == 160000}" (click)="patchARate(160000)">
                                <span>160 kbit/s</span>
                            </button>
                            <button mat-menu-item [ngClass]="{'active': rateForm.value.output_audio_bit_rate == 192000}" (click)="patchARate(192000)">
                                <span>192 kbit/s</span>
                            </button>
                            <button mat-menu-item [ngClass]="{'active': rateForm.value.output_audio_bit_rate == 256000}" (click)="patchARate(256000)">
                                <span>256 kbit/s</span>
                            </button>
                            <button mat-menu-item [ngClass]="{'active': rateForm.value.output_audio_bit_rate == 320000}" (click)="patchARate(320000)">
                                <span>320 kbit/s</span>
                            </button>
                        </mat-menu>
                    </div>
                </form>
    
                <div class="ve_input_out ml-1" *ngIf="vidProject">
                    <input class="ve_input reports_white_input" style="max-width: 250px;" [(ngModel)]="vidProject.name" matInput placeholder="{{ 'Project Name' | translate }}">
                </div>
            </div>
            <div class="d-f-c">
                <ng-container *ngIf="!data?.thumbMode; else elseTemplate">
                    <div class="zoom_group small_group">
                        <div class="save" [ngClass]="{'saving': is_saving}" (click)="saveVideoEditorHistory('User save')">
                            <mat-icon>save</mat-icon>
                        </div>
                        <div class="zoom_out" [ngClass]="{'dis_zoom': !hasPrevHistory()}" (click)="historyPrev()">
                            <mat-icon>undo</mat-icon>
                        </div>
                        <div class="zoom_def" [ngClass]="{'dis_zoom': !hasNextHistory()}" (click)="historyNext()">
                            <mat-icon>redo</mat-icon>
                        </div>
                    </div>
    
                    <button [disabled]="true" class="head_btn" [ngClass]="{'red': current_version != r_version}" style="cursor: default !important;">
                        {{r_version}}
                    </button>
                    <!-- <button [disabled]="isSubmit" class="head_btn" (click)="openScrnSettings()">
                        {{"Scrn"| translate}}
                    </button> -->
                    <button [disabled]="isSubmit" class="head_btn" (click)="openAutomationProjects(true)">
                        {{"Automation"| translate}}
                    </button>
                    <button [disabled]="isSubmit" [ngStyle]="{'submited_btn': isSubmit}" class="head_btn" (click)="save(true)">
                        <mat-spinner class="spin_16 mr-1" *ngIf="isSubmit"></mat-spinner> 
                        {{"Save project"| translate}}
                    </button>
        
                    <div class="cut_value">
                        <div (click)="priority.open()">
                            <!-- <ng-container [ngSwitch]="waiting_time_limit.value">
                                <ng-container *ngSwitchCase="1">
                                    {{ "Now" | translate }}
                                </ng-container>
                                <ng-container *ngSwitchCase="300">
                                    {{ "Immediately" | translate }}
                                </ng-container>
                                <ng-container *ngSwitchCase="1200">
                                    {{ "Urgent" | translate }}
                                </ng-container>
                                <ng-container *ngSwitchCase="14400">
                                    {{ "High" | translate }}
                                </ng-container>
                                <ng-container *ngSwitchCase="43200">
                                    {{ "Middle" | translate }}
                                </ng-container>
                                <ng-container *ngSwitchCase="86400">
                                    {{ "Low" | translate }}
                                </ng-container>
                                <ng-container *ngSwitchCase="172800">
                                    {{ "No priority" | translate }}
                                </ng-container>
                                <ng-container *ngSwitchDefault>
                                    {{ "Priority" | translate }}
                                </ng-container>
                            </ng-container> -->
                            {{ "Priority" | translate }}
                            <!-- {{waiting_time_limit.value}} -->
                        </div>
                        <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                            <mat-select disableOptionCentering #priority [formControl]="waiting_time_limit">
                                <mat-option value="1" *ngIf="!!data?.user?.is_root" [ngClass]="{'cst_active_select_option': waiting_time_limit.value == 1}">
                                    {{ "Now" | translate }}
                                </mat-option>
                                <mat-option value="300" *ngIf="!!data?.user?.is_root" [ngClass]="{'cst_active_select_option': waiting_time_limit.value == 300}">
                                    {{ "Immediately" | translate }} (x3 VPU) 
                                </mat-option>
                                <mat-option value="1200" [ngClass]="{'cst_active_select_option': waiting_time_limit.value == 1200}">
                                    {{ "Urgent" | translate }} (x2 VPU)
                                </mat-option>
                                <mat-option value="14400" [ngClass]="{'cst_active_select_option': waiting_time_limit.value == 14400}">
                                    {{ "High" | translate }} (x1.2 VPU)
                                </mat-option>
                                <mat-option value="43200" [ngClass]="{'cst_active_select_option': waiting_time_limit.value == 43200}">
                                    {{ "Middle" | translate }} (x1.2 VPU)
                                </mat-option>
                                <mat-option value="86400" [ngClass]="{'cst_active_select_option': waiting_time_limit.value == 86400}">
                                    {{ "Low" | translate }}
                                </mat-option>
                                <mat-option value="172800" [ngClass]="{'cst_active_select_option': waiting_time_limit.value == 172800}">
                                    {{ "No priority" | translate }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
        
                    <button [disabled]="isSubmit" [ngStyle]="{'submited_btn': isSubmit}" class="head_btn head_btn_green" (click)="save()">
                        <mat-spinner class="spin_16 mr-1" *ngIf="isSubmit"></mat-spinner> 
                        {{"Export"| translate}}
                    </button>
                </ng-container>
                <ng-template #elseTemplate>
                    <button [disabled]="isCreateThumb" [ngStyle]="{'submited_btn': isCreateThumb}" class="head_btn head_btn_green" (click)="generateVideoFrame()">
                        <mat-spinner class="spin_16 mr-1" *ngIf="isCreateThumb"></mat-spinner> 
                        {{"Use this frame as thumbnail"| translate}}
                    </button>
                </ng-template>
                
    
                <button class="mr-1" #expSett mat-icon-button (click)="$event.preventDefault(); $event.stopPropagation(); exportSettings(expSett);">
                    <mat-icon>settings</mat-icon>
                </button>
                <button mat-icon-button (click)="close()">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </div>
    </mat-dialog-title>
    <mat-dialog-content class="video_editor_content">
        <div class="video_edtior_screen" #videoContainer>
            <div [ngStyle]="{'height': 'calc(100% - ' + outContainer?.offsetHeight + 'px)'}">
                <div class="panels" [ngClass]="{'forms_activated': is_create_cards.value, 'rd_info_opened': hasCropOn()}">
                    <ng-container *ngIf="!data?.thumbMode">
                        <div class="panel_options" [ngClass]="{'cards_active': is_create_cards.value}">
                            <ng-container *ngIf="editedSub; else elseTemplateIsEditSub">
                                <div class="subs_text">
                                    <div class="new_text_out">
                                        <div class="new_text_title" (click)="logNewText(editedSub?.sub)">
                                            {{"Editing sub text" | translate}}
                                        </div>
    
                                        <div class="timings">
                                            <div class="time">
                                                <span>{{"from" | translate}}</span>
                                                <input class="ex_number reports_white_input" matInput type="number" [(ngModel)]="editedSub.sub.from">
                                            </div>
                                            <div class="time">
                                                <span>{{"to" | translate}}</span>
                                                <input class="ex_number reports_white_input" matInput type="number" [(ngModel)]="editedSub.sub.to">
                                            </div>
                                        </div>
    
                                        <textarea class="ex_number reports_white_input" matInput type="text" [(ngModel)]="editedSub.sub.text"></textarea>
                                        
                                        <div class="sub_nav_btn" style="width: unset;" (click)="saveEditSub($event)" #tooltip="matTooltip" [matTooltipPosition]="'above'" matTooltip="{{ 'Save sub' | translate }}">
                                            <mat-icon class="icon-16">edit</mat-icon>
                                            {{"Edit" | translate}}
                                        </div>
                                        <div class="sub_nav_btn" style="width: unset; margin-top: 5px;" (click)="deleteSub($event, editedSub.part, editedSub.sInd)" #tooltip="matTooltip" [matTooltipPosition]="'above'" matTooltip="{{ 'Save sub' | translate }}">
                                            <mat-icon class="icon-16">delete</mat-icon>
                                            {{"Delete" | translate}}
                                        </div>
    
                                        <div style="margin-top: 10px;">{{'Mark subtitle' | translate}}</div>
                                        <div class="colors">
                                            <div class="color_out" [ngClass]="{'active': editedSub?.sub?.background == '#2986f0' || !editedSub?.sub?.background}">
                                                <div class="color" style="background: #2986f0;" (click)="setSubColor('#2986f0')"></div>
                                            </div>
                                            <div class="color_out" [ngClass]="{'active': editedSub?.sub?.background == '#34495e'}">
                                                <div class="color" style="background: #34495e;" (click)="setSubColor('#34495e')"></div>
                                            </div>
                                            <div class="color_out" [ngClass]="{'active': editedSub?.sub?.background == '#00cec9'}">
                                                <div class="color" style="background: #00cec9;" (click)="setSubColor('#00cec9')"></div>
                                            </div>
                                            <div class="color_out" [ngClass]="{'active': editedSub?.sub?.background == '#1abc9c'}">
                                                <div class="color" style="background: #1abc9c;" (click)="setSubColor('#1abc9c')"></div>
                                            </div>
                                            <div class="color_out" [ngClass]="{'active': editedSub?.sub?.background == '#733469'}">
                                                <div class="color" style="background: #733469;" (click)="setSubColor('#733469')"></div>
                                            </div>
                                            <div class="color_out" [ngClass]="{'active': editedSub?.sub?.background == '#b32828'}">
                                                <div class="color" style="background: #b32828;" (click)="setSubColor('#b32828')"></div>
                                            </div>
                                            <div class="color_out" [ngClass]="{'active': editedSub?.sub?.background == '#e84393'}">
                                                <div class="color" style="background: #e84393;" (click)="setSubColor('#e84393')"></div>
                                            </div>
                                            <div class="color_out" [ngClass]="{'active': editedSub?.sub?.background == '#d56f24'}">
                                                <div class="color" style="background: #d56f24;" (click)="setSubColor('#d56f24')"></div>
                                            </div>
                                            <div class="color_out" [ngClass]="{'active': editedSub?.sub?.background == '#dfff03'}">
                                                <div class="color" style="background: #dfff03;" (click)="setSubColor('#dfff03')"></div>
                                            </div>
                                            <div class="color_out" [ngClass]="{'active': editedSub?.sub?.background == '#12a50d'}">
                                                <div class="color" style="background: #12a50d;" (click)="setSubColor('#12a50d')"></div>
                                            </div>
                                            <div class="color_out" [ngClass]="{'active': editedSub?.sub?.background == '#1dd1a1'}">
                                                <div class="color" style="background: #1dd1a1;" (click)="setSubColor('#1dd1a1')"></div>
                                            </div>
                                            <div class="color_out" [ngClass]="{'active': editedSub?.sub?.background == '#7f8c8d'}">
                                                <div class="color" style="background: #7f8c8d;" (click)="setSubColor('#7f8c8d')"></div>
                                            </div>
                                            <div class="color_out" [ngClass]="{'active': editedSub?.sub?.background == '#c8d6e5'}">
                                                <div class="color" style="background: #c8d6e5;" (click)="setSubColor('#c8d6e5')"></div>
                                            </div>
                                     
                                        </div>
    
                                        <div style="margin-top: 10px;">{{'Speaker' | translate}}</div>
                                        <div>
                                            <input class="ve_input reports_white_input" style="max-width: 75%;" [(ngModel)]="editedSub.sub.speaker" matInput placeholder="{{ 'Speaker Name' | translate }}">
                                        </div>
                                        
                                        
                                        <!-- <div class="words_add" *ngIf="activeItem?.subSet.newText && activeItem?.subSet.newText.words">
                                            <div class="word_add" *ngFor="let newWord of activeItem?.subSet.newText.words; let nwInd = index;">
                                                <input class="ex_number reports_white_input" matInput type="text" [(ngModel)]="newWord.value" (change)="onChangeNewWord($event, activeItem?.subSet.newText, newWord, nwInd)">
                                                <div class="time">
                                                    <span>{{"from" | translate}}</span>
                                                    <input class="ex_number reports_white_input" matInput type="number" [(ngModel)]="newWord.from" (change)="onChangeNewWordTime($event, activeItem?.subSet.newText, newWord, nwInd)">
                                                </div>
                                                <div class="time">
                                                    <span>{{"to" | translate}}</span>
                                                    <input class="ex_number reports_white_input" matInput type="number" [(ngModel)]="newWord.to" (change)="onChangeNewWordTime($event, activeItem?.subSet.newText, newWord, nwInd)">
                                                </div>
                                            </div>
                                        </div> -->
                                    </div>
                                </div>
                                <div class="subs_nav">
                                    <div></div>

                                    <div class="d-f-c">
                                        <div class="sub_nav_btn" (click)="closeEditSub($event)" #tooltip="matTooltip" [matTooltipPosition]="'above'" matTooltip="{{ 'Close Editing Sub' | translate }}">
                                            <mat-icon>close</mat-icon>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-template #elseTemplateIsEditSub>
                                <ng-container *ngIf="isSubsShow; else elseTemplateSubsShow">
                                    <div class="subs_text">
                                        <ng-container *ngFor="let trackEl of tracks; let k = index;">
                                            <ng-container *ngIf="trackEl.type == 'video'">
                                                <ng-container *ngFor="let part of trackEl.parts; let p = index;">
                                                    <!-- (click)="activateItem($event, trackEl, k, part, p)" -->
                                                    <div class="sub_title cp">
                                                        {{part.filename}}
                                                    </div>
                                                    <ng-container *ngIf="!!part.subs_on && part.subs">
                                                        <ng-container *ngFor="let sub of part.subs; let sInd = index;">
                                                            <div class="sub_text" *ngIf="sub.from > part.from && (sub.to < part.to)">
                                                                <div class="sub_time">
                                                                    <!-- <div class="d-f-c">
                                                                        <mat-icon class="show_on_hover" (click)="deleteSub($event, part, sInd)">delete</mat-icon>
                                                                        <mat-icon class="show_on_hover ml-5" (click)="editSub($event, sub, part, sInd)">edit</mat-icon>
                                                                    </div> -->
                                                                    <span (click)="timeSubClick(part, sub)" (dblclick)="editSub($event, sub, part, sInd)">{{useSpeedRate((+sub.from - +part.from + +part.prevAllDuration), part)|veTime:false}} - {{useSpeedRate((+sub.to - +part.from + +part.prevAllDuration), part)|veTime:false}}</span>
                                                                    <ng-container *ngIf="!!sub.speaker; else elseTemplateNoSpeak">
                                                                        <b class="speaker_name text_one_line" [ngStyle]="{'color': sub?.background || '#2986f0'}">{{sub.speaker}}</b>
                                                                    </ng-container>
                                                                    <ng-template #elseTemplateNoSpeak>
                                                                        <div class="point_circle_out">
                                                                            <b class="point_circle" [ngStyle]="{'background': sub?.background || '#2986f0'}"></b>
                                                                        </div>
                                                                    </ng-template>
                                                                </div>
                                                                <div *ngIf="sub.words" class="sub_text_words cp" (click)="timeSubClick(part, sub)">
                                                                    <ng-container *ngFor="let word of sub.words; let sI = index; let last = last">
                                                                        <ng-container *ngIf="(!isProjectPlaying && !!verticalLine?.show) ? ((currentCutTimeProject === 0 && verticalLine.val - part.prevAllDuration >= useSpeedRate(+word.from - +part.from, part) && verticalLine.val - part.prevAllDuration < useSpeedRate(+word.to - +part.from, part))) : ((currentCutTimeProject === 0 && currentTimeProject - part.prevAllDuration >= useSpeedRate(+word.from - +part.from, part) && currentTimeProject - part.prevAllDuration < useSpeedRate(+word.to - +part.from, part)) || (currentCutTimeProject !== 0 && currentCutTimeProject - part.prevAllDuration >= useSpeedRate(+word.from - +part.from, part) && currentCutTimeProject - part.prevAllDuration < useSpeedRate(+word.to - +part.from, part))); else elseTemplateActiveWord">
                                                                            <span class="sub_word active_word" (contextmenu)="openWordEdit($event, part, sub, word, sI); $event.preventDefault(); $event.stopPropagation();"
                                                                            [ngStyle]="{ 
                                                                                'margin-right': last ? '0' : '0.25em',
                                                                                'color': !!part?.subSet?.is_karaoke ? part?.subSet?.activeColor : part?.subSet?.pastColor,
                                                                                'text-decoration': !!part?.subSet?.underline ? 'underline '+ part?.subSet?.activeColor : (!!part?.subSet?.strike ? 'line-through ' + part?.subSet?.activeColor : 'none')
                                                                            }">{{word.value}}</span>
                                                                        </ng-container>
                                                                        <ng-template #elseTemplateActiveWord>
                                                                            <ng-container *ngIf="(!isProjectPlaying && !!verticalLine?.show) ? ((currentCutTimeProject === 0 && verticalLine.val - part.prevAllDuration < useSpeedRate(+word.to - +part.from, part))) : ((currentCutTimeProject === 0 && currentTimeProject - part.prevAllDuration < useSpeedRate(+word.to - +part.from, part)) || (currentCutTimeProject !== 0 && currentCutTimeProject - part.prevAllDuration < useSpeedRate(+word.to - +part.from, part))); else elseTemplateFutureWord">
                                                                                <span class="sub_word future_word" (contextmenu)="openWordEdit($event, part, sub, word, sI); $event.preventDefault(); $event.stopPropagation();"
                                                                                [ngStyle]="{ 
                                                                                    'margin-right': last ? '0' : '0.25em',
                                                                                    'color': !!part?.subSet?.is_karaoke ? part?.subSet?.futureColor : part?.subSet?.pastColor,
                                                                                    'text-decoration': !!part?.subSet?.underline ? 'underline '+ part?.subSet?.futureColor : (!!part?.subSet?.strike ? 'line-through ' + part?.subSet?.futureColor : 'none')
                                                                                }">{{word.value}}</span>
                                                                            </ng-container>
                                                                            <ng-template #elseTemplateFutureWord>
                                                                                <span class="sub_word past_word" (contextmenu)="openWordEdit($event, part, sub, word, sI); $event.preventDefault(); $event.stopPropagation();"
                                                                                [ngStyle]="{ 
                                                                                    'margin-right': last ? '0' : '0.25em',
                                                                                    'color': part?.subSet?.pastColor,
                                                                                    'text-decoration': !!part?.subSet?.underline ? 'underline '+ part?.subSet?.pastColor : (!!part?.subSet?.strike ? 'line-through ' + part?.subSet?.pastColor : 'none')
                                                                                }">{{word.value}}</span>
                                                                            </ng-template>
                                                                        </ng-template>
                                                                    </ng-container>
                                                                </div>
                                                                <mat-icon class="edit_sub show_on_hover ml-5" (click)="editSub($event, sub, part, sInd)">edit</mat-icon>
                                                            </div>
                                                        </ng-container>
                                                    </ng-container>
                                                </ng-container>
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                    <div class="subs_nav">
                                        <div></div>
    
                                        <!-- <div class="sub_nav_btn" (click)="saveEditSub($event)" *ngIf="editedSub && editedSub?.part?.pId == activeItem?.pId" #tooltip="matTooltip" [matTooltipPosition]="'above'" matTooltip="{{ 'Close Editing Sub' | translate }}">
                                            <mat-icon>save</mat-icon>
                                        </div> -->
                                        <div class="d-f-c">
                                            <div class="sub_nav_btn" (click)="exportSubsToCsv($event)" #tooltip="matTooltip" [matTooltipPosition]="'above'" matTooltip="{{ 'Export subtitles as .csv' | translate }}">
                                                <mat-icon>download_for_offline</mat-icon>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-template #elseTemplateSubsShow>
                                    <ng-container *ngIf="!!activeItem && !!activeItem?.subs_on && !!activeItem.subSet?.is_edited_text; else SubTextTemplate">
                                        <div class="subs_text" *ngIf="activeItem.subs" [ngClass]="{'no_karaoke': !activeItem?.subSet?.is_karaoke}">
                                            <ng-container *ngIf="!!activeItem?.subSet?.is_add_text; else elseTemplateTextAdding">
                                                <div class="new_text_out">
                                                    <div class="new_text_title" (click)="logNewText(activeItem?.subSet.newText)">
                                                        {{"Adding new text" | translate}}
                                                    </div>
            
                                                    <div class="timings">
                                                        <div class="time">
                                                            <span>{{"from" | translate}}</span>
                                                            <input class="ex_number reports_white_input" matInput type="number" [(ngModel)]="activeItem?.subSet.newText.from">
                                                        </div>
                                                        <div class="time">
                                                            <span>{{"to" | translate}}</span>
                                                            <input class="ex_number reports_white_input" matInput type="number" [(ngModel)]="activeItem?.subSet.newText.to">
                                                        </div>
                                                    </div>
            
                                                    <textarea class="ex_number reports_white_input" matInput type="text" (input)="onInputNewText($event, activeItem?.subSet.newText)" [(ngModel)]="activeItem?.subSet.newText.text"></textarea>
                                                    
                                                    <div class="sub_nav_btn" style="width: unset;" (click)="onAddNewText($event, activeItem?.subSet.newText)" #tooltip="matTooltip" [matTooltipPosition]="'above'" matTooltip="{{ 'Add new text' | translate }}">
                                                        <mat-icon>add</mat-icon>
                                                        {{"Add" | translate}}
                                                    </div>
                                                    
                                                    <!-- <div class="words_add" *ngIf="activeItem?.subSet.newText && activeItem?.subSet.newText.words">
                                                        <div class="word_add" *ngFor="let newWord of activeItem?.subSet.newText.words; let nwInd = index;">
                                                            <input class="ex_number reports_white_input" matInput type="text" [(ngModel)]="newWord.value" (change)="onChangeNewWord($event, activeItem?.subSet.newText, newWord, nwInd)">
                                                            <div class="time">
                                                                <span>{{"from" | translate}}</span>
                                                                <input class="ex_number reports_white_input" matInput type="number" [(ngModel)]="newWord.from" (change)="onChangeNewWordTime($event, activeItem?.subSet.newText, newWord, nwInd)">
                                                            </div>
                                                            <div class="time">
                                                                <span>{{"to" | translate}}</span>
                                                                <input class="ex_number reports_white_input" matInput type="number" [(ngModel)]="newWord.to" (change)="onChangeNewWordTime($event, activeItem?.subSet.newText, newWord, nwInd)">
                                                            </div>
                                                        </div>
                                                    </div> -->
                                                </div>
                                            </ng-container>
                                            <ng-template #elseTemplateTextAdding>        
                                                <ng-container *ngFor="let sub of activeItem.subs; let sInd = index;">
                                                    <div class="sub_text" *ngIf="sub.from > activeItem.from && (sub.to < activeItem.to)">
                                                        <div class="sub_time">
                                                            <!-- <div class="d-f-c">
                                                                <mat-icon class="show_on_hover" (click)="deleteSub($event, activeItem, sInd)">delete</mat-icon>
                                                                <mat-icon class="show_on_hover ml-5" (click)="editSub($event, sub, activeItem, sInd)">edit</mat-icon>
                                                            </div> -->
                                                            <span (click)="timeSubClick(activeItem, sub)" (dblclick)="editSub($event, sub, activeItem, sInd)">{{useSpeedRate((+sub.from - +activeItem.from + +activeItem.prevAllDuration), activeItem)|veTime:false}} - {{useSpeedRate((+sub.to - +activeItem.from + + +activeItem.prevAllDuration), activeItem)|veTime:false}}</span>
                                                            <ng-container *ngIf="!!sub.speaker; else elseTemplateNoSpeak">
                                                                <b class="speaker_name text_one_line" [ngStyle]="{'color': sub?.background || '#2986f0'}">{{sub.speaker}}</b>
                                                            </ng-container>
                                                            <ng-template #elseTemplateNoSpeak>
                                                                <div class="point_circle_out">
                                                                    <b class="point_circle" [ngStyle]="{'background': sub?.background || '#2986f0'}"></b>
                                                                </div>
                                                            </ng-template>
                                                        </div>
                                                        <div *ngIf="sub.words" class="sub_text_words cp" (click)="timeSubClick(activeItem, sub)">
                                                            <ng-container *ngFor="let word of sub.words; let sI = index; let last = last">
                                                                <ng-container *ngIf="(!isProjectPlaying && !!verticalLine?.show) ? ((currentCutTimeProject === 0 && verticalLine.val - activeItem.prevAllDuration >= useSpeedRate(+word.from - +activeItem.from, activeItem) && verticalLine.val - activeItem.prevAllDuration < useSpeedRate(+word.to - +activeItem.from, activeItem))) : ((currentCutTimeProject === 0 && currentTimeProject - activeItem.prevAllDuration >= useSpeedRate(+word.from - +activeItem.from, activeItem) && currentTimeProject - activeItem.prevAllDuration < useSpeedRate(+word.to - +activeItem.from, activeItem)) || (currentCutTimeProject !== 0 && currentCutTimeProject - activeItem.prevAllDuration >= useSpeedRate(+word.from - +activeItem.from, activeItem) && currentCutTimeProject - activeItem.prevAllDuration < useSpeedRate(+word.to - +activeItem.from, activeItem))); else elseTemplateActiveWord">
                                                                    <span class="sub_word active_word" (contextmenu)="openWordEdit($event, activeItem, sub, word, sI); $event.preventDefault(); $event.stopPropagation();"
                                                                    [ngStyle]="{ 
                                                                        'margin-right': last ? '0' : '0.25em',
                                                                        'color': !!activeItem?.subSet?.is_karaoke ? activeItem?.subSet?.activeColor : activeItem?.subSet?.pastColor,
                                                                        'text-decoration': !!activeItem?.subSet?.underline ? 'underline '+ activeItem?.subSet?.activeColor : (!!activeItem?.subSet?.strike ? 'line-through ' + activeItem?.subSet?.activeColor : 'none')
                                                                    }">{{word.value}}</span>
                                                                </ng-container>
                                                                <ng-template #elseTemplateActiveWord>
                                                                    <ng-container *ngIf="(!isProjectPlaying && !!verticalLine?.show) ? ((currentCutTimeProject === 0 && verticalLine.val - activeItem.prevAllDuration < useSpeedRate(+word.to - +activeItem.from, activeItem))) : ((currentCutTimeProject === 0 && currentTimeProject - activeItem.prevAllDuration < useSpeedRate(+word.to - +activeItem.from, activeItem)) || (currentCutTimeProject !== 0 && currentCutTimeProject - activeItem.prevAllDuration < useSpeedRate(+word.to - +activeItem.from, activeItem))); else elseTemplateFutureWord">
                                                                        <span class="sub_word future_word" (contextmenu)="openWordEdit($event, activeItem, sub, word, sI); $event.preventDefault(); $event.stopPropagation();"
                                                                        [ngStyle]="{ 
                                                                            'margin-right': last ? '0' : '0.25em',
                                                                            'color': !!activeItem?.subSet?.is_karaoke ? activeItem?.subSet?.futureColor : activeItem?.subSet?.pastColor,
                                                                            'text-decoration': !!activeItem?.subSet?.underline ? 'underline '+ activeItem?.subSet?.futureColor : (!!activeItem?.subSet?.strike ? 'line-through ' + activeItem?.subSet?.futureColor : 'none')
                                                                        }">{{word.value}}</span>
                                                                    </ng-container>
                                                                    <ng-template #elseTemplateFutureWord>
                                                                        <span class="sub_word past_word" (contextmenu)="openWordEdit($event, activeItem, sub, word, sI); $event.preventDefault(); $event.stopPropagation();"
                                                                        [ngStyle]="{ 
                                                                            'margin-right': last ? '0' : '0.25em',
                                                                            'color': activeItem?.subSet?.pastColor,
                                                                            'text-decoration': !!activeItem?.subSet?.underline ? 'underline '+ activeItem?.subSet?.pastColor : (!!activeItem?.subSet?.strike ? 'line-through ' + activeItem?.subSet?.pastColor : 'none')
                                                                        }">{{word.value}}</span>
                                                                    </ng-template>
                                                                </ng-template>
                                                            </ng-container>
                                                        </div>
                                                        <mat-icon class="show_on_hover ml-5" (click)="editSub($event, sub, activeItem, sInd)">edit</mat-icon>
                                                    </div>
                                                </ng-container>
                                            </ng-template>
                                        </div>
                                        <div class="subs_nav">
                                            <div></div>
        
                                            <!-- <div class="sub_nav_btn" (click)="saveEditSub($event)" *ngIf="editedSub && editedSub?.part?.pId == activeItem?.pId" #tooltip="matTooltip" [matTooltipPosition]="'above'" matTooltip="{{ 'Close Editing Sub' | translate }}">
                                                <mat-icon>save</mat-icon>
                                            </div> -->
                                            <div class="d-f-c">
                                                <ng-container *ngIf="editedSub && editedSub?.part?.pId == activeItem?.pId; else isEditSubTmpl">         
                                                    <div class="sub_nav_btn" (click)="closeEditSub($event)" #tooltip="matTooltip" [matTooltipPosition]="'above'" matTooltip="{{ 'Close Editing Sub' | translate }}">
                                                        <mat-icon>close</mat-icon>
                                                    </div>
                                                </ng-container>
                                                <ng-template #isEditSubTmpl>
                                                    <div class="sub_nav_btn" (click)="openTextAdding($event)" *ngIf="!activeItem?.subSet?.is_add_text" #tooltip="matTooltip" [matTooltipPosition]="'above'" matTooltip="{{ 'Add new text' | translate }}">
                                                        <mat-icon>add</mat-icon>
                                                    </div>
                                                    <div class="sub_nav_btn" (click)="closeTextAdding($event)" *ngIf="!!activeItem?.subSet?.is_add_text" #tooltip="matTooltip" [matTooltipPosition]="'above'" matTooltip="{{ 'Close Adding text' | translate }}">
                                                        <mat-icon>close</mat-icon>
                                                    </div>
                                                </ng-template>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <ng-template #SubTextTemplate>       
                                        <div class="panel_line panel_opt_head" [ngClass]="{'has_sub': is_create_cards.value}">
                                            <!-- <div class="head_line">
                                                <div class="d-f-c">
                                                    <mat-checkbox *ngIf="saveMode.value == 'parts'" class="select_all_checkbox" (click)="$event.preventDefault; $event.stopPropagation()" (change)="$event ? masterToggle() : null"
                                                        [checked]="hasValue() && isAllSelected()"
                                                        [indeterminate]="hasValue() && !isAllSelected()"
                                                        [aria-label]="checkboxLabel()"
                                                        [disableRipple]="true">
                                                        <span class="">{{checkboxLabel()}}</span>
                                                    </mat-checkbox>
                                                </div>
                        
                                            </div> -->
                
                                            <div class="fragments_head"  [ngClass]="{'need_padding_left': !!is_create_cards.value}">
                                                <!-- (click)="cut.is_audio_only = !!cut.is_audio_only ? 0 : 1" -->
                                                <div class="fragments_head_btns" *ngIf="saveMode.value == 'parts'">
                                                    <button class="check_btn small" mat-icon-button [disableRipple]="true" [ngClass]="{'changing': hasValue() && !isAllSelected()}" (click)="masterToggle()">
                                                        <ng-container *ngIf="hasValue() && isAllSelected(); else elseTemplateIsAud">
                                                            <mat-icon>audiotrack</mat-icon>
                                                        </ng-container>
                                                        <ng-template #elseTemplateIsAud>
                                                            <mat-icon>music_off</mat-icon>
                                                        </ng-template>
                                                    </button>
                                                    <button class="check_btn small" mat-icon-button [disableRipple]="true" [ngClass]="{'changing': hasValueVid() && !isAllSelectedVid()}" (click)="masterToggleVid()">
                                                        <ng-container *ngIf="hasValueVid() && isAllSelectedVid(); else elseTemplateIsVid">
                                                            <mat-icon>videocam</mat-icon>
                                                        </ng-container>
                                                        <ng-template #elseTemplateIsVid>
                                                            <mat-icon>videocam_off</mat-icon>
                                                        </ng-template>
                                                    </button>
                                                </div>
                                                
                                                <input *ngIf="data.file" [(ngModel)]="data.file.filename" class="proj_name reports_white_input" matInput placeholder="{{ 'Project Name' | translate }}">
                        
                                                <div class="fragments_head_info">
                                                    <div class="project_duration">
                                                        {{duration|veTime}}
                                                    </div>
                                                    <div class="parts_count">
                                                        {{getVideoParts().length}} {{"files" | translate}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div class="panel_options_out" [appDeviceClass]="'custom-scroll'" [ngClass]="{'active_item_out': !!activeItem}">
                                            <div class="panel_options_in">
                                                <ng-container *ngIf="hasParts() && saveMode.value == 'parts'">
                                                    <ng-container *ngFor="let trackEl of tracks">
                                                        <ng-container *ngIf="trackEl.type == 'video'">
                                                            <ng-container *ngFor="let cut of trackEl.parts">
                                                                <div class="panel_option_out" [ngClass]="{'forms_activated': is_create_cards.value }">
                                                                    <div class="fragments_head panel_option">
                                                                        <button class="check_btn small" mat-icon-button (click)="cut.is_audio_only = !!cut.is_audio_only ? 0 : 1" [disableRipple]="true">
                                                                            <ng-container *ngIf="!!cut.is_audio_only; else elseTemplateIsAud">
                                                                                <mat-icon>audiotrack</mat-icon>
                                                                            </ng-container>
                                                                            <ng-template #elseTemplateIsAud>
                                                                                <mat-icon>music_off</mat-icon>
                                                                            </ng-template>
                                                                        </button>
                                                                        <button class="check_btn small" mat-icon-button (click)="cut.is_video_only = !!cut.is_video_only ? 0 : 1" [disableRipple]="true">
                                                                            <ng-container *ngIf="!!cut.is_video_only; else elseTemplateIsVid">
                                                                                <mat-icon>videocam</mat-icon>
                                                                            </ng-container>
                                                                            <ng-template #elseTemplateIsVid>
                                                                                <mat-icon>videocam_off</mat-icon>
                                                                            </ng-template>
                                                                        </button>
                                                                        <button class="check_btn small" [ngClass]="{'visible_on_hover': !(playCut && playCut.pId == cut.pId)}" mat-icon-button (click)="playVideoInterval(cut)" [disableRipple]="true">
                                                                            <ng-container *ngIf="playCut && playCut.pId == cut.pId; else elseTemplatePlayed">
                                                                                <mat-icon>pause_circle</mat-icon>
                                                                            </ng-container>
                                                                            <ng-template #elseTemplatePlayed>
                                                                                <mat-icon>play_circle</mat-icon>
                                                                            </ng-template>
                                                                        </button>
                    
                                             
                                                                        
                                                                        <div class="proj_name_out">
                                                                            <input [(ngModel)]="cut.filename" class="proj_name reports_white_input" matInput placeholder="{{ 'File Name' | translate }}">
                                                                            <div class="result_file_tags">
                                                                                <svg class="res_icon" width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path d="M1.75 2.5C1.94891 2.5 2.13968 2.42098 2.28033 2.28033C2.42098 2.13968 2.5 1.94891 2.5 1.75C2.5 1.55109 2.42098 1.36032 2.28033 1.21967C2.13968 1.07902 1.94891 1 1.75 1C1.55109 1 1.36032 1.07902 1.21967 1.21967C1.07902 1.36032 1 1.55109 1 1.75C1 1.94891 1.07902 2.13968 1.21967 2.28033C1.36032 2.42098 1.55109 2.5 1.75 2.5ZM7.705 3.79C7.885 3.97 8 4.22 8 4.5C8 4.775 7.89 5.025 7.705 5.205L5.205 7.705C5.025 7.885 4.775 8 4.5 8C4.225 8 3.975 7.89 3.79 7.705L0.295 4.21C0.11 4.025 0 3.775 0 3.5V1C0 0.445 0.445 0 1 0H3.5C3.775 0 4.025 0.11 4.205 0.29L7.705 3.79ZM5.77 0.855L6.27 0.355L9.705 3.79C9.89 3.97 10 4.225 10 4.5C10 4.775 9.89 5.025 9.71 5.205L7.02 7.895L6.52 7.395L9.375 4.5L5.77 0.855Z" fill="#C9C9C9"/>
                                                                                </svg> 
                                                                                <ng-container *ngIf="cut.tags">
                                                                                    <div class="res_file_tag" *ngFor="let tag of cut.tags; let t = index;">
                                                                                        {{!!tag.value ? tag.value : tag?.parameterValue?.value }}
                                                                                        <div class="remove" (click)="onRemoveCut(cut, t)">
                                                                                            <svg style="width: 5px; height: 5px;" width="5" height="5" viewBox="0 0 5 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                <path d="M5 0.503571L4.49643 0L2.5 1.99643L0.503571 0L0 0.503571L1.99643 2.5L0 4.49643L0.503571 5L2.5 3.00357L4.49643 5L5 4.49643L3.00357 2.5L5 0.503571Z" fill="white"/>
                                                                                            </svg>
                                                                                        </div>
                                                                                    </div>
                                                                                </ng-container>
                                                                                <div class="add_tag cp" (click)="openCutTargetValues(cut)">
                                                                                    <svg style="width: 14px; height: 14px;" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                        <path d="M12 8.8H8.8V12H7.2V8.8H4V7.2H7.2V4H8.8V7.2H12M8 0C6.94943 0 5.90914 0.206926 4.93853 0.608964C3.96793 1.011 3.08601 1.60028 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.08601 14.3997 3.96793 14.989 4.93853 15.391C5.90914 15.7931 6.94943 16 8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 6.94943 15.7931 5.90914 15.391 4.93853C14.989 3.96793 14.3997 3.08601 13.6569 2.34315C12.914 1.60028 12.0321 1.011 11.0615 0.608964C10.0909 0.206926 9.05058 0 8 0Z" fill="#C4C4C4"/>
                                                                                    </svg>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                
                                                                        <div class="fragments_head_info">
                    
                                                                            <div class="opt_block">
                                                                                <div>&nbsp;</div>
                                                                                <div>{{cut.duration | veTime}}</div>
                                                                            </div>
                                                                            <div class="opt_block">
                                                                                <div>{{"from" | translate}}</div>
                                                                                <div>{{cut.from | veTime}}</div>
                                                                            </div>
                                                                            <div class="opt_block" style="margin-left: 4px !important;">
                                                                                <div>&nbsp;</div>
                                                                                <div>-</div>
                                                                            </div>
                                                                            
                                                                            <div class="opt_block" style="margin-left: 4px !important;">
                                                                                <div>{{"to" | translate}}</div>
                                                                                <div>{{cut.to | veTime}}</div>
                                                                            </div>
                                                                        </div>
                                      
                                                                    </div>
                
                                                                </div>
                                                            </ng-container>
                                                            <!-- <button (click)="testLogic()">test</button> -->
                                                        </ng-container>
                                                    </ng-container>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </ng-template>
                                </ng-template>
                            </ng-template>
                            
                        </div>
                    </ng-container>
                    <div class="preview_panel_out" #prevPanelOut>
                        <div class="preview_panel" #prevPanelIn>             
                            <div class="holst" #holst [ngClass]="{'crop_on': hasCropOn()}" [ngStyle]="{'width': project?.width + 'px', 'height': project?.height + 'px'}">
                                <ng-container *ngFor="let track of tracks">
                                    <ng-container *ngIf="track.type == 'video'">
                                        <ng-container *ngFor="let part of track.parts; let p = index;">
                                            <ng-container *ngIf="part.is_combine; else elseTemplateCombineVid">
                                                <ng-container *ngFor="let item of part.blends">
                                                    <app-resizable-draggable
                                                    [ngClass]="{'show_vid': (!isProjectPlaying && !hasCropOn() && !!verticalLine?.show) ? ((p == 0 && u == 0 && verticalLine.val === 0) || (currentCutTimeProject === 0 && verticalLine.val >= +item.prevAllDuration && verticalLine.val < +item.prevAllDuration + +item.duration)) : ((p == 0 && u == 0 && currentTimeProject === 0) || (currentCutTimeProject === 0 && currentTimeProject >= +item.prevAllDuration && currentTimeProject < +item.prevAllDuration + +item.duration) || (currentCutTimeProject !== 0 && currentCutTimeProject >= +item.prevAllDuration && currentCutTimeProject < +item.prevAllDuration + +item.duration))}"
                                                    (Loadedmetadata)="loadedVideo($event, track, item, p)"
                                                    [host]="data?.host"
                                                    [company_id]="data?.company_id"
                                                    [project]="project"
                                                    [forAll]="false"
                                                    [combine]="part"
                                                    [part]="item"
                                                    [crop_on]="item?.crop_on"
                                                    [holst]="holst"
                                                    [width]="item?.rd?.width"
                                                    [height]="item?.rd?.height"
                                                    [ratio]="item?.rd?.ratio">
                                                    </app-resizable-draggable>
                                                </ng-container>
                                            </ng-container>
                                            <ng-template #elseTemplateCombineVid>
                                                <app-resizable-draggable
                                                [ngClass]="{'show_vid': (!isProjectPlaying && !hasCropOn() && !!verticalLine?.show) ? ((p == 0 && u == 0 && verticalLine.val === 0) || (currentCutTimeProject === 0 && verticalLine.val >= +part.prevAllDuration && verticalLine.val < +part.prevAllDuration + +part.duration)) : ((p == 0 && u == 0 && currentTimeProject === 0) || (currentCutTimeProject === 0 && currentTimeProject >= +part.prevAllDuration && currentTimeProject < +part.prevAllDuration + +part.duration) || (currentCutTimeProject !== 0 && currentCutTimeProject >= +part.prevAllDuration && currentCutTimeProject < +part.prevAllDuration + +part.duration))}"
                                                (Loadedmetadata)="loadedVideo($event, track, part, p)"
                                                [host]="data?.host"
                                                [verticalLine]="verticalLine"
                                                [isProjectPlaying]="isProjectPlaying"
                                                [hasCropOn]="hasCropOn()"
                                                [currentTimeProject]="currentTimeProject"
                                                [currentCutTimeProject]="currentCutTimeProject"
                                                [company_id]="data?.company_id"
                                                [project]="project"
                                                [forAll]="false"
                                                [part]="part"
                                                [combine]="null"
                                                [crop_on]="part?.crop_on"
                                                [holst]="holst"
                                                [width]="part?.rd?.width"
                                                [height]="part?.rd?.height"
                                                [ratio]="part?.rd?.ratio">
                                                </app-resizable-draggable>
                                            </ng-template>
                                        </ng-container>
                                    </ng-container>
                                    <ng-container *ngIf="track.type == 'audio'">
                                        <ng-container *ngFor="let part of track.parts; let p = index;">
                                            <audio class="audioPlayer" [id]="'audio_' + part?.pId" [src]="data?.host + (part?.preview1080 ? part?.preview1080 : part?.original) + '?company_id=' + data?.company_id" (loadedmetadata)="loadedVideo($event, track, part, p, true)"></audio>
                                        </ng-container>
                                    </ng-container>
                                </ng-container>
                            </div>   
                        </div>
                    </div>
                    <div class="subs_info" [ngClass]="{'open': !!activeItem && !!activeItem?.subs_on}" [appDeviceClass]="'custom-scroll'">
                        <ng-container *ngIf="is_saved_styles; else elseTemplateSavedStyles">
                            <div class="styles">
                                <mat-icon class="go_back" (click)="savedStyles($event)">arrow_back_ios_new</mat-icon>
                                <div *ngFor="let item of styles" class="style" (click)="setStyle($event, item)">
                                    <mat-icon class="edit_style cp" *ngIf="item.id > 0" (click)="setStyle($event, item, true)">edit</mat-icon>
                                    <!-- '-webkit-text-stroke-width': item?.settings?.style?.outline/10+'px' || '0.1px',
                                    '-webkit-text-stroke-color': item?.settings?.style?.outlineColor, -->
                                    <div class="style_view" [ngStyle]="{
                                        'color': item?.settings?.style?.pastColor || '#fff',
                                        'font-style': !!item?.settings?.style?.italic ? 'italic' : 'normal',
                                        'font-weight': !!item?.settings?.style?.bold ? 'bold' : 'normal',
                                        'text-shadow': 
                                        (item?.settings?.style?.outline / 10 || 0.1) + 'px ' +
                                        (item?.settings?.style?.outline / 10 || 0.1) + 'px 0 ' +
                                        (item?.settings?.style?.outlineColor || 'black') + ',' +
                                        '-' + (item?.settings?.style?.outline / 10 || 0.1) + 'px -' +
                                        (item?.settings?.style?.outline / 10 || 0.1) + 'px 0 ' +
                                        (item?.settings?.style?.outlineColor || 'black') + ',' +
                                        (item?.settings?.style?.outline / 10 || 0.1) + 'px -' +
                                        (item?.settings?.style?.outline / 10 || 0.1) + 'px 0 ' +
                                        (item?.settings?.style?.outlineColor || 'black') + ',' +
                                        '-' + (item?.settings?.style?.outline / 10 || 0.1) + 'px ' +
                                        (item?.settings?.style?.outline / 10 || 0.1) + 'px 0 ' +
                                        (item?.settings?.style?.outlineColor || 'black') 
                                    }">
                                        <span [ngStyle]="{ 
                                            'margin-right': last ? '0' : '0.25em',
                                            'color': item?.settings?.style?.pastColor,
                                            'text-decoration': !!item?.settings?.style?.underline ? 'underline '+ item?.settings?.style?.pastColor : (!!item?.settings?.style?.strike ? 'line-through ' + item?.settings?.style?.pastColor : 'none')
                                        }">Hey</span> 
                                        <span [ngStyle]="{ 
                                            'margin-right': last ? '0' : '0.25em',
                                            'color': item?.settings?.style?.pastColor,
                                            'text-decoration': !!item?.settings?.style?.underline ? 'underline '+ item?.settings?.style?.pastColor : (!!item?.settings?.style?.strike ? 'line-through ' + item?.settings?.style?.pastColor : 'none')
                                        }">guys,</span> 
                                        <span [ngStyle]="{
                                            'color': !!item?.settings?.style?.is_karaoke ? item?.settings?.style?.activeColor : item?.settings?.style?.pastColor,
                                            'text-decoration': !!item?.settings?.style?.underline ? 'underline '+ item?.settings?.style?.activeColor : (!!item?.settings?.style?.strike ? 'line-through ' + item?.settings?.style?.activeColor : 'none')
                                        }">it's</span> 
                                        <span [ngStyle]="{
                                            'color': !!item?.settings?.style?.is_karaoke ? item?.settings?.style?.futureColor : item?.settings?.style?.pastColor,
                                            'text-decoration': !!item?.settings?.style?.underline ? 'underline '+ item?.settings?.style?.futureColor : (!!item?.settings?.style?.strike ? 'line-through ' + item?.settings?.style?.futureColor : 'none')
                                        }">a</span> 
                                        <span [ngStyle]="{
                                            'color': !!item?.settings?.style?.is_karaoke ? item?.settings?.style?.futureColor : item?.settings?.style?.pastColor,
                                            'text-decoration': !!item?.settings?.style?.underline ? 'underline '+ item?.settings?.style?.futureColor : (!!item?.settings?.style?.strike ? 'line-through ' + item?.settings?.style?.futureColor : 'none')
                                        }">new</span> 
                                        <span [ngStyle]="{
                                            'color': !!item?.settings?.style?.is_karaoke ? item?.settings?.style?.futureColor : item?.settings?.style?.pastColor,
                                            'text-decoration': !!item?.settings?.style?.underline ? 'underline '+ item?.settings?.style?.futureColor : (!!item?.settings?.style?.strike ? 'line-through ' + item?.settings?.style?.futureColor : 'none')
                                        }">day</span>
                                    </div>
                                    <div class="style_title">
                                        {{item.name}}
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <ng-template #elseTemplateSavedStyles>            
                            <ng-container *ngIf="!!activeItem?.subSet">
                                <div class="sub_setting_title_block">
                                    <span class="sub_setting_title">{{"Font" | translate}}:</span>
                                </div>
                                <div class="sub_setting">
                                    <span class="sub_setting_title">{{"Size" | translate}}:</span>
                                    <input class="ex_number reports_white_input" [(ngModel)]="activeItem.subSet.fontSize" matInput type="number">
                                    <span>{{"px"}}</span>
                                </div>
                                <div class="sub_setting">
                                    <span class="sub_setting_title">{{"Outline" | translate}}:</span>
                                    <input class="ex_number reports_white_input" [(ngModel)]="activeItem.subSet.outline" matInput type="number">
                                    <span>{{"px"}}</span>
                                </div>
                                
                                <div class="sub_setting">
                                    <span class="sub_setting_title">{{"Style" | translate}}:</span>
                                   
                                    <div class="btns_group">
                                        <div class="btn_group" (click)="toggleSubFormat(activeItem.subSet, 'bold')" [ngClass]="{'active': activeItem.subSet.bold == '1'}">
                                            <mat-icon>format_bold</mat-icon>
                                        </div>
                                        <div class="btn_group" (click)="toggleSubFormat(activeItem.subSet, 'italic')" [ngClass]="{'active': activeItem.subSet.italic == '1'}">
                                            <mat-icon>format_italic</mat-icon>
                                        </div>
                                        <div class="btn_group" (click)="toggleSubFormat(activeItem.subSet, 'underline')" [ngClass]="{'active': activeItem.subSet.underline == '1'}">
                                            <mat-icon>format_underlined</mat-icon>
                                        </div>
                                        <div class="btn_group" (click)="toggleSubFormat(activeItem.subSet, 'strike')" [ngClass]="{'active': activeItem.subSet.strike == '1'}">
                                            <mat-icon>format_strikethrough</mat-icon>
                                        </div>
                                    </div>
                                </div>
                                <div class="sub_setting_title_block">
                                    <span class="sub_setting_title">{{"Colors" | translate}}:</span>
                                </div>
                                <ng-container *ngIf="!!activeItem?.subSet?.is_karaoke; else elseTemplateKaraokeColors">
                                    <div class="sub_setting">
                                        <span class="sub_setting_title">{{"Acitve word" | translate}}:</span>
                                        <button [(colorPicker)]="activeItem.subSet.activeColor" [cpOutputFormat]="'rgba'" [style.backgroundColor]="activeItem.subSet.activeColor" type="button" class="ve_color_pick"></button>
                                    </div>
                                    <div class="sub_setting">
                                        <span class="sub_setting_title">{{"Future word" | translate}}:</span>
                                        <button [(colorPicker)]="activeItem.subSet.futureColor" [cpOutputFormat]="'rgba'" [style.backgroundColor]="activeItem.subSet.futureColor" type="button" class="ve_color_pick"></button>  
                                    </div>
                                    <div class="sub_setting">
                                        <span class="sub_setting_title">{{"Past word" | translate}}:</span>
                                        <button [(colorPicker)]="activeItem.subSet.pastColor" [cpOutputFormat]="'rgba'" [style.backgroundColor]="activeItem.subSet.pastColor" type="button" class="ve_color_pick"></button>
                                    </div>
                                </ng-container>
                                <ng-template #elseTemplateKaraokeColors>
                                    <div class="sub_setting">
                                        <span class="sub_setting_title">{{"Words" | translate}}:</span>
                                        <button [(colorPicker)]="activeItem.subSet.pastColor" [cpOutputFormat]="'rgba'" [style.backgroundColor]="activeItem.subSet.pastColor" type="button" class="ve_color_pick"></button>
                                    </div>
                                </ng-template>
                                
                                
                                <div class="sub_setting">
                                    <span class="sub_setting_title">{{"Outline" | translate}}:</span>
                                    <button [(colorPicker)]="activeItem.subSet.outlineColor" [cpOutputFormat]="'rgba'" [style.backgroundColor]="activeItem.subSet.outlineColor" type="button" class="ve_color_pick"></button>
                                </div>
        
                                <div class="sub_setting" *ngIf="activeItem && activeItem.subSet">
                                    <span class="sub_setting_title">{{"Karaoke" | translate}}:</span>
                                    <button mat-icon-button [ngClass]="{'active_sub_pos': !!activeItem?.subSet?.is_karaoke}" (click)="activeItem.subSet.is_karaoke = !activeItem.subSet.is_karaoke">
                                        <mat-icon>record_voice_over</mat-icon>
                                    </button>
                                </div>
        
                                
                                <div class="d-f-c">
                                    <button mat-icon-button #tooltip="matTooltip" [matTooltipPosition]="'above'" matTooltip="{{ (activeStyle ? 'Edit style' : 'Save style') | translate }}" (click)="saveStyleAsBook($event, activeItem)">
                                        <mat-icon>{{activeStyle ? 'edit' : 'save'}}</mat-icon>
                                    </button>
                                    <button #tooltip="matTooltip" [matTooltipPosition]="'above'" matTooltip="{{ 'Styles' | translate }}" class="ml-1" mat-icon-button (click)="savedStyles($event)">
                                        <mat-icon>bookmarks</mat-icon>
                                    </button>
                                </div>
                                <mat-divider></mat-divider>
                                
                                
                                <div class="sub_setting" *ngIf="activeItem && activeItem.subSet && activeItem.subSet.pos">
                                    <span class="sub_setting_title">{{"Position" | translate}}:</span>
                                    <button mat-icon-button [ngClass]="{'active_sub_pos': !!activeItem?.subSet?.pos?.is_edited}" (click)="activeItem.subSet.pos.is_edited = !activeItem.subSet.pos.is_edited">
                                        <mat-icon>control_camera</mat-icon>
                                    </button>
                                </div>
        
                                <div class="sub_setting" *ngIf="activeItem && activeItem.subSet">
                                    <span class="sub_setting_title">{{"Edit text" | translate}}:</span>
                                    <button mat-icon-button [ngClass]="{'active_sub_pos': !!activeItem?.subSet?.is_edited_text}" (click)="activeItem.subSet.is_edited_text = !activeItem.subSet.is_edited_text">
                                        <mat-icon>edit_note</mat-icon>
                                    </button>
                                </div>
    
                                <!-- <pre style="color: #fff; font-size: 10px;">
                                    {{activeItem?.subSet?.pos | json}}
                                </pre> -->
                            </ng-container>
                        </ng-template>
                    </div>
                    <div class="video_filters" [ngClass]="{'open': !!activeItem && !!activeItem?.video_filters_on}" [appDeviceClass]="'custom-scroll'">
                        <ng-container *ngIf="activeItem && activeItem.video_filters">
                            <div class="example-label-container d-f-c" style="justify-content: space-between;">
                                <label class="example-name-label">{{"Brightness" | translate}}</label>
                                <label class="example-value-label">{{activeItem.video_filters.brightness}}</label>
                            </div>
                            <mat-slider
                                class="example-margin"
                                [min]="0" 
                                [max]="10"
                                [step]="0.01"
                                [thumbLabel]="true"
                                [(ngModel)]="activeItem.video_filters.brightness"
                                [vertical]="false">
                            </mat-slider>
    
                            <div class="example-label-container d-f-c" style="justify-content: space-between;">
                                <label class="example-name-label">{{"Contrast" | translate}}</label>
                                <label class="example-value-label">{{activeItem.video_filters.contrast}}</label>
                            </div>
                            <mat-slider
                                class="example-margin"
                                [min]="-1000" 
                                [max]="1000"
                                [step]="1"
                                [thumbLabel]="true"
                                [(ngModel)]="activeItem.video_filters.contrast"
                                [vertical]="false">
                            </mat-slider>
    
                            <div class="example-label-container d-f-c" style="justify-content: space-between;">
                                <label class="example-name-label">{{"Saturation" | translate}}</label>
                                <label class="example-value-label">{{activeItem.video_filters.saturation}}</label>
                            </div>
                            <mat-slider
                                class="example-margin"
                                [min]="0" 
                                [max]="300"
                                [step]="1"
                                [thumbLabel]="true"
                                [(ngModel)]="activeItem.video_filters.saturation"
                                [vertical]="false">
                            </mat-slider>
                            
                            <!-- <div class="example-label-container d-f-c" style="justify-content: space-between;">
                                <label class="example-name-label">{{"Hue" | translate}}</label>
                                <label class="example-value-label">{{activeItem.video_filters.hue}}</label>
                            </div>
                            <mat-slider
                                class="example-margin"
                                [min]="0" 
                                [max]="360"
                                [step]="1"
                                [thumbLabel]="true"
                                [(ngModel)]="activeItem.video_filters.hue"
                                [vertical]="false">
                            </mat-slider>
                            
                            <div class="example-label-container d-f-c" style="justify-content: space-between;">
                                <label class="example-name-label">{{"Grayscale" | translate}}</label>
                                <label class="example-value-label">{{activeItem.video_filters.grayscale}}</label>
                            </div>
                            <mat-slider
                                class="example-margin"
                                [min]="0" 
                                [max]="100"
                                [step]="1"
                                [thumbLabel]="true"
                                [(ngModel)]="activeItem.video_filters.grayscale"
                                [vertical]="false">
                            </mat-slider> -->
     
                            <div>
                                <button mat-raised-button (click)="resetVidFilters(activeItem)" color="primary">{{"Reset" | translate}}</button>
                            </div>
                        </ng-container>
                    </div>
                    <div class="rd_info" [ngClass]="{'open': hasCropOn()}" [appDeviceClass]="'custom-scroll'">
                        <div class="little">project real W: <span>{{project?.mWidth}}</span></div>
                        <div class="little">project real H: <span>{{project?.mHeight}}</span></div>
                        <div class="little">project browser W: <span>{{project?.width}}</span></div>
                        <div class="little">project browser H: <span>{{project?.height}}</span></div>
                        <div class="little">project percent X: <span>{{project?.percentX}}</span></div>
                        <div class="little">project percent Y: <span>{{project?.percentY}}</span></div>
                        <div class="little">project ratio: <span>{{project?.ratio}}</span></div>
                        <mat-divider></mat-divider>
                        <div class="little">video real W: <span>{{activeItem?.rd?.mWidth}}</span></div>
                        <div class="little">video real H: <span>{{activeItem?.rd?.mHeight}}</span></div>
                        <div class="little">video browser W: <span>{{activeItem?.rd?.width}}</span></div>
                        <div class="little">video browser H: <span>{{activeItem?.rd?.height}}</span></div>
                        <div class="little">video percent X: <span>{{activeItem?.rd?.percentX}}</span></div>
                        <div class="little">video percent Y: <span>{{activeItem?.rd?.percentY}}</span></div>
                        <div class="little">video ratio: <span>{{activeItem?.rd?.ratio}}</span></div>
                        <mat-divider></mat-divider>
                        <div class="red">crop_width: <span>{{activeItem?.rd?.params?.part_crop_width}}</span></div>
                        <div class="red">crop_height: <span>{{activeItem?.rd?.params?.part_crop_height}}</span></div>
                        <div class="red">crop_x: <span>{{activeItem?.rd?.params?.part_crop_x}}</span></div>
                        <div class="red">crop_y: <span>{{activeItem?.rd?.params?.part_crop_y}}</span></div>
                        <div>scale_width: <span>{{activeItem?.rd?.params?.part_scale_width}}</span></div>
                        <div>scale_height: <span>{{activeItem?.rd?.params?.part_scale_height}}</span></div>
                        <div>pad_x: <span>{{activeItem?.rd?.params?.part_pad_x}}</span></div>
                        <div>pad_y: <span>{{activeItem?.rd?.params?.part_pad_y}}</span></div>
                        <div style="color: blueviolet;">rotate_deg: <span>{{activeItem?.rd?.params?.part_rotate_deg}}</span></div>
                        <!-- <div style="color: blueviolet;" *ngIf="activeItem && activeItem.rd">
                            <mat-form-field class="example-full-width">
                                <mat-label>rotate_deg</mat-label>
                                <input (input)="changePartDeg($event, activeItem)" matInput maxlength="5" type="number" [(ngModel)]="activeItem.rd.deg" min="-360" max="360" step="1">
                            </mat-form-field>
                        </div> -->
                    </div>
                </div>
    <!--     
                <video height="100%" id="test_vid" width="100%" *ngIf="tracks && tracks[0] && tracks[0].parts && tracks[0].parts[0]">
                    <source [src]="data?.host + tracks[0].parts[0].preview1080 + '?company_id=' + data?.company_id" [type]=" tracks[0].parts[0]?.content_type == 'video/quicktime' ? 'video/mp4' :  tracks[0].parts[0]?.content_type"/>
                    Your browser doesn't support HTML5 video tag.
                </video> -->
    
                <div class="time_and_btns">
                    <div class="dev_values">-- activeItemID: {{activeItem?.pId}} """""  saveMode: {{saveMode.value}}<ng-container *ngIf="activeItem?.is_combine"><span *ngFor="let sBlend of activeItem.savedBlends; let n = index">|{{n}} fragment -- <b>{{activeItem?.blends[n]?.duration || 0}} -- {{sBlend.duration}}</b> |</span></ng-container></div>
                    
                    <div class="d-f-c">
                        <ng-container *ngIf="!data?.thumbMode">
                            <div class="d-f-c">
                                <button [disableRipple]="true" class="ml-1" [ngClass]="{'active_mode': mode == 'view'}" mat-icon-button (click)="changeMode('view')">
                                    <mat-icon>ads_click</mat-icon>
                                </button>
                                <button [disableRipple]="true" class="ml-1" [ngClass]="{'active_mode': mode == 'cut'}" mat-icon-button (click)="changeMode('cut')">
                                    <mat-icon>content_cut</mat-icon>
                                </button>
                                <button [disableRipple]="true" class="ml-1" [ngClass]="{'active_mode':screenIsSubmit}" mat-icon-button (click)="createScreen()">
                                    <mat-icon>screenshot_monitor</mat-icon>
                                </button>
                            </div>
            
                            <!--  || (selectedFragments && selectedFragments.length > 1) -->
                            <div class="active_item_info" *ngIf="!!activeItem || (selectedFragments.selected && selectedFragments.selected.length > 0)">
                                <button *ngIf="activeItem?.trackElIndex == 0 && !activeItem?.is_detached" mat-icon-button (click)="$event.preventDefault(); $event.stopPropagation(); detachAudio()" [disableRipple]="true">
                                    <mat-icon>audio_file</mat-icon>
                                </button>
    
                                <button *ngIf="activeItem && activeItem?.trackElIndex == 0 && !activeItem.is_combine" [ngClass]="{'active_mode': !!activeItem?.is_changed, 'changing': !!activeItem?.crop_on}" mat-icon-button (click)="$event.preventDefault(); $event.stopPropagation(); changeCropOn(activeItem)" [disableRipple]="true">
                                    <mat-icon *ngIf="!!activeItem?.crop_on">crop</mat-icon>
                                    <mat-icon *ngIf="!activeItem?.crop_on">crop_free</mat-icon>
                                </button>
    
                                <button *ngIf="activeItem && activeItem?.trackElIndex == 0" [ngClass]="{'active_mode': !!activeItem?.has_blur_bg}" mat-icon-button (click)="$event.preventDefault(); $event.stopPropagation(); changeBlurBg()" [disableRipple]="true">
                                    <mat-icon *ngIf="!!activeItem?.has_blur_bg">blur_on</mat-icon>
                                    <mat-icon *ngIf="!activeItem?.has_blur_bg">blur_off</mat-icon>
                                </button>
    
                                <button *ngIf="activeItem && activeItem?.trackElIndex == 0" [ngClass]="{'active_mode': !!activeItem?.video_filters_on}" mat-icon-button (click)="$event.preventDefault(); $event.stopPropagation(); changeVideoFilters()" [disableRipple]="true">
                                    <mat-icon *ngIf="!!activeItem?.video_filters_on">movie_filter</mat-icon>
                                    <mat-icon *ngIf="!activeItem?.video_filters_on">movie_filter</mat-icon>
                                </button>
    
                                <button *ngIf="activeItem && activeItem?.trackElIndex == 0" [ngClass]="{'active_mode': !!activeItem?.subs_on}" mat-icon-button (click)="$event.preventDefault(); $event.stopPropagation(); changeSubtitles()" [disableRipple]="true">
                                    <mat-icon *ngIf="!!activeItem?.subs_on">subtitles</mat-icon>
                                    <mat-icon *ngIf="!activeItem?.subs_on">subtitles_off</mat-icon>
                                </button>
    
                                <button *ngIf="activeItem && activeItem?.trackElIndex == 0" mat-icon-button (click)="$event.preventDefault(); $event.stopPropagation(); changeVidOnly()" [disableRipple]="true">
                                    <mat-icon *ngIf="!!activeItem?.is_video_only">videocam</mat-icon>
                                    <mat-icon *ngIf="!activeItem?.is_video_only">videocam_off</mat-icon>
                                </button>
    
                                <button *ngIf="activeItem && activeItem?.trackElIndex == 0" mat-icon-button [disabled]="!!activeItem?.is_detached" (click)="$event.preventDefault(); $event.stopPropagation(); changeAudOnly()" [disableRipple]="true">
                                    <mat-icon *ngIf="!!activeItem?.is_audio_only">audiotrack</mat-icon>
                                    <mat-icon *ngIf="!activeItem?.is_audio_only">music_off</mat-icon>
                                </button>
        
                                <div class="track_menu_out" *ngIf="activeItem">
                                    <button class="check_btn" mat-icon-button (click)="$event.preventDefault(); $event.stopPropagation(); trackOpen(activeItem)" [disableRipple]="true">
                                        <mat-icon *ngIf="activeItem.volume >= 0.9">volume_up</mat-icon>
                                        <mat-icon *ngIf="activeItem.volume > 0.1 && activeItem.volume < 0.9">volume_down</mat-icon>
                                        <mat-icon *ngIf="activeItem.volume > 0 && activeItem.volume <= 0.1">volume_mute</mat-icon>
                                        <mat-icon *ngIf="activeItem.volume == 0">volume_off</mat-icon>
                                    </button>
                                    <div class="track_menu_in" *ngIf="activeItem?.is_open" (click)="$event.preventDefault(); $event.stopPropagation();">
                                        <mat-slider (valueChange)="changePartVolume($event, activeItem)"
                                        [(ngModel)]="activeItem.volume"
                                        [value]="activeItem.volume"
                                        thumbLabel
                                        min="0"
                                        [vertical]="true"
                                        step="0.01"
                                        max="1">
                                        </mat-slider>
                                        <mat-icon *ngIf="activeItem.volume >= 0.9" (click)="activeItem.volume = 0">volume_up</mat-icon>
                                        <mat-icon *ngIf="activeItem.volume > 0.1 && activeItem.volume < 0.9">volume_down</mat-icon>
                                        <mat-icon *ngIf="activeItem.volume > 0 && activeItem.volume <= 0.1">volume_mute</mat-icon>
                                        <mat-icon *ngIf="activeItem.volume == 0" (click)="activeItem.volume = 1">volume_off</mat-icon>
                                    </div>
                                </div>
            
                                <div class="option_btns ml-1">
                                    <div class="opt_block" *ngIf="activeItem">
                                        <div>{{"Duration" | translate}}:</div>
                                        <div>{{activeItem?.duration | veTime}}</div>
                                    </div>
                                    <div class="opt_block" *ngIf="activeItem">
                                        <div>{{"From" | translate}}:</div>
                                        <div>{{activeItem.from | veTime}}</div>
                                    </div>
                                    <div class="opt_block" *ngIf="activeItem">
                                        <div>{{"To" | translate}}:</div>
                                        <div>{{activeItem.to | veTime}}</div>
                                    </div>
                                    <div class="opt_block" *ngIf="activeItem">
                                        <div>{{"Speed" | translate}}: <span>{{activeItem.part_speed_rate}}x</span></div>
                                        <mat-slider [(ngModel)]="activeItem.part_speed_rate" color="primary" [min]="0.25" [max]="3" [step]="0.01" [value]="activeItem.part_speed_rate" (valueChange)="changeItemRate(activeItem, $event)" discrete>
                                            <input matSliderThumb>
                                        </mat-slider>
                                    </div>
                                      
                                    <!-- <div class="opt_block">
                                        <div>{{"Speed" | translate}}:</div>
                                        <div>
                                            <div class="vm_spliter_btn vm_spliter_btn_menu">
                                                <button style="color: #fff;" class="active_vm_btn" mat-icon-button [matMenuTriggerFor]="ratioValueMenu" aria-label="Example icon-button with a menu">
                                                    x{{activeItem?.part_speed_rate}}
                                                </button>
                                                <mat-menu #ratioValueMenu="matMenu" class="ratio_value_menu_class" overlayPanelClass='ratio_value_menu' yPosition="above" xPosition="before">
            
                                                    <button mat-menu-item (click)="changeItemRate(activeItem, 0.25)">
                                                        <span>0.25</span>
                                                    </button>
                                                    <button mat-menu-item (click)="changeItemRate(activeItem, 0.5)">
                                                        <span>0.5</span>
                                                    </button>
                                                    <button mat-menu-item (click)="changeItemRate(activeItem, 0.75)">
                                                        <span>0.75</span>
                                                    </button>
                                                    <button mat-menu-item (click)="changeItemRate(activeItem, 1)">
                                                        <span>1</span>
                                                    </button>
                                                    <button mat-menu-item (click)="changeItemRate(activeItem, 1.25)">
                                                        <span>1.25</span>
                                                    </button>
                                                    <button mat-menu-item (click)="changeItemRate(activeItem, 1.5)">
                                                        <span>1.5</span>
                                                    </button>
                                                    <button mat-menu-item (click)="changeItemRate(activeItem, 2)">
                                                        <span>2</span>
                                                    </button>
                                                </mat-menu>      
                                            </div>
                                        </div>
                                    </div> -->
                                </div>
                            </div>
                        </ng-container>
                    </div>
        
                    <div class="d-f-c" style="width: 120px;">
                        <button class="do_not_deactivate" mat-icon-button (click)="goToTime(false)">
                            <mat-icon>replay_5</mat-icon>
                        </button>
                        <ng-container *ngIf="isProjectPlaying; else elseTemplateIsPlayProject">
                            <button class="do_not_deactivate" mat-icon-button (click)="pauseProject()">
                                <mat-icon>pause</mat-icon>
                            </button>
                        </ng-container>
                        <ng-template #elseTemplateIsPlayProject>
                            <button class="do_not_deactivate" mat-icon-button (click)="playProject()">
                                <mat-icon>play_arrow</mat-icon>
                            </button>
                        </ng-template>
                        <button class="do_not_deactivate" mat-icon-button (click)="goToTime(true)">
                            <mat-icon>forward_5</mat-icon>
                        </button>
                    </div>
        
                    <div class="d-f-c">
                        <div class="d-f-c">
                            <ng-container *ngIf="!!checkCustomSpeed(); else elseTemplateHasCstSpeed">
                                <mat-icon style="color: #686868;" class="mr-1" #tooltip="matTooltip" [matTooltipPosition]="'above'" matTooltip="{{ 'Not available in case custom speed applied for any of the fragments.' | translate }}">speed</mat-icon>                        
                            </ng-container>
                            <ng-template #elseTemplateHasCstSpeed>
                                <div class="option_btns mr-1">
                                    <div class="opt_block">
                                        <div>{{"Speed" | translate}}: <span>{{projectSpeed}}x</span></div>
                                        <mat-slider [(ngModel)]="projectSpeed" color="primary" [min]="0.25" [max]="3" [step]="0.01" [value]="projectSpeed" (valueChange)="changeProjectSpeed($event)" discrete>
                                            <input matSliderThumb>
                                        </mat-slider>
                                    </div>
                                </div>
                            </ng-template>
                            
                            <div class="times">
                                <span>{{currentTimeProject | veTime}}</span>
                                <span>/</span>
                                <span>{{duration | veTime}}</span>
                            </div>
                        </div>
                        
                        <div class="d-f-c">
                            <ng-container *ngIf="!data?.thumbMode">
                                <div class="add_media">
                                    <div class="add_media_btn" (click)="toggleMediaMenu()">
                                        {{ "Add file" | translate }}
                                    </div>
                    
                                    <div class="add_media_menu_out" *ngIf="isMediaMenuOpen">
                                        <div class="add_media_menu_in">
                                            <div class="add_media_item title">{{"Add file" | translate}}</div>
                                            <div class="add_media_divider"></div>
                                            <div class="add_media_item" (click)="selectFile('audio')">
                                                {{ "Audio file(s)" | translate }}
                                            </div>
                                            <div class="add_media_item" (click)="selectFile('video')">
                                                {{ "Video file(s)" | translate }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                
                                <!-- {{zoom.value}} -->
                                <div class="zoom_group">
                                    <div class="zoom_out" (click)="zoomMinus()" [ngClass]="{'disabled': minusDisabled}">
                                        <mat-icon>remove</mat-icon>
                                    </div>
                                    <div class="zoom_def" (click)="zoomDefault()">
                                        <mat-icon>compare_arrows</mat-icon>
                                    </div>
                                    <div class="zoom_in" (click)="zoomPlus()" [ngClass]="{'disabled': plusDisabled}">
                                        <mat-icon>add</mat-icon>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
         
                </div>
            </div>
            <!-- <audio class="audioPlayer" controls [src]="data?.host + (data?.file?.preview1080 ? data?.file?.preview1080 : data?.file?.original) + '?company_id=' + data?.company_id"></audio> -->
            <!-- <img style="max-width: 100%; max-height: 100%;" src="../../../../../../assets/img/part_transform_video.png" alt=""> -->
            <div class="trackpad_out noselect">
                <div class="trackpad_left" [ngClass]="{'active': leftActive}" (mouseenter)="startFunction($event, true)" (mouseleave)="stopFunction()" (mouseup)="stopFunction()">
                    <ng-container *ngFor="let track of tracks; let k = index">
                        <div class="track_menu" [ngClass]="{'large': track.type == 'video'}">
                            <ng-container *ngIf="track.type == 'video'; else elseTemplateVideoTrack">
                                <mat-icon>videocam</mat-icon>
                            </ng-container>
                            <ng-template #elseTemplateVideoTrack>
                                <mat-icon>audiotrack</mat-icon>
                            </ng-template>
                        </div>
                    </ng-container>
                </div>
                <div class="trackpad" [ngClass]="{'cut_mode': mode == 'cut'}" #outContainer appCtrlScroll (scrollDown)="onScrollDown()" (scrollTop)="onScrollTop()">
                    <div class="trackpad_in"  
                    (mousemove)="onMouseMove($event, wWidth)" 
                    (mouseleave)="onMouseLeave($event)" 
                    (mouseover)="onMouseOver($event)" 
                    (mouseenter)="onMouseEnter($event)" 
                    (mouseup)="onMouseUp($event)"
                    (mousedown)="onMouseDown($event)">
                        <!-- && verticalLine.show -->
                        <div class="hovered-time-marker" *ngIf="verticalLine && verticalLine.show && !cutEdited" [style.left.px]="(verticalLine.val / duration) * wWidth">
                            <div class="value">
                                {{verticalLine.val | veTime}}
                            </div>
                        </div>
                    
                        <!--  (click)="clickToCurrentTime($event)" -->
                        <div id="current-marker" class="current-time-marker" [ngClass]="{'current-cut-marker': mode == 'cut' && ((verticalLine.val|veTime) == (currentTimeProject|veTime))}" [style.left.px]="(currentTimeProject / duration) * wWidth">
                            <div class="mark">
                                <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 0H7V5L3.5 9.5L0 5V0Z" fill="#fff"/>
                                </svg>
                            </div>
                            <div class="line">
        
                            </div>
                        </div>
    
                        <div class="trackpad_line">
                            <!-- <div class="app-timeline-trackpad__cursor" style="height: 320px; transform: translate3d(538px, 0px, 0px);">
                                <div class="app-timeline-cursor">
                                    <div class="app-timeline-cursor__stick"></div> 
                                    <div class="app-timeline-cursor__line"></div>
                                </div> 
                                <div class="app-timeline-trackpad__cursor-alert app-timeline-trackpad__cursor-alert--align-top" style="transform: translate3d(-50%, 26px, 0px); display: none;">
                                    {{player?.currentTime | veTime}}
                                </div>
                            </div> -->
                            <div class="trackpad_line_in" [ngStyle]="{'width': wWidth+'px'}" #customTimeline>
        
                                <div class="trackpad_line_inside" *ngIf="liner && liner.times && liner.times.length">
                                    <div class="line" [ngStyle]="{'width': liner.width+'px'}" *ngFor="let time of liner.times; let l = index">
                                        <div class="vert"></div>
                                        <div class="vert_label">{{time | veTime}}</div>
                                        
                                        <div class="last_line" *ngIf="l == liner.times.length - 1">
                                            <div class="vert"></div>
                                            <div class="vert_label">{{duration | veTime}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
    
                        <div class="trackpad_tracks" [ngStyle]="{'width': wWidth+'px'}">
                            <!-- (dragover)="handleDragOver($event)" (drop)="handleDrop($event)" -->
                            <!-- <div class="timeline-spacer timeline-spacer--upper" [ngClass]="{'active': spacerActive == 1}"></div> -->
    
         
                            <!-- (drop)="handleDrop($event, track)" (dragleave)="handleDragLeave($event)" (dragover)="handleDragOver($event)" -->
                            <ng-container *ngFor="let track of tracks; let k = index">
                                <div class="track"  [ngClass]="{'large': track.type == 'video'}">
                                   
                                        <ng-container *ngFor="let part of track.parts; let p = index">
    
                                            <div class="hor_space" [attr.data-is-left]="1" [attr.data-track-el-index]="k" [attr.data-part-index]="p" [ngStyle]="{'transform': 'translate3d(' + ((part?.prevAllDuration / duration) * wWidth) + 'px, 0px, 0px)'}"></div>
                                            <div class="track_element timeline_el" (contextmenu)="open($event, part); $event.preventDefault();" [id]="'track_element_' + part?.pId" (click)="activateItem($event, track, k, part, p)" [ngClass]="{'audio_track_element': k != 0, 'selected': !!selectedFragments.isSelected(part), 'activated': !!activeItem && activeItem?.pId == part?.pId, 'no_vid': !part.is_video_only && track.type == 'video', 'dragging': !!part?.dragging, 'moved': movedFragment?.part?.pId == part?.pId}" *ngIf="part.duration" [ngStyle]="{'width': (((part?.duration / duration) * wWidth) - 2) + 'px', 'transform': 'translate3d(' + ((part?.prevAllDuration / duration) * wWidth) + 'px, 0px, 0px)'}">
                                                <div *ngIf="!!activeItem && activeItem?.pId == part?.pId" class="v_mask v_mask_left" (mousedown)="onMouseDownCut($event, part, true, k, p)">
                                                    <span *ngIf="activeItem.saveFTD && activeItem.from != activeItem.saveFTD.from">+{{(activeItem.from - activeItem.saveFTD.from) | veTime}}</span>
                                                </div>
                                                <div *ngIf="!!activeItem && activeItem?.pId == part?.pId" class="v_mask v_mask_right" (mousedown)="onMouseDownCut($event, part, false, k, p)">
                                                    <span *ngIf="activeItem.saveFTD && activeItem.to != activeItem.saveFTD.to">-{{(activeItem.saveFTD.to - activeItem.to) | veTime}}</span>
                                                </div>
                                                <!-- (dragstart)="handleDragStart($event, track, part, k, p)" (dragend)="handleDragEnd($event, part)" draggable="true" -->
                                                <div class="part_item" (mousedown)="onMouseDownPart($event, part, k, p)">
                                                    <div class="part_name text_one_line">
                                                        {{part.filename}} 
                                                    </div>
                                                    <div *ngIf="track.type == 'video' && !part?.is_detached" class="audio_placeholder" [ngClass]="{'no_aud': !part.is_audio_only}"></div>
                                                </div>
                                                <div class="tl_subs" *ngIf="part?.subs_on && part?.subs">
                                                    <ng-container *ngFor="let sub of part.subs; let sInd = index;">
                                                        <div class="sub_text" (dblclick)="editSub($event, sub, part, sInd)" (mousedown)="onMouseDownPartSub($event, part, k, p, sub, sInd)" *ngIf="sub.from > part.from && (sub.to < part.to)" [ngStyle]="{'width': ((((useSpeedRate((sub.to - part.from), part) - useSpeedRate((sub.from - part.from), part)) / duration) * wWidth)) + 'px', 'transform': 'translate3d(' + ((useSpeedRate((sub.from - part.from), part) / duration) * wWidth) + 'px, 0px, 0px)', 'z-index': sInd + 1, 'background': sub?.background || '#2986f0'}">
                                                            <span class="text_one_line">{{sub.text}}</span>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                                
                                                <!-- <div class="text_one_line">
                                                    {{part.saveFTD|json}}
                                                </div> -->
                                                <!-- <div class="text_one_line">
                                                    -- id:{{part?.pId}}
                                                    from-to: {{part?.from | veTime}} - {{part?.to | veTime}} | duration: {{part?.duration | veTime}} | prevAllDuration: {{part?.prevAllDuration | veTime}} | part_speed_rate: {{part?.part_speed_rate}}
                                                </div> -->
                                            </div>
                                            <div class="hor_space" [attr.data-is-left]="0" [attr.data-track-el-index]="k" [attr.data-part-index]="p" [ngStyle]="{'transform': 'translate3d(' + (+(((part?.prevAllDuration + part?.duration) / duration) * wWidth) - ((p == track.parts.length - 1) ? +1 : +0)) + 'px, 0px, 0px)'}"></div>
                                        </ng-container>
                                </div>
                                <!-- <div class="timeline-spacer timeline-spacer--intermediate" *ngIf="k != tracks.length - 1"> -->
        
                                <!-- </div> -->
                            </ng-container>
                            
                            <!-- (dragover)="handleDragOver($event)" (drop)="handleDrop($event)" -->
                            <div class="timeline-spacer timeline-spacer--lower" [ngClass]="{'active': spacerActive == 2}" (dragover)="handleDragOverSpacer($event)" (dragenter)="handleDragEnterSpacer($event, true)" (dragleave)="handleDragLeaveSpacer($event, true)" (drop)="handleDropSpacer($event, true)"></div>
                        </div>
                    </div>
                </div>
                <div class="trackpad_right" [ngClass]="{'active': rightActive}" (mouseenter)="startFunction($event, false)" (mouseleave)="stopFunction()" (mouseup)="stopFunction()"></div>
            </div>
    
        </div>   
    </mat-dialog-content>
</div>


<ng-template #exportSettingsMenu>
    <section class="exp_sett_context">
        <div class="context_head">
            {{"Export settings"}}: 
            <!-- is inherit: {{inheritTags}} -->
            <mat-icon class="cp" style="margin-left: 20px;" (click)="closeExportSettings()">close</mat-icon>
        </div>
        <div class="context_body">
            <ng-container *ngIf="saveMode.value == 'concat'; else elseTemplateISConMode">
                <mat-checkbox color="primary" class="example-margin" [checked]="inheritTags" (change)="toggleInheritTags()">{{ 'Inherit tags' | translate }}</mat-checkbox>
            </ng-container>
            <ng-template #elseTemplateISConMode>
                <mat-checkbox color="primary" class="example-margin readonly_check" [readonly]="true" [checked]="inheritTags" (change)="toggleInheritTags()">{{ 'Inherit tags' | translate }}</mat-checkbox>
            </ng-template>

            <div class="save_location">
                <div class="sys_folder">
                    <div (click)="foldersSelect.toggle()">
                        {{getFolderByLoc(save_folder.value).name}}
                        <mat-icon>arrow_drop_down</mat-icon>

                        <span class="slesh">/</span>
                    </div>
                    <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                        <mat-select disableOptionCentering #foldersSelect [formControl]="save_folder">
                            <mat-option *ngFor="let item of folders" [value]="item.location" [ngClass]="{'cst_active_select_option': save_folder.value == item.location}">
                                {{ item.name | translate }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <input class="ex_number reports_white_input" [(ngModel)]="save_location" matInput type="text">
            </div>
        </div>
    </section>
</ng-template>
<ng-template #wordEdit let-editData>
    <section class="word_edit_context">
        <ng-container *ngIf="editData?.word?.is_edited; else elseTemplateWordEdited">
            <div class="edit_word">
                <div class="edit_word_head">
                    {{"Correcting word"}}:
                    <mat-icon class="cp" (click)="toggleWordEdit($event, editData)">close</mat-icon>
                </div>
                <div class="edit_word_body">
                    <input class="ex_number reports_white_input" [(ngModel)]="editData.word.value" matInput type="text" (change)="onChangeWord($event, editData)">
                    <div class="word_edit_btn">{{"Ok" | translate}}</div>
                </div>
            </div>
        </ng-container>
        <ng-template #elseTemplateWordEdited>            
            <div class="word_edit_menu">
                <div class="word_edit_btn" (click)="toggleWordEdit($event, editData)">{{"Correct" | translate}}</div>
                <div class="word_edit_btn" (click)="onDeleteWord($event, editData)">{{"Delete" | translate}}</div>
            </div>
        </ng-template>
        
        <!-- <div>
            {{editData.word.value}}
        </div>
        <div>
            {{editData.part.id}}
        </div>
        <div>
            {{editData.wordInd}}
        </div>
        <div>
            {{editData.sub | json}}
        </div> -->
    </section>
</ng-template>

<ng-template #fileMenu let-part>
	<section class="file-menu">
        <ng-container *ngIf="selectedFragments.isSelected(part) && canCombine(); else elseTemplateSelectedFr">
            <div (click)="closeRightClick(); combineVideos(part)">
                {{ 'Combine' | translate }}
            </div>
        </ng-container>
        <ng-template #elseTemplateSelectedFr>
            <div *ngIf="!!part.is_combine" (click)="closeRightClick(); unCombineVideos(part)">
                {{ 'Uncombine' | translate }}
            </div>
            <div *ngIf="part.trackElIndex == 0 && !!part?.subs_on" (click)="closeRightClick(); startVoice(part)" [ngClass]="{'dis_click': !!part?.voiceStart}">
                {{ 'Start voice acting' | translate }}
            </div>
            <div *ngIf="part.trackElIndex == 0" (click)="closeRightClick(); detachAudio(part)" [ngClass]="{'dis_click': !!part?.is_detached}">
                {{ 'Detach' | translate }}
            </div>
            <div (click)="closeRightClick(); deleteActiveItem(part)">
                {{ 'Delete' | translate }}
            </div>
            <div class="menu_divider"></div>
            <div (click)="closeRightClick(); copy(part)">
                {{ 'Copy' | translate }}
            </div>
            <div (click)="closeRightClick(); cut(part)">
                {{ 'Cut' | translate }}
            </div>
        </ng-template>
        <div (click)="settings(part)">
            <span>{{ "File properties" | translate }}</span>
        </div>
	</section>
</ng-template>