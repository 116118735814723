import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { RootService } from 'src/app/shared/services/rest/root.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-workspace-limits',
  templateUrl: './workspace-limits.component.html',
  styleUrls: ['./workspace-limits.component.scss']
})

export class WorkspaceLimitsComponent extends BaseClass implements OnInit, OnDestroy {
  public form: FormGroup;
  public storageForm: FormGroup;
  public streamForm: FormGroup;
  public host: any = environment.host;
  public imgRoute: any = '';
  public views1_day: number = 1;
  public views2_day: number = 1;
  public activeIndex = new FormControl(0);

  public isSubmit: boolean = false;
  public submited: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private rootService: RootService,
    public dialogRef: MatDialogRef<WorkspaceLimitsComponent>,
    private fb: FormBuilder,
    private layoutService: LayoutService,
    private sm: StorageManagerService,
  ) { super() }

  ngOnInit(): void {
    this.dialogRef.addPanelClass("adding_executors_dialog")
    console.log("WorkspaceLimitsComponent", this.data);
    this.form = this.fb.group({
      is_views_count_update: this.data.is_views_count_update,
      is_rate_views_count_update_grace: this.data.is_rate_views_count_update_grace,
      rate_views_count_update_grace_time: !!this.data.rate_views_count_update_grace_time ? this.data.rate_views_count_update_grace_time / 3600 : this.data.rate_views_count_update_grace_time,
      rate_views_count_update_week: this.data.rate_views_count_update_week,
      rate_views_count_update_day: this.data.rate_views_count_update_day,
      rate_views_count_update_week_calc: Math.round(this.data.rate_views_count_update_week / 14),
      rate_views_count_update_day_calc: Math.round(this.data.rate_views_count_update_day / 14),
    })

    this.attachSubscriptions(
      this.form.get('rate_views_count_update_week').valueChanges.subscribe(res => {
        this.form.patchValue({rate_views_count_update_week_calc: Math.round(res / 14)}, {emitEvent: false})
      })
    )
    this.attachSubscriptions(
      this.form.get('rate_views_count_update_week_calc').valueChanges.subscribe(res => {
        if (res && typeof(+res) == 'number') {
          this.form.patchValue({rate_views_count_update_week: Math.round(res * 14)}, {emitEvent: false})
        }
      })
    )
    this.attachSubscriptions(
      this.form.get('rate_views_count_update_day').valueChanges.subscribe(res => {
        this.form.patchValue({rate_views_count_update_day_calc: Math.round(res / 14)}, {emitEvent: false})
      })
    )
    this.attachSubscriptions(
      this.form.get('rate_views_count_update_day_calc').valueChanges.subscribe(res => {
        if (res && typeof(+res) == 'number') {
          this.form.patchValue({rate_views_count_update_day: Math.round(res * 14)}, {emitEvent: false})
        }
      })
    )

    this.storageForm = this.fb.group({
      filesize_h1_limit: !!this.data.filesize_h1_limit ? this.data.filesize_h1_limit /1073741824 : 0,
      filesize_h2_limit: !!this.data.filesize_h2_limit ? this.data.filesize_h2_limit /1073741824 : 0,
      filesize_h3_limit: !!this.data.filesize_h3_limit ? this.data.filesize_h3_limit /1073741824 : 0,
    })

    this.streamForm = this.fb.group({
      live_stream_limit : !!this.data.live_stream_limit  ? this.data.live_stream_limit : 0
    })

    this.getImgRoute()
  }

  sumbitBtn() {
    if (this.activeIndex.value == 0) {
      this.onSubmit();
    } else if (this.activeIndex.value == 1) {
      this.onSubmitStorage();
    } else {
      this.onSubmitStream();
    }
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  log() {
    console.log(this.form.value)
  }

  changeTab(val) {
    this.activeIndex.patchValue(val);
  }

  close() {
    this.dialogRef.close({event: "close", data: false});
  }

  addExecutor() {
    this.isSubmit = true;

  }

  onSubmitStream() {
    this.isSubmit = true;
    let val = {...this.streamForm.value};
    this.attachSubscriptions(
      this.rootService.changeLimit(this.data.id, val).subscribe(resp => {
        this.data.live_stream_limit = resp.live_stream_limit;
        this.isSubmit = false;
        this.close()
      }, error => {
        this.isSubmit = false;
        this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
      })
    )
  }

  onSubmitStorage() {
    this.isSubmit = true;
    let val = {...this.storageForm.value};
    val.filesize_h1_limit = val.filesize_h1_limit*1073741824;
    val.filesize_h2_limit = val.filesize_h2_limit*1073741824;
    val.filesize_h3_limit = val.filesize_h3_limit*1073741824;
    this.attachSubscriptions(
      this.rootService.changeLimit(this.data.id, val).subscribe(resp => {
        this.data.filesize_h1_limit = resp.filesize_h1_limit;
        this.data.filesize_h2_limit = resp.filesize_h2_limit;
        this.data.filesize_h3_limit = resp.filesize_h3_limit;
        this.isSubmit = false;
        this.close()
      }, error => {
        this.isSubmit = false;
        this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
      })
    )
  }

  onSubmit() {
    this.isSubmit = true;
    let val = {...this.form.value};
    delete val.rate_views_count_update_week_calc;
    delete val.rate_views_count_update_day_calc;
    val.rate_views_count_update_grace_time = val.rate_views_count_update_grace_time*3600;
    this.attachSubscriptions(
      this.rootService.changeLimit(this.data.id, val).subscribe(resp => {
        this.data.is_views_count_update = resp.is_views_count_update;
        this.data.is_rate_views_count_update_grace = resp.is_rate_views_count_update_grace;
        this.data.rate_views_count_update_grace_time = resp.rate_views_count_update_grace_time;
        this.data.rate_views_count_update_week = resp.rate_views_count_update_week;
        this.data.rate_views_count_update_day = resp.rate_views_count_update_day;
        this.isSubmit = false;
        this.close()
      }, error => {
        this.isSubmit = false;
        this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
      })
    )
  }
  ngOnDestroy() {
    this.clearSubscriptions()
  }

}
