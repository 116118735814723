import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { BaseClass } from 'src/app/shared/models/base-class';
import { GlobalDataService } from 'src/app/shared/services/common/global-data.service';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { MembersService } from 'src/app/shared/services/rest/members.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';

@Component({
  selector: 'app-edit-price',
  templateUrl: './edit-price.component.html',
  styleUrls: ['./edit-price.component.scss']
})
export class EditPriceComponent extends BaseClass implements OnInit, OnDestroy {
  @ViewChild('currencySelect') currencySelect: MatSelect;
  public form: FormGroup;
  public currencyes: any;
  public isFormChange: boolean = false;
  public numberRegEx = /\d*\.?\d{1,2}/;
  
  constructor(
    private taskService: TaskService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<EditPriceComponent>,
    private fb: FormBuilder,
    private globalDataService: GlobalDataService,
    private companyService: CompanyService,
    private membersService: MembersService,
    private layoutService: LayoutService
  ) { super() }

  ngOnInit(): void {

    console.log("EditPriceComponent", this.data)
    this.getCurrencyList();
    this.form = this.fb.group({
      rate: [this.data.employee.rate, [Validators.pattern(this.numberRegEx)]],
      price: [this.data.employee.price, [Validators.pattern(this.numberRegEx)]],
      price_currency_id: this.data.employee.price_currency_id
    })

    if (this.data.employee.partner_company_id && this.data.employee.partnerCompany && !this.data.employee.partner_employee_id && !this.form.value.price_currency_id) {
      this.getPartners(this.data.employee.partnerCompany.id)
    }

    if (!this.data.empl_status) {
      if ((this.data.employee && this.data.employee.employee && !this.data.employee.employee.hasOwnProperty('rate_target')) || (this.data.employee && this.data.employee.employee && +this.data.employee.employee.rate_target === 0)) {
        this.form.removeControl("rate");
      }
    }

    this.attachSubscriptions(
      this.form.valueChanges.subscribe(() => this.isFormChange = true)
    )

    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        if (this.isFormChange) {
          this.layoutService.openBottomSheet(this.dialogRef);
        } else {
          this.close();
        }
      })
    )
  }

  getPartners(id) {
    this.attachSubscriptions(
      this.companyService.getPartners({company_id: this.data.company.id}).subscribe(resp => {
        console.log('resp', resp)
        if (resp.find(x => x.partner_company_id == id)) {
          this.form.patchValue({
            price_currency_id: resp.find(x => x.partner_company_id == id).currency_id
          })
        }
      })
    )
  }

  getCurrencyList() {
    this.attachSubscriptions(
      this.globalDataService.getCurrencies().subscribe(resp => {
        console.log("getCurrencyList", resp)
        let currencyData = [...resp]
        this.currencyes = currencyData;
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  onSubmit() {

    if (String(this.form.value['rate']).indexOf(",") != -1) {
      this.form.value['rate'] = this.form.value['rate'].replace(",", ".");
    }
    if (String(this.form.value['price']).indexOf(",") != -1) {
      this.form.value['price'] = this.form.value['price'].replace(",", ".");
    }

    if (this.form.value.price > 0 && !this.form.value.price_currency_id) {
      this.layoutService.showSnackBar({name: ''}, marker("To save the price set the currency "), SnackBarItem)
      if (this.currencySelect) {
        this.currencySelect.open()
      }
      return
    }

    if (this.form.value.price == null) {
      this.form.value.price = "0";
    }

    this.attachSubscriptions(
      (!!this.data.employee.is_partner ? this.membersService.editTaskPartner(this.data.employee.id, this.form.value, this.data.company.id) : (!!this.data.employee.is_partner_employee ? this.membersService.editTaskPartnerEmployee(this.data.employee.id, this.form.value, this.data.company.id) : this.membersService.editMember(this.data.employee.id, this.form.value, this.data.company.id))).subscribe(resp => {
        this.dialogRef.close({event: "update", data: resp})
      },
      error => {
        this.data.showError()
        this.dialogRef.close({event: "update", data: {}})
      })
    )
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

}
