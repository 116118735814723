import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseClass } from 'src/app/shared/models/base-class';
import { QuestsService } from 'src/app/shared/services/rest/quests.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';

@Component({
  selector: 'app-change-status',
  templateUrl: './change-status.component.html',
  styleUrls: ['./change-status.component.scss']
})
export class ChangeStatusComponent extends BaseClass implements OnInit, OnDestroy {
  public form: FormGroup;
  public questStatuses: any;
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ChangeStatusComponent>,
    private fb: FormBuilder,
    private questsService: QuestsService,
    private taskService: TaskService
  ) { super() }

  ngOnInit(): void {

    console.log(this.data)

    this.getQuestStatuses();
    this.form = this.fb.group({
      application_status_id: [this.data.employee.application_status_id, Validators.required],
    })
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  getQuestStatuses() {
    this.attachSubscriptions(
      this.questsService.getQuestStatuses().subscribe(resp => {
        this.questStatuses = resp;
      })
    )
  }

  onSubmit() {
    console.log("submit")
    this.attachSubscriptions(
      this.taskService.editEmployee(this.data.employee.id, this.form.value).subscribe(resp => {
        this.data.employee.application_status_id = this.form.value.application_status_id
        this.dialogRef.close({event: "edit", data: resp});
      })
    )
  }

  ngOnDestroy() {
    this.clearSubscriptions()
  }
}
