

<h2 mat-dialog-title class="dialog_title">
    <span>{{data.tmpl.name}} {{ "scheme" | translate }}</span>
    <button class="ml-1" mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h2>

<mat-dialog-content class="mat-typography">
    <div class="container auto_scheme">
        <!-- <div class="card flex justify-content-center">
            <p-organizationChart [value]="treeData">
                <ng-template let-node pTemplate="auto">
                    <div class="p-2 text-center">
                        <img [src]="node.data.image" class="mb-3 w-3rem h-3rem" />
                        <div class="font-bold">{{ node.data.name }}</div>
                        <div>{{ node.data.title }}</div>
                    </div>
                </ng-template>
            </p-organizationChart>
        </div> -->
        <div class="card flex justify-content-center">
            <p-organizationChart [value]="schemeData">
                <ng-template let-node pTemplate="auto">
                    <div class="p-2 text-center">
                        <div class="font-bold">{{ node.data.type + ' #' + node.data.id }}</div>
                        <div>{{ node.data.name }}</div>
                        <div *ngIf="node.data.cycleTo" class="d-f-c" style="justify-content: center;">
                            <mat-icon>recycling</mat-icon>
                        </div>
                        <!-- <div>{{ node.data.children|json }}</div>
                        <div>{{ node.data.parents|json }}</div> -->
                    </div>
                </ng-template>
            </p-organizationChart>
        </div>
    </div>
</mat-dialog-content>
