  <div class="workspace_steps">

      <mat-horizontal-stepper #stepper [selectedIndex]="selectedIndex" labelPosition="bottom" (selectionChange)="selectionChange($event)" [disableRipple]="false">

<!-- [editable]="true" -->
      <mat-step [stepControl]="timeForm" state="invite_members">
        <ng-template matStepLabel>{{ "Timezone" | translate }}</ng-template>
          <div class="empl_wrp step_inside">
            <form [formGroup]="timeForm">
              <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ "Time zone" | translate }}</mat-label>
                <mat-select disableOptionCentering formControlName="timezone">
                    <mat-option>
                        <ngx-mat-select-search [formControl]="timezoneFilterCtrl" noEntriesFoundLabel="'no matching time zone found'" placeholderLabel="{{ 'Find time zone' | translate }}..." ></ngx-mat-select-search>
                    </mat-option>
                    <mat-option value="no">-- {{ "My time zone is not" | translate }} --</mat-option>
                    <mat-optgroup *ngFor="let zone of filteredTimezonesGroups | async" [label]="getTimeZone(zone.value)"
                    [disabled]="zone.disabled">
                        <mat-option *ngFor="let name of zone.names" [value]="name">
                            {{name}}
                        </mat-option>
                    </mat-optgroup>
                </mat-select>
              </mat-form-field>
          
              <mat-form-field appearance="standard" class="full_width_form">
                  <mat-label>{{ 'Timezone offset' | translate }}</mat-label>
                  <input [readonly]="timeForm.get('timezone').value != 'no'" formControlName="timezone_offset" matInput placeholder="{{ 'Timezone offset' | translate }}">
              </mat-form-field>
            </form>
          </div>
          <div class="d-f-c btns_footer" style="justify-content: space-between;">
            <button mat-button (click)="goBack()">{{ "Back" | translate }}</button>
            <button mat-raised-button (click)="timeZoneNext()" color="primary">{{ "Next" | translate }}</button>
          </div>
        </mat-step>
        <mat-step [stepControl]="virtual && form" state="add_members">
          <div class="add_members_wrp">
            <ng-template matStepLabel>{{ "Add members" | translate }}</ng-template>
            <ng-container *ngIf="onlyMe; else elseTemplateonlyMe">
                <div class="members_wrp">
                    <div class="add_members members_btn" (click)="selectedIndex = 1; onlyMe = false;">
                        <svg style="width: 42px; height: 30px;" width="42" height="30" viewBox="0 0 42 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M26.25 15C30.3937 15 33.75 11.6438 33.75 7.5C33.75 3.35625 30.3937 0 26.25 0C22.1063 0 18.75 3.35625 18.75 7.5C18.75 11.6438 22.1063 15 26.25 15ZM26.25 3.75C28.3125 3.75 30 5.4375 30 7.5C30 9.5625 28.3125 11.25 26.25 11.25C24.1875 11.25 22.5 9.5625 22.5 7.5C22.5 5.4375 24.1875 3.75 26.25 3.75ZM26.25 18.75C21.2437 18.75 11.25 21.2625 11.25 26.25V30H41.25V26.25C41.25 21.2625 31.2563 18.75 26.25 18.75ZM15 26.25C15.4125 24.9 21.2062 22.5 26.25 22.5C31.3125 22.5 37.125 24.9188 37.5 26.25H15ZM9.375 20.625V15H15V11.25H9.375V5.625H5.625V11.25H0V15H5.625V20.625H9.375Z" fill="#EDEDED"/>
                        </svg>
                        <div>
                            <div class="big">
                                {{ "Add workspace members" | translate }}
                            </div>
                            <div class="small">
                                ({{ "Executors or/and Clients" | translate }})
                            </div>
                        </div>
                        
                    </div>
                    <div class="only_me members_btn" (click)="selectedIndex = 3; onlyMe = true;">
                        <div style="width: 100%;">
                            <div class="big">
                                {{ "Continue with me as a single user" | translate }}
                            </div>
                            <div class="small">
                                ({{ "More users may be added later" | translate }})
                            </div>
                        </div>
                    
                    </div>
                </div>
            </ng-container>
            <ng-template #elseTemplateonlyMe>       
              <div class="new_member_title">{{ "New workspace member" | translate }}</div>
              
              <div class="new_member_tab_group">
                <mat-tab-group [@.disabled]="true" (selectedTabChange)="tabChange($event)" [selectedIndex]="memberIndex">

                  <mat-tab>
                    <ng-template mat-tab-label>
                      {{ "Name" | translate }}
                    </ng-template>
                    <div class="v_m_tab">
                      <form [formGroup]="virtual" (ngSubmit)="createVirtual()">
                        <mat-form-field appearance="standard" class="full_width_form">
                            <mat-label>{{ 'Firstname' | translate }}</mat-label>
                            <input required formControlName="firstname" matInput>
                        </mat-form-field>
                        <mat-form-field appearance="standard" class="full_width_form">
                            <mat-label>{{ 'Lastname' | translate }}</mat-label>
                            <input required formControlName="lastname" matInput>
                        </mat-form-field>
                        <div class="d-f-c btns_footer" style="justify-content: flex-end;">
                          <button mat-button (click)="cancelOnlyMe()">{{ "Back" | translate }}</button>
                          <button mat-raised-button color="primary" class="ml-1" >{{ "Next" | translate }}</button>
                        </div>
                      </form>
                    </div>
                  </mat-tab>

                  <mat-tab [disabled]="!form.get('vuser_id').value">
                    <ng-template mat-tab-label>
                      {{ "Rights and roles" | translate }}
                    </ng-template>
                    <div class="v_m_tab">
                      <form [formGroup]="form">
          
                        <div class="d-f-c" style="padding: 1.34375em 0;">
                          <div class="mr-1">{{ "Manager" | translate}}</div>
                          <mat-checkbox formControlName="permissions" color="primary" [value]="'manager'"></mat-checkbox>
                        </div>
                        
                        <div class="member_rule_info_wrp">
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 21H23L12 2L1 21ZM13 18H11V16H13V18ZM13 14H11V10H13V14Z" fill="#FF6A6A"/>
                          </svg>
                          <div>
                            The same rights as owner (you), but with no rights to: 
                            <br>
                            — Delete anything
                          </div>
                            
                        </div>
                      </form>
                    </div>
                    <div class="d-f-c btns_footer" style="justify-content: flex-end;">
                      <button type="button" mat-button (click)="memberIndex = memberIndex - 1">{{ "Back" | translate }}</button>
                      <button type="button" mat-raised-button color="primary" class="ml-1" (click)="memberIndex = memberIndex + 1">{{ "Next" | translate }}</button>
                    </div>
                  </mat-tab>

                  <!-- [disabled]="!form.get('vuser_id').value" -->
                  <mat-tab [disabled]="!form.get('vuser_id').value">
                    <ng-template mat-tab-label>
                      {{ "Conditions" | translate }}
                    </ng-template>
                    <div class="v_m_tab">
                      <ng-container [ngSwitch]="payState">
                        <ng-container *ngSwitchCase="0">
                          <div class="question">{{ "Do you plan to pay" | translate }} {{firstname.value + " " + lastname.value}} {{" for executing your tasks?" | translate }}</div>
                          <div class="d-f-c btns_footer" style="justify-content: flex-end;">
                            <button type="button" mat-raised-button (click)="memberIndex = memberIndex + 1; createMember()">{{ "No, I am not going to pay" | translate }}</button>
                            <button type="button" mat-raised-button color="primary" class="ml-1" (click)="payState = payState + 1">{{ "Yes, I am going to pay" | translate }}</button>
                          </div>
                        </ng-container>
                        <ng-container *ngSwitchCase="1">
                          <form [formGroup]="form">
                            <div class="question">{{ "What currency will you use for estimation and pay his/her job?" | translate }}</div>
                            <mat-form-field appearance="standard" class="full_width_form">
                                <mat-label>{{ "Currency" | translate }}</mat-label>
                                <mat-select formControlName="currency_id" required>
                                  <mat-option *ngFor="let currency of currencyList" [value]="currency.id">
                                      {{ currency.name }}
                                  </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </form>
                        </ng-container>
                        <ng-container *ngSwitchCase="2">
                          <div class="question">{{ "How do calculate earnings for " | translate }} {{firstname.value + " " + lastname.value + "?"}}</div>
                          <mat-radio-group (change)="selectEarningsCalc($event)" [value]="earningsVal.value" aria-label="Select an option">
                            <div>
                              <mat-radio-button color="primary" value="1">{{ "By particular price for every task/job" | translate }}</mat-radio-button>
                            </div>
                            <div>
                              <mat-radio-button color="primary" value="2">{{ "There is monthly rate" | translate }}</mat-radio-button>
                            </div>
                          </mat-radio-group>
                          <form [formGroup]="form" *ngIf="earningsVal.value == 2">
                            <mat-form-field appearance="standard" class="full_width_form">
                              <mat-label>{{ 'Monthly rate' | translate }}</mat-label>
                              <input formControlName="salary" matInput type="number" placeholder="{{ 'Salary' | translate }}">
                              <span class="silver_sufix" matSuffix>{{ getCurrency(form.get('currency_id').value) ? getCurrency(form.get('currency_id').value).iso_name : 'USD' }}</span>
                            </mat-form-field>
                          </form>
                        </ng-container>
                        <ng-container *ngSwitchCase="3">
                          <div class="question">{{ "Set up productivity measurement by points" | translate }}</div>
                          <div class="member_rule_info_wrp">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M1 21H23L12 2L1 21ZM13 18H11V16H13V18ZM13 14H11V10H13V14Z" fill="#FF6A6A"/>
                            </svg>
                            <div>
                              This allows you to control prodictivity of executors who work on monthly rate basis.
                              You define standart job as equal to 1 point. Then you have to define how many standart
                              jobs can be performed per month. This will be the monthly amount of points that
                              should be accumulated by your executor to assume that his prodictivity was 100% and he
                              earned 100% of monthly salary rate. Every task he/she evaluates in comparison to
                              standart job and defines how much points the particlear job is equal. 
                              If you are not sure you may set up this later. You may schedule free consultation with
                              our experts on this to assist you in define you points system.                              
                            </div>
                          </div>
                          <form [formGroup]="form">
                            <mat-form-field appearance="standard" class="full_width_form">
                              <mat-label>{{ 'Monthly points rate' | translate }}</mat-label>
                              <input formControlName="rate_target" matInput type="number" placeholder="{{ 'Rate' | translate }}">
                              <span class="silver_sufix" matSuffix>PTS</span>
                            </mat-form-field>
                          </form>
                        </ng-container>
                      </ng-container>
                    </div>
                    <div class="d-f-c btns_footer" style="justify-content: flex-end;">
                      <button type="button" mat-button (click)="memberIndex = memberIndex - 1; payState = 0;">{{ "Back" | translate }}</button>
                      
                      <ng-container [ngSwitch]="payState">
                        <ng-container *ngSwitchCase="0">
     
                        </ng-container>
                        <ng-container *ngSwitchCase="1">
                          <button type="button" class="ml-1" mat-raised-button (click)="memberIndex = memberIndex + 1; payState = 0; createMember()">{{ "Skip for now, set up later" | translate }}</button>
                          <button type="button" class="ml-1" color="primary" mat-raised-button (click)="setCurrency()">{{ "Next" | translate }}</button>
                        </ng-container>
                        <ng-container *ngSwitchCase="2">
                          <button type="button" class="ml-1" mat-raised-button (click)="skipNow();">{{ "Skip for now, set up later" | translate }}</button>
                          <button type="button" class="ml-1" color="primary" mat-raised-button (click)="setEarning()">{{ "Next" | translate }}</button>
                        </ng-container>
                        <ng-container *ngSwitchCase="3">
                          <button type="button" class="ml-1" mat-raised-button (click)="skipNowRate()">{{ "Skip for now, set up later" | translate }}</button>
                          <button type="button" class="ml-1" color="primary" mat-raised-button (click)="setRate()">{{ "Next" | translate }}</button>
                        </ng-container>
                      </ng-container>
                    </div>
                  </mat-tab>

                  <mat-tab [disabled]="!form.get('vuser_id').value && !newUser?.vuser_id">
                    <ng-template mat-tab-label>
                      {{ "Invitation" | translate }}
                    </ng-template>
                    <div class="question">{{ "Send invitation to" | translate }} {{newUser?.vuser?.name}}</div>
                    <button type="button" class="ml-1 d-f-c" color="primary" mat-raised-button ngxClipboard [cbContent]="host+'/?code='+newUser?.invite" (click)="copyInvite(newUser)">
                      <svg style="margin-right: 20px;" width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 18H2V5C2 4.45 1.55 4 1 4C0.45 4 0 4.45 0 5V18C0 19.1 0.9 20 2 20H12C12.55 20 13 19.55 13 19C13 18.45 12.55 18 12 18ZM17 14V2C17 0.9 16.1 0 15 0H6C4.9 0 4 0.9 4 2V14C4 15.1 4.9 16 6 16H15C16.1 16 17 15.1 17 14ZM15 14H6V2H15V14Z" fill="white"/>
                      </svg>
                      {{ "Copy invitation link" | translate }}
                    </button>
                    
                    <!-- <mat-form-field appearance="standard" class="full_width_form">
                      <mat-label>E-mail</mat-label>
                      <input matInput placeholder="pat@example.com">
                    </mat-form-field> -->
                    <div class="d-f-c btns_footer" style="justify-content: flex-end;">
                      <button type="button" mat-button (click)="memberIndex = memberIndex - 1; payState = 0;">{{ "Back" | translate }}</button>
                      <button type="button" class="ml-1" mat-raised-button (click)="addAnother()">{{ "Add new workspace member" | translate }}</button>
                      <button type="button" class="ml-1" color="primary" mat-raised-button matStepperNext>{{ "Next" | translate }}</button>
                  
                    </div>
                  </mat-tab>

                </mat-tab-group>
              </div>

              
            </ng-template>
          </div>
        </mat-step>



        <mat-step [stepControl]="secondFormGroup" state="invite_members">
          <ng-template matStepLabel>{{ "Invite members" | translate }}</ng-template>
            <div class="empl_wrp step_inside">
              <ng-container *ngIf="employees?.length; else elseTemplateEMPL">
                <div class="empl" *ngFor="let employee of employees">
                  <app-user [class]="'user_div'" [employee]="employee"></app-user>
                  <ng-container *ngIf="!employee?.user_id">
                    <div class="d-f-c empl_right">
                      <div class="d-f-c" style="cursor: pointer;" ngxClipboard [cbContent]="origin+'/?code='+employee?.invite" (click)="copyInvite(employee)">
                        <div class="empl_hover">
                          {{ "Copy invitation URL" | translate }}
                          <mat-icon style="color: #000;">content_copy</mat-icon>
                        </div>
                        {{employee?.invite}}
                      </div>
                    </div>
                  </ng-container>
                </div>
              </ng-container>
              <ng-template #elseTemplateEMPL>
                {{ "You do not have workspace members, first add members." | translate }}
              </ng-template>
            </div>
            <div class="d-f-c btns_footer" style="justify-content: space-between;">
              <button mat-button matStepperPrevious>{{ "Back" | translate }}</button>
              <button mat-raised-button matStepperNext color="primary">{{ "Next" | translate }}</button>
            </div>
        </mat-step>



        <mat-step [stepControl]="thirdFormGroup" state="types">

          <form [formGroup]="thirdFormGroup" class="stepper_form">

            <ng-template matStepLabel>{{ "Structure Types" | translate }}</ng-template>

            <mat-tab-group [@.disabled]="true" mat-align-tabs="center" [selectedIndex]="tabIndex">

              <mat-tab label="{{ 'Card Types' | translate}}">
                <div class="types_wrp step_inside">
                  <mat-list role="list" class="job_types">
                    <mat-list-item *ngFor="let type of taskTypes; let i = index;" role="listitem" class="company_item">
                        <div class="type_name">
                            <mat-checkbox [disabled]="type.disabled" class="mr-1" color="primary" (change)="toggleTaskType($event, type)" [checked]="type?.taskStatusToCompany?.length > 0"></mat-checkbox>
                            {{ type?.name }}
                        </div>
        
                        <div class="d-f-c">
                            <div class="company_btns" *ngIf="type?.company_id == company?.id">
                                <mat-icon (click)="editTaskType(type)">edit</mat-icon>
                                <mat-icon (click)="deleteTaskType(type, i)">delete</mat-icon>
                            </div>
                        </div>
                    </mat-list-item>
                    <div style="padding: 10px">
                      <button mat-raised-button (click)="createTaskType()">{{ "New type" | translate }}</button>
                    </div>
                  </mat-list>
                </div>
                <div class="btns_footer d-f-c" style="justify-content: space-between;">
                  <button mat-button matStepperPrevious>{{ "Back" | translate }}</button>
                  <button mat-raised-button color="primary" (click)="tabIndex = 1">{{ "Next" | translate }}</button>
                </div>
              </mat-tab>

              <mat-tab label="{{ 'Job types' | translate}}">
                <div class="types_wrp step_inside">
                  <mat-list role="list" class="job_types">
                    <mat-list-item *ngFor="let type of types; let i = index;" role="listitem" class="company_item">
                        <div class="type_name">
                            <mat-checkbox [disabled]="type.disabled" class="mr-1" color="primary" (change)="toggleType($event, type)" [checked]="type?.operationToCompany?.length > 0"></mat-checkbox>
                            {{ type?.name }}
                        </div>
        
                        <div class="d-f-c">
                            <div class="company_btns" *ngIf="type?.company_id == company?.id">
                                <mat-icon (click)="editType(type)">edit</mat-icon>
                                <mat-icon (click)="deleteType(type, i)">delete</mat-icon>
                            </div>
                        </div>
                      </mat-list-item>
                      <div style="padding: 10px">
                        <button mat-raised-button (click)="createType()">{{ "New type" | translate }}</button>
                      </div>
                  </mat-list>
                </div>
                <div class="btns_footer d-f-c" style="justify-content: space-between;">
                  <button mat-button (click)="tabIndex = 0">{{ "Back" | translate }}</button>
                  <button mat-raised-button color="primary" (click)="typesNext(stepper)">{{ "Next" | translate }}</button>
                </div>
              </mat-tab>

            </mat-tab-group>

          </form>
        </mat-step>



        <mat-step state="groups">
          <ng-template matStepLabel>{{ "Groups" | translate }}</ng-template>
          <div class="groups_wrp step_inside">
            <mat-list role="list" class="job_types">
              <mat-list-item *ngFor="let group of groups; let i = index;" role="listitem" class="company_item">
                <div class="d-f-c" style="justify-content: space-between; width: 100%;">
                  <span>
                    {{group.name}}
                  </span>
                  <div class="d-f-c">
                    <button mat-icon-button class="mr-1" (click)="deleteGroupCompany(group?.id, i)"><mat-icon style="color: red;">delete</mat-icon></button>
                    <button mat-icon-button (click)="editGroup(group)"><mat-icon style="color: green;">edit</mat-icon></button>
                  </div>
                </div>
              </mat-list-item>
              <div style="padding: 10px">
                <button mat-raised-button color="primary" (click)="createGroup()">{{ "New group" | translate }}</button>
              </div>
            </mat-list>
          </div>
          <div class="btns_footer d-f-c" style="justify-content: space-between;">
            <button mat-button matStepperPrevious>{{ "Back" | translate }}</button>
            <button mat-raised-button color="primary" (click)="finish()">{{ "Finish" | translate }}</button>
          </div>
        </mat-step>

      </mat-horizontal-stepper>
  </div>
  