import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { NgxMatDateAdapter } from '@angular-material-components/datetime-picker';
import { MY_NEW_FORMATS } from '../add-task-profile/add-task-profile.component';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { GlobalDataService } from 'src/app/shared/services/common/global-data.service';
import { map, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
@Component({
  selector: 'app-post-to-plan',
  templateUrl: './post-to-plan.component.html',
  styleUrls: ['./post-to-plan.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_NEW_FORMATS},
  ]
})
export class PostToPlanComponent extends BaseClass implements OnInit, OnDestroy {
  public isSubmit: boolean = false;
  public selectedPlan: any;
  public host: any = environment.host;
  public timeZone = - new Date().getTimezoneOffset();
  public plans: any;
  public regularDateTimes: any = [];
  public activeLang: any;
  public profileStatuses: any;

  constructor(
    private taskService: TaskService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PostToPlanComponent>,
    private fb: FormBuilder,
    private _ngx_adapter: NgxMatDateAdapter<any>,
    private _adapter: DateAdapter<any>,
    private globalDataService: GlobalDataService,
    private languageService: LanguageService,
    private companyService: CompanyService,
    private layoutService: LayoutService
  ) { super() }

  ngOnInit(): void {
    console.log("PostToPlanComponent", this.data)
    this.getLangData();
    this.getProfilesStatus();
    this.getFreeRegulars();
  }

  getProfilesStatus() {
    this.attachSubscriptions(
      this.globalDataService.getContentStatus().subscribe(resp => {
        console.log("getContentStatus", resp);
        this.profileStatuses = resp.slice();
      })
    )
  }

  getStatusById(id) {
    if (!this.profileStatuses || this.profileStatuses.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.profileStatuses.find(el => el.id == id)
  }

  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData().subscribe(resp => {
        this._ngx_adapter.setLocale(resp.active);
        this._adapter.setLocale(resp.active);
        this.activeLang = resp.active;
      })
    )
  }

  acceptRegularTime(item) {
    console.log("acceptRegularTime", item);
    if (item.class == 'white') {
      this.selectedPlan = item;
    }
    // this.regularDateTimes = [];
  }

  getFreeRegulars() {
    let filterData = {
      channel_id: this.data.profile.channel_id,
      content_type_id: this.data.profile.content_type_id,
      publish_at_from: moment().startOf('day').format("X")
    }

    
    this.attachSubscriptions(
      this.companyService.getExpandPublishScheduledPosts('1', this.data.company_id, {...filterData}, '20').pipe(
        map(el => el.body),
        switchMap(contents => {
          let urlsData:any = {
            channel_id: this.data.profile.channel_id,
            content_type_id: this.data.profile.content_type_id,
            content_published_at_from: moment().startOf('day').format("X")
          };
       
          return this.taskService.getExpandPublishUrls('1', this.data.company_id, {...urlsData}, '20').pipe(
            map(expUrls => {
              return {    
                urls: expUrls.body,
                contents: contents
              }
            })
          )
        })
      ).subscribe((resp:any) => {

        let urls = resp.urls
        urls.forEach(x => {
          x.scheduledObj = {};
          x.name = x.content_name;
        })

        resp.contents.forEach(con => {
          if (urls.length > 0 && urls.filter(x => x.content_published_at == con.publish_at).length > 0) {
            urls.find(x => x.content_published_at == con.publish_at).scheduledObj = con
          } else {
            con.is_con = true;
            con.content_published_at = con.publish_at;
            urls.push(con)
          }
        })

        urls.sort(function(a, b) {
          return (a.content_published_at - b.content_published_at);
        })

        let regularFreeDateTimes = [...urls]

        regularFreeDateTimes.forEach(x => {
          if (x.hasOwnProperty('scheduledObj')) {
            if (moment().isSameOrAfter(moment(x.content_published_at*1000))) {
              x.class = 'green'
            } else {
              x.class = 'blue'
            }
          } else {
            if (moment().isSameOrAfter(moment(x.content_published_at*1000))) {
              x.class = 'silver'
            } else {
              if (!!x.task_id) {
                x.class = 'yelow'
              } else {
                x.class = 'white'
              }
            }
          }
        })
        console.log("regularFreeDateTimes", regularFreeDateTimes)

        this.regularDateTimes.push(...regularFreeDateTimes);
        if (this.regularDateTimes.find(x => x.task_id == this.data.task.id)) {
          this.selectedPlan = this.regularDateTimes.find(x => x.task_id == this.data.task.id);
        }
        
      })
    )
  }

  showUserDate(date:any = false, timestamp:any = false) {
    let timeAndDate;
    if (date) {
      timeAndDate = moment(date).clone()
    } else {
      timeAndDate = moment(timestamp*1000).clone()
    }

    const utcOffset = moment.tz(timeAndDate, this.data.company.timezone).utcOffset();
    
    let utcTimestamp = moment(timeAndDate).format("X")
    
    let difference = (Number(this.timeZone) - Number(utcOffset))*60
    // console.log(difference);
    
    let workSpaceTimeZone;
    if (date) {
      workSpaceTimeZone = Number(utcTimestamp) + Number(difference)
    } else {
      workSpaceTimeZone = Number(utcTimestamp) - Number(difference)
    }
    return workSpaceTimeZone
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  onSubmit() {
    this.isSubmit = true;
    if (this.data.profile.contentPlan) {
      this.attachSubscriptions(
        this.companyService.editScheduledPost(this.data.profile.contentPlan.id, {task_id: 0, task_channel_id: 0}).pipe(
          switchMap(x => this.companyService.editScheduledPost(this.selectedPlan.id, {task_id: this.data.task.id, task_channel_id: this.data.profile.id}))
        ).subscribe(resp => {
          this.dialogRef.close({event: "edit", data: resp})
        },
        error => {
          this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
        })
      )
    } else {
      this.attachSubscriptions(
        this.companyService.editScheduledPost(this.selectedPlan.id, {task_id: this.data.task.id, task_channel_id: this.data.profile.id}).subscribe(resp => {
          this.dialogRef.close({event: "edit", data: resp})
        },
        error => {
          this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
        })
      )
    }

  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
