import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, of } from 'rxjs';
import { last, map, switchMap } from 'rxjs/operators';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { ClientsService } from 'src/app/shared/services/rest/clients.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';

@Component({
  selector: 'app-service',
  templateUrl: './service.component.html',
  styleUrls: ['./service.component.scss']
})
export class ServiceComponent extends BaseClass implements OnInit, OnDestroy {
public form: FormGroup = this.fb.group({
  planned_live_stream_limit: this.data.company.planned_live_stream_limit,
  count: this.data.company.live_stream_limit
});
public isSubmit: boolean = false;
public nextPlan: any;
public currentPlan: any;
public result: any;
public calcRes = JSON.parse(JSON.stringify(this.form.value));
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<ServiceComponent>,
    private companyService: CompanyService,
    private layoutService: LayoutService
  ) { super() }

  ngOnInit(): void {
    console.log("", this.data)
  }

  onInput(event: Event, key, min?, max?): void {
    const inputElement = event.target as HTMLInputElement;
    let value = Number(inputElement.value);
  
    // Если значение меньше min или не является числом, устанавливаем 60
    if (!!min) {
      if (isNaN(value) || value < min) {
        value = min;
      }
    } else {
      if (isNaN(value)) {
        value = this.data.company[key];
      }
    }

    if (max && value > max) {
      value = max;
    }
  
    // Обновляем значение в форме
    this.form.get(key)?.setValue(value);
  }

  log() {
    console.log("this.currentPlan", this.currentPlan)
    console.log("this.nextPlan", this.nextPlan)
    console.log("this.result", this.result)
    console.log("this.calcRes", this.calcRes)
    console.log("this.form.value", this.form.value)
    console.log("this.data", this.data)
  }

  onSubmit() {
    this.currentPlan = undefined;
    this.calcRes = JSON.parse(JSON.stringify(this.form.value));
    this.isSubmit = true;
    this.attachSubscriptions(
      this.companyService.getWorkspacePlans("1", {company_id: this.data.company.id}).pipe(
        map(k => k.body),
        switchMap(plans => {

          if (plans.filter(x => x.restriction_min_live_stream_limit <= this.calcRes.planned_live_stream_limit).length) {
            let currentPlans = plans.filter(x => x.restriction_min_live_stream_limit <= this.calcRes.planned_live_stream_limit).sort((a, b) => a.price - b.price);
            if (currentPlans[0]) {
              this.currentPlan = currentPlans[0]
            } else {
              this.currentPlan = undefined;
            }
            // this.currentPlan = plans.filter(x => x.restriction_min_live_stream_limit <= this.calcRes.planned_live_stream_limit).reduce((max, obj) =>
            //   obj.restriction_min_live_stream_limit > max.restriction_min_live_stream_limit ? obj : max
            // );
          } else {
            this.currentPlan = undefined;
          }

          if (plans.filter(x => x.restriction_min_live_stream_limit > this.calcRes.planned_live_stream_limit).length) {
            let nextPlans = plans.filter(x => x.restriction_min_live_stream_limit > this.calcRes.planned_live_stream_limit).sort((a, b) => a.restriction_min_live_stream_limit - b.restriction_min_live_stream_limit);
            if (this.currentPlan && nextPlans.find(p => p.restriction_min_live_stream_limit > this.calcRes.planned_live_stream_limit && p.price < this.currentPlan.price)) {
              this.nextPlan = nextPlans.find(p => p.restriction_min_live_stream_limit > this.calcRes.planned_live_stream_limit && p.price < this.currentPlan.price)
            } else {
              this.nextPlan = undefined;
            }
          } else {
            this.nextPlan = undefined;
          }

          let info:any = {
            company_id: this.data.company.id,
            is_type_live_stream: 1,
          };

          info.live_stream_limit = this.form.value.count - this.data.company.live_stream_limit;

          console.log("info", info);
          if (!!info.live_stream_limit) {
            return this.companyService.addWorkspaceTransaction(info)
          } else {
            return of({name: "Q-ty now didn`t changed"})
          }
        })
      ).subscribe(resp => {
        console.log('resp', resp);
        this.result = resp
        this.isSubmit = false;
      })
    )
    // this.attachSubscriptions(
    //   this.companyService.addWorkspaceTransaction(this.form.value).subscribe(resp => {
    //     console.log(resp);
    //     this.isSubmit = false;
    //   })
    // )
  }

  confirm() {
    this.isSubmit = true;
    let arr = [];
    if (this.calcRes.planned_live_stream_limit == this.form.value.planned_live_stream_limit) {
      arr.push(this.companyService.editCompany(this.data.company.id, {planned_live_stream_limit: this.form.value.planned_live_stream_limit}))
    }
    if (this.result && this.result.id) {
      arr.push(this.companyService.editWorkspaceTransaction(this.result.id, {is_done: 1}))
    }
    if (arr.length) {
      this.attachSubscriptions(
        forkJoin(arr).pipe(last()).subscribe(resp => {
          console.log(resp);
          this.isSubmit = false;
          this.goBack();
        })
      )
    }
  }

  goBack() {
    this.dialogRef.close()
  }

  ngOnDestroy() {
    this.clearSubscriptions()
  }

}
