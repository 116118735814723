import { Directive, ElementRef, AfterViewInit, Input } from '@angular/core';
import * as hljs from 'highlight.js';

@Directive({
  selector: '[appHighlight]'
})
export class HighlightDirective implements AfterViewInit {
  @Input() language?: string;
  @Input() isMarkdown = false; // Флаг для Markdown

  constructor(private el: ElementRef) {}

  ngAfterViewInit(): void {
    console.log("HighlightDirective", this.el);
    this.highlight();
  }

  highlight() {
    console.log("highlight isMarkdown", this.isMarkdown);
    if (this.isMarkdown) {
      // Подсвечиваем все <code> внутри Markdown
      const codeBlocks = this.el.nativeElement.querySelectorAll('pre code');
      console.log("highlight codeBlocks", codeBlocks);
      codeBlocks.forEach((block: HTMLElement) => {
        hljs.highlightElement(block);
        console.log("highlight block", block);
      });
    } else {
      // Обычное поведение, если не Markdown
      if (this.language) {
        this.el.nativeElement.classList.add(this.language);
      }
      console.log("this.el.nativeElement", this.el.nativeElement);
      hljs.highlightElement(this.el.nativeElement);
    }
  }
}
