import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseClass } from 'src/app/shared/models/base-class';
import { ScenariosService } from 'src/app/shared/services/rest/scenarios.service';
import { TreeNode } from 'primeng/api';

@Component({
  selector: 'app-auto-scheme',
  templateUrl: './auto-scheme.component.html',
  styleUrls: ['./auto-scheme.component.scss']
})

export class AutoSchemeComponent extends BaseClass implements OnInit, OnDestroy {
  treeData: TreeNode[] = [
    {
        expanded: true,
        type: 'auto',
        styleClass: 'bg-indigo-500 text-white',
        data: {
            image: 'https://primefaces.org/cdn/primeng/images/demo/avatar/amyelsner.png',
            name: 'Amy Elsner',
            title: 'CEO'
        },
        children: [
            {
                expanded: true,
                type: 'auto',
                styleClass: 'bg-purple-500 text-white',
                data: {
                    image: 'https://primefaces.org/cdn/primeng/images/demo/avatar/annafali.png',
                    name: 'Anna Fali',
                    title: 'CMO'
                },
                children: [
                    {
                        label: 'Sales',
                        styleClass: 'bg-purple-500 text-white'
                    },
                    {
                        label: 'Marketing',
                        styleClass: 'bg-purple-500 text-white'
                    }
                ]
            },
            {
                expanded: true,
                type: 'auto',
                styleClass: 'bg-teal-500 text-white',
                data: {
                    image: 'https://primefaces.org/cdn/primeng/images/demo/avatar/stephenshaw.png',
                    name: 'Stephen Shaw',
                    title: 'CTO'
                },
                children: [
                    {
                        label: 'Development',
                        styleClass: 'bg-teal-500 text-white'
                    },
                    {
                        label: 'UI/UX Design',
                        styleClass: 'bg-teal-500 text-white'
                    }
                ]
            }
        ]
    }
  ];

  schemeData: TreeNode[] = []

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AutoSchemeComponent>,
    private dialog: MatDialog,
    private bottomSheet: MatBottomSheet,
    private scenariosService: ScenariosService,
  ) { super() }

  ngOnInit(): void {
    console.log("AutoSchemeComponent", this.data);
    
    let scheme = JSON.parse(this.data.tmpl.scheme_data)
    console.log("getTmpl scheme", scheme)

    this.schemeData.push({
      expanded: true,
      type: 'auto',
      data: Object.assign(scheme.tree[scheme.initial], {parents: []}),
      children: [

      ],
    })
    console.log('this.schemeData', this.schemeData);

    if (this.schemeData[0].data.children.length) {
      appendChildrensToNode(this.schemeData[0])
    }


    // function appendChildrensToNode(node) {
    //   node.data.children.forEach((child, i) => {
    //     node.children.push({
    //       expanded: !!scheme.tree[child].children.length,
    //       type: 'auto',
    //       data: Object.assign(scheme.tree[child], {parents: [...node.data.parents, {id: node.data.id, type: node.data.type}]}),
    //       children: [
    //       ],
    //     })
    //     if (!!node.children[i].expanded) {
    //       appendChildrensToNode(node.children[i]);
    //     }
    //   })
    // }

    function appendChildrensToNode(node) {
      if (node.data.children.length) {
        node.data.children.forEach((child, i) => {
          node.children.push({
            expanded: !!scheme.tree[child].children.length,
            type: 'auto',
            data: Object.assign(JSON.parse(JSON.stringify(scheme.tree[child])), { parents: [...node.data.parents, node.data.type+"#"+node.data.id] }),
            children: [],
          });
          
          if (node.data.parents.filter(x => x == child).length == 0) {
            if (!!node.children[i].expanded) {
              appendChildrensToNode(node.children[i]);
            }
          } else {
            node.children[i].data.cycleTo = true;
          }
        });
      }
    }

  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  test(e) {
    console.log(e);
  }

  ngOnDestroy(): void {
    this.clearSubscriptions();
  }
}
