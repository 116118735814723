<mat-dialog-content class="mat-typography" dirEnter (dirSubmit)="!form.valid || isSubmit ? null : onSubmit()">
    <div class="container form_wrapper">
        <div class="title_modal">
            <ng-container *ngIf="data.sftp; else elseTemplateEdit">
                {{ "Editing Youtube Sftp Dropbox" | translate }}
            </ng-container>
            <ng-template #elseTemplateEdit>
                {{ "Creating Youtube Sftp Dropbox" | translate }}
            </ng-template>
        </div>
        <form [formGroup]="form" class="form_with_pads form_with_valids">
            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ 'Name' | translate }}</mat-label>
                <input formControlName="name" matInput placeholder="{{ 'Name' | translate }}">
            </mat-form-field>

            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ 'Host' | translate }}</mat-label>
                <input formControlName="host" matInput placeholder="{{ 'Host' | translate }}">
            </mat-form-field>
            
            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ 'Username' | translate }}</mat-label>
                <input formControlName="username" matInput placeholder="{{ 'Username' | translate }}">
            </mat-form-field>

            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ 'Port' | translate }}</mat-label>
                <input formControlName="port" matInput placeholder="{{ 'Port' | translate }}">
            </mat-form-field>

            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ 'Folder' | translate }}</mat-label>
                <input formControlName="reports_directory" matInput placeholder="{{ 'Folder' | translate }}">
            </mat-form-field>

            <ng-container *ngIf="!data.sftp">
                <p>
                    {{ "Keys" | translate }}:
                    <mat-button-toggle-group appearance="legacy" class="ml-1" formControlName="is_generate_keys" [value]="form.get('is_generate_keys').value">
                        <mat-button-toggle [value]="1">{{ "Generate" | translate }}</mat-button-toggle>
                        <mat-button-toggle [value]="0">{{ "Enter own" | translate }}</mat-button-toggle>
                    </mat-button-toggle-group>
                </p>
    
                <ng-container *ngIf="form.get('is_generate_keys').value === 0">
                    <mat-form-field appearance="standard" class="full_width_form">
                        <mat-label>{{ 'Public key' | translate }}</mat-label>
                        <textarea 
                        cdkTextareaAutosize
                        #autosize="cdkTextareaAutosize"
                        cdkAutosizeMinRows="2"
                        cdkAutosizeMaxRows="10" formControlName="public_key" matInput></textarea>
                    </mat-form-field>
                    <mat-form-field appearance="standard" class="full_width_form">
                        <mat-label>{{ 'Private key' | translate }}</mat-label>
                        <textarea 
                        cdkTextareaAutosize
                        #autosize="cdkTextareaAutosize"
                        cdkAutosizeMinRows="2"
                        cdkAutosizeMaxRows="10" formControlName="private_key" matInput></textarea>
                    </mat-form-field>
                </ng-container>
            </ng-container>
            
        </form>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <div class="f_w_p_btns">
        <button mat-button (click)="close()">
            {{ "Close" | translate }}
        </button>
        <button class="ml-1 d-f-btn" (click)="onSubmit()" cdkFocusInitial mat-raised-button [disabled]="isSubmit || !form.valid">
            <mat-spinner class="spin_16 mr-1" *ngIf="isSubmit"></mat-spinner>  
            <ng-container *ngIf="data.sftp; else elseTemplate">
                {{ "Edit" | translate }}
            </ng-container>
            <ng-template #elseTemplate>
                {{ "Add" | translate }}
            </ng-template>
        </button>
    </div>
</mat-dialog-actions>
