<mat-dialog-content class="mat-typography" dirEnter (dirSubmit)="!form.valid || isSubmit ? null : onSubmit()">
    <div class="container form_wrapper">
        <div class="title_modal">
            <ng-container *ngIf="data.import; else elseTemplateEdit">
                {{ "Editing Import" | translate }}
            </ng-container>
            <ng-template #elseTemplateEdit>
                {{ "Creating Import" | translate }}
            </ng-template>
        </div>
        <form [formGroup]="form" class="form_with_pads form_with_valids">
            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ 'Name' | translate }}</mat-label>
                <input formControlName="name" matInput placeholder="{{ 'Name' | translate }}">
            </mat-form-field>

            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ 'Url' | translate }}</mat-label>
                <input formControlName="file_url" matInput placeholder="{{ 'Url' | translate }}">
            </mat-form-field>   

            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ "Time zone" | translate }}</mat-label>
                <mat-select disableOptionCentering formControlName="timezone">
                    <mat-option>
                        <ngx-mat-select-search [formControl]="timezoneFilterCtrl" noEntriesFoundLabel="'no matching time zone found'" placeholderLabel="{{ 'Find time zone' | translate }}..." ></ngx-mat-select-search>
                    </mat-option>
                    <mat-option value="no">-- {{ "My time zone is not" | translate }} --</mat-option>
                    <mat-optgroup *ngFor="let zone of filteredTimezonesGroups | async" [label]="getTimeZone(zone.value)"
                    [disabled]="zone.disabled">
                        <mat-option *ngFor="let name of zone.names" [value]="name">
                            {{name}}
                        </mat-option>
                    </mat-optgroup>
                </mat-select>
            </mat-form-field>
        
            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ 'Timezone offset' | translate }}</mat-label>
                <input [readonly]="form.get('timezone').value != 'no'" formControlName="timezone_offset" matInput placeholder="{{ 'Timezone offset' | translate }}">
            </mat-form-field>

            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ 'Update period (h)' | translate }}</mat-label>
                <input formControlName="update_every" matInput type="number" placeholder="{{ 'Update period (h)' | translate }}">
            </mat-form-field>

            <p>
                <mat-button-toggle-group appearance="legacy" class="is_active_w8" formControlName="is_active" aria-label="Active">
                    <mat-button-toggle [value]="1" style="color: blue;">{{ 'Active' | translate }}</mat-button-toggle>
                    <mat-button-toggle [value]="0" style="color: red;">{{ "Not active" | translate }}</mat-button-toggle>
                </mat-button-toggle-group>
            </p>

        </form>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <div class="f_w_p_btns">
        <button mat-button (click)="close()">
            {{ "Close" | translate }}
        </button>
        <button class="ml-1 d-f-btn" (click)="onSubmit()" cdkFocusInitial mat-raised-button [disabled]="isSubmit || !form.valid">
            <mat-spinner class="spin_16 mr-1" *ngIf="isSubmit"></mat-spinner>  
            <ng-container *ngIf="data.import; else elseTemplate">
                {{ "Edit" | translate }}
            </ng-container>
            <ng-template #elseTemplate>
                {{ "Add" | translate }}
            </ng-template>
        </button>
    </div>
</mat-dialog-actions>
