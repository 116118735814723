<div class="cases_page">
    <div class="container">
      <div class="filter_out" *ngIf="!is_mobile">  
        <form [formGroup]="filter" class="filter_in" (ngSubmit)="filterStatements(null, null, true)" >
          <div class="filter_form_in">
    
            <!-- <div class="d-f-c">
              <mat-form-field appearance="legacy" class="filter_form search">
                <mat-icon matPrefix>search</mat-icon>
                <input formControlName="q" matInput>
                <mat-icon (click)="$event.stopPropagation();" matSuffix>tune</mat-icon>
              </mat-form-field>
              <button type="submit" class="filter_btn search_filter_btn">{{ "Search" | translate }}</button>
            </div>

            <mat-form-field appearance="legacy" class="filter_form executor" *ngIf="!withoutFilter">
              <mat-label>{{ "Executor" | translate }}</mat-label>
              <mat-select [disableOptionCentering]="true" selectDir formControlName="employee_id"  (selectionChange)="selectChange($event)">
                <mat-option>
                  <ngx-mat-select-search 
                      placeholderLabel="{{ 'Search' | translate }}" 
                      noEntriesFoundLabel="{{ 'no matching member found' | translate }}"
                      [formControl]="execControl"></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let employee of employees$ | async" [value]="employee.id">
                  <app-user [class]="'user_div'" [employee]="employee"></app-user>
                </mat-option>
              </mat-select>
            </mat-form-field> -->

            <mat-form-field appearance="legacy" class="example-form-field filter_form date">
                <mat-label>{{ 'Month' | translate }}</mat-label>
                <input [min]="minDate" matInput [matDatepicker]="dp" formControlName="start_time_from">
                <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
                <mat-datepicker #dp
                                startView="multi-year"
                                (yearSelected)="chosenYearHandler($event)"
                                (monthSelected)="chosenMonthHandler($event, dp)"
                                panelClass="example-month-picker">
                </mat-datepicker>
            </mat-form-field>
          </div>
        </form>
        <div class="filter_values">
          <mat-chip-list class="chips_wrp without_ordered start_page_chips">
            
            <!-- <mat-chip (removed)="removeValue('q')" *ngIf="activatedRoute.snapshot.queryParamMap.get('q')">
              {{activatedRoute.snapshot.queryParamMap.get('q')}}
              <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
            </mat-chip> -->

        
            <!-- <mat-chip class="user_chip" *ngIf="filter.get('employee_id').value" (removed)="removeValue('employee_id', filter.get('employee_id').value)" >
                <span class="mr-1" style="font-weight: 500;">{{ "Executor" | translate }}</span>
                <app-user [class]="'user_div'" [employee]="getEmployee(filter.get('employee_id').value)"></app-user>
                <mat-icon class="remove_chip_btn" matChipRemove *ngIf="!withoutFilter">cancel</mat-icon>
            </mat-chip>
       -->

            <mat-chip (removed)="removeValue('start_time_from')" *ngIf="filter.get('start_time_from').value">
                {{ filter?.get('start_time_from').value | date:'MMM, y':"+0000":activeLang | yearPipe }}
                <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
            </mat-chip>

            <mat-icon class="remove_all_chips_btn" (click)="removeValue('all')" *ngIf="!withoutFilter && (filter.get('start_time_from').value || filter.get('start_time_to').value)">cancel</mat-icon>
          </mat-chip-list>
  
          <div class="stats_wrp">
            <div class="tasks_counters" (click)="log()">
              <!-- <div class="counter_block" *ngIf="company?.original_filesize">
                <div class="counter_top">{{ "Filesize" | translate }}</div>
                <div class="counter_bottom">{{company?.original_filesize | filesize}}</div>
              </div> -->
              <div class="counter_block" *ngIf="company?.file_stat_updated_at">
                <div class="counter_top">{{ "Updated at" | translate }}</div>
                <div class="counter_bottom">{{company?.file_stat_updated_at*1000 | date:'hh:mm a, MMM d, y':'':activeLang | yearPipe}}</div>
              </div>
              <div class="counter_block" *ngIf="pagination && pagination['totalCount']">
                <div class="counter_top">{{ "Usages" | translate }}</div>
                <div class="counter_bottom">{{pagination['totalCount']}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    
    
    <!-- <div class="lists_wrp" *ngIf="!is_mobile">
        <div class="lists_left">
          <div class="job_status_wrp">
            <button mat-button  (click)='changeActiveStatus(0, $event)' [ngClass]="{'active_status': activeStatus.includes(0)}">{{ "All" | translate }}</button>
            <button mat-button  (click)='changeActiveStatus(1, $event)' [ngClass]="{'active_status': activeStatus.includes(1)}">{{ "Waiting" | translate }}</button>
            <button mat-button  (click)='changeActiveStatus(2, $event)' [ngClass]="{'active_status': activeStatus.includes(2)}">{{ "In process" | translate }}</button>
            <button mat-button  (click)='changeActiveStatus(3, $event)' [ngClass]="{'active_status': activeStatus.includes(3)}">{{ "To approve" | translate }}</button>
            <button mat-button  (click)='changeActiveStatus(4, $event)' [ngClass]="{'active_status': activeStatus.includes(4)}">
                {{ "Approved" | translate }}
            </button>
            <button mat-button  (click)='changeActiveStatus(5, $event)' [ngClass]="{'active_status': activeStatus.includes(5)}">{{ "To correct" | translate }}</button>
            <button mat-button  (click)='changeActiveStatus(6, $event)' [ngClass]="{'active_status': activeStatus.includes(6)}">{{ "Sources needed" | translate }}</button>
            <button mat-button  (click)='changeActiveStatus(98, $event)' [ngClass]="{'active_status': activeStatus.includes(98)}">{{ "Ready" | translate }}</button>
            <button mat-button  (click)='changeActiveStatus(99, $event)' [ngClass]="{'active_status': activeStatus.includes(99)}">{{ "Canceled" | translate }}</button>
          </div>
        </div>
  
        <div class="d-f-c">
          <mat-button-toggle-group class="view_group" (change)="changeView($event)" [value]="filter.get('employee_id').value.includes(me?.id) ? 'executor' : 'client'">
            <mat-button-toggle value="client">{{ "As client" | translate }}</mat-button-toggle>
            <mat-button-toggle value="executor">{{ "As executor" | translate }}</mat-button-toggle>
          </mat-button-toggle-group>
          
          <button type="button" mat-icon-button [matMenuTriggerFor]="sortBtn" class="mobActiveFilter">
            <mat-icon>sort</mat-icon>
          </button>
        </div>

        <mat-menu #sortBtn="matMenu" class="sort_tablet" xPosition='before' overlayPanelClass='sortTAB'>
          <button mat-menu-item  (click)="sort('updated_desc')" [ngClass]="{'active_status': sortValue == 'updated_desc'}">
            {{  "Sort by updated" | translate }}
            <mat-icon style="margin-right: 0 !important;">arrow_drop_down</mat-icon>
          </button>
          <button mat-menu-item  (click)="sort('updated')" [ngClass]="{'active_status': sortValue == 'updated'}">
            {{ "Sort by updated" | translate }}
            <mat-icon style="margin-right: 0 !important;">arrow_drop_up</mat-icon>
          </button>
          <button mat-menu-item  (click)="sort('priority_desc')" [ngClass]="{'active_status': sortValue == 'priority_desc'}">
            {{  "Sort by priority" | translate }}
            <mat-icon style="margin-right: 0 !important;">arrow_drop_down</mat-icon>
          </button>
          <button mat-menu-item  (click)="sort('priority')" [ngClass]="{'active_status': sortValue == 'priority'}">
            {{ "Sort by priority" | translate }}
            <mat-icon style="margin-right: 0 !important;">arrow_drop_up</mat-icon>
          </button>
          <button mat-menu-item  (click)="sort('id_desc')" [ngClass]="{'active_status': sortValue == 'id_desc'}">
            {{ "Sort by id" | translate }}
            <mat-icon style="margin-right: 0 !important;">arrow_drop_down</mat-icon>
          </button>
          <button mat-menu-item  (click)="sort('id')" [ngClass]="{'active_status': sortValue == 'id'}">
            {{ "Sort by id" | translate }}
            <mat-icon style="margin-right: 0 !important;">arrow_drop_up</mat-icon>
          </button>
        </mat-menu>
      </div>     -->
    </div>
    <div class="container">
        <div class="custom_row head_row">
            <div class="d-f-c">
                <!-- <div class="check_wrp" *ngIf="!!user?.is_root">
                    <mat-checkbox class="select_all_checkbox"
                      [formControl]="showAddOptions">
                      <span class="check_sm_label">{{"Extra info" | translate }}</span>
                    </mat-checkbox>
                </div> -->
                <span class="ml-1">{{ "period_id" | translate }}</span>
            </div>
            <!-- <div class="period">
                {{ "Start" | translate }}
            </div>
            <div class="period">
                {{ "End" | translate }}
            </div> -->
            <div>{{ "Used" | translate }}</div>
            <div>{{ "Used Total" | translate }}</div>
            <!-- <ng-container *ngIf="showAddOptions.value">
                <div>{{ "filesize_h1_used " | translate }}</div>
                <div>{{ "filesize_h2_used " | translate }}</div>
                <div>{{ "filesize_h3_used " | translate }}</div>
            </ng-container> -->
        </div>
    </div>
    <div class="statements_wrp" #usagesContainer>
        <div class="statements_out" *ngFor="let usage of usages">
            <div class="container statement custom_row" [ngClass]="{'out': useOut}">
                <div class="id">
                    {{usage.id}}
                </div>
                <div>
                  <span #tooltip="matTooltip"
                  matTooltip='{{usage.start_time*1000 | date:"hh:mm a":"+0000":activeLang}} - {{usage.end_time*1000 | date:"hh:mm a":"+0000":activeLang}}'
                  [matTooltipPosition]="'above'">
                    {{usage.period_id}}
                  </span>
                </div>
                <!-- <div class="period">
                    {{usage.start_time*1000 | date:'dd, hh:mm a':"+0000":activeLang}}
                </div>
                <div class="period">
                    {{usage.end_time*1000 | date:'dd, hh:mm a':"+0000":activeLang}}
                </div> -->
                <div>
                    {{usage.aiu_used}}
                </div>
                <div>
                    {{usage.aiu_used_total}}
                </div>
            </div>
        </div>
    </div>
</div>