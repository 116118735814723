<mat-dialog-title class="dialog_title">
    <span>
        {{ "New card" | translate }}
    </span>
    <!-- <button mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
    </button> -->
</mat-dialog-title>
<mat-dialog-content class="mat-typography" dirEnter (dirSubmit)="isSubmit ? null : selectWorkSpace()">
    <div class="container form_wrapper">
        <div class="white_block">
            <div class="white_block_title">
                {{ "SELECT WORKSPACE THE CARD WILL BELONG TO" | translate }}
            </div>
            <mat-tab-group class="create_new_task_tabs" [selectedIndex]="selectedIndex" (selectedIndexChange)="changeTab($event)">
                <mat-tab>
                    <ng-template mat-tab-label>
                        {{ "Your workspaces" | translate }}
                    </ng-template>
                    <form [formGroup]="form" class="form_with_valids">
                        <div class="users_select" [ngClass]="{'req_value': !form.get('company_id').valid}">
                            <ng-container *ngIf="!form.get('company_id').valid && submited">
                                <div class="req_field"></div>
                                <div class="req_label">
                                    <div class="angle"></div>
                                    <span>
                                        {{ "You need to choose Workspace" | translate }}
                                    </span>
                                </div>
                            </ng-container>
                            <!-- <label>{{ "Choose a Workspace" | translate }}: </label> -->
                            <mat-chip-list class="new_task_chips" [multiple]="false" selectable formControlName="company_id">
                                <ng-container *ngFor="let comp of data.companyes; let i = index">
                                    <mat-chip class="chip_user" (click)="toggleChip(comp)" [value]="comp.id">
                                        <app-user [class]="'user_div'" [companyEmployee]="comp" [imgRoute]="data?.imgRoute" [host]="data?.host" [company]="comp"></app-user>
                                    </mat-chip>
                                    <mat-chip *ngIf="i == data.companyes.length - 1 && i > 6" class="chip_user more" (click)="companyesContainer.open()">
                                        {{ "more" | translate }}
                                    </mat-chip>
                                </ng-container>
                            </mat-chip-list>
                        </div>
            
                        <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                            <mat-select #companyesContainer formControlName="company_id" (selectionChange)="toggleChip($event)">
                                <mat-option>
                                    <ngx-mat-select-search 
                                    placeholderLabel="{{ 'Search' | translate }}" 
                                    noEntriesFoundLabel="{{ 'no matching Workspaces found' | translate }}"
                                    [formControl]="compMoreControl"></ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let comp of companyes$ | async" [value]="comp.id">
                                    <app-user [class]="'user_div'" [companyEmployee]="comp" [imgRoute]="data?.imgRoute" [host]="data?.host" [company]="comp"></app-user>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </form>
                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        {{ "External Workspaces" | translate }}
                    </ng-template>
                    <form [formGroup]="exForm" class="form_with_valids">
                        <div class="users_select" [ngClass]="{'req_value': !exForm.get('company_id').valid}">
                            <ng-container *ngIf="!exForm.get('company_id').valid && exSubmited">
                                <div class="req_field"></div>
                                <div class="req_label">
                                    <div class="angle"></div>
                                    <span>
                                        {{ "Choose a Workspace" | translate }}
                                    </span>
                                </div>
                            </ng-container>
                            <label>{{ "To select external workspace, please enter one of yours." | translate }}: </label>
                            <mat-chip-list class="new_task_chips" [multiple]="false" selectable formControlName="company_id">
                                <ng-container *ngFor="let comp of myCompanyes; let i = index">
                                    <mat-chip class="chip_user" (click)="toggleExChip(comp)" [value]="comp.id">
                                        <app-user [class]="'user_div'" [companyEmployee]="comp" [imgRoute]="data?.imgRoute" [host]="data?.host" [company]="comp"></app-user>
                                    </mat-chip>
                                    <mat-chip *ngIf="i == myCompanyes.length - 1 && i > 6" class="chip_user more" (click)="exCompanyesContainer.open()">
                                        {{ "more" | translate }}
                                    </mat-chip>
                                </ng-container>
                            </mat-chip-list>
                        </div>
            
                        <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                            <mat-select #exCompanyesContainer formControlName="company_id" (selectionChange)="toggleExChip($event)">
                                <mat-option>
                                    <ngx-mat-select-search 
                                    placeholderLabel="{{ 'Search' | translate }}" 
                                    noEntriesFoundLabel="{{ 'no matching Workspaces found' | translate }}"
                                    [formControl]="myCompMoreControl"></ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let comp of myCompanyes$ | async" [value]="comp.id">
                                    <app-user [class]="'user_div'" [companyEmployee]="comp" [imgRoute]="data?.imgRoute" [host]="data?.host" [company]="comp"></app-user>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
    
                        <div *ngIf="!!noPartners && !!exForm.get('company_id').value" style="padding: 15px 0; color: red;">
                            {{ "This workspace has no partners" | translate }}
                        </div>
    
                        <div *ngIf="partners && partners.length" class="users_select" [ngClass]="{'req_value': !exForm.get('partner_company_id').valid}">
                            <ng-container *ngIf="!exForm.get('partner_company_id').valid && exSubmited">
                                <div class="req_field"></div>
                                <div class="req_label">
                                    <div class="angle"></div>
                                    <span>
                                        {{ "Choose a Workspace" | translate }}
                                    </span>
                                </div>
                            </ng-container>
                            <label>{{ "Select external workspace" | translate }}: </label>
                            <mat-chip-list class="new_task_chips" [multiple]="false" selectable formControlName="partner_company_id">
                                <ng-container *ngFor="let comp of partners; let i = index">
                                    <mat-chip class="chip_user" (click)="togglePartnerChip(comp)" [value]="comp?.partner_company_id">
                                        <app-user [class]="'user_div'" [companyEmployee]="comp?.partnerCompany" [imgRoute]="data?.imgRoute" [host]="data?.host" [company]="comp"></app-user>
                                    </mat-chip>
                                    <mat-chip *ngIf="i == partners.length - 1 && i > 6" class="chip_user more" (click)="externalCompanyesSelect.open()">
                                        {{ "more" | translate }}
                                    </mat-chip>
                                </ng-container>
                            </mat-chip-list>
                        </div>
            
                        <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                            <mat-select #externalCompanyesSelect formControlName="partner_company_id" (selectionChange)="togglePartnerChip($event)">
                                <mat-option>
                                    <ngx-mat-select-search 
                                    placeholderLabel="{{ 'Search' | translate }}" 
                                    noEntriesFoundLabel="{{ 'no matching external workspaces found' | translate }}"
                                    [formControl]="partnerCompMoreControl"></ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let comp of partners$ | async" [value]="comp?.partner_company_id">
                                    <app-user [class]="'user_div'" [companyEmployee]="comp?.partnerCompany" [imgRoute]="data?.imgRoute" [host]="data?.host" [company]="comp"></app-user>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </form>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <div class="f_w_p_btns">
        <button mat-button (click)="close()">
            {{ "Close" | translate }}
        </button>
        <ng-container *ngIf="selectedIndex.value == 0; else elseTemplatePartnersTab">
            <button class="ml-1 d-f-btn" (click)="selectWorkSpace()" mat-raised-button cdkFocusInitial color="primary" [disabled]="!!isSubmit">
                <mat-spinner class="spin_16 mr-1" *ngIf="isSubmit"></mat-spinner>  
                {{ "Next" | translate }}
            </button>
        </ng-container>
        <ng-template #elseTemplatePartnersTab>
            <button class="ml-1 d-f-btn" (click)="selectPartnerWorkSpace()" mat-raised-button cdkFocusInitial color="primary" [disabled]="!!exIsSubmit">
                <mat-spinner class="spin_16 mr-1" *ngIf="exIsSubmit"></mat-spinner>  
                {{ "Next" | translate }}
            </button>
        </ng-template>
        

    </div>
</mat-dialog-actions>
