import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { fromEvent, merge, of } from 'rxjs';
import { filter, finalize, map, pairwise, switchMap, takeUntil, tap } from 'rxjs/operators';
import { BaseClass } from '../../models/base-class';
import { FileService } from '../../services/rest/file.service';

@Component({
  selector: 'app-drawer',
  templateUrl: './drawer.component.html',
  styleUrls: ['./drawer.component.scss']
})
export class DrawerComponent extends BaseClass implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  public drawingData: any = [];
  @Input() color: any = "#FF0000";
  @Input() strokeWidth: number = 3;
  @Input() only_show: any;
  @Input() selectedChat: any;
  public jsonData: any;
  @ViewChild('svg') svg: ElementRef;

  constructor(
    private fileService: FileService,
  ) { super() }
  
  ngOnInit(): void {
    
  }

  changeColor() {
    console.log("changeColor")
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    console.log("ngOnChanges", changes);
    if (!!changes.selectedChat && (!changes.selectedChat.previousValue && changes.selectedChat.currentValue) || (changes.selectedChat.previousValue && changes.selectedChat.currentValue && changes.selectedChat.currentValue.id != changes.selectedChat.previousValue.id)) {
      if (changes.selectedChat.currentValue.data && changes.selectedChat.currentValue.data.file_timestamps && changes.selectedChat.currentValue.data.file_timestamps.length > 1 && changes.selectedChat.currentValue.data.file_timestamps[1].type == 'drawer' && changes.selectedChat.currentValue.data.file_timestamps[1].draws && changes.selectedChat.currentValue.data.file_timestamps[1].draws.length) {
        this.jsonData = {
          viewBox: changes.selectedChat.currentValue.data.file_timestamps[1].viewBox,
          paths: changes.selectedChat.currentValue.data.file_timestamps[1].draws
        }
        console.log("this.jsonData", this.jsonData)
        this.loadSvg();
      }
    }
  }

  ngAfterViewInit() {
    const svgElement = this.svg.nativeElement;
    const svgNS = 'http://www.w3.org/2000/svg';
    console.log("svgElement", svgElement)
    svgElement.setAttribute('viewBox', `0 0 ${svgElement.clientWidth} ${svgElement.clientHeight}`);

    // Определяем события мыши для рисования
    const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0;

    const startEvent = isTouchDevice ? 'touchstart' : 'mousedown';
    const moveEvent = isTouchDevice ? 'touchmove' : 'mousemove';
    const endEvent = isTouchDevice ? 'touchend' : 'mouseup';

    // alert(startEvent)
    
    const mouseDown$ = fromEvent(svgElement, startEvent);
    const mouseMove$ = fromEvent(document, moveEvent);
    const mouseUp$ = fromEvent(document, endEvent);

    // Массив для хранения рисунка
    let drawingPath = null;
    this.drawingData = '';

    fromEvent<KeyboardEvent>(document, 'keydown').pipe(
      filter(event => (event.ctrlKey || event.metaKey) && event.keyCode === 90 && !this.only_show)
    ).subscribe(resp => {
      console.log('this.only_show', this.only_show);
      console.log('svgElement', svgElement);
      const pathElements:any = svgElement.querySelectorAll('path');
      console.log("drawingData", this.drawingData)
      console.log("pathElements", pathElements)
      console.log("Before drawingData", this.drawingData);
      if (pathElements && pathElements.length > 0) {
        const lastPath = pathElements[pathElements.length - 1];
        const lastPathData = lastPath.getAttribute('d');

        console.log("lastPathData", lastPathData)
        console.log("lastPathData.length", lastPathData.length)

        // Optionally, update this.drawingData if needed

        // Update this.drawingData by removing the last path data
        this.drawingData = this.drawingData.slice(0, -lastPathData.length);

        // Optionally, log the updated this.drawingData
        console.log("Updated drawingData", this.drawingData);
        svgElement.removeChild(lastPath);
      }
    });

    // Начнем рисование при нажатии левой кнопки мыши
    const drawing$ = mouseDown$.pipe(
      switchMap((startEvent: MouseEvent | TouchEvent) => {
        const start = this.isTouchEvent(startEvent)
          ? (startEvent as TouchEvent).touches[0]
          : (startEvent as MouseEvent);

        if (this.isTouchEvent(startEvent)) {
          startEvent.preventDefault();
        }
    
        const startX = start.clientX - svgElement.getBoundingClientRect().left;
        const startY = start.clientY - svgElement.getBoundingClientRect().top;

        // Создаем новый элемент <path> для рисования
        drawingPath = document.createElementNS(svgNS, 'path');
        drawingPath.setAttribute('stroke', this.color);
        drawingPath.setAttribute('stroke-width', this.strokeWidth.toString());
        drawingPath.setAttribute('fill', 'none');
        drawingPath.setAttribute('d', `M ${startX} ${startY}`);
        drawingPath.setAttribute('stroke-linejoin', 'round'); // Для lineJoin
        drawingPath.setAttribute('stroke-linecap', 'round'); // Для lineCap 

        svgElement.appendChild(drawingPath);

        // alert(startX);
        // alert(startY);
        // Очистить массив рисунка при новом рисовании
        this.drawingData = `M ${startX} ${startY}`;
        // alert(this.drawingData);
        
        // return mouseMove$.pipe(
        //   pairwise(),
        //   takeUntil(mouseUp$)
        // );

        return merge(
          mouseMove$.pipe(filter(() => !this.isTouchEvent(startEvent))),
          fromEvent<TouchEvent>(document, moveEvent).pipe(
            filter(() => this.isTouchEvent(startEvent)),
            switchMap((touchMoveEvent: TouchEvent) => {
              touchMoveEvent.preventDefault(); // Предотвращение стандартного поведения браузера (panning)
              return of(touchMoveEvent);
            })
          )
        ).pipe(
          pairwise(),
          takeUntil(merge(
            mouseUp$.pipe(filter(() => !this.isTouchEvent(startEvent))),
            fromEvent<TouchEvent>(document, endEvent).pipe(
              filter(() => this.isTouchEvent(startEvent)),
              switchMap((touchEndEvent: TouchEvent) => {
                touchEndEvent.preventDefault(); // Предотвращение стандартного поведения браузера (panning)
                return of(touchEndEvent);
              })
            )
          ))
        );
      })
    );

    this.attachSubscriptions(
      drawing$.subscribe((res) => {
        const [start, end]:any = res;

        // alert('drawing')
        // alert(res)
  
        // const x1 = start.clientX - svgElement.getBoundingClientRect().left;
        // const y1 = start.clientY - svgElement.getBoundingClientRect().top;
        // const x2 = end.clientX - svgElement.getBoundingClientRect().left;
        // const y2 = end.clientY - svgElement.getBoundingClientRect().top;

        const x1 = (this.isTouchEvent(start) ? (start as TouchEvent).touches[0].clientX : start.clientX) - svgElement.getBoundingClientRect().left;
        const y1 = (this.isTouchEvent(start) ? (start as TouchEvent).touches[0].clientY : start.clientY) - svgElement.getBoundingClientRect().top;
        const x2 = (this.isTouchEvent(end) ? (end as TouchEvent).touches[0].clientX : end.clientX) - svgElement.getBoundingClientRect().left;
        const y2 = (this.isTouchEvent(end) ? (end as TouchEvent).touches[0].clientY : end.clientY) - svgElement.getBoundingClientRect().top;
    
  
  
        // Добавляем линию к пути
        drawingPath.setAttribute('d', `${this.drawingData} L ${x2} ${y2}`);

        this.drawingData += ` L ${x2} ${y2}`;
        // alert(this.drawingData);
      })
    )

    this.attachSubscriptions(
      this.fileService.getDrawData().subscribe(resp => {
        console.log("getDrawData", resp)
        if (!!resp) {
          this.saveSvg();
          console.log("sendShapesToSave", this.jsonData);
          if (this.jsonData.paths.length) {
            this.fileService.drawDataValues$.next(
              {
                type: 'drawer',
                viewBox: this.jsonData.viewBox,
                draws: this.jsonData.paths, 
              }
            );
          } else {
            this.fileService.drawDataValues$.next(false);
          }

        }
      })
    )

    this.attachSubscriptions(
      this.fileService.getDrawClear().subscribe(resp => {
        console.log("getDrawClear", resp)
        if (!!resp) {
          this.clearSvg();
        }
      })
    )
  }

  isTouchEvent(event: MouseEvent | TouchEvent): event is TouchEvent {
    return 'touches' in event;
  }

  clearSvg() {
    const svgElement = this.svg.nativeElement;
    while (svgElement.firstChild) {
      svgElement.removeChild(svgElement.firstChild);
    }
  }

  saveSvg() {
    const svgElement = this.svg.nativeElement;
    const pathElements:any = svgElement.querySelectorAll('path');
    const pathsData = [];
  
    for (const path of pathElements) {
      const pathData = {
        d: path.getAttribute('d'), // атрибут 'd' содержит описание пути
        stroke: path.getAttribute('stroke'),
        'stroke-width': path.getAttribute('stroke-width'),
        fill: path.getAttribute('fill'),
      };
      pathsData.push(pathData);
    }

    this.jsonData = {
      paths: pathsData,
      viewBox: `0 0 ${svgElement.clientWidth} ${svgElement.clientHeight}`
    };
    console.log("jsonData", this.jsonData)
  }

  loadSvg() {
    setTimeout(() => {
      this.clearSvg();
      console.log("this.jsonData", this)
      console.log("this.jsonData", this.jsonData)
      const parsedData = this.jsonData.paths;
      const viewBox = this.jsonData.viewBox;
      const svgElement = this.svg.nativeElement;
      svgElement.setAttribute('viewBox', viewBox);
    
      for (const pathData of parsedData) {
        const pathElement = document.createElementNS('http://www.w3.org/2000/svg', 'path');
        pathElement.setAttribute('d', pathData.d);
        pathElement.setAttribute('stroke', pathData.stroke);
        pathElement.setAttribute('stroke-width', pathData['stroke-width']);
        pathElement.setAttribute('fill', pathData.fill);
        pathElement.setAttribute('stroke-linejoin', 'round'); // Для lineJoin
        pathElement.setAttribute('stroke-linecap', 'round'); // Для lineCap 
    
        svgElement.appendChild(pathElement);
      }
    }, 0)
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}