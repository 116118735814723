import { NgxMatDateAdapter } from '@angular-material-components/datetime-picker';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { Component, Inject, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { Observable, ReplaySubject, Subscription, concat, forkJoin, from, fromEvent, of } from 'rxjs';
import { catchError, concatMap, debounceTime, filter, last, map, startWith, switchMap, take, tap } from 'rxjs/operators';
import { MY_NEW_FORMATS } from 'src/app/components/workspace-pages/cases/dialogs/open-task/dialogs/url-analytics/url-analytics.component';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { AiService } from 'src/app/shared/services/rest/ai.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { FileService } from 'src/app/shared/services/rest/file.service';
import { ParametersService } from 'src/app/shared/services/rest/parameters.service';
import { ScenariosService } from 'src/app/shared/services/rest/scenarios.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { VideoViewerComponent } from '../../../video-viewer/video-viewer.component';
import { ChatService } from 'src/app/shared/services/rest/chat.service';
import { casesModel } from 'src/app/shared/functions/casesModel';
import { MobFmViewComponent } from 'src/app/shared/global_components/mob-file-manager/dialogs/mob-fm-view/mob-fm-view.component';
import { ShowAnswerComponent } from 'src/app/shared/global_components/add-edit-ai-scheme/show-answer/show-answer.component';
import { LoadingService } from 'src/app/shared/services/rest/loading.service';
import { ExtraAiInfoComponent } from '../extra-ai-info/extra-ai-info.component';

@Component({
  selector: 'app-card-project',
  templateUrl: './card-project.component.html',
  styleUrls: ['./card-project.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_NEW_FORMATS},
  ]
})
export class CardProjectComponent extends BaseClass implements OnInit, OnDestroy {
  public cards: any = [];

  public publications: any = [];
  public forAllContentTypes: any;
  public ai_frames: FormControl = new FormControl(0);
  public ai_model: FormControl = new FormControl();
  public aiModelControl: FormControl = new FormControl();
  public models$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public models;
  
  public groups$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public groupsMoreControl: FormControl = new FormControl();
  public groups: any;

  
  public createFramesStart: boolean = false;
  public is_mobile: boolean = false;
  public projectName: string = this.data.project ? this.data.project.name : `Project based on ${this.data.allFiles.length} files`;

  public isShow: boolean = false;
  public isSubmit: boolean = false;
  
  public is_dragging: boolean = false;

  public filteredOptions: any = [];
  public filteredOptions$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public profilesTab: number = 0

  public taskTemplates: any;
  public taskTemplates$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public taskTemplatesControl: FormControl = new FormControl();

  public profiles: any[] = [];
  public profilesMoreControl: FormControl = new FormControl();
  public profiles$: ReplaySubject<any> = new ReplaySubject<any>(1);
  

  public appPublicationData: any = {
    channel_id: '',
    content_type_id: '',
    content_status_id: 6,
    content_published_at: '',
    is_content_plan: 1,
    content_plan_start_at: 0,
    content_plan_min_interval: 1,
    content_name: '',
    content_description: '',
    task_channel_files: [{
      original_file_id: 0,
      is_content: 1,
      is_preview: 0,
      is_thumbnail: 0,
      is_original: 0,
      is_to_upload: 1
    }]
  }

  public form: FormGroup = this.fb.group({
    card_name: '',
    post_name: '',
    template_id: '',
    made_for_kids: 0
  })
  public aiSysForm: FormGroup;
  public aiForm: FormGroup;

  public aiResults: any;
  public aiMistake: any;
  public showSys: boolean = false;
  public aiCount: number = 0;
  
  @ViewChild('contextMenu') contextMenu: TemplateRef<any>;
  overlayRef: OverlayRef | null;
  public locSub: Subscription;
  public backContextSub: Subscription;
  public openFileSub: Subscription;
  public isGettingResults: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CardProjectComponent>,
    private fileService: FileService,
    private parametersService: ParametersService,
    private ls: LoadingService,
    private dialog: MatDialog,
    private sanitizer: DomSanitizer,
    private chatService: ChatService,
    private fb: FormBuilder,
    public scenariosService: ScenariosService,
    public aiService: AiService,
    public companyService: CompanyService,
    private sm: StorageManagerService,
    public taskService: TaskService,
    public layoutService: LayoutService,
    public overlay: Overlay,
    public viewContainerRef: ViewContainerRef,
    private _ngx_adapter: NgxMatDateAdapter<any>,
    private _adapter: DateAdapter<any>,
  ) { super() }

  ngOnInit(): void {
    console.log("CardProjectComponent", this.data);
    this.dialogRef.addPanelClass('video_editor_dialog');
    this.checkIsMobile();

    this.appPublicationData.content_plan_content_schedules_ids = [];
    this.appPublicationData.content_plan_content_schedule_items_ids = [];

    this.aiForm = this.fb.group({
      on_the_video: '',
      your_idea_of_title: '',
      remembers: [[]],
      keywords: [[]],
      also_remember: ''
    })

    this.getLocations();
    this.getAIModels();

    // this.models$.next(this.models.slice());

    this.attachSubscriptions(
      this.aiModelControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchAiModels(resp))
    )

    this.aiSysForm = this.fb.group({
      str: this.getResultsReqObject().messages[0].content || ''
    })

    this.attachSubscriptions(
      this.aiForm.valueChanges.subscribe(res => {
        this.aiSysForm.patchValue({
          str: this.getResultsReqObject().messages[0].content || ""
        })
      })
    )

    if (this.data.project) {
      let custom_data = JSON.parse(this.data.project.custom_data);
      // let custom_data = JSON.parse(this.data.project.custom_data, this.getCircularReplacer())
      console.log("custom_data", custom_data)
      this.publications = custom_data.publications;
      this.cards = custom_data.cards;
      this.cards.forEach(card => {
        card.files.forEach(file => {
          if (!file.cstID) {
            file.cstID = this.generateRandomId();
          }
          if (!file.hasOwnProperty('post_location')) {
            file.post_location = '';
          }
        })
      })
      if (custom_data.form) {
        this.form.patchValue(custom_data.form)
      }
      if (custom_data.ai_frames) {
        this.ai_frames.patchValue(custom_data.ai_frames)
      }
      if (custom_data.ai_model) {
        this.ai_model.patchValue(custom_data.ai_model)
      }
    } else {
      this.data.allFiles.forEach((element, index) => {
        element.post_name = 'Post ' + (index + 1);
        element.post_description = 'Post desc' + (index + 1);
        element.post_frame_ms = 0;
        element.post_location = '';

        element.publications = []
        let el = JSON.parse(JSON.stringify(element), this.getCircularReplacer());
        delete el.createdEmployee;
        delete el.taskChannelFiles;
        delete el.fileOriginalData;
        delete el.task;

        el.cstID = this.generateRandomId();
        this.cards.push({
          files: [el],
          card_name: 'Card ' + (index + 1)
        })
      });
    }

    this.attachSubscriptions(
      this.form.get('card_name').valueChanges.subscribe(res => {
        this.cards.forEach(x => {
          x.card_name = res
        })
      })
    )
    this.attachSubscriptions(
      this.form.get('post_name').valueChanges.subscribe(res => {
        this.cards.forEach(x => {
          x.files.forEach(u => {
            u.post_name = res
          })
        })
      })
    )

    this.getConnectedThums();

    this.getTaskTemplates();
    this.getProfiles();
    this.getGroups();
    this.getForAllContentTypes();

    this.attachSubscriptions(
      this.taskTemplatesControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchBy("name", 'taskTemplates', resp))
    )

    this.attachSubscriptions(
      this.groupsMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchBy("name", 'groups', resp))
    )

    this.attachSubscriptions(
      this.profilesMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchBy("name", 'profiles', resp))
    )
  }

  getAIModels() {
    this.attachSubscriptions(
      this.aiService.getAIModels().subscribe(resp => {
        this.models = resp;
        this.models$.next(resp);

        if (this.models.length) {
          let model_id = this.models[0].id;
          if (this.models.find(k => k.id == 1)) {
            model_id = this.models.find(k => k.id == 1).id
          }
          this.ai_model.patchValue(model_id)
        }
      })
    )
  }

  getConnectedThums() {
    let lenSelection = this.cards.length;
    let count = 0;
    this.attachSubscriptions(
      from(this.cards).pipe(
        concatMap((task:any) => {
          return forkJoin(task.files.map(file => this.fileService.getFilePartition("1", this.data.company ? this.data.company.id : this.data.company_id, {file_id: file.original_file_id}).pipe(
            tap(consists => {
              let elements = consists.filter(k => k.consistOfFile && k.consistOfFile.filename.indexOf(`Thumbnail-`) != -1)

              file.consists = [];
              elements.forEach(el => {
                let thumb = el.consistOfFile
                file.consists.push({
                  id: this.generateRandomId(),
                  blob: null,
                  blobUrl: null,
                  blobSrc: null,
                  isLoad: true,
                  time: this.extractTimeInSeconds(thumb.filename),
                  reportsFile: thumb,
                  uploadedFile: thumb
                })
              });

              file.consists.sort((a, b) => a.time - b.time);
            })
          ))).pipe(
            tap(addedNewOut => {
              this.ls.requests$.next({
                value: Math.round((100 / lenSelection) * (count+1)),
                target: `Getting Thumbnails for ${lenSelection} cards`,
              })
              count++;
            })
          )
        })
      ).subscribe({
        next: (next) => {
          console.log("next onSubmit", next);
        },
        complete: () => {
          console.log("complete onSubmit");
          // this.dialogRef.close({event: "Add"});
        },
        error: (error) => {
          console.log("error onSubmit", error)
        }
      })
    )
  }

  acitveFrame(file, frame) {
    file.thumbFile = frame;
    (document.getElementById(`video_${file.cstID}`) as HTMLVideoElement).currentTime = +frame.time;
  }

  searchLocations(e) {
    if (!this.filteredOptions) {
      return;
    }


    if (!e.target.value) {
      this.filteredOptions$.next(this.filteredOptions.slice());
      return;
    }

    
    let value = e.target.value.toLowerCase()

    // else {
    //   e.target.value = e.target.value.toLowerCase();
    // }

    this.filteredOptions$.next(
      this.filteredOptions.filter(b => b.name.toLowerCase().indexOf(value) > -1)
    );
  }

  onFocusOutLocation(e) {
    console.log("onFocusOutLocation", e);
    this.filteredOptions$.next(this.filteredOptions.slice());
  }

  onFocusLocation(e) {
    console.log("onFocusLocation", e);
    this.searchLocations(e)
  }

  changeLocation(e) {
    console.log("changeLocation", e);
    this.searchLocations(e)
  }

  getLocations(q?) {
    if (this.locSub) {
      this.locSub.unsubscribe();
    }
    this.attachSubscriptions(
      this.taskService.getFacebookPlacesDyn('1', '1', q).pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }
  
          return forkJoin(arr.map(x => this.taskService.getFacebookPlacesDyn(x, '200', q).pipe(map(u => u.body)))).pipe(
            last(),
            tap(values => {
              let conValues = [].concat(...values)
              this.filteredOptions = conValues;
              this.filteredOptions$.next(this.filteredOptions.slice())
            }),
          )
        }),
      ).subscribe(resp => {
        console.log("getLocations resp " + q + ' :', resp);
      })
    )
  }

  setValue(file, key, val) {
    file[key] = val;
  }

  understandFileType(val) {
    if (!val) {
      return ""
    }
    
    if (val == 'application/pdf') {
      return 'pdf'
    } else if (val.indexOf("/") != -1) {
      return val.split('/')[0]
    } else {
      return ""
    }
  }
  
  generateRandomId(): string {
    const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    const length = 10;
    let randomId = '';
  
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      randomId += characters.charAt(randomIndex);
    }
  
    return randomId;
  }
  
  onSearchAiModels(resp) {
    if (!this.models) {
      return;
    }

    if (!resp) {
      this.models$.next(this.models.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }

    // filter the banks
    this.models$.next(
      this.models.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  checkIsMobile() {
    if (window.innerWidth <= 769) {
      this.is_mobile = true;
    } else {
      this.is_mobile = false;
    }
    this.onResize();
  }

  onResize() {
    this.attachSubscriptions(
      fromEvent(window, "resize").pipe(
        map(() => window.innerWidth)
      ).subscribe((wWidth) => {
          if (wWidth <= 769) {
            this.is_mobile = true;
          } else {
            this.is_mobile = false;
          }
        }
      )
    )
  }

  toggleMadeForKids() {
    this.form.patchValue({
      made_for_kids: !!this.form.value.made_for_kids ? 0 : 1
    })
  }

  
  isYouTube(number) {
    // Преобразуем число в строку для удобства работы с символами
    const numStr = number.toString();

    // Проверяем, начинается ли строка с символа '3'
    return numStr.charAt(0) === '1';
  }

  isTikTok(number) {
    // Преобразуем число в строку для удобства работы с символами
    const numStr = number.toString();

    // Проверяем, начинается ли строка с символа '3'
    return numStr.charAt(0) === '3';
  }
  isFB(number) {
    // Преобразуем число в строку для удобства работы с символами
    const numStr = number.toString();

    // Проверяем, начинается ли строка с символа '3'
    return numStr.charAt(0) === '4';
  }

  getChannelById(id) {
    if (!this.profiles || this.profiles.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.profiles.find(el => el.id == id)
  }

  titleAI(e, data, cut?) {
    this.resetRes()
    if (cut) {
      this.aiCount = 0
      data.isGettingResults = true;
      this.getCutResults(e, this.aiForm, data)
    } else {
      this.openContext(e, data, true, cut)
    }
  }

  getResults(form, contextdata) {
    this.aiCount++

    let x:any = {
      company_id: this.data.company.id,
      partner_company_id: this.data.company.id != this.data.target_company_id ? this.data.target_company_id : 0,
      messages: [],
      model: this.ai_model.value
    }

    let msg = "";
    Object.keys(form.value).forEach(key => {
      if (!!form.value[key]) {
        if (['keywords', 'remembers'].includes(key) && form.value[key].length == 0) {
          return
        }

        if (key == 'keywords') {
          msg += "Use keywords "
        }

        if (['keywords', 'remembers'].includes(key)) {
          msg+= `${form.value[key].join(', ')}. `
        } else {
          msg+= `${form.value[key]}. `
        }

      }
    })

    let content = `${msg}Write ${this.cards.length} options in html ul.`
    if (content !== this.aiSysForm.value.str) {
      content = this.aiSysForm.value.str
    }

    x.messages.push({
      role: 'user',
      content: content
    })

    this.isGettingResults = true;
    this.attachSubscriptions(
      this.aiService.sendAIMsg(x).pipe(
        map(u => {
          if (u.indexOf("<ul>") != -1) {
            const parser = new DOMParser();
            const doc = parser.parseFromString(u, 'text/html');
            const ulElement = doc.querySelector('ul');

            if (ulElement) {
              let liElements = ulElement.querySelectorAll('li');
              let liArray = Array.from(liElements).map(x => (String(x.textContent) as any).replaceAll("\n", "").trim())
              return {parsed: !!liArray && liArray.length ? liArray : false, before: u};
            } else {
              return {parsed: false, before: u}
            }
          } else {
            return {parsed: false, before: u}
          }
        })
      ).subscribe(resp => {
        console.log("getResults", resp)
        if (resp.parsed) {
          this.aiCount = 0;
          this.isGettingResults = false;
          this.aiResults = resp.parsed
          console.log("TESTS", !contextdata.contextData || !contextdata.contextData.is_cut)
          console.log("TESTS", this.aiResults)
          if (!contextdata.contextData || !contextdata.contextData.is_cut) {
            this.setTitlesForParts(this.cards)
          }
        } else {
          if (this.aiCount <= 3) {
            this.getResults(form, contextdata)
          } else {
            this.aiMistake = resp.before
            this.aiCount = 0;
            this.isGettingResults = false;
            this.layoutService.showSnackBar({name: ''}, marker("Incorrect data received, please try again"), SnackBarItem)
          }
        }
      }, error => {
        if (this.aiCount <= 3) {
          this.getResults(form, contextdata)
        } else {
          this.aiMistake = error
          this.aiCount = 0;
          this.isGettingResults = false;
          this.layoutService.showSnackBar({name: ''}, marker("Incorrect data received, please try again"), SnackBarItem)
        }
      })
    )
    // console.log("getResults form", form.value);
    // console.log("getResults x", x);
  }

  getCutResults(e, form, contextdata) {
    this.aiCount++
    let x:any = {
      company_id: this.data.company.id,
      partner_company_id: this.data.company.id != this.data.target_company_id ? this.data.target_company_id : 0,
      messages: [],
      model: this.ai_model.value
    }
    
    let msg = "";
    Object.keys(form.value).forEach(key => {
      if (!!form.value[key]) {
        if (['keywords', 'remembers'].includes(key) && form.value[key].length == 0) {
          return
        }
        
        if (key == 'keywords') {
          msg += "Use keywords "
        } 

        if (['keywords', 'remembers'].includes(key)) {
          msg+= `${form.value[key].join(', ')}. `
        } else {
          msg+= `${form.value[key]}. `
        }
  
        // x.messages.push({
        //   role: 'user',
        //   content: msg
        // })
      }
    })
    
    x.messages.push({
      role: 'user',
      content: `${msg}Write ${contextdata.optionsCount} options in html ul.`
    })
    // x.messages.push({
    //   role: 'user',
    //   content: "Important: Answer without extra text, only the answer to my specific request. If I ask you to provide 1 option, give it in the form (for example js array: ['option']). If I ask for more options, give them out without iteration and in the form of a single array where each element of the array is an answer variant (for example js array: ['option1', 'option2', ...])"
    // })

    contextdata.isGettingResults = true;
    this.attachSubscriptions(
      this.aiService.sendAIMsg(x).pipe(
        map(u => {
          if (u.indexOf("<ul>") != -1) {
            const parser = new DOMParser();
            const doc = parser.parseFromString(u, 'text/html');
            const ulElement = doc.querySelector('ul');

            if (ulElement) {
              let liElements = ulElement.querySelectorAll('li');
              let liArray = Array.from(liElements).map(x => (String(x.textContent) as any).replaceAll("\n", "").trim())
              return {parsed: !!liArray && liArray.length ? liArray : false, before: u};
            } else {
              return {parsed: false, before: u}
            }
          } else {
            return {parsed: false, before: u}
          }
        })
      ).subscribe(resp => {
        if (resp) {
          this.aiCount = 0;
          contextdata.isGettingResults = false;
          this.aiResults = resp.parsed
          this.openContext(e, contextdata, true, true)
        } else {
          if (this.aiCount <= 3) {
            this.getCutResults(e, this.aiForm, contextdata)
          } else {
            this.aiCount = 0;
            contextdata.isGettingResults = false;
            this.layoutService.showSnackBar({name: ''}, marker("Incorrect data received, please try again"), SnackBarItem)
          }
        }
      }, error => {
        if (this.aiCount <= 3) {
          this.getCutResults(e, this.aiForm, contextdata)
        } else {
          this.aiCount = 0;
          contextdata.isGettingResults = false;
          this.layoutService.showSnackBar({name: ''}, marker("Incorrect data received, please try again"), SnackBarItem)
        }
      })
    )
  }

  setTitlesForParts(cards) {
    this.closeContext();
    cards.forEach((card,i) => {
      if (card.form && this.aiResults && this.aiResults.length && this.aiResults[i]) {
        card.is_input_set_ai = true;
        card.card_name = this.aiResults[i];
      }
    });
  }

  useThumb(file) {

    // вфі
    const videoUrl = this.data.host + file.original + '?company_id=' + this.data.company_id;
    let timeInSeconds = (document.getElementById(`video_${file.cstID}`) as HTMLVideoElement).currentTime;


    this.attachSubscriptions(
      this.fileService.getFilePartition("1", this.data.company ? this.data.company.id : this.data.company_id, {file_id: file.original_file_id}).subscribe(consists => {
        console.log("get consist_of", consists)

        if (consists.length && !!consists.filter(k => k.consistOfFile && k.consistOfFile.filename.indexOf(`Thumbnail-${(timeInSeconds as number).toFixed(2)}s`) != -1).length) {
          let thumb = consists.find(k => k.consistOfFile && k.consistOfFile.filename.indexOf(`Thumbnail-${(timeInSeconds as number).toFixed(2)}s`) != -1).consistOfFile
          console.log("HAS THIS THUMB", thumb)
          this.layoutService.showSnackBar({name: `This video file already has a cover for ${(timeInSeconds as number).toFixed(2)}s`}, 'It will be used', SnackBarItem);
          let newFrame = {
            id: this.generateRandomId(),
            blob: null,
            blobUrl: null,
            blobSrc: null,
            isLoad: true,
            time: +(timeInSeconds as number).toFixed(2),
            reportsFile: thumb,
            uploadedFile: thumb
          }
          
          // if (!file.frames) {
          //   file.frames = [newFrame]
          // } else {
          //   file.frames.push(newFrame)
          // }
          file.thumbFile = newFrame

        } else {
          this.getVideoFrameAsBlob(videoUrl, +(timeInSeconds as number).toFixed(2))
          .then((blob) => {
            console.log('Generated Blob:', blob);
            // Например, создаем объект URL для предпросмотра
            const blobUrl = URL.createObjectURL(blob);
    
            let newFrame = {
              id: this.generateRandomId(),
              blob: blob,
              blobUrl: blobUrl,
              blobSrc: this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl),
              isLoad: true,
              time: +(timeInSeconds as number).toFixed(2)
            }
            
            // if (!file.frames) {
            //   file.frames = [newFrame]
            // } else {
            //   file.frames.push(newFrame)
            // }
            file.thumbFile = newFrame
    
            this.uploadFrame(file, newFrame, true);
            console.log('file:', file);
            console.log('Blob URL:', blobUrl);
          })
          .catch((error) => {
            console.log('file:', file);
            console.error('Error:', error);
          });
        }
      })
    )

      
    // console.log("useThumb", file);
    // console.log("useThumb", document.getElementById(`video_${file.cstID}`))
    // console.log("useThumb", (document.getElementById(`video_${file.cstID}`) as HTMLVideoElement))
    // console.log("useThumb", (document.getElementById(`video_${file.cstID}`) as HTMLVideoElement).currentTime)
    // file.post_frame_ms = (document.getElementById(`video_${file.cstID}`) as HTMLVideoElement).currentTime;

    // if (document.getElementById(`video_thumb_${file.cstID}`)) {
    //   (document.getElementById(`video_thumb_${file.cstID}`) as HTMLVideoElement).currentTime = file.post_frame_ms;
    // }
  }

  loadedVideo(e, file) {
    e.target.currentTime = file.post_frame_ms;
  }

  removeThumb(file) {
    // this.attachSubscriptions(
    //   this.fileService.deleteFile(file.thumbFile.id).subscribe(resp => {
    //     delete file.thumbFile;
    //   }, error => {
    //   })
    // )
    delete file.thumbFile;
  }

  extractTimeInSeconds(input: string): number | null {
    const match = input.match(/Thumbnail-(\d+(\.\d+)?)s-/);
    if (match && match[1]) {
      return parseFloat(match[1]);
    }
    return 0; // Возвращаем null, если время не найдено
  }

  removeFrame(file, frameInd) {
    file.frames.splice(frameInd, 1)
  }

  resetRes() {
    this.aiResults = [];
    this.aiMistake = undefined;
    this.showSys = false;
  }

  selectTitle(item, contentData) {
    console.log("item, contentData", item, contentData)
    if (contentData.contextData && contentData.contextData.is_cut) {
      contentData.contextData.is_input_set_ai = true;
      contentData.contextData.card_name = item;
    } else {
      this.data.is_input_set_ai = true;
      this.form.patchValue({
        name: item
      })
    }
    this.closeContext();
  }

  getResultsReqObject() {
    let x:any = {
      company_id: this.data.company.id,
      partner_company_id: this.data.company.id != this.data.target_company_id ? this.data.target_company_id : 0,
      messages: [],
      model: this.ai_model.value
    }
    let form = this.aiForm.value;

    
    let msg = "";
    Object.keys(form).forEach(key => {
      if (!!form[key]) {
        if (['keywords', 'remembers'].includes(key) && form[key].length == 0) {
          return
        }
        
        // if (key == "on_the_video") {
        //   msg += "On the video"
        // } else if (key == "your_idea_of_title") {
        //   msg += "Example of the title"
        // } else if (key == "also_remember") {
        //   msg += "Also remember"
        // } else if (key == 'keywords') {
        //   msg += "Use words"
        // } else if (key == 'remembers') {
        //   msg += "Remember"
        // }

        if (key == 'keywords') {
          msg += "Use keywords "
        }

        if (['keywords', 'remembers'].includes(key)) {
          msg+= `${form[key].join(', ')}. `
        } else {
          msg+= `${form[key]}. `
        }
        // x.messages.push({
        //   role: 'user',
        //   content: msg
        // })
      }
    })
    // x.messages.push({
    //   role: 'user',
    //   content: `Important: Write ${parts.length} options for video title. Answer without extra text, only the answer to my specific request. If I asked you for several options, give them as an array. Where each option is a string and an array element. Without extra text and iteration of options in your answer. (for example js array: ["option1", "option2", ...]).`
    // })
    x.messages.push({
      role: 'user',
      content: `${msg}Write ${this.cards.length} options in html ul.`
    })


    return x
  }

  getGroups() {
    this.attachSubscriptions(
      this.companyService.getInfiniteGroupsCompany(this.data.company_id, '1', '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.companyService.getInfiniteGroupsCompany(this.data.company_id, x).pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        this.groups = [].concat(...res);
        this.groups.unshift({
          id: '',
          name: "Not set"
        });
        this.groups$.next(this.groups.slice())
      })
    )
  }

  onSearchBy(by, key, val) {
    if (!this[key]) {
      return;
    }

    if (!val) {
      this[key+"$"].next(this[key].slice());
      return;
    } else {
      val = val.toLowerCase();
    }

    // filter the banks
    this[key+"$"].next(
      this[key].filter(b => b[by].toLowerCase().indexOf(val) > -1)
    );
  }

  getProfiles() {
    this.attachSubscriptions(
      this.companyService.getAllProfilesDyn('1', this.data.company.id, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }
  
          return forkJoin(arr.map(x => this.companyService.getAllProfilesDyn(x, this.data.company.id, '200').pipe(map(u => u.body)))).pipe(
            last(),
            tap(values => {
              let conValues = [].concat(...values)
              this.profiles = conValues;
              this.profiles$.next(this.profiles.slice());
            }),
          )
        }),
      ).subscribe(resp => {
        console.log("getGroupProfiles resp", resp);
        console.log("getGroupProfiles profiles", this.profiles);
      })
    )
  }
  
  getTaskTemplates() {
    this.attachSubscriptions(
      this.scenariosService.getTaskTemplates('1', {company_id: this.data.company_id}, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.scenariosService.getTaskTemplates(x, {company_id: this.data.company_id}, '200').pipe(map(u => u.body)))).pipe(
            last(),
            tap(values => {
              this.taskTemplates = [].concat(...values)
              this.taskTemplates.unshift({
                id: '',
                name: "Not set"
              })
              console.log("taskTemplates", this.taskTemplates)
              this.taskTemplates$.next(this.taskTemplates.slice())
            }),
          )
        }),
      ).subscribe(resp => {
        console.log("getTaskTemplates sub", resp);
      })
    )
  }

  startDrag(e) {
    console.log("startDrag", e);
    this.is_dragging = true;
  }

  endDrag(e) {
    console.log("startDrag", e);
    this.is_dragging = false;
  }

  deleteCard(card, i) {
    this.cards.splice(i, 1);
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
  }
  

  onImgError(event){
    event.target.src = this.data.imgRoute+'/assets/img/image_black_48dp.svg'
  }

  log() {
    console.log('cards', this.cards)
    console.log('publications', this.publications)
  }

  close() {
    this.dialogRef.close({event: "close", data: false});
  }

  deleteFile(file, item) {
    item.files.splice(item.files.findIndex(k => k.cstID == file.cstID), 1)
  }

  selected(e, file) {
    console.log(e)
    console.log("file", file)
  };

  displayFn(place:any): string {
    return place && place.name ? place.name : '';
  }

  openFile(e, file, item) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    if (file.content_type.indexOf("image") != -1) {
      this.previewVideo(file, item);
    } else if (file.content_type.indexOf("video") != -1) {
      this.previewVideo(file, item);
    } else if (file.content_type.indexOf("audio") != -1) {
      this.previewVideo(file, item);
    }  else if (file.content_type == 'application/pdf') {
      this.previewVideo(file, item);
    } else if (!!file.thumbnail) {
      this.previewVideo(file, item);
    } else if (!!file.is_document) {
      this.previewVideo(file, item);
    } else {
      this.download(file);
    }
  }

  download(file) {
    if (!!file.is_document) {
      const blob = new Blob([file.document.text], { type: 'text/html' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${file.filename}.html`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } else {
      window.open(this.data.host + file.original + '?company_id=' + this.data.company_id + `&filename=${file.filename}`, '_blank');
    }
  }

  neededData(task, company) {
    let arr = [
      this.chatService.getTasksChats(company.id, [task.id]).pipe(
        tap(res => {
          task['operations'].map(o_el => {
            o_el['chatsInfo'] = [];
            o_el['chatsInfo'].push(...res.filter(z => z.task_operation_id == o_el.id));
            o_el['unReadChats'] = res.filter(z => z.task_operation_id == o_el.id && z.is_read != '1').length;
            o_el['unClosedChats'] = res.filter(z => z.task_operation_id == o_el.id && z.status_id != '4').length;
          });
          task['chatsInfo'] = [];
          task['chatsInfo'].push(...res.filter(z => z.task_id == task.id));
          task['unReadChats'] = res.filter(z => z.is_read != '1').length;
          task['unClosedChats'] = res.filter(z => z.status_id != '4').length;
        })
      )
    ]
    if (task.company_id == company.id) {
      arr.push(
        this.taskService.getTaskClients(task.id, company.id).pipe(
          tap(res => {
            task.clients = res
          }),
          catchError(err => {
            return err
          })
        )
      )
    }
    return forkJoin(arr)
  }

  openAiReqText(withFrames?) {
    const dialogRefCst = this.dialog.open(ExtraAiInfoComponent, {
      panelClass: '',
      autoFocus: false,
      data: {
      }
    });

    this.attachSubscriptions(
      dialogRefCst.afterClosed().subscribe(result => {
        if (result.event == 'update' && result.data) {
          this.selectAiRequest(null, result.data, withFrames);
        }
        console.log(result);
      })
    )
  }

  previewVideo(file, item) {
    console.log(file)
    if (file.isOpen) {
      return
    }

    this.cards.forEach(card => {
      card.files.forEach(cFile => {
        cFile.isOpen = false;
      })
    })
    
    file.isOpen = true;
    if (this.openFileSub) {
      this.openFileSub.unsubscribe();
    }

    this.openFileSub = this.taskService.getOneTaskExpand(this.data.company.id, file.task_id).pipe(
      map(x => x.body[0]),
      switchMap(x => this.neededData(x, this.data.company).pipe(map(() => casesModel([x], [], 'update')),map(x => x.arr[0]))),
    ).subscribe(resp => {
      file.isOpen = false;
      if (this.is_mobile) {
        let playlist = item.files.filter(x => (x.is_uploaded || !!x.is_document) && (!!x.is_document || (x.content_type.indexOf("image") != -1 || x.content_type.indexOf("video") != -1 || x.content_type.indexOf("audio") != -1 || x.content_type == 'application/pdf') || !!x.thumbnail))
        let currIndex = playlist.indexOf(file)
        const dialogRef = this.dialog.open(MobFmViewComponent, {
          backdropClass: ['mob_video_viewer_backdrop'],
          panelClass: 'mob_video_viewer',
          autoFocus: false,
          data: {
            file: file,
            files: item.files,
            playlist: playlist,
            currIndex: currIndex,
            task: resp,
            work: !!file.task_operation_id ? resp.operations.find(x => x.id == file.task_operation_id) : undefined,
            company: this.data.company,
            user: this.data.user,
            initCompanyId: this.data.company_id
          }
        });
      } else {
        const dialogRef = this.dialog.open(VideoViewerComponent, {
          panelClass: 'video_viewer',
          autoFocus: false,
          data: {
            file: file,
            files: item.files,
            playlist: item.files.filter(x => (x.is_uploaded || !!x.is_document) && (!!x.is_document || (x.content_type.indexOf("image") != -1 || x.content_type.indexOf("video") != -1 || x.content_type.indexOf("audio") != -1) || x.content_type == 'application/pdf' || !!x.thumbnail)),
            task: resp,
            work: !!file.task_operation_id ? resp.operations.find(x => x.id == file.task_operation_id) : undefined,
            company: this.data.company,
            user: this.data.user,
            initCompanyId: this.data.company_id
          }
        });
      }
    }, error => {
      file.isOpen = false;
    })
  }

  hasFilesWithPubl(item) {
    return !!item.files.filter(k => k.publications && k.publications.length).length
  }

  save(is_draft:boolean = false) {
    this.isSubmit = true;
    console.log("save", is_draft);
    console.log("save cards", this.cards);
    this.cards.forEach(card => {
      card.files.forEach(element => {
        delete element.consists
      });
    });

    this.attachSubscriptions(
      this.fileService.createCardProject({
        company_id: this.data.company_id,
        name: this.projectName,
        is_draft: 1,
        is_to_process: 0,
        custom_data: JSON.stringify({
          cards: this.cards,
          publications: this.publications,
          form: this.form.value,
          ai_frames: this.ai_frames.value,
          ai_model: this.ai_model.value
        }, this.getCircularReplacer())
      }, this.data.company_id).pipe(
        switchMap(project => {
          console.log("project0", project)
          if (this.cards.length) {
            console.log("project1", project)
            let usedOutlets = [];
            let items = [];
            this.cards.forEach(card => {
              console.log("before activeT")
              let activeT = JSON.parse(JSON.stringify(this.getItemById(this.taskTemplates, this.form.get('template_id').value), this.getCircularReplacer()));
              console.log("after activeT", activeT)
              let cardData:any = {
                company_id: this.data.company_id,
                task_create_project_id: project.id,
                task_template_id: this.form.value.template_id,
                template_data: Object.assign(activeT.template_data, {name: card.card_name}),
                files_ids: '',
                task_channels: []
              }
              console.log("cardData", cardData);
              cardData.files_ids = card.files.map(u => u.original_file_id).join(',');
              card.files.forEach((file, fInd) => {
                console.log("file", file);

                file.publications.forEach(pub => {
                  let cont_name = '';
                  if (file.post_name) {
                    cont_name = file.post_name;
                  } else if (card.card_name) {
                    cont_name = card.card_name;
                  } else {
                    cont_name = file.filename;
                  }
                  
                  // TO DO
                  if (fInd == 0) {
                    cardData.template_data.name = cont_name;
                  }
                  // TO DO END
                  pub.content_name = pub.content_type_id && this.isYouTube(pub.content_type_id) && cont_name.length > 100 ? cont_name.slice(0,97)+'...' : cont_name;
                  pub.content_description = file.post_description || cont_name;
                  pub.task_channel_files[0].original_file_id = file.original_file_id;
                  pub.content_plan_min_interval = pub.content_plan_min_interval + usedOutlets.filter(k => k.channel_id == pub.channel_id && k.content_type_id == pub.content_type_id).length;
                          

                  if ((this.isFB(pub.content_type_id) || [201, 202, 206].includes(pub.content_type_id)) && file.post_location) {
                    let facebook_place_id;
                    if (file.post_location.place_id) {
                      facebook_place_id = file.post_location.place_id
                    } else {
                      facebook_place_id = file.post_location
                    }
                    pub.facebook_place_id = facebook_place_id
                  }

                  if (pub.content_type_id && this.isYouTube(pub.content_type_id)) {
                    pub.publishing_params = {
                      privacy : 'private',
                      made_for_kids: !!this.form.value.made_for_kids ? 1 : 0
                    }
                  }

                  if (pub.content_type_id && this.isTikTok(pub.content_type_id)) {
                    pub.publishing_params = {
                      privacy_level : 'PUBLIC_TO_EVERYONE',
                      disable_duet: true,
                      disable_stitch: true,
                      disable_comment: false,
                      brand_content_toggle: false,
                      brand_organic_toggle: false,
                    }
                  }
                  
                  // is_content: 1,
                  // is_preview: 0,
                  // is_thumbnail: 0,
                  // is_original: 0,
                  // is_to_upload: 1

                  // TODO
                  // if (!!file.post_frame_ms && file.post_frame_ms > 0) {
                  //   pub.task_channel_files.push({
                  //     company_id: pub.company_id,
                  //     task_channel_id: pub.channel_id,
                  //     original_file_id: file.original_file_id,
                  //     thumbnail_frame_ms: Math.round(file.post_frame_ms*1000),
                  //     is_to_upload: 1,
                  //     is_thumbnail_frame: 1
                  //   })
                  // }

                  if (!!file.thumbFile && !!file.thumbFile.reportsFile) {
                    pub.task_channel_files.push({
                      company_id: pub.company_id,
                      task_channel_id: pub.channel_id,
                      original_file_id: file.thumbFile.reportsFile.original_file_id,
                      is_to_upload: 1,
                      is_thumbnail: 1
                    })
                  }
                  cardData.task_channels.push(pub);
                  usedOutlets.push({channel_id: pub.channel_id, content_type_id: pub.content_type_id})
                });
              })

              items.push(cardData);
            })

            console.log("ITEMS", items);

            // return forkJoin(items.map(x => this.fileService.createCardProjectItem(x, this.data.company_id))).pipe(
            //   map(projItems => {
            //     return {
            //       projItems: projItems,
            //       project: project
            //     }
            //   })
            // )

            let postData = []
            

            items.forEach(k => {
              postData.push({
                "path": `/api/task-create-project-item/`,
                "query": {"company_id": this.data.company_id},
                "method": "POST",
                "body": {
                  [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
                  ...k
                }
              })
            })

            return this.taskService.multiRequest(postData).pipe(
              map(projItems => {
                return {
                  projItems: projItems,
                  project: project
                }
              })
            )
          } else {
            console.log("project2", project)
            return of({
              project: project,
              projItems: []
            })
          }
        }),
        switchMap(result => {
          if (!is_draft) {
            return this.fileService.editCardProject(result.project.id, {is_draft: 0, is_to_process: 1}, this.data.company_id).pipe(
              map(editedProject => {
                return {
                  editedProject: editedProject,
                  project: result.project,
                  projItems: result.projItems
                }
              })
            )
          } else {
            return of(result)
          }
        })
      ).subscribe(res => {
        console.log("save", res);
        this.layoutService.showSnackBar({name: 'Project created'}, '', SnackBarItem)
        this.isSubmit = false;
        this.close();
      }, error => {
        this.isSubmit = false;
        this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
      })
    )
  }

  dropCard(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.cards, event.previousIndex, event.currentIndex);
  }

  getCircularReplacer() {
    const ancestors:any = [];
    return function (key, value) {
      if (typeof value !== "object" || value === null) {
        return value;
      }
      // `this` is the object that value is contained in,
      // i.e., its direct parent.
      while (ancestors.length > 0 && ancestors.at(-1) !== this) {
        ancestors.pop();
      }
      if (ancestors.includes(value)) {
        return "[Circular]";
      }
      ancestors.push(value);
      return value;
    };
  }

  selectTmpl(e, card?) {
    console.log(e, card)
    if (card) {
      // if (e.value === '') {
      //   card.form.patchValue({
      //     type: 0
      //   })
      // } else {
      //   let tmpl = this.taskTemplates.find(x => x.id == e.value)
      //   card.form.patchValue({
      //     type: 1,
      //     name: tmpl.template_data.name
      //   })
      // }
      // card.is_custom.patchValue(true)
      // card.is_input_set_ai = false;
      // this.saveVideoEditorHistory();
    } else {
      console.log('e.value', e.value);
      if (e.value === '') {
        // this.form.patchValue({
        //   type: 0
        // })
      } else {
        let tmpl = this.taskTemplates.find(x => x.id == e.value)
        this.form.patchValue({
          name: tmpl.template_data.name
        })
      }
    }
  }

  closeContext() {
    console.log("closeContext")
    this.profilesTab = 0;
    // this.regularDateTimes = [];
    // this.appPublicationData = {
    //   channel_id: '',
    //   content_type_id: '',
    //   content_status_id: 6,
    //   content_published_at: '',
    //   content_name: '',
    //   content_description: '',
    //   task_channel_files: [{
    //     original_file_id: 0,
    //     is_content: 1,
    //     is_preview: 0,
    //     is_thumbnail: 0,
    //     is_original: 0,
    //     is_to_upload: 1
    //   }]
    // }
    this.backContextSub && this.backContextSub.unsubscribe();
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
  }

  logAi() {
    console.log("form", this.form.value)
    // console.log("ai_model", this.ai_model.value)
    // console.log("aiLang", this.aiLang.value)
    // console.log("aiAction", this.aiAction.value)
    // console.log("aiLangName", this.aiLangName.value)
    // console.log("aiActionName", this.aiActionName.value)
  }

  getValue(file, card) {
    card.is_getting_ai = true;
    let content = `Give me only array(without extra text outside []) with 10 objects in the format [{title: 'Title for the post on image', description: 'Description for the post on image'},....]. I use json parse on your answer.`
    content += `

File meta data: ${file.parameterValuesToTask.map(k => k.parameterValue ? k.parameterValue.value : ' ').join(', ')}.`

    if (file.post_location) {
      if (file.post_location.name) {
        content += `
Location: ${file.post_location.name}`
      } else {
        content += `
Location: ${file.post_location}`
      }
    } 

    content += `
    
100 symbols long title and put 2 hash tags in the end of title`

    let lang = file.parameterValuesToTask.find(k => k.parameter.is_language) && file.parameterValuesToTask.find(k => k.parameter.is_language).parameterValue ? file.parameterValuesToTask.find(k => k.parameter.is_language).parameterValue.value : undefined;
    if (lang) {
      content += `
Write the answer in ${lang} language.`
    }

    let x:any = {
      company_id: this.data.company.id,
      partner_company_id: this.data.company.id != this.data.target_company_id ? this.data.target_company_id : 0,
      messages: [],
      model: this.ai_model.value
    }
    x.messages.push({
      role: 'user',
      content: [{
        type: 'text',
        text: content
      }, {
        type: 'image_url',
        image_file_id: file.id
      }]
    })

    this.attachSubscriptions(
      this.aiService.sendAIMsg(x).subscribe(answer => {

        try {
          // Извлечение JSON из текста
          let jsonMatch = answer.match(/\[([\s\S]*?)\]/); // Альтернатива без флага 's'
          if (jsonMatch) {
              let jsonString = jsonMatch[0]; // Сам JSON
              let parseAnswer = JSON.parse(jsonString); // Парсим JSON
              console.log('Parsed data:', parseAnswer);
              let titles;
              let descs;
              if (parseAnswer) {
                titles = parseAnswer.map(k => k.title);
                descs = parseAnswer.map(k => k.description);
              }
              file.titles = titles || [];
              file.descs = descs || [];
              card.is_getting_ai = false;
          } else {
              console.error('No JSON array found in the response');
              file.titles = [];
              file.descs = [];
              card.is_getting_ai = false;
          }
        } catch (error) {
          console.error('Error parsing JSON:', error);
          file.titles = [];
          file.descs = [];
          card.is_getting_ai = false;
        }
        console.log('file titles', file.titles);
        console.log('file descs', file.descs);
      })
    )
  }

  allFilesUploadFrames() {
    let notUploadedFrames: number = 0
    this.cards.forEach(card => {
      card.files.forEach(file => {
        if (file.frames) {
          file.frames.forEach(frame => {
            if (!frame.uploadedFile) {
              notUploadedFrames++
            }
          });
        } else {
          // if (!!this.ai_frames.value && (!file.frames || !file.frames.length)) {
            
          // }
        }
      });
    });

    return notUploadedFrames == 0;
  }

  selectAiRequest(card?, moreVal?, withFrames?) {
    this.logAi();
      // let content = `${this.getSchemeContent(actionControl.value, langControl.value, key)}`
    if (!!withFrames && !this.allFilesUploadFrames()) {
      this.layoutService.showSnackBar({name: 'Frame creation in progress'}, marker("Wait a few seconds"), SnackBarItem)
      return
    }

    this.ls.requests$.next({
      value: 0,
      target: "Getting media info"
    })

    let count = 0;
    concat(...this.cards.map((c) => {
      c.is_getting_ai = true;
      if (c.files && c.files.length) {
        return forkJoin(c.files.map(file => {
          let content = `Give me only array(without extra text outside []) with 10 objects in the format [{title: ${withFrames && file.frames && file.frames.length ? 'Title for the social media video post for that consist of frames provided in images' : 'Title for the post on image'}, description: ${withFrames && file.frames && file.frames.length ? 'Description for the social media video post for that consist of frames provided in images no less than 250 symbols' : 'Description for the post on image'}},....]. I use json parse on your answer.`

          content += `

${withFrames && file.frames && file.frames.length ? 'Files' : 'File'} meta data: ${file.parameterValuesToTask.map(k => k.parameterValue ? k.parameterValue.value : ' ').join(', ')}.`
          
          if (file.post_location) {
            if (file.post_location.name) {
              content += `
Location: ${file.post_location.name}`
            } else {
              content += `
Location: ${file.post_location}`
            }
          }

          if (moreVal) {
            content += `

${moreVal}`
          } else {
            content += `
            
100 symbols long title and put 2 hash tags in the end of title`
          }

          let lang = file.parameterValuesToTask.find(k => k.parameter.is_language) && file.parameterValuesToTask.find(k => k.parameter.is_language).parameterValue ? file.parameterValuesToTask.find(k => k.parameter.is_language).parameterValue.value : undefined;
          if (lang) {
            content += `
Write the answer in ${lang} language.`
          }

          let x:any = {
            company_id: this.data.company.id,
            partner_company_id: this.data.company.id != this.data.target_company_id ? this.data.target_company_id : 0,
            messages: [],
            model: this.ai_model.value
          }
          x.messages.push({
            role: 'user',
            content: [{
              type: 'text',
              text: content
            }, {
              type: 'image_url',
              image_file_id: file.id
            }]
          })

          if (withFrames && file.frames && file.frames.length) {
            file.frames.forEach(frame => {
              if (frame.uploadedFile) {
                x.messages[0].content.push({
                  type: 'image_url',
                  image_file_id: frame.uploadedFile.id
                })
              }
            })
          }

          return this.aiService.sendAIMsg(x).pipe(
            tap(answer => {
              try {
                // Извлечение JSON из текста
                let jsonMatch = answer.match(/\[([\s\S]*?)\]/); // Альтернатива без флага 's'
                if (jsonMatch) {
                    let jsonString = jsonMatch[0]; // Сам JSON
                    let parseAnswer = JSON.parse(jsonString); // Парсим JSON
                    console.log('Parsed data:', parseAnswer);
                    let titles;
                    let descs;
                    if (parseAnswer) {
                      titles = parseAnswer.map(k => k.title);
                      descs = parseAnswer.map(k => k.description);
                    }
                    file.titles = titles || [];
                    file.descs = descs || [];
                    c.is_getting_ai = false;
                } else {
                    console.error('No JSON array found in the response');
                    file.titles = [];
                    file.descs = [];
                    c.is_getting_ai = false;
                }
              } catch (error) {
                console.error('Error parsing JSON:', error);
                file.titles = [];
                file.descs = [];
                c.is_getting_ai = false;
              }
              
              console.log('file titles', file.titles);
              console.log('file descs', file.descs);
            }),
          )
        }))
      } else {
        c.is_getting_ai = false;
        return of(null)
      }
    })).pipe(
      tap(el => {
        this.ls.requests$.next({
          value: Math.round((100 / this.cards.length) * (count+1)),
          target: "Getting media info"
        })
        count++;
      }),
      catchError((error) => {
        this.layoutService.showSnackBar({name: 'Please wait a minute, the frames are still being processed.'}, marker("Try again later."), SnackBarItem)
        this.ls.requests$.next({
          value: 100,
          target: "Getting media info"
        })
        return of(false)
      })
    ).subscribe(res => {
      console.log(res);
      console.log('cards', this.cards);
    })
  
  }

  openContext({ x, y }: MouseEvent, contextData, is_ai?, is_cut?) {
    this.closeContext();
    console.log("{ x, y }: MouseEvent, contextData, group_id", { x, y }, contextData)

    let positionStrategy;
    
    if (this.is_mobile) {
      positionStrategy = this.overlay.position()
        .global()
        .centerHorizontally() // Центрируем окно по горизонтали
        .top('10%');
    } else {
      positionStrategy = this.overlay.position()
        .flexibleConnectedTo({ x, y })
        .withPositions([
          {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top',
          }
        ]);
    }

    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });

    contextData.is_cut = is_cut

    let conData:any = {
      contextData: contextData
    }

    if (is_ai) {
      conData.is_ai = is_ai
    } else {
      let outlets = []
      let setupGroups = [];
      let activeT = this.getItemById(this.taskTemplates, this.form.get('template_id').value);
      if (activeT && activeT.template_data.group_id && !setupGroups.includes(activeT.template_data.group_id)) {
        setupGroups.push(activeT.template_data.group_id)
      }
      // let parts = (this.data.tracks.filter(x => x.type == 'video').map(k => k.parts) as any).flat(Infinity)
      // parts.forEach(part => {
      //   if (part.form) {
      //     if (part.form.get('template_id').value) {
      //       let activeT = this.getItemById(this.taskTemplates, part.form.get('template_id').value);
      //       if (activeT && activeT.template_data.group_id && !setupGroups.includes(activeT.template_data.group_id)) {
      //         setupGroups.push(activeT.template_data.group_id)
      //       }
      //     } else if (part.form.value.group_id) {
      //       if (!setupGroups.includes(part.form.value.group_id)) {
      //         setupGroups.push(part.form.value.group_id)
      //       }
      //     }
      //   }
      // })

      console.log("setupGroups", setupGroups)

      if (setupGroups && setupGroups.length) {
        setupGroups.forEach(group_id => {
          console.log("this.getGroupById(group_id)", this.getGroupById(group_id))
          if (this.getGroupById(group_id)) {
            let channelToGroup = this.getGroupById(group_id).channelToGroup;
      
            channelToGroup.forEach((chan:any) => {
              chan.is_selected = false;
              chan.fromGroupId = group_id;
              chan.publData = {
                channel_id: chan.channel_id,
                content_type_id: chan.content_type_id,
                content_status_id: 6,
                content_published_at: 0,
                is_content_plan: 1,
                content_plan_start_at: 0,
                content_plan_min_interval: 0,
                content_name: '',
                content_description: '',
                task_channel_files: [{
                  original_file_id: 0,
                  is_content: 1,
                  is_preview: 0,
                  is_thumbnail: 0,
                  is_original: 0,
                  is_to_upload: 1
                }]
              }
              chan.publData.content_plan_content_schedules_ids = [];
              chan.publData.content_plan_content_schedule_items_ids = [];

              if (!!chan.content_type_id) {
                if (outlets.filter(x => x.content_type_id == chan.content_type_id).length == 0) {
                  outlets.push({
                    content_type_id: chan.content_type_id,
                    platform: chan.channel.platform,
                    contentType: this.getTypeById(chan.content_type_id),
                    arr: [chan],
                  })
                } else {
                  outlets.find(x => x.content_type_id == chan.content_type_id).arr.push(chan)
                }
              }
            })
  
            conData.group = this.getGroupById(group_id);
          }
        })
        conData.outlets = outlets;
      }

      this.attachSubscriptions(
        forkJoin(conData.outlets.map(outGr => forkJoin(outGr.arr.map(outl => this.companyService.getRegulars('1', this.data.company_id, {channel_id: [outl.channel_id || outl.id], content_type_id: outGr.content_type_id}).pipe(tap(regs => {outl.regulars = regs.body})))))).subscribe(resp => {
          console.log("after getting regulars", resp)
          console.log("conData.outlets after getting regulars", conData.outlets)
        })
      )
    }
    

    this.overlayRef.attach(new TemplatePortal(this.contextMenu, this.viewContainerRef, {
      $implicit: conData
    }));

    console.log("this.contextMenu", this.contextMenu)
    console.log("conData.outlets", conData.outlets)

    this.backContextSub = fromEvent<MouseEvent>(document, 'click')
      .pipe(
        filter(event => {
          const clickTarget = event.target as HTMLElement;
          return !!this.overlayRef && !this.overlayRef.overlayElement.contains(clickTarget) && !clickTarget.closest('.list_of_keywords') && !clickTarget.closest('.mat-select-panel'); 
        }),
        take(1)
      ).subscribe(() => this.closeContext())

  }

  nextTab() {
    if (this.profilesTab == 0) {

    }
    this.profilesTab = this.profilesTab + 1;
  }
  
  deletePublications(card?) {
    this.publications = [];
    this.cards.forEach((card, i) => {
      card.files.forEach(file => {
        file.publications = [];
      });
    });
  }

  finishPubl(outlets) {
    console.log("finishPubl", outlets)
    let pubs = [];
    outlets.forEach(outlet => {
      outlet.arr.forEach(item => {
        if (item.is_selected) {
          pubs.push(item)
          // pubs.push(item.publData)
        }
      });
    });
    this.publications = pubs;
    console.log("finishPubl 2", this.publications)


    // parts.forEach(part => {
    //   if (part.form) {
    //     if (part.form.get('template_id').value) {
    //       let activeT = this.getItemById(part.taskTemplates, part.form.get('template_id').value);
    //       if (activeT && activeT.template_data.group_id && !setupGroups.includes(activeT.template_data.group_id)) {
    //         setupGroups.push(activeT.template_data.group_id)
    //       }
    //     } else if (part.form.value.group_id) {
    //       if (!setupGroups.includes(part.form.value.group_id)) {
    //         setupGroups.push(part.form.value.group_id)
    //       }
    //     }
    //   }
    // })

    this.cards.forEach((card, i) => {

      // let cardGroupId;

      // if (card.form) {
      //   if (card.form.get('template_id').value) {
      //     let activeT = this.getItemById(this.taskTemplates, card.form.get('template_id').value);
      //     if (activeT && activeT.template_data.group_id) {
      //       cardGroupId = activeT.template_data.group_id;
      //     }
      //   } else if (card.form.value.group_id) {
      //     cardGroupId = card.form.value.group_id;
      //   }
      // }

      card.files.forEach(file => {
        file.tags_ids = file.parameterValuesToTask.map(x => x.parameter_value_id);
        this.publications.forEach(pub => {
          pub.tags_ids = this.getChannelById(pub.channel_id).parameterValuesToChannel.map(x => x.parameter_value_id)
          if (this.checkForMatches(pub.tags_ids, file.tags_ids)) {
            file.publications.push(JSON.parse(JSON.stringify(pub.publData, this.getCircularReplacer())))
          }
        });     
      });


      // if (cardGroupId) {
      //   let pubsData = JSON.parse(JSON.stringify(this.publications, this.getCircularReplacer()))
      //   pubsData.map(x => x.publData.content_plan_min_interval = x.publData.content_plan_min_interval + 1)
      //   card.publications = pubsData.filter(x => x.fromGroupId == cardGroupId).map(x => x.publData);

      //   console.log("card.publications", card.publications)
      // }



      // if (i == this.cards.length - 1) {
      // }
    });
    this.closeContext();
  }

  checkForMatches(arr1, arr2) {
    // Создаем множества из элементов массивов для быстрого поиска
    let set1 = new Set(arr1);
    let set2 = new Set(arr2);
    
    // Проверяем каждый элемент из первого массива на наличие во втором
    for (let num of set1) {
        if (set2.has(num)) {
            return true; // Если нашли совпадение, возвращаем true
        }
    }
    
    // Если не нашли совпадений, возвращаем false
    return false;
}

  getForAllContentTypes() {
    this.attachSubscriptions(
      this.companyService.getContentTypes().pipe(
        map(el => el.body)
      ).subscribe(resp => {
        this.forAllContentTypes = resp;
      })
    )
  }
  
  checkOutletsCount(arrOut) {
    let count = 0;

    arrOut.forEach(el => {
      count = count + el.arr.length;
    })
    
    return count
  }
  
  selectAll(arrOut) {    
    arrOut.forEach(el => {
      el.arr.forEach(elIn => {
        elIn.is_selected = true;
      })
    })
  }
  
  getGroupById(id) {
    if (!this.groups || this.groups.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.groups.find(el => el.id == id)
  }

  getTypeById(id) {
    if (!this.forAllContentTypes || this.forAllContentTypes.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.forAllContentTypes.find(el => el.id == id)
  }

  getItemById(arr, id) {
    return arr.find(x => x.id == id)
  }

  createFrames() {
    // if (!this.ai_frames.value || this.createFramesStart) {
    if (!this.ai_frames.value) {
      return
    }

    // this.createFramesStart = true;
   
    this.cards.forEach(card => {
      card.files.forEach(file => {
        if (file.duration || file.meta_duration) {
          let times = this.getFrameTimes(file.duration || file.meta_duration, this.ai_frames.value)
          times.forEach(time => {
            this.addFrame(file, time)
          })
        }
      });
    })

    this.layoutService.showSnackBar({name: 'Frame creation started'}, marker("successfully"), SnackBarItem)
  }

  getFrameTimes(videoDuration: number, framesCount: number): number[] {
    if (framesCount < 1 || videoDuration <= 0) {
      throw new Error("Неверные параметры: framesCount должен быть >= 1, videoDuration > 0.");
    }
  
    // Определяем количество кусков (framesCount + 2 включает крайние отрезки, которые нужно отбросить)
    const totalSegments = framesCount + 2;
  
    // Длина одного куска
    const segmentDuration = videoDuration / totalSegments;
  
    // Генерируем массив времени начала каждого куска, исключая первый и последний
    const frameTimes = [];
    for (let i = 1; i <= framesCount; i++) {
      frameTimes.push(segmentDuration * i);
    }
  
    return frameTimes;
  }

  loadedMetaVideo(e, file) {
    if (!e.target.duration) {
      file.duration = file.meta_duration;
      return
    }
    file.duration = +e.target.duration;
  }

  addFrame(file, time?) {
    const videoUrl = this.data.host + (file.preview1080 ? file.preview1080 : file.original) + '?company_id=' + this.data.company_id;
    let timeInSeconds;

    if (time) {
      timeInSeconds = time;
    } else {
      timeInSeconds = (document.getElementById(`video_${file.cstID}`) as HTMLVideoElement).currentTime;
    }

    timeInSeconds = +(timeInSeconds as number).toFixed(2)

    this.getVideoFrameAsBlob(videoUrl, timeInSeconds)
      .then((blob) => {
        console.log('Generated Blob:', blob);
        // Например, создаем объект URL для предпросмотра
        const blobUrl = URL.createObjectURL(blob);

        let newFrame = {
          id: this.generateRandomId(),
          blob: blob,
          blobUrl: blobUrl,
          blobSrc: this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl),
          isLoad: true,
          time: timeInSeconds
        }
        
        if (!file.frames) {
          file.frames = [newFrame]
        } else {
          file.frames.push(newFrame)
        }

        this.uploadFrame(file, newFrame);
        console.log('file:', file);
        console.log('Blob URL:', blobUrl);
      })
      .catch((error) => {
        console.log('file:', file);
        console.error('Error:', error);
      });
  }

  uploadFrame(file, frame, is_thumb: boolean = false) {
    this.attachSubscriptions(
      this.fileService.uploadFile({
        company_id: file.company_id,
        task_id: file.task_id,
        task_operation_id: file.task_operation_id,
        filesize: frame.blob.size,
        filename: is_thumb ? `Thumbnail-${(frame.time as number).toFixed(2)}s-${file.filename.split('.')[0]}.png` : `Frame-${(frame.time as number).toFixed(2)}s-${file.filename.split('.')[0]}.png`,
        content_type: frame.blob.type ? frame.blob.type : 'image/png',
        location: is_thumb ? file.location : '/project/ai-resources',
        is_dir: 0
      }, this.data.company.id).pipe(
        tap(res => {
          frame.reportsFile = res;
          frame.place = "cp_master";
          frame.url = window.location.href;
          frame.data = res;
          frame.files = [];
          frame.task = undefined;
          frame.work = undefined;
          frame.activeLang = this.data.activeLang;
          frame.location = is_thumb ? file.location : '/project/ai-resources';
          frame.target = frame.blob;
          frame.user = this.data.user;
          frame.task_id = file.task_id;
          frame.task_operation_id = file.task_operation_id;
          frame.company = this.data.company;
          frame.partition = {
            company_id: file.company_id,
            file_id: file.id,
            consist_of_file_id: frame.reportsFile.id
          };
          frame.company_id = this.data.company.id;
          frame.target_company_id = file.target_company_id;
          this.fileService.files$.next(frame);

          file.consists.push(frame);
          file.consists.sort((a, b) => a.time - b.time);
        }),
        catchError((error) => {
          if (error == "Company limit exceeded") {
            this.layoutService.showSnackBar({name: ''}, marker("This company has exceeded the file upload limit."), SnackBarItem)
          } else {
            this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
          }
          return of(false)
        })
      ).subscribe(resp => {
        console.log(resp)
      })
    )
  }

  getVideoFrameAsBlob(videoUrl: string, timeInSeconds: number): Promise<Blob> {
    return new Promise((resolve, reject) => {
      // Создаем элементы видео и canvas
      const video = document.createElement('video');
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      
      if (!context) {
        reject('Canvas context not available');
        return;
      }
  
      video.crossOrigin = 'anonymous'; // Устанавливаем для поддержки CORS (если нужно)
      video.src = videoUrl;
  
      // Обработка ошибки загрузки видео
      video.onerror = () => reject('Error loading video');
  
      video.onloadedmetadata = () => {
        // Проверяем, чтобы время не превышало длительность видео
        if (timeInSeconds > video.duration) {
          reject('Specified time exceeds video duration');
          return;
        }
  
        // Устанавливаем время воспроизведения
        video.currentTime = timeInSeconds;
      };
  
      video.onseeked = () => {
        // Устанавливаем размеры canvas
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
  
        // Рисуем кадр на canvas
        context.drawImage(video, 0, 0, canvas.width, canvas.height);
  
        // Генерируем Blob
        canvas.toBlob(
          (blob) => {
            if (blob) {
              resolve(blob);
            } else {
              reject('Failed to create Blob');
            }
          },
          'image/png', // Формат изображения
          1 // Качество (от 0 до 1)
        );
      };
    });
  }

  ngOnDestroy(): void {
    if (this.openFileSub) {
      this.openFileSub.unsubscribe();
    }
    this.clearSubscriptions()
  }

}
