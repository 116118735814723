<div class="container centred_page">

    <div class="step">
        <div #tableContainer class="example-container mat-elevation-z8 employees_table">
            <div class="quest_form">

                <form *ngIf="form" [formGroup]="form" (ngSubmit)="submit()">
                    <ng-container *ngFor="let key of keys; let i = index">
                        <div class="application_title" *ngIf="(i == 0 && formData[key]?.application?.id) || (formData[key]?.application?.id != formData[keys[i-1]]?.application?.id)">
                            {{formData[key]?.application?.name}}
                        </div>
                        <div class="form_item">
                            <ng-container [ngSwitch]="formData[key].type">
                                <ng-container *ngSwitchCase="'file'">
                                    <div class="d-f" style="flex-direction: column; width: 100%;">
                                        <a *ngIf="formData[key].file" target="_blank" [href]="host + formData[key].file.download_url + '?company_id=' + company?.id" class="d-f-c download_link" style="margin: 10px 0; padding: 0 11.25px;"> <mat-icon>download</mat-icon> {{formData[key].file.filename}} <b class="ml-1" *ngIf="formData[key].file.filesize">{{(formData[key].file.filesize / (1024*1024)) | reportsNumber}}MB</b> </a>
                                        <mat-form-field appearance="outline" class="form_item_field full_width_form">
                                            <mat-label>{{formData[key].name}}</mat-label>
                                            <ngx-mat-file-input [formControlName]="key" (change)="uploadFile($event, formData[key])"></ngx-mat-file-input>
                                            <mat-hint style="color: red; padding-top: 11px;" *ngIf="formData[key].comment && appStatusId <= 2">{{formData[key].comment}}</mat-hint>
                                            <mat-icon matSuffix>folder</mat-icon>
                                        </mat-form-field>
                                        <!-- <button class="cancel_btn" [ngClass]="{'has_not_file': !formData[key].file || !formData[key].file?.download_url}" mat-raised-button color="warn" *ngIf="formData[key].upload && (formData[key].upload.progress || formData[key].upload.progress == 0) && !formData[key].upload.is_end" style="cursor: pointer;" (click)="cancelUpload(formData[key])">
                                            <mat-icon>close</mat-icon>
                                            {{ "Cancel" | translate }}
                                        </button> -->
                                        <mat-progress-bar *ngIf="formData[key].upload && (formData[key].upload.progress || formData[key].upload.progress == 0) && !formData[key].upload.is_end" class="progress_bar" [ngClass]="{'pending': formData[key].upload.progress == -1}" [mode]="formData[key].upload.state == 'PENDING' ? 'indeterminate' : 'determinate'" [value]="formData[key].upload.progress"></mat-progress-bar>
                                        <div *ngIf="formData[key].upload && (formData[key].upload.progress || formData[key].upload.progress == 0) && formData[key].upload.progress >= 0 && !formData[key].upload.is_end" class="progress">{{formData[key].upload.progress}}%</div>
                                    </div>
                                </ng-container>
                                <ng-container *ngSwitchCase="'rules'">
                                    <div class="form_item_field full_width_form">
                                        <div [innerHTML]="formData[key].rules"></div>
                                        <mat-checkbox [formControlName]="key" (change)="onChangeRules($event.checked, formData[key])" [checked]="+formData[key].text == 1">{{formData[key].name}}</mat-checkbox>
                                        <div *ngIf="formData[key].comment && appStatusId <= 2" style="font-size: 75%; padding: 0 1em;">
                                            <mat-hint style="color: red; padding-top: 11px; font-weight: 400;">{{formData[key].comment}}</mat-hint>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-container *ngSwitchCase="'date'">
                                    <mat-form-field appearance="outline" class="form_item_field full_width_form">
                                        <mat-label>{{formData[key].name}}</mat-label>
                                        <input (dateChange)="changeDate($event, formData[key])" disabled [formControlName]="key" matInput [matDatepicker]="pickerDay" placeholder="MM / DD/ YYYY">
                                        <mat-datepicker-toggle matSuffix [for]="pickerDay"></mat-datepicker-toggle>
                                        <mat-datepicker disabled="false" #pickerDay></mat-datepicker>
                                        <mat-hint style="color: red; padding-top: 11px;" *ngIf="formData[key].comment && appStatusId <= 2">{{formData[key].comment}}</mat-hint>
                                    </mat-form-field>
                                </ng-container>
                                <ng-container *ngSwitchDefault>
                                    <mat-form-field appearance="outline" class="form_item_field full_width_form">
                                        <mat-label>{{formData[key].name}}</mat-label>
                                        <input [formControlName]="key" matInput (input)="printFieldVal($event.target.value, formData[key])" [value]="formData[key].text" [type]="formData[key].type" placeholder="{{formData[key].name}}">
                                        <mat-hint style="color: red; padding-top: 11px;" *ngIf="formData[key].comment && appStatusId <= 2">{{formData[key].comment}}</mat-hint>
                                    </mat-form-field>
                                </ng-container>
                            </ng-container>
                        </div>
                    </ng-container>
                    

                    <button type="submit" class="ml-1 d-f-btn" style="margin-top: 25px;" mat-raised-button color="primary" [disabled]="isSubmit || appStatusId == 4">
                        <mat-spinner class="spin_16 mr-1" *ngIf="isSubmit"></mat-spinner>  
                        {{ "Save" | translate }}
                    </button>
                </form>
            </div>
      </div>
    </div>
    <!-- <div (click)="log()">test</div> -->
</div>