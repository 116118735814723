<mat-dialog-content class="mat-typography">
    <div class="container form_wrapper">
        <div class="title_modal">
            <ng-container *ngIf="data.file_ids.length; else elseTemplateHasSelFiles">
                {{ 'Create a card based on' | translate }} {{data.file_ids.length}} {{ 'selected files' | translate }}
            </ng-container>
            <ng-template #elseTemplateHasSelFiles>
                {{ 'Create a card based on this file' | translate }}
            </ng-template>
        </div>

        <form [formGroup]="form" class="form_with_pads form_with_valids">
            <ng-container *ngIf="taskTemplates && taskTemplates.length > 5; else elseTemplateWithSearchTmpls">
                <mat-form-field appearance="standard" class="full_width_form">
                    <mat-label>{{ "Choose a template" | translate }}</mat-label>
                    <mat-select disableOptionCentering formControlName="id">
                        <mat-option>
                            <ngx-mat-select-search 
                            placeholderLabel="{{ 'Search' | translate }}" 
                            noEntriesFoundLabel="{{ 'no matching template found' | translate }}"
                            [formControl]="taskTemplatesMoreControl"></ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let tmpl of taskTemplates$ | async" [value]="tmpl.id">
                            {{ tmpl.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </ng-container>
            <ng-template #elseTemplateWithSearchTmpls>
                <mat-form-field appearance="standard" class="full_width_form">
                    <mat-label>{{ "Choose a template" | translate }}</mat-label>
                    <mat-select disableOptionCentering formControlName="id">
                        <mat-option *ngFor="let tmpl of taskTemplates" [value]="tmpl.id">
                            {{ tmpl?.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </ng-template>
            
            <mat-form-field appearance="standard" *ngIf="form.get('id').valid" class="full_width_form">
                <ng-container *ngIf="!form.get('name').valid && submited">
                    <div class="req_field"></div>
                    <div class="req_label">
                        <div class="angle"></div>
                        <span>
                            {{ "You need to write here" | translate }}
                        </span>
                    </div>
                </ng-container>
                <mat-label>{{ 'Card title' | translate }}</mat-label>
                <input formControlName="name" matInput placeholder="{{ 'Name' | translate }}">
            </mat-form-field>

            <div class="small_chips" *ngIf="form.get('id').valid">
                <label>{{ "Card type" | translate }}: </label>
                <mat-chip-list [multiple]="false" selectable formControlName="status_id">
                    <ng-container *ngFor="let status of taskStatuses; let i = index">
                        <mat-chip class="small_chip" (click)="selectType(status)" [value]="status?.id">
                            {{ status.name | translate }}
                        </mat-chip>
                    </ng-container>
                    
                </mat-chip-list>
            </div>

            <div class="relations_wrp" [ngClass]="{'opened': relations}">
                <div class="relations_in">
                    <button class="relations" mat-button (click)="!relations ? relationsOpen() : null">{{ "Assign relations to other card(s)" | translate }}</button>
                    <div class="relations_inside">
        
                        <div class="search_type">
                            <mat-button-toggle-group appearance="legacy" [formControl]="typeOfSearchControl">
                                <mat-button-toggle value="Everywhere">Everywhere</mat-button-toggle>
                                <mat-button-toggle value="Custom ID">Custom ID</mat-button-toggle>
                                <mat-button-toggle value="Internal ID">Internal ID</mat-button-toggle>
                                <mat-button-toggle value="Global ID">Global ID</mat-button-toggle>
                                <mat-button-toggle value="Task Name">Task Name</mat-button-toggle>
                            </mat-button-toggle-group>

                            <mat-form-field appearance="legacy" class="ml-1" *ngIf="['Everywhere', 'Custom ID', 'Task Name'].includes(typeOfSearchControl.value)">
                                <mat-label>{{ "Group" | translate }}</mat-label>
                                <mat-select #groupIdContainer [formControl]="groupOfSearchControl">
                                    <mat-option>
                                        <ngx-mat-select-search 
                                        placeholderLabel="{{ 'Search' | translate }}" 
                                        noEntriesFoundLabel="{{ 'no matching group found' | translate }}"
                                        [formControl]="groupMoreControl"></ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option [value]="''">
                                        {{ 'All' | translate }}
                                    </mat-option>
                                    <mat-option *ngFor="let group of groups$ | async" [value]="group.id">
                                        {{ group.name | translate }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <ng-container formGroupName="consist_of_task_id">
                            <mat-form-field appearance="standard" class="full_width_form">
                                <mat-label>{{ "Consist of" | translate }}</mat-label>

                                <div class="chips-boundary consist_boundary">
                                    <mat-chip-list class="sorted_chips without_ordered" #consistOfChipList formControlName="add">
                                        <div class="chips_sorter_container" cdkDropListGroup #consistGroup>
                                          
                                            <div class="chips_drag_placeholder" cdkDropList (cdkDropListEntered)="entered($event, consistGroup, consistPlaceholder)" (cdkDropListDropped)="dropped(consistGroup, consistPlaceholder, form.get('consist_of_task_id').get('add').value)" #consistPlaceholder></div>
    
                                            <div *ngFor="let id of form.get('consist_of_task_id').get('add').value"  
                                            cdkDropList
                                            class="sorted_chips_drop_list"
                                            (cdkDropListEntered)="entered($event, consistGroup, consistPlaceholder)"
                                            (cdkDropListDropped)="dropped(consistGroup, consistPlaceholder, form.get('consist_of_task_id').get('add').value)">
                                                <div cdkDrag cdkDragBoundary=".consist_boundary" class="sorted_chip" (cdkDragReleased)="dragReleased(consistPlaceholder, consistDragPlaceholder)" (contextmenu)="openContext($event, {'id': id, 'form': form, 'control': 'consist_of_task_id'}); $event.preventDefault(); $event.stopPropagation();">
                                                    <div class="sorted_chip_custom_placeholder" #consistDragPlaceholder *cdkDragPlaceholder></div>
                                                    <mat-chip [removable]="true" color="primary" (removed)="onRemoved(id, 'consist_of_task_id')">
                                                        <ng-container *ngIf="findTask(id)">
                                                            {{ findTask(id).name.length > 25 ? (findTask(id).name | slice:0:25) + " ..." : findTask(id).name }} ({{findTask(id)?.custom_id}})
                                                        </ng-container>
                                                        <mat-icon matChipRemove>cancel</mat-icon>
                                                    </mat-chip>
                                                </div>
    
                                            </div>
    
                                            <input class="auto_complete_input"
                                            placeholder="{{ 'Search' | translate }}"
                                            #consistOfInput
                                            [formControl]="consistOfControl"
                                            [matAutocomplete]="consistOfAuto"
                                            [matChipInputFor]="consistOfChipList"
                                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
                                            
                                        </div>
                                    </mat-chip-list>
                                </div>

                                <mat-autocomplete (optionsScroll)="onScroll('consistOfControl')" autoActiveFirstOption #consistOfAuto="matAutocomplete" (optionSelected)="selected($event, consistOfInput, 'consist_of_task_id')">
                                    <ng-container *ngFor="let task of tasks$ | async">
                                        <mat-option *ngIf="!form.get('consist_of_task_id').get('add').value.includes(task.id) && (['Everywhere', 'Custom ID', 'Task Name'].includes(typeOfSearchControl.value) ? (groupOfSearchControl.value == task.group_id || groupOfSearchControl.value == '') : true)" [value]="task.id">
                                            <div class="task_ids_wrp_out">
                                                <div class="task_ids_wrp"> 
                                                    <span class="text_one_line">
                                                        {{ task.name }}
                                                    </span>
                                                    <div class="task_ids">
                                                        {{task.custom_id}} 
                                                        <span>
                                                            <div>{{task.internal_id}}</div>
                                                            <div>{{task.id}}</div>
                                                        </span>
                                                    </div>
                                                </div>

                                                <div class="group text_one_line">
                                                    {{task?.group?.name}}
                                                </div>
                                            </div>
                                        </mat-option>
                                    </ng-container>
                                    
                                </mat-autocomplete>
                            </mat-form-field>
                        </ng-container>
                        
                        <ng-container formGroupName="part_of_task_id">
                            <mat-form-field appearance="standard" class="full_width_form">
                                <mat-label>{{ "Part of" | translate }}</mat-label>

                                <div class="chips-boundary part_boundary">
                                    <mat-chip-list class="sorted_chips without_ordered" #partOfChipList formControlName="add">
                                        <div class="chips_sorter_container" cdkDropListGroup #partGroup>
                                          
                                            <div class="chips_drag_placeholder" cdkDropList (cdkDropListEntered)="entered($event, partGroup, partPlaceholder)" (cdkDropListDropped)="dropped(partGroup, partPlaceholder, form.get('part_of_task_id').get('add').value)" #partPlaceholder></div>
    
                                            <div *ngFor="let id of form.get('part_of_task_id').get('add').value"  
                                            cdkDropList
                                            class="sorted_chips_drop_list"
                                            (cdkDropListEntered)="entered($event, partGroup, partPlaceholder)"
                                            (cdkDropListDropped)="dropped(partGroup, partPlaceholder, form.get('part_of_task_id').get('add').value)">
                                                <div cdkDrag cdkDragBoundary=".part_boundary" class="sorted_chip" (cdkDragReleased)="dragReleased(partPlaceholder, partDragPlaceholder)" (contextmenu)="openContext($event, {'id': id, 'form': form, 'control': 'part_of_task_id'}); $event.preventDefault(); $event.stopPropagation();">
                                                <div class="sorted_chip_custom_placeholder" #partDragPlaceholder *cdkDragPlaceholder></div>
                                                <mat-chip [removable]="true" color="primary" (removed)="onRemoved(id, 'part_of_task_id')">
                                                    <ng-container *ngIf="findTask(id)">
                                                        {{ findTask(id).name.length > 25 ? (findTask(id).name | slice:0:25) + " ..." : findTask(id).name }} ({{findTask(id)?.custom_id}})
                                                    </ng-container>
                                                    <mat-icon matChipRemove>cancel</mat-icon>
                                                </mat-chip>
                                                </div>
    
                                            </div>
    
                                            <input class="auto_complete_input"
                                            placeholder="{{ 'Search' | translate }}"
                                            #partOfInput
                                            [formControl]="partOfControl"
                                            [matAutocomplete]="partOfAuto"
                                            [matChipInputFor]="partOfChipList"
                                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
                                            
                                        </div>
                                    </mat-chip-list>
                                </div>

                                <mat-autocomplete (optionsScroll)="onScroll('partOfControl')" autoActiveFirstOption #partOfAuto="matAutocomplete" (optionSelected)="selected($event, partOfInput, 'part_of_task_id')">
                                    <ng-container *ngFor="let task of tasks$ | async">
                                        <mat-option *ngIf="!form.get('part_of_task_id').get('add').value.includes(task.id) && (['Everywhere', 'Custom ID', 'Task Name'].includes(typeOfSearchControl.value) ? (groupOfSearchControl.value == task.group_id || groupOfSearchControl.value == '') : true)" [value]="task.id">
                                            <div class="task_ids_wrp_out">
                                                <div class="task_ids_wrp"> 
                                                    <span class="text_one_line">
                                                        {{ task.name }}
                                                    </span>
                                                    <div class="task_ids">
                                                        {{task.custom_id}} 
                                                        <span>
                                                            <div>{{task.internal_id}}</div>
                                                            <div>{{task.id}}</div>
                                                        </span>
                                                    </div>
                                                </div>

                                                <div class="group text_one_line">
                                                    {{task?.group?.name}}
                                                </div>
                                            </div>
                                        </mat-option>
                                    </ng-container>
                                    
                                </mat-autocomplete>
                            </mat-form-field>
                        </ng-container>

                        <ng-container formGroupName="related_task_id">
                            <mat-form-field appearance="standard" class="full_width_form">
                                <mat-label>{{ "Related" | translate }}</mat-label>

                                <div class="chips-boundary related_boundary">
                                    <mat-chip-list class="sorted_chips without_ordered" #relatedOfChipList formControlName="add">
                                        <div class="chips_sorter_container" cdkDropListGroup #relatedGroup>
                                          
                                            <div class="chips_drag_placeholder" cdkDropList (cdkDropListEntered)="entered($event, relatedGroup, relatedPlaceholder)" (cdkDropListDropped)="dropped(relatedGroup, relatedPlaceholder, form.get('related_task_id').get('add').value)" #relatedPlaceholder></div>
    
                                            <div *ngFor="let id of form.get('related_task_id').get('add').value"  
                                            cdkDropList
                                            class="sorted_chips_drop_list"
                                            (cdkDropListEntered)="entered($event, relatedGroup, relatedPlaceholder)"
                                            (cdkDropListDropped)="dropped(relatedGroup, relatedPlaceholder, form.get('related_task_id').get('add').value)">
                                                <div cdkDrag cdkDragBoundary=".related_boundary" class="sorted_chip" (cdkDragReleased)="dragReleased(relatedPlaceholder, relatedDragPlaceholder)" (contextmenu)="openContext($event, {'id': id, 'form': form, 'control': 'related_task_id'}); $event.preventDefault(); $event.stopPropagation();">
                                                    <div class="sorted_chip_custom_placeholder" #relatedDragPlaceholder *cdkDragPlaceholder></div>
                                                    <mat-chip [removable]="true" color="primary" (removed)="onRemoved(id, 'related_task_id')">
                                                        <ng-container *ngIf="findTask(id)">
                                                            {{ findTask(id).name.length > 25 ? (findTask(id).name | slice:0:25) + " ..." : findTask(id).name }} ({{findTask(id)?.custom_id}})
                                                        </ng-container>
                                                        <mat-icon matChipRemove>cancel</mat-icon>
                                                    </mat-chip>
                                                </div>
    
                                            </div>
    
                                            <input class="auto_complete_input"
                                            placeholder="{{ 'Search' | translate }}"
                                            #relatedOfInput
                                            [formControl]="relativeControl"
                                            [matAutocomplete]="relatedOfAuto"
                                            [matChipInputFor]="relatedOfChipList"
                                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
                                            
                                        </div>
                                    </mat-chip-list>
                                </div>

                                <mat-autocomplete (optionsScroll)="onScroll('relativeControl')" autoActiveFirstOption #relatedOfAuto="matAutocomplete" (optionSelected)="selected($event, relatedOfInput, 'related_task_id')">
                                    <ng-container *ngFor="let task of tasks$ | async">
                                        <mat-option *ngIf="!form.get('related_task_id').get('add').value.includes(task.id) && (['Everywhere', 'Custom ID', 'Task Name'].includes(typeOfSearchControl.value) ? (groupOfSearchControl.value == task.group_id || groupOfSearchControl.value == '') : true)" [value]="task.id">
                                            <div class="task_ids_wrp_out">
                                                <div class="task_ids_wrp"> 
                                                    <span class="text_one_line">
                                                        {{ task.name }}
                                                    </span>
                                                    <div class="task_ids">
                                                        {{task.custom_id}} 
                                                        <span>
                                                            <div>{{task.internal_id}}</div>
                                                            <div>{{task.id}}</div>
                                                        </span>
                                                    </div>
                                                </div>

                                                <div class="group text_one_line">
                                                    {{task?.group?.name}}
                                                </div>
                                            </div>
                                        </mat-option>
                                    </ng-container>
                                    
                                </mat-autocomplete>
                            </mat-form-field>
                        </ng-container>
                    </div>
                </div>
            </div>
        </form>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <div class="f_w_p_btns">
        <button mat-raised-button (click)="close()">
            {{ "Close" | translate }}
        </button>
        <button class="ml-1 d-f-btn" (click)="save()" mat-raised-button cdkFocusInitial color="primary" [disabled]="isSubmit">
            <mat-spinner class="spin_16 mr-1" *ngIf="isSubmit"></mat-spinner>  
            {{ "Save" | translate }}
        </button>
    </div>
</mat-dialog-actions>


<ng-template #contextMenu let-contextdata>
	<section class="context-menu">
        <div *ngIf="contextdata.control != 'consist_of_task_id'" (click)="moveTo(contextdata, 'consist_of_task_id')">
            <mat-icon class="mr-1">swipe_up_alt</mat-icon>
            {{ 'Move to Consist Of' | translate }}
        </div>
        <div *ngIf="contextdata.control != 'part_of_task_id'" (click)="moveTo(contextdata, 'part_of_task_id')">
            <mat-icon class="mr-1" *ngIf="contextdata.control == 'related_task_id'">swipe_up_alt</mat-icon>
            <mat-icon class="mr-1" *ngIf="contextdata.control == 'consist_of_task_id'">swipe_down_alt</mat-icon>
            {{ 'Move to Part Of' | translate }}
        </div>
        <div *ngIf="contextdata.control != 'related_task_id'" (click)="moveTo(contextdata, 'related_task_id')">
            <mat-icon class="mr-1">swipe_down_alt</mat-icon>
            {{ 'Move to Related' | translate }}
        </div>
        <div class="menu_divider"></div>
        <div (click)="openCard(contextdata)">
            <mat-icon class="mr-1">open_in_new</mat-icon>
            {{ 'Open Card' | translate }}
        </div>
        <div class="menu_divider"></div>
        <div (click)="remove(contextdata)">
            <mat-icon class="mr-1">delete</mat-icon>
            {{ 'Remove' | translate }}
        </div>
	</section>
</ng-template>