import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReplaySubject, forkJoin, of } from 'rxjs';
import { debounceTime, last, map, switchMap, tap } from 'rxjs/operators';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { ScenariosService } from 'src/app/shared/services/rest/scenarios.service';

@Component({
  selector: 'app-add-job-automation',
  templateUrl: './add-job-automation.component.html',
  styleUrls: ['./add-job-automation.component.scss']
})
export class AddJobAutomationComponent extends BaseClass implements OnInit, OnDestroy {
  public scenarioForm: FormGroup;
  public templateForm: FormGroup;
  public host: any = '';
  public imgRoute: any = '';
  public templates: any;
  public activeIndex = new FormControl(0);
  public isSubmit: boolean = false;
  public submited: boolean = false;

  public templatesMoreControl: FormControl = new FormControl();
  public templates$: ReplaySubject<any> = new ReplaySubject<any>(1);
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddJobAutomationComponent>,
    private fb: FormBuilder,
    private sm: StorageManagerService,
    private scenariosService: ScenariosService,
    private layoutService: LayoutService
    ) { super() }

  ngOnInit(): void {
    this.templateForm = this.fb.group({
      company_id: this.data.company.id,
      task_operation_id: this.data.work.id,
      template_id: ''
    })

    this.scenarioForm = this.fb.group({
      company_id: this.data.company.id,
      task_operation_id: this.data.work.id,
      scenario_name: ['', Validators.required],
      scenario_data: this.fb.group({
        status_id: ['', Validators.required],
        operation_id: ['', Validators.required],
        employees_ids: [],
        is_copy_approved_files: 0,
        is_copy_ready_files: 0,
        is_create_discussions: 0,
      }),
      is_active: 1
    })

    this.getAutomationTemplates();
    this.attachSubscriptions(
      this.templatesMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchTemplates(resp))
    )
  }

  getAutomationTemplates() {
    let filter:any = {company_id: this.data.company.id, is_task_operation: 1};
    if (this.data.scenario_name) {
      filter.scenario_name = this.data.scenario_name
    }
    this.attachSubscriptions(
      this.scenariosService.getAutomationTemplates('1', filter, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.scenariosService.getAutomationTemplates(x, filter, '200').pipe(map(u => u.body)))).pipe(
            last(),
            tap(values => {
              this.templates = [].concat(...values)
              console.log("getAutomationTemplates", this.templates)
              this.templates$.next(this.templates.slice());
            }),
          )
        }),
      ).subscribe(resp => {
        console.log("getAutomationTemplates sub", resp);
      })
    )
  }

  onSearchTemplates(resp) {
    if (!this.templates) {
      return;
    }

    if (!resp) {
      this.templates$.next(this.templates.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.templates$.next(
      this.templates.filter(z => z.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  close() {
    this.dialogRef.close({event: "close", data: false});
  }

  toggleChip = (chip: any) => {
    this.templateForm.patchValue({
      template_id: !!chip.id ? chip.id : chip.value
    })
  }

  changeTab(val) {
    this.activeIndex.patchValue(val);
  }

  addScenario() {

  }

  addTemplate() {
    this.attachSubscriptions(
      this.scenariosService.addAutomationScenario(this.templateForm.value, this.data.company.id).pipe(
        switchMap(res => {
          return this.scenariosService.getAutomationTemplate(res.template_id, this.data.company.id).pipe(tap(result => res.template = result), map(() => res))
        }),
        switchMap(res => {
          if (this.data.scenario_name && this.data.scenario_name == 'TaskOperationFilesCollector') {
            return of(res)
          } else {
            return this.scenariosService.tryScenario(res.id).pipe(tap(result => res.tryData = result), map(() => res))
          }
        }),
      ).subscribe(resp => {
        // console.log("this.data", this.data)
        if (this.data.work && this.data.work.automationScenarios) {
          this.data.work.automationScenarios.push(resp);
        }
        console.log("addAutomationScenario", resp);
        this.layoutService.showSnackBar({name: ''}, "Successfully assigned", SnackBarItem)
        this.close();
      }, error => {
        this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
      })
    )
  }

  ngOnDestroy(): void {
    this.clearSubscriptions();
  }
}
