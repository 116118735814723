import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, fromEvent, interval, of, ReplaySubject, Subscription, timer } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, finalize, last, map, switchMap, takeWhile, tap } from 'rxjs/operators';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { AuthService } from 'src/app/shared/services/rest/auth.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { ConnectedAppsService } from 'src/app/shared/services/rest/connected-apps.service';
import { ParametersService } from 'src/app/shared/services/rest/parameters.service';
import { environment } from 'src/environments/environment';
import { GroupCreateComponent } from '../../categorization/groups/group-create/group-create.component';
import { ProfileCreateComponent } from '../profile-create/profile-create.component';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
// import { MY_FORMATS } from 'src/app/components/atTasksDialog/task-profile-add/task-profile-add.component';
import { NgxMatDateAdapter } from '@angular-material-components/datetime-picker';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as moment from 'moment';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { GlobalDataService } from 'src/app/shared/services/common/global-data.service';
import { OpenTaskComponent } from 'src/app/components/workspace-pages/cases/dialogs/open-task/open-task.component';
import { TimeFormatPipe } from 'src/app/shared/pipes/timeFormat';
import { CreateTaskComponent } from 'src/app/components/atTasksDialog/create-task/create-task.component';
import { MobAddTaskComponent } from 'src/app/components/mob-task/mob-add-task/mob-add-task.component';
import { prioritys } from 'src/app/shared/consts/prioritys';

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/DD/YYYY, hh:mm:ss A', // Парсинг с разделителем "/"
  },
  display: {
    dateInput: 'MM/DD/YYYY, hh:mm:ss A', // Отображение с разделителем "/"
    monthYearLabel: 'MMM YYYY', // Отображение месяца и года
    dateA11yLabel: 'MMMM D, YYYY', // Для доступности
    monthYearA11yLabel: 'MMMM YYYY', // Для доступности месяца/года
  },
};

@Component({
  selector: 'app-playlist-assistant',
  templateUrl: './playlist-assistant.component.html',
  styleUrls: ['./playlist-assistant.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ]
})

export class PlaylistAssistantComponent extends BaseClass implements OnInit, OnDestroy {
  public host: any = environment.host;
  public imgRoute: any = '';
  public profileStatuses: any;
  public activeLang: any;
  public user: any = {};
  public company_id: any;
  public company: any;
  public contentTypes: any;
  public result: any;
  // public result: any = {
  //   "id": 17,
  //   "company_id": 18,
  //   "name": "Alex Epic cards creation task at 25.02.2025 23:26",
  //   "channel_id": 16,
  //   "data": {
  //     "type": "task_channel",
  //     "is_not_assigned": 1,
  //     "name": "{video_name}",
  //     "priority": 0,
  //     "group_id": 10,
  //     "status_id": 11,
  //     "is_random_avatar": 1
  //   },
  //   "is_active": 0,
  //   "progress": 100,
  //   "error_msg": "",
  //   "applied_at": 1740518853,
  //   "created_at": 1740518804,
  //   "updated_at": 1740518853
  // };

  public form: FormGroup;

  public is_mobile: boolean = false;
  public isSubmit: boolean = false;
  public isLoadVideos: boolean = false;
  public count:number = 1;
  public crCardIndex:number = 1;
  
  public activeStatus: any = '';
  public typeStatus: any = '';

  private retryCount = 0; // Добавляем счетчик попыток
  private maxRetries = 100; // Максимальное количество попыток

  public index: number = 1;
  public videos: any[] = [];
  
  public prioritys: any = prioritys;
  
  public statuses: any;
  public statuses$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public statusesMoreControl: FormControl = new FormControl();


  public urlsSub: Subscription;
  public outlet: any;
  public outlet_id: any = +this.activatedRoute.snapshot.queryParamMap.get('outlet_id');
  public channel_account_id: any = +this.activatedRoute.snapshot.queryParamMap.get('channel_account_id');
  public external_id: any = this.activatedRoute.snapshot.queryParamMap.get('external_id');
  // public channel_id: any = this.activatedRoute.snapshot.queryParamMap.get('channel_id');
  // public platform_id: number = +this.activatedRoute.snapshot.queryParamMap.get('platform_id');
  // // public group_id: number[] = !!this.activatedRoute.snapshot.queryParamMap.get('group_id') ? this.activatedRoute.snapshot.queryParamMap.get('group_id').split(',').map(Number) : [];
  // // public content_type_id: number = +this.activatedRoute.snapshot.queryParamMap.get('content_type_id');
  // public lang_id: number = +this.activatedRoute.snapshot.queryParamMap.get('lang_id');
  // public sftp_id: any = this.activatedRoute.snapshot.queryParamMap.get('sftp_id');

  // public chToGroups: any[];

  // public expChannels: any = [];
  // public expChannels$: ReplaySubject<any> = new ReplaySubject<any>(1);
  // public expChannelsControl: FormControl = new FormControl();

  
  public groups: any;
  public groups$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public groupsControl: FormControl = new FormControl();

  // public langs: any;
  // public langs$: ReplaySubject<any> = new ReplaySubject<any>(1);
  // public langsControl: FormControl = new FormControl();

  constructor(
      private sm: StorageManagerService,
      private auth: AuthService,
      private companyService: CompanyService,
      private languageService: LanguageService,
      private taskService: TaskService,
      private globalDataService: GlobalDataService,
      private connectedAppsService: ConnectedAppsService,
      private parametersService: ParametersService,
      private fb: FormBuilder,
      private router: Router,
      private _ngx_adapter: NgxMatDateAdapter<any>,
      private dialog: MatDialog,
      private activatedRoute: ActivatedRoute,
      private bottomSheet: MatBottomSheet,
      private layoutService: LayoutService
  ) { super() }

  ngOnInit(): void {
    this.company_id = this.activatedRoute.snapshot.queryParamMap.get('company_id');
    this.outlet_id = +this.activatedRoute.snapshot.queryParamMap.get('outlet_id');
    this.channel_account_id = +this.activatedRoute.snapshot.queryParamMap.get('channel_account_id');
    this.external_id = this.activatedRoute.snapshot.queryParamMap.get('external_id');


    this.attachSubscriptions(
      this.statusesMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchStatuses(resp))
    )

    this.attachSubscriptions(
      this.languageService.getLangData().subscribe(resp => {
        this.activeLang = resp.active;
        
        this._ngx_adapter.setLocale(resp.active);
      })
    )

    this.form = this.fb.group({
      company_id: this.company_id,
      status_id: ['', Validators.required],
      priority: 0,
      group_id: '',
      is_random_avatar: 1,
      name: ['{video_name}', Validators.required],
    })

    this.attachSubscriptions(
      this.groupsControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchGroups(resp))
    )

    // this.attachSubscriptions(
    //   this.langsControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchLangs(resp))
    // )

    this.checkIsMobile();
    this.getCompany();
    this.getTaskStatus();

    this.getImgRoute();
    this.getProfilesStatus();
    this.getGroupsCompany();
    this.getCsrf();
    this.getUser();
    this.onRouteChange();
  }

  onSearchStatuses(resp) {
    if (!this.statuses) {
      return;
    }

    if (!resp) {
      this.statuses$.next(this.statuses.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.statuses$.next(
      this.statuses.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  getTaskStatus() {
    this.attachSubscriptions(
      this.taskService.getTaskStatuses(this.company_id).subscribe(resp => {
        console.log("getTaskStatuses", resp)
        this.statuses = resp;
        this.statuses$.next(this.statuses.slice());
      })
    )
  }

  onInput(event: Event, key, min?, max?): void {
    const inputElement = event.target as HTMLInputElement;
    let value = Number(inputElement.value);
  
    // Если значение меньше min или не является числом, устанавливаем 60
    if (!!min) {
      if (isNaN(value) || value < min) {
        value = min;
      }
    } else {
      if (isNaN(value)) {
        value = this[key];
      }
    }

    if (max && value > max) {
      value = max;
    }
  
    // Обновляем значение в форме
    this[key] = value;
    // this.setToTime();
  }

  goToCards() {
    this.router.navigate(['/tasks'], { queryParams: {company_id: this.company_id, group_id: this.result.data.group_id, task_created_at_from: this.result.created_at, order_by: "updated_desc", count: 1} });
  }



  // onSearchLangs(resp) {
  //   if (!this.langs) {
  //     return;
  //   }

  //   if (!resp) {
  //     this.langs$.next(this.langs.slice());
  //     return;
  //   } else {
  //     resp = resp.toLowerCase();
  //   }

  //   this.langs$.next(
  //     this.langs.filter(b => b.value.toLowerCase().indexOf(resp) > -1)
  //   );
  // }

  onSearchGroups(resp) {
    if (!this.groups) {
      return;
    }

    if (!resp) {
      this.groups$.next(this.groups.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }

    this.groups$.next(
      this.groups.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  openTask(task) {
    let taskData:any = {
      task_id: task.id,
      company: this.company,
      initCompanyId: this.company_id,

    }
    if (task.hasOwnProperty("operations")) {
      taskData.task = task
    }

    const dialogRef = this.dialog.open(OpenTaskComponent, {
      backdropClass: 'backdrop_under_header',
      panelClass: !this.is_mobile ? ['open_task_dialog', 'show_header'] : 'open_task_dialog',
      autoFocus: false,
      data: taskData
    });
  }
  
  goToIndex(val) {
    if (this.index >= val) {
      this.index = val;
    }
  }

  launchCardsCr() {
    console.log("launchCardsCr", this.form.value)
    if (!this.form.valid) {
      this.layoutService.showSnackBar({name: `Form not valid`}, '', SnackBarItem);
      console.log(this.form);
      return
    }

    this.isSubmit = true;
    if (!!!this.form.value.group_id) {
      delete this.form.value.group_id
    }
    
    let sbmtVal = {...this.form.value}
    if (!!sbmtVal.is_random_avatar) {
      sbmtVal.is_random_avatar = 1
    } else {
      sbmtVal.is_random_avatar = 0
    }

  // Есил создаём задачи по всем не добавленным постам
    delete sbmtVal.company_id
    sbmtVal.is_not_assigned = 1;


    // company_id: this.company_id,
    // company: this.company,
    // imgRoute: this.imgRoute,
    // host: this.host,
    // videos: this.videos,
    // basedVideos: true,
    // is_not_assigned: true,
    // typeStatus: this.typeStatus,
    // isMulti: true,
    // newList: true,
    // content_type_id: this.typeStatus,
    // channel: this.outlet,
    // is_alt: true

    sbmtVal.type = "task_channel"
    
    if (!!this.typeStatus) {
      sbmtVal.content_type_id = this.typeStatus
    }

    // if (!!this.data.newList) {
    //   sbmtVal.type = "task_channel"
      
    //   if (!!this.data.content_type_id) {
    //     sbmtVal.content_type_id = this.data.content_type_id
    //   }
    // } else {
    //   if (!!this.data.is_alt) {
    //     sbmtVal.type = 'channel'
    //   }

    //   if (this.data.typeStatus && this.data.typeStatus != 'All') {
    //     sbmtVal[this.data.typeStatus] = 1;
    //   }
    // }



    this.attachSubscriptions(
      this.taskService.createTasks({
        channel_id: this.outlet ? this.outlet.id : '',
        company_id: this.company_id,
        name: `${this.outlet.name} cards creation task at ${moment().format('DD.MM.YYYY HH:mm')}`,
        is_active: 1,
        data: sbmtVal
      }).subscribe(resp => {
        console.log("Tasks for all unassigned posts", resp)
        this.result = resp;
        this.crCardsNext();
        if (this.result.id) {
          this.pingProgress();
        }
        this.isSubmit = false;
        this.layoutService.showSnackBar({name: `Tasks for all unassigned posts`}, "created!", SnackBarItem);
      })
    )
  } 

  pingProgress() {
    interval(750).pipe(
      takeWhile(() => this.result.is_active && this.result.progress < 100 && !this.result.error_msg, true), // true - чтобы первое значение всё равно прошло
      switchMap(() => this.taskService.getCreateTasks(this.company_id, { id: this.result.id })
        .pipe(map(x => x.body))
      )
    ).subscribe(resp => {
      if (resp && resp.length) {
        this.result = resp[0];
      }
    });
  }

  onRouteChange() {
    this.attachSubscriptions(
      this.activatedRoute.queryParams.pipe(
        tap((val) => {
          this.company_id = this.activatedRoute.snapshot.queryParamMap.get('company_id');
        
          this.count = +this.activatedRoute.snapshot.queryParamMap.get('count') || 0;
          this.count++;

          this.index = +this.activatedRoute.snapshot.queryParamMap.get('index') || 1;
          this.channel_account_id = +this.activatedRoute.snapshot.queryParamMap.get('channel_account_id');
          this.outlet_id = +this.activatedRoute.snapshot.queryParamMap.get('outlet_id');
          this.external_id = this.activatedRoute.snapshot.queryParamMap.get('external_id');

          if (!this.outlet_id) {
            this.goToOutlets();
          } else {
            this.getOutlet();
          }

          if (this.index > 1) {
            this.activeStatus = 0
          }
          this.getVideos();

        })
      ).subscribe(res => {
        console.log("company_id", this.company_id);
      })
    )
  }
  getProfilesStatus() {
    this.attachSubscriptions(
      this.globalDataService.getContentStatus().subscribe(resp => {
        console.log("getContentStatus", resp);
        this.profileStatuses = resp.slice();
      })
    )
  }

  getStatusById(id) {
    if (!this.profileStatuses || this.profileStatuses.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.profileStatuses.find(el => el.id == id)
  }

  getNotAssigned() {
    return this.videos.filter(k => !k.task_id)
  }

  goToCreateCards() {
    this.changeActiveStatus(0)
    this.index = 2;
    this.router.navigate(['/outler-cards-assistant'], { queryParams: {company_id: this.company_id, outlet_id: this.outlet_id, channel_account_id: this.channel_account_id, external_id: this.external_id, count: this.count, index: this.index} });
  }

  getVideos() {
    this.isLoadVideos = true;
    let filter:any = {
      channel_id: this.outlet_id
    }
    if (this.activeStatus || this.activeStatus === 0) {
      filter.task_id = this.activeStatus
    }
    if (this.typeStatus) {
      filter.content_type_id = this.typeStatus
    }
    if (this.urlsSub) {
      this.urlsSub.unsubscribe();
    }

    this.urlsSub = this.taskService.getExpandUrls('1', this.company_id, filter, '200').pipe(
      switchMap(el => {
        const totalCount = el.headers.get('x-pagination-total-count');
        const pages = Math.ceil(totalCount / 200);

        if (pages === 0) {
          this.videos = [];
          return of([]);
        }

        const requests = Array.from({ length: pages }, (_, index) =>
          this.taskService.getExpandUrls(index+1, this.company_id, filter, '200').pipe(
            map(u => u.body),
            tap(k => {
              k.forEach(channel => {
                if (channel.taskChannelFiles && channel.taskChannelFiles.length > 0 && !channel.file) {
                  if (channel.taskChannelFiles.filter(x => this.understandFileType(x.originalFile.content_type) == 'video').length > 0) {
                    channel.file = channel.taskChannelFiles.find(x => this.understandFileType(x.originalFile.content_type) == 'video').originalFile;
                  } else {
                    channel.file = channel.taskChannelFiles.filter(x => this.understandFileType(x.originalFile.content_type) != 'audio')[0].originalFile;
                  }
                }
              })
            }),
          )
        );

        return forkJoin(requests).pipe(
          map(responses => [].concat(...responses)),
          tap(conValues => {
            this.videos = conValues;
          }),
          last()
        );
      }),
      finalize(() => {

        this.isLoadVideos = false;
        // if (!this.videos.length && this.retryCount < this.maxRetries) {
        //   this.retryCount++; // Увеличиваем счетчик попыток
        //   timer(2000).subscribe(() => this.getVideos());
        // } else {
        //   this.retryCount = 0; // Сбрасываем счетчик после успешного выполнения или достижения максимума
        // }

        console.log("videos - " + this.retryCount, this.videos)
      })
    ).subscribe()
  }

  understandFileType(val) {
    if (!val) {
      return ""
    }
    
    if (val == 'application/pdf') {
      return 'pdf'
    } else if (val.indexOf("/") != -1) {
      return val.split('/')[0]
    } else {
      return ""
    }
  }

  crCardsNext() {
    this.crCardIndex++;
  }

  crCardsBack() {
    this.crCardIndex++;
  }

  updateVideos(profile) {
    let reqData:any = {
      is_update_data: 1
    }
    // if (this.outlet && this.outlet.platform_id == 2) {
    //   reqData.update_data_list = this.update_data_list
    // }{"company_id":"18","channel_account_id":64,"external_id":"375957242272683"}

    if (!!this.channel_account_id) {
      this.attachSubscriptions(
        this.companyService.editCompanyGroupProfile(profile.id, {company_id: profile.company_id, channel_account_id: 0}).pipe(
          switchMap(res => {
            return this.companyService.editCompanyGroupProfile(profile.id, reqData).pipe(
              catchError(error => {
                let msg = error;
                if (error == "You can update data once per day, please wait.") {
                  msg = msg + " Remaining: " + new TimeFormatPipe().transform(profile.can_update_at)
                }
                this.layoutService.showSnackBar({name: ''}, msg, SnackBarItem)
                return of(res)
              })
            )
          }),
          switchMap(res => {
            return this.companyService.editCompanyGroupProfile(profile.id, {company_id: profile.company_id, channel_account_id: this.channel_account_id, external_id: this.external_id})
          })
        ).subscribe(resp => {
          profile.is_update_data = resp.is_update_data;
          console.log("updateVideos", resp)
        })
      )
    } else {
      this.attachSubscriptions(
        this.companyService.editCompanyGroupProfile(profile.id, reqData).subscribe(resp => {
          profile.is_update_data = resp.is_update_data;
          // if (profile && profile.platform_id == 2) {
          //   this.update_data_list = resp.update_data_list;
          // }
          console.log("updateVideos", resp)
        }, error => {
          let msg = error;
          if (error == "You can update data once per day, please wait.") {
            msg = msg + " Remaining: " + new TimeFormatPipe().transform(profile.can_update_at)
          }
          this.layoutService.showSnackBar({name: ''}, msg, SnackBarItem)
        })
      )
    }
  }


  checkIsMobile() {
    if (window.innerWidth <= 769) {
      this.is_mobile = true;
    } else {
      this.is_mobile = false;
    }
    this.onResize();
  }

  onResize() {
    this.attachSubscriptions(
      fromEvent(window, "resize").pipe(
        map(() => window.innerWidth)
      ).subscribe((wWidth) => {
          if (wWidth <= 769) {
            this.is_mobile = true;
          } else {
            this.is_mobile = false;
          }
        }
      )
    )
  }
  
  changeActiveStatus(val) {
    this.activeStatus = val;
    this.getVideos();
  }

  getOutlet() {
    this.attachSubscriptions(
      this.companyService.getFilteredProfiles(this.company_id, '1', {id: this.outlet_id}).pipe(
        map(el => el.body),
      ).subscribe(resp => {
        if (resp && resp[0]) {
          this.outlet = resp[0]
          
          this.getContentTypes(this.outlet.platform_id)
        }

        console.log("getOutlet", this.outlet)
      })
    )
  }

  createTasksForAll() {
    if (!this.is_mobile) {
      const dialogRef = this.dialog.open(CreateTaskComponent, {
        disableClose: true,
        data: {
          company_id: this.company_id,
          company: this.company,
          imgRoute: this.imgRoute,
          host: this.host,
          videos: this.videos,
          basedVideos: true,
          is_not_assigned: true,
          typeStatus: this.typeStatus,
          isMulti: true,
          newList: true,
          content_type_id: this.typeStatus,
          channel: this.outlet,
          is_alt: true
        }
      });
  
      this.attachSubscriptions(
        dialogRef.afterClosed().subscribe(result => {
          
          if (!!result && result.event == 'Add') {
            this.goToOutlets()
          }
        })
      )
    } else {
      const dialogRef = this.dialog.open(MobAddTaskComponent, {
        backdropClass: ['mob_interface_backdrop'],
        panelClass: ['mob_interface_panel', 'animate__animated', 'animate__slideInUp'],
        disableClose: true,
        data: {
          company_id: this.company_id,
          company: this.company,
          imgRoute: this.imgRoute,
          host: this.host,
          videos: this.videos,
          basedVideos: true,
          is_not_assigned: true,
          isMulti: true,
          newList: true,
          content_type_id: this.typeStatus,
          typeStatus: this.typeStatus,
          channel: this.outlet,
          is_alt: true
        }
      });
  
      this.attachSubscriptions(
        dialogRef.afterClosed().subscribe(result => {
          
          if (!!result && result.event == 'Add') {
            this.goToOutlets()
          }
        })
      )
    }
  }

  changeTypeStatus(val) {
    this.typeStatus = val;
    this.getVideos();
  }

  getContentTypes(platform_id) {
    this.attachSubscriptions(
      this.companyService.getContentTypes(platform_id, '1').pipe(
        map(el => el.body)
      ).subscribe(resp => {
        this.contentTypes = resp;
      })
    )
  }

  goToOutlets() {
    this.router.navigate(['/settings'], { queryParams: {company_id: this.company_id, page: 'profiles'} });
  }


  getGroupsCompany() {
    this.attachSubscriptions(
      this.companyService.getInfiniteGroupsCompany(this.company_id, '1', '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.companyService.getInfiniteGroupsCompany(this.company_id, x).pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        this.groups = [].concat(...res);
        this.groups$.next(this.groups.slice());
      })
    )
  }

  getGroupById(id) {
    if (!this.groups || this.groups.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.groups.find(el => el.id == id)
  }

  getCompany() {
    this.attachSubscriptions(
      this.companyService.getCompany(this.company_id).subscribe(resp => {
        this.companyService.company$.next(resp[0]);
        this.company = resp[0];
      })
    )
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  getCsrf() {
    this.attachSubscriptions(
      this.auth.$userToken.subscribe(resp => {
        this.sm.localStorageSetItem("csrf_param", resp.csrf_param)
        this.sm.localStorageSetItem("csrf_token", resp.csrf_token)
      })
    )
  }

  getUser() {
    this.attachSubscriptions(
      this.auth.$user.subscribe(resp => {
        this.user = resp;
      })
    )
  }

  finish() {
    this.isSubmit = true;
  }

  ngOnDestroy(): void {
    if (this.urlsSub) {
      this.urlsSub.unsubscribe();
    }
    this.clearSubscriptions()
  }

}
