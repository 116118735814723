import { Overlay } from '@angular/cdk/overlay';
import { AfterViewChecked, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild, ViewContainerRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { mimeTypes } from 'mime-wrapper';
import { forkJoin, fromEvent, interval, of, timer } from 'rxjs';
import { catchError, concatMap, filter, finalize, map, switchMap, take, takeWhile, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { filesSort } from '../../functions/filesSort';
import { BaseClass } from '../../models/base-class';
import { StorageManagerService } from '../../services/common/storage-manager.service';
import { AuthService } from '../../services/rest/auth.service';
import { ChatService } from '../../services/rest/chat.service';
import { CompanyService } from '../../services/rest/company.service';
import { FileService } from '../../services/rest/file.service';
import { ParametersService } from '../../services/rest/parameters.service';
import { QuestsService } from '../../services/rest/quests.service';
import { RefreshService } from '../../services/rest/refresh.service';
import { TaskService } from '../../services/rest/task.service';
import { Upload, UploadService } from '../../services/rest/upload.service';
import { DeleteAlertComponent } from '../delete-alert/delete-alert.component';
import { VideoViewerComponent } from '../file-manager/dialog/video-viewer/video-viewer.component';
import { MobFmViewComponent } from '../mob-file-manager/dialogs/mob-fm-view/mob-fm-view.component';
import { SameFilesComponent } from './dialogs/same-files/same-files.component';
import * as CryptoJS from 'crypto-js';
import { FileInput } from 'ngx-material-file-input';
import { LayoutService } from '../../services/common/layout.service';
import { SnackBarItem } from '../snack-bar/snack-bar-item';
@Component({
  selector: 'app-file-interface',
  templateUrl: './file-interface.component.html',
  styleUrls: ['./file-interface.component.scss']
})
export class FileInterfaceComponent extends BaseClass implements OnInit, OnChanges, AfterViewChecked, OnDestroy {
  @ViewChild("filesWrapper") filesWrapper: ElementRef;
  public files: any[] = [];
  public uploadedFiles: any[] = [];
  public upload: Upload | undefined;
  public updatedCount: number = 0;
  public company_id: number;
  public uploadedPage: number = 1;
  public uploadedPagination: any;
  public operationsValues: any;
  public user: any;
  public uploadProgress: any;
  public imgRoute: any;
  public changeLocateFile: any;
  public copiedFiles: any;
  public copiedIds: any;
  public type: FormControl = new FormControl(1)
  @Input() company: any;
  @Input() activeLang: any;
  public throttle = 300;
  public scrollUpDistance = 3.5;
  public host: any = environment.host;
  public is_mobile: boolean = false;
  public completedFiles: boolean = false;
  public isVisible: boolean = true;
  public filesNeedToSetProperties: any[] = [];
  @Input() uploadsIsOpen: boolean;
  @Output() Close = new EventEmitter<any>();
  @Output() Open = new EventEmitter<any>();

  constructor(
    private fileService: FileService,
    private uploads: UploadService,
    private companyService: CompanyService,
    private activatedRoute: ActivatedRoute,
    private auth: AuthService,
    private dialog: MatDialog,
    private taskService: TaskService,
    private refreshService: RefreshService,
    private parametersService: ParametersService,
    private chatService: ChatService,
    private bottomSheet: MatBottomSheet,
    public viewContainerRef: ViewContainerRef,
    public layoutService: LayoutService,
    public overlay: Overlay,
    private questsService: QuestsService,
    private cd: ChangeDetectorRef,
    public sm: StorageManagerService
  ) { super() }

  ngOnInit(): void {
    this.getUser();
    this.getImgRoute();
    this.checkIsMobile();

    if (this.sm.localStorageGetItem("copiedFiles")) {
      this.copiedIds = JSON.parse(this.sm.localStorageGetItem("copiedFiles")).filter(x => x.company_id == this.company_id)

      if (this.copiedIds.length && this.company_id) {
        this.attachSubscriptions(
          this.fileService.getFilesPreview(this.company_id, this.copiedIds.map(y => y.id)).subscribe(res => {
            this.copiedFiles = res.filter(b => b.is_dir == 0)
            console.log("this.copiedIds", this.copiedIds)
            console.log("this.copiedFiles", this.copiedFiles)
          })
        )
      }
    } else {
      this.copiedIds = undefined;
      this.copiedFiles = undefined;
      this.type.patchValue(1);
    }

    this.attachSubscriptions(
      this.uploads.getTryAgainFile().subscribe(resp => {
        if (this.files.find(x => x.data.id == resp.id)) {
          let target = this.files.find(x => x.data.id == resp.id)
          this.tryAgain(target)
        }
      })
    )
    
    this.attachSubscriptions(
      this.uploads.getSameFilesFile().subscribe(resp => {
        if (this.files.find(x => x.data.id == resp.id)) {
          let target = this.files.find(x => x.data.id == resp.id)
          this.openSameFiles(target)
        }
      })
    )

    this.attachSubscriptions(
      this.auth.getUserToken().pipe(
        filter(() => this.checkErrors())
      ).subscribe(resp => {
        console.log("FM getUserToken", resp)
        this.retryAll();
      })
    )

    this.attachSubscriptions(
      this.uploads.getProgressUpload().subscribe(resp => {
        // console.log("this.uploadProgress", this.uploadProgress)
        this.uploadProgress = resp;
      })
    )

    this.attachSubscriptions(
      this.uploads.getCancelFile().subscribe(resp => {
        console.log(this.files);

        if (this.files.find(x => x.data.id == resp.id)) {
          let target = this.files.find(x => x.data.id == resp.id)
          this.cancelUploadField(target)
          
          this.files.splice(this.files.indexOf(target), 1);
        }
        this.uploads.progressUpload$.next({
          uploadedFiles: this.files.filter(x => !x.target.hasOwnProperty("upload") || ['ERROR', 'PENDING', 'IN_PROGRESS'].includes(x.target.upload.state)).length,
          uploadedSize: this.getUploadedSize(this.files.filter(x => !x.target.hasOwnProperty("upload") || ['ERROR', 'PENDING', 'IN_PROGRESS'].includes(x.target.upload.state)))
        })
      })
    )
 
    this.attachSubscriptions(
      interval(5000).pipe(
        filter(() => this.uploadsIsOpen && this.isVisible && this.files.length > 0 && this.files.filter(x => !!x.uploadedFile && (["image", "video"].includes(mimeTypes.getType(x.uploadedFile.content_type)) || ["image", "video"].includes(this.understandFileType(x.uploadedFile.content_type))) && !x.uploadedFile.thumbnail).length > 0),
        switchMap(() => {
          let _files = this.files.filter(x => !!x.uploadedFile && (["image", "video"].includes(mimeTypes.getType(x.uploadedFile.content_type)) || ["image", "video"].includes(this.understandFileType(x.uploadedFile.content_type))) && !x.uploadedFile.thumbnail)
          let filesData = []
          _files.forEach(el => {
            if (filesData.filter(x => x.company_id == el.uploadedFile.company_id).length == 0) {
              filesData.push({
                company_id: el.uploadedFile.company_id,
                ids: [el.uploadedFile.id]
              })
            } else {
              if (!filesData.find(x => x.company_id == el.uploadedFile.company_id).ids.includes(el.uploadedFile.id)) {
                filesData.find(x => x.company_id == el.uploadedFile.company_id).ids.push(el.uploadedFile.id)
              }
            }
          })

          console.log("_files", _files);
          console.log("filesData", filesData);

          // let reqData = [];
          // filesData.forEach(el => {
          //   reqData.push({
          //     "path": '/api/file/',
          //     "query": {
          //       'company_id': el.company_id,
          //     },
          //     "method": "GET",
          //     "body": {[this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token')}
          //   })
          // });
          // console.log("reqData", reqData);
          if (filesData.length > 0) {
            return forkJoin(filesData.map(x => this.fileService.getFilesPreview(x.company_id, x.ids)))
          } else {
            return of([])
          }
        })
      ).subscribe(resp => {
        console.log("preview logic", resp)
        console.log("preview logic files", this.files)

        if (!resp || resp.length == 0) {
          return
        }

        resp.forEach(companyFiles => {
          if (!!companyFiles) {
            companyFiles.forEach(x => {
              if (x.thumbnail && this.files.filter(b => !!b.uploadedFile && b.uploadedFile.id == x.id).length > 0) {
                this.files.find(b => b.uploadedFile.id == x.id).uploadedFile.thumbnail = x.thumbnail;
                this.files.find(b => b.uploadedFile.id == x.id).uploadedFile.thumbnail_filesize = x.thumbnail_filesize;
                
                if (!!x.discussion_id) {
                  if (!!this.files.find(b => b.uploadedFile.id == x.id).posts && this.files.find(b => b.uploadedFile.id == x.id).posts.filter(u => u.id == x.discussion_id).length > 0) {
                    this.files.find(b => b.uploadedFile.id == x.id).posts.find(u => u.id == x.discussion_id).file.thumbnail = x.thumbnail;
                    this.files.find(b => b.uploadedFile.id == x.id).posts.find(u => u.id == x.discussion_id).file.thumbnail_filesize = x.thumbnail_filesize;
                  }
                } else {
                  if (!!this.files.find(b => b.uploadedFile.id == x.id).files && this.files.find(b => b.uploadedFile.id == x.id).files.filter(u => u.id == x.id).length > 0) {
                    this.files.find(b => b.uploadedFile.id == x.id).files.find(u => u.id == x.id).thumbnail = x.thumbnail;
                    this.files.find(b => b.uploadedFile.id == x.id).files.find(u => u.id == x.id).thumbnail_filesize = x.thumbnail_filesize;
                  }
                }
              }
            })
          }
        });
      })
    )

    
    this.attachSubscriptions(
      fromEvent<MouseEvent>(document, 'click')
      .pipe(
        filter(event => {
          const clickTarget = event.target as HTMLElement;
          let check6 = !!document.getElementsByTagName('app-file-interface') && document.getElementsByTagName('app-file-interface').length > 0 && !document.getElementsByTagName('app-file-interface')[0].contains(clickTarget)
          return this.uploadsIsOpen && check6
        })
      ).subscribe(() => this.Close.emit())
    )

    this.attachSubscriptions(
      this.uploads.getUploadState().subscribe(resp => {

        console.log("getUploadState", resp)

        if (this.files.filter(x => x.data.id == resp.data.id).length == 0) {
          return
        }

        let targetFile = this.files.find(x => x.data.id == resp.data.id).target;
        if (resp.parts.filter(x => x.status == "end").length != resp.parts.length) {
          let uploadedSize = (resp.parts.filter(x => x.status == "end").length / resp.parts.length) * (targetFile.fileVal ? targetFile.fileVal.size : targetFile.size)
          targetFile.upload = {
            progress: Math.round((resp.parts.filter(x => x.status == "end").length / resp.parts.length) * 100),
            state: "IN_PROGRESS",
            is_end: false,
            size_is_uploaded: uploadedSize,
            speed: uploadedSize / ((Date.now() - resp.time) / 1000),
            timeEnd: Math.round(targetFile.fileVal ? (resp.time / 1000) + (targetFile.fileVal.size / (uploadedSize / ((Date.now() - resp.time) / 1000))) : (resp.time / 1000) + (targetFile.size / (uploadedSize / ((Date.now() - resp.time) / 1000))))
          }
        } else {
          targetFile.upload = {
            progress: 100,
            state: "DONE",
            is_end: true,
            size_is_uploaded: targetFile.fileVal ? targetFile.fileVal.size : targetFile.size,
            speed: 0,
            timeEnd: null
          }
        }
        this.sendProgress(resp, targetFile);
        this.uploads.progressUpload$.next({
          uploadedFiles: this.files.filter(x => !x.target.hasOwnProperty("upload") || ['ERROR', 'PENDING', 'IN_PROGRESS'].includes(x.target.upload.state)).length,
          uploadedSize: this.getUploadedSize(this.files.filter(x => !x.target.hasOwnProperty("upload") || ['ERROR', 'PENDING', 'IN_PROGRESS'].includes(x.target.upload.state)))
        })
        filesSort(this.files)
      })
    )

    this.resubscribeOnError();
  }

  sendProgress(interfaceData, target) {
    this.attachSubscriptions(
      this.fileService.editFile(interfaceData.data.id, {user_upload_progress: Math.ceil(target.upload.size_is_uploaded), user_upload_speed_mbit: Math.ceil(target.upload.speed)}, interfaceData.company_id).subscribe(resp => {
        console.log("sendProgress", resp)
      }, error => {
        console.log("sendProgress error", error)
      })
    )
  }

  retryAll() {
    this.files.filter(x => !!x.target.upload && x.target.upload.state == 'ERROR').forEach(file => {
      this.tryAgain(file)
    })
  }

  uploadFile(event) {
    console.log(event);
    if (event.target.files.length > 0) {
      let file = event.target.files[0]
      // console.log("@ test 1", file);
      // console.log("@ test 2", this.changeLocateFile);
      if (file.size && this.changeLocateFile.target && this.changeLocateFile.target.size && file.size == this.changeLocateFile.target.size) {
        file.countSameFiles = this.changeLocateFile.target.countSameFiles
        if (this.changeLocateFile.target) {
          if (this.changeLocateFile.target.rangeUpload) {
            file.rangeUpload = this.changeLocateFile.target.rangeUpload
            file.reportsFile = this.changeLocateFile.target.reportsFile
          }
          if (this.changeLocateFile.target.filepath) { 
            file.filepath = this.changeLocateFile.filepath
          }
          if (this.changeLocateFile.target.upload) { 
            file.upload = this.changeLocateFile.target.upload
          }
        }
        file.smartUpload = this.changeLocateFile.target.smartUpload
  
        this.changeLocateFile.target = file;
  
        this.changeLocateFile.target.reportsFile.upload_url.info.forEach((e,i) => {
          const index = parseInt(i)
          const start = index * this.changeLocateFile.target.reportsFile.upload_url.chunk_size
          const end = (index + 1) * this.changeLocateFile.target.reportsFile.upload_url.chunk_size
          const blob = index < this.changeLocateFile.target.reportsFile.upload_url.urls.length
          ? file.slice(start, end)
          : file.slice(start)
          e.file = blob
          if (i == this.changeLocateFile.target.reportsFile.upload_url.info.length - 1) {
            this.tryAgain(file)
          }
        });
      } else {
        this.layoutService.showSnackBar({name: "Files don't match"}, marker("Cancel uploads and upload this file again"), SnackBarItem)
      }
    }
  }

  getNotCompletedFiles() {
    return this.files.filter(u => u.target && u.target.upload && u.target.upload.state == "DONE" && (!u.uploadedFile || u.uploadedFile.is_uploaded == 0)) 
  }

  completeFiles() {
    if (this.completedFiles) {
      return
    }
    
    this.completedFiles = true;
    this.attachSubscriptions(
      forkJoin(this.getNotCompletedFiles().map(file => {
        if (file.data.upload_url.chunks == 1) {
          return this.uploads.completeUpload({id: file.data.upload_url.id}).pipe(
            tap((x:any) => {
              if (!!x.success && file.uploadedFile) {
                file.uploadedFile.is_uploaded = 1;
              }
            })
          )
        } else {
          let data = {
            id: file.data.upload_url.id,
            upload_id: file.data.upload_url.upload_id,
            parts: []
          }

          file.data.upload_url.info.forEach((element, index) => {
            data.parts.push({
              "ETag": element.etag,
              "PartNumber": index + 1
            })
          });

          return this.uploads.completeUpload(data).pipe(
            tap((x:any) => {
              if (!!x.success && file.uploadedFile) {
                file.uploadedFile.is_uploaded = 1;
              }
            })
          )
        }
      })).subscribe(resp => {
        console.log("completeFiles", resp)
        this.completedFiles = false;
      }, error => {
        this.completedFiles = false;
      })
    )
  }

  locateFile(file, input: HTMLInputElement) {
    input.click()
    this.changeLocateFile = file;
  }

  resubscribeOnError() {
    this.attachSubscriptions(
      this.fileService.getfiles().pipe(
        tap(val => {
          console.log("getfiles", val)
        }),
        map(val => {
          console.log("fileService FILEINTERFACE", val)
          if (!val.place || val.place != 'chat') {
            this.Open.emit()
          }
          
          if (this.files.filter(x => x.data.id == val.data.id).length == 0) {
            // this.files.find(x => x.data.id == val.data.id).target.upload = val.target.upload;
            this.files.push(val);
            this.uploads.progressUpload$.next({
              uploadedFiles: this.files.filter(x => !x.target.hasOwnProperty("upload") || ['ERROR', 'PENDING', 'IN_PROGRESS'].includes(x.target.upload.state)).length,
              uploadedSize: this.getUploadedSize(this.files.filter(x => !x.target.hasOwnProperty("upload") || ['ERROR', 'PENDING', 'IN_PROGRESS'].includes(x.target.upload.state)))
            })
            filesSort(this.files)
            return val
          } else {
            this.uploads.progressUpload$.next({
              uploadedFiles: this.files.filter(x => !x.target.hasOwnProperty("upload") || ['ERROR', 'PENDING', 'IN_PROGRESS'].includes(x.target.upload.state)).length,
              uploadedSize: this.getUploadedSize(this.files.filter(x => !x.target.hasOwnProperty("upload") || ['ERROR', 'PENDING', 'IN_PROGRESS'].includes(x.target.upload.state)))
            })
            filesSort(this.files)
            return this.files.find(x => x.data.id == val.data.id)
          }

        }),
        concatMap(resp => {
          console.log("concatMap 1 fileService", resp, !!resp.target.upload && resp.target.upload.state == "DELETED")
          if (!!resp.target.upload && resp.target.upload.state == "DELETED") {
            console.log("IN")
            return of("null")
          } else {
            return (((resp.place == 'file_manager' || !['image.png', 'изображение.png'].includes(resp.target.name)) && !!resp.company && resp.place != 'app_form') ? this.fileService.getFileData("1", resp.company_id, {'location_all': "/", task_id: 0, filename: !!resp.target ? resp.target.name : (resp.data.fileVal ? resp.data.fileVal.name : resp.data.filename), filesize: resp.data.fileVal ? resp.data.fileVal.size : resp.data.filesize, is_uploaded: 1}) : of(resp)).pipe(
              tap(el => {
                if (el.headers) {
                  resp.countSameFiles = el.headers.get('x-pagination-total-count');
                  resp.target.countSameFiles = el.headers.get('x-pagination-total-count');
                  resp.sameFiles = el.body;
                  if (resp.sameFiles.find(x => x.id == resp.data.id)) {
                    resp.countSameFiles = resp.countSameFiles - 1;
                    resp.target.countSameFiles = resp.target.countSameFiles - 1;
                    resp.sameFiles.splice(resp.sameFiles.findIndex(x => x.id == resp.data.id), 1);
                  }
                  resp.sameOriginals = resp.sameFiles.filter(x => !x.original_file_id || x.original_file_id == x.id)
                  resp.countSameOriginals = resp.sameOriginals.length
                }
              }),
              map(() => resp),
              tap(resp => {
                if (resp.countSameFiles > 0 && !resp.data.tryAgain) {
                  console.log("switchMap INSIDE in", resp)
                  resp.skipSwitchMap = true;
                } else {
                  resp.skipSwitchMap = false;
                }
              }),
              switchMap(resp => {
                if (!!resp.skipSwitchMap) {
                  return of(resp)
                }
                console.log("switchMap INSIDE LAST SWITCH", resp);

                console.log("canReady this.files", this.files)
                if (resp.data.upload_url.chunks == 1) {
                  resp.smartUpload = false;
                  return this.uploads.upload(resp.target, resp.target.fileVal ? resp.target.fileVal : resp.target, resp.data.upload_url.urls[0], this.files).pipe(
                    switchMap(upload => {
  
                      if (upload == 'Unknown Error') {
                        resp.target.upload = {
                          progress: 0,
                          state: 'ERROR',
                          is_end: false,
                          size_is_uploaded: 0,
                          speed: 0,
                          timeEnd: null
                        }
                        this.uploads.progressUpload$.next({
                          uploadedFiles: this.files.filter(x => !x.target.hasOwnProperty("upload") || ['ERROR', 'PENDING', 'IN_PROGRESS'].includes(x.target.upload.state)).length,
                          uploadedSize: this.getUploadedSize(this.files.filter(x => !x.target.hasOwnProperty("upload") || ['ERROR', 'PENDING', 'IN_PROGRESS'].includes(x.target.upload.state)))
                        })
                        filesSort(this.files)
                        return of(upload)
                      }
                      resp.target.upload = upload
                      this.uploads.progressUpload$.next({
                        uploadedFiles: this.files.filter(x => !x.target.hasOwnProperty("upload") || ['ERROR', 'PENDING', 'IN_PROGRESS'].includes(x.target.upload.state)).length,
                        uploadedSize: this.getUploadedSize(this.files.filter(x => !x.target.hasOwnProperty("upload") || ['ERROR', 'PENDING', 'IN_PROGRESS'].includes(x.target.upload.state)))
                      })
                      filesSort(this.files)
                      if (resp.target.upload.state == "DONE") {
                        return this.uploads.completeUpload({id: resp.data.upload_url.id}).pipe(
                          tap((x) => {
                            this.updatedCount = this.updatedCount + 1;
                          }),
                          catchError(err => {
                            return of(err)
                          }),
                          switchMap((val) => {
                            if (resp.folder && resp.folder.is_folder) {
                              this.refreshService.refreshFiles$.next(resp);
                              return of('is_folder')
                            }
                            if (resp.place == 'work_status') {
                              return of(resp.place)
                            }
                            if (resp.place == 'edit_company') {
                              return this.companyService.getCompany(resp.data.id).pipe(
                                map(x => x[0]),
                                catchError(err => {
                                  return of(err)
                                })
                              )
                            }
                            if (resp.posts) {
                              return this.chatService.getTargetPost(resp.chat.id, resp.post.id, resp.company_id).pipe(
                                tap(x => {
                                  resp.user.name = resp.user.display_name
                                  x[0].discussionPostFiles[resp.index].file.createdEmployee = resp.user;
                                  x[0].discussionPostFiles[resp.index].file.user = resp.user;
                                  resp.uploadedFile = x[0].discussionPostFiles[resp.index].file;
                                  resp.post.discussionPostFiles[resp.index].file.thumbnail = undefined;
                                  resp.post.discussionPostFiles[resp.index].file.original = undefined;
                                  setTimeout(() => {
                                    resp.post.discussionPostFiles[resp.index].file.is_uploaded = 1;
                                    resp.post.discussionPostFiles[resp.index].file.thumbnail = x[0].discussionPostFiles[resp.index].file.thumbnail
                                    resp.post.discussionPostFiles[resp.index].file.original = x[0].discussionPostFiles[resp.index].file.original
                                  }, 0)
                                }),
                                map(x => x[0]),
                                catchError(err => {
                                  return of(err)
                                })
                              )
                            }
                            if (resp.files) {
                              return this.fileService.getTargetFile(resp.data.upload_url.id, resp.company_id).pipe(
                                tap(x => {
                                  resp.user.name = resp.user.display_name
                                  x.createdEmployee = resp.user
                                  x.user = resp.user
                                  resp.uploadedFile = x
                                  console.log("resp.uploadedFile", resp)
                                }),
                                catchError(err => {
                                  return of(err)
                                })
                              )
                            } else {
                              return this.questsService.getQuestEmpl(resp.company_id, {id: resp.target.id}, '1').pipe(
                                map(x => x.body[0]),
                                catchError(err => {
                                  return of(err)
                                })
                              );
                            }
                          }),
                          switchMap(x => {
                            console.log("AFTER UPLOAD 1", resp, x)
                            if (resp.files && resp.target.afterUploadData) {
                              return this.fileService.editFile(resp.data.upload_url.id, {is_ready_if_approved: !!resp.target.afterUploadData.is_ready_if_approved ? 1 : 0, operation_reminder_id: resp.target.afterUploadData.operation_reminder_id}, resp.company_id).pipe(
                                tap(res => {
                                  x.is_ready_if_approved = resp.target.afterUploadData.is_ready_if_approved;
                                  x.operation_reminder_id = resp.target.afterUploadData.operation_reminder_id;
                                  if (!!resp.target.selectedCheck) {
                                    x.selectedCheck = resp.target.selectedCheck
                                    x.operation_reminder_name = resp.target.selectedCheck.text;
                                  }
                                  // delete resp.target.afterUploadData
                                }),
                                switchMap(x => {
                                  if (resp.target.afterUploadData.close_reminder) {
                                    return this.taskService.createOperationReminderStatus(resp.work.id, resp.target.afterUploadData.operation_reminder_id, resp.company_id).pipe(
                                      tap(val => {
                                        resp.target.selectedCheck.status = val
                                      }),
                                      map(() => x)
                                    )
                                  } else {
                                    return of(x);
                                  }
                                }),
                              )
                            } else {
                              return of(x)
                            }
                          }),
                          tap((x) => {
                            if (x != 'is_folder') {
                              if (resp.place == 'work_status') {
                                return of("end" + resp.place)
                              }
                              if (resp.place == 'edit_company') {
                                resp.data.avatarFile = x.avatarFile;
                              }
                              if (resp.files) {
                                console.log("x before кккpush", x, resp.files.filter(u => u.id == x.id), resp)
                                if (resp.files.filter(u => u.id == x.id).length == 0) {
                                  console.log("кккpush 1")
                                  resp.files.push(x)
                                } else {
                                  console.log("кккpush 2")
                                  resp.files.splice(resp.files.findIndex(u => u.id == x.id), 1, x)
                                }
                                console.log("after кккpush", resp.files)
                              } else {
                                if (x.file) {
                                  resp.target.file = x.file;
                                }
                                if (x.user) {
                                  x.user.name = x.user.display_name
                                  resp.target.createdEmployee = x.user;
                                  resp.target.user = x.user;
                                }
                              }
                            }
                          }),
                          switchMap((x) => {
                            // console.log(resp.work_id);
                            if (resp.work_id && resp.work_id != 0 && (resp.location.indexOf('to_approve') != -1 || resp.location.indexOf('ready_files') != -1)) {
                              let postBody:any = {};
                              if (resp.location.indexOf("to_approve") != -1) {
                                postBody.is_to_approve_files = 1
                              }
                              if (resp.location.indexOf("ready_files") != -1) {
                                postBody.is_approved_files = 1
                              }
                              return this.taskService.editWork(resp.work_id, postBody, resp.company_id)
                            } else {
                              return of("end")
                            }
                          })
                        )
                      } else {
                        return of(upload)
                      }
                    }),
                  )
                } else {
                  console.log("else", resp.data.upload_url);
                  console.log("else2", resp);
                  if (!resp.target.upload || !(resp.target.upload.progress > 0)) {
                    resp.target.upload = {
                      progress: 0,
                      state: 'IN_PROGRESS',
                      is_end: false,
                      size_is_uploaded: 0,
                      speed: 0,
                      timeEnd: null
                    }
                  }
                  if (!!this.sm.localStorageGetItem("smartUpload") || resp.place == 'chat') {
                    resp.smartUpload = true;
                    resp.target.smartUpload = true;
                    return this.uploads.smartMultiUpload(resp?.is_chat ? resp.target.fileVal : resp.target, resp.data.upload_url, resp.target, resp.data, resp, this.files).pipe(
                      takeWhile(() => resp.data.upload_url.info.filter(x => x.status == 'end').length != resp.data.upload_url.info.length && !resp.data.upload_url.info.filter(x => x.status == 'error').length),
                    )
                  } else {
                    resp.smartUpload = false;
                    resp.target.smartUpload = false;
                    return this.uploads.multiUploadOneByOne(resp?.is_chat ? resp.target.fileVal : resp.target, resp.data.upload_url, resp.target, resp.data).pipe(
                      switchMap((upload) => {
                        console.log("upload", upload)
                        
                        if (!upload.includes("Unknown Error") && resp.data.upload_url.info && resp.data.upload_url.info.length == resp.data.upload_url.info.filter(u => !!u.etag).length) {
                          let data = {
                            id: resp.data.upload_url.id,
                            upload_id: resp.data.upload_url.upload_id,
                            parts: []
                          }
                          resp.data.upload_url.info.forEach((element, index) => {
                            data.parts.push({
                              "ETag": element.etag,
                              "PartNumber": index + 1
                            })
                          });

                          return this.uploads.completeUpload(data).pipe(
                            tap((x) => {
                              console.log("###1")
                              this.updatedCount = this.updatedCount + 1;
                            }),
                            switchMap((val) => {
                              if (resp.folder && resp.folder.is_folder) {
                                this.refreshService.refreshFiles$.next(resp);
                                return of('is_folder')
                              }
                              if (resp.place == 'work_status') {
                                return of(resp.place)
                              }
                              if (resp.place == 'edit_company') {
                                return this.companyService.getCompany(resp.data.id).pipe(
                                  map(x => x[0])
                                )
                              }
                              if (resp.posts) {
                                return this.chatService.getTargetPost(resp.chat.id, resp.post.id, resp.company_id).pipe(
                                  tap(x => {
                                    resp.user.name = resp.user.display_name
                                    x[0].discussionPostFiles[resp.index].file.createdEmployee = resp.user;
                                    x[0].discussionPostFiles[resp.index].file.user = resp.user;
                                    resp.uploadedFile = x[0].discussionPostFiles[resp.index].file;
                                    resp.post.discussionPostFiles.forEach(x => {
                                      x.thumbnail = x.thumbnail
                                      x.original = x.original
                                    })
                                  }),
                                  map(x => x[0])
                                )
                              }
                              if (resp.files) {
                                return this.fileService.getTargetFile(resp.data.upload_url.id, resp.company_id).pipe(
                                  tap(x => {
                                    resp.user.name = resp.user.display_name
                                    x.createdEmployee = resp.user;
                                    x.user = resp.user;
                                    resp.uploadedFile = x
                                  })
                                )
                              } else {
                                return this.questsService.getQuestEmpl(resp.company_id, {id: resp.target.id}, '1').pipe(
                                  map(x => x.body[0]),
                                );
                              }
                            }),
                            switchMap(x => {
                              console.log("AFTER UPLOAD 2", resp, x)
                              if (resp.files && resp.target.afterUploadData) {
                                return this.fileService.editFile(resp.data.upload_url.id, {is_ready_if_approved: !!resp.target.afterUploadData.is_ready_if_approved ? 1 : 0, operation_reminder_id: resp.target.afterUploadData.operation_reminder_id}, resp.company_id).pipe(
                                  tap(res => {
                                    x.is_ready_if_approved = resp.target.afterUploadData.is_ready_if_approved;
                                    x.operation_reminder_id = resp.target.afterUploadData.operation_reminder_id;
                                    if (!!resp.target.selectedCheck) {
                                      x.selectedCheck = resp.target.selectedCheck
                                      x.operation_reminder_name = resp.target.selectedCheck.text;
                                    }
                                    // delete resp.target.afterUploadData
                                  }),
                                  switchMap(x => {
                                    if (resp.target.afterUploadData.close_reminder) {
                                      return this.taskService.createOperationReminderStatus(resp.work.id, resp.target.afterUploadData.operation_reminder_id, resp.company_id).pipe(
                                        tap(val => {
                                          resp.target.selectedCheck.status = val
                                        }),
                                        map(() => x)
                                      )
                                    } else {
                                      return of(x);
                                    }
                                  }),
                                )
                              } else {
                                return of(x)
                              }
                            }),
                            tap((x) => {
                              if (x != 'is_folder') {
                                console.log("###2")
                                if (resp.place == 'edit_company') {
                                  resp.data.avatarFile = x.avatarFile;
                                }
                                if (resp.files) {
                                  if (resp.files.filter(u => u.id == x.id).length == 0) {
                                    resp.files.push(x)
                                  } else {
                                    resp.files.splice(resp.files.findIndex(u => u.id == x.id), 1, x)
                                  }
                                } else {
                                  if (x.file) {
                                    resp.target.file = x.file;
                                  }
                                  if (x.user) {
                                    x.user.name = x.user.display_name;
                                    resp.target.createdEmployee = x.user;
                                  }
                                }
                              }
                            }),
                            finalize(() => {
                              resp.target.upload = {
                                progress: 100,
                                state: 'DONE',
                                is_end: true,
                                size_is_uploaded: resp.target.fileVal ? resp.target.fileVal.size : resp.target ? resp.target.size : 0,
                                speed: 0,
                                timeEnd: null
                              }
                            }),
                            switchMap((x) => {
                              if (resp.work_id && resp.work_id != 0 && (resp.location.indexOf('to_approve') != -1 || resp.location.indexOf('ready_files') != -1)) {
                                let postBody:any = {};
                                if (resp.location.indexOf("to_approve") != -1) {
                                  postBody.is_to_approve_files = 1
                                }
                                if (resp.location.indexOf("ready_files") != -1) {
                                  postBody.is_approved_files = 1
                                }
                                return this.taskService.editWork(resp.work_id, postBody, resp.company_id)
                              } else {
                                return of(x)
                              }
                            }),
                          );
                        } else {
                          if (resp.data.upload_url.info) {
                            resp.data.upload_url.info.forEach(el => {
                              if (!el.etag) {
                                el.status = 'error'
                              }
                            })
                          }
                          
                          resp.target.upload.state = "ERROR"
                          return of(upload);
                        }
        
                      }),
                      catchError(err => {
                        console.log("resp", resp);
                        console.log("this.files", this.files);
                        console.log("err", err)
                        return of(err)
                      }),
                    )
                    } 
                }
              }),
              catchError(err => {
                resp.target.upload = {
                  progress: 0,
                  state: 'ERROR',
                  is_end: false,
                  size_is_uploaded: 0,
                  speed: 0,
                  timeEnd: null
                }
                return of(err)
              }),
            )
          }
        }),
      ).subscribe({
        next: (next) => {
          // console.log("next Fin", next);
        },
        complete: () => {
          console.log("END Fin");
          console.log(this.files)
          this.resubscribeOnError();
        },
        error: (error) => {
          console.log("error Fin", error)
          this.files.filter(x => !x.target.upload).forEach(el => {
            el.target.upload = {
              progress: 0,
              state: 'ERROR',
              is_end: false,
              size_is_uploaded: 0,
              speed: 0,
              timeEnd: null
            }
          })
          this.resubscribeOnError();
        }
      })
    )
  }

  checkErrors() {
    return this.files.filter(x => x.target && x.target.upload && x.target.upload.state == 'ERROR').length > 0
  }

  clearBuffer() {
    this.copiedIds = undefined;
    this.copiedFiles = undefined;
    this.type.patchValue(1);
    localStorage.removeItem('copiedFiles')
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log("FileInterfaceComponent --- changes", changes)
    if (!!changes.uploadsIsOpen && changes.uploadsIsOpen.currentValue == true && changes.uploadsIsOpen.previousValue == false) {
      if (!!this.activatedRoute.snapshot.queryParamMap.get('company_id')) {

        if (this.sm.localStorageGetItem("copiedFiles")) {
          this.copiedIds = JSON.parse(this.sm.localStorageGetItem("copiedFiles")).filter(x => x.company_id == +this.activatedRoute.snapshot.queryParamMap.get('company_id'))
          if (this.copiedIds.length) {
            this.attachSubscriptions(
              this.fileService.getFilesPreview(+this.activatedRoute.snapshot.queryParamMap.get('company_id'), this.copiedIds.map(y => y.id)).subscribe(res => {
                this.copiedFiles = res
                console.log("this.copiedIds", this.copiedIds)
                console.log("this.copiedFiles", this.copiedFiles)
              })
            )
          }
        } else {
          this.copiedIds = undefined;
          this.copiedFiles = undefined;
          this.type.patchValue(1);
        }

        if (this.company_id != +this.activatedRoute.snapshot.queryParamMap.get('company_id')) {
          this.company_id = +this.activatedRoute.snapshot.queryParamMap.get('company_id');
          this.filesWrapper.nativeElement.scrollTo(0, 0);
          this.uploadedPage = 1;
          this.uploadedFiles = [];
          this.uploadedPagination = undefined;
          this.taskService.getOperations(this.company_id, this.activeLang).pipe(
            take(1)
          ).subscribe(resp => {
            this.operationsValues = resp;
          })
          this.getUploadedFiles(this.uploadedPage);
        }
      } else {
        this.uploadedPage = 1;
        this.uploadedFiles = [];
        this.uploadedPagination = undefined;
      }
    }
  }

  onDown(e) {
    if (+this.uploadedPagination['pageCount'] >= this.uploadedPage) {
      this.getUploadedFiles(this.uploadedPage)
    }
  }

  getUploadedFiles(n) {
    this.fileService.getUploadedFiles(this.company_id, n, '/').pipe(
      tap(el => {
        this.uploadedPagination = {
          'pageCount': el.headers.get('x-pagination-page-count'),
          'perPage': el.headers.get('x-pagination-per-page'),
          'totalCount': el.headers.get('x-pagination-total-count'),
          'currentPage': el.headers.get('x-pagination-current-page'),
        }
      }),
      map(el => el.body)
    ).subscribe(resp => {
      resp.forEach(el => {
        if (this.files.filter(x => (!!x.data.discussion_id ? (x.data.id == el.id) : (x.data.id == el.id))).length == 0 && this.uploadedFiles.filter(x => x.id == el.id).length == 0) {
          this.uploadedFiles.push(el)
        }
      })
      console.log("getUploadedFiles", this.uploadedPage, this.uploadedFiles)
      this.uploadedPage = this.uploadedPage + 1;
    })
  }

  getUser() {
    this.attachSubscriptions(
      this.auth.$user.subscribe(resp => {
        this.user = resp;
      })
    )
  }

  getUploadedSize(arr) {
    let size = 0;
    arr.forEach(x => {
      if (x.target.hasOwnProperty("upload")) {
        size = +size + (+x.target.size - +x.target.upload.size_is_uploaded)
      } else {
        size = +size + +x.target.size
      }
    })

    return size
  }

  viewUploadedFile(file) {
    console.log(file);
    if (!file.is_uploaded) {
      return
    }

    if (!!file.discussion_id) {
      if (!this.is_mobile) {
        const dialogRef = this.dialog.open(VideoViewerComponent, {
          panelClass: 'video_viewer',
          autoFocus: false,
          data: {
            file: file,
            company: this.company,
            initCompanyId: file.company_id,
          }
        });
      } else {
        const dialogRef = this.dialog.open(MobFmViewComponent, {
          backdropClass: ['mob_video_viewer_backdrop'],
          panelClass: 'mob_video_viewer',
          autoFocus: false,
          data: {
            file: file,
            company: this.company,
            initCompanyId: file.company_id,
          }
        });
      }
    } else {
      if (!this.is_mobile) {
        const dialogRef = this.dialog.open(VideoViewerComponent, {
          panelClass: 'video_viewer',
          autoFocus: false,
          data: {
            file: file,
            task: file.task,
            work: file.taskOperation,
            operationsValues: file.operationsValues,
            company: this.company,
            initCompanyID: file.company_id,
            activeLang: this.activeLang,
            user: this.user
          }
        });
      } else {
        const dialogRef = this.dialog.open(MobFmViewComponent, {
          backdropClass: ['mob_video_viewer_backdrop'],
          panelClass: 'mob_video_viewer',
          autoFocus: false,
          data: {
            file: file,
            task: file.task,
            work: file.taskOperation,
            operationsValues: file.operationsValues,
            company: this.company,
            initCompanyID: file.company_id,
            activeLang: this.activeLang,
            user: this.user
          }
        });
      }
    }

    this.close()
  }

  viewFile(file) {
    console.log(file)
    if (!!file.uploadedFile) {
      this.Close.emit()
      if (!!file.task && !!file.work) {
        if (!this.is_mobile) {
          const dialogRef = this.dialog.open(VideoViewerComponent, {
            panelClass: 'video_viewer',
            autoFocus: false,
            data: {
              file: file.uploadedFile,
              task: file.task,
              work: file.work,
              operationsValues: file.operationsValues,
              company: file.company,
              initCompanyID: file.company.id,
              activeLang: file.activeLang,
              user: this.user
            }
          });
        } else {
          const dialogRef = this.dialog.open(MobFmViewComponent, {
            backdropClass: ['mob_video_viewer_backdrop'],
            panelClass: 'mob_video_viewer',
            autoFocus: false,
            data: {
              file: file.uploadedFile,
              task: file.task,
              work: file.work,
              operationsValues: file.operationsValues,
              company: file.company,
              initCompanyID: file.company.id,
              activeLang: file.activeLang,
              user: this.user
            }
          });
        }
      } else {
        if (!this.is_mobile) {
          const dialogRef = this.dialog.open(VideoViewerComponent, {
            panelClass: 'video_viewer',
            autoFocus: false,
            data: {
              file: file.uploadedFile,
              company: file.company,
              initCompanyId: file.company.id,
            }
          });
        } else {
          const dialogRef = this.dialog.open(MobFmViewComponent, {
            backdropClass: ['mob_video_viewer_backdrop'],
            panelClass: 'mob_video_viewer',
            autoFocus: false,
            data: {
              file: file.uploadedFile,
              company: file.company,
              initCompanyId: file.company.id,
            }
          });
        }
      }
    }
  }

  checkIsMobile() {
    if (window.innerWidth <= 769) {
      this.is_mobile = true;
    } else {
      this.is_mobile = false;
    }
    this.onResize();
  }

  onResize() {
    this.attachSubscriptions(
      fromEvent(window, "resize").pipe(
        map(() => window.innerWidth)
      ).subscribe((wWidth) => {
          if (wWidth <= 769) {
            this.is_mobile = true;
          } else {
            this.is_mobile = false;
          }
        }
      )
    )
  }

  ngAfterViewChecked() {
    this.cd.detectChanges();
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  onImgError(event){
    event.target.src = this.imgRoute+'/assets/img/image_black_48dp.svg'
  }

  selectVersion(e) {
    console.log(e)
    this.sm.localStorageSetItem("smartUpload", e.target.value == 1 ? 1 : '')
  }
  
  selectType(e) {
    console.log(e)
    console.log("this.type.value", this.type.value)
  }
  
  checkFile(file) {
    console.log("file", file)
    console.log("upload", file.target.upload)
    console.log("files", this.files)
  }

  understandFileType(val) {
    if (!val) {
      return ""
    }
    
    if (val == 'application/pdf') {
      return 'pdf'
    } else if (val.indexOf("/") != -1) {
      return val.split('/')[0]
    } else {
      return ""
    }
  }

  sortFiles() {
    filesSort(this.files)
  }

  // tryToEdit(file) {
  //   this.attachSubscriptions(
  //     this.fileService.editFile(file.data.id, {filename: "some name.svg"}).subscribe(resp => {
  //       file.data.filename = resp.filename;
  //       // file.target.name = resp.filename;
  //       if (file.files.find(x => x.id == file.data.id)) {
  //         file.files.find(x => x.id == file.data.id).filename = resp.filename;
  //       }
  //       console.log(file);
  //     })
  //   )
  // }

  changeNeededFiles(e, file) {
    if (!e.value) {
      this.attachSubscriptions(
        this.taskService.createOperationReminderStatus(file.data.task_operation_id, file.target.props.selectedCheck.id, file.company_id).subscribe(resp => {
          file.target.props.selectedCheck.status = resp;
          console.log(file)
        })
      )
    } else {
      this.attachSubscriptions(
        this.taskService.deleteOperationReminderStatus(file.target.props.selectedCheck.status.id).subscribe(resp => {
          file.target.props.selectedCheck.status = resp
        })
      )
    }
    console.log("AFTER", file)
  }

  toggleFileOptions(file) {
    this.files.forEach(f => {
      if (f.data.id != file.data.id) {
        f.showOptions = false;
      }
    })

    file.showOptions = !file.showOptions;

    if (!file.showOptions) {
      return
    }

    if (file.target.props) {
      if (!file.target.props.operation_reminder_id) {
        file.target.props.operation_reminder_id = 0;
      }
  
      if (file.files && file.files.find(x => x.id == file.data.id)) {
        if (!!file.files.find(x => x.id == file.data.id).operation_reminder_id) {
          file.target.props.operation_reminder_id = file.files.find(x => x.id == file.data.id).operation_reminder_id
          file.target.props.operation_reminder_name = file.files.find(x => x.id == file.data.id).operation_reminder_name
        }
        file.target.props.is_ready_if_approved = file.files.find(x => x.id == file.data.id).is_ready_if_approved
      }
      
      if (!!file.work && (!file.work.checklists || file.work.checklists.length == 0)) {
        this.attachSubscriptions(
          this.taskService.getOperationReminder(file.company_id, {task_operation_id: file.work_id}).pipe(
            map(x => x.body),
            switchMap(val => {
              let tagsFilter:any = {task_id: file.task_id, task_operation_id: file.work_id}

              if (file.company_id != this.company_id) {
                tagsFilter.partner_company_id = file.company_id
              }
              return this.parametersService.getTargetParameters(this.company_id || file.company_id, tagsFilter).pipe(
                map(b => {
                  return {
                    checklist: val,
                    jobParameters: b
                  }
                })
              )
            })
          ).subscribe(resp => {
            console.log("getOperationReminder", resp);
            // file.work.checklists = resp;
  
            let __checklist = resp.checklist;
            let jobParameters = resp.jobParameters
            file.work.checklists = [];
            __checklist.forEach(element => {
              if (element.exceptParameterValues.length > 0) {
                if (element.exceptParameterValues.map(b => b.parameter_value_id).every(n => !jobParameters.map(m => m.parameter_value_id).includes(n))) {
                  file.work.checklists.push(element)
                }
              } else if (element.toParameterValues.length > 0) {
                if (element.toParameterValues.map(b => b.parameter_value_id).some(n => jobParameters.map(m => m.parameter_value_id).includes(n))) {
                  file.work.checklists.push(element)
                }
              } else {
                file.work.checklists.push(element)
              }
            });
        
            if (file.work.checklists.length == 0 && file.work.checklists.filter(b => !!b.status).length == file.work.checklists.length) {
              file.hasChecklistError = true;
            } else {
              if (!!file.data.operation_reminder_id && file.work.checklists.find(x => x.id == file.data.operation_reminder_id)) {
                file.target.props.selectedCheck = file.work.checklists.find(x => x.id == file.data.operation_reminder_id)
              }
            }
  
          })
        )
      }
    }

    console.log(file)
  }

  

  changeCheckList(e, file) {
    console.log(e.target.value);
    this.attachSubscriptions(
      this.fileService.editFile(file.data.id, {operation_reminder_id: +e.target.value}, file.company_id).subscribe(resp => {
        file.target.props.operation_reminder_id = resp.operation_reminder_id;
        if (!!file.target.props.operation_reminder_id) {
          file.target.props.selectedCheck = file.work.checklists.find(x => x.id == file.target.props.operation_reminder_id)
        }
        file.target.props.operation_reminder_name = resp.operation_reminder_name;
        if (file.files.find(x => x.id == file.data.id)) {
          file.files.find(x => x.id == file.data.id).operation_reminder_id = resp.operation_reminder_id;
          if (!!resp.operation_reminder_id && file.work.checklists.find(x => x.id == resp.operation_reminder_id)) {
            file.files.find(x => x.id == file.data.id).selectedCheck = file.work.checklists.find(x => x.id == resp.operation_reminder_id)
          }
          file.files.find(x => x.id == file.data.id).operation_reminder_name = resp.operation_reminder_name;
        }
      })
    )
  }

  readyFile(event, file) {
    this.attachSubscriptions(
      this.fileService.editFile(file.data.id, {is_ready_if_approved: event.checked ? 1 : 0}, file.company_id).subscribe(resp => {
        file.target.props.is_ready_if_approved = resp.is_ready_if_approved;
        if (file.files.find(x => x.id == file.data.id)) {
          file.files.find(x => x.id == file.data.id).is_ready_if_approved = resp.is_ready_if_approved;
        }
        // if (!!file.is_ready_if_approved && !file.operation_reminder_id && file?.checklist && file?.checklist?.length > 0 && !this.company.permissions.includes('owner') && !this.checkIsManager(this.task, this.company, this.user)) {
        //   this.selectCheckList(file);
        // }
      })
    )
  }

  checkDuplicates() {
    if (this.files.filter(file => file.target && file.hasOwnProperty('countSameOriginals') && (!file.target.upload || !!file.target.upload.is_end || file.target.upload.state == 'ERROR' || (file.countSameOriginals > 0 && !file.data.tryAgain)) && (!file.target || !file.target.upload || !file.target.upload.is_end) && (file.countSameOriginals == 1 && !file.data.tryAgain)).length > 0) {
      return true
    } else {
      return false
    }
  }

  copyAll() {
    // is !!file?.target?.upload?.is_end || file?.target?.upload?.state == 'ERROR' || (file.countSameOriginals > 0 && !file.data.tryAgain)
    // no !file?.target?.upload?.is_end
    // is file.countSameOriginals > 0 && !file.data.tryAgain

    let type = ''
    console.log("copyAll", this.files);
    this.files.filter(file => file.target && file.hasOwnProperty('countSameOriginals') && (!file.target.upload || !!file.target.upload.is_end || file.target.upload.state == 'ERROR' || (file.countSameOriginals > 0 && !file.data.tryAgain)) && (!file.target || !file.target.upload || !file.target.upload.is_end) && (file.countSameOriginals == 1 && !file.data.tryAgain)).forEach(file => {
      if (file.place == 'chat') {
        this.attachSubscriptions(
          this.fileService.copyFile(Object.assign({
            location: '/',
            task_id: file.chat.task_id,
            task_operation_id: file.chat.task_operation_id,
            discussion_id: file.chat.id,
          }, {id: file.sameOriginals[0].id, company_id: file.company.id, text: file.target.text}), this.company_id).pipe(
            catchError(err => {
              this.layoutService.showSnackBar({name: ''}, marker(err), SnackBarItem)
              return of(err)
            }),
          ).subscribe(resp => {
            console.log(resp);
            this.doSome('delete', file)
            // file.target.file = file.sameOriginals[0];
            // if (file.sameOriginals[0].user) {
            //   file.target.user = file.sameOriginals[0].user;
            // }
            // this.dialogRef.close({event: "paste"})
          })
        )
      } else if (file.place == 'file_manager') {
        this.attachSubscriptions(
          this.fileService.copyFile(Object.assign({
            location: file.folder && file.folder.is_folder ? file.folder.path.replaceAll("//", '/') : file.location,
            task_id: file.task_id,
            task_operation_id: file.work_id || file.task_operation_id,
            discussion_id: 0,
          }, {id: file.sameOriginals[0].id, company_id: file.company.id}), this.company_id).pipe(
            catchError(err => {
              this.layoutService.showSnackBar({name: ''}, marker(err), SnackBarItem)
              return of(err)
            }),
          ).subscribe(resp => {
            // console.log(resp);
            // this.delete();
            // file.target.file = file.sameOriginals[0];
            if (file.sameOriginals[0].user) {
              resp.user = file.sameOriginals[0].user;
            }

            this.doSome('paste', file)
          })
        )
      }
    })
    // console.log("PASTE", res)
  }

  doSome(type, file) {
    if (type == 'delete') {
      console.log('delete');
      if (file.place == 'chat') {
        this.chatService.deletePost(file.target.id, file.company.id).subscribe(result => {
          console.log("next", result)
          if (file.posts.find(b => b.id == file.target.id)) {
            file.posts.splice(file.posts.findIndex(b => b.id == file.target.id), 1)
          }
          this.cancelUpload(file)
        }, error => {
          console.log("error", error)
          if (file.posts.find(b => b.id == file.target.id)) {
            file.posts.splice(file.posts.findIndex(b => b.id == file.target.id), 1)
          }
          this.cancelUpload(file)
        })
      } else {
        this.fileService.deleteFile(file.data.id).subscribe(result => {
          if (file.hasOwnProperty('files')) {
            if (file.files.find(x => x.id == file.data.id)) {
              file.files.splice(file.files.findIndex(x => x.id == file.data.id), 1)
            }
          }
          console.log("next", result)
          this.cancelUpload(file)
        }, error => {
          console.log("error", error)
          this.cancelUpload(file)
        })
      }
    } else if (type == 'paste') {
      if (file.place == 'file_manager') {
        this.fileService.deleteFile(file.data.id).subscribe(result => {
          console.log("next", file)
          if (file.hasOwnProperty('files')) {
            if (file.folder && file.folder.is_folder) {
              this.refreshService.refreshFiles$.next(file);
            } else {
              file.files.push(file.sameOriginals[0])
            }

            if (file.files.find(x => x.id == file.data.id)) {
              file.files.splice(file.files.findIndex(x => x.id == file.data.id), 1)
            }
          }
          this.cancelUpload(file)
        }, error => {
          console.log("error", error)
          if (file.hasOwnProperty('files')) {
            if (file.folder && file.folder.is_folder) {
              this.refreshService.refreshFiles$.next(file);
            } else {
              file.files.push(file.sameOriginals[0])
            }
          }
          this.cancelUpload(file)
        })
      } else {
        console.log("paste")
        this.cancelUpload(file)
        file.data.tryAgain = true;
        file.target.tryAgain = true;
        this.tryAgain(file)
      }
    }
  }
  
  openSameFiles(file) {
    // this.is_expanded = true;
    // this.overlayContextRef.overlayElement.classList.add('is_expanded')
    console.log(file)
    
    this.Close.emit()
    const dialogRef = this.dialog.open(SameFilesComponent, {
      disableClose: true,
      data: {
        company_id: this.company_id || file.company_id,
        file: file
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(res => {
        // this.is_expanded = false;
        // this.overlayContextRef.overlayElement.classList.remove('is_expanded')
        if (res.event == 'delete') {
          console.log('delete');
          if (file.place == 'chat') {
            this.chatService.deletePost(file.target.id, file.company.id).subscribe(result => {
              console.log("next", result)
              if (file.posts.find(b => b.id == file.target.id)) {
                file.posts.splice(file.posts.findIndex(b => b.id == file.target.id), 1)
              }
              this.cancelUpload(file)
            }, error => {
              console.log("error", error)
              if (file.posts.find(b => b.id == file.target.id)) {
                file.posts.splice(file.posts.findIndex(b => b.id == file.target.id), 1)
              }
              this.cancelUpload(file)
            })
          } else {
            this.fileService.deleteFile(file.data.id).subscribe(result => {
              console.log("next", result)
              this.cancelUpload(file)
            }, error => {
              console.log("error", error)
              this.cancelUpload(file)
            })
          }
        } else if (res.event == 'chat_close') {
          this.cancelUpload(file)
        } else if (res.event == 'upload') {
          console.log("upload")
          file.data.tryAgain = true;
          file.target.tryAgain = true;
          this.tryAgain(file)
        } else if (res.event == 'paste') {
          console.log("PASTE", res)
          if (file.place == 'file_manager') {
            this.fileService.deleteFile(file.data.id).subscribe(result => {
              console.log("next", file)
              if (file.hasOwnProperty('files')) {
                file.files.push(res.newFile)
              }
              this.cancelUpload(file)
            }, error => {
              console.log("error", error)
              if (file.hasOwnProperty('files')) {
                file.files.push(res.newFile)
              }
              this.cancelUpload(file)
            })
          } else {
            console.log("paste")
            this.cancelUpload(file)
            file.data.tryAgain = true;
            file.target.tryAgain = true;
            this.tryAgain(file)
          }
        }
        this.Open.emit()
      })
    )
  }

  tryAgain(file) {
    if (file.target.upload) {
      file.target.upload.state = 'IN_PROGRESS';
    }
    
    let objData:any = {
      place: file.place,
      url: file.url,
      data: file.data,
      files: file.files,
      location: file.location,
      target: file.target,
      user: file.user,
      task_id: file.task_id,
      work_id: file.work_id,
      company_id: file.company_id,
      company: file.company
    }
    if (file.place == 'chat') {
      objData.is_chat = file.is_chat
      objData.chat = file.chat
      objData.posts = file.posts
    }

    this.fileService.files$.next(objData)
  }
  
  cancelUpload(file) {
    this.uploads.cancelFile$.next({
      id: file.data.id,
      is_paused: true
    })

    this.attachSubscriptions(
      this.fileService.deleteFile(file.data.id).subscribe(resp => {
        console.log(resp)
      }, error => {
        console.log(error)
      })
    )
  }

  close() {
    this.Close.emit()
  }


  logFiles() {
    console.log(this.files)
  }

  cancelAllUpload() {
    if (this.allUploaded()) {
      this.uploads.cancelFile$.next({
        id: 0,
        is_paused: true
      })
      this.files.forEach(file => {
        this.cancelUploadField(file)
      })
      // console.log(this.files)
      this.files = [];
    } else {

      let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
        hasBackdrop: true,
        backdropClass: 'bottom-sheed-backdrop',
          data: {
            targetVal: {},
            msg: marker("Closing the loader will cancel uploading."),
            btn_no: marker("Don't close"),
            btn_yes: marker("Close loader")
          }
        });
    
        deleteAlert.afterDismissed().subscribe( data => {
          if (data && data.message == 'no') {
            console.log("no");
            return
          } else if (data && data.message == 'yes') {
            this.uploads.cancelFile$.next({
              id: 0,
              is_paused: true
            })
            this.files.forEach(file => {
              this.cancelUploadField(file)
            })
            // console.log(this.files)
            this.files = [];
          }
        })
      // this.checkHeight()
    }

  }

  cancelUploadField(val) {
    val.target.is_paused = true;
    val.target.fileVal = null;
    console.log(val.target.upload)
    if (val.target.upload) {
      val.target.upload.progress = 100;
      val.target.upload.state = 'DELETED';
      val.target.upload.is_end = 'true';
    } else {
      val.target.upload = {
        is_end: "true",
        progress: 100,
        size_is_uploaded: 0,
        speed: 0,
        state: "DELETED",
        timeEnd: null
      }
    }
    console.log(val);
  }

  allUploaded() {
    if (this.files.length == 0) {
      return true;
    } else {
      if (this.files.filter(x => !!x.target && !!x.target.upload && x.target.upload.state == "DONE").length == this.files.length) {
        return true
      } else {
        return false
      }
    }
  }

  // toggleFiles() {
  //   this.is_expanded = !this.is_expanded
  //   // this.overlayContextRef.overlayElement.classList.toggle('is_expanded')
  //   // this.HideMenu.emit()
  // }

  @HostListener("window:visibilitychange", ["$event"])
  onVisibilityChange($event) {
      const isVisible = $event.target.visibilityState === 'visible';
      console.log("isVisible", isVisible)
      this.isVisible = isVisible

      if (isVisible) {
        if (this.sm.localStorageGetItem("copiedFiles")) {
          this.copiedIds = JSON.parse(this.sm.localStorageGetItem("copiedFiles")).filter(x => x.company_id == this.company_id)

          if (this.copiedIds.length && this.company_id) {
            this.attachSubscriptions(
              this.fileService.getFilesPreview(this.company_id, this.copiedIds.map(y => y.id)).subscribe(res => {
                this.copiedFiles = res.filter(b => b.is_dir == 0)
                console.log("this.copiedIds", this.copiedIds)
                console.log("this.copiedFiles", this.copiedFiles)
              })
            )
          }
        } else {
          this.copiedIds = undefined;
          this.copiedFiles = undefined;
          this.type.patchValue(1);
        }
          // tab is visible
      } else {
          // tab is not-visible
      }
  }

  @HostListener('window:beforeunload', ['$event'])
  doSomething($event) {
    console.log("beforeunload")
    if (this.uploadProgress?.uploadedSize > 0) {
      $event.returnValue='Your data will be lost!';
    }
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

}
