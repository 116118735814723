import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { concat, forkJoin, fromEvent, ReplaySubject, Subscription } from 'rxjs';
import { concatMap, debounceTime, distinctUntilChanged, filter, last, map, switchMap, tap } from 'rxjs/operators';
import { prioritys } from 'src/app/shared/consts/prioritys';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { ChatService } from 'src/app/shared/services/rest/chat.service';
import { MembersService } from 'src/app/shared/services/rest/members.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';

@Component({
  selector: 'app-status-reason',
  templateUrl: './status-reason.component.html',
  styleUrls: ['./status-reason.component.scss']
})
export class StatusReasonComponent extends BaseClass implements OnInit, OnDestroy {
  @ViewChild("chatsContainer") chatsContainer: ElementRef;
  public page: number = 1;
  public pagination: any;
  public chats: any;
  public origin = window.location.origin;
  public chatsSub: Subscription;
  public chatsDataSub: Subscription;
  public prioritys = prioritys;
  public form: FormGroup;
  public chatsTab:boolean = true;
  public emplSelected: [] = this.data.is_rejected ? this.data.selected_empls : this.data.job.empl_status.filter(x => x.selected)

  public timeZone = new Date().getTimezoneOffset();
  public chat_id: any;
  public menuState: boolean = false;

  constructor(
    private taskService: TaskService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<StatusReasonComponent>,
    private fb: FormBuilder,
    private membersService: MembersService,
    private chatService: ChatService,
    private layoutService: LayoutService
  ) { super() }

  ngOnInit(): void {
    this.data.dialogHasChat = true;
    console.log("StatusReasonComponent", this.data)
    this.form = this.fb.group({
      task_operation_id: this.data.job.id,
      status_id: [this.data.status.id, Validators.required],
      name: ['', Validators.required],
      text: ['', Validators.required],
      priority: 0
    })

    this.getChats(this.page);

    this.chatsDataSub = this.chatService.getChatsDataStream().pipe(
      concatMap(chatsInPage => {
        return concat(...chatsInPage.map(taskInPage => this.neededData(taskInPage))).pipe(last(),map(x => chatsInPage))
      }),
    ).subscribe(resp => console.log("-----getTaskDataStream-----",resp));
  }

  copyLink(type) {
    this.layoutService.showSnackBar(marker(type + " link"), marker("Copied"), SnackBarItem)
  }
  
  hideMenu() {
    this.chat_id = undefined;
    this.menuState = false;
  }

  understandFileType(val) {
    if (!val) {
      return ""
    }
    
    if (val == 'application/pdf') {
      return 'pdf'
    } else if (val.indexOf("/") != -1) {
      return val.split('/')[0]
    } else {
      return ""
    }
  }
  
  openChat(chat) {
    this.chats.map(x => {
      if (x.id != chat.id) {
        x.is_open = false
      }
    });
    if (chat.is_open) {
      chat.is_open = false;
      this.chat_id = undefined;
      this.menuState = false;
    } else {
      this.chat_id = chat.id;
      this.menuState = true;
      chat.is_open = true;
    }
  }

  pinChat(e, chat) {
    e.preventDefault();
    e.stopPropagation();
    this.attachSubscriptions(
      this.chatService.pinChat({
        discussion_id: chat.id,
        order: 0
      }, this.data.company.id).subscribe(resp => {
        chat.pin = resp;
      })
    )
  }

  unPinChat(e, chat) {
    e.preventDefault();
    e.stopPropagation();
    this.attachSubscriptions(
      this.chatService.deletePinnedChat(chat.pin.id, this.data.company.id).subscribe(resp => {
        chat.pin = null;
      })
    )
  }

  neededData(chat) {
    return forkJoin([
      this.membersService.getMembers({ task_id: chat.task_id, task_operation_id: chat.task_operation_id }, this.data.company.id).pipe(
        tap(res => {
          chat.employees = res.filter(x => x.discussion_id == chat.id || x.discussion_id == 0 || !x.discussion_id);
          chat.employees.forEach(el => {
            if (chat.employeesStatuses.find(x => x.discussion_id == chat.id && el.employee_id == x.employee_id)) {
              el.employeeStatus = chat.employeesStatuses.find(x => x.discussion_id == chat.id && el.employee_id == x.employee_id)
            } else {
              el.employeeStatus = null
            }
          })
        })
      ),
    ])
  }
  
  ngAfterViewInit(): void {
    this.onScroll();
  }

  onScroll() {
    this.attachSubscriptions(
      fromEvent(this.chatsContainer.nativeElement, "scroll").pipe(
        filter((e:any) => e.target.scrollTop >=  e.target.scrollHeight - e.target.offsetHeight - 400),
        debounceTime(300)
      ).subscribe(() => {
        if (this.page <= this.pagination['pageCount']) {
          this.getChats(this.page);
        }
      })
    )
  }
  
  onImgError(event){
    event.target.src = this.data.imgRoute+'/assets/img/image_black_48dp.svg'
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  selectPrioritys = (priority: any) => {
    this.form.patchValue({
      priority: priority.id
    })
  }

  getPriority(id) {
    if (!this.prioritys) {
      return false;
    }
    return this.prioritys.find(el => el.id == id)
  } 

  getStatus(id) {
    if (!this.data.statuses) {
      return false;
    }
    return this.data.statuses.find(el => el.id == id)
  }  

  getGroup(id) {
    if (!this.data.groups) {
      return false;
    }
    return this.data.groups.find(el => el.id == id)
  }  

  getColumnName(id) {
    if (!this.data.operationsValues || this.data.operationsValues.filter(el => el.id == id).length == 0) {
      return '';
    }
    return this.data.operationsValues.find(el => el.id == id)?.translation?.name ? this.data.operationsValues.find(el => el.id == id).translation.name : this.data.operationsValues.find(el => el.id == id).name
  }

  getOperationStatusName(id) {
    switch (+id) {
      case 1:
        return "Waiting";
        break;
      case 2:
        return "In process";
        break;
      case 3:
        return "To approve";
        break;
      case 4:
        return "Approved";
        break;
      case 5:
        return "To correct";
        break;
      case 6:
        return "Sources Needed";
        break;
      case 97:
        return "On hold";
        break;
      case 98:
        return "Ready";
        break;
      case 99:
        return "Canceled";
        break;
    }
  }

  getChats(page) {
    this.chatsSub = this.chatService.getChatsExpand(page, this.data.company.id, {task_operation_id: this.data.job.id}, '5').pipe(
      tap(el => {
        this.pagination = {
          'pageCount': el.headers.get('x-pagination-page-count'),
          'perPage': el.headers.get('x-pagination-per-page'),
          'totalCount': el.headers.get('x-pagination-total-count'),
          'currentPage': el.headers.get('x-pagination-current-page'),
        }
        if (this.pagination['currentPage'] == '1') {
          this.chats = [];
        }
      }),
      map(el => el.body),
      tap(resp => {
        this.chatService.chatData$.next(resp)
      }),
      distinctUntilChanged()
    ).subscribe(resp => {
      this.chats.push(...resp);

      console.log("this.chats "+this.page, this.chats)

      this.page = this.page + 1;
      if (+this.pagination['currentPage'] == 1) {
        this.chatsContainer.nativeElement.scrollTop = 0;
      }

      if (this.page <= 3 && +this.pagination['pageCount'] != 1) {
        this.getChats(this.page);
      }

    })
    
  }

  // switchMap(() => {
  //   return forkJoin(this.emplSelected.filter((x:any) => !!x.partnerCompanyStatus || !!x.employeeStatus).map((val:any) => (!val.is_partner ? this.membersService.editTaskEmployeeStatus(val.employeeStatus.id, {status_id: this.data.status.id}) : this.membersService.editPartnerStatus(val.partnerCompanyStatus.id, {status_id: this.data.status.id})))).pipe(last())
  // })   
  createChat() {
    this.attachSubscriptions(
      this.chatService.createChat(this.form.value, this.data.company.id).subscribe(resp => {
        console.log(resp);
        this.dialogRef.close({change: true})
      })
    )
  }

  save() {
    this.dialogRef.close({change: true})
  }

  ngOnDestroy(): void {
    if (this.chatsSub) {
      this.chatsSub.unsubscribe()
    }
    if (this.chatsDataSub) {
      this.chatsDataSub.unsubscribe();
    }
    this.clearSubscriptions()
  }
}
