<h2 mat-dialog-title class="dialog_title">
    <span>
        {{ "Adding a client" | translate }}
    </span>
    <button mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h2>
<mat-dialog-content class="mat-typography">
    <div class="container">
        <form [formGroup]="form">
            <ng-container *ngIf="!data.is_partner; else elseTemplateParterAdd">
                <mat-form-field appearance="standard" class="full_width_form">
                    <mat-label>{{ "Choose an Individual" | translate }}</mat-label>
                    <mat-select formControlName="client_employee_id" required>
                        <mat-option>
                            <ngx-mat-select-search 
                            placeholderLabel="{{ 'Search' | translate }}" 
                            noEntriesFoundLabel="{{ 'no matching individuals found' | translate }}"
                            [formControl]="employeesControl"></ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let employee of employees$ | async" [value]="employee.id">                  
                            <app-user [class]="'user_div'" [employee]="employee"></app-user>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </ng-container>
            <ng-template #elseTemplateParterAdd>
                <mat-form-field appearance="standard" class="full_width_form">
                    <mat-label>{{ "Choose a Workspace" | translate }}</mat-label>
                    <mat-select formControlName="client_partner_company_id" required>
                        <mat-option>
                            <ngx-mat-select-search 
                            placeholderLabel="{{ 'Search' | translate }}" 
                            noEntriesFoundLabel="{{ 'no matching partners found' | translate }}"
                            [formControl]="partnersControl"></ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let partner of partners$ | async" [value]="partner.partner_company_id">                  
                            <app-user [class]="'user_div'" [companyEmployee]="partner?.partnerCompany" [imgRoute]="data.imgRoute" [host]="data.host" [company]="data?.company"></app-user>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </ng-template>
            <p>
                <mat-checkbox color="primary" formControlName="is_principal">{{ "Moderation" | translate }}</mat-checkbox>
            </p>
        </form>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button (click)="close()">
        {{ "Close" | translate }}
    </button>
    <button class="ml-1 d-f-btn" (click)="submitForm()" mat-raised-button color="primary" [disabled]="!form.valid || isSubmit">
        <mat-spinner class="spin_16 mr-1" *ngIf="isSubmit"></mat-spinner>  
        {{ "Add" | translate }}
    </button>
</mat-dialog-actions>
