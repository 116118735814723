import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'app-edit-approve',
  templateUrl: './edit-approve.component.html',
  styleUrls: ['./edit-approve.component.scss']
})
export class EditApproveComponent implements OnInit, AfterViewInit {
  @ViewChild('btnNo') btnNo: MatButton
  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private bottomSheetRef: MatBottomSheetRef<EditApproveComponent>
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    setTimeout(() => {this.btnNo.focus()},0)
  }

  no(): void {
    this.bottomSheetRef.dismiss({
      message: 'no',
    });
  }

  yes() {
    this.bottomSheetRef.dismiss({
      message: 'yes',
    });
  }

}
