import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { forkJoin, of } from 'rxjs';
import { catchError, concatMap, map, switchMap, tap } from 'rxjs/operators';
import { OperationChatComponent } from 'src/app/components/atTasksDialog/operation-chat/operation-chat.component';
import { ChatsComponent } from 'src/app/components/chats/chats.component';
import { casesModel } from 'src/app/shared/functions/casesModel';
import { BaseClass } from 'src/app/shared/models/base-class';
import { ChatService } from 'src/app/shared/services/rest/chat.service';
import { MembersService } from 'src/app/shared/services/rest/members.service';
import { RefreshService } from 'src/app/shared/services/rest/refresh.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { EditChatConnectionsComponent } from './dialogs/edit-chat-connections/edit-chat-connections.component';

@Component({
  selector: 'app-chat-connections',
  templateUrl: './chat-connections.component.html',
  styleUrls: ['./chat-connections.component.scss']
})
export class ChatConnectionsComponent extends BaseClass implements OnInit, OnDestroy {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ChatConnectionsComponent>,
    private dialog: MatDialog,
    private refreshService: RefreshService,
    private taskService: TaskService,
    private chatService: ChatService,
    private membersService: MembersService,
    ) { super() }

  ngOnInit(): void {
    console.log("ChatConnectionsComponent", this.data)

    this.refresh()
  }

  neededData(chat) {
    return forkJoin([
      this.membersService.getMembers({ task_id: chat.task_id, task_operation_id: chat.task_operation_id }, this.data.company.id).pipe(
        tap(res => {
          chat.employees = res.filter(x => x.discussion_id == chat.id || x.discussion_id == 0 || !x.discussion_id);
          chat.employees.forEach(el => {
            if (chat.employeesStatuses.find(x => x.discussion_id == chat.id && el.employee_id == x.employee_id)) {
              el.employeeStatus = chat.employeesStatuses.find(x => x.discussion_id == chat.id && el.employee_id == x.employee_id)
            } else {
              el.employeeStatus = null
            }
          })
         
        }),
        catchError(err => of(err))
      ),
      // this.membersService.getTaskPartnerStatuses({task_id: chat.task_id, task_operation_id: chat.task_operation_id}, this.company.id).pipe(
      //   tap(resp => {
      //   console.log("chatPartnerStats GET", resp)
      // }))
    ])
  }

  refresh() {
    this.attachSubscriptions(
      this.refreshService.getRefreshChats().pipe(
        concatMap(res => {
          if ( this.data.chat.id == res.chat_id && this.data.company.id == res.company_id ) {
            return this.chatService.getChatsExpand(1, this.data.company.id, {id: [res.chat_id]}, !this.data.is_mobile ? '20' : '10').pipe(
              map(x => x.body[0]),
              switchMap(val => this.neededData(val).pipe(map(() => val))),
              tap(val => {
                this.data.chat = val
              })
            )
          } else {
            return of(false)
          }    
        })
      ).subscribe(resp => {
        console.log("getRefreshChats", resp)
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  neededTaskData(task) {
    let arr = [
      this.chatService.getTasksChats(this.data.company.id, [task.id]).pipe(
        tap(res => {
          task['operations'].map(o_el => {
            o_el['chatsInfo'] = [];
            o_el['chatsInfo'].push(...res.filter(z => z.task_operation_id == o_el.id));
            o_el['unReadChats'] = res.filter(z => z.task_operation_id == o_el.id && z.is_read != '1').length;
            o_el['unClosedChats'] = res.filter(z => z.task_operation_id == o_el.id && z.status_id != '4').length;
          });
          task['chatsInfo'] = [];
          task['chatsInfo'].push(...res.filter(z => z.task_id == task.id));
          task['unReadChats'] = res.filter(z => z.is_read != '1').length;
          task['unClosedChats'] = res.filter(z => z.status_id != '4').length;
        })
      )
    ]
    return forkJoin(arr)
  }

  openChat(chat) {
    this.attachSubscriptions(
      this.taskService.getOneTaskExpand(this.data.company.id, chat.task_id).pipe(
        map(x => x.body[0]),
        switchMap(x => this.neededTaskData(x).pipe(map(() => casesModel([x], [], 'update')),map(x => x.arr[0]))),
      ).subscribe(resp => {
        console.log(resp);

        if (this.dialog.openDialogs.filter(x => !!x.componentInstance.data.withConnectedChats).length > 0) {
          this.dialog.openDialogs.filter(x => !!x.componentInstance.data.withConnectedChats)[0].close()
        }
        const goToChat = this.dialog.open(OperationChatComponent, {
          backdropClass: ['backdrop_under_header', "without_pad"],
          panelClass: !this.data.is_mobile ? ['full_screen_dialog', 'global_chats_dialog', 'show_header'] : ['full_screen_dialog', 'global_chats_dialog'],
          position: !this.data.is_mobile ? { top: '58px', left: '0', right: '0', bottom: '0' } : {},
          data: {
            company: this.data.company,
            task: resp,
            operationsValues: this.data.operationsValues,
            work: resp.operations.find(el => el.id == chat.task_operation_id),
            user: this.data.user,
            empl_status: this.data.empl_status,
            chat: chat,
            dialogType: 'operation_chat',
            withConnectedChats: true,
            chats: resp.operations.find(el => el.id == chat.task_operation_id).chatsInfo
          }
        });
    
        this.attachSubscriptions(
          goToChat.afterClosed().subscribe(result => {
            if (!!result && result.event == 'close') {
              this.chatService.postChatRead(chat.id, 0, this.data.company.id).subscribe(resp => {
                console.log(resp);
              })
            }
          })
        )
      })
    )
  }

  editConnections() {
    const goToChatCon = this.dialog.open(EditChatConnectionsComponent, {
      backdropClass: 'backdrop_under_header',
      panelClass: !this.data.is_mobile ? ['open_task_dialog', 'show_header'] : 'open_task_dialog',
      data: this.data
    });

    this.attachSubscriptions(
      goToChatCon.afterClosed().subscribe(result => {
        this.refreshService.refreshChat$.next({company_id: this.data.company.id, chat_id: this.data.chat.id})
      })
    )
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

}
