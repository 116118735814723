import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { mimeTypes } from 'mime-wrapper';
import { forkJoin, fromEvent, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { casesModel } from 'src/app/shared/functions/casesModel';
import { InterfaceComponent } from 'src/app/shared/global_components/file-manager/dialog/interface/interface.component';
import { PublicInterfaceComponent } from 'src/app/shared/global_components/file-manager/dialog/public-interface/public-interface.component';
import { VideoViewerComponent } from 'src/app/shared/global_components/file-manager/dialog/video-viewer/video-viewer.component';
import { MobFmViewComponent } from 'src/app/shared/global_components/mob-file-manager/dialogs/mob-fm-view/mob-fm-view.component';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { AuthService } from 'src/app/shared/services/rest/auth.service';
import { ChatService } from 'src/app/shared/services/rest/chat.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { FileService } from 'src/app/shared/services/rest/file.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { environment } from 'src/environments/environment';
import { OpenJobComponent } from '../../workspace-pages/cases/dialogs/open-job/open-job.component';
import { OpenTaskComponent } from '../../workspace-pages/cases/dialogs/open-task/open-task.component';

@Component({
  selector: 'app-file-link',
  templateUrl: './file-link.component.html',
  styleUrls: ['./file-link.component.scss']
})
export class FileLinkComponent extends BaseClass implements OnInit, OnDestroy {

  public host: any = environment.host;
  public imgRoute: any = '';
  public link: any;
  public is_mobile: boolean = false;
  public is_load: boolean = false;
  public activeLang: any;
  public linkStr: any;
  public user: any;
  constructor(
    private auth: AuthService,
    private router: Router,
    private sm: StorageManagerService,
    private dialog: MatDialog,
    private companyService: CompanyService,
    private fileService: FileService,
    private languageService: LanguageService,
    public activatedRoute: ActivatedRoute,
    private taskService: TaskService,
    private chatService: ChatService
  ) { super() }

  ngOnInit(): void {
    this.linkStr = window.location.href;
    this.getImgRoute();
    this.getUser();
    this.getLangData();
    this.checkIsMobile();
    this.getInitMeta();
  }

  
  checkIsMobile() {
    if (window.innerWidth <= 769) {
      this.is_mobile = true;
    } else {
      this.is_mobile = false;
    }
    this.onResize();
  }

  download(file, company_id) {
    window.open(this.host + file.original + '?company_id=' + company_id + `&filename=${file.filename}`, '_blank');
    this.toMainPage()
    // var a = document.createElement('a');
    // a.href = this.host + element;
    // a.download = folder.name;
    // a.target = "_blank"
    // a.click();  
    // a.remove(); 
  }

  onResize() {
    this.attachSubscriptions(
      fromEvent(window, "resize").pipe(
        map(() => window.innerWidth)
      ).subscribe((wWidth) => {
          if (wWidth <= 769) {
            this.is_mobile = true;
          } else {
            this.is_mobile = false;
          }
        }
      )
    )
  }

  toMainPage() {
    // this.router.navigate(['/main'])
  }

  openInCompany(company) {
    console.log(company, this.link);
    if (!this.link.meta.is_dir) {
      this.openFile(this.link.meta.task_id, this.link.meta.id, company)
    } else {
      if (!this.link.meta.task_operation_id) {
        this.openTask({id: this.link.meta.task_id}, company)
      } else {
        this.openJob(this.link.meta.task_id, this.link.meta.task_operation_id, company)
      }
    }

  }

  openPublicFolder(link) {
    console.log("openPublicFolder", link)

    const dialogRef = this.dialog.open(PublicInterfaceComponent, {
      backdropClass: ['backdrop_under_header', 'file_backdrop_under_header'],
      panelClass: !this.is_mobile ? ['file_manager_modal', 'show_header'] : 'file_manager_modal',
      data: {
        link: link,
        loc: this.activatedRoute.snapshot.queryParamMap.get('path') ? decodeURIComponent(this.activatedRoute.snapshot.queryParamMap.get('path')) : ''
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        this.toMainPage()
      })
    )
  }

  openFolder(link) {
    console.log("openFolder", link)
    if (!link.meta.task_operation_id) {
      this.openTask({id: link.meta.task_id}, link.companyes[0])
    } else {
      this.openJob(link.meta.task_id, link.meta.task_operation_id, link.companyes[0])
    }
  }

  openTask(task, company) {
    let taskData:any = {
      task_id: task.id,
      task: task,
      company: company,
      initCompanyId: company.id,
      loc: this.activatedRoute.snapshot.queryParamMap.get('path') ? decodeURIComponent(this.activatedRoute.snapshot.queryParamMap.get('path')) : ''
    }

    const dialogRef = this.dialog.open(OpenTaskComponent, {
      backdropClass: 'backdrop_under_header',
      panelClass: !this.is_mobile ? ['open_task_dialog', 'show_header'] : 'open_task_dialog',
      autoFocus: false,
      data: taskData
    });

    dialogRef.afterClosed().subscribe(res => {
      this.toMainPage()
    })
  }

  openJob(task_id, task_operation_id, company) {
    console.log(this.activatedRoute.snapshot.queryParamMap.get('path'))
    this.attachSubscriptions(
      this.taskService.getOneTaskExpand(company.id, task_id).pipe(
        map(x => x.body[0]),
        switchMap(x => this.neededData(x, company).pipe(map(() => casesModel([x], [], 'update')),map(x => x.arr[0]))),
      ).subscribe(resp => {
        const dialogRef = this.dialog.open(OpenJobComponent, {
          backdropClass: 'backdrop_under_header',
          autoFocus: false,
          panelClass: !this.is_mobile ? ['open_task_dialog', 'show_header'] : 'open_task_dialog',
          data: {
            task_id: task_id,
            task_operation_id: task_operation_id,
            task: resp,
            company: company,
            initCompanyId: company.id,
            loc: this.activatedRoute.snapshot.queryParamMap.get('path') ? decodeURIComponent(this.activatedRoute.snapshot.queryParamMap.get('path')) : ''
          }
        });

        dialogRef.afterClosed().subscribe(res => {
          this.toMainPage()
        })
      })
    )
  }

  getInitMeta() {
    this.is_load = true;
    this.attachSubscriptions(
      this.auth.getInitMetaDev('file', this.router.url.split('/')[2].split("?")[0]).pipe(
        switchMap((res:any) => {
          if (res.meta && res.meta.id && res.meta.acm) {
            return this.companyService.getCompanyesForLink(false, false, false, res.meta.id, res.meta.acm).pipe(
              tap(el => {
                res.companyes = el
              }),
              switchMap(el => {
                console.log("pre getPublicFiles", el)
                if (el.length == 0) {
                  return this.fileService.getPublicFiles(res.meta.acm).pipe(
                    tap(val => {
                      if (!val.file.is_dir) {
                        res.publicFile = val
                      } else {
                        res.publicFolder = val
                      }
                    }),
                    catchError(error => {
                      res.error = error
                      res.hasError = true;
                      return of(res)
                    })
                  )
                } else {
                  return of(res)
                }
              }),
              catchError(error => {
                console.log(error, res)
                res.companyes = []
                return this.fileService.getPublicFiles(res.meta.acm).pipe(
                  tap(val => {
                    if (!val.file.is_dir) {
                      res.publicFile = val
                    } else {
                      res.publicFolder = val
                    }
                  }),
                  catchError(error => {
                    res.error = error
                    res.hasError = true;
                    return of(res)
                  })
                )
              }),
              map(() => res)
            )
          } else {
            res.companyes = []
            return of(res)
          }
        })
      ).subscribe(resp => {
        this.link = resp
        console.log("getInitData", resp)
        console.log("this.link", this.link)
        
        // return
        if (!!resp.publicFile) {
          console.log("public file")
          if (resp.hasError) {
            console.log(resp.hasError)
          } else {
            this.openPublicFile(resp)
          }
          this.is_load = false
        } else if (!!resp.publicFolder) {
          console.log("public folder")
          if (resp.hasError) {
            console.log(resp.hasError)
          } else {
            this.openPublicFolder(resp)
          }
          this.is_load = false
        } else {
          if (resp.meta.is_dir || !!this.activatedRoute.snapshot.queryParamMap.get('path')) {
            console.log("normal folder")
            if (resp.companyes && resp.companyes.length) {
              if (resp.companyes.length == 1) {
                this.openFolder(resp)
                setTimeout(() => {
                  this.is_load = false
                }, 1000)
              } else {
                this.is_load = false
              }
            } else {
              this.is_load = false
            }
          } else {
            console.log("normal file")
            if (resp.companyes && resp.companyes.length) {
              if (resp.companyes.length == 1) {
                this.openFile(resp.meta.task_id, resp.meta.id, resp.companyes[0])
                setTimeout(() => {
                  this.is_load = false
                }, 1000)
              } else {
                this.is_load = false
              }
            } else {
              this.is_load = false
            }
          }
        }
      })
    )
  }

  understandFileType(val) {
    if (!val) {
      return ""
    }
    
    if (val == 'application/pdf') {
      return 'pdf'
    } else if (val.indexOf("/") != -1) {
      return val.split('/')[0]
    } else {
      return ""
    }
  }

  openPublicFile(link) {
    console.log(link)
    if (link.publicFile.is_document != 1 &&!(['image', 'video'].includes(this.understandFileType(link.publicFile.file.content_type)) || (!!mimeTypes.getType(link.publicFile.file.filename) && (mimeTypes.getType(link.publicFile.file.filename).indexOf("image") != -1 ||  mimeTypes.getType(link.publicFile.file.filename).indexOf("video") != -1)))) {
      this.download(link.publicFile.file, link.publicFile.file.company_id)
      return
    }

    if (!this.is_mobile) {
      const dialogRef = this.dialog.open(VideoViewerComponent, {
        panelClass: ['video_view_modal', 'video_viewer'],
        autoFocus: false,
        data: {
          file: link.publicFile.file,
          initCompanyID: link.publicFile.file.company_id,
          is_public: true
        }
      });
      dialogRef.afterClosed().subscribe(res => {
        this.toMainPage()
      })
    } else {
      const dialogRef = this.dialog.open(MobFmViewComponent, {
        backdropClass: ['mob_video_viewer_backdrop'],
        panelClass: 'mob_video_viewer',
        autoFocus: false,
        data: {
          file: link.publicFile.file,
          initCompanyID: link.publicFile.file.company_id,
          is_public: true
        }
      });
      dialogRef.afterClosed().subscribe(res => {
        this.toMainPage()
      })
    }

    

  }
  
  getUser() {
    this.attachSubscriptions(
      this.auth.$user.subscribe(resp => {
        console.log("USER", resp)
        this.user = resp;
      })
    )
  }

  onPartnerImgError(event){
    event.target.src = this.imgRoute+'/assets/img/partner.png'
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  neededData(task, company) {
    let arr = [
      this.chatService.getTasksChats(company.id, [task.id]).pipe(
        tap(res => {
          task['operations'].map(o_el => {
            o_el['chatsInfo'] = [];
            o_el['chatsInfo'].push(...res.filter(z => z.task_operation_id == o_el.id));
            o_el['unReadChats'] = res.filter(z => z.task_operation_id == o_el.id && z.is_read != '1').length;
            o_el['unClosedChats'] = res.filter(z => z.task_operation_id == o_el.id && z.status_id != '4').length;
          });
          task['chatsInfo'] = [];
          task['chatsInfo'].push(...res.filter(z => z.task_id == task.id));
          task['unReadChats'] = res.filter(z => z.is_read != '1').length;
          task['unClosedChats'] = res.filter(z => z.status_id != '4').length;
        })
      )
    ]
    if (task.company_id == company.id) {
      arr.push(
        this.taskService.getTaskClients(task.id, company.id).pipe(
          tap(res => {
            task.clients = res
          }),
          catchError(err => {
            return err
          })
        )
      )
    }
    return forkJoin(arr)
  }

  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData().subscribe(resp => {
        this.activeLang = resp.active;
      })
    )
  }

  openFile(task_id, file_id, company) {

    this.attachSubscriptions(
      this.taskService.getOneTaskExpand(company.id, task_id).pipe(
        map(x => x.body[0]),
        switchMap(x => this.neededData(x, company).pipe(map(() => casesModel([x], [], 'update')),map(x => x.arr[0]))),
        switchMap(x => this.taskService.getOperations(company.id, this.activeLang).pipe(tap(u => x.operationsValues = u), map(() => x))),
        switchMap(x => this.fileService.getFileDataNew('1', company.id, {file_id: file_id}).pipe(map(x => x.body),tap(u => x.metaFile = u[0] ? u[0] : null), map(() => x)))
        // switchMap(x => this.fileService.getTargetFile(file_id, company.id).pipe(tap(u => x.metaFile = u), map(() => x)))
      ).subscribe(resp => {
        console.log("NEXT NEXT NEXT", resp)
        let task = resp;
        let work;

        if (task.metaFile.is_document != 1 && !(['image', 'video'].includes(this.understandFileType(task.metaFile.content_type)) || (!!mimeTypes.getType(task.metaFile.filename) && (mimeTypes.getType(task.metaFile.filename).indexOf("image") != -1 ||  mimeTypes.getType(task.metaFile.filename).indexOf("video") != -1)))) {
          this.download(task.metaFile, company.id)
          return
        }

        if (!!this.link.meta.task_operation_id) {
          work = task.operations.find(x => x.id == this.link.meta.task_operation_id)
        }

        if (!this.is_mobile) {
          const dialogRef = this.dialog.open(VideoViewerComponent, {
            panelClass: ['video_view_modal', 'video_viewer'],
            autoFocus: false,
            data: {
              file: task.metaFile,
              task: task,
              work: work,
              operationsValues: task.operationsValues,
              company: company,
              initCompanyID: company.id,
              activeLang: this.activeLang,
              user: this.user
            }
          });
          dialogRef.afterClosed().subscribe(res => {
            this.toMainPage()
          })
        } else {
          const dialogRef = this.dialog.open(MobFmViewComponent, {
            backdropClass: ['mob_video_viewer_backdrop'],
            panelClass: 'mob_video_viewer',
            autoFocus: false,
            data: {
              file: task.metaFile,
              task: task,
              work: work,
              operationsValues: task.operationsValues,
              company: company,
              initCompanyID: company.id,
              activeLang: this.activeLang,
              user: this.user
            }
          });
          dialogRef.afterClosed().subscribe(res => {
            this.toMainPage()
          })
        }
      })
    )

  }

  goToDashboard(company_id) {
    this.router.navigate(['/dashboard'], { queryParams: {company_id: company_id} });
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
