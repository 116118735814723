import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import * as moment from 'moment';
import { map, switchMap } from 'rxjs/operators';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { BaseClass } from 'src/app/shared/models/base-class';
import { GlobalDataService } from 'src/app/shared/services/common/global-data.service';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { MembersService } from 'src/app/shared/services/rest/members.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { MY_FORMATS } from '../../atTasksDialog/task-profile-add/task-profile-add.component';
@Component({
  selector: 'app-edit-additional-employees',
  templateUrl: './edit-additional-employees.component.html',
  styleUrls: ['./edit-additional-employees.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ]
})
export class EditAdditionalEmployeesComponent extends BaseClass implements OnInit, OnDestroy {
  public form: FormGroup;
  public operationStatuses: any;
  public numberRegEx = /\d*\.?\d{1,2}/;
  public isSubmit: boolean = false;
  public submited: boolean = false;
  public currencyList: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<EditAdditionalEmployeesComponent>,
    private taskService: TaskService,
    private fb: FormBuilder,
    private _adapter: DateAdapter<any>,
    private globalDataService: GlobalDataService,
    private layoutService: LayoutService,
    private languageService: LanguageService,
    private membersService: MembersService
  ) { super() }

  ngOnInit(): void {
    console.log(this.data);
    this.form = this.fb.group({
      rate: [this.data.addEmpl.rate, [Validators.required, Validators.pattern(this.numberRegEx)]],
      price_currency_id: this.data.addEmpl.price_currency_id,
      price: [this.data.addEmpl.price, [Validators.required, Validators.pattern(this.numberRegEx)]],
      status_id: [this.data.addEmpl.status_id, Validators.required],
      employee_comment: this.data.addEmpl.employee_comment,
      completed_at: this.data.addEmpl.completed_at != "0" ? moment(this.data.addEmpl.completed_at*1000) : 0
    })

    if (!this.data.discussion) {
      this.form.addControl("task_operation_id", this.fb.control(this.data.work.id))
      this.form.get('task_operation_id').updateValueAndValidity();
    } else {
      this.form.addControl("discussion_id", this.fb.control(this.data.discussion.id))
      this.form.get('discussion_id').updateValueAndValidity();
    }

    this.getCurrencyList();
    this.getOperationsStatus();
    this.getLangData();
  }

  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData().subscribe(resp => {
        this._adapter.setLocale(resp.active);
      })
    )
  }
  
  selectStatus(status) {
    this.form.patchValue({
      status_id: status.id
    })
  }

  getOperationsStatus() {
    this.attachSubscriptions(
      this.taskService.getOperationsStatus().subscribe(resp => {
        this.operationStatuses = resp.slice();
      })
    )
  }  

  getCurrencyList() {
    this.attachSubscriptions(
      this.globalDataService.getCurrencies().subscribe(resp => {
        console.log("getCurrencyList", resp)
        this.currencyList = resp.slice();
      })
    )
    // this.attachSubscriptions(
    //   this.companyService.getCurrencyList().subscribe(resp => {
    //     this.currencyList = resp
    //   })
    // )
  }


  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  onSubmit() {
    if (!this.form.valid) {
      this.submited = true;
      this.layoutService.showSnackBar({name: ''}, marker("You need to fill in all required fields"), SnackBarItem)
      return
    }

    this.submited = false;
    this.isSubmit = true;
    if (String(this.form.value['rate']).indexOf(",") != -1) {
      this.form.value['rate'] = this.form.value['rate'].replace(",", ".");
    }
    if (String(this.form.value['price']).indexOf(",") != -1) {
      this.form.value['price'] = this.form.value['price'].replace(",", ".");
    }
    if (!!this.form.value.completed_at && !!this.form.value.completed_at._d) {
      this.form.value.completed_at = moment(this.form.value.completed_at._d).format("X");
    } else {
      this.form.value.completed_at = '0';
    }
    let val = {...this.form.value};
    delete val.status_id

    if (this.data.addEmpl.is_partner) {
      this.attachSubscriptions(
        this.membersService.editTaskPartner(this.data.addEmpl.id, val, this.data.company.id).pipe(
          switchMap(x => {
            if (!!this.data.addEmpl.employeeStatus) {
              return this.membersService.editPartnerStatus(this.data.addEmpl.employeeStatus.id, {status_id: this.form.value.status_id}, this.data.company.id).pipe(map(() => x))
            } else {
              return this.membersService.addPartnerStatus({
                status_id: this.form.value.status_id,
                company_id: this.data.company.id,
                task_id: this.data.task.id,
                task_operation_id: this.data.work.id,
                discussion_id: this.data.discussion ? this.data.discussion.id : 0,
                partner_company_id: this.data.addEmpl.partner_company_id,
              }, this.data.company.id).pipe(map(() => x))
            }
          })
        ).subscribe(resp => {
          this.isSubmit = false;
          this.dialogRef.close({event: "update", data: resp})
        })
      )
    } else if (this.data.addEmpl.is_partner_employee) {
      this.attachSubscriptions(
        this.membersService.editTaskPartnerEmployee(this.data.addEmpl.id, val, this.data.company.id).pipe(
          switchMap(x => {
            if (!!this.data.addEmpl.employeeStatus) {
              return this.membersService.editPartnerEmployeeStatus(this.data.addEmpl.employeeStatus.id, {status_id: this.form.value.status_id}, this.data.company.id).pipe(map(() => x))
            } else {
              return this.membersService.addPartnerEmployeeStatus({
                status_id:this.form.value.status_id,
                company_id: this.data.company.id,
                task_id: this.data.task.id,
                task_operation_id: this.data.work.id,
                discussion_id: this.data.discussion ? this.data.discussion.id : 0,
                partner_company_id: this.data.addEmpl.partner_company_id,
                partner_employee_id: this.data.addEmpl.partner_employee_id
              }, this.data.company.id).pipe(map(() => x))
            }
          })
        ).subscribe(resp => {
          this.isSubmit = false;
          this.dialogRef.close({event: "update", data: resp})
        })
      )
    } else {
      this.attachSubscriptions(
        this.membersService.editMember(this.data.addEmpl.id, val, this.data.company.id).pipe(
          switchMap(x => {
            if (!!this.data.addEmpl.employeeStatus) {
              return this.membersService.editTaskEmployeeStatus(this.data.addEmpl.employeeStatus.id, {status_id: this.form.value.status_id}, this.data.company.id).pipe(map(() => x))
            } else {
              return this.membersService.addTaskEmployeeStatus({company_id: this.data.company.id, task_id: this.data.task.id, task_operation_id: this.data.work.id, employee_id: this.data.addEmpl.employee_id, status_id: this.form.value.status_id}, this.data.company.id).pipe(map(() => x))
            }
          })
        ).subscribe(resp => {
          this.isSubmit = false;
          this.dialogRef.close({event: "update", data: resp})
        })
      )
    }
  }

  ngOnDestroy(): void {
    this.clearSubscriptions();    
  }


  
}
