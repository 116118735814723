import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { BaseClass } from 'src/app/shared/models/base-class';

@Component({
  selector: 'app-mob-active-filters',
  templateUrl: './mob-active-filters.component.html',
  styleUrls: ['./mob-active-filters.component.scss']
})

export class MobActiveFiltersComponent extends BaseClass implements OnInit, OnDestroy {
  public activeParams: any;
  public sortValue: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private activatedRoute: ActivatedRoute,
    public dialogRef: MatDialogRef<MobActiveFiltersComponent>,
  ) { super() }

  ngOnInit(): void {
    console.log("MobActiveFiltersComponent", this.data)

    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        this.close();
      })
    )

    this.activeParams = JSON.parse(JSON.stringify(this.data.params))
    delete this.activeParams.company_id;
    delete this.activeParams.count;

    switch (this.activeSections()) {
      case 'Cards':
        this.sortValue = this.activatedRoute.snapshot.queryParamMap.get('order_by') ? this.activatedRoute.snapshot.queryParamMap.get('order_by') : 'updated_desc'
        break;
      case 'Jobs':
        this.sortValue = this.activatedRoute.snapshot.queryParamMap.get('order_by') ? this.activatedRoute.snapshot.queryParamMap.get('order_by') : 'updated_desc'
        break;
      case 'Chats':
        this.sortValue = this.activatedRoute.snapshot.queryParamMap.get('order_by') ? this.activatedRoute.snapshot.queryParamMap.get('order_by') : 'updated_desc'
        break;
      case 'Posts':
        this.sortValue = this.activatedRoute.snapshot.queryParamMap.get('order_by') ? this.activatedRoute.snapshot.queryParamMap.get('order_by') : 'content_published_at_desc'
        break;
    
      default:
        break;
    }
    if (!!this.sortValue && !['updated_desc', 'content_published_at_desc'].includes(this.sortValue)) {
      this.activeParams.order_by = this.sortValue
    }
   

    if (['Board by Date', 'Board by Order'].includes(this.activeSections())) {
      if (!this.activeParams.status_id || this.activeParams.status_id.split(',').sort().toString() == [1,2,5].toString()) {
        delete this.activeParams.status_id;
      }
      if (!this.activeParams.doneStatus || this.activeParams.doneStatus.split(',').sort().toString() == [3,4,6,97,98,99].toString()) {
        delete this.activeParams.doneStatus;
      }
    } else if (['Jobs', 'Chats'].includes(this.activeSections())) {
      if (!this.activeParams.status_id) {
        delete this.activeParams.status_id;
      }
    } else {
      delete this.activeParams.status_id;
      delete this.activeParams.doneStatus;
    }

    console.log("this.activeParams", this.activeParams)
  }

  getOrder(val) {
    let sorts = {
      "updated_desc": {
        "name": "Updated",
        "arrow": true
      },
      "updated": {
        "name": "Updated",
        "arrow": false
      },
      "priority_desc": {
        "name": "Priority",
        "arrow": true
      },
      "priority": {
        "name": "Priority",
        "arrow": false
      },
      "custom_id_desc": {
        "name": "Custom id",
        "arrow": true
      },
      "custom_id": {
        "name": "Custom id",
        "arrow": false
      },
      "internal_id_desc": {
        "name": "Internal id",
        "arrow": true
      },
      "internal_id": {
        "name": "Internal id",
        "arrow": false
      },
      "id_desc": {
        "name": "Id",
        "arrow": true
      },
      "id": {
        "name": "Id",
        "arrow": false
      },
      "filesize_desc": {
        "name": "Filesize",
        "arrow": true
      },
      "filesize": {
        "name": "Filesize",
        "arrow": false
      },
      "planned_completed_desc": {
        "name": "Deadline",
        "arrow": true
      },
      "planned_completed": {
        "name": "Deadline",
        "arrow": false
      },
      "completed_desc": {
        "name": "Completed",
        "arrow": true
      },
      "completed": {
        "name": "Completed",
        "arrow": false
      },
      "content_published_at_desc": {
        "name": "Content published at",
        "arrow": true
      },
      "content_published_at": {
        "name": "Content published at",
        "arrow": false
      },
      "content_views_count_desc": {
        "name": "Content views count",
        "arrow": true
      },
      "content_views_count": {
        "name": "Content views count",
        "arrow": false
      }
    }    

    return sorts[val]  
  }

  getValueById(id) {
    if (!this.data.allValues || this.data.allValues.length == 0) {
      return null
    }
    return this.data.allValues.find(x => x.id == id)
  }

  getKeys() {
    return Object.keys(this.activeParams)
  }

  getValues(value) {
    let val; 
    if (typeof value == 'number') {
      val = value.toString()
    } else {
      val = value
    }
    return val.split(',')
  }

  activeSections() {
    switch (window.location.pathname) {
      case '/dashboard':
        return 'Dashboard'
        break;
      case '/tasks':
        return 'Cards'
        break;
      case '/jobs':
        return 'Jobs'
        break;
      case '/chats':
        return 'Chats'
        break;
      case '/board':
        return 'Board by Date'
        break;
      case '/board-list':
        return 'Board by Order'
        break;
      case '/urls':
        return 'Posts'
        break;
      case '/urls-dashboard':
        return 'Post Dashboards'
        break;
      case '/outlets-dashboard':
        return 'Outlet Dashboards'
        break;
      case '/settings':
        return 'Settings'
        break;
      case '/files':
        return 'Files'
        break;
      default:
        return window.location.pathname.replace('/', '')
        break;
    }
  }

  removeValue(key, val?) {
    if (key == "location_all" && val == '/') {
      return
    }

    if (!!val) {
      let arr = [...this.data.filter.value[key]];
      if (arr.includes(+val)) {
        arr.splice(arr.indexOf(+val), 1)
      }
      this.data.filter.patchValue({
        [key]: arr
      })
    } else {
      if (key == "all") {
        Object.keys(this.activeParams).forEach(key => {
          if (key.indexOf('_at_') == -1) {
            this.data.filter.patchValue({
              [key]: []
            })
          } else {
            this.data.filter.patchValue({
              [key]: ""
            })
          }
        })
      } else if (key.indexOf('_at_') == -1) {
        this.data.filter.patchValue({
          [key]: []
        })
      } else {
        this.data.filter.patchValue({
          [key]: ""
        })
      }
    }

    this.onSubmit(key == 'order_by');
  }

  clearAll() {

    Object.keys(this.activeParams).forEach(key => {
      console.log(key);
      if (key.indexOf('_at_') == -1) {
        this.data.filter.patchValue({
          [key]: []
        })
      } else {
        this.data.filter.patchValue({
          [key]: ""
        })
      }
    })
    this.onSubmit(true)
  }

  getViewsUpdateIntervalListByValue(val) {
    if (!this.data.viewsUpdateIntervalList || this.data.viewsUpdateIntervalList.length == 0) {
      return null
    }
    return this.data.viewsUpdateIntervalList.find(x => x.value == val)
  }

  getStatusById(id) {
    if (!this.data.statuses || this.data.statuses.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.data.statuses.find(el => el.id == id)
  }

  getTaskStatusById(id) {
    if (!this.data.taskStatuses || this.data.taskStatuses.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.data.taskStatuses.find(el => el.id == id)
  }

  getJobTypeById(id) {
    if (!this.data.jobTypes || this.data.jobTypes.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.data.jobTypes.find(el => el.id == id)
  }

  getPriorityById(id) {
    if (!this.data.prioritys || this.data.prioritys.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.data.prioritys.find(el => el.id == id)
  }

  getGroupById(id) {
    if (!this.data.groups || this.data.groups.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.data.groups.find(el => el.id == id)
  }
  
  getChecklistById(id) {
    if (!this.data.checklists || this.data.checklists.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.data.checklists.find(el => el.id == id)
  }

  getProfileByID(id) {
    if (!this.data.profiles || this.data.profiles.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.data.profiles.find(el => el.id == id)
  }

  getProfileStatusByID(id) {
    if (!this.data.profileStatuses || this.data.profileStatuses.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.data.profileStatuses.find(el => el.id == id)
  }

  getContentUrlByID(id) {
    if (!this.data.contentUrl || this.data.contentUrl.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.data.contentUrl.find(el => el.id == id)
  }

  getEmployeeById(id) {
    if (!this.data.employees || this.data.employees.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.data.employees.find(el => el.id == id)
  }  

  getContentById(id) {
    if (!this.data.allContentTypes || this.data.allContentTypes.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.data.allContentTypes.find(el => el.id == id)
  }

  
  getPartnerById(id) {
    if (!this.data.partners || this.data.partners.filter(el => el.partner_company_id == id).length == 0) {
      return false;
    }
    return this.data.partners.find(el => el.partner_company_id == id)
  }  

  getPlatformById(id) {
    if (!this.data.platforms || this.data.platforms.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.data.platforms.find(el => el.id == id)
  }

  close() {
    this.dialogRef.removePanelClass('animate__slideInUp');
    this.dialogRef.addPanelClass('animate__slideOutDown');
    setTimeout(()=>{this.dialogRef.close({event: 'close'})}, 300);
  }

  onSubmit(val:boolean = false) {
    this.dialogRef.removePanelClass('animate__slideInUp');
    this.dialogRef.addPanelClass('animate__slideOutDown');
    setTimeout(()=>{this.dialogRef.close({event: 'apply', data: val})}, 300);
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

}
