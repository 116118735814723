<h2 mat-dialog-title class="dialog_title">
    <span (click)="log()">
        {{ "Dubbing" | translate }}: {{ data.files && data.files.length ? ('selected files' | translate) : data.file.filename }}
    </span>
    <button class="ml-1" mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h2>
<mat-dialog-content class="mat-typography" dirEnter (dirSubmit)="!form.valid ? null : submitForm()">
    <div class="container">
        <form [formGroup]="form">
            <mat-form-field appearance="standard" class="full_width_form" *ngIf="!(data.files && data.files.length)">
                <mat-label>{{ "Source language" | translate }}</mat-label>
                <mat-spinner class="spin_24 mr-1" matPrefix *ngIf="isGettingTag"></mat-spinner>
                <mat-select [disableOptionCentering]="true" selectDir [multiple]="false" formControlName="source_language">
                    <mat-select-trigger class="multi_users">
                        <ng-container *ngIf="form.get('source_language').value">
                            <span>{{ getLangByVal(form.get('source_language').value)?.value }}</span>
                        </ng-container>
                    </mat-select-trigger>
                    <mat-option>
                        <ngx-mat-select-search 
                        placeholderLabel="{{ 'Search' | translate }}" 
                        noEntriesFoundLabel="{{ 'no matching language found' | translate }}"
                        [formControl]="langsControl"></ngx-mat-select-search>
                    </mat-option>
                    <ng-container *ngFor="let item of langs$ | async">
                        <mat-option *ngIf="!!item.iso_value" [value]="item.iso_value">
                            <span>{{ item.value }}</span>
                        </mat-option>
                    </ng-container>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ "Target language(s)" | translate }}</mat-label>
                <mat-select [disableOptionCentering]="true" selectDir [multiple]="true" formControlName="target_language">
                    <mat-select-trigger class="multi_users">
                        <ng-container *ngIf="form.get('target_language').value && form.get('target_language').value.length">
                            <ng-container *ngFor="let val of form.get('target_language').value">
                                <span>{{ getLangByVal(val)?.value }}</span>
                            </ng-container>
                        </ng-container>
                    </mat-select-trigger>
                    <mat-option>
                        <ngx-mat-select-search 
                        placeholderLabel="{{ 'Search' | translate }}" 
                        noEntriesFoundLabel="{{ 'no matching language found' | translate }}"
                        [formControl]="langsControl"></ngx-mat-select-search>
                    </mat-option>
                    <ng-container *ngFor="let item of langs$ | async">
                        <mat-option *ngIf="!!item.iso_value" [value]="item.iso_value">
                            <span>{{ item.value }}</span>
                        </mat-option>
                    </ng-container>
                </mat-select>

                <div class="ml-1 cp" (click)="$event.preventDefault(); $event.stopPropagation();" [ngClass]="{'hide': !((form.get('target_language').value && form.get('target_language').value.length) || bookmarksLangs?.length)}" matSuffix [matMenuTriggerFor]="targetLangsMenu" #menuLangsTrigger="matMenuTrigger">
                    <mat-icon color="#686868" class="">more_vert</mat-icon>
                </div>
                <mat-menu #targetLangsMenu="matMenu" xPosition="after">
                    <button class="scheme_item" *ngFor="let item of bookmarksLangs" mat-menu-item (click)="activeSchemeLang(item)">
                        <span>{{ item.name }}</span>
                        <div class="d-f-c show_scheme_hover" *ngIf="is_scheme_editing_langs">
                            <mat-icon class="small_scheme_icon green" (click)="editScheme($event, menuLangsTrigger, item, 'Target Language')">edit</mat-icon>
                            <mat-icon class="small_scheme_icon red" (click)="deleteScheme($event, menuLangsTrigger, item, 'Target Language')">delete</mat-icon>
                        </div>
                    </button>
                    <button class="scheme_item scheme_item_create" mat-menu-item (click)="createNewScheme($event, 'Target Language')" *ngIf="form.get('target_language').value && form.get('target_language').value.length">
                        <mat-icon>add</mat-icon>
                        <span>{{ "Create new scheme" | translate }}</span>
                        <mat-icon style="margin-left: 50px !important; margin-right: 0 !important;" *ngIf="bookmarksLangs && bookmarksLangs.length" [ngStyle]="{'color': is_scheme_editing_langs ? '#3f51b5' : '#686868'}" (click)="toggleSchemeEditingLangs($event)">settings</mat-icon>
                    </button>
                </mat-menu>
            </mat-form-field>

            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ "Inherit Tags from Category" | translate }}</mat-label>
                <mat-select [disableOptionCentering]="true" selectDir [multiple]="true" formControlName="inherit_parameters_ids">
                    <mat-option>
                        <ngx-mat-select-search 
                        placeholderLabel="{{ 'Search' | translate }}" 
                        noEntriesFoundLabel="{{ 'no matching tag category found' | translate }}"
                        [formControl]="paramControl"></ngx-mat-select-search>
                    </mat-option>
                    <ng-container *ngFor="let item of parameters$ | async">
                        <mat-option *ngIf="!!item.id" [value]="item.id">
                            <span>{{ item.name }}</span>
                        </mat-option>
                    </ng-container>
                </mat-select>
            </mat-form-field>
            

            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ "Folder for output files" | translate }}</mat-label>
                <mat-select [disableOptionCentering]="true" selectDir formControlName="folder">
                    <mat-select-trigger>
                        {{getFolderByLoc(form.get('folder').value)?.name}}
                    </mat-select-trigger>
                    <mat-option *ngFor="let item of folders" [value]="item.location">
                        {{ item.name | translate }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </form>

        <div style="margin-top: 10px;">
            {{"Output filename" | translate}}
        </div>
        <div class="result_name d-f-c" style="margin: 10px 0 20px 0;">
            <div class="d-f-c" style="width: calc(100% - 34px);">
                <b>{{getSchemeContent(filenameScheme)}}</b>
            </div>
            <div class="ml-1 cp" [matMenuTriggerFor]="clipboardMenu" #menuTrigger="matMenuTrigger">
                <mat-icon color="#686868" class="">more_vert</mat-icon>
            </div>
            <mat-menu #clipboardMenu="matMenu" xPosition="after">
                <button class="scheme_item" *ngFor="let item of bookmarks" mat-menu-item (click)="activeScheme(item)">
                    <span>{{ item.name }}</span>
                    <div class="d-f-c show_scheme_hover" *ngIf="is_scheme_editing">
                        <mat-icon class="small_scheme_icon green" (click)="editScheme($event, menuTrigger, item)">edit</mat-icon>
                        <mat-icon class="small_scheme_icon red" (click)="deleteScheme($event, menuTrigger, item)">delete</mat-icon>
                    </div>
                </button>
                <button class="scheme_item scheme_item_create" mat-menu-item (click)="createNewScheme($event)">
                    <mat-icon>add</mat-icon>
                    <span>{{ "Create new scheme" | translate }}</span>
                    <mat-icon style="margin-left: 50px !important; margin-right: 0 !important;" *ngIf="bookmarks && bookmarks.length" [ngStyle]="{'color': is_scheme_editing ? '#3f51b5' : '#686868'}" (click)="toggleSchemeEditing($event)">settings</mat-icon>
                </button>
            </mat-menu>
        </div>

        <div class="dub_files" [appDeviceClass]="'custom-scroll custom-scroll-t'" *ngIf="data.files && data.files.length">
            <div class="dub" *ngFor="let file of data.files">
                <div class="file_info">
                    <div class="filename text_one_line">{{ file?.filename | translate }}</div>
                    <div class="tags" style="color: #3f51b5;" *ngIf="file.primaryTags">
                        <ng-container *ngFor="let prim of file.primaryTags; let last = last">
                          <span>{{prim?.parameterValue?.value}}</span>
                          <ng-container *ngIf="!last">, </ng-container>
                        </ng-container>
                    </div>
                </div>
                <div class="file_lang" *ngIf="file.dub">
                    <mat-form-field appearance="standard" class="full_width_form">
                        <mat-label>{{ "Inherit Tags from Category" | translate }}</mat-label>
                        <mat-select [disableOptionCentering]="true" selectDir [multiple]="true" [(ngModel)]="file.dub.inherit_parameters_ids">
                            <mat-select-trigger class="multi_users">
                                <ng-container *ngIf="file.dub.inherit_parameters_ids && file.dub.inherit_parameters_ids.length">
                                    <ng-container *ngFor="let val of file.dub.inherit_parameters_ids">
                                        <span>{{ getParamById(val)?.name }}</span>
                                    </ng-container>
                                </ng-container>
                            </mat-select-trigger>
                            <mat-option>
                                <ngx-mat-select-search 
                                placeholderLabel="{{ 'Search' | translate }}" 
                                noEntriesFoundLabel="{{ 'no matching tag category found' | translate }}"
                                [formControl]="paramControl"></ngx-mat-select-search>
                            </mat-option>
                            <ng-container *ngFor="let item of parameters$ | async">
                                <mat-option *ngIf="!!item.id" [value]="item.id">
                                    <span>{{ item.name }}</span>
                                </mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>

                    <div class="split vert"></div>

                    <mat-form-field appearance="standard" class="full_width_form">
                        <mat-label>{{ "Source language" | translate }}</mat-label>
                        <mat-spinner class="spin_24 mr-1" matPrefix *ngIf="file.dub.is_getting"></mat-spinner>
                        <mat-select [disableOptionCentering]="true" selectDir [multiple]="false" [(ngModel)]="file.dub.source_language">
                            <mat-select-trigger class="multi_users">
                                <ng-container *ngIf="file.dub.source_language">
                                    <span>{{ getLangByVal(file.dub.source_language)?.value }}</span>
                                </ng-container>
                            </mat-select-trigger>
                            <mat-option>
                                <ngx-mat-select-search 
                                placeholderLabel="{{ 'Search' | translate }}" 
                                noEntriesFoundLabel="{{ 'no matching language found' | translate }}"
                                [formControl]="langsControl"></ngx-mat-select-search>
                            </mat-option>
                            <ng-container *ngFor="let item of langs$ | async">
                                <mat-option *ngIf="!!item.iso_value" [value]="item.iso_value">
                                    <span>{{ item.value }}</span>
                                </mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>

                    <div class="split"></div>

                    <mat-form-field appearance="standard" class="full_width_form">
                        <mat-label>{{ "Target language(s)" | translate }}</mat-label>
                        <mat-select [disableOptionCentering]="true" selectDir [multiple]="true" [(ngModel)]="file.dub.target_language">
                            <mat-select-trigger class="multi_users">
                                <ng-container *ngIf="file.dub.target_language && file.dub.target_language.length">
                                    <ng-container *ngFor="let val of file.dub.target_language">
                                        <span>{{ getLangByVal(val)?.value }}</span>
                                    </ng-container>
                                </ng-container>
                            </mat-select-trigger>
                            <mat-option>
                                <ngx-mat-select-search 
                                placeholderLabel="{{ 'Search' | translate }}" 
                                noEntriesFoundLabel="{{ 'no matching language found' | translate }}"
                                [formControl]="langsControl"></ngx-mat-select-search>
                            </mat-option>
                            <ng-container *ngFor="let item of langs$ | async">
                                <mat-option *ngIf="!!item.iso_value" [value]="item.iso_value">
                                    <span>{{ item.value }}</span>
                                </mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <!-- <div class="box_wrp d-f-c" style="height: 40px;">
            <mat-checkbox color="primary" [(ngModel)]="is_remember">{{"Remember selection for this device" | translate}}</mat-checkbox>
        </div> -->
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button (click)="close()">
        {{ "Close" | translate }}
    </button>
    <ng-container *ngIf="data.files && data.files.length; else elseTemplateMultiFiles">        
        <button class="ml-1" (click)="multiSubmitForm()" mat-raised-button color="primary" [disabled]="isSubmit">
            {{ "Start Dubbing" | translate }}
        </button>
    </ng-container>
    <ng-template #elseTemplateMultiFiles>
        <button class="ml-1" (click)="submitForm()" mat-raised-button color="primary" [disabled]="isSubmit">
            {{ "Start Dubbing" | translate }}
        </button>
    </ng-template>
    
</mat-dialog-actions>
