<div class="loaders_wrp" [ngClass]="{'has_loaders': loaders.length}" *ngIf="loaders.length">
    <div class="loader" *ngFor="let loader of loaders">
        <div class="loader_status">
            {{ loader.target | translate }}
        </div>
        <div class="loader_spinner_wrp">
            <div class="progress_val">
                {{ loader.value }}
            </div>
            <mat-progress-spinner
                class="example-margin"
                [color]="'primary'"
                [mode]="'determinate'"
                [value]="loader.value">
            </mat-progress-spinner>
        </div>
    </div>
</div>