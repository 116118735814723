import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, tap } from 'rxjs/operators';
import { BaseClass } from 'src/app/shared/models/base-class';
import { TaskService } from 'src/app/shared/services/rest/task.service';

@Component({
  selector: 'app-same-url-tasks',
  templateUrl: './same-url-tasks.component.html',
  styleUrls: ['./same-url-tasks.component.scss']
})
export class SameUrlTasksComponent extends BaseClass implements OnInit, AfterViewInit, OnDestroy  {
  @ViewChild("tasksContainer") tasksContainer: ElementRef;
  public page: number = 1;
  public pagination: any;
  public tasks: any[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<SameUrlTasksComponent>,
    private taskService: TaskService
  ) { super() }

  ngOnInit(): void {
    console.log(" DATA INSIDE DIALOG",this.data)
    this.getTasksArray(this.page)
  }

  ngAfterViewInit(): void {
    this.onScroll();
  }

  onScroll() {
    this.attachSubscriptions(
      fromEvent(this.tasksContainer.nativeElement, "scroll").pipe(
        filter((e:any) => e.target.scrollTop >=  e.target.scrollHeight - e.target.offsetHeight - 400),
        debounceTime(300),
        map((e:any) => Math.ceil((e.target.scrollTop + e.target.innerHeight)/ (400))),
        distinctUntilChanged()
      ).subscribe(() => {
          if (this.page <= this.pagination['pageCount']) {
            this.getTasksArray(this.page);
          }
        }
      )
    )
  }

  getTasksArray(page) {
    this.attachSubscriptions(
      this.taskService.getTasksArray(page, this.data.company.id, this.data.tasks.map(Number)).pipe(
        tap(el => {
          this.pagination = {
            'pageCount': el.headers.get('x-pagination-page-count'),
            'perPage': el.headers.get('x-pagination-per-page'),
            'totalCount': el.headers.get('x-pagination-total-count'),
            'currentPage': el.headers.get('x-pagination-current-page'),
          }
        }),
        map(el => el.body)
      ).subscribe(result => {
        console.log("getTasksArray",result);
        result.forEach(element => {
          if (!this.tasks.includes(element)) {
            this.tasks.push(element);
          }
        });
        this.page = this.page + 1;
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false});
  }

  ngOnDestroy(): void {
    this.clearSubscriptions();
  }

}
