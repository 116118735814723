import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { concat, forkJoin, Subscription } from 'rxjs';
import { concatMap, last, map, switchMap, tap } from 'rxjs/operators';
import { DeleteAlertComponent } from 'src/app/shared/global_components/delete-alert/delete-alert.component';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { AuthService } from 'src/app/shared/services/rest/auth.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { ParametersService } from 'src/app/shared/services/rest/parameters.service';
import { environment } from 'src/environments/environment';
import { AddParameterFromCollectionComponent } from './dialogs/add-parameter-from-collection/add-parameter-from-collection.component';
import { NewParamenterValuesComponent } from './dialogs/new-paramenter-values/new-paramenter-values.component';
import { TipsInterfaceComponent } from 'src/app/shared/global_components/tips-interface/tips-interface.component';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-workspace-parameters',
  templateUrl: './workspace-parameters.component.html',
  styleUrls: ['./workspace-parameters.component.scss']
})
export class WorkspaceParametersComponent extends BaseClass implements OnInit, OnDestroy {
  public isExpanded: boolean = true;
  public activeTab: any;
  public company: any;
  public host: any = environment.host;
  public imgRoute: any = '';
  public company_id: any;
  public users: any;
  public parameters: any;
  public _user: any;
  public paramDataSub: Subscription;
  constructor(
    private activatedRoute: ActivatedRoute,
    private auth: AuthService,
    private layoutService: LayoutService,
    private companyService: CompanyService,
    private parametersService: ParametersService,
    private dialog: MatDialog,
    private bottomSheet: MatBottomSheet,
    private sm: StorageManagerService
  ) { 
    super()
   }

  ngOnInit(): void {
    this.company_id = this.activatedRoute.snapshot.queryParamMap.get('company_id');
    this.getUser();
    this.getCompany();
    this.getImgRoute();

    this.paramDataSub = this.parametersService.getParamsStream().pipe(
      concatMap(params => {
        return concat(...params.map(oneParam => this.neededParametersData(oneParam))).pipe(last(),map(x => params))
      }),
    ).subscribe(resp => console.log("-----getParamsStream-----",resp));
    this.getParameters()

  }

  getParameters() {
    this.attachSubscriptions(
      this.parametersService.getApiParameters(this.company_id).pipe(
        tap(res => {
          this.parametersService.paramsData$.next(res)
        })
      ).subscribe(resp => {
        this.parameters = resp
        console.log("this.parameters", this.parameters)
      })
    )
  }
  neededParametersData(param) {
    let arr = []

    arr.push(
      this.parametersService.getApiParameterValues(this.company_id, param.id, '200', '1', '', null, null, ',original').pipe(
        map(y => y.body),
        tap(x => {
          param.values = x
        })
      )
    )

    return forkJoin(arr)
  }

  getCompany() {
    this.attachSubscriptions(
      this.companyService.getCompany(this.company_id).subscribe(resp => {
        this.companyService.company$.next(resp[0]);
        this.company = resp[0];
      })
    )
  }

  updateParameter(param) {
    this.attachSubscriptions(
      this.parametersService.importApiParameter({company_id: this.company_id, id: param.original_id}).subscribe(resp => {
        this.getParameters()
      })
    )
  }

  toggleParam(param) {
    param.isOpen = !param.isOpen
    console.log("toggleParam(param)", param)
  }

  drop(event: CdkDragDrop<string[]>) {
    console.log("drop", event);
    console.log("drop", event.previousIndex + " -> " + event.currentIndex);


    let targetVal = event.item.data;
    moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    let values:any = event.container.data;
    let prevSibling:any = values[event.currentIndex - 1];
    let nextSibling:any = values[event.currentIndex + 1];

    let sbmtData:any = {
      order_after_id: 0
    }
    if (event.currentIndex == 0) {
      sbmtData.order_after_id = 0;
    } else if (event.currentIndex == values.length - 1) {
      sbmtData.order_after_id = -1;
    } else {
      sbmtData.order_after_id = prevSibling.id;
    }
    this.attachSubscriptions(
      this.parametersService.editApiParameterValue(targetVal.id, sbmtData).subscribe(resp => {
        console.log(resp);
      }, error => {
        moveItemInArray(event.container.data, event.currentIndex, event.previousIndex);
      })
    )
  }

  getNewValues(param) {
    const dialogRef = this.dialog.open(NewParamenterValuesComponent, {
      data: {
        company_id: this.company_id,
        param: param
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (result && result.event == "add") {
          this.getParameters()
        }
      })
    )
  }

  changeValueAssigned(e, value) {
    console.log(e, value)
    this.attachSubscriptions(
      this.parametersService.editApiParameterValue(value.id, {is_not_automatically_assigned: e.checked ? 1 : 0}).subscribe(resp => {
        value.is_not_automatically_assigned = resp.is_not_automatically_assigned
        console.log(resp);
      }, error => {
        this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
      })
    )
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }
  
  addApiParameterValue(param) {
    this.attachSubscriptions(
      this.parametersService.addApiParameterValue({company_id: this.company_id, parameter_id: param.id, value: "New value"}).subscribe(resp => {
        param.values.push(resp)
        this.focusValue(param, resp)
      })
    )
  }

  editApiParameterValue(param, value, e) {
    console.log(param, value, e)
    value.isEddited = false;

    this.attachSubscriptions(
      this.parametersService.editApiParameterValue(value.id, {value: e.target.value}).subscribe(resp => {
        console.log("editApiParameterValue", resp)
        value = resp;
      }, error => {
        value.value = "New value"
      })
    )
  }

  editApiParameter(param, e) {
    param.isEddited = false;

    this.attachSubscriptions(
      this.parametersService.editApiParameter(param.id, {name: e.target.value}).subscribe(resp => {
        console.log("editApiParameterValue", resp)
        param.name = resp.name;
        param.updated_at = resp.updated_at
      }, error => {
        param.name = "New WorkSpace parameter";
      })
    )
  }



  saveValue(param, value) {
    value.isEddited = false

    if (value.value == "") {
      value.value = "New value";
    }
  }

  focusValue(param, value) {
    value.isEddited = true;
    if (value.value == "New value") {
      value.value = ""
    }
    setTimeout(() => {
      if (document.getElementById(`val_${param.id}_${value.id}`)) {
        document.getElementById(`val_${param.id}_${value.id}`).focus()
      }
    }, 0)
  }

  deleteValue(param, val, i) {
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: val,
        target: marker("parameter value")
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        this.attachSubscriptions(
          this.parametersService.deleteApiParameterValue(val.id).subscribe(resp => {
            param.values.splice(i, 1)
          })
        )
      }
    });

  }

  deleteApiParam(param, i) {
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: param,
        target: marker("Api parameter")
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        this.attachSubscriptions(
          this.parametersService.deleteApiParameter(param.id).subscribe(resp => {
            this.parameters.splice(i, 1)
          })
        )
      }
    });

  }

  saveApiParam(param) {
    param.isEddited = false
    if (param.name == "") {
      param.name = "New WorkSpace parameter";
    }
  }

  focusApiParam(param) {
    param.isEddited = true;
    if (param.name == "New WorkSpace parameter") {
      param.name = ""
    }

    setTimeout(() => {
      if (document.getElementById(`param_${param.id}`)) {
        document.getElementById(`param_${param.id}`).focus()
      }
    }, 0)
  }

  addApiParameter() {
    this.attachSubscriptions(
      this.parametersService.addApiParameter({company_id: this.company_id, name: "New WorkSpace parameter"})
      .pipe(
        tap(b => { b.values = [] })
      ).subscribe(resp => {
        this.parameters.push(resp);
        this.focusApiParam(resp);
      })
    )
  }

  

  openTips(param, value?) {
    let tipsData:any = {
      company_id: this.company_id,
      parameters: this.parameters,
      param: param
    }
    if (value) {
      tipsData.value = value;
    }
    const dialogRef = this.dialog.open(TipsInterfaceComponent, {
      data: tipsData
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (result && result.event == "add") {
          this.getParameters()
        }
      })
    )
  }

  addParameterFromCollection() {
    const dialogRef = this.dialog.open(AddParameterFromCollectionComponent, {
      data: {
        company_id: this.company_id,
        parameters: this.parameters
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (result && result.event == "add") {
          this.getParameters()
        }
      })
    )
  }

  getUser() {
    this.attachSubscriptions(
      this.auth.$user.subscribe(resp => {
        this._user = resp;
      })
    )
  }

  goBack() {
    history.back();
  }

  ngOnDestroy(): void {
    if (this.paramDataSub) {
      this.paramDataSub.unsubscribe();
    }
    this.clearSubscriptions()
  }
}
