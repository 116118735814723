import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { debounceTime, map, switchMap } from 'rxjs/operators';
import { BaseClass } from 'src/app/shared/models/base-class';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { RefreshService } from 'src/app/shared/services/rest/refresh.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';

@Component({
  selector: 'app-add-employee-to-bookmark',
  templateUrl: './add-employee-to-bookmark.component.html',
  styleUrls: ['./add-employee-to-bookmark.component.scss']
})

export class AddEmployeeToBookmarkComponent extends BaseClass implements OnInit, OnDestroy {

  public isSubmit: boolean = false;
  public submited: boolean = false;
  public switched: boolean = false;

  public employeeMoreControl: FormControl = new FormControl();
  public employees$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public form: FormGroup = this.fb.group({
    employee_id: [this.data.bookmark.sectionToEmployees.map(x => x.employee_id)],
  })
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private taskService: TaskService,
    private sm: StorageManagerService,
    public dialogRef: MatDialogRef<AddEmployeeToBookmarkComponent>,
    private fb: FormBuilder,
  ) { super() }

  ngOnInit(): void {
    this.dialogRef.addPanelClass("create_task_by_manager_modal")
    console.log("AddEmployeeToBookmarkComponent", this.data)

    if (this.data.employees) {
      this.data.employees.map(x => {
        if (this.data.bookmark.sectionToEmployees.filter(b => b.employee_id == x.id).length > 0) {
          x.is_save = 1;
        }
        x.is_active = 1;
        x.is_shortcut = 0;
      })
      this.employees$.next(this.data.employees.slice())
    }

    this.attachSubscriptions(
      this.employeeMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchEmoloyees(resp))
    )
    
    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        this.close();
      })
    )

  }

  editEmployeeBookmark(employee, key) {
    employee[key] = employee[key] == 1 ? 0 : 1
  }
  
  toggleChip = (chip: any) => {
    this.switched = true;
    if (!!this.form.value.employee_id && this.form.value.employee_id.includes(!!chip.id ? chip.id : chip.value)) {
      let val = [...this.form.value.employee_id]
      val.splice(this.form.value.employee_id.indexOf(!!chip.id ? chip.id : chip.value), 1)
      this.form.patchValue({
        employee_id: val
      })
    } else {
      if (!!this.form.value.employee_id) {
        let val = [...this.form.value.employee_id]
        val.push(!!chip.id ? chip.id : chip.value)
        this.form.patchValue({
          employee_id: val
        })
      } else {
        this.form.patchValue({
          employee_id: [!!chip.id ? chip.id : chip.value]
        })
      }
    }

    this.switched = false
  }

  onSearchEmoloyees(resp) {
    if (!this.data.employees) {
      return;
    }

    if (!resp) {
      this.employees$.next(this.data.employees.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.employees$.next(
      this.data.employees.filter(z => z.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  close() {
    this.dialogRef.close({event: 'close'});
  }

  getEmployeeById(id) {
    if (!this.data.employees || this.data.employees.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.data.employees.find(el => el.id == id)
  }  

  save() {
    if (!this.form.valid) {
      this.submited = true;
      console.log(this.form);
      return
    }
    this.submited = false;
    this.isSubmit = true;
    
    let addData = {...this.form.value}

    console.log("this.form.value.employee_id", this.form.value.employee_id)

    let postData = [];
    
    addData.employee_id.forEach(id => {
      if (this.data.bookmark.sectionToEmployees.map(x => x.employee_id).includes(id)) {
        return
      }
      postData.push({
        "path": `/api/section-to-employee/`,
        "query": {},
        "method": "POST",
        "body": Object.assign({
          [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
          employee_id: id,
        }, {
          company_id: this.data.company.id,
          section_id: this.data.bookmark.id,
          order: 0,
          is_active: !!this.getEmployeeById(id).is_active ? 1 : 0,
          is_shortcut: !!this.getEmployeeById(id).is_shortcut ? 1 : 0,
        }) 
      })
    });

    this.attachSubscriptions(
      this.taskService.multiRequest(postData).subscribe(res => {
        console.log("addBookmark", res)
        this.isSubmit = false
        this.dialogRef.close({event: 'save'});
      })
    )

  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

}
