<mat-dialog-content class="mat-typography">
    <div class="container form_wrapper" [appDeviceClass]="'custom-scroll custom-scroll-t'">
        <div class="title_modal d-f-c">
            <span class="set_width">
                {{ "Migrate all" | translate }} {{(!!data.target.task_operation_id ? "job" : "card") | translate}} {{"files" | translate}}
            </span>
        </div>

        <!-- (infiniteScroll)="getNextDepartments()" -->
        <form [formGroup]="form" class="form_with_pads" (ngSubmit)="onSubmit()">
            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ "Storage" | translate }}</mat-label>
                <mat-select disableOptionCentering formControlName="to_storage_code">
                    <mat-option [value]="item.storage_code" [ngClass]="{'cst_active_select_option': form.get('to_storage_code').value == item.storage_code}" *ngFor="let item of storages">
                        {{ item.name }}
                    </mat-option>
                </mat-select>
                <button class="suffix_button" [ngStyle]="{'color': (!form.get('to_storage_code').value || isSubmit) ? '#686868' : '#4E844E'}" [disabled]="!form.get('to_storage_code').value || isSubmit" type="submit" (click)="$event.stopPropagation()" mat-icon-button matSuffix>
                    <mat-icon>add_circle</mat-icon>
                </button>
            </mat-form-field>
        </form>

        <mat-nav-list *ngIf="migrations?.length">
            <mat-list-item *ngFor="let item of migrations; let i = index;" class="data_out_item">
                <div class="data_item">
                    <div class="data_item_info">
                        <div class="id">ID: {{item.id}}</div>
                        <div class="code">{{item.to_storage_code}}</div>
                    </div>
                    <mat-icon style="color: red;" (click)="deleteBatchMigration(item.id, i)">delete</mat-icon>
                </div>
            </mat-list-item>
        </mat-nav-list>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <div class="f_w_p_btns">
        <button mat-raised-button (click)="close()">
            {{ "Close" | translate }}
        </button>
    </div>
</mat-dialog-actions>