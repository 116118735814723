import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseClass } from 'src/app/shared/models/base-class';

@Component({
  selector: 'app-add-edit-view-variable',
  templateUrl: './add-edit-view-variable.component.html',
  styleUrls: ['./add-edit-view-variable.component.scss']
})
export class AddEditViewVariableComponent extends BaseClass implements OnInit, OnDestroy {
  public variables;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddEditViewVariableComponent>,
  ) { super() }

  ngOnInit(): void {
    console.log("AddEditViewVariableComponent", this.data)

    this.variables = JSON.parse(JSON.stringify(this.data.variables))
  }

  close() {
    this.dialogRef.close({event: "close", data: false});
  }

  submitForm() {
    // let sbmtForm:any = {...this.form.value};
    this.dialogRef.close({event: "close", data: this.data.variable});
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
