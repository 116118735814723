import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { MatTable } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { fromEvent, of, ReplaySubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';
import { DeleteAlertComponent } from 'src/app/shared/global_components/delete-alert/delete-alert.component';
import { BaseClass } from 'src/app/shared/models/base-class';
import { GlobalDataService } from 'src/app/shared/services/common/global-data.service';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { AuthService } from 'src/app/shared/services/rest/auth.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { environment } from 'src/environments/environment';
import { AddPaymentComponent } from '../add-payment/add-payment.component';
import { EditPaymentComponent } from '../edit-payment/edit-payment.component';
import * as moment from 'moment';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss']
})
export class PaymentsComponent extends BaseClass implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild("tableContainer") tableContainer: ElementRef;
  @ViewChild(MatTable) table: MatTable<any>;
  public host: any = environment.host;
  public imgRoute: any = '';
  public user: any = {};
  public user_id: number;
  public page: number = 1;
  public company_id: any;
  public company: any;
  public me: any;
  public pagination: any;
  public timeZone: any;
  public activeLang: any;
  public currencyList: any;
  public payments: any[] = [];
  public employees: any;
  public filter: FormGroup = this.fb.group({
    employee_id: '',
    partner_company_id: ''
  });
  @Input() withoutFilter:boolean = false;
  public currencyes: any[] = [];
  public displayedColumns: string[] = ["Date Posted", 'Real_paid_at', "Payment System", "Employee", "Amount", "Comment"];
  public employees$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public execControl: FormControl = new FormControl();
  
  public partners: any;
  public partnersControl: FormControl = new FormControl();
  public partners$: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(
    private sm: StorageManagerService,
    private auth: AuthService,
    private companyService: CompanyService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private languageService: LanguageService,
    private dialog: MatDialog,
    private globalDataService: GlobalDataService,
    private bottomSheet: MatBottomSheet,
    private taskService: TaskService,
    private fb: FormBuilder
  ) { super() }

  ngOnInit(): void {
    window.scroll(0,0);
    this.timeZone = new Date().getTimezoneOffset();
    this.company_id = this.activatedRoute.snapshot.queryParamMap.get('company_id');
    
    this.getCurrencyList();

    if (!!this.activatedRoute.snapshot.queryParamMap.get('employee_id')) {
      this.filter.patchValue({
        employee_id: +this.activatedRoute.snapshot.queryParamMap.get('employee_id')
      })
    }

    // this.filter.get('employee_id').valueChanges.subscribe(resp => {
    //   this.page = 1;
    //   this.payments = [];
    //   this.currencyes.map(x => x.val = 0);
    //   this.getPayments(this.page, resp)
    //   // this.router.navigate(['/payments'], { queryParams: {company_id: this.company_id, id: resp}});
    // })
    // this.filter.get('partner_company_id').valueChanges.subscribe(resp => {
      
    //   this.page = 1;
    //   this.payments = [];
    //   this.currencyes.map(x => x.val = 0);
    //   this.getPayments(this.page, 0, resp)
    //   // this.router.navigate(['/payments'], { queryParams: {company_id: this.company_id, id: resp}});
    // })


    
    // this.getCompany();
    this.getImgRoute();
    this.getCsrf();
    this.getUser();
    this.getLangData();
    this.getEmployees();
    this.getPartners();

    this.attachSubscriptions(
      this.execControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchEmoloyees(resp))
    )

    
    this.attachSubscriptions(
      this.partnersControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchPartners(resp))
    )
    this.onRouteChange();
  }

  getPartner(id) {
    if (!this.partners) {
      return false;
    }
    return this.partners.find(el => el.partner_company_id == id)
  }  

  getEmployee(id) {
    if (!this.employees) {
      return false;
    }
    return this.employees.find(el => el.id == id)
  }

  log() {
    console.log('filter', this.filter.value);
  }

  onRouteChange() {
    this.attachSubscriptions(
      this.activatedRoute.queryParams.pipe(
        tap((val) => {
          if (this.company_id != this.activatedRoute.snapshot.queryParamMap.get('company_id')) {
            this.company_id = this.activatedRoute.snapshot.queryParamMap.get('company_id');
            this.getEmployees();
            this.getPartners();
          }
          // this.sortValue = !!this.activatedRoute.snapshot.queryParamMap.get('order_by') ? this.activatedRoute.snapshot.queryParamMap.get('order_by') : "updated_desc";
          this.filter.patchValue({
            employee_id: (!!this.activatedRoute.snapshot.queryParamMap.get('operation_employee_id') || !!this.activatedRoute.snapshot.queryParamMap.get('employee_id')) ? +(this.activatedRoute.snapshot.queryParamMap.get('operation_employee_id') || this.activatedRoute.snapshot.queryParamMap.get('employee_id')) : "",
            partner_company_id: !!this.activatedRoute.snapshot.queryParamMap.get('operation_partner_company_id') ? this.activatedRoute.snapshot.queryParamMap.get('operation_partner_company_id') : "",
          })
        }),
        switchMap((val) => {
          if (this.company == undefined || this.company.id != this.activatedRoute.snapshot.queryParamMap.get('company_id')) {
            return this.companyService.getCompany(this.activatedRoute.snapshot.queryParamMap.get('company_id')).pipe(
              map(x => x[0]),
              tap(x => {
                this.company = x;
                this.me = this.company.employees[0];
                this.companyService.company$.next(x);
                if ((this.company.permissions.includes('owner') || this.company.permissions.includes('admin')) && !this.displayedColumns.includes('Activity')) {
                  this.displayedColumns.push("Activity")
                }
              }),
              map(x => val)
            )
          } else {
            return of(val)
          }
        }),
        debounceTime(300)
      ).subscribe(params => {
        console.log("route STREAM", params);
        console.log("route COMPANY", this.company);

        this.page = 1;
        this.payments = [];
        this.currencyes.map(x => x.val = 0);
        this.getPayments(this.page)
      })
    )
  }

  onSearchPartners(resp) {
    if (!this.partners) {
      return;
    }

    if (!resp) {
      this.partners$.next(this.partners.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.partners$.next(
      this.partners.filter(z => z.partnerCompany.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  addPayment(e) {
    e.preventDefault();
    const dialogRef = this.dialog.open(AddPaymentComponent, {
      disableClose: true,
      data: {
        user: this.user,
        company: this.company,
        company_id: this.company_id,
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result && result.event == "update") {
          this.page = 1;
          this.currencyes.map(x => x.val = 0);
          this.getPayments(this.page)
        }
      })
    )
  }

  
  editPayment(e, element) {
    e.preventDefault();
    const dialogRef = this.dialog.open(EditPaymentComponent, {
      disableClose: true,
      data: {
        user: this.user,
        company: this.company,
        company_id: this.company_id,
        payment: element
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result && result.event == "update") {
          this.page = 1;
          this.currencyes.map(x => x.val = 0);
          this.getPayments(this.page)
        }
      })
    )
  }

  onSearchEmoloyees(resp) {
    if (!this.employees) {
      return;
    }

    if (!resp) {
      this.employees$.next(this.employees.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.employees$.next(
      this.employees.filter(z => z.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  ngAfterViewInit(): void {
    this.onScroll();
  }

  getEmployees() {
    this.attachSubscriptions(
      this.taskService.getEmployees(this.company_id).subscribe(resp => {
        this.employees = resp;
        this.employees$.next(this.employees.slice())
      })
    )
  }

  getPartners() {
    this.attachSubscriptions(
      this.companyService.getPartners({company_id: this.company_id}).subscribe(resp => {
        this.partners = resp.filter(x => x.partner_company_id != 0);
        this.partners$.next(this.partners.slice())
      })
    )
  }

  getCurrency(id) {
    if (!!this.currencyList) {
      return this.currencyList.find(el => el.id == id)
    }
  }
  
  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData().subscribe(resp => {
        this.activeLang = resp.active;
      })
    )
  }

  download(payment) {
    payment.files.forEach(el => {
      console.log(this.host + el.download_url + "?acm=" + el.acm)
      window.open(this.host + el.download_url + "?acm=" + el.acm, '_blank');
    })
  }

  getCurrencyList() {
    // this.attachSubscriptions(
    //   this.globalDataService.getCurrencies().subscribe(resp => {
    //     console.log("getCurrencyList", resp)
    //     this.currencyList = resp.slice();
    //     this.currencyes = [];
    //     this.currencyes.push(...this.currencyList)
    //     this.getPayments(this.page);
    //   })
    // )
    this.attachSubscriptions(
      this.companyService.getCurrencyList().subscribe(resp => {
        this.currencyList = resp;
        this.currencyes.push(...resp)
        console.log("getCurrencyList", this.currencyes);
        // this.getPayments(this.page)
      })
    )
  }

  removeValue(val) {
    if (val == "all") {
      let params = {
        company_id: this.company_id,
        page: this.activatedRoute.snapshot.queryParamMap.get('page'),
        tab: this.activatedRoute.snapshot.queryParamMap.get('tab')
      }
      this.router.navigate(['/settings'], {queryParams: params})
    } else {
      let params = {...this.activatedRoute.queryParamMap.source['_value']}
      delete params[val]

      this.router.navigate(['/settings'], {queryParams: params})
    }
  }

  removeValueFromMultiple(key, value) {
    let arr = this.filter.value[key];

    console.log(key, value);
    console.log(arr);
    
    if (arr.includes(value)) {
      arr.splice(arr.indexOf(value), 1)
    }
    console.log(arr);
    console.log(this.filter.value[key]);
    // return 
    // this.filter.patchValue({
    //   [key]: arr
    // })
    this.filterData()
  }

  
  filterData() {
    let params = {
      company_id: this.company_id,
      page: this.activatedRoute.snapshot.queryParamMap.get('page'),
      tab: this.activatedRoute.snapshot.queryParamMap.get('tab')
    };

    Object.keys(this.filter.value).forEach(element => {
      if (!!this.filter.value[element]) {
        if (element == 'employee_id') {
          params['operation_employee_id'] = this.filter.value[element]
        } else {
          params[element] = this.filter.value[element]
        }
      }
    });

    this.router.navigate(['/settings'], { queryParams: params});
  }

  onScroll() {
    this.attachSubscriptions(
      fromEvent(this.tableContainer.nativeElement, "scroll").pipe(
        filter((e:any) => e.target.scrollTop >=  e.target.scrollHeight - e.target.offsetHeight - 400),
        map(() => this.tableContainer.nativeElement.scrollTop),
        debounceTime(300),
        map(y => Math.ceil((y + this.tableContainer.nativeElement.innerHeight)/ (400))),
        distinctUntilChanged()
      ).subscribe(() => {
          if (this.page <= this.pagination['pageCount']) {
            this.getPayments(this.page);
          }
        }
      )
    )
  }

  deletePayment(payment) {
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: {},
        target: marker("payment")
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        this.attachSubscriptions(
          this.companyService.deletePayment(payment.id).subscribe(resp => {
            this.payments.splice(this.payments.indexOf(payment), 1);
            this.table.renderRows();
          })
        )
      }
    });

  }


  goBack(e) {
    e.preventDefault();
    history.back();
  }
  
  getPayments(page) {
    this.attachSubscriptions(
      this.companyService.getPayments(page, this.company_id, this.filter.value.employee_id, this.filter.value.partner_company_id).pipe(
        tap(el => {
          this.pagination = {
            'pageCount': el.headers.get('x-pagination-page-count'),
            'perPage': el.headers.get('x-pagination-per-page'),
            'totalCount': el.headers.get('x-pagination-total-count'),
            'currentPage': el.headers.get('x-pagination-current-page'),
          }
        }),
        map(el => el.body),
        distinctUntilChanged()
      ).subscribe(resp => {
        resp.forEach(element => {
          if (this.currencyes.find(x => x.id == element.currency_id)) {
            if (!this.currencyes.find(x => x.id == element.currency_id).hasOwnProperty('val')) {
              this.currencyes.find(x => x.id == element.currency_id).val = 0
            }
            this.currencyes.find(x => x.id == element.currency_id).val = +this.currencyes.find(x => x.id == element.currency_id).val + +element.amount
          }
        });
        if (page == 1) {
          this.payments = resp
        } else {
          this.payments.push(...resp);
        }
        this.page = this.page + 1;

        
        
        console.log('payments', this.payments)
        console.log('currencyes', this.currencyes)
        this.table.renderRows();
      })
    )
  }

  getCompany() {
    this.attachSubscriptions(
      this.companyService.getCompany(this.company_id).subscribe(resp => {
        this.companyService.company$.next(resp[0]);
        this.company = resp[0];
        if ((this.company.permissions.includes('owner') || this.company.permissions.includes('admin')) && !this.displayedColumns.includes('Activity')) {
          this.displayedColumns.push("Activity")
        }
      })
    )
  }

  roundNumber(num) {
    return Math.round( num );
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  getCsrf() {
    this.attachSubscriptions(
      this.auth.$userToken.subscribe(resp => {
        this.user_id = resp.user_id;
        this.sm.localStorageSetItem("csrf_param", resp.csrf_param)
        this.sm.localStorageSetItem("csrf_token", resp.csrf_token)
      })
    )
  }

  getUser() {
    this.attachSubscriptions(
      this.auth.$user.subscribe(resp => {
        this.user = resp;
      })
    )
  }

  ngOnDestroy() {
    this.clearSubscriptions()
  }

}
