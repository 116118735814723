

<h2 mat-dialog-title class="dialog_title">
    <span>{{ "Assigned executors for Task Template" | translate }}</span>
    <button class="ml-1" mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h2>

<mat-dialog-content class="mat-typography">
    <div class="container">
        <mat-list role="list">
            <mat-list-item role="listitem" *ngFor="let executor of executors; let i = index;">
                <div class="add_empl">
                    <div class="add_empl_val">
                        <app-user [class]="'user_div'" [employee]="executor?.employee"></app-user>
                    </div>
                    <div class="add_empl_btns" style="padding-left: 50px;" *ngIf="data?.company?.permissions.includes('owner') || checkIsManager(data?.task, data?.company, data?.user)">
                        <!-- <mat-icon style="color: green;" (click)="editExecutor(executor)">edit</mat-icon> -->
                        <mat-icon class="cp" style="color: brown;" (click)="deleteExecutor(executor?.id, i)">delete</mat-icon>
                    </div>
                </div>
            </mat-list-item>
        </mat-list>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-raised-button (click)="close()">
        {{ "Back" | translate }}
    </button>
    <button class="ml-1 d-f-btn" (click)="addExecutor()" mat-raised-button color="primary">
        {{ "Add" | translate }}
    </button>
</mat-dialog-actions>