import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { MatTable } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { ReplaySubject, forkJoin, fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, last, map, switchMap, tap } from 'rxjs/operators';
import { DeleteAlertComponent } from 'src/app/shared/global_components/delete-alert/delete-alert.component';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { QuestsService } from 'src/app/shared/services/rest/quests.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { environment } from 'src/environments/environment';
import { AddFieldComponent } from '../a-Dialog/add-field/add-field.component';
import { AddQuestToEmplComponent } from '../a-Dialog/add-quest-to-empl/add-quest-to-empl.component';
import { ChangeStatusComponent } from '../a-Dialog/change-status/change-status.component';
import { EditFieldComponent } from '../a-Dialog/edit-field/edit-field.component';
import { AddQuestComponent } from '../add-quest/add-quest.component';
import { EditQuestComponent } from '../edit-quest/edit-quest.component';
import * as moment from 'moment';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MY_NEW_FORMATS } from 'src/app/components/workspace-pages/cases/dialogs/open-task/dialogs/add-task-profile/add-task-profile.component';
@Component({
  selector: 'app-queasts-list',
  templateUrl: './queasts-list.component.html',
  styleUrls: ['./queasts-list.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    {provide: MAT_DATE_FORMATS, useValue: MY_NEW_FORMATS},
  ],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})


export class QueastsListComponent extends BaseClass implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild("tableContainer") tableContainer: ElementRef;
  @ViewChild("tableContainer") emplTableContainer: ElementRef;
  @ViewChild(MatTable) table: MatTable<any>;
  public selectedTabVal: number = 0;
  public page: number = 1;
  public emplPage: number = 1;
  public pagination: any;
  public emplPagination: any;
  public quests: any[] = [];
  public emplQuests: any[] = [];
  public company_id: number;
  public host: any = environment.host;
  public company: any;
  public employees: any;
  public employee: any;
  public groups: any;
  public activeLang: any;
  public operations: any;
  public displayedColumns: string[] = ["Date", "Name"];
  public expandedElement: Quest | null;
  public filter: FormGroup
  public id: any = '';

  public employeeMoreControl: FormControl = new FormControl();
  public employees$: ReplaySubject<any> = new ReplaySubject<any>(1);
  
  constructor(
    private taskService: TaskService,
    private fb: FormBuilder,
    private router: Router,
    public sm: StorageManagerService,
    private languageService: LanguageService,
    private activatedRoute: ActivatedRoute,
    private _adapter: DateAdapter<any>,
    private dialog: MatDialog,
    private companyService: CompanyService,
    private questsService: QuestsService,
    private bottomSheet: MatBottomSheet
  ) { super() }

  ngOnInit(): void {
    this.company_id = +this.activatedRoute.snapshot.queryParamMap.get('company_id');
    this.id = this.activatedRoute.snapshot.queryParamMap.get('id') ? +this.activatedRoute.snapshot.queryParamMap.get('id') : '';
    this.filter = this.fb.group({
      employee_id: ''
    })

    this.getCompany();
    this.getLangData();

    this.getQuests(this.page);

    // this.getQuestEmpl(this.emplPage);

    this.getEmployees();

    this.attachSubscriptions(
      this.employeeMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchEmoloyees(resp))
    )

    this.filter.get('employee_id').valueChanges.subscribe(resp => {
      this.employee = this.employees.find(x => x.id == resp);
      this.router.navigate(['/settings'], { queryParams: {company_id: this.company_id, page: 'questionnaires', id: resp}});
      this.filterEmplQuests(resp)
    })
  }

  onSearchEmoloyees(resp) {
    if (!this.employees) {
      return;
    }

    if (!resp) {
      this.employees$.next(this.employees.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.employees$.next(
      this.employees.filter(z => z.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  deleteForm() {
    
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        target: marker("employee application form")
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        this.attachSubscriptions(
          forkJoin(this.emplQuests.map(val => this.deleteFunc(val))).subscribe(resp => {
            this.emplQuests = []
          })
        )
      }
    });
  }

  deleteFunc(field) {
    return this.questsService.deleteDataField(field.id)
  }

  getStatusName(val) {
    switch (val) {
      case 1:
        return "Waiting"
        break;
    
      case 2:
        return "To correct"
        break;
    
      case 3:
        return "To approve"
        break;
    
      case 4:
        return "Approved"
        break;
      default:
        return marker("Set status");
        break;
    }
  }

  filterEmplQuests(empl_id) {
    this.emplPage = 1;
    this.emplPagination = undefined;

    this.emplQuests = [];

    this.getQuestEmpl(this.emplPage, {employee_id: empl_id})
  }

  deleteQuest(quest) {
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: quest,
        target: marker("application form")
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        this.attachSubscriptions(
          this.questsService.deleteQuest(quest.id).subscribe(resp => {
            this.quests.splice(this.quests.indexOf(quest), 1);
            this.table.renderRows();
          })
        )
      }
    });
  }
  
  getEmployeeById(id) {
    return this.employees.find(x => x.id == id)
  }

  getEmployees() {
    this.attachSubscriptions(
      this.taskService.getEmployeesDyn('1', this.company_id, null, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }
  
          return forkJoin(arr.map(x => this.taskService.getEmployeesDyn(x, this.company_id, null, '200').pipe(map(u => u.body)))).pipe(
            last(),
            tap(values => {
              let conValues = [].concat(...values)
              this.employees = conValues;
              this.employees$.next(this.employees.slice())
              if (!!this.id) {
                console.log("@@@", this.id)
                this.selectedTabVal = 1;
                this.filter.patchValue({
                  employee_id: this.id
                })
              } else {
                console.log("###", this.id)
                this.filter.patchValue({
                  employee_id: this.employees[0].id
                })
              }
            }),
          )
        }),
      ).subscribe(resp => {
        console.log("getEmployees resp", resp);
        console.log("getEmployees", this.employees);
      })
    )
  }

  // getEmployees() {
  //   this.attachSubscriptions(
  //     this.taskService.getEmployees(this.company_id).subscribe(resp => {
  //       this.employees = resp;
  //       if (this.employees.length ) {
  //         if (!!this.id) {
  //           console.log("@@@", this.id)
  //           this.selectedTabVal = 1;
  //           this.filter.patchValue({
  //             employee_id: this.id
  //           })
  //         } else {
  //           console.log("###", this.id)
  //           this.filter.patchValue({
  //             employee_id: this.employees[0].id
  //           })
  //         }
  //       }
  //     })
  //   )
  // }

  deleteField(quest, field) {
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: field,
        target: marker("field")
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        this.attachSubscriptions(
          this.questsService.deleteField(field.id).subscribe(resp => {
            quest.form.splice(quest.form.indexOf(field), 1);
            // this.table.renderRows();
          })
        )
      }
    });

  }

  deleteDataField(field, index) {
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: field,
        target: marker("employee field")
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        this.attachSubscriptions(
          this.questsService.deleteDataField(field.id).subscribe(resp => {
            this.emplQuests.splice(index, 1);
            // this.table.renderRows();
          })
        )
      }
    });

  }

  getQuests(page) {
    this.attachSubscriptions(
      this.questsService.getQuests(this.company_id, page).pipe(
        tap(el => {
          this.pagination = {
            'pageCount': el.headers.get('x-pagination-page-count'),
            'perPage': el.headers.get('x-pagination-per-page'),
            'totalCount': el.headers.get('x-pagination-total-count'),
            'currentPage': el.headers.get('x-pagination-current-page'),
          }
        }),
        map(el => el.body)
      ).subscribe(resp => {
        console.log('getQuests', resp)
        if (page == 1) {
          this.quests = resp
        } else {
          this.quests.push(...resp);
        }
        this.page = this.page + 1;
        this.table.renderRows();
        // this.table['_results'][0].renderRows();
      })
    )
  }

  getCompany() {
    this.attachSubscriptions(
      this.companyService.getCompany(this.company_id).subscribe(resp => {
        console.log("getCompany", resp);
        this.companyService.company$.next(resp[0]);
        this.company = resp[0];
        if (this.company.permissions.includes('owner') || this.company.permissions.includes('admin') || this.company.permissions.includes('hr')) {
          this.displayedColumns.push("Activity")
        }
      })
    )
  }

  changeTab(val) {
    this.selectedTabVal = val;
  }

  getQuestEmpl(page, filterData = {}) {
    this.attachSubscriptions(
      this.questsService.getQuestEmpl(this.company_id, filterData, page).pipe(
        tap(el => {
          this.emplPagination = {
            'pageCount': el.headers.get('x-pagination-page-count'),
            'perPage': el.headers.get('x-pagination-per-page'),
            'totalCount': el.headers.get('x-pagination-total-count'),
            'currentPage': el.headers.get('x-pagination-current-page'),
          }
        }),
        map(el => el.body),
        tap(el => {

          console.log("@@@@@@",el)
          el.forEach(element => {
            if (element.type == 'file' && !!element?.text) {
              element.value = this.dataURLtoFile(element.text, element.name);
            }
            if (element.type == 'date' && !!element?.text) {
              element.text = moment(element.text*1000).utc()
            }
          });
          // return el
        })
      ).subscribe(resp => {
        console.log("getQuestEmpl", resp)
        this.emplPage = this.emplPage + 1;
        this.emplQuests.push(...resp);
        // this.table['_results'][1].renderRows();
      })
    )
  }

  dataURLtoFile(dataurl, filename) {
 
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
        
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    
    return new File([u8arr], filename, {type:mime});
}


  goBack(e) {
    e.preventDefault();
    history.back();
  }

  ngAfterViewInit() {
    this.tableContainer.nativeElement.scrollTop = 0;
    this.onScroll();
    this.onScrollEmpl();
  }

  onScroll() {
    this.attachSubscriptions(
      fromEvent(this.tableContainer.nativeElement, "scroll").pipe(
        filter((e:any) => e.target.scrollTop >=  e.target.scrollHeight - e.target.offsetHeight - 400),
        map(() => this.tableContainer.nativeElement.scrollTop),
        debounceTime(300),
        map(y => Math.ceil((y + this.tableContainer.nativeElement.innerHeight)/ (400))),
        distinctUntilChanged()
      ).subscribe(() => {
          if (this.page <= this.pagination['pageCount']) {
            this.getQuests(this.page);
          }
        }
      )
    )
  }

  onScrollEmpl() {
    this.attachSubscriptions(
      fromEvent(this.emplTableContainer.nativeElement, "scroll").pipe(
        filter((e:any) => e.target.scrollTop >=  e.target.scrollHeight - e.target.offsetHeight - 400),
        map(() => this.emplTableContainer.nativeElement.scrollTop),
        debounceTime(300),
        map(y => Math.ceil((y + this.emplTableContainer.nativeElement.innerHeight)/ (400))),
        distinctUntilChanged()
      ).subscribe(() => {
          if (this.emplPage <= this.emplPagination['pageCount']) {
            this.getQuestEmpl(this.emplPage);
          }
        }
      )
    )
  }

  addQuest(e) {
    e.preventDefault();
    const dialogRef = this.dialog.open(AddQuestComponent, {
      disableClose: true,
      data: {
        company: this.company,
        company_id: this.company_id
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result && result.event == "update") {
          this.page = 1;
          this.getQuests(this.page);
        }
      })
    )
  }
  
  editQuest(element) {
    const dialogRef = this.dialog.open(EditQuestComponent, {
      disableClose: true,
      data: {
        company: this.company,
        company_id: this.company_id,
        quest: element
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result && result.event == "update") {
          this.page = 1;
          this.getQuests(this.page);
        }
      })
    )
  }

  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData().subscribe(resp => {
        this.activeLang = resp.active;
        this._adapter.setLocale(resp.active);
      })
    )
  }


  rowClick(element) {
    console.log(element);
    if (!element.form) {
      element.form = [];
      this.questsService.getQuestForm(this.company_id, element.id).subscribe(resp => {
        console.log("getQuestForm",resp)
        element.form = resp;
        element.isExpanded = !element.isExpanded
        console.log('getOperationReminder', element)
      })
    } else {
      element.isExpanded = !element.isExpanded
    }
  }

  addFieldToEmpl() {
    const dialogRef = this.dialog.open(AddFieldComponent, {
      data: {
        company_id: this.company_id,
        employee_id: this.filter.value.employee_id
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        
        if (!!result && result.event == 'add') {
          // this.table.renderRows();
          this.filterEmplQuests(this.filter.value.employee_id);
        }
      })
    )
  }

  changeQuestStatus() {
    console.log("this.employees", this.employees);
    const dialogRef = this.dialog.open(ChangeStatusComponent, {
      data: {
        company: this.company,
        employee: this.employee
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        
        if (!!result && result.event == 'edit') {
          // this.table.renderRows();
          // this.filterEmplQuests(this.filter.value.employee_id);
        }
      })
    )
  }

  addField(quest) {
    const dialogRef = this.dialog.open(AddFieldComponent, {
      data: {
        company_id: this.company_id,
        quest: quest
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        
        if (!!result && result.event == 'Add') {
          // this.table.renderRows();
        }
      })
    )
  }

  editField(quest, field) {
    const dialogRef = this.dialog.open(EditFieldComponent, {
      data: {
        company_id: this.company_id,
        quest: quest,
        field: field,
        is_data: false,
        employee: false
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        
        if (!!result && result.event == 'edit') {
          // this.table.renderRows();
        }
      })
    )
  }

  editDataField(field) {
    const dialogRef = this.dialog.open(EditFieldComponent, {
      data: {
        company_id: this.company_id,
        is_data: true,
        field: field,
        quest: false,
        employee: this.employee
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        
        if (!!result && result.event == 'edit') {
          // this.table.renderRows();
        }
      })
    )
  }

  addQuestToEmpl() {
    const dialogRef = this.dialog.open(AddQuestToEmplComponent, {
      data: {
        company: this.company,
        employees: this.employees,
        quests: this.quests,
        employee_id: this.filter.get('employee_id').value
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        
        if (!!result && result.event == 'add') {
          // this.table.renderRows();
          this.filterEmplQuests(this.filter.value.employee_id);
          // this.table['_results'][1].renderRows();
        }
      })
    )
  }
  
  ngOnDestroy() {
    this.clearSubscriptions()
  }
}


export interface Quest {
  id: number,
  company_id: number,
  name: string
  created_at: number,
  updated_at: number
}
