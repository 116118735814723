import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { debounceTime, map, tap } from 'rxjs/operators';
import { BaseClass } from 'src/app/shared/models/base-class';
import { CompanyService } from 'src/app/shared/services/rest/company.service';

@Component({
  selector: 'app-mob-channels-filter',
  templateUrl: './mob-channels-filter.component.html',
  styleUrls: ['./mob-channels-filter.component.scss']
})

export class MobChannelsFilterComponent extends BaseClass implements OnInit, OnDestroy {
  throttle = 300;
  scrollUpDistance = 3.5;
  public switched: boolean = false;
  public outletKey: string = 'channel_id';

  public searchControl: FormControl = new FormControl('');
  public isLoad: boolean = false;
  public outletsSubsControl: FormControl = new FormControl('')
  public outletsPage: number = 1;
  public outletsPagination: any;
  public outlets: any;

  public savedForm: any;
  public noSavedForm: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<MobChannelsFilterComponent>,
    private companyService: CompanyService,
  ) { super() }

  ngOnInit(): void {
    console.log("MobChannelsFilterComponent", this.data)

    this.getProfiles(this.outletsPage)
    this.savedForm = JSON.parse(JSON.stringify(this.data.filter.get('channel_id').value));
    this.noSavedForm = JSON.parse(JSON.stringify(this.data.filter.get('no_channel_id').value));
    this.attachSubscriptions(
      this.outletsSubsControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onFilterByPlatforms(resp))
    )

    this.attachSubscriptions(
      this.searchControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onFilterBySearch(resp))
    )
    
    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        this.close();
      })
    )
  }

  onFilterBySearch(resp) {
    console.log("searchProfiles", resp);
    this.outletsPage = 1;
    this.getProfiles(this.outletsPage, resp, this.outletsSubsControl.value)
  }

  onFilterByPlatforms(resp) {
    console.log("searchProfiles", resp);
    this.outletsPage = 1;
    this.getProfiles(this.outletsPage, this.searchControl.value, resp)
  }

  setOutletKey(val) {
    this.outletKey = val
    this.switched = true;
    setTimeout(() => {
      this.switched = false;
    }, 0)
  }
  
  getPlatformById(id) {
    if (!this.data.platforms || this.data.platforms.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.data.platforms.find(el => el.id == id)
  }

  noChannelMulty(e, id) {
    e.preventDefault();
    e.stopPropagation();
    let arr = this.data.filter.get('no_channel_id').value.slice() || [];

    if (arr.includes(id)) {
      arr.splice(arr.indexOf(id), 1)
    } else {
      arr.push(id)
    }

    this.data.filter.patchValue({
      no_channel_id: arr,
    })
    this.noSavedForm = JSON.parse(JSON.stringify(this.data.filter.value.no_channel_id));
  }

  getProfiles(page, q: string = '', platform_id:any = '') {
    this.isLoad = true;
    this.attachSubscriptions(
      this.companyService.getFilteredProfilesDyn(this.data.company.id, page, {q, platform_id}).pipe(
        tap(el => {
          if (page == 1) {
            this.outletsPagination = {
              'pageCount': el.headers.get('x-pagination-page-count'),
              'perPage': el.headers.get('x-pagination-per-page'),
              'totalCount': el.headers.get('x-pagination-total-count'),
              'currentPage': el.headers.get('x-pagination-current-page'),
            }
          }
        }),
        map(el => el.body)
      ).subscribe(resp => {
        console.log("getProfiles", resp)

        if (page == 1) {
          this.outlets = resp
        } else {
          resp.forEach(profile => {
            if (this.outlets.filter(x => x.id == profile.id).length == 0) {
              this.outlets.push(...resp);
            }
          })
        }

        this.isLoad = false;
          setTimeout(() => {
            this.data.filter.patchValue({
              [this.outletKey]: this.savedForm,
              no_channel_id: this.noSavedForm
            })
          },0)
        this.outletsPage = this.outletsPage + 1;
      })
    )
  }

  onDown(e, control) {
    console.log("onDown", e?.target?.scrollTop)
    if (this.outletsPagination && this.outletsPage <= this.outletsPagination['pageCount']) {
      console.log("#########onDown")
      this.getProfiles(this.outletsPage);
    }
  }

  setChannelMulty(e, id, key) {
    e.preventDefault();
    e.stopPropagation();
    let arr = this.data.filter.get(key).value.slice() || [];

    if (arr.includes(id)) {
      arr.splice(arr.indexOf(id), 1)
    } else {
      arr.push(id)
    }

    this.data.filter.patchValue({
      [key]: arr,
    })
    this.savedForm = JSON.parse(JSON.stringify(this.data.filter.value[key]));
  }

  noChannel(e, id) {
    e.preventDefault();
    e.stopPropagation();
    this.data.filter.patchValue({
      no_channel_id: id,
    })
  }

  patchControl(control:FormControl, val) {
    control.patchValue(val)
  }

  patchTab(ctrl, val) {
    console.log(ctrl, val);
    this.switched = true;
    ctrl = val
    setTimeout(() => {
      this.switched = false
    },0)
  }

  close() {
    this.dialogRef.removePanelClass('animate__slideInUp');
    this.dialogRef.addPanelClass('animate__slideOutDown');
    setTimeout(()=>{this.dialogRef.close({event: 'close'})}, 300);
  }

  onSubmit() {
    this.dialogRef.removePanelClass('animate__slideInUp');
    this.dialogRef.addPanelClass('animate__slideOutDown');
    setTimeout(()=>{this.dialogRef.close({event: 'apply'})}, 300);
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

}
