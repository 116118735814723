import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { BaseClass } from 'src/app/shared/models/base-class';
import { StatementService } from 'src/app/shared/services/rest/statement.service';
import { AddEditStatementDocumentsComponent } from '../add-edit-statement-documents/add-edit-statement-documents.component';
import { environment } from 'src/environments/environment';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { ChatService } from 'src/app/shared/services/rest/chat.service';
import { forkJoin } from 'rxjs';
import { VideoViewerComponent } from 'src/app/shared/global_components/file-manager/dialog/video-viewer/video-viewer.component';
import { casesModel } from 'src/app/shared/functions/casesModel';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { AuthService } from 'src/app/shared/services/rest/auth.service';

@Component({
  selector: 'app-statement-documents',
  templateUrl: './statement-documents.component.html',
  styleUrls: ['./statement-documents.component.scss']
})
export class StatementDocumentsComponent extends BaseClass implements OnInit, OnDestroy  {
  public page: number = 1;
  public pagination: any;
  public user: any;
  public activeLang: any;
  public operationsValues: any;
  public statementDocs: any;
  public isLoadStatementDocs: boolean = false;
  public host: any = environment.host;
  throttle = 100;
  scrollUpDistance = 3.5;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<StatementDocumentsComponent>,
    private auth: AuthService,
    private taskService: TaskService,
    private languageService: LanguageService,
    private chatService: ChatService,
    private statementService: StatementService,
    private dialog: MatDialog,
  ) { super() }

  ngOnInit(): void {
    console.log("StatementDocumentsComponent", this.data)
    this.getStatementDocuments(this.page);
    this.getUser();
    this.getLangData();
  }

  getUser() {
    this.attachSubscriptions(
      this.auth.$user.subscribe(resp => {
        this.user = resp;
      })
    )
  }

  addDoc() {
    const dialogRef = this.dialog.open(AddEditStatementDocumentsComponent, {
      disableClose: true,
      data: {
        company: this.data.company,
        statement: this.data.statement
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result && result.event == "update") {
          this.page = 1;
          this.getStatementDocuments(this.page);
        }
      })
    )
  }

  download(file) {
    window.open(this.host + file.original + '?company_id=' + this.data.company.id + `&filename=${file.filename}`, '_blank');
  }

  getStatementDocuments(page) {
    this.isLoadStatementDocs = true;
    this.attachSubscriptions(
      this.statementService.getStatementDocuments(page, this.data.company.id, {statement_id: this.data.statement.id}, '200').pipe(
        tap(el => {
          this.pagination = {
            'pageCount': el.headers.get('x-pagination-page-count'),
            'perPage': el.headers.get('x-pagination-per-page'),
            'totalCount': el.headers.get('x-pagination-total-count'),
            'currentPage': el.headers.get('x-pagination-current-page'),
          }
        }),
        map(el => el.body),
      ).subscribe(resp => {
        if (page == 1) {
          this.statementDocs = resp
        } else {
          this.statementDocs.push(...resp)
        }
        this.page++;
        this.isLoadStatementDocs = false
        console.log("statementDocs", this.statementDocs)
      })
    )
  }

  // neededData(task, company) {
  //   let arr = [
  //     this.chatService.getTasksChats(company.id, [task.id]).pipe(
  //       tap(res => {
  //         task['operations'].map(o_el => {
  //           o_el['chatsInfo'] = [];
  //           o_el['chatsInfo'].push(...res.filter(z => z.task_operation_id == o_el.id));
  //           o_el['unReadChats'] = res.filter(z => z.task_operation_id == o_el.id && z.is_read != '1').length;
  //           o_el['unClosedChats'] = res.filter(z => z.task_operation_id == o_el.id && z.status_id != '4').length;
  //         });
  //         task['chatsInfo'] = [];
  //         task['chatsInfo'].push(...res.filter(z => z.task_id == task.id));
  //         task['unReadChats'] = res.filter(z => z.is_read != '1').length;
  //         task['unClosedChats'] = res.filter(z => z.status_id != '4').length;
  //       })
  //     )
  //   ]
  //   if (task.company_id == company.id) {
  //     arr.push(
  //       this.taskService.getTaskClients(task.id, company.id).pipe(
  //         tap(res => {
  //           task.clients = res
  //         }),
  //         catchError(err => {
  //           return err
  //         })
  //       )
  //     )
  //   }
  //   return forkJoin(arr)
  // }

  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData().pipe(
        tap(res => {
          this.activeLang = res.active;
        }),
        switchMap(x => {
          return this.taskService.getOperations(this.data.company.id, this.activeLang).pipe(tap(ops => {
            this.operationsValues = ops;
          }))
        })
      ).subscribe(resp => {
        console.log('activeLang', this.activeLang)
        console.log('operationsValues', this.operationsValues)
      })
    )
  }

  openPdf(file) {
    const dialogRef = this.dialog.open(VideoViewerComponent, {
      panelClass: 'video_viewer',
      autoFocus: false,
      data: {
        file: file,
        files: this.statementDocs.map(x => x.file).filter(u => !!u),
        playlist: this.statementDocs.map(x => x.file).filter(u => !!u),
        // task: resp,
        // work: !!file.task_operation_id ? resp.operations.find(x => x.id == file.task_operation_id) : undefined,
        operationsValues: this.operationsValues,
        company: this.data.company,
        activeLang: this.activeLang,
        user: this.user,
        initCompanyId: this.data.company.id
      }
    });
  }

  onDownStatementDocuments(e) {
    console.log("SCROLL DOWN", e);
    if (this.pagination['pageCount'] >= this.page) {
      console.log("onDownStatementDocuments")
      this.getStatementDocuments(this.page);
    }
  }


  find() {
    this.dialogRef.close({event: "find"});
  }
  findAlt() {
    this.dialogRef.close({event: "alt_find"});
  }

  close() {
    this.dialogRef.close({event: "close", data: false});
  }

  ngOnDestroy(): void {
    this.clearSubscriptions();
  }
}
