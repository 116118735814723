import { AfterViewInit, Component, ElementRef, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { FileService } from 'src/app/shared/services/rest/file.service';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { StatementService } from 'src/app/shared/services/rest/statement.service';
import { AddVariableComponent } from '../add-variable/add-variable.component';
import { AddTableComponent } from '../add-table/add-table.component';
import { fromEvent } from 'rxjs';
import { AddDateVariableComponent } from '../add-date-variable/add-date-variable.component';

@Component({
  selector: 'app-add-edit-st-template',
  templateUrl: './add-edit-st-template.component.html',
  styleUrls: ['./add-edit-st-template.component.scss']
})
export class AddEditStTemplateComponent extends BaseClass implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('editableDiv') editableDiv: ElementRef;
  public form: FormGroup;
  public isFormChange: boolean = false;
  public isSubmit: boolean = false;
  public no_acssess: boolean = true;
  public selectedText: any;
  public selectedBlock: any;
  public company: any;
  public company_id: any;
  public target_company_id: any = this.data.target_company_id || (this.data.item && this.data.item.company_id ? this.data.item.company_id : this.data.company_id);
  @Input() initData: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private companyService: CompanyService,
    private fileService: FileService,
    private statementService: StatementService,
    private taskService: TaskService,
    public dialogRef: MatDialogRef<AddEditStTemplateComponent>,
    public layoutService: LayoutService
  ) { super() }

  ngOnInit(): void {
    this.dialogRef.addPanelClass("full_width_dialog")
    console.log("AddEditStTemplateComponent", this.data);
    this.form = this.fb.group({
      company_id: this.target_company_id,
      name: ['', Validators.required],
      template: ['', Validators.required],
      statement_record_type: 'earnings',
      group_by: 'task_operation'
    })

    if (this.data.tmpl) {
      this.form.patchValue({
        name: this.data.tmpl.name,
        template: this.data.tmpl.data || this.data.tmpl.template,
        statement_record_type: this.data.tmpl.statement_record_type,
        group_by: this.data.tmpl.group_by
      })
    } else {
      this.form.patchValue({
        template: `Statement #<span class="st_tmpl_block variable" contenteditable="false" style="display: inline-block; font-size: 16px; line-height: 26px;" data-type="Statement Variable" data-name="ID" data-source="$statement" data-field="id">{$statement.id}</span><br>
        <br>
        `
      })

      // <div>Paid: {foreach $statement.summaries as $summary}{if $summary.paid}{$currencies[$summary.currency_id].short_name|escape} {$summary.paid},{/if}{/foreach}</div><br>
      // <div>Balance: {foreach $statement.summaries as $summary}{if $summary.balance}{$currencies[$summary.currency_id].short_name|escape} {$summary.balance},{/if}{/foreach}</div><br>
      // <div>balance_pending: {foreach $statement.summaries as $summary}{if $summary.balance_pending}{$currencies[$summary.currency_id].short_name|escape} {$summary.balance_pending},{/if}{/foreach}</div><br>
      // <div>pending: {foreach $statement.summaries as $summary}{if $summary.pending}{$currencies[$summary.currency_id].short_name|escape} {$summary.pending},{/if}{/foreach}</div><br>
      // <div>Previous Balance: {foreach $statement.summaries as $summary}{if $summary.start_balance}{$currencies[$summary.currency_id].short_name|escape} {$summary.start_balance},{/if}{/foreach}</div><br>
      // <div>earned: {foreach $statement.summaries as $summary}{if $summary.earned}{$currencies[$summary.currency_id].short_name|escape} {$summary.earned},{/if}{/foreach}</div><br>
      // <div>returned: {foreach $statement.summaries as $summary}{if $summary.returned}{$currencies[$summary.currency_id].short_name|escape} {$summary.returned},{/if}{/foreach}</div><br>
      // {if $contact.messengers}
      // {/if}

      // <br>
      // {foreach $statement_records as $statement_record}
      // <br>
      // <br>
      // {$statement_record.task_group_name|escape}
      // -
      // {$statement_record.task_name|escape}({$statement_record.task_status_name|escape})
      // -
      // {$statement_record.task_operation_name|escape}({$statement_record.task_operation_operation_name|escape})
      // -
      // {$statement_record.discussion_name|escape}
      // <br>
      // <br>
      // {/foreach}<br>
    }

    this.attachSubscriptions(
      this.form.valueChanges.subscribe(() => this.isFormChange = true)
    )

    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        if (this.isFormChange) {
          this.layoutService.openBottomSheet(this.dialogRef);
        } else {
          this.close();
        }
      })
    )
  }

  formatText(key) {
    if (key == 'createLink') {

      const from = this.selectedText.state.linkNode;
      console.log(this.selectedText);
      console.log("!!from", from);
      if (!!from) {
        let sel = window.getSelection();
        const range = document.createRange();
        var linkURL = prompt('Enter a URL:', this.selectedText.state.link ? this.selectedText.state.link : '');
        // document.getSelection().removeAllRanges();
        from.childNodes.forEach(element => {
          console.log("childNodes", element)
          // range.selectNode(element);
        });
        sel.removeAllRanges();
        range.selectNodeContents(from)
        
        sel.addRange(range);
        var sText = sel.toString();
        
        console.log('sText', sText);
        console.log('linkURL', linkURL);
        if (linkURL !== null && linkURL != '') {
          document.execCommand('insertHTML', false, '<a href="' + linkURL + '" target="_blank">' + sText + '</a>');
        } else {
          // let sel2 = window.getSelection();
          // let range2 = document.createRange();
          // sel2.removeAllRanges();
          // range2.selectNodeContents(from)
          // sel2.addRange(range2);
          // // sel2.removeAllRanges();
          document.execCommand('unlink', false, null);
          sel.removeAllRanges();
        }
        console.log("!!from", from);
      } else {
        var linkURL = prompt('Enter a URL:', '');
        var sText = document.getSelection().toString();
        if (linkURL !== null && linkURL != '') {
          document.execCommand('insertHTML', false, '<a href="' + linkURL + '" target="_blank">' + sText + '</a>');
        }
      }
      this.mouseUp()
    } else {
      document.execCommand(key, false, null);
      this.mouseUp()
    }
  }

  onPaste(e) {
    if (e && e.clipboardData && ((!!e.clipboardData.getData('text/html') && e.clipboardData.getData('text/html').indexOf('on_reports_copy') == -1) || e.clipboardData.getData('text/html') == '')) {
      e.preventDefault();
      const text = e.clipboardData.getData('text/plain').replaceAll("\n","<br>");
      document.execCommand('insertHTML', false, text);
    }
    this.mouseUp()
  }

  onCopy(event) {
    const selection = document.getSelection();
    if (selection) {
      const range = selection.getRangeAt(0);
      const clonedContent = range.cloneContents();
  
      const wrapperDiv = document.createElement('div');
      const span = document.createElement('span');
      span.classList.add('on_reports_copy')
      wrapperDiv.appendChild(clonedContent);
      wrapperDiv.appendChild(span);
  
      event.clipboardData?.setData('text/html', wrapperDiv.innerHTML);
      event.clipboardData?.setData('text/plain', selection?.toString().replace(/<br>|<BR>|<\/br>|<\/BR>/gi, '\n'));
      
      event.preventDefault();
    }
  }

  mouseUp(e?) {
    let selection = document.getSelection();
    let range = selection.getRangeAt(0);
    
    let clientRects = range.getClientRects();

    let posArr = [];
    Object.keys(clientRects).forEach(key => {
      posArr.push({x: clientRects[key].x + clientRects[key].width/2 , y: clientRects[key].y})
    });

    let pos = {
      x: Math.round(posArr.map(n => n.x).reduce((a, b) => +a + +b, 0) / posArr.length),
      y: Math.round(posArr.map(n => n.y).reduce((a, b) => +a + +b, 0) / posArr.length),
    }


    this.selectedText = {
      is_selected: selection.toString().trim().length > 0,
      position: pos,
      state: {
        bold: document.queryCommandState('bold'),
        italic: document.queryCommandState('italic'),
        underline: document.queryCommandState('underline'),
        strikeThrough: document.queryCommandState('strikeThrough'),
        insertOrderedList: document.queryCommandState('insertOrderedList'),
        insertUnorderedList: document.queryCommandState('insertUnorderedList'),
        createLink: this.isLink(),
        link: this.isLink() ? this.getLink() : "",
        linkNode: this.isLink() ? this.getLinkNode() : null
      }
    }
  }

  getLink() {
    const selection = document.getSelection()
    const startA = selection.anchorNode.parentElement.getAttribute('href')
    const endA = selection.focusNode.parentElement.getAttribute('href')
    
    const altStartA = selection.anchorNode
    const altEndA = selection.anchorNode

    if (altStartA.nodeName === 'A' || altEndA.nodeName === 'A') {
      return altStartA['href'] || altEndA['href']
    }
    return startA || endA
  }

  getLinkNode() {
    const selection = document.getSelection()
    const startA = selection.anchorNode.parentElement
    const endA = selection.focusNode.parentElement
    const altStartA = selection.anchorNode
    const altEndA = selection.anchorNode

    if (altStartA.nodeName === 'A' || altEndA.nodeName === 'A') {
      return altStartA || altEndA
    }
    return startA || endA
  }

  fixHTML(html: string): string {
    const stack = []; // Стек для хранения открытых тегов
    const regex = /<(\/?[\w\d-]+)[^>]*>/g; // Регулярное выражение для поиска тегов
  
    // Проходим по всем тегам в HTML
    html = html.replace(regex, (match, tag) => {
      // Если тег является закрывающим
      if (tag.startsWith('/')) {
        // Если стек не пустой и верхний элемент стека совпадает с закрывающим тегом, закрываем его
        if (stack.length && stack[stack.length - 1] === tag.substring(1)) {
          stack.pop();
          return match; // Возвращаем сам закрывающий тег
        } else {
          // Если нет соответствующего открывающего тега, возвращаем закрывающий тег без изменений
          return match;
        }
      } else {
        // Если тег является открывающим, добавляем его в стек
        stack.push(tag);
        return match;
      }
    });
  
    // Добавляем отсутствующие открывающие теги перед закрывающими тегами
    stack.forEach(tag => {
      html = `<${tag}>${html}`;
    });
  
    return html;
  }
  

  copySelected() {
    let selection = window.getSelection();
    let range = selection.getRangeAt(0);
    let clonedSelection = range.cloneContents();
    let tempDiv = document.createElement('div');
    tempDiv.appendChild(clonedSelection);
  
    let htmlToCopy = tempDiv.innerHTML;
  
    // Проверяем, является ли HTML валидным
    if (this.isValidHTML(htmlToCopy)) {
      // Копируем HTML в буфер обмена
      navigator.clipboard.writeText(htmlToCopy).then(() => {
        
        this.layoutService.showSnackBar({name: ''}, marker("HTML скопирован в буфер обмена"), SnackBarItem)
        console.log('HTML скопирован в буфер обмена');
      }).catch(err => {
        
      this.layoutService.showSnackBar({name: 'Ошибка при копировании HTML в буфер обмена:'}, err, SnackBarItem)
        console.error('Ошибка при копировании HTML в буфер обмена: ', err);
      });
    } else {
      console.warn('Невалидный HTML, производим попытку исправления...');
      this.layoutService.showSnackBar({name: ''}, marker("Невалидный HTML, производим попытку исправления..."), SnackBarItem)
  
      // Пытаемся исправить HTML
      let fixedHTML = this.fixHTML(htmlToCopy);
  
      // Проверяем, является ли исправленный HTML валидным
      if (this.isValidHTML(fixedHTML)) {
        console.log('HTML успешно исправлен и скопирован в буфер обмена');
        this.layoutService.showSnackBar({name: ''}, marker("HTML успешно исправлен"), SnackBarItem)
        // Копируем исправленный HTML в буфер обмена
        navigator.clipboard.writeText(fixedHTML).then(() => {
          this.layoutService.showSnackBar({name: ''}, marker("HTML скопирован в буфер обмена"), SnackBarItem)
          console.log('HTML скопирован в буфер обмена');
        }).catch(err => {
          this.layoutService.showSnackBar({name: 'Ошибка при копировании HTML в буфер обмена:'}, err, SnackBarItem)
          console.error('Ошибка при копировании HTML в буфер обмена: ', err);
        });
      } else {
        console.error('Невозможно исправить HTML, копирование отменено');
      }
    }
  }

  isValidHTML(html: string): boolean {
    let tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;
    // Проверяем, соответствует ли внутренний HTML структуре
    return tempDiv.innerHTML === html;
  }

  removeActiveClassFromBlocks() {
    const blocks = this.editableDiv.nativeElement.querySelectorAll('.st_tmpl_block');
    blocks.forEach((block: HTMLElement) => {
      block.classList.remove('active');
    });
  }

  log() {
    console.log("form", this.form.value)
    console.log("editableDiv html", this.editableDiv.nativeElement.innerHTML)
  }

  isLink () {
    const selection = document.getSelection()
    const parentStartA = selection.anchorNode.parentElement.parentElement.tagName === 'A'
    const parentEndA = selection.focusNode.parentElement.parentElement.tagName === 'A'
    const startA = selection.anchorNode.parentElement.tagName === 'A'
    const endA = selection.focusNode.parentElement.tagName === 'A'
    const altStartA = selection.anchorNode.nodeName === 'A'
    const altEndA = selection.anchorNode.nodeName === 'A'
    

    console.log("isLink selection", selection);
    console.log("isLink test", parentStartA || parentEndA || startA || endA || altStartA || altEndA);
    // console.log("isLink startA", selection.anchorNode.parentElement);
    // console.log("isLink endA", selection.focusNode.parentElement);
    return parentStartA || parentEndA || startA || endA || altStartA || altEndA
  }

  close() {
    this.dialogRef.close({event: "close", data: false});
  }

  addDateVariable() {
    const cursorPosition = window.getSelection().getRangeAt(0);
    console.log("addVariable cursorPosition", cursorPosition)
    const range = document.createRange();
    range.setStart(cursorPosition.startContainer, cursorPosition.startOffset);
    const dialogRef = this.dialog.open(AddDateVariableComponent, {
      disableClose: true,
      data: {
        company: this.data.company
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log("addVariable result", result)
        if (result.data) {
          if (result.data.source == "$statement") {
            // let textNode;
            // textNode = document.createTextNode(`{${result.data.source}.${result.data.var}|date_format:'${result.data.date_format}'}`);
            // range.insertNode(textNode);
            // const htmlNode = document.createRange().createContextualFragment(`<span class='st_tmpl_block variable' data-source='${result.data.source}' data-field='${result.data.field}'>${result.data.source}${result.data.field}.text|escape}</span>`);
            // range.insertNode(htmlNode);
            result.data.type = `Statement Date (${result.data.date_format.replaceAll("%", "")})`
            let htmlNode = document.createRange().createContextualFragment(`<span class='st_tmpl_block variable' contenteditable="false" style="display: inline-block; font-size: 16px; line-height: 26px;" data-type='${result.data.type}' data-name='${result.data.name}' data-source='${result.data.source}' data-var='${result.data.var}' data-date_format='${result.data.date_format}'>{${result.data.source}.${result.data.var}|date_format:'${result.data.date_format}'}</span>`);
            range.insertNode(htmlNode);
            this.insertEmpty(cursorPosition)
            this.clearSelection();
          }
        }
      })
    )
  }

  addVariable() {
    const cursorPosition = window.getSelection().getRangeAt(0);
    console.log("addVariable cursorPosition", cursorPosition)
    const range = document.createRange();
    range.setStart(cursorPosition.startContainer, cursorPosition.startOffset);
    const dialogRef = this.dialog.open(AddVariableComponent, {
      disableClose: true,
      data: {
        company: this.data.company
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log("addVariable result", result)
        if (result.data) {
          if (result.data.source == "$employee.application.") {
            // let textNode;
            // if (result.data.date_format) {
            //   textNode = document.createTextNode(`${result.data.source}${result.data.field}.text|date_format:'${result.data.date_format}'}`);
            // } else {
            //   textNode = document.createTextNode(`${result.data.source}${result.data.field}.text|escape}`);
            // }
            // range.insertNode(textNode);
            let htmlNode;
            if (result.data.date_format) {
              result.data.type = `Form Date (${result.data.date_format.replaceAll("%", "")})`
              htmlNode = document.createRange().createContextualFragment(`<span class='st_tmpl_block variable' contenteditable="false" style="display: inline-block; font-size: 16px; line-height: 26px;" data-type='${result.data.type}' data-name='${result.data.name}' data-source='${result.data.source}' data-field='${result.data.field}' data-date_format='${result.data.date_format}'>{${result.data.source}${result.data.field}.text|date_format:'${result.data.date_format}'}</span>`);
            } else {
              result.data.type = `Form Variable`
              htmlNode = document.createRange().createContextualFragment(`<span class='st_tmpl_block variable' contenteditable="false" style="display: inline-block; font-size: 16px; line-height: 26px;" data-type='${result.data.type}' data-name='${result.data.name}' data-source='${result.data.source}' data-field='${result.data.field}'>{${result.data.source}${result.data.field}.text|escape}</span>`);
            }
            range.insertNode(htmlNode);
            this.insertEmpty(cursorPosition)
            
            // <span class="st_tmpl_block variable" contenteditable="false" style="display: inline-block; font-size: 16px; line-height: 26px;">SPAN</span>
            // const htmlNode = document.createRange().createContextualFragment(`<span class='st_tmpl_block variable' data-source='${result.data.source}' data-field='${result.data.field}'>${result.data.source}${result.data.field}.text|escape}</span>`);
            // range.insertNode(htmlNode);
            this.clearSelection();
          }
          if (result.data.source == "$statement") {

            result.data.type = `Statement Variable`
            let htmlNode = document.createRange().createContextualFragment(`<span class='st_tmpl_block variable' contenteditable="false" style="display: inline-block; font-size: 16px; line-height: 26px;" data-type='${result.data.type}' data-name='${result.data.name}' data-source='${result.data.source}' data-field='${result.data.statement_field}'>{${result.data.source}.${result.data.statement_field}}</span>`);
            range.insertNode(htmlNode);
            this.insertEmpty(cursorPosition)
            
            // <span class="st_tmpl_block variable" contenteditable="false" style="display: inline-block; font-size: 16px; line-height: 26px;">SPAN</span>
            // const htmlNode = document.createRange().createContextualFragment(`<span class='st_tmpl_block variable' data-source='${result.data.source}' data-field='${result.data.field}'>${result.data.source}${result.data.field}.text|escape}</span>`);
            // range.insertNode(htmlNode);
            this.clearSelection();
          }
          if (result.data.source == "$statement.summaries") {
            result.data.type = `Statement Summary`
            let htmlNode = document.createRange().createContextualFragment(`<span class='st_tmpl_block variable' contenteditable="false" style="display: inline-block; font-size: 16px; line-height: 26px;" data-type='${result.data.type}' data-name='${result.data.name}' data-source='${result.data.source}' data-statement_field='${result.data.statement_field}'>{assign 'comma' 0}{foreach ${result.data.source} as $summ}{if $summ.${result.data.statement_field} != 0}{if $comma}, {/if}{$currencies[$summ.currency_id].short_name|escape}{$summ.${result.data.statement_field}|round:2}{assign 'comma' 1}{/if}{/foreach}</span>`);
            range.insertNode(htmlNode);
            this.insertEmpty(cursorPosition)
            // const htmlNode = document.createRange().createContextualFragment(`<span class='st_tmpl_block variable' data-source='${result.data.source}' data-statement_field='${result.data.statement_field}'>{foreach ${result.data.source} as $summ}{if $summ.${result.data.statement_field}}{$currencies[$summ.currency_id].short_name|escape} {$summ.${result.data.statement_field}},{/if}{/foreach}</span>`);
            // range.insertNode(htmlNode);
            this.clearSelection();
          }
        }
      })
    )
  }

  insertEmpty(cursorPosition) {
    // Создаем текстовый узел
    const textNode = document.createTextNode('');
    cursorPosition.insertNode(textNode);

    // Устанавливаем курсор после вставленного текстового узла
    const newRange = document.createRange();
    newRange.setStartAfter(textNode);
    newRange.collapse(true);

    // Устанавливаем фокус на вставленный текстовый узел
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(newRange);
  }

  clearSelection() {
    window.getSelection().removeAllRanges();
  }

  copyTmpl() {
    let edDiv = this.editableDiv.nativeElement;
    let html = this.editableDiv.nativeElement.innerHTML;
    navigator.clipboard.writeText(html).then(() => {
      this.layoutService.showSnackBar({name: ''}, marker("HTML скопирован в буфер обмена"), SnackBarItem)
      console.log('HTML скопирован в буфер обмена');
    }).catch(err => {
      this.layoutService.showSnackBar({name: 'Ошибка при копировании HTML в буфер обмена:'}, err, SnackBarItem)
      console.error('Ошибка при копировании HTML в буфер обмена: ', err);
    });
  }

  pasteTmpl() {
    const editableDiv = this.editableDiv.nativeElement;
    const cursorPosition = window.getSelection().getRangeAt(0);
    navigator.clipboard.readText().then(html => {
      // html содержит HTML из буфера обмена
      const newLineNode = document.createElement('div'); // Можно также использовать 'br', если вы хотите просто новую строку
      // const tableNode = document.createRange().createContextualFragment(table);

      if (!editableDiv.contains(cursorPosition.startContainer)) {
        const newLineNode = document.createElement('div'); // Можно также использовать 'br', если вы хотите просто новую строку
        // const tableNode = document.createRange().createContextualFragment(table);
        newLineNode.innerHTML = html
        editableDiv.appendChild(newLineNode);
        this.clearSelection();
      } else {
        const range = document.createRange();
        range.setStart(cursorPosition.startContainer, cursorPosition.startOffset);
        const newLineNode = document.createElement('div');
        newLineNode.innerHTML = html
        range.insertNode(newLineNode);
        this.clearSelection();
      }
      
      this.layoutService.showSnackBar({name: ''}, marker('HTML вставлен успешно'), SnackBarItem)
    }).catch(err => {
      this.layoutService.showSnackBar({name: 'Ошибка при чтении HTML из буфера обмена:'}, err, SnackBarItem)
      console.error('Ошибка при чтении HTML из буфера обмена: ', err);
    });
  }

  clearTmpl() {
    this.editableDiv.nativeElement.innerHTML = ''
  }
  
  addTable(block?) {
    const editableDiv = this.editableDiv.nativeElement;
    const cursorPosition = window.getSelection().getRangeAt(0);
    console.log("addTableComponent cursorPosition", cursorPosition)
    let values;
    if (block) {
      let columns = [];
      block.target.dataset.row_var.split(',').forEach(el => {
        columns.push({
          row_var: el,
          head_name: '',
          row_type: '',
          row_name: '',
          font_size: 12,
          padding_left: 0,
          padding_right: 0,
          padding_top: 0,
          padding_bottom: 0,
        })
      });
      block.target.dataset.head_name.split(',').forEach((el,i) => {
        columns[i].head_name = el;
      });
      if (block.target.dataset.row_type) {
        block.target.dataset.row_type.split(',').forEach((el,i) => {
          columns[i].row_type = el;
        });
      }
      if (block.target.dataset.row_name) {
        block.target.dataset.row_name.split(',').forEach((el,i) => {
          columns[i].row_name = el;
        });
      }
      block.target.dataset.font_size.split(',').forEach((el,i) => {
        columns[i].font_size = el;
      });
      block.target.dataset.padding_left.split(',').forEach((el,i) => {
        columns[i].padding_left = el;
      });
      block.target.dataset.padding_right.split(',').forEach((el,i) => {
        columns[i].padding_right = el;
      });
      block.target.dataset.padding_top.split(',').forEach((el,i) => {
        columns[i].padding_top = el;
      });
      block.target.dataset.padding_bottom.split(',').forEach((el,i) => {
        columns[i].padding_bottom = el;
      });
      values = {
        columns: columns,
        source: block.target.dataset.source,
        sourceItem: block.target.dataset.sourceItem,
      }
    }

    const dialogRef = this.dialog.open(AddTableComponent, {
      disableClose: true,
      data: {
        company: this.data.company,
        values: values
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log("addVariable result", result)
        if (result.data) {
          if (result.data.source == "$statement_records") {
            let tableHead = ``;
            let tableBody = ``;
            result.data.columns.forEach(col => {
              tableHead += `<td style="font-size: ${col.font_size}px; padding-left: ${col.padding_left}px; padding-right: ${col.padding_right}px; padding-top: ${col.padding_top}px; padding-bottom: ${col.padding_bottom}px;"><b>${col.head_name}</b></td>`;
              let td;
              if (!!col.row_var && col.row_var == 'primaryParameterValuesToTask') {
                td = `<td style="font-size: ${col.font_size}px; padding-left: ${col.padding_left}px; padding-right: ${col.padding_right}px; padding-top: ${col.padding_top}px; padding-bottom: ${col.padding_bottom}px;"><span class='variable' contenteditable="false" style="display: inline-block; font-size: 16px; line-height: 26px;" data-type='${col.row_type}' data-name='${col.row_name}'>{assign 'comma' 0}{foreach $s_r.taskOperation.parameterValuesToTask as $tag}{if $tag.is_primary != 0}{if $comma}, {/if}{$tag.parameterValue.value|escape}{assign 'comma' 1}{/if}{/foreach}</span></td>`
              } else if (!!col.row_var) {
                td = `<td style="font-size: ${col.font_size}px; padding-left: ${col.padding_left}px; padding-right: ${col.padding_right}px; padding-top: ${col.padding_top}px; padding-bottom: ${col.padding_bottom}px;"><span class='variable' contenteditable="false" style="display: inline-block; font-size: 16px; line-height: 26px;" data-type='${col.row_type}' data-name='${col.row_name}'>{${result.data.source_item}.${col.row_var}|escape}</span></td>`
              }
              tableBody += !!td ? td : "<td></td>"
            })


            let table = `
              <section class='st_tmpl_block table' 
              data-source='${result.data.source}' 
              data-source-item='${result.data.source_item}' 
              data-row_name='${result.data.columns.map(col => col.row_name)}' 
              data-row_type='${result.data.columns.map(col => col.row_type)}' 
              data-font_size='${result.data.columns.map(col => col.font_size)}' 
              data-padding_left='${result.data.columns.map(col => col.padding_left)}' 
              data-padding_right='${result.data.columns.map(col => col.padding_right)}' 
              data-padding_top='${result.data.columns.map(col => col.padding_top)}' 
              data-padding_bottom='${result.data.columns.map(col => col.padding_bottom)}' 
              data-head_name='${result.data.columns.map(col => col.head_name)}' 
              data-row_var='${result.data.columns.map(col => col.row_var)}'>
                <table style='width: 100%;'>
                  <thead>
                    <tr>
                      ${tableHead}
                    </tr>
                  </thead>
                  <tbody>
                    <!-- {foreach ${result.data.source} as ${result.data.source_item}} -->
                      <tr>
                        ${tableBody}
                      </tr>
                    <!-- {/foreach} -->
                  </tbody>
                </table> 
              </section>
            `

            if (block) {
              block.target.innerHTML = table;
              this.clearSelection();
            } else {
              console.log("table", table)
              if (!editableDiv.contains(cursorPosition.startContainer)) {
                const newLineNode = document.createElement('div'); // Можно также использовать 'br', если вы хотите просто новую строку
                // const tableNode = document.createRange().createContextualFragment(table);
                newLineNode.innerHTML = table
                editableDiv.appendChild(newLineNode);
                this.clearSelection();
              } else {
                const range = document.createRange();
                range.setStart(cursorPosition.startContainer, cursorPosition.startOffset);
                const newLineNode = document.createElement('div');
                newLineNode.innerHTML = table
                range.insertNode(newLineNode);
                this.clearSelection();
              }
            }

          }
        }
      })
    )
  }

  addPageDivider() {
    const editableDiv = this.editableDiv.nativeElement;
    const cursorPosition = window.getSelection().getRangeAt(0);
    console.log("addTableComponent cursorPosition", cursorPosition)
    let divider = `<br><div class='st_tmpl_block divider' style="page-break-after:always;"> </div><br>`;
    if (!editableDiv.contains(cursorPosition.startContainer)) {
      const newLineNode = document.createElement('div'); // Можно также использовать 'br', если вы хотите просто новую строку
      // const tableNode = document.createRange().createContextualFragment(table);
      newLineNode.innerHTML = divider
      editableDiv.appendChild(newLineNode);
    } else {
      const range = document.createRange();
      range.setStart(cursorPosition.startContainer, cursorPosition.startOffset);
      const newLineNode = document.createElement('div');
      newLineNode.innerHTML = divider
      range.insertNode(newLineNode);
    }
    console.log("editableDiv",editableDiv)
    console.log("editableDiv.chidrens",editableDiv.children)
    Array.from(editableDiv.children).filter((x:HTMLElement) => !!x.querySelectorAll('.divider').length).forEach((ch:HTMLElement) => {
      console.log("ch", ch)
      console.log("ch", ch.querySelectorAll('.divider').length)
      const replLineNode = document.createRange().createContextualFragment(divider)
      // const replLineNode = document.createElement('div');
      // replLineNode.innerHTML = divider
      ch.replaceWith(replLineNode)
    })
    
    this.clearSelection();

  }

  deleteBlock(block) {
    console.log("deleteBlock", block)
    block.target.remove();
    this.selectedBlock = undefined;
  }

  editBlock(block) {
    console.log("editBlock", block)
    console.log("block.target.dataset", block.target.dataset)
    this.addTable(block)
  }

  ngAfterViewInit() {
    this.attachSubscriptions(
      fromEvent(this.editableDiv.nativeElement, 'click')
      .pipe(
        filter((event: MouseEvent) => {
          this.removeActiveClassFromBlocks();
          const target = event.target as HTMLElement;
          if (target.closest('section') && target.closest('section').classList.contains('table')) {
            target.closest('section').classList.add('active');
            this.selectedBlock = {
              is_selected: true,
              target: target.closest('section'),
              type: 'table',
              position: {
                x: target.closest('section').getBoundingClientRect().right,
                y: target.closest('section').getBoundingClientRect().y
              }
            }
            return true;
          } else if (target.closest('.st_tmpl_block') && target.closest('.st_tmpl_block').classList.contains('divider')) {
            target.closest('.st_tmpl_block').classList.add('active');
            this.selectedBlock = {
              is_selected: true,
              target: target.closest('.st_tmpl_block'),
              type: 'divider',
              position: {
                x: target.closest('.st_tmpl_block').getBoundingClientRect().right,
                y: target.closest('.st_tmpl_block').getBoundingClientRect().y
              }
            }
            return true;
          } else {
            this.selectedBlock = undefined;
            return false;
          }
        }),
        map((event: MouseEvent) => {
          const target = event.target as HTMLElement;
          let type = ''
          if (target.closest('section') && target.closest('section').classList.contains('table')) {
            type = 'table'
          } else if (target.closest('.st_tmpl_block') && target.closest('.st_tmpl_block').classList.contains('divider')) {
            type = 'divider'
          }
          return {
            event: event,
            type: type
          }
        })
      )
      .subscribe((data: any) => {
        console.log('Сlicked on editable block', data);
      })
    )
  }

  insertText() {
    // const cursorPosition = window.getSelection().getRangeAt(0);
    // const range = document.createRange();
    // const textNode = document.createTextNode('{$Hello world}');
    // range.setStart(cursorPosition.startContainer, cursorPosition.startOffset);
    // range.insertNode(textNode);
    const editableDiv = this.editableDiv.nativeElement;
    const cursorPosition = window.getSelection().getRangeAt(0);
    
    // Проверяем, находится ли фокус внутри editableDiv
    if (!editableDiv.contains(cursorPosition.startContainer)) {
      // Если editableDiv не в фокусе, вставляем новый элемент с новой строкой
      const newLineNode = document.createElement('div'); // Можно также использовать 'br', если вы хотите просто новую строку
      newLineNode.appendChild(document.createTextNode('{$Hello world}'));
      editableDiv.appendChild(newLineNode);
    } else {
      // Если editableDiv в фокусе, вставляем текст в текущую позицию
      const range = document.createRange();
      const textNode = document.createTextNode('{$Hello world}');
      range.setStart(cursorPosition.startContainer, cursorPosition.startOffset);
      range.insertNode(textNode);
    }
  }

  closeBtn() {
    if (this.isFormChange) {
      this.layoutService.openBottomSheet(this.dialogRef);
    } else {
      this.close();
    }
  }


  convert(str){
    return str.replace(/&quot;/g,'"')
    .replace(/&gt;/g,'>')
    .replace(/&lt;/g,'<')
    .replace(/&amp;/g,'&')
  }



  submitForm() {
    if (!this.form.valid ) {
      this.layoutService.showSnackBar({name: ''}, marker("You need to fill in all required fields"), SnackBarItem)
      return
    }

    this.isSubmit = true;

    let edDiv = this.editableDiv.nativeElement
    let html = this.editableDiv.nativeElement.innerHTML


    console.log("edDiv", edDiv)
    console.log("html", html)
    if (this.data.tmpl) {
      let editData:any = {
        data: html,
        template: this.convert(html.replaceAll(`contenteditable="false" style="display: inline-block; font-size: 16px; line-height: 26px;"`, '').replaceAll('<!--', '').replaceAll('-->', '')),
        name: this.form.value.name
      }
      this.attachSubscriptions(
        this.statementService.editStatementTemplate(this.company_id, this.data.tmpl.id, editData).subscribe(resp => {
          this.dialogRef.close({event: "update", data: resp});
          this.layoutService.showSnackBar({name: 'Editing successful'}, '', SnackBarItem)
          this.isSubmit = false;
        }, error => {
          this.layoutService.showSnackBar({name: 'Failed to edit'}, error, SnackBarItem)
          this.isSubmit = false;
          // this.dialogRef.close({event: "error"})
        })
      )
    } else {
      let addData = {...this.form.value}
      addData.data = html;
      addData.template = this.convert(html.replaceAll(`contenteditable="false" style="display: inline-block; font-size: 16px; line-height: 26px;"`, '').replaceAll('<!--', '').replaceAll('-->', ''))
      this.attachSubscriptions(
        this.statementService.addStatementTemplate(this.data.company_id, addData).subscribe(resp => {
          this.dialogRef.close({event: "update", data: resp});
          this.isSubmit = false;
        }, error => {
          this.layoutService.showSnackBar({name: 'Failed to add'}, error, SnackBarItem)
          this.isSubmit = false;
          // this.dialogRef.close({event: "error"});
        })
      )
    }
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
