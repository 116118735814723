import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { BaseClass } from '../../models/base-class';
import { FileService } from '../../services/rest/file.service';
import { catchError, filter, last, map, switchMap, take, tap } from 'rxjs/operators';
import { TaskService } from '../../services/rest/task.service';
import { ChatService } from '../../services/rest/chat.service';
import { Subscription, forkJoin, fromEvent, of } from 'rxjs';
import { casesModel } from '../../functions/casesModel';
import { OpenJobComponent } from 'src/app/components/workspace-pages/cases/dialogs/open-job/open-job.component';
import { MatDialog } from '@angular/material/dialog';
import { SelectionModel } from '@angular/cdk/collections';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { TemplatePortal } from '@angular/cdk/portal';
import { DeleteAlertComponent } from '../delete-alert/delete-alert.component';
import { OpenTaskComponent } from 'src/app/components/workspace-pages/cases/dialogs/open-task/open-task.component';
import { VideoViewerComponent } from '../file-manager/dialog/video-viewer/video-viewer.component';
import { Router } from '@angular/router';
import { StorageManagerService } from '../../services/common/storage-manager.service';
import { VideoEditorV5Component } from '../file-manager/dialog/video-editor-v5/video-editor-v5.component';
import { PublishToOutletComponent } from '../file-manager/dialog/publish-to-outlet/publish-to-outlet.component';
import { CompanyService } from '../../services/rest/company.service';
import { FileSettingsComponent } from '../file-manager/dialog/file-settings/file-settings.component';
import { mimeTypes } from 'mime-wrapper';
import { RenameComponent } from '../file-manager/dialog/rename/rename.component';
import { FormControl } from '@angular/forms';
import { OperationChatComponent } from 'src/app/components/atTasksDialog/operation-chat/operation-chat.component';
import { AddChatToBoardComponent } from 'src/app/components/workspace-pages/chats-page/dialogs/add-chat-to-board/add-chat-to-board.component';
import { CreateOperationChatComponent } from 'src/app/components/atTasksDialog/create-operation-chat/create-operation-chat.component';
import { SnackBarItem } from '../snack-bar/snack-bar-item';
import { LayoutService } from '../../services/common/layout.service';
import { ChatStatusesComponent } from '../chat/dialogs/chat-statuses/chat-statuses.component';
import { ChatConnectionsComponent } from 'src/app/components/workspace-pages/chats-page/dialogs/chat-connections/chat-connections.component';
import { TargetParametersComponent } from 'src/app/components/atTasksDialog/target-parameters/target-parameters.component';

@Component({
  selector: 'app-lite-chats',
  templateUrl: './lite-chats.component.html',
  styleUrls: ['./lite-chats.component.scss']
})
export class LiteChatsComponent extends BaseClass implements OnInit, OnDestroy {
  @ViewChild('chatMenu') chatMenu: TemplateRef<any>;
  @Input() target: any;
  @Input() company: any;
  @Input() is_mobile: any;
  @Input() host: any;
  @Input() user: any;
  @Input() activeLang: any;
  @Input() imgRoute: any;
  @Input() liteFilter: any;
  @Input() liteFilterForm: any;
  @Input() useOut: boolean = false;
  public statuses: any;
  public chatsIndex: FormControl = new FormControl(0);
  public operationsValues: any;
  public origin = window.location.origin;
  throttle = 100;
  scrollUpDistance = 3.5;

  public tabStatuses = {
    0: [0],
    1: [1,2,5],
    2: [3,4,6,97,98,99]
  }

  public overlayRefChatMenu: OverlayRef | null;
  public subChatMenu: Subscription;
  @Output() CloseContext = new EventEmitter<any>();
  @Output() OpenJob = new EventEmitter<any>();
  constructor(
    private dialog: MatDialog,
    private fileService: FileService,    
    public router: Router,
    public sm: StorageManagerService,
    public overlay: Overlay,
    public viewContainerRef: ViewContainerRef,
    public layoutService: LayoutService,
    public companyService: CompanyService,
    private bottomSheet: MatBottomSheet,
    private taskService: TaskService,
    private chatService: ChatService,
  ) {
    super()
   }

  ngOnInit(): void {
    console.log("ngOnInit LiteChatsComponent liteFilter", this.liteFilter)
    this.target.outputChats.page = 1;
    this.target.outputChats.pagination = undefined;
    this.target.outputChats.is_load = true;
    this.target.outputChats.chats = [];
    this.getTargetChats(this.target);
    this.getOperations();
    this.getOperationsStatus();

    this.attachSubscriptions(
      this.chatsIndex.valueChanges.subscribe(resp => {
        this.target.outputChats.page = 1;
        this.target.outputChats.pagination = undefined;
        this.target.outputChats.is_load = true;
        this.target.outputChats.chats = [];
        this.getTargetChats(this.target);
      })
    )
  }

  openInNew(e, chat) {
    e.preventDefault();
    e.stopPropagation();
    console.log("openInNew", chat);
    window.open(`${this.origin}/chat/${chat.acm}`, '_blank')
  }

  openTargetValues(info, element) {
    let initData:any = {
      company: this.company,
      chat: element
    }

    initData.target = {
      task_id: info[0],
      task_operation_id: info[1],
      discussion_id: info[2]
    }
    if (element.company_id != this.company.id) {
      initData.target.partner_company_id = element.company_id
    }
    const dialogRef = this.dialog.open(TargetParametersComponent, {
      backdropClass: ['parameters_modal_backdrop'],
      panelClass: ['without_paddings_modal', 'parameters_modal'],
      data: initData
    });

    // this.attachSubscriptions(
    //   dialogRef.afterClosed().subscribe(result => {
    //     this.refreshService.refreshTask$.next({company_id: this.company_id, task_id: element.id})
    //   })
    // )
  }
  
  openConnectionChats(chat) {
    const goToChatCon = this.dialog.open(ChatConnectionsComponent, {
      backdropClass: 'backdrop_under_header',
      panelClass: !this.is_mobile ? ['open_task_dialog', 'show_header'] : 'open_task_dialog',
      data: {
        company: this.company,
        user: this.user,
        chat: chat,
        is_mobile: this.is_mobile,
        imgRoute: this.imgRoute,
        operationsValues: this.operationsValues,
        host: this.host,
        activeLang: this.activeLang,
      }
    });

    // this.attachSubscriptions(
    //   goToChatCon.afterClosed().subscribe(result => {
    //     this.updateDiscussion(this.chat_id)
    //     this.refreshService.refreshChat$.next({company_id: this.company.id, chat_id: this.chat_id})
    //     this.initRefreshBoard()
    //   })
    // )
  }

  neededTaskData(task, needChats:boolean = true) {
    let arr = [];
    if (this.company.permissions.includes('owner') || this.checkIsManager(task, this.company, this.user)) {
      arr.push(this.taskService.getTaskClients(task.id, this.company.id).pipe(
        tap(res => {
          task.clients = res
        }),
        catchError(err => {
          return err
        })
      ))
    }

    if (needChats) {
      arr.push(this.chatService.getTasksChats(this.company.id, [task.id]).pipe(
        tap(res => {
          task['operations'].map(o_el => {
            o_el['chatsInfo'] = [];
            o_el['chatsInfo'].push(...res.filter(z => z.task_operation_id == o_el.id));
            o_el['unReadChats'] = res.filter(z => z.task_operation_id == o_el.id && z.is_read != '1').length;
            o_el['unClosedChats'] = res.filter(z => z.task_operation_id == o_el.id && z.status_id != '4').length;
          });
          task['chatsInfo'] = [];
          task['chatsInfo'].push(...res.filter(z => z.task_id == task.id));
          task['unReadChats'] = res.filter(z => z.is_read != '1').length;
          task['unClosedChats'] = res.filter(z => z.status_id != '4').length;
        })
      ))
    }
    if (arr.length) {
      return forkJoin(arr)
    } else {
      return of(task)
    }
  }

  toPersonalStatuses(chat) {
    this.attachSubscriptions(
      this.taskService.getOneTaskExpand(this.company.id, chat.task_id).pipe(
        map(x => x.body[0]),
        switchMap(val => this.neededTaskData(val, false).pipe(map(() => casesModel([val], [], 'update')),map(val => val.arr[0])))
      ).subscribe(resp => {
        console.log("addToBoard", resp)
        let work = resp.operations.find(x => x.id == chat.task_operation_id);
        work.employees = work.employees.filter(x => x.discussion_id == chat.id || x.discussion_id == 0 || !x.discussion_id)
        work.mainEmployees = work.mainEmployees.filter(x => x.discussion_id == chat.id || x.discussion_id == 0 || !x.discussion_id)
        work.addEmployee = work.addEmployee.filter(x => x.discussion_id == chat.id || x.discussion_id == 0 || !x.discussion_id)
        work.addEmployee.forEach(x => {
          if (!x.employeeStatus && chat.employeesStatuses.find(z => z.employee_id == x.employee_id)) {
            x.employeeStatus = chat.employeesStatuses.find(z => z.employee_id == x.employee_id)
          }
        })
        const goToChat = this.dialog.open(ChatStatusesComponent, {
          panelClass: 'add_to_board_modal',
          data: {
            company: this.company,
            user: this.user,
            imgRoute: this.imgRoute,
            host: this.host,
            activeLang: this.activeLang,
            chat: chat,
            employees: chat.employees,
            needGetMembers: true,
            task: resp,
            job: work,
            statuses: this.statuses,
            empl_status: this.company.permissions.includes('owner') || this.checkIsManager(resp, this.company, this.user),
            operationsValues: this.operationsValues,
          }
        });

        // this.attachSubscriptions(
        //   goToChat.afterClosed().subscribe(result => {
        //     this.refreshService.refreshChat$.next({company_id: this.company_id, chat_id: chat.id})
        //   })
        // )
      })
    )

  }

  addToBoard(chat) {
    this.attachSubscriptions(
      this.taskService.getOneTaskExpand(this.company.id, chat.task_id).pipe(
        map(x => x.body[0]),
        switchMap(val => this.neededTaskData(val).pipe(map(() => casesModel([val], [], 'update')),map(val => val.arr[0])))
      ).subscribe(resp => {
        console.log("addToBoard", resp)
        let work = resp.operations.find(x => x.id == chat.task_operation_id);
        work.employees = work.employees.filter(x => x.discussion_id == chat.id || x.discussion_id == 0 || !x.discussion_id)
        work.mainEmployees = work.mainEmployees.filter(x => x.discussion_id == chat.id || x.discussion_id == 0 || !x.discussion_id)
        work.addEmployee = work.addEmployee.filter(x => x.discussion_id == chat.id || x.discussion_id == 0 || !x.discussion_id)
        work.addEmployee.forEach(x => {
          if (!x.employeeStatus && chat.employeesStatuses.find(z => z.employee_id == x.employee_id)) {
            x.employeeStatus = chat.employeesStatuses.find(z => z.employee_id == x.employee_id)
          }
        })
        const goToChat = this.dialog.open(AddChatToBoardComponent, {
          panelClass: 'add_to_board_modal',
          data: {
            company: this.company,
            user: this.user,
            imgRoute: this.imgRoute,
            host: this.host,
            activeLang: this.activeLang,
            chat: chat,
            employees: chat.employees,
            needGetMembers: true,
            task: resp,
            job: work,
            statuses: this.statuses,
            empl_status: this.company.permissions.includes('owner') || this.checkIsManager(resp, this.company, this.user),
            operationsValues: this.operationsValues,
          }
        });

        // this.attachSubscriptions(
        //   goToChat.afterClosed().subscribe(result => {
        //     this.refreshService.refreshChat$.next({company_id: this.company_id, chat_id: chat.id})
        //   })
        // )
      })
    )

  }

  getOperationsStatus() {
    this.attachSubscriptions(
      this.taskService.getOperationsStatus().subscribe(resp => {
        this.statuses = resp.slice();
      })
    )
  }

  createChat() {
    this.attachSubscriptions(
      this.taskService.getOneTaskExpand(this.company.id, this.target.outputChats.targetIds.task_id).pipe(
        map(x => x.body[0]),
        switchMap(x => this.neededData(x, this.company).pipe(map(() => casesModel([x], [], 'update')),map(x => x.arr[0]))),
      ).subscribe(resp => {
        const dialogRef = this.dialog.open(CreateOperationChatComponent, {
          disableClose: true,
          data: {
            is_create_chat_component: true,
            company: this.company,
            task: resp,
            work: !!this.target.outputChats.targetIds.task_operation_id && resp.operations.find(u => u.id == this.target.outputChats.targetIds.task_operation_id) ? resp.operations.find(u => u.id == this.target.outputChats.targetIds.task_operation_id) : undefined,
            user: this.user,
            empl_status: this.company.permissions.includes('owner') || this.checkIsManager(resp, this.company, this.user),
            operationsValues: this.operationsValues
          }
        });
    
        this.attachSubscriptions(
          dialogRef.afterClosed().subscribe(result => {
            console.log(result)
            if (!!result && result.event == 'create') {
              this.target.outputChats.page = 1;
              this.target.outputChats.pagination = undefined;
              this.target.outputChats.is_load = true;
              this.target.outputChats.chats = [];
              this.getTargetChats(this.target);
            }
          })
        )
      })
    )

  }

  goToChat(chat, job) {
    console.log("goToChat", chat, job)
    this.attachSubscriptions(
      this.taskService.getOneTaskExpand(this.company.id, this.target.outputChats.targetIds.task_id).pipe(
        map(x => x.body[0]),
        switchMap(x => this.neededData(x, this.company).pipe(map(() => casesModel([x], [], 'update')),map(x => x.arr[0]))),
      ).subscribe(resp => {
        const goToChat = this.dialog.open(OperationChatComponent, {
          backdropClass: ['backdrop_under_header', "without_pad"],
          panelClass: !this.is_mobile ? ['full_screen_dialog', 'global_chats_dialog', 'show_header'] : ['full_screen_dialog', 'global_chats_dialog'],
          position: !this.is_mobile ? { top: '58px', left: '0', right: '0', bottom: '0' } : {},
          autoFocus: this.is_mobile ? false : true,
          data: {
            company: this.company,
            task: resp,
            work: !!this.target.outputChats.targetIds.task_operation_id && resp.operations.find(u => u.id == this.target.outputChats.targetIds.task_operation_id) ? resp.operations.find(u => u.id == this.target.outputChats.targetIds.task_operation_id) : undefined,
            dialogType: 'operation_chat',
            user: this.user,
            empl_status: this.company.permissions.includes('owner') || this.checkIsManager(resp, this.company, this.user),
            chat: chat,
            chats: this.target.outputChats.chats,
            closeFull: true,
            operationsValues: this.operationsValues
          }
        });
    
        this.attachSubscriptions(
          goToChat.afterClosed().subscribe(result => {
            this.target.outputChats.page = 1;
            this.target.outputChats.pagination = undefined;
            this.target.outputChats.is_load = true;
            this.target.outputChats.chats = [];
            this.getTargetChats(this.target);
          })
        )
      })
    )
  }

  checkIsManager(task, company, _user) { 
    return task?.managers.filter(x => x.user_id == _user.id).length > 0 || task?.group?.managers.filter(x => x.user_id == _user.id).length > 0 || company?.permissions.includes('admin') || company?.permissions.includes('owner') || company?.permissions.includes('manager'); 
  }

  log() {
    console.log('target', this.target)
  }

  understandFileType(val) {
    if (!val) {
      return ""
    }
    
    if (val == 'application/pdf') {
      return 'pdf'
    } else if (val.indexOf("/") != -1) {
      return val.split('/')[0]
    } else {
      return ""
    }
  }

  getOperations() {
    this.attachSubscriptions(
      this.taskService.getOperations(this.company.id, this.activeLang).subscribe(resp => {
        this.operationsValues = resp;
      })
    )
  }

  neededCardData(task) {
    let arr = [
      this.chatService.getTasksChats(this.company.id, [task.id]).pipe(
        tap(res => {
          task['operations'].map(o_el => {
            o_el['chatsInfo'] = [];
            o_el['chatsInfo'].push(...res.filter(z => z.task_operation_id == o_el.id));
            o_el['unReadChats'] = res.filter(z => z.task_operation_id == o_el.id && z.is_read != '1').length;
            o_el['unClosedChats'] = res.filter(z => z.task_operation_id == o_el.id && z.status_id != '4').length;
          });
          task['chatsInfo'] = [];
          task['chatsInfo'].push(...res.filter(z => z.task_id == task.id));
          task['unReadChats'] = res.filter(z => z.is_read != '1').length;
          task['unClosedChats'] = res.filter(z => z.status_id != '4').length;
        })
      )
    ]
    if (task.company_id == this.company.id) {
      arr.push(
        this.taskService.getTaskClients(task.id, this.company.id).pipe(
          tap(res => {
            task.clients = res
          }),
          catchError(err => {
            return err
          })
        )
      )
    }
    arr.push(...task.operations.filter(z => (z.status_id == 3 && z.is_to_approve_files) || (z.status_id == 98 && z.is_approved_files)).map(x => this.fileService.getFilesForOpenTask(this.company.id, x.id, x.status_id == 3 ? '/to_approve' : '/ready_files').pipe(
      tap(val => {
        x.filesCount = val.headers.get('x-pagination-total-count')
        x.files = val.body
      })
    )))

    return forkJoin(arr)
  }

  openJob(task_id, task_operation_id, loc?) {
    if (this.target.can_open) {
      this.OpenJob.emit(true);
    } else {
      this.attachSubscriptions(
        this.taskService.getOneTaskExpand(this.company.id, task_id).pipe(
          map(x => x.body[0]),
          switchMap(x => this.neededCardData(x).pipe(map(() => casesModel([x], [], 'update')),map(x => x.arr[0]))),
        ).subscribe(resp => {
          const dialogRef = this.dialog.open(OpenJobComponent, {
            backdropClass: 'backdrop_under_header',
            panelClass: !this.is_mobile ? ['open_task_dialog', 'show_header'] : 'open_task_dialog',
            autoFocus: false,
            data: {
              task_id: task_id,
              task_operation_id: task_operation_id,
              task: resp,
              initCompanyId: this.company.id,
              loc: loc
            }
          });
        })
      )
    }
  }

  openTask(task) {
    const dialogRef = this.dialog.open(OpenTaskComponent, {
      backdropClass: 'backdrop_under_header',
      panelClass: !this.is_mobile ? ['open_task_dialog', 'show_header'] : 'open_task_dialog',
      autoFocus: false,
      data: {
        task_id: task.id,
        initCompanyId: this.company.id
      }
    });
  }

  
  neededData(task, company) {
    let arr = [
      this.chatService.getTasksChats(company.id, [task.id]).pipe(
        tap(res => {
          task['operations'].map(o_el => {
            o_el['chatsInfo'] = [];
            o_el['chatsInfo'].push(...res.filter(z => z.task_operation_id == o_el.id));
            o_el['unReadChats'] = res.filter(z => z.task_operation_id == o_el.id && z.is_read != '1').length;
            o_el['unClosedChats'] = res.filter(z => z.task_operation_id == o_el.id && z.status_id != '4').length;
          });
          task['chatsInfo'] = [];
          task['chatsInfo'].push(...res.filter(z => z.task_id == task.id));
          task['unReadChats'] = res.filter(z => z.is_read != '1').length;
          task['unClosedChats'] = res.filter(z => z.status_id != '4').length;
        })
      )
    ]
    if (task.company_id == company.id) {
      arr.push(
        this.taskService.getTaskClients(task.id, company.id).pipe(
          tap(res => {
            task.clients = res
          }),
          catchError(err => {
            return err
          })
        )
      )
    }
    return forkJoin(arr)
  }

  onImgError(event){
    event.target.src = this.imgRoute+'/assets/img/image_black_48dp.svg'
  }

  onDownChats($event, target) {
    if (target.outputChats.pagination['pageCount'] >= target.outputChats.page) {
      console.log("onDownChats")
      this.getTargetChats(target)
    }
  }


  changeTab(val) {
    this.chatsIndex.patchValue(val)
  }

  getTargetChats(target) {
    // let params:any = {}
    // if (!!this.chatsIndex.value) {
    //   params.status_id = this.tabStatuses[this.chatsIndex.value]
    // }
    let filterData = {...this.liteFilter};
    if (!!this.chatsIndex.value) {
      filterData.status_id = this.tabStatuses[this.chatsIndex.value]
    }
    // Object.keys(filterData).filter(x => x != 'preset_id').forEach(element => {
    //   if (element != 'location_type') {
    //     if (element.indexOf('_at_') != -1 && filterData[element] !== '') {
    //       params[element] = filterData[element].format("X")
    //     } else if (['discussion_parameter_value_id'].includes(element) && filterData[element] && filterData[element].length > 0) {
    //       params[element] = filterData[element].join(',')
    //     } else if (filterData[element] !== '') {
    //       params[element] = filterData[element]
    //     }
    //   }
    // });
    this.attachSubscriptions(
      this.chatService.getChatsExpand(target.outputChats.page, this.company.id, filterData, '20', target.outputChats.targetIds.task_id, target.outputChats.targetIds.task_operation_id).pipe(
        tap(el => {
          if (target.outputChats.page == 1) {
            target.outputChats.pagination = {
              'pageCount': el.headers.get('x-pagination-page-count'),
              'perPage': el.headers.get('x-pagination-per-page'),
              'totalCount': el.headers.get('x-pagination-total-count'),
              'currentPage': el.headers.get('x-pagination-current-page'),
            }
          }
        }),
        map(el => el.body)
      ).subscribe(resp => {
        if (target.outputChats.page == 1) {
          target.outputChats.chats = resp;
        } else {
          target.outputChats.chats.push(...resp);
        }
        target.outputChats.page++;
        target.outputChats.is_load = false;
        console.log("openContext target.outputChats", target.outputChats)
      })
    )
  }

  getCircularReplacer() {
    const ancestors:any = [];
    return function (key, value) {
      if (typeof value !== "object" || value === null) {
        return value;
      }
      // `this` is the object that value is contained in,
      // i.e., its direct parent.
      while (ancestors.length > 0 && ancestors.at(-1) !== this) {
        ancestors.pop();
      }
      if (ancestors.includes(value)) {
        return "[Circular]";
      }
      ancestors.push(value);
      return value;
    };
  }

  copyLink(type) {
    this.layoutService.showSnackBar({name: type + " link"}, "Copied", SnackBarItem)
  }

  closeChatMenu() {
    this.subChatMenu && this.subChatMenu.unsubscribe();
    if (this.overlayRefChatMenu) {
      this.overlayRefChatMenu.dispose();
      this.overlayRefChatMenu = null;
    }
  }

  openChatMenu({ x, y }: MouseEvent, target, chat) {
    this.closeChatMenu();
    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo({ x, y })
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        }
      ]);

    this.overlayRefChatMenu = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });


    this.overlayRefChatMenu.attach(new TemplatePortal(this.chatMenu, this.viewContainerRef, {
      $implicit: {
        chat: chat,
        target: target
      }
    }));
    
    this.subChatMenu = fromEvent<MouseEvent>(document, 'click')
      .pipe(
        filter(event => {
          const clickTarget = event.target as HTMLElement;
          return !!this.overlayRefChatMenu && !this.overlayRefChatMenu.overlayElement.contains(clickTarget);
        }),
        take(1)
      ).subscribe(() => this.closeChatMenu())

  }

  ngOnDestroy(): void {
    this.clearSubscriptions();
  }
}
