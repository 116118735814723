<mat-dialog-content class="mat-typography" dirEnter (dirSubmit)="!form.valid ? null : onSubmit()">
    <div class="container form_wrapper">
        <div class="title_modal" *ngIf="!data.onlyName">
            <ng-container *ngIf="!data.onlyPriority; else elseTemplateTitle">
                {{ "Editing chat" | translate }}: {{data?.chat?.name}}
            </ng-container>
            <ng-template #elseTemplateTitle>
                {{ "Editing chat priority" | translate }}: {{data?.chat?.name}}
            </ng-template>
        </div>
        <form [formGroup]="form" class="form_with_pads form_with_valids">
            <mat-form-field *ngIf="!data.onlyPriority" appearance="standard" class="full_width_form">
                <ng-container *ngIf="!form.get('name').valid && submited">
                    <div class="req_field"></div>
                    <div class="req_label">
                        <div class="angle"></div>
                        <span>
                            {{ "You need to write here" | translate }}
                        </span>
                    </div>
                </ng-container>
                <mat-label>{{ 'Chat name' | translate }}</mat-label>
                <input cdkFocusInitial formControlName="name" matInput type="text" placeholder="{{ 'Chat name' | translate }}">
            </mat-form-field>

            <div class="priority_chips" *ngIf="!data.onlyName">
                <label>{{ "Priority" | translate }}: </label>
                <mat-chip-list [multiple]="false" selectable formControlName="priority">
                    <mat-chip *ngFor="let priority of prioritys" [ngClass]="{
                        'Urgent': priority.id == '1000',
                        'High': priority.id == '900',
                        'Middle': priority.id == '800',
                        'Low': priority.id == '700',
                        'No': priority.id == '0'
                    }" class="priority_chip" (click)="selectPrioritys(priority)" [value]="priority.id">
                        {{ priority.name | translate }}
                    </mat-chip>
                </mat-chip-list>
            </div>

            <ng-container *ngIf="!data.onlyPriority && !data.onlyName">
                <div class="date_and_time_label">{{ "Deadline" | translate }}</div>
                <div class="date_and_time_c_w" formGroupName="date">
                    <mat-form-field appearance="standard" class="date_picker_cst">
                        <div class="prefix" matPrefix style="margin-right: 10px;">{{ "Date" | translate }}</div>
                        <input formControlName="day" matInput [matDatepicker]="pickerDay" placeholder="MM / DD/ YYYY">
                        <mat-datepicker-toggle matSuffix [for]="pickerDay"></mat-datepicker-toggle>
                        <mat-datepicker #pickerDay></mat-datepicker>
                    </mat-form-field>
                    
                    <div class="ngx-timepicker-field-example">
                        <div class="prefix">{{ "Time" | translate }}</div>
                        <ngx-timepicker-field [format]="24" [defaultTime]="form.get('date').get('time').value" formControlName="time"></ngx-timepicker-field>
                    </div>
                </div>
            </ng-container>

            <!-- <div class="small_chips" *ngIf="statuses?.length > 0 && !data.onlyPriority">
                <ng-container *ngIf="!form.get('status_id').valid && submited">
                    <div class="req_field"></div>
                    <div class="req_label">
                        <div class="angle"></div>
                        <span>
                            {{ "You need to choose a value" | translate }}
                        </span>
                    </div>
                </ng-container>
                <label>{{ "Status" | translate }}: </label>
                <mat-chip-list [multiple]="false" selectable formControlName="status_id">
                    <ng-container *ngFor="let status of statuses">
                        <mat-chip class="small_chip" *ngIf="status.id != 98" (click)="selectStatus(status)" [disabled]="data?.company?.permissions.includes('employee') && status.is_lock == 1" [value]="status?.id" [ngClass]="{
                            'first': status.id == '1',
                            'second': status.id == '2',
                            'third': status.id == '3',
                            'fours': status.id == '4',
                            'fives': status.id == '5',
                            'sixs': status.id == '6',
                            'seven': status.id == '98',
                            'eight': status.id == '99'}">
                            {{ status.name | translate }}
                        </mat-chip>
                    </ng-container>
                </mat-chip-list>
            </div> -->

            <div class="out_block_with_title" style="margin-top: 28px;" *ngIf="statuses?.length > 0 && !data.onlyPriority && !data.onlyName">
                <div class="right_title">
                    {{ "Status" | translate}}
                </div>
                <div class="statuses_interface">
                    <div class="chat_status">
                        <span>{{ "General chat status" | translate }}</span>
                        <div class="inside_job" [ngClass]="{
                            'first': chat?.status_id == '1',
                            'second': chat?.status_id == '2',
                            'third': chat?.status_id == '3',
                            'fours': chat?.status_id == '4',
                            'fives': chat?.status_id == '5',
                            'sixs': chat?.status_id == '6',
                            'nine': chat?.status_id == '97',
                            'seven': chat?.status_id == '98',
                            'eight': chat?.status_id == '99'}">
                            <span>{{ chat?.status?.name }}</span>  
                        </div>
                    </div>
                    <div class="other_title">
                        <ng-container *ngIf="!checkIsManager(task, data?.company, data?.user) || !!iAmEmployee; else elseTemplateNoEmpl">
                            {{ "Yours and others chat status" | translate }}
                        </ng-container>
                        <ng-template #elseTemplateNoEmpl>
                            <span>{{ "Executors and general chat stauses" | translate }}</span>
                        </ng-template>
                    </div>
                    <div class="interface" cdkDropListGroup [cdkDropListGroupDisabled]="!checkIsManager(task, data?.company, data?.user) && iAmEmployee?.is_manager == 0">
                        <div class="line" *ngFor="let status of statuses">
                            <div
                                *ngIf="status.id != 98"
                                cdkDropList
                                [cdkDropListData]="status.empl"
                                [id]="status.id"
                                cdkDropListOrientation="horizontal"
                                class="example-list"
                                [cdkDropListDisabled]="!checkIsManager(task, data?.company, data?.user) && iAmEmployee?.is_manager == 0"
                                (cdkDropListDropped)="drop($event)" [ngClass]="{
                                    'first': status.id == '1',
                                    'second': status.id == '2',
                                    'third': status.id == '3',
                                    'fours': status.id == '4',
                                    'fives': status.id == '5',
                                    'sixs': status.id == '6',
                                    'nine': status.id == '97',
                                    'seven': status.id == '98',
                                    'eight': status.id == '99'}">
                                <div class="status_btn_out">
                                    <div class="status_btn text_one_line" (click)="checkIsManager(task, data?.company, data?.user) ?  managerChangeStatus(status) : ( iAmEmployee?.is_manager == 1 ? emplChangeStatus(status) : addEmlChangeStatus(status) )" [ngClass]="{
                                        'active': status.id == chat?.status_id,
                                        'disabled': status.is_lock == 1 && !checkIsManager(task, data?.company, data?.user),
                                        'first': status.id == '1',
                                        'second': status.id == '2',
                                        'third': status.id == '3',
                                        'fours': status.id == '4',
                                        'fives': status.id == '5',
                                        'sixs': status.id == '6',
                                        'nine': status.id == '97',
                                        'seven': status.id == '98',
                                        'eight': status.id == '99'}"
                                        >
                                        {{status.name}}
                                    </div>
                                </div>
                                <div class="items">
                                    <div class="item" *ngFor="let item of status.empl" [ngClass]="{
                                        'can_drag': checkIsManager(task, data?.company, data?.user) || iAmEmployee?.is_manager == 1,
                                        'active': status.id == chat?.status_id,
                                        'first': status.id == '1',
                                        'second': status.id == '2',
                                        'third': status.id == '3',
                                        'fours': status.id == '4',
                                        'fives': status.id == '5',
                                        'sixs': status.id == '6',
                                        'nine': status.id == '97',
                                        'seven': status.id == '98',
                                        'eight': status.id == '99'}" [cdkDragData]="item" cdkDrag (cdkDragEnded)="stopDragged()" (cdkDragStarted)="startDragged()" [cdkDragDisabled]="!checkIsManager(task, data?.company, data?.user) && iAmEmployee?.is_manager == 0">
                                        <ng-container *ngIf="!!item.is_partner; else elseTemplateIsPartner">               
                                            <ng-container *ngIf="!!item?.partnerCompany?.avatarFile?.thumbnail && !!host; else elseTemplatePartnerAvatar">
                                                <img class="partner_avatar" (error)="onPartnerImgError($event)" [src]="host + item?.partnerCompany?.avatarFile?.thumbnail + '?company_id=' + data?.company?.id" [ngStyle]="{'z-index': work?.mainEmployees.length - i, 'transform': 'translateX(-' + i*6 + 'px)'}" alt="">
                                            </ng-container>
                                            <ng-template #elseTemplatePartnerAvatar>
                                                <img class="partner_avatar" (error)="onPartnerImgError($event)" src="{{ imgRoute }}assets/img/partner.png" [ngStyle]="{'z-index': work?.mainEmployees.length - i, 'transform': 'translateX(-' + i*6 + 'px)'}">
                                            </ng-template>
                                        </ng-container>
                                        <ng-template #elseTemplateIsPartner>
                                            <ng-container *ngIf="item?.employee?.image; else elseStatEmpl">
                                                <img class="partner_avatar" [src]="item?.employee?.image" alt="">
                                            </ng-container>
                                            <ng-template #elseStatEmpl>
                                                <mat-icon>account_circle</mat-icon>
                                            </ng-template>
                                        </ng-template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <div class="f_w_p_btns">
        <button mat-raised-button (click)="close()">
            {{ "Close" | translate }}
        </button>
        <button class="ml-1 d-f-btn" (click)="onSubmit()" mat-raised-button cdkFocusInitial color="primary" [disabled]="isSubmit">
            <mat-spinner class="spin_16 mr-1" *ngIf="isSubmit"></mat-spinner>  
            {{ "Save" | translate }}
        </button>
    </div>
</mat-dialog-actions>