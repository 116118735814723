import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { AuthService } from 'src/app/shared/services/rest/auth.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';

import * as moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/en-gb';
import 'moment/locale/uk';
import 'moment/locale/ru';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { BaseClass } from 'src/app/shared/models/base-class';
import { SameUrlTasksComponent } from '../same-url-tasks/same-url-tasks.component';
import { TasksHaveSameUrlComponent } from 'src/app/shared/global_components/tasks-have-same-url/tasks-have-same-url.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { debounceTime, distinctUntilChanged, filter, map, tap } from 'rxjs/operators';
import { fromEvent, ReplaySubject } from 'rxjs';
import { MatSelect } from '@angular/material/select';
import { GlobalDataService } from 'src/app/shared/services/common/global-data.service';
import { NgxMatDateAdapter } from '@angular-material-components/datetime-picker';
import { environment } from 'src/environments/environment';
import { transformToWatchUrl } from 'src/app/shared/functions/liveUrlTransform';


export const MY_FORMATS = {
  parse: {
    dateInput: 'LLLLL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@Component({
  selector: 'app-task-profile-add',
  templateUrl: './task-profile-add.component.html',
  styleUrls: ['./task-profile-add.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ]
})
export class TaskProfileAddComponent extends BaseClass implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('profilesContainer') profilesContainer: MatSelect;
  public profiles: any[] = [];
  public pagination: any;
  public page: number = 1;
  public host: any = environment.host;
  public imgRoute: any = '';
  public user: any = {};
  public user_id: number;
  public urlForm: FormGroup;
  public settingsForm: FormGroup;
  public company_id: any;
  public activeLang: any;
  public task_id: any;
  public profileStatuses: any;
  public selectedIndex: number = 0;
  public isFormChange: boolean = false;
  public isParseWork: boolean = false;
  public isSubmit: boolean = false;
      
  public profilesMoreControl: FormControl = new FormControl();
  public profiles$: ReplaySubject<any> = new ReplaySubject<any>(1);
  
  public contentPagination: any;
  public contentPage: number = 1;

  public contentTypes: any[] = [];
  public contentTypeMoreControl: FormControl = new FormControl();
  public contentTypes$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public timeZone = - new Date().getTimezoneOffset();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<TaskProfileAddComponent>,
    private sm: StorageManagerService,
    private auth: AuthService,
    private companyService: CompanyService,
    private globalDataService: GlobalDataService,
    private fb: FormBuilder,
    private bottomSheet: MatBottomSheet,
    private dialog: MatDialog,
    private taskService: TaskService,
    private languageService: LanguageService,
    private _ngx_adapter: NgxMatDateAdapter<any>,
    private _adapter: DateAdapter<any>,
    private layoutService: LayoutService
  ) { super() }

  ngOnInit(): void {
    window.scroll(0,0);

    this.company_id = this.data.company_id;
    this.task_id = this.data.task.id;

    this.urlForm = this.fb.group({
      task_id: this.task_id,
      content_url: ['', Validators.required],
    })
    
    this.settingsForm = this.fb.group({
      channel_id: ['', Validators.required],
      content_name: ['', Validators.required],
      content_image: '',
      content_type_id: '',
      content_published_at: '',
      content_status_id: 1,
      content_views_count: ''
    })

    this.attachSubscriptions(
      this.settingsForm.get("channel_id").valueChanges.subscribe(resp => {
        console.log('"channel_id" valueChanges', resp)
        if (!!resp && this.profiles.find(x => x.id == resp)) {
          let platform_id = this.profiles.find(x => x.id == resp).platform_id
          this.contentPage = 1;
          this.contentTypes = [];
          this.contentTypes$.next(this.contentTypes.slice());
          this.getContentTypes(platform_id, this.contentPage);
        }
      })
    )

    this.attachSubscriptions(
      this.profilesMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchProfiles(resp))
    )

    this.attachSubscriptions(
      this.contentTypeMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchContentTypes(resp))
    )

    this.urlForm.valueChanges.subscribe(() => this.isFormChange = true);
    this.settingsForm.valueChanges.subscribe(() => this.isFormChange = true);

    this.dialogRef.backdropClick().subscribe(e => {
      e.preventDefault();
      if (this.isFormChange) {
        this.layoutService.openBottomSheet(this.dialogRef);
      } else {
        this.close();
      }
    });

    this.getCompany();
    this.getImgRoute();
    this.getCsrf();
    this.getUser();
    this.getProfiles(this.company_id, this.page);
    this.getLangData();
    this.getTaskProfiles();
    this.getProfilesStatus();
  }

  
  onInputUrl(originalUrl: string): void {
    console.log('originalUrl', originalUrl);
    let transformedUrl = transformToWatchUrl(originalUrl);
    
    if (transformedUrl != originalUrl) {
      this.urlForm.patchValue({
        content_url: transformedUrl
      })
    }
    console.log("transformedUrl", transformedUrl);
  }
  showUserDate(date:any = false, timestamp:any = false) {
    let timeAndDate;
    if (date) {
      timeAndDate = moment(date).clone()
    } else {
      timeAndDate = moment(timestamp*1000).clone()
    }

    const utcOffset = moment.tz(timeAndDate, this.data.company.timezone).utcOffset();
    
    let utcTimestamp = moment(timeAndDate).format("X")
    
    let difference = (Number(this.timeZone) - Number(utcOffset))*60
    console.log(difference);
    
    let workSpaceTimeZone;
    if (date) {
      workSpaceTimeZone = Number(utcTimestamp) + Number(difference)
    } else {
      workSpaceTimeZone = Number(utcTimestamp) - Number(difference)
    }
    return workSpaceTimeZone
  }

  onSearchContentTypes(resp) {
    if (!this.contentTypes) {
      return;
    }

    if (!resp) {
      this.contentTypes$.next(this.contentTypes.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.contentTypes$.next(
      this.contentTypes.filter(z => (z.name.toLowerCase().indexOf(resp) > -1))
    );
  }

  selectType(type) {
    this.settingsForm.patchValue({
      content_type_id: !!type.id ? type.id : type.value
    })
  }

  getContentTypes(platform_id, contentPage) {
    this.attachSubscriptions(
      this.companyService.getContentTypes(platform_id, contentPage).pipe(
        tap(el => {
          this.contentPagination = {
            'pageCount': el.headers.get('x-pagination-page-count'),
            'perPage': el.headers.get('x-pagination-per-page'),
            'totalCount': el.headers.get('x-pagination-total-count'),
            'currentPage': el.headers.get('x-pagination-current-page'),
          }
        }),
        map(el => el.body)
      ).subscribe(resp => {
        this.contentTypes.push(...resp);
        this.contentTypes$.next(this.contentTypes.slice());
        this.contentPage = this.contentPage + 1;
        console.log("getContentTypes", this.contentPage, this.contentTypes)

        if (this.contentPagination.pageCount >= this.contentPage) {
          this.getContentTypes(platform_id, this.contentPage);
        }
      })
    )
  }

  onSearchProfiles(resp) {
    if (!this.profiles) {
      return;
    }

    if (!resp) {
      this.profiles$.next(this.profiles.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }

    // filter the banks
    this.profiles$.next(
      this.profiles.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  ngAfterViewInit(): void {
    this.attachSubscriptions(
      this.profilesContainer.openedChange.subscribe((e) => {
        if (e) {
          this.onScroll()
        }
      })
    )
  }

  

  onScroll() {
    this.attachSubscriptions(
      fromEvent(this.profilesContainer.panel.nativeElement, "scroll").pipe(
        filter((e:any) => e.target.scrollTop >=  e.target.scrollHeight - e.target.offsetHeight - 400),
        map(() => this.profilesContainer.panel.nativeElement.scrollTop),
        debounceTime(300),
        map(y => Math.ceil((y + this.profilesContainer.panel.nativeElement.innerHeight)/ (400))),
        distinctUntilChanged()
      ).subscribe(() => {
          if (this.page <= this.pagination['pageCount']) {
            this.getProfiles(this.company_id, this.page);
          }
        }
      )
    )
  }

  getProfiles(company_id, page) {
    this.attachSubscriptions(
      this.companyService.getProfiles(company_id, page).pipe(
        tap(el => {
          this.pagination = {
            'pageCount': el.headers.get('x-pagination-page-count'),
            'perPage': el.headers.get('x-pagination-per-page'),
            'totalCount': el.headers.get('x-pagination-total-count'),
            'currentPage': el.headers.get('x-pagination-current-page'),
          }
        }),
        map(el => el.body)
      ).subscribe(resp => {
        console.log("getProfiles", resp)
        this.profiles.push(...resp);
        this.profiles$.next(this.profiles.slice());
        this.page = this.page + 1;
      })
    )
  }

  getProfilesStatus() {
    this.attachSubscriptions(
      this.globalDataService.getContentStatus().subscribe(resp => {
        console.log("getContentStatus", resp);
        this.profileStatuses = resp.slice();
      })
    )
    // this.attachSubscriptions(
    //   this.taskService.getProfileStatuses().subscribe(resp => {
    //     console.log("getProfilesStatus", resp)
    //     this.profileStatuses = resp;
    //   })
    // )
  }

  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData().subscribe(resp => {
        this._ngx_adapter.setLocale(resp.active);
        this._adapter.setLocale(resp.active);
        this.activeLang = resp.active;
      })
    )
  }
  getTaskProfiles() {
    this.attachSubscriptions(
      this.taskService.getTaskProfiles(this.task_id, this.company_id).subscribe(resp => console.log("PROFILES", resp))
    )
  }

  getCompany() {
    this.attachSubscriptions(
      this.companyService.getCompany(this.company_id).subscribe(resp => {
        this.companyService.company$.next(resp[0]);
      })
    )
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  getCsrf() {
    this.attachSubscriptions(
      this.auth.$userToken.subscribe(resp => {
        this.user_id = resp.user_id;
        this.sm.localStorageSetItem("csrf_param", resp.csrf_param)
        this.sm.localStorageSetItem("csrf_token", resp.csrf_token)
      })
    )
  }

  getUser() {
    this.attachSubscriptions(
      this.auth.$user.subscribe(resp => {
        this.user = resp;
      })
    )
  }

  onSubmit() {
    this.isSubmit = true;
    // if (!!this.settingsForm.value.content_published_at && !!this.settingsForm.value.content_published_at._d) {
    //   this.settingsForm.value.content_published_at = moment(this.settingsForm.value.content_published_at._d).unix();
    // } else {
    //   delete this.settingsForm.value.content_published_at;
    // }

    let form = Object.assign(this.urlForm.value, this.settingsForm.value);
    let timeAndDate = form.content_published_at.clone()

    const utcOffset = moment.tz(timeAndDate, this.data.company.timezone).utcOffset();
    console.log(utcOffset);

    
    let utcTimestamp = moment(timeAndDate).format("X")
    console.log(utcTimestamp);
    
    let difference = (Number(this.timeZone) - Number(utcOffset))*60
    console.log("difference", difference);
    
    let workSpaceTimeZone = Number(utcTimestamp) + Number(difference)
    console.log("workSpaceTimeZone", workSpaceTimeZone);

    let sbmtData = {...form}

    sbmtData.content_published_at = workSpaceTimeZone

    this.attachSubscriptions(
      this.taskService.addProfile(sbmtData, this.company_id).subscribe(resp => {
        this.isSubmit = false;
        this.dialogRef.close({event: "Add", data: resp})
      },
      error => {
        this.isSubmit = false;
      })
    )
  }

  getLinkData(stepper: MatStepper) {
    if (this.isParseWork) {
      this.layoutService.showSnackBar({name: ""}, marker("Data is being received. Wait a moment, please."), SnackBarItem)
      return false;
    }

    this.isParseWork = true;
    this.attachSubscriptions(
      this.taskService.getLinkData(this.urlForm.value.content_url, this.company_id, (this.data.task.company_id != this.company_id ? this.data.task.company_id : null)).subscribe(resp => {
        if (resp.tasks.length) {

          let sameUrl =  this.bottomSheet.open(TasksHaveSameUrlComponent, {
            data: {
              tasks: resp.tasks
            }
          });

          sameUrl.afterDismissed().subscribe( data => {
            if (data && data.message == 'close') {
              console.log("close");
            } if (data && data.message == 'continue') {
              const dialogRef = this.dialog.open(SameUrlTasksComponent, {
                data: {
                  company: this.data.company,
                  tasks: resp.tasks,
                  resp: resp
                }
              });
          
              // this.attachSubscriptions(
              //   dialogRef.afterClosed().subscribe(result => {
              //   })
              // )
            }
          });
          
        }

        this.settingsForm.patchValue({
          content_name: (resp.parsers && resp.parsers.includes("InstagramParser")) || (resp.url && resp.url.indexOf("instagram.com") != -1) ? resp.description : resp.name,
          content_published_at: moment(resp.published_at*1000),
          content_image: resp.image,
          content_views_count: !!resp.views_count ? resp.views_count : '0' 
        })

        if (!!resp.profile_url && this.profiles.find(el => el.url.replace("https://","").replace("http://","") == resp.profile_url.replace("https://","").replace("http://",""))) {
          this.settingsForm.patchValue({
            channel_id: this.profiles.find(el => el.url.replace("https://","").replace("http://","") == resp.profile_url.replace("https://","").replace("http://","")).id
          })
        } else {
          this.layoutService.showSnackBar({name: resp.profile_url}, marker("The profile for this link was not found in the system. Add it to Reports and the profile will be auto-detected."), SnackBarItem)
        }
        this.isParseWork = false;
        // channel_id
        stepper.next();
      })
    )
  }

  selectionChange($event?: StepperSelectionEvent): void {
    this.selectedIndex = $event.selectedIndex;
  }

  stepperBack(stepper: MatStepper) {
    stepper.previous();
  }

  cancel() {
    this.dialogRef.close({event: "null", data: false})
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
