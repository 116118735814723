<mat-dialog-content class="mat-typography">
    <div class="container form_wrapper" [appDeviceClass]="'custom-scroll custom-scroll-t'">
        <div class="title_modal d-f-c">
            <ng-container *ngIf="data.forPartnerWorkspace; else elseTemplateIsPartner">
                <span class="set_width">
                    {{ "Channel to partner" | translate }}:
                </span>
                <app-user [class]="'user_div'" [companyEmployee]="data?.employee.partnerCompany" [imgRoute]="data?.imgRoute" [host]="data?.host" [company]="data?.company"></app-user>
            </ng-container>
            <ng-template #elseTemplateIsPartner>
                <span class="set_width">
                    {{ "Channel to employee" | translate }}:
                </span>
                <app-user *ngIf="data?.fromEmployees" [class]="'user_div ml-1'" [employee]="data?.employee"></app-user>
            </ng-template>
            
        </div>

        <form [formGroup]="form" class="form_with_pads" (ngSubmit)="onSubmit()">
            <p>
                <mat-checkbox color="primary" formControlName="is_channel_content">{{ "Can create post for further sending for publising." | translate }}</mat-checkbox>
            </p>

            <p>
                <mat-checkbox color="primary" formControlName="is_channel_content_upload">{{ "Can publish and edit published posts." | translate }}</mat-checkbox>
            </p>


            <ng-container *ngIf="data?.fromEmployees; else elseTemplateFromEmpl">
                <mat-form-field appearance="legacy" class="full_width_form h_cst_t">
                    <mat-label>{{ "Profile" | translate }}</mat-label>
                    <mat-select [disableOptionCentering]="true" [multiple]="true" selectDir formControlName="channel_id">
                        <mat-select-trigger class="multi_users">
                            <ng-container *ngIf="form.get('channel_id').value && form.get('channel_id').value.length">
                                <ng-container *ngFor="let id of form.get('channel_id').value">
                                    <span class="multi_item"><app-outlet-line [channel]="getProfile(id)" [company_id]="data.company_id"></app-outlet-line></span>
                                </ng-container>
                            </ng-container>
                        </mat-select-trigger>
                        <mat-option>
                            <ngx-mat-select-search 
                                placeholderLabel="{{ 'Search' | translate }}" 
                                noEntriesFoundLabel="{{ 'no matching outlet found' | translate }}"
                                [formControl]="profilesMoreControl"></ngx-mat-select-search>
                        </mat-option>                  
                        <mat-option *ngFor="let profile of profiles$ | async" [value]="profile.id" class="cst_o">
                            <app-outlet-line [channel]="profile" [company_id]="company_id"></app-outlet-line>
                        </mat-option>
                    </mat-select>           
                    <button class="suffix_button" [ngStyle]="{'color': (!form.get('channel_id').value || isSubmit) ? '#686868' : '#4E844E'}" [disabled]="!form.get('channel_id').value || isSubmit" type="submit" (click)="$event.stopPropagation()" mat-icon-button matSuffix>
                        <mat-icon>add_circle</mat-icon>
                    </button>
                </mat-form-field>
            </ng-container>
            <ng-template #elseTemplateFromEmpl>
                <mat-form-field appearance="legacy" class="full_width_form">
                    <mat-label>{{ "Executor" | translate }}</mat-label>
                    <mat-select [disableOptionCentering]="true" selectDir formControlName="employee_id">
                      <mat-option>
                          <ngx-mat-select-search 
                              placeholderLabel="{{ 'Search' | translate }}" 
                              noEntriesFoundLabel="{{ 'no matching member found' | translate }}"
                              [formControl]="execControl"></ngx-mat-select-search>
                        </mat-option>
                      <mat-option *ngFor="let employee of employees$ | async" [value]="employee.id">
                          <app-user [class]="'user_div'" [employee]="employee"></app-user>
                      </mat-option>
                    </mat-select>
                    <button class="suffix_button" [ngStyle]="{'color': (!form.get('employee_id').value || isSubmit) ? '#686868' : '#4E844E'}" [disabled]="!form.get('employee_id').value || isSubmit" type="submit" (click)="$event.stopPropagation()" mat-icon-button matSuffix>
                        <mat-icon>add_circle</mat-icon>
                    </button>
                </mat-form-field>
            </ng-template>
        </form>

        <mat-nav-list *ngIf="channelsToEmployee?.length">
            <mat-list-item *ngFor="let chToEm of channelsToEmployee; let i = index;" class="data_out_item">
                <div class="data_item">
                    <div class="check_wrp mr-1">
                        <mat-checkbox color="primary" class="example-margin" (click)="$event.stopPropagation()" (change)="$event ? toggleElement(chToEm) : null" [checked]='repCollection.isSelected(chToEm)'></mat-checkbox>
                    </div>
                    <div class="data_item_info">
                        <ng-container *ngIf="data?.fromEmployees; else elseTemplateFromEmplData">
                            <div class="channel">
                                <app-outlet-line [channel]="chToEm.channel" [company_id]="company_id"></app-outlet-line>
    
                                <div class="d-f-c">
                                    <mat-icon (click)="editChToEm(chToEm, 'is_channel_content')" class="cp data_icon ml-1" [ngClass]="{'active': chToEm?.is_channel_content}" #tooltip="matTooltip"
                                    matTooltip="{{ chToEm?.is_channel_content ? ('Can create content for download.' | translate) : ('Can`t create content for download.' | translate)}}"
                                    [matTooltipPosition]="'below'">add_circle</mat-icon>
                                    
                                    <div class="d-f-c ml-1 cp" #tooltip="matTooltip" (click)="editChToEm(chToEm, 'is_channel_content_upload')"
                                    matTooltip="{{ chToEm?.is_channel_content_upload ? ('Can send created content for download and edit content after.' | translate) : ('Can`t send created content for download and edit content after.' | translate)}}"
                                    [matTooltipPosition]="'below'">
                                        <mat-icon class="data_icon" [ngClass]="{'active': chToEm?.is_channel_content_upload}">publish</mat-icon>/<mat-icon  class="data_icon" [ngClass]="{'active': chToEm?.is_channel_content_upload}">edit</mat-icon>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <ng-template #elseTemplateFromEmplData>
                            <div class="employee">
                                <ng-container *ngIf="chToEm?.employee_id; else elseTemplateIsEmpl">
                                    <app-user [class]="'user_div'" [employee]="getEmployee(chToEm?.employee_id)"></app-user>
                                </ng-container>
                                <ng-template #elseTemplateIsEmpl>
                                    <app-user [class]="'user_div'" [employee]="getEmployee(chToEm?.partner_employee_id)"></app-user>
                                </ng-template>
                                

                                <mat-icon (click)="editChToEm(chToEm, 'is_channel_content')" class="data_icon ml-1 cp" [ngClass]="{'active': chToEm?.is_channel_content}" #tooltip="matTooltip"
                                matTooltip="{{ chToEm?.is_channel_content ? ('Can create content for download.' | translate) : ('Can`t create content for download.' | translate)}}"
                                [matTooltipPosition]="'below'">add_circle</mat-icon>
                                
                                <div class="d-f-c ml-1 cp" #tooltip="matTooltip" (click)="editChToEm(chToEm, 'is_channel_content_upload')"
                                matTooltip="{{ chToEm?.is_channel_content_upload ? ('Can send created content for download and edit content after.' | translate) : ('Can`t send created content for download and edit content after.' | translate)}}"
                                [matTooltipPosition]="'below'">
                                    <mat-icon class="data_icon" [ngClass]="{'active': chToEm?.is_channel_content_upload}">publish</mat-icon>/<mat-icon  class="data_icon" [ngClass]="{'active': chToEm?.is_channel_content_upload}">edit</mat-icon>
                                </div>
                            </div>
                        </ng-template>
                    </div>
                    <mat-icon style="color: red;" (click)="deleteChannelsToEmployee(chToEm.id, i)">delete</mat-icon>
                </div>
            </mat-list-item>
        </mat-nav-list>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <div class="f_w_p_btns d-f-c">
        <div class="d-f-c" style="margin-right: 50px;" *ngIf="repCollection && channelsToEmployee?.length">
            <mat-checkbox class="select_all_checkbox" (click)="$event.preventDefault; $event.stopPropagation()" (change)="$event ? masterToggle() : null"
            [checked]="repCollection.hasValue() && isAllSelected()"
            [indeterminate]="repCollection.hasValue() && !isAllSelected()"
            [aria-label]="checkboxLabel()">
                <span>{{ checkboxLabel() | translate }}</span>
            </mat-checkbox>
            
            <button class="ml-1" *ngIf="repCollection.selected.length" color="warn" mat-raised-button (click)="multiDelete()" [disabled]="is_deleting">
                {{ "Delete selected" | translate }} {{repCollection.selected.length}}
            </button>
        </div>

        <button mat-raised-button (click)="close()">
            {{ "Close" | translate }}
        </button>
    </div>
</mat-dialog-actions>