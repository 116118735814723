<h2 mat-dialog-title class="dialog_title">
    <div class="d-f-c title_outlet_line">
        {{ "Link_outlet" | translate }} <app-outlet-line [channel]="data.profile" [company_id]="data.company_id"></app-outlet-line> {{ "with authorized outlet" | translate }}:
    </div>
    <button mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h2>
<mat-dialog-content class="mat-typography" dirEnter (dirSubmit)="!form.valid ? null : onSubmit()">
    <div class="container">
        <div class="tabs" *ngIf="!!data?.company && data?.company?.permissions?.includes('manage_channel_account') && !data?.company?.permissions?.includes('owner')">
            <div class="tab" (click)="changeFromCompany(false)" [ngClass]="{'active': !fromCompany}">
                {{"Your channels" | translate}}
            </div>
            <div class="tab" (click)="changeFromCompany(true)" [ngClass]="{'active': !!fromCompany}">
                {{"Owner channels" | translate}}
            </div>
        </div>
        <form [formGroup]="form">
            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ "Choose an account" | translate }}</mat-label>
                <mat-select formControlName="channel_account_id" [disableOptionCentering]="true" selectDir msInfiniteScroll (infiniteScroll)="onScroll($event, 'chanControl')">
                    <mat-select-trigger>
                        <div *ngIf="selectedChan" class="text_one_line">
                            {{selectedChan.name}}
                        </div>
                    </mat-select-trigger>
                    <mat-option>
                        <ngx-mat-select-search 
                        placeholderLabel="{{ 'Search' | translate }}" 
                        noEntriesFoundLabel="{{ 'no matching card found' | translate }}"
                        [formControl]="chanControl"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let channel of channels" [value]="channel.id" (click)="selectCard(channel)">
                        {{ channel.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <ng-container *ngIf="type == 'facebook'; else elseTemplateIsMeta">
                <div class="custom-select" #channelSelect (click)="openFilter(channelSelect); $event.preventDefault(); $event.stopPropagation();">
                    <div>
                        <ng-container *ngIf="form.get('external_id') && form.get('external_id').value && form.get('external_id').value.length; else elseTemplateIsSelected">
                            <ng-container *ngIf="form.get('external_id').value == 0; else elseTemplateNotSet">
                                {{ "Channel not set" | translate }}
                            </ng-container>
                            <ng-template #elseTemplateNotSet>
                                <div class="label">{{ "Choose a channel" | translate }}</div>
                                <app-outlet-line [channel]="getExpChannel(form.get('external_id').value[0])" [company_id]="data?.company_id"></app-outlet-line>
                            </ng-template>
                        </ng-container>
                        <ng-template #elseTemplateIsSelected>
                            {{ "Choose a channel" | translate }}
                        </ng-template>
                    </div>
                    <mat-icon>arrow_drop_down</mat-icon>
                    <div class="underline"></div>
                </div>
            </ng-container>
            <ng-template #elseTemplateIsMeta>
                <mat-form-field appearance="standard" class="full_width_form h_cst_t" *ngIf="form.get('external_id')">
                    <mat-label>{{ "Choose a channel" | translate }}</mat-label>
                    <mat-select [disableOptionCentering]="true" selectDir #profilesContainer formControlName="external_id" required>
                        <mat-select-trigger>
                            <app-outlet-line [channel]="getExpChannel(form.get('external_id').value)" [company_id]="data?.company_id"></app-outlet-line>
                        </mat-select-trigger>
                        <mat-option>
                            <ngx-mat-select-search 
                            placeholderLabel="{{ 'Search' | translate }}" 
                            noEntriesFoundLabel="{{ 'no matching channel found' | translate }}"
                            [formControl]="expChannelsControl"></ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let channel of expChannels$ | async" [value]="channel.external_id" class="cst_o">
                            <div class="d-f-c" style="justify-content: space-between; width: 100%;">
                                <app-outlet-line [channel]="channel" [company_id]="data?.company_id"></app-outlet-line>
                                
                                <a *ngIf="channel.computedUrl && channel.computedUrl != 'https://www.youtube.com/'" target="_blank" (click)="$event.stopPropagation()" [href]="channel.computedUrl">
                                    <mat-icon>open_in_new</mat-icon>
                                </a>
                            </div>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </ng-template>
            

        </form>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button (click)="close()">
        {{ "Close" | translate }}
    </button>
    <button class="ml-1" (click)="onSubmit()" mat-raised-button cdkFocusInitial color="primary" [disabled]="!form.valid">
        {{ "Save" | translate }}
    </button>
    <button class="ml-1" mat-raised-button color="warn" (click)="getChannelAccountAuthUrl()">
        <mat-icon>add</mat-icon>
        {{"Connect new" | translate}}
    </button>

</mat-dialog-actions>

<ng-template #filterMenu let-val>
    <div class="channels_select" [ngStyle]="{'width': val + 'px'}">
        <section class="sub_filter_wrp">
            <div class="subs" [appDeviceClass]="'custom-scroll'" *ngIf="groupedProfiles && groupedProfiles.length">
                <div class="sub" [ngClass]="{'active': channelsSubsControl.value == 0}" (click)="patchControl(channelsSubsControl, 0)">
                    <div class="d-f-c">
                        <span class="text_one_line">
                            {{ "All" | translate }}
                        </span>
                    </div>
                </div>
                <div class="sub" [ngClass]="{'active': channelsSubsControl.value == 1}" (click)="patchControl(channelsSubsControl, 1)">
                    <div class="d-f-c">
                        <app-platform [platform_id]="2"></app-platform>
                        <span class="ml-1 text_one_line">
                            {{ "Instagram" | translate }}
                        </span>
                    </div>
                </div>
                <div class="sub" [ngClass]="{'active': channelsSubsControl.value == 2}" (click)="patchControl(channelsSubsControl, 2)">
                    <div class="d-f-c">
                        <app-platform [platform_id]="4"></app-platform>
                        <span class="ml-1 text_one_line">
                            {{ "Facebook" | translate }}
                        </span>
                    </div>
                </div>
            </div>
            <div class="block">
                <ng-container *ngIf="!switched && groupedProfiles && groupedProfiles[channelsSubsControl['value']] && groupedProfiles[channelsSubsControl['value']].channels.length">
                    <ng-container *ngIf="groupedProfiles[channelsSubsControl['value']].channels.length > 7; else elseTemplateSubL">
                        <div class="filter_exp_search">
                            <mat-form-field appearance="legacy" class="filter_form search">
                                <input [formControl]="groupedProfiles[channelsSubsControl['value']].channelsControl" matInput>
                                <mat-icon matSuffix>search</mat-icon>
                            </mat-form-field>
                          </div>
                        <form [formGroup]="form" class="exp_form" [appDeviceClass]="'custom-scroll'">
                            <mat-selection-list [multiple]="false" formControlName="external_id" (selectionChange)="selectChange($event)">
                                <mat-list-option [value]="0" [ngClass]="{'active': 0 === form.get('external_id').value}">
                                    <div class="user_div">
                                        <mat-icon style="visibility: none;">user</mat-icon>
                                        <span>
                                            {{ "Channel not set" | translate }}
                                        </span>
                                    </div>
                                </mat-list-option>
                                <ng-container *ngFor="let fil of groupedProfiles[channelsSubsControl['value']].asChannels | async">
                                    <mat-list-option class="cst_o" [value]="fil.external_id" [ngClass]="{'active': fil.external_id == form.get('external_id').value}">
                                        <div class="d-f-c" style="justify-content: space-between; width: 100%;">
                                            <app-outlet-line [class]="'for_nav'" [channel]="fil" [company_id]="company_id"></app-outlet-line>
                                            
                                            <a class="cst_a" *ngIf="fil.computedUrl && fil.computedUrl != 'https://www.youtube.com/'" target="_blank" (click)="$event.stopPropagation()" [href]="fil.computedUrl">
                                                <mat-icon>open_in_new</mat-icon>
                                            </a>
                                        </div>
                                    </mat-list-option>
                                </ng-container>
                            </mat-selection-list>
                        </form>
                    </ng-container>
                    <ng-template #elseTemplateSubL>
                        <form [formGroup]="form" class="exp_form" [appDeviceClass]="'custom-scroll'">
                            <mat-selection-list [multiple]="false" formControlName="external_id" (selectionChange)="selectChange($event)">
                                <mat-list-option [value]="0" [ngClass]="{'active': 0 === form.get('external_id').value}">
                                    <div class="user_div">
                                        <mat-icon style="visibility: none;">user</mat-icon>
                                        <span>
                                            {{ "Channel not set" | translate }}
                                        </span>
                                    </div>
                                </mat-list-option>
                                <ng-container *ngFor="let fil of groupedProfiles[channelsSubsControl['value']].channels">
                                    <mat-list-option class="cst_o" [value]="fil.external_id" [ngClass]="{'active': fil.external_id == form.get('external_id').value}">
                                        <div class="d-f-c" style="justify-content: space-between; width: 100%;">
                                            <app-outlet-line [class]="'for_nav'" [channel]="fil" [company_id]="company_id"></app-outlet-line>
                                            
                                            <a class="cst_a" *ngIf="fil.computedUrl && fil.computedUrl != 'https://www.youtube.com/'" target="_blank" (click)="$event.stopPropagation()" [href]="fil.computedUrl">
                                                <mat-icon>open_in_new</mat-icon>
                                            </a>
                                        </div>
                                    </mat-list-option>
                                </ng-container>
                            </mat-selection-list>
                        </form>
                    </ng-template>
                </ng-container>
            </div>
        </section>
    </div>
</ng-template>