import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { BaseClass } from 'src/app/shared/models/base-class';
import { GlobalDataService } from 'src/app/shared/services/common/global-data.service';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';

@Component({
  selector: 'app-edit-partner-workspace',
  templateUrl: './edit-partner-workspace.component.html',
  styleUrls: ['./edit-partner-workspace.component.scss']
})

export class EditPartnerWorkspaceComponent extends BaseClass implements OnInit, OnDestroy {
  public form: FormGroup;
  public company_id: any;
  public disableSelect: boolean = false;
  public isSubmit: boolean = false;
  public isFormChange: boolean = false;
  public currencyList: any;
  public numberRegEx = /\d*\.?\d{1,2}/;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<EditPartnerWorkspaceComponent>,
    private sm: StorageManagerService,
    private layoutService: LayoutService,
    private companyService: CompanyService,
    private fb: FormBuilder,
    private globalDataService: GlobalDataService,
  ) { super() }

  ngOnInit(): void {
    console.log('EditPartnerWorkspaceComponent', this.data);
    this.getCompany();

    if (this.data.partner.currency_id) {
      this.disableSelect = true
    }
    
    this.form = this.fb.group({
      partner_company_id: this.data.company_id,
      currency_id: [this.data.partner.currency_id],
      rate_target: [this.data.partner.rate_target, [Validators.required, Validators.pattern(this.numberRegEx)]],
      salary: [this.data.partner.salary, [Validators.required, Validators.pattern(this.numberRegEx)]],
    })


    this.attachSubscriptions(
      this.form.valueChanges.subscribe(() => this.isFormChange = true)
    )

    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        if (this.isFormChange) {
          this.layoutService.openBottomSheet(this.dialogRef);
        } else {
          this.close();
        }
      })
    )

    this.getCurrencyList();
  }

  onPartnerImgError(event){
    event.target.src = this.data.imgRoute+'/assets/img/partner.png'
  }

  getCompany() {
    this.attachSubscriptions(
      this.companyService.getCompany(this.data.company_id).subscribe(resp => {
        this.companyService.company$.next(resp[0]);
      })
    )
  }

  getCurrencyList() {
    this.attachSubscriptions(
      this.globalDataService.getCurrencies().subscribe(resp => {
        console.log("getCurrencyList", resp)
        this.currencyList = resp.slice();
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  onSubmit() {
    this.isSubmit = true;
    if (String(this.form.value['rate_target']).indexOf(",") != -1) {
      this.form.value['rate_target'] = this.form.value['rate_target'].replace(",", ".");
    }
    if (String(this.form.value['salary']).indexOf(",") != -1) {
      this.form.value['salary'] = this.form.value['salary'].replace(",", ".");
    }
    this.attachSubscriptions(
      this.companyService.editPartner(this.data.partner.id, this.form.value).subscribe(resp => {
        this.isSubmit = false;
        this.dialogRef.close({event: "update", data: resp});
      }, error => {
        this.isSubmit = false;
        this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
      })
    )
  }

  ngOnDestroy(): void {
    this.clearSubscriptions();
  }
}
