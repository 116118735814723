<h2 mat-dialog-title class="dialog_title">
    <span>
        <ng-container *ngIf="data.only; else elseTemplate">
            {{ "Only for" | translate }}
        </ng-container>
        <ng-template #elseTemplate>
            {{ "For everyone except" | translate }}
        </ng-template>
    </span>
    <button mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h2>
<mat-dialog-content class="mat-typography">
    <div class="container">
        
        <ng-container *ngFor="let param of parameters">

            <mat-form-field appearance="standard" class="full_width_form" *ngIf="!!param?.hasValues">
                <mat-label>{{ param.name }}</mat-label>
    
                <mat-chip-list 
                    class="without_ordered"
                    #paramsChipList
                    [(ngModel)]="param.activeValues"
                >
                    <mat-chip 
                        class="param_chip"
                        *ngFor="let val of param.activeValues; let k = index;"
                        [removable]="true" 
                        color="primary" 
                        (removed)="onRemove(val.id, param, k)">
                            {{ !!val?.parameterValue ? val.parameterValue?.value : getValueById(val.parameter_value_id)?.value }}
                        <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
    
                    <input
                    id="param_input_{{param.id}}"
                    class="auto_complete_input"
                    placeholder="{{ 'Search' | translate }}"
                    #paramsInput
                    [formControl]="param.search"
                    [matAutocomplete]="paramsAuto"
                    [matChipInputFor]="paramsChipList"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    (matChipInputTokenEnd)="add($event)">
                </mat-chip-list>
                <mat-autocomplete autoActiveFirstOption #paramsAuto="matAutocomplete" (optionSelected)="selected($event, param)" (optionsScroll)="onScroll(param)">
                    <ng-container *ngFor="let val of param.values">
                        <mat-option *ngIf="noOption(param.activeValues, val?.id) && withSearch(param?.search?.value, val)" [value]="val?.id">
                            {{ val?.value }}
                        </mat-option>
                    </ng-container>
                </mat-autocomplete>
            </mat-form-field>
        </ng-container>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">

    <div class="btns">
        <button mat-raised-button (click)="close()">
            {{ "Close" | translate }}
        </button>
        <button class="ml-1" (click)="close()" mat-raised-button cdkFocusInitial color="primary">
            {{ "Save" | translate }}
        </button>
    </div>
</mat-dialog-actions>
