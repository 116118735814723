<mat-dialog-content class="mat-typography">
    <div class="job_chat" [ngClass]="{'selected': menuState}">
        <div class="lists_window" #chatsContainer>
            <div class="job_chats_list" [ngClass]="{'full_height': jobChatsModel?.length <= 1}">
                <div class="title">
                    <span (click)="log()">{{ "Сhats from this job" | translate }}:</span>
                    <div class="d-f-c">
                        <form [formGroup]="chatsFilter" class="job_chats_fil">
                            <div (click)="filterChats.open()">
                                <ng-container *ngIf="chatsFilter.value.data_file_id === 0">
                                    {{ "Just chats" | translate }}
                                </ng-container>
                                <ng-container *ngIf="chatsFilter.value.data_file_id === ''">
                                    {{ "All" | translate }}
                                </ng-container>
                                <ng-container *ngIf="chatsFilter.value.data_file_id == 'isset'">
                                    {{ "File chats" | translate }}
                                </ng-container>
                                &#11206;
                            </div>
                            <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                                <mat-select disableOptionCentering #filterChats formControlName="data_file_id">
                                    <mat-option [value]="''" [ngClass]="{'cst_active_select_option': chatsFilter.value.data_file_id === ''}">
                                        {{ "All" | translate }}
                                    </mat-option>
                                    <mat-option [value]="'isset'" [ngClass]="{'cst_active_select_option': chatsFilter.value.data_file_id == 'isset'}">
                                        {{ "File chats" | translate }}
                                    </mat-option>
                                    <mat-option [value]="0" [ngClass]="{'cst_active_select_option': chatsFilter.value.data_file_id === 0}">
                                        {{ "Just chats" | translate }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </form>
                        <button type="button" class="mobile_closeChat ml-1" mat-icon-button (click)="close()">
                            <mat-icon style="color: #686868;">close</mat-icon>
                        </button>
                    </div>
                </div>

                <mat-selection-list #chatsList [multiple]="false" (selectionChange)="selectChat(chatsList.selectedOptions.selected[0]?.value, 'click')">
                    <mat-list-option class="chat_snippet" *ngFor="let chat of (activeJob?.chatsArr || activeJob?.chatsInfo); let i = index;" (click)="clickChat(chat.id)" [ngClass]="{'highlight_chat': chat.is_read == '0'}" [value]="chat?.id" [selected]="chat_id == chat.id" (contextmenu)="openChatMenu($event, chat); $event.preventDefault(); $event.stopPropagation();">
                        <div class="indicators">
                            <mat-icon class="pinned_chat" [ngClass]="{'show': !!chat.pin}">push_pin</mat-icon>
                            <!-- <div class="no_read_point" [ngClass]="{'show': chat.is_read == '0'}"></div> -->
                        </div>
                        <div class="chat_snippet_in">
                            <div class="snippet_header">
                                <div class="name text_one_line">
                                    {{chat?.name}}
                                </div>
                                <ng-container *ngIf="!!chat?.lastPost; else elseTemplateHasLast">     
                                    <ng-container *ngIf="chat?.lastPost?.updated_at > today; else updatedAtTempl">
                                        <div class="date" #tooltip="matTooltip"
                                        matTooltip="{{ chat.updated_at*1000 | date:'MMM d, y':'timeZone':activeLang | yearPipe }}"
                                        [matTooltipPosition]="'left'">
                                          {{ chat?.lastPost?.updated_at*1000 | date:'hh:mm a':'timeZone':activeLang | yearPipe }}
                                        </div>
                                      </ng-container>
                                      <ng-template #updatedAtTempl>
                                        <div class="date" #tooltip="matTooltip"
                                        matTooltip="{{ chat.updated_at*1000 | date:'hh:mm a':'timeZone':activeLang | yearPipe }}"
                                        [matTooltipPosition]="'left'">
                                          {{ chat?.lastPost?.updated_at*1000 | date:'MMM d, y':'timeZone':activeLang | yearPipe }}
                                        </div>
                                    </ng-template>
                                </ng-container>
                                <ng-template #elseTemplateHasLast>
                                    <ng-container *ngIf="chat?.created_at > today; else updatedAtTempl">
                                        <div class="date" #tooltip="matTooltip"
                                        matTooltip="{{ chat?.created_at*1000 | date:'MMM d, y':'timeZone':activeLang | yearPipe }}"
                                        [matTooltipPosition]="'left'">
                                            {{ chat?.created_at*1000 | date:'hh:mm a':'timeZone':activeLang | yearPipe }}
                                        </div>
                                        </ng-container>
                                        <ng-template #updatedAtTempl>
                                        <div class="date" #tooltip="matTooltip"
                                        matTooltip="{{ chat?.created_at*1000 | date:'hh:mm a':'timeZone':activeLang | yearPipe }}"
                                        [matTooltipPosition]="'left'">
                                            {{ chat?.created_at*1000 | date:'MMM d, y':'timeZone':activeLang | yearPipe }}
                                        </div>
                                    </ng-template>
                                </ng-template>
                            </div>
                            <div class="snippet_body">
                                <div class="snippet_info">
                                    <ng-container *ngIf="chat?.lastPost?.action; else elseTemplateHasAction">
                                        <div class="member">
                                            <ng-container *ngIf="!!chat?.lastPost?.action?.createdEmployee?.image; else elseOwnerTemplateLastPost">
                                                <img [appHoverUserInfo]="{ user: chat?.lastPost?.action?.createdEmployee, company_id: data?.company?.id }" [src]="chat?.lastPost?.action?.createdEmployee?.image" alt="">
                                            </ng-container>
                                            <ng-template #elseOwnerTemplateLastPost>
                                                <mat-icon [appHoverUserInfo]="{ user: chat?.lastPost?.action?.createdEmployee, company_id: data?.company?.id }">account_circle</mat-icon>
                                            </ng-template>
                                            <span class="member_name">
                                                <ng-container *ngIf="chat?.lastPost?.action?.user_id == data.user.id; else elseTemplateIfMe">
                                                    {{ "You" | translate }}
                                                </ng-container>
                                                <ng-template #elseTemplateIfMe>
                                                    {{ chat?.lastPost?.action?.createdEmployee?.name }}
                                                </ng-template>
                                            </span>
                                        </div>
                                    </ng-container>
                                    <ng-template #elseTemplateHasAction>
                                        <div class="member">
                                            <ng-container *ngIf="!!chat?.lastPost?.employee?.image; else elseOwnerTemplateLastPostD">
                                                <img [appHoverUserInfo]="{ user: chat?.lastPost?.employee, company_id: data?.company?.id }" [src]="chat?.lastPost?.employee?.image" alt="">
                                            </ng-container>
                                            <ng-template #elseOwnerTemplateLastPostD>
                                                <mat-icon [appHoverUserInfo]="{ user: chat?.lastPost?.employee, company_id: data?.company?.id }">account_circle</mat-icon>
                                            </ng-template>
                                            <span class="member_name">
                                                <ng-container *ngIf="chat?.lastPost?.employee?.user_id == data.user.id; else elseTemplateIfMe">
                                                    {{ "You" | translate }}
                                                </ng-container>
                                                <ng-template #elseTemplateIfMe>
                                                    {{ chat?.lastPost?.employee?.name }}
                                                </ng-template>
                                            </span>
                                        </div>
                                    </ng-template>
                                    
                                </div>
    
                                <div class="chat_employees">
                                    <div class="block_inside" *ngIf="chat?.employees?.length > 0"  [ngStyle]="{'width': 19 + (chat?.employees?.length > 9 ? 9 : chat?.employees?.length - 1) * 13 + 'px'}">
                                        <ng-container *ngFor="let employee of chat?.employees; let i = index;">
                                            <div class="chat_empl" *ngIf="i < 9"  #tooltip="matTooltip"
                                            matTooltip="{{employee?.employee?.name}}"
                                            [matTooltipPosition]="'below'" [ngStyle]="{'z-index': chat?.employees?.length - i, 'transform': 'translateX(-' + i*6 + 'px)'}">
                                              <ng-container *ngIf="employee?.employee?.image; else elseMobWorkTemplate">
                                                <img [appHoverUserInfo]="{ user: employee?.employee, company_id: data?.company?.id }" [src]="employee?.employee?.image" alt="">
                                              </ng-container>
                                              <ng-template #elseMobWorkTemplate>
                                                <mat-icon [appHoverUserInfo]="{ user: employee?.employee, company_id: data?.company?.id }">account_circle</mat-icon>
                                              </ng-template>
                
                                              <div class="calendar" #tooltip="matTooltip"
                                              matTooltip="{{employee?.employeeStatus?.start_at*1000 | date:'MMM dd. Y':'+0000':activeLang | yearPipe}}"
                                              [matTooltipPosition]="'right'"
                                              *ngIf="!!employee?.employeeStatus && !!employee?.employeeStatus?.discussion_id && [1,2,5].includes(employee?.employeeStatus?.status_id) && employee?.employeeStatus.start_at > 0">
                                                <svg [ngClass]="{'red': employee?.employeeStatus.initial_start_at < today}" width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                  <path d="M1.90909 0V1.27273H1.27273C0.566364 1.27273 0 1.83909 0 2.54545V11.4545C0 12.1545 0.566364 12.7273 1.27273 12.7273H5.15455C5.95636 13.5164 7.05727 14 8.27273 14C10.7355 14 12.7273 12.0082 12.7273 9.54545C12.7273 8.33 12.2436 7.22909 11.4545 6.42727V2.54545C11.4545 1.84545 10.8882 1.27273 10.1818 1.27273H9.54545V0H8.27273V1.27273H3.18182V0H1.90909ZM1.27273 2.54545H10.1818V3.81818H1.27273V2.54545ZM1.27273 5.09091H10.1818V5.51727C9.60273 5.24364 8.95364 5.09091 8.27273 5.09091C5.81 5.09091 3.81818 7.08273 3.81818 9.54545C3.81818 10.2264 3.97091 10.8755 4.24455 11.4545H1.27273V5.09091ZM8.27273 6.45909C9.97818 6.45909 11.3591 7.84 11.3591 9.54545C11.3591 11.2509 9.97818 12.6318 8.27273 12.6318C6.56727 12.6318 5.18636 11.2509 5.18636 9.54545C5.18636 7.84 6.56727 6.45909 8.27273 6.45909ZM7.63636 7.63636V9.98455L9.66636 11.1555L10.1436 10.3282L8.59091 9.43091V7.63636H7.63636Z" fill="#219653"/>
                                                </svg>
                                              </div>
                
                                            </div>
                                            <ng-container *ngIf="i >= 9 && i == chat?.employees?.length - 1">
                                                <div class="more_empl" [ngStyle]="{'z-index': chat?.employees?.length - 3, 'transform': 'translateX(-' + 2*6 + 'px)'}">
                                                    +{{chat?.employees?.length - 3}}
                                                </div>
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                            <div class="snippet_footer" [ngClass]="{'cen': !chat?.lastPost?.action}">
                                <ng-container *ngIf="chat?.lastPost?.action; else elseTemplateHasActionMSG">
                                    <div class="msg">
                                        <div class="action">
                                            {{ 'Set' | translate }} 
                                            <ng-container [ngSwitch]="chat?.lastPost?.action?.action">
                                              <ng-container *ngSwitchCase="'task_employee_status_change'">
                                                <b style="margin-left: 5px; margin-right: 5px;">{{ getChatStatus(chat?.lastPost?.action?.task_employee_status_id) }}</b>
                                                {{ "for" | translate }}
                                                <ng-container *ngIf="!!chat?.lastPost?.action?.employee?.image; else elseActionOwnerTemplate">
                                                    <img [appHoverUserInfo]="{ user: chat?.lastPost?.action?.employee, company_id: data?.company?.id }" class="ml-5" [src]="chat?.lastPost?.action?.employee?.image" alt="">
                                                </ng-container>
                                                <ng-template #elseActionOwnerTemplate>
                                                    <mat-icon [appHoverUserInfo]="{ user: chat?.lastPost?.action?.employee, company_id: data?.company?.id }" class="ml-5">account_circle</mat-icon>
                                                </ng-template>
                                              </ng-container>
                                              <ng-container *ngSwitchCase="'discussion_status'">
                                                 <b style="margin-left: 5px;">{{ getChatStatus(chat?.lastPost?.action?.discussion_status_id) }}</b>
                                              </ng-container>
                                            </ng-container>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-template #elseTemplateHasActionMSG>
                                    <div class="chat_last_post">
                                        <div class="last_post_file" *ngIf="!!chat?.lastPost?.file_id">
                                          <div class="file_view">
                                            <ng-container [ngSwitch]="understandFileType(chat?.lastPost?.file?.content_type)">
                                                <ng-container *ngSwitchCase="'video'">
                                                    <div class="file movie d-f-c">
                                                        <ng-container *ngIf="chat?.lastPost?.file?.thumbnail; else elseTemplateThumbnail">
                                                            <div class="movie_thumb" >
                                                                <img style="max-width: 100%; max-height: 100%;" (error)="onImgError($event)" [src]="data.host + chat?.lastPost?.file?.thumbnail + '?company_id=' + data?.company?.id">
                                                            </div>
                                                        </ng-container>
                                                        <ng-template #elseTemplateThumbnail>
                                                            <mat-icon class="file_img_icon" style="color: #3f51b5">
                                                                movie
                                                            </mat-icon>
                                                        </ng-template>
                                                    </div>
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'pdf'">
                                                    <div class="pdf">
                                                        <svg height="800px" width="800px" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
                                                            viewBox="0 0 303.188 303.188" xml:space="preserve">
                                                            <g>
                                                                <polygon style="fill:#E8E8E8;" points="219.821,0 32.842,0 32.842,303.188 270.346,303.188 270.346,50.525 	"/>
                                                                <path style="fill:#FB3449;" d="M230.013,149.935c-3.643-6.493-16.231-8.533-22.006-9.451c-4.552-0.724-9.199-0.94-13.803-0.936
                                                                    c-3.615-0.024-7.177,0.154-10.693,0.354c-1.296,0.087-2.579,0.199-3.861,0.31c-1.314-1.36-2.584-2.765-3.813-4.202
                                                                    c-7.82-9.257-14.134-19.755-19.279-30.664c1.366-5.271,2.459-10.772,3.119-16.485c1.205-10.427,1.619-22.31-2.288-32.251
                                                                    c-1.349-3.431-4.946-7.608-9.096-5.528c-4.771,2.392-6.113,9.169-6.502,13.973c-0.313,3.883-0.094,7.776,0.558,11.594
                                                                    c0.664,3.844,1.733,7.494,2.897,11.139c1.086,3.342,2.283,6.658,3.588,9.943c-0.828,2.586-1.707,5.127-2.63,7.603
                                                                    c-2.152,5.643-4.479,11.004-6.717,16.161c-1.18,2.557-2.335,5.06-3.465,7.507c-3.576,7.855-7.458,15.566-11.815,23.02
                                                                    c-10.163,3.585-19.283,7.741-26.857,12.625c-4.063,2.625-7.652,5.476-10.641,8.603c-2.822,2.952-5.69,6.783-5.941,11.024
                                                                    c-0.141,2.394,0.807,4.717,2.768,6.137c2.697,2.015,6.271,1.881,9.4,1.225c10.25-2.15,18.121-10.961,24.824-18.387
                                                                    c4.617-5.115,9.872-11.61,15.369-19.465c0.012-0.018,0.024-0.036,0.037-0.054c9.428-2.923,19.689-5.391,30.579-7.205
                                                                    c4.975-0.825,10.082-1.5,15.291-1.974c3.663,3.431,7.621,6.555,11.939,9.164c3.363,2.069,6.94,3.816,10.684,5.119
                                                                    c3.786,1.237,7.595,2.247,11.528,2.886c1.986,0.284,4.017,0.413,6.092,0.335c4.631-0.175,11.278-1.951,11.714-7.57
                                                                    C231.127,152.765,230.756,151.257,230.013,149.935z M119.144,160.245c-2.169,3.36-4.261,6.382-6.232,9.041
                                                                    c-4.827,6.568-10.34,14.369-18.322,17.286c-1.516,0.554-3.512,1.126-5.616,1.002c-1.874-0.11-3.722-0.937-3.637-3.065
                                                                    c0.042-1.114,0.587-2.535,1.423-3.931c0.915-1.531,2.048-2.935,3.275-4.226c2.629-2.762,5.953-5.439,9.777-7.918
                                                                    c5.865-3.805,12.867-7.23,20.672-10.286C120.035,158.858,119.587,159.564,119.144,160.245z M146.366,75.985
                                                                    c-0.602-3.514-0.693-7.077-0.323-10.503c0.184-1.713,0.533-3.385,1.038-4.952c0.428-1.33,1.352-4.576,2.826-4.993
                                                                    c2.43-0.688,3.177,4.529,3.452,6.005c1.566,8.396,0.186,17.733-1.693,25.969c-0.299,1.31-0.632,2.599-0.973,3.883
                                                                    c-0.582-1.601-1.137-3.207-1.648-4.821C147.945,83.048,146.939,79.482,146.366,75.985z M163.049,142.265
                                                                    c-9.13,1.48-17.815,3.419-25.979,5.708c0.983-0.275,5.475-8.788,6.477-10.555c4.721-8.315,8.583-17.042,11.358-26.197
                                                                    c4.9,9.691,10.847,18.962,18.153,27.214c0.673,0.749,1.357,1.489,2.053,2.22C171.017,141.096,166.988,141.633,163.049,142.265z
                                                                    M224.793,153.959c-0.334,1.805-4.189,2.837-5.988,3.121c-5.316,0.836-10.94,0.167-16.028-1.542
                                                                    c-3.491-1.172-6.858-2.768-10.057-4.688c-3.18-1.921-6.155-4.181-8.936-6.673c3.429-0.206,6.9-0.341,10.388-0.275
                                                                    c3.488,0.035,7.003,0.211,10.475,0.664c6.511,0.726,13.807,2.961,18.932,7.186C224.588,152.585,224.91,153.321,224.793,153.959z"/>
                                                                <polygon style="fill:#FB3449;" points="227.64,25.263 32.842,25.263 32.842,0 219.821,0 	"/>
                                                                <g>
                                                                    <path style="fill:#A4A9AD;" d="M126.841,241.152c0,5.361-1.58,9.501-4.742,12.421c-3.162,2.921-7.652,4.381-13.472,4.381h-3.643
                                                                        v15.917H92.022v-47.979h16.606c6.06,0,10.611,1.324,13.652,3.971C125.321,232.51,126.841,236.273,126.841,241.152z
                                                                        M104.985,247.387h2.363c1.947,0,3.495-0.546,4.644-1.641c1.149-1.094,1.723-2.604,1.723-4.529c0-3.238-1.794-4.857-5.382-4.857
                                                                        h-3.348C104.985,236.36,104.985,247.387,104.985,247.387z"/>
                                                                    <path style="fill:#A4A9AD;" d="M175.215,248.864c0,8.007-2.205,14.177-6.613,18.509s-10.606,6.498-18.591,6.498h-15.523v-47.979
                                                                        h16.606c7.701,0,13.646,1.969,17.836,5.907C173.119,235.737,175.215,241.426,175.215,248.864z M161.76,249.324
                                                                        c0-4.398-0.87-7.657-2.609-9.78c-1.739-2.122-4.381-3.183-7.926-3.183h-3.773v26.877h2.888c3.939,0,6.826-1.143,8.664-3.43
                                                                        C160.841,257.523,161.76,254.028,161.76,249.324z"/>
                                                                    <path style="fill:#A4A9AD;" d="M196.579,273.871h-12.766v-47.979h28.355v10.403h-15.589v9.156h14.374v10.403h-14.374
                                                                        L196.579,273.871L196.579,273.871z"/>
                                                                </g>
                                                                <polygon style="fill:#D1D3D3;" points="219.821,50.525 270.346,50.525 219.821,0 	"/>
                                                            </g>
                                                        </svg>
                                                    </div>
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'image'">
                                                    <div class="img_wrp">
                                                        <img (error)="onImgError($event)" [src]="data.host + (chat?.lastPost?.file?.thumbnail ? chat?.lastPost?.file?.thumbnail + '?company_id=' + data?.company?.id : chat?.lastPost?.file?.original + '?company_id=' + data?.company?.id)" alt="">
                                                    </div>
                                                </ng-container>
                                                <ng-container *ngSwitchDefault>
                                                  <ng-container *ngIf="!!chat?.lastPost?.file?.thumbnail; else elseTemplateHasThumb">
                                                    <div class="img_wrp">
                                                      <img (error)="onImgError($event)" [src]="data.host + chat?.lastPost?.file?.thumbnail + '?company_id=' + data?.company?.id" alt="">
                                                    </div>
                                                  </ng-container>
                                                  <ng-template #elseTemplateHasThumb>
                                                    <div class="file d-f-c">
                                                      <mat-icon class="file_img_icon mr-1" style="color: #3f51b5">
                                                          insert_drive_file
                                                      </mat-icon>
                                                  </div>
                                                  </ng-template>
                                                </ng-container>
                                            </ng-container>
                                          </div>
                                          <!-- <div class="file_info">
                                              <span class="file_name">{{ chat?.lastPost?.file?.filename }}</span>
                                              <span class="file_size">{{ chat?.lastPost?.file?.filesize | filesize }}</span>
                                          </div> -->
                
                                        </div>
                                        
                                        <!-- <div class="text_one_line msg file_msg">
                                          {{ chat?.lastPost?.text }}
                                        </div> -->
                                        <div class="text_one_line msg file_msg" [innerHTML]='chat?.lastPost?.text ? (chat?.lastPost?.text.replaceAll("\n"," ").replaceAll("</BR>"," ").replaceAll("<BR>"," ").replaceAll("</br>"," ").replaceAll("<br>"," ")) : ""'></div>
                                    </div>
                                </ng-template>
                                
                              
            
                                <div class="who_read" *ngIf="chat?.readUsers?.length" [ngStyle]="{'width': 14 + (chat?.readUsers?.length - 1) * 10 + 'px'}">
                                    <ng-container *ngFor="let conUser of chat?.readUsers; let j = index;">
                                        <div [ngStyle]="{'z-index': chat?.readUsers?.length - j, 'transform': 'translateX(-' + j*4 + 'px)'}" #tooltip="matTooltip" matTooltip="{{ 'Seen by ' + conUser?.employee?.name + ' ' + (conUser.updated_at*1000 | date:'short':'timeZone':activeLang | yearPipe) }}" [matTooltipPosition]="'above'">
                                            <ng-container *ngIf="!!conUser?.employee?.image; else elseOwnerTemplateconUser">
                                                <img [appHoverUserInfo]="{ user: conUser?.employee, company_id: data?.company?.id }" style="width: 14px; height: 14px; border-radius: 100%;" [src]="conUser?.employee?.image" alt="">
                                            </ng-container>
                                            <ng-template #elseOwnerTemplateconUser>
                                                <mat-icon [appHoverUserInfo]="{ user: conUser?.employee, company_id: data?.company?.id }" style="width: 14px; height: 14px; font-size: 14px;">account_circle</mat-icon>
                                            </ng-template>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </mat-list-option>
                </mat-selection-list>
            </div>

            <div class="task_jobs" *ngIf="jobChatsModel?.length > 1">
                <div class="title">
                    <span>{{ "Jobs of current card" | translate }}:</span>
                </div>
                <!--  -->
                <!-- (selectionChange)="selectJob(jobsList.selectedOptions.selected[0]?.value)" -->
                <mat-selection-list #jobsList [multiple]="false" >
                    <!-- /(click)="clickChat(chat.id)" -->
                    <ng-container *ngFor="let job of jobChatsModel; let i = index;" >
                        <mat-list-option [disableRipple]="true" *ngIf="activeJob.id != job.id" class="chat_element" (click)="jobClick(job, jobsList.selectedOptions.selected[0]?.value)" [value]="job">
                            <div class="task_job_item">
                                <div class="task_job_item_comment">
                                    <div class="red_point" *ngIf="findUnread(job?.chatsInfo)"></div>
                                    <ng-container *ngIf="job?.name; else elseTemplateTitleHover">
                                        <div class="text_one_line">{{job?.name}}</div>
                                    </ng-container>
                                    <ng-template #elseTemplateTitleHover>
                                        &nbsp;
                                    </ng-template>
                                </div>
                                <!-- <div class="task_job_item_comment">
                                    <div class="red_point" *ngIf="findUnread(job?.chatsInfo)"></div>
                                    <ng-container *ngIf="job?.comment; else elseTemplateTitleHover">
                                        {{ job?.comment.length > 30 ? (job?.comment | slice:0:27) + "..." : job?.comment}}
                                    </ng-container>
                                    <ng-template #elseTemplateTitleHover>
                                        &nbsp;
                                    </ng-template>
                                </div> -->
                                <div class="empl_info">
                                    <div class="empl_avatars">
                                        <ng-container *ngIf="!!job?.employee?.employee?.image; else elseTemplateTo">
                                            <img [appHoverUserInfo]="{ user: job?.employee?.employee, company_id: data?.company?.id }" [src]="job?.employee?.employee?.image" alt="">
                                        </ng-container>
                                        <ng-template #elseTemplateTo>
                                            <mat-icon [appHoverUserInfo]="{ user: job?.employee?.employee, company_id: data?.company?.id }">account_circle</mat-icon>
                                        </ng-template>
                                    </div>
                                    <div class="job_right">
                                        <div>
                                            <div class="up d-f-c">
                                                <span class="mr-1">
                                                    {{getColumnName(job?.operation_id)}}
                                                </span>
                                                <div class="inside_job" [ngClass]="{
                                                    'first': job.status_id == '1',
                                                    'second': job.status_id == '2',
                                                    'third': job.status_id == '3',
                                                    'fours': job.status_id == '4',
                                                    'fives': job.status_id == '5',
                                                    'sixs': job.status_id == '6',
                                                    'nine': job.status_id == '97',
                                                    'seven': job.status_id == '98',
                                                    'eight': job.status_id == '99'}">
                                                    <span>{{ job.status.name }}</span>
                                                </div>
                                            </div>
                                            <div class="down" [ngClass]="{'has_add': !!job?.addEmployee?.length}">
                                                <div class="add_employees" *ngIf="!!job?.addEmployee?.length" [ngStyle]="{'width': 16 + (job?.addEmployee?.length - 1) * 12 + 'px'}">
                                                    <div class="add_emp" *ngFor="let addEmp of job?.addEmployee; let j = index" [ngStyle]="{'z-index': job?.addEmployee.length - j, 'transform': 'translateX(-' + j*4 + 'px)'}">
                                                        <ng-container *ngIf="!!addEmp?.employee?.image; else elseTemplate">
                                                            <img [appHoverUserInfo]="{ user: addEmp?.employee, company_id: data?.company?.id }" [src]="addEmp?.employee?.image" alt="">
                                                        </ng-container>
                                                        <ng-template #elseTemplate>
                                                            <mat-icon [appHoverUserInfo]="{ user: addEmp?.employee, company_id: data?.company?.id }">account_circle</mat-icon>
                                                        </ng-template>
                                                    </div>
                                                </div>
                                                <div class="comment" *ngIf="job?.private_comment">
                                                    {{ job?.private_comment.length > 55 ? (job?.private_comment | slice:0:52) + "..." : job?.private_comment}}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="date">
                                            <ng-container *ngIf="job.updated_at; else elseTemplateMobComplete">
                                                {{ job.updated_at*1000 | date:'MMM dd. yyyy':'+0000': activeLang | yearPipe }}
                                            </ng-container>
                                            <ng-template #elseTemplateMobComplete>
                                                &nbsp;
                                            </ng-template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="all_chats" *ngIf="clickedJob?.id == job.id">
                                <div class="j_chat" *ngFor="let jChat of job.chatsInfo" (click)="$event.preventDefault(); $event.stopPropagation(); setChat(job, jChat)" [ngClass]="{'active': jChat.id == chat_id, 'unread': jChat.is_read == '0'}">
                                    <div class="j_chat_name">
                                        {{ jChat.name.length > 30 ? (jChat.name | slice:0:27) + "..." : jChat.name}}
                                    </div>
                                    <div class="j_chat_status" [ngClass]="{
                                        'first': jChat.status_id == '1',
                                        'second': jChat.status_id == '2',
                                        'third': jChat.status_id == '3',
                                        'fours': jChat.status_id == '4',
                                        'fives': jChat.status_id == '5',
                                        'sixs': jChat.status_id == '6',
                                        'nine': jChat.status_id == '97',
                                        'seven': jChat.status_id == '98',
                                        'eight': jChat.status_id == '99'}">
                                        <span>{{ jChat.status.name }}</span>
                                    </div>
                                </div>
                            </div>
                        </mat-list-option>
                    </ng-container>
                    
                </mat-selection-list>
            </div>
          

        </div>

        <div class="chat_window" *ngIf="chat_id">
            <app-chat
            [selectMenuCounter]='selectMenuCounter'
            [chat_id]='chat_id'
            [chats]='activeJob.chatsInfo'
            [timeZone]='timeZone'
            [statuses]='statuses'
            [task]='data.task'
            [tasks]='data.tasks'
            [work]='activeJob'
            [activeLang]='activeLang'
            [linkToPost]="!!data?.m_id ? {
                chat_id: data?.chat?.id,
                msg_id: data?.m_id,
                is_used: false
            } : {}"
            [operationsValues]='data.operationsValues'
            [company]='data.company'
            [user]='data.user'
            [is_mobile]='is_mobile'
            [chatMore]='activeJob.chatsInfo.length - 1'
            [dialogComp]='[dialogRef, data.dialogComp]'
            (HideMenu)="hideMenu()"
            (ToNextChat)="toNextChat()"
            (Close)="close()"
            ></app-chat>
        </div>

        <div id="mob_menu_toggler" *ngIf="!is_mobile">
            <svg id="mob_menu_svg" class="ham ham2" viewBox="0 0 100 100" width="80" (click)="toggleMenu()" [ngClass]="!menuState ? 'active' : ''">
                <path
                      class="line top"
                      d="m 70,33 h -40 c -6.5909,0 -7.763966,-4.501509 -7.763966,-7.511428 0,-4.721448 3.376452,-9.583771 13.876919,-9.583771 14.786182,0 11.409257,14.896182 9.596449,21.970818 -1.812808,7.074636 -15.709402,12.124381 -15.709402,12.124381" />
                <path
                      class="line middle"
                      d="m 30,50 h 40" />
                <path
                      class="line bottom"
                      d="m 70,67 h -40 c -6.5909,0 -7.763966,4.501509 -7.763966,7.511428 0,4.721448 3.376452,9.583771 13.876919,9.583771 14.786182,0 11.409257,-14.896182 9.596449,-21.970818 -1.812808,-7.074636 -15.709402,-12.124381 -15.709402,-12.124381" />
              </svg>
        </div>
    </div>
</mat-dialog-content>

<ng-template #chatMenu let-menuData>
	<section class="proj-file-menu">
        <div (click)="addToBoard(menuData?.chat); closeChatMenu();">
            <svg class="mr-1" style="width: 18px; height: 18px" width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.8 12L6 16.8H9.6V24H12V16.8H15.6L10.8 12ZM19.2 2.4H18V0H15.6V2.4H6V0H3.6V2.4H2.4C1.76348 2.4 1.15303 2.65286 0.702944 3.10294C0.252856 3.55303 0 4.16348 0 4.8V21.6C0 22.2365 0.252856 22.847 0.702944 23.2971C1.15303 23.7471 1.76348 24 2.4 24H7.2V21.6H2.4V8.4H19.2V21.6H14.4V24H19.2C19.8365 24 20.447 23.7471 20.8971 23.2971C21.3471 22.847 21.6 22.2365 21.6 21.6V4.8C21.6 4.16348 21.3471 3.55303 20.8971 3.10294C20.447 2.65286 19.8365 2.4 19.2 2.4Z" fill="#219653"/>
            </svg>
            {{ 'Add to board' | translate }}
        </div>

        <div (click)="openTargetValues([menuData?.chat?.task_id, menuData?.chat?.task_operation_id, menuData?.chat?.id], menuData?.chat)">
            <svg class="mr-1" width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg" style="width: 18px; height: 15px;"><path d="M3.15 4.5C3.50804 4.5 3.85142 4.35777 4.10459 4.10459C4.35777 3.85142 4.5 3.50804 4.5 3.15C4.5 2.79196 4.35777 2.44858 4.10459 2.19541C3.85142 1.94223 3.50804 1.8 3.15 1.8C2.79196 1.8 2.44858 1.94223 2.19541 2.19541C1.94223 2.44858 1.8 2.79196 1.8 3.15C1.8 3.50804 1.94223 3.85142 2.19541 4.10459C2.44858 4.35777 2.79196 4.5 3.15 4.5ZM13.869 6.822C14.193 7.146 14.4 7.596 14.4 8.1C14.4 8.595 14.202 9.045 13.869 9.369L9.369 13.869C9.045 14.193 8.595 14.4 8.1 14.4C7.605 14.4 7.155 14.202 6.822 13.869L0.531 7.578C0.198 7.245 0 6.795 0 6.3V1.8C0 0.801 0.801 0 1.8 0H6.3C6.795 0 7.245 0.198 7.569 0.522L13.869 6.822ZM10.386 1.539L11.286 0.639L17.469 6.822C17.802 7.146 18 7.605 18 8.1C18 8.595 17.802 9.045 17.478 9.369L12.636 14.211L11.736 13.311L16.875 8.1L10.386 1.539Z" fill="#686868"></path></svg>
            {{ "Tags" | translate }}
        </div>
        
        <div ngxClipboard [cbContent]="origin + '/chat/' + menuData?.chat?.acm" (cbOnSuccess)="copyLink('chat'); closeChatMenu();">
            <mat-icon class="mr-1">link</mat-icon> 
            {{ 'Copy url to chat' | translate }}
        </div>

        <a [href]="origin + '/chat/' + menuData?.chat?.acm" (click)="openInNew($event, menuData?.chat); closeChatMenu();">
            <mat-icon class="mr-1">open_in_new</mat-icon>
            {{ 'Open in new tab' | translate }}
        </a>
        <div (click)="openConnectionChats(menuData?.chat); closeChatMenu();">
            <mat-icon class="mr-1">
                <svg style="cursor: pointer;" class="chat_cons" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18 16.08C17.24 16.08 16.56 16.38 16.04 16.85L8.91 12.7C8.96 12.47 9 12.24 9 12C9 11.76 8.96 11.53 8.91 11.3L15.96 7.19C16.5 7.69 17.21 8 18 8C19.66 8 21 6.66 21 5C21 3.34 19.66 2 18 2C16.34 2 15 3.34 15 5C15 5.24 15.04 5.47 15.09 5.7L8.04 9.81C7.5 9.31 6.79 9 6 9C4.34 9 3 10.34 3 12C3 13.66 4.34 15 6 15C6.79 15 7.5 14.69 8.04 14.19L15.16 18.35C15.11 18.56 15.08 18.78 15.08 19C15.08 20.61 16.39 21.92 18 21.92C19.61 21.92 20.92 20.61 20.92 19C20.92 17.39 19.61 16.08 18 16.08Z" fill="#D7D7D7"/>
                    <circle *ngIf="chat?.partOfDiscussionPartition.length != 0" cx="6" cy="12" r="3" fill="#96A6C2"/>
                    <circle *ngIf="chat?.discussionRelated.length != 0" cx="18" cy="19" r="3" fill="#FFD0A7"/>
                    <circle *ngIf="chat?.consistOfDiscussionPartition.length != 0" cx="18" cy="5" r="3" fill="#98F1BD"/>
                  </svg>
            </mat-icon> 
            {{ "Chat connections" | translate }}
        </div>

        <div (click)="toPersonalStatuses(menuData?.chat); closeChatMenu();">
            <mat-icon class="mr-1">manage_accounts</mat-icon> 
            {{ 'Statuses' | translate }}
        </div>
	</section>
</ng-template>