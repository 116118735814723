<mat-dialog-content class="mat-typography" dirEnter (dirSubmit)="!form.valid || noChat ? null : onSubmit()">
    <div class="container form_wrapper">
        <div class="title_modal">
            {{ "Editing a job" | translate }}
        </div>
        <form [formGroup]="form" class="form_with_pads form_with_valids">

            <div class="small_chips" *ngIf="operations?.length > 0">
                <ng-container *ngIf="!form.get('operation_id').valid && submited">
                    <div class="req_field"></div>
                    <div class="req_label">
                        <div class="angle"></div>
                        <span>
                            {{ "You need to choose a value" | translate }}
                        </span>
                    </div>
                </ng-container>
                <label>{{ "Job type" | translate }}: </label>
                <mat-chip-list [multiple]="false" selectable formControlName="operation_id">
                    <ng-container *ngFor="let operation of operations; let i = index">
                        <mat-chip class="small_chip" (click)="selectType(operation)" [value]="operation?.id">
                            {{ operation.name | translate }}
                        </mat-chip>
                        <mat-chip *ngIf="i == operations?.length - 1 && i > 6" class="small_chip more" (click)="operationIdContainer.open()">
                            {{ "More..." | translate }}
                        </mat-chip>
                    </ng-container>
                    
                </mat-chip-list>
            </div>

            <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                <mat-select #operationIdContainer formControlName="operation_id" (selectionChange)="selectType($event)">
                    <mat-option>
                        <ngx-mat-select-search 
                        placeholderLabel="{{ 'Search' | translate }}" 
                        noEntriesFoundLabel="{{ 'no matching job type found' | translate }}"
                        [formControl]="operationMoreControl"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let operation of operations$ | async" [value]="operation.id">
                        {{ operation.name | translate }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ 'Title' | translate }}</mat-label>
                <input formControlName="name" matInput placeholder="{{ 'Title' | translate }}">
            </mat-form-field>

            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ 'Description' | translate }}</mat-label>
                <textarea formControlName="comment" matInput placeholder="{{ 'Drief description' | translate }}"
                cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="5"></textarea>
            </mat-form-field>

            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ 'Hidden notes (available only to owners, admins and managers)' | translate }}</mat-label>
                <textarea formControlName="private_comment" matInput placeholder="{{ 'Hidden notes (available only to owners, admins and managers)' | translate }}"
                cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="5"></textarea>
            </mat-form-field>
    
            <div class="small_chips" *ngIf="operationStatuses?.length > 0">
                <ng-container *ngIf="!form.get('status_id').valid && submited">
                    <div class="req_field"></div>
                    <div class="req_label">
                        <div class="angle"></div>
                        <span>
                            {{ "You need to choose a value" | translate }}
                        </span>
                    </div>
                </ng-container>
                <label>{{ "Status" | translate }}: </label>
                <mat-chip-list [multiple]="false" selectable formControlName="status_id">
                    <ng-container *ngFor="let status of operationStatuses;">
                        <mat-chip class="small_chip" (click)="selectStatus(status)" [value]="status?.id" [ngClass]="{
                            'first': status.id == '1',
                            'second': status.id == '2',
                            'third': status.id == '3',
                            'fours': status.id == '4',
                            'fives': status.id == '5',
                            'sixs': status.id == '6',
                            'nine': status.id == '97',
                            'seven': status.id == '98',
                            'eight': status.id == '99'}">
                            {{ status.name | translate }}
                        </mat-chip>
                    </ng-container>
                    
                </mat-chip-list>
            </div>


            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ 'Executor notes (available only to owners, admins, managers and author of notes)' | translate }}</mat-label>
                <textarea formControlName="employee_comment" matInput placeholder="{{ 'Executor notes (available only to owners, admins, managers and author of notes)' | translate }}"
                cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="5"></textarea>
            </mat-form-field>

            <div class="priority_chips">
                <label>{{ "Priority" | translate }}: </label>
                <mat-chip-list [multiple]="false" selectable formControlName="priority">
                    <mat-chip *ngFor="let priority of prioritys" [ngClass]="{
                        'Urgent': priority.id == '1000',
                        'High': priority.id == '900',
                        'Middle': priority.id == '800',
                        'Low': priority.id == '700',
                        'No': priority.id == '0'
                    }" class="priority_chip" (click)="selectPrioritys(priority)" [value]="priority.id">
                        {{ priority.name | translate }}
                    </mat-chip>
                </mat-chip-list>
            </div>
    
            <mat-form-field appearance="standard" class="full_width_form">
                <mat-label>{{ "Date the job was performed" | translate }}</mat-label>
                <input matInput [matDatepicker]="dp" formControlName="completed_at">
                <mat-datepicker-toggle matPrefix [for]="dp"></mat-datepicker-toggle>
                <mat-datepicker #dp></mat-datepicker>
            </mat-form-field>

            <div class="date_and_time_label">{{ "Deadline" | translate }}</div>
            <div class="date_and_time_c_w" formGroupName="date">
                <mat-form-field appearance="standard" class="date_picker_cst">
                    <div class="prefix" matPrefix style="margin-right: 10px;">{{ "Date" | translate }}</div>
                    <input formControlName="day" matInput [matDatepicker]="pickerDay" placeholder="MM / DD/ YYYY">
                    <mat-datepicker-toggle matSuffix [for]="pickerDay"></mat-datepicker-toggle>
                    <mat-datepicker #pickerDay></mat-datepicker>
                </mat-form-field>
                
                <div class="ngx-timepicker-field-example">
                    <div class="prefix">{{ "Time" | translate }}</div>
                    <ngx-timepicker-field [format]="24" [defaultTime]="form.get('date').get('time').value" formControlName="time"></ngx-timepicker-field>
                </div>
            </div>
        </form>
        <form [formGroup]="workEmpl" class="form_with_pads form_with_valids">
            <mat-form-field *ngIf="workEmpl.get('rate')" appearance="standard" class="full_width_form">
                <mat-label>{{ 'Points value for job performance' | translate }}</mat-label>
                <input formControlName="rate" matInput type="number" placeholder="{{ 'Points value for job performance' | translate }}">
                <div class="cur_suffix" matSuffix>PTS</div>
            </mat-form-field>
    
            <mat-form-field appearance="standard" class="full_width_form" *ngIf="workEmpl.get('price')">
                <mat-label>{{ 'Price to be charged by executor' | translate }}</mat-label>
                <input formControlName="price" matInput type="number" placeholder="{{ 'Price to be charged by executor' | translate }}">
            </mat-form-field>
        </form>

        <form [formGroup]="templateForm" class="form_with_pads form_with_valids">
            <div class="users_select">
                <label>{{ "Choose a scenario" | translate }}: </label>
                <mat-chip-list [multiple]="true" selectable formControlName="template_id">
                    <ng-container *ngFor="let template of templates; let i = index">
                        <mat-chip [disabled]="disableTemplate" style="padding-left: 12px !important;" class="chip_user" (click)="toggleChipTemplate(template)" [value]="template.id" [selected]="templateForm.value.template_id && templateForm.value.template_id.includes(template.id)">
                            {{ template.name }}
                        </mat-chip>
                        <mat-chip *ngIf="i == templates.length - 1 && i > 6" class="chip_user more" (click)="templatesIdContainer.open()">
                            {{ "more" | translate }}
                        </mat-chip>
                    </ng-container>
                </mat-chip-list>
            </div>

            <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                <mat-select [multiple]="true" #templatesIdContainer formControlName="template_id" (selectionChange)="templateChange($event)">
                    <mat-option>
                        <ngx-mat-select-search 
                        placeholderLabel="{{ 'Search' | translate }}" 
                        noEntriesFoundLabel="{{ 'no matching template found' | translate }}"
                        [formControl]="templatesMoreControl"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let template of templates$ | async" [value]="template.id" [disabled]="disableTemplate">
                        {{template.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </form>
        <div class="content_link_info form_with_pads" *ngIf="data.empl_status && data.work.content_url">
            {{ "The employee marked the work with the status 'To Approve' and attached a link to the content: " | translate }}
            <div>
                <a target="_blank" href="{{data.work.content_url}}">{{data.work.content_url}}</a>
            </div>
        </div>
 
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <div class="f_w_p_btns">
        <button mat-raised-button (click)="close()">
            {{ "Close" | translate }}
        </button>
        <button class="ml-1 d-f-btn" (click)="onSubmit()" cdkFocusInitial mat-raised-button color="primary" [disabled]="isSubmit || noChat">
            <mat-spinner class="spin_16 mr-1" *ngIf="isSubmit"></mat-spinner>  
            {{ "Save" | translate }}
        </button>
    </div>
</mat-dialog-actions>